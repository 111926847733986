"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var zoneNameWindowed = function zoneNameWindowed(name, bgURL, types) {
    return new ZoneName(name, bgURL, types, standardInserter, standardRemover, standardAnonInserter, standardAnonRemover, standardWindowPositioner, windowAnimatedInserter(fadeOutUp), windowAnimatedInserter(fadeOutUp), standardCardStatusUpdate);
};

var TabbedZone = function (_WindowedZone) {
    _inherits(TabbedZone, _WindowedZone);

    function TabbedZone(state, index, pileName, owner, createdBy, attachedTrait) {
        _classCallCheck(this, TabbedZone);

        var _this = _possibleConstructorReturn(this, (TabbedZone.__proto__ || Object.getPrototypeOf(TabbedZone)).call(this, state, index, pileName, owner, createdBy, attachedTrait));

        if (_this.layoutModel.tabsInitialized) {
            var acceptingTabs = _this.layoutModel.tabViews.filter(function (v) {
                return v.zoneAcceptor(_this);
            });
            if (acceptingTabs.length > 0) {
                acceptingTabs.forEach(function (v) {
                    _this.addTab(v);
                    v.addZone(_this);
                });
            } else {
                var newTab = _this.extractTab();
                _this.layoutModel.tabViews.push(newTab);
                /* This is *not* double-dipping because when the constructor of tab is called,
                 * this zone hasn't yet been added to state. Yeah, kinda gross. */
                _this.addTab(newTab);
                newTab.addZone(_this);
            }
        }
        return _this;
    }

    _createClass(TabbedZone, [{
        key: "reposition",
        value: function reposition() {
            _get(TabbedZone.prototype.__proto__ || Object.getPrototypeOf(TabbedZone.prototype), "reposition", this).call(this);
            this.tabViews.forEach(function (t) {
                return t.redraw();
            });
        }
    }, {
        key: "addCard",
        value: function addCard(card) {
            var targetStack = this.cardStacks.find(function (s) {
                return s.topCard.isCompatibleWith(card);
            });
            if (isUndefined(targetStack)) {
                targetStack = new CardStack(this.state.getAnonCard(card.cardName), this);
                targetStack.addFilter("intangible");
            }
            this.reposition();
            return targetStack;
        }
    }, {
        key: "addAnonymousCard",
        value: function addAnonymousCard() {
            var targetStack = this.cardStacks.find(function (s) {
                return s.topCard.cardName === CardNames.BACK;
            });
            if (isUndefined(targetStack)) {
                targetStack = new CardStack(this.state.getAnonCard(CardNames.BACK), this);
                targetStack.addFilter("intangible");
            }
            this.reposition();
            return targetStack;
        }
    }, {
        key: "mergeStacks",
        value: function mergeStacks(targetStack, transitionStack) {
            _get(TabbedZone.prototype.__proto__ || Object.getPrototypeOf(TabbedZone.prototype), "mergeStacks", this).call(this, targetStack, transitionStack);
            this.tabViews.forEach(function (t) {
                return t.redraw();
            });
        }
    }, {
        key: "shouldStack",
        get: function get() {
            return true;
        }
    }]);

    return TabbedZone;
}(WindowedZone);

var TrashZone = function (_TabbedZone) {
    _inherits(TrashZone, _TabbedZone);

    function TrashZone() {
        _classCallCheck(this, TrashZone);

        return _possibleConstructorReturn(this, (TrashZone.__proto__ || Object.getPrototypeOf(TrashZone)).apply(this, arguments));
    }

    _createClass(TrashZone, [{
        key: "getScript",
        value: function getScript(fromZone, stack, targetStack, props) {
            if (props.isInstant) return [];

            var width = stack.view.bbox.width;
            var height = stack.view.bbox.height;
            var rotAmount = -20 + Math.random() * 40;
            return [new Keyframe(props.speed * 0.8, new RelativeTarget(new Rectangle(0, 0.1 * height, 0, 0, 10, rotAmount)), Curves.SMOOTH), new Keyframe(props.speed * 1.2, new RelativeTarget(new Rectangle(0, 0.3 * height, 0, 0, 10, rotAmount * 0.5, -1)), Curves.SMOOTH)];
        }
    }, {
        key: "extractTab",
        value: function extractTab() {
            return new AlwaysVisibleGameTab(this.layoutModel, "images/mats/trash_mat.png", function (zone) {
                return zone instanceof TrashZone;
            });
        }
    }, {
        key: "zoneName",
        get: function get() {
            return "TrashZone";
        }
    }, {
        key: "showWindow",
        get: function get() {
            return this.visible;
        }
    }]);

    return TrashZone;
}(TabbedZone);

var TavernZone = function (_TabbedZone2) {
    _inherits(TavernZone, _TabbedZone2);

    function TavernZone() {
        _classCallCheck(this, TavernZone);

        return _possibleConstructorReturn(this, (TavernZone.__proto__ || Object.getPrototypeOf(TavernZone)).apply(this, arguments));
    }

    _createClass(TavernZone, [{
        key: "extractTab",
        value: function extractTab() {
            var _this4 = this;

            return new GameTab(this.layoutModel, "images/mats/tavern_mat.png", function (zone) {
                return zone instanceof TavernZone && zone.owner === _this4.owner;
            });
        }
    }, {
        key: "zoneName",
        get: function get() {
            return "TavernZone";
        }
    }]);

    return TavernZone;
}(TabbedZone);

var ExileZone = function (_TabbedZone3) {
    _inherits(ExileZone, _TabbedZone3);

    function ExileZone() {
        _classCallCheck(this, ExileZone);

        return _possibleConstructorReturn(this, (ExileZone.__proto__ || Object.getPrototypeOf(ExileZone)).apply(this, arguments));
    }

    _createClass(ExileZone, [{
        key: "extractTab",
        value: function extractTab() {
            var _this6 = this;

            return new GameTab(this.layoutModel, "images/mats/exile_mat.png", function (zone) {
                return zone instanceof ExileZone && zone.owner === _this6.owner;
            });
        }
    }, {
        key: "zoneName",
        get: function get() {
            return "ExileZone";
        }
    }]);

    return ExileZone;
}(TabbedZone);

var SetAsideZone = function (_TabbedZone4) {
    _inherits(SetAsideZone, _TabbedZone4);

    function SetAsideZone() {
        _classCallCheck(this, SetAsideZone);

        return _possibleConstructorReturn(this, (SetAsideZone.__proto__ || Object.getPrototypeOf(SetAsideZone)).apply(this, arguments));
    }

    _createClass(SetAsideZone, [{
        key: "extractTab",
        value: function extractTab() {
            var _this8 = this;

            return new GameTab(this.layoutModel, getFullArtURL(this.createdBy === null ? CardNames.BACK : this.createdBy.cardName), function (zone) {
                var thisCreatedBy = _this8.createdBy === null ? CardNames.BACK : _this8.createdBy.cardName;
                var thatCreatedBy = zone.createdBy === null ? CardNames.BACK : zone.createdBy.cardName;
                return zone instanceof SetAsideZone && zone.owner === _this8.owner && thisCreatedBy === thatCreatedBy;
            }, function () {
                var createdByString = _this8.createdBy === null ? "" : " (" + LANGUAGE.getCardName[_this8.createdBy.cardName].singular + ")";
                return "" + LANGUAGE.getZoneName["SetAsideZone"] + createdByString;
            });
        }
    }, {
        key: "zoneName",
        get: function get() {
            return "SetAsideZone";
        }
    }]);

    return SetAsideZone;
}(TabbedZone);

var NVZone = function (_TabbedZone5) {
    _inherits(NVZone, _TabbedZone5);

    function NVZone() {
        _classCallCheck(this, NVZone);

        return _possibleConstructorReturn(this, (NVZone.__proto__ || Object.getPrototypeOf(NVZone)).apply(this, arguments));
    }

    _createClass(NVZone, [{
        key: "extractTab",
        value: function extractTab() {
            var _this10 = this;

            return new GameTab(this.layoutModel, getFullArtURL(CardNames.NATIVE_VILLAGE), function (zone) {
                return zone instanceof NVZone && zone.owner === _this10.owner;
            }, function () {
                var createdByString = " (" + LANGUAGE.getCardName[CardNames.NATIVE_VILLAGE].singular + ")";
                return "" + LANGUAGE.getZoneName["SetAsideZone"] + createdByString;
            });
        }
    }, {
        key: "zoneName",
        get: function get() {
            return "NVZone";
        }
    }]);

    return NVZone;
}(TabbedZone);

var IslandZone = function (_TabbedZone6) {
    _inherits(IslandZone, _TabbedZone6);

    function IslandZone() {
        _classCallCheck(this, IslandZone);

        return _possibleConstructorReturn(this, (IslandZone.__proto__ || Object.getPrototypeOf(IslandZone)).apply(this, arguments));
    }

    _createClass(IslandZone, [{
        key: "extractTab",
        value: function extractTab() {
            var _this12 = this;

            return new GameTab(this.layoutModel, getFullArtURL(CardNames.ISLAND), function (zone) {
                return zone instanceof IslandZone && zone.owner === _this12.owner;
            }, function () {
                var createdByString = " (" + LANGUAGE.getCardName[CardNames.ISLAND].singular + ")";
                return "" + LANGUAGE.getZoneName["SetAsideZone"] + createdByString;
            });
        }
    }, {
        key: "zoneName",
        get: function get() {
            return "IslandZone";
        }
    }]);

    return IslandZone;
}(TabbedZone);