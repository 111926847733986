"use strict";

var TChineseAbilityNames = {};

TChineseAbilityNames[AbilityNames.BUY] = "a";
TChineseAbilityNames[AbilityNames.PLAY_ATTACK] = "打出攻擊卡";
TChineseAbilityNames[AbilityNames.DRAW_INSTRUCTIONS] = "b";
TChineseAbilityNames[AbilityNames.PLAY_INSTRUCTIONS] = "打出";
TChineseAbilityNames[AbilityNames.RESOLVE_EVENT] = "c";
TChineseAbilityNames[AbilityNames.GAIN_BY_NAME] = "d";

TChineseAbilityNames[AbilityNames.FISHING_VILLAGE_ABILITY] = TChineseCardNames[CardNames.FISHING_VILLAGE].singular;
TChineseAbilityNames[AbilityNames.REMOVE_COST_REDUCTION] = "e";
TChineseAbilityNames[AbilityNames.HAVEN_ABILITY] = TChineseCardNames[CardNames.HAVEN].singular;
TChineseAbilityNames[AbilityNames.SAVE_RETURN_TO_HAND] = TChineseCardNames[CardNames.SAVE].singular;
TChineseAbilityNames[AbilityNames.CARAVAN_ABILITY] = TChineseCardNames[CardNames.CARAVAN].singular;
TChineseAbilityNames[AbilityNames.MERCHANT_SHIP_ABILITY] = TChineseCardNames[CardNames.MERCHANT_SHIP].singular;
TChineseAbilityNames[AbilityNames.OUTPOST_ABILITY] = TChineseCardNames[CardNames.OUTPOST].singular;
TChineseAbilityNames[AbilityNames.TACTICIAN_ABILITY] = TChineseCardNames[CardNames.TACTICIAN].singular;
TChineseAbilityNames[AbilityNames.WHARF_ABILITY] = TChineseCardNames[CardNames.WHARF].singular;
TChineseAbilityNames[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = TChineseCardNames[CardNames.HORSE_TRADERS].singular;
TChineseAbilityNames[AbilityNames.DUCHESS_MAY_GAIN] = TChineseCardNames[CardNames.DUCHESS].singular;
TChineseAbilityNames[AbilityNames.FOOLS_GOLD_TRASH] = TChineseCardNames[CardNames.FOOLS_GOLD].singular;
TChineseAbilityNames[AbilityNames.NOBLE_BRIGAND] = TChineseCardNames[CardNames.NOBLE_BRIGAND].singular;
TChineseAbilityNames[AbilityNames.HAGGLER_ABILITY] = TChineseCardNames[CardNames.HAGGLER].singular;
TChineseAbilityNames[AbilityNames.INN_ABILITY] = TChineseCardNames[CardNames.INN].singular;
TChineseAbilityNames[AbilityNames.MANDARIN] = TChineseCardNames[CardNames.MANDARIN].singular;
TChineseAbilityNames[AbilityNames.FARMLAND] = TChineseCardNames[CardNames.FARMLAND].singular;
TChineseAbilityNames[AbilityNames.GAIN_CHEAPER] = "獲得一張價值較低的";
TChineseAbilityNames[AbilityNames.GAIN_TWO_RUINS] = TChineseCardNames[CardNames.DEATH_CART].singular;
TChineseAbilityNames[AbilityNames.HUNTING_GROUNDS] = TChineseCardNames[CardNames.HUNTING_GROUNDS].singular;
TChineseAbilityNames[AbilityNames.SQUIRE_GAIN_ATTACK] = TChineseCardNames[CardNames.SQUIRE].singular;
TChineseAbilityNames[AbilityNames.URCHIN_TRANSFORM] = TChineseCardNames[CardNames.URCHIN].singular;
TChineseAbilityNames[AbilityNames.FEODUM_GAIN_SILVERS] = TChineseCardNames[CardNames.FEODUM].singular;
TChineseAbilityNames[AbilityNames.DOCTOR_OVERPAY] = "對醫生超額支付";
TChineseAbilityNames[AbilityNames.RESOLVE_DOCTOR_OVERPAY] = TChineseCardNames[CardNames.DOCTOR].singular;
TChineseAbilityNames[AbilityNames.HERALD_OVERPAY] = "對傳令官超額支付";
TChineseAbilityNames[AbilityNames.RESOLVE_HERALD_OVERPAY] = TChineseCardNames[CardNames.HERALD].singular;
TChineseAbilityNames[AbilityNames.MASTERPIECE_OVERPAY] = "對傑作超額支付";
TChineseAbilityNames[AbilityNames.RESOLVE_MASTERPIECE_OVERPAY] = TChineseCardNames[CardNames.MASTERPIECE].singular;
TChineseAbilityNames[AbilityNames.STONEMASON_OVERPAY] = "對石匠超額支付";
TChineseAbilityNames[AbilityNames.RESOLVE_STONEMASON_OVERPAY] = TChineseCardNames[CardNames.STONEMASON].singular;
TChineseAbilityNames[AbilityNames.EXCHANGE] = "交換";
TChineseAbilityNames[AbilityNames.SHUFFLE] = "洗牌";
TChineseAbilityNames[AbilityNames.AMULET] = TChineseCardNames[CardNames.AMULET].singular;
TChineseAbilityNames[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = TChineseCardNames[CardNames.CARAVAN_GUARD].singular;
TChineseAbilityNames[AbilityNames.DUNGEON_ABILITY] = TChineseCardNames[CardNames.DUNGEON].singular;
TChineseAbilityNames[AbilityNames.GEAR_ABILITY] = TChineseCardNames[CardNames.GEAR].singular;
TChineseAbilityNames[AbilityNames.MESSENGER_GAIN] = TChineseCardNames[CardNames.MESSENGER].singular;
TChineseAbilityNames[AbilityNames.BRIDGE_TROLL_ABILITY] = TChineseCardNames[CardNames.BRIDGE_TROLL].singular;
TChineseAbilityNames[AbilityNames.HAUNTED_WOODS_DURATION] = "恐懼叢林的抽卡效果";
TChineseAbilityNames[AbilityNames.HAUNTED_WOODS_TOPDECK] = "因為恐懼叢林而將卡片放回牌庫頂";
TChineseAbilityNames[AbilityNames.LOST_CITY] = TChineseCardNames[CardNames.LOST_CITY].singular;
TChineseAbilityNames[AbilityNames.WINE_MERCHANT_DISCARD] = TChineseCardNames[CardNames.WINE_MERCHANT].singular;
TChineseAbilityNames[AbilityNames.HIRELING] = TChineseCardNames[CardNames.HIRELING].singular;
TChineseAbilityNames[AbilityNames.SWAMP_HAG_DURATION] = TChineseCardNames[CardNames.SWAMP_HAG].singular;
TChineseAbilityNames[AbilityNames.TRASH_TOKEN_ABILITY] = TChineseCardNames[CardNames.PLAN].singular;
TChineseAbilityNames[AbilityNames.EMPORIUM_GAIN_POINTS] = TChineseCardNames[CardNames.EMPORIUM].singular;
TChineseAbilityNames[AbilityNames.ROCKS_GAINS_SILVER] = TChineseCardNames[CardNames.ROCKS].singular;
TChineseAbilityNames[AbilityNames.FORTUNE] = TChineseCardNames[CardNames.FORTUNE].singular;
TChineseAbilityNames[AbilityNames.CRUMBLING_CASTLE] = TChineseCardNames[CardNames.CRUMBLING_CASTLE].singular;
TChineseAbilityNames[AbilityNames.HAUNTED_CASTLE] = TChineseCardNames[CardNames.HAUNTED_CASTLE].singular;
TChineseAbilityNames[AbilityNames.SPRAWLING_CASTLE] = TChineseCardNames[CardNames.SPRAWLING_CASTLE].singular;
TChineseAbilityNames[AbilityNames.GRAND_CASTLE] = TChineseCardNames[CardNames.GRAND_CASTLE].singular;
TChineseAbilityNames[AbilityNames.ENCHANTRESS_ABILITY] = TChineseCardNames[CardNames.ENCHANTRESS].singular;
TChineseAbilityNames[AbilityNames.ENCHANTRESS_CANTRIP] = "+1 卡片, +1 行動";
TChineseAbilityNames[AbilityNames.TEMPLE] = TChineseCardNames[CardNames.TEMPLE].singular;
TChineseAbilityNames[AbilityNames.VILLA] = TChineseCardNames[CardNames.VILLA].singular;
TChineseAbilityNames[AbilityNames.ARCHIVE_ABILITY] = TChineseCardNames[CardNames.ARCHIVE].singular;
TChineseAbilityNames[AbilityNames.CAPITAL_TAKE_DEBT] = TChineseCardNames[CardNames.CAPITAL].singular;
TChineseAbilityNames[AbilityNames.CHARM] = TChineseCardNames[CardNames.CHARM].singular;
TChineseAbilityNames[AbilityNames.FORUM] = TChineseCardNames[CardNames.FORUM].singular;
TChineseAbilityNames[AbilityNames.HERMIT] = TChineseCardNames[CardNames.HERMIT].singular;
TChineseAbilityNames[AbilityNames.DONATE] = TChineseCardNames[CardNames.DONATE].singular;
TChineseAbilityNames[AbilityNames.TAX_RECEIVE] = TChineseCardNames[CardNames.TAX].singular;
TChineseAbilityNames[AbilityNames.DOMINATE] = TChineseCardNames[CardNames.DOMINATE].singular;
TChineseAbilityNames[AbilityNames.VP_TO_AQUEDUCT] = "將分數放到引水渠";
TChineseAbilityNames[AbilityNames.VP_FROM_AQUEDUCT] = "來自引水渠的分數";
TChineseAbilityNames[AbilityNames.ARENA_DISCARD_ACTION] = TChineseCardNames[CardNames.ARENA].singular;
TChineseAbilityNames[AbilityNames.VP_FROM_BASILICA] = TChineseCardNames[CardNames.BASILICA].singular;
TChineseAbilityNames[AbilityNames.VP_FROM_BATHS] = TChineseCardNames[CardNames.BATHS].singular;
TChineseAbilityNames[AbilityNames.VP_FROM_BATTLEFIELD] = TChineseCardNames[CardNames.BATTLEFIELD].singular;
TChineseAbilityNames[AbilityNames.VP_FROM_COLONNADE] = TChineseCardNames[CardNames.COLONNADE].singular;
TChineseAbilityNames[AbilityNames.VP_TO_DEFILED_SHRINE] = "將分數放到髒亂的寺廟";
TChineseAbilityNames[AbilityNames.VP_FROM_DEFILED_SHRINE] = "來自髒亂的寺廟的分數";
TChineseAbilityNames[AbilityNames.VP_FROM_LABYRINTH] = TChineseCardNames[CardNames.LABYRINTH].singular;
TChineseAbilityNames[AbilityNames.MOUNTAIN_PASS_BIDDING] = TChineseCardNames[CardNames.MOUNTAIN_PASS].singular;
TChineseAbilityNames[AbilityNames.TOMB] = TChineseCardNames[CardNames.TOMB].singular;
TChineseAbilityNames[AbilityNames.LIGHTHOUSE_COIN] = TChineseCardNames[CardNames.LIGHTHOUSE].singular;
TChineseAbilityNames[AbilityNames.REACTION] = "應對";
TChineseAbilityNames[AbilityNames.CALL_GUIDE] = "呼叫 " + TChineseCardNames[CardNames.GUIDE].singular;
TChineseAbilityNames[AbilityNames.CALL_RATCATCHER] = "呼叫 " + TChineseCardNames[CardNames.RATCATCHER].singular;
TChineseAbilityNames[AbilityNames.CALL_TRANSMOGRIFY] = "呼叫 " + TChineseCardNames[CardNames.TRANSMOGRIFY].singular;
TChineseAbilityNames[AbilityNames.CALL_TEACHER] = "呼叫 " + TChineseCardNames[CardNames.TEACHER].singular;
TChineseAbilityNames[AbilityNames.CALL_DUPLICATE] = "呼叫 " + TChineseCardNames[CardNames.DUPLICATE].singular;
TChineseAbilityNames[AbilityNames.CALL_COIN] = "呼叫 " + TChineseCardNames[CardNames.COIN_OF_THE_REALM].singular;
TChineseAbilityNames[AbilityNames.CALL_ROYAL_CARRIAGE] = "呼叫 " + TChineseCardNames[CardNames.ROYAL_CARRIAGE].singular;
TChineseAbilityNames[AbilityNames.START_OF_TURN] = "開始你的回合";
TChineseAbilityNames[AbilityNames.START_BUY_PHASE] = "開始購買階段";
TChineseAbilityNames[AbilityNames.END_OF_BUY_PHASE] = "結束購買階段";
TChineseAbilityNames[AbilityNames.BETWEEN_TURNS] = "回合間";
TChineseAbilityNames[AbilityNames.END_OF_TURN] = "回合結束";
TChineseAbilityNames[AbilityNames.SUMMON_PLAY] = TChineseCardNames[CardNames.SUMMON].singular;
TChineseAbilityNames[AbilityNames.CARAVAN_GUARD_REACTION] = TChineseCardNames[CardNames.CARAVAN_GUARD].singular;
TChineseAbilityNames[AbilityNames.TRAVELLING_FAIR_REACTION] = TChineseCardNames[CardNames.TRAVELLING_FAIR].singular;
TChineseAbilityNames[AbilityNames.MOAT_REACTION] = TChineseCardNames[CardNames.MOAT].singular;
TChineseAbilityNames[AbilityNames.HORSE_TRADERS_REACTION] = TChineseCardNames[CardNames.HORSE_TRADERS].singular;
TChineseAbilityNames[AbilityNames.BEGGAR_REACTION] = TChineseCardNames[CardNames.BEGGAR].singular;
TChineseAbilityNames[AbilityNames.MARKET_SQUARE_REACTION] = TChineseCardNames[CardNames.MARKET_SQUARE].singular;
TChineseAbilityNames[AbilityNames.TRADER_REACTION] = TChineseCardNames[CardNames.TRADER].singular;
TChineseAbilityNames[AbilityNames.DIPLOMAT_REACTION] = TChineseCardNames[CardNames.DIPLOMAT].singular;
TChineseAbilityNames[AbilityNames.TOPDECK_REACTION] = "放到牌庫頂";
TChineseAbilityNames[AbilityNames.WATCHTOWER_TRASH_REACTION] = "移除";
TChineseAbilityNames[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "放到牌庫頂";
TChineseAbilityNames[AbilityNames.PRINCE_PLAY] = TChineseCardNames[CardNames.PRINCE].singular;
TChineseAbilityNames[AbilityNames.PRINCE_SET_ASIDE] = TChineseCardNames[CardNames.PRINCE].singular;
TChineseAbilityNames[AbilityNames.DELUDED_RETURNED] = "歸還狀態";
TChineseAbilityNames[AbilityNames.ENVIOUS_RETURNED] = "歸還狀態";
TChineseAbilityNames[AbilityNames.RECEIVE_BOON] = "收到祝福";
TChineseAbilityNames[AbilityNames.COBBLER_ABILITY] = TChineseCardNames[CardNames.COBBLER].singular;
TChineseAbilityNames[AbilityNames.CRYPT_ABILITY] = TChineseCardNames[CardNames.CRYPT].singular;
TChineseAbilityNames[AbilityNames.DEN_OF_SIN_ABILITY] = TChineseCardNames[CardNames.DEN_OF_SIN].singular;
TChineseAbilityNames[AbilityNames.FAITHFUL_HOUND] = TChineseCardNames[CardNames.FAITHFUL_HOUND].singular;
TChineseAbilityNames[AbilityNames.FAITHFUL_HOUND_RETURN] = TChineseCardNames[CardNames.FAITHFUL_HOUND].singular;
TChineseAbilityNames[AbilityNames.GHOST_TOWN_ABILITY] = TChineseCardNames[CardNames.GHOST_TOWN].singular;
TChineseAbilityNames[AbilityNames.GUARDIAN_COIN] = TChineseCardNames[CardNames.GUARDIAN].singular;
TChineseAbilityNames[AbilityNames.RAIDER_ABILITY] = TChineseCardNames[CardNames.RAIDER].singular;
TChineseAbilityNames[AbilityNames.SECRET_CAVE] = TChineseCardNames[CardNames.SECRET_CAVE].singular;
TChineseAbilityNames[AbilityNames.CEMETERY] = TChineseCardNames[CardNames.CEMETERY].singular;
TChineseAbilityNames[AbilityNames.HAUNTED_MIRROR] = TChineseCardNames[CardNames.HAUNTED_MIRROR].singular;
TChineseAbilityNames[AbilityNames.GHOST] = TChineseCardNames[CardNames.GHOST].singular;
TChineseAbilityNames[AbilityNames.REMOVE_FLIPPED_STATUS] = "X";
TChineseAbilityNames[AbilityNames.LOST_IN_THE_WOODS] = TChineseCardNames[CardNames.LOST_IN_THE_WOODS].singular;
TChineseAbilityNames[AbilityNames.BLESSED_VILLAGE] = TChineseCardNames[CardNames.BLESSED_VILLAGE].singular;
TChineseAbilityNames[AbilityNames.FORTRESS] = TChineseCardNames[CardNames.FORTRESS].singular;
TChineseAbilityNames[AbilityNames.RECEIVE_HEX] = "收到咒術";
TChineseAbilityNames[AbilityNames.HOVEL_TRASH] = TChineseCardNames[CardNames.HOVEL].singular;
TChineseAbilityNames[AbilityNames.TUNNEL_REVEAL] = TChineseCardNames[CardNames.TUNNEL].singular;
TChineseAbilityNames[AbilityNames.CHANGELING_MAY_EXCHANGE] = "跟 " + TChineseCardNames[CardNames.CHANGELING].singular + "交換";

TChineseAbilityNames[AbilityNames.DUCAT] = TChineseCardNames[CardNames.DUCAT].singular;
TChineseAbilityNames[AbilityNames.CARGO_SHIP_SET_ASIDE] = TChineseCardNames[CardNames.CARGO_SHIP].singular;
TChineseAbilityNames[AbilityNames.CARGO_SHIP_RETURN] = TChineseCardNames[CardNames.CARGO_SHIP].singular;
TChineseAbilityNames[AbilityNames.IMPROVE] = TChineseCardNames[CardNames.IMPROVE].singular;
TChineseAbilityNames[AbilityNames.PRIEST] = TChineseCardNames[CardNames.PRIEST].singular;
TChineseAbilityNames[AbilityNames.RESEARCH] = TChineseCardNames[CardNames.RESEARCH].singular;
TChineseAbilityNames[AbilityNames.SILK_MERCHANT] = TChineseCardNames[CardNames.SILK_MERCHANT].singular;
TChineseAbilityNames[AbilityNames.LACKEYS] = TChineseCardNames[CardNames.LACKEYS].singular;
TChineseAbilityNames[AbilityNames.SPICES] = TChineseCardNames[CardNames.SPICES].singular;
TChineseAbilityNames[AbilityNames.KEY] = TChineseCardNames[CardNames.KEY].singular;
TChineseAbilityNames[AbilityNames.TREASURE_CHEST] = TChineseCardNames[CardNames.TREASURE_CHEST].singular;
TChineseAbilityNames[AbilityNames.FLAG_BEARER] = TChineseCardNames[CardNames.FLAG_BEARER].singular;
TChineseAbilityNames[AbilityNames.CATHEDRAL] = TChineseCardNames[CardNames.CATHEDRAL].singular;
TChineseAbilityNames[AbilityNames.CITY_GATE] = TChineseCardNames[CardNames.CITY_GATE].singular;
TChineseAbilityNames[AbilityNames.PAGEANT] = TChineseCardNames[CardNames.PAGEANT].singular;
TChineseAbilityNames[AbilityNames.SEWERS_MAY_TRASH] = TChineseCardNames[CardNames.SEWERS].singular;
TChineseAbilityNames[AbilityNames.EXPLORATION] = TChineseCardNames[CardNames.EXPLORATION].singular;
TChineseAbilityNames[AbilityNames.FAIR] = TChineseCardNames[CardNames.FAIR].singular;
TChineseAbilityNames[AbilityNames.SILOS] = TChineseCardNames[CardNames.SILOS].singular;
TChineseAbilityNames[AbilityNames.ACADEMY] = TChineseCardNames[CardNames.ACADEMY].singular;
TChineseAbilityNames[AbilityNames.GUILDHALL] = TChineseCardNames[CardNames.GUILDHALL].singular;
TChineseAbilityNames[AbilityNames.PIAZZA] = TChineseCardNames[CardNames.PIAZZA].singular;
TChineseAbilityNames[AbilityNames.BARRACKS] = TChineseCardNames[CardNames.BARRACKS].singular;
TChineseAbilityNames[AbilityNames.CROP_ROTATION] = TChineseCardNames[CardNames.CROP_ROTATION].singular;
TChineseAbilityNames[AbilityNames.INNOVATION] = TChineseCardNames[CardNames.INNOVATION].singular;
TChineseAbilityNames[AbilityNames.CITADEL] = TChineseCardNames[CardNames.CITADEL].singular;
TChineseAbilityNames[AbilityNames.SINISTER_PLOT] = TChineseCardNames[CardNames.SINISTER_PLOT].singular;
TChineseAbilityNames[AbilityNames.ENCAMPMENT_RETURNED] = TChineseCardNames[CardNames.ENCAMPMENT].singular;
TChineseAbilityNames[AbilityNames.EMBARGO_GAIN_CURSE] = TChineseCardNames[CardNames.EMBARGO].singular;
TChineseAbilityNames[AbilityNames.POSSESSION_GAIN_INSTEAD] = TChineseCardNames[CardNames.POSSESSION].singular;
TChineseAbilityNames[AbilityNames.TRADE_ROUTE_MOVE_TOKEN] = TChineseCardNames[CardNames.TRADE_ROUTE].singular;

TChineseAbilityNames[AbilityNames.CAPTAIN] = TChineseCardNames[CardNames.CAPTAIN].singular;
TChineseAbilityNames[AbilityNames.CHURCH] = TChineseCardNames[CardNames.CHURCH].singular;
TChineseAbilityNames[AbilityNames.POSSESSION_SETS_ASIDE] = TChineseCardNames[CardNames.POSSESSION].singular;
TChineseAbilityNames[AbilityNames.MINT] = TChineseCardNames[CardNames.MINT].singular;

TChineseAbilityNames[AbilityNames.SLEIGH_TOPDECK_REACTION] = "放到牌庫頂";
TChineseAbilityNames[AbilityNames.SLEIGH_PUT_IN_HAND_REACTION] = "加入手中";
TChineseAbilityNames[AbilityNames.BLACK_CAT_REACTION] = TChineseCardNames[CardNames.BLACK_CAT].singular;
TChineseAbilityNames[AbilityNames.DISCARD_FROM_EXILE] = "將被驅逐的卡片棄掉";
TChineseAbilityNames[AbilityNames.EXILE_BY_NAME] = "驅逐";
TChineseAbilityNames[AbilityNames.SHEEPDOG_REACTION] = TChineseCardNames[CardNames.SHEEPDOG].singular;
TChineseAbilityNames[AbilityNames.CAVALRY] = TChineseCardNames[CardNames.CAVALRY].singular;
TChineseAbilityNames[AbilityNames.HOSTELRY] = TChineseCardNames[CardNames.HOSTELRY].singular;
TChineseAbilityNames[AbilityNames.VILLAGE_GREEN_VILLAGE] = TChineseCardNames[CardNames.VILLAGE_GREEN].singular;
TChineseAbilityNames[AbilityNames.VILLAGE_GREEN_PLAY] = TChineseCardNames[CardNames.VILLAGE_GREEN].singular;
TChineseAbilityNames[AbilityNames.BARGE] = TChineseCardNames[CardNames.BARGE].singular;
TChineseAbilityNames[AbilityNames.FALCONER_REACTION] = TChineseCardNames[CardNames.FALCONER].singular;
TChineseAbilityNames[AbilityNames.GATEKEEPER_EXILE] = TChineseCardNames[CardNames.GATEKEEPER].singular;
TChineseAbilityNames[AbilityNames.GATEKEEPER_DURATION] = TChineseCardNames[CardNames.GATEKEEPER].singular;
TChineseAbilityNames[AbilityNames.LIVERY] = TChineseCardNames[CardNames.LIVERY].singular;
TChineseAbilityNames[AbilityNames.MASTERMIND] = TChineseCardNames[CardNames.MASTERMIND].singular;
TChineseAbilityNames[AbilityNames.DELAY_PLAY] = TChineseCardNames[CardNames.DELAY].singular;
TChineseAbilityNames[AbilityNames.INVEST] = TChineseCardNames[CardNames.INVEST].singular;
TChineseAbilityNames[AbilityNames.REAP_PLAY] = TChineseCardNames[CardNames.REAP].singular;
TChineseAbilityNames[AbilityNames.PLUS_CARD_TOKEN] = "+卡片";
TChineseAbilityNames[AbilityNames.PLUS_BUY_TOKEN] = "+購買";
TChineseAbilityNames[AbilityNames.PLUS_ACTION_TOKEN] = "+行動";
TChineseAbilityNames[AbilityNames.PLUS_COIN_TOKEN] = "+預算";
TChineseAbilityNames[AbilityNames.KILN] = TChineseCardNames[CardNames.KILN].singular;
TChineseAbilityNames[AbilityNames.TURTLE_PLAY] = TChineseCardNames[CardNames.WAY_OF_THE_TURTLE].singular;
TChineseAbilityNames[AbilityNames.GIVE_PLUS_CARDS] = TChineseAbilityNames[AbilityNames.PLUS_CARD_TOKEN];
TChineseAbilityNames[AbilityNames.GIVE_PLUS_BUYS] = TChineseAbilityNames[AbilityNames.PLUS_BUY_TOKEN];
TChineseAbilityNames[AbilityNames.GIVE_PLUS_ACTIONS] = TChineseAbilityNames[AbilityNames.PLUS_ACTION_TOKEN];
TChineseAbilityNames[AbilityNames.GIVE_PLUS_COINS] = TChineseAbilityNames[AbilityNames.PLUS_COIN_TOKEN];

// Allies
TChineseAbilityNames[AbilityNames.CONJURER] = TChineseCardNames[CardNames.CONJURER].singular;
TChineseAbilityNames[AbilityNames.CONTRACT_ABILITY] = TChineseCardNames[CardNames.CONTRACT].singular;
TChineseAbilityNames[AbilityNames.ENCHANTRESS_REPLACE_ABILITY] = TChineseCardNames[CardNames.ENCHANTRESS].singular;
TChineseAbilityNames[AbilityNames.GALLERIA] = TChineseCardNames[CardNames.GALLERIA].singular;
TChineseAbilityNames[AbilityNames.GARRISON_ADD_TOKEN] = TChineseCardNames[CardNames.GARRISON].singular;
TChineseAbilityNames[AbilityNames.GARRISON_REMOVE_ABILITY] = TChineseCardNames[CardNames.GARRISON].singular;
TChineseAbilityNames[AbilityNames.GUILDMASTER] = TChineseCardNames[CardNames.GUILDMASTER].singular;
TChineseAbilityNames[AbilityNames.HIGHWAYMAN_NEXT_TURN_ABILITY] = TChineseCardNames[CardNames.HIGHWAYMAN].singular;
TChineseAbilityNames[AbilityNames.HIGHWAYMAN_NULLIFIED] = TChineseCardNames[CardNames.HIGHWAYMAN].singular;
TChineseAbilityNames[AbilityNames.HIGHWAYMAN_REPLACE_ABILITY] = TChineseCardNames[CardNames.HIGHWAYMAN].singular;
TChineseAbilityNames[AbilityNames.IMPORTER_ABILITY] = TChineseCardNames[CardNames.IMPORTER].singular;
TChineseAbilityNames[AbilityNames.LICH] = TChineseCardNames[CardNames.LICH].singular;
TChineseAbilityNames[AbilityNames.ROYAL_GALLEY_PLAY] = TChineseCardNames[CardNames.ROYAL_GALLEY].singular;
TChineseAbilityNames[AbilityNames.ROYAL_GALLEY_SET_ASIDE] = TChineseCardNames[CardNames.ROYAL_GALLEY].singular;
TChineseAbilityNames[AbilityNames.SKIRMISHER] = TChineseCardNames[CardNames.SKIRMISHER].singular;
TChineseAbilityNames[AbilityNames.STRONGHOLD_ABILITY] = TChineseCardNames[CardNames.STRONGHOLD].singular;
TChineseAbilityNames[AbilityNames.SYCOPHANT] = TChineseCardNames[CardNames.SYCOPHANT].singular;
TChineseAbilityNames[AbilityNames.TERRITORY_GAIN] = TChineseCardNames[CardNames.TERRITORY].singular;
TChineseAbilityNames[AbilityNames.WARLORD_ABILITY] = TChineseCardNames[CardNames.WARLORD].singular;

TChineseAbilityNames[AbilityNames.ARCHITECTS_GUILD] = TChineseCardNames[CardNames.ARCHITECTS_GUILD].singular;
TChineseAbilityNames[AbilityNames.BAND_OF_NOMADS_ACTION] = "行動";
TChineseAbilityNames[AbilityNames.BAND_OF_NOMADS_BUY] = "購買";
TChineseAbilityNames[AbilityNames.BAND_OF_NOMADS_CARD] = "卡片";
TChineseAbilityNames[AbilityNames.CAVE_DWELLERS] = TChineseCardNames[CardNames.CAVE_DWELLERS].singular;
TChineseAbilityNames[AbilityNames.CIRCLE_OF_WITCHES] = TChineseCardNames[CardNames.CIRCLE_OF_WITCHES].singular;
TChineseAbilityNames[AbilityNames.CITY_STATE] = TChineseCardNames[CardNames.CITY_STATE].singular;
TChineseAbilityNames[AbilityNames.CITY_STATE_PLAY] = TChineseCardNames[CardNames.CITY_STATE].singular;
TChineseAbilityNames[AbilityNames.COASTAL_HAVEN] = TChineseCardNames[CardNames.COASTAL_HAVEN].singular;
TChineseAbilityNames[AbilityNames.CRAFTERS_GUILD] = TChineseCardNames[CardNames.CRAFTERS_GUILD].singular;
TChineseAbilityNames[AbilityNames.DESERT_GUIDES] = TChineseCardNames[CardNames.DESERT_GUIDES].singular;
TChineseAbilityNames[AbilityNames.FAMILY_OF_INVENTORS] = TChineseCardNames[CardNames.FAMILY_OF_INVENTORS].singular;
TChineseAbilityNames[AbilityNames.FELLOWSHIP_OF_SCRIBES] = TChineseCardNames[CardNames.FELLOWSHIP_OF_SCRIBES].singular;
TChineseAbilityNames[AbilityNames.FOREST_DWELLERS] = TChineseCardNames[CardNames.FOREST_DWELLERS].singular;
TChineseAbilityNames[AbilityNames.GANG_OF_PICKPOCKETS] = TChineseCardNames[CardNames.GANG_OF_PICKPOCKETS].singular;
TChineseAbilityNames[AbilityNames.ISLAND_FOLK] = TChineseCardNames[CardNames.ISLAND_FOLK].singular;
TChineseAbilityNames[AbilityNames.LEAGUE_OF_BANKERS] = TChineseCardNames[CardNames.LEAGUE_OF_BANKERS].singular;
TChineseAbilityNames[AbilityNames.LEAGUE_OF_SHOPKEEPERS] = TChineseCardNames[CardNames.LEAGUE_OF_SHOPKEEPERS].singular;
TChineseAbilityNames[AbilityNames.MARKET_TOWNS] = TChineseCardNames[CardNames.MARKET_TOWNS].singular;
TChineseAbilityNames[AbilityNames.MOUNTAIN_FOLK] = TChineseCardNames[CardNames.MOUNTAIN_FOLK].singular;
TChineseAbilityNames[AbilityNames.PEACEFUL_CULT] = TChineseCardNames[CardNames.PEACEFUL_CULT].singular;
TChineseAbilityNames[AbilityNames.TRAPPERS_LODGE] = TChineseCardNames[CardNames.TRAPPERS_LODGE].singular;
TChineseAbilityNames[AbilityNames.WOODWORKERS_GUILD] = TChineseCardNames[CardNames.WOODWORKERS_GUILD].singular;
TChineseAbilityNames[AbilityNames.ASTROLABE_ABILITY] = TChineseCardNames[CardNames.ASTROLABE].singular;
TChineseAbilityNames[AbilityNames.BLOCKADE_ABILITY] = TChineseCardNames[CardNames.BLOCKADE].singular;
TChineseAbilityNames[AbilityNames.CORSAIR_NEXT_TURN_ABILITY] = TChineseCardNames[CardNames.CORSAIR].singular;
TChineseAbilityNames[AbilityNames.CORSAIR_TRASH] = TChineseCardNames[CardNames.CORSAIR].singular;
TChineseAbilityNames[AbilityNames.MONKEY_NEXT_TURN_ABILITY] = TChineseCardNames[CardNames.MONKEY].singular;
TChineseAbilityNames[AbilityNames.PIRATE_ABILITY] = TChineseCardNames[CardNames.PIRATE].singular;
TChineseAbilityNames[AbilityNames.PIRATE_REACTION] = TChineseCardNames[CardNames.PIRATE].singular;
TChineseAbilityNames[AbilityNames.SAILOR_NEXT_TURN] = TChineseCardNames[CardNames.SAILOR].singular;
TChineseAbilityNames[AbilityNames.SAILOR_REACTION] = TChineseCardNames[CardNames.SAILOR].singular;
TChineseAbilityNames[AbilityNames.SEA_WITCH_ABILITY] = TChineseCardNames[CardNames.SEA_WITCH].singular;
TChineseAbilityNames[AbilityNames.TIDE_POOLS_ABILITY] = TChineseCardNames[CardNames.TIDE_POOLS].singular;
TChineseAbilityNames[AbilityNames.TREASURY] = TChineseCardNames[CardNames.TREASURY].singular;
TChineseAbilityNames[AbilityNames.CLERK_ABILITY] = TChineseCardNames[CardNames.CLERK].singular;
TChineseAbilityNames[AbilityNames.BERSERKER] = TChineseCardNames[CardNames.BERSERKER].singular;
TChineseAbilityNames[AbilityNames.CAULDRON_CURSING] = TChineseCardNames[CardNames.CAULDRON].singular;
TChineseAbilityNames[AbilityNames.GUARD_DOG_REACTION] = TChineseCardNames[CardNames.GUARD_DOG].singular;
TChineseAbilityNames[AbilityNames.NOMADS] = TChineseCardNames[CardNames.NOMADS].singular;
TChineseAbilityNames[AbilityNames.SOUK] = TChineseCardNames[CardNames.SOUK].singular;
TChineseAbilityNames[AbilityNames.TRAIL_REACTION] = TChineseCardNames[CardNames.TRAIL].singular;
TChineseAbilityNames[AbilityNames.WEAVER_PLAY] = TChineseCardNames[CardNames.WEAVER].singular;
TChineseAbilityNames[AbilityNames.ALCHEMIST_TOPDECK] = TChineseCardNames[CardNames.ALCHEMIST].singular;

TChineseAbilityNames[AbilityNames.CAGE_TRASH] = TChineseCardNames[CardNames.CAGE].singular;
TChineseAbilityNames[AbilityNames.CAGE_PUT_IN_HAND] = TChineseCardNames[CardNames.CAGE].singular;
TChineseAbilityNames[AbilityNames.GROTTO] = TChineseCardNames[CardNames.GROTTO].singular;
TChineseAbilityNames[AbilityNames.JEWELLED_EGG_GAIN_LOOT] = TChineseCardNames[CardNames.JEWELLED_EGG].singular;
TChineseAbilityNames[AbilityNames.SEARCH] = TChineseCardNames[CardNames.SEARCH].singular;
TChineseAbilityNames[AbilityNames.SHAMAN] = TChineseCardNames[CardNames.SHAMAN].singular;
TChineseAbilityNames[AbilityNames.SECLUDED_SHRINE] = TChineseCardNames[CardNames.SECLUDED_SHRINE].singular;
TChineseAbilityNames[AbilityNames.SIREN_GAIN] = TChineseCardNames[CardNames.SIREN].singular;
TChineseAbilityNames[AbilityNames.SIREN_DURATION] = TChineseCardNames[CardNames.SIREN].singular;
TChineseAbilityNames[AbilityNames.STOWAWAY_DRAW] = TChineseCardNames[CardNames.STOWAWAY].singular;
TChineseAbilityNames[AbilityNames.STOWAWAY_REACTION] = TChineseCardNames[CardNames.STOWAWAY].singular;
TChineseAbilityNames[AbilityNames.TASKMASTER] = TChineseCardNames[CardNames.TASKMASTER].singular;
TChineseAbilityNames[AbilityNames.ABUNDANCE] = TChineseCardNames[CardNames.ABUNDANCE].singular;
TChineseAbilityNames[AbilityNames.CABIN_BOY] = TChineseCardNames[CardNames.CABIN_BOY].singular;
TChineseAbilityNames[AbilityNames.CRUCIBLE] = TChineseCardNames[CardNames.CRUCIBLE].singular;
TChineseAbilityNames[AbilityNames.FLAGSHIP_ACTIVATE] = TChineseCardNames[CardNames.FLAGSHIP].singular;
TChineseAbilityNames[AbilityNames.FLAGSHIP_REPLAY] = TChineseCardNames[CardNames.FLAGSHIP].singular;
TChineseAbilityNames[AbilityNames.GONDOLA_DURATION] = TChineseCardNames[CardNames.GONDOLA].singular;
TChineseAbilityNames[AbilityNames.GONDOLA_PLAY] = TChineseCardNames[CardNames.GONDOLA].singular;
TChineseAbilityNames[AbilityNames.HARBOR_VILLAGE] = TChineseCardNames[CardNames.HARBOR_VILLAGE].singular;
TChineseAbilityNames[AbilityNames.LANDING_PARTY] = TChineseCardNames[CardNames.LANDING_PARTY].singular;
TChineseAbilityNames[AbilityNames.MAPMAKER] = TChineseCardNames[CardNames.MAPMAKER].singular;
TChineseAbilityNames[AbilityNames.ROPE] = TChineseCardNames[CardNames.ROPE].singular;
TChineseAbilityNames[AbilityNames.BURIED_TREASURE_PLAY] = TChineseCardNames[CardNames.BURIED_TREASURE].singular;
TChineseAbilityNames[AbilityNames.BURIED_TREASURE_DURATION] = TChineseCardNames[CardNames.BURIED_TREASURE].singular;
TChineseAbilityNames[AbilityNames.CREW_DURATION] = TChineseCardNames[CardNames.CREW].singular;
TChineseAbilityNames[AbilityNames.CUTTHROAT_GAIN] = TChineseCardNames[CardNames.CUTTHROAT].singular;
TChineseAbilityNames[AbilityNames.ENLARGE] = TChineseCardNames[CardNames.ENLARGE].singular;
TChineseAbilityNames[AbilityNames.FRIGATE_DURATION] = TChineseCardNames[CardNames.FRIGATE].singular;
TChineseAbilityNames[AbilityNames.FRIGATE_DISCARD] = TChineseCardNames[CardNames.FRIGATE].singular;
TChineseAbilityNames[AbilityNames.LONGSHIP_DURATION] = TChineseCardNames[CardNames.LONGSHIP].singular;
TChineseAbilityNames[AbilityNames.MINING_ROAD] = TChineseCardNames[CardNames.MINING_ROAD].singular;
TChineseAbilityNames[AbilityNames.QUARTERMASTER] = TChineseCardNames[CardNames.QUARTERMASTER].singular;
TChineseAbilityNames[AbilityNames.TRICKSTER_REACTION] = TChineseCardNames[CardNames.TRICKSTER].singular;
TChineseAbilityNames[AbilityNames.TRICKSTER_RETURN_TO_HAND] = TChineseCardNames[CardNames.TRICKSTER].singular;
TChineseAbilityNames[AbilityNames.WEALTHY_VILLAGE] = TChineseCardNames[CardNames.WEALTHY_VILLAGE].singular;
TChineseAbilityNames[AbilityNames.DELIVER_SET_ASIDE] = TChineseCardNames[CardNames.DELIVER].singular;
TChineseAbilityNames[AbilityNames.DELIVER_RETURN] = TChineseCardNames[CardNames.DELIVER].singular;
TChineseAbilityNames[AbilityNames.RUSH] = TChineseCardNames[CardNames.RUSH].singular;
TChineseAbilityNames[AbilityNames.MIRROR] = TChineseCardNames[CardNames.MIRROR].singular;
TChineseAbilityNames[AbilityNames.PREPARE] = TChineseCardNames[CardNames.PREPARE].singular;
TChineseAbilityNames[AbilityNames.AMPHORA] = TChineseCardNames[CardNames.AMPHORA].singular;
TChineseAbilityNames[AbilityNames.DOUBLOONS] = TChineseCardNames[CardNames.DOUBLOONS].singular;
TChineseAbilityNames[AbilityNames.ENDLESS_CHALICE] = TChineseCardNames[CardNames.ENDLESS_CHALICE].singular;
TChineseAbilityNames[AbilityNames.FIGUREHEAD] = TChineseCardNames[CardNames.FIGUREHEAD].singular;
TChineseAbilityNames[AbilityNames.JEWELS] = TChineseCardNames[CardNames.JEWELS].singular;
TChineseAbilityNames[AbilityNames.PUZZLE_BOX_RETURN] = TChineseCardNames[CardNames.PUZZLE_BOX].singular;
TChineseAbilityNames[AbilityNames.SHIELD_REACTION] = TChineseCardNames[CardNames.SHIELD].singular;
TChineseAbilityNames[AbilityNames.SPELL_SCROLL] = TChineseCardNames[CardNames.SPELL_SCROLL].singular;
TChineseAbilityNames[AbilityNames.CURSED_GAIN] = TChineseCardNames[CardNames.CURSED].singular;
TChineseAbilityNames[AbilityNames.FAWNING_GAIN] = TChineseCardNames[CardNames.FAWNING].singular;
TChineseAbilityNames[AbilityNames.SCHEDULER] = TChineseCardNames[CardNames.FRIENDLY].singular;
TChineseAbilityNames[AbilityNames.FRIENDLY_ABILITY] = TChineseCardNames[CardNames.FRIENDLY].singular;
TChineseAbilityNames[AbilityNames.HASTY_SET_ASIDE] = TChineseCardNames[CardNames.HASTY].singular;
TChineseAbilityNames[AbilityNames.HASTY_PLAY] = TChineseCardNames[CardNames.HASTY].singular;
TChineseAbilityNames[AbilityNames.INHERITED] = TChineseCardNames[CardNames.INHERITED].singular;
TChineseAbilityNames[AbilityNames.INSPIRING] = TChineseCardNames[CardNames.INSPIRING].singular;
TChineseAbilityNames[AbilityNames.NEARBY] = TChineseCardNames[CardNames.NEARBY].singular;
TChineseAbilityNames[AbilityNames.PATIENT_SCHEDULER] = TChineseCardNames[CardNames.PATIENT].singular;
TChineseAbilityNames[AbilityNames.PATIENT_SET_ASIDE] = TChineseCardNames[CardNames.PATIENT].singular;
TChineseAbilityNames[AbilityNames.PATIENT_PLAY] = TChineseCardNames[CardNames.PATIENT].singular;
TChineseAbilityNames[AbilityNames.PIOUS] = TChineseCardNames[CardNames.PIOUS].singular;
TChineseAbilityNames[AbilityNames.RECKLESS_REPEAT] = TChineseCardNames[CardNames.RECKLESS].singular;
TChineseAbilityNames[AbilityNames.RECKLESS_RETURN] = TChineseCardNames[CardNames.RECKLESS].singular;
TChineseAbilityNames[AbilityNames.RICH_GAIN] = TChineseCardNames[CardNames.RICH].singular;
TChineseAbilityNames[AbilityNames.SHY] = TChineseCardNames[CardNames.SHY].singular;
TChineseAbilityNames[AbilityNames.TIRELESS_SET_ASIDE] = TChineseCardNames[CardNames.TIRELESS].singular;
TChineseAbilityNames[AbilityNames.TIRELESS_RETURN] = TChineseCardNames[CardNames.TIRELESS].singular;
TChineseAbilityNames[AbilityNames.STAR_CHART] = TChineseCardNames[CardNames.STAR_CHART].singular;
TChineseAbilityNames[AbilityNames.ORDER_OF_ASTROLOGERS] = TChineseCardNames[CardNames.ORDER_OF_ASTROLOGERS].singular;
TChineseAbilityNames[AbilityNames.ORDER_OF_MASONS] = TChineseCardNames[CardNames.ORDER_OF_MASONS].singular;
TChineseAbilityNames[AbilityNames.FATED] = TChineseCardNames[CardNames.FATED].singular;
TChineseAbilityNames[AbilityNames.AVOID] = TChineseCardNames[CardNames.AVOID].singular;
TChineseAbilityNames[AbilityNames.HERBALIST_ASK_FOR_DISCARD] = TChineseCardNames[CardNames.HERBALIST].singular;
TChineseAbilityNames[AbilityNames.SCHEME_ASK_FOR_DISCARD] = TChineseCardNames[CardNames.SCHEME].singular;
TChineseAbilityNames[AbilityNames.TRICKSTER_ASK_FOR_DISCARD] = TChineseCardNames[CardNames.TRICKSTER].singular;

TChineseAbilityNames[AbilityNames.SAUNA_TRASH_ABILITY] = TChineseCardNames[CardNames.SAUNA].singular;
TChineseAbilityNames[AbilityNames.CHAMELEON_RESOLUTION] = TChineseCardNames[CardNames.WAY_OF_THE_CHAMELEON].singular;
TChineseAbilityNames[AbilityNames.MERCHANT_GUILD] = TChineseCardNames[CardNames.MERCHANT_GUILD].singular;
TChineseAbilityNames[AbilityNames.HERBALIST_TOPDECK] = TChineseCardNames[CardNames.HERBALIST].singular;
TChineseAbilityNames[AbilityNames.SCHEME_TOPDECK] = TChineseCardNames[CardNames.SCHEME].singular;
TChineseAbilityNames[AbilityNames.FROG_TOPDECK] = TChineseCardNames[CardNames.WAY_OF_THE_FROG].singular;
TChineseAbilityNames[AbilityNames.MERCHANT_CAMP_TOPDECK] = TChineseCardNames[CardNames.MERCHANT_CAMP].singular;
TChineseAbilityNames[AbilityNames.WALLED_VILLAGE_TOPDECK] = TChineseCardNames[CardNames.WALLED_VILLAGE].singular;
TChineseAbilityNames[AbilityNames.TENT_TOPDECK] = TChineseCardNames[CardNames.TENT].singular;
TChineseAbilityNames[AbilityNames.HORN_TOPDECK] = TChineseCardNames[CardNames.HORN].singular;
TChineseAbilityNames[AbilityNames.PAGE_EXCHANGE] = TChineseCardNames[CardNames.TREASURE_HUNTER].singular;
TChineseAbilityNames[AbilityNames.TREASURE_HUNTER_EXCHANGE] = TChineseCardNames[CardNames.WARRIOR].singular;
TChineseAbilityNames[AbilityNames.WARRIOR_EXCHANGE] = TChineseCardNames[CardNames.HERO].singular;
TChineseAbilityNames[AbilityNames.HERO_EXCHANGE] = TChineseCardNames[CardNames.CHAMPION].singular;
TChineseAbilityNames[AbilityNames.PEASANT_EXCHANGE] = TChineseCardNames[CardNames.SOLDIER].singular;
TChineseAbilityNames[AbilityNames.SOLDIER_EXCHANGE] = TChineseCardNames[CardNames.FUGITIVE].singular;
TChineseAbilityNames[AbilityNames.FUGITIVE_EXCHANGE] = TChineseCardNames[CardNames.DISCIPLE].singular;
TChineseAbilityNames[AbilityNames.DISCIPLE_EXCHANGE] = TChineseCardNames[CardNames.TEACHER].singular;
TChineseAbilityNames[AbilityNames.MARCHLAND] = TChineseCardNames[CardNames.MARCHLAND].singular;
TChineseAbilityNames[AbilityNames.FARMHANDS_SET_ASIDE] = TChineseCardNames[CardNames.FARMHANDS].singular;
TChineseAbilityNames[AbilityNames.FARMHANDS_PLAY] = TChineseCardNames[CardNames.FARMHANDS].singular;
TChineseAbilityNames[AbilityNames.JOUST_DISCARD] = TChineseCardNames[CardNames.JOUST].singular;
TChineseAbilityNames[AbilityNames.INFIRMARY_OVERPAY] = TChineseCardNames[CardNames.INFIRMARY].singular;
TChineseAbilityNames[AbilityNames.RESOLVE_INFIRMARY_OVERPAY] = TChineseCardNames[CardNames.INFIRMARY].singular;
TChineseAbilityNames[AbilityNames.DRAW_FROM_FOOTPAD] = TChineseCardNames[CardNames.FOOTPAD].singular;
TChineseAbilityNames[AbilityNames.FARRIER_OVERPAY] = TChineseCardNames[CardNames.FARRIER].singular;
TChineseAbilityNames[AbilityNames.RESOLVE_FARRIER_OVERPAY] = TChineseCardNames[CardNames.FARRIER].singular;
TChineseAbilityNames[AbilityNames.DRAW_FROM_SQUIRREL] = TChineseCardNames[CardNames.WAY_OF_THE_SQUIRREL].singular;
TChineseAbilityNames[AbilityNames.DRAW_FROM_RIVER] = TChineseCardNames[CardNames.THE_RIVERS_GIFT].singular;
TChineseAbilityNames[AbilityNames.DRAW_FROM_FARRIER] = TChineseCardNames[CardNames.FARRIER].singular;
TChineseAbilityNames[AbilityNames.FERRYMAN] = TChineseCardNames[CardNames.FERRYMAN].singular;

TChineseAbilityNames[AbilityNames.BIDING_TIME_SET_ASIDE_HAND] = TChineseCardNames[CardNames.BIDING_TIME].singular;
TChineseAbilityNames[AbilityNames.BIDING_TIME_RETURN_TO_HAND] = TChineseCardNames[CardNames.BIDING_TIME].singular;
TChineseAbilityNames[AbilityNames.ENLIGHTENMENT_CANTRIP] = TChineseCardNames[CardNames.ENLIGHTENMENT].singular;
TChineseAbilityNames[AbilityNames.ENLIGHTENMENT_REPLACE_ABILITY] = TChineseCardNames[CardNames.ENLIGHTENMENT].singular;
TChineseAbilityNames[AbilityNames.GOOD_HARVEST] = TChineseCardNames[CardNames.GOOD_HARVEST].singular;
TChineseAbilityNames[AbilityNames.HARSH_WINTER] = TChineseCardNames[CardNames.HARSH_WINTER].singular;
TChineseAbilityNames[AbilityNames.KIND_EMPEROR] = TChineseCardNames[CardNames.KIND_EMPEROR].singular;
TChineseAbilityNames[AbilityNames.PANIC_BUYS] = TChineseCardNames[CardNames.PANIC].singular;
TChineseAbilityNames[AbilityNames.PANIC_RETURN] = TChineseCardNames[CardNames.PANIC].singular;
TChineseAbilityNames[AbilityNames.PROGRESS] = TChineseCardNames[CardNames.PROGRESS].singular;
TChineseAbilityNames[AbilityNames.RAPID_EXPANSION_SET_ASIDE] = TChineseCardNames[CardNames.RAPID_EXPANSION].singular;
TChineseAbilityNames[AbilityNames.RAPID_EXPANSION_PLAY_LATER] = TChineseCardNames[CardNames.RAPID_EXPANSION].singular;
TChineseAbilityNames[AbilityNames.SICKNESS] = TChineseCardNames[CardNames.SICKNESS].singular;
TChineseAbilityNames[AbilityNames.RIVER_SHRINE] = TChineseCardNames[CardNames.RIVER_SHRINE].singular;
TChineseAbilityNames[AbilityNames.SAMURAI] = TChineseCardNames[CardNames.SAMURAI].singular;
TChineseAbilityNames[AbilityNames.DAIMYO_REPLAY] = TChineseCardNames[CardNames.DAIMYO].singular;
TChineseAbilityNames[AbilityNames.FORESIGHT_RETURN_TO_HAND] = TChineseCardNames[CardNames.FORESIGHT].singular;