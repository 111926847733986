"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var GameConcepts = {
    CHOICE_CARDS: new GameConcept([CardNames.STEWARD, CardNames.WISHING_WELL], [CardNames.STEWARD, CardNames.WISHING_WELL, CardNames.PAWN, CardNames.DUNGEON, CardNames.MERCHANT_SHIP, CardNames.CHAPEL, CardNames.VILLAGE, CardNames.SMITHY, CardNames.MARKET, CardNames.WITCH]),
    DURATIONS: new GameConcept([CardNames.GEAR, CardNames.FISHING_VILLAGE], [CardNames.CARAVAN, CardNames.FISHING_VILLAGE, CardNames.WHARF, CardNames.DUNGEON, CardNames.MERCHANT_SHIP, CardNames.CHAPEL, CardNames.VILLAGE, CardNames.SMITHY, CardNames.MARKET, CardNames.WITCH]),
    KINGDOM_TREASURES: new GameConcept([CardNames.TREASURE_TROVE, CardNames.WAR_CHEST], [CardNames.COUNTERFEIT, CardNames.LOAN, CardNames.TREASURE_TROVE], [CardNames.CHAPEL, CardNames.VILLAGE, CardNames.SMITHY, CardNames.MARKET, CardNames.WITCH]),
    PLATINUM_COLONY: new GameConcept([CardNames.PLATINUM, CardNames.COLONY], []),
    WHEN_GAIN_BUY: new GameConcept([CardNames.BORDER_VILLAGE, CardNames.HAGGLER], []),

    WHEN_TRASH: new GameConcept([CardNames.CATACOMBS, CardNames.FORTRESS], []),
    VP_TOKENS: new GameConcept([CardNames.MONUMENT, CardNames.SACRIFICE], []),
    COFFERS: new GameConcept([CardNames.SPICES, CardNames.BAKER], []),
    ON_PLAY_DEBT: new GameConcept([CardNames.ROOT_CELLAR, CardNames.LITTER], []),
    VILLAGERS: new GameConcept([CardNames.LACKEYS, CardNames.SCULPTOR], []),
    EXILE: new GameConcept([CardNames.BOUNTY_HUNTER, CardNames.COVEN], []),
    REACTIONS: new GameConcept([CardNames.SHEEPDOG, CardNames.DIPLOMAT], []),
    FAVORS: new GameConcept([CardNames.UNDERLING, CardNames.TRAPPERS_LODGE], []),
    WHEN_DISCARD: new GameConcept([CardNames.TUNNEL, CardNames.MERCHANT_CAMP], []),
    TRASH_INTERACTION: new GameConcept([CardNames.LURKER, CardNames.FORAGER], []),
    UNIQUE_MATS: new GameConcept([CardNames.NATIVE_VILLAGE, CardNames.ISLAND], []),
    EVENTS: new GameConcept([CardNames.EXPEDITION, CardNames.WINDFALL], []),
    RESERVE: new GameConcept([CardNames.RATCATCHER, CardNames.DUPLICATE], []),
    SHADOW: new GameConcept([CardNames.FISHMONGER, CardNames.NINJA], []),
    DEBT_COSTS: new GameConcept([CardNames.CITY_QUARTER, CardNames.WEDDING, CardNames.OVERLORD], []),
    ADVENTURES_TOKENS: new GameConcept([CardNames.TRAINING, CardNames.RANGER], []),
    NIGHT_PHASE: new GameConcept([CardNames.MONASTERY, CardNames.DEN_OF_SIN], []),
    SHELTERS: new GameConcept([CardNames.OVERGROWN_ESTATE, CardNames.NECROPOLIS, CardNames.HOVEL], []),
    HORSES: new GameConcept([CardNames.HORSE, CardNames.SUPPLIES], []),
    PROJECTS: new GameConcept([CardNames.FAIR, CardNames.STAR_CHART], []),
    LANDMARKS: new GameConcept([CardNames.TOWER, CardNames.BATTLEFIELD], []),
    OMEN_PROPHECY: new GameConcept([CardNames.RUSTIC_VILLAGE, CardNames.GREAT_LEADER], []),
    EXTRA_CARDS: new GameConcept([CardNames.HERMIT, CardNames.MADMAN], []),
    SPLIT_PILES: new GameConcept([CardNames.SETTLERS, CardNames.HERB_GATHERER], []),
    ARTIFACTS: new GameConcept([CardNames.FLAG_BEARER, CardNames.FLAG], []),
    BOONS_HEXES: new GameConcept([CardNames.BARD, CardNames.THE_SEAS_GIFT], []),
    HEIRLOOMS: new GameConcept([CardNames.SHEPHERD, CardNames.PASTURE], []),
    STRANGE_COSTS: new GameConcept([CardNames.DESTRIER, CardNames.ANIMAL_FAIR], []),
    OVERPAY: new GameConcept([CardNames.MASTERPIECE, CardNames.HERALD], []),
    COMMAND: new GameConcept([CardNames.BAND_OF_MISFITS, CardNames.CAPTAIN], []),
    POTION: new GameConcept([CardNames.POTION, CardNames.UNIVERSITY], []),
    WAYS: new GameConcept([CardNames.WAY_OF_THE_SHEEP, CardNames.WAY_OF_THE_TURTLE], [])
};

var CardPoolData = [[], [], [GameConcepts.CHOICE_CARDS, GameConcepts.DURATIONS, GameConcepts.KINGDOM_TREASURES, GameConcepts.PLATINUM_COLONY], [GameConcepts.WHEN_GAIN_BUY, GameConcepts.WHEN_TRASH, GameConcepts.VP_TOKENS], [GameConcepts.COFFERS, GameConcepts.ON_PLAY_DEBT, GameConcepts.VILLAGERS, GameConcepts.EXILE, GameConcepts.REACTIONS], [GameConcepts.FAVORS, GameConcepts.WHEN_DISCARD, GameConcepts.TRASH_INTERACTION, GameConcepts.UNIQUE_MATS], [GameConcepts.EVENTS, GameConcepts.RESERVE, GameConcepts.SHADOW, GameConcepts.DEBT_COSTS, GameConcepts.ADVENTURES_TOKENS], [GameConcepts.NIGHT_PHASE, GameConcepts.SHELTERS, GameConcepts.HORSES, GameConcepts.PROJECTS, GameConcepts.LANDMARKS, GameConcepts.OMEN_PROPHECY], [GameConcepts.EXTRA_CARDS, GameConcepts.SPLIT_PILES, GameConcepts.ARTIFACTS, GameConcepts.BOONS_HEXES, GameConcepts.HEIRLOOMS], [GameConcepts.STRANGE_COSTS, GameConcepts.OVERPAY, GameConcepts.COMMAND, GameConcepts.POTION, GameConcepts.WAYS]];

var CardPoolLevel = function () {
    function CardPoolLevel(level, concepts) {
        _classCallCheck(this, CardPoolLevel);

        this.level = level;
        this.concepts = concepts;
        this.cards = undefined;
        this.tabs = undefined;
    }

    _createClass(CardPoolLevel, [{
        key: "setCards",
        value: function setCards(cards) {
            var _this = this;

            this.cards = cards;
            this.tabs = [];
            this.tabs.push(new CardsTab(this.level, cards));
            this.concepts.forEach(function (c) {
                return _this.tabs.push(new ConceptTab(c));
            });
        }
    }]);

    return CardPoolLevel;
}();

var CardsTab = function () {
    function CardsTab(levelNumber, cards) {
        _classCallCheck(this, CardsTab);

        this.levelNumber = levelNumber;
        this.cards = cards.sort(LANGUAGE.getEnvironment.cardComparator);
        this.smallCards = this.cards.map(function (cardName) {
            return new CardObject(-1, cardName, cardName);
        });
    }

    _createClass(CardsTab, [{
        key: "getTranslatedTitle",
        value: function getTranslatedTitle() {
            return getPhrase(Phrases.NEW_CARDS);
        }
    }, {
        key: "getTranslatedCardIntroductionText",
        value: function getTranslatedCardIntroductionText() {
            return LANGUAGE.getLevelIntroductions[this.levelNumber - 1];
        }
    }]);

    return CardsTab;
}();

var ConceptTab = function () {
    function ConceptTab(concept) {
        _classCallCheck(this, ConceptTab);

        this.concept = concept;
        this.largeCards = this.concept.cards.map(function (cardName) {
            return new CardObject(-1, cardName, cardName);
        });
    }

    _createClass(ConceptTab, [{
        key: "getTranslatedText",
        value: function getTranslatedText() {
            return LANGUAGE.getGameConcepts[this.concept].text;
        }
    }, {
        key: "getTranslatedTitle",
        value: function getTranslatedTitle() {
            return LANGUAGE.getGameConcepts[this.concept].title;
        }
    }]);

    return ConceptTab;
}();

var CardPoolLevels = CardPoolData.map(function (data, index) {
    return new CardPoolLevel(index + 1, data);
});