"use strict";

var TChineseDeclineButtons = {};

TChineseDeclineButtons[DeclineButtonIds.END_ACTION_PHASE] = "結束行動";
TChineseDeclineButtons[DeclineButtonIds.END_BUY_PHASE] = "結束購買";
TChineseDeclineButtons[DeclineButtonIds.END_TURN] = "回合結束";
TChineseDeclineButtons[DeclineButtonIds.DONE_PLAYING_TREASURES] = "不打出錢幣卡";
TChineseDeclineButtons[DeclineButtonIds.DONE_REACTING] = "不做動作";
TChineseDeclineButtons[DeclineButtonIds.MINING_VILLAGE_DONT_TRASH] = "不移除";
TChineseDeclineButtons[DeclineButtonIds.PEARL_DIVER_DONT_TOPDECK] = "不放到牌庫頂";
TChineseDeclineButtons[DeclineButtonIds.HARBINGER_DONT_TOPDECK] = "不放到牌庫頂";
TChineseDeclineButtons[DeclineButtonIds.THRONE_ROOM_DONT] = "不使用王座";
TChineseDeclineButtons[DeclineButtonIds.SMUGGLERS_GAIN_IMPOSSIBLE_COPY] = "不獲得";
TChineseDeclineButtons[DeclineButtonIds.EXPLORER_GAIN_SILVER] = "獲得銀幣";
TChineseDeclineButtons[DeclineButtonIds.HERBALIST_DONT_TOPDECK] = "不放置牌庫頂";
TChineseDeclineButtons[DeclineButtonIds.MINT_DONT_COPY] = "不複製";
TChineseDeclineButtons[DeclineButtonIds.HAMLET_DONT_DISCARD] = "不棄卡";
TChineseDeclineButtons[DeclineButtonIds.TOURNAMENT_DONT_REVEAL] = "不展示";
TChineseDeclineButtons[DeclineButtonIds.YOUNG_WITCH_DONT_REVEAL_BANE] = "不展示";
TChineseDeclineButtons[DeclineButtonIds.IGG_NO_COPPER] = "不獲得銅幣";
TChineseDeclineButtons[DeclineButtonIds.COUNTERFEIT_NO_TREASURE] = "不使用偽幣";
TChineseDeclineButtons[DeclineButtonIds.CULTIST_DONT_PLAY_ANOTHER] = "不連鎖邪教徒";
TChineseDeclineButtons[DeclineButtonIds.IRONMONGER_KEEP] = "放回牌庫頂";
TChineseDeclineButtons[DeclineButtonIds.PROCESSION_DONT] = "不進擊";
TChineseDeclineButtons[DeclineButtonIds.PLAZA_NO_DISCARD] = "不棄卡片";
TChineseDeclineButtons[DeclineButtonIds.DONT_OVERPAY] = "不超額支付";
TChineseDeclineButtons[DeclineButtonIds.DONE_SETTING_ASIDE] = "選擇完放置一邊的卡";
TChineseDeclineButtons[DeclineButtonIds.ARTIFICER_DONT] = "不棄卡";
TChineseDeclineButtons[DeclineButtonIds.ENGINEER_DONT_TRASH] = "不移除";
TChineseDeclineButtons[DeclineButtonIds.ENCAMPMENT_DONT_REVEAL] = "不展示";
TChineseDeclineButtons[DeclineButtonIds.SETTLERS_DONT_TAKE_COPPER] = "不拿取銅幣";
TChineseDeclineButtons[DeclineButtonIds.GLADIATOR_DONT_REVEAL] = "不展示";
TChineseDeclineButtons[DeclineButtonIds.CROWN_DONT] = "不皇冠";
TChineseDeclineButtons[DeclineButtonIds.LEGIONARY_DONT_REVEAL] = "不展示";
TChineseDeclineButtons[DeclineButtonIds.SHUFFLE_ALL] = "全部洗牌";
TChineseDeclineButtons[DeclineButtonIds.PASS] = "跳過";
TChineseDeclineButtons[DeclineButtonIds.DONT_DISCARD] = "不棄卡";
TChineseDeclineButtons[DeclineButtonIds.DONT_TRASH] = "不移除";
TChineseDeclineButtons[DeclineButtonIds.VAULT_NO_DISCARD] = "不棄卡";
TChineseDeclineButtons[DeclineButtonIds.DONT_BUY] = "不購買";
TChineseDeclineButtons[DeclineButtonIds.DONT_GAIN] = "不獲得";
TChineseDeclineButtons[DeclineButtonIds.DISCARD_ALL] = "全部棄掉";
TChineseDeclineButtons[DeclineButtonIds.TORTURER_FAIL_CURSE] = "沒有獲得詛咒";
TChineseDeclineButtons[DeclineButtonIds.TORTURER_GAIN_CURSE] = "獲得詛咒";
TChineseDeclineButtons[DeclineButtonIds.BUSTLING_VILLAGE_NO_SETTLERS] = "不拿拓荒者";
TChineseDeclineButtons[DeclineButtonIds.PRINCE_NO] = "不放置一邊";
TChineseDeclineButtons[DeclineButtonIds.SAUNA_DONT_CHAIN] = "不連鎖";
TChineseDeclineButtons[DeclineButtonIds.AVANTO_DONT_CHAIN] = "不連鎖";
TChineseDeclineButtons[DeclineButtonIds.CONCLAVE_DONT] = "不打出";
TChineseDeclineButtons[DeclineButtonIds.IMP_DONT] = "不打出";
TChineseDeclineButtons[DeclineButtonIds.NECROMANCER_DONT] = "不打出";
TChineseDeclineButtons[DeclineButtonIds.NO_THANKS] = "No thanks";
TChineseDeclineButtons[DeclineButtonIds.DONT_EXILE] = "不驅逐";
TChineseDeclineButtons[DeclineButtonIds.KINGS_COURT_DONT] = "不使用王宮";
TChineseDeclineButtons[DeclineButtonIds.MASTERMIND_DONT] = "不使用策劃者";
TChineseDeclineButtons[DeclineButtonIds.DELAY_DONT] = "不使用姍姍來遲";
TChineseDeclineButtons[DeclineButtonIds.TOIL_DONT] = "不使用寒耕熱耘";
TChineseDeclineButtons[DeclineButtonIds.MARCH_DONT] = "不使用行兵列陣";
TChineseDeclineButtons[DeclineButtonIds.DONT_TOPDECK] = "不放到牌庫頂";
TChineseDeclineButtons[DeclineButtonIds.RANDOM_ORDER] = "隨意的順序";
TChineseDeclineButtons[DeclineButtonIds.HUNTING_LODGE_KEEP] = "保留";
TChineseDeclineButtons[DeclineButtonIds.TIARA_NO_TREASURE] = "不打出";
TChineseDeclineButtons[DeclineButtonIds.SWAP_DONT] = "不換";
TChineseDeclineButtons[DeclineButtonIds.DONT_PLAY] = "不打出";
TChineseDeclineButtons[DeclineButtonIds.DONT_REVEAL] = "不展示";
TChineseDeclineButtons[DeclineButtonIds.DONT_ROTATE] = "不旋轉";
TChineseDeclineButtons[DeclineButtonIds.DONT] = "不要";
TChineseDeclineButtons[DeclineButtonIds.SPEND_FAVOR] = "使用支持";
TChineseDeclineButtons[DeclineButtonIds.CONTINUE] = "不使用就繼續";
TChineseDeclineButtons[DeclineButtonIds.DONT_JOUST] = "不放到一邊";
TChineseDeclineButtons[DeclineButtonIds.SNAKE_WITCH_DONT_RETURN] = "Don't return"; // <- translate

TChineseDeclineButtons[DoneButtonIds.DONE_TRASHING] = "確認移除";
TChineseDeclineButtons[DoneButtonIds.DONE_DISCARDING] = "確認棄掉";
TChineseDeclineButtons[DoneButtonIds.DONE_ORDERING] = "確認順序";
TChineseDeclineButtons[DoneButtonIds.DONE_RETURNING] = "確認歸還";
TChineseDeclineButtons[DoneButtonIds.DONE_SETTING_ASIDE] = "確認放置一邊";
TChineseDeclineButtons[DoneButtonIds.DONE_SELECTING] = "確認選擇";
TChineseDeclineButtons[DoneButtonIds.DONE_CHOOSING] = "確認選擇";
TChineseDeclineButtons[DoneButtonIds.TRASH] = "移除";
TChineseDeclineButtons[DoneButtonIds.DISCARD] = "棄卡";
TChineseDeclineButtons[DoneButtonIds.PASS] = "傳牌";
TChineseDeclineButtons[DoneButtonIds.TOPDECK] = "放牌庫頂";
TChineseDeclineButtons[DoneButtonIds.BOTTOMDECK] = "牌庫底";
TChineseDeclineButtons[DoneButtonIds.EXILE] = "驅逐";
TChineseDeclineButtons[DoneButtonIds.OVERPAY] = "超額支付";

var TChineseGameButtons = {};

TChineseGameButtons[GameButtons.AUTOPLAY_TREASURES] = "自動打出錢幣卡";
TChineseGameButtons[GameButtons.USE_VILLAGER] = "使用村民";
TChineseGameButtons[GameButtons.USE_FAVOR_TOPDECK] = "使用支持來放到牌庫頂";
TChineseGameButtons[GameButtons.USE_FAVOR_DISCARD] = "使用支持來棄卡";
TChineseGameButtons[GameButtons.COFFERS] = "使用存款";

var TChineseDelayedQuestionButtons = {};

TChineseDelayedQuestionButtons[CardNames.ARTIFICER] = "使用技工";
TChineseDelayedQuestionButtons[CardNames.BUSTLING_VILLAGE] = "拿取拓荒者";
TChineseDelayedQuestionButtons[CardNames.CULTIST] = "打出邪教徒";
TChineseDelayedQuestionButtons[CardNames.ENGINEER] = "移除工程師";
TChineseDelayedQuestionButtons[CardNames.HAMLET] = "棄卡";
TChineseDelayedQuestionButtons[CardNames.HARBINGER] = "放回牌頂";
TChineseDelayedQuestionButtons[CardNames.ILL_GOTTEN_GAINS] = "獲得銅幣";
TChineseDelayedQuestionButtons[CardNames.IRONMONGER] = "棄掉";
TChineseDelayedQuestionButtons[CardNames.MINING_VILLAGE] = "移除";
TChineseDelayedQuestionButtons[CardNames.PEARL_DIVER] = "放到牌庫頂";
TChineseDelayedQuestionButtons[CardNames.PLAZA] = "棄掉";
TChineseDelayedQuestionButtons[CardNames.SETTLERS] = "拿取銅幣";
TChineseDelayedQuestionButtons[CardNames.HUNTING_LODGE] = "棄掉手牌後抽五張";
TChineseDelayedQuestionButtons[CardNames.SNAKE_WITCH] = "歸還並發詛咒";
TChineseDelayedQuestionButtons[CardNames.RUSTIC_VILLAGE] = "棄二手牌來抽一張";
TChineseDelayedQuestionButtons[CardNames.GOLD_MINE] = "黃金與4負債";

var TChineseCleanupActions = {};

TChineseCleanupActions[CleanupActions.TOPDECK] = new CleanupButtonText("放置牌頂", "棄掉");
TChineseCleanupActions[CleanupActions.EXCHANGE] = new CleanupButtonText("交換", "保留");

// Missing lines as of 09.01.2023 20:44:10
TChineseDeclineButtons[DeclineButtonIds.ORB_DONT] = "+1 購買, +3 預算";
TChineseDeclineButtons[DeclineButtonIds.DONT_PUZZLE_BOX] = "不放到一邊";
TChineseDeclineButtons[DeclineButtonIds.DONT_INSPIRING] = "不打出";