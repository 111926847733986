"use strict";

var FrenchAutoPlays = {};

FrenchAutoPlays[AutoPlays.MOAT] = ["Désactivé", "Toujours révéler"];
FrenchAutoPlays[AutoPlays.CROWN] = ["Désactivé", "Fin Actions automatique"];
FrenchAutoPlays[AutoPlays.CHANGELING] = ["Désactivé", "Demander judicieusement", "Ne jamais échanger"];
FrenchAutoPlays[AutoPlays.URCHIN] = ["Désactivé", "Ne jamais écarter"];
FrenchAutoPlays[AutoPlays.MOUNTEBANK] = ["Désactivé", "Toujours défausser"];
FrenchAutoPlays[AutoPlays.SETTLERS] = ["Désactivé", "Toujours prendre le Cuivre"];
FrenchAutoPlays[AutoPlays.BUSTLING_VILLAGE] = ["Désactivé", "Toujours prendre les Colons"];
FrenchAutoPlays[AutoPlays.TOURNAMENT] = ["Désactivé", "Toujours dévoiler la Province"];
FrenchAutoPlays[AutoPlays.PEASANT] = ["Désactivé", "Échanger par défaut", "Garder par défaut"];
FrenchAutoPlays[AutoPlays.SOLDIER] = ["Désactivé", "Échanger par défaut", "Garder par défaut"];
FrenchAutoPlays[AutoPlays.FUGITIVE] = ["Désactivé", "Échanger par défaut", "Garder par défaut"];
FrenchAutoPlays[AutoPlays.DISCIPLE] = ["Désactivé", "Échanger par défaut", "Garder par défaut"];
FrenchAutoPlays[AutoPlays.PAGE] = ["Désactivé", "Échanger par défaut", "Garder par défaut"];
FrenchAutoPlays[AutoPlays.TREASURE_HUNTER] = ["Désactivé", "Échanger par défaut", "Garder par défaut"];
FrenchAutoPlays[AutoPlays.WARRIOR] = ["Désactivé", "Échanger par défaut", "Garder par défaut"];
FrenchAutoPlays[AutoPlays.HERO] = ["Désactivé", "Échanger par défaut", "Garder par défaut"];
FrenchAutoPlays[AutoPlays.FAITHFUL_HOUND] = ["Désactivé", "Toujours mettre de côté"];
FrenchAutoPlays[AutoPlays.YOUNG_WITCH] = ["Désactivé", "Toujours révéler le fléau"];
FrenchAutoPlays[AutoPlays.TREASURY] = ["Désactivé", "Toujours remettre sur la pioche"];
FrenchAutoPlays[AutoPlays.ALCHEMIST] = ["Désactivé", "Toujours remettre sur la pioche"];
FrenchAutoPlays[AutoPlays.WALLED_VILLAGE] = ["Désactivé", "Toujours remettre sur la pioche"];
FrenchAutoPlays[AutoPlays.HERBALIST] = ["Désactivé", "Ordre quelconque"];
FrenchAutoPlays[AutoPlays.ROYAL_SEAL] = ["Désactivé", "Toujours mettre sur la pioche", "Ne jamais mettre sur la pioche"];
FrenchAutoPlays[AutoPlays.COIN_OF_THE_REALM] = ["Désactivé", "Demander judicieusement"];
FrenchAutoPlays[AutoPlays.BORDER_GUARD] = ["Désactivé", "Toujours remettre sur la pioche"];
FrenchAutoPlays[AutoPlays.TRADER] = ["Désactivé", "Ne pas réagir en recevant un Argent"];
FrenchAutoPlays[AutoPlays.PROVINCE] = ["Désactivé", "Laisser les mauvaises cartes en Exil"];
FrenchAutoPlays[AutoPlays.CHAMPION] = ["Désactivé", "Réenvisager judicieusement les Voies"];

FrenchAutoPlays[AutoPlays.WAY_OF_THE_BUTTERFLY] = ["Désactivé", "Retourner judicieusement"];
FrenchAutoPlays[AutoPlays.DESPERATION] = ["Désactivé", "Toujours recevoir une Malédiction"];
FrenchAutoPlays[AutoPlays.TORTURER] = ["Désactivé", "Ne pas défausser sans raison"];
FrenchAutoPlays[AutoPlays.ARENA] = ["Désactivé", "Ne pas défausser sans raison"];
FrenchAutoPlays[AutoPlays.PAGEANT] = ["Désactivé", "Toujours prendre les Coffres"];
FrenchAutoPlays[AutoPlays.OLD_WITCH] = ["Désactivé", "Toujours écarter une Malédiction"];
FrenchAutoPlays[AutoPlays.SINISTER_PLOT] = ["Désactivé", "Ne jamais piocher zéro carte"];
FrenchAutoPlays[AutoPlays.MONEYLENDER] = ["Désactivé", "Toujours écarter un Cuivre"];
FrenchAutoPlays[AutoPlays.ENCAMPMENT] = ["Désactivé", "Révéler judicieusement"];
FrenchAutoPlays[AutoPlays.DUPLICATE] = ["Désactivé", "Uniquement des cartes gagnables", "Et aussi pas de Malédictions"];