"use strict";

var FrenchErrors = {};

FrenchErrors[FailureReasons.WRONG_PASSWORD] = "Mot de passe incorrect.";
FrenchErrors[FailureReasons.NOT_ENOUGH_PLAYERS] = "Pas assez de joueurs.";
FrenchErrors[FailureReasons.TOO_MANY_PLAYERS] = "Trop de joueurs.";
FrenchErrors[FailureReasons.NEW_HOST_NOT_FOUND] = "Impossible de trouver l'hôte.";
FrenchErrors[FailureReasons.ALREADY_JOINED_A_TABLE] = "Vous avez déjà rejoint une table.";
FrenchErrors[FailureReasons.NOT_ALLOWED_TO_JOIN] = "Vous n'êtes pas autorisé à rejoindre.";
FrenchErrors[FailureReasons.BAD_CLIENT_VERSION] = "Votre client est trop vieux; rafraîchir la page (+CTRL) pour en obtenir un nouveau.";
FrenchErrors[FailureReasons.UNKNOWN_RULE_INDEX] = "Unknown rule index.";
FrenchErrors[FailureReasons.RULE_NOT_REMOVABLE] = "This rule is not removable.";
FrenchErrors[FailureReasons.INVITEE_NOT_LOGGED_IN] = "L'invité n'est pas en ligne.";
FrenchErrors[FailureReasons.NOT_ALLOWED_TO_PLAY] = "Vous n'êtes pas autorisé à jouer.";
FrenchErrors[FailureReasons.TABLE_CHAT_REQUIRES_TABLE] = "La discussion de table nécessite une table.";
FrenchErrors[FailureReasons.SPECTATOR_CHAT_REQUIRES_TABLE] = "La discussion des spectateur nécessite une table.";
FrenchErrors[FailureReasons.LOGIN_REQUIRED] = "Vous devez être en ligne.";
FrenchErrors[FailureReasons.COMMAND_REQUIRES_TABLE] = "Cette commande nécessite une table.";
FrenchErrors[FailureReasons.COMMAND_REQUIRES_HOST] = "Cette commande nécessite les permissions de l'hôte.";
FrenchErrors[FailureReasons.RULE_CONFLICTS] = "Conflit de règles.";
FrenchErrors[FailureReasons.NO_GAMESERVER_AVAILABLE] = "Aucun serveur disponible.";
FrenchErrors[FailureReasons.SELECTED_CARD_NOT_OWNED] = "Vous ne possédez pas la carte sélectionnée.";
FrenchErrors[FailureReasons.SELECTED_CARD_NOT_KINGDOM_CARD] = "La carte sélectionnée n'est pas une carte royaume.";
FrenchErrors[FailureReasons.SELECTED_TOO_MANY_EVENTS_OR_LANDMARKS] = "Trop d'événements ou de jalons sélectionnés.";
FrenchErrors[FailureReasons.THIS_IS_NOT_YOUR_TABLE] = "Ce n'est pas votre table.";
FrenchErrors[FailureReasons.ALREADY_LOGGED_IN] = "Déjà en ligne.";
FrenchErrors[FailureReasons.CANNOT_RECONNECT_WHEN_STILL_CONNECTED] = "Impossible de se reconnecter.";
FrenchErrors[FailureReasons.KICK_FAILED] = "Échec de l'exclusion.";
FrenchErrors[FailureReasons.ALREADY_STARTING] = "Déjà en train de commencer.";
FrenchErrors[FailureReasons.ALREADY_STARTED] = "Déjà commencé.";
FrenchErrors[FailureReasons.NOTHING_TO_KICK] = "Rien à exclure.";
FrenchErrors[FailureReasons.INTERNAL_ERROR] = "Erreur interne.";
FrenchErrors[FailureReasons.ILLEGAL_PROPERTY_CHANGE] = "Changement de propriété illégal";
FrenchErrors[FailureReasons.CODE_ALREADY_USED] = "Code déjà utilisé";
FrenchErrors[FailureReasons.FAILED_CONFIRMING_EMAIL] = "Échec de la confirmation de l'e-mail";
FrenchErrors[FailureReasons.FAILED_RETRIEVING_USER] = "Échec de la récupération de l'utilisateur";
FrenchErrors[FailureReasons.EMAIL_NOT_CONFIRMED_YET] = "Vous ne pouvez pas héberger une partie avant de confirmer votre e-mail";
FrenchErrors[FailureReasons.FAILURE_SENDING_EMAIL] = "L'e-mail de confirmation n'a pas pu être envoyé";
FrenchErrors[FailureReasons.CONFIRM_EMAIL_FIRST] = "Désolé, nous ne pouvons pas vous envoyer de mot de passe sans e-mail confirmé";
FrenchErrors[FailureReasons.OLD_PASSWORD_INCORRECT] = "Ancien mot de passe incorrect";
FrenchErrors[FailureReasons.INCORRECT_PRICE] = "Prix incorrect";
FrenchErrors[FailureReasons.PURCHASE_IN_FUTURE] = "L'achat semble venir du futur";
FrenchErrors[FailureReasons.PURCHASE_TOO_OLD] = "Achat trop vieux; merci d'essayer à nouveau";
FrenchErrors[FailureReasons.FAILED_SETTING_UP_PURCHASE] = "Échec de la mise en route de l'achat";
FrenchErrors[FailureReasons.FAILED_STORING_PURCHASE] = "Échec de l'enregistrement de l'achat";
FrenchErrors[FailureReasons.DUPLICATE_USERNAME] = "Ce nom d'utilisateur existe déjà";
FrenchErrors[FailureReasons.INVALID_USERNAME] = "Nom d'utilisateur invalide";
FrenchErrors[FailureReasons.INVALID_PASSWORD] = "Mot de passe invalide";
FrenchErrors[FailureReasons.INVALID_GENDER] = "Genre invalide";
FrenchErrors[FailureReasons.PURCHASE_TOO_SHORT] = "La durée minimale d'abonnement est de 1 mois";
FrenchErrors[FailureReasons.PURCHASE_TOO_LONG] = "Actuellement, nous ne vendons pas d'abonnement au-delà de 2024";
FrenchErrors[FailureReasons.METASERVER_INACTIVE] = "Une nouvelle version du serveur est parue: merci de rafraîchir la page (+CTRL) pour vous y connecter.";
FrenchErrors[FailureReasons.NO_SUCH_TABLE] = "Table inconnue.";
FrenchErrors[FailureReasons.NO_SUCH_USER] = "Utilisateur inconnu.";
FrenchErrors[FailureReasons.NO_SUCH_CODE] = "Code inconnu.";
FrenchErrors[FailureReasons.RESERVED_USERNAME] = "Ce nom d'utilisateur est réservé.";
FrenchErrors[FailureReasons.PRICE_IS_ZERO] = "Vous ne pouvez pas acheter pour un coût de 0.";
FrenchErrors[FailureReasons.NO_SUCH_USERNAME] = "Nom d'utilisateur inconnu.";
FrenchErrors[FailureReasons.NO_SUCH_GAMELOG] = "La partie n'a pas pu être trouvée.";
FrenchErrors[FailureReasons.ILLEGAL_PLAYER_ORDER] = "Ordre des joueurs illégal.";
FrenchErrors[FailureReasons.INVALID_SESSION_ID] = "Identifiant de session invalide.";
FrenchErrors[FailureReasons.ACTION_CODE_EXPIRED] = "Code expiré.";
FrenchErrors[FailureReasons.BOT_GAMES_CANT_BE_RATED] = "Les parties avec des AI ne peuvent pas être classées.";
FrenchErrors[FailureReasons.TOO_MANY_PLAYERS_FOR_RATED_GAME] = "Les parties avec >4 joueurs ne peuvent pas être classées.";
FrenchErrors[FailureReasons.ALREADY_ON_BLACKLIST] = "Déjà sur liste noire.";
FrenchErrors[FailureReasons.NOT_ON_BLACKLIST] = "Pas sur liste noire.";
FrenchErrors[FailureReasons.ALREADY_ON_FRIENDLIST] = "Déjà sur liste d'amis.";
FrenchErrors[FailureReasons.NOT_ON_FRIENDLIST] = "Pas sur liste d'amis.";
FrenchErrors[FailureReasons.SEARCHING_FOR_MYSELF] = "Vous ne pouvez pas vous ajouter vous-même.";
FrenchErrors[FailureReasons.SEARCHED_FOR_BOT] = "Vous ne pouvez pas ajouter une AI.";
FrenchErrors[FailureReasons.USER_IS_BANNED] = "Vous avez été banni de ce site.";
FrenchErrors[FailureReasons.WRONG_BONUS_CODE_TYPE] = "WRONG_BONUS_CODE_TYPE.";
FrenchErrors[FailureReasons.ILLEGAL_BONUS_CODE] = "ILLEGAL_BONUS_CODE.";
FrenchErrors[FailureReasons.GAME_ID_SHOULD_BE_NUMBER] = "Le numéro de partie doit être un nombre.";
FrenchErrors[FailureReasons.NOT_SUBSCRIBED_TO] = "Pas abonné à";
FrenchErrors[FailureReasons.KINGDOM_HAS_TOO_MANY_CARDS] = "Le Royaume a trop de cartes.";
FrenchErrors[FailureReasons.NO_CARDS_AVAILABLE_TO_FILL_KINGDOM] = "Pas de carte disponible pour compléter le Royaume.";
FrenchErrors[FailureReasons.FAILED_TO_SELECT_BANE_CARD] = "Impossible de choisir la carte Fléau.";
FrenchErrors[FailureReasons.FAILED_TO_SELECT_MOUSE_CARD] = "Impossible de choisir la carte Souris.";
FrenchErrors[FailureReasons.FAILED_TO_SELECT_FERRYMAN_CARD] = "Impossible de choisir la carte Passeur.";
FrenchErrors[FailureReasons.FAILED_TO_SELECT_PREVIEW_CARDS] = "Impossible de choisir la carte d'avant-première.";
FrenchErrors[FailureReasons.FAILED_TO_SELECT_ALLY] = "Impossible de choisir un Allié.";
FrenchErrors[FailureReasons.FAILED_TO_SELECT_LIASON] = "Impossible de choisir une Liaison.";
FrenchErrors[FailureReasons.REQUIRING_RANDOM_CARD_FROM_DISABLED_EXPANSION] = "Demande une carte aléatoire d'une extension désactivée.";
FrenchErrors[FailureReasons.COMMAND_REQUIRES_PLAYER] = "This command is only for players.";
FrenchErrors[FailureReasons.COMMAND_REQUIRES_GAME] = "This command requires a game.";
FrenchErrors[FailureReasons.ILLEGAL_ANSWER] = "Provided an illegal answer in this game.";
FrenchErrors[FailureReasons.ILLEGAL_REQUEST] = "Illegal request in this game.";
FrenchErrors[FailureReasons.NO_SUCH_UNDO] = "No such undo.";
FrenchErrors[FailureReasons.SERVER_DOESNT_ALLOW_NEW_GAMES] = "Server doesn't allow new games. Please reload the page.";
FrenchErrors[FailureReasons.SERVER_IS_DOWN] = "Server is down. Please reload the page.";

var FrenchSuccessDescriptions = {};

FrenchSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_FORGOT_PASSWORD] = "Nous vous avons envoyé un e-mail pour réinitialiser votre mot de passe.";
FrenchSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_CONFIRM_EMAIL] = "Nous vous avons envoyé un e-mail pour confirmer votre adresse.";
FrenchSuccessDescriptions[SuccessDescriptions.PASSWORD_CHANGED] = "Mot de passe changé";
FrenchSuccessDescriptions[SuccessDescriptions.KICK_SUCCESS] = "Exclusion confirmée";
FrenchSuccessDescriptions[SuccessDescriptions.PURCHASE_SUCCESS] = "Achat confirmé";
FrenchSuccessDescriptions[SuccessDescriptions.EMAIL_CONFIRMED] = "E-mail confirmé";
FrenchSuccessDescriptions[SuccessDescriptions.REFUND_SUCCESS] = "Achat remboursé";
FrenchSuccessDescriptions[SuccessDescriptions.ACTION_CODE_USED] = "Utilisation du code confirmée.";
FrenchSuccessDescriptions[SuccessDescriptions.SUBSCRIPTION_UPDATED] = "Votre abonnement est mis à jour.";

var FrenchTableRules = {};

FrenchTableRules[TableRuleIds.PLAYERS_CAN_SEE_SPECTATOR_CHAT] = "Les joueurs peuvent voir la discussion des spectateurs";
FrenchTableRules[TableRuleIds.BANNED_CARDS] = "Cartes bannies";
FrenchTableRules[TableRuleIds.REQUIRED_CARDS] = "Cartes requises";
FrenchTableRules[TableRuleIds.USED_EXPANSIONS] = "Extensions utilisée";
FrenchTableRules[TableRuleIds.RESTRICTIVE_PLAYER_RULES] = "Seuls les utilisateurs spécifiés peuvent jouer";
FrenchTableRules[TableRuleIds.SPECTATE_RULES] = "Autoriser les spectateurs";
FrenchTableRules[TableRuleIds.MIN_RATING] = "Différence de classement minimale";
FrenchTableRules[TableRuleIds.MAX_RATING] = "Différence de classement maximale";
FrenchTableRules[TableRuleIds.RANDOM_SEED] = "Spécifier la graine aléatoire";
FrenchTableRules[TableRuleIds.MIN_PLAYERS] = "Min joueurs";
FrenchTableRules[TableRuleIds.MAX_PLAYERS] = "Max joueurs";
FrenchTableRules[TableRuleIds.SPECIAL_KINGDOM_RULES] = "Règles spéciales pour ce royaume";
FrenchTableRules[TableRuleIds.RESPECTED_CARD_LISTS] = "Respecter les cartes appréciées/peu appréciées/bannies";
FrenchTableRules[TableRuleIds.SHOW_VP_COUNTER] = "Montrer le compteur de PV";
FrenchTableRules[TableRuleIds.UNDO_SETTINGS] = "Propriétés d'annulation";
FrenchTableRules[TableRuleIds.ALWAYS_ALLOWED_PLAYER_RULES] = "ALWAYS_ALLOWED_PLAYER_RULES";
FrenchTableRules[TableRuleIds.PLAYER_ORDER] = "Ordre des joueurs";
FrenchTableRules[TableRuleIds.REPLAY_INSTRUCTIONS] = "Instructions pour rejouer";
FrenchTableRules[TableRuleIds.RATED_GAME] = "Partie classée";
FrenchTableRules[TableRuleIds.PREVIEW_GAME] = "Ménagerie en avant-première";
FrenchTableRules[TableRuleIds.SCRIPTED_RULES] = "Régles spéciales pour le royaume";
FrenchTableRules[TableRuleIds.CARD_POOL_LEVEL] = "Niveau de pool de cartes";

var FrenchActivities = {};

FrenchActivities[ActivityStati.LOGGED_IN] = "En ligne";
FrenchActivities[ActivityStati.PLAYING] = "Joue";
FrenchActivities[ActivityStati.SPECTATING] = "Regarde";
FrenchActivities[ActivityStati.AT_TABLE_PLAYER] = "A une table (joue)";
FrenchActivities[ActivityStati.AT_TABLE_SPECTATOR] = "A une table (regarde)";
FrenchActivities[ActivityStati.DISCONNECTED] = "Déconnecté";

var FrenchTableStati = {};

FrenchTableStati[TableStati.NEW] = "Nouvelle";
FrenchTableStati[TableStati.POST_GAME] = "Partie terminée";
FrenchTableStati[TableStati.RUNNING] = "En cours";
FrenchTableStati[TableStati.ABANDONED] = "Abandonnée";

var FrenchLobbyButtons = {
    DONE: "OK",
    RESET: "Réinitialisé",
    CLEAR_SELECTION: "Effacer la sélection",
    SHOW_TEXT_KINGDOM: "Montre le texte du royaume",
    NEW_TABLE: "Nouvelle table",
    START_GAME: "Commencer une partie",
    LEAVE_TABLE: "Quitter la table",
    SELECT_KINGDOM: "Choisir les cartes royaume",
    LIST_BANNED: "Bannies",
    LIST_DISLIKED: "Peu appréciées",
    LIST_LIKED: "Appréciées",
    LIST_FAMILIAR: "Familières",
    LOAD_FROM_END: "Charger depuis la fin",
    SELECT_COLONIES: "Colonies: ",
    SELECT_SHELTERS: "Refuges: "
};

var FrenchLobbyTexts = {
    AUTOCOMPLETE_FAILED: "Aucune carte ne correspond à \"CARDS\".",
    ADD_CARDS_AUTOCOMPLETE: "Entrer les cartes à inclure dans votre royaume",
    BAN_CARDS_AUTOCOMPLETE: "Entrer les cartes à exclure de votre royaume",
    KINGDOM_OPTIONS: "Options du royaume",
    GAME_OPTIONS: "Options de la partie",
    RESPECTED_CARDS: "Cartes respectées",
    RELATIVE_LEVEL: "Niveau relatif",
    LEVEL_INTERVAL: "${min} à ${max}",
    EMPTY_SLOT: "Emplacement vide",
    REMOVE_SLOT: "Enlever l'emplacement",
    ADD_SLOT: "Ajouter un emplacement",
    RANDOMIZE_PLAYER_ORDER: "Ordre des joueurs aléatoire",
    CHAT: "Discussion",
    SELECT_CARDS: "Choisir des cartes"
};

var FrenchTernaryFieldTexts = {};

FrenchTernaryFieldTexts[TernaryField.YES] = "Oui";
FrenchTernaryFieldTexts[TernaryField.NO] = "Non";
FrenchTernaryFieldTexts[TernaryField.RANDOM] = "Au hasard";

var FrenchTabs = {};

FrenchTabs[LobbyTabs.INBOX] = "Boîte de réception";
FrenchTabs[LobbyTabs.MATCH] = "Parties";
FrenchTabs[LobbyTabs.FRIEND_LIST] = "Liste d'amis";
FrenchTabs[LobbyTabs.TABLES] = "Tables";
FrenchTabs[LobbyTabs.NEW_TABLE] = "Nouvelle table";
FrenchTabs[LobbyTabs.MY_TABLE] = "Ma table";
FrenchTabs[LobbyTabs.ACCOUNT] = "Compte";
FrenchTabs[LobbyTabs.STORE] = "Boutique";
FrenchTabs[LobbyTabs.OPTIONS] = "Options";
FrenchTabs[LobbyTabs.FAMILIAR_CARDS] = "Cartes familières";
FrenchTabs[LobbyTabs.LEADERBOARD] = "Classement";
FrenchTabs[LobbyTabs.CARDLISTS] = "Listes de cartes";

var FrenchMatchTypes = {};

FrenchMatchTypes[AutomatchTypes.QUICK_MATCH] = "N'importe quel adversaire";
FrenchMatchTypes[AutomatchTypes.GOOD_MATCH] = "Adversaire de même niveau";
FrenchMatchTypes[AutomatchTypes.MATCH_WITH_EXPANSIONS] = "Assortir les extensions";
FrenchMatchTypes[AutomatchTypes.MATCH_WITH_BOT] = "Adversaire AI";
FrenchMatchTypes[AutomatchTypes.MATCH_UNRATED] = "Adversaire humain";

var FrenchUndoSettings = {};

FrenchUndoSettings[UndoSettings.NEVER] = "Jamais";
FrenchUndoSettings[UndoSettings.ASK] = "Demander";
FrenchUndoSettings[UndoSettings.ALWAYS] = "Toujours";

var FrenchGroupIds = {};

FrenchGroupIds[GroupIds.EVERYBODY] = "Tout le monde";
FrenchGroupIds[GroupIds.NOBODY] = "Personne";
FrenchGroupIds[GroupIds.FRIENDS_OF] = "Amis de ";
FrenchGroupIds[GroupIds.LIST_PLAYER_IDS] = "Joueurs sélectionnés:";

var FrenchCountries = {};

FrenchCountries[Countries.NON_EU] = "Hors UE";
FrenchCountries[Countries.AT] = "Autriche";
FrenchCountries[Countries.BE] = "Belgique";
FrenchCountries[Countries.BG] = "Bulgarie";
FrenchCountries[Countries.HR] = "Croatie";
FrenchCountries[Countries.CY] = "Chypre";
FrenchCountries[Countries.CZ] = "République tchèque";
FrenchCountries[Countries.DK] = "Danemark";
FrenchCountries[Countries.EE] = "Estonie";
FrenchCountries[Countries.FI] = "Finlande";
FrenchCountries[Countries.FR] = "France";
FrenchCountries[Countries.DE] = "Allemagne";
FrenchCountries[Countries.GR] = "Grèce";
FrenchCountries[Countries.HU] = "Hongrie";
FrenchCountries[Countries.IE] = "Irlande";
FrenchCountries[Countries.IT] = "Italie";
FrenchCountries[Countries.LV] = "Lettonie";
FrenchCountries[Countries.LT] = "Lituanie";
FrenchCountries[Countries.LU] = "Luxembourg";
FrenchCountries[Countries.MT] = "Malte";
FrenchCountries[Countries.NL] = "Pays-bas";
FrenchCountries[Countries.PL] = "Pologne";
FrenchCountries[Countries.PT] = "Portugal";
FrenchCountries[Countries.RO] = "Roumanie";
FrenchCountries[Countries.SK] = "Slovaquie";
FrenchCountries[Countries.SI] = "Slovénie";
FrenchCountries[Countries.ES] = "Espagne";
FrenchCountries[Countries.SE] = "Suède";
FrenchCountries[Countries.GB] = "Royaume-Uni";

var FrenchAutomatchQuestions = {};
var FrenchAutomatchValues = {};

FrenchAutomatchQuestions[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS] = "Nombre de joueurs";
FrenchAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS] = {};
FrenchAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.REQUIRE] = "2J";
FrenchAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.EITHER] = "2J ou 3J";
FrenchAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.REJECT] = "3J";

FrenchAutomatchQuestions[AUTOMATCH_QUESTION.RATED_GAME] = "Parties classées";
FrenchAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME] = {};
FrenchAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.REQUIRE] = "Classée";
FrenchAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.EITHER] = "Classée ou non classée";
FrenchAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.REJECT] = "Non classée";

FrenchAutomatchQuestions[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME] = "Extra 2ème édition";
FrenchAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME] = {};
FrenchAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.REQUIRE] = "Oui";
FrenchAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.EITHER] = "Oui ou Non";
FrenchAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.REJECT] = "Non";

FrenchAutomatchQuestions[AUTOMATCH_QUESTION.PREVIEW_GAME] = "2e Previews";
FrenchAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME] = {};
FrenchAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.REQUIRE] = "Oui";
FrenchAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.REJECT] = "Non";
FrenchAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.EITHER] = "Oui ou Non";