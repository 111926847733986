"use strict";

webclient.component("messageView", {
    controller: ['inboxService', function (inboxService) {
        var self = this;

        self.$onInit = function () {
            self.inbox = inboxService;
        };

        self.hasMessages = function () {
            if (inboxService.hasMessages()) return true;
            if (inboxService.hasSummaries()) inboxService.fetchMessage();
            return false;
        };

        self.getMessage = function () {
            return inboxService.getSelectedMessage();
        };
        self.getNoMessagesText = function () {
            return getPhrase(Phrases.NO_MESSAGES);
        };
        self.getMarkReadText = function () {
            return getPhrase(Phrases.MARK_READ);
        };
        self.getDeleteText = function () {
            return getPhrase(Phrases.DELETE);
        };
        self.markRead = function () {
            return inboxService.markMessageAsRead();
        };
        self.delete = function () {
            return inboxService.deleteMessage();
        };
        self.hasRead = function () {
            return inboxService.hasReadMessage();
        };
    }],
    template: "\n            <div class=\"message-view\">\n                <div ng-if=\"$ctrl.hasMessages()\">\n                    <div class=\"message-subject\">\n                        {{$ctrl.getMessage().subject}}                    \n                    </div><br/>\n                    <div ng-bind-html=\"$ctrl.getMessage().body | trustAsHtml\"></div>                    \n                </div>\n                <div ng-if=\"!$ctrl.hasMessages()\">\n                    <div class=\"message-subject\">{{::$ctrl.getNoMessagesText()}}</div>\n                </div>               \n            </div>         \n            <div class=\"message-view-buttons\" ng-if=\"$ctrl.hasMessages()\">\n                <div ng-if=\"!$ctrl.hasRead()\" style=\"display:inline;\">                    \n                    <button class=\"lobby-button copper-button\" ng-click=\"$ctrl.markRead()\">{{$ctrl.getMarkReadText()}}</button>\n                </div>\n                <button class=\"lobby-button stone-button\" ng-click=\"$ctrl.delete()\">{{$ctrl.getDeleteText()}}</button>                \n            </div>\n            "
});