"use strict";

var EsperantoEnvironment = new function () {
    this.enter = function () {};
    this.leave = function () {};
    this.strlen = function (str) {
        return str.length;
    };

    this.getPileName = function (pile, direction) {
        var pileString = LANGUAGE.getCardName[pile.pileName].singular;
        var separator = LANGUAGE.getEnvironment.pileNameSeparator;
        var s = pile.pileName === CardNames.BLACK_MARKET_PILE ? "" : separator + LANGUAGE.getPhrase[Phrases.PILE];
        var article = getDirectionalArticle(direction);
        return colorTextByCardname(article + " " + pileString + s, pile.pileName);
    };
    this.pileNameSeparator = " ";

    this.phraseCollection_Or = function (phrases) {
        return EnglishEnvironment.phraseCollection(phrases, EsperantoPhrases[Phrases.COMMA], EsperantoPhrases[Phrases.OR]);
    };

    // commented out by Ingix, as it is the same as the English phrase collection; changed above line to use English one
    /*
    this.phraseCollection = function (phrases, repeatedDelimiter, finalDelimiter) {
        let phrColl = "";
        for (let i = 0; i < phrases.length; i++) {
            phrColl += phrases[i] + ((i < phrases.length - 2) ? repeatedDelimiter :
                                    (i === phrases.length - 2 ? " " + finalDelimiter + " " :
                                                                ""));
        }
        return phrColl;
    };
    */

    this.cardComparator = EnglishEnvironment.cardComparator;

    // commented out by Ingix, as it is an old version (not considering Ways or Allies)
    /*
       this.cardComparator = (a, b) => {
           if (a.isLandscape() !== b.isLandscape()) return a.isLandscape() ? 1 : -1;
           if (a.isEvent() !== b.isEvent()) return a.isEvent() ? -1 : 1;
           if (a.isLandmark() !== b.isLandmark()) return a.isLandmark() ? -1 : 1;
           if (a.isProject() !== b.isProject()) return a.isProject() ? -1 : 1;
           let translatedNameA = LANGUAGE.getCardName[a].singular;
           let translatedNameB = LANGUAGE.getCardName[b].singular;
           return translatedNameA.localeCompare(translatedNameB);
       };
    */

    this.determineFontStyle = EnglishEnvironment.determineFontStyle;
}();