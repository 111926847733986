"use strict";

var RussianAbilityNames = {};

RussianAbilityNames[AbilityNames.BUY] = "a";
RussianAbilityNames[AbilityNames.PLAY_ATTACK] = "Сыграть атаку";
RussianAbilityNames[AbilityNames.DRAW_INSTRUCTIONS] = "b";
RussianAbilityNames[AbilityNames.PLAY_INSTRUCTIONS] = "Сыграть";
RussianAbilityNames[AbilityNames.RESOLVE_EVENT] = "c";
RussianAbilityNames[AbilityNames.GAIN_BY_NAME] = "d";

RussianAbilityNames[AbilityNames.FISHING_VILLAGE_ABILITY] = RussianCardNames[CardNames.FISHING_VILLAGE].singular;
RussianAbilityNames[AbilityNames.REMOVE_COST_REDUCTION] = "e";
RussianAbilityNames[AbilityNames.HAVEN_ABILITY] = RussianCardNames[CardNames.HAVEN].singular;
RussianAbilityNames[AbilityNames.SAVE_RETURN_TO_HAND] = RussianCardNames[CardNames.SAVE].singular;
RussianAbilityNames[AbilityNames.CARAVAN_ABILITY] = RussianCardNames[CardNames.CARAVAN].singular;
RussianAbilityNames[AbilityNames.MERCHANT_SHIP_ABILITY] = RussianCardNames[CardNames.MERCHANT_SHIP].singular;
RussianAbilityNames[AbilityNames.OUTPOST_ABILITY] = RussianCardNames[CardNames.OUTPOST].singular;
RussianAbilityNames[AbilityNames.TACTICIAN_ABILITY] = RussianCardNames[CardNames.TACTICIAN].singular;
RussianAbilityNames[AbilityNames.WHARF_ABILITY] = RussianCardNames[CardNames.WHARF].singular;
RussianAbilityNames[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = RussianCardNames[CardNames.HORSE_TRADERS].singular;
RussianAbilityNames[AbilityNames.DUCHESS_MAY_GAIN] = RussianCardNames[CardNames.DUCHESS].singular;
RussianAbilityNames[AbilityNames.FOOLS_GOLD_TRASH] = RussianCardNames[CardNames.FOOLS_GOLD].singular;
RussianAbilityNames[AbilityNames.NOBLE_BRIGAND] = RussianCardNames[CardNames.NOBLE_BRIGAND].singular;
RussianAbilityNames[AbilityNames.HAGGLER_ABILITY] = RussianCardNames[CardNames.HAGGLER].singular;
RussianAbilityNames[AbilityNames.INN_ABILITY] = RussianCardNames[CardNames.INN].singular;
RussianAbilityNames[AbilityNames.MANDARIN] = RussianCardNames[CardNames.MANDARIN].singular;
RussianAbilityNames[AbilityNames.FARMLAND] = RussianCardNames[CardNames.FARMLAND].singular;
RussianAbilityNames[AbilityNames.GAIN_CHEAPER] = "Получить карту дешевле";
RussianAbilityNames[AbilityNames.GAIN_TWO_RUINS] = RussianCardNames[CardNames.DEATH_CART].singular;
RussianAbilityNames[AbilityNames.HUNTING_GROUNDS] = RussianCardNames[CardNames.HUNTING_GROUNDS].singular;
RussianAbilityNames[AbilityNames.SQUIRE_GAIN_ATTACK] = RussianCardNames[CardNames.SQUIRE].singular;
RussianAbilityNames[AbilityNames.URCHIN_TRANSFORM] = RussianCardNames[CardNames.URCHIN].singular;
RussianAbilityNames[AbilityNames.FEODUM_GAIN_SILVERS] = RussianCardNames[CardNames.FEODUM].singular;
RussianAbilityNames[AbilityNames.DOCTOR_OVERPAY] = "Переплатить за Доктора";
RussianAbilityNames[AbilityNames.RESOLVE_DOCTOR_OVERPAY] = RussianCardNames[CardNames.DOCTOR].singular;
RussianAbilityNames[AbilityNames.HERALD_OVERPAY] = "Переплатить за Глашатая";
RussianAbilityNames[AbilityNames.RESOLVE_HERALD_OVERPAY] = RussianCardNames[CardNames.HERALD].singular;
RussianAbilityNames[AbilityNames.MASTERPIECE_OVERPAY] = "Переплатить за Шедевр";
RussianAbilityNames[AbilityNames.RESOLVE_MASTERPIECE_OVERPAY] = RussianCardNames[CardNames.MASTERPIECE].singular;
RussianAbilityNames[AbilityNames.STONEMASON_OVERPAY] = "Переплатить за Каменщика";
RussianAbilityNames[AbilityNames.RESOLVE_STONEMASON_OVERPAY] = RussianCardNames[CardNames.STONEMASON].singular;
RussianAbilityNames[AbilityNames.EXCHANGE] = "Обменять";
RussianAbilityNames[AbilityNames.SHUFFLE] = "Перетасовать";
RussianAbilityNames[AbilityNames.AMULET] = RussianCardNames[CardNames.AMULET].singular;
RussianAbilityNames[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = RussianCardNames[CardNames.CARAVAN_GUARD].singular;
RussianAbilityNames[AbilityNames.DUNGEON_ABILITY] = RussianCardNames[CardNames.DUNGEON].singular;
RussianAbilityNames[AbilityNames.GEAR_ABILITY] = RussianCardNames[CardNames.GEAR].singular;
RussianAbilityNames[AbilityNames.MESSENGER_GAIN] = RussianCardNames[CardNames.MESSENGER].singular;
RussianAbilityNames[AbilityNames.BRIDGE_TROLL_ABILITY] = RussianCardNames[CardNames.BRIDGE_TROLL].singular;
RussianAbilityNames[AbilityNames.HAUNTED_WOODS_DURATION] = "Тянуть карты (Призрачный Лес)";
RussianAbilityNames[AbilityNames.HAUNTED_WOODS_TOPDECK] = "На верх колоды (Призрачный Лес)";
RussianAbilityNames[AbilityNames.LOST_CITY] = RussianCardNames[CardNames.LOST_CITY].singular;
RussianAbilityNames[AbilityNames.WINE_MERCHANT_DISCARD] = RussianCardNames[CardNames.WINE_MERCHANT].singular;
RussianAbilityNames[AbilityNames.HIRELING] = RussianCardNames[CardNames.HIRELING].singular;
RussianAbilityNames[AbilityNames.SWAMP_HAG_DURATION] = RussianCardNames[CardNames.SWAMP_HAG].singular;
RussianAbilityNames[AbilityNames.TRASH_TOKEN_ABILITY] = RussianCardNames[CardNames.PLAN].singular;
RussianAbilityNames[AbilityNames.EMPORIUM_GAIN_POINTS] = RussianCardNames[CardNames.EMPORIUM].singular;
RussianAbilityNames[AbilityNames.ROCKS_GAINS_SILVER] = RussianCardNames[CardNames.ROCKS].singular;
RussianAbilityNames[AbilityNames.FORTUNE] = RussianCardNames[CardNames.FORTUNE].singular;
RussianAbilityNames[AbilityNames.CRUMBLING_CASTLE] = RussianCardNames[CardNames.CRUMBLING_CASTLE].singular;
RussianAbilityNames[AbilityNames.HAUNTED_CASTLE] = RussianCardNames[CardNames.HAUNTED_CASTLE].singular;
RussianAbilityNames[AbilityNames.SPRAWLING_CASTLE] = RussianCardNames[CardNames.SPRAWLING_CASTLE].singular;
RussianAbilityNames[AbilityNames.GRAND_CASTLE] = RussianCardNames[CardNames.GRAND_CASTLE].singular;
RussianAbilityNames[AbilityNames.ENCHANTRESS_ABILITY] = "+2 Карты";
RussianAbilityNames[AbilityNames.ENCHANTRESS_CANTRIP] = "+1 Карта, +1 Действие";
RussianAbilityNames[AbilityNames.TEMPLE] = RussianCardNames[CardNames.TEMPLE].singular;
RussianAbilityNames[AbilityNames.VILLA] = RussianCardNames[CardNames.VILLA].singular;
RussianAbilityNames[AbilityNames.ARCHIVE_ABILITY] = RussianCardNames[CardNames.ARCHIVE].singular;
RussianAbilityNames[AbilityNames.CAPITAL_TAKE_DEBT] = RussianCardNames[CardNames.CAPITAL].singular;
RussianAbilityNames[AbilityNames.CHARM] = RussianCardNames[CardNames.CHARM].singular;
RussianAbilityNames[AbilityNames.FORUM] = RussianCardNames[CardNames.FORUM].singular;
RussianAbilityNames[AbilityNames.HERMIT] = RussianCardNames[CardNames.HERMIT].singular;
RussianAbilityNames[AbilityNames.DONATE] = RussianCardNames[CardNames.DONATE].singular;
RussianAbilityNames[AbilityNames.TAX_RECEIVE] = RussianCardNames[CardNames.TAX].singular;
RussianAbilityNames[AbilityNames.DOMINATE] = RussianCardNames[CardNames.DOMINATE].singular;
RussianAbilityNames[AbilityNames.VP_TO_AQUEDUCT] = "VP на Акведук";
RussianAbilityNames[AbilityNames.VP_FROM_AQUEDUCT] = "VP с Акведука";
RussianAbilityNames[AbilityNames.ARENA_DISCARD_ACTION] = RussianCardNames[CardNames.ARENA].singular;
RussianAbilityNames[AbilityNames.VP_FROM_BASILICA] = RussianCardNames[CardNames.BASILICA].singular;
RussianAbilityNames[AbilityNames.VP_FROM_BATHS] = RussianCardNames[CardNames.BATHS].singular;
RussianAbilityNames[AbilityNames.VP_FROM_BATTLEFIELD] = RussianCardNames[CardNames.BATTLEFIELD].singular;
RussianAbilityNames[AbilityNames.VP_FROM_COLONNADE] = RussianCardNames[CardNames.COLONNADE].singular;
RussianAbilityNames[AbilityNames.VP_TO_DEFILED_SHRINE] = "VP на Осквернённую Святыню";
RussianAbilityNames[AbilityNames.VP_FROM_DEFILED_SHRINE] = "VP с Осквернённой Святыни";
RussianAbilityNames[AbilityNames.VP_FROM_LABYRINTH] = RussianCardNames[CardNames.LABYRINTH].singular;
RussianAbilityNames[AbilityNames.MOUNTAIN_PASS_BIDDING] = RussianCardNames[CardNames.MOUNTAIN_PASS].singular;
RussianAbilityNames[AbilityNames.TOMB] = RussianCardNames[CardNames.TOMB].singular;
RussianAbilityNames[AbilityNames.LIGHTHOUSE_COIN] = RussianCardNames[CardNames.LIGHTHOUSE].singular;
RussianAbilityNames[AbilityNames.REACTION] = "Реакция";
RussianAbilityNames[AbilityNames.CALL_GUIDE] = "Вызвать " + RussianCardNames[CardNames.GUIDE].singular;
RussianAbilityNames[AbilityNames.CALL_RATCATCHER] = "Вызвать " + RussianCardNames[CardNames.RATCATCHER].singular;
RussianAbilityNames[AbilityNames.CALL_TRANSMOGRIFY] = "Вызвать " + RussianCardNames[CardNames.TRANSMOGRIFY].singular;
RussianAbilityNames[AbilityNames.CALL_TEACHER] = "Вызвать " + RussianCardNames[CardNames.TEACHER].singular;
RussianAbilityNames[AbilityNames.CALL_DUPLICATE] = "Вызвать " + RussianCardNames[CardNames.DUPLICATE].singular;
RussianAbilityNames[AbilityNames.CALL_COIN] = "Вызвать " + RussianCardNames[CardNames.COIN_OF_THE_REALM].singular;
RussianAbilityNames[AbilityNames.CALL_ROYAL_CARRIAGE] = "Вызвать " + RussianCardNames[CardNames.ROYAL_CARRIAGE].singular;
RussianAbilityNames[AbilityNames.START_OF_TURN] = "Начать ход";
RussianAbilityNames[AbilityNames.START_BUY_PHASE] = "START_BUY_PHASE";
RussianAbilityNames[AbilityNames.END_OF_BUY_PHASE] = "END_OF_BUY_PHASE";
RussianAbilityNames[AbilityNames.BETWEEN_TURNS] = "BETWEEN_TURNS";
RussianAbilityNames[AbilityNames.END_OF_TURN] = "END_OF_TURN";
RussianAbilityNames[AbilityNames.SUMMON_PLAY] = RussianCardNames[CardNames.SUMMON].singular;
RussianAbilityNames[AbilityNames.CARAVAN_GUARD_REACTION] = RussianCardNames[CardNames.CARAVAN_GUARD].singular;
RussianAbilityNames[AbilityNames.TRAVELLING_FAIR_REACTION] = RussianCardNames[CardNames.TRAVELLING_FAIR].singular;
RussianAbilityNames[AbilityNames.MOAT_REACTION] = RussianCardNames[CardNames.MOAT].singular;
RussianAbilityNames[AbilityNames.HORSE_TRADERS_REACTION] = RussianCardNames[CardNames.HORSE_TRADERS].singular;
RussianAbilityNames[AbilityNames.BEGGAR_REACTION] = RussianCardNames[CardNames.BEGGAR].singular;
RussianAbilityNames[AbilityNames.MARKET_SQUARE_REACTION] = RussianCardNames[CardNames.MARKET_SQUARE].singular;
RussianAbilityNames[AbilityNames.TRADER_REACTION] = RussianCardNames[CardNames.TRADER].singular;
RussianAbilityNames[AbilityNames.DIPLOMAT_REACTION] = RussianCardNames[CardNames.DIPLOMAT].singular;
RussianAbilityNames[AbilityNames.TOPDECK_REACTION] = "На верх колоды";
RussianAbilityNames[AbilityNames.WATCHTOWER_TRASH_REACTION] = "Выкинуть";
RussianAbilityNames[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "На верх колоды";
RussianAbilityNames[AbilityNames.PRINCE_PLAY] = RussianCardNames[CardNames.PRINCE].singular;
RussianAbilityNames[AbilityNames.DELUDED_RETURNED] = "Вернуть Состояние";
RussianAbilityNames[AbilityNames.ENVIOUS_RETURNED] = "Вернуть Состояние";
RussianAbilityNames[AbilityNames.RECEIVE_BOON] = "Принять Благодеяние";
RussianAbilityNames[AbilityNames.COBBLER_ABILITY] = RussianCardNames[CardNames.COBBLER].singular;
RussianAbilityNames[AbilityNames.CRYPT_ABILITY] = RussianCardNames[CardNames.CRYPT].singular;
RussianAbilityNames[AbilityNames.DEN_OF_SIN_ABILITY] = RussianCardNames[CardNames.DEN_OF_SIN].singular;
RussianAbilityNames[AbilityNames.FAITHFUL_HOUND] = RussianCardNames[CardNames.FAITHFUL_HOUND].singular;
RussianAbilityNames[AbilityNames.FAITHFUL_HOUND_RETURN] = RussianCardNames[CardNames.FAITHFUL_HOUND].singular;
RussianAbilityNames[AbilityNames.GHOST_TOWN_ABILITY] = RussianCardNames[CardNames.GHOST_TOWN].singular;
RussianAbilityNames[AbilityNames.GUARDIAN_COIN] = RussianCardNames[CardNames.GUARDIAN].singular;
RussianAbilityNames[AbilityNames.RAIDER_ABILITY] = RussianCardNames[CardNames.RAIDER].singular;
RussianAbilityNames[AbilityNames.SECRET_CAVE] = RussianCardNames[CardNames.SECRET_CAVE].singular;
RussianAbilityNames[AbilityNames.CEMETERY] = RussianCardNames[CardNames.CEMETERY].singular;
RussianAbilityNames[AbilityNames.HAUNTED_MIRROR] = RussianCardNames[CardNames.HAUNTED_MIRROR].singular;
RussianAbilityNames[AbilityNames.GHOST] = RussianCardNames[CardNames.GHOST].singular;
RussianAbilityNames[AbilityNames.REMOVE_FLIPPED_STATUS] = "X";
RussianAbilityNames[AbilityNames.LOST_IN_THE_WOODS] = RussianCardNames[CardNames.LOST_IN_THE_WOODS].singular;
RussianAbilityNames[AbilityNames.BLESSED_VILLAGE] = RussianCardNames[CardNames.BLESSED_VILLAGE].singular;
RussianAbilityNames[AbilityNames.FORTRESS] = RussianCardNames[CardNames.FORTRESS].singular;
RussianAbilityNames[AbilityNames.RECEIVE_HEX] = "Принять Порчу";
RussianAbilityNames[AbilityNames.HOVEL_TRASH] = RussianCardNames[CardNames.HOVEL].singular;
RussianAbilityNames[AbilityNames.TUNNEL_REVEAL] = RussianCardNames[CardNames.TUNNEL].singular;
RussianAbilityNames[AbilityNames.CHANGELING_MAY_EXCHANGE] = "Обменять на " + RussianCardNames[CardNames.CHANGELING].singular;

RussianAbilityNames[AbilityNames.DUCAT] = RussianCardNames[CardNames.DUCAT].singular;
RussianAbilityNames[AbilityNames.CARGO_SHIP_SET_ASIDE] = RussianCardNames[CardNames.CARGO_SHIP].singular;
RussianAbilityNames[AbilityNames.CARGO_SHIP_RETURN] = RussianCardNames[CardNames.CARGO_SHIP].singular;
RussianAbilityNames[AbilityNames.IMPROVE] = RussianCardNames[CardNames.IMPROVE].singular;
RussianAbilityNames[AbilityNames.PRIEST] = RussianCardNames[CardNames.PRIEST].singular;
RussianAbilityNames[AbilityNames.RESEARCH] = RussianCardNames[CardNames.RESEARCH].singular;
RussianAbilityNames[AbilityNames.SILK_MERCHANT] = RussianCardNames[CardNames.SILK_MERCHANT].singular;
RussianAbilityNames[AbilityNames.LACKEYS] = RussianCardNames[CardNames.LACKEYS].singular;
RussianAbilityNames[AbilityNames.SPICES] = RussianCardNames[CardNames.SPICES].singular;
RussianAbilityNames[AbilityNames.KEY] = RussianCardNames[CardNames.KEY].singular;
RussianAbilityNames[AbilityNames.TREASURE_CHEST] = RussianCardNames[CardNames.TREASURE_CHEST].singular;
RussianAbilityNames[AbilityNames.FLAG_BEARER] = RussianCardNames[CardNames.FLAG_BEARER].singular;
RussianAbilityNames[AbilityNames.CATHEDRAL] = RussianCardNames[CardNames.CATHEDRAL].singular;
RussianAbilityNames[AbilityNames.CITY_GATE] = RussianCardNames[CardNames.CITY_GATE].singular;
RussianAbilityNames[AbilityNames.PAGEANT] = RussianCardNames[CardNames.PAGEANT].singular;
RussianAbilityNames[AbilityNames.SEWERS_MAY_TRASH] = RussianCardNames[CardNames.SEWERS].singular;
RussianAbilityNames[AbilityNames.EXPLORATION] = RussianCardNames[CardNames.EXPLORATION].singular;
RussianAbilityNames[AbilityNames.FAIR] = RussianCardNames[CardNames.FAIR].singular;
RussianAbilityNames[AbilityNames.SILOS] = RussianCardNames[CardNames.SILOS].singular;
RussianAbilityNames[AbilityNames.ACADEMY] = RussianCardNames[CardNames.ACADEMY].singular;
RussianAbilityNames[AbilityNames.GUILDHALL] = RussianCardNames[CardNames.GUILDHALL].singular;
RussianAbilityNames[AbilityNames.PIAZZA] = RussianCardNames[CardNames.PIAZZA].singular;
RussianAbilityNames[AbilityNames.BARRACKS] = RussianCardNames[CardNames.BARRACKS].singular;
RussianAbilityNames[AbilityNames.CROP_ROTATION] = RussianCardNames[CardNames.CROP_ROTATION].singular;
RussianAbilityNames[AbilityNames.INNOVATION] = RussianCardNames[CardNames.INNOVATION].singular;
RussianAbilityNames[AbilityNames.CITADEL] = RussianCardNames[CardNames.CITADEL].singular;
RussianAbilityNames[AbilityNames.SINISTER_PLOT] = RussianCardNames[CardNames.SINISTER_PLOT].singular;
RussianAbilityNames[AbilityNames.ENCAMPMENT_RETURNED] = RussianCardNames[CardNames.ENCAMPMENT].singular;
RussianAbilityNames[AbilityNames.EMBARGO_GAIN_CURSE] = RussianCardNames[CardNames.EMBARGO].singular;
RussianAbilityNames[AbilityNames.POSSESSION_GAIN_INSTEAD] = RussianCardNames[CardNames.POSSESSION].singular;
RussianAbilityNames[AbilityNames.TRADE_ROUTE_MOVE_TOKEN] = RussianCardNames[CardNames.TRADE_ROUTE].singular;
RussianAbilityNames[AbilityNames.CAPTAIN] = RussianCardNames[CardNames.CAPTAIN].singular;
RussianAbilityNames[AbilityNames.CHURCH] = RussianCardNames[CardNames.CHURCH].singular;
RussianAbilityNames[AbilityNames.POSSESSION_SETS_ASIDE] = RussianCardNames[CardNames.POSSESSION].singular;
RussianAbilityNames[AbilityNames.MINT] = RussianCardNames[CardNames.MINT].singular;

RussianAbilityNames[AbilityNames.SLEIGH_TOPDECK_REACTION] = "Положить на верх колоды";
RussianAbilityNames[AbilityNames.SLEIGH_PUT_IN_HAND_REACTION] = "Положить в руку";
RussianAbilityNames[AbilityNames.BLACK_CAT_REACTION] = RussianCardNames[CardNames.BLACK_CAT].singular;
RussianAbilityNames[AbilityNames.DISCARD_FROM_EXILE] = "Сбросить из Изгнания";
RussianAbilityNames[AbilityNames.EXILE_BY_NAME] = "Изгнать";
RussianAbilityNames[AbilityNames.SHEEPDOG_REACTION] = RussianCardNames[CardNames.SHEEPDOG].singular;
RussianAbilityNames[AbilityNames.CAVALRY] = RussianCardNames[CardNames.CAVALRY].singular;
RussianAbilityNames[AbilityNames.HOSTELRY] = RussianCardNames[CardNames.HOSTELRY].singular;
RussianAbilityNames[AbilityNames.VILLAGE_GREEN_VILLAGE] = RussianCardNames[CardNames.VILLAGE_GREEN].singular;
RussianAbilityNames[AbilityNames.VILLAGE_GREEN_PLAY] = RussianCardNames[CardNames.VILLAGE_GREEN].singular;
RussianAbilityNames[AbilityNames.BARGE] = RussianCardNames[CardNames.BARGE].singular;
RussianAbilityNames[AbilityNames.FALCONER_REACTION] = RussianCardNames[CardNames.FALCONER].singular;
RussianAbilityNames[AbilityNames.GATEKEEPER_EXILE] = RussianCardNames[CardNames.GATEKEEPER].singular;
RussianAbilityNames[AbilityNames.GATEKEEPER_DURATION] = RussianCardNames[CardNames.GATEKEEPER].singular;
RussianAbilityNames[AbilityNames.LIVERY] = RussianCardNames[CardNames.LIVERY].singular;
RussianAbilityNames[AbilityNames.MASTERMIND] = RussianCardNames[CardNames.MASTERMIND].singular;
RussianAbilityNames[AbilityNames.DELAY_PLAY] = RussianCardNames[CardNames.DELAY].singular;
RussianAbilityNames[AbilityNames.INVEST] = RussianCardNames[CardNames.INVEST].singular;
RussianAbilityNames[AbilityNames.REAP_PLAY] = RussianCardNames[CardNames.REAP].singular;
RussianAbilityNames[AbilityNames.PLUS_CARD_TOKEN] = "+Карта";
RussianAbilityNames[AbilityNames.PLUS_BUY_TOKEN] = "+Покупка";
RussianAbilityNames[AbilityNames.PLUS_ACTION_TOKEN] = "+Действие";
RussianAbilityNames[AbilityNames.PLUS_COIN_TOKEN] = "+Монета";
RussianAbilityNames[AbilityNames.KILN] = RussianCardNames[CardNames.KILN].singular;
RussianAbilityNames[AbilityNames.TURTLE_PLAY] = RussianCardNames[CardNames.WAY_OF_THE_TURTLE].singular;
RussianAbilityNames[AbilityNames.GIVE_PLUS_CARDS] = RussianAbilityNames[AbilityNames.PLUS_CARD_TOKEN];
RussianAbilityNames[AbilityNames.GIVE_PLUS_BUYS] = RussianAbilityNames[AbilityNames.PLUS_BUY_TOKEN];
RussianAbilityNames[AbilityNames.GIVE_PLUS_ACTIONS] = RussianAbilityNames[AbilityNames.PLUS_ACTION_TOKEN];
RussianAbilityNames[AbilityNames.GIVE_PLUS_COINS] = RussianAbilityNames[AbilityNames.PLUS_COIN_TOKEN];

// Allies
RussianAbilityNames[AbilityNames.CONJURER] = RussianCardNames[CardNames.CONJURER].singular;
RussianAbilityNames[AbilityNames.CONTRACT_ABILITY] = RussianCardNames[CardNames.CONTRACT].singular;
RussianAbilityNames[AbilityNames.ENCHANTRESS_REPLACE_ABILITY] = RussianCardNames[CardNames.ENCHANTRESS].singular;
RussianAbilityNames[AbilityNames.GALLERIA] = RussianCardNames[CardNames.GALLERIA].singular;
RussianAbilityNames[AbilityNames.GARRISON_ADD_TOKEN] = RussianCardNames[CardNames.GARRISON].singular;
RussianAbilityNames[AbilityNames.GARRISON_REMOVE_ABILITY] = RussianCardNames[CardNames.GARRISON].singular;
RussianAbilityNames[AbilityNames.GUILDMASTER] = RussianCardNames[CardNames.GUILDMASTER].singular;
RussianAbilityNames[AbilityNames.HIGHWAYMAN_NEXT_TURN_ABILITY] = RussianCardNames[CardNames.HIGHWAYMAN].singular;
RussianAbilityNames[AbilityNames.HIGHWAYMAN_NULLIFIED] = RussianCardNames[CardNames.HIGHWAYMAN].singular;
RussianAbilityNames[AbilityNames.HIGHWAYMAN_REPLACE_ABILITY] = RussianCardNames[CardNames.HIGHWAYMAN].singular;
RussianAbilityNames[AbilityNames.IMPORTER_ABILITY] = RussianCardNames[CardNames.IMPORTER].singular;
RussianAbilityNames[AbilityNames.LICH] = RussianCardNames[CardNames.LICH].singular;
RussianAbilityNames[AbilityNames.ROYAL_GALLEY_PLAY] = RussianCardNames[CardNames.ROYAL_GALLEY].singular;
RussianAbilityNames[AbilityNames.ROYAL_GALLEY_SET_ASIDE] = RussianCardNames[CardNames.ROYAL_GALLEY].singular;
RussianAbilityNames[AbilityNames.SKIRMISHER] = RussianCardNames[CardNames.SKIRMISHER].singular;
RussianAbilityNames[AbilityNames.STRONGHOLD_ABILITY] = RussianCardNames[CardNames.STRONGHOLD].singular;
RussianAbilityNames[AbilityNames.SYCOPHANT] = RussianCardNames[CardNames.SYCOPHANT].singular;
RussianAbilityNames[AbilityNames.TERRITORY_GAIN] = RussianCardNames[CardNames.TERRITORY].singular;
RussianAbilityNames[AbilityNames.WARLORD_ABILITY] = RussianCardNames[CardNames.WARLORD].singular;

RussianAbilityNames[AbilityNames.ARCHITECTS_GUILD] = RussianCardNames[CardNames.ARCHITECTS_GUILD].singular;
RussianAbilityNames[AbilityNames.BAND_OF_NOMADS_ACTION] = "Действие";
RussianAbilityNames[AbilityNames.BAND_OF_NOMADS_BUY] = "Покупка";
RussianAbilityNames[AbilityNames.BAND_OF_NOMADS_CARD] = "Карта";
RussianAbilityNames[AbilityNames.CAVE_DWELLERS] = RussianCardNames[CardNames.CAVE_DWELLERS].singular;
RussianAbilityNames[AbilityNames.CIRCLE_OF_WITCHES] = RussianCardNames[CardNames.CIRCLE_OF_WITCHES].singular;
RussianAbilityNames[AbilityNames.CITY_STATE] = RussianCardNames[CardNames.CITY_STATE].singular;
RussianAbilityNames[AbilityNames.CITY_STATE_PLAY] = RussianCardNames[CardNames.CITY_STATE].singular;
RussianAbilityNames[AbilityNames.COASTAL_HAVEN] = RussianCardNames[CardNames.COASTAL_HAVEN].singular;
RussianAbilityNames[AbilityNames.CRAFTERS_GUILD] = RussianCardNames[CardNames.CRAFTERS_GUILD].singular;
RussianAbilityNames[AbilityNames.DESERT_GUIDES] = RussianCardNames[CardNames.DESERT_GUIDES].singular;
RussianAbilityNames[AbilityNames.FAMILY_OF_INVENTORS] = RussianCardNames[CardNames.FAMILY_OF_INVENTORS].singular;
RussianAbilityNames[AbilityNames.FELLOWSHIP_OF_SCRIBES] = RussianCardNames[CardNames.FELLOWSHIP_OF_SCRIBES].singular;
RussianAbilityNames[AbilityNames.FOREST_DWELLERS] = RussianCardNames[CardNames.FOREST_DWELLERS].singular;
RussianAbilityNames[AbilityNames.GANG_OF_PICKPOCKETS] = RussianCardNames[CardNames.GANG_OF_PICKPOCKETS].singular;
RussianAbilityNames[AbilityNames.ISLAND_FOLK] = RussianCardNames[CardNames.ISLAND_FOLK].singular;
RussianAbilityNames[AbilityNames.LEAGUE_OF_BANKERS] = RussianCardNames[CardNames.LEAGUE_OF_BANKERS].singular;
RussianAbilityNames[AbilityNames.LEAGUE_OF_SHOPKEEPERS] = RussianCardNames[CardNames.LEAGUE_OF_SHOPKEEPERS].singular;
RussianAbilityNames[AbilityNames.MARKET_TOWNS] = RussianCardNames[CardNames.MARKET_TOWNS].singular;
RussianAbilityNames[AbilityNames.MOUNTAIN_FOLK] = RussianCardNames[CardNames.MOUNTAIN_FOLK].singular;
RussianAbilityNames[AbilityNames.PEACEFUL_CULT] = RussianCardNames[CardNames.PEACEFUL_CULT].singular;
RussianAbilityNames[AbilityNames.TRAPPERS_LODGE] = RussianCardNames[CardNames.TRAPPERS_LODGE].singular;
RussianAbilityNames[AbilityNames.WOODWORKERS_GUILD] = RussianCardNames[CardNames.WOODWORKERS_GUILD].singular;

RussianAbilityNames[AbilityNames.ASTROLABE_ABILITY] = RussianCardNames[CardNames.ASTROLABE].singular;
RussianAbilityNames[AbilityNames.BLOCKADE_ABILITY] = RussianCardNames[CardNames.BLOCKADE].singular;
RussianAbilityNames[AbilityNames.CORSAIR_NEXT_TURN_ABILITY] = RussianCardNames[CardNames.CORSAIR].singular;
RussianAbilityNames[AbilityNames.CORSAIR_TRASH] = RussianCardNames[CardNames.CORSAIR].singular;
RussianAbilityNames[AbilityNames.MONKEY_NEXT_TURN_ABILITY] = RussianCardNames[CardNames.MONKEY].singular;
RussianAbilityNames[AbilityNames.PIRATE_ABILITY] = RussianCardNames[CardNames.PIRATE].singular;
RussianAbilityNames[AbilityNames.PIRATE_REACTION] = RussianCardNames[CardNames.PIRATE].singular;
RussianAbilityNames[AbilityNames.SAILOR_NEXT_TURN] = RussianCardNames[CardNames.SAILOR].singular;
RussianAbilityNames[AbilityNames.SAILOR_REACTION] = RussianCardNames[CardNames.SAILOR].singular;
RussianAbilityNames[AbilityNames.SEA_WITCH_ABILITY] = RussianCardNames[CardNames.SEA_WITCH].singular;
RussianAbilityNames[AbilityNames.TIDE_POOLS_ABILITY] = RussianCardNames[CardNames.TIDE_POOLS].singular;
RussianAbilityNames[AbilityNames.TREASURY] = RussianCardNames[CardNames.TREASURY].singular;
RussianAbilityNames[AbilityNames.CLERK_ABILITY] = RussianCardNames[CardNames.CLERK].singular;

RussianAbilityNames[AbilityNames.BERSERKER] = RussianCardNames[CardNames.BERSERKER].singular;
RussianAbilityNames[AbilityNames.CAULDRON_CURSING] = RussianCardNames[CardNames.CAULDRON].singular;
RussianAbilityNames[AbilityNames.GUARD_DOG_REACTION] = RussianCardNames[CardNames.GUARD_DOG].singular;
RussianAbilityNames[AbilityNames.NOMADS] = RussianCardNames[CardNames.NOMADS].singular;
RussianAbilityNames[AbilityNames.SOUK] = RussianCardNames[CardNames.SOUK].singular;
RussianAbilityNames[AbilityNames.TRAIL_REACTION] = RussianCardNames[CardNames.TRAIL].singular;
RussianAbilityNames[AbilityNames.WEAVER_PLAY] = RussianCardNames[CardNames.WEAVER].singular;
RussianAbilityNames[AbilityNames.ALCHEMIST_TOPDECK] = RussianCardNames[CardNames.ALCHEMIST].singular;
RussianAbilityNames[AbilityNames.MERCHANT_GUILD] = RussianCardNames[CardNames.MERCHANT_GUILD].singular;
RussianAbilityNames[AbilityNames.HERBALIST_TOPDECK] = RussianCardNames[CardNames.HERBALIST].singular;
RussianAbilityNames[AbilityNames.SCHEME_TOPDECK] = RussianCardNames[CardNames.SCHEME].singular;
RussianAbilityNames[AbilityNames.FROG_TOPDECK] = RussianCardNames[CardNames.WAY_OF_THE_FROG].singular;
RussianAbilityNames[AbilityNames.MERCHANT_CAMP_TOPDECK] = RussianCardNames[CardNames.MERCHANT_CAMP].singular;
RussianAbilityNames[AbilityNames.WALLED_VILLAGE_TOPDECK] = RussianCardNames[CardNames.WALLED_VILLAGE].singular;
RussianAbilityNames[AbilityNames.TENT_TOPDECK] = RussianCardNames[CardNames.TENT].singular;
RussianAbilityNames[AbilityNames.HORN_TOPDECK] = RussianCardNames[CardNames.HORN].singular;
RussianAbilityNames[AbilityNames.PAGE_EXCHANGE] = RussianCardNames[CardNames.TREASURE_HUNTER].singular;
RussianAbilityNames[AbilityNames.TREASURE_HUNTER_EXCHANGE] = RussianCardNames[CardNames.WARRIOR].singular;
RussianAbilityNames[AbilityNames.WARRIOR_EXCHANGE] = RussianCardNames[CardNames.HERO].singular;
RussianAbilityNames[AbilityNames.HERO_EXCHANGE] = RussianCardNames[CardNames.CHAMPION].singular;
RussianAbilityNames[AbilityNames.PEASANT_EXCHANGE] = RussianCardNames[CardNames.SOLDIER].singular;
RussianAbilityNames[AbilityNames.SOLDIER_EXCHANGE] = RussianCardNames[CardNames.FUGITIVE].singular;
RussianAbilityNames[AbilityNames.FUGITIVE_EXCHANGE] = RussianCardNames[CardNames.DISCIPLE].singular;
RussianAbilityNames[AbilityNames.DISCIPLE_EXCHANGE] = RussianCardNames[CardNames.TEACHER].singular;

//Plunder
RussianAbilityNames[AbilityNames.CAGE_TRASH] = RussianCardNames[CardNames.CAGE].singular;
RussianAbilityNames[AbilityNames.CAGE_PUT_IN_HAND] = RussianCardNames[CardNames.CAGE].singular;
RussianAbilityNames[AbilityNames.GROTTO] = RussianCardNames[CardNames.GROTTO].singular;
RussianAbilityNames[AbilityNames.JEWELLED_EGG_GAIN_LOOT] = RussianCardNames[CardNames.JEWELLED_EGG].singular;
RussianAbilityNames[AbilityNames.SEARCH] = RussianCardNames[CardNames.SEARCH].singular;
RussianAbilityNames[AbilityNames.SHAMAN] = RussianCardNames[CardNames.SHAMAN].singular;
RussianAbilityNames[AbilityNames.SECLUDED_SHRINE] = RussianCardNames[CardNames.SECLUDED_SHRINE].singular;
RussianAbilityNames[AbilityNames.SIREN_GAIN] = RussianCardNames[CardNames.SIREN].singular;
RussianAbilityNames[AbilityNames.SIREN_DURATION] = RussianCardNames[CardNames.SIREN].singular;
RussianAbilityNames[AbilityNames.STOWAWAY_DRAW] = RussianCardNames[CardNames.STOWAWAY].singular;
RussianAbilityNames[AbilityNames.STOWAWAY_REACTION] = RussianCardNames[CardNames.STOWAWAY].singular;
RussianAbilityNames[AbilityNames.TASKMASTER] = RussianCardNames[CardNames.TASKMASTER].singular;
RussianAbilityNames[AbilityNames.ABUNDANCE] = RussianCardNames[CardNames.ABUNDANCE].singular;
RussianAbilityNames[AbilityNames.CABIN_BOY] = RussianCardNames[CardNames.CABIN_BOY].singular;
RussianAbilityNames[AbilityNames.CRUCIBLE] = RussianCardNames[CardNames.CRUCIBLE].singular;
RussianAbilityNames[AbilityNames.FLAGSHIP_ACTIVATE] = RussianCardNames[CardNames.FLAGSHIP].singular;
RussianAbilityNames[AbilityNames.FLAGSHIP_REPLAY] = RussianCardNames[CardNames.FLAGSHIP].singular;
RussianAbilityNames[AbilityNames.GONDOLA_DURATION] = RussianCardNames[CardNames.GONDOLA].singular;
RussianAbilityNames[AbilityNames.GONDOLA_PLAY] = RussianCardNames[CardNames.GONDOLA].singular;
RussianAbilityNames[AbilityNames.HARBOR_VILLAGE] = RussianCardNames[CardNames.HARBOR_VILLAGE].singular;
RussianAbilityNames[AbilityNames.LANDING_PARTY] = RussianCardNames[CardNames.LANDING_PARTY].singular;
RussianAbilityNames[AbilityNames.MAPMAKER] = RussianCardNames[CardNames.MAPMAKER].singular;
RussianAbilityNames[AbilityNames.ROPE] = RussianCardNames[CardNames.ROPE].singular;
RussianAbilityNames[AbilityNames.BURIED_TREASURE_PLAY] = RussianCardNames[CardNames.BURIED_TREASURE].singular;
RussianAbilityNames[AbilityNames.BURIED_TREASURE_DURATION] = RussianCardNames[CardNames.BURIED_TREASURE].singular;
RussianAbilityNames[AbilityNames.CREW_DURATION] = RussianCardNames[CardNames.CREW].singular;
RussianAbilityNames[AbilityNames.CUTTHROAT_GAIN] = RussianCardNames[CardNames.CUTTHROAT].singular;
RussianAbilityNames[AbilityNames.ENLARGE] = RussianCardNames[CardNames.ENLARGE].singular;
RussianAbilityNames[AbilityNames.FRIGATE_DURATION] = RussianCardNames[CardNames.FRIGATE].singular;
RussianAbilityNames[AbilityNames.FRIGATE_DISCARD] = RussianCardNames[CardNames.FRIGATE].singular;
RussianAbilityNames[AbilityNames.LONGSHIP_DURATION] = RussianCardNames[CardNames.LONGSHIP].singular;
RussianAbilityNames[AbilityNames.MINING_ROAD] = RussianCardNames[CardNames.MINING_ROAD].singular;
RussianAbilityNames[AbilityNames.QUARTERMASTER] = RussianCardNames[CardNames.QUARTERMASTER].singular;
RussianAbilityNames[AbilityNames.TRICKSTER_REACTION] = RussianCardNames[CardNames.TRICKSTER].singular;
RussianAbilityNames[AbilityNames.TRICKSTER_RETURN_TO_HAND] = RussianCardNames[CardNames.TRICKSTER].singular;
RussianAbilityNames[AbilityNames.WEALTHY_VILLAGE] = RussianCardNames[CardNames.WEALTHY_VILLAGE].singular;
RussianAbilityNames[AbilityNames.DELIVER_SET_ASIDE] = RussianCardNames[CardNames.DELIVER].singular;
RussianAbilityNames[AbilityNames.DELIVER_RETURN] = RussianCardNames[CardNames.DELIVER].singular;
RussianAbilityNames[AbilityNames.RUSH] = RussianCardNames[CardNames.RUSH].singular;
RussianAbilityNames[AbilityNames.MIRROR] = RussianCardNames[CardNames.MIRROR].singular;
RussianAbilityNames[AbilityNames.PREPARE] = RussianCardNames[CardNames.PREPARE].singular;
RussianAbilityNames[AbilityNames.AMPHORA] = RussianCardNames[CardNames.AMPHORA].singular;
RussianAbilityNames[AbilityNames.DOUBLOONS] = RussianCardNames[CardNames.DOUBLOONS].singular;
RussianAbilityNames[AbilityNames.ENDLESS_CHALICE] = RussianCardNames[CardNames.ENDLESS_CHALICE].singular;
RussianAbilityNames[AbilityNames.FIGUREHEAD] = RussianCardNames[CardNames.FIGUREHEAD].singular;
RussianAbilityNames[AbilityNames.JEWELS] = RussianCardNames[CardNames.JEWELS].singular;
RussianAbilityNames[AbilityNames.PUZZLE_BOX_RETURN] = RussianCardNames[CardNames.PUZZLE_BOX].singular;
RussianAbilityNames[AbilityNames.SHIELD_REACTION] = RussianCardNames[CardNames.SHIELD].singular;
RussianAbilityNames[AbilityNames.SPELL_SCROLL] = RussianCardNames[CardNames.SPELL_SCROLL].singular;
RussianAbilityNames[AbilityNames.CURSED_GAIN] = RussianCardNames[CardNames.CURSED].singular;
RussianAbilityNames[AbilityNames.FAWNING_GAIN] = RussianCardNames[CardNames.FAWNING].singular;
RussianAbilityNames[AbilityNames.SCHEDULER] = RussianCardNames[CardNames.FRIENDLY].singular;
RussianAbilityNames[AbilityNames.FRIENDLY_ABILITY] = RussianCardNames[CardNames.FRIENDLY].singular;
RussianAbilityNames[AbilityNames.HASTY_SET_ASIDE] = RussianCardNames[CardNames.HASTY].singular;
RussianAbilityNames[AbilityNames.HASTY_PLAY] = RussianCardNames[CardNames.HASTY].singular;
RussianAbilityNames[AbilityNames.INHERITED] = RussianCardNames[CardNames.INHERITED].singular;
RussianAbilityNames[AbilityNames.INSPIRING] = RussianCardNames[CardNames.INSPIRING].singular;
RussianAbilityNames[AbilityNames.NEARBY] = RussianCardNames[CardNames.NEARBY].singular;
RussianAbilityNames[AbilityNames.PATIENT_SCHEDULER] = RussianCardNames[CardNames.PATIENT].singular;
RussianAbilityNames[AbilityNames.PATIENT_SET_ASIDE] = RussianCardNames[CardNames.PATIENT].singular;
RussianAbilityNames[AbilityNames.PATIENT_PLAY] = RussianCardNames[CardNames.PATIENT].singular;
RussianAbilityNames[AbilityNames.PIOUS] = RussianCardNames[CardNames.PIOUS].singular;
RussianAbilityNames[AbilityNames.RECKLESS_REPEAT] = RussianCardNames[CardNames.RECKLESS].singular;
RussianAbilityNames[AbilityNames.RECKLESS_RETURN] = RussianCardNames[CardNames.RECKLESS].singular;
RussianAbilityNames[AbilityNames.RICH_GAIN] = RussianCardNames[CardNames.RICH].singular;
RussianAbilityNames[AbilityNames.SHY] = RussianCardNames[CardNames.SHY].singular;
RussianAbilityNames[AbilityNames.TIRELESS_SET_ASIDE] = RussianCardNames[CardNames.TIRELESS].singular;
RussianAbilityNames[AbilityNames.TIRELESS_RETURN] = RussianCardNames[CardNames.TIRELESS].singular;
RussianAbilityNames[AbilityNames.STAR_CHART] = RussianCardNames[CardNames.STAR_CHART].singular;
RussianAbilityNames[AbilityNames.ORDER_OF_ASTROLOGERS] = RussianCardNames[CardNames.ORDER_OF_ASTROLOGERS].singular;
RussianAbilityNames[AbilityNames.ORDER_OF_MASONS] = RussianCardNames[CardNames.ORDER_OF_MASONS].singular;
RussianAbilityNames[AbilityNames.FATED] = RussianCardNames[CardNames.FATED].singular;
RussianAbilityNames[AbilityNames.AVOID] = RussianCardNames[CardNames.AVOID].singular;
RussianAbilityNames[AbilityNames.HERBALIST_ASK_FOR_DISCARD] = RussianCardNames[CardNames.HERBALIST].singular;
RussianAbilityNames[AbilityNames.SCHEME_ASK_FOR_DISCARD] = RussianCardNames[CardNames.SCHEME].singular;
RussianAbilityNames[AbilityNames.TRICKSTER_ASK_FOR_DISCARD] = RussianCardNames[CardNames.TRICKSTER].singular;
RussianAbilityNames[AbilityNames.SAUNA_TRASH_ABILITY] = RussianCardNames[CardNames.SAUNA].singular;
RussianAbilityNames[AbilityNames.CHAMELEON_RESOLUTION] = RussianCardNames[CardNames.WAY_OF_THE_CHAMELEON].singular;
RussianAbilityNames[AbilityNames.MARCHLAND] = RussianCardNames[CardNames.MARCHLAND].singular;
RussianAbilityNames[AbilityNames.FARMHANDS_SET_ASIDE] = RussianCardNames[CardNames.FARMHANDS].singular;
RussianAbilityNames[AbilityNames.FARMHANDS_PLAY] = RussianCardNames[CardNames.FARMHANDS].singular;
RussianAbilityNames[AbilityNames.JOUST_DISCARD] = RussianCardNames[CardNames.JOUST].singular;
RussianAbilityNames[AbilityNames.INFIRMARY_OVERPAY] = RussianCardNames[CardNames.INFIRMARY].singular;
RussianAbilityNames[AbilityNames.RESOLVE_INFIRMARY_OVERPAY] = RussianCardNames[CardNames.INFIRMARY].singular;
RussianAbilityNames[AbilityNames.DRAW_FROM_FOOTPAD] = RussianCardNames[CardNames.FOOTPAD].singular;
RussianAbilityNames[AbilityNames.FARRIER_OVERPAY] = RussianCardNames[CardNames.FARRIER].singular;
RussianAbilityNames[AbilityNames.RESOLVE_FARRIER_OVERPAY] = RussianCardNames[CardNames.FARRIER].singular;
RussianAbilityNames[AbilityNames.DRAW_FROM_SQUIRREL] = RussianCardNames[CardNames.WAY_OF_THE_SQUIRREL].singular;
RussianAbilityNames[AbilityNames.DRAW_FROM_RIVER] = RussianCardNames[CardNames.THE_RIVERS_GIFT].singular;
RussianAbilityNames[AbilityNames.DRAW_FROM_FARRIER] = RussianCardNames[CardNames.FARRIER].singular;
RussianAbilityNames[AbilityNames.FERRYMAN] = RussianCardNames[CardNames.FERRYMAN].singular;

RussianAbilityNames[AbilityNames.BIDING_TIME_SET_ASIDE_HAND] = RussianCardNames[CardNames.BIDING_TIME].singular;
RussianAbilityNames[AbilityNames.BIDING_TIME_RETURN_TO_HAND] = RussianCardNames[CardNames.BIDING_TIME].singular;
RussianAbilityNames[AbilityNames.ENLIGHTENMENT_CANTRIP] = RussianCardNames[CardNames.ENLIGHTENMENT].singular;
RussianAbilityNames[AbilityNames.ENLIGHTENMENT_REPLACE_ABILITY] = RussianCardNames[CardNames.ENLIGHTENMENT].singular;
RussianAbilityNames[AbilityNames.GOOD_HARVEST] = RussianCardNames[CardNames.GOOD_HARVEST].singular;
RussianAbilityNames[AbilityNames.HARSH_WINTER] = RussianCardNames[CardNames.HARSH_WINTER].singular;
RussianAbilityNames[AbilityNames.KIND_EMPEROR] = RussianCardNames[CardNames.KIND_EMPEROR].singular;
RussianAbilityNames[AbilityNames.PANIC_BUYS] = RussianCardNames[CardNames.PANIC].singular;
RussianAbilityNames[AbilityNames.PANIC_RETURN] = RussianCardNames[CardNames.PANIC].singular;
RussianAbilityNames[AbilityNames.PROGRESS] = RussianCardNames[CardNames.PROGRESS].singular;
RussianAbilityNames[AbilityNames.RAPID_EXPANSION_SET_ASIDE] = RussianCardNames[CardNames.RAPID_EXPANSION].singular;
RussianAbilityNames[AbilityNames.RAPID_EXPANSION_PLAY_LATER] = RussianCardNames[CardNames.RAPID_EXPANSION].singular;
RussianAbilityNames[AbilityNames.SICKNESS] = RussianCardNames[CardNames.SICKNESS].singular;
RussianAbilityNames[AbilityNames.RIVER_SHRINE] = RussianCardNames[CardNames.RIVER_SHRINE].singular;
RussianAbilityNames[AbilityNames.SAMURAI] = RussianCardNames[CardNames.SAMURAI].singular;
RussianAbilityNames[AbilityNames.DAIMYO_REPLAY] = RussianCardNames[CardNames.DAIMYO].singular;
RussianAbilityNames[AbilityNames.FORESIGHT_RETURN_TO_HAND] = RussianCardNames[CardNames.FORESIGHT].singular;