"use strict";

var FrenchQuestionDescriptions = {};

FrenchQuestionDescriptions[QuestionIds.GAME_RESOLVE_ABILITY] = ["Résoudre une capacité forcée :", "Résoudre un effet"];
FrenchQuestionDescriptions[QuestionIds.GAME_MAY_RESOLVE_ABILITY] = ["Résoudre une capacité optionnelle.", "Résoudre un effet"];
FrenchQuestionDescriptions[QuestionIds.GAME_MAY_REACT_WITH] = ["Réagir.", "Voulez-vous réagir ?"];
FrenchQuestionDescriptions[QuestionIds.GAME_TURN_SELECT] = ["Choisir un tour.", "Quel sera le prochain tour?"];
FrenchQuestionDescriptions[QuestionIds.GAME_MAY_BUY] = ["Vous pouvez acheter des cartes.", "Vous pouvez acheter des cartes"];
FrenchQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING] = ["commencer à acheter.", "Vous pouvez acheter des cartes"];
FrenchQuestionDescriptions[QuestionIds.GAME_MAY_BUY_NONCARDS] = ["acheter, mais pas des cartes.", "Vous pouvez acheter, mais pas des cartes"];
FrenchQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING_NONCARDS] = ["commencer à acheter, mais pas des cartes.", "Vous pouvez acheter, mais pas des cartes"];
FrenchQuestionDescriptions[QuestionIds.GAME_REPAY_HOW_MUCH] = ["Rembourser la dette.", "Vous pouvez rembourser votre dette"];
FrenchQuestionDescriptions[QuestionIds.GAME_SPEND_COIN_TOKENS] = ["Dépenser des jetons pièces.", "Vous pouvez dépenser des jetons pièces"];
FrenchQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_ACTION] = ["jouer une action de votre main.", "Vous pouvez jouer des actions"];
FrenchQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_TREASURES] = ["jouer des trésors.", "vous pouvez jouer des trésors"];
FrenchQuestionDescriptions[QuestionIds.GAME_PLAY_ALL_TREASURES] = ["jouer automatiquement les trésors.", "GAME_PLAY_ALL_TREASURES"];
FrenchQuestionDescriptions[QuestionIds.GAME_ACTION_PHASE] = ["C'est votre phase action", "Vous pouvez jouer des actions"];
FrenchQuestionDescriptions[QuestionIds.GAME_BUY_PHASE] = ["C'est votre phase achat", "Vous pouvez acheter des cartes"];
FrenchQuestionDescriptions[QuestionIds.GAME_BUY_PHASE_NONCARDS] = ["C'est votre phase achat", "Vous pouvez acheter, mais pas des cartes"];
FrenchQuestionDescriptions[QuestionIds.GAME_CLEANUP_PHASE] = ["Choisissez l'ordre de résolution de la phase ajustement.", "Choisissez les capacités d'ajustement"];
FrenchQuestionDescriptions[QuestionIds.GAME_USE_VILLAGER] = ["Vous pouvez utiliser un Villageois.", "Vous pouvez utiliser un Villageois"];
FrenchQuestionDescriptions[QuestionIds.GAME_USE_COFFERS] = ["Vous pouvez utiliser des Coffres.", "Vous pouvez utiliser des Coffres"];
FrenchQuestionDescriptions[QuestionIds.ARTISAN_GAIN] = ["Recevez en main une carte coûtant jusque $5. ", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.ARTISAN_TOPDECK] = ["Mettez une carte de votre main sur votre pioche.", "Mettez une carte sur la pioche"];
FrenchQuestionDescriptions[QuestionIds.BANDIT] = ["Écartez une carte de PLAYER0.", "Écartez une carte de PLAYER0."];
FrenchQuestionDescriptions[QuestionIds.BUREAUCRAT] = ["Dévoilez une carte Victoire de votre main et placez-la sur la pioche.", "Mettez une carte Victoire sur la pioche"];
FrenchQuestionDescriptions[QuestionIds.CELLAR] = ["Défaussez des cartes, et piochez-en le même nombre.", "Défaussez puis piochez"];
FrenchQuestionDescriptions[QuestionIds.CHAPEL] = ["Écartez jusqu'à 4 cartes de votre main.", "Écartez jusqu'à 4 cartes"];
FrenchQuestionDescriptions[QuestionIds.HARBINGER_TOPDECK] = ["Vous pouvez mettre une carte de votre défausse sur votre pioche.", "Vous pouvez mettre sur la pioche"];
FrenchQuestionDescriptions[QuestionIds.LIBRARY] = ["Vous pouvez mettre de côté CARDS0.", "Vous pouvez mettre de côté CARDS0"];
FrenchQuestionDescriptions[QuestionIds.MILITIA] = ["Défaussez jusqu'à avoir 3 cartes en main.", "Défaussez à 3 cartes"];
FrenchQuestionDescriptions[QuestionIds.MINE_TRASH] = ["Vous pouvez écarter un trésor de votre main. Recevez en main un trésor coûtant jusqu'à $3 de plus", "Vous pouvez améliorer un trésor"];
FrenchQuestionDescriptions[QuestionIds.MINE_GAIN] = ["Recevez en main un trésor coûtant jusqu'à AMOUNT0.", "Recevez un trésor"];
FrenchQuestionDescriptions[QuestionIds.MONEYLENDER] = ["Vous pouvez écarter un Cuivre de votre main pour +$3.", "Vous pouvez écarter un Cuivre"];
FrenchQuestionDescriptions[QuestionIds.POACHER] = ["Défaussez Argument0.", "Défaussez Argument0."];
FrenchQuestionDescriptions[QuestionIds.REMODEL_TRASH] = ["Écartez une carte de votre main. Recevez une carte coûtant jusqu'à $2 de plus.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.REMODEL_GAIN] = ["Recevez une carte coûtant jusqu'à AMOUNT0.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.SENTRY_TRASH] = ["Écartez parmi les cartes dévoilées", "Vous pouvez écarter"];
FrenchQuestionDescriptions[QuestionIds.SENTRY_DISCARD] = ["Défaussez parmi les cartes dévoilées", "Vous pouvez défausser"];
FrenchQuestionDescriptions[QuestionIds.SENTRY_TOPDECK] = ["Remettez-les sur votre pioche dans l'ordre de votre choix", "Choisissez l'ordre"];
FrenchQuestionDescriptions[QuestionIds.THRONE_ROOM] = ["Vous pouvez jouer une carte Action de votre main deux fois.", "Vous pouvez jouer une action deux fois"];
FrenchQuestionDescriptions[QuestionIds.VASSAL] = ["Vous vouez jouer CARDS0 depuis votre défausse.", "Vous pouvez jouer CARDS0"];
FrenchQuestionDescriptions[QuestionIds.WORKSHOP] = ["Recevez une carte coûtant jusqu'à $4.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.TRASHING_TOKEN] = ["Vous pouvez écarter une carte de votre main.", "Vous pouvez écarter une carte"];
FrenchQuestionDescriptions[QuestionIds.ALMS] = ["Recevez une carte coûtant jusqu'à $4.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.AMULET_MODE] = ["Choisissez : +$1; ou écartez une carte de votre main; ou recevez un Argent.", "Choisissez l'effet de l'Amulette"];
FrenchQuestionDescriptions[QuestionIds.AMULET_TRASH] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.ARTIFICER_DELAY] = ["Vous pouvez défaussez autant de cartes que vous voulez. Vous pouvez ensuite recevoir une carte coûtant exactement $1 par carte défaussée, sur votre pioche.", "Vous pouvez utiliser le Maître-Artisan"];
FrenchQuestionDescriptions[QuestionIds.ARTIFICER_DISCARD] = ["Défaussez autant de cartes que vous voulez. Vous pouvez ensuite recevoir une carte coûtant exactement $1 par carte défaussée, sur votre pioche.", "Défaussez, puis recevez"];
FrenchQuestionDescriptions[QuestionIds.ARTIFICER_GAIN] = ["Vous pouvez recevoir une carte coûtant exactement AMOUNT0, sur votre pioche.", "Vous pouvez recevoir une carte"];
FrenchQuestionDescriptions[QuestionIds.BALL_FIRST] = ["Recevez 2 cartes coûtant chacune jusqu'à $4.", "Recevez 2 cartes"];
FrenchQuestionDescriptions[QuestionIds.BALL_SECOND] = ["Recevez une autre carte coûtant jusqu'à $4.", "Recevez une autre carte"];
FrenchQuestionDescriptions[QuestionIds.BONFIRE] = ["Écartez jusqu'à 2 Cuivres que vous avez en jeu.", "Écartez jusqu'à 2 Cuivres"];
FrenchQuestionDescriptions[QuestionIds.DISCIPLE] = ["Vous pouvez jouer une carte Action de votre main deux fois. Recevez un exemplaire de cette carte.", "Jouez deux fois, puis recevez un exemplaire"];
FrenchQuestionDescriptions[QuestionIds.DUNGEON] = ["Défaussez 2 cartes.", "Défaussez 2 cartes"];
FrenchQuestionDescriptions[QuestionIds.FERRY] = ["Déplacez votre jeton -$2 sur une pile de cartes Action de la réserve.", "Déplacez votre jeton -$2"];
FrenchQuestionDescriptions[QuestionIds.FUGITIVE] = ["Défausses une carte.", "Défaussez une carte"];
FrenchQuestionDescriptions[QuestionIds.GEAR] = ["Mettez de côté jusqu'à 2 cartes de votre main. Au début de votre prochain tour, prenez-les en main.", "Mettez de côté jusqu'à 2 cartes"];
FrenchQuestionDescriptions[QuestionIds.HAUNTED_WOODS] = ["Mettez les cartes de votre main sur votre pioche dans l'ordre de votre choix.", "Mettez votre main sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.HERO] = ["Recevez un trésor.", "Recevez un Trésor"];
FrenchQuestionDescriptions[QuestionIds.LOST_ARTS_NEW] = ["Mettez votre jeton +1 Action sur une pile de cartes Action de la réserve.", "Déplacez votre jeton +1 Action"];
FrenchQuestionDescriptions[QuestionIds.LOST_ARTS_MOVE] = ["Déplacez votre jeton +1 Action sur une pile de cartes Action de la réserve.", "Déplacez votre jeton +1 Action"];
FrenchQuestionDescriptions[QuestionIds.MESSENGER_DISCARD] = ["Vous pouvez mettre votre pioche dans votre défausse.", "Vous pouvez défausser votre pioche"];
FrenchQuestionDescriptions[QuestionIds.MESSENGER_GAIN] = ["Recevez une carte coûtant jusqu'à $4, et tous les autres joueurs en recevront un exemplaire.", "Distribuez une carte coûtant jusqu'à $4"];
FrenchQuestionDescriptions[QuestionIds.MISER] = ["Choisissez : Mettez un Cuivre de votre main sur votre plateau Taverne; ou +AMOUNT0", "Cuivre à la taverne ou +AMOUNT0?"];
FrenchQuestionDescriptions[QuestionIds.PATHFINDING_NEW] = ["Mettez votre jeton +1 Carte sur une pile de cartes Action de la réserve.", "Déplacez votre jeton +1 Carte"];
FrenchQuestionDescriptions[QuestionIds.PATHFINDING_MOVE] = ["Mettez votre jeton +1 Carte sur une pile de cartes Action de la réserve.", "Déplacez votre jeton +1 Carte"];
FrenchQuestionDescriptions[QuestionIds.PILGRIMAGE_FIRST] = ["Vous pouvez choisir jusqu'à 3 cartes en jeu de noms différents, et recevoir un exemplaire de chacune.", "Choisissez 3 cartes à recevoir"];
FrenchQuestionDescriptions[QuestionIds.PILGRIMAGE_SECOND] = ["Vous pouvez choisir jusqu'à 2 autres cartes en jeu de noms différents, et recevoir un exemplaire de chacune.", "Choisissez 2 cartes à recevoir"];
FrenchQuestionDescriptions[QuestionIds.PILGRIMAGE_THIRD] = ["Vous pouvez choisir une autre carte en jeu de nom différent, et en recevoir un exemplaire.", "Choisissez 1 carte à recevoir"];
FrenchQuestionDescriptions[QuestionIds.PLAN_NEW] = ["Mettez votre jeton Écart sur une pile de cartes Action de la réserve.", "Déplacez votre jeton Écart"];
FrenchQuestionDescriptions[QuestionIds.PLAN_MOVE] = ["Déplacez votre jeton Écart sur une pile de cartes Action de la réserve.", "Déplacez votre jeton Écart"];
FrenchQuestionDescriptions[QuestionIds.QUEST_MODE] = ["Choisissez de défausser une attaque, deux Malédictions, ou six cartes.", "Choisissez votre quête"];
FrenchQuestionDescriptions[QuestionIds.QUEST_ATTACK] = ["Défaussez une carte Attaque.", "Défaussez une attaque"];
FrenchQuestionDescriptions[QuestionIds.QUEST_6CARDS] = ["Défaussez six cartes.", "Défaussez six cartes"];
FrenchQuestionDescriptions[QuestionIds.RATCATCHER] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_PLAY] = ["Vous pouvez écarter la Démolition en jeu.", "Vous pouvez écarter la Démolition"];
FrenchQuestionDescriptions[QuestionIds.RAZE_ALL] = ["Écartez une carte de votre main, ou la Démolition en jeu.", "Écartez une carte, ou la Démolition"];
FrenchQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_HAND] = ["Vous pouvez écarter une carte de votre main.", "Vous pouvez écarter une carte"];
FrenchQuestionDescriptions[QuestionIds.RAZE_CHOOSE_CARD] = ["Choisissez une carte à prendre en main.", "Prenez une carte en main"];
FrenchQuestionDescriptions[QuestionIds.SAVE] = ["Mettez de côté une carte de votre main, et prenez-la en main à la fin de votre tour.", "Mettez une carte de côté"];
FrenchQuestionDescriptions[QuestionIds.SCOUTING_PARTY_DISCARD] = ["Défaussez NUMBER0 cartes.", "Défaussez NUMBER0 cartes."];
FrenchQuestionDescriptions[QuestionIds.SCOUTING_PARTY_TOPDECK] = ["Mettez les deux cartes restantes sur votre pioche, dans l'ordre de votre choix.", "Mettez ces cartes sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.SEAWAY] = ["Recevez une carte Action coûtant jusqu'à $4. Mettez votre jeton +1 Achat sur cette pile.", "Recevez une carte Action"];
FrenchQuestionDescriptions[QuestionIds.SOLDIER] = ["Défaussez une carte.", "Défaussez une carte"];
FrenchQuestionDescriptions[QuestionIds.STORYTELLER_FIRST] = ["Jouez jusqu'à 3 trésors de votre main.", "Jouez jusqu'à 3 trésors"];
FrenchQuestionDescriptions[QuestionIds.STORYTELLER_SECOND] = ["Jouez jusqu'à 2 autres trésors de votre main.", "Jouez jusqu'à 2 trésors"];
FrenchQuestionDescriptions[QuestionIds.STORYTELLER_THIRD] = ["Vous pouvez jouer un autre trésor de votre main.", "Vous pouvez jouer un autre trésor"];
FrenchQuestionDescriptions[QuestionIds.TEACHER_CHOOSE_TOKEN] = ["Choisissez quel jeton déplacer.", "Choisissez le jeton"];
FrenchQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_MOVE] = ["Déplacez votre jeton +1 Action sur une pile de cartes Action de la réserve sur laquelle vous n'avez pas de jeton.", "Déplacez votre jeton +1 Action"];
FrenchQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_NEW] = ["Mettez votre jeton +1 Action sur une pile de cartes Action de la réserve sur laquelle vous n'avez pas de jeton.", "Déplacez votre jeton +1 Action"];
FrenchQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_MOVE] = ["Déplacez votre jeton +1 Carte sur une pile de cartes Action de la réserve sur laquelle vous n'avez pas de jeton.", "Déplacez votre jeton +1 Carte"];
FrenchQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_NEW] = ["Mettez votre jeton +1 Carte sur une pile de cartes Action de la réserve sur laquelle vous n'avez pas de jeton.", "Déplacez votre jeton +1 Carte"];
FrenchQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_MOVE] = ["Déplacez votre jeton +1 Pièce sur une pile de cartes Action de la réserve sur laquelle vous n'avez pas de jeton.", "Déplacez votre jeton +1 Pièce"];
FrenchQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_NEW] = ["Mettez votre jeton +1 Pièce sur une pile de cartes Action de la réserve sur laquelle vous n'avez pas de jeton.", "Déplacez votre jeton +1 Pièce"];
FrenchQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_MOVE] = ["Déplacez votre jeton +1 Achat sur une pile de cartes Action de la réserve sur laquelle vous n'avez pas de jeton.", "Déplacez votre jeton +1 Achat"];
FrenchQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_NEW] = ["Mettez votre jeton +1 Achat sur une pile de cartes Action de la réserve sur laquelle vous n'avez pas de jeton.", "Déplacez votre jeton +1 Achat"];
FrenchQuestionDescriptions[QuestionIds.TRADE] = ["Écartez jusqu'à 2 cartes de votre main. Recevez un Argent par carte écartée.", "Écartez jusqu'à 2 cartes"];
FrenchQuestionDescriptions[QuestionIds.TRAINING_NEW] = ["Mettez votre jeton +1 Pièce sur une pile de cartes Action de la réserve.", "Déplacez votre jeton +1 Pièce"];
FrenchQuestionDescriptions[QuestionIds.TRAINING_MOVE] = ["Déplacez votre jeton +1 Pièce sur une pile de cartes Action de la réserve.", "Déplacez votre jeton +1 Pièce"];
FrenchQuestionDescriptions[QuestionIds.TRANSMOGRIFY_TRASH] = ["Écartez une carte de votre main, et recevez en main une carte coûtant jusqu'à $1 de plus.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.TRANSMOGRIFY_GAIN] = ["Recevez en main une carte coûtant jusqu'à AMOUNT0.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.APOTHECARY] = ["Remettez les cartes sur la pioche dans l'ordre de votre choix.", "Remettez sur la pioche"];
FrenchQuestionDescriptions[QuestionIds.APPRENTICE] = ["Écartez une carte de votre main. +1 Carte par $1 du coût, +2 Cartes si elle coûte aussi [P].", "Écartez, puis piochez"];
FrenchQuestionDescriptions[QuestionIds.GOLEM] = ["Choisissez quelle action jouer en premier.", "Choisissez l'ordre"];
FrenchQuestionDescriptions[QuestionIds.HERBALIST] = ["Vous pouvez mettre sur votre pioche un trésor en jeu.", "Vous pouvez mettre un trésor sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.HERBALISTS] = ["Quels Trésors mettre sur votre pioche", "Mettez sur la pioche jusqu'à Argument0 Trésors"];
FrenchQuestionDescriptions[QuestionIds.SCRYING_POOL_YOU] = ["Laissez ou défaussez CARDS0", "Vous: laissez ou défaussez CARDS0"];
FrenchQuestionDescriptions[QuestionIds.SCRYING_POOL_OTHER] = ["Choisissez si PLAYER1 doit laisser ou défausser CARDS0.", "PLAYER1: laisse ou défausse CARDS0."];
FrenchQuestionDescriptions[QuestionIds.TRANSMUTE] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.UNIVERSITY] = ["Vous pouvez recevoir une carte Action coûtant jusqu'à $5.", "Vous pouvez recevoir une action"];
FrenchQuestionDescriptions[QuestionIds.FOLLOWERS] = ["Défaussez jusqu'à avoir 3 cartes en main.", "Défaussez à 3 cartes"];
FrenchQuestionDescriptions[QuestionIds.DIADEM] = ["Utiliser des Villageois avant de convertir vos actions en pièces ?", "Utiliser des Villageois ?"];
FrenchQuestionDescriptions[QuestionIds.HAMLET] = ["Vous pouvez défausser pour obtenir +1 Action et/ou +1 Achat", "Vous pouvez défausser"];
FrenchQuestionDescriptions[QuestionIds.HAMLET_ACTION] = ["Défaussez une carte pour +1 Action.", "Défaussez pour +1 Action"];
FrenchQuestionDescriptions[QuestionIds.HAMLET_BUY] = ["Défaussez une carte for +1 Achat.", "Défaussez pour +1 Achat"];
FrenchQuestionDescriptions[QuestionIds.HAMLET_BOTH] = ["Défaussez 2 cartes.", "Défaussez 2 carte"];
FrenchQuestionDescriptions[QuestionIds.HORN_OF_PLENTY] = ["Recevez une carte coûtant jusqu'à AMOUNT0. Si c'est une carte Victoire, écartez ceci.", "Recevez une carte jusqu'à AMOUNT0"];
FrenchQuestionDescriptions[QuestionIds.HORSE_TRADERS] = ["Défaussez 2 cartes.", "Défaussez 2 cartes."];
FrenchQuestionDescriptions[QuestionIds.JESTER] = ["Choisissez qui reçoit CARDS0: vous ou PLAYER1.", "Choisissez qui reçoit CARDS0"];
FrenchQuestionDescriptions[QuestionIds.REMAKE_TRASH_FIRST] = ["Faites ceci 2 fois: écartez une carte de votre main, et recevez une carte coûtant exactement $1 de plus.", "Écartez une première carte"];
FrenchQuestionDescriptions[QuestionIds.REMAKE_TRASH_SECOND] = ["Écartez une autre carte de votre main, puis recevez une carte coûtant exactement $1 de plus.", "Écartez une autre carte"];
FrenchQuestionDescriptions[QuestionIds.REMAKE_GAIN_FIRST] = ["Recevez une carte coûtant exactement COST0", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.REMAKE_GAIN_SECOND] = ["Recevez une carte coûtant exactement COST0", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.TOURNAMENT_GAIN_PRIZE_OR_DUCHY] = ["Recevez un Prix ou un Duché sur votre pioche.", "Recevez un Prix ou un Duché"];
FrenchQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_PRIZE] = ["Vous pouvez recevoir un Prix sur votre pioche.", "Vous pouvez recevoir un Prix"];
FrenchQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_DUCHY] = ["Vous pouvez recevoir un Duché sur votre pioche.", "Vous pouvez recevoir un Duché"];
FrenchQuestionDescriptions[QuestionIds.TOURNAMENT_REVEAL] = ["Vous pouvez dévoiler une Province.", "Vous pouvez dévoiler une Province"];
FrenchQuestionDescriptions[QuestionIds.TRUSTY_STEED] = ["Choisissez comment jouer le Fidèle Destrier.", "Choisissez comment jouer le Fidèle Destrier"];
FrenchQuestionDescriptions[QuestionIds.YOUNG_WITCH] = ["Défaussez 2 cartes.", "Défaussez 2 cartes"];
FrenchQuestionDescriptions[QuestionIds.YOUNG_WITCH_BANE] = ["Vous pouvez dévoiler le Fléau (CARDS0) pour bloquer la Jeune Sorcière.", "Dévoiler le fléau?"];
FrenchQuestionDescriptions[QuestionIds.ALTAR_TRASH] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.ALTAR_GAIN] = ["Recevez une carte coûtant jusqu'à $5.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.BAND_OF_MISFITS] = ["Jouez une carte Action coûtant moins que ceci.", "Jouez une carte"];
FrenchQuestionDescriptions[QuestionIds.ARMORY] = ["Recevez sur votre pioche une carte coûtant jusqu'à $4.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.CATACOMBS_MODE] = ["Prenez CARDS0 en main; ou défaussez-les et +3 cartes.", "Prendre ou laisser ?"];
FrenchQuestionDescriptions[QuestionIds.CATACOMBS_GAIN] = ["Recevez une carte moins de coût inférieur à AMOUNT0.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.COUNT_PAY] = ["Choisissez : Défaussez 2 cartes; ou mettez une carte de votre main sur votre pioche; ou recevez un Cuivre.", "Choisissez la pénalité du Comte"];
FrenchQuestionDescriptions[QuestionIds.COUNT_DISCARD] = ["Défaussez 2 cartes.", "Défaussez 2 cartes"];
FrenchQuestionDescriptions[QuestionIds.COUNT_TOPDECK] = ["Mettez une carte de votre main sur votre pioche.", "Mettez une carte sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.COUNT_PROFIT] = ["Choisissez : +$3; ou écartez votre main; ou recevez un Duché.", "Choisissez l'avantage du Comte"];
FrenchQuestionDescriptions[QuestionIds.COUNTERFEIT] = ["Vous pouvez jouer un trésor en main deux fois. Dans ce cas, écartez-le.", "Jouez deux fois, puis écartez"];
FrenchQuestionDescriptions[QuestionIds.CULTIST] = ["Vous pouvez jouer un Cultiste de votre main gratuitement.", "Jouer un autre Cultiste ?"];
FrenchQuestionDescriptions[QuestionIds.DAME_ANNA] = ["Vous pouvez écarter jusqu'à deux cartes de votre main.", "Écartez jusqu'à 2 cartes"];
FrenchQuestionDescriptions[QuestionIds.DAME_NATALIE] = ["Vous pouvez recevoir une carte coûtant jusqu'à $3.", "Vous pouvez recevoir une carte"];
FrenchQuestionDescriptions[QuestionIds.DEATH_CART] = ["Vous pouvez écarter une carte Action de votre main ou une Charrette de Cadavres en jeu.", "Vous pouvez écarte une Charrette de Cadavres ou une carte Action."];
FrenchQuestionDescriptions[QuestionIds.FORAGER] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.KNIGHTS] = ["Choisissez une carte à écarter.", "Choisissez une carte à écarter"];
FrenchQuestionDescriptions[QuestionIds.KNIGHTS_ALREADY_DIES] = ["Choisissez une carte à écarter. (CARDS0 meurt déjà)", "Choisissez une carte à écarter"];
FrenchQuestionDescriptions[QuestionIds.KNIGHTS_STILL_MATTERS] = ["Choisissez une carte à écarter (CARDS1 mourra si vous écartez CARDS0)", "Choisissez une carte à écarter"];
FrenchQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_EXPAND] = ["Vous pouvez écartez une carte Action de votre main et recevoir une carte coûtant jusqu'à $3 de plus.", "Vous pouvez écarter une carte"];
FrenchQuestionDescriptions[QuestionIds.GRAVEROBBER_EXPAND] = ["Écartez une carte de votre main et recevez une carte coûtant jusqu'à $3 de plus.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_GAIN] = ["Vous pouvez recevoir une carte du rebut sur votre pioche.", "Vous pouvez recevoir une carte du rebut"];
FrenchQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN] = ["Recevez une carte du rebut sur votre pioche.", "Recevez une carte du rebut"];
FrenchQuestionDescriptions[QuestionIds.GRAVEROBBER_COMPLEX] = ["Résolution du Pilleur de tombes", "Résolution du Pilleur de tombes"];
FrenchQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN_SUPPLY] = ["Recevez une carte coûtant jusqu'à AMOUNT0.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.HERMIT_TRASH] = ["Vous pouvez écartez une carte non-trésor de votre défausse ou de votre main.", "Vous pouvez écarter"];
FrenchQuestionDescriptions[QuestionIds.HERMIT_GAIN] = ["Recevez une carte coûtant jusqu'à $3.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.HUNTING_GROUNDS] = ["Recevez un Duché ou 3 Domaines.", "Duché ou 3 Domaines ?"];
FrenchQuestionDescriptions[QuestionIds.IRONMONGER] = ["Vous pouvez laisser ou défausser CARDS0.", "Vous pouvez défausser CARDS0"];
FrenchQuestionDescriptions[QuestionIds.JUNK_DEALER] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.MERCENARY_ONE] = ["Vous pouvez écarter une carte de votre main (sans le bonus).", "Vous pouvez écarter une carte"];
FrenchQuestionDescriptions[QuestionIds.MERCENARY_MULTIPLE] = ["Vous pouvez écarter deux cartes de votre main.", "Vous pouvez écarter deux cartes"];
FrenchQuestionDescriptions[QuestionIds.MERCENARY_DISCARD] = ["Défaussez jusqu'à avoir 3 cartes en main.", "Défaussez jusqu'à 3 cartes"];
FrenchQuestionDescriptions[QuestionIds.MYSTIC] = ["Devinez la carte du dessus de votre pioche.", "Devinez une carte"];
FrenchQuestionDescriptions[QuestionIds.PILLAGE] = ["Choisissez la carte que PLAYER0 défausse.", "Choisissez la défausse de PLAYER0"];
FrenchQuestionDescriptions[QuestionIds.PROCESSION_PLAY] = ["Vous pouvez jouer une carte Action de votre main deux fois.", "Vous pouvez jouer une action deux fois."];
FrenchQuestionDescriptions[QuestionIds.PROCESSION_GAIN] = ["Recevez une carte Action coûtant exactement AMOUNT0.", "Recevez une carte Action"];
FrenchQuestionDescriptions[QuestionIds.RATS_TRASH_ANY] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.RATS_TRASH_OTHER] = ["Écartez une carte de votre main, hormis les Rats.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.REBUILD_SKIP] = ["Nommez une carte Victoire à passer (ou bien Cuivre).", "Nommez une carte à passer"];
FrenchQuestionDescriptions[QuestionIds.REBUILD_GAIN] = ["Recevez une carte Victoire coûtant jusqu'à AMOUNT0.", "Recevez une carte Victoire"];
FrenchQuestionDescriptions[QuestionIds.ROGUE_TRASH] = ["Choisissez une carte à écarter pour PLAYER0.", "Écartez une carte de PLAYER0"];
FrenchQuestionDescriptions[QuestionIds.ROGUE_GAIN] = ["Recevez une carte du rebut coûtant entre $3 et $6.", "Recevez une carte du rebut"];
FrenchQuestionDescriptions[QuestionIds.SCAVENGER] = ["Vous pouvez mettre votre pioche dans votre défausse.", "Vous pouvez défausser votre pioche"];
FrenchQuestionDescriptions[QuestionIds.SCAVENGER_TOPDECK] = ["Mettez une carte de votre défausse sur votre pioche.", "Mettez une carte sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.SIR_MICHAEL] = ["Défaussez jusqu'à 3 cartes en main.", "Défaussez jusqu'à 3 cartes"];
FrenchQuestionDescriptions[QuestionIds.SQUIRE_MODE] = ["Choisissez : +2 Actions ; ou +2 Achats ; ou recevez un Argent.", "Choisissez le rôle de l'Écuyer"];
FrenchQuestionDescriptions[QuestionIds.SQUIRE_GAIN] = ["Recevez une carte Attaque.", "Recevez une attaque"];
FrenchQuestionDescriptions[QuestionIds.STOREROOM_CARDS] = ["Défaussez des cartes, puis piochez en autant.", "Défaussez pour des cartes"];
FrenchQuestionDescriptions[QuestionIds.STOREROOM_COINS] = ["Défaussez des cartes pour +$1 chacune.", "Défaussez pour des pièces"];
FrenchQuestionDescriptions[QuestionIds.SURVIVORS] = ["Remettez ces deux cartes sur votre pioche dans l'ordre de votre choix, ou défaussez-les.", "Mettez sur la pioche ou défaussez"];
FrenchQuestionDescriptions[QuestionIds.URCHIN] = ["Défaussez jusqu'à 4 cartes.", "Défaussez jusqu'à 4 cartes"];
FrenchQuestionDescriptions[QuestionIds.WANDERING_MINSTREL] = ["Remettez les cartes Action sur cotre pioche dans l'ordre de votre choix.", "Remettez sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.ADVANCE_TRASH] = ["Vous pouvez écarter une carte Action de votre main.", "Vous pouvez écarter une action"];
FrenchQuestionDescriptions[QuestionIds.ADVANCE_GAIN] = ["Recevez une carte Action coûtant jusqu'à $6.", "Recevez une action"];
FrenchQuestionDescriptions[QuestionIds.ANNEX] = ["Choisissez jusqu'à 5 cartes à ne pas mélanger dans votre pioche.", "Choisissez jusqu'à 5 cartes"];
FrenchQuestionDescriptions[QuestionIds.ARCHIVE] = ["Choisissez une carte à prendre en main.", "Choisissez une carte"];
FrenchQuestionDescriptions[QuestionIds.ARENA] = ["Vous pouvez défausser une carte Action pour NUMBER0PV.", "Arène : défausser pour NUMBER0PV ?"];
FrenchQuestionDescriptions[QuestionIds.BANQUET] = ["Recevez une carte non Victoire coûtant jusqu'à $5.", "Recevez une carte non Victoire"];
FrenchQuestionDescriptions[QuestionIds.BUSTLING_VILLAGE] = ["Vous pouvez prendre en main une carte Colons.", "Vous pouvez prendre un Colons"];
FrenchQuestionDescriptions[QuestionIds.CATAPULT_TRASH] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.CATAPULT_DISCARD] = ["Défaussez jusqu'à 3 cartes en main.", "Défaussez jusqu'à 3 cartes"];
FrenchQuestionDescriptions[QuestionIds.CHARM_MODE] = ["Choisissez comment jouer le Sort", "Choisissez comment jouer le Sort"];
FrenchQuestionDescriptions[QuestionIds.CHARM_GAIN] = ["Recevez une carte autre que CARDS0 coûtant exactement AMOUNT1.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.CROWN_ACTION] = ["Vous pouvez jouer une action de votre main deux fois.", "Vous pouvez jouer une action deux fois"];
FrenchQuestionDescriptions[QuestionIds.CROWN_TREASURE] = ["Vous pouvez jouer un trésor de votre main deux fois.", "Vous pouvez jouer un trésor deux fois"];
FrenchQuestionDescriptions[QuestionIds.DONATE] = ["Écartez autant de cartes que vous voulez.", "Écartez"];
FrenchQuestionDescriptions[QuestionIds.ENCAMPMENT] = ["Vous pouvez dévoiler un Or ou un Saccage de votre main.", "Vous pouvez dévoiler un Or ou un Saccage"];
FrenchQuestionDescriptions[QuestionIds.ENGINEER_GAIN] = ["Recevez une carte coûtant jusqu'à $4.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.ENGINEER_TRASH] = ["Vous pouvez écarter l'Ingénieur.", "Vous pouvez écarter l'Ingénieur"];
FrenchQuestionDescriptions[QuestionIds.FORUM] = ["Défaussez 2 cartes.", "Défaussez 2 cartes"];
FrenchQuestionDescriptions[QuestionIds.GLADIATOR_REVEAL] = ["Dévoiler une carte de votre main.", "Dévoilez une carte"];
FrenchQuestionDescriptions[QuestionIds.GLADIATOR_PRETEND] = ["Faire semblant de réfléchir avant de dévoiler un exemplaire.", "Faire semblant de réfléchir"];
FrenchQuestionDescriptions[QuestionIds.GLADIATOR_BLOCK] = ["Vous pouvez dévoiler CARDS0 de votre main.", "Vous pouvez dévoiler CARDS0"];
FrenchQuestionDescriptions[QuestionIds.HAUNTED_CASTLE] = ["Mettez 2 cartes de votre main sur votre pioche.", "Mettez 2 cartes sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.LEGIONARY_REVEAL] = ["Vous pouvez dévoiler un Or de votre main.", "Vous pouvez dévoiler un Or"];
FrenchQuestionDescriptions[QuestionIds.LEGIONARY_DISCARD] = ["Défaussez jusqu'à 2 cartes en main.", "Défaussez jusqu'à 2 cartes"];
FrenchQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_NEW] = ["Enchérissez jusqu'à 40D.", "Enchérissez jusqu'à 40D"];
FrenchQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_OVERBID] = ["Enchérissez jusqu'à 40D. Pour l'instant PLAYER0 propose la plus haute enchère avec NUMBER1.", "Enchérissez jusqu'à 40D"];
FrenchQuestionDescriptions[QuestionIds.OPULENT_CASTLE] = ["Défaussez autant de cartes Victoire que vous souhaitez pour +$2 chacune. ", "Défaussez pour +$2 chacune"];
FrenchQuestionDescriptions[QuestionIds.OVERLORD] = ["Jouez une carte Action coûtant jusqu'à $5. ", "Jouez une carte"];
FrenchQuestionDescriptions[QuestionIds.RITUAL] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.SACRIFICE] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.SALT_THE_EARTH] = ["Écartez une carte Victoire de la réserve.", "Écartez depuis la réserve"];
FrenchQuestionDescriptions[QuestionIds.SETTLERS] = ["Vous pouvez mettre un Cuivre de votre défausse dans votre main.", "Vous pouvez prendre un Cuivre"];
FrenchQuestionDescriptions[QuestionIds.SMALL_CASTLE_IN_PLAY] = ["Écartez le Petit château en jeu, ou un Château de votre main.", "Écartez un Château"];
FrenchQuestionDescriptions[QuestionIds.SMALL_CASTLE_GONE] = ["Écartez un Château de votre main.", "Écartez un Château"];
FrenchQuestionDescriptions[QuestionIds.SMALL_CASTLE_GAIN] = ["Recevez un Château.", "Recevez un Château"];
FrenchQuestionDescriptions[QuestionIds.SPRAWLING_CASTLE] = ["Recevez un Duché ou 3 Domaines.", "Duché ou 3 Domaines ?"];
FrenchQuestionDescriptions[QuestionIds.TAX] = ["Choisissez une pile de la réserve à laquelle ajouter (2D).", "Ajoutez (2D) à une pile"];
FrenchQuestionDescriptions[QuestionIds.TEMPLE] = ["Écartez entre 1 et 3 cartes de noms différents de votre main.", "Écartez 1 - 3 cartes"];
FrenchQuestionDescriptions[QuestionIds.WILD_HUNT] = ["Choisissez entre +3 Cartes et Domaine + PV", "Cartes ou points ?"];
FrenchQuestionDescriptions[QuestionIds.ADVISOR] = ["Choisissez la carte que PLAYER0 ne prend pas.", "Défaussez pour PLAYER0"];
FrenchQuestionDescriptions[QuestionIds.BUTCHER_TRASH] = ["Vous pouvez écarter une carte de votre main.", "Vous pouvez écarter une carte"];
FrenchQuestionDescriptions[QuestionIds.BUTCHER_GAIN] = ["Recevez une carte coûtant jusqu'à AMOUNT0.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.DOCTOR_PLAY] = ["Nommez une carte à écarter.", "Nommez une carte"];
FrenchQuestionDescriptions[QuestionIds.DOCTOR_TOPDECK] = ["Remettez ces cartes sur la pioche dans l'ordre de votre choix.", "Remettez ces cartes sur la pioche"];
FrenchQuestionDescriptions[QuestionIds.DOCTOR_OVERPAY] = ["Vous pouvez surpayer le Docteur.", "Vous pouvez surpayer"];
FrenchQuestionDescriptions[QuestionIds.DOCTOR_MODE] = ["Que faire avec CARDS0 ?", "Que faire avec CARDS0 ?"];
FrenchQuestionDescriptions[QuestionIds.DOCTOR_MODE_MANY] = ["Que faire avec CARDS0? (NUMBER1 de plus)", "CARDS0? (NUMBER1 de plus)"];
FrenchQuestionDescriptions[QuestionIds.HERALD_OVERPAY] = ["Vous pouvez surpayer pour le Héraut.", "Vous pouvez surpayer"];
FrenchQuestionDescriptions[QuestionIds.HERALD_CHOOSE] = ["Mettez une carte de votre défausse sur votre pioche.", "Mettez une carte sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.JOURNEYMAN] = ["Nommez une carte à ne pas piocher.", "Nommez une carte"];
FrenchQuestionDescriptions[QuestionIds.MASTERPIECE] = ["Vous pouvez surpayer pour le Chef-d'œuvre.", "Vous pouvez surpayer"];
FrenchQuestionDescriptions[QuestionIds.PLAZA_DELAY] = ["Vous pouvez défausser un trésor pour prendre un jeton Pièce.", "Vous pouvez défausser un trésor"];
FrenchQuestionDescriptions[QuestionIds.PLAZA_DISCARD] = ["Défaussez un trésor.", "Défaussez un trésor"];
FrenchQuestionDescriptions[QuestionIds.STONEMASON_TRASH] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.STONEMASON_GAIN1] = ["Recevez 2 cartes coûtant chacune moins que AMOUNT0.", "Recevez 2 cartes"];
FrenchQuestionDescriptions[QuestionIds.STONEMASON_GAIN2] = ["Recevez une autre carte coûtant moins que AMOUNT0.", "Recevez une autre carte"];
FrenchQuestionDescriptions[QuestionIds.STONEMASON_OVERPAY] = ["Vous pouvez surpayer le Tailleur de pierres en recevant une action coûtant jusqu'à AMOUNT0.", "Spécifiez le surpaiement, ou choisissez une carte à recevoir"];
FrenchQuestionDescriptions[QuestionIds.STONEMASON_GAIN_EQUAL] = ["Recevez une autre action coûtant exactement AMOUNT0.", "Recevez une autre action"];
FrenchQuestionDescriptions[QuestionIds.TAXMAN_TRASH] = ["Vous pouvez écarter un trésor de votre main.", "Vous pouvez écarter un trésor"];
FrenchQuestionDescriptions[QuestionIds.TAXMAN_GAIN] = ["Recevez un trésor coûtant jusqu'à AMOUNT0 sur votre pioche.", "Recevez un trésor"];
FrenchQuestionDescriptions[QuestionIds.BORDER_VILLAGE] = ["Recevez une carte moins chère que AMOUNT0.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.CARTOGRAPHER_DISCARD] = ["Défaussez autant de cartes que vous voulez.", "Défaussez"];
FrenchQuestionDescriptions[QuestionIds.CARTOGRAPHER_TOPDECK] = ["Mettez les cartes restantes sur votre pioche dans l'ordre de votre choix.", "Mettez sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.DEVELOP_TRASH] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.DEVELOP_GAIN1] = ["Recevez sur votre pioche une carte coûtant AMOUNT0 ou Argument1.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.DEVELOP_GAIN2] = ["Recevez sur votre pioche une carte coûtant AMOUNT0.", "Recevez une autre carte"];
FrenchQuestionDescriptions[QuestionIds.DUCHESS] = ["Vous pouvez défausser CARDS0.", "Vous pouvez défausser CARDS0"];
FrenchQuestionDescriptions[QuestionIds.EMBASSY] = ["Défaussez 3 cartes.", "Défaussez 3 cartes"];
FrenchQuestionDescriptions[QuestionIds.FARMLAND_TRASH] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.FARMLAND_GAIN] = ["Recevez une carte coûtant exactement AMOUNT0.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.HAGGLER] = ["Recevez une carte non-Victoire coûtant moins que AMOUNT0.", "Recevez une carte non-Victoire"];
FrenchQuestionDescriptions[QuestionIds.ILL_GOTTEN_GAINS] = ["Vous pouvez recevoir un Cuivre en main.", "Vous pouvez recevoir un Cuivre"];
FrenchQuestionDescriptions[QuestionIds.INN_DISCARD] = ["Défaussez 2 cartes.", "Défaussez 2 cartes"];
FrenchQuestionDescriptions[QuestionIds.INN_SHUFFLE] = ["Mélangez autant que vous voulez de cartes Actions de votre défausse dans votre pioche.", "Mélangez des cartes Action"];
FrenchQuestionDescriptions[QuestionIds.JACK_DISCARD] = ["Vous pouvez défausser CARDS0.", "Vous pouvez défausser CARDS0"];
FrenchQuestionDescriptions[QuestionIds.JACK_TRASH] = ["Vous pouvez écarter une carte non-Trésor de votre main.", "Vous pouvez écarter une carte non-Trésor"];
FrenchQuestionDescriptions[QuestionIds.MANDARIN_PLAY] = ["Mettez une carte de votre main sur votre pioche.", "Mettez une carte sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.MANDARIN_GAIN] = ["Mettez tous les trésors en jeu sur votre pioche, dans l'ordre de votre choix.", "Mettez vos trésors sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.MARGRAVE] = ["Défaussez jusqu'à 3 cartes en main.", "Défaussez jusqu'à 3 cartes"];
FrenchQuestionDescriptions[QuestionIds.NOBLE_BRIGAND] = ["Choisissez un trésor à écarter.", "Choisissez un trésor"];
FrenchQuestionDescriptions[QuestionIds.OASIS] = ["Défaussez une carte.", "Défaussez une carte"];
FrenchQuestionDescriptions[QuestionIds.ORACLE_DISCARD] = ["Vous pouvez défausser CARDS0 pour PLAYER1.", "Faire défaussez PLAYER1 ?"];
FrenchQuestionDescriptions[QuestionIds.ORACLE_TOPDECK] = ["Remettez les cartes sur votre pioche, dans l'ordre de votre choix.", "Remettez ces cartes sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.SCHEME] = ["Vous pouvez choisir une carte affectée par le Complot", "Vous pouvez utiliser le Complot"];
FrenchQuestionDescriptions[QuestionIds.SCHEMES] = ["Sur quelles cartes utiliser le Complot ?", "Vous pouvez utiliser le complot sur jusqu'à Argument0 cartes"];
FrenchQuestionDescriptions[QuestionIds.SPICE_MERCHANT_TRASH] = ["Vous pouvez écarter un trésor de votre main.", "Vous pouvez écarter un trésor"];
FrenchQuestionDescriptions[QuestionIds.SPICE_MERCHANT_MODE] = ["Choisissez : +2 Cartes et +1 Action ; ou +1 Achat et +$2.", "Choisissez comment jouer le Marchand d'épices"];
FrenchQuestionDescriptions[QuestionIds.STABLES] = ["Vous pouvez défausser un trésor.", "Vous pouvez défausser un trésor"];
FrenchQuestionDescriptions[QuestionIds.TRADER_TRASH] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.BARON] = ["Vous pouvez défausser un Domaine.", "Vous pouvez défausser un Domaine"];
FrenchQuestionDescriptions[QuestionIds.COURTIER_REVEAL] = ["Dévoilez une carte de votre main.", "Dévoilez une carte"];
FrenchQuestionDescriptions[QuestionIds.COURTYARD] = ["Mettez une carte de votre main sur votre pioche.", "Mettez une carte sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.DIPLOMAT] = ["Défaussez 3 cartes.", "Défaussez 3 cartes"];
FrenchQuestionDescriptions[QuestionIds.IRONWORKS] = ["Recevez une carte coûtant jusqu'à $4.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.LURKER_MAY_TRASH] = ["Vous pouvez écarter une carte Action de la réserve.", "Vous pouvez écarter depuis la réserve"];
FrenchQuestionDescriptions[QuestionIds.LURKER_TRASH] = ["Écartez une carte Action de la réserve.", "Écartez depuis la réserve"];
FrenchQuestionDescriptions[QuestionIds.LURKER_MAY_GAIN] = ["Vous pouvez recevoir une carte Action du rebut.", "Vous pouvez recevoir depuis le rebut"];
FrenchQuestionDescriptions[QuestionIds.LURKER_GAIN] = ["Recevez une carte Action du rebut.", "Recevez depuis le rebut"];
FrenchQuestionDescriptions[QuestionIds.MASQUERADE_PASS] = ["Passez une carte à PLAYER0.", "Passez une carte à PLAYER0"];
FrenchQuestionDescriptions[QuestionIds.MASQUERADE_TRASH] = ["Vous pouvez écartez une carte de votre main.", "Vous pouvez écarter une carte"];
FrenchQuestionDescriptions[QuestionIds.MILL] = ["Vous pouvez défausser 2 cartes", "Vous pouvez défausser 2 cartes"];
FrenchQuestionDescriptions[QuestionIds.MINING_VILLAGE] = ["Vous pouvez écarter le Village minier.", "Vous pouvez écarter le Village minier"];
FrenchQuestionDescriptions[QuestionIds.MINION] = ["Choisissez : +$2 ou de nouvelles mains.", "Choisissez : +$2 ou de nouvelles mains."];
FrenchQuestionDescriptions[QuestionIds.NOBLES] = ["Choisissez: +3 Cartes ou +2 Actions.", "Choisissez +3 Cartes ou +2 Actions"];
FrenchQuestionDescriptions[QuestionIds.PATROL] = ["Remettez ces cartes dans l'ordre de votre choix.", "Remettez ces cartes sur la pioche"];
FrenchQuestionDescriptions[QuestionIds.PAWN] = ["Choisissez comment jouer le Pion.", "Choisissez comment jouer le Pion"];
FrenchQuestionDescriptions[QuestionIds.REPLACE_TRASH] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.REPLACE_GAIN] = ["Recevez une carte coûtant jusqu'à AMOUNT0.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.SECRET_PASSAGE_TOPDECK] = ["Mettez une carte sur votre pioche vide.", "Mettez une carte sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.SECRET_PASSAGE_INSERT] = ["Choisissez une carte à insérer dans votre pioche, et où l'insérer", "Choisissez une carte et une place"];
FrenchQuestionDescriptions[QuestionIds.STEWARD_MODE] = ["Choisissez : +2 Cartes ; ou +$2 ; ou écartez 2 cartes de votre main.", "Choisissez comment jouer l'Intendant"];
FrenchQuestionDescriptions[QuestionIds.STEWARD_TRASH] = ["Choisissez 2 cartes à écarter.", "Choisissez 2 cartes à écarter"];
FrenchQuestionDescriptions[QuestionIds.SWINDLER] = ["Choisissez une carte que PLAYER0 recevra.", "Choisissez une carte pour PLAYER0"];
FrenchQuestionDescriptions[QuestionIds.TRADING_POST] = ["Écartez 2 cartes de votre main.", "Écartez 2 cartes"];
FrenchQuestionDescriptions[QuestionIds.UPGRADE_TRASH] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.UPGRADE_GAIN] = ["Recevez une carte coûtant exactement AMOUNT0.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.WISHING_WELL] = ["Devinez quel carte est sur le dessus de votre pioche.", "Devinez une carte"];
FrenchQuestionDescriptions[QuestionIds.SUMMON] = ["Recevez une carte Action coûtant jusqu'à $4.", "Convoquez une carte"];
FrenchQuestionDescriptions[QuestionIds.GOVERNOR_MODE] = ["Choisissez comment jouer le Gouverneur.", "Choisissez comment jouer le Gouverneur"];
FrenchQuestionDescriptions[QuestionIds.GOVERNOR_TRASH] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.GOVERNOR_MAY_TRASH] = ["Vous pouvez écarter une carte de votre main.", "Vous pouvez écarter"];
FrenchQuestionDescriptions[QuestionIds.GOVERNOR_GAIN] = ["Recevez une carte coûtant exactement AMOUNT0.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.SAUNA_CHAIN] = ["Vous pouvez jouer un Trou dans la glace sans dépenser d'action", "Vous pouvez jouer un Trou dans la glace"];
FrenchQuestionDescriptions[QuestionIds.SAUNA_MAY_TRASH] = ["Vous pouvez écarter une carte avec le Sauna", "Vous pouvez écarter une carte"];
FrenchQuestionDescriptions[QuestionIds.AVANTO_CHAIN] = ["Vous pouvez jouer un Sauna sans dépenser d'action", "Vous pouvez jouer un Sauna"];

FrenchQuestionDescriptions[QuestionIds.BISHOP_SELF] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.BISHOP_OTHER] = ["Vous pouvez écarter une carte de votre main.", "Vous pouvez écarter une carte"];
FrenchQuestionDescriptions[QuestionIds.CONTRABAND] = ["Nommez une carte que PLAYER0 ne peut pas acheter à ce tour.", "Interdisez une carte"];
FrenchQuestionDescriptions[QuestionIds.COUNTING_HOUSE] = ["Combien de Cuivres voulez-vous prendre en main ?", "Prenez des Cuivres"];
FrenchQuestionDescriptions[QuestionIds.EXPAND_TRASH] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.EXPAND_GAIN] = ["Recevez une carte coûtant jusqu'à AMOUNT0.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.FORGE_TRASH] = ["Écartez autant de cartes de votre main que vous le souhaitez.", "Écartez"];
FrenchQuestionDescriptions[QuestionIds.FORGE_GAIN] = ["Recevez une carte coûtant jusqu'à AMOUNT0.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.GOONS] = ["Défaussez jusqu'à 3 cartes en main.", "Défaussez jusqu'à 3 cartes"];
FrenchQuestionDescriptions[QuestionIds.KINGS_COURT] = ["Vous pouvez jouer une carte Action trois fois.", "Vous pouvez jouer une action 3 fois"];
FrenchQuestionDescriptions[QuestionIds.LOAN] = ["Défaussez ou écartez CARDS0.", "Défaussez ou écartez CARDS0"];
FrenchQuestionDescriptions[QuestionIds.MINT] = ["Vous pouvez dévoiler un trésor de votre main. Gagnez-en un exemplaire.", "Vous pouvez copier un trésor"];
FrenchQuestionDescriptions[QuestionIds.MOUNTEBANK] = ["Vous pouvez défausser une Malédiction.", "Vous pouvez défausser une Malédiction"];
FrenchQuestionDescriptions[QuestionIds.RABBLE] = ["Remettez ces cartes dans l'ordre de votre choix.", "Remettez ces cartes sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.TRADE_ROUTE] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.VAULT_SELF] = ["Défaussez autant de cartes que vous le souhaitez.", "Défaussez"];
FrenchQuestionDescriptions[QuestionIds.VAULT_OTHER] = ["Vous pouvez défausser 2 cartes.", "Vous pouvez défausser 2 cartes"];
FrenchQuestionDescriptions[QuestionIds.AMBASSADOR_REVEAL] = ["Dévoilez une carte de votre main.", "Dévoilez une carte de votre main"];
FrenchQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_ONE] = ["Vous pouvez remettre CARDS0 dans la réserve.", "Vous pouvez remettre CARDS0"];
FrenchQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_TWO] = ["Remettre 0, 1 ou CARDS0 dans la réserve.", "Remettre 0, 1 ou 2"];
FrenchQuestionDescriptions[QuestionIds.EMBARGO] = ["Mettez un jeton Embargo sur une pile de la réserve.", "Mettez un Embargo"];
FrenchQuestionDescriptions[QuestionIds.EXPLORER] = ["Vous pouvez dévoiler une Province.", "Vous pouvez dévoiler une Province"];
FrenchQuestionDescriptions[QuestionIds.GHOST_SHIP] = ["Mettez NUMBER0 cartes sur votre pioche.", "Mettez NUMBER0 cartes sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.HAVEN] = ["Mettez une carte de côté.", "Mettez une carte de côté"];
FrenchQuestionDescriptions[QuestionIds.ISLAND] = ["Mettez une carte de votre main sur votre plateau Île.", "Mettez une carte sur votre plateau"];
FrenchQuestionDescriptions[QuestionIds.LOOKOUT_TRASH] = ["Écartez une carte.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.LOOKOUT_DISCARD] = ["Défaussez une carte.", "Défaussez une carte"];
FrenchQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_TAKE] = ["Vous pouvez prendre CARDS0 en main.", "Vous pouvez prendre les cartes"];
FrenchQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_SET_ASIDE] = ["Vous pouvez mettre la carte du haut de votre pioche sur votre plateau.", "Vous pouvez mettre sur votre plateau"];
FrenchQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_BOTH] = ["Ajoutez une carte au plateau, ou prenez CARDS0 en main.", "Comment jouer le Village indigène ?"];
FrenchQuestionDescriptions[QuestionIds.NAVIGATOR] = ["Mettez ces 5 cartes sur votre pioche dans l'ordre de votre choix, ou défaussez-les.", "Mettez sur la pioche ou défaussez"];
FrenchQuestionDescriptions[QuestionIds.PEARL_DIVER] = ["Vous pouvez mettre CARDS0 sur le haut de votre pioche.", "Vous pouvez mettre CARDS0 en haut"];
FrenchQuestionDescriptions[QuestionIds.PIRATE_SHIP_MODE] = ["Choisissez : +AMOUNT0 ou attaquez.", "+AMOUNT0 ou attaque ?"];
FrenchQuestionDescriptions[QuestionIds.PIRATE_SHIP_TRASH] = ["Choisissez un trésor à écarter.", "Choisissez un trésor à écarter"];
FrenchQuestionDescriptions[QuestionIds.SALVAGER] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.SMUGGLERS_MAY_GAIN] = ["Vous pouvez recevoir un exemplaire.", "Vous pouvez recevoir un exemplaire"];
FrenchQuestionDescriptions[QuestionIds.SMUGGLERS_GAIN] = ["Recevez un exemplaire.", "Recevez un exemplaire"];
FrenchQuestionDescriptions[QuestionIds.WAREHOUSE] = ["Défaussez 3 cartes.", "Défaussez 3 cartes."];
FrenchQuestionDescriptions[QuestionIds.LURKER_COMPLEX] = ["Résolution du Rôdeur", "Résolution du Rôdeur"];
FrenchQuestionDescriptions[QuestionIds.BLACK_MARKET_COMPLEX] = ["Vous jouez le Marché noir", "Vous jouez le Marché noir"];
FrenchQuestionDescriptions[QuestionIds.BLACK_MARKET_BUY] = ["acheter une des cartes dévoilées.", "Vous pouvez acheter"];
FrenchQuestionDescriptions[QuestionIds.BLACK_MARKET_PLAY] = ["jouer des trésors de votre main.", "Vous pouvez jouer des trésors"];
FrenchQuestionDescriptions[QuestionIds.ENVOY] = ["Choisissez une carte à défausser.", "Défaussez une carte"];
//FrenchQuestionDescriptions[QuestionIds.PRINCE_MODE] = ["PRINCE_MODE", "Mettre le Prince de côté ?"];
FrenchQuestionDescriptions[QuestionIds.PRINCE_MAY_SET_ASIDE] = ["Vous pouvez mettre une carte de côté avec ", "Vous pouvez mettre une carte de côté"];
FrenchQuestionDescriptions[QuestionIds.TORTURER_MAY_CURSE] = ["Vous pouvez recevoir une Malédiction.", "Vous pouvez recevoir une Malédiction"];
FrenchQuestionDescriptions[QuestionIds.TORTURER_MAY_DISCARD] = ["Vous pouvez défausser 2 cartes.", "Vous pouvez défausser 2 cartes"];
FrenchQuestionDescriptions[QuestionIds.TORTURER_DISCARD_OR_CURSE] = ["Recevez une Malédiction, ou défaussez 2 cartes.", "Recevez une Malédiction, ou défaussez 2 cartes."];
//FrenchQuestionDescriptions[QuestionIds.WHAT_TO_IMPERSONATE] = ["Choisissez quelle carte imiter.", "Choisissez quelle carte imiter"];
FrenchQuestionDescriptions[QuestionIds.INHERITANCE] = ["Choisissez une carte dont hériter.", "Choisissez une carte dont hériter"];

FrenchQuestionDescriptions[QuestionIds.SHORT_YOU_MAY_REACT] = ["-", "Vous pouvez réagir"];
FrenchQuestionDescriptions[QuestionIds.SHORT_WHEN_GAIN] = ["-", "Vous recevez Argument0..."];
FrenchQuestionDescriptions[QuestionIds.SHORT_WHEN_WOULD_GAIN] = ["-", "Vous allez recevoir Argument0..."];

FrenchQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL_SPECIFIC] = ["-", "Jouer ou recourir à Argument0..."];
FrenchQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL] = ["-", "Jouer ou recourir à des actions"];
FrenchQuestionDescriptions[QuestionIds.SHORT_PLAY] = ["-", "Vous pouvez jouer des cartes Actions"];
FrenchQuestionDescriptions[QuestionIds.SHORT_CALL_SPECIFIC] = ["-", "Vous pouvez recourir à Argument0"];
FrenchQuestionDescriptions[QuestionIds.SHORT_CALL] = ["-", "Vous pouvez recourir à des cartes"];

FrenchQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_DISCARD] = ["Vous pouvez défaussez une carte Trésor.", "Vous pouvez défausser"];
FrenchQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_GAIN] = ["Recevez une carte coûtant jusqu'à $4.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.THE_FLAMES_GIFT] = ["Vous pouvez écarter une carte de votre main.", "Vous pouvez écarter"];
FrenchQuestionDescriptions[QuestionIds.THE_MOONS_GIFT] = ["Vous pouvez placer une carte sur votre pioche.", "Vous pouvez placer une carte sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.THE_SKYS_GIFT] = ["Vous pouvez défausser 3 cartes pour recevoir un Or.", "Vous pouvez défausser"];
FrenchQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_TOPDECK] = ["replacez le reste dans l'ordre de votre choix", "replacez le reste"];
FrenchQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_DISCARD] = ["Défaussez autant que vous souhaitez", "Défaussez"];
FrenchQuestionDescriptions[QuestionIds.THE_WINDS_GIFT] = ["Défaussez 2 cartes.", "Défaussez 2 cartes"];
FrenchQuestionDescriptions[QuestionIds.FEAR_DISCARD] = ["défaussez une carte Action ou Trésor", "Défaussez une carte"];
FrenchQuestionDescriptions[QuestionIds.HAUNTING] = ["Placez quelque chose sur la pioche.", "Placez sur la pioche"];
FrenchQuestionDescriptions[QuestionIds.LOCUSTS] = ["Recevez une carte moins chère ayant un type en commun.", "Recevez une carte moins chère"];
FrenchQuestionDescriptions[QuestionIds.POVERTY] = ["Défaussez jusqu'à avoir 3 cartes en main", "Défaussez à 3 cartes"];
FrenchQuestionDescriptions[QuestionIds.BAT] = ["Vous pouvez écarter jusqu'à 2 cartes", "Vous pouvez écarter"];
FrenchQuestionDescriptions[QuestionIds.BLESSED_VILLAGE_MODE] = ["Quand appliquer Argument0?", "Quand appliquer ?"];
FrenchQuestionDescriptions[QuestionIds.CEMETERY] = ["Vous pouvez écarter jusqu'à 4 cartes", "Vous pouvez écarter"];
FrenchQuestionDescriptions[QuestionIds.CHANGELING_GAIN_COPY] = ["Recevez une exemplaire d'une carte en jeu", "Recevez un exemplaire"];
FrenchQuestionDescriptions[QuestionIds.CHANGELING_REPLACE] = ["", ""];
FrenchQuestionDescriptions[QuestionIds.COBBLER] = ["Recevez en main une carte coûtant jusqu'à $4.", "Recevez en main"];
FrenchQuestionDescriptions[QuestionIds.CONCLAVE] = ["Vous pouvez jouer une carte Action", "Vous pouvez jouer une carte Action"];
FrenchQuestionDescriptions[QuestionIds.CRYPT_SET_ASIDE] = ["Mettez de côté autant de cartes Trésors en jeu que vous souhaitez", "Mettez de côté des Trésors"];
FrenchQuestionDescriptions[QuestionIds.CRYPT_RETURN_TO_HAND] = ["Choisissez quelle carte prendre en main.", "Choisissez une carte"];
FrenchQuestionDescriptions[QuestionIds.DEVILS_WORKSHOP] = ["Recevez une carte coûtant jusqu'à $4.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.DRUID] = ["Choisissez quelle Aubaine appliquer.", "Quelle Aubaine ?"];
FrenchQuestionDescriptions[QuestionIds.EXORCIST_TRASH] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.EXORCIST_GAIN] = ["Recevez un Esprit moins cher", "Recevez un Esprit moins cher"];
FrenchQuestionDescriptions[QuestionIds.FOOL] = ["Quelle Aubaine, ensuite ?", "Quelle Aubaine ?"];
FrenchQuestionDescriptions[QuestionIds.GOAT] = ["Vous pouvez écarter une carte", "Vous pouvez écarter"];
FrenchQuestionDescriptions[QuestionIds.HAUNTED_MIRROR] = ["Vous pouvez défausser une carte Action pour recevoir un Fantôme", "Vous pouvez défausser"];
FrenchQuestionDescriptions[QuestionIds.IMP] = ["Vous pouvez jouer une carte Action dont vous n'avez pas d'exemplaire en jeu", "Quoi jouer ?"];
FrenchQuestionDescriptions[QuestionIds.NECROMANCER] = ["Jouez depuis le rebut", "Jouez depuis le rebut"];
FrenchQuestionDescriptions[QuestionIds.MONASTERY] = ["Vous pouvez écarter jusqu'à AMOUNT0 carte(s) en main, or Cuivre(s) en jeu.", "Vous pouvez écarter jusqu'à AMOUNT0 carte(s)"];
FrenchQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_TOPDECK] = ["Replacez le reste sur la pioche", "Replacez le reste"];
FrenchQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_DISCARD] = ["Défaussez autant que vous souhaitez", "Défaussez"];
FrenchQuestionDescriptions[QuestionIds.PIXIE_MODE] = ["Écarter la Pixie pour appliquer deux fois ?", "Écarter la Pixie ?"];
FrenchQuestionDescriptions[QuestionIds.POOKA] = ["Vous pouvez écarter une carte Trésor autre qu'un Or maudit", "Vous pouvez écarter"];
FrenchQuestionDescriptions[QuestionIds.RAIDER] = ["Défaussez une carte", "Défaussez une carte"];
FrenchQuestionDescriptions[QuestionIds.SACRED_GROVE_MODE] = ["Appliquer aussi Argument0 ?", "appliquer Argument0 ?"];
FrenchQuestionDescriptions[QuestionIds.SECRET_CAVE] = ["Vous pouvez défausser 3 cartes, pour +$3 au prochain tour", "Vous pouvez défausser"];
FrenchQuestionDescriptions[QuestionIds.SHEPHERD] = ["Défaussez des cartes Victoire pour +2 cartes chacune", "Vous pouvez défausser"];
FrenchQuestionDescriptions[QuestionIds.TRAGIC_HERO] = ["Recevez une carte Trésor", "Recevez un Trésor"];
FrenchQuestionDescriptions[QuestionIds.VAMPIRE] = ["Recevez une carte non-Vampire coûtant jusqu'à $5", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.WISH] = ["Recevez une carte en main", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.ZOMBIE_APPRENTICE] = ["Vous pouvez écarter une carte Action", "Vous pouvez écarter"];
FrenchQuestionDescriptions[QuestionIds.ZOMBIE_MASON] = ["Vous pouvez recevoir une carte", "Vous pouvez recevoir une carte"];
FrenchQuestionDescriptions[QuestionIds.ZOMBIE_SPY] = ["Vous pouvez défausser", "Vous pouvez défausser"];
FrenchQuestionDescriptions[QuestionIds.WHAT_NIGHT_TO_PLAY] = ["Vous pouvez jouer une carte Nuit", "Quelle carte Nuit jouer ?"];
FrenchQuestionDescriptions[QuestionIds.LOST_IN_THE_WOODS] = ["Vous pouvez défausser une carte pour appliquer une Aubaine", "Défausser pour une Aubaine ?"];
FrenchQuestionDescriptions[QuestionIds.START_GAME] = ["Regardez vos cartes de départ", "Vos cartes de départ :"];
FrenchQuestionDescriptions[QuestionIds.DISMANTLE_TRASH] = ["Écartez une carte de votre main", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.DISMANTLE_GAIN] = ["Recevez une carte moins chère", "Recevez une carte moins chère"];

FrenchQuestionDescriptions[QuestionIds.DUCAT] = ["Vous pouvez écarter un Cuivre.", "Vous pouvez écarter un Cuivre"];
FrenchQuestionDescriptions[QuestionIds.IMPROVE_TRASH] = ["Vous pouvez écarter une carte Action en jeu que vous auriez défaussé.", "Vous pouvez améliorer"];
FrenchQuestionDescriptions[QuestionIds.IMPROVE_GAIN] = ["Recevez une carte coûtant exactement AMOUNT0.", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.HIDEOUT] = ["Écartez une carte. Si c'est une carte Victoire, recevez une Malédiction.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.MOUNTAIN_VILLAGE] = ["Prenez en main une carte de votre défausse.", "Prenez en main"];
FrenchQuestionDescriptions[QuestionIds.PRIEST] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.RESEARCH_TRASH] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.OLD_WITCH] = ["Vous pouvez écartez une Malédiction de votre main.", "Vous pouvez écarter"];
FrenchQuestionDescriptions[QuestionIds.RECRUITER] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.SCEPTER_COMPLEX] = ["Quand vous jouez le Sceptre", "Rejouer ou pièces ?"];
FrenchQuestionDescriptions[QuestionIds.SCEPTER_MODE] = ["Choisissez le mode", "ajouter tes pièces"];
FrenchQuestionDescriptions[QuestionIds.SCEPTER_PLAY] = ["Rejouez une carte action encore en jeu", "Rejouez une Action"];
FrenchQuestionDescriptions[QuestionIds.SCULPTOR] = ["Recevez en main une carte coûtant jusqu'à $4.", "Recevez en main"];
FrenchQuestionDescriptions[QuestionIds.SEER] = ["Replacez les cartes sur votre pioche dans l'ordre de votre choix", "Replacez dans l'ordre"];
FrenchQuestionDescriptions[QuestionIds.VILLAIN] = ["Défaussez une carte coûtant 2 ou plus.", "Défaussez une carte"];
FrenchQuestionDescriptions[QuestionIds.TREASURER_COMPLEX] = ["Quand vous jouez la Trésorière", "Quand vous jouez la Trésorière"];
FrenchQuestionDescriptions[QuestionIds.TREASURER_TRASH] = ["écartez une carte Trésor", "écartez un Trésor"];
FrenchQuestionDescriptions[QuestionIds.TREASURER_GAIN] = ["recevez en main un Trésor", "recevez un Trésor"];
FrenchQuestionDescriptions[QuestionIds.TREASURER_KEY] = ["prenez la Clé", "prenez la Clé"];
FrenchQuestionDescriptions[QuestionIds.TREASURER_FAIL] = ["ne rien faire", "ne rien faire"];
FrenchQuestionDescriptions[QuestionIds.BORDER_GUARD_PUT_IN_HAND] = ["Prenez en main une des cartes dévoilées", "Prenez en main"];
FrenchQuestionDescriptions[QuestionIds.BORDER_GUARD_MODE] = ["Quel Artefact souhaitez-vous prendre ?", "Corne ou Lanterne ?"];
FrenchQuestionDescriptions[QuestionIds.CATHEDRAL] = ["Écartez une carte de votre main.", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.CITY_GATE] = ["Placez une carte de votre main sur votre pioche.", "Placez sur la pioche."];
FrenchQuestionDescriptions[QuestionIds.PAGEANT] = ["vous pouvez payer $1 pour +1 Coffres.", "$1 pour +1 Coffres ?"];
FrenchQuestionDescriptions[QuestionIds.SEWERS] = ["Vous pouvez écarter jusqu'à AMOUNT0 cartes de votre main. (" + FrenchCardNames[CardNames.SEWERS].singular + ")", "Vous pouvez écarter jusqu'à AMOUNT0 (" + FrenchCardNames[CardNames.SEWERS].singular + ")"];
FrenchQuestionDescriptions[QuestionIds.SILOS] = ["Défaussez des Cuivres pour +1 carte chacun", "Vous pouvez défausser"];
FrenchQuestionDescriptions[QuestionIds.CROP_ROTATION] = ["Défausser un carte Victoire pour +2 cartes ?", "Vous pouvez défausser"];
FrenchQuestionDescriptions[QuestionIds.SINISTER_PLOT] = ["Piocher AMOUNT0 cartes, ou ajouter un jeton ?", "Piocher AMOUNT0 ou ajouter un jeton ?"];
FrenchQuestionDescriptions[QuestionIds.STAR_CHART] = ["Choisissez une carte à mettre en haut quand vous mélangez", "Choisissez la carte du haut"];
FrenchQuestionDescriptions[QuestionIds.CAPTAIN] = ["Choisissez une Action de la réserve coûtant jusqu'à $4, pour la jouer", "Choisissez quoi jouer"];
FrenchQuestionDescriptions[QuestionIds.CHURCH_SET_ASIDE] = ["Mettez de côté jusqu'à 3 cartes", "Mettez de côté jusqu'à 3 cartes"];
FrenchQuestionDescriptions[QuestionIds.CHURCH_TRASH] = ["Vous pouvez écarter une carte de votre main.", "Vous pouvez écarter une carte"];
FrenchQuestionDescriptions[QuestionIds.COURTIER_MODE] = ["Choisissez NUMBER0: +1 Action; ou +1 Achat; ou +$3; ou recevez un Or. Les choix doivent être différents.", "Choisissez NUMBER0 options"];
FrenchQuestionDescriptions[QuestionIds.CAMEL_TRAIN] = ["Exile a card from the supply.", "Exilez une carte"];
FrenchQuestionDescriptions[QuestionIds.GOATHERD] = ["You may trash a card from your hand.", "Vous pouvez écarter une carte"];
FrenchQuestionDescriptions[QuestionIds.SNOWY_VILLAGE] = ["You may use villagers while you can", "Utiliser des villageois tant que vous pouvez ?"];
FrenchQuestionDescriptions[QuestionIds.BOUNTY_HUNTER] = ["Exile a card from your hand", "Exilez une carte"];
FrenchQuestionDescriptions[QuestionIds.CARDINAL_EXILE] = ["Exile a card", "Exilez une carte"];
FrenchQuestionDescriptions[QuestionIds.GROOM] = ["Gain a card costing up to AMOUNT0.", "Recevez une carte coûtant jusqu'à AMOUNT0"];
FrenchQuestionDescriptions[QuestionIds.HOSTELRY] = ["Discard for Horses", "Défausser pour des Chevaux"];
FrenchQuestionDescriptions[QuestionIds.VILLAGE_GREEN] = ["Now or next turn?", "Maintenant ou au prochain tour ?"];
FrenchQuestionDescriptions[QuestionIds.BARGE] = ["Now or next turn?", "Maintenant ou au prochain tour ?"];
FrenchQuestionDescriptions[QuestionIds.DISPLACE_EXILE] = ["Exile a card from your hand", "Exilez une carte"];
FrenchQuestionDescriptions[QuestionIds.DISPLACE_GAIN] = ["Gain a card costing up to AMOUNT0.", "Recevez une carte coûtant jusqu'à AMOUNT0"];
FrenchQuestionDescriptions[QuestionIds.FALCONER] = ["Gain a card to your hand", "Recevez une carte en main"];
FrenchQuestionDescriptions[QuestionIds.HUNTING_LODGE] = ["Discard for +5 Cards?", "Défausser pour +5 Cartes ?"];
FrenchQuestionDescriptions[QuestionIds.MASTERMIND] = ["You may play an Action card three times.", "Vous pouvez jouer une Action trois fois"];
FrenchQuestionDescriptions[QuestionIds.SANCTUARY] = ["You may Exile a Card from your hand", "Vous pouvez exiler une carte"];
FrenchQuestionDescriptions[QuestionIds.WAYFARER] = ["You may gain a Silver", "Vous pouvez recevoir un Argent"];
FrenchQuestionDescriptions[QuestionIds.DELAY] = ["You may set aside an Action", "Vous pouvez mettre de côté un Action"];
FrenchQuestionDescriptions[QuestionIds.DESPERATION] = ["You may gain a Curse", "Vous pouvez recevoir une Malédiction"];
FrenchQuestionDescriptions[QuestionIds.GAMBLE] = ["Play Argument0?", "Jouer Argument0 ?"];
FrenchQuestionDescriptions[QuestionIds.PURSUE] = ["What card to keep on top?", "Quelle carte garder sur votre pioche ?"];
FrenchQuestionDescriptions[QuestionIds.TOIL] = ["You may play a card", "Vous pouvez jouer une carte"];
FrenchQuestionDescriptions[QuestionIds.ENHANCE_TRASH] = ["You may trash a non-Victory card", "Vous pouvez écarter une carte non-Victoire"];
FrenchQuestionDescriptions[QuestionIds.ENHANCE_GAIN] = ["Gain a card costing up to AMOUNT0.", "Recevez une carte coûtant jusqu'à AMOUNT0"];
FrenchQuestionDescriptions[QuestionIds.MARCH] = ["You may play a card from your discard pile", "Vous pouvez jouer une carte depuis votre défausse"];
FrenchQuestionDescriptions[QuestionIds.TRANSPORT_MAY_EXILE] = ["You may exile an Action card", "Vous pouvez exiler une carte Action"];
FrenchQuestionDescriptions[QuestionIds.TRANSPORT_EXILE] = ["Exile an Action card", "Exilez une carte Action"];
FrenchQuestionDescriptions[QuestionIds.TRANSPORT_MAY_TOPDECK] = ["You may topdeck an Exiled Action", "Vous pouvez mettre une Action exilée sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.TRANSPORT_TOPDECK] = ["Topdeck an Exiled Action", "Mettez une Action exilée sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.TRANSPORT_COMPLEX] = ["Exile from the Supply; or Topdeck from Exile", "Exilez depuis la Réserve; ou mettez sur votre pioche depuis l'Exil"];
FrenchQuestionDescriptions[QuestionIds.BANISH] = ["Exile cards with the same name", "Exilez des cartes ayant le même nom"];
FrenchQuestionDescriptions[QuestionIds.BARGAIN] = ["Gain a non-Victory up to $5", "Recevez une carte non-Victoire coûtant jusqu'à $5"];
FrenchQuestionDescriptions[QuestionIds.INVEST] = ["Exile an Action card to Invest in", "Exilez une carte Action dans laquelle investir"];
FrenchQuestionDescriptions[QuestionIds.DEMAND] = ["Gain a card up to $4 onto your deck", "Recevez une carte coûtant jusqu'à $4 sur votre pioche"];
FrenchQuestionDescriptions[QuestionIds.POPULATE] = ["What card to gain next?", "Quelle carte recevoir ensuite ?"];
FrenchQuestionDescriptions[QuestionIds.RECONSIDER_WAY] = ["Reconsider the Way you play Argument0", "Réenvisagez comment jouer Argument0"];
FrenchQuestionDescriptions[QuestionIds.CONSIDER_WAY] = ["Consider the Way you play Argument0", "Envisagez comment jouer Argument0"];
FrenchQuestionDescriptions[QuestionIds.WAY_OF_THE_GOAT] = ["Trash a Card from your hand.", "Écartez une carte de votre main."];
FrenchQuestionDescriptions[QuestionIds.WAY_OF_THE_RAT] = ["Discard a Treasure to gain a copy", "Défaussez un Trésor pour recevoir un exemplaire"];
FrenchQuestionDescriptions[QuestionIds.SCRAP_TRASH] = ["Trash a card from your hand.", "Écartez une carte de votre main."];
FrenchQuestionDescriptions[QuestionIds.SCRAP_MODES] = ["Choose Argument0 effects", "Choisissez Argument0 effets"];
FrenchQuestionDescriptions[QuestionIds.ANIMAL_FAIR_TRASH] = ["Trash an Action from your hand", "Écartez une Action de votre main"];
FrenchQuestionDescriptions[QuestionIds.ANIMAL_FAIR_MAY_TRASH] = ["You may trash an Action from your hand", "Vous pouvez écarter une Action de votre main"];
FrenchQuestionDescriptions[QuestionIds.BUTTERFLY_RETURN] = ["Retourner Argument0 sur sa pile ?", "Retourner Argument0 sur sa pile ?"];
FrenchQuestionDescriptions[QuestionIds.BUTTERFLY_GAIN] = FrenchQuestionDescriptions[QuestionIds.UPGRADE_GAIN];

FrenchQuestionDescriptions[QuestionIds.BLOCKADE] = ["", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.PIRATE] = ["", "Recevez en main"];
FrenchQuestionDescriptions[QuestionIds.SAILOR] = ["", "Vous pouvez écarter une carte"];
FrenchQuestionDescriptions[QuestionIds.TIDE_POOLS] = FrenchQuestionDescriptions[QuestionIds.DUNGEON];
FrenchQuestionDescriptions[QuestionIds.SEA_WITCH] = FrenchQuestionDescriptions[QuestionIds.DUNGEON];

FrenchQuestionDescriptions[QuestionIds.TIARA_MODE] = ["", "Choisissez comment jouer la Couronne de mariée"];
FrenchQuestionDescriptions[QuestionIds.TIARA_PLAY] = ["", "Quel Trésor jouer deux fois"];
FrenchQuestionDescriptions[QuestionIds.CRYSTAL_BALL] = ["", "Que faire avec Argument0"];
FrenchQuestionDescriptions[QuestionIds.WAR_CHEST_PROHIBIT] = ["", "Interdire une carte"];
FrenchQuestionDescriptions[QuestionIds.WAR_CHEST_GAIN] = ["", "Recevoir une carte"];
FrenchQuestionDescriptions[QuestionIds.ANVIL_DISCARD] = FrenchQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_DISCARD];
FrenchQuestionDescriptions[QuestionIds.ANVIL_GAIN] = FrenchQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_GAIN];
FrenchQuestionDescriptions[QuestionIds.CLERK] = ["", "Placer une carte sur la pioche"];
FrenchQuestionDescriptions[QuestionIds.INVESTMENT_TRASH] = FrenchQuestionDescriptions[QuestionIds.TRADE_ROUTE];
FrenchQuestionDescriptions[QuestionIds.INVESTMENT_MODE] = ["", "Choisir comment jouer le Placement"];

// Allies
FrenchQuestionDescriptions[QuestionIds.TOWN_MODE] = ["", "Comment jouer le Bourg"];
FrenchQuestionDescriptions[QuestionIds.MODIFY_TRASH] = ["", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.MODIFY_MODE] = ["", "Comment jouer la Modification"];
FrenchQuestionDescriptions[QuestionIds.MODIFY_GAIN] = ["", "Recevez une carte"];
FrenchQuestionDescriptions[QuestionIds.SWAP_RETURN] = ["", "Vous pouvez recevoir une carte"];
FrenchQuestionDescriptions[QuestionIds.SWAP_GAIN] = ["", "Recevoir une Action"];
FrenchQuestionDescriptions[QuestionIds.SKIRMISHER] = ["", "Défaussez à 3 cartes"];
FrenchQuestionDescriptions[QuestionIds.WOODWORKERS_GUILD_TRASH] = ["", "Écartez une Action"];
FrenchQuestionDescriptions[QuestionIds.WOODWORKERS_GUILD_GAIN] = ["", "Recevez une Action"];
FrenchQuestionDescriptions[QuestionIds.CRAFTERS_GUILD] = ["", "Dépenser 2 Faveurs pour recevoir une carte ?"];
FrenchQuestionDescriptions[QuestionIds.CAVE_DWELLERS] = ["", "Dépenser une Faveur et défausser pour piocher ?"];
FrenchQuestionDescriptions[QuestionIds.DESERT_GUIDES] = ["", "Garder cette main ?"];
FrenchQuestionDescriptions[QuestionIds.BAND_OF_NOMADS] = ["", "Dépenser plus de Faveurs pour des Achats ?"];
FrenchQuestionDescriptions[QuestionIds.SYCOPHANT] = ["", "Défaussez 3 cartes"];
FrenchQuestionDescriptions[QuestionIds.IMPORTER] = ["", "Recevez une carte coûtant jusqu'à $5"];
FrenchQuestionDescriptions[QuestionIds.CONTRACT] = ["Y", "Vous pouvez jouer une action au tour suivant"];
FrenchQuestionDescriptions[QuestionIds.BAUBLE] = ["", "Choisissez comment jouer la Babiole"];
FrenchQuestionDescriptions[QuestionIds.BROKER_TRASH] = ["", "Quelle carte écarter ?"];
FrenchQuestionDescriptions[QuestionIds.BROKER_MODE] = ["W", "Quel bonus obtenir ?"];
FrenchQuestionDescriptions[QuestionIds.MARKET_TOWNS_FIRST] = ["", "Quelle action jouer ?"];
FrenchQuestionDescriptions[QuestionIds.MARKET_TOWNS_AGAIN] = ["", "Jouer une autre Action pour une Faveur ?"];
FrenchQuestionDescriptions[QuestionIds.BATTLE_PLAN_REVEAL] = ["", "Dévoiler une Attaque pour +1 Carte ?"];
FrenchQuestionDescriptions[QuestionIds.BATTLE_PLAN_ROTATE] = ["", "Tourner une pile de la Réserve ?"];
FrenchQuestionDescriptions[QuestionIds.BARBARIAN] = ["", "Recevez une carte moins chère"];
FrenchQuestionDescriptions[QuestionIds.INNKEEPER_MODE] = ["", "Comment jouer l'Aubergiste ?"];
FrenchQuestionDescriptions[QuestionIds.INNKEEPER_DISCARD] = ["", "Défaussez Argument0 Cartes"];
FrenchQuestionDescriptions[QuestionIds.CAPITAL_CITY_MODE] = ["", "Défausser ou jouer ?"];
FrenchQuestionDescriptions[QuestionIds.CAPITAL_CITY_DISCARD] = ["", "Défaussez 2 Cartes"];
FrenchQuestionDescriptions[QuestionIds.SPECIALIST_PLAY] = ["", "Quelle carte jouer ?"];
FrenchQuestionDescriptions[QuestionIds.SPECIALIST_MODE] = ["", "Rejouer Argument0 ou dupliquer ?"];
FrenchQuestionDescriptions[QuestionIds.GANG_OF_PICKPOCKETS] = ["", "Défausser ou dépenser une Faveur ?"];
FrenchQuestionDescriptions[QuestionIds.COASTAL_HAVEN] = ["", "Quelles cartes garder en main ?"];
FrenchQuestionDescriptions[QuestionIds.CARPENTER_GAIN] = FrenchQuestionDescriptions[QuestionIds.WORKSHOP];
FrenchQuestionDescriptions[QuestionIds.CARPENTER_TRASH] = FrenchQuestionDescriptions[QuestionIds.REMODEL_TRASH];
FrenchQuestionDescriptions[QuestionIds.CARPENTER_GAIN_UP_TO] = FrenchQuestionDescriptions[QuestionIds.REMODEL_GAIN];
FrenchQuestionDescriptions[QuestionIds.MAY_ROTATE_FORTS] = ["", "Vous pouvez tourner les Fortifications"];
FrenchQuestionDescriptions[QuestionIds.ROYAL_GALLEY] = ["", "Vous pouvez jouer une Action"];
FrenchQuestionDescriptions[QuestionIds.HILL_FORT_GAIN] = FrenchQuestionDescriptions[QuestionIds.WORKSHOP];
FrenchQuestionDescriptions[QuestionIds.HILL_FORT_MODE] = ["", "Choisissez comment jouer le Fort de la Colline"];
FrenchQuestionDescriptions[QuestionIds.STRONGHOLD_MODE] = ["", "Choisissez comment jouer le Bastion"];
FrenchQuestionDescriptions[QuestionIds.MAY_ROTATE_ODYSSEYS] = ["Y", "Vous pouvez tourner les Odyssées"];
FrenchQuestionDescriptions[QuestionIds.OLD_MAP_DISCARD] = ["", "Défaussez une Carte"];
FrenchQuestionDescriptions[QuestionIds.SUNKEN_TREASURE] = ["", "Recevez une Action que vous n'avez pas en jeu"];
FrenchQuestionDescriptions[QuestionIds.PEACEFUL_CULT] = ["", "Vous pouvez écarter contre des Faveur"];
FrenchQuestionDescriptions[QuestionIds.ORDER_OF_ASTROLOGERS] = ["", "Vous pouvez mettre sur la pioche contre des Faveurs"];
FrenchQuestionDescriptions[QuestionIds.STAR_CHART_ASTROLOGERS_COMBINED] = ["", "Vous pouvez mettre sur la pioche (une gratuitement) contre des Faveurs"];
FrenchQuestionDescriptions[QuestionIds.ORDER_OF_MASONS] = ["", "Vous pouvez choisir 2 cartes par Faveur pour mettre dans votre défausse"];
FrenchQuestionDescriptions[QuestionIds.ARCHER_HIDE] = ["", "Choisissez une carte à ne pas dévoiler"];
FrenchQuestionDescriptions[QuestionIds.ARCHER_DISCARD] = FrenchQuestionDescriptions[QuestionIds.PILLAGE];
FrenchQuestionDescriptions[QuestionIds.MARQUIS] = ["", "Défaussez à 10 cartes"];
FrenchQuestionDescriptions[QuestionIds.HERB_GATHERER_PLAY] = ["", "Quel Trésor jouer ?"];
FrenchQuestionDescriptions[QuestionIds.MAY_ROTATE_AUGURS] = ["", "Vous pouvez tourner les Augures"];
FrenchQuestionDescriptions[QuestionIds.ACOLYTE] = ["", "Écarter pour recevoir un Or ?"];
FrenchQuestionDescriptions[QuestionIds.ACOLYTE_SELF] = ["", "Écarter pour recevoir un Augure ?"];
FrenchQuestionDescriptions[QuestionIds.SORCERESS] = ["", "Devinez une carte"];
FrenchQuestionDescriptions[QuestionIds.SIBYL_TOPDECK] = ["", "Placez une carte sur la pioche"];
FrenchQuestionDescriptions[QuestionIds.SIBYL_BOTTOMDECK] = ["", "Placez une carte sous la pioche"];
FrenchQuestionDescriptions[QuestionIds.MAY_ROTATE_WIZARDS] = ["", "Vous pouvez tourner les Magiciens"];
FrenchQuestionDescriptions[QuestionIds.STUDENT] = ["", "Écartez une carte"];
FrenchQuestionDescriptions[QuestionIds.CONJURER] = FrenchQuestionDescriptions[QuestionIds.WORKSHOP];
FrenchQuestionDescriptions[QuestionIds.SORCERER] = ["", "Devinez une carte"];
FrenchQuestionDescriptions[QuestionIds.LICH] = ["", "Recevez carte du Rebut"];
FrenchQuestionDescriptions[QuestionIds.MAY_ROTATE_TOWNSFOLK] = ["", "Vous pouvez tourner les Citoyens"];
FrenchQuestionDescriptions[QuestionIds.TOWN_CRIER] = ["", "Comment jouer la Crieuse Publique ?"];
FrenchQuestionDescriptions[QuestionIds.MILLER] = ["", "Comment jouer le Meunier ?"];
FrenchQuestionDescriptions[QuestionIds.ELDER] = ["", "Vous pouvez jouer une carte"];
FrenchQuestionDescriptions[QuestionIds.SENTINEL_TRASH] = ["", "Écartez jusqu'à deux cartes"];
FrenchQuestionDescriptions[QuestionIds.SENTINEL_TOPDECK] = FrenchQuestionDescriptions[QuestionIds.APOTHECARY];
FrenchQuestionDescriptions[QuestionIds.COURIER] = ["", "Vous pouvez jouer une carte"];
FrenchQuestionDescriptions[QuestionIds.HUNTER_ACTION] = ["", "Prenez en main une Action"];
FrenchQuestionDescriptions[QuestionIds.HUNTER_TREASURE] = ["", "Prenez en main un Trésor"];
FrenchQuestionDescriptions[QuestionIds.HUNTER_VICTORY] = ["", "Prenez un main une carte Victoire"];
FrenchQuestionDescriptions[QuestionIds.LURKER_MODE] = ["", "Choisissez comment jouer le Rôdeur"];
FrenchQuestionDescriptions[QuestionIds.GRAVEROBBER_MODE] = ["", "Choisissez comment jouer le Pilleur de Tombes"];
FrenchQuestionDescriptions[QuestionIds.TREASURER_MODE] = ["", "Choisissez comment jouer la Trésorière"];
FrenchQuestionDescriptions[QuestionIds.ARCHITECTS_GUILD] = ["", "Que recevoir"];
FrenchQuestionDescriptions[QuestionIds.FAMILY_OF_INVENTORS] = ["", "De quelle pile baisser le coût ?"];
FrenchQuestionDescriptions[QuestionIds.FOREST_DWELLERS_DISCARD] = ["", "Défaussez autant que souhaité"];
FrenchQuestionDescriptions[QuestionIds.FOREST_DWELLERS_TOPDECK] = ["", "Placez sur la pioche dans l'ordre"];
FrenchQuestionDescriptions[QuestionIds.USE_FAVOR_WHEN_SHUFFLING] = ["", "Dépenser une Faveur en mélangeant ?"];
FrenchQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS] = ["", "Vous pouvez dépenser des Coffres supplémentaires"];
FrenchQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_BASILICA] = ["", "Vous pouvez dépenser des Coffres pour la Basilique"];
FrenchQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_SWASHBUCKLER] = ["", "Vous pouvez dépenser des Coffres pour ne pas obtenir le Coffre au trésor"];
FrenchQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_FORTUNE] = ["", "Vous pouvez dépenser des Coffres avant de doubler vos Pièces"];

FrenchQuestionDescriptions[QuestionIds.WHEELWRIGHT_DISCARD] = ["", "Défaussez pour recevoir"];
FrenchQuestionDescriptions[QuestionIds.WHEELWRIGHT_GAIN] = ["", "Recevez une carte coûtant jusqu'à AMOUNT0"];
FrenchQuestionDescriptions[QuestionIds.BERSERKER_DISCARD] = FrenchQuestionDescriptions[QuestionIds.MILITIA];
FrenchQuestionDescriptions[QuestionIds.BERSERKER_GAIN] = ["", "Recevez une carte moins chère"];
FrenchQuestionDescriptions[QuestionIds.WEAVER] = ["", "Recevez une carte ou deux Argent"];
FrenchQuestionDescriptions[QuestionIds.SOUK] = ["", "Écartez jusqu'à deux cartes"];
FrenchQuestionDescriptions[QuestionIds.WITCHS_HUT] = ["", "Défaussez 2 cartes dévoilées"];

// Missing lines as of 08.01.2023 19:00:59
FrenchQuestionDescriptions[QuestionIds.STONEMASON_OVERPAY_AMOUNT] = ["Vous pouvez surpayer le Tailleur de Pierre en recevant une Action coûtant jusqu'à AMOUNT0.", "Précisez le montant du surpaiement."];
FrenchQuestionDescriptions[QuestionIds.BLACKSMITH] = ["Comment jouer le Forgeur ?", "Comment jouer le Forgeur ?"];
FrenchQuestionDescriptions[QuestionIds.CAGE_SET_ASIDE] = ["Vous pouvez mettre de côté jusqu'à 4 cartes.", "Vous pouvez mettre de côté jusqu'à 4 cartes."];
FrenchQuestionDescriptions[QuestionIds.GROTTO_SET_ASIDE] = ["Vous pouvez mettre de côté jusqu'à 4 cartes.", "Vous pouvez mettre de côté jusqu'à 4 cartes."];
FrenchQuestionDescriptions[QuestionIds.SHAMAN_GAIN] = ["Recevez une carte coûtant jusqu'à 6 depuis le rebut.", "Recevez une carte coûtant jusqu'à 6 depuis le rebut."];
FrenchQuestionDescriptions[QuestionIds.SHAMAN_TRASH] = ["Vous pouvez écarter une carte de votre main.", "Vous pouvez écarter une carte de votre main."];
FrenchQuestionDescriptions[QuestionIds.SECLUDED_SHRINE] = ["Vous pouvez écarter jusqu'à 2 cartes de votre main.", "Vous pouvez écarter jusqu'à 2 cartes de votre main."];
FrenchQuestionDescriptions[QuestionIds.SIREN] = ["Vous pouvez écarter une Action.", "Vous pouvez écarter une Action."];
FrenchQuestionDescriptions[QuestionIds.CABIN_BOY_MODE] = ["Choisissez : +2 Pièces, ou écartez [Cabin Boy] pour recevoir une carte Durée.", "Choisissez : +2 Pièces, ou écartez [Cabin Boy] pour recevoir une carte Durée."];
FrenchQuestionDescriptions[QuestionIds.CABIN_BOY_GAIN] = ["Recevez une carte Durée.", "Recevez une carte Durée."];
FrenchQuestionDescriptions[QuestionIds.CRUCIBLE] = ["Écartez une carte de votre main.", "Écartez une carte de votre main."];
FrenchQuestionDescriptions[QuestionIds.FORTUNE_HUNTER_PLAY] = ["Jouez un Trésor dévoilé.", "Jouez un Trésor dévoilé."];
FrenchQuestionDescriptions[QuestionIds.FORTUNE_HUNTER_TOPDECK] = ["Placez les cartes restantes sur la pioche.", "Placez les cartes restantes sur la pioche."];
FrenchQuestionDescriptions[QuestionIds.GONDOLA_MODE] = ["+2 Coins now or later?", "+2 Pièces maintenant ou plus tard ?"];
FrenchQuestionDescriptions[QuestionIds.GONDOLA_PLAY] = ["You may play an Action card from your hand.", "Vous pouvez jouer une carte Action depuis votre main."];
FrenchQuestionDescriptions[QuestionIds.MAPMAKER] = ["Put 2 cards into your hand.", "Prenez en main 2 cartes."];
FrenchQuestionDescriptions[QuestionIds.MAROON] = ["Trash a card.", "Écartez une carte."];
FrenchQuestionDescriptions[QuestionIds.ROPE_TRASH] = ["You may trash a card from your hand.", "Vous pouvez écarter une carte de votre main."];
FrenchQuestionDescriptions[QuestionIds.TOOLS] = ["Gain a copy of a card somebody has in play.", "Recevez un exemplaire d'une carte que quelqu'un a en jeu."];
FrenchQuestionDescriptions[QuestionIds.CUTTHROAT_DISCARD] = ["Discard down to 3 cards in hand.", "Défaussez jusqu'à 3 cartes en main."];
FrenchQuestionDescriptions[QuestionIds.ENLARGE_TRASH] = ["Trash a card.", "Écartez une carte."];
FrenchQuestionDescriptions[QuestionIds.ENLARGE_GAIN] = ["Gain a card costing up to AMOUNT0.", "Recevez une carte coûtant jusqu'à AMOUNT0"];
FrenchQuestionDescriptions[QuestionIds.FIGURINE] = ["You may discard an Action for +1 Coin and +1 Buy.", "Vous pouvez défausser une Action pour +1 Pièce et +1 Achat."];
FrenchQuestionDescriptions[QuestionIds.FIRST_MATE] = ["You may play a card.", "Vous pouvez jouer une carte."];
FrenchQuestionDescriptions[QuestionIds.FRIGATE] = ["Discard down to 4 cards in hand.", "Défaussez jusqu'à 4 cartes en main."];
FrenchQuestionDescriptions[QuestionIds.PICKAXE] = ["Trash a card from your hand.", "Écartez une carte de votre main."];
FrenchQuestionDescriptions[QuestionIds.PILGRIM_TOPDECK] = ["Put a card from your hand onto your deck.", "Placez une carte de votre main sur votre pioche."];
FrenchQuestionDescriptions[QuestionIds.QUARTERMASTER_MODE] = ["Gain a card costing up to 4, or take one of: Argument0.", "Recevez une carte coûtant jusqu'à 4, ou prenez parmi : Argument0."];
FrenchQuestionDescriptions[QuestionIds.QUARTERMASTER_GAIN] = ["Gain a card costing up to 4.", "Recevez une carte coûtant jusqu'à 4."];
FrenchQuestionDescriptions[QuestionIds.QUARTERMASTER_TAKE] = ["Put a card into your hand.", "Prenez une carte en main."];
FrenchQuestionDescriptions[QuestionIds.TRICKSTER] = ["You may set aside a treasure from play.", "Vous pouvez mettre de côté un Trésor en jeu."];
FrenchQuestionDescriptions[QuestionIds.SILVER_MINE] = ["Gain a treasure into your hand.", "Recevez en main un Trésor."];
FrenchQuestionDescriptions[QuestionIds.KINGS_CACHE] = ["You may play a Treasure 3 times.", "Vous pouvez jouer un Trésor 3 fois."];
FrenchQuestionDescriptions[QuestionIds.BURY] = ["Place a card on the bottom of your deck.", "Placez une carte sous votre pioche."];
FrenchQuestionDescriptions[QuestionIds.PERIL] = ["You may trash an action to gain a Loot.", "Vous pouvez écarter une Action pour recevoir un Trophée."];
FrenchQuestionDescriptions[QuestionIds.PREPARE] = ["Play a set-aside card.", "Jouez une carte mise de côté."];
FrenchQuestionDescriptions[QuestionIds.FORAY] = ["Discard 3 cards.", "Défausser 3 cartes."];
FrenchQuestionDescriptions[QuestionIds.SCROUNGE_COMPLEX] = ["When playing Scrounge,", "En jouant la Maraude,"];
FrenchQuestionDescriptions[QuestionIds.SCROUNGE_TRASH] = ["Trash a card from your hand.", "Écartez une carte de votre main."];
FrenchQuestionDescriptions[QuestionIds.SCROUNGE_MAY_TRASH] = ["You may trash a card from your hand.", "Vous pouvez écarter une carte de votre main."];
FrenchQuestionDescriptions[QuestionIds.SCROUNGE_GAIN_ESTATE] = ["Gain an Estate.", "Recevez un Domaine."];
FrenchQuestionDescriptions[QuestionIds.SCROUNGE_MAY_GAIN_ESTATE] = ["You may gain an Estate.", "Vous pouvez recevoir un Domaine."];
FrenchQuestionDescriptions[QuestionIds.SCROUNGE_GAIN] = ["Gain a card costing up to 5.", "Recevez une carte coûtant jusqu'à 5."];
FrenchQuestionDescriptions[QuestionIds.MAELSTROM_TRASH] = ["Trash a card.", "Écartez une carte."];
FrenchQuestionDescriptions[QuestionIds.MAELSTROM_OPPONENT_TRASH] = ["Trash Argument0 cards.", "Écartez Argument0 cartes."];
FrenchQuestionDescriptions[QuestionIds.INVASION_PLAY] = ["You may play an Attack.", "Vous pouvez jouer une Attaque."];
FrenchQuestionDescriptions[QuestionIds.INVASION_GAIN] = ["Gain an Action.", "Recevez une Action."];
FrenchQuestionDescriptions[QuestionIds.PROSPER] = ["Gain a differently-named Treasure.", "Recevez un Trésor de nom différent."];
FrenchQuestionDescriptions[QuestionIds.AMPHORA_MODE] = ["+1 Buy, +3 Coins now or next turn?", "+1 Achat, +3 Pièces maintenant ou au prochain tour ?"];
FrenchQuestionDescriptions[QuestionIds.HAMMER] = ["Gain a card costing up to 4.", "Recevez une carte coûtant jusqu'à 4."];
FrenchQuestionDescriptions[QuestionIds.ORB] = ["Play an Action or Treasure.", "Jouez une Action ou un Trésor."];
FrenchQuestionDescriptions[QuestionIds.PRIZE_GOAT] = ["You may trash a card.", "Vous pouvez écarter une carte."];
FrenchQuestionDescriptions[QuestionIds.PUZZLE_BOX] = ["Set aside a card from your hand, and put it into your hand at end of turn (after drawing).", "Mettez une carte de côté"];
FrenchQuestionDescriptions[QuestionIds.SEXTANT_DISCARD] = ["Discard any number of cards.", "Défaussez un nombre quelconque de cartes."];
FrenchQuestionDescriptions[QuestionIds.SEXTANT_TOPDECK] = ["Put the remaining cards back in any order.", "Placer les cartes restantes dans l'ordre."];
FrenchQuestionDescriptions[QuestionIds.SPELL_SCROLL_GAIN] = ["Gain a card costing less than AMOUNT0.", "Recevez une carte coûtant moins que AMOUNT0."];
FrenchQuestionDescriptions[QuestionIds.SPELL_SCROLL_PLAY] = ["Play Argument0?", "Jouer Argument0 ?"];
FrenchQuestionDescriptions[QuestionIds.STAFF] = ["You may play an Action card.", "Vous povuez jouer une carte Action."];
FrenchQuestionDescriptions[QuestionIds.SWORD] = ["Discard down to 4 cards.", "Défaussez jusqu'à cartes."];
FrenchQuestionDescriptions[QuestionIds.FRIENDLY] = ["Discard a [Friendly] card to gain another.", "Défaussez une carte [Friendly] pour en recevoir une autre."];
FrenchQuestionDescriptions[QuestionIds.INHERITED] = ["Replace a starting card with an [Inherited] card.", "Remplacez une carte de départ par une carte [Inherited]."];
FrenchQuestionDescriptions[QuestionIds.INSPIRING] = ["You may play an action you don't have in play.", "Vous pouvez jouer une Action pas en jeu ([Inspiring])."];
FrenchQuestionDescriptions[QuestionIds.PATIENT_SET_ASIDE] = ["Set aside [Patient] cards to play next turn.", "Mettez de côté des cartes [Patient] pour les jouer au prochain tour."];
FrenchQuestionDescriptions[QuestionIds.PATIENT_PLAY] = ["Which [Patient] card to play next?", "Quelle carte [Patient] jouer ensuite ?"];
FrenchQuestionDescriptions[QuestionIds.PIOUS_TRASH] = ["You may trash a card from your hand.", "Vous pouvez écarter une carte de votre main."];
FrenchQuestionDescriptions[QuestionIds.SHY] = ["Discard a [Shy] card for +2 Cards.", "Défaussez une carte [Shy] pour +2 Cartes."];
FrenchQuestionDescriptions[QuestionIds.FATED_TOPDECK] = ["You may topdeck Fated Cards.", "Vous pouvez placer des cartes Destinées sur votre pioche."];
FrenchQuestionDescriptions[QuestionIds.FATED_BOTTOMDECK] = ["You may bottomdeck Fated Cards.", "Vous pouvez placer des cartes Destinées sous votre pioche."];
FrenchQuestionDescriptions[QuestionIds.AVOID] = ["Put up to 3 cards in your Discard pile.", "Mettez jusqu'à 3 cartes dans votre défausse."];
FrenchQuestionDescriptions[QuestionIds.TRICKSTER_DISCARD] = ["What Treasure to set aside?.", "Quel Trésor mettre de côté ?"];
FrenchQuestionDescriptions[QuestionIds.MARCHLAND] = ["", "Défaussez pour +$1 par carte"];

FrenchQuestionDescriptions[QuestionIds.FARMHANDS] = ["Quelle carte jouer au début de votre prochain tour ?", "Quelle carte jouer au début de votre prochain tour ?"];
FrenchQuestionDescriptions[QuestionIds.COURSER] = ["Choisissez comment jouer le Coursier", "Choisissez comment jouer le Coursier"];
FrenchQuestionDescriptions[QuestionIds.CORONET_ACTION] = ["Quelle Action jouer deux fois ?", "Quelle Action jouer deux fois ?"];
FrenchQuestionDescriptions[QuestionIds.CORONET_TREASURE] = ["Quel Trésor jouer deux fois ?", "Quel Trésor jour deux fois ?"];
FrenchQuestionDescriptions[QuestionIds.JOUST_SET_ASIDE] = ["Set aside a Province?", "Mettre de côté une Province ?"];
FrenchQuestionDescriptions[QuestionIds.JOUST_GAIN_REWARD] = ["What Reward to gain to your hand?", "Quelle Récompense recevoir en main ?"];
FrenchQuestionDescriptions[QuestionIds.INFIRMARY_TRASH] = ["You may trash a card.", "Vous pouvez écarter une carte"];
FrenchQuestionDescriptions[QuestionIds.INFIRMARY_OVERPAY] = ["You may overpay for Infirmary.", "Vous pouvez surpayer"];
FrenchQuestionDescriptions[QuestionIds.FOOTPAD] = FrenchQuestionDescriptions[QuestionIds.MILITIA];
FrenchQuestionDescriptions[QuestionIds.FARRIER_OVERPAY] = ["You may overpay for Farrier.", "Vous pouvez surpayer"];
FrenchQuestionDescriptions[QuestionIds.SHOP] = FrenchQuestionDescriptions[QuestionIds.CONCLAVE];
FrenchQuestionDescriptions[QuestionIds.FERRYMAN] = FrenchQuestionDescriptions[QuestionIds.FUGITIVE];

FrenchQuestionDescriptions[QuestionIds.ORDER_BOTTOMDECKED_CARDS] = ["Choose the order on the bottom", "Choose the order on the bottom"];
FrenchQuestionDescriptions[QuestionIds.GROWTH] = FrenchQuestionDescriptions[QuestionIds.BORDER_VILLAGE];
FrenchQuestionDescriptions[QuestionIds.KIND_EMPEROR] = ["Gain an Action to your Hand", "Gain an Action to your Hand"];
FrenchQuestionDescriptions[QuestionIds.SICKNESS_MODE] = ["", ""];
FrenchQuestionDescriptions[QuestionIds.SICKNESS_DISCARD] = ["", ""];
FrenchQuestionDescriptions[QuestionIds.SICKNESS] = ["Discard 3 or gain a Curse onto your deck", "Discard 3 or gain a Curse onto your deck"];
FrenchQuestionDescriptions[QuestionIds.SNAKE_WITCH] = ["Return [Snake Witch] to Curse?", "Return [Snake Witch] to have each other player gain a Curse?"];
FrenchQuestionDescriptions[QuestionIds.CRAFTSMAN] = ["Gain a card costing up to 5.", "Gain a card costing up to 5."];
FrenchQuestionDescriptions[QuestionIds.ALLEY] = FrenchQuestionDescriptions[QuestionIds.OASIS];
FrenchQuestionDescriptions[QuestionIds.CHANGE_TRASH] = ["Trash a card from your Hand", "Trash a card from your Hand"];
FrenchQuestionDescriptions[QuestionIds.CHANGE_GAIN] = ["Gain a card and get debt", "Gain a card and get debt"];
FrenchQuestionDescriptions[QuestionIds.NINJA] = FrenchQuestionDescriptions[QuestionIds.MILITIA];
FrenchQuestionDescriptions[QuestionIds.RIVER_SHRINE_TRASH] = ["Trash up to 2 cards", "Trash up to 2 cards"];
FrenchQuestionDescriptions[QuestionIds.RIVER_SHRINE_GAIN] = ["Gain a card costing up to 4.", "Gain a card costing up to 4."];
FrenchQuestionDescriptions[QuestionIds.RUSTIC_VILLAGE_CHOICE] = ["Discard 2 cards to draw 1?", "Discard 2 cards to draw 1?"];
FrenchQuestionDescriptions[QuestionIds.RUSTIC_VILLAGE_DISCARD] = ["Which 2 cards to discard?", "Which 2 cards to discard?"];
FrenchQuestionDescriptions[QuestionIds.GOLD_MINE] = ["Gain a Gold and get 4 debt?", "Gain a Gold and get 4 debt?"];
FrenchQuestionDescriptions[QuestionIds.KITSUNE] = ["Choose how to play Kitsune", "Choose how to play Kitsune"];
FrenchQuestionDescriptions[QuestionIds.TANUKI_TRASH] = FrenchQuestionDescriptions[QuestionIds.REMODEL_TRASH];
FrenchQuestionDescriptions[QuestionIds.TANUKI_GAIN] = FrenchQuestionDescriptions[QuestionIds.REMODEL_GAIN];
FrenchQuestionDescriptions[QuestionIds.SAMURAI] = FrenchQuestionDescriptions[QuestionIds.MILITIA];
FrenchQuestionDescriptions[QuestionIds.MOUNTAIN_SHRINE] = ["You may trash a card from your hand.", "You may trash a card from your hand."];
FrenchQuestionDescriptions[QuestionIds.AMASS] = ["Gain an Action card costing up to 5.", "Gain an Action card costing up to 5."];
FrenchQuestionDescriptions[QuestionIds.ASCETICISM] = ["Trash up to Argument0 Cards.", "Trash up to Argument0 Cards."];
FrenchQuestionDescriptions[QuestionIds.CONTINUE] = ["Gain and play a non-attack Action card", "Gain and play a non-attack Action card"];
FrenchQuestionDescriptions[QuestionIds.CREDIT] = ["Gain an Action or Treasure up to 8", "Gain an Action or Treasure up to 8"];
FrenchQuestionDescriptions[QuestionIds.KINTSUGI_TRASH] = ["Trash a card from your hand.", "Trash a card from your hand."];
FrenchQuestionDescriptions[QuestionIds.KINTSUGI_GAIN] = FrenchQuestionDescriptions[QuestionIds.REMODEL_GAIN];
FrenchQuestionDescriptions[QuestionIds.PRACTICE] = FrenchQuestionDescriptions[QuestionIds.THRONE_ROOM];
FrenchQuestionDescriptions[QuestionIds.SEA_TRADE] = ["Trash up to Argument0 Cards.", "Trash up to Argument0 Cards."];
FrenchQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_FIRST] = ["You may gain 3 Action cards you don't have in play.", "You may gain 3 Action cards you don't have in play."];
FrenchQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_SECOND] = ["You may gain 2 Action cards you don't have in play.", "You may gain 2 Action cards you don't have in play."];
FrenchQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_THIRD] = ["You may gain an Action card you don't have in play.", "You may gain an Action card you don't have in play."];
FrenchQuestionDescriptions[QuestionIds.GATHER] = ["Gain a card costing exactly Argument0.", "Gain a card costing exactly Argument0."];

FrenchQuestionDescriptions[QuestionIds.GAME_PAY_OFF_DEBT] = ["You may pay off Debt.", "You may pay off Debt"];
FrenchQuestionDescriptions[QuestionIds.MAY_PAY_OFF_EXTRA_DEBT] = ["You may pay off Debt.", "You may pay off Debt"];
FrenchQuestionDescriptions[QuestionIds.PAY_OFF_DEBT_BEFORE_CHANGE] = ["Pay off Debt to remodel?", "Pay off Debt to remodel?"];
FrenchQuestionDescriptions[QuestionIds.PAY_OFF_DEBT_BEFORE_STORYTELLER] = ["Pay off Debt to draw fewer Cards?", "Pay off Debt to draw fewer Cards?"];