"use strict";

var RussianCardNames = {};

RussianCardNames[CardNames.BACK] = new TranslatedCardName("карта", "карты", "карту", "карт");
RussianCardNames[CardNames.CURSE] = new TranslatedCardName("Проклятие", "Проклятия", "Проклятие", "Проклятий");
RussianCardNames[CardNames.COPPER] = new TranslatedCardName("Медь", "Меди", "Медь", "карт Меди");
RussianCardNames[CardNames.SILVER] = new TranslatedCardName("Серебро", "Серебра", "Серебро", "карт Серебра");
RussianCardNames[CardNames.GOLD] = new TranslatedCardName("Золото", "Золота", "Золото", "карт Золота");
RussianCardNames[CardNames.ESTATE] = new TranslatedCardName("Поместье", "Поместья", "Поместье", "Поместий");
RussianCardNames[CardNames.DUCHY] = new TranslatedCardName("Герцогство", "Герцогства", "Герцогство", "Герцогств");
RussianCardNames[CardNames.PROVINCE] = new TranslatedCardName("Провинция", "Провинции", "Провинцию", "Провинций");
RussianCardNames[CardNames.ARTISAN] = new TranslatedCardName("Мастеровой", "Мастеровых", "Мастерового", "Мастеровых");
RussianCardNames[CardNames.BANDIT] = new TranslatedCardName("Бандит", "Бандита", "Бандита", "Бандитов");
RussianCardNames[CardNames.BUREAUCRAT] = new TranslatedCardName("Чиновник", "Чиновника", "Чиновника", "Чиновников");
RussianCardNames[CardNames.CELLAR] = new TranslatedCardName("Погреб", "Погреба", "Погреб", "Погребов");
RussianCardNames[CardNames.CHAPEL] = new TranslatedCardName("Часовня", "Часовни", "Часовню", "Часовен");
RussianCardNames[CardNames.COUNCIL_ROOM] = new TranslatedCardName("Зал Совета", "Зала Совета", "Зал Совета", "Залов Совета");
RussianCardNames[CardNames.FESTIVAL] = new TranslatedCardName("Фестиваль", "Фестиваля", "Фестиваль", "Фестивалей");
RussianCardNames[CardNames.GARDENS] = new TranslatedCardName("Сады", "Сада", "Сады", "Садов");
RussianCardNames[CardNames.HARBINGER] = new TranslatedCardName("Предвестник", "Предвестника", "Предвестника", "Предвестников");
RussianCardNames[CardNames.LABORATORY] = new TranslatedCardName("Лаборатория", "Лаборатории", "Лабораторию", "Лабораторий");
RussianCardNames[CardNames.LIBRARY] = new TranslatedCardName("Библиотека", "Библиотеки", "Библиотеку", "Библиотек");
RussianCardNames[CardNames.MARKET] = new TranslatedCardName("Рынок", "Рынка", "Рынок", "Рынков");
RussianCardNames[CardNames.MERCHANT] = new TranslatedCardName("Купец", "Купца", "Купца", "Купцов");
RussianCardNames[CardNames.MILITIA] = new TranslatedCardName("Ополчение", "Ополчения", "Ополчение", "Ополчений");
RussianCardNames[CardNames.MINE] = new TranslatedCardName("Рудник", "Рудника", "Рудник", "Рудников");
RussianCardNames[CardNames.MOAT] = new TranslatedCardName("Ров", "Рва", "Ров", "Рвов");
RussianCardNames[CardNames.MONEYLENDER] = new TranslatedCardName("Ростовщик", "Ростовщика", "Ростовщика", "Ростовщиков");
RussianCardNames[CardNames.POACHER] = new TranslatedCardName("Браконьер", "Браконьера", "Браконьера", "Браконьеров");
RussianCardNames[CardNames.REMODEL] = new TranslatedCardName("Реконструция", "Реконструции", "Реконструцию", "Реконструций");
RussianCardNames[CardNames.SENTRY] = new TranslatedCardName("Часовой", "Часовых", "Часового", "Часовых");
RussianCardNames[CardNames.SMITHY] = new TranslatedCardName("Кузница", "Кузницы", "Кузницу", "Кузниц");
RussianCardNames[CardNames.THRONE_ROOM] = new TranslatedCardName("Тронный Зал", "Тронных Зала", "Тронный Зал", "Тронных Залов");
RussianCardNames[CardNames.VASSAL] = new TranslatedCardName("Подданный", "Подданных", "Подданного", "Подданных");
RussianCardNames[CardNames.VILLAGE] = new TranslatedCardName("Деревня", "Деревни", "Деревню", "Деревень");
RussianCardNames[CardNames.WITCH] = new TranslatedCardName("Ведьма", "Ведьмы", "Ведьму", "Ведьм");
RussianCardNames[CardNames.WORKSHOP] = new TranslatedCardName("Мастерская", "Мастерские", "Мастерскую", "Мастерских");

RussianCardNames[CardNames.COURTYARD] = new TranslatedCardName("Внутренний Двор", "Внутренних Двора", "Внутренний Двор", "Внутренних Дворов");
RussianCardNames[CardNames.CONSPIRATOR] = new TranslatedCardName("Заговорщик", "Заговорщика", "Заговорщика", "Заговорщиков");
RussianCardNames[CardNames.COURTIER] = new TranslatedCardName("Придворный", "Придворных", "Придворного", "Придворных");
RussianCardNames[CardNames.BARON] = new TranslatedCardName("Барон", "Барона", "Барона", "Баронов");
RussianCardNames[CardNames.BRIDGE] = new TranslatedCardName("Мост", "Моста", "Мост", "Мостов");
RussianCardNames[CardNames.DIPLOMAT] = new TranslatedCardName("Дипломат", "Дипломата", "Дипломата", "Дипломатов");
RussianCardNames[CardNames.DUKE] = new TranslatedCardName("Герцог", "Герцога", "Герцога", "Герцогов");
RussianCardNames[CardNames.FARM] = new TranslatedCardName("Ферма", "Фермы", "Ферм", "Ферму");
RussianCardNames[CardNames.NOBLES] = new TranslatedCardName("Вельможи", "карты Вельможей", "Вельможей", "карт Вельможей");
RussianCardNames[CardNames.IRONWORKS] = new TranslatedCardName("Кузнечный Цех", "Кузнечных Цеха", "Кузнечный Цех", "Кузнечных Цехов");
RussianCardNames[CardNames.LURKER] = new TranslatedCardName("Притаившийся", "Притаившихся", "Притаившегося", "Притаившихся");
RussianCardNames[CardNames.MASQUERADE] = new TranslatedCardName("Маскарад", "Маскарада", "Маскарад", "Маскарадов");
RussianCardNames[CardNames.MILL] = new TranslatedCardName("Мельница", "Мельницы", "Мельницу", "Мельниц");
RussianCardNames[CardNames.MINING_VILLAGE] = new TranslatedCardName("Шахтёрская Деревня", "Шахтёрские Деревни", "Шахтёрскую Деревню", "Шахтёрских Деревень");
RussianCardNames[CardNames.MINION] = new TranslatedCardName("Приспешник", "Приспешника", "Приспешника", "Приспешников");
RussianCardNames[CardNames.PATROL] = new TranslatedCardName("Дозор", "Дозора", "Дозор", "Дозоров");
RussianCardNames[CardNames.PAWN] = new TranslatedCardName("Подневольный", "Подневольных", "Подневольного", "Подневольных");
RussianCardNames[CardNames.REPLACE] = new TranslatedCardName("Замещение", "Замещения", "Замещение", "Замещений");
RussianCardNames[CardNames.SECRET_PASSAGE] = new TranslatedCardName("Секретный Проход", "Секретных Прохода", "Секретный Проход", "Секретных Проходов");
RussianCardNames[CardNames.SHANTY_TOWN] = new TranslatedCardName("Трущобы", "карты Трущоб", "Трущобы", "Трущоб");
RussianCardNames[CardNames.STEWARD] = new TranslatedCardName("Управляющий", "Управляющих", "Управляющего", "Управляющих");
RussianCardNames[CardNames.SWINDLER] = new TranslatedCardName("Мошенник", "Мошенника", "Мошенника", "Мошенников");
RussianCardNames[CardNames.TORTURER] = new TranslatedCardName("Мучитель", "Мучителя", "Мучителя", "Мучителей");
RussianCardNames[CardNames.TRADING_POST] = new TranslatedCardName("Фактория", "Фактории", "Факторию", "Факторий");
RussianCardNames[CardNames.UPGRADE] = new TranslatedCardName("Улучшение", "Улучшения", "Улучшение", "Улучшений");
RussianCardNames[CardNames.WISHING_WELL] = new TranslatedCardName("Колодец Желаний", "Колодца Желаний", "Колодец Желаний", "Колодцев Желаний");

RussianCardNames[CardNames.AMBASSADOR] = new TranslatedCardName("Посол", "Посла", "Посла", "Послов");
RussianCardNames[CardNames.BAZAAR] = new TranslatedCardName("Базар", "Базара", "Базар", "Базаров");
RussianCardNames[CardNames.CARAVAN] = new TranslatedCardName("Караван", "Каравана", "Караван", "Караванов");
RussianCardNames[CardNames.CUTPURSE] = new TranslatedCardName("Карманник", "Карманника", "Карманника", "Карманников");
RussianCardNames[CardNames.EMBARGO] = new TranslatedCardName("Эмбарго", "Эмбарго", "Эмбарго", "Эмбарго");
RussianCardNames[CardNames.EXPLORER] = new TranslatedCardName("Исследователь", "Исследователя", "Исследователя", "Исследователей");
RussianCardNames[CardNames.FISHING_VILLAGE] = new TranslatedCardName("Рыбацкая Деревня", "Рыбацких Деревни", "Рыбацкую Деревню", "Рыбацких Деревень");
RussianCardNames[CardNames.GHOST_SHIP] = new TranslatedCardName("Корабль-Призрак", "Корабля-Призрака", "Корабль-Призрак", "Кораблей-Призраков");
RussianCardNames[CardNames.HAVEN] = new TranslatedCardName("Гавань", "Гавани", "Гавань", "Гаваней");
RussianCardNames[CardNames.ISLAND] = new TranslatedCardName("Остров", "Острова", "Остров", "Островов");
RussianCardNames[CardNames.LIGHTHOUSE] = new TranslatedCardName("Маяк", "Маяка", "Маяк", "Маяков");
RussianCardNames[CardNames.LOOKOUT] = new TranslatedCardName("Смотровой", "Смотровых", "Смотрового", "Смотровых");
RussianCardNames[CardNames.MERCHANT_SHIP] = new TranslatedCardName("Торговый Корабль", "Торговых Корабля", "Торговый Корабль", "Торговых Кораблей");
RussianCardNames[CardNames.NATIVE_VILLAGE] = new TranslatedCardName("Поселение Аборигенов", "Поселения Аборигенов", "Поселение Аборигенов", "Поселений Аборигенов");
RussianCardNames[CardNames.NAVIGATOR] = new TranslatedCardName("Штурман", "Штурмана", "Штурмана", "Штурманов");
RussianCardNames[CardNames.OUTPOST] = new TranslatedCardName("Аванпост", "Аванпоста", "Аванпост", "Аванпостов");
RussianCardNames[CardNames.PEARL_DIVER] = new TranslatedCardName("Ловец Жемчуга", "Ловца Жемчуга", "Ловца Жемчуга", "Ловцов Жемчуга");
RussianCardNames[CardNames.PIRATE_SHIP] = new TranslatedCardName("Пиратский Корабль", "Пиратских Корабля", "Пиратский Корабль", "Пиратских Кораблей");
RussianCardNames[CardNames.SALVAGER] = new TranslatedCardName("Спасатель", "Спасателя", "Спасателя", "Спасателей");
RussianCardNames[CardNames.SEA_HAG] = new TranslatedCardName("Морская Ведьма", "Морских Ведьмы", "Морскую Ведьму", "Морских Ведьм");
RussianCardNames[CardNames.SMUGGLERS] = new TranslatedCardName("Контрабандисты", "Контрабандиста", "Контрабандистов", "Контрабандистов");
RussianCardNames[CardNames.TACTICIAN] = new TranslatedCardName("Тактик", "Тактика", "Тактика", "Тактиков");
RussianCardNames[CardNames.TREASURE_MAP] = new TranslatedCardName("Карта Сокровищ", "Карты Сокровищ", "Карту Сокровищ", "Карт Сокровищ");
RussianCardNames[CardNames.TREASURY] = new TranslatedCardName("Казна", "Казны", "Казну", "карт Казны");
RussianCardNames[CardNames.WAREHOUSE] = new TranslatedCardName("Склад", "Склада", "Склад", "Складов");
RussianCardNames[CardNames.WHARF] = new TranslatedCardName("Верфь", "Верфи", "Верфь", "Верфей");

RussianCardNames[CardNames.ALCHEMIST] = new TranslatedCardName("Алхимик", "Алхимика", "Алхимика", "Алхимиков");
RussianCardNames[CardNames.APOTHECARY] = new TranslatedCardName("Аптекарь", "Аптекаря", "Аптекаря", "Аптекарей");
RussianCardNames[CardNames.APPRENTICE] = new TranslatedCardName("Подмастерье", "Подмастерья", "Подмастерье", "Подмастерий");
RussianCardNames[CardNames.FAMILIAR] = new TranslatedCardName("Фамильяр", "Фамильяра", "Фамильяра", "Фамильяров");
RussianCardNames[CardNames.GOLEM] = new TranslatedCardName("Голем", "Голема", "Голема", "Големов");
RussianCardNames[CardNames.HERBALIST] = new TranslatedCardName("Травник", "Травника", "Травника", "Травников");
RussianCardNames[CardNames.PHILOSOPHERS_STONE] = new TranslatedCardName("Философский Камень", "Философских Камня", "Философский Камень", "Философских Камней");
RussianCardNames[CardNames.POSSESSION] = new TranslatedCardName("Одержимость", "Одержимости", "Одержимость", "Одержимостей");
RussianCardNames[CardNames.POTION] = new TranslatedCardName("Зелье", "Зелья", "Зелье", "Зелий");
RussianCardNames[CardNames.SCRYING_POOL] = new TranslatedCardName("Гадательная Чаша", "Гадательные Чаши", "Гадательную Чашу", "Гадательных Чаш");
RussianCardNames[CardNames.TRANSMUTE] = new TranslatedCardName("Превращение", "Превращения", "Превращение", "Превращений");
RussianCardNames[CardNames.UNIVERSITY] = new TranslatedCardName("Университет", "Университета", "Университет", "Университетов");
RussianCardNames[CardNames.VINEYARD] = new TranslatedCardName("Виноградник", "Виноградника", "Виноградник", "Виноградников");

RussianCardNames[CardNames.BANK] = new TranslatedCardName("Банк", "Банка", "Банк", "Банков");
RussianCardNames[CardNames.BISHOP] = new TranslatedCardName("Епископ", "Епископа", "Епископа", "Епископов");
RussianCardNames[CardNames.COLONY] = new TranslatedCardName("Колония", "Колонии", "Колонию", "Колоний");
RussianCardNames[CardNames.CONTRABAND] = new TranslatedCardName("Контрабанда", "Контрабанды", "Контрабанду", "Контрабанд");
RussianCardNames[CardNames.COUNTING_HOUSE] = new TranslatedCardName("Счётная Палата", "Счётных Палаты", "Счётную Палату", "Счётных Палат");
RussianCardNames[CardNames.CITY] = new TranslatedCardName("Город", "Города", "Город", "Городов");
RussianCardNames[CardNames.EXPAND] = new TranslatedCardName("Расширение", "Расширения", "Расширение", "Расширений");
RussianCardNames[CardNames.FORGE] = new TranslatedCardName("Ковка", "Ковки", "Ковку", "Ковок");
RussianCardNames[CardNames.GRAND_MARKET] = new TranslatedCardName("Центральный Рынок", "Центральных Рынка", "Центральный Рынок", "Центральных Рынков");
RussianCardNames[CardNames.GOONS] = new TranslatedCardName("Головорезы", "карты Головорезов", "Головорезов", "карт Головорезов");
RussianCardNames[CardNames.HOARD] = new TranslatedCardName("Сокровищница", "Сокровищницы", "Сокровищницу", "Сокровищниц");
RussianCardNames[CardNames.KINGS_COURT] = new TranslatedCardName("Королевский Суд", "Королевских Суда", "Королевский Суд", "Королевских Судов");
RussianCardNames[CardNames.LOAN] = new TranslatedCardName("Ссуда", "Ссуды", "Ссуду", "Ссуд");
RussianCardNames[CardNames.MINT] = new TranslatedCardName("Монетный Двор", "Монетных Двора", "Монетный Двор", "Монетных Дворов");
RussianCardNames[CardNames.MONUMENT] = new TranslatedCardName("Памятник", "Памятника", "Памятник", "Памятников");
RussianCardNames[CardNames.MOUNTEBANK] = new TranslatedCardName("Шарлатан", "Шарлатана", "Шарлатана", "Шарлатанов");
RussianCardNames[CardNames.PEDDLER] = new TranslatedCardName("Коробейник", "Коробейника", "Коробейника", "Коробейников");
RussianCardNames[CardNames.PLATINUM] = new TranslatedCardName("Платина", "Платины", "Платину", "карт Платины");
RussianCardNames[CardNames.QUARRY] = new TranslatedCardName("Карьер", "Карьера", "Карьер", "Карьеров");
RussianCardNames[CardNames.RABBLE] = new TranslatedCardName("Сброд", "Сброда", "Сброд", "Сбродов");
RussianCardNames[CardNames.ROYAL_SEAL] = new TranslatedCardName("Королевская Печать", "Королевских Печати", "Королевскую Печать", "Королевских Печатей");
RussianCardNames[CardNames.TALISMAN] = new TranslatedCardName("Талисман", "Талисмана", "Талисман", "Талисманов");
RussianCardNames[CardNames.TRADE_ROUTE] = new TranslatedCardName("Торговый Путь", "Торговых Пути", "Торговый Путь", "Торговых Путей");
RussianCardNames[CardNames.VAULT] = new TranslatedCardName("Хранилище", "Хранилища", "Хранилище", "Хранилищ");
RussianCardNames[CardNames.VENTURE] = new TranslatedCardName("Приключение", "Приключения", "Приключение", "Приключений");
RussianCardNames[CardNames.WATCHTOWER] = new TranslatedCardName("Сторожевая Башня", "Сторожевых Башни", "Сторожевую Башню", "Сторожевых Башень");
RussianCardNames[CardNames.WORKERS_VILLAGE] = new TranslatedCardName("Рабочий Посёлок", "Рабочих Посёлка", "Рабочий Посёлок", "Рабочих Посёлков");

RussianCardNames[CardNames.BAG_OF_GOLD] = new TranslatedCardName("Мешок Золота", "Мешка Золота", "Мешок Золота", "Мешков Золота");
RussianCardNames[CardNames.DIADEM] = new TranslatedCardName("Диадема", "Диадемы", "Диадему", "Диадем");
RussianCardNames[CardNames.FAIRGROUNDS] = new TranslatedCardName("Ярмарка", "Ярмарки", "Ярмарку", "Ярмарок");
RussianCardNames[CardNames.FARMING_VILLAGE] = new TranslatedCardName("Деревня Фермеров", "Деревни Фермеров", "Деревню Фермеров", "Деревень Фермеров");
RussianCardNames[CardNames.FOLLOWERS] = new TranslatedCardName("Последователи", "Последователя", "Последователей", "Последователей");
RussianCardNames[CardNames.FORTUNE_TELLER] = new TranslatedCardName("Гадалка", "Гадалки", "Гадалку", "Гадалок");
RussianCardNames[CardNames.HAMLET] = new TranslatedCardName("Деревушка", "Деревушки", "Деревушку", "Деревушек");
RussianCardNames[CardNames.HARVEST] = new TranslatedCardName("Сбор Урожая", "Сбора Урожая", "Сбор Урожая", "Сборов Урожая");
RussianCardNames[CardNames.HORSE_TRADERS] = new TranslatedCardName("Торговцы Лошадьми", "Торговца Лошадьми", "Торговцев Лошадьми", "Торговцев Лошадьми");
RussianCardNames[CardNames.HORN_OF_PLENTY] = new TranslatedCardName("Рог Изобилия", "Рога Изобилия", "Рог Изобилия", "Рогов Изобилия");
RussianCardNames[CardNames.HUNTING_PARTY] = new TranslatedCardName("Охотничий Отряд", "Охотничих Отряда", "Охотничий Отряд", "Охотничих Отрядов");
RussianCardNames[CardNames.JESTER] = new TranslatedCardName("Шут", "Шута", "Шута", "Шутов");
RussianCardNames[CardNames.MENAGERIE] = new TranslatedCardName("Зверинец", "Зверинца", "Зверинец", "Зверинцев");
RussianCardNames[CardNames.PRINCESS] = new TranslatedCardName("Принцесса", "Принцессы", "Принцессу", "Принцесс");
RussianCardNames[CardNames.REMAKE] = new TranslatedCardName("Переделка", "Переделки", "Переделку", "Переделок");
RussianCardNames[CardNames.TOURNAMENT] = new TranslatedCardName("Турнир", "Турнира", "Турнир", "Турниров");
RussianCardNames[CardNames.TRUSTY_STEED] = new TranslatedCardName("Верный Конь", "Верных Коня", "Верного Коня", "Верных Коней");
RussianCardNames[CardNames.YOUNG_WITCH] = new TranslatedCardName("Юная Ведьма", "Юные Ведьмы", "Юную Ведьму", "Юных Ведьм");

RussianCardNames[CardNames.BORDER_VILLAGE] = new TranslatedCardName("Пограничная Деревня", "Пограничных Деревни", "Пограничную Деревню", "Пограничных Деревень");
RussianCardNames[CardNames.CACHE] = new TranslatedCardName("Схрон", "Схрона", "Схрон", "Схронов");
RussianCardNames[CardNames.CARTOGRAPHER] = new TranslatedCardName("Картограф", "Картографа", "Картографа", "Картографов");
RussianCardNames[CardNames.CROSSROADS] = new TranslatedCardName("Перекрёсток", "Перекрёстка", "Перекрёсток", "Перекрёстков");
RussianCardNames[CardNames.DEVELOP] = new TranslatedCardName("Развитие", "Развития", "Развитие", "Развитий");
RussianCardNames[CardNames.DUCHESS] = new TranslatedCardName("Герцогиня", "Герцогини", "Герцогиню", "Герцогинь");
RussianCardNames[CardNames.EMBASSY] = new TranslatedCardName("Посольство", "Посольства", "Посольство", "Посольств");
RussianCardNames[CardNames.FARMLAND] = new TranslatedCardName("Угодья", "карты Угодий", "Угодья", "карт Угодий");
RussianCardNames[CardNames.FOOLS_GOLD] = new TranslatedCardName("Золото Дураков", "Золота Дураков", "Золото Дураков", "Золота Дураков");
RussianCardNames[CardNames.HAGGLER] = new TranslatedCardName("Торгаш", "Торгаша", "Торгаша", "Торгашей");
RussianCardNames[CardNames.HIGHWAY] = new TranslatedCardName("Тракт", "Тракта", "Тракт", "Трактов");
RussianCardNames[CardNames.ILL_GOTTEN_GAINS] = new TranslatedCardName("Проклятое Сокровище", "карты Проклятого Сокровища", "Проклятое Сокровище", "карт Проклятого Сокровища");
RussianCardNames[CardNames.INN] = new TranslatedCardName("Гостиница", "Гостиницы", "Гостиницу", "Гостиниц");
RussianCardNames[CardNames.JACK_OF_ALL_TRADES] = new TranslatedCardName("Мастер на все Руки", "Мастера на все Руки", "Мастера на все Руки", "Мастеров на все Руки");
RussianCardNames[CardNames.MANDARIN] = new TranslatedCardName("Мандарин", "Мандарина", "Мандарина", "Мандаринов");
RussianCardNames[CardNames.NOBLE_BRIGAND] = new TranslatedCardName("Благородный Разбойник", "Благородных Разбойника", "Благородного Разбойника", "Благородных Разбойников");
RussianCardNames[CardNames.NOMAD_CAMP] = new TranslatedCardName("Стойбище", "Стойбища", "Стойбище", "Стойбищ");
RussianCardNames[CardNames.OASIS] = new TranslatedCardName("Оазис", "Оазиса", "Оазис", "Оазисов");
RussianCardNames[CardNames.ORACLE] = new TranslatedCardName("Оракул", "Оракула", "Оракула", "Оракулов");
RussianCardNames[CardNames.MARGRAVE] = new TranslatedCardName("Маркграф", "Маркграфа", "Маркграфа", "Маркграфов");
RussianCardNames[CardNames.SCHEME] = new TranslatedCardName("Схема", "Схемы", "Схему", "Схем");
RussianCardNames[CardNames.SILK_ROAD] = new TranslatedCardName("Шёлковый Путь", "Шёлковых Пути", "Шёлковый Путь", "Шёлковых Путей");
RussianCardNames[CardNames.SPICE_MERCHANT] = new TranslatedCardName("Торговец Специями", "Торговца Специями", "Торговца Специями", "Торговцев Специями");
RussianCardNames[CardNames.STABLES] = new TranslatedCardName("Конюшня", "Конюшни", "Конюшню", "Конюшен");
RussianCardNames[CardNames.TRADER] = new TranslatedCardName("Торговец", "Торговца", "Торговца", "Торговцев");
RussianCardNames[CardNames.TUNNEL] = new TranslatedCardName("Туннель", "Туннеля", "Туннель", "Туннелей");

RussianCardNames[CardNames.RUIN_PILE] = new TranslatedCardName("Руины", "карты Руин", "Руины", "карт Руин");
RussianCardNames[CardNames.KNIGHTS] = new TranslatedCardName("Рыцари", "карты Рыцарей", "Рыцаря", "карт Рыцарей");
RussianCardNames[CardNames.ABANDONED_MINE] = new TranslatedCardName("Заброшенный Рудник", "Заброшенных Рудника", "Заброшенный Рудник", "Заброшенных Рудников");
RussianCardNames[CardNames.ALTAR] = new TranslatedCardName("Алтарь", "Алтаря", "Алтарь", "Алтарей");
RussianCardNames[CardNames.ARMORY] = new TranslatedCardName("Арсенал", "Арсенала", "Арсенал", "Арсеналов");
RussianCardNames[CardNames.BAND_OF_MISFITS] = new TranslatedCardName("Отщепенцы", "карты Отщепенцев", "Отщепенцев", "карт Отщепенцев");
RussianCardNames[CardNames.BANDIT_CAMP] = new TranslatedCardName("Лагерь Разбойников", "Лагеря Разбойников", "Лагерь Разбойников", "Лагерей Разбойников");
RussianCardNames[CardNames.BEGGAR] = new TranslatedCardName("Попрошайка", "Попрошаек", "Попрошайку", "Попрошаек");
RussianCardNames[CardNames.CATACOMBS] = new TranslatedCardName("Катакомбы", "карты Катакомб", "Катакомбы", "карт Катакомб");
RussianCardNames[CardNames.COUNT] = new TranslatedCardName("Граф", "Графа", "Графа", "Графов");
RussianCardNames[CardNames.COUNTERFEIT] = new TranslatedCardName("Подделка", "Подделки", "Подделку", "Подделок");
RussianCardNames[CardNames.CULTIST] = new TranslatedCardName("Культист", "Культиста", "Культиста", "Культистов");
RussianCardNames[CardNames.DAME_ANNA] = new TranslatedCardName("Леди Анна", "Леди Анны", "Леди Анну", "Леди Анн");
RussianCardNames[CardNames.DAME_JOSEPHINE] = new TranslatedCardName("Леди Джозефина", "Леди Джозефин", "Леди Джозефину", "Леди Джозефин");
RussianCardNames[CardNames.DAME_MOLLY] = new TranslatedCardName("Леди Молли", "Леди Молли", "Леди Молли", "Леди Молли");
RussianCardNames[CardNames.DAME_NATALIE] = new TranslatedCardName("Леди Натали", "Леди Натали", "Леди Натали", "Леди Натали");
RussianCardNames[CardNames.DAME_SYLVIA] = new TranslatedCardName("Леди Сильвия", "Леди Сильвии", "Леди Сильвию", "Леди Сильвий");
RussianCardNames[CardNames.DEATH_CART] = new TranslatedCardName("Труповозка", "Труповозки", "Труповозку", "Труповозок");
RussianCardNames[CardNames.FEODUM] = new TranslatedCardName("Феод", "Феода", "Феод", "Феодов");
RussianCardNames[CardNames.FORAGER] = new TranslatedCardName("Фуражир", "Фуражира", "Фуражира", "Фуражиров");
RussianCardNames[CardNames.FORTRESS] = new TranslatedCardName("Крепость", "Крепости", "Крепость", "Крепостей");
RussianCardNames[CardNames.GRAVEROBBER] = new TranslatedCardName("Расхититель Могил", "Расхитителя Могил", "Расхитителя Могил", "Расхитителей Могил");
RussianCardNames[CardNames.HERMIT] = new TranslatedCardName("Отшельник", "Отшельника", "Отшельника", "Отшельников");
RussianCardNames[CardNames.HOVEL] = new TranslatedCardName("Лачуга", "Лачуги", "Лачугу", "Лачуг");
RussianCardNames[CardNames.HUNTING_GROUNDS] = new TranslatedCardName("Охотничьи Угодья", "карты Охотничьих Угодий", "Охотничьи Угодья", "карт Охотничьих Угодий");
RussianCardNames[CardNames.IRONMONGER] = new TranslatedCardName("Скобянщик", "Скобянщика", "Скобянщика", "Скобянщиков");
RussianCardNames[CardNames.JUNK_DEALER] = new TranslatedCardName("Старьёвщик", "Старьёвщика", "Старьёвщика", "Старьёвщиков");
RussianCardNames[CardNames.MADMAN] = new TranslatedCardName("Безумец", "Безумца", "Безумца", "Безумцев");
RussianCardNames[CardNames.MARKET_SQUARE] = new TranslatedCardName("Рыночная Площадь", "Рыночных Площади", "Рыночную Площадь", "Рыночных Площадей");
RussianCardNames[CardNames.MARAUDER] = new TranslatedCardName("Мародёр", "Мародёра", "Мародёра", "Мародёров");
RussianCardNames[CardNames.MERCENARY] = new TranslatedCardName("Наёмный Убийца", "Наёмных Убийцы", "Наёмного Убийцу", "Наёмных Убийц");
RussianCardNames[CardNames.MYSTIC] = new TranslatedCardName("Мистик", "Мистика", "Мистика", "Мистиков");
RussianCardNames[CardNames.NECROPOLIS] = new TranslatedCardName("Некрополь", "Некрополя", "Некрополь", "Некрополей");
RussianCardNames[CardNames.OVERGROWN_ESTATE] = new TranslatedCardName("Заросшее Поместье", "Заросших Поместья", "Заросшее Поместье", "Заросших Поместий");
RussianCardNames[CardNames.PILLAGE] = new TranslatedCardName("Погром", "Погрома", "Погром", "Погромов");
RussianCardNames[CardNames.POOR_HOUSE] = new TranslatedCardName("Богадельня", "Богадельни", "Богадельню", "Богаделен");
RussianCardNames[CardNames.PROCESSION] = new TranslatedCardName("Шествие", "Шествия", "Шествие", "Шествий");
RussianCardNames[CardNames.RATS] = new TranslatedCardName("Крысы", "карты Крыс", "Крыс", "карт Крыс");
RussianCardNames[CardNames.REBUILD] = new TranslatedCardName("Перестройка", "Перестройки", "Перестройку", "Перестроек");
RussianCardNames[CardNames.ROGUE] = new TranslatedCardName("Жулик", "Жулика", "Жулика", "Жуликов");
RussianCardNames[CardNames.RUINED_LIBRARY] = new TranslatedCardName("Разрушенная Библиотека", "Разрушенных Библиотеки", "Разрушенную Библиотеку", "Разрушенных Библиотек");
RussianCardNames[CardNames.RUINED_MARKET] = new TranslatedCardName("Разрушенный Рынок", "Разрушенных Рынка", "Разрушенный Рынок", "Разрушенных Рынков");
RussianCardNames[CardNames.RUINED_VILLAGE] = new TranslatedCardName("Разрушенная Деревня", "Разрушенных Деревни", "Разрушенную Деревню", "Разрушенных Деревень");
RussianCardNames[CardNames.SAGE] = new TranslatedCardName("Мудрец", "Мудреца", "Мудреца", "Мудрецов");
RussianCardNames[CardNames.SCAVENGER] = new TranslatedCardName("Мусорщик", "Мусорщика", "Мусорщика", "Мусорщиков");
RussianCardNames[CardNames.SIR_BAILEY] = new TranslatedCardName("Сэр Бейли", "Сэра Бейли", "Сэра Бейли", "Сэров Бейли");
RussianCardNames[CardNames.SIR_DESTRY] = new TranslatedCardName("Сэр Дестри", "Сэра Дестри", "Сэра Дестри", "Сэров Дестри");
RussianCardNames[CardNames.SIR_MARTIN] = new TranslatedCardName("Сэр Мартин", "Сэра Мартина", "Сэра Мартина", "Сэров Мартинов");
RussianCardNames[CardNames.SIR_MICHAEL] = new TranslatedCardName("Сэр Майкл", "Сэра Майкла", "Сэра Майкла", "Сэров Майклов");
RussianCardNames[CardNames.SIR_VANDER] = new TranslatedCardName("Сэр Вандер", "Сэра Вандера", "Сэра Вандера", "Сэров Вандеров");
RussianCardNames[CardNames.SPOILS] = new TranslatedCardName("Добыча", "карты Добычи", "Добычу", "карт Добычи");
RussianCardNames[CardNames.STOREROOM] = new TranslatedCardName("Кладовая", "Кладовые", "Кладовую", "Кладовых");
RussianCardNames[CardNames.SQUIRE] = new TranslatedCardName("Оруженосец", "Оруженосца", "Оруженосца", "Оруженосцев");
RussianCardNames[CardNames.SURVIVORS] = new TranslatedCardName("Уцелевшие", "карты Уцелевших", "Уцелевших", "карт Уцелевших");
RussianCardNames[CardNames.URCHIN] = new TranslatedCardName("Беспризорник", "Беспризорника", "Беспризорника", "Беспризорников");
RussianCardNames[CardNames.VAGRANT] = new TranslatedCardName("Бродяга", "Бродяги", "Бродягу", "Бродяг");
RussianCardNames[CardNames.WANDERING_MINSTREL] = new TranslatedCardName("Странствующий Бард", "Странствующих Барда", "Странствующего Барда", "Странствующих Бардов");

RussianCardNames[CardNames.ADVISOR] = new TranslatedCardName("Советник", "Советника", "Советника", "Советников");
RussianCardNames[CardNames.BAKER] = new TranslatedCardName("Пекарь", "Пекаря", "Пекаря", "Пекарей");
RussianCardNames[CardNames.BUTCHER] = new TranslatedCardName("Мясник", "Мясника", "Мясника", "Мясников");
RussianCardNames[CardNames.CANDLESTICK_MAKER] = new TranslatedCardName("Свечной Мастер", "Свечных Мастера", "Свечного Мастера", "Свечных Мастеров");
RussianCardNames[CardNames.DOCTOR] = new TranslatedCardName("Доктор", "Доктора", "Доктора", "Докторов");
RussianCardNames[CardNames.HERALD] = new TranslatedCardName("Глашатай", "Глашатая", "Глашатая", "Глашатаев");
RussianCardNames[CardNames.JOURNEYMAN] = new TranslatedCardName("Подёнщик", "Подёнщика", "Подёнщика", "Подёнщиков");
RussianCardNames[CardNames.MASTERPIECE] = new TranslatedCardName("Шедевр", "Шедевра", "Шедевр", "Шедевров");
RussianCardNames[CardNames.MERCHANT_GUILD] = new TranslatedCardName("Купеческая Гильдия", "Купеческие Гильдии", "Купеческую Гильдию", "Купеческих Гильдий");
RussianCardNames[CardNames.PLAZA] = new TranslatedCardName("Площадь", "Площади", "Площадь", "Площадей");
RussianCardNames[CardNames.TAXMAN] = new TranslatedCardName("Сборщик Налогов", "Сборщика Налогов", "Сборщика Налогов", "Сборщиков Налогов");
RussianCardNames[CardNames.SOOTHSAYER] = new TranslatedCardName("Предсказатель", "Предсказателя", "Предсказателя", "Предсказателей");
RussianCardNames[CardNames.STONEMASON] = new TranslatedCardName("Каменщик", "Каменщика", "Каменщика", "Каменщиков");

RussianCardNames[CardNames.ALMS] = new TranslatedCardName("Милостыня", "Милостыни", "Милостыню", "Милостынь");
RussianCardNames[CardNames.AMULET] = new TranslatedCardName("Амулет", "Амулета", "Амулет", "Амулетов");
RussianCardNames[CardNames.ARTIFICER] = new TranslatedCardName("Ремесленник", "Ремесленника", "Ремесленника", "Ремесленников");
RussianCardNames[CardNames.BALL] = new TranslatedCardName("Бал", "Бала", "Бал", "Балов");
RussianCardNames[CardNames.BONFIRE] = new TranslatedCardName("Костёр", "Костра", "Костёр", "Костров");
RussianCardNames[CardNames.BORROW] = new TranslatedCardName("Заем", "Займа", "Заем", "Займов");
RussianCardNames[CardNames.BRIDGE_TROLL] = new TranslatedCardName("Тролль под Мостом", "Тролля под Мостом", "Тролля под Мостом", "Троллей под Мостом");
RussianCardNames[CardNames.CARAVAN_GUARD] = new TranslatedCardName("Охрана Каравана", "Охраны Каравана", "Охрану Каравана", "Охран Каравана");
RussianCardNames[CardNames.CHAMPION] = new TranslatedCardName("Чемпион", "Чемпиона", "Чемпиона", "Чемпионов");
RussianCardNames[CardNames.COIN_OF_THE_REALM] = new TranslatedCardName("Королевская Монета", "Королевские Монеты", "Королевскую Монету", "Королевских Монет");
RussianCardNames[CardNames.DISCIPLE] = new TranslatedCardName("Ученик", "Ученика", "Ученика", "Учеников");
RussianCardNames[CardNames.DISTANT_LANDS] = new TranslatedCardName("Дальние Земли", "карты Дальних Земель", "Дальние Земли", "карт Дальних Земель");
RussianCardNames[CardNames.DUNGEON] = new TranslatedCardName("Подземелье", "Подземелья", "Подземелье", "Подземелий");
RussianCardNames[CardNames.DUPLICATE] = new TranslatedCardName("Дублирование", "Дублирования", "Дублирование", "Дублирований");
RussianCardNames[CardNames.EXPEDITION] = new TranslatedCardName("Экспедиция", "Экспедиции", "Экспедицию", "Экспедиций");
RussianCardNames[CardNames.FERRY] = new TranslatedCardName("Переправа", "Переправы", "Переправу", "Переправ");
RussianCardNames[CardNames.FUGITIVE] = new TranslatedCardName("Дезертир", "Дезертира", "Дезертира", "Дезертиров");
RussianCardNames[CardNames.GEAR] = new TranslatedCardName("Снаряжение", "Снаряжения", "Снаряжение", "Снаряжений");
RussianCardNames[CardNames.GIANT] = new TranslatedCardName("Гигант", "Гиганта", "Гиганта", "Гигантов");
RussianCardNames[CardNames.GUIDE] = new TranslatedCardName("Проводник", "Проводника", "Проводника", "Проводников");
RussianCardNames[CardNames.HAUNTED_WOODS] = new TranslatedCardName("Призрачный Лес", "Призрачных Леса", "Призрачный Лес", "Призрачных Лесов");
RussianCardNames[CardNames.HERO] = new TranslatedCardName("Герой", "Героя", "Героя", "Героев");
RussianCardNames[CardNames.HIRELING] = new TranslatedCardName("Наёмник", "Наёмника", "Наёмника", "Наёмников");
RussianCardNames[CardNames.INHERITANCE] = new TranslatedCardName("Наследство", "Наследства", "Наследство", "Наследств");
RussianCardNames[CardNames.LOST_ARTS] = new TranslatedCardName("Утерянные Искусства", "события Утерянных Искусств", "Утерянные Искусства", "событий Утерянных Искусств");
RussianCardNames[CardNames.LOST_CITY] = new TranslatedCardName("Затерянный Город", "Затерянных Города", "Затерянный Город", "Затерянных Городов");
RussianCardNames[CardNames.MAGPIE] = new TranslatedCardName("Сорока", "Сороки", "Сороку", "Сорок");
RussianCardNames[CardNames.MESSENGER] = new TranslatedCardName("Посланник", "Посланника", "Посланника", "Посланников");
RussianCardNames[CardNames.MISER] = new TranslatedCardName("Скряга", "Скряги", "Скрягу", "Скряг");
RussianCardNames[CardNames.MISSION] = new TranslatedCardName("Миссия", "Миссии", "Миссию", "Миссий");
RussianCardNames[CardNames.PATHFINDING] = new TranslatedCardName("Землепроходство", "Землепроходства", "Землепроходство", "Землепроходств");
RussianCardNames[CardNames.PAGE] = new TranslatedCardName("Паж", "Пажа", "Пажа", "Пажей");
RussianCardNames[CardNames.PEASANT] = new TranslatedCardName("Крестьянин", "Крестьянина", "Крестьянина", "Крестьян");
RussianCardNames[CardNames.PILGRIMAGE] = new TranslatedCardName("Паломничество", "Паломничества", "Паломничество", "Паломничеств");
RussianCardNames[CardNames.PLAN] = new TranslatedCardName("Замысел", "Замысла", "Замысел", "Замыслов");
RussianCardNames[CardNames.PORT] = new TranslatedCardName("Порт", "Порта", "Порт", "Портов");
RussianCardNames[CardNames.QUEST] = new TranslatedCardName("Поход", "Похода", "Поход", "Походов");
RussianCardNames[CardNames.RANGER] = new TranslatedCardName("Лесничий", "Лесничих", "Лесничего", "Лесничих");
RussianCardNames[CardNames.RAID] = new TranslatedCardName("Набег", "Набега", "Набег", "Набегов");
RussianCardNames[CardNames.RATCATCHER] = new TranslatedCardName("Крысолов", "Крысолова", "Крысолова", "Крысоловов");
RussianCardNames[CardNames.RAZE] = new TranslatedCardName("Разрушение", "Разрушения", "Разрушение", "Разрушений");
RussianCardNames[CardNames.RELIC] = new TranslatedCardName("Реликвия", "Реликвии", "Реликвию", "Реликвий");
RussianCardNames[CardNames.ROYAL_CARRIAGE] = new TranslatedCardName("Королевская Карета", "Королевские Кареты", "Королевскую Карету", "Королевских Карет");
RussianCardNames[CardNames.SAVE] = new TranslatedCardName("Сбережение", "Сбережения", "Сбережение", "Сбережений");
RussianCardNames[CardNames.SCOUTING_PARTY] = new TranslatedCardName("Поисковый Отряд", "Поисковых Отряда", "Поисковый Отряд", "Поисковых Отрядов");
RussianCardNames[CardNames.SEAWAY] = new TranslatedCardName("Фарватер", "Фарватера", "Фарватер", "Фарватеров");
RussianCardNames[CardNames.SOLDIER] = new TranslatedCardName("Солдат", "Солдата", "Солдата", "Солдатов");
RussianCardNames[CardNames.STORYTELLER] = new TranslatedCardName("Сказочник", "Сказочника", "Сказочника", "Сказочников");
RussianCardNames[CardNames.SWAMP_HAG] = new TranslatedCardName("Болотная Ведьма", "Болотные Ведьмы", "Болотную Ведьму", "Болотных Ведьм");
RussianCardNames[CardNames.TEACHER] = new TranslatedCardName("Учитель", "Учителя", "Учителя", "Учителей");
RussianCardNames[CardNames.TRAVELLING_FAIR] = new TranslatedCardName("Странствующий Балаган", "Странствующих Балагана", "Странствующий Балаган", "Странствующих Балаганов");
RussianCardNames[CardNames.TRADE] = new TranslatedCardName("Сделка", "Сделки", "Сделку", "Сделок");
RussianCardNames[CardNames.TRAINING] = new TranslatedCardName("Обучение", "Обучения", "Обучение", "Обучений");
RussianCardNames[CardNames.TRANSMOGRIFY] = new TranslatedCardName("Метаморфоза", "Метаморфозы", "Метаморфозу", "Метаморфоз");
RussianCardNames[CardNames.TREASURE_TROVE] = new TranslatedCardName("Клад", "Клада", "Клад", "Кладов");
RussianCardNames[CardNames.TREASURE_HUNTER] = new TranslatedCardName("Охотник за Сокровищами", "Охотника за Сокровищами", "Охотника за Сокровищами", "Охотников за Сокровищами");
RussianCardNames[CardNames.WARRIOR] = new TranslatedCardName("Воин", "Воина", "Воина", "Воинов");
RussianCardNames[CardNames.WINE_MERCHANT] = new TranslatedCardName("Винный Торговец", "Винных Торговца", "Винного Торговца", "Винных Торговецев");

RussianCardNames[CardNames.ENCAMPMENT] = new TranslatedCardName("Лагерь", "Лагеря", "Лагерь", "Лагерей");
RussianCardNames[CardNames.PLUNDER] = new TranslatedCardName("Награбленное", "карты Награбленного", "Награбленное", "карт Награбленного");
RussianCardNames[CardNames.PATRICIAN] = new TranslatedCardName("Патриций", "Патриция", "Патриция", "Патрициев");
RussianCardNames[CardNames.EMPORIUM] = new TranslatedCardName("Эмпорий", "Эмпория", "Эмпорий", "Эмпориев");
RussianCardNames[CardNames.SETTLERS] = new TranslatedCardName("Поселенцы", "карты Поселенцев", "Поселенцев", "карт Поселенцев");
RussianCardNames[CardNames.BUSTLING_VILLAGE] = new TranslatedCardName("Суетливая Деревня", "Суетливых Деревни", "Суетливую Деревню", "Суетливых Деревень");
RussianCardNames[CardNames.CATAPULT] = new TranslatedCardName("Катапульта", "Катапульты", "Катапульту", "Катапульт");
RussianCardNames[CardNames.ROCKS] = new TranslatedCardName("Булыжники", "карты Булыжников", "Булыжники", "карт Булыжников");
RussianCardNames[CardNames.GLADIATOR] = new TranslatedCardName("Гладиатор", "Гладиатора", "Гладиатора", "Гладиаторов");
RussianCardNames[CardNames.FORTUNE] = new TranslatedCardName("Состояние", "Состояния", "Состояние", "Состояний");
RussianCardNames[CardNames.CASTLES] = new TranslatedCardName("Замки", "Замка", "Замки", "Замков");
RussianCardNames[CardNames.HUMBLE_CASTLE] = new TranslatedCardName("Скромный Замок", "Скромных Замка", "Скромный Замок", "Скромных Замков");
RussianCardNames[CardNames.CRUMBLING_CASTLE] = new TranslatedCardName("Полуразрушенный Замок", "Полуразрушенных Замка", "Полуразрушенный Замок", "Полуразрушенных Замков");
RussianCardNames[CardNames.SMALL_CASTLE] = new TranslatedCardName("Маленький Замок", "Маленьких Замка", "Маленький Замок", "Маленьких Замков");
RussianCardNames[CardNames.HAUNTED_CASTLE] = new TranslatedCardName("Замок с Привидениями", "Замка с Привидениями", "Замок с Привидениями", "Замков с Привидениями");
RussianCardNames[CardNames.OPULENT_CASTLE] = new TranslatedCardName("Роскошный Замок", "Роскошных Замка", "Роскошный Замок", "Роскошных Замков");
RussianCardNames[CardNames.SPRAWLING_CASTLE] = new TranslatedCardName("Разросшийся Замок", "Разросшихся Замка", "Разросшийся Замок", "Разросшихся Замков");
RussianCardNames[CardNames.GRAND_CASTLE] = new TranslatedCardName("Великий Замок", "Великих Замка", "Великий Замок", "Великих Замков");
RussianCardNames[CardNames.KINGS_CASTLE] = new TranslatedCardName("Королевский Замок", "Королевских Замка", "Королевский Замок", "Королевских Замков");
RussianCardNames[CardNames.ADVANCE] = new TranslatedCardName("Продвижение", "Продвижения", "Продвижение", "Продвижений");
RussianCardNames[CardNames.ANNEX] = new TranslatedCardName("Захват", "Захвата", "Захват", "Захватов");
RussianCardNames[CardNames.ARCHIVE] = new TranslatedCardName("Архив", "Архива", "Архив", "Архивов");
RussianCardNames[CardNames.AQUEDUCT] = new TranslatedCardName("Акведук", "Акведука", "Акведук", "Акведуков");
RussianCardNames[CardNames.ARENA] = new TranslatedCardName("Арена", "Арены", "Арену", "Арен");
RussianCardNames[CardNames.BANDIT_FORT] = new TranslatedCardName("Укрепления Разбойников", "карты Укреплений Разбойников", "Укрепления Разбойников", "карт Укреплений Разбойников");
RussianCardNames[CardNames.BANQUET] = new TranslatedCardName("Банкет", "Банкета", "Банкет", "Банкетов");
RussianCardNames[CardNames.BASILICA] = new TranslatedCardName("Базилика", "Базилики", "Базилику", "Базилик");
RussianCardNames[CardNames.BATHS] = new TranslatedCardName("Купальни", "карты Купален", "Купальни", "карт Купален");
RussianCardNames[CardNames.BATTLEFIELD] = new TranslatedCardName("Поле Боя", "Поля Боя", "Поле Боя", "Полей Боя");
RussianCardNames[CardNames.CAPITAL] = new TranslatedCardName("Капитал", "Капитала", "Капитал", "Капиталов");
RussianCardNames[CardNames.CHARM] = new TranslatedCardName("Безделушка", "Безделушки", "Безделушку", "Безделушек");
RussianCardNames[CardNames.CHARIOT_RACE] = new TranslatedCardName("Гонки на Колесницах", "карты Гонок на Колесницах", "Гонки на Колесницах", "карт Гонок на Колесницах");
RussianCardNames[CardNames.CITY_QUARTER] = new TranslatedCardName("Городской Квартал", "Городских Квартала", "Городской Квартал", "Городских Кварталов");
RussianCardNames[CardNames.COLONNADE] = new TranslatedCardName("Колоннада", "Колоннады", "Колоннаду", "Колоннад");
RussianCardNames[CardNames.CONQUEST] = new TranslatedCardName("Завоевание", "Завоевания", "Завоевание", "Завоеваний");
RussianCardNames[CardNames.CROWN] = new TranslatedCardName("Корона", "Короны", "Корона", "Корон");
RussianCardNames[CardNames.DELVE] = new TranslatedCardName("Шурф", "Шурфа", "Шурф", "Шурфов");
RussianCardNames[CardNames.DEFILED_SHRINE] = new TranslatedCardName("Осквернённая Святыня", "Осквернённые Святыни", "Осквернённую Святыню", "Осквернённых Святынь");
RussianCardNames[CardNames.DOMINATE] = new TranslatedCardName("Господство", "Господства", "Господство", "Господств");
RussianCardNames[CardNames.DONATE] = new TranslatedCardName("Пожертвование", "Пожертвования", "Пожертвование", "Пожертвований");
RussianCardNames[CardNames.ENCHANTRESS] = new TranslatedCardName("Заклинательница", "Заклинательницы", "Заклинательницу", "Заклинательниц");
RussianCardNames[CardNames.ENGINEER] = new TranslatedCardName("Инженер", "Инженера", "Инженера", "Инженеров");
RussianCardNames[CardNames.FARMERS_MARKET] = new TranslatedCardName("Фермерский Рынок", "Фермерских Рынка", "Фермерский Рынок", "Фермерских Рынков");
RussianCardNames[CardNames.FORUM] = new TranslatedCardName("Форум", "Форума", "Форум", "Форумов");
RussianCardNames[CardNames.FOUNTAIN] = new TranslatedCardName("Фонтан", "Фонтана", "Фонтан", "Фонтанов");
RussianCardNames[CardNames.GROUNDSKEEPER] = new TranslatedCardName("Садовник", "Садовника", "Садовника", "Садовников");
RussianCardNames[CardNames.KEEP] = new TranslatedCardName("Замковые Укрепления", "вехи Замковых Укреплений", "Замковые Укрепления", "вех Замковых Укреплений");
RussianCardNames[CardNames.LABYRINTH] = new TranslatedCardName("Лабиринт", "Лабиринта", "Лабиринт", "Лабиринтов");
RussianCardNames[CardNames.LEGIONARY] = new TranslatedCardName("Легионер", "Легионера", "Легионера", "Легионеров");
RussianCardNames[CardNames.MOUNTAIN_PASS] = new TranslatedCardName("Горный Перевал", "Горных Перевала", "Горный Перевал", "Горных Перевалов");
RussianCardNames[CardNames.MUSEUM] = new TranslatedCardName("Музей", "Музея", "Музей", "Музеев");
RussianCardNames[CardNames.OBELISK] = new TranslatedCardName("Обелиск", "Обелиска", "Обелиск", "Обелисков");
RussianCardNames[CardNames.ORCHARD] = new TranslatedCardName("Фруктовый Сад", "Фруктовых Сада", "Фруктовый Сад", "Фруктовых Садов");
RussianCardNames[CardNames.OVERLORD] = new TranslatedCardName("Повелитель", "Повелителя", "Повелителя", "Повелителей");
RussianCardNames[CardNames.PALACE] = new TranslatedCardName("Дворец", "Дворца", "Дворец", "Дворецов");
RussianCardNames[CardNames.RITUAL] = new TranslatedCardName("Ритуал", "Ритуала", "Ритуал", "Ритуалов");
RussianCardNames[CardNames.ROYAL_BLACKSMITH] = new TranslatedCardName("Королевский Кузнец", "Королевских Кузнеца", "Королевского Кузнеца", "Королевских Кузнецов");
RussianCardNames[CardNames.SACRIFICE] = new TranslatedCardName("Жертвоприношение", "Жертвоприношения", "Жертвоприношение", "Жертвоприношений");
RussianCardNames[CardNames.SALT_THE_EARTH] = new TranslatedCardName("Соление Земель", "Соления Земель", "Соление Земель", "Солений Земель");
RussianCardNames[CardNames.TAX] = new TranslatedCardName("Налог", "Налога", "Налог", "Налогов");
RussianCardNames[CardNames.TEMPLE] = new TranslatedCardName("Храм", "Храма", "Храма", "Храмов");
RussianCardNames[CardNames.TOMB] = new TranslatedCardName("Гробница", "Гробницы", "Гробницу", "Гробниц");
RussianCardNames[CardNames.TOWER] = new TranslatedCardName("Башня", "Башни", "Башню", "Башень");
RussianCardNames[CardNames.TRIUMPH] = new TranslatedCardName("Триумф", "Триумфа", "Триумф", "Триумфов");
RussianCardNames[CardNames.TRIUMPHAL_ARCH] = new TranslatedCardName("Триумфальная Арка", "Триумфальные Арки", "Триумфальную Арку", "Триумфальных Арок");
RussianCardNames[CardNames.VILLA] = new TranslatedCardName("Вилла", "Виллы", "Виллу", "Вилл");
RussianCardNames[CardNames.WALL] = new TranslatedCardName("Стена", "Стены", "Стену", "Стен");
RussianCardNames[CardNames.WOLF_DEN] = new TranslatedCardName("Волчье Логово", "Волчьих Логова", "Волчье Логово", "Волчьих Логов");
RussianCardNames[CardNames.WEDDING] = new TranslatedCardName("Бракосочетание", "Бракосочетания", "Бракосочетание", "Бракосочетаний");
RussianCardNames[CardNames.WILD_HUNT] = new TranslatedCardName("Дикая Охота", "Диких Охоты", "Дикую Охоту", "Диких Охот");
RussianCardNames[CardNames.WINDFALL] = new TranslatedCardName("Нежданная Удача", "Нежданных Удачи", "Нежданную Удачу", "Нежданных Удач");

RussianCardNames[CardNames.BOON_DRAWPILE] = new TranslatedCardName("Колода Благодеяний", "Колоды Благодеяний", "Колоду Благодеяний", "Колод Благодеяний");
RussianCardNames[CardNames.BOON_DISCARDPILE] = new TranslatedCardName("Колода сброса Благодеяний", "Колоды сброса Благодеяний", "Колоду сброса Благодеяний", "Колод сброса Благодеяний");
RussianCardNames[CardNames.THE_EARTHS_GIFT] = new TranslatedCardName("Дар Земли", "Дара Земли", "Дар Земли", "Даров Земли");
RussianCardNames[CardNames.THE_FIELDS_GIFT] = new TranslatedCardName("Дар Полей", "Дара Полей", "Дар Полей", "Даров Полей");
RussianCardNames[CardNames.THE_FLAMES_GIFT] = new TranslatedCardName("Дар Пламени", "Дара Пламени", "Дар Пламени", "Даров Пламени");
RussianCardNames[CardNames.THE_FORESTS_GIFT] = new TranslatedCardName("Дар Леса", "Дара Леса", "Дар Леса", "Даров Леса");
RussianCardNames[CardNames.THE_MOONS_GIFT] = new TranslatedCardName("Дар Луны", "Дара Луны", "Дар Луны", "Даров Луны");
RussianCardNames[CardNames.THE_MOUNTAINS_GIFT] = new TranslatedCardName("Дар Гор", "Дара Гор", "Дар Гор", "Даров Гор");
RussianCardNames[CardNames.THE_RIVERS_GIFT] = new TranslatedCardName("Дар Рек", "Дара Рек", "Дар Рек", "Даров Рек");
RussianCardNames[CardNames.THE_SEAS_GIFT] = new TranslatedCardName("Дар Морей", "Дара Морей", "Дар Морей", "Даров Морей");
RussianCardNames[CardNames.THE_SKYS_GIFT] = new TranslatedCardName("Дар Неба", "Дара Неба", "Дар Неба", "Даров Неба");
RussianCardNames[CardNames.THE_SUNS_GIFT] = new TranslatedCardName("Дар Солнца", "Дара Солнца", "Дар Солнца", "Даров Солнца");
RussianCardNames[CardNames.THE_SWAMPS_GIFT] = new TranslatedCardName("Дар Болот", "Дара Болот", "Дар Болот", "Даров Болот");
RussianCardNames[CardNames.THE_WINDS_GIFT] = new TranslatedCardName("Дар Ветров", "Дара Ветров", "Дар Ветров", "Даров Ветров");
RussianCardNames[CardNames.HEX_DRAWPILE] = new TranslatedCardName("Колода Порч", "Колоды Порч", "Колоду Порч", "Колод Порч");
RussianCardNames[CardNames.HEX_DISCARDPILE] = new TranslatedCardName("Колода сброса Порч", "Колоды сброса Порч", "Колоду сброса Порч", "Колод сброса Порч");
RussianCardNames[CardNames.BAD_OMENS] = new TranslatedCardName("Плохая Примета", "Плохих Приметы", "Плохую Примету", "Плохих Примет");
RussianCardNames[CardNames.DELUSION] = new TranslatedCardName("Наваждение", "Наваждения", "Наваждение", "Наваждений");
RussianCardNames[CardNames.ENVY] = new TranslatedCardName("Зависть", "Зависти", "Зависть", "Завистей");
RussianCardNames[CardNames.FAMINE] = new TranslatedCardName("Голод", "Голода", "Голод", "Порч Голода");
RussianCardNames[CardNames.FEAR] = new TranslatedCardName("Страх", "Страха", "Страх", "Страхов");
RussianCardNames[CardNames.GREED] = new TranslatedCardName("Жадность", "Жадности", "Жадность", "Жадностей");
RussianCardNames[CardNames.HAUNTING] = new TranslatedCardName("Одержимость Привидениями", "Одержимости Привидениями", "Одержимость Привидениями", "Одержимостей Привидениями");
RussianCardNames[CardNames.LOCUSTS] = new TranslatedCardName("Саранча", "Саранчи", "Саранчу", "Саранчей");
RussianCardNames[CardNames.MISERY] = new TranslatedCardName("Несчастье", "Несчастья", "Несчастье", "Несчастий");
RussianCardNames[CardNames.PLAGUE] = new TranslatedCardName("Чума", "Чумы", "Чуму", "Порч Чумы");
RussianCardNames[CardNames.POVERTY] = new TranslatedCardName("Нищета", "Нищеты", "Нищету", "Порч Нищеты");
RussianCardNames[CardNames.WAR] = new TranslatedCardName("Война", "Войны", "Войну", "Войн");
RussianCardNames[CardNames.MISERABLE] = new TranslatedCardName("Несчастный", "Несчастных", "Состояние: Несчастный", "Несчастных");
RussianCardNames[CardNames.TWICE_MISERABLE] = new TranslatedCardName("Дважды Несчастный", "Дважды Несчастных", "Состояние: Дважды Несчастный", "Дважды Несчастных");
RussianCardNames[CardNames.ENVIOUS] = new TranslatedCardName("Завистливый", "Завистливого", "Состояние: Завистливый", "Завистливых");
RussianCardNames[CardNames.DELUDED] = new TranslatedCardName("Сбитый С Толку", "Сбитых С Толку", "Состояние: Сбитый С Толку", "Сбитых С Толку");
RussianCardNames[CardNames.LOST_IN_THE_WOODS] = new TranslatedCardName("Потерявшийся В Лесу", "Потерявшихся в Лесу", "Состояние: Потерявшийся В Лесу", "Потерявшихся В Лесу");
RussianCardNames[CardNames.BARD] = new TranslatedCardName("Бард", "Барда", "Барда", "Бардов");
RussianCardNames[CardNames.BLESSED_VILLAGE] = new TranslatedCardName("Благословенная Деревня", "Благословенных Деревни", "Благословенную Деревню", "Благословенных Деревень");
RussianCardNames[CardNames.CHANGELING] = new TranslatedCardName("Подменыш", "Подменыша", "Подменыша", "Подменышей");
RussianCardNames[CardNames.CEMETERY] = new TranslatedCardName("Погост", "Погоста", "Погост", "Погостов");
RussianCardNames[CardNames.COBBLER] = new TranslatedCardName("Башмачник", "Башмачника", "Башмачника", "Башмачников");
RussianCardNames[CardNames.CONCLAVE] = new TranslatedCardName("Конклав", "Конклава", "Конклав", "Конклавов");
RussianCardNames[CardNames.CRYPT] = new TranslatedCardName("Склеп", "Склепа", "Склеп", "Склепов");
RussianCardNames[CardNames.CURSED_VILLAGE] = new TranslatedCardName("Проклятая Деревня", "Проклятых Деревни", "Проклятую Деревню", "Проклятых Деревень");
RussianCardNames[CardNames.DEN_OF_SIN] = new TranslatedCardName("Логово Греха", "Логова Греха", "Логово Греха", "Логов Греха");
RussianCardNames[CardNames.DEVILS_WORKSHOP] = new TranslatedCardName("Дьявольская Мастерская", "Дьявольские Мастерские", "Дьявольскую Мастерскую", "Дьявольских Мастерских");
RussianCardNames[CardNames.DRUID] = new TranslatedCardName("Друид", "Друида", "Друида", "Друидов");
RussianCardNames[CardNames.EXORCIST] = new TranslatedCardName("Экзорцист", "Экзорциста", "Экзорциста", "Экзорцистов");
RussianCardNames[CardNames.FAITHFUL_HOUND] = new TranslatedCardName("Верный Пес", "Верных Пса", "Верного Пса", "Верных Псов");
RussianCardNames[CardNames.FOOL] = new TranslatedCardName("Глупец", "Глупца", "Глупца", "Глупцов");
RussianCardNames[CardNames.GHOST_TOWN] = new TranslatedCardName("Город-Призрак", "Города-Призрака", "Город-Призрак", "Городов-Призраков");
RussianCardNames[CardNames.GUARDIAN] = new TranslatedCardName("Страж", "Стража", "Стража", "Стражей");
RussianCardNames[CardNames.IDOL] = new TranslatedCardName("Идол", "Идола", "Идол", "Идолов");
RussianCardNames[CardNames.LEPRECHAUN] = new TranslatedCardName("Лепрекон", "Лепрекона", "Лепрекона", "Лепреконов");
RussianCardNames[CardNames.MONASTERY] = new TranslatedCardName("Монастырь", "Монастыря", "Монастырь", "Монастырей");
RussianCardNames[CardNames.NECROMANCER] = new TranslatedCardName("Некромант", "Некроманта", "Некроманта", "Некромантов");
RussianCardNames[CardNames.NIGHT_WATCHMAN] = new TranslatedCardName("Ночной Дозорный", "Ночных Дозорных", "Ночного Дозорного", "Ночных Дозорных");
RussianCardNames[CardNames.PIXIE] = new TranslatedCardName("Фея", "Феи", "Фею", "Фей");
RussianCardNames[CardNames.POOKA] = new TranslatedCardName("Пука", "Пуки", "Пуку", "Пук");
RussianCardNames[CardNames.RAIDER] = new TranslatedCardName("Налётчик", "Налётчика", "Налётчика", "Налётчиков");
RussianCardNames[CardNames.SACRED_GROVE] = new TranslatedCardName("Священная Роща", "Священных Рощи", "Священную Рощу", "Священных Рощ");
RussianCardNames[CardNames.SECRET_CAVE] = new TranslatedCardName("Тайный Грот", "Тайных Грота", "Тайный Грот", "Тайных Гротов");
RussianCardNames[CardNames.SHEPHERD] = new TranslatedCardName("Пастух", "Пастуха", "Пастуха", "Пастухов");
RussianCardNames[CardNames.SKULK] = new TranslatedCardName("Бездельник", "Бездельника", "Бездельника", "Бездельников");
RussianCardNames[CardNames.TORMENTOR] = new TranslatedCardName("Истязатель", "Истязателя", "Истязателя", "Истязателей");
RussianCardNames[CardNames.TRAGIC_HERO] = new TranslatedCardName("Трагический Герой", "Трагических Героя", "Трагического Героя", "Трагических Героев");
RussianCardNames[CardNames.TRACKER] = new TranslatedCardName("Лямочник", "Лямочника", "Лямочника", "Лямочников");
RussianCardNames[CardNames.VAMPIRE] = new TranslatedCardName("Вампир", "Вампира", "Вампира", "Вампиров");
RussianCardNames[CardNames.WEREWOLF] = new TranslatedCardName("Оборотень", "Оборотня", "Оборотня", "Оборотней");
RussianCardNames[CardNames.CURSED_GOLD] = new TranslatedCardName("Проклятое Золото", "Проклятых Золота", "Проклятое Золото", "Карт Проклятого Золота");
RussianCardNames[CardNames.GOAT] = new TranslatedCardName("Козел", "Козла", "Козла", "Козлов");
RussianCardNames[CardNames.HAUNTED_MIRROR] = new TranslatedCardName("Призрачное Зеркало", "Призрачных Зеркала", "Призрачное Зеркало", "Призрачных Зеркал");
RussianCardNames[CardNames.LUCKY_COIN] = new TranslatedCardName("Счастливая Монетка", "Счастливых Монетки", "Счастливую Монетку", "Счастливых Монеток");
RussianCardNames[CardNames.MAGIC_LAMP] = new TranslatedCardName("Волшебная Лампа", "Волшебных Лампы", "Волшебную Лампу", "Волшебных Ламп");
RussianCardNames[CardNames.PASTURE] = new TranslatedCardName("Пастбище", "Пастбища", "Пастбище", "Пастбищ");
RussianCardNames[CardNames.POUCH] = new TranslatedCardName("Мешочек", "Мешочка", "Мешочек", "Мешочков");
RussianCardNames[CardNames.BAT] = new TranslatedCardName("Нетопырь", "Нетопыря", "Нетопыря", "Нетопырей");
RussianCardNames[CardNames.GHOST] = new TranslatedCardName("Призрак", "Призрака", "Призрака", "Призраков");
RussianCardNames[CardNames.IMP] = new TranslatedCardName("Чертенок", "Чертенка", "Чертенка", "Чертят");
RussianCardNames[CardNames.WILL_O_WISP] = new TranslatedCardName("Блуждающий Огонек", "Блуждающих Огонька", "Блуждающий Огонек", "Блуждающих Огоньков");
RussianCardNames[CardNames.WISH] = new TranslatedCardName("Желание", "Желания", "Желание", "Желаний");
RussianCardNames[CardNames.ZOMBIE_APPRENTICE] = new TranslatedCardName("Зомби-Подмастерье", "Зомби-Подмастерья", "Зомби-Подмастерье", "Зомби-Подмастерьев");
RussianCardNames[CardNames.ZOMBIE_MASON] = new TranslatedCardName("Зомби-Каменотес", "Зомби-Каменотеса", "Зомби-Каменотеса", "Зомби-Каменотесов");
RussianCardNames[CardNames.ZOMBIE_SPY] = new TranslatedCardName("Зомби-Шпион", "Зомби-Шпиона", "Зомби-Шпиона", "Зомби-Шпионов");

RussianCardNames[CardNames.ACTING_TROUPE] = new TranslatedCardName("Актёрская Труппа", "Актёрские Труппы", "Актёрскую Труппу", "Актёрских Трупп");
RussianCardNames[CardNames.BORDER_GUARD] = new TranslatedCardName("Пограничник", "Пограничника", "Пограничника", "Пограничников");
RussianCardNames[CardNames.CARGO_SHIP] = new TranslatedCardName("Грузовой Корабль", "Грузовых Корабля", "Грузовой Корабль", "Грузовых Кораблей");
RussianCardNames[CardNames.DUCAT] = new TranslatedCardName("Дукат", "Дуката", "Дукат", "Дукатов");
RussianCardNames[CardNames.EXPERIMENT] = new TranslatedCardName("Эксперимент", "Эксперимента", "Эксперимент", "Экспериментов");
RussianCardNames[CardNames.FLAG_BEARER] = new TranslatedCardName("Знаменосец", "Знаменосеца", "Знаменосеца", "Знаменосцев");
RussianCardNames[CardNames.HIDEOUT] = new TranslatedCardName("Убежище", "Убежища", "Убежище", "Убежищ");
RussianCardNames[CardNames.INVENTOR] = new TranslatedCardName("Изобретатель", "Изобретателя", "Изобретателя", "Изобретателей");
RussianCardNames[CardNames.IMPROVE] = new TranslatedCardName("Усовершенствование", "Усовершенствования", "Усовершенствование", "Усовершенствований");
RussianCardNames[CardNames.LACKEYS] = new TranslatedCardName("Лакей", "Лакея", "Лакея", "Лакеев");
RussianCardNames[CardNames.MOUNTAIN_VILLAGE] = new TranslatedCardName("Горная Деревня", "Горных Деревни", "Горную Деревню", "Горных Деревень");
RussianCardNames[CardNames.PATRON] = new TranslatedCardName("Покровитель", "Покровителя", "Покровителя", "Покровителей");
RussianCardNames[CardNames.PRIEST] = new TranslatedCardName("Священник", "Священника", "Священника", "Священников");
RussianCardNames[CardNames.RESEARCH] = new TranslatedCardName("Исследование", "Исследования", "Исследование", "Исследований");
RussianCardNames[CardNames.SILK_MERCHANT] = new TranslatedCardName("Торговец Шёлком", "Торговца Шёлком", "Торговеца Шёлком", "Торговцев Шёлком");
RussianCardNames[CardNames.OLD_WITCH] = new TranslatedCardName("Старая Ведьма", "Старые Ведьмы", "Старую Ведьму", "Старых Ведьм");
RussianCardNames[CardNames.RECRUITER] = new TranslatedCardName("Вербовщик", "Вербовщика", "Вербовщика", "Вербовщиков");
RussianCardNames[CardNames.SCEPTER] = new TranslatedCardName("Скипетр", "Скипетра", "Скипетр", "Скипетров");
RussianCardNames[CardNames.SCHOLAR] = new TranslatedCardName("Учёный", "Учёных", "Учёного", "Учёных");
RussianCardNames[CardNames.SCULPTOR] = new TranslatedCardName("Скульптор", "Скульптора", "Скульптора", "Скульпторов");
RussianCardNames[CardNames.SEER] = new TranslatedCardName("Провидец", "Провидца", "Провидца", "Провидцев");
RussianCardNames[CardNames.SPICES] = new TranslatedCardName("Специи", "карты Специй", "Специи", "карт Специй");
RussianCardNames[CardNames.SWASHBUCKLER] = new TranslatedCardName("Головорез", "Головореза", "Головореза", "Головорезов");
RussianCardNames[CardNames.TREASURER] = new TranslatedCardName("Казначей", "Казначея", "Казначея", "Казначеев");
RussianCardNames[CardNames.VILLAIN] = new TranslatedCardName("Злодей", "Злодея", "Злодея", "Злодеев");
RussianCardNames[CardNames.FLAG] = new TranslatedCardName("Флаг", "Флага", "Флаг", "Флагов");
RussianCardNames[CardNames.HORN] = new TranslatedCardName("Рог", "Рога", "Рог", "Рогов");
RussianCardNames[CardNames.KEY] = new TranslatedCardName("Ключ", "Ключа", "Ключ", "Ключей");
RussianCardNames[CardNames.LANTERN] = new TranslatedCardName("Фонарь", "Фонаря", "Фонарь", "Фонарей");
RussianCardNames[CardNames.TREASURE_CHEST] = new TranslatedCardName("Сундук с Сокровищами", "Сундука с Сокровищами", "Сундук с Сокровищами", "Сундуков с Сокровищами");
RussianCardNames[CardNames.ACADEMY] = new TranslatedCardName("Академия", "Академии", "Академию", "Академий");
RussianCardNames[CardNames.BARRACKS] = new TranslatedCardName("Казарма", "Казармы", "Казарму", "Казарм");
RussianCardNames[CardNames.CANAL] = new TranslatedCardName("Канал", "Канала", "Канал", "Каналов");
RussianCardNames[CardNames.CAPITALISM] = new TranslatedCardName("Капитализм", "карты Капитализма", "Капитализм", "карт Капитализма");
RussianCardNames[CardNames.CATHEDRAL] = new TranslatedCardName("Собор", "Собора", "Собор", "Соборов");
RussianCardNames[CardNames.CITADEL] = new TranslatedCardName("Цитадель", "Цитадели", "Цитадель", "Цитаделей");
RussianCardNames[CardNames.CITY_GATE] = new TranslatedCardName("Городские Ворота", "карты Городских Ворот", "Городские Ворота", "Городских Ворот");
RussianCardNames[CardNames.CROP_ROTATION] = new TranslatedCardName("Севооборот", "Севооборота", "Севооборот", "Севооборотов");
RussianCardNames[CardNames.EXPLORATION] = new TranslatedCardName("Эксплорация", "Эксплорации", "Эксплорацию", "Эксплораций");
RussianCardNames[CardNames.FAIR] = new TranslatedCardName("Торжище", "Торжища", "Торжище", "Торжищ");
RussianCardNames[CardNames.FLEET] = new TranslatedCardName("Флот", "Флота", "Флот", "Флотов");
RussianCardNames[CardNames.GUILDHALL] = new TranslatedCardName("Ратуша", "Ратуши", "Ратушу", "Ратуш");
RussianCardNames[CardNames.INNOVATION] = new TranslatedCardName("Инновация", "Инновации", "Инновацию", "Инноваций");
RussianCardNames[CardNames.PAGEANT] = new TranslatedCardName("Карнавальное Шествие", "Карнавальных Шествия", "Карнавальное Шествие", "Карнавальных Шествий");
RussianCardNames[CardNames.PIAZZA] = new TranslatedCardName("Базарная Площадь", "Базарных Площади", "Базарную Площадь", "Базарных Площадей");
RussianCardNames[CardNames.ROAD_NETWORK] = new TranslatedCardName("Дорожная Сеть", "Дорожных Сети", "Дорожную Сеть", "Дорожных Сетей");
RussianCardNames[CardNames.SEWERS] = new TranslatedCardName("Канализация", "Канализации", "Канализацию", "Канализаций");
RussianCardNames[CardNames.SILOS] = new TranslatedCardName("Силос", "Силоса", "Силос", "Силосов");
RussianCardNames[CardNames.SINISTER_PLOT] = new TranslatedCardName("Зловещий Заговор", "Зловещих Заговора", "Зловещий Заговор", "Зловещих Заговоров");
RussianCardNames[CardNames.STAR_CHART] = new TranslatedCardName("Атлас Звёздного Неба", "Атласа Звёздного Неба", "Атлас Звёздного Неба", "Атласов Звёздного Неба");

RussianCardNames[CardNames.AVANTO] = new TranslatedCardName("Прорубь", "Проруби", "Прорубь", "Прорубей");
RussianCardNames[CardNames.BLACK_MARKET] = new TranslatedCardName("Чёрный Рынок", "Чёрных Рынка", "Чёрный Рынок", "Чёрных Рынков");
RussianCardNames[CardNames.ENVOY] = new TranslatedCardName("Посыльный", "Посыльных", "Посыльного", "Посыльных");
RussianCardNames[CardNames.GOVERNOR] = new TranslatedCardName("Губернатор", "Губернатора", "Губернатора", "Губернаторов");
RussianCardNames[CardNames.PRINCE] = new TranslatedCardName("Принц", "Принца", "Принца", "Принцев");
RussianCardNames[CardNames.SAUNA] = new TranslatedCardName("Сауна", "Сауны", "Сауну", "Саун");
RussianCardNames[CardNames.STASH] = new TranslatedCardName("Тайник", "Тайника", "Тайник", "Тайников");
RussianCardNames[CardNames.SUMMON] = new TranslatedCardName("Зов", "Зова", "Зов", "Зовов");
RussianCardNames[CardNames.WALLED_VILLAGE] = new TranslatedCardName("Укреплённая Деревня", "Укреплённых Деревни", "Укреплённую Деревню", "Укреплённых Деревень");
RussianCardNames[CardNames.BLACK_MARKET_PILE] = new TranslatedCardName("колода Чёрного Рынка", "колоды Чёрного Рынка", "колоду Чёрного Рынка", "колод Чёрного Рынка");
RussianCardNames[CardNames.DISMANTLE] = new TranslatedCardName("Разъединение", "Разъединения", "Разъединение", "Разъединений");
RussianCardNames[CardNames.CHURCH] = new TranslatedCardName("Церковь", "Церкви", "Церковь", "Церквей");
RussianCardNames[CardNames.CAPTAIN] = new TranslatedCardName("Капитан", "Капитана", "Капитана", "Капитанов");

RussianCardNames[CardNames.BLACK_CAT] = new TranslatedCardName("Чёрный Кот", "Чёрных Кота", "Чёрного Кота", "Чёрных Котов");
RussianCardNames[CardNames.SLEIGH] = new TranslatedCardName("Сани", "Саней", "Сани", "Саней");
RussianCardNames[CardNames.SUPPLIES] = new TranslatedCardName("Снабжение", "Снабжения", "Снабжение", "Снабжений");
RussianCardNames[CardNames.CAMEL_TRAIN] = new TranslatedCardName("Верблюжий Обоз", "Верблюжих Обоза", "Верблюжий Обоз", "Верблюжих Обозов");
RussianCardNames[CardNames.GOATHERD] = new TranslatedCardName("Козопас", "Козопаса", "Козопаса", "Козопасов");
RussianCardNames[CardNames.SCRAP] = new TranslatedCardName("Металлолом", "Металлолома", "Металлолом", "Металлоломов");
RussianCardNames[CardNames.SHEEPDOG] = new TranslatedCardName("Английская Овчарка", "Английских Овчарки", "Английскую Овчарку", "Английских Овчарок");
RussianCardNames[CardNames.SNOWY_VILLAGE] = new TranslatedCardName("Заснеженная Деревня", "Заснеженных Деревни", "Заснеженную Деревню", "Заснеженных Деревень");
RussianCardNames[CardNames.STOCKPILE] = new TranslatedCardName("Неприкосновенный Запас", "Неприкосновенных Запаса", "Неприкосновенный Запас", "Неприкосновенных Запасов");
RussianCardNames[CardNames.BOUNTY_HUNTER] = new TranslatedCardName("Охотник за Головами", "Охотника за Головами", "Охотника за Головами", "Охотников за Головами");
RussianCardNames[CardNames.CARDINAL] = new TranslatedCardName("Кардинал", "Кардинала", "Кардинала", "Кардиналов");
RussianCardNames[CardNames.CAVALRY] = new TranslatedCardName("Кавалерия", "Кавалерии", "Кавалерию", "Кавалерий");
RussianCardNames[CardNames.GROOM] = new TranslatedCardName("Конюх", "Конюха", "Конюха", "Конюхов");
RussianCardNames[CardNames.HOSTELRY] = new TranslatedCardName("Постоялый Двор", "Постоялых Двора", "Постоялый Двор", "Постоялых Дворов");
RussianCardNames[CardNames.VILLAGE_GREEN] = new TranslatedCardName("Лужайка", "Лужайки", "Лужайку", "Лужаек");
RussianCardNames[CardNames.BARGE] = new TranslatedCardName("Барка", "Барки", "Барку", "Барок");
RussianCardNames[CardNames.COVEN] = new TranslatedCardName("Шабаш", "Шабаша", "Шабаш", "Шабашей");
RussianCardNames[CardNames.DISPLACE] = new TranslatedCardName("Вытеснение", "Вытеснения", "Вытеснение", "Вытеснений");
RussianCardNames[CardNames.FALCONER] = new TranslatedCardName("Сокольник", "Сокольника", "Сокольника", "Сокольников");
RussianCardNames[CardNames.FISHERMAN] = new TranslatedCardName("Рыбак", "Рыбака", "Рыбака", "Рыбаков");
RussianCardNames[CardNames.GATEKEEPER] = new TranslatedCardName("Привратник", "Привратника", "Привратника", "Привратников");
RussianCardNames[CardNames.HUNTING_LODGE] = new TranslatedCardName("Охотничий Домик", "Охотничих Домика", "Охотничий Домик", "Охотничих Домиков");
RussianCardNames[CardNames.KILN] = new TranslatedCardName("Печь для Обжига", "Печи для Обжига", "Печь для Обжига", "Печей для Обжига");
RussianCardNames[CardNames.LIVERY] = new TranslatedCardName("Извозчичий Двор", "Извозчичих Двора", "Извозчичий Двор", "Извозчичих Дворов");
RussianCardNames[CardNames.MASTERMIND] = new TranslatedCardName("Вдохновитель", "Вдохновителя", "Вдохновителя", "Вдохновителей");
RussianCardNames[CardNames.PADDOCK] = new TranslatedCardName("Загон", "Загона", "Загон", "Загонов");
RussianCardNames[CardNames.SANCTUARY] = new TranslatedCardName("Святилище", "Святилища", "Святилище", "Святилищ");
RussianCardNames[CardNames.DESTRIER] = new TranslatedCardName("Боевой Конь", "Боевых Коня", "Боевого Коня", "Боевых Коней");
RussianCardNames[CardNames.WAYFARER] = new TranslatedCardName("Путник", "Путника", "Путника", "Путников");
RussianCardNames[CardNames.ANIMAL_FAIR] = new TranslatedCardName("Ярмарка Животных", "Ярмарки Животных", "Ярмарку Животных", "Ярмарок Животных");
RussianCardNames[CardNames.HORSE] = new TranslatedCardName("Лошадь", "Лошади", "Лошадь", "Лошадей");
RussianCardNames[CardNames.WAY_OF_THE_BUTTERFLY] = new TranslatedCardName("Путь Бабочки");
RussianCardNames[CardNames.WAY_OF_THE_CAMEL] = new TranslatedCardName("Путь Верблюда");
RussianCardNames[CardNames.WAY_OF_THE_CHAMELEON] = new TranslatedCardName("Путь Хамелеона");
RussianCardNames[CardNames.WAY_OF_THE_FROG] = new TranslatedCardName("Путь Лягушки");
RussianCardNames[CardNames.WAY_OF_THE_GOAT] = new TranslatedCardName("Путь Козла");
RussianCardNames[CardNames.WAY_OF_THE_HORSE] = new TranslatedCardName("Путь Лошади");
RussianCardNames[CardNames.WAY_OF_THE_MOLE] = new TranslatedCardName("Путь Крота");
RussianCardNames[CardNames.WAY_OF_THE_MONKEY] = new TranslatedCardName("Путь Обезьяны");
RussianCardNames[CardNames.WAY_OF_THE_MOUSE] = new TranslatedCardName("Путь Мыши");
RussianCardNames[CardNames.WAY_OF_THE_MULE] = new TranslatedCardName("Путь Мула");
RussianCardNames[CardNames.WAY_OF_THE_OTTER] = new TranslatedCardName("Путь Выдры");
RussianCardNames[CardNames.WAY_OF_THE_OWL] = new TranslatedCardName("Путь Совы");
RussianCardNames[CardNames.WAY_OF_THE_OX] = new TranslatedCardName("Путь Быка");
RussianCardNames[CardNames.WAY_OF_THE_PIG] = new TranslatedCardName("Путь Свиньи");
RussianCardNames[CardNames.WAY_OF_THE_RAT] = new TranslatedCardName("Путь Крысы");
RussianCardNames[CardNames.WAY_OF_THE_SEAL] = new TranslatedCardName("Путь Тюленя");
RussianCardNames[CardNames.WAY_OF_THE_SHEEP] = new TranslatedCardName("Путь Овцы");
RussianCardNames[CardNames.WAY_OF_THE_SQUIRREL] = new TranslatedCardName("Путь Белки");
RussianCardNames[CardNames.WAY_OF_THE_TURTLE] = new TranslatedCardName("Путь Черепахи");
RussianCardNames[CardNames.WAY_OF_THE_WORM] = new TranslatedCardName("Путь Червя");
RussianCardNames[CardNames.DELAY] = new TranslatedCardName("Задержка");
RussianCardNames[CardNames.DESPERATION] = new TranslatedCardName("Безрассудство");
RussianCardNames[CardNames.GAMBLE] = new TranslatedCardName("Авантюра");
RussianCardNames[CardNames.PURSUE] = new TranslatedCardName("Преследование");
RussianCardNames[CardNames.RIDE] = new TranslatedCardName("Поездка");
RussianCardNames[CardNames.TOIL] = new TranslatedCardName("Тяжкий Труд");
RussianCardNames[CardNames.ENHANCE] = new TranslatedCardName("Усиление");
RussianCardNames[CardNames.MARCH] = new TranslatedCardName("Марш");
RussianCardNames[CardNames.TRANSPORT] = new TranslatedCardName("Перевозка");
RussianCardNames[CardNames.BANISH] = new TranslatedCardName("Высылка");
RussianCardNames[CardNames.BARGAIN] = new TranslatedCardName("Торг");
RussianCardNames[CardNames.INVEST] = new TranslatedCardName("Инвестиция");
RussianCardNames[CardNames.SEIZE_THE_DAY] = new TranslatedCardName("Лови Момент");
RussianCardNames[CardNames.COMMERCE] = new TranslatedCardName("Коммерция");
RussianCardNames[CardNames.DEMAND] = new TranslatedCardName("Требование");
RussianCardNames[CardNames.STAMPEDE] = new TranslatedCardName("Паническое Бегство");
RussianCardNames[CardNames.REAP] = new TranslatedCardName("Жатва");
RussianCardNames[CardNames.ENCLAVE] = new TranslatedCardName("Анклав");
RussianCardNames[CardNames.ALLIANCE] = new TranslatedCardName("Союз");
RussianCardNames[CardNames.POPULATE] = new TranslatedCardName("Заселение");

RussianCardNames[CardNames.PRIZE_PILE] = new TranslatedCardName("Стопка Трофеев", "Стопки Трофеев", "Стопку Трофеев");

RussianCardNames[CardNames.CARD_OF_THE_BUTTERFLY] = "";
RussianCardNames[CardNames.CARD_OF_THE_CAMEL] = "";
RussianCardNames[CardNames.CARD_OF_THE_CHAMELEON] = "";
RussianCardNames[CardNames.CARD_OF_THE_FROG] = "";
RussianCardNames[CardNames.CARD_OF_THE_GOAT] = "";
RussianCardNames[CardNames.CARD_OF_THE_HORSE] = "";
RussianCardNames[CardNames.CARD_OF_THE_MOLE] = "";
RussianCardNames[CardNames.CARD_OF_THE_MONKEY] = "";
RussianCardNames[CardNames.CARD_OF_THE_MOUSE] = "";
RussianCardNames[CardNames.CARD_OF_THE_MULE] = "";
RussianCardNames[CardNames.CARD_OF_THE_OTTER] = "";
RussianCardNames[CardNames.CARD_OF_THE_OWL] = "";
RussianCardNames[CardNames.CARD_OF_THE_OX] = "";
RussianCardNames[CardNames.CARD_OF_THE_PIG] = "";
RussianCardNames[CardNames.CARD_OF_THE_RAT] = "";
RussianCardNames[CardNames.CARD_OF_THE_SEAL] = "";
RussianCardNames[CardNames.CARD_OF_THE_SHEEP] = "";
RussianCardNames[CardNames.CARD_OF_THE_SQUIRREL] = "";
RussianCardNames[CardNames.CARD_OF_THE_TURTLE] = "";
RussianCardNames[CardNames.CARD_OF_THE_WORM] = "";

RussianCardNames[CardNames.TOWN] = new TranslatedCardName("Городок", "Городка", "Городок", "Городков");
RussianCardNames[CardNames.MODIFY] = new TranslatedCardName("Модификация", "Модификации", "Модификацию", "Модификаций");
RussianCardNames[CardNames.MERCHANT_CAMP] = new TranslatedCardName("Торговый Лагерь", "Торговых Лагеря", "Торговый Лагерь", "Торговых Лагерей");
RussianCardNames[CardNames.SWAP] = new TranslatedCardName("Обмен", "Обмена", "Обмен", "Обменов");
RussianCardNames[CardNames.SKIRMISHER] = new TranslatedCardName("Застрельщик", "Застрельщика", "Застрельщика", "Застрельщиков");
RussianCardNames[CardNames.HIGHWAYMAN] = new TranslatedCardName("Разбойник", "Разбойника", "Разбойника", "Разбойников");
RussianCardNames[CardNames.BARBARIAN] = new TranslatedCardName("Варвар", "Варвара", "Варвара", "Варваров");
RussianCardNames[CardNames.INNKEEPER] = new TranslatedCardName("Трактирщик", "Трактирщика", "Трактирщика", "Трактирщиков");
RussianCardNames[CardNames.CAPITAL_CITY] = new TranslatedCardName("Столица", "Столицы", "Столицу", "Столиц");
RussianCardNames[CardNames.SPECIALIST] = new TranslatedCardName("Специалист", "Специалиста", "Специалиста", "Специалистов");
RussianCardNames[CardNames.CARPENTER] = new TranslatedCardName("Плотник", "Плотника", "Плотника", "Плотников");
RussianCardNames[CardNames.ROYAL_GALLEY] = new TranslatedCardName("Королевская Галера", "Королевских Галеры", "Королевскую Галеру", "Королевских Галер");
RussianCardNames[CardNames.MARQUIS] = new TranslatedCardName("Маркиз", "Маркиза", "Маркиза", "Маркизов");
RussianCardNames[CardNames.SENTINEL] = new TranslatedCardName("Охранник", "Охранника", "Охранника", "Охранников");
RussianCardNames[CardNames.COURIER] = new TranslatedCardName("Посыльный", "Посыльных", "Посыльного", "Посыльных");
RussianCardNames[CardNames.GALLERIA] = new TranslatedCardName("Галерея", "Галереи", "Галерею", "Галерей");
RussianCardNames[CardNames.HUNTER] = new TranslatedCardName("Охотник", "Охотника", "Охотника", "Охотников");

RussianCardNames[CardNames.SYCOPHANT] = new TranslatedCardName("Льстец", "Льстеца", "Льстеца", "Льстецов");
RussianCardNames[CardNames.UNDERLING] = new TranslatedCardName("Мелкая Сошка", "Мелких Сошки", "Мелкую Сошку", "Мелких Сошек");
RussianCardNames[CardNames.IMPORTER] = new TranslatedCardName("Импортёр", "Импортёра", "Импортёра", "Импортёров");
RussianCardNames[CardNames.CONTRACT] = new TranslatedCardName("Договор", "Договора", "Договор", "Договоров");
RussianCardNames[CardNames.EMISSARY] = new TranslatedCardName("Эмиссар", "Эмиссара", "Эмиссара", "Эмиссаров");
RussianCardNames[CardNames.BAUBLE] = new TranslatedCardName("Безделушка", "Безделушки", "Безделушку", "Безделушек");
RussianCardNames[CardNames.BROKER] = new TranslatedCardName("Маклер", "Маклера", "Маклера", "Маклеров");
RussianCardNames[CardNames.GUILDMASTER] = new TranslatedCardName("Гильдмастер", "Гильдмастера", "Гильдмастера", "Гильдмастеров");

RussianCardNames[CardNames.ARCHITECTS_GUILD] = new TranslatedCardName("Гильдия Архитекторов");
RussianCardNames[CardNames.ORDER_OF_ASTROLOGERS] = new TranslatedCardName("Орден Астрологов");
RussianCardNames[CardNames.LEAGUE_OF_BANKERS] = new TranslatedCardName("Лига Банкиров");
RussianCardNames[CardNames.MOUNTAIN_FOLK] = new TranslatedCardName("Горный Народ");
RussianCardNames[CardNames.WOODWORKERS_GUILD] = new TranslatedCardName("Гильдия Плотников");
RussianCardNames[CardNames.PEACEFUL_CULT] = new TranslatedCardName("Мирный Культ");
RussianCardNames[CardNames.CRAFTERS_GUILD] = new TranslatedCardName("Гильдия Ремесленников");
RussianCardNames[CardNames.CAVE_DWELLERS] = new TranslatedCardName("Пещерные Жители");
RussianCardNames[CardNames.PLATEAU_SHEPHERDS] = new TranslatedCardName("Пастухи с Плато");
RussianCardNames[CardNames.DESERT_GUIDES] = new TranslatedCardName("Проводники Пустыни");
RussianCardNames[CardNames.FAMILY_OF_INVENTORS] = new TranslatedCardName("Семья Изобретателей");
RussianCardNames[CardNames.ISLAND_FOLK] = new TranslatedCardName("Островной Народ");
RussianCardNames[CardNames.ORDER_OF_MASONS] = new TranslatedCardName("Орден Каменщиков");
RussianCardNames[CardNames.BAND_OF_NOMADS] = new TranslatedCardName("Группа Кочевников");
RussianCardNames[CardNames.GANG_OF_PICKPOCKETS] = new TranslatedCardName("Банда Карманников");
RussianCardNames[CardNames.MARKET_TOWNS] = new TranslatedCardName("Торговые Города");
RussianCardNames[CardNames.FOREST_DWELLERS] = new TranslatedCardName("Лесные Жители");
RussianCardNames[CardNames.FELLOWSHIP_OF_SCRIBES] = new TranslatedCardName("Писчее Братство");
RussianCardNames[CardNames.LEAGUE_OF_SHOPKEEPERS] = new TranslatedCardName("Лига Владельцев Магазинов");
RussianCardNames[CardNames.COASTAL_HAVEN] = new TranslatedCardName("Береговая Гавань");
RussianCardNames[CardNames.CITY_STATE] = new TranslatedCardName("Город-Государство");
RussianCardNames[CardNames.TRAPPERS_LODGE] = new TranslatedCardName("Охотничий Домик");
RussianCardNames[CardNames.CIRCLE_OF_WITCHES] = new TranslatedCardName("Ковен Ведьм");

RussianCardNames[CardNames.AUGUR_PILE] = new TranslatedCardName("Авгуры");
RussianCardNames[CardNames.HERB_GATHERER] = new TranslatedCardName("Собиратель Трав", "Собирателя Трав", "Собирателя Трав", "Собирателей Трав");
RussianCardNames[CardNames.ACOLYTE] = new TranslatedCardName("Послушник", "Послушника", "Послушника", "Послушников");
RussianCardNames[CardNames.SORCERESS] = new TranslatedCardName("Колдунья", "Колдуньи", "Колдунью", "Колдуний");
RussianCardNames[CardNames.SIBYL] = new TranslatedCardName("Сивилла", "Сивиллы", "Сивиллу", "Сивилл");
RussianCardNames[CardNames.CLASH_PILE] = new TranslatedCardName("Столкновения");
RussianCardNames[CardNames.BATTLE_PLAN] = new TranslatedCardName("План Битвы", "Плана Битвы", "План Битвы", "Планов Битвы");
RussianCardNames[CardNames.ARCHER] = new TranslatedCardName("Лучник", "Лучника", "Лучника", "Лучников");
RussianCardNames[CardNames.WARLORD] = new TranslatedCardName("Военачальник", "Военачальника", "Военачальника", "Военачальников");
RussianCardNames[CardNames.TERRITORY] = new TranslatedCardName("Территория", "Территории", "Территорию", "Территорий");
RussianCardNames[CardNames.FORT_PILE] = new TranslatedCardName("Форты");
RussianCardNames[CardNames.TENT] = new TranslatedCardName("Палатка", "Палатки", "Палатку", "Палаток");
RussianCardNames[CardNames.GARRISON] = new TranslatedCardName("Гарнизон", "Гарнизона", "Гарнизон", "Гарнизонов");
RussianCardNames[CardNames.HILL_FORT] = new TranslatedCardName("Форт на Холме", "Форта на Холме", "Форт на Холме", "Фортов на Холме");
RussianCardNames[CardNames.STRONGHOLD] = new TranslatedCardName("Оплот", "Оплота", "Оплот", "Оплотов");
RussianCardNames[CardNames.ODYSSEY_PILE] = new TranslatedCardName("Одиссеи");
RussianCardNames[CardNames.OLD_MAP] = new TranslatedCardName("Старая Карта", "Старые Карты", "Старую Карту", "Старых Карт");
RussianCardNames[CardNames.VOYAGE] = new TranslatedCardName("Путешествие", "Путешествия", "Путешествие", "Путешествий");
RussianCardNames[CardNames.SUNKEN_TREASURE] = new TranslatedCardName("Затонувшее Сокровище", "Затонувших Сокровища", "Затонувшее Сокровище", "Затонувших Сокровищ");
RussianCardNames[CardNames.DISTANT_SHORE] = new TranslatedCardName("Дальний Берег", "Дальних Берега", "Дальний Берег", "Дальних Берегов");
RussianCardNames[CardNames.TOWNSFOLK_PILE] = new TranslatedCardName("Горожане");
RussianCardNames[CardNames.TOWN_CRIER] = new TranslatedCardName("Городской Плакальщик", "Городских Плакальщика", "Городского Плакальщика", "Городских Плакальщиков");
RussianCardNames[CardNames.BLACKSMITH] = new TranslatedCardName("Кузнец", "Кузнеца", "Кузнеца", "Кузнецов");
RussianCardNames[CardNames.MILLER] = new TranslatedCardName("Мельник", "Мельника", "Мельника", "Мельников");
RussianCardNames[CardNames.ELDER] = new TranslatedCardName("Старейшина", "Старейшины", "Старейшину", "Старейшин");
RussianCardNames[CardNames.WIZARD_PILE] = new TranslatedCardName("Волшебники");
RussianCardNames[CardNames.STUDENT] = new TranslatedCardName("Студент", "Студента", "Студента", "Студентов");
RussianCardNames[CardNames.CONJURER] = new TranslatedCardName("Фокусник", "Фокусника", "Фокусника", "Фокуусников");
RussianCardNames[CardNames.SORCERER] = new TranslatedCardName("Колдун", "Колдуна", "Колдуна", "Колдунов");
RussianCardNames[CardNames.LICH] = new TranslatedCardName("Лич", "Лича", "Лича", "Личей");

RussianCardNames[CardNames.MONKEY] = new TranslatedCardName("Обезьянка", "Обезьянки", "Обезьянку", "Обезьянок");
RussianCardNames[CardNames.BLOCKADE] = new TranslatedCardName("Блокада", "Блокады", "Блокаду", "Блокад");
RussianCardNames[CardNames.SAILOR] = new TranslatedCardName("Моряк", "Моряка", "Моряка", "Моряков");
RussianCardNames[CardNames.PIRATE] = new TranslatedCardName("Пират", "Пирата", "Пирата", "Пиратов");
RussianCardNames[CardNames.CORSAIR] = new TranslatedCardName("Корсар", "Корсара", "Корсара", "Корсаров");
RussianCardNames[CardNames.SEA_CHART] = new TranslatedCardName("Морская Карта", "Морских Карты", "Морскую Карту", "Морских Карт");
RussianCardNames[CardNames.ASTROLABE] = new TranslatedCardName("Астролябия", "Астролябии", "Астролябию", "Астролябий");
RussianCardNames[CardNames.TIDE_POOLS] = new TranslatedCardName("Заводь", "Заводи", "Заводь", "Заводей");
RussianCardNames[CardNames.SEA_WITCH] = new TranslatedCardName("Морская Карга", "Морских Карги", "Морскую Каргу", "Морских Карг");

RussianCardNames[CardNames.ANVIL] = new TranslatedCardName("Наковальня", "Наковальни", "Наковальню", "Наковален");
RussianCardNames[CardNames.CLERK] = new TranslatedCardName("Клерк", "Клерка", "Клерка", "Клерков");
RussianCardNames[CardNames.INVESTMENT] = new TranslatedCardName("Вложение", "Вложения", "Вложение", "Вложений");
RussianCardNames[CardNames.TIARA] = new TranslatedCardName("Тиара", "Тиары", "Тиару", "Тиар");
RussianCardNames[CardNames.CHARLATAN] = new TranslatedCardName("Обманщик", "Обманщика", "Обманщика", "Обманщиков");
RussianCardNames[CardNames.COLLECTION] = new TranslatedCardName("Коллекция", "Коллекции", "Коллекцию", "Коллекций");
RussianCardNames[CardNames.CRYSTAL_BALL] = new TranslatedCardName("Хрустальный Шар", "Хрустальных Шара", "Хрустальный Шар", "Хрустальных Шаров");
RussianCardNames[CardNames.MAGNATE] = new TranslatedCardName("Магнат", "Магната", "Магната", "Магнатов");
RussianCardNames[CardNames.WAR_CHEST] = new TranslatedCardName("Военный Сундук", "Военных Сундука", "Военный Сундук", "Военных Сундуков");

RussianCardNames[CardNames.GUARD_DOG] = new TranslatedCardName("Сторожевая Собака", "Сторожевых Собаки", "Сторожевую Собаку", "Сторожевых Собак");
RussianCardNames[CardNames.BERSERKER] = new TranslatedCardName("Берсерк", "Берсерка", "Берсерка", "Берсерков");
RussianCardNames[CardNames.NOMADS] = new TranslatedCardName("Странник", "Странника", "Странника", "Странников");
RussianCardNames[CardNames.TRAIL] = new TranslatedCardName("Тропа", "Тропы", "Тропу", "Троп");
RussianCardNames[CardNames.WEAVER] = new TranslatedCardName("Ткач", "Ткача", "Ткача", "Ткачей");
RussianCardNames[CardNames.SOUK] = new TranslatedCardName("Барахолка", "Барахолки", "Барахолку", "Барахолок");
RussianCardNames[CardNames.CAULDRON] = new TranslatedCardName("Котёл", "Котла", "Котлёл", "Котлов");
RussianCardNames[CardNames.WHEELWRIGHT] = new TranslatedCardName("Колёсный Мастер", "Колёсных Мастера", "Колёсного Мастера", "Колёсных Мастеров");
RussianCardNames[CardNames.WITCHS_HUT] = new TranslatedCardName("Хижина Ведьмы", "Хижины Ведьмы", "Хижину Ведьмы", "Хижин Ведьмы");

RussianCardNames[CardNames.CAGE] = new TranslatedCardName("Клетка", "Клетки", "Клетку", "Клеток");
RussianCardNames[CardNames.GROTTO] = new TranslatedCardName("Грот", "Грота", "Грот", "Гротов");
RussianCardNames[CardNames.JEWELLED_EGG] = new TranslatedCardName("Драгоценное Яйцо", "Драгоценных Яйца", "Драгоценное Яйцо", "Драгоценных Яиц");
RussianCardNames[CardNames.SEARCH] = new TranslatedCardName("Поиск", "Поиска", "Поиск", "Поисков");
RussianCardNames[CardNames.SHAMAN] = new TranslatedCardName("Шаман", "Шамана", "Шамана", "Шаманов");
RussianCardNames[CardNames.SECLUDED_SHRINE] = new TranslatedCardName("Уединённый Храм", "Уединённых Храма", "Уединённый Храм", "Уединённых Храмов");
RussianCardNames[CardNames.SIREN] = new TranslatedCardName("Сирена", "Сирены", "Сирену", "Сирен");
RussianCardNames[CardNames.STOWAWAY] = new TranslatedCardName("Безбилетник", "Безбилетника", "Безбилетника", "Безбилетников");
RussianCardNames[CardNames.TASKMASTER] = new TranslatedCardName("Бригадир", "Бригадира", "Бригадира", "Бригадиров");
RussianCardNames[CardNames.ABUNDANCE] = new TranslatedCardName("Избыток", "Избытка", "Избыток", "Избытоков");
RussianCardNames[CardNames.CABIN_BOY] = new TranslatedCardName("Юнга", "Юнги", "Юнгу", "Юнг");
RussianCardNames[CardNames.CRUCIBLE] = new TranslatedCardName("Горнило", "Горнила", "Горнило", "Горнил");
RussianCardNames[CardNames.FLAGSHIP] = new TranslatedCardName("Флагман", "Флагмана", "Флагман", "Флагманов");
RussianCardNames[CardNames.FORTUNE_HUNTER] = new TranslatedCardName("Охотник за Удачей", "Охотника за Удачей", "Охотника за Удачей", "Охотников за Удачей");
RussianCardNames[CardNames.GONDOLA] = new TranslatedCardName("Гондола", "Гондолы", "Гондолу", "Гондол");
RussianCardNames[CardNames.HARBOR_VILLAGE] = new TranslatedCardName("Портовая Деревня", "Портовых Деревни", "Портовую Деревню", "Портовых Деревень");
RussianCardNames[CardNames.LANDING_PARTY] = new TranslatedCardName("Десантный Отряд", "Десантных Отряда", "Десантный Отряд", "Десантных Отрядов");
RussianCardNames[CardNames.MAPMAKER] = new TranslatedCardName("Создатель Карт", "Создателя Карт", "Создателя Карт", "Создателей Карт");
RussianCardNames[CardNames.MAROON] = new TranslatedCardName("Изгой", "Изгоя", "Изгоя", "Изгоев");
RussianCardNames[CardNames.ROPE] = new TranslatedCardName("Верёвка", "Верёвки", "Верёвку", "Верёвок");
RussianCardNames[CardNames.SWAMP_SHACKS] = new TranslatedCardName("Болотная Лачуга", "Болотных Лачуги", "Болотную Лачугу", "Болотных Лачуг");
RussianCardNames[CardNames.TOOLS] = new TranslatedCardName("Инструмент", "Инструмента", "Инструмент", "Инструментов");
RussianCardNames[CardNames.BURIED_TREASURE] = new TranslatedCardName("Зарытое Сокровище", "Зарытых Сокровища", "Зарытое Сокровище", "Зарытых Сокровищ");
RussianCardNames[CardNames.CREW] = new TranslatedCardName("Судовая Команда", "Судовых Команды", "Судовую Команду", "Судовых Команд");
RussianCardNames[CardNames.CUTTHROAT] = new TranslatedCardName("Убийца", "Убийцы", "Убийцу", "убийц");
RussianCardNames[CardNames.ENLARGE] = new TranslatedCardName("Увеличение", "Увеличения", "Увеличение", "Увеличений");
RussianCardNames[CardNames.FIGURINE] = new TranslatedCardName("Статуэтка", "Статуэтки", "Статуэтку", "Статуэток");
RussianCardNames[CardNames.FIRST_MATE] = new TranslatedCardName("Первый Помощник", "Первых Помощника", "Первого Помощника", "Первых Помощников  ");
RussianCardNames[CardNames.FRIGATE] = new TranslatedCardName("Фрегат", "Фрегата", "Фрегат", "Фрегатов");
RussianCardNames[CardNames.LONGSHIP] = new TranslatedCardName("Драккар", "Драккара", "Драккар", "Драккаров");
RussianCardNames[CardNames.MINING_ROAD] = new TranslatedCardName("Горная Дорога", "Горные Дороги", "Горную Дорогу", "Горных Дорог");
RussianCardNames[CardNames.PENDANT] = new TranslatedCardName("Кулон", "Кулона", "Кулон", "Кулонов");
RussianCardNames[CardNames.PICKAXE] = new TranslatedCardName("Кирка", "Кирки", "Кирку", "Кирок");
RussianCardNames[CardNames.PILGRIM] = new TranslatedCardName("Пилигрим", "Пилигрима", "Пилигрима", "Пилигримов");
RussianCardNames[CardNames.QUARTERMASTER] = new TranslatedCardName("Интендант", "Интенданта", "Интенданта", "Интендантов");
RussianCardNames[CardNames.SILVER_MINE] = new TranslatedCardName("Серебряный Рудник", "Серебряных Рудника", "Серебряный Рудник", "Серебряных Рудников");
RussianCardNames[CardNames.TRICKSTER] = new TranslatedCardName("Ловкач", "Ловкача", "Ловкача", "Ловкачей");
RussianCardNames[CardNames.WEALTHY_VILLAGE] = new TranslatedCardName("Богатая Деревня", "Богатых Деревни", "Богатую Деревню", "Богатых Деревень");
RussianCardNames[CardNames.SACK_OF_LOOT] = new TranslatedCardName("Мешок с Добычей", "Мешка с Добычей", "Мешок с Добычей", "Мешков с Добычей");
RussianCardNames[CardNames.KINGS_CACHE] = new TranslatedCardName("Королевский Тайник", "Королевских Тайника", "Королевский Тайник", "Королевских Тайников");
RussianCardNames[CardNames.BURY] = new TranslatedCardName("Погребение", "Погребения", "Погребение", "Погребений");
RussianCardNames[CardNames.AVOID] = new TranslatedCardName("Уклонение", "Уклонения", "Уклонение", "Уклонений");
RussianCardNames[CardNames.DELIVER] = new TranslatedCardName("Доставка", "Доставки", "Доставку", "Доставок");
RussianCardNames[CardNames.PERIL] = new TranslatedCardName("Опасность", "Опасности", "Опасность", "Опасностей");
RussianCardNames[CardNames.RUSH] = new TranslatedCardName("Спешка", "Спешки", "Спешку", "Спешек");
RussianCardNames[CardNames.FORAY] = new TranslatedCardName("Налёт", "Налёта", "Налёт", "Налётов");
RussianCardNames[CardNames.LAUNCH] = new TranslatedCardName("Баркас", "Баркаса", "Баркас", "Баркасов");
RussianCardNames[CardNames.MIRROR] = new TranslatedCardName("Зеркало", "Зеркала", "Зеркало", "Зеркал");
RussianCardNames[CardNames.PREPARE] = new TranslatedCardName("Подготовка", "Подготовки", "Подготовку", "Подготовок");
RussianCardNames[CardNames.SCROUNGE] = new TranslatedCardName("Попрошайничество", "Попрошайничества", "Попрошайничество", "Попрошайничеств");
RussianCardNames[CardNames.JOURNEY] = new TranslatedCardName("Вояж", "Вояжа", "Вояж", "Вояжей");
RussianCardNames[CardNames.MAELSTROM] = new TranslatedCardName("Водоворот", "Водоворота", "Водоворот", "Водоворотов");
RussianCardNames[CardNames.LOOTING] = new TranslatedCardName("Мародёрство", "Мародёрства", "Мародёрство", "Мародёрств");
RussianCardNames[CardNames.INVASION] = new TranslatedCardName("Вторжение", "Вторжения", "Вторжение", "Вторжений");
RussianCardNames[CardNames.PROSPER] = new TranslatedCardName("Процветание", "Процветания", "Процветание", "Процветаний");
RussianCardNames[CardNames.LOOT_PILE] = new TranslatedCardName("Стопка Грабленого");
RussianCardNames[CardNames.AMPHORA] = new TranslatedCardName("Амфора", "Амфоры", "Амфору", "Амфор");
RussianCardNames[CardNames.DOUBLOONS] = new TranslatedCardName("Дублон", "Дублона", "Дублон", "Дублонов");
RussianCardNames[CardNames.ENDLESS_CHALICE] = new TranslatedCardName("Бесконечная Чаша", "Бесконечных Чаши", "Бесконечную Чашу", "Бесконечных Чаш");
RussianCardNames[CardNames.FIGUREHEAD] = new TranslatedCardName("Ростр", "Ростра", "Ростр", "Ростров");
RussianCardNames[CardNames.HAMMER] = new TranslatedCardName("Молот", "Молота", "Молот", "Молотов");
RussianCardNames[CardNames.INSIGNIA] = new TranslatedCardName("Знак Отличия", "Знака Отличия", "Знак Отличия", "Знаков Отличия");
RussianCardNames[CardNames.JEWELS] = new TranslatedCardName("Драгоценный Камень", "Драгоценных Камня", "Драгоценный Камень", "Драгоценных Камней");
RussianCardNames[CardNames.ORB] = new TranslatedCardName("Сфера", "Сферы", "Сферу", "Сфер");
RussianCardNames[CardNames.PRIZE_GOAT] = new TranslatedCardName("Призовая Коза", "Призовых Козы", "Призовую Козу", "Призовых Коз");
RussianCardNames[CardNames.PUZZLE_BOX] = new TranslatedCardName("Головоломка", "Головоломки", "Головоломку", "Головоломок");
RussianCardNames[CardNames.SEXTANT] = new TranslatedCardName("Секстант", "Секстанта", "Секстант", "Секстантов");
RussianCardNames[CardNames.SHIELD] = new TranslatedCardName("Щит", "Щита", "Щит", "Щитов");
RussianCardNames[CardNames.SPELL_SCROLL] = new TranslatedCardName("Свиток Заклинаний", "Свитка Заклинаний", "Свиток Заклинаний", "Свитков Заклинаний");
RussianCardNames[CardNames.STAFF] = new TranslatedCardName("Посох", "Посоха", "Посох", "Посохов");
RussianCardNames[CardNames.SWORD] = new TranslatedCardName("Меч", "Меча", "Меч", "Мечей");
RussianCardNames[CardNames.CHEAP] = new TranslatedCardName("Дешёвый", "Дешёвых", "Дешёвого", "Дешёвых");
RussianCardNames[CardNames.CURSED] = new TranslatedCardName("Проклятый", "Проклятых", "Проклятого", "Проклятых");
RussianCardNames[CardNames.FATED] = new TranslatedCardName("Обречённый", "Обречённых", "Обречённого", "Обречённых");
RussianCardNames[CardNames.FAWNING] = new TranslatedCardName("Раболепный", "Раболепных", "Раболепного", "Раболепных");
RussianCardNames[CardNames.FRIENDLY] = new TranslatedCardName("Дружелюбный", "Дружелюбных", "Дружелюбного", "Дружелюбных");
RussianCardNames[CardNames.HASTY] = new TranslatedCardName("Торопливый", "Торопливых", "Торопливого", "Торопливых");
RussianCardNames[CardNames.INHERITED] = new TranslatedCardName("Унаследованный", "Унаследованных", "Унаследованного", "Унаследованных");
RussianCardNames[CardNames.INSPIRING] = new TranslatedCardName("Вдохновляющий", "Вдохновляющих", "Вдохновляющего", "Вдохновляющих");
RussianCardNames[CardNames.NEARBY] = new TranslatedCardName("Близлежащий", "Близлежащих", "Близлежащего", "Близлежащих");
RussianCardNames[CardNames.PATIENT] = new TranslatedCardName("Терпеливый", "Терпеливых", "Терпеливого", "Терпеливых");
RussianCardNames[CardNames.PIOUS] = new TranslatedCardName("Набожный", "Набожных", "Набожного", "Набожных");
RussianCardNames[CardNames.RECKLESS] = new TranslatedCardName("Безрассудный", "Безрассудных", "Безрассудного", "Безрассудных");
RussianCardNames[CardNames.RICH] = new TranslatedCardName("Богатый", "Богатых", "Богатого", "Богатых");
RussianCardNames[CardNames.SHY] = new TranslatedCardName("Застенчивый", "Застенчивых", "Застенчивого", "Застенчивых");
RussianCardNames[CardNames.TIRELESS] = new TranslatedCardName("Неутомимый", "Неутомимых", "Неутомимого", "Неутомимых");
RussianCardNames[CardNames.MARCHLAND] = new TranslatedCardName("Пограничная Полоса", "Пограничных Полосы", "Пограничную Полосу", "Пограничных Полос");

RussianCardNames[CardNames.SHOP] = new TranslatedCardName("Магазин", "Магазина", "Магазин", "Магазинов");
RussianCardNames[CardNames.CARNIVAL] = new TranslatedCardName("Карнавал", "Карнавала", "Карнавал", "Карнавалов");
RussianCardNames[CardNames.FERRYMAN] = new TranslatedCardName("Паромщик", "Паромщика", "Паромщика", "Паромщиков");
RussianCardNames[CardNames.FARMHANDS] = new TranslatedCardName("Батраки", "Батраков", "Батраков", "Батраков");
RussianCardNames[CardNames.JOUST] = new TranslatedCardName("Рыцарский Турнир", "Рыцарских Турнира", "Рыцарский Турнир", "Рыцарских Турниров");
RussianCardNames[CardNames.REWARDS_PILE] = new TranslatedCardName("Награды", "Награды", "Награды", "Наград");
RussianCardNames[CardNames.COURSER] = new TranslatedCardName("Скакун", "Скакуна", "Скакуна", "Скакунов");
RussianCardNames[CardNames.RENOWN] = new TranslatedCardName("Слава", "Славы", "Славу", "Слав");
RussianCardNames[CardNames.CORONET] = new TranslatedCardName("Венец", "Венеца", "Венец", "Венцов");
RussianCardNames[CardNames.DEMESNE] = new TranslatedCardName("Имение", "Имения", "Имение", "Имений");
RussianCardNames[CardNames.HOUSECARL] = new TranslatedCardName("Телохранитель", "Телохранителя", "Телохранителя", "Телохранителей");
RussianCardNames[CardNames.HUGE_TURNIP] = new TranslatedCardName("Огромная Репа", "Огромных Репы", "Огромную Репу", "Огромных Реп");
RussianCardNames[CardNames.FARRIER] = new TranslatedCardName("Коновал", "Коновала", "Коновала", "Коновалов");
RussianCardNames[CardNames.INFIRMARY] = new TranslatedCardName("Лазарет", "Лазарета", "Лазарет", "Лазаретов");
RussianCardNames[CardNames.FOOTPAD] = new TranslatedCardName("Грабитель", "Грабителя", "Грабителя", "Грабителей");

RussianCardNames[CardNames.FISHMONGER] = new TranslatedCardName("Торговец рыбой", "Торговца рыбой", "Торговец рыбой", "Торговцев рыбой");
RussianCardNames[CardNames.SNAKE_WITCH] = new TranslatedCardName("Змеиная Ведьма", "Змеиных Ведьмы", "Змеиную Ведьму", "Змеиных Ведьм");
RussianCardNames[CardNames.ARISTOCRAT] = new TranslatedCardName("Аристократ", "Аристократа", "Аристократа", "Аристократов");
RussianCardNames[CardNames.CRAFTSMAN] = new TranslatedCardName("Мастер", "Мастера", "Мастера", "Мастеров");
RussianCardNames[CardNames.RIVERBOAT] = new TranslatedCardName("Речная Лодка", "Речных Лодки", "Речную Лодку", "Речных Лодкок");
RussianCardNames[CardNames.ROOT_CELLAR] = new TranslatedCardName("Овощной Погреб", "Овощных Погреба", "Овощной Погреб", "Овощных Погребов");
RussianCardNames[CardNames.ALLEY] = new TranslatedCardName("Аллея", "Аллеи", "Аллею", "Аллей");
RussianCardNames[CardNames.CHANGE] = new TranslatedCardName("Изменение", "Изменения", "Изменение", "Изменений");
RussianCardNames[CardNames.NINJA] = new TranslatedCardName("Ниндзя", "Ниндзя", "Ниндзя", "Ниндзя");
RussianCardNames[CardNames.POET] = new TranslatedCardName("Поэт", "Поэта", "Поэта", "Поэтов");
RussianCardNames[CardNames.RIVER_SHRINE] = new TranslatedCardName("Речной храм", "Речных храма", "Речной храм", "Речных храмов");
RussianCardNames[CardNames.RUSTIC_VILLAGE] = new TranslatedCardName("Удалённая Деревня", "Удалённых Деревни", "Удалённыю Деревню", "Удалённых Деревень");
RussianCardNames[CardNames.GOLD_MINE] = new TranslatedCardName("Золотой Рудник", "Золотых Рудника", "Золотой Рудник", "Золотых Рудников");
RussianCardNames[CardNames.IMPERIAL_ENVOY] = new TranslatedCardName("Имперский Посланник", "Имперских Посланника", "Имперского Посланника", "Имперских Посланников");
RussianCardNames[CardNames.KITSUNE] = new TranslatedCardName("Кицунэ", "Кицунэ", "Кицунэ", "Кицунэ");
RussianCardNames[CardNames.LITTER] = new TranslatedCardName("Носилки", "Носилки", "Носилки", "Носилкок");
RussianCardNames[CardNames.RICE_BROKER] = new TranslatedCardName("Торговец Рисом", "Торговца Рисом", "Торговеца Рисом", "Торговцев Рисом");
RussianCardNames[CardNames.RONIN] = new TranslatedCardName("Ронин", "Ронина", "Ронина", "Ронинов");
RussianCardNames[CardNames.TANUKI] = new TranslatedCardName("Тануки", "Тануки", "Тануки", "Тануки");
RussianCardNames[CardNames.TEA_HOUSE] = new TranslatedCardName("Чайный Дом", "Чайных Дома", "Чайный Дом", "Чайных Домов");
RussianCardNames[CardNames.SAMURAI] = new TranslatedCardName("Самурай", "Самурая", "Самурая", "Самураев");
RussianCardNames[CardNames.RICE] = new TranslatedCardName("Рис", "Риса", "Рис", "Риса");
RussianCardNames[CardNames.MOUNTAIN_SHRINE] = new TranslatedCardName("Горный Храм", "Горных Храма", "Горный Храм", "Горных Храмов");
RussianCardNames[CardNames.DAIMYO] = new TranslatedCardName("Даймё", "Даймё", "Даймё", "Даймё");
RussianCardNames[CardNames.ARTIST] = new TranslatedCardName("Художник", "Художника", "Художника", "Художников");
RussianCardNames[CardNames.APPROACHING_ARMY] = new TranslatedCardName("Приближающаяся Армия");
RussianCardNames[CardNames.BIDING_TIME] = new TranslatedCardName("Время Ожидания");
RussianCardNames[CardNames.BUREAUCRACY] = new TranslatedCardName("Бюрократия");
RussianCardNames[CardNames.DIVINE_WIND] = new TranslatedCardName("Божественный Ветер");
RussianCardNames[CardNames.ENLIGHTENMENT] = new TranslatedCardName("Просвещение");
RussianCardNames[CardNames.FLOURISHING_TRADE] = new TranslatedCardName("Процветающая Торговля");
RussianCardNames[CardNames.GOOD_HARVEST] = new TranslatedCardName("Хороший Урожай");
RussianCardNames[CardNames.GREAT_LEADER] = new TranslatedCardName("Великий Лидер");
RussianCardNames[CardNames.GROWTH] = new TranslatedCardName("Рост");
RussianCardNames[CardNames.HARSH_WINTER] = new TranslatedCardName("Суровая Зима");
RussianCardNames[CardNames.KIND_EMPEROR] = new TranslatedCardName("Добрый Император");
RussianCardNames[CardNames.PANIC] = new TranslatedCardName("Паника");
RussianCardNames[CardNames.PROGRESS] = new TranslatedCardName("Прогресс");
RussianCardNames[CardNames.RAPID_EXPANSION] = new TranslatedCardName("Быстрая Экспансия");
RussianCardNames[CardNames.SICKNESS] = new TranslatedCardName("Болезнь");
RussianCardNames[CardNames.AMASS] = new TranslatedCardName("Накопление");
RussianCardNames[CardNames.ASCETICISM] = new TranslatedCardName("Аскетизм");
RussianCardNames[CardNames.CREDIT] = new TranslatedCardName("Кредит");
RussianCardNames[CardNames.FORESIGHT] = new TranslatedCardName("Предвидение");
RussianCardNames[CardNames.KINTSUGI] = new TranslatedCardName("Кинцуги");
RussianCardNames[CardNames.PRACTICE] = new TranslatedCardName("Практика");
RussianCardNames[CardNames.SEA_TRADE] = new TranslatedCardName("Морская Торговля");
RussianCardNames[CardNames.RECEIVE_TRIBUTE] = new TranslatedCardName("Получение Дани");
RussianCardNames[CardNames.GATHER] = new TranslatedCardName("Собрание");
RussianCardNames[CardNames.CONTINUE] = new TranslatedCardName("Продолжение");