"use strict";

function Type(name) {
    var isShownOnPile = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var isRealType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

    var self = this;
    self.name = name;
    self.toString = function () {
        return self.name;
    };
    self.isShownOnPile = isShownOnPile;
    self.isRealType = isRealType;
}

var Types = {
    ACTION: new Type("Action"),
    NIGHT: new Type("Night"),
    TREASURE: new Type("Treasure"),
    DURATION: new Type("Duration"),
    CURSE: new Type("Curse"),
    ATTACK: new Type("Attack", true),
    EVENT: new Type("Event"),
    GATHERING: new Type("Gathering"),
    KNIGHT: new Type("Knight"),
    LANDMARK: new Type("Landmark"),
    LOOTER: new Type("Looter"),
    RUINS: new Type("Ruins"),
    TRAVELLER: new Type("Traveller"),
    PRIZE: new Type("Prize"),
    RESERVE: new Type("Reserve"),
    VICTORY: new Type("Victory"),
    REACTION: new Type("Reaction"),
    SHELTER: new Type("Shelter"),
    CASTLE: new Type("Castle", true),
    FATE: new Type("Fate"),
    DOOM: new Type("Doom"),
    HEIRLOOM: new Type("Heirloom"),
    SPIRIT: new Type("Spirit"),
    ZOMBIE: new Type("Zombie"),
    BOON: new Type("Boon"),
    HEX: new Type("Hex"),
    STATE: new Type("State"),
    ARTIFACT: new Type("Artifact"),
    PROJECT: new Type("Project"),
    COMMAND: new Type("Command", true),
    WAY: new Type("Way"),
    LIAISON: new Type("Liaison", true),
    ALLY: new Type("Ally"),
    AUGUR: new Type("Augur", true),
    CLASH: new Type("Clash", true),
    CRAFT: new Type("Craft"),
    FORT: new Type("Fort", true),
    ODYSSEY: new Type("Odyssey", true),
    TOWNSFOLK: new Type("Townsfolk", true),
    TOY: new Type("Toy"),
    WIZARD: new Type("Wizard", true),
    LOOT: new Type("Loot"),
    TRAIT: new Type("Trait"),
    REWARD: new Type("Reward"),
    SHADOW: new Type("Shadow"),
    OMEN: new Type("Omen"),
    PROPHECY: new Type("Prophecy"),
    RANDOMIZER: new Type("Randomizer", false, false)
};

var NO_TEMPLATE_TYPES = [Types.ATTACK, Types.PRIZE, Types.REWARD, Types.LOOTER, Types.KNIGHT, Types.CASTLE, Types.GATHERING, Types.SPIRIT, Types.ZOMBIE, Types.FATE, Types.DOOM, Types.HEIRLOOM, Types.COMMAND, Types.LIAISON, Types.AUGUR, Types.CLASH, Types.CRAFT, Types.FORT, Types.ODYSSEY, Types.TOWNSFOLK, Types.TOY, Types.WIZARD, Types.RANDOMIZER, Types.LOOT, Types.SHADOW, Types.OMEN, Types.PROPHECY];
var NO_MINI_TEMPLATE_TYPES = [Types.TRAVELLER, Types.RANDOMIZER];