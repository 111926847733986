"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DrawZone = function (_TabbedZone) {
    _inherits(DrawZone, _TabbedZone);

    function DrawZone() {
        _classCallCheck(this, DrawZone);

        return _possibleConstructorReturn(this, (DrawZone.__proto__ || Object.getPrototypeOf(DrawZone)).apply(this, arguments));
    }

    _createClass(DrawZone, [{
        key: "initialize",
        value: function initialize(cardIds) {
            var _this2 = this;

            // These are shown in the corner
            this.primaryStacks = [];
            // These are shown in the shadow card window
            this.windowStacks = [];
            if (cardIds.length > 0) {
                cardIds.forEach(function (id) {
                    if (id === -1) {
                        var primaryStack = _this2.addAnonymousCard();
                        primaryStack.anonymousCards++;
                    } else {
                        var _primaryStack = _this2.addCard(_this2.state.cards[id]);
                        _primaryStack.addCard(_this2.state.cards[id]);
                    }
                });
            }
            this.reposition();
        }
    }, {
        key: "extractTab",
        value: function extractTab() {
            var _this3 = this;

            var nonBackStack = this.windowStacks.find(function (s) {
                return s.topCard.cardName !== CardNames.BACK;
            });
            return new ShadowGameTab(this.layoutModel, getFullArtURL(CardNames.NINJA), function (zone) {
                return zone instanceof DrawZone && zone.owner === _this3.owner;
            }, function () {
                return LANGUAGE.getPhrase[Phrases.SHADOW_CARDS];
            });
        }
    }, {
        key: "reposition",
        value: function reposition() {
            var _this4 = this;

            var shouldAnimate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            var vh = 0.01 * window.innerHeight;
            var vw = 0.01 * window.innerWidth;

            var zIndex = 0;
            var x = void 0,
                y = void 0,
                w = void 0,
                h = void 0;
            var shadowOffsetY = 7 * vh;

            if (this.owner.isHero) {
                h = 18 * vh;
                w = h / CardSizes.FULL.ratio;
                x = vw + w;
                y = 86 * vh;
            } else {
                h = 17 * vh;
                w = h / CardSizes.FULL.ratio;
                x = (1.5 + LEFT_COLUMN_WIDTH) * vw + w;
                y = this.layoutModel.isSpec ? 4 * vh : -10 * vh;
            }

            this.primaryStacks = this.primaryStacks.filter(function (s) {
                return !s.destroyed;
            });
            this.windowStacks = this.windowStacks.filter(function (s) {
                return !s.destroyed;
            });

            if (this.primaryStacks.length > 0) {
                this.primaryStacks.forEach(function (cardStack) {
                    return cardStack.addFilter("not-windowed");
                });
                if (this.owner.isActiveOpponent && !this.layoutModel.isSpec) this.primaryStacks.forEach(function (cardStack) {
                    return cardStack.addFilter("inverted-card");
                });else this.primaryStacks.forEach(function (cardStack) {
                    return cardStack.removeFilter("inverted-card");
                });

                if (this.owner.isActiveOpponent || this.owner.isHero) this.primaryStacks.forEach(function (cardStack) {
                    return cardStack.removeFilter("zone-hidden");
                });else this.primaryStacks.forEach(function (cardStack) {
                    return cardStack.addFilter("zone-hidden");
                });
                this.primaryStacks.forEach(function (cardStack, i) {
                    if (_this4.owner.isHero) {
                        cardStack.reposition(x, y + (1 - (i + 1) / _this4.primaryStacks.length) * shadowOffsetY, w, h, zIndex - i);
                    } else {
                        cardStack.reposition(x, y - (1 - (i + 1) / _this4.primaryStacks.length) * shadowOffsetY, w, h, zIndex - i);
                    }
                });
            }

            _get(DrawZone.prototype.__proto__ || Object.getPrototypeOf(DrawZone.prototype), "reposition", this).call(this, shouldAnimate);
        }
    }, {
        key: "show",
        value: function show(cause) {
            if (cause instanceof GameTab) _get(DrawZone.prototype.__proto__ || Object.getPrototypeOf(DrawZone.prototype), "show", this).call(this, cause);
        }
    }, {
        key: "addAnonymousCard",
        value: function addAnonymousCard() {
            var windowStack = this.windowStacks[0];
            if (isUndefined(windowStack) || windowStack.topCard.cardName !== CardNames.BACK) {
                windowStack = new CardStack(this.state.getAnonCard(CardNames.BACK), this);
                this.windowStacks.unshift(windowStack);
            }
            windowStack.addAnonymousCard();

            var primaryStack = this.primaryStacks.find(function (s) {
                return s.topCard.cardName === CardNames.BACK;
            });
            if (isUndefined(primaryStack)) {
                primaryStack = new CardStack(this.state.getAnonCard(CardNames.BACK), this);
                primaryStack.getCounter = function () {
                    return primaryStack.cards.length + primaryStack.anonymousCards;
                };
                this.primaryStacks.unshift(primaryStack);
            }
            this.reposition();
            return primaryStack;
        }
    }, {
        key: "addCard",
        value: function addCard(card) {
            var targetName = card.cardName;
            var windowStack = this.windowStacks[0];
            if (isUndefined(windowStack) || windowStack.topCard.cardName !== targetName) {
                windowStack = new CardStack(this.state.getAnonCard(targetName), this);
                this.windowStacks.unshift(windowStack);
            }
            windowStack.addCard(card);

            var primaryStack = this.primaryStacks.find(function (s) {
                return s.topCard.cardName === targetName;
            });
            if (isUndefined(primaryStack)) {
                primaryStack = new CardStack(this.state.getAnonCard(targetName), this);
                primaryStack.getCounter = function () {
                    return primaryStack.cards.length + primaryStack.anonymousCards;
                };
                this.primaryStacks.unshift(primaryStack);
            }
            this.reposition();
            return primaryStack;
        }
    }, {
        key: "spliceCard",
        value: function spliceCard(card) {
            var targetCard = card;
            var targetStack = this.primaryStacks.find(function (s) {
                return s.cards.includes(card);
            });
            if (isUndefined(targetStack)) {
                targetStack = this.primaryStacks.find(function (s) {
                    return s.cards.some(function (c) {
                        return c.cardName === card.cardName;
                    });
                });
                if (isUndefined(targetStack)) {
                    var _newStack = this.spliceAnonymousCard();
                    _newStack.anonymousCards--;
                    _newStack.addCard(card);
                    return _newStack;
                } else {
                    targetCard = targetStack.cards.find(function (c) {
                        return c.cardName === card.cardName;
                    });
                }
            }
            targetStack.removeCard(targetCard);
            var newStack = new CardStack(this.state.getAnonCard(targetCard.cardName), this.state.nowhere);
            newStack.addCard(card);
            newStack.copyPosition(targetStack);
            if (targetStack.cards.length + targetStack.anonymousCards === 0) {
                if (targetStack.trackedAnimators.length === 0) {
                    targetStack.destroy();
                    this.reposition(true);
                    var index = this.primaryStacks.indexOf(targetStack);
                    if (index > -1) this.primaryStacks.splice(index, 1);
                } else {
                    targetStack.addFilter("intangible");
                }
            }

            var windowStack = this.windowStacks.find(function (s) {
                return s.cards.includes(card);
            });
            if (isUndefined(windowStack)) {
                windowStack = this.windowStacks.find(function (s) {
                    return s.cards.some(function (c) {
                        return c.cardName === card.cardName;
                    });
                });
                if (isUndefined(windowStack)) {
                    // Something weird happened
                    return newStack;
                }
            }
            windowStack.removeCard(targetCard);
            if (windowStack.cards.length + windowStack.anonymousCards === 0) {
                if (windowStack.trackedAnimators.length === 0) {
                    windowStack.destroy();
                    this.reposition(true);
                    var _index = this.windowStacks.indexOf(windowStack);
                    if (_index > -1) this.windowStacks.splice(_index, 1);
                } else {
                    windowStack.addFilter("intangible");
                }
            }
            return newStack;
        }
    }, {
        key: "spliceAnonymousCard",
        value: function spliceAnonymousCard() {
            var primaryStack = this.primaryStacks.find(function (s) {
                return s.topCard.cardName === CardNames.BACK;
            });
            var newStack = new CardStack(this.state.getAnonCard(CardNames.BACK), this.state.nowhere);
            newStack.anonymousCards++;
            newStack.copyPosition(primaryStack);
            primaryStack.anonymousCards--;
            if (primaryStack.anonymousCards === 0) {
                if (primaryStack.trackedAnimators.length === 0) {
                    primaryStack.destroy();
                    var index = this.primaryStacks.indexOf(primaryStack);
                    if (index > -1) this.primaryStacks.splice(index, 1);
                } else {
                    primaryStack.addFilter("intangible");
                }
            }

            var windowStack = this.windowStacks.find(function (s) {
                return s.topCard.cardName === CardNames.BACK;
            });
            windowStack.anonymousCards--;
            if (windowStack.anonymousCards === 0) {
                if (windowStack.trackedAnimators.length === 0) {
                    windowStack.destroy();
                    var _index2 = this.windowStacks.indexOf(windowStack);
                    if (_index2 > -1) this.windowStacks.splice(_index2, 1);
                } else {
                    windowStack.addFilter("intangible");
                }
            }
            return newStack;
        }
    }, {
        key: "getScript",
        value: function getScript(fromZone, stack, targetStack, props) {
            if (props.isInstant) return [];

            if (fromZone instanceof DiscardZone && fromZone.owner === this.owner) {
                if (props.animationType === ClientAnimationTypes.MINIMAL) return [];else return [new Keyframe(props.speed * 0.5, new StackTarget(), Curves.SMOOTH)];
            } else {
                return _get(DrawZone.prototype.__proto__ || Object.getPrototypeOf(DrawZone.prototype), "getScript", this).call(this, fromZone, stack, targetStack, props);
            }
        }
    }, {
        key: "mergeStacks",
        value: function mergeStacks(targetStack, transitionStack) {
            var _this5 = this;

            transitionStack.cards.forEach(function (c) {
                _this5.addCard(c).addCard(c);
            });
            Array.from(Array(transitionStack.anonymousCards).keys()).forEach(function (_) {
                _this5.addAnonymousCard().anonymousCards++;
            });
            transitionStack.destroy(targetStack);
            targetStack.removeFilter("intangible");
        }
    }, {
        key: "zoneName",
        get: function get() {
            return "DrawZone";
        }
    }, {
        key: "isOwnershipDependent",
        get: function get() {
            return true;
        }
    }, {
        key: "shouldSort",
        get: function get() {
            return false;
        }
    }, {
        key: "maxMargin",
        get: function get() {
            return -0.15;
        }
    }]);

    return DrawZone;
}(TabbedZone);