"use strict";

var FrenchTypes = {};

FrenchTypes[Types.ACTION] = "Action";
FrenchTypes[Types.TREASURE] = "Trésor";
FrenchTypes[Types.ATTACK] = "Attaque";
FrenchTypes[Types.CURSE] = "Malédiction";
FrenchTypes[Types.DURATION] = "Durée";
FrenchTypes[Types.EVENT] = "Événement";
FrenchTypes[Types.GATHERING] = "Collecte";
FrenchTypes[Types.KNIGHT] = "Chevalier";
FrenchTypes[Types.LANDMARK] = "Jalon";
FrenchTypes[Types.LOOTER] = "Pillard";
FrenchTypes[Types.RUINS] = "Ruine";
FrenchTypes[Types.TRAVELLER] = "Itinérant";
FrenchTypes[Types.PRIZE] = "Prix";
FrenchTypes[Types.RESERVE] = "Réserve";
FrenchTypes[Types.VICTORY] = "Victoire";
FrenchTypes[Types.REACTION] = "Réaction";
FrenchTypes[Types.SHELTER] = "Refuge";
FrenchTypes[Types.CASTLE] = "Château";
FrenchTypes[Types.FATE] = "Destin";
FrenchTypes[Types.NIGHT] = "Nuit";
FrenchTypes[Types.DOOM] = "Fatalité";
FrenchTypes[Types.HEIRLOOM] = "Patrimoine";
FrenchTypes[Types.SPIRIT] = "Esprit";
FrenchTypes[Types.ZOMBIE] = "Zombie";
FrenchTypes[Types.BOON] = "Aubaine";
FrenchTypes[Types.HEX] = "Sortilège";
FrenchTypes[Types.STATE] = "État";
FrenchTypes[Types.PROJECT] = "Projet";
FrenchTypes[Types.COMMAND] = "Ordre";
FrenchTypes[Types.WAY] = "Voie";
FrenchTypes[Types.LIAISON] = "Liaison";
FrenchTypes[Types.ALLY] = "Allié";
FrenchTypes[Types.AUGUR] = "Augure";
FrenchTypes[Types.CLASH] = "Affrontement";
FrenchTypes[Types.FORT] = "Fortifications";
FrenchTypes[Types.ODYSSEY] = "Odyssée";
FrenchTypes[Types.TOWNSFOLK] = "Citoyen";
FrenchTypes[Types.WIZARD] = "Magicien";
FrenchTypes[Types.LOOT] = "Loot";
FrenchTypes[Types.TRAIT] = "Trait";
FrenchTypes[Types.REWARD] = "Récompense";
FrenchTypes[Types.SHADOW] = "Ombre";
FrenchTypes[Types.OMEN] = "Signe";
FrenchTypes[Types.PROPHECY] = "Prophétie";

var FrenchZoneNames = {};

FrenchZoneNames["HandZone"] = "Main";
FrenchZoneNames["DrawZone"] = "Pioche";
FrenchZoneNames["DiscardZone"] = "Défausse";
FrenchZoneNames["TrashZone"] = "Écart";
FrenchZoneNames["SupplyZone"] = "Pile de la réserve";
FrenchZoneNames["InPlayZone"] = "Zone de jeu";
FrenchZoneNames["SetAsideZone"] = "Mis de côté";
FrenchZoneNames["TavernZone"] = "Plateau taverne";
FrenchZoneNames["NVZone"] = "Plateau Village indigène";
FrenchZoneNames["IslandZone"] = "Plateau île";
FrenchZoneNames["ExileZone"] = "Exil";
FrenchZoneNames["SetAsideZone"] = "Cartes mises de côté";

var FrenchMovementTypes = {};

FrenchMovementTypes[MovementTypes.PLAY] = new TranslatedMovementType("jouez", "joue");
FrenchMovementTypes[MovementTypes.DRAW] = new TranslatedMovementType("piochez", "pioche");
FrenchMovementTypes[MovementTypes.DISCARD] = new TranslatedMovementType("défaussez", "défausse");
FrenchMovementTypes[MovementTypes.GAIN] = new TranslatedMovementType("recevez", "reçoit");
FrenchMovementTypes[MovementTypes.TRASH] = new TranslatedMovementType("écartez", "écarte");
FrenchMovementTypes[MovementTypes.TOPDECK] = new TranslatedMovementType("placez sur la pioche", "place sur la pioche");
FrenchMovementTypes[MovementTypes.REVEAL] = new TranslatedMovementType("dévoilez", "dévoile");
FrenchMovementTypes[MovementTypes.REVEAL_SEARCHING] = new TranslatedMovementType("dévoilez", "dévoile");
FrenchMovementTypes[MovementTypes.LOOK_AT] = new TranslatedMovementType("regardez", "regarde");
FrenchMovementTypes[MovementTypes.PUT_IN_HAND] = new TranslatedMovementType("la prenez en main", "la prend en main");
FrenchMovementTypes[MovementTypes.SET_ASIDE] = new TranslatedMovementType("la mettez de côté", "la met de côté");
FrenchMovementTypes[MovementTypes.PUT_ON_MAT] = new TranslatedMovementType("mettez sur le plateau", "met sur le plateau");
FrenchMovementTypes[MovementTypes.DECK_TO_DISCARD] = new TranslatedMovementType("DECK_TO_DISCARD", "DECK_TO_DISCARD");
FrenchMovementTypes[MovementTypes.BACK_ON_DECK] = new TranslatedMovementType("BACK_ON_DECK", "BACK_ON_DECK");
FrenchMovementTypes[MovementTypes.SHUFFLE_INTO_DECK] = new TranslatedMovementType("SHUFFLE_INTO_DECK", "SHUFFLE_INTO_DECK");
FrenchMovementTypes[MovementTypes.INSERT_IN_DECK] = new TranslatedMovementType("INSERT_IN_DECK", "INSERT_IN_DECK");
FrenchMovementTypes[MovementTypes.EXCHANGE_RETURN] = new TranslatedMovementType("EXCHANGE_RETURN", "EXCHANGE_RETURN");
FrenchMovementTypes[MovementTypes.EXCHANGE_RECEIVE] = new TranslatedMovementType("EXCHANGE_RECEIVE", "EXCHANGE_RECEIVE");
FrenchMovementTypes[MovementTypes.PASS] = new TranslatedMovementType("PASS", "PASS");
FrenchMovementTypes[MovementTypes.RETURN_TO] = new TranslatedMovementType("RETURN_TO", "RETURN_TO");
FrenchMovementTypes[MovementTypes.PUT_ON_BOTTOM_OF] = new TranslatedMovementType("PUT_ON_BOTTOM_OF", "PUT_ON_BOTTOM_OF");
FrenchMovementTypes[MovementTypes.STARTS_WITH] = new TranslatedMovementType("STARTS_WITH", "STARTS_WITH");
FrenchMovementTypes[MovementTypes.TAKE] = new TranslatedMovementType("TAKE", "TAKE");
FrenchMovementTypes[MovementTypes.GET_STATE] = new TranslatedMovementType("GET_STATE", "GET_STATE");
FrenchMovementTypes[MovementTypes.RETURN_STATE] = new TranslatedMovementType("RETURN_STATE", "RETURN_STATE");
FrenchMovementTypes[MovementTypes.EXILE] = new TranslatedMovementType("EXIL", "EXIL");

var FrenchPileTokenTexts = {};

FrenchPileTokenTexts[TokenNames.PLUS_ACTION] = "+Action";
FrenchPileTokenTexts[TokenNames.PLUS_BUY] = "+Achat";
FrenchPileTokenTexts[TokenNames.PLUS_CARD] = "+Carte";
FrenchPileTokenTexts[TokenNames.PLUS_COIN] = "+1";
FrenchPileTokenTexts[TokenNames.MINUS_COST] = "-2";
FrenchPileTokenTexts[TokenNames.TRASH] = "X";

var FrenchPileMarkers = {};

FrenchPileMarkers[PileMarkerTypes.BANE] = "Fléau";
FrenchPileMarkers[PileMarkerTypes.OBELISK] = "Obélisque";
FrenchPileMarkers[PileMarkerTypes.SPLIT] = "Partagée";
FrenchPileMarkers[PileMarkerTypes.MIXED] = "Mixte";

var FrenchTokenNames = {};

FrenchTokenNames[TokenNames.PLUS_ACTION] = "Jeton +Action";
FrenchTokenNames[TokenNames.PLUS_BUY] = "+Jeton +Achat";
FrenchTokenNames[TokenNames.PLUS_COIN] = "Jeton +Pièce";
FrenchTokenNames[TokenNames.PLUS_CARD] = "Jeton +Carte";
FrenchTokenNames[TokenNames.MINUS_COST] = "Jeton -2$";
FrenchTokenNames[TokenNames.TRASH] = "Jeton Écart";
FrenchTokenNames[TokenNames.JOURNEY] = "Jeton Voyage";
FrenchTokenNames[TokenNames.MINUS_CARD] = "Jeton -1 Carte";
FrenchTokenNames[TokenNames.MINUS_COIN] = "Jeton -1 Pièce";

var FrenchKingdomViewerNames = {};

FrenchKingdomViewerNames[KingdomViewerGroups.SUPPLY_CARDS] = "Cartes de la réserve";
FrenchKingdomViewerNames[KingdomViewerGroups.KINGDOM_LANDSCAPES] = "Événements, Jalons, Projets et Voies";
FrenchKingdomViewerNames[KingdomViewerGroups.NON_KINGDOM] = "Cartes hors réserve";
FrenchKingdomViewerNames[KingdomViewerGroups.RUINS] = "Ruines";
FrenchKingdomViewerNames[KingdomViewerGroups.DRUID_BOONS] = "Aubaines de la Druide";
FrenchKingdomViewerNames[KingdomViewerGroups.KNIGHTS] = "Chevaliers";
FrenchKingdomViewerNames[KingdomViewerGroups.CASTLES] = "Châteaux";
FrenchKingdomViewerNames[KingdomViewerGroups.BOONS_HEXES] = "Aubaines et Sortilèges";
FrenchKingdomViewerNames[KingdomViewerGroups.STATES_ARTIFACTS] = "États et Artefacts";
FrenchKingdomViewerNames[KingdomViewerGroups.BLACK_MARKET] = "Marché noir";
FrenchKingdomViewerNames[KingdomViewerGroups.PRIZES] = "Prix";
FrenchKingdomViewerNames[KingdomViewerGroups.REWARDS] = "Récompenses";

// Missing lines as of 08.01.2023 18:54:58
FrenchTypes[Types.LOOT] = "Trophée";
FrenchTypes[Types.TRAIT] = "Trait";
FrenchZoneNames["RevealZone"] = "Révéler";
FrenchZoneNames["SkippableRevealZone"] = "Révéler";
FrenchZoneNames["TheBox"] = "la boîte"; // this is used for Inherited to say where everything except Copper goes (when it is replaced by the inherited card)
FrenchKingdomViewerNames[KingdomViewerGroups.LOOT] = "Trophée";