"use strict";

webclient.component('scoreSummaryTable', {
    bindings: {
        "results": "<"
    },
    controller: ["meta", function (meta) {
        var self = this;

        self.rank = function (result) {
            return result.rank;
        };
        self.getName = function (result) {
            return meta.playerNames[result.playerId];
        };
        self.getTurnPhrase = function () {
            return LANGUAGE.getPhrase[Phrases.TURNS];
        };
        self.shouldShowResign = function (result) {
            return result.resignIndex > -1;
        };
    }],
    template: "\n        <div class=\"score-summary-row fieldset-title\" ng-repeat=\"result in $ctrl.results\">\n\t        <div class=\"score-fieldset-rank\">{{$ctrl.rank(result)}}</div>\n            <div class=\"score-content-title\">{{$ctrl.getName(result)}}\n                <div class=\"fieldset-title-separator\"></div>\n            </div>\n            <div class=\"score-vp-container\">\n                <vp-amount-icon class=\"score-total-vp\" amount=\"result.score.totalPoints\"></vp-amount-icon>\n                <div class=\"fieldset-title-separator\"></div>\n            </div>\n            <div class=\"score-turn-count\">{{$ctrl.getTurnPhrase()}}: {{result.score.usedTurns}}\n                <div class=\"fieldset-title-separator\" ng-class=\"{'final-separator': !$ctrl.shouldShowResign(result)}\"></div>\n            </div>\n            <div class=\"score-resigned-icon\" ng-if=\"$ctrl.shouldShowResign(result)\"></div>\n        </div>\n    "
});