"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

(window._gsQueue || (window._gsQueue = [])).push(function () {
  "use strict";
  window._gsDefine("plugins.ThrowPropsPlugin", ["plugins.TweenPlugin", "TweenLite", "easing.Ease", "utils.VelocityTracker"], function (t, e, i, s) {
    var r,
        n,
        a,
        o,
        l = function l() {
      t.call(this, "throwProps"), this._overwriteProps.length = 0;
    },
        h = 999999999999999,
        u = { x: 1, y: 1, z: 2, scale: 1, scaleX: 1, scaleY: 1, rotation: 1, rotationZ: 1, rotationX: 2, rotationY: 2, skewX: 1, skewY: 1 },
        _ = function _(t, e, i, s) {
      for (var r, n, a = e.length, o = 0, l = h; --a > -1;) {
        r = e[a], n = r - t, 0 > n && (n = -n), l > n && r >= s && i >= r && (o = a, l = n);
      }return e[o];
    },
        p = function p(t, e, i, s) {
      if ("auto" === t.end) return t;i = isNaN(i) ? h : i, s = isNaN(s) ? -h : s;var r = "function" == typeof t.end ? t.end(e) : t.end instanceof Array ? _(e, t.end, i, s) : Number(t.end);return r > i ? r = i : s > r && (r = s), { max: r, min: r };
    },
        c = l.calculateChange = function (t, s, r, n) {
      null == n && (n = .05);var a = s instanceof i ? s : s ? new i(s) : e.defaultEase;return r * n * t / a.getRatio(n);
    },
        f = l.calculateDuration = function (t, s, r, n, a) {
      a = a || .05;var o = n instanceof i ? n : n ? new i(n) : e.defaultEase;return Math.abs((s - t) * o.getRatio(a) / r / a);
    },
        d = l.calculateTweenDuration = function (t, r, n, a, o) {
      if ("string" == typeof t && (t = e.selector(t)), !t) return 0;null == n && (n = 10), null == a && (a = .2), null == o && (o = 1), t.length && (t = t[0] || t);var h,
          u,
          _,
          d,
          m,
          g,
          v,
          y,
          w,
          x,
          T = 0,
          b = 9999999999,
          P = r.throwProps || r,
          k = r.ease instanceof i ? r.ease : r.ease ? new i(r.ease) : e.defaultEase,
          S = isNaN(P.checkpoint) ? .05 : Number(P.checkpoint),
          C = isNaN(P.resistance) ? l.defaultResistance : Number(P.resistance);for (h in P) {
        "resistance" !== h && "checkpoint" !== h && (u = P[h], "object" != (typeof u === "undefined" ? "undefined" : _typeof(u)) && (w = w || s.getByTarget(t), w && w.isTrackingProp(h) ? u = "number" == typeof u ? { velocity: u } : { velocity: w.getVelocity(h) } : (d = Number(u) || 0, _ = d * C > 0 ? d / C : d / -C)), "object" == (typeof u === "undefined" ? "undefined" : _typeof(u)) && (void 0 !== u.velocity && "number" == typeof u.velocity ? d = Number(u.velocity) || 0 : (w = w || s.getByTarget(t), d = w && w.isTrackingProp(h) ? w.getVelocity(h) : 0), m = isNaN(u.resistance) ? C : Number(u.resistance), _ = d * m > 0 ? d / m : d / -m, g = "function" == typeof t[h] ? t[h.indexOf("set") || "function" != typeof t["get" + h.substr(3)] ? h : "get" + h.substr(3)]() : t[h] || 0, v = g + c(d, k, _, S), void 0 !== u.end && (u = p(u, v, u.max, u.min)), void 0 !== u.max && v > Number(u.max) ? (x = u.unitFactor || 1, y = g > u.max && u.min !== u.max || d * x > -15 && 45 > d * x ? a + .1 * (n - a) : f(g, u.max, d, k, S), b > y + o && (b = y + o)) : void 0 !== u.min && Number(u.min) > v && (x = u.unitFactor || 1, y = u.min > g && u.min !== u.max || d * x > -45 && 15 > d * x ? a + .1 * (n - a) : f(g, u.min, d, k, S), b > y + o && (b = y + o)), y > T && (T = y)), _ > T && (T = _));
      }return T > b && (T = b), T > n ? n : a > T ? a : T;
    },
        m = l.prototype = new t("throwProps");return m.constructor = l, l.version = "0.9.0", l.API = 2, l._autoCSS = !0, l.defaultResistance = 100, l.track = function (t, e, i) {
      return s.track(t, e, i);
    }, l.untrack = function (t, e) {
      s.untrack(t, e);
    }, l.isTracking = function (t, e) {
      return s.isTracking(t, e);
    }, l.getVelocity = function (t, e) {
      var i = s.getByTarget(t);return i ? i.getVelocity(e) : 0 / 0;
    }, l._cssRegister = function () {
      var t = (window.GreenSockGlobals || window).com.greensock.plugins.CSSPlugin;if (t) {
        var e = t._internals,
            i = e._parseToProxy,
            a = e._setPluginRatio,
            o = e.CSSPropTween;e._registerComplexSpecialProp("throwProps", { parser: function parser(t, e, h, _, p, c) {
            c = new l();var f,
                d,
                m,
                g,
                v,
                y = {},
                w = {},
                x = {},
                T = {},
                b = {};n = {};for (m in e) {
              "resistance" !== m && (d = e[m], "object" == (typeof d === "undefined" ? "undefined" : _typeof(d)) ? (void 0 !== d.velocity && "number" == typeof d.velocity ? y[m] = Number(d.velocity) || 0 : (v = v || s.getByTarget(t), y[m] = v && v.isTrackingProp(m) ? v.getVelocity(m) : 0), void 0 !== d.end && (T[m] = d.end), void 0 !== d.min && (w[m] = d.min), void 0 !== d.max && (x[m] = d.max), void 0 !== d.resistance && (f = !0, b[m] = d.resistance)) : "number" == typeof d ? y[m] = d : (v = v || s.getByTarget(t), y[m] = v && v.isTrackingProp(m) ? v.getVelocity(m) : d || 0), u[m] && _._enableTransforms(2 === u[m]));
            }g = i(t, y, _, p, c), r = g.proxy, y = g.end;for (m in r) {
              n[m] = { velocity: y[m], min: w[m], max: x[m], end: T[m], resistance: b[m] };
            }return null != e.resistance && (n.resistance = e.resistance), p = new o(t, "throwProps", 0, 0, g.pt, 2), p.plugin = c, p.setRatio = a, p.data = g, c._onInitTween(r, n, _._tween), p;
          } });
      }
    }, l.to = function (t, i, s, l, h) {
      i.throwProps || (i = { throwProps: i });var u = new e(t, 1, i);return u.render(0, !0, !0), u.vars.css ? (u.duration(d(r, { throwProps: n, ease: i.ease }, s, l, h)), u._delay && !u.vars.immediateRender ? u.invalidate() : a._onInitTween(r, o, u), u) : (u.kill(), new e(t, d(t, i, s, l, h), i));
    }, m._onInitTween = function (t, e, i) {
      this.target = t, this._props = [], a = this, o = e;var r,
          n,
          l,
          h,
          u,
          _,
          f,
          d,
          m,
          g = i._ease,
          v = isNaN(e.checkpoint) ? .05 : Number(e.checkpoint),
          y = i._duration,
          w = 0;for (r in e) {
        if ("resistance" !== r && "checkpoint" !== r) {
          if (n = e[r], "number" == typeof n) u = Number(n) || 0;else if ("object" != (typeof n === "undefined" ? "undefined" : _typeof(n)) || isNaN(n.velocity)) {
            if (m = m || s.getByTarget(t), !m || !m.isTrackingProp(r)) throw "ERROR: No velocity was defined in the throwProps tween of " + t + " property: " + r;u = m.getVelocity(r);
          } else u = Number(n.velocity);_ = c(u, g, y, v), d = 0, h = "function" == typeof t[r], l = h ? t[r.indexOf("set") || "function" != typeof t["get" + r.substr(3)] ? r : "get" + r.substr(3)]() : t[r], "object" == (typeof n === "undefined" ? "undefined" : _typeof(n)) && (f = l + _, void 0 !== n.end && (n = p(n, f, n.max, n.min)), void 0 !== n.max && f > Number(n.max) ? d = n.max - l - _ : void 0 !== n.min && Number(n.min) > f && (d = n.min - l - _)), this._props[w++] = { p: r, s: l, c1: _, c2: d, f: h, r: !1 }, this._overwriteProps[w] = r;
        }
      }return !0;
    }, m._kill = function (e) {
      for (var i = this._props.length; --i > -1;) {
        null != e[this._props[i].p] && this._props.splice(i, 1);
      }return t.prototype._kill.call(this, e);
    }, m._roundProps = function (t, e) {
      for (var i = this._props, s = i.length; --s > -1;) {
        (t[i[s]] || t.throwProps) && (i[s].r = e);
      }
    }, m.setRatio = function (t) {
      for (var e, i, s = this._props.length; --s > -1;) {
        e = this._props[s], i = e.s + e.c1 * t + e.c2 * t * t, e.r && (i = 0 | i + (i > 0 ? .5 : -.5)), e.f ? this.target[e.p](i) : this.target[e.p] = i;
      }
    }, t.activate([l]), l;
  }, !0), window._gsDefine("utils.VelocityTracker", ["TweenLite"], function (t) {
    var e,
        i,
        s,
        r,
        n = /([A-Z])/g,
        a = {},
        o = { x: 1, y: 1, z: 2, scale: 1, scaleX: 1, scaleY: 1, rotation: 1, rotationZ: 1, rotationX: 2, rotationY: 2, skewX: 1, skewY: 1 },
        l = document.defaultView ? document.defaultView.getComputedStyle : function () {},
        h = function h(t, e, i) {
      var s = (t._gsTransform || a)[e];return s || 0 === s ? s : (t.style[e] ? s = t.style[e] : (i = i || l(t, null)) ? (t = i.getPropertyValue(e.replace(n, "-$1").toLowerCase()), s = t || i.length ? t : i[e]) : t.currentStyle && (i = t.currentStyle, s = i[e]), parseFloat(s) || 0);
    },
        u = t.ticker,
        _ = function _(t, e, i) {
      this.p = t, this.f = e, this.v1 = this.v2 = 0, this.t1 = this.t2 = u.time, this.css = !1, this.type = "", this._prev = null, i && (this._next = i, i._prev = this);
    },
        p = function p() {
      var t,
          i,
          n = e,
          a = u.time;if (a - s >= .03) for (r = s, s = a; n;) {
        for (i = n._firstVP; i;) {
          t = i.css ? h(n.target, i.p) : i.f ? n.target[i.p]() : n.target[i.p], (t !== i.v1 || a - i.t1 > .15) && (i.v2 = i.v1, i.v1 = t, i.t2 = i.t1, i.t1 = a), i = i._next;
        }n = n._next;
      }
    },
        c = function c(t) {
      this._lookup = {}, this.target = t, this.elem = t.style && t.nodeType ? !0 : !1, i || (u.addEventListener("tick", p, null, !1, -100), s = r = u.time, i = !0), e && (this._next = e, e._prev = this), e = this;
    },
        f = c.getByTarget = function (t) {
      for (var i = e; i;) {
        if (i.target === t) return i;i = i._next;
      }
    },
        d = c.prototype;return d.addProp = function (e, i) {
      if (!this._lookup[e]) {
        var s = this.target,
            r = "function" == typeof s[e],
            n = r ? this._altProp(e) : e,
            a = this._firstVP;this._firstVP = this._lookup[e] = this._lookup[n] = a = new _(n !== e && 0 === e.indexOf("set") ? n : e, r, a), a.css = this.elem && (void 0 !== this.target.style[a.p] || o[a.p]), a.css && o[a.p] && !s._gsTransform && t.set(s, { x: "+=0" }), a.type = i || a.css && 0 === e.indexOf("rotation") ? "deg" : "", a.v1 = a.v2 = a.css ? h(s, a.p) : r ? s[a.p]() : s[a.p];
      }
    }, d.removeProp = function (t) {
      var e = this._lookup[t];e && (e._prev ? e._prev._next = e._next : e === this._firstVP && (this._firstVP = e._next), e._next && (e._next._prev = e._prev), this._lookup[t] = 0, e.f && (this._lookup[this._altProp(t)] = 0));
    }, d.isTrackingProp = function (t) {
      return this._lookup[t] instanceof _;
    }, d.getVelocity = function (t) {
      var e,
          i,
          s,
          r = this._lookup[t],
          n = this.target;if (!r) throw "The velocity of " + t + " is not being tracked.";return e = r.css ? h(n, r.p) : r.f ? n[r.p]() : n[r.p], i = e - r.v2, ("rad" === r.type || "deg" === r.type) && (s = "rad" === r.type ? 2 * Math.PI : 360, i %= s, i !== i % (s / 2) && (i = 0 > i ? i + s : i - s)), i / (u.time - r.t2);
    }, d._altProp = function (t) {
      var e = t.substr(0, 3),
          i = ("get" === e ? "set" : "set" === e ? "get" : e) + t.substr(3);return "function" == typeof this.target[i] ? i : t;
    }, c.getByTarget = function (t) {
      for (var i = e; i;) {
        if (i.target === t) return i;i = i._next;
      }
    }, c.track = function (t, e, i) {
      var s = f(t),
          r = e.split(","),
          n = r.length;for (i = (i || "").split(","), s || (s = new c(t)); --n > -1;) {
        s.addProp(r[n], i[n] || i[0]);
      }return s;
    }, c.untrack = function (t, i) {
      var s = f(t),
          r = (i || "").split(","),
          n = r.length;if (s) {
        for (; --n > -1;) {
          s.removeProp(r[n]);
        }s._firstVP && i || (s._prev ? s._prev._next = s._next : s === e && (e = s._next), s._next && (s._next._prev = s._prev));
      }
    }, c.isTracking = function (t, e) {
      var i = f(t);return i ? !e && i._firstVP ? !0 : i.isTrackingProp(e) : !1;
    }, c;
  }, !0);
}), window._gsDefine && window._gsQueue.pop()();