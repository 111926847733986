"use strict";

// possible ability descriptions that could use "Argument0" to be more helpful
//
// Argument0 is some number an effect relates to
// ----------------------------------------------
// EnglishAbilityDescriptions[AbilityNames.EMPORIUM_GAIN_POINTS] = "+Argument0 VP (" + EnglishCardNames[CardNames.EMPORIUM].singular + ")";
//    the following needs the (server side?) magic that takes "3" and "Gold" and turns it into the language correct version of "3 Golds"
// EnglishAbilityDescriptions[AbilityNames.FORTUNE] = "gain Argument0 (" + EnglishCardNames[CardNames.FORTUNE].singular + ")";
// EnglishAbilityDescriptions[AbilityNames.GRAND_CASTLE] = "+Argument0 VP (" + EnglishCardNames[CardNames.GRAND_CASTLE].singular + ")";
// EnglishAbilityDescriptions[AbilityNames.TEMPLE] = "take all (Argument0) VP from the " + EnglishCardNames[CardNames.TEMPLE].singular + " pile";
// EnglishAbilityDescriptions[AbilityNames.TAX_RECEIVE] = "take all (Argument0) debt from the pile you bought from (" + EnglishCardNames[CardNames.TAX].singular + ")";
// EnglishAbilityDescriptions[AbilityNames.VP_FROM_AQUEDUCT] = "take all (Argument0) VP from " + EnglishCardNames[CardNames.AQUEDUCT].singular;
// EnglishAbilityDescriptions[AbilityNames.VP_FROM_DEFILED_SHRINE] = "take all (Argument0) VP from " + EnglishCardNames[CardNames.DEFILED_SHRINE].singular;


var EnglishAbilityDescriptions = {};

EnglishAbilityDescriptions[AbilityNames.BUY] = "buy";
EnglishAbilityDescriptions[AbilityNames.PLAY_ATTACK] = "plays";
EnglishAbilityDescriptions[AbilityNames.DRAW_INSTRUCTIONS] = "draw Argument0";
EnglishAbilityDescriptions[AbilityNames.PLAY_INSTRUCTIONS] = "play";
EnglishAbilityDescriptions[AbilityNames.RESOLVE_EVENT] = "RESOLVE_EVENT";
EnglishAbilityDescriptions[AbilityNames.GAIN_BY_NAME] = "gain Argument0 (Argument1)";
EnglishAbilityDescriptions[AbilityNames.FISHING_VILLAGE_ABILITY] = "+1 Action, +$1";
EnglishAbilityDescriptions[AbilityNames.REMOVE_COST_REDUCTION] = "REMOVE_COST_REDUCTION";
EnglishAbilityDescriptions[AbilityNames.HAVEN_ABILITY] = "put Argument0 in your hand";
EnglishAbilityDescriptions[AbilityNames.SAVE_RETURN_TO_HAND] = "put Argument0 in your hand";
EnglishAbilityDescriptions[AbilityNames.CARAVAN_ABILITY] = "+1 Card";
EnglishAbilityDescriptions[AbilityNames.MERCHANT_SHIP_ABILITY] = "+$2";
EnglishAbilityDescriptions[AbilityNames.OUTPOST_ABILITY] = "[Outpost]";
EnglishAbilityDescriptions[AbilityNames.TACTICIAN_ABILITY] = "+5 Cards, +1 Action, +1 Buy";
EnglishAbilityDescriptions[AbilityNames.WHARF_ABILITY] = "+2 Cards, +1 Buy";
EnglishAbilityDescriptions[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = "+1 Card, put [Horse Traders] in your hand";
EnglishAbilityDescriptions[AbilityNames.DUCHESS_MAY_GAIN] = "gain [^nDuchess]";
EnglishAbilityDescriptions[AbilityNames.FOOLS_GOLD_TRASH] = "trash [Fools Gold] to topdeck [^nGold]";
EnglishAbilityDescriptions[AbilityNames.NOBLE_BRIGAND] = "try to rob opponents for [^nSilver] or [^nGold]";
EnglishAbilityDescriptions[AbilityNames.HAGGLER_ABILITY] = "gain a cheaper non-Victory card";
EnglishAbilityDescriptions[AbilityNames.INN_ABILITY] = "shuffle Actions from discard into your deck";
EnglishAbilityDescriptions[AbilityNames.MANDARIN] = "topdeck all your Treasures in play";
EnglishAbilityDescriptions[AbilityNames.FARMLAND] = "remodel a hand card by exactly $2";
EnglishAbilityDescriptions[AbilityNames.GAIN_CHEAPER] = "gain a cheaper card";
EnglishAbilityDescriptions[AbilityNames.GAIN_TWO_RUINS] = "gain 2 Ruins";
EnglishAbilityDescriptions[AbilityNames.HUNTING_GROUNDS] = "gain [^nDuchy] or 3 [^pEstate]";
EnglishAbilityDescriptions[AbilityNames.SQUIRE_GAIN_ATTACK] = "gain an Attack card";
EnglishAbilityDescriptions[AbilityNames.URCHIN_TRANSFORM] = "trash [Urchin] to gain [^nMercenary]";
EnglishAbilityDescriptions[AbilityNames.FEODUM_GAIN_SILVERS] = "gain 3 [^pSilver]";
EnglishAbilityDescriptions[AbilityNames.DOCTOR_OVERPAY] = "overpay to trash or discard cards from your deck";
EnglishAbilityDescriptions[AbilityNames.RESOLVE_DOCTOR_OVERPAY] = "trash or discard cards from your deck";
EnglishAbilityDescriptions[AbilityNames.HERALD_OVERPAY] = "overpay to topdeck cards from your discard";
EnglishAbilityDescriptions[AbilityNames.RESOLVE_HERALD_OVERPAY] = "topdeck cards from your discard";
EnglishAbilityDescriptions[AbilityNames.MASTERPIECE_OVERPAY] = "overpay to gain [^pSilver]";
EnglishAbilityDescriptions[AbilityNames.RESOLVE_MASTERPIECE_OVERPAY] = "gain [^pSilver]";
EnglishAbilityDescriptions[AbilityNames.STONEMASON_OVERPAY] = "overpay to gain 2 Action cards";
EnglishAbilityDescriptions[AbilityNames.RESOLVE_STONEMASON_OVERPAY] = "gain 2 Action cards";
EnglishAbilityDescriptions[AbilityNames.EXCHANGE] = "EXCHANGE";
EnglishAbilityDescriptions[AbilityNames.AMULET] = "+$1; or trash a card; or gain [^nSilver]";
EnglishAbilityDescriptions[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = "+$1";
EnglishAbilityDescriptions[AbilityNames.DUNGEON_ABILITY] = "+2 Cards, then discard 2 cards";
EnglishAbilityDescriptions[AbilityNames.GEAR_ABILITY] = "put Argument0 in your hand";
EnglishAbilityDescriptions[AbilityNames.MESSENGER_GAIN] = "all players gain the same $4 (or cheaper) card";
EnglishAbilityDescriptions[AbilityNames.BRIDGE_TROLL_ABILITY] = "+1 Buy";
EnglishAbilityDescriptions[AbilityNames.HAUNTED_WOODS_DURATION] = "+3 Cards";
EnglishAbilityDescriptions[AbilityNames.HAUNTED_WOODS_TOPDECK] = "topdeck all (opponent's [Haunted Woods])";
EnglishAbilityDescriptions[AbilityNames.LOST_CITY] = "+1 Card (opponent's [Lost City])";
EnglishAbilityDescriptions[AbilityNames.WINE_MERCHANT_DISCARD] = "discard [^nWine Merchant] from your Tavern mat";
EnglishAbilityDescriptions[AbilityNames.HIRELING] = "+1 Card";
EnglishAbilityDescriptions[AbilityNames.SWAMP_HAG_DURATION] = "+$3";
EnglishAbilityDescriptions[AbilityNames.TRASH_TOKEN_ABILITY] = "trash a card (Trashing Token)";
EnglishAbilityDescriptions[AbilityNames.EMPORIUM_GAIN_POINTS] = "+2 VP, if you have at least 5 Action cards in play";
EnglishAbilityDescriptions[AbilityNames.ROCKS_GAINS_SILVER] = "gain [^nSilver]";
EnglishAbilityDescriptions[AbilityNames.FORTUNE] = "gain [^nGold] per [Gladiator] in play";
EnglishAbilityDescriptions[AbilityNames.CRUMBLING_CASTLE] = "+1 VP and gain [^nSilver]";
EnglishAbilityDescriptions[AbilityNames.HAUNTED_CASTLE] = "gain [^nGold] and opponents must topdeck some cards";
EnglishAbilityDescriptions[AbilityNames.SPRAWLING_CASTLE] = "gain [^nDuchy] or 3 [^pEstate]";
EnglishAbilityDescriptions[AbilityNames.GRAND_CASTLE] = "+1 VP per Victory card in play or your hand";
EnglishAbilityDescriptions[AbilityNames.ENCHANTRESS_ABILITY] = "+2 Cards";
EnglishAbilityDescriptions[AbilityNames.ENCHANTRESS_CANTRIP] = "+1 Card, +1 Action";
EnglishAbilityDescriptions[AbilityNames.TEMPLE] = "take all VP from the [Temple] pile";
EnglishAbilityDescriptions[AbilityNames.VILLA] = "put [Villa] into your hand, +1 Action, possibly return to your Action phase";
EnglishAbilityDescriptions[AbilityNames.ARCHIVE_ABILITY] = "take one of Argument0";
EnglishAbilityDescriptions[AbilityNames.CAPITAL_TAKE_DEBT] = "take 6 debt, then you may pay off debt";
EnglishAbilityDescriptions[AbilityNames.CHARM] = "gain a different card with the same cost";
EnglishAbilityDescriptions[AbilityNames.FORUM] = "+1 Buy";
EnglishAbilityDescriptions[AbilityNames.HERMIT] = "trash this, gain [^nMadman]";
EnglishAbilityDescriptions[AbilityNames.DONATE] = "trash from your combined hand, deck and discard pile";
EnglishAbilityDescriptions[AbilityNames.TAX_RECEIVE] = "take the debt from the pile you bought from";
EnglishAbilityDescriptions[AbilityNames.DOMINATE] = "gain [^nProvince], then +9 VP if you did";
EnglishAbilityDescriptions[AbilityNames.VP_TO_AQUEDUCT] = "move 1 VP from Treasure pile to [Aqueduct]";
EnglishAbilityDescriptions[AbilityNames.VP_FROM_AQUEDUCT] = "take all VP from [Aqueduct]";
EnglishAbilityDescriptions[AbilityNames.ARENA_DISCARD_ACTION] = "discard an Action to take 2 VP from [Arena]";
EnglishAbilityDescriptions[AbilityNames.VP_FROM_BASILICA] = "take 2 VP from [Basilica] if you have $2 or more";
EnglishAbilityDescriptions[AbilityNames.VP_FROM_BATHS] = "take 2 VP from [Baths]";
EnglishAbilityDescriptions[AbilityNames.VP_FROM_BATTLEFIELD] = "take 2 VP from [Battlefield]";
EnglishAbilityDescriptions[AbilityNames.VP_FROM_COLONNADE] = "take 2 VP from [Colonnade] if you gained a card you have in play";
EnglishAbilityDescriptions[AbilityNames.VP_TO_DEFILED_SHRINE] = "move 1 VP from Action pile to [Defiled Shrine]";
EnglishAbilityDescriptions[AbilityNames.VP_FROM_DEFILED_SHRINE] = "take all VP from [Defiled Shrine]";
EnglishAbilityDescriptions[AbilityNames.VP_FROM_LABYRINTH] = "take 2 VP from [Labyrinth]";
EnglishAbilityDescriptions[AbilityNames.MOUNTAIN_PASS_BIDDING] = "bid debt to get +8 VP";
EnglishAbilityDescriptions[AbilityNames.TOMB] = "+1 VP";
EnglishAbilityDescriptions[AbilityNames.LIGHTHOUSE_COIN] = "+$1";
EnglishAbilityDescriptions[AbilityNames.REACTION] = "REACTION";
EnglishAbilityDescriptions[AbilityNames.CALL_GUIDE] = "call [Guide] to discard hand, +5 Cards";
EnglishAbilityDescriptions[AbilityNames.CALL_RATCATCHER] = "call [Ratcatcher] to trash a card";
EnglishAbilityDescriptions[AbilityNames.CALL_TRANSMOGRIFY] = "call [Transmogrify] to upgrade a card";
EnglishAbilityDescriptions[AbilityNames.CALL_TEACHER] = "call [Teacher] to move an Adventures token to an Action pile";
EnglishAbilityDescriptions[AbilityNames.CALL_DUPLICATE] = "call [Duplicate] to gain a copy of Argument0";
EnglishAbilityDescriptions[AbilityNames.CALL_COIN] = "call [Coin Of The Realm] for +2 Actions";
EnglishAbilityDescriptions[AbilityNames.CALL_ROYAL_CARRIAGE] = "call [Royal Carriage] to replay Argument0";
EnglishAbilityDescriptions[AbilityNames.START_OF_TURN] = "start your turn";
EnglishAbilityDescriptions[AbilityNames.START_BUY_PHASE] = "start your buy phase";
EnglishAbilityDescriptions[AbilityNames.END_OF_BUY_PHASE] = "end your buy phase";
EnglishAbilityDescriptions[AbilityNames.BETWEEN_TURNS] = "are between turns";
EnglishAbilityDescriptions[AbilityNames.END_OF_TURN] = "end your turn";
EnglishAbilityDescriptions[AbilityNames.SUMMON_PLAY] = "play Argument0";
EnglishAbilityDescriptions[AbilityNames.CARAVAN_GUARD_REACTION] = "play [Caravan Guard]";
EnglishAbilityDescriptions[AbilityNames.TRAVELLING_FAIR_REACTION] = "may topdeck Argument0";
EnglishAbilityDescriptions[AbilityNames.MOAT_REACTION] = "reveal [Moat] to block the attack";
EnglishAbilityDescriptions[AbilityNames.HORSE_TRADERS_REACTION] = "set aside [Horse Traders] for +1 Card next turn";
EnglishAbilityDescriptions[AbilityNames.BEGGAR_REACTION] = "discard [Beggar] to gain 2 [^pSilver], one onto your deck";
EnglishAbilityDescriptions[AbilityNames.MARKET_SQUARE_REACTION] = "discard [Market Square] to gain [^nGold]";
EnglishAbilityDescriptions[AbilityNames.TRADER_REACTION] = "Exchange Argument0 for a [^nSilver]";
EnglishAbilityDescriptions[AbilityNames.DIPLOMAT_REACTION] = "+2 Cards, discard 3 cards";
EnglishAbilityDescriptions[AbilityNames.TOPDECK_REACTION] = "topdeck Argument0";
EnglishAbilityDescriptions[AbilityNames.WATCHTOWER_TRASH_REACTION] = "trash Argument0";
EnglishAbilityDescriptions[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "topdeck Argument0";
EnglishAbilityDescriptions[AbilityNames.PRINCE_PLAY] = "play Argument0";
EnglishAbilityDescriptions[AbilityNames.TUNNEL_REVEAL] = "gain [^nGold]";
EnglishAbilityDescriptions[AbilityNames.POSSESSION_GAIN_INSTEAD] = "Argument0 gains Argument2 instead of Argument1";
EnglishAbilityDescriptions[AbilityNames.HOVEL_TRASH] = "trash [Hovel]";
EnglishAbilityDescriptions[AbilityNames.SAUNA_TRASH_ABILITY] = "Trash with Sauna";
EnglishAbilityDescriptions[AbilityNames.DELUDED_RETURNED] = "return [Deluded]";
EnglishAbilityDescriptions[AbilityNames.ENVIOUS_RETURNED] = "return [Envious]";
EnglishAbilityDescriptions[AbilityNames.RECEIVE_BOON] = "receive Argument0";
EnglishAbilityDescriptions[AbilityNames.COBBLER_ABILITY] = "gain a $4 (or cheaper) card to your hand";
EnglishAbilityDescriptions[AbilityNames.CRYPT_ABILITY] = "take one of Argument0";
EnglishAbilityDescriptions[AbilityNames.DEN_OF_SIN_ABILITY] = "+2 Cards";
EnglishAbilityDescriptions[AbilityNames.FAITHFUL_HOUND] = "set [Faithful Hound] aside";
EnglishAbilityDescriptions[AbilityNames.FAITHFUL_HOUND_RETURN] = "return [Faithful Hound] to your hand";
EnglishAbilityDescriptions[AbilityNames.GHOST_TOWN_ABILITY] = "+1 Card, +1 Action";
EnglishAbilityDescriptions[AbilityNames.GUARDIAN_COIN] = "+$1";
EnglishAbilityDescriptions[AbilityNames.RAIDER_ABILITY] = "+$3";
EnglishAbilityDescriptions[AbilityNames.SECRET_CAVE] = "+$3";
EnglishAbilityDescriptions[AbilityNames.CEMETERY] = "trash up to 4 cards";
EnglishAbilityDescriptions[AbilityNames.HAUNTED_MIRROR] = "discard an Action to gain [^nGhost]";
EnglishAbilityDescriptions[AbilityNames.GHOST] = "play Argument0 twice";
EnglishAbilityDescriptions[AbilityNames.REMOVE_FLIPPED_STATUS] = "unflip cards in the trash";
EnglishAbilityDescriptions[AbilityNames.LOST_IN_THE_WOODS] = "discard a card to receive a Boon";
EnglishAbilityDescriptions[AbilityNames.BLESSED_VILLAGE] = "take a Boon";
EnglishAbilityDescriptions[AbilityNames.FORTRESS] = "put [Fortress] into your hand";
EnglishAbilityDescriptions[AbilityNames.RECEIVE_HEX] = "receive a Hex";
EnglishAbilityDescriptions[AbilityNames.CHANGELING_MAY_EXCHANGE] = "exchange Argument0 for [^nChangeling]";
EnglishAbilityDescriptions[AbilityNames.TRADE_ROUTE_MOVE_TOKEN] = "Move coin token to the Trade Route Mat";
EnglishAbilityDescriptions[AbilityNames.DUCAT] = "trash [^nCopper]";
EnglishAbilityDescriptions[AbilityNames.CARGO_SHIP_SET_ASIDE] = "set Argument0 aside";
EnglishAbilityDescriptions[AbilityNames.CARGO_SHIP_RETURN] = "put Argument0 in your hand";
EnglishAbilityDescriptions[AbilityNames.IMPROVE] = "improve an Action in play";
EnglishAbilityDescriptions[AbilityNames.PRIEST] = "+$2";
EnglishAbilityDescriptions[AbilityNames.RESEARCH] = "put Argument0 in your hand";
EnglishAbilityDescriptions[AbilityNames.SILK_MERCHANT] = "+1 Coffers, +1 Villager";
EnglishAbilityDescriptions[AbilityNames.LACKEYS] = "+2 Villagers";
EnglishAbilityDescriptions[AbilityNames.SPICES] = "+2 Coffers";
EnglishAbilityDescriptions[AbilityNames.KEY] = "+$1";
EnglishAbilityDescriptions[AbilityNames.TREASURE_CHEST] = "gain [^nGold]";
EnglishAbilityDescriptions[AbilityNames.FLAG_BEARER] = "take [^nFlag]";
EnglishAbilityDescriptions[AbilityNames.CATHEDRAL] = "trash a card";
EnglishAbilityDescriptions[AbilityNames.CITY_GATE] = "+1 Card, then topdeck";
EnglishAbilityDescriptions[AbilityNames.PAGEANT] = "pay $1 for +1 Coffers";
EnglishAbilityDescriptions[AbilityNames.SEWERS_MAY_TRASH] = "trash another Card";
EnglishAbilityDescriptions[AbilityNames.EXPLORATION] = "+1 Coffers, +1 Villager";
EnglishAbilityDescriptions[AbilityNames.FAIR] = "+1 buy";
EnglishAbilityDescriptions[AbilityNames.SILOS] = "discard Coppers for cards";
EnglishAbilityDescriptions[AbilityNames.ACADEMY] = "+1 Villager";
EnglishAbilityDescriptions[AbilityNames.GUILDHALL] = "+1 Coffers";
EnglishAbilityDescriptions[AbilityNames.PIAZZA] = "reveal and play if Action";
EnglishAbilityDescriptions[AbilityNames.BARRACKS] = "+1 Action";
EnglishAbilityDescriptions[AbilityNames.CROP_ROTATION] = "discard Victory for +2 Cards";
EnglishAbilityDescriptions[AbilityNames.INNOVATION] = "play Argument0";
EnglishAbilityDescriptions[AbilityNames.CITADEL] = "play Argument0 again";
EnglishAbilityDescriptions[AbilityNames.SINISTER_PLOT] = "draw or add a token";
EnglishAbilityDescriptions[AbilityNames.ENCAMPMENT_RETURNED] = "return [Encampment] to the Supply";
EnglishAbilityDescriptions[AbilityNames.EMBARGO_GAIN_CURSE] = "gain [^nCurse]";
EnglishAbilityDescriptions[AbilityNames.CAPTAIN] = "Play an action from the supply";
EnglishAbilityDescriptions[AbilityNames.CHURCH] = "put Argument0 in your hand, then you may trash";
EnglishAbilityDescriptions[AbilityNames.POSSESSION_SETS_ASIDE] = "set Argument0 aside";
EnglishAbilityDescriptions[AbilityNames.MINT] = "Trash all your Treasures in play";
EnglishAbilityDescriptions[AbilityNames.SLEIGH_TOPDECK_REACTION] = "Discard [Sleigh] to topdeck Argument0";
EnglishAbilityDescriptions[AbilityNames.SLEIGH_PUT_IN_HAND_REACTION] = "Discard [Sleigh] to put Argument0 in hand";
EnglishAbilityDescriptions[AbilityNames.BLACK_CAT_REACTION] = "Play [Black Cat]";
EnglishAbilityDescriptions[AbilityNames.DISCARD_FROM_EXILE] = "Discard Argument0 from Exile.";
EnglishAbilityDescriptions[AbilityNames.EXILE_BY_NAME] = "Exile Argument0";
EnglishAbilityDescriptions[AbilityNames.SHEEPDOG_REACTION] = "Play [Sheepdog]";
EnglishAbilityDescriptions[AbilityNames.CAVALRY] = "+2 Cards, +1 Buy & return to Action phase.";
EnglishAbilityDescriptions[AbilityNames.HOSTELRY] = "Discard Treasures for Horses";
EnglishAbilityDescriptions[AbilityNames.VILLAGE_GREEN_VILLAGE] = "+1 Card, +2 Actions";
EnglishAbilityDescriptions[AbilityNames.VILLAGE_GREEN_PLAY] = "Play [Village Green]";
EnglishAbilityDescriptions[AbilityNames.BARGE] = "+3 Cards, +1 Buy";
EnglishAbilityDescriptions[AbilityNames.FALCONER_REACTION] = "Gain a cheaper Card";
EnglishAbilityDescriptions[AbilityNames.GATEKEEPER_EXILE] = "Exile Argument0";
EnglishAbilityDescriptions[AbilityNames.GATEKEEPER_DURATION] = "+3 Coins";
EnglishAbilityDescriptions[AbilityNames.LIVERY] = "Gain a Horse";
EnglishAbilityDescriptions[AbilityNames.MASTERMIND] = "You may play a card thrice";
EnglishAbilityDescriptions[AbilityNames.DELAY_PLAY] = "Play Argument0";
EnglishAbilityDescriptions[AbilityNames.INVEST] = "INVEST ABILITY (never shown to user)";
EnglishAbilityDescriptions[AbilityNames.REAP_PLAY] = "Play the set aside [Gold]";
EnglishAbilityDescriptions[AbilityNames.PLUS_CARD_TOKEN] = "+Card from the token";
EnglishAbilityDescriptions[AbilityNames.PLUS_ACTION_TOKEN] = "+Action from the token";
EnglishAbilityDescriptions[AbilityNames.PLUS_BUY_TOKEN] = "+Buy from the token";
EnglishAbilityDescriptions[AbilityNames.PLUS_COIN_TOKEN] = "+Coin from the token";
EnglishAbilityDescriptions[AbilityNames.KILN] = "gain a copy of Argument0";
EnglishAbilityDescriptions[AbilityNames.CHAMELEON_RESOLUTION] = "get +Cards instead of +Coins";
EnglishAbilityDescriptions[AbilityNames.RETURN_MINUS_COIN_TOKEN] = "Return the -Coin token";
EnglishAbilityDescriptions[AbilityNames.TURTLE_PLAY] = "Play the set aside Argument0";
EnglishAbilityDescriptions[AbilityNames.GIVE_PLUS_CARDS] = "+Card";
EnglishAbilityDescriptions[AbilityNames.GIVE_PLUS_ACTIONS] = "+Action";
EnglishAbilityDescriptions[AbilityNames.GIVE_PLUS_BUYS] = "+Buy";
EnglishAbilityDescriptions[AbilityNames.GIVE_PLUS_COINS] = "+Coin";
EnglishAbilityDescriptions[AbilityNames.MONKEY_NEXT_TURN_ABILITY] = "+1 Card";
EnglishAbilityDescriptions[AbilityNames.BLOCKADE_ABILITY] = "put Argument0 in your hand";
EnglishAbilityDescriptions[AbilityNames.PIRATE_ABILITY] = "gain a Treasure to your hand";
EnglishAbilityDescriptions[AbilityNames.PIRATE_REACTION] = "Play [Pirate]";
EnglishAbilityDescriptions[AbilityNames.CORSAIR_NEXT_TURN_ABILITY] = "+1 Card";
EnglishAbilityDescriptions[AbilityNames.CORSAIR_TRASH] = "Trash Argument0";
EnglishAbilityDescriptions[AbilityNames.SAILOR_NEXT_TURN] = "+$2, and you may trash";
EnglishAbilityDescriptions[AbilityNames.SAILOR_REACTION] = "Play Argument0";
EnglishAbilityDescriptions[AbilityNames.SKIRMISHER] = "other players discard down to 3";
EnglishAbilityDescriptions[AbilityNames.ENCHANTRESS_REPLACE_ABILITY] = "Replacement by Enchantress";
EnglishAbilityDescriptions[AbilityNames.HIGHWAYMAN_NEXT_TURN_ABILITY] = "Discard Highwayman, +3 Cards";
EnglishAbilityDescriptions[AbilityNames.HIGHWAYMAN_REPLACE_ABILITY] = "Replacement by Highwayman";
EnglishAbilityDescriptions[AbilityNames.HIGHWAYMAN_NULLIFIED] = "Play Nullified";
EnglishAbilityDescriptions[AbilityNames.LEAGUE_OF_BANKERS] = "+$1 per 4 Favors";
EnglishAbilityDescriptions[AbilityNames.WOODWORKERS_GUILD] = "Spend a Favor and trash an Action to gain an Action.";
EnglishAbilityDescriptions[AbilityNames.CRAFTERS_GUILD] = "Spend 2 Favors to gain";
EnglishAbilityDescriptions[AbilityNames.CAVE_DWELLERS] = "Spend a Favor to discard then draw";
EnglishAbilityDescriptions[AbilityNames.DESERT_GUIDES] = "Spend a Favor to discard and draw";
EnglishAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_CARD] = "Spend a Favor for +1 Card";
EnglishAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_ACTION] = "Spend a Favor for +1 Action";
EnglishAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_BUY] = "Spend a Favor for +1 Buy";
EnglishAbilityDescriptions[AbilityNames.SYCOPHANT] = "+2 Favors";
EnglishAbilityDescriptions[AbilityNames.IMPORTER_ABILITY] = "Gain a card up to 5";
EnglishAbilityDescriptions[AbilityNames.CONTRACT_ABILITY] = "play Argument0";
EnglishAbilityDescriptions[AbilityNames.MARKET_TOWNS] = "Spend a Favor to play an Action";
EnglishAbilityDescriptions[AbilityNames.TRAPPERS_LODGE] = "Spend a Favor to topdeck Argument0";
EnglishAbilityDescriptions[AbilityNames.GUILDMASTER] = "+1 Favor";
EnglishAbilityDescriptions[AbilityNames.ISLAND_FOLK] = "Spend 5 Favors for an extra Turn";
EnglishAbilityDescriptions[AbilityNames.GANG_OF_PICKPOCKETS] = "Discard down to 4 unless you spend a Favor";
EnglishAbilityDescriptions[AbilityNames.COASTAL_HAVEN] = "Keep cards in hand for next turn";
EnglishAbilityDescriptions[AbilityNames.CITY_STATE] = "Spend 2 Favors to Play Argument0";
EnglishAbilityDescriptions[AbilityNames.CITY_STATE_PLAY] = "---";
EnglishAbilityDescriptions[AbilityNames.CIRCLE_OF_WITCHES] = "Spend 3 Favors to give opponents a Curse";
EnglishAbilityDescriptions[AbilityNames.ROYAL_GALLEY_SET_ASIDE] = "Set aside with Royal Galley";
EnglishAbilityDescriptions[AbilityNames.ROYAL_GALLEY_PLAY] = "Play Argument0";
EnglishAbilityDescriptions[AbilityNames.STRONGHOLD_ABILITY] = "+3 Cards";
EnglishAbilityDescriptions[AbilityNames.MOUNTAIN_FOLK] = "Spend 5 Favors to draw 3 Cards";
EnglishAbilityDescriptions[AbilityNames.PEACEFUL_CULT] = "Spend Favors to trash";
EnglishAbilityDescriptions[AbilityNames.FELLOWSHIP_OF_SCRIBES] = "Spend a Favor to draw a Card";
EnglishAbilityDescriptions[AbilityNames.LEAGUE_OF_SHOPKEEPERS] = "Shopkeeper";
EnglishAbilityDescriptions[AbilityNames.TERRITORY_GAIN] = "Gain a Gold per empty supply pile";
EnglishAbilityDescriptions[AbilityNames.WARLORD_ABILITY] = "+2 Cards";
EnglishAbilityDescriptions[AbilityNames.GARRISON_ADD_TOKEN] = "Add a token";
EnglishAbilityDescriptions[AbilityNames.GARRISON_REMOVE_ABILITY] = "+Argument0 Cards";
EnglishAbilityDescriptions[AbilityNames.CONJURER] = "Put this into your Hand";
EnglishAbilityDescriptions[AbilityNames.LICH] = "Discard and Gain from trash";
EnglishAbilityDescriptions[AbilityNames.GALLERIA] = "+1 Buy";
EnglishAbilityDescriptions[AbilityNames.ARCHITECTS_GUILD] = "Gain a cheaper non-Victory";
EnglishAbilityDescriptions[AbilityNames.FAMILY_OF_INVENTORS] = "Make a pile cheaper";
EnglishAbilityDescriptions[AbilityNames.FOREST_DWELLERS] = "Scout 3 cards";
EnglishAbilityDescriptions[AbilityNames.ASTROLABE_ABILITY] = "+$1, +1 Buy";
EnglishAbilityDescriptions[AbilityNames.TIDE_POOLS_ABILITY] = "Discard 2 Cards";
EnglishAbilityDescriptions[AbilityNames.SEA_WITCH_ABILITY] = "+2 Cards, then discard 2 cards";
EnglishAbilityDescriptions[AbilityNames.CLERK_ABILITY] = "Play [Clerk]";
EnglishAbilityDescriptions[AbilityNames.GUARD_DOG_REACTION] = "Play [Guard Dog]";
EnglishAbilityDescriptions[AbilityNames.BERSERKER] = "Play [Berserker]";
EnglishAbilityDescriptions[AbilityNames.NOMADS] = "+2 Coins";
EnglishAbilityDescriptions[AbilityNames.TRAIL_REACTION] = "Play [Trail]";
EnglishAbilityDescriptions[AbilityNames.WEAVER_PLAY] = "Play [Weaver]";
EnglishAbilityDescriptions[AbilityNames.SOUK] = "Trash up to 2";
EnglishAbilityDescriptions[AbilityNames.CAULDRON_CURSING] = "Each other player gains a Curse";
EnglishAbilityDescriptions[AbilityNames.MARCHLAND] = "+1 Buy, Discard cards for +$1 each";
EnglishAbilityDescriptions[AbilityNames.UNUSED_ABILITY_2] = "unused";
EnglishAbilityDescriptions[AbilityNames.UNUSED_ABILITY_2] = "unused";
EnglishAbilityDescriptions[AbilityNames.UNUSED_ABILITY_2] = "unused";
EnglishAbilityDescriptions[AbilityNames.UNUSED_ABILITY_2] = "unused";
EnglishAbilityDescriptions[AbilityNames.TREASURY] = "Topdeck [Treasury]";
EnglishAbilityDescriptions[AbilityNames.MERCHANT_GUILD] = "Get Coffers per gained card";
EnglishAbilityDescriptions[AbilityNames.ALCHEMIST_TOPDECK] = "Topdeck [Alchemist]";
EnglishAbilityDescriptions[AbilityNames.HERBALIST_TOPDECK] = "Topdeck Argument0 with [Herbalist]";
EnglishAbilityDescriptions[AbilityNames.SCHEME_TOPDECK] = "Topdeck Argument0 with [Scheme]";
EnglishAbilityDescriptions[AbilityNames.FROG_TOPDECK] = "Topdeck Argument0";
EnglishAbilityDescriptions[AbilityNames.MERCHANT_CAMP_TOPDECK] = "Topdeck [Merchant Camp]";
EnglishAbilityDescriptions[AbilityNames.WALLED_VILLAGE_TOPDECK] = "Topdeck [Walled Village]";
EnglishAbilityDescriptions[AbilityNames.TENT_TOPDECK] = "Topdeck [Tent]";
EnglishAbilityDescriptions[AbilityNames.HORN_TOPDECK] = "Topdeck [Border Guard] with [Horn]";
EnglishAbilityDescriptions[AbilityNames.PAGE_EXCHANGE] = "Exchange [Page] for a [Treasure Hunter]";
EnglishAbilityDescriptions[AbilityNames.TREASURE_HUNTER_EXCHANGE] = "Exchange [Treasure Hunter] for a [Warrior]";
EnglishAbilityDescriptions[AbilityNames.WARRIOR_EXCHANGE] = "Exchange [Warrior] for a [Hero]";
EnglishAbilityDescriptions[AbilityNames.HERO_EXCHANGE] = "Exchange [Hero] for a [Champion]";
EnglishAbilityDescriptions[AbilityNames.PEASANT_EXCHANGE] = "Exchange [Peasant] for a [Soldier]";
EnglishAbilityDescriptions[AbilityNames.SOLDIER_EXCHANGE] = "Exchange [Soldier] for a [Fugitive]";
EnglishAbilityDescriptions[AbilityNames.FUGITIVE_EXCHANGE] = "Exchange [Fugitive] for a [Disciple]";
EnglishAbilityDescriptions[AbilityNames.DISCIPLE_EXCHANGE] = "Exchange [Disciple] for a [Teacher]";
EnglishAbilityDescriptions[AbilityNames.CAGE_TRASH] = "Trash [Cage]";
EnglishAbilityDescriptions[AbilityNames.CAGE_PUT_IN_HAND] = "Put Argument0 in Hand";
EnglishAbilityDescriptions[AbilityNames.GROTTO] = "Discard Argument0, then draw";
EnglishAbilityDescriptions[AbilityNames.JEWELLED_EGG_GAIN_LOOT] = "Gain a Loot.";
EnglishAbilityDescriptions[AbilityNames.SEARCH] = "Trash [Search], and gain a Loot.";
EnglishAbilityDescriptions[AbilityNames.SHAMAN] = "Gain a card from the trash costing up to 6.";
EnglishAbilityDescriptions[AbilityNames.SECLUDED_SHRINE] = "Trash up to 2 cards";
EnglishAbilityDescriptions[AbilityNames.SIREN_GAIN] = "Trash an Action, or [Siren]";
EnglishAbilityDescriptions[AbilityNames.SIREN_DURATION] = "Draw up to 8 cards";
EnglishAbilityDescriptions[AbilityNames.STOWAWAY_DRAW] = "Draw 2 cards";
EnglishAbilityDescriptions[AbilityNames.STOWAWAY_REACTION] = "Play [Stowaway]";
EnglishAbilityDescriptions[AbilityNames.TASKMASTER] = "+1 Action, +1 Coin";
EnglishAbilityDescriptions[AbilityNames.ABUNDANCE] = "+1 Buy, +3 Coins";
EnglishAbilityDescriptions[AbilityNames.CABIN_BOY] = "+2 Coins, or trash [Cabin Boy] to gain a Duration.";
EnglishAbilityDescriptions[AbilityNames.FLAGSHIP_ACTIVATE] = "";
EnglishAbilityDescriptions[AbilityNames.FLAGSHIP_REPLAY] = "Replay Argument0";
EnglishAbilityDescriptions[AbilityNames.GONDOLA_DURATION] = "+2 Coins";
EnglishAbilityDescriptions[AbilityNames.GONDOLA_PLAY] = "Play an Action card";
EnglishAbilityDescriptions[AbilityNames.HARBOR_VILLAGE] = "+1 Coin";
EnglishAbilityDescriptions[AbilityNames.LANDING_PARTY] = "Topdeck [Landing Party]";
EnglishAbilityDescriptions[AbilityNames.MAPMAKER] = "Play [Mapmaker]";
EnglishAbilityDescriptions[AbilityNames.ROPE] = "+1 Card, then you may trash a card";
EnglishAbilityDescriptions[AbilityNames.BURIED_TREASURE_DURATION] = "+1 Buy, +3 Coins";
EnglishAbilityDescriptions[AbilityNames.BURIED_TREASURE_PLAY] = "Play [Buried Treasure]";
EnglishAbilityDescriptions[AbilityNames.CREW_DURATION] = "Topdeck [Crew]";
EnglishAbilityDescriptions[AbilityNames.CUTTHROAT_GAIN] = "Gain a Loot";
EnglishAbilityDescriptions[AbilityNames.ENLARGE] = "Trash a card, to gain one costing up to 2 more.";
EnglishAbilityDescriptions[AbilityNames.FRIGATE_DURATION] = "";
EnglishAbilityDescriptions[AbilityNames.FRIGATE_DISCARD] = "Discard down to 4 cards in hand.";
EnglishAbilityDescriptions[AbilityNames.LONGSHIP_DURATION] = "+2 Cards";
EnglishAbilityDescriptions[AbilityNames.MINING_ROAD] = "Play Argument0";
EnglishAbilityDescriptions[AbilityNames.QUARTERMASTER] = "Resolve [Quartermaster]";
EnglishAbilityDescriptions[AbilityNames.TRICKSTER_REACTION] = "Set aside a Treasure";
EnglishAbilityDescriptions[AbilityNames.TRICKSTER_RETURN_TO_HAND] = "Put Argument0 in hand";
EnglishAbilityDescriptions[AbilityNames.WEALTHY_VILLAGE] = "Gain a Loot";
EnglishAbilityDescriptions[AbilityNames.DELIVER_SET_ASIDE] = "Set aside Argument0";
EnglishAbilityDescriptions[AbilityNames.DELIVER_RETURN] = "Put Argument0 in hand";
EnglishAbilityDescriptions[AbilityNames.RUSH] = "Play Argument0";
EnglishAbilityDescriptions[AbilityNames.MIRROR] = "Gain another Argument0";
EnglishAbilityDescriptions[AbilityNames.PREPARE] = "Play a set-aside card";
EnglishAbilityDescriptions[AbilityNames.AMPHORA] = "+1 Buy, +3 Coins";
EnglishAbilityDescriptions[AbilityNames.DOUBLOONS] = "Gain a Gold";
EnglishAbilityDescriptions[AbilityNames.ENDLESS_CHALICE] = "+1 Coin, +1 Buy";
EnglishAbilityDescriptions[AbilityNames.FIGUREHEAD] = "+2 Cards";
EnglishAbilityDescriptions[AbilityNames.JEWELS] = "Put [Jewels] on the bottom of your deck.";
EnglishAbilityDescriptions[AbilityNames.PUZZLE_BOX_RETURN] = "Put Argument0 in hand";
EnglishAbilityDescriptions[AbilityNames.SHIELD_REACTION] = "Reveal [Shield] to be unaffected by the attack.";
EnglishAbilityDescriptions[AbilityNames.SPELL_SCROLL] = "Play Argument0";
EnglishAbilityDescriptions[AbilityNames.CURSED_GAIN] = "Gain a Loot and a Curse";
EnglishAbilityDescriptions[AbilityNames.FAWNING_GAIN] = "Gain a [Fawning] card";
EnglishAbilityDescriptions[AbilityNames.SCHEDULER] = "[Friendly] (seeing this is a bug!)";
EnglishAbilityDescriptions[AbilityNames.FRIENDLY_ABILITY] = "Discard a [Friendly] card to gain another";
EnglishAbilityDescriptions[AbilityNames.HASTY_SET_ASIDE] = "Set Argument0 aside";
EnglishAbilityDescriptions[AbilityNames.HASTY_PLAY] = "Play Argument0";
EnglishAbilityDescriptions[AbilityNames.INHERITED] = "Replace a starting card.";
EnglishAbilityDescriptions[AbilityNames.INSPIRING] = "Play a card that you don't have in play.";
EnglishAbilityDescriptions[AbilityNames.NEARBY] = "+1 Buy";
EnglishAbilityDescriptions[AbilityNames.PATIENT_SCHEDULER] = "[Patient] (seeing this is a bug!)";
EnglishAbilityDescriptions[AbilityNames.PATIENT_SET_ASIDE] = "Set aside [Patient] cards.";
EnglishAbilityDescriptions[AbilityNames.PATIENT_PLAY] = "Play Argument0";
EnglishAbilityDescriptions[AbilityNames.PIOUS] = "Trash a card";
EnglishAbilityDescriptions[AbilityNames.RECKLESS_REPEAT] = "Follow the instructions of Argument0 again.";
EnglishAbilityDescriptions[AbilityNames.RECKLESS_RETURN] = "Return Argument0 to its pile.";
EnglishAbilityDescriptions[AbilityNames.RICH_GAIN] = "Gain [^nSilver]";
EnglishAbilityDescriptions[AbilityNames.SHY] = "Discard a [Shy] card for +2 Cards";
EnglishAbilityDescriptions[AbilityNames.TIRELESS_SET_ASIDE] = "Set aside Argument0";
EnglishAbilityDescriptions[AbilityNames.TIRELESS_RETURN] = "Topdeck Argument0";
EnglishAbilityDescriptions[AbilityNames.STAR_CHART] = "Topdeck";
EnglishAbilityDescriptions[AbilityNames.ORDER_OF_ASTROLOGERS] = "Topdeck";
EnglishAbilityDescriptions[AbilityNames.ORDER_OF_MASONS] = "Discard";
EnglishAbilityDescriptions[AbilityNames.FATED] = "Topdeck and/or Discard";
EnglishAbilityDescriptions[AbilityNames.AVOID] = "Discard";
EnglishAbilityDescriptions[AbilityNames.HERBALIST_ASK_FOR_DISCARD] = "Topdeck a Treasure";
EnglishAbilityDescriptions[AbilityNames.SCHEME_ASK_FOR_DISCARD] = "Topdeck an Action";
EnglishAbilityDescriptions[AbilityNames.TRICKSTER_ASK_FOR_DISCARD] = "Set aside a Treasure";
EnglishAbilityDescriptions[AbilityNames.FARMHANDS_SET_ASIDE] = "Set aside an Action or Treasure";
EnglishAbilityDescriptions[AbilityNames.FARMHANDS_PLAY] = "play Argument0";
EnglishAbilityDescriptions[AbilityNames.JOUST_DISCARD] = "Discard a Province";
EnglishAbilityDescriptions[AbilityNames.INFIRMARY_OVERPAY] = "overpay to play [Infirmary]";
EnglishAbilityDescriptions[AbilityNames.RESOLVE_INFIRMARY_OVERPAY] = "play [Infirmary]";
EnglishAbilityDescriptions[AbilityNames.DRAW_FROM_FOOTPAD] = "Draw a card";
EnglishAbilityDescriptions[AbilityNames.FARRIER_OVERPAY] = "overpay for +Cards at end of turn.";
EnglishAbilityDescriptions[AbilityNames.RESOLVE_FARRIER_OVERPAY] = "resolve [Farrier] overpay";
EnglishAbilityDescriptions[AbilityNames.DRAW_FROM_SQUIRREL] = "Draw 2 extra Cards";
EnglishAbilityDescriptions[AbilityNames.DRAW_FROM_RIVER] = "Draw 1 extra Cards";
EnglishAbilityDescriptions[AbilityNames.DRAW_FROM_FARRIER] = "Draw Argument0 extra Cards";
EnglishAbilityDescriptions[AbilityNames.FERRYMAN] = "Gain Argument0";
EnglishAbilityDescriptions[AbilityNames.BIDING_TIME_SET_ASIDE_HAND] = "Set Cards in hand Aside";
EnglishAbilityDescriptions[AbilityNames.BIDING_TIME_RETURN_TO_HAND] = "Return cards to hand";
EnglishAbilityDescriptions[AbilityNames.ENLIGHTENMENT_CANTRIP] = "+1 Card, +1 Action";
EnglishAbilityDescriptions[AbilityNames.ENLIGHTENMENT_REPLACE_ABILITY] = "Replacement by Enlightenment";
EnglishAbilityDescriptions[AbilityNames.GOOD_HARVEST] = "+1 Buy +1 Coin";
EnglishAbilityDescriptions[AbilityNames.HARSH_WINTER] = "Take Debt or put it on the pile";
EnglishAbilityDescriptions[AbilityNames.KIND_EMPEROR] = "Gain an Action to your hand";
EnglishAbilityDescriptions[AbilityNames.PANIC_BUYS] = "+2 Buys";
EnglishAbilityDescriptions[AbilityNames.PANIC_RETURN] = "Return Argument0 to its pile";
EnglishAbilityDescriptions[AbilityNames.PROGRESS] = "Put Argument0 onto your deck";
EnglishAbilityDescriptions[AbilityNames.RAPID_EXPANSION_SET_ASIDE] = "Set Argument0 aside";
EnglishAbilityDescriptions[AbilityNames.RAPID_EXPANSION_PLAY_LATER] = "Play Argument0";
EnglishAbilityDescriptions[AbilityNames.SICKNESS] = "Curse onto your deck or discard 3 cards";
EnglishAbilityDescriptions[AbilityNames.RIVERBOAT] = "Play Argument0";
EnglishAbilityDescriptions[AbilityNames.RIVER_SHRINE] = "Gain a card up to 4 Coins";
EnglishAbilityDescriptions[AbilityNames.SAMURAI] = "+1 Coin";
EnglishAbilityDescriptions[AbilityNames.DAIMYO_REPLAY] = "Replay Argument0";
EnglishAbilityDescriptions[AbilityNames.FORESIGHT_RETURN_TO_HAND] = EnglishAbilityDescriptions[AbilityNames.SAVE_RETURN_TO_HAND];