"use strict";

var DutchAutoPlays = {};

DutchAutoPlays[AutoPlays.MOAT] = ["Off", "Always reveal"];
DutchAutoPlays[AutoPlays.CROWN] = ["Off", "Auto-end actions"];
DutchAutoPlays[AutoPlays.CHANGELING] = ["Off", "Smart ask", "Never exchange"];
DutchAutoPlays[AutoPlays.URCHIN] = ["Off", "Never trash"];
DutchAutoPlays[AutoPlays.MOUNTEBANK] = ["Off", "Always discard"];
DutchAutoPlays[AutoPlays.SETTLERS] = ["Off", "Always take Copper"];
DutchAutoPlays[AutoPlays.BUSTLING_VILLAGE] = ["Off", "Always take Settlers"];
DutchAutoPlays[AutoPlays.TOURNAMENT] = ["Off", "Always reveal Province"];
DutchAutoPlays[AutoPlays.PEASANT] = ["Off", "Default exchange", "Default keep"];
DutchAutoPlays[AutoPlays.SOLDIER] = ["Off", "Default exchange", "Default keep"];
DutchAutoPlays[AutoPlays.FUGITIVE] = ["Off", "Default exchange", "Default keep"];
DutchAutoPlays[AutoPlays.DISCIPLE] = ["Off", "Default exchange", "Default keep"];
DutchAutoPlays[AutoPlays.PAGE] = ["Off", "Default exchange", "Default keep"];
DutchAutoPlays[AutoPlays.TREASURE_HUNTER] = ["Off", "Default exchange", "Default keep"];
DutchAutoPlays[AutoPlays.WARRIOR] = ["Off", "Default exchange", "Default keep"];
DutchAutoPlays[AutoPlays.HERO] = ["Off", "Default exchange", "Default keep"];
DutchAutoPlays[AutoPlays.FAITHFUL_HOUND] = ["Off", "Always set aside"];
DutchAutoPlays[AutoPlays.YOUNG_WITCH] = ["Off", "Always reveal bane"];
DutchAutoPlays[AutoPlays.TREASURY] = ["Off", "Always topdeck"];
DutchAutoPlays[AutoPlays.ALCHEMIST] = ["Off", "Always topdeck"];
DutchAutoPlays[AutoPlays.WALLED_VILLAGE] = ["Off", "Always topdeck"];
DutchAutoPlays[AutoPlays.HERBALIST] = ["Off", "Any order"];
DutchAutoPlays[AutoPlays.ROYAL_SEAL] = ["Off", "Always topdeck", "Never topdeck"];
DutchAutoPlays[AutoPlays.COIN_OF_THE_REALM] = ["Off", "Smart ask"];