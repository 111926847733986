"use strict";

var GermanEnvironment = new function () {
    this.enter = function () {};
    this.leave = function () {};
    this.strlen = EnglishEnvironment.strlen;

    this.getPileName = EnglishEnvironment.getPileName;
    this.pileNameSeparator = "-";

    this.phraseCollection_Or = function (phrases) {
        return EnglishEnvironment.phraseCollection(phrases, GermanPhrases[Phrases.COMMA], GermanPhrases[Phrases.OR]);
    };

    this.cardComparator = EnglishEnvironment.cardComparator;

    this.determineFontStyle = function (isLandscape, lines) {
        var estimateLineLength = function estimateLineLength(line) {
            return line.replace(/{!?\d+}/, function (m) {
                return m + "xx";
            }).replace(/\[!?\d+\]/, "xx").length;
        };
        var maxLineLength = lines.map(estimateLineLength).reduce(function (a, b) {
            return a > b ? a : b;
        });
        var cutoffs = isLandscape ? [45, 55, 62, 70, 99] : [20, 27, 31, 35, 42, 99];
        var styles = ["huge-font", "large-font", "medium-font", "small-font", "tiny-font", "tiniest-font"];
        var index = cutoffs.findIndex(function (e) {
            return maxLineLength < e;
        });
        if (index === -1) index = 5;
        return styles[index];
    };
}();