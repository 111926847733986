"use strict";

var REQUIRED_RATING_RANGE = 5;
var publicAutomatch = void 0;

webclient.service('automatch', ['$rootScope', 'serverMessenger', 'preferences', 'cardService', function ($rootScope, serverMessenger, preferences, cardService) {
    var self = this;
    var scope = $rootScope.$new(true);
    var matchingCriteria = preferences.getUserPrefValue(UserPrefIds.MATCHING_CRITERIA);
    var automatchProgress = new AutomatchProgress(false, 0, 0, 0, 0, 0, 10, 2, 0, 50, 50, []);

    if (isDebug) publicAutomatch = self;

    self.syncMatchCriteria = function () {
        var send = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        self.searchesBot = matchingCriteria.searchesBot;
        self.searchesHuman = matchingCriteria.searchesHuman;
        self.maxRatingDifference = matchingCriteria.maxRatingDifference;
        self.minRatingDifference = matchingCriteria.minRatingDifference;
        self.cardPoolLevel = preferences.getUserPrefValue(UserPrefIds.CARD_POOL_LEVEL);
        self.minCardPoolLevel = preferences.getUserPrefValue(UserPrefIds.MIN_CARD_POOL_LEVEL);
        self.matchingCriteria = matchingCriteria;
        self.automatchPreferences = matchingCriteria.automatchPreferences;
        self.timeInQueue = matchingCriteria.timeInQueue;
        self.myLevel2P = automatchProgress.myLevel2P;
        self.myLevel3P = automatchProgress.myLevel3P;
        if (send) preferences.setUserPrefValue(UserPrefIds.MATCHING_CRITERIA, matchingCriteria);
    };
    self.syncMatchCriteria();

    self.getLevel2P = function () {
        return automatchProgress.myLevel2P;
    };
    self.getLevel3P = function () {
        return automatchProgress.myLevel3P;
    };

    self.iAmSearching = function () {
        return automatchProgress.iAmSearching;
    };

    self.getAutomatchCandidates = function () {
        if (automatchProgress.feedback.length === 0) {
            return getPhrase(Phrases.NOBODY_ELSE_IN_QUEUE);
        } else {
            return automatchProgress.feedback.map(function (n) {
                return n.name;
            }).join(", ");
        }
    };

    self.toggleAutomatchPreference = function (automatchQuestion) {
        matchingCriteria.toggle(automatchQuestion);
        self.syncCriteria();
    };

    self.updateMaxRatingDifference = function () {
        var newValue = parseInt(self.maxRatingDifference);
        if (newValue >= -100 && newValue <= 100) {
            matchingCriteria.maxRatingDifference = newValue;
            matchingCriteria.minRatingDifference = Math.min(matchingCriteria.minRatingDifference, matchingCriteria.maxRatingDifference - REQUIRED_RATING_RANGE);
            self.syncCriteria();
        }
    };

    self.changeMaxRating = function (shift) {
        var postShift = self.maxRatingDifference + shift;
        if (postShift < 100 && postShift > -100) {
            self.maxRatingDifference += shift;
            self.updateMaxRatingDifference();
        }
    };

    self.updateMinRatingDifference = function () {
        var newValue = parseInt(self.minRatingDifference);
        if (newValue >= -100 && newValue <= 100) {
            matchingCriteria.minRatingDifference = newValue;
            matchingCriteria.maxRatingDifference = Math.max(matchingCriteria.maxRatingDifference, matchingCriteria.minRatingDifference + REQUIRED_RATING_RANGE);
            self.syncCriteria();
        }
    };

    self.changeMinRating = function (shift) {
        var postShift = self.minRatingDifference + shift;
        if (postShift < 100 && postShift > -100) {
            self.minRatingDifference += shift;
            self.updateMinRatingDifference();
        }
    };

    self.changeCardPoolLevel = function (shift) {
        var postShift = parseInt(self.cardPoolLevel) + shift;
        if (postShift <= 10 && postShift > 0) {
            self.cardPoolLevel += shift;
            self.updateCardPoolLevel();
        }
    };

    self.updateCardPoolLevel = function () {
        var newLevel = parseInt(self.cardPoolLevel);
        if (newLevel >= 1 && newLevel <= 10) {
            preferences.setUserPrefValue(UserPrefIds.CARD_POOL_LEVEL, newLevel);
            if (newLevel < self.minCardPoolLevel) {
                self.minCardPoolLevel = newLevel;
                self.updateMinCardPoolLevel();
            }
        }
    };

    self.changeMinCardPoolLevel = function (shift) {
        var postShift = parseInt(self.minCardPoolLevel) + shift;
        if (postShift <= 10 && postShift > 0) {
            self.minCardPoolLevel += shift;
            self.updateMinCardPoolLevel();
        }
    };

    self.maxMinCardPoolLevel = function () {
        var ownedLevel = cardService.ownership.maxOwnedLevel();
        if (ownedLevel <= 1) return 5;
        if (ownedLevel >= 5) return 10;
        return ownedLevel + 5;
    };

    self.waitingForSubscriber = function () {
        var ownedLevel = cardService.ownership.maxOwnedLevel();
        return self.minCardPoolLevel > ownedLevel;
    };

    self.updateMinCardPoolLevel = function () {
        var newMinLevel = parseInt(self.minCardPoolLevel);
        if (newMinLevel >= 1 && newMinLevel <= self.maxMinCardPoolLevel()) {
            preferences.setUserPrefValue(UserPrefIds.MIN_CARD_POOL_LEVEL, newMinLevel);
            if (newMinLevel > self.cardPoolLevel) {
                self.cardPoolLevel = newMinLevel;
                self.updateCardPoolLevel();
            }
        }
    };

    self.searchNow = function () {
        serverMessenger.requestAutomatch(matchingCriteria);
    };

    self.botMatch = function (numberOfBots) {
        var previews = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        var require = previews ? MATCHING_PREFERENCE.REQUIRE : MATCHING_PREFERENCE.REJECT;
        matchingCriteria.automatchPreferences.set(AUTOMATCH_QUESTION.PREVIEW_GAME, require);
        serverMessenger.requestAutomatch(new MatchingCriteria(numberOfBots, false, 10, -10, matchingCriteria.automatchPreferences));
    };

    self.cancel = function () {
        var stopped = new MatchingCriteria(0, false, 0, 0, matchingCriteria.automatchPreferences);
        serverMessenger.requestAutomatch(stopped);
    };

    self.getSearchingCountsWith = function (automatchQuestion) {
        return automatchProgress.searchingWith[automatchQuestion];
    };
    self.getSearchingCountsWithout = function (automatchQuestion) {
        return automatchProgress.searchingWithout[automatchQuestion];
    };

    function sendNewCriteria(newCriteria) {
        matchingCriteria = newCriteria;
        self.syncMatchCriteria();
        serverMessenger.requestAutomatch(matchingCriteria);
    }

    self.syncCriteria = function () {
        self.syncMatchCriteria();
        if (automatchProgress.iAmSearching) serverMessenger.requestAutomatch(matchingCriteria);
        $rootScope.safeApply();
    };

    scope.$on(Events.AUTOMATCH_STATUS, function (e, status) {
        automatchProgress = status.automatchProgress;
        self.showPreviewQueue = status.showPreviewQueue;
        self.daysOfSubscription = status.daysOfSubscription;
        self.syncMatchCriteria(false);
        $rootScope.safeApply();
    });

    scope.$on(Events.TAB_CLICKED, function () {
        $rootScope.safeApply();
    });
}]);