"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CardStatus = function (_GameStateChange) {
    _inherits(CardStatus, _GameStateChange);

    function CardStatus(game, cardIndex, staysOut, playedCardLists, setAsideCardLists, cardPropertyList) {
        _classCallCheck(this, CardStatus);

        var _this = _possibleConstructorReturn(this, (CardStatus.__proto__ || Object.getPrototypeOf(CardStatus)).call(this, game));

        _this.cardIndex = cardIndex;
        _this.staysOut = staysOut;
        _this.playedCardLists = playedCardLists;
        _this.setAsideCardLists = setAsideCardLists;
        _this.cardPropertyList = cardPropertyList;
        return _this;
    }

    _createClass(CardStatus, [{
        key: "isCompatible",
        value: function isCompatible(that) {
            return this.isFlipped === that.isFlipped && this.isInvested === that.isInvested;
        }
    }, {
        key: "execute",
        value: function execute(game) {
            var card = game.state.cards[this.cardIndex];
            this.setAsideCardLists = this.setAsideCardLists.map(function (l) {
                if (l[0] === -1) {
                    return l;
                } else {
                    return l.map(function (c) {
                        return game.state.cards[c];
                    });
                }
            });

            card.cardStatus = this;
        }
    }, {
        key: "isFlipped",
        get: function get() {
            return this.cardPropertyList.includes(CardProperties.FACE_DOWN);
        }
    }, {
        key: "isInvested",
        get: function get() {
            return this.cardPropertyList.includes(CardProperties.INVESTED);
        }
    }], [{
        key: "parse",
        value: function parse(game, reader) {
            var cardIndex = reader.readInt();
            var staysOut = reader.readBoolean();
            var playedCardLists = this.readLists(reader);
            var setAsideCardLists = this.readLists(reader);
            var cardPropertyList = reader.readEnumArray(CardProperties);
            return new CardStatus(game, cardIndex, staysOut, playedCardLists, setAsideCardLists, cardPropertyList);
        }
    }, {
        key: "readLists",
        value: function readLists(reader) {
            var nLists = reader.readInt();
            var lists = [];
            while (nLists-- > 0) {
                lists.push(reader.readInts());
            }
            return lists;
        }
    }, {
        key: "default",
        value: function _default(game, cardIndex) {
            return new CardStatus(game, cardIndex, false, [], [], []);
        }
    }]);

    return CardStatus;
}(GameStateChange);