"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Zone = function () {
    function Zone(state, index, pileName, owner, createdBy, attachedTrait) {
        var _this = this;

        var isVisible = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : true;

        _classCallCheck(this, Zone);

        this.state = state;
        this.index = index;
        this.pileName = pileName;
        this.owner = owner;
        if (owner !== this.state.nobody) this.owner.ownedZones.push(this);
        this.createdBy = createdBy;
        this.attachedTrait = attachedTrait;
        this.isVisible = isVisible;

        this.markers = [];
        this.tokens = [];
        this.counters = [];
        this.cardStacks = [];
        this.tabViews = [];
        this.indexOfType = 0;

        this.resizeListener = function () {
            return _this.reposition();
        };
        window.addEventListener("resize", this.resizeListener);
    }

    _createClass(Zone, [{
        key: "unload",
        value: function unload() {
            this.cardStacks.forEach(function (s) {
                return s.destroy();
            });
            this.markers = [];
            this.tokens = [];
            this.counters = [];
            this.cardStacks = [];
            this.tabViews = [];
            window.removeEventListener("resize", this.resizeListener);
        }
    }, {
        key: "addMarker",
        value: function addMarker(marker) {
            this.markers.push(marker);
            this.cardStacks.forEach(function (c) {
                return c.updateMarkers();
            });
        }
    }, {
        key: "removeStack",
        value: function removeStack(stack) {
            var index = this.cardStacks.indexOf(stack);
            if (index > -1) this.cardStacks.splice(index, 1);else console.error("Tried to remove a stack that wasn't there");
        }
    }, {
        key: "initialize",
        value: function initialize() {}
    }, {
        key: "reposition",
        value: function reposition() {}
    }, {
        key: "spliceCard",
        value: function spliceCard(card) {
            var targetCard = card;
            var targetStack = this.cardStacks.find(function (s) {
                return s.cards.includes(card);
            });
            if (isUndefined(targetStack)) {
                targetStack = this.cardStacks.find(function (s) {
                    return s.cards.some(function (c) {
                        return c.cardName === card.cardName;
                    });
                });
                if (isUndefined(targetStack)) {
                    var _newStack = this.spliceAnonymousCard();
                    _newStack.anonymousCards--;
                    _newStack.addCard(card);
                    return _newStack;
                } else {
                    targetCard = targetStack.cards.find(function (c) {
                        return c.cardName === card.cardName;
                    });
                }
            }
            targetStack.removeCard(targetCard);
            var newStack = new CardStack(this.state.getAnonCard(targetCard.cardName), this.state.nowhere);
            newStack.addCard(card);
            newStack.copyPosition(targetStack);
            if (targetStack.cards.length + targetStack.anonymousCards === 0) {
                if (targetStack.trackedAnimators.length === 0) {
                    targetStack.destroy();
                    this.reposition(true);
                } else {
                    targetStack.addFilter("intangible");
                }
            }
            return newStack;
        }
    }, {
        key: "spliceAnonymousCard",
        value: function spliceAnonymousCard() {
            var shouldAnonymize = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

            if (shouldAnonymize) this.cardStacks.forEach(function (s) {
                return s.anonymize();
            });
            var targetStack = this.cardStacks.find(function (s) {
                return s.anonymousCards > 0;
            });
            if (!isUndefined(targetStack)) {
                targetStack.anonymousCards--;
                var newStack = new CardStack(this.state.getAnonCard(CardNames.BACK), this.state.nowhere);
                newStack.anonymousCards++;
                newStack.copyPosition(targetStack);
                if (targetStack.cards.length + targetStack.anonymousCards === 0) {
                    if (targetStack.trackedAnimators.length === 0) {
                        targetStack.destroy();
                        this.reposition(true);
                    } else {
                        targetStack.addFilter("intangible");
                    }
                }
                return newStack;
            } else {
                console.error("failed to find card to remove");
                return null;
            }
        }
    }, {
        key: "getScript",
        value: function getScript(fromZone, stack, targetStack, props) {
            if (props.isInstant) return [];

            var width = stack.view.bbox.width;
            var height = stack.view.bbox.height;

            if (fromZone instanceof SkippableRevealZone || fromZone instanceof WindowedZone && !fromZone.visible) {
                return [new Keyframe(0, new StackTarget(new Rectangle(0, -0.5 * height, 0, 0, 10, 0, 0, -1)), Curves.LINEAR), new Keyframe(props.speed, new StackTarget(), Curves.SMOOTH)];
            }

            switch (props.type) {
                case ClientAnimationTypes.MINIMAL:
                    var init = stack.view.bbox.linearInterpolate(targetStack.view.bbox, 0.8);
                    init.opacity = 0;
                    return [new Keyframe(0, new AbsoluteTarget(init), Curves.LINEAR), new Keyframe(props.speed, new StackTarget(), Curves.SMOOTH)];
                    break;
                case ClientAnimationTypes.NORMAL:
                    if (fromZone instanceof SupplyZone || fromZone instanceof RevealZone) {
                        return [new Keyframe(props.speed * 1.5, new RelativeTarget(new Rectangle(-0.05 * width, -0.2 * height, 0.1 * width, 0.1 * height, 10, 0, -1)), Curves.SMOOTH)];
                    } else {
                        return [new Keyframe(props.speed * 1.5, new StackTarget(), Curves.SMOOTH), new Keyframe(props.delay * 0.2, new StackTarget(), Curves.SMOOTH)];
                    }
                    break;
                case ClientAnimationTypes.HIGHLIGHTED:
                    if (fromZone instanceof SupplyZone) {
                        return [new Keyframe(props.delay, new RelativeTarget(new Rectangle(-0.1 * width, -0.4 * height, 0.2 * width, 0.2 * height, 10, 0, 0)), Curves.SMOOTH), new Keyframe(props.speed, new RelativeTarget(new Rectangle(-0.05 * width, -0.2 * height, 0.1 * width, 0.1 * height, 10, 0, -1)), Curves.SMOOTH)];
                    } else {
                        return [new Keyframe(props.speed * 1.5, new StackTarget(), Curves.SMOOTH), new Keyframe(props.delay * 0.5, new StackTarget(), Curves.SMOOTH)];
                    }
                    break;
            }
        }
    }, {
        key: "mergeStacks",
        value: function mergeStacks(targetStack, transitionStack) {
            transitionStack.cards.forEach(function (c) {
                return targetStack.addCard(c);
            });
            targetStack.anonymousCards += transitionStack.anonymousCards;
            transitionStack.destroy(targetStack);
            targetStack.removeFilter("intangible");
        }
    }, {
        key: "addTab",
        value: function addTab(tabView) {
            this.tabViews.push(tabView);
        }
    }, {
        key: "addToken",
        value: function addToken(token) {
            this.tokens.push(token);
            this.cardStacks.forEach(function (s) {
                return s.tokensChanged();
            });
        }
    }, {
        key: "removeToken",
        value: function removeToken(token) {
            var index = this.tokens.indexOf(token);
            if (index > -1) {
                this.tokens.splice(token, 1);
                this.cardStacks.forEach(function (s) {
                    return s.tokensChanged();
                });
            } else {
                console.error("Couldn't find token to remove");
            }
        }
    }, {
        key: "onFocusChange",
        value: function onFocusChange() {}
    }, {
        key: "visible",
        get: function get() {
            return this.isVisible;
        }
    }, {
        key: "owner",
        set: function set(newValue) {
            if (newValue !== this.state.nobody && !(newValue instanceof Player)) {
                throw "Illegal zone owner";
            }
            this.privateOwner = newValue;
        },
        get: function get() {
            return this.privateOwner;
        }
    }, {
        key: "cardCount",
        get: function get() {
            return sum(this.cardStacks.map(function (s) {
                return s.cardCount;
            }));
        }
    }, {
        key: "layoutModel",
        get: function get() {
            return this.state.game.layoutModel;
        }
    }, {
        key: "zoneName",
        get: function get() {
            return "SetAsideZone";
        }
    }, {
        key: "isNowhere",
        get: function get() {
            return this === this.state.nowhere;
        }
    }, {
        key: "repositionOnNewTurn",
        get: function get() {
            return false;
        }
    }]);

    return Zone;
}();