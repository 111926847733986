"use strict";

var RussianCardTexts = {};
RussianCardTexts[CardNames.BACK] = "";
RussianCardTexts[CardNames.CURSE] = "";
RussianCardTexts[CardNames.COPPER] = "";
RussianCardTexts[CardNames.SILVER] = "";
RussianCardTexts[CardNames.GOLD] = "";
RussianCardTexts[CardNames.ESTATE] = "";
RussianCardTexts[CardNames.DUCHY] = "";
RussianCardTexts[CardNames.PROVINCE] = "";
RussianCardTexts[CardNames.ARTISAN] = "Получите в руку//карту не дороже[5].//Положите карту из руки//на верх колоды.";
RussianCardTexts[CardNames.BANDIT] = "Получите Золото.//Каждый другой игрок раскрывает//2 верхние карты своей колоды,//выкидывает раскрытую карту//Сокровища (не Медь) и сбрасывает//остальные раскрытые карты.";
RussianCardTexts[CardNames.BUREAUCRAT] = "Получите Серебро//на верх вашей колоды.//Каждый другой игрок раскрывает//карту Победы из руки и кладёт её//на верх своей колоды (или//раскрывает руку без карт Победы).";
RussianCardTexts[CardNames.CELLAR] = "|+1 Действие|//Сбросьте любое число карт,//затем вытяните столько же.";
RussianCardTexts[CardNames.CHAPEL] = "Выкиньте до четырёх//карт из руки.";
RussianCardTexts[CardNames.COUNCIL_ROOM] = "|+4 Карты|//|+1 Покупка|//Каждый другой игрок//тянет карту.";
RussianCardTexts[CardNames.FESTIVAL] = "|+2 Действия|//|+1 Покупка|//|+[2]|";
RussianCardTexts[CardNames.GARDENS] = "Приносит{1}за каждые//10 карт, которые у вас есть//(с округлением вниз).";
RussianCardTexts[CardNames.HARBINGER] = "|+1 Карта|//|+1 Действие|////Посмотрите на ваш сброс.//Вы можете положить карту//оттуда на верх колоды.";
RussianCardTexts[CardNames.LABORATORY] = "|+2 Карты|//|+1 Действие|";
RussianCardTexts[CardNames.LIBRARY] = "Тяните карты до семи в руке,//по желанию пропуская любые//карты Действия. Пропущенные//карты откладывайте в сторону//и затем сбросьте их.";
RussianCardTexts[CardNames.MARKET] = "|+|[1]////|+1 Карта|//|+1 Действие|//|+1 Покупка|";
RussianCardTexts[CardNames.MERCHANT] = "|+1 Карта|//|+1 Действие|////Первый раз, когда вы играете//Серебро в этот ход, +[1].";
RussianCardTexts[CardNames.MILITIA] = "|+|[2]//Каждый другой игрок//сбрасывает до трёх//карт в руке.";
RussianCardTexts[CardNames.MINE] = "Вы можете выкинуть//Сокровище из руки. Получите в//руку Сокровище не более чем//на[3]дороже выкинутого.";
RussianCardTexts[CardNames.MOAT] = "|+2 Карты|//---//Когда другой игрок играет//карту Атаки, вы можете раскрыть//эту карту из руки, тогда//Атака вас не затронет.";
RussianCardTexts[CardNames.MONEYLENDER] = "Вы можете выкинуть//карту Меди из руки,//тогда +[3].";
RussianCardTexts[CardNames.POACHER] = "|+1 Карта|//|+1 Действие|//|+[1]|////Сбросьте по одной карте//за каждую пустую//стопку Резерва.";
RussianCardTexts[CardNames.REMODEL] = "Выкиньте карту из руки.//Получите карту стоимостью//не более чем на[2]//дороже выкинутой.";
RussianCardTexts[CardNames.SENTRY] = "|+1 Карта|//|+1 Действие|////Посмотрите на 2 верхние карты//вашей колоды. Выкиньте//и/или сбросьте любое их число.//Положите остаток назад на верх//колоды в любом порядке.";
RussianCardTexts[CardNames.SMITHY] = "|+3 Карты|";
RussianCardTexts[CardNames.THRONE_ROOM] = "Вы можете сыграть//карту Действия//из вашей руки дважды.";
RussianCardTexts[CardNames.VASSAL] = "|+|[2]////Сбросьте верхнюю карту//вашей колоды.//Если это карта Действия,//то можете сыграть её.";
RussianCardTexts[CardNames.VILLAGE] = "|+1 Карта|//|+2 Действия|";
RussianCardTexts[CardNames.WITCH] = "|+2 Карты|//Каждый другой игрок//получает Проклятие.";
RussianCardTexts[CardNames.WORKSHOP] = "Получите карту//не дороже[4].";
RussianCardTexts[CardNames.COURTYARD] = "|+3 Карты|//Положите карту из руки//на верх вашей колоды.";
RussianCardTexts[CardNames.CONSPIRATOR] = "|+|[2]////Если вы сыграли 3//или больше Действий//в этот ход (включая это),//то |+1 Карта|, |+1 Действие|.";
RussianCardTexts[CardNames.COURTIER] = "Раскройте карту из руки. Для//каждого присущего ей типа//(Действие, Атака, и т. д.) выберите//одно из четырёх: |+1 Действие|;//или |+1 Покупка|; или +[3];//или получите Золото.//Выборы не должны повторяться.";
RussianCardTexts[CardNames.BARON] = "|+1 Покупка|//Вы можете сбросить//Поместье, тогда +[4].//Иначе получите Поместье.";
RussianCardTexts[CardNames.BRIDGE] = "|+1 Покупка|//|+[1]|////В этом ходу все карты (везде)//стоят на[1]дешевле.";
RussianCardTexts[CardNames.DIPLOMAT] = "|+2 Карты|////Если у вас 5 или меньше карт//в руке (после того, как//вытянули карты), |+2 Действия|.//---//Когда другой игрок играет карту Атаки,//если в вашей руке не менее пяти карт,//вы можете раскрыть эту карту из руки.//Тогда вытяните 2 карты и сбросьте 3.";
RussianCardTexts[CardNames.DUKE] = "Приносит{1}//за каждое ваше Герцогство.";
RussianCardTexts[CardNames.FARM] = "[!2]//---//{!2}";
RussianCardTexts[CardNames.NOBLES] = "{!2}//---//Выберите одно из двух://|+3 Карты|; или |+2 Действия|.";
RussianCardTexts[CardNames.IRONWORKS] = "Получите карту не дороже[4].//Если полученная карта - //Действие, то |+1 Действие|;//Сокровище, то|+|[1];//Победа, то|+1 Карта|.";
RussianCardTexts[CardNames.LURKER] = "|+1 Действие|////Выберите одно из двух://выкиньте карту Действия//из Резерва; или получите//карту Действия со Свалки.";
RussianCardTexts[CardNames.MASQUERADE] = "|+2 Карты|////Каждый игрок с картами в руке//одновременно передаёт одну//карту следующему такому//игроку налево. Затем вы можете//выкинуть карту из руки.";
RussianCardTexts[CardNames.MILL] = "{!1}//---//|+1 Карта|//|+1 Действие|////Вы можете сбросить 2 карты,//тогда +[2].";
RussianCardTexts[CardNames.MINING_VILLAGE] = "|+1 Карта|//|+2 Действия|////Вы можете выкинуть эту//карту, тогда +[2].";
RussianCardTexts[CardNames.MINION] = "|+1 Действие|////Выберите одно из двух: либо//+[2]; либо сбросьте вашу руку,//|+4 Карты|, каждый другой//игрок с хотя бы пятью//картами в руке сбрасывает//руку и тянет 4 карты.";
RussianCardTexts[CardNames.PATROL] = "|+3 Карты|////Раскройте 4 верхние карты вашей//колоды. Положите раскрытые//карты Победы и Проклятия в руку.//Положите остальные раскрытые //карты назад в любом порядке.";
RussianCardTexts[CardNames.PAWN] = "Выберите два разных//варианта из четырёх://|+1 Карта|; |+1 Действие|;//|+1 Покупка|; +[1].";
RussianCardTexts[CardNames.REPLACE] = "Выкиньте карту из руки. Получите//карту стоимостью не более чем на//[2]дороже выкинутой. Если полученная//карта - Действие или Сокровище, то//положите её на верх вашей колоды;//если это карта Победы, то каждый//другой игрок получает Проклятие.";
RussianCardTexts[CardNames.SECRET_PASSAGE] = "|+2 Карты|//|+1 Действие|////Возьмите карту из руки//и положите её в любое//место вашей колоды.";
RussianCardTexts[CardNames.SHANTY_TOWN] = "|+2 Действия|////Раскройте вашу руку.//Если у вас нет карт//Действия в руке, |+2 Карты|.";
RussianCardTexts[CardNames.STEWARD] = "Выберите одно из трёх: //|+2 Карты|; или +[2];//или выкиньте 2 карты//из руки.";
RussianCardTexts[CardNames.SWINDLER] = "|+|[2]////Каждый другой игрок//выкидывает верхнюю карту//своей колоды и получает//карту такой же стоимости//по вашему выбору.";
RussianCardTexts[CardNames.TORTURER] = "|+3 Карты|////Каждый другой игрок либо//сбрасывает 2 карты, либо//получает Проклятие в руку,//по своему выбору.//(Они могут выбрать вариант,//выполнить который не могут.)";
RussianCardTexts[CardNames.TRADING_POST] = "Выкиньте 2 карты из руки.//Тогда получите Серебро//в руку.";
RussianCardTexts[CardNames.UPGRADE] = "|+1 Карта|//|+1 Действие|////Выкиньте карту из руки.//Получите карту ровно на[1]//дороже выкинутой.";
RussianCardTexts[CardNames.WISHING_WELL] = "|+1 Карта|//|+1 Действие|////Назовите карту и раскройте//верхнюю карту вашей колоды.//Если вы раскрыли названную//карту, то положите её в руку.";

RussianCardTexts[CardNames.AMBASSADOR] = "Раскройте карту из руки.//Верните до двух её копий из//руки в Резерв. Затем каждый//другой игрок получает//её копию.";
RussianCardTexts[CardNames.BAZAAR] = "|+1 Карта|//|+2 Действия|//|+[1]|";
RussianCardTexts[CardNames.CARAVAN] = "|+1 Карта|//|+1 Действие|////В начале вашего следующего//хода, |+1 Карта|.";
RussianCardTexts[CardNames.CUTPURSE] = "|+[2]|////Каждый другой игрок//сбрасывает карту Меди//из руки (или раскрывает//руку без Меди).";
RussianCardTexts[CardNames.EMBARGO] = "|+[2]|////Выкиньте эту карту. Тогда//добавьте жетон Эмбарго//на стопку Резерва. (До конца//игры, когда игрок покупает//карту из этой стопки,//он получает Проклятие.)";
RussianCardTexts[CardNames.EXPLORER] = "Вы можете раскрыть//Провинцию из руки. Тогда//получите Золото в руку.//Иначе получите Серебро//в руку.";
RussianCardTexts[CardNames.FISHING_VILLAGE] = "|+2 Действия|//|+[1]|////В начале вашего//следующего хода://|+1 Действие| и |+|[1]";
RussianCardTexts[CardNames.GHOST_SHIP] = "|+2 Карты|////Каждый другой игрок с четырьмя//или более картами в руке кладёт//карты из руки на верх своей//колоды, пока в его руке не //останутся 3 карты.";
RussianCardTexts[CardNames.HAVEN] = "|+1 Карта|//|+1 Действие|////Отложите карту из руки в//сторону, рубашкой вверх//(положите её под эту карту).//В начале вашего следующего//хода возьмите её в руку.";
RussianCardTexts[CardNames.ISLAND] = "{!2}//---//Отложите эту карту и//другую карту из руки//на ваш Островной мат.";
RussianCardTexts[CardNames.LIGHTHOUSE] = "|+1 Действие|////Сейчас и в начале вашего//следующего хода, |+|[1].//До этого, когда//другой игрок играет карту//Атаки, она вас не затрагивает.";
RussianCardTexts[CardNames.LOOKOUT] = "|+1 Действие|////Посмотрите на 3 верхние карты//вашей колоды. Выкиньте одну//из них. Сбросьте одну из них.//Положите оставшуюся назад//на верх колоды.";
RussianCardTexts[CardNames.MERCHANT_SHIP] = "Сейчас и в начале вашего//следующего хода: |+|[2].";
RussianCardTexts[CardNames.NATIVE_VILLAGE] = "|+2 Действия|////Выберите одно: Положите//верхнюю карту вашей колоды на//мат Поселения Аборигенов (вы//можете смотреть на карты вашего//мата в любое время); либо//возьмите все карты с мата в руку.";
RussianCardTexts[CardNames.NAVIGATOR] = "|+[2]|////Посмотрите на 5 верхних карт//вашей колоды. Либо сбросьте//их все, либо положите назад//в любом порядке.";
RussianCardTexts[CardNames.OUTPOST] = "Вы тянете только 3 карты//для вашей следующей руки.//Получите дополнительный//ход после текущего//(но не третий ход подряд).";
RussianCardTexts[CardNames.PEARL_DIVER] = "|+1 Карта|//|+1 Действие|////Посмотрите на нижнюю//карту вашей колоды. Вы//можете положить её наверх.";
RussianCardTexts[CardNames.PIRATE_SHIP] = "Выберите одно: |+|[1]за каждый//Жетон на вашем Пиратском мате; либо//каждый другой игрок раскрывает 2//верхние карты своей колоды,//выкидывает раскрытое Сокровище по//вашему выбору, сбрасывает остальные//карты, и если кто-то выкинул//Сокровище, то вы добавляете Золотой//Жетон на ваш Пиратский мат.";
RussianCardTexts[CardNames.SALVAGER] = "|+1 Покупка|////Выкиньте карту из руки.//|+|[1]за каждую[1]её//стоимости.";
RussianCardTexts[CardNames.SEA_HAG] = "Каждый другой игрок//сбрасывает верхнюю карту//своей колоды, затем//получает Проклятие на//верх своей колоды.";
RussianCardTexts[CardNames.SMUGGLERS] = "Получите копию карты//стоимостью не более[6],//которую игрок справа//получил в его последний//ход.";
RussianCardTexts[CardNames.TACTICIAN] = "Если у вас есть хотя бы одна//карта в руке, то сбросьте вашу//руку, и в начале вашего//следующего хода, |+5 Карт|, //|+1 Действие| и |+1 Покупка|.";
RussianCardTexts[CardNames.TREASURE_MAP] = "Выкиньте эту карту и другую//Карту Сокровищ из руки.//Если вы выкинули две Карты//Сокровищ, получите четыре//Золота на верх колоды.";
RussianCardTexts[CardNames.TREASURY] = "|+1 Карта|//|+1 Действие|//|+[1]|//---//В конце вашей Фазы Покупок в этом ходу,//если вы не получали карт//Победы в этот ход, то можете//положить эту карту на верх колоды.";
RussianCardTexts[CardNames.WAREHOUSE] = "|+3 Карты|//|+1 Действие|////Сбросьте 3 карты.";
RussianCardTexts[CardNames.WHARF] = "Сейчас и в начале вашего//следующего хода:////|+2 Карты|//|+1 Покупка|";

RussianCardTexts[CardNames.ALCHEMIST] = "|+2 Карты|//|+1 Действие|//---//В начале фазы Расчистки//текущего хода, если у вас//есть Зелье в игре, можете//положить эту карту (Алхимика)//на верх колоды.";
RussianCardTexts[CardNames.APOTHECARY] = "|+1 Карта|//|+1 Действие|////Раскройте 4 верхние карты вашей//колоды. Положите раскрытые//карты Меди и Зелья в руку.//Положите остальные раскрытые//карты назад в любом порядке.";
RussianCardTexts[CardNames.APPRENTICE] = "|+1 Действие|////Выкиньте карту из руки.//|+1 Карта| за каждую[1]её//стоимости. |+2 Карты| если в//её стоимости было[P].";
RussianCardTexts[CardNames.FAMILIAR] = "|+1 Карта|//|+1 Действие|////Каждый другой игрок//получает Проклятие.";
RussianCardTexts[CardNames.GOLEM] = "Раскрывайте карты с вашей//колоды, пока не будут раскрыты//2 карты Действия (но не Големы).//Сбросьте остальные раскрытые//карты, затем сыграйте карты//Действия в любом порядке.";
RussianCardTexts[CardNames.HERBALIST] = "|+1 Покупка|//|+[1]|//---//Один раз за текущий ход,//когда вы сбрасываете//Сокровище из игры, можете//положить его на верх колоды.";
RussianCardTexts[CardNames.PHILOSOPHERS_STONE] = "Посчитайте карты ваших//колоды и сброса.//|+|[1] за каждые 5 карт//суммы (с округлением вниз).";
RussianCardTexts[CardNames.POSSESSION] = "Игрок слева от вас получает//дополнительный ход после текущего//(но не второй дополнительный ход подряд),//в нём вы можете видеть все карты, которые//он может видеть, и принимаете все//решения за него. Все карты и[D],//которые он получает в течение этого хода,//получаете вместо него вы; все карты,//которые он выкидывает, откладываются в//сторону и возвращаются к нему в сброс//в конце хода.";
RussianCardTexts[CardNames.POTION] = "";
RussianCardTexts[CardNames.SCRYING_POOL] = "|+1 Действие|////Каждый игрок (вы тоже) раскрывает//верхнюю карту своей колоды и по//вашему выбору либо сбрасывает её,//либо кладёт назад. Затем раскрывайте//карты с вашей колоды до тех пор, пока//не раскроете карту, не являющуюся//картой Действия. Положите все//раскрытые карты в руку.";
RussianCardTexts[CardNames.TRANSMUTE] = "Выкиньте карту из руки.//Если это была карта...////Действия,получите Герцогство//Сокровища,получите Превращение//Победы,получите Золото";
RussianCardTexts[CardNames.UNIVERSITY] = "|+2 Действия|////Вы можете получить карту//Действия не дороже[5].";
RussianCardTexts[CardNames.VINEYARD] = "Приносит{1}за каждые//3 карты Действия, которые у//вас есть (с округлением вниз).";

RussianCardTexts[CardNames.BANK] = "|+|[1]за каждое//Сокровище у вас в игре//(включая эту карту).";
RussianCardTexts[CardNames.BISHOP] = "|+{1}|//|+[1]|////Выкиньте карту из руки.//|+|{1}за каждые[2]её стоимости//(с округлением вниз).//Каждый другой игрок может//выкинуть карту из своей руки.";
RussianCardTexts[CardNames.COLONY] = "";
RussianCardTexts[CardNames.CONTRABAND] = "[3]////|+1 Покупка|////Игрок слева называет карту.//Вы не можете купить//названную карту в этот ход.";
RussianCardTexts[CardNames.COUNTING_HOUSE] = "Посмотрите на ваш сброс,//раскройте любое число карт//Меди оттуда и положите их//в руку.";
RussianCardTexts[CardNames.CITY] = "|+1 Карта|//|+2 Действия|////Если одна или больше стопка//Резерва пустые, то |+1 Карта|.//Если две или больше, то//|+1 Покупка| и |+|[1].";
RussianCardTexts[CardNames.EXPAND] = "Выкиньте карту из руки.//Получите карту стоимостью//до[3]больше выкинутой.";
RussianCardTexts[CardNames.FORGE] = "Выкиньте любое количество//карт из руки. Получите карту,//стоимостью равную общей//стоимости выкинутых //карт в[].";
RussianCardTexts[CardNames.GRAND_MARKET] = "|+1 Карта|//|+1 Действие|//|+1 Покупка|//|+[2]|//---//Вы не можете купить эту карту,//если у вас есть Медь в игре.";
RussianCardTexts[CardNames.GOONS] = "////|+1 Покупка|//|+[2]|////Каждый другой игрок//сбрасывает до трёх карт в руке.//---//Пока эта карта у вас в игре,//когда вы покупаете карту,|+|{1}.";
RussianCardTexts[CardNames.KINGS_COURT] = "Вы можете сыграть карту//Действия из руки 3 раза.";
RussianCardTexts[CardNames.LOAN] = "[1]////Раскрывайте карты с вашей//колоды до тех пор, пока не//раскроете Сокровище. Сбросьте//или выкиньте его. Сбросьте//остальные раскрытые карты.";
RussianCardTexts[CardNames.MONUMENT] = "|+{1}|//|+[2]|";
RussianCardTexts[CardNames.MOUNTEBANK] = "|+[2]|////Каждый другой игрок//может сбросить Проклятие.//Иначе он получает//Проклятие и Медь.";
RussianCardTexts[CardNames.PEDDLER] = "|+1 Карта|//|+1 Действие|//|+[1]|//---//Во время фазы Покупки игрока//эта карта стоит на[2]дешевле//за каждую карту Действия у//него в игре, но не меньше[0].";
RussianCardTexts[CardNames.PLATINUM] = "";
RussianCardTexts[CardNames.RABBLE] = "|+3 Карты|////Каждый другой игрок раскрывает//3 верхние карты своей колоды,//сбрасывает Действия и//Сокровища, и кладёт остальные//раскрытые карты назад в том//порядке, который он выберет.";
RussianCardTexts[CardNames.ROYAL_SEAL] = "[!2]//---//Пока эта карта у вас в игре,//когда вы покупаете карту,//вы можете положить её//на верх колоды.";
RussianCardTexts[CardNames.TALISMAN] = "[4]//---//Пока эта карта у вас в игре,//когда вы покупаете карту//стоимостью до[!1](не карту//Победы), получите её копию.";
RussianCardTexts[CardNames.TRADE_ROUTE] = "|+1 Покупка|////Выкиньте карту из руки. |+|[1]за каждый//Жетон на мате Торгового Пути.//---//Подготовка: Положите Золотой Жетон//на каждую стопку карт Победы в//Резерве. Когда кто-то получает карту//из такой стопки, переложите Жетон с//неё на мат Торгового Пути.";
RussianCardTexts[CardNames.VAULT] = "|+2 Карты|////Сбросьте любое число карт//и получите |+|[1]за каждую.//Каждый другой игрок может//сбросить 2 карты, тогда он//тянет карту.";
RussianCardTexts[CardNames.VENTURE] = "[1]////Раскрывайте карты с вашей//колоды до тех пор, пока не//раскроете Сокровище. Сбросьте//остальные раскрытые карты,//затем сыграйте Сокровище.";
RussianCardTexts[CardNames.WATCHTOWER] = "Тяните карты, пока у вас//не будет 6 карт в руке.//---//Когда вы получаете карту, можете//раскрыть эту карту из руки,//тогда либо выкиньте получаемую//карту, либо положите её на верх// колоды.";
RussianCardTexts[CardNames.WORKERS_VILLAGE] = "|+1 Карта|//|+2 Действия|//|+1 Покупка|";

RussianCardTexts[CardNames.PRIZE_PILE] = "";
RussianCardTexts[CardNames.BAG_OF_GOLD] = "|+1 Действие|////Получите Золото на верх//колоды.//%(Эта карта не представлена%//%в Резерве)%";
RussianCardTexts[CardNames.DIADEM] = "[!2]//|+|[1] за каждое ваше//неиспользованное Действие//(Действие, не карту Действия).//%(Эта карта не представлена%//%в Резерве)%";
RussianCardTexts[CardNames.FAIRGROUNDS] = "Приносит по{2}//за каждые 5 разных карт,//которые у вас есть//(с округлением вниз).";
RussianCardTexts[CardNames.FARMING_VILLAGE] = "|+2 Действия|////Раскрывайте карты с вашей//колоды, пока не раскроете//карту Действия или//Сокровище. Положите это в //руку и сбросьте остаток.";
RussianCardTexts[CardNames.FOLLOWERS] = "|+2 Карты|////Получите Поместье.//Каждый другой игрок получает//Проклятие и сбрасывает до//трёх карт в руке.//%(Эта карта не представлена%//%в Резерве)%";
RussianCardTexts[CardNames.FORTUNE_TELLER] = "+[2]//////Каждый другой игрок раскрывает//карты с верха своей колоды, пока//не раскроет карту Победы или//Проклятие. Это он кладёт на верх//колоды и сбрасывает остаток.";
RussianCardTexts[CardNames.HAMLET] = "|+1 Карта|//|+1 Действие|////Вы можете сбросить карту,//тогда |+1 Действие|.//Вы можете сбросить карту,//тогда |+1 Покупка|.";
RussianCardTexts[CardNames.HARVEST] = "Раскройте 4 верхние карты//колоды, затем сбросьте их.//+[1]за каждую разную//сброшенную карту.";
RussianCardTexts[CardNames.HORSE_TRADERS] = "|+1 Покупка|//|+[3]|//---//Сбросьте 2 карты////Когда другой игрок играет карту Атаки,//вы можете отложить эту карту из руки.//Тогда в начале вашего следующего хода //+|1 Карта| и верните эту карту в руку.";
RussianCardTexts[CardNames.HORN_OF_PLENTY] = "Получите карту, стоимостью//не превышающую [1] за каждую//разную карту у вас//в игре, считая эту карту.//Выкиньте эту карту,//если полученная//карта - карта Победы.";
RussianCardTexts[CardNames.HUNTING_PARTY] = "|+1 Карта|//|+1 Действие|////Раскройте вашу руку. Раскрывайте//карты с колоды, пока не раскроете//такую, копии которой у вас //в руке нет. Положите её в руку//и сбросьте остаток.";
RussianCardTexts[CardNames.JESTER] = "+[2]////Каждый другой игрок раскрывает//верхнюю карту своей колоды. Если//это карта Победы, то он получает//Проклятие; иначе по вашему//выбору либо он получает копию//раскрытой карты, либо вы.";
RussianCardTexts[CardNames.MENAGERIE] = "|+1 Действие|////Раскройте руку. Если в ней//нет повторяющихся карт,//то |+3 Карты|.//Иначе, |+1 Карта.|";
RussianCardTexts[CardNames.PRINCESS] = "|+1 Покупка|////В этот ход, карты//стоят на [2] дешевле,//%(Эта карта не представлена%//% в Резерве)%";
RussianCardTexts[CardNames.REMAKE] = "Выполните это дважды://Выкиньте карту из руки,//затем получите карту//стоимостью ровно на[1]//больше выкинутой.";
RussianCardTexts[CardNames.TOURNAMENT] = "|+1 Действие|////Каждый игрок может раскрыть//Провинцию из руки. Если вы//сделали это, то сбросьте её и//получите на верх колоды любой Трофей//(из стопки Трофеев) или Герцогство.//Если никто другой не раскрыл//Провинцию, то |+1 Карта| и +[1].";
RussianCardTexts[CardNames.TRUSTY_STEED] = "Выберите два разных варианта//из четырёх: |+2 Карты|; //|+2 Действия|; +[2]; Получите//4 Серебра и переложите//вашу колоду в сброс.//%(Эта карта не представлена%//%в Резерве)%";
RussianCardTexts[CardNames.YOUNG_WITCH] = "|+2 Карты|////Сбросьте 2 карты. Каждый другой//игрок может раскрыть карту Погибели//из руки, иначе он получает Проклятие.//---//Подготовка: Добавьте дополнительную//стопку карт Королевства стоимостью [2]//или [3] в Резерв. Карты из этой стопки//считаются картами Погибели.";

RussianCardTexts[CardNames.BORDER_VILLAGE] = "|+1 Карта|//|+2 Действия|//---//Когда вы получаете эту карту,//получите карту дешевле неё.";
RussianCardTexts[CardNames.CACHE] = "[!3]//---//Когда вы получаете эту//карту, получите две Меди.";
RussianCardTexts[CardNames.CARTOGRAPHER] = "|+1 Карта|//|+1 Действия|////Посмотрите на 4 верхние карты//вашей колоды. Сбросьте любое//их количество, затем положите//остальные назад в любом//порядке.";
RussianCardTexts[CardNames.CROSSROADS] = "Раскройте вашу руку.//|+1 Карта| за каждую раскрытую//карту Победы. Если вы играете//Перекрёсток первый раз за ход,//| +3 Действия|.";
RussianCardTexts[CardNames.DEVELOP] = "Выкиньте карту из руки.//Получите в любом порядке//две карты на верх колоды,//одну ровно на[1]дороже,//другую ровно на[1]дешевле//выкинутой.";
RussianCardTexts[CardNames.DUCHESS] = "|+[2]|////Каждый игрок (включая вас)//смотрит на верхнюю карту своей//колоды и может сбросить её.//---//Если Герцогини есть в Резерве,//то вы можете получить одну,//когда вы получаете Герцогство.";
RussianCardTexts[CardNames.EMBASSY] = "|+5 Карты|////Сбросьте 3 карты.//---//Когда вы получаете эту//карту, каждый другой//игрок получает Серебро.";
RussianCardTexts[CardNames.FARMLAND] = "[2]//---//Когда вы покупаете эту карту,//выкиньте карту из руки и//получите карту ровно на{!2}//дороже выкинутой.";
RussianCardTexts[CardNames.FOOLS_GOLD] = "Если это первый раз,//когда вы сыграли Золото Дураков//в этот ход, |+|[1], иначе |+|[4].//---//Когда другой игрок покупает//Провинцию, вы можете выкинуть//эту карту из руки, тогда//получите Золото на верх колоды.";
RussianCardTexts[CardNames.HAGGLER] = "|+[2]|//---//Пока эта карта в игре, когда//вы покупаете карту, получите//также карту дешевле покупаемой//(но не карту Победы).";
RussianCardTexts[CardNames.HIGHWAY] = "|+1 Карта|//|+1 Действия|//---//Пока эта карта в игре, все//карты стоят на[1]дешевле.";
RussianCardTexts[CardNames.ILL_GOTTEN_GAINS] = "[!1]////Можете получить Медь в руку.//---//Когда вы получаете эту карту,//каждый другой игрок получает//Проклятие.";
RussianCardTexts[CardNames.INN] = "|+2 Карты|//|+2 Действия|////Сбросьте 2 карты.//---//Когда вы получаете эту карту, посмотрите//на ваш сброс, раскройте любое//количество карт Действия оттуда (можно//таким образом раскрыть и эту карту),//и замешайте их в вашу колоду.";
RussianCardTexts[CardNames.JACK_OF_ALL_TRADES] = "Получите Серебро.//Посмотрите на верхнюю карту//колоды, можете сбросить её.//Тяните карты до пяти в руке.//Вы можете выкинуть карту из//руки (но не Сокровище).";
RussianCardTexts[CardNames.MANDARIN] = "|+[3]|////Положите карту из руки//на верх колоды.//---//Когда вы получаете эту карту,//положите все ваши Сокровища//из игры на верх колоды в//любом порядке.";
RussianCardTexts[CardNames.NOBLE_BRIGAND] = "|+[1]|////Каждый другой игрок раскрывает две верхние//карты своей колоды, выкидывает раскрытое//Серебро или Золото по вашему выбору,//сбрасывает остальные раскрытые карты, и//получает Медь, если он не раскрыл ни одного //Сокровища. Вы получаете выкинутые карты.//---//Когда вы покупаете эту карту,//атакуйте как описано выше.";
RussianCardTexts[CardNames.NOMAD_CAMP] = "---//|+1 Покупка|//|+[2]|////Когда вы получаете эту карту,//положите её на верх колоды.";
RussianCardTexts[CardNames.OASIS] = "|+1 Карта|//|+1 Действие|//|+[1]|////Сбросьте одну карту.";
RussianCardTexts[CardNames.ORACLE] = "Каждый игрок (включая вас)//раскрывает 2 верхние карты//своей колоды, и по вашему выбору//либо сбрасывает их, либо кладёт//назад (в том порядке, который он//сам выберет).//|+2 Карты|";
RussianCardTexts[CardNames.MARGRAVE] = "|+3 Карты|//|+1 Покупка|////Каждый другой игрок тянет//карту, затем сбрасывает //до трёх карт в руке.";
RussianCardTexts[CardNames.SCHEME] = "|+1 Карта|//|+1 Действие|////В этом ходу вы можете положить//одну из ваших карт Действия//на верх колоды, когда вы будете//сбрасывать её из игры.";
RussianCardTexts[CardNames.SILK_ROAD] = "Приносит{1}за каждые 4//карты Победы, которые у вас//есть (с округлением вниз).";
RussianCardTexts[CardNames.SPICE_MERCHANT] = "Вы можете выкинуть Сокровище//из руки, тогда выберите://либо |+2 Карты| и |+1 Действие|;//либо |+|[2]и |+1 Покупка|.";
RussianCardTexts[CardNames.STABLES] = "Вы можете сбросить//Сокровище, тогда |+3 Карты|//и |+1 Действие|.";
RussianCardTexts[CardNames.TRADER] = "Выкиньте карту из руки.//Получите Серебро за//каждую[1]её стоимости.//---//Когда вы получаете карту, можете//раскрыть эту карту из руки,//тогда обменяйте полученную//карту на Серебро.";
RussianCardTexts[CardNames.TUNNEL] = "{!2}//---//Когда вы сбрасываете эту//карту не в фазе Расчистки,//можете раскрыть её; тогда//получите Золото.";

RussianCardTexts[CardNames.RUIN_PILE] = "";
RussianCardTexts[CardNames.KNIGHTS] = "";
RussianCardTexts[CardNames.ABANDONED_MINE] = "|+|[1]";
RussianCardTexts[CardNames.ALTAR] = "Выкиньте карту из руки.//Получите карту//не дороже[5].";
RussianCardTexts[CardNames.ARMORY] = "Получите карту не дороже[4],//положив её на верх колоды.";
RussianCardTexts[CardNames.BAND_OF_MISFITS] = "Сыграйте более дешёвую//карту Действия (не Команду)//из Резерва,//оставляя её на месте.";
RussianCardTexts[CardNames.BANDIT_CAMP] = "|+1 Карта|//|+2 Действия|////Получите Добычу //из стопки Добычи.";
RussianCardTexts[CardNames.BEGGAR] = "Получите 3 Меди в руку.//---//Когда другой игрок играет//карту Атаки, вы можете сначала//сбросить эту карту, тогда//получите 2 Серебра, положив//одно на верх колоды.";
RussianCardTexts[CardNames.CATACOMBS] = "Посмотрите на 3 верхние карты//вашей колоды. Выберите одно из//двух: либо положите их в руку;//либо сбросьте их и |+3 Карты|.//---//Когда вы выкидываете эту карту,//получите карту дешевле неё.";
RussianCardTexts[CardNames.COUNT] = "Выберите одно из трёх://Сбросьте 2 карты; или положите//карту из руки на верх колоды;//или получите Медь.////Выберите одно из трёх: +[3];//или выкиньте вашу руку;//или получите Герцогство.";
RussianCardTexts[CardNames.COUNTERFEIT] = "[!1]//|+1 Покупка|////Вы можете сыграть//Сокровище (не Длительность)//из руки дважды.//Тогда выкиньте его.";
RussianCardTexts[CardNames.CULTIST] = "|+2 Карты|////Каждый другой игрок получает//карту Руин. Вы можете сыграть//Культиста из руки.//---//Когда вы выкидываете//эту карту, |+3 Карты|.";
RussianCardTexts[CardNames.DAME_ANNA] = "Вы можете выкинуть до//двух карт из руки.////Каждый другой игрок раскрывает//2 верхние карты своей колоды,//выкидывает одну из них//стоимостью от[3]до[6]и сбрасывает//остальные. Если таким образом//выкинут Рыцарь, выкиньте эту карту.";
RussianCardTexts[CardNames.DAME_JOSEPHINE] = "{2}////Каждый другой игрок раскрывает//2 верхние карты своей колоды,//выкидывает одну из них//стоимостью от[3]до[6]и сбрасывает//остальные. Если таким образом//выкинут Рыцарь, выкиньте эту карту.//---";
RussianCardTexts[CardNames.DAME_MOLLY] = "|+2 Действия|////Каждый другой игрок раскрывает//2 верхние карты своей колоды,//выкидывает одну из них//стоимостью от[3]до[6]и сбрасывает//остальные. Если таким образом//выкинут Рыцарь, выкиньте эту карту.";
RussianCardTexts[CardNames.DAME_NATALIE] = "Вы можете получить карту//не дороже[3].////Каждый другой игрок раскрывает//2 верхние карты своей колоды,//выкидывает одну из них//стоимостью от[3]до[6]и сбрасывает//остальные. Если таким образом//выкинут Рыцарь, выкиньте эту карту.";
RussianCardTexts[CardNames.DAME_SYLVIA] = "+[2]////Каждый другой игрок раскрывает//2 верхние карты своей колоды,//выкидывает одну из них//стоимостью от[3]до[6]и сбрасывает//остальные. Если таким образом//выкинут Рыцарь, выкиньте эту карту.";
RussianCardTexts[CardNames.DEATH_CART] = "Вы можете выкинуть эту карту//или карту Действия из руки,//тогда |+|[5].//---//Когда вы получаете эту карту,//получите 2 карты Руин.";
RussianCardTexts[CardNames.FEODUM] = "Приносит{1}за каждые//3 Серебра, которые у вас есть//(с округлением вниз).//---//Когда вы выкидываете эту//карту, получите 3 Серебра.";
RussianCardTexts[CardNames.FORAGER] = "|+1 Действие|//|+1 Покупка|////Выкиньте карту из руки, затем//|+|[1]за каждое различное//Сокровище на Свалке.";
RussianCardTexts[CardNames.FORTRESS] = "|+1 Карта|//|+2 Действия|//---//Когда вы выкидываете эту//карту, положите её в руку.";
RussianCardTexts[CardNames.GRAVEROBBER] = "[3]////Выберите одно из двух: Получите//карту со Свалки стоимостью//от[3]до[6]на верх колоды;//или выкиньте карту Действия из//руки и получите карту на//дороже выкинутой.";
RussianCardTexts[CardNames.HERMIT] = "Посмотрите на ваш сброс.//Можете выкинуть карту из сброса//или из руки (но не Сокровище).//Получите карту не дороже [3].//В конце фазы Покупок в этом ходу,//если вы не получили ни одной карты за неё,//то обменяйте эту карту (Отшельника)//на Безумца.";
RussianCardTexts[CardNames.HOVEL] = "Когда вы получаете карту//Победы, можете выкинуть//эту карту (Лачугу) из руки.";
RussianCardTexts[CardNames.HUNTING_GROUNDS] = "|+4 Карты|//---//Когда вы выкидываете//эту карту, получите//Герцогство или 3 Поместья.";
RussianCardTexts[CardNames.IRONMONGER] = "|+1 Карта|//|+1 Действия|//Раскройте верхнюю карту вашей//колоды; можете сбросить её. Так//или иначе, если раскрытая карта -//Действие, то |+1 Действие|;//Сокровище, то|+|[1];//Победа, то|+1 Карта|.";
RussianCardTexts[CardNames.JUNK_DEALER] = "|+1 Карта|//|+1 Действие|//|+[1]|////Выкиньте карту из руки.";
RussianCardTexts[CardNames.MADMAN] = "|+2 Действия|////Верните эту карту в стопку//Безумцев. Тогда |+1 Карта| за//каждую карту у вас в руке.////%(Эта карта не представлена%//%в Резерве)%";
RussianCardTexts[CardNames.MARKET_SQUARE] = "|+1 Карты|//|+1 Действие|//|+1 Покупка|//---//Когда одна из ваших карт выкинута,//вы можете сбросить эту карту//из руки, тогда получите Золото.";
RussianCardTexts[CardNames.MARAUDER] = "Получите Добычу из стопки//Добычи. Каждый другой//игрок получает карту Руин.";
RussianCardTexts[CardNames.MERCENARY] = "Вы можете выкинуть 2 карты//из руки. Тогда |+2 Карты|//+[2], и каждый другой игрок//сбрасывает до трёх карт в руке.////%(Эта карта не представлена%//%в Резерве)%";
RussianCardTexts[CardNames.MYSTIC] = "|+1 Действие|//|+[2]|////Назовите карту, затем//раскройте верхнюю карту//вашей колоды. Если вы//раскрыли названную карту,//то положите её в руку.";
RussianCardTexts[CardNames.NECROPOLIS] = "|+2 Действия|";
RussianCardTexts[CardNames.OVERGROWN_ESTATE] = "{!0}//---//Когда вы выкидываете//эту карту, |+1 Карта|.";
RussianCardTexts[CardNames.PILLAGE] = "Выкиньте эту карту. Тогда//получите две карты Добычи и//каждый другой игрок с пятью или//более картами в руке раскрывает//свою руку и сбрасывает карту по//вашему выбору.";
RussianCardTexts[CardNames.POOR_HOUSE] = "+[4]////Раскройте вашу руку. -[1]за//каждое Сокровище у вас//в руке ([0]- ваш минимум).";
RussianCardTexts[CardNames.PROCESSION] = "Вы можете сыграть карту//Действия (не Длительность)//из руки дважды.//Выкиньте её. Получите карту//Действия ровно на[1]//дороже неё.";
RussianCardTexts[CardNames.RATS] = "|+1 Карта|//|+1 Действие|////Получите Крыс. Выкиньте карту//из руки, но не Крыс (или //раскройте руку полную Крыс).//---//Когда вы выкидываете//эту карту, |+1 Карта|.";
RussianCardTexts[CardNames.REBUILD] = "|+1 Действие|////Назовите карту. Раскрывайте карты//с вашей колоды, пока не раскроете//карту Победы, которой вы не//называли. Сбросьте все остальные//раскрытые карты, выкиньте карту//Победы, и получите карту Победы//на[3]дороже выкинутой.";
RussianCardTexts[CardNames.ROGUE] = "+[2]////Если на Свалке есть карты стоимостью//от[3]до[6], получите одну из них.//Иначе каждый другой игрок раскрывает//2 верхние карты своей колоды,//выкидывает одну из них стоимостью от//[3]до[6]и сбрасывает остальные.";
RussianCardTexts[CardNames.RUINED_LIBRARY] = "|+1 Карта|";
RussianCardTexts[CardNames.RUINED_MARKET] = "|+1 Покупка|";
RussianCardTexts[CardNames.RUINED_VILLAGE] = "|+1 Действие|";
RussianCardTexts[CardNames.SAGE] = "|+1 Действие|////Раскрывайте карты с вашей//колоды до тех пор, пока не//раскроете такую, что стоит//[3]или дороже. Положите её//в руку и сбросьте остальные//раскрытые карты.";
RussianCardTexts[CardNames.SCAVENGER] = "+[2]////Вы можете положить вашу//колоду в сброс. Посмотрите//на ваш сброс и положите одну//карту оттуда на верх колоды.";
RussianCardTexts[CardNames.SIR_BAILEY] = "|+1 Карта|//|+1 Действие|////Каждый другой игрок раскрывает//2 верхние карты своей колоды,//выкидывает одну из них//стоимостью от[3]до[6]и сбрасывает//остальные. Если таким образом//выкинут Рыцарь, выкиньте эту карту.";
RussianCardTexts[CardNames.SIR_DESTRY] = "|+2 Карты|////Каждый другой игрок раскрывает//2 верхние карты своей колоды,//выкидывает одну из них//стоимостью от[3]до[6]и сбрасывает//остальные. Если таким образом//выкинут Рыцарь, выкиньте эту карту.";
RussianCardTexts[CardNames.SIR_MARTIN] = "|+2 Покупки|////Каждый другой игрок раскрывает//2 верхние карты своей колоды,//выкидывает одну из них//стоимостью от[3]до[6]и сбрасывает//остальные. Если таким образом//выкинут Рыцарь, выкиньте эту карту.";
RussianCardTexts[CardNames.SIR_MICHAEL] = "Каждый другой игрок//сбрасывает до трёх карт в руке.////Каждый другой игрок раскрывает//2 верхние карты своей колоды,//выкидывает одну из них//стоимостью от[3]до[6]и сбрасывает//остальные. Если таким образом//выкинут Рыцарь, выкиньте эту карту.";
RussianCardTexts[CardNames.SIR_VANDER] = "Каждый другой игрок раскрывает//2 верхние карты своей колоды,//выкидывает одну из них//стоимостью от[3]до[6]и сбрасывает//остальные. Если таким образом//выкинут Рыцарь, выкиньте эту карту.//---//Когда вы выкидываете эту//карту, получите Золото.";
RussianCardTexts[CardNames.SPOILS] = "[3]////Когда вы играете эту карту,//верните её в стопку Добычи.////%(Эта карта не представлена%//%в Резерве)%";
RussianCardTexts[CardNames.STOREROOM] = "|+1 Покупка|////Сбросьте любое число карт,//затем вытяните столько же.//Затем сбросьте любое число //карт и получите[1]за каждую.";
RussianCardTexts[CardNames.SQUIRE] = "+[1]////Выберите одно из трёх: //|+2 Действия|; |+2 Покупки|;// или получите Серебро.//---//Когда вы выкидываете эту //карту, получите карту Атаки.";
RussianCardTexts[CardNames.SURVIVORS] = "Посмотрите на две верхние//карты вашей колоды.//Сбросьте их или положите их //назад в любом порядке.";
RussianCardTexts[CardNames.URCHIN] = "|+1 Карта|//|+1 Действие|////Каждый другой игрок сбрасывает//до четырёх карт в руке.//---//Когда вы играете другую Атаку в то время//как эта карта в игре, вы можете сначала//выкинуть эту карту, тогда получите//Наёмного Убийцу из стопки Наёмных Убийц.";
RussianCardTexts[CardNames.VAGRANT] = "|+1 Карта|//|+1 Действие|////Раскройте верхнюю карту вашей//колоды. Если это Проклятие,//Руины, Убежище или карта//Победы, положите её в руку.";
RussianCardTexts[CardNames.WANDERING_MINSTREL] = "|+1 Карта|//|+2 Действия|////Раскройте 3 верхние карты вашей//колоды. Положите карты Действия//назад в любом порядке и сбросьте//остальные раскрытые карты.";

RussianCardTexts[CardNames.ADVISOR] = "|+1 Действие|////Раскройте 3 верхние карты//вашей колоды. Игрок слева//от вас выбирает одну из них.//Сбросьте её и положите//остальные в руку.";
RussianCardTexts[CardNames.BAKER] = "|+1 Карта|//|+1 Действие|//|+1 Монетка|//---//Подготовка: Каждый игрок//получает |+1 Монетку|.";
RussianCardTexts[CardNames.BUTCHER] = "|+2 Монетки|////Можете выкинуть карту из руки.//Тогда получите карту//не дороже, чем выкинутая карта//плюс [1] за каждую//заплаченную Монетку.";
RussianCardTexts[CardNames.CANDLESTICK_MAKER] = "|+1 Действие|//|+1 Покупка|//|+1 Монетка|";
RussianCardTexts[CardNames.DOCTOR] = "Назовите карту. Раскройте 3 верхние//карты вашей колоды. Выкиньте раскрытые//копии названной карты. Остальные//положите назад в любом порядке.//---//Переплата: За каждую [1],//которой вы переплатили, посмотрите на//верхнюю карту вашей колоды; выкиньте//её, сбросьте её, или положите её назад.";
RussianCardTexts[CardNames.HERALD] = "|+1 Карта|//|+1 Действие|////Раскройте верхнюю карту вашей колоды.//Если это карта Действия, то сыграйте её.//---//Переплата: За каждую [1], которой//вы переплатили, посмотрите на ваш сброс//и положите карту оттуда на верх колоды.";
RussianCardTexts[CardNames.JOURNEYMAN] = "Назовите карту. Раскрывайте//карты с вашей колоды, пока//не раскроете 3, которые//называются иначе. Положите//их в руку и сбросьте остаток.";
RussianCardTexts[CardNames.MASTERPIECE] = "[!1]//---//Переплата: Получите Серебро//за каждую [1], которой вы переплатили.";
RussianCardTexts[CardNames.MERCHANT_GUILD] = "|+1 Покупка|//---//|+[1]|////В конце вашей фазы Покупок//в этот ход |+1 Монеткa| за каждую//карту которую вы получили//в течение хода.";
RussianCardTexts[CardNames.PLAZA] = "|+1 Карта|//|+2 Действия|////Вы можете сбросить//Сокровище из руки; тогда//|+1 Монеткa|.";
RussianCardTexts[CardNames.TAXMAN] = "Вы можете выкинуть Сокровище из//руки. Каждый другой игрок с пятью//или более картами в руке сбрасывает//его копию (или раскрывает руку без//него). Получите Сокровище на верх//вашей колоды, стоимостью не более//чем на[3]дороже выкинутого.";
RussianCardTexts[CardNames.SOOTHSAYER] = "Получите Золото.//Каждый другой игрок//получает Проклятие, и если//получил, то тянет карту.";
RussianCardTexts[CardNames.STONEMASON] = "Выкиньте карту из руки.//Получите 2 карты, каждая//дешевле выкинутой.//---//Переплата: Получите//2 карты Действия, каждая из которых//стоит ровно столько, сколько//вы переплатили.";

RussianCardTexts[CardNames.ALMS] = "Один раз за ход: Если у вас нет Сокровищ в игре,//получите карту не дороже[4].";
RussianCardTexts[CardNames.AMULET] = "Сейчас и в начале вашего//следующего хода: +[1];//или выкиньте карту из руки;//или получите Серебро.";
RussianCardTexts[CardNames.ARTIFICER] = "|+1 Карта|//|+1 Действие|//|+[1]|////Сбросьте любое число карт из руки.//Вы можете получить карту стоимостью//ровно[1]за каждую сброшенную карту,//положив её на верх колоды.";
RussianCardTexts[CardNames.BALL] = "Возьмите ваш Жетон -[1]. Получите 2 карты не//дороже[4]каждая.";
RussianCardTexts[CardNames.BONFIRE] = "Выкиньте до двух карт Меди из игры.";
RussianCardTexts[CardNames.BORROW] = "Один раз за ход: |+1 Покупка|. Если ваш Жетон -1 Карты//не лежит на вашей колоде, то положите его туда и +[1].";
RussianCardTexts[CardNames.BRIDGE_TROLL] = "Каждый другой игрок берёт//свой Жетон -[1]. В этот и в//следующий ваш ход, карты//стоят на [1] дешевле.//Сейчас и в начале//следующего хода: |+1 Покупка|.";
RussianCardTexts[CardNames.CARAVAN_GUARD] = "|+1 Карта|//|+1 Действие|//В начале вашего//следующего хода, |+|[1].//---//Когда другой игрок играет//карту Атаки, вы можете//сначала сыграть эту карту//из вашей руки.";
RussianCardTexts[CardNames.CHAMPION] = "|+1 Действие|////До конца игры, когда другой//игрок играет Атаку, она вас//не затрагивает, когда вы играете//Действие, |+1 Действие|.////%(Эта карта не представлена в Резерве)%";
RussianCardTexts[CardNames.COIN_OF_THE_REALM] = "[!1]//Положите эту карту на ваш мат Таверны.//---//Сразу после того, как вы сыграли//Действие, можете вызвать эту//карту, тогда |+2 Действия|.";
RussianCardTexts[CardNames.DISCIPLE] = "Вы можете дважды сыграть//карту Действия из руки.//Получите её копию.//---//Когда вы сбрасываете эту карту//из игры, можете обменять её на//Учителя.////%(Эта карта не представлена%//%в Резерве)%";
RussianCardTexts[CardNames.DISTANT_LANDS] = "Положите эту карту на ваш мат//Таверны.//---//Приносит{0}если лежит на//вашем мате Таверны в конце//игры (иначе приносит{4}).";
RussianCardTexts[CardNames.DUNGEON] = "|+1 Действие|////Сейчас и в начале вашего//следующего хода: |+2 Карты|,//затем сбросьте 2 карты.";
RussianCardTexts[CardNames.DUPLICATE] = "Положите эту карту на ваш мат//Таверны.//---//Когда вы получаете карту стоимостью//до[6], можете вызвать эту карту//(Дублирование), чтобы получить//копию получаемой карты.";
RussianCardTexts[CardNames.EXPEDITION] = "Вытяните дополнительные 2 карты для вашей//следующей руки.";
RussianCardTexts[CardNames.FERRY] = "Передвиньте ваш Жетон -[2]на стопку карт Действия//(карты из этой стопки стоят на[2]дешевле в ваши//ходы, но не меньше[0]).";
RussianCardTexts[CardNames.FUGITIVE] = "|+2 Карты|//|+1 Действие|////Сбросьте карту.//---//Когда вы сбрасываете эту карту//из игры, можете обменять//её на Ученика.////%(Эта карта не представлена%//%в Резерве)%";
RussianCardTexts[CardNames.GEAR] = "|+2 Карты|////Отложите в сторону до двух//карт из руки, рубашкой вверх.//В начале вашего следующего//хода возьмите их в руку.";
RussianCardTexts[CardNames.GIANT] = "////Переверните ваш Жетон Путешествий//(в начале игры он лежит лицом вверх).//Если он лицом вниз, +[1]. Если он//лицом вверх, +[5]и каждый другой//игрок раскрывает верхнюю карту своей//колоды, выкидывает её, если она стоит от//[3]до[6]в противном случае сбрасывает//её и получает Проклятие.";
RussianCardTexts[CardNames.GUIDE] = "|+1 Карта|//|+1 Действие|////Положите эту карту на ваш мат//Таверны.//---//В начале вашего хода вы можете//вызвать эту карту, тогда сбросьте//вашу руку и вытяните 5 карт.";
RussianCardTexts[CardNames.HAUNTED_WOODS] = "В начале вашего следующего//хода, |+3 Карты|.//До тех пор, когда другой игрок//получает купленную им карту,//он кладёт свою руку на верх//своей колоды в любом порядке.";
RussianCardTexts[CardNames.HERO] = "|+[2]|//---//Получите Сокровище.////Когда вы сбрасываете эту карту//из игры, можете обменять её на//Чемпиона.////%(Эта карта не представлена%//%в Резерве)%";
RussianCardTexts[CardNames.HIRELING] = "В начале каждого вашего//хода до конца игры:////|+1 Карта|";
RussianCardTexts[CardNames.INHERITANCE] = "Один раз за игру: Отложите в сторону карту Действия (не Команду)//из Резерва не дороже[4]. Передвиньте на неё ваш Жетон Поместья.//(В течение ваших ходов, Поместья также являются//картами Действия и Командами с таким текстом://\"Сыграйте карту с вашим токеном Поместья, оставляя её на месте\").";
RussianCardTexts[CardNames.LOST_ARTS] = "Передвиньте ваш Жетон +1 Действия на стопку карт Действия//(когда вы играете карту из этой стопки, сначала получите//+1 Действие).";
RussianCardTexts[CardNames.LOST_CITY] = "|+2 Карты|//|+2 Действия|//---//Когда вы получаете эту карту,//каждый другой игрок тянет//карту.";
RussianCardTexts[CardNames.MAGPIE] = "|+1 Карта|//|+1 Действие|////Раскройте верхнюю карту вашей//колоды. Если это Сокровище, то//положите его в руку. Если это//карта Действия или Победы, то//получите Сороку.";
RussianCardTexts[CardNames.MESSENGER] = "|+1 Buy|//|+[2]|//Вы можете положить вашу колоду//в ваш сброс.////Когда эта карта - первая//полученная вами за текущую//фазу Покупок, получите карту//не дороже [4], каждый другой//игрок получает её копию.";
RussianCardTexts[CardNames.MISER] = "Выберите одно: Положите//карту Меди из руки на ваш мат//Таверны; или +[1]за каждую//карту Меди на вашем мате//Таверны.";
RussianCardTexts[CardNames.MISSION] = "После текущего хода получите ещё один (но не третий ход подряд),//в котором вы не сможете покупать карты.";
RussianCardTexts[CardNames.PATHFINDING] = "Передвиньте ваш Жетон +1 Карты на стопку карт Действия//(когда вы играете карту из этой стопки, сначала получите//+1 Карту).";
RussianCardTexts[CardNames.PAGE] = "|+1 Карта|//|+1 Действие|//---//Когда вы сбрасываете эту карту//из игры, можете обменять её на//Охотника за Сокровищами.";
RussianCardTexts[CardNames.PEASANT] = "---//|+1 Покупка|//|+[1]|////Когда вы сбрасываете эту карту//из игры, можете обменять её на//Солдата.";
RussianCardTexts[CardNames.PILGRIMAGE] = "Один раз за ход: Переверните ваш Жетон Путешествий (в начале//игры он лежит лицом вверх). Если он лицом вверх, то выберите до//трёх различных карт у вас в игре, и получите копию каждой из них.";
RussianCardTexts[CardNames.PLAN] = "Передвиньте ваш Жетон Свалки на стопку карт//Действия (когда вы получаете карту из этой стопки,//можете выкинуть карту из руки).";
RussianCardTexts[CardNames.PORT] = "|+1 Карта|//|+2 Действия|//---//Когда вы получаете эту карту,//получите ещё один Порт//(но за этот уже не получайте//дополнительного).";
RussianCardTexts[CardNames.QUEST] = "Вы можете сбросить Атаку, два Проклятья,//или шесть карт. Тогда получите Золото.";
RussianCardTexts[CardNames.RANGER] = "|+1 Покупка|////Переверните ваш Жетон//Путешествий (в начале игры//он лежит лицом вверх). Если//он лицом вверх, то +5 Карт.";
RussianCardTexts[CardNames.RAID] = "Получите Серебро за каждое Серебро у вас в игре.//Каждый другой игрок кладёт свой Жетон -1 Карты//на верх своей колоды.";
RussianCardTexts[CardNames.RATCATCHER] = "|+1 Карта|//|+1 Действие|////Положите эту карту на ваш мат//Таверны.//---//В начале вашего хода вы можете//вызвать эту карту, тогда выкиньте//карту из руки.";
RussianCardTexts[CardNames.RAZE] = "|+1 Действие|////Выкиньте эту карту или карту из//руки. Посмотрите на столько//карт с верха вашей колоды,//сколько[]стоила выкинутая//карта. Положите одну из них//в руку и сбросьте остальные.";
RussianCardTexts[CardNames.RELIC] = "[!2]////Каждый другой игрок кладёт свой//Жетон -1 Карты на свою колоду.";
RussianCardTexts[CardNames.ROYAL_CARRIAGE] = "|+1 Действие|////Положите эту карту на ваш мат//Таверны.//---//Сразу после того, как вы сыграли//Действие, если оно до сих пор в//игре, можете вызвать эту карту,//чтобы сыграть это Действие ещё раз.";
RussianCardTexts[CardNames.SAVE] = "Один раз за ход: |+1 Покупка|. Отложите в сторону карту//из руки и положите её в руку в конце хода (после//фазы Расчистки).";
RussianCardTexts[CardNames.SCOUTING_PARTY] = "|+1 Покупка|////Посмотрите на 5 верхних карт вашей колоды. Сбросьте 3//из них и положите остальные обратно в любом порядке.";
RussianCardTexts[CardNames.SEAWAY] = "Получите карту Действия не дороже[4]. Передвиньте ваш//Жетон +1 Покупки на стопку полученной вами карты (когда вы//играете карту из этой стопки, сначала получите +1 Покупку).";
RussianCardTexts[CardNames.SOLDIER] = "|+[1]|//---//+[2]за каждую другую Атаку у вас в игре.//Каждый другой игрок с четырьмя или//более картами в руке сбрасывает карту.////Когда вы сбрасываете эту карту из игры,//можете обменять её на Дезертира.////%(Эта карта не представлена%//%в Резерве)%";
RussianCardTexts[CardNames.STORYTELLER] = "|+1 Действие|////Сыграйте до трёх Сокровищ из руки.//|+1 Карта| и заплатите все ваши [],//|+1 Карта| за каждую заплаченную//вами [1].";
RussianCardTexts[CardNames.SWAMP_HAG] = "В начале вашего следующего//хода: +[3].//До тех пор, когда другой игрок //получает покупаемую им карту,//он получает Проклятие.";
RussianCardTexts[CardNames.TEACHER] = "Положите эту карту на ваш мат//Таверны.//---//В начале вашего хода вы можете//вызвать эту карту, тогда передвиньте//ваш Жетон +1 Карты, +1 Действия,//+1 Покупки или +[1]на стопку//карт Действия, на которой у вас//нет жетонов.////%(Эта карта не представлена в Резерве)%";
RussianCardTexts[CardNames.TRAVELLING_FAIR] = "|+2 Покупки|////Когда вы покупаете карту в этот ход,//вы можете положить её на верх колоды.";
RussianCardTexts[CardNames.TRADE] = "Выкиньте до двух карт из руки.//Получите Серебро за каждую выкинутую карту.";
RussianCardTexts[CardNames.TRAINING] = "Передвиньте ваш Жетон +[1]на стопку карт Действия//(когда вы играете карту из этой стопки, сначала//получите +[1]).";
RussianCardTexts[CardNames.TRANSMOGRIFY] = "|+1 Действие|////Положите эту карту на ваш мат//Таверны.//---//В начале вашего хода вы можете//вызвать эту карту, тогда выкиньте//карту из руки, получите карту не//более чем на[1]дороже//выкинутой, и положите её в руку.";
RussianCardTexts[CardNames.TREASURE_TROVE] = "[!2]////Получите Золото и Медь.";
RussianCardTexts[CardNames.TREASURE_HUNTER] = "|+1 Действие|//|+[1]|//---//Получите Серебро за каждую карту,//которую игрок справа от вас получил//в его последний ход.////Когда вы сбрасываете эту карту из//игры, можете обменять её на Воина.////%(Эта карта не представлена в Резерве)%";
RussianCardTexts[CardNames.WARRIOR] = "|+2 Карты|////За каждого Путешественника у вас в игре//(включая эту карту), каждый другой игрок//сбрасывает верхнюю карту своей колоды и//выкидывает её, если она стоит[3]или[4].//---//Когда вы сбрасываете эту карту//из игры, можете обменять её на Героя.//%(Эта карта не представлена в Резерве)%";
RussianCardTexts[CardNames.WINE_MERCHANT] = "|+1 Покупка|//|+[4]|//---//Положите эту карту на ваш мат//Таверны.////В конце вашей фазы Покупок, если у//вас осталось непотраченными хотя бы[2]//можете сбросить эту карту с мата Таверны.";

RussianCardTexts[CardNames.ENCAMPMENT] = "|+2 Карты|//|+2 Действия|////Вы можете раскрыть Золото или//Награбленное из руки. Иначе//отложите эту карту в сторону и//верните её в стопку в начале//фазы Расчистки.";
RussianCardTexts[CardNames.PLUNDER] = "[!2]////+{1}";
RussianCardTexts[CardNames.PATRICIAN] = "|+1 Карта|//|+1 Действие|////Раскройте верхнюю карту//вашей колоды. Если она стоит//[5]или больше, то положите//её в руку.";
RussianCardTexts[CardNames.EMPORIUM] = "|+1 Карта|//|+1 Действие|//|+[1]|//---//Когда вы получаете эту карту,//если у вас есть хотя бы 5 карт//Действия в игре,{+2}.";
RussianCardTexts[CardNames.SETTLERS] = "|+1 Карта|//|+1 Действие|////Вы можете раскрыть карту//Меди из вашего сброса и//положить её в руку.";
RussianCardTexts[CardNames.BUSTLING_VILLAGE] = "|+1 Карта|//|+3 Действия|////Вы можете раскрыть карту//Поселенцев из вашего сброса и//положить её в руку.";
RussianCardTexts[CardNames.CATAPULT] = "|+|[1]////Выкиньте карту из руки. Если она//стоила[3]или больше, то каждый//другой игрок получает Проклятие.//Если она была Сокровищем, то//каждый другой игрок сбрасывает//до трёх карт в руке.";
RussianCardTexts[CardNames.ROCKS] = "[!1]//---//Когда вы получаете или выкидываете//эту карту, получите Серебро; если//это ваша фаза Покупки, то полученное//Серебро положите на верх колоды,//иначе в руку.";
RussianCardTexts[CardNames.GLADIATOR] = "|+|[2]////Раскройте карту из руки.//Игрок слева от вас может раскрыть//копию такой карты из своей руки.//Иначе, +[1]и выкиньте Гладиатора//из Резерва.";
RussianCardTexts[CardNames.FORTUNE] = "|+1 Покупка|////Когда вы играете эту карту,//удвойте ваши[], если вы//ещё так не делали в этот ход.//---//Когда вы получаете эту карту,//получите Золото за каждого//Гладиатора у вас в игре.";
RussianCardTexts[CardNames.CASTLES] = "Отсортируйте стопку Замков по//стоимости, самыми дорогими вниз.//Для игры вдвоём используйте//только по одной копии каждого//Замка. Только верхняя карта//из стопки может быть получена//или куплена.";
RussianCardTexts[CardNames.HUMBLE_CASTLE] = "[!1]//---//Приносит {1} за каждый ваш//Замок.";
RussianCardTexts[CardNames.CRUMBLING_CASTLE] = "{!1}//---//Когда вы получаете или//выкидываете эту карту, +{1}//и получите Серебро.";
RussianCardTexts[CardNames.SMALL_CASTLE] = "Выкиньте эту карту//или другой замок из руки,//тогда получите Замок.//---//{!2}";
RussianCardTexts[CardNames.HAUNTED_CASTLE] = "{!2}//---//Когда вы получаете эту карту во время//вашего хода, получите Золото, и//каждый другой игрок с как минимум//пятью картами в руке кладёт 2 карты//из своей руки себе на колоду.";
RussianCardTexts[CardNames.OPULENT_CASTLE] = "Сбросьте любое число карт//Победы из руки, раскрывая их. //|+|[2] за каждую сброшенную карту.//---//{!3}";
RussianCardTexts[CardNames.SPRAWLING_CASTLE] = "{!4}//---//Когда вы получаете эту карту,//получите Герцогство или//3 Поместья.";
RussianCardTexts[CardNames.GRAND_CASTLE] = "{!5}//---//Когда вы получаете эту карту,//раскройте вашу руку. +{1} за//каждую карту Победы в вашей//руке и/или в игре.";
RussianCardTexts[CardNames.KINGS_CASTLE] = "Приносит {2} за каждый ваш//Замок.";
RussianCardTexts[CardNames.ADVANCE] = "Вы можете выкинуть карту Действия из руки,//тогда получите карту Действия не дороже[6].";
RussianCardTexts[CardNames.ANNEX] = "Посмотрите на свой сброс. Оставьте там 5 или//меньше карт, остальные замешайте в вашу колоду.//Получите Герцогство.";
RussianCardTexts[CardNames.ARCHIVE] = "|+1 Действие|//Отложите в сторону 3 верхние//карты вашей колоды (вы можете//их видеть). Сейчас и в начале//ваших следующих двух ходов//кладите по одной из//отложенных карт в руку.";
RussianCardTexts[CardNames.AQUEDUCT] = "Когда вы получаете Сокровище, переложите {1} с его стопки сюда.//Когда вы получаете карту Победы, возьмите все {} отсюда.//---//Подготовка: Положите по {8} на стопки Серебра и Золота.";
RussianCardTexts[CardNames.ARENA] = "В начале вашей фазы Покупки вы можете сбросить карту//Действия, тогда возьмите {2} отсюда.//---//Подготовка: Положите сюда по {6} за каждого игрока.";
RussianCardTexts[CardNames.BANDIT_FORT] = "При подсчёте очков,{-2}за каждые ваши//Серебро и Золото.";
RussianCardTexts[CardNames.BANQUET] = "Получите 2 Меди и карту не дороже[5]//(но не карту победы).";
RussianCardTexts[CardNames.BASILICA] = "Когда вы получаете карту в вашей фазе Покупок и у вас//осталось [2] или больше, возьмите {2} отсюда.//---//Подготовка: Положите сюда по {6} за каждого игрока.";
RussianCardTexts[CardNames.BATHS] = "Когда вы заканчиваете ход не получив ни одной карты,//возьмите {2} отсюда.//---//Подготовка: Положите сюда по {6} за каждого игрока.";
RussianCardTexts[CardNames.BATTLEFIELD] = "Когда вы получаете карту Победы, возьмите {2} отсюда.//---//Подготовка: Положите сюда по {6} за каждого игрока.";
RussianCardTexts[CardNames.CAPITAL] = "[!6]//|+1 Покупка|//---//Когда вы сбрасываете эту карту//из игры, возьмите [6D], затем//можете заплатить [D].";
RussianCardTexts[CardNames.CHARM] = "Выберите: |+1 Покупка| и +[2];//либо в следующий раз, когда//вы будете получать карту в этот//ход, вы можете также получить//карту такой же стоимости,//но с другим названием.";
RussianCardTexts[CardNames.CHARIOT_RACE] = "|+1 Действие|////Раскройте верхнюю карту вашей//колоды и положите её в руку.//Игрок слева от вас раскрывает//верхнюю карту своей колоды.//Если ваша карта стоит больше,//+[1]и{+1}.";
RussianCardTexts[CardNames.CITY_QUARTER] = "|+2 Действия|//////Раскройте вашу руку. //|+1 Карта| за каждую//раскрытую карту Действия.";
RussianCardTexts[CardNames.COLONNADE] = "Когда вы получаете карту Действия в вашей фазе Покупок и у вас//есть копия такой карты в игре, возьмите {2} отсюда.//---//Подготовка: Положите сюда по {6} за каждого игрока.";
RussianCardTexts[CardNames.CONQUEST] = "Получите 2 Серебра.//{+1} за каждое Серебро, полученное вами в этот ход.";
RussianCardTexts[CardNames.CROWN] = "Если сейчас ваша фаза//Действия, можете сыграть//карту Действия из руки дважды.//Если сейчас ваша фаза//Покупки, можете сыграть карту//Сокровища из руки дважды.";
RussianCardTexts[CardNames.DELVE] = "|+1 Покупка|//Получите Серебро.";
RussianCardTexts[CardNames.DEFILED_SHRINE] = "Когда вы получаете карту Действия, передвиньте {1} с её стопки//сюда. Когда вы получаете Проклятие, возьмите все {} отсюда.//---//Подготовка: Положите{2}на каждую стопку Резерва//карт Действия (но не карт Скопления).";
RussianCardTexts[CardNames.DOMINATE] = "Получите Провинцию, тогда |+|{9}.";
RussianCardTexts[CardNames.DONATE] = "В начале вашего следующего хода, перед другими действиями,//положите вашу колоду и сброс в руку,//выкиньте любое их число,//затем замешайте руку в колоду и вытяните 5 карт.";
RussianCardTexts[CardNames.ENCHANTRESS] = "До начала вашего следующего//хода, когда другой игрок в первый раз//за свой ход играет карту Действия,//он получает |+1 Карту| и//|+1 Действие| вместо того, чтобы//следовать тексту сыгранной им карты.////В начале вашего следующего хода,//|+2 Карты|";
RussianCardTexts[CardNames.ENGINEER] = "Получите карту не дороже [4].//Можете выкинуть эту карту//(Инженера, не полученную карту).//Получите карту не дороже [4].";
RussianCardTexts[CardNames.FARMERS_MARKET] = "|+1 Покупка|////Если на стопке Фермерских//Рынков есть хотя бы {4} то//возьмите их и выкиньте эту//карту. Иначе добавьте//{1} на стопку и |+|[1]//за каждое {1} на стопке.";
RussianCardTexts[CardNames.FORUM] = "|+3 Карты|//|+1 Действие|//Сбросьте 2 карты.//---//Когда вы получаете эту карту,//|+1 Покупка.|";
RussianCardTexts[CardNames.FOUNTAIN] = "При подсчёте очков{15}если у вас есть хотя бы//10 Меди.";
RussianCardTexts[CardNames.GROUNDSKEEPER] = "|+1 Карта|//|+1 Действие|//---//В этот ход, когда//вы получаете карту Победы, |+|{1}.";
RussianCardTexts[CardNames.KEEP] = "При подсчёте очков{5}за каждое разное ваше Сокровище,//копий которого у вас не меньше, чем у каждого другого//игрока.";
RussianCardTexts[CardNames.LABYRINTH] = "Когда вы получаете вторую карту за время вашего хода,//возьмите {2} отсюда.//---//Подготовка: Положите сюда по {6} за каждого игрока.";
RussianCardTexts[CardNames.LEGIONARY] = "|+|[3]////Вы можете раскрыть Золото//из руки, тогда каждый//другой игрок сбрасывает//до двух карт в руке и//тянет одну карту.";
RussianCardTexts[CardNames.MOUNTAIN_PASS] = "Когда вы первый игрок, который получает Провинцию,//каждый игрок делает ставку до[40D], заканчивая вами. Игрок//с наибольшей ставкой получает{+8}и забирает поставленный им[D]";
RussianCardTexts[CardNames.MUSEUM] = "При подсчёте очков {2} за каждую разную карту,//которая у вас есть.";
RussianCardTexts[CardNames.OBELISK] = "При подсчёте очков {2} за каждую вашу карту//из выбранной стопки.//---//Подготовка: Выберите любую стопку карт Действия из Резерва.";
RussianCardTexts[CardNames.ORCHARD] = "При подсчёте очков {4} за каждую разную карту//Действия, которых у вас есть не меньше трёх копий.";
RussianCardTexts[CardNames.OVERLORD] = "Сыграйте карту Действия//(не Команду)//из Резерва не дороже [5],//оставляя её на месте.";
RussianCardTexts[CardNames.PALACE] = "При подсчёте очков {3} за каждый набор//из Меди, Серебра и Золота.";
RussianCardTexts[CardNames.RITUAL] = "Получите Проклятие, тогда выкиньте карту из руки.//{+1}за каждую[1]её стоимости.";
RussianCardTexts[CardNames.ROYAL_BLACKSMITH] = "|+5 Карт|//////Раскройте вашу руку;//сбросьте всю Медь.";
RussianCardTexts[CardNames.SACRIFICE] = "Выкиньте карту из руки.//Если это...////Действие, |+2 Карты|,// |+2 Действия|//Сокровище, |+|[2]//карта Победы, |+|{2}";
RussianCardTexts[CardNames.SALT_THE_EARTH] = "{+1}//Выкиньте карту Победы из Резерва.";
RussianCardTexts[CardNames.TAX] = "Добавьте[2D]на стопку Резерва.//---//Подготовка: Добавьте [1D] на каждую стопку Резерва.//Когда игрок получает карту, он берёт весь [D] с её стопки.";
RussianCardTexts[CardNames.TEMPLE] = "|+{1}|//Выкиньте от одной до трёх//разных карт из руки.//Добавьте {1} на стопку Храмов.//---//Когда вы получаете эту карту,//возьмите все {} со стопки Храмов.";
RussianCardTexts[CardNames.TOMB] = "Когда вы выкидываете карту,{+1}.";
RussianCardTexts[CardNames.TOWER] = "При подсчете очков,{1}за каждую вашу карту//(но не карту Победы) из пустой стопки.";
RussianCardTexts[CardNames.TRIUMPH] = "Получите Поместье, тогда{+1}//за каждую полученную вами в этот ход карту.";
RussianCardTexts[CardNames.TRIUMPHAL_ARCH] = "При подсчёте очков{3}за каждую вашу копию второй по//частоте карты Действия в вашей колоде.";
RussianCardTexts[CardNames.VILLA] = "|+2 Действия|//|+1 Покупка|//---//|+[1]|////Когда вы получаете эту карту,//положите её в руку, |+1 Действие|,//и если сейчас ваша фаза Покупки,//то вернитесь в фазу Действия.";
RussianCardTexts[CardNames.WALL] = "При подсчете очков{-1}за каждую вашу карту,//начиная с шестнадцатой.";
RussianCardTexts[CardNames.WOLF_DEN] = "При подсчете очков{-3}за каждую такую карту,//которой у вас ровно одна копия.";
RussianCardTexts[CardNames.WEDDING] = "{+1}//Получите Золото.";
RussianCardTexts[CardNames.WILD_HUNT] = "Выберите: |+3 Карты| и//добавьте {1} на стопку Дикой//Охоты; либо получите//Поместье и тогда возьмите все//{} со стопки.";
RussianCardTexts[CardNames.WINDFALL] = "Если ваши колода и сброс пусты, получите 3 Золота.";

RussianCardTexts[CardNames.PIG] = "";
RussianCardTexts[CardNames.MINUS_CARD] = "";
RussianCardTexts[CardNames.MINUS_COIN] = "";
RussianCardTexts[CardNames.STATE_LIMBO] = "";
RussianCardTexts[CardNames.DRUID_BOONS] = "";
RussianCardTexts[CardNames.BOON_DRAWPILE] = "";
RussianCardTexts[CardNames.BOON_DISCARDPILE] = "";
RussianCardTexts[CardNames.THE_EARTHS_GIFT] = "Вы можете сбросить Сокровище чтобы//получить карту не дороже[4].";
RussianCardTexts[CardNames.THE_FIELDS_GIFT] = "|+1 Действие|//|+[1]|////(Сохраните это Благодеяние до фазы Расчистки.)";
RussianCardTexts[CardNames.THE_FLAMES_GIFT] = "Вы можете выкинуть карту из руки.";
RussianCardTexts[CardNames.THE_FORESTS_GIFT] = "|+1 Покупка|//|+[1]|////(Сохраните это Благодеяние до фазы Расчистки.)";
RussianCardTexts[CardNames.THE_MOONS_GIFT] = "Посмотрите на ваш сброс. Вы можете//положить карту оттуда на верх колоды.";
RussianCardTexts[CardNames.THE_MOUNTAINS_GIFT] = "Получите Серебро.";
RussianCardTexts[CardNames.THE_RIVERS_GIFT] = "|+1 Карта| в конце вашего хода.//(Сохраните это Благодеяние до фазы Расчистки.)";
RussianCardTexts[CardNames.THE_SEAS_GIFT] = "|+1 Карта|";
RussianCardTexts[CardNames.THE_SKYS_GIFT] = "Вы можете сбросить 3 карты чтобы//получить Золото.";
RussianCardTexts[CardNames.THE_SUNS_GIFT] = "Посмотрите на 4 верхние карты вашей колоды.//Сбросьте любое их количество, затем положите//остальные назад в любом порядке.";
RussianCardTexts[CardNames.THE_SWAMPS_GIFT] = "Получите Блуждающий Огонёк из//стопки Блуждающих Огоньков.";
RussianCardTexts[CardNames.THE_WINDS_GIFT] = "|+2 Карты|//Сбросьте 2 карты.";
RussianCardTexts[CardNames.HEX_DRAWPILE] = "";
RussianCardTexts[CardNames.HEX_DISCARDPILE] = "";
RussianCardTexts[CardNames.BAD_OMENS] = "Положите вашу колоду в сброс. Посмотрите на//него и положите две карты Меди на верх колоды//(или раскройте сброс, показывая, что Меди нет).";
RussianCardTexts[CardNames.DELUSION] = "Если вы не Сбиты с Толку и не//Завистливы, то станьте Сбитым с Толку.";
RussianCardTexts[CardNames.ENVY] = "Если вы не Сбиты с Толку и не//Завистливы, то станьте Завистливым.";
RussianCardTexts[CardNames.FAMINE] = "Раскройте 3 верхние карты вашей колоды.//Сбросьте карты Действия. Замешайте остальные//раскрытые карту в вашу колоду.";
RussianCardTexts[CardNames.FEAR] = "Если у вас есть хотя бы 5 карт в руке, сбросьте//карту Действия или Сокровище (или раскройте//руку, показывая, что сбрасывать нечего).";
RussianCardTexts[CardNames.GREED] = "Получите карту Меди на верх//вашей колоды.";
RussianCardTexts[CardNames.HAUNTING] = "Если у вас есть хотя бы 4 карты в руке,//положите одну из них на верх вашей колоды.";
RussianCardTexts[CardNames.LOCUSTS] = "Выкиньте верхнюю карту вашей колоды. Если это Медь или//Поместье, получите Проклятие. Иначе, получите карту дешевле//выкинутой, и имеющую хотя бы один из типов выкинутой карты.";
RussianCardTexts[CardNames.MISERY] = "Если это ваше первое Несчастье за игру, станьте//Несчастным. Иначе, перестаньте быть//Несчастным и станьте Дважды Несчастным.";
RussianCardTexts[CardNames.PLAGUE] = "Получите Проклятие в руку.";
RussianCardTexts[CardNames.POVERTY] = "Сбросьте до трёх карт в руке.";
RussianCardTexts[CardNames.WAR] = "Раскрывайте карты с вашей колоды, пока не//раскроете ту, которая стоит[3]или[4]. Выкиньте//её и сбросьте остальные раскрытые карты.";
RussianCardTexts[CardNames.MISERABLE] = "{!-2}";
RussianCardTexts[CardNames.TWICE_MISERABLE] = "{!-4}";
RussianCardTexts[CardNames.ENVIOUS] = "В начале вашей фазы Покупок, верните это//Состояние, и Серебро и Золото приносят по[1]//в этот ход.";
RussianCardTexts[CardNames.DELUDED] = "В начале вашей фазы Покупок, верните это//Состояние, и вы не можете покупать карты//Действия в этот ход.";
RussianCardTexts[CardNames.LOST_IN_THE_WOODS] = "В начале вашего хода, вы можете сбросить//карту чтобы принять Благодеяние.";
RussianCardTexts[CardNames.BARD] = "|+|[2]//Примите Благодеяние.";
RussianCardTexts[CardNames.BLESSED_VILLAGE] = "|+1 Карта|//|+2 Действия|//---//Когда вы получаете эту карту,//примите Благодеяние.//Используйте его сейчас или//в начале следующего хода.";
RussianCardTexts[CardNames.CHANGELING] = "Выкиньте эту карту.//Получите копию карты, которая//есть у вас в игре.//---//Если Подменыши есть в Резерве,//когда вы получаете карту стоимостью//[3]или больше, можете обменять//её на Подменыша.";
RussianCardTexts[CardNames.CEMETERY] = "{!2}//---//Когда вы получаете эту карту,//выкиньте до четырёх карт из руки.//%%(Наследие: Призрачное Зеркало)%%";
RussianCardTexts[CardNames.COBBLER] = "В начале вашего следующего//хода получите в руку//карту не дороже[4].";
RussianCardTexts[CardNames.CONCLAVE] = "|+[2]|////Вы можете сыграть карту//Действия из руки, если//её копии ещё нет у вас в игре.//Если сыграли, |+1 Действие|.";
RussianCardTexts[CardNames.CRYPT] = "Отложите любое количество//Сокровищ (не Длительности)//из игры лицом вниз//под эту карту. Пока они не//кончатся, в начале каждого//вашего хода, кладите одно//из них в руку.";
RussianCardTexts[CardNames.CURSED_VILLAGE] = "|+2 Действия|////Тяните карты, пока у вас не//будет 6 карт в руке.//---//Когда вы получаете эту//карту, примите Порчу.";
RussianCardTexts[CardNames.DEN_OF_SIN] = "В начале вашего следующего//хода, |+2 Карты|.//---//Эту карту получают в руку//(а не в сброс).";
RussianCardTexts[CardNames.DEVILS_WORKSHOP] = "Если число карт, которые//вы получили в этот ход://|2+: |получите Чертёнка из стопки//Чертят;//|1: |получите карту не дороже[4];//|0: |получите Золото.";
RussianCardTexts[CardNames.DRUID] = "|+1 Покупка|////Примите одно из отложенных//Благодеяний (оставляя его//на месте).//---//Подготовка: Отложите 3//Благодеяния лицом вверх.";
RussianCardTexts[CardNames.EXORCIST] = "Выкиньте карту из руки.//Получите Духа дешевле//выкинутой карты из одной//из стопок с Духами.";
RussianCardTexts[CardNames.FAITHFUL_HOUND] = "|+2 Карты|//---//Когда вы сбрасываете эту карту//не в фазе Расчистки, можете//отложить её, тогда положите её//в руку в конце хода.";
RussianCardTexts[CardNames.FOOL] = "Если вы не Потерявшийся//в Лесу, станьте им, возьмите//3 Благодеяния, и примите//их в любом порядке.////%%(Наследие: Счастливая Монета)%%";
RussianCardTexts[CardNames.GHOST_TOWN] = "В начале вашего//следующего хода: //|+1 Карта |и |+1 Действие|.//---//Эту карту получают в руку//(а не в сброс).";
RussianCardTexts[CardNames.GUARDIAN] = "В начале вашего//следующего хода, |+|[1].//До тех пор, когда другой игрок играет//карту Атаки, она вас не//затрагивает.//---//Эту карту получают в руку//(а не в сброс).";
RussianCardTexts[CardNames.IDOL] = "[!2]//Если у вас нечётное число//Идолов (считая этот)//в игре, примите Благодеяние;//если чётное, то каждые другой//игрок получает Проклятие.";
RussianCardTexts[CardNames.LEPRECHAUN] = "Получите Золото.////Если у вас//в игре ровно 7 карт, получите//Желание.//Иначе, примите Порчу.";
RussianCardTexts[CardNames.MONASTERY] = "За каждую карту, полученную//вами в этот ход, можете//выкинуть карту из руки//или Медь из игры.";
RussianCardTexts[CardNames.NECROMANCER] = "Сыграйте лежащую лицом вверх//карту Действия (не Длительность)//со Свалки, оставляя её на месте.//Переверните её лицом вниз//до конца хода.//---//Подготовка: Положите трёх//Зомби на Свалку.";
RussianCardTexts[CardNames.NIGHT_WATCHMAN] = "Посмотрите на 5 верхних карт//вашей колоды, сбросьте любое//их количество, затем положите//остальные назад в любом порядке.//---//Эту карту получают в руку//(а не в сброс).";
RussianCardTexts[CardNames.PIXIE] = "|+1 Карта|//|+1 Действие|////Сбросьте верхнее Благодеяние.//Вы можете выкинуть эту карту,//чтобы принять сброшенное//Благодеяние дважды.//%%(Наследие: Козёл)%%";
RussianCardTexts[CardNames.POOKA] = "Вы можете выкинуть//Сокровище (не Проклятое//Золото) из руки, тогда //|+4 Карты|.//%%(Наследие: Проклятое Золото)%%";
RussianCardTexts[CardNames.RAIDER] = "Каждый другой игрок с пятью//или более картами в руке сбрасывает//из руки карту, копия которой есть//у вас в игре (или раскрывает руку,//показывая, что сбрасывать нечего).//В начале вашего следующего//хода +[3].";
RussianCardTexts[CardNames.SACRED_GROVE] = "|+1 Покупка|//|+[3]|////Примите Благодеяние. Если//оно не даёт +[1], то//каждый другой игрок может//принять его.";
RussianCardTexts[CardNames.SECRET_CAVE] = "|+1 Карта|//|+1 Действие|////Вы можете сбросить 3 карты.//Тогда в начале вашего//следующего хода, +[3].//%%(Наследие: Волшебная Лампа)%%";
RussianCardTexts[CardNames.SHEPHERD] = "|+1 Действие|////Сбросьте любое число карт//Победы, раскрывая их.//|+2 Карты| за каждую//сброшенную карту.//%%(Наследие: Пастбище)%%";
RussianCardTexts[CardNames.SKULK] = "|+1 Покупка|////Каждый другой игрок//принимает следующую//Порчу.//---//Когда вы получаете эту//карту, получите Золото.";
RussianCardTexts[CardNames.TORMENTOR] = "|+|[2]////Если у вас нет других карт в игре,//получите Чертёнка из стопки//Чертят. Иначе, каждый другой//игрок принимает следующую//Порчу.";
RussianCardTexts[CardNames.TRAGIC_HERO] = "|+3 Карты|//|+1 Покупка|//////Если у вас 8 или больше карт в//руке (после того, как вытянули//карты), выкиньте эту карту и//получите Сокровище.";
RussianCardTexts[CardNames.TRACKER] = "|+[1]|////В этот ход, когда//вы получаете карту, можете//положить полученную карту на//верх колоды.////Примите Благодеяние.//%%(Наследие: Мешочек)%%";
RussianCardTexts[CardNames.VAMPIRE] = "Каждый другой игрок принимает//следующую Порчу. Получите//карту не дороже[5](не Вампира).//Обменяйте эту карту (Вампира)//на Нетопыря.";
RussianCardTexts[CardNames.WEREWOLF] = "Если сейчас ваша фаза Ночи,//то каждый другой игрок//принимает следующую//Порчу. Иначе, |+3 Карты|.";
RussianCardTexts[CardNames.CURSED_GOLD] = "[!3]//Получите Проклятие.";
RussianCardTexts[CardNames.GOAT] = "[!1]//Можете выкинуть карту//из руки.";
RussianCardTexts[CardNames.HAUNTED_MIRROR] = "[!1]//---//Когда вы выкидываете эту карту,//можете сбросить карту Действия,//тогда получите Призрака из//стопки Призраков.";
RussianCardTexts[CardNames.LUCKY_COIN] = "[!1]//Получите Серебро.";
RussianCardTexts[CardNames.MAGIC_LAMP] = "[!1]//Если есть хотя бы 6 карт, которых//у вас в игре ровно по одной копии,//выкиньте эту карту. Тогда получите//3 Желания.";
RussianCardTexts[CardNames.PASTURE] = "[!1]//---//Приносит {1} за каждое//Поместье, которое у вас есть.";
RussianCardTexts[CardNames.POUCH] = "[!1]////|+1 Покупка|";
RussianCardTexts[CardNames.BAT] = "Выкиньте до двух карт из руки. //Если выкинули хотя бы одну,//то обменяйте эту карту на//Вампира.////%(Эта карта не представлена в Резерве.)%";
RussianCardTexts[CardNames.GHOST] = "Раскрывайте карты с вашей колоды//до тех пор, пока не раскроете карту//Действия. Сбросьте остальные//раскрытые карты и отложите карту//Действия. В начале вашего следующего//хода, сыграйте её дважды.//%(Эта карта не представлена в Резерве.)%";
RussianCardTexts[CardNames.IMP] = "|+2 Карты|////Вы можете сыграть карту//Действия из руки, если//её копии нет у вас в игре.////%(Эта карта не представлена в Резерве.)%";
RussianCardTexts[CardNames.WILL_O_WISP] = "|+1 Карта|//|+1 Действие|////Раскройте верхнюю карту//вашей колоды. Если она//стоит[2]или меньше,//положите её в руку.////%(Эта карта не представлена в Резерве.)%";
RussianCardTexts[CardNames.WISH] = "|+1 Действие|////Верните эту карту в стопку//Желаний. Тогда получите в//руку карту не дороже[6].////%(Эта карта не представлена в Резерве.)%";
RussianCardTexts[CardNames.ZOMBIE_APPRENTICE] = "Вы можете выкинуть карту//Действия из руки за//|+3 Карты |и |+1 Действие|.";
RussianCardTexts[CardNames.ZOMBIE_MASON] = "Выкиньте верхнюю карту вашей//колоды. Можете получить карту//стоимостью не более чем на[1]//дороже выкинутой.";
RussianCardTexts[CardNames.ZOMBIE_SPY] = "|+1 Карта|//|+1 Действие|////Посмотрите на верхнюю карту//вашей колоды. Сбросьте её//или положите её назад.";

RussianCardTexts[CardNames.ACTING_TROUPE] = "|+4 Хуторянина|//Выкиньте эту карту.";
RussianCardTexts[CardNames.BORDER_GUARD] = "|+1 Действие|////Раскройте 2 верхние карты//вашей колоды. Положите одну//в руку и сбросьте другую.//Если обе были картами Действия,//то возьмите Фонарь или Рог.";
RussianCardTexts[CardNames.CARGO_SHIP] = "|+[2]|////Один раз за текущий ход, когда//вы получаете карту, можете//отложить её лицом вверх//(на этот Грузовой Корабль).//В начале вашего следующего//хода положите её в руку.";
RussianCardTexts[CardNames.DUCAT] = "|+1 Монетка|//|+1 Покупка|//---//Когда вы получаете эту карту,//можете выкинуть//карту Меди из руки.";
RussianCardTexts[CardNames.EXPERIMENT] = "|+2 Карты|//|+1 Действие|////Верните эту карту в стопку.//---//Когда вы получаете эту карту,//получите ещё один Эксперимент//(но за этот уже не получайте//дополнительного).";
RussianCardTexts[CardNames.FLAG_BEARER] = "|+[2]|//---//Когда вы получаете или//выкидываете эту карту,//возьмите Флаг.";
RussianCardTexts[CardNames.HIDEOUT] = "|+1 Карта|//|+2 Действия|////Выкиньте карту из руки.//Если это была карта Победы,//получите Проклятие.";
RussianCardTexts[CardNames.INVENTOR] = "Получите карту не дороже[4],//затем карты стоят на[1]//дешевле в этот ход.";
RussianCardTexts[CardNames.IMPROVE] = "|+[2]|////В начале фазы Расчистки вы//можете выкинуть карту//Действия, которую бы иначе//сбросили из игры, тогда//получите карту ровно на[1]//дороже выкинутой.";
RussianCardTexts[CardNames.LACKEYS] = "|+2 Карты|//---//Когда вы получаете эту карту,//|+2 Хуторянина|.";
RussianCardTexts[CardNames.MOUNTAIN_VILLAGE] = "|+2 Действия|////Посмотрите на ваш Сброс//и положите одну карту//оттуда в руку;//иначе, |+1 Карта|.";
RussianCardTexts[CardNames.PATRON] = "|+1 Хуторянин|//|+[2]|//---//Когда вы раскрываете эту карту//в фазе Действий//(используется слово//\"раскрыть\"), |+1 Монетка|.";
RussianCardTexts[CardNames.PRIEST] = "|+[2]|////Выкиньте карту из руки.//До конца этого хода, когда//вы выкидываете карту, |+|[2].";
RussianCardTexts[CardNames.RESEARCH] = "|+1 Действие|////Выкиньте карту из руки. За каждую//[1]её стоимости отложите верхнюю//карту вашей колоды лицом вниз//(на это Исследование). В начале//вашего следующего хода положите//отложенные карты в руку.";
RussianCardTexts[CardNames.SILK_MERCHANT] = "|+2 Карты|//|+1 Покупка|//---//Когда вы получаете или//выкидываете эту карту,//|+1 Монетка| и |+1 Хуторянин|.";
RussianCardTexts[CardNames.OLD_WITCH] = "|+3 Карты|////Каждый другой игрок//получает Проклятие и может//выкинуть Проклятие из руки.";
RussianCardTexts[CardNames.RECRUITER] = "|+2 Карты|////Выкиньте карту из руки.//|+1 Хуторянин| за каждую//[1]её стоимости.";
RussianCardTexts[CardNames.SCEPTER] = "Когда вы играете эту карту,//выберите либо: |+[2]|;//либо сыграйте ещё раз карту//Действия, которую вы уже//играли в этот ход, если она//ещё в игре.";
RussianCardTexts[CardNames.SCHOLAR] = "Сбросьте вашу руку.//|+7 Карт|.";
RussianCardTexts[CardNames.SCULPTOR] = "Получите в руку карту//не дороже[4]. Если это//Сокровище, |+1 Хуторянин|.";
RussianCardTexts[CardNames.SEER] = "|+1 Карта|//|+1 Действие|////Раскройте 3 верхние карты вашей//колоды. Те, которые стоят от//[2]до[4], положите в руку.//Остальные положите назад//в любом порядке.";
RussianCardTexts[CardNames.SPICES] = "[2]////|+1 Покупка|//---//Когда вы получаете эту карту,//|+2 Монетки|.";
RussianCardTexts[CardNames.SWASHBUCKLER] = "|+3 Карты|////Если ваш Сброс не пуст: //|+1 Монетка|, затем, если у вас//есть хотя бы 4 Монетки, возьмите// Сундук с Сокровищами.";
RussianCardTexts[CardNames.TREASURER] = "|+[3]|////Выберите одно из трёх://Выкиньте Сокровище из руки;//или получите Сокровище//со Свалки в руку;//или возьмите Ключ.";
RussianCardTexts[CardNames.VILLAIN] = "|+2 Монетки|////Каждый другой игрок с пятью или//более картами в руке сбрасывает//одну стоимостью[2]или больше//(или раскрывает руку, показывая//что таких карт у него нет).";
RussianCardTexts[CardNames.FLAG] = "Когда вы тянете вашу руку, |+1 Карта|.";
RussianCardTexts[CardNames.HORN] = "Один раз за ход, когда вы сбрасываете Пограничника//из игры, можете положить его на верх колоды.";
RussianCardTexts[CardNames.KEY] = "В начале вашего хода, |+|[1].";
RussianCardTexts[CardNames.LANTERN] = "Ваши Пограничники раскрывают 3 карты и сбрасывают 2.//Для получения Рога нужно,//чтобы все 3 раскрытые карты были Действиями.";
RussianCardTexts[CardNames.TREASURE_CHEST] = "В начале вашей фазы Покупок получите Золото.";
RussianCardTexts[CardNames.ACADEMY] = "Когда вы получаете карту Действия,//|+1 Хуторянин|.";
RussianCardTexts[CardNames.BARRACKS] = "В начале вашего хода, |+1 Действие|.";
RussianCardTexts[CardNames.CANAL] = "В течение ваших ходов, карты стоят на[1]//дешевле.";
RussianCardTexts[CardNames.CAPITALISM] = "В течение ваших ходов, карты Действия,//приносящие +[], также являются Сокровищами.";
RussianCardTexts[CardNames.CATHEDRAL] = "В начале вашего хода,//выкиньте карту из руки.";
RussianCardTexts[CardNames.CITADEL] = "Первый раз, когда вы играете карту Действия//в течение каждого из ваших ходов,//сразу сыграйте её ещё раз.";
RussianCardTexts[CardNames.CITY_GATE] = "В начале вашего хода, |+1 Карта|,//затем положите карту из руки на верх колоды.";
RussianCardTexts[CardNames.CROP_ROTATION] = "В начале вашего хода, можете сбросить//карту Победы за |+2 Карты|.";
RussianCardTexts[CardNames.EXPLORATION] = "В конце вашей фазы Покупок, если вы не получили за неё//ни одной карты, |+1 Монетка| и |+1 Хуторянин|.";
RussianCardTexts[CardNames.FAIR] = "В начале вашего хода, |+1 Покупка|.";
RussianCardTexts[CardNames.FLEET] = "После конца игры каждый обладатель Флота//делает ещё один ход.";
RussianCardTexts[CardNames.GUILDHALL] = "Когда вы получаете Сокровище, |+1 Монетка|.";
RussianCardTexts[CardNames.INNOVATION] = "Один раз за каждый ваш ход, когда вы получаете карту Действия,//можете сыграть её.";
RussianCardTexts[CardNames.PAGEANT] = "В конце вашей фазы Покупок,//вы можете заплатить[1]за |+1 Монетку|.";
RussianCardTexts[CardNames.PIAZZA] = "В начале вашего хода, раскройте верхнюю карту//вашей колоды. Если это карта Действия, сыграйте её.";
RussianCardTexts[CardNames.ROAD_NETWORK] = "Когда другой игрок получает карту Победы,//|+1 Карта|.";
RussianCardTexts[CardNames.SEWERS] = "Когда вы выкидываете карту (не с помощью//Канализации), можете выкинуть карту из руки.";
RussianCardTexts[CardNames.SILOS] = "В начале вашего хода, сбросьте любое число//карт Меди, раскрыв их, и вытяньте столько же карт.";
RussianCardTexts[CardNames.SINISTER_PLOT] = "В начале вашего хода, добавьте сюда жетон, или же//уберите все ваши жетоны отсюда, тогда |+1 Карта| за каждый.";
RussianCardTexts[CardNames.STAR_CHART] = "Когда вы тасуете вашу колоду, можете выбрать//одну карту и положить её на верх колоды.";

RussianCardTexts[CardNames.SAUNA] = "|+1 Карта|//|+1 Действие|////Вы можете сыграть Прорубь//из руки.////В этот ход, когда вы//играете Серебро, можете//выкинуть карту из руки.";
RussianCardTexts[CardNames.AVANTO] = "|+3 Карты|////Вы можете сыграть Сауну//из руки.";
RussianCardTexts[CardNames.BLACK_MARKET] = "|+[2]|////Раскройте три верхние карты колоды Чёрного Рынка.//Сыграйте любое количество Сокровищ из руки.//Вы можете купить одну из раскрытых карт. //Положите остальные в низ колоды Чёрного Рынка// в любом порядке.//---//Подготовка: составьте колоду Чёрного Рынка из//различных карт Королевства, не представленных// в Резерве.";
RussianCardTexts[CardNames.ENVOY] = "Раскройте 5 верхних карт вашей//колоды. Игрок слева от вас//выбирает одну из них, которую вы//сбрасываете. Положите остальные//раскрытые карты в руку.";
RussianCardTexts[CardNames.GOVERNOR] = "|+1 Действие|////Выберите одно; вы получаете//версию в скобках: каждый игрок//тянет |+1 (+3) Карты|; или каждый //игрок получает Серебро (Золото);//или каждый игрок может выкинуть //карту из своей руки и получить//карту ровно на[1]([2]) дороже.";
RussianCardTexts[CardNames.PRINCE] = "Вы можете отложить карту Действия//(не Длительность и не Команду)//не дороже [4] из руки//на эту карту. В начале//каждого из ваших ходов//играйте эту карту,//оставляя её отложенной.";
RussianCardTexts[CardNames.STASH] = "[!2]//---//Когда вы тасуете вашу колоду,//можете положить эту карту//в любое её место.";
RussianCardTexts[CardNames.SUMMON] = "Получите карту не дороже[4]. Отложите её в сторону.//Тогда в начале вашего следующего хода сыграйте её.";
RussianCardTexts[CardNames.WALLED_VILLAGE] = "|+1 Карта|//|+2 Действия|//---//В начале фазы Расчистки, если//у вас в игре есть эта карта и//максимум ещё одна карта//Действия, то вы можете положить//эту карту на верх колоды.";
RussianCardTexts[CardNames.BLACK_MARKET_PILE] = "";
RussianCardTexts[CardNames.DISMANTLE] = "Выкиньте карту из руки.//Если она стоит[1]//или больше, получите//карту дешевле и Золото.";
RussianCardTexts[CardNames.CAPTAIN] = "Сейчас и в начале//вашего следующего хода://Сыграйте карту Действия//из Резерва//(не Длительность и не Команду)//не дороже[4], оставляя её//на месте.";
RussianCardTexts[CardNames.CHURCH] = "|+1 Действие|////Отложите в сторону до трёх карт//из руки. В начале вашего//следующего хода, положите их//в руку, затем можете выкинуть//карту из руки.";

RussianCardTexts[CardNames.BLACK_CAT] = "|+2 Карты|//Если это не ваш ход, то каждый//другой игрок получает Проклятие.//---//Когда другой игрок получает//карту Победы, вы можете сыграть//эту карту (Чёрного Кота) из руки.";
RussianCardTexts[CardNames.SLEIGH] = "Получите 2 Лошади.//---//Когда вы получаете карту,//можете сбросить эту карту (Сани),//тогда положите полученную карту//в руку или на верх вашей колоды.";
RussianCardTexts[CardNames.SUPPLIES] = "[!1]////Когда вы играете эту карту,//получите Лошадь//на верх вашей колоды.";
RussianCardTexts[CardNames.CAMEL_TRAIN] = "Изгоните карту (не карту//Победы) из Резерва.//---//Когда вы получаете эту карту,//изгоните Золото из Резерва.";
RussianCardTexts[CardNames.GOATHERD] = "|+1 Действие|////Вы можете выкинуть//карту из руки.////|+1 Карта| за каждую карту,//которую игрок справа//выкинул в его ход.";
RussianCardTexts[CardNames.SCRAP] = "Выкиньте карту из руки.//Выберите разную вещь//за каждую[1]её стоимости: //|+1 Карта|; |+1 Действие|; //|+1 Покупка|; |+|[1];//получите Серебро;//получите Лошадь.";
RussianCardTexts[CardNames.SHEEPDOG] = "|+2 Карты|//---//Когда вы получаете карту,//вы можете сыграть эту карту//(Английскую Овчарку) из руки.";
RussianCardTexts[CardNames.SNOWY_VILLAGE] = "|+1 Карта|//|+4 Действия|//|+1 Покупка|////Игнорируйте все последующие//|+Действия|, которые//вы получите в этот ход.";
RussianCardTexts[CardNames.STOCKPILE] = "[!3]////|+1 Покупка|////Когда вы играете эту карту,//изгоните её.";
RussianCardTexts[CardNames.BOUNTY_HUNTER] = "|+1 Действие|////Изгоните карту из руки.//Если её копии не было//у вас в Изгнании, |+|[3].";
RussianCardTexts[CardNames.CARDINAL] = "|+[2]|////Каждый другой игрок раскрывает//2 верхние карты своей колоды,//изгоняет одну из них//стоимостью от[3]до[6]//и сбрасывает остальные.";
RussianCardTexts[CardNames.CAVALRY] = "Получите 2 Лошади.//---//Когда вы получаете эту карту,//|+2 Карты|, |+1 Покупка|, и если//сейчас ваша фаза Покупки, то//вернитесь в фазу Действия.";
RussianCardTexts[CardNames.GROOM] = "Получите карту не дороже[4].//Если полученная карта - //Действие, получите Лошадь//Сокровище, получите Серебро//Победа, |+1 Карта| и |+1 Действие|";
RussianCardTexts[CardNames.HOSTELRY] = "|+1 Карта|//|+2 Действия|//---//Когда вы получаете эту карту,//можете сбросить любое количество//Сокровищ из руки,//раскрывая их, тогда получите//такое же количество Лошадей.";
RussianCardTexts[CardNames.VILLAGE_GREEN] = "Либо сейчас, либо в начале//вашего следующего хода,//|+1 Карта| и |+2 Действия.|//---//Когда вы сбрасываете эту//карту не в фазе Расчистки,//можете раскрыть и сыграть её.";
RussianCardTexts[CardNames.BARGE] = "Либо сейчас, либо в начале//вашего следующего хода,//|+3 Карты| и |+1 Покупка.|";
RussianCardTexts[CardNames.COVEN] = "|+1 Действие|//|+[2]|////Каждый другой игрок изгоняет//Проклятие из Резерва.//Если не может, то сбрасывает//все свои изгнанные Проклятия.";
RussianCardTexts[CardNames.DISPLACE] = "Изгоните карту из руки.//Получите карту //с другим названием, не более//чем на[2]дороже изгнанной.";
RussianCardTexts[CardNames.FALCONER] = "Получите в руку карту//дешевле этой.//---//Когда любой игрок получает карту//с двумя или более типами//(Действие, Атака, итд.),//вы можете сыграть//эту карту (Сокольника) из руки.";
RussianCardTexts[CardNames.FISHERMAN] = "|+1 Карта|//|+1 Действие|//|+[1]|//---//В течение ваших ходов,//если ваш сброс пуст, эта карта//стоит на [3] дешевле.";
RussianCardTexts[CardNames.GATEKEEPER] = "В начале вашего следующего//хода, |+|[3]. До этого,//когда другой игрок получает//карту Действия или Сокровище,//копии которой нет у него//в Изгнании, он изгоняет её.";
RussianCardTexts[CardNames.HUNTING_LODGE] = "|+1 Карта|//|+2 Действия|////Вы можете сбросить//вашу руку, тогда |+5 Карт|.";
RussianCardTexts[CardNames.KILN] = "|+[2]|////В следующий раз когда//вы будете играть карту//в этот ход, можете//сначала получить её копию.";
RussianCardTexts[CardNames.LIVERY] = "|+[3]|////В этот ход, когда вы получаете//карту стоимостью[4]//или больше, получите Лошадь.";
RussianCardTexts[CardNames.MASTERMIND] = "В начале вашего следующего//хода, можете сыграть//карту Действия из руки 3 раза.";
RussianCardTexts[CardNames.PADDOCK] = "|+[2]|////Получите 2 Лошади.////|+1 Действие| за каждую//пустую стопку Резерва.";
RussianCardTexts[CardNames.SANCTUARY] = "|+1 Карта|//|+1 Действие|//|+1 Покупка|////Вы можете изгнать//карту из руки.";
RussianCardTexts[CardNames.DESTRIER] = "|+2 Карты|//|+1 Действие|//---//В течение ваших ходов, эта карта//стоит на[1]меньше за каждую//полученную вами в этот ход карту.";
RussianCardTexts[CardNames.WAYFARER] = "|+3 Карты|////Вы можете получить Серебро.//---//Эта карта стоит столько же, сколько//последняя полученная вами//в этот ход карта, если такие есть.";
RussianCardTexts[CardNames.ANIMAL_FAIR] = "|+[4]|////|+1 Покупка| за каждую//пустую стопку Резерва.//---//Вместо того, чтобы платить//стоимость этой карты, вы можете//выкинуть карту Действия из руки.";
RussianCardTexts[CardNames.HORSE] = "|+2 Карты|//|+1 Действие|////Верните эту карту//в стопку Лошадей.////%(Эта карта не представлена%//%в Резерве)%";
RussianCardTexts[CardNames.WAY_OF_THE_BUTTERFLY] = "Вы можете вернуть эту карту в её стопку,//тогда получите карту ровно на[1]дороже выкинутой.";
RussianCardTexts[CardNames.WAY_OF_THE_CAMEL] = "Изгоните Золото из Резерва.";
RussianCardTexts[CardNames.WAY_OF_THE_CHAMELEON] = "Следуйте инструкциям этой карты; когда//вы бы получали |+Карты| в этот ход,//получите вместо этого |+|[], и наоборот.";
RussianCardTexts[CardNames.WAY_OF_THE_FROG] = "|+1 Действие|////В этот ход, когда вы сбрасываете эту карту из игры//положите её на верх колоды.";
RussianCardTexts[CardNames.WAY_OF_THE_GOAT] = "Выкиньте карту из руки.";
RussianCardTexts[CardNames.WAY_OF_THE_HORSE] = "|+2 Карты|//|+1 Действие|////Верните эту карту в её стопку.";
RussianCardTexts[CardNames.WAY_OF_THE_MOLE] = "|+1 Действие|////Сбросьте вашу руку. |+3 Карты.|";
RussianCardTexts[CardNames.WAY_OF_THE_MONKEY] = "|+1 Покупка|//|+[1]|";
RussianCardTexts[CardNames.WAY_OF_THE_MOUSE] = "Сыграйте отложенную карту, оставляя её на месте.//---//Подготовка: Отложите неиспользованную карту Действия//стоимостью[2]или[3].";
RussianCardTexts[CardNames.WAY_OF_THE_MULE] = "|+1 Действие|//|+[1]|";
RussianCardTexts[CardNames.WAY_OF_THE_OTTER] = "|+2 Карты|";
RussianCardTexts[CardNames.WAY_OF_THE_OWL] = "Тяните карты, пока у вас не будет 6 карт в руке.";
RussianCardTexts[CardNames.WAY_OF_THE_OX] = "|+2 Действия|";
RussianCardTexts[CardNames.WAY_OF_THE_PIG] = "|+1 Карта|//|+1 Действие|";
RussianCardTexts[CardNames.WAY_OF_THE_RAT] = "Вы можете сбросить Сокровище,//тогда получите копию этой карты.";
RussianCardTexts[CardNames.WAY_OF_THE_SEAL] = "|+[1]|////В этом ходу, когда вы получаете карту,//можете положить её на верх вашей колоды.";
RussianCardTexts[CardNames.WAY_OF_THE_SHEEP] = "|+[2]|";
RussianCardTexts[CardNames.WAY_OF_THE_SQUIRREL] = "|+2 Карты| в конце этого хода.";
RussianCardTexts[CardNames.WAY_OF_THE_TURTLE] = "Отложите эту карту в сторону. Тогда//сыграйте её в начале вашего следующего хода.";
RussianCardTexts[CardNames.WAY_OF_THE_WORM] = "Изгоните Поместье из Резерва.";
RussianCardTexts[CardNames.DELAY] = "Вы можете отложить карту Действия из руки.//Тогда в начале вашего следующего хода сыграйте её.";
RussianCardTexts[CardNames.DESPERATION] = "Один раз за ход: Вы можете получить Проклятие.//Тогда |+1 Покупка| и |+|[2].";
RussianCardTexts[CardNames.GAMBLE] = "|+1 Покупка|////Раскройте верхнюю карту вашей колоды. Если это Сокровище//или карта Действия, можете сыграть её. Иначе сбросьте её.";
RussianCardTexts[CardNames.PURSUE] = "|+1 Покупка|////Назовите карту. Раскройте 4 верхние карты вашей колоды.//Положите копии названной карты назад и сбросьте остальные.";
RussianCardTexts[CardNames.RIDE] = "Получите Лошадь.";
RussianCardTexts[CardNames.TOIL] = "|+1 Покупка|////Вы можете сыграть карту Действия из руки.";
RussianCardTexts[CardNames.ENHANCE] = "Вы можете выкинуть карту из руки (не карту Победы),//тогда получите карту //стоимостью не более чем на[2]дороже выкинутой.";
RussianCardTexts[CardNames.MARCH] = "Посмотрите на ваш сброс.//Можете сыграть карту Действия из сброса.";
RussianCardTexts[CardNames.TRANSPORT] = "Выберите одно: изгоните карту Действия из Резерва;//или положите карту Действия из вашего Изгнания//на верх вашей колоды.";
RussianCardTexts[CardNames.BANISH] = "Изгоните любое количество//одинаково названных карт из руки.";
RussianCardTexts[CardNames.BARGAIN] = "Получите карту не дороже[5](но не карту Победы).//Каждый другой игрок получает Лошадь.";
RussianCardTexts[CardNames.INVEST] = "Изгоните карту Действия из Резерва.//Пока она в Изгнании, когда другой игрок//получает её копию или Инвестирует в неё, |+2 Карты|.";
RussianCardTexts[CardNames.SEIZE_THE_DAY] = "Один раз за игру://Получите дополнительный ход после текущего.";
RussianCardTexts[CardNames.COMMERCE] = "Получите Золото за каждую различную//карту, полученную вами в этот ход.";
RussianCardTexts[CardNames.DEMAND] = "Получите Лошадь и карту не дороже[4], //и то и другое на верх колоды.";
RussianCardTexts[CardNames.STAMPEDE] = "Если у вас 5 или меньше карт в игре,//получите 5 Лошадей на верх колоды.";
RussianCardTexts[CardNames.REAP] = "Получите Золото. Отложите его. Тогда,//в начале вашего следующего хода, сыграйте его.";
RussianCardTexts[CardNames.ENCLAVE] = "Получите Золото. Изгоните Герцогство из Резерва.";
RussianCardTexts[CardNames.ALLIANCE] = "Получите Провинцию, Герцогство, Поместье,//Золото, Серебро, и Медь.";
RussianCardTexts[CardNames.POPULATE] = "Получите по карте из каждой стопки карт Действия.";
RussianCardTexts[CardNames.CARD_OF_THE_BUTTERFLY] = "";
RussianCardTexts[CardNames.CARD_OF_THE_CAMEL] = "";
RussianCardTexts[CardNames.CARD_OF_THE_CHAMELEON] = "";
RussianCardTexts[CardNames.CARD_OF_THE_FROG] = "";
RussianCardTexts[CardNames.CARD_OF_THE_GOAT] = "";
RussianCardTexts[CardNames.CARD_OF_THE_HORSE] = "";
RussianCardTexts[CardNames.CARD_OF_THE_MOLE] = "";
RussianCardTexts[CardNames.CARD_OF_THE_MONKEY] = "";
RussianCardTexts[CardNames.CARD_OF_THE_MOUSE] = "";
RussianCardTexts[CardNames.CARD_OF_THE_MULE] = "";
RussianCardTexts[CardNames.CARD_OF_THE_OTTER] = "";
RussianCardTexts[CardNames.CARD_OF_THE_OWL] = "";
RussianCardTexts[CardNames.CARD_OF_THE_OX] = "";
RussianCardTexts[CardNames.CARD_OF_THE_PIG] = "";
RussianCardTexts[CardNames.CARD_OF_THE_RAT] = "";
RussianCardTexts[CardNames.CARD_OF_THE_SEAL] = "";
RussianCardTexts[CardNames.CARD_OF_THE_SHEEP] = "";
RussianCardTexts[CardNames.CARD_OF_THE_SQUIRREL] = "";
RussianCardTexts[CardNames.CARD_OF_THE_TURTLE] = "";
RussianCardTexts[CardNames.CARD_OF_THE_WORM] = "";

RussianCardTexts[CardNames.TOWN] = "Выберите одно из двух://|+1 Карта| and |+2 Действия|;// или |+1 Покупка| и +[2].";
RussianCardTexts[CardNames.MODIFY] = "Выкиньте карту из руки.//Выберите одно из двух://|+1 Карта| и |+1 Действие|;//или получите карту стоимостью//не более чем на[2]//дороже выкинутой.";
RussianCardTexts[CardNames.MERCHANT_CAMP] = "|+2 Действия|//+[1]//---//Когда вы сбрасываете эту//карту из игры, можете//положить её на верх колоды.";
RussianCardTexts[CardNames.SWAP] = "|+1 Карта|//|+1 Действие|//Вы можете вернуть карту Действия//из руки в её стопку,//тогда получите в руку другую//карту Действия не дороже [5].";
RussianCardTexts[CardNames.SKIRMISHER] = "|+1 Карта|//|+1 Действие|//|+[1]|//В этот ход, когда вы получаете//карту Атаки, каждый другой//игрок сбрасывает до//трёх карт в руке.";
RussianCardTexts[CardNames.HIGHWAYMAN] = "В начале вашего следующего//хода, сбросьте эту карту из//игры и |+3 Карты|.//До тех пор первое Сокровище,//которое каждый другой игрок//играет каждый ход//ничего не делает.";
RussianCardTexts[CardNames.BARBARIAN] = "|+[2]|//Каждый другой игрок выкидывает//верхнюю карту своей колоды. Если она//стоит [3] или больше, то он получает//карту дешевле выкинутой,//и имеющую хотя бы один//из типов выкинутой карты;//иначе он получает Проклятие.";
RussianCardTexts[CardNames.INNKEEPER] = "|+1 Действие|//Выберите одно из трёх: |+1 Карта|; //или |+3 Карты|, сбросьте 3 карты;//или |+5 Карт|, сбросьте 6 карт.";
RussianCardTexts[CardNames.CAPITAL_CITY] = "|+1 Карта|//|+2 Действия|//Вы можете сбросить 2 карты, тогда +[2].//Вы можете заплатить [2] за |+2 Карты|.";
RussianCardTexts[CardNames.SPECIALIST] = "Вы можете сыграть Действие или//Сокровище из руки.//Выберите одно: сыграйте его ещё раз;//или получите его копию.";
RussianCardTexts[CardNames.CARPENTER] = "Если нет пустых стопок Резерва,//|+1 Действие| и получите//карту не дороже [4].//Иначе, выкиньте карту из руки//и получите карту//не более чем на [2]//дороже выкинутой.";
RussianCardTexts[CardNames.ROYAL_GALLEY] = "|+1 Карта|//Вы можете сыграть карту//Действия (не Длительность) из руки.//Отложите её; тогда,//в начале вашего следующего//хода, сыграйте её.";
RussianCardTexts[CardNames.MARQUIS] = "|+1 Покупка|//|+1 Карта| за каждую//карту в руке. Сбросьте//до 10 карт в руке.";
RussianCardTexts[CardNames.SENTINEL] = "Посмотрите на 5 верхних карт//вашей колоды. Выкиньте две//или меньше. Положите остаток//назад на верх колоды//в любом порядке.";
RussianCardTexts[CardNames.COURIER] = "|+[1]|//Сбросьте верхнюю карту//вашей колоды. Посмотрите//на ваш сброс; можете сыграть//Действие или Сокровище оттуда.";
RussianCardTexts[CardNames.GALLERIA] = "|+[3]|//В этот ход, когда вы//получаете карту стоимостью//[3] или [4], |+1 Покупка|.";
RussianCardTexts[CardNames.HUNTER] = "|+1 Действие|//Раскройте 3 верхние карты//вашей колоды. Из них положите//Действие, Сокровище, и карту Победы//в руку. Сбросьте остальные//раскрытые карты.";
RussianCardTexts[CardNames.SYCOPHANT] = "|+1 Действие|//Сбросьте 3 карты. Если вы//сбросили хотя бы одну, |+|[3].//---//Когда вы получаете или//выкидываете эту карту, |+2 Услуги|.";
RussianCardTexts[CardNames.UNDERLING] = "|+1 Карта|//|+1 Действие|//|+1 Услуга|";
RussianCardTexts[CardNames.IMPORTER] = "В начале вашего//следующего хода,// получите карту не дороже [5].//---//Подготовка: Каждый игрок//получает |+4 Услуги|.";
RussianCardTexts[CardNames.CONTRACT] = "[!2]//|+1 Услуга|//Вы можете отложить карту Действия//из руки, тогда сыграйте её//в начале вашего следующего хода.";
RussianCardTexts[CardNames.EMISSARY] = "|+3 Карты|//Если это заставило//вас перетасовать колоду//(хотя бы одну карту),//|+1 Действие| и |+2 Услуги|.";
RussianCardTexts[CardNames.BAUBLE] = "Выберите две разные опции://|+1 Покупка|; +[1]; |+1 Услуга|;//в этот ход, когда вы получаете//карту, можете положить её//на верх колоды.";
RussianCardTexts[CardNames.BROKER] = "Выкиньте карту из руки.//Выберите одно из четырёх,//и получите это за каждую//[1] выкинутой карты:// |+1 Карта|; |+1 Действие|;//+[1]; или |+1 Услуга|.";
RussianCardTexts[CardNames.GUILDMASTER] = "|+[3]|//В этот ход, когда вы//получаете карту, |+1 Услуга|.";
RussianCardTexts[CardNames.ARCHITECTS_GUILD] = "Когда вы получаете карту, можете потратить |2 Услуги|//чтобы получить карту дешевле (не карту Победы).";
RussianCardTexts[CardNames.ORDER_OF_ASTROLOGERS] = "Когда вы тасуете колоду, можете потратить//любое количество |Услуг| и положить такое же//количество карт на верх колоды.";
RussianCardTexts[CardNames.LEAGUE_OF_BANKERS] = "В начале вашей фазы Покупок, +[1]//за каждые |4 Услуги| у вас в наличии (с округлением вниз).";
RussianCardTexts[CardNames.MOUNTAIN_FOLK] = "В начале вашего хода, можете//потратить |5 Услуг| за |+3 Карты|";
RussianCardTexts[CardNames.WOODWORKERS_GUILD] = "В начале вашей фазы Покупок, можете потратить//|Услугу| чтобы выкинуть карту Действия из руки.//Тогда получите карту Действия.";
RussianCardTexts[CardNames.PEACEFUL_CULT] = "В начале вашей фазы Покупок, потратьте любое//количество |Услуг| чтобы выкинуть//такое же количество карт из руки.";
RussianCardTexts[CardNames.CRAFTERS_GUILD] = "В начале вашего хода, можете потратить |2 Услуги|,//тогда получите карту не дороже [4] на верх колоды.";
RussianCardTexts[CardNames.CAVE_DWELLERS] = "В начале вашего хода, можете потратить |Услугу|//чтобы сбросить карту и затем вытянуть карту.//Можно повторить несколько раз.";
RussianCardTexts[CardNames.PLATEAU_SHEPHERDS] = "При подсчёте очков, каждая пара из одной |Услуги|//и карты стоимостью [2] приносит {2}.";
RussianCardTexts[CardNames.DESERT_GUIDES] = "В начале вашего хода, можете потратить |Услугу|//чтобы сбросить руку и вытянуть 5 карт.//Можно повторить несколько раз.";
RussianCardTexts[CardNames.FAMILY_OF_INVENTORS] = "В начале вашей фазы Покупок, можете положить//жетон |Услуги| на стопку в Резерве (не карт Победы).//Карты из этой стопки стоят на [1] меньше//за каждый жетон |Услуги| на стопке.";
RussianCardTexts[CardNames.ISLAND_FOLK] = "В конце вашего хода, можете потратить |5 Услуг|,//тогда сделайте ещё один ход (но не третий ход подряд).";
RussianCardTexts[CardNames.ORDER_OF_MASONS] = "Когда вы тасуете колоду, за каждую потраченную//|Услугу| можете положить до двух карт в сброс.";
RussianCardTexts[CardNames.BAND_OF_NOMADS] = "Когда вы получаете карту стоимостью//[3] или больше, можете потратить |Услугу| за//|+1 Карту|, или |+1 Действие|, или |+1 Покупку|.";
RussianCardTexts[CardNames.GANG_OF_PICKPOCKETS] = "В начале вашего хода, сбросьте до//4 карт в руке или потратьте |Услугу|.";
RussianCardTexts[CardNames.MARKET_TOWNS] = "В начале вашей фазы Покупок, можете потратить//|Услугу| чтобы сыграть карту Действия из руки.//Можно повторить несколько раз.";
RussianCardTexts[CardNames.FOREST_DWELLERS] = "В начале вашего хода, можете потратить |Услугу|//чтобы посмотреть на 3 верхние карты вашей колоды.//Сбросьте любое количество//и положите остальные назад в любом порядке.";
RussianCardTexts[CardNames.FELLOWSHIP_OF_SCRIBES] = "После того как вы сыграли карту Действия,//если у вас не более четырёх карт в руке,//можете потратить |Услугу| за |+1 Карту|.";
RussianCardTexts[CardNames.LEAGUE_OF_SHOPKEEPERS] = "После того как вы сыграли Связи,//если у вас есть |5| или больше |Услуг|, |+|[1],//и если |10| или больше, то |+1 Действие| и |+1 Покупка|.";
RussianCardTexts[CardNames.COASTAL_HAVEN] = "Когда вы сбрасываете руку в фазе Расчистки, можете потратить//любое количество |Услуг| чтобы сохранить то же количество//карт в руке до следующего хода (вы всё равно тянете пять карт).";
RussianCardTexts[CardNames.CITY_STATE] = "Когда вы получаете карту Действия в ваш ход,//можете потратить |2 Услуги| чтобы сыграть её.";
RussianCardTexts[CardNames.TRAPPERS_LODGE] = "Когда вы получаете карту, можете потратить//|Услугу| чтобы положить её на верх колоды.";
RussianCardTexts[CardNames.CIRCLE_OF_WITCHES] = "После того как вы сыграли Связи,//можете потратить |3 Услуги| чтобы//каждый другой игрок получил Проклятие.";
RussianCardTexts[CardNames.AUGUR_PILE] = "В начале игры в этой стопке находятся//по 4 копии Собирателей Трав,//Послушников, Колдуний и Сивилл,//именно в таком порядке.//Может быть куплена или получена//только верхняя карта.";
RussianCardTexts[CardNames.HERB_GATHERER] = "|+1 Покупка|//Положите вашу колоду в сброс.//Посмотртие на сброс//и можете сыграть Сокровище оттуда.//Можете прокрутить Авгуров.";
RussianCardTexts[CardNames.ACOLYTE] = "Можете выкинуть карту Действия//или Сокровище из руки чтобы//получить Золото.//Можете выкинуть эту карту//чтобы плучить Авгура.";
RussianCardTexts[CardNames.SORCERESS] = "|+1 Действие|//Назовите карту.//Раскройте верхнюю карту//вашей колоды и положите её в руку.//Если вы угадали, каждый другой//игрок получает Проклятие.";
RussianCardTexts[CardNames.SIBYL] = "|+4 Карты|//|+1 Действие|//Положите карту из руки//на верх колоды,//и ещё одну под низ колоды.";
RussianCardTexts[CardNames.CLASH_PILE] = "В начале игры в этой стопке находятся//по 4 копии Планов Битвы,//Лучников, Военачальников и Территорий,//именно в таком порядке.//Может быть куплена или получена//только верхняя карта.";
RussianCardTexts[CardNames.BATTLE_PLAN] = "|+1 Карта|//|+1 Действие|//Можете раскрыть карту Атаки//из руки, тогда |+1 Карта|.//Можете прокрутить стопку Резерва.";
RussianCardTexts[CardNames.ARCHER] = "|+[2]|//Каждый другой игрок с пятью//или более картами в руке//раскрывает все кроме одной//и сбрасывает одну из раскрытых//карт во вашему выбору.";
RussianCardTexts[CardNames.WARLORD] = "|+1 Действие|//В начале вашего следующего хода,//|+2 Карты|. До тех пор, другие//игроки не могут играть//карты Действия из руки//если у них уже есть 2 или больше//копии такого Действия в игре.";
RussianCardTexts[CardNames.TERRITORY] = "Приносит по {1} за каждую разную//карту Победу, которая у вас есть.//---//Когда вы получаете эту карту,//получите Золото за каждую//пустую стопку Резерва.";
RussianCardTexts[CardNames.FORT_PILE] = "В начале игры в этой стопке находятся//по 4 копии Палаток, Гарнизонов,//Фортов на Холме и Оплотов,//именно в таком порядке.//Может быть куплена или получена//только верхняя карта.";
RussianCardTexts[CardNames.TENT] = "|+[2]|//Можете прокрутить Форты.//---//Когда вы сбрасываете//эту карту из игры,//можете положить её на верх колоды.";
RussianCardTexts[CardNames.GARRISON] = "|+[2]|//В этом ходу, когда вы получаете карту,//положите жетон на эту карту.//В начале вашего следующего хода,//снимите их, получив |+1 Карту| за каждый.";
RussianCardTexts[CardNames.HILL_FORT] = "Получите карту не дороже [4].//Выберите одно из двух://Положите её в руку;//или |+1 Карта| и |+1 Действие|.";
RussianCardTexts[CardNames.STRONGHOLD] = "Выберите одно из двух: |+|[3];//или в начале вашего//следующего хода, |+3 Карты.|//---//{!2}";
RussianCardTexts[CardNames.ODYSSEY_PILE] = "В начале игры в этой стопке находятся//по 4 копии Старых Карт, Путешествий,//Затонувших Сокровищ и Дальних Берегов,//именно в таком порядке.//Может быть куплена или получена//только верхняя карта.";
RussianCardTexts[CardNames.OLD_MAP] = "|+1 Карта|//|+1 Действие|//Сбросьте карту. |+1 Карта|.//Можете прокрутить Одиссеи.";
RussianCardTexts[CardNames.VOYAGE] = "|+1 Действие|//Получите дополнительный//ход после текущего//(но не третий ход подряд),//в течение которого//вы можете сыграть только//три карты из руки.";
RussianCardTexts[CardNames.SUNKEN_TREASURE] = "Получите карту Действия//копии которой//нет у вас в игре.";
RussianCardTexts[CardNames.DISTANT_SHORE] = "|+2 Карты|//|+1 Действие|//Получите Поместье.//---//{!2}";
RussianCardTexts[CardNames.TOWNSFOLK_PILE] = "В начале игры в этой стопке находятся//по 4 копии Городских Плакальщиков,//Кузнецов, Мельников и Старейшин,//именно в таком порядке.//Может быть куплена или получена//только верхняя карта.";
RussianCardTexts[CardNames.TOWN_CRIER] = "Выберите одно из трёх: |+|[2]; или//получите Серебро; или//|+1 Карта| и |+1 Действие|.////Вы можете прокрутить Горожан. ";
RussianCardTexts[CardNames.BLACKSMITH] = "Выберите одно из трёх://тяните карты пока у вас//не будет 6 карт в руке;//или |+2 Карты|; или//|+1 Карта| и |+1 Действие|.";
RussianCardTexts[CardNames.MILLER] = "|+1 Действие|//Посмотрите на четыре верхние карты//вашей колоды. Положите одну из них//в руку и сбросьте остальные.";
RussianCardTexts[CardNames.ELDER] = "|+[2]|//Можете сыграть карту Действия из руки.//Если она даёт вам возможность//выбора из нескольких опций//(например, \"выберите одно\")//в этом ходу, можете выбрать//дополнительную (неповторяющуюся)//опцию.";
RussianCardTexts[CardNames.WIZARD_PILE] = "В начале игры в этой стопке находятся//по 4 копии Судентов,//Фокусников, Колдунов и Личей,//именно в таком порядке.//Может быть куплена или получена//только верхняя карта.";
RussianCardTexts[CardNames.STUDENT] = "|+1 Действие|//Можете прокрутить Волшебников.//Выкиньте карту из руки.//Если это Сокровище, |+1 Услуга|// и положите эту карту на верх колоды.";
RussianCardTexts[CardNames.CONJURER] = "Получите карту не дороже [4].//В начале вашего//следующего хода,//положите эту карту в руку.";
RussianCardTexts[CardNames.SORCERER] = "|+1 Карта|//|+1 Действие|//Каждый другой игрок//называет карту, затем раскрывает//верхнюю карту своей колоды.//Если не угадывает, то получает Проклятие.";
RussianCardTexts[CardNames.LICH] = "|+6 Карт|//|+2 Действия|//Пропустите ход.//---//Когда вы выкидываете эту карту,//сбросьте её и получите//карту дешевле со Свалки.";

RussianCardTexts[CardNames.MONKEY] = "До начала вашего следующего//хода, когда игрок по правую руку//получает карту, |+1 Карта|.//В начале вашего следующего хода,//|+1 Карта|.";
RussianCardTexts[CardNames.BLOCKADE] = "Получите карту не дороже [4],//отложите её в сторону. В начале вашего//следующего хода, положите её в руку.//Пока карта отложена,//когда другой игрок получает//копию этой карты в свой ход,//он получает Проклятие.";
RussianCardTexts[CardNames.SAILOR] = "|+1 Действие|//Один раз за текущий ход,//когда вы получаете карту Длительности,//можете сыграть её.//В начале вашего следующего хода,//+[2] и можете выкинуть//карту из руки.";
RussianCardTexts[CardNames.PIRATE] = "В начале вашего//следующего хода, получите Сокровище//не дороже [6] в руку.//---//Когда любой игрок получает//Сокровище, можете сыграть эту//карту (Пирата) из руки.";
RussianCardTexts[CardNames.CORSAIR] = "|+[2]|//В начале вашего следующего хода,//|+1 Карта|. До этого, каждый другой//игрок выкидывает первое Серебро//или Золото, сыгранное за ход.";
RussianCardTexts[CardNames.SEA_CHART] = "|+1 Карта|//|+1 Действие|//Раскройте верхнюю карту//вашей колоды. //Положите её в руку,//если её копия есть у вас в игре.";
RussianCardTexts[CardNames.ASTROLABE] = "Сейчас и в начале//вашего следующего хода:////[!1]//|+1 Покупка|";
RussianCardTexts[CardNames.TIDE_POOLS] = "|+3 Карты|//|+1 Действие|//В начале вашего следующего хода,//сбросьте 2 карты.";
RussianCardTexts[CardNames.SEA_WITCH] = "|+2 Карты|//Каждый другой игрок получает//Проклятие. В начале вашего//следующего хода,//|+2 Карты|,//затем сбросьте 2 карты.";

RussianCardTexts[CardNames.HOARD] = "[!2]//В этот ход, когда вы получаете//карту Победы, если вы//купили её, получите Золото.";
RussianCardTexts[CardNames.MINT] = "Вы можете раскрыть//Сокровище из руки.//Получите его копию.//---//Когда вы получаете эту карту,//выкиньте все ваши Сокровища//(кроме карт Длительности) из игры.";
RussianCardTexts[CardNames.QUARRY] = "[!1]//В этот ход, карты Действия//стоят на [2] меньше.";
RussianCardTexts[CardNames.ANVIL] = "[!1]//Вы можете сбросить Сокровище//из руки, тогда получите//карту не дороже [4].";
RussianCardTexts[CardNames.CLERK] = "|+[2]|//Каждый другой игрок с пятью//или более картами в руке//кладёт одну карту//на верх своей колоды.//---//В начале вашего хода,//можете сыграть эту карту из руки.";
RussianCardTexts[CardNames.INVESTMENT] = "Выкиньте карту из руки.//Выберите одно: +[1]; или выкиьте//эту карту чтобы раскрыть вашу руку,// и получить +{1} за каждое различное//Сокровище в руке.";
RussianCardTexts[CardNames.TIARA] = "|+1 Покупка|//В этот ход, когда вы получаете//карту, можете положить её на верх//колоды. Можете сыграть//Сокровище из руки дважды.";
RussianCardTexts[CardNames.CHARLATAN] = "|+[3]|//Каждый другой игрок//получает Проклятие.//---//Если Обманщики есть в Резерве,//Проклятие это также//Сокровище, приносящее [1].";
RussianCardTexts[CardNames.COLLECTION] = "[!2]//|+1 Покупка|//В этот ход, когда вы получаете//карту Действия, +{1}.";
RussianCardTexts[CardNames.CRYSTAL_BALL] = "[!1]//Посмотрите на верхнюю карту//вашей колоды. Можете выкинуть её,//сбросить её, или, если это//карта Действия или Сокровище,//сыграть её.";
RussianCardTexts[CardNames.MAGNATE] = "Раскройте вашу руку.//|+1 Карта| за Сокровище в ней.";
RussianCardTexts[CardNames.WAR_CHEST] = "Игрок по вашу левую руку//называет карту. Получите карту//не дороже [5], которая не была//названа для Военных Сундуков//в этот ход.";

RussianCardTexts[CardNames.FARMLAND] = "{!2}//---//Когда вы получаете эту карту,//выкиньте карту из руки и//получите карту ровно на [2]//дороже выкинутой (но не Угодья).";
RussianCardTexts[CardNames.HAGGLER] = "|+[2]|//---//В этот ход, когда вы//получаете карту, если вы купили её,//получите также карту дешевле//полученной (но не карту Победы).";
RussianCardTexts[CardNames.HIGHWAY] = "|+1 Карта|//|+1 Действие|//---//В этот ход,//карты стоят на [1] дешевле.";
RussianCardTexts[CardNames.BERSERKER] = "Получите карту дешевле этой.//Каждый другой игрок сбрасывает//до трёх карт в руке.//---//Когда вы получаете эту карту,//если у вас в игре есть//карта Действия, сыграйте//эту карту (Берсеркера).";
RussianCardTexts[CardNames.GUARD_DOG] = "|+2 Карты|//Если у вас в руке 5//или меньше карт, |+2 Карты|.//---//Когда другой игрок играет//карту Атаки, вы можете сначала//сыграть эту карту//из вашей руки.";
RussianCardTexts[CardNames.NOMADS] = "|+1 Покупка|//|+[2]|//---//Когда вы получаете или//выкидываете эту карту, +[2].";
RussianCardTexts[CardNames.TRAIL] = "|+1 Карта|//|+1 Действие|//---//Когда вы получаете,//выкидываете, или сбрасываете//эту карту вне фазы Расчистки,//можете сыграть её.";
RussianCardTexts[CardNames.WEAVER] = "Получите два Серебра//или карту не дороже [4].//---//Когда вы сбрасываете эту карту//вне фазы Расчистки,//можете сыграть её.";
RussianCardTexts[CardNames.SOUK] = "|+1 Покупка|//|+[7]|//-[1] за карту в вашей руке.//([0] - ваш минимум.)//---//Когда вы получаете эту карту,//выкиньте до двух карт из руки.";
RussianCardTexts[CardNames.CAULDRON] = "[!2]//|+1 Покупка|//Третий раз когда вы получаете//карту Действия в этот ход, каждый//другой игрок получает Проклятие.";
RussianCardTexts[CardNames.WHEELWRIGHT] = "|+1 Карта|//|+1 Действие|//Вы можете сбросить карту,//тогда получите карту Действия//не дороже сброшенной карты.";
RussianCardTexts[CardNames.WITCHS_HUT] = "|+4 Карты|//Сбросьте две карты, раскройте их.//Если обе - карты Действия,//каждый другой игрок//получает Проклятие.";

RussianCardTexts[CardNames.CAGE] = "Отложите до четырёх карт//из вашей руки лицом вниз//(на эту Клетку). В следующий раз//когда вы получите карту Победы,//выкиньте эту карту (Клетку),//и положите отложенные карты//в руку в конце хода.";
RussianCardTexts[CardNames.GROTTO] = "|+1 Действие|////Отложите до четырёх карт//из вашей руки лицом вниз//(на этот Грот). В начале//вашего следующего хода,//сбросьте их, затем//вытяните то же количество//карт.";
RussianCardTexts[CardNames.JEWELLED_EGG] = "[!1]//|+1 Покупка|//---//Когда вы выкидываете//эту карту, получите Грабленое.";
RussianCardTexts[CardNames.SEARCH] = "|+[2]|////В следующий раз когда//опустошится стопка Резерва,//выкиньте эту карту//и получите Грабленое.";
RussianCardTexts[CardNames.SHAMAN] = "|+1 Действие|//|+[1]|////Можете выкинуть//карту из руки.//---//Если Шаманы есть в Резерве,//в начале вашего хода, получите карту//со свалки не дороже [6].";
RussianCardTexts[CardNames.SECLUDED_SHRINE] = "|+[1]|////В следующий раз//когда вы получите//Сокровище, выкиньте до//двух карт из руки.";
RussianCardTexts[CardNames.SIREN] = "Каждый другой игрок//получает Проклятие.////В начале вашего следующего//хода, тяните карты,//пока у вас не будет//8 карт в руке.//---//Когда вы получаете эту карту,//выкиньте её или карту//Действия из руки.";
RussianCardTexts[CardNames.STOWAWAY] = "В начале вашего следующего//хода, |+2 Карты|.//---//Когда любой игрок получает//карту Длительности, вы//можете сыграть эту карту//(Безбилетника) из руки.";
RussianCardTexts[CardNames.TASKMASTER] = "|+1 Действие|, |+|[1], и если вы//получили карту стоимостью//ровно [5] в этом ходу, тогда//в начале вашего следующего//хода, повторите эти инструкции.";
RussianCardTexts[CardNames.ABUNDANCE] = "В следующий раз когда//вы получите карту Действия,//|+1 Покупка| и |+|[3].";
RussianCardTexts[CardNames.CABIN_BOY] = "|+1 Карта|//|+1 Действие|////В начале вашего следующего//хода, выберите: |+|[2];//или выкиньте эту карту,//тогда получите карту Длительности.";
RussianCardTexts[CardNames.CRUCIBLE] = "Выкиньте карту из руки.//|+|[1] за каждую//[1] её стоимости.";
RussianCardTexts[CardNames.FLAGSHIP] = "|+[2]|////В следующий раз когда//вы играете карту//Действия (не Команду),//сыграйте её ещё раз.";
RussianCardTexts[CardNames.FORTUNE_HUNTER] = "|+[2]|////Посмотрите на три//верхие карты вашей колоды.//Можете сыграть Сокровище.//Положите остальные карты//назад в любом порядке.";
RussianCardTexts[CardNames.GONDOLA] = "Либо сейчас, либо в начале//вашего следующего хода://|+|[2].//---//Когда вы получаете эту карту,//можете сыграть карту//Действия из руки.";
RussianCardTexts[CardNames.HARBOR_VILLAGE] = "|+1 Карта|//|+2 Действия|////После того как вы//в следующий раз сыграли//карту Действия в этом ходу,//если она дала вам |+|[],//|+|[1].";
RussianCardTexts[CardNames.LANDING_PARTY] = "|+2 Карты|//|+2 Действия|////В следующий раз, когда//первая сыгранная вами за//ход карта, - Сокровище,//положите эту карту (Десантный//Отряд) на верх колоды.";
RussianCardTexts[CardNames.MAPMAKER] = "Посмотрите на четыре//верхние карты вашей колоды.//Положите две в руку//и сборосьте остальные.//---//Когда другой игрок//получает карту Победы,//можете сыграть эту карту//(Создателя Карт) из руки.";
RussianCardTexts[CardNames.MAROON] = "Выкиньте карту из руки.//|+2 Карты| за каждый//присущий ей тип//(Действие, Атака, итд.).";
RussianCardTexts[CardNames.ROPE] = "[!1]//|+1 Покупка|////В начале вашего следующего//хода, |+1 Карта| и можете//выкинуть карту из руки.";
RussianCardTexts[CardNames.SWAMP_SHACKS] = "|+2 Действия|////|+1 Карта| за каждые три//карты у вас в игре//(с округлением вниз).";
RussianCardTexts[CardNames.TOOLS] = "Получите копию карты,//которая есть в игре//у любого из игроков.";
RussianCardTexts[CardNames.BURIED_TREASURE] = "В начале вашего следующего//хода, |+1 Покупка| и |+|[3].//---//Когда вы получаете эту//карту, сыграйте её.";
RussianCardTexts[CardNames.CREW] = "|+3 Карты|////В начале вашего следующего//хода, положите эту карту//на верх колоды.";
RussianCardTexts[CardNames.CUTTHROAT] = "Каждый другой игрок//сбрасывает до трёх//карт в руке.//В следующий раз когда//любой игрок получает//Сокровище не дешевле [5],//получите Грабленое.";
RussianCardTexts[CardNames.ENLARGE] = "Сейчас и в начале//вашего следующего хода://Выкиньте карту из руки,//и получите карту//не более чем на [2]//дороже выкинутой.";
RussianCardTexts[CardNames.FIGURINE] = "|+2 Карты|////Можете сбросить карту//Действия, тогда//|+1 Покупка| и |+|[1].";
RussianCardTexts[CardNames.FIRST_MATE] = "Сыграйте любое количество//одноимённых карт Действия//из руки, затем//тяните карты, пока у вас//не будет 6 карт в руке.";
RussianCardTexts[CardNames.FRIGATE] = "|+[3]|////До начала вашего//следующего хода, каждый//раз когда другой игрок//играет карту Действия,//он затем сбрасывает//до четырёх карт в руке.";
RussianCardTexts[CardNames.LONGSHIP] = "|+2 Действия|////В начале вашего//следующего хода,//|+2 Карты|.";
RussianCardTexts[CardNames.MINING_ROAD] = "|+1 Действие|//|+1 Покупка|//|+[2]|////Один раз за текущий ход,//когда вы получаете Сокровище,//можете сыграть его.";
RussianCardTexts[CardNames.PENDANT] = "|+|[1] за каждое разное//Сокровище у вас в игре.";
RussianCardTexts[CardNames.PICKAXE] = "[!1]////Выкиньте карту из руки.//Если она не дешевле [3],//получите Грабленое в руку.";
RussianCardTexts[CardNames.PILGRIM] = "|+4 Карты|////Положите карту из руки//на верх колоды.";
RussianCardTexts[CardNames.QUARTERMASTER] = "В начале каждого вашего//хода до конца игры,//выберите: Получите карту не//дороже [4], отложите её//на эту карту (Интенданта);//или положите одну из//отложенных карт в руку.";
RussianCardTexts[CardNames.SILVER_MINE] = "Получите Сокровище//дешевле этой карты//в руку.";
RussianCardTexts[CardNames.TRICKSTER] = "Каждый другой игрок//получает Проклятие. Один//раз за текущий ход, когда//вы сбрасываете Сокровище//из игры, можете отложить//его в сторону. Положите//его в руку в конце хода.";
RussianCardTexts[CardNames.WEALTHY_VILLAGE] = "|+1 Карта|//|+2 Действия|//---//Когда вы получаете эту карту,//если у вас есть//хотя бы три разных//сокровища в игре,//получите Грабленое.";
RussianCardTexts[CardNames.SACK_OF_LOOT] = "[!1]//|+1 Покупка|//Получите Грабленое.";
RussianCardTexts[CardNames.KINGS_CACHE] = "Можете сыграть Сокровище//из руки три раза.";
RussianCardTexts[CardNames.BURY] = "|+1 Покупка|////Положите любую карту из сброса//под низ колоды.";
RussianCardTexts[CardNames.AVOID] = "|+1 Покупка|////Когда вы в следующий раз тасуете вашу//колоду в этот ход, выберите до трёх карт//и положите их в сброс.";
RussianCardTexts[CardNames.DELIVER] = "|+1 Покупка|////В этот ход, каждый раз когда вы//получаете карту, отложите её в сторону,//и положите её в руку в конце хода.";
RussianCardTexts[CardNames.PERIL] = "Можете выкинуть карту Действия из руки,//тогда получите Грабленое.";
RussianCardTexts[CardNames.RUSH] = "|+1 Покупка|////В следующий раз когда вы получаете//карту Действия в этот ход, сыграйте её.";
RussianCardTexts[CardNames.FORAY] = "Сбросьте три карты, раскрывая их.//Если они все разные, получите Грабленое.";
RussianCardTexts[CardNames.LAUNCH] = "Один раз за ход: вернитесь в фазу Действия.//|+1 Карта|, |+1 Действие|, и |+1 Покупка|.";
RussianCardTexts[CardNames.MIRROR] = "|+1 Покупка|////В следующий раз когда вы получаете карту//Действия в этот ход, получите её копию.";
RussianCardTexts[CardNames.PREPARE] = "Отложите вашу руку в сторону лицом вверх.//В начале вашего следующего хода,//сыграйте отложенные карты Действия и Сокровища//в любом порядке, затем сбросьте//остальные отложенные карты.";
RussianCardTexts[CardNames.SCROUNGE] = "Выберите: Выкиньте карту из руки;//или получите Поместье со Свалки, тогда//получите карту не дороже [5].";
RussianCardTexts[CardNames.JOURNEY] = "Не сбрасывайте карты из руки в фазе Расчистки//в этом ходу, и получите дополнительный ход после текущего//(но не третий ход подряд).";
RussianCardTexts[CardNames.MAELSTROM] = "Выкиньте три карты из руки. Каждый другой игрок с пятью//или более картами в руке выкидывает одну из них.";
RussianCardTexts[CardNames.LOOTING] = "Получите Грабленое.";
RussianCardTexts[CardNames.INVASION] = "Вы можете сыграть карту Атаки из руки. Получите Герцогство.//Получите карту Действия на верх колоды.//Получите Грабленое; сыграйте его.";
RussianCardTexts[CardNames.PROSPER] = "Получите Грабленое, а также любое количество карт Сокровищ//с разными именами.";
RussianCardTexts[CardNames.AMPHORA] = "Либо сейчас, либо//в начале вашего//следующего хода://|+1 Покупка| и |+|[3].";
RussianCardTexts[CardNames.DOUBLOONS] = "[!3]////---//Когда вы получаете эту карту,//получите Золото.";
RussianCardTexts[CardNames.ENDLESS_CHALICE] = "Сейчас и в начале//каждого из ваших ходов//до конца игры://[!1]//|+1 Покупка|";
RussianCardTexts[CardNames.FIGUREHEAD] = "[!3]////В начале вашего//следующего хода,//|+2 Карты|.";
RussianCardTexts[CardNames.HAMMER] = "[!3]////Получите карту//не дороже [4].";
RussianCardTexts[CardNames.INSIGNIA] = "[!3]////В этом ходу, когда вы//получаете карту, можете//положить её на верх колоды.";
RussianCardTexts[CardNames.JEWELS] = "[!3]//|+1 Покупка|////В начале вашего//следующего хода,//положите эту карту//под низ колоды.";
RussianCardTexts[CardNames.ORB] = "Посмотрите на ваш//сброс. Выберите либо: Сыграйте//Действие или Сокровище оттуда;// или |+1 Покупка| и |+|[3].";
RussianCardTexts[CardNames.PRIZE_GOAT] = "[!3]//|+1 Покупка|////Можете выкинуть карту//из руки.";
RussianCardTexts[CardNames.PUZZLE_BOX] = "[!3]//|+1 Покупка|////Можете отложить карту//из руки в сторону.//Положите её в руку в конце хода.";
RussianCardTexts[CardNames.SEXTANT] = "[!3]//|+1 Покупка|////Посмотрите на верхние//5 карт вашей колоды.//Сбросьте любое количество.//Положите остальные назад//в любом порядке.";
RussianCardTexts[CardNames.SHIELD] = "[!3]//|+1 Покупка|//---//Когда другой игрок играет//карту Атаки, вы можете раскрыть//эту карту из руки, тогда//Атака вас не затронет.";
RussianCardTexts[CardNames.SPELL_SCROLL] = "Выкиньте эту карту,//тогда получите карту//дешевле. Если это//карта Действия или//Сокровище, можете сыграть её.";
RussianCardTexts[CardNames.STAFF] = "[!3]//|+1 Покупка|////Можете сыграть карту//Действия из руки.";
RussianCardTexts[CardNames.SWORD] = "[!3]//|+1 Покупка|////Каждый другой игрок//сбрасывает до четырёх//карт в руке.";
RussianCardTexts[CardNames.CHEAP] = "Дешёвые карты стоят на [1] меньше.";
RussianCardTexts[CardNames.CURSED] = "Когда вы получаете Проклятую карту, получите//Грабленое и Проклятие.";
RussianCardTexts[CardNames.FAWNING] = "Когда вы получаете Провинцию,//получите Раболепную карту.";
RussianCardTexts[CardNames.FATED] = "Когда вы тасуете колоду, можете раскрыть//Обречённые карты и положить их либо//на верх колоды, либо под низ.";
RussianCardTexts[CardNames.FRIENDLY] = "В начале вашей Фазы Расчистки,//можете сбросить Дружелюбную//карту, тогда получите Дружелюбную карту.";
RussianCardTexts[CardNames.HASTY] = "Когда вы получаете Торопливую карту, отложите//её в сторону, и сыграте её в начале//вышего следующего хода.";
RussianCardTexts[CardNames.INHERITED] = "Подготовка: Вы начинаете игру с Унаследованной//картой вместой одной из стартовых карт по вашему выбору.";
RussianCardTexts[CardNames.INSPIRING] = "Сыграв Вдохновляющую карту в ваш ход,//можете сыграть карту Действия из руки,//которой нет у вас в игре.";
RussianCardTexts[CardNames.NEARBY] = "Когда вы получаете Близлежащую карту, |+1 Покупка|.";
RussianCardTexts[CardNames.PATIENT] = "В начале вашей Фазы Расчистки, можете//отложите Терпеливые карты из руки, тогда//сыграйте их в начале вашего следующего хода.";
RussianCardTexts[CardNames.PIOUS] = "Когда вы получаете Набожную карту,//можете выкинуть карту из руки.";
RussianCardTexts[CardNames.RECKLESS] = "Следуйте инструкциям Безрассудной карты дважды.//Когда вы сбрасываете её из игры,//верните её в её стопку.";
RussianCardTexts[CardNames.RICH] = "Когда вы игрете Богатую карту, получите Серебро.";
RussianCardTexts[CardNames.SHY] = "В начале вашего хода, можете//сбросить одну Застенчивую карту, тогда |+2 Карты|.";
RussianCardTexts[CardNames.TIRELESS] = "Когда вы сбрасываете Неутомимую карту//из игры, отложите её, и положите её//на верх вашей колоды в конце хода.";
RussianCardTexts[CardNames.MARCHLAND] = "Приносит {1} за каждые//3 карты Победы, которые у вас есть.//---//Когда вы получаете эту карту,//|+1 Покупка|,//и сбросьте любое количество//карт Победы (раскрывая их),// получая +[1] за каждую.";

RussianCardTexts[CardNames.SHOP] = "|+1 Карта|//|+[1]|//Можете сыграть карту//Действия из руки,//копии которой нет у вас в игре.";
RussianCardTexts[CardNames.CARNIVAL] = "Раскройте 4 верхние//карты вашей колоды.//Возьмите в руку//по одной карте//с разными названиями//и сбросьте остальные.";
RussianCardTexts[CardNames.FERRYMAN] = "|+2 Карты|//|+1 Действие|//Сбросьте карту.//---//Подготовка: Выберите//неиспользуемую стопку карт//королевства стоимостью [3] или [4].//Получайте карту из стопки//каждый раз когда//получаете Паромщика.";
RussianCardTexts[CardNames.FARMHANDS] = "|+1 Карта|//|+2 Действия|//---//Когда вы получаете эту карту,//можете отложить карту//Действия или Сокровище//из руки и сыграть её в начале//вашего следующего хода.";
RussianCardTexts[CardNames.JOUST] = "|+1 Карта|//|+1 Действие|//|+[1]|//Можете отложить в сторону//Провинцию из руки,//тогда получите Награду//в руку. Сбросьте//Провинцию в фазе Расчистки.";
RussianCardTexts[CardNames.REWARDS_PILE] = "--";
RussianCardTexts[CardNames.COURSER] = "Выберите два разных варианта://|+2 Карты|; |+2 Действия|;//|+|[2]; Получите 4 Серебра.//%(Эта карта не представлена%//%в Резерве)%";
RussianCardTexts[CardNames.RENOWN] = "|+1 Покупка|////В этом ходу карты//стоят на [2] дешевле.//%(Эта карта не представлена%//%в Резерве)%";
RussianCardTexts[CardNames.CORONET] = "Можете дважды сыграть//карту Действия (не Награду)//из руки.////Можете дважды сыграть//Сокровище (не Награду)//из руки.//%(Эта карта не представлена%//%в Резерве)%";
RussianCardTexts[CardNames.DEMESNE] = "|+2 Действия|//|+2 Покупки|//Получите Золото.//---//Приносит {1} за каждое//Золото, которое у вас есть.//%(Эта карта не представлена%//%в Резерве)%";
RussianCardTexts[CardNames.HOUSECARL] = "|+1 Карта| за каждую//разную карту у вас//в игре.//%(Эта карта не представлена%//%в Резерве)%";
RussianCardTexts[CardNames.HUGE_TURNIP] = "|+2 Монетки|//|+|[1] за каждую вашу Монетку.//%(Эта карта не представлена%//%в Резерве)%";
RussianCardTexts[CardNames.FARRIER] = "|+1 Карта|//|+1 Действие|//|+1 Покупка|//---//Переплата: |+1 Карта|//в конце этого хода//за каждую переплаченную [1].";
RussianCardTexts[CardNames.INFIRMARY] = "|+1 Карта|//Можете выкинуть//карту из руки.//---//Переплата: Сыграйте эту карту//за каждую переплаченную [1].";
RussianCardTexts[CardNames.FOOTPAD] = "|+2 Монетки|//Каждый другой игрок//сбрасывает до трёх//карт в руке.//---//В играх использующих//эту стопку, когда вы//получаете карту в фазе//Дествия, |+1 Карта|.";

RussianCardTexts[CardNames.FISHMONGER] = "|+1 Покупка|//|+[1]|//---//Можете сыграть эту карту//из вашей колоды, как если бы//она была у вас в руке.";
RussianCardTexts[CardNames.SNAKE_WITCH] = "|+1 Карта|//|+1 Действие|//Если у вас нет//повторяющихся карт в руке,//можете раскрыть руку//и вернуть эту карту в стопку,//тогда каждый другой//игрок получает Проклятие.";
RussianCardTexts[CardNames.ARISTOCRAT] = "Если число Аристократов//у вас в игре:////1 или 5: |+3 Действия|;//2 или 6: |+3 Карты|;//3 или 7: |+|[3];//4 или 8: |+3 Покупки|.";
RussianCardTexts[CardNames.CRAFTSMAN] = "|+[2D]|////Получите карту//не дороже [5].";
RussianCardTexts[CardNames.RIVERBOAT] = "В начале вашего//следующего хода сыграйте//отложенную карту,//оставляя её на месте.//---//Подготовка: Отложите в сторону//неиспользованную карту//Действия (не Длительноть)//стоимостью [5].";
RussianCardTexts[CardNames.ROOT_CELLAR] = "|+3 Карты|//|+1 Действие|//|+[3D]|";
RussianCardTexts[CardNames.ALLEY] = "|+1 Карта|//|+1 Действие|//Сбросьте карту.//---//Можете сыграть эту карту//из вашей колоды, как если бы//она была у вас в руке.";
RussianCardTexts[CardNames.CHANGE] = "Если у вас есть [D], |+|[3].////Иначе выкиньте карту из руки,//и получите карту стоящую//больше []. Возьмите [D]//эквивалентно разнице//в [] между картами.";
RussianCardTexts[CardNames.NINJA] = "|+1 Карта|//Каждый другой игрок//сбрасывает до трёх карт в руке.//---//Можете сыграть эту карту//из вашей колоды, как если бы//она была у вас в руке.";
RussianCardTexts[CardNames.POET] = "|+1 <>|//|+1 Карта|//|+1 Действие|//Раскройте верхнюю карту//вашей колоды.//Если она стоит [3] или//меньше, положите её в руку.";
RussianCardTexts[CardNames.RIVER_SHRINE] = "|+1 <>|////Выкиньте до двух карт//из руки. В начале фазы//Расчистки, если вы не//получали карт в вашей фазе//Покупок в этот ход,//получите карту не дороже [4].";
RussianCardTexts[CardNames.RUSTIC_VILLAGE] = "|+1 <>|//|+1 Карта|//|+2 Действия|//Можете сбросить//2 карты, тогда |+1 Карта|.";
RussianCardTexts[CardNames.GOLD_MINE] = "|+1 Карта|//|+1 Действие|//|+1 Покупка|//Можете получить//Золото и +[4D].";
RussianCardTexts[CardNames.IMPERIAL_ENVOY] = "|+5 Карт|//|+1 Покупка|//|+[2D]|";
RussianCardTexts[CardNames.KITSUNE] = "|+1 <>|//Выберите две разные опции://|+|[2]; |+2 Действия|;//каждый другой игрок//получает Проклятие;//получите Серебро.";
RussianCardTexts[CardNames.LITTER] = "|+2 Карты|//|+2 Действия|//|+[1D]|";
RussianCardTexts[CardNames.RICE_BROKER] = "|+1 Действие|//Выкиньте карту из руки.//Если это Сокровище,//|+2 Карты|.//Если это Действие,//|+5 Карт|.";
RussianCardTexts[CardNames.RONIN] = "Тяните карты, пока у вас//не будет 7 карт в руке.//---//Можете сыграть эту карту//из вашей колоды, как если бы//она была у вас в руке.";
RussianCardTexts[CardNames.TANUKI] = "Выкиньте карту из руки.//Получите карту стоимостью//не более чем на[2]//дороже выкинутой.//---//Можете сыграть эту карту//из вашей колоды, как если бы//она была у вас в руке.";
RussianCardTexts[CardNames.TEA_HOUSE] = "|+1 <>|//|+1 Карта|//|+1 Действие|//|+[2]|";
RussianCardTexts[CardNames.SAMURAI] = "Каждый другой игрок//сбрасывает до трёх//карт в руке (единожды).//В начале каждого вашего//хода до конца игры, +[1].//%(Эта карта остаётся в игре.)%";
RussianCardTexts[CardNames.RICE] = "|+1 Покупка|//|+|[1] за каждый разный//тип карт у вас в игре.";
RussianCardTexts[CardNames.MOUNTAIN_SHRINE] = "|+1 <>|//+[2]//Можете выкинуть карту//из руки. Тогда, если//на Свалке есть карты//Действия, |+2 Карты|";
RussianCardTexts[CardNames.DAIMYO] = "|+1 Карта|//|+1 Действие|//В следующий раз когда//вы сыграете карту//Действия (не Команду)//в этот ход,//сыграте её ещё раз.";
RussianCardTexts[CardNames.ARTIST] = "|+1 Действие|////|+1 Карта| за каждую карту,//которой у вас в игре//ровно по одной копии.";
RussianCardTexts[CardNames.APPROACHING_ARMY] = "После того как вы играете карту Атаки, +[1].//---//Подготовка: Добавьте стопку карт Атаки в игру.";
RussianCardTexts[CardNames.BIDING_TIME] = "В начале вашей фазы Расчистки, отложите вашу//руку в сторону лицом вниз. В начале//вашего следующего хода, положите эти карты в руку.";
RussianCardTexts[CardNames.BUREAUCRACY] = "Когда вы получаете карту//которая стоит не [0], получите Медь.";
RussianCardTexts[CardNames.DIVINE_WIND] = "Когда вы снимаете последнее| |<>, уберите все//стопки карт Королевства из Резерва,//и выложите 10 новых случайным образом.";
RussianCardTexts[CardNames.ENLIGHTENMENT] = "Сокровища также являются картами Действия.//Когда вы играете Сокровище в фазе Действий,//вместо того чтобы следовать его тексту,//|+1 Карта| и |+1 Действие|.";
RussianCardTexts[CardNames.FLOURISHING_TRADE] = "Карты стоят на [1] дешевле.//Можете использовать оставшиеся Дейстия как Покупки.";
RussianCardTexts[CardNames.GOOD_HARVEST] = "В первый раз когда вы играете//каждое по-разному названное Сокровище каждый ход,//сначала |+1 Покупка| и +[1].";
RussianCardTexts[CardNames.GREAT_LEADER] = "После каждой сыгранной вами карты Действия, |+1 Действие|.";
RussianCardTexts[CardNames.GROWTH] = "Когда вы получаете Сокровище, получите карту дешевле.";
RussianCardTexts[CardNames.HARSH_WINTER] = "Когда вы получаете карту в ваш ход,//если на её стопке есть [D], возьмите его;//иначе положите [2D] на стопку.";
RussianCardTexts[CardNames.KIND_EMPEROR] = "В начале вашего хода, а также когда вы убираете//последнее| |<>: Получите карту Действия в руку.";
RussianCardTexts[CardNames.PANIC] = "Когда вы играете Сокровище, |+2 Покупки|, а когда вы//сбрасываете его из игры, верните его в стопку.";
RussianCardTexts[CardNames.PROGRESS] = "Когда вы получаете карту, положите её на верх колоды.";
RussianCardTexts[CardNames.RAPID_EXPANSION] = "Когда вы получате карту Действия или Сокровище,//отложите полученную карту в сторону,//и сыграйте её в начале вашего следующего хода.";
RussianCardTexts[CardNames.SICKNESS] = "В начале вашего хода выберите одно://Получите Проклятие на верх колоды;//или сбросьте три карты.";
RussianCardTexts[CardNames.AMASS] = "Если у вас нет карт Действия в игре,//получите карту Действия не дороже [5].";
RussianCardTexts[CardNames.ASCETICISM] = "Заплатите любое число [] чтобы//выкинуть такое же количество карт из руки.";
RussianCardTexts[CardNames.CREDIT] = "Получите карту Действия или Сокровище//не дороже [8]. Возмите столько [D] сколько она стоит.";
RussianCardTexts[CardNames.FORESIGHT] = "Раскрывайте карты с вашей колоды пока//не раскроете карту Действия. Отложите её//в сторону и сбросьте остальные раскрытые карты.//Положите её в руку в конце хода.";
RussianCardTexts[CardNames.KINTSUGI] = "Выкиньте карту из руки. Если вы получали Золото//в течение игры, получите карту стоимостью//не более чем на [2] дороже выкинутой.";
RussianCardTexts[CardNames.PRACTICE] = "Можете сыграть карту Действи из руки дважды.";
RussianCardTexts[CardNames.SEA_TRADE] = "|+1 Карта| за карту Действия у вас в игре.//Выкиньте столько же карт или меньше из руки.";
RussianCardTexts[CardNames.RECEIVE_TRIBUTE] = "Если вы полчили хотя бы три карты в этот ход,//получите до трёх разных карт Действия,// копий которых нет у вас в игре.";
RussianCardTexts[CardNames.GATHER] = "Получите карту стоимостью ровно [3],//карту стоимостью ровно [4],//и карту стоимостью ровно [5].";
RussianCardTexts[CardNames.CONTINUE] = "Один раз за ход: Получите карту Действия (не Атаку)//не дороже [4]. Вернитесь в вашу фазу Действий//и сыграйте полученную карту. |+1 Действие| и |+1 Покупка|.";