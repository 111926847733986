"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CoinsLayer = function (_StackViewLayer) {
    _inherits(CoinsLayer, _StackViewLayer);

    function CoinsLayer(rootNode, cardStack) {
        _classCallCheck(this, CoinsLayer);

        var _this = _possibleConstructorReturn(this, (CoinsLayer.__proto__ || Object.getPrototypeOf(CoinsLayer)).call(this, rootNode, cardStack));

        _this.node.style.zIndex = 7;
        _this.node.classList.add("coins-layer");
        return _this;
    }

    _createClass(CoinsLayer, [{
        key: "redraw",
        value: function redraw(w, h) {
            var card = this.cardStack.topCard;
            var cardName = card.cardName;
            var cardSize = this.cardStack.cardSize;
            var cost = card.cost;
            var production = cardName.coinProduction;
            var hasProduction = !isUndefined(production);
            var isShifted = this.cardStack.hasFilter("shifted-name");
            var shouldHide = cardName.isBack() || this.cardStack.hasFilter("appears-anonymous");

            if (shouldHide) {
                this.node.classList.add("invisible");
                return;
            } else {
                this.node.classList.remove("invisible");
            }

            clearChildNodes(this.node);

            if (cardName.shouldShowCost() && cardSize !== CardSizes.MICRO) {
                var position = getCostPosition(w, cardName, cardSize);
                var costNode = createCostNode(cost);
                assignPositionToNode(position, costNode);
                costNode.style.fontSize = costNode.style.height;
                this.node.appendChild(costNode);
            }

            if (hasProduction && cardSize === CardSizes.FULL) {
                var cardNameBbox = getCardNameBbox(w, cardName, cardSize, isShifted).roundPositions();
                var leftProductionNode = createCostNode(production);
                assignPositionToNode(new Rectangle(0.02 * w, cardNameBbox.y - 0.01 * h, 0.1 * h, 0.1 * h), leftProductionNode);
                var rightX = cardName === CardNames.POTION ? 0.98 * w - 0.07 * h : 0.98 * w - 0.1 * h;
                var rightProductionNode = createCostNode(production);
                assignPositionToNode(new Rectangle(rightX, cardNameBbox.y - 0.01 * h, 0.1 * h, 0.1 * h), rightProductionNode);
                leftProductionNode.style.fontSize = leftProductionNode.style.height;
                rightProductionNode.style.fontSize = rightProductionNode.style.height;
                this.node.appendChild(leftProductionNode);
                this.node.appendChild(rightProductionNode);
            }
        }
    }]);

    return CoinsLayer;
}(StackViewLayer);