"use strict";
// Intro page of Store tab in Lobby

CzechParagraphs.STORE_INTRO = new Paragraph(["Playing with the base cards is for free.", "Playing with expansions requires a subscription.", "One subscriber per game is sufficient."]);

CzechParagraphs.STORE_BUYING = new Paragraph(["Subscriptions are not automatically recurring.", "You can simply buy again to continue subscribing or upgrade.", "The price will be reduced by the value of your remaining subscription.", "Buying for longer periods will get you a discount."]);

CzechParagraphs.STORE_SUBSCRIPTIONS = new Paragraph(["Silver Subscription &mdash; half the expansions &mdash; € 2.15 per month.", "Gold Subscription &mdash; all current expansions &mdash; € 4.30 per month.", "Gold does not include future expansions."]);

CzechParagraphs.STORE_DOLLARS = new Paragraph(["Purchases are priced in euros.", "Your payment in dollars will be automatically converted."]);

CzechParagraphs.STORE_UPGRADE = new Paragraph(["Your subscriptions contain all expansions except the latest.", "Choose 'Yes' and this expansion will be added to your subscriptions.", "The expire date will be the earliest of your current subscriptions.", "Choose 'No' for all other purchases."]);