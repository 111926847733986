"use strict";

var GermanCardModes = {};

GermanCardModes[CardModes.PAWN_CARD_ACTION] = "Karte, Aktion";
GermanCardModes[CardModes.PAWN_CARD] = "+1 Karte";
GermanCardModes[CardModes.PAWN_ACTION] = "+1 Aktion";
GermanCardModes[CardModes.PAWN_BUY] = "+1 Kauf";
GermanCardModes[CardModes.PAWN_COIN] = "+1 Geld";
GermanCardModes[CardModes.STEWARD_CARDS] = "+2 Karten";
GermanCardModes[CardModes.STEWARD_COINS] = "+2 Geld";
GermanCardModes[CardModes.STEWARD_TRASH] = "Entsorge 2";
GermanCardModes[CardModes.MINION_COINS] = "+2 Geld";
GermanCardModes[CardModes.MINION_NEWHAND] = "Hand ablegen";
GermanCardModes[CardModes.NOBLES_CARDS] = "+3 Karten";
GermanCardModes[CardModes.NOBLES_ACTIONS] = "+2 Aktionen";
GermanCardModes[CardModes.COURTIER_ACTION] = "+1 Aktion";
GermanCardModes[CardModes.COURTIER_BUY] = "+1 Kauf";
GermanCardModes[CardModes.COURTIER_COINS] = "+3 Geld";
GermanCardModes[CardModes.COURTIER_GOLD] = "Nimm ein Gold";
GermanCardModes[CardModes.NATIVE_VILLAGE_SET_ASIDE] = "Lege beiseite";
GermanCardModes[CardModes.NATIVE_VILLAGE_RETURN] = "Nimm auf";
GermanCardModes[CardModes.PIRATE_SHIP_RAID] = "Angriff";
GermanCardModes[CardModes.PIRATE_SHIP_PROFIT] = "Geld";
GermanCardModes[CardModes.SCRYING_POOL_TOPDECK] = "Auf Nachziehstapel";
GermanCardModes[CardModes.SCRYING_POOL_DISCARD] = "Ablegen";
GermanCardModes[CardModes.LOAN_DISCARD] = "Ablegen";
GermanCardModes[CardModes.LOAN_TRASH] = "Entsorgen";
GermanCardModes[CardModes.TRUSTY_STEED_CARDS_ACTIONS] = "Karten, Aktionen";
GermanCardModes[CardModes.TRUSTY_STEED_CARDS] = "+2 Karten";
GermanCardModes[CardModes.TRUSTY_STEED_ACTIONS] = "+2 Aktionen";
GermanCardModes[CardModes.TRUSTY_STEED_COINS] = "+2 Geld";
GermanCardModes[CardModes.TRUSTY_STEED_SILVERS] = "Nimm 4 Silber";
GermanCardModes[CardModes.JESTER_THEY_GAIN] = "Gegner nimmt";
GermanCardModes[CardModes.JESTER_YOU_GAIN] = "Du nimmst";
GermanCardModes[CardModes.DUCHESS_KEEP] = "Behalten";
GermanCardModes[CardModes.DUCHESS_DISCARD] = "Ablegen";
GermanCardModes[CardModes.ORACLE_TOPDECK] = "Auf Nachziehstapel";
GermanCardModes[CardModes.ORACLE_DISCARD] = "Ablegen";
GermanCardModes[CardModes.JACK_KEEP] = "Behalten";
GermanCardModes[CardModes.JACK_DISCARD] = "Ablegen";
GermanCardModes[CardModes.SPICE_MERCHANT_CARDS] = "Karten, Aktion";
GermanCardModes[CardModes.SPICE_MERCHANT_COINS] = "Geld, Kauf";
GermanCardModes[CardModes.CATACOMBS_TAKE] = "Auf die Hand";
GermanCardModes[CardModes.CATACOMBS_DISCARD] = "Ablegen";
GermanCardModes[CardModes.COUNT_DISCARD] = "Ablegen";
GermanCardModes[CardModes.COUNT_TOPDECK] = "Auf Nachziehstapel";
GermanCardModes[CardModes.COUNT_GAIN_COPPER] = "Nimm Kupfer";
GermanCardModes[CardModes.COUNT_COINS] = "+3 Geld";
GermanCardModes[CardModes.COUNT_TRASH_HAND] = "Hand entsorgen";
GermanCardModes[CardModes.COUNT_GAIN_DUCHY] = "Nimm Herzogtum";
GermanCardModes[CardModes.HUNTING_GROUNDS_ESTATES] = "Nimm 3 Anwesen";
GermanCardModes[CardModes.HUNTING_GROUNDS_DUCHY] = "Nimm Herzogtum";
GermanCardModes[CardModes.IRONMONGER_KEEP] = "Behalten";
GermanCardModes[CardModes.IRONMONGER_DISCARD] = "Ablegen";
GermanCardModes[CardModes.SCAVENGER_DISCARD] = "Ablegen";
GermanCardModes[CardModes.SCAVENGER_KEEP] = "Nicht Ablegen";
GermanCardModes[CardModes.SQUIRE_ACTIONS] = "+2 Aktionen";
GermanCardModes[CardModes.SQUIRE_BUYS] = "+2 Käufe";
GermanCardModes[CardModes.SQUIRE_SILVER] = "Silber";
GermanCardModes[CardModes.MERCENARY_DONT_TRASH] = "Nicht entsorgen";
GermanCardModes[CardModes.DOCTOR_TRASH] = "Entsorgen";
GermanCardModes[CardModes.DOCTOR_DISCARD] = "Ablegen";
GermanCardModes[CardModes.DOCTOR_TOPDECK] = "Auf Nachziehstapel";
GermanCardModes[CardModes.AMULET_COIN] = "+1 Geld";
GermanCardModes[CardModes.AMULET_TRASH] = "Entsorgen";
GermanCardModes[CardModes.AMULET_SILVER] = "Silber";
GermanCardModes[CardModes.MISER_COPPER_TO_MAT] = "Kupfer auf Tableau";
GermanCardModes[CardModes.MISER_PROFIT] = "+ Geld";
GermanCardModes[CardModes.MESSENGER_DISCARD] = "Ablegen";
GermanCardModes[CardModes.MESSENGER_KEEP] = "Behalten";
GermanCardModes[CardModes.QUEST_ATTACK] = "Angriffskarte";
GermanCardModes[CardModes.QUEST_CURSES] = "2 Flüche";
GermanCardModes[CardModes.QUEST_CARDS] = "6 Karten";
GermanCardModes[CardModes.TEACHER_CARD] = "+Karte";
GermanCardModes[CardModes.TEACHER_ACTION] = "+Aktion";
GermanCardModes[CardModes.TEACHER_BUY] = "+Kauf";
GermanCardModes[CardModes.TEACHER_COIN] = "+Geld";
GermanCardModes[CardModes.LIBRARY_DRAW_IT] = "Behalten";
GermanCardModes[CardModes.LIBRARY_SKIP_IT] = "Überspringen";
GermanCardModes[CardModes.GLADIATOR_PRETEND] = "Nichts aufdecken";
GermanCardModes[CardModes.SPRAWLING_CASTLE_ESTATES] = "Nimm 3 Anwesen";
GermanCardModes[CardModes.SPRAWLING_CASTLE_DUCHY] = "Nimm Herzogtum";
GermanCardModes[CardModes.CHARM_BUY_MONEY] = "Kauf, +2 Geld";
GermanCardModes[CardModes.CHARM_COPY] = "Extra-Karte";
GermanCardModes[CardModes.WILD_HUNT_CARDS] = "Karten";
GermanCardModes[CardModes.WILD_HUNT_POINTS] = "Punkte";
GermanCardModes[CardModes.PRINCE_SET_ASIDE] = "Beiseite legen";
GermanCardModes[CardModes.PRINCE_NO] = "Nicht beiseite legen";
GermanCardModes[CardModes.HAMLET_ACTION] = "Für Aktion ablegen";
GermanCardModes[CardModes.HAMLET_BUY] = "Für Kauf ablegen";
GermanCardModes[CardModes.HAMLET_BOTH] = "Beides";
GermanCardModes[CardModes.GOVERNOR_CARDS] = "+3 Karten";
GermanCardModes[CardModes.GOVERNOR_TREASURE] = "Nimm Gold";
GermanCardModes[CardModes.GOVERNOR_REMODEL] = "Entsorge & Nimm";
GermanCardModes[CardModes.BLESSED_VILLAGE_NOW] = "Jetzt empfangen";
GermanCardModes[CardModes.BLESSED_VILLAGE_LATER] = "Nächsten Zug";
GermanCardModes[CardModes.PIXIE_TRASH] = "Entsorgen";
GermanCardModes[CardModes.PIXIE_KEEP] = "Behalten";
GermanCardModes[CardModes.SACRED_GROVE_RECEIVE] = "Empfangen";
GermanCardModes[CardModes.SACRED_GROVE_NO] = "Nein danke";
GermanCardModes[CardModes.ZOMBIE_SPY_DISCARD] = "Lege ab";
GermanCardModes[CardModes.ZOMBIE_SPY_TOPDECK] = "Auf Nachziehstapel";
GermanCardModes[CardModes.START_GAME] = "Starte Spiel";
GermanCardModes[CardModes.PEARL_DIVER_TOPDECK] = GermanPhrases[Phrases.TOP];
GermanCardModes[CardModes.PEARL_DIVER_BOTTOMDECK] = GermanPhrases[Phrases.BOTTOM];
GermanCardModes[CardModes.SCEPTER_FAIL_ACTION] = "Aktion (verfällt)";
GermanCardModes[CardModes.SCEPTER_ADD_COINS] = "+2 Geld";
GermanCardModes[CardModes.TREASURER_FAIL] = "Tue nichts";
GermanCardModes[CardModes.TREASURER_KEY] = "Erhalte den Schlüssel";
GermanCardModes[CardModes.BORDER_GUARD_LANTERN] = "Laterne";
GermanCardModes[CardModes.BORDER_GUARD_HORN] = "Horn";
GermanCardModes[CardModes.BORDER_GUARD_FAIL] = "Tue nichts";
GermanCardModes[CardModes.PAGEANT_YES] = "+1 Taler";
GermanCardModes[CardModes.PAGEANT_NO] = "Nein danke";
GermanCardModes[CardModes.SINISTER_PLOT_ADD] = "Marker auf Plan";
GermanCardModes[CardModes.SINISTER_PLOT_DRAW] = "Ziehe Karten";
GermanCardModes[CardModes.PLAY] = "Spielen";
GermanCardModes[CardModes.DONT_PLAY] = "Nicht spielen";
GermanCardModes[CardModes.VILLAGE_GREEN_NOW] = "Jetzt ausführen";
GermanCardModes[CardModes.VILLAGE_GREEN_LATER] = "Nächsten Zug";
GermanCardModes[CardModes.BARGE_NOW] = "Jetzt ausführen";
GermanCardModes[CardModes.BARGE_LATER] = "Nächsten Zug";
GermanCardModes[CardModes.SCRAP_CARD] = "+1 Karte";
GermanCardModes[CardModes.SCRAP_ACTION] = "+1 Aktion";
GermanCardModes[CardModes.SCRAP_BUY] = "+1 Kauf";
GermanCardModes[CardModes.SCRAP_COIN] = "+1 Geld";
GermanCardModes[CardModes.SCRAP_SILVER] = "Nimm ein Silber";
GermanCardModes[CardModes.SCRAP_HORSE] = "Nimm ein Pferd";
GermanCardModes[CardModes.MODE_OF_THE_BUTTERFLY] = "Schmetterling";
GermanCardModes[CardModes.MODE_OF_THE_CAMEL] = "Kamel";
GermanCardModes[CardModes.MODE_OF_THE_CHAMELEON] = "Chamäleon";
GermanCardModes[CardModes.MODE_OF_THE_FROG] = "Frosch";
GermanCardModes[CardModes.MODE_OF_THE_GOAT] = "Ziege";
GermanCardModes[CardModes.MODE_OF_THE_HORSE] = "Pferd";
GermanCardModes[CardModes.MODE_OF_THE_MOLE] = "Maulwurf";
GermanCardModes[CardModes.MODE_OF_THE_MONKEY] = "Affe";
GermanCardModes[CardModes.MODE_OF_THE_MOUSE] = "Maus";
GermanCardModes[CardModes.MODE_OF_THE_MULE] = "Maulesel";
GermanCardModes[CardModes.MODE_OF_THE_OTTER] = "Otter";
GermanCardModes[CardModes.MODE_OF_THE_OWL] = "Eule";
GermanCardModes[CardModes.MODE_OF_THE_OX] = "Ochse";
GermanCardModes[CardModes.MODE_OF_THE_PIG] = "Schwein";
GermanCardModes[CardModes.MODE_OF_THE_RAT] = "Ratte";
GermanCardModes[CardModes.MODE_OF_THE_SEAL] = "Robbe";
GermanCardModes[CardModes.MODE_OF_THE_SHEEP] = "Schaf";
GermanCardModes[CardModes.MODE_OF_THE_SQUIRREL] = "Eichhörnchen";
GermanCardModes[CardModes.MODE_OF_THE_TURTLE] = "Schildkröte";
GermanCardModes[CardModes.MODE_OF_THE_WORM] = "Wurm";
GermanCardModes[CardModes.BUTTERFLY_RETURN_IT] = "Lege zurück";
GermanCardModes[CardModes.BUTTERFLY_DO_NOTHING] = "Tue nichts";
GermanCardModes[CardModes.WAYFARER_SILVER] = "Nimm ein Silber";
GermanCardModes[CardModes.DESPERATION_CURSE] = "Nimm einen Fluch";
GermanCardModes[CardModes.CRYSTAL_BALL_TRASH] = "Entsorgen";
GermanCardModes[CardModes.CRYSTAL_BALL_DISCARD] = "Ablegen";
GermanCardModes[CardModes.CRYSTAL_BALL_PLAY] = "Spielen";
GermanCardModes[CardModes.CRYSTAL_BALL_TOPDECK] = "Zurück auf Nachziehstapel";
GermanCardModes[CardModes.TOWN_VILLAGE] = "+1 Karte +2 Aktionen";
GermanCardModes[CardModes.TOWN_COINS_BUY] = "+1 Kauf +2 Geld";
GermanCardModes[CardModes.MODIFY_CANTRIP] = "+1 Karte +1 Aktion";
GermanCardModes[CardModes.MODIFY_GAIN] = "Nimm eine Karte";
GermanCardModes[CardModes.DESERT_GUIDES_KEEP] = "Behalten";
GermanCardModes[CardModes.DESERT_GUIDES_NEW_HAND] = "Neue Hand";
GermanCardModes[CardModes.BAUBLE_BUY] = "+1 Kauf";
GermanCardModes[CardModes.BAUBLE_COIN] = "+1 Geld";
GermanCardModes[CardModes.BAUBLE_FAVOR] = "+1 Gefallen";
GermanCardModes[CardModes.BAUBLE_TOPDECK] = "Auf Nachziehstapel";
GermanCardModes[CardModes.BROKER_CARDS] = "+ Karten";
GermanCardModes[CardModes.BROKER_ACTIONS] = "+ Aktionen";
GermanCardModes[CardModes.BROKER_COINS] = "+ Geld";
GermanCardModes[CardModes.BROKER_FAVORS] = "+ Gefallen";
GermanCardModes[CardModes.INNKEEPER_CARD] = "+1 Karte";
GermanCardModes[CardModes.INNKEEPER_3] = "+3 Karten, 3 ablegen";
GermanCardModes[CardModes.INNKEEPER_5_6] = "+5 Karten, 6 ablegen";
GermanCardModes[CardModes.CAPITAL_CITY_DISCARD] = "2 ablegen für +2 Geld";
GermanCardModes[CardModes.CAPITAL_CITY_PAY] = "Zahle 2 Geld für +2 Karten";
GermanCardModes[CardModes.SPECIALIST_PLAY_AGAIN] = "Nochmal spielen";
GermanCardModes[CardModes.SPECIALIST_GAIN_COPY] = "Gleiche Karte nehmen";
GermanCardModes[CardModes.HILL_FORT_TO_HAND] = "Karte auf die Hand";
GermanCardModes[CardModes.HILL_FORT_CANTRIP] = "+1 Karte +1 Aktion";
GermanCardModes[CardModes.STRONGHOLD_COINS] = "+3 Geld";
GermanCardModes[CardModes.STRONGHOLD_CARDS] = "Nächster Zug: +3 Karten";
GermanCardModes[CardModes.TOWN_CRIER_COINS] = "+2 Geld";
GermanCardModes[CardModes.TOWN_CRIER_SILVER] = "Nimm ein Silber";
GermanCardModes[CardModes.TOWN_CRIER_CANTRIP] = "+1 Karte +1 Aktion";
GermanCardModes[CardModes.BLACKSMITH_OWL] = "Ziehe bis 6 Handkarten";
GermanCardModes[CardModes.BLACKSMITH_OTTER] = "+2 Karten";
GermanCardModes[CardModes.BLACKSMITH_PIG] = "+1 Karte +1 Aktion";
GermanCardModes[CardModes.LURKER_TRASH] = "Entsorge aus dem Vorrat";
GermanCardModes[CardModes.LURKER_GAIN] = "Nimm aus dem Müll";
GermanCardModes[CardModes.GRAVEROBBER_GAIN] = "Nimm aus dem Müll";
GermanCardModes[CardModes.GRAVEROBBER_TRASH] = "Entsorge & Nimm";
GermanCardModes[CardModes.TREASURER_TRASH] = "Entsorge";
GermanCardModes[CardModes.TREASURER_GAIN] = "Nimm aus dem Müll";
GermanCardModes[CardModes.INVESTMENT_COIN] = "+1 Geld";
GermanCardModes[CardModes.INVESTMENT_VP] = "Entsorgen für +VP";
GermanCardModes[CardModes.WEAVER_SILVERS] = "Nimm 2 Silber";
GermanCardModes[CardModes.CABIN_BOY_COINS] = "+2 Geld";
GermanCardModes[CardModes.CABIN_BOY_GAIN] = "Entsorge für Dauerkarte";
GermanCardModes[CardModes.GONDOLA_NOW] = "+2 Geld";
GermanCardModes[CardModes.GONDOLA_LATER] = "Nächsten Zug";
GermanCardModes[CardModes.QUARTERMASTER_GAIN] = "Nimm eine Karte";
GermanCardModes[CardModes.QUARTERMASTER_TAKE] = "Nimm vom Quartiermeister";
GermanCardModes[CardModes.AMPHORA_NOW] = "+1 Kauf, +3 Geld";
GermanCardModes[CardModes.AMPHORA_LATER] = "Nächsten Zug";
GermanCardModes[CardModes.SPELL_SCROLL_PLAY] = "Spielen";
GermanCardModes[CardModes.SPELL_SCROLL_REFUSE] = "Nicht spielen";
GermanCardModes[CardModes.COURSER_CARDS_ACTIONS] = "Karten & Aktionen";
GermanCardModes[CardModes.COURSER_CARDS] = "+2 Karten";
GermanCardModes[CardModes.COURSER_ACTIONS] = "+2 Aktionen";
GermanCardModes[CardModes.COURSER_COINS] = "+2 Geld";
GermanCardModes[CardModes.COURSER_SILVERS] = "+4 Silber";
GermanCardModes[CardModes.SICKNESS_FAIL_DISCARD] = "3 Karten ablegen (verfällt)";
GermanCardModes[CardModes.SICKNESS_CURSE] = "Fluch auf Nachziehstapel";
GermanCardModes[CardModes.SICKNESS_FAIL_CURSE] = "Fluch nehmen (verfällt)";
GermanCardModes[CardModes.SICKNESS_DISCARD] = "Alle Karten ablegen";
GermanCardModes[CardModes.KITSUNE_ACTIONS] = "+2 Aktionen";
GermanCardModes[CardModes.KITSUNE_COINS] = "+2 Geld";
GermanCardModes[CardModes.KITSUNE_CURSE] = "Fluch verteilen";
GermanCardModes[CardModes.KITSUNE_SILVER] = "Nimm ein Silber";

GermanCardModes[ExtraTurnTypes.FLEET] = GermanCardNames[CardNames.FLEET].singular;
GermanCardModes[ExtraTurnTypes.SEIZE_THE_DAY] = GermanCardNames[CardNames.SEIZE_THE_DAY].singular;
GermanCardModes[ExtraTurnTypes.MISSION] = GermanCardNames[CardNames.MISSION].singular;
GermanCardModes[ExtraTurnTypes.OUTPOST] = GermanCardNames[CardNames.OUTPOST].singular;
GermanCardModes[ExtraTurnTypes.POSSESSION] = GermanCardNames[CardNames.POSSESSION].singular;
GermanCardModes[ExtraTurnTypes.ISLAND_FOLK] = GermanCardNames[CardNames.ISLAND_FOLK].singular;
GermanCardModes[ExtraTurnTypes.VOYAGE] = GermanCardNames[CardNames.VOYAGE].singular;
GermanCardModes[ExtraTurnTypes.JOURNEY] = GermanCardNames[CardNames.JOURNEY].singular;