"use strict";

// Layout constants

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var LEFT_COLUMN_WIDTH = 15;
var RIGHT_COLUMN_WIDTH = 23;
var CENTRAL_COLUMN_WIDTH = 100 - LEFT_COLUMN_WIDTH - RIGHT_COLUMN_WIDTH;

var BASE_SUPPLY_HEIGHT = 50;
var MAIN_SUPPLY_HEIGHT = 35;
var CENTRAL_HEIGHT = 33;
var OPPONENT_HEIGHT = 9.5;
var OPPONENT_INFO_HEIGHT = 5.5;

var SPEC_HAND_HEIGHT = 20;
var HERO_HAND_HEIGHT = 24.5;
var SPEC_SUPPLY_HEIGHT = 30;
var SPEC_PLAY_HEIGHT = 17;
var INPLAY_HEIGHT = 19;
var WINDOW_HEIGHT = 30; // For *cards* in windows
var WINDOW_MARGIN = 2;
var BOON_HEIGHT = 18;

var spaces = function spaces(a, b) {
    var t = a % b;
    return t === 0 ? 0 : b - t;
};

var LayoutElement = function LayoutElement(col, row, width, height) {
    _classCallCheck(this, LayoutElement);

    this.col = col;
    this.row = row;
    this.width = width;
    this.height = height;
};

var LayoutSetup = function LayoutSetup(largeRows, landscapesUp, microRows) {
    _classCallCheck(this, LayoutSetup);

    this.largeRows = largeRows;
    this.landscapesUp = landscapesUp;
    this.microRows = microRows;
};

var KingdomSetup = function KingdomSetup(cardCount, landscapeCount, microCount, baseRows) {
    _classCallCheck(this, KingdomSetup);

    this.cardCount = cardCount;
    this.landscapeCount = landscapeCount;
    this.microCount = microCount;
    this.baseRows = baseRows;
};

var Layout = function () {
    function Layout(layoutSetup, kingdomSetup) {
        _classCallCheck(this, Layout);

        layoutSetup = layoutSetup;
        kingdomSetup = kingdomSetup;

        this.cardElements = [];
        this.landscapeElements = [];
        this.microElements = [];
        var outstandingMicros = kingdomSetup.microCount;
        var col = 0;
        var row = 0;
        var maxCol = 0;
        var maxRow = 0;

        var newElt = function newElt(list, width, height) {
            list.push(new LayoutElement(col, row, width, height));
            maxCol = Math.max(col + width, maxCol);
            maxRow = Math.max(row + height, maxRow);
        };

        var cardColumns = Math.ceil(kingdomSetup.cardCount / layoutSetup.largeRows);
        for (var i = 0; i < kingdomSetup.cardCount; i++) {
            newElt(this.cardElements, 1, 1);
            col++;
            if (col >= cardColumns) {
                col = 0;
                row++;
            }
        }

        if (col <= cardColumns - 1 && col > 0) {
            row += 1 / 30;
            while (outstandingMicros > 0 && col <= cardColumns - 1) {
                newElt(this.microElements, 1, 1 / 5);
                row += 1 / 4;
                if (row >= layoutSetup.largeRows) {
                    col++;
                    row--;
                }
                outstandingMicros--;
            }
        }

        if (layoutSetup.landscapesUp) {
            col = cardColumns;
            row = 0;
            if (kingdomSetup.landscapeCount === 3 && layoutSetup.largeRows === 2) {
                for (var _i = 0; _i < kingdomSetup.landscapeCount; _i++) {
                    newElt(this.landscapeElements, 1, 2 / 3);
                    row += 2 / 3;
                }
            } else {
                for (var _i2 = 0; _i2 < kingdomSetup.landscapeCount; _i2++) {
                    newElt(this.landscapeElements, 3 / 2, 1);
                    row++;
                    if (row >= layoutSetup.largeRows) {
                        col += 3 / 2;
                        row = 0;
                    }
                }

                if (kingdomSetup.landscapeCount > 0) {
                    row += 1 / 30;
                    while (outstandingMicros > 0 && row < layoutSetup.largeRows) {
                        newElt(this.microElements, 1, 1 / 5);
                        row += 1 / 4;
                        outstandingMicros--;
                    }
                }
            }
        } else {
            col = 0;
            row = layoutSetup.largeRows;
            for (var _i3 = 0; _i3 < kingdomSetup.landscapeCount; _i3++) {
                newElt(this.landscapeElements, 1, 2 / 3);
                col++;
                if (col >= cardColumns) {
                    col = 0;
                    row += 2 / 3;
                }
            }
            if (col > 0) {
                var threshold = maxRow;
                row += 4 / 45; // These funky numbers are "space-around" for 2 x 1/5 in 2/3.
                while (outstandingMicros > 0 && col < cardColumns) {
                    newElt(this.microElements, 1, 1 / 5);
                    row += 13 / 45;
                    if (row >= threshold) {
                        col++;
                        row -= 26 / 45;
                    }
                    outstandingMicros--;
                }
            }
        }

        var largeCols = maxCol;
        var largeRows = maxRow;
        col = 0;
        row = largeRows + 1 / 40;
        while (outstandingMicros > 0 && col < largeCols) {
            newElt(this.microElements, 1, 1 / 5);
            row += 1 / 4;
            if (row >= largeRows + layoutSetup.microRows / 4) {
                col++;
                row = largeRows + 1 / 40;
            }
            outstandingMicros--;
        }

        col = maxCol;
        row = 0;
        while (outstandingMicros > 0) {
            newElt(this.microElements, 1, 1 / 5);
            row += 1 / 4;
            if (row >= maxRow) {
                col++;
                row = 0;
            }
            outstandingMicros--;
        }

        this.maxCol = maxCol;
        this.maxRow = maxRow;
    }

    _createClass(Layout, [{
        key: "computeUnitHeight",
        value: function computeUnitHeight(bbox) {
            this.unitHeight = Math.min(bbox.height / this.maxRow, bbox.width / this.maxCol * CardSizes.MINI.ratio);
        }
    }, {
        key: "positionElements",
        value: function positionElements(bbox) {
            var _this = this;

            var absoluteHeight = this.maxRow * this.unitHeight;
            var absoluteWidth = this.maxCol * this.unitHeight / CardSizes.MINI.ratio;

            var left = bbox.x + (bbox.width - absoluteWidth) / 2;
            var top = bbox.y + (bbox.height - absoluteHeight) / 2;
            var unitWidth = this.unitHeight / CardSizes.MINI.ratio;
            var position = function position(element) {
                element.bbox = new Rectangle(left + element.col * unitWidth, top + element.row * _this.unitHeight, element.width * unitWidth, element.height * _this.unitHeight);
            };
            this.cardElements.forEach(position);
            this.landscapeElements.forEach(position);
            this.microElements.forEach(position);
        }
    }, {
        key: "sortElements",
        value: function sortElements() {
            this.cardElements.sort(function (a, b) {
                if (a.row === b.row) return a.col - b.col;else return b.row - a.row;
            });
            this.landscapeElements.sort(function (a, b) {
                if (a.row === b.row) return a.col - b.col;else return b.row - a.row;
            });
        }
    }]);

    return Layout;
}();

var LayoutModel = function () {
    function LayoutModel(game, preferences) {
        var _this2 = this;

        _classCallCheck(this, LayoutModel);

        this.game = game;
        this.preferences = preferences;
        this.layoutSetups = [];
        for (var _largeRows = 2; _largeRows < 6; _largeRows++) {
            var _arr = [true, false];

            for (var _i4 = 0; _i4 < _arr.length; _i4++) {
                var landscapesUp = _arr[_i4];
                for (var microRows = 0; microRows < 6; microRows++) {
                    this.layoutSetups.push(new LayoutSetup(_largeRows, landscapesUp, microRows));
                }
            }
        }
        this.layouts = [];
        this.layout = null;
        this.tabViews = [];
        this.tabsInitialized = false;

        this.resizeListener = function () {
            return _this2.computeBestLayout();
        };
        window.addEventListener("resize", this.resizeListener);
    }

    _createClass(LayoutModel, [{
        key: "unload",
        value: function unload() {
            window.removeEventListener("resize", this.resizeListener);
        }
    }, {
        key: "setKingdomSetup",
        value: function setKingdomSetup(kingdomSetup) {
            this.layouts = this.layoutSetups.map(function (layoutSetup) {
                return new Layout(layoutSetup, kingdomSetup);
            });
            this.baseRows = kingdomSetup.baseRows;
        }
    }, {
        key: "computePileCounts",
        value: function computePileCounts() {
            var sortAndIndex = function sortAndIndex(zones) {
                var rev = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

                zones.sort(function (a, b) {
                    return cardNameSorter(a.pileName, b.pileName);
                });
                if (rev) zones = zones.reverse();
                zones.forEach(function (z, i) {
                    return z.indexOfType = i;
                });
            };

            var zones = this.game.state.zones.filter(function (z) {
                return z.isVisible;
            });
            var kingdomCards = zones.filter(function (z) {
                return z !== null && z instanceof SupplyCardZone;
            });
            sortAndIndex(kingdomCards);
            var landscapes = zones.filter(function (z) {
                return z !== null && z instanceof SingleLandscapeZone;
            });
            sortAndIndex(landscapes);
            var baseVictoryPiles = zones.filter(function (z) {
                return z !== null && z instanceof BasicVictoryZone;
            });
            sortAndIndex(baseVictoryPiles, true);
            var baseTreasurePiles = zones.filter(function (z) {
                return z !== null && z instanceof BasicTreasureZone;
            });
            sortAndIndex(baseTreasurePiles, true);
            var nonSupplies = zones.filter(function (z) {
                return z !== null && z instanceof NonSupplyZone;
            });
            sortAndIndex(nonSupplies, true);
            var baseRows = Math.max(baseVictoryPiles.length, baseTreasurePiles.length);
            this.setKingdomSetup(new KingdomSetup(kingdomCards.length, landscapes.length, nonSupplies.length, baseRows));
        }
    }, {
        key: "computeBestLayout",
        value: function computeBestLayout() {
            var _this3 = this;

            var opponentCount = this.game.state.players.length - 1;
            this.isLowered = this.preferences.getUserPrefValue(UserPrefIds.MOVE_KINGDOM) && !this.game.state.activeTurn.owner.isHero;

            var vw = window.innerWidth / 100;
            var vh = window.innerHeight / 100;

            var frameLeft = Math.min(LEFT_COLUMN_WIDTH * vw, BASE_SUPPLY_HEIGHT / this.baseRows / CardSizes.MINI.ratio * 2 * vh);
            var frameRight = (100 - RIGHT_COLUMN_WIDTH) * vw;
            var frameTop = void 0;
            if (opponentCount > 0) {
                if (this.isSpec) {
                    if (this.isLowered) frameTop = (SPEC_HAND_HEIGHT + 9 + SPEC_PLAY_HEIGHT) * vh;else frameTop = (SPEC_HAND_HEIGHT + 2) * vh;
                } else {
                    if (this.isLowered) frameTop = (OPPONENT_HEIGHT + INPLAY_HEIGHT + 9) * vh;else frameTop = OPPONENT_HEIGHT * vh;
                }
                this.baseOffset = (OPPONENT_HEIGHT + (opponentCount - 1) * OPPONENT_INFO_HEIGHT) * vh;
            } else {
                frameTop = 3.5 * vh;
                this.baseOffset = 0;
            }
            var frameHeight = (this.isSpec ? SPEC_SUPPLY_HEIGHT : MAIN_SUPPLY_HEIGHT) * vh;
            this.kingdomBbox = new Rectangle(frameLeft, frameTop, frameRight - frameLeft, frameHeight);

            this.layout = this.layouts[0];
            this.layouts.forEach(function (layout) {
                layout.computeUnitHeight(_this3.kingdomBbox);
                if (layout.unitHeight > _this3.layout.unitHeight) _this3.layout = layout;
            });
            this.layout.positionElements(this.kingdomBbox);
            this.layout.sortElements();
        }
    }, {
        key: "createTabs",
        value: function createTabs() {
            var _this4 = this;

            this.game.state.zones.filter(function (z) {
                return z.isVisible;
            }).forEach(function (z) {
                if (z instanceof TabbedZone && z.tabViews.length === 0) {
                    _this4.tabViews.push(z.extractTab());
                }
            });
            this.tabsInitialized = true;
        }
    }, {
        key: "isSpec",
        get: function get() {
            return !this.game.state.players.some(function (p) {
                return p.isMe;
            });
        }
    }]);

    return LayoutModel;
}();