"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Question = function (_GameStateChange) {
    _inherits(Question, _GameStateChange);

    function Question(game, index, description) {
        _classCallCheck(this, Question);

        var _this = _possibleConstructorReturn(this, (Question.__proto__ || Object.getPrototypeOf(Question)).call(this));

        _this.game = game;
        _this.questionIndex = index;
        _this.type = getByOrdinal(QuestionTypes, description.type);
        _this.association = description.association;
        _this.story = description.story;
        _this.shortStory = description.shortStory;
        _this.accumulatedAnswers = [];
        _this.associatedCards = [];
        _this.parentQuestion = null;

        _this.max = 0;

        _this.doneButton = null;
        _this.declineButton = null;
        _this.undoButton = null;
        return _this;
    }

    _createClass(Question, [{
        key: "canAccept",
        value: function canAccept(answer) {
            return true;
        }
    }, {
        key: "execute",
        value: function execute(game) {
            var isRoot = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

            if (isRoot) {
                this.setStory();
                this.game.questionModel.newQuestion(this);
            }
        }
    }, {
        key: "setStory",
        value: function setStory() {
            this.game.logModel.ticker = this.shortStory;
        }
    }, {
        key: "clearAnswers",
        value: function clearAnswers() {
            this.accumulatedAnswers = [];
        }
    }, {
        key: "createAnimation",
        value: function createAnimation(game) {
            return new QuestionAnimation(this);
        }
    }, {
        key: "answer",
        value: function answer(_answer) {
            this.accumulatedAnswers = [_answer];
        }
    }, {
        key: "send",
        value: function send() {
            if (this.parentQuestion !== null) {
                if (this.parentQuestion.type === QuestionTypes.COMPLEX_OR) this.parentQuestion.answer(this.parentQuestion.subquestions.indexOf(this));
                this.parentQuestion.send();
            } else {
                this.game.questionModel.answerQuestion({ questionIndex: this.questionIndex[0], list: this.getAnswer() });
            }
        }
    }, {
        key: "getAnswer",
        value: function getAnswer() {
            return this.accumulatedAnswers;
        }
    }, {
        key: "getBundledQuestion",
        value: function getBundledQuestion(type) {
            var _this2 = this;

            if (this.parentQuestion === null || this.parentQuestion.type !== QuestionTypes.COMPLEX_AND) return null;
            var question = this.parentQuestion.subquestions.find(function (q) {
                return q.type === type && q !== _this2;
            });
            return isUndefined(question) ? null : question;
        }
    }, {
        key: "shouldStackCards",
        get: function get() {
            return false;
        }
    }]);

    return Question;
}(GameStateChange);