"use strict";

var SpanishDeclineButtons = {};

SpanishDeclineButtons[DeclineButtonIds.END_ACTION_PHASE] = "Fin Acciones";
SpanishDeclineButtons[DeclineButtonIds.END_BUY_PHASE] = "Fin Compras";
SpanishDeclineButtons[DeclineButtonIds.END_TURN] = "Fin Turno";
SpanishDeclineButtons[DeclineButtonIds.DONE_PLAYING_TREASURES] = "Fin Tesoros";
SpanishDeclineButtons[DeclineButtonIds.DONE_REACTING] = "Continuar";
SpanishDeclineButtons[DeclineButtonIds.MINING_VILLAGE_DONT_TRASH] = "No Eliminar";
SpanishDeclineButtons[DeclineButtonIds.PEARL_DIVER_DONT_TOPDECK] = "No Poner Sobre el Mazo";
SpanishDeclineButtons[DeclineButtonIds.HARBINGER_DONT_TOPDECK] = "No Poner Sobre el Mazo";
SpanishDeclineButtons[DeclineButtonIds.THRONE_ROOM_DONT] = "No Usar Trono";
SpanishDeclineButtons[DeclineButtonIds.SMUGGLERS_GAIN_IMPOSSIBLE_COPY] = "No Ganar";
SpanishDeclineButtons[DeclineButtonIds.EXPLORER_GAIN_SILVER] = "Ganar Plata";
SpanishDeclineButtons[DeclineButtonIds.HERBALIST_DONT_TOPDECK] = "No Poner Sobre el Mazo";
SpanishDeclineButtons[DeclineButtonIds.MINT_DONT_COPY] = "No Copiar";
SpanishDeclineButtons[DeclineButtonIds.HAMLET_DONT_DISCARD] = "No Descartar";
SpanishDeclineButtons[DeclineButtonIds.TOURNAMENT_DONT_REVEAL] = "No Revelar";
SpanishDeclineButtons[DeclineButtonIds.YOUNG_WITCH_DONT_REVEAL_BANE] = "No Revelar";
SpanishDeclineButtons[DeclineButtonIds.IGG_NO_COPPER] = "Sin Cobre";
SpanishDeclineButtons[DeclineButtonIds.COUNTERFEIT_NO_TREASURE] = "No Falsificar";
SpanishDeclineButtons[DeclineButtonIds.CULTIST_DONT_PLAY_ANOTHER] = "No Encadenar Sectario";
SpanishDeclineButtons[DeclineButtonIds.IRONMONGER_KEEP] = "Mantener Arriba";
SpanishDeclineButtons[DeclineButtonIds.PROCESSION_DONT] = "No Procesionar";
SpanishDeclineButtons[DeclineButtonIds.PLAZA_NO_DISCARD] = "No Descartar";
SpanishDeclineButtons[DeclineButtonIds.DONT_OVERPAY] = "No Pagar de Más";
SpanishDeclineButtons[DeclineButtonIds.DONE_SETTING_ASIDE] = "No Apartar Más";
SpanishDeclineButtons[DeclineButtonIds.ARTIFICER_DONT] = "No Descartar";
SpanishDeclineButtons[DeclineButtonIds.ENGINEER_DONT_TRASH] = "No Eliminar";
SpanishDeclineButtons[DeclineButtonIds.ENCAMPMENT_DONT_REVEAL] = "No Revelar";
SpanishDeclineButtons[DeclineButtonIds.SETTLERS_DONT_TAKE_COPPER] = "No Coger Cobre";
SpanishDeclineButtons[DeclineButtonIds.GLADIATOR_DONT_REVEAL] = "No Revelar";
SpanishDeclineButtons[DeclineButtonIds.CROWN_DONT] = "No Usar Corona";
SpanishDeclineButtons[DeclineButtonIds.LEGIONARY_DONT_REVEAL] = "No Revelar";
SpanishDeclineButtons[DeclineButtonIds.SHUFFLE_ALL] = "Barajar Todo";
SpanishDeclineButtons[DeclineButtonIds.PASS] = "Pasar";
SpanishDeclineButtons[DeclineButtonIds.DONT_DISCARD] = "No Descartar";
SpanishDeclineButtons[DeclineButtonIds.DONT_TRASH] = "No Eliminar";
SpanishDeclineButtons[DeclineButtonIds.VAULT_NO_DISCARD] = "No Descartar";
SpanishDeclineButtons[DeclineButtonIds.DONT_BUY] = "No Comprar";
SpanishDeclineButtons[DeclineButtonIds.DONT_GAIN] = "No Ganar";
SpanishDeclineButtons[DeclineButtonIds.DISCARD_ALL] = "Descartar Todo";
SpanishDeclineButtons[DeclineButtonIds.TORTURER_FAIL_CURSE] = "Ganar Maldición";
SpanishDeclineButtons[DeclineButtonIds.TORTURER_GAIN_CURSE] = "Ganar Maldición";
SpanishDeclineButtons[DeclineButtonIds.BUSTLING_VILLAGE_NO_SETTLERS] = "No Coger Colonos";
SpanishDeclineButtons[DeclineButtonIds.PRINCE_NO] = "No Apartar";
SpanishDeclineButtons[DeclineButtonIds.SAUNA_DONT_CHAIN] = "No encadenar";
SpanishDeclineButtons[DeclineButtonIds.AVANTO_DONT_CHAIN] = "No encadenar";
SpanishDeclineButtons[DeclineButtonIds.CONCLAVE_DONT] = "No jugar";
SpanishDeclineButtons[DeclineButtonIds.IMP_DONT] = "No jugar";
SpanishDeclineButtons[DeclineButtonIds.NECROMANCER_DONT] = "No jugar";
SpanishDeclineButtons[DeclineButtonIds.NO_THANKS] = "No gracias";
SpanishDeclineButtons[DeclineButtonIds.DONT_EXILE] = "No Exiliar";
SpanishDeclineButtons[DeclineButtonIds.KINGS_COURT_DONT] = "No Usar Corte";
SpanishDeclineButtons[DeclineButtonIds.MASTERMIND_DONT] = "No Usar Genio";
SpanishDeclineButtons[DeclineButtonIds.DELAY_DONT] = "No Usar Demora";
SpanishDeclineButtons[DeclineButtonIds.TOIL_DONT] = "No Usar Labor";
SpanishDeclineButtons[DeclineButtonIds.MARCH_DONT] = "No Usar Marcha";
SpanishDeclineButtons[DeclineButtonIds.DONT_TOPDECK] = "No Poner Sobre el Mazo";
SpanishDeclineButtons[DeclineButtonIds.RANDOM_ORDER] = "Orden Aleatorio";
SpanishDeclineButtons[DeclineButtonIds.HUNTING_LODGE_KEEP] = "Mantener";
SpanishDeclineButtons[DeclineButtonIds.TIARA_NO_TREASURE] = "Don't play"; // <--TRANSLATE
SpanishDeclineButtons[DeclineButtonIds.SWAP_DONT] = "Don't Swap"; // <--TRANSLATE
SpanishDeclineButtons[DeclineButtonIds.DONT_PLAY] = "Don't Play"; // <--TRANSLATE
SpanishDeclineButtons[DeclineButtonIds.DONT_REVEAL] = "Don't Reveal"; // <--TRANSLATE
SpanishDeclineButtons[DeclineButtonIds.DONT_ROTATE] = "Don't Rotate"; // <--TRANSLATE
SpanishDeclineButtons[DeclineButtonIds.DONT] = "Don't"; // <--TRANSLATE
SpanishDeclineButtons[DeclineButtonIds.SPEND_FAVOR] = "Spend Favor"; // <--TRANSLATE
SpanishDeclineButtons[DeclineButtonIds.CONTINUE] = "Continue"; // TRANSLATE (new)
SpanishDeclineButtons[DeclineButtonIds.ORB_DONT] = "+1 Покупка, +3 Монеты";
SpanishDeclineButtons[DeclineButtonIds.DONT_PUZZLE_BOX] = "Не откладывать в сторону";
SpanishDeclineButtons[DeclineButtonIds.DONT_INSPIRING] = "Не играть";
SpanishDeclineButtons[DeclineButtonIds.DONT_JOUST] = "Don't Set Aside";
SpanishDeclineButtons[DeclineButtonIds.SNAKE_WITCH_DONT_RETURN] = "Don't return"; // <- translate

SpanishDeclineButtons[DoneButtonIds.DONE_TRASHING] = "Confirmar eliminadas";
SpanishDeclineButtons[DoneButtonIds.DONE_DISCARDING] = "Confirmar descartes";
SpanishDeclineButtons[DoneButtonIds.DONE_ORDERING] = "Hecho";
SpanishDeclineButtons[DoneButtonIds.DONE_RETURNING] = "Hecho";
SpanishDeclineButtons[DoneButtonIds.DONE_SETTING_ASIDE] = "Hecho";
SpanishDeclineButtons[DoneButtonIds.DONE_SELECTING] = "Hecho";
SpanishDeclineButtons[DoneButtonIds.DONE_CHOOSING] = "Hecho";
SpanishDeclineButtons[DoneButtonIds.TRASH] = "Eliminar";
SpanishDeclineButtons[DoneButtonIds.DISCARD] = "Descartar";
SpanishDeclineButtons[DoneButtonIds.PASS] = "Pasar";
SpanishDeclineButtons[DoneButtonIds.TOPDECK] = "Poner Sobre el Mazo";
SpanishDeclineButtons[DoneButtonIds.BOTTOMDECK] = "Bottomdeck"; //<--TRANSLATE
SpanishDeclineButtons[DoneButtonIds.EXILE] = "Exiliar";
SpanishDeclineButtons[DoneButtonIds.OVERPAY] = "Pagar de Más";

var SpanishGameButtons = {};

SpanishGameButtons[GameButtons.AUTOPLAY_TREASURES] = "Jugar Tesoros";
SpanishGameButtons[GameButtons.USE_VILLAGER] = "Usar Aldeano";
SpanishGameButtons[GameButtons.USE_FAVOR_TOPDECK] = "Use Favor to topdeck"; // TRANSLATE (new)
SpanishGameButtons[GameButtons.USE_FAVOR_DISCARD] = "Use Favor to discard"; // TRANSLATE (new)
SpanishGameButtons[GameButtons.COFFERS] = "Use Coffers"; // TRANSLATE (new)

var SpanishDelayedQuestionButtons = {};

SpanishDelayedQuestionButtons[CardNames.ARTIFICER] = "Usar Vidriera";
SpanishDelayedQuestionButtons[CardNames.BUSTLING_VILLAGE] = "Coger Colonos";
SpanishDelayedQuestionButtons[CardNames.CULTIST] = "Jugar Sectario";
SpanishDelayedQuestionButtons[CardNames.ENGINEER] = "Eliminar Ingeniera";
SpanishDelayedQuestionButtons[CardNames.HAMLET] = "Descartar";
SpanishDelayedQuestionButtons[CardNames.HARBINGER] = "Poner Sobre el Mazo";
SpanishDelayedQuestionButtons[CardNames.ILL_GOTTEN_GAINS] = "Ganar Copper";
SpanishDelayedQuestionButtons[CardNames.IRONMONGER] = "Descartar";
SpanishDelayedQuestionButtons[CardNames.MINING_VILLAGE] = "Eliminar";
SpanishDelayedQuestionButtons[CardNames.PEARL_DIVER] = "Poner Sobre el Mazo";
SpanishDelayedQuestionButtons[CardNames.PLAZA] = "Descartar";
SpanishDelayedQuestionButtons[CardNames.SETTLERS] = "Coger Cobre";
SpanishDelayedQuestionButtons[CardNames.HUNTING_LODGE] = "Descartar y Robar 5";
SpanishDelayedQuestionButtons[CardNames.SNAKE_WITCH] = "Return & Curse";
SpanishDelayedQuestionButtons[CardNames.RUSTIC_VILLAGE] = "Discard 2 to draw 1";
SpanishDelayedQuestionButtons[CardNames.GOLD_MINE] = "Gold & 4 Debt";

var SpanishCleanupActions = {};

SpanishCleanupActions[CleanupActions.TOPDECK] = new CleanupButtonText("Poner Sobre el Mazo", "Descartar");
SpanishCleanupActions[CleanupActions.EXCHANGE] = new CleanupButtonText("Cambiar", "Mantener");

// Missing lines as of 09.01.2023 18:44:56
SpanishDeclineButtons[DeclineButtonIds.ORB_DONT] = "+1 Buy, +3 Coins";
SpanishDeclineButtons[DeclineButtonIds.DONT_PUZZLE_BOX] = "Don't Set Aside";
SpanishDeclineButtons[DeclineButtonIds.DONT_INSPIRING] = "Don't Play";