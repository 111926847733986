"use strict";

var EsperantoDeclineButtons = {};

EsperantoDeclineButtons[DeclineButtonIds.END_ACTION_PHASE] = "Finu Agfazon";
EsperantoDeclineButtons[DeclineButtonIds.END_BUY_PHASE] = "Finu Aĉetfazon";
EsperantoDeclineButtons[DeclineButtonIds.END_TURN] = "Finu Vicon";
EsperantoDeclineButtons[DeclineButtonIds.DONE_PLAYING_TREASURES] = "Ne Plu Trezorojn";
EsperantoDeclineButtons[DeclineButtonIds.DONE_REACTING] = "Daŭru";
EsperantoDeclineButtons[DeclineButtonIds.MINING_VILLAGE_DONT_TRASH] = "Ne Rubigu";
EsperantoDeclineButtons[DeclineButtonIds.PEARL_DIVER_DONT_TOPDECK] = "Ne Surmetu";
EsperantoDeclineButtons[DeclineButtonIds.HARBINGER_DONT_TOPDECK] = "Ne Surmetu";
EsperantoDeclineButtons[DeclineButtonIds.THRONE_ROOM_DONT] = "Ne Duobligu";
EsperantoDeclineButtons[DeclineButtonIds.SMUGGLERS_GAIN_IMPOSSIBLE_COPY] = "Ne Gajnu";
EsperantoDeclineButtons[DeclineButtonIds.EXPLORER_GAIN_SILVER] = "Gajnu Arĝenton";
EsperantoDeclineButtons[DeclineButtonIds.HERBALIST_DONT_TOPDECK] = "Ne Surmetu";
EsperantoDeclineButtons[DeclineButtonIds.MINT_DONT_COPY] = "Ne Kopiu";
EsperantoDeclineButtons[DeclineButtonIds.HAMLET_DONT_DISCARD] = "Ne Forĵetu";
EsperantoDeclineButtons[DeclineButtonIds.TOURNAMENT_DONT_REVEAL] = "Ne Malkaŝu";
EsperantoDeclineButtons[DeclineButtonIds.YOUNG_WITCH_DONT_REVEAL_BANE] = "Ne Malkaŝu";
EsperantoDeclineButtons[DeclineButtonIds.IGG_NO_COPPER] = "Nul Kuprojn";
EsperantoDeclineButtons[DeclineButtonIds.COUNTERFEIT_NO_TREASURE] = "Ne Falsu";
EsperantoDeclineButtons[DeclineButtonIds.CULTIST_DONT_PLAY_ANOTHER] = "Ne Ludu Sektanon";
EsperantoDeclineButtons[DeclineButtonIds.IRONMONGER_KEEP] = "Gardu Supre";
EsperantoDeclineButtons[DeclineButtonIds.PROCESSION_DONT] = "Ne Procesiu";
EsperantoDeclineButtons[DeclineButtonIds.PLAZA_NO_DISCARD] = "Ne Forĵetu";
EsperantoDeclineButtons[DeclineButtonIds.DONT_OVERPAY] = "Ne Tropagu";
EsperantoDeclineButtons[DeclineButtonIds.DONE_SETTING_ASIDE] = "Finas Flankenmeti";
EsperantoDeclineButtons[DeclineButtonIds.ARTIFICER_DONT] = "Ne Forĵetu";
EsperantoDeclineButtons[DeclineButtonIds.ENGINEER_DONT_TRASH] = "Ne Rubigu";
EsperantoDeclineButtons[DeclineButtonIds.ENCAMPMENT_DONT_REVEAL] = "Ne Malkaŝu";
EsperantoDeclineButtons[DeclineButtonIds.SETTLERS_DONT_TAKE_COPPER] = "Ne Prenu Kupron";
EsperantoDeclineButtons[DeclineButtonIds.GLADIATOR_DONT_REVEAL] = "Ne Malkaŝu";
EsperantoDeclineButtons[DeclineButtonIds.CROWN_DONT] = "Ne Kronu";
EsperantoDeclineButtons[DeclineButtonIds.LEGIONARY_DONT_REVEAL] = "Ne Malkaŝu";
EsperantoDeclineButtons[DeclineButtonIds.SHUFFLE_ALL] = "Miksu Ĉion";
EsperantoDeclineButtons[DeclineButtonIds.PASS] = "Rifuzu";
EsperantoDeclineButtons[DeclineButtonIds.DONT_DISCARD] = "Ne Forĵetu";
EsperantoDeclineButtons[DeclineButtonIds.DONT_TRASH] = "Ne Rubigu";
EsperantoDeclineButtons[DeclineButtonIds.VAULT_NO_DISCARD] = "Ne Forĵetu";
EsperantoDeclineButtons[DeclineButtonIds.DONT_BUY] = "Ne Aĉetu";
EsperantoDeclineButtons[DeclineButtonIds.DONT_GAIN] = "Ne Gajnu";
EsperantoDeclineButtons[DeclineButtonIds.DISCARD_ALL] = "Forĵetu Ĉion";
EsperantoDeclineButtons[DeclineButtonIds.TORTURER_FAIL_CURSE] = "Gajnu Malbenon";
EsperantoDeclineButtons[DeclineButtonIds.TORTURER_GAIN_CURSE] = "Gajnu Malbenon";
EsperantoDeclineButtons[DeclineButtonIds.BUSTLING_VILLAGE_NO_SETTLERS] = "Ne Prenu Setlantojn";
EsperantoDeclineButtons[DeclineButtonIds.PRINCE_NO] = "Ne Flankenmetu";
EsperantoDeclineButtons[DeclineButtonIds.SAUNA_DONT_CHAIN] = "Ne Ludu";
EsperantoDeclineButtons[DeclineButtonIds.AVANTO_DONT_CHAIN] = "Ne Ludu";
EsperantoDeclineButtons[DeclineButtonIds.CONCLAVE_DONT] = "Ne Ludu";
EsperantoDeclineButtons[DeclineButtonIds.IMP_DONT] = "Ne Ludu";
EsperantoDeclineButtons[DeclineButtonIds.NECROMANCER_DONT] = "Ne Ludu";
EsperantoDeclineButtons[DeclineButtonIds.NO_THANKS] = "Ne, Dankon";
EsperantoDeclineButtons[DeclineButtonIds.DONT_EXILE] = "Ne Ekzilu";
EsperantoDeclineButtons[DeclineButtonIds.KINGS_COURT_DONT] = "Ne Reĝigu";
EsperantoDeclineButtons[DeclineButtonIds.MASTERMIND_DONT] = "Nul Malica Geniulo";
EsperantoDeclineButtons[DeclineButtonIds.DELAY_DONT] = "Ne Prokrastu";
EsperantoDeclineButtons[DeclineButtonIds.TOIL_DONT] = "Ne Laboregu";
EsperantoDeclineButtons[DeclineButtonIds.MARCH_DONT] = "Ne Marŝu";
EsperantoDeclineButtons[DeclineButtonIds.DONT_TOPDECK] = "Ne Surmetu";
EsperantoDeclineButtons[DeclineButtonIds.RANDOM_ORDER] = "Hazarda Ordo";
EsperantoDeclineButtons[DeclineButtonIds.HUNTING_LODGE_KEEP] = "Gardu";
EsperantoDeclineButtons[DeclineButtonIds.TIARA_NO_TREASURE] = "Ne ludu";
EsperantoDeclineButtons[DeclineButtonIds.SWAP_DONT] = "Ne interŝanĝu";
EsperantoDeclineButtons[DeclineButtonIds.DONT_PLAY] = "Ne ludu";
EsperantoDeclineButtons[DeclineButtonIds.DONT_REVEAL] = "Ne malkaŝu";
EsperantoDeclineButtons[DeclineButtonIds.DONT_ROTATE] = "Ne rotaciu";
EsperantoDeclineButtons[DeclineButtonIds.DONT] = "Ne faru";
EsperantoDeclineButtons[DeclineButtonIds.SPEND_FAVOR] = "Elspezu Komplezon";
EsperantoDeclineButtons[DeclineButtonIds.CONTINUE] = "Daŭru";
EsperantoDeclineButtons[DeclineButtonIds.DONT_JOUST] = "Don't Set Aside";
EsperantoDeclineButtons[DeclineButtonIds.SNAKE_WITCH_DONT_RETURN] = "Don't return"; // <- translatr

EsperantoDeclineButtons[DoneButtonIds.DONE_TRASHING] = "Jesigu Rubigi";
EsperantoDeclineButtons[DoneButtonIds.DONE_DISCARDING] = "Jesigu Forĵeti";
EsperantoDeclineButtons[DoneButtonIds.DONE_ORDERING] = "Finas Ordigi";
EsperantoDeclineButtons[DoneButtonIds.DONE_RETURNING] = "Finas Redoni";
EsperantoDeclineButtons[DoneButtonIds.DONE_SETTING_ASIDE] = "Finas Flankenmeti";
EsperantoDeclineButtons[DoneButtonIds.DONE_SELECTING] = "Finas Elekti";
EsperantoDeclineButtons[DoneButtonIds.DONE_CHOOSING] = "Finas Elekti";
EsperantoDeclineButtons[DoneButtonIds.TRASH] = "Rubigu";
EsperantoDeclineButtons[DoneButtonIds.DISCARD] = "Forĵetu";
EsperantoDeclineButtons[DoneButtonIds.PASS] = "Rifuzu";
EsperantoDeclineButtons[DoneButtonIds.TOPDECK] = "Surmetu";
EsperantoDeclineButtons[DoneButtonIds.BOTTOMDECK] = "Submetu";
EsperantoDeclineButtons[DoneButtonIds.EXILE] = "Ekzilu";
EsperantoDeclineButtons[DoneButtonIds.OVERPAY] = "Tropagu";

var EsperantoGameButtons = {};

EsperantoGameButtons[GameButtons.AUTOPLAY_TREASURES] = "Aŭtoludu Trezorkartojn";
EsperantoGameButtons[GameButtons.USE_VILLAGER] = "Uzu Vilaĝanon";
EsperantoGameButtons[GameButtons.USE_FAVOR_TOPDECK] = "Uzu Komplezon por surmeti";
EsperantoGameButtons[GameButtons.USE_FAVOR_DISCARD] = "Uzu Komplezon por forĵeti";
EsperantoGameButtons[GameButtons.COFFERS] = "Uzu Monrezervojn";

var EsperantoDelayedQuestionButtons = {};

EsperantoDelayedQuestionButtons[CardNames.ARTIFICER] = "Uzu Farinton";
EsperantoDelayedQuestionButtons[CardNames.BUSTLING_VILLAGE] = "Prenu Setlantojn";
EsperantoDelayedQuestionButtons[CardNames.CULTIST] = "Ludu Sektanon";
EsperantoDelayedQuestionButtons[CardNames.ENGINEER] = "Rubigu Inĝenieron";
EsperantoDelayedQuestionButtons[CardNames.HAMLET] = "Forĵetu";
EsperantoDelayedQuestionButtons[CardNames.HARBINGER] = "Surmetu";
EsperantoDelayedQuestionButtons[CardNames.ILL_GOTTEN_GAINS] = "Gajnu Kupron";
EsperantoDelayedQuestionButtons[CardNames.IRONMONGER] = "Forĵetu";
EsperantoDelayedQuestionButtons[CardNames.MINING_VILLAGE] = "Rubigu";
EsperantoDelayedQuestionButtons[CardNames.PEARL_DIVER] = "Surmetu";
EsperantoDelayedQuestionButtons[CardNames.PLAZA] = "Forĵetu";
EsperantoDelayedQuestionButtons[CardNames.SETTLERS] = "Prenu Kupron";
EsperantoDelayedQuestionButtons[CardNames.HUNTING_LODGE] = "Forĵetu & Tiru 5";
EsperantoDelayedQuestionButtons[CardNames.SNAKE_WITCH] = "Return & Curse";
EsperantoDelayedQuestionButtons[CardNames.RUSTIC_VILLAGE] = "Discard 2 to draw 1";
EsperantoDelayedQuestionButtons[CardNames.GOLD_MINE] = "Gold & 4 Debt";

var EsperantoCleanupActions = {};

EsperantoCleanupActions[CleanupActions.TOPDECK] = new CleanupButtonText("Surmetu", "Forĵetu");
EsperantoCleanupActions[CleanupActions.EXCHANGE] = new CleanupButtonText("Interŝanĝu", "Gardu");

// Missing lines as of 09.01.2023 19:11:39
EsperantoDeclineButtons[DeclineButtonIds.ORB_DONT] = "+1 Buy, +3 Coins";
EsperantoDeclineButtons[DeclineButtonIds.DONT_PUZZLE_BOX] = "Don't Set Aside";
EsperantoDeclineButtons[DeclineButtonIds.DONT_INSPIRING] = "Don't Play";