"use strict";

webclient.component('tableEmptySlot', {
    bindings: {
        index: "<",
        originalPlayer: "<"
    },
    controller: ['tableService', '$element', 'meta', '$scope', function (tableService, $element, meta, $scope) {
        var self = this;
        self.label = LANGUAGE.getLobbyTexts['EMPTY_SLOT'];
        self.isHost = function () {
            return tableService.heroIsHost();
        };
        self.isSpec = function () {
            return !tableService.heroIsPlayer();
        };
        self.originalPlayerLabel = function () {
            return getPhrase(Phrases.ORIGINAL_PLAYER) + ": " + self.originalPlayer.name;
        };

        self.addBot = function () {
            tableService.addBot();
            tableService.changeRule(new TableRule(TableRuleIds.RATED_GAME, false));
            self.hideTooltip();
        };
        self.removeSlot = function () {
            var n = tableService.getRuleValue(TableRuleIds.MAX_PLAYERS);
            var rule = new TableRule(TableRuleIds.MAX_PLAYERS, n - 1);
            tableService.changeRule(rule);
            rule = new TableRule(TableRuleIds.MIN_PLAYERS, n - 1);
            tableService.changeRule(rule);
            self.hideTooltip();
        };
        self.play = function () {
            tableService.sitDown();
            self.hideTooltip();
        };

        var setTooltip = function setTooltip(iconClass) {
            var boundingBox = $element[0].querySelector(iconClass).getBoundingClientRect();
            var iconOffset = $element[0].querySelector(iconClass).offsetWidth / 2;
            var tooltipOffset = document.getElementById("tooltip").offsetWidth / 2;
            document.getElementById("tooltip").style.top = boundingBox.top - boundingBox.height / 2 + "px";
            document.getElementById("tooltip").style.left = boundingBox.left + iconOffset - tooltipOffset + "px";
        };
        self.tooltip = function (type) {
            document.getElementById("tooltip").className = "table-tooltip";

            switch (type) {
                case "bot":
                    document.getElementById("tooltip").innerHTML = LANGUAGE.getPhrase[Phrases.ADD_BOT];
                    setTooltip(".table-add-bot-icon");
                    break;
                case "slot":
                    document.getElementById("tooltip").innerHTML = LANGUAGE.getLobbyTexts['REMOVE_SLOT'];
                    setTooltip(".table-remove-slot-icon");
                    break;
                case "play":
                    document.getElementById("tooltip").innerHTML = LANGUAGE.getPhrase[Phrases.PLAY];
                    setTooltip(".table-play-icon");
                    break;
            }
        };
        self.hideTooltip = function () {
            document.getElementById("tooltip").className = "table-tooltip hidden";
        };
    }],
    template: "\n            <div class=\"table-person-line empty\">\n                <div class=\"table-empty-slot-label\">\n                    <div class=\"table-person-name-inner\" ng-class=\"{expanded: !$ctrl.originalPlayer}\">\n                        {{$ctrl.label}}\n                    </div>\n                    <div class=\"table-person-original-name\" ng-if=\"$ctrl.originalPlayer\">\n                        {{$ctrl.originalPlayerLabel()}}\n                    </div>\n                </div>\n                <div\n                    class=\"table-add-bot-icon\"\n                    ng-if=\"$ctrl.isHost()\"\n                    ng-click=\"$ctrl.addBot()\"\n                    ng-mouseover=\"$ctrl.tooltip('bot')\"\n                    ng-mouseleave=\"$ctrl.hideTooltip()\">\n                </div>\n                <div \n                    class=\"table-play-icon\"\n                    ng-if=\"$ctrl.isSpec()\"\n                    ng-click=\"$ctrl.play()\"\n                    ng-mouseover=\"$ctrl.tooltip('play')\"\n                    ng-mouseleave=\"$ctrl.hideTooltip()\">\n                </div>\n                <div\n                    class=\"table-remove-slot-icon\"\n                    ng-if=\"$ctrl.isHost()\"\n                    ng-click=\"$ctrl.removeSlot()\"\n                    ng-mouseover=\"$ctrl.tooltip('slot')\"\n                    ng-mouseleave=\"$ctrl.hideTooltip()\">\n                </div>\n            </div>"
});