"use strict";
// function autoSizeText() {
//     let elements = $('.auto-resize');
//     console.log(elements);
//     if (elements.length === 0) return;
//     elements.forEach(e => {
//         while (e.scrollHeight > e.offsetHeight) resizeText(e);
//     });
// }
//
// function resizeText(element) {
//     let newSize = (parseInt($(element).css('font-size').slice(0, -2)) - 1) + 'px';
//     $(element).css('font-size', newSize);
// }

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FontMetric = function () {
    function FontMetric() {
        _classCallCheck(this, FontMetric);

        this.uuid = generateUUID();
    }

    _createClass(FontMetric, [{
        key: "toString",
        value: function toString() {
            return this.uuid;
        }
    }]);

    return FontMetric;
}();

var FontMetrics = {
    PX: new FontMetric(),
    VW: new FontMetric(),
    VH: new FontMetric()
};

var ResizableTextElement = function () {
    function ResizableTextElement(domElement, marginInPixels, text, font, fontSize) {
        _classCallCheck(this, ResizableTextElement);

        this.domElement = domElement;
        this.marginInPixels = marginInPixels;
        this.text = text;
        this.font = font;
        this.fontSize = fontSize;
    }

    _createClass(ResizableTextElement, [{
        key: "resetFontSize",
        value: function resetFontSize() {
            this.domElement.style.fontSize = this.fontSize;
        }
    }]);

    return ResizableTextElement;
}();

var TextResizer = function () {
    function TextResizer() {
        _classCallCheck(this, TextResizer);

        this.canvas = document.createElement("canvas");
        this.elements = [];
    }

    _createClass(TextResizer, [{
        key: "registerElement",
        value: function registerElement(e) {
            this.elements.push(e);
        }
    }, {
        key: "deregisterElement",
        value: function deregisterElement(e) {
            removeElement(this.elements, e);
        }
    }, {
        key: "resizeTexts",
        value: function resizeTexts() {
            var _this = this;

            this.elements.forEach(function (e) {
                return _this.resizeText(e);
            });
        }
    }, {
        key: "resizeText",
        value: function resizeText(element) {
            element.resetFontSize();
            while (this.exceedsMaxWidth(element)) {
                TextResizer.shrinkText(element);
            }
        }
    }, {
        key: "exceedsMaxWidth",
        value: function exceedsMaxWidth(element) {
            var fontString = element.domElement.style.fontSize + ' ' + element.font;
            var textWidth = this.getTextWidth(element.text, fontString);
            return textWidth > element.domElement.clientWidth - element.marginInPixels;
        }
    }, {
        key: "getFittingFontSize",
        value: function getFittingFontSize(element) {}
    }, {
        key: "getTextWidth",
        value: function getTextWidth(text, font) {
            var context = this.canvas.getContext("2d");
            context.font = font;
            var metrics = context.measureText(text);
            return metrics.width;
        }
    }], [{
        key: "shrinkText",
        value: function shrinkText(element) {}
    }]);

    return TextResizer;
}();

function autoResizeText() {
    var elements = document.getElementsByClassName('auto-resize');
    timeLog('Element size: ', elements.length);
    timeLog('Elements: ', elements);
    return elements;
}

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see http://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
function getTextWidth(text, font) {
    // re-use canvas object for better performance
    var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = font;
    var metrics = context.measureText(text);
    return metrics.width;
}

// console.log(getTextWidth("hello there!", "bold 12vw arial"));  // close to 86