"use strict";

var CzechExpansionNames = {};

CzechExpansionNames[Expansions.BASE] = 'Základ';
CzechExpansionNames[Expansions.INTRIGUE] = 'Intriky';
CzechExpansionNames[Expansions.SEASIDE] = 'Pobřeží';
CzechExpansionNames[Expansions.ALCHEMY] = 'Alchemie';
CzechExpansionNames[Expansions.PROSPERITY] = 'Prosperita';
CzechExpansionNames[Expansions.CORNUCOPIA_GUILDS] = 'Roh hojnosti & Řemeslný Cech';
CzechExpansionNames[Expansions.HINTERLANDS] = 'Zázamí';
CzechExpansionNames[Expansions.DARK_AGES] = 'Temné časy';
CzechExpansionNames[Expansions.ADVENTURES] = 'Dobrodružství';
CzechExpansionNames[Expansions.EMPIRES] = 'Říše';
CzechExpansionNames[Expansions.PROMOS] = 'Promo';
CzechExpansionNames[Expansions.NOCTURNE] = 'Noční krajina';
CzechExpansionNames[Expansions.RENAISSANCE] = 'Renesance';
CzechExpansionNames[Expansions.MENAGERIE] = 'Zvěřinec';