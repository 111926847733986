"use strict";

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var LogView = function () {
    function LogView(game, logModel) {
        _classCallCheck(this, LogView);

        this.game = game;
        this.logModel = logModel;
        logModel.logView = this;
        this.privateShowing = true;

        this.rendered = false;
        this.rootNode = document.createElement("div");
        this.rootNode.classList.add("game-log");
        this.scrollContainer = document.createElement("div");
        this.scrollContainer.classList.add("log-scroll-container");
        this.rootNode.appendChild(this.scrollContainer);
        this.forceScrollToBottom();

        this.shouldShowUndos = false;
        this.undoNodes = [];
        this.premoveNodes = [];
    }

    _createClass(LogView, [{
        key: "updateUndoVisibility",
        value: function updateUndoVisibility() {
            var _this = this;

            this.undoNodes.forEach(function (undoNode, index) {
                undoNode.style.display = _this.showingUndos || _this.game.metaGameModel.undoIndex === undoNode.associatedEntry.decisionIndex ? "initial" : "none";
                undoNode.style.zIndex = _this.undoNodes.length - index;
            });
        }
    }, {
        key: "render",
        value: function render(node) {
            var _this2 = this;

            if (!this.rendered) {
                this.parentNode = node;
                node.appendChild(this.rootNode);
                this.forceScrollToBottom();
                this.game.optionsModel.panelRendered();
                this.rendered = true;
            }

            var THRESHOLD = 32;
            var mouseMoveHandler = function mouseMoveHandler(offsetX, offsetY) {
                if (_this2.cursorLock) return;
                if (offsetY > _this2.rootNode.clientHeight - THRESHOLD) _this2.rootNode.style.cursor = "row-resize";else _this2.rootNode.style.cursor = "initial";
            };
            var mouseDownHandler = function mouseDownHandler(offsetX, offsetY) {
                if (offsetY > _this2.rootNode.clientHeight - THRESHOLD) {
                    _this2.rootNode.style.cursor = "row-resize";
                    _this2.cursorLock = true;
                    var moveListener = function moveListener(clientX, clientY) {
                        var shiftedY = clientY;
                        var weight = shiftedY / (0.96 * (_this2.game.specView.showing ? 0.8 : 1) * window.innerHeight);
                        weight = Math.min(1, Math.max(0, weight));
                        _this2.game.optionsModel.logWeight = 100 * weight;
                        _this2.game.optionsModel.chatWeight = 100 * (1 - weight);
                        _this2.game.optionsModel.updatePanelHeights();
                    };

                    var _mouseMoveHandler = function _mouseMoveHandler(e) {
                        return moveListener(e.clientX, e.clientY);
                    };
                    var touchMoveHandler = function touchMoveHandler(e) {
                        return moveListener(e.touches[0].clientX, e.touches[0].clientY);
                    };

                    var dropListener = function dropListener() {
                        document.removeEventListener("mousemove", _mouseMoveHandler);
                        document.removeEventListener("touchmove", touchMoveHandler);
                        document.removeEventListener("mouseup", dropListener);
                        document.removeEventListener("touchend", dropListener);
                        document.removeEventListener("touchcancel", dropListener);
                        _this2.game.optionsModel.normalize();
                        _this2.cursorLock = false;
                        _this2.rootNode.style.cursor = "initial";
                    };
                    document.addEventListener("mousemove", _mouseMoveHandler);
                    document.addEventListener("touchmove", touchMoveHandler);
                    document.addEventListener("mouseup", dropListener);
                    document.addEventListener("touchend", dropListener);
                    document.addEventListener("touchcancel", dropListener);
                } else _this2.rootNode.style.cursor = "initial";
            };

            this.rootNode.onmousemove = function (e) {
                return mouseMoveHandler(e.clientX - _this2.rootNode.getBoundingClientRect().x, e.clientY - _this2.rootNode.getBoundingClientRect().y);
            };
            this.rootNode.onmousedown = function (e) {
                return mouseDownHandler(e.clientX - _this2.rootNode.getBoundingClientRect().x, e.clientY - _this2.rootNode.getBoundingClientRect().y);
            };
            this.rootNode.ontouchmove = function (e) {
                return mouseMoveHandler(e.touches[0].clientX - _this2.rootNode.getBoundingClientRect().x, e.touches[0].clientY - _this2.rootNode.getBoundingClientRect().y);
            };
            this.rootNode.ontouchstart = function (e) {
                return mouseDownHandler(e.touches[0].clientX - _this2.rootNode.getBoundingClientRect().x, e.touches[0].clientY - _this2.rootNode.getBoundingClientRect().y);
            };
        }
    }, {
        key: "insertNode",
        value: function insertNode(newNode) {
            if (this.premoveNodes.length === 0) this.scrollContainer.appendChild(newNode);else {
                this.scrollContainer.insertBefore(newNode, this.premoveNodes[0]);
            }
        }
    }, {
        key: "addDecisionEntry",
        value: function addDecisionEntry(entry) {
            var _this3 = this;

            var rootNode = document.createElement("div");
            var undoNode = document.createElement("div");
            undoNode.classList.add("undo-line");
            undoNode.style.display = this.showingUndos ? "initial" : "none";
            var innerNode = document.createElement("div");
            innerNode.classList.add("undo-line-inner");
            rootNode.appendChild(undoNode);
            undoNode.appendChild(innerNode);
            undoNode.onclick = function () {
                _this3.game.logModel.undoTo(entry);
            };
            undoNode.associatedEntry = entry;

            entry.renderedLines.push(undoNode);
            this.insertNode(rootNode);
            this.undoNodes.push(undoNode);
        }
    }, {
        key: "parseLogEntry",
        value: function parseLogEntry(entry) {
            var _this4 = this;

            this.parser = new LogLineParser(this.game.state, this.game.state.players, false);
            var currentLine = void 0,
                currentLineIsEmpty = void 0;

            var distanceFromBottom = this.rootNode.scrollHeight - this.rootNode.scrollTop - this.rootNode.clientHeight;
            var initializeLine = function initializeLine() {
                currentLine = document.createElement("div");
                currentLine.classList.add("log-line");
                if (entry.name.lineClass) currentLine.classList.add(entry.name.lineClass);
                var normalizer = function normalizer(x) {
                    return x / (x + 10) * 50;
                };
                var padding = normalizer(entry.depth);
                currentLine.style.paddingLeft = padding + "%";
                currentLine.style.width = 100 - padding + "%";
                currentLineIsEmpty = true;
            };
            initializeLine();

            var blocks = this.parser.parseLineWithArguments(LANGUAGE.getLogEntry[entry.name], entry.logArguments);
            blocks.forEach(function (block) {
                var blockNode = document.createElement("div");
                currentLine.appendChild(blockNode);
                blockNode.classList.add("log-line-block");
                Object.entries(block.style).forEach(function (_ref) {
                    var _ref2 = _slicedToArray(_ref, 2),
                        key = _ref2[0],
                        value = _ref2[1];

                    blockNode.style[key] = value;
                });
                if (block.studyCard !== null) {
                    blockNode.oncontextmenu = function (e) {
                        e.preventDefault();
                        _this4.game.studyModel.study(block.studyCard);
                    };
                    blockNode.onclick = function (e) {
                        e.preventDefault();
                        _this4.game.studyModel.study(block.studyCard);
                    };
                }
                var innerSpan = document.createElement("span");
                blockNode.appendChild(innerSpan);
                innerSpan.innerHTML = block.text;
                currentLineIsEmpty = false;
                if (block.lineBreak) {
                    var rootNode = document.createElement("div");
                    rootNode.appendChild(currentLine);
                    _this4.insertNode(rootNode);
                    entry.renderedLines.push(currentLine);
                    initializeLine();
                }
            });
            if (!currentLineIsEmpty) {
                var rootNode = document.createElement("div");
                rootNode.appendChild(currentLine);
                this.insertNode(rootNode);
                entry.renderedLines.push(currentLine);
            }

            if (entry.name.lineClass === "new-turn-line") {
                var index = entry.index - 1;
                while (index >= 0 && this.game.logModel.entries[index] instanceof DecisionEntry) {
                    this.game.logModel.entries[index].renderedLines.forEach(function (n) {
                        return n.classList.add("thick");
                    });
                    index--;
                }
            }

            if (distanceFromBottom < 100) {
                this.forceScrollToBottom();
            }
            return currentLine;
        }
    }, {
        key: "forceScrollToBottom",
        value: function forceScrollToBottom() {
            this.rootNode.scrollTo(0, this.rootNode.scrollHeight);
        }
    }, {
        key: "updatePremoveLogEntries",
        value: function updatePremoveLogEntries() {
            var _this5 = this;

            this.premoveNodes.forEach(function (n) {
                return n.remove();
            });
            this.premoveNodes = [];
            var entries = this.logModel.premoveLogEntries;
            if (entries.length > 0) {
                var headerLine = document.createElement("div");
                headerLine.classList.add("log-line");
                headerLine.classList.add("new-turn-line");
                headerLine.innerHTML = LANGUAGE.getUserPreferences[UserPrefIds.PREMOVES];
                this.scrollContainer.appendChild(headerLine);
                this.premoveNodes.push(headerLine);

                entries.forEach(function (entry, index) {
                    var newLine = _this5.parseLogEntry(entry);
                    newLine.style.cursor = "pointer";
                    newLine.onclick = function () {
                        return _this5.game.questionModel.cancelPremove(index);
                    };
                    _this5.scrollContainer.appendChild(newLine);
                    _this5.premoveNodes.push(newLine);
                });
            }
        }
    }, {
        key: "showing",
        set: function set(value) {
            this.privateShowing = value;
            this.rootNode.style.display = this.showing ? "initial" : "none";
        },
        get: function get() {
            return this.privateShowing;
        }
    }, {
        key: "showingUndos",
        set: function set(value) {
            this.privateShowingUndos = value;
            this.updateUndoVisibility();
        },
        get: function get() {
            return this.privateShowingUndos;
        }
    }, {
        key: "height",
        set: function set(value) {
            this.parentNode.style.height = "calc(var(--vh, 1vh) * " + value + ")";
        }
    }]);

    return LogView;
}();