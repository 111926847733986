"use strict";

webclient.component('vpContainer', {
    bindings: {
        amount: "<",
        forceShow: "<",
        outline: "<"
    },
    controller: ["$scope", function ($scope) {
        var self = this;

        var updateValues = function updateValues() {
            self.amountText = self.forceShow ? "" : self.amount;
        };
        $scope.$watch("$ctrl.amount", updateValues);
        self.outlineClass = self.outline ? "outline" : "";
    }],
    template: "<div class=\"vp-container\">\n        <div class=\"shield-label\">{{$ctrl.amountText}}</div>\n        <div class=\"shield-amount {{$ctrl.outlineClass}}\"></div>\n    </div>"
});