"use strict";

function DoneButtonId() {
    var imageName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "continue";

    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
    self.buttonText = "Done";
    self.imageName = imageName;
}

var DoneButtonIds = {
    DONE_TRASHING: new DoneButtonId("trash"),
    DONE_DISCARDING: new DoneButtonId("discard"),
    DONE_ORDERING: new DoneButtonId(),
    DONE_RETURNING: new DoneButtonId(),
    DONE_SETTING_ASIDE: new DoneButtonId("put-on-mat"),
    DONE_SELECTING: new DoneButtonId(),
    DONE_CHOOSING: new DoneButtonId(),
    TRASH: new DoneButtonId("trash"),
    DISCARD: new DoneButtonId("discard"),
    PASS: new DoneButtonId("discard"),
    TOPDECK: new DoneButtonId("topdeck"),
    BOTTOMDECK: new DoneButtonId("bottomdeck"),
    EXILE: new DoneButtonId("exile"),
    OVERPAY: new DoneButtonId("buy")
};

function DeclineButtonId() {
    var imageName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "refuse";
    var isEndButton = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
    self.buttonText = "Done";
    self.imageName = imageName;
    self.isEndButton = isEndButton;
}

var DeclineButtonIds = {
    END_ACTION_PHASE: new DeclineButtonId("stone-button", true),
    END_BUY_PHASE: new DeclineButtonId("gold-button", true),
    END_TURN: new DeclineButtonId("copper-button", true),
    DONE_PLAYING_TREASURES: new DeclineButtonId(),
    DONE_REACTING: new DeclineButtonId("continue"),
    MINING_VILLAGE_DONT_TRASH: new DeclineButtonId(),
    PEARL_DIVER_DONT_TOPDECK: new DeclineButtonId(),
    HARBINGER_DONT_TOPDECK: new DeclineButtonId(),
    THRONE_ROOM_DONT: new DeclineButtonId(),
    SMUGGLERS_GAIN_IMPOSSIBLE_COPY: new DeclineButtonId(),
    EXPLORER_GAIN_SILVER: new DeclineButtonId("take-silver"),
    HERBALIST_DONT_TOPDECK: new DeclineButtonId(),
    MINT_DONT_COPY: new DeclineButtonId(),
    HAMLET_DONT_DISCARD: new DeclineButtonId(),
    TOURNAMENT_DONT_REVEAL: new DeclineButtonId(),
    YOUNG_WITCH_DONT_REVEAL_BANE: new DeclineButtonId(),
    IGG_NO_COPPER: new DeclineButtonId(),
    COUNTERFEIT_NO_TREASURE: new DeclineButtonId(),
    CULTIST_DONT_PLAY_ANOTHER: new DeclineButtonId(),
    IRONMONGER_KEEP: new DeclineButtonId("topdeck"),
    PROCESSION_DONT: new DeclineButtonId(),
    PLAZA_NO_DISCARD: new DeclineButtonId(),
    DONT_OVERPAY: new DeclineButtonId(),
    DONE_SETTING_ASIDE: new DeclineButtonId(),
    ARTIFICER_DONT: new DeclineButtonId(),
    ENGINEER_DONT_TRASH: new DeclineButtonId(),
    ENCAMPMENT_DONT_REVEAL: new DeclineButtonId(),
    SETTLERS_DONT_TAKE_COPPER: new DeclineButtonId(),
    GLADIATOR_DONT_REVEAL: new DeclineButtonId(),
    CROWN_DONT: new DeclineButtonId(),
    LEGIONARY_DONT_REVEAL: new DeclineButtonId(),
    SHUFFLE_ALL: new DeclineButtonId(),
    PASS: new DeclineButtonId(),
    DONT_DISCARD: new DeclineButtonId(),
    DONT_TRASH: new DeclineButtonId(),
    VAULT_NO_DISCARD: new DeclineButtonId(),
    DONT_BUY: new DeclineButtonId(),
    DONT_GAIN: new DeclineButtonId(),
    DISCARD_ALL: new DeclineButtonId(),
    TORTURER_FAIL_CURSE: new DeclineButtonId(),
    TORTURER_GAIN_CURSE: new DeclineButtonId("take-curse"),
    BUSTLING_VILLAGE_NO_SETTLERS: new DeclineButtonId(),
    PRINCE_NO: new DeclineButtonId(),
    SAUNA_DONT_CHAIN: new DeclineButtonId(),
    AVANTO_DONT_CHAIN: new DeclineButtonId(),
    CONCLAVE_DONT: new DeclineButtonId(),
    IMP_DONT: new DeclineButtonId(),
    NECROMANCER_DONT: new DeclineButtonId(),
    NO_THANKS: new DeclineButtonId(),
    DONT_EXILE: new DeclineButtonId(),
    KINGS_COURT_DONT: new DeclineButtonId(),
    MASTERMIND_DONT: new DeclineButtonId(),
    DELAY_DONT: new DeclineButtonId(),
    TOIL_DONT: new DeclineButtonId(),
    MARCH_DONT: new DeclineButtonId(),
    DONT_TOPDECK: new DeclineButtonId(),
    RANDOM_ORDER: new DeclineButtonId(),
    HUNTING_LODGE_KEEP: new DeclineButtonId(),
    TIARA_NO_TREASURE: new DeclineButtonId(),
    SWAP_DONT: new DeclineButtonId(),
    DONT_PLAY: new DeclineButtonId(),
    DONT_REVEAL: new DeclineButtonId(),
    DONT_ROTATE: new DeclineButtonId(),
    DONT: new DeclineButtonId(),
    SPEND_FAVOR: new DeclineButtonId(),
    CONTINUE: new DeclineButtonId("continue"),
    ORB_DONT: new DeclineButtonId(),
    DONT_PUZZLE_BOX: new DeclineButtonId(),
    DONT_INSPIRING: new DeclineButtonId(),
    DONT_JOUST: new DeclineButtonId(),
    SNAKE_WITCH_DONT_RETURN: new DeclineButtonId()

};