"use strict";

var SpanishErrors = {};

SpanishErrors[FailureReasons.WRONG_PASSWORD] = "Contraseña incorrecta.";
SpanishErrors[FailureReasons.NOT_ENOUGH_PLAYERS] = "No hay suficientes jugadores.";
SpanishErrors[FailureReasons.TOO_MANY_PLAYERS] = "Demasiados jugadores.";
SpanishErrors[FailureReasons.NEW_HOST_NOT_FOUND] = "Anfitrión no encontrado.";
SpanishErrors[FailureReasons.ALREADY_JOINED_A_TABLE] = "Ya te has unido a una mesa.";
SpanishErrors[FailureReasons.NOT_ALLOWED_TO_JOIN] = "No tienes permiso para unirte.";
SpanishErrors[FailureReasons.BAD_CLIENT_VERSION] = "Tu cliente ha caducado; Por favor, ctrl/cmd-F5 para acceder al más reciente.";
SpanishErrors[FailureReasons.UNKNOWN_RULE_INDEX] = "Índice de regla desconocido.";
SpanishErrors[FailureReasons.RULE_NOT_REMOVABLE] = "Esta regla no se puede quitar.";
SpanishErrors[FailureReasons.INVITEE_NOT_LOGGED_IN] = "El invitado no ha iniciado sesión.";
SpanishErrors[FailureReasons.NOT_ALLOWED_TO_PLAY] = "No tienes permiso para jugar.";
SpanishErrors[FailureReasons.TABLE_CHAT_REQUIRES_TABLE] = "El chat de mesa requiere una mesa.";
SpanishErrors[FailureReasons.SPECTATOR_CHAT_REQUIRES_TABLE] = "El chat de espectadores requiere una mesa.";
SpanishErrors[FailureReasons.LOGIN_REQUIRED] = "Necesitas iniciar sesión.";
SpanishErrors[FailureReasons.COMMAND_REQUIRES_TABLE] = "Este comando requiere una mesa.";
SpanishErrors[FailureReasons.COMMAND_REQUIRES_HOST] = "Este comando requiere privilegios de anfitrión.";
SpanishErrors[FailureReasons.RULE_CONFLICTS] = "Conflicto de reglas.";
SpanishErrors[FailureReasons.NO_GAMESERVER_AVAILABLE] = "Servidor de juego no disponible.";
SpanishErrors[FailureReasons.SELECTED_CARD_NOT_OWNED] = "No posees la carta seleccionada.";
SpanishErrors[FailureReasons.SELECTED_CARD_NOT_KINGDOM_CARD] = "La carta seleccionada no es una carta de Reino.";
SpanishErrors[FailureReasons.SELECTED_TOO_MANY_EVENTS_OR_LANDMARKS] = "Demasiados Eventos o Hitos seleccionados.";
SpanishErrors[FailureReasons.THIS_IS_NOT_YOUR_TABLE] = "Esta mesa no te pertenece.";
SpanishErrors[FailureReasons.ALREADY_LOGGED_IN] = "Ya has iniciado sesión.";
SpanishErrors[FailureReasons.CANNOT_RECONNECT_WHEN_STILL_CONNECTED] = "Ya estás conectado.";
SpanishErrors[FailureReasons.KICK_FAILED] = "Expulsión fallida.";
SpanishErrors[FailureReasons.ALREADY_STARTING] = "Partida ya iniciándose.";
SpanishErrors[FailureReasons.ALREADY_STARTED] = "Partida ya inciada.";
SpanishErrors[FailureReasons.NOTHING_TO_KICK] = "Nadie para expulsar.";
SpanishErrors[FailureReasons.INTERNAL_ERROR] = "Error interno.";
SpanishErrors[FailureReasons.ILLEGAL_PROPERTY_CHANGE] = "Cambio de propiedad ilegal";
SpanishErrors[FailureReasons.CODE_ALREADY_USED] = "Código ya usado";
SpanishErrors[FailureReasons.FAILED_CONFIRMING_EMAIL] = "Fallo en la confirmación del email";
SpanishErrors[FailureReasons.FAILED_RETRIEVING_USER] = "Fallo recuperando usuario";
SpanishErrors[FailureReasons.EMAIL_NOT_CONFIRMED_YET] = "No puedes ser anfitrión antes de confirmar el email.";
SpanishErrors[FailureReasons.FAILURE_SENDING_EMAIL] = "La confirmación del email no pudo ser enviada";
SpanishErrors[FailureReasons.CONFIRM_EMAIL_FIRST] = "Disculpa, no podemos mandar una contraseña a un email no confirmado.";
SpanishErrors[FailureReasons.OLD_PASSWORD_INCORRECT] = "Contraseña antigua incorrecta";
SpanishErrors[FailureReasons.INCORRECT_PRICE] = "Precio incorrecto";
SpanishErrors[FailureReasons.PURCHASE_IN_FUTURE] = "La compra parece venir del futuro";
SpanishErrors[FailureReasons.PURCHASE_TOO_OLD] = "Compra demasiado antigua, inténtelo de nuevo";
SpanishErrors[FailureReasons.FAILED_SETTING_UP_PURCHASE] = "Fallo al preparar la compra";
SpanishErrors[FailureReasons.FAILED_STORING_PURCHASE] = "Fallo al registrar la compra";
SpanishErrors[FailureReasons.DUPLICATE_USERNAME] = "El nombre de usuario ya existe";
SpanishErrors[FailureReasons.INVALID_USERNAME] = "El nombre de usuario no es válido";
SpanishErrors[FailureReasons.INVALID_PASSWORD] = "La contraseña no es válida";
SpanishErrors[FailureReasons.INVALID_GENDER] = "El género no es válido";
SpanishErrors[FailureReasons.PURCHASE_TOO_SHORT] = "La suscripción mínima es 1 mes";
SpanishErrors[FailureReasons.PURCHASE_TOO_LONG] = "Actualmente no vendemos suscripciones más allá de 2021"; // TRANSLATE : 2021 --> 2024 (it might need some changes to surround words)
SpanishErrors[FailureReasons.METASERVER_INACTIVE] = "Nueva versión del servidor disponible; ctrl/cmd-F5 para conectarte";
SpanishErrors[FailureReasons.NO_SUCH_TABLE] = "No existe dicha mesa.";
SpanishErrors[FailureReasons.NO_SUCH_USER] = "No existe dicho usuario.";
SpanishErrors[FailureReasons.NO_SUCH_CODE] = "No existe dicho código.";
SpanishErrors[FailureReasons.RESERVED_USERNAME] = "Este nombre de usuario está reservado.";
SpanishErrors[FailureReasons.PRICE_IS_ZERO] = "No puedes hacer una compra de precio cero.";
SpanishErrors[FailureReasons.NO_SUCH_USERNAME] = "El nombre de usuario no existe.";
SpanishErrors[FailureReasons.NO_SUCH_GAMELOG] = "No se pudo encontrar la partida solicitada.";
SpanishErrors[FailureReasons.ILLEGAL_PLAYER_ORDER] = "Orden de jugadores ilegal.";
SpanishErrors[FailureReasons.INVALID_SESSION_ID] = "ID de sesión inválido.";
SpanishErrors[FailureReasons.ACTION_CODE_EXPIRED] = "El código expiró.";
SpanishErrors[FailureReasons.BOT_GAMES_CANT_BE_RATED] = "Las partidas con bot no pueden ser puntuadas.";
SpanishErrors[FailureReasons.TOO_MANY_PLAYERS_FOR_RATED_GAME] = "Las partidas con más de 4 jugadores no pueden ser puntuadas.";
SpanishErrors[FailureReasons.ALREADY_ON_BLACKLIST] = "Ya está en la lista negra.";
SpanishErrors[FailureReasons.NOT_ON_BLACKLIST] = "No está en la lista negra.";
SpanishErrors[FailureReasons.ALREADY_ON_FRIENDLIST] = "Ya está en la lista de amigos.";
SpanishErrors[FailureReasons.NOT_ON_FRIENDLIST] = "No está en la lista de amigos.";
SpanishErrors[FailureReasons.SEARCHING_FOR_MYSELF] = "No puedes añadirte a tí mismo.";
SpanishErrors[FailureReasons.SEARCHED_FOR_BOT] = "No puedes añadir a un bot.";
SpanishErrors[FailureReasons.USER_IS_BANNED] = "Se te ha prohibido el acceso al sitio.";
SpanishErrors[FailureReasons.WRONG_BONUS_CODE_TYPE] = "WRONG_BONUS_CODE_TYPE.";
SpanishErrors[FailureReasons.ILLEGAL_BONUS_CODE] = "ILLEGAL_BONUS_CODE.";
SpanishErrors[FailureReasons.GAME_ID_SHOULD_BE_NUMBER] = "El id de la partida debe ser un número.";
SpanishErrors[FailureReasons.NOT_SUBSCRIBED_TO] = "Not subscribed to"; // TRANSLATE
SpanishErrors[FailureReasons.KINGDOM_HAS_TOO_MANY_CARDS] = "Kingdom has too many cards."; // TRANSLATE
SpanishErrors[FailureReasons.NO_CARDS_AVAILABLE_TO_FILL_KINGDOM] = "No cards available to fill kingdom."; // TRANSLATE
SpanishErrors[FailureReasons.FAILED_TO_SELECT_BANE_CARD] = "Failed to select bane card."; // TRANSLATE
SpanishErrors[FailureReasons.FAILED_TO_SELECT_MOUSE_CARD] = "Failed to select mouse card."; // TRANSLATE
SpanishErrors[FailureReasons.FAILED_TO_SELECT_FERRYMAN_CARD] = "Failed to select Ferryman card."; // TRANSLATE
SpanishErrors[FailureReasons.FAILED_TO_SELECT_PREVIEW_CARDS] = "Failed to select preview card."; // TRANSLATE
SpanishErrors[FailureReasons.FAILED_TO_SELECT_ALLY] = "Failed to select Ally."; // TRANSLATE
SpanishErrors[FailureReasons.FAILED_TO_SELECT_LIASON] = "Failed to select Liason."; // TRANSLATE
SpanishErrors[FailureReasons.REQUIRING_RANDOM_CARD_FROM_DISABLED_EXPANSION] = "Requiring a random card from a disabled expansion."; // TRANSLATE
SpanishErrors[FailureReasons.COMMAND_REQUIRES_PLAYER] = "This command is only for players.";
SpanishErrors[FailureReasons.COMMAND_REQUIRES_GAME] = "This command requires a game.";
SpanishErrors[FailureReasons.ILLEGAL_ANSWER] = "Provided an illegal answer in this game.";
SpanishErrors[FailureReasons.ILLEGAL_REQUEST] = "Illegal request in this game.";
SpanishErrors[FailureReasons.NO_SUCH_UNDO] = "No such undo.";
SpanishErrors[FailureReasons.SERVER_DOESNT_ALLOW_NEW_GAMES] = "Server doesn't allow new games. Please reload the page.";
SpanishErrors[FailureReasons.SERVER_IS_DOWN] = "Server is down. Please reload the page.";

var SpanishSuccessDescriptions = {};

SpanishSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_FORGOT_PASSWORD] = "Te hemos mandado un email para cambiar la contraseña.";
SpanishSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_CONFIRM_EMAIL] = "Te hemos mandado un email para confirmar la dirección.";
SpanishSuccessDescriptions[SuccessDescriptions.PASSWORD_CHANGED] = "Contraseña cambiada";
SpanishSuccessDescriptions[SuccessDescriptions.KICK_SUCCESS] = "Expulsado con éxito";
SpanishSuccessDescriptions[SuccessDescriptions.PURCHASE_SUCCESS] = "Compra exitosa";
SpanishSuccessDescriptions[SuccessDescriptions.EMAIL_CONFIRMED] = "Email confirmado";
SpanishSuccessDescriptions[SuccessDescriptions.REFUND_SUCCESS] = "Compra reembolsada";
SpanishSuccessDescriptions[SuccessDescriptions.ACTION_CODE_USED] = "Código usado con éxito.";
SpanishSuccessDescriptions[SuccessDescriptions.SUBSCRIPTION_UPDATED] = "Tu suscripción se ha actualizado.";

var SpanishTableRules = {};

SpanishTableRules[TableRuleIds.PLAYERS_CAN_SEE_SPECTATOR_CHAT] = "Ver mensajes de espectadores";
SpanishTableRules[TableRuleIds.BANNED_CARDS] = "Cartas vetadas";
SpanishTableRules[TableRuleIds.REQUIRED_CARDS] = "Cartas requeridas";
SpanishTableRules[TableRuleIds.USED_EXPANSIONS] = "Expansiones usadas";
SpanishTableRules[TableRuleIds.RESTRICTIVE_PLAYER_RULES] = "Solo los jugadores especificados pueden jugar";
SpanishTableRules[TableRuleIds.SPECTATE_RULES] = "Permitir espectadores";
SpanishTableRules[TableRuleIds.MIN_RATING] = "Nivel mínimo";
SpanishTableRules[TableRuleIds.MAX_RATING] = "Nivel máximo";
SpanishTableRules[TableRuleIds.RANDOM_SEED] = "Especificar semilla aleatoria";
SpanishTableRules[TableRuleIds.MIN_PLAYERS] = "Min jugadores";
SpanishTableRules[TableRuleIds.MAX_PLAYERS] = "Max jugadores";
SpanishTableRules[TableRuleIds.SPECIAL_KINGDOM_RULES] = "Reglas de Reino especiales";
SpanishTableRules[TableRuleIds.RESPECTED_CARD_LISTS] = "Respetar cartas favoritas / rechazadas / vetadas";
SpanishTableRules[TableRuleIds.SHOW_VP_COUNTER] = "Mostrar contador de PV";
SpanishTableRules[TableRuleIds.UNDO_SETTINGS] = "Deshacer ajustes";
SpanishTableRules[TableRuleIds.ALWAYS_ALLOWED_PLAYER_RULES] = "ALWAYS_ALLOWED_PLAYER_RULES";
SpanishTableRules[TableRuleIds.PLAYER_ORDER] = "Orden de jugadores";
SpanishTableRules[TableRuleIds.REPLAY_INSTRUCTIONS] = "Instrucciones de repetición";
SpanishTableRules[TableRuleIds.RATED_GAME] = "Partida puntuada";
SpanishTableRules[TableRuleIds.PREVIEW_GAME] = "Avance de Bestiario";
SpanishTableRules[TableRuleIds.SCRIPTED_RULES] = "Reglas de Reino especiales";
SpanishTableRules[TableRuleIds.CARD_POOL_LEVEL] = "Nivel de cartas";

var SpanishActivities = {};

SpanishActivities[ActivityStati.LOGGED_IN] = "Sesión iniciada";
SpanishActivities[ActivityStati.PLAYING] = "Jugando";
SpanishActivities[ActivityStati.SPECTATING] = "Espectador";
SpanishActivities[ActivityStati.AT_TABLE_PLAYER] = "En la mesa (jugador)";
SpanishActivities[ActivityStati.AT_TABLE_SPECTATOR] = "En la mesa (espectador)";
SpanishActivities[ActivityStati.DISCONNECTED] = "Desconectado";

var SpanishTableStati = {};

SpanishTableStati[TableStati.NEW] = "Nueva";
SpanishTableStati[TableStati.POST_GAME] = "Post-partida";
SpanishTableStati[TableStati.RUNNING] = "En curso";
SpanishTableStati[TableStati.ABANDONED] = "Abandonada";

var SpanishLobbyButtons = {
    DONE: "Hecho",
    RESET: "Reiniciar",
    CLEAR_SELECTION: "Borrar selección",
    SHOW_TEXT_KINGDOM: "Show Text Kingdom", // <--TRANSLATE
    NEW_TABLE: "Nueva mesa",
    START_GAME: "Comenzar partida",
    LEAVE_TABLE: "Abandonar mesa",
    SELECT_KINGDOM: "Seleccionar Reino",
    LIST_BANNED: "Banned", // <-- TRANSLATE
    LIST_DISLIKED: "Disliked", // <-- TRANSLATE
    LIST_LIKED: "Liked", // <-- TRANSLATE
    LIST_FAMILIAR: "Familiar", // <-- TRANSLATE
    LOAD_FROM_END: "Load from End", // <-- TRANSLATE    
    SELECT_COLONIES: "Colonias: ",
    SELECT_SHELTERS: "Cobijos: "
};

var SpanishLobbyTexts = {
    AUTOCOMPLETE_FAILED: "No se encontraron las cartas para \"CARDS\".",
    ADD_CARDS_AUTOCOMPLETE: "Introduce cartas para incluir al Reino",
    BAN_CARDS_AUTOCOMPLETE: "Introduce cartas para vetar en el Reino",
    KINGDOM_OPTIONS: "Kingdom Options", // <-- TRANSLATE
    GAME_OPTIONS: "Game Options", // <-- TRANSLATE
    RESPECTED_CARDS: "Respected Cards", // <-- TRANSLATE
    RELATIVE_LEVEL: "Relative Level", // <-- TRANSLATE
    LEVEL_INTERVAL: "${min} to ${max}", // <-- TRANSLATE
    EMPTY_SLOT: "Empty Slot", // <-- TRANSLATE
    REMOVE_SLOT: "Remove Slot", // <-- TRANSLATE
    ADD_SLOT: "Add Slot", // <-- TRANSLATE
    RANDOMIZE_PLAYER_ORDER: "Randomize Player Order", // <-- TRANSLATE
    CHAT: "Chat", // <-- TRANSLATE
    SELECT_CARDS: "Select Cards" // <-- TRANSLATE  
};

var SpanishTernaryFieldTexts = {};

SpanishTernaryFieldTexts[TernaryField.YES] = "Sí";
SpanishTernaryFieldTexts[TernaryField.NO] = "No";
SpanishTernaryFieldTexts[TernaryField.RANDOM] = "Aleatorio";

var SpanishTabs = {};

SpanishTabs[LobbyTabs.INBOX] = "Buzón";
SpanishTabs[LobbyTabs.MATCH] = "Jugar"; // emparejar, partidas...
SpanishTabs[LobbyTabs.FRIEND_LIST] = "Amigos";
SpanishTabs[LobbyTabs.TABLES] = "Mesas";
SpanishTabs[LobbyTabs.NEW_TABLE] = "Nueva mesa";
SpanishTabs[LobbyTabs.MY_TABLE] = "Mi mesa";
SpanishTabs[LobbyTabs.ACCOUNT] = "Cuenta";
SpanishTabs[LobbyTabs.STORE] = "Tienda";
SpanishTabs[LobbyTabs.OPTIONS] = "Ajustes";
SpanishTabs[LobbyTabs.FAMILIAR_CARDS] = "Cartas familiares";
SpanishTabs[LobbyTabs.LEADERBOARD] = "Clasificación";
SpanishTabs[LobbyTabs.CARDLISTS] = "Listas de cartas";

var SpanishMatchTypes = {};

SpanishMatchTypes[AutomatchTypes.QUICK_MATCH] = "Cualquier rival";
SpanishMatchTypes[AutomatchTypes.GOOD_MATCH] = "Rival similar";
SpanishMatchTypes[AutomatchTypes.MATCH_WITH_EXPANSIONS] = "Partida con expansiones";
SpanishMatchTypes[AutomatchTypes.MATCH_WITH_BOT] = "Bot";
SpanishMatchTypes[AutomatchTypes.MATCH_UNRATED] = "Rival humano";

var SpanishUndoSettings = {};

SpanishUndoSettings[UndoSettings.NEVER] = "Nunca";
SpanishUndoSettings[UndoSettings.ASK] = "Preguntar";
SpanishUndoSettings[UndoSettings.ALWAYS] = "Siempre";

var SpanishGroupIds = {};

SpanishGroupIds[GroupIds.EVERYBODY] = "Todos";
SpanishGroupIds[GroupIds.NOBODY] = "Nadie";
SpanishGroupIds[GroupIds.FRIENDS_OF] = "Amigos de";
SpanishGroupIds[GroupIds.LIST_PLAYER_IDS] = "Jugadores seleccionados:";

var SpanishCountries = {};

SpanishCountries[Countries.NON_EU] = "Fuera UE";
SpanishCountries[Countries.AT] = "Austria";
SpanishCountries[Countries.BE] = "Bélgica";
SpanishCountries[Countries.BG] = "Bulgaria";
SpanishCountries[Countries.HR] = "Croacia";
SpanishCountries[Countries.CY] = "Chipre";
SpanishCountries[Countries.CZ] = "Republica Checa";
SpanishCountries[Countries.DK] = "Dinamarca";
SpanishCountries[Countries.EE] = "Estonia";
SpanishCountries[Countries.FI] = "Finlandia";
SpanishCountries[Countries.FR] = "Francia";
SpanishCountries[Countries.DE] = "Alemania";
SpanishCountries[Countries.GR] = "Grecia";
SpanishCountries[Countries.HU] = "Hungría";
SpanishCountries[Countries.IE] = "Irlanda";
SpanishCountries[Countries.IT] = "Italia";
SpanishCountries[Countries.LV] = "Letonia";
SpanishCountries[Countries.LT] = "Lituania";
SpanishCountries[Countries.LU] = "Luxemburgo";
SpanishCountries[Countries.MT] = "Malta";
SpanishCountries[Countries.NL] = "Países Bajos";
SpanishCountries[Countries.PL] = "Polonia";
SpanishCountries[Countries.PT] = "Portugal";
SpanishCountries[Countries.RO] = "Rumanía";
SpanishCountries[Countries.SK] = "Eslovaquia";
SpanishCountries[Countries.SI] = "Eslovenia";
SpanishCountries[Countries.ES] = "España";
SpanishCountries[Countries.SE] = "Suecia";
SpanishCountries[Countries.GB] = "Reino Unido";

var SpanishAutomatchQuestions = {};
var SpanishAutomatchValues = {};

SpanishAutomatchQuestions[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS] = "Jugadores";
SpanishAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS] = {};
SpanishAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.REQUIRE] = "2 jugadores";
SpanishAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.EITHER] = "2 o 3 jugadores";
SpanishAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.REJECT] = "3 jugadores";

SpanishAutomatchQuestions[AUTOMATCH_QUESTION.RATED_GAME] = "Tipo de partida";
SpanishAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME] = {};
SpanishAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.REQUIRE] = "Puntuada";
SpanishAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.EITHER] = "Puntuada o Amistosa";
SpanishAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.REJECT] = "Amistosa";

SpanishAutomatchQuestions[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME] = "Extra 2e";
SpanishAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME] = {};
SpanishAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.REQUIRE] = "Sí";
SpanishAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.EITHER] = "Sí o No";
SpanishAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.REJECT] = "No";

SpanishAutomatchQuestions[AUTOMATCH_QUESTION.PREVIEW_GAME] = "Previews";
SpanishAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME] = {};
SpanishAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.REQUIRE] = "Sí";
SpanishAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.REJECT] = "No";
SpanishAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.EITHER] = "Sí o No";