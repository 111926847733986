"use strict";

webclient.component('costContainer', {
    bindings: {
        coin: "<",
        potion: "<",
        debt: "<",
        forceShow: "<"
    },
    controller: ["$scope", "$element", function ($scope, $element) {
        var self = this;

        var canvas = $element[0].getElementsByTagName("canvas")[0];
        var ctx = canvas.getContext('2d');
        var w = canvas.width;
        var h = canvas.height;

        var fullRedraw = function fullRedraw() {
            self.onlyPotion = self.coin === 0 && self.debt === 0 && self.potion > 0;
            canvas.height = canvas.clientHeight * 2;
            canvas.width = self.onlyPotion ? canvas.clientHeight * 1.4 : canvas.clientHeight * 2;
            w = canvas.width;
            h = canvas.height;
            drawCostAmount(ctx, { "coin": self.coin, "potion": self.potion, "debt": self.debt }, new Rectangle(0, 0, w, h), self.forceShow);
        };
        fullRedraw();
        $scope.$watch('$ctrl.coin', fullRedraw);
        $scope.$watch('$ctrl.potion', fullRedraw);
        $scope.$watch('$ctrl.debt', fullRedraw);
        $scope.$watch('$ctrl.forceShow', fullRedraw);
    }],
    template: "\n        <canvas ng-class=\"{'only-potion': $ctrl.onlyPotion}\"></canvas>\n    "
});

webclient.component('vpAmountIcon', {
    bindings: {
        amount: "<"
    },
    controller: ["$scope", "$element", function ($scope, $element) {
        var self = this;

        var canvas = $element[0].getElementsByTagName("canvas")[0];
        var ctx = canvas.getContext('2d');
        var w = canvas.width;
        var h = canvas.height;

        var fullRedraw = function fullRedraw() {
            canvas.height = canvas.clientHeight * 2;
            canvas.width = canvas.clientWidth * 2;
            w = canvas.width;
            h = canvas.height;
            var victoryURL = 'images/elements/victory_locontrast.png';
            Preloader.load(victoryURL, function (source) {
                ctx.drawImage(source, 0, 0, w, h);
                ctx.strokeStyle = "white";
                drawFittedText(ctx, self.amount, new Rectangle(0.05 * w, 0.05 * h, 0.9 * w, 0.9 * h), 0.9, "Minion-Bold", "", true);
            });
        };
        setTimeout(fullRedraw, 0);
        $scope.$watch('$ctrl.amount', fullRedraw);
    }],
    template: "\n        <canvas></canvas>\n    "
});