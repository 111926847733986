"use strict";

var CzechCardNames = {};

CzechCardNames[CardNames.BACK] = new TranslatedCardName("karta", "karty", "kartu", "karet");
CzechCardNames[CardNames.CURSE] = new TranslatedCardName("Kletba", "Kletby", "Kletbu", "Kleteb");
CzechCardNames[CardNames.COPPER] = new TranslatedCardName("Měďák", "Měďáky", "Měďák", "Měďáků");
CzechCardNames[CardNames.SILVER] = new TranslatedCardName("Stříbrňák", "Stříbrňáky", "Stříbrňák", "Stříbrňáků");
CzechCardNames[CardNames.GOLD] = new TranslatedCardName("Zlaťák", "Zlaťáky", "Zlaťák", "Zlaťáků");
CzechCardNames[CardNames.ESTATE] = new TranslatedCardName("Statek", "Statky", "Statek", "Statků");
CzechCardNames[CardNames.DUCHY] = new TranslatedCardName("Vévodství", "Vévodství", "Vévodství", "Vévodství");
CzechCardNames[CardNames.PROVINCE] = new TranslatedCardName("Provincie", "Provincie", "Provincii", "Provincií");
CzechCardNames[CardNames.ARTISAN] = new TranslatedCardName("Umělec", "Umělce", "Umělce", "Umělců");
CzechCardNames[CardNames.BANDIT] = new TranslatedCardName("Bandita", "Bandity", "Banditu", "Bynditů");
CzechCardNames[CardNames.BUREAUCRAT] = new TranslatedCardName("Úředník", "Úředníky", "Úředníka", "Úředníků");
CzechCardNames[CardNames.CELLAR] = new TranslatedCardName("Sklepení", "Sklepení", "Sklepení", "Sklepení");
CzechCardNames[CardNames.CHAPEL] = new TranslatedCardName("Kaple", "Kaple", "Kapli", "Kaplí");
CzechCardNames[CardNames.COUNCIL_ROOM] = new TranslatedCardName("Zasedání Rady", "Zasedání Rady", "Zasedání Rady", "Zasedání Rad");
CzechCardNames[CardNames.FESTIVAL] = new TranslatedCardName("Jarmark", "Jarmarky", "Jarmark", "Jarmarků");
CzechCardNames[CardNames.GARDENS] = new TranslatedCardName("Zahrady", "Zahrady", "Zahrady", "Zahrad");
CzechCardNames[CardNames.HARBINGER] = new TranslatedCardName("Harbinger", "Harbingery", "Harbingera", "Harbingerů");
CzechCardNames[CardNames.LABORATORY] = new TranslatedCardName("Laboratoř", "Laboratoře", "Laboratoř", "Laboratoří");
CzechCardNames[CardNames.LIBRARY] = new TranslatedCardName("Knihovna", "Knihovny", "Knihovnu", "Knihoven");
CzechCardNames[CardNames.MARKET] = new TranslatedCardName("Trh", "Trhy", "Trh", "Trhů");
CzechCardNames[CardNames.MERCHANT] = new TranslatedCardName("Obchodník", "Obchodníky", "Obchodníka", "Obchodníků");
CzechCardNames[CardNames.MILITIA] = new TranslatedCardName("Milice", "Milice", "Milici", "Milic");
CzechCardNames[CardNames.MINE] = new TranslatedCardName("Důl", "Doly", "Důl", "Dolů");
CzechCardNames[CardNames.MOAT] = new TranslatedCardName("Hradní Příkop", "Hradní Příkopy", "Hradní Příkop", "Hradních Příkpoů");
CzechCardNames[CardNames.MONEYLENDER] = new TranslatedCardName("Lichvář", "Lichváře", "Lichváře", "Lichvářů");
CzechCardNames[CardNames.POACHER] = new TranslatedCardName("Pytlák", "Pytláky", "Pytláka", "Pytláků");
CzechCardNames[CardNames.REMODEL] = new TranslatedCardName("Přestavba", "Přestavby", "Přestavbu", "Přestaveb");
CzechCardNames[CardNames.SENTRY] = new TranslatedCardName("Hlídka", "Hlídky", "Hlídku", "Hlídek");
CzechCardNames[CardNames.SMITHY] = new TranslatedCardName("Kovárna", "Kovárny", "Kovárnu", "Kováren");
CzechCardNames[CardNames.THRONE_ROOM] = new TranslatedCardName("Trůnní Sál", "Trůnní Sály", "Trůnní Sál", "Trůnních Sálů");
CzechCardNames[CardNames.VASSAL] = new TranslatedCardName("Vazal", "Vazaly", "Vazala", "Vazalů");
CzechCardNames[CardNames.VILLAGE] = new TranslatedCardName("Vesnice", "Vesnice", "Vesnici", "Vesnic");
CzechCardNames[CardNames.WITCH] = new TranslatedCardName("Čarodějnice", "Čarodějnice", "Čarodějnici", "Čarodějnic");
CzechCardNames[CardNames.WORKSHOP] = new TranslatedCardName("Dílna", "Dílny", "Dílnu", "Dílen");

CzechCardNames[CardNames.COURTYARD] = new TranslatedCardName("Hradní nádvoří", "Hradní nádvoří", "Hradní nádvoří", "Hradních nádvoří");
CzechCardNames[CardNames.CONSPIRATOR] = new TranslatedCardName("Spiklenec", "Spiklence", "Spiklence", "Spiklenců");
CzechCardNames[CardNames.COURTIER] = new TranslatedCardName("Dvořan", "Dvořany", "Dvořana", "Dvořanů");
CzechCardNames[CardNames.BARON] = new TranslatedCardName("Baron", "Barony", "Barona", "Baronů");
CzechCardNames[CardNames.BRIDGE] = new TranslatedCardName("Most", "Mosty", "Most", "Mostů");
CzechCardNames[CardNames.DIPLOMAT] = new TranslatedCardName("Diplomat", "Diplomaty", "Diplomata", "Diplomatů");
CzechCardNames[CardNames.DUKE] = new TranslatedCardName("Vévoda", "Vévody", "Vévodu", "Vévodů");
CzechCardNames[CardNames.HAREM] = new TranslatedCardName("Harém", "Harémy", "Harém", "Harémů");
CzechCardNames[CardNames.NOBLES] = new TranslatedCardName("Šlechtici", "Šlechtice", "Šlechtice", "Šlechticů");
CzechCardNames[CardNames.IRONWORKS] = new TranslatedCardName("Hutě", "Hutě", "Hutě", "Hutí");
CzechCardNames[CardNames.LURKER] = new TranslatedCardName("Stínový vrah", "Stínové vrahy", "Stínového vraha", "Stínových vrahů");
CzechCardNames[CardNames.MASQUERADE] = new TranslatedCardName("Maškary", "Maškary", "Maškary", "Maškar");
CzechCardNames[CardNames.MILL] = new TranslatedCardName("Mlýn", "Mlýny", "Mlýn", "Mlýnů");
CzechCardNames[CardNames.MINING_VILLAGE] = new TranslatedCardName("Důlní osada", "Důlní osady", "Důlní osadu", "Důlních osad");
CzechCardNames[CardNames.MINION] = new TranslatedCardName("Služebník", "Služebníky", "Služebníka", "Služebníků");
CzechCardNames[CardNames.PATROL] = new TranslatedCardName("Obhlídka", "Obhlídky", "Obhlídku", "Obhlídek");
CzechCardNames[CardNames.PAWN] = new TranslatedCardName("Pěšák", "Pěšáky", "Pěšáka", "Pěšáků");
CzechCardNames[CardNames.REPLACE] = new TranslatedCardName("Výměna", "Výměny", "Výměnu", "Výměn");
CzechCardNames[CardNames.SECRET_PASSAGE] = new TranslatedCardName("Tajná chodba", "Tajné chodby", "Tajnou chodbu", "Tajných chodeb");
CzechCardNames[CardNames.SHANTY_TOWN] = new TranslatedCardName("Chudinská čtvrť", "Chudinské čtvrti", "Chudinskou čtvrť", "Chudinských čtvrtí");
CzechCardNames[CardNames.STEWARD] = new TranslatedCardName("Správce", "Správce", "Správce", "Správců");
CzechCardNames[CardNames.SWINDLER] = new TranslatedCardName("Podvodník", "Podvodníky", "Podvodníka", "Podvodníků");
CzechCardNames[CardNames.TORTURER] = new TranslatedCardName("Žalářník", "Žalářníky", "Žalářníka", "Žalářníků");
CzechCardNames[CardNames.TRADING_POST] = new TranslatedCardName("Obchodní místo", "Obchodní místa", "Obchodní místo", "Obchodních míst");
CzechCardNames[CardNames.UPGRADE] = new TranslatedCardName("Pokrok", "Pokroky", "Pokrok", "Pokroků");
CzechCardNames[CardNames.WISHING_WELL] = new TranslatedCardName("Studna přání", "Studny přání", "Studnu přání", "Studen přání");

CzechCardNames[CardNames.AMBASSADOR] = new TranslatedCardName("Velvyslanec", "Velvyslance", "Velvyslance", "Velvyslanců");
CzechCardNames[CardNames.BAZAAR] = new TranslatedCardName("Bazar", "Bazary", "Bazar", "Bazarů");
CzechCardNames[CardNames.CARAVAN] = new TranslatedCardName("Karavana", "Karavany", "Karavanu", "Karavan");
CzechCardNames[CardNames.CUTPURSE] = new TranslatedCardName("Kapsář", "Kapsáře", "Kapsáře", "Kapsářů");
CzechCardNames[CardNames.EMBARGO] = new TranslatedCardName("Embargo", "Embarga", "Embargo", "Embarg");
CzechCardNames[CardNames.EXPLORER] = new TranslatedCardName("Průzkumník", "Průzkumníky", "Průzkumníka", "Průzkumníků");
CzechCardNames[CardNames.FISHING_VILLAGE] = new TranslatedCardName("Rybářská vesnice", "Rybářské vesnice", "Rybářskou vesnici", "Rybářských vesnic");
CzechCardNames[CardNames.GHOST_SHIP] = new TranslatedCardName("Loď duchů", "Lodě duchů", "Loď duchů", "Lodí duchů");
CzechCardNames[CardNames.HAVEN] = new TranslatedCardName("Přístav", "Přístavy", "Přístav", "Přístavů");
CzechCardNames[CardNames.ISLAND] = new TranslatedCardName("Ostrov", "Ostrovy", "Ostrov", "Ostrovů");
CzechCardNames[CardNames.LIGHTHOUSE] = new TranslatedCardName("Maják", "Majáky", "Maják", "Majáků");
CzechCardNames[CardNames.LOOKOUT] = new TranslatedCardName("Rozhledna", "Rozhledny", "Rozhlednu", "Rozhleden");
CzechCardNames[CardNames.MERCHANT_SHIP] = new TranslatedCardName("Kupecká loď", "Kupecké lodě", "Kupeckou loď", "Kupeckých lodí");
CzechCardNames[CardNames.NATIVE_VILLAGE] = new TranslatedCardName("Domorodá vesnice", "Domorodé vesnice", "Domorodou vesnici", "Domorodých vesnic");
CzechCardNames[CardNames.NAVIGATOR] = new TranslatedCardName("Navigátor", "Navigátora", "Navigátora", "Navigátorů");
CzechCardNames[CardNames.OUTPOST] = new TranslatedCardName("Předsunutá hlídka", "Předsunuté hlídky", "Předsunutou hlídku", "Předsunutých hlídek");
CzechCardNames[CardNames.PEARL_DIVER] = new TranslatedCardName("Lovec perel", "Lovce perel", "Lovce perel", "Lovců perel");
CzechCardNames[CardNames.PIRATE_SHIP] = new TranslatedCardName("Pirátská loď", "Pirátské lodě", "Pirátskou loď", "Pirátských lodí");
CzechCardNames[CardNames.SALVAGER] = new TranslatedCardName("Zachránce", "Zachránce", "Zachránce", "Zachránců");
CzechCardNames[CardNames.SEA_HAG] = new TranslatedCardName("Mořská babizna", "Mořská babizna", "Mořská babizna", "Mořských babizen");
CzechCardNames[CardNames.SMUGGLERS] = new TranslatedCardName("Pašerák", "Pašeráky", "Pašeráka", "Pašeráků");
CzechCardNames[CardNames.TACTICIAN] = new TranslatedCardName("Stratég", "Stratégy", "Stratéga", "Stratégů");
CzechCardNames[CardNames.TREASURE_MAP] = new TranslatedCardName("Mapa Pokladu", "Mapy pokladu", "Mapu pokladu", "Map pokladů");
CzechCardNames[CardNames.TREASURY] = new TranslatedCardName("Pokladnice", "Pokladnice", "Pokladnici", "Pokladnic");
CzechCardNames[CardNames.WAREHOUSE] = new TranslatedCardName("Skladiště", "Skladiště", "Skladiště", "Skladišť");
CzechCardNames[CardNames.WHARF] = new TranslatedCardName("Přístavní molo", "Přístavní mola", "Přístavní molo", "Přístavních mol");

CzechCardNames[CardNames.ALCHEMIST] = new TranslatedCardName("Alchymista", "Alchymisty", "Alchymistu", "Alchymistů");
CzechCardNames[CardNames.APOTHECARY] = new TranslatedCardName("Lékárník", "Lékárníky", "Lékárníka", "Lékárníků");
CzechCardNames[CardNames.APPRENTICE] = new TranslatedCardName("Učedník", "Učedníky", "Učedníka", "Osadníků");
CzechCardNames[CardNames.FAMILIAR] = new TranslatedCardName("Alchymistův poskok", "Alchymistovy poskoky", "Alchymistova poskoka", "Alchymistových poskoků");
CzechCardNames[CardNames.GOLEM] = new TranslatedCardName("Golem", "Golemy", "Golema", "Golemů");
CzechCardNames[CardNames.HERBALIST] = new TranslatedCardName("Bylinkář", "Bylinkáře", "Bylinkáře", "Bylinkářů");
CzechCardNames[CardNames.PHILOSOPHERS_STONE] = new TranslatedCardName("Kámen mudrců", "Kámen mudrců", "Kámen mudrců", "Kamenů mudrců");
CzechCardNames[CardNames.POSSESSION] = new TranslatedCardName("Posedlost", "Posedlosti", "Posedlost", "Posedlostí");
CzechCardNames[CardNames.POTION] = new TranslatedCardName("Elixír", "Elixíry", "Elixír", "Elixírů");
CzechCardNames[CardNames.SCRYING_POOL] = new TranslatedCardName("Vidění", "Vidění", "Vidění", "Vidění");
CzechCardNames[CardNames.TRANSMUTE] = new TranslatedCardName("Přeměna", "Přeměny", "Přeměnu", "Přeměn");
CzechCardNames[CardNames.UNIVERSITY] = new TranslatedCardName("Univerzita", "Univerzity", "Univerzitu", "Univerzit");
CzechCardNames[CardNames.VINEYARD] = new TranslatedCardName("Vinice", "Vinice", "Vinici", "Vinic");

CzechCardNames[CardNames.BANK] = new TranslatedCardName("Banka", "Banky", "Banku", "Bank");
CzechCardNames[CardNames.BISHOP] = new TranslatedCardName("Biskup", "Biskupy", "Biskupa", "Biskupů");
CzechCardNames[CardNames.COLONY] = new TranslatedCardName("Kolonie", "Kolonie", "Kolonii", "Kolonií");
CzechCardNames[CardNames.CONTRABAND] = new TranslatedCardName("Kontraband", "Kontrabandy", "Kontraband", "Kontrabandů");
CzechCardNames[CardNames.COUNTING_HOUSE] = new TranslatedCardName("Zastavárna", "Zastavárny", "Zastavárnu", "Zastaváren");
CzechCardNames[CardNames.CITY] = new TranslatedCardName("Město", "Města", "Město", "Měst");
CzechCardNames[CardNames.EXPAND] = new TranslatedCardName("Rozšíření", "Rozšíření", "Rozšíření", "Rozšíření");
CzechCardNames[CardNames.FORGE] = new TranslatedCardName("Kování", "Kování", "Kování", "Kování");
CzechCardNames[CardNames.GRAND_MARKET] = new TranslatedCardName("Velký trh", "Velké trhy", "Velký trh", "Velkých trhů");
CzechCardNames[CardNames.GOONS] = new TranslatedCardName("Hrdlořez", "Hrdlořezi", "Hrdlořeze", "Hrdlořezů");
CzechCardNames[CardNames.HOARD] = new TranslatedCardName("Bohatství", "Bohatství", "Bohatství", "Bohatství");
CzechCardNames[CardNames.KINGS_COURT] = new TranslatedCardName("Královský dvůr", "Královské dvory", "Královský dvůr", "Královských dvorů");
CzechCardNames[CardNames.LOAN] = new TranslatedCardName("Půjčka", "Půjčky", "Půjčku", "Půjček");
CzechCardNames[CardNames.MINT] = new TranslatedCardName("Mincovna", "Mincovny", "Mincovnu", "Mincoven");
CzechCardNames[CardNames.MONUMENT] = new TranslatedCardName("Památník", "Památníky", "Památník", "Památníků");
CzechCardNames[CardNames.MOUNTEBANK] = new TranslatedCardName("Mastičkář", "Mastičkáře", "Mastičkáře", "Mastičkářů");
CzechCardNames[CardNames.PEDDLER] = new TranslatedCardName("Podomní obchodník", "Podomní obchodníky", "Podomního obchodníka", "Podomních obchodníků");
CzechCardNames[CardNames.PLATINUM] = new TranslatedCardName("Platina", "Platiny", "Platinu", "Platin");
CzechCardNames[CardNames.QUARRY] = new TranslatedCardName("Kamenolom", "Kamenolomy", "Kamenolom", "Kamenolomů");
CzechCardNames[CardNames.RABBLE] = new TranslatedCardName("Dav", "Davy", "Dav", "Davů");
CzechCardNames[CardNames.ROYAL_SEAL] = new TranslatedCardName("Královská pečeť", "Královské pečeti", "Královskou pečeť", "Královských pečetí");
CzechCardNames[CardNames.TALISMAN] = new TranslatedCardName("Talisman", "Talismany", "Talisman", "Talismanů");
CzechCardNames[CardNames.TRADE_ROUTE] = new TranslatedCardName("Obchodní cesta", "Obchodní cesty", "Obchodní cestu", "Obchodních cest");
CzechCardNames[CardNames.VAULT] = new TranslatedCardName("Trezor", "Trezory", "Trezor", "Trezorů");
CzechCardNames[CardNames.VENTURE] = new TranslatedCardName("Odvážlivec", "Odvážlivce", "Odvážlivce", "Odvážlivců");
CzechCardNames[CardNames.WATCHTOWER] = new TranslatedCardName("Strážní věž", "Strážní věže", "Strážní věž", "Strážních věží");
CzechCardNames[CardNames.WORKERS_VILLAGE] = new TranslatedCardName("Dělnická čtvrť", "Dělnické čtvrti", "Dělnickou čtvrť", "Dělnických čtvrtí");

CzechCardNames[CardNames.PRIZE_PILE] = new TranslatedCardName("Odměna", "Odměny", "Odměnu", "Odměn");
CzechCardNames[CardNames.BAG_OF_GOLD] = new TranslatedCardName("Pytel zlata", "Pytle zlata", "Pytel zlata", "Pytlů zlata");
CzechCardNames[CardNames.DIADEM] = new TranslatedCardName("Diadém", "Diadémy", "Diadém", "Diadémů");
CzechCardNames[CardNames.FAIRGROUNDS] = new TranslatedCardName("Tržiště", "Tržiště", "Tržiště", "Tržišť");
CzechCardNames[CardNames.FARMING_VILLAGE] = new TranslatedCardName("Selský dvůr", "Selské dvory", "Selský dvůr", "Selských dvorů");
CzechCardNames[CardNames.FOLLOWERS] = new TranslatedCardName("Stoupenci", "Stoupence", "Stoupence", "Stoupenců");
CzechCardNames[CardNames.FORTUNE_TELLER] = new TranslatedCardName("Věštkyně", "Věštkyně", "Věštkyni", "Věštkyň");
CzechCardNames[CardNames.HAMLET] = new TranslatedCardName("Osada", "Osady", "Osadu", "Osudů");
CzechCardNames[CardNames.HARVEST] = new TranslatedCardName("Sklizeň", "Sklizně", "Sklizeň", "Sklizní");
CzechCardNames[CardNames.HORSE_TRADERS] = new TranslatedCardName("Konští handlíři", "Koňské handlíře", "Koňského handlíře", "Koňských handlířů");
CzechCardNames[CardNames.HORN_OF_PLENTY] = new TranslatedCardName("Roh hojnosti", "Rohy hojnosti", "Roh hojnosti", "Rohů hojnosti");
CzechCardNames[CardNames.HUNTING_PARTY] = new TranslatedCardName("Lovci", "Lovce", "Lovce", "Lovců");
CzechCardNames[CardNames.JESTER] = new TranslatedCardName("Šašek", "Šašky", "Šaška", "Šašků");
CzechCardNames[CardNames.MENAGERIE] = new TranslatedCardName("Zvěřinec", "Zvěřince", "Zvěřinec", "Zvěřinců");
CzechCardNames[CardNames.PRINCESS] = new TranslatedCardName("Princezna", "Princezny", "Princeznu", "Princezen");
CzechCardNames[CardNames.REMAKE] = new TranslatedCardName("Nové provedení", "Nové provedení", "Nové provedení", "Nových provedení");
CzechCardNames[CardNames.TOURNAMENT] = new TranslatedCardName("Turnaj", "Turnaje", "Turnaj", "Turnajů");
CzechCardNames[CardNames.TRUSTY_STEED] = new TranslatedCardName("Věrný oř", "Věrné oře", "Věrného oře", "Věrných ořů");
CzechCardNames[CardNames.YOUNG_WITCH] = new TranslatedCardName("Mladá čarodějnice", "Mladé čarodějnice", "Mladou čarodějnici", "Mladých čarodějnic");

CzechCardNames[CardNames.BORDER_VILLAGE] = new TranslatedCardName("Pohraniční vesnice", "Pohraniční vesnice", "Pohraniční vesnici", "Pohraničních vesnic");
CzechCardNames[CardNames.CACHE] = new TranslatedCardName("Úkryt", "Úkryty", "Úkryt", "Úkrytů");
CzechCardNames[CardNames.CARTOGRAPHER] = new TranslatedCardName("Kartograf", "Kartografy", "Kartografa", "Kartografů");
CzechCardNames[CardNames.CROSSROADS] = new TranslatedCardName("Křižovatka", "Křižovatky", "Křižovatku", "Křižovatek");
CzechCardNames[CardNames.DEVELOP] = new TranslatedCardName("Výstavba", "Výstavby", "Výstavbu", "Výstaveb");
CzechCardNames[CardNames.DUCHESS] = new TranslatedCardName("Vévodkyně", "Vévodkyně", "Vévodkyni", "Věvodkyň");
CzechCardNames[CardNames.EMBASSY] = new TranslatedCardName("Vyslanectví", "Vyslanectví", "Vyslanectví", "Vyslanectví");
CzechCardNames[CardNames.FARMLAND] = new TranslatedCardName("Úrodná země", "Úrodné země", "Úrodnou zemi", "Úrodných zemí");
CzechCardNames[CardNames.FOOLS_GOLD] = new TranslatedCardName("Kočičí zlato", "Kočičí zlata", "Kočičí zlato", "Kočičích zlat");
CzechCardNames[CardNames.HAGGLER] = new TranslatedCardName("Handlíř", "Handlíře", "Handlíře", "Handlířů");
CzechCardNames[CardNames.HIGHWAY] = new TranslatedCardName("Silnice", "Silnice", "Silnici", "Silnic");
CzechCardNames[CardNames.ILL_GOTTEN_GAINS] = new TranslatedCardName("Nečestné zisky", "Nečestné zisky", "Nečestné zisky", "Nečestných zisků");
CzechCardNames[CardNames.INN] = new TranslatedCardName("Hostinec", "Hostince", "Hostinec", "Hostinců");
CzechCardNames[CardNames.JACK_OF_ALL_TRADES] = new TranslatedCardName("Všeuměl", "Všeuměly", "Všeuměla", "Všeumělů");
CzechCardNames[CardNames.MANDARIN] = new TranslatedCardName("Mandarín", "Mandaríny", "Mandarína", "Mandarínů");
CzechCardNames[CardNames.NOBLE_BRIGAND] = new TranslatedCardName("Zbojník", "Zbojníky", "Zbojníka", "Zbojníků");
CzechCardNames[CardNames.NOMAD_CAMP] = new TranslatedCardName("Tábor nomádů", "Tábory nomádů", "Tábor nomádů", "Táborů nomádů");
CzechCardNames[CardNames.OASIS] = new TranslatedCardName("Oáza", "Oázy", "Oázu", "Oáz");
CzechCardNames[CardNames.ORACLE] = new TranslatedCardName("Věštírna", "Věštírny", "Věštírnu", "Věštíren");
CzechCardNames[CardNames.MARGRAVE] = new TranslatedCardName("Markrabství", "Markrabství", "Markrabství", "Markrabství");
CzechCardNames[CardNames.SCHEME] = new TranslatedCardName("Pleticha", "Pletichy", "Pletichu", "Pletich");
CzechCardNames[CardNames.SILK_ROAD] = new TranslatedCardName("Hedvábná stezka", "Hedvábné stezky", "Hedvábnou stezku", "Hedvábných stezek");
CzechCardNames[CardNames.SPICE_MERCHANT] = new TranslatedCardName("Kořenář", "Kořenáře", "Kořenáře", "Kořenářů");
CzechCardNames[CardNames.STABLES] = new TranslatedCardName("Stáje", "Stáje", "Stáj", "Stájí");
CzechCardNames[CardNames.TRADER] = new TranslatedCardName("Obchodník", "Obchodníky", "Obchodníka", "Obchodníků");
CzechCardNames[CardNames.TUNNEL] = new TranslatedCardName("Tunel", "Tunely", "Tunel", "Tulenů");

CzechCardNames[CardNames.RUIN_PILE] = new TranslatedCardName("Ruiny", "Ruiny", "Ruiny", "Ruin");
CzechCardNames[CardNames.KNIGHTS] = new TranslatedCardName("Rytíří", "Rytíře", "Rytíře", "Rytířů");
CzechCardNames[CardNames.ABANDONED_MINE] = new TranslatedCardName("Opuštěný důl", "Opuštěné doly", "Opuštěný důl", "Opuštěných dolů");
CzechCardNames[CardNames.ALTAR] = new TranslatedCardName("Oltář", "Oltáře", "Oltář", "Oltářů");
CzechCardNames[CardNames.ARMORY] = new TranslatedCardName("Zbrojnice", "Zbrojnice", "Zbrojnici", "Zbrojnic");
CzechCardNames[CardNames.BAND_OF_MISFITS] = new TranslatedCardName("Zkrachovalci", "Zkrachovalce", "Zkrachovalce", "Zkrachovalců");
CzechCardNames[CardNames.BANDIT_CAMP] = new TranslatedCardName("Tábor banditů", "Tábory banditů", "Tábor banditů", "Táborů banditů");
CzechCardNames[CardNames.BEGGAR] = new TranslatedCardName("Žebrák", "Žebráky", "Žebráka", "Žebráků");
CzechCardNames[CardNames.CATACOMBS] = new TranslatedCardName("Katakomby", "Katakomby", "Katakomby", "Katakomb");
CzechCardNames[CardNames.COUNT] = new TranslatedCardName("Hrabě", "Hrabata", "Hraběte", "Hrabět");
CzechCardNames[CardNames.COUNTERFEIT] = new TranslatedCardName("Padělek", "Padělky", "Padělek", "Padělků");
CzechCardNames[CardNames.CULTIST] = new TranslatedCardName("Vyznavač", "Vyznavače", "Vyznavače", "Vyznavačů");
CzechCardNames[CardNames.DAME_ANNA] = new TranslatedCardName("Lady Anna", "Lady Anny", "Lady Annu", "Lady Ann");
CzechCardNames[CardNames.DAME_JOSEPHINE] = new TranslatedCardName("Lady Josefína", "Lady Josefíny", "Lady Josefínu", "Lady Josefín");
CzechCardNames[CardNames.DAME_MOLLY] = new TranslatedCardName("Lady Molly", "Lady Molly", "Lady Molly", "Lady Molly");
CzechCardNames[CardNames.DAME_NATALIE] = new TranslatedCardName("Lady Natálie", "Lady Natálie", "Lady Natálie", "Lady Natálií");
CzechCardNames[CardNames.DAME_SYLVIA] = new TranslatedCardName("Lady Sylvia", "Lady Sylvia", "Lady Sylvia", "Lady Silvií");
CzechCardNames[CardNames.DEATH_CART] = new TranslatedCardName("Pohřební kára", "Pohřební káry", "Pohřební káru", "Pohřebních kár");
CzechCardNames[CardNames.FEODUM] = new TranslatedCardName("Lnové pole", "Lnové pole", "Lnové pole", "Lnových polí");
CzechCardNames[CardNames.FORAGER] = new TranslatedCardName("Sběrač", "Sběrače", "Sběrače", "Sběračů");
CzechCardNames[CardNames.FORTRESS] = new TranslatedCardName("Pevnost", "Pevnosti", "Pevnost", "Pevností");
CzechCardNames[CardNames.GRAVEROBBER] = new TranslatedCardName("Vykradač hrobů", "Vykradače hrobů", "Vykradače hrobů", "Vykradačů hrobů");
CzechCardNames[CardNames.HERMIT] = new TranslatedCardName("Poustevník", "Poustevníky", "Poustevníka", "Poustevníků");
CzechCardNames[CardNames.HOVEL] = new TranslatedCardName("Chýše", "Chýše", "Chýše", "Chýší");
CzechCardNames[CardNames.HUNTING_GROUNDS] = new TranslatedCardName("Revír", "Revíry", "Revír", "Revírů");
CzechCardNames[CardNames.IRONMONGER] = new TranslatedCardName("Železářství", "Železářství", "Železářství", "Železářství");
CzechCardNames[CardNames.JUNK_DEALER] = new TranslatedCardName("Vetešník", "Vetešníky", "Vetešníka", "Vetešníků");
CzechCardNames[CardNames.MADMAN] = new TranslatedCardName("Šílenec", "Šílence", "Šílence", "Šílenců");
CzechCardNames[CardNames.MARKET_SQUARE] = new TranslatedCardName("Náměstí", "Náměstí", "Náměstí", "Náměstí");
CzechCardNames[CardNames.MARAUDER] = new TranslatedCardName("Nájezdník", "Nájezdníky", "Nájezdníka", "Nájezdníků");
CzechCardNames[CardNames.MERCENARY] = new TranslatedCardName("Žoldnéř", "Žoldnéře", "Žoldnéře", "Žoldnéřů");
CzechCardNames[CardNames.MYSTIC] = new TranslatedCardName("Šamanka", "Šamanky", "Šamanku", "Šamanek");
CzechCardNames[CardNames.NECROPOLIS] = new TranslatedCardName("Vylidněné město", "Vylidněná města", "Vylidněné město", "Vilidněných měst");
CzechCardNames[CardNames.OVERGROWN_ESTATE] = new TranslatedCardName("Zpustlý statek", "Zpustlé statky", "Zpustlý statek", "Zpustlých statků");
CzechCardNames[CardNames.PILLAGE] = new TranslatedCardName("Drancování", "Drancování", "Drancování", "Drancování");
CzechCardNames[CardNames.POOR_HOUSE] = new TranslatedCardName("Chudobinec", "Chudobince", "Chudobinec", "Chudobinců");
CzechCardNames[CardNames.PROCESSION] = new TranslatedCardName("Proces", "Procesy", "Proces", "Procesů");
CzechCardNames[CardNames.RATS] = new TranslatedCardName("Krysy", "Krysy", "Krysy", "Krys");
CzechCardNames[CardNames.REBUILD] = new TranslatedCardName("Obnova", "Obnovy", "Obnovu", "Obnov");
CzechCardNames[CardNames.ROGUE] = new TranslatedCardName("Padouch", "Padouchy", "Padoucha", "Padouchů");
CzechCardNames[CardNames.RUINED_LIBRARY] = new TranslatedCardName("Zničená knihovna", "Zničené knihovny", "Zničenou knihovnu", "Zničených knihoven");
CzechCardNames[CardNames.RUINED_MARKET] = new TranslatedCardName("Zničený trh", "Zničené trhy", "Zničený trh", "Zničených trhů");
CzechCardNames[CardNames.RUINED_VILLAGE] = new TranslatedCardName("Vypálená vesnice", "Vypálené vesnice", "Vypálenou vesnici", "Vypálených vesnic");
CzechCardNames[CardNames.SAGE] = new TranslatedCardName("Mudrc", "Mudrce", "Mudrce", "Mudrců");
CzechCardNames[CardNames.SCAVENGER] = new TranslatedCardName("Sběratel", "Sběratele", "Sběratele", "Sběratelů");
CzechCardNames[CardNames.SIR_BAILEY] = new TranslatedCardName("Sir Bailey", "Siry Bailey", "Sira Bailey", "Sirů Bailey");
CzechCardNames[CardNames.SIR_DESTRY] = new TranslatedCardName("Sir Destry", "Siry Destry", "Sira Destry", "Sirů Destry");
CzechCardNames[CardNames.SIR_MARTIN] = new TranslatedCardName("Sir Martin", "Siry Martiny", "Sira Martina", "Sirů Martinů");
CzechCardNames[CardNames.SIR_MICHAEL] = new TranslatedCardName("Sir Michael", "Siry Michaely", "Sira Michaela", "Sirů Michaelů");
CzechCardNames[CardNames.SIR_VANDER] = new TranslatedCardName("Sir Vander", "Siry Vandery", "Sira Vandera", "Sirů Vanderů");
CzechCardNames[CardNames.SPOILS] = new TranslatedCardName("Kořist", "Kořisti", "Kořist", "Kořistí");
CzechCardNames[CardNames.STOREROOM] = new TranslatedCardName("Komora", "Komory", "Komoru", "Komor");
CzechCardNames[CardNames.SQUIRE] = new TranslatedCardName("Panoš", "Panoše", "Panoše", "Panošů");
CzechCardNames[CardNames.SURVIVORS] = new TranslatedCardName("Pozůstalí", "Pozůstalí", "Pozůstalí", "Pozůstalých");
CzechCardNames[CardNames.URCHIN] = new TranslatedCardName("Darebák", "Darebáky", "Darebáka", "Darebáků");
CzechCardNames[CardNames.VAGRANT] = new TranslatedCardName("Tulák", "Tuláky", "Tuláka", "Tuláků");
CzechCardNames[CardNames.WANDERING_MINSTREL] = new TranslatedCardName("Bard", "Bardy", "Barda", "Bardů");

CzechCardNames[CardNames.ADVISOR] = new TranslatedCardName("Advisor", "Advisors", "an Advisor", "");
CzechCardNames[CardNames.BAKER] = new TranslatedCardName("Baker", "Bakers", "a Baker", "");
CzechCardNames[CardNames.BUTCHER] = new TranslatedCardName("Butcher", "Butchers", "a Butcher", "");
CzechCardNames[CardNames.CANDLESTICK_MAKER] = new TranslatedCardName("Candlestick Maker", "Candlestick Makers", "a Candlestick Maker", "");
CzechCardNames[CardNames.DOCTOR] = new TranslatedCardName("Doctor", "Doctors", "a Doctor", "");
CzechCardNames[CardNames.HERALD] = new TranslatedCardName("Herald", "Heralds", "a Herald", "");
CzechCardNames[CardNames.JOURNEYMAN] = new TranslatedCardName("Journeyman", "Journeymen", "a Journeyman", "");
CzechCardNames[CardNames.MASTERPIECE] = new TranslatedCardName("Masterpiece", "Masterpieces", "a Masterpiece", "");
CzechCardNames[CardNames.MERCHANT_GUILD] = new TranslatedCardName("Merchant Guild", "Merchant Guilds", "a Merchant Guild", "");
CzechCardNames[CardNames.PLAZA] = new TranslatedCardName("Plaza", "Plazas", "a Plaza", "");
CzechCardNames[CardNames.TAXMAN] = new TranslatedCardName("Taxman", "Taxmen", "a Taxman", "");
CzechCardNames[CardNames.SOOTHSAYER] = new TranslatedCardName("Soothsayer", "Soothsayers", "a Soothsayer", "");
CzechCardNames[CardNames.STONEMASON] = new TranslatedCardName("Stonemason", "Stonemasons", "a Stonemason", "");

CzechCardNames[CardNames.ALMS] = new TranslatedCardName("Almužna", "Almužny", "Almužnu", "");
CzechCardNames[CardNames.AMULET] = new TranslatedCardName("Amulet", "Amulets", "an Amulet", "");
CzechCardNames[CardNames.ARTIFICER] = new TranslatedCardName("Řemeslník", "Řemeslníky", "Řemeslníka", "");
CzechCardNames[CardNames.BALL] = new TranslatedCardName("Ball", "Balls", "a Ball", "");
CzechCardNames[CardNames.BONFIRE] = new TranslatedCardName("Bonfire", "Bonfires", "a Bonfire", "");
CzechCardNames[CardNames.BORROW] = new TranslatedCardName("Dlužník", "Dlužníky", "Dlužníka", "");
CzechCardNames[CardNames.BRIDGE_TROLL] = new TranslatedCardName("Bridge Troll", "Bridge Trolls", "a Bridge Troll", "");
CzechCardNames[CardNames.CARAVAN_GUARD] = new TranslatedCardName("Caravan Guard", "Caravan Guards", "a Caravan Guard", "");
CzechCardNames[CardNames.CHAMPION] = new TranslatedCardName("Champion", "Champions", "a Champion", "");
CzechCardNames[CardNames.COIN_OF_THE_REALM] = new TranslatedCardName("Coin of the Realm", "Coins of the Realm", "a Coin of the Realm", "");
CzechCardNames[CardNames.DISCIPLE] = new TranslatedCardName("Disciple", "Disciples", "a Disciple", "");
CzechCardNames[CardNames.DISTANT_LANDS] = new TranslatedCardName("Distant Lands", "Distant Lands", "a Distant Lands", "");
CzechCardNames[CardNames.DUNGEON] = new TranslatedCardName("Dungeon", "Dungeons", "a Dungeon", "");
CzechCardNames[CardNames.DUPLICATE] = new TranslatedCardName("Duplicate", "Duplicates", "a Duplicate", "");
CzechCardNames[CardNames.EXPEDITION] = new TranslatedCardName("Expedition", "Expeditions", "an Expedition", "");
CzechCardNames[CardNames.FERRY] = new TranslatedCardName("Ferry", "Ferries", "a Ferry", "");
CzechCardNames[CardNames.FUGITIVE] = new TranslatedCardName("Fugitive", "Fugitives", "a Fugitive", "");
CzechCardNames[CardNames.GEAR] = new TranslatedCardName("Gear", "Gears", "a Gear", "");
CzechCardNames[CardNames.GIANT] = new TranslatedCardName("Giant", "Giants", "a Giant", "");
CzechCardNames[CardNames.GUIDE] = new TranslatedCardName("Guide", "Guides", "a Guide", "");
CzechCardNames[CardNames.HAUNTED_WOODS] = new TranslatedCardName("Haunted Woods", "Haunted Woods", "a Haunted Woods", "");
CzechCardNames[CardNames.HERO] = new TranslatedCardName("Hero", "Heroes", "a Hero", "");
CzechCardNames[CardNames.HIRELING] = new TranslatedCardName("Hireling", "Hirelings", "a Hireling", "");
CzechCardNames[CardNames.INHERITANCE] = new TranslatedCardName("Inheritance", "Inheritances", "an Inheritance", "");
CzechCardNames[CardNames.LOST_ARTS] = new TranslatedCardName("Lost Arts", "Lost Arts", "a Lost Arts", "");
CzechCardNames[CardNames.LOST_CITY] = new TranslatedCardName("Lost City", "Lost Cities", "a Lost City", "");
CzechCardNames[CardNames.MAGPIE] = new TranslatedCardName("Magpie", "Magpies", "a Magpie", "");
CzechCardNames[CardNames.MESSENGER] = new TranslatedCardName("Messenger", "Messengers", "a Messenger", "");
CzechCardNames[CardNames.MISER] = new TranslatedCardName("Miser", "Misers", "a Miser", "");
CzechCardNames[CardNames.MISSION] = new TranslatedCardName("Poslání", "Poslání", "Poslání", "");
CzechCardNames[CardNames.PATHFINDING] = new TranslatedCardName("Pathfinding", "Pathfindings", "a Pathfinding", "");
CzechCardNames[CardNames.PAGE] = new TranslatedCardName("Page", "Pages", "a Page", "");
CzechCardNames[CardNames.PEASANT] = new TranslatedCardName("Peasant", "Peasants", "a Peasant", "");
CzechCardNames[CardNames.PILGRIMAGE] = new TranslatedCardName("Pilgrimage", "Pilgrimages", "a Pilgrimage", "");
CzechCardNames[CardNames.PLAN] = new TranslatedCardName("Plan", "Plans", "a Plan", "");
CzechCardNames[CardNames.PORT] = new TranslatedCardName("Port", "Ports", "a Port", "");
CzechCardNames[CardNames.QUEST] = new TranslatedCardName("Quest", "Quests", "a Quest", "");
CzechCardNames[CardNames.RANGER] = new TranslatedCardName("Ranger", "Rangers", "a Ranger", "");
CzechCardNames[CardNames.RAID] = new TranslatedCardName("Raid", "Raids", "a Raid", "");
CzechCardNames[CardNames.RATCATCHER] = new TranslatedCardName("Ratcatcher", "Ratcatchers", "a Ratcatcher", "");
CzechCardNames[CardNames.RAZE] = new TranslatedCardName("Raze", "Razes", "a Raze", "");
CzechCardNames[CardNames.RELIC] = new TranslatedCardName("Relic", "Relics", "a Relic", "");
CzechCardNames[CardNames.ROYAL_CARRIAGE] = new TranslatedCardName("Royal Carriage", "Royal Carriages", "a Royal Carriage", "");
CzechCardNames[CardNames.SAVE] = new TranslatedCardName("Save", "Saves", "a Save", "");
CzechCardNames[CardNames.SCOUTING_PARTY] = new TranslatedCardName("Scouting Party", "Scouting Parties", "a Scouting Party", "");
CzechCardNames[CardNames.SEAWAY] = new TranslatedCardName("Seaway", "Seaways", "a Seaway", "");
CzechCardNames[CardNames.SOLDIER] = new TranslatedCardName("Soldier", "Soldiers", "a Soldier", "");
CzechCardNames[CardNames.STORYTELLER] = new TranslatedCardName("Storyteller", "Storytellers", "a Storyteller", "");
CzechCardNames[CardNames.SWAMP_HAG] = new TranslatedCardName("Swamp Hag", "Swamp Hags", "a Swamp Hag", "");
CzechCardNames[CardNames.TEACHER] = new TranslatedCardName("Teacher", "Teachers", "a Teacher", "");
CzechCardNames[CardNames.TRAVELLING_FAIR] = new TranslatedCardName("Travelling Fair", "Travelling Fairs", "a Travelling Fair", "");
CzechCardNames[CardNames.TRADE] = new TranslatedCardName("Trade", "Trades", "a Trade", "");
CzechCardNames[CardNames.TRAINING] = new TranslatedCardName("Training", "Trainings", "a Training", "");
CzechCardNames[CardNames.TRANSMOGRIFY] = new TranslatedCardName("Transmogrify", "Transmogrifies", "a Transmogrify", "");
CzechCardNames[CardNames.TREASURE_TROVE] = new TranslatedCardName("Treasure Trove", "Treasure Troves", "a Treasure Trove", "");
CzechCardNames[CardNames.TREASURE_HUNTER] = new TranslatedCardName("Treasure Hunter", "Treasure Hunters", "a Treasure Hunter", "");
CzechCardNames[CardNames.WARRIOR] = new TranslatedCardName("Warrior", "Warriors", "a Warrior", "");
CzechCardNames[CardNames.WINE_MERCHANT] = new TranslatedCardName("Wine Merchant", "Wine Merchants", "a Wine Merchant", "");

CzechCardNames[CardNames.ENCAMPMENT] = new TranslatedCardName("Encampment", "Encampments", "an Encampment", "");
CzechCardNames[CardNames.PLUNDER] = new TranslatedCardName("Plunder", "Plunders", "a Plunder", "");
CzechCardNames[CardNames.PATRICIAN] = new TranslatedCardName("Patrician", "Patricians", "a Patrician", "");
CzechCardNames[CardNames.EMPORIUM] = new TranslatedCardName("Emporium", "Emporia", "an Emporium", "");
CzechCardNames[CardNames.SETTLERS] = new TranslatedCardName("Settlers", "Settlers", "a Settlers", "");
CzechCardNames[CardNames.BUSTLING_VILLAGE] = new TranslatedCardName("Bustling Village", "Bustling Villages", "a Bustling Village", "");
CzechCardNames[CardNames.CATAPULT] = new TranslatedCardName("Catapult", "Catapults", "a Catapult", "");
CzechCardNames[CardNames.ROCKS] = new TranslatedCardName("Rocks", "Rocks", "a Rocks", "");
CzechCardNames[CardNames.GLADIATOR] = new TranslatedCardName("Gladiator", "Gladiators", "a Gladiator", "");
CzechCardNames[CardNames.FORTUNE] = new TranslatedCardName("Fortune", "Fortunes", "a Fortune", "");
CzechCardNames[CardNames.CASTLES] = new TranslatedCardName("Castles", "Castles", "a Castles", "");
CzechCardNames[CardNames.HUMBLE_CASTLE] = new TranslatedCardName("Humble Castle", "Humble Castles", "a Humble Castle", "");
CzechCardNames[CardNames.CRUMBLING_CASTLE] = new TranslatedCardName("Crumbling Castle", "Crumbling Castles", "a Crumbling Castle", "");
CzechCardNames[CardNames.SMALL_CASTLE] = new TranslatedCardName("Small Castle", "Small Castles", "a Small Castle", "");
CzechCardNames[CardNames.HAUNTED_CASTLE] = new TranslatedCardName("Haunted Castle", "Haunted Castles", "a Haunted Castle", "");
CzechCardNames[CardNames.OPULENT_CASTLE] = new TranslatedCardName("Opulent Castle", "Opulent Castles", "an Opulent Castle", "");
CzechCardNames[CardNames.SPRAWLING_CASTLE] = new TranslatedCardName("Sprawling Castle", "Sprawling Castles", "a Sprawling Castle", "");
CzechCardNames[CardNames.GRAND_CASTLE] = new TranslatedCardName("Grand Castle", "Grand Castles", "a Grand Castle", "");
CzechCardNames[CardNames.KINGS_CASTLE] = new TranslatedCardName("King's Castle", "King's Castles", "a King's Castle", "");
CzechCardNames[CardNames.ADVANCE] = new TranslatedCardName("Advance", "Advances", "an Advance", "");
CzechCardNames[CardNames.ANNEX] = new TranslatedCardName("Annex", "Annexes", "an Annex", "");
CzechCardNames[CardNames.ARCHIVE] = new TranslatedCardName("Archive", "Archives", "an Archive", "");
CzechCardNames[CardNames.AQUEDUCT] = new TranslatedCardName("Aqueduct", "Aqueducts", "an Aqueduct", "");
CzechCardNames[CardNames.ARENA] = new TranslatedCardName("Arena", "Arenas", "an Arena", "");
CzechCardNames[CardNames.BANDIT_FORT] = new TranslatedCardName("Bandit Fort", "Bandit Forts", "a Bandit Fort", "");
CzechCardNames[CardNames.BANQUET] = new TranslatedCardName("Banquet", "Banquets", "a Banquet", "");
CzechCardNames[CardNames.BASILICA] = new TranslatedCardName("Basilica", "Basilicas", "a Basilica", "");
CzechCardNames[CardNames.BATHS] = new TranslatedCardName("Baths", "Baths", "a Baths", "");
CzechCardNames[CardNames.BATTLEFIELD] = new TranslatedCardName("Battlefield", "Battlefields", "a Battlefield", "");
CzechCardNames[CardNames.CAPITAL] = new TranslatedCardName("Capital", "Capitals", "a Capital", "");
CzechCardNames[CardNames.CHARM] = new TranslatedCardName("Charm", "Charms", "a Charm", "");
CzechCardNames[CardNames.CHARIOT_RACE] = new TranslatedCardName("Chariot Race", "Chariot Races", "a Chariot Race", "");
CzechCardNames[CardNames.CITY_QUARTER] = new TranslatedCardName("City Quarter", "City Quarters", "a City Quarter", "");
CzechCardNames[CardNames.COLONNADE] = new TranslatedCardName("Colonnade", "Colonnades", "a Colonnade", "");
CzechCardNames[CardNames.CONQUEST] = new TranslatedCardName("Conquest", "Conquests", "a Conquest", "");
CzechCardNames[CardNames.CROWN] = new TranslatedCardName("Crown", "Crowns", "a Crown", "");
CzechCardNames[CardNames.DELVE] = new TranslatedCardName("Delve", "Delves", "a Delve", "");
CzechCardNames[CardNames.DEFILED_SHRINE] = new TranslatedCardName("Defiled Shrine", "Defiled Shrines", "a Defiled Shrine", "");
CzechCardNames[CardNames.DOMINATE] = new TranslatedCardName("Dominate", "Dominates", "a Dominate", "");
CzechCardNames[CardNames.DONATE] = new TranslatedCardName("Donate", "Donates", "a Donate", "");
CzechCardNames[CardNames.ENCHANTRESS] = new TranslatedCardName("Enchantress", "Enchantresses", "an Enchantress", "");
CzechCardNames[CardNames.ENGINEER] = new TranslatedCardName("Inženýr", "Inženýry", "Inženýra", "");
CzechCardNames[CardNames.FARMERS_MARKET] = new TranslatedCardName("Farmers' Market", "Farmers' Markets", "a Farmers' Market", "");
CzechCardNames[CardNames.FORUM] = new TranslatedCardName("Forum", "Forums", "a Forum", "");
CzechCardNames[CardNames.FOUNTAIN] = new TranslatedCardName("Fountain", "Fountains", "a Fountain", "");
CzechCardNames[CardNames.GROUNDSKEEPER] = new TranslatedCardName("Strážce země", "Strážce země", "Strážce země", "");
CzechCardNames[CardNames.KEEP] = new TranslatedCardName("Keep", "Keeps", "a Keep", "");
CzechCardNames[CardNames.LABYRINTH] = new TranslatedCardName("Labyrinth", "Labyrinths", "a Labyrinth", "");
CzechCardNames[CardNames.LEGIONARY] = new TranslatedCardName("Legionary", "Legionaries", "a Legionary", "");
CzechCardNames[CardNames.MOUNTAIN_PASS] = new TranslatedCardName("Mountain Pass", "Mountain Passes", "a Mountain Pass", "");
CzechCardNames[CardNames.MUSEUM] = new TranslatedCardName("Museum", "Museums", "a Museum", "");
CzechCardNames[CardNames.OBELISK] = new TranslatedCardName("Obelisk", "Obelisks", "an Obelisk", "");
CzechCardNames[CardNames.ORCHARD] = new TranslatedCardName("Orchard", "Orchards", "an Orchard", "");
CzechCardNames[CardNames.OVERLORD] = new TranslatedCardName("Overlord", "Overlords", "an Overlord", "");
CzechCardNames[CardNames.PALACE] = new TranslatedCardName("Palace", "Palaces", "a Palace", "");
CzechCardNames[CardNames.RITUAL] = new TranslatedCardName("Ritual", "Rituals", "a Ritual", "");
CzechCardNames[CardNames.ROYAL_BLACKSMITH] = new TranslatedCardName("Royal Blacksmith", "Royal Blacksmiths", "a Royal Blacksmith", "");
CzechCardNames[CardNames.SACRIFICE] = new TranslatedCardName("Sacrifice", "Sacrifices", "a Sacrifice", "");
CzechCardNames[CardNames.SALT_THE_EARTH] = new TranslatedCardName("Salt the Earth", "Salt the Earths", "a Salt the Earth", "");
CzechCardNames[CardNames.TAX] = new TranslatedCardName("Tax", "Taxes", "a Tax", "");
CzechCardNames[CardNames.TEMPLE] = new TranslatedCardName("Temple", "Temples", "a Temple", "");
CzechCardNames[CardNames.TOMB] = new TranslatedCardName("Tomb", "Tombs", "a Tomb", "");
CzechCardNames[CardNames.TOWER] = new TranslatedCardName("Tower", "Towers", "a Tower", "");
CzechCardNames[CardNames.TRIUMPH] = new TranslatedCardName("Triumph", "Triumphs", "a Triumph", "");
CzechCardNames[CardNames.TRIUMPHAL_ARCH] = new TranslatedCardName("Triumphal Arch", "Triumphal Arches", "a Triumphal Arch", "");
CzechCardNames[CardNames.VILLA] = new TranslatedCardName("Villa", "Villas", "a Villa", "");
CzechCardNames[CardNames.WALL] = new TranslatedCardName("Wall", "Walls", "a Wall", "");
CzechCardNames[CardNames.WOLF_DEN] = new TranslatedCardName("Wolf Den", "Wolf Dens", "a Wolf Den", "");
CzechCardNames[CardNames.WEDDING] = new TranslatedCardName("Wedding", "Weddings", "a Wedding", "");
CzechCardNames[CardNames.WILD_HUNT] = new TranslatedCardName("Wild Hunt", "Wild Hunts", "a Wild Hunt", "");
CzechCardNames[CardNames.WINDFALL] = new TranslatedCardName("Windfall", "Windfalls", "a Windfall", "");

CzechCardNames[CardNames.BOON_DRAWPILE] = new TranslatedCardName("Boon Drawpile", "Boon Drawpiles", "a Boon Drawpile", "");
CzechCardNames[CardNames.BOON_DISCARDPILE] = new TranslatedCardName("Boon Discardpile", "Boon Discardpiles", "a Boon Discardpile", "");
CzechCardNames[CardNames.THE_EARTHS_GIFT] = new TranslatedCardName("The Earth's Gift", "The Earth's Gifts", "The Earth's Gift", "");
CzechCardNames[CardNames.THE_FIELDS_GIFT] = new TranslatedCardName("The Field's Gift", "The Field's Gifts", "The Field's Gift", "");
CzechCardNames[CardNames.THE_FLAMES_GIFT] = new TranslatedCardName("The Flame's Gift", "The Flame's Gifts", "The Flame's Gift", "");
CzechCardNames[CardNames.THE_FORESTS_GIFT] = new TranslatedCardName("The Forest's Gift", "The Forest's Gifts", "The Forest's Gift", "");
CzechCardNames[CardNames.THE_MOONS_GIFT] = new TranslatedCardName("The Moon's Gift", "The Moon's Gifts", "The Moon's Gift", "");
CzechCardNames[CardNames.THE_MOUNTAINS_GIFT] = new TranslatedCardName("The Mountain's Gift", "The Mountain's Gifts", "The Mountain's Gift", "");
CzechCardNames[CardNames.THE_RIVERS_GIFT] = new TranslatedCardName("The River's Gift", "The River's Gifts", "The River's Gift", "");
CzechCardNames[CardNames.THE_SEAS_GIFT] = new TranslatedCardName("The Sea's Gift", "The Sea's Gifts", "The Sea's Gift", "");
CzechCardNames[CardNames.THE_SKYS_GIFT] = new TranslatedCardName("The Sky's Gift", "The Sky's Gifts", "The Sky's Gift", "");
CzechCardNames[CardNames.THE_SUNS_GIFT] = new TranslatedCardName("The Sun's Gift", "The Sun's Gifts", "The Sun's Gift", "");
CzechCardNames[CardNames.THE_SWAMPS_GIFT] = new TranslatedCardName("The Swamp's Gift", "The Swamp's Gifts", "The Swamp's Gift", "");
CzechCardNames[CardNames.THE_WINDS_GIFT] = new TranslatedCardName("The Wind's Gift", "The Wind's Gifts", "The Wind's Gift", "");
CzechCardNames[CardNames.HEX_DRAWPILE] = new TranslatedCardName("Hex Drawpile", "Hex Drawpiles", "a Hex Drawpile", "");
CzechCardNames[CardNames.HEX_DISCARDPILE] = new TranslatedCardName("Hex Discardpile", "Hex Discardpiles", "a Hex Discardpile", "");
CzechCardNames[CardNames.BAD_OMENS] = new TranslatedCardName("Bad Omens", "Bad Omens", "Bad Omens", "");
CzechCardNames[CardNames.DELUSION] = new TranslatedCardName("Delusion", "Delusions", "Delusion", "");
CzechCardNames[CardNames.ENVY] = new TranslatedCardName("Envy", "Envies", "Envy", "");
CzechCardNames[CardNames.FAMINE] = new TranslatedCardName("Famine", "Famines", "Famine", "");
CzechCardNames[CardNames.FEAR] = new TranslatedCardName("Fear", "Fears", "Fear", "");
CzechCardNames[CardNames.GREED] = new TranslatedCardName("Greed", "Greeds", "Greed", "");
CzechCardNames[CardNames.HAUNTING] = new TranslatedCardName("Haunting", "Hauntings", "Haunting", "");
CzechCardNames[CardNames.LOCUSTS] = new TranslatedCardName("Locusts", "Locusts", "Locusts", "");
CzechCardNames[CardNames.MISERY] = new TranslatedCardName("Misery", "Miseries", "Misery", "");
CzechCardNames[CardNames.PLAGUE] = new TranslatedCardName("Plague", "Plagues", "Plague", "");
CzechCardNames[CardNames.POVERTY] = new TranslatedCardName("Poverty", "Poverties", "Poverty", "");
CzechCardNames[CardNames.WAR] = new TranslatedCardName("War", "Wars", "War", "");
CzechCardNames[CardNames.MISERABLE] = new TranslatedCardName("Miserable", "Miserables", "Miserable", "");
CzechCardNames[CardNames.TWICE_MISERABLE] = new TranslatedCardName("Twice Miserable", "Twice Miserables", "Twice Miserable", "");
CzechCardNames[CardNames.ENVIOUS] = new TranslatedCardName("Envious", "Envious", "Envious", "");
CzechCardNames[CardNames.DELUDED] = new TranslatedCardName("Deluded", "Deludeds", "Deluded", "");
CzechCardNames[CardNames.LOST_IN_THE_WOODS] = new TranslatedCardName("Lost In The Woods", "Lost In The Woods", "Lost In The Woods", "");
CzechCardNames[CardNames.BARD] = new TranslatedCardName("Bard", "Bards", "a Bard", "");
CzechCardNames[CardNames.BLESSED_VILLAGE] = new TranslatedCardName("Blessed Village", "Blessed Villages", "a Blessed Village", "");
CzechCardNames[CardNames.CHANGELING] = new TranslatedCardName("Changeling", "Changelings", "a Changeling", "");
CzechCardNames[CardNames.CEMETERY] = new TranslatedCardName("Cemetery", "Cemeteries", "a Cemetery", "");
CzechCardNames[CardNames.COBBLER] = new TranslatedCardName("Cobbler", "Cobblers", "a Cobbler", "");
CzechCardNames[CardNames.CONCLAVE] = new TranslatedCardName("Conclave", "Conclaves", "a Conclave", "");
CzechCardNames[CardNames.CRYPT] = new TranslatedCardName("Crypt", "Crypts", "a Crypt", "");
CzechCardNames[CardNames.CURSED_VILLAGE] = new TranslatedCardName("Cursed Village", "Cursed Villages", "a Cursed Village", "");
CzechCardNames[CardNames.DEN_OF_SIN] = new TranslatedCardName("Den of Sin", "Dens of Sin", "a Den of Sin", "");
CzechCardNames[CardNames.DEVILS_WORKSHOP] = new TranslatedCardName("Devil's Workshop", "Devil's Workshops", "a Devil's Workshop", "");
CzechCardNames[CardNames.DRUID] = new TranslatedCardName("Druid", "Druids", "a Druid", "");
CzechCardNames[CardNames.EXORCIST] = new TranslatedCardName("Exorcist", "Exorcists", "an Exorcist", "");
CzechCardNames[CardNames.FAITHFUL_HOUND] = new TranslatedCardName("Faithful Hound", "Faithful Hounds", "a Faithful Hound", "");
CzechCardNames[CardNames.FOOL] = new TranslatedCardName("Fool", "Fools", "a Fool", "");
CzechCardNames[CardNames.GHOST_TOWN] = new TranslatedCardName("Ghost Town", "Ghost Towns", "a Ghost Town", "");
CzechCardNames[CardNames.GUARDIAN] = new TranslatedCardName("Guardian", "Guardians", "a Guardian", "");
CzechCardNames[CardNames.IDOL] = new TranslatedCardName("Idol", "Idols", "an Idol", "");
CzechCardNames[CardNames.LEPRECHAUN] = new TranslatedCardName("Leprechaun", "Leprechauns", "a Leprechaun", "");
CzechCardNames[CardNames.MONASTERY] = new TranslatedCardName("Monastery", "Monasteries", "a Monastery", "");
CzechCardNames[CardNames.NECROMANCER] = new TranslatedCardName("Necromancer", "Necromancers", "a Necromancer", "");
CzechCardNames[CardNames.NIGHT_WATCHMAN] = new TranslatedCardName("Night Watchman", "Night Watchmen", "a Night Watchman", "");
CzechCardNames[CardNames.PIXIE] = new TranslatedCardName("Pixie", "Pixies", "a Pixie", "");
CzechCardNames[CardNames.POOKA] = new TranslatedCardName("Pooka", "Pookas", "a Pooka", "");
CzechCardNames[CardNames.RAIDER] = new TranslatedCardName("Raider", "Raiders", "a Raider", "");
CzechCardNames[CardNames.SACRED_GROVE] = new TranslatedCardName("Sacred Grove", "Sacred Groves", "a Sacred Grove", "");
CzechCardNames[CardNames.SECRET_CAVE] = new TranslatedCardName("Secret Cave", "Secret Caves", "a Secret Cave", "");
CzechCardNames[CardNames.SHEPHERD] = new TranslatedCardName("Shepherd", "Shepherds", "a Shepherd", "");
CzechCardNames[CardNames.SKULK] = new TranslatedCardName("Skulk", "Skulks", "a Skulk", "");
CzechCardNames[CardNames.TORMENTOR] = new TranslatedCardName("Tormentor", "Tormentors", "a Tormentor", "");
CzechCardNames[CardNames.TRAGIC_HERO] = new TranslatedCardName("Tragic Hero", "Tragic Heroes", "a Tragic Hero", "");
CzechCardNames[CardNames.TRACKER] = new TranslatedCardName("Tracker", "Trackers", "a Tracker", "");
CzechCardNames[CardNames.VAMPIRE] = new TranslatedCardName("Vampire", "Vampires", "a Vampire", "");
CzechCardNames[CardNames.WEREWOLF] = new TranslatedCardName("Werewolf", "Werewolves", "a Werewolf", "");
CzechCardNames[CardNames.CURSED_GOLD] = new TranslatedCardName("Cursed Gold", "Cursed Golds", "a Cursed Gold", "");
CzechCardNames[CardNames.GOAT] = new TranslatedCardName("Goat", "Goats", "a Goat", "");
CzechCardNames[CardNames.HAUNTED_MIRROR] = new TranslatedCardName("Haunted Mirror", "Haunted Mirrors", "a Haunted Mirror", "");
CzechCardNames[CardNames.LUCKY_COIN] = new TranslatedCardName("Lucky Coin", "Lucky Coins", "a Lucky Coin", "");
CzechCardNames[CardNames.MAGIC_LAMP] = new TranslatedCardName("Magic Lamp", "Magic Lamps", "a Magic Lamp", "");
CzechCardNames[CardNames.PASTURE] = new TranslatedCardName("Pasture", "Pastures", "a Pasture", "");
CzechCardNames[CardNames.POUCH] = new TranslatedCardName("Pouch", "Pouches", "a Pouch", "");
CzechCardNames[CardNames.BAT] = new TranslatedCardName("Bat", "Bats", "a Bat", "");
CzechCardNames[CardNames.GHOST] = new TranslatedCardName("Ghost", "Ghosts", "a Ghost", "");
CzechCardNames[CardNames.IMP] = new TranslatedCardName("Imp", "Imps", "an Imp", "");
CzechCardNames[CardNames.WILL_O_WISP] = new TranslatedCardName("Will-o'-Wisp", "Will-o'-Wisps", "a Will-o'-Wisp", "");
CzechCardNames[CardNames.WISH] = new TranslatedCardName("Wish", "Wishes", "a Wish", "");
CzechCardNames[CardNames.ZOMBIE_APPRENTICE] = new TranslatedCardName("Zombie Apprentice", "Zombie Apprentices", "a Zombie Apprentice", "");
CzechCardNames[CardNames.ZOMBIE_MASON] = new TranslatedCardName("Zombie Mason", "Zombie Masons", "a Zombie Mason", "");
CzechCardNames[CardNames.ZOMBIE_SPY] = new TranslatedCardName("Zombie Spy", "Zombie Spies", "a Zombie Spy", "");

CzechCardNames[CardNames.ACTING_TROUPE] = new TranslatedCardName("Acting Troupe", "Acting Troupes", "an Acting Troupe", "");
CzechCardNames[CardNames.BORDER_GUARD] = new TranslatedCardName("Border Guard", "Border Guards", "a Border Guard", "");
CzechCardNames[CardNames.CARGO_SHIP] = new TranslatedCardName("Cargo Ship", "Cargo Ships", "a Cargo Ship", "");
CzechCardNames[CardNames.DUCAT] = new TranslatedCardName("Ducat", "Ducats", "a Ducat", "");
CzechCardNames[CardNames.EXPERIMENT] = new TranslatedCardName("Experiment", "Experiments", "an Experiment", "");
CzechCardNames[CardNames.FLAG_BEARER] = new TranslatedCardName("Flag Bearer", "Flag Bearers", "a Flag Bearer", "");
CzechCardNames[CardNames.HIDEOUT] = new TranslatedCardName("Hideout", "Hideouts", "a Hideout", "");
CzechCardNames[CardNames.INVENTOR] = new TranslatedCardName("Inventor", "Inventors", "an Inventor", "");
CzechCardNames[CardNames.IMPROVE] = new TranslatedCardName("Improve", "Improves", "an Improve", "");
CzechCardNames[CardNames.LACKEYS] = new TranslatedCardName("Lackeys", "Lackeys", "a Lackeys", "");
CzechCardNames[CardNames.MOUNTAIN_VILLAGE] = new TranslatedCardName("Mountain Village", "Mountain Villages", "a Mountain Village", "");
CzechCardNames[CardNames.PATRON] = new TranslatedCardName("Patron", "Patrons", "a Patron", "");
CzechCardNames[CardNames.PRIEST] = new TranslatedCardName("Priest", "Priests", "a Priest", "");
CzechCardNames[CardNames.RESEARCH] = new TranslatedCardName("Research", "Researches", "a Research", "");
CzechCardNames[CardNames.SILK_MERCHANT] = new TranslatedCardName("Silk Merchant", "Silk Merchants", "a Silk Merchant", "");
CzechCardNames[CardNames.OLD_WITCH] = new TranslatedCardName("Old Witch", "Old Witches", "an Old Witch", "");
CzechCardNames[CardNames.RECRUITER] = new TranslatedCardName("Recruiter", "Recruiters", "a Recruiter", "");
CzechCardNames[CardNames.SCEPTER] = new TranslatedCardName("Scepter", "Scepters", "a Scepter", "");
CzechCardNames[CardNames.SCHOLAR] = new TranslatedCardName("Scholar", "Scholars", "a Scholar", "");
CzechCardNames[CardNames.SCULPTOR] = new TranslatedCardName("Sculptor", "Sculptors", "a Sculptor", "");
CzechCardNames[CardNames.SEER] = new TranslatedCardName("Seer", "Seers", "a Seer", "");
CzechCardNames[CardNames.SPICES] = new TranslatedCardName("Spices", "Spices", "a Spices", "");
CzechCardNames[CardNames.SWASHBUCKLER] = new TranslatedCardName("Swashbuckler", "Swashbucklers", "a Swashbuckler", "");
CzechCardNames[CardNames.TREASURER] = new TranslatedCardName("Treasurer", "Treasurers", "a Treasurer", "");
CzechCardNames[CardNames.VILLAIN] = new TranslatedCardName("Villain", "Villains", "a Villain", "");
CzechCardNames[CardNames.FLAG] = new TranslatedCardName("Flag", "Flags", "the Flag", "");
CzechCardNames[CardNames.HORN] = new TranslatedCardName("Horn", "Horns", "the Horn", "");
CzechCardNames[CardNames.KEY] = new TranslatedCardName("Key", "Keys", "the Key", "");
CzechCardNames[CardNames.LANTERN] = new TranslatedCardName("Lantern", "Lanterns", "the Lantern", "");
CzechCardNames[CardNames.TREASURE_CHEST] = new TranslatedCardName("Treasure Chest", "Treasure Chests", "the Treasure Chest", "");
CzechCardNames[CardNames.ACADEMY] = new TranslatedCardName("Academy");
CzechCardNames[CardNames.BARRACKS] = new TranslatedCardName("Barracks");
CzechCardNames[CardNames.CANAL] = new TranslatedCardName("Canal");
CzechCardNames[CardNames.CAPITALISM] = new TranslatedCardName("Capitalism");
CzechCardNames[CardNames.CATHEDRAL] = new TranslatedCardName("Cathedral");
CzechCardNames[CardNames.CITADEL] = new TranslatedCardName("Citadel");
CzechCardNames[CardNames.CITY_GATE] = new TranslatedCardName("City Gate");
CzechCardNames[CardNames.CROP_ROTATION] = new TranslatedCardName("Crop Rotation");
CzechCardNames[CardNames.EXPLORATION] = new TranslatedCardName("Exploration");
CzechCardNames[CardNames.FAIR] = new TranslatedCardName("Fair");
CzechCardNames[CardNames.FLEET] = new TranslatedCardName("Fleet");
CzechCardNames[CardNames.GUILDHALL] = new TranslatedCardName("Guildhall");
CzechCardNames[CardNames.INNOVATION] = new TranslatedCardName("Innovation");
CzechCardNames[CardNames.PAGEANT] = new TranslatedCardName("Pageant");
CzechCardNames[CardNames.PIAZZA] = new TranslatedCardName("Piazza");
CzechCardNames[CardNames.ROAD_NETWORK] = new TranslatedCardName("Road Network");
CzechCardNames[CardNames.SEWERS] = new TranslatedCardName("Sewers");
CzechCardNames[CardNames.SILOS] = new TranslatedCardName("Silos");
CzechCardNames[CardNames.SINISTER_PLOT] = new TranslatedCardName("Sinister Plot");
CzechCardNames[CardNames.STAR_CHART] = new TranslatedCardName("Star Chart");

CzechCardNames[CardNames.AVANTO] = new TranslatedCardName("Avanto", "Avantos", "an Avanto", "");
CzechCardNames[CardNames.BLACK_MARKET] = new TranslatedCardName("Black Market", "Black Markets", "a Black Market", "");
CzechCardNames[CardNames.ENVOY] = new TranslatedCardName("Envoy", "Envoys", "an Envoy", "");
CzechCardNames[CardNames.GOVERNOR] = new TranslatedCardName("Governor", "Governors", "a Governor", "");
CzechCardNames[CardNames.PRINCE] = new TranslatedCardName("Prince", "Princes", "a Prince", "");
CzechCardNames[CardNames.SAUNA] = new TranslatedCardName("Sauna", "Saunas", "a Sauna", "");
CzechCardNames[CardNames.STASH] = new TranslatedCardName("Stash", "Stashes", "a Stash", "");
CzechCardNames[CardNames.SUMMON] = new TranslatedCardName("Summon", "Summons", "a Summon", "");
CzechCardNames[CardNames.WALLED_VILLAGE] = new TranslatedCardName("Walled Village", "Walled Villages", "a Walled Village", "");
CzechCardNames[CardNames.BLACK_MARKET_PILE] = new TranslatedCardName("Black Market Deck", "Black Market Decks", "a Black Market Deck", "");
CzechCardNames[CardNames.DISMANTLE] = new TranslatedCardName("Dismantle", "Dismantles", "a Dismantle", "");
CzechCardNames[CardNames.CHURCH] = new TranslatedCardName("Church", "Churches", "a Church", "");
CzechCardNames[CardNames.CAPTAIN] = new TranslatedCardName("Captain", "Captains", "a Captain", "");

CzechCardNames[CardNames.BLACK_CAT] = new TranslatedCardName("Black Cat", "Black Cats", "a Black Cat", "");
CzechCardNames[CardNames.SLEIGH] = new TranslatedCardName("Sleigh", "Sleighs", "a Sleigh", "");
CzechCardNames[CardNames.SUPPLIES] = new TranslatedCardName("Supplies", "Supplies", "a Supplies", "");
CzechCardNames[CardNames.CAMEL_TRAIN] = new TranslatedCardName("Camel Train", "Camel Trains", "a Camel Train", "");
CzechCardNames[CardNames.GOATHERD] = new TranslatedCardName("Goatherd", "Goatherds", "a Goatherd", "");
CzechCardNames[CardNames.SCRAP] = new TranslatedCardName("Scrap", "Scraps", "a Scrap", "");
CzechCardNames[CardNames.SHEEPDOG] = new TranslatedCardName("Sheepdog", "Sheepdogs", "a Sheepdog", "");
CzechCardNames[CardNames.SNOWY_VILLAGE] = new TranslatedCardName("Snowy Village", "Snowy Villages", "a Snowy Village", "");
CzechCardNames[CardNames.STOCKPILE] = new TranslatedCardName("Stockpile", "Stockpiles", "a Stockpile", "");
CzechCardNames[CardNames.BOUNTY_HUNTER] = new TranslatedCardName("Bounty Hunter", "Bounty Hunters", "a Bounty Hunter", "");
CzechCardNames[CardNames.CARDINAL] = new TranslatedCardName("Cardinal", "Cardinals", "a Cardinal", "");
CzechCardNames[CardNames.CAVALRY] = new TranslatedCardName("Cavalry", "Cavalries", "a Cavalry", "");
CzechCardNames[CardNames.GROOM] = new TranslatedCardName("Groom", "Grooms", "a Groom", "");
CzechCardNames[CardNames.HOSTELRY] = new TranslatedCardName("Hostelry", "Hostelries", "a Hostelry", "");
CzechCardNames[CardNames.VILLAGE_GREEN] = new TranslatedCardName("Village Green", "Village Greens", "a Village Green", "");
CzechCardNames[CardNames.BARGE] = new TranslatedCardName("Barge", "Barges", "a Barge", "");
CzechCardNames[CardNames.COVEN] = new TranslatedCardName("Coven", "Covens", "a Coven", "");
CzechCardNames[CardNames.DISPLACE] = new TranslatedCardName("Displace", "Displaces", "a Displace", "");
CzechCardNames[CardNames.FALCONER] = new TranslatedCardName("Falconer", "Falconers", "a Falconer", "");
CzechCardNames[CardNames.FISHERMAN] = new TranslatedCardName("Fisherman", "Fishermen", "a Fisherman", "");
CzechCardNames[CardNames.GATEKEEPER] = new TranslatedCardName("Gatekeeper", "Gatekeepers", "a Gatekeeper", "");
CzechCardNames[CardNames.HUNTING_LODGE] = new TranslatedCardName("Hunting Lodge", "Hunting Lodges", "a Hunting Lodge", "");
CzechCardNames[CardNames.KILN] = new TranslatedCardName("Kiln", "Kilns", "a Kiln", "");
CzechCardNames[CardNames.LIVERY] = new TranslatedCardName("Livery", "Liveries", "a Livery", "");
CzechCardNames[CardNames.MASTERMIND] = new TranslatedCardName("Mastermind", "Masterminds", "a Mastermind", "");
CzechCardNames[CardNames.PADDOCK] = new TranslatedCardName("Paddock", "Paddocks", "a Paddock", "");
CzechCardNames[CardNames.SANCTUARY] = new TranslatedCardName("Sanctuary", "Sanctuaries", "a Sanctuary", "");
CzechCardNames[CardNames.DESTRIER] = new TranslatedCardName("Destrier", "Destriers", "a Destrier", "");
CzechCardNames[CardNames.WAYFARER] = new TranslatedCardName("Wayfarer", "Wayfarers", "a Wayfarer", "");
CzechCardNames[CardNames.ANIMAL_FAIR] = new TranslatedCardName("Animal Fair", "Animal Fairs", "an Animal Fair", "");
CzechCardNames[CardNames.HORSE] = new TranslatedCardName("Horse", "Horses", "a Horse", "");
CzechCardNames[CardNames.WAY_OF_THE_BUTTERFLY] = new TranslatedCardName("Way of the Butterfly");
CzechCardNames[CardNames.WAY_OF_THE_CAMEL] = new TranslatedCardName("Way of the Camel");
CzechCardNames[CardNames.WAY_OF_THE_CHAMELEON] = new TranslatedCardName("Way of the Chameleon");
CzechCardNames[CardNames.WAY_OF_THE_FROG] = new TranslatedCardName("Way of the Frog");
CzechCardNames[CardNames.WAY_OF_THE_GOAT] = new TranslatedCardName("Way of the Goat");
CzechCardNames[CardNames.WAY_OF_THE_HORSE] = new TranslatedCardName("Way of the Horse");
CzechCardNames[CardNames.WAY_OF_THE_MOLE] = new TranslatedCardName("Way of the Mole");
CzechCardNames[CardNames.WAY_OF_THE_MONKEY] = new TranslatedCardName("Way of the Monkey");
CzechCardNames[CardNames.WAY_OF_THE_MOUSE] = new TranslatedCardName("Way of the Mouse");
CzechCardNames[CardNames.WAY_OF_THE_MULE] = new TranslatedCardName("Way of the Mule");
CzechCardNames[CardNames.WAY_OF_THE_OTTER] = new TranslatedCardName("Way of the Otter");
CzechCardNames[CardNames.WAY_OF_THE_OWL] = new TranslatedCardName("Way of the Owl");
CzechCardNames[CardNames.WAY_OF_THE_OX] = new TranslatedCardName("Way of the Ox");
CzechCardNames[CardNames.WAY_OF_THE_PIG] = new TranslatedCardName("Way of the Pig");
CzechCardNames[CardNames.WAY_OF_THE_RAT] = new TranslatedCardName("Way of the Rat");
CzechCardNames[CardNames.WAY_OF_THE_SEAL] = new TranslatedCardName("Way of the Seal");
CzechCardNames[CardNames.WAY_OF_THE_SHEEP] = new TranslatedCardName("Way of the Sheep");
CzechCardNames[CardNames.WAY_OF_THE_SQUIRREL] = new TranslatedCardName("Way of the Squirrel");
CzechCardNames[CardNames.WAY_OF_THE_TURTLE] = new TranslatedCardName("Way of the Turtle");
CzechCardNames[CardNames.WAY_OF_THE_WORM] = new TranslatedCardName("Way of the Worm");
CzechCardNames[CardNames.DELAY] = new TranslatedCardName("Odklad");
CzechCardNames[CardNames.DESPERATION] = new TranslatedCardName("Desperation");
CzechCardNames[CardNames.GAMBLE] = new TranslatedCardName("Gamble");
CzechCardNames[CardNames.PURSUE] = new TranslatedCardName("Pursue");
CzechCardNames[CardNames.RIDE] = new TranslatedCardName("Ride");
CzechCardNames[CardNames.TOIL] = new TranslatedCardName("Dřina");
CzechCardNames[CardNames.ENHANCE] = new TranslatedCardName("Enhance");
CzechCardNames[CardNames.MARCH] = new TranslatedCardName("Pochod");
CzechCardNames[CardNames.TRANSPORT] = new TranslatedCardName("Transport");
CzechCardNames[CardNames.BANISH] = new TranslatedCardName("Banish");
CzechCardNames[CardNames.BARGAIN] = new TranslatedCardName("Bargain");
CzechCardNames[CardNames.INVEST] = new TranslatedCardName("Invest");
CzechCardNames[CardNames.SEIZE_THE_DAY] = new TranslatedCardName("Seize The Day");
CzechCardNames[CardNames.COMMERCE] = new TranslatedCardName("Commerce");
CzechCardNames[CardNames.DEMAND] = new TranslatedCardName("Demand");
CzechCardNames[CardNames.STAMPEDE] = new TranslatedCardName("Stampede");
CzechCardNames[CardNames.REAP] = new TranslatedCardName("Reap");
CzechCardNames[CardNames.ENCLAVE] = new TranslatedCardName("Enclave");
CzechCardNames[CardNames.ALLIANCE] = new TranslatedCardName("Alliance");
CzechCardNames[CardNames.POPULATE] = new TranslatedCardName("Populate");

CzechCardNames[CardNames.CARD_OF_THE_BUTTERFLY] = "";
CzechCardNames[CardNames.CARD_OF_THE_CAMEL] = "";
CzechCardNames[CardNames.CARD_OF_THE_CHAMELEON] = "";
CzechCardNames[CardNames.CARD_OF_THE_FROG] = "";
CzechCardNames[CardNames.CARD_OF_THE_GOAT] = "";
CzechCardNames[CardNames.CARD_OF_THE_HORSE] = "";
CzechCardNames[CardNames.CARD_OF_THE_MOLE] = "";
CzechCardNames[CardNames.CARD_OF_THE_MONKEY] = "";
CzechCardNames[CardNames.CARD_OF_THE_MOUSE] = "";
CzechCardNames[CardNames.CARD_OF_THE_MULE] = "";
CzechCardNames[CardNames.CARD_OF_THE_OTTER] = "";
CzechCardNames[CardNames.CARD_OF_THE_OWL] = "";
CzechCardNames[CardNames.CARD_OF_THE_OX] = "";
CzechCardNames[CardNames.CARD_OF_THE_PIG] = "";
CzechCardNames[CardNames.CARD_OF_THE_RAT] = "";
CzechCardNames[CardNames.CARD_OF_THE_SEAL] = "";
CzechCardNames[CardNames.CARD_OF_THE_SHEEP] = "";
CzechCardNames[CardNames.CARD_OF_THE_SQUIRREL] = "";
CzechCardNames[CardNames.CARD_OF_THE_TURTLE] = "";
CzechCardNames[CardNames.CARD_OF_THE_WORM] = "";

// ***** TODO *****: Allies
CzechCardNames[CardNames.TOWN] = new TranslatedCardName("Town", "Towns", "a Town");
CzechCardNames[CardNames.MODIFY] = new TranslatedCardName("Modify", "Modifies", "a Modify");
CzechCardNames[CardNames.MERCHANT_CAMP] = new TranslatedCardName("Merchant Camp", "Merchant Camps", "a Merchant Camp");
CzechCardNames[CardNames.SWAP] = new TranslatedCardName("Swap", "Swaps", "a Swap");
CzechCardNames[CardNames.SKIRMISHER] = new TranslatedCardName("Skirmisher", "Skirmishers", "a Skirmisher");
CzechCardNames[CardNames.HIGHWAYMAN] = new TranslatedCardName("Highwayman", "Highwaymen", "a Highwayman");
CzechCardNames[CardNames.BARBARIAN] = new TranslatedCardName("Barbarian", "Barbarians", "a Barbarian");
CzechCardNames[CardNames.INNKEEPER] = new TranslatedCardName("Innkeeper", "Innkeepers", "an Innkeeper");
CzechCardNames[CardNames.CAPITAL_CITY] = new TranslatedCardName("Capital City", "Capital Cities", "a Capital City");
CzechCardNames[CardNames.SPECIALIST] = new TranslatedCardName("Specialist", "Specialists", "a Specialist");
CzechCardNames[CardNames.CARPENTER] = new TranslatedCardName("Carpenter", "Carpenters", "a Carpenter");
CzechCardNames[CardNames.ROYAL_GALLEY] = new TranslatedCardName("Royal Galley", "Royal Galleys", "a Royal Galley");
CzechCardNames[CardNames.MARQUIS] = new TranslatedCardName("Marquis", "Marquises", "a Marquis");
CzechCardNames[CardNames.SENTINEL] = new TranslatedCardName("Sentinel", "Sentinels", "a Sentinel");
CzechCardNames[CardNames.COURIER] = new TranslatedCardName("Courier", "Couriers", "a Courier");
CzechCardNames[CardNames.GALLERIA] = new TranslatedCardName("Galleria", "Gallerias", "a Galleria");
CzechCardNames[CardNames.HUNTER] = new TranslatedCardName("Hunter", "Hunters", "a Hunter");

CzechCardNames[CardNames.SYCOPHANT] = new TranslatedCardName("Sycophant", "Sycophants", "a Sycophant");
CzechCardNames[CardNames.UNDERLING] = new TranslatedCardName("Underling", "Underlings", "an Underling");
CzechCardNames[CardNames.IMPORTER] = new TranslatedCardName("Importer", "Importers", "an Importer");
CzechCardNames[CardNames.CONTRACT] = new TranslatedCardName("Contract", "Contracts", "a Contract");
CzechCardNames[CardNames.EMISSARY] = new TranslatedCardName("Emissary", "Emissaries", "an Emissary");
CzechCardNames[CardNames.BAUBLE] = new TranslatedCardName("Bauble", "Baubles", "a Bauble");
CzechCardNames[CardNames.BROKER] = new TranslatedCardName("Broker", "Brokers", "a Broker");
CzechCardNames[CardNames.GUILDMASTER] = new TranslatedCardName("Guildmaster", "Guildmasters", "a Guildmaster");

CzechCardNames[CardNames.ARCHITECTS_GUILD] = new TranslatedCardName("Architects' Guild");
CzechCardNames[CardNames.ORDER_OF_ASTROLOGERS] = new TranslatedCardName("Order of Astrologers");
CzechCardNames[CardNames.LEAGUE_OF_BANKERS] = new TranslatedCardName("League of Bankers");
CzechCardNames[CardNames.MOUNTAIN_FOLK] = new TranslatedCardName("Mountain Folk");
CzechCardNames[CardNames.WOODWORKERS_GUILD] = new TranslatedCardName("Woodworkers' Guild");
CzechCardNames[CardNames.PEACEFUL_CULT] = new TranslatedCardName("Peaceful Cult");
CzechCardNames[CardNames.CRAFTERS_GUILD] = new TranslatedCardName("Crafters' Guild");
CzechCardNames[CardNames.CAVE_DWELLERS] = new TranslatedCardName("Cave Dwellers");
CzechCardNames[CardNames.PLATEAU_SHEPHERDS] = new TranslatedCardName("Plateau Shepherds");
CzechCardNames[CardNames.DESERT_GUIDES] = new TranslatedCardName("Desert Guides");
CzechCardNames[CardNames.FAMILY_OF_INVENTORS] = new TranslatedCardName("Family of Inventors");
CzechCardNames[CardNames.ISLAND_FOLK] = new TranslatedCardName("Island Folk");
CzechCardNames[CardNames.ORDER_OF_MASONS] = new TranslatedCardName("Order of Masons");
CzechCardNames[CardNames.BAND_OF_NOMADS] = new TranslatedCardName("Band of Nomads");
CzechCardNames[CardNames.GANG_OF_PICKPOCKETS] = new TranslatedCardName("Gang of Pickpockets");
CzechCardNames[CardNames.MARKET_TOWNS] = new TranslatedCardName("Market Towns");
CzechCardNames[CardNames.FOREST_DWELLERS] = new TranslatedCardName("Forest Dwellers");
CzechCardNames[CardNames.FELLOWSHIP_OF_SCRIBES] = new TranslatedCardName("Fellowship of Scribes");
CzechCardNames[CardNames.LEAGUE_OF_SHOPKEEPERS] = new TranslatedCardName("League of Shopkeepers");
CzechCardNames[CardNames.COASTAL_HAVEN] = new TranslatedCardName("Coastal Haven");
CzechCardNames[CardNames.CITY_STATE] = new TranslatedCardName("City-state");
CzechCardNames[CardNames.TRAPPERS_LODGE] = new TranslatedCardName("Trappers' Lodge");
CzechCardNames[CardNames.CIRCLE_OF_WITCHES] = new TranslatedCardName("Circle of Witches");

CzechCardNames[CardNames.AUGUR_PILE] = new TranslatedCardName("Augurs");
CzechCardNames[CardNames.HERB_GATHERER] = new TranslatedCardName("Herb Gatherer", "Herb Gatherers", "a Herb Gatherer");
CzechCardNames[CardNames.ACOLYTE] = new TranslatedCardName("Acolyte", "Acolytes", "an Acolyte");
CzechCardNames[CardNames.SORCERESS] = new TranslatedCardName("Sorceress", "Sorceresses", "a Sorceress");
CzechCardNames[CardNames.SIBYL] = new TranslatedCardName("Sibyl", "Sibyls", "a Sibyl");
CzechCardNames[CardNames.CLASH_PILE] = new TranslatedCardName("Clashes");
CzechCardNames[CardNames.BATTLE_PLAN] = new TranslatedCardName("Battle Plan", "Battle Plans", "a Battle Plan");
CzechCardNames[CardNames.ARCHER] = new TranslatedCardName("Archer", "Archers", "an Archer");
CzechCardNames[CardNames.WARLORD] = new TranslatedCardName("Warlord", "Warlords", "a Warlord");
CzechCardNames[CardNames.TERRITORY] = new TranslatedCardName("Territory", "Territories", "a Territory");
CzechCardNames[CardNames.FORT_PILE] = new TranslatedCardName("Forts");
CzechCardNames[CardNames.TENT] = new TranslatedCardName("Tent", "Tents", "a Tent");
CzechCardNames[CardNames.GARRISON] = new TranslatedCardName("Garrison", "Garrisons", "a Garrison");
CzechCardNames[CardNames.HILL_FORT] = new TranslatedCardName("Hill Fort", "Hill Forts", "a Hill Fort");
CzechCardNames[CardNames.STRONGHOLD] = new TranslatedCardName("Stronghold", "Strongholds", "a Stronghold");
CzechCardNames[CardNames.ODYSSEY_PILE] = new TranslatedCardName("Odysseys");
CzechCardNames[CardNames.OLD_MAP] = new TranslatedCardName("Old Map", "Old Maps", "an Old Map");
CzechCardNames[CardNames.VOYAGE] = new TranslatedCardName("Voyage", "Voyages", "a Voyage");
CzechCardNames[CardNames.SUNKEN_TREASURE] = new TranslatedCardName("Sunken Treasure", "Sunken Treasures", "a Sunken Treasure");
CzechCardNames[CardNames.DISTANT_SHORE] = new TranslatedCardName("Distant Shore", "Distant Shores", "a Distant Shore");
CzechCardNames[CardNames.TOWNSFOLK_PILE] = new TranslatedCardName("Townsfolk");
CzechCardNames[CardNames.TOWN_CRIER] = new TranslatedCardName("Town Crier", "Town Criers", "a Town Crier");
CzechCardNames[CardNames.BLACKSMITH] = new TranslatedCardName("Blacksmith", "Blacksmiths", "a Blacksmith");
CzechCardNames[CardNames.MILLER] = new TranslatedCardName("Miller", "Millers", "a Miller");
CzechCardNames[CardNames.ELDER] = new TranslatedCardName("Elder", "Elders", "an Elder");
CzechCardNames[CardNames.WIZARD_PILE] = new TranslatedCardName("Wizards");
CzechCardNames[CardNames.STUDENT] = new TranslatedCardName("Student", "Students", "a Student");
CzechCardNames[CardNames.CONJURER] = new TranslatedCardName("Conjurer", "Conjurers", "a Conjurer");
CzechCardNames[CardNames.SORCERER] = new TranslatedCardName("Sorcerer", "Sorcerers", "a Sorcerer");
CzechCardNames[CardNames.LICH] = new TranslatedCardName("Lich", "Liches", "a Lich");