"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TableSorter = function () {
    function TableSorter(sorter) {
        _classCallCheck(this, TableSorter);

        this.sorter = sorter;
        this.uuid = generateUUID();
    }

    _createClass(TableSorter, [{
        key: "sort",
        value: function sort(arr) {
            arr.sort(this.sorter);
        }
    }, {
        key: "toString",
        value: function toString() {
            return this.uuid;
        }
    }]);

    return TableSorter;
}();

function statusSorter(a, b) {
    var n = a.getStatusOrdinal() - b.getStatusOrdinal();
    if (n !== 0) return n;
    if (a.status !== TableStati.RUNNING) return 0;
    return b.startTime - a.startTime;
}

function numberOfPlayersSorter(a, b) {
    return b.getNumberOfPlayers() - a.getNumberOfPlayers();
}

var TableSorters = {
    HOST_A_Z: new TableSorter(function (a, b) {
        return a.getHostName().localeCompare(b.getHostName());
    }),
    HOST_Z_A: new TableSorter(function (a, b) {
        return b.getHostName().localeCompare(a.getHostName());
    }),
    PLAYERS_HIGH_LOW: new TableSorter(function (a, b) {
        return numberOfPlayersSorter(a, b);
    }),
    PLAYERS_LOW_HIGH: new TableSorter(function (a, b) {
        return numberOfPlayersSorter(b, a);
    }),
    SPECTATORS_HIGH_LOW: new TableSorter(function (a, b) {
        return b.spectators - a.spectators;
    }),
    SPECTATORS_LOW_HIGH: new TableSorter(function (a, b) {
        return a.spectators - b.spectators;
    }),
    STATUS_HIGH_LOW: new TableSorter(function (a, b) {
        return statusSorter(a, b);
    }),
    STATUS_LOW_HIGH: new TableSorter(function (a, b) {
        return statusSorter(b, a);
    })
};