"use strict";

var EsperantoLevelIntroductions = ["Ĉe la unua nivelo vi ludas kun la bazludo; etendigoj ne estas uzita.", "Nivelo 2 ne aldonas novajn ludkonceptojn, sed multajn simplajn kartojn el diversaj etendigoj."];

var EsperantoGameConcepts = {};
EsperantoGameConcepts[GameConcepts.CHOICE_CARDS] = new TranslatedGameConcept("Elektantaj Kartoj", "Iuj kartoj donas al vi elekton de faro. Ekzemple Intendanto povas fari unu el tri a\u0135oj;\nkiam vi ludas \u011Din vi havas tri butonojn por elekti.\nAliaj kartoj, kiel Dezirputo, lasas vin nomumi karton.\nVi povas klaki sur karto por elekti tiun, a\u016D tajpi la nomon de alia karto.");

EsperantoGameConcepts[GameConcepts.DURATIONS] = new TranslatedGameConcept("Daŭroj", "Da\u016Drokartoj estas oran\u011Daj, kaj povas fari ion en estonta vico. \nLa sola speciala a\u0135o pri ili estas ke ili restas en ludo \u011Dis la vico, en kiu ili finas fari a\u0135ojn. \nEkzemple kiam vi ludas Ekipa\u0135on, vi tiras du kartojn, kaj povas flankenmeti \u011Dis du kartojn el via mano. \nSe vi ne flankenmetis kartojn, Ekipa\u0135o kompletas, kaj estos for\u0135etita tiun vicon. \nTamen se vi flankenmetas kartojn, Ekipa\u0135o restas en ludo; je via sekva vico vi ricevas la kartojn, kaj Ekipa\u0135o estas for\u0135etita je la fino de tiu vico. \nTrono\u0109ambro, kiu ludas Da\u016Drokarton, restas en ludo kun \u011Di.");

EsperantoGameConcepts[GameConcepts.KINGDOM_TREASURES] = new TranslatedGameConcept("Specialaj Trezoroj", "Trezoroj ne devas sole fari monerojn; ili povas fari aliajn a\u0135ojn, ekzakte kiel Agkartoj. \nEkzemple kiam vi ludas Tezoraron, kune kun ricevi [2], vi gajnas Oron kaj Kupron.");

EsperantoGameConcepts[GameConcepts.PLATINUM_COLONY] = new TranslatedGameConcept("Plateno & Kolonio", "Plateno estas Trezoro valoras [5]; Kolonio estas Venko valoras 10 VP. Tiuj ne estas regnokartoj; ili aperas apud Oro kaj Provinco.\nIli aperas hazarde, la\u016D la ofteco de kartoj de Prospero en la ludo - 10% \u015Danco po tiel karto. Ili \u0109iam kunaperas.\nEn ludoj kun Kolonio, se la Kolonistako estas malplena, la ludo finas (la ludo povas ankora\u016D fini pro Provincoj a\u016D tri malplenaj stakoj).");

EsperantoGameConcepts[GameConcepts.WHEN_GAIN_BUY] = new TranslatedGameConcept("Kiam-gajni/aĉeti", "Iuj kartoj faras ion kiam ili estas gajnita a\u016D a\u0109etita. Tiuj ne estas tute la sama a\u0135o, kaj ne havas la sama altempigo.\nKiam vi a\u0109etas karton, unue vi faras a\u0135ojn, kiu okazas kiam vi a\u0109etas \u011Din, poste vi fakte gajnas la karton, poste vi faras a\u0135ojn, kiu okazas kiam vi gajnas \u011Din.\nVi povas anka\u016D gajnas kartojn sen a\u0109eti ilin, ekz. kun Aliformo.\nTiel ekzemple se vi Aliformas karton en Liman Vila\u011Don, poste tuj post vi gajnas la Liman Vila\u011Don, vi gajnos pli malmultekostan karton. \nSed se vi Aliformas karton en Forumon, nenio speciala okazas; vi ne a\u0109etis la Forumon.\nSe vi a\u0109etas Liman Vila\u011Don, vi gajnos \u011Din, poste gajnos pli malmultekostan karton; kaj se vi a\u0109etas Forumon, vi ricevos +1 A\u0109eto, poste gajnos \u011Din.\nPost vi gajnis a\u016D a\u0109etis la karton, tiu kapablo ne plu faras ion ajn por vi; \nLima Vila\u011Do en via kartaro estas ekzakte tiel Vila\u011Do, krom ke \u011Di kostas [6].");

EsperantoGameConcepts[GameConcepts.WHEN_TRASH] = new TranslatedGameConcept("Kiam-rubigi", "Iuj kartoj faras ion kiam ili estas rubigita.\nLa karto devas fakte esti metita en la Rubujon por okazigi la efikon.\nLa efiko povas okazi meze de solvi alian karton;\nekzemple se vi rubigas Katakombojn per Aliformo, unue vi gajnos karton kostantan malpli ol Katakomboj, \nkaj poste vi gajnos karton kostantan \u011Dis [2] pli ol Katakomboj pro Aliformo.");

EsperantoGameConcepts[GameConcepts.VP_TOKENS] = new TranslatedGameConcept("VP Jetonoj", "VP jetonoj provizas vojon por fari VP, al venkado, sen Venkkarto eniri en vian kartaron.\nEkzemple \u0109iun fojon vi ludas Monumenton, vi ricevas alian poenton. Ne estas limo al kiom da poentoj \u0109i tio povas gajni por vi.\nVP jetonoj estas inkluzivita en via poentaro kiel montrita sur la ekrano, kaj anka\u016D montrita aparte.");

EsperantoGameConcepts[GameConcepts.COFFERS] = new TranslatedGameConcept("Monrezervoj", "Iuj kartoj donas al vi +Monrezervo; \u0109i tio estas [], kiun vi povas \u015Dpari \u011Dis vi volas uzi \u011Din.\nDum via vico, vi povas konverti iom ajn da Monrezervjetonoj en [].\nVi povas elspezi la Monrezervon en la sama vico, en kiu vi ricevas \u011Din, inkluzive dum via A\u0109etfazo.\nEkzemple, Spicoj donas al vi du Monrezervojn kiam vi gajnas \u011Din.");

EsperantoGameConcepts[GameConcepts.VILLAGERS] = new TranslatedGameConcept("Vilaĝanoj", "Iuj kartoj donas al vi +Vila\u011Danoj; \u0109i tioj estas +Agoj, kiujn vi povas \u015Dpari \u011Dis vi volas uzi ilin.\nDum via Agfazo, vi povas konverti iom ajn da Vila\u011Danoj en po +1 Ago; kutime vi uzos ilin unuope.\nEkzemple, Lakeoj donas al vi du Vila\u011Danojn kiam vi gajnas \u011Din.\nVi en iun vicon ludus Lakeojn, tirus For\u011Dejon, kiun vi ne povus ludi, kaj poste uzus unu el viaj Vila\u011Danoj por +1 Ago, por ke vi povus da\u016Drigi ludi kartojn.");

EsperantoGameConcepts[GameConcepts.EXILE] = new TranslatedGameConcept("Ekzilo", "\u0108iu ludanto havas ilian propran Ekzilmaton, sur kiu kartoj povas esti metita.\n\"Ekzilu karton\" signifas meti \u011Din sur vian maton;\nse la karto estis en la Provizo, \u0109i tio ne spronas \"kiam vi gajnas \u0109i tion\" kapablojn, sed la karto estas ankora\u016D nun via (ekzemple dum poentkalkulo).\nKiam vi gajnas karton, vi povas anka\u016D for\u0135eti \u0109iujn kopiojn de \u011Di el via mato se vi volas (metante ilin en vian for\u0135etan stakon).\nVi ne povas for\u0135eti nur kelkajn el ili; estas \u0109io a\u016D nenio.\nEkzemple kiam vi ludas Premi\u0109asisto, vi Ekzilas karton el via mano, kaj se vi ne jam havis kopion de \u011Di sur la mato, vi ricevas +[3].\nVi povus Ekzili Bienon el via mano; tio estas kiel forigi la Bienon, \u0109ar vi neniam tiros \u011Din, sed \u011Di ankora\u016D kontas por via poentaro je la fino.\nSe vi poste gajnis Bienon, kaj volis, ke la Ekzilita Bieno estas en via kartaro ial, vi povus for\u0135eti \u011Din.");

EsperantoGameConcepts[GameConcepts.REACTIONS] = new TranslatedGameConcept("Reagoj", "Reagkartoj estas bluaj, kaj havas kapablon, kiu povas esti uzita je nekutima fojo.\nOfte vi malka\u015Das la karton el via mano por uzi la kapablon; iam vi anka\u016D for\u0135eti \u011Din; iam vi ludi \u011Din.\nVi konas Fosa\u0135on, kiu baras Atakojn; aliaj Reagoj ne necese faras tion, ili nur faras kion ajn, kiun ili diras.\nEkzemple \u015Cafhundo lasas vin ludi \u011Din iam ajn vi gajnas karton; estus e\u0109 la vico de alia ludanto.\nVi tiras du kartojn, kaj povas ankora\u016D tiam ludi alian \u015Cafhundon, e\u0109 unu, kiun vi \u0135us tiris.");

EsperantoGameConcepts[GameConcepts.FAVORS] = new TranslatedGameConcept("Komplezoj", "Kontaktulkartoj havas kapablon, kiu povas doni al vi Komplezjetonojn. \nEn ludo kun unu a\u016D pli da Kontaktuloj, \u0109iam estos precize unu Aliancano; \n\u0109i tio estas horizontala karto, kiu diras al vi tion, por kio Komplezjetonoj povas esti uzata \u0109i-lude. \nEkzemple en ludo kun Subulo, vi havus Dometo de Kaptistoj por la Aliancano; \nposte \u0109iufoje kiam vi gajnas karton \u0109i-lude, vi povas elspezi Komplezjetonon por meti la karton sur vian kartaron. \nVi komencas la ludon kun unu Komplezjetono (a\u016D kvin en ludoj kun Importisto), kaj povas e\u0109 uzi \u011Din dum via unua vico.");

EsperantoGameConcepts[GameConcepts.WHEN_DISCARD] = new TranslatedGameConcept("Kiam-forĵeti", "Iuj kartoj faras ion kiam ili estas for\u0135etita.\nIuj zorgas pri esti for\u0135etita el via mano, kiel Tunelo; kiam vi for\u0135etas \u011Din, vi povas malka\u015Di \u011Din por gajni Oron.\nAliaj faras ion kiam estas for\u0135etita el ludo, kiu kutime estas en Purigfazo; ili devas esti estinta metita en ludon por fari \u0109i tion.\nEkzemple kiam vi ludas Trezorejon, vi havos eblecon en Purigfazo por meti \u011Din sur vian kartaron, kondi\u0109e ke vi ne a\u0109etis Venkkarton \u0109i-vice.");

EsperantoGameConcepts[GameConcepts.TRASH_INTERACTION] = new TranslatedGameConcept("Interagi kun Rubujo", "Kelkaj kartoj interagas kun la Rubujo. \nVi povas rigardi la Rubujon iam ajn per baskuli la vidon supre dekstre.\nEkzemple Ka\u016Dranto povas gajni Agkartojn el la Rubujo.\nNe gravas kiel ili alvenas tie.");

EsperantoGameConcepts[GameConcepts.UNIQUE_MATS] = new TranslatedGameConcept("Unikaj Matoj", "Iuj kartoj havas iliajn proprajn unikajn matojn, de kiuj \u0109iu ludanto ricevas ilian propran kopion.\nEkzemple Indi\u011Dena Vila\u011Do havas maton, kaj povas a\u016D aldoni karton al via mato por \u011Di, a\u016D preni \u0109iujn kartojn el \u011Di.\n\u0108iu el \u0109i tiuj matoj montras \u011Dian enhavon malsame.");

EsperantoGameConcepts[GameConcepts.EVENTS] = new TranslatedGameConcept("Okazoj", "Iam la ludo havas horizontala kartoj, kiuj modifas iel la ludon.\nOkazoj estas la unuaj el \u0109i tiuj.\nOkazoj havas koston kaj efikon; vi povas uzi A\u0109eton en via A\u0109etfazo kaj pagi la koston, por generi la efikon.\nSe vi havas multajn A\u0109etojn vi povas a\u0109eti unu el la efikojn plurfoje (krom se \u011Di diras alie).\nVi povas a\u0109eti karton kaj Okazon, ktp.\nA\u0109eti Okazon ne estas a\u0109eti karton, kaj Okazoj ne estas tu\u015Dita de a\u0135oj, kiuj aludas \"kartoj,\" kiel Ponto.");

EsperantoGameConcepts[GameConcepts.RESERVE] = new TranslatedGameConcept("Reservoj", "Rezervkartoj estas sunbrunaj, kaj havas kapablon, kiu metas ilin sur vian maton de Taverno, kaj alian kapablon, kiu povas \"alvoki\" ilin el \u011Di.\n\"Alvoki\" tiel karton metas \u011Din en ludon.\nTiel entute ili estas kartoj, kiuj lasas vin \u015Dpari efikon por iam en la estonteco.\nEkzemple, kiam vi ludas Ratkaptiston, vi ricevas +1 Karto, +1 Ago, kaj metas Ratkaptiston sur vian maton.\nPoste je la komenco de iu estonta vico, vi povas alvoki la Ratkaptiston, movante \u011Din en ludon kaj rubigante karton el via mano.\nRatkaptisto estas poste for\u0135etita tiun vicon en Purigfazo.");

EsperantoGameConcepts[GameConcepts.ADVENTURES_TOKENS] = new TranslatedGameConcept("Aventuroj-jetonoj", "La etendigo Aventuroj venas kun vario de jetonoj, de kiuj \u0109iu ludanto ricevas aron.\nIuj iras sur Provizstakojn; aliaj sidas anta\u016D ludanto ie.\nEkzemple Trejnado estas Okazo, kiu lasas ludanto meti +[1] jetonon sur stakon;\nposte por la cetero de la ludo, la ludanto ricevas +[1] kiam tiu ludas karton el tiu stako.\nEstas similaj jetonoj por +1 Ago, +1 Karto, kaj +1 A\u0109eto.\nArbargardisto uzas la Voja\u011Djetonon, kiu renversas \u0109iun fojon Arbargardisto estas ludita;\n\u0109i tiu signifas ke \u0109iu dua ludado de Arbargardisto donos al vi la +5 Kartoj.\nEstas anka\u016D: -[2] kosto jetono, kiu plimalmultekostigas unu stakon je viaj vicoj;\nRubigjetono, kiu lasas vin rubigi karton kiam vi a\u0109etas karton el la stako kun \u011Di;\nkaj -[1] kaj -1 Karto jetonoj, kiuj senigas vin je la sekva +[1], kiun vi ricevus a\u016D karton, kiun vi tirus.");

EsperantoGameConcepts[GameConcepts.NIGHT_PHASE] = new TranslatedGameConcept("Nokto", "Nokturno aldonas novan fazon: la Noktofazo.\n\u0108i tiu fazo okazas post la A\u0109etfazo kaj anta\u016D Purigfazo.\nDum \u011Di, vi povas ludi iom ajn da Noktokartoj.\nJen \u0109io; Noktokartoj estas kartoj, kiujn vi ludas en tiu fazo.\nDefa\u016Dlte tio estas la sola vojo kiel vi povas ludi ilin; ili ne estas Agoj, ili ne estas Trezoroj.\nEkzemple Mona\u0125ejo estas Noktokarto; en la Noktofazo vi povas ludi \u011Din por rubigi iom da kartoj, depende de kiom da kartoj, kiujn vi gajnis \u0109i-vice.\nPluraj Noktokartoj estas anka\u016D Da\u016Drokartoj, kaj iom da ili iras al via mano kiam vi gajnas ilin; Pekdoma\u0109o estas unu el \u0109i tiuj.\nTiel kiam vi a\u0109etas Pekdoma\u0109on, vi gajnas \u011Din, metu \u011Din en vian manon... kaj poste estas via Noktofazo, kaj vi povas tuj ludi \u011Din.");

EsperantoGameConcepts[GameConcepts.SHELTERS] = new TranslatedGameConcept("Ŝirmejoj", "La \u015Cirmejoj aperas hazarde, la\u016D la ofteco de kartoj de Mallumaj Epokoj en la ludo - 10% \u015Danco po tiel karto.\nIli \u0109iam kunaperas.\nIli ne estas Provizkartoj; ili anstata\u016Digas komencantajn Bienojn.\nTiel anstata\u016D 7 Kuprojn kaj 3 Bienojn, vi havas 7 Kuprojn, Surkreskata Bieno, Nekropolo, kaj Doma\u0109o.");

EsperantoGameConcepts[GameConcepts.HORSES] = new TranslatedGameConcept("Ĉevaloj", "\u0108evalo estas ne-Proviza karto, kiun iuj kartoj povas gajni por vi.\nEkzemple \u0109iun fojon vi ludas Proviantojn, vi gajnas \u0108evalon (sur vian kartaron).\nKiam vi ludas \u0108evalon, vi ricevas +2 Kartoj kaj +1 Ago, kaj redonas la \u0108evalon al \u011Dia stako.\n\u0108evaloj povas anka\u016D esti uzita alivoje, kiel kartoj povas esti uzita, ekz. vi povas Aliformi \u0108evalon (signifante ke \u011Di iras al la Rubujo, ne al \u011Dia stako).\nSe la \u0108evalstako estas malplena, \u0108evaloj ne povas esti gajnita (\u011Dis pli da \u0108evaloj redonas al \u011Di).\n\u0108evalo ne povas esti gajnita alivoje, nur per kartoj, kiu specife gajni \u0108evalojn.\nLa \u0108evalstako ne konti al la 3-staka ludfino.");

EsperantoGameConcepts[GameConcepts.PROJECTS] = new TranslatedGameConcept("Projektoj", "Projektoj estas alia speco de horizontala karto. En ludoj kun ili, vi ricevas du kubojn en via ludantkoloro.\nEn via A\u0109etfazo, vi povas uzi A\u0109eton kaj pagi la indikitan koston por meti kubon sur Projekton.\n\u0108i tiu kubo donas al vi la kapablon de la Projekto por la cetero de la ludo.\nEkzemple, Foiro kostas [4], kaj se vi a\u0109etas \u011Din vi havos +1 A\u0109eto je viaj vicoj por la cetero de la ludo.\nKiel Okazoj, Projektoj ne estas \"kartoj\" kaj tiel ekz. ne plimalmultekostigas pro Ponto.\nVi ne povas movi la kubo poste; en ludo kun tri Projektoj, vi nur povas a\u0109eti du el ili.\nVi povas nur a\u0109eti \u0109iun Projekton unue po ludo, vi ne povas meti du kubojn sur unu.");

EsperantoGameConcepts[GameConcepts.LANDMARKS] = new TranslatedGameConcept("Rekoniloj", "Rekoniloj estas alia speco de horizontala karto.\nIli provizas iun aldonan vojon por ricevas VP, a\u016D kun VP-jetonoj a\u016D nur aldonita al poentaroj je la fino de la ludo.\nEkzemple, Turo provizas 1 VP je la fino de la ludo po karto, kiun vi havas el malplena ne-Venka Provizstako.\nVia poentaro konsideras la efikojn de Rekoniloj.");

EsperantoGameConcepts[GameConcepts.EXTRA_CARDS] = new TranslatedGameConcept("Ekstraj Kartoj", "Iuj kartoj uzas malsamajn ne-Provizajn stakojn.\nKiel \u0108evaloj, la ne-Proviza kartoj povas nur esti gajnita de la kartoj, kiuj specife gajnas ilin, kaj la stakoj ne kontas al la ludfina kondi\u0109o.\nEkzemple Ermito havas kondi\u0109o, kiu igas vin inter\u015Dan\u011Di \u011Din por Frenezulo. \nErmito estas la sola karto, kiu uzas Frenezulon.\n\nEstas anka\u016D kartoj, kiuj uzas Ruinojn, kiuj estas Provizstako, kiu nur estas elmetita kiam karto uzas ilin.\nLa kartoj en la Ruinstako estas miksita; estas kvin malsamaj eblaj specoj.\nIuj kartoj disdonas \u0109i tiujn kartojn, kiel Sor\u0109istino disdonas Malbenojn.\n\n\nNokturno havas aron de ne-Provizaj stakoj nomitaj Spiritoj. Iuj kartoj aludas individuajn Spiritojn, kaj unu aludas la aron.\n");

EsperantoGameConcepts[GameConcepts.SPLIT_PILES] = new TranslatedGameConcept("Heterogenaj Stakoj", "\nIuj stakoj enhavas du malsamajn kartojn.\nEstas 5 kopioj de la pli malmultekosta karto, poste 5 kopioj de la alia karto.\nEkzemple unu el \u0109i tiuj stakoj havas 5 Setlantjon, kaj sub ili 5 Movoplenajn Vila\u011Dojn.\nVi povas nur gajni la supran karton de la stako; se vi volas Movoplenan Vila\u011Don, iu devas preni la 5ajn Setlantojn.\n\nEstas anka\u016D unu stako, kiu enhavas 8 malsamajn kartojn - la Kasteloj - kaj unu stako, kiu enhavas 10 malsamajn kartojn, miksitakjn - la Kavaliroj.\n");

EsperantoGameConcepts[GameConcepts.ARTIFACTS] = new TranslatedGameConcept("Artefaktoj", "Kvar kartoj aludas Artefaktojn.\nLa Artefaktoj mem estas nur spurita informo, ne kartoj.\nEkzemple la Flagon donas ekstran karton al \u011Dia havanto kiam tiu tiras.\nPor ricevi \u011Din, vi devas gajni a\u016D rubigi Flagportanton.\nNur unu ludanto povas havi Artefakton samtempe;\nse vi ricevas la Flagon kiam iu alia havas \u011Din, vi prenas \u011Din el ili.");

EsperantoGameConcepts[GameConcepts.BOONS_HEXES] = new TranslatedGameConcept("Benoj & Envultoj", "Nokturno havas Benojn kaj Envultojn.\n\u0108i tiuj estas malgrandaj stakoj de malsamaj efikoj;\nBenoj estas bonaj kaj Envultoj estas malbonaj (uzitaj de Atakoj).\nKiam karto diras \"Ricevas Benon,\" kiel Bardo, vi renversas la sekvan Benon kaj faras kion ajn, kiun \u011Di diras (miksu ili kiam necesa).\nEkzemple unu Beno estas Dono de la Maro; vi nur ricevas +1 Karto.\nEnvultoj estas similaj sed malbonaj; kun pluraj ludantoj, kiam vi donas al \u0109iu alia ludanto Envulton, ili \u0109iuj ricevas la saman.");

EsperantoGameConcepts[GameConcepts.HEIRLOOMS] = new TranslatedGameConcept("Heredaĵoj", "Nokturno havas Hereda\u0135ojn, kiuj estas kartoj, kiuj anstata\u016Digas komencantajn Kuprojn.\nIli estas uzita iom ajn kongruanta regnokarto estas uzita; ekzemple, en ludoj kun \u015Cafisto, Pa\u015Dtejo estas uzita.\nIli nur anstata\u016Digas unuopan komencantan Kupron; en ludoj kun \u015Cafisto, vi havas 3 Biejnojn, 6 Kuprojn, kaj Pa\u015Dtejon.\nCerte vi havus pli ol unu karton, kiu donas Hereda\u0135on en ludo, kaj havus amba\u016D \u015Cirmejojn kaj Hereda\u0135on.\nLa raporto indikas la enhavon de la komencantaj kartaroj.");

EsperantoGameConcepts[GameConcepts.STRANGE_COSTS] = new TranslatedGameConcept("Nekutimaj Kostoj", "Iuj kartoj havas ian nekutiman koston.\nEkzemple Batal\u0109evalo kostas [1] malpli po karto, kiun vi gajnis tiun vicon.\nSe vi gajnis tri kartojn, \u011Di kostus [3].\n\u0108i tiuj \u015Dan\u011Doj \u0109iam apliki\u011Das al \u0109iuj kopioj de karto.\nLa programo \u011Disdatigos la kostojn por speguli tiun, kiu ili nune estas.");

EsperantoGameConcepts[GameConcepts.OVERPAY] = new TranslatedGameConcept("Tropago", "Kelkaj kartoj lasas vin pagi ekstre kiam vi a\u0109etas ilin, por krei efikon.\nEkzemple kiam vi a\u0109etas Majstra\u0135on, \u0109iu ekstra [1], kiun vi pagas gajnas por vi Ar\u011Denton.\n\u0108i tio ne \u015Dan\u011Das la propran koston de Majstra\u0135o, \u011Di ankora\u016D kostas [3].\nVi nur pli pagas por ricevi la efikon.");

EsperantoGameConcepts[GameConcepts.COMMAND] = new TranslatedGameConcept("Komando", "Iuj kartoj povas ludi alian karton sen movo.\nVi krei la efikon de ludado de karto, sed ne ricevas ekz. \"dum \u0109i tio estas en ludo\" bonusoj.\nEkzemple Malsocietemularo povas ludi pli malmultekostan karton el la Provizo.\nIom da \u0109i tiuj kartoj uzas la \"Komando\" specon por eviti lasi vin krei senfinan ciklon de kartoj, kiuj ludas aliajn kartojn; jen \u0109io, kiun \u011Di signifas.\nKartoj kiel Malsocietemularo restas en ludo kondi\u0109e ke la karto, kiun \u011Di ludas estus restinta, ekz. se Malsocietemularo ludas Fi\u015Dkaptistan Vila\u011Don, \u011Di restas en ludo \u011Dis sekva vico.");

EsperantoGameConcepts[GameConcepts.POTION] = new TranslatedGameConcept("Eliksiro", "Iuj kartoj havas [P] en ilia kostoj.\n\u0108i tio estas resurso, kiun vi povas nur ricevi de ludi Eliksiron (kaj tiuj ludoj \u0109iam inkluzivas Eliksiron kiel Provizstako).\n\u011Ci alie similas []; vi ricevas \u011Din, vi elspezas \u011Din.\nSe vi havas [2] kaj [P], vi povas a\u0109eti Universitaton, ekzemple.\nPor a\u0109eti 2an Universitaton vi bezonus alian [2] kaj anka\u016D alian [P].\n\nKiam kartoj inspektas koston de karto en moneroj, ili ignoras la [P].\nKiam vi komparas kostojn, [P] estas pli ol nenio, sed nek pli nek malpli ol [] kvanto.\nVi povas Aliformi Universitaton en karton kostantan [4] kaj [P], a\u016D karton kostantan [4] ([4] estas \"\u011Dis\" [4] kaj [P]), sed vi ne povas Aliformi Kupron en Universitaton.\nKavaliroj rubigas kartojn kostantajn de [3] al [6]; karto kun [P] en \u011Dia kosto ne kostas de [3] al [6].");

EsperantoGameConcepts[GameConcepts.DEBT] = new TranslatedGameConcept("Ŝuldo", "\u015Culdo lasas vin a\u0109eti karton nun kaj pagi \u011Din poste.\nIuj kartoj havas [D] en \u011Dia kosto. \u0108i tio signifas \u015Duldon; vi ne povas iom ajn da [] por a\u0109eti [D] kostojn, sed anstata\u016D prenas tiom da [D] jetonoj.\nDum vi havas [D] jetonojn, vi ne povas a\u0109eti kartojn a\u016D pagi Okazojn a\u016D Projektojn;\nen via A\u0109etfazo, amba\u016D anta\u016D kaj post vi a\u0109etas kartojn (kaj inter a\u0109etoj), vi povas repagi [D] po [1] po [1D].\nRepago de [D] ne uzas A\u0109eton; \u011Di nur estas ion, kiun vi povas fari en via A\u0109etfazo.\nEkzemple vi havus [3] kaj decidus a\u0109eti Urbokvartalo.\nVi prenas [8D] kaj tuj repagi [3D] kun via [3].\nJe via sekva vico, vi ne povas jam a\u0109eti kartojn, vi devas repagi la ceteran [5D] unue.\nSe vi havis [7] vi povus repagi la [5D] kaj poste ankora\u016D a\u0109eti ion por [2], a\u016D a\u0109eti alian Urbokvartalon.\nKelkaj a\u0135oj kostas amba\u016D [] kaj [D]; vi devas havi la [] por pagi tiujn.\n\n[D] ne estas []; kiam kartoj inspektas la kosto de karto en moneroj, ili ignoras [D].\nKarto kun [D] kosto kaj ne [] kosto havas implican [0] en \u011Dia kosto.\nKiam vi komparas kostojn, [D] estas pli ol nenio, sed nek pli nek malpli ol [] kvanto.\nVi povas Aliformi Urbokvartalon en Reganton, kiu anka\u016D kostas [8D], kaj povas Aliformi Urbokvartalon en Bienon, kiu kostas \"\u011Dis\" [2] kaj [8D].\nKavaliroj rubigas kartojn kostantajn de [3] al [6]; kartoj kun [D] en ilia kosto ne kostas de [3] al [6].");

EsperantoGameConcepts[GameConcepts.WAYS] = new TranslatedGameConcept("Vojoj", "Vojoj estas alia speco de horizontala karto.\n\u0108iu donas al \u0109iuj Agkartoj alian elekton, tra la tuta ludo.\nEkzemple, en ludo kun Vojo de la \u015Cafo, \u0109iun fojon vi ludas Agkarton, vi povas a\u016D fari \u011Dian kutiman kapablon, a\u016D nur ricevi +[2] el \u011Di.\nVojoj nur anstata\u016Digas la supran parton de kartoj kun disiganta linio; la cetero ankora\u016D okazas kiam ajn \u011Di diras.\n");

EsperantoGameConcepts[GameConcepts.DEBT_COSTS] = new TranslatedGameConcept("Debt Costs", "Debt lets you buy a card now and pay for it later. Some cards have [D] in the cost. This represents debt; \nyou don't pay any [] for a [D] cost, but instead take that many [D] tokens. While you have [D] tokens, you can't buy cards or pay for Events or Projects;\nduring your turns, you can pay off [D] at [1] per [1D]. Paying off [D] doesn't use up a Buy. \nSo for example you might have [3] and decide to buy City Quarter. You take [8D] and immediately pay off [3D] with your [3].\nOn your next turn you can't buy cards yet, you still have to pay off the remaining [5D] first. \nIf you had [7] you could pay off the [5D] and still buy something for [2], or buy another City Quarter.\nA few things cost both [] and [D]; you have to have the [] to pay for those.\n\n[D] isn't []; when cards check a card's cost in coins, they ignore [D]. A card with a [D] cost and no [] cost has an implicit [0] in its cost.\nWhen comparing costs, [D] is more than nothing, but neither more nor less than a [] amount. You can Remodel a City Quarter into an Overlord.");

EsperantoGameConcepts[GameConcepts.ON_PLAY_DEBT] = new TranslatedGameConcept("On-play Debt", "Some cards give [D] when played; +[3D] gives you 3 debt tokens.\nWhile you have [D] tokens, you can't buy cards or pay for Events or Projects; \nduring your turns, you can pay off [D] at [1] per [1D]. Paying off [D] doesn't use up a Buy. \nSo for example you could play Root Cellar and get [3D]. \nIn your Buy phase you play 5 Coppers, pay off the [3D], and have [2] left to spend.\n[D] lasts between turns, as long as it hasn't been paid.");

EsperantoGameConcepts[GameConcepts.SHADOW] = new TranslatedGameConcept("Shadow", "Shadow cards can be played from your deck. They have unique backs, and go to the bottom of your deck when shuffling.\nWhenever you can play an Action card from your hand - whether normally, or via a card like Throne Room - you can choose to use that play on a Shadow card from your deck.\nShadow cards aren't in your hand when they're in your deck; you can't discard them and so on.\nYou just get to play them from your deck. You can also play them normally from your hand if you draw them.");

EsperantoGameConcepts[GameConcepts.OMEN_PROPHECY] = new TranslatedGameConcept("Omens & Prophecies", "An Omen causes the game to include a Prophecy, and has a +1 [Sun] ability. The Prophecy starts with [Sun] tokens on it (5 with 2 players), and +1 [Sun] \nremoves a token from the Prophecy. At the start of the game, the ability on the Prophecy doesn't do anything; \nwhen the last token is removed, the Prophecy ability becomes active, changing the rest of the game. \nFor example in a game with Rustic Village and Great Leader, each time you play Rustic Village, you remove a token from Great Leader; \nonce it has no tokens on it, every Action played will produce +1 Action.");