"use strict";

webclient.component("userPrefInteger", {
    bindings: {
        pref: "="
    },
    controllerAs: "userPref",
    controller: ['preferences', function (preferences) {
        var userPref = this;

        userPref.update = function () {
            preferences.setUserPreference(userPref.pref);
        };

        userPref.shift = function (shiftAmount) {
            userPref.pref.arguments += shiftAmount;
            userPref.pref.arguments = Math.max(userPref.pref.id.args.min, Math.min(userPref.pref.id.args.max, userPref.pref.arguments));
            userPref.update();
        };
    }],
    template: "\n        <hold-button class=\"minus-button pref-button\" shift-action=\"userPref.shift\" shift-amount=\"-1\"></hold-button>\n        <input \n          class=\"numeric-button\"\n          type=\"number\"\n          autocomplete=\"off\" \n          min=\"{{userPref.pref.id.args.min}}\"\n          max=\"{{userPref.pref.id.args.max}}\" \n          ng-change=\"userPref.update()\"\n          ng-model=\"userPref.pref.arguments\"/>\n        <hold-button class=\"plus-button pref-button\" shift-action=\"userPref.shift\" shift-amount=\"1\"></hold-button>\n"
});