"use strict";
// from https://stackoverflow.com/questions/15731634/how-do-i-handle-right-click-events-in-angular-js#15732476

webclient.directive('ngRightClick', ['$parse', function ($parse) {
	return {
		restrict: 'A',
		link: function link(scope, element, attrs) {
			var fn = $parse(attrs.ngRightClick);
			element.bind('contextmenu', function (event) {
				scope.$apply(function () {
					// commented out by Ingix to allow the normal right click menu
					event.preventDefault();
					event.stopPropagation();
					fn(scope, { $event: event });
				});
			});
		}
	};
}]);