"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ErrorMachine = function () {
	function ErrorMachine(errorMap, className) {
		_classCallCheck(this, ErrorMachine);

		try {
			Object.keys(errorMap);
		} catch (error) {

			console.log('ERROR Class ErrorMachine: expects object that responds to Object.keys(object).');
			trow(error);
		}

		if (!(typeof className === 'string')) {
			throw 'ERROR Class ErrorMachine: expects className to be a String';
		}

		this.map = errorMap;
		this.className = className;
		this.showTrace = true;
	}

	_createClass(ErrorMachine, [{
		key: 'errors',
		value: function errors() {

			return this.map;
		}
	}, {
		key: 'error',
		value: function error(message) {
			var object = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;


			console.log('ERROR generated by class ' + this.className + ': ' + message);

			if (this.showTrace) {
				console.trace();
			}

			if (object !== undefined) {
				console.log("   Offending object %o", object);
			}

			return message;
		}
	}]);

	return ErrorMachine;
}();