"use strict";

webclient.component("tableChat", {
    bindings: {
        condensed: "="
    },
    controller: ['tableService', 'chat', '$rootScope', 'preferences', function (tableService, chat, $rootScope, preferences) {
        var self = this;
        var scope = $rootScope.$new(true);
        self.chat = chat;
        self.isHost = function () {
            return tableService.heroIsHost();
        };
        self.sendChat = function () {
            return chat.sendToTable(tableService.getTableId());
        };
        self.getMessageText = function () {
            var key = Phrases.MESSAGE;
            if (!self.specChatActive()) key = tableService.heroIsPlayer() ? Phrases.MESSAGE_PLAYERS : Phrases.MESSAGE_SPECTATORS;
            return getPhrase(key);
        };

        scope.$on(Events.CHAT_MESSAGE_PROCESSED, function () {
            catchAll(scrollToBottom());
        });

        scope.$on(Events.LOBBY_PAGE_LOADED, function () {
            $rootScope.$digest();
            catchAll(forceScrollToBottom());
        });

        scope.$on(Events.TABLE_JOINED, function () {
            $rootScope.$digest();
            catchAll(forceScrollToBottom());
        });

        function scrollToBottom() {
            var chatElement = document.getElementsByClassName("table-chat-display")[0];
            if (isUndefined(chatElement)) return;
            if (isUndefined(chatElement.scrollHeight)) return;
            var shouldScrollToBottom = chatElement.scrollHeight - chatElement.clientHeight <= chatElement.scrollTop + 120;
            $rootScope.$digest();
            if (shouldScrollToBottom) chatElement.scrollTop = chatElement.scrollHeight - chatElement.clientHeight;
        }

        function forceScrollToBottom() {
            var chatElement = document.getElementsByClassName("table-chat-display")[0];
            if (isUndefined(chatElement)) return;
            if (isUndefined(chatElement.scrollHeight)) return;
            chatElement.scrollTop = chatElement.scrollHeight - chatElement.clientHeight;
        }

        self.title = LANGUAGE.getLobbyTexts['CHAT'];
        self.mouseOver = function () {
            return self.title = getPhrase(self.condensed ? Phrases.SHOW_CHAT : Phrases.HIDE_CHAT);
        };
        self.mouseLeave = function () {
            return self.title = LANGUAGE.getLobbyTexts['CHAT'];
        };

        self.toggleChat = function () {
            var booleanValue = self.condensed;
            self.condensed = !booleanValue;
            var preference = new UserPreference(UserPrefIds.CHAT_VISIBLE, booleanValue);
            preferences.setUserPrefValue(UserPrefIds.CHAT_VISIBLE, booleanValue);
        };
        self.toggleLabel = LANGUAGE.getTableRule[TableRuleIds.PLAYERS_CAN_SEE_SPECTATOR_CHAT];
        self.specChatActive = function () {
            if (tableService.hasTable()) {
                return tableService.getRuleValue(TableRuleIds.PLAYERS_CAN_SEE_SPECTATOR_CHAT);
            }
        };
        self.toggleSpecChat = function () {
            if (self.isHost()) {
                var rule = new TableRule(TableRuleIds.PLAYERS_CAN_SEE_SPECTATOR_CHAT, !self.specChatActive());
                tableService.changeRule(rule);
            }
        };
    }],
    template: "\n            <div\n              class=\"fieldset-div table-fieldset table-chat\"\n              ng-class=\"{condensed: $ctrl.condensed}\">\n                <div \n                  class=\"fieldset-title unselectable\"\n                  ng-click=\"$ctrl.toggleChat()\" \n                  style=\"cursor: pointer;\"\n                  ng-mouseover=\"$ctrl.mouseOver()\"\n                  ng-mouseleave=\"$ctrl.mouseLeave()\">\n                    {{$ctrl.title}}\n                </div>\n                <div class=\"table-toggle-container\">\n                    <div class=\"toggle-label\">\n                        {{$ctrl.toggleLabel}}\n                    </div>\n                    <div\n                      class=\"switch-button\"\n                      ng-class=\"{active: $ctrl.specChatActive(),\n                                 clickable: $ctrl.isHost()}\"\n                      ng-click=\"$ctrl.toggleSpecChat()\">\n                        <div class=\"switch-button-slider\"></div>\n                    </div>\n                </div>\n                <div class=\"table-chat-display\">\n                    <div ng-repeat=\"chatLine in $ctrl.chat.chatLines track by chatLine.id\">\n                        <div class=\"log-line\" style=\"color: {{chatLine.senderColor}}; font-weight: bold;\"\n                            ng-if=\"chatLine.shouldShowSender\">{{chatLine.sender}}</div>\n                        <div class=\"log-line\" ng-class=\"{'chat-message': chatLine.isChatLine}\" style=\"color: {{chatLine.textColor}}; font-size: {{chatLine.size * 100}}%;\"\n                            ng-if=\"chatLine.message.length > 0\">{{::chatLine.message}}</div>\n                    </div>\n                </div>\n                <form ng-submit=\"$ctrl.sendChat()\">\n                    <input type=\"text\" class=\"table-chat-input\" placeholder=\"{{$ctrl.getMessageText()}}\" ng-model=\"$ctrl.chat.message\"/>\n                </form>\n            </div>"
});