"use strict";

var CzechCardModes = {};

CzechCardModes[CardModes.PAWN_CARD_ACTION] = "Karta & Akce";
CzechCardModes[CardModes.PAWN_CARD] = "+1 Karta";
CzechCardModes[CardModes.PAWN_ACTION] = "+1 Akce";
CzechCardModes[CardModes.PAWN_BUY] = "+1 Nákup";
CzechCardModes[CardModes.PAWN_COIN] = "+1 Mince";
CzechCardModes[CardModes.STEWARD_CARDS] = "+2 Karty";
CzechCardModes[CardModes.STEWARD_COINS] = "+2 Mince";
CzechCardModes[CardModes.STEWARD_TRASH] = "Vyhoď 2";
CzechCardModes[CardModes.MINION_COINS] = "+2 Mince";
CzechCardModes[CardModes.MINION_NEWHAND] = "Odlož";
CzechCardModes[CardModes.NOBLES_CARDS] = "+3 Karty";
CzechCardModes[CardModes.NOBLES_ACTIONS] = "+2 Akce";
CzechCardModes[CardModes.COURTIER_ACTION] = "+1 Akce";
CzechCardModes[CardModes.COURTIER_BUY] = "+1 Nákup";
CzechCardModes[CardModes.COURTIER_COINS] = "+3 Mince";
CzechCardModes[CardModes.COURTIER_GOLD] = "Vezmi si Zlato";
CzechCardModes[CardModes.NATIVE_VILLAGE_SET_ASIDE] = "Dej na stranu";
CzechCardModes[CardModes.NATIVE_VILLAGE_RETURN] = "Zvedni";
CzechCardModes[CardModes.PIRATE_SHIP_RAID] = "Zaútoč";
CzechCardModes[CardModes.PIRATE_SHIP_PROFIT] = "Získej";
CzechCardModes[CardModes.SCRYING_POOL_TOPDECK] = "Dej navrch";
CzechCardModes[CardModes.SCRYING_POOL_DISCARD] = "Odlož";
CzechCardModes[CardModes.LOAN_DISCARD] = "Odlož";
CzechCardModes[CardModes.LOAN_TRASH] = "Vyhoď";
CzechCardModes[CardModes.TRUSTY_STEED_CARDS_ACTIONS] = "Karty & Akce";
CzechCardModes[CardModes.TRUSTY_STEED_CARDS] = "+2 Karty";
CzechCardModes[CardModes.TRUSTY_STEED_ACTIONS] = "+2 Akce";
CzechCardModes[CardModes.TRUSTY_STEED_COINS] = "+2 Mince";
CzechCardModes[CardModes.TRUSTY_STEED_SILVERS] = "+4 Stříbrňáky";
CzechCardModes[CardModes.JESTER_THEY_GAIN] = "Protihráč si bere";
CzechCardModes[CardModes.JESTER_YOU_GAIN] = "Vezmi si";
CzechCardModes[CardModes.DUCHESS_KEEP] = "Nechej si";
CzechCardModes[CardModes.DUCHESS_DISCARD] = "Odlož";
CzechCardModes[CardModes.ORACLE_TOPDECK] = "Dej navrch";
CzechCardModes[CardModes.ORACLE_DISCARD] = "Odlož";
CzechCardModes[CardModes.JACK_KEEP] = "Nechej si";
CzechCardModes[CardModes.JACK_DISCARD] = "Odlož";
CzechCardModes[CardModes.SPICE_MERCHANT_CARDS] = "Karty, Akce";
CzechCardModes[CardModes.SPICE_MERCHANT_COINS] = "Mince, Nákup";
CzechCardModes[CardModes.CATACOMBS_TAKE] = "Dej si do ruky";
CzechCardModes[CardModes.CATACOMBS_DISCARD] = "Odlož";
CzechCardModes[CardModes.COUNT_DISCARD] = "Odlož";
CzechCardModes[CardModes.COUNT_TOPDECK] = "Dej navrch";
CzechCardModes[CardModes.COUNT_GAIN_COPPER] = "Vezmi si Meďák";
CzechCardModes[CardModes.COUNT_COINS] = "+3 Mince";
CzechCardModes[CardModes.COUNT_TRASH_HAND] = "Vyhoď karty z ruky";
CzechCardModes[CardModes.COUNT_GAIN_DUCHY] = "Vezmi si Vévodství";
CzechCardModes[CardModes.HUNTING_GROUNDS_ESTATES] = "Vezmi si 3 Statky";
CzechCardModes[CardModes.HUNTING_GROUNDS_DUCHY] = "Vezmi si Vévodství";
CzechCardModes[CardModes.IRONMONGER_KEEP] = "Nechej si";
CzechCardModes[CardModes.IRONMONGER_DISCARD] = "Odlož";
CzechCardModes[CardModes.SCAVENGER_DISCARD] = "Odlož";
CzechCardModes[CardModes.SCAVENGER_KEEP] = "Pokračuj";
CzechCardModes[CardModes.SQUIRE_ACTIONS] = "+2 Akce";
CzechCardModes[CardModes.SQUIRE_BUYS] = "+2 Nákupy";
CzechCardModes[CardModes.SQUIRE_SILVER] = "Vezmi si Stříbrňák";
CzechCardModes[CardModes.MERCENARY_DONT_TRASH] = "Nevyhazuj";
CzechCardModes[CardModes.DOCTOR_TRASH] = "Vyhoď";
CzechCardModes[CardModes.DOCTOR_DISCARD] = "Odlož";
CzechCardModes[CardModes.DOCTOR_TOPDECK] = "Dej navrch";
CzechCardModes[CardModes.AMULET_COIN] = "+1 Mince";
CzechCardModes[CardModes.AMULET_TRASH] = "Vyhoď";
CzechCardModes[CardModes.AMULET_SILVER] = "Vezmi si Stříbrňák";
CzechCardModes[CardModes.MISER_COPPER_TO_MAT] = "Dej Meďák na Desku";
CzechCardModes[CardModes.MISER_PROFIT] = "Získej";
CzechCardModes[CardModes.MESSENGER_DISCARD] = "Odlož";
CzechCardModes[CardModes.MESSENGER_KEEP] = "Nechej si";
CzechCardModes[CardModes.QUEST_ATTACK] = "Zaútoč";
CzechCardModes[CardModes.QUEST_CURSES] = "2 Kletby";
CzechCardModes[CardModes.QUEST_CARDS] = "6 Karty";
CzechCardModes[CardModes.TEACHER_CARD] = "Karta";
CzechCardModes[CardModes.TEACHER_ACTION] = "Akce";
CzechCardModes[CardModes.TEACHER_BUY] = "Nákup";
CzechCardModes[CardModes.TEACHER_COIN] = "Mince";
CzechCardModes[CardModes.LIBRARY_DRAW_IT] = "Nechej si";
CzechCardModes[CardModes.LIBRARY_SKIP_IT] = "Přeskoč tah";
CzechCardModes[CardModes.GLADIATOR_PRETEND] = "Neukazuj";
CzechCardModes[CardModes.SPRAWLING_CASTLE_ESTATES] = "Vezmi si 3 Statky";
CzechCardModes[CardModes.SPRAWLING_CASTLE_DUCHY] = "Vezmi si Vévodství";
CzechCardModes[CardModes.CHARM_BUY_MONEY] = "+Nákup, +2 Mince";
CzechCardModes[CardModes.CHARM_COPY] = "Vezmi si Kartu";
CzechCardModes[CardModes.WILD_HUNT_CARDS] = "Karty";
CzechCardModes[CardModes.WILD_HUNT_POINTS] = "Body";
CzechCardModes[CardModes.PRINCE_SET_ASIDE] = "Dej na stranu";
CzechCardModes[CardModes.PRINCE_NO] = "Pokračuj";
CzechCardModes[CardModes.HAMLET_ACTION] = "Odlož pro Akci";
CzechCardModes[CardModes.HAMLET_BUY] = "Odlož pro Nákup";
CzechCardModes[CardModes.HAMLET_BOTH] = "Oba";
CzechCardModes[CardModes.GOVERNOR_CARDS] = "+3 Karty";
CzechCardModes[CardModes.GOVERNOR_TREASURE] = "Vezmi si Zlato";
CzechCardModes[CardModes.GOVERNOR_REMODEL] = "Vyhoď & Vezmi si";
CzechCardModes[CardModes.BLESSED_VILLAGE_NOW] = "Hned si vezmi";
CzechCardModes[CardModes.BLESSED_VILLAGE_LATER] = "Příští tah";
CzechCardModes[CardModes.PIXIE_TRASH] = "Vyhoď";
CzechCardModes[CardModes.PIXIE_KEEP] = "Nechej si";
CzechCardModes[CardModes.SACRED_GROVE_RECEIVE] = "Přijmi to";
CzechCardModes[CardModes.SACRED_GROVE_NO] = "Ne, děkuji";
CzechCardModes[CardModes.ZOMBIE_SPY_DISCARD] = "Odlož";
CzechCardModes[CardModes.ZOMBIE_SPY_TOPDECK] = "Dej navrch";
CzechCardModes[CardModes.START_GAME] = "Začít hru";
CzechCardModes[CardModes.PEARL_DIVER_TOPDECK] = CzechPhrases[Phrases.TOP];
CzechCardModes[CardModes.PEARL_DIVER_BOTTOMDECK] = CzechPhrases[Phrases.BOTTOM];
CzechCardModes[CardModes.SCEPTER_FAIL_ACTION] = "Neúspěšná Akce";
CzechCardModes[CardModes.SCEPTER_ADD_COINS] = "+2 Mince";
CzechCardModes[CardModes.TREASURER_FAIL] = "Nic nedělat";
CzechCardModes[CardModes.TREASURER_KEY] = "Vezmi si Klíč";
CzechCardModes[CardModes.BORDER_GUARD_LANTERN] = "Lucerna";
CzechCardModes[CardModes.BORDER_GUARD_HORN] = "Klakson";
CzechCardModes[CardModes.BORDER_GUARD_FAIL] = "Nic nedělat";
CzechCardModes[CardModes.PAGEANT_YES] = "+1 Měďák";
CzechCardModes[CardModes.PAGEANT_NO] = "Ne, děkuji";
CzechCardModes[CardModes.SINISTER_PLOT_ADD] = "Přidat žeton";
CzechCardModes[CardModes.SINISTER_PLOT_DRAW] = "Vezmi si";
CzechCardModes[CardModes.PLAY] = "Zahraj";
CzechCardModes[CardModes.DONT_PLAY] = "Nehraj";
CzechCardModes[CardModes.VILLAGE_GREEN_NOW] = "Teď";
CzechCardModes[CardModes.VILLAGE_GREEN_LATER] = "Příští tah";
CzechCardModes[CardModes.BARGE_NOW] = "Teď";
CzechCardModes[CardModes.BARGE_LATER] = "Příští tah";
CzechCardModes[CardModes.SCRAP_CARD] = "+1 Karta";
CzechCardModes[CardModes.SCRAP_ACTION] = "+1 Akce";
CzechCardModes[CardModes.SCRAP_BUY] = "+1 Nákup";
CzechCardModes[CardModes.SCRAP_COIN] = "+1 Mince";
CzechCardModes[CardModes.SCRAP_SILVER] = "Vezmi si Stříbrňák";
CzechCardModes[CardModes.SCRAP_HORSE] = "Vezmi si Koně";
CzechCardModes[CardModes.MODE_OF_THE_BUTTERFLY] = "Motýl";
CzechCardModes[CardModes.MODE_OF_THE_CAMEL] = "Velbloud";
CzechCardModes[CardModes.MODE_OF_THE_CHAMELEON] = "Chameleon";
CzechCardModes[CardModes.MODE_OF_THE_FROG] = "Žába";
CzechCardModes[CardModes.MODE_OF_THE_GOAT] = "Koza";
CzechCardModes[CardModes.MODE_OF_THE_HORSE] = "Kůň";
CzechCardModes[CardModes.MODE_OF_THE_MOLE] = "Krtek";
CzechCardModes[CardModes.MODE_OF_THE_MONKEY] = "Opice";
CzechCardModes[CardModes.MODE_OF_THE_MOUSE] = "Myš";
CzechCardModes[CardModes.MODE_OF_THE_MULE] = "Mula";
CzechCardModes[CardModes.MODE_OF_THE_OTTER] = "Vydra";
CzechCardModes[CardModes.MODE_OF_THE_OWL] = "Sova";
CzechCardModes[CardModes.MODE_OF_THE_OX] = "Vůl";
CzechCardModes[CardModes.MODE_OF_THE_PIG] = "Prase";
CzechCardModes[CardModes.MODE_OF_THE_RAT] = "Krysa";
CzechCardModes[CardModes.MODE_OF_THE_SEAL] = "Tuleň";
CzechCardModes[CardModes.MODE_OF_THE_SHEEP] = "Ovce";
CzechCardModes[CardModes.MODE_OF_THE_SQUIRREL] = "Veverka";
CzechCardModes[CardModes.MODE_OF_THE_TURTLE] = "Želva";
CzechCardModes[CardModes.MODE_OF_THE_WORM] = "Červ";
CzechCardModes[CardModes.BUTTERFLY_RETURN_IT] = "Vrať to";
CzechCardModes[CardModes.BUTTERFLY_DO_NOTHING] = "Nic nedělat";
CzechCardModes[CardModes.WAYFARER_SILVER] = "Vezmi si Stříbrňák";
CzechCardModes[CardModes.DESPERATION_CURSE] = "Vezmi si Kletbu";

CzechCardModes[ExtraTurnTypes.MISSION] = CzechCardNames[CardNames.MISSION].singular;
CzechCardModes[ExtraTurnTypes.OUTPOST] = CzechCardNames[CardNames.OUTPOST].singular;
CzechCardModes[ExtraTurnTypes.POSSESSION] = CzechCardNames[CardNames.POSSESSION].singular;
CzechCardModes[ExtraTurnTypes.FLEET] = CzechCardNames[CardNames.FLEET].singular;
CzechCardModes[ExtraTurnTypes.SEIZE_THE_DAY] = CzechCardNames[CardNames.SEIZE_THE_DAY].singular;