"use strict";

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PremoveLayer = function (_StackViewLayer) {
    _inherits(PremoveLayer, _StackViewLayer);

    function PremoveLayer(rootNode, cardStack) {
        _classCallCheck(this, PremoveLayer);

        var _this = _possibleConstructorReturn(this, (PremoveLayer.__proto__ || Object.getPrototypeOf(PremoveLayer)).call(this, rootNode, cardStack));

        _this.node.style.zIndex = 12;
        _this.node.classList.add("premove-layer");
        _this.isHovered = false;
        _this.isWayHovered = false;

        _this.clipNode = document.createElement("premove-layer-clip-border");
        _this.node.appendChild(_this.clipNode);
        _this.innerNode = document.createElement("premove-layer-inner");
        _this.clipNode.appendChild(_this.innerNode);
        _this.counterNode = document.createElement("premove-counter");
        _this.node.appendChild(_this.counterNode);

        _this.counterNode.onclick = function (e) {
            _this.cardStack.cancelPremove();
            e.stopPropagation();
        };

        _this.buttonBboxes = [[0.2, 0.02, 0.6, 0.2], [0.02, 0.1, 0.2, 0.8], [0.2, 0.78, 0.6, 0.2], [0.78, 0.1, 0.2, 0.8]];
        var buttonIcons = ["discard", "buy", "react", "play"];
        _this.buttons = _this.buttonBboxes.map(function (bbox, i) {
            var buttonNode = document.createElement("premove-button");
            _this.node.appendChild(buttonNode);
            var iconNode = document.createElement("premove-button-icon");
            buttonNode.appendChild(iconNode);
            iconNode.style.backgroundImage = "url(images/buttons/" + buttonIcons[i] + ".png)";

            buttonNode.onclick = function (e) {
                _this.cardStack.premove(i + 1);
                e.stopPropagation();
            };
            return buttonNode;
        });

        _this.cardModesNode = document.createElement("premove-card-modes");
        _this.node.appendChild(_this.cardModesNode);
        return _this;
    }

    _createClass(PremoveLayer, [{
        key: "redraw",
        value: function redraw(w, h) {
            var _this2 = this;

            var premoves = isUndefined(this.cardStack.game) ? [] : this.cardStack.game.questionModel.getPremoves(this.cardStack);
            var color = "#46B5FC";

            var r = Math.max(w, h) * 0.04;
            var m = r / 8;
            this.node.style.borderWidth = r / 3 + "px";
            this.node.style.borderRadius = r + "px";
            assignPositionToNode(new Rectangle(0, 0, w - 2 * m - r / 3, h - 2 * m - r / 3), this.node);
            if (premoves.length > 0) {
                this.node.style.borderColor = color;
                this.clipNode.classList.remove("invisible");
                this.counterNode.classList.remove("invisible");
                var size = Math.min(h, w * 0.23);
                var x = w - size;
                var y = 0;
                assignPositionToNode(new Rectangle(x, y, size, size), this.counterNode);
                this.counterNode.style.color = "white";
                this.counterNode.style.fontSize = size + "px";
                this.counterNode.style.fontFamily = "Montserrat";
                fitTextInNode(premoves.length, this.counterNode, new Rectangle(0, 0, size * 0.8, size * 0.8));
            } else {
                this.node.style.borderColor = "rgba(0, 0, 0, 0)";
                this.clipNode.classList.add("invisible");
                this.counterNode.classList.add("invisible");
            }

            if (this.cardStack.canPremove) {
                this.buttons.forEach(function (b, i) {
                    b.classList.remove("invisible");

                    var _buttonBboxes$i = _slicedToArray(_this2.buttonBboxes[i], 4),
                        buttonX = _buttonBboxes$i[0],
                        buttonY = _buttonBboxes$i[1],
                        buttonW = _buttonBboxes$i[2],
                        buttonH = _buttonBboxes$i[3];

                    assignPositionToNode(new Rectangle(buttonX * w, buttonY * h, buttonW * w, buttonH * h), b);
                });
            } else {
                this.buttons.forEach(function (b, i) {
                    b.classList.add("invisible");
                });
            }

            clearChildNodes(this.cardModesNode);
            if (this.cardStack.canPremoveModes) {
                var modes = this.cardStack.topCard.cardName.cardModes.forEach(function (mode) {
                    if (mode.premovable) {
                        var modeNode = document.createElement("premove-card-mode");
                        _this2.cardModesNode.appendChild(modeNode);
                        modeNode.style.backgroundImage = "url(" + mode.imageName + ")";
                        modeNode.onclick = function () {
                            return _this2.cardStack.game.questionModel.premoveCardMode(mode);
                        };
                    }
                });
            }
        }
    }]);

    return PremoveLayer;
}(StackViewLayer);