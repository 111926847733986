"use strict";
// Use like <question-select ng-model="ctrl.question"> </question-select> in HTML
//

webclient.component("questionSelect", {

    bindings: {

        question: "=ngModel"
    },

    controllerAs: "ctrl",

    controller: [function () {
        var _this = this;

        var ctrl = this;

        ctrl.value = undefined;
        ctrl.selectQuestionClicked = function (event) {
            return event.stopPropagation();
        };
        ctrl.ensureSelection = function () {
            if (ctrl.value === undefined) {
                ctrl.value = ctrl.question.answerLabels[0];
            }return '';
        };
        ctrl.selectionChanged = function () {
            return ctrl.question.setAnswer(_this.value);
        };
    }],

    templateUrl: 'html/related-questions/select-1.9.2.2.html'
});