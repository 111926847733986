"use strict";

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var StackViewLayer = function StackViewLayer(rootNode, cardStack) {
	_classCallCheck(this, StackViewLayer);

	this.cardStack = cardStack;
	this.node = document.createElement("div");
	this.node.classList.add("card-stack-layer");
	rootNode.appendChild(this.node);
};