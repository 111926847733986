"use strict";

webclient.service('friendService', ['$rootScope', 'serverMessenger', function ($rootScope, serverMessenger) {
    var self = this;
    var scope = $rootScope.$new(true);
    var friendActivities = [];

    self.getFriendActivities = function () {
        return friendActivities;
    };

    self.joinTable = function (tableId) {
        serverMessenger.joinTable(tableId, false);
    };

    scope.$on(Events.AUTOMATCH_STATUS, function (e, status) {
        if (status.friendActivities.length !== friendActivities.length || status.friendActivities.some(function (activity, i) {
            return !activity.equals(friendActivities[i]);
        })) {
            friendActivities = status.friendActivities;
            $rootScope.safeApply();
        }
    });
}]);