"use strict";

webclient.component("cardWindowConnector", {
    bindings: {
        card: "<",
        index: "<",
        zone: "<"
    },
    controller: ["$scope", "gamestate", function ($scope, gamestate) {
        var self = this;

        var attachmentRadius = window.innerHeight * 0.008;
        var tangentLength = window.innerWidth * 0.1;
        var curvePoints = 40;
        var movementTime = 1000;
        var delayTime = 2000;

        // Manually did this bezier nonsense because I can
        var recompute = function recompute() {
            var zoneBbox = self.zone.bbox;
            if (self.card.cardStack === null) return;
            var cardBbox = self.card.cardStack.bbox;
            var zoneMiddleX = zoneBbox.x + zoneBbox.width / 2;
            var cardMiddleX = cardBbox.x + cardBbox.width / 2;

            var cardAttachOnLeft = void 0,
                zoneAttachOnLeft = void 0;

            if (zoneMiddleX < cardMiddleX) {
                zoneAttachOnLeft = false;
                cardAttachOnLeft = zoneBbox.x + zoneBbox.width < cardMiddleX;
            } else {
                zoneAttachOnLeft = true;
                cardAttachOnLeft = zoneBbox.x < cardMiddleX;
            }
            var sameSideAttach = zoneAttachOnLeft === cardAttachOnLeft;

            var zoneAttachX = (zoneAttachOnLeft ? 0 : zoneBbox.width) + zoneBbox.x;
            var cardAttachX = (cardAttachOnLeft ? 0 : cardBbox.width) + cardBbox.x;
            var zoneAttachY = zoneBbox.y + zoneBbox.height / 3;
            var cardAttachY = cardBbox.y + cardBbox.height / 3 + attachmentRadius * 2 * self.index;

            var cardAttachPointTop = [cardAttachX, cardAttachY - attachmentRadius];
            var cardAttachPointBottom = [cardAttachX, cardAttachY + attachmentRadius];
            var zoneAttachPointTop = [zoneAttachX, zoneAttachY - attachmentRadius];
            var zoneAttachPointBottom = [zoneAttachX, zoneAttachY + attachmentRadius];

            var cardTangencyPointTop = [cardAttachX + (cardAttachOnLeft ? -tangentLength : tangentLength), cardAttachY - attachmentRadius];
            var cardTangencyPointBottom = [cardAttachX + (cardAttachOnLeft ? -tangentLength : tangentLength), cardAttachY + attachmentRadius];
            var zoneTangencyPointTop = [zoneAttachX + (zoneAttachOnLeft ? -tangentLength : tangentLength), zoneAttachY - attachmentRadius];
            var zoneTangencyPointBottom = [zoneAttachX + (zoneAttachOnLeft ? -tangentLength : tangentLength), zoneAttachY + attachmentRadius];

            if (zoneAttachY < cardAttachY) {
                zoneTangencyPointTop[0] += (zoneAttachOnLeft ? -attachmentRadius : attachmentRadius) * 2;
                cardTangencyPointBottom[0] += (cardAttachOnLeft ? -attachmentRadius : attachmentRadius) * 2;
            } else {
                zoneTangencyPointBottom[0] += (zoneAttachOnLeft ? -attachmentRadius : attachmentRadius) * 2;
                cardTangencyPointTop[0] += (cardAttachOnLeft ? -attachmentRadius : attachmentRadius) * 2;
            }

            var firstCurveHandles = sameSideAttach ? [cardAttachPointTop, cardTangencyPointTop, zoneTangencyPointBottom, zoneAttachPointBottom] : [cardAttachPointTop, cardTangencyPointTop, zoneTangencyPointTop, zoneAttachPointTop];

            var secondCurveHandles = sameSideAttach ? [zoneAttachPointTop, zoneTangencyPointTop, cardTangencyPointBottom, cardAttachPointBottom] : [zoneAttachPointBottom, zoneTangencyPointBottom, cardTangencyPointBottom, cardAttachPointBottom];

            var firstCurvePoints = [firstCurveHandles[0]];
            var secondCurvePoints = [secondCurveHandles[0]];

            var binom = function binom(i) {
                return i === 1 || i === 2 ? 3 : 1;
            };
            var bezier = function bezier(handles, t, dim) {
                return handles.map(function (handle, i) {
                    return binom(i) * Math.pow(1 - t, 3 - i) * Math.pow(t, i) * handle[dim];
                }).reduce(function (a, b) {
                    return a + b;
                }, 0);
            };
            for (var i = 1; i < curvePoints; i++) {
                var firstPoint = [];
                var secondPoint = [];
                var t = i / curvePoints;
                for (var dim = 0; dim < 2; dim++) {
                    firstPoint[dim] = bezier(firstCurveHandles, t, dim);
                    secondPoint[dim] = bezier(secondCurveHandles, t, dim);
                }
                firstCurvePoints.push(firstPoint);
                secondCurvePoints.push(secondPoint);
            }
            firstCurvePoints.push(firstCurveHandles[3]);
            secondCurvePoints.push(secondCurveHandles[3]);

            var clipPath = firstCurvePoints.map(function (p) {
                return p[0] + "px " + p[1] + "px";
            }).join(", ") + ", " + secondCurvePoints.map(function (p) {
                return p[0] + "px " + p[1] + "px";
            }).join(", ");

            self.connectorStyle = "\n                clip-path: polygon(" + clipPath + ");\n            ";

            self.innerCurvePoints = firstCurvePoints.map(function (p, i) {
                return p.map(function (coord, dim) {
                    return (coord + secondCurvePoints[curvePoints - i][dim]) / 2;
                });
            });
        };
        recompute();

        var innerKeyframeIndex = 0;

        var innerTick = function innerTick() {
            innerKeyframeIndex = (innerKeyframeIndex + 1) % curvePoints;
            var innerKeyframe = self.innerCurvePoints[innerKeyframeIndex];
            var factor = innerKeyframeIndex / curvePoints;
            var opacity = 2 * (0.5 - Math.abs(0.5 - factor));
            self.innerStyle = "left: " + innerKeyframe[0] + "px; top: " + innerKeyframe[1] + "px; opacity: " + opacity + ";";
            safeDigest($scope);
            setTimeout(innerTick, innerKeyframeIndex > 0 ? movementTime / curvePoints : delayTime);
        };
        innerTick();

        $scope.$on(Events.REDRAW, function () {
            recompute();
        });
    }],
    template: "\n        <div class=\"card-window-connector\" style=\"{{$ctrl.connectorStyle}}\">\n            <div class=\"card-window-connector-inner\" style=\"{{$ctrl.innerStyle}}\"></div>\n        </div>\n    "
});