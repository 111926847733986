"use strict";

webclient.service("cardService", ['$rootScope', 'preferences', function ($rootScope, preferences) {
    var cardService = this;

    var scope = $rootScope.$new(true);

    cardService.getCardObjects = function () {
        var showAll = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        if (showAll) return getValues(CardNames).filter(function (c) {
            return !c.isFake && BANNED_CARDS.indexOf(c) === -1;
        });else return getValues(CardNames).filter(function (c) {
            return c.isKingdomPile() && !c.isFake && BANNED_CARDS.indexOf(c) === -1 && !c.isRuin();
        });
    };

    cardService.getOwnedCardObjects = function () {
        if (cardService.ownership) {
            return cardService.getCardObjects().filter(function (c) {
                return cardService.ownership.ownsCard(c);
            });
        } else {
            return cardService.getCardObjects();
        }
    };

    cardService.ownershipEndsAt = function (expansion) {
        return cardService.ownership.expansionEndsAt(expansion);
    };

    cardService.expansions = getValues(Expansions).filter(function (e) {
        return e.visible;
    });

    function reloadAllCards() {
        cardService.cardObjects = cardService.getCardObjects();
        cardService.cards = cardService.cardObjects.filter(function (c) {
            return !c.isLandscape();
        });
        cardService.landscapes = cardService.cardObjects.filter(function (c) {
            return c.isLandscape();
        });
    }

    cardService.reloadOwnedCards = function () {
        var pref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;

        if (!isUndefined(pref)) {
            cardService.ownership = pref.arguments;
        } else {
            cardService.ownership = preferences.getUserPrefValue(UserPrefIds.CARD_OWNERSHIP);
        }
        cardService.ownedCardObjects = cardService.getOwnedCardObjects();
        cardService.ownedBoons = cardService.ownedCardObjects.includes(CardNames.DRUID) ? BOONS : [];
        cardService.ownedCards = cardService.ownedCardObjects.filter(function (c) {
            return !c.isLandscape();
        });
        cardService.ownedLandscapes = cardService.ownedCardObjects.filter(function (c) {
            return c.isLandscape();
        });
    };

    reloadAllCards();
    cardService.reloadOwnedCards();

    scope.$on(Events.OWNERSHIP_CHANGED, function (event, pref) {
        cardService.reloadOwnedCards(pref);
    });
}]);