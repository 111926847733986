"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Inspection = function (_GameStateChange) {
    _inherits(Inspection, _GameStateChange);

    function Inspection(game, playerIndex, cardIds, fromZoneIndex, toZoneIndex, isPublic, isLinkedToQuestion) {
        _classCallCheck(this, Inspection);

        var _this = _possibleConstructorReturn(this, (Inspection.__proto__ || Object.getPrototypeOf(Inspection)).call(this));

        _this.game = game;
        _this.playerIndex = playerIndex;
        _this.cardIds = cardIds;
        _this.fromZoneIndex = fromZoneIndex;
        _this.toZoneIndex = toZoneIndex;
        _this.isPublic = isPublic;
        _this.isLinkedToQuestion = isLinkedToQuestion;
        return _this;
    }

    _createClass(Inspection, [{
        key: "execute",
        value: function execute(game, terminator) {
            if (this.toZoneIndex === -1) {
                terminator();
                return;
            }

            var toZone = game.state.zones[this.toZoneIndex];
            var fromZone = game.state.zones[this.fromZoneIndex];
            var cardIds = this.cardIds;
            if (toZone === fromZone) {
                cardIds = cardIds.filter(function (id) {
                    return !game.state.cards[id].cardStacks.some(function (s) {
                        return s.location === toZone;
                    });
                });
            }
            if (cardIds.length === 0) {
                terminator();
                return;
            }

            var props = getAnimationSettings().map.get(AnimationClassIds.TRANSITION);

            var finishCount = 0;
            var callback = function callback() {
                finishCount++;
                if (finishCount === cardIds.length) terminator();
            };
            var transitionStacks = cardIds.map(function (id, index) {
                var outStack = fromZone.spliceAnonymousCard(false);
                if (id > -1) {
                    outStack.anonymousCards--;
                    outStack.addCard(game.state.cards[id]);
                }
                return outStack;
            });
            transitionStacks.forEach(function (stack, index) {
                var id = cardIds[index];
                var targetStack = id === -1 ? toZone.addAnonymousCard() : toZone.addCard(game.state.cards[id]);
                stack.animator = new Animator(stack, toZone.getScript(fromZone, stack, targetStack, props), targetStack, callback);
                return stack;
            });
        }
    }, {
        key: "createAnimation",
        value: function createAnimation(game) {
            return new PileAffectingAnimation(this, this.toZoneIndex);
        }
    }], [{
        key: "parse",
        value: function parse(game, reader) {
            var inspectingPlayer = reader.readInt();
            var cardIds = reader.readInts();
            var fromZone = reader.readInt();
            var toZone = reader.readInt();
            var isPublic = reader.readBoolean();
            var isLinkedToQuestion = reader.readBoolean();
            return new Inspection(game, inspectingPlayer, cardIds, fromZone, toZone, isPublic, isLinkedToQuestion);
        }
    }]);

    return Inspection;
}(GameStateChange);