"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var StoreState = function () {
    function StoreState(timeoutService) {
        _classCallCheck(this, StoreState);

        this.timeoutService = timeoutService;
        this.runningTimer = undefined;
    }

    _createClass(StoreState, [{
        key: 'setTimer',
        value: function setTimer(callback, duration) {

            if (isDefined(this.runningTimer)) {

                this.timeoutService.cancel(this.runningTimer);
            }

            this.runningTimer = this.timeoutService(callback, duration);
        }
    }, {
        key: 'handleEvent',
        value: function handleEvent(event) {

            this.eventCountDown = this.eventCountDown - 1;

            if (this.eventCountDown < 0) {
                return;
            }

            assert(StoreState.validEvent(event));

            timeLog('Store event: ' + StoreState.description(event));

            if (event === StoreState.events.START) {

                this.setState(StoreState.states.ANSWERING_QUESTIONS);
            } else {

                var state = this.deriveNewState(event);

                this.setState(state);
                this.setTimeout(state);
            }
        }
    }, {
        key: 'deriveNewState',
        value: function deriveNewState(event) {

            var eventLabel = StoreState.eventLabel(event);
            var state = StoreState.stateChanges[this.state][eventLabel];

            return state;
        }
    }, {
        key: 'setState',
        value: function setState(state) {

            timeLog('Store state changes to: ' + StoreState.description(state));

            this.state = state === StoreState.states.KEEP_LAST_STATE ? this.state : state;
        }
    }, {
        key: 'setTimeout',
        value: function setTimeout(state) {

            var states = StoreState.states;
            var periods = StoreState.timeoutPeriods;
            var storeState = this;

            if (state === states.PENDING_REQUEST) {

                timeLog('Pending request timer set.');
                this.setTimer(function () {
                    StoreState.handleTimeout(storeState);
                }, periods.PUT_CUSTOMER_ON_HOLD);
            } else if (state === states.WAITING_CUSTOMER) {

                timeLog('Waiting customer timer set.');
                this.setTimer(function () {
                    StoreState.handleTimeout(storeState);
                }, periods.SERVER_UNREACHABLE);
            }
        }
    }, {
        key: 'waitingForStoreService',
        value: function waitingForStoreService() {

            return this.requestIsPending() || this.customerIsWaiting();
        }
    }, {
        key: 'requestIsPending',
        value: function requestIsPending() {
            return this.state === StoreState.states.PENDING_REQUEST;
        }
    }, {
        key: 'handleTimeout',
        value: function handleTimeout() {
            this.handleEvent(StoreState.events.TIMEOUT);
        }
    }, {
        key: 'serviceIsUnreachable',
        value: function serviceIsUnreachable() {
            return this.state === StoreState.states.TIMED_OUT_REQUEST;
        }
    }, {
        key: 'customerIsWaiting',
        value: function customerIsWaiting() {
            return this.state === StoreState.states.WAITING_CUSTOMER;
        }
    }, {
        key: 'customerIsRedirected',
        value: function customerIsRedirected() {
            return this.state === StoreState.states.REDIRECTED;
        }
    }, {
        key: 'answeringQuestions',
        value: function answeringQuestions() {
            return this.state === StoreState.states.ANSWERING_QUESTIONS;
        }
    }], [{
        key: 'validState',
        value: function validState(state) {
            return ObjectValues(StoreState.states).indexOf(state) !== -1;
        }
    }, {
        key: 'validEvent',
        value: function validEvent(event) {
            return ObjectValues(StoreState.events).indexOf(event) !== -1;
        }
    }, {
        key: 'stateLabel',
        value: function stateLabel(state) {
            return FindByValue(StoreState.states, state);
        }
    }, {
        key: 'eventLabel',
        value: function eventLabel(event) {
            return FindByValue(StoreState.events, event);
        }
    }, {
        key: 'handleTimeout',
        value: function handleTimeout(storeState) {
            return storeState.handleTimeout();
        }
    }, {
        key: 'description',
        value: function description(stateOrEvent) {
            return String(stateOrEvent).slice(7, -1);
        }
    }]);

    return StoreState;
}();

StoreState.timeoutPeriods = { // in Milliseconds

    PUT_CUSTOMER_ON_HOLD: 1000,
    SERVER_UNREACHABLE: 5000
};

StoreState.states = {

    ANSWERING_QUESTIONS: Symbol('Customer is answering questions.'),
    PENDING_REQUEST: Symbol('Exactly one pending request to the Store Server.'),
    WAITING_CUSTOMER: Symbol('Pending request to the Store Server is taking much time.'),
    TIMED_OUT_REQUEST: Symbol('The last request to the the Store Server timed out.'),
    REDIRECTED: Symbol('Redirected to our payment provider.'),
    KEEP_LAST_STATE: Symbol('Keep last state.'),
    UNEXPECTED_STATE: Symbol('Typo or missing state in StoreState.states.'),
    UNEXPECTED_EVENT: Symbol('Typo or missing event in StoreState.events.')
};

StoreState.events = {

    START: Symbol('Customer just entered store.'),
    TIMEOUT: Symbol('Timer of pending request to the Store Server ran out.'),
    REQUEST_ANSWERED: Symbol('Answer on pending request to the Store Server arrived.'),
    SENDING_REQUEST: Symbol('Setting timer for a pending request to the Store Server.'),
    REDIRECT: Symbol('Customer is redirected to payment provider.'),
    REDIRECT_FAILED: Symbol('Customer clicks redirected failed button.'),
    PAYMENT_SUCCEEDED: Symbol('Back from payment provider, subscription updated.'),
    PAYMENT_FAILED: Symbol('Back from payment provider, subscription not updated.')
};

StoreState.stateChanges = {};

// Read this for example as: when the customer is answering questions and
// triggers a REDIRECT event, then go to the state REDIRECTED.
StoreState.stateChanges[StoreState.states.ANSWERING_QUESTIONS] = {

    TIMEOUT: StoreState.states.KEEP_LAST_STATE,
    REQUEST_ANSWERED: StoreState.states.KEEP_LAST_STATE,
    SENDING_REQUEST: StoreState.states.PENDING_REQUEST,
    REDIRECT: StoreState.states.REDIRECTED,
    PAYMENT_SUCCEEDED: StoreState.states.KEEP_LAST_STATE,
    PAYMENT_FAILED: StoreState.states.KEEP_LAST_STATE,
    REDIRECT_FAILED: StoreState.states.ANSWERING_QUESTIONS
};

StoreState.stateChanges[StoreState.states.PENDING_REQUEST] = {

    TIMEOUT: StoreState.states.WAITING_CUSTOMER,
    REQUEST_ANSWERED: StoreState.states.ANSWERING_QUESTIONS,
    SENDING_REQUEST: StoreState.states.KEEP_LAST_STATE,
    REDIRECT: StoreState.states.KEEP_LAST_STATE,
    PAYMENT_SUCCEEDED: StoreState.states.KEEP_LAST_STATE,
    PAYMENT_FAILED: StoreState.states.KEEP_LAST_STATE,
    REDIRECT_FAILED: StoreState.states.KEEP_LAST_STATE
};

StoreState.stateChanges[StoreState.states.WAITING_CUSTOMER] = {

    TIMEOUT: StoreState.states.TIMED_OUT_REQUEST,
    REQUEST_ANSWERED: StoreState.states.ANSWERING_QUESTIONS,
    SENDING_REQUEST: StoreState.states.KEEP_LAST_STATE,
    REDIRECT: StoreState.states.KEEP_LAST_STATE,
    PAYMENT_SUCCEEDED: StoreState.states.KEEP_LAST_STATE,
    PAYMENT_FAILED: StoreState.states.KEEP_LAST_STATE,
    REDIRECT_FAILED: StoreState.states.KEEP_LAST_STATE
};

StoreState.stateChanges[StoreState.states.TIMED_OUT_REQUEST] = {

    TIMEOUT: StoreState.states.KEEP_LAST_STATE,
    REQUEST_ANSWERED: StoreState.states.ANSWERING_QUESTIONS,
    SENDING_REQUEST: StoreState.states.PENDING_REQUEST,
    REDIRECT: StoreState.states.KEEP_LAST_STATE,
    PAYMENT_SUCCEEDED: StoreState.states.KEEP_LAST_STATE,
    PAYMENT_FAILED: StoreState.states.KEEP_LAST_STATE,
    REDIRECT_FAILED: StoreState.states.KEEP_LAST_STATE
};

StoreState.stateChanges[StoreState.states.REDIRECTED] = {

    TIMEOUT: StoreState.states.KEEP_LAST_STATE,
    REQUEST_ANSWERED: StoreState.states.ANSWERING_QUESTIONS,
    SENDING_REQUEST: StoreState.states.PENDING_REQUEST,
    REDIRECT: StoreState.states.KEEP_LAST_STATE,
    PAYMENT_SUCCEEDED: StoreState.states.ANSWERING_QUESTIONS,
    PAYMENT_FAILED: StoreState.states.ANSWERING_QUESTIONS,
    REDIRECT_FAILED: StoreState.states.ANSWERING_QUESTIONS
};