"use strict";

webclient.component("spectatorUndoRequest", {
    transclude: true,
    controller: ['game', '$scope', function (game, $scope) {
        var self = this;

        $scope.$on(Events.REDRAW, function () {
            safeDigest($scope);
        });
        $scope.$on(Events.GAME_MODEL_CREATED, function () {
            safeDigest($scope);
        });
        self.shouldShow = function () {
            return game.metaGameModel.undoRequest !== null && isUndefined(game.playerModel.playerMe);
        };
        self.getWindowText = function () {
            var requester = game.state.players[game.metaGameModel.undoRequest.playerIndex].name;
            return getPhrase(Phrases.SPECTATOR_UNDO_REQUEST).replace('USERNAME', requester);
        };
    }],
    template: "\n            <modal-window ng-if=\"$ctrl.shouldShow()\">\n                <div class=\"timeout\">{{::$ctrl.getWindowText()}}</div>\n            </modal-window>\n        "
});