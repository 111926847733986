"use strict";

var GermanAbilityDescriptions = {};

GermanAbilityDescriptions[AbilityNames.BUY] = "Kaufe";
GermanAbilityDescriptions[AbilityNames.PLAY_ATTACK] = "Spiele";
GermanAbilityDescriptions[AbilityNames.DRAW_INSTRUCTIONS] = "Ziehe Argument0 Karte(n).";
GermanAbilityDescriptions[AbilityNames.PLAY_INSTRUCTIONS] = "Spiele";
GermanAbilityDescriptions[AbilityNames.RESOLVE_EVENT] = "RESOLVE_EVENT";
GermanAbilityDescriptions[AbilityNames.GAIN_BY_NAME] = "Nimm Argument0 (Argument1).";
GermanAbilityDescriptions[AbilityNames.FISHING_VILLAGE_ABILITY] = "+1 Aktion, +$1";
GermanAbilityDescriptions[AbilityNames.REMOVE_COST_REDUCTION] = "REMOVE_COST_REDUCTION";
GermanAbilityDescriptions[AbilityNames.HAVEN_ABILITY] = "Nimm Argument0 auf die Hand.";
GermanAbilityDescriptions[AbilityNames.SAVE_RETURN_TO_HAND] = "Nimm Argument0 auf die Hand.";
GermanAbilityDescriptions[AbilityNames.CARAVAN_ABILITY] = "+1 Karte";
GermanAbilityDescriptions[AbilityNames.MERCHANT_SHIP_ABILITY] = "+$2";
GermanAbilityDescriptions[AbilityNames.OUTPOST_ABILITY] = "[Outpost]";
GermanAbilityDescriptions[AbilityNames.TACTICIAN_ABILITY] = "+5 Karten, +1 Aktion, +1 Kauf";
GermanAbilityDescriptions[AbilityNames.WHARF_ABILITY] = "+2 Karten, +1 Kauf";
GermanAbilityDescriptions[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = "+1 Karte, nimm [^nHorse Traders] zurück auf deine Hand";
GermanAbilityDescriptions[AbilityNames.DUCHESS_MAY_GAIN] = "Nimm [^nDuchess].";
GermanAbilityDescriptions[AbilityNames.FOOLS_GOLD_TRASH] = "Entsorge [Fools Gold], um [^nGold] auf den Nachziehstapel zu nehmen.";
GermanAbilityDescriptions[AbilityNames.NOBLE_BRIGAND] = "Versuche, Deinen Gegnern [^nSilver] oder [^nGold] zu stehlen.";
GermanAbilityDescriptions[AbilityNames.HAGGLER_ABILITY] = "Nimm eine billigere Nicht-Punktekarte.";
GermanAbilityDescriptions[AbilityNames.INN_ABILITY] = "Mische Aktionen aus deinem Ablagestapel in deinen Nachziehstapel.";
GermanAbilityDescriptions[AbilityNames.MANDARIN] = "Lege alle deine Geldkarten im Spiel auf deinen Nachziehstapel.";
GermanAbilityDescriptions[AbilityNames.FARMLAND] = "Entsorge eine Handkarte und nimm eine Karte, die genau 2 Geld mehr kostet.";
GermanAbilityDescriptions[AbilityNames.GAIN_CHEAPER] = "Nimm eine billigere Karte";
GermanAbilityDescriptions[AbilityNames.GAIN_TWO_RUINS] = "Nimm 2 Ruinen.";
GermanAbilityDescriptions[AbilityNames.HUNTING_GROUNDS] = "Nimm [^nDuchy] oder 3 [^pEstate].";
GermanAbilityDescriptions[AbilityNames.SQUIRE_GAIN_ATTACK] = "Nimm eine Angriffskarte.";
GermanAbilityDescriptions[AbilityNames.URCHIN_TRANSFORM] = "Entsorge [^nUrchin] um [^nMercenary] zu nehmen.";
GermanAbilityDescriptions[AbilityNames.FEODUM_GAIN_SILVERS] = "Nimm 3 [^pSilver].";
GermanAbilityDescriptions[AbilityNames.DOCTOR_OVERPAY] = "Karten deines Nachziehstapels ablegen oder entsorgen.";
GermanAbilityDescriptions[AbilityNames.RESOLVE_DOCTOR_OVERPAY] = "Entsorge oder lege Karten vom Nachziehstapel ab";
GermanAbilityDescriptions[AbilityNames.HERALD_OVERPAY] = "Karten vom Ablage- auf den Nachziehstapel legen.";
GermanAbilityDescriptions[AbilityNames.RESOLVE_HERALD_OVERPAY] = "Lege Karten von Ablage- auf den Nachziehstapel";
GermanAbilityDescriptions[AbilityNames.MASTERPIECE_OVERPAY] = "Nimm dir mehrere [^pSilver]";
GermanAbilityDescriptions[AbilityNames.RESOLVE_MASTERPIECE_OVERPAY] = "Nimm [^pSilver]";
GermanAbilityDescriptions[AbilityNames.STONEMASON_OVERPAY] = "Nimm dir 2 Aktionskarten";
GermanAbilityDescriptions[AbilityNames.RESOLVE_STONEMASON_OVERPAY] = "Nimm 2 Aktionskarten";
GermanAbilityDescriptions[AbilityNames.EXCHANGE] = "Tausche aus";
GermanAbilityDescriptions[AbilityNames.AMULET] = "+$1; oder entsorge eine Handkarte; oder nimm [^nSilver]";
GermanAbilityDescriptions[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = "+$1";
GermanAbilityDescriptions[AbilityNames.DUNGEON_ABILITY] = "+2 Karten, lege 2 Handkarten ab";
GermanAbilityDescriptions[AbilityNames.GEAR_ABILITY] = "Nimm Argument0 auf die Hand.";
GermanAbilityDescriptions[AbilityNames.MESSENGER_GAIN] = "Alle Spieler erhalten dieselbe Karte ($4 oder billiger).";
GermanAbilityDescriptions[AbilityNames.BRIDGE_TROLL_ABILITY] = "+1 Kauf";
GermanAbilityDescriptions[AbilityNames.HAUNTED_WOODS_DURATION] = "+3 Karten";
GermanAbilityDescriptions[AbilityNames.HAUNTED_WOODS_TOPDECK] = "Lege deine Handkarten auf den Nachziehstapel.";
GermanAbilityDescriptions[AbilityNames.LOST_CITY] = "+1 Karte ([Lost City] eines anderen Spielers)";
GermanAbilityDescriptions[AbilityNames.WINE_MERCHANT_DISCARD] = "Lege [^nWine Merchant] von deinem Wirtshaustableau ab.";
GermanAbilityDescriptions[AbilityNames.HIRELING] = "+1 Karte";
GermanAbilityDescriptions[AbilityNames.SWAMP_HAG_DURATION] = "+$3";
GermanAbilityDescriptions[AbilityNames.TRASH_TOKEN_ABILITY] = "Entsorge eine Karte";
GermanAbilityDescriptions[AbilityNames.EMPORIUM_GAIN_POINTS] = "+2 SP, wenn du mindestens 5 Aktionskarten im Spiel hast.";
GermanAbilityDescriptions[AbilityNames.ROCKS_GAINS_SILVER] = "Nimm [^nSilver].";
GermanAbilityDescriptions[AbilityNames.FORTUNE] = "Nimm [^nGold] pro [Gladiator] im Spiel.";
GermanAbilityDescriptions[AbilityNames.CRUMBLING_CASTLE] = "+1 SP und nimm [^nSilver].";
GermanAbilityDescriptions[AbilityNames.HAUNTED_CASTLE] = "Nimm [^nGold] und alle anderen Spieler müssen Karten auf ihren Nachziehstapel legen.";
GermanAbilityDescriptions[AbilityNames.SPRAWLING_CASTLE] = "Nimm [^nDuchy] oder 3 [^pEstate].";
GermanAbilityDescriptions[AbilityNames.GRAND_CASTLE] = "+1 SP pro Punktekarte im Spiel oder in deiner Hand.";
GermanAbilityDescriptions[AbilityNames.ENCHANTRESS_ABILITY] = "+2 Karten";
GermanAbilityDescriptions[AbilityNames.ENCHANTRESS_CANTRIP] = "+1 Karte, +1 Aktion";
GermanAbilityDescriptions[AbilityNames.TEMPLE] = "Nimm alle Siegpunkte vom [Temple]-Stapel.";
GermanAbilityDescriptions[AbilityNames.VILLA] = "Nimm [Villa] auf deine Hand, +1 Aktion, gehe eventuell zur Aktionsphase zurück.";
GermanAbilityDescriptions[AbilityNames.ARCHIVE_ABILITY] = "Nimm eine Karte auf die Hand: Argument0.";
GermanAbilityDescriptions[AbilityNames.CAPITAL_TAKE_DEBT] = "Nimm 6 Schulden, dann kannst Du Schulden abbezahlen.";
GermanAbilityDescriptions[AbilityNames.CHARM] = "Nimm eine andere Karte mit gleichen Kosten.";
GermanAbilityDescriptions[AbilityNames.FORUM] = "+1 Kauf";
GermanAbilityDescriptions[AbilityNames.HERMIT] = "Entsorge diese Karte, dann nimm [^nMadman].";
GermanAbilityDescriptions[AbilityNames.DONATE] = "Entsorge beliebig viele Karten aus deiner Hand sowie deinem Nachzieh- und Ablegestapel.";
GermanAbilityDescriptions[AbilityNames.TAX_RECEIVE] = "Nimm die Schulden vom Stapel, von dem du eine Karte gekauft hast.";
GermanAbilityDescriptions[AbilityNames.DOMINATE] = "Nimm [^nProvince], dann +9 SP, falls du es gemacht hast.";
GermanAbilityDescriptions[AbilityNames.VP_TO_AQUEDUCT] = "Bewege einen Siegpunkt vom Geldstapel auf [Aqueduct].";
GermanAbilityDescriptions[AbilityNames.VP_FROM_AQUEDUCT] = "Nimm alle Siegpunkte von [Aqueduct].";
GermanAbilityDescriptions[AbilityNames.ARENA_DISCARD_ACTION] = "Lege eine Aktionskarte ab für +2 SP.";
GermanAbilityDescriptions[AbilityNames.VP_FROM_BASILICA] = "Nimm 2 SP von [Basilica] falls du $2 oder mehr hast.";
GermanAbilityDescriptions[AbilityNames.VP_FROM_BATHS] = "Nimm 2 SP von [Baths].";
GermanAbilityDescriptions[AbilityNames.VP_FROM_BATTLEFIELD] = "Nimm 2 SP von [Battlefield].";
GermanAbilityDescriptions[AbilityNames.VP_FROM_COLONNADE] = "Nimm 2 SP von [Colonnade].";
GermanAbilityDescriptions[AbilityNames.VP_TO_DEFILED_SHRINE] = "Bewege einen Siegpunkt vom Aktionsstapel auf [Defiled Shrine].";
GermanAbilityDescriptions[AbilityNames.VP_FROM_DEFILED_SHRINE] = "Nimm alle Siegpunkte von [Defiled Shrine].";
GermanAbilityDescriptions[AbilityNames.VP_FROM_LABYRINTH] = "Nimm 2 SP von [Labyrinth].";
GermanAbilityDescriptions[AbilityNames.MOUNTAIN_PASS_BIDDING] = "Biete Schulden für +8 SP.";
GermanAbilityDescriptions[AbilityNames.TOMB] = "+1 SP";
GermanAbilityDescriptions[AbilityNames.LIGHTHOUSE_COIN] = "+$1";
GermanAbilityDescriptions[AbilityNames.REACTION] = "Reaktion";
GermanAbilityDescriptions[AbilityNames.CALL_GUIDE] = "Rufe [^nGuide] auf, um alle Handkarten abzulegen, dann +5 Karten.";
GermanAbilityDescriptions[AbilityNames.CALL_RATCATCHER] = "Rufe [^nRatcatcher] auf, um eine Handkarte zu entsorgen.";
GermanAbilityDescriptions[AbilityNames.CALL_TRANSMOGRIFY] = "Rufe [^nTransmogrify] auf, um eine Handkarte zu entsorgen und eine bis zu $1 teurere Karte zu nehmen.";
GermanAbilityDescriptions[AbilityNames.CALL_TEACHER] = "Rufe [^nTeacher] auf, um einen Abenteuer-Marker auf einen Aktionskarten-Vorratsstapel zu legen, der keinen solchen Marker von dir hat.";
GermanAbilityDescriptions[AbilityNames.CALL_DUPLICATE] = "Rufe [^nDuplicate] auf, um zusätzlich Argument0 zu nehmen.";
GermanAbilityDescriptions[AbilityNames.CALL_COIN] = "Rufe [^nCoin Of The Realm] auf, um +2 Aktionen zu erhalten.";
GermanAbilityDescriptions[AbilityNames.CALL_ROYAL_CARRIAGE] = "Rufe [^nRoyal Carriage] auf, um Argument0 noch einmal zu spielen.";
GermanAbilityDescriptions[AbilityNames.START_OF_TURN] = "Beginne deinen Zug";
GermanAbilityDescriptions[AbilityNames.START_BUY_PHASE] = "Beginne deine Kaufphase";
GermanAbilityDescriptions[AbilityNames.END_OF_BUY_PHASE] = "Beende deine Kaufphase";
GermanAbilityDescriptions[AbilityNames.BETWEEN_TURNS] = "Zwischen den Zügen";
GermanAbilityDescriptions[AbilityNames.END_OF_TURN] = "Beendest deinen Zug";
GermanAbilityDescriptions[AbilityNames.SUMMON_PLAY] = "Spiele Argument0.";
GermanAbilityDescriptions[AbilityNames.CARAVAN_GUARD_REACTION] = "Spiele [^nCaravan Guard].";
GermanAbilityDescriptions[AbilityNames.TRAVELLING_FAIR_REACTION] = "Wanderzirkus";
GermanAbilityDescriptions[AbilityNames.MOAT_REACTION] = "Decke [^nMoat] auf, um den Angriff zu blockieren.";
GermanAbilityDescriptions[AbilityNames.HORSE_TRADERS_REACTION] = "Lege [^nHorse Traders] für +1 Karte in deinem nächsten Zug zur Seite.";
GermanAbilityDescriptions[AbilityNames.BEGGAR_REACTION] = "Lege [^nBeggar] ab, um 2 [^pSilver] zu nehmen.";
GermanAbilityDescriptions[AbilityNames.MARKET_SQUARE_REACTION] = "Lege [^nMarket Square] ab, um [^nGold] zu nehmen.";
GermanAbilityDescriptions[AbilityNames.TRADER_REACTION] = "Decke [^nTrader] auf, um Argument0 für [^nSilver] auszutauschen.";
GermanAbilityDescriptions[AbilityNames.DIPLOMAT_REACTION] = "Decke [^nDiplomat] auf, für +2 Karten, dann 3 Karten ablegen.";
GermanAbilityDescriptions[AbilityNames.TOPDECK_REACTION] = "Lege Argument0 auf den Nachziehstapel.";
GermanAbilityDescriptions[AbilityNames.WATCHTOWER_TRASH_REACTION] = "Entsorge Argument0.";
GermanAbilityDescriptions[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "Lege Argument0 auf den Nachziehstapel.";
GermanAbilityDescriptions[AbilityNames.PRINCE_PLAY] = "Spiele Argument0.";
GermanAbilityDescriptions[AbilityNames.TUNNEL_REVEAL] = "Decke [^nTunnel] auf, um [^nGold] zu nehmen.";
GermanAbilityDescriptions[AbilityNames.POSSESSION_GAIN_INSTEAD] = "Argument0 nimmt Argument2 statt Argument1.";
GermanAbilityDescriptions[AbilityNames.HOVEL_TRASH] = "Entsorge Hütte.";
GermanAbilityDescriptions[AbilityNames.DELUDED_RETURNED] = "Lege [Deluded] zurück.";
GermanAbilityDescriptions[AbilityNames.ENVIOUS_RETURNED] = "Lege [Envious] zurück.";
GermanAbilityDescriptions[AbilityNames.RECEIVE_BOON] = "Empfange Argument0.";
GermanAbilityDescriptions[AbilityNames.COBBLER_ABILITY] = "Nimm eine Karte, die bis zu $4 kostet, auf deine Hand.";
GermanAbilityDescriptions[AbilityNames.CRYPT_ABILITY] = "Nimm eine Karte auf die Hand: Argument0.";
GermanAbilityDescriptions[AbilityNames.DEN_OF_SIN_ABILITY] = "+2 Karten";
GermanAbilityDescriptions[AbilityNames.FAITHFUL_HOUND] = "Lege [^nFaithful Hound] zur Seite, um ihn am Zugende wieder auf die Hand zu nehmen.";
GermanAbilityDescriptions[AbilityNames.FAITHFUL_HOUND_RETURN] = "Nimm [^nFaithful Hound] wieder auf die Hand.";
GermanAbilityDescriptions[AbilityNames.GHOST_TOWN_ABILITY] = "+1 Karte, +1 Aktion";
GermanAbilityDescriptions[AbilityNames.GUARDIAN_COIN] = "+$1";
GermanAbilityDescriptions[AbilityNames.RAIDER_ABILITY] = "+$3";
GermanAbilityDescriptions[AbilityNames.SECRET_CAVE] = "+$3";
GermanAbilityDescriptions[AbilityNames.CEMETERY] = "Entsorge bis zu 4 Handkarten";
GermanAbilityDescriptions[AbilityNames.HAUNTED_MIRROR] = "Lege eine Aktionskarte ab, um [^nGhost] zu nehmen.";
GermanAbilityDescriptions[AbilityNames.GHOST] = "Spiele Argument0 zweimal.";
GermanAbilityDescriptions[AbilityNames.REMOVE_FLIPPED_STATUS] = "X";
GermanAbilityDescriptions[AbilityNames.LOST_IN_THE_WOODS] = "Lege eine Karte ab, um eine Gabe zu empfangen.";
GermanAbilityDescriptions[AbilityNames.BLESSED_VILLAGE] = "Du erhälst eine Gabe.";
GermanAbilityDescriptions[AbilityNames.FORTRESS] = "Nimm [Fortress] auf die Hand.";
GermanAbilityDescriptions[AbilityNames.RECEIVE_HEX] = "Empfange eine Plage.";
GermanAbilityDescriptions[AbilityNames.CHANGELING_MAY_EXCHANGE] = "Tausche Argument0 gegen [^nChangeling] aus.";
GermanAbilityDescriptions[AbilityNames.TRADE_ROUTE_MOVE_TOKEN] = "Bewege den Marker auf das Tableau Handelsroute.";
GermanAbilityDescriptions[AbilityNames.DUCAT] = "Entsorge [^nCopper] aus deiner Hand.";
GermanAbilityDescriptions[AbilityNames.CARGO_SHIP_SET_ASIDE] = "Lege Argument0 mit [Cargo Ship] für deinen nächsten Zug beiseite.";
GermanAbilityDescriptions[AbilityNames.CARGO_SHIP_RETURN] = "Nimm Argument0 aus dem [Cargo Ship] auf die Hand.";
GermanAbilityDescriptions[AbilityNames.IMPROVE] = "Verbessere eine deiner Aktionskarten im Spiel um genau $1.";
GermanAbilityDescriptions[AbilityNames.PRIEST] = "+$2";
GermanAbilityDescriptions[AbilityNames.RESEARCH] = "Nimm Argument0 auf die Hand.";
GermanAbilityDescriptions[AbilityNames.SILK_MERCHANT] = "+1 Taler, +1 Dorfbewohner";
GermanAbilityDescriptions[AbilityNames.LACKEYS] = "+2 Dorfbewohner";
GermanAbilityDescriptions[AbilityNames.SPICES] = "+2 Taler";
GermanAbilityDescriptions[AbilityNames.KEY] = "+$1";
GermanAbilityDescriptions[AbilityNames.TREASURE_CHEST] = "Nimm dir [^nGold].";
GermanAbilityDescriptions[AbilityNames.FLAG_BEARER] = "Nimm dir die [Flag].";
GermanAbilityDescriptions[AbilityNames.CATHEDRAL] = "Entsorge eine Handkarte.";
GermanAbilityDescriptions[AbilityNames.CITY_GATE] = "+1 Karte, lege dann 1 Handkarte auf deinen Nachziehstapel.";
GermanAbilityDescriptions[AbilityNames.PAGEANT] = "Zahle $1 für +1 Taler.";
GermanAbilityDescriptions[AbilityNames.SEWERS_MAY_TRASH] = "Entsorge eine weitere Handkarte.";
GermanAbilityDescriptions[AbilityNames.STAR_CHART] = "Beim Mischen 1 Karte nach oben legen.";
GermanAbilityDescriptions[AbilityNames.EXPLORATION] = "+1 Taler, +1 Dorfbewohner";
GermanAbilityDescriptions[AbilityNames.FAIR] = "+1 Kauf";
GermanAbilityDescriptions[AbilityNames.SILOS] = "Lege beliebig viele [^pCopper] aus deiner Hand für je +1 Karte ab.";
GermanAbilityDescriptions[AbilityNames.ACADEMY] = "+1 Dorfbewohner";
GermanAbilityDescriptions[AbilityNames.GUILDHALL] = "+1 Taler";
GermanAbilityDescriptions[AbilityNames.PIAZZA] = "Decke die oberste Karte vom Nachziehstapel auf und spiele sie, falls es eine Aktion ist.";
GermanAbilityDescriptions[AbilityNames.BARRACKS] = "+1 Aktion";
GermanAbilityDescriptions[AbilityNames.CROP_ROTATION] = "Lege eine Punktekarte ab für +2 Karten.";
GermanAbilityDescriptions[AbilityNames.INNOVATION] = "Lege Argument0 beiseite und spiele die Karte dann.";
GermanAbilityDescriptions[AbilityNames.CITADEL] = "Spiele Argument0 noch einmal.";
GermanAbilityDescriptions[AbilityNames.SINISTER_PLOT] = "Ziehe Karten oder lege einen Marker auf [^nSinister Plot].";
GermanAbilityDescriptions[AbilityNames.ENCAMPMENT_RETURNED] = "Lege [Encampment] zurück in den Vorrat.";
GermanAbilityDescriptions[AbilityNames.EMBARGO_GAIN_CURSE] = "Nimm [^nCurse].";
GermanAbilityDescriptions[AbilityNames.CAPTAIN] = "Spiele eine (Nicht-Dauer)-Aktion bis zu $4 aus dem Vorrat.";
GermanAbilityDescriptions[AbilityNames.CHURCH] = "Nimm Argument0 auf die Hand, dann darfst du entsorgen.";
GermanAbilityDescriptions[AbilityNames.POSSESSION_SETS_ASIDE] = "Lege Argument0 beiseite.";
GermanAbilityDescriptions[AbilityNames.MINT] = "Entsorge alle deine Geldkarten im Spiel.";
GermanAbilityDescriptions[AbilityNames.SLEIGH_TOPDECK_REACTION] = "Lege [Sleigh] ab, um Argument0 auf deinen Nachziehstapel zu legen.";
GermanAbilityDescriptions[AbilityNames.SLEIGH_PUT_IN_HAND_REACTION] = "Lege [Sleigh] ab, um Argument0 auf deine Hand zu nehmen.";
GermanAbilityDescriptions[AbilityNames.BLACK_CAT_REACTION] = "Spiele [Black Cat].";
GermanAbilityDescriptions[AbilityNames.DISCARD_FROM_EXILE] = "Lege Argument0 aus dem Exil ab.";
GermanAbilityDescriptions[AbilityNames.EXILE_BY_NAME] = "Lege Argument0 ins Exil.";
GermanAbilityDescriptions[AbilityNames.SHEEPDOG_REACTION] = "Spiele [Sheepdog]";
GermanAbilityDescriptions[AbilityNames.CAVALRY] = "+2 Karten, +1 Kauf & zurück zur Aktionsphase";
GermanAbilityDescriptions[AbilityNames.HOSTELRY] = "Lege Geldkarten ab, um Pferde zu nehmen.";
GermanAbilityDescriptions[AbilityNames.VILLAGE_GREEN_VILLAGE] = "+1 Karte, +2 Aktionen";
GermanAbilityDescriptions[AbilityNames.VILLAGE_GREEN_PLAY] = "Spiele [Village Green].";
GermanAbilityDescriptions[AbilityNames.BARGE] = "+3 Karten, +1 Kauf";
GermanAbilityDescriptions[AbilityNames.FALCONER_REACTION] = "Nimm eine billigere Karte.";
GermanAbilityDescriptions[AbilityNames.GATEKEEPER_EXILE] = "Lege Argument0 ins Exil.";
GermanAbilityDescriptions[AbilityNames.GATEKEEPER_DURATION] = "+3 Geld";
GermanAbilityDescriptions[AbilityNames.LIVERY] = "Nimm ein Pferd.";
GermanAbilityDescriptions[AbilityNames.MASTERMIND] = "Du darfst eine Karte dreimal spielen.";
GermanAbilityDescriptions[AbilityNames.DELAY_PLAY] = "Spiele Argument0.";
GermanAbilityDescriptions[AbilityNames.INVEST] = "INVEST ABILITY (never shown to user)";
GermanAbilityDescriptions[AbilityNames.REAP_PLAY] = "Spiele das beiseite gelegte [Gold]";
GermanAbilityDescriptions[AbilityNames.PLUS_CARD_TOKEN] = "+1 Karte vom Marker";
GermanAbilityDescriptions[AbilityNames.PLUS_ACTION_TOKEN] = "+1 Aktion vom Marker";
GermanAbilityDescriptions[AbilityNames.PLUS_BUY_TOKEN] = "+1 Kauf vom Marker";
GermanAbilityDescriptions[AbilityNames.PLUS_COIN_TOKEN] = "+1 Geld vom Marker";
GermanAbilityDescriptions[AbilityNames.KILN] = "Nimm dir Argument0.";
GermanAbilityDescriptions[AbilityNames.TURTLE_PLAY] = "Spiele Argument0.";
GermanAbilityDescriptions[AbilityNames.CHAMELEON_RESOLUTION] = "Erhalte +Karten statt +Geld";
GermanAbilityDescriptions[AbilityNames.GIVE_PLUS_CARDS] = "+1 Karte";
GermanAbilityDescriptions[AbilityNames.GIVE_PLUS_ACTIONS] = "+1 Aktion";
GermanAbilityDescriptions[AbilityNames.GIVE_PLUS_BUYS] = "+1 Kauf";
GermanAbilityDescriptions[AbilityNames.GIVE_PLUS_COINS] = "+1 Geld";
GermanAbilityDescriptions[AbilityNames.RETURN_MINUS_COIN_TOKEN] = "-Geld Marker zurücklegen";
GermanAbilityDescriptions[AbilityNames.MONKEY_NEXT_TURN_ABILITY] = "+1 Karte";
GermanAbilityDescriptions[AbilityNames.BLOCKADE_ABILITY] = "Nimm Argument0 auf deine Hand";
GermanAbilityDescriptions[AbilityNames.PIRATE_ABILITY] = "Nimm eine Geldkarte auf deine Hand";
GermanAbilityDescriptions[AbilityNames.PIRATE_REACTION] = "Spiele [Pirate]";
GermanAbilityDescriptions[AbilityNames.CORSAIR_NEXT_TURN_ABILITY] = "+1 Karte";
GermanAbilityDescriptions[AbilityNames.CORSAIR_TRASH] = "Entsorge Argument0";
GermanAbilityDescriptions[AbilityNames.SAILOR_NEXT_TURN] = "+$2, und du darfst entsorgen";
GermanAbilityDescriptions[AbilityNames.SAILOR_REACTION] = "Spiele Argument0";
GermanAbilityDescriptions[AbilityNames.SKIRMISHER] = "Mitspieler legen bis auf 3 ab.";
GermanAbilityDescriptions[AbilityNames.ENCHANTRESS_REPLACE_ABILITY] = "Ersetze durch +1 Karte, +1 Aktion.";
GermanAbilityDescriptions[AbilityNames.HIGHWAYMAN_NEXT_TURN_ABILITY] = "Lege Wegelagerer ab, +3 Karten";
GermanAbilityDescriptions[AbilityNames.HIGHWAYMAN_REPLACE_ABILITY] = "Ersetze mit Wegelagerer Effekt.";
GermanAbilityDescriptions[AbilityNames.HIGHWAYMAN_NULLIFIED] = "Kein Effekt";
GermanAbilityDescriptions[AbilityNames.LEAGUE_OF_BANKERS] = "+$1 pro 4 Gefallen";
GermanAbilityDescriptions[AbilityNames.WOODWORKERS_GUILD] = "Löse 1 Gefallen ein für 1 Aktion entsorgen, 1 nehmen.";
GermanAbilityDescriptions[AbilityNames.CRAFTERS_GUILD] = "Löse 2 Gefallen ein, um eine Karte bis $4 zu nehmen.";
GermanAbilityDescriptions[AbilityNames.CAVE_DWELLERS] = "Löse 1 Gefallen ein für 1 Karte ablegen, 1 ziehen.";
GermanAbilityDescriptions[AbilityNames.DESERT_GUIDES] = "Löse 1 Gefallen ein für Hand ablegen, 5 ziehen.";
GermanAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_CARD] = "Löse 1 Gefallen ein für +1 Karte.";
GermanAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_ACTION] = "Löse 1 Gefallen ein für +1 Aktion.";
GermanAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_BUY] = "Löse 1 Gefallen ein für +1 Kauf.";
GermanAbilityDescriptions[AbilityNames.SYCOPHANT] = "+2 Gafallen";
GermanAbilityDescriptions[AbilityNames.IMPORTER_ABILITY] = "Nimm eine Karte bis zu $5.";
GermanAbilityDescriptions[AbilityNames.CONTRACT_ABILITY] = "Spiele Argument0.";
GermanAbilityDescriptions[AbilityNames.MARKET_TOWNS] = "Löse 1 Gefallen ein, um eine Aktionskarte zu spielen.";
GermanAbilityDescriptions[AbilityNames.TRAPPERS_LODGE] = "Löse 1 Gefallen ein, um Argument0 auf den Nachziehstapel zu legen.";
GermanAbilityDescriptions[AbilityNames.GUILDMASTER] = "+1 Gefallen";
GermanAbilityDescriptions[AbilityNames.ISLAND_FOLK] = "Löse 5 Gefallen für einen Extrazug ein.";
GermanAbilityDescriptions[AbilityNames.GANG_OF_PICKPOCKETS] = "Lege bis auf 4 ab oder löse einen Gefallen ein.";
GermanAbilityDescriptions[AbilityNames.COASTAL_HAVEN] = "Behalte Karten für nächsten Zug auf der Hand.";
GermanAbilityDescriptions[AbilityNames.CITY_STATE] = "Löse 2 Gefallen ein, um Argument0 zu spielen.";
GermanAbilityDescriptions[AbilityNames.CITY_STATE_PLAY] = "---";
GermanAbilityDescriptions[AbilityNames.CIRCLE_OF_WITCHES] = "Löse 3 Gefallen ein, damit Mitspieler 1 Fluch nehmen.";
GermanAbilityDescriptions[AbilityNames.ROYAL_GALLEY_SET_ASIDE] = "Lege mit Königliche Galeere zur Seite.";
GermanAbilityDescriptions[AbilityNames.ROYAL_GALLEY_PLAY] = "Spiele Argument0.";
GermanAbilityDescriptions[AbilityNames.STRONGHOLD_ABILITY] = "+3 Karten";
GermanAbilityDescriptions[AbilityNames.MOUNTAIN_FOLK] = "Löse 5 Gefallen ein für +3 Karten";
GermanAbilityDescriptions[AbilityNames.PEACEFUL_CULT] = "Löse Gefallen ein, um Handkarten zu entsorgen.";
GermanAbilityDescriptions[AbilityNames.FELLOWSHIP_OF_SCRIBES] = "Löse 1 Gefallen ein für +1 Karte";
GermanAbilityDescriptions[AbilityNames.LEAGUE_OF_SHOPKEEPERS] = "+$1 ab 5; +1 Aktion, +1 Kauf ab 10 Gefallen";
GermanAbilityDescriptions[AbilityNames.TERRITORY_GAIN] = "Nimm ein Gold pro leerem Vorratsstapel.";
GermanAbilityDescriptions[AbilityNames.WARLORD_ABILITY] = "+2 Karten";
GermanAbilityDescriptions[AbilityNames.GARRISON_ADD_TOKEN] = "Füge einen Marker hinzu.";
GermanAbilityDescriptions[AbilityNames.GARRISON_REMOVE_ABILITY] = "+Argument0 Karten";
GermanAbilityDescriptions[AbilityNames.CONJURER] = "Nimm den Beschwörer auf deine Hand.";
GermanAbilityDescriptions[AbilityNames.LICH] = "Lege ab und nimm aus dem Müll.";
GermanAbilityDescriptions[AbilityNames.GALLERIA] = "+1 Kauf";
GermanAbilityDescriptions[AbilityNames.ARCHITECTS_GUILD] = "Nimm eine billigere Ksrte.";
GermanAbilityDescriptions[AbilityNames.FAMILY_OF_INVENTORS] = "Mache einen Stapel billiger.";
GermanAbilityDescriptions[AbilityNames.FOREST_DWELLERS] = "Löse 1 Gefallen ein, um 3 Karten zu sortieren.";
GermanAbilityDescriptions[AbilityNames.ORDER_OF_ASTROLOGERS] = "Beim Mischen 1 Karte pro Gefallen nach oben legen.";
GermanAbilityDescriptions[AbilityNames.ORDER_OF_MASONS] = "Beim Mischen 2 Karten pro Gefallen in Ablagestapel legen.";

GermanAbilityDescriptions[AbilityNames.ASTROLABE_ABILITY] = "+$1, +1 Kauf";
GermanAbilityDescriptions[AbilityNames.TIDE_POOLS_ABILITY] = "Lege 2 Handkarten ab";
GermanAbilityDescriptions[AbilityNames.SEA_WITCH_ABILITY] = "+2 Karten, lege 2 Handkarten ab";
GermanAbilityDescriptions[AbilityNames.CLERK_ABILITY] = "Spiele [Clerk]";
GermanAbilityDescriptions[AbilityNames.TREASURY] = "Schatzkammer auf Nachziehstapel legen";
GermanAbilityDescriptions[AbilityNames.MERCHANT_GUILD] = "Erhalte Taler für genommene Karten";

GermanAbilityDescriptions[AbilityNames.GUARD_DOG_REACTION] = "Spiele [Guard Dog]";
GermanAbilityDescriptions[AbilityNames.BERSERKER] = "Spiele [Berserker]";
GermanAbilityDescriptions[AbilityNames.NOMADS] = "+2 Geld";
GermanAbilityDescriptions[AbilityNames.TRAIL_REACTION] = "Spiele [Trail]";
GermanAbilityDescriptions[AbilityNames.WEAVER_PLAY] = "Spiele [Weaver]";
GermanAbilityDescriptions[AbilityNames.SOUK] = "Entsorge bis zu 2 Handkarten.";
GermanAbilityDescriptions[AbilityNames.CAULDRON_CURSING] = "Jeder Mitspieler nimmt einen Fluch.";
GermanAbilityDescriptions[AbilityNames.SAUNA_TRASH_ABILITY] = "Entsorge eine Karte ([Sauna])";
GermanAbilityDescriptions[AbilityNames.UNUSED_ABILITY_2] = "unused";
GermanAbilityDescriptions[AbilityNames.UNUSED_ABILITY_2] = "unused";
GermanAbilityDescriptions[AbilityNames.UNUSED_ABILITY_2] = "unused";
GermanAbilityDescriptions[AbilityNames.UNUSED_ABILITY_2] = "unused";
GermanAbilityDescriptions[AbilityNames.UNUSED_ABILITY_2] = "unused";
GermanAbilityDescriptions[AbilityNames.TREASURY] = "[Treasury] auf Nachziehstapel legen";
GermanAbilityDescriptions[AbilityNames.MERCHANT_GUILD] = "Erhalte Taler für genommene Karten";
GermanAbilityDescriptions[AbilityNames.ALCHEMIST_TOPDECK] = "[Alchemist] auf Nachziehstapel legen";
GermanAbilityDescriptions[AbilityNames.HERBALIST_TOPDECK] = "Geldkarte auf Nachziehstapel legen ([Herbalist])";
GermanAbilityDescriptions[AbilityNames.SCHEME_TOPDECK] = "Aktionskarte auf Nachziehstapel legen ([Scheme])";
GermanAbilityDescriptions[AbilityNames.FROG_TOPDECK] = "Argument0 auf Nachziehstapel legen ([Way of the Frog])";
GermanAbilityDescriptions[AbilityNames.MERCHANT_CAMP_TOPDECK] = "[Merchant Camp] auf Nachziehstapel legen";
GermanAbilityDescriptions[AbilityNames.WALLED_VILLAGE_TOPDECK] = "[Walled Village] auf Nachziehstapel legen";
GermanAbilityDescriptions[AbilityNames.TENT_TOPDECK] = "[Tent] auf Nachziehstapel legen";
GermanAbilityDescriptions[AbilityNames.HORN_TOPDECK] = "[Border Guard] auf Nachziehstapel legen ([Horn])";
GermanAbilityDescriptions[AbilityNames.PAGE_EXCHANGE] = "[Page] in [Treasure Hunter] eintauschen";
GermanAbilityDescriptions[AbilityNames.TREASURE_HUNTER_EXCHANGE] = "[Treasure Hunter] in [Warrior] eintauschen";
GermanAbilityDescriptions[AbilityNames.WARRIOR_EXCHANGE] = "[Warrior] in [Hero] eintauschen";
GermanAbilityDescriptions[AbilityNames.HERO_EXCHANGE] = "[Hero] in [Champion] eintauschen";
GermanAbilityDescriptions[AbilityNames.PEASANT_EXCHANGE] = "[Peasant] in [Soldier] eintauschen";
GermanAbilityDescriptions[AbilityNames.SOLDIER_EXCHANGE] = "[Soldier] in [Fugitive] eintauschen";
GermanAbilityDescriptions[AbilityNames.FUGITIVE_EXCHANGE] = "[Fugitive] in [Disciple] eintauschen";
GermanAbilityDescriptions[AbilityNames.DISCIPLE_EXCHANGE] = "[Disciple] in [Teacher] eintauschen";

GermanAbilityDescriptions[AbilityNames.CAGE_TRASH] = "Entsorge [Cage]";
GermanAbilityDescriptions[AbilityNames.CAGE_PUT_IN_HAND] = "Nimm Argument0 auf die Hand."; //TOTEST: ?
GermanAbilityDescriptions[AbilityNames.GROTTO] = "Lege Argument0 ab, dann ziehe dieselbe Anzahl Karten.";
GermanAbilityDescriptions[AbilityNames.JEWELLED_EGG_GAIN_LOOT] = "Nimm eine Kostbarkeit.";
GermanAbilityDescriptions[AbilityNames.SEARCH] = "Entsorge [Search] und nimm eine Kostbarkeit.";
GermanAbilityDescriptions[AbilityNames.SHAMAN] = "Nimm eine Karte aus dem Müll, die bis zu 6 Geld kostet.";
GermanAbilityDescriptions[AbilityNames.SECLUDED_SHRINE] = "Entsorge bis zu 2 deiner Handkarten.";
GermanAbilityDescriptions[AbilityNames.SIREN_GAIN] = "Entsorge eine Aktionskarte oder die [Siren].";
GermanAbilityDescriptions[AbilityNames.SIREN_DURATION] = "Ziehe, bis du 8 Handkarten hast.";
GermanAbilityDescriptions[AbilityNames.STOWAWAY_DRAW] = "Ziehe 2 Karten";
GermanAbilityDescriptions[AbilityNames.STOWAWAY_REACTION] = "Spiele [Stowaway]";
GermanAbilityDescriptions[AbilityNames.TASKMASTER] = "+1 Aktion, +1 Geld (Wdhlg. im nächsten Zug nach Nehmen von $5-Karte)";
GermanAbilityDescriptions[AbilityNames.ABUNDANCE] = "+1 Kauf, +3 Geld";
GermanAbilityDescriptions[AbilityNames.CABIN_BOY] = "+2 Geld, oder entsorge [Cabin Boy], um eine Dauerkarte zu nehmen.";
GermanAbilityDescriptions[AbilityNames.FLAGSHIP_ACTIVATE] = "**abc**";
GermanAbilityDescriptions[AbilityNames.FLAGSHIP_REPLAY] = "Spiele Argument0 erneut";
GermanAbilityDescriptions[AbilityNames.GONDOLA_DURATION] = "+2 Geld";
GermanAbilityDescriptions[AbilityNames.GONDOLA_PLAY] = "Spiele eine Aktionskarte aus deiner Hand.";
GermanAbilityDescriptions[AbilityNames.HARBOR_VILLAGE] = "+1 Geld"; //TOTEST: ?
GermanAbilityDescriptions[AbilityNames.LANDING_PARTY] = "Lege [Landing Party] auf deinen Nachziehstapel.";
GermanAbilityDescriptions[AbilityNames.MAPMAKER] = "Spiele [Mapmaker]";
GermanAbilityDescriptions[AbilityNames.ROPE] = "+1 Karte, dann darfst du eine Handkarte entsorgen.";
GermanAbilityDescriptions[AbilityNames.BURIED_TREASURE_DURATION] = "+1 Kauf, +3 Geld";
GermanAbilityDescriptions[AbilityNames.BURIED_TREASURE_PLAY] = "Spiele [Buried Treasure]";
GermanAbilityDescriptions[AbilityNames.CREW_DURATION] = "Lege [Crew] auf deinen Nachziehstapel.";
GermanAbilityDescriptions[AbilityNames.CUTTHROAT_GAIN] = "Nimm eine Kostbarkeit";
GermanAbilityDescriptions[AbilityNames.ENLARGE] = "Entsorge eine Handkarte; nimm eine Karte, die bis zu 2 Geld mehr kostet.";
GermanAbilityDescriptions[AbilityNames.FRIGATE_DURATION] = "";
GermanAbilityDescriptions[AbilityNames.FRIGATE_DISCARD] = "Lege bis auf 4 Handkarten ab."; //TOTEST: ?
GermanAbilityDescriptions[AbilityNames.LONGSHIP_DURATION] = "+2 Karten";
GermanAbilityDescriptions[AbilityNames.MINING_ROAD] = "Spiele Argument0";
GermanAbilityDescriptions[AbilityNames.QUARTERMASTER] = "Handle [Quartermaster] ab";
GermanAbilityDescriptions[AbilityNames.TRICKSTER_REACTION] = "Lege eine Geldkarte beiseite.";
GermanAbilityDescriptions[AbilityNames.TRICKSTER_RETURN_TO_HAND] = "Nimm Argument0 auf die Hand."; //TOTEST: ?
GermanAbilityDescriptions[AbilityNames.WEALTHY_VILLAGE] = "Nimm eine Kostbarkeit";
GermanAbilityDescriptions[AbilityNames.DELIVER_SET_ASIDE] = "Lege Argument0 beiseite";
GermanAbilityDescriptions[AbilityNames.DELIVER_RETURN] = "Nimm Argument0 auf die Hand."; //TOTEST: ?
GermanAbilityDescriptions[AbilityNames.RUSH] = "Spiele Argument0";
GermanAbilityDescriptions[AbilityNames.MIRROR] = "Nimm noch Argument0";
GermanAbilityDescriptions[AbilityNames.PREPARE] = "Spiele die beiseite gelegten Karten.";
GermanAbilityDescriptions[AbilityNames.AMPHORA] = "+1 Kauf, +3 Geld";
GermanAbilityDescriptions[AbilityNames.DOUBLOONS] = "Nimm ein Gold";
GermanAbilityDescriptions[AbilityNames.ENDLESS_CHALICE] = "+1 Geld, +1 Kauf";
GermanAbilityDescriptions[AbilityNames.FIGUREHEAD] = "+2 Karten";
GermanAbilityDescriptions[AbilityNames.JEWELS] = "Lege [Jewels] unter deinen Nachziehstapel.";
GermanAbilityDescriptions[AbilityNames.PUZZLE_BOX_RETURN] = "Nimm Argument0 auf die Hand.";
GermanAbilityDescriptions[AbilityNames.SHIELD_REACTION] = "Decke [Shield] auf, um vom Angriff nicht betroffen zu sein.";
GermanAbilityDescriptions[AbilityNames.SPELL_SCROLL] = "Spiele Argument0"; //TOTEST: ?
GermanAbilityDescriptions[AbilityNames.CURSED_GAIN] = "Nimm eine Kostbarkeit und einen Fluch.";
GermanAbilityDescriptions[AbilityNames.FAWNING_GAIN] = "Nimm eine [Fawning]e Karte";
GermanAbilityDescriptions[AbilityNames.SCHEDULER] = "[Friendly] (Es ist ein Fehler, wenn das auftaucht!)";
GermanAbilityDescriptions[AbilityNames.FRIENDLY_ABILITY] = "Lege eine [Friendly]e Karte ab, um eine weitere zu nehmen.";
GermanAbilityDescriptions[AbilityNames.HASTY_SET_ASIDE] = "Lege Argument0 zur Seite ([Hasty])";
GermanAbilityDescriptions[AbilityNames.HASTY_PLAY] = "Spiele Argument0 ([Hasty])";
GermanAbilityDescriptions[AbilityNames.INHERITED] = "Ersetze eine Startkarte."; //TOTEST: ?
GermanAbilityDescriptions[AbilityNames.INSPIRING] = "Spiele eine Aktionskarte, die du nicht im Spiel hast ([Inspiring]).";
GermanAbilityDescriptions[AbilityNames.NEARBY] = "+1 Kauf";
GermanAbilityDescriptions[AbilityNames.PATIENT_SCHEDULER] = "[Patient] (Es ist ein Fehler, wenn das auftaucht!)";
GermanAbilityDescriptions[AbilityNames.PATIENT_SET_ASIDE] = "Lege [Patient]e Karten zur Seite.";
GermanAbilityDescriptions[AbilityNames.PATIENT_PLAY] = "Spiele Argument0"; //TOTEST: ?
GermanAbilityDescriptions[AbilityNames.PIOUS] = "Entsorge eine Karte";
GermanAbilityDescriptions[AbilityNames.RECKLESS_REPEAT] = "Folge den Anweisungen von Argument0 nocheinmal.";
GermanAbilityDescriptions[AbilityNames.RECKLESS_RETURN] = "Lege Argument0 zurück auf den Vorratsstapel.";
GermanAbilityDescriptions[AbilityNames.RICH_GAIN] = "Nimm [^nSilver]";
GermanAbilityDescriptions[AbilityNames.SHY] = "Lege 1 [Shy]e Karte für +2 Karten ab."; //TOTEST: ?
GermanAbilityDescriptions[AbilityNames.TIRELESS_SET_ASIDE] = "Lege Argument0 zur Seite.";
GermanAbilityDescriptions[AbilityNames.TIRELESS_RETURN] = "Lege Argument0 auf deinen Nachziehstapel.";
GermanAbilityDescriptions[AbilityNames.BEFORE_FIRST_TURN] = "Vor dem ersten Zug (Es ist ein Fehler, wenn das auftaucht!)";
GermanAbilityDescriptions[AbilityNames.FATED] = "Beim Mischen Vorherbestimmte Karten nach oben oder unten legen.";
GermanAbilityDescriptions[AbilityNames.AVOID] = "Beim Mischen bis zu 3 Karten in Ablagestapel legen.";
GermanAbilityDescriptions[AbilityNames.HERBALIST_ASK_FOR_DISCARD] = "Geldkarte auf Nachziehstapel legen";
GermanAbilityDescriptions[AbilityNames.SCHEME_ASK_FOR_DISCARD] = "Aktionskarte auf Nachziehstapel legen";
GermanAbilityDescriptions[AbilityNames.TRICKSTER_ASK_FOR_DISCARD] = "Geldkarte für nächsten Zug zur Seite legen";
GermanAbilityDescriptions[AbilityNames.MARCHLAND] = "+1 Kauf und lege Handkarten ab für +1 Geld pro Karte";
GermanAbilityDescriptions[AbilityNames.FARMHANDS_SET_ASIDE] = "Aktions- oder Geldkarte zur Seite legen";
GermanAbilityDescriptions[AbilityNames.FARMHANDS_PLAY] = "Spiele Argument0";
GermanAbilityDescriptions[AbilityNames.JOUST_DISCARD] = "Lege eine Provinz ab";
GermanAbilityDescriptions[AbilityNames.INFIRMARY_OVERPAY] = "Überzahlen, um [Infirmary] zu spielen";
GermanAbilityDescriptions[AbilityNames.RESOLVE_INFIRMARY_OVERPAY] = "Spiele [Infirmary]";
GermanAbilityDescriptions[AbilityNames.DRAW_FROM_FOOTPAD] = "Ziehe 1 Karte";
GermanAbilityDescriptions[AbilityNames.FARRIER_OVERPAY] = "Überzahlen für +Karten am Ende des Zuges";
GermanAbilityDescriptions[AbilityNames.RESOLVE_FARRIER_OVERPAY] = "[Farrier] Überzahlen aktivieren";
GermanAbilityDescriptions[AbilityNames.DRAW_FROM_SQUIRREL] = "Ziehe 2 Karten";
GermanAbilityDescriptions[AbilityNames.DRAW_FROM_RIVER] = "Ziehe 1 Karte";
GermanAbilityDescriptions[AbilityNames.DRAW_FROM_FARRIER] = "Ziehe Argument0 Karten";
GermanAbilityDescriptions[AbilityNames.FERRYMAN] = "Nimm Argument0";

GermanAbilityDescriptions[AbilityNames.BIDING_TIME_SET_ASIDE_HAND] = "Lege alle Handkarten zur Seite";
GermanAbilityDescriptions[AbilityNames.BIDING_TIME_RETURN_TO_HAND] = "[^nBiding Time]- Karten zurück auf die Hand";
GermanAbilityDescriptions[AbilityNames.ENLIGHTENMENT_CANTRIP] = "+1 Karte, +1 Aktion"; // is it used?
GermanAbilityDescriptions[AbilityNames.ENLIGHTENMENT_REPLACE_ABILITY] = "Ersetze durch +1 Karte, +1 Aktion.";
GermanAbilityDescriptions[AbilityNames.GOOD_HARVEST] = "+1 Kauf, +1 Geld";
GermanAbilityDescriptions[AbilityNames.HARSH_WINTER] = "Nimm Schulden vom Stepel oder lege Schulden auf den Stapel.";
GermanAbilityDescriptions[AbilityNames.KIND_EMPEROR] = "Nimm eine Aktionskarte auf deine Hand.";
GermanAbilityDescriptions[AbilityNames.PANIC_BUYS] = "+2 Käufe";
GermanAbilityDescriptions[AbilityNames.PANIC_RETURN] = "Lege Argument0 zurück auf den Stapel."; // when used ?
GermanAbilityDescriptions[AbilityNames.PROGRESS] = "Lege Argument0 auf deinen Nachziehstapel."; // missing Argument0
GermanAbilityDescriptions[AbilityNames.RAPID_EXPANSION_SET_ASIDE] = "Lege Argument0 zur Seite.";
GermanAbilityDescriptions[AbilityNames.RAPID_EXPANSION_PLAY_LATER] = "Spiele Argument0.";
GermanAbilityDescriptions[AbilityNames.SICKNESS] = "Nimm einen Fluch auf den Nachziehtapel oder lege 3 Karten ab.";
GermanAbilityDescriptions[AbilityNames.RIVER_SHRINE] = "Nimm eine Karte bis zu 4 Geld.";
GermanAbilityDescriptions[AbilityNames.SAMURAI] = "+1 Geld";
GermanAbilityDescriptions[AbilityNames.DAIMYO_REPLAY] = "Spiele Argument0 erneut";
GermanAbilityDescriptions[AbilityNames.FORESIGHT_RETURN_TO_HAND] = GermanAbilityDescriptions[AbilityNames.SAVE_RETURN_TO_HAND];