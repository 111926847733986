"use strict";

var EnglishCardModes = {};

EnglishCardModes[CardModes.PAWN_CARD_ACTION] = "Card & Action";
EnglishCardModes[CardModes.PAWN_CARD] = "+1 Card";
EnglishCardModes[CardModes.PAWN_ACTION] = "+1 Action";
EnglishCardModes[CardModes.PAWN_BUY] = "+1 Buy";
EnglishCardModes[CardModes.PAWN_COIN] = "+1 Coin";
EnglishCardModes[CardModes.STEWARD_CARDS] = "+2 Cards";
EnglishCardModes[CardModes.STEWARD_COINS] = "+2 Coins";
EnglishCardModes[CardModes.STEWARD_TRASH] = "Trash 2";
EnglishCardModes[CardModes.MINION_COINS] = "+2 Coins";
EnglishCardModes[CardModes.MINION_NEWHAND] = "Discard";
EnglishCardModes[CardModes.NOBLES_CARDS] = "+3 Cards";
EnglishCardModes[CardModes.NOBLES_ACTIONS] = "+2 Actions";
EnglishCardModes[CardModes.COURTIER_ACTION] = "+1 Action";
EnglishCardModes[CardModes.COURTIER_BUY] = "+1 Buy";
EnglishCardModes[CardModes.COURTIER_COINS] = "+3 Coins";
EnglishCardModes[CardModes.COURTIER_GOLD] = "Gain Gold";
EnglishCardModes[CardModes.NATIVE_VILLAGE_SET_ASIDE] = "Set Aside";
EnglishCardModes[CardModes.NATIVE_VILLAGE_RETURN] = "Pick Up";
EnglishCardModes[CardModes.PIRATE_SHIP_RAID] = "Attack";
EnglishCardModes[CardModes.PIRATE_SHIP_PROFIT] = "Profit";
EnglishCardModes[CardModes.SCRYING_POOL_TOPDECK] = "Topdeck";
EnglishCardModes[CardModes.SCRYING_POOL_DISCARD] = "Discard";
EnglishCardModes[CardModes.LOAN_DISCARD] = "Discard";
EnglishCardModes[CardModes.LOAN_TRASH] = "Trash";
EnglishCardModes[CardModes.TRUSTY_STEED_CARDS_ACTIONS] = "Cards & Actions";
EnglishCardModes[CardModes.TRUSTY_STEED_CARDS] = "+2 Cards";
EnglishCardModes[CardModes.TRUSTY_STEED_ACTIONS] = "+2 Actions";
EnglishCardModes[CardModes.TRUSTY_STEED_COINS] = "+2 Coins";
EnglishCardModes[CardModes.TRUSTY_STEED_SILVERS] = "+4 Silvers";
EnglishCardModes[CardModes.JESTER_THEY_GAIN] = "Opponent Gains";
EnglishCardModes[CardModes.JESTER_YOU_GAIN] = "You Gain";
EnglishCardModes[CardModes.DUCHESS_KEEP] = "Keep";
EnglishCardModes[CardModes.DUCHESS_DISCARD] = "Discard";
EnglishCardModes[CardModes.ORACLE_TOPDECK] = "Topdeck";
EnglishCardModes[CardModes.ORACLE_DISCARD] = "Discard";
EnglishCardModes[CardModes.JACK_KEEP] = "Keep";
EnglishCardModes[CardModes.JACK_DISCARD] = "Discard";
EnglishCardModes[CardModes.SPICE_MERCHANT_CARDS] = "Cards, Action";
EnglishCardModes[CardModes.SPICE_MERCHANT_COINS] = "Coins, Buy";
EnglishCardModes[CardModes.CATACOMBS_TAKE] = "Put in Hand";
EnglishCardModes[CardModes.CATACOMBS_DISCARD] = "Discard";
EnglishCardModes[CardModes.COUNT_DISCARD] = "Discard";
EnglishCardModes[CardModes.COUNT_TOPDECK] = "Topdeck";
EnglishCardModes[CardModes.COUNT_GAIN_COPPER] = "Gain Copper";
EnglishCardModes[CardModes.COUNT_COINS] = "+3 Coins";
EnglishCardModes[CardModes.COUNT_TRASH_HAND] = "Trash Hand";
EnglishCardModes[CardModes.COUNT_GAIN_DUCHY] = "Gain Duchy";
EnglishCardModes[CardModes.HUNTING_GROUNDS_ESTATES] = "Gain 3 Estates";
EnglishCardModes[CardModes.HUNTING_GROUNDS_DUCHY] = "Gain Duchy";
EnglishCardModes[CardModes.IRONMONGER_KEEP] = "Keep";
EnglishCardModes[CardModes.IRONMONGER_DISCARD] = "Discard";
EnglishCardModes[CardModes.SCAVENGER_DISCARD] = "Discard";
EnglishCardModes[CardModes.SCAVENGER_KEEP] = "Continue";
EnglishCardModes[CardModes.SQUIRE_ACTIONS] = "+2 Actions";
EnglishCardModes[CardModes.SQUIRE_BUYS] = "+2 Buys";
EnglishCardModes[CardModes.SQUIRE_SILVER] = "Gain Silver";
EnglishCardModes[CardModes.MERCENARY_DONT_TRASH] = "Don't Trash";
EnglishCardModes[CardModes.DOCTOR_TRASH] = "Trash";
EnglishCardModes[CardModes.DOCTOR_DISCARD] = "Discard";
EnglishCardModes[CardModes.DOCTOR_TOPDECK] = "Topdeck";
EnglishCardModes[CardModes.AMULET_COIN] = "+1 Coin";
EnglishCardModes[CardModes.AMULET_TRASH] = "Trash";
EnglishCardModes[CardModes.AMULET_SILVER] = "Gain Silver";
EnglishCardModes[CardModes.MISER_COPPER_TO_MAT] = "Copper to Mat";
EnglishCardModes[CardModes.MISER_PROFIT] = "Profit";
EnglishCardModes[CardModes.MESSENGER_DISCARD] = "Discard";
EnglishCardModes[CardModes.MESSENGER_KEEP] = "Keep";
EnglishCardModes[CardModes.QUEST_ATTACK] = "Attack";
EnglishCardModes[CardModes.QUEST_CURSES] = "2 Curses";
EnglishCardModes[CardModes.QUEST_CARDS] = "6 Cards";
EnglishCardModes[CardModes.TEACHER_CARD] = "Card";
EnglishCardModes[CardModes.TEACHER_ACTION] = "Action";
EnglishCardModes[CardModes.TEACHER_BUY] = "Buy";
EnglishCardModes[CardModes.TEACHER_COIN] = "Coin";
EnglishCardModes[CardModes.LIBRARY_DRAW_IT] = "Keep";
EnglishCardModes[CardModes.LIBRARY_SKIP_IT] = "Skip";
EnglishCardModes[CardModes.GLADIATOR_PRETEND] = "Don't Reveal";
EnglishCardModes[CardModes.SPRAWLING_CASTLE_ESTATES] = "Gain 3 Estates";
EnglishCardModes[CardModes.SPRAWLING_CASTLE_DUCHY] = "Gain Duchy";
EnglishCardModes[CardModes.CHARM_BUY_MONEY] = "+Buy, +2 Coins";
EnglishCardModes[CardModes.CHARM_COPY] = "Gain Card";
EnglishCardModes[CardModes.WILD_HUNT_CARDS] = "Cards";
EnglishCardModes[CardModes.WILD_HUNT_POINTS] = "Points";
EnglishCardModes[CardModes.HAMLET_ACTION] = "Discard for Action";
EnglishCardModes[CardModes.HAMLET_BUY] = "Discard for Buy";
EnglishCardModes[CardModes.HAMLET_BOTH] = "Both";
EnglishCardModes[CardModes.GOVERNOR_CARDS] = "+3 Cards";
EnglishCardModes[CardModes.GOVERNOR_TREASURE] = "Gain Gold";
EnglishCardModes[CardModes.GOVERNOR_REMODEL] = "Trash & Gain";
EnglishCardModes[CardModes.BLESSED_VILLAGE_NOW] = "Receive now";
EnglishCardModes[CardModes.BLESSED_VILLAGE_LATER] = "Next turn";
EnglishCardModes[CardModes.PIXIE_TRASH] = "Trash";
EnglishCardModes[CardModes.PIXIE_KEEP] = "Keep";
EnglishCardModes[CardModes.SACRED_GROVE_RECEIVE] = "Receive it";
EnglishCardModes[CardModes.SACRED_GROVE_NO] = "No thanks";
EnglishCardModes[CardModes.ZOMBIE_SPY_DISCARD] = "Discard";
EnglishCardModes[CardModes.ZOMBIE_SPY_TOPDECK] = "Topdeck";
EnglishCardModes[CardModes.START_GAME] = "Start Game";
EnglishCardModes[CardModes.PEARL_DIVER_TOPDECK] = EnglishPhrases[Phrases.TOP];
EnglishCardModes[CardModes.PEARL_DIVER_BOTTOMDECK] = EnglishPhrases[Phrases.BOTTOM];
EnglishCardModes[CardModes.SCEPTER_FAIL_ACTION] = "Fail Action";
EnglishCardModes[CardModes.SCEPTER_ADD_COINS] = "+2 Coins";
EnglishCardModes[CardModes.TREASURER_FAIL] = "Do nothing";
EnglishCardModes[CardModes.TREASURER_KEY] = "Take the Key";
EnglishCardModes[CardModes.BORDER_GUARD_LANTERN] = "Lantern";
EnglishCardModes[CardModes.BORDER_GUARD_HORN] = "Horn";
EnglishCardModes[CardModes.BORDER_GUARD_FAIL] = "Do nothing";
EnglishCardModes[CardModes.PAGEANT_YES] = "+1 Coffers";
EnglishCardModes[CardModes.PAGEANT_NO] = "No thanks";
EnglishCardModes[CardModes.SINISTER_PLOT_ADD] = "Add token";
EnglishCardModes[CardModes.SINISTER_PLOT_DRAW] = "Draw";
EnglishCardModes[CardModes.PLAY] = "Play";
EnglishCardModes[CardModes.DONT_PLAY] = "Don't Play";
EnglishCardModes[CardModes.VILLAGE_GREEN_NOW] = "Now";
EnglishCardModes[CardModes.VILLAGE_GREEN_LATER] = "Next turn";
EnglishCardModes[CardModes.BARGE_NOW] = "+3 Cards +1 Buy";
EnglishCardModes[CardModes.BARGE_LATER] = "Next turn";
EnglishCardModes[CardModes.SCRAP_CARD] = "+1 Card";
EnglishCardModes[CardModes.SCRAP_ACTION] = "+1 Action";
EnglishCardModes[CardModes.SCRAP_BUY] = "+1 Buy";
EnglishCardModes[CardModes.SCRAP_COIN] = "+1 Coin";
EnglishCardModes[CardModes.SCRAP_SILVER] = "gain a Silver";
EnglishCardModes[CardModes.SCRAP_HORSE] = "gain a Horse";
EnglishCardModes[CardModes.MODE_OF_THE_BUTTERFLY] = "Butterfly";
EnglishCardModes[CardModes.MODE_OF_THE_CAMEL] = "Camel";
EnglishCardModes[CardModes.MODE_OF_THE_CHAMELEON] = "Chameleon";
EnglishCardModes[CardModes.MODE_OF_THE_FROG] = "Frog";
EnglishCardModes[CardModes.MODE_OF_THE_GOAT] = "Goat";
EnglishCardModes[CardModes.MODE_OF_THE_HORSE] = "Horse";
EnglishCardModes[CardModes.MODE_OF_THE_MOLE] = "Mole";
EnglishCardModes[CardModes.MODE_OF_THE_MONKEY] = "Monkey";
EnglishCardModes[CardModes.MODE_OF_THE_MOUSE] = "Mouse";
EnglishCardModes[CardModes.MODE_OF_THE_MULE] = "Mule";
EnglishCardModes[CardModes.MODE_OF_THE_OTTER] = "Otter";
EnglishCardModes[CardModes.MODE_OF_THE_OWL] = "Owl";
EnglishCardModes[CardModes.MODE_OF_THE_OX] = "Ox";
EnglishCardModes[CardModes.MODE_OF_THE_PIG] = "Pig";
EnglishCardModes[CardModes.MODE_OF_THE_RAT] = "Rat";
EnglishCardModes[CardModes.MODE_OF_THE_SEAL] = "Seal";
EnglishCardModes[CardModes.MODE_OF_THE_SHEEP] = "Sheep";
EnglishCardModes[CardModes.MODE_OF_THE_SQUIRREL] = "Squirrel";
EnglishCardModes[CardModes.MODE_OF_THE_TURTLE] = "Turtle";
EnglishCardModes[CardModes.MODE_OF_THE_WORM] = "Worm";
EnglishCardModes[CardModes.BUTTERFLY_RETURN_IT] = "Return it";
EnglishCardModes[CardModes.BUTTERFLY_DO_NOTHING] = "Do nothing";
EnglishCardModes[CardModes.WAYFARER_SILVER] = "Gain Silver";
EnglishCardModes[CardModes.DESPERATION_CURSE] = "Gain Curse";
EnglishCardModes[CardModes.CRYSTAL_BALL_TRASH] = "Trash";
EnglishCardModes[CardModes.CRYSTAL_BALL_DISCARD] = "Discard";
EnglishCardModes[CardModes.CRYSTAL_BALL_PLAY] = "Play";
EnglishCardModes[CardModes.CRYSTAL_BALL_TOPDECK] = "Topdeck";
EnglishCardModes[CardModes.TOWN_VILLAGE] = "+1 Card +2 Actions";
EnglishCardModes[CardModes.TOWN_COINS_BUY] = "+1 Buy +2 Coins";
EnglishCardModes[CardModes.MODIFY_CANTRIP] = "+1 Card +1 Action";
EnglishCardModes[CardModes.MODIFY_GAIN] = "Gain a card";
EnglishCardModes[CardModes.DESERT_GUIDES_KEEP] = "Keep it";
EnglishCardModes[CardModes.DESERT_GUIDES_NEW_HAND] = "New Hand";
EnglishCardModes[CardModes.BAUBLE_BUY] = "+1 Buy";
EnglishCardModes[CardModes.BAUBLE_COIN] = "+1 Coin";
EnglishCardModes[CardModes.BAUBLE_FAVOR] = "+1 Favor";
EnglishCardModes[CardModes.BAUBLE_TOPDECK] = "Topdecking";
EnglishCardModes[CardModes.BROKER_CARDS] = "+Cards";
EnglishCardModes[CardModes.BROKER_ACTIONS] = "+Actions";
EnglishCardModes[CardModes.BROKER_COINS] = "+Coins";
EnglishCardModes[CardModes.BROKER_FAVORS] = "+Favors";
EnglishCardModes[CardModes.INNKEEPER_CARD] = "+1 Card";
EnglishCardModes[CardModes.INNKEEPER_3] = "+3 Cards, Discard 3";
EnglishCardModes[CardModes.INNKEEPER_5_6] = "+5 Cards, Discard 6";
EnglishCardModes[CardModes.CAPITAL_CITY_DISCARD] = "Discard 2 for +$2";
EnglishCardModes[CardModes.CAPITAL_CITY_PAY] = "$2 for +2 Cards";
EnglishCardModes[CardModes.SPECIALIST_PLAY_AGAIN] = "Play Again";
EnglishCardModes[CardModes.SPECIALIST_GAIN_COPY] = "Gain Copy";
EnglishCardModes[CardModes.HILL_FORT_TO_HAND] = "Put in Hand";
EnglishCardModes[CardModes.HILL_FORT_CANTRIP] = "+1 Card +1 Action";
EnglishCardModes[CardModes.STRONGHOLD_COINS] = "+3 Coins";
EnglishCardModes[CardModes.STRONGHOLD_CARDS] = "next turn: +3 Cards";
EnglishCardModes[CardModes.TOWN_CRIER_COINS] = "+2 Coins";
EnglishCardModes[CardModes.TOWN_CRIER_SILVER] = "gain a Silver";
EnglishCardModes[CardModes.TOWN_CRIER_CANTRIP] = "+1 Card +1 Action";
EnglishCardModes[CardModes.BLACKSMITH_OWL] = "draw until 6";
EnglishCardModes[CardModes.BLACKSMITH_OTTER] = "+2 Cards";
EnglishCardModes[CardModes.BLACKSMITH_PIG] = "+1 Card +1 Action";
EnglishCardModes[CardModes.LURKER_TRASH] = "Trash from Supply";
EnglishCardModes[CardModes.LURKER_GAIN] = "Gain from Trash";
EnglishCardModes[CardModes.GRAVEROBBER_GAIN] = "Gain onto deck";
EnglishCardModes[CardModes.GRAVEROBBER_TRASH] = "Expand from hand";
EnglishCardModes[CardModes.TREASURER_TRASH] = "Trash";
EnglishCardModes[CardModes.TREASURER_GAIN] = "Gain from Trash";
EnglishCardModes[CardModes.INVESTMENT_COIN] = "+1 Coin";
EnglishCardModes[CardModes.INVESTMENT_VP] = "Trash for +VP";
EnglishCardModes[CardModes.WEAVER_SILVERS] = "2 Silvers";
EnglishCardModes[CardModes.UNUSED_CARDMODE_7] = "unused";
EnglishCardModes[CardModes.UNUSED_CARDMODE_8] = "unused";
EnglishCardModes[CardModes.UNUSED_CARDMODE_9] = "unused";
EnglishCardModes[CardModes.UNUSED_CARDMODE_10] = "unused";
EnglishCardModes[CardModes.UNUSED_CARDMODE_11] = "unused";
EnglishCardModes[CardModes.CABIN_BOY_COINS] = "+2 Coins";
EnglishCardModes[CardModes.CABIN_BOY_GAIN] = "Trash for Duration";
EnglishCardModes[CardModes.GONDOLA_NOW] = "+2 Coins";
EnglishCardModes[CardModes.GONDOLA_LATER] = "Next Turn";
EnglishCardModes[CardModes.QUARTERMASTER_GAIN] = "Gain a card";
EnglishCardModes[CardModes.QUARTERMASTER_TAKE] = "Take a card";
EnglishCardModes[CardModes.AMPHORA_NOW] = "+1 Buy, +3 Coins";
EnglishCardModes[CardModes.AMPHORA_LATER] = "Later";
EnglishCardModes[CardModes.SPELL_SCROLL_PLAY] = "Play";
EnglishCardModes[CardModes.SPELL_SCROLL_REFUSE] = "Don't Play";
EnglishCardModes[CardModes.COURSER_CARDS_ACTIONS] = "Cards & Actions";
EnglishCardModes[CardModes.COURSER_CARDS] = "+2 Cards";
EnglishCardModes[CardModes.COURSER_ACTIONS] = "+2 Actions";
EnglishCardModes[CardModes.COURSER_COINS] = "+2 Coins";
EnglishCardModes[CardModes.COURSER_SILVERS] = "+4 Silvers";
EnglishCardModes[CardModes.SICKNESS_FAIL_DISCARD] = "Fail to discard";
EnglishCardModes[CardModes.SICKNESS_CURSE] = "Curse onto your deck";
EnglishCardModes[CardModes.SICKNESS_FAIL_CURSE] = "Fail to gain a Curse";
EnglishCardModes[CardModes.SICKNESS_DISCARD] = "Discard 3 cards";
EnglishCardModes[CardModes.KITSUNE_ACTIONS] = "+2 Actions";
EnglishCardModes[CardModes.KITSUNE_COINS] = "+2 Coins";
EnglishCardModes[CardModes.KITSUNE_CURSE] = "Give Curse";
EnglishCardModes[CardModes.KITSUNE_SILVER] = "Gain a Silver";

EnglishCardModes[ExtraTurnTypes.MISSION] = EnglishCardNames[CardNames.MISSION].singular;
EnglishCardModes[ExtraTurnTypes.OUTPOST] = EnglishCardNames[CardNames.OUTPOST].singular;
EnglishCardModes[ExtraTurnTypes.POSSESSION] = EnglishCardNames[CardNames.POSSESSION].singular;
EnglishCardModes[ExtraTurnTypes.FLEET] = EnglishCardNames[CardNames.FLEET].singular;
EnglishCardModes[ExtraTurnTypes.SEIZE_THE_DAY] = EnglishCardNames[CardNames.SEIZE_THE_DAY].singular;
EnglishCardModes[ExtraTurnTypes.ISLAND_FOLK] = EnglishCardNames[CardNames.ISLAND_FOLK].singular;
EnglishCardModes[ExtraTurnTypes.VOYAGE] = EnglishCardNames[CardNames.VOYAGE].singular;
EnglishCardModes[ExtraTurnTypes.JOURNEY] = EnglishCardNames[CardNames.JOURNEY].singular;