"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Cost = function () {
    function Cost(coin) {
        var potion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
        var debt = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
        var isSpecial = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
        var isEvent = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

        _classCallCheck(this, Cost);

        this.coin = coin;
        this.potion = potion;
        this.debt = debt;
        this.isSpecial = isSpecial;
    }

    _createClass(Cost, [{
        key: "shouldShowCoinCost",
        value: function shouldShowCoinCost() {
            return !(this.coin === 0 && (this.potion > 0 || this.debt > 0));
        }
    }, {
        key: "shouldShowPotionCost",
        value: function shouldShowPotionCost() {
            return this.potion > 0;
        }
    }, {
        key: "shouldShowDebtCost",
        value: function shouldShowDebtCost() {
            return this.debt > 0;
        }
    }, {
        key: "equals",
        value: function equals(that) {
            return that && this.coin === that.coin && this.potion === that.potion && this.debt === that.debt;
        }
    }, {
        key: "copy",
        value: function copy() {
            return new Cost(this.coin, this.potion, this.debt, this.isSpecial);
        }
    }, {
        key: "toString",
        value: function toString() {
            return "(" + this.coin + " coins; " + this.potion + " potions; " + this.debt + " debt)";
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var coins = reader.readInt();
            var potions = reader.readInt();
            var debt = reader.readInt();
            return new Cost(coins, potions, debt);
        }
    }]);

    return Cost;
}();

function costSorter(cost1, cost2) {
    var coinDifference = cost1.coin - cost2.coin;
    if (coinDifference !== 0) return coinDifference;
    var potionDifference = cost1.potion - cost2.potion;
    if (potionDifference !== 0) return potionDifference;
    return cost1.debt - cost2.debt;
}

function cardNameSorter(cardName1, cardName2) {
    if (cardName1.isLandscape() && !cardName2.isLandscape()) return 1;
    if (!cardName1.isLandscape() && cardName2.isLandscape()) return -1;
    var costDifference = costSorter(cardName1.cost, cardName2.cost);
    if (costDifference !== 0) return costDifference;
    return cardName1.name.localeCompare(cardName2.name);
}

function stackCardNameSorter(cardName1, cardName2) {
    var groupDifference = cardName1.sortingGroup - cardName2.sortingGroup;
    return groupDifference != 0 ? groupDifference : cardNameSorter(cardName1, cardName2);
}