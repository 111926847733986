"use strict";

var RussianErrors = {};

RussianErrors[FailureReasons.WRONG_PASSWORD] = "Неверный пароль.";
RussianErrors[FailureReasons.NOT_ENOUGH_PLAYERS] = "Недостаточно игроков.";
RussianErrors[FailureReasons.TOO_MANY_PLAYERS] = "Слишком много игроков.";
RussianErrors[FailureReasons.NEW_HOST_NOT_FOUND] = "Невозможно найти новый хост.";
RussianErrors[FailureReasons.ALREADY_JOINED_A_TABLE] = "Вы уже присоединились к игре.";
RussianErrors[FailureReasons.NOT_ALLOWED_TO_JOIN] = "Вы не можете присоединиться.";
RussianErrors[FailureReasons.BAD_CLIENT_VERSION] = "Неправильная версия клиента.";
RussianErrors[FailureReasons.UNKNOWN_RULE_INDEX] = "Неизвестный индекс правила.";
RussianErrors[FailureReasons.RULE_NOT_REMOVABLE] = "Это правило невозможно отменить.";
RussianErrors[FailureReasons.INVITEE_NOT_LOGGED_IN] = "Приглашённый вами пользователь сейчас не в сети.";
RussianErrors[FailureReasons.NOT_ALLOWED_TO_PLAY] = "Вы не можете играть";
RussianErrors[FailureReasons.TABLE_CHAT_REQUIRES_TABLE] = "Нужно находиться за столом, чтобы пользоваться чатом.";
RussianErrors[FailureReasons.SPECTATOR_CHAT_REQUIRES_TABLE] = "Нужно находиться за столом, чтобы пользоваться чатом зрителей.";
RussianErrors[FailureReasons.LOGIN_REQUIRED] = "Вам нужно войти в сеть.";
RussianErrors[FailureReasons.COMMAND_REQUIRES_TABLE] = "Для выполнения этой команды нужно находиться за столом.";
RussianErrors[FailureReasons.COMMAND_REQUIRES_HOST] = "Для выполнения этой команды необходимо быть хостом.";
RussianErrors[FailureReasons.RULE_CONFLICTS] = "Невозможное сочетание правил.";
RussianErrors[FailureReasons.NO_GAMESERVER_AVAILABLE] = "Нет доступного игрового сервера.";
RussianErrors[FailureReasons.SELECTED_CARD_NOT_OWNED] = "Вы не владеете выбранной картой.";
RussianErrors[FailureReasons.SELECTED_CARD_NOT_KINGDOM_CARD] = "Выбранная карта не является картой королевства.";
RussianErrors[FailureReasons.SELECTED_TOO_MANY_EVENTS_OR_LANDMARKS] = "Выбрано слишком много Событий или Вех.";
RussianErrors[FailureReasons.THIS_IS_NOT_YOUR_TABLE] = "Это не ваш стол.";
RussianErrors[FailureReasons.ALREADY_LOGGED_IN] = "Вы уже в сети.";
RussianErrors[FailureReasons.CANNOT_RECONNECT_WHEN_STILL_CONNECTED] = "Невозможно переподключиться, вы всё ещё подключены.";
RussianErrors[FailureReasons.KICK_FAILED] = "Не удалось прогнать.";
RussianErrors[FailureReasons.ALREADY_STARTING] = "Уже начинается.";
RussianErrors[FailureReasons.ALREADY_STARTED] = "Уже началась.";
RussianErrors[FailureReasons.NOTHING_TO_KICK] = "Некого прогонять.";
RussianErrors[FailureReasons.INTERNAL_ERROR] = "Внутренняя ошибка.";
RussianErrors[FailureReasons.ILLEGAL_PROPERTY_CHANGE] = "Некорректное изменение свойства.";
RussianErrors[FailureReasons.CODE_ALREADY_USED] = "Код уже использован.";
RussianErrors[FailureReasons.FAILED_CONFIRMING_EMAIL] = "Не удалось подтвердить email.";
RussianErrors[FailureReasons.FAILED_RETRIEVING_USER] = "Не удалось восстановить пользователя.";
RussianErrors[FailureReasons.EMAIL_NOT_CONFIRMED_YET] = "Вы не можете быть хостом пока не подтвердили ваш email.";
RussianErrors[FailureReasons.FAILURE_SENDING_EMAIL] = "Невозможно послать email с подтверждением.";
RussianErrors[FailureReasons.CONFIRM_EMAIL_FIRST] = "Мы не можем прислать вам пароль без подтверждённого адреса email.";
RussianErrors[FailureReasons.OLD_PASSWORD_INCORRECT] = "Старый пароль неверный.";
RussianErrors[FailureReasons.INCORRECT_PRICE] = "Неправильная цена.";
RussianErrors[FailureReasons.PURCHASE_IN_FUTURE] = "Эта покупка пришла из будущего.";
RussianErrors[FailureReasons.PURCHASE_TOO_OLD] = "Покупка устарела; попробуйте ещё раз";
RussianErrors[FailureReasons.FAILED_SETTING_UP_PURCHASE] = "Не удалось подготовить покупку";
RussianErrors[FailureReasons.FAILED_STORING_PURCHASE] = "Не удалось сохранить покупку";
RussianErrors[FailureReasons.DUPLICATE_USERNAME] = "Пользователь с таким именем уже существует";
RussianErrors[FailureReasons.INVALID_USERNAME] = "Неправильное имя пользователя";
RussianErrors[FailureReasons.INVALID_PASSWORD] = "Неправильный пароль";
RussianErrors[FailureReasons.INVALID_GENDER] = "Неправильный пол";
RussianErrors[FailureReasons.PURCHASE_TOO_SHORT] = "Минимальный срок подписки - один месяц";
RussianErrors[FailureReasons.PURCHASE_TOO_LONG] = "На данный момент мы не продаём подписки далее чем за 2024 год";
RussianErrors[FailureReasons.METASERVER_INACTIVE] = "Новая версия была выпущена. Пожалуйста, обновите страницу";
RussianErrors[FailureReasons.NO_SUCH_TABLE] = "Стол не существует.";
RussianErrors[FailureReasons.NO_SUCH_USER] = "Пользователь не существует.";
RussianErrors[FailureReasons.NO_SUCH_CODE] = "Код не существует.";
RussianErrors[FailureReasons.RESERVED_USERNAME] = "Это имя пользователя зарезервировано.";
RussianErrors[FailureReasons.PRICE_IS_ZERO] = "Вы не можете сделать покупку на нулевую сумму.";
RussianErrors[FailureReasons.NO_SUCH_USERNAME] = "Имя пользователя не существует.";
RussianErrors[FailureReasons.NO_SUCH_GAMELOG] = "Запрошенная игра не найдена.";
RussianErrors[FailureReasons.ILLEGAL_PLAYER_ORDER] = "Некорректный порядок хода.";
RussianErrors[FailureReasons.INVALID_SESSION_ID] = "Некорректный ID сессии.";
RussianErrors[FailureReasons.ACTION_CODE_EXPIRED] = "Промокод истек.";
RussianErrors[FailureReasons.BOT_GAMES_CANT_BE_RATED] = "Игры с ботом не влияют на рейтинг.";
RussianErrors[FailureReasons.TOO_MANY_PLAYERS_FOR_RATED_GAME] = "Игры с количеством игроков больше 4х не влияют на рейтинг.";
RussianErrors[FailureReasons.ALREADY_ON_BLACKLIST] = "Уже в черном списке";
RussianErrors[FailureReasons.NOT_ON_BLACKLIST] = "Не в черном списке";
RussianErrors[FailureReasons.ALREADY_ON_FRIENDLIST] = "Уже в списке друзей";
RussianErrors[FailureReasons.NOT_ON_FRIENDLIST] = "Не в списке друзей";
RussianErrors[FailureReasons.SEARCHING_FOR_MYSELF] = "Нельзя добавить себя.";
RussianErrors[FailureReasons.SEARCHED_FOR_BOT] = "Нельзя добавить бота.";
RussianErrors[FailureReasons.USER_IS_BANNED] = "Ваш аккаунт заблокирован.";
RussianErrors[FailureReasons.WRONG_BONUS_CODE_TYPE] = "Неверный тип промокода.";
RussianErrors[FailureReasons.ILLEGAL_BONUS_CODE] = "Некорректый промокод.";
RussianErrors[FailureReasons.GAME_ID_SHOULD_BE_NUMBER] = "ID игры должен состоять из цифр.";
RussianErrors[FailureReasons.NOT_SUBSCRIBED_TO] = "Нет подписки к";
RussianErrors[FailureReasons.KINGDOM_HAS_TOO_MANY_CARDS] = "Выбрано слишком много карт.";
RussianErrors[FailureReasons.NO_CARDS_AVAILABLE_TO_FILL_KINGDOM] = "Выбрано недостаточно карт.";
RussianErrors[FailureReasons.FAILED_TO_SELECT_BANE_CARD] = "Не удалось выбрать карту Погибели.";
RussianErrors[FailureReasons.FAILED_TO_SELECT_MOUSE_CARD] = "Не удалось выбрать карту для Пути Мыши.";
RussianErrors[FailureReasons.FAILED_TO_SELECT_FERRYMAN_CARD] = "Не удалось выбрать карту для Паромщика.";
RussianErrors[FailureReasons.FAILED_TO_SELECT_PREVIEW_CARDS] = "Не удалось выбрать карту превью.";
RussianErrors[FailureReasons.FAILED_TO_SELECT_ALLY] = "Не удалось выбрать Союзника.";
RussianErrors[FailureReasons.FAILED_TO_SELECT_LIASON] = "Не удалось выбрать Услугу.";
RussianErrors[FailureReasons.REQUIRING_RANDOM_CARD_FROM_DISABLED_EXPANSION] = "Нужна случайная карта из неактивированного дополнения.";
RussianErrors[FailureReasons.COMMAND_REQUIRES_PLAYER] = "Эта команда только для игроков.";
RussianErrors[FailureReasons.COMMAND_REQUIRES_GAME] = "This command requires a game.";
RussianErrors[FailureReasons.ILLEGAL_ANSWER] = "Provided an illegal answer in this game.";
RussianErrors[FailureReasons.ILLEGAL_REQUEST] = "Illegal request in this game.";
RussianErrors[FailureReasons.NO_SUCH_UNDO] = "No such undo.";
RussianErrors[FailureReasons.SERVER_DOESNT_ALLOW_NEW_GAMES] = "Server doesn't allow new games. Please reload the page.";
RussianErrors[FailureReasons.SERVER_IS_DOWN] = "Server is down. Please reload the page.";

var RussianSuccessDescriptions = {};

RussianSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_FORGOT_PASSWORD] = "Вам послано письмо, чтобы вы могли сбросить пароль.";
RussianSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_CONFIRM_EMAIL] = "Вам послано письмо для подтверждения вашего адреса.";
RussianSuccessDescriptions[SuccessDescriptions.PASSWORD_CHANGED] = "Пароль изменён";
RussianSuccessDescriptions[SuccessDescriptions.KICK_SUCCESS] = "Вы успешно прогнали другого пользователя";
RussianSuccessDescriptions[SuccessDescriptions.PURCHASE_SUCCESS] = "Покупка удачно завершена";
RussianSuccessDescriptions[SuccessDescriptions.EMAIL_CONFIRMED] = "Адрес email подтверждён";
RussianSuccessDescriptions[SuccessDescriptions.REFUND_SUCCESS] = "Покупка успешно отменена";
RussianSuccessDescriptions[SuccessDescriptions.ACTION_CODE_USED] = "Промокод принят.";
RussianSuccessDescriptions[SuccessDescriptions.SUBSCRIPTION_UPDATED] = "Ваша подписка обновлена.";

var RussianTableRules = {};

RussianTableRules[TableRuleIds.PLAYERS_CAN_SEE_SPECTATOR_CHAT] = "Игроки могут видеть переписку зрителей";
RussianTableRules[TableRuleIds.BANNED_CARDS] = "Запрещённые карты";
RussianTableRules[TableRuleIds.REQUIRED_CARDS] = "Требуемые карты";
RussianTableRules[TableRuleIds.USED_EXPANSIONS] = "Использованные дополнения";
RussianTableRules[TableRuleIds.RESTRICTIVE_PLAYER_RULES] = "Только отмеченные пользователи могут играть";
RussianTableRules[TableRuleIds.SPECTATE_RULES] = "Разрешить зрителей";
RussianTableRules[TableRuleIds.MIN_RATING] = "Мин. относительный рейтинг";
RussianTableRules[TableRuleIds.MAX_RATING] = "Макс. относительный рейтинг";
RussianTableRules[TableRuleIds.RANDOM_SEED] = "Указать старт для RNG";
RussianTableRules[TableRuleIds.MIN_PLAYERS] = "Мин. игроков";
RussianTableRules[TableRuleIds.MAX_PLAYERS] = "Макс. игроков";
RussianTableRules[TableRuleIds.SPECIAL_KINGDOM_RULES] = "Особые правила";
RussianTableRules[TableRuleIds.RESPECTED_CARD_LISTS] = "Активировать списки";
RussianTableRules[TableRuleIds.SHOW_VP_COUNTER] = "Показывать счётчик очков победы";
RussianTableRules[TableRuleIds.UNDO_SETTINGS] = "Настройки отмены действий";
RussianTableRules[TableRuleIds.ALWAYS_ALLOWED_PLAYER_RULES] = "ALWAYS_ALLOWED_PLAYER_RULES";
RussianTableRules[TableRuleIds.PLAYER_ORDER] = "Порядок хода игроков";
RussianTableRules[TableRuleIds.REPLAY_INSTRUCTIONS] = "Повторить игру";
RussianTableRules[TableRuleIds.RATED_GAME] = "Игра с рейтингом";
RussianTableRules[TableRuleIds.PREVIEW_GAME] = "Превью Зверинца";
RussianTableRules[TableRuleIds.SCRIPTED_RULES] = "Особенные правила Королевства";
RussianTableRules[TableRuleIds.CARD_POOL_LEVEL] = "Уровень сложности карт";

var RussianActivities = {};

RussianActivities[ActivityStati.LOGGED_IN] = "В сети";
RussianActivities[ActivityStati.PLAYING] = "Играет";
RussianActivities[ActivityStati.SPECTATING] = "Наблюдает";
RussianActivities[ActivityStati.AT_TABLE_PLAYER] = "За столом (игрок)";
RussianActivities[ActivityStati.AT_TABLE_SPECTATOR] = "За столом (зритель)";
RussianActivities[ActivityStati.DISCONNECTED] = "Не в сети";

var RussianTableStati = {};

RussianTableStati[TableStati.NEW] = "Новый";
RussianTableStati[TableStati.POST_GAME] = "Игра закончена";
RussianTableStati[TableStati.RUNNING] = "Идёт игра";
RussianTableStati[TableStati.ABANDONED] = "Покинут";

var RussianLobbyButtons = {
    DONE: "Подтвердить",
    RESET: "Сбросить",
    CLEAR_SELECTION: "Сбросить выделение",
    SHOW_TEXT_KINGDOM: "Показать королевство текстом",
    NEW_TABLE: "Новый стол",
    START_GAME: "Начать игру",
    LEAVE_TABLE: "Покинуть стол",
    SELECT_KINGDOM: "Выбрать карты королевства",
    LIST_BANNED: "Забаненные",
    LIST_DISLIKED: "Не нравятся",
    LIST_LIKED: "Нравятся",
    LIST_FAMILIAR: "Знакомые карты",
    LOAD_FROM_END: "Загрузить с конца",
    SELECT_COLONIES: "Колонии: ",
    SELECT_SHELTERS: "Убежища: "
};

var RussianLobbyTexts = {
    AUTOCOMPLETE_FAILED: "По \"CARDS\" ничего не нашлось.",
    ADD_CARDS_AUTOCOMPLETE: "Введите желаемые карты",
    BAN_CARDS_AUTOCOMPLETE: "Добавьте запрещённые карты",
    KINGDOM_OPTIONS: "Настройки королевства",
    GAME_OPTIONS: "Настройки игры",
    RESPECTED_CARDS: "Обязательные карты",
    RELATIVE_LEVEL: "Относительный уровень",
    LEVEL_INTERVAL: "от ${min} до ${max}",
    EMPTY_SLOT: "Пустой слот",
    REMOVE_SLOT: "Удалить слот",
    ADD_SLOT: "Добавить слот",
    RANDOMIZE_PLAYER_ORDER: "Случайный порядок хода",
    CHAT: "Чат",
    SELECT_CARDS: "Выбрать карты"
};

var RussianTernaryFieldTexts = {};

RussianTernaryFieldTexts[TernaryField.YES] = "Да";
RussianTernaryFieldTexts[TernaryField.NO] = "Нет";
RussianTernaryFieldTexts[TernaryField.RANDOM] = "Случайно";

var RussianTabs = {};

RussianTabs[LobbyTabs.INBOX] = "Входящие";
RussianTabs[LobbyTabs.MATCH] = "Поиск соперников";
RussianTabs[LobbyTabs.FRIEND_LIST] = "Список друзей";
RussianTabs[LobbyTabs.TABLES] = "Список столов";
RussianTabs[LobbyTabs.NEW_TABLE] = "Новый стол";
RussianTabs[LobbyTabs.MY_TABLE] = "Мой стол";
RussianTabs[LobbyTabs.ACCOUNT] = "Учётная запись";
RussianTabs[LobbyTabs.STORE] = "Магазин";
RussianTabs[LobbyTabs.OPTIONS] = "Настройки";
RussianTabs[LobbyTabs.FAMILIAR_CARDS] = "Знакомые карты";
RussianTabs[LobbyTabs.LEADERBOARD] = "Список лидеров";
RussianTabs[LobbyTabs.CARDLISTS] = "Списки карт";

var RussianMatchTypes = {};

RussianMatchTypes[AutomatchTypes.QUICK_MATCH] = "Любой оппонент";
RussianMatchTypes[AutomatchTypes.GOOD_MATCH] = "Равный оппонент";
RussianMatchTypes[AutomatchTypes.MATCH_WITH_EXPANSIONS] = "Дождаться игры с дополнениями";
RussianMatchTypes[AutomatchTypes.MATCH_WITH_BOT] = "Бот";
RussianMatchTypes[AutomatchTypes.MATCH_UNRATED] = "Против человека";

var RussianUndoSettings = {};

RussianUndoSettings[UndoSettings.NEVER] = "Никогда";
RussianUndoSettings[UndoSettings.ASK] = "Спрашивать";
RussianUndoSettings[UndoSettings.ALWAYS] = "Всегда";

var RussianGroupIds = {};

RussianGroupIds[GroupIds.EVERYBODY] = "Все";
RussianGroupIds[GroupIds.NOBODY] = "Никто";
RussianGroupIds[GroupIds.FRIENDS_OF] = "Друзья";
RussianGroupIds[GroupIds.LIST_PLAYER_IDS] = "Выбранные игроки:";

var RussianCountries = {};

RussianCountries[Countries.NON_EU] = "Не ЕС";
RussianCountries[Countries.AT] = "Австрия";
RussianCountries[Countries.BE] = "Бельгия";
RussianCountries[Countries.BG] = "Болгария";
RussianCountries[Countries.HR] = "Хорватия";
RussianCountries[Countries.CY] = "Кипр";
RussianCountries[Countries.CZ] = "Чехия";
RussianCountries[Countries.DK] = "Дания";
RussianCountries[Countries.EE] = "Эстония";
RussianCountries[Countries.FI] = "Финляндия";
RussianCountries[Countries.FR] = "Франция";
RussianCountries[Countries.DE] = "Германия";
RussianCountries[Countries.GR] = "Греция";
RussianCountries[Countries.HU] = "Венгрия";
RussianCountries[Countries.IE] = "Ирландия";
RussianCountries[Countries.IT] = "Италия";
RussianCountries[Countries.LV] = "Латвия";
RussianCountries[Countries.LT] = "Литва";
RussianCountries[Countries.LU] = "Люксембург";
RussianCountries[Countries.MT] = "Мальта";
RussianCountries[Countries.NL] = "Голландия";
RussianCountries[Countries.PL] = "Польша";
RussianCountries[Countries.PT] = "Португалия";
RussianCountries[Countries.RO] = "Румыния";
RussianCountries[Countries.SK] = "Словакия";
RussianCountries[Countries.SI] = "Словения";
RussianCountries[Countries.ES] = "Испания";
RussianCountries[Countries.SE] = "Швеция";
RussianCountries[Countries.GB] = "Великобритания";

var RussianAutomatchQuestions = {};
var RussianAutomatchValues = {};

RussianAutomatchQuestions[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS] = "Число игроков";
RussianAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS] = {};
RussianAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.REQUIRE] = "два";
RussianAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.EITHER] = "два или три";
RussianAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.REJECT] = "три";

RussianAutomatchQuestions[AUTOMATCH_QUESTION.RATED_GAME] = "Рейтинг";
RussianAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME] = {};
RussianAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.REQUIRE] = "с рейтингом";
RussianAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.EITHER] = "без предпочтения";
RussianAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.REJECT] = "без рейтинга";

RussianAutomatchQuestions[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME] = "Больше вторых изданий";
RussianAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME] = {};
RussianAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.REQUIRE] = "включить";
RussianAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.EITHER] = "без предпочтения";
RussianAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.REJECT] = "выключить";

RussianAutomatchQuestions[AUTOMATCH_QUESTION.PREVIEW_GAME] = "Превью вторых изданий";
RussianAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME] = {};
RussianAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.REQUIRE] = "включить";
RussianAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.REJECT] = "выключить";
RussianAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.EITHER] = "без предпочтения";