"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ClientActions = function () {
    function ClientActions() {
        _classCallCheck(this, ClientActions);
    }

    _createClass(ClientActions, null, [{
        key: "openUrl",
        value: function openUrl(url) {
            window.open(url);
        }
    }, {
        key: "gotoTab",
        value: function gotoTab(tab) {
            activeMeta.broadcast(Events.GOTO_TAB, tab);
        }
    }]);

    return ClientActions;
}();