"use strict";

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function createBackgroundStyle(source) {
    return { "background": "url(\'" + source + "\') no-repeat", "background-size": "cover", "background-position": "right" };
}

function createContrastedBackgroundStyle(source, contrast) {
    return {
        "background": "url(\'" + source + "\') no-repeat", "background-size": "cover",
        "filter": "contrast(" + contrast + "%)"
    };
}

String.prototype.capitalizeFirstLetter = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.decapitalizeFirstLetter = function () {
    return this.charAt(0).toLowerCase() + this.slice(1);
};

function stringCapitalize(string) {
    return String(string).capitalizeFirstLetter();
};

function stringDowncase(string) {
    return String(string).toLowerCase().slice(0);
};

function getVw() {
    return window.innerWidth / 100;
}

function getVh() {
    return window.innerHeight / 100;
}

function getTypeString(cardTypes) {
    var typeString = "";
    ObjectValues(Types).forEach(function (t) {
        if (cardTypes.includes(t)) typeString += " - " + t.name;
    });
    return typeString.substring(3);
}

function removeIrrelevantTypes(typeString) {
    NO_TEMPLATE_TYPES.forEach(function (type) {
        return typeString = typeString.replace('-' + type.name.toLowerCase(), '');
    });
    return typeString;
}

function removeIrrelevantMiniTypes(typeString) {
    NO_MINI_TEMPLATE_TYPES.forEach(function (type) {
        return typeString = typeString.replace('-' + type.name.toLowerCase(), '');
    });
    return typeString;
}

var cachedWebpsAreSupported = null;

function webpsAreSupported() {
    if (cachedWebpsAreSupported !== null) return cachedWebpsAreSupported;

    var elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
        var result = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
        cachedWebpsAreSupported = result;
        return result;
    } else {
        cachedWebpsAreSupported = false;
        return false;
    }
}

function getFullTemplateURL(cardTypes) {
    var isBasic = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var hasHeirloom = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

    var typeString = getTypeString(cardTypes).toLowerCase().replace(/ /g, '');
    typeString = removeIrrelevantTypes(typeString);
    // if (makeDurationsNotOrange && staysOut === 'remove') {
    //     typeString = typeString.replace('-duration', '');
    // }
    // if (makeThroneRoomsOrange && (staysOut === 'force') && !typeString.includes('-duration')) {
    //     typeString = typeString.replace(/-reserve/g, '').replace(/-treasure/g, '') + '-duration';
    // }
    if (webpsAreSupported()) {
        return "images/cards/templates/" + typeString + (isBasic ? "-basic" : "") + (hasHeirloom ? "-heirloom" : "") + ".webp";
    } else {
        return "images/jpeg/cards/templates/" + typeString + (isBasic ? "-basic" : "") + (hasHeirloom ? "-heirloom" : "") + ".png";
    }
}

function getMiniTemplateURL(cardTypes) {
    var isLandscape = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    if (isLandscape) {
        var s = getFullTemplateURL(cardTypes);
        return s;
    }
    var typeString = getTypeString(cardTypes).toLowerCase().replace(/ /g, '');
    typeString = removeIrrelevantMiniTypes(removeIrrelevantTypes(typeString));
    if (webpsAreSupported()) {
        return "images/cards/templates/mini-" + typeString + ".webp";
    } else {
        return "images/jpeg/cards/templates/mini-" + typeString + ".png";
    }
}

function getMicroTemplateURL(cardTypes) {
    // CR ceviri - This is really dumb - should push it into the Types someday.
    var relevantTypes = [];
    if (cardTypes.includes(Types.ACTION)) relevantTypes.push("action");
    if (cardTypes.includes(Types.TREASURE)) relevantTypes.push("treasure");
    if (cardTypes.includes(Types.RESERVE)) relevantTypes.push("reserve");
    if (cardTypes.includes(Types.CURSE)) relevantTypes.push("curse");
    if (cardTypes.includes(Types.ALLY)) relevantTypes.push("ally");
    if (cardTypes.includes(Types.STATE)) relevantTypes.push("state");
    if (cardTypes.includes(Types.LANDMARK)) relevantTypes.push("landmark");
    if (cardTypes.includes(Types.BOON)) relevantTypes.push("boon");
    if (cardTypes.includes(Types.HEX)) relevantTypes.push("hex");
    if (cardTypes.includes(Types.NIGHT)) relevantTypes.push("night");
    if (cardTypes.includes(Types.DURATION)) relevantTypes.push("duration");
    if (cardTypes.includes(Types.RUINS)) relevantTypes.push("ruins");
    if (cardTypes.includes(Types.VICTORY)) relevantTypes.push("victory");
    if (cardTypes.includes(Types.REACTION)) relevantTypes.push("reaction");
    if (cardTypes.includes(Types.TRAIT)) relevantTypes.push("trait");
    if (webpsAreSupported()) {
        return "images/cards/templates/micro-" + relevantTypes.join("-") + ".webp";
    } else {
        return "images/jpeg/cards/templates/micro-" + relevantTypes.join("-") + ".png";
    }
}

function getFullArtURL(cardName) {
    var s = toDashedLowerCase(cardName.name);
    if (!cardName.expansion) console.log("Problem with " + cardName);
    if (webpsAreSupported()) {
        return "images/cards/art/" + cardName.expansion.fileName + "/" + s + ".webp";
    } else {
        return "images/jpeg/cards/art/" + cardName.expansion.fileName + "/" + s + ".jpg";
    }
}

function getReminderArtURL(cardName) {
    var s = toDashedLowerCase(cardName.name);
    return "images/reminders/" + s + ".png";
}

function getFullBackURL() {
    return "images/cards/templates/back.jpg";
}

function getMiniBackURL() {
    return "images/cards/templates/back-mini.jpg";
}

function getMiniArtURL(cardName) {
    if (cardName.isBaseCard()) {
        var s = toDashedLowerCase(cardName.name);
        var expansionName = toDashedLowerCase(cardName.expansion.name);
        if (webpsAreSupported()) {
            return "images/cards/art/" + expansionName + "/" + "mini-" + s + ".webp";
        } else {
            return "images/jpeg/cards/art/" + expansionName + "/" + "mini-" + s + ".jpg";
        }
    } else {
        return getFullArtURL(cardName);
    }
}

function toDashedLowerCase(string) {
    return string.toLowerCase().replace(/ /g, '-');
}

function addToSet(element, set) {
    if (set.indexOf(element) === -1) set.push(element);
}

function removeElement(arr, element) {
    var i = arr.indexOf(element);
    if (i === -1) throw new Error("Element not present in array.");
    return arr.splice(i, 1);
}

function filterOut(arr, predicate) {
    var elements = arr.filter(predicate);
    elements.forEach(function (e) {
        return arr.splice(arr.indexOf(e), 1);
    });
    return arr;
}

function getCardArtLocations(cardNames) {
    var locations = [];
    cardNames.forEach(function (c) {
        addToSet(getFullArtURL(c), locations);
        addToSet(getMiniArtURL(c), locations);
        addToSet(getFullTemplateURL(c.types, c.isBaseCard(), c.hasHeirloom()), locations);
        addToSet(getMiniTemplateURL(c.types, c.isLandscape()), locations);
        addToSet(getMicroTemplateURL(c.types), locations);
    });
    return locations;
}

function getButtonArtURL(button) {
    if (button.imageName) return "images/buttons/" + button.imageName + ".png";else return false;
}

function getDelayedQuestionArtURL(cardName) {
    if (cardName === CardNames.BUSTLING_VILLAGE) return "images/buttons/gain-card.png";
    if (cardName === CardNames.ENGINEER) return "images/buttons/trash.png";
    if (cardName === CardNames.HAMLET) return "images/buttons/discard.png";
    if (cardName === CardNames.HARBINGER) return "images/buttons/topdeck.png";
    if (cardName === CardNames.ILL_GOTTEN_GAINS) return "images/buttons/take-copper.png";
    if (cardName === CardNames.IRONMONGER) return "images/buttons/discard-deck.png";
    if (cardName === CardNames.MINING_VILLAGE) return "images/buttons/trash.png";
    if (cardName === CardNames.PEARL_DIVER) return "images/buttons/topdeck.png";
    if (cardName === CardNames.PLAZA) return "images/buttons/discard.png";
    if (cardName === CardNames.SETTLERS) return "images/buttons/take-copper.png";
    return "images/buttons/continue.png";
}

var isEven = function isEven(n) {
    return n % 2 === 0;
};
var isUndefined = function isUndefined(obj) {
    return typeof obj === 'undefined' || obj === null;
};
var isDefined = function isDefined(obj) {
    return typeof obj !== 'undefined' && obj !== null;
};
var isEmpty = function isEmpty(obj) {
    return isUndefined(obj) || obj === "";
};
var isNonEmpty = function isNonEmpty(obj) {
    return !isEmpty(obj);
};

function getRandomIntFromRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getRandomBoolean() {
    return Math.random() > 0.5;
}

function getMaxOfArray(numArray) {
    return Math.max.apply(null, numArray);
}

function getMinOfArray(numArray) {
    return Math.min.apply(null, numArray);
}

function latestDate(dateArray) {
    assert(dateArray instanceof Array);
    return new Date(getMaxOfArray(dateArray));
}

function earliestDate(dateArray) {
    assert(dateArray instanceof Array);
    return new Date(getMinOfArray(dateArray));
}

function shortDateString(date) {

    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();
    var year = '' + date.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    return [year, month, day].join('-');
}

function timeString(date) {

    var hours = '' + date.getHours();
    var minutes = '' + date.getMinutes();

    if (hours.length < 2) {
        hours = '0' + hours;
    }
    if (minutes.length < 2) {
        minutes = '0' + minutes;
    }

    return [hours, minutes].join(':');
}

function zoneString(date) {

    var offset = date.getTimezoneOffset() / 60;
    var zone = 'UTC ' + (offset === 0 ? '' : (offset < 0 ? '+' : '') + offset);

    return zone;
}

function zonedTimeString(date) {

    return timeString(date) + ' ' + zoneString(date);
}

function removeFromArray(array, element) {

    var index = array.indexOf(element);

    if (index > -1) {
        array.splice(index, 1);
    }
}

function arrayToMatrix(array, rowLength) {

    var row = [];
    var matrix = [];

    array.forEach(function (item, index) {

        if (index % rowLength === 0) {
            if (row.length > 0) {
                matrix.push(row);
            }
            row = [];
        }

        row.push(item);
    });

    if (row.length > 0) {
        matrix.push(row);
    }

    return matrix;
}

function getMinOfArray(numArray) {
    return Math.min.apply(null, numArray);
}

function nDigits(number) {
    return number === 0 ? 1 : Math.ceil(Math.log10(number));
}

function rotateArray(arr, n) {
    while (arr.length && n < 0) {
        n += arr.length;
    }arr.push.apply(arr, arr.splice(0, n));
    return arr;
}

function deepValue(obj, path) {
    for (var i = 0, p = path.split('.'), len = p.length; i < len; i++) {
        obj = obj[p[i]];
    }
    return obj;
}

function stringSort(a, b) {
    return a > b ? 1 : -1;
}

function newline() {
    return "<br/>";
}

// alternative rendering
var line = function line(string) {
    var lineHeight = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

    return lineHeight + "###" + string + "//";
};

var lines = function lines(strings, fontSize, lineHeight) {
    return strings.map(function (s) {
        return s[0] === "@" ? s : line(s, fontSize);
    }).join("");
};

var boldLine = function boldLine(string, fontSize) {
    return line("|" + string + "|", fontSize);
};

var boldLines = function boldLines(strings, fontSize, lineHeight) {
    return strings.map(function (s) {
        return s[0] === "@" ? s : boldLine(s, fontSize);
    }).join("");
};

var emptyLine = function emptyLine() {
    return "5###//";
};
var insertSpace = function insertSpace(n) {
    return new Array(n).join(' ');
};

var inlineBold = function inlineBold(s) {
    return "|" + s + "|";
};
var inlineItalic = function inlineItalic(s) {
    return "%" + s + "%";
};
var inlineLeft = function inlineLeft(s) {
    return s;
};
var inlineTop = function inlineTop(s) {
    return s;
};

var addTop = function addTop(target) {
    var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return size + "###//" + target;
};
var setLandTopOffset = function setLandTopOffset(target, size) {
    return size + "###//" + target;
};
var setTopOffset = function setTopOffset(target, size) {
    return size + "###//" + target;
};
var setFontSize = function setFontSize(target, size) {
    var lines = target.split("//");
    return lines.map(function (l) {
        if (l) {
            var parts = l.split("###");
            return parseInt(parts[0]) + size + "###" + parts[1] + "//";
        }
    }).join("");
};
var move = function move(targets, leftShift) {
    var topShift = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

    var lines = targets.split("//");
    return lines.map(function (l) {
        if (l) {
            if (l.includes("###")) {
                var parts = l.split("###");
                var isElement = parts[0][0] === "@";
                if (isElement) {
                    parts[0] = parts[0].slice(3);
                    return "@@@" + (parseInt(parts[0]) + topShift) + "###" + (parseInt(parts[1]) + leftShift) + "###" + parts[2] + "//";
                } else {
                    return parseInt(parts[0]) + topShift + "###" + parts[1] + "   //";
                }
            } else {
                return l + "    ";
            }
        }
    }).join("");
};

var insertLine = function insertLine(width, height, top) {
    return "@" + top + "###---//";
};
var insertPlusCoin = function insertPlusCoin(amount) {
    var scale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.4;
    var left = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    var top = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    return "@@@" + top + "###" + left + "###|+|[" + (scale > 0.4 ? '!' : '') + amount + "]@@";
};
var insertCoin = function insertCoin(amount) {
    var scale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.4;
    var left = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    var top = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    return "@@@" + top + "###" + left + "###[" + (scale > 0.4 ? '!' : '') + amount + "]@@@";
};
var insertVP = function insertVP(amount) {
    var scale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.4;
    var left = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    var top = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    return "@@@" + top + "###" + left + "###{" + (scale > 0.4 ? '!' : '') + amount + "}@@@";
};
var insertDebt = function insertDebt(amount) {
    var scale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.4;
    var left = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    var top = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    return "@@@" + top + "###" + left + "###[" + amount + "D]@@@";
};
var insertPotion = function insertPotion() {
    var scale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0.4;
    var left = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var top = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    return "@@@" + top + "###" + left + "###[P]@@@";
};

// ----

function getNumberOfLines(string) {
    return findOccurrences(string, "</br>");
}

function findOccurrences(string, subString) {
    var allowOverlapping = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

    string += "";
    subString += "";
    if (subString.length <= 0) return string.length + 1;

    var n = 0,
        pos = 0,
        step = allowOverlapping ? 1 : subString.length;

    while (true) {
        pos = string.indexOf(subString, pos);
        if (pos >= 0) {
            ++n;
            pos += step;
        } else {
            break;
        }
    }
    return n;
}

function moveArrayItem(arr, fromIndex, toIndex) {
    var item = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, item);
}

function arraysAreEqual(arr1, arr2) {
    return arr1.length === arr2.length && arr1.every(function (e, i) {
        return arr2[i] === e;
    });
}

// Object.values not yet supported by all browsers

/*
 function getByOrdinal(pseudoEnum, ordinal) {
 return Object.values(pseudoEnum)[ordinal];
 }

 function getOrdinal(pseudoEnum, element) {
 return Object.values(pseudoEnum).indexOf(element);
 }
 */

function getKeys(obj) {
    return Object.keys(obj);
}

function getValues(obj) {
    return getKeys(obj).map(function (k) {
        return obj[k];
    });
}

function getByOrdinal(pseudoEnum, ordinal) {
    if (ordinal < 0) ordinal = 0;
    var value = pseudoEnum[Object.keys(pseudoEnum)[ordinal]];
    if (isUndefined(value) && ordinal >= 0) {
        console.log(pseudoEnum);
        throw new Error("pseudoEnum doesn't define ordinal " + ordinal);
    }
    return value;
}

function getOrdinal(pseudoEnum, element) {
    var ordinal = Object.keys(pseudoEnum).map(function (k) {
        return pseudoEnum[k];
    }).indexOf(element);
    if (ordinal === -1) throw new Error("Requested element does not exist.");
    return ordinal;
}

function getEnumString(pseudoEnum, element) {
    var index = getValues(pseudoEnum).indexOf(element);
    return getKeys(pseudoEnum)[index];
}

function cardNamesToString(items) {
    if (items === null) return "";
    return items.map(function (c) {
        if (c instanceof CardName) {
            return LANGUAGE.getCardName[c].singular;
        } else if (c instanceof Expansion) {
            return LANGUAGE.getExpansionName[c];
        }
    }).join(', ');
}

function getDoneButtonOrdinal(doneButton) {
    return getOrdinal(DoneButtonIds, doneButton);
}

function emptyArray(array) {
    array.splice(0, array.length);
}

function generateRangeArray(min, max) {
    var step = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;

    var a = [];
    for (var i = min; i <= max; i += step) {
        a.push(i);
    }
    return a;
}

function clearSelection() {
    if (document.selection) {
        document.selection.empty();
    } else if (window.getSelection) {
        window.getSelection().removeAllRanges();
    }
}

function isActive(activatedAction) {
    return activatedAction !== Actions.NONE && activatedAction !== Actions.DISABLED;
}

function generateUUID() {
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
        d += performance.now();
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
    });
    return uuid;
}

var timeLogStart = -1;

function timeLog(message) {
    var object = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";

    if (timeLogStart === -1) timeLogStart = new Date().getTime();
    if (object === "") {
        console.log(new Date().getTime() - timeLogStart + ": " + message);
    } else {
        console.log(new Date().getTime() - timeLogStart + ": " + message, object);
    }
}

function assert(b) {
    if (!b) throw new Error("Assert failed.");
}

function localStorageIsEnabled() {
    return localStorage !== null && !isUndefined(localStorage);
}

function sessionStorageIsEnabled() {
    return sessionStorage !== null && !isUndefined(sessionStorage);
}

var rootElement = angular.element(document.getElementsByTagName('body'));

function getClass(className) {
    return rootElement = angular.element(document.getElementsByClassName(className));
}

function countWatchers() {
    var root = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : rootElement;


    var watchers = [];

    var f = function f(element) {
        angular.forEach(['$scope', '$isolateScope'], function (scopeProperty) {
            if (element.data() && element.data().hasOwnProperty(scopeProperty)) {
                angular.forEach(element.data()[scopeProperty].$$watchers, function (watcher) {
                    watchers.push(watcher);
                });
            }
        });

        angular.forEach(element.children(), function (childElement) {
            f(angular.element(childElement));
        });
    };

    f(root);

    // Remove duplicate watchers
    var watchersWithoutDuplicates = [];
    angular.forEach(watchers, function (item) {
        if (watchersWithoutDuplicates.indexOf(item) < 0) {
            watchersWithoutDuplicates.push(item);
        }
    });

    console.log(watchersWithoutDuplicates.length);
}

function ObjectValues(object) {

    return Object.keys(object).map(function (key) {
        return object[key];
    });
}

function FindByValue(object, value) {

    var index = ObjectValues(object).indexOf(value);
    var keys = Object.keys(object);

    return index === -1 ? undefined : keys[index];
}

function ArrayEquals(array1, array2) {

    assert(array1 instanceof Array);
    assert(array2 instanceof Array);

    if (array1.length !== array2.length) {
        return false;
    }

    for (var i = 0, l = array1.length; i < l; i++) {

        if (array1[i] instanceof Array && array2[i] instanceof Array) {

            if (!ArrayEquals(array1[i], array[i])) {
                return false;
            }
        } else {
            if (array1[i] !== array2[i]) {
                return false;
            }
        }
    }

    return true;
}

function sameSet(array1, array2) {

    assert(array1 instanceof Array);
    assert(array2 instanceof Array);

    if (array1.some(function (item) {
        return array2.indexOf(item) === -1;
    })) {
        return false;
    }
    if (array2.some(function (item) {
        return array1.indexOf(item) === -1;
    })) {
        return false;
    }

    return true;
}

function arrayMinus(array1, array2) {

    assert(array1 instanceof Array);
    assert(array2 instanceof Array);

    var copy = array1.slice(0);

    return copy.filter(function (item) {
        return array2.indexOf(item) === -1;
    });
}

function arrayEqualsLastItem(array, item) {

    assert(array instanceof Array);

    return array[array.length - 1] === item;
}

function arrayUniq(array) {

    assert(array instanceof Array);

    var uniq = [];

    array.forEach(function (item) {
        if (uniq.indexOf(item) === -1) {
            uniq.push(item);
        }
    });

    return uniq;
}

function countSize(listOfLists) {
    return listOfLists.reduce(function (v, l) {
        return v + l.length;
    }, 0);
}

var Node = function Node(value) {
    _classCallCheck(this, Node);

    this.value = value;
    this.next = null;
    this.prev = null;
    if (value.listNode) {
        throw "value already has a listNode";
    } else {
        value.listNode = this;
    }
};

var DoublyLinkedList = function () {
    function DoublyLinkedList(name) {
        _classCallCheck(this, DoublyLinkedList);

        this.head = null;
        this.tail = null;
        this.length = 0;
        this.name = name;
    }

    _createClass(DoublyLinkedList, [{
        key: "push",
        value: function push(value) {
            var newNode = new Node(value);
            if (this.length === 0) {
                this.head = newNode;
                this.tail = newNode;
            } else {
                this.tail.next = newNode;
                newNode.prev = this.tail;
                this.tail = newNode;
            }
            this.length++;
        }
    }, {
        key: "pop",
        value: function pop() {
            if (this.length === 0) return undefined;
            var temp = this.tail;
            this.remove(temp);
            return temp.value;
        }
    }, {
        key: "shift",
        value: function shift() {
            if (this.length === 0) return undefined;
            var temp = this.head;
            this.remove(temp);
            return temp.value;
        }
    }, {
        key: "remove",
        value: function remove(node) {
            if (node.next === null) {
                if (this.tail) this.tail = this.tail.prev;
            } else {
                node.next.prev = node.prev;
            }
            if (node.prev === null) {
                if (this.head) this.head = this.head.next;
            } else {
                node.prev.next = node.next;
            }
            node.value.listNode = undefined;
            this.length--;
        }
    }, {
        key: "peek",
        value: function peek() {
            if (this.length === 0) return undefined;
            return this.head.value;
        }
    }, {
        key: "peekLast",
        value: function peekLast() {
            if (this.length === 0) return undefined;
            return this.tail.value;
        }
    }, {
        key: "unshift",
        value: function unshift(value) {
            var newNode = new Node(value);
            if (this.length === 0) {
                this.head = newNode;
                this.tail = newNode;
            } else {
                newNode.next = this.head;
                this.head.prev = newNode;
                this.head = newNode;
            }
            this.length++;
        }
    }, {
        key: "some",
        value: function some(test) {
            var temp = this.head;
            while (temp) {
                if (test(temp.value)) return true;
                temp = temp.next;
            }
            return false;
        }
    }, {
        key: "debugDump",
        value: function debugDump() {
            console.log("%o length %o", this.name, this.length);
            var temp = this.head;
            while (temp !== null) {
                console.log("     value: %o", temp.value);
                temp = temp.next;
            }
        }
    }]);

    return DoublyLinkedList;
}();

function cycleTernary(input) {
    switch (input) {
        case TernaryField.NO:
            return TernaryField.RANDOM;
        case TernaryField.RANDOM:
            return TernaryField.YES;
        case TernaryField.YES:
            return TernaryField.NO;
    }
    return TernaryField.YES;
}

var reorderSorter = function reorderSorter(a, b) {
    var o = a.allowsReordering - b.allowsReordering;
    if (o !== 0) return o;
    return a.index - b.index;
};

/* the parameter map has values that are arrays */
var reverseMap = function reverseMap(map) {
    var reversed = new Map();
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
        var _loop = function _loop() {
            var _step$value = _slicedToArray(_step.value, 2),
                key = _step$value[0],
                array = _step$value[1];

            array.forEach(function (value) {
                if (reversed.has(value)) throw 'duplicate value in map';
                reversed.set(value, key);
            });
        };

        for (var _iterator = map[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            _loop();
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally {
        try {
            if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
            }
        } finally {
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }

    return reversed;
};

Array.prototype.sum = function () {
    return undefined.reduce(function (a, b) {
        return a + b;
    }, 0);
};
var sum = function sum(array) {
    return array.reduce(function (a, b) {
        return a + b;
    }, 0);
};