"use strict";

var GermanLogEntries = {};

GermanLogEntries[LogEntryNames.GAIN] = "Argument0 nimmt Argument1.";
GermanLogEntries[LogEntryNames.GAIN_WITH] = "Argument0 nimmt Argument1. (Argument2)";
GermanLogEntries[LogEntryNames.BUY_AND_GAIN] = "Argument0 kauft und nimmt Argument1.";
GermanLogEntries[LogEntryNames.TRASH] = "Argument0 entsorgt Argument1.";
GermanLogEntries[LogEntryNames.DISCARD] = "Argument0 legt Argument1 ab.";
GermanLogEntries[LogEntryNames.DISCARD_WITH] = "Argument0 legt Argument1 ab. (Argument2)";
GermanLogEntries[LogEntryNames.PLAY] = "Argument0 spielt Argument1.";
GermanLogEntries[LogEntryNames.PLAY_AGAIN] = "Argument0 spielt Argument1 erneut.";
GermanLogEntries[LogEntryNames.PLAY_THIRD] = "Argument0 spielt Argument1 ein drittes mal.";
GermanLogEntries[LogEntryNames.TOPDECK] = "Argument0 legt Argument1 auf den Nachziehstapel.";
GermanLogEntries[LogEntryNames.DRAW] = "Argument0 zieht Argument1.";
GermanLogEntries[LogEntryNames.DRAW_FROM] = "Argument0 zieht Argument1. (Argument2)";
GermanLogEntries[LogEntryNames.REVEAL] = "Argument0 deckt Argument1 auf.";
GermanLogEntries[LogEntryNames.REVEAL_SEARCHING] = "Argument0 deckt Argument1 auf.";
GermanLogEntries[LogEntryNames.REVEAL_FINDING] = "Argument0 deckt Argument1 auf.";
GermanLogEntries[LogEntryNames.REVEAL_SEARCHING_AND_FINDING] = "Argument0 deckt Argument1 und am Ende Argument2 auf.";
GermanLogEntries[LogEntryNames.REVEAL_HAND] = "Argument0 hat in Hand: Argument1.";
GermanLogEntries[LogEntryNames.REVEAL_DISCARD] = "Argument0 hat in Ablage: Argument1.";
GermanLogEntries[LogEntryNames.LOOK_AT] = "Argument0 schaut sich Argument1 an.";
GermanLogEntries[LogEntryNames.PUT_IN_HAND] = "Argument0 nimmt Argument1 auf die Hand.";
GermanLogEntries[LogEntryNames.SET_ASIDE] = "Argument0 legt Argument1 beiseite.";
GermanLogEntries[LogEntryNames.PUT_ON_MAT] = "Argument0 legt Argument1 auf Argument2.";
GermanLogEntries[LogEntryNames.CALL] = "Argument0 ruft Argument1 auf.";
GermanLogEntries[LogEntryNames.DECK_TO_DISCARD] = "Argument0 legt den Nachziehstapel auf den Ablagestapel.";
GermanLogEntries[LogEntryNames.BACK_ON_DECK] = "Argument0 legt Argument1 zurück auf den Nachziehstapel.";
GermanLogEntries[LogEntryNames.SHUFFLE_INTO_DECK] = "Argument0 mischt Argument1 in den Nachziehstapel.";
GermanLogEntries[LogEntryNames.INSERT_IN_DECK] = "Argument0 legt Argument1 in den Nachziehstapel.";
GermanLogEntries[LogEntryNames.EXCHANGE_RETURN] = "Argument0 legt Argument1 zurück.";
GermanLogEntries[LogEntryNames.EXCHANGE_RECEIVE] = "Argument0 erhält Argument1.";
GermanLogEntries[LogEntryNames.PASS] = "Argument0 gibt Argument1 an Argument2.";
GermanLogEntries[LogEntryNames.STARTS_WITH] = "Argument0 beginnt mit folgenden Karten: Argument1.";
GermanLogEntries[LogEntryNames.BUY] = "Argument0 kauft Argument1.";
GermanLogEntries[LogEntryNames.COUNTER_ADD] = "Zähler dazu";
GermanLogEntries[LogEntryNames.COUNTER_RESET] = "Zähler zurückgesetzt";
GermanLogEntries[LogEntryNames.REACTS_WITH] = "Argument0 benutzt als Reaktion Argument1.";
GermanLogEntries[LogEntryNames.SHUFFLES] = "Argument0 mischt den Nachziehstapel.";
GermanLogEntries[LogEntryNames.NEW_TURN] = "Argument0";
GermanLogEntries[LogEntryNames.WISH_CORRECT] = "Argument0 wünscht sich Argument1 und findet die Karte.";
GermanLogEntries[LogEntryNames.WISH_WRONG] = "Argument0 wünscht sich Argument1, findet jedoch Argument2.";
GermanLogEntries[LogEntryNames.BLACK_MARKET_RESHUFFLE] = "Schwarzmarkt-Deck wird gemischt.";
GermanLogEntries[LogEntryNames.RETURN_TO] = "Argument0 legt Argument1 zurück auf Argument2.";

GermanLogEntries[LogEntryNames.PUT_ON_BOTTOM_OF] = "Argument0 legt Argument1 unter Argument2.";
GermanLogEntries[LogEntryNames.LOSE_TRACK_MOVED] = "Argument0 kann Argument1 nicht bewegen (die Karte wurde bereits bewegt).";
GermanLogEntries[LogEntryNames.LOSE_TRACK_HIDDEN] = "Argument0 kann Argument1 nicht bewegen (die Karte wurde verdeckt).";
GermanLogEntries[LogEntryNames.LOSE_TRACK_RESHUFFLED] = "Argument0 kann Argument1 nicht bewegen (die Karte wurde eingemischt).";
GermanLogEntries[LogEntryNames.LOSE_TRACK_ANONYMOUS] = "Argument0 kann nicht bewegt werden.";

GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_BANDIT_CAMP] = "Argument0.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_TOWER] = "Argument0.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH_ABSENT] = "Keine Aktionskarten mit verschiedenen Namen.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH] = "Argument0.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_WALL] = "Argument0.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_WOLF_DEN] = "genau Argument0.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_PALACE] = "Argument0.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_ORCHARD] = "Argument0.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_OBELISK] = "Argument0 Karten vom Argument1-Stapel.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_MUSEUM] = "Argument0 Karten mit verschiedenen Namen.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_KINGS_CASTLE] = "Argument0 Schlösser.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_KEEP] = "Argument0.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_HUMBLE_CASTLE] = "Argument0 Schlösser.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_FOUNTAIN] = "Argument0.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_FEODUM] = "Argument0.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_GARDENS] = "Argument0.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_VINEYARD] = "Argument0 Aktionskarten.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_SILK_ROAD] = "Argument0 Punktekarten.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_FAIRGROUNDS] = "Argument0 Karten mit verschiedenen Namen.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_DUKE] = "Argument0.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_DUH] = "";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_VP_TOKENS] = "Siegpunkte-Marker.";

GermanLogEntries[LogEntryNames.PUT_IN_HAND_FROM] = "Argument0 nimmt Argument1 auf die Hand (Argument2).";
GermanLogEntries[LogEntryNames.STARTS_TURN] = "Argument0 beginnt den Zug.";

GermanLogEntries[LogEntryNames.TAKE_VP_FROM] = "Argument0 nimmt Argument1 Siegpunkte-Marker von Argument2.";
GermanLogEntries[LogEntryNames.MOVE_VP_FROM_TO] = "Argument0 legt Argument1 Siegpunkte-Marker von Argument2 auf Argument3.";
GermanLogEntries[LogEntryNames.OBELISK_CHOOSES_PILE] = "Obelisk wählt Argument0.";
GermanLogEntries[LogEntryNames.PLAYER_MOVES_TOKEN_TO] = "Argument0 legt Argument1 auf Argument2.";
GermanLogEntries[LogEntryNames.PLAYER_GETS_VP] = "Argument0 bekommt Argument1 Siegpunkte-Marker.";
GermanLogEntries[LogEntryNames.PLAYER_GETS_VP_FROM] = "Argument0 bekommt Argument1 Siegpunkte-Marker von Argument2.";
GermanLogEntries[LogEntryNames.ADDS_VP_TO] = "Argument0 fügt Argument1 Siegpunkte-Marker Argument2 hinzu.";
GermanLogEntries[LogEntryNames.WAITING_FOR] = "Warte auf Argument0.";
GermanLogEntries[LogEntryNames.ALMS_FAIL] = "Argument0 kauft Almosen, hat aber Argument1 im Spiel.";
GermanLogEntries[LogEntryNames.BORROW_FAIL] = "Argument0 kauft Leihgabe, hatte aber bereits Argument1";
GermanLogEntries[LogEntryNames.QUEST_FAIL_ATTACK] = "Argument0 konnte keine Angriffskarte ablegen.";
GermanLogEntries[LogEntryNames.QUEST_FAIL_CURSES] = "Argument0 konnte keine Argument1 ablegen.";
GermanLogEntries[LogEntryNames.QUEST_FAIL_DISCARD] = "Argument0 konnte keine 6 Karten ablegen.";
GermanLogEntries[LogEntryNames.SAVE_FAIL] = "Argument0 hatte keine Handkarten zum Beiseite legen.";
GermanLogEntries[LogEntryNames.SCOUTING_PARTY_FAIL] = "Argument0 konnte keine Karten ablegen oder auf den Nachziehstapel legen.";
GermanLogEntries[LogEntryNames.BONFIRE_FAIL] = "Argument0 hat nichts entsorgt.";
GermanLogEntries[LogEntryNames.MISSION_SUCCESS] = "Argument0 erhält einen zusätzlichen Zug nach diesem Zug.";
GermanLogEntries[LogEntryNames.TRADE_FAIL] = "Argument0 hat nichts entsorgt.";
GermanLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_DOWN] = "Argument0 dreht den Argument1 mit der Vorderseite nach unten.";
GermanLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_UP] = "Argument0 dreht den Argument1 mit der Vorderseite nach oben.";

GermanLogEntries[LogEntryNames.TRIUMPH_FAIL] = "Argument0 konnte Argument1 nicht nehmen.";
GermanLogEntries[LogEntryNames.ADVANCE_FAIL] = "Argument0 konnte keine Aktionskarte entsorgen.";
GermanLogEntries[LogEntryNames.TAX_ADD_DEBT] = "Argument0 legt Argument1 auf Argument2.";
GermanLogEntries[LogEntryNames.TAX_TAKE_DEBT] = "Argument0 nimmt Argument1 von Argument2.";
GermanLogEntries[LogEntryNames.RITUAL_FAIL] = "Argument0 konnte keinen Argument1 nehmen.";
GermanLogEntries[LogEntryNames.WINDFALL_FAIL] = "Argument0 ist nicht leer.";
GermanLogEntries[LogEntryNames.DOMINATE_FAIL] = "Argument0 konnte keine Argument1 nehmen.";

GermanLogEntries[LogEntryNames.PLUS_ACTION_FROM] = "Argument0 bekommt +1 Aktion (von Argument1).";
GermanLogEntries[LogEntryNames.PLUS_BUY_FROM] = "Argument0 bekommt +1 Buy (von Argument1).";
GermanLogEntries[LogEntryNames.DRAWS_FROM] = "Argument0 zieht Argument1 (von Argument2).";
GermanLogEntries[LogEntryNames.PLUS_COIN_FROM] = "Argument0 bekommt +1 Geld (von Argument1).";
GermanLogEntries[LogEntryNames.DRAWS_LESS_BECAUSE] = "Argument0 zieht eine Karte nicht (wegen Argument1).";

GermanLogEntries[LogEntryNames.PRINCE_FAILS] = "";
GermanLogEntries[LogEntryNames.POSSESSION_TAKES_COIN_TOKENS] = "Argument0 erhält stattdessen die Taler.";
GermanLogEntries[LogEntryNames.POSSESSION_TAKES_DEBT_TOKENS] = "Argument0 erhält stattdessen die Schulden.";
GermanLogEntries[LogEntryNames.POSSESION_TAKES_VP_TOKENS] = "Argument0 erhält sattdessen die Siegpunkte-Marker.";

GermanLogEntries[LogEntryNames.TAKES_DEBT] = "Argument0 nimmt Argument1 Schulden.";
GermanLogEntries[LogEntryNames.REPAYS_DEBT] = "Argument0 bezahlt Argument1 Schulden.";
GermanLogEntries[LogEntryNames.REPAYS_SOME_DEBT] = "Argument0 bezahlt Argument1 Schulden (Argument2 verbleiben).";
GermanLogEntries[LogEntryNames.RETURNS_TOKEN] = "Argument0 legt den Argument1 von Argument2 zurück.";

GermanLogEntries[LogEntryNames.SET_ASIDE_WITH] = "Argument0 benutzt Argument2, um Argument1 beiseite zu legen.";
GermanLogEntries[LogEntryNames.BLOCKS_WITH] = "Argument0 benutzt Argument1 zum Blocken.";
GermanLogEntries[LogEntryNames.NAMES] = "Argument0 nennt Argument1.";
GermanLogEntries[LogEntryNames.GAIN_ON_DRAWPILE] = "Argument0 nimmt Argument1 auf den Nachziehstapel.";
GermanLogEntries[LogEntryNames.GAIN_FROM_TRASH] = "Argument0 nimmt Argument1 vom Müllstapel.";
GermanLogEntries[LogEntryNames.OBELISK_FAILS] = "Obelisk konnte keinen Aktionsstapel wählen.";
GermanLogEntries[LogEntryNames.INHERITS] = "Argument0 benutzt Erbschaft auf Argument1.";
GermanLogEntries[LogEntryNames.GAME_META_INFO] = "Argument0";

GermanLogEntries[LogEntryNames.THE_SKYS_GIFT_FAIL] = "Argument0 kann nicht ablegen für [The Skys Gift]";
GermanLogEntries[LogEntryNames.RECEIVES] = "Argument0 empfängt Argument1.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_PASTURE] = "Argument0.";
GermanLogEntries[LogEntryNames.TAKE] = "Argument0 erhält Argument1.";
GermanLogEntries[LogEntryNames.RETURN] = "Argument0 legt Argument1 zurück.";
GermanLogEntries[LogEntryNames.THE_SUNS_GIFT_FAIL] = "[The Suns Gift] hat nichts zum Ablegen.";
GermanLogEntries[LogEntryNames.DRUID_SELECTS_BOONS] = "[Druid] legt Argument1 beiseite.";
GermanLogEntries[LogEntryNames.DISCARD_RECEIVABLE] = "Argument0 legt Argument1 ab.";
GermanLogEntries[LogEntryNames.PLAY_ENCHANTED] = "Argument0 wird von Argument1 verzaubert.";

GermanLogEntries[LogEntryNames.GAIN_ANOTHER_EXPERIMENT] = "Argument0 nimmt ein weiteres [Experiment].";
GermanLogEntries[LogEntryNames.COFFERS_FROM_PATRON] = "Argument0 erhält +1 Taler vom [Patron].";
GermanLogEntries[LogEntryNames.GETS_COFFER] = "Argument0 erhält +Argument1 Taler.";
GermanLogEntries[LogEntryNames.GETS_COFFERS] = "Argument0 erhält +Argument1 Taler.";
GermanLogEntries[LogEntryNames.GETS_COFFER_FROM] = "Argument0 erhält +Argument1 Taler. (Argument2)";
GermanLogEntries[LogEntryNames.GETS_COFFERS_FROM] = "Argument0 erhält +Argument1 Taler. (Argument2)";
GermanLogEntries[LogEntryNames.USES_COFFER] = "Argument0 gibt Argument1 Taler aus. (+Argument1 Geld)";
GermanLogEntries[LogEntryNames.USES_COFFERS] = "Argument0 gibt Argument1 Taler aus. (+Argument1 Geld)";
GermanLogEntries[LogEntryNames.GETS_VILLAGER] = "Argument0 erhält +Argument1 Dorfbewohner.";
GermanLogEntries[LogEntryNames.GETS_VILLAGERS] = "Argument0 erhält +Argument1 Dorfbewohner.";
GermanLogEntries[LogEntryNames.GETS_VILLAGER_FROM] = "Argument0 erhält +Argument1 Dorfbewohner. (Argument2)";
GermanLogEntries[LogEntryNames.GETS_VILLAGERS_FROM] = "Argument0 erhält +Argument1 Dorfbewohner. (Argument2)";
GermanLogEntries[LogEntryNames.USES_VILLAGER] = "Argument0 setzt Argument1 Dorfbewohner ein.";
GermanLogEntries[LogEntryNames.USES_VILLAGERS] = "Argument0 setzt Argument1 Dorfbewohner ein.";
GermanLogEntries[LogEntryNames.BEGINS_BUYPHASE] = "Argument0 beginnt die Kaufphase."; // please translate
GermanLogEntries[LogEntryNames.ENDS_BUYPHASE] = "Argument0 beendet die Kaufphase.";
GermanLogEntries[LogEntryNames.GETS_ACTION] = "Argument0 erhält +Argument1 Aktion.";
GermanLogEntries[LogEntryNames.GETS_ACTIONS] = "Argument0 erhält +Argument1 Aktionen.";
GermanLogEntries[LogEntryNames.GETS_ACTION_FROM] = "Argument0 erhält +Argument1 Aktion. (Argument2)";
GermanLogEntries[LogEntryNames.GETS_ACTIONS_FROM] = "Argument0 erhält +Argument1 Aktionen. (Argument2)";
GermanLogEntries[LogEntryNames.GETS_BUY] = "Argument0 erhält +Argument1 Kauf.";
GermanLogEntries[LogEntryNames.GETS_BUYS] = "Argument0 erhält +Argument1 Käufe.";
GermanLogEntries[LogEntryNames.GETS_BUY_FROM] = "Argument0 erhält +Argument1 Kauf. (Argument2)";
GermanLogEntries[LogEntryNames.GETS_BUYS_FROM] = "Argument0 erhält +Argument1 Käufe. (Argument2)";
GermanLogEntries[LogEntryNames.GETS_COIN] = "Argument0 erhält +Argument1 Geld.";
GermanLogEntries[LogEntryNames.GETS_COINS] = "Argument0 erhält +Argument1 Geld.";
GermanLogEntries[LogEntryNames.GETS_COIN_FROM] = "Argument0 erhält +Argument1 Geld. (Argument2)";
GermanLogEntries[LogEntryNames.GETS_COINS_FROM] = "Argument0 erhält +Argument1 Geld. (Argument2)";
GermanLogEntries[LogEntryNames.LOSES_COIN] = "Argument0 verliert Argument1 Geld.";
GermanLogEntries[LogEntryNames.LOSES_COINS] = "Argument0 verliert Argument1 Geld.";
GermanLogEntries[LogEntryNames.BOTTOMDECKS] = "Argument0 legt Argument1 im Nachziehstapel nach ganz unten.";
GermanLogEntries[LogEntryNames.TRASH_WITH] = "Argument0 entsorgt Argument1. (Argument2)";
GermanLogEntries[LogEntryNames.PRIEST_ACTIVATES] = "ab jetzt beim Entsorgen: +Argument0 Geld.";
GermanLogEntries[LogEntryNames.PLAY_TREASURES_FOR] = "Argument0 spielt Argument1. (+Argument2 Geld)";
GermanLogEntries[LogEntryNames.PLAY_TREASURES_AGAIN_FOR] = "Argument0 spielt Argument1 erneut. (+Argument2 Geld)";
GermanLogEntries[LogEntryNames.PLAY_TREASURES_THIRD_FOR] = GermanLogEntries[LogEntryNames.PLAY_THIRD] + " (+Argument2 Geld)";
GermanLogEntries[LogEntryNames.RETURNS_MINUS_COIN_TOKEN] = "Argument0 legt den Argument1 von Argument2 zurück. (-1 Geld)";
GermanLogEntries[LogEntryNames.NOT_IN_PLAY_PREVENTED_PLAYING] = "Argument0 war nicht im Spiel und konnte nicht gespielt werden.";
GermanLogEntries[LogEntryNames.PILLAGE_FAIL] = "[Pillage] wurde nicht entsorgt und bewirkt deshalb nichts.";
GermanLogEntries[LogEntryNames.DEATH_CART_FAIL] = "[Death Cart] hat nichts entsorgt und bewirkt deshalb nichts.";
GermanLogEntries[LogEntryNames.TACTICIAN_FAIL] = "[Tactician] hat nichts abgeworfen und bewirkt deshalb nichts.";
GermanLogEntries[LogEntryNames.EMBARGO_FAIL] = "[Embargo] wurde nicht entsorgt und bewirkt deshalb nichts.";
GermanLogEntries[LogEntryNames.EXILE] = "Argument0 legt Argument1 ins Exil.";
GermanLogEntries[LogEntryNames.DISCARD_FROM_EXILE] = "Argument0 legt Argument1 aus dem Exil ab.";
GermanLogEntries[LogEntryNames.DISCARD_REVEALED] = "Argument0 legt aufgedeckt Argument1 ab.";
GermanLogEntries[LogEntryNames.PLAY_WITH] = GermanLogEntries[LogEntryNames.PLAY] + " (Argument2)";
GermanLogEntries[LogEntryNames.PLAY_AGAIN_WITH] = GermanLogEntries[LogEntryNames.PLAY] + " (Argument2)";
GermanLogEntries[LogEntryNames.PLAY_THIRD_WITH] = GermanLogEntries[LogEntryNames.PLAY] + " (Argument2)";
GermanLogEntries[LogEntryNames.IS_INVESTED] = "Argument0 zieht Argument2 Karten, wenn ein Gegner Argument1 nimmt oder darin investiert.";
GermanLogEntries[LogEntryNames.COMMERCE_FAIL] = "Argument0 hat in diesem Zug noch keine Karten genommen.";
GermanLogEntries[LogEntryNames.STAMPEDE_FAIL] = "Argument0 kaufte [Stampede] mit Argument1 Karten im Spiel.";
GermanLogEntries[LogEntryNames.PLAY_USING_WAY] = "Argument0 spielt Argument1 mittels Argument2.";
GermanLogEntries[LogEntryNames.WAY_OF_THE_HORSE_FAILS_NO_PILE] = "Argument0 hat keinen Stapel zum Zurücklegen.";
GermanLogEntries[LogEntryNames.WAY_OF_THE_HORSE_FAILS_NOT_IN_PLAY] = "[Way Of The Horse] kann Argument0 nicht zurücklegen, weil die Karte nicht im Spiel ist.";
GermanLogEntries[LogEntryNames.WAY_OF_THE_TURTLE_FAILS_TO_SET_ASIDE] = "[Way Of The Turtle] kann Argument0 nicht beiseite legen.";
GermanLogEntries[LogEntryNames.USES_WAY] = "Argument0 benutzt Argument1.";
GermanLogEntries[LogEntryNames.WOULD_GET_COIN] = "Argument0 würde +$Argument1 bekommen.";
GermanLogEntries[LogEntryNames.WOULD_DRAW] = "Argument0 würde Argument1 ziehen.";
GermanLogEntries[LogEntryNames.TOPDECK_WITH] = "Argument0 legt Argument1 mittels Argument2 auf den Nachziehstapel.";
GermanLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_NO_PILE] = "Argument0 kann auf keinen Stapel zurückgelegt werden.";
GermanLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_NOT_IN_PLAY] = "[Way Of The Butterfly] kann Argument0 nicht bewegen, weil die Karte nicht im Spiel ist.";
GermanLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_INTENTIONAL] = "Argument0 hat Argument1 nicht auf den Stapel zurückgelegt.";
GermanLogEntries[LogEntryNames.PLAY_NULLIFIED] = "Argument0 spielt Argument1 (tut nichts wegen [Highwayman]).";
GermanLogEntries[LogEntryNames.GETS_FAVOR] = "Argument0 erhält +Argument1 Gefallen.";
GermanLogEntries[LogEntryNames.GETS_FAVORS] = "Argument0 erhält +Argument1 Gefallen.";
GermanLogEntries[LogEntryNames.GETS_FAVOR_FROM] = "Argument0 erhält +Argument1 Gefallen. (Argument2)";
GermanLogEntries[LogEntryNames.GETS_FAVORS_FROM] = "Argument0 erhält +Argument1 Gefallen. (Argument2)";
GermanLogEntries[LogEntryNames.SPENDS_FAVOR] = "Argument0 löst Argument1 Gefallen ein.";
GermanLogEntries[LogEntryNames.SPENDS_FAVORS] = "Argument0 löst Argument1 Gefallen ein.";
GermanLogEntries[LogEntryNames.ROTATES_EMPTY_PILE] = "Argument0 rotiert einen leeren Stapel.";
GermanLogEntries[LogEntryNames.ROTATES_IDENTICAL_PILE] = "Argument0 rotiert einen Stapel mit gleichen Karten.";
GermanLogEntries[LogEntryNames.ROTATES] = "Argument0 rotiert Argument1.";
GermanLogEntries[LogEntryNames.ISLAND_FOLK] = "Argument0 erhält einen zusätzlichen Zug nach diesem Zug.";
GermanLogEntries[LogEntryNames.ROYAL_GALLEY_FAIL] = "[Royal Galley] kann die Karte nicht zur Seite legen.";
GermanLogEntries[LogEntryNames.FAILS_TO_PLAY] = "Argument0 kann Argument1 nicht spielen.";
GermanLogEntries[LogEntryNames.FAILS_TO_PLAY_WRONG_LOCATION] = "Argument0 kann Argument1 nicht spielen, weil die Karte nicht im Argument2 ist.";
GermanLogEntries[LogEntryNames.STRANDS_CARDS] = "Argument0 lässt Argument1 gestrandet zurück.";
GermanLogEntries[LogEntryNames.VOYAGE_SUCCESS] = "Argument0 erhält einen zusätzlichen Zug nach diesem Zug.";
GermanLogEntries[LogEntryNames.GARRISON_ADDS_TOKEN] = "Argument0 legt einen Marker auf [Garrison] (hat nun Argument1).";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_PLATEAU_SHEPHERDS] = "Argument0 Karten für 2 Geld, Argument1 Gefallen.";
GermanLogEntries[LogEntryNames.PUT_IN_DISCARD] = "Argument0 legt Argument1 auf den Ablagestapel.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_TERRITORY] = "Argument0 Punktekarten mit unterschiedlichen Namen.";
GermanLogEntries[LogEntryNames.SORCERESS_GUESS] = "Argument0 nennt Argument1.";
GermanLogEntries[LogEntryNames.SORCERER_GUESS] = "Argument0 nennt Argument1.";
GermanLogEntries[LogEntryNames.SKIPS_A_TURN_REMAINING] = "Argument0 setzt einen Zug aus (dann noch Argument1).";
GermanLogEntries[LogEntryNames.SKIPS_A_TURN] = "Argument0 setzt einen Zug aus.";
GermanLogEntries[LogEntryNames.CANCELS_A_TURN] = "Für Argument0 fällt der Extra-Zug von Argument1 aus.";
GermanLogEntries[LogEntryNames.WILL_SKIP_A_TURN] = "Argument0 wird einen Zug aussetzen.";
GermanLogEntries[LogEntryNames.WILL_SKIP_A_TURN_COUNT] = "Argument0 wird einen Zug aussetzen (insgesamt Argument1).";
GermanLogEntries[LogEntryNames.KEEPS_IN_HAND] = "Argument0 behält Argument1 auf der Hand.";
GermanLogEntries[LogEntryNames.INVESTMENT_FAIL] = "[Investment] ist nicht im Spiel und kann sich deshalb nicht selbst entsorgen.";
GermanLogEntries[LogEntryNames.GAIN_SETTING_ASIDE] = "Argument0 nimmt Argument1, beiseite gelegt.";
GermanLogEntries[LogEntryNames.PLAYER_BIDS] = "Argument0 bietet Argument1 Schulden";
GermanLogEntries[LogEntryNames.PLAYER_ADDS_FAVOR_TOKEN_TO] = "Argument0 legt einen Gefallen auf Argument1. ([Family of Inventors])";
GermanLogEntries[LogEntryNames.BEGINS_CLEANUP_PHASE] = "Argument0 beginnt die Aufräumphase.";
GermanLogEntries[LogEntryNames.PREMOVES] = "Argument0 vor-klickt Argument1.";
GermanLogEntries[LogEntryNames.SPELL_SCROLL_FAILS] = "[Spell Scroll] wurde nicht entsorgt, mehr passiert nicht.";
GermanLogEntries[LogEntryNames.PERIL_FAIL] = "[Peril] hat nichts entsorgt, mehr passiert nicht.";
GermanLogEntries[LogEntryNames.ACQUIRES_TRAIT] = "Das Merkmal Argument1 wird auf Argument0 angewandt.";
GermanLogEntries[LogEntryNames.TRAIT_FAILS] = "Argument0 konnte keinen Aktions- oder Geldstapel finden.";
GermanLogEntries[LogEntryNames.SHUFFLES_CARDS] = "Argument0 mischt Argument1.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_MARCHLAND] = "Argument0 Punktekarten.";

GermanLogEntries[LogEntryNames.FARMHANDS_FAIL] = "[Farmhands] konnte keine Karte zur Seite legen.";
GermanLogEntries[LogEntryNames.FARMHANDS_REFUSE] = "[Farmhands] hat keine Karte zur Seite gelegt.";
GermanLogEntries[LogEntryNames.EXPLAIN_POINTS_DEMESNE] = "Argument0 Gold.";
GermanLogEntries[LogEntryNames.REACTS_PLAYING] = "Argument0 spielt Argument1 als Reaktion.";
GermanLogEntries[LogEntryNames.PLAY_ENLIGHTENED] = "Argument1 spielt Argument0 als +1 Karte, +1 Aktion"; // Arguments should be exchanged, IMO
GermanLogEntries[LogEntryNames.PANIC_FAILS] = "[Panic] kann Argument0 nicht zurücklegen.";
GermanLogEntries[LogEntryNames.PANIC_FAILS_NO_PILE] = "[Panic] kann Argument0 nicht zurücklegen (kein Stapel).";
GermanLogEntries[LogEntryNames.AMASS_FAILS] = "Argument0 kauft [Amass], hat aber Argument1 im Spiel.";