"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var BackgroundLayer = function (_StackViewLayer) {
    _inherits(BackgroundLayer, _StackViewLayer);

    function BackgroundLayer(rootNode, cardStack) {
        _classCallCheck(this, BackgroundLayer);

        var _this = _possibleConstructorReturn(this, (BackgroundLayer.__proto__ || Object.getPrototypeOf(BackgroundLayer)).call(this, rootNode, cardStack));

        _this.node.style.zIndex = 0;
        _this.node.classList.add("background-layer");
        return _this;
    }

    _createClass(BackgroundLayer, [{
        key: "redraw",
        value: function redraw(w, h) {
            var r = Math.max(w, h) * 0.04;
            var m = r / 8;
            this.node.style.borderRadius = r + "px";
            this.node.style.left = m + "px";
            this.node.style.top = m + "px";
            this.node.style.width = w - 2 * m + "px";
            this.node.style.height = h - 2 * m + "px";
        }
    }]);

    return BackgroundLayer;
}(StackViewLayer);