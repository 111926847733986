"use strict";

var TChineseAbilityDescriptions = {};

TChineseAbilityDescriptions[AbilityNames.BUY] = "購買";
TChineseAbilityDescriptions[AbilityNames.PLAY_ATTACK] = "打出";
TChineseAbilityDescriptions[AbilityNames.DRAW_INSTRUCTIONS] = "抽牌動作 Argument0";
TChineseAbilityDescriptions[AbilityNames.PLAY_INSTRUCTIONS] = "打出動作";
TChineseAbilityDescriptions[AbilityNames.RESOLVE_EVENT] = "解決事件";
TChineseAbilityDescriptions[AbilityNames.GAIN_BY_NAME] = "獲得 Argument0 (Argument1)";
TChineseAbilityDescriptions[AbilityNames.FISHING_VILLAGE_ABILITY] = "+1 行動 (漁村)";
TChineseAbilityDescriptions[AbilityNames.REMOVE_COST_REDUCTION] = "REMOVE_COST_REDUCTION";
TChineseAbilityDescriptions[AbilityNames.HAVEN_ABILITY] = "港口";
TChineseAbilityDescriptions[AbilityNames.SAVE_RETURN_TO_HAND] = "儲蓄";
TChineseAbilityDescriptions[AbilityNames.CARAVAN_ABILITY] = "商隊";
TChineseAbilityDescriptions[AbilityNames.MERCHANT_SHIP_ABILITY] = "商船";
TChineseAbilityDescriptions[AbilityNames.OUTPOST_ABILITY] = "前哨";
TChineseAbilityDescriptions[AbilityNames.TACTICIAN_ABILITY] = "策士";
TChineseAbilityDescriptions[AbilityNames.WHARF_ABILITY] = "+2 卡片, +1 購買 (碼頭)";
TChineseAbilityDescriptions[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = "馬商";
TChineseAbilityDescriptions[AbilityNames.DUCHESS_MAY_GAIN] = "女公爵";
TChineseAbilityDescriptions[AbilityNames.FOOLS_GOLD_TRASH] = "愚人金";
TChineseAbilityDescriptions[AbilityNames.NOBLE_BRIGAND] = "義賊";
TChineseAbilityDescriptions[AbilityNames.HAGGLER_ABILITY] = "奧客";
TChineseAbilityDescriptions[AbilityNames.INN_ABILITY] = "旅館";
TChineseAbilityDescriptions[AbilityNames.MANDARIN] = "官吏";
TChineseAbilityDescriptions[AbilityNames.FARMLAND] = "農地";
TChineseAbilityDescriptions[AbilityNames.GAIN_CHEAPER] = "獲得一張較便宜的卡片";
TChineseAbilityDescriptions[AbilityNames.GAIN_TWO_RUINS] = "獲得兩張廢墟";
TChineseAbilityDescriptions[AbilityNames.HUNTING_GROUNDS] = "狩獵場";
TChineseAbilityDescriptions[AbilityNames.SQUIRE_GAIN_ATTACK] = "獲得一張攻擊卡";
TChineseAbilityDescriptions[AbilityNames.URCHIN_TRANSFORM] = "可以移除出牌區的1張貧童";
TChineseAbilityDescriptions[AbilityNames.FEODUM_GAIN_SILVERS] = "獲得三張銀幣";
TChineseAbilityDescriptions[AbilityNames.DOCTOR_OVERPAY] = "對大夫超付預算";
TChineseAbilityDescriptions[AbilityNames.RESOLVE_DOCTOR_OVERPAY] = "trash or discard cards from your deck";
TChineseAbilityDescriptions[AbilityNames.HERALD_OVERPAY] = "對傳令官超付預算";
TChineseAbilityDescriptions[AbilityNames.RESOLVE_HERALD_OVERPAY] = "topdeck cards from your discard";
TChineseAbilityDescriptions[AbilityNames.MASTERPIECE_OVERPAY] = "對傑作超付預算";
TChineseAbilityDescriptions[AbilityNames.RESOLVE_MASTERPIECE_OVERPAY] = "gain [^pSilver]";
TChineseAbilityDescriptions[AbilityNames.STONEMASON_OVERPAY] = "對石匠超付預算";
TChineseAbilityDescriptions[AbilityNames.RESOLVE_STONEMASON_OVERPAY] = "gain 2 Action cards";
TChineseAbilityDescriptions[AbilityNames.EXCHANGE] = "交換";
TChineseAbilityDescriptions[AbilityNames.AMULET] = "護身符";
TChineseAbilityDescriptions[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = "護衛";
TChineseAbilityDescriptions[AbilityNames.DUNGEON_ABILITY] = "地牢";
TChineseAbilityDescriptions[AbilityNames.GEAR_ABILITY] = "工具";
TChineseAbilityDescriptions[AbilityNames.MESSENGER_GAIN] = "信使";
TChineseAbilityDescriptions[AbilityNames.BRIDGE_TROLL_ABILITY] = "橋下巨人";
TChineseAbilityDescriptions[AbilityNames.HAUNTED_WOODS_DURATION] = "恐懼叢林的抽卡";
TChineseAbilityDescriptions[AbilityNames.HAUNTED_WOODS_TOPDECK] = "恐懼叢林的放回牌庫頂";
TChineseAbilityDescriptions[AbilityNames.LOST_CITY] = "失落之城";
TChineseAbilityDescriptions[AbilityNames.WINE_MERCHANT_DISCARD] = "棄掉 酒商";
TChineseAbilityDescriptions[AbilityNames.HIRELING] = "雇傭";
TChineseAbilityDescriptions[AbilityNames.SWAMP_HAG_DURATION] = "沼澤女巫";
TChineseAbilityDescriptions[AbilityNames.TRASH_TOKEN_ABILITY] = "移除標記";
TChineseAbilityDescriptions[AbilityNames.EMPORIUM_GAIN_POINTS] = "獲得商場的分數";
TChineseAbilityDescriptions[AbilityNames.ROCKS_GAINS_SILVER] = "獲得一張銀幣";
TChineseAbilityDescriptions[AbilityNames.FORTUNE] = "大祕寶";
TChineseAbilityDescriptions[AbilityNames.CRUMBLING_CASTLE] = "崩塌的城堡";
TChineseAbilityDescriptions[AbilityNames.HAUNTED_CASTLE] = "惡靈的城堡";
TChineseAbilityDescriptions[AbilityNames.SPRAWLING_CASTLE] = "廣闊的城堡";
TChineseAbilityDescriptions[AbilityNames.GRAND_CASTLE] = "雄偉的城堡";
TChineseAbilityDescriptions[AbilityNames.ENCHANTRESS_ABILITY] = "+2 卡片";
TChineseAbilityDescriptions[AbilityNames.ENCHANTRESS_CANTRIP] = "+1 卡片, +1 行動";
TChineseAbilityDescriptions[AbilityNames.TEMPLE] = "寺廟";
TChineseAbilityDescriptions[AbilityNames.VILLA] = "別墅";
TChineseAbilityDescriptions[AbilityNames.ARCHIVE_ABILITY] = "檔案室 (Argument0).";
TChineseAbilityDescriptions[AbilityNames.CAPITAL_TAKE_DEBT] = "融資";
TChineseAbilityDescriptions[AbilityNames.CHARM] = "幸運物";
TChineseAbilityDescriptions[AbilityNames.FORUM] = "羅馬廣場";
TChineseAbilityDescriptions[AbilityNames.HERMIT] = "隱士";
TChineseAbilityDescriptions[AbilityNames.DONATE] = "捐獻";
TChineseAbilityDescriptions[AbilityNames.TAX_RECEIVE] = "徵稅";
TChineseAbilityDescriptions[AbilityNames.DOMINATE] = "主宰";
TChineseAbilityDescriptions[AbilityNames.VP_TO_AQUEDUCT] = "將分數放到引水渠";
TChineseAbilityDescriptions[AbilityNames.VP_FROM_AQUEDUCT] = "得到引水渠上面的分數";
TChineseAbilityDescriptions[AbilityNames.ARENA_DISCARD_ACTION] = "競技場";
TChineseAbilityDescriptions[AbilityNames.VP_FROM_BASILICA] = "殿堂";
TChineseAbilityDescriptions[AbilityNames.VP_FROM_BATHS] = "浴場";
TChineseAbilityDescriptions[AbilityNames.VP_FROM_BATTLEFIELD] = "戰場";
TChineseAbilityDescriptions[AbilityNames.VP_FROM_COLONNADE] = "列柱";
TChineseAbilityDescriptions[AbilityNames.VP_TO_DEFILED_SHRINE] = "將分數放到髒亂的寺廟";
TChineseAbilityDescriptions[AbilityNames.VP_FROM_DEFILED_SHRINE] = "得到髒亂的寺廟上面分數";
TChineseAbilityDescriptions[AbilityNames.VP_FROM_LABYRINTH] = "迷宮";
TChineseAbilityDescriptions[AbilityNames.MOUNTAIN_PASS_BIDDING] = "山間小路";
TChineseAbilityDescriptions[AbilityNames.TOMB] = "墓碑";
TChineseAbilityDescriptions[AbilityNames.LIGHTHOUSE_COIN] = "燈塔";
TChineseAbilityDescriptions[AbilityNames.REACTION] = "應對";
TChineseAbilityDescriptions[AbilityNames.CALL_GUIDE] = "呼叫 導遊";
TChineseAbilityDescriptions[AbilityNames.CALL_RATCATCHER] = "呼叫 捕鼠人";
TChineseAbilityDescriptions[AbilityNames.CALL_TRANSMOGRIFY] = "呼叫 變形術";
TChineseAbilityDescriptions[AbilityNames.CALL_TEACHER] = "呼叫 師傅";
TChineseAbilityDescriptions[AbilityNames.CALL_DUPLICATE] = "呼叫 複製";
TChineseAbilityDescriptions[AbilityNames.CALL_COIN] = "呼叫 國幣";
TChineseAbilityDescriptions[AbilityNames.CALL_ROYAL_CARRIAGE] = "呼叫 皇家馬車";
TChineseAbilityDescriptions[AbilityNames.START_OF_TURN] = "回合開始";
TChineseAbilityDescriptions[AbilityNames.START_BUY_PHASE] = "購買階段：";
TChineseAbilityDescriptions[AbilityNames.END_OF_BUY_PHASE] = "購買階段結束";
TChineseAbilityDescriptions[AbilityNames.BETWEEN_TURNS] = "回合之間";
TChineseAbilityDescriptions[AbilityNames.END_OF_TURN] = "回合結束";
TChineseAbilityDescriptions[AbilityNames.SUMMON_PLAY] = "召喚 Argument0.";
TChineseAbilityDescriptions[AbilityNames.CARAVAN_GUARD_REACTION] = "可以展示護衛.";
TChineseAbilityDescriptions[AbilityNames.TRAVELLING_FAIR_REACTION] = "遊行";
TChineseAbilityDescriptions[AbilityNames.MOAT_REACTION] = "可以展示 護城河.";
TChineseAbilityDescriptions[AbilityNames.HORSE_TRADERS_REACTION] = "可以展示 馬商.";
TChineseAbilityDescriptions[AbilityNames.BEGGAR_REACTION] = "可以展示 乞丐.";
TChineseAbilityDescriptions[AbilityNames.MARKET_SQUARE_REACTION] = "可以展示 市集廣場.";
TChineseAbilityDescriptions[AbilityNames.TRADER_REACTION] = "可以展示 路邊攤.";
TChineseAbilityDescriptions[AbilityNames.DIPLOMAT_REACTION] = "外交官";
TChineseAbilityDescriptions[AbilityNames.TOPDECK_REACTION] = "可以放置到牌庫頂";
TChineseAbilityDescriptions[AbilityNames.WATCHTOWER_TRASH_REACTION] = "可以展示 瞭望台 來移除它";
TChineseAbilityDescriptions[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "可以展示 瞭望台來將他放到牌庫頂";
TChineseAbilityDescriptions[AbilityNames.PRINCE_PLAY] = "用王子的效果打出 Argument0 .";
TChineseAbilityDescriptions[AbilityNames.TUNNEL_REVEAL] = "展示坑道.";
TChineseAbilityDescriptions[AbilityNames.POSSESSION_GAIN_INSTEAD] = "改由Argument0獲得Argument2，而不是Argument1獲得.";
TChineseAbilityDescriptions[AbilityNames.HOVEL_TRASH] = "移除 茅屋.";
TChineseAbilityDescriptions[AbilityNames.DELUDED_RETURNED] = "歸還 幻覺";
TChineseAbilityDescriptions[AbilityNames.ENVIOUS_RETURNED] = "歸還 嫉妒";
TChineseAbilityDescriptions[AbilityNames.RECEIVE_BOON] = "收到 Argument0";
TChineseAbilityDescriptions[AbilityNames.COBBLER_ABILITY] = "獲得一張卡片";
TChineseAbilityDescriptions[AbilityNames.CRYPT_ABILITY] = "地下墓室 (Argument0).";
TChineseAbilityDescriptions[AbilityNames.DEN_OF_SIN_ABILITY] = "+2 卡片 (風化區)";
TChineseAbilityDescriptions[AbilityNames.FAITHFUL_HOUND] = "忠犬";
TChineseAbilityDescriptions[AbilityNames.FAITHFUL_HOUND_RETURN] = "忠犬";
TChineseAbilityDescriptions[AbilityNames.GHOST_TOWN_ABILITY] = "+1 卡片, +1 行動 (鬼城)";
TChineseAbilityDescriptions[AbilityNames.GUARDIAN_COIN] = "+1 預算 (守衛)";
TChineseAbilityDescriptions[AbilityNames.RAIDER_ABILITY] = "+3 預算 (入侵者)";
TChineseAbilityDescriptions[AbilityNames.SECRET_CAVE] = "+3 預算 (秘密洞穴)";
TChineseAbilityDescriptions[AbilityNames.CEMETERY] = "移除至多四卡片";
TChineseAbilityDescriptions[AbilityNames.HAUNTED_MIRROR] = "鬼入鏡";
TChineseAbilityDescriptions[AbilityNames.GHOST] = "鬼魂Argument0.";
TChineseAbilityDescriptions[AbilityNames.REMOVE_FLIPPED_STATUS] = "X";
TChineseAbilityDescriptions[AbilityNames.LOST_IN_THE_WOODS] = "你可以棄一張卡片來收到一個祝福";
TChineseAbilityDescriptions[AbilityNames.BLESSED_VILLAGE] = "收到一個祝福";
TChineseAbilityDescriptions[AbilityNames.FORTRESS] = "將要塞放到手中";
TChineseAbilityDescriptions[AbilityNames.RECEIVE_HEX] = "收到咒術";
TChineseAbilityDescriptions[AbilityNames.CHANGELING_MAY_EXCHANGE] = "用 Argument0 交換調換兒";
TChineseAbilityDescriptions[AbilityNames.TRADE_ROUTE_MOVE_TOKEN] = "將商路的標誌移除，商路效果將多提供一預算";
TChineseAbilityDescriptions[AbilityNames.DUCAT] = "可以移除手上一張1張銅幣";
TChineseAbilityDescriptions[AbilityNames.CARGO_SHIP_SET_ASIDE] = "將 Argument0 放到一邊 (貨船)";
TChineseAbilityDescriptions[AbilityNames.CARGO_SHIP_RETURN] = "將 Argument0 放回手中 (貨船)";
TChineseAbilityDescriptions[AbilityNames.IMPROVE] = "改良出牌區的一張行動卡";
TChineseAbilityDescriptions[AbilityNames.PRIEST] = "+$2 (祭司)";
TChineseAbilityDescriptions[AbilityNames.RESEARCH] = "將 Argument0 放到你手中 (研究)";
TChineseAbilityDescriptions[AbilityNames.SILK_MERCHANT] = "+1 存款, +1 村民 (布商)";
TChineseAbilityDescriptions[AbilityNames.LACKEYS] = "+2 村民 (	奴才)";
TChineseAbilityDescriptions[AbilityNames.SPICES] = "+2 存款 (香料)";
TChineseAbilityDescriptions[AbilityNames.KEY] = "+$1 (金鑰)";
TChineseAbilityDescriptions[AbilityNames.TREASURE_CHEST] = "獲得一張黃金(寶箱)";
TChineseAbilityDescriptions[AbilityNames.FLAG_BEARER] = "拿取旗幟";
TChineseAbilityDescriptions[AbilityNames.CATHEDRAL] = "移除一張手牌(大教堂)";
TChineseAbilityDescriptions[AbilityNames.CITY_GATE] = "+1 卡片, 然後將一張卡放到牌庫頂 (城門)";
TChineseAbilityDescriptions[AbilityNames.PAGEANT] = "將剩餘的 $1 變成 +1 存款(歷史劇)";
TChineseAbilityDescriptions[AbilityNames.SEWERS_MAY_TRASH] = "移除另一張卡片(下水道)";
TChineseAbilityDescriptions[AbilityNames.EXPLORATION] = "+1 存款, +1 村民 (探索)";
TChineseAbilityDescriptions[AbilityNames.FAIR] = "+1 購買 (小市場)";
TChineseAbilityDescriptions[AbilityNames.SILOS] = "棄掉幾張銅幣就抽幾張卡片 (筒倉)";
TChineseAbilityDescriptions[AbilityNames.ACADEMY] = "+1 村民 (學院)";
TChineseAbilityDescriptions[AbilityNames.GUILDHALL] = "+1 存款(市政廳)";
TChineseAbilityDescriptions[AbilityNames.PIAZZA] = "先展示牌庫頂的一張卡，如果是行動卡就打出(聖馬可廣場)";
TChineseAbilityDescriptions[AbilityNames.BARRACKS] = "+1 行動(兵營)";
TChineseAbilityDescriptions[AbilityNames.CROP_ROTATION] = "可以棄掉(輪耕)";
TChineseAbilityDescriptions[AbilityNames.INNOVATION] = "放到一邊並打出 (創新)";
TChineseAbilityDescriptions[AbilityNames.CITADEL] = "再發動一次效果 (堡壘)";
TChineseAbilityDescriptions[AbilityNames.SINISTER_PLOT] = "先決定要抽卡或增加指示物 (惡毒手段)";
TChineseAbilityDescriptions[AbilityNames.ENCAMPMENT_RETURNED] = "歸還 [Encampment]到供應區";
TChineseAbilityDescriptions[AbilityNames.CAPTAIN] = "從供應區發動一張行動卡 (船長)";
TChineseAbilityDescriptions[AbilityNames.CHURCH] = "將 Argument0 加入手中，你可以移除一張手牌 (教會)";
TChineseAbilityDescriptions[AbilityNames.POSSESSION_SETS_ASIDE] = "將 Argument0 放到一邊";
TChineseAbilityDescriptions[AbilityNames.MINT] = "移除你出牌區全部的錢幣卡";
TChineseAbilityDescriptions[AbilityNames.SLEIGH_TOPDECK_REACTION] = "棄掉 [Sleigh] 來將 Argument0 放到牌庫頂";
TChineseAbilityDescriptions[AbilityNames.SLEIGH_PUT_IN_HAND_REACTION] = "棄掉 [Sleigh] 來將 Argument0 加入手中";
TChineseAbilityDescriptions[AbilityNames.BLACK_CAT_REACTION] = "打出 [Black Cat]";
TChineseAbilityDescriptions[AbilityNames.DISCARD_FROM_EXILE] = "棄掉一張被你驅逐的 Argument0 ";
TChineseAbilityDescriptions[AbilityNames.EXILE_BY_NAME] = "驅逐 Argument0";
TChineseAbilityDescriptions[AbilityNames.SHEEPDOG_REACTION] = "打出 [Sheepdog]";
TChineseAbilityDescriptions[AbilityNames.CAVALRY] = "+2 卡片, +1 購買 & 回到出牌階段。";
TChineseAbilityDescriptions[AbilityNames.HOSTELRY] = "每棄一張錢幣卡來獲得一張馬";
TChineseAbilityDescriptions[AbilityNames.VILLAGE_GREEN_VILLAGE] = "+1 卡片, +2 行動";
TChineseAbilityDescriptions[AbilityNames.VILLAGE_GREEN_PLAY] = "打出 [Village Green]";
TChineseAbilityDescriptions[AbilityNames.BARGE] = "+3 卡片, +1 購買";
TChineseAbilityDescriptions[AbilityNames.FALCONER_REACTION] = "獲得一張較便宜的卡片";
TChineseAbilityDescriptions[AbilityNames.GATEKEEPER_EXILE] = "驅逐 Argument0";
TChineseAbilityDescriptions[AbilityNames.GATEKEEPER_DURATION] = "+3 預算";
TChineseAbilityDescriptions[AbilityNames.LIVERY] = "獲得一張馬";
TChineseAbilityDescriptions[AbilityNames.MASTERMIND] = "妳可以打出一張卡，發動三次。";
TChineseAbilityDescriptions[AbilityNames.DELAY_PLAY] = "打出 Argument0";
TChineseAbilityDescriptions[AbilityNames.INVEST] = "INVEST ABILITY (never shown to user)";
TChineseAbilityDescriptions[AbilityNames.REAP_PLAY] = "打出放到一邊的 [Gold]";
TChineseAbilityDescriptions[AbilityNames.PLUS_CARD_TOKEN] = "因為+卡片指示物 +1卡片";
TChineseAbilityDescriptions[AbilityNames.PLUS_ACTION_TOKEN] = "因為+行動指示物 +1行動";
TChineseAbilityDescriptions[AbilityNames.PLUS_BUY_TOKEN] = "因為+購買指示物 +1購買";
TChineseAbilityDescriptions[AbilityNames.PLUS_COIN_TOKEN] = "因為+預算指示物 +1預算";
TChineseAbilityDescriptions[AbilityNames.KILN] = "獲得一張 Argument0";
TChineseAbilityDescriptions[AbilityNames.TURTLE_PLAY] = "打出被放到一邊的 Argument0";
TChineseAbilityDescriptions[AbilityNames.GIVE_PLUS_CARDS] = "+卡片";
TChineseAbilityDescriptions[AbilityNames.GIVE_PLUS_ACTIONS] = "+行動";
TChineseAbilityDescriptions[AbilityNames.GIVE_PLUS_BUYS] = "+購買";
TChineseAbilityDescriptions[AbilityNames.GIVE_PLUS_COINS] = "+預算";
TChineseAbilityDescriptions[AbilityNames.MONKEY_NEXT_TURN_ABILITY] = "+1 卡片";
TChineseAbilityDescriptions[AbilityNames.BLOCKADE_ABILITY] = "將 Argument0 加入手中";
TChineseAbilityDescriptions[AbilityNames.PIRATE_ABILITY] = "獲得一張錢幣卡並加入手中";
TChineseAbilityDescriptions[AbilityNames.PIRATE_REACTION] = "發動 [Pirate]";
TChineseAbilityDescriptions[AbilityNames.CORSAIR_NEXT_TURN_ABILITY] = "+1 卡片";
TChineseAbilityDescriptions[AbilityNames.CORSAIR_TRASH] = "移除 Argument0";
TChineseAbilityDescriptions[AbilityNames.SAILOR_NEXT_TURN] = "+$2, 還有你可以移除";
TChineseAbilityDescriptions[AbilityNames.SAILOR_REACTION] = "發動 Argument0";
TChineseAbilityDescriptions[AbilityNames.SKIRMISHER] = "其他玩家將手牌棄到剩 3 張";
TChineseAbilityDescriptions[AbilityNames.ENCHANTRESS_REPLACE_ABILITY] = "被魔女的效果變小豬";
TChineseAbilityDescriptions[AbilityNames.HIGHWAYMAN_NEXT_TURN_ABILITY] = "棄掉攔路強盜後, +3 卡片";
TChineseAbilityDescriptions[AbilityNames.HIGHWAYMAN_REPLACE_ABILITY] = "被攔路強盜無效";
TChineseAbilityDescriptions[AbilityNames.HIGHWAYMAN_NULLIFIED] = "沒有效果發動";
TChineseAbilityDescriptions[AbilityNames.LEAGUE_OF_BANKERS] = "每4支持就+$1";
TChineseAbilityDescriptions[AbilityNames.WOODWORKERS_GUILD] = "花費一點支持來移除手上一張卡片，並獲得一張行動卡";
TChineseAbilityDescriptions[AbilityNames.CRAFTERS_GUILD] = "花費2支持來獲得";
TChineseAbilityDescriptions[AbilityNames.CAVE_DWELLERS] = "花費1點支持來棄一張卡抽一張";
TChineseAbilityDescriptions[AbilityNames.DESERT_GUIDES] = "花費1點支持來棄掉重抽";
TChineseAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_CARD] = "花費1支持來+1 卡";
TChineseAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_ACTION] = "花費1支持來 +1 行動";
TChineseAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_BUY] = "花費1支持來 +1 購買";
TChineseAbilityDescriptions[AbilityNames.SYCOPHANT] = "+2 支持";
TChineseAbilityDescriptions[AbilityNames.IMPORTER_ABILITY] = "獲得一張價值至多5的卡";
TChineseAbilityDescriptions[AbilityNames.CONTRACT_ABILITY] = "打出 Argument0";
TChineseAbilityDescriptions[AbilityNames.MARKET_TOWNS] = "花費1支持來打出行動卡";
TChineseAbilityDescriptions[AbilityNames.TRAPPERS_LODGE] = "花費1支持來將 Argument0 放到牌庫頂";
TChineseAbilityDescriptions[AbilityNames.GUILDMASTER] = "+1 支持";
TChineseAbilityDescriptions[AbilityNames.ISLAND_FOLK] = "花費 5 支持來換取一個額外回合";
TChineseAbilityDescriptions[AbilityNames.GANG_OF_PICKPOCKETS] = "除非你花費1支持，否則棄到剩四張";
TChineseAbilityDescriptions[AbilityNames.COASTAL_HAVEN] = "保留一張手牌到下回合"; // TRANSLATE (changed)
// new TChinese text ("Keep" instead of "Set aside"):  "Keep cards in hand for next turn";
TChineseAbilityDescriptions[AbilityNames.CITY_STATE] = "花費 2 支持來打出 Argument0";
TChineseAbilityDescriptions[AbilityNames.CITY_STATE_PLAY] = "---";
TChineseAbilityDescriptions[AbilityNames.CIRCLE_OF_WITCHES] = "花費 3 支持讓其他玩家獲得詛咒";
TChineseAbilityDescriptions[AbilityNames.ROYAL_GALLEY_SET_ASIDE] = "因皇家槳帆船放到一邊";
TChineseAbilityDescriptions[AbilityNames.ROYAL_GALLEY_PLAY] = "發動 Argument0";
TChineseAbilityDescriptions[AbilityNames.STRONGHOLD_ABILITY] = "+3 卡片";
TChineseAbilityDescriptions[AbilityNames.MOUNTAIN_FOLK] = "花費5點支持來抽三張卡片";
TChineseAbilityDescriptions[AbilityNames.PEACEFUL_CULT] = "花費1點支持來移除";
TChineseAbilityDescriptions[AbilityNames.FELLOWSHIP_OF_SCRIBES] = "花費1支持來抽一張卡";
TChineseAbilityDescriptions[AbilityNames.LEAGUE_OF_SHOPKEEPERS] = "店主";
TChineseAbilityDescriptions[AbilityNames.TERRITORY_GAIN] = "有幾疊空牌堆就獲得幾張黃金";
TChineseAbilityDescriptions[AbilityNames.WARLORD_ABILITY] = "+2 卡片";
TChineseAbilityDescriptions[AbilityNames.GARRISON_ADD_TOKEN] = "累積數字";
TChineseAbilityDescriptions[AbilityNames.GARRISON_REMOVE_ABILITY] = "+Argument0 卡";
TChineseAbilityDescriptions[AbilityNames.CONJURER] = "將此卡加入手中";
TChineseAbilityDescriptions[AbilityNames.LICH] = "棄掉此卡並獲得一張較便宜的卡";
TChineseAbilityDescriptions[AbilityNames.GALLERIA] = "+1 購買";
TChineseAbilityDescriptions[AbilityNames.ARCHITECTS_GUILD] = "獲得一張較便宜的非分數卡";
TChineseAbilityDescriptions[AbilityNames.FAMILY_OF_INVENTORS] = "使一疊牌堆變便宜";
TChineseAbilityDescriptions[AbilityNames.FOREST_DWELLERS] = "偵查 3 張卡";
TChineseAbilityDescriptions[AbilityNames.ASTROLABE_ABILITY] = "+$1, +1 購買";
TChineseAbilityDescriptions[AbilityNames.TIDE_POOLS_ABILITY] = "棄掉2張卡";
TChineseAbilityDescriptions[AbilityNames.SEA_WITCH_ABILITY] = "地牢";
TChineseAbilityDescriptions[AbilityNames.CLERK_ABILITY] = "發動 [Clerk]";
TChineseAbilityDescriptions[AbilityNames.TREASURY] = "將錢幣卡放到牌庫頂";
TChineseAbilityDescriptions[AbilityNames.MERCHANT_GUILD] = "每獲得一張卡就得到一存款";

TChineseAbilityDescriptions[AbilityNames.GUARD_DOG_REACTION] = "發動 [Guard Dog]";
TChineseAbilityDescriptions[AbilityNames.BERSERKER] = "發動 [Berserker]";
TChineseAbilityDescriptions[AbilityNames.NOMADS] = "+2 預算";
TChineseAbilityDescriptions[AbilityNames.TRAIL_REACTION] = "發動 [Trail]";
TChineseAbilityDescriptions[AbilityNames.WEAVER_PLAY] = "發動[Weaver]";
TChineseAbilityDescriptions[AbilityNames.SOUK] = "移除至多2張卡";
TChineseAbilityDescriptions[AbilityNames.CAULDRON_CURSING] = "其他玩家獲得一張詛咒";
TChineseAbilityDescriptions[AbilityNames.ALCHEMIST_TOPDECK] = "將煉金術士置頂";

TChineseAbilityDescriptions[AbilityNames.CAGE_TRASH] = "移除 [Cage]";
TChineseAbilityDescriptions[AbilityNames.CAGE_PUT_IN_HAND] = "將 Argument0 加入手中";
TChineseAbilityDescriptions[AbilityNames.GROTTO] = "棄掉 Argument0, 然後抽牌";
TChineseAbilityDescriptions[AbilityNames.JEWELLED_EGG_GAIN_LOOT] = "獲得一張贓物";
TChineseAbilityDescriptions[AbilityNames.SEARCH] = "移除[Search]並且獲得贓物";
TChineseAbilityDescriptions[AbilityNames.SHAMAN] = "從移除牌堆獲得一張至多6的卡";
TChineseAbilityDescriptions[AbilityNames.SECLUDED_SHRINE] = "移除至多兩張卡";
TChineseAbilityDescriptions[AbilityNames.SIREN_GAIN] = "移除[Siren]或是一張行動卡";
TChineseAbilityDescriptions[AbilityNames.SIREN_DURATION] = "抽到八張卡";
TChineseAbilityDescriptions[AbilityNames.STOWAWAY_DRAW] = "抽兩張卡";
TChineseAbilityDescriptions[AbilityNames.STOWAWAY_REACTION] = "發動 [Stowaway]";
TChineseAbilityDescriptions[AbilityNames.TASKMASTER] = "+1 行動, +1 預算";
TChineseAbilityDescriptions[AbilityNames.ABUNDANCE] = "+1 購買, +3 預算";
TChineseAbilityDescriptions[AbilityNames.CABIN_BOY] = "+2 預算, 或是移除 [Cabin Boy]來獲得一張持續卡";
TChineseAbilityDescriptions[AbilityNames.FLAGSHIP_ACTIVATE] = "";
TChineseAbilityDescriptions[AbilityNames.FLAGSHIP_REPLAY] = "再次發動 Argument0";
TChineseAbilityDescriptions[AbilityNames.GONDOLA_DURATION] = "+2 預算";
TChineseAbilityDescriptions[AbilityNames.GONDOLA_PLAY] = "打出行動卡";
TChineseAbilityDescriptions[AbilityNames.HARBOR_VILLAGE] = "+1 預算";
TChineseAbilityDescriptions[AbilityNames.LANDING_PARTY] = "置頂 [Landing Party]";
TChineseAbilityDescriptions[AbilityNames.MAPMAKER] = "打出 [Mapmaker]";
TChineseAbilityDescriptions[AbilityNames.ROPE] = "+1 卡片，然後你可以移除一張卡。";
TChineseAbilityDescriptions[AbilityNames.BURIED_TREASURE_DURATION] = "+1 購買, +3 預算";
TChineseAbilityDescriptions[AbilityNames.BURIED_TREASURE_PLAY] = "打出 [Buried Treasure]";
TChineseAbilityDescriptions[AbilityNames.CREW_DURATION] = "置頂 [Crew]";
TChineseAbilityDescriptions[AbilityNames.CUTTHROAT_GAIN] = "獲得一張贓物";
TChineseAbilityDescriptions[AbilityNames.ENLARGE] = "移除一張卡，來獲得一張價值至多加[2]的卡";
TChineseAbilityDescriptions[AbilityNames.FRIGATE_DURATION] = "";
TChineseAbilityDescriptions[AbilityNames.FRIGATE_DISCARD] = "棄到剩四張卡";
TChineseAbilityDescriptions[AbilityNames.LONGSHIP_DURATION] = "+2 卡片";
TChineseAbilityDescriptions[AbilityNames.MINING_ROAD] = "發動 Argument0";
TChineseAbilityDescriptions[AbilityNames.QUARTERMASTER] = "結算 [Quartermaster]";
TChineseAbilityDescriptions[AbilityNames.TRICKSTER_REACTION] = "將一張錢幣卡放到一邊";
TChineseAbilityDescriptions[AbilityNames.TRICKSTER_RETURN_TO_HAND] = "將 Argument0 加入手中";
TChineseAbilityDescriptions[AbilityNames.WEALTHY_VILLAGE] = "獲得一張贓物";
TChineseAbilityDescriptions[AbilityNames.DELIVER_SET_ASIDE] = "將 Argument0 放到一邊";
TChineseAbilityDescriptions[AbilityNames.DELIVER_RETURN] = "將 Argument0 加入手牌";
TChineseAbilityDescriptions[AbilityNames.RUSH] = "發動 Argument0";
TChineseAbilityDescriptions[AbilityNames.MIRROR] = "獲得另一張 Argument0";
TChineseAbilityDescriptions[AbilityNames.PREPARE] = "打出放到一邊的卡";
TChineseAbilityDescriptions[AbilityNames.AMPHORA] = "+1 購買, +3 預算";
TChineseAbilityDescriptions[AbilityNames.DOUBLOONS] = "獲得一張黃金";
TChineseAbilityDescriptions[AbilityNames.ENDLESS_CHALICE] = "+1 預算, +1 購買";
TChineseAbilityDescriptions[AbilityNames.FIGUREHEAD] = "+2 卡片";
TChineseAbilityDescriptions[AbilityNames.JEWELS] = "將 [Jewels] 放到牌牌庫底";
TChineseAbilityDescriptions[AbilityNames.PUZZLE_BOX_RETURN] = "將 Argument0 加入手牌";
TChineseAbilityDescriptions[AbilityNames.SHIELD_REACTION] = "展示 [Shield] 來無效對你的攻擊";
TChineseAbilityDescriptions[AbilityNames.SPELL_SCROLL] = "發動 Argument0";
TChineseAbilityDescriptions[AbilityNames.CURSED_GAIN] = "獲得一張贓物與詛咒";
TChineseAbilityDescriptions[AbilityNames.FAWNING_GAIN] = "獲得一張 [Fawning]卡片";
TChineseAbilityDescriptions[AbilityNames.SCHEDULER] = "[Friendly] (seeing this is a bug!)";
TChineseAbilityDescriptions[AbilityNames.FRIENDLY_ABILITY] = "棄掉一張 [Friendly] 來獲得另一張";
TChineseAbilityDescriptions[AbilityNames.HASTY_SET_ASIDE] = "將 Argument0 放到一邊";
TChineseAbilityDescriptions[AbilityNames.HASTY_PLAY] = "發動 Argument0";
TChineseAbilityDescriptions[AbilityNames.INHERITED] = "替換起始卡片";
TChineseAbilityDescriptions[AbilityNames.INSPIRING] = "發動一張你沒有打出的卡片";
TChineseAbilityDescriptions[AbilityNames.NEARBY] = "+1 購買";
TChineseAbilityDescriptions[AbilityNames.PATIENT_SCHEDULER] = "[Patient] ERROR (seeing this is a bug!)";
TChineseAbilityDescriptions[AbilityNames.PATIENT_SET_ASIDE] = "將 [Patient] 放到一邊.";
TChineseAbilityDescriptions[AbilityNames.PATIENT_PLAY] = "發動 Argument0";
TChineseAbilityDescriptions[AbilityNames.PIOUS] = "移除一張卡";
TChineseAbilityDescriptions[AbilityNames.RECKLESS_REPEAT] = "再發動一次 Argument0 ";
TChineseAbilityDescriptions[AbilityNames.RECKLESS_RETURN] = "歸還 Argument0 到它牌堆.";
TChineseAbilityDescriptions[AbilityNames.RICH_GAIN] = "獲得 [^nSilver]";
TChineseAbilityDescriptions[AbilityNames.SHY] = "棄掉一張 [Shy] 來換取 +2 卡片";
TChineseAbilityDescriptions[AbilityNames.TIRELESS_SET_ASIDE] = "將 Argument0 放到一邊";
TChineseAbilityDescriptions[AbilityNames.TIRELESS_RETURN] = "將 Argument0 放回牌庫頂";
TChineseAbilityDescriptions[AbilityNames.STAR_CHART] = "放回牌庫頂";
TChineseAbilityDescriptions[AbilityNames.ORDER_OF_ASTROLOGERS] = "放回牌庫頂";
TChineseAbilityDescriptions[AbilityNames.ORDER_OF_MASONS] = "棄掉";
TChineseAbilityDescriptions[AbilityNames.FATED] = "放牌庫頂或是棄掉";
TChineseAbilityDescriptions[AbilityNames.AVOID] = "棄掉";
TChineseAbilityDescriptions[AbilityNames.HERBALIST_ASK_FOR_DISCARD] = "置頂一張錢幣卡";
TChineseAbilityDescriptions[AbilityNames.SCHEME_ASK_FOR_DISCARD] = "置頂一張行動卡";
TChineseAbilityDescriptions[AbilityNames.TRICKSTER_ASK_FOR_DISCARD] = "將一張錢幣卡放到一邊";
TChineseAbilityDescriptions[AbilityNames.EMBARGO_GAIN_CURSE] = "獲得 [^nCurse]";
TChineseAbilityDescriptions[AbilityNames.HERBALIST_TOPDECK] = "[Herbalist] 將 Argument0 的效果放到牌庫頂";
TChineseAbilityDescriptions[AbilityNames.SCHEME_TOPDECK] = "[Scheme] 將 Argument0 放到牌庫頂 ";
TChineseAbilityDescriptions[AbilityNames.FROG_TOPDECK] = "將 Argument0 放到牌庫頂";
TChineseAbilityDescriptions[AbilityNames.MERCHANT_CAMP_TOPDECK] = "將 [Merchant Camp]放到牌庫頂";
TChineseAbilityDescriptions[AbilityNames.WALLED_VILLAGE_TOPDECK] = "將 [Walled Village] 放到牌庫頂";
TChineseAbilityDescriptions[AbilityNames.TENT_TOPDECK] = "將 [Tent]放到牌庫頂";
TChineseAbilityDescriptions[AbilityNames.HORN_TOPDECK] = "[Horn]將 [Border Guard] 放到牌庫頂 ";
TChineseAbilityDescriptions[AbilityNames.PAGE_EXCHANGE] = "用 [Page] 交換 [Treasure Hunter]";
TChineseAbilityDescriptions[AbilityNames.TREASURE_HUNTER_EXCHANGE] = "用 [Treasure Hunter] 交換 [Warrior]";
TChineseAbilityDescriptions[AbilityNames.WARRIOR_EXCHANGE] = "用 [Warrior] 交換 [Hero]";
TChineseAbilityDescriptions[AbilityNames.HERO_EXCHANGE] = "用 [Hero] 交換 [Champion]";
TChineseAbilityDescriptions[AbilityNames.PEASANT_EXCHANGE] = "用 [Peasant] 交換 [Soldier]";
TChineseAbilityDescriptions[AbilityNames.SOLDIER_EXCHANGE] = "用 [Soldier] 交換 [Fugitive]";
TChineseAbilityDescriptions[AbilityNames.FUGITIVE_EXCHANGE] = "用 [Fugitive] 交換 [Disciple]";
TChineseAbilityDescriptions[AbilityNames.DISCIPLE_EXCHANGE] = "用 [Disciple] 交換 [Teacher]";
TChineseAbilityDescriptions[AbilityNames.MARCHLAND] = "+1 購買, 棄卡來換取 +$1";
TChineseAbilityDescriptions[AbilityNames.FARMHANDS_SET_ASIDE] = "將一張錢幣卡或行動卡放到一邊";
TChineseAbilityDescriptions[AbilityNames.FARMHANDS_PLAY] = "發動 Argument0";
TChineseAbilityDescriptions[AbilityNames.JOUST_DISCARD] = "棄掉一張行省";
TChineseAbilityDescriptions[AbilityNames.INFIRMARY_OVERPAY] = "對 [Infirmary] 超額支付";
TChineseAbilityDescriptions[AbilityNames.RESOLVE_INFIRMARY_OVERPAY] = "對 [Infirmary] 超額支付";
TChineseAbilityDescriptions[AbilityNames.DRAW_FROM_FOOTPAD] = "抽一張卡片";
TChineseAbilityDescriptions[AbilityNames.FARRIER_OVERPAY] = "超額支付可以讓你在回合結束多抽卡片";
TChineseAbilityDescriptions[AbilityNames.RESOLVE_FARRIER_OVERPAY] = "結算 [Farrier] 的超額支付";
TChineseAbilityDescriptions[AbilityNames.DRAW_FROM_SQUIRREL] = "多抽兩張卡";
TChineseAbilityDescriptions[AbilityNames.DRAW_FROM_RIVER] = "多抽一張卡";
TChineseAbilityDescriptions[AbilityNames.DRAW_FROM_FARRIER] = "多抽 Argument0 張卡";
TChineseAbilityDescriptions[AbilityNames.FERRYMAN] = "獲得 Argument0";

TChineseAbilityDescriptions[AbilityNames.BIDING_TIME_SET_ASIDE_HAND] = "將手牌放到一邊";
TChineseAbilityDescriptions[AbilityNames.BIDING_TIME_RETURN_TO_HAND] = "將放到一邊的牌加入手牌";
TChineseAbilityDescriptions[AbilityNames.ENLIGHTENMENT_CANTRIP] = "+1 卡片, +1 行動";
TChineseAbilityDescriptions[AbilityNames.ENLIGHTENMENT_REPLACE_ABILITY] = "改為啟示的效果";
TChineseAbilityDescriptions[AbilityNames.GOOD_HARVEST] = "+1 購買 +1 預算";
TChineseAbilityDescriptions[AbilityNames.HARSH_WINTER] = "拿取負債或是把負債放上去";
TChineseAbilityDescriptions[AbilityNames.KIND_EMPEROR] = "獲得一張行動卡並加入手中";
TChineseAbilityDescriptions[AbilityNames.PANIC_BUYS] = "+2 購買";
TChineseAbilityDescriptions[AbilityNames.PANIC_RETURN] = "歸還 Argument0 ";
TChineseAbilityDescriptions[AbilityNames.PROGRESS] = "把 Argument0 放到牌庫頂";
TChineseAbilityDescriptions[AbilityNames.RAPID_EXPANSION_SET_ASIDE] = "將 Argument0 放到一邊";
TChineseAbilityDescriptions[AbilityNames.RAPID_EXPANSION_PLAY_LATER] = "打出 Argument0";
TChineseAbilityDescriptions[AbilityNames.SICKNESS] = "將一張詛咒放到牌庫頂或是棄掉三張手牌";
TChineseAbilityDescriptions[AbilityNames.RIVER_SHRINE] = "獲得一張價值至多 4的卡片";
TChineseAbilityDescriptions[AbilityNames.SAMURAI] = "+1 預算";
TChineseAbilityDescriptions[AbilityNames.DAIMYO_REPLAY] = "再次打出 Argument0";
TChineseAbilityDescriptions[AbilityNames.FORESIGHT_RETURN_TO_HAND] = TChineseAbilityDescriptions[AbilityNames.SAVE_RETURN_TO_HAND];