"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var QUESTION_LIST_ERROR_CODES = {

    ARRAY_EXPECTED: 'Given value is not of class Array',
    QUESTION_EXPECTED: 'Given value is not of class Question',
    INVALID_INDEX: 'Given question index out of bounds'
};

// List of related questions. This means answers can be updated automatically based
// on setting the answers of other questions in the list. The list can also automatically react
// on changed answers. The first question that is changed, claims successfully to be
// the initial question; the rest fails. When all answers are set, at the end of
// setting the answer of the initial question, the reaction of the question list is
// called.

var QuestionList = function () {
    function QuestionList(arrayOfQuestions) {
        _classCallCheck(this, QuestionList);

        this.errors = QUESTION_LIST_ERROR_CODES;
        this.errorMachine = new ErrorMachine(QUESTION_LIST_ERROR_CODES, QuestionList.name);

        this.checkParameters(arrayOfQuestions);

        this.array = arrayOfQuestions;
        this.index = 0;
        this.cycle = false;
        this.onChange = undefined;
        this.initialQuestion = undefined;

        if (this.array.length > 0) {
            this.setCurrent(this.array[0]);
        }
    }

    _createClass(QuestionList, [{
        key: 'setOnChangeMethod',
        value: function setOnChangeMethod(method) {

            this.onChange = method;
        }
    }, {
        key: 'onChangeDo',
        value: function onChangeDo() {

            if (isDefined(this.onChange)) {
                this.onChange();
            }

            this.initialQuestion = undefined;
        }
    }, {
        key: 'tryToBeInitialQuestion',
        value: function tryToBeInitialQuestion(question) {

            if (isUndefined(this.initialQuestion)) {
                this.initialQuestion = question;
            }
        }
    }, {
        key: 'isInitialQuestion',
        value: function isInitialQuestion(question) {
            return question === this.initialQuestion;
        }
    }, {
        key: 'checkParameters',
        value: function checkParameters(arrayOfQuestions) {
            var _this = this;

            if (!(arrayOfQuestions instanceof Array)) {
                throw this.errorMachine.error(this.errors.ARRAY_EXPECTED);
            }

            arrayOfQuestions.forEach(function (item) {

                if (!(item instanceof RelatedQuestion)) {
                    throw _this.errorMachine.error(_this.errors.QUESTION_EXPECTED, item);
                }

                item.questionList = _this;
            });
        }
    }, {
        key: 'beforeCurrent',
        value: function beforeCurrent(question) {
            return this.array.indexOf(question) < this.array.indexOf(this.currentQuestion());
        }
    }, {
        key: 'afterCurrent',
        value: function afterCurrent(question) {
            return this.array.indexOf(question) > this.array.indexOf(this.currentQuestion());
        }
    }, {
        key: 'toArray',
        value: function toArray() {
            return this.array;
        }
    }, {
        key: 'maxIndex',
        value: function maxIndex() {
            return this.array.length - 1;
        }
    }, {
        key: 'nextIndex',
        value: function nextIndex() {
            return this.cycle ? (this.index + 1) % this.array.length : Math.min(this.index + 1, this.maxIndex());
        }
    }, {
        key: 'previousIndex',
        value: function previousIndex() {
            return this.cycle ? (this.index - 1 + this.array.length) % this.array.length : Math.max(this.index - 1, 0);
        }
    }, {
        key: 'nextQuestion',
        value: function nextQuestion() {
            return this.array[this.nextIndex()];
        }
    }, {
        key: 'previousQuestion',
        value: function previousQuestion() {
            return this.array[this.previousIndex()];
        }
    }, {
        key: 'currentQuestion',
        value: function currentQuestion() {
            return this.array[this.index];
        }
    }, {
        key: 'lastQuestion',
        value: function lastQuestion() {
            return this.array[this.array.length - 1];
        }
    }, {
        key: 'gotoNextQuestion',
        value: function gotoNextQuestion() {
            this.array.length === 0 || this.setCurrent(this.nextQuestion());
        }
    }, {
        key: 'gotoPreviousQuestion',
        value: function gotoPreviousQuestion() {
            this.array.length === 0 || this.setCurrent(this.previousIndex());
        }
    }, {
        key: 'visitedQuestions',
        value: function visitedQuestions() {
            return this.toArray().filter(function (question) {
                return question.isVisited();
            });
        }
    }, {
        key: 'hasErrors',
        value: function hasErrors() {
            return isDefined(this.firstWithError());
        }
    }, {
        key: 'firstWithError',
        value: function firstWithError() {
            return this.toArray().find(function (question) {
                return question.hasError();
            });
        }
    }, {
        key: 'successor',
        value: function successor(question) {

            var index = this.array.indexOf(question);
            var succssorIndex = this.cycle ? (index + 1) % this.array.length : Math.min(index + 1, this.maxIndex());

            return this.array[succssorIndex];
        }
    }, {
        key: 'setCurrent',
        value: function setCurrent(question) {

            var index = this.array.indexOf(question);

            this.setCurrentIndex(index);
            this.onFirstVisit(question);

            question.onBecommingCurrent();
        }
    }, {
        key: 'setCurrentIndex',
        value: function setCurrentIndex(index) {

            if (index < 0 || index > this.array.length - 1) {
                throw this.errorMachine.error(this.errors.INVALID_INDEX, item);
            }

            this.index = index;
        }
    }, {
        key: 'onFirstVisit',
        value: function onFirstVisit(question) {

            if (!question.isVisited()) {

                if (question.answerType === 'select') {
                    question.setAnswer(question.answerLabels[0], true, false);
                }
                if (question.answerType === 'select-buttons') {
                    question.setAnswer(question.answerLabels[0]);
                }
            }

            question.setVisited(true);
        }

        // Return true if answers have changed since last time you called this method.
        // Return true the first time this is called.

    }, {
        key: 'answersHaveChanged',
        value: function answersHaveChanged() {

            var answerLabels = this.answerLabels();

            if (isUndefined(this.previousAnswerLabels)) {
                this.previousAnswerLabels = answerLabels;
                return true;
            }

            if (ArrayEquals(answerLabels, this.previousAnswerLabels)) {
                return false;
            } else {

                this.previousAnswerLabels = answerLabels;
                return true;
            }
        }
    }, {
        key: 'answerLabels',
        value: function answerLabels() {

            return this.toArray().map(function (question) {
                return question.answerLabel();
            });
        }
    }]);

    return QuestionList;
}();