"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var OrderedPlayer = function () {
    function OrderedPlayer(namedId, order) {
        _classCallCheck(this, OrderedPlayer);

        this.namedId = namedId;
        this.order = order;
    }

    _createClass(OrderedPlayer, [{
        key: "serialize",
        value: function serialize(writer) {
            this.namedId.serialize(writer);
            writer.writeInt(this.order);
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var namedId = NamedId.parse(reader);
            var order = reader.readInt();
            return new OrderedPlayer(namedId, order);
        }
    }]);

    return OrderedPlayer;
}();