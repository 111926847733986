"use strict";

webclient.component("premovingDial", {
    bindings: {
        cardStack: "<"
    },
    controller: ["$scope", "$element", function ($scope, $element) {

        setInterval(fullRedraw, 40);
    }],
    template: "<canvas></canvas>"
});