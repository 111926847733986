"use strict";

// "use strict";
function Point(x, y) {
    var self = this;
    self.x = x;
    self.y = y;
}

function Rectangle(x, y, width, height) {
    var zIndex = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
    var rotZ = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 0;

    var _this = this;

    var opacity = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : 1;
    var rotY = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : 0;

    var self = this;
    self.x = x;
    self.y = y;
    self.width = width;
    self.height = height;
    self.zIndex = zIndex;
    self.rotZ = rotZ;
    self.rotY = rotY;
    self.opacity = opacity;

    self.getStyleString = function () {
        var scaleX = Math.cos(self.rotY / 180 * Math.PI);
        return "\n            left: 0;\n            top: 0;\n            width: " + self.width + "px;\n            height: " + self.height + "px;\n            font-size: " + Math.floor(self.height) + "px;\n            z-index: " + self.zIndex + ";\n            transform:\n                translateX(" + self.x + "px)\n                translateY(" + self.y + "px)\n                rotateZ(" + self.rotZ + "deg)\n                scaleX(" + scaleX + ");\n            opacity: " + self.opacity + ";\n        ";
    };

    self.contains = function (x, y) {
        return self.x <= x && x <= self.x + self.width && self.y <= y && y <= self.y + self.height;
    };

    self.intersects = function (b) {
        return self.x < b.x + b.width && b.x < self.x + self.width && self.y < b.y + b.height && b.y < self.y + self.height;
    };

    self.equals = function (b) {
        return self.x === b.x && self.y === b.y && self.width === b.width && self.height === b.height && self.zIndex === b.zIndex && self.rotZ === b.rotZ && self.opacity === b.opacity && self.rotY === b.rotY;
    };

    self.linearInterpolate = function (b, ratio) {
        return new Rectangle(self.x * (1 - ratio) + b.x * ratio, self.y * (1 - ratio) + b.y * ratio, self.width * (1 - ratio) + b.width * ratio, self.height * (1 - ratio) + b.height * ratio, self.zIndex * (1 - ratio) + b.zIndex * ratio, self.rotZ * (1 - ratio) + b.rotZ * ratio, self.opacity * (1 - ratio) + b.opacity * ratio, self.rotY * (1 - ratio) + b.rotY * ratio);
    };

    self.getOffset = function (b) {
        return new Rectangle(b.x - self.x, b.y - self.y, b.width - self.width, b.height - self.height, b.zIndex - self.zIndex, b.rotZ - self.rotZ, b.opacity - self.opacity, b.rotY - self.rotY);
    };

    self.offset = function (b) {
        return new Rectangle(self.x + b.x, self.y + b.y, self.width + b.width, self.height + b.height, self.zIndex + b.zIndex, self.rotZ + b.rotZ, self.opacity + b.opacity, self.rotY + b.rotY);
    };

    self.onScreen = function () {
        return self.intersects(new Rectangle(0, 0, window.innerWidth, window.innerHeight));
    };

    self.reset = function () {
        self.x = -10;
        self.y = -10;
        self.width = 0;
        self.height = 0;
    };

    self.copy = function () {
        return new Rectangle(_this.x, _this.y, _this.width, _this.height, _this.zIndex, _this.rotZ, _this.opacity, _this.rotY);
    };

    self.forceAspectRatio = function (ratio) {
        var outBox = self.copy();
        outBox.height = outBox.width * ratio;
        outBox.opacity = 1;
        outBox.rotZ = 0;
        outBox.rotY = 0;
        return outBox;
    };

    self.relativize = function () {
        var outBox = self.copy();
        outBox.x = 0;
        outBox.y = 0;
        outBox.zIndex = 0;
        return outBox;
    };

    self.roundPositions = function () {
        var outBox = self.copy();
        outBox.x = Math.floor(outBox.x);
        outBox.y = Math.floor(outBox.y);
        outBox.width = Math.floor(outBox.width);
        outBox.height = Math.floor(outBox.height);
        return outBox;
    };
}