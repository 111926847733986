"use strict";

var GermanPhrases = {};

GermanPhrases[Phrases.THE] = "den";
GermanPhrases[Phrases.THE_FROM] = "dem";
GermanPhrases[Phrases.THE_TO] = "den";
GermanPhrases[Phrases.AND] = "und";
GermanPhrases[Phrases.OR] = "oder";
GermanPhrases[Phrases.OTHER] = "andere";
GermanPhrases[Phrases.ANOTHER] = "ein anderer";
GermanPhrases[Phrases.PILE] = "Stapel";
GermanPhrases[Phrases.QUICK_UNDO] = "1 zurück";
GermanPhrases[Phrases.UNDO_BUTTON] = "beliebig zurück";
GermanPhrases[Phrases.UNDO] = "Rückgängig";
GermanPhrases[Phrases.YOU] = "Du";
GermanPhrases[Phrases.WOULD] = "würdest";
GermanPhrases[Phrases.YOU_MAY] = "Du darfst...";
GermanPhrases[Phrases.COMMA] = ", ";
GermanPhrases[Phrases.PERIOD] = ".";
GermanPhrases[Phrases.COMPLEX_STORY_LINE] = "FIRST, SECOND";
GermanPhrases[Phrases.COIN] = "Geld";
GermanPhrases[Phrases.COINS] = "Geld";
GermanPhrases[Phrases.COINS_MANY] = "Geld";
GermanPhrases[Phrases.POTION] = "Trank";
GermanPhrases[Phrases.POTIONS] = "Tränke";
GermanPhrases[Phrases.POTIONS_MANY] = "Tränke";
GermanPhrases[Phrases.DEBT] = "Schulden";

GermanPhrases[Phrases.VERSION] = "Version";
GermanPhrases[Phrases.USERNAME] = "Benutzername";
GermanPhrases[Phrases.PASSWORD] = "Passwort";
GermanPhrases[Phrases.CONFIRM_PASSWORD] = "Passwort bestätigen";
GermanPhrases[Phrases.FORGOT_YOUR_PASSWORD] = "Passwort vergessen?";
GermanPhrases[Phrases.CREATE_ACCOUNT] = "Konto erstellen";
GermanPhrases[Phrases.EMAIL] = "Email";
GermanPhrases[Phrases.SIGN_UP] = "Registrieren";
GermanPhrases[Phrases.LOGIN] = "Anmelden";
GermanPhrases[Phrases.KICK] = "Kick";
GermanPhrases[Phrases.RESIGN] = "Aufgeben";
GermanPhrases[Phrases.CREATE_TABLE] = "Tisch erstellen";
GermanPhrases[Phrases.LEAVE_TABLE] = "Tisch verlassen";
GermanPhrases[Phrases.MESSAGE] = "Nachricht";
GermanPhrases[Phrases.MESSAGE_SPECTATORS] = "Nachricht an Zuschauer";
GermanPhrases[Phrases.MESSAGE_PLAYERS] = "Nachricht an Spieler";
GermanPhrases[Phrases.START_GAME] = "Starte Spiel";
GermanPhrases[Phrases.READY] = "Bereit";
GermanPhrases[Phrases.NOT_READY] = "Nicht bereit";
GermanPhrases[Phrases.PLAYERS] = "Spieler";
GermanPhrases[Phrases.SPECTATORS] = "Zuschauer";
GermanPhrases[Phrases.HOST] = "Host";
GermanPhrases[Phrases.RANDOM] = "Zufällig";
GermanPhrases[Phrases.BASIC_OPTIONS] = "Standardoptionen";
GermanPhrases[Phrases.ADVANCED_OPTIONS] = "Erweiterte Optionen";
GermanPhrases[Phrases.YES] = "Ja";
GermanPhrases[Phrases.NO] = "Nein";
GermanPhrases[Phrases.FRIENDS_ONLY] = "Nur Freunde";
GermanPhrases[Phrases.EVENTS_AND_LANDMARKS] = "Ereignisse und Landmarken";
GermanPhrases[Phrases.TRUE] = "wahr";
GermanPhrases[Phrases.FALSE] = "falsch";
GermanPhrases[Phrases.QUICK_MATCH] = "Schnelles Spiel";
GermanPhrases[Phrases.GOOD_MATCH] = "Gutes Spiel";
GermanPhrases[Phrases.WAIT_FOR_EXPANSIONS] = "Warte auf Erweiterungen";
GermanPhrases[Phrases.CANCEL] = "Abbrechen";
GermanPhrases[Phrases.VIEW_TRASH] = "Müll";
GermanPhrases[Phrases.VIEW_KINGDOM] = "Karten";
GermanPhrases[Phrases.VIEW_PLAY_AREA] = "Spielfeld";
GermanPhrases[Phrases.LEAVE] = "Verlassen";
GermanPhrases[Phrases.TOTAL_IN_QUEUE] = "Benutzer insgesamt in der Warteschlange";
GermanPhrases[Phrases.EDIT_TABLE] = "Tisch bearbeiten";
GermanPhrases[Phrases.NEED_HELP] = "Brauchst du Hilfe?";
GermanPhrases[Phrases.PLAY] = "Spielen";
GermanPhrases[Phrases.MAKE_RESIGN] = "Soll USERNAME aufgeben?";
GermanPhrases[Phrases.UNDO_REQUEST_SINGULAR] = "Wunsch, 1 Schritt rückgängig zu machen";
GermanPhrases[Phrases.UNDO_REQUEST_PLURAL] = "Wunsch, NUMBER Schritte rückgängig zu machen";
GermanPhrases[Phrases.UNDO_REQUEST_MANY] = "Wunsch, NUMBER Schritte rückgängig zu machen";
GermanPhrases[Phrases.PENDING_UNDO_REQUEST] = "Rückgängig-Anfrage läuft...";
GermanPhrases[Phrases.SPECTATOR_UNDO_REQUEST] = "USERNAME has requested an undo";
GermanPhrases[Phrases.GRANT] = "Gestatten";
GermanPhrases[Phrases.ACCEPT] = "Akzeptieren";
GermanPhrases[Phrases.DECLINE] = "Ablehnen";
GermanPhrases[Phrases.DENY] = "Verweigern";
GermanPhrases[Phrases.UNDO_DENIED] = "Anfrage Rückgängig zu machen abgelehnt.";
GermanPhrases[Phrases.UNDO_CANCELLED] = "Anfrage Rückgängig zu machen abgebrochen.";
GermanPhrases[Phrases.TOC_SENTENCE] = "Ich habe die TERMS_OF_SERVICE gelesen.";
GermanPhrases[Phrases.TERMS_OF_SERVICE] = "Allgemeine Geschäftsbedingungen";
GermanPhrases[Phrases.TERMS_OF_SERVICE_URL] = ""; /* edit it after writing translated terms of service */
GermanPhrases[Phrases.RECONNECTING] = "Verbindung wird wiederhergestellt...";
GermanPhrases[Phrases.PLAYER_JOINED_TABLE] = "PLAYER_NAME ist dem Tisch beigetreten.";
GermanPhrases[Phrases.PLAYER_LEFT_TABLE] = "PLAYER_NAME hat den Tisch verlassen.";
GermanPhrases[Phrases.JOINING_GAME_MESSAGE] = "Du trittst Spiel #NUMBER bei.";
GermanPhrases[Phrases.SIGNING_UP] = "Du wirst angemeldet...";
GermanPhrases[Phrases.ACTION] = "Aktion";
GermanPhrases[Phrases.ACTIONS] = "Aktionen";
GermanPhrases[Phrases.BUY] = "Kauf";
GermanPhrases[Phrases.BUYS] = "Käufe";
GermanPhrases[Phrases.KICK_SUCCEEDED] = "Kick erfolgreich";
GermanPhrases[Phrases.CONNECTION_CLOSED] = "Serververbindung wurde geschlossen oder konnte nicht hergestellt werden.";
GermanPhrases[Phrases.NEW_PASSWORD] = "Neues Passwort";
GermanPhrases[Phrases.RETRY] = "Erneut versuchen";
GermanPhrases[Phrases.FRIEND] = "Freund";
GermanPhrases[Phrases.ACTIVITY] = "Aktivität";
GermanPhrases[Phrases.STATUS] = "Status";
GermanPhrases[Phrases.PURCHASE] = "Kaufe";
GermanPhrases[Phrases.UNTIL] = "bis";
GermanPhrases[Phrases.BUY_FOR_AMOUNT] = "Kaufe für AMOUNT";
GermanPhrases[Phrases.CHANGE_PASSWORD] = "Ändern";
GermanPhrases[Phrases.HIDE] = "Verstecken";
GermanPhrases[Phrases.CONFIRM] = "Bestätigen";
GermanPhrases[Phrases.JOIN] = "Beitreten";
GermanPhrases[Phrases.TURN] = "Zug";
GermanPhrases[Phrases.CARD] = "Karte";
GermanPhrases[Phrases.DESCRIPTION] = "Beschreibung";
GermanPhrases[Phrases.SCORE] = "Punkte";
GermanPhrases[Phrases.TURNS] = "Züge";
GermanPhrases[Phrases.NOTHING] = "nichts";
GermanPhrases[Phrases.SPECTATE] = "Zuschauen";
GermanPhrases[Phrases.FRIENDS] = "Freunde";
GermanPhrases[Phrases.FOLLOWING] = "Folgen";
GermanPhrases[Phrases.FOLLOWERS] = "Follower";
GermanPhrases[Phrases.UNFRIEND] = "Beenden";
GermanPhrases[Phrases.UNFOLLOW] = "Beenden";
GermanPhrases[Phrases.BEFRIEND] = "Anfreunden";
GermanPhrases[Phrases.FRIENDS_EXPLANATION] = "Gegenseitig als Freund hinzugefügt";
GermanPhrases[Phrases.FOLLOWING_EXPLANATION] = "Du hast diese Spieler als Freund hinzugefügt.";
GermanPhrases[Phrases.FOLLOWERS_EXPLANATION] = "Du wurdest von diesen Spielern als Freund hinzugefügt.";
GermanPhrases[Phrases.ADD] = "Hinzufügen";
GermanPhrases[Phrases.TYPE_FRIEND] = "Name des Freundes";
GermanPhrases[Phrases.UNLOCKS] = "freigeschalten";
GermanPhrases[Phrases.SELECT_YOUR_COUNTRY] = "Wähle dein Land";
GermanPhrases[Phrases.SELECT_A_COUNTRY] = "Wähle ein Land";
GermanPhrases[Phrases.KICK_AND_RESIGN] = "Kick & Aufgeben";
GermanPhrases[Phrases.BETWEEN_TURNS] = "Zwischen den Zügen";
GermanPhrases[Phrases.PLAYER] = "Spieler";
GermanPhrases[Phrases.RESIGNED] = "aufgegeben";
GermanPhrases[Phrases.YOU_RESIGNED] = "Du hast aufgegeben";
GermanPhrases[Phrases.YOU_LOSE] = "Niederlage";
GermanPhrases[Phrases.YOU_TIE] = "Unentschieden";
GermanPhrases[Phrases.YOU_WIN] = "Sieg";
GermanPhrases[Phrases.BUYS_MANY] = "Käufe";
GermanPhrases[Phrases.ACTIONS_MANY] = "Aktionen";
GermanPhrases[Phrases.TOKEN] = "Taler";
GermanPhrases[Phrases.TOKENS] = "Taler";
GermanPhrases[Phrases.TOKENS_MANY] = "Taler";
GermanPhrases[Phrases.OVERPAY] = "Mehr zahlen";
GermanPhrases[Phrases.BID] = "Biete";
GermanPhrases[Phrases.TAKE] = "Nimm";
GermanPhrases[Phrases.BOTTOM] = "Unten";
GermanPhrases[Phrases.TOP] = "Oben";
GermanPhrases[Phrases.LOCATION] = "Ort";
GermanPhrases[Phrases.CLEAN_UP] = "Aufräumphase";
GermanPhrases[Phrases.PLAN_CLEAN_UP] = "Plane die Aufräumphase";
GermanPhrases[Phrases.EXECUTE] = "Ausführen";
GermanPhrases[Phrases.MOVEMENT_VERB] = "VERB.";
GermanPhrases[Phrases.MOVEMENT_VERB_OBJECT] = "VERB OBJECT.";
GermanPhrases[Phrases.FORUM] = "Forum";
GermanPhrases[Phrases.VIEW_LOG] = "Log";
GermanPhrases[Phrases.REPLAY_GAME] = "Spiel wiederholen";
GermanPhrases[Phrases.REMOVE_REPLAY] = "Wiederholung löschen";
GermanPhrases[Phrases.SHELTERS] = "Unterschlüpfe";
GermanPhrases[Phrases.NO_SHELTERS] = "Keine Unterschlüpfe";
GermanPhrases[Phrases.COLONIES] = "Kolonien";
GermanPhrases[Phrases.NO_COLONIES] = "Keine Kolonien";
GermanPhrases[Phrases.PLAYER_ORDER] = "Spieler-Reihenfolge";
GermanPhrases[Phrases.ENTER_USERNAME] = "Please enter your username.";
GermanPhrases[Phrases.ENTER_LOGIN_CREDENTIALS] = "Bitte gib deine Anmeldedaten ein.";
GermanPhrases[Phrases.SUBMIT] = "Abschicken";
GermanPhrases[Phrases.DONE] = "Fertig";
GermanPhrases[Phrases.GAME_ID] = "Spiel-Id";
GermanPhrases[Phrases.DECISION_INDEX] = "Entscheidungsindex";
GermanPhrases[Phrases.ORIGINAL_PLAYER] = "Ursprünglicher Spieler";
GermanPhrases[Phrases.ORIGINAL_PLAYERS] = "Ursprüngliche Spieler";
GermanPhrases[Phrases.GAME_ID_SHOULD_BE_NUMBER] = "Spiel-Id muss eine Zahl sein.";
GermanPhrases[Phrases.ADD_BOT] = "Bot hinzufügen";
GermanPhrases[Phrases.DECISION] = "Entscheidung";
GermanPhrases[Phrases.LOAD_GAME] = "Spiel laden";
GermanPhrases[Phrases.CHANGE_REPLAY] = "Wiederholung ändern";
GermanPhrases[Phrases.NAME_CARD] = "Nenne eine Karte";
GermanPhrases[Phrases.LOAD_OLD_GAME] = "Altes Spiel laden";
GermanPhrases[Phrases.BOT] = "Bot";
GermanPhrases[Phrases.CANCEL_RESIGN] = "Abbrechen & Aufgeben";
GermanPhrases[Phrases.LOGOUT] = "Abmelden";
GermanPhrases[Phrases.FIRST] = "Erste";
GermanPhrases[Phrases.LAST] = "Letzte";
GermanPhrases[Phrases.INBOX] = "Postfach";
GermanPhrases[Phrases.MESSAGES] = "Nachrichten";
GermanPhrases[Phrases.MARK_READ] = "Als gelesen markieren";
GermanPhrases[Phrases.DELETE] = "Löschen";
GermanPhrases[Phrases.NO_MESSAGES] = "Keine Nachrichten";
GermanPhrases[Phrases.LEADERBOARD] = "Rangliste";
GermanPhrases[Phrases.LAST_UPDATED] = "Zuletzt aktualisiert";
GermanPhrases[Phrases.RANK] = "Rang";
GermanPhrases[Phrases.NAME] = "Name";
GermanPhrases[Phrases.RATING] = "Bewertung";
GermanPhrases[Phrases.TREND] = "Trend";
GermanPhrases[Phrases.VP] = "SP";
GermanPhrases[Phrases.FAQ] = "FAQ";
GermanPhrases[Phrases.RESULTS] = "Ergebnisse";
GermanPhrases[Phrases.SKILL] = "μ";
GermanPhrases[Phrases.DEVIATION] = "φ";
GermanPhrases[Phrases.VOLATILITY] = "σ";
GermanPhrases[Phrases.SKILL_RANGE] = "Fertigkeitsbereich";
GermanPhrases[Phrases.GAMES] = "Spiele";
GermanPhrases[Phrases.GLICKO2] = "Glicko-2";
GermanPhrases[Phrases.IMPLEMENTATION] = "Implementierung";
GermanPhrases[Phrases.RATED_QUEUE] = "Spieler in der Warteschlange für gewertete Spiele";
GermanPhrases[Phrases.UNRATED_QUEUE] = "Spieler in der Warteschlange für ungewertete Spiele";
GermanPhrases[Phrases.UNRATED_MATCH] = "Ungewertetes Spiel";
GermanPhrases[Phrases.LEVEL] = "Stufe";
GermanPhrases[Phrases.TWO_PLAYERS] = "2 Spieler";
GermanPhrases[Phrases.THREE_FOUR_PLAYERS] = "3-4 Spieler";
GermanPhrases[Phrases.GAME] = "Spiel";
GermanPhrases[Phrases.RATED] = "gewertet";
GermanPhrases[Phrases.UNRATED] = "ungewertet";
GermanPhrases[Phrases.OTHER_CARDS] = "unbekannte";
GermanPhrases[Phrases.OTHER_CARD] = "unbekannte";
GermanPhrases[Phrases.YOUR_TURN] = "Dein Zug!";
GermanPhrases[Phrases.LOADING_GAME] = "Lade Spiel...";
GermanPhrases[Phrases.BLACKLIST] = "Blacklist";
GermanPhrases[Phrases.REMOVE] = "Entfernen";
GermanPhrases[Phrases.BLACKLIST_USER] = "In die Blacklist";
GermanPhrases[Phrases.BEFRIEND_USER] = "In die Freundesliste";
GermanPhrases[Phrases.BLACKLISTED] = "auf der schwarzen Liste";
GermanPhrases[Phrases.FRIENDLIST_EMPTY] = "Deine Freundesliste ist derzeit leer.";
GermanPhrases[Phrases.BLACKLIST_EMPTY] = "Deine Blacklist ist derzeit leer.";
GermanPhrases[Phrases.PLAYERS_OF_TOTAL_PLAYERS] = "NUM_PLAYERS von NUM_TOTAL_PLAYERS";
GermanPhrases[Phrases.SHOW_RUNNING_GAMES] = "Laufende Spiele anzeigen";
GermanPhrases[Phrases.SHOW_1P_GAMES] = "1-Spieler-Spiele anzeigen";
GermanPhrases[Phrases.SHOW_2P_GAMES] = "2-Spieler-Spiele anzeigen";
GermanPhrases[Phrases.SHOW_3XP_GAMES] = "3+-Spieler-Spiele anzeigen";
GermanPhrases[Phrases.SHOW_BOT_GAMES] = "Bot-Spiele anzeigen";
GermanPhrases[Phrases.FIND_GAME] = "Spiel gegen Menschen";
GermanPhrases[Phrases.FIND_BOT_GAME] = "Spiel gegen Computer";
GermanPhrases[Phrases.START_SEARCH] = "Suche starten";
GermanPhrases[Phrases.CANCEL_SEARCH] = "Suche abbrechen";
GermanPhrases[Phrases.SEARCHING_2P] = "Zwei Spieler";
GermanPhrases[Phrases.SEARCHING_3P] = "Drei Spieler";
GermanPhrases[Phrases.SEARCHING_RATED] = "Gewertetes Spiel";
GermanPhrases[Phrases.SEARCHING_PRACTICE] = "Übungsspiel";
GermanPhrases[Phrases.MAX] = "Max";
GermanPhrases[Phrases.MIN] = "Min";
GermanPhrases[Phrases.SEARCHING] = "suche...";
GermanPhrases[Phrases.START_BOT_GAME_1] = "1 Bot";
GermanPhrases[Phrases.START_BOT_GAME_2] = "2 Bots";
GermanPhrases[Phrases.START_BOT_GAME_3] = "3 Bots";
GermanPhrases[Phrases.NO_FRIENDS_ONLINE] = "Keine Freunde online";
GermanPhrases[Phrases.AUTOMATCH_FEEDBACK] = "Suchende Spieler";
GermanPhrases[Phrases.NOBODY_ELSE_IN_QUEUE] = "Zur Zeit niemand.";
GermanPhrases[Phrases.CANCEL_SMALL] = "Stopp";
GermanPhrases[Phrases.PLAYER_REPLACED] = "LEAVING_PLAYER wurde von REPLACEMENT ersetzt.";
GermanPhrases[Phrases.RESIGN_PROMPT] = "Aufgeben?";
GermanPhrases[Phrases.RESIGN_MULTIPLE_OPPONENTS] = "Warnung: dies ist ein Mehrpersonen-Spiel - es wäre unsportlich gegenüber deinen Mitspielern, wenn du jetzt aufgibst";
GermanPhrases[Phrases.CONTINUE_WITH_BOTS] = "Spiel fortsetzen mit Bots";
GermanPhrases[Phrases.PLAYER_DISCONNECTED] = "PLAYER_NAME hat die Verbindung verloren.";
GermanPhrases[Phrases.PLAYER_RECONNECTED] = "PLAYER_NAME hat die Verbindung wiederhergestellt.";
GermanPhrases[Phrases.VALIDATE_BONUS_CODE] = "Einlösen";
GermanPhrases[Phrases.NEW_TO_DOMINION] = "Anleitung";
GermanPhrases[Phrases.SEARCHING_PREVIEWS] = "Menagerie Vorschau";
GermanPhrases[Phrases.AUTOPLAY_CLEANUP] = "Aufräumphasen-Autoplays";
GermanPhrases[Phrases.AUTOPLAY_INTERFACE] = "Standard-Autoplays";
GermanPhrases[Phrases.AUTOPLAY_OTHER] = "Andere Autoplays";
GermanPhrases[Phrases.GAME_ENDED] = "Das Spiel ist zu Ende.";
GermanPhrases[Phrases.OK] = "OK";
GermanPhrases[Phrases.HAS_RESIGNED] = "PLAYER_NAME hat aufgegeben.";

// Intro page of Store tab in Lobby
GermanPhrases[Phrases.STORE_WELCOME] = "Dominion Online Shop";
GermanPhrases[Phrases.ONE_MONTH] = "1 Monat";
GermanPhrases[Phrases.TWO_MONTHS] = "2 Monate";
GermanPhrases[Phrases.THREE_MONTHS] = "3 Monate";
GermanPhrases[Phrases.SIX_MONTHS] = "6 Monate";
GermanPhrases[Phrases.NINE_MONTHS] = "9 Monate";
GermanPhrases[Phrases.ONE_YEAR] = "1 Jahr";
GermanPhrases[Phrases.FIFTEEN_MONTHS] = "15 Monate";
GermanPhrases[Phrases.EIGHTEEN_MONTHS] = "18 Monate";
GermanPhrases[Phrases.TWO_YEAR] = "2 Jahre";
GermanPhrases[Phrases.CORE_SUBSCRIPTION] = "Kern-Abonnement";
GermanPhrases[Phrases.COMPLETE_SUBSCRIPTION] = "Komplett-Abonnement";
GermanPhrases[Phrases.CORE_SUBSCRIPTION_PRICE] = "(€ 2.15 / Monat)";
GermanPhrases[Phrases.CORE_SUBSCRIPTION_EXPLANATION] = "Die einfachere Hälfte der Karten";
GermanPhrases[Phrases.COMPLETE_SUBSCRIPTION_PRICE] = "(€ 4.30 / Monat)";
GermanPhrases[Phrases.COMPLETE_SUBSCRIPTION_EXPLANATION] = "Alle Karten";
GermanPhrases[Phrases.CHOOSE_SUBSCRIPTION] = "Wähle dein Abonnement: ";
GermanPhrases[Phrases.CHOOSE_DURATION] = "Wähle die Dauer des Abonnements, heute beginnend:";
GermanPhrases[Phrases.CONTINUE] = "Weiter";
GermanPhrases[Phrases.ADD_LATEST_EXPANSION] = "Möchtest Du Menagerie zu deinem aktuellen Abonnement hinzufügen?";
GermanPhrases[Phrases.INTRODUCTION_TO_STORE] = "Einführung in den Shop";
GermanPhrases[Phrases.CHECK_YOUR_PURCHASE] = "Überprüfe die Deteils des Kaufs";
GermanPhrases[Phrases.SELLER_INFO] = "Verkäufer-Information";
GermanPhrases[Phrases.BUYING_SUBSCRIPTION_TO] = "Kaufe Abonnement für";
GermanPhrases[Phrases.BUYING_SUBSCRIPTION_TILL] = "Kaufe Abonnement bis";
GermanPhrases[Phrases.AMOUNT] = "Preis";
GermanPhrases[Phrases.ITEM] = "Posten";
GermanPhrases[Phrases.YOUR_SUBSCRIPTION] = "Abonnement ";
GermanPhrases[Phrases.YOUR_ACCOUNT] = "Konto ";
GermanPhrases[Phrases.REDIRECTED_TO_PAYMENT_PROVIDER] = "Umleitung zum Zahlungsdienstleister ...";
GermanPhrases[Phrases.REDIRECT_YOURSELF] = "Wenn du nicht automatisch weitergeleitet wirst, klicke";
GermanPhrases[Phrases.THIS_LINK] = "diesen Link";
GermanPhrases[Phrases.ZERO_INVOICE_1] = "Dein gewählter Kauf fügt deinem aktuellen Abonnement nichts hinzu.";
GermanPhrases[Phrases.ZERO_INVOICE_2] = "Bitte wähle ein anderes Abonnement oder eine längere Dauer.";
GermanPhrases[Phrases.SORRY_STORE_SERVICE_DOWN] = "Der Shop ist im Moment nicht erreichbar. Bitte entschuldige die Unannehmlichkeiten.";
GermanPhrases[Phrases.CONNECTING_TO_STORE_SERVICE] = "Verbinde...";
GermanPhrases[Phrases.PAYMENT_REDIRECTION_FAILED] = "Wenn du nicht umgeleitet wurdest, stelle sicher, dass dein Browser das Öffnen neuer Tabs zuläßt.";
GermanPhrases[Phrases.REDIRECT_FAILED_BUTTON] = "Klicke, wenn die Umleitung nicht funktioniert hat.";
GermanPhrases[Phrases.UPGRADE_DURATION] = "Bis das aktuelle Abonnement ausläuft";
GermanPhrases[Phrases.FOR_FREE] = "Kostenlos";
GermanPhrases[Phrases.INVOICE_NEED_ANSWERS] = "Um die Rechnung zu bekommen, müssen auf alle Fragen gültige Antworten gegeben werden.";
GermanPhrases[Phrases.MAXED_OUT_SUBSCRIPTIONS] = "Dein Abonnement hat die maximale Dauer und enthält alle existierenden Erweiterungen.";
GermanPhrases[Phrases.IP_COUNTRY_MISMATCH] = "Das gewählte Land passt nicht zu deiner IP-Adresse:";
GermanPhrases[Phrases.EXPIRES_AT] = "Abonnement endet um";
GermanPhrases[Phrases.TOOLTIP_RANDOM_SELECTION] = "Klicke, um eine zufällige Karte aus ARGUMENT0 deinem König­reich hin­zu­zu­fügen.";
GermanPhrases[Phrases.TOOLTIP_LANDSCAPE_SLOT] = "Dieser Slot könnte ARGUMENT0 sein. Klicke auf die Bilder, um das zu ändern. Entferne alle Häkchen, um den Slot zu entfernen.";
GermanPhrases[Phrases.LANDSCAPE_UNUSED] = "leer";
GermanPhrases[Phrases.TOOLTIP_LANDSCAPE_PLUS_SLOT] = "Schaffe einen neuen Slot für Querkarten und wähle dann, welche Arten von Querkarten dort erscheinen können.";
GermanPhrases[Phrases.LANDSCAPE] = "Querkarte";
GermanPhrases[Phrases.MOUSE_TOY] = "Beiseite gelegte Karte für Weg der Maus";
GermanPhrases[Phrases.VILLAGERS] = "Dorfbew.";

GermanPhrases[Phrases.PREVIEW_BOT_MATCH] = "Vorschau gegen Bot";

GermanPhrases[Phrases.TOOLTIP_USE_COFFERS] = "Klicke, um einen Taler auszugeben";
GermanPhrases[Phrases.TOOLTIP_CANT_USE_COFFERS] = "Du kannst Taler nur in deiner Kaufphase ausgeben, vor dem Kaufen";
GermanPhrases[Phrases.TOOLTIP_USE_VILLAGER] = "Klicke, um einen Dorfbewohner zu aktivieren";
GermanPhrases[Phrases.TOOLTIP_CANT_USE_VILLAGER] = "Du kannst Dorfbewohner nur in deiner Aktionsphase aktivieren";
//GermanPhrases[Phrases.CARDS_BANNED_BY_PLAYERS]        = "Von Spielern verbotene Karten: ";
GermanPhrases[Phrases.AUTOCOMPLETE_CARD_LISTS] = "Karten zur Liste hinzufügen";
GermanPhrases[Phrases.RESPECTED_CARD_LISTS] = "Berücksichtigte Kartenlisten";
GermanPhrases[Phrases.BANNED_CARDS] = "verbotene Karten";
GermanPhrases[Phrases.LIKED_CARDS] = "beliebte Karten";
GermanPhrases[Phrases.DISLIKED_CARDS] = "unbeliebte Karten";
GermanPhrases[Phrases.KINGDOM_GENERATOR_PERCENTAGES] = "relative Wahrscheinlichkeiten im Königreich:";
GermanPhrases[Phrases.DAYS] = "Tage";
GermanPhrases[Phrases.SPECIAL_KINGDOM_RULES] = "Spezielle Königreich-Regeln:";
GermanPhrases[Phrases.SHOW_CHAT] = "Zeige Chat";
GermanPhrases[Phrases.HIDE_CHAT] = "Verberge Chat";
GermanPhrases[Phrases.UNBLACKLIST] = "Raus aus der Blacklist";
GermanPhrases[Phrases.INHERITANCE_INSTRUCTIONS] = "Spiele die Karte unter//deinem Anwesen-Marker//und lasse sie dort.";
GermanPhrases[Phrases.AUTOMATCH_LABEL_CARD_POOL_RANGE] = "Kartenstufenbereich";
GermanPhrases[Phrases.AUTOMATCH_LABEL_EXPANSIONS] = "Expansions";
GermanPhrases[Phrases.AUTOMATCH_LABEL_RATING] = "Rangliste";
GermanPhrases[Phrases.BASE_ONLY] = "Basis";
GermanPhrases[Phrases.SIMPLE] = "Einfach";

GermanPhrases[Phrases.TOOLTIP_OWNED_ENABLED] = "Deaktiviere Erweiterung durch Klick.<br/>Wenn deaktiviert, bringst du sie nicht zu deinen Spielen.";
GermanPhrases[Phrases.TOOLTIP_OWNED_DISABLED] = "Aktiviere Erweiterung durch Klick.<br/>Wenn aktiviert, bringst du sie zu deinen Spielen.";
GermanPhrases[Phrases.TOOLTIP_VOID_ENABLED] = "Deaktiviere Erweiterung durch Klick.<br/>Deaktivierung hat ohne Abonnement keinen Effekt.";
GermanPhrases[Phrases.TOOLTIP_VOID_DISABLED] = "Aktiviere Erweiterung durch Klick.<br/>Aktivierung hat ohne Abonnement keinen Effekt.";
GermanPhrases[Phrases.TOOLTIP_BASE_SET] = "Das Basisspiel kann nicht deaktiviert werden.";
GermanPhrases[Phrases.CHOOSE_CARD_POOL] = "Wähle Kartenstufe";
GermanPhrases[Phrases.USE_LEVEL] = "Nutze Stufe";
GermanPhrases[Phrases.VIEW_INTRODUCTION_CARD_SELECTION] = "Lies Einführung in Kartenwahl";
GermanPhrases[Phrases.VIEW_CARD_POOL_LEVELS] = "Sichte Kartenstufen";
GermanPhrases[Phrases.CARD_POOL_BOX_HEADER] = "Kartenstufen";
GermanPhrases[Phrases.CARD_POOL_LEVEL] = "Stufe";
GermanPhrases[Phrases.MIN_CARD_POOL_LEVEL] = "Kleinste erwünschte Stufe";
GermanPhrases[Phrases.CARD_POOL_LEVEL_SMALL] = "Stufe";
GermanPhrases[Phrases.USED_CARD_POOL_LEVEL] = "Kartenstufe: "; // 
GermanPhrases[Phrases.SHOW_LEVEL] = "Kartenstufe: ";
GermanPhrases[Phrases.SUBSCRIPTION_YES] = "Danke für dein Abonnement.";
GermanPhrases[Phrases.SUBSCRIPTION_NO] = "Du hast kein Abonnement.";
GermanPhrases[Phrases.SUBSCRIPTION_FOR] = "Dein Abonnement reicht noch";
GermanPhrases[Phrases.EMPTY_BECAUSE_DISABLED] = "Du kannst keine Karten dieser Erweiterung wählen,weil du sie im Menüpunkt 'Mein Konto' deaktiviert hast.";
GermanPhrases[Phrases.EMPTY_BECAUSE_NOT_SUBSCRIBED] = "Du kannst keine Karten dieser Erweiterung wählen, weil Du kein Abonnement für sie hast.";
GermanPhrases[Phrases.EXPLAIN_SUBSCRIPTIONS] = "\nDiese Webseite erlaubt dir, das Basisspiel kostenlos zu spielen.\nWenn du mit den Erweiterungen spielen willst oder die Webseite unterst\xFCtzen m\xF6chtest, dann denke \xFCber den Kauf eines Abonnements nach.\nDu kannst auch auf einen Gegner warten, der Karten aus Erweiterungen hat. Wenn du das tun willst, w\xE4hle \"Warte auf Erweiterungen\" in den Automatch-Optionen.\n";
GermanPhrases[Phrases.CARD_SELECTION_INTRODUCTION_TEXT] = ["Der Hauptmen\xFCpunkt <span class=\"game-concept\">Spiel finden</span> bietet 2 M\xF6glichkeiten, die Karten zu begrenzen, mit denen du spielst. \n    Ansonsten kannst du unter <span class=\"game-concept\">Neuer Tisch</span> deinen eigenen Tisch aufmachen und alles selber einstellen, musst aber auf Gegner warten.", "<div class=\"header\">Kartenstufen</div>\n        Falls du dabei bist, die Karten zu lernen, ist das wahrscheinlich die beste Methode.\n        Wir haben 10 Kartenstufen definiert, vom Basisspiel als Stufe 1 bis alle Karten als Stufe 10.\n        Du kannst selbst entscheiden, wie schnell du die Stufen 'hinaufklettern' willst.\n        Du kannst dir kurze Erkl\xE4rungen der neuen Mechaniken jeder Stufe vorher anschauen.\n        <span class=\"game-concept\">Stufe</span> sollte auf die Kartenstufe gesetzt sein, mit der du maximal spielen willst.\n        <span class=\"game-concept\">Kleinste gew\xFCnschte Stufe</span> gibt die kleinste Stufe an, mit der du bereit bist, zu spielen. Wenn du sie niedriger setzt, hilfst du anderen Spielern, und es k\xF6nnte das Finden eines Gegners beschleunigen. ", "<div class=\"header\">Kartenlisten</div>\n        Der Knopf <span class=\"game-concept\">Kartenlisten</span> im Optionsmen\xFC (Zahnradsymbol oben links) erlaubt dir, kleine Listen von Karten anzulegen. Verbotene Karten  tauchen niemals in deinen Spielen auf, and unbeliebte Karten nur halb so oft wie normal."];

GermanPhrases[Phrases.LEVEL_DESCRIPTIONS] = ["Nur Basisspiel", "Einfache Karten", "Auswahl, Dauer- & Geldkarten, Platin & Kolonie", "Beim Nehmen/Kaufen/Entsorgen, Siegpunktmarker", "Taler, Dorfbewohner, Exil, Reaktionen", "Im Spiel, Beim Ablegen, Müll, Tableaus", "Ereignisse, Reserve, Abenteuer-Marker", "Projekte, Landmarken, Nacht, Pferde", "Extrakarten, Artefakte, Gaben, Plagen, Erbstücke", "Alle Karten"];
GermanPhrases[Phrases.NEW_CARDS] = "Neue Karten";
GermanPhrases[Phrases.FAVOR] = "Gefallen";
GermanPhrases[Phrases.FAVORS] = "Gefallen";
GermanPhrases[Phrases.FAVORS_MANY] = "Gefallen";
GermanPhrases[Phrases.INDIVIDUAL_EXPANSIONS] = "Einzelne Erweiterungen";
GermanPhrases[Phrases.WARNING_SEARCHING_SUBSCRIBER] = "Suche nach Gegner mit Abonnement";
GermanPhrases[Phrases.WARNING_MAX_RATING_LOW] = "Max Rangliste ist sehr niedrig";
GermanPhrases[Phrases.WARNING_MIN_RATING_HIGH] = "Min Rangliste ist sehr hoch";
GermanPhrases[Phrases.SEARCHING_2E_PREVIEWS] = "Vorschau 2. Editionen";
GermanPhrases[Phrases.WARNING_ERRATA] = "Hinweis: In diesem Spiel gibt es Karten mit neuen Textänderungen:";
GermanPhrases[Phrases.DONT_SHOW_THIS_MESSAGE_AGAIN] = "Diese Nachricht nicht nochmal anzeigen";
GermanPhrases[Phrases.REPLAY_LAST_KINGDOM] = "Letztes Königreich nochmal spielen";
GermanPhrases[Phrases.RECONNECTING_FAILED_MESSAGE] = "Du wurdest COUNT Mal mit diesem Spiel verbunden.";
GermanPhrases[Phrases.TRY_AGAIN] = "Nochmal verbinden";
GermanPhrases[Phrases.RETURN_TO_LOBBY] = "Zurück zur Lobby";
GermanPhrases[Phrases.NO_PONG_RECEIVED] = "Warnung: Möglicherweise keine Verbindung mehr zum Spieleserver.";
GermanPhrases[Phrases.MESSAGE_INDEX_MISMATCH] = "Warnung: Instabile Verbindung zum Spieleserver.";
GermanPhrases[Phrases.ALL] = "Alle";
GermanPhrases[Phrases.TIMED_OUT] = "Zeit abgelaufen";
GermanPhrases[Phrases.RECONNECT] = "Neu verbinden";
GermanPhrases[Phrases.SHADOW_CARDS] = "Schattenkarten";