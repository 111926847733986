"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CostReduction = function (_GameStateChange) {
    _inherits(CostReduction, _GameStateChange);

    function CostReduction(game, generic, byType, byCard, costOverride) {
        _classCallCheck(this, CostReduction);

        var _this = _possibleConstructorReturn(this, (CostReduction.__proto__ || Object.getPrototypeOf(CostReduction)).call(this, game));

        _this.generic = generic;
        _this.byType = byType;
        _this.byCard = byCard;
        _this.costOverride = costOverride;
        return _this;
    }

    _createClass(CostReduction, [{
        key: "execute",
        value: function execute(game) {
            var _this2 = this;

            game.state.cards.forEach(function (c) {
                return _this2.apply(c);
            });
            game.state.anonCards.forEach(function (c) {
                return _this2.apply(c);
            });
        }
    }, {
        key: "apply",
        value: function apply(card) {
            var _this3 = this;

            if (card.cardName.isLandscape()) return;
            if (this.costOverride.has(card.cardName)) {
                card.cost = this.costOverride.get(card.cardName);
            } else {
                var cardName = card.cardName;
                var byCard = this.byCard.has(cardName) ? this.byCard.get(cardName) : 0;
                var byType = card.types.map(function (t) {
                    return _this3.byType.has(t) ? _this3.byType.get(t) : 0;
                }).reduce(function (a, b) {
                    return a + b;
                }, 0);

                var effectiveCost = card.cardName.cost.copy();
                effectiveCost.coin -= this.generic + byType + byCard;
                if (effectiveCost.coin < 0) effectiveCost.coin = 0;
                card.cost = effectiveCost;
            }
        }
    }], [{
        key: "parse",
        value: function parse(game, reader) {
            var generic = reader.readInt();
            var byType = reader.readEnumToIntMapObject(Types);
            var byCard = reader.readEnumToIntMapObject(CardNames);
            var costOverride = reader.readEnumToObjectMap(CardNames, Cost.parse);
            return new CostReduction(game, generic, byType, byCard, costOverride);
        }
    }]);

    return CostReduction;
}(GameStateChange);