"use strict";

var EsperantoCardTexts = {};

EsperantoCardTexts[CardNames.BACK] = "";
EsperantoCardTexts[CardNames.CURSE] = "";
EsperantoCardTexts[CardNames.COPPER] = "";
EsperantoCardTexts[CardNames.SILVER] = "";
EsperantoCardTexts[CardNames.GOLD] = "";
EsperantoCardTexts[CardNames.ESTATE] = "";
EsperantoCardTexts[CardNames.DUCHY] = "";
EsperantoCardTexts[CardNames.PROVINCE] = "";

EsperantoCardTexts[CardNames.ARTISAN] = "Gajnu al via mano karton//kostantan \u011Dis [5].//Metu karton el via mano//sur vian kartaron.";
EsperantoCardTexts[CardNames.BANDIT] = "Gajnu Oron. \u0108iu alia//ludanto malka\u015Das la 2//suprajn kartojn de ties//kartaro, rubigas malka\u015Ditan//Trezorkarton krom Kupro,//kaj for\u0135etas la ceteron.";
EsperantoCardTexts[CardNames.BUREAUCRAT] = "Gajnu Ar\u011Denton sur vian//kartaron. \u0108iu alia ludanto //malka\u015Das Venkkarton el via//mano kaj metas \u011Din sur ties//kartaron (a\u016D malka\u015Das manon//sen Venkkartoj).";
EsperantoCardTexts[CardNames.CELLAR] = "|+1 Ago|////For\u0135etu iom ajn da kartoj,//poste tiru tiom da kartoj.";
EsperantoCardTexts[CardNames.CHAPEL] = "Rubigu \u011Dis 4//kartojn el via//mano.";
EsperantoCardTexts[CardNames.COUNCIL_ROOM] = "|+4 Kartoj|//|+1 A\u0109eto|////\u0108iu alia ludanto//tiras karton.";
EsperantoCardTexts[CardNames.FESTIVAL] = "|+2 Agoj|//|+1 A\u0109eto|//|+[2]|";
EsperantoCardTexts[CardNames.GARDENS] = "Valoras {1} po 10//kartoj, kiujn vi havas//(subrondigu).";
EsperantoCardTexts[CardNames.HARBINGER] = "|+1 Karto|//|+1 Ago|////Trarigardu vian for\u0135etan//stakon. Vi povas meti//karton el \u011Di sur vian//kartaron.";
EsperantoCardTexts[CardNames.LABORATORY] = "|+2 Kartoj|//|+1 Ago|";
EsperantoCardTexts[CardNames.LIBRARY] = "Tiru \u011Dis vi havas 7 kartojn//mane, preterpasante iujn//ajn Agkartojn, kiujn vi//elektas; flankenmetu tiojn,//for\u0135etante ilin poste.";
EsperantoCardTexts[CardNames.MARKET] = "|+1 Karto|//|+1 Ago|//|+1 A\u0109eto|//|+[1]|";
EsperantoCardTexts[CardNames.MERCHANT] = "|+1 Karto|//|+1 Ago|////Kiam vi unuafoje ludas//Ar\u011Denton \u0109i-vice, |+|[1].";
EsperantoCardTexts[CardNames.MILITIA] = "|+[2]|//\u0108iu alia ludanto for\u0135etu//\u011Dis tiu havas 3 kartojn//mane.";
EsperantoCardTexts[CardNames.MINE] = "Vi povas rubigi//Trezorkarton el via mano.//Gajnu al via mano//Trezorkarton kostantan//\u011Dis [3] pli ol \u011Di.";
EsperantoCardTexts[CardNames.MOAT] = "|+2 Kartoj|//---//Kiam alia ludanto ludas//Atakkarton, vi povas unue//malka\u015Di \u0109i tion el via mano,//por esti ne tu\u015Dita de \u011Di.";
EsperantoCardTexts[CardNames.MONEYLENDER] = "Vi povas rubigi Kupron//el via mano por//|+|[3].";
EsperantoCardTexts[CardNames.POACHER] = "|+1 Karto|//|+1 Ago|//|+[1]|////For\u0135etu karton po//malplena Provizstako.";
EsperantoCardTexts[CardNames.REMODEL] = "Rubigu karton el via mano.//Gajnu karton kostantan \u011Dis//[2] pli ol \u011Di.";
EsperantoCardTexts[CardNames.SENTRY] = "|+1 Karto|//|+1 Ago|////Rigardu la 2 suprajn kartojn de//via kartaro. Rubigu ka\u016D for\u0135etu//iom ajn da ili. Remetu la ceteron//sur vian kartaron en ajna ordo.";
EsperantoCardTexts[CardNames.SMITHY] = "|+3 Kartoj|";
EsperantoCardTexts[CardNames.THRONE_ROOM] = "Vi povas ludi Agkarton//el via mano dufoje.";
EsperantoCardTexts[CardNames.VASSAL] = "|+[2]|////For\u0135etu la supran karton//de via kartaro. Se \u011Di//estas Agkarto, vi//povas ludi \u011Din.";
EsperantoCardTexts[CardNames.VILLAGE] = "|+1 Karto|//|+2 Agoj|";
EsperantoCardTexts[CardNames.WITCH] = "|+2 Kartoj|////\u0108iu alia ludanto//gajnas Malbenon.";

EsperantoCardTexts[CardNames.WORKSHOP] = "Gajnu karton//kostantan//\u011Dis [4].";
EsperantoCardTexts[CardNames.COURTYARD] = "|+3 Kartoj|////Metu karton el via mano//sur vian kartaron.";
EsperantoCardTexts[CardNames.CONSPIRATOR] = "|+[2]|////Se vi ludis 3 a\u016D pli da//Agkartoj \u0109i-vice//(inkluzive \u0109i tion),//|+1 Karto| kaj |+1 Ago|.";
EsperantoCardTexts[CardNames.COURTIER] = "Malka\u015Du karton el via mano.//Po speco, kiun \u011Di havas (Ago,//Atako, ktp), elektu unu: //|+1 Ago|; a\u016D |+1 A\u0109eto|; a\u016D//|+|[3]; a\u016D gajnu Oron. La//elektoj devas esti malsamaj.";
EsperantoCardTexts[CardNames.BARON] = "|+1 A\u0109eto|////Vi povas for\u0135eti Bienon//por |+|[4]. Se vi ne faras//tion, gajnu Bienon.";
EsperantoCardTexts[CardNames.BRIDGE] = "|+1 A\u0109eto|//|+[1]|////\u0108i-vice, kartoj (\u0109ie)//kostas [1] malpli.";
EsperantoCardTexts[CardNames.DIPLOMAT] = "|+2 Kartoj|////Se vi havas 5 a\u016D malpli da//kartoj mane (post tiro), |+2 Agoj|.//---//Kiam alia ludanto ludas Atakkarton,//vi povas unue malka\u015Di \u0109i tion el mano//kun 5 a\u016D pli da kartoj, por tiri 2//kartojn poste for\u0135eti 3 kartojn.";
EsperantoCardTexts[CardNames.DUKE] = "Valoras {1} po Duklando,//kiun vi havas.";
EsperantoCardTexts[CardNames.FARM] = "[!2]//---//{!2}";
EsperantoCardTexts[CardNames.NOBLES] = "{!2}//---//Elektu unu: |+3 Kartoj|; a\u016D//|+2 Agoj|.";
EsperantoCardTexts[CardNames.IRONWORKS] = "Gajnu karton kostantan \u011Dis [4].//Se la gajnita karto estas...//Agkarto, |+1 Ago|//Trezorkarto, |+[1]|//Venkkarto,|+1 Karto|";
EsperantoCardTexts[CardNames.LURKER] = "|+1 Ago|////Elektu unu: Rubigu//Agkarton el la Provizo;//a\u016D gajnu Agkarton el//la Rubujo.";
EsperantoCardTexts[CardNames.MASQUERADE] = "|+2 Kartoj|////\u0108iu ludanto kun iom ajn da//kartoj mane donas karton al la//sekva tia ludanto maldekstren,//samtempe. Poste vi povas//rubigi karton el via mano.";
EsperantoCardTexts[CardNames.MILL] = "|+1 Karto|//|+1 Ago|//Vi povas for\u0135eti 2 kartojn,//por |+|[2].//---//{!1}";
EsperantoCardTexts[CardNames.MINING_VILLAGE] = "|+1 Karto|//|+2 Agoj|////Vi povas rubigi \u0109i tion//por |+|[2].";
EsperantoCardTexts[CardNames.MINION] = "|+1 Ago|////Elektu unu: |+|[2]; a\u016D for\u0135etu//vian manon, |+4 Kartoj|, kaj//\u0109iu alia ludanto kun almena\u016D//5 kartoj mane for\u0135etas ties//manon kaj tiras 4 kartojn.";
EsperantoCardTexts[CardNames.PATROL] = "|+3 Kartoj|////Malka\u015Du la 4 suprajn kartojn//de via kartaro. Metu la//Venkkartojn kaj la Malbenojn//en vian manon. Remetu//la ceteron en ajna ordo.";
EsperantoCardTexts[CardNames.PAWN] = "Elektu du: |+1 Kartoj|; //|+1 Ago|; |+1 A\u0109eto|;//|+|[1]. La elektoj//devas esti malsamaj.";
EsperantoCardTexts[CardNames.REPLACE] = "Rubigu karton el via mano.//Gajnu karton kostantan \u011Dis [2]//pli ol \u011Di. Se la gajnita karto//estas Agkarto a\u016D Trezorkarto,//metu \u011Din sur vian kartaron; se//\u011Di estas Venkkarto, \u0109iu alia//ludanto gajnas Malbenon.";
EsperantoCardTexts[CardNames.SECRET_PASSAGE] = "|+2 Kartoj|//|+1 Ago|////Prenu karton el via mano//kaj metu \u011Din ie ajn//en vian kartaron.";
EsperantoCardTexts[CardNames.SHANTY_TOWN] = "|+2 Agoj|////Malka\u015Du vian manon.//Se vi ne havas Agkartojn//mane, |+2 Kartoj|.";
EsperantoCardTexts[CardNames.STEWARD] = "Elektu unu: |+2 Kartoj|;//a\u016D |+|[2]; a\u016D rubigu 2//kartojn el via mano.";
EsperantoCardTexts[CardNames.SWINDLER] = "|+[2]|////\u0108iu alia ludanto rubigas//la supran karton de ties//kartaro kaj gajnas karton//kun la sama kosto, kiun//vi elektas.";
EsperantoCardTexts[CardNames.TORTURER] = "|+3 Kartoj|////\u0108iu alia ludanto a\u016D for\u0135etas //2 kartojn a\u016D gajnas Malbenon //al via mano, tiu elektas.//(Tiu povas elekti elekton,//kiun tiu ne povas fari.)";
EsperantoCardTexts[CardNames.TRADING_POST] = "Rubigu 2 kartojn el via//mano. Se vi faris tion,//gajnu al via mano//Ar\u011Denton.";
EsperantoCardTexts[CardNames.UPGRADE] = "|+1 Karto|//|+1 Ago|////Rubigu karton el via mano.//Gajnu karton kostantan//precize [1] pli ol \u011Di.";
EsperantoCardTexts[CardNames.WISHING_WELL] = "|+1 Karto|//|+1 Ago|////Nomumu karton, poste//malka\u015Du la supran karton//de via kartaro. Se vi//nomumis \u011Din, metu \u011Din//en vian manon.";

EsperantoCardTexts[CardNames.AMBASSADOR] = "Malka\u015Du karton el via//mano. Redonu \u011Dis 2//kopiojn de \u011Di el via//mano al la Provizo.//Poste \u0109iu alia ludanto//gajnas kopion.";
EsperantoCardTexts[CardNames.BAZAAR] = "|+1 Karto|//|+2 Agoj|//|+[1]|";
EsperantoCardTexts[CardNames.CARAVAN] = "|+1 Karto|//|+1 Ago|////Je la komenco de via//sekva vico, |+1 Karto|.";
EsperantoCardTexts[CardNames.CUTPURSE] = "|+[2]|////\u0108iu alia ludanto for\u0135etas//Kupron (a\u016D malka\u015Das//manon sen Kuproj).";
EsperantoCardTexts[CardNames.EMBARGO] = "|+[2]|////Rubigu \u0109i tion. Se vi faris//tion, aldonu Embargjetonon//al Provizstako. (Por la cetero//de la ludo, kiam ludanto//a\u0109etas karton el tiu stako,//tiu gajnas Malbenon.)";
EsperantoCardTexts[CardNames.EXPLORER] = "Vi povas malka\u015Di Provincon//el via mano. Se vi faras tion,//gajnu al via mano Oron. Se//vi ne faras tion, gajnu al//via mano Ar\u011Denton.";
EsperantoCardTexts[CardNames.FISHING_VILLAGE] = "|+2 Agoj|//|+[1]|////Je la komenco de via//sekva vico: |+1 Ago|//kaj |+|[1].";
EsperantoCardTexts[CardNames.GHOST_SHIP] = "|+2 Kartoj|////\u0108iu alia ludanto kun 4 a\u016D pli//da kartoj mane metas kartojn //el ties mano sur ties kartaron//\u011Dis tiu havas 3 kartojn mane.";
EsperantoCardTexts[CardNames.HAVEN] = "|+1 Karto|//|+1 Ago|////Flankenmetu karton el via//mano surventre (sub \u0109i tio).//Je la komenco de via sekva//vico, metu \u011Din en vian manon.";
EsperantoCardTexts[CardNames.ISLAND] = "{!2}//---//Metu \u0109i tion kaj karton//el via mano sur vian//maton de Insulo.";
EsperantoCardTexts[CardNames.LIGHTHOUSE] = "|+1 Ago|////Nun kaj je la komenco de via//sekva vico, |+|[1].//---//Dum \u0109i tio estas en ludo,//kiam alia ludanto ludas//Atakkarton, \u011Di ne tu\u015Das vin.";
EsperantoCardTexts[CardNames.LOOKOUT] = "|+1 Ago|////Rigardu la 3 suprajn kartojn//de via kartaro. Rubigu unu el//ili. For\u0135etu unu el ili. Remetu//la alian sur vian kartaron.";
EsperantoCardTexts[CardNames.MERCHANT_SHIP] = "Nun kaj je la komenco//de via sekva vico://|+|[2].";
EsperantoCardTexts[CardNames.NATIVE_VILLAGE] = "|+2 Agoj|////Elektu unu: Metu la supran//karton de via kartaro surventre//sur vian maton de Indi\u011Dena Vila\u011Do//(vi povas rigardi tiujn kartojn//iam ajn); a\u016D metu \u0109iujn kartojn//el via mato en vian manon.";
EsperantoCardTexts[CardNames.NAVIGATOR] = "|+[2]|////Rigardu la 5 suprajn//kartojn de via kartaro.//A\u016D for\u0135etu \u0109iujn, a\u016D//remetu ilin en//ajna ordo.";
EsperantoCardTexts[CardNames.OUTPOST] = "Se \u0109i tiu estas la unua fojo,//ke vi ludis Anta\u016Dpostenon//\u0109i-vice, kaj la anta\u016Da vico ne//estis via, poste ludu ekstran//vicon post \u0109i tiu, kaj vi nur//tiras 3 kartojn por via//sekva mano.";
EsperantoCardTexts[CardNames.PEARL_DIVER] = "|+1 Karto|//|+1 Ago|////Rigardu la malsupran//karton de via kartaro.//Vi povas meti \u011Din//supre.";
EsperantoCardTexts[CardNames.PIRATE_SHIP] = "Elektu unu: |+|[1] po Monerjetono//sur via mato de Pirata \u015Cipo; a\u016D \u0109iu alia//ludanto malka\u015Das la 2 suprajn kartojn//de ties kartaro, rubigas unu el tiuj//Trezorkartoj, kiun vi elektas, for\u0135etas//la ceteron, kaj poste se iu ajn rubigis//Trezorkarton vi aldonas Monerjetonon//al via mato de Pirata \u015Cipo.";
EsperantoCardTexts[CardNames.SALVAGER] = "|+1 A\u0109eto|////Rubigu karton el via//mano. |+|[1] po [1],//kiun \u011Di kostas.";
EsperantoCardTexts[CardNames.SEA_HAG] = "\u0108iu alia ludanto for\u0135etas//la supran karton de ties//kartaro, poste gajnas//Malbenon sur ties//kartaron.";
EsperantoCardTexts[CardNames.SMUGGLERS] = "Gajnu kopion de karto//kostanta \u011Dis [6], kiun la//ludanto dekstren gajnis//en ties anta\u016Da vico.";
EsperantoCardTexts[CardNames.TACTICIAN] = "Se vi havas almena\u016D unu//karton mane, for\u0135etu vian//manon, kaj je la komenco de//via sekva vico, |+5 Kartoj|,//|+1 Ago|, kaj |+1 A\u0109eto|.";
EsperantoCardTexts[CardNames.TREASURE_MAP] = "Rubigu \u0109i tion kaj//Trezormapon el via//mano. Se vi rubigis 2//Trezormapojn, gajnu//4 Orojn sur vian//kartaron.";
EsperantoCardTexts[CardNames.TREASURY] = "|+1 Karto|//|+1 Ago|//|+[1]|//---//Kiam vi for\u0135etas \u0109i tion el ludo, se//vi ne gajnis Venkkarton \u0109i-vice, vi//povas meti \u0109i tion sur vian kartaron.";
EsperantoCardTexts[CardNames.WAREHOUSE] = "|+3 Kartoj|//|+1 Ago|////For\u0135etu 3 kartojn.";
EsperantoCardTexts[CardNames.WHARF] = "Nun kaj je la komenco//de via sekva vico://|+2 Kartoj |kaj//|+1 A\u0109eto|.";

EsperantoCardTexts[CardNames.ALCHEMIST] = "|+2 Kartoj|//|+1 Ago|////Je la komenco de Purigfazo//\u0109i-vice, se vi havas Eliksiron en//ludo, vi povas meti \u0109i tion//sur vian kartaron.";
EsperantoCardTexts[CardNames.APOTHECARY] = "|+1 Karto|//|+1 Ago|////Malka\u015Du la 4 suprajn kartojn//de via kartaro. Metu la Kuprojn//kaj la Eliksirojn en vian manon.//Remetu la ceteron en ajna ordo.";
EsperantoCardTexts[CardNames.APPRENTICE] = "|+1 Ago|////Rubigu karton el via//mano. |+1 Karto| po [1],//kiun \u011Di kostas.//|+2 Kartoj| se \u011Di havas//[P] en \u011Dia kosto.";
EsperantoCardTexts[CardNames.FAMILIAR] = "|+1 Karto|//|+1 Ago|////\u0108iu alia ludanto gajnas//Malbenon.";
EsperantoCardTexts[CardNames.GOLEM] = "Malka\u015Du kartojn el via kartaro//\u011Dis vi malka\u015Das 2 Agkartojn//krom Golemoj. For\u0135etu la//aliajn kartojn, poste ludu la//Agkartojn en ajna ordo.";
EsperantoCardTexts[CardNames.HERBALIST] = "|+1 A\u0109eto|//|+[1]|////Unufoje \u0109i-vice, kiam vi//for\u0135etas Trezorkarton el//ludo, vi povas meti \u0109i tion//sur vian kartaron.";
EsperantoCardTexts[CardNames.PHILOSOPHERS_STONE] = "Nombru vian kartaron//kaj for\u0135etan stakon.//Valoras [1] po 5 kartoj//inter ili (subrondigu).";
EsperantoCardTexts[CardNames.POSSESSION] = "La ludanto maldekstren ludas//ekstran vicon post \u0109i tiu, dum kiu vi//povas vidi \u0109iujn kartojn, kiujn tiu povas//vidi kaj decidi \u0109ion por tiu. Vi gajnas iujn//ajn kartojn a\u016D [D], kiujn tiu gajnus en tiu//vico; tiu flankenmetas \u0109iujn ties kartojn,//kiujn tiu rubigas, kaj tiu metas ilin en//ties for\u0135etan stakon je la fino de la vico.";
EsperantoCardTexts[CardNames.POTION] = "";
EsperantoCardTexts[CardNames.SCRYING_POOL] = "|+1 Ago|////\u0108iu ludanto (inkluzive vin) malka\u015Das//la supran karton de ties kartaro kaj//a\u016D for\u0135etas \u011Din a\u016D remetu \u011Din, vi//elektas. Poste malka\u015Du kartojn el via//kartaro \u011Dis vi malka\u015Das unu, kiu ne//estas Agkarto. Metu \u0109iujn tiujn//malka\u015Ditajn kartojn en vian manon.";
EsperantoCardTexts[CardNames.TRANSMUTE] = "Rubigu karton el via mano.//Se vi estas...//Agkarto, gajnu Duklandon//Trezorkarto, gajnu Transmutacion//Venkkarto, gajnu Oron";
EsperantoCardTexts[CardNames.UNIVERSITY] = "|+2 Agoj|////Vi povas gajni Agkarton//kostantan \u011Dis [5].";
EsperantoCardTexts[CardNames.VINEYARD] = "Valoras {1} po 3//Agkartoj, kiujn vi havas//(subrondigu).";

EsperantoCardTexts[CardNames.BANK] = "Valoras [1] po//Trezorkarto, kiun vi//havas en ludo//(inkluzive \u0109i tion).";
EsperantoCardTexts[CardNames.BISHOP] = "|+{1}|//|+[1]|////Rubigu karton el via//mano. |+|{1} po [2], kiujn \u011Di//kostas (subrondigu). \u0108iu//alia ludanto povas rubigi//karton el ties mano.";
EsperantoCardTexts[CardNames.COLONY] = "";
EsperantoCardTexts[CardNames.CONTRABAND] = "[!3]//|+1 A\u0109eto|////La ludanto maldekstren//nomumas karton. Vi//ne povas a\u0109eti tiun//karton \u0109i-vice.";
EsperantoCardTexts[CardNames.COUNTING_HOUSE] = "Trarigardu vian for\u0135etan//stakon, malka\u015Du iom ajn//da Kuprojn el \u011Di, kaj//metu ilin en vian manon.";
EsperantoCardTexts[CardNames.CITY] = "|+1 Karto|//|+2 Agoj|////Se estas unu a\u016D pli malplenaj//Provizstakoj, |+1 Karto|.//Se estas 2 a\u016D pli,//|+1 A\u0109eto| kaj |+|[1].";
EsperantoCardTexts[CardNames.EXPAND] = "Rubigu karton el via mano.//Gajnu karton kostantan//\u011Dis [3] pli ol \u011Di.";
EsperantoCardTexts[CardNames.FORGE] = "Rubigu iom ajn da kartoj//el via mano. Gajnu karton//kun kosto, kiu precize//egalas la suma kosto en//[] de la rubigitaj kartoj.";
EsperantoCardTexts[CardNames.GRAND_MARKET] = "|+1 Karto|//|+1 Ago|//|+1 A\u0109eto|//|+[2]|//---//Vi ne povas a\u0109eti \u0109i tion se vi//havas iujn ajn Kuprojn en ludo.";
EsperantoCardTexts[CardNames.GOONS] = "|+1 A\u0109eto|//|+[2]|////\u0108iu alia ludanto for\u0135etu//\u011Dis tiu havas 3 kartojn mane.//---//Dum \u0109i tio estas en ludo,//kiam vi a\u0109etas karton, |+|{1}.";
EsperantoCardTexts[CardNames.HOARD] = "[!2]//\u0108i-vice, kiam vi gajnas//Venkkarton, se vi a\u0109etis//\u011Din, gajnu Oron.";
EsperantoCardTexts[CardNames.KINGS_COURT] = "Vi povas ludi Agkarton//el via mano trifoje.";
EsperantoCardTexts[CardNames.LOAN] = "[!1]////Malka\u015Du kartojn el via//kartaro \u011Dis vi malka\u015Das//Trezorkarton. For\u0135etu//a\u016D rubigu \u011Din. For\u0135etu//la aliajn kartojn.";
EsperantoCardTexts[CardNames.MINT] = "Vi povas malka\u015Di//Trezorkarton el via//mano. Gajnu kopion de \u011Di.//---//Kiam vi a\u0109etas \u0109i tion,//rubigu \u0109iujn ne-Da\u016Drajn//Trezorkartojn, kiujn vi//havas en ludo.";
EsperantoCardTexts[CardNames.MONUMENT] = "|+{1}|//|+[2]|";
EsperantoCardTexts[CardNames.MOUNTEBANK] = "|+[2]|////\u0108iu alia ludanto povas//for\u0135eti Malbenon. Se tiu//ne faras tion, tiu gajnas//Malbenon kaj Kupron.";
EsperantoCardTexts[CardNames.PEDDLER] = "|+1 Karto|//|+1 Ago|//|+[1]|//---//Dum via A\u0109etfazo, \u0109i tio//kostas [2] malpli po Agkarto,//kiun vi havas en ludo.";
EsperantoCardTexts[CardNames.PLATINUM] = "";
EsperantoCardTexts[CardNames.QUARRY] = "[!1]//Dum \u0109i tio estas en ludo,//Agkartoj kostas [!1] malpli.";
EsperantoCardTexts[CardNames.RABBLE] = "|+3 Kartoj|////\u0108iu alia ludanto malka\u015Das//la 3 suprajn kartojn de ties//kartaro, for\u0135etas la Agkartojn//kaj Trezorkartojn, kaj remetas//la ceteron en ajna ordo,//kiun tiu elektas.";
EsperantoCardTexts[CardNames.ROYAL_SEAL] = "[!2]//---//Dum \u0109i tio estas en ludo,//kiam vi gajnas karton,//vi povas meti \u011Din//sur vian kartaron.";
EsperantoCardTexts[CardNames.TALISMAN] = "[!1]//---//Dum \u0109i tio estas en ludo,//kiam vi a\u0109etas ne-Venkan//karton kostantan \u011Dis [4],//gajnu kopion de \u011Di.";
EsperantoCardTexts[CardNames.TRADE_ROUTE] = "|+1 A\u0109eto|//Rubigu karton el via mano.//|+|[1] po Monerjetono sur//la mato de Komerca Vojo.//---//Agordo: Aldonu Monerjetonon al \u0109iu//Venka Provizstako; movu \u0109i tiun//jetonon al la mato de Komerca Vojo//kiam karto estas gajnita el la stako.";
EsperantoCardTexts[CardNames.VAULT] = "|+2 Kartoj|////For\u0135etu iom ajn da kartoj,//por |+|[1] po \u0109iu. \u0108iu//alia ludanto povas for\u0135eti//2 kartojn, por tiri karton.";
EsperantoCardTexts[CardNames.VENTURE] = "[!1]////Kiam vi ludas \u0109i tion,//malka\u015Du kartojn el via//kartaro \u011Dis vi malka\u015Das//Trezorkarton. For\u0135etu//la aliajn kartojn. Ludu//tiun Trezorkarton.";
EsperantoCardTexts[CardNames.WATCHTOWER] = "Tiru \u011Dis vi havas 6//kartojn mane.//---//Kiam vi gajnas karton, vi//povas malka\u015Di \u0109i tion el//via mano, por a\u016D rubigi//tiun karton a\u016D meti \u011Din//sur vian kartaron.";
EsperantoCardTexts[CardNames.WORKERS_VILLAGE] = "|+1 Karto|//|+2 Agoj|//|+1 A\u0109eto|";

EsperantoCardTexts[CardNames.PRIZE_PILE] = "";
EsperantoCardTexts[CardNames.BAG_OF_GOLD] = "|+1 Ago|////Gajnu Oron sur vian//kartaron.////%(\u0108i tio ne estas en%//%la Provizo.)%";
EsperantoCardTexts[CardNames.DIADEM] = "[!2]////|+|[!1] po neuzita Ago, kiun//vi havas (Ago, ne Agkarto).////%(\u0108i tio ne estas%//%en la Provizo.)%";
EsperantoCardTexts[CardNames.FAIRGROUNDS] = "Valoras {2} po 5 malsame//nomataj kartoj, kiujn vi//havas (subrondigu).";
EsperantoCardTexts[CardNames.FARMING_VILLAGE] = "|+2 Agoj|////Malka\u015Du kartojn el via kartaro//\u011Dis vi malka\u015Das Agkarton a\u016D//Trezorkarton. Metu tiun//karton en vian manon kaj//for\u0135etu la ceteron.";
EsperantoCardTexts[CardNames.FOLLOWERS] = "|+2 Kartoj|////Gajnu Bienon. \u0108iu alia ludanto//gajnas Malbenon kaj for\u0135etas \u011Dis//tiu havas 3 kartojn mane.////%(\u0108i tio ne estas en la Provizo.)%";
EsperantoCardTexts[CardNames.FORTUNE_TELLER] = "|+[2]|////\u0108iu alia ludanto malka\u015Das//kartojn el ties kartaro \u011Dis//tiu malka\u015Das Venkkarton//a\u016D Malbenon. Tiu metas \u011Din//supre kaj for\u0135etas la ceteron.";
EsperantoCardTexts[CardNames.HAMLET] = "|+1 Karto|//|+1 Ago|////Vi povas for\u0135eti karton por//|+1 Ago|.//Vi povas for\u0135eti karton por//|+1 A\u0109eto|.";
EsperantoCardTexts[CardNames.HARVEST] = "Malka\u015Du la 4 suprajn//kartojn de via kartaro,//poste for\u0135etu ilin. |+|[1]//po malsame nomataj//kartoj, kiujn vi//malka\u015Dis.";
EsperantoCardTexts[CardNames.HORSE_TRADERS] = "|+[3]|//|+1 A\u0109eto|//For\u0135etu 2 kartojn.//---//Kiam alia ludanto ludas Atakkarton,//vi povas unue flankenmeti \u011Din el via//mano. Se vi faras tion, poste je la// komenco de via sekva vico, |+1 Karto|//kaj remetu \u0109i tion en vian manon.";
EsperantoCardTexts[CardNames.HORN_OF_PLENTY] = "Gajnu karton kostantan//\u011Dis [1] po malsame nomata//karto, kiun vi havas en//ludo (inkluzive \u0109i tion).//Se \u011Di estas Venkkarto,//rubigu \u0109i tion.";
EsperantoCardTexts[CardNames.HUNTING_PARTY] = "|+1 Karto|//|+1 Ago|////Malka\u015Du vian manon. Malka\u015Du//kartojn el via kartaro \u011Dis malka\u015Das//unu, kiu ne estas kopio de unu el//via mano. Metu \u011Din en vian//manon kaj for\u0135etu la ceteron.";
EsperantoCardTexts[CardNames.JESTER] = "|+[2]|////\u0108iu alia ludanto for\u0135etas la//supran karton de ties kartaro.//Se \u011Di estas Venkkarto tiu gajnas//Malbenon; alie a\u016D tiu gajnas//kopion de la for\u0135etita karto a\u016D//vi gajnas kopion, vi elektas.";
EsperantoCardTexts[CardNames.MENAGERIE] = "|+1 Ago|////Malka\u015Du vian manon. Se//\u0109iuj malka\u015Dantaj kartoj havas//malsamajn nomojn, |+3 Kartoj|.//Alie, |+1 Karto.|";
EsperantoCardTexts[CardNames.PRINCESS] = "|+1 A\u0109eto|//\u0108i-vice, kartoj//kostas [2] malpli.////%(\u0108i tio ne estas%//%en la Provizo.)%";
EsperantoCardTexts[CardNames.REMAKE] = "Faru \u0109i tion dufoje: Rubigu//karton el via mano, poste//gajnu karton kostantan//precize [1] pli ol \u011Di.";
EsperantoCardTexts[CardNames.TOURNAMENT] = "|+1 Ago|////\u0108iu ludanto povas malka\u015Di Provincon//el via mano. Se vi faras tion, for\u0135etu//\u011Din kaj gajnu iun ajn Premion (el la//Premistako) a\u016D Duklandon, sur vian//kartaron. Se neniu alia faras tion,//|+1 Karto| kaj |+|[1].";
EsperantoCardTexts[CardNames.TRUSTY_STEED] = "Elektu du: |+2 Kartoj|; |+2 Agoj|;//|+|[2]; gajnu 4 Ar\u011Dentojn kaj//metu vian kartaron en vian//for\u0135etan stakon. La elektoj//devas esti malsamaj.//%(\u0108i tio ne estas en la Provizo.)%";
EsperantoCardTexts[CardNames.YOUNG_WITCH] = "|+2 Kartoj|////For\u0135etu 2 kartojn. \u0108iu alia ludanto povas//malka\u015Di Malhelpkarton el ties mano;//se tiu ne faras tion, tiu gajnas Malbenon.//---//Agordo: Aldonu ekstran Regnostakon//kostantan [2] a\u016D[3] al la Provizo.//Kartoj el tiu stako estas Malhelpkartoj.";
EsperantoCardTexts[CardNames.BORDER_VILLAGE] = "|+1 Karto|//|+2 Agoj|//---//Kiam vi gajnas \u0109i tion, gajnu//pli malmultekostan karton.";

EsperantoCardTexts[CardNames.CACHE] = "[!3]//---//Kiam vi gajnas \u0109i tion,//gajnu 2 Kuprojn.";
EsperantoCardTexts[CardNames.CARTOGRAPHER] = "|+1 Karto|//|+1 Ago|////Rigardu la 4 suprajn kartojn//de via kartaro. For\u0135etu iom ajn//da ili, poste remetu la ceteron//en ajna ordo.";
EsperantoCardTexts[CardNames.CROSSROADS] = "Malka\u015Du vian manon.//|+1 Karto| po Venkkarto,//kiun vi malka\u015Das. Se \u0109i//tiu estas la unua fojo, ke//vi ludis Vojkruci\u011Don//\u0109i-vice, |+3 Agoj|.";
EsperantoCardTexts[CardNames.DEVELOP] = "Rubigu karton el via mano.//Gajnu du kartojn sur vian//kartaron, kun unu kostanta//precize [1] pli ol \u011Di, kaj unu//kostanta precize [1] malpli//ol \u011Di, en ajna ordo.";
EsperantoCardTexts[CardNames.DUCHESS] = "|+[2]|//\u0108iu ludanto (inkluzive vin)//rigardas la supran karton de ties//kartaro kaj povas for\u0135eti \u011Din.//---//En ludoj, kiuj uzas \u0109i tion, kiam//vi gajnas Duklandon, vi povas//gajni Dukinon.";
EsperantoCardTexts[CardNames.EMBASSY] = "|+5 Kartoj|////For\u0135etu 3 kartojn.//---//Kiam vi gajnas \u0109i tion, \u0109iu//alia ludanto gajnu Ar\u011Denton.";
EsperantoCardTexts[CardNames.FARMLAND] = "{!2}//---//Kiam vi a\u0109etas \u0109i tion, rubigu//karton el via mano kaj gajnu//karton krom Agro kostantan//precize [2] pli ol \u011Di.";
EsperantoCardTexts[CardNames.FOOLS_GOLD] = "Valoras [1] se estas la unua//fojo ke vi ludis Malsa\u011Da Oro//\u0109i-vice, alie valoras [4].//---//Kiam alia ludanto gajnas//Provincon, vi povas rubigi \u0109i//tion el via mano, por gajni//Oron sur vian kartaron.";
EsperantoCardTexts[CardNames.HAGGLER] = "|+[2]|//---//Dum \u0109i tio estas en ludo,//kiam vi a\u0109etas karton,//gajnu pli malmulte-//kostan ne-Venkan karton.";
EsperantoCardTexts[CardNames.HIGHWAY] = "|+1 Karto|//|+1 Ago|//---//Dum \u0109i tio estas en ludo,//kartoj kostas [1] malpli.";
EsperantoCardTexts[CardNames.ILL_GOTTEN_GAINS] = "[!1]////Kiam vi ludas \u0109i tion, vi povas//gajni al via mano Kupron.//---//Kiam vi gajnas \u0109i tion, \u0109iu//alia ludanto gajnas Malbenon.";
EsperantoCardTexts[CardNames.INN] = "|+2 Kartoj|//|+2 Agoj|////For\u0135etu 2 kartojn.//---//Kiam vi gajnas \u0109i tion, malka\u015Du//iom ajn da Agkartoj el \u011Dia//for\u0135eta stako, kaj miksu//ilin en vian kartaron.";
EsperantoCardTexts[CardNames.JACK_OF_ALL_TRADES] = "Gajnu Ar\u011Denton. Rigardu//la supran karton de via//kartaro; vi povas for\u0135eti//\u011Din. Tiru \u011Dis vi havas 5//kartojn mane. Vi povas//rubigi ne-Trezoran//karton el via mano.";
EsperantoCardTexts[CardNames.MANDARIN] = "|+[3]|//Metu karton el via mano//sur vian kartaron.//---//Kiam vi gajnas \u0109i tion, metu//\u0109iujn Trezorkartojn, kiujn//vi havas en ludo sur vian//kartaron en ajna ordo.";
EsperantoCardTexts[CardNames.NOBLE_BRIGAND] = "|+[1]|////Kiam vi a\u0109etas a\u016D ludas \u0109i tion, \u0109iu alia//ludanto malka\u015Das la 2 suprajn kartojn//de ties kartaro, rubigas malka\u015Ditan//Ar\u011Denton a\u016D Oron, kiun vi elektas,//for\u0135etas la ceteron, kaj gajnas Kupron//se tiu ne malka\u015Das Trezorkarton.//Vi gajnas la rubigitajn kartojn.";
EsperantoCardTexts[CardNames.NOMAD_CAMP] = "|+1 A\u0109eto|//|+[2]|//---//\u0108i tio estas gajnita sur vian//kartaron (anstata\u016D al via//for\u0135eta stako).";
EsperantoCardTexts[CardNames.OASIS] = "|+1 Karto|//|+1 Ago|//|+[1]|////For\u0135etu karton.";
EsperantoCardTexts[CardNames.ORACLE] = "\u0108iu ludanto (inkluzive//vin) malka\u015Das la 2 suprajn//kartojn de ties kartaro,//kaj for\u0135etas ilin a\u016D//remetas ilin, vi elektas.//Tiu elektas la ordo de//remeto. Poste, |+2 Kartoj|.";
EsperantoCardTexts[CardNames.MARGRAVE] = "|+3 Kartoj|//|+1 A\u0109eto|////\u0108iu alia ludanto tiras//karton, poste for\u0135etas \u011Dis//tiu havas 3 kartojn mane.";
EsperantoCardTexts[CardNames.SCHEME] = "|+1 Karto|//|+1 Ago|////\u0108i-vice, vi povas meti unu//da via Agkartoj sur vian//kartaron kiam vi for\u0135etas//\u011Din el ludo.";
EsperantoCardTexts[CardNames.SILK_ROAD] = "Valoras {1} po//4 Venkkartoj,//kiun vi havas//(subrondigu).";
EsperantoCardTexts[CardNames.SPICE_MERCHANT] = "Vi povas rubigi Trezorkarton//el via mano por elekti unu://|+2 Kartoj| kaj| +1 Ago|;//a\u016D| +1 A\u0109eto |kaj| +|[2].";
EsperantoCardTexts[CardNames.STABLES] = "Vi povas for\u0135eti Trezorkarton,//por |+3 Kartoj| kaj| +1 Ago|.";
EsperantoCardTexts[CardNames.TRADER] = "Rubigu karton el via//mano. Gajnu Ar\u011Denton//po [1], kiun \u011Di kostas.//---//Kiam vi gajnas karton, vi//povas malka\u015Di \u0109i tion el//via mano, por inter\u015Dan\u011Di//tiun karton por Ar\u011Dento.";
EsperantoCardTexts[CardNames.TUNNEL] = "{!2}//---//Kiam vi for\u0135etas \u0109i tion//krom en Purigfazo, vi//povas malka\u015Di \u011Din por//gajni Oron.";

EsperantoCardTexts[CardNames.RUIN_PILE] = "";
EsperantoCardTexts[CardNames.KNIGHTS] = "Miksu la Kavalirstakon anta\u016D//\u0109iu ludo kun \u011Di. Tenu \u011Din//surventre krom la supra karto,//kiu estas la sola, kiun oni//povas a\u0109eti a\u016D gajni.";
EsperantoCardTexts[CardNames.ABANDONED_MINE] = "|+|[1]";
EsperantoCardTexts[CardNames.ALTAR] = "Rubigu karton el via//mano. Gajnu karton//kostantan \u011Dis [5].";
EsperantoCardTexts[CardNames.ARMORY] = "Gajnu karton sur vian//kartaron kostantan//\u011Dis [4].";
EsperantoCardTexts[CardNames.BAND_OF_MISFITS] = "Ludu ne-Komandan//Agkarton el la Provizo//kostantan malpli ol//\u0109i tion, lasante \u011Din tie.";
EsperantoCardTexts[CardNames.BANDIT_CAMP] = "|+1 Karto|//|+2 Agoj|////Gajnu Raba\u0135on.";
EsperantoCardTexts[CardNames.BEGGAR] = "Gajnu al via mano 3 Kuprojn.//---//Kiam alia ludanto ludas//Atakkarton, vi povas unue//for\u0135eti \u0109i tion por gajni//2 Ar\u011Dentojn, metante unu//sur vian kartaron.";
EsperantoCardTexts[CardNames.CATACOMBS] = "Rigardu la 3 suprajn kartojn//de via kartaro. Elektu unu://Metu ilin en vian manon; a\u016D//for\u0135etu ilin kaj |+3 Kartoj|.//---//Kiam vi rubigas \u0109i tion, gajnu//pli malmultekostan karton.";
EsperantoCardTexts[CardNames.COUNT] = "Elektu unu: For\u0135etu 2//kartojn; a\u016D metu karton el//via mano sur vian kartaron;//a\u016D gajnu Kupron.////Elektu unu: |+|[3]; a\u016D rubigu//vian manon; a\u016D gajnu//Duklandon.";
EsperantoCardTexts[CardNames.COUNTERFEIT] = "[!1]////|+1 A\u0109eto|////Vi povas ludi ne-Da\u016Dran//Trezorkarton el via mano//dufoje. Rubigu \u011Din.";
EsperantoCardTexts[CardNames.CULTIST] = "|+2 Kartoj|////\u0108iu alia ludanto gajnas//Ruinon. Vi povas ludi//Sektanon el via mano.//---//Kiam vi rubigas \u0109i tion,//|+3 Kartoj|.";
EsperantoCardTexts[CardNames.DAME_ANNA] = "Vi povas rubigi \u011Dis 2 kartojn//el via mano. \u0108iu alia ludanto//malka\u015Das la 2 suprajn kartojn//de ties kartaro, rubigas unu//da ili kostantan de [3] \u011Dis [6],//kaj for\u0135etas la ceteron. Se \u0109i//tio rubigas Kavaliron,// rubigu \u0109i tion.";
EsperantoCardTexts[CardNames.DAME_JOSEPHINE] = "{!2}//---//\u0108iu alia ludanto malka\u015Das la//2 suprajn kartojn de ties kartaro,//rubigas unu da ili kostantan de [3]//\u011Dis [6], kaj for\u0135etas la ceteron. Se \u0109i//tio rubigas Kavaliron, rubigu \u0109i tion.";
EsperantoCardTexts[CardNames.DAME_MOLLY] = "|+2 Agoj|//\u0108iu alia ludanto malka\u015Das//la 2 suprajn kartojn de ties//kartaro, rubigas unu da//ili kostantan de [3] \u011Dis [6],//kaj for\u0135etas la ceteron. Se//\u0109i tio rubigas Kavaliron,//rubigu \u0109i tion.";
EsperantoCardTexts[CardNames.DAME_NATALIE] = "Vi povas gajni karton//kostantan \u011Dis [3]. \u0108iu alia//ludanto malka\u015Das la 2 suprajn//kartojn de ties kartaro, rubigas//unu da ili kostantan de [3] \u011Dis//[6], kaj for\u0135etas la ceteron. Se//\u0109i tio rubigas Kavaliron,// rubigu \u0109i tion.";
EsperantoCardTexts[CardNames.DAME_SYLVIA] = "|+[2]|//\u0108iu alia ludanto malka\u015Das//la 2 suprajn kartojn de ties//kartaro, rubigas unu da//ili kostantan de [3] \u011Dis [6],//kaj for\u0135etas la ceteron. Se//\u0109i tio rubigas Kavaliron,//rubigu \u0109i tion.";
EsperantoCardTexts[CardNames.DEATH_CART] = "Vi povas rubigi \u0109i tion//a\u016D Agkarton el via//mano, por |+|[5].//---//Kiam vi gajnas \u0109i tion,//gajnu 2 Ruinojn.";
EsperantoCardTexts[CardNames.FEODUM] = "Valoras {1} po 3 Ar\u011Dentojn,//kiujn vi havas (subrondigu).//---//Kiam vi rubigas \u0109i tion,//gajnu 3 Ar\u011Dentojn.";
EsperantoCardTexts[CardNames.FORAGER] = "|+1 Ago|//|+1 A\u0109eto|////Rubigu karton el via mano,//poste |+|[1] po malsame nomata//Trezorkarto en la Rubujo.";
EsperantoCardTexts[CardNames.FORTRESS] = "|+1 Karto|//|+2 Agoj|//---//Kiam vi rubigas \u0109i tion,//metu \u011Din en vian manon.";
EsperantoCardTexts[CardNames.GRAVEROBBER] = "Elektu unu: Gajnu karton el//la Rubujo kostantan de [3]//\u011Dis [6], sur vian kartaron; a\u016D//rubigu Agkarton el via mano//kaj gajnu karton kostantan//\u011Dis [3] pli ol \u011Di.";
EsperantoCardTexts[CardNames.HERMIT] = "Trarigardu vian for\u0135etan stakon.//Vi povas rubigi ne-Trezoran//karton el \u011Di a\u016D via mano.//Gajnu karton kostantan \u011Dis [3].//Je la fino de via A\u0109etfazo//\u0109i-vice, se vi ne gajnis//iom ajn da kartoj en \u011Di,//inter\u015Dan\u011Du \u0109i tiun por Frenezulo.";
EsperantoCardTexts[CardNames.HOVEL] = "Kiam vi gajnas Venkkarton,//vi povas rubigi \u0109i tion//el via mano.";
EsperantoCardTexts[CardNames.HUNTING_GROUNDS] = "|+4 Kartoj|//---//Kiam vi rubigas \u0109i tion, gajnu//Duklandon a\u016D 3 Bienojn.";
EsperantoCardTexts[CardNames.IRONMONGER] = "|+1 Karto|//|+1 Ago|//Malka\u015Du la supran karton de via//kartaro; vi povas for\u0135eti \u011Din.//\u0108iukaze se \u011Di estas...//Agkarto, |+1 Ago|//Trezorkarto, |+|[1]//Venkkarto,|+1 Karto|";
EsperantoCardTexts[CardNames.JUNK_DEALER] = "|+1 Karto|//|+1 Ago|//|+[1]|//// Rubigu karton el via mano.";
EsperantoCardTexts[CardNames.MADMAN] = "|+2 Agoj|////Redonu \u0109i tion al \u011Dia stako.//Se vi faras tion, |+1 Karto|//po karto en via mano.////%(\u0108i tio ne estas%//%en la Provizo.)%";
EsperantoCardTexts[CardNames.MARKET_SQUARE] = "|+1 Karto|//|+1 Ago|//|+1 A\u0109eto|//---//Kiam unu da viaj kartoj estas//rubigita, vi povas for\u0135etu \u0109i tion//el via mano por gajni Oron.";
EsperantoCardTexts[CardNames.MARAUDER] = "Gajnu Raba\u0135on.//\u0108iu alia ludanto//gajnas Ruinon.";
EsperantoCardTexts[CardNames.MERCENARY] = "Vi povas rubigi 2 kartojn//el via mano. Se vi faris//tion, |+2 Kartoj|, |+|[2], kaj//\u0109iu alia ludanto for\u0135etu \u011Dis//tiu havas 3 kartojn mane.////%(\u0108i tio ne estas%//%en la Provizo.)%";
EsperantoCardTexts[CardNames.MYSTIC] = "|+1 Ago|//|+[2]|////Nomumu karton, poste malka\u015Du//la supran karton de via kartaro.//Se vi nomumis \u011Din, metu \u011Din en//vian manon.";
EsperantoCardTexts[CardNames.NECROPOLIS] = "|+2 Agoj|";
EsperantoCardTexts[CardNames.OVERGROWN_ESTATE] = "{!0}//---//Kiam vi rubigas \u0109i tion,//|+1 Karto|.";
EsperantoCardTexts[CardNames.PILLAGE] = "Rubigu \u0109i tion. Se vi faris//tion, gajnu 2 Raba\u0135on, kaj//\u0109iu alia ludanto kun 5 a\u016D pli//da kartoj mane malka\u015Das ties//manon kaj for\u0135etas karton,//kiun vi elektas.";
EsperantoCardTexts[CardNames.POOR_HOUSE] = "|+[4]|////Malka\u015Du vian manon.//|-|[1] po Trezorkarto en//via mano. (Vi ne povas//havi malpli ol [0].)";
EsperantoCardTexts[CardNames.PROCESSION] = "Vi povas ludi ne-Da\u016Dran//Agkarton el via mano//dufoje. Rubigu \u011Din.//Gajnu Agkarton kostantan//precize [1] pli ol \u011Di.";
EsperantoCardTexts[CardNames.RATS] = "|+1 Karto|//|+1 Ago|//Gajnu Ratojn. Rubigu karton el//via mano krom Ratoj (a\u016D malka\u015Du//manon kun sole Ratoj).//---//Kiam vi rubigas \u0109i tion, |+1 Karto|.";
EsperantoCardTexts[CardNames.REBUILD] = "|+1 Ago|//Nomumu karton. Malka\u015Du//kartojn el via kartaro \u011Dis vi//malka\u015Das Venkkarton, kiun vi//ne nomumis. For\u0135etu la ceteron,//rubigu la Venkkarton, kaj//gajnu Venkkarton kostantan//\u011Dis [3] pli ol \u011Di.";
EsperantoCardTexts[CardNames.ROGUE] = "|+[2]|//Se estas iu ajn kartoj en la//Rubujo kostanta de [3] \u011Dis [6],//gajnu unu el ili. Alie, \u0109iu alia//ludanto malka\u015Das la 2 suprajn//kartojn de ties kartaro, rubigas//unu da ili kostantan de [3] \u011Dis//[6], kaj for\u0135etas la ceteron.";
EsperantoCardTexts[CardNames.RUINED_LIBRARY] = "|+1 Karto|";
EsperantoCardTexts[CardNames.RUINED_MARKET] = "|+1 A\u0109eto|";
EsperantoCardTexts[CardNames.RUINED_VILLAGE] = "|+1 Ago|";
EsperantoCardTexts[CardNames.SAGE] = "|+1 Ago|////Malka\u015Du kartojn el via//kartaro \u011Dis vi malka\u015Das//karton kostantan [3]//a\u016D pli. Metu tiun//karton en vian manon//kaj for\u0135etu la ceteron.";
EsperantoCardTexts[CardNames.SCAVENGER] = "|+[2]|////Vi povas meti vian kartaron//en vian for\u0135etan stakon.//Metu karton el via for\u0135eta//stako sur vian kartaron.";
EsperantoCardTexts[CardNames.SIR_BAILEY] = "|+1 Karto|//|+1 Ago|//\u0108iu alia ludanto malka\u015Das la 2//suprajn kartojn de ties kartaro,//rubigas unu da ili kostantan//de [3] \u011Dis [6], kaj for\u0135etas la//ceteron. Se \u0109i tio rubigas//Kavaliron, rubigu \u0109i tion.";
EsperantoCardTexts[CardNames.SIR_DESTRY] = "|+2 Kartoj|//\u0108iu alia ludanto malka\u015Das//la 2 suprajn kartojn de ties//kartaro, rubigas unu da//ili kostantan de [3] \u011Dis [6],//kaj for\u0135etas la ceteron. Se//\u0109i tio rubigas Kavaliron,//rubigu \u0109i tion.";
EsperantoCardTexts[CardNames.SIR_MARTIN] = "|+2 A\u0109etoj|//\u0108iu alia ludanto malka\u015Das//la 2 suprajn kartojn de ties//kartaro, rubigas unu da//ili kostantan de [3] \u011Dis [6],//kaj for\u0135etas la ceteron. Se//\u0109i tio rubigas Kavaliron,//rubigu \u0109i tion.";
EsperantoCardTexts[CardNames.SIR_MICHAEL] = "\u0108iu alia ludanto for\u0135etas//\u011Dis tiu havas 3 kartojn mane.//\u0108iu alia ludanto malka\u015Das la 2//suprajn kartojn de ties kartaro,//rubigas unu da ili kostantan//de [3] \u011Dis [6], kaj for\u0135etas la//ceteron. Se \u0109i tio rubigas//Kavaliron, rubigu \u0109i tion.";
EsperantoCardTexts[CardNames.SIR_VANDER] = "\u0108iu alia ludanto malka\u015Das la 2//suprajn kartojn de ties kartaro,//rubigas unu da ili kostantan//de [3] \u011Dis [6], kaj for\u0135etas la//ceteron. Se \u0109i tio rubigas//Kavaliron, rubigu \u0109i tion.//---//Kiam vi rubigas \u0109i tion, gajnu Oron.";
EsperantoCardTexts[CardNames.SPOILS] = "[!3]////Kiam vi ludas \u0109i tion,//redonu \u011Din al \u011Dia stako.////%(\u0108i tio ne estas%//%en la Provizo.)%";
EsperantoCardTexts[CardNames.STOREROOM] = "|+1 A\u0109eto|////For\u0135etu iom ajn da kartoj,//poste tiru tiom da kartoj.//Poste for\u0135etu iom ajn da//kartoj por +[1] po \u0109iu.";
EsperantoCardTexts[CardNames.SQUIRE] = "|+[1]|////Elektu unu: |+2 Agoj|; //|+2 A\u0109etoj|; a\u016D gajnu//Ar\u011Denton.//---//Kiam vi rubigas \u0109i tion,//gajnu Atakkarton.";
EsperantoCardTexts[CardNames.SURVIVORS] = "Rigardu la 2 suprajn//kartojn de via kartaro.//For\u0135etu ilin a\u016D remetu//ilin en ajna ordo.";
EsperantoCardTexts[CardNames.URCHIN] = "|+1 Karto|//|+1 Ago|////\u0108iu alia ludanto for\u0135etu \u011Dis//tiu havas 4 kartojn mane.//---//Kiam vi ludas alian Atakkarton//kun \u0109i tion en ludo, vi povas unue//rubigu \u0109i tion, por gajni Soldulon.";
EsperantoCardTexts[CardNames.VAGRANT] = "|+1 Karto|//|+1 Ago|////Malka\u015Du la supran karton de via//kartaro. Se \u011Di estas Malbeno,//Ruino, \u015Cirmejo, a\u016D Venkkarto,//metu \u011Din en vian manon.";
EsperantoCardTexts[CardNames.WANDERING_MINSTREL] = "|+1 Karto|//|+2 Agoj|////Malka\u015Du la 3 suprajn kartojn//de via kartaro. Remetu la//Agkartojn en ajna ordo//kaj for\u0135etu la ceteron.";

EsperantoCardTexts[CardNames.ADVISOR] = "|+1 Ago|////Malka\u015Du la 3 suprajn kartojn//de via kartaro. La ludanto//maldekstren elektas unu el ili.//For\u0135etu tiun karton kaj metu//la ceteron en vian manon.";
EsperantoCardTexts[CardNames.BAKER] = "|+1 Karto|//|+1 Ago|//|+1 Monrezervo|//---//Agordo: \u0108iu ludanto ricevas//|+1 Monrezervo|.";
EsperantoCardTexts[CardNames.BUTCHER] = "|+2 Monrezervoj|////Vi povas rubigi karton el//via mano, por gajni karton//kostantan \u011Dis [1] pli ol \u011Di//po Monrezervo, kiun//vi elspezas.";
EsperantoCardTexts[CardNames.CANDLESTICK_MAKER] = "|+1 Ago|//|+1 A\u0109eto|//|+1 Monrezervo|";
EsperantoCardTexts[CardNames.DOCTOR] = "Nomumu karton. Malka\u015Du la 3//suprajn kartojn de via kartaro.//Rubigu la kongruojn. Remetu la//ceteron en ajna ordo.//---//|Tropago:| Po [1] tropagita,//rigardu la supran karton de via//kartaro; rubigu, for\u0135etu, a\u016D remetu \u011Din.";
EsperantoCardTexts[CardNames.HERALD] = "|+1 Karto|//|+1 Ago|//Malka\u015Du la supran karton de via//kartaro. Se \u011Di estas Agkarto, ludu \u011Din.//---//|Tropago:| Po [1] tropagita, metu//karton el via for\u0135eta stako//sur vian kartaron.";
EsperantoCardTexts[CardNames.JOURNEYMAN] = "Nomumu karton. Malka\u015Du//kartojn el via kartaro \u011Dis vi//malka\u015Das 3 kartojn sen tiu//nomo. Metu tiujn kartojn//en vian manon kaj for\u0135etas//la ceteron.";
EsperantoCardTexts[CardNames.MASTERPIECE] = "[!1]//---//|Tropago:| Po [1] tropagita,//gajnu Ar\u011Denton.";
EsperantoCardTexts[CardNames.MERCHANT_GUILD] = "|+1 A\u0109eto|//|+[1]|//Je la fino de via//A\u0109etfazo \u0109i-vice,//|+1 Monrezervo| po karto,//kiun vi gajnis en \u011Di.";
EsperantoCardTexts[CardNames.PLAZA] = "|+1 Karto|//|+2 Agoj|////Vi povas for\u0135eti//Trezorkarton por//|+1 Monrezervo|.";
EsperantoCardTexts[CardNames.TAXMAN] = "Vi povas rubigi Trezorkarton el//via mano. \u0108iu alia ludanto kun//5 a\u016D pli da kartoj mane for\u0135etas//kopion de \u011Di (a\u016D malka\u015Das ke tiu ne//povas). Gajnu sur vian kartaron//Trezorkarton kostantan \u011Dis [3]//pli ol la rubigita karto.";
EsperantoCardTexts[CardNames.SOOTHSAYER] = "Gajnu Oron. \u0108iu alia//ludanto gajnas Malbenon,//kaj se tiu faris tion,//tiras karton.";
EsperantoCardTexts[CardNames.STONEMASON] = "Rubigu karton el via mano.//Gajnu 2 kartojn, \u0109iu kostanta//malpli ol \u011Di.//---//|Tropago:| Gajnu 2 Agkartojn,//\u0109iu kostanta la kvanton,//kiun vi tropagis.";

EsperantoCardTexts[CardNames.ALMS] = "Unufoje po vico: Se vi ne havas Trezorkartojn//en ludo, gajnu karton kostantan \u011Dis [4].";
EsperantoCardTexts[CardNames.AMULET] = "Nun kaj je la komenco//de via sekva vico, elektu//unu: |+|[1]; a\u016D rubigu//karton el via mano;//a\u016D gajnu Ar\u011Denton.";
EsperantoCardTexts[CardNames.ARTIFICER] = "|+1 Karto|//|+1 Ago|//|+[1]|////For\u0135etu iom ajn da kartoj.//Vi povas gajni karton kostantan//precize [1] po for\u0135etita karto,//sur vian kartaron.";
EsperantoCardTexts[CardNames.BALL] = "Prenu vian -[1] jetonon. Gajnu 2 kartojn,//\u0109iu kostas \u011Dis [4].";
EsperantoCardTexts[CardNames.BONFIRE] = "Rubigu \u011Dis 2 Kuprojn, kiujn vi havas en ludo.";
EsperantoCardTexts[CardNames.BORROW] = "Unufoje po vico: |+1 A\u0109eto|. Se via -1 Karto jetono//ne estas sur via kartaro, metu \u011Din tie kaj |+|[1].";
EsperantoCardTexts[CardNames.BRIDGE_TROLL] = "\u0108iu alia ludanto prenas ties//-[1] jetonon. Dum \u0109i tiu kaj//via sekva vico, kartoj//kostas [1] malpli. Nun kaj je la//komenco de via sekva vico://|+1 A\u0109eto|.";
EsperantoCardTexts[CardNames.CARAVAN_GUARD] = "|+1 Karto|//|+1 Ago|////Je la komenco de via sekva vico, |+|[1].//---//Kiam alia ludanto ludas Atakkarton,//vi povas unue ludi \u0109i tion el via mano.//% (|+1 Ago| ne havas efikon se ne estas%//%via vico.)%";
EsperantoCardTexts[CardNames.CHAMPION] = "|+1 Ago|//Por la cetero de la ludo, kiam//alia ludanto ludas Atakkarton, \u011Di//ne tu\u015Das vin, kaj kiam vi ludas//Agkarton, |+1 Ago|.//%(\u0108i tio restas en ludo.%//%\u0108i tio ne estas en la Provizo.)%";
EsperantoCardTexts[CardNames.COIN_OF_THE_REALM] = "[!1]//Metu \u0109i tion sur vian//maton de Taverno.//---//Tuj post kiam vi ludas//Agkarton, vi povas alvoki//\u0109i tion, por |+2 Agoj|.";
EsperantoCardTexts[CardNames.DISCIPLE] = "Vi povas ludi Agkarton el via//mano dufoje. Gajnu kopion de \u011Di.//---//Kiam vi for\u0135etas \u0109i tion el//ludo, vi povas inter\u015Dan\u011Di//\u011Din por Instruisto.//%(\u0108i tio ne estas en la Provizo.)%";
EsperantoCardTexts[CardNames.DISTANT_LANDS] = "Metu \u0109i tion sur vian//maton de Taverno.//---//Valoras {4} se sur via mato//de Taverno je la fino de//la ludo (alie valoras {0}).";
EsperantoCardTexts[CardNames.DUNGEON] = "|+1 Ago|////Nun kaj je la comenco de//via sekva vico: |+2 Kartoj|,//poste for\u0135etu 2 kartojn.";
EsperantoCardTexts[CardNames.DUPLICATE] = "Metu \u0109i tion sur vian//maton de Taverno.//---//Kiam vi gajnas karton//kostantan \u011Dis [6], vi povas//alvoki \u0109i tion, por gajni//kopion de tiu karto.";
EsperantoCardTexts[CardNames.EXPEDITION] = "Tiru 2 ekstrajn kartojn por via sekva mano.";
EsperantoCardTexts[CardNames.FERRY] = "Movu via -[2] jetonon al Aga Provizstako.//(Kartoj el tiu stako kostas [2] malpli//en viaj vicoj.)";
EsperantoCardTexts[CardNames.FUGITIVE] = "|+2 Kartoj|//|+1 Ago|////For\u0135etu karton.//---//Kiam vi for\u0135etas \u0109i tion el ludo, vi//povas inter\u015Dan\u011Di \u011Din por Dis\u0109iplo.//%(\u0108i tio ne estas en la Provizo.)%";
EsperantoCardTexts[CardNames.GEAR] = "|+2 Kartoj|////Flankenmetu \u011Dis 2 kartojn//el via mano surventre (sub//\u0109i tio). Je la komenco de//via sekva vico, metu ilin//en vian manon.";
EsperantoCardTexts[CardNames.GIANT] = "Renversu vian Voja\u011Djetonon//(\u011Di startas surdorse). Poste se//\u011Di estas surventra, |+|[1]. Se \u011Di//estas surdorsa, |+|[5], kaj \u0109iu alia//ludanto malka\u015Das la supran karton//de ties kartaro, rubigu \u011Din se \u011Di//kostas de [3] \u011Dis [6], kaj alie//for\u0135etas \u011Din kaj gajnas Malbenon.";
EsperantoCardTexts[CardNames.GUIDE] = "|+1 Karto|//|+1 Ago|//Metu \u0109i tion sur vian//maton de Taverno.//---//Je la komenco de via vico, vi//povas alvoki \u0109i tion, por for\u0135eti//vian manon kaj tiri 5 kartojn.";
EsperantoCardTexts[CardNames.HAUNTED_WOODS] = "Je la komenco de via//sekva vico, |+3 Kartoj|.//\u011Cis tiam, kiam alia ludanto//gajnas karton, kiun tiu a\u0109etis,//tiu metas ties manon sur//ties kartaron en ajna ordo.//";
EsperantoCardTexts[CardNames.HERO] = "|+[2]|//Gajnu Trezorkarton.//---//Kiam vi for\u0135etas \u0109i tion el//ludo, vi povas inter\u015Dan\u011Di//\u011Din por \u0108ampiono.////%(\u0108i tio ne estas en la Provizo.)%";
EsperantoCardTexts[CardNames.HIRELING] = "Je la komenco de \u0109iuj viaj//vicoj dum la cetero de la ludo:////|+1 Karto|////%(\u0108i tio restas en ludo.)%";
EsperantoCardTexts[CardNames.INHERITANCE] = "Unufoje po ludo: Flankenmetu ne-Komandan Agkarton el la//Provizo kostantan \u011Dis [4]. Movu vian Bienjetonon al \u011Di.//(Dum viaj vicoj, Bienoj anka\u016D estas Agoj kun//\"Ludu la karton kun via Bienjetono, lasante \u011Din tie\".)";
EsperantoCardTexts[CardNames.LOST_ARTS] = "Movu vian +1 Ago jetonon al Aga Provizstako.//(Kiam vi ludas karton el tiu stako, vi unue//ricevas |+1 Ago|.)";
EsperantoCardTexts[CardNames.LOST_CITY] = "|+2 Kartoj|//|+2 Agoj|//---//Kiam vi gajnas \u0109i tion, \u0109iu//alia ludanto tiras karton.";
EsperantoCardTexts[CardNames.MAGPIE] = "|+1 Karto|//|+1 Ago|////Malka\u015Du la supran karton de via//kartaro. Se \u011Di estas Trezorkarto,//metu \u011Din en vian manon. Se \u011Di estas//Agkarto a\u016D Venkkarto, gajnu Pigon.";
EsperantoCardTexts[CardNames.MESSENGER] = "|+1 A\u0109eto|//|+[2]|//Vi povas meti vian kartaron en//vian for\u0135etan stakon.//---//Kiam \u0109i tio estas la unua karto,//kiun vi gajnas en via A\u0109etfazo,//gajnu karton kostantan \u011Dis [4], kaj//\u0109iu alia ludanto gajnas kopion de \u011Di.";
EsperantoCardTexts[CardNames.MISER] = "Elektu unu: Metu Kupron el//via mano sur vian maton de//Taverno; a\u016D |+|[1] po Kupro//sur via mato de Taverno.";
EsperantoCardTexts[CardNames.MISSION] = "Unufoje po vico: Se la anta\u016Da vico ne estas via,//ludu alian vicon post \u0109i tiu, dum kiu//vi ne povas a\u0109eti kartojn.";
EsperantoCardTexts[CardNames.PATHFINDING] = "Movu vian +1 Karto jetonon al Aga Provizstako.//(Kiam vi ludas karton el tiu stako, vi unue//ricevas |+1 Karto|.)";
EsperantoCardTexts[CardNames.PAGE] = "|+1 Karto|//|+1 Ago|//---//Kiam vi for\u0135etas \u0109i tion el ludo,//vi povas inter\u015Dan\u011Di \u011Din por//Trezorser\u0109anto.";
EsperantoCardTexts[CardNames.PEASANT] = "|+1 A\u0109eto|//---//|+[1]|////Kiam vi for\u0135etas \u0109i tion el ludo,//vi povas inter\u015Dan\u011Di \u011Din por//Soldato.";
EsperantoCardTexts[CardNames.PILGRIMAGE] = "Unufoje po vico: Renversu vian Voja\u011Djetonon (\u011Di//startas surdorse); poste se \u011Di estas surdorsa, elektu//\u011Dis 3 malsame nomatajn kartojn, kiujn vi havas//en ludo kaj gajnu kopion de \u0109iu.";
EsperantoCardTexts[CardNames.PLAN] = "Movu via Rubigjetonon al Aga Provizstako.//(Kiam vi gajntas karton el via stako, vi povas//rubigi karton el via mano.)";
EsperantoCardTexts[CardNames.PORT] = "|+1 Karto|//|+2 Agoj|//---//Kiam vi gajnas \u0109i tion,//gajnu alian Havenurbon//(kiu ne estas akompanata de alia).";
EsperantoCardTexts[CardNames.QUEST] = "Vi povas for\u0135eti Atakkarton, 2 Malbenojn,//a\u016D 6 kartojn. Se vi faras unu el tioj,//gajnu Oron.";
EsperantoCardTexts[CardNames.RANGER] = "|+1 A\u0109eto|////Renversu vian Voja\u011Djetonon//(\u011Di startas surdorse). Poste se//\u011Di estas surdorsa, |+5 Kartoj|.";
EsperantoCardTexts[CardNames.RAID] = "Gajnu Ar\u011Denton po Ar\u011Dento, kiun vi havas//en ludo. \u0108iu alia ludanto metas ties//-1 Karto jetonon sur ties kartaron.";
EsperantoCardTexts[CardNames.RATCATCHER] = "|+1 Karto|//|+1 Ago|//Metu \u0109i tion sur vian//maton de Taverno.//---//Je la komenco de via vico,//vi povas alvoki \u0109i tion, por//rubigi karton el via mano.";
EsperantoCardTexts[CardNames.RAZE] = "|+1 Ago|//Rubigu \u0109i tion a\u016D karton el//via mano. Rigardu unu karton//el la supro de via kartaro po//[1], kiun la rubigita karto//kostas. Metu unu el ili en vian//manon kaj for\u0135etu la ceteron.";
EsperantoCardTexts[CardNames.RELIC] = "[!2]////Kiam vi ludas \u0109i tion, \u0109iu alia//ludanto metas ties -1 Karto//jetonon sur ties kartaron.";
EsperantoCardTexts[CardNames.ROYAL_CARRIAGE] = "|+1 Ago|//Metu \u0109i tion sur vian//maton de Taverno.//---//Tuj post kiam vi ludas//Agkarton, se \u011Di estas da\u016Dre en//ludo, vi povas alvoki \u0109i tion,//por reludi tiun Agkarton.";
EsperantoCardTexts[CardNames.SAVE] = "Unufoje po vico: |+1 A\u0109eto|. Flankenmetu//karton el via mano, kaj remetu \u011Din en vian//manon je la fino de la vico (post tiro).";
EsperantoCardTexts[CardNames.SCOUTING_PARTY] = "|+1 A\u0109eto|////Rigardu la 5 suprajn kartojn de via kartaro.//For\u0135etu 3 kaj remetu la ceteron en ajna ordo.";
EsperantoCardTexts[CardNames.SEAWAY] = "Gajnu Agkarton kostantan \u011Dis [4]. Movu vian//+1 A\u0109eto jetonon al \u011Dia stako. (Kiam vi ludas//karton el tiu stako, vi unue ricevas |+1 A\u0109eto|.)";
EsperantoCardTexts[CardNames.SOLDIER] = "|+[1]|//|+|[2] po alia Atakkarto, kiun vi havas//en ludo. \u0108iu alia ludanto kun 4 a\u016D//pli da kartoj mane for\u0135etas karton.//---//Kiam vi for\u0135etas \u0109i tion el ludo, vi//povas inter\u015Dan\u011Di \u011Din por Fu\u011Danto.//%(\u0108i tio ne estas en la Provizo.)%";
EsperantoCardTexts[CardNames.STORYTELLER] = "|+1 Ago|////Ludu \u011Dis 3 Trezorkartojn el via//mano. Poste |+1 Karto|,//kaj pagu \u0109iom da via [] kaj//tiru karton po [1], kiun vi pagis.";
EsperantoCardTexts[CardNames.SWAMP_HAG] = "Je la komenco de via sekva//vico: |+[3]|.//\u011Cis tiam, kiam alia ludanto//gajnas karton, kiun tiu a\u0109etis,//tiu gajnas Malbenon. ";
EsperantoCardTexts[CardNames.TEACHER] = "Metu \u0109i tion sur vian//maton de Taverno.//---//Je la komenco de via vico, vi povas//alvoki \u0109i tion, por movi vian |+|1 Karto,//|+|1 Ago,|+|1 A\u0109eto, a\u016D |+|[1] jetonon al//Aga Provizstako, sur kiu vi ne havas//jetonon. (Kiam vi ludas karton el tiu//stako, vi unue ricevas tiun bonuson.)//%(\u0108i tio ne estas en la Provizo.)%";
EsperantoCardTexts[CardNames.TRAVELLING_FAIR] = "|+2 A\u0109etoj|////Kiam vi gajnas karton \u0109i-vice,//vi povas metu \u011Din sur vian kartaron.";
EsperantoCardTexts[CardNames.TRADE] = "Rubigu \u011Dis 2 kartojn el via mano.//Gajnu Ar\u011Denton po rubigita karto.";
EsperantoCardTexts[CardNames.TRAINING] = "Movu vian +[1] jetonon al Aga Provizstako.//(Kiam vi ludas karton el tiu stako, vi unue//ricevas +[1].)";
EsperantoCardTexts[CardNames.TRANSMOGRIFY] = "|+1 Ago|////Metu \u0109i tion sur vian//maton de Taverno.//---//Je la komenco de via vico, vi povas//alvoki \u0109i tion, por rubigi karton el//via mano, kaj gajni al via mano//karton kostantan \u011Dis [1] pli ol \u011Di.";
EsperantoCardTexts[CardNames.TREASURE_TROVE] = "[!2]////Kiam vi ludas \u0109i tion,//gajnu Oron kaj Kupron.";
EsperantoCardTexts[CardNames.TREASURE_HUNTER] = "|+1 Ago|//|+[1]|//Gajnu Ar\u011Denton po karto, kiun//la ludanto dekstren gajnis en//ties anta\u016Da vico.//---//Kiam vi for\u0135etas \u0109i tion el ludo, vi//povas inter\u015Dan\u011Di \u011Din por Batalanto.//%(\u0108i tio ne estas en la Provizo.)%";
EsperantoCardTexts[CardNames.WARRIOR] = "|+2 Kartoj|////Unufoje po Voja\u011Danto, kiun vi havas//en ludo (inkluzive \u0109i tion), \u0109iu alia//ludanto for\u0135etas la supran karton de//ties kartaro kaj rubigas \u0109i tion se \u011Di//kostas [3] a\u016D[4].//---//Kiam vi for\u0135etas \u0109i tion el ludo, vi//povas inter\u015Dan\u011Di \u011Din por Heroo.//%(\u0108i tio ne estas en la Provizo.)%";
EsperantoCardTexts[CardNames.WINE_MERCHANT] = "|+1 A\u0109eto|//|+[4]|//Metu \u0109i tion sur vian//maton de Taverno.//---//Je la fino de via A\u0109etfazo, se vi havas//almena\u016D[2] ne-elspezitan, vi povas//for\u0135eti \u0109i tion el via mato de Taverno.";

EsperantoCardTexts[CardNames.ENCAMPMENT] = "|+2 Kartoj|//|+2 Agoj|////Vi povas malka\u015Di Oron a\u016D //Priraba\u0135on el via mano. Se vi ne//faras tion, flankenmetu \u0109i tion,//kaj redonu \u011Din al la Provizo \u0109e//la komenco de Purigfazo.";
EsperantoCardTexts[CardNames.PLUNDER] = "{+1}//[!2]";
EsperantoCardTexts[CardNames.PATRICIAN] = "|+1 Karto|//|+1 Ago|//////Malka\u015Du la supran karton de//via kartaro. Se \u011Di kostas [5] a\u016D//pli, metu \u011Din en vian manon.";
EsperantoCardTexts[CardNames.EMPORIUM] = "|+1 Karto|//|+1 Ago|//|+[1]|//---//Kiam vi gajnas \u0109i tion,//se vi havas almena\u016D 5//Agkartojn en ludo, |+|{2}.";
EsperantoCardTexts[CardNames.SETTLERS] = "|+1 Karto|//|+1 Ago|//////Trarigardu vian for\u0135etan//stakon. Vi povas malka\u015Di//Kupron el \u011Di kaj metu//\u011Din en vian manon.";
EsperantoCardTexts[CardNames.BUSTLING_VILLAGE] = "|+1 Karto|//|+3 Agoj|//////Trarigardu vian for\u0135etan//stakon. Vi povas malka\u015Di//Setlantojn el \u011Di kaj metu//\u011Din en vian manon.";
EsperantoCardTexts[CardNames.CATAPULT] = "|+[1]|//Rubigu karton el via mano.//Se \u011Di kostas [3] a\u016D pli, \u0109iu alia//ludanto gajnas Malbenon. Se//\u011Di estas Trezorkarto, \u0109iu alia//ludanto for\u0135etas \u011Dis tiu havas//3 kartojn mane.";
EsperantoCardTexts[CardNames.ROCKS] = "|[1]|//---//Kiam vi gajnas a\u016D rubigas \u0109i//tion, gajnu Ar\u011Denton; se estas//via A\u0109etfazo, metu la Ar\u011Denton//sur vian kartaron, alie metu//\u011Din en vian manon.";
EsperantoCardTexts[CardNames.GLADIATOR] = "|+[2]|////Malka\u015Du karton el via mano.//La ludanto maldekstren//povas malka\u015Di kopion el ties//mano. Se tiu ne faras tion,//|+|[1] kaj rubigu Gladiatoron//el la Provizo.";
EsperantoCardTexts[CardNames.FORTUNE] = "|+1 A\u0109eto|//Kiam vi ludas \u0109i tion, duobligi//vian [] se vi ne ankora\u016D faris//tion \u0109i-vice.//---//Kiam vi gajnas \u0109i tion, gajnu//Oron po Gladiatoro, kiun vi//havas en ludo.";
EsperantoCardTexts[CardNames.CASTLES] = "Ordigu la Kastelstakon la\u016D//kosto, metante la pli multe-//kostajn Kastelojn sur la fundon.//Por 2-ludanta ludo, uzu nur unu//el \u0109iu Kastelo. Oni povas gajni//a\u016D a\u0109eti nur la supran Kastelon.";
EsperantoCardTexts[CardNames.HUMBLE_CASTLE] = "{!1}//---//Valoras [!1] po Kastelo,//kiun vi havas.";
EsperantoCardTexts[CardNames.CRUMBLING_CASTLE] = "{!1}//---//Kiam vi gajnas a\u016D//rubigas \u0109i tion, |+|{1}//kaj gajnu Ar\u011Denton.";
EsperantoCardTexts[CardNames.SMALL_CASTLE] = "{!2}//---//Rubigu \u0109i tion a\u016D Kastelon//el via mano. Se vi faras//tion, gajnu Kastelon.";
EsperantoCardTexts[CardNames.HAUNTED_CASTLE] = "|{2}|//---//Kiam vi gajnas \u0109i tion dum via//vico, gajnu Oron, kaj \u0109iu alia//ludanto kun 5 a\u016D pli da kartoj//mane metas 2 kartojn el ties//mano sur ties kartaron.";
EsperantoCardTexts[CardNames.OPULENT_CASTLE] = "For\u0135etu iom ajn da Venk-//kartoj, malka\u015Dante ilin. //|+|[!3] po for\u0135etita karto.//---//{!3}";
EsperantoCardTexts[CardNames.SPRAWLING_CASTLE] = "{!4}//---//Kiam vi gajnas \u0109i tion,//gajnu Duklandon a\u016D//3 Bienon.";
EsperantoCardTexts[CardNames.GRAND_CASTLE] = "{!5}//---//Kiam vi gajnas \u0109i tion,//malka\u015Du vian manon. |+|{1}//po Venkkarto, kiun vi//havas mane ka\u016D en ludo.";
EsperantoCardTexts[CardNames.KINGS_CASTLE] = "Valoras {2} po//Kastelo, kiun vi//havas.";
EsperantoCardTexts[CardNames.ADVANCE] = "Vi povas rubigi Agkarton el via mano. Se vi//faras tion, gajnu Agkarton kostantan \u011Dis [6].";
EsperantoCardTexts[CardNames.ANNEX] = "Miksu \u0109iujn krom \u011Dis 5 kartoj el via for\u0135eta//stako en vian kartaron. Gajnu Duklandon.";
EsperantoCardTexts[CardNames.ARCHIVE] = "|+1 Ago|////Flankenmetu la 3 suprajn//kartojn de via kartaro//surventre (vi povas rigardi//ilin). Nun kaj je la komenco//de viaj du sekvaj vicoj, metu//unu el ili en vian manon.";
EsperantoCardTexts[CardNames.AQUEDUCT] = "Kiam vi gajnas Trezorkarton, movu {} el \u011Dia stako al \u0109i tio.//Kiam vi gajnas Venkkarton, prenu la {8} el \u0109i tion.//---//Agordo: Metu {1} sur la stakojn de Ar\u011Dento kaj Oro.";
EsperantoCardTexts[CardNames.ARENA] = "Je la komenco de via A\u0109etofazo, vi povas for\u0135eti//Agkarton. Se vi faras tion, prenu {2} el \u0109i tie.//---//Agordo: Metu {6} \u0109i tie po ludanto.";
EsperantoCardTexts[CardNames.BANDIT_FORT] = "Dum poentkalkulo, {-2} po Ar\u011Dento a\u016D//Oro, kiun vi havas.";
EsperantoCardTexts[CardNames.BANQUET] = "Gajnu 2 Kuprojn kaj ne-Venkan karton//kostantan \u011Dis [5].";
EsperantoCardTexts[CardNames.BASILICA] = "Kiam vi gajnas karton, se vi havas restantan//[2] a\u016D pli, prenu {6} el \u0109i tie.//---//Agordo: Metu {2} \u0109i tie po ludanto.";
EsperantoCardTexts[CardNames.BATHS] = "Kiam vi finas vian vicon sen kartgajno,//prenu {2} el \u0109i tie.//---//Agordo: Metu {6} \u0109i tie po ludanto.";
EsperantoCardTexts[CardNames.BATTLEFIELD] = "Kiam vi gajnas Venkkarton, prenu {2} el \u0109i tie.//---//Agordo: Metu {6} \u0109i tie po ludanto.";
EsperantoCardTexts[CardNames.CAPITAL] = "[!6]//|+1 A\u0109eto|//---//Kiam vi for\u0135etas \u0109i tion//el ludo, prenu [6D], kaj//poste vi povas repagi [D].";
EsperantoCardTexts[CardNames.CHARM] = "Elektu unu: |+1 A\u0109eto|//kaj |+|[2]; a\u016D la sekvan//fojon vi gajnas karton//\u0109i-vice, vi povas anka\u016D//gajni malsame nomatan//karton kun la sama kosto.";
EsperantoCardTexts[CardNames.CHARIOT_RACE] = "|+1 Ago|////Malka\u015Du la supran karton de//via kartaro kaj metu \u011Din en//vian manon. La ludanto//maldekstren malka\u015Das la supran//karton de ties kartaro. Se via//karto kostas pli, |+|[1] kaj {+1}.";
EsperantoCardTexts[CardNames.CITY_QUARTER] = "|+2 Agoj|//////Malka\u015Du vian manon.//|+1 Karto| po malka\u015Dita//Agkarto.";
EsperantoCardTexts[CardNames.COLONNADE] = "Kiam vi gajnas Agkarton, se vi havas kopion de \u011Di//en ludo, prenu {2} el \u0109i tie.//---//Agordo: Metu {6} \u0109i tie po ludanto.";
EsperantoCardTexts[CardNames.CONQUEST] = "Gajnu 2 Ar\u011Dentojn.//{+1} po Ar\u011Dento, kiun vi gajnis \u0109i-vice.";
EsperantoCardTexts[CardNames.CROWN] = "Se estas via Agfazo, vi//povas ludi Agkarton el//via mano dufoje.////Se estas via A\u0109etfazo, vi//povas ludi Trezorkarton el//via mano dufoje.";
EsperantoCardTexts[CardNames.DELVE] = "|+1 A\u0109eto|//Gajnu Ar\u011Denton.";
EsperantoCardTexts[CardNames.DEFILED_SHRINE] = "Kiam vi gajnas Agkarton, movu {} el \u011Dia stako al \u0109i tio.//Kiam vi gajnas Malbenon en via A\u0109etfazo, prenu la {2} el \u0109i tio.//---//Agordo: Metu {1} sur \u0109iu ne-Kolektadan Agan Provizstakon.";
EsperantoCardTexts[CardNames.DOMINATE] = "Gajnu Provincon. Se vi faras tion,{+9}.";
EsperantoCardTexts[CardNames.DONATE] = "Tuj anta\u016D vi tiras vian sekvan manon, metu vian kartaron kaj//for\u0135etan stakon en vian manon, rubigu iom ajn da ili,//poste miksu vian manon en vian kartaron.";
EsperantoCardTexts[CardNames.ENCHANTRESS] = "\u011Cis via sekva vico, kiam//\u0109iu alia ludanto unue ludas//Agkarton en ties vico, tiu//ricevas |+1 Karto| kaj |+1 Ago|//anstata\u016D sekvi \u011Diajn instruk-//ciojn. Je la komenco//de via sekva vico,//|+2 Kartoj|";
EsperantoCardTexts[CardNames.ENGINEER] = "Gajnu karton kostantan \u011Dis//[4]. Vi povas rubigi \u0109i tion.//Se vi faras tion, gajnu//karton kostantan \u011Dis [4].";
EsperantoCardTexts[CardNames.FARMERS_MARKET] = "|+1 A\u0109eto|////Se estas {4} a\u016D pli sur la//Provizstako de Kampara//Bazaro, prenu ilin kaj rubigas//\u0109i tion. Alie, aldonu {1} al//la stako kaj poste |+|[1] po {1}//sur la stako.";
EsperantoCardTexts[CardNames.FORUM] = "|+3 Kartoj|//|+1 Ago|////For\u0135etu 2 kartojn.//---//Kiam vi gajnas \u0109i tion,//|+1 A\u0109eto|.";
EsperantoCardTexts[CardNames.FOUNTAIN] = "Dum poentkalkulo, {15} se vi havas//almena\u016D 10 Kuprojn.";
EsperantoCardTexts[CardNames.GROUNDSKEEPER] = "|+1 Karto|//|+1 Ago|//\u0108i-vice, kiam vi gajnas//Venkkarton, |+|{1}.";
EsperantoCardTexts[CardNames.KEEP] = "Dum poentkalkulo, {5} po malsame nomata//Trezorkarto, de kiu vi havas pli multajn kopiojn//ol \u0109iu alia ludanto, a\u016D egalvenke plejmultajn.";
EsperantoCardTexts[CardNames.LABYRINTH] = "Kiam vi gajnas duan karton en unu el//viaj vicoj, prenu {2} el \u0109i tie.//---//Agordo: Metu {6} \u0109i tie po ludanto.";
EsperantoCardTexts[CardNames.LEGIONARY] = "|+|[3]////Vi povas malka\u015Di Oron el//via mano. Se vi faras tion,//\u0109iu alia ludanto for\u0135etas \u011Dis//tiu havas 2 kartojn mane,//poste tiras karton.";
EsperantoCardTexts[CardNames.MOUNTAIN_PASS] = "Kiam vi estas la unua ludanto, kiu gajnas Provincon,//\u0109iu ludanto proponas unufoje \u011Dis [40D], finante kun vi.//Alta proponanto ricevas {+8} kaj prenas la [D], kiun tiu proponis.";
EsperantoCardTexts[CardNames.MUSEUM] = "Dum poentkalkulo, {2} po malsame nomata//karton, kiun vi havas.";
EsperantoCardTexts[CardNames.OBELISK] = "Dum poentkalkulo, {2} po karto, kiun vi//havas el la elektita stako.//---//Agordo: Elektu hazardan Agan Provizstakon.";
EsperantoCardTexts[CardNames.ORCHARD] = "Dum poentkalkulo, {4} po malsame//nomata Agkarto, de kiu vi havas//almena\u016D 3 kopiojn.";
EsperantoCardTexts[CardNames.OVERLORD] = "Ludu ne-Komandan//Agkarton el la Provizo//kostantan \u011Dis [5],//lasante \u011Din tie.";
EsperantoCardTexts[CardNames.PALACE] = "Dum poentkalkulo, {3} po aro, kiun vi//havas de Kupro - Ar\u011Dento - Oro.";
EsperantoCardTexts[CardNames.RITUAL] = "Gajnu Malbenon. Se vi faras tion, rubigu karton//el via mano. |+|{1} po [1], kiun \u011Di kostis.";
EsperantoCardTexts[CardNames.ROYAL_BLACKSMITH] = "|+5 Kartoj|////Malka\u015Du vian manon;//for\u0135etu la Kuprojn.";
EsperantoCardTexts[CardNames.SACRIFICE] = "Rubigu karton el via mano.//Se \u011Di estas...////Agkarto, |+2 Kartoj, +2 Agoj|//Trezorkarto, |+|{2}//Venkkarto, |+|[2]";
EsperantoCardTexts[CardNames.SALT_THE_EARTH] = "{+1}//Rubigu Venkkarton el la Provizo.";
EsperantoCardTexts[CardNames.TAX] = "Aldonu [2D] al Provizstako.//---//Agordo: Aldonu [1D] al \u0109iu Provizstako. Kiam ludanto//gajnas karton en ties A\u0109etfazo, tiu prenas la [D] el \u011Dia stako.";
EsperantoCardTexts[CardNames.TEMPLE] = "|+|{1}//Rubigu de 1 \u011Dis 3 malsame//nomatajn kartojn el via mano.//Aldonu {1} al la Provizstako//de Templo.//---//Kiam vi gajnas \u0109i tion, prenas la//{+1} el la Provizstako de Templo.";
EsperantoCardTexts[CardNames.TOMB] = "Kiam vi rubigas karton, |+|{1}.";
EsperantoCardTexts[CardNames.TOWER] = "Dum poentkalkulo, {1} po ne-Venka karto,//kiun vi havas el malplena Provizstako.";
EsperantoCardTexts[CardNames.TRIUMPH] = "Gajnu Bienon. Se vi faris tion,//|+|{1} po karto, kiun vi gajnis \u0109i-vice.";
EsperantoCardTexts[CardNames.TRIUMPHAL_ARCH] = "Dum poentkalkulo, {3} po kopio, kiun vi havas//de la due plej multa Agkarto inter viaj kartoj//(se estas egalvenko, nombru ajnon).";
EsperantoCardTexts[CardNames.VILLA] = "|+2 Agoj|//|+1 A\u0109eto|//---//|+[1]|////Kiam vi gajnas \u0109i tion, metu \u011Din en//vian manon, |+1 Ago|, kaj se estas//via A\u0109etfazo, reiru al via Agfazo.";
EsperantoCardTexts[CardNames.WALL] = "Dum poentkalkulo, {-1} po karto, kiun//vi havas post la unua 15.";
EsperantoCardTexts[CardNames.WOLF_DEN] = "Dum poentkalkulo, {-3} po karto, de kiu//vi havas precize unu kopion.";
EsperantoCardTexts[CardNames.WEDDING] = "|+{1}|//Gajnu Oron.";
EsperantoCardTexts[CardNames.WILD_HUNT] = "Elektu unu: |+3 Kartoj| kaj//aldonu {1} al la Provizstako//de Sova\u011Da \u0108asado; a\u016D gajnu//Bienon, kaj se vi faras tion,//prenu la {} el la stako.";
EsperantoCardTexts[CardNames.WINDFALL] = "Se via kartaro kaj for\u0135eta stako estas//malplena, gajnu 3 Orojn.";
EsperantoCardTexts[CardNames.PIG] = "PIG!,";
EsperantoCardTexts[CardNames.MINUS_CARD] = "";
EsperantoCardTexts[CardNames.MINUS_COIN] = "";

EsperantoCardTexts[CardNames.STATE_LIMBO] = "";
EsperantoCardTexts[CardNames.DRUID_BOONS] = "";
EsperantoCardTexts[CardNames.BOON_DRAWPILE] = "";
EsperantoCardTexts[CardNames.BOON_DISCARDPILE] = "";
EsperantoCardTexts[CardNames.THE_EARTHS_GIFT] = "Vi povas for\u0135eti Trezorkarton por gajni//karton kostantan \u011Dis [4].";
EsperantoCardTexts[CardNames.THE_FIELDS_GIFT] = "|+1 Ago|//|+[1]|////(Gardu \u0109i tion \u011Dis Purigfazo.)";
EsperantoCardTexts[CardNames.THE_FLAMES_GIFT] = "Vi povas rubigi karton el via mano.";
EsperantoCardTexts[CardNames.THE_FORESTS_GIFT] = "|+1 A\u0109eto|//|+[1]|////(Gardu \u0109i tion \u011Dis Purigfazo.)";
EsperantoCardTexts[CardNames.THE_MOONS_GIFT] = "Trarigardu vian for\u0135etan stakon.//Vi povas meti karton el \u011Di sur vian kartaron.";
EsperantoCardTexts[CardNames.THE_MOUNTAINS_GIFT] = "Gajnu Ar\u011Denton.";
EsperantoCardTexts[CardNames.THE_RIVERS_GIFT] = "|+1 Karto| je la fino de \u0109i tiu vico.//(Gardu \u0109i tion \u011Dis Purigfazo.)";
EsperantoCardTexts[CardNames.THE_SEAS_GIFT] = "|+1 Karto|";
EsperantoCardTexts[CardNames.THE_SKYS_GIFT] = "Vi povas for\u0135eti 3 kartojn por gajni Oron.";
EsperantoCardTexts[CardNames.THE_SUNS_GIFT] = "Rigardu la 4 suprajn kartojn de via//kartaro. For\u0135etu iom ajn da ili kaj//remetu la ceteron en ajna ordo.";
EsperantoCardTexts[CardNames.THE_SWAMPS_GIFT] = "Gajnu Vaglumon.";
EsperantoCardTexts[CardNames.THE_WINDS_GIFT] = "|+2 Kartoj|//For\u0135etu 2 kartojn.";
EsperantoCardTexts[CardNames.HEX_DRAWPILE] = "";
EsperantoCardTexts[CardNames.HEX_DISCARDPILE] = "";
EsperantoCardTexts[CardNames.BAD_OMENS] = "Metu vian kartaron en vian for\u0135etan stakon.//Trarigardu \u011Din kaj metu 2 Kuprojn el \u011Di sur//vian kartaron (a\u016D malka\u015Du ke vi ne povas).";
EsperantoCardTexts[CardNames.DELUSION] = "Se vi ne havas Trompitan a\u016D Envieman,//prenu Trompitan.";
EsperantoCardTexts[CardNames.ENVY] = "Se vi ne havas Trompitan a\u016D Envieman,//prenu Envieman.";
EsperantoCardTexts[CardNames.FAMINE] = "Malka\u015Du la 3 suprajn kartojn de via//kartaro. For\u0135etu la Agkartojn. Miksu//la ceteron en vian kartaron.";
EsperantoCardTexts[CardNames.FEAR] = "Se vi havas almena\u016D 5 kartojn mane,//for\u0135etu Agkarton a\u016D Trezorkarton//(a\u016D malka\u015Du ke vi ne povas).";
EsperantoCardTexts[CardNames.GREED] = "Gajnu Kupron sur vian kartaron.";
EsperantoCardTexts[CardNames.HAUNTING] = "Se vi havas almena\u016D 4 kartojn mane,//metu unu el ili sur vian kartaron.";
EsperantoCardTexts[CardNames.LOCUSTS] = "Rubigu la supran karton de via kartaro.//Se \u011Di estas Kupro a\u016D Bieno, gajnu//Malbenon. Alie, gajnu pli malmultekostan//karton, kiu kunhavas specon kun \u011Di.";
EsperantoCardTexts[CardNames.MISERY] = "Se \u0109i tio estas via unua Mizero \u0109i-lude,//prenu Mizeran. Alie, renversu \u011Din al//Duoble Mizera.";
EsperantoCardTexts[CardNames.PLAGUE] = "Gajnu Malbenon al via mano.";
EsperantoCardTexts[CardNames.POVERTY] = "For\u0135etu \u011Dis vi havas 3 kartojn mane.";
EsperantoCardTexts[CardNames.WAR] = "Malka\u015Du kartojn el via kartaro \u011Dis vi//malka\u015Das unu kostantan [3] a\u016D[4].//Rubigu \u011Din kaj for\u0135etu la ceteron.";
EsperantoCardTexts[CardNames.MISERABLE] = "|{-2}|";
EsperantoCardTexts[CardNames.TWICE_MISERABLE] = "|{-4}|";
EsperantoCardTexts[CardNames.ENVIOUS] = "Je la komenco de via A\u0109etfazo, redonu \u0109i//tion, kaj Ar\u011Dento kaj Oro faras [1]\u0109i-vice.";
EsperantoCardTexts[CardNames.DELUDED] = "Je la komenco de via A\u0109etfazo, redonu \u0109i//tion, kaj vi ne povas a\u0109eti Agkartojn \u0109i-vice.";
EsperantoCardTexts[CardNames.LOST_IN_THE_WOODS] = "Je la komenco de via vico, vi povas//for\u0135eti karton por ricevi Benon.";
EsperantoCardTexts[CardNames.BARD] = "|+|[2]//Ricevas Benon.";
EsperantoCardTexts[CardNames.BLESSED_VILLAGE] = "|+1 Karto|//|+2 Agoj|//---//Kiam vi gajnas \u0109i tion, prenu//Benon. Ricevu \u011Din nun a\u016D je la//komenco de via sekva vico.";
EsperantoCardTexts[CardNames.CHANGELING] = "Rubigu \u0109i tion. Gajnu kopion//de karto, kiun vi havas en ludo.//---//En ludoj, kiuj uzas \u0109i tion, kiam vi//gajnas karton kostantan [3] a\u016D pli,//vi povas inter\u015Dan\u011Di \u011Din por//Anstata\u016Dinfano.";
EsperantoCardTexts[CardNames.CEMETERY] = "{!2}//---//Kiam vi gajnas \u0109i tion, rubigu//\u011Dis 4 kartojn el via mano.////%%(Hereda\u0135o: Hantata Spegulo)%%";
EsperantoCardTexts[CardNames.COBBLER] = "Je le komenco de via sekva//vico, gajnu al via mano//karton kostantan \u011Dis [4].";
EsperantoCardTexts[CardNames.CONCLAVE] = "|+[2]|////Vi povas ludi Agkarton el//via mano, de kiu vi ne//havas kopion en ludo. Se//vi faras tion, |+1 Ago.|";
EsperantoCardTexts[CardNames.CRYPT] = "Flankenmetu surventre iom//ajn da ne-Da\u016Draj Trezorkartoj,//kiujn vi havas en ludo//(sub \u0109i tio). Dum iom ajn//restas, je la komenco de//\u0109iuj viaj vicoj, metu unu//el ili en vian manon.";
EsperantoCardTexts[CardNames.CURSED_VILLAGE] = "|+2 Agoj|////Tiras \u011Dis vi havas 6//kartojn mane.//---//Kiam vi gajnas \u0109i tion,//ricevu Envulton.";
EsperantoCardTexts[CardNames.DEN_OF_SIN] = "Je la komenco de//via sekva vico://|+2 Kartoj|.//---//\u0108i tio estas gajnita al//via mano (anstata\u016D al//via for\u0135eta stako).";
EsperantoCardTexts[CardNames.DEVILS_WORKSHOP] = "Se la nombro da kartoj, kiujn//vi gajnis \u0109i-vice estas://|2+: |gajnu Diableton;//|1: |gajnu karton kostantan//\u011Dis [4];//|0: |gajnu Oron.";
EsperantoCardTexts[CardNames.DRUID] = "|+1 A\u0109eto|//Ricevu unu el la//flankenmetitaj Benoj//(lasante \u011Din tie).//---//Agordo: Flankenmetu la 3//suprajn Benojn surdorse.";
EsperantoCardTexts[CardNames.EXORCIST] = "Rubigu karton el via mano.//Gajnu pli malmultekostan//Spiriton el unu el la//Spiritstakoj.";
EsperantoCardTexts[CardNames.FAITHFUL_HOUND] = "|+2 Kartoj|//---//Kiam vi for\u0135etas \u0109i tion//krom en Purigfazo, vi povas//flankenmeti \u011Din, poste//metu \u011Din en vian manon//je la fino de la vico.";
EsperantoCardTexts[CardNames.FOOL] = "Se vi ne estas la ludanto//kun Perdita en la Arbaro,//prenu \u011Din, prenu 3 Benojn,//kaj ricevas la Benojn en//ajna ordo.////%%(Hereda\u0135o: Bon\u015Danca Monero)%%";
EsperantoCardTexts[CardNames.GHOST_TOWN] = "Je la komenco de//via sekva vico://|+1 Karto |kaj |+1 Ago|.//---//\u0108i tio estas gajnita al//via mano (anstata\u016D al//via for\u0135eta stako.";
EsperantoCardTexts[CardNames.GUARDIAN] = "\u011Cis via sekva vico, kiam//alia ludanto ludas Atakkarton,//\u011Di ne tu\u015Das vin. Je la komenco//de via sekva vico, |+|[1].//---//\u0108i tio estas gajnita al via mano//(anstata\u016D al via for\u0135eta stako).";
EsperantoCardTexts[CardNames.IDOL] = "[!2]////Se vi havas neparan nombron//da Idoloj en ludo, ricevas//Benon; se paran nombron, \u0109iu//alia ludanto gajnas Malbenon.";
EsperantoCardTexts[CardNames.LEPRECHAUN] = "Gajnu Oron. Se vi havas//precize 7 kartojn en ludo,//gajnu Deziron. Alie,//ricevas Envulton.";
EsperantoCardTexts[CardNames.MONASTERY] = "Po karto, kiun vi gajnis//\u0109i-vice, vi povas rubigi//karton el via mano a\u016D//Kupron, kiun vi havas//en ludo.";
EsperantoCardTexts[CardNames.NECROMANCER] = "Ludu surdorsan ne-Da\u016Dran//Agkarton el la Rubujo,//lasante \u011Din tie kaj renversante//\u011Din surventre por la vico.//---//Agordo: Metu la 3 Zombiojn//en la Rubujo.";
EsperantoCardTexts[CardNames.NIGHT_WATCHMAN] = "Rigardu la 5 suprajn//kartojn de via kartaro,//for\u0135etu iom ajn, kaj remetu//la ceteron en ajna ordo.//---//\u0108i tio estas gajnita al//via mano (anstata\u016D al//via for\u0135eta stako.";
EsperantoCardTexts[CardNames.PIXIE] = "|+1 Karto|//|+1 Ago|////For\u0135etu la supran Benon.//Vi povas rubigi \u0109i tion por//ricevi tiun Benon dufoje.////%%(Hereda\u0135o: Kapro)%%";
EsperantoCardTexts[CardNames.POOKA] = "Vi povas rubigi Trezorkarton //krom Malbenita Oro el via//mano, por |+4 Kartoj|.////%%(Hereda\u0135o: Malbenita Oro)%%";
EsperantoCardTexts[CardNames.RAIDER] = "\u0108iu alia ludanto kun 5 a\u016D//pli da kartoj mane for\u0135etas//kopion de karto, kiun vi havas//en ludo (a\u016D malka\u015Das ke tiu//ne povas). Je la komenco//de via sekva vico,//|+|[3].";
EsperantoCardTexts[CardNames.SACRED_GROVE] = "|+1 A\u0109eto|//|+[3]|////Ricevas Benon. Se \u011Di ne//donas +[1], \u0109iu alia ludanto//povas ricevi \u011Din.";
EsperantoCardTexts[CardNames.SECRET_CAVE] = "|+1 Karto|//|+1 Ago|////Vi povas for\u0135eti 3 kartojn.//Se vi faras tion, je la komenco//de via sekva vico, +[3].////%%(Hereda\u0135o: Sor\u0109a Lampo)%%";
EsperantoCardTexts[CardNames.SHEPHERD] = "|+1 Ago|////For\u0135etu iom ajn da//Venkkartoj, malka\u015Dante ilin.//|+2 Kartoj| po for\u0135etita karto.////%%(Hereda\u0135o: Pa\u015Dtejo)%%";
EsperantoCardTexts[CardNames.SKULK] = "|+1 A\u0109eto|////\u0108iu alia ludanto ricevas//la sekvan Envulton.//---//Kiam vi gajnas \u0109i tion,//gajnu Oron.";
EsperantoCardTexts[CardNames.TORMENTOR] = "|+|[2]////Se vi ne havas aliajn kartojn//en ludo, gajnu Diableton.//Alie, \u0109iu alia ludanto//ricevas la sekvan Envulton.";
EsperantoCardTexts[CardNames.TRAGIC_HERO] = "|+3 Kartoj|//|+1 A\u0109eto|//////Se vi havas 8 a\u016D pli da kartoj//mane (post tiro), rubigu \u0109i//tion kaj gajnu Trezorkarton.";
EsperantoCardTexts[CardNames.TRACKER] = "|+[1]|////\u0108i-vice, kiam vi gajnas//karton, vi povas metu \u011Din//sur vian kartaron.//Ricevas Benon.////%%(Hereda\u0135o: Saketo)%%";
EsperantoCardTexts[CardNames.VAMPIRE] = "\u0108iu alia ludanto ricevas la//sekvan Envulton. Gajnu//karton kostantan \u011Dis [5]//krom Vampiro. Inter\u015Dan\u011Du//\u0109i tion por Vesperto.";
EsperantoCardTexts[CardNames.WEREWOLF] = "Se estas via Noktofazo,//\u0109iu alia ludanto ricevas//la sekvan Envulton.//Alie, |+3 Kartoj|.";
EsperantoCardTexts[CardNames.CURSED_GOLD] = "[!3]////Gajnu Malbenon.";
EsperantoCardTexts[CardNames.GOAT] = "[!1]////Vi povas rubigi karton//el via mano.";
EsperantoCardTexts[CardNames.HAUNTED_MIRROR] = "[!1]//---//Kiam vi rubigas \u0109i tion, vi//povas for\u0135eti Agkarton, por//gajni Fantomon.";
EsperantoCardTexts[CardNames.LUCKY_COIN] = "[!1]////Gajnu Ar\u011Denton.";
EsperantoCardTexts[CardNames.MAGIC_LAMP] = "[!1]////Se estas almena\u016D 6 kartoj, de//kiuj vi havas precize 1 kopion//en ludo, rubigu \u0109i tion.//Se vi faras tion, gajnu//3 Deziron.";
EsperantoCardTexts[CardNames.PASTURE] = "[!1]//---//Valoras {1} po Bieno,//kiun vi havas.";
EsperantoCardTexts[CardNames.POUCH] = "[!1]////|+1 A\u0109eto|";
EsperantoCardTexts[CardNames.BAT] = "Rubigu \u011Dis 2 kartojn el//via mano. Se vi rubigis//almena\u016D unu, inter\u015Dan\u011Du//\u0109i tion por Vampiro.//%(\u0108i tio ne estas%//%en la Provizo.)%";
EsperantoCardTexts[CardNames.GHOST] = "Malka\u015Du kartojn el via kartaro \u011Dis//vi malka\u015Das Agkarton. For\u0135etu la//aliajn kartojn kaj flankenmetu//la Agkarton. Je la komenco de//via sekva vico, ludu \u011Din dufoje.////%(\u0108i tio ne estas en la Provizo.)%";
EsperantoCardTexts[CardNames.IMP] = "|+2 Kartoj|////Vi povas ludi Agkarton el//via mano, de kiu vi ne//havas kopion en ludo.////%(\u0108i tio ne estas%//%en la Provizo.)%";
EsperantoCardTexts[CardNames.WILL_O_WISP] = "|+1 Karto|//|+1 Ago|////Malka\u015Du la supran karton de//via kartaro. Se \u011Di kostas [2] a\u016D//malpli, metu \u011Din en vian manon.////%(\u0108i tio ne estas en la Provizo.)%";
EsperantoCardTexts[CardNames.WISH] = "|+1 Ago|//Redonu \u0109i tion al \u011Dia//stako. Se vi faris tion,//gajnu al via mano//karton kostantan \u011Dis [6].////%(\u0108i tio ne estas%//%en la Provizo.)%";
EsperantoCardTexts[CardNames.ZOMBIE_APPRENTICE] = "Vi povas rubigi Agkarton//el via mano por//|+3 Kartoj |kaj |+1 Ago|.";
EsperantoCardTexts[CardNames.ZOMBIE_MASON] = "Rubigu la supran karton//de via kartaro. Vi povas//gajni karton kostantan//\u011Dis [1] pli ol \u011Di.";
EsperantoCardTexts[CardNames.ZOMBIE_SPY] = "|+1 Karto|//|+1 Ago|////Rigardu la supran karton//de via kartaro. For\u0135etu//\u011Din a\u016D remetu \u011Din.";

EsperantoCardTexts[CardNames.ACTING_TROUPE] = "|+4 Vila\u011Danoj|//Rubigu \u0109i tion.";
EsperantoCardTexts[CardNames.BORDER_GUARD] = "|+1 Ago|////Malka\u015Du la 2 suprajn kartojn de//via kartaro. Metu unu en vian//manon kaj for\u0135etu la alian. Se//amba\u016D estis Agkartoj, prenu//la Lanternon a\u016D la Kornon.";
EsperantoCardTexts[CardNames.CARGO_SHIP] = "|+[2]|////Unufoje \u0109i-vice, kiam vi//gajnas karton, vi povas//flankenmeti \u011Din surdorse//(sur \u0109i tio). Je la komenco//de via sekva vico, metu//\u011Din en vian manon.";
EsperantoCardTexts[CardNames.DUCAT] = "|+1 Monrezervo|//|+1 A\u0109eto|//---//Kiam vi gajnas \u0109i tion,//vi povas rubigi Kupron//el via mano.";
EsperantoCardTexts[CardNames.EXPERIMENT] = "|+2 Kartoj|//|+1 Ago|//Redonu \u0109i tion al la Provizo.//---//Kiam vi gajnas \u0109i tion, gajnu//alian Eksperimenton (kiu ne//estas akompanata de alia).";
EsperantoCardTexts[CardNames.FLAG_BEARER] = "|+[2]|//---//Kiam vi gajnas a\u016D rubigas//\u0109i tion, prenu la Flagon.";
EsperantoCardTexts[CardNames.HIDEOUT] = "|+1 Karto|//|+2 Agoj|////Rubigu karton el via mano.//Se \u011Di estas Venkkarto,//gajnu Malbenon.";
EsperantoCardTexts[CardNames.INVENTOR] = "Gajnu karton kostantan//\u011Dis [4], poste kartoj//kostas [1] malpli \u0109i-vice.";
EsperantoCardTexts[CardNames.IMPROVE] = "|+[2]|////Je la komenco de Purigfazo,//vi povas rubigi Agkarton,//kiun vi for\u0135etus el ludo//\u0109i-vice, por gajni karton//kostantan precize [1] pli ol \u011Di.";
EsperantoCardTexts[CardNames.LACKEYS] = "|+2 Kartoj|//---//Kiam vi gajnas \u0109i tion,//|+2 Vila\u011Danoj|.";
EsperantoCardTexts[CardNames.MOUNTAIN_VILLAGE] = "|+2 Agoj|////Metu karton el via for\u0135eta//stako en vian manon; se//vi ne povas, |+1 Karto|.";
EsperantoCardTexts[CardNames.PATRON] = "|+1 Vila\u011Dano|//|+[2]|//---//Kiam io ka\u016Dzas ke vi//malka\u015Das \u0109i tion (uzante//la vorto \"malka\u015Di\") en//Agfazo, |+1 Monrezervo|.";
EsperantoCardTexts[CardNames.PRIEST] = "|+[2]|////Rubigu karton el via//mano. Por la cetero de \u0109i//tiu vico, kiam vi rubigas//karton, |+|[2].";
EsperantoCardTexts[CardNames.RESEARCH] = "|+1 Ago|////Rubigu karton el via mano. Po//[1], kiun \u011Di kostas, flankenmetu//karton el via kartaro surventre//(sur \u0109i tio). Je la komenco de via//sekva vico, metu tiujn kartojn//en vian manon.";
EsperantoCardTexts[CardNames.SILK_MERCHANT] = "|+2 Kartoj|//|+1 A\u0109eto|//---//Kiam vi gajnas a\u016D rubigas//\u0109i tion,|+1 Monrezervo|//kaj  |+1 Vila\u011Dano|.";
EsperantoCardTexts[CardNames.OLD_WITCH] = "|+3 Kartoj|////\u0108iu alia ludanto gajnas//Malbenon kaj povas rubigi //Malbenon el ties mano.";
EsperantoCardTexts[CardNames.RECRUITER] = "|+2 Kartoj|////Rubigu karton el via mano.//|+1 Vila\u011Dano| po [1], kiun//\u011Di kostas.";
EsperantoCardTexts[CardNames.SCEPTER] = "Elektu unu: |+|[2]; a\u016D//reludu Agkarton, kiun vi//ludis \u0109i-vice, kiu estas//da\u016Dre en ludo.";
EsperantoCardTexts[CardNames.SCHOLAR] = "For\u0135etu vian manon.//|+7 Kartoj|.";
EsperantoCardTexts[CardNames.SCULPTOR] = "Gajnu al via mano//karton kostantan \u011Dis [4].//Se \u011Di estas Trezoro,//|+1 Vila\u011Dano|.";
EsperantoCardTexts[CardNames.SEER] = "|+1 Karto|//|+1 Ago|////Malka\u015Du la 3 suprajn kartojn//de via kartaro. Metu tiujn,//kiuj kostas de [2] \u011Dis [4]//en vian manon. Remetu//la ceteron en ajna ordo.";
EsperantoCardTexts[CardNames.SPICES] = "[2]////|+1 A\u0109eto|//---//Kiam vi gajnas \u0109i tion,//|+2 Monrezervoj|.";
EsperantoCardTexts[CardNames.SWASHBUCKLER] = "|+3 Kartoj|////Se via for\u0135eta stako//enhavas iom ajn da//kartoj: |+1 Monrezervo|,//poste se vi havas//almena\u016D 4 Monrezerojn,//prenu la Trezorkeston.";
EsperantoCardTexts[CardNames.TREASURER] = "|+[3]|////Elektu unu: Rubigu//Trezorkarton el via mano;//a\u016D gajnu Trezorkarton el//la Rubujo al via mano;//a\u016D prenu la \u015Closilon.";
EsperantoCardTexts[CardNames.VILLAIN] = "|+2 Monrezervoj|////\u0108iu alia ludanto kun 5//a\u016D pli da kartoj mane//for\u0135etas unu kostantan//[2] a\u016D pli (a\u016D malka\u015Das//ke tiu ne povas).";
EsperantoCardTexts[CardNames.FLAG] = "Kiam vi tiras vian manon, |+1 Karto|.";
EsperantoCardTexts[CardNames.HORN] = "Unufoje po vico, kiam vi for\u0135etas//Limgardiston el ludo, vi povas meti//\u011Din sur vian kartaron.";
EsperantoCardTexts[CardNames.KEY] = "Je la komenco de via vico, |+|[1].";
EsperantoCardTexts[CardNames.LANTERN] = "Limgardistoj, kiujn vi ludas, malka\u015Das//3 kartojn kaj for\u0135etas 2. (\u0108iuj 3//devas esti Agkartoj por preni la Kornon.)";
EsperantoCardTexts[CardNames.TREASURE_CHEST] = "Je la komenco de via A\u0109etfazo,//gajnu Oron.";
EsperantoCardTexts[CardNames.ACADEMY] = "Kiam vi gajnas Agkarton,//|+1 Vila\u011Dano|.";
EsperantoCardTexts[CardNames.BARRACKS] = "Je la komenco de via vico, |+1 Ago|.";
EsperantoCardTexts[CardNames.CANAL] = "Dum viaj vicoj, kartoj kostas [1] malpli.";
EsperantoCardTexts[CardNames.CAPITALISM] = "Dum viaj vicoj, Agkartoj kun +[] kvantoj//en ilia teksto estas anka\u016D Trezorkartoj.";
EsperantoCardTexts[CardNames.CATHEDRAL] = "Je la komenco de via vico,//rubigu karton el via mano.";
EsperantoCardTexts[CardNames.CITADEL] = "Kiam vi unuafoje ludas Agkarton en \u0109iuj viaj//vicoj, reludu \u011Din poste."; // TRANSLATE; use the same phrase as on Scepter and Royal Carriage (english: "Replay")
EsperantoCardTexts[CardNames.CITY_GATE] = "Je la komenco de via vico, |+1 Karto|,//poste metu karton el via mano sur vian kartaron.";
EsperantoCardTexts[CardNames.CROP_ROTATION] = "Je la komenco de via vico, vi povas for\u0135etu//Venkkarton por |+2 Kartoj|.";
EsperantoCardTexts[CardNames.EXPLORATION] = "Je la fino de via A\u0109etfazo, se vi ne gajnis iujn//ajn kartojn, |+1 Monrezervo| kaj |+1 Vila\u011Dano|.";
EsperantoCardTexts[CardNames.FAIR] = "Je la komenco de via vico, |+1 A\u0109eto|.";
EsperantoCardTexts[CardNames.FLEET] = "Post la fino de la ludo, estas ekstra rondo//de vicoj sole por ludantoj kun \u0109i tio.";
EsperantoCardTexts[CardNames.GUILDHALL] = "Kiam vi gajnas Trezorkarton,//|+1 Monrezervo|.";
EsperantoCardTexts[CardNames.INNOVATION] = "Unufoje dum \u0109iuj viaj vicoj, kiam vi//gajnas Agkarton, vi povas ludi \u011Din.";
EsperantoCardTexts[CardNames.PAGEANT] = "Je la fino de via A\u0109etfazo,//vi povas pagi [1] por |+1 Monrezervo|.";
EsperantoCardTexts[CardNames.PIAZZA] = "Je la komenco de via vico, malka\u015Du//la supran karton de via kartaro.//Se \u011Di estas Agkarto, ludu \u011Din.";
EsperantoCardTexts[CardNames.ROAD_NETWORK] = "Kiam alia ludanto gajnas Venkkarton,//|+1 Karto|.";
EsperantoCardTexts[CardNames.SEWERS] = "Kiam vi rubigas karton krom kun \u0109i tio,//vi povas rubigi karton el via mano.";
EsperantoCardTexts[CardNames.SILOS] = "Je la komenco de via vico, for\u0135etu iom ajn da//Kuproj, malka\u015Dita, poste tiru tiom da kartoj.";
EsperantoCardTexts[CardNames.SINISTER_PLOT] = "Je la komenco de via vico, aldonu jetonon//\u0109i tie, a\u016D forigu viajn jetonojn \u0109i tie//por |+1 Karto| po jetono.";
EsperantoCardTexts[CardNames.STAR_CHART] = "Kiam vi miksas, vi povas//elekti la supran karton.";

EsperantoCardTexts[CardNames.SAUNA] = "|+1 Karto|//|+1 Ago|//Vi povas ludi Glacitruon//el via mano.//\u0108i-vice, kiam vi ludas//Ar\u011Denton, vi povas rubigi//karton el via mano.";
EsperantoCardTexts[CardNames.AVANTO] = "|+3 Kartoj|////Vi povas ludi Sa\u016Dnon//el via mano.";
EsperantoCardTexts[CardNames.BLACK_MARKET] = "|+[2]|////Malka\u015Du la 3 suprajn kartojn de la//kartaro de Nigra Merkato. Ludu iom//ajn da Trezorkartoj el via mano. Vi//povas a\u0109eti unu el la malka\u015Ditaj kartoj.//Metu la ceteron sur la fundon de la//kartaro de Nigra Merkato en ajna ordo.//---//Agordo: Kreu kartaron de Nigra Merkato//el malsamaj neuzitaj Regnokartoj.";
EsperantoCardTexts[CardNames.ENVOY] = "Malka\u015Du la 5 suprajn//kartojn de via kartaro. La//ludanto maldekstren//elektas unu. For\u0135etu tiun//kaj metu la ceteron//en vian manon.";
EsperantoCardTexts[CardNames.GOVERNOR] = "|+1 Ago|//Elektu unu; vi ricevas la version//en krampoj: \u0108iu ludanto ricevas//|+1 (+3) Kartoj|; a\u016D \u0109iu ludanto//gajnas Ar\u011Denton (Oron); a\u016D \u0109iu//ludanto povas rubigi karton el//ties mano kaj gajnas karton//kostantan precize [1]([2]) pli.";
EsperantoCardTexts[CardNames.PRINCE] = "Vi povas flankenmeti (sur \u0109i//tio) ne-Da\u016Dran ne-Komandan//Agkarton el via mano//kostantan \u011Dis [4]. Je la//komenco de \u0109iuj viaj//vicoj, ludu tiun Agkarton,//lasante \u011Din flankenmetita.";
// Esperanto text: "You may set aside (on this)//a non-Duration Action card//from your hand costing up//to [4]. At the start of each//of your turns, play that//card, leaving it set aside.";
EsperantoCardTexts[CardNames.STASH] = "[!2]//---//Kiam vi miksas \u0109i tion,//vi povas trarigardu vian//ceteran kartaron, kaj povas//meti \u0109i tion ie inter la//miksitaj kartoj.";
EsperantoCardTexts[CardNames.SUMMON] = "Gajnu Agkarton kostantan \u011Dis [4].//Flankenmetu \u011Din. Se vi faris tion, poste je//la komenco de via sekva vico, ludu \u011Din.";
EsperantoCardTexts[CardNames.WALLED_VILLAGE] = "|+1 Karto|//|+2 Agoj|//---//Je la komenco de Purigfazo, se//vi havas \u0109i tion kaj apena\u016D unu//alian Agkarton en ludo, vi povas//meti \u0109i tion sur vian kartaron.";
EsperantoCardTexts[CardNames.BLACK_MARKET_PILE] = "";
EsperantoCardTexts[CardNames.DISMANTLE] = "Rubigu karton el via mano.//Se \u011Di kostas almena\u016D[1],//gajnu pli malmultekostan//karton kaj Oron.";
EsperantoCardTexts[CardNames.CAPTAIN] = "Nun kaj je la komenco//de via sekva vico://Ludu ne-Da\u016Dran,//ne-Komandan Agkarton//el la Provizo kostantan//\u011Dis [4], lasante \u011Din tie.";
EsperantoCardTexts[CardNames.CHURCH] = "|+1 Ago|////Flankenmetu \u011Dis 3 kartojn//el via mano surventre. Je la//komenco de via sekva vico,//metu ilin en vian manon,//poste vi povas rubigi//karton el via mano.";

EsperantoCardTexts[CardNames.BLACK_CAT] = "|+2 Kartoj|//Se ne estas via vico, \u0109iu//alia ludanto gajnas//Malbenon.//---//Kiam alia ludanto gajnas//Venkkarton, vi povas ludi//\u0109i tion el via mano.";
EsperantoCardTexts[CardNames.SLEIGH] = "Gajnu 2 \u0108evalojn.//---//Kiam vi gajnas karton,//vi povas for\u0135eti \u0109i tion,//por metu tiun karton en//vian manon a\u016D sur vian//kartaron.";
EsperantoCardTexts[CardNames.SUPPLIES] = "[!1]////Gajnu \u0108evalon sur//vian kartaron.";
EsperantoCardTexts[CardNames.CAMEL_TRAIN] = "Ekzilu ne-Venkan//karton el la Provizo.//---//Kiam vi gajnas \u0109i tion,//Ekzilu Oron el la Provizo.";
EsperantoCardTexts[CardNames.GOATHERD] = "|+1 Ago|////Vi povas rubigi karton//el via mano.////|+1 Karto| po karto, kiun//la ludanto dekstren rubigis//en ties anta\u016Da vico.";
EsperantoCardTexts[CardNames.SCRAP] = "Rubigu karton el via mano.//Elektu malsaman a\u0135on po//[1], kiun \u011Di kostas: |+1 Karto|;//|+1 Ago|; |+1 A\u0109eto|; |+|[1];//gajnu Ar\u011Denton; gajnu \u0108evalon.";
EsperantoCardTexts[CardNames.SHEEPDOG] = "|+2 Kartoj|//---//Kiam vi gajnas karton,//vi povas ludi \u0109i tion//el via mano.";
EsperantoCardTexts[CardNames.SNOWY_VILLAGE] = "|+1 Karto|//|+4 Agoj|//|+1 A\u0109eto|////Ignoru pluajn |+Agoj|,//kiujn vi ricevas \u0109i-vice.";
EsperantoCardTexts[CardNames.STOCKPILE] = "[!3]////|+1 A\u0109eto|////Ekzilu \u011Din.";
EsperantoCardTexts[CardNames.BOUNTY_HUNTER] = "|+1 Ago|////Ekzilu karton el via mano.//Se vi ne havis kopion//de \u011Di en Ekzilo, |+|[3].";
EsperantoCardTexts[CardNames.CARDINAL] = "|+[2]|////\u0108iu alia ludanto malka\u015Das//la 2 suprajn kartojn de//ties kartaro, Ekzilas//unu kostantan de [3]//\u011Dis [6], kaj for\u0135etas//la ceteron.";
EsperantoCardTexts[CardNames.CAVALRY] = "Gajnu 2 \u0108evalojn.//---//Kiam vi gajnas \u0109i tion,//|+2 Kartoj|, |+1 A\u0109eto|, kaj//se estas via A\u0109etfazo//reiru al via Agfazo.";
EsperantoCardTexts[CardNames.GROOM] = "Gajnu karton kostantan//\u011Dis [4]. Se \u011Di estas...////Agkarto, gajnu \u0108evalon//Trezorkarto, gajnu Ar\u011Denton//Venkkarto,|+1 Karto|// kaj |+1 Ago|";
EsperantoCardTexts[CardNames.HOSTELRY] = "|+1 Karto|//|+2 Agoj|//---//Kiam vi gajnas \u0109i tion, vi//povas for\u0135eti iom ajn da//Trezorkartoj, malka\u015Dante ilin,//por gajni tiom da \u0108evaloj.";
EsperantoCardTexts[CardNames.VILLAGE_GREEN] = "Nun a\u016D je la komenco//de via sekva vico,//|+1 Karto| kaj |+2 Agoj.|//---//Kiam vi for\u0135etas \u0109i tion//krom dum Purigfazo, vi povas//malka\u015Di \u011Din por ludi \u011Din.";
EsperantoCardTexts[CardNames.BARGE] = "Nun a\u016D je la//komenco de via//sekva vico,//|+3 Kartoj| kaj//|+1 A\u0109eto|.";
EsperantoCardTexts[CardNames.COVEN] = "|+1 Ago|//|+[2]|////\u0108iu alia ludanto Ekzilas//Malbenon el la Provizo.//Se tiu ne povas, tiu for\u0135etas//ties Ekzilitajn Malbenojn.";
EsperantoCardTexts[CardNames.DISPLACE] = "Ekzilu karton el via mano.//Gajnu malsame nomatan//karton kostantan \u011Dis [2]//pli ol \u011Di.";
EsperantoCardTexts[CardNames.FALCONER] = "Gajnu al via mano karton,//kiu kostas malpli ol \u0109i tio.//---//Kiam iu ajn ludanto gajnas//karton kun 2 a\u016D pli da specoj//(Ago, Atako, ktp), vi povas//ludi \u0109i tion el via mano.";
EsperantoCardTexts[CardNames.FISHERMAN] = "|+1 Karto|//|+1 Ago|//|+[3]|//---//Dum viaj vicoj, se via//for\u0135eta stako estas malplena,//\u0109i tio kostas [1] malpli.";
EsperantoCardTexts[CardNames.GATEKEEPER] = "Je la komenco de via sekva//vico, |+|[3]. \u011Cis tiu fojo,//kiam alia ludanto gajnas//Agkarton a\u016D Trezorkarton,//de kiu tiu ne havas Ekzilitan//kopion, tiu Ekzilas \u011Din.";
EsperantoCardTexts[CardNames.HUNTING_LODGE] = "|+1 Karto|//|+2 Agoj|////Vi povas for\u0135eti vian//manon por |+5 Kartoj|.";
EsperantoCardTexts[CardNames.KILN] = "|+[2]|////La sekvan fojon vi ludas//karton \u0109i-vice, vi povas//unue gajni kopion de \u011Di.";
EsperantoCardTexts[CardNames.LIVERY] = "|+[3]|////\u0108i-vice, kiam vi gajnas //karton kostantan almena\u016D//[4], gajnu \u0108evalon.";
EsperantoCardTexts[CardNames.MASTERMIND] = "Je la komenco de via//sekva vico, vi povas ludi//Agkarton el via mano//trifoje.";
EsperantoCardTexts[CardNames.PADDOCK] = "|+[2]|////Gajnu 2 \u0108evalojn.////|+1 Ago| po//malplena Provizstako.";
EsperantoCardTexts[CardNames.SANCTUARY] = "|+1 Karto|//|+1 Ago|//|+1 A\u0109eto|////Vi povas Ekzili karton//el via mano.";
EsperantoCardTexts[CardNames.DESTRIER] = "|+2 Kartoj|//|+1 Ago|//---//Dum viaj vicoj, \u0109i tio//kostas [1] malpli po karto,//kiun vi gajnis \u0109i-vice.";
EsperantoCardTexts[CardNames.WAYFARER] = "|+3 Kartoj|////Vi povas gajni Ar\u011Denton.//---//\u0108i tio havas la saman koston//kiel la anta\u016Da alia karto//gajnita \u0109i-vice, se ekzistas.";
EsperantoCardTexts[CardNames.ANIMAL_FAIR] = "|+[4]|////|+1 A\u0109eto| po malplena//Provizstako.//---//Anstata\u016D pagi la koston de \u0109i//tiu karto, vi povas rubigi//Agkarton el via mano.";
EsperantoCardTexts[CardNames.HORSE] = "|+2 Kartoj|//|+1 Ago|////Redonu \u0109i tion//al \u011Dia stako.////%(\u0108i tio ne estas%//%en la Provizo.)%";
EsperantoCardTexts[CardNames.WAY_OF_THE_BUTTERFLY] = "Vi povas redoni \u0109i tion al \u011Dia stako//por gajni karton kostantan//precize [1] pli ol \u011Di.";
EsperantoCardTexts[CardNames.WAY_OF_THE_CAMEL] = "Ekzilu Oron el la Provizo.";
EsperantoCardTexts[CardNames.WAY_OF_THE_CHAMELEON] = "Sekvu la instrukciojn de \u0109i tiu karto; \u0109iufoje,//kiu ili donus vin |+Kartoj| \u0109i-vice,//vi anstata\u016D ricevas |+|[], kaj inverse.";
EsperantoCardTexts[CardNames.WAY_OF_THE_FROG] = "|+1 Ago|////Kiam vi for\u0135etas \u0109i tion el ludo//\u0109i-vice, metu \u011Din sur vian kartaron.";
EsperantoCardTexts[CardNames.WAY_OF_THE_GOAT] = "Rubigu karton el via mano.";
EsperantoCardTexts[CardNames.WAY_OF_THE_HORSE] = "|+2 Kartoj|//|+1 Ago|////Redonu \u0109i tion al \u011Dia stako.";
EsperantoCardTexts[CardNames.WAY_OF_THE_MOLE] = "|+1 Ago|////For\u0135etu vian manon. |+3 Kartoj.|";
EsperantoCardTexts[CardNames.WAY_OF_THE_MONKEY] = "|+1 A\u0109eto|//|+[1]|";
EsperantoCardTexts[CardNames.WAY_OF_THE_MOUSE] = "Ludu la flankenmetitan karton, lasante \u011Din tie.//---//Agordo: Flankenmetu neuzitan Agkarton//kostantan [2] a\u016D [3].";
EsperantoCardTexts[CardNames.WAY_OF_THE_MULE] = "|+1 Ago|//|+[1]|";
EsperantoCardTexts[CardNames.WAY_OF_THE_OTTER] = "|+2 Kartoj|";
EsperantoCardTexts[CardNames.WAY_OF_THE_OWL] = "Tiru \u011Dis vi havas 6 kartojn mane.";
EsperantoCardTexts[CardNames.WAY_OF_THE_OX] = "|+2 Agoj|";
EsperantoCardTexts[CardNames.WAY_OF_THE_PIG] = "|+1 Karto|//|+1 Ago|";
EsperantoCardTexts[CardNames.WAY_OF_THE_RAT] = "Vi povas for\u0135eti Trezorkarton//por gajni kopion de \u0109i tio.";
EsperantoCardTexts[CardNames.WAY_OF_THE_SEAL] = "|+[1]|////\u0108i-vice, kiam vi gajnas karton,//vi povas meti \u011Din sur vian kartaron.";
EsperantoCardTexts[CardNames.WAY_OF_THE_SHEEP] = "|+[2]|";
EsperantoCardTexts[CardNames.WAY_OF_THE_SQUIRREL] = "|+2 Kartoj| je la fino de \u0109i tiu vico.";
EsperantoCardTexts[CardNames.WAY_OF_THE_TURTLE] = "Flankenmetu \u0109i tion. Se vi faras tion,//ludu \u011Din je la komenco de via sekva vico.";
EsperantoCardTexts[CardNames.WAY_OF_THE_WORM] = "Ekzilu Bienon el la Provizo.";
EsperantoCardTexts[CardNames.DELAY] = "Vi povas flankenmeti Agkarton el via mano.//Je la komenco de via sekva vico, ludu \u011Din.";
EsperantoCardTexts[CardNames.DESPERATION] = "Unufoje po vico: Vi povas gajni Malbenon.//Se vi faras tion, |+1 A\u0109eto| kaj |+|[2].";
EsperantoCardTexts[CardNames.GAMBLE] = "|+1 A\u0109eto|////Malka\u015Du la supran karton de via kartaro.//Se \u011Di estas Trezorkarto a\u016D Agkarto, vi//povas ludi \u011Din. Alie, for\u0135etu \u011Din.";
EsperantoCardTexts[CardNames.PURSUE] = "|+1 A\u0109eto|////Nomumu karton. Malka\u015Du la 4 suprajn//kartojn de via kartaro. Remetu la//kongruojn kaj for\u0135etu la ceteron.";
EsperantoCardTexts[CardNames.RIDE] = "Gajnu \u0108evalon.";
EsperantoCardTexts[CardNames.TOIL] = "|+1 A\u0109eto|////Vi povas ludi Agkarton el via mano.";
EsperantoCardTexts[CardNames.ENHANCE] = "Vi povas rubigi ne-Venkan karton//el via mano, por gajni karton kostantan//\u011Dis [2] pli ol \u011Di.";
EsperantoCardTexts[CardNames.MARCH] = "Trarigardu vian for\u0135etan stakon.//Vi povas ludi Agkarton el \u011Di.";
EsperantoCardTexts[CardNames.TRANSPORT] = "Elektu unu: Ekzilu Agkarton el la//Provizo; a\u016D metu Agkarton, kiun vi//havas en Ekzilo sur vian kartaron.";
EsperantoCardTexts[CardNames.BANISH] = "Ekzilu iom ajn da kartoj kun//la sama nomo el via mano.";
EsperantoCardTexts[CardNames.BARGAIN] = "Gajnu ne-Venkan karton kostantan \u011Dis [5].//\u0108iu alia ludanto gajnas \u0108evalon.";
EsperantoCardTexts[CardNames.INVEST] = "Ekzilu Agkarton el la Provizo. Dum \u011Di//estas en Ekzilo, kiam alia ludanto gajnas//a\u016D Investas en kopio de \u011Di, |+2 Kartoj|.";
EsperantoCardTexts[CardNames.SEIZE_THE_DAY] = "Unufoje po ludo://Ludu ekstran vicon post \u0109i tiu.";
EsperantoCardTexts[CardNames.COMMERCE] = "Gajnu Oron po malsame nomata karto,//kiun vi gajnis \u0109i-vice.";
EsperantoCardTexts[CardNames.DEMAND] = "Gajnu \u0108evalon kaj karton kostantan//\u011Dis [4], amba\u016D sur vian kartaron.";
EsperantoCardTexts[CardNames.STAMPEDE] = "Se vi havas \u011Dis 5 kartojn en ludo,//gajnu 5 \u0108evalojn sur vian kartaron.";
EsperantoCardTexts[CardNames.REAP] = "Gajnu Oron. Flankenmetu \u011Din. Se vi faras tion,//je la komenco de via sekva vico, ludu \u011Din.";
EsperantoCardTexts[CardNames.ENCLAVE] = "Gajnu Oron. Ekzilu Duklandon el la Provizo.";
EsperantoCardTexts[CardNames.ALLIANCE] = "Gajnu Provincon, Duklandon, Bienon,//Oron, Ar\u011Denton, kaj Kupron.";
EsperantoCardTexts[CardNames.POPULATE] = "Gajnu karton el \u0109iu Aga Provizstako.";
EsperantoCardTexts[CardNames.CARD_OF_THE_BUTTERFLY] = "";
EsperantoCardTexts[CardNames.CARD_OF_THE_CAMEL] = "";
EsperantoCardTexts[CardNames.CARD_OF_THE_CHAMELEON] = "";
EsperantoCardTexts[CardNames.CARD_OF_THE_FROG] = "";
EsperantoCardTexts[CardNames.CARD_OF_THE_GOAT] = "";
EsperantoCardTexts[CardNames.CARD_OF_THE_HORSE] = "";
EsperantoCardTexts[CardNames.CARD_OF_THE_MOLE] = "";
EsperantoCardTexts[CardNames.CARD_OF_THE_MONKEY] = "";
EsperantoCardTexts[CardNames.CARD_OF_THE_MOUSE] = "";
EsperantoCardTexts[CardNames.CARD_OF_THE_MULE] = "";
EsperantoCardTexts[CardNames.CARD_OF_THE_OTTER] = "";
EsperantoCardTexts[CardNames.CARD_OF_THE_OWL] = "";
EsperantoCardTexts[CardNames.CARD_OF_THE_OX] = "";
EsperantoCardTexts[CardNames.CARD_OF_THE_PIG] = "";
EsperantoCardTexts[CardNames.CARD_OF_THE_RAT] = "";
EsperantoCardTexts[CardNames.CARD_OF_THE_SEAL] = "";
EsperantoCardTexts[CardNames.CARD_OF_THE_SHEEP] = "";
EsperantoCardTexts[CardNames.CARD_OF_THE_SQUIRREL] = "";
EsperantoCardTexts[CardNames.CARD_OF_THE_TURTLE] = "";
EsperantoCardTexts[CardNames.CARD_OF_THE_WORM] = "";

// Allies

EsperantoCardTexts[CardNames.TOWN] = "Elektu unu://|+1 Karto| kaj |+2 Agoj|;// aŭ |+1 Aĉeto| kaj +[2].";
EsperantoCardTexts[CardNames.MODIFY] = "Rubigu karton el via mano.//Elektu unu: |+1 Karto| kaj//|+1 Ago|; aŭ gajnu karton//kostantan ĝis [2] pli//ol la rubigita karto.";
EsperantoCardTexts[CardNames.MERCHANT_CAMP] = "|+2 Agoj|//+[1]//---//Kiam vi forĵetas ĉi tion//el ludo, vi povas meti//ĝin sur vian kartaron.";
EsperantoCardTexts[CardNames.SWAP] = "|+1 Karto|//|+1 Ago|//Vi povas redonu Agkarton//el via mano al ĝia stako, por//gajni malsaman Agkarton//kostantan ĝis [5].";
EsperantoCardTexts[CardNames.SKIRMISHER] = "|+1 Karto|//|+1 Ago|//|+[1]|//Ĉi-vice, kiam vi gajnas//Atakkarton, ĉiu alia ludanto//forĵetas ĝis tiu havas//3 kartojn mane.";
EsperantoCardTexts[CardNames.HIGHWAYMAN] = "Je la komenco de via//sekva vico, forĵetu ĉi tion//el ludo kaj |+3 Kartoj|.//Ĝis tiu fojo, la unua//Trezorkarto, kiun ĉiu alia//ludanto ludas ĉiu vice,//faras nenion.";
EsperantoCardTexts[CardNames.BARBARIAN] = "|+[2]|//Ĉiu alia ludanto rubigas la//supran karton de ties kartaro.//Se ĝi kostas [3] aŭ pli tiu//gajnas pli malmultekostan//karton, kiu kunhavas//specon kun ĝi; alie tiu//gajnas Malbenon.";
EsperantoCardTexts[CardNames.INNKEEPER] = "|+1 Ago|//Elektu unu: |+1 Karto|;//aŭ |+3 Kartoj|, forĵetu//3 kartoj;//aŭ |+5 Kartoj|, forĵetu//6 kartoj.";
EsperantoCardTexts[CardNames.CAPITAL_CITY] = "|+1 Karto|//|+2 Agoj|//Vi povas forĵeti 2 kartojn//por +[2].//Vi povas pagi [2]//por |+2 Kartoj|.";
EsperantoCardTexts[CardNames.SPECIALIST] = "Vi povas ludi Agkarton aŭ//Trezorkarton el via mano.//Elektu unu: Reludu ĝin;//aŭ gajnu kopion de ĝi.";
EsperantoCardTexts[CardNames.CARPENTER] = "Se neniuj Provizstakoj estas//malplenaj, |+1 Ago| kaj gajnu//karton kostantan ĝis [4].//Alie, rubigu karton el//via mano kaj gajnu karton//kostantan ĝis [2] pli ol ĝi.";
EsperantoCardTexts[CardNames.ROYAL_GALLEY] = "|+1 Karto|//Vi povas ludi ne-Daŭran//Agkarton el via mano.//Flankenmetu ĝin; se vi faris//tion, poste je la komenco de//via sekva vico, ludu ĝin.";
EsperantoCardTexts[CardNames.MARQUIS] = "|+1 Aĉeto|//|+1 Karto| po karto en//via mano. Forĵetu ĝis//vi havas 10 kartojn mane.";
EsperantoCardTexts[CardNames.SENTINEL] = "Rigardu la 5 suprajn//kartojn de via kartaro.//Rubigu ĝis 2 da ili. Remetu//la ceteron en ajna ordo.";
EsperantoCardTexts[CardNames.COURIER] = "|+[1]|//Forĵetu la supran karton de//via kartaro. Trarigardu vian//forĵetan stakon; vi povas ludi//Agkarton aŭ Trezorkarton el ĝi.";
EsperantoCardTexts[CardNames.GALLERIA] = "|+[3]|//Ĉi-vice, kiam vi//gajnas karton kostantan//[3] aŭ [4], |+1 Aĉeto|.";
EsperantoCardTexts[CardNames.HUNTER] = "|+1 Ago|//Malkaŝu la 3 suprajn kartojn de//via kartaro. El tiujn kartojn,//metu Agkarton, Trezorkarton,//kaj Venkkarton en vian//manon. Forĵetu la ceteron.";

EsperantoCardTexts[CardNames.SYCOPHANT] = "|+1 Ago|//Forĵetu 3 kartojn. Se vi//forĵetis almenaŭ unu, |+|[3].//---//Kiam vi gajnas aŭ rubigas//ĉi tion, |+2 Komplezoj|.";
EsperantoCardTexts[CardNames.UNDERLING] = "|+1 Karto|//|+1 Ago|//|+1 Komplezo|";
EsperantoCardTexts[CardNames.IMPORTER] = "Je la komenco de via//sekva vico, gajnu karton//kostantan ĝis [5].//---//Agordo: Ĉiu ludanto//ricevas |+4 Komplezoj|.";
EsperantoCardTexts[CardNames.CONTRACT] = "[!2]//|+1 Komplezo|//Vi povas flankenmeti Agkarton//el via mano por ludi ĝin je//la komenco de via sekva vico.";
EsperantoCardTexts[CardNames.EMISSARY] = "|+3 Kartoj|//Se ĉi tion igis vin miksi//(almenaŭ unu karton),//|+1 Ago| kaj |+2 Komplezoj|.";
EsperantoCardTexts[CardNames.BAUBLE] = "Elektu du malsamajn elektojn://|+1 Aĉeto|; +[1]; |+1 Komplezo|;//ĉi-vice, kiam vi gajnas//karton, vi povas meti ĝin//sur vian kartaron.";
EsperantoCardTexts[CardNames.BROKER] = "Rubigu karton el via mano.//Elektu unu, por ricevi unufoje//po [1], kiun la rubigita//karto kostas:// |+1 Karto|; |+1 Ago|;//+[1]; or |+1 Komplezo|.";
EsperantoCardTexts[CardNames.GUILDMASTER] = "|+[3]|//Ĉi-vice, kiam vi gajnas//karton, |+1 Komplezo|.";
EsperantoCardTexts[CardNames.ARCHITECTS_GUILD] = "Kiam vi gajnas karton, vi povas elspezi//|2 Komplezojn| por gajni pli malmultekostan//ne-Venkan karton.";
EsperantoCardTexts[CardNames.ORDER_OF_ASTROLOGERS] = "Kiam vi miksas, vi povas elekti//unu karton po |Komplezo|, kiun vi//elspezas, por meti supre.";
EsperantoCardTexts[CardNames.LEAGUE_OF_BANKERS] = "Je la komenco de via Aĉetfazo,//+[1] po |4 Komplezo|-jetonojn, kiun//vi havas (subrondigu).";
EsperantoCardTexts[CardNames.MOUNTAIN_FOLK] = "Je la komenco de via vico, vi povas//elspezi |5 Komplezojn| por |+3 Kartoj|";
EsperantoCardTexts[CardNames.WOODWORKERS_GUILD] = "Je la komenco de via Aĉetfazo, vi povas elspezi//|Komplezon| por rubigu Agkarton el via mano.//Se vi faris tion, gajnu Agkarton.";
EsperantoCardTexts[CardNames.PEACEFUL_CULT] = "Je la komenco de via Aĉetfazo, elspezu//iom ajn da |Komplezoj| por rubigu tiom ajn//da kartoj el via mano.";
EsperantoCardTexts[CardNames.CRAFTERS_GUILD] = "Je la komenco de via vico, vi povas elspezi//|2 Komplezojn| por gajni karton kostantan//ĝis [4] sur vian kartaron.";
EsperantoCardTexts[CardNames.CAVE_DWELLERS] = "Je la komenco de via vico, vi povas elspezi//|Komplezon|, por forĵeti karton poste tiri//karton. Ripetu laŭplaĉe.";
EsperantoCardTexts[CardNames.PLATEAU_SHEPHERDS] = "Dum poentkalkulo, parigu viajn |Komplezjon|//kun kartojn, kiujn vi havas kostantajn [2],//por {2} po paro.";
EsperantoCardTexts[CardNames.DESERT_GUIDES] = "Je la komenco de via vico, vi povas elspezi//|Komplezon| por forĵeti vian manon kaj tiri//5 kartojn. Ripetu laŭplaĉe.";
EsperantoCardTexts[CardNames.FAMILY_OF_INVENTORS] = "Je la komenco de via Aĉetfazo, vi povas meti//|Komplezo|-jetonon, kiun vi havas sur ne-Venkan//Provizstakon. Kartoj kostas [1] malpli po Komplezo-//jetono sur ilia stako.";
EsperantoCardTexts[CardNames.ISLAND_FOLK] = "Je la fino de via vico, se la antaŭa//vico ne estas via, vi povas elspezi//|5 Komplezojn| por ludi alian vicon.";
EsperantoCardTexts[CardNames.ORDER_OF_MASONS] = "Kiam vi miksas, vi povas elekti ĝis 2 kartojn//po |Komplezo|, kiun vi elspezas, por meti//en vian forĵetan stakon.";
EsperantoCardTexts[CardNames.BAND_OF_NOMADS] = "Kiam vi gajnas karton kostantan [3] aŭ pli,//vi povas elspezi |Komplezon|, por |+1 Karto|,//aŭ |+1 Ago|, aŭ |+1 Aĉeto|.";
EsperantoCardTexts[CardNames.GANG_OF_PICKPOCKETS] = "Je la komenco de via vico, forĵetu ĝis//vi havas 4 kartojn mane krom se vi//elspezas |Komplezon|.";
EsperantoCardTexts[CardNames.MARKET_TOWNS] = "Je la komenco de via Aĉetfazo,//vi povas elspezi |Komplezon| por ludi//Agkarton el via mano. Ripetu laŭplaĉe.";
EsperantoCardTexts[CardNames.FOREST_DWELLERS] = "Je la komenco de via vico, vi povas elspezi |Komplezon|//por rigardu la 3 suprajn kartojn de via kartaro. Forĵetu//iom ajn kaj remetu la ceteron en ajna ordo.";
EsperantoCardTexts[CardNames.FELLOWSHIP_OF_SCRIBES] = "Post ludante Agkarton, se vi havas 4 aŭ//malpli kartojn mane, vi povas elspezi//|Komplezon| por |+1 Karto|.";
EsperantoCardTexts[CardNames.LEAGUE_OF_SHOPKEEPERS] = "Post vi ludas Kontaktulon, se//vi havas |5| aŭ pli |Komplezojn|, |+|[1],//kaj se |10| aŭ pli, |+1 Ago| kaj |+1 Aĉeto|.";
EsperantoCardTexts[CardNames.COASTAL_HAVEN] = "Kiam vi forĵetas vian manon en Purigfazo, vi povas//elspezi iom ajn |Komplezojn| por gardi tiom ajn//da kartoj mane por sekva vico (ankoraŭ tiru 5).";
EsperantoCardTexts[CardNames.CITY_STATE] = "Kiam vi gajnas Agkarton dum via vico, vi//povas elspezi |2 Komplezojn| por ludi ĝin.";
EsperantoCardTexts[CardNames.TRAPPERS_LODGE] = "Kiam vi gajnas karton, vi povas elspezi//|Komplezon| por meti ĝin sur vian kartaron.";
EsperantoCardTexts[CardNames.CIRCLE_OF_WITCHES] = "Post vi ludas Kontaktulon, vi povas elspezi//|3 Komplezojn| por igi ĉiun alian ludanton//gajni Malbenon.";
EsperantoCardTexts[CardNames.AUGUR_PILE] = "Ĉi tiu stako komence de//la ludo havas po 4 kopiojn//de Herbkolektanto, Akolito,//Magiistino, kaj Sibilo, en//tiu ordo. Oni povas gajni aŭ//aĉeti nur la supran karton.";
EsperantoCardTexts[CardNames.HERB_GATHERER] = "|+1 Aĉeto|//Metu vian kartaron en vian//forĵetan stakon. Trarigardu//ĝin kaj vi povas ludi//Trezorkarton el ĝi. Vi//povas rotacii la Aŭguristojn.";
EsperantoCardTexts[CardNames.ACOLYTE] = "Vi povas rubigi//Agkarton aŭ Venkkarton//el via mano por gajni//Oron. Vi povas rubigi ĉi//tion por gajni Aŭguriston.";
EsperantoCardTexts[CardNames.SORCERESS] = "|+1 Ago|//Nomumu karton. Malkaŝu la//supran karton de via kartaro//kaj metu ĝin en vian manon.//Se vi kongruas, ĉiu alia//ludanto gajnas Malbenon.";
EsperantoCardTexts[CardNames.SIBYL] = "|+4 Kartoj|//|+1 Ago|//Metu karton el via mano sur//la supron de via kartaro, kaj//alian sur la fundon.";
EsperantoCardTexts[CardNames.CLASH_PILE] = "Ĉi tiu stako komence de//la ludo havas po 4 kopiojn//de Batalplano, Arkisto,//Militestro, kaj Teritorio, en//tiu ordo. Oni povas gajni aŭ//aĉeti nur la supran karton.";
EsperantoCardTexts[CardNames.BATTLE_PLAN] = "|+1 Karto|//|+1 Ago|//Vi povas malkaŝi Atakkarton//el via mano por |+1 Karto|.//Vi povas rotacii iun ajn//Provizstakon.";
EsperantoCardTexts[CardNames.ARCHER] = "|+[2]|//Ĉiu alia ludanto kun 5 aŭ//pli da kartoj mane malkaŝas//ĉiujn krom unu kaj forĵetas//unu el tiujn, kiun vi elektas.";
EsperantoCardTexts[CardNames.WARLORD] = "|+1 Ago|//Je la komenco de via sekva//vico, |+2 Kartoj|. Ĝis tiam,//aliaj ludantoj ne povas ludi//Agkarton el via mano, de kiu//tiu havas 2 aŭ pli kopiojn//en ludo.";
EsperantoCardTexts[CardNames.TERRITORY] = "Valoras {1} po malsame//nomataj Venkkarton, kiun//vi havas.//---//Kiam vi gajnas ĉi tion, gajnu//Oron po malplena Provizstako.";
EsperantoCardTexts[CardNames.FORT_PILE] = "Ĉi tiu stako komence de//la ludo havas po 4 kopiojn de//Tendo, Garnizono, Monteta//Fortikaĵo, kaj Fortikaĵego, en//tiu ordo. Oni povas gajni aŭ//aĉeti nur la supran karton.";
EsperantoCardTexts[CardNames.TENT] = "|+[2]|//Vi povas rotacii la Redutojn.//---//Kiam vi forĵetas ĉi tion//el ludo, vi povas meti//ĝin sur vian karataron.";
EsperantoCardTexts[CardNames.GARRISON] = "|+[2]|//Ĉi-vice, kiam vi gajnas//karton, aldonu jetonon//ĉie. Je la komenco de//via sekva vico, forigu//ilin po |+1 Karto|.";
EsperantoCardTexts[CardNames.HILL_FORT] = "Gajnu karton kostantan//ĝis [4]. Elektu unu://Metu ĝin en vian//manon; aŭ |+1 Karto|//kaj |+1 Ago|.";
EsperantoCardTexts[CardNames.STRONGHOLD] = "Elektu unu: |+|[3];//aŭ je la komenco de via//sekva vico, |+3 Kartoj|.//---//{!2}";
EsperantoCardTexts[CardNames.ODYSSEY_PILE] = "Ĉi tiu stako komence de//la ludo havas po 4 kopiojn de//Malnova Mapo, Vojaĝo, Subakvigita//Trezoro, kaj Formarbordo, en//tiu ordo. Oni povas gajni aŭ//aĉeti nur la supran karton.";
EsperantoCardTexts[CardNames.OLD_MAP] = "|+1 Karto|//|+1 Ago|//Forĵetu karton. |+1 Karto|.//Vi povas rotacii la Odiseadojn.";
EsperantoCardTexts[CardNames.VOYAGE] = "|+1 Ago|//Se la antaŭa vico ne estas//via, ludu ekstran vicon//post ĉi tiu, dum kiu vi//povas nur ludi 3 kartojn//el via mano.";
EsperantoCardTexts[CardNames.SUNKEN_TREASURE] = "Gajnu Agkarton,//de kiu vi ne havas//kopion en ludo.";
EsperantoCardTexts[CardNames.DISTANT_SHORE] = "|+2 Kartoj|//|+1 Ago|//Gajnu Bienon.//---//{!2}";
EsperantoCardTexts[CardNames.TOWNSFOLK_PILE] = "Ĉi tiu stako komence de//la ludo havas po 4 kopiojn//de Urbanoncisto, Forĝisto,//Muelisto, kaj Pliaĝulo, en//tiu ordo. Oni povas gajni aŭ//aĉeti nur la supran karton.";
EsperantoCardTexts[CardNames.TOWN_CRIER] = "Elektu unu: |+|[2]; aŭ//gajnu Arĝenton; aŭ//|+1 Karto| kaj |+1 Ago|.////Vi povas rotacii//la Urbulojn. ";
EsperantoCardTexts[CardNames.BLACKSMITH] = "Elektu unu: tiras ĝis vi//havas 6 kartoj mane;//aŭ |+2 Kartoj|; aŭ//|+1 Karto| kaj |+1 Ago|.";
EsperantoCardTexts[CardNames.MILLER] = "|+1 Ago|//Rigardu la 4 suprajn kartojn de//via kartaro. Metu unu en vian//manon kaj forĵetu la ceteron.";
EsperantoCardTexts[CardNames.ELDER] = "|+[2]|//Vi povas ludi Agkarton el via//mano. Se ĝi donas al vi elekton//de kapabloj (akz. \"elektu unu\")//ĉi-vice, vi povas elekti//ekstran (malsaman) aĵon.";
EsperantoCardTexts[CardNames.WIZARD_PILE] = "Ĉi tiu stako komence de//la ludo havas po 4 kopiojn //de Studento, Elvokisto,//Magiisto, kaj Liĉo, en tiu//ordo. Oni povas gajni aŭ//aĉeti nur la supran karton.";
EsperantoCardTexts[CardNames.STUDENT] = "|+1 Ago|//Vi povas rotacii la Sorĉistojn.//Rubigu karton el via mano.//Se ĝi estas Trezorkarton,//|+1 Komplezo| kaj metu ĉi tion//sur vian kartaron.";
EsperantoCardTexts[CardNames.CONJURER] = "Gajnu karton kostantan//ĝis [4]. Je la komenco//de via sekva vico, metu//ĉi tion en vian manon.";
EsperantoCardTexts[CardNames.SORCERER] = "|+1 Karto|//|+1 Ago|//Ĉiu alia ludanto nomumas//karton, poste malkaŝas la//supran karton de ties kartaro.//Se erara, tiu gajnas Malbenon.";
EsperantoCardTexts[CardNames.LICH] = "|+6 Kartoj|//|+2 Agoj|//Preterpasu vicon.//---//Kiam vi rubigas ĉi tion,//forĵetu ĝin kaj gajnu//pli malmultekostan//karton el la rubujo.";

EsperantoCardTexts[CardNames.LIGHTHOUSE] = "|+1 Ago|////Nun kaj je la komenco de via//sekva vico, |+|[1].//Ĝis tiam, kiam alia//ludanto ludas Atakkarton,//ĝi ne tuŝas vin.";
EsperantoCardTexts[CardNames.TREASURY] = "|+1 Karto|//|+1 Ago|//|+[1]|//Ĉe la fino de via Aĉetfazo//ĉi-lude, se vi ne gajnis//Venkkarton en ĝi, vi povas//meti ĉi tion sur vian kartaron.";
EsperantoCardTexts[CardNames.MONKEY] = "Ĝis via sekva vico, kiam//la ludanto dekstren//gajnas karton, |+1 Karto|.//Je la komenco de via//sekva vico, |+1 Karto|.";
EsperantoCardTexts[CardNames.BLOCKADE] = "Gajnu karton kostantan ĝis [4],//flankenmetante ĝin. Je la komenco//de via sekva vico, metu ĝin en vian//manon. Dum ĝi estas flankenmetita//kiam alia ludanto gajnas kopion//de ĝi en ties vico, tiu gajnas//Malbenon.";
EsperantoCardTexts[CardNames.SAILOR] = "|+1 Ago|//Unufoje ĉi-vice, kiam vi gajnas//Daŭrokarton, vi povas ludi ĝin.//Je la komenco de via sekva//vico, +[2] kaj vi povas rubigi//karton el via mano.";
EsperantoCardTexts[CardNames.PIRATE] = "Je la komenco de via sekva//vico, gajnu Trezorkarton//kostantan ĝis [6] al via mano.//---//Kiam ajna ludanto gajnas//Trezorkarton, vi povas ludi//ĉi tion el via mano.";
EsperantoCardTexts[CardNames.CORSAIR] = "|+[2]|//Je la komenco de via sekva//vico, |+1 Karto|. Ĝis tiam, ĉiu//alia ludanto rubigas la unuan//Arĝenton aŭ Oron, kiun//tiu ludas en ĉiu vico.";
EsperantoCardTexts[CardNames.SEA_CHART] = "|+1 Karto|//|+1 Ago|//Malkaŝu la supran karton de//via kartaro. Metu ĝin en vian//manon se vi havas kopion de//ĝi en ludo.";
EsperantoCardTexts[CardNames.ASTROLABE] = "Nun kaj je la komenco//de via sekva vico:////[!1]//|+1 Aĉeto|";
EsperantoCardTexts[CardNames.TIDE_POOLS] = "|+3 Kartoj|//|+1 Ago|//Je la komenco de via sekva//vico, forĵetu 2 kartojn.";
EsperantoCardTexts[CardNames.SEA_WITCH] = "|+2 Kartoj|//Ĉiu alia ludanto gajnas//Malbenon. Je la komenco//de via sekva vico,//|+2 Kartoj|, poste forĵetu//2 kartojn.";

EsperantoCardTexts[CardNames.HOARD] = "[!2]//\u0108i-vice kiam vi gajnas//Venkkarton, se vi a\u0109etis//\u011Din, gajnu Oron.";
EsperantoCardTexts[CardNames.QUARRY] = "[!1]//\u0108i-vice, Agkartoj//kostas [!1] malpli.";
EsperantoCardTexts[CardNames.MINT] = "Vi povas malka\u015Di//Trezorkarton el via//mano. Gajnu kopion de \u011Di.//---//Kiam vi gajnas \u0109i tion,//rubigu \u0109iujn ne-Da\u016Drajn//Trezorkartojn, kiujn vi//havas en ludo.";
EsperantoCardTexts[CardNames.ANVIL] = "[!1]//Vi povas forĵeti//Trezorkarton por gajni//karton kostantan ĝis [4].";
EsperantoCardTexts[CardNames.CLERK] = "|+[2]|//Ĉiu alia ludanto kun 5//aŭ pli da kartoj mane//metas unu sur vian kartaron.//---//Je la komenco de via vico, vi//povas ludi ĉi tion el via mano.";
EsperantoCardTexts[CardNames.INVESTMENT] = "Rubigu karton el via mano.//Elektu unu: +[1]; aŭ rubigu//ĉi tion por malkaŝi vian//manon por +{1} po malsame//nomata Trezorkarton tie.";
EsperantoCardTexts[CardNames.TIARA] = "|+1 Aĉeto|//Ĉi-vice, kiam vi gajnas//karton, vi povas meti ĝin sur//vian kartaron. Vi povas ludi//Trezorkarton el via mano//dufoje.";
EsperantoCardTexts[CardNames.CHARLATAN] = "|+[3]|//Ĉiu alia ludanto gajnas//Malbenon.//---//En ludoj, kiu uzas ĉi tion,//Malbeno estas ankaŭ//Trezoro, valoras [1].";
EsperantoCardTexts[CardNames.COLLECTION] = "[!2]//|+1 Aĉeto|//Ĉi-vice, kiam vi gajnas//Agkarton, +{1}.";
EsperantoCardTexts[CardNames.CRYSTAL_BALL] = "[!1]//Rigardu la supran karton de//via kartaro. Vi povas rubigi//ĝin, forĵeti ĝin, aŭ, se ĝi estas//Agkarto aŭ Trezorkarto, vi//povas ludi ĝin.";
EsperantoCardTexts[CardNames.MAGNATE] = "Malkaŝu vian manon. //|+1 Karto| po Trezorkarto tie.";
EsperantoCardTexts[CardNames.WAR_CHEST] = "La ludanto maldekstren//nomumas karton. Gajnu//karton kostantan ĝis [5],//kiu ne estis nomumita por//Militkestoj ĉi-vice.";

EsperantoCardTexts[CardNames.FARMLAND] = "{!2}//---//Kiam vi gajnas \u0109i tion, rubigu//karton el via mano kaj gajnu//karton krom Agro kostantan//precize [2] pli ol \u011Di.";
EsperantoCardTexts[CardNames.HAGGLER] = "|+[2]|//\u0108i-vice, kiam vi gajnas//karton, se vi a\u0109etis \u011Din,//gajnu pli malmultekostan//ne-Venkan karton.";
EsperantoCardTexts[CardNames.HIGHWAY] = "|+1 Karto|//|+1 Ago|//\u0108i-vice, kartoj//kostas [1] malpli.";
EsperantoCardTexts[CardNames.BERSERKER] = "Gajnu karton kostantan//malpli ol ĉi tion. Ĉiu alia//ludanto forĵetas ĝis tiu//havas 3 kartojn mane.//---//Kiam vi gajnas ĉi tion,//se vi havas Agkarton//en ludo, ludu ĉi tion.";
EsperantoCardTexts[CardNames.GUARD_DOG] = "|+2 Kartoj|//Se vi havas 5 aŭ malpli//kartoj mane, |+2 Kartoj|.//---//Kiam alia ludanto ludas//Atakkarton, vi povas unue//ludi ĉi tion el via mano.";
EsperantoCardTexts[CardNames.NOMADS] = "|+1 Aĉeto|//|+[2]|//---//Kiam vi gajnas aŭ//rubigas ĉi tion, +[2].";
EsperantoCardTexts[CardNames.TRAIL] = "|+1 Karto|//|+1 Ago|//---//Kiam vi gajnas, rubigas,//aŭ forĵetas ĉi tion krom en//Purigfazo, vi povas ludi ĝin.";
EsperantoCardTexts[CardNames.WEAVER] = "Gajnu 2 Arĝentojn aŭ//karton kostantan ĝis [4].//---//Kiam vi forĵetas ĉi tion//krom en Purigfazo,//vi povas ludi ĝin.";
EsperantoCardTexts[CardNames.SOUK] = "|+1 Aĉeto|//|+[7]|//-[1] po karto en via mano.//(Ne subtrahu sub [0].)//---//Kiam vi gajnas ĉi tion, rubigu//ĝis 2 kartojn el via mano.";
EsperantoCardTexts[CardNames.CAULDRON] = "[!2]//|+1 Aĉeto|//Kiam vi triafoje gajnas//Agkarton ĉi-vice, ĉiu alia//ludanto gajnas Malbenon.";
EsperantoCardTexts[CardNames.WHEELWRIGHT] = "|+1 Karto|//|+1 Ago|//Vi povas forĵeti karton//por gajni Agkarton//kostantan tiom, kiom//ĝi aŭ malpli.";
EsperantoCardTexts[CardNames.WITCHS_HUT] = "|+4 Kartoj|//Forĵeti 2 kartojn,//malkaŝante. Se ili ambaŭ//Agkartoj, ĉiu alia ludanto//gajnas Malbenon.";

EsperantoCardTexts[CardNames.CAGE] = "Flankenmetu ĝis 4 kartojn el//via mano surventre (sur ĉi tio).//Venontfoje vi gajnas//Venkkarton, rubigu ĉi tion, kaj metu//la flankenmetitajn kartojn en vian//manon je fino de vico.";
EsperantoCardTexts[CardNames.GROTTO] = "|+1 Ago|////Flankenmetu ĝis 4 kartojn el//via mano surventre (sur ĉi tio).//Je la komenco de via sekva vico,//forĵetu ilin, poste tiru//tiom multe.";
EsperantoCardTexts[CardNames.JEWELLED_EGG] = "|+[1]|//|+1 Aĉeto|//---//Kiam vi rubigas ĉi tion,//gajnu Akiraĵon.";
EsperantoCardTexts[CardNames.SEARCH] = "|+[2]|////Venontfoje Provizstako//malplenas, rubigu ĉi tion//kaj gajnu Akiraĵon.";
EsperantoCardTexts[CardNames.SHAMAN] = "|+1 Ago|//|+[1]|////Vi povas rubigi karton//el via mano.//---//En ludoj, kiuj uzas ĉi tion, je la//komenco de via vico, gajnu karton//el la Rubujo kostantan ĝis [6].";
EsperantoCardTexts[CardNames.SECLUDED_SHRINE] = "|+[1]|////Venontfoje vi gajnas//Trezorkarton, rubigu ĝis//2 kartojn el via mano.";
EsperantoCardTexts[CardNames.SIREN] = "Ĉiu alia ludanto gajnas//Malbenon.////Je la komenco de via sekva vico,//tiru ĝis vi havas 8 kartojn mane.//---//Kiam vi gajnas ĉi tion, rubigu//ĝin krom se vi rubigas Agkarton//el via mano.";
EsperantoCardTexts[CardNames.STOWAWAY] = "Je la komenco de via sekva//vico, |+2 Kartoj|.//---//Kiam iu ajn gajnas Daŭrokarton//vi povas ludi ĉi tion//el via mano.";
EsperantoCardTexts[CardNames.TASKMASTER] = "|+1 Ago|, |+|[1], kaj se vi//gajnas karton kostantan precize [5]//ĉi-vice, poste je la komenco//de via sekvu vico, ripetu//ĉi tiun kapablon";
EsperantoCardTexts[CardNames.ABUNDANCE] = "Venontfoje vi gajnas//Agkarton,//|+1 Aĉeto| kaj |+|[3].";
EsperantoCardTexts[CardNames.CABIN_BOY] = "|+1 Karto|//|+1 Ago|////Je la komenco de via sekva vico,//elektu unu: |+|[2]; aŭ rubigu ĉi tion//por gajni Daŭrokarton.";
EsperantoCardTexts[CardNames.CRUCIBLE] = "Rubigu karton el via mano.//|+|[1] po [1], kiun ĝi kostas.";
EsperantoCardTexts[CardNames.FLAGSHIP] = "|+[2]|////Venontfoje vi ludas//non-Command Action//card, replay it.";
EsperantoCardTexts[CardNames.FORTUNE_HUNTER] = "|+[2]|////Rigardu la 3 suprajn kartojn de//via kartaro. Vi povas ludi//Trezorkarton el ili. Remetu//la ceteron en ajna ordo.";
EsperantoCardTexts[CardNames.GONDOLA] = "Aŭ nun aŭ je la komenco de//via sekva vico: |+|[2].//---//Kiam vi gajnas ĉi tion, vi//povas ludi Agkarton//el via mano.";
EsperantoCardTexts[CardNames.HARBOR_VILLAGE] = "|+1 Karto|//|+2 Agoj|////Post la sekva Agkarto, kiun vi ludas//ĉi-vice, se ĝi donis al vi//|+|[], |+|[1].";
EsperantoCardTexts[CardNames.LANDING_PARTY] = "|+2 Kartoj|//|+2 Agoj|////Venontfoje la unua karto, kiun vi//ludas dum vico estas Trezorkarto,//metu ĉi-tion sur vian kartaron//poste.";
EsperantoCardTexts[CardNames.MAPMAKER] = "Rigardu la 4 suprajn karton//de via kartaro. Metu 2 en vian//manon kaj forĵetu la ceteron.//---//Kiu ajna ludanto gajnas Venkkarton//vi povas ludi ĉi tion el//via mano.";
EsperantoCardTexts[CardNames.MAROON] = "Rubigu karton el via mano.//|+2 Kartoj| po speco, kiun ĝi havas//(Ago, Atako, ktp.).";
EsperantoCardTexts[CardNames.ROPE] = "|+[1]|//|+1 Aĉeto|////Je la komenco de via sekva vico,//|+1 Karto| kaj vi povas rubigi//karton el via mano.";
EsperantoCardTexts[CardNames.SWAMP_SHACKS] = "|+2 Agoj|////|+1 Karto| po 3 kartoj, kiujn//vi havas en ludo (subrondigu).";
EsperantoCardTexts[CardNames.TOOLS] = "Gajnu kopion de karto,//kiun iu ajn havas en ludo.";
EsperantoCardTexts[CardNames.BURIED_TREASURE] = "Je la komenco de via sekva//vico, |+1 Aĉeto| kaj |+|[3].//---//Kiam vi gajnas ĉi tion,//ludu ĝin.";
EsperantoCardTexts[CardNames.CREW] = "|+3 Kartoj|////Je la komenco de via sekva//vico, metu ĝin sur vian kartaron.";
EsperantoCardTexts[CardNames.CUTTHROAT] = "Ĉiu alia ludanto forĵetas//ĝis tiu havas 3 kartojn mane.//Venontfoje iu ajn gajnas//Trezorkarton kostantan [5] aŭ//pli, gajnu Akiraĵon.";
EsperantoCardTexts[CardNames.ENLARGE] = "Nun kaj je la komenco de via//sekva vico: Rubigu karton el//via mano, kaj gajnu unu//kostantan ĝis [2] pli.";
EsperantoCardTexts[CardNames.FIGURINE] = "|+2 Kartoj|////Vi povas forĵeti Agkarton//por |+1 Aĉeto| kaj |+|[1].";
EsperantoCardTexts[CardNames.FIRST_MATE] = "Ludu iom ajn da Agkartoj//kun la sama nomo el via//mano, poste tiru ĝis vi//havas 6 kartojn mane.";
EsperantoCardTexts[CardNames.FRIGATE] = "|+[3]|////Ĝis la komenco de via sekva//vico, ĉiufoje alia ludanto//ludas Agkarton, tiu forĵetas//ĝis tiu havas 4 kartojn//mane poste.";
EsperantoCardTexts[CardNames.LONGSHIP] = "|+2 Agoj|////Je la komenco de via sekva//vico, |+2 Kartoj|.";
EsperantoCardTexts[CardNames.MINING_ROAD] = "|+1 Ago|//|+1 Aĉeto|//|+[2]|////Unufoje ĉi-vice, kiam vi//gajnas Trezorkarton, vi//povas ludi ĝin.";
EsperantoCardTexts[CardNames.PENDANT] = "|+|[1] po malsame//nomata Trezorkarton, kiun//vi havas en ludo.";
EsperantoCardTexts[CardNames.PICKAXE] = "|+[1]|////Rubigu karton el via mano.//Se ĝi kostas [3] aŭ pli,//gajnu Akiraĵon al via mano.";
EsperantoCardTexts[CardNames.PILGRIM] = "|+4 Kartoj|////Metu karton el via mano//sur vian kartaron.";
EsperantoCardTexts[CardNames.QUARTERMASTER] = "Je la komenco de ĉiuj viaj//vicoj dum la cetero de la ludo,//elektu unu: Gajnu karton kostantan//ĝis [4], flankenmetante ĝin sur//ĉi tio; aŭ metu karton el ĉi tio//en vian manon.";
EsperantoCardTexts[CardNames.SILVER_MINE] = "Gajnu Trezorkarton kostantan malpli//ol ĝi al via mano.";
EsperantoCardTexts[CardNames.TRICKSTER] = "Ĉiu alia ludanto gajnas Malbenon.//Unufoje ĉi-vice, kiam vi forĵetas//Trezorkarton el ludo, vi povas//flankenmetu ĝin. Metu ĝin en vian//manon je fino de vico.";
EsperantoCardTexts[CardNames.WEALTHY_VILLAGE] = "|+1 Karto|//|+2 Agoj|//---//Kiam vi gajnas ĉi tion, se vi//havas almenaŭ 3 malsame//nomatajn Trezorkartojn en ludo,//gajnu Akiraĵon.";
EsperantoCardTexts[CardNames.SACK_OF_LOOT] = "|+[1]|//|+1 Aĉeto|//Gajnu Akiraĵon.";
EsperantoCardTexts[CardNames.KINGS_CACHE] = "Vi povas ludi Trezorkarton//el via mano trifoje.";
EsperantoCardTexts[CardNames.BURY] = "|+1 Aĉeto|////Metu ajnan karton el via forĵeta stako//sur la fundon de via kartaro.";
EsperantoCardTexts[CardNames.AVOID] = "|+1 Aĉeto|////Venontfoje vi miksas ĉi-vice, elektu ĝis 3//kartojn por meti en vian forĵetan stakon.";
EsperantoCardTexts[CardNames.DELIVER] = "|+1 Aĉeto|////Ĉi-vice, ĉiufoje vi gajnas karton, flankenmetu ĝin,//kaj metu ĝin en vian manon je fino de vico.";
EsperantoCardTexts[CardNames.PERIL] = "Vi povas rubigi Agkarton el//via mano por gajni Akiraĵon.";
EsperantoCardTexts[CardNames.RUSH] = "|+1 Aĉeto|////Venontfoje vi gajnas Agkarton ĉi-vice, ludu ĝin.";
EsperantoCardTexts[CardNames.FORAY] = "Forĵetu 3 kartojn, malkaŝante ilin. Se ili//havas 3 malsamajn nomojn, gajnu Akiraĵon.";
EsperantoCardTexts[CardNames.LAUNCH] = "Unufoje po vico: Reiru al via Agfazo.//|+1 Karto|, |+1 Ago|, kaj |+1 Aĉeto|.";
EsperantoCardTexts[CardNames.MIRROR] = "|+1 Aĉeto|////Venontfoje vi gajnas Agkarton//ĉi-vice, gajnu kopion de ĝi.";
EsperantoCardTexts[CardNames.PREPARE] = "Flankenmetu vian manon surdorse. Je la komenco de//via sekva vico, ludu tiujn Agkartojn kaj Trezorkartojn//en ajna ordo, poste forĵetu la ceteron.";
EsperantoCardTexts[CardNames.SCROUNGE] = "Elektu unu: Rubigu karton el via mano;//aŭ gajnu Bienon el la Rubujo, kaj se vi faris tion,//gajnu karton kostantan ĝis [5].";
EsperantoCardTexts[CardNames.JOURNEY] = "Unufoje po vico: Se la antaŭa vico ne estis via, vi ne//forĵetas kartojn el ludo dum Purigfazo ĉi-vice, kaj ludu//ekstran vicon post ĉi tiu.";
EsperantoCardTexts[CardNames.MAELSTROM] = "Rubigu 3 kartojn el via mano. Ĉiu alia ludanto kun 5//aŭ pli da kartoj mane rubigas unu el ili.";
EsperantoCardTexts[CardNames.LOOTING] = "Gajnu Akiraĵon.";
EsperantoCardTexts[CardNames.INVASION] = "Vi povas ludi Atakkarton el via mano. Gajnu Duklandon.//Gajnu Agkarton sur vian kartaron. Gajnu Akiraĵon; ludu ĝin.";
EsperantoCardTexts[CardNames.PROSPER] = "Gajnu Akiraĵon, kaj iom ajn da//malsame nomataj Trezorkartoj.";
EsperantoCardTexts[CardNames.AMPHORA] = "Aŭ nun aŭ je la//komenco de via sekva vico://|+1 Aĉeto| kaj |+|[3].";
EsperantoCardTexts[CardNames.DOUBLOONS] = "|+[3]|//---//Kiam vi gajnas ĉi tion,//Gajnu Oron.";
EsperantoCardTexts[CardNames.ENDLESS_CHALICE] = "Nun kaj je la komenco de//ĉiuj viaj vicoj por//la cetero de la ludo://|+|[1] kaj |+1 Aĉeto|.";
EsperantoCardTexts[CardNames.FIGUREHEAD] = "|+[3]|////Je la komenco de via//sekva vico, |+2 Kartoj|.";
EsperantoCardTexts[CardNames.HAMMER] = "|+[3]|////Gajnu karton kostantan//ĝis [4].";
EsperantoCardTexts[CardNames.INSIGNIA] = "|+[3]|////Ĉi-vice, kiam vi//gajnas karton, vi povas//metu ĝin sur vian kartaron.";
EsperantoCardTexts[CardNames.JEWELS] = "|+[3]|//|+1 Aĉeto|////Je la komenco de via//sekva vico, metu ĉi tion//sur la fundon de via kartaro.";
EsperantoCardTexts[CardNames.ORB] = "Trarigardu vian forĵetan//stakon. Elektu unu: Ludu//Ag- aŭ Trezorkarton el//ĝi; aŭ |+1 Aĉeto| kaj |+|[3].";
EsperantoCardTexts[CardNames.PRIZE_GOAT] = "|+[3]|//|+1 Aĉeto|////Vi povas rubigi karton//el via mano.";
EsperantoCardTexts[CardNames.PUZZLE_BOX] = "|+[3]|//|+1 Aĉeto|////Vi povas flankenmeti karton//el via mano surventre.//Metu ĝin en vian manon je//fino de vico.";
EsperantoCardTexts[CardNames.SEXTANT] = "|+[3]|//|+1 Aĉeto|////Rigardu la 5 suprajn kartojn de//via kartaro. Forĵetu iom//ajn da ili. Remetu la//ceteron en ajna ordo.";
EsperantoCardTexts[CardNames.SHIELD] = "|+[3]|//|+1 Aĉeto|//---//Kiam alia ludanto ludas//Atakkarton, vi povas unue//malkaŝi ĉi tion el via mano//por esti ne tuŝita.";
EsperantoCardTexts[CardNames.SPELL_SCROLL] = "Rubigu ĉi tion por gajni//malmultekostan karton. Se ĝi//estas Ag- aŭ Trezorkarton,//vi povas ludi ĝin.";
EsperantoCardTexts[CardNames.STAFF] = "|+[3]|//|+1 Aĉeto|////Vi povas ludi Agkarton//el via mano.";
EsperantoCardTexts[CardNames.SWORD] = "|+[3]|//|+1 Aĉeto|////Ĉiu alia ludanto forĵetas//ĝis tiu havas 4 kartojn mane.";

EsperantoCardTexts[CardNames.CHEAP] = "Cheap cards cost [1] less.";
EsperantoCardTexts[CardNames.CURSED] = "When you gain a Cursed card, gain//a Loot and a Curse.";
EsperantoCardTexts[CardNames.FAWNING] = "When you gain a Province,//gain a Fawning card.";
EsperantoCardTexts[CardNames.FATED] = "When shuffling, you may look through the cards and reveal//Fated cards to put them on the top or bottom.";
EsperantoCardTexts[CardNames.FRIENDLY] = "At the start of your Clean-up//phase, you may discard a Friendly//card to gain a Friendly card.";
EsperantoCardTexts[CardNames.HASTY] = "When you gain a Hasty card, set//it aside, and play it at the start//of your next turn.";
EsperantoCardTexts[CardNames.INHERITED] = "Setup: You start the game with an Inherited//card in place of a starting card you choose.";
EsperantoCardTexts[CardNames.INSPIRING] = "After playing an Inspiring card on your turn,//you may play an Action from your hand that you//don't have a copy of in play.";
EsperantoCardTexts[CardNames.NEARBY] = "When you gain a Nearby card, |+1 Buy|.";
EsperantoCardTexts[CardNames.PATIENT] = "At the start of your Clean-up phase, you may//set aside Patient cards from your hand, to play them//at the start of your next turn.";
EsperantoCardTexts[CardNames.PIOUS] = "When you gain a Pious card, you may//trash a card from your hand.";
EsperantoCardTexts[CardNames.RECKLESS] = "Follow the instructions of played Reckless cards twice.//When discarding one from play, return it//to its pile.";
EsperantoCardTexts[CardNames.RICH] = "When you gain a Rich card, gain a Silver.";
EsperantoCardTexts[CardNames.SHY] = "At the start of your turn, you may//discard one Shy card for |+2 Cards|.";
EsperantoCardTexts[CardNames.TIRELESS] = "When you discard a Tireless card from//play, set it aside, and put it//onto your deck at end of turn.";
EsperantoCardTexts[CardNames.MARCHLAND] = "Worth {1} per 3//Victory cards you have.//---//When you gain this, |+1 Buy|,//and discard any number of//cards for +[1] each.";

EsperantoCardTexts[CardNames.SHOP] = "|+1 Card|//|+[1]|//You may play an Action card//from your hand that you don't//have a copy of in play.\n";
EsperantoCardTexts[CardNames.CARNIVAL] = "Reveal the top 4 cards//of your deck. Put one//of each differently named//card into your hand//and discard the rest.";
EsperantoCardTexts[CardNames.FERRYMAN] = "|+2 Cards|//|+1 Action|//Discard a card.//---//Setup: Choose an unused Kingdom//pile costing [3] or [4]. Gain//one when you gain a Ferryman.";
EsperantoCardTexts[CardNames.FARMHANDS] = "|+1 Card|//|+2 Actions|//---//When you gain this, you may//set aside an Action or Treasure//from your hand, and play it at//the start of your next turn.";
EsperantoCardTexts[CardNames.JOUST] = "|+1 Card|//|+1 Action|//|+[1]|//You may set aside a Province//from your hand to gain any//Reward to your hand. Discard//the Province in Clean-up.";
EsperantoCardTexts[CardNames.REWARDS_PILE] = "--";
EsperantoCardTexts[CardNames.COURSER] = "Choose two different options://|+2 Cards|; |+2 Actions|;//|+|[2]; gain 4 Silvers.////%(This is not in the Supply.)%";
EsperantoCardTexts[CardNames.RENOWN] = "|+1 Buy|////This turn, cards cost [2] less.////%(This is not in the Supply.)%";
EsperantoCardTexts[CardNames.CORONET] = "You may play a non-Reward//Action from your hand twice.////You may play a non-Reward//Treasure from your hand twice.////%(This is not in the Supply.)%";
EsperantoCardTexts[CardNames.DEMESNE] = "|+2 Actions|//|+2 Buys|//Gain a Gold.//---//Worth {1} per Gold you have.////%(This is not in the Supply.)%";
EsperantoCardTexts[CardNames.HOUSECARL] = "|+1 Card| per differently named//Action card you have in play.////%(This is not in the Supply.)%";
EsperantoCardTexts[CardNames.HUGE_TURNIP] = "|+2 Coffers|//|+|[1] per Coffers you have.////%(This is not in the Supply.)%";
EsperantoCardTexts[CardNames.FARRIER] = "|+1 Card|//|+1 Action|//|+1 Buy|//---//Overpay: |+1 Card| at the end of//this turn per [1] overpaid.";
EsperantoCardTexts[CardNames.INFIRMARY] = "|+1 Card|//You may trash a card//from your hand.//---//Overpay: Play this once//per [1] overpaid.";
EsperantoCardTexts[CardNames.FOOTPAD] = "|+2 Coffers|//Each other player discards//down to 3 cards in hand.//---//In games using this, when//you gain a card in an//Action phase, |+1 Card|.\n";

EsperantoCardTexts[CardNames.FISHMONGER] = "|+1 Buy|//|+[1]|//---//You can play this from your//deck as if in your hand.";
EsperantoCardTexts[CardNames.SNAKE_WITCH] = "|+1 Card|//|+1 Action|//If your hand has no duplicate//cards, you may reveal it and//return this to its pile, to have//each other player gain a Curse.";
EsperantoCardTexts[CardNames.ARISTOCRAT] = "If the number of Aristocrats//you have in play is:////1 or 5: |+3 Actions|;//2 or 6: |+3 Cards|;//3 or 7: |+|[3];//4 or 8: |+3 Buys|.";
EsperantoCardTexts[CardNames.CRAFTSMAN] = "|+[2D]|////Gain a card//costing up to [5].";
EsperantoCardTexts[CardNames.RIVERBOAT] = "At the start of your next//turn, play the set aside//card, leaving it there.//---//Setup: Set aside an//unused non-Duration//Action card costing [5].";
EsperantoCardTexts[CardNames.ROOT_CELLAR] = "|+3 Cards|//|+1 Action|//|+[3D]|";
EsperantoCardTexts[CardNames.ALLEY] = "|+1 Card|//|+1 Action|//Discard a card.//---//You can play this from your//deck as if in your hand.";
EsperantoCardTexts[CardNames.CHANGE] = "If you have any [D], |+|[3].////Otherwise, trash a card from//your hand, and gain a card//costing more [] than it. +[D]//equal to the difference in [].";
EsperantoCardTexts[CardNames.NINJA] = "|+1 Card|//Each other player discards//down to 3 cards in hand.//---//You can play this from your//deck as if in your hand.";
EsperantoCardTexts[CardNames.POET] = "|+1 <>|//|+1 Card|//|+1 Action|//Reveal the top card of your//deck. If it costs [3] or less,//put it into your hand.";
EsperantoCardTexts[CardNames.RIVER_SHRINE] = "|+1 <>|////Trash up to 2 cards from your//hand. At the start of Clean-up,//if you didn't gain any cards in//your Buy phase this turn, gain//a card costing up to [4].";
EsperantoCardTexts[CardNames.RUSTIC_VILLAGE] = "|+1 <>|//|+1 Card|//|+2 Actions|//You may discard//2 cards for |+1 Card|.";
EsperantoCardTexts[CardNames.GOLD_MINE] = "|+1 Card|//|+1 Action|//|+1 Buy|//You may gain a//Gold and get +[4D].";
EsperantoCardTexts[CardNames.IMPERIAL_ENVOY] = "|+5 Cards|//|+1 Buy|//|+[2D]|";
EsperantoCardTexts[CardNames.KITSUNE] = "|+1 <>|//Choose two different options://|+|[2]; |+2 Actions|; each other//player gains a Curse; gain//a Silver.";
EsperantoCardTexts[CardNames.LITTER] = "|+2 Cards|//|+2 Actions|//|+[1D]|";
EsperantoCardTexts[CardNames.RICE_BROKER] = "|+1 Action|//Trash a card from your hand.//If it's a Treasure, |+2 Cards|.//If it's an Action, |+5 Cards|.";
EsperantoCardTexts[CardNames.RONIN] = "Draw until you have//7 cards in hand.//---//You can play this from your//deck as if in your hand.";
EsperantoCardTexts[CardNames.TANUKI] = "Trash a card from your//hand. Gain a card costing//up to [2] more than it.//---//You can play this from your//deck as if in your hand.";
EsperantoCardTexts[CardNames.TEA_HOUSE] = "|+1 <>|//|+1 Card|//|+1 Action|//|+[2]|";
EsperantoCardTexts[CardNames.SAMURAI] = "Each other player discards//down to 3 cards in hand (once).//At the start of each of//your turns this game, +[1].//%(This stays in play.)%";
EsperantoCardTexts[CardNames.RICE] = "|+1 Buy|//|+|[1] per different type among//cards you have in play.";
EsperantoCardTexts[CardNames.MOUNTAIN_SHRINE] = "|+1 <>|//+[2]//You may trash a card from//your hand. Then if there//are any Action cards//in the trash, |+2 Cards|";
EsperantoCardTexts[CardNames.DAIMYO] = "|+1 Card|//|+1 Action|//The next time you//play a non-Command//Action card this turn,//replay it afterwards.";
EsperantoCardTexts[CardNames.ARTIST] = "|+1 Action|////|+1 Card| per card you//have exactly one//copy of in play.";
EsperantoCardTexts[CardNames.APPROACHING_ARMY] = "After you play an Attack card, +[1].//---//Setup: Add an Attack to the game.";
EsperantoCardTexts[CardNames.BIDING_TIME] = "At the start of your Clean-up, set aside your hand face down.//At the start of your next turn, put those cards into your hand.";
EsperantoCardTexts[CardNames.BUREAUCRACY] = "When you gain a card that doesn't//cost [0], gain a Copper.";
EsperantoCardTexts[CardNames.DIVINE_WIND] = "When you remove the last| |<>, remove all//Kingdom cards from the Supply, and//set up 10 new random ones.";
EsperantoCardTexts[CardNames.ENLIGHTENMENT] = "Treasures are also Actions. When you play a//Treasure in an Action phase, instead of following//its instructions, |+1 Card| and |+1 Action|.";
EsperantoCardTexts[CardNames.FLOURISHING_TRADE] = "Cards cost [1] less.//You may use Action plays as Buys.";
EsperantoCardTexts[CardNames.GOOD_HARVEST] = "The first time you play each differently//named Treasure each turn, first, |+1 Buy| and +[1].";
EsperantoCardTexts[CardNames.GREAT_LEADER] = "After each Action card you play, |+1 Action|.";
EsperantoCardTexts[CardNames.GROWTH] = "When you gain a Treasure,//gain a cheaper card.";
EsperantoCardTexts[CardNames.HARSH_WINTER] = "When you gain a card on your turn,//if there's [D] on its pile, take it;//otherwise put [2D] on its pile.";
EsperantoCardTexts[CardNames.KIND_EMPEROR] = "At the start of your turn, and when you//remove the last| |<>: Gain an Action to your hand.";
EsperantoCardTexts[CardNames.PANIC] = "When you play a Treasure, |+2 Buys|, and when you//discard one from play, return it to its pile.";
EsperantoCardTexts[CardNames.PROGRESS] = "When you gain a card, put it onto your deck.";
EsperantoCardTexts[CardNames.RAPID_EXPANSION] = "When you gain an Action or Treasure, set it//aside, and play it at the start of your next turn.";
EsperantoCardTexts[CardNames.SICKNESS] = "At the start of your turn, choose one://Gain a Curse onto your deck; or discard 3 cards.";
EsperantoCardTexts[CardNames.AMASS] = "If you have no Action cards in play,//gain an Action card costing up to [5].";
EsperantoCardTexts[CardNames.ASCETICISM] = "Pay any amount of [] to trash//that many cards from your hand.";
EsperantoCardTexts[CardNames.CREDIT] = "Gain an Action or Treasure costing//up to [8]. +[D] equal to its cost.";
EsperantoCardTexts[CardNames.FORESIGHT] = "Reveal cards from your deck until revealing an Action.//Set it aside and discard the rest.//Put it into your hand at end of turn.";
EsperantoCardTexts[CardNames.KINTSUGI] = "Trash a card from your hand. If you've gained a Gold this game,//gain a card costing up to [2] more than the trashed card.";
EsperantoCardTexts[CardNames.PRACTICE] = "You may play an Action card from your hand twice.";
EsperantoCardTexts[CardNames.SEA_TRADE] = "|+1 Card| per Action card you have in play.//Trash up to that many cards from your hand.";
EsperantoCardTexts[CardNames.RECEIVE_TRIBUTE] = "If you've gained at least 3 cards this turn,//gain up to 3 differently named Action cards//you don't have copies of in play.";
EsperantoCardTexts[CardNames.GATHER] = "Gain a card costing exactly [3],//a card costing exactly [4],//and a card costing exactly [5].";
EsperantoCardTexts[CardNames.CONTINUE] = "Once per turn: Gain a non-Attack Action card costing up to [4].//Return to your Action phase and play it. |+1 Action| and |+1 Buy|.";