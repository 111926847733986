"use strict";

var CzechQuestionDescriptions = {};

CzechQuestionDescriptions[QuestionIds.GAME_RESOLVE_ABILITY] = ["Resolve a forced ability:", "Vyřeš efekt"];
CzechQuestionDescriptions[QuestionIds.GAME_MAY_RESOLVE_ABILITY] = ["Resolve an optional ability.", "Můžeš vyřešit efekt"];
CzechQuestionDescriptions[QuestionIds.GAME_MAY_REACT_WITH] = ["React with something", "Chceš reagovat?"];
CzechQuestionDescriptions[QuestionIds.GAME_TURN_SELECT] = ["Select a turn.", "Jaký tah další?"];
CzechQuestionDescriptions[QuestionIds.GAME_MAY_BUY] = ["buy cards.", "Můžeš nakoupit karty"];
CzechQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING] = ["start buying cards.", "Můžeš nakoupit karty"];
CzechQuestionDescriptions[QuestionIds.GAME_MAY_BUY_NONCARDS] = ["buy, but not cards.", "You may buy, but not cards"];
CzechQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING_NONCARDS] = ["start buying, but not cards.", "You may buy, but not cards"];
CzechQuestionDescriptions[QuestionIds.GAME_REPAY_HOW_MUCH] = ["Repay debt.", "You may pay off debt"];
CzechQuestionDescriptions[QuestionIds.GAME_SPEND_COIN_TOKENS] = ["spend coffers tokens.", "You may spend coffers tokens"];
CzechQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_ACTION] = ["play an Action from your hand.", "You may play Actions"];
CzechQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_TREASURES] = ["play treasures.", "You may play Treasures"];
CzechQuestionDescriptions[QuestionIds.GAME_PLAY_ALL_TREASURES] = ["autoplay treasures.", "GAME_PLAY_ALL_TREASURES"];
CzechQuestionDescriptions[QuestionIds.GAME_ACTION_PHASE] = ["It's your action phase", "You may play Actions"];
CzechQuestionDescriptions[QuestionIds.GAME_BUY_PHASE] = ["It's your buy phase", "You may buy cards"];
CzechQuestionDescriptions[QuestionIds.GAME_BUY_PHASE_NONCARDS] = ["It's your buy phase", "You may buy, but not cards"];
CzechQuestionDescriptions[QuestionIds.GAME_CLEANUP_PHASE] = ["Choose clean-up items and resolution order.", "Choose cleanup abilities"];
CzechQuestionDescriptions[QuestionIds.GAME_USE_VILLAGER] = ["You may use a Villager.", "You may use a Villager"];
CzechQuestionDescriptions[QuestionIds.ARTISAN_GAIN] = ["Gain a card to your hand costing up to $5. ", "Gain a card"];
CzechQuestionDescriptions[QuestionIds.ARTISAN_TOPDECK] = ["Put a card from your hand onto your deck.", "Topdeck a card"];
CzechQuestionDescriptions[QuestionIds.BANDIT] = ["Trash a card from PLAYER0.", "Trash a card from PLAYER0."];
CzechQuestionDescriptions[QuestionIds.BUREAUCRAT] = ["Reveal a Victory card from your hand and put it onto your deck.", "Topdeck a Victory card"];
CzechQuestionDescriptions[QuestionIds.CELLAR] = ["Discard any number of cards, then draw that many.", "Discard, then draw"];
CzechQuestionDescriptions[QuestionIds.CHAPEL] = ["Trash up to 4 cards from your hand.", "Trash up to 4 cards"];
CzechQuestionDescriptions[QuestionIds.HARBINGER_TOPDECK] = ["You may topdeck a card from your discard pile to put onto your deck.", "You may topdeck"];
CzechQuestionDescriptions[QuestionIds.LIBRARY] = ["You may skip CARDS0 by setting it aside.", "You may skip CARDS0"];
CzechQuestionDescriptions[QuestionIds.MILITIA] = ["Discard down to 3 cards in hand.", "Discard down to 3"];
CzechQuestionDescriptions[QuestionIds.MINE_TRASH] = ["You may trash a Treasure from your hand. Gain a Treasure to your hand costing up to $3 more than it.", "You may improve a Treasure"];
CzechQuestionDescriptions[QuestionIds.MINE_GAIN] = ["Gain a Treasure to your hand costing up to AMOUNT0.", "Gain a Treasure up to AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.MONEYLENDER] = ["You may trash a Copper from your hand for +$3.", "You may trash a Copper"];
CzechQuestionDescriptions[QuestionIds.POACHER] = ["Discard Argument0.", "Discard Argument0."];
CzechQuestionDescriptions[QuestionIds.REMODEL_TRASH] = ["Trash a card from your hand. Gain a card costing up to $2 more than it.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.REMODEL_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card up to AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.SENTRY_TRASH] = ["Trash any of the revealed cards", "You may trash"];
CzechQuestionDescriptions[QuestionIds.SENTRY_DISCARD] = ["Discard any of the revealed cards", "You may discard"];
CzechQuestionDescriptions[QuestionIds.SENTRY_TOPDECK] = ["Put them back onto your deck in any order", "Topdeck in any order"];
CzechQuestionDescriptions[QuestionIds.THRONE_ROOM] = ["You may play an Action card from your hand twice.", "You may play an Action twice"];
CzechQuestionDescriptions[QuestionIds.VASSAL] = ["You may play CARDS0 from your discard pile.", "You may play CARDS0"];
CzechQuestionDescriptions[QuestionIds.WORKSHOP] = ["Gain a card costing up to $4.", "Gain a card"];
CzechQuestionDescriptions[QuestionIds.TRASHING_TOKEN] = ["You may trash a card from your hand.", "You may trash a card"];
CzechQuestionDescriptions[QuestionIds.ALMS] = ["Gain a card costing up to $4.", "Gain a card"];
CzechQuestionDescriptions[QuestionIds.AMULET_MODE] = ["Choose one: +$1; or trash a card from your hand; or gain a Silver.", "Choose how to play Amulet"];
CzechQuestionDescriptions[QuestionIds.AMULET_TRASH] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.ARTIFICER_DELAY] = ["You may discard any number of cards. You may gain a card costing exactly $1 per card discarded, onto your deck.", "You may use Artificer"];
CzechQuestionDescriptions[QuestionIds.ARTIFICER_DISCARD] = ["Discard any number of cards. You may gain a card costing exactly $1 per card discarded, onto your deck.", "Discard, then gain"];
CzechQuestionDescriptions[QuestionIds.ARTIFICER_GAIN] = ["You may gain a card costing exactly AMOUNT0, onto your deck.", "You may gain a card for AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.BALL_FIRST] = ["Gain 2 cards each costing up to $4.", "Gain two cards"];
CzechQuestionDescriptions[QuestionIds.BALL_SECOND] = ["Gain another card costing up to $4.", "Gain another card"];
CzechQuestionDescriptions[QuestionIds.BONFIRE] = ["Trash up to 2 cards you have in play.", "Trash up to 2 cards from play"];
CzechQuestionDescriptions[QuestionIds.DISCIPLE] = ["You may play an Action card from your hand twice. Gain a copy of it.", "Play twice, then gain a copy"];
CzechQuestionDescriptions[QuestionIds.DUNGEON] = ["Discard 2 cards.", "Discard 2 cards"];
CzechQuestionDescriptions[QuestionIds.FERRY] = ["Move your -$2 cost token to an Action Supply pile.", "Move -$2 cost token"];
CzechQuestionDescriptions[QuestionIds.FUGITIVE] = ["Discard a card.", "Discard a card"];
CzechQuestionDescriptions[QuestionIds.GEAR] = ["Set aside up to 2 cards from your hand. At the start of your next turn, put them into your hand.", "Set aside up to 2 cards"];
CzechQuestionDescriptions[QuestionIds.HAUNTED_WOODS] = ["Put your hand onto your deck in any order", "Put your hand onto your deck"];
CzechQuestionDescriptions[QuestionIds.HERO] = ["Gain a Treasure.", "Gain a Treasure"];
CzechQuestionDescriptions[QuestionIds.LOST_ARTS_NEW] = ["Put your +1 Action token on an Action Supply pile.", "Move +1 Action token"];
CzechQuestionDescriptions[QuestionIds.LOST_ARTS_MOVE] = ["Move your +1 Action token to an Action Supply pile.", "Move +1 Action token"];
CzechQuestionDescriptions[QuestionIds.MESSENGER_DISCARD] = ["You may put your deck into your discard pile.", "You may discard your deck"];
CzechQuestionDescriptions[QuestionIds.MESSENGER_GAIN] = ["Gain a card costing up to $4, and each other player gains a copy of it.", "Distribute a card costing up to $4"];
CzechQuestionDescriptions[QuestionIds.MISER] = ["Choose one: Put a Copper from your hand onto your Tavern mat; or +AMOUNT0", "Copper to mat or +AMOUNT0?"];
CzechQuestionDescriptions[QuestionIds.PATHFINDING_NEW] = ["Put your +1 Card token on an Action Supply pile.", "Move +1 Card token"];
CzechQuestionDescriptions[QuestionIds.PATHFINDING_MOVE] = ["Move your +1 Card token to an Action Supply pile.", "Move +1 Card token"];
CzechQuestionDescriptions[QuestionIds.PILGRIMAGE_FIRST] = ["You may choose up to 3 differently named cards you have in play and gain a copy of each.", "Choose 3 cards to gain"];
CzechQuestionDescriptions[QuestionIds.PILGRIMAGE_SECOND] = ["You may choose another 2 differently named cards you have in play and gain a copy of each.", "Choose 2 cards to gain"];
CzechQuestionDescriptions[QuestionIds.PILGRIMAGE_THIRD] = ["You may choose one more differently named card you have in play to gain a copy of.", "Choose 1 card to gain"];
CzechQuestionDescriptions[QuestionIds.PLAN_NEW] = ["Put your Trashing token on an Action Supply pile.", "Move Trashing token"];
CzechQuestionDescriptions[QuestionIds.PLAN_MOVE] = ["Move your Trashing token to an Action Supply pile.", "Move Trashing token"];
CzechQuestionDescriptions[QuestionIds.QUEST_MODE] = ["Choose between discarding an Attack, two Curses, or six cards.", "Choose your quest"];
CzechQuestionDescriptions[QuestionIds.QUEST_ATTACK] = ["Discard an Attack card.", "Discard an Attack"];
CzechQuestionDescriptions[QuestionIds.QUEST_6CARDS] = ["Discard six cards.", "Discard six cards"];
CzechQuestionDescriptions[QuestionIds.RATCATCHER] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_PLAY] = ["You may trash Raze from play.", "You may trash Raze"];
CzechQuestionDescriptions[QuestionIds.RAZE_ALL] = ["Trash a card from your hand or Raze from play.", "Trash a card or trash Raze"];
CzechQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_HAND] = ["You may trash a card from your hand.", "You may trash a card"];
CzechQuestionDescriptions[QuestionIds.RAZE_CHOOSE_CARD] = ["Choose a card to put into your hand.", "Put a card into your hand"];
CzechQuestionDescriptions[QuestionIds.SAVE] = ["Set aside a card from your hand, and put it into your hand at end of turn (after drawing).", "Set a card aside"];
CzechQuestionDescriptions[QuestionIds.SCOUTING_PARTY_DISCARD] = ["Discard NUMBER0 cards.", "Discard NUMBER0 cards."];
CzechQuestionDescriptions[QuestionIds.SCOUTING_PARTY_TOPDECK] = ["Put the remaining two cards on top of your deck in any order.", "Topdeck these cards"];
CzechQuestionDescriptions[QuestionIds.SEAWAY] = ["Gain an Action card costing up to $4. Move your +1 Buy token to its pile.", "Gain an Action card"];
CzechQuestionDescriptions[QuestionIds.SOLDIER] = ["Discard a card.", "Discard a card"];
CzechQuestionDescriptions[QuestionIds.STORYTELLER_FIRST] = ["Play up to 3 Treasures from your hand.", "Play up to 3 Treasures"];
CzechQuestionDescriptions[QuestionIds.STORYTELLER_SECOND] = ["Play up to 2 more Treasures from your hand.", "Play up to 2 Treasures"];
CzechQuestionDescriptions[QuestionIds.STORYTELLER_THIRD] = ["You may play another Treasure from your hand.", "You may play another Treasure"];
CzechQuestionDescriptions[QuestionIds.TEACHER_CHOOSE_TOKEN] = ["Choose which token to move.", "Choose which token"];
CzechQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_MOVE] = ["Put your +1 Action token on an Action Supply pile you have no tokens on.", "Move +1 Action token"];
CzechQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_NEW] = ["Move your +1 Action token to an Action Supply pile you have no tokens on.", "Move +1 Action token"];
CzechQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_MOVE] = ["Put your +1 Card token on an Action Supply pile you have no tokens on.", "Move +1 Card token"];
CzechQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_NEW] = ["Move your +1 Card token to an Action Supply pile you have no tokens on. ", "Move +1 Card token"];
CzechQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_MOVE] = ["Put your +1 Coin token on an Action Supply pile you have no tokens on.", "Move +1 Coin token"];
CzechQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_NEW] = ["Move your +1 Coin token to an Action Supply pile you have no tokens on.", "Move +1 Coin token"];
CzechQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_MOVE] = ["Put your +1 Buy token on an Action Supply pile you have no tokens on.", "Move +1 Buy token"];
CzechQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_NEW] = ["Move your +1 Buy token to an Action Supply pile you have no tokens on.", "Move +1 Buy token"];
CzechQuestionDescriptions[QuestionIds.TRADE] = ["Trash up to 2 cards from your hand. Gain a Silver per card you trashed.", "Trash up to 2 cards"];
CzechQuestionDescriptions[QuestionIds.TRAINING_NEW] = ["Put your +1 Coin token on an Action Supply pile.", "Move +1 Coin token"];
CzechQuestionDescriptions[QuestionIds.TRAINING_MOVE] = ["Move your +1 Coin token to an Action Supply pile.", "Move +1 Coin token"];
CzechQuestionDescriptions[QuestionIds.TRANSMOGRIFY_TRASH] = ["Trash a card from your hand, and gain a card costing up to $1 more than it into your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.TRANSMOGRIFY_GAIN] = ["Gain a card costing up to AMOUNT0 into your hand.", "Gain a card up to AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.APOTHECARY] = ["Put the cards back on your deck in any order.", "Topdeck these cards"];
CzechQuestionDescriptions[QuestionIds.APPRENTICE] = ["Trash a card from your hand. +1 Card per $1 it costs. +2 Cards if it has [P] in its cost.", "Trash, then draw"];
CzechQuestionDescriptions[QuestionIds.GOLEM] = ["Choose which Action to play first.", "Choose play order"];
CzechQuestionDescriptions[QuestionIds.HERBALIST] = ["You may put one of your Treasures from play onto your deck.", "You may topdeck a Treasure"];
CzechQuestionDescriptions[QuestionIds.SCRYING_POOL_YOU] = ["Keep or discard CARDS0", "You: keep or discard CARDS0"];
CzechQuestionDescriptions[QuestionIds.SCRYING_POOL_OTHER] = ["Choose whether PLAYER1 should keep or discard CARDS0.", "PLAYER1: keep or discard CARDS0."];
CzechQuestionDescriptions[QuestionIds.TRANSMUTE] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.UNIVERSITY] = ["You may gain an Action card costing up to $5.", "You may gain an Action"];
CzechQuestionDescriptions[QuestionIds.FOLLOWERS] = ["Discard down to 3 cards in hand.", "Discard down to 3"];
CzechQuestionDescriptions[QuestionIds.DIADEM] = ["Use Villagers before getting Coins for Actions?", "Use Villagers?"];
CzechQuestionDescriptions[QuestionIds.HAMLET] = ["You may discard to get +1 Action and/or +1 Buy", "You may discard"];
CzechQuestionDescriptions[QuestionIds.HAMLET_ACTION] = ["Discard a card for +1 Action.", "Discard for +1 Action"];
CzechQuestionDescriptions[QuestionIds.HAMLET_BUY] = ["Discard a card for +1 Buy.", "Discard for +1 Buy"];
CzechQuestionDescriptions[QuestionIds.HAMLET_BOTH] = ["Discard 2 cards.", "Discard 2 cards"];
CzechQuestionDescriptions[QuestionIds.HORN_OF_PLENTY] = ["Gain a card costing up to AMOUNT0. If it's a Victory card, trash this.", "Gain a card up to AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.HORSE_TRADERS] = ["Discard 2 cards.", "Discard 2 cards."];
CzechQuestionDescriptions[QuestionIds.JESTER] = ["Choose who gains CARDS0: You or PLAYER1.", "Choose who gains CARDS0"];
CzechQuestionDescriptions[QuestionIds.REMAKE_TRASH_FIRST] = ["Do this twice: Trash a card from your hand, then gain a card costing exactly $1 more than it.", "Trash first card"];
CzechQuestionDescriptions[QuestionIds.REMAKE_TRASH_SECOND] = ["Trash another card from your hand, then gain a card costing exactly $1 more than it.", "Trash another card"];
CzechQuestionDescriptions[QuestionIds.REMAKE_GAIN_FIRST] = ["Gain a card costing exactly COST0", "Gain a card"];
CzechQuestionDescriptions[QuestionIds.REMAKE_GAIN_SECOND] = ["Gain a card costing exactly COST0", "Gain a card"];
CzechQuestionDescriptions[QuestionIds.TOURNAMENT_GAIN_PRIZE_OR_DUCHY] = ["Gain a Prize or a Duchy onto your deck.", "Gain a Prize or a Duchy"];
CzechQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_PRIZE] = ["You may gain a Prize onto your deck.", "You may gain a Prize"];
CzechQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_DUCHY] = ["You may gain a Duchy onto your deck.", "You may gain a Duchy"];
CzechQuestionDescriptions[QuestionIds.TOURNAMENT_REVEAL] = ["You may reveal a Province.", "You may reveal a Province"];
CzechQuestionDescriptions[QuestionIds.TRUSTY_STEED] = ["Choose how to play Trusty Steed.", "Choose how to play Trusty Steed"];
CzechQuestionDescriptions[QuestionIds.YOUNG_WITCH] = ["Discard 2 cards.", "Discard 2 cards"];
CzechQuestionDescriptions[QuestionIds.YOUNG_WITCH_BANE] = ["You may reveal CARDS0 to block Young Witch.", "Reveal CARDS0 to block Young Witch?"];
CzechQuestionDescriptions[QuestionIds.ALTAR_TRASH] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.ALTAR_GAIN] = ["Gain a card costing up to $5.", "Gain a card"];
CzechQuestionDescriptions[QuestionIds.ARMORY] = ["Gain a card onto your deck costing up to $4.", "Gain a card"];
CzechQuestionDescriptions[QuestionIds.BAND_OF_MISFITS] = ["Play an Action card costing less then this.", "Play a card"];
CzechQuestionDescriptions[QuestionIds.CATACOMBS_MODE] = ["Put CARDS0 into your hand; or discard them and +3 Cards.", "Take or draw new?"];
CzechQuestionDescriptions[QuestionIds.CATACOMBS_GAIN] = ["Gain a card cheaper than AMOUNT0.", "Gain a card cheaper than AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.COUNT_PAY] = ["Choose one: Discard 2 cards; or put a card from your hand onto your deck; or gain a Copper.", "Choose Count payment"];
CzechQuestionDescriptions[QuestionIds.COUNT_DISCARD] = ["Discard 2 cards.", "Discard 2 cards"];
CzechQuestionDescriptions[QuestionIds.COUNT_TOPDECK] = ["Put a card from your hand onto your deck.", "Topdeck a card"];
CzechQuestionDescriptions[QuestionIds.COUNT_PROFIT] = ["Choose one: +$3; or trash your hand; or gain a Duchy.", "Choose Count profit"];
CzechQuestionDescriptions[QuestionIds.COUNTERFEIT] = ["You may play a Treasure from your hand twice. If you do, trash that Treasure.", "Play twice, then trash"];
CzechQuestionDescriptions[QuestionIds.CULTIST] = ["You may play a Cultist from your hand for free.", "Play another Cultist?"];
CzechQuestionDescriptions[QuestionIds.DAME_ANNA] = ["You may trash up to 2 cards from your hand.", "Trash up to 2 cards"];
CzechQuestionDescriptions[QuestionIds.DAME_NATALIE] = ["You may gain a card costing up to $3.", "You may gain a card"];
CzechQuestionDescriptions[QuestionIds.DEATH_CART] = ["You may trash an Action card from your hand or Death Cart from play.", "You may trash Death Cart or an Action card."];
CzechQuestionDescriptions[QuestionIds.FORAGER] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.KNIGHTS] = ["Choose a card to trash.", "Choose a card to trash"];
CzechQuestionDescriptions[QuestionIds.KNIGHTS_ALREADY_DIES] = ["Choose a card to trash. (CARDS0 already dies)", "Choose a card to trash"];
CzechQuestionDescriptions[QuestionIds.KNIGHTS_STILL_MATTERS] = ["Choose a card to trash (CARDS1 will be trashed if you trash CARDS0)", "Choose a card to trash"];
CzechQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_EXPAND] = ["You may trash from your hand and gain a card costing up to $3 more than it.", "You may trash a card"];
CzechQuestionDescriptions[QuestionIds.GRAVEROBBER_EXPAND] = ["Trash from your hand and gain a card costing up to $3 more than it.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_GAIN] = ["You may gain from the trash onto your deck.", "You may gain from trash"];
CzechQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN] = ["Gain from the trash onto your deck.", "Gain from the trash"];
CzechQuestionDescriptions[QuestionIds.GRAVEROBBER_COMPLEX] = ["When playing Graverobber", "Gain a card from the trash or trash an Action card"];
CzechQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN_SUPPLY] = ["Gain a card costing up to AMOUNT0.", "Gain a card up to AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.HERMIT_TRASH] = ["You may trash a non-Treasure card from your discard pile or hand.", "You may trash"];
CzechQuestionDescriptions[QuestionIds.HERMIT_GAIN] = ["Gain a card costing up to $3.", "Gain a card"];
CzechQuestionDescriptions[QuestionIds.HUNTING_GROUNDS] = ["Gain a Duchy or 3 Estates.", "Duchy or 3 Estates?"];
CzechQuestionDescriptions[QuestionIds.IRONMONGER] = ["You may keep or discard CARDS0.", "You may discard CARDS0"];
CzechQuestionDescriptions[QuestionIds.JUNK_DEALER] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.MERCENARY_ONE] = ["You may trash a card from your hand (for no bonus).", "You may trash a card"];
CzechQuestionDescriptions[QuestionIds.MERCENARY_MULTIPLE] = ["You may trash two cards from your hand.", "You may trash two cards"];
CzechQuestionDescriptions[QuestionIds.MERCENARY_DISCARD] = ["Discard down to 3 cards.", "Discard down to 3"];
CzechQuestionDescriptions[QuestionIds.MYSTIC] = ["Guess what card is on top of your deck.", "Guess a card"];
CzechQuestionDescriptions[QuestionIds.PILLAGE] = ["Choose which card PLAYER0 discards.", "Choose discard for PLAYER0"];
CzechQuestionDescriptions[QuestionIds.PROCESSION_PLAY] = ["You may play an Action card from your hand twice.", "You may play an Action twice."];
CzechQuestionDescriptions[QuestionIds.PROCESSION_GAIN] = ["Gain an Action costing exactly AMOUNT0.", "Gain an Action for AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.RATS_TRASH_ANY] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.RATS_TRASH_OTHER] = ["Trash a card from your hand other than a Rats.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.REBUILD_SKIP] = ["Name a Victory card to skip (or Copper).", "Name a card to skip"];
CzechQuestionDescriptions[QuestionIds.REBUILD_GAIN] = ["Gain a Victory card costing up to AMOUNT0.", "Gain a Victory card up to AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.ROGUE_TRASH] = ["Choose a card from PLAYER0 to trash.", "Trash a card from PLAYER0"];
CzechQuestionDescriptions[QuestionIds.ROGUE_GAIN] = ["Gain a card from the trash costing from $3 to $6.", "Gain a card from the trash"];
CzechQuestionDescriptions[QuestionIds.SCAVENGER] = ["You may put your deck into your discard pile.", "You may discard your deck"];
CzechQuestionDescriptions[QuestionIds.SCAVENGER_TOPDECK] = ["Put a card from your discard pile onto your deck.", "Topdeck a card"];
CzechQuestionDescriptions[QuestionIds.SIR_MICHAEL] = ["Discard down to 3 cards.", "Discard down to 3 cards"];
CzechQuestionDescriptions[QuestionIds.SQUIRE_MODE] = ["Choose one: +2 Actions; or +2 Buys; or gain a Silver.", "Choose Squire mode"];
CzechQuestionDescriptions[QuestionIds.SQUIRE_GAIN] = ["Gain an Attack card.", "Gain an Attack"];
CzechQuestionDescriptions[QuestionIds.STOREROOM_CARDS] = ["Discard any number of cards, then draw that many.", "Discard for cards"];
CzechQuestionDescriptions[QuestionIds.STOREROOM_COINS] = ["Discard any number of cards for +$1 each.", "Discard for coins"];
CzechQuestionDescriptions[QuestionIds.SURVIVORS] = ["Topdeck these two cards in any order, or discard them both.", "Topdeck or discard"];
CzechQuestionDescriptions[QuestionIds.URCHIN] = ["Discard down to 4 cards.", "Discard down to 4 cards"];
CzechQuestionDescriptions[QuestionIds.WANDERING_MINSTREL] = ["Put the Action cards back in any order.", "Topdeck in any order"];
CzechQuestionDescriptions[QuestionIds.ADVANCE_TRASH] = ["You may trash an Action card from your hand.", "You may trash an Action"];
CzechQuestionDescriptions[QuestionIds.ADVANCE_GAIN] = ["Gain an Action card costing up to $6.", "Gain an Action"];
CzechQuestionDescriptions[QuestionIds.ANNEX] = ["Choose up to 5 cards not to shuffle into your deck.", "Choose up to 5 cards"];
CzechQuestionDescriptions[QuestionIds.ARCHIVE] = ["Choose a card to put into your hand.", "Choose a card"];
CzechQuestionDescriptions[QuestionIds.ARENA] = ["You may discard an Action card for NUMBER0VP.", "Arena: discard for NUMBER0VP?"];
CzechQuestionDescriptions[QuestionIds.BANQUET] = ["Gain a non-Victory card costing up to $5.", "Gain a non-Victory"];
CzechQuestionDescriptions[QuestionIds.BUSTLING_VILLAGE] = ["You may put a Settlers into your hand.", "You may take a Settlers"];
CzechQuestionDescriptions[QuestionIds.CATAPULT_TRASH] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.CATAPULT_DISCARD] = ["Discard down to 3 cards.", "Discard down to 3 cards"];
CzechQuestionDescriptions[QuestionIds.CHARM_MODE] = ["Choose how to play Charm", "Choose how to play Charm"];
CzechQuestionDescriptions[QuestionIds.CHARM_GAIN] = ["Gain a card other than CARDS0 costing exactly AMOUNT1.", "Gain a card for AMOUNT1 (not CARDS0)"];
CzechQuestionDescriptions[QuestionIds.CROWN_ACTION] = ["You may play an Action from your hand twice.", "You may play an Action twice"];
CzechQuestionDescriptions[QuestionIds.CROWN_TREASURE] = ["You may play a Treasure from your hand twice.", "You may play a Treasure twice"];
CzechQuestionDescriptions[QuestionIds.DONATE] = ["Trash any number of cards.", "Trash any number"];
CzechQuestionDescriptions[QuestionIds.ENCAMPMENT] = ["You may reveal a Gold or Plunder from your hand.", "You may reveal Gold or Plunder"];
CzechQuestionDescriptions[QuestionIds.ENGINEER_GAIN] = ["Gain a card costing up to $4.", "Gain a card"];
CzechQuestionDescriptions[QuestionIds.ENGINEER_TRASH] = ["You may trash the Engineer.", "You may trash the Engineer"];
CzechQuestionDescriptions[QuestionIds.FORUM] = ["Discard 2 cards.", "Discard 2 cards"];
CzechQuestionDescriptions[QuestionIds.GLADIATOR_REVEAL] = ["Reveal a card from your hand.", "Reveal a card"];
CzechQuestionDescriptions[QuestionIds.GLADIATOR_PRETEND] = ["Pretend to think about revealing a copy.", "Pretend to think"];
CzechQuestionDescriptions[QuestionIds.GLADIATOR_BLOCK] = ["You may reveal CARDS0 from your hand.", "You may reveal CARDS0"];
CzechQuestionDescriptions[QuestionIds.HAUNTED_CASTLE] = ["Put 2 cards from your hand onto your deck.", "Topdeck 2 cards"];
CzechQuestionDescriptions[QuestionIds.LEGIONARY_REVEAL] = ["You may reveal a Gold from your hand.", "You may reveal a Gold"];
CzechQuestionDescriptions[QuestionIds.LEGIONARY_DISCARD] = ["Discard down to 2 cards in hand.", "Discard down to 2 cards"];
CzechQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_NEW] = ["Bid up to 40D.", "Bid up to 40D"];
CzechQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_OVERBID] = ["Bid up to 40D. Currently PLAYER0 holds the high bid of NUMBER1.", "Bid up to 40D"];
CzechQuestionDescriptions[QuestionIds.OPULENT_CASTLE] = ["Discard any number of Victory cards for +$2 each. ", "Discard for +$2 each"];
CzechQuestionDescriptions[QuestionIds.OVERLORD] = ["Play an Action card costing up to $5. ", "Play a card"];
CzechQuestionDescriptions[QuestionIds.RITUAL] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.SACRIFICE] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.SALT_THE_EARTH] = ["Trash a Victory card from the Supply.", "Trash from the Supply"];
CzechQuestionDescriptions[QuestionIds.SETTLERS] = ["You may put a Copper from your discard pile into your hand.", "You may take a Copper"];
CzechQuestionDescriptions[QuestionIds.SMALL_CASTLE_IN_PLAY] = ["Trash Small Castle from play or a Castle from your hand.", "Trash a Castle"];
CzechQuestionDescriptions[QuestionIds.SMALL_CASTLE_GONE] = ["Trash a Castle from your hand.", "Trash a Castle"];
CzechQuestionDescriptions[QuestionIds.SMALL_CASTLE_GAIN] = ["Gain a Castle.", "Gain a Castle"];
CzechQuestionDescriptions[QuestionIds.SPRAWLING_CASTLE] = ["Gain a Duchy or 3 Estates.", "Duchy or 3 Estates?"];
CzechQuestionDescriptions[QuestionIds.TAX] = ["Choose a supply pile to add (2D) to.", "Add (2D) to a pile"];
CzechQuestionDescriptions[QuestionIds.TEMPLE] = ["Trash from 1 to 3 differently named cards from your hand.", "Trash 1 - 3 cards"];
CzechQuestionDescriptions[QuestionIds.WILD_HUNT] = ["Choose between +3 Cards and the Estate and VP", "Cards or points?"];
CzechQuestionDescriptions[QuestionIds.ADVISOR] = ["Choose what card PLAYER0 doesn't get.", "Discard for PLAYER0"];
CzechQuestionDescriptions[QuestionIds.BUTCHER_TRASH] = ["You may trash a card from your hand.", "You may trash a card"];
CzechQuestionDescriptions[QuestionIds.BUTCHER_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card up to AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.DOCTOR_PLAY] = ["Name a card to trash.", "Name a card"];
CzechQuestionDescriptions[QuestionIds.DOCTOR_TOPDECK] = ["Put these cards back in any order.", "Topdeck these cards"];
CzechQuestionDescriptions[QuestionIds.DOCTOR_OVERPAY] = ["You may overpay for Doctor.", "You may overpay"];
CzechQuestionDescriptions[QuestionIds.DOCTOR_MODE] = ["What to do with CARDS0?", "What to do with CARDS0?"];
CzechQuestionDescriptions[QuestionIds.DOCTOR_MODE_MANY] = ["What to do with CARDS0? (NUMBER1 more)", "CARDS0? (NUMBER1 more)"];
CzechQuestionDescriptions[QuestionIds.HERALD_OVERPAY] = ["You may overpay for Herald.", "You may overpay"];
CzechQuestionDescriptions[QuestionIds.HERALD_CHOOSE] = ["Put a card from your discard pile on your deck.", "Topdeck a card"];
CzechQuestionDescriptions[QuestionIds.JOURNEYMAN] = ["Name a card not to draw.", "Name a card"];
CzechQuestionDescriptions[QuestionIds.MASTERPIECE] = ["You may overpay for Masterpiece.", "You may overpay"];
CzechQuestionDescriptions[QuestionIds.PLAZA_DELAY] = ["You may discard a Treasure to take a Coin token.", "You may discard a Treasure"];
CzechQuestionDescriptions[QuestionIds.PLAZA_DISCARD] = ["Discard a Treasure.", "Discard a Treasure"];
CzechQuestionDescriptions[QuestionIds.STONEMASON_TRASH] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.STONEMASON_GAIN1] = ["Gain 2 cards each costing less than AMOUNT0.", "Gain 1st card cheaper than AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.STONEMASON_GAIN2] = ["Gain another card costing less than AMOUNT0.", "Gain 2nd card cheaper than AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.STONEMASON_OVERPAY] = ["You may overpay for stonemason by gaining an Action up to AMOUNT0.", "Overpay by gaining"];
CzechQuestionDescriptions[QuestionIds.STONEMASON_GAIN_EQUAL] = ["Gain another Action costing exactly AMOUNT0.", "Gain another Action for AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.TAXMAN_TRASH] = ["You may trash a Treasure from your hand.", "You may trash a Treasure"];
CzechQuestionDescriptions[QuestionIds.TAXMAN_GAIN] = ["Gain a treasure costing up to AMOUNT0 onto your deck.", "Gain a treasure up to AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.BORDER_VILLAGE] = ["Gain a card cheaper than AMOUNT0.", "Gain a card cheaper than AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.CARTOGRAPHER_DISCARD] = ["Discard any number.", "Discard any number"];
CzechQuestionDescriptions[QuestionIds.CARTOGRAPHER_TOPDECK] = ["Put the remaining cards on top of your deck in any order.", "Topdeck these cards"];
CzechQuestionDescriptions[QuestionIds.DEVELOP_TRASH] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.DEVELOP_GAIN1] = ["Gain a card onto your deck costing AMOUNT0 or Argument1.", "Gain 1st card for AMOUNT0 or Argument1"];
CzechQuestionDescriptions[QuestionIds.DEVELOP_GAIN2] = ["Gain a card onto your deck costing AMOUNT0.", "Gain 2nd card for AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.DUCHESS] = ["You may discard CARDS0.", "You may discard CARDS0"];
CzechQuestionDescriptions[QuestionIds.EMBASSY] = ["Discard 3 cards.", "Discard 3 cards"];
CzechQuestionDescriptions[QuestionIds.FARMLAND_TRASH] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.FARMLAND_GAIN] = ["Gain a card costing exactly AMOUNT0.", "Gain a card for AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.HAGGLER] = ["Gain a non-victory costing less than AMOUNT0.", "Gain a non-Victory cheaper than AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.ILL_GOTTEN_GAINS] = ["You may gain a Copper to your hand.", "You may gain a Copper"];
CzechQuestionDescriptions[QuestionIds.INN_DISCARD] = ["Discard 2 cards.", "Discard 2 cards"];
CzechQuestionDescriptions[QuestionIds.INN_SHUFFLE] = ["Shuffle any number of Actions from your discard pile into your deck.", "Shuffle Actions back in"];
CzechQuestionDescriptions[QuestionIds.JACK_DISCARD] = ["You may discard CARDS0.", "You may discard CARDS0"];
CzechQuestionDescriptions[QuestionIds.JACK_TRASH] = ["You may trash a non-Treasure from your hand.", "You may trash a non-Treasure"];
CzechQuestionDescriptions[QuestionIds.MANDARIN_PLAY] = ["Put a card from your hand onto your deck.", "Topdeck a card"];
CzechQuestionDescriptions[QuestionIds.MANDARIN_GAIN] = ["Put all Treasures you have in play onto your deck in any order.", "Topdeck your Treasures"];
CzechQuestionDescriptions[QuestionIds.MARGRAVE] = ["Discard down to 3 cards in hand.", "Discard down to 3 cards"];
CzechQuestionDescriptions[QuestionIds.NOBLE_BRIGAND] = ["Choose a Treasure to have trashed.", "Choose a Treasure"];
CzechQuestionDescriptions[QuestionIds.OASIS] = ["Discard a card.", "Discard a card"];
CzechQuestionDescriptions[QuestionIds.ORACLE_DISCARD] = ["You may discard CARDS0 from PLAYER1.", "Make PLAYER1 discard?"];
CzechQuestionDescriptions[QuestionIds.ORACLE_TOPDECK] = ["Put the cards on top of your deck in any order.", "Topdeck these cards"];
CzechQuestionDescriptions[QuestionIds.SCHEME] = ["You may choose a card to scheme", "You may scheme a card"];
CzechQuestionDescriptions[QuestionIds.SCHEMES] = ["What cards to Scheme", "Scheme up to Argument0 cards"];
CzechQuestionDescriptions[QuestionIds.SPICE_MERCHANT_TRASH] = ["You may trash a Treasure from your hand.", "You may trash a Treasure"];
CzechQuestionDescriptions[QuestionIds.SPICE_MERCHANT_MODE] = ["Choose one: +2 Cards and +1 Action; or +1 Buy and +$2.", "Choose how to play Spice Merchant"];
CzechQuestionDescriptions[QuestionIds.STABLES] = ["You may discard a Treasure.", "You may discard a Treasure"];
CzechQuestionDescriptions[QuestionIds.TRADER_TRASH] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.BARON] = ["You may discard an Estate.", "You may discard an Estate"];
CzechQuestionDescriptions[QuestionIds.COURTIER_REVEAL] = ["Reveal a card from your hand.", "Reveal a card"];
CzechQuestionDescriptions[QuestionIds.COURTIER_MODE] = ["Choose NUMBER0: +1 Action; or +1 Buy; or +$3; or gain a Gold. The choices must be different.", "Choose NUMBER0 options"];
CzechQuestionDescriptions[QuestionIds.COURTYARD] = ["Put a card from your hand onto your deck.", "Topdeck a card"];
CzechQuestionDescriptions[QuestionIds.DIPLOMAT] = ["Discard 3 cards.", "Discard 3 cards"];
CzechQuestionDescriptions[QuestionIds.IRONWORKS] = ["Gain a card costing up to $4.", "Gain a card"];
CzechQuestionDescriptions[QuestionIds.LURKER_MAY_TRASH] = ["You may trash an Action card from the Supply.", "You may trash from the Supply"];
CzechQuestionDescriptions[QuestionIds.LURKER_TRASH] = ["Trash an Action card from the Supply.", "Trash from the Supply"];
CzechQuestionDescriptions[QuestionIds.LURKER_MAY_GAIN] = ["You may gain an Action card from the trash.", "You may gain the trash"];
CzechQuestionDescriptions[QuestionIds.LURKER_GAIN] = ["Gain an Action card from the trash.", "Gain from the trash"];
CzechQuestionDescriptions[QuestionIds.MASQUERADE_PASS] = ["Pass a card to PLAYER0.", "Pass a card to PLAYER0"];
CzechQuestionDescriptions[QuestionIds.MASQUERADE_TRASH] = ["You may trash a card from your hand.", "You may trash a card"];
CzechQuestionDescriptions[QuestionIds.MILL] = ["You may discard 2 cards", "You may discard 2 cards"];
CzechQuestionDescriptions[QuestionIds.MINING_VILLAGE] = ["You may trash Mining Village.", "You may trash Mining Village"];
CzechQuestionDescriptions[QuestionIds.MINION] = ["Choose one: +$2 or new hands.", "Choose +$2 or new hands"];
CzechQuestionDescriptions[QuestionIds.NOBLES] = ["Choose one: +3 Cards; or +2 Actions.", "Choose +3 Cards or +2 Actions"];
CzechQuestionDescriptions[QuestionIds.PATROL] = ["Put these cards back in any order.", "Topdeck these cards"];
CzechQuestionDescriptions[QuestionIds.PAWN] = ["Choose how to play Pawn.", "Choose how to play Pawn"];
CzechQuestionDescriptions[QuestionIds.REPLACE_TRASH] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.REPLACE_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card up to AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.SECRET_PASSAGE_TOPDECK] = ["Put a card onto your empty deck.", "Topdeck a card"];
CzechQuestionDescriptions[QuestionIds.SECRET_PASSAGE_INSERT] = ["Choose a card to put into your deck.", "Choose a card"];
CzechQuestionDescriptions[QuestionIds.STEWARD_MODE] = ["Choose one: +2 Cards; or +$2; or trash 2 cards from your hand.", "Choose how to play Steward"];
CzechQuestionDescriptions[QuestionIds.STEWARD_TRASH] = ["Choose 2 cards to trash.", "Choose 2 cards to trash"];
CzechQuestionDescriptions[QuestionIds.SWINDLER] = ["Choose a card for PLAYER0 to gain.", "Choose a card for PLAYER0"];
CzechQuestionDescriptions[QuestionIds.TRADING_POST] = ["Trash 2 cards from your hand.", "Trash 2 cards"];
CzechQuestionDescriptions[QuestionIds.UPGRADE_TRASH] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.UPGRADE_GAIN] = ["Gain a card costing exactly AMOUNT0.", "Gain a card for AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.WISHING_WELL] = ["Guess what card is on top of your deck.", "Guess a card"];
CzechQuestionDescriptions[QuestionIds.SUMMON] = ["Gain an Action card costing up to $4.", "Summon a card"];
CzechQuestionDescriptions[QuestionIds.GOVERNOR_MODE] = ["Choose how to play Governor", "Choose how to play Governor"];
CzechQuestionDescriptions[QuestionIds.GOVERNOR_TRASH] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.GOVERNOR_MAY_TRASH] = ["You may trash a card from your hand.", "You may trash"];
CzechQuestionDescriptions[QuestionIds.GOVERNOR_GAIN] = ["Gain a card costing exactly Argument0", "Gain a card"];
CzechQuestionDescriptions[QuestionIds.SAUNA_CHAIN] = ["You may play an Avanto without spending an action", "You may play an Avanto"];
CzechQuestionDescriptions[QuestionIds.SAUNA_MAY_TRASH] = ["You may trash a card with Sauna", "You may trash"];
CzechQuestionDescriptions[QuestionIds.AVANTO_CHAIN] = ["You may play a Sauna without spending an action", "You may play a Sauna"];

CzechQuestionDescriptions[QuestionIds.BISHOP_SELF] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.BISHOP_OTHER] = ["You may trash a card from your hand.", "You may trash a card"];
CzechQuestionDescriptions[QuestionIds.CONTRABAND] = ["Name a card PLAYER0 can't buy this turn.", "Ban a card"];
CzechQuestionDescriptions[QuestionIds.COUNTING_HOUSE] = ["How many Coppers to put into your hand?", "Take coppers"];
CzechQuestionDescriptions[QuestionIds.EXPAND_TRASH] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.EXPAND_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card up to AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.FORGE_TRASH] = ["Trash any number of cards from your hand.", "Trash any number"];
CzechQuestionDescriptions[QuestionIds.FORGE_GAIN] = ["Gain a card costing exactly AMOUNT0.", "Gain a card for AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.GOONS] = ["Discard down to 3 cards in hand.", "Discard down to 3 cards"];
CzechQuestionDescriptions[QuestionIds.KINGS_COURT] = ["You may play an Action card three times.", "You may play an Action three times"];
CzechQuestionDescriptions[QuestionIds.LOAN] = ["Discard or trash CARDS0.", "Discard or trash CARDS0"];
CzechQuestionDescriptions[QuestionIds.MINT] = ["You may reveal a Treasure from your hand. Gain a copy of it.", "You may copy a Treasure"];
CzechQuestionDescriptions[QuestionIds.MOUNTEBANK] = ["You may discard a Curse.", "You may discard a Curse"];
CzechQuestionDescriptions[QuestionIds.RABBLE] = ["Put these cards back in any order.", "Topdeck these cards"];
CzechQuestionDescriptions[QuestionIds.TRADE_ROUTE] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.VAULT_SELF] = ["Discard any number of cards.", "Discard any number"];
CzechQuestionDescriptions[QuestionIds.VAULT_OTHER] = ["You may discard 2 cards.", "You may discard 2 cards"];
CzechQuestionDescriptions[QuestionIds.AMBASSADOR_REVEAL] = ["Reveal a card from your hand.", "Reveal a card from your hand"];
CzechQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_ONE] = ["You may return CARDS0 to the Supply.", "You may return CARDS0"];
CzechQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_TWO] = ["Return 0, 1 or CARDS0 to the Supply.", "Return 0, 1 or 2"];
CzechQuestionDescriptions[QuestionIds.EMBARGO] = ["Add an Embargo token to a Supply pile.", "Embargo a pile"];
CzechQuestionDescriptions[QuestionIds.EXPLORER] = ["You may reveal a Province.", "You may reveal a Province"];
CzechQuestionDescriptions[QuestionIds.GHOST_SHIP] = ["Put NUMBER0 cards on top of your deck.", "Topdeck NUMBER0 cards"];
CzechQuestionDescriptions[QuestionIds.HAVEN] = ["Set a card aside.", "Set a card aside"];
CzechQuestionDescriptions[QuestionIds.ISLAND] = ["Put a card from your hand onto your Island mat.", "Put a card onto your mat"];
CzechQuestionDescriptions[QuestionIds.LOOKOUT_TRASH] = ["Trash a card.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.LOOKOUT_DISCARD] = ["Discard a card.", "Discard a card"];
CzechQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_TAKE] = ["You may put CARDS0 into your hand.", "Pick up Argument0?"];
CzechQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_SET_ASIDE] = ["You may put the top card of your deck onto your mat.", "You may put onto your mat"];
CzechQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_BOTH] = ["Add a card to your mat or take CARDS0 into your hand.", "Pick up Argument0?"];
CzechQuestionDescriptions[QuestionIds.NAVIGATOR] = ["Topdeck these 5 cards in any order, or discard them all.", "Topdeck or discard"];
CzechQuestionDescriptions[QuestionIds.PEARL_DIVER] = ["Put CARDS0 on top or bottom of your deck.", "Put CARDS0 on top or bottom"];
CzechQuestionDescriptions[QuestionIds.PIRATE_SHIP_MODE] = ["Choose one: +AMOUNT0 or attack.", "+AMOUNT0 or attack"];
CzechQuestionDescriptions[QuestionIds.PIRATE_SHIP_TRASH] = ["Choose a Treasure to trash.", "Choose a Treasure to trash"];
CzechQuestionDescriptions[QuestionIds.SALVAGER] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.SMUGGLERS_MAY_GAIN] = ["You may gain a copy.", "You may gain a card"];
CzechQuestionDescriptions[QuestionIds.SMUGGLERS_GAIN] = ["Gain a copy.", "Gain a card"];
CzechQuestionDescriptions[QuestionIds.WAREHOUSE] = ["Discard 3 cards.", "Discard 3 cards."];
CzechQuestionDescriptions[QuestionIds.LURKER_COMPLEX] = ["When playing Lurker", "When playing Lurker"];
CzechQuestionDescriptions[QuestionIds.BLACK_MARKET_COMPLEX] = ["You play Black Market", "You play Black Market"];
CzechQuestionDescriptions[QuestionIds.BLACK_MARKET_BUY] = ["buy one of the revealed cards.", "You may buy"];
CzechQuestionDescriptions[QuestionIds.BLACK_MARKET_PLAY] = ["play Treasures from your hand.", "You may play Treasures"];
CzechQuestionDescriptions[QuestionIds.ENVOY] = ["Choose a card to discard.", "Discard a card"];
CzechQuestionDescriptions[QuestionIds.PRINCE_MODE] = ["PRINCE_MODE", "Set prince aside?"];
CzechQuestionDescriptions[QuestionIds.PRINCE_MAY_SET_ASIDE] = ["You may set aside a card with ", "You may set a card aside"];
CzechQuestionDescriptions[QuestionIds.TORTURER_MAY_CURSE] = ["You may gain a Curse.", "You may gain a Curse"];
CzechQuestionDescriptions[QuestionIds.TORTURER_MAY_DISCARD] = ["You may discard 2 cards.", "You may discard 2 cards"];
CzechQuestionDescriptions[QuestionIds.TORTURER_DISCARD_OR_CURSE] = ["Either gain a Curse or discard 2 cards.", "Discard 2 cards or gain a Curse"];
CzechQuestionDescriptions[QuestionIds.WHAT_TO_IMPERSONATE] = ["Choose a card to impersonate.", "Choose a card to impersonate"];
CzechQuestionDescriptions[QuestionIds.INHERITANCE] = ["Choose a card to inherit.", "Choose a card to inherit"];

CzechQuestionDescriptions[QuestionIds.SHORT_YOU_MAY_REACT] = ["-", "You may react"];
CzechQuestionDescriptions[QuestionIds.SHORT_WHEN_GAIN] = ["-", "You gain Argument0..."];
CzechQuestionDescriptions[QuestionIds.SHORT_WHEN_WOULD_GAIN] = ["-", "You would gain Argument0..."];

CzechQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL_SPECIFIC] = ["-", "Play or call Argument0..."];
CzechQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL] = ["-", "Play or call Actions"];
CzechQuestionDescriptions[QuestionIds.SHORT_PLAY] = ["-", "You may play Actions"];
CzechQuestionDescriptions[QuestionIds.SHORT_CALL_SPECIFIC] = ["-", "You may call Argument0"];
CzechQuestionDescriptions[QuestionIds.SHORT_CALL] = ["-", "You may call cards"];

CzechQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_DISCARD] = ["You may discard a Treasure", "You may discard"];
CzechQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_GAIN] = ["Gain a card costing up to $4.", "Gain a card"];
CzechQuestionDescriptions[QuestionIds.THE_FLAMES_GIFT] = ["You may trash a card from your hand.", "You may trash"];
CzechQuestionDescriptions[QuestionIds.THE_MOONS_GIFT] = ["You may put a card onto your deck.", "You may topdeck"];
CzechQuestionDescriptions[QuestionIds.THE_SKYS_GIFT] = ["You may discard 3 cards to gain a Gold.", "You may discard"];
CzechQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_TOPDECK] = ["put the rest back in any order", "put the rest back"];
CzechQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_DISCARD] = ["Discard any number", "Discard any number"];
CzechQuestionDescriptions[QuestionIds.THE_WINDS_GIFT] = ["Discard 2 cards.", "Discard 2 cards"];
CzechQuestionDescriptions[QuestionIds.FEAR_DISCARD] = ["discard an Action or Treasure", "Discard a card"];
CzechQuestionDescriptions[QuestionIds.HAUNTING] = ["Topdeck something.", "Topdeck something"];
CzechQuestionDescriptions[QuestionIds.LOCUSTS] = ["Gain a cheaper card that shares a type.", "Gain a cheaper card"];
CzechQuestionDescriptions[QuestionIds.POVERTY] = ["Discard down to 3 cards in hand", "Discard down to 3"];
CzechQuestionDescriptions[QuestionIds.BAT] = ["You may trash up to 2 cards", "You may Trash"];
CzechQuestionDescriptions[QuestionIds.BLESSED_VILLAGE_MODE] = ["When to receive Argument0?", "When to receive?"];
CzechQuestionDescriptions[QuestionIds.CEMETERY] = ["You may trash up to 4 cards", "You may Trash"];
CzechQuestionDescriptions[QuestionIds.CHANGELING_GAIN_COPY] = ["Gain a copy of a card you have in play", "Gain a card"];
CzechQuestionDescriptions[QuestionIds.CHANGELING_REPLACE] = ["", ""];
CzechQuestionDescriptions[QuestionIds.COBBLER] = ["Gain a card costing up to $4 to your hand.", "Gain to your hand"];
CzechQuestionDescriptions[QuestionIds.CONCLAVE] = ["You may play an Action", "You may play an Action"];
CzechQuestionDescriptions[QuestionIds.CRYPT_SET_ASIDE] = ["Set aside any number of Treasures you have in play", "Set aside any number"];
CzechQuestionDescriptions[QuestionIds.CRYPT_RETURN_TO_HAND] = ["Choose a card to put into your hand.", "Choose a card"];
CzechQuestionDescriptions[QuestionIds.DEVILS_WORKSHOP] = ["Gain a card costing up to $4.", "Gain a card"];
CzechQuestionDescriptions[QuestionIds.DRUID] = ["Which Boon to receive?", "Which Boon?"];
CzechQuestionDescriptions[QuestionIds.EXORCIST_TRASH] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.EXORCIST_GAIN] = ["Gain a cheaper Spirit", "Gain a cheaper Spirit"];
CzechQuestionDescriptions[QuestionIds.FOOL] = ["What Boon next?", "What Boon next?"];
CzechQuestionDescriptions[QuestionIds.GOAT] = ["You may trash a card", "You may Trash"];
CzechQuestionDescriptions[QuestionIds.HAUNTED_MIRROR] = ["You may discard an action to get a Ghost", "You may discard"];
CzechQuestionDescriptions[QuestionIds.IMP] = ["You may play an action you don't have in play", "What to play?"];
CzechQuestionDescriptions[QuestionIds.NECROMANCER] = ["Play from trash", "Play from trash"];
CzechQuestionDescriptions[QuestionIds.MONASTERY] = ["You may trash up to AMOUNT0 cards or Coppers in play.", "You may trash up to AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_TOPDECK] = ["Topdeck the rest", "Topdeck the rest"];
CzechQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_DISCARD] = ["Discard any number", "Discard any number"];
CzechQuestionDescriptions[QuestionIds.PIXIE_MODE] = ["Trash Pixie to receive twice?", "Trash Pixie?"];
CzechQuestionDescriptions[QuestionIds.POOKA] = ["You may trash a Treasure other than Cursed Gold", "You may trash"];
CzechQuestionDescriptions[QuestionIds.RAIDER] = ["Discard a card", "Discard a card"];
CzechQuestionDescriptions[QuestionIds.SACRED_GROVE_MODE] = ["Also receive Argument0?", "receive Argument0?"];
CzechQuestionDescriptions[QuestionIds.SECRET_CAVE] = ["You may discard 3 cards for +$3 next turn", "You may discard"];
CzechQuestionDescriptions[QuestionIds.SHEPHERD] = ["Discard Victory cards for +2 cards each", "You may discard"];
CzechQuestionDescriptions[QuestionIds.TRAGIC_HERO] = ["Gain a Treasure", "Gain a Treasure"];
CzechQuestionDescriptions[QuestionIds.VAMPIRE] = ["Gain a non-Vampire up to 5", "Gain a card"];
CzechQuestionDescriptions[QuestionIds.WISH] = ["Gain a card to your hand", "Gain a card"];
CzechQuestionDescriptions[QuestionIds.ZOMBIE_APPRENTICE] = ["You may trash an Action", "You may trash"];
CzechQuestionDescriptions[QuestionIds.ZOMBIE_MASON] = ["You may gain a card", "You may gain a card"];
CzechQuestionDescriptions[QuestionIds.ZOMBIE_SPY] = ["You may Discard", "You may Discard"];
CzechQuestionDescriptions[QuestionIds.WHAT_NIGHT_TO_PLAY] = ["You may play a Night card", "What Night to play?"];
CzechQuestionDescriptions[QuestionIds.LOST_IN_THE_WOODS] = ["You may discard a card to receive a Boon", "Discard for a Boon?"];
CzechQuestionDescriptions[QuestionIds.START_GAME] = ["Inspect your starting cards", "Your starting cards:"];
CzechQuestionDescriptions[QuestionIds.DISMANTLE_TRASH] = ["Trash a card from your hand", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.DISMANTLE_GAIN] = ["Gain a card cheaper than AMOUNT0.", "Gain a card cheaper than AMOUNT0"];

CzechQuestionDescriptions[QuestionIds.DUCAT] = ["You may trash a Copper.", "You may trash a Copper"];
CzechQuestionDescriptions[QuestionIds.IMPROVE_TRASH] = ["You may trash an Action in play that would be discarded", "You may improve"];
CzechQuestionDescriptions[QuestionIds.IMPROVE_GAIN] = ["Gain a card costing exactly AMOUNT0.", "Gain a card for AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.HIDEOUT] = ["Trash a card. If it's a Victory card, gain a Curse", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.MOUNTAIN_VILLAGE] = ["Put a card from your discard into your hand", "Put into your hand"];
CzechQuestionDescriptions[QuestionIds.PRIEST] = ["Trash a card from your hand", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.RESEARCH_TRASH] = ["Trash a card from your hand", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.OLD_WITCH] = ["You may trash a Curse from your hand", "You may trash"];
CzechQuestionDescriptions[QuestionIds.RECRUITER] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.SCEPTER_COMPLEX] = ["When playing Scepter", "Replay or Coins?"];
CzechQuestionDescriptions[QuestionIds.SCEPTER_MODE] = ["get +$2", "add Coins"];
CzechQuestionDescriptions[QuestionIds.SCEPTER_PLAY] = ["replay an Action still in play", "Replay an Action"];
CzechQuestionDescriptions[QuestionIds.SCULPTOR] = ["Gain a card costing up to $4 to your hand.", "Gain to your hand"];
CzechQuestionDescriptions[QuestionIds.SEER] = ["Put them back onto your deck in any order", "Topdeck in any order"];
CzechQuestionDescriptions[QuestionIds.VILLAIN] = ["Discard a card costing 2 or more.", "Discard a card"];
CzechQuestionDescriptions[QuestionIds.TREASURER_COMPLEX] = ["When playing Treasurer", "When playing Treasurer"];
CzechQuestionDescriptions[QuestionIds.TREASURER_TRASH] = ["trash a Treasure", "trash a Treasure"];
CzechQuestionDescriptions[QuestionIds.TREASURER_GAIN] = ["gain a Treasure to your hand", "gain a Treasure"];
CzechQuestionDescriptions[QuestionIds.TREASURER_KEY] = ["take the Key", "take the Key"];
CzechQuestionDescriptions[QuestionIds.TREASURER_FAIL] = ["do nothing", "do nothing"];
CzechQuestionDescriptions[QuestionIds.BORDER_GUARD_PUT_IN_HAND] = ["Put one of the revealed cards into your hand", "Put into your hand"];
CzechQuestionDescriptions[QuestionIds.BORDER_GUARD_MODE] = ["What Artifact do you want to take?", "Horn or Lantern?"];
CzechQuestionDescriptions[QuestionIds.CATHEDRAL] = ["Trash a card from your hand.", "Trash a card"];
CzechQuestionDescriptions[QuestionIds.CITY_GATE] = ["Put a card from your hand onto your deck.", "Topdeck a card."];
CzechQuestionDescriptions[QuestionIds.PAGEANT] = ["you may pay $1 for +1 Coffers.", "$1 for +1 Coffers?"];
CzechQuestionDescriptions[QuestionIds.SEWERS] = ["You may trash up to AMOUNT0 cards from your hand. (Sewers)", "You may trash up to AMOUNT0 (Sewers)"];
CzechQuestionDescriptions[QuestionIds.SILOS] = ["Discard Coppers for +1 card each", "You may discard"];
CzechQuestionDescriptions[QuestionIds.CROP_ROTATION] = ["Discard a Victory card for +2 cards?", "You may discard"];
CzechQuestionDescriptions[QuestionIds.SINISTER_PLOT] = ["Draw AMOUNT0 cards or add a token?", "Draw AMOUNT0 or add a token?"];
CzechQuestionDescriptions[QuestionIds.STAR_CHART] = ["Select a card to go on top when shuffling", "Select a card to go on top"];
CzechQuestionDescriptions[QuestionIds.CAPTAIN] = ["Choose an Action from the supply up to $4 to play", "Choose what to play"];
CzechQuestionDescriptions[QuestionIds.CHURCH_SET_ASIDE] = ["Set aside up to 3 cards", "Set aside up to 3 cards"];
CzechQuestionDescriptions[QuestionIds.CHURCH_TRASH] = ["You may trash a card from your hand.", "You may trash a card"];
CzechQuestionDescriptions[QuestionIds.CAMEL_TRAIN] = ["Exile a card from the supply.", "Exile a card"];
CzechQuestionDescriptions[QuestionIds.GOATHERD] = ["You may trash a card from your hand.", "You may trash a card"];
CzechQuestionDescriptions[QuestionIds.SNOWY_VILLAGE] = ["You may use villagers while you can", "Use villagers while you can?"];
CzechQuestionDescriptions[QuestionIds.BOUNTY_HUNTER] = ["Exile a card from your hand", "Exile a card"];
CzechQuestionDescriptions[QuestionIds.CARDINAL_EXILE] = ["Exile a card", "Exile a card"];
CzechQuestionDescriptions[QuestionIds.GROOM] = ["Gain a card costing up to AMOUNT0.", "Gain a card up to AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.HOSTELRY] = ["Discard for Horses", "Discard for Horses"];
CzechQuestionDescriptions[QuestionIds.VILLAGE_GREEN] = ["Now or next turn?", "Now or next turn?"];
CzechQuestionDescriptions[QuestionIds.BARGE] = ["Now or next turn?", "Now or next turn?"];
CzechQuestionDescriptions[QuestionIds.DISPLACE_EXILE] = ["Exile a card from your hand", "Exile a card"];
CzechQuestionDescriptions[QuestionIds.DISPLACE_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card up to AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.FALCONER] = ["Gain a card to your hand", "Gain a card to your hand"];
CzechQuestionDescriptions[QuestionIds.HUNTING_LODGE] = ["Discard for +5 Cards?", "Discard for +5 Cards?"];
CzechQuestionDescriptions[QuestionIds.MASTERMIND] = ["You may play an Action card three times.", "You may play an Action three times"];
CzechQuestionDescriptions[QuestionIds.SANCTUARY] = ["You may Exile a Card from your hand", "You may Exile a Card"];
CzechQuestionDescriptions[QuestionIds.WAYFARER] = ["You may gain a Silver", "You may gain a Silver"];
CzechQuestionDescriptions[QuestionIds.DELAY] = ["You may set aside an Action", "You may set aside an Action"];
CzechQuestionDescriptions[QuestionIds.DESPERATION] = ["You may gain a Curse", "You may gain a Curse"];
CzechQuestionDescriptions[QuestionIds.GAMBLE] = ["Play Argument0?", "Play Argument0?"];
CzechQuestionDescriptions[QuestionIds.PURSUE] = ["What card to keep on top?", "What card to keep on top?"];
CzechQuestionDescriptions[QuestionIds.TOIL] = ["You may play a card", "You may play a card"];
CzechQuestionDescriptions[QuestionIds.ENHANCE_TRASH] = ["You may trash a non-Victory card", "You may trash a non-Victory card"];
CzechQuestionDescriptions[QuestionIds.ENHANCE_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card up to AMOUNT0"];
CzechQuestionDescriptions[QuestionIds.MARCH] = ["You may play a card from your discard pile", "You may play a card from discard"];
CzechQuestionDescriptions[QuestionIds.TRANSPORT_MAY_EXILE] = ["You may exile an Action card", "You may exile an Action card"];
CzechQuestionDescriptions[QuestionIds.TRANSPORT_EXILE] = ["Exile an Action card", "Exile an Action card"];
CzechQuestionDescriptions[QuestionIds.TRANSPORT_MAY_TOPDECK] = ["You may topdeck an Exiled Action", "You may topdeck an Exiled Action"];
CzechQuestionDescriptions[QuestionIds.TRANSPORT_TOPDECK] = ["Topdeck an Exiled Action", "Topdeck an Exiled Action"];
CzechQuestionDescriptions[QuestionIds.TRANSPORT_COMPLEX] = ["Exile from the Supply; or Topdeck from Exile", "Exile from the Supply; or Topdeck from Exile"];
CzechQuestionDescriptions[QuestionIds.BANISH] = ["Exile cards with the same name", "Exile cards with the same name"];
CzechQuestionDescriptions[QuestionIds.BARGAIN] = ["Gain a non-Victory up to $5", "Gain a non-Victory up to $5"];
CzechQuestionDescriptions[QuestionIds.INVEST] = ["Exile an Action card to Invest in", "Exile an Action card to Invest in"];
CzechQuestionDescriptions[QuestionIds.DEMAND] = ["Gain a card up to $4 onto your deck", "Gain a card up to $4 onto your deck"];
CzechQuestionDescriptions[QuestionIds.POPULATE] = ["What card to gain next?", "What card to gain next?"];
CzechQuestionDescriptions[QuestionIds.RECONSIDER_WAY] = ["Reconsider the Way you play Argument0", "Reconsider the Way you play Argument0"];
CzechQuestionDescriptions[QuestionIds.CONSIDER_WAY] = ["Consider the Way you play Argument0", "Consider the Way you play Argument0"];
CzechQuestionDescriptions[QuestionIds.WAY_OF_THE_GOAT] = ["Trash a Card from your hand.", "Trash a Card from your hand."];
CzechQuestionDescriptions[QuestionIds.WAY_OF_THE_RAT] = ["Discard a Treasure to gain a copy", "Discard a Treasure to gain a copy"];
CzechQuestionDescriptions[QuestionIds.SCRAP_TRASH] = ["Trash a card from your hand.", "Trash a card from your hand."];
CzechQuestionDescriptions[QuestionIds.SCRAP_MODES] = ["Choose Argument0 effects", "Choose Argument0 effects"];
CzechQuestionDescriptions[QuestionIds.ANIMAL_FAIR_TRASH] = ["Trash an Action from your hand", "Trash an Action from your hand"];
CzechQuestionDescriptions[QuestionIds.ANIMAL_FAIR_MAY_TRASH] = ["You may trash an Action from your hand", "You may trash an Action from your hand"];
CzechQuestionDescriptions[QuestionIds.BUTTERFLY_RETURN] = ["Return Argument0 to its pile?", "Return Argument0 to its pile?"];
CzechQuestionDescriptions[QuestionIds.BUTTERFLY_GAIN] = CzechQuestionDescriptions[QuestionIds.UPGRADE_GAIN];

CzechQuestionDescriptions[QuestionIds.GAME_PAY_OFF_DEBT] = ["You may pay off Debt.", "You may pay off Debt"];
CzechQuestionDescriptions[QuestionIds.MAY_PAY_OFF_EXTRA_DEBT] = ["You may pay off Debt.", "You may pay off Debt"];
CzechQuestionDescriptions[QuestionIds.PAY_OFF_DEBT_BEFORE_CHANGE] = ["Pay off Debt to remodel?", "Pay off Debt to remodel?"];
CzechQuestionDescriptions[QuestionIds.PAY_OFF_DEBT_BEFORE_STORYTELLER] = ["Pay off Debt to draw fewer Cards?", "Pay off Debt to draw fewer Cards?"];