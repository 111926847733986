"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ArtLayer = function (_StackViewLayer) {
    _inherits(ArtLayer, _StackViewLayer);

    function ArtLayer(rootNode, cardStack) {
        _classCallCheck(this, ArtLayer);

        var _this = _possibleConstructorReturn(this, (ArtLayer.__proto__ || Object.getPrototypeOf(ArtLayer)).call(this, rootNode, cardStack));

        _this.node.style.zIndex = 1;
        _this.node.classList.add("art-layer");
        _this.crossNode = document.createElement("div");
        rootNode.appendChild(_this.crossNode);
        _this.crossNode.classList.add("empty-card-cross");
        return _this;
    }

    _createClass(ArtLayer, [{
        key: "redraw",
        value: function redraw(w, h) {
            var card = this.cardStack.topCard;
            var cardSize = this.cardStack.cardSize;
            var types = card.types;
            var isInherited = card.cardName === CardNames.ESTATE && types.includes(Types.ACTION);
            var proxyName = isInherited ? CardNames.MILL : card.image;
            var artURL = void 0;
            switch (cardSize) {
                case CardSizes.FULL:
                    artURL = getFullArtURL(card.image);
                    break;
                case CardSizes.MINI:
                default:
                    artURL = getMiniArtURL(card.image);
                    break;
            }
            var isInverted = this.cardStack.hasFilter("inverted-card");
            var isEmpty = this.cardStack.hasFilter("empty");
            var appearsAnonymous = this.cardStack.hasFilter("appears-anonymous");

            if (proxyName.isBack() || appearsAnonymous || cardSize === CardSizes.MICRO) {
                this.node.classList.add("invisible");
                return;
            } else {
                this.node.classList.remove("invisible");
            }

            var artBbox = getCardArtBbox(w, proxyName, cardSize).roundPositions();

            if (isInverted) {
                artBbox.y = h - artBbox.y - artBbox.height;
            }
            if (isEmpty) {
                this.node.style.filter = "sepia() contrast(50%) brightness(150%)";
            } else {
                this.node.style.filter = "";
            }

            this.node.style.left = artBbox.x + "px";
            this.node.style.top = artBbox.y + "px";
            this.node.style.width = artBbox.width + "px";
            this.node.style.height = artBbox.height + "px";
            this.node.style.backgroundImage = "url(\"" + artURL + "\")";

            if (isEmpty) {
                this.crossNode.classList.remove("invisible");
                this.crossNode.style.left = 0.2 * w + "px";
                this.crossNode.style.top = 0.2 * h + "px";
                this.crossNode.style.width = 0.6 * w + "px";
                this.crossNode.style.height = 0.7 * h + "px";
            } else {
                this.crossNode.classList.add("invisible");
            }
        }
    }]);

    return ArtLayer;
}(StackViewLayer);