"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var GameState = function () {
    function GameState(game) {
        _classCallCheck(this, GameState);

        this.game = game;
        this.nobody = new Player(this, -1, -1, [], []);
        this.nowhere = new Zone(this, -1, CardNames.BACK, this.nobody, null, CardNames.BACK);
        this.orphanage = new RevealZone(this, -1, CardNames.BACK, this.nobody, null, CardNames.BACK);

        this.reset();
    }

    _createClass(GameState, [{
        key: "reset",
        value: function reset() {
            this.players = [];
            this.zones = [];
            this.cardNames = [];
            this.anonCards = new Map();
            this.cards = [];
            this.counters = [];
            this.tokens = [];
            this.activeTurn = new TurnDescription(this.game, -1, 0, 0, -1);
            this.activeCostReduction = null;
        }
    }, {
        key: "parse",
        value: function parse(reader) {
            this.parsePlayers(reader);
            this.parseCards(reader);
            this.createZones(reader);
            this.parsePileMarkers(reader);
            this.createTokens(reader);
            this.createCounters(reader);
            this.createTurnDescription(reader);
            this.activeCostReduction = CostReduction.parse(this.game, reader);
            this.activeCostReduction.execute(this.game);
            TemporaryEffects.parse(this.game, reader).applyTo(this.cards);
            this.linkAssociations(reader);
            this.linkCardStatuses(reader);
            this.cards.forEach(function (c) {
                return c.checkIfOrphaned();
            });
        }
    }, {
        key: "parsePlayers",
        value: function parsePlayers(reader) {
            var nPlayers = reader.readInt();
            this.players = [];
            for (var index = 0; index < nPlayers; index++) {
                var playerId = reader.readInt();
                var ignoredCards = reader.readArrayOf(CardFrequency);
                var activeReminders = reader.readArrayOf(Reminder);
                var boughtProjects = reader.readArrayOf(CardName);
                this.players.push(new Player(this, playerId, index, activeReminders, boughtProjects));
            }
        }
    }, {
        key: "parseCards",
        value: function parseCards(reader) {
            var _this = this;

            this.cards = [];
            this.cardNames = reader.readArrayOf(CardName);
            this.cardNames.forEach(this.createAnonCard.bind(this));
            this.createAnonCard(CardNames.BACK);
            var pileNames = reader.readArrayOf(CardName);
            var frequencies = reader.readInts();
            var cardIndex = 0;
            frequencies.forEach(function (n, index) {
                for (var i = 0; i < n; i++) {
                    _this.cards.push(new CardObject(_this, cardIndex++, _this.cardNames[index], pileNames[index]));
                }
            });
        }
    }, {
        key: "createZones",
        value: function createZones(reader) {
            this.zones = [];
            var indices = reader.readInts();
            var names = reader.readInts();
            var pileNames = reader.readInts();
            var owners = reader.readInts();
            var numberOfZones = reader.readInt();
            var cardsInZone = [];
            for (var i = 0; i < numberOfZones; i++) {
                cardsInZone[i] = reader.readInts();
            }
            var createdByArray = reader.readInts();
            var attachedTraits = reader.readInts();
            var visibility = reader.readBooleans();

            for (var _i = 0; _i < numberOfZones; _i++) {
                var zoneConstructor = getByOrdinal(ZoneNames, names[_i]);
                var pileName = getByOrdinal(CardNames, pileNames[_i]);
                var owner = owners[_i] === -1 ? this.nobody : this.players[owners[_i]];
                var createdBy = createdByArray[_i] === -1 ? null : this.cards[createdByArray[_i]];
                var attachedTrait = getByOrdinal(CardNames, attachedTraits[_i]);
                var isVisible = visibility[_i];
                var newZone = new zoneConstructor(this, indices[_i], pileName, owner, createdBy, attachedTrait, visibility[_i]);
                this.zones[newZone.index] = newZone;
            }

            for (var _i2 = 0; _i2 < numberOfZones; _i2++) {
                this.zones[indices[_i2]].initialize(cardsInZone[_i2]);
            }
        }
    }, {
        key: "parsePileMarkers",
        value: function parsePileMarkers(reader) {
            var _this2 = this;

            reader.readArrayOf(PileMarker).forEach(function (m) {
                _this2.zones[m.zoneIndex].addMarker(m);
            });
        }
    }, {
        key: "createTokens",
        value: function createTokens(reader) {
            var _this3 = this;

            this.tokens = [];
            var names = reader.readInts();
            var owners = reader.readInts().map(function (id) {
                return _this3.players[id];
            });
            var zoneIndices = reader.readInts();
            var isFlipped = reader.readBooleans();
            for (var i = 0; i < names.length; i++) {
                var tokenName = getByOrdinal(TokenNames, names[i]);
                var newToken = new Token(this, i, tokenName, owners[i], this.zones[zoneIndices[i]], isFlipped[i]);
                this.tokens.push(newToken);
                this.zones[zoneIndices[i]].tokens.push(newToken);
            }
        }
    }, {
        key: "createCounters",
        value: function createCounters(reader) {
            var _this4 = this;

            this.counters = [];
            var indices = reader.readInts();
            var names = reader.readInts();
            var owners = reader.readInts().map(function (id) {
                return id === -1 ? _this4.nobody : _this4.players[id];
            });
            var values = reader.readInts();
            var piles = reader.readInts();
            var cards = reader.readInts();
            var visibilities = reader.readBooleans();
            for (var i = 0; i < names.length; i++) {
                var counterName = getByOrdinal(CounterNames, names[i]);
                var zone = piles[i] === -1 ? this.nowhere : this.zones[piles[i]];
                var card = cards[i] === -1 ? null : this.cards[cards[i]];
                var newCounter = new Counter(this, indices[i], counterName, owners[i], zone, card, values[i], visibilities[i]);
                this.counters.push(newCounter);
            }
        }
    }, {
        key: "createTurnDescription",
        value: function createTurnDescription(reader) {
            var ownerId = reader.readInt();
            var number = reader.readInt();
            var type = reader.readInt();
            var controllerId = reader.readInt();
            var newTurn = new TurnDescription(this.game, ownerId, number, type, controllerId);
            this.activeTurn = newTurn;
        }
    }, {
        key: "linkAssociations",
        value: function linkAssociations(reader) {
            reader.readArrayOfGameObject(this.game, Association).forEach(function (a) {
                a.execute();
            });
        }
    }, {
        key: "linkCardStatuses",
        value: function linkCardStatuses(reader) {
            var size = reader.readInt();
            while (size-- > 0) {
                var cardStatus = CardStatus.parse(this.game, reader);
                cardStatus.execute(this.game);
            }
        }
    }, {
        key: "createAnonCard",
        value: function createAnonCard(cardName) {
            this.anonCards.set(cardName, new CardObject(-1, cardName, cardName));
        }
    }, {
        key: "getAnonCard",
        value: function getAnonCard(cardName) {
            if (!this.anonCards.has(cardName)) this.createAnonCard(cardName);
            return this.anonCards.get(cardName);
        }
    }, {
        key: "getPlayer",
        value: function getPlayer(id) {
            return id === -1 ? this.nobody : this.players[id];
        }
    }]);

    return GameState;
}();

;

var Counter = function () {
    function Counter(state, index, counterName, owner, associatedPile, associatedCard, value, isVisible) {
        _classCallCheck(this, Counter);

        this.state = state;
        this.index = index;
        this.counterName = counterName;
        if (!(owner instanceof Player)) throw "Illegal counter owner";
        this.owner = owner;
        this.owner.counters.push(this);
        if (!associatedPile instanceof Zone) throw "Illegal associatedPile on Counter";
        this.associatedPile = associatedPile;
        this.associatedPile.counters.push(this);
        this.associatedCard = associatedCard;
        if (this.associatedCard !== null) this.associatedCard.counters.push(this);
        this.privateValue = value;

        this.isVisible = isVisible;
        this.views = [];
    }

    _createClass(Counter, [{
        key: "value",
        set: function set(value) {
            this.privateValue = value;
            this.views.forEach(function (v) {
                return v.update();
            });
        },
        get: function get() {
            return this.privateValue;
        }
    }]);

    return Counter;
}();

var Token = function () {
    function Token(state, index, tokenName, owner, zone, isFlipped) {
        _classCallCheck(this, Token);

        this.state = state;
        this.index = index;
        this.tokenName = tokenName;
        this.owner = owner;
        this.zone = zone;
        this.isFlipped = isFlipped;
        this.views = [];
    }

    _createClass(Token, [{
        key: "process",
        value: function process(change) {
            this.isFlipped = change.isFlipped;
            if (change.newZone !== this.zone) {
                this.zone.removeToken(this);
                this.zone = change.newZone;
                change.newZone.addToken(this);
            }
            this.views.forEach(function (v) {
                return v.update();
            });
            this.owner.onReminderChange();
        }
    }, {
        key: "zone",
        set: function set(newValue) {
            if (!(newValue instanceof Zone)) throw "token zone should be a zone";
            this.privateZone = newValue;
        },
        get: function get() {
            return this.privateZone;
        }
    }, {
        key: "owner",
        set: function set(newValue) {
            if (newValue !== this.state.nobody && !(newValue instanceof Player)) throw "Illegal token owner";
            this.privateOwner = newValue;
        },
        get: function get() {
            return this.privateOwner;
        }
    }]);

    return Token;
}();