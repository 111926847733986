"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var MouseZone = function (_Zone) {
    _inherits(MouseZone, _Zone);

    function MouseZone() {
        _classCallCheck(this, MouseZone);

        return _possibleConstructorReturn(this, (MouseZone.__proto__ || Object.getPrototypeOf(MouseZone)).apply(this, arguments));
    }

    _createClass(MouseZone, [{
        key: "reposition",
        value: function reposition() {
            var shouldAnimate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            var mouseCard = this.state.cards.find(function (c) {
                return c.cardName === CardNames.WAY_OF_THE_MOUSE;
            });
            if (!isUndefined(mouseCard)) {
                var mouseBBox = mouseCard.cardStacks[0].view.bbox;

                var x = mouseBBox.width * 0.6;
                var width = mouseBBox.width * 0.3;
                var height = width * CardSizes.MINI.ratio;
                var y = (mouseBBox.height * 0.8 - height) / 2;
                var stack = this.cardStacks[0];
                width -= mouseBBox.width;
                height -= mouseBBox.height;
                var offset = new Rectangle(x, y, width, height, 1);

                if (shouldAnimate) stack.animator = new TransitionAnimator(stack, simpleTrackedTransitionScript(offset), mouseCard.cardStacks[0], function () {});else stack.reposition(mouseBBox.x + offset.x, mouseBBox.y + offset.y, mouseBBox.width + offset.width, mouseBBox.height + offset.height, mouseBBox.zIndex + 1);
                stack.view.canvas.style.pointerEvents = "none";
            }
        }
    }, {
        key: "initialize",
        value: function initialize(cardIds) {
            var _this2 = this;

            cardIds.forEach(function (id) {
                var targetName = _this2.state.cards[id].cardName;
                var targetStack = new CardStack(_this2.state.getAnonCard(targetName), _this2, CardSizes.MINI);
                targetStack.addCard(_this2.state.cards[id]);
            });
            this.reposition();
        }
    }, {
        key: "repositionOnNewTurn",
        get: function get() {
            return true;
        }
    }]);

    return MouseZone;
}(Zone);