"use strict";

webclient.component("modalWindow", {
    bindings: {
        containerClass: '@'
    },
    transclude: true,
    controller: [function () {
        var self = this;

        self.$onInit = function () {
            if (!isUndefined(self.containerClass)) return;
            self.containerClass = "centered-container";
        };
    }],
    template: "\n            <div class=\"{{::$ctrl.containerClass}}\">\n                <div class=\"modal-window centered-content\">\n                    <ng-transclude></ng-transclude>\n                </div>\n            </div>"
});