"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var QuestionElements = function () {
    function QuestionElements(elements) {
        _classCallCheck(this, QuestionElements);

        this.elements = elements;
    }

    _createClass(QuestionElements, [{
        key: "extractButtons",
        value: function extractButtons() {
            return [];
        }
    }, {
        key: "createAssociations",
        value: function createAssociations(state) {
            return [];
        }
    }]);

    return QuestionElements;
}();

var QuestionCardElements = function (_QuestionElements) {
    _inherits(QuestionCardElements, _QuestionElements);

    function QuestionCardElements() {
        _classCallCheck(this, QuestionCardElements);

        return _possibleConstructorReturn(this, (QuestionCardElements.__proto__ || Object.getPrototypeOf(QuestionCardElements)).apply(this, arguments));
    }

    _createClass(QuestionCardElements, [{
        key: "createAssociations",
        value: function createAssociations(state) {
            var _this2 = this;

            return this.elements.map(function (entry, index) {
                state.cards[entry].associateAnswer(_this2.question, index);
                return state.cards[entry];
            });
        }
    }], [{
        key: "parse",
        value: function parse(reader, size) {
            var cards = [];
            for (var i = 0; i < size; i++) {
                cards.push(reader.readInt());
            }return new QuestionCardElements(cards);
        }
    }]);

    return QuestionCardElements;
}(QuestionElements);

var QuestionAbilityElements = function (_QuestionElements2) {
    _inherits(QuestionAbilityElements, _QuestionElements2);

    function QuestionAbilityElements() {
        _classCallCheck(this, QuestionAbilityElements);

        return _possibleConstructorReturn(this, (QuestionAbilityElements.__proto__ || Object.getPrototypeOf(QuestionAbilityElements)).apply(this, arguments));
    }

    _createClass(QuestionAbilityElements, [{
        key: "extractButtons",
        value: function extractButtons(game) {
            var _this4 = this;

            var abilityList = [];

            this.elements.forEach(function (ability, index) {
                var existingAbility = abilityList.find(function (a) {
                    return a.ability.name.isStacking && a.ability.isEquivalentTo(ability);
                });
                if (isUndefined(existingAbility)) {
                    var label = ability.label.length <= 1 ? LANGUAGE.getCardName[game.state.cards[ability.association].cardName].singular : ability.label;
                    existingAbility = new AbilityButtonView(_this4.question, index, label, ability);
                    abilityList.push(existingAbility);
                }
                existingAbility.count++;
            });
            abilityList.sort(function (a, b) {
                return Ability.sort(a.ability, b.ability);
            });
            return abilityList;
        }
    }, {
        key: "createAssociations",
        value: function createAssociations(state) {
            var _this5 = this;

            return this.elements.map(function (entry, index) {
                if (entry.association > -1) {
                    state.cards[entry.association].associateAnswer(_this5.question, index);
                    return state.cards[entry.association];
                } else {
                    return [];
                }
            });
        }
    }], [{
        key: "parse",
        value: function parse(reader, size) {
            var abilities = [];
            for (var i = 0; i < size; i++) {
                abilities.push(Ability.parse(reader, true));
            }
            return new QuestionAbilityElements(abilities);
        }
    }]);

    return QuestionAbilityElements;
}(QuestionElements);

var QuestionExtraTurnElements = function (_QuestionElements3) {
    _inherits(QuestionExtraTurnElements, _QuestionElements3);

    function QuestionExtraTurnElements() {
        _classCallCheck(this, QuestionExtraTurnElements);

        return _possibleConstructorReturn(this, (QuestionExtraTurnElements.__proto__ || Object.getPrototypeOf(QuestionExtraTurnElements)).apply(this, arguments));
    }

    _createClass(QuestionExtraTurnElements, [{
        key: "extractButtons",
        value: function extractButtons() {
            var _this7 = this;

            var buttons = [];
            this.elements.forEach(function (description, index) {
                var existing = buttons.find(function (b) {
                    return b.description.type === description.type;
                });
                if (isUndefined(existing)) {
                    existing = new ExtraTurnButtonView(_this7.question, index, description);
                    buttons.push(existing);
                }
                existing.count++;
            });
            return buttons;
        }
    }], [{
        key: "parse",
        value: function parse(reader, size) {
            var extraTurns = [];
            for (var i = 0; i < size; i++) {
                var owner = reader.readInt();
                var turnNumber = reader.readInt();
                var type = getByOrdinal(ExtraTurnTypes, reader.readInt());
                var controller = reader.readInt();
                extraTurns.push(new ExtraTurnDescription(owner, type));
            }
            return new QuestionExtraTurnElements(extraTurns);
        }
    }]);

    return QuestionExtraTurnElements;
}(QuestionElements);

var QuestionZoneElements = function (_QuestionElements4) {
    _inherits(QuestionZoneElements, _QuestionElements4);

    function QuestionZoneElements() {
        _classCallCheck(this, QuestionZoneElements);

        return _possibleConstructorReturn(this, (QuestionZoneElements.__proto__ || Object.getPrototypeOf(QuestionZoneElements)).apply(this, arguments));
    }

    _createClass(QuestionZoneElements, [{
        key: "createAssociations",
        value: function createAssociations(state) {
            var _this9 = this;

            var cards = [];
            this.elements.forEach(function (zoneId, index) {
                state.zones[zoneId].cardStacks.forEach(function (s) {
                    if (s.cardSize !== CardSizes.MICRO) {
                        s.cards.forEach(function (c) {
                            c.associateAnswer(_this9.question, index);
                            cards.push(c);
                        });
                        if (s.stackCard.cardName !== CardNames.BACK) s.stackCard.associateAnswer(_this9.question, index);
                    }
                });
            });
            return cards;
        }
    }], [{
        key: "parse",
        value: function parse(reader, size) {
            var zoneIndices = [];
            for (var i = 0; i < size; i++) {
                zoneIndices.push(reader.readInt());
            }return new QuestionZoneElements(zoneIndices);
        }
    }]);

    return QuestionZoneElements;
}(QuestionElements);

var QuestionCardModeElements = function (_QuestionElements5) {
    _inherits(QuestionCardModeElements, _QuestionElements5);

    function QuestionCardModeElements() {
        _classCallCheck(this, QuestionCardModeElements);

        return _possibleConstructorReturn(this, (QuestionCardModeElements.__proto__ || Object.getPrototypeOf(QuestionCardModeElements)).apply(this, arguments));
    }

    _createClass(QuestionCardModeElements, [{
        key: "extractButtons",
        value: function extractButtons() {
            var _this11 = this;

            return this.elements.map(function (element, index) {
                return element.imageName === null ? new GameButtonView(_this11.question, index, LANGUAGE.getCardMode[element]) : new SelectableGameButtonView(_this11.question, index, LANGUAGE.getCardMode[element], element.imageName, element.getLabel(_this11.question.game));
            });
        }
    }], [{
        key: "parse",
        value: function parse(reader, size) {
            var modes = [];
            for (var i = 0; i < size; i++) {
                modes.push(getByOrdinal(CardModes, reader.readInt()));
            }
            return new QuestionCardModeElements(modes);
        }
    }]);

    return QuestionCardModeElements;
}(QuestionElements);

var QuestionCardNameElements = function (_QuestionElements6) {
    _inherits(QuestionCardNameElements, _QuestionElements6);

    function QuestionCardNameElements() {
        _classCallCheck(this, QuestionCardNameElements);

        return _possibleConstructorReturn(this, (QuestionCardNameElements.__proto__ || Object.getPrototypeOf(QuestionCardNameElements)).apply(this, arguments));
    }

    _createClass(QuestionCardNameElements, [{
        key: "createAssociations",
        value: function createAssociations(state) {
            var _this13 = this;

            var changedCards = [];
            this.elements.forEach(function (cardName, index) {
                state.cards.forEach(function (c) {
                    if (c.cardName === cardName) {
                        c.associateAnswer(_this13.question, index);
                        changedCards.push(c);
                    }
                });
            });
            return changedCards;
        }
    }], [{
        key: "parse",
        value: function parse(reader, size) {
            var cardNames = [];
            for (var i = 0; i < size; i++) {
                cardNames.push(CardName.parse(reader));
            }
            return new QuestionCardNameElements(cardNames);
        }
    }]);

    return QuestionCardNameElements;
}(QuestionElements);

var QuestionGameButtonElements = function (_QuestionElements7) {
    _inherits(QuestionGameButtonElements, _QuestionElements7);

    function QuestionGameButtonElements() {
        _classCallCheck(this, QuestionGameButtonElements);

        return _possibleConstructorReturn(this, (QuestionGameButtonElements.__proto__ || Object.getPrototypeOf(QuestionGameButtonElements)).apply(this, arguments));
    }

    _createClass(QuestionGameButtonElements, [{
        key: "extractButtons",
        value: function extractButtons() {
            var _this15 = this;

            return this.elements.map(function (gameButton, index) {
                switch (gameButton) {
                    case GameButtons.USE_VILLAGER:
                    case GameButtons.USE_COFFERS:
                    case GameButtons.PAY_OFF_DEBT:
                        return new DetachedGameButtonView(_this15.question, index, gameButton);
                    default:
                        if (gameButton.imageName) return new IconGameButtonView(_this15.question, index, LANGUAGE.getGameButton[gameButton], getButtonArtURL(gameButton), "");else return new GameButtonView(_this15.question, index, LANGUAGE.getGameButton[gameButton]);
                }
            });
        }
    }], [{
        key: "parse",
        value: function parse(reader, size) {
            var buttons = [];
            for (var i = 0; i < size; i++) {
                buttons.push(getByOrdinal(GameButtons, reader.readInt()));
            }
            return new QuestionGameButtonElements(buttons);
        }
    }]);

    return QuestionGameButtonElements;
}(QuestionElements);

var QuestionCleanupActionElements = function (_QuestionElements8) {
    _inherits(QuestionCleanupActionElements, _QuestionElements8);

    function QuestionCleanupActionElements() {
        _classCallCheck(this, QuestionCleanupActionElements);

        return _possibleConstructorReturn(this, (QuestionCleanupActionElements.__proto__ || Object.getPrototypeOf(QuestionCleanupActionElements)).apply(this, arguments));
    }

    _createClass(QuestionCleanupActionElements, [{
        key: "extractButtons",
        value: function extractButtons() {
            var _this17 = this;

            return this.elements.map(function (cleanupItem, index) {
                return new CleanupButtonView(_this17.question, index, "", cleanupItem);
            });
        }
    }], [{
        key: "parse",
        value: function parse(reader, size) {
            var actions = [];
            for (var i = 0; i < size; i++) {
                var cardNames = reader.readArrayOf(CardName);
                var isOptional = reader.readBoolean();
                var allowsReordering = reader.readBoolean();
                var enabled = reader.readBoolean();
                actions.push(new CleanupItem(i, cardNames, isOptional, allowsReordering, enabled));
            }
            return new QuestionCleanupActionElements(actions);
        }
    }]);

    return QuestionCleanupActionElements;
}(QuestionElements);

var QuestionBuyableCardElements = function (_QuestionElements9) {
    _inherits(QuestionBuyableCardElements, _QuestionElements9);

    function QuestionBuyableCardElements() {
        _classCallCheck(this, QuestionBuyableCardElements);

        return _possibleConstructorReturn(this, (QuestionBuyableCardElements.__proto__ || Object.getPrototypeOf(QuestionBuyableCardElements)).apply(this, arguments));
    }

    _createClass(QuestionBuyableCardElements, [{
        key: "createAssociations",
        value: function createAssociations(state) {
            var _this19 = this;

            return this.elements.map(function (entry, index) {
                if (entry.isSimple || shouldUseAutobuy) {
                    state.cards[entry.id].associateAnswer(_this19.question, index);
                    return state.cards[entry.id];
                }
            }).filter(function (x) {
                return !isUndefined(x);
            });
        }
    }], [{
        key: "parse",
        value: function parse(reader, size) {
            var cards = [];
            for (var i = 0; i < size; i++) {
                cards.push(BuyableCard.parse(reader));
            }return new QuestionBuyableCardElements(cards);
        }
    }]);

    return QuestionBuyableCardElements;
}(QuestionElements);