"use strict";

webclient.service('serverMessenger', ['serverConnection', 'languageSelection', function (serverConnection, languageSelection) {
    var self = this;
    self.send = function (buffer) {
        serverConnection.send(buffer);
    };

    self.chatMessage = function (receiver, sender, message) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.SEND_CHAT));
        writer.writeString(receiver);
        writer.writeString(sender);
        writer.writeString(message);
        self.send(writer.buffer);
    };

    self.login = function (username, password) {
        timeLog("Logging in...");
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.LOGIN));
        writer.writeString(username);
        writer.writeString(password);
        writer.writeString(VERSION);
        var i = languageSelection.hasChangedLanguage() ? LANGUAGE.getOrdinal() : -1;
        writer.writeInt(i);
        self.send(writer.buffer);
    };

    self.joinTable = function (tableId, isPlayer) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.JOIN_TABLE));
        writer.writeLong(tableId);
        writer.writeBoolean(isPlayer);
        self.send(writer.buffer);
    };

    self.sendInvite = function (tableId, playerId) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.SEND_INVITE));
        writer.writeLong(tableId);
        writer.writeInt(playerId);
        self.send(writer.buffer);
    };

    self.changeTableRule = function (tableId, rule) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.CHANGE_TABLE_RULE));
        writer.writeLong(tableId);
        new TableRuleSerializer().serialize(rule, writer);
        self.send(writer.buffer);
    };

    self.changeHost = function (tableId, playerId) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.CHANGE_HOST));
        writer.writeLong(tableId);
        writer.writeInt(playerId);
        self.send(writer.buffer);
    };

    self.leaveTable = function (tableId, playerId) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.LEAVE_TABLE));
        writer.writeLong(tableId);
        writer.writeInt(playerId);
        self.send(writer.buffer);
    };

    self.sitDown = function (tableId) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.SIT_DOWN));
        writer.writeLong(tableId);
        self.send(writer.buffer);
    };

    self.standUp = function (tableId, playerId) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.STAND_UP));
        writer.writeLong(tableId);
        writer.writeInt(playerId);
        self.send(writer.buffer);
    };

    self.newTableRequest = function (rules) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.NEW_TABLE_REQUEST));
        var n = rules.length;
        writer.writeInt(n);
        var serializer = new TableRuleSerializer();
        for (var i = 0; i < n; i++) {
            serializer.serialize(rules[i], writer);
        }
        self.send(writer.buffer);
    };

    self.startGameRequest = function (tableId, isReady) {
        timeLog('Requesting start game, ready state: ' + isReady ? 'true' : 'false');
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.READY_TO_START));
        writer.writeLong(tableId);
        writer.writeBoolean(isReady);
        self.send(writer.buffer);
    };

    self.continueWithBots = function (tableId) {
        timeLog('Requesting continue with bots');
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.CONTINUE_WITH_BOTS));
        writer.writeLong(tableId);
        self.send(writer.buffer);
    };

    self.requestUpdate = function (updateType) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.REQUEST_UPDATE));
        writer.writeInt(getOrdinal(UpdateTypes, updateType));
        self.send(writer.buffer);
    };

    self.setUserPreference = function (userPref) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.CHANGE_USER_PROPERTY));
        new UserPrefSerializer().serialize(userPref, writer);
        self.send(writer.buffer);
    };

    self.loginWithCode = function (code) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.LOGIN_WITH_CODE));
        writer.writeString(code);
        writer.writeString(VERSION);
        self.send(writer.buffer);
    };

    self.loginWithSession = function (playerId, sessionId) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.LOGIN_WITH_SESSION));
        writer.writeInt(playerId);
        writer.writeString(VERSION);
        writer.writeString(sessionId);
        var i = languageSelection.hasChangedLanguage() ? LANGUAGE.getOrdinal() : -1;
        writer.writeInt(i);
        self.send(writer.buffer);
    };

    self.reconnectWithSession = function (playerId, sessionId) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.RECONNECT_SESSION));
        writer.writeInt(playerId);
        writer.writeString(VERSION);
        writer.writeString(sessionId);
        self.send(writer.buffer);
    };

    self.loginWithNewPassword = function (code, password) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.LOGIN_WITH_NEW_PASSWORD));
        writer.writeString(code);
        writer.writeString(VERSION);
        writer.writeString(password);
        var i = languageSelection.hasChangedLanguage() ? LANGUAGE.getOrdinal() : -1;
        writer.writeInt(i);
        self.send(writer.buffer);
    };

    self.forgotPassword = function (username) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.FORGOT_PASSWORD));
        writer.writeString(username);
        writer.writeString(VERSION);
        self.send(writer.buffer);
    };

    self.changePassword = function (old_pwd, new_pwd) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.CHANGE_PASSWORD));
        writer.writeString(old_pwd);
        writer.writeString(new_pwd);
        self.send(writer.buffer);
    };

    self.requestPurchasePrice = function (onlineProduct) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.REQUEST_PURCHASE_PRICE));
        writer.writeInt(getOrdinal(StorePackages, onlineProduct.storePackage));
        writer.writeLong(onlineProduct.endDate);
        writer.writeString(onlineProduct.country);
        self.send(writer.buffer);
    };

    self.purchase = function (purchase) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.PURCHASE));
        writer.writeInt(getOrdinal(StorePackages, purchase.onlineProduct.storePackage));
        writer.writeLong(purchase.onlineProduct.endDate);
        writer.writeString(purchase.onlineProduct.country);
        writer.writeLong(purchase.purchaseTime);
        writer.writeInt(purchase.amountInEuroCents);
        self.send(writer.buffer);
    };

    self.createAccount = function (accountInfo) {
        var signUpCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";

        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.CREATE_ACCOUNT));
        writer.writeString(accountInfo.username);
        writer.writeString(accountInfo.password);
        writer.writeString(accountInfo.email);
        writer.writeInt(accountInfo.gender);
        writer.writeString(VERSION);
        var i = languageSelection.hasChangedLanguage() ? LANGUAGE.getOrdinal() : -1;
        writer.writeInt(i);
        writer.writeString(signUpCode);
        self.send(writer.buffer);
    };

    self.removeSessions = function (username, password, shouldRemoveGames) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.REMOVE_SESSIONS));
        writer.writeString(username);
        writer.writeString(password);
        writer.writeString(VERSION);
        writer.writeBoolean(shouldRemoveGames);
        self.send(writer.buffer);
    };

    self.removeActiveSessions = function () {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.REMOVE_ACTIVE_SESSIONS));
        self.send(writer.buffer);
    };

    self.changeFriendStatus = function (namedId, following) {
        var requestRelationships = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.CHANGE_FRIEND_STATUS));
        if (namedId.id === null) {
            writer.writeInt(-1);
        } else {
            writer.writeInt(namedId.id);
        }
        writer.writeString(namedId.name);
        writer.writeBoolean(following);
        writer.writeBoolean(requestRelationships);
        self.send(writer.buffer);
    };

    self.requestAutomatch = function (matchingCriteria) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.REQUEST_AUTOMATCH));
        matchingCriteria.serialize(writer);
        self.send(writer.buffer);
    };

    self.addBot = function (tableId) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.ADD_BOT));
        writer.writeLong(tableId);
        self.send(writer.buffer);
    };

    self.changeMailStatus = function (messageId, newStatus) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.CHANGE_MAIL_STATUS));
        writer.writeLong(messageId);
        writer.writeInt(getOrdinal(MailStati, newStatus));
        self.send(writer.buffer);
    };

    self.requestMailMessages = function (messageIds) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.REQUEST_MAIL_MESSAGES));
        writer.writeInt(messageIds.length);
        messageIds.forEach(function (id) {
            return writer.writeLong(id);
        });
        self.send(writer.buffer);
    };

    self.requestMailSummaries = function (date) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.REQUEST_MAIL_SUMMARIES));
        writer.writeLong(date);
        self.send(writer.buffer);
    };

    self.requestLeaderboard = function () {
        var topX = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 20;
        var includeFriends = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.REQUEST_LEADERBOARD));
        writer.writeInt(topX);
        writer.writeBoolean(includeFriends);
        self.send(writer.buffer);
    };

    self.terminateGame = function () {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.TERMINATE_GAME));
        self.send(writer.buffer);
    };

    self.changeBlacklistStatus = function (namedId, isBlacklisted) {
        var requestRelationships = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.CHANGE_BLACKLIST_STATUS));
        if (namedId.id === null) {
            writer.writeInt(-1);
        } else {
            writer.writeInt(namedId.id);
        }
        writer.writeString(namedId.name);
        writer.writeBoolean(isBlacklisted);
        writer.writeBoolean(requestRelationships);
        self.send(writer.buffer);
    };

    self.logout = function () {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.LOGOUT));
        self.send(writer.buffer);
    };

    self.validateBonusCode = function (playerId, code) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.VALIDATE_BONUS_CODE));
        writer.writeInt(playerId);
        writer.writeString(code);
        self.send(writer.buffer);
    };

    self.useBonusCode = function (playerId, code, expansion) {
        var expansion_index = Object.keys(Expansions).map(function (k) {
            return Expansions[k].name;
        }).indexOf(expansion);
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.USE_BONUS_CODE));
        writer.writeInt(playerId);
        writer.writeString(code);
        writer.writeInt(expansion_index);
        self.send(writer.buffer);
    };

    self.reconnectGame = function () {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.RECONNECT_GAME));
        self.send(writer.buffer);
    };

    self.resign = function () {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.RESIGN));
        self.send(writer.buffer);
    };

    self.answerQuestion = function (answer) {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.ANSWER_QUESTION));
        writer.writeInt(answer.questionIndex);
        writer.writeInts(answer.list);
        var autoPlayed = false;
        writer.writeBoolean(autoPlayed);
        self.send(writer.buffer);
    };

    self.undoRequest = function (index) {
        timeLog("Sending undo request.");
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.UNDO_REQUEST));
        writer.writeInt(index);
        self.send(writer.buffer);
    };

    self.timeoutRequest = function (timeoutOffer) {
        timeLog("Sending timeout request.");
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.TIMEOUT_REQUEST));
        writer.writeInt(timeoutOffer.decisionIndex);
        self.send(writer.buffer);
    };

    self.undoRequestGranted = function (undoRequest) {
        timeLog("Sending undo request granted.");
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.UNDO_REQUEST_GRANTED));
        undoRequest.serialize(writer);
        self.send(writer.buffer);
    };

    self.undoRequestDenied = function (undoRequest) {
        timeLog("Sending undo request denied.");
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.UNDO_REQUEST_DENIED));
        undoRequest.serialize(writer);
        self.send(writer.buffer);
    };

    self.undoRequestCancelled = function (undoRequest) {
        timeLog("Sending undo request cancelled.");
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.UNDO_REQUEST_CANCELLED));
        undoRequest.serialize(writer);
        self.send(writer.buffer);
    };

    self.ping = function () {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.PING));
        self.send(writer.buffer);
    };

    self.timerRequest = function () {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.GET_TIMER));
        self.send(writer.buffer);
    };

    self.requestConnectToOtherServer = function () {
        var writer = new Writer();
        writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.REQUEST_CONNECT_TO_OTHER_SERVER));
        self.send(writer.buffer);
    };
}]);

function TableRuleSerializer() {
    var self = this;

    self.playersCanSeeSpectatorChat = function (writer, rule) {
        writer.writeBoolean(rule.value);
    };

    self.bannedCards = function (writer, rule) {
        var a = rule.arguments;
        writer.writeInts(a.map(function (c) {
            return getOrdinal(CardNames, c);
        }));
    };

    self.requiredCards = function (writer, rule) {
        writer.writeTypedArray(rule.arguments);
    };

    self.usedExpansions = function (writer, rule) {
        var a = rule.arguments;
        writer.writeInts(a.map(function (c) {
            return getOrdinal(Expansions, c);
        }));
    };

    self.onlySpecifiedIdsPlay = function (writer, rule) {

        throw new Error('Not yet implemented!');

        // let a = rule.arguments;
        // let n = a.length;
        // writer.writeInt(n);
        // for (let i = 0; i < n; i++) {
        //     let namedId = a[i];
        //     writer.writeInt(namedId.id);
        //     writer.writeString(namedId.name);
        // }
        // writer.writeBoolean(rule.value);
    };

    self.spectateRules = function (writer, rule) {
        var group = rule.value;
        writer.writeInt(getOrdinal(GroupIds, group));
        if (group === GroupIds.FRIENDS_OF) new NamedId(rule.arguments, "").serialize(writer);
    };

    self.minRating = function (writer, rule) {
        writer.writeDouble(rule.value);
    };

    self.maxRating = function (writer, rule) {
        writer.writeDouble(rule.value);
    };

    self.randomSeed = function (writer, rule) {
        writer.writeBoolean(rule.value);
        writer.writeLong(rule.arguments + "_0");
    };

    self.minPlayers = function (writer, rule) {
        writer.writeInt(rule.value);
    };

    self.maxPlayers = function (writer, rule) {
        writer.writeInt(rule.value);
    };

    self.specialKingdomRules = function (writer, rule) {
        rule.arguments.serialize(writer);
    };

    self.respectedCardLists = function (writer, rule) {
        writer.writeInts(rule.value);
    };

    self.showVPCounter = function (writer, rule) {
        writer.writeBoolean(rule.value);
    };

    self.playerOrder = function (writer, rule) {
        var playerOrder = rule.value;
        playerOrder.serialize(writer);
    };

    self.undoSettings = function (writer, rule) {
        throw new Error('Client to meta Undo Settings is not implemented');
    };

    self.alwaysAllowedPlayers = function (writer, rule) {
        throw new Error('Client to meta Always Allowed Players is not implemented');
    };

    self.replayInstructions = function (writer, rule) {
        var r = rule.value;
        r.serialize(writer);
    };

    self.ratedGame = function (writer, rule) {
        writer.writeBoolean(rule.value);
    };

    self.previewGame = function (writer, rule) {
        writer.writeBoolean(rule.value);
    };

    self.scriptedRules = function (writer, rule) {
        writer.writeTypedArray(rule.value);
    };

    self.cardPoolLevel = function (writer, rule) {
        writer.writeInt(rule.value);
    };

    self.serializers = [self.playersCanSeeSpectatorChat, self.bannedCards, self.requiredCards, self.usedExpansions, self.onlySpecifiedIdsPlay, self.spectateRules, self.minRating, self.maxRating, self.randomSeed, self.minPlayers, self.maxPlayers, self.specialKingdomRules, self.respectedCardLists, self.showVPCounter, self.playerOrder, self.undoSettings, self.alwaysAllowedPlayers, self.replayInstructions, self.ratedGame, self.previewGame, self.scriptedRules, self.cardPoolLevel];

    self.serialize = function (rule, writer) {
        var index = getOrdinal(TableRuleIds, rule.id);
        writer.writeInt(index);
        self.serializers[index](writer, rule);
    };
}

function UserPrefSerializer() {
    var self = this;

    self.ownership = function (writer, outputArguments) {
        //        writer.writeInts(outputArguments.map(c => getOrdinal(CardNames, c)));
    };

    self.cardList = function (writer, outputArguments) {
        outputArguments.serialize(writer);
    };

    self.expansionList = function (writer, outputArguments) {
        writer.writeInts(outputArguments.map(function (c) {
            return getOrdinal(Expansions, c);
        }));
    };

    self.playerList = function (writer, outputArguments) {
        writer.writeInts(outputArguments);
    };

    self.integer = function (writer, outputArguments) {
        writer.writeInt(outputArguments);
    };

    self.boolean = function (writer, outputArguments) {
        writer.writeBoolean(outputArguments);
    };

    self.string = function (writer, outputArguments) {
        writer.writeString(outputArguments);
    };

    self.autoplay_settings = function (writer, outputArguments) {

        outputArguments.serialize(writer);
    };

    self.matchingCriteria = function (writer, outputArguments) {
        outputArguments.serialize(writer);
    };

    self.serializers = {};
    self.serializers[UserPrefTypes.BOOLEAN] = self.boolean;
    self.serializers[UserPrefTypes.INTEGER] = self.integer;
    self.serializers[UserPrefTypes.STRING] = self.string;
    self.serializers[UserPrefTypes.EMAIL] = self.string;
    self.serializers[UserPrefTypes.CARD_LIST] = self.cardList;
    self.serializers[UserPrefTypes.OWNERSHIP] = self.ownership;
    self.serializers[UserPrefTypes.AUTOPLAY_SETTINGS] = self.autoplay_settings;
    self.serializers[UserPrefTypes.EXPANSION_LIST] = self.expansionList;
    self.serializers[UserPrefTypes.MATCHING_CRITERIA] = self.matchingCriteria;

    self.serialize = function (pref, writer) {
        var index = getOrdinal(UserPrefIds, pref.id);
        var type = pref.id.type;
        var typeIndex = getOrdinal(UserPrefTypes, type);
        writer.writeInt(index);
        writer.writeInt(typeIndex);
        self.serializers[type](writer, pref.arguments);
    };
}