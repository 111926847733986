"use strict";

function QuestionIdType() {
    this.uuid = generateUUID();
    this.toString = function () {
        return self.uuid;
    };
}

var QuestionIdTypes = {
    FORCE_SMALL_BUTTONS: new QuestionIdType(),
    ORDER: new QuestionIdType(),
    STACK_CARDS: new QuestionIdType(),
    DELAYED_CLEAR: new QuestionIdType(),
    DONT_SHOW_WINDOWS: new QuestionIdType()
};

function QuestionId() {
    var types = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
    self.forceSmallButton = types.includes(QuestionIdTypes.FORCE_SMALL_BUTTONS);
    self.stackCards = types.includes(QuestionIdTypes.STACK_CARDS);
    self.delayedClear = types.includes(QuestionIdTypes.DELAYED_CLEAR);
    self.isOrder = types.includes(QuestionIdTypes.ORDER);
    self.showWindows = !types.includes(QuestionIdTypes.DONT_SHOW_WINDOWS);
}

var QuestionIds = {
    GAME_RESOLVE_ABILITY: new QuestionId([QuestionIdTypes.DONT_SHOW_WINDOWS]),
    GAME_MAY_RESOLVE_ABILITY: new QuestionId([QuestionIdTypes.DONT_SHOW_WINDOWS]),
    GAME_MAY_REACT_WITH: new QuestionId(),
    GAME_TURN_SELECT: new QuestionId(),
    GAME_MAY_BUY: new QuestionId([QuestionIdTypes.DELAYED_CLEAR]),
    GAME_MAY_START_BUYING: new QuestionId([QuestionIdTypes.DELAYED_CLEAR]),
    GAME_MAY_BUY_NONCARDS: new QuestionId([QuestionIdTypes.DELAYED_CLEAR]),
    GAME_MAY_START_BUYING_NONCARDS: new QuestionId([QuestionIdTypes.DELAYED_CLEAR]),
    GAME_REPAY_HOW_MUCH: new QuestionId(),
    GAME_SPEND_COIN_TOKENS: new QuestionId(),
    GAME_MAY_PLAY_ACTION: new QuestionId(),
    GAME_MAY_PLAY_TREASURES: new QuestionId(),
    GAME_PLAY_ALL_TREASURES: new QuestionId(),
    GAME_ACTION_PHASE: new QuestionId(),
    GAME_BUY_PHASE: new QuestionId([QuestionIdTypes.DELAYED_CLEAR]),
    GAME_BUY_PHASE_NONCARDS: new QuestionId([QuestionIdTypes.DELAYED_CLEAR]),
    GAME_CLEANUP_PHASE: new QuestionId(),
    GAME_USE_VILLAGER: new QuestionId(),
    GAME_USE_COFFERS: new QuestionId(),
    GAME_PAY_OFF_DEBT: new QuestionId(),
    ARTISAN_GAIN: new QuestionId(),
    ARTISAN_TOPDECK: new QuestionId(),
    BANDIT: new QuestionId(),
    BUREAUCRAT: new QuestionId(),
    CELLAR: new QuestionId(),
    CHAPEL: new QuestionId(),
    HARBINGER_TOPDECK: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    LIBRARY: new QuestionId(),
    MILITIA: new QuestionId(),
    MINE_TRASH: new QuestionId(),
    MINE_GAIN: new QuestionId(),
    MONEYLENDER: new QuestionId(),
    POACHER: new QuestionId(),
    REMODEL_TRASH: new QuestionId(),
    REMODEL_GAIN: new QuestionId(),
    SENTRY_TRASH: new QuestionId(),
    SENTRY_DISCARD: new QuestionId(),
    SENTRY_TOPDECK: new QuestionId(),
    THRONE_ROOM: new QuestionId(),
    VASSAL: new QuestionId(),
    WORKSHOP: new QuestionId(),
    TRASHING_TOKEN: new QuestionId(),
    ALMS: new QuestionId(),
    AMULET_MODE: new QuestionId(),
    AMULET_TRASH: new QuestionId(),
    ARTIFICER_DELAY: new QuestionId(),
    ARTIFICER_DISCARD: new QuestionId(),
    ARTIFICER_GAIN: new QuestionId(),
    BALL_FIRST: new QuestionId(),
    BALL_SECOND: new QuestionId(),
    BONFIRE: new QuestionId([QuestionIdTypes.FORCE_SMALL_BUTTONS]),
    DISCIPLE: new QuestionId(),
    DUNGEON: new QuestionId(),
    FERRY: new QuestionId(),
    FUGITIVE: new QuestionId(),
    GEAR: new QuestionId(),
    HAUNTED_WOODS: new QuestionId(),
    HERO: new QuestionId(),
    INHERITANCE: new QuestionId(),
    LOST_ARTS_NEW: new QuestionId(),
    LOST_ARTS_MOVE: new QuestionId(),
    MESSENGER_DISCARD: new QuestionId(),
    MESSENGER_GAIN: new QuestionId(),
    MISER: new QuestionId(),
    PATHFINDING_NEW: new QuestionId(),
    PATHFINDING_MOVE: new QuestionId(),
    PILGRIMAGE_FIRST: new QuestionId([QuestionIdTypes.FORCE_SMALL_BUTTONS]),
    PILGRIMAGE_SECOND: new QuestionId([QuestionIdTypes.FORCE_SMALL_BUTTONS]),
    PILGRIMAGE_THIRD: new QuestionId([QuestionIdTypes.FORCE_SMALL_BUTTONS]),
    PLAN_NEW: new QuestionId(),
    PLAN_MOVE: new QuestionId(),
    QUEST_MODE: new QuestionId(),
    QUEST_ATTACK: new QuestionId(),
    QUEST_6CARDS: new QuestionId(),
    RATCATCHER: new QuestionId(),
    RAZE_CAN_TRASH_FROM_PLAY: new QuestionId(),
    RAZE_ALL: new QuestionId(),
    RAZE_CAN_TRASH_FROM_HAND: new QuestionId(),
    RAZE_CHOOSE_CARD: new QuestionId(),
    SAVE: new QuestionId(),
    SCOUTING_PARTY_DISCARD: new QuestionId(),
    SCOUTING_PARTY_TOPDECK: new QuestionId(),
    SEAWAY: new QuestionId(),
    SOLDIER: new QuestionId(),
    STORYTELLER_FIRST: new QuestionId(),
    STORYTELLER_SECOND: new QuestionId(),
    STORYTELLER_THIRD: new QuestionId(),
    TEACHER_CHOOSE_TOKEN: new QuestionId(),
    TEACHER_PLUS_ACTION_MOVE: new QuestionId(),
    TEACHER_PLUS_ACTION_NEW: new QuestionId(),
    TEACHER_PLUS_CARD_MOVE: new QuestionId(),
    TEACHER_PLUS_CARD_NEW: new QuestionId(),
    TEACHER_PLUS_COIN_MOVE: new QuestionId(),
    TEACHER_PLUS_COIN_NEW: new QuestionId(),
    TEACHER_PLUS_BUY_MOVE: new QuestionId(),
    TEACHER_PLUS_BUY_NEW: new QuestionId(),
    TRADE: new QuestionId(),
    TRAINING_NEW: new QuestionId(),
    TRAINING_MOVE: new QuestionId(),
    TRANSMOGRIFY_TRASH: new QuestionId(),
    TRANSMOGRIFY_GAIN: new QuestionId(),
    APOTHECARY: new QuestionId(),
    APPRENTICE: new QuestionId(),
    GOLEM: new QuestionId(),
    HERBALIST: new QuestionId([QuestionIdTypes.FORCE_SMALL_BUTTONS]),
    HERBALISTS: new QuestionId([QuestionIdTypes.FORCE_SMALL_BUTTONS]),
    SCRYING_POOL_YOU: new QuestionId(),
    SCRYING_POOL_OTHER: new QuestionId(),
    TRANSMUTE: new QuestionId(),
    UNIVERSITY: new QuestionId(),
    FOLLOWERS: new QuestionId(),
    DIADEM: new QuestionId(),
    HAMLET: new QuestionId(),
    HAMLET_ACTION: new QuestionId(),
    HAMLET_BUY: new QuestionId(),
    HAMLET_BOTH: new QuestionId(),
    HORN_OF_PLENTY: new QuestionId(),
    HORSE_TRADERS: new QuestionId(),
    JESTER: new QuestionId(),
    REMAKE_TRASH_FIRST: new QuestionId(),
    REMAKE_TRASH_SECOND: new QuestionId(),
    REMAKE_GAIN_FIRST: new QuestionId(),
    REMAKE_GAIN_SECOND: new QuestionId(),
    TOURNAMENT_GAIN_PRIZE_OR_DUCHY: new QuestionId(),
    TOURNAMENT_MAY_GAIN_PRIZE: new QuestionId(),
    TOURNAMENT_MAY_GAIN_DUCHY: new QuestionId(),
    TOURNAMENT_REVEAL: new QuestionId(),
    TRUSTY_STEED: new QuestionId(),
    YOUNG_WITCH: new QuestionId(),
    YOUNG_WITCH_BANE: new QuestionId(),
    ALTAR_TRASH: new QuestionId(),
    ALTAR_GAIN: new QuestionId(),
    ARMORY: new QuestionId(),
    BAND_OF_MISFITS: new QuestionId(),
    CATACOMBS_MODE: new QuestionId(),
    CATACOMBS_GAIN: new QuestionId(),
    COUNT_PAY: new QuestionId(),
    COUNT_DISCARD: new QuestionId(),
    COUNT_TOPDECK: new QuestionId(),
    COUNT_PROFIT: new QuestionId(),
    COUNTERFEIT: new QuestionId(),
    CULTIST: new QuestionId(),
    DAME_ANNA: new QuestionId(),
    DAME_NATALIE: new QuestionId(),
    DEATH_CART: new QuestionId(),
    FORAGER: new QuestionId(),
    KNIGHTS: new QuestionId(),
    KNIGHTS_ALREADY_DIES: new QuestionId(),
    KNIGHTS_STILL_MATTERS: new QuestionId(),
    GRAVEROBBER_MAY_EXPAND: new QuestionId(),
    GRAVEROBBER_EXPAND: new QuestionId(),
    GRAVEROBBER_MAY_GAIN: new QuestionId(),
    GRAVEROBBER_GAIN: new QuestionId(),
    GRAVEROBBER_COMPLEX: new QuestionId(),
    GRAVEROBBER_GAIN_SUPPLY: new QuestionId(),
    HERMIT_TRASH: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    HERMIT_GAIN: new QuestionId(),
    HUNTING_GROUNDS: new QuestionId(),
    IRONMONGER: new QuestionId(),
    JUNK_DEALER: new QuestionId(),
    MERCENARY_ONE: new QuestionId(),
    MERCENARY_MULTIPLE: new QuestionId(),
    MERCENARY_DISCARD: new QuestionId(),
    MYSTIC: new QuestionId([QuestionIdTypes.DONT_SHOW_WINDOWS]),
    PILLAGE: new QuestionId(),
    PROCESSION_PLAY: new QuestionId(),
    PROCESSION_GAIN: new QuestionId(),
    RATS_TRASH_ANY: new QuestionId(),
    RATS_TRASH_OTHER: new QuestionId(),
    REBUILD_SKIP: new QuestionId([QuestionIdTypes.DONT_SHOW_WINDOWS]),
    REBUILD_GAIN: new QuestionId(),
    ROGUE_TRASH: new QuestionId(),
    ROGUE_GAIN: new QuestionId(),
    SCAVENGER: new QuestionId(),
    SCAVENGER_TOPDECK: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    SIR_MICHAEL: new QuestionId(),
    SQUIRE_MODE: new QuestionId(),
    SQUIRE_GAIN: new QuestionId(),
    STOREROOM_CARDS: new QuestionId(),
    STOREROOM_COINS: new QuestionId(),
    SURVIVORS: new QuestionId(),
    URCHIN: new QuestionId(),
    WANDERING_MINSTREL: new QuestionId(),
    ADVANCE_TRASH: new QuestionId(),
    ADVANCE_GAIN: new QuestionId(),
    ANNEX: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    ARCHIVE: new QuestionId(),
    ARENA: new QuestionId(),
    BANQUET: new QuestionId(),
    BUSTLING_VILLAGE: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    CATAPULT_TRASH: new QuestionId(),
    CATAPULT_DISCARD: new QuestionId(),
    CHARM_MODE: new QuestionId(),
    CHARM_GAIN: new QuestionId(),
    CROWN_ACTION: new QuestionId(),
    CROWN_TREASURE: new QuestionId(),
    DONATE: new QuestionId(),
    ENCAMPMENT: new QuestionId(),
    ENGINEER_GAIN: new QuestionId(),
    ENGINEER_TRASH: new QuestionId(),
    FORUM: new QuestionId(),
    GLADIATOR_REVEAL: new QuestionId(),
    GLADIATOR_PRETEND: new QuestionId(),
    GLADIATOR_BLOCK: new QuestionId(),
    HAUNTED_CASTLE: new QuestionId(),
    LEGIONARY_REVEAL: new QuestionId(),
    LEGIONARY_DISCARD: new QuestionId(),
    MOUNTAIN_PASS_NEW: new QuestionId(),
    MOUNTAIN_PASS_OVERBID: new QuestionId(),
    OPULENT_CASTLE: new QuestionId(),
    OVERLORD: new QuestionId(),
    RITUAL: new QuestionId(),
    SACRIFICE: new QuestionId(),
    SALT_THE_EARTH: new QuestionId(),
    SETTLERS: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    SMALL_CASTLE_IN_PLAY: new QuestionId(),
    SMALL_CASTLE_GONE: new QuestionId(),
    SMALL_CASTLE_GAIN: new QuestionId(),
    SPRAWLING_CASTLE: new QuestionId(),
    TAX: new QuestionId(),
    TEMPLE: new QuestionId(),
    WILD_HUNT: new QuestionId(),
    ADVISOR: new QuestionId(),
    BUTCHER_TRASH: new QuestionId(),
    BUTCHER_GAIN: new QuestionId(),
    DOCTOR_PLAY: new QuestionId([QuestionIdTypes.DONT_SHOW_WINDOWS]),
    DOCTOR_TOPDECK: new QuestionId(),
    DOCTOR_OVERPAY: new QuestionId(),
    DOCTOR_MODE: new QuestionId(),
    DOCTOR_MODE_MANY: new QuestionId(),
    HERALD_OVERPAY: new QuestionId(),
    HERALD_CHOOSE: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    JOURNEYMAN: new QuestionId([QuestionIdTypes.DONT_SHOW_WINDOWS]),
    MASTERPIECE: new QuestionId(),
    PLAZA_DELAY: new QuestionId(),
    PLAZA_DISCARD: new QuestionId(),
    STONEMASON_TRASH: new QuestionId(),
    STONEMASON_GAIN1: new QuestionId(),
    STONEMASON_GAIN2: new QuestionId(),
    STONEMASON_OVERPAY: new QuestionId(),
    STONEMASON_GAIN_EQUAL: new QuestionId(),
    STONEMASON_OVERPAY_AMOUNT: new QuestionId(),
    TAXMAN_TRASH: new QuestionId(),
    TAXMAN_GAIN: new QuestionId(),
    BORDER_VILLAGE: new QuestionId(),
    CARTOGRAPHER_DISCARD: new QuestionId(),
    CARTOGRAPHER_TOPDECK: new QuestionId(),
    DEVELOP_TRASH: new QuestionId(),
    DEVELOP_GAIN1: new QuestionId(),
    DEVELOP_GAIN2: new QuestionId(),
    DUCHESS: new QuestionId(),
    EMBASSY: new QuestionId(),
    FARMLAND_TRASH: new QuestionId(),
    FARMLAND_GAIN: new QuestionId(),
    HAGGLER: new QuestionId(),
    ILL_GOTTEN_GAINS: new QuestionId(),
    INN_DISCARD: new QuestionId(),
    INN_SHUFFLE: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    JACK_DISCARD: new QuestionId(),
    JACK_TRASH: new QuestionId(),
    MANDARIN_PLAY: new QuestionId(),
    MANDARIN_GAIN: new QuestionId(),
    MARGRAVE: new QuestionId(),
    NOBLE_BRIGAND: new QuestionId(),
    OASIS: new QuestionId(),
    ORACLE_DISCARD: new QuestionId(),
    ORACLE_TOPDECK: new QuestionId(),
    SCHEME: new QuestionId([QuestionIdTypes.FORCE_SMALL_BUTTONS]),
    SCHEMES: new QuestionId([QuestionIdTypes.FORCE_SMALL_BUTTONS]),
    SPICE_MERCHANT_TRASH: new QuestionId(),
    SPICE_MERCHANT_MODE: new QuestionId(),
    STABLES: new QuestionId(),
    TRADER_TRASH: new QuestionId(),
    BARON: new QuestionId(),
    COURTIER_REVEAL: new QuestionId(),
    COURTIER_MODE: new QuestionId(),
    COURTYARD: new QuestionId(),
    DIPLOMAT: new QuestionId(),
    IRONWORKS: new QuestionId(),
    LURKER_MAY_TRASH: new QuestionId(),
    LURKER_TRASH: new QuestionId(),
    LURKER_MAY_GAIN: new QuestionId(),
    LURKER_GAIN: new QuestionId(),
    LURKER_COMPLEX: new QuestionId(),
    MASQUERADE_PASS: new QuestionId(),
    MASQUERADE_TRASH: new QuestionId(),
    MILL: new QuestionId(),
    MINING_VILLAGE: new QuestionId(),
    MINION: new QuestionId(),
    NOBLES: new QuestionId(),
    PATROL: new QuestionId(),
    PAWN: new QuestionId(),
    REPLACE_TRASH: new QuestionId(),
    REPLACE_GAIN: new QuestionId(),
    SECRET_PASSAGE_TOPDECK: new QuestionId(),
    SECRET_PASSAGE_INSERT: new QuestionId(),
    STEWARD_MODE: new QuestionId(),
    STEWARD_TRASH: new QuestionId(),
    SWINDLER: new QuestionId(),
    TORTURER_MAY_CURSE: new QuestionId(),
    TORTURER_MAY_DISCARD: new QuestionId(),
    TORTURER_DISCARD_OR_CURSE: new QuestionId(),
    TRADING_POST: new QuestionId(),
    UPGRADE_TRASH: new QuestionId(),
    UPGRADE_GAIN: new QuestionId(),
    WISHING_WELL: new QuestionId([QuestionIdTypes.DONT_SHOW_WINDOWS]),
    BLACK_MARKET_BUY: new QuestionId(),
    BLACK_MARKET_PLAY: new QuestionId(),
    BLACK_MARKET_COMPLEX: new QuestionId(),
    SUMMON: new QuestionId(),
    ENVOY: new QuestionId(),
    PRINCE_MAY_SET_ASIDE: new QuestionId(),
    GOVERNOR_MODE: new QuestionId(),
    GOVERNOR_MAY_TRASH: new QuestionId(),
    GOVERNOR_GAIN: new QuestionId(),
    SAUNA_CHAIN: new QuestionId(),
    SAUNA_MAY_TRASH: new QuestionId(),
    AVANTO_CHAIN: new QuestionId(),
    BISHOP_SELF: new QuestionId(),
    BISHOP_OTHER: new QuestionId(),
    CONTRABAND: new QuestionId([QuestionIdTypes.DONT_SHOW_WINDOWS]),
    COUNTING_HOUSE: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    EXPAND_TRASH: new QuestionId(),
    EXPAND_GAIN: new QuestionId(),
    FORGE_TRASH: new QuestionId(),
    FORGE_GAIN: new QuestionId(),
    GOONS: new QuestionId(),
    KINGS_COURT: new QuestionId(),
    LOAN: new QuestionId(),
    MINT: new QuestionId(),
    MOUNTEBANK: new QuestionId(),
    RABBLE: new QuestionId(),
    TRADE_ROUTE: new QuestionId(),
    VAULT_SELF: new QuestionId(),
    VAULT_OTHER: new QuestionId(),
    AMBASSADOR_REVEAL: new QuestionId(),
    AMBASSADOR_RETURN_ONE: new QuestionId(),
    AMBASSADOR_RETURN_TWO: new QuestionId(),
    EMBARGO: new QuestionId(),
    EXPLORER: new QuestionId(),
    GHOST_SHIP: new QuestionId(),
    HAVEN: new QuestionId(),
    ISLAND: new QuestionId(),
    LOOKOUT_TRASH: new QuestionId(),
    LOOKOUT_DISCARD: new QuestionId(),
    NATIVE_VILLAGE_MAY_TAKE: new QuestionId(),
    NATIVE_VILLAGE_MAY_SET_ASIDE: new QuestionId(),
    NATIVE_VILLAGE_BOTH: new QuestionId(),
    NAVIGATOR: new QuestionId(),
    PEARL_DIVER: new QuestionId(),
    PIRATE_SHIP_MODE: new QuestionId(),
    PIRATE_SHIP_TRASH: new QuestionId(),
    SALVAGER: new QuestionId(),
    SMUGGLERS_MAY_GAIN: new QuestionId(),
    SMUGGLERS_GAIN: new QuestionId(),
    WAREHOUSE: new QuestionId(),
    SHORT_YOU_MAY_REACT: new QuestionId(),
    SHORT_WHEN_GAIN: new QuestionId(),
    SHORT_WHEN_WOULD_GAIN: new QuestionId(),
    SHORT_PLAY_CALL_SPECIFIC: new QuestionId(),
    SHORT_PLAY_CALL: new QuestionId(),
    SHORT_PLAY: new QuestionId(),
    SHORT_CALL_SPECIFIC: new QuestionId(),
    SHORT_CALL: new QuestionId(),
    THE_EARTHS_GIFT_DISCARD: new QuestionId(),
    THE_EARTHS_GIFT_GAIN: new QuestionId(),
    THE_FLAMES_GIFT: new QuestionId(),
    THE_MOONS_GIFT: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    THE_SKYS_GIFT: new QuestionId(),
    THE_SUNS_GIFT_TOPDECK: new QuestionId(),
    THE_SUNS_GIFT_DISCARD: new QuestionId(),
    THE_WINDS_GIFT: new QuestionId(),
    FEAR_DISCARD: new QuestionId(),
    HAUNTING: new QuestionId(),
    LOCUSTS: new QuestionId(),
    POVERTY: new QuestionId(),
    BAT: new QuestionId(),
    BLESSED_VILLAGE_MODE: new QuestionId(),
    CEMETERY: new QuestionId(),
    CHANGELING_GAIN_COPY: new QuestionId(),
    CHANGELING_REPLACE: new QuestionId(),
    COBBLER: new QuestionId(),
    CONCLAVE: new QuestionId(),
    CRYPT_SET_ASIDE: new QuestionId([QuestionIdTypes.FORCE_SMALL_BUTTONS]),
    CRYPT_RETURN_TO_HAND: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    DEVILS_WORKSHOP: new QuestionId(),
    DRUID: new QuestionId(),
    EXORCIST_TRASH: new QuestionId(),
    EXORCIST_GAIN: new QuestionId(),
    FOOL: new QuestionId(),
    MONASTERY: new QuestionId([QuestionIdTypes.FORCE_SMALL_BUTTONS]),
    GOAT: new QuestionId(),
    HAUNTED_MIRROR: new QuestionId(),
    IMP: new QuestionId(),
    NECROMANCER: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    NIGHT_WATCHMAN_TOPDECK: new QuestionId(),
    NIGHT_WATCHMAN_DISCARD: new QuestionId(),
    PIXIE_MODE: new QuestionId(),
    POOKA: new QuestionId(),
    RAIDER: new QuestionId(),
    SACRED_GROVE_MODE: new QuestionId(),
    SECRET_CAVE: new QuestionId(),
    SHEPHERD: new QuestionId(),
    TRAGIC_HERO: new QuestionId(),
    VAMPIRE: new QuestionId(),
    WISH: new QuestionId(),
    ZOMBIE_APPRENTICE: new QuestionId(),
    ZOMBIE_MASON: new QuestionId(),
    ZOMBIE_SPY: new QuestionId(),
    WHAT_NIGHT_TO_PLAY: new QuestionId(),
    LOST_IN_THE_WOODS: new QuestionId(),
    START_GAME: new QuestionId(),
    DISMANTLE_TRASH: new QuestionId(),
    DISMANTLE_GAIN: new QuestionId(),
    DUCAT: new QuestionId(),
    IMPROVE_TRASH: new QuestionId(),
    IMPROVE_GAIN: new QuestionId(),
    HIDEOUT: new QuestionId(),
    MOUNTAIN_VILLAGE: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    PRIEST: new QuestionId(),
    RESEARCH_TRASH: new QuestionId(),
    OLD_WITCH: new QuestionId(),
    RECRUITER: new QuestionId(),
    SCEPTER_COMPLEX: new QuestionId(),
    SCEPTER_PLAY: new QuestionId(),
    SCEPTER_MODE: new QuestionId(),
    SCULPTOR: new QuestionId(),
    SEER: new QuestionId(),
    VILLAIN: new QuestionId(),
    TREASURER_COMPLEX: new QuestionId(),
    TREASURER_TRASH: new QuestionId(),
    TREASURER_GAIN: new QuestionId(),
    TREASURER_KEY: new QuestionId(),
    TREASURER_FAIL: new QuestionId(),
    BORDER_GUARD_PUT_IN_HAND: new QuestionId(),
    BORDER_GUARD_MODE: new QuestionId(),
    HORN_TOPDECK: new QuestionId(),
    CATHEDRAL: new QuestionId(),
    CITY_GATE: new QuestionId(),
    PAGEANT: new QuestionId(),
    SEWERS: new QuestionId(),
    SILOS: new QuestionId(),
    CROP_ROTATION: new QuestionId(),
    SINISTER_PLOT: new QuestionId(),
    STAR_CHART: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    CAPTAIN: new QuestionId(),
    CHURCH_SET_ASIDE: new QuestionId(),
    CHURCH_TRASH: new QuestionId(),
    CAMEL_TRAIN: new QuestionId(),
    GOATHERD: new QuestionId(),
    SNOWY_VILLAGE: new QuestionId(),
    BOUNTY_HUNTER: new QuestionId(),
    CARDINAL_EXILE: new QuestionId(),
    GROOM: new QuestionId(),
    HOSTELRY: new QuestionId(),
    VILLAGE_GREEN: new QuestionId(),
    BARGE: new QuestionId(),
    DISPLACE_EXILE: new QuestionId(),
    DISPLACE_GAIN: new QuestionId(),
    FALCONER: new QuestionId(),
    HUNTING_LODGE: new QuestionId(),
    MASTERMIND: new QuestionId(),
    SANCTUARY: new QuestionId(),
    WAYFARER: new QuestionId(),
    DELAY: new QuestionId(),
    DESPERATION: new QuestionId(),
    GAMBLE: new QuestionId(),
    PURSUE: new QuestionId([QuestionIdTypes.DONT_SHOW_WINDOWS]),
    TOIL: new QuestionId(),
    ENHANCE_TRASH: new QuestionId(),
    ENHANCE_GAIN: new QuestionId(),
    MARCH: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    TRANSPORT_EXILE: new QuestionId(),
    TRANSPORT_MAY_EXILE: new QuestionId(),
    TRANSPORT_TOPDECK: new QuestionId(),
    TRANSPORT_MAY_TOPDECK: new QuestionId(),
    TRANSPORT_COMPLEX: new QuestionId(),
    BANISH: new QuestionId(),
    BARGAIN: new QuestionId(),
    INVEST: new QuestionId(),
    DEMAND: new QuestionId(),
    POPULATE: new QuestionId(),
    RECONSIDER_WAY: new QuestionId(),
    CONSIDER_WAY: new QuestionId(),
    WAY_OF_THE_GOAT: new QuestionId(),
    WAY_OF_THE_RAT: new QuestionId(),
    SCRAP_TRASH: new QuestionId(),
    SCRAP_MODES: new QuestionId([QuestionIdTypes.FORCE_SMALL_BUTTONS]),
    ANIMAL_FAIR_TRASH: new QuestionId(),
    ANIMAL_FAIR_MAY_TRASH: new QuestionId(),
    BUTTERFLY_RETURN: new QuestionId([QuestionIdTypes.FORCE_SMALL_BUTTONS]),
    BUTTERFLY_GAIN: new QuestionId([QuestionIdTypes.FORCE_SMALL_BUTTONS]),
    BLOCKADE: new QuestionId(),
    PIRATE: new QuestionId(),
    WHEELWRIGHT_DISCARD: new QuestionId(),
    WHEELWRIGHT_GAIN: new QuestionId(),
    TIARA_MODE: new QuestionId(),
    TIARA_PLAY: new QuestionId(),
    CRYSTAL_BALL: new QuestionId(),
    WAR_CHEST_PROHIBIT: new QuestionId([QuestionIdTypes.DONT_SHOW_WINDOWS]),
    WAR_CHEST_GAIN: new QuestionId(),
    SAILOR: new QuestionId(),
    TOWN_MODE: new QuestionId(),
    MODIFY_TRASH: new QuestionId(),
    MODIFY_MODE: new QuestionId(),
    MODIFY_GAIN: new QuestionId(),
    SWAP_RETURN: new QuestionId(),
    SWAP_GAIN: new QuestionId(),
    SKIRMISHER: new QuestionId(),
    WOODWORKERS_GUILD_TRASH: new QuestionId(),
    WOODWORKERS_GUILD_GAIN: new QuestionId(),
    CRAFTERS_GUILD: new QuestionId(),
    CAVE_DWELLERS: new QuestionId(),
    DESERT_GUIDES: new QuestionId(),
    BAND_OF_NOMADS: new QuestionId(),
    SYCOPHANT: new QuestionId(),
    IMPORTER: new QuestionId(),
    CONTRACT: new QuestionId(),
    BAUBLE: new QuestionId(),
    BROKER_TRASH: new QuestionId(),
    BROKER_MODE: new QuestionId(),
    MARKET_TOWNS_FIRST: new QuestionId(),
    MARKET_TOWNS_AGAIN: new QuestionId(),
    BATTLE_PLAN_REVEAL: new QuestionId(),
    BATTLE_PLAN_ROTATE: new QuestionId(),
    BARBARIAN: new QuestionId(),
    INNKEEPER_MODE: new QuestionId(),
    INNKEEPER_DISCARD: new QuestionId(),
    CAPITAL_CITY_MODE: new QuestionId(),
    CAPITAL_CITY_DISCARD: new QuestionId(),
    SPECIALIST_PLAY: new QuestionId(),
    SPECIALIST_MODE: new QuestionId(),
    GANG_OF_PICKPOCKETS: new QuestionId(),
    COASTAL_HAVEN: new QuestionId(),
    CARPENTER_GAIN: new QuestionId(),
    CARPENTER_TRASH: new QuestionId(),
    CARPENTER_GAIN_UP_TO: new QuestionId(),
    MAY_ROTATE_FORTS: new QuestionId(),
    ROYAL_GALLEY: new QuestionId(),
    HILL_FORT_GAIN: new QuestionId(),
    HILL_FORT_MODE: new QuestionId(),
    STRONGHOLD_MODE: new QuestionId(),
    MAY_ROTATE_ODYSSEYS: new QuestionId(),
    OLD_MAP_DISCARD: new QuestionId(),
    SUNKEN_TREASURE: new QuestionId(),
    PEACEFUL_CULT: new QuestionId(),
    ORDER_OF_ASTROLOGERS: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    STAR_CHART_ASTROLOGERS_COMBINED: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    ORDER_OF_MASONS: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    ARCHER_HIDE: new QuestionId(),
    ARCHER_DISCARD: new QuestionId(),
    MARQUIS: new QuestionId(),
    HERB_GATHERER_PLAY: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    MAY_ROTATE_AUGURS: new QuestionId(),
    ACOLYTE: new QuestionId(),
    ACOLYTE_SELF: new QuestionId(),
    SORCERESS: new QuestionId([QuestionIdTypes.DONT_SHOW_WINDOWS]),
    SIBYL_TOPDECK: new QuestionId(),
    SIBYL_BOTTOMDECK: new QuestionId(),
    MAY_ROTATE_WIZARDS: new QuestionId(),
    STUDENT: new QuestionId(),
    CONJURER: new QuestionId(),
    SORCERER: new QuestionId([QuestionIdTypes.DONT_SHOW_WINDOWS]),
    LICH: new QuestionId(),
    MAY_ROTATE_TOWNSFOLK: new QuestionId(),
    TOWN_CRIER: new QuestionId(),
    BLACKSMITH: new QuestionId(),
    MILLER: new QuestionId(),
    ELDER: new QuestionId(),
    SENTINEL_TRASH: new QuestionId(),
    SENTINEL_TOPDECK: new QuestionId(),
    COURIER: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    HUNTER_ACTION: new QuestionId(),
    HUNTER_TREASURE: new QuestionId(),
    HUNTER_VICTORY: new QuestionId(),
    LURKER_MODE: new QuestionId(),
    GRAVEROBBER_MODE: new QuestionId(),
    TREASURER_MODE: new QuestionId(),
    ARCHITECTS_GUILD: new QuestionId(),
    FAMILY_OF_INVENTORS: new QuestionId(),
    FOREST_DWELLERS_DISCARD: new QuestionId(),
    FOREST_DWELLERS_TOPDECK: new QuestionId(),
    TIDE_POOLS: new QuestionId(),
    SEA_WITCH: new QuestionId(),
    ANVIL_DISCARD: new QuestionId(),
    ANVIL_GAIN: new QuestionId(),
    CLERK: new QuestionId(),
    INVESTMENT_TRASH: new QuestionId(),
    INVESTMENT_MODE: new QuestionId(),
    BERSERKER_GAIN: new QuestionId(),
    BERSERKER_DISCARD: new QuestionId(),
    WEAVER: new QuestionId(),
    SOUK: new QuestionId(),
    WITCHS_HUT: new QuestionId(),
    MARCHLAND: new QuestionId(),
    UNUSED_QUESTION_23: new QuestionId(),
    UNUSED_QUESTION_24: new QuestionId(),
    UNUSED_QUESTION_25: new QuestionId(),
    UNUSED_QUESTION_26: new QuestionId(),
    UNUSED_QUESTION_27: new QuestionId(),
    UNUSED_QUESTION_28: new QuestionId(),
    UNUSED_QUESTION_29: new QuestionId(),
    UNUSED_QUESTION_30: new QuestionId(),
    UNUSED_QUESTION_31: new QuestionId(),
    UNUSED_QUESTION_32: new QuestionId(),
    UNUSED_QUESTION_33: new QuestionId(),
    UNUSED_QUESTION_34: new QuestionId(),
    UNUSED_QUESTION_35: new QuestionId(),
    UNUSED_QUESTION_36: new QuestionId(),
    UNUSED_QUESTION_37: new QuestionId(),
    UNUSED_QUESTION_38: new QuestionId(),
    SHOP: new QuestionId(),
    FERRYMAN: new QuestionId(),
    UNUSED_QUESTION_41: new QuestionId(),
    USE_FAVOR_WHEN_SHUFFLING: new QuestionId(),
    MAY_PAY_OFF_EXTRA_DEBT: new QuestionId(),
    MAY_SPEND_EXTRA_COFFERS: new QuestionId(),
    MAY_SPEND_EXTRA_COFFERS_BASILICA: new QuestionId(),
    MAY_SPEND_EXTRA_COFFERS_SWASHBUCKLER: new QuestionId(),
    MAY_SPEND_EXTRA_COFFERS_FORTUNE: new QuestionId(),
    CAGE_SET_ASIDE: new QuestionId(),
    GROTTO_SET_ASIDE: new QuestionId(),
    SHAMAN_GAIN: new QuestionId(),
    SHAMAN_TRASH: new QuestionId(),
    SECLUDED_SHRINE: new QuestionId(),
    SIREN: new QuestionId(),
    CABIN_BOY_MODE: new QuestionId(),
    CABIN_BOY_GAIN: new QuestionId(),
    CRUCIBLE: new QuestionId(),
    FORTUNE_HUNTER_PLAY: new QuestionId(),
    FORTUNE_HUNTER_TOPDECK: new QuestionId(),
    GONDOLA_MODE: new QuestionId(),
    GONDOLA_PLAY: new QuestionId(),
    MAPMAKER: new QuestionId(),
    MAROON: new QuestionId(),
    ROPE_TRASH: new QuestionId(),
    TOOLS: new QuestionId(),
    CUTTHROAT_DISCARD: new QuestionId(),
    ENLARGE_TRASH: new QuestionId(),
    ENLARGE_GAIN: new QuestionId(),
    FIGURINE: new QuestionId(),
    FIRST_MATE: new QuestionId(),
    FRIGATE: new QuestionId(),
    PICKAXE: new QuestionId(),
    PILGRIM_TOPDECK: new QuestionId(),
    QUARTERMASTER_MODE: new QuestionId(),
    QUARTERMASTER_GAIN: new QuestionId(),
    QUARTERMASTER_TAKE: new QuestionId(),
    TRICKSTER: new QuestionId(),
    SILVER_MINE: new QuestionId(),
    KINGS_CACHE: new QuestionId(),
    BURY: new QuestionId(),
    FORAY: new QuestionId(),
    PERIL: new QuestionId(),
    PREPARE: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    SCROUNGE_COMPLEX: new QuestionId(),
    SCROUNGE_TRASH: new QuestionId(),
    SCROUNGE_MAY_TRASH: new QuestionId(),
    SCROUNGE_GAIN_ESTATE: new QuestionId(),
    SCROUNGE_MAY_GAIN_ESTATE: new QuestionId(),
    SCROUNGE_GAIN: new QuestionId(),
    MAELSTROM_OPPONENT_TRASH: new QuestionId(),
    MAELSTROM_TRASH: new QuestionId(),
    INVASION_PLAY: new QuestionId(),
    INVASION_GAIN: new QuestionId(),
    PROSPER: new QuestionId(),
    AMPHORA_MODE: new QuestionId(),
    HAMMER: new QuestionId(),
    ORB: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    PRIZE_GOAT: new QuestionId(),
    PUZZLE_BOX: new QuestionId(),
    SEXTANT_DISCARD: new QuestionId(),
    SEXTANT_TOPDECK: new QuestionId(),
    SPELL_SCROLL_GAIN: new QuestionId(),
    SPELL_SCROLL_PLAY: new QuestionId(),
    STAFF: new QuestionId(),
    SWORD: new QuestionId(),
    FRIENDLY: new QuestionId(),
    INHERITED: new QuestionId(),
    INSPIRING: new QuestionId(),
    PATIENT_SET_ASIDE: new QuestionId(),
    PATIENT_PLAY: new QuestionId(),
    PIOUS_TRASH: new QuestionId(),
    SHY: new QuestionId(),
    FATED_TOPDECK: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    FATED_BOTTOMDECK: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    AVOID: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    TRICKSTER_DISCARD: new QuestionId(),
    FARMHANDS: new QuestionId(),
    COURSER: new QuestionId(),
    CORONET_ACTION: new QuestionId(),
    CORONET_TREASURE: new QuestionId(),
    JOUST_SET_ASIDE: new QuestionId(),
    JOUST_GAIN_REWARD: new QuestionId([QuestionIdTypes.STACK_CARDS]),
    INFIRMARY_TRASH: new QuestionId(),
    INFIRMARY_OVERPAY: new QuestionId(),
    FOOTPAD: new QuestionId(),
    FARRIER_OVERPAY: new QuestionId(),
    ORDER_BOTTOMDECKED_CARDS: new QuestionId(),
    GROWTH: new QuestionId(),
    KIND_EMPEROR: new QuestionId(),
    SICKNESS_MODE: new QuestionId(),
    SICKNESS_DISCARD: new QuestionId(),
    SICKNESS: new QuestionId(),
    SNAKE_WITCH: new QuestionId(),
    CRAFTSMAN: new QuestionId(),
    ALLEY: new QuestionId(),
    CHANGE_TRASH: new QuestionId(),
    CHANGE_GAIN: new QuestionId(),
    NINJA: new QuestionId(),
    RIVER_SHRINE_TRASH: new QuestionId(),
    RIVER_SHRINE_GAIN: new QuestionId(),
    RUSTIC_VILLAGE_CHOICE: new QuestionId(),
    RUSTIC_VILLAGE_DISCARD: new QuestionId(),
    GOLD_MINE: new QuestionId(),
    KITSUNE: new QuestionId(),
    TANUKI_TRASH: new QuestionId(),
    TANUKI_GAIN: new QuestionId(),
    SAMURAI: new QuestionId(),
    MOUNTAIN_SHRINE: new QuestionId(),
    AMASS: new QuestionId(),
    ASCETICISM: new QuestionId(),
    CONTINUE: new QuestionId(),
    CREDIT: new QuestionId(),
    KINTSUGI_TRASH: new QuestionId(),
    KINTSUGI_GAIN: new QuestionId(),
    PRACTICE: new QuestionId(),
    SEA_TRADE: new QuestionId(),
    RECEIVE_TRIBUTE_FIRST: new QuestionId(),
    RECEIVE_TRIBUTE_SECOND: new QuestionId(),
    RECEIVE_TRIBUTE_THIRD: new QuestionId(),
    GATHER: new QuestionId(),
    PAY_OFF_DEBT_BEFORE_CHANGE: new QuestionId(),
    PAY_OFF_DEBT_BEFORE_STORYTELLER: new QuestionId()

};