"use strict";

var EnglishCardNames = {};

EnglishCardNames[CardNames.BACK] = new TranslatedCardName("card", "cards", "a card");
EnglishCardNames[CardNames.CURSE] = new TranslatedCardName("Curse", "Curses", "a Curse");
EnglishCardNames[CardNames.COPPER] = new TranslatedCardName("Copper", "Coppers", "a Copper");
EnglishCardNames[CardNames.SILVER] = new TranslatedCardName("Silver", "Silvers", "a Silver");
EnglishCardNames[CardNames.GOLD] = new TranslatedCardName("Gold", "Golds", "a Gold");
EnglishCardNames[CardNames.ESTATE] = new TranslatedCardName("Estate", "Estates", "an Estate");
EnglishCardNames[CardNames.DUCHY] = new TranslatedCardName("Duchy", "Duchies", "a Duchy");
EnglishCardNames[CardNames.PROVINCE] = new TranslatedCardName("Province", "Provinces", "a Province");
EnglishCardNames[CardNames.ARTISAN] = new TranslatedCardName("Artisan", "Artisans", "an Artisan");
EnglishCardNames[CardNames.BANDIT] = new TranslatedCardName("Bandit", "Bandits", "a Bandit");
EnglishCardNames[CardNames.BUREAUCRAT] = new TranslatedCardName("Bureaucrat", "Bureaucrats", "a Bureaucrat");
EnglishCardNames[CardNames.CELLAR] = new TranslatedCardName("Cellar", "Cellars", "a Cellar");
EnglishCardNames[CardNames.CHAPEL] = new TranslatedCardName("Chapel", "Chapels", "a Chapel");
EnglishCardNames[CardNames.COUNCIL_ROOM] = new TranslatedCardName("Council Room", "Council Rooms", "a Council Room");
EnglishCardNames[CardNames.FESTIVAL] = new TranslatedCardName("Festival", "Festivals", "a Festival");
EnglishCardNames[CardNames.GARDENS] = new TranslatedCardName("Gardens", "Gardens", "a Gardens");
EnglishCardNames[CardNames.HARBINGER] = new TranslatedCardName("Harbinger", "Harbingers", "a Harbinger");
EnglishCardNames[CardNames.LABORATORY] = new TranslatedCardName("Laboratory", "Laboratories", "a Laboratory");
EnglishCardNames[CardNames.LIBRARY] = new TranslatedCardName("Library", "Libraries", "a Library");
EnglishCardNames[CardNames.MARKET] = new TranslatedCardName("Market", "Markets", "a Market");
EnglishCardNames[CardNames.MERCHANT] = new TranslatedCardName("Merchant", "Merchants", "a Merchant");
EnglishCardNames[CardNames.MILITIA] = new TranslatedCardName("Militia", "Militias", "a Militia");
EnglishCardNames[CardNames.MINE] = new TranslatedCardName("Mine", "Mines", "a Mine");
EnglishCardNames[CardNames.MOAT] = new TranslatedCardName("Moat", "Moats", "a Moat");
EnglishCardNames[CardNames.MONEYLENDER] = new TranslatedCardName("Moneylender", "Moneylenders", "a Moneylender");
EnglishCardNames[CardNames.POACHER] = new TranslatedCardName("Poacher", "Poachers", "a Poacher");
EnglishCardNames[CardNames.REMODEL] = new TranslatedCardName("Remodel", "Remodels", "a Remodel");
EnglishCardNames[CardNames.SENTRY] = new TranslatedCardName("Sentry", "Sentries", "a Sentry");
EnglishCardNames[CardNames.SMITHY] = new TranslatedCardName("Smithy", "Smithies", "a Smithy");
EnglishCardNames[CardNames.THRONE_ROOM] = new TranslatedCardName("Throne Room", "Throne Rooms", "a Throne Room");
EnglishCardNames[CardNames.VASSAL] = new TranslatedCardName("Vassal", "Vassals", "a Vassal");
EnglishCardNames[CardNames.VILLAGE] = new TranslatedCardName("Village", "Villages", "a Village");
EnglishCardNames[CardNames.WITCH] = new TranslatedCardName("Witch", "Witches", "a Witch");
EnglishCardNames[CardNames.WORKSHOP] = new TranslatedCardName("Workshop", "Workshops", "a Workshop");

EnglishCardNames[CardNames.COURTYARD] = new TranslatedCardName("Courtyard", "Courtyards", "a Courtyard");
EnglishCardNames[CardNames.CONSPIRATOR] = new TranslatedCardName("Conspirator", "Conspirators", "a Conspirator");
EnglishCardNames[CardNames.COURTIER] = new TranslatedCardName("Courtier", "Courtiers", "a Courtier");
EnglishCardNames[CardNames.BARON] = new TranslatedCardName("Baron", "Barons", "a Baron");
EnglishCardNames[CardNames.BRIDGE] = new TranslatedCardName("Bridge", "Bridges", "a Bridge");
EnglishCardNames[CardNames.DIPLOMAT] = new TranslatedCardName("Diplomat", "Diplomats", "a Diplomat");
EnglishCardNames[CardNames.DUKE] = new TranslatedCardName("Duke", "Dukes", "a Duke");
EnglishCardNames[CardNames.FARM] = new TranslatedCardName("Farm", "Farms", "a Farm");
EnglishCardNames[CardNames.NOBLES] = new TranslatedCardName("Nobles", "Nobles", "a Nobles");
EnglishCardNames[CardNames.IRONWORKS] = new TranslatedCardName("Ironworks", "Ironworks", "an Ironworks");
EnglishCardNames[CardNames.LURKER] = new TranslatedCardName("Lurker", "Lurkers", "a Lurker");
EnglishCardNames[CardNames.MASQUERADE] = new TranslatedCardName("Masquerade", "Masquerades", "a Masquerade");
EnglishCardNames[CardNames.MILL] = new TranslatedCardName("Mill", "Mills", "a Mill");
EnglishCardNames[CardNames.MINING_VILLAGE] = new TranslatedCardName("Mining Village", "Mining Villages", "a Mining Village");
EnglishCardNames[CardNames.MINION] = new TranslatedCardName("Minion", "Minions", "a Minion");
EnglishCardNames[CardNames.PATROL] = new TranslatedCardName("Patrol", "Patrols", "a Patrol");
EnglishCardNames[CardNames.PAWN] = new TranslatedCardName("Pawn", "Pawns", "a Pawn");
EnglishCardNames[CardNames.REPLACE] = new TranslatedCardName("Replace", "Replaces", "a Replace");
EnglishCardNames[CardNames.SECRET_PASSAGE] = new TranslatedCardName("Secret Passage", "Secret Passages", "a Secret Passage");
EnglishCardNames[CardNames.SHANTY_TOWN] = new TranslatedCardName("Shanty Town", "Shanty Towns", "a Shanty Town");
EnglishCardNames[CardNames.STEWARD] = new TranslatedCardName("Steward", "Stewards", "a Steward");
EnglishCardNames[CardNames.SWINDLER] = new TranslatedCardName("Swindler", "Swindlers", "a Swindler");
EnglishCardNames[CardNames.TORTURER] = new TranslatedCardName("Torturer", "Torturers", "a Torturer");
EnglishCardNames[CardNames.TRADING_POST] = new TranslatedCardName("Trading Post", "Trading Posts", "a Trading Post");
EnglishCardNames[CardNames.UPGRADE] = new TranslatedCardName("Upgrade", "Upgrades", "an Upgrade");
EnglishCardNames[CardNames.WISHING_WELL] = new TranslatedCardName("Wishing Well", "Wishing Wells", "a Wishing Well");

EnglishCardNames[CardNames.AMBASSADOR] = new TranslatedCardName("Ambassador", "Ambassadors", "an Ambassador");
EnglishCardNames[CardNames.BAZAAR] = new TranslatedCardName("Bazaar", "Bazaars", "a Bazaar");
EnglishCardNames[CardNames.CARAVAN] = new TranslatedCardName("Caravan", "Caravans", "a Caravan");
EnglishCardNames[CardNames.CUTPURSE] = new TranslatedCardName("Cutpurse", "Cutpurses", "a Cutpurse");
EnglishCardNames[CardNames.EMBARGO] = new TranslatedCardName("Embargo", "Embargos", "an Embargo");
EnglishCardNames[CardNames.EXPLORER] = new TranslatedCardName("Explorer", "Explorers", "an Explorer");
EnglishCardNames[CardNames.FISHING_VILLAGE] = new TranslatedCardName("Fishing Village", "Fishing Villages", "a Fishing Village");
EnglishCardNames[CardNames.GHOST_SHIP] = new TranslatedCardName("Ghost Ship", "Ghost Ships", "a Ghost Ship");
EnglishCardNames[CardNames.HAVEN] = new TranslatedCardName("Haven", "Havens", "a Haven");
EnglishCardNames[CardNames.ISLAND] = new TranslatedCardName("Island", "Islands", "an Island");
EnglishCardNames[CardNames.LIGHTHOUSE] = new TranslatedCardName("Lighthouse", "Lighthouses", "a Lighthouse");
EnglishCardNames[CardNames.LOOKOUT] = new TranslatedCardName("Lookout", "Lookouts", "a Lookout");
EnglishCardNames[CardNames.MERCHANT_SHIP] = new TranslatedCardName("Merchant Ship", "Merchant Ships", "a Merchant Ship");
EnglishCardNames[CardNames.NATIVE_VILLAGE] = new TranslatedCardName("Native Village", "Native Villages", "a Native Village");
EnglishCardNames[CardNames.NAVIGATOR] = new TranslatedCardName("Navigator", "Navigators", "a Navigator");
EnglishCardNames[CardNames.OUTPOST] = new TranslatedCardName("Outpost", "Outposts", "an Outpost");
EnglishCardNames[CardNames.PEARL_DIVER] = new TranslatedCardName("Pearl Diver", "Pearl Divers", "a Pearl Diver");
EnglishCardNames[CardNames.PIRATE_SHIP] = new TranslatedCardName("Pirate Ship", "Pirate Ships", "a Pirate Ship");
EnglishCardNames[CardNames.SALVAGER] = new TranslatedCardName("Salvager", "Salvagers", "a Salvager");
EnglishCardNames[CardNames.SEA_HAG] = new TranslatedCardName("Sea Hag", "Sea Hags", "a Sea Hag");
EnglishCardNames[CardNames.SMUGGLERS] = new TranslatedCardName("Smugglers", "Smugglers", "a Smugglers");
EnglishCardNames[CardNames.TACTICIAN] = new TranslatedCardName("Tactician", "Tacticians", "a Tactician");
EnglishCardNames[CardNames.TREASURE_MAP] = new TranslatedCardName("Treasure Map", "Treasure Maps", "a Treasure Map");
EnglishCardNames[CardNames.TREASURY] = new TranslatedCardName("Treasury", "Treasuries", "a Treasury");
EnglishCardNames[CardNames.WAREHOUSE] = new TranslatedCardName("Warehouse", "Warehouses", "a Warehouse");
EnglishCardNames[CardNames.WHARF] = new TranslatedCardName("Wharf", "Wharves", "a Wharf");

EnglishCardNames[CardNames.ALCHEMIST] = new TranslatedCardName("Alchemist", "Alchemists", "an Alchemist");
EnglishCardNames[CardNames.APOTHECARY] = new TranslatedCardName("Apothecary", "Apothecaries", "an Apothecary");
EnglishCardNames[CardNames.APPRENTICE] = new TranslatedCardName("Apprentice", "Apprentices", "an Apprentice");
EnglishCardNames[CardNames.FAMILIAR] = new TranslatedCardName("Familiar", "Familiars", "a Familiar");
EnglishCardNames[CardNames.GOLEM] = new TranslatedCardName("Golem", "Golems", "a Golem");
EnglishCardNames[CardNames.HERBALIST] = new TranslatedCardName("Herbalist", "Herbalists", "a Herbalist");
EnglishCardNames[CardNames.PHILOSOPHERS_STONE] = new TranslatedCardName("Philosopher's Stone", "Philosopher's Stones", "a Philosopher's Stone");
EnglishCardNames[CardNames.POSSESSION] = new TranslatedCardName("Possession", "Possessions", "a Possession");
EnglishCardNames[CardNames.POTION] = new TranslatedCardName("Potion", "Potions", "a Potion");
EnglishCardNames[CardNames.SCRYING_POOL] = new TranslatedCardName("Scrying Pool", "Scrying Pools", "a Scrying Pool");
EnglishCardNames[CardNames.TRANSMUTE] = new TranslatedCardName("Transmute", "Transmutes", "a Transmute");
EnglishCardNames[CardNames.UNIVERSITY] = new TranslatedCardName("University", "Universities", "a University");
EnglishCardNames[CardNames.VINEYARD] = new TranslatedCardName("Vineyard", "Vineyards", "a Vineyard");

EnglishCardNames[CardNames.BANK] = new TranslatedCardName("Bank", "Banks", "a Bank");
EnglishCardNames[CardNames.BISHOP] = new TranslatedCardName("Bishop", "Bishops", "a Bishop");
EnglishCardNames[CardNames.COLONY] = new TranslatedCardName("Colony", "Colonies", "a Colony");
EnglishCardNames[CardNames.CONTRABAND] = new TranslatedCardName("Contraband", "Contrabands", "a Contraband");
EnglishCardNames[CardNames.COUNTING_HOUSE] = new TranslatedCardName("Counting House", "Counting Houses", "a Counting House");
EnglishCardNames[CardNames.CITY] = new TranslatedCardName("City", "Cities", "a City");
EnglishCardNames[CardNames.EXPAND] = new TranslatedCardName("Expand", "Expands", "an Expand");
EnglishCardNames[CardNames.FORGE] = new TranslatedCardName("Forge", "Forges", "a Forge");
EnglishCardNames[CardNames.GRAND_MARKET] = new TranslatedCardName("Grand Market", "Grand Markets", "a Grand Market");
EnglishCardNames[CardNames.GOONS] = new TranslatedCardName("Goons", "Goons", "a Goons");
EnglishCardNames[CardNames.HOARD] = new TranslatedCardName("Hoard", "Hoards", "a Hoard");
EnglishCardNames[CardNames.KINGS_COURT] = new TranslatedCardName("King's Court", "King's Courts", "a King's Court");
EnglishCardNames[CardNames.LOAN] = new TranslatedCardName("Loan", "Loans", "a Loan");
EnglishCardNames[CardNames.MINT] = new TranslatedCardName("Mint", "Mints", "a Mint");
EnglishCardNames[CardNames.MONUMENT] = new TranslatedCardName("Monument", "Monuments", "a Monument");
EnglishCardNames[CardNames.MOUNTEBANK] = new TranslatedCardName("Mountebank", "Mountebanks", "a Mountebank");
EnglishCardNames[CardNames.PEDDLER] = new TranslatedCardName("Peddler", "Peddlers", "a Peddler");
EnglishCardNames[CardNames.PLATINUM] = new TranslatedCardName("Platinum", "Platina", "a Platinum");
EnglishCardNames[CardNames.QUARRY] = new TranslatedCardName("Quarry", "Quarries", "a Quarry");
EnglishCardNames[CardNames.RABBLE] = new TranslatedCardName("Rabble", "Rabbles", "a Rabble");
EnglishCardNames[CardNames.ROYAL_SEAL] = new TranslatedCardName("Royal Seal", "Royal Seals", "a Royal Seal");
EnglishCardNames[CardNames.TALISMAN] = new TranslatedCardName("Talisman", "Talismans", "a Talisman");
EnglishCardNames[CardNames.TRADE_ROUTE] = new TranslatedCardName("Trade Route", "Trade Routes", "a Trade Route");
EnglishCardNames[CardNames.VAULT] = new TranslatedCardName("Vault", "Vaults", "a Vault");
EnglishCardNames[CardNames.VENTURE] = new TranslatedCardName("Venture", "Ventures", "a Venture");
EnglishCardNames[CardNames.WATCHTOWER] = new TranslatedCardName("Watchtower", "Watchtowers", "a Watchtower");
EnglishCardNames[CardNames.WORKERS_VILLAGE] = new TranslatedCardName("Worker's Village", "Worker's Villages", "a Worker's Village");

EnglishCardNames[CardNames.PRIZE_PILE] = new TranslatedCardName("Prize", "Prize", "Prize");
EnglishCardNames[CardNames.BAG_OF_GOLD] = new TranslatedCardName("Bag of Gold", "Bags of Gold", "a Bag of Gold");
EnglishCardNames[CardNames.DIADEM] = new TranslatedCardName("Diadem", "Diadems", "a Diadem");
EnglishCardNames[CardNames.FAIRGROUNDS] = new TranslatedCardName("Fairgrounds", "Fairgrounds", "a Fairgrounds");
EnglishCardNames[CardNames.FARMING_VILLAGE] = new TranslatedCardName("Farming Village", "Farming Villages", "a Farming Village");
EnglishCardNames[CardNames.FOLLOWERS] = new TranslatedCardName("Followers", "Followers", "a Followers");
EnglishCardNames[CardNames.FORTUNE_TELLER] = new TranslatedCardName("Fortune Teller", "Fortune Tellers", "a Fortune Teller");
EnglishCardNames[CardNames.HAMLET] = new TranslatedCardName("Hamlet", "Hamlets", "a Hamlet");
EnglishCardNames[CardNames.HARVEST] = new TranslatedCardName("Harvest", "Harvests", "a Harvest");
EnglishCardNames[CardNames.HORSE_TRADERS] = new TranslatedCardName("Horse Traders", "Horse Traders", "a Horse Traders");
EnglishCardNames[CardNames.HORN_OF_PLENTY] = new TranslatedCardName("Horn of Plenty", "Horns of Plenty", "a Horn of Plenty");
EnglishCardNames[CardNames.HUNTING_PARTY] = new TranslatedCardName("Hunting Party", "Hunting Parties", "a Hunting Party");
EnglishCardNames[CardNames.JESTER] = new TranslatedCardName("Jester", "Jesters", "a Jester");
EnglishCardNames[CardNames.MENAGERIE] = new TranslatedCardName("Menagerie", "Menageries", "a Menagerie");
EnglishCardNames[CardNames.PRINCESS] = new TranslatedCardName("Princess", "Princesses", "a Princess");
EnglishCardNames[CardNames.REMAKE] = new TranslatedCardName("Remake", "Remakes", "a Remake");
EnglishCardNames[CardNames.TOURNAMENT] = new TranslatedCardName("Tournament", "Tournaments", "a Tournament");
EnglishCardNames[CardNames.TRUSTY_STEED] = new TranslatedCardName("Trusty Steed", "Trusty Steeds", "a Trusty Steed");
EnglishCardNames[CardNames.YOUNG_WITCH] = new TranslatedCardName("Young Witch", "Young Witches", "a Young Witch");

EnglishCardNames[CardNames.BORDER_VILLAGE] = new TranslatedCardName("Border Village", "Border Villages", "a Border Village");
EnglishCardNames[CardNames.CACHE] = new TranslatedCardName("Cache", "Caches", "a Cache");
EnglishCardNames[CardNames.CARTOGRAPHER] = new TranslatedCardName("Cartographer", "Cartographers", "a Cartographer");
EnglishCardNames[CardNames.CROSSROADS] = new TranslatedCardName("Crossroads", "Crossroads", "a Crossroads");
EnglishCardNames[CardNames.DEVELOP] = new TranslatedCardName("Develop", "Develops", "a Develop");
EnglishCardNames[CardNames.DUCHESS] = new TranslatedCardName("Duchess", "Duchesses", "a Duchess");
EnglishCardNames[CardNames.EMBASSY] = new TranslatedCardName("Embassy", "Embassies", "an Embassy");
EnglishCardNames[CardNames.FARMLAND] = new TranslatedCardName("Farmland", "Farmlands", "a Farmland");
EnglishCardNames[CardNames.FOOLS_GOLD] = new TranslatedCardName("Fool's Gold", "Fool's Golds", "a Fool's Gold");
EnglishCardNames[CardNames.HAGGLER] = new TranslatedCardName("Haggler", "Hagglers", "a Haggler");
EnglishCardNames[CardNames.HIGHWAY] = new TranslatedCardName("Highway", "Highways", "a Highway");
EnglishCardNames[CardNames.ILL_GOTTEN_GAINS] = new TranslatedCardName("Ill-Gotten Gains", "Ill-Gotten Gains", "an Ill-Gotten Gains");
EnglishCardNames[CardNames.INN] = new TranslatedCardName("Inn", "Inns", "an Inn");
EnglishCardNames[CardNames.JACK_OF_ALL_TRADES] = new TranslatedCardName("Jack of All Trades", "Jacks of All Trades", "a Jack of All Trades");
EnglishCardNames[CardNames.MANDARIN] = new TranslatedCardName("Mandarin", "Mandarins", "a Mandarin");
EnglishCardNames[CardNames.NOBLE_BRIGAND] = new TranslatedCardName("Noble Brigand", "Noble Brigands", "a Noble Brigand");
EnglishCardNames[CardNames.NOMAD_CAMP] = new TranslatedCardName("Nomad Camp", "Nomad Camps", "a Nomad Camp");
EnglishCardNames[CardNames.OASIS] = new TranslatedCardName("Oasis", "Oases", "an Oasis");
EnglishCardNames[CardNames.ORACLE] = new TranslatedCardName("Oracle", "Oracles", "an Oracle");
EnglishCardNames[CardNames.MARGRAVE] = new TranslatedCardName("Margrave", "Margraves", "a Margrave");
EnglishCardNames[CardNames.SCHEME] = new TranslatedCardName("Scheme", "Schemes", "a Scheme");
EnglishCardNames[CardNames.SILK_ROAD] = new TranslatedCardName("Silk Road", "Silk Roads", "a Silk Road");
EnglishCardNames[CardNames.SPICE_MERCHANT] = new TranslatedCardName("Spice Merchant", "Spice Merchants", "a Spice Merchant");
EnglishCardNames[CardNames.STABLES] = new TranslatedCardName("Stables", "Stables", "a Stables");
EnglishCardNames[CardNames.TRADER] = new TranslatedCardName("Trader", "Traders", "a Trader");
EnglishCardNames[CardNames.TUNNEL] = new TranslatedCardName("Tunnel", "Tunnels", "a Tunnel");

EnglishCardNames[CardNames.RUIN_PILE] = new TranslatedCardName("Ruins", "Ruins", "a Ruins");
EnglishCardNames[CardNames.KNIGHTS] = new TranslatedCardName("Knights", "Knights", "a Knights");
EnglishCardNames[CardNames.ABANDONED_MINE] = new TranslatedCardName("Abandoned Mine", "Abandoned Mines", "an Abandoned Mine");
EnglishCardNames[CardNames.ALTAR] = new TranslatedCardName("Altar", "Altars", "an Altar");
EnglishCardNames[CardNames.ARMORY] = new TranslatedCardName("Armory", "Armories", "an Armory");
EnglishCardNames[CardNames.BAND_OF_MISFITS] = new TranslatedCardName("Band of Misfits", "Bands of Misfits", "a Band of Misfits");
EnglishCardNames[CardNames.BANDIT_CAMP] = new TranslatedCardName("Bandit Camp", "Bandit Camps", "a Bandit Camp");
EnglishCardNames[CardNames.BEGGAR] = new TranslatedCardName("Beggar", "Beggars", "a Beggar");
EnglishCardNames[CardNames.CATACOMBS] = new TranslatedCardName("Catacombs", "Catacombs", "a Catacombs");
EnglishCardNames[CardNames.COUNT] = new TranslatedCardName("Count", "Counts", "a Count");
EnglishCardNames[CardNames.COUNTERFEIT] = new TranslatedCardName("Counterfeit", "Counterfeits", "a Counterfeit");
EnglishCardNames[CardNames.CULTIST] = new TranslatedCardName("Cultist", "Cultists", "a Cultist");
EnglishCardNames[CardNames.DAME_ANNA] = new TranslatedCardName("Dame Anna", "Dame Annas", "Dame Anna");
EnglishCardNames[CardNames.DAME_JOSEPHINE] = new TranslatedCardName("Dame Josephine", "Dame Josephines", "Dame Josephine");
EnglishCardNames[CardNames.DAME_MOLLY] = new TranslatedCardName("Dame Molly", "Dame Mollies", "Dame Molly");
EnglishCardNames[CardNames.DAME_NATALIE] = new TranslatedCardName("Dame Natalie", "Dame Natalies", "Dame Natalie");
EnglishCardNames[CardNames.DAME_SYLVIA] = new TranslatedCardName("Dame Sylvia", "Dame Sylvias", "Dame Sylvia");
EnglishCardNames[CardNames.DEATH_CART] = new TranslatedCardName("Death Cart", "Death Carts", "a Death Cart");
EnglishCardNames[CardNames.FEODUM] = new TranslatedCardName("Feodum", "Feoda", "a Feodum");
EnglishCardNames[CardNames.FORAGER] = new TranslatedCardName("Forager", "Foragers", "a Forager");
EnglishCardNames[CardNames.FORTRESS] = new TranslatedCardName("Fortress", "Fortresses", "a Fortress");
EnglishCardNames[CardNames.GRAVEROBBER] = new TranslatedCardName("Graverobber", "Graverobbers", "a Graverobber");
EnglishCardNames[CardNames.HERMIT] = new TranslatedCardName("Hermit", "Hermits", "a Hermit");
EnglishCardNames[CardNames.HOVEL] = new TranslatedCardName("Hovel", "Hovels", "a Hovel");
EnglishCardNames[CardNames.HUNTING_GROUNDS] = new TranslatedCardName("Hunting Grounds", "Hunting Grounds", "a Hunting Grounds");
EnglishCardNames[CardNames.IRONMONGER] = new TranslatedCardName("Ironmonger", "Ironmongers", "an Ironmonger");
EnglishCardNames[CardNames.JUNK_DEALER] = new TranslatedCardName("Junk Dealer", "Junk Dealers", "a Junk Dealer");
EnglishCardNames[CardNames.MADMAN] = new TranslatedCardName("Madman", "Madmen", "a Madman");
EnglishCardNames[CardNames.MARKET_SQUARE] = new TranslatedCardName("Market Square", "Market Squares", "a Market Square");
EnglishCardNames[CardNames.MARAUDER] = new TranslatedCardName("Marauder", "Marauders", "a Marauder");
EnglishCardNames[CardNames.MERCENARY] = new TranslatedCardName("Mercenary", "Mercenaries", "a Mercenary");
EnglishCardNames[CardNames.MYSTIC] = new TranslatedCardName("Mystic", "Mystics", "a Mystic");
EnglishCardNames[CardNames.NECROPOLIS] = new TranslatedCardName("Necropolis", "Necropolis", "a Necropolis");
EnglishCardNames[CardNames.OVERGROWN_ESTATE] = new TranslatedCardName("Overgrown Estate", "Overgrown Estates", "an Overgrown Estate");
EnglishCardNames[CardNames.PILLAGE] = new TranslatedCardName("Pillage", "Pillages", "a Pillage");
EnglishCardNames[CardNames.POOR_HOUSE] = new TranslatedCardName("Poor House", "Poor Houses", "a Poor House");
EnglishCardNames[CardNames.PROCESSION] = new TranslatedCardName("Procession", "Processions", "a Procession");
EnglishCardNames[CardNames.RATS] = new TranslatedCardName("Rats", "Rats", "a Rats");
EnglishCardNames[CardNames.REBUILD] = new TranslatedCardName("Rebuild", "Rebuilds", "a Rebuild");
EnglishCardNames[CardNames.ROGUE] = new TranslatedCardName("Rogue", "Rogues", "a Rogue");
EnglishCardNames[CardNames.RUINED_LIBRARY] = new TranslatedCardName("Ruined Library", "Ruined Libraries", "a Ruined Library");
EnglishCardNames[CardNames.RUINED_MARKET] = new TranslatedCardName("Ruined Market", "Ruined Markets", "a Ruined Market");
EnglishCardNames[CardNames.RUINED_VILLAGE] = new TranslatedCardName("Ruined Village", "Ruined Villages", "a Ruined Village");
EnglishCardNames[CardNames.SAGE] = new TranslatedCardName("Sage", "Sages", "a Sage");
EnglishCardNames[CardNames.SCAVENGER] = new TranslatedCardName("Scavenger", "Scavengers", "a Scavenger");
EnglishCardNames[CardNames.SIR_BAILEY] = new TranslatedCardName("Sir Bailey", "Sir Baileys", "Sir Bailey");
EnglishCardNames[CardNames.SIR_DESTRY] = new TranslatedCardName("Sir Destry", "Sir Destries", "Sir Destry");
EnglishCardNames[CardNames.SIR_MARTIN] = new TranslatedCardName("Sir Martin", "Sir Martins", "Sir Martin");
EnglishCardNames[CardNames.SIR_MICHAEL] = new TranslatedCardName("Sir Michael", "Sir Michaels", "Sir Michael");
EnglishCardNames[CardNames.SIR_VANDER] = new TranslatedCardName("Sir Vander", "Sir Vanders", "Sir Vander");
EnglishCardNames[CardNames.SPOILS] = new TranslatedCardName("Spoils", "Spoils", "a Spoils");
EnglishCardNames[CardNames.STOREROOM] = new TranslatedCardName("Storeroom", "Storerooms", "a Storeroom");
EnglishCardNames[CardNames.SQUIRE] = new TranslatedCardName("Squire", "Squires", "a Squire");
EnglishCardNames[CardNames.SURVIVORS] = new TranslatedCardName("Survivors", "Survivors", "a Survivors");
EnglishCardNames[CardNames.URCHIN] = new TranslatedCardName("Urchin", "Urchins", "an Urchin");
EnglishCardNames[CardNames.VAGRANT] = new TranslatedCardName("Vagrant", "Vagrants", "a Vagrant");
EnglishCardNames[CardNames.WANDERING_MINSTREL] = new TranslatedCardName("Wandering Minstrel", "Wandering Minstrels", "a Wandering Minstrel");

EnglishCardNames[CardNames.ADVISOR] = new TranslatedCardName("Advisor", "Advisors", "an Advisor");
EnglishCardNames[CardNames.BAKER] = new TranslatedCardName("Baker", "Bakers", "a Baker");
EnglishCardNames[CardNames.BUTCHER] = new TranslatedCardName("Butcher", "Butchers", "a Butcher");
EnglishCardNames[CardNames.CANDLESTICK_MAKER] = new TranslatedCardName("Candlestick Maker", "Candlestick Makers", "a Candlestick Maker");
EnglishCardNames[CardNames.DOCTOR] = new TranslatedCardName("Doctor", "Doctors", "a Doctor");
EnglishCardNames[CardNames.HERALD] = new TranslatedCardName("Herald", "Heralds", "a Herald");
EnglishCardNames[CardNames.JOURNEYMAN] = new TranslatedCardName("Journeyman", "Journeymen", "a Journeyman");
EnglishCardNames[CardNames.MASTERPIECE] = new TranslatedCardName("Masterpiece", "Masterpieces", "a Masterpiece");
EnglishCardNames[CardNames.MERCHANT_GUILD] = new TranslatedCardName("Merchant Guild", "Merchant Guilds", "a Merchant Guild");
EnglishCardNames[CardNames.PLAZA] = new TranslatedCardName("Plaza", "Plazas", "a Plaza");
EnglishCardNames[CardNames.TAXMAN] = new TranslatedCardName("Taxman", "Taxmen", "a Taxman");
EnglishCardNames[CardNames.SOOTHSAYER] = new TranslatedCardName("Soothsayer", "Soothsayers", "a Soothsayer");
EnglishCardNames[CardNames.STONEMASON] = new TranslatedCardName("Stonemason", "Stonemasons", "a Stonemason");

EnglishCardNames[CardNames.ALMS] = new TranslatedCardName("Alms", "Alms", "an Alms");
EnglishCardNames[CardNames.AMULET] = new TranslatedCardName("Amulet", "Amulets", "an Amulet");
EnglishCardNames[CardNames.ARTIFICER] = new TranslatedCardName("Artificer", "Artificers", "an Artificer");
EnglishCardNames[CardNames.BALL] = new TranslatedCardName("Ball", "Balls", "a Ball");
EnglishCardNames[CardNames.BONFIRE] = new TranslatedCardName("Bonfire", "Bonfires", "a Bonfire");
EnglishCardNames[CardNames.BORROW] = new TranslatedCardName("Borrow", "Borrows", "a Borrow");
EnglishCardNames[CardNames.BRIDGE_TROLL] = new TranslatedCardName("Bridge Troll", "Bridge Trolls", "a Bridge Troll");
EnglishCardNames[CardNames.CARAVAN_GUARD] = new TranslatedCardName("Caravan Guard", "Caravan Guards", "a Caravan Guard");
EnglishCardNames[CardNames.CHAMPION] = new TranslatedCardName("Champion", "Champions", "a Champion");
EnglishCardNames[CardNames.COIN_OF_THE_REALM] = new TranslatedCardName("Coin of the Realm", "Coins of the Realm", "a Coin of the Realm");
EnglishCardNames[CardNames.DISCIPLE] = new TranslatedCardName("Disciple", "Disciples", "a Disciple");
EnglishCardNames[CardNames.DISTANT_LANDS] = new TranslatedCardName("Distant Lands", "Distant Lands", "a Distant Lands");
EnglishCardNames[CardNames.DUNGEON] = new TranslatedCardName("Dungeon", "Dungeons", "a Dungeon");
EnglishCardNames[CardNames.DUPLICATE] = new TranslatedCardName("Duplicate", "Duplicates", "a Duplicate");
EnglishCardNames[CardNames.EXPEDITION] = new TranslatedCardName("Expedition", "Expeditions", "an Expedition");
EnglishCardNames[CardNames.FERRY] = new TranslatedCardName("Ferry", "Ferries", "a Ferry");
EnglishCardNames[CardNames.FUGITIVE] = new TranslatedCardName("Fugitive", "Fugitives", "a Fugitive");
EnglishCardNames[CardNames.GEAR] = new TranslatedCardName("Gear", "Gears", "a Gear");
EnglishCardNames[CardNames.GIANT] = new TranslatedCardName("Giant", "Giants", "a Giant");
EnglishCardNames[CardNames.GUIDE] = new TranslatedCardName("Guide", "Guides", "a Guide");
EnglishCardNames[CardNames.HAUNTED_WOODS] = new TranslatedCardName("Haunted Woods", "Haunted Woods", "a Haunted Woods");
EnglishCardNames[CardNames.HERO] = new TranslatedCardName("Hero", "Heroes", "a Hero");
EnglishCardNames[CardNames.HIRELING] = new TranslatedCardName("Hireling", "Hirelings", "a Hireling");
EnglishCardNames[CardNames.INHERITANCE] = new TranslatedCardName("Inheritance", "Inheritances", "an Inheritance");
EnglishCardNames[CardNames.LOST_ARTS] = new TranslatedCardName("Lost Arts", "Lost Arts", "a Lost Arts");
EnglishCardNames[CardNames.LOST_CITY] = new TranslatedCardName("Lost City", "Lost Cities", "a Lost City");
EnglishCardNames[CardNames.MAGPIE] = new TranslatedCardName("Magpie", "Magpies", "a Magpie");
EnglishCardNames[CardNames.MESSENGER] = new TranslatedCardName("Messenger", "Messengers", "a Messenger");
EnglishCardNames[CardNames.MISER] = new TranslatedCardName("Miser", "Misers", "a Miser");
EnglishCardNames[CardNames.MISSION] = new TranslatedCardName("Mission", "Missions", "a Mission");
EnglishCardNames[CardNames.PATHFINDING] = new TranslatedCardName("Pathfinding", "Pathfindings", "a Pathfinding");
EnglishCardNames[CardNames.PAGE] = new TranslatedCardName("Page", "Pages", "a Page");
EnglishCardNames[CardNames.PEASANT] = new TranslatedCardName("Peasant", "Peasants", "a Peasant");
EnglishCardNames[CardNames.PILGRIMAGE] = new TranslatedCardName("Pilgrimage", "Pilgrimages", "a Pilgrimage");
EnglishCardNames[CardNames.PLAN] = new TranslatedCardName("Plan", "Plans", "a Plan");
EnglishCardNames[CardNames.PORT] = new TranslatedCardName("Port", "Ports", "a Port");
EnglishCardNames[CardNames.QUEST] = new TranslatedCardName("Quest", "Quests", "a Quest");
EnglishCardNames[CardNames.RANGER] = new TranslatedCardName("Ranger", "Rangers", "a Ranger");
EnglishCardNames[CardNames.RAID] = new TranslatedCardName("Raid", "Raids", "a Raid");
EnglishCardNames[CardNames.RATCATCHER] = new TranslatedCardName("Ratcatcher", "Ratcatchers", "a Ratcatcher");
EnglishCardNames[CardNames.RAZE] = new TranslatedCardName("Raze", "Razes", "a Raze");
EnglishCardNames[CardNames.RELIC] = new TranslatedCardName("Relic", "Relics", "a Relic");
EnglishCardNames[CardNames.ROYAL_CARRIAGE] = new TranslatedCardName("Royal Carriage", "Royal Carriages", "a Royal Carriage");
EnglishCardNames[CardNames.SAVE] = new TranslatedCardName("Save", "Saves", "a Save");
EnglishCardNames[CardNames.SCOUTING_PARTY] = new TranslatedCardName("Scouting Party", "Scouting Parties", "a Scouting Party");
EnglishCardNames[CardNames.SEAWAY] = new TranslatedCardName("Seaway", "Seaways", "a Seaway");
EnglishCardNames[CardNames.SOLDIER] = new TranslatedCardName("Soldier", "Soldiers", "a Soldier");
EnglishCardNames[CardNames.STORYTELLER] = new TranslatedCardName("Storyteller", "Storytellers", "a Storyteller");
EnglishCardNames[CardNames.SWAMP_HAG] = new TranslatedCardName("Swamp Hag", "Swamp Hags", "a Swamp Hag");
EnglishCardNames[CardNames.TEACHER] = new TranslatedCardName("Teacher", "Teachers", "a Teacher");
EnglishCardNames[CardNames.TRAVELLING_FAIR] = new TranslatedCardName("Travelling Fair", "Travelling Fairs", "a Travelling Fair");
EnglishCardNames[CardNames.TRADE] = new TranslatedCardName("Trade", "Trades", "a Trade");
EnglishCardNames[CardNames.TRAINING] = new TranslatedCardName("Training", "Trainings", "a Training");
EnglishCardNames[CardNames.TRANSMOGRIFY] = new TranslatedCardName("Transmogrify", "Transmogrifies", "a Transmogrify");
EnglishCardNames[CardNames.TREASURE_TROVE] = new TranslatedCardName("Treasure Trove", "Treasure Troves", "a Treasure Trove");
EnglishCardNames[CardNames.TREASURE_HUNTER] = new TranslatedCardName("Treasure Hunter", "Treasure Hunters", "a Treasure Hunter");
EnglishCardNames[CardNames.WARRIOR] = new TranslatedCardName("Warrior", "Warriors", "a Warrior");
EnglishCardNames[CardNames.WINE_MERCHANT] = new TranslatedCardName("Wine Merchant", "Wine Merchants", "a Wine Merchant");

EnglishCardNames[CardNames.ENCAMPMENT] = new TranslatedCardName("Encampment", "Encampments", "an Encampment");
EnglishCardNames[CardNames.PLUNDER] = new TranslatedCardName("Plunder", "Plunders", "a Plunder");
EnglishCardNames[CardNames.PATRICIAN] = new TranslatedCardName("Patrician", "Patricians", "a Patrician");
EnglishCardNames[CardNames.EMPORIUM] = new TranslatedCardName("Emporium", "Emporia", "an Emporium");
EnglishCardNames[CardNames.SETTLERS] = new TranslatedCardName("Settlers", "Settlers", "a Settlers");
EnglishCardNames[CardNames.BUSTLING_VILLAGE] = new TranslatedCardName("Bustling Village", "Bustling Villages", "a Bustling Village");
EnglishCardNames[CardNames.CATAPULT] = new TranslatedCardName("Catapult", "Catapults", "a Catapult");
EnglishCardNames[CardNames.ROCKS] = new TranslatedCardName("Rocks", "Rocks", "a Rocks");
EnglishCardNames[CardNames.GLADIATOR] = new TranslatedCardName("Gladiator", "Gladiators", "a Gladiator");
EnglishCardNames[CardNames.FORTUNE] = new TranslatedCardName("Fortune", "Fortunes", "a Fortune");
EnglishCardNames[CardNames.CASTLES] = new TranslatedCardName("Castles", "Castles", "a Castles");
EnglishCardNames[CardNames.HUMBLE_CASTLE] = new TranslatedCardName("Humble Castle", "Humble Castles", "a Humble Castle");
EnglishCardNames[CardNames.CRUMBLING_CASTLE] = new TranslatedCardName("Crumbling Castle", "Crumbling Castles", "a Crumbling Castle");
EnglishCardNames[CardNames.SMALL_CASTLE] = new TranslatedCardName("Small Castle", "Small Castles", "a Small Castle");
EnglishCardNames[CardNames.HAUNTED_CASTLE] = new TranslatedCardName("Haunted Castle", "Haunted Castles", "a Haunted Castle");
EnglishCardNames[CardNames.OPULENT_CASTLE] = new TranslatedCardName("Opulent Castle", "Opulent Castles", "an Opulent Castle");
EnglishCardNames[CardNames.SPRAWLING_CASTLE] = new TranslatedCardName("Sprawling Castle", "Sprawling Castles", "a Sprawling Castle");
EnglishCardNames[CardNames.GRAND_CASTLE] = new TranslatedCardName("Grand Castle", "Grand Castles", "a Grand Castle");
EnglishCardNames[CardNames.KINGS_CASTLE] = new TranslatedCardName("King's Castle", "King's Castles", "a King's Castle");
EnglishCardNames[CardNames.ADVANCE] = new TranslatedCardName("Advance", "Advances", "an Advance");
EnglishCardNames[CardNames.ANNEX] = new TranslatedCardName("Annex", "Annexes", "an Annex");
EnglishCardNames[CardNames.ARCHIVE] = new TranslatedCardName("Archive", "Archives", "an Archive");
EnglishCardNames[CardNames.AQUEDUCT] = new TranslatedCardName("Aqueduct", "Aqueducts", "an Aqueduct");
EnglishCardNames[CardNames.ARENA] = new TranslatedCardName("Arena", "Arenas", "an Arena");
EnglishCardNames[CardNames.BANDIT_FORT] = new TranslatedCardName("Bandit Fort", "Bandit Forts", "a Bandit Fort");
EnglishCardNames[CardNames.BANQUET] = new TranslatedCardName("Banquet", "Banquets", "a Banquet");
EnglishCardNames[CardNames.BASILICA] = new TranslatedCardName("Basilica", "Basilicas", "a Basilica");
EnglishCardNames[CardNames.BATHS] = new TranslatedCardName("Baths", "Baths", "a Baths");
EnglishCardNames[CardNames.BATTLEFIELD] = new TranslatedCardName("Battlefield", "Battlefields", "a Battlefield");
EnglishCardNames[CardNames.CAPITAL] = new TranslatedCardName("Capital", "Capitals", "a Capital");
EnglishCardNames[CardNames.CHARM] = new TranslatedCardName("Charm", "Charms", "a Charm");
EnglishCardNames[CardNames.CHARIOT_RACE] = new TranslatedCardName("Chariot Race", "Chariot Races", "a Chariot Race");
EnglishCardNames[CardNames.CITY_QUARTER] = new TranslatedCardName("City Quarter", "City Quarters", "a City Quarter");
EnglishCardNames[CardNames.COLONNADE] = new TranslatedCardName("Colonnade", "Colonnades", "a Colonnade");
EnglishCardNames[CardNames.CONQUEST] = new TranslatedCardName("Conquest", "Conquests", "a Conquest");
EnglishCardNames[CardNames.CROWN] = new TranslatedCardName("Crown", "Crowns", "a Crown");
EnglishCardNames[CardNames.DELVE] = new TranslatedCardName("Delve", "Delves", "a Delve");
EnglishCardNames[CardNames.DEFILED_SHRINE] = new TranslatedCardName("Defiled Shrine", "Defiled Shrines", "a Defiled Shrine");
EnglishCardNames[CardNames.DOMINATE] = new TranslatedCardName("Dominate", "Dominates", "a Dominate");
EnglishCardNames[CardNames.DONATE] = new TranslatedCardName("Donate", "Donates", "a Donate");
EnglishCardNames[CardNames.ENCHANTRESS] = new TranslatedCardName("Enchantress", "Enchantresses", "an Enchantress");
EnglishCardNames[CardNames.ENGINEER] = new TranslatedCardName("Engineer", "Engineers", "an Engineer");
EnglishCardNames[CardNames.FARMERS_MARKET] = new TranslatedCardName("Farmers' Market", "Farmers' Markets", "a Farmers' Market");
EnglishCardNames[CardNames.FORUM] = new TranslatedCardName("Forum", "Forums", "a Forum");
EnglishCardNames[CardNames.FOUNTAIN] = new TranslatedCardName("Fountain", "Fountains", "a Fountain");
EnglishCardNames[CardNames.GROUNDSKEEPER] = new TranslatedCardName("Groundskeeper", "Groundskeepers", "a Groundskeeper");
EnglishCardNames[CardNames.KEEP] = new TranslatedCardName("Keep", "Keeps", "a Keep");
EnglishCardNames[CardNames.LABYRINTH] = new TranslatedCardName("Labyrinth", "Labyrinths", "a Labyrinth");
EnglishCardNames[CardNames.LEGIONARY] = new TranslatedCardName("Legionary", "Legionaries", "a Legionary");
EnglishCardNames[CardNames.MOUNTAIN_PASS] = new TranslatedCardName("Mountain Pass", "Mountain Passes", "a Mountain Pass");
EnglishCardNames[CardNames.MUSEUM] = new TranslatedCardName("Museum", "Museums", "a Museum");
EnglishCardNames[CardNames.OBELISK] = new TranslatedCardName("Obelisk", "Obelisks", "an Obelisk");
EnglishCardNames[CardNames.ORCHARD] = new TranslatedCardName("Orchard", "Orchards", "an Orchard");
EnglishCardNames[CardNames.OVERLORD] = new TranslatedCardName("Overlord", "Overlords", "an Overlord");
EnglishCardNames[CardNames.PALACE] = new TranslatedCardName("Palace", "Palaces", "a Palace");
EnglishCardNames[CardNames.RITUAL] = new TranslatedCardName("Ritual", "Rituals", "a Ritual");
EnglishCardNames[CardNames.ROYAL_BLACKSMITH] = new TranslatedCardName("Royal Blacksmith", "Royal Blacksmiths", "a Royal Blacksmith");
EnglishCardNames[CardNames.SACRIFICE] = new TranslatedCardName("Sacrifice", "Sacrifices", "a Sacrifice");
EnglishCardNames[CardNames.SALT_THE_EARTH] = new TranslatedCardName("Salt the Earth", "Salt the Earths", "a Salt the Earth");
EnglishCardNames[CardNames.TAX] = new TranslatedCardName("Tax", "Taxes", "a Tax");
EnglishCardNames[CardNames.TEMPLE] = new TranslatedCardName("Temple", "Temples", "a Temple");
EnglishCardNames[CardNames.TOMB] = new TranslatedCardName("Tomb", "Tombs", "a Tomb");
EnglishCardNames[CardNames.TOWER] = new TranslatedCardName("Tower", "Towers", "a Tower");
EnglishCardNames[CardNames.TRIUMPH] = new TranslatedCardName("Triumph", "Triumphs", "a Triumph");
EnglishCardNames[CardNames.TRIUMPHAL_ARCH] = new TranslatedCardName("Triumphal Arch", "Triumphal Arches", "a Triumphal Arch");
EnglishCardNames[CardNames.VILLA] = new TranslatedCardName("Villa", "Villas", "a Villa");
EnglishCardNames[CardNames.WALL] = new TranslatedCardName("Wall", "Walls", "a Wall");
EnglishCardNames[CardNames.WOLF_DEN] = new TranslatedCardName("Wolf Den", "Wolf Dens", "a Wolf Den");
EnglishCardNames[CardNames.WEDDING] = new TranslatedCardName("Wedding", "Weddings", "a Wedding");
EnglishCardNames[CardNames.WILD_HUNT] = new TranslatedCardName("Wild Hunt", "Wild Hunts", "a Wild Hunt");
EnglishCardNames[CardNames.WINDFALL] = new TranslatedCardName("Windfall", "Windfalls", "a Windfall");

EnglishCardNames[CardNames.BOON_DRAWPILE] = new TranslatedCardName("Boon Drawpile", "Boon Drawpiles", "a Boon Drawpile");
EnglishCardNames[CardNames.BOON_DISCARDPILE] = new TranslatedCardName("Boon Discardpile", "Boon Discardpiles", "a Boon Discardpile");
EnglishCardNames[CardNames.THE_EARTHS_GIFT] = new TranslatedCardName("The Earth's Gift", "The Earth's Gifts", "The Earth's Gift");
EnglishCardNames[CardNames.THE_FIELDS_GIFT] = new TranslatedCardName("The Field's Gift", "The Field's Gifts", "The Field's Gift");
EnglishCardNames[CardNames.THE_FLAMES_GIFT] = new TranslatedCardName("The Flame's Gift", "The Flame's Gifts", "The Flame's Gift");
EnglishCardNames[CardNames.THE_FORESTS_GIFT] = new TranslatedCardName("The Forest's Gift", "The Forest's Gifts", "The Forest's Gift");
EnglishCardNames[CardNames.THE_MOONS_GIFT] = new TranslatedCardName("The Moon's Gift", "The Moon's Gifts", "The Moon's Gift");
EnglishCardNames[CardNames.THE_MOUNTAINS_GIFT] = new TranslatedCardName("The Mountain's Gift", "The Mountain's Gifts", "The Mountain's Gift");
EnglishCardNames[CardNames.THE_RIVERS_GIFT] = new TranslatedCardName("The River's Gift", "The River's Gifts", "The River's Gift");
EnglishCardNames[CardNames.THE_SEAS_GIFT] = new TranslatedCardName("The Sea's Gift", "The Sea's Gifts", "The Sea's Gift");
EnglishCardNames[CardNames.THE_SKYS_GIFT] = new TranslatedCardName("The Sky's Gift", "The Sky's Gifts", "The Sky's Gift");
EnglishCardNames[CardNames.THE_SUNS_GIFT] = new TranslatedCardName("The Sun's Gift", "The Sun's Gifts", "The Sun's Gift");
EnglishCardNames[CardNames.THE_SWAMPS_GIFT] = new TranslatedCardName("The Swamp's Gift", "The Swamp's Gifts", "The Swamp's Gift");
EnglishCardNames[CardNames.THE_WINDS_GIFT] = new TranslatedCardName("The Wind's Gift", "The Wind's Gifts", "The Wind's Gift");
EnglishCardNames[CardNames.HEX_DRAWPILE] = new TranslatedCardName("Hex Drawpile", "Hex Drawpiles", "a Hex Drawpile");
EnglishCardNames[CardNames.HEX_DISCARDPILE] = new TranslatedCardName("Hex Discardpile", "Hex Discardpiles", "a Hex Discardpile");
EnglishCardNames[CardNames.BAD_OMENS] = new TranslatedCardName("Bad Omens", "Bad Omens", "Bad Omens");
EnglishCardNames[CardNames.DELUSION] = new TranslatedCardName("Delusion", "Delusions", "Delusion");
EnglishCardNames[CardNames.ENVY] = new TranslatedCardName("Envy", "Envies", "Envy");
EnglishCardNames[CardNames.FAMINE] = new TranslatedCardName("Famine", "Famines", "Famine");
EnglishCardNames[CardNames.FEAR] = new TranslatedCardName("Fear", "Fears", "Fear");
EnglishCardNames[CardNames.GREED] = new TranslatedCardName("Greed", "Greeds", "Greed");
EnglishCardNames[CardNames.HAUNTING] = new TranslatedCardName("Haunting", "Hauntings", "Haunting");
EnglishCardNames[CardNames.LOCUSTS] = new TranslatedCardName("Locusts", "Locusts", "Locusts");
EnglishCardNames[CardNames.MISERY] = new TranslatedCardName("Misery", "Miseries", "Misery");
EnglishCardNames[CardNames.PLAGUE] = new TranslatedCardName("Plague", "Plagues", "Plague");
EnglishCardNames[CardNames.POVERTY] = new TranslatedCardName("Poverty", "Poverties", "Poverty");
EnglishCardNames[CardNames.WAR] = new TranslatedCardName("War", "Wars", "War");
EnglishCardNames[CardNames.MISERABLE] = new TranslatedCardName("Miserable", "Miserables", "Miserable");
EnglishCardNames[CardNames.TWICE_MISERABLE] = new TranslatedCardName("Twice Miserable", "Twice Miserables", "Twice Miserable");
EnglishCardNames[CardNames.ENVIOUS] = new TranslatedCardName("Envious", "Envious", "Envious");
EnglishCardNames[CardNames.DELUDED] = new TranslatedCardName("Deluded", "Deludeds", "Deluded");
EnglishCardNames[CardNames.LOST_IN_THE_WOODS] = new TranslatedCardName("Lost In The Woods", "Lost In The Woods", "Lost In The Woods");
EnglishCardNames[CardNames.BARD] = new TranslatedCardName("Bard", "Bards", "a Bard");
EnglishCardNames[CardNames.BLESSED_VILLAGE] = new TranslatedCardName("Blessed Village", "Blessed Villages", "a Blessed Village");
EnglishCardNames[CardNames.CHANGELING] = new TranslatedCardName("Changeling", "Changelings", "a Changeling");
EnglishCardNames[CardNames.CEMETERY] = new TranslatedCardName("Cemetery", "Cemeteries", "a Cemetery");
EnglishCardNames[CardNames.COBBLER] = new TranslatedCardName("Cobbler", "Cobblers", "a Cobbler");
EnglishCardNames[CardNames.CONCLAVE] = new TranslatedCardName("Conclave", "Conclaves", "a Conclave");
EnglishCardNames[CardNames.CRYPT] = new TranslatedCardName("Crypt", "Crypts", "a Crypt");
EnglishCardNames[CardNames.CURSED_VILLAGE] = new TranslatedCardName("Cursed Village", "Cursed Villages", "a Cursed Village");
EnglishCardNames[CardNames.DEN_OF_SIN] = new TranslatedCardName("Den of Sin", "Dens of Sin", "a Den of Sin");
EnglishCardNames[CardNames.DEVILS_WORKSHOP] = new TranslatedCardName("Devil's Workshop", "Devil's Workshops", "a Devil's Workshop");
EnglishCardNames[CardNames.DRUID] = new TranslatedCardName("Druid", "Druids", "a Druid");
EnglishCardNames[CardNames.EXORCIST] = new TranslatedCardName("Exorcist", "Exorcists", "an Exorcist");
EnglishCardNames[CardNames.FAITHFUL_HOUND] = new TranslatedCardName("Faithful Hound", "Faithful Hounds", "a Faithful Hound");
EnglishCardNames[CardNames.FOOL] = new TranslatedCardName("Fool", "Fools", "a Fool");
EnglishCardNames[CardNames.GHOST_TOWN] = new TranslatedCardName("Ghost Town", "Ghost Towns", "a Ghost Town");
EnglishCardNames[CardNames.GUARDIAN] = new TranslatedCardName("Guardian", "Guardians", "a Guardian");
EnglishCardNames[CardNames.IDOL] = new TranslatedCardName("Idol", "Idols", "an Idol");
EnglishCardNames[CardNames.LEPRECHAUN] = new TranslatedCardName("Leprechaun", "Leprechauns", "a Leprechaun");
EnglishCardNames[CardNames.MONASTERY] = new TranslatedCardName("Monastery", "Monasteries", "a Monastery");
EnglishCardNames[CardNames.NECROMANCER] = new TranslatedCardName("Necromancer", "Necromancers", "a Necromancer");
EnglishCardNames[CardNames.NIGHT_WATCHMAN] = new TranslatedCardName("Night Watchman", "Night Watchmen", "a Night Watchman");
EnglishCardNames[CardNames.PIXIE] = new TranslatedCardName("Pixie", "Pixies", "a Pixie");
EnglishCardNames[CardNames.POOKA] = new TranslatedCardName("Pooka", "Pookas", "a Pooka");
EnglishCardNames[CardNames.RAIDER] = new TranslatedCardName("Raider", "Raiders", "a Raider");
EnglishCardNames[CardNames.SACRED_GROVE] = new TranslatedCardName("Sacred Grove", "Sacred Groves", "a Sacred Grove");
EnglishCardNames[CardNames.SECRET_CAVE] = new TranslatedCardName("Secret Cave", "Secret Caves", "a Secret Cave");
EnglishCardNames[CardNames.SHEPHERD] = new TranslatedCardName("Shepherd", "Shepherds", "a Shepherd");
EnglishCardNames[CardNames.SKULK] = new TranslatedCardName("Skulk", "Skulks", "a Skulk");
EnglishCardNames[CardNames.TORMENTOR] = new TranslatedCardName("Tormentor", "Tormentors", "a Tormentor");
EnglishCardNames[CardNames.TRAGIC_HERO] = new TranslatedCardName("Tragic Hero", "Tragic Heroes", "a Tragic Hero");
EnglishCardNames[CardNames.TRACKER] = new TranslatedCardName("Tracker", "Trackers", "a Tracker");
EnglishCardNames[CardNames.VAMPIRE] = new TranslatedCardName("Vampire", "Vampires", "a Vampire");
EnglishCardNames[CardNames.WEREWOLF] = new TranslatedCardName("Werewolf", "Werewolves", "a Werewolf");
EnglishCardNames[CardNames.CURSED_GOLD] = new TranslatedCardName("Cursed Gold", "Cursed Golds", "a Cursed Gold");
EnglishCardNames[CardNames.GOAT] = new TranslatedCardName("Goat", "Goats", "a Goat");
EnglishCardNames[CardNames.HAUNTED_MIRROR] = new TranslatedCardName("Haunted Mirror", "Haunted Mirrors", "a Haunted Mirror");
EnglishCardNames[CardNames.LUCKY_COIN] = new TranslatedCardName("Lucky Coin", "Lucky Coins", "a Lucky Coin");
EnglishCardNames[CardNames.MAGIC_LAMP] = new TranslatedCardName("Magic Lamp", "Magic Lamps", "a Magic Lamp");
EnglishCardNames[CardNames.PASTURE] = new TranslatedCardName("Pasture", "Pastures", "a Pasture");
EnglishCardNames[CardNames.POUCH] = new TranslatedCardName("Pouch", "Pouches", "a Pouch");
EnglishCardNames[CardNames.BAT] = new TranslatedCardName("Bat", "Bats", "a Bat");
EnglishCardNames[CardNames.GHOST] = new TranslatedCardName("Ghost", "Ghosts", "a Ghost");
EnglishCardNames[CardNames.IMP] = new TranslatedCardName("Imp", "Imps", "an Imp");
EnglishCardNames[CardNames.WILL_O_WISP] = new TranslatedCardName("Will-o'-Wisp", "Will-o'-Wisps", "a Will-o'-Wisp");
EnglishCardNames[CardNames.WISH] = new TranslatedCardName("Wish", "Wishes", "a Wish");
EnglishCardNames[CardNames.ZOMBIE_APPRENTICE] = new TranslatedCardName("Zombie Apprentice", "Zombie Apprentices", "a Zombie Apprentice");
EnglishCardNames[CardNames.ZOMBIE_MASON] = new TranslatedCardName("Zombie Mason", "Zombie Masons", "a Zombie Mason");
EnglishCardNames[CardNames.ZOMBIE_SPY] = new TranslatedCardName("Zombie Spy", "Zombie Spies", "a Zombie Spy");

EnglishCardNames[CardNames.ACTING_TROUPE] = new TranslatedCardName("Acting Troupe", "Acting Troupes", "an Acting Troupe");
EnglishCardNames[CardNames.BORDER_GUARD] = new TranslatedCardName("Border Guard", "Border Guards", "a Border Guard");
EnglishCardNames[CardNames.CARGO_SHIP] = new TranslatedCardName("Cargo Ship", "Cargo Ships", "a Cargo Ship");
EnglishCardNames[CardNames.DUCAT] = new TranslatedCardName("Ducat", "Ducats", "a Ducat");
EnglishCardNames[CardNames.EXPERIMENT] = new TranslatedCardName("Experiment", "Experiments", "an Experiment");
EnglishCardNames[CardNames.FLAG_BEARER] = new TranslatedCardName("Flag Bearer", "Flag Bearers", "a Flag Bearer");
EnglishCardNames[CardNames.HIDEOUT] = new TranslatedCardName("Hideout", "Hideouts", "a Hideout");
EnglishCardNames[CardNames.INVENTOR] = new TranslatedCardName("Inventor", "Inventors", "an Inventor");
EnglishCardNames[CardNames.IMPROVE] = new TranslatedCardName("Improve", "Improves", "an Improve");
EnglishCardNames[CardNames.LACKEYS] = new TranslatedCardName("Lackeys", "Lackeys", "a Lackeys");
EnglishCardNames[CardNames.MOUNTAIN_VILLAGE] = new TranslatedCardName("Mountain Village", "Mountain Villages", "a Mountain Village");
EnglishCardNames[CardNames.PATRON] = new TranslatedCardName("Patron", "Patrons", "a Patron");
EnglishCardNames[CardNames.PRIEST] = new TranslatedCardName("Priest", "Priests", "a Priest");
EnglishCardNames[CardNames.RESEARCH] = new TranslatedCardName("Research", "Researches", "a Research");
EnglishCardNames[CardNames.SILK_MERCHANT] = new TranslatedCardName("Silk Merchant", "Silk Merchants", "a Silk Merchant");
EnglishCardNames[CardNames.OLD_WITCH] = new TranslatedCardName("Old Witch", "Old Witches", "an Old Witch");
EnglishCardNames[CardNames.RECRUITER] = new TranslatedCardName("Recruiter", "Recruiters", "a Recruiter");
EnglishCardNames[CardNames.SCEPTER] = new TranslatedCardName("Scepter", "Scepters", "a Scepter");
EnglishCardNames[CardNames.SCHOLAR] = new TranslatedCardName("Scholar", "Scholars", "a Scholar");
EnglishCardNames[CardNames.SCULPTOR] = new TranslatedCardName("Sculptor", "Sculptors", "a Sculptor");
EnglishCardNames[CardNames.SEER] = new TranslatedCardName("Seer", "Seers", "a Seer");
EnglishCardNames[CardNames.SPICES] = new TranslatedCardName("Spices", "Spices", "a Spices");
EnglishCardNames[CardNames.SWASHBUCKLER] = new TranslatedCardName("Swashbuckler", "Swashbucklers", "a Swashbuckler");
EnglishCardNames[CardNames.TREASURER] = new TranslatedCardName("Treasurer", "Treasurers", "a Treasurer");
EnglishCardNames[CardNames.VILLAIN] = new TranslatedCardName("Villain", "Villains", "a Villain");
EnglishCardNames[CardNames.FLAG] = new TranslatedCardName("Flag", "Flags", "the Flag");
EnglishCardNames[CardNames.HORN] = new TranslatedCardName("Horn", "Horns", "the Horn");
EnglishCardNames[CardNames.KEY] = new TranslatedCardName("Key", "Keys", "the Key");
EnglishCardNames[CardNames.LANTERN] = new TranslatedCardName("Lantern", "Lanterns", "the Lantern");
EnglishCardNames[CardNames.TREASURE_CHEST] = new TranslatedCardName("Treasure Chest", "Treasure Chests", "the Treasure Chest");
EnglishCardNames[CardNames.ACADEMY] = new TranslatedCardName("Academy");
EnglishCardNames[CardNames.BARRACKS] = new TranslatedCardName("Barracks");
EnglishCardNames[CardNames.CANAL] = new TranslatedCardName("Canal");
EnglishCardNames[CardNames.CAPITALISM] = new TranslatedCardName("Capitalism");
EnglishCardNames[CardNames.CATHEDRAL] = new TranslatedCardName("Cathedral");
EnglishCardNames[CardNames.CITADEL] = new TranslatedCardName("Citadel");
EnglishCardNames[CardNames.CITY_GATE] = new TranslatedCardName("City Gate");
EnglishCardNames[CardNames.CROP_ROTATION] = new TranslatedCardName("Crop Rotation");
EnglishCardNames[CardNames.EXPLORATION] = new TranslatedCardName("Exploration");
EnglishCardNames[CardNames.FAIR] = new TranslatedCardName("Fair");
EnglishCardNames[CardNames.FLEET] = new TranslatedCardName("Fleet");
EnglishCardNames[CardNames.GUILDHALL] = new TranslatedCardName("Guildhall");
EnglishCardNames[CardNames.INNOVATION] = new TranslatedCardName("Innovation");
EnglishCardNames[CardNames.PAGEANT] = new TranslatedCardName("Pageant");
EnglishCardNames[CardNames.PIAZZA] = new TranslatedCardName("Piazza");
EnglishCardNames[CardNames.ROAD_NETWORK] = new TranslatedCardName("Road Network");
EnglishCardNames[CardNames.SEWERS] = new TranslatedCardName("Sewers");
EnglishCardNames[CardNames.SILOS] = new TranslatedCardName("Silos");
EnglishCardNames[CardNames.SINISTER_PLOT] = new TranslatedCardName("Sinister Plot");
EnglishCardNames[CardNames.STAR_CHART] = new TranslatedCardName("Star Chart");

EnglishCardNames[CardNames.AVANTO] = new TranslatedCardName("Avanto", "Avantos", "an Avanto");
EnglishCardNames[CardNames.BLACK_MARKET] = new TranslatedCardName("Black Market", "Black Markets", "a Black Market");
EnglishCardNames[CardNames.ENVOY] = new TranslatedCardName("Envoy", "Envoys", "an Envoy");
EnglishCardNames[CardNames.GOVERNOR] = new TranslatedCardName("Governor", "Governors", "a Governor");
EnglishCardNames[CardNames.PRINCE] = new TranslatedCardName("Prince", "Princes", "a Prince");
EnglishCardNames[CardNames.SAUNA] = new TranslatedCardName("Sauna", "Saunas", "a Sauna");
EnglishCardNames[CardNames.STASH] = new TranslatedCardName("Stash", "Stashes", "a Stash");
EnglishCardNames[CardNames.SUMMON] = new TranslatedCardName("Summon", "Summons", "a Summon");
EnglishCardNames[CardNames.WALLED_VILLAGE] = new TranslatedCardName("Walled Village", "Walled Villages", "a Walled Village");
EnglishCardNames[CardNames.BLACK_MARKET_PILE] = new TranslatedCardName("Black Market Deck", "Black Market Decks", "a Black Market Deck");
EnglishCardNames[CardNames.DISMANTLE] = new TranslatedCardName("Dismantle", "Dismantles", "a Dismantle");
EnglishCardNames[CardNames.CAPTAIN] = new TranslatedCardName("Captain", "Captains", "a Captain");
EnglishCardNames[CardNames.CHURCH] = new TranslatedCardName("Church", "Churches", "a Church");
EnglishCardNames[CardNames.MARCHLAND] = new TranslatedCardName("Marchland", "Marchlands", "a Marchland");

EnglishCardNames[CardNames.BLACK_CAT] = new TranslatedCardName("Black Cat", "Black Cats", "a Black Cat");
EnglishCardNames[CardNames.SLEIGH] = new TranslatedCardName("Sleigh", "Sleighs", "a Sleigh");
EnglishCardNames[CardNames.SUPPLIES] = new TranslatedCardName("Supplies", "Supplies", "a Supplies");
EnglishCardNames[CardNames.CAMEL_TRAIN] = new TranslatedCardName("Camel Train", "Camel Trains", "a Camel Train");
EnglishCardNames[CardNames.GOATHERD] = new TranslatedCardName("Goatherd", "Goatherds", "a Goatherd");
EnglishCardNames[CardNames.SCRAP] = new TranslatedCardName("Scrap", "Scraps", "a Scrap");
EnglishCardNames[CardNames.SHEEPDOG] = new TranslatedCardName("Sheepdog", "Sheepdogs", "a Sheepdog");
EnglishCardNames[CardNames.SNOWY_VILLAGE] = new TranslatedCardName("Snowy Village", "Snowy Villages", "a Snowy Village");
EnglishCardNames[CardNames.STOCKPILE] = new TranslatedCardName("Stockpile", "Stockpiles", "a Stockpile");
EnglishCardNames[CardNames.BOUNTY_HUNTER] = new TranslatedCardName("Bounty Hunter", "Bounty Hunters", "a Bounty Hunter");
EnglishCardNames[CardNames.CARDINAL] = new TranslatedCardName("Cardinal", "Cardinals", "a Cardinal");
EnglishCardNames[CardNames.CAVALRY] = new TranslatedCardName("Cavalry", "Cavalries", "a Cavalry");
EnglishCardNames[CardNames.GROOM] = new TranslatedCardName("Groom", "Grooms", "a Groom");
EnglishCardNames[CardNames.HOSTELRY] = new TranslatedCardName("Hostelry", "Hostelries", "a Hostelry");
EnglishCardNames[CardNames.VILLAGE_GREEN] = new TranslatedCardName("Village Green", "Village Greens", "a Village Green");
EnglishCardNames[CardNames.BARGE] = new TranslatedCardName("Barge", "Barges", "a Barge");
EnglishCardNames[CardNames.COVEN] = new TranslatedCardName("Coven", "Covens", "a Coven");
EnglishCardNames[CardNames.DISPLACE] = new TranslatedCardName("Displace", "Displaces", "a Displace");
EnglishCardNames[CardNames.FALCONER] = new TranslatedCardName("Falconer", "Falconers", "a Falconer");
EnglishCardNames[CardNames.FISHERMAN] = new TranslatedCardName("Fisherman", "Fishermen", "a Fisherman");
EnglishCardNames[CardNames.GATEKEEPER] = new TranslatedCardName("Gatekeeper", "Gatekeepers", "a Gatekeeper");
EnglishCardNames[CardNames.HUNTING_LODGE] = new TranslatedCardName("Hunting Lodge", "Hunting Lodges", "a Hunting Lodge");
EnglishCardNames[CardNames.KILN] = new TranslatedCardName("Kiln", "Kilns", "a Kiln");
EnglishCardNames[CardNames.LIVERY] = new TranslatedCardName("Livery", "Liveries", "a Livery");
EnglishCardNames[CardNames.MASTERMIND] = new TranslatedCardName("Mastermind", "Masterminds", "a Mastermind");
EnglishCardNames[CardNames.PADDOCK] = new TranslatedCardName("Paddock", "Paddocks", "a Paddock");
EnglishCardNames[CardNames.SANCTUARY] = new TranslatedCardName("Sanctuary", "Sanctuaries", "a Sanctuary");
EnglishCardNames[CardNames.DESTRIER] = new TranslatedCardName("Destrier", "Destriers", "a Destrier");
EnglishCardNames[CardNames.WAYFARER] = new TranslatedCardName("Wayfarer", "Wayfarers", "a Wayfarer");
EnglishCardNames[CardNames.ANIMAL_FAIR] = new TranslatedCardName("Animal Fair", "Animal Fairs", "an Animal Fair");
EnglishCardNames[CardNames.HORSE] = new TranslatedCardName("Horse", "Horses", "a Horse");
EnglishCardNames[CardNames.WAY_OF_THE_BUTTERFLY] = new TranslatedCardName("Way of the Butterfly");
EnglishCardNames[CardNames.WAY_OF_THE_CAMEL] = new TranslatedCardName("Way of the Camel");
EnglishCardNames[CardNames.WAY_OF_THE_CHAMELEON] = new TranslatedCardName("Way of the Chameleon");
EnglishCardNames[CardNames.WAY_OF_THE_FROG] = new TranslatedCardName("Way of the Frog");
EnglishCardNames[CardNames.WAY_OF_THE_GOAT] = new TranslatedCardName("Way of the Goat");
EnglishCardNames[CardNames.WAY_OF_THE_HORSE] = new TranslatedCardName("Way of the Horse");
EnglishCardNames[CardNames.WAY_OF_THE_MOLE] = new TranslatedCardName("Way of the Mole");
EnglishCardNames[CardNames.WAY_OF_THE_MONKEY] = new TranslatedCardName("Way of the Monkey");
EnglishCardNames[CardNames.WAY_OF_THE_MOUSE] = new TranslatedCardName("Way of the Mouse");
EnglishCardNames[CardNames.WAY_OF_THE_MULE] = new TranslatedCardName("Way of the Mule");
EnglishCardNames[CardNames.WAY_OF_THE_OTTER] = new TranslatedCardName("Way of the Otter");
EnglishCardNames[CardNames.WAY_OF_THE_OWL] = new TranslatedCardName("Way of the Owl");
EnglishCardNames[CardNames.WAY_OF_THE_OX] = new TranslatedCardName("Way of the Ox");
EnglishCardNames[CardNames.WAY_OF_THE_PIG] = new TranslatedCardName("Way of the Pig");
EnglishCardNames[CardNames.WAY_OF_THE_RAT] = new TranslatedCardName("Way of the Rat");
EnglishCardNames[CardNames.WAY_OF_THE_SEAL] = new TranslatedCardName("Way of the Seal");
EnglishCardNames[CardNames.WAY_OF_THE_SHEEP] = new TranslatedCardName("Way of the Sheep");
EnglishCardNames[CardNames.WAY_OF_THE_SQUIRREL] = new TranslatedCardName("Way of the Squirrel");
EnglishCardNames[CardNames.WAY_OF_THE_TURTLE] = new TranslatedCardName("Way of the Turtle");
EnglishCardNames[CardNames.WAY_OF_THE_WORM] = new TranslatedCardName("Way of the Worm");
EnglishCardNames[CardNames.DELAY] = new TranslatedCardName("Delay");
EnglishCardNames[CardNames.DESPERATION] = new TranslatedCardName("Desperation");
EnglishCardNames[CardNames.GAMBLE] = new TranslatedCardName("Gamble");
EnglishCardNames[CardNames.PURSUE] = new TranslatedCardName("Pursue");
EnglishCardNames[CardNames.RIDE] = new TranslatedCardName("Ride");
EnglishCardNames[CardNames.TOIL] = new TranslatedCardName("Toil");
EnglishCardNames[CardNames.ENHANCE] = new TranslatedCardName("Enhance");
EnglishCardNames[CardNames.MARCH] = new TranslatedCardName("March");
EnglishCardNames[CardNames.TRANSPORT] = new TranslatedCardName("Transport");
EnglishCardNames[CardNames.BANISH] = new TranslatedCardName("Banish");
EnglishCardNames[CardNames.BARGAIN] = new TranslatedCardName("Bargain");
EnglishCardNames[CardNames.INVEST] = new TranslatedCardName("Invest");
EnglishCardNames[CardNames.SEIZE_THE_DAY] = new TranslatedCardName("Seize The Day");
EnglishCardNames[CardNames.COMMERCE] = new TranslatedCardName("Commerce");
EnglishCardNames[CardNames.DEMAND] = new TranslatedCardName("Demand");
EnglishCardNames[CardNames.STAMPEDE] = new TranslatedCardName("Stampede");
EnglishCardNames[CardNames.REAP] = new TranslatedCardName("Reap");
EnglishCardNames[CardNames.ENCLAVE] = new TranslatedCardName("Enclave");
EnglishCardNames[CardNames.ALLIANCE] = new TranslatedCardName("Alliance");
EnglishCardNames[CardNames.POPULATE] = new TranslatedCardName("Populate");

EnglishCardNames[CardNames.CARD_OF_THE_BUTTERFLY] = "";
EnglishCardNames[CardNames.CARD_OF_THE_CAMEL] = "";
EnglishCardNames[CardNames.CARD_OF_THE_CHAMELEON] = "";
EnglishCardNames[CardNames.CARD_OF_THE_FROG] = "";
EnglishCardNames[CardNames.CARD_OF_THE_GOAT] = "";
EnglishCardNames[CardNames.CARD_OF_THE_HORSE] = "";
EnglishCardNames[CardNames.CARD_OF_THE_MOLE] = "";
EnglishCardNames[CardNames.CARD_OF_THE_MONKEY] = "";
EnglishCardNames[CardNames.CARD_OF_THE_MOUSE] = "";
EnglishCardNames[CardNames.CARD_OF_THE_MULE] = "";
EnglishCardNames[CardNames.CARD_OF_THE_OTTER] = "";
EnglishCardNames[CardNames.CARD_OF_THE_OWL] = "";
EnglishCardNames[CardNames.CARD_OF_THE_OX] = "";
EnglishCardNames[CardNames.CARD_OF_THE_PIG] = "";
EnglishCardNames[CardNames.CARD_OF_THE_RAT] = "";
EnglishCardNames[CardNames.CARD_OF_THE_SEAL] = "";
EnglishCardNames[CardNames.CARD_OF_THE_SHEEP] = "";
EnglishCardNames[CardNames.CARD_OF_THE_SQUIRREL] = "";
EnglishCardNames[CardNames.CARD_OF_THE_TURTLE] = "";
EnglishCardNames[CardNames.CARD_OF_THE_WORM] = "";

// new Seaside
EnglishCardNames[CardNames.MONKEY] = new TranslatedCardName("Monkey", "Monkeys", "a Monkey");
EnglishCardNames[CardNames.BLOCKADE] = new TranslatedCardName("Blockade", "Blockades", "a Blockade");
EnglishCardNames[CardNames.SAILOR] = new TranslatedCardName("Sailor", "Sailors", "a Sailor");
EnglishCardNames[CardNames.PIRATE] = new TranslatedCardName("Pirate", "Pirates", "a Pirate");
EnglishCardNames[CardNames.CORSAIR] = new TranslatedCardName("Corsair", "Corsairs", "a Corsair");
EnglishCardNames[CardNames.SEA_CHART] = new TranslatedCardName("Sea Chart", "Sea Charts", "a Sea Chart");
EnglishCardNames[CardNames.ASTROLABE] = new TranslatedCardName("Astrolabe", "Astrolabes", "an Astrolabe");
EnglishCardNames[CardNames.TIDE_POOLS] = new TranslatedCardName("Tide Pools", "Tide Pools", "a Tide Pools");
EnglishCardNames[CardNames.SEA_WITCH] = new TranslatedCardName("Sea Witch", "Sea Witches", "a Sea Witch");

EnglishCardNames[CardNames.ANVIL] = new TranslatedCardName("Anvil", "Anvils", "an Anvil");
EnglishCardNames[CardNames.CLERK] = new TranslatedCardName("Clerk", "Clerks", "a Clerk");
EnglishCardNames[CardNames.INVESTMENT] = new TranslatedCardName("Investment", "Investments", "an Investment");
EnglishCardNames[CardNames.TIARA] = new TranslatedCardName("Tiara", "Tiaras", "a Tiara");
EnglishCardNames[CardNames.CHARLATAN] = new TranslatedCardName("Charlatan", "Charlatans", "a Charlatan");
EnglishCardNames[CardNames.COLLECTION] = new TranslatedCardName("Collection", "Collections", "a Collection");
EnglishCardNames[CardNames.CRYSTAL_BALL] = new TranslatedCardName("Crystal Ball", "Crystal Balls", "a Crystal Ball");
EnglishCardNames[CardNames.MAGNATE] = new TranslatedCardName("Magnate", "Magnates", "a Magnate");
EnglishCardNames[CardNames.WAR_CHEST] = new TranslatedCardName("War Chest", "War Chests", "a War Chest");

EnglishCardNames[CardNames.GUARD_DOG] = new TranslatedCardName("Guard Dog", "Guard Dogs", "a Guard Dog");
EnglishCardNames[CardNames.BERSERKER] = new TranslatedCardName("Berserker", "Berserkers", "a Berserker");
EnglishCardNames[CardNames.NOMADS] = new TranslatedCardName("Nomads", "Nomads", "a Nomads");
EnglishCardNames[CardNames.TRAIL] = new TranslatedCardName("Trail", "Trails", "a Trail");
EnglishCardNames[CardNames.WEAVER] = new TranslatedCardName("Weaver", "Weavers", "a Weaver");
EnglishCardNames[CardNames.SOUK] = new TranslatedCardName("Souk", "Souks", "a Souk");
EnglishCardNames[CardNames.CAULDRON] = new TranslatedCardName("Cauldron", "Cauldrons", "a Cauldron");
EnglishCardNames[CardNames.WHEELWRIGHT] = new TranslatedCardName("Wheelwright", "Wheelwrights", "a Wheelwright");
EnglishCardNames[CardNames.WITCHS_HUT] = new TranslatedCardName("Witch's Hut", "Witch's Huts", "a Witch's Hut");

EnglishCardNames[CardNames.TOWN] = new TranslatedCardName("Town", "Towns", "a Town");
EnglishCardNames[CardNames.MODIFY] = new TranslatedCardName("Modify", "Modifies", "a Modify");
EnglishCardNames[CardNames.MERCHANT_CAMP] = new TranslatedCardName("Merchant Camp", "Merchant Camps", "a Merchant Camp");
EnglishCardNames[CardNames.SWAP] = new TranslatedCardName("Swap", "Swaps", "a Swap");
EnglishCardNames[CardNames.SKIRMISHER] = new TranslatedCardName("Skirmisher", "Skirmishers", "a Skirmisher");
EnglishCardNames[CardNames.HIGHWAYMAN] = new TranslatedCardName("Highwayman", "Highwaymen", "a Highwayman");
EnglishCardNames[CardNames.BARBARIAN] = new TranslatedCardName("Barbarian", "Barbarians", "a Barbarian");
EnglishCardNames[CardNames.INNKEEPER] = new TranslatedCardName("Innkeeper", "Innkeepers", "an Innkeeper");
EnglishCardNames[CardNames.CAPITAL_CITY] = new TranslatedCardName("Capital City", "Capital Cities", "a Capital City");
EnglishCardNames[CardNames.SPECIALIST] = new TranslatedCardName("Specialist", "Specialists", "a Specialist");
EnglishCardNames[CardNames.CARPENTER] = new TranslatedCardName("Carpenter", "Carpenters", "a Carpenter");
EnglishCardNames[CardNames.ROYAL_GALLEY] = new TranslatedCardName("Royal Galley", "Royal Galleys", "a Royal Galley");
EnglishCardNames[CardNames.MARQUIS] = new TranslatedCardName("Marquis", "Marquises", "a Marquis");
EnglishCardNames[CardNames.SENTINEL] = new TranslatedCardName("Sentinel", "Sentinels", "a Sentinel");
EnglishCardNames[CardNames.COURIER] = new TranslatedCardName("Courier", "Couriers", "a Courier");
EnglishCardNames[CardNames.GALLERIA] = new TranslatedCardName("Galleria", "Gallerias", "a Galleria");
EnglishCardNames[CardNames.HUNTER] = new TranslatedCardName("Hunter", "Hunters", "a Hunter");

EnglishCardNames[CardNames.SYCOPHANT] = new TranslatedCardName("Sycophant", "Sycophants", "a Sycophant");
EnglishCardNames[CardNames.UNDERLING] = new TranslatedCardName("Underling", "Underlings", "an Underling");
EnglishCardNames[CardNames.IMPORTER] = new TranslatedCardName("Importer", "Importers", "an Importer");
EnglishCardNames[CardNames.CONTRACT] = new TranslatedCardName("Contract", "Contracts", "a Contract");
EnglishCardNames[CardNames.EMISSARY] = new TranslatedCardName("Emissary", "Emissaries", "an Emissary");
EnglishCardNames[CardNames.BAUBLE] = new TranslatedCardName("Bauble", "Baubles", "a Bauble");
EnglishCardNames[CardNames.BROKER] = new TranslatedCardName("Broker", "Brokers", "a Broker");
EnglishCardNames[CardNames.GUILDMASTER] = new TranslatedCardName("Guildmaster", "Guildmasters", "a Guildmaster");
EnglishCardNames[CardNames.ARCHITECTS_GUILD] = new TranslatedCardName("Architects' Guild");
EnglishCardNames[CardNames.ORDER_OF_ASTROLOGERS] = new TranslatedCardName("Order of Astrologers");
EnglishCardNames[CardNames.LEAGUE_OF_BANKERS] = new TranslatedCardName("League of Bankers");
EnglishCardNames[CardNames.MOUNTAIN_FOLK] = new TranslatedCardName("Mountain Folk");
EnglishCardNames[CardNames.WOODWORKERS_GUILD] = new TranslatedCardName("Woodworkers' Guild");
EnglishCardNames[CardNames.PEACEFUL_CULT] = new TranslatedCardName("Peaceful Cult");
EnglishCardNames[CardNames.CRAFTERS_GUILD] = new TranslatedCardName("Crafters' Guild");
EnglishCardNames[CardNames.CAVE_DWELLERS] = new TranslatedCardName("Cave Dwellers");
EnglishCardNames[CardNames.PLATEAU_SHEPHERDS] = new TranslatedCardName("Plateau Shepherds");
EnglishCardNames[CardNames.DESERT_GUIDES] = new TranslatedCardName("Desert Guides");
EnglishCardNames[CardNames.FAMILY_OF_INVENTORS] = new TranslatedCardName("Family of Inventors");
EnglishCardNames[CardNames.ISLAND_FOLK] = new TranslatedCardName("Island Folk");
EnglishCardNames[CardNames.ORDER_OF_MASONS] = new TranslatedCardName("Order of Masons");
EnglishCardNames[CardNames.BAND_OF_NOMADS] = new TranslatedCardName("Band of Nomads");
EnglishCardNames[CardNames.GANG_OF_PICKPOCKETS] = new TranslatedCardName("Gang of Pickpockets");
EnglishCardNames[CardNames.MARKET_TOWNS] = new TranslatedCardName("Market Towns");
EnglishCardNames[CardNames.FOREST_DWELLERS] = new TranslatedCardName("Forest Dwellers");
EnglishCardNames[CardNames.FELLOWSHIP_OF_SCRIBES] = new TranslatedCardName("Fellowship of Scribes");
EnglishCardNames[CardNames.LEAGUE_OF_SHOPKEEPERS] = new TranslatedCardName("League of Shopkeepers");
EnglishCardNames[CardNames.COASTAL_HAVEN] = new TranslatedCardName("Coastal Haven");
EnglishCardNames[CardNames.CITY_STATE] = new TranslatedCardName("City-state");
EnglishCardNames[CardNames.TRAPPERS_LODGE] = new TranslatedCardName("Trappers' Lodge");
EnglishCardNames[CardNames.CIRCLE_OF_WITCHES] = new TranslatedCardName("Circle of Witches");
EnglishCardNames[CardNames.AUGUR_PILE] = new TranslatedCardName("Augurs");
EnglishCardNames[CardNames.HERB_GATHERER] = new TranslatedCardName("Herb Gatherer", "Herb Gatherers", "an Herb Gatherer");
EnglishCardNames[CardNames.ACOLYTE] = new TranslatedCardName("Acolyte", "Acolytes", "an Acolyte");
EnglishCardNames[CardNames.SORCERESS] = new TranslatedCardName("Sorceress", "Sorceresses", "a Sorceress");
EnglishCardNames[CardNames.SIBYL] = new TranslatedCardName("Sibyl", "Sibyls", "a Sibyl");
EnglishCardNames[CardNames.CLASH_PILE] = new TranslatedCardName("Clashes");
EnglishCardNames[CardNames.BATTLE_PLAN] = new TranslatedCardName("Battle Plan", "Battle Plans", "a Battle Plan");
EnglishCardNames[CardNames.ARCHER] = new TranslatedCardName("Archer", "Archers", "an Archer");
EnglishCardNames[CardNames.WARLORD] = new TranslatedCardName("Warlord", "Warlords", "a Warlord");
EnglishCardNames[CardNames.TERRITORY] = new TranslatedCardName("Territory", "Territories", "a Territory");
EnglishCardNames[CardNames.FORT_PILE] = new TranslatedCardName("Forts");
EnglishCardNames[CardNames.TENT] = new TranslatedCardName("Tent", "Tents", "a Tent");
EnglishCardNames[CardNames.GARRISON] = new TranslatedCardName("Garrison", "Garrisons", "a Garrison");
EnglishCardNames[CardNames.HILL_FORT] = new TranslatedCardName("Hill Fort", "Hill Forts", "a Hill Fort");
EnglishCardNames[CardNames.STRONGHOLD] = new TranslatedCardName("Stronghold", "Strongholds", "a Stronghold");
EnglishCardNames[CardNames.ODYSSEY_PILE] = new TranslatedCardName("Odysseys");
EnglishCardNames[CardNames.OLD_MAP] = new TranslatedCardName("Old Map", "Old Maps", "an Old Map");
EnglishCardNames[CardNames.VOYAGE] = new TranslatedCardName("Voyage", "Voyages", "a Voyage");
EnglishCardNames[CardNames.SUNKEN_TREASURE] = new TranslatedCardName("Sunken Treasure", "Sunken Treasures", "a Sunken Treasure");
EnglishCardNames[CardNames.DISTANT_SHORE] = new TranslatedCardName("Distant Shore", "Distant Shores", "a Distant Shore");
EnglishCardNames[CardNames.TOWNSFOLK_PILE] = new TranslatedCardName("Townsfolk");
EnglishCardNames[CardNames.TOWN_CRIER] = new TranslatedCardName("Town Crier", "Town Criers", "a Town Crier");
EnglishCardNames[CardNames.BLACKSMITH] = new TranslatedCardName("Blacksmith", "Blacksmiths", "a Blacksmith");
EnglishCardNames[CardNames.MILLER] = new TranslatedCardName("Miller", "Millers", "a Miller");
EnglishCardNames[CardNames.ELDER] = new TranslatedCardName("Elder", "Elders", "an Elder");
EnglishCardNames[CardNames.WIZARD_PILE] = new TranslatedCardName("Wizards");
EnglishCardNames[CardNames.STUDENT] = new TranslatedCardName("Student", "Students", "a Student");
EnglishCardNames[CardNames.CONJURER] = new TranslatedCardName("Conjurer", "Conjurers", "a Conjurer");
EnglishCardNames[CardNames.SORCERER] = new TranslatedCardName("Sorcerer", "Sorcerers", "a Sorcerer");
EnglishCardNames[CardNames.LICH] = new TranslatedCardName("Lich", "Liches", "a Lich");

EnglishCardNames[CardNames.CAGE] = new TranslatedCardName("Cage", "Cages", "a Cage");
EnglishCardNames[CardNames.GROTTO] = new TranslatedCardName("Grotto", "Grottoes", "a Grotto");
EnglishCardNames[CardNames.JEWELLED_EGG] = new TranslatedCardName("Jewelled Egg", "Jewelled Eggs", "a Jewelled Egg");
EnglishCardNames[CardNames.SEARCH] = new TranslatedCardName("Search", "Searches", "a Search");
EnglishCardNames[CardNames.SHAMAN] = new TranslatedCardName("Shaman", "Shamans", "a Shaman");
EnglishCardNames[CardNames.SECLUDED_SHRINE] = new TranslatedCardName("Secluded Shrine", "Secluded Shrines", "a Secluded Shrine");
EnglishCardNames[CardNames.SIREN] = new TranslatedCardName("Siren", "Sirens", "a Siren");
EnglishCardNames[CardNames.STOWAWAY] = new TranslatedCardName("Stowaway", "Stowaways", "a Stowaway");
EnglishCardNames[CardNames.TASKMASTER] = new TranslatedCardName("Taskmaster", "Taskmasters", "a Taskmaster");
EnglishCardNames[CardNames.ABUNDANCE] = new TranslatedCardName("Abundance", "Abundances", "an Abundance");
EnglishCardNames[CardNames.CABIN_BOY] = new TranslatedCardName("Cabin Boy", "Cabin Boys", "a Cabin Boy");
EnglishCardNames[CardNames.CRUCIBLE] = new TranslatedCardName("Crucible", "Crucibles", "a Crucible");
EnglishCardNames[CardNames.FLAGSHIP] = new TranslatedCardName("Flagship", "Flagships", "a Flagship");
EnglishCardNames[CardNames.FORTUNE_HUNTER] = new TranslatedCardName("Fortune Hunter", "Fortune Hunters", "a Fortune Hunter");
EnglishCardNames[CardNames.GONDOLA] = new TranslatedCardName("Gondola", "Gondolas", "a Gondola");
EnglishCardNames[CardNames.HARBOR_VILLAGE] = new TranslatedCardName("Harbor Village", "Harbor Villages", "a Harbor Village");
EnglishCardNames[CardNames.LANDING_PARTY] = new TranslatedCardName("Landing Party", "Landing Parties", "a Landing Party");
EnglishCardNames[CardNames.MAPMAKER] = new TranslatedCardName("Mapmaker", "Mapmakers", "a Mapmaker");
EnglishCardNames[CardNames.MAROON] = new TranslatedCardName("Maroon", "Maroons", "a Maroon");
EnglishCardNames[CardNames.ROPE] = new TranslatedCardName("Rope", "Ropes", "a Rope");
EnglishCardNames[CardNames.SWAMP_SHACKS] = new TranslatedCardName("Swamp Shacks", "Swamp Shacks", "a Swamp Shacks");
EnglishCardNames[CardNames.TOOLS] = new TranslatedCardName("Tools", "Tools", "a Tools");
EnglishCardNames[CardNames.BURIED_TREASURE] = new TranslatedCardName("Buried Treasure", "Buried Treasures", "a Buried Treasure");
EnglishCardNames[CardNames.CREW] = new TranslatedCardName("Crew", "Crews", "a Crew");
EnglishCardNames[CardNames.CUTTHROAT] = new TranslatedCardName("Cutthroat", "Cutthroats", "a Cutthroat");
EnglishCardNames[CardNames.ENLARGE] = new TranslatedCardName("Enlarge", "Enlarges", "an Enlarge");
EnglishCardNames[CardNames.FIGURINE] = new TranslatedCardName("Figurine", "Figurines", "a Figurine");
EnglishCardNames[CardNames.FIRST_MATE] = new TranslatedCardName("First Mate", "First Mates", "a First Mate");
EnglishCardNames[CardNames.FRIGATE] = new TranslatedCardName("Frigate", "Frigates", "a Frigate");
EnglishCardNames[CardNames.LONGSHIP] = new TranslatedCardName("Longship", "Longships", "a Longship");
EnglishCardNames[CardNames.MINING_ROAD] = new TranslatedCardName("Mining Road", "Mining Roads", "a Mining Road");
EnglishCardNames[CardNames.PENDANT] = new TranslatedCardName("Pendant", "Pendants", "a Pendant");
EnglishCardNames[CardNames.PICKAXE] = new TranslatedCardName("Pickaxe", "Pickaxes", "a Pickaxe");
EnglishCardNames[CardNames.PILGRIM] = new TranslatedCardName("Pilgrim", "Pilgrims", "a Pilgrim");
EnglishCardNames[CardNames.QUARTERMASTER] = new TranslatedCardName("Quartermaster", "Quartermasters", "a Quartermaster");
EnglishCardNames[CardNames.SILVER_MINE] = new TranslatedCardName("Silver Mine", "Silver Mines", "a Silver Mine");
EnglishCardNames[CardNames.TRICKSTER] = new TranslatedCardName("Trickster", "Tricksters", "a Trickster");
EnglishCardNames[CardNames.WEALTHY_VILLAGE] = new TranslatedCardName("Wealthy Village", "Wealthy Villages", "a Wealthy Village");
EnglishCardNames[CardNames.SACK_OF_LOOT] = new TranslatedCardName("Sack Of Loot", "Sacks of Loot", "a Sack of Loot");
EnglishCardNames[CardNames.KINGS_CACHE] = new TranslatedCardName("King's Cache", "King's Caches", "a King's Cache");
EnglishCardNames[CardNames.BURY] = new TranslatedCardName("Bury", "Buries", "a Bury");
EnglishCardNames[CardNames.AVOID] = new TranslatedCardName("Avoid", "Avoids", "an Avoid");
EnglishCardNames[CardNames.DELIVER] = new TranslatedCardName("Deliver", "Delivers", "a Deliver");
EnglishCardNames[CardNames.PERIL] = new TranslatedCardName("Peril", "Perils", "a Peril");
EnglishCardNames[CardNames.RUSH] = new TranslatedCardName("Rush", "Rushes", "a Rush");
EnglishCardNames[CardNames.FORAY] = new TranslatedCardName("Foray", "Forays", "a Foray");
EnglishCardNames[CardNames.LAUNCH] = new TranslatedCardName("Launch", "Launches", "a Launch");
EnglishCardNames[CardNames.MIRROR] = new TranslatedCardName("Mirror", "Mirrors", "a Mirror");
EnglishCardNames[CardNames.PREPARE] = new TranslatedCardName("Prepare", "Prepares", "a Prepare");
EnglishCardNames[CardNames.SCROUNGE] = new TranslatedCardName("Scrounge", "Scrounges", "a Scrounge");
EnglishCardNames[CardNames.JOURNEY] = new TranslatedCardName("Journey", "Journeys", "a Journey");
EnglishCardNames[CardNames.MAELSTROM] = new TranslatedCardName("Maelstrom", "Maelstroms", "a Maelstrom");
EnglishCardNames[CardNames.LOOTING] = new TranslatedCardName("Looting", "Lootings", "a Looting");
EnglishCardNames[CardNames.INVASION] = new TranslatedCardName("Invasion", "Invasions", "an Invasion");
EnglishCardNames[CardNames.PROSPER] = new TranslatedCardName("Prosper", "Prospers", "a Prosper");
EnglishCardNames[CardNames.LOOT_PILE] = new TranslatedCardName("Loot");
EnglishCardNames[CardNames.AMPHORA] = new TranslatedCardName("Amphora", "Amphorae", "an Amphora");
EnglishCardNames[CardNames.DOUBLOONS] = new TranslatedCardName("Doubloons", "Doubloons", "a Doubloons");
EnglishCardNames[CardNames.ENDLESS_CHALICE] = new TranslatedCardName("Endless Chalice", "Endless Chalices", "an Endless Chalice");
EnglishCardNames[CardNames.FIGUREHEAD] = new TranslatedCardName("Figurehead", "Figureheads", "a Figurehead");
EnglishCardNames[CardNames.HAMMER] = new TranslatedCardName("Hammer", "Hammers", "a Hammer");
EnglishCardNames[CardNames.INSIGNIA] = new TranslatedCardName("Insignia", "Insignias", "an Insignia");
EnglishCardNames[CardNames.JEWELS] = new TranslatedCardName("Jewels", "Jewels", "a Jewels");
EnglishCardNames[CardNames.ORB] = new TranslatedCardName("Orb", "Orbs", "an Orb");
EnglishCardNames[CardNames.PRIZE_GOAT] = new TranslatedCardName("Prize Goat", "Prize Goats", "a Prize Goat");
EnglishCardNames[CardNames.PUZZLE_BOX] = new TranslatedCardName("Puzzle Box", "Puzzle Boxes", "a Puzzle Box");
EnglishCardNames[CardNames.SEXTANT] = new TranslatedCardName("Sextant", "Sextants", "a Sextant");
EnglishCardNames[CardNames.SHIELD] = new TranslatedCardName("Shield", "Shields", "a Shield");
EnglishCardNames[CardNames.SPELL_SCROLL] = new TranslatedCardName("Spell Scroll", "Spell Scrolls", "a Spell Scroll");
EnglishCardNames[CardNames.STAFF] = new TranslatedCardName("Staff", "Staffs", "a Staff");
EnglishCardNames[CardNames.SWORD] = new TranslatedCardName("Sword", "Swords", "a Sword");
EnglishCardNames[CardNames.CHEAP] = new TranslatedCardName("Cheap", "Cheaps", "a Cheap");
EnglishCardNames[CardNames.CURSED] = new TranslatedCardName("Cursed", "Curseds", "a Cursed");
EnglishCardNames[CardNames.FATED] = new TranslatedCardName("Fated", "Fateds", "a Fated");
EnglishCardNames[CardNames.FAWNING] = new TranslatedCardName("Fawning", "Fawnings", "a Fawning");
EnglishCardNames[CardNames.FRIENDLY] = new TranslatedCardName("Friendly", "Friendlies", "a Friendly");
EnglishCardNames[CardNames.HASTY] = new TranslatedCardName("Hasty", "Hasties", "a Hasty");
EnglishCardNames[CardNames.INHERITED] = new TranslatedCardName("Inherited", "Inheriteds", "an Inherited");
EnglishCardNames[CardNames.INSPIRING] = new TranslatedCardName("Inspiring", "Inspirings", "an Inspiring");
EnglishCardNames[CardNames.NEARBY] = new TranslatedCardName("Nearby", "Nearbys", "an Nearby");
EnglishCardNames[CardNames.PATIENT] = new TranslatedCardName("Patient", "Patients", "a Patient");
EnglishCardNames[CardNames.PIOUS] = new TranslatedCardName("Pious", "Piouses", "a Pious");
EnglishCardNames[CardNames.RECKLESS] = new TranslatedCardName("Reckless", "Recklesses", "a Reckless");
EnglishCardNames[CardNames.RICH] = new TranslatedCardName("Rich", "Riches", "a Rich");
EnglishCardNames[CardNames.SHY] = new TranslatedCardName("Shy", "Shies", "a Shy");
EnglishCardNames[CardNames.TIRELESS] = new TranslatedCardName("Tireless", "Tirelesses", "a Tireless");
EnglishCardNames[CardNames.SHOP] = new TranslatedCardName("Shop", "Shops", "a Shop");
EnglishCardNames[CardNames.CARNIVAL] = new TranslatedCardName("Carnival", "Carnivals", "a Carnival");
EnglishCardNames[CardNames.FERRYMAN] = new TranslatedCardName("Ferryman", "Ferrymen", "a Ferryman");
EnglishCardNames[CardNames.FARMHANDS] = new TranslatedCardName("Farmhands", "Farmhands", "a Farmhands");
EnglishCardNames[CardNames.JOUST] = new TranslatedCardName("Joust", "Jousts", "a Joust");
EnglishCardNames[CardNames.REWARDS_PILE] = new TranslatedCardName("Rewards", "Rewards", "Rewards");
EnglishCardNames[CardNames.COURSER] = new TranslatedCardName("Courser", "Coursers", "a Courser");
EnglishCardNames[CardNames.RENOWN] = new TranslatedCardName("Renown", "Renowns", "a Renown");
EnglishCardNames[CardNames.CORONET] = new TranslatedCardName("Coronet", "Coronets", "a Coronet");
EnglishCardNames[CardNames.DEMESNE] = new TranslatedCardName("Demesne", "Demesnes", "a Demesne");
EnglishCardNames[CardNames.HOUSECARL] = new TranslatedCardName("Housecarl", "Housecarls", "a Housecarl");
EnglishCardNames[CardNames.HUGE_TURNIP] = new TranslatedCardName("Huge Turnip", "Huge Turnips", "a Huge Turnip");
EnglishCardNames[CardNames.FARRIER] = new TranslatedCardName("Farrier", "Farriers", "a Farrier");
EnglishCardNames[CardNames.INFIRMARY] = new TranslatedCardName("Infirmary", "Infirmaries", "an Infirmary");
EnglishCardNames[CardNames.FOOTPAD] = new TranslatedCardName("Footpad", "Footpads", "a Footpad");
EnglishCardNames[CardNames.FISHMONGER] = new TranslatedCardName("Fishmonger", "Fishmongers", "a Fishmonger");
EnglishCardNames[CardNames.SNAKE_WITCH] = new TranslatedCardName("Snake Witch", "Snake Witches", "a Snake Witch");
EnglishCardNames[CardNames.ARISTOCRAT] = new TranslatedCardName("Aristocrat", "Aristocrats", "an Aristocrat");
EnglishCardNames[CardNames.CRAFTSMAN] = new TranslatedCardName("Craftsman", "Craftsmen", "a Craftsman");
EnglishCardNames[CardNames.RIVERBOAT] = new TranslatedCardName("Riverboat", "Riverboats", "a Riverboat");
EnglishCardNames[CardNames.ROOT_CELLAR] = new TranslatedCardName("Root Cellar", "Root Cellars", "a Root Cellar");
EnglishCardNames[CardNames.ALLEY] = new TranslatedCardName("Alley", "Alleys", "an Alley");
EnglishCardNames[CardNames.CHANGE] = new TranslatedCardName("Change", "Changes", "a Change");
EnglishCardNames[CardNames.NINJA] = new TranslatedCardName("Ninja", "Ninjas", "a Ninja");
EnglishCardNames[CardNames.POET] = new TranslatedCardName("Poet", "Poets", "a Poet");
EnglishCardNames[CardNames.RIVER_SHRINE] = new TranslatedCardName("River Shrine", "River Shrines", "a River Shrine");
EnglishCardNames[CardNames.RUSTIC_VILLAGE] = new TranslatedCardName("Rustic Village", "Rustic Villages", "a Rustic Village");
EnglishCardNames[CardNames.GOLD_MINE] = new TranslatedCardName("Gold Mine", "Gold Mines", "a Gold Mine");
EnglishCardNames[CardNames.IMPERIAL_ENVOY] = new TranslatedCardName("Imperial Envoy", "Imperial Envoys", "an Imperial Envoy");
EnglishCardNames[CardNames.KITSUNE] = new TranslatedCardName("Kitsune", "Kitsunes", "a Kitsune");
EnglishCardNames[CardNames.LITTER] = new TranslatedCardName("Litter", "Litters", "a Litter");
EnglishCardNames[CardNames.RICE_BROKER] = new TranslatedCardName("Rice Broker", "Rice Brokers", "a Rice Broker");
EnglishCardNames[CardNames.RONIN] = new TranslatedCardName("Ronin", "Ronins", "a Ronin");
EnglishCardNames[CardNames.TANUKI] = new TranslatedCardName("Tanuki", "Tanukis", "a Tanuki");
EnglishCardNames[CardNames.TEA_HOUSE] = new TranslatedCardName("Tea House", "Tea Houses", "a Tea House");
EnglishCardNames[CardNames.SAMURAI] = new TranslatedCardName("Samurai", "Samurais", "a Samurai");
EnglishCardNames[CardNames.RICE] = new TranslatedCardName("Rice", "Rices", "a Rice");
EnglishCardNames[CardNames.MOUNTAIN_SHRINE] = new TranslatedCardName("Mountain Shrine", "Mountain Shrines", "a Mountain Shrine");
EnglishCardNames[CardNames.DAIMYO] = new TranslatedCardName("Daimyo", "Daimyos", "a Daimyo");
EnglishCardNames[CardNames.ARTIST] = new TranslatedCardName("Artist", "Artists", "an Artist");
EnglishCardNames[CardNames.APPROACHING_ARMY] = new TranslatedCardName("Approaching Army");
EnglishCardNames[CardNames.BIDING_TIME] = new TranslatedCardName("Biding Time");
EnglishCardNames[CardNames.BUREAUCRACY] = new TranslatedCardName("Bureaucracy");
EnglishCardNames[CardNames.DIVINE_WIND] = new TranslatedCardName("Divine Wind");
EnglishCardNames[CardNames.ENLIGHTENMENT] = new TranslatedCardName("Enlightenment");
EnglishCardNames[CardNames.FLOURISHING_TRADE] = new TranslatedCardName("Flourishing Trade");
EnglishCardNames[CardNames.GOOD_HARVEST] = new TranslatedCardName("Good Harvest");
EnglishCardNames[CardNames.GREAT_LEADER] = new TranslatedCardName("Great Leader");
EnglishCardNames[CardNames.GROWTH] = new TranslatedCardName("Growth");
EnglishCardNames[CardNames.HARSH_WINTER] = new TranslatedCardName("Harsh Winter");
EnglishCardNames[CardNames.KIND_EMPEROR] = new TranslatedCardName("Kind Emperor");
EnglishCardNames[CardNames.PANIC] = new TranslatedCardName("Panic");
EnglishCardNames[CardNames.PROGRESS] = new TranslatedCardName("Progress");
EnglishCardNames[CardNames.RAPID_EXPANSION] = new TranslatedCardName("Rapid Expansion");
EnglishCardNames[CardNames.SICKNESS] = new TranslatedCardName("Sickness");
EnglishCardNames[CardNames.AMASS] = new TranslatedCardName("Amass");
EnglishCardNames[CardNames.ASCETICISM] = new TranslatedCardName("Asceticism");
EnglishCardNames[CardNames.CREDIT] = new TranslatedCardName("Credit");
EnglishCardNames[CardNames.FORESIGHT] = new TranslatedCardName("Foresight");
EnglishCardNames[CardNames.KINTSUGI] = new TranslatedCardName("Kintsugi");
EnglishCardNames[CardNames.PRACTICE] = new TranslatedCardName("Practice");
EnglishCardNames[CardNames.SEA_TRADE] = new TranslatedCardName("Sea Trade");
EnglishCardNames[CardNames.RECEIVE_TRIBUTE] = new TranslatedCardName("Receive Tribute");
EnglishCardNames[CardNames.GATHER] = new TranslatedCardName("Gather");
EnglishCardNames[CardNames.CONTINUE] = new TranslatedCardName("Continue");