"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var NameQuestion = function (_Question) {
    _inherits(NameQuestion, _Question);

    function NameQuestion(game, index, description) {
        _classCallCheck(this, NameQuestion);

        var _this = _possibleConstructorReturn(this, (NameQuestion.__proto__ || Object.getPrototypeOf(NameQuestion)).call(this, game, index, description));

        _this.questionClass = QuestionClasses.NAME_QUESTION;
        return _this;
    }

    _createClass(NameQuestion, [{
        key: "execute",
        value: function execute(game) {
            var _this2 = this;

            var isRoot = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

            game.state.cards.forEach(function (card) {
                var cardName = card.cardName;
                if (!cardName.isLandscape() && !cardName.isBack() && card.cardStacks.some(function (s) {
                    return !(s.location instanceof NonSupplyZone);
                })) {
                    card.associateAnswer(_this2, getOrdinal(CardNames, cardName));
                }
            });
            game.state.anonCards.forEach(function (card) {
                if (!card.cardName.isBack() && card.cardStacks.some(function (s) {
                    return !(s.location instanceof NonSupplyZone);
                })) card.associateAnswer(_this2, getOrdinal(CardNames, card.cardName));
            });
            _get(NameQuestion.prototype.__proto__ || Object.getPrototypeOf(NameQuestion.prototype), "execute", this).call(this, game, isRoot);
            if (!isUndefined(game.gameArea)) {
                game.gameArea.updateNameInput();
            }
        }
    }, {
        key: "answer",
        value: function answer(_answer) {
            this.accumulatedAnswers = [_answer];
            this.send();
        }
    }], [{
        key: "parse",
        value: function parse(game, reader, questionIndex) {
            var questionDescription = QuestionDescription.parse(reader);
            return new NameQuestion(game, questionIndex, questionDescription);
        }
    }]);

    return NameQuestion;
}(Question);