"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AnonymousCardObject = function AnonymousCardObject(cardName) {
    _classCallCheck(this, AnonymousCardObject);

    this.id = -1;
    this.cardName = cardName;
    this.pileName = cardName;

    this.image = this.cardName;
    this.types = this.cardName.types;
    this.cost = this.cardName.cost;
    this.cost = cardName.cost;

    this.question = null;
    this.answer = null;
    this.isSelected = false;
    this.counters = [];
};

var AnonymousCardStack = function () {
    function AnonymousCardStack(studyModel, cardName) {
        var cardSize = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CardSizes.FULL;
        var counter = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : -1;

        _classCallCheck(this, AnonymousCardStack);

        this.studyModel = studyModel;
        this.cardName = cardName;
        this.cardSize = cardSize;
        this.filters = [];
        this.counter = counter;
        this.location = null;
        this.view = new CardStackView(this);
    }

    _createClass(AnonymousCardStack, [{
        key: "destroy",
        value: function destroy() {
            this.view.destroy();
        }
    }, {
        key: "render",
        value: function render(node) {
            var _this = this;

            this.view.render(node);
            this.view.canvas.oncontextmenu = function (event) {
                event.preventDefault();
                _this.studyModel.study(_this.topCard.cardName);
            };
        }
    }, {
        key: "hasFilter",
        value: function hasFilter(filter) {
            return this.filters.includes(filter);
        }
    }, {
        key: "addFilter",
        value: function addFilter(filter) {
            if (this.filters.indexOf(filter) === -1) {
                this.filters.push(filter);
                this.view.filterChanged();
            }
        }
    }, {
        key: "removeFilter",
        value: function removeFilter(filter) {
            var index = this.filters.indexOf(filter);
            if (index > -1) {
                this.filters.splice(index, 1);
                this.view.filterChanged();
            }
        }
    }, {
        key: "getClickableCard",
        value: function getClickableCard() {
            return null;
        }
    }, {
        key: "getCounter",
        value: function getCounter() {
            return this.counter;
        }
    }, {
        key: "cardName",
        set: function set(value) {
            if (value !== this.privateCardName) {
                this.privateCardName = value;
                this.topCard = new AnonymousCardObject(value);
            }
        },
        get: function get() {
            return this.privateCardName;
        }
    }, {
        key: "count",
        get: function get() {
            return this.counter;
        }
    }, {
        key: "ratio",
        get: function get() {
            return this.cardName.isLandscape() ? this.cardSize.landscapeRatio : this.cardSize.ratio;
        }
    }, {
        key: "visible",
        get: function get() {
            return !(this.hasFilter("intangible") || this.hasFilter("zone-hidden"));
        }
    }]);

    return AnonymousCardStack;
}();

webclient.component("anonymousCard", {
    bindings: {
        cardName: "<",
        cardSize: "<",
        counter: "<"
    },
    controller: ["$scope", "$element", "game", "metaStudyService", function ($scope, $element, game, metaStudyService) {
        var _this2 = this;

        if (isUndefined(this.cardSize)) this.cardSize = CardSizes.FULL;

        if (isUndefined(this.counter)) this.counter = -1;

        var studyModel = metaStudyService.studyModel;
        var cardStack = new AnonymousCardStack(studyModel, this.cardName, this.cardSize, this.counter);

        var interval = void 0;
        var redraw = function redraw() {
            if (isUndefined(cardStack.view)) clearInterval(interval); // this is a GC thing
            var bbox = $element[0].getBoundingClientRect();
            if (bbox.width > 0 && bbox.height > 0) {
                cardStack.view.reposition(0, 0, bbox.width, bbox.height);
            }
        };
        redraw();
        interval = setInterval(redraw, 50);
        $scope.$on('$destroy', function () {
            clearInterval(interval);
        });
        if (this.counter > -1) cardStack.addFilter("shifted-name");
        $scope.$watch("$ctrl.cardName", function () {
            cardStack.cardName = _this2.cardName;
            cardStack.view.updateCard();
        });
        $scope.$watch("$ctrl.cardSize", function () {
            cardStack.cardSize = _this2.cardSize;
            cardStack.view.redrawAll();
        });
        $scope.$watch("$ctrl.counter", function () {
            cardStack.counter = isUndefined(_this2.counter) ? -1 : _this2.counter;
            cardStack.view.updateCounter();
        });
        cardStack.render($element[0]);
    }]
});