"use strict";

var publicTables = void 0;

webclient.service('tables', ['$rootScope', 'serverMessenger', 'meta', function ($rootScope, serverMessenger, meta) {
    var self = this;
    var scope = $rootScope.$new(true);

    var summaries = [];
    var filteredSummaries = [];
    var tableFilters = getValues(TableFilters);
    var activeSorter = TableSorters.SPECTATORS_HIGH_LOW;

    if (isDebug) publicTables = self;

    self.getRawSummaries = function () {
        return summaries;
    };

    var MAX_TABLES_IN_LIST = 50;

    var getActiveFilters = function getActiveFilters() {
        return tableFilters.filter(function (f) {
            return !f.isActive();
        });
    };

    function filterSummaries() {
        var s = summaries.slice(0);
        getActiveFilters().forEach(function (f) {
            return s = s.filter(f.getFilter());
        });
        filteredSummaries = s;
        sortSummaries();
    }

    function sortSummaries() {
        activeSorter.sort(filteredSummaries);
    }
    sortSummaries();

    self.sortByHost = function () {
        if (activeSorter === TableSorters.HOST_A_Z) {
            activeSorter = TableSorters.HOST_Z_A;
        } else {
            activeSorter = TableSorters.HOST_A_Z;
        }
        sortSummaries();
    };

    self.sortByPlayers = function () {
        if (activeSorter === TableSorters.PLAYERS_HIGH_LOW) {
            activeSorter = TableSorters.PLAYERS_LOW_HIGH;
        } else {
            activeSorter = TableSorters.PLAYERS_HIGH_LOW;
        }
        sortSummaries();
    };

    self.sortBySpectators = function () {
        if (activeSorter === TableSorters.SPECTATORS_HIGH_LOW) {
            activeSorter = TableSorters.SPECTATORS_HIGH_LOW;
        } else {
            activeSorter = TableSorters.SPECTATORS_LOW_HIGH;
        }
        sortSummaries();
    };

    self.sortByStatus = function () {
        if (activeSorter === TableSorters.STATUS_HIGH_LOW) {
            activeSorter = TableSorters.STATUS_LOW_HIGH;
        } else {
            activeSorter = TableSorters.STATUS_HIGH_LOW;
        }
        sortSummaries();
    };

    self.getSummaries = function () {
        if (filteredSummaries.length <= MAX_TABLES_IN_LIST) return filteredSummaries;
        return filteredSummaries.slice(0, MAX_TABLES_IN_LIST - 1);
    };

    self.toggleFilter = function (f) {
        f.isActive() ? f.deActivate() : f.activate();
        filterSummaries();
    };

    self.play = function (tableId) {
        if (meta.getMyTableId() === tableId) {
            serverMessenger.sitDown(tableId);
        } else {
            serverMessenger.joinTable(tableId, true);
        }
    };

    self.spectate = function (tableId) {
        if (meta.getMyTableId() === tableId) {
            serverMessenger.standUp(tableId, meta.getMyId());
        } else {
            serverMessenger.joinTable(tableId, false);
        }
    };

    self.refresh = function () {
        serverMessenger.requestUpdate(UpdateTypes.TABLES);
    };

    scope.$on(Events.TABLES_OVERVIEW, function (event, data) {
        summaries = data.filter(function (s) {
            return s.status !== TableStati.ABANDONED;
        });
    });
}]);