"use strict";

var activeGame = void 0;

webclient.service('game', ["meta", "preferences", "gameBroadcaster", "serverMessenger", "kingdomService", "gameSoundService", "$rootScope", function (meta, preferences, gameBroadcaster, serverMessenger, kingdomService, gameSoundService, $rootScope) {
    var _this = this;

    var self = this;

    if (isDebug) activeGame = self;
    self._isActive = false;
    self.isRunning = function () {
        return self._isActive;
    };
    self.heroOverride = null;
    self.gameSoundService = gameSoundService;

    self.readState = function (isUpdate, reader) {
        if (!isUndefined(_this.state)) {
            _this.state.zones.forEach(function (z) {
                return z.unload();
            });
            _this.state.nowhere.unload();
            _this.state.orphanage.unload();
        }
        if (!isUndefined(_this.questionModel)) _this.questionModel.unload();
        if (!isUndefined(_this.layoutModel)) _this.layoutModel.unload();
        if (!isUndefined(_this.kingdomViewer)) _this.kingdomViewer.initialize();

        self._isActive = true;
        if (!isUndefined(_this.gameArea)) {
            _this.gameArea.reset();
        }
        if (!isUpdate && !isUndefined(self.animationDirector)) self.animationDirector.interruptAnimations();

        self.animationDirector = new AnimationDirector(_this);
        self.layoutModel = new LayoutModel(_this, preferences);
        self.metaGameModel = new MetaGameModel(_this, serverMessenger, gameBroadcaster);
        self.optionsModel = new OptionsModel(_this, serverMessenger, gameBroadcaster, preferences);
        self.playerModel = new PlayerModel(_this, meta);
        self.questionModel = new QuestionModel(_this, serverMessenger);
        self.studyModel = new StudyModel(_this);
        self.state = new GameState(_this);
        self.state.parse(reader);
        self.playerModel.initialize();
        if (isUpdate) {
            var oldEntries = self.logModel.entries;
            self.logModel = new LogModel(_this, serverMessenger);
            self.logModel.newLogEntries(oldEntries);
        } else self.logModel = new LogModel(_this, serverMessenger);
        self.layoutModel.computePileCounts();
        self.layoutModel.computeBestLayout();
        self.layoutModel.createTabs();
        self.optionsModel.readAutoplaySettings(reader);
        self.optionsModel.readTableOptions(reader);
        self.questionModel.createCleanupWindow();
        self.state.zones.filter(function (z) {
            return z.isVisible;
        }).forEach(function (z) {
            return z.reposition();
        });
        kingdomService.updateLastSeenKingdom(self.state);
        Preloader.loadAll(self.state.cardNames.map(function (cn) {
            return [getFullArtURL(cn), getFullTemplateURL(cn.types, cn.isBaseCard(), cn.hasHeirloom()), getMiniTemplateURL(cn.types, cn.isLandscape())];
        }).reduce(function (a, b) {
            return a.concat(b);
        }, []), function () {
            gameBroadcaster.send(Events.GAME_MODEL_CREATED, _this);
            if (!isUndefined(_this.gameArea)) _this.gameArea.initialize();
        }, 15000);
    };

    self.reset = function () {
        _this.gameArea = undefined;
    };

    $rootScope.$on(Events.CLEANUP_GAME, function () {
        _this._isActive = false;
    });
}]);

function processPreferences(preferences) {
    shouldUseImmediateSendExceptions = !preferences.getUserPrefValue(UserPrefIds.CONFIRM_ACTIONS);
    shouldMoveSupplyOnOppositeTurns = preferences.getUserPrefValue(UserPrefIds.MOVE_KINGDOM);
    shouldUseAutobuy = preferences.getUserPrefValue(UserPrefIds.USE_AUTOBUY);
    animationType = preferences.getUserPrefValue(UserPrefIds.ANIMATION_TYPE);
    wayButtonsAreTransparent = preferences.getUserPrefValue(UserPrefIds.TRANSPARENT_WAY_BUTTON);
}