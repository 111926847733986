"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SelectionSet = function () {
    function SelectionSet(expansion, name, selection) {
        var imgPath = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;
        var displayName = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : undefined;
        var toolTipText = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : undefined;

        _classCallCheck(this, SelectionSet);

        this.expansion = expansion;
        this.name = name;
        this.selection = selection;
        this.imgPath = imgPath;

        this.displayName = displayName || this.name;

        // for the kingdom autocomplete we need card names and expansion names to be different
        // this is violated for Menagerie and 2 German cards (Venture --> Adventures, Reap --> Cornucopia)
        // so we slightly fudge the expansion name for the cases where it matters

        this.uniqueDisplayName = this.displayName;
        if (LANGUAGE === GERMAN) {
            if (this.expansion === Expansions.MENAGERIE || this.expansion === Expansions.ADVENTURES) {
                this.uniqueDisplayName += " (Erw.)";
            }
        }
        if (LANGUAGE === ENGLISH) {
            if (this.expansion === Expansions.MENAGERIE) {
                this.uniqueDisplayName += " (exp.)";
            }
        }
        if (LANGUAGE === RUSSIAN) {
            if (this.expansion === Expansions.MENAGERIE) {
                this.uniqueDisplayName += " (доп.)";
            }
        }

        this.toolTipText = toolTipText && toolTipText.replace(/ARGUMENT0/g, this.displayName);
    }

    _createClass(SelectionSet, null, [{
        key: "fromExpansion",
        value: function fromExpansion(expansion, cardService) {
            var expansionSymbol = "images/elements/" + stringDowncase(expansion.name.replace(/\s/g, '-')) + "-small-white.png";
            return new SelectionSet(expansion, expansion.name, cardService === null ? null : cardService.cards.filter(function (c) {
                return c.expansion === expansion;
            }), expansionSymbol, LANGUAGE.getExpansionName[expansion], LANGUAGE.getPhrase[Phrases.TOOLTIP_RANDOM_SELECTION]);
        }
    }]);

    return SelectionSet;
}();