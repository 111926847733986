"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CardStackView = function () {
    function CardStackView(cardStack) {
        _classCallCheck(this, CardStackView);

        this.cardStack = cardStack;

        this.canvas = document.createElement("div");
        this.rendered = false;

        this.artLayer = new ArtLayer(this.canvas, cardStack);
        this.backgroundLayer = new BackgroundLayer(this.canvas, cardStack);
        this.borderLayer = new BorderLayer(this.canvas, cardStack);
        this.coinsLayer = new CoinsLayer(this.canvas, cardStack);
        this.counterLayer = new CounterLayer(this.canvas, cardStack);
        this.iconLayer = new IconLayer(this.canvas, cardStack);
        this.nameLayer = new NameLayer(this.canvas, cardStack);
        this.premoveLayer = new PremoveLayer(this.canvas, cardStack);
        this.templateLayer = new TemplateLayer(this.canvas, cardStack);
        this.textLayer = new TextLayer(this.canvas, cardStack);
        this.tokenLayer = new TokenLayer(this.canvas, cardStack);
        this.typesLayer = new TypesLayer(this.canvas, cardStack);
        this.wayButtonLayer = new WayButtonLayer(this.canvas, cardStack);

        this.layers = [this.backgroundLayer, this.artLayer, this.templateLayer, this.iconLayer, this.textLayer, this.typesLayer, this.nameLayer, this.coinsLayer, this.tokenLayer, this.wayButtonLayer, this.borderLayer, this.premoveLayer, this.counterLayer];

        this.dragHandler = null;
        this.studyTimeout = null;

        this.bbox = new Rectangle(0, 0, 0, 0);
        if (!isUndefined(this.cardStack.renderNode)) {
            this.render(this.cardStack.renderNode);
        }
        this.redrawAll();
    }

    _createClass(CardStackView, [{
        key: "unload",
        value: function unload() {
            delete this.cardStack.view;
        }
    }, {
        key: "retarget",
        value: function retarget(cardStack) {
            this.cardStack = cardStack;
            this.layers.forEach(function (l) {
                return l.cardStack = cardStack;
            });
            this.positionCanvas();
        }
    }, {
        key: "destroy",
        value: function destroy() {
            this.tokenLayer.destroy();
            this.canvas.remove();
            this.unload();
        }
    }, {
        key: "onclick",
        value: function onclick(event) {
            var w = this.bbox.width;
            var h = this.bbox.height;
            var wayButtonBbox = new Rectangle(0.21 * w, 0.57 * h, 0.58 * w, 0.27 * h);

            var card = this.cardStack.getClickableCard();
            if (card !== null) {
                var wayQuestion = card.question.getBundledQuestion(QuestionTypes.WAY);

                if (wayQuestion !== null && wayButtonBbox.contains(event.offsetX, event.offsetY)) {
                    wayQuestion.answer(0);
                }
                card.question.answer(card.answer);
            } else {
                if (this.cardStack.canPremove) {
                    var _wayButtonBbox = new Rectangle(0.21 * w, 0.57 * h, 0.58 * w, 0.27 * h);
                    var isWayPremove = _wayButtonBbox.contains(event.clientX - this.canvas.clientX, event.clientY - this.canvas.clientY);
                    this.cardStack.game.questionModel.premove(this.cardStack, 0, isWayPremove);
                }
            }
        }
    }, {
        key: "onmousemove",
        value: function onmousemove(event) {
            var w = this.bbox.width;
            var h = this.bbox.height;
            var wayButtonBbox = new Rectangle(0.21 * w, 0.57 * h, 0.58 * w, 0.27 * h);
            this.borderLayer.isWayHovered = false;
            this.wayButtonLayer.isHovered = false;
            var card = this.cardStack.getClickableCard();
            if (card !== null) {
                var wayQuestion = card.question.getBundledQuestion(QuestionTypes.WAY);
                if (wayQuestion !== null && wayQuestion.max > 0 && card.cardName.isAction() && wayButtonBbox.contains(event.offsetX, event.offsetY)) {
                    this.borderLayer.isWayHovered = true;
                    this.wayButtonLayer.isHovered = true;
                }
            }
            this.borderLayer.redraw(this.width, this.height);
            this.wayButtonLayer.redraw(this.width, this.height);
        }
    }, {
        key: "onmousedown",
        value: function onmousedown(clientX, clientY) {
            var _this = this;

            var isTouchStart = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

            this.cancelNextClick = false;
            if (this.dragHandler !== null) {
                this.dragHandler(clientX - this.bbox.x, clientY - this.bbox.y);
            } else {
                if (this.studyTimeout === null) {
                    var dropListener = function dropListener() {
                        _cancelDropListener();
                        if (_this.studyTimeout !== null) {
                            window.clearTimeout(_this.studyTimeout);
                            _this.studyTimeout = null;
                        }
                    };
                    var _cancelDropListener = function _cancelDropListener() {
                        document.removeEventListener("mouseup", dropListener);
                        document.removeEventListener("touchend", dropListener);
                        document.removeEventListener("touchcancel", dropListener);
                    };
                    var studyAfterLongPress = function studyAfterLongPress() {
                        _cancelDropListener();
                        _this.cancelNextClick = true;
                        _this.studyTimeout = null;
                        if (_this.cardStack.location !== null) _this.cardStack.location.state.game.studyModel.study(_this.cardStack.topCard.cardName);
                    };
                    if (isTouchStart) {
                        this.studyTimeout = setTimeout(studyAfterLongPress, 500);
                        document.addEventListener("touchend", dropListener);
                        document.addEventListener("touchcancel", dropListener);
                        document.addEventListener("mouseup", dropListener);
                    }
                }
            }
        }
    }, {
        key: "render",
        value: function render(node) {
            var _this2 = this;

            if (this.rendered) return;
            this.rendered = true;
            node.appendChild(this.canvas);
            if (this.cardStack.destroyed || this.canvas === null) {
                // This could happen asychronously, from the above
                return;
            }
            this.renderNode = node;
            this.canvas.onclick = function (event) {
                _this2.onclick(event);
            };
            this.canvas.onmousemove = this.onmousemove.bind(this);
            this.canvas.onmouseover = function () {
                _this2.cardStack.isHovered = true;
                _this2.borderLayer.isHovered = true;
                _this2.borderLayer.redraw(_this2.width, _this2.height);
            };
            this.canvas.onmouseout = function () {
                _this2.cardStack.isHovered = false;
                _this2.borderLayer.isHovered = false;
                _this2.borderLayer.redraw(_this2.width, _this2.height);
            };
            this.canvas.oncontextmenu = function (event) {
                event.preventDefault();
                _this2.cardStack.location.state.game.studyModel.study(_this2.cardStack.topCard.cardName);
            };
            this.canvas.onmousedown = function (event) {
                if (event.button === 0) _this2.onmousedown(event.clientX, event.clientY, false);
            };
            this.canvas.ontouchstart = function (e) {
                return _this2.onmousedown(e.touches[0].clientX, e.touches[0].clientY, true);
            };
        }
    }, {
        key: "positionCanvas",
        value: function positionCanvas() {
            if (this.cardStack.destroyed || this.canvas === null) {
                return;
            }
            this.canvas.style.position = "fixed";
            this.canvas.style.left = 0;
            this.canvas.style.top = 0;
            this.canvas.style.width = this.bbox.width + "px";
            this.canvas.style.height = this.bbox.height + "px";
            this.canvas.style.zIndex = this.bbox.zIndex;
            this.canvas.style.opacity = this.bbox.opacity;
            this.canvas.style.display = this.cardStack.visible ? "initial" : "none";

            var scaleX = Math.cos(this.bbox.rotY / 180 * Math.PI);
            this.canvas.style.transform = "translateX(" + this.bbox.x + "px) translateY(" + this.bbox.y + "px) rotateZ(" + this.bbox.rotZ + "deg) scaleX(" + scaleX + ")";
            if (this.cardStack instanceof AnonymousCardStack) {
                this.canvas.style.left = this.bbox.x + "px";
                this.canvas.style.top = this.bbox.y + "px";
                this.canvas.style.position = this.cardStack.hasFilter("relative-position") ? "relative" : "absolute";
                this.canvas.style.transform = "rotateZ(" + this.bbox.rotZ + "deg) scaleX(" + scaleX + ")";
            }

            this.redrawAll();
        }
    }, {
        key: "updateCounter",
        value: function updateCounter() {
            this.counterLayer.redraw(this.width, this.height);
        }
    }, {
        key: "updateCard",
        value: function updateCard() {
            this.backgroundLayer.redraw(this.width, this.height);
            this.artLayer.redraw(this.width, this.height);
            this.templateLayer.redraw(this.width, this.height);
            this.iconLayer.redraw(this.width, this.height);
            this.textLayer.redraw(this.width, this.height);
            this.typesLayer.redraw(this.width, this.height);
            this.nameLayer.redraw(this.width, this.height);
            this.coinsLayer.redraw(this.width, this.height);
            this.updateAnswer();
        }
    }, {
        key: "updateArt",
        value: function updateArt() {
            this.artLayer.redraw(this.width, this.height);
        }
    }, {
        key: "updateMarkers",
        value: function updateMarkers() {
            this.typesLayer.redraw(this.width, this.height);
        }
    }, {
        key: "updateAnswer",
        value: function updateAnswer() {
            this.borderLayer.redraw(this.width, this.height);
            this.wayButtonLayer.redraw(this.width, this.height);
            this.premoveLayer.redraw(this.width, this.height);
            if (this.cardStack.getClickableCard() === null && this.canvas.style.cursor === "pointer") this.canvas.style.cursor = "default";else if (this.cardStack.getClickableCard() !== null) this.canvas.style.cursor = "pointer";
        }
    }, {
        key: "updateCoins",
        value: function updateCoins() {
            this.coinsLayer.redraw(this.width, this.height);
        }
    }, {
        key: "updateTokens",
        value: function updateTokens() {
            this.tokenLayer.redraw(this.width, this.height);
        }
    }, {
        key: "updatePremoves",
        value: function updatePremoves() {
            this.premoveLayer.redraw(this.width, this.height);
        }
    }, {
        key: "filterChanged",
        value: function filterChanged() {
            if (this.cardStack.hasFilter("disabled")) {
                this.canvas.style.filter = "grayscale(100%)";
            } else {
                this.canvas.style.filter = "";
            }
            if (this.cardStack.hasFilter("unclickable")) {
                this.canvas.style.pointerEvents = "none";
            } else {
                this.canvas.style.pointerEvents = "all";
            }
            this.canvas.style.display = this.cardStack.visible ? "initial" : "none";
            this.redrawAll();
        }
    }, {
        key: "redrawAll",
        value: function redrawAll() {
            var _this3 = this;

            this.layers.forEach(function (l) {
                return l.redraw(_this3.width, _this3.height);
            });
            if (!isUndefined(this.canvas)) {
                if (this.cardStack.getClickableCard() === null && this.canvas.style.cursor === "pointer") this.canvas.style.cursor = "default";else if (this.cardStack.getClickableCard() !== null) this.canvas.style.cursor = "pointer";
            }
        }
    }, {
        key: "reposition",
        value: function reposition(x, y, w, h) {
            var zIndex = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
            var rotZ = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 0;
            var opacity = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : 1;
            var rotY = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : 0;

            var shouldReposition = this.bbox.x !== x || this.bbox.y !== y || this.bbox.width !== w || this.bbox.height !== h || this.bbox.zIndex !== zIndex || this.bbox.rotZ !== rotZ || this.bbox.opacity !== opacity || this.bbox.rotY !== rotY;

            this.bbox.x = x;
            this.bbox.y = y;
            this.bbox.width = w;
            this.bbox.height = h;
            this.bbox.zIndex = Math.floor(zIndex);
            this.bbox.rotZ = rotZ;
            this.bbox.opacity = Math.min(1, Math.max(0, opacity));
            this.bbox.rotY = rotY;

            if (shouldReposition) {
                this.positionCanvas();
            }
        }
    }, {
        key: "copyBbox",
        value: function copyBbox(_ref) {
            var x = _ref.x,
                y = _ref.y,
                width = _ref.width,
                height = _ref.height,
                zIndex = _ref.zIndex,
                rotZ = _ref.rotZ,
                opacity = _ref.opacity,
                rotY = _ref.rotY;

            this.reposition(x, y, width, height, zIndex, rotZ, opacity, rotY);
        }
    }, {
        key: "copyPosition",
        value: function copyPosition(targetView) {
            this.bbox.x = targetView.bbox.x;
            this.bbox.y = targetView.bbox.y;
            this.bbox.width = targetView.bbox.width;
            this.bbox.height = targetView.bbox.height;
            this.bbox.zIndex = targetView.bbox.zIndex;
            this.bbox.rotZ = targetView.bbox.rotZ;
            this.bbox.opacity = targetView.bbox.opacity;
            this.bbox.rotY = targetView.bbox.rotY;
            this.positionCanvas();
        }
    }, {
        key: "isBusy",
        get: function get() {
            return this.layers.some(function (l) {
                return l.redrawing;
            });
        }
    }, {
        key: "scaleFactor",
        get: function get() {
            return Math.max(2, window.devicePixelRatio);
        }
    }, {
        key: "width",
        get: function get() {
            return Math.max(this.bbox.width, this.bbox.height / this.cardStack.ratio);
        }
    }, {
        key: "height",
        get: function get() {
            return Math.max(this.bbox.height, this.bbox.width * this.cardStack.ratio);
        }
    }]);

    return CardStackView;
}();

var AuxillaryTypes = {
    SELECTED: "0",
    TRAIT: "1",
    SUPPLY_SHADOW: "2"
};

var CardStack = function () {
    function CardStack(stackCard, location) {
        var _this4 = this;

        var cardSize = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CardSizes.FULL;
        var parentStack = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

        _classCallCheck(this, CardStack);

        this.destroyed = false;
        this.parentStack = parentStack;
        this.auxillaryStacks = {};
        this.cards = [];
        this.filters = [];
        this.cardSize = cardSize;
        this.location = location;
        if (parentStack === null) this.location.cardStacks.push(this);

        this.getCounter = function () {
            var output = _this4.privateCards.length + _this4.anonymousCards;
            if (output < 2) return -1;else return output;
        };

        this.anonymousCards = 0;
        this.stackCard = stackCard;
        stackCard.addToStack(this);

        this.positioned = false;
        this.trackedAnimators = [];
        this.animator = null;

        this.updateDerivedProperties();
        this.view = new CardStackView(this);
        this.isHovered = false;

        this.createAttachedTraitStack();
    }

    _createClass(CardStack, [{
        key: "updateCount",
        value: function updateCount() {
            this.count = this.getCounter();
        }
    }, {
        key: "updateTopCard",
        value: function updateTopCard() {
            this.topCard = this.privateCards.length === 0 ? this.stackCard : this.privateCards[0];
        }
    }, {
        key: "forceTopCard",
        value: function forceTopCard(card) {
            var _this5 = this;

            var index = this.privateCards.indexOf(card);
            if (index > -1) {
                this.privateCards.splice(index, 1);
            } else if (this.anonymousCards > 0) {
                this.anonymousCards--;
            } else {
                this.privateCards.forEach(function (c) {
                    return c.removeFromStack(_this5);
                });
                this.privateCards = [];
            }
            this.privateCards.unshift(card);
            this.updateTopCard();
            this.updateCount();
        }
    }, {
        key: "updateDerivedProperties",
        value: function updateDerivedProperties() {
            this.updateCount();
            this.updateTopCard();
        }
    }, {
        key: "render",
        value: function render(node) {
            this.node = node;
            this.view.render(node);

            for (var idx in this.auxillaryStacks) {
                this.auxillaryStacks[idx].render(node);
            }
        }
    }, {
        key: "anonymize",
        value: function anonymize() {
            var _this6 = this;

            this.anonymousCards += this.privateCards.length;
            this.privateCards.forEach(function (c) {
                return c.removeFromStack(_this6);
            });
            this.privateCards = [];
            this.updateTopCard();
        }
    }, {
        key: "destroy",
        value: function destroy() {
            var _this7 = this;

            var replacement = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

            if (replacement !== null && replacement.destroyed) console.error("retargeting to a destroyed stack");
            for (var key in this.auxillaryStacks) {
                this.auxillaryStacks[key].destroy();
            }if (this.animator !== null) this.animator.interrupt();
            [].concat(_toConsumableArray(this.trackedAnimators)).forEach(function (a) {
                if (replacement !== null) a.retarget(replacement);else console.error("destroyed stack without replacement which had animators");
            });
            this.privateCards.forEach(function (c) {
                return c.removeFromStack(_this7);
            });
            if (!isUndefined(this.view)) {
                if (replacement !== null && (replacement.view.isBusy || replacement.hasFilter("intangible"))) {
                    this.view.copyPosition(replacement.view);
                    replacement.view.destroy();
                    replacement.view = this.view;
                    this.view.retarget(replacement);
                } else {
                    this.view.destroy();
                }
            }
            if (this.parentStack === null) this.location.removeStack(this);
            this.destroyed = true;

            this.destroyStack = new Error();
            this.destroyReplacement = replacement;
            delete this.view;
        }
    }, {
        key: "hasFilter",
        value: function hasFilter(filter) {
            return this.filters.includes(filter);
        }
    }, {
        key: "addFilter",
        value: function addFilter(filter) {
            if (this.filters.indexOf(filter) === -1) {
                this.filters.push(filter);
                this.view.filterChanged();

                if (filter === "shadowed-supply") {
                    if (this.parentStack === null && !this.auxillaryStacks[AuxillaryTypes.SUPPLY_SHADOW]) {
                        var stackCard = this.location.state.getAnonCard(CardNames.BACK);
                        var newStack = new CardStack(stackCard, this.location, CardSizes.MINI, this);
                        this.auxillaryStacks[AuxillaryTypes.SUPPLY_SHADOW] = newStack;
                    }
                }

                if (filter === "zone-hidden") {
                    Object.values(this.auxillaryStacks).forEach(function (stack) {
                        return stack.addFilter(filter);
                    });
                }
            }
        }
    }, {
        key: "removeFilter",
        value: function removeFilter(filter) {
            var index = this.filters.indexOf(filter);
            if (index > -1) {
                this.filters.splice(index, 1);
                this.view.filterChanged();

                if (filter === "shadowed-supply") {
                    if (this.parentStack === null && this.auxillaryStacks[AuxillaryTypes.SUPPLY_SHADOW]) {
                        this.auxillaryStacks[AuxillaryTypes.SUPPLY_SHADOW].destroy();
                        delete this.auxillaryStacks[AuxillaryTypes.SUPPLY_SHADOW];
                    }
                }
            }
            if (filter === "zone-hidden") {
                Object.values(this.auxillaryStacks).forEach(function (stack) {
                    return stack.removeFilter(filter);
                });
            }
        }
    }, {
        key: "interruptCurrentAnimator",
        value: function interruptCurrentAnimator() {
            if (this.animator !== null) this.animator.interrupt();
        }
    }, {
        key: "addCards",
        value: function addCards(cards) {
            var _this8 = this;

            cards.forEach(function (card) {
                _this8.privateCards.unshift(card);
                card.addToStack(_this8);
            });
            this.updateTopCard();
            this.updateCount();
        }
    }, {
        key: "addCard",
        value: function addCard(card) {
            this.addCards([card]);
        }
    }, {
        key: "appendCards",
        value: function appendCards(cards) {
            var _this9 = this;

            cards.forEach(function (card) {
                _this9.privateCards.push(card);
                card.addToStack(_this9);
            });
            this.updateTopCard();
            this.updateCount();
        }
    }, {
        key: "appendCard",
        value: function appendCard(card) {
            this.appendCards([card]);
        }
    }, {
        key: "removeCard",
        value: function removeCard(card) {
            var index = this.privateCards.indexOf(card);
            if (index > -1) {
                this.privateCards.splice(index, 1);
                card.removeFromStack(this);
                if (index === 0) this.updateTopCard();
                this.updateCount();
            } else {
                var auxStack = Object.values(this.auxillaryStacks).find(function (s) {
                    return s.cards.includes(card);
                });
                if (!isUndefined(auxStack)) auxStack.removeCard(card);else console.error("Removing card from a stack which doesn't contain it.", this.privateCards, card);
            }
        }
    }, {
        key: "removeCards",
        value: function removeCards(cards) {
            var _this10 = this;

            cards.forEach(function (card) {
                var index = _this10.privateCards.indexOf(card);
                if (index > -1) _this10.privateCards.splice(index, 1);else console.error("Removing card from a stack which doesn't contain it.");
                card.removeFromStack(_this10);
            });
            this.updateTopCard();
            this.updateCount();
        }
    }, {
        key: "addAnonymousCard",
        value: function addAnonymousCard() {
            this.anonymousCards++;
            this.updateCount();
        }
    }, {
        key: "counterChange",
        value: function counterChange() {
            this.view.updateCounter();
        }
    }, {
        key: "updateMarkers",
        value: function updateMarkers() {
            this.view.updateMarkers();
        }
    }, {
        key: "copyPosition",
        value: function copyPosition(targetStack) {
            this.view.copyPosition(targetStack.view);
        }
    }, {
        key: "repositionBbox",
        value: function repositionBbox(bbox) {
            this.reposition(bbox.x, bbox.y, bbox.width, bbox.height, bbox.zIndex, bbox.rotZ, bbox.opacity, bbox.rotY);
        }
    }, {
        key: "reposition",
        value: function reposition(x, y, w, h) {
            var zIndex = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
            var rotZ = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 0;
            var opacity = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : 1;
            var rotY = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : 0;

            this.positioned = true;
            var auxillaryOffsets = {};
            for (var key in this.auxillaryStacks) {
                auxillaryOffsets[key] = this.view.bbox.getOffset(this.auxillaryStacks[key].view.bbox);
            }
            this.view.reposition(x, y, w, h, zIndex, rotZ, opacity, rotY);
            for (var _key in this.auxillaryStacks) {
                this.auxillaryStacks[_key].view.copyBbox(this.view.bbox.offset(auxillaryOffsets[_key]));
                switch (_key) {
                    case AuxillaryTypes.TRAIT:
                        this.auxillaryStacks[_key].view.reposition(x + w * 0.02, y - h * 0.15, w * 0.96, w * 0.175, zIndex + 1);
                        break;
                    case AuxillaryTypes.SUPPLY_SHADOW:
                        this.auxillaryStacks[_key].view.reposition(x + w * 0.062, y + h * 0.062, w, h, zIndex - 10);
                        break;
                }
            };
        }
    }, {
        key: "linearlyPosition",
        value: function linearlyPosition(stackCount, stackIndex, height, margin, left, right, top, zIndex) {
            var centered = arguments.length > 8 && arguments[8] !== undefined ? arguments[8] : true;
            var shouldAnimate = arguments.length > 9 && arguments[9] !== undefined ? arguments[9] : false;

            var y = top;
            var w = height / this.ratio;
            var h = height;
            var z = Math.floor(zIndex + stackIndex);
            var x = computeX(left, right, w, margin, stackIndex, stackCount, centered);
            if (shouldAnimate && this.positioned && (this.animator === null || this.animator instanceof TransitionAnimator)) this.animator = new TransitionAnimator(this, absoluteTransitionScript(new Rectangle(x, y, w, h, z)), this, function () {});else this.reposition(x, y, w, h, z);
        }
    }, {
        key: "getClickableCard",
        value: function getClickableCard() {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = this.privateCards[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var card = _step.value;

                    if (card.question !== null && card.question.canAccept(card.answer)) {
                        return card;
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            if (this.privateCards.length === 0 && this.stackCard.question !== null && this.stackCard.question.canAccept(this.stackCard.answer)) {
                return this.stackCard;
            }
            return null;
        }
    }, {
        key: "updateAnswers",
        value: function updateAnswers(question) {
            if (this.destroyed) return;
            var selectedCards = this.privateCards.filter(function (c) {
                return c.isSelected;
            });
            if (this.parentStack === null && this.cards.length > 1) {
                if (selectedCards.length > 0 && selectedCards[0].question.max > 1) {
                    if (!(AuxillaryTypes.SELECTED in this.auxillaryStacks)) {
                        var newStack = new CardStack(this.stackCard, this.location, this.cardSize, this);
                        var _view$bbox = this.view.bbox,
                            x = _view$bbox.x,
                            y = _view$bbox.y,
                            width = _view$bbox.width,
                            height = _view$bbox.height,
                            zIndex = _view$bbox.zIndex,
                            rotZ = _view$bbox.rotZ;

                        newStack.view.copyBbox(this.view.bbox);
                        newStack.view.zIndex -= 1;
                        this.auxillaryStacks[AuxillaryTypes.SELECTED] = newStack;
                    }
                    var selectedStack = this.auxillaryStacks[AuxillaryTypes.SELECTED];;
                    selectedStack.removeFilter("intangible");
                    selectedStack.animator = new TransitionAnimator(selectedStack, selectedStackCreateScript(this), this, function () {});
                    selectedStack.addCards(selectedCards);
                    this.removeCards(selectedCards);
                    if (this.privateCards.length === 0) {
                        this.addFilter("disabled");
                    }
                }
            } else if (this.parentStack !== null) {
                this.parentStack.updateAnswers(question);
            }
            if (AuxillaryTypes.SELECTED in this.auxillaryStacks) {
                var _selectedStack = this.auxillaryStacks[AuxillaryTypes.SELECTED];
                var unselectedCards = _selectedStack.cards.filter(function (c) {
                    return !c.isSelected;
                });
                if (unselectedCards.length > 0) {
                    this.addCards(unselectedCards);
                    this.removeFilter("disabled");
                    _selectedStack.removeCards(unselectedCards);
                }
                if (_selectedStack.cards.length === 0) {
                    _selectedStack.animator = new TransitionAnimator(_selectedStack, simpleTrackedTransitionScript(new Rectangle(0, 0, 0, 0, -5)), this, function () {
                        if (!_selectedStack.destroyed) // destroyed
                            _selectedStack.addFilter("intangible");
                    });
                }
            }

            if (question !== null && !isUndefined(this.getClickableCard()) && question.story.questionId.showWindows) {
                if (this.location instanceof WindowedZone && !this.location.visible) {
                    this.location.show(question);
                }
            }
            if (!isUndefined(this.view)) this.view.updateAnswer();
        }
    }, {
        key: "updatePremoves",
        value: function updatePremoves() {
            if (!isUndefined(this.view)) this.view.updatePremoves();
        }
    }, {
        key: "redrawCoins",
        value: function redrawCoins() {
            if (!isUndefined(this.view)) this.view.updateCoins();
        }
    }, {
        key: "imageChanged",
        value: function imageChanged() {
            if (!isUndefined(this.view)) this.view.updateArt();
        }
    }, {
        key: "typesChanged",
        value: function typesChanged() {
            if (!isUndefined(this.view)) this.view.updateCard();
        }
    }, {
        key: "tokensChanged",
        value: function tokensChanged() {
            if (!isUndefined(this.view)) this.view.updateTokens();
        }
    }, {
        key: "cardStatusChanged",
        value: function cardStatusChanged() {
            var _this11 = this;

            if (!(this.location instanceof SupplyZone)) {
                var incompatibleCards = this.cards.filter(function (c) {
                    return !c.isCompatibleWith(_this11.topCard);
                });
                var newStacks = incompatibleCards.forEach(function (c) {
                    var stack = _this11.location.spliceCard(c);
                    var targetStack = _this11.location.addCard(c);
                    stack.animator = new Animator(stack, simpleTrackedTransitionScript(), targetStack, function () {});
                });
            }
        }
    }, {
        key: "createAttachedTraitStack",
        value: function createAttachedTraitStack() {
            if (this.parentStack === null && this.location.attachedTrait !== CardNames.BACK) {
                var stackCard = this.location.state.getAnonCard(this.location.attachedTrait);
                var newStack = new CardStack(stackCard, this.location, CardSizes.MICRO, this);
                this.auxillaryStacks[AuxillaryTypes.TRAIT] = newStack;
            }
        }
    }, {
        key: "premove",
        value: function premove(method) {
            var isWayPremove = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            this.game.questionModel.premove(this, method, isWayPremove);
        }
    }, {
        key: "cancelPremove",
        value: function cancelPremove() {
            this.game.questionModel.cancelFirstPremoveOfStack(this);
        }
    }, {
        key: "game",
        get: function get() {
            return this.location.state.game;
        }
    }, {
        key: "renderNode",
        get: function get() {
            if (!isUndefined(this.location.state.game.gameArea)) return this.location.state.game.gameArea.cardStacksArea;
        }
    }, {
        key: "cards",
        set: function set(list) {
            this.privateCards = list;
        },
        get: function get() {
            return this.privateCards.concat(Object.values(this.auxillaryStacks).map(function (s) {
                return s.cards;
            }).reduce(function (a, b) {
                return a.concat(b);
            }, []));
        }
    }, {
        key: "anonymousCards",
        set: function set(value) {
            this.privateAnonymousCards = value;
            this.updateCount();
        },
        get: function get() {
            return this.privateAnonymousCards;
        }
    }, {
        key: "count",
        set: function set(count) {
            this.privateCount = count;
            if (!isUndefined(this.view)) this.view.updateCounter();
        },
        get: function get() {
            return this.privateCount;
        }
    }, {
        key: "cardCount",
        get: function get() {
            return this.cards.length + this.anonymousCards;
        }
    }, {
        key: "topCard",
        set: function set(card) {
            this.privateTopCard = card;
            if (card !== this.stackCard) {
                var index = this.privateCards.indexOf(card);
                if (index > -1) this.privateCards.splice(index, 1);
                this.privateCards.unshift(card);
            }
            if (!isUndefined(this.view)) this.view.updateCard();
        },
        get: function get() {
            if (this.hasFilter("force-stack-card")) {
                return this.stackCard;
            }
            return this.privateTopCard;
        }
    }, {
        key: "ratio",
        get: function get() {
            return this.topCard.cardName.isLandscape() ? this.cardSize.landscapeRatio : this.cardSize.ratio;
        }
    }, {
        key: "visible",
        get: function get() {
            return (this.parentStack === null || this.parentStack.visible) && !(this.hasFilter("intangible") || this.hasFilter("zone-hidden"));
        }
    }, {
        key: "isHovered",
        get: function get() {
            return this.privateIsHovered;
        },
        set: function set(value) {
            this.privateIsHovered = value;
            this.location.onFocusChange(this, value);
        }
    }, {
        key: "canPremove",
        get: function get() {
            return !isUndefined(this.game) && this.game.optionsModel.getPreference(UserPrefIds.PREMOVES) && this.game.questionModel.openQuestions.length === 0 && this.game.state.players.some(function (p) {
                return p.isMe;
            }) && this.cards.length > 0;
        }
    }, {
        key: "canPremoveModes",
        get: function get() {
            var card = this.getClickableCard();
            var actionType = card === null ? Actions.NONE : card.question.type.actionType;
            return !isUndefined(this.game) && this.game.optionsModel.getPreference(UserPrefIds.PREMOVES) && this.location instanceof HandZone && this.location.owner.isMe && actionType === Actions.PLAY && this.game.state.players.some(function (p) {
                return p.isMe;
            }) && this.cards.length > 0;
        }
    }]);

    return CardStack;
}();