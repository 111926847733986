"use strict";

webclient.component("threeValuedButton", {
    bindings: {
        value: "=ngModel",
        label: "@"
    },
    controllerAs: "button",
    controller: function controller() {
        var button = this;
        button.toggle = function () {
            switch (button.value) {
                case TernaryField.NO:
                default:
                    button.value = TernaryField.YES;
                    break;
                case TernaryField.YES:
                    button.value = TernaryField.RANDOM;
                    break;
                case TernaryField.RANDOM:
                    button.value = TernaryField.NO;
                    break;
            }
            button.valuelabel = LANGUAGE.getTernaryFieldTexts[button.value];
        };
        button.valuelabel = LANGUAGE.getTernaryFieldTexts[button.value];
    },
    template: "<button type=\"button\" class=\"lobby-button toggle-button\" \n                    ng-click=\"button.toggle()\">\n                    {{::button.label}} {{button.valuelabel}}\n                </button>"
});