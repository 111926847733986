"use strict";

webclient.controller('ScorePanelController', ['$scope', 'meta', 'tableService', 'uiGridConstants', 'soundService', 'gameBroadcaster', function ($scope, meta, tableService, uiGridConstants, soundService, gameBroadcaster) {
    var scorePanel = this;

    var results = tableService.gameResult.playerResults;
    results.sort(function (a, b) {
        return a.rank - b.rank;
    });
    results.forEach(function (r) {
        return r.score.parts.sort(function (a, b) {
            return b.points - a.points;
        });
    });
    scorePanel.results = results;

    var myResults = results.filter(function (r) {
        return r.playerId === meta.model.me.id;
    });
    if (myResults.length === 0) return;
    var myResult = myResults[0];
    if (myResult.resignIndex > -1) {
        scorePanel.labelText = getPhrase(Phrases.YOU_RESIGNED);
        soundService.play(SOUNDS.GAME_OVER);
        return;
    }
    if (myResult.rank > 1) {
        scorePanel.labelText = getPhrase(Phrases.YOU_LOSE);
        soundService.play(SOUNDS.GAME_OVER);
    } else if (results.filter(function (r) {
        return r.rank === 1;
    }).length > 1) {
        scorePanel.labelText = getPhrase(Phrases.YOU_TIE);
        soundService.play(SOUNDS.WIN);
    } else {
        scorePanel.labelText = getPhrase(Phrases.YOU_WIN);
        soundService.play(SOUNDS.WIN);
    }
    safeDigest($scope);
}]);