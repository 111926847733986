"use strict";

var SpanishAutoPlays = {};

SpanishAutoPlays[AutoPlays.MOAT] = ["Nunca", "Siempre revelar"];
SpanishAutoPlays[AutoPlays.TRADER] = ["Nunca", "No reaccionar al ganar Platas"];
SpanishAutoPlays[AutoPlays.CROWN] = ["Nunca", "Auto-finalizar Acciones"];
SpanishAutoPlays[AutoPlays.CHANGELING] = ["Nunca", "Cuando sea conveniente", "Nunca intercambiar"];
SpanishAutoPlays[AutoPlays.URCHIN] = ["Nunca", "Nunca eliminar"];
SpanishAutoPlays[AutoPlays.MOUNTEBANK] = ["Nunca", "Siempre descartar"];
SpanishAutoPlays[AutoPlays.SETTLERS] = ["Nunca", "Siempre tomar Cobre"];
SpanishAutoPlays[AutoPlays.BUSTLING_VILLAGE] = ["Nunca", "Siempre tomar Colonos"];
SpanishAutoPlays[AutoPlays.TOURNAMENT] = ["Nunca", "Siempre revelar Provincia"];
SpanishAutoPlays[AutoPlays.PEASANT] = ["Nunca", "Intercambiar por defecto", "Mantener por defecto"];
SpanishAutoPlays[AutoPlays.SOLDIER] = ["Nunca", "Intercambiar por defecto", "Mantener por defecto"];
SpanishAutoPlays[AutoPlays.FUGITIVE] = ["Nunca", "Intercambiar por defecto", "Mantener por defecto"];
SpanishAutoPlays[AutoPlays.DISCIPLE] = ["Nunca", "Intercambiar por defecto", "Mantener por defecto"];
SpanishAutoPlays[AutoPlays.PAGE] = ["Nunca", "Intercambiar por defecto", "Mantener por defecto"];
SpanishAutoPlays[AutoPlays.TREASURE_HUNTER] = ["Nunca", "Intercambiar por defecto", "Mantener por defecto"];
SpanishAutoPlays[AutoPlays.WARRIOR] = ["Nunca", "Intercambiar por defecto", "Mantener por defecto"];
SpanishAutoPlays[AutoPlays.HERO] = ["Nunca", "Intercambiar por defecto", "Mantener por defecto"];
SpanishAutoPlays[AutoPlays.FAITHFUL_HOUND] = ["Nunca", "Siempre apartar"];
SpanishAutoPlays[AutoPlays.YOUNG_WITCH] = ["Nunca", "Siempre revelar Perdición"];
SpanishAutoPlays[AutoPlays.TREASURY] = ["Nunca", "Siempre poner sobre el mazo"];
SpanishAutoPlays[AutoPlays.ALCHEMIST] = ["Nunca", "Siempre poner sobre el mazo"];
SpanishAutoPlays[AutoPlays.WALLED_VILLAGE] = ["Nunca", "Siempre poner sobre el mazo"];
SpanishAutoPlays[AutoPlays.HERBALIST] = ["Nunca", "En cualquier orden"];
SpanishAutoPlays[AutoPlays.ROYAL_SEAL] = ["Nunca", "Siempre poner sobre el mazo", "Nunca poner sobre el mazo"];
SpanishAutoPlays[AutoPlays.COIN_OF_THE_REALM] = ["Nunca", "Cuando sea conveniente"];
SpanishAutoPlays[AutoPlays.BORDER_GUARD] = ["Nunca", "Siempre poner sobre el mazo"];
SpanishAutoPlays[AutoPlays.PROVINCE] = ["Nunca", "Mantener malas cartas en el Exilio"];
SpanishAutoPlays[AutoPlays.CHAMPION] = ["Nunca", "Reconsiderar Sendas cuando sea conveniente"];
SpanishAutoPlays[AutoPlays.WAY_OF_THE_BUTTERFLY] = ["Nunca", "Devolver cuando sea conveniente"];
SpanishAutoPlays[AutoPlays.DESPERATION] = ["Nunca", "Siempre ganar Maldición"];
SpanishAutoPlays[AutoPlays.TORTURER] = ["Nunca", "No descartar innecesariamente"];
SpanishAutoPlays[AutoPlays.ARENA] = ["Nunca", "No descartar innecesariamente"];
SpanishAutoPlays[AutoPlays.PAGEANT] = ["Nunca", "Siempre tomar Cofres"];
SpanishAutoPlays[AutoPlays.OLD_WITCH] = ["Nunca", "Siempre eliminar Maldición"];
SpanishAutoPlays[AutoPlays.SINISTER_PLOT] = ["Nunca", "Nunca robar cero Cartas"];
SpanishAutoPlays[AutoPlays.MONEYLENDER] = ["Nunca", "Siempre eliminar Cobre"];
SpanishAutoPlays[AutoPlays.ENCAMPMENT] = ["Off", "Smart Reveal"];
SpanishAutoPlays[AutoPlays.DUPLICATE] = ["Off", "Only gainable Cards", "Also no Curses"];