"use strict";

function Country(code) {
    var self = this;
    self.code = code;
    self.uuid = generateUUID();
    self.name = function () {
        return LANGUAGE.getCountry[self];
    };
    self.toString = function () {
        return self.uuid;
    };
}

function getCountryByCode(countryCode) {
    var country = getValues(Countries).find(function (country) {
        return country.code === countryCode;
    });
    if (country) return country;
    return Countries.NON_EU;
}

function getCountryByName(countryName) {
    var country = getValues(Countries).find(function (country) {
        return country.name() === countryName;
    });
    if (country) return country;
    return Countries.NON_EU;
}

var Countries = {
    NON_EU: new Country(""),
    AT: new Country("AT"),
    BE: new Country("BE"),
    BG: new Country("bg"),
    HR: new Country("HR"),
    CY: new Country("CY"),
    CZ: new Country("CZ"),
    DK: new Country("DK"),
    EE: new Country("EE"),
    FI: new Country("FI"),
    FR: new Country("FR"),
    DE: new Country("DE"),
    GR: new Country("GR"),
    HU: new Country("HU"),
    IE: new Country("IE"),
    IT: new Country("IT"),
    LV: new Country("LV"),
    LT: new Country("LT"),
    LU: new Country("LU"),
    MT: new Country("MT"),
    NL: new Country("NL"),
    PL: new Country("PL"),
    PT: new Country("PT"),
    RO: new Country("RO"),
    SK: new Country("SK"),
    SI: new Country("SI"),
    ES: new Country("ES"),
    SE: new Country("SE")
};

var EU_COUNTRY_CODES = Object.keys(Countries).filter(function (code) {
    return code !== 'NON_EU';
});