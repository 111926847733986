"use strict";

var DutchEnvironment = new function () {
    this.enter = function () {};
    this.leave = function () {};
    this.strlen = EnglishEnvironment.strlen;

    this.getPileName = EnglishEnvironment.getPileName;
    this.pileNameSeparator = " ";

    this.phraseCollection_Or = function (phrases) {
        return EnglishEnvironment.phraseCollection(phrases, DutchPhrases[Phrases.COMMA], DutchPhrases[Phrases.OR]);
    };
}();