"use strict";

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

webclient.controller('cardlistController', ['$scope', 'metaBroadcaster', 'cardService', 'preferences', 'serverMessenger', function ($scope, metaBroadcaster, cardService, preferences, serverMessenger) {
    var self = this;

    self.lobbyButtons = LANGUAGE.getLobbyButtons;
    self.lobbyTexts = LANGUAGE.getLobbyTexts;
    self.selectedCards = [];
    self.selectedLandscapes = [];
    self.selectedExpansion = Expansions.BASE;
    self.cardlists = CardLists;
    self.cardObjects = cardService.cardObjects;
    self.cardSizeMini = CardSizes.MINI;

    var makeCardObject = function makeCardObject(name) {
        var newCard = new CardObject(-1, name, name);
        newCard.cardSize = CardSizes.MINI;
        newCard.dirty = true;
        return newCard;
    };

    self.onResize = function () {
        safeDigest($scope);
    };

    self.selectCardObjects = function (cards) {
        self.selectedCards = cards.filter(function (c) {
            return !c.isLandscape();
        }).map(makeCardObject);
        self.selectedLandscapes = cards.filter(function (c) {
            return c.isLandscape();
        }).map(makeCardObject);
        self.updateRemainingSlots();
    };

    self.selectList = function (listIndex) {
        if (self.selectedList === listIndex) self.selectedList = null;else self.selectedList = listIndex;

        if (self.selectedList !== null) {
            var userPref = preferences.getUserPreference(self.selectedList.userPrefId);
            if (userPref) {
                var cards = userPref.arguments.list;
                self.selectCardObjects(cards);
            }
        }
        safeDigest($scope);
        $scope.$$postDigest(function () {
            metaBroadcaster.send(Events.REDRAW);
        });
    };

    self.storeCardList = function () {
        var cardList = [].concat(_toConsumableArray(self.selectedCards));
        cardList.push.apply(cardList, _toConsumableArray(self.selectedLandscapes));
        var oldValue = preferences.getUserPrefValue(self.selectedList.userPrefId);
        oldValue.list = cardList;
        preferences.setUserPrefValue(self.selectedList.userPrefId, oldValue);
    };

    self.addCardObject = function (cardObject) {
        if (self.selectedList === null) return;
        if (cardObject.cardName.isLandscape()) {
            self.add(self.selectedLandscapes, cardObject, self.selectedList.maxLandscapes());
        } else {
            self.add(self.selectedCards, cardObject, self.selectedList.maxCards());
        }
    };

    self.add = function (list, item, max) {
        if (list.indexOf(item) === -1 && list.length < max) {
            list.push(item);
            self.updateRemainingSlots();
            self.storeCardList();
        }
    };

    self.removeCardObject = function (cardObject) {
        if (cardObject.cardName.isLandscape()) {
            self.remove(self.selectedLandscapes, cardObject);
        } else {
            self.remove(self.selectedCards, cardObject);
        }
    };

    self.remove = function (list, item) {
        var index = list.indexOf(item);
        if (index !== -1) {
            list.splice(index, 1);
            self.updateRemainingSlots();
            self.storeCardList();
        }
    };

    self.activeListHasCard = function (cardName) {
        return self.selectedCards.concat(self.selectedLandscapes).some(function (c) {
            return c.cardName === cardName;
        });
    };
    self.cardAdder = function (cardName) {
        if (!self.activeListHasCard(cardName)) self.addCardObject(makeCardObject(cardName));
    };

    self.updateRemainingSlots = function () {
        var chosenCards = self.selectedCards.length;
        var chosenLandscapes = self.selectedLandscapes.length;
        self.availableCardSlots = Array(self.selectedList.maxCards() - chosenCards).fill(undefined);
        self.availableLandscapeSlots = Array(self.selectedList.maxLandscapes() - chosenLandscapes).fill(undefined);
    };

    self.displayCard = function (card) {
        return self.selectedExpansion === undefined || card.cardName.expansion === self.selectedExpansion;
    };

    self.expansions = getValues(Expansions).filter(function (e) {
        return e.visible;
    });

    self.selectExpansion = function (expansion) {
        self.selectedExpansion = expansion === self.selectedExpansion ? undefined : expansion;
    };

    self.reloadCards = function () {
        self.cardObjects = cardService.getCardObjects();
        self.cards = self.cardObjects.filter(function (c) {
            return !c.isLandscape();
        }).map(makeCardObject);
        self.landscapes = self.cardObjects.filter(function (c) {
            return c.isLandscape();
        }).map(makeCardObject);
    };

    self.reloadCards();
    self.selectList(null);

    window.addEventListener(Events.RESIZE, function () {
        return self.onResize();
    });
}]);