"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Sound = function () {
    function Sound(name, type) {
        _classCallCheck(this, Sound);

        this.name = name;
        this.type = type;
    }

    _createClass(Sound, [{
        key: 'hasType',
        value: function hasType(type) {
            return this.type === type;
        }
    }, {
        key: 'toString',
        value: function toString() {
            return this.name;
        }
    }]);

    return Sound;
}();

var SOUNDS = {
    PING: new Sound('ping', SoundTypes.NOTIFICATION),
    WIN: new Sound('win', SoundTypes.FX),
    GAME_OVER: new Sound('game-over', SoundTypes.FX),
    MATCH_FOUND: new Sound('match-found', SoundTypes.NOTIFICATION),
    SHUFFLE: new Sound('shuffle', SoundTypes.FX),
    YOUR_TURN: new Sound('your-turn', SoundTypes.NOTIFICATION)
};