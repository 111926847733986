"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Shuffle = function (_GameStateChange) {
    _inherits(Shuffle, _GameStateChange);

    function Shuffle(game, owner, shouldIncludeDiscardPile) {
        _classCallCheck(this, Shuffle);

        var _this = _possibleConstructorReturn(this, (Shuffle.__proto__ || Object.getPrototypeOf(Shuffle)).call(this, game));

        _this.owner = owner;
        _this.shouldIncludeDiscardPile = shouldIncludeDiscardPile;
        return _this;
    }

    _createClass(Shuffle, [{
        key: "execute",
        value: function execute(game, terminator) {
            if (this.shouldIncludeDiscardPile) {
                var discard = game.state.playerService.players[this.owner].ownedZones.find(function (z) {
                    return z.zoneName instanceof DiscardZone;
                });
                var draw = game.state.playerService.players[this.owner].ownedZones.find(function (z) {
                    return z.zoneName instanceof DrawZone;
                });
                while (discard.cards.length + discard.anonymousCards > 0) {
                    discard.removeAnonymousCard();
                    draw.addAnonymousCard();
                }
            }
            terminator();
        }
    }, {
        key: "createAnimation",
        value: function createAnimation(game) {
            var draw = game.state.players[this.owner].ownedZones.find(function (z) {
                return z instanceof DrawZone;
            });
            return new PileAffectingAnimation(this, draw.index);
        }
    }], [{
        key: "parse",
        value: function parse(game, reader) {
            var owner = reader.readInt();
            var shouldIncludeDiscardPile = reader.readBoolean();
            return new Shuffle(game, owner, shouldIncludeDiscardPile);
        }
    }]);

    return Shuffle;
}(GameStateChange);