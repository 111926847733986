"use strict";

var RussianCardModes = {};

RussianCardModes[CardModes.PAWN_CARD_ACTION] = "Карта и Действие";
RussianCardModes[CardModes.PAWN_CARD] = "+1 Карта";
RussianCardModes[CardModes.PAWN_ACTION] = "+1 Действие";
RussianCardModes[CardModes.PAWN_BUY] = "+1 Покупка";
RussianCardModes[CardModes.PAWN_COIN] = "+1 Монета";
RussianCardModes[CardModes.STEWARD_CARDS] = "+2 Карты";
RussianCardModes[CardModes.STEWARD_COINS] = "+2 Монеты";
RussianCardModes[CardModes.STEWARD_TRASH] = "Выкинуть карты 2";
RussianCardModes[CardModes.MINION_COINS] = "+2 Монеты";
RussianCardModes[CardModes.MINION_NEWHAND] = "Сбросить";
RussianCardModes[CardModes.NOBLES_CARDS] = "+3 Карты";
RussianCardModes[CardModes.NOBLES_ACTIONS] = "+2 Действия";
RussianCardModes[CardModes.COURTIER_ACTION] = "+1 Действие";
RussianCardModes[CardModes.COURTIER_BUY] = "+1 Покупка";
RussianCardModes[CardModes.COURTIER_COINS] = "+3 Монеты";
RussianCardModes[CardModes.COURTIER_GOLD] = "Получить Золото";
RussianCardModes[CardModes.NATIVE_VILLAGE_SET_ASIDE] = "Отложить";
RussianCardModes[CardModes.NATIVE_VILLAGE_RETURN] = "Взять в руку";
RussianCardModes[CardModes.PIRATE_SHIP_RAID] = "Атака";
RussianCardModes[CardModes.PIRATE_SHIP_PROFIT] = "Монеты";
RussianCardModes[CardModes.SCRYING_POOL_TOPDECK] = "Положить наверх";
RussianCardModes[CardModes.SCRYING_POOL_DISCARD] = "Сбросить";
RussianCardModes[CardModes.LOAN_DISCARD] = "Сбросить";
RussianCardModes[CardModes.LOAN_TRASH] = "Выкинуть";
RussianCardModes[CardModes.TRUSTY_STEED_CARDS_ACTIONS] = "Карты и Действия";
RussianCardModes[CardModes.TRUSTY_STEED_CARDS] = "+2 Карты";
RussianCardModes[CardModes.TRUSTY_STEED_ACTIONS] = "+2 Действия";
RussianCardModes[CardModes.TRUSTY_STEED_COINS] = "+2 Монеты";
RussianCardModes[CardModes.TRUSTY_STEED_SILVERS] = "+4 Серебра";
RussianCardModes[CardModes.JESTER_THEY_GAIN] = "Противник получает";
RussianCardModes[CardModes.JESTER_YOU_GAIN] = "Вы получаете";
RussianCardModes[CardModes.DUCHESS_KEEP] = "Оставить";
RussianCardModes[CardModes.DUCHESS_DISCARD] = "Сбросить";
RussianCardModes[CardModes.ORACLE_TOPDECK] = "Положить наверх";
RussianCardModes[CardModes.ORACLE_DISCARD] = "Сбросить";
RussianCardModes[CardModes.JACK_KEEP] = "Оставить";
RussianCardModes[CardModes.JACK_DISCARD] = "Сбросить";
RussianCardModes[CardModes.SPICE_MERCHANT_CARDS] = "Карты, Действие";
RussianCardModes[CardModes.SPICE_MERCHANT_COINS] = "Монеты, Покупка";
RussianCardModes[CardModes.CATACOMBS_TAKE] = "Положить в руку";
RussianCardModes[CardModes.CATACOMBS_DISCARD] = "Сбросить";
RussianCardModes[CardModes.COUNT_DISCARD] = "Сбросить";
RussianCardModes[CardModes.COUNT_TOPDECK] = "Положить наверх";
RussianCardModes[CardModes.COUNT_GAIN_COPPER] = "Получить Медь";
RussianCardModes[CardModes.COUNT_COINS] = "+3 Монеты";
RussianCardModes[CardModes.COUNT_TRASH_HAND] = "Выкинуть руку";
RussianCardModes[CardModes.COUNT_GAIN_DUCHY] = "Получить Герцогство";
RussianCardModes[CardModes.HUNTING_GROUNDS_ESTATES] = "Получить 3 Поместья";
RussianCardModes[CardModes.HUNTING_GROUNDS_DUCHY] = "Получить Герцогство";
RussianCardModes[CardModes.IRONMONGER_KEEP] = "Оставить";
RussianCardModes[CardModes.IRONMONGER_DISCARD] = "Сбросить";
RussianCardModes[CardModes.SCAVENGER_DISCARD] = "Сбросить";
RussianCardModes[CardModes.SCAVENGER_KEEP] = "Продолжить";
RussianCardModes[CardModes.SQUIRE_ACTIONS] = "+2 Действия";
RussianCardModes[CardModes.SQUIRE_BUYS] = "+2 Покупки";
RussianCardModes[CardModes.SQUIRE_SILVER] = "Получить Серебро";
RussianCardModes[CardModes.MERCENARY_DONT_TRASH] = "Не выкидывать";
RussianCardModes[CardModes.DOCTOR_TRASH] = "Выкинуть";
RussianCardModes[CardModes.DOCTOR_DISCARD] = "Сбросить";
RussianCardModes[CardModes.DOCTOR_TOPDECK] = "Положить наверх";
RussianCardModes[CardModes.AMULET_COIN] = "+1 Монета";
RussianCardModes[CardModes.AMULET_TRASH] = "Выкинуть карту";
RussianCardModes[CardModes.AMULET_SILVER] = "Получить Серебро";
RussianCardModes[CardModes.MISER_COPPER_TO_MAT] = "Медь на Мат";
RussianCardModes[CardModes.MISER_PROFIT] = "Монеты";
RussianCardModes[CardModes.MESSENGER_DISCARD] = "Сбросить";
RussianCardModes[CardModes.MESSENGER_KEEP] = "Продолжить";
RussianCardModes[CardModes.QUEST_ATTACK] = "Атака";
RussianCardModes[CardModes.QUEST_CURSES] = "2 Проклятия";
RussianCardModes[CardModes.QUEST_CARDS] = "6 Карт";
RussianCardModes[CardModes.TEACHER_CARD] = "Карты";
RussianCardModes[CardModes.TEACHER_ACTION] = "Действия";
RussianCardModes[CardModes.TEACHER_BUY] = "Покупки";
RussianCardModes[CardModes.TEACHER_COIN] = "Монеты";
RussianCardModes[CardModes.LIBRARY_DRAW_IT] = "Оставить";
RussianCardModes[CardModes.LIBRARY_SKIP_IT] = "Пропустить";
RussianCardModes[CardModes.GLADIATOR_PRETEND] = "Не раскрывать";
RussianCardModes[CardModes.SPRAWLING_CASTLE_ESTATES] = "Получить 3 Поместья";
RussianCardModes[CardModes.SPRAWLING_CASTLE_DUCHY] = "Получить Герцогство";
RussianCardModes[CardModes.CHARM_BUY_MONEY] = "+Покупка, +2 Монеты";
RussianCardModes[CardModes.CHARM_COPY] = "Получить карту";
RussianCardModes[CardModes.WILD_HUNT_CARDS] = "Карты";
RussianCardModes[CardModes.WILD_HUNT_POINTS] = "Очки Победы";
RussianCardModes[CardModes.PRINCE_SET_ASIDE] = "Отложить в сторону";
RussianCardModes[CardModes.PRINCE_NO] = "Продолжить";
RussianCardModes[CardModes.HAMLET_ACTION] = "Сбросить за Действие";
RussianCardModes[CardModes.HAMLET_BUY] = "Сбросить за Покупку";
RussianCardModes[CardModes.HAMLET_BOTH] = "Сбросить две карты";
RussianCardModes[CardModes.GOVERNOR_CARDS] = "+3 Карты";
RussianCardModes[CardModes.GOVERNOR_TREASURE] = "Получить Золото";
RussianCardModes[CardModes.GOVERNOR_REMODEL] = "Улучшить карту";
RussianCardModes[CardModes.BLESSED_VILLAGE_NOW] = "Принять сейчас";
RussianCardModes[CardModes.BLESSED_VILLAGE_LATER] = "Следующий ход";
RussianCardModes[CardModes.PIXIE_TRASH] = "Выкинуть";
RussianCardModes[CardModes.PIXIE_KEEP] = "Оставить";
RussianCardModes[CardModes.SACRED_GROVE_RECEIVE] = "Получить Благодеяние";
RussianCardModes[CardModes.SACRED_GROVE_NO] = "Нет, спасибо";
RussianCardModes[CardModes.ZOMBIE_SPY_DISCARD] = "Сбросить";
RussianCardModes[CardModes.ZOMBIE_SPY_TOPDECK] = "Положить на верх";
RussianCardModes[CardModes.START_GAME] = "Начать Игру";
RussianCardModes[CardModes.PEARL_DIVER_TOPDECK] = RussianPhrases[Phrases.TOP];
RussianCardModes[CardModes.PEARL_DIVER_BOTTOMDECK] = RussianPhrases[Phrases.BOTTOM];
RussianCardModes[CardModes.SCEPTER_FAIL_ACTION] = "Ничего не делать";
RussianCardModes[CardModes.SCEPTER_ADD_COINS] = "+2 Монеты";
RussianCardModes[CardModes.TREASURER_FAIL] = "Не брать";
RussianCardModes[CardModes.TREASURER_KEY] = "Взять Ключ";
RussianCardModes[CardModes.BORDER_GUARD_LANTERN] = "Фонарь";
RussianCardModes[CardModes.BORDER_GUARD_HORN] = "Рог";
RussianCardModes[CardModes.BORDER_GUARD_FAIL] = "Не брать";
RussianCardModes[CardModes.PAGEANT_YES] = "+1 Монетка";
RussianCardModes[CardModes.PAGEANT_NO] = "Не брать";
RussianCardModes[CardModes.SINISTER_PLOT_ADD] = "Добавить жетон";
RussianCardModes[CardModes.SINISTER_PLOT_DRAW] = "Тянуть карты";
RussianCardModes[CardModes.PLAY] = "Сыграть";
RussianCardModes[CardModes.DONT_PLAY] = "Не играть";
RussianCardModes[CardModes.VILLAGE_GREEN_NOW] = "Сейчас";
RussianCardModes[CardModes.VILLAGE_GREEN_LATER] = "Следующий ход";
RussianCardModes[CardModes.BARGE_NOW] = "Сейчас";
RussianCardModes[CardModes.BARGE_LATER] = "Следующий ход";
RussianCardModes[CardModes.SCRAP_CARD] = "+1 Карта";
RussianCardModes[CardModes.SCRAP_ACTION] = "+1 Действие";
RussianCardModes[CardModes.SCRAP_BUY] = "+1 Покупка";
RussianCardModes[CardModes.SCRAP_COIN] = "+1 Монета";
RussianCardModes[CardModes.SCRAP_SILVER] = "Получите Серебро";
RussianCardModes[CardModes.SCRAP_HORSE] = "Получите Лошадь";
RussianCardModes[CardModes.MODE_OF_THE_BUTTERFLY] = "Бабочка";
RussianCardModes[CardModes.MODE_OF_THE_CAMEL] = "Верблюд";
RussianCardModes[CardModes.MODE_OF_THE_CHAMELEON] = "Хамелеон";
RussianCardModes[CardModes.MODE_OF_THE_FROG] = "Лягушка";
RussianCardModes[CardModes.MODE_OF_THE_GOAT] = "Козёл";
RussianCardModes[CardModes.MODE_OF_THE_HORSE] = "Лошадь";
RussianCardModes[CardModes.MODE_OF_THE_MOLE] = "Крот";
RussianCardModes[CardModes.MODE_OF_THE_MONKEY] = "Обезьяна";
RussianCardModes[CardModes.MODE_OF_THE_MOUSE] = "Мышь";
RussianCardModes[CardModes.MODE_OF_THE_MULE] = "Мул";
RussianCardModes[CardModes.MODE_OF_THE_OTTER] = "Выдра";
RussianCardModes[CardModes.MODE_OF_THE_OWL] = "Сова";
RussianCardModes[CardModes.MODE_OF_THE_OX] = "Бык";
RussianCardModes[CardModes.MODE_OF_THE_PIG] = "Свинья";
RussianCardModes[CardModes.MODE_OF_THE_RAT] = "Крыса";
RussianCardModes[CardModes.MODE_OF_THE_SEAL] = "Тюлень";
RussianCardModes[CardModes.MODE_OF_THE_SHEEP] = "Овца";
RussianCardModes[CardModes.MODE_OF_THE_SQUIRREL] = "Белка";
RussianCardModes[CardModes.MODE_OF_THE_TURTLE] = "Черепаха";
RussianCardModes[CardModes.MODE_OF_THE_WORM] = "Червь";
RussianCardModes[CardModes.BUTTERFLY_RETURN_IT] = "Вернуть";
RussianCardModes[CardModes.BUTTERFLY_DO_NOTHING] = "Ничего не делать";
RussianCardModes[CardModes.WAYFARER_SILVER] = "Получить Серебро";
RussianCardModes[CardModes.DESPERATION_CURSE] = "Получить Проклятие";
RussianCardModes[CardModes.CRYSTAL_BALL_TRASH] = "Выкинуть";
RussianCardModes[CardModes.CRYSTAL_BALL_DISCARD] = "Сбросить";
RussianCardModes[CardModes.CRYSTAL_BALL_PLAY] = "Сыграть";
RussianCardModes[CardModes.CRYSTAL_BALL_TOPDECK] = "На верх колоды";
RussianCardModes[CardModes.TOWN_VILLAGE] = "+1 Карта +2 Действия";
RussianCardModes[CardModes.TOWN_COINS_BUY] = "+1 Покупка +2 Монеты";
RussianCardModes[CardModes.MODIFY_CANTRIP] = "+1 Карта +1 Действие";
RussianCardModes[CardModes.MODIFY_GAIN] = "Получите карту";
RussianCardModes[CardModes.DESERT_GUIDES_KEEP] = "Оставить";
RussianCardModes[CardModes.DESERT_GUIDES_NEW_HAND] = "Новая рука";
RussianCardModes[CardModes.BAUBLE_BUY] = "+1 Покупка";
RussianCardModes[CardModes.BAUBLE_COIN] = "+1 Монета";
RussianCardModes[CardModes.BAUBLE_FAVOR] = "+1 Услуга";
RussianCardModes[CardModes.BAUBLE_TOPDECK] = "На верх колоды";
RussianCardModes[CardModes.BROKER_CARDS] = "+Карты";
RussianCardModes[CardModes.BROKER_ACTIONS] = "+Действия";
RussianCardModes[CardModes.BROKER_COINS] = "+Монеты";
RussianCardModes[CardModes.BROKER_FAVORS] = "+Услуги";
RussianCardModes[CardModes.INNKEEPER_CARD] = "+1 Карта";
RussianCardModes[CardModes.INNKEEPER_3] = "+3 Карты, сбросить 3";
RussianCardModes[CardModes.INNKEEPER_5_6] = "+5 Карт, сбросить 6";
RussianCardModes[CardModes.CAPITAL_CITY_DISCARD] = "Сбросить 2 за +$2";
RussianCardModes[CardModes.CAPITAL_CITY_PAY] = "$2 за +2 Карты";
RussianCardModes[CardModes.SPECIALIST_PLAY_AGAIN] = "Сыграть снова";
RussianCardModes[CardModes.SPECIALIST_GAIN_COPY] = "Получить копию";
RussianCardModes[CardModes.HILL_FORT_TO_HAND] = "Положить в руку";
RussianCardModes[CardModes.HILL_FORT_CANTRIP] = "+1 Карта +1 Действие";
RussianCardModes[CardModes.STRONGHOLD_COINS] = "+3 Монеты";
RussianCardModes[CardModes.STRONGHOLD_CARDS] = "следующий ход: +3 Карты";
RussianCardModes[CardModes.TOWN_CRIER_COINS] = "+2 Монеты";
RussianCardModes[CardModes.TOWN_CRIER_SILVER] = "Получить Серебро";
RussianCardModes[CardModes.TOWN_CRIER_CANTRIP] = "+1 Карта +1 Действие";
RussianCardModes[CardModes.BLACKSMITH_OWL] = "тяните до 6 карт в руке";
RussianCardModes[CardModes.BLACKSMITH_OTTER] = "+2 Карты";
RussianCardModes[CardModes.BLACKSMITH_PIG] = "+1 Карта +1 Действие";
RussianCardModes[CardModes.LURKER_TRASH] = "Выкинуть из Резерва";
RussianCardModes[CardModes.LURKER_GAIN] = "Получить со Свалки";
RussianCardModes[CardModes.GRAVEROBBER_GAIN] = "Получить на верх колоды";
RussianCardModes[CardModes.GRAVEROBBER_TRASH] = "Выкинуть из руки";
RussianCardModes[CardModes.TREASURER_TRASH] = "Выкинуть";
RussianCardModes[CardModes.TREASURER_GAIN] = "Получить со Свалки";
RussianCardModes[CardModes.INVESTMENT_COIN] = "+$1";
RussianCardModes[CardModes.INVESTMENT_VP] = "выкинуть за +VP";
RussianCardModes[CardModes.WEAVER_SILVERS] = "2 Серебра";
RussianCardModes[CardModes.CABIN_BOY_COINS] = "+2 Монеты";
RussianCardModes[CardModes.CABIN_BOY_GAIN] = "Выкинуть за Длительность";
RussianCardModes[CardModes.GONDOLA_NOW] = "+2 Монеты";
RussianCardModes[CardModes.GONDOLA_LATER] = "Следующий ход";
RussianCardModes[CardModes.QUARTERMASTER_GAIN] = "Получите карту";
RussianCardModes[CardModes.QUARTERMASTER_TAKE] = "Возьмите карту";
RussianCardModes[CardModes.AMPHORA_NOW] = "+1 Покупка, +3 Монеты";
RussianCardModes[CardModes.AMPHORA_LATER] = "Потом";
RussianCardModes[CardModes.SPELL_SCROLL_PLAY] = "Сыграть";
RussianCardModes[CardModes.SPELL_SCROLL_REFUSE] = "Не играть";
RussianCardModes[CardModes.COURSER_CARDS_ACTIONS] = "Карты и Действия";
RussianCardModes[CardModes.COURSER_CARDS] = "+2 Карты";
RussianCardModes[CardModes.COURSER_ACTIONS] = "+2 Действия";
RussianCardModes[CardModes.COURSER_COINS] = "+2 Монеты";
RussianCardModes[CardModes.COURSER_SILVERS] = "+4 Серебра";
RussianCardModes[CardModes.SICKNESS_FAIL_DISCARD] = "Сбросить карты";
RussianCardModes[CardModes.SICKNESS_CURSE] = "Проклятие на верх колоды";
RussianCardModes[CardModes.SICKNESS_FAIL_CURSE] = "Получить Проклятие";
RussianCardModes[CardModes.SICKNESS_DISCARD] = "Сбросить 3 карты";
RussianCardModes[CardModes.KITSUNE_ACTIONS] = "+2 Действия";
RussianCardModes[CardModes.KITSUNE_COINS] = "+2 Монеты";
RussianCardModes[CardModes.KITSUNE_CURSE] = "Дать Проклятия";
RussianCardModes[CardModes.KITSUNE_SILVER] = "Получить Серебро";

RussianCardModes[ExtraTurnTypes.MISSION] = RussianCardNames[CardNames.MISSION].singular;
RussianCardModes[ExtraTurnTypes.OUTPOST] = RussianCardNames[CardNames.OUTPOST].singular;
RussianCardModes[ExtraTurnTypes.POSSESSION] = RussianCardNames[CardNames.POSSESSION].singular;
RussianCardModes[ExtraTurnTypes.FLEET] = RussianCardNames[CardNames.FLEET].singular;
RussianCardModes[ExtraTurnTypes.SEIZE_THE_DAY] = RussianCardNames[CardNames.SEIZE_THE_DAY].singular;
RussianCardModes[ExtraTurnTypes.ISLAND_FOLK] = RussianCardNames[CardNames.ISLAND_FOLK].singular;
RussianCardModes[ExtraTurnTypes.VOYAGE] = RussianCardNames[CardNames.VOYAGE].singular;
RussianCardModes[ExtraTurnTypes.JOURNEY] = RussianCardNames[CardNames.JOURNEY].singular;