"use strict";

var EnglishAutoPlays = {};

EnglishAutoPlays[AutoPlays.MOAT] = ["Off", "Always reveal"];
EnglishAutoPlays[AutoPlays.TRADER] = ["Off", "Don't react to Silver gains"];
EnglishAutoPlays[AutoPlays.CROWN] = ["Off", "Auto-end actions"];
EnglishAutoPlays[AutoPlays.CHANGELING] = ["Off", "Smart ask", "Never exchange"];
EnglishAutoPlays[AutoPlays.URCHIN] = ["Off", "Never trash"];
EnglishAutoPlays[AutoPlays.MOUNTEBANK] = ["Off", "Always discard"];
EnglishAutoPlays[AutoPlays.SETTLERS] = ["Off", "Always take Copper"];
EnglishAutoPlays[AutoPlays.BUSTLING_VILLAGE] = ["Off", "Always take Settlers"];
EnglishAutoPlays[AutoPlays.TOURNAMENT] = ["Off", "Always reveal Province"];
EnglishAutoPlays[AutoPlays.PEASANT] = ["Off", "Default exchange", "Default keep"];
EnglishAutoPlays[AutoPlays.SOLDIER] = ["Off", "Default exchange", "Default keep"];
EnglishAutoPlays[AutoPlays.FUGITIVE] = ["Off", "Default exchange", "Default keep"];
EnglishAutoPlays[AutoPlays.DISCIPLE] = ["Off", "Default exchange", "Default keep"];
EnglishAutoPlays[AutoPlays.PAGE] = ["Off", "Default exchange", "Default keep"];
EnglishAutoPlays[AutoPlays.TREASURE_HUNTER] = ["Off", "Default exchange", "Default keep"];
EnglishAutoPlays[AutoPlays.WARRIOR] = ["Off", "Default exchange", "Default keep"];
EnglishAutoPlays[AutoPlays.HERO] = ["Off", "Default exchange", "Default keep"];
EnglishAutoPlays[AutoPlays.FAITHFUL_HOUND] = ["Off", "Always set aside"];
EnglishAutoPlays[AutoPlays.YOUNG_WITCH] = ["Off", "Always reveal bane"];
EnglishAutoPlays[AutoPlays.TREASURY] = ["Off", "Always topdeck"];
EnglishAutoPlays[AutoPlays.ALCHEMIST] = ["Off", "Always topdeck"];
EnglishAutoPlays[AutoPlays.WALLED_VILLAGE] = ["Off", "Always topdeck"];
EnglishAutoPlays[AutoPlays.HERBALIST] = ["Off", "Any order"];
EnglishAutoPlays[AutoPlays.ROYAL_SEAL] = ["Off", "Always topdeck", "Never topdeck"];
EnglishAutoPlays[AutoPlays.COIN_OF_THE_REALM] = ["Off", "Smart ask"];
EnglishAutoPlays[AutoPlays.BORDER_GUARD] = ["Off", "Always topdeck"];
EnglishAutoPlays[AutoPlays.PROVINCE] = ["Off", "Keep bad cards in Exile"];
EnglishAutoPlays[AutoPlays.CHAMPION] = ["Off", "Smart reconsider Ways"];
EnglishAutoPlays[AutoPlays.WAY_OF_THE_BUTTERFLY] = ["Off", "Smart return"];
EnglishAutoPlays[AutoPlays.DESPERATION] = ["Off", "Always gain Curse"];
EnglishAutoPlays[AutoPlays.TORTURER] = ["Off", "Don't discard unnecessarily"];
EnglishAutoPlays[AutoPlays.ARENA] = ["Off", "Don't discard unnecessarily"];
EnglishAutoPlays[AutoPlays.PAGEANT] = ["Off", "Always take Coffers"];
EnglishAutoPlays[AutoPlays.OLD_WITCH] = ["Off", "Always trash Curse"];
EnglishAutoPlays[AutoPlays.SINISTER_PLOT] = ["Off", "Never draw zero Cards"];
EnglishAutoPlays[AutoPlays.MONEYLENDER] = ["Off", "Always trash Copper"];
EnglishAutoPlays[AutoPlays.ENCAMPMENT] = ["Off", "Smart Reveal"];
EnglishAutoPlays[AutoPlays.DUPLICATE] = ["Off", "Only gainable Cards", "Also no Curses"];