"use strict";

webclient.controller('LobbyController', ['$rootScope', '$scope', 'meta', 'debounce', 'metaBroadcaster', 'tableService', 'serverMessenger', '$timeout', 'inboxService', 'leaderboardService', 'autoplayService', function ($rootScope, $scope, meta, debounce, metaBroadcaster, tableService, serverMessenger, $timeout, inboxService, leaderboardService, autoPlayService) {
    var lobby = this;
    var tabs = [];

    function setTabs() {
        var shouldLeaveTable = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        var b = tableService.hasTable() && !shouldLeaveTable;
        LobbyTabs.MY_TABLE.isVisible = b;
        LobbyTabs.NEW_TABLE.isVisible = !b;
        tabs = getValues(LobbyTabs).filter(function (t) {
            return t.isVisible;
        });
    }

    setTabs();

    function setActiveTab() {
        if (tableService.hasTable()) {
            timeLog("going to a table");
            lobby.tab = LobbyTabs.MY_TABLE;
        } else if (inboxService.hasNewMessages()) {
            timeLog("going to inbox");
            lobby.tab = LobbyTabs.INBOX;
        } else {
            timeLog("going to matching");
            lobby.tab = LobbyTabs.MATCH;
        }
    }

    $rootScope.$on(Events.OWNERSHIP_CHANGED, function (event, pref) {
        timeLog('lobby-controller.js OWNERSCHIP CHANGED received');
        lobby.tab = LobbyTabs.INBOX;
    });

    $rootScope.$on(Events.SHOW_SUBSCRIPTIONS, function (event, reason) {

        console.log('Lobby gets SHOW ACCOUNT event, show user account.');
        lobby.tab = LobbyTabs.ACCOUNT;
    });

    lobby.resizeDebounceID = generateUUID();

    window.addEventListener(Events.RESIZE, function () {

        debounce(lobby.resizeDebounceID, function () {});
    });

    lobby.getTabs = function () {
        return tabs;
    };

    lobby.clickTab = function (tab) {
        lobby.tab = tab;
        if (tab === LobbyTabs.MATCH) {
            serverMessenger.requestUpdate(UpdateTypes.AUTOMATCH);
        } else if (tab === LobbyTabs.TABLES) {
            serverMessenger.requestUpdate(UpdateTypes.TABLES);
        } else if (tab === LobbyTabs.FRIEND_LIST || tab === LobbyTabs.MY_TABLE) {
            serverMessenger.requestUpdate(UpdateTypes.FRIEND_LIST);
        } else if (tab === LobbyTabs.NEW_TABLE) {
            var rules = [];
            rules.push(new TableRule(TableRuleIds.MIN_PLAYERS, 2));
            rules.push(new TableRule(TableRuleIds.MAX_PLAYERS, 2));
            rules.push(new TableRule(TableRuleIds.USED_EXPANSIONS, -1, ObjectValues(Expansions).filter(function (e) {
                return e !== Expansions.DEPRECATED;
            })));
            serverMessenger.newTableRequest(rules);
        } else {
            serverMessenger.requestUpdate(UpdateTypes.NONE);
        }
        if (tab === LobbyTabs.LEADERBOARD) {
            serverMessenger.requestLeaderboard(20, true);
        }
        meta.broadcast(Events.TAB_CLICKED);
    };

    lobby.clickOptionsTab = function () {
        lobby.tab = LobbyTabs.OPTIONS;
    };

    lobby.showInbox = function () {
        return lobby.tab === LobbyTabs.INBOX;
    };
    lobby.showAutomatch = function () {
        return lobby.tab === LobbyTabs.MATCH;
    };
    lobby.showFriends = function () {
        return lobby.tab === LobbyTabs.FRIEND_LIST;
    };
    lobby.showTables = function () {
        return lobby.tab === LobbyTabs.TABLES;
    };
    lobby.showMyTable = function () {
        return lobby.tab === LobbyTabs.MY_TABLE;
    };
    lobby.showStore = function () {
        return lobby.tab === LobbyTabs.STORE;
    };
    lobby.showAccountInfo = function () {
        return lobby.tab === LobbyTabs.ACCOUNT;
    };
    lobby.showLeaderboard = function () {
        return lobby.tab === LobbyTabs.LEADERBOARD;
    };
    lobby.showCardlists = function () {
        return lobby.tab === LobbyTabs.CARDLISTS;
    };
    lobby.showOptionsTab = function () {
        return lobby.tab === LobbyTabs.OPTIONS;
    };
    lobby.autoplayService = autoPlayService;

    lobby.preferenceLabels = LANGUAGE.getUserPreferences;
    lobby.tabLabels = LANGUAGE.getTabLabels;
    lobby.tabLabelSize = LANGUAGE === RUSSIAN ? "font-size: 1.0vw" : "";

    lobby.showingPopup = false;
    lobby.popupclass = "";

    lobby.getInboxText = function () {
        return getPhrase(Phrases.INBOX);
    };
    lobby.getNeedHelpText = function () {
        return getPhrase(Phrases.NEED_HELP);
    };
    lobby.getLogoutText = function () {
        return getPhrase(Phrases.LOGOUT);
    };

    lobby.loadInbox = function () {
        lobby.tab = LobbyTabs.INBOX;
        inboxService.show();
    };
    lobby.loadForum = function () {
        window.open("http://forum.shuffleit.nl/index.php?board=13.0");
    };
    lobby.loadFaq = function () {
        window.open("http://forum.shuffleit.nl/index.php?topic=2246.0");
    };
    lobby.loadNewToDominion = function () {
        window.open("http://forum.shuffleit.nl/index.php?topic=2245.0");
    };
    lobby.logout = function () {
        return meta.broadcast(Events.LOGOUT);
    };

    $scope.$on(Events.GOTO_TAB, function (e, tab) {
        lobby.tab = tab;
        $scope.$digest();
    });

    $scope.$on(Events.SHOW_TABLE, function () {
        setTabs();
        lobby.tab = LobbyTabs.MY_TABLE;
        serverMessenger.requestUpdate(UpdateTypes.FRIEND_LIST);
        $scope.$digest();
    });

    $scope.$on(Events.PLAYER_LEFT, function (event, playerLeft) {
        if (!playerLeft.is(meta.model.me.id)) return;
        setTabs(true);
        lobby.tab = lobby.tab === LobbyTabs.MY_TABLE ? LobbyTabs.TABLES : lobby.tab;
        $scope.$digest();
    });

    lobby.shouldShowMenu = false;
    lobby.toggleMenu = function () {
        lobby.shouldShowMenu = !lobby.shouldShowMenu;
    };

    $scope.$on(Events.LOBBY_PAGE_LOADED, function () {
        return setActiveTab();
    });

    $timeout(function () {
        return serverMessenger.requestUpdate(UpdateTypes.AUTOMATCH);
    });
}]);