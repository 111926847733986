"use strict";

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function LoginSuccess(playerId, username, isReconnecting, previousReconnects, sessionId, numberOfUnreadMails, ipCountry, cardLevelMap) {
    var self = this;
    self.playerId = playerId;
    self.username = username;
    self.isReconnecting = isReconnecting;
    self.previousReconnects = previousReconnects;
    self.sessionId = sessionId;
    self.numberOfUnreadMails = numberOfUnreadMails;
    self.ipCountry = ipCountry;
    self.cardLevelMap = cardLevelMap;
}

function Receiver(type, id) {
    var self = this;
    self.type = type;
    self.id = id;
}

function Sender(type, id) {
    var self = this;
    self.type = type;
    self.id = id;
}

var ChatMessage = function () {
    function ChatMessage(receiver, sender, message) {
        _classCallCheck(this, ChatMessage);

        this.receiver = receiver;
        this.sender = sender;
        this.message = message;
    }

    _createClass(ChatMessage, null, [{
        key: "parse",
        value: function parse(reader) {
            var receiver = reader.readString();
            var sender = reader.readString();
            var message = reader.readString();
            return new ChatMessage(receiver, sender, message);
        }
    }]);

    return ChatMessage;
}();

var Invite = function () {
    function Invite(messageId, receiver, sender, tableId) {
        _classCallCheck(this, Invite);

        this.messageId = messageId;
        this.receiver = receiver;
        this.sender = sender;
        this.tableId = tableId;
    }

    _createClass(Invite, null, [{
        key: "parse",
        value: function parse(reader) {
            var messageId = reader.readLong();
            var receiver = reader.readString();
            var sender = reader.readString();
            var tableId = reader.readLong();
            return new Invite(messageId, receiver, sender, tableId);
        }
    }]);

    return Invite;
}();

var TableSummary = function () {
    function TableSummary(tableId, namedId, players, bots, minPlayers, maxPlayers, spectators, isObservable, isJoinable, status, startTime) {
        _classCallCheck(this, TableSummary);

        this.tableId = tableId;
        this.namedId = namedId;
        this.players = players;
        this.bots = bots;
        this.minPlayers = minPlayers;
        this.maxPlayers = maxPlayers;
        this.spectators = spectators;
        this.isObservable = isObservable;
        this.isJoinable = isJoinable;
        this.status = status;
        this.startTime = startTime;
    }

    _createClass(TableSummary, [{
        key: "hasStarted",
        value: function hasStarted() {
            return this.status === TableStati.RUNNING;
        }
    }, {
        key: "isNew",
        value: function isNew() {
            return this.status === TableStati.NEW;
        }
    }, {
        key: "hasBots",
        value: function hasBots() {
            return this.bots > 0;
        }
    }, {
        key: "isSinglePlayer",
        value: function isSinglePlayer() {
            return this.maxPlayers === 1 && this.getNumberOfPlayers() < 2 || this.minPlayers === 1 && this.getNumberOfPlayers() === 1;
        }
    }, {
        key: "is2Player",
        value: function is2Player() {
            return this.minPlayers < 3 && this.maxPlayers > 1 && this.getNumberOfPlayers() < 3;
        }
    }, {
        key: "isMultiPlayer",
        value: function isMultiPlayer() {
            return this.maxPlayers > 2 && !(this.hasStarted() && this.getNumberOfPlayers() < 3);
        }
    }, {
        key: "getSeatString",
        value: function getSeatString() {
            var range = this.minPlayers < this.maxPlayers ? this.minPlayers + " - " + this.maxPlayers : this.minPlayers;
            var s = getPhrase(Phrases.PLAYERS_OF_TOTAL_PLAYERS);
            s = s.replace("NUM_PLAYERS", this.players + this.bots);
            s = s.replace("NUM_TOTAL_PLAYERS", range);
            return s;
        }
    }, {
        key: "getSpectators",
        value: function getSpectators() {
            return this.spectators;
        }
    }, {
        key: "getHostName",
        value: function getHostName() {
            return this.namedId.name;
        }
    }, {
        key: "getStatusOrdinal",
        value: function getStatusOrdinal() {
            return getOrdinal(TableStati, this.status);
        }
    }, {
        key: "getNumberOfPlayers",
        value: function getNumberOfPlayers() {
            return this.players + this.bots;
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var tableId = reader.readLong();
            var namedId = NamedId.parse(reader);
            var players = reader.readInt();
            var bots = reader.readInt();
            var spectators = reader.readInt();
            var minPlayers = reader.readInt();
            var maxPlayers = reader.readInt();
            var observable = reader.readBoolean();
            var joinable = reader.readBoolean();
            var status = getByOrdinal(TableStati, reader.readInt());
            var startTime = -1;
            if (status === TableStati.RUNNING) startTime = reader.readLong();
            return new TableSummary(tableId, namedId, players, bots, minPlayers, maxPlayers, spectators, observable, joinable, status, startTime);
        }
    }]);

    return TableSummary;
}();

var FriendActivity = function () {
    function FriendActivity(playerId, playerName, status, tableId) {
        _classCallCheck(this, FriendActivity);

        this.playerId = playerId;
        this.playerName = playerName;
        this.status = status;
        this.tableId = tableId;
    }

    _createClass(FriendActivity, [{
        key: "getStatusString",
        value: function getStatusString() {
            return LANGUAGE.getActivity[this.status];
        }
    }, {
        key: "shouldShowJoinButton",
        value: function shouldShowJoinButton() {
            return this.tableId !== -1;
        }
    }, {
        key: "getJoinButtonText",
        value: function getJoinButtonText() {
            return getPhrase(Phrases.JOIN);
        }
    }, {
        key: "equals",
        value: function equals(other) {
            return this.playerId === other.playerId && this.playerName === other.playerName && this.status === other.status && this.tableId === other.tableId;
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var playerId = reader.readInt();
            var playerName = reader.readString();
            var status = getByOrdinal(ActivityStati, reader.readInt());
            var tableId = reader.readLong();
            return new FriendActivity(playerId, playerName, status, tableId);
        }
    }]);

    return FriendActivity;
}();

function TableStateChange(type, change) {
    this.type = type;
    this.change = change;
}

var RuleChange = function () {
    function RuleChange(tableId, rule) {
        _classCallCheck(this, RuleChange);

        this.tableId = tableId;
        this.rule = rule;
    }

    _createClass(RuleChange, null, [{
        key: "parse",
        value: function parse(reader) {
            var tableId = reader.readLong();
            var rule = new TableRuleProcessor().process(reader);
            return new RuleChange(tableId, rule);
        }
    }]);

    return RuleChange;
}();

function TableRule(id, value) {
    var args = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

    var self = this;
    self.id = id;
    self.value = value;
    self.arguments = args;
}

var HostChange = function () {
    function HostChange(tableId, newHost) {
        _classCallCheck(this, HostChange);

        this.tableId = tableId;
        this.newHost = newHost;
    }

    _createClass(HostChange, null, [{
        key: "parse",
        value: function parse(reader) {
            var tableId = reader.readLong();
            var newHost = reader.readInt();
            return new HostChange(tableId, newHost);
        }
    }]);

    return HostChange;
}();

var PlayerJoined = function () {
    function PlayerJoined(tableId, playerId, playerName, isPlayer) {
        _classCallCheck(this, PlayerJoined);

        this.tableId = tableId;
        this.playerId = playerId;
        this.playerName = playerName;
        this.isPlayer = isPlayer;
    }

    _createClass(PlayerJoined, null, [{
        key: "parse",
        value: function parse(reader) {
            var tableId = reader.readLong();
            var playerId = reader.readInt();
            var playerName = reader.readString();
            var isPlayer = reader.readBoolean();
            return new PlayerJoined(tableId, playerId, playerName, isPlayer);
        }
    }]);

    return PlayerJoined;
}();

var PlayerDisconnected = function () {
    function PlayerDisconnected(tableId, namedId) {
        _classCallCheck(this, PlayerDisconnected);

        this.tableId = tableId;
        this.namedId = namedId;
    }

    _createClass(PlayerDisconnected, null, [{
        key: "parse",
        value: function parse(reader) {
            var tableId = reader.readLong();
            var namedId = NamedId.parse(reader);
            return new PlayerDisconnected(tableId, namedId);
        }
    }]);

    return PlayerDisconnected;
}();

var PlayerReconnected = function () {
    function PlayerReconnected(tableId, namedId) {
        _classCallCheck(this, PlayerReconnected);

        this.tableId = tableId;
        this.namedId = namedId;
    }

    _createClass(PlayerReconnected, null, [{
        key: "parse",
        value: function parse(reader) {
            var tableId = reader.readLong();
            var namedId = NamedId.parse(reader);
            return new PlayerReconnected(tableId, namedId);
        }
    }]);

    return PlayerReconnected;
}();

var PlayerLeft = function () {
    function PlayerLeft(tableId, playerId) {
        _classCallCheck(this, PlayerLeft);

        this.tableId = tableId;
        this.playerId = playerId;
    }

    _createClass(PlayerLeft, [{
        key: "is",
        value: function is(id) {
            return this.playerId === id;
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var tableId = reader.readLong();
            var playerId = reader.readInt();
            return new PlayerLeft(tableId, playerId);
        }
    }]);

    return PlayerLeft;
}();

var PlayerReplaced = function () {
    function PlayerReplaced(tableId, oldPlayer, newPlayer) {
        _classCallCheck(this, PlayerReplaced);

        this.tableId = tableId;
        this.oldPlayer = oldPlayer;
        this.newPlayer = newPlayer;
    }

    _createClass(PlayerReplaced, null, [{
        key: "parse",
        value: function parse(reader) {
            var tableId = reader.readLong();
            var oldPlayer = NamedId.parse(reader);
            var newPlayer = NamedId.parse(reader);
            return new PlayerReplaced(tableId, oldPlayer, newPlayer);
        }
    }]);

    return PlayerReplaced;
}();

var NewPlayerStatus = function () {
    function NewPlayerStatus(playerId, tableId, activityStatus) {
        _classCallCheck(this, NewPlayerStatus);

        this.playerId = playerId;
        this.tableId = tableId;
        this.activityStatus = activityStatus;
    }

    _createClass(NewPlayerStatus, null, [{
        key: "parse",
        value: function parse(reader) {
            var playerId = reader.readInt();
            var tableId = reader.readLong();
            var activityStatus = getByOrdinal(ActivityStati, reader.readInt());
            return new NewPlayerStatus(playerId, tableId, activityStatus);
        }
    }]);

    return NewPlayerStatus;
}();

function Slot(seatIndex, connectionSecret) {
    var self = this;
    self.seatIndex = seatIndex;
    self.connectionSecret = connectionSecret;
}

var GameStarted = function () {
    function GameStarted(tableId, gameId) {
        _classCallCheck(this, GameStarted);

        this.tableId = tableId;
        this.gameId = gameId;
    }

    _createClass(GameStarted, null, [{
        key: "parse",
        value: function parse(reader) {
            var tableId = reader.readLong();
            var gameId = reader.readLong();
            return new GameStarted(tableId, gameId);
        }
    }]);

    return GameStarted;
}();

var BonusCodeValidated = function () {
    function BonusCodeValidated(playerId, code, boughtExpansion, expansionList) {
        _classCallCheck(this, BonusCodeValidated);

        this.playerId = playerId;
        this.code = code;
        this.boughtExpansion = boughtExpansion;
        this.expansionList = expansionList;
    }

    _createClass(BonusCodeValidated, null, [{
        key: "parse",
        value: function parse(reader) {
            var playerId = reader.readInt();
            var code = reader.readString();
            var boughtExpansion = getByOrdinal(Expansions, reader.readInt());
            var expansionList = reader.readEnumArray(Expansions).map(function (e) {
                return e.name;
            });
            return new BonusCodeValidated(playerId, code, boughtExpansion, expansionList);
        }
    }]);

    return BonusCodeValidated;
}();

var PurchaseResult = function () {
    function PurchaseResult(paymentStatus) {
        _classCallCheck(this, PurchaseResult);

        this.paymentStatus = paymentStatus;
    }

    _createClass(PurchaseResult, [{
        key: "isSuccess",
        value: function isSuccess() {
            return this.paymentStatus === 0;
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var paymentStatus = reader.readInt();
            return new PurchaseResult(paymentStatus);
        }
    }]);

    return PurchaseResult;
}();

function TableDetails(tableId, hostId, players, readyToStart, bots, spectators, reconnectingPlayers, rules, changedRules) {
    var self = this;
    self.tableId = tableId;
    self.hostId = hostId;
    self.players = players.map(function (p) {
        return p.id;
    });
    self.playerNames = players.map(function (p) {
        return p.name;
    });
    self.playerNamedIds = players;
    self.spectatorNamedIds = spectators;
    self.readyToStart = readyToStart;
    self.bots = bots;
    self.spectators = spectators.map(function (s) {
        return s.id;
    });
    self.spectatorNames = spectators.map(function (s) {
        return s.name;
    });
    self.reconnectingPlayers = reconnectingPlayers.map(function (r) {
        return r.id;
    });
    self.reconnectingPlayerNames = reconnectingPlayers.map(function (r) {
        return r.name;
    });
    self.rules = rules;
    self.changedRules = changedRules;
    self.initialNumberOfChangedRules = changedRules.filter(function (e) {
        return e;
    }).length;

    self.containsPlayer = function (id) {
        return self.players.indexOf(id) > -1 || self.spectators.indexOf(id) > -1;
    };

    self.addPlayer = function (person) {
        if (self.players.indexOf(person.id) > -1) return;
        self.players.push(person.id);
        self.playerNames.push(person.name);
    };

    self.removePlayer = function (id) {
        var index = self.players.indexOf(id);
        if (index === -1) return;
        self.players.splice(index, 1);
        self.playerNames.splice(index, 1);
    };

    self.removeBot = function (id) {
        var index = self.bots.map(function (b) {
            return b.id;
        }).indexOf(id);
        if (index === -1) return;
        self.bots.splice(index, 1);
    };

    self.addSpectator = function (person) {
        if (self.spectators.indexOf(person.id) > -1) return;
        self.spectators.push(person.id);
        self.spectatorNames.push(person.name);
    };

    self.removeSpectator = function (id) {
        var index = self.spectators.indexOf(id);
        if (index === -1) return;
        self.spectators.splice(index, 1);
        self.spectatorNames.splice(index, 1);
    };

    self.setReady = function (id, isReady) {
        var i = self.players.indexOf(id);
        self.readyToStart[i] = isReady;
    };

    self.changeRule = function (rule) {
        var index = self.rules.map(function (r) {
            return r.id;
        }).indexOf(rule.id);
        self.rules[index] = rule;
        self.changedRules[index] = true;
    };

    self.processRoleChange = function (id, isPlayer) {
        if (isPlayer && self.players.indexOf(id) === -1) {
            self.addPlayer(new Person(id, self.getSpectatorNameById(id)));
            self.removeSpectator(id);
        }
        if (!isPlayer && self.spectators.indexOf(id) === -1) {
            self.addSpectator(new Person(id, self.getPlayerNameById(id)));
            self.removePlayer(id);
        }
    };

    self.hasChangedRules = function () {
        return self.changedRules.filter(function (e) {
            return e;
        }).length > self.initialNumberOfChangedRules;
    };
    self.isReady = function (id) {
        if (self.bots.some(function (b) {
            return b.id === id;
        })) return true;
        return self.readyToStart[self.players.indexOf(id)];
    };
    self.getRule = function (id) {
        return self.rules.find(function (r) {
            return r.id === id;
        });
    };
    self.isHost = function (id) {
        return self.hostId === id;
    };
    self.setHost = function (id) {
        return self.hostId = id;
    };
    self.isPlayer = function (id) {
        return self.players.indexOf(id) > -1;
    };
    self.isSpectator = function (id) {
        return self.spectators.indexOf(id) > -1;
    };
    self.getPlayerNameById = function (id) {
        return self.playerNames[self.players.indexOf(id)];
    };
    self.getReconnectingPlayerNameById = function (id) {
        return self.reconnectingPlayerNames[self.reconnectingPlayers.indexOf(id)];
    };
    self.getSpectatorNameById = function (id) {
        return self.spectatorNames[self.spectators.indexOf(id)];
    };
    self.openSeatCount = function () {
        return self.getRule(TableRuleIds.MAX_PLAYERS).value - self.getBeings().length;
    };
    self.hasOpenSeats = function () {
        return self.openSeatCount() > 0;
    };
    self.getUsernameById = function (id) {
        if (self.players.indexOf(id) > -1) return self.getPlayerNameById(id);
        if (self.spectators.indexOf(id) > -1) return self.getSpectatorNameById(id);
        return 'NOT_AT_TABLE';
    };
    self.getPlayers = function () {
        var namedIds = [];
        for (var i = 0; i < self.players.length; i++) {
            namedIds.push(new NamedId(self.players[i], self.playerNames[i]));
        }
        return namedIds;
    };
    self.getBeings = function () {
        return self.getPlayers().concat(bots);
    };
    self.addBot = function (namedId) {
        return self.bots.push(namedId);
    };
    self.isBot = function (id) {
        return self.bots.some(function (n) {
            return n.id === id;
        });
    };
    self.getHostName = function () {
        return self.getUsernameById(self.hostId);
    };
    self.isBotGame = function () {
        return self.bots.length > 0 && self.players.length === 1;
    };
}

var NewRole = function () {
    function NewRole(tableId, playerId, isPlayer, readyToStart) {
        _classCallCheck(this, NewRole);

        this.tableId = tableId;
        this.playerId = playerId;
        this.isPlayer = isPlayer;
        this.readyToStart = readyToStart;
    }

    _createClass(NewRole, null, [{
        key: "parse",
        value: function parse(reader) {
            var tableId = reader.readLong();
            var playerId = reader.readInt();
            var isPlayer = reader.readBoolean();
            var readyToStart = reader.readBoolean();
            return new NewRole(tableId, playerId, isPlayer, readyToStart);
        }
    }]);

    return NewRole;
}();

var ScorePart = function () {
    function ScorePart(cardName, points, frequency, explanation) {
        _classCallCheck(this, ScorePart);

        this.cardName = cardName;
        this.points = points;
        this.frequency = frequency;
        this.explanation = explanation;
    }

    _createClass(ScorePart, null, [{
        key: "parse",
        value: function parse(reader) {
            var cardName = CardName.parse(reader);
            var points = reader.readInt();
            var frequency = reader.readInt();
            var uselessIndex = reader.readInt();
            var explanation = LogEntry.parse(reader);
            return new ScorePart(cardName, points, frequency, explanation);
        }
    }]);

    return ScorePart;
}();

var Score = function () {
    function Score(totalPoints, usedTurns, parts) {
        _classCallCheck(this, Score);

        this.totalPoints = totalPoints;
        this.usedTurns = usedTurns;
        this.parts = parts;
    }

    _createClass(Score, null, [{
        key: "parse",
        value: function parse(reader) {
            var totalPoints = reader.readInt();
            var usedTurns = reader.readInt();
            var scoreParts = reader.readArrayOf(ScorePart);
            return new Score(totalPoints, usedTurns, scoreParts);
        }
    }]);

    return Score;
}();

var PlayerResult = function () {
    function PlayerResult(playerId, rank, score, cardNames, resignIndex) {
        _classCallCheck(this, PlayerResult);

        this.playerId = playerId;
        this.rank = rank;
        this.score = score;
        this.cardNames = cardNames;
        this.resignIndex = resignIndex;
    }

    _createClass(PlayerResult, null, [{
        key: "parse",
        value: function parse(reader) {
            var playerId = reader.readInt();
            var rank = reader.readInt();
            var score = Score.parse(reader);
            var cardNames = reader.readArrayOf(CardFrequency);
            var resignIndex = reader.readInt();
            return new PlayerResult(playerId, rank, score, cardNames, resignIndex);
        }
    }]);

    return PlayerResult;
}();

var GameResult = function () {
    function GameResult(tableId, gameId, emptyPiles, playerResults, autoContinue) {
        _classCallCheck(this, GameResult);

        this.tableId = tableId;
        this.gameId = gameId;
        this.emptyPiles = emptyPiles;
        this.playerResults = playerResults;
        this.autoContinue = autoContinue;
    }

    _createClass(GameResult, [{
        key: "anyResigningPlayerId",
        value: function anyResigningPlayerId() {
            var result = this.playerResults.find(function (r) {
                return r.resignIndex !== -1;
            });
            return isUndefined(result) ? undefined : result.playerId;
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var tableId = reader.readLong();
            var gameId = reader.readLong();
            var emptyPiles = reader.readArrayOf(CardName);
            var playerResults = reader.readArrayOf(PlayerResult);
            var autoContinue = reader.readBoolean();
            return new GameResult(tableId, gameId, emptyPiles, playerResults, autoContinue);
        }
    }]);

    return GameResult;
}();

var GameFinished = function () {
    function GameFinished(tableDetails, gameResult, continueAllowed) {
        _classCallCheck(this, GameFinished);

        this.tableDetails = tableDetails;
        this.gameResult = gameResult;
        this.continueAllowed = continueAllowed;
    }

    _createClass(GameFinished, null, [{
        key: "parse",
        value: function parse(reader) {
            var details = new TableDetailsProcessor().process(reader);
            var gameResult = GameResult.parse(reader);
            var continueAllowed = reader.readBoolean();
            return new GameFinished(details, gameResult, continueAllowed);
        }
    }]);

    return GameFinished;
}();

function UserPreference(id) {
    var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    var self = this;
    self.id = id;
    self.arguments = args;
}

function CardUniverse(date, expansions) {
    var self = this;
    self.date = date;
    self.dateobj = new Date(date);
    self.expansions = expansions;
}

var CardList = function () {
    function CardList(listId, list) {
        _classCallCheck(this, CardList);

        this.listId = listId;
        this.list = list;
    }

    _createClass(CardList, [{
        key: "serialize",
        value: function serialize(writer) {
            writer.writeInt(this.listId);
            writer.writeInts(this.list.map(function (c) {
                return getOrdinal(CardNames, c.cardName);
            }));
        }
    }]);

    return CardList;
}();

function OnlineProduct(storePackage, endDate, country) {
    var self = this;
    self.storePackage = storePackage;
    self.endDate = endDate;
    self.enddateobj = new Date(endDate);
    self.country = country;
}

function Purchase(onlineProduct, purchaseTime, prices) {
    var self = this;
    self.onlineProduct = onlineProduct;
    self.purchaseTime = purchaseTime;
    self.prices = prices;
    self.amountInEuroCents = prices.find(function (price) {
        return price.priceCategory === PriceCategories.TOTAL;
    }).amountInEuroCents;
}

var Price = function () {
    function Price(priceCategory, price) {
        _classCallCheck(this, Price);

        this.priceCategory = priceCategory;
        this.amountInEuroCents = price;
    }

    _createClass(Price, [{
        key: "inEuro",
        value: function inEuro() {

            return this.amountInEuroCents / 100;
        }
    }, {
        key: "euroString",
        value: function euroString() {
            var n = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 5;
            // Minimum 5: euro sign, one digit, comma, two digits.

            var euroSign = "\u20AC";
            var amount = this.inEuro().toFixed(2).toString();
            var string = String(amount);

            return euroSign + string.padStart(n - 1).toString();
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var priceCategory = getByOrdinal(PriceCategories, reader.readInt());
            var price = reader.readInt();
            return new Price(priceCategory, price);
        }
    }]);

    return Price;
}();

var NamedId = function () {
    function NamedId(id, name) {
        _classCallCheck(this, NamedId);

        this.id = id;
        this.name = name;
    }

    _createClass(NamedId, [{
        key: "serialize",
        value: function serialize(writer) {
            writer.writeInt(this.id);
            writer.writeString(this.name);
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var n = reader.readInt();
            var s = reader.readString();
            return new NamedId(n, s);
        }
    }]);

    return NamedId;
}();

var Group = function () {
    function Group(id) {
        _classCallCheck(this, Group);

        this.id = id;
    }

    _createClass(Group, null, [{
        key: "parse",
        value: function parse(reader) {
            var groupId = getByOrdinal(GroupIds, reader.readInt());
            return groupId.parse(reader);
        }
    }]);

    return Group;
}();

var Nobody = function (_Group) {
    _inherits(Nobody, _Group);

    function Nobody() {
        _classCallCheck(this, Nobody);

        return _possibleConstructorReturn(this, (Nobody.__proto__ || Object.getPrototypeOf(Nobody)).call(this, GroupIds.NOBODY));
    }

    _createClass(Nobody, null, [{
        key: "parse",
        value: function parse(reader) {
            return new Nobody();
        }
    }]);

    return Nobody;
}(Group);

var Everybody = function (_Group2) {
    _inherits(Everybody, _Group2);

    function Everybody() {
        _classCallCheck(this, Everybody);

        return _possibleConstructorReturn(this, (Everybody.__proto__ || Object.getPrototypeOf(Everybody)).call(this, GroupIds.EVERYBODY));
    }

    _createClass(Everybody, null, [{
        key: "parse",
        value: function parse(reader) {
            return new Everybody();
        }
    }]);

    return Everybody;
}(Group);

var FriendsOf = function (_Group3) {
    _inherits(FriendsOf, _Group3);

    function FriendsOf(namedId) {
        _classCallCheck(this, FriendsOf);

        var _this3 = _possibleConstructorReturn(this, (FriendsOf.__proto__ || Object.getPrototypeOf(FriendsOf)).call(this, GroupIds.FRIENDS_OF));

        _this3.namedId = namedId;
        return _this3;
    }

    _createClass(FriendsOf, [{
        key: "serialize",
        value: function serialize(writer) {
            writer.writeInt(getOrdinal(GroupIds, this.id));
            this.namedId.serialize(writer);
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            return new FriendsOf(NamedId.parse(reader));
        }
    }]);

    return FriendsOf;
}(Group);

var ListPlayerIds = function (_Group4) {
    _inherits(ListPlayerIds, _Group4);

    function ListPlayerIds(namedIds) {
        _classCallCheck(this, ListPlayerIds);

        var _this4 = _possibleConstructorReturn(this, (ListPlayerIds.__proto__ || Object.getPrototypeOf(ListPlayerIds)).call(this, GroupIds.LIST_PLAYER_IDS));

        _this4.namedIds = namedIds;
        return _this4;
    }

    _createClass(ListPlayerIds, [{
        key: "serialize",
        value: function serialize(writer) {
            writer.writeInt(getOrdinal(GroupIds, this.id));
            this.namedIds.forEach(function (id) {
                return id.serialize(writer);
            });
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            return new ListPlayerIds(reader.readArrayOf(NamedId));
        }
    }]);

    return ListPlayerIds;
}(Group);

var Relationships = function () {
    function Relationships(following, friends, followers, blacklisted) {
        _classCallCheck(this, Relationships);

        this.following = following;
        this.friends = friends;
        this.followers = followers;
        this.blacklisted = blacklisted;
    }

    _createClass(Relationships, null, [{
        key: "parse",
        value: function parse(reader) {
            var following = reader.readArrayOf(NamedId);
            var friends = reader.readArrayOf(NamedId);
            var followers = reader.readArrayOf(NamedId);
            var blacklisted = reader.readArrayOf(NamedId);
            return new Relationships(following, friends, followers, blacklisted);
        }
    }]);

    return Relationships;
}();

var AutomatchStatus = function () {
    function AutomatchStatus(friendActivities, automatchProgress, showPreviewQueue, daysOfSubscription, otherServerAvailable) {
        _classCallCheck(this, AutomatchStatus);

        this.friendActivities = friendActivities;
        this.automatchProgress = automatchProgress;
        this.showPreviewQueue = showPreviewQueue;
        this.daysOfSubscription = daysOfSubscription;
        this.otherServerAvailable = otherServerAvailable;
    }

    _createClass(AutomatchStatus, null, [{
        key: "parse",
        value: function parse(reader) {
            var friendActivities = reader.readArrayOf(FriendActivity);
            var automatchProgress = AutomatchProgress.parse(reader);
            var showPreviewQueue = reader.readBoolean();
            var daysOfSubscription = reader.readDouble();
            var otherServerAvailable = reader.readBoolean();
            return new AutomatchStatus(friendActivities, automatchProgress, showPreviewQueue, daysOfSubscription, otherServerAvailable);
        }
    }]);

    return AutomatchStatus;
}();

var AutomatchProgress = function () {
    function AutomatchProgress(iAmSearching, searchingWith, searchingWithout, timeInQueue, myLevel2P, myLevel3P, feedback) {
        _classCallCheck(this, AutomatchProgress);

        this.iAmSearching = iAmSearching;
        this.searchingWith = searchingWith;
        this.searchingWithout = searchingWithout;
        this.timeInQueue = timeInQueue;
        this.myLevel2P = myLevel2P;
        this.myLevel3P = myLevel3P;
        this.feedback = feedback;
    }

    _createClass(AutomatchProgress, null, [{
        key: "parse",
        value: function parse(reader) {
            var iAmSearching = reader.readBoolean();
            var searchingWith = reader.readEnumToIntMapObject(AUTOMATCH_QUESTION);
            var searchingWithout = reader.readEnumToIntMapObject(AUTOMATCH_QUESTION);
            var timeInQueue = reader.readLong();
            var myLevel2P = reader.readDouble();
            var myLevel3P = reader.readDouble();
            var feedback = reader.readArrayOf(NamedId);
            return new AutomatchProgress(iAmSearching, searchingWith, searchingWithout, timeInQueue, myLevel2P, myLevel3P, feedback);
        }
    }]);

    return AutomatchProgress;
}();

var MatchingCriteria = function () {
    function MatchingCriteria(searchesBot, searchesHuman, maxRatingDifference, minRatingDifference, automatchPreferences) {
        _classCallCheck(this, MatchingCriteria);

        this.searchesBot = searchesBot;
        this.searchesHuman = searchesHuman;
        this.maxRatingDifference = maxRatingDifference;
        this.minRatingDifference = minRatingDifference;
        this.automatchPreferences = automatchPreferences;
        this.storedKeys = Array.from(this.automatchPreferences.keys());
    }

    _createClass(MatchingCriteria, [{
        key: "serialize",
        value: function serialize(writer) {
            writer.writeInt(this.searchesBot);
            writer.writeBoolean(this.searchesHuman);
            writer.writeInt(this.maxRatingDifference);
            writer.writeInt(this.minRatingDifference);
            writer.writeMapObject(this.automatchPreferences, function (w, k) {
                return w.writeInt(getOrdinal(AUTOMATCH_QUESTION, k));
            }, function (w, v) {
                return w.writeInt(getOrdinal(MATCHING_PREFERENCE, v));
            });
        }
    }, {
        key: "toggle",
        value: function toggle(automatchQuestion) {
            var oldValue = getOrdinal(MATCHING_PREFERENCE, this.automatchPreferences.get(automatchQuestion));
            var newValue = (oldValue + 1) % Object.keys(MATCHING_PREFERENCE).length;
            this.automatchPreferences.set(automatchQuestion, getByOrdinal(MATCHING_PREFERENCE, newValue));
        }
    }, {
        key: "keys",
        value: function keys() {
            return this.storedKeys;
        }
    }, {
        key: "translatedQuestionLabel",
        value: function translatedQuestionLabel(automatchQuestion) {
            return LANGUAGE.automatchQuestions[automatchQuestion];
        }
    }, {
        key: "translatedQuestionValue",
        value: function translatedQuestionValue(automatchQuestion) {
            var value = this.automatchPreferences.get(automatchQuestion);
            return LANGUAGE.automatchValues[automatchQuestion][value];
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var searchesBot = reader.readInt();
            var searchesHuman = reader.readBoolean();
            var maxRatingDifference = reader.readInt();
            var minRatingDifference = reader.readInt();
            var automatchPreferences = reader.readEnumToEnumMapObject(AUTOMATCH_QUESTION, MATCHING_PREFERENCE);
            return new MatchingCriteria(searchesBot, searchesHuman, maxRatingDifference, minRatingDifference, automatchPreferences);
        }
    }]);

    return MatchingCriteria;
}();

var AutomatchFound = function () {
    function AutomatchFound(tableDetails, isBotGame) {
        _classCallCheck(this, AutomatchFound);

        this.tableDetails = tableDetails;
        this.isBotGame = isBotGame;
    }

    _createClass(AutomatchFound, null, [{
        key: "parse",
        value: function parse(reader) {
            var tableDetails = new TableDetailsProcessor().process(reader);
            var isBotGame = reader.readBoolean();
            return new AutomatchFound(tableDetails, isBotGame);
        }
    }]);

    return AutomatchFound;
}();

var PlayerList = function (_Array) {
    _inherits(PlayerList, _Array);

    function PlayerList() {
        var namedIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        _classCallCheck(this, PlayerList);

        var _this5 = _possibleConstructorReturn(this, (PlayerList.__proto__ || Object.getPrototypeOf(PlayerList)).call(this));

        for (var i = 0; i < namedIds.length; i++) {
            _this5.push(namedIds[i]);
        }
        return _this5;
    }

    _createClass(PlayerList, [{
        key: "serialize",
        value: function serialize(writer) {
            writer.writeInt(this.length);
            this.forEach(function (e) {
                writer.writeInt(e.id);
                writer.writeString(e.name);
            });
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            return new PlayerList(reader.readArrayOf(NamedId));
        }
    }]);

    return PlayerList;
}(Array);

var ReplayInstructions = function () {
    function ReplayInstructions(gameId, decisionIndex, playerList) {
        _classCallCheck(this, ReplayInstructions);

        this.gameId = gameId;
        this.decisionIndex = decisionIndex;
        this.playerList = playerList;
    }

    _createClass(ReplayInstructions, [{
        key: "serialize",
        value: function serialize(writer) {
            writer.writeLong(this.gameId);
            writer.writeInt(this.decisionIndex);
            if (this.playerList.length > 0) {
                this.playerList.serialize(writer);
            } else {
                writer.writeInt(0);
            }
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var gameId = reader.readLong();
            var decisionIndex = reader.readInt();
            var playerList = PlayerList.parse(reader);
            return new ReplayInstructions(gameId, decisionIndex, playerList);
        }
    }]);

    return ReplayInstructions;
}();

var PlayerOrder = function () {
    function PlayerOrder() {
        var players = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        _classCallCheck(this, PlayerOrder);

        this.players = players;
        this.sort();
    }

    _createClass(PlayerOrder, [{
        key: "sort",
        value: function sort() {
            this.players.sort(function (a, b) {
                return a.order - b.order;
            });
        }
    }, {
        key: "getLength",
        value: function getLength() {
            return this.players.length;
        }
    }, {
        key: "add",
        value: function add(player) {
            this.players.push(player);
            this.sort();
        }
    }, {
        key: "moveUp",
        value: function moveUp(index) {
            if (index >= this.players.length) throw new Error('No such player to move up.');
            if (index === 0) throw new Error('Trying to move up first player.');
            this.players[index].order--;
            this.players[index - 1].order++;
            this.sort();
        }
    }, {
        key: "moveDown",
        value: function moveDown(index) {
            if (index >= this.players.length) throw new Error('No such player to move down.');
            if (index === this.players.length - 1) throw new Error('Trying to move down last player.');
            this.players[index].order++;
            this.players[index + 1].order--;
            this.sort();
        }
    }, {
        key: "getOrderString",
        value: function getOrderString() {
            return this.players.map(function (p) {
                return p.namedId.name;
            }).join(getPhrase(Phrases.COMMA));
        }
    }, {
        key: "serialize",
        value: function serialize(writer) {
            writer.writeTypedArray(this.players);
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            return new PlayerOrder(reader.readArrayOf(OrderedPlayer));
        }
    }]);

    return PlayerOrder;
}();

var BotAdded = function () {
    function BotAdded(tableId, namedId) {
        _classCallCheck(this, BotAdded);

        this.tableId = tableId;
        this.namedId = namedId;
    }

    _createClass(BotAdded, null, [{
        key: "parse",
        value: function parse(reader) {
            var tableId = reader.readLong();
            var namedId = NamedId.parse(reader);
            return new BotAdded(tableId, namedId);
        }
    }]);

    return BotAdded;
}();

var Mail = function () {
    function Mail(messageId, receiver, sender, subject, body, sendDate, status) {
        _classCallCheck(this, Mail);

        this.messageId = messageId;
        this.receiver = receiver;
        this.sender = sender;
        this.subject = subject;
        this.body = body;
        this.sendDate = sendDate;
        this.status = status;
    }

    _createClass(Mail, [{
        key: "getSummary",
        value: function getSummary() {
            return new MailSummary(this.messageId, this.status, this.subject, this.sendDate);
        }
    }, {
        key: "getDate",
        value: function getDate() {
            return this.sendDate;
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var messageId = reader.readLong();
            var receiver = reader.readString();
            var sender = reader.readString();
            var subject = reader.readString();
            var body = reader.readString();
            var sendDate = reader.readLong();
            var status = getByOrdinal(MailStati, reader.readInt());
            return new Mail(messageId, receiver, sender, subject, body, sendDate, status);
        }
    }]);

    return Mail;
}();

var MailSummary = function () {
    function MailSummary(messageId, status, subject, date) {
        _classCallCheck(this, MailSummary);

        this.messageId = messageId;
        this.status = status;
        this.subject = subject;
        this.date = date;
    }

    _createClass(MailSummary, null, [{
        key: "parse",
        value: function parse(reader) {
            var messageId = reader.readLong();
            var status = getByOrdinal(MailStati, reader.readInt());
            var subject = reader.readString();
            var date = reader.readLong();
            return new MailSummary(messageId, status, subject, date);
        }
    }]);

    return MailSummary;
}();

var Leaderboard = function () {
    function Leaderboard(leaderboard2P, leaderboard34P) {
        _classCallCheck(this, Leaderboard);

        this.leaderboard2P = leaderboard2P;
        this.leaderboard34P = leaderboard34P;
    }

    _createClass(Leaderboard, null, [{
        key: "parse",
        value: function parse(reader) {
            var leaderboard2P = reader.readArrayOf(LeaderboardEntry);
            var leaderboard34P = reader.readArrayOf(LeaderboardEntry);
            return new Leaderboard(leaderboard2P, leaderboard34P);
        }
    }]);

    return Leaderboard;
}();

var LeaderboardEntry = function () {
    function LeaderboardEntry(namedId, rank, level, levelChange, skill, deviation, volatility, convertedSkill, convertedDeviation, gameCount) {
        _classCallCheck(this, LeaderboardEntry);

        this.namedId = namedId;
        this.rank = rank;
        this.level = level;
        this.levelChange = levelChange;
        this.skill = skill;
        this.deviation = deviation;
        this.volatility = volatility;
        this.convertedSkill = convertedSkill;
        this.convertedDeviation = convertedDeviation;
        this.gameCount = gameCount;
    }

    _createClass(LeaderboardEntry, [{
        key: "getName",
        value: function getName() {
            return this.namedId.name;
        }
    }, {
        key: "getId",
        value: function getId() {
            return this.namedId.id;
        }
    }, {
        key: "getDetailedLevel",
        value: function getDetailedLevel() {
            return this.convertedSkill - 2 * this.convertedDeviation;
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var namedId = NamedId.parse(reader);
            var rank = reader.readInt();
            var level = reader.readInt();
            var levelChange = reader.readInt();
            var skill = reader.readDouble();
            var deviation = reader.readDouble();
            var volatility = reader.readDouble();
            var convertedSkill = reader.readDouble();
            var convertedDeviation = reader.readDouble();
            var gameCount = reader.readInt();
            return new LeaderboardEntry(namedId, rank, level, levelChange, skill, deviation, volatility, convertedSkill, convertedDeviation, gameCount);
        }
    }]);

    return LeaderboardEntry;
}();

var DirectionalZone = function () {
    function DirectionalZone(index, direction) {
        _classCallCheck(this, DirectionalZone);

        this.index = index;
        this.direction = direction;
    }

    _createClass(DirectionalZone, null, [{
        key: "parse",
        value: function parse(reader) {
            var direction = getByOrdinal(Directions, reader.readInt());
            var index = reader.readInt();
            return new DirectionalZone(index, direction);
        }
    }]);

    return DirectionalZone;
}();

var TableOptions = function () {
    function TableOptions(playersSeeSpecChat) {
        _classCallCheck(this, TableOptions);

        this.playersSeeSpecChat = playersSeeSpecChat;
    }

    _createClass(TableOptions, null, [{
        key: "parse",
        value: function parse(reader) {
            return new TableOptions(reader.readBoolean());
        }
    }]);

    return TableOptions;
}();

var MetaGameInfo = function () {
    function MetaGameInfo(gameId, isRated, cardPoolLevel, levelMap, changedLikelyhoodCards) {
        _classCallCheck(this, MetaGameInfo);

        this.gameId = gameId;
        this.isRated = isRated;
        this.cardPoolLevel = cardPoolLevel;
        this.levelMap = levelMap;
        this.changedLikelyhoodCards = changedLikelyhoodCards;
    }

    _createClass(MetaGameInfo, null, [{
        key: "parse",
        value: function parse(reader) {
            var gameId = reader.readLong();
            var isRated = reader.readBoolean();
            var cardPoolLevel = reader.readInt();
            var levelMap = new Map();
            var size = reader.readInt();
            for (var i = 0; i < size; i++) {
                levelMap.set(reader.readInt(), reader.readDouble());
            }
            var changedLikelyhoodCards = reader.readArrayOf(ChangedLikelyhoodList);
            return new MetaGameInfo(gameId, isRated, cardPoolLevel, levelMap, changedLikelyhoodCards);
        }
    }]);

    return MetaGameInfo;
}();

var ChangedLikelyhoodList = function () {
    function ChangedLikelyhoodList(likelyhood, cards) {
        _classCallCheck(this, ChangedLikelyhoodList);

        this.likelyhood = likelyhood;
        this.cards = cards;
    }

    _createClass(ChangedLikelyhoodList, null, [{
        key: "parse",
        value: function parse(reader) {
            var likelyhood = reader.readInt();
            var cards = reader.readArrayOf(CardName);
            return new ChangedLikelyhoodList(likelyhood, cards);
        }
    }]);

    return ChangedLikelyhoodList;
}();

function Relation(namedId, relationStatus) {
    var self = this;
    self.namedId = namedId;
    self.relationStatus = relationStatus;
    self.getName = function () {
        return self.namedId.name;
    };
    self.getId = function () {
        return self.namedId.id;
    };
}

var USER_AUTOPLAY_SETTINGS_HISTORY = [];

var AutoPlaySettings = function () {
    function AutoPlaySettings(map) {
        _classCallCheck(this, AutoPlaySettings);

        this.map = map;
        self = this;
    }

    _createClass(AutoPlaySettings, [{
        key: "saveInHistory",
        value: function saveInHistory() {
            var _this6 = this;

            var values = Object.keys(this.map).map(function (name) {
                return _this6.map[name].value;
            });
            USER_AUTOPLAY_SETTINGS_HISTORY.push(values);
        }
    }, {
        key: "undo",
        value: function undo() {
            var _this7 = this;

            if (USER_AUTOPLAY_SETTINGS_HISTORY.length > 0) {

                var values = USER_AUTOPLAY_SETTINGS_HISTORY.pop();
                Object.keys(this.map).map(function (name, index) {
                    return _this7.map[name].setValue(values[index]);
                });
            }
        }
    }, {
        key: "setAllOff",
        value: function setAllOff() {
            this.applyToAllOptions(function (s) {
                return s.setOffValue();
            });
        }
    }, {
        key: "setDefault",
        value: function setDefault() {
            this.applyToAllOptions(function (s) {
                return s.setDefaultValue();
            });
        }
    }, {
        key: "setConvenient",
        value: function setConvenient() {
            this.applyToAllOptions(function (s) {
                return s.setConvenientValue();
            });
        }
    }, {
        key: "applyToAllOptions",
        value: function applyToAllOptions(someFunction) {
            var _this8 = this;

            Object.keys(this.map).forEach(function (name) {
                var setting = _this8.map[name];
                someFunction(setting);
            });
        }
    }, {
        key: "find",
        value: function find(cardName) {
            return this.map[cardName];
        }
    }, {
        key: "serialize",
        value: function serialize(writer) {
            var size = Object.keys(this.map).length;
            writer.writeInt(size);
            ObjectValues(this.map).forEach(function (entry) {
                entry.serialize(writer);
            });
        }
    }, {
        key: "toString",
        value: function toString() {
            return ObjectValues(this.map).map(function (setting) {
                return setting.nameValueString();
            }).join('; ');
        }
    }], [{
        key: "groups",
        value: function groups() {

            var cleanupAutoplays = [AutoPlays.HERO, AutoPlays.WARRIOR, AutoPlays.TREASURE_HUNTER, AutoPlays.PAGE, AutoPlays.DISCIPLE, AutoPlays.FUGITIVE, AutoPlays.SOLDIER, AutoPlays.PEASANT, AutoPlays.ALCHEMIST, AutoPlays.TREASURY, AutoPlays.HERBALIST, AutoPlays.WALLED_VILLAGE, AutoPlays.BORDER_GUARD];

            var interfaceAutoplays = [AutoPlays.CROWN, AutoPlays.COIN_OF_THE_REALM, AutoPlays.CHANGELING, AutoPlays.TRADER, AutoPlays.CHAMPION, AutoPlays.DESPERATION, AutoPlays.PAGEANT, AutoPlays.ARENA, AutoPlays.SINISTER_PLOT, AutoPlays.MONEYLENDER];
            var otherAutoplays = [];
            var result = {};
            var special = [];

            result[Phrases.AUTOPLAY_INTERFACE] = interfaceAutoplays;
            result[Phrases.AUTOPLAY_CLEANUP] = cleanupAutoplays;

            ObjectValues(result).forEach(function (settings) {
                return special = special.concat(settings);
            });

            result[Phrases.AUTOPLAY_OTHER] = ObjectValues(AutoPlays).filter(function (setting) {
                return special.indexOf(setting) === -1;
            });

            return result;
        }
    }, {
        key: "parse",
        value: function parse(reader) {
            var size = reader.readInt();
            var map = {};
            while (size-- > 0) {
                var autoPlay = AutoPlay.parse(reader);
                map[autoPlay.cardName] = autoPlay;
            }
            return new AutoPlaySettings(map);
        }
    }]);

    return AutoPlaySettings;
}();

var SpecialKingdomRules = function () {
    function SpecialKingdomRules(useColonies, useShelters, setupRules, traitTargets) {
        _classCallCheck(this, SpecialKingdomRules);

        if (useColonies === undefined) throw new Error();
        this.useColonies = useColonies;
        this.useShelters = useShelters;
        this.setupRules = setupRules;
        this.traitTargets = traitTargets;
        this.setLegacyVariableNames();
    }

    _createClass(SpecialKingdomRules, [{
        key: "serialize",
        value: function serialize(writer) {
            this.parseLegacyVariableNames();
            writer.writeInt(getOrdinal(TernaryField, this.useColonies));
            writer.writeInt(getOrdinal(TernaryField, this.useShelters));
            writer.writeTypedArray(this.setupRules);
            writer.writeMapObject(this.traitTargets, function (w, k) {
                return w.writeInt(getValues(CardNames).indexOf(k));
            }, function (w, v) {
                return w.writeInt(getValues(CardNames).indexOf(v));
            });
        }
    }, {
        key: "setLegacyVariableNames",
        value: function setLegacyVariableNames() {
            this.baneCard = this.getRuleValue(SetupRuleIds.YOUNG_WITCH);
            this.mouseToy = this.getRuleValue(SetupRuleIds.MOUSE);
            this.ferrymanCard = this.getRuleValue(SetupRuleIds.FERRYMAN);
            this.riverboatCard = this.getRuleValue(SetupRuleIds.RIVERBOAT);
            this.obeliskPile = this.getRuleValue(SetupRuleIds.OBELISK);
            this.druidBoons = this.getRuleValue(SetupRuleIds.DRUID);
        }
    }, {
        key: "parseLegacyVariableNames",
        value: function parseLegacyVariableNames() {
            if (this.baneCard) this.setRuleValue(SetupRuleIds.YOUNG_WITCH, this.baneCard);
            if (this.mouseToy) this.setRuleValue(SetupRuleIds.MOUSE, this.mouseToy);
            if (this.ferrymanCard) this.setRuleValue(SetupRuleIds.FERRYMAN, this.ferrymanCard);
            if (this.riverboatCard) this.setRuleValue(SetupRuleIds.RIVERBOAT, this.riverboatCard);
            if (this.obeliskPile) this.setRuleValue(SetupRuleIds.OBELISK, this.obeliskPile);
            if (this.druidBoons) this.setRuleValue(SetupRuleIds.DRUID, this.druidBoons);
        }
    }, {
        key: "setRuleValue",
        value: function setRuleValue(setupRuleId, value) {
            var oldRule = this.setupRules.find(function (r) {
                return r.id === setupRuleId;
            });
            if (oldRule) {
                oldRule.value = value;
            } else {
                this.setupRules.push(new SetupRule(setupRuleId, value));
            }
        }
    }, {
        key: "getRuleValue",
        value: function getRuleValue(setupRuleId) {
            var rule = this.setupRules.find(function (r) {
                return r.id === setupRuleId;
            });
            return rule && rule.value;
        }
    }, {
        key: "removeRule",
        value: function removeRule(setupRuleId) {
            var oldRuleIndex = this.setupRules.findIndex(function (r) {
                return r.id === setupRuleId;
            });
            if (oldRuleIndex > -1) {
                this.setupRules.splice(oldRuleIndex, 1);
            }
        }
    }, {
        key: "isDefault",
        value: function isDefault() {
            this.parseLegacyVariableNames();
            return this.useColonies === TernaryField.RANDOM && this.useShelters === TernaryField.RANDOM && this.setupRules.length === 0 && this.traitTargets.length === 0;
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var useColonies = getByOrdinal(TernaryField, reader.readInt());
            var useShelters = getByOrdinal(TernaryField, reader.readInt());
            var setupRules = reader.readArrayOf(SetupRule);
            var traitTargets = reader.readEnumToEnumMapObject(CardNames, CardNames);
            return new SpecialKingdomRules(useColonies, useShelters, setupRules, traitTargets);
        }
    }]);

    return SpecialKingdomRules;
}();

var AutomatchQuestion = function () {
    function AutomatchQuestion(intValue, becomesTableRule) {
        var _this9 = this;

        var isSimple = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

        _classCallCheck(this, AutomatchQuestion);

        this.intValue = intValue;
        this.becomesTableRule = becomesTableRule;
        this.isSimple = isSimple;
        this.toString = function () {
            return _this9.intValue;
        };
    }

    _createClass(AutomatchQuestion, [{
        key: "serialize",
        value: function serialize(writer) {
            writer.writeInt(this.intValue);
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var intValue = reader.readInt();
            return getByOrdinal(AUTOMATCH_QUESTION, intValue);
        }
    }]);

    return AutomatchQuestion;
}();

var Failure = function () {
    function Failure(failureReason, parameter) {
        _classCallCheck(this, Failure);

        this.failureReason = failureReason;
        this.parameter = parameter;
    }

    _createClass(Failure, [{
        key: "toTranslatedString",
        value: function toTranslatedString() {
            var text = LANGUAGE.getError[this.failureReason];
            if (this.parameter) text += ' ' + LANGUAGE.getCardName[this.parameter].singular;
            return text;
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var failureReason = getByOrdinal(FailureReasons, reader.readInt());
            var index = reader.readInt();
            var parameter = index === -1 ? undefined : getByOrdinal(CardNames, index);
            return new Failure(failureReason, parameter);
        }
    }]);

    return Failure;
}();

var Ownership = function () {
    function Ownership(dateToExpansions, dateToLevels) {
        _classCallCheck(this, Ownership);

        this.dateToExpansions = dateToExpansions;
        this.dateToLevels = dateToLevels;
        this.expansionToDate = reverseMap(dateToExpansions);
        this.levelToDate = reverseMap(dateToLevels);
    }

    _createClass(Ownership, [{
        key: "hasAnything",
        value: function hasAnything() {
            return this.hasExpansions() || this.hasLevels();
        }
    }, {
        key: "hasExpansions",
        value: function hasExpansions() {
            return this.expansionMap.values.some(function (e) {
                return !e.isForFree();
            });
        }
    }, {
        key: "hasLevels",
        value: function hasLevels() {
            return this.levelMap.values.some(function (l) {
                return l > 1;
            });
        }
    }, {
        key: "expansionEndsAt",
        value: function expansionEndsAt(expansion) {
            if (this.expansionToDate.has(expansion)) {
                return new Date(this.expansionToDate.get(expansion));
            }
            return false;
        }
    }, {
        key: "ownsExpansion",
        value: function ownsExpansion(expansion) {
            var endDate = this.expansionEndsAt(expansion);
            return endDate && endDate > new Date();
        }
    }, {
        key: "levelEndsAt",
        value: function levelEndsAt(level) {
            if (this.levelToDate.has(level)) {
                return new Date(this.levelToDate.get(level));
            }
            return false;
        }
    }, {
        key: "ownsLevel",
        value: function ownsLevel(level) {
            var endDate = this.levelEndsAt(level);
            return endDate && endDate > new Date();
        }
    }, {
        key: "ownsCard",
        value: function ownsCard(card) {
            return this.ownsExpansion(card.expansion) || this.ownsLevel(card.cardPoolLevel);
        }
    }, {
        key: "maxOwnedLevel",
        value: function maxOwnedLevel() {
            for (var l = 10; l > 1; l--) {
                if (this.ownsLevel(l)) return l;
            }return 1;
        }
    }, {
        key: "dump",
        value: function dump() {
            console.log("Subscribed to the expansions:");
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = this.expansionToDate[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var _step$value = _slicedToArray(_step.value, 2),
                        expansion = _step$value[0],
                        date = _step$value[1];

                    console.log("expansion " + expansion + " ends at " + date);
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            console.log("Subscribed to the levels:");
            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
                for (var _iterator2 = this.levelToDate[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                    var _step2$value = _slicedToArray(_step2.value, 2),
                        level = _step2$value[0],
                        date = _step2$value[1];

                    console.log("level " + level + " ends at " + date);
                }
            } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion2 && _iterator2.return) {
                        _iterator2.return();
                    }
                } finally {
                    if (_didIteratorError2) {
                        throw _iteratorError2;
                    }
                }
            }
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var nExpansions = reader.readInt();
            var expansionMap = new Map();
            for (var i = 0; i < nExpansions; i++) {
                var date = reader.readDate();
                var expansions = reader.readArrayOf(Expansion);
                expansionMap.set(date, expansions);
            }
            var nLevels = reader.readInt();
            var levelMap = new Map();
            for (var _i = 0; _i < nLevels; _i++) {
                var _date = reader.readDate();
                var levels = reader.readInts();
                levelMap.set(_date, levels);
            }
            return new Ownership(expansionMap, levelMap);
        }
    }]);

    return Ownership;
}();

var SetupRule = function () {
    function SetupRule(id, value) {
        _classCallCheck(this, SetupRule);

        this.id = id;
        this.value = value;
    }

    _createClass(SetupRule, [{
        key: "serialize",
        value: function serialize(writer) {
            writer.writeInt(getOrdinal(SetupRuleIds, this.id));
            if (this.id.type === SetupRuleType.CARD) {
                writer.writeInt(getOrdinal(CardNames, this.value));
            } else {
                writer.writeTypedArray(this.value);
            }
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var id = getByOrdinal(SetupRuleIds, reader.readInt());
            var value = id.type === SetupRuleType.CARD ? getByOrdinal(CardNames, reader.readInt()) : reader.readArrayOf(CardName);
            return new SetupRule(id, value);
        }
    }]);

    return SetupRule;
}();

"use strict";

function LooksAt(cardId, playerIndex) {
    this.cardId = cardId;
    this.playerIndex = playerIndex;
}

var Ability = function () {
    function Ability(name, association, isOptional) {
        var logEntryArguments = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];

        _classCallCheck(this, Ability);

        this.name = name;
        this.isOptional = isOptional;
        this.association = association;
        this.question = undefined;
        this.logEntryArguments = logEntryArguments;
    }

    _createClass(Ability, [{
        key: "isEquivalentTo",
        value: function isEquivalentTo(a_2) {
            if (!(this.name === a_2.name)) return false;
            if (this.logEntryArguments.length !== a_2.logEntryArguments.length) return false;
            // Don't combine different masterminds
            var WEAK_STACKING_ABILITIES = [AbilityNames.MASTERMIND, AbilityNames.QUARTERMASTER];
            if (WEAK_STACKING_ABILITIES.includes(this.name) && this.association !== a_2.association) return false;

            return this.logEntryArguments.filter(function (arg, i) {
                if (arg.type === 0) {
                    var other = a_2.logEntryArguments[i].argument;
                    if (arg.argument.length !== other.length) return true;
                    for (var j = 0; j < other.length; j++) {
                        if (arg.argument[j].cardName !== other[j].cardName) return true;
                    }
                }
                return arg.type === 11 && arg.argument !== a_2.logEntryArguments[i].argument;
            }).length === 0;
        }
    }, {
        key: "getButtonHints",
        value: function getButtonHints() {
            var hints = [];
            this.logEntryArguments.forEach(function (a) {
                if (a.type === 0) {
                    a.argument.forEach(function (e) {
                        hints.push({ 'cardName': e.cardName, 'repeat': false });
                        for (var i = 1; i < e.frequency; i++) {
                            hints.push({ 'cardName': e.cardName, 'repeat': true });
                        }
                    });
                }
                if (a.type === 11) {
                    var isRepeat = hints.some(function (e) {
                        return e.cardName === a.argument;
                    });
                    hints.push({ 'cardName': a.argument, 'repeat': isRepeat });
                }
            });

            return hints;
        }
    }, {
        key: "label",
        get: function get() {
            return LANGUAGE.getAbility[this.name];
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var possiblyOptional = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            var type = getByOrdinal(AbilityDescriptionTypes, reader.readInt());
            var nameIndex = reader.readInt();
            var typeOrName = type === AbilityDescriptionTypes.MOVEMENT_CAUSE ? getByOrdinal(MovementTypes, nameIndex) : getByOrdinal(AbilityNames, nameIndex);
            var association = reader.readInt();
            var logEntryArguments = type === AbilityDescriptionTypes.WITH_ARGUMENTS ? reader.readArrayOf(LogArgument) : [];
            var isOptional = possiblyOptional ? reader.readBoolean() : false;
            return new Ability(typeOrName, association, isOptional, logEntryArguments);
        }
    }, {
        key: "sort",
        value: function sort(a, b) {
            if (a.isOptional && !b.isOptional) return 1;
            if (!a.isOptional && b.isOptional) return -1;

            var _map = [a, b].map(function (x) {
                return x.association;
            }),
                _map2 = _slicedToArray(_map, 2),
                cardA = _map2[0],
                cardB = _map2[1];

            if (cardA !== cardB) return cardA - cardB;

            var _map3 = [a, b].map(function (x) {
                return x.getButtonHints();
            }),
                _map4 = _slicedToArray(_map3, 2),
                hintsA = _map4[0],
                hintsB = _map4[1];

            if (hintsA.length !== hintsB.length) return hintsB.length - hintsA.length;
            if (hintsA.length > 0) return cardNameSorter(hintsA[0].cardName, hintsB[0].cardName);
            return 0;
        }
    }]);

    return Ability;
}();

function ExtraTurnDescription(owner, type) {
    this.owner = owner;
    this.type = type;
}

var CardFrequency = function () {
    function CardFrequency(cardName, frequency) {
        _classCallCheck(this, CardFrequency);

        this.cardName = cardName;
        this.frequency = frequency;
    }

    _createClass(CardFrequency, null, [{
        key: "parse",
        value: function parse(reader) {
            var cardName = CardName.parse(reader);
            var frequency = reader.readInt();
            return new CardFrequency(cardName, frequency);
        }
    }]);

    return CardFrequency;
}();

var Story = function () {
    function Story(questionId, questionArguments, context) {
        _classCallCheck(this, Story);

        this.questionId = questionId;
        this.arguments = questionArguments;
        this.context = context;
    }

    _createClass(Story, [{
        key: "hasContext",
        value: function hasContext() {
            return this.context !== -1;
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var questionId = getByOrdinal(QuestionIds, reader.readInt());
            var questionArguments = reader.readArrayOf(LogArgument);
            var hasContext = reader.readBoolean();
            var context = hasContext ? LogArgument.parse(reader) : -1;
            return new Story(questionId, questionArguments, context);
        }
    }]);

    return Story;
}();

var QuestionDescription = function () {
    function QuestionDescription(type, association, story, shortStory) {
        _classCallCheck(this, QuestionDescription);

        this.type = type;
        this.association = association;
        this.story = story;
        this.shortStory = shortStory;
    }

    _createClass(QuestionDescription, null, [{
        key: "parse",
        value: function parse(reader) {
            var questionType = reader.readInt();
            var association = reader.readInt();
            var story = Story.parse(reader);
            var shortStory = Story.parse(reader);
            return new QuestionDescription(questionType, association, story, shortStory);
        }
    }]);

    return QuestionDescription;
}();

var AbilityEvent = function () {
    function AbilityEvent(type, description, logEntryArguments) {
        _classCallCheck(this, AbilityEvent);

        this.type = type;
        this.description = description;
        this.logEntryArguments = logEntryArguments;
    }

    _createClass(AbilityEvent, null, [{
        key: "parse",
        value: function parse(reader) {
            var eventType = getByOrdinal(EventTypes, reader.readInt());
            var abilityDescription = Ability.parse(reader);
            var logEntryArguments = reader.readArrayOf(LogArgument);
            return new AbilityEvent(eventType, abilityDescription, logEntryArguments);
        }
    }]);

    return AbilityEvent;
}();

var PileMarker = function () {
    function PileMarker(type, zoneIndex) {
        _classCallCheck(this, PileMarker);

        this.type = type;
        this.zoneIndex = zoneIndex;
    }

    _createClass(PileMarker, null, [{
        key: "parse",
        value: function parse(reader) {
            var type = getByOrdinal(PileMarkerTypes, reader.readInt());
            var zoneIndex = reader.readInt();
            return new PileMarker(type, zoneIndex);
        }
    }]);

    return PileMarker;
}();

function CleanupItem(index, cardNames, isOptional, allowsReordering, enabled) {
    var self = this;
    self.index = index;
    self.ordering = -1;
    self.cardNames = cardNames;
    self.isOptional = isOptional;
    self.isEnabled = enabled;
    self.allowsReordering = allowsReordering;
    self.cleanupAction = self.cardNames[0].isTraveller() ? CleanupActions.EXCHANGE : CleanupActions.TOPDECK;
}

var BuyableCard = function () {
    function BuyableCard(id, isSimple) {
        _classCallCheck(this, BuyableCard);

        this.id = id;
        this.isSimple = isSimple;
    }

    _createClass(BuyableCard, null, [{
        key: "parse",
        value: function parse(reader) {
            var id = reader.readInt();
            var isSimple = reader.readBoolean();
            return new BuyableCard(id, isSimple);
        }
    }]);

    return BuyableCard;
}();

var CardTypeChange = function () {
    function CardTypeChange(id, addedTypes) {
        _classCallCheck(this, CardTypeChange);

        this.id = id;
        this.addedTypes = addedTypes;
        this.removedTypes = [];
    }

    _createClass(CardTypeChange, [{
        key: "apply",
        value: function apply(cardTypeChange) {
            if (cardTypeChange.cardIndices.includes(this.id)) {
                this.addedTypes = _.union(this.addedTypes, cardTypeChange.addedTypes);
                this.addedTypes = _.difference(this.addedTypes, cardTypeChange.removedTypes);
            }
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            return new CardTypeChange(reader.readInt(), reader.readEnumArray(Types));
        }
    }]);

    return CardTypeChange;
}();

var Reminder = function () {
    function Reminder(cardName, blinking, frequency) {
        _classCallCheck(this, Reminder);

        this.cardName = cardName;
        this.blinking = blinking;
        this.frequency = frequency;
    }

    _createClass(Reminder, null, [{
        key: "parse",
        value: function parse(reader) {
            return new Reminder(CardName.parse(reader), reader.readBoolean(), reader.readInt());
        }
    }]);

    return Reminder;
}();

var TemporaryEffects = function () {
    function TemporaryEffects(renames, behaviourChanges, imageChanges, cardTypeChanges) {
        _classCallCheck(this, TemporaryEffects);

        this.renames = renames;
        this.behaviourChanges = behaviourChanges;
        this.imageChanges = imageChanges;
        this.cardTypeChanges = cardTypeChanges;
    }

    _createClass(TemporaryEffects, [{
        key: "applyTo",
        value: function applyTo(cards) {
            this.cardTypeChanges.forEach(function (change) {
                cards[change.id].updateTypes(change);
            });
            this.imageChanges.forEach(function (e) {
                return e.execute();
            });
        }
    }], [{
        key: "parse",
        value: function parse(game, reader) {
            var renames = reader.readArrayOfGameObject(game, Rename);
            var behaviourChanges = reader.readArrayOfGameObject(game, BehaviourChange);
            var imageChanges = reader.readArrayOfGameObject(game, ImageChange);
            var cardTypeChanges = reader.readArrayOf(CardTypeChange);
            return new TemporaryEffects(renames, behaviourChanges, imageChanges, cardTypeChanges);
        }
    }]);

    return TemporaryEffects;
}();

var Associations = function () {
    function Associations() {
        _classCallCheck(this, Associations);
    }

    _createClass(Associations, null, [{
        key: "parse",
        value: function parse(reader) {
            var associations = [];
            reader.readArrayOf(Association).forEach(function (a) {
                associations[a.cardIndex] = a.linkedCardIndices;
            });
            return associations;
        }
    }]);

    return Associations;
}();

var PregameStatus = function () {
    function PregameStatus(gameServerName, gameId, connectedIds, missingIds) {
        _classCallCheck(this, PregameStatus);

        this.gameServerName = gameServerName;
        this.gameId = gameId;
        this.connectedIds = connectedIds;
        this.missingIds = missingIds;
    }

    _createClass(PregameStatus, null, [{
        key: "parse",
        value: function parse(reader) {
            var gameServerName = reader.readString();
            var gameId = reader.readLong();
            var connectedIds = reader.readInts();
            var missingIds = reader.readInts();
            return new PregameStatus(gameServerName, gameId, connectedIds, missingIds);
        }
    }]);

    return PregameStatus;
}();