"use strict";

webclient.component("miniZoneTab", {
    bindings: {
        zones: "<"
    },
    controller: ["gameBroadcaster", "$element", "$scope", "cardWindowService", function (gameBroadcaster, $element, $scope, cardWindowService) {
        var self = this;

        self.tabLabel = LANGUAGE.getZoneName[self.zones[0].zoneName];
        self.tabBackgroundImage = function () {
            return self.zones[0].zoneName.tabBG(self.zones[0]);
        };
        self.getDisplayedSize = function () {
            return self.zones.map(function (z) {
                return z.cardCount();
            }).reduce(function (a, b) {
                return a + b;
            }, 0);
        };

        var updateVisibility = function updateVisibility() {
            if (self.zones.some(function (z) {
                return z.zoneName === TrashZone;
            })) {
                self.isVisible = true;
            } else if (self.zones.some(function (z) {
                return z.zoneName === InPlayZone && z.isActiveCenterZone;
            })) {
                self.isVisible = false;
            } else {
                self.isVisible = self.zones.some(function (z) {
                    return z.cardCount() > 0;
                });
                if (!self.isVisible) {
                    self.zones.forEach(cardWindowService.hideWindow);
                }
            }
            self.isActive = self.zones.some(function (z) {
                return z.visible;
            });
        };
        updateVisibility();

        var sendRedraw = function sendRedraw() {
            gameBroadcaster.send(Events.REDRAW);
        };

        self.click = function () {
            var shouldShow = self.zones.some(function (z) {
                return !z.visible || cardWindowService.getReason(z) !== "tab";
            });
            if (shouldShow) {
                self.zones.forEach(function (z) {
                    cardWindowService.showWindow(z, "tab");
                });
            } else {
                self.zones.forEach(function (z) {
                    cardWindowService.showWindow(z, "hover");
                });
            }
            updateVisibility();
        };

        var mouseMoveHandler = function mouseMoveHandler(e) {
            if (!$element[0].contains(e.target)) {
                self.dehover();
            }
        };
        self.hover = function () {
            self.zones.forEach(function (z) {
                if (cardWindowService.getReason(z) === null) {
                    cardWindowService.showWindow(z, "hover");
                }
            });
            document.querySelector("body").addEventListener("mousemove", mouseMoveHandler);
        };

        self.dehover = function () {
            if (self.zones.every(function (z) {
                return ["hover", null].includes(cardWindowService.getReason(z));
            })) {
                self.zones.forEach(function (z) {
                    cardWindowService.hideWindow(z);
                });
                document.querySelector("body").removeEventListener("mousemove", mouseMoveHandler);
            }
        };

        $scope.$on(Events.REDRAW, function () {
            updateVisibility();
            safeDigest($scope);
        });

        var listener = window.addEventListener("resize", function () {
            $scope.$digest();
        });

        $scope.$on("$destroy", function () {
            window.removeEventListener("resize", listener);
        });
    }],
    template: "\n<div\n  class=\"mini-zone-tab\"\n  ng-click=\"$ctrl.click()\"\n  ng-class=\"{visible: $ctrl.isVisible,\n  \t\t\t active: $ctrl.isActive}\"\n  ng-mouseover=\"$ctrl.hover()\"\n  ng-mouseleave=\"$ctrl.dehover()\"\n  style=\"background-image: url('{{$ctrl.tabBackgroundImage()}}');\">\n\t<div class=\"mini-zone-label unselectable\">{{$ctrl.tabLabel}}</div>\n    <card-counter\n      persistent=\"true\"\n      count=\"$ctrl.getDisplayedSize()\">\n    </card-counter>\n</div>\n\t"
});