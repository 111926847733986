"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var GameTab = function (_RenderableObject) {
    _inherits(GameTab, _RenderableObject);

    function GameTab(layoutModel, backgroundImage, zoneAcceptor) {
        var zoneNameGetter = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

        _classCallCheck(this, GameTab);

        var _this = _possibleConstructorReturn(this, (GameTab.__proto__ || Object.getPrototypeOf(GameTab)).call(this));

        _this.layoutModel = layoutModel;
        _this.backgroundImage = backgroundImage;
        _this.zoneAcceptor = zoneAcceptor;
        _this.zones = [];
        _this.state.zones.filter(_this.zoneAcceptor).forEach(function (z) {
            z.addTab(_this);
            _this.zones.push(z);
        });

        _this.zoneLayout = new Map();
        _this.locked = false;
        _this.zoneNameGetter = zoneNameGetter === null ? function () {
            return _this.zones.length > 0 ? LANGUAGE.getZoneName[_this.zones[0].zoneName] : "";
        } : zoneNameGetter;

        document.addEventListener("resize", _this.redraw);

        if (!isUndefined(_this.layoutModel.game.gameArea)) {
            _this.render(_this.layoutModel.game.gameArea.gameTabArea);
        }
        return _this;
    }

    _createClass(GameTab, [{
        key: "addZone",
        value: function addZone(zone) {
            this.zones.push(zone);
            this.computeZoneLayout();
            this.redraw();
        }
    }, {
        key: "removeZone",
        value: function removeZone(zone) {
            var index = this.zones.indexOf(zone);
            if (index > -1) {
                this.zones.splice(index, 1);
                this.computeZoneLayout();
                this.redraw();
            }
        }
    }, {
        key: "render",
        value: function render(node) {
            var _this2 = this;

            _get(GameTab.prototype.__proto__ || Object.getPrototypeOf(GameTab.prototype), "render", this).call(this, node);
            this.canvas.onclick = function () {
                return _this2.locked = !_this2.locked;
            };
            this.canvas.onmouseover = function () {
                return _this2.zones.forEach(function (z) {
                    return z.show(_this2);
                });
            };
            this.canvas.onmouseout = function () {
                if (!_this2.locked) _this2.zones.forEach(function (z) {
                    return z.hide(_this2);
                });
            };
            this.redraw();
        }
    }, {
        key: "computeZoneLayout",
        value: function computeZoneLayout() {
            var _this3 = this;

            var lines = [];
            this.zones.forEach(function (z) {
                var line = lines.find(function (b) {
                    return b.some(function (_z) {
                        return _z.createdBy === z.createdBy;
                    });
                });
                if (isUndefined(line)) {
                    lines.push([z]);
                } else {
                    line.push(z);
                }
            });

            if (lines.length === 0) return;
            var vh = 0.01 * window.innerHeight;
            var vw = 0.01 * window.innerWidth;

            var maxHeight = 75 * vh;
            var maxWidth = (100 - RIGHT_COLUMN_WIDTH) * 0.9 * vw;
            var height = Math.min(32 * vh, maxHeight / lines.length);
            var runningY = 40 * vh - height * lines.length / 2;
            lines.forEach(function (line, lineIndex) {
                var zoneWidths = line.map(function (z) {
                    return WINDOW_HEIGHT / CardSizes.FULL.ratio * (2 + z.cardStacks.length * 0.8) * vh;
                });
                var total = sum(zoneWidths);
                var factor = total < maxWidth ? 1 : maxWidth / total;
                var runningX = total > maxWidth ? vw : vw + ((100 - RIGHT_COLUMN_WIDTH - 1) * vw - total) / 2;
                line.forEach(function (z, i) {
                    _this3.zoneLayout.set(z, new Rectangle(runningX, runningY + 1 * vh, zoneWidths[i] * factor, Math.max(height - 2 * vh, 14 * vh)));
                    runningX += zoneWidths[i] * factor;
                });
                runningY += height;
            });
        }
    }, {
        key: "repositionCanvas",
        value: function repositionCanvas() {
            var _this4 = this;

            if (this.ownershipType === -1 || this.counter === -1) {
                this.canvas.style.display = "none";
                return;
            }
            this.canvas.style.display = "initial";

            var vw = window.innerWidth / 100;
            var vh = window.innerHeight / 100;
            var sameTypeTabs = this.layoutModel.tabViews.filter(function (t) {
                return t.ownershipType === _this4.ownershipType && t.showing;
            });
            var index = sameTypeTabs.indexOf(this);
            var totalWidth = [12, 75, 50][this.ownershipType] * vw;
            var width = Math.min(11 * vw, totalWidth / sameTypeTabs.length);
            var height = 4 * vh;
            var x = width * index * 0.95;
            if (this.ownershipType === 0) x = (100 - RIGHT_COLUMN_WIDTH) * vw - x - width;
            var y = this.ownershipType === 1 ? 100 * vh - height + 1 : 0;

            var shouldRedraw = this.canvas.clientHeight !== width || this.canvas.clientHeight !== height;
            this.canvas.style.position = "fixed";
            this.canvas.style.width = width + "px";
            this.canvas.style.height = height + "px";
            this.canvas.style.transform = "translateX(" + x + "px) translateY(" + y + "px)";
            this.canvas.style.zIndex = 10000 - index;
            this.canvas.style.cursor = "pointer";

            if (shouldRedraw) {
                this.canvas.width = width * 2;
                this.canvas.height = height * 2;
                this.redrawBackground();
            }
        }
    }, {
        key: "redrawBackground",
        value: function redrawBackground() {
            var _this5 = this;

            if (this.ownershipType === -1) return;
            var _ref = [this.canvas.width, this.canvas.height, this.context],
                w = _ref[0],
                h = _ref[1],
                ctx = _ref[2];

            var r = h * 0.2;
            ctx.clearRect(0, 0, w, h);
            ctx.beginPath();
            ctx.lineWidth = r / 3;
            switch (this.ownershipType) {
                case 0:
                    ctx.moveTo(w, h - ctx.lineWidth);
                    ctx.lineTo(2 * r, h - ctx.lineWidth);
                    ctx.arcTo(r, h - ctx.lineWidth, r, h - r - ctx.lineWidth, r);
                    ctx.lineTo(r, r);
                    ctx.arcTo(r, 0, 0, 0, r);
                    ctx.lineTo(0, -r);
                    ctx.lineTo(w + r, -r);
                    ctx.lineTo(w + r, h);
                    break;
                case 1:
                    ctx.moveTo(0, ctx.lineWidth);
                    ctx.lineTo(w - 2 * r, ctx.lineWidth);
                    ctx.arcTo(w - r, ctx.lineWidth, w - r, r + ctx.lineWidth, r);
                    ctx.lineTo(w - r, h - r);
                    ctx.arcTo(w - r, h, w, h, r);
                    ctx.lineTo(w, h + r);
                    ctx.lineTo(-r, h + r);
                    ctx.lineTo(-r, ctx.lineWidth);
                    break;
                case 2:
                    ctx.moveTo(0, h - ctx.lineWidth);
                    ctx.lineTo(w - 2 * r, h - ctx.lineWidth);
                    ctx.arcTo(w - r, h - ctx.lineWidth, w - r, h - r - ctx.lineWidth, r);
                    ctx.lineTo(w - r, r);
                    ctx.arcTo(w - r, 0, w, 0, r);
                    ctx.lineTo(w, -r);
                    ctx.lineTo(-r, -r);
                    ctx.lineTo(-r, h);
                    break;
            }
            ctx.fill();

            Preloader.load(this.backgroundImage, function (source) {
                ctx.globalCompositeOperation = "source-atop";
                ctx.filter = "contrast(0.5) brightness(0.6) blur(2px)";
                var sourceW = Math.min(source.width, source.height / h * w);
                var sourceH = Math.min(source.height, source.width / w * h);
                ctx.drawImage(source, (source.width - sourceW) / 2, (source.height - sourceH) / 2, sourceW, sourceH, 0, 0, w, h);
                ctx.filter = "contrast(1) brightness(1)";
                ctx.strokeStyle = "white";
                ctx.fillStyle = "#333";
                var someZoneVisible = _this5.zones.some(function (z) {
                    return z.showCauses.length > 0;
                });
                if (someZoneVisible) {
                    ctx.fillStyle = "rgba(250, 130, 40, 0.4)";
                    ctx.fill();
                }
                ctx.stroke();
                ctx.globalCompositeOperation = "source-over";
                _this5.redrawText();
            });
        }
    }, {
        key: "redrawText",
        value: function redrawText() {
            var _ref2 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref2[0],
                h = _ref2[1],
                ctx = _ref2[2];

            var size = Math.min(h * 0.8, w * 0.2);
            var x = this.ownershipType === 0 ? size + 0.1 * w : 0.1 * w;
            var y = this.ownershipType === 1 ? 0.3 * h : 0.2 * h;
            var bbox = new Rectangle(x, y, 0.8 * w - size, 0.6 * h);
            ctx.strokeStyle = "black";
            ctx.fillStyle = "white";
            var text = this.zoneNameGetter();
            drawFittedText(ctx, text, bbox, 1, "TrajanPro-Bold", "bold", true);

            this.redrawCounter();
        }
    }, {
        key: "redrawCounter",
        value: function redrawCounter() {
            var _this6 = this;

            var _ref3 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref3[0],
                h = _ref3[1],
                ctx = _ref3[2];

            var artURL = 'images/elements/card-counter.png';
            Preloader.load(artURL, function (source) {
                var size = Math.min(h * 0.8, w * 0.2);
                var x = [h * 0.2, w - h * 0.2 - size, w - h * 0.2 - size][_this6.ownershipType];
                var y = [h * 0.94 - size, h * 0.06, h * 0.94 - size][_this6.ownershipType];
                ctx.drawImage(source, x, y, size, size);
                ctx.fillStyle = "white";
                drawFittedText(ctx, _this6.counter, new Rectangle(x + size * 0.1, y + size * 0.1, size * 0.8, size * 0.8), 1, "Montserrat");
            });
        }
    }, {
        key: "redraw",
        value: function redraw() {
            if (!this.zones.some(function (z) {
                return z.showCauses.length > 0;
            })) this.locked = false;
            this.repositionCanvas();
            this.redrawBackground();
        }
    }, {
        key: "state",
        get: function get() {
            return this.layoutModel.game.state;
        }
    }, {
        key: "ownershipType",
        get: function get() {
            if (this.zones.length === 0) return -1;
            if (this.zones[0].owner === this.state.nobody) return 0;
            if (this.zones[0].owner.isHero) return 1;
            if (this.zones[0].owner.isActiveOpponent) return 2;
            return -1;
        }
    }, {
        key: "counter",
        get: function get() {
            var count = sum(this.zones.map(function (z) {
                return z.cardCount;
            }));
            return count === 0 ? -1 : count;
        }
    }, {
        key: "showing",
        get: function get() {
            return this.ownershipType > -1 && this.counter > -1;
        }
    }]);

    return GameTab;
}(RenderableObject);

var AlwaysVisibleGameTab = function (_GameTab) {
    _inherits(AlwaysVisibleGameTab, _GameTab);

    function AlwaysVisibleGameTab() {
        _classCallCheck(this, AlwaysVisibleGameTab);

        return _possibleConstructorReturn(this, (AlwaysVisibleGameTab.__proto__ || Object.getPrototypeOf(AlwaysVisibleGameTab)).apply(this, arguments));
    }

    _createClass(AlwaysVisibleGameTab, [{
        key: "counter",
        get: function get() {
            return sum(this.zones.map(function (z) {
                return z.cardCount;
            }));
        }
    }]);

    return AlwaysVisibleGameTab;
}(GameTab);

var InPlayGameTab = function (_GameTab2) {
    _inherits(InPlayGameTab, _GameTab2);

    function InPlayGameTab() {
        _classCallCheck(this, InPlayGameTab);

        return _possibleConstructorReturn(this, (InPlayGameTab.__proto__ || Object.getPrototypeOf(InPlayGameTab)).apply(this, arguments));
    }

    _createClass(InPlayGameTab, [{
        key: "counter",
        get: function get() {
            if (this.zones[0].owner === this.zones[0].state.activeTurn.owner) return -1;
            var count = sum(this.zones.map(function (z) {
                return z.cardCount;
            }));
            return count === 0 ? -1 : count;
        }
    }]);

    return InPlayGameTab;
}(GameTab);

var ShadowGameTab = function (_GameTab3) {
    _inherits(ShadowGameTab, _GameTab3);

    function ShadowGameTab() {
        _classCallCheck(this, ShadowGameTab);

        return _possibleConstructorReturn(this, (ShadowGameTab.__proto__ || Object.getPrototypeOf(ShadowGameTab)).apply(this, arguments));
    }

    _createClass(ShadowGameTab, [{
        key: "counter",
        get: function get() {
            var count = sum(this.zones.map(function (z) {
                return sum(z.windowStacks.map(function (s) {
                    return s.cards.length;
                }));
            }));
            return count === 0 ? -1 : count;
        }
    }]);

    return ShadowGameTab;
}(GameTab);