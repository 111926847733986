"use strict";
// Intro page of Store tab in Lobby

EsperantoParagraphs.STORE_INTRO = new Paragraph(["Ludoj kun la Bazaj kartoj estas senpagaj.", "Ludoj kun etendigoj postulas abonon.", "Unu abonanto po ludo sufiĉas."]);

EsperantoParagraphs.STORE_BUYING = new Paragraph(["Abonoj ne aŭtomate reas.", "Vi povas facile reaĉeti por jesigi aboni aŭ altgradigu.", "La prezo estos reduktita de la valoro de via restanta abono.", "Se vi aĉetas por pli longa tempo, vi ricevas rabaton."]);

EsperantoParagraphs.STORE_SUBSCRIPTIONS = new Paragraph(["Kerna Abono &mdash; la pli simpla duono de la kartoj &mdash; € 2.15 per month.", "Kompleta Abono &mdash; ĉiuj kartoj &mdash; € 4.30 per month."]);

EsperantoParagraphs.STORE_DOLLARS = new Paragraph(["Aĉetoj havas koston en eŭroj.", "Pagoj en dolaroj estos aŭtomate konvertitaj."]);

EsperantoParagraphs.STORE_UPGRADE = new Paragraph(["Viaj abonoj enhavas ĉiuj etendigoj krom la lastaj.", "Elektu 'Jes' kaj ĉi ti etendigo estos aldonita al viaj abonoj.", "La limdato estos la plej frua de viaj nuntempaj abonoj.", "Elektu 'Ne' por ĉiuj aliaj aĉetoj."]);