"use strict";

webclient.component("relatedQuestion", {

    bindings: {

        question: "=ngModel"
    },

    controllerAs: "ctrl",

    controller: [function () {

        var ctrl = this;

        ctrl.isHaveReadItQuestion = function () {
            return ctrl.question.answerType === 'info';
        };
        ctrl.isSelectQuestion = function () {
            return ctrl.question.answerType === 'select';
        };
        ctrl.isTextQuestion = function () {
            return ctrl.question.answerType === 'text';
        };
        ctrl.isBooleanQuestion = function () {
            return ctrl.question.answerType === 'boolean';
        };
        ctrl.isSelectButtonsQuestion = function () {
            return ctrl.question.answerType === 'select-buttons';
        };

        ctrl.statusCSSclass = function () {

            if (ctrl.question.isCurrent()) {

                var klass = 'current-question';

                if (ctrl.question.isLast()) {

                    if (ctrl.question.priorQuestions().some(function (question) {
                        return question.hasError();
                    })) {

                        klass = klass + ' error';
                    }
                }

                return klass;
            } else {

                if (ctrl.question.beforeCurrent()) {

                    if (ctrl.question.isAnswered() && ctrl.question.validAnswer()) {
                        return 'valid-answer';
                    }
                    if (ctrl.question.hasError()) {
                        return 'error';
                    }
                    if (ctrl.question.hasWarning()) {
                        return 'warning';
                    }

                    if (ctrl.question.isRequired() && !ctrl.question.isAnswered()) {
                        return 'warning';
                    }
                    if (ctrl.question.isRequired() && ctrl.question.isAnswered() && !ctrl.question.validAnswer()) {
                        return 'warning';
                    }
                } else if (ctrl.question.isVisited()) {

                    if (ctrl.question.isAnswered() && !ctrl.question.validAnswer()) {
                        return 'warning';
                    }
                    if (!ctrl.question.isAnswered() && ctrl.question.isRequired()) {
                        return 'warning';
                    }

                    return 'valid-answer';
                }

                return 'unvisited';
            }
        };
    }],

    template: "\n        <div class=\"related-question {{ctrl.statusCSSclass()}}\">\n            <div class=\"typed-question\" ng-if=\"ctrl.isHaveReadItQuestion()\"> <question-info class=\"question-info\" ng-model=\"ctrl.question\"> </question-info> </div>\n            <div class=\"typed-question\" ng-if=\"ctrl.isSelectQuestion()\"> <question-select class=\"question-select\" ng-model=\"ctrl.question\"> </question-select> </div>\n            <div class=\"typed-question\" ng-if=\"ctrl.isTextQuestion()\"> <question-text class=\"question-text\" ng-model=\"ctrl.question\"> </question-text> </div>\n            <div class=\"typed-question\" ng-if=\"ctrl.isBooleanQuestion()\"> <question-boolean class=\"question-boolean\"  ng-model=\"ctrl.question\"> </question-boolean> </div>\n            <div class=\"typed-question\" ng-if=\"ctrl.isSelectButtonsQuestion()\"> <question-select-buttons class=\"question-select-buttons\" ng-model=\"ctrl.question\"> </question-select-buttons> </div>\n        </div>\n\t"
});