"use strict";

var EnglishAbilityNames = {};

EnglishAbilityNames[AbilityNames.BUY] = "a";
EnglishAbilityNames[AbilityNames.PLAY_ATTACK] = "play attack";
EnglishAbilityNames[AbilityNames.DRAW_INSTRUCTIONS] = "b";
EnglishAbilityNames[AbilityNames.PLAY_INSTRUCTIONS] = "play";
EnglishAbilityNames[AbilityNames.RESOLVE_EVENT] = "c";
EnglishAbilityNames[AbilityNames.GAIN_BY_NAME] = "d";

EnglishAbilityNames[AbilityNames.FISHING_VILLAGE_ABILITY] = EnglishCardNames[CardNames.FISHING_VILLAGE].singular;
EnglishAbilityNames[AbilityNames.REMOVE_COST_REDUCTION] = "e";
EnglishAbilityNames[AbilityNames.HAVEN_ABILITY] = EnglishCardNames[CardNames.HAVEN].singular;
EnglishAbilityNames[AbilityNames.SAVE_RETURN_TO_HAND] = EnglishCardNames[CardNames.SAVE].singular;
EnglishAbilityNames[AbilityNames.CARAVAN_ABILITY] = EnglishCardNames[CardNames.CARAVAN].singular;
EnglishAbilityNames[AbilityNames.MERCHANT_SHIP_ABILITY] = EnglishCardNames[CardNames.MERCHANT_SHIP].singular;
EnglishAbilityNames[AbilityNames.OUTPOST_ABILITY] = EnglishCardNames[CardNames.OUTPOST].singular;
EnglishAbilityNames[AbilityNames.TACTICIAN_ABILITY] = EnglishCardNames[CardNames.TACTICIAN].singular;
EnglishAbilityNames[AbilityNames.WHARF_ABILITY] = EnglishCardNames[CardNames.WHARF].singular;
EnglishAbilityNames[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = EnglishCardNames[CardNames.HORSE_TRADERS].singular;
EnglishAbilityNames[AbilityNames.DUCHESS_MAY_GAIN] = EnglishCardNames[CardNames.DUCHESS].singular;
EnglishAbilityNames[AbilityNames.FOOLS_GOLD_TRASH] = EnglishCardNames[CardNames.FOOLS_GOLD].singular;
EnglishAbilityNames[AbilityNames.NOBLE_BRIGAND] = EnglishCardNames[CardNames.NOBLE_BRIGAND].singular;
EnglishAbilityNames[AbilityNames.HAGGLER_ABILITY] = EnglishCardNames[CardNames.HAGGLER].singular;
EnglishAbilityNames[AbilityNames.INN_ABILITY] = EnglishCardNames[CardNames.INN].singular;
EnglishAbilityNames[AbilityNames.MANDARIN] = EnglishCardNames[CardNames.MANDARIN].singular;
EnglishAbilityNames[AbilityNames.FARMLAND] = EnglishCardNames[CardNames.FARMLAND].singular;
EnglishAbilityNames[AbilityNames.GAIN_CHEAPER] = "Gain a cheaper card";
EnglishAbilityNames[AbilityNames.GAIN_TWO_RUINS] = EnglishCardNames[CardNames.DEATH_CART].singular;
EnglishAbilityNames[AbilityNames.HUNTING_GROUNDS] = EnglishCardNames[CardNames.HUNTING_GROUNDS].singular;
EnglishAbilityNames[AbilityNames.SQUIRE_GAIN_ATTACK] = EnglishCardNames[CardNames.SQUIRE].singular;
EnglishAbilityNames[AbilityNames.URCHIN_TRANSFORM] = EnglishCardNames[CardNames.URCHIN].singular;
EnglishAbilityNames[AbilityNames.FEODUM_GAIN_SILVERS] = EnglishCardNames[CardNames.FEODUM].singular;
EnglishAbilityNames[AbilityNames.DOCTOR_OVERPAY] = "Overpay";
EnglishAbilityNames[AbilityNames.RESOLVE_DOCTOR_OVERPAY] = EnglishCardNames[CardNames.DOCTOR].singular;
EnglishAbilityNames[AbilityNames.HERALD_OVERPAY] = "Overpay";
EnglishAbilityNames[AbilityNames.RESOLVE_HERALD_OVERPAY] = EnglishCardNames[CardNames.HERALD].singular;
EnglishAbilityNames[AbilityNames.MASTERPIECE_OVERPAY] = "Overpay";
EnglishAbilityNames[AbilityNames.RESOLVE_MASTERPIECE_OVERPAY] = EnglishCardNames[CardNames.MASTERPIECE].singular;
EnglishAbilityNames[AbilityNames.STONEMASON_OVERPAY] = "Overpay";
EnglishAbilityNames[AbilityNames.RESOLVE_STONEMASON_OVERPAY] = EnglishCardNames[CardNames.STONEMASON].singular;
EnglishAbilityNames[AbilityNames.EXCHANGE] = "Exchange";
EnglishAbilityNames[AbilityNames.SHUFFLE] = "Shuffle";
EnglishAbilityNames[AbilityNames.AMULET] = EnglishCardNames[CardNames.AMULET].singular;
EnglishAbilityNames[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = EnglishCardNames[CardNames.CARAVAN_GUARD].singular;
EnglishAbilityNames[AbilityNames.DUNGEON_ABILITY] = EnglishCardNames[CardNames.DUNGEON].singular;
EnglishAbilityNames[AbilityNames.GEAR_ABILITY] = EnglishCardNames[CardNames.GEAR].singular;
EnglishAbilityNames[AbilityNames.MESSENGER_GAIN] = EnglishCardNames[CardNames.MESSENGER].singular;
EnglishAbilityNames[AbilityNames.BRIDGE_TROLL_ABILITY] = EnglishCardNames[CardNames.BRIDGE_TROLL].singular;
EnglishAbilityNames[AbilityNames.HAUNTED_WOODS_DURATION] = "Draw";
EnglishAbilityNames[AbilityNames.HAUNTED_WOODS_TOPDECK] = "Topdeck";
EnglishAbilityNames[AbilityNames.LOST_CITY] = EnglishCardNames[CardNames.LOST_CITY].singular;
EnglishAbilityNames[AbilityNames.WINE_MERCHANT_DISCARD] = EnglishCardNames[CardNames.WINE_MERCHANT].singular;
EnglishAbilityNames[AbilityNames.HIRELING] = EnglishCardNames[CardNames.HIRELING].singular;
EnglishAbilityNames[AbilityNames.SWAMP_HAG_DURATION] = EnglishCardNames[CardNames.SWAMP_HAG].singular;
EnglishAbilityNames[AbilityNames.TRASH_TOKEN_ABILITY] = EnglishCardNames[CardNames.PLAN].singular;
EnglishAbilityNames[AbilityNames.EMPORIUM_GAIN_POINTS] = EnglishCardNames[CardNames.EMPORIUM].singular;
EnglishAbilityNames[AbilityNames.ROCKS_GAINS_SILVER] = EnglishCardNames[CardNames.ROCKS].singular;
EnglishAbilityNames[AbilityNames.FORTUNE] = EnglishCardNames[CardNames.FORTUNE].singular;
EnglishAbilityNames[AbilityNames.CRUMBLING_CASTLE] = EnglishCardNames[CardNames.CRUMBLING_CASTLE].singular;
EnglishAbilityNames[AbilityNames.HAUNTED_CASTLE] = EnglishCardNames[CardNames.HAUNTED_CASTLE].singular;
EnglishAbilityNames[AbilityNames.SPRAWLING_CASTLE] = EnglishCardNames[CardNames.SPRAWLING_CASTLE].singular;
EnglishAbilityNames[AbilityNames.GRAND_CASTLE] = EnglishCardNames[CardNames.GRAND_CASTLE].singular;
EnglishAbilityNames[AbilityNames.ENCHANTRESS_ABILITY] = EnglishCardNames[CardNames.ENCHANTRESS].singular;
EnglishAbilityNames[AbilityNames.ENCHANTRESS_CANTRIP] = "+1 Card, +1 Action";
EnglishAbilityNames[AbilityNames.TEMPLE] = EnglishCardNames[CardNames.TEMPLE].singular;
EnglishAbilityNames[AbilityNames.VILLA] = EnglishCardNames[CardNames.VILLA].singular;
EnglishAbilityNames[AbilityNames.ARCHIVE_ABILITY] = EnglishCardNames[CardNames.ARCHIVE].singular;
EnglishAbilityNames[AbilityNames.CAPITAL_TAKE_DEBT] = EnglishCardNames[CardNames.CAPITAL].singular;
EnglishAbilityNames[AbilityNames.CHARM] = EnglishCardNames[CardNames.CHARM].singular;
EnglishAbilityNames[AbilityNames.FORUM] = EnglishCardNames[CardNames.FORUM].singular;
EnglishAbilityNames[AbilityNames.HERMIT] = EnglishCardNames[CardNames.HERMIT].singular;
EnglishAbilityNames[AbilityNames.DONATE] = EnglishCardNames[CardNames.DONATE].singular;
EnglishAbilityNames[AbilityNames.TAX_RECEIVE] = EnglishCardNames[CardNames.TAX].singular;
EnglishAbilityNames[AbilityNames.DOMINATE] = EnglishCardNames[CardNames.DOMINATE].singular;
EnglishAbilityNames[AbilityNames.VP_TO_AQUEDUCT] = "VP to Aqueduct";
EnglishAbilityNames[AbilityNames.VP_FROM_AQUEDUCT] = "VP from Aqueduct";
EnglishAbilityNames[AbilityNames.ARENA_DISCARD_ACTION] = EnglishCardNames[CardNames.ARENA].singular;
EnglishAbilityNames[AbilityNames.VP_FROM_BASILICA] = EnglishCardNames[CardNames.BASILICA].singular;
EnglishAbilityNames[AbilityNames.VP_FROM_BATHS] = EnglishCardNames[CardNames.BATHS].singular;
EnglishAbilityNames[AbilityNames.VP_FROM_BATTLEFIELD] = EnglishCardNames[CardNames.BATTLEFIELD].singular;
EnglishAbilityNames[AbilityNames.VP_FROM_COLONNADE] = EnglishCardNames[CardNames.COLONNADE].singular;
EnglishAbilityNames[AbilityNames.VP_TO_DEFILED_SHRINE] = "VP to Defiled Shrine";
EnglishAbilityNames[AbilityNames.VP_FROM_DEFILED_SHRINE] = "VP from Defiled Shrine";
EnglishAbilityNames[AbilityNames.VP_FROM_LABYRINTH] = EnglishCardNames[CardNames.LABYRINTH].singular;
EnglishAbilityNames[AbilityNames.MOUNTAIN_PASS_BIDDING] = EnglishCardNames[CardNames.MOUNTAIN_PASS].singular;
EnglishAbilityNames[AbilityNames.TOMB] = EnglishCardNames[CardNames.TOMB].singular;
EnglishAbilityNames[AbilityNames.LIGHTHOUSE_COIN] = EnglishCardNames[CardNames.LIGHTHOUSE].singular;
EnglishAbilityNames[AbilityNames.REACTION] = "Reaction";
EnglishAbilityNames[AbilityNames.CALL_GUIDE] = "Call " + EnglishCardNames[CardNames.GUIDE].singular;
EnglishAbilityNames[AbilityNames.CALL_RATCATCHER] = "Call " + EnglishCardNames[CardNames.RATCATCHER].singular;
EnglishAbilityNames[AbilityNames.CALL_TRANSMOGRIFY] = "Call " + EnglishCardNames[CardNames.TRANSMOGRIFY].singular;
EnglishAbilityNames[AbilityNames.CALL_TEACHER] = "Call " + EnglishCardNames[CardNames.TEACHER].singular;
EnglishAbilityNames[AbilityNames.CALL_DUPLICATE] = "Call " + EnglishCardNames[CardNames.DUPLICATE].singular;
EnglishAbilityNames[AbilityNames.CALL_COIN] = "Call " + EnglishCardNames[CardNames.COIN_OF_THE_REALM].singular;
EnglishAbilityNames[AbilityNames.CALL_ROYAL_CARRIAGE] = "Call " + EnglishCardNames[CardNames.ROYAL_CARRIAGE].singular;
EnglishAbilityNames[AbilityNames.START_OF_TURN] = "start your turn";
EnglishAbilityNames[AbilityNames.START_BUY_PHASE] = "START_BUY_PHASE";
EnglishAbilityNames[AbilityNames.END_OF_BUY_PHASE] = "END_OF_BUY_PHASE";
EnglishAbilityNames[AbilityNames.BETWEEN_TURNS] = "BETWEEN_TURNS";
EnglishAbilityNames[AbilityNames.END_OF_TURN] = "END_OF_TURN";
EnglishAbilityNames[AbilityNames.SUMMON_PLAY] = EnglishCardNames[CardNames.SUMMON].singular;
EnglishAbilityNames[AbilityNames.CARAVAN_GUARD_REACTION] = EnglishCardNames[CardNames.CARAVAN_GUARD].singular;
EnglishAbilityNames[AbilityNames.TRAVELLING_FAIR_REACTION] = EnglishCardNames[CardNames.TRAVELLING_FAIR].singular;
EnglishAbilityNames[AbilityNames.MOAT_REACTION] = EnglishCardNames[CardNames.MOAT].singular;
EnglishAbilityNames[AbilityNames.HORSE_TRADERS_REACTION] = EnglishCardNames[CardNames.HORSE_TRADERS].singular;
EnglishAbilityNames[AbilityNames.BEGGAR_REACTION] = EnglishCardNames[CardNames.BEGGAR].singular;
EnglishAbilityNames[AbilityNames.MARKET_SQUARE_REACTION] = EnglishCardNames[CardNames.MARKET_SQUARE].singular;
EnglishAbilityNames[AbilityNames.TRADER_REACTION] = EnglishCardNames[CardNames.TRADER].singular;
EnglishAbilityNames[AbilityNames.DIPLOMAT_REACTION] = EnglishCardNames[CardNames.DIPLOMAT].singular;
EnglishAbilityNames[AbilityNames.TOPDECK_REACTION] = "Topdeck";
EnglishAbilityNames[AbilityNames.WATCHTOWER_TRASH_REACTION] = "Trash";
EnglishAbilityNames[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "Topdeck";
EnglishAbilityNames[AbilityNames.PRINCE_PLAY] = EnglishCardNames[CardNames.PRINCE].singular;
EnglishAbilityNames[AbilityNames.DELUDED_RETURNED] = "return state";
EnglishAbilityNames[AbilityNames.ENVIOUS_RETURNED] = "return state";
EnglishAbilityNames[AbilityNames.RECEIVE_BOON] = "Receive Boon";
EnglishAbilityNames[AbilityNames.COBBLER_ABILITY] = EnglishCardNames[CardNames.COBBLER].singular;
EnglishAbilityNames[AbilityNames.CRYPT_ABILITY] = EnglishCardNames[CardNames.CRYPT].singular;
EnglishAbilityNames[AbilityNames.DEN_OF_SIN_ABILITY] = EnglishCardNames[CardNames.DEN_OF_SIN].singular;
EnglishAbilityNames[AbilityNames.FAITHFUL_HOUND] = EnglishCardNames[CardNames.FAITHFUL_HOUND].singular;
EnglishAbilityNames[AbilityNames.FAITHFUL_HOUND_RETURN] = EnglishCardNames[CardNames.FAITHFUL_HOUND].singular;
EnglishAbilityNames[AbilityNames.GHOST_TOWN_ABILITY] = EnglishCardNames[CardNames.GHOST_TOWN].singular;
EnglishAbilityNames[AbilityNames.GUARDIAN_COIN] = EnglishCardNames[CardNames.GUARDIAN].singular;
EnglishAbilityNames[AbilityNames.RAIDER_ABILITY] = EnglishCardNames[CardNames.RAIDER].singular;
EnglishAbilityNames[AbilityNames.SECRET_CAVE] = EnglishCardNames[CardNames.SECRET_CAVE].singular;
EnglishAbilityNames[AbilityNames.CEMETERY] = EnglishCardNames[CardNames.CEMETERY].singular;
EnglishAbilityNames[AbilityNames.HAUNTED_MIRROR] = EnglishCardNames[CardNames.HAUNTED_MIRROR].singular;
EnglishAbilityNames[AbilityNames.GHOST] = EnglishCardNames[CardNames.GHOST].singular;
EnglishAbilityNames[AbilityNames.REMOVE_FLIPPED_STATUS] = "X";
EnglishAbilityNames[AbilityNames.LOST_IN_THE_WOODS] = EnglishCardNames[CardNames.LOST_IN_THE_WOODS].singular;
EnglishAbilityNames[AbilityNames.BLESSED_VILLAGE] = EnglishCardNames[CardNames.BLESSED_VILLAGE].singular;
EnglishAbilityNames[AbilityNames.FORTRESS] = EnglishCardNames[CardNames.FORTRESS].singular;
EnglishAbilityNames[AbilityNames.RECEIVE_HEX] = "Receive Hex";
EnglishAbilityNames[AbilityNames.HOVEL_TRASH] = EnglishCardNames[CardNames.HOVEL].singular;
EnglishAbilityNames[AbilityNames.SAUNA_TRASH_ABILITY] = EnglishCardNames[CardNames.SAUNA].singular;
EnglishAbilityNames[AbilityNames.TUNNEL_REVEAL] = EnglishCardNames[CardNames.TUNNEL].singular;
EnglishAbilityNames[AbilityNames.CHANGELING_MAY_EXCHANGE] = EnglishCardNames[CardNames.CHANGELING].singular;
EnglishAbilityNames[AbilityNames.DUCAT] = EnglishCardNames[CardNames.DUCAT].singular;
EnglishAbilityNames[AbilityNames.CARGO_SHIP_SET_ASIDE] = EnglishCardNames[CardNames.CARGO_SHIP].singular;
EnglishAbilityNames[AbilityNames.CARGO_SHIP_RETURN] = EnglishCardNames[CardNames.CARGO_SHIP].singular;
EnglishAbilityNames[AbilityNames.IMPROVE] = EnglishCardNames[CardNames.IMPROVE].singular;
EnglishAbilityNames[AbilityNames.PRIEST] = EnglishCardNames[CardNames.PRIEST].singular;
EnglishAbilityNames[AbilityNames.RESEARCH] = EnglishCardNames[CardNames.RESEARCH].singular;
EnglishAbilityNames[AbilityNames.SILK_MERCHANT] = EnglishCardNames[CardNames.SILK_MERCHANT].singular;
EnglishAbilityNames[AbilityNames.LACKEYS] = EnglishCardNames[CardNames.LACKEYS].singular;
EnglishAbilityNames[AbilityNames.SPICES] = EnglishCardNames[CardNames.SPICES].singular;
EnglishAbilityNames[AbilityNames.KEY] = EnglishCardNames[CardNames.KEY].singular;
EnglishAbilityNames[AbilityNames.TREASURE_CHEST] = EnglishCardNames[CardNames.TREASURE_CHEST].singular;
EnglishAbilityNames[AbilityNames.FLAG_BEARER] = EnglishCardNames[CardNames.FLAG_BEARER].singular;
EnglishAbilityNames[AbilityNames.CATHEDRAL] = EnglishCardNames[CardNames.CATHEDRAL].singular;
EnglishAbilityNames[AbilityNames.CITY_GATE] = EnglishCardNames[CardNames.CITY_GATE].singular;
EnglishAbilityNames[AbilityNames.PAGEANT] = EnglishCardNames[CardNames.PAGEANT].singular;
EnglishAbilityNames[AbilityNames.SEWERS_MAY_TRASH] = EnglishCardNames[CardNames.SEWERS].singular;
EnglishAbilityNames[AbilityNames.EXPLORATION] = EnglishCardNames[CardNames.EXPLORATION].singular;
EnglishAbilityNames[AbilityNames.FAIR] = EnglishCardNames[CardNames.FAIR].singular;
EnglishAbilityNames[AbilityNames.SILOS] = EnglishCardNames[CardNames.SILOS].singular;
EnglishAbilityNames[AbilityNames.ACADEMY] = EnglishCardNames[CardNames.ACADEMY].singular;
EnglishAbilityNames[AbilityNames.GUILDHALL] = EnglishCardNames[CardNames.GUILDHALL].singular;
EnglishAbilityNames[AbilityNames.PIAZZA] = EnglishCardNames[CardNames.PIAZZA].singular;
EnglishAbilityNames[AbilityNames.BARRACKS] = EnglishCardNames[CardNames.BARRACKS].singular;
EnglishAbilityNames[AbilityNames.CROP_ROTATION] = EnglishCardNames[CardNames.CROP_ROTATION].singular;
EnglishAbilityNames[AbilityNames.INNOVATION] = EnglishCardNames[CardNames.INNOVATION].singular;
EnglishAbilityNames[AbilityNames.CITADEL] = EnglishCardNames[CardNames.CITADEL].singular;
EnglishAbilityNames[AbilityNames.SINISTER_PLOT] = EnglishCardNames[CardNames.SINISTER_PLOT].singular;
EnglishAbilityNames[AbilityNames.ENCAMPMENT_RETURNED] = EnglishCardNames[CardNames.ENCAMPMENT].singular;
EnglishAbilityNames[AbilityNames.EMBARGO_GAIN_CURSE] = EnglishCardNames[CardNames.EMBARGO].singular;
EnglishAbilityNames[AbilityNames.POSSESSION_GAIN_INSTEAD] = EnglishCardNames[CardNames.POSSESSION].singular;
EnglishAbilityNames[AbilityNames.TRADE_ROUTE_MOVE_TOKEN] = EnglishCardNames[CardNames.TRADE_ROUTE].singular;
EnglishAbilityNames[AbilityNames.CAPTAIN] = EnglishCardNames[CardNames.CAPTAIN].singular;
EnglishAbilityNames[AbilityNames.CHURCH] = EnglishCardNames[CardNames.CHURCH].singular;
EnglishAbilityNames[AbilityNames.POSSESSION_SETS_ASIDE] = EnglishCardNames[CardNames.POSSESSION].singular;
EnglishAbilityNames[AbilityNames.MINT] = EnglishCardNames[CardNames.MINT].singular;
EnglishAbilityNames[AbilityNames.SLEIGH_TOPDECK_REACTION] = "Topdeck";
EnglishAbilityNames[AbilityNames.SLEIGH_PUT_IN_HAND_REACTION] = "Put in Hand";
EnglishAbilityNames[AbilityNames.BLACK_CAT_REACTION] = EnglishCardNames[CardNames.BLACK_CAT].singular;
EnglishAbilityNames[AbilityNames.DISCARD_FROM_EXILE] = "Discard from Exile";
EnglishAbilityNames[AbilityNames.EXILE_BY_NAME] = "Exile";
EnglishAbilityNames[AbilityNames.SHEEPDOG_REACTION] = EnglishCardNames[CardNames.SHEEPDOG].singular;
EnglishAbilityNames[AbilityNames.CAVALRY] = EnglishCardNames[CardNames.CAVALRY].singular;
EnglishAbilityNames[AbilityNames.HOSTELRY] = EnglishCardNames[CardNames.HOSTELRY].singular;
EnglishAbilityNames[AbilityNames.VILLAGE_GREEN_VILLAGE] = EnglishCardNames[CardNames.VILLAGE_GREEN].singular;
EnglishAbilityNames[AbilityNames.VILLAGE_GREEN_PLAY] = EnglishCardNames[CardNames.VILLAGE_GREEN].singular;
EnglishAbilityNames[AbilityNames.BARGE] = EnglishCardNames[CardNames.BARGE].singular;
EnglishAbilityNames[AbilityNames.FALCONER_REACTION] = EnglishCardNames[CardNames.FALCONER].singular;
EnglishAbilityNames[AbilityNames.GATEKEEPER_EXILE] = EnglishCardNames[CardNames.GATEKEEPER].singular;
EnglishAbilityNames[AbilityNames.GATEKEEPER_DURATION] = EnglishCardNames[CardNames.GATEKEEPER].singular;
EnglishAbilityNames[AbilityNames.LIVERY] = EnglishCardNames[CardNames.LIVERY].singular;
EnglishAbilityNames[AbilityNames.MASTERMIND] = EnglishCardNames[CardNames.MASTERMIND].singular;
EnglishAbilityNames[AbilityNames.DELAY_PLAY] = EnglishCardNames[CardNames.DELAY].singular;
EnglishAbilityNames[AbilityNames.INVEST] = EnglishCardNames[CardNames.INVEST].singular;
EnglishAbilityNames[AbilityNames.REAP_PLAY] = EnglishCardNames[CardNames.REAP].singular;
EnglishAbilityNames[AbilityNames.PLUS_CARD_TOKEN] = "+Card";
EnglishAbilityNames[AbilityNames.PLUS_BUY_TOKEN] = "+Buy";
EnglishAbilityNames[AbilityNames.PLUS_ACTION_TOKEN] = "+Action";
EnglishAbilityNames[AbilityNames.PLUS_COIN_TOKEN] = "+Coin";
EnglishAbilityNames[AbilityNames.KILN] = EnglishCardNames[CardNames.KILN].singular;
EnglishAbilityNames[AbilityNames.CHAMELEON_RESOLUTION] = EnglishCardNames[CardNames.WAY_OF_THE_CHAMELEON].singular;
EnglishAbilityNames[AbilityNames.RETURN_MINUS_COIN_TOKEN] = "Return -Coin Token";
EnglishAbilityNames[AbilityNames.TURTLE_PLAY] = EnglishCardNames[CardNames.WAY_OF_THE_TURTLE].singular;
EnglishAbilityNames[AbilityNames.GIVE_PLUS_CARDS] = EnglishAbilityNames[AbilityNames.PLUS_CARD_TOKEN];
EnglishAbilityNames[AbilityNames.GIVE_PLUS_BUYS] = EnglishAbilityNames[AbilityNames.PLUS_BUY_TOKEN];
EnglishAbilityNames[AbilityNames.GIVE_PLUS_ACTIONS] = EnglishAbilityNames[AbilityNames.PLUS_ACTION_TOKEN];
EnglishAbilityNames[AbilityNames.GIVE_PLUS_COINS] = EnglishAbilityNames[AbilityNames.PLUS_COIN_TOKEN];

EnglishAbilityNames[AbilityNames.ASTROLABE_ABILITY] = EnglishCardNames[CardNames.ASTROLABE].singular;
EnglishAbilityNames[AbilityNames.BLOCKADE_ABILITY] = EnglishCardNames[CardNames.BLOCKADE].singular;
EnglishAbilityNames[AbilityNames.CORSAIR_NEXT_TURN_ABILITY] = EnglishCardNames[CardNames.CORSAIR].singular;
EnglishAbilityNames[AbilityNames.CORSAIR_TRASH] = EnglishCardNames[CardNames.CORSAIR].singular;
EnglishAbilityNames[AbilityNames.MONKEY_NEXT_TURN_ABILITY] = EnglishCardNames[CardNames.MONKEY].singular;
EnglishAbilityNames[AbilityNames.PIRATE_ABILITY] = EnglishCardNames[CardNames.PIRATE].singular;
EnglishAbilityNames[AbilityNames.PIRATE_REACTION] = EnglishCardNames[CardNames.PIRATE].singular;
EnglishAbilityNames[AbilityNames.SAILOR_NEXT_TURN] = EnglishCardNames[CardNames.SAILOR].singular;
EnglishAbilityNames[AbilityNames.SAILOR_REACTION] = EnglishCardNames[CardNames.SAILOR].singular;
EnglishAbilityNames[AbilityNames.SEA_WITCH_ABILITY] = EnglishCardNames[CardNames.SEA_WITCH].singular;
EnglishAbilityNames[AbilityNames.TIDE_POOLS_ABILITY] = EnglishCardNames[CardNames.TIDE_POOLS].singular;
EnglishAbilityNames[AbilityNames.TREASURY] = EnglishCardNames[CardNames.TREASURY].singular;
EnglishAbilityNames[AbilityNames.CLERK_ABILITY] = EnglishCardNames[CardNames.CLERK].singular;
EnglishAbilityNames[AbilityNames.BERSERKER] = EnglishCardNames[CardNames.BERSERKER].singular;
EnglishAbilityNames[AbilityNames.CAULDRON_CURSING] = EnglishCardNames[CardNames.CAULDRON].singular;
EnglishAbilityNames[AbilityNames.GUARD_DOG_REACTION] = EnglishCardNames[CardNames.GUARD_DOG].singular;
EnglishAbilityNames[AbilityNames.NOMADS] = EnglishCardNames[CardNames.NOMADS].singular;
EnglishAbilityNames[AbilityNames.SOUK] = EnglishCardNames[CardNames.SOUK].singular;
EnglishAbilityNames[AbilityNames.TRAIL_REACTION] = EnglishCardNames[CardNames.TRAIL].singular;
EnglishAbilityNames[AbilityNames.WEAVER_PLAY] = EnglishCardNames[CardNames.WEAVER].singular;
EnglishAbilityNames[AbilityNames.UNUSED_ABILITY_1] = "unused";
EnglishAbilityNames[AbilityNames.UNUSED_ABILITY_2] = "unused";
EnglishAbilityNames[AbilityNames.UNUSED_ABILITY_2] = "unused";
EnglishAbilityNames[AbilityNames.UNUSED_ABILITY_2] = "unused";
EnglishAbilityNames[AbilityNames.UNUSED_ABILITY_2] = "unused";

//Promos 2E
EnglishAbilityNames[AbilityNames.MARCHLAND] = EnglishCardNames[CardNames.MARCHLAND].singular;

// Allies
EnglishAbilityNames[AbilityNames.CONJURER] = EnglishCardNames[CardNames.CONJURER].singular;
EnglishAbilityNames[AbilityNames.CONTRACT_ABILITY] = EnglishCardNames[CardNames.CONTRACT].singular;
EnglishAbilityNames[AbilityNames.ENCHANTRESS_REPLACE_ABILITY] = EnglishCardNames[CardNames.ENCHANTRESS].singular;
EnglishAbilityNames[AbilityNames.GALLERIA] = EnglishCardNames[CardNames.GALLERIA].singular;
EnglishAbilityNames[AbilityNames.GARRISON_ADD_TOKEN] = EnglishCardNames[CardNames.GARRISON].singular;
EnglishAbilityNames[AbilityNames.GARRISON_REMOVE_ABILITY] = EnglishCardNames[CardNames.GARRISON].singular;
EnglishAbilityNames[AbilityNames.GUILDMASTER] = EnglishCardNames[CardNames.GUILDMASTER].singular;
EnglishAbilityNames[AbilityNames.HIGHWAYMAN_NEXT_TURN_ABILITY] = EnglishCardNames[CardNames.HIGHWAYMAN].singular;
EnglishAbilityNames[AbilityNames.HIGHWAYMAN_NULLIFIED] = EnglishCardNames[CardNames.HIGHWAYMAN].singular;
EnglishAbilityNames[AbilityNames.HIGHWAYMAN_REPLACE_ABILITY] = EnglishCardNames[CardNames.HIGHWAYMAN].singular;
EnglishAbilityNames[AbilityNames.IMPORTER_ABILITY] = EnglishCardNames[CardNames.IMPORTER].singular;
EnglishAbilityNames[AbilityNames.LICH] = EnglishCardNames[CardNames.LICH].singular;
EnglishAbilityNames[AbilityNames.ROYAL_GALLEY_PLAY] = EnglishCardNames[CardNames.ROYAL_GALLEY].singular;
EnglishAbilityNames[AbilityNames.ROYAL_GALLEY_SET_ASIDE] = EnglishCardNames[CardNames.ROYAL_GALLEY].singular;
EnglishAbilityNames[AbilityNames.SKIRMISHER] = EnglishCardNames[CardNames.SKIRMISHER].singular;
EnglishAbilityNames[AbilityNames.STRONGHOLD_ABILITY] = EnglishCardNames[CardNames.STRONGHOLD].singular;
EnglishAbilityNames[AbilityNames.SYCOPHANT] = EnglishCardNames[CardNames.SYCOPHANT].singular;
EnglishAbilityNames[AbilityNames.TERRITORY_GAIN] = EnglishCardNames[CardNames.TERRITORY].singular;
EnglishAbilityNames[AbilityNames.WARLORD_ABILITY] = EnglishCardNames[CardNames.WARLORD].singular;

EnglishAbilityNames[AbilityNames.ARCHITECTS_GUILD] = EnglishCardNames[CardNames.ARCHITECTS_GUILD].singular;
EnglishAbilityNames[AbilityNames.BAND_OF_NOMADS_ACTION] = "Action";
EnglishAbilityNames[AbilityNames.BAND_OF_NOMADS_BUY] = "Buy";
EnglishAbilityNames[AbilityNames.BAND_OF_NOMADS_CARD] = "Card";
EnglishAbilityNames[AbilityNames.CAVE_DWELLERS] = EnglishCardNames[CardNames.CAVE_DWELLERS].singular;
EnglishAbilityNames[AbilityNames.CIRCLE_OF_WITCHES] = EnglishCardNames[CardNames.CIRCLE_OF_WITCHES].singular;
EnglishAbilityNames[AbilityNames.CITY_STATE] = EnglishCardNames[CardNames.CITY_STATE].singular;
EnglishAbilityNames[AbilityNames.CITY_STATE_PLAY] = EnglishCardNames[CardNames.CITY_STATE].singular;
EnglishAbilityNames[AbilityNames.COASTAL_HAVEN] = EnglishCardNames[CardNames.COASTAL_HAVEN].singular;
EnglishAbilityNames[AbilityNames.CRAFTERS_GUILD] = EnglishCardNames[CardNames.CRAFTERS_GUILD].singular;
EnglishAbilityNames[AbilityNames.DESERT_GUIDES] = EnglishCardNames[CardNames.DESERT_GUIDES].singular;
EnglishAbilityNames[AbilityNames.FAMILY_OF_INVENTORS] = EnglishCardNames[CardNames.FAMILY_OF_INVENTORS].singular;
EnglishAbilityNames[AbilityNames.FELLOWSHIP_OF_SCRIBES] = EnglishCardNames[CardNames.FELLOWSHIP_OF_SCRIBES].singular;
EnglishAbilityNames[AbilityNames.FOREST_DWELLERS] = EnglishCardNames[CardNames.FOREST_DWELLERS].singular;
EnglishAbilityNames[AbilityNames.GANG_OF_PICKPOCKETS] = EnglishCardNames[CardNames.GANG_OF_PICKPOCKETS].singular;
EnglishAbilityNames[AbilityNames.ISLAND_FOLK] = EnglishCardNames[CardNames.ISLAND_FOLK].singular;
EnglishAbilityNames[AbilityNames.LEAGUE_OF_BANKERS] = EnglishCardNames[CardNames.LEAGUE_OF_BANKERS].singular;
EnglishAbilityNames[AbilityNames.LEAGUE_OF_SHOPKEEPERS] = EnglishCardNames[CardNames.LEAGUE_OF_SHOPKEEPERS].singular;
EnglishAbilityNames[AbilityNames.MARKET_TOWNS] = EnglishCardNames[CardNames.MARKET_TOWNS].singular;
EnglishAbilityNames[AbilityNames.MOUNTAIN_FOLK] = EnglishCardNames[CardNames.MOUNTAIN_FOLK].singular;
EnglishAbilityNames[AbilityNames.PEACEFUL_CULT] = EnglishCardNames[CardNames.PEACEFUL_CULT].singular;
EnglishAbilityNames[AbilityNames.TRAPPERS_LODGE] = EnglishCardNames[CardNames.TRAPPERS_LODGE].singular;
EnglishAbilityNames[AbilityNames.WOODWORKERS_GUILD] = EnglishCardNames[CardNames.WOODWORKERS_GUILD].singular;
EnglishAbilityNames[AbilityNames.MERCHANT_GUILD] = EnglishCardNames[CardNames.MERCHANT_GUILD].singular;

// Misc
EnglishAbilityNames[AbilityNames.ALCHEMIST_TOPDECK] = EnglishCardNames[CardNames.ALCHEMIST].singular;
EnglishAbilityNames[AbilityNames.HERBALIST_TOPDECK] = EnglishCardNames[CardNames.HERBALIST].singular;
EnglishAbilityNames[AbilityNames.SCHEME_TOPDECK] = EnglishCardNames[CardNames.SCHEME].singular;
EnglishAbilityNames[AbilityNames.FROG_TOPDECK] = EnglishCardNames[CardNames.WAY_OF_THE_FROG].singular;
EnglishAbilityNames[AbilityNames.MERCHANT_CAMP_TOPDECK] = EnglishCardNames[CardNames.MERCHANT_CAMP].singular;
EnglishAbilityNames[AbilityNames.WALLED_VILLAGE_TOPDECK] = EnglishCardNames[CardNames.WALLED_VILLAGE].singular;
EnglishAbilityNames[AbilityNames.TENT_TOPDECK] = EnglishCardNames[CardNames.TENT].singular;
EnglishAbilityNames[AbilityNames.HORN_TOPDECK] = EnglishCardNames[CardNames.HORN].singular;
EnglishAbilityNames[AbilityNames.PAGE_EXCHANGE] = EnglishCardNames[CardNames.TREASURE_HUNTER].singular;
EnglishAbilityNames[AbilityNames.TREASURE_HUNTER_EXCHANGE] = EnglishCardNames[CardNames.WARRIOR].singular;
EnglishAbilityNames[AbilityNames.WARRIOR_EXCHANGE] = EnglishCardNames[CardNames.HERO].singular;
EnglishAbilityNames[AbilityNames.HERO_EXCHANGE] = EnglishCardNames[CardNames.CHAMPION].singular;
EnglishAbilityNames[AbilityNames.PEASANT_EXCHANGE] = EnglishCardNames[CardNames.SOLDIER].singular;
EnglishAbilityNames[AbilityNames.SOLDIER_EXCHANGE] = EnglishCardNames[CardNames.FUGITIVE].singular;
EnglishAbilityNames[AbilityNames.FUGITIVE_EXCHANGE] = EnglishCardNames[CardNames.DISCIPLE].singular;
EnglishAbilityNames[AbilityNames.DISCIPLE_EXCHANGE] = EnglishCardNames[CardNames.TEACHER].singular;

//Plunder
EnglishAbilityNames[AbilityNames.CAGE_TRASH] = EnglishCardNames[CardNames.CAGE].singular;
EnglishAbilityNames[AbilityNames.CAGE_PUT_IN_HAND] = EnglishCardNames[CardNames.CAGE].singular;
EnglishAbilityNames[AbilityNames.GROTTO] = EnglishCardNames[CardNames.GROTTO].singular;
EnglishAbilityNames[AbilityNames.JEWELLED_EGG_GAIN_LOOT] = EnglishCardNames[CardNames.JEWELLED_EGG].singular;
EnglishAbilityNames[AbilityNames.SEARCH] = EnglishCardNames[CardNames.SEARCH].singular;
EnglishAbilityNames[AbilityNames.SHAMAN] = EnglishCardNames[CardNames.SHAMAN].singular;
EnglishAbilityNames[AbilityNames.SECLUDED_SHRINE] = EnglishCardNames[CardNames.SECLUDED_SHRINE].singular;
EnglishAbilityNames[AbilityNames.SIREN_GAIN] = EnglishCardNames[CardNames.SIREN].singular;
EnglishAbilityNames[AbilityNames.SIREN_DURATION] = EnglishCardNames[CardNames.SIREN].singular;
EnglishAbilityNames[AbilityNames.STOWAWAY_DRAW] = EnglishCardNames[CardNames.STOWAWAY].singular;
EnglishAbilityNames[AbilityNames.STOWAWAY_REACTION] = EnglishCardNames[CardNames.STOWAWAY].singular;
EnglishAbilityNames[AbilityNames.TASKMASTER] = EnglishCardNames[CardNames.TASKMASTER].singular;
EnglishAbilityNames[AbilityNames.ABUNDANCE] = EnglishCardNames[CardNames.ABUNDANCE].singular;
EnglishAbilityNames[AbilityNames.CABIN_BOY] = EnglishCardNames[CardNames.CABIN_BOY].singular;
EnglishAbilityNames[AbilityNames.CRUCIBLE] = EnglishCardNames[CardNames.CRUCIBLE].singular;
EnglishAbilityNames[AbilityNames.FLAGSHIP_ACTIVATE] = EnglishCardNames[CardNames.FLAGSHIP].singular;
EnglishAbilityNames[AbilityNames.FLAGSHIP_REPLAY] = EnglishCardNames[CardNames.FLAGSHIP].singular;
EnglishAbilityNames[AbilityNames.GONDOLA_DURATION] = EnglishCardNames[CardNames.GONDOLA].singular;
EnglishAbilityNames[AbilityNames.GONDOLA_PLAY] = EnglishCardNames[CardNames.GONDOLA].singular;
EnglishAbilityNames[AbilityNames.HARBOR_VILLAGE] = EnglishCardNames[CardNames.HARBOR_VILLAGE].singular;
EnglishAbilityNames[AbilityNames.LANDING_PARTY] = EnglishCardNames[CardNames.LANDING_PARTY].singular;
EnglishAbilityNames[AbilityNames.MAPMAKER] = EnglishCardNames[CardNames.MAPMAKER].singular;
EnglishAbilityNames[AbilityNames.ROPE] = EnglishCardNames[CardNames.ROPE].singular;
EnglishAbilityNames[AbilityNames.BURIED_TREASURE_PLAY] = EnglishCardNames[CardNames.BURIED_TREASURE].singular;
EnglishAbilityNames[AbilityNames.BURIED_TREASURE_DURATION] = EnglishCardNames[CardNames.BURIED_TREASURE].singular;
EnglishAbilityNames[AbilityNames.CREW_DURATION] = EnglishCardNames[CardNames.CREW].singular;
EnglishAbilityNames[AbilityNames.CUTTHROAT_GAIN] = EnglishCardNames[CardNames.CUTTHROAT].singular;
EnglishAbilityNames[AbilityNames.ENLARGE] = EnglishCardNames[CardNames.ENLARGE].singular;
EnglishAbilityNames[AbilityNames.FRIGATE_DURATION] = EnglishCardNames[CardNames.FRIGATE].singular;
EnglishAbilityNames[AbilityNames.FRIGATE_DISCARD] = EnglishCardNames[CardNames.FRIGATE].singular;
EnglishAbilityNames[AbilityNames.LONGSHIP_DURATION] = EnglishCardNames[CardNames.LONGSHIP].singular;
EnglishAbilityNames[AbilityNames.MINING_ROAD] = EnglishCardNames[CardNames.MINING_ROAD].singular;
EnglishAbilityNames[AbilityNames.QUARTERMASTER] = EnglishCardNames[CardNames.QUARTERMASTER].singular;
EnglishAbilityNames[AbilityNames.TRICKSTER_REACTION] = EnglishCardNames[CardNames.TRICKSTER].singular;
EnglishAbilityNames[AbilityNames.TRICKSTER_RETURN_TO_HAND] = EnglishCardNames[CardNames.TRICKSTER].singular;
EnglishAbilityNames[AbilityNames.WEALTHY_VILLAGE] = EnglishCardNames[CardNames.WEALTHY_VILLAGE].singular;
EnglishAbilityNames[AbilityNames.DELIVER_SET_ASIDE] = EnglishCardNames[CardNames.DELIVER].singular;
EnglishAbilityNames[AbilityNames.DELIVER_RETURN] = EnglishCardNames[CardNames.DELIVER].singular;
EnglishAbilityNames[AbilityNames.RUSH] = EnglishCardNames[CardNames.RUSH].singular;
EnglishAbilityNames[AbilityNames.MIRROR] = EnglishCardNames[CardNames.MIRROR].singular;
EnglishAbilityNames[AbilityNames.PREPARE] = EnglishCardNames[CardNames.PREPARE].singular;
EnglishAbilityNames[AbilityNames.AMPHORA] = EnglishCardNames[CardNames.AMPHORA].singular;
EnglishAbilityNames[AbilityNames.DOUBLOONS] = EnglishCardNames[CardNames.DOUBLOONS].singular;
EnglishAbilityNames[AbilityNames.ENDLESS_CHALICE] = EnglishCardNames[CardNames.ENDLESS_CHALICE].singular;
EnglishAbilityNames[AbilityNames.FIGUREHEAD] = EnglishCardNames[CardNames.FIGUREHEAD].singular;
EnglishAbilityNames[AbilityNames.JEWELS] = EnglishCardNames[CardNames.JEWELS].singular;
EnglishAbilityNames[AbilityNames.PUZZLE_BOX_RETURN] = EnglishCardNames[CardNames.PUZZLE_BOX].singular;
EnglishAbilityNames[AbilityNames.SHIELD_REACTION] = EnglishCardNames[CardNames.SHIELD].singular;
EnglishAbilityNames[AbilityNames.SPELL_SCROLL] = EnglishCardNames[CardNames.SPELL_SCROLL].singular;
EnglishAbilityNames[AbilityNames.CURSED_GAIN] = EnglishCardNames[CardNames.CURSED].singular;
EnglishAbilityNames[AbilityNames.FAWNING_GAIN] = EnglishCardNames[CardNames.FAWNING].singular;
EnglishAbilityNames[AbilityNames.SCHEDULER] = EnglishCardNames[CardNames.FRIENDLY].singular;
EnglishAbilityNames[AbilityNames.FRIENDLY_ABILITY] = EnglishCardNames[CardNames.FRIENDLY].singular;
EnglishAbilityNames[AbilityNames.HASTY_SET_ASIDE] = EnglishCardNames[CardNames.HASTY].singular;
EnglishAbilityNames[AbilityNames.HASTY_PLAY] = EnglishCardNames[CardNames.HASTY].singular;
EnglishAbilityNames[AbilityNames.INHERITED] = EnglishCardNames[CardNames.INHERITED].singular;
EnglishAbilityNames[AbilityNames.INSPIRING] = EnglishCardNames[CardNames.INSPIRING].singular;
EnglishAbilityNames[AbilityNames.NEARBY] = EnglishCardNames[CardNames.NEARBY].singular;
EnglishAbilityNames[AbilityNames.PATIENT_SCHEDULER] = EnglishCardNames[CardNames.PATIENT].singular;
EnglishAbilityNames[AbilityNames.PATIENT_SET_ASIDE] = EnglishCardNames[CardNames.PATIENT].singular;
EnglishAbilityNames[AbilityNames.PATIENT_PLAY] = EnglishCardNames[CardNames.PATIENT].singular;
EnglishAbilityNames[AbilityNames.PIOUS] = EnglishCardNames[CardNames.PIOUS].singular;
EnglishAbilityNames[AbilityNames.RECKLESS_REPEAT] = EnglishCardNames[CardNames.RECKLESS].singular;
EnglishAbilityNames[AbilityNames.RECKLESS_RETURN] = EnglishCardNames[CardNames.RECKLESS].singular;
EnglishAbilityNames[AbilityNames.RICH_GAIN] = EnglishCardNames[CardNames.RICH].singular;
EnglishAbilityNames[AbilityNames.SHY] = EnglishCardNames[CardNames.SHY].singular;
EnglishAbilityNames[AbilityNames.TIRELESS_SET_ASIDE] = EnglishCardNames[CardNames.TIRELESS].singular;
EnglishAbilityNames[AbilityNames.TIRELESS_RETURN] = EnglishCardNames[CardNames.TIRELESS].singular;
EnglishAbilityNames[AbilityNames.STAR_CHART] = EnglishCardNames[CardNames.STAR_CHART].singular;
EnglishAbilityNames[AbilityNames.ORDER_OF_ASTROLOGERS] = EnglishCardNames[CardNames.ORDER_OF_ASTROLOGERS].singular;
EnglishAbilityNames[AbilityNames.ORDER_OF_MASONS] = EnglishCardNames[CardNames.ORDER_OF_MASONS].singular;
EnglishAbilityNames[AbilityNames.FATED] = EnglishCardNames[CardNames.FATED].singular;
EnglishAbilityNames[AbilityNames.AVOID] = EnglishCardNames[CardNames.AVOID].singular;
EnglishAbilityNames[AbilityNames.HERBALIST_ASK_FOR_DISCARD] = EnglishCardNames[CardNames.HERBALIST].singular;
EnglishAbilityNames[AbilityNames.SCHEME_ASK_FOR_DISCARD] = EnglishCardNames[CardNames.SCHEME].singular;
EnglishAbilityNames[AbilityNames.TRICKSTER_ASK_FOR_DISCARD] = EnglishCardNames[CardNames.TRICKSTER].singular;
EnglishAbilityNames[AbilityNames.FARMHANDS_SET_ASIDE] = EnglishCardNames[CardNames.FARMHANDS].singular;
EnglishAbilityNames[AbilityNames.FARMHANDS_PLAY] = EnglishCardNames[CardNames.FARMHANDS].singular;
EnglishAbilityNames[AbilityNames.JOUST_DISCARD] = EnglishCardNames[CardNames.JOUST].singular;
EnglishAbilityNames[AbilityNames.INFIRMARY_OVERPAY] = EnglishCardNames[CardNames.INFIRMARY].singular;
EnglishAbilityNames[AbilityNames.RESOLVE_INFIRMARY_OVERPAY] = EnglishCardNames[CardNames.INFIRMARY].singular;
EnglishAbilityNames[AbilityNames.DRAW_FROM_FOOTPAD] = EnglishCardNames[CardNames.FOOTPAD].singular;
EnglishAbilityNames[AbilityNames.FARRIER_OVERPAY] = EnglishCardNames[CardNames.FARRIER].singular;
EnglishAbilityNames[AbilityNames.RESOLVE_FARRIER_OVERPAY] = EnglishCardNames[CardNames.FARRIER].singular;
EnglishAbilityNames[AbilityNames.DRAW_FROM_SQUIRREL] = EnglishCardNames[CardNames.WAY_OF_THE_SQUIRREL].singular;
EnglishAbilityNames[AbilityNames.DRAW_FROM_RIVER] = EnglishCardNames[CardNames.THE_RIVERS_GIFT].singular;
EnglishAbilityNames[AbilityNames.DRAW_FROM_FARRIER] = EnglishCardNames[CardNames.FARRIER].singular;
EnglishAbilityNames[AbilityNames.FERRYMAN] = EnglishCardNames[CardNames.FERRYMAN].singular;
EnglishAbilityNames[AbilityNames.BIDING_TIME_SET_ASIDE_HAND] = EnglishCardNames[CardNames.BIDING_TIME].singular;
EnglishAbilityNames[AbilityNames.BIDING_TIME_RETURN_TO_HAND] = EnglishCardNames[CardNames.BIDING_TIME].singular;
EnglishAbilityNames[AbilityNames.ENLIGHTENMENT_CANTRIP] = EnglishCardNames[CardNames.ENLIGHTENMENT].singular;
EnglishAbilityNames[AbilityNames.ENLIGHTENMENT_REPLACE_ABILITY] = EnglishCardNames[CardNames.ENLIGHTENMENT].singular;
EnglishAbilityNames[AbilityNames.GOOD_HARVEST] = EnglishCardNames[CardNames.GOOD_HARVEST].singular;
EnglishAbilityNames[AbilityNames.HARSH_WINTER] = EnglishCardNames[CardNames.HARSH_WINTER].singular;
EnglishAbilityNames[AbilityNames.KIND_EMPEROR] = EnglishCardNames[CardNames.KIND_EMPEROR].singular;
EnglishAbilityNames[AbilityNames.PANIC_BUYS] = EnglishCardNames[CardNames.PANIC].singular;
EnglishAbilityNames[AbilityNames.PANIC_RETURN] = EnglishCardNames[CardNames.PANIC].singular;
EnglishAbilityNames[AbilityNames.PROGRESS] = EnglishCardNames[CardNames.PROGRESS].singular;
EnglishAbilityNames[AbilityNames.RAPID_EXPANSION_SET_ASIDE] = EnglishCardNames[CardNames.RAPID_EXPANSION].singular;
EnglishAbilityNames[AbilityNames.RAPID_EXPANSION_PLAY_LATER] = EnglishCardNames[CardNames.RAPID_EXPANSION].singular;
EnglishAbilityNames[AbilityNames.SICKNESS] = EnglishCardNames[CardNames.SICKNESS].singular;
EnglishAbilityNames[AbilityNames.RIVERBOAT] = EnglishCardNames[CardNames.RIVERBOAT].singular;
EnglishAbilityNames[AbilityNames.RIVER_SHRINE] = EnglishCardNames[CardNames.RIVER_SHRINE].singular;
EnglishAbilityNames[AbilityNames.SAMURAI] = EnglishCardNames[CardNames.SAMURAI].singular;
EnglishAbilityNames[AbilityNames.DAIMYO_REPLAY] = EnglishCardNames[CardNames.DAIMYO].singular;
EnglishAbilityNames[AbilityNames.FORESIGHT_RETURN_TO_HAND] = EnglishCardNames[CardNames.FORESIGHT].singular;