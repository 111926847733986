"use strict";

var hasCancelledReconnect = false;

webclient.service('reconnect', ['$rootScope', 'serverMessenger', 'connection', function ($rootScope, serverMessenger, connection) {
    var self = this;
    var scope = $rootScope.$new(true);
    var _isReconnecting = false;

    self.isReconnecting = function () {
        return _isReconnecting;
    };
    self.cancel = function () {
        timeLog('Cancelling reconnect...');
        _isReconnecting = false;
        console.log("reconnect cancelled -> _isReconnecting is " + _isReconnecting);
        hasCancelledReconnect = true;
        serverMessenger.terminateGame();
        console.log("returning to lobby because I terminated my game");
        $rootScope.$broadcast(Events.RETURN_TO_LOBBY);
    };
    self.gameLoaded = function () {
        return _isReconnecting = false;
    };

    scope.$on(Events.LOGIN_SUCCESS, function (event, loginSuccess) {
        if (!loginSuccess.isReconnecting) return;
        _isReconnecting = true;
        console.log("LOGIN_SUCCESS -> _isReconnecting is " + _isReconnecting);
    });

    scope.$on(Events.FORCE_RECONNECT, function () {
        _isReconnecting = true;
        console.log("FORCE_RECONNECT -> _isReconnecting is " + _isReconnecting);
    });

    scope.$on(Events.GAME_MODEL_CREATED, function () {
        _isReconnecting = false;
        safeDigest($rootScope);
        console.log("GAME_MODEL_CREATED -> _isReconnecting is " + _isReconnecting);
    });

    scope.$on(Events.GAME_PAGE_LOADED, function () {
        _isReconnecting = false;
        safeDigest($rootScope);
        console.log("GAME_PAGE_LOADED -> _isReconnecting is " + _isReconnecting);
    });
}]);