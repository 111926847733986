"use strict";

var EnglishCardTexts = {};
EnglishCardTexts[CardNames.BACK] = "";
EnglishCardTexts[CardNames.CURSE] = "";
EnglishCardTexts[CardNames.COPPER] = "";
EnglishCardTexts[CardNames.SILVER] = "";
EnglishCardTexts[CardNames.GOLD] = "";
EnglishCardTexts[CardNames.ESTATE] = "";
EnglishCardTexts[CardNames.DUCHY] = "";
EnglishCardTexts[CardNames.PROVINCE] = "";
EnglishCardTexts[CardNames.ARTISAN] = "Gain a card to your hand//costing up to [5].//Put a card from your hand//onto your deck.";
EnglishCardTexts[CardNames.BANDIT] = "Gain a Gold.//Each other player reveals the//top 2 cards of their deck,//trashes a revealed Treasure//other than Copper, and//discards the rest.";
EnglishCardTexts[CardNames.BUREAUCRAT] = "Gain a Silver onto your deck.//Each other player reveals a//Victory card from their hand//and puts it onto their deck (or//reveals a hand with//no Victory cards).";
EnglishCardTexts[CardNames.CELLAR] = "|+1 Action|////Discard any number of cards.//|+1 Card| per card discarded.";
EnglishCardTexts[CardNames.CHAPEL] = "Trash up to 4 cards from//your hand.";
EnglishCardTexts[CardNames.COUNCIL_ROOM] = "|+4 Cards|//|+1 Buy|////Each other player draws a card.";
EnglishCardTexts[CardNames.FESTIVAL] = "|+2 Actions|//|+1 Buy|//|+[2]|";
EnglishCardTexts[CardNames.GARDENS] = "Worth {1} per 10 cards you//have (round down).";
EnglishCardTexts[CardNames.HARBINGER] = "|+1 Card|//|+1 Action|////Look through your discard pile.//You may put a card from it//onto your deck.";
EnglishCardTexts[CardNames.LABORATORY] = "|+2 Cards|//|+1 Action|";
EnglishCardTexts[CardNames.LIBRARY] = "Draw until you have 7 cards in//hand, skipping any Action//cards you choose to; set those//aside, discarding them//afterwards.";
EnglishCardTexts[CardNames.MARKET] = "|+1 Card|//|+1 Action|//|+1 Buy|//|+[1]|";
EnglishCardTexts[CardNames.MERCHANT] = "|+1 Card|//|+1 Action|////The first time you play a Silver//this turn, |+|[1].";
EnglishCardTexts[CardNames.MILITIA] = "|+[2]|////Each other player discards//down to 3 cards in hand.";
EnglishCardTexts[CardNames.MINE] = "You may trash a Treasure from//your hand. Gain a Treasure to//your hand costing up to [3]//more than it.";
EnglishCardTexts[CardNames.MOAT] = "|+2 Cards|//---//When another player plays an//Attack card, you may first//reveal this from your hand, to//be unaffected by it.";
EnglishCardTexts[CardNames.MONEYLENDER] = "You may trash a Copper from//your hand for |+|[3].";
EnglishCardTexts[CardNames.POACHER] = "|+1 Card|//|+1 Action|//|+[1]|////Discard a card per empty//Supply pile.";
EnglishCardTexts[CardNames.REMODEL] = "Trash a card from your hand.//Gain a card costing up to [2]//more than it.";
EnglishCardTexts[CardNames.SENTRY] = "|+1 Card|//|+1 Action|////Look at the top 2 cards of your//deck. Trash and/or discard any//number of them. Put the rest//back on top in any order.";
EnglishCardTexts[CardNames.SMITHY] = "|+3 Cards|";
EnglishCardTexts[CardNames.THRONE_ROOM] = "You may play an Action card//from your hand twice.";
EnglishCardTexts[CardNames.VASSAL] = "|+[2]|////Discard the top card of your//deck. If it's an Action card,//you may play it.";
EnglishCardTexts[CardNames.VILLAGE] = "|+1 Card|//|+2 Actions|";
EnglishCardTexts[CardNames.WITCH] = "|+2 Cards|////Each other player gains a Curse.";
EnglishCardTexts[CardNames.WORKSHOP] = "Gain a card costing up to [4].";

EnglishCardTexts[CardNames.COURTYARD] = "|+3 Cards|////Put a card from your hand//onto your deck.";
EnglishCardTexts[CardNames.CONSPIRATOR] = "|+[2]|////If you've played 3//or more Actions this turn//(counting this), |+1 Card|//and |+1 Action|.";
EnglishCardTexts[CardNames.COURTIER] = "Reveal a card from your hand.//For each type it has (Action,//Attack, etc.) choose one: //|+1 Action|; or |+1 Buy|; or//|+|[3]; or gain a Gold. The//choices must be different.";
EnglishCardTexts[CardNames.BARON] = "|+1 Buy|////You may discard an Estate//for |+|[4]. If you don't,//gain an Estate.";
EnglishCardTexts[CardNames.BRIDGE] = "|+1 Buy|//|+[1]|////This turn, cards (everywhere)//cost [1] less.";
EnglishCardTexts[CardNames.DIPLOMAT] = "|+2 Cards|////If you have 5 or fewer cards in hand//(after drawing), |+2 Actions|.//---//When another player plays an//Attack card, you may first reveal//this from a hand of 5 or more cards,//to draw 2 cards then discard 3.";
EnglishCardTexts[CardNames.DUKE] = "Worth {1} per Duchy//you have.";
EnglishCardTexts[CardNames.FARM] = "[!2]//---//{!2}";
EnglishCardTexts[CardNames.NOBLES] = "Choose one: |+3 Cards|; or//|+2 Actions|.//---//{!2}";
EnglishCardTexts[CardNames.IRONWORKS] = "Gain a card costing up to [4].//If the gained card is an...////Action card, |+1 Action|//Treasure card, |+|[1]//Victory card, |+1 Card|";
EnglishCardTexts[CardNames.LURKER] = "|+1 Action|////Choose one: Trash an Action//card from the Supply; or gain//an Action card from the trash.";
EnglishCardTexts[CardNames.MASQUERADE] = "|+2 Cards|////Each player with any cards//in hand passes one to the//next such player to their left,//at once. Then you may trash//a card from your hand.";
EnglishCardTexts[CardNames.MILL] = "|+1 Card|//|+1 Action|//You may discard 2 cards,//for |+|[2].//---//{!1}";
EnglishCardTexts[CardNames.MINING_VILLAGE] = "|+1 Card|//|+2 Actions|////You may trash this for |+|[2].";
EnglishCardTexts[CardNames.MINION] = "|+1 Action|////Choose one: |+|[2]; or discard//your hand, |+4 Cards|, and//each other player with at least//5 cards in hand discards their//hand and draws 4 cards.";
EnglishCardTexts[CardNames.PATROL] = "|+3 Cards|////Reveal the top 4 cards of your//deck. Put the Victory cards and//Curses into your hand. Put the//rest back in any order.";
EnglishCardTexts[CardNames.PAWN] = "Choose two: |+1 Card|; //|+1 Action|; |+1 Buy|; |+|[1].//The choices must be different.";
EnglishCardTexts[CardNames.REPLACE] = "Trash a card from your hand.//Gain a card costing up to [2]//more than it. If the gained card//is an Action or Treasure,//put it onto your deck; if it's a//Victory card, each other player//gains a Curse.";
EnglishCardTexts[CardNames.SECRET_PASSAGE] = "|+2 Cards|//|+1 Action|////Take a card from your hand //and put it anywhere in your//deck.";
EnglishCardTexts[CardNames.SHANTY_TOWN] = "|+2 Actions|////Reveal your hand.//If you have no Action cards//in hand, |+2 Cards|.";
EnglishCardTexts[CardNames.STEWARD] = "Choose one: |+2 Cards|; or//|+|[2]; or trash 2 cards//from your hand.";
EnglishCardTexts[CardNames.SWINDLER] = "|+[2]|////Each other player trashes the//top card of their deck and//gains a card with the same//cost that you choose.";
EnglishCardTexts[CardNames.TORTURER] = "|+3 Cards|////Each other player either//discards 2 cards or gains a//Curse to their hand, their//choice. (They may pick an//option they can't do.)";
EnglishCardTexts[CardNames.TRADING_POST] = "Trash 2 cards from your//hand. If you did, gain a//Silver to your hand.";
EnglishCardTexts[CardNames.UPGRADE] = "|+1 Card|//|+1 Action|////Trash a card from your hand.//Gain a card costing//exactly [1] more than it.";
EnglishCardTexts[CardNames.WISHING_WELL] = "|+1 Card|//|+1 Action|////Name a card, then reveal the//top card of your deck. If you//named it, put it into your hand.";
EnglishCardTexts[CardNames.AMBASSADOR] = "Reveal a card from your hand.//Return up to 2 copies of it//from your hand to the Supply.//Then each other player gains a//copy of it.";

EnglishCardTexts[CardNames.BAZAAR] = "|+1 Card|//|+2 Actions|//|+[1]|";
EnglishCardTexts[CardNames.CARAVAN] = "|+1 Card|//|+1 Action|////At the start of your next//turn, |+1 Card|.";
EnglishCardTexts[CardNames.CUTPURSE] = "|+[2]|////Each other player discards a//Copper (or reveals a hand//with no Copper).";
EnglishCardTexts[CardNames.EMBARGO] = "|+[2]|////Trash this to add an //Embargo token to a Supply pile.//(For the rest of the game, when//a player buys a card from that//pile, they gain a Curse.)";
EnglishCardTexts[CardNames.EXPLORER] = "You may reveal a Province//from your hand. If you do,//gain a Gold to your hand.//If you don't, gain a Silver//to your hand.";
EnglishCardTexts[CardNames.FISHING_VILLAGE] = "|+2 Actions|//|+[1]|////At the start of your next turn://|+1 Action| and |+|[1].";
EnglishCardTexts[CardNames.GHOST_SHIP] = "|+2 Cards|////Each other player with 4 or//more cards in hand puts cards//from their hand onto their deck//until they have 3 cards in hand.";
EnglishCardTexts[CardNames.HAVEN] = "|+1 Card|//|+1 Action|////Set aside a card from your//hand face down (under this).//At the start of your next turn,//put it into your hand.";
EnglishCardTexts[CardNames.ISLAND] = "Put this and a card from your//hand onto your Island mat.////---//{!2}";
EnglishCardTexts[CardNames.LIGHTHOUSE] = "|+1 Action|////Now and at the start of your//next turn, |+|[1].//---//While you have this in play,//when another player plays an// Attack card, it doesn't affect you.";
EnglishCardTexts[CardNames.LOOKOUT] = "|+1 Action|////Look at the top 3 cards of//your deck. Trash one of them.//Discard one of them.//Put the other one back on//top of your deck.";
EnglishCardTexts[CardNames.MERCHANT_SHIP] = "Now and at the start//of your next turn: |+|[2].";
EnglishCardTexts[CardNames.NATIVE_VILLAGE] = "|+2 Actions|////Choose one: Put the top card of//your deck face down on your//Native Village mat (you may//look at those cards at any time);//or put all the cards from your//mat into your hand.";
EnglishCardTexts[CardNames.NAVIGATOR] = "|+[2]|////Look at the top 5 cards of//your deck. Either discard//them all, or put them back//in any order.";
EnglishCardTexts[CardNames.OUTPOST] = "You only draw 3 cards for //your next hand. Take an//extra turn after this one//(but not a 3rd turn in a row).";
EnglishCardTexts[CardNames.PEARL_DIVER] = "|+1 Card|//|+1 Action|////Look at the bottom card//of your deck. You may//put it on top.";
EnglishCardTexts[CardNames.PIRATE_SHIP] = "Choose one: |+|[1] per Coin token//on your Pirate Ship mat; or each//other player reveals the top 2 cards//of their deck, trashes one of those//Treasures that you choose, and//discards the rest, and then if anyone//trashed a Treasure you add a Coin//token to your Pirate Ship mat.";
EnglishCardTexts[CardNames.SALVAGER] = "|+1 Buy|////Trash a card from your hand.//|+|[1] per [1] it costs.";
EnglishCardTexts[CardNames.SEA_HAG] = "Each other player discards the//top card of their deck, then//gains a Curse onto their deck.";
EnglishCardTexts[CardNames.SMUGGLERS] = "Gain a copy of a card//costing up to [6] that the//player to your right gained//on their last turn.";
EnglishCardTexts[CardNames.TACTICIAN] = "If you have at least one card in//hand: Discard your hand, and at//the start of your next turn,//|+5 Cards|, |+1 Action|,//and |+1 Buy|.";
EnglishCardTexts[CardNames.TREASURE_MAP] = "Trash this and a Treasure Map//from your hand. If you trashed//two Treasure Maps, gain//4 Golds onto your deck.";
EnglishCardTexts[CardNames.WAREHOUSE] = "|+3 Cards|//|+1 Action|////Discard 3 cards.";
EnglishCardTexts[CardNames.WHARF] = "Now and at the start//of your next turn://|+2 Cards |and |+1 Buy|.";

EnglishCardTexts[CardNames.ALCHEMIST] = "|+2 Cards|//|+1 Action|////At the start of Clean-up//this turn, if you have a//Potion in play, you may put//this onto your deck.";
EnglishCardTexts[CardNames.APOTHECARY] = "|+1 Card|//|+1 Action|////Reveal the top 4 cards of your//deck. Put the Coppers and//Potions into your hand. Put//the rest back in any order.";
EnglishCardTexts[CardNames.APPRENTICE] = "|+1 Action|////Trash a card from your hand.//|+1 Card| per [1] it costs.//|+2 Cards| if it has [P] in its cost.";
EnglishCardTexts[CardNames.FAMILIAR] = "|+1 Card|//|+1 Action|////Each other player gains//a Curse.";
EnglishCardTexts[CardNames.GOLEM] = "Reveal cards from your deck//until you reveal 2 Action//cards other than Golems.//Discard the other cards, then//play the Action cards in//either order.";
EnglishCardTexts[CardNames.HERBALIST] = "|+1 Buy|//|+[1]|////Once this turn, when//you discard a Treasure//from play, you may//put it onto your deck.";
EnglishCardTexts[CardNames.PHILOSOPHERS_STONE] = "Count your deck and//discard pile.//|+|[1] per 5 cards total between// them (round down).";
EnglishCardTexts[CardNames.POSSESSION] = "The player to your left takes an//extra turn after this one (but not a//2nd extra turn in a row), in which you//can see all cards they can and make all//decisions for them. Any cards or [D]//they would gain on that turn, you gain//instead; any cards of theirs that are//trashed are set aside and put in their//discard pile at end of turn.";
EnglishCardTexts[CardNames.POTION] = "";
EnglishCardTexts[CardNames.SCRYING_POOL] = "|+1 Action|////Each player (including you) reveals//the top card of their deck and either//discards it or puts it back,//your choice. Then reveal cards from your//deck until revealing one that//isn't an Action. Put all of the//revealed cards into your hand.";
EnglishCardTexts[CardNames.TRANSMUTE] = "Trash a card from your hand.//If it's an...////Action card, gain a Duchy//Treasure card, gain a Transmute//Victory card, gain a Gold";
EnglishCardTexts[CardNames.UNIVERSITY] = "|+2 Actions|////You may gain an Action card//costing up to [5].";
EnglishCardTexts[CardNames.VINEYARD] = "Worth {1} per 3 Action cards//you have (round down).";

EnglishCardTexts[CardNames.BANK] = "|+|[1] per Treasure you have//in play (counting this).";
EnglishCardTexts[CardNames.BISHOP] = "|+[1]|//|+{1}|////Trash a card from your hand.//|+|{1} per [2] it costs (round//down). Each other player may//trash a card from their hand.";
EnglishCardTexts[CardNames.COLONY] = "";
EnglishCardTexts[CardNames.CONTRABAND] = "[!3]//|+1 Buy|////The player to your left//names a card. You can't //buy that card this turn.";
EnglishCardTexts[CardNames.COUNTING_HOUSE] = "Look through your discard//pile, reveal any number of//Coppers from it, and put them//into your hand.";
EnglishCardTexts[CardNames.CITY] = "|+1 Card|//|+2 Actions|////If there are one or more//empty Supply piles, |+1 Card|.//If there are two or more,//|+1 Buy| and |+|[1].";
EnglishCardTexts[CardNames.EXPAND] = "Trash a card from your hand.//Gain a card costing up to [3]//more than it.";
EnglishCardTexts[CardNames.FORGE] = "Trash any number of cards//from your hand. Gain a card//with cost exactly equal to//the total cost in [] of//the trashed cards.";
EnglishCardTexts[CardNames.GRAND_MARKET] = "|+1 Card|//|+1 Action|//|+1 Buy|//|+[2]|//---//You can't buy this if you have//any Coppers in play.";
EnglishCardTexts[CardNames.GOONS] = "|+1 Buy|//|+[2]|//Each other player discards//down to 3 cards in hand.//---//While you have this in play,//when you buy a card, |+|{1}.";
EnglishCardTexts[CardNames.KINGS_COURT] = "You may play an Action card//from your hand three times.";
EnglishCardTexts[CardNames.LOAN] = "[!1]//Reveal cards from your deck//until you reveal a Treasure.//Discard it or trash it.//Discard the other cards.";
EnglishCardTexts[CardNames.MONUMENT] = "|+[2]|////|+{1}|";
EnglishCardTexts[CardNames.MOUNTEBANK] = "|+[2]|////Each other player may discard//a Curse. If they don't, they//gain a Curse and a Copper.";
EnglishCardTexts[CardNames.PEDDLER] = "|+1 Card|//|+1 Action|//|+[1]|//---//During a player's Buy phase,//this costs [2] less per Action//card they have in play.";
EnglishCardTexts[CardNames.PLATINUM] = "";
EnglishCardTexts[CardNames.QUARRY] = "[!1]//---//While this is in play,//Action cards cost [2] less.";
EnglishCardTexts[CardNames.RABBLE] = "|+3 Cards|////Each other player reveals the//top 3 cards of their deck,//discards the Actions and//Treasures, and puts the rest//back in any order they choose.";
EnglishCardTexts[CardNames.ROYAL_SEAL] = "[!2]//---//While you have this in play,//when you gain a card, you may //put that card onto your deck.";
EnglishCardTexts[CardNames.TALISMAN] = "[!1]//---//While you have this in play, when//you buy a non-Victory card costing// [4] or less, gain a copy of it.";
EnglishCardTexts[CardNames.TRADE_ROUTE] = "|+1 Buy|////Trash a card from your hand. |+|[1] per//Coin token on the Trade Route mat.//---//Setup: Add a Coin token to each//Victory Supply pile; move that token//to the Trade Route mat when a card//is gained from the pile.";
EnglishCardTexts[CardNames.VAULT] = "|+2 Cards|////Discard any number of cards//for |+|[1] each.//Each other player may//discard 2 cards, to draw a card.";
EnglishCardTexts[CardNames.VENTURE] = "[!1]//Reveal cards from your deck//until you reveal a Treasure.//Discard the other cards.//Play that Treasure.";
EnglishCardTexts[CardNames.WATCHTOWER] = "Draw until you have 6 cards//in hand.//---//When you gain a card, you//may reveal this from your//hand, to either trash that card//or put it onto your deck.";
EnglishCardTexts[CardNames.WORKERS_VILLAGE] = "|+1 Card|//|+2 Actions|//|+1 Buy|";

EnglishCardTexts[CardNames.PRIZE_PILE] = "";
EnglishCardTexts[CardNames.BAG_OF_GOLD] = "|+1 Action|////Gain a Gold onto your deck.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.DIADEM] = "[!2]//|+|[1] per unused Action you//have (Action, not Action card).////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.FAIRGROUNDS] = "Worth {2} per 5 differently//named cards you have//(round down).";
EnglishCardTexts[CardNames.FARMING_VILLAGE] = "|+2 Actions|////Reveal cards from your deck//until you reveal a Treasure or//Action card. Put that card into//your hand and discard the rest.";
EnglishCardTexts[CardNames.FOLLOWERS] = "|+2 Cards|////Gain an Estate. Each other//player gains a Curse and//discards down to 3 cards//in hand.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.FORTUNE_TELLER] = "|+[2]|////Each other player reveals cards//from the top of their deck until//they reveal a Victory card or//a Curse. They put it on top and//discard the rest.";
EnglishCardTexts[CardNames.HAMLET] = "|+1 Card|//|+1 Action|////You may discard a card for//|+1 Action|.//You may discard a card for //|+1 Buy|.";
EnglishCardTexts[CardNames.HARVEST] = "Reveal the top 4 cards of your//deck, then discard them.//|+|[1] per differently named//card revealed.";
EnglishCardTexts[CardNames.HORSE_TRADERS] = "|+1 Buy|//|+[3]|//Discard 2 cards.//---//When another player plays an Attack//card, you may first set this aside from//your hand. If you do, then at the start of//your next turn, |+1 Card| and return//this to your hand.";
EnglishCardTexts[CardNames.HORN_OF_PLENTY] = "Gain a card costing up//to [1] per differently named//card you have in play//(counting this). If it's a//Victory card, trash this.";
EnglishCardTexts[CardNames.HUNTING_PARTY] = "|+1 Card|//|+1 Action|////Reveal your hand. Reveal cards//from your deck until you reveal//one that isn't a copy of one in//your hand. Put it into your hand//and discard the rest.";
EnglishCardTexts[CardNames.JESTER] = "|+[2]|////Each other player discards the top//card of their deck. If it's a Victory//card they gain a Curse; otherwise//they gain a copy of the discarded//card or you do, your choice.";
EnglishCardTexts[CardNames.MENAGERIE] = "|+1 Action|////Reveal your hand. If the//revealed cards all have//different names, |+3 Cards|.//Otherwise, |+1 Card.|";
EnglishCardTexts[CardNames.PRINCESS] = "|+1 Buy|////This turn, cards cost [2] less.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.REMAKE] = "Do this twice: Trash a card//from your hand, then gain a//card costing exactly [1] more//than it.";
EnglishCardTexts[CardNames.TOURNAMENT] = "|+1 Action|////Each player may reveal a Province//from their hand. If you do, discard//it and gain any Prize (from the//Prize pile) or a Duchy, onto your//deck. If no-one else does,//|+1 Card| and |+|[1].";
EnglishCardTexts[CardNames.TRUSTY_STEED] = "Choose two: |+2 Cards|; //|+2 Actions|; |+|[2]; gain//4 Silvers and put your deck//into your discard pile. The//choices must be different.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.YOUNG_WITCH] = "|+2 Cards|////Discard 2 cards. Each other player//gains a Curse unless they//reveal a Bane from their hand.//---//Setup: Add an extra Kingdom card// pile costing [2] or [3] to the Supply.//Its cards are Banes.";

EnglishCardTexts[CardNames.BORDER_VILLAGE] = "|+1 Card|//|+2 Actions|//---//When you gain this, gain a//cheaper card.";
EnglishCardTexts[CardNames.CACHE] = "[!3]//---//When you gain this, gain//2 Coppers.";
EnglishCardTexts[CardNames.CARTOGRAPHER] = "|+1 Card|//|+1 Action|////Look at the top 4 cards of your//deck. Discard any number of//them, then put the rest back//in any order.";
EnglishCardTexts[CardNames.CROSSROADS] = "Reveal your hand. |+1 Card|//per Victory card revealed.//If this is the first time you//played a Crossroads//this turn, |+3 Actions|.";
EnglishCardTexts[CardNames.DEVELOP] = "Trash a card from your hand.//Gain two cards onto your deck,//with one costing exactly [1] more//than it, and one costing//exactly [1] less than//it, in either order.";
EnglishCardTexts[CardNames.DUCHESS] = "|+[2]|//Each player (including you) looks//at the top card of their deck and//may discard it.//---//In games using this, when you gain//a Duchy, you may gain a Duchess.";
EnglishCardTexts[CardNames.EMBASSY] = "|+5 Cards|//Discard 3 cards.//---//When you gain this, each other//player gains a Silver.";
EnglishCardTexts[CardNames.FOOLS_GOLD] = "If this is the first time//you played a Fool's Gold this//turn, |+|[1] otherwise |+|[4].//---//When another player gains a//Province, you may trash this//from your hand, to gain a Gold//onto your deck.";
EnglishCardTexts[CardNames.ILL_GOTTEN_GAINS] = "[!1]//You may gain a Copper//to your hand.//---//When you gain this, each//other player gains a Curse.";
EnglishCardTexts[CardNames.INN] = "|+2 Cards|//|+2 Actions|////Discard 2 cards.//---//When you gain this, reveal//any number of Action cards//from your discard pile and//shuffle them into your deck.";
EnglishCardTexts[CardNames.JACK_OF_ALL_TRADES] = "Gain a Silver.//Look at the top card of your//deck; you may discard it.//Draw until you have 5 cards in//your hand.//You may trash a non-Treasure//card from your hand.";
EnglishCardTexts[CardNames.MANDARIN] = "|+[3]|//Put a card from your hand//onto your deck.//---//When you gain this, put all//Treasures you have in play//onto your deck in any order.";
EnglishCardTexts[CardNames.NOBLE_BRIGAND] = "|+[1]|//Each other player reveals the top 2//cards of their deck, trashes a revealed//Silver or Gold you choose, discards//the rest, and gains a Copper if they//didn't reveal a Treasure.//You gain the trashed cards.//---//When you buy this, do its attack.";
EnglishCardTexts[CardNames.NOMAD_CAMP] = "|+1 Buy|//|+[2]|//---//This is gained onto your deck//(instead of to your discard pile).";
EnglishCardTexts[CardNames.OASIS] = "|+1 Card|//|+1 Action|//|+[1]|////Discard a card.";
EnglishCardTexts[CardNames.ORACLE] = "Each player (including you)//reveals the top 2 cards of//their deck, and discards them//or puts them back, your choice//(they choose the order).//Then, |+2 Cards.|";
EnglishCardTexts[CardNames.MARGRAVE] = "|+3 Cards|//|+1 Buy|////Each other player draws a//card, then discards down to//3 cards in hand.";
EnglishCardTexts[CardNames.SCHEME] = "|+1 Card|//|+1 Action|////This turn, you may put one//of your Action cards onto//your deck when you//discard it from play.";
EnglishCardTexts[CardNames.SILK_ROAD] = "Worth {1} per 4 Victory cards//you have (round down).";
EnglishCardTexts[CardNames.SPICE_MERCHANT] = "You may trash a Treasure//from your hand to choose one://|+2 Cards| and| +1 Action|;//or| +1 Buy |and| +|[2].";
EnglishCardTexts[CardNames.STABLES] = "You may discard a Treasure,//for |+3 Cards| and| +1 Action|.";
EnglishCardTexts[CardNames.TRADER] = "Trash a card from your hand.//Gain a Silver per [1] it costs.//---//When you gain a card,//you may reveal this from//your hand, to exchange//the card for a Silver.";
EnglishCardTexts[CardNames.TUNNEL] = "{!2}//---//When you discard this other//than during Clean-up, you//may reveal it to gain a Gold.";

EnglishCardTexts[CardNames.RUIN_PILE] = "";
EnglishCardTexts[CardNames.KNIGHTS] = "Shuffle the Knight pile before//each game with it. Keep it face//down except for the top card,//which is the only one that can//be bought or gained.";
EnglishCardTexts[CardNames.ABANDONED_MINE] = "|+[1]|";
EnglishCardTexts[CardNames.ALTAR] = "Trash a card from your hand.//Gain a card costing up to [5].";
EnglishCardTexts[CardNames.ARMORY] = "Gain a card onto your deck//costing up to [4].";
EnglishCardTexts[CardNames.BAND_OF_MISFITS] = "Play a non-Command//Action card from the Supply//that costs less than this,//leaving it there.";
EnglishCardTexts[CardNames.BANDIT_CAMP] = "|+1 Card|//|+2 Actions|////Gain a Spoils.";
EnglishCardTexts[CardNames.BEGGAR] = "Gain 3 Coppers to your hand.//---//When another player plays an//Attack card, you may first//discard this to gain 2 Silvers,//putting one onto your deck.";
EnglishCardTexts[CardNames.CATACOMBS] = "Look at the top 3 cards of//your deck. Choose one: Put//them into your hand; or//discard them and |+3 Cards|.//---//When you trash this, gain a//cheaper card.";
EnglishCardTexts[CardNames.COUNT] = "Choose one: Discard 2 cards; or//put a card from your hand onto//your deck; or gain a Copper.//Choose one: |+|[3]; or trash your//hand; or gain a Duchy.";
EnglishCardTexts[CardNames.COUNTERFEIT] = "[!1]//|+1 Buy|////You may play a non-Duration//Treasure from your hand twice.//Trash it.";
EnglishCardTexts[CardNames.CULTIST] = "|+2 Cards|////Each other player gains a Ruins.//You may play a Cultist from//your hand.//---//When you trash this, |+3 Cards|.";
EnglishCardTexts[CardNames.DAME_ANNA] = "You may trash up to 2 cards//from your hand. Each other player//reveals the top 2 cards of their//deck, trashes one of them costing//from [3] to [6], and discards//the rest. If a Knight is trashed//by this, trash this.";
EnglishCardTexts[CardNames.DAME_JOSEPHINE] = "Each other player reveals the top//two cards of their deck, trashes one//of them costing from [3] to [6],//and discards the rest. If a Knight//is trashed by this, trash this.//---//{!2}";
EnglishCardTexts[CardNames.DAME_MOLLY] = "|+2 Actions|////Each other player reveals the top//two cards of their deck, trashes one//of them costing from [3] to [6],//and discards the rest. If a Knight//is trashed by this, trash this.";
EnglishCardTexts[CardNames.DAME_NATALIE] = "You may gain a card costing//up to [3]. Each other//player reveals the top 2 cards of//their deck, trashes one of them//costing from [3] to [6], and//discards the rest. If a Knight//is trashed by this, trash this.";
EnglishCardTexts[CardNames.DAME_SYLVIA] = "|+[2]|////Each other player reveals the top//two cards of their deck, trashes one//of them costing from [3] to [6],//and discards the rest. If a Knight//is trashed by this, trash this.";
EnglishCardTexts[CardNames.DEATH_CART] = "You may trash this or//an Action card from//your hand, for |+|[5].//---//When you gain this,//gain 2 Ruins.";
EnglishCardTexts[CardNames.FEODUM] = "Worth {1} per three Silvers//you have (round down).//---//When you trash this, gain//3 Silvers.";
EnglishCardTexts[CardNames.FORAGER] = "|+1 Action|//|+1 Buy|////Trash a card from your hand,//then |+|[1] per differently//named Treasure in the trash.";
EnglishCardTexts[CardNames.FORTRESS] = "|+1 Card|//|+2 Actions|//---//When you trash this,//put it into your hand.";
EnglishCardTexts[CardNames.GRAVEROBBER] = "Choose one: Gain a card from the//trash costing from [3] to [6], onto//your deck; or trash an Action card//from your hand and gain a card//costing up to [3] more than it.";
EnglishCardTexts[CardNames.HERMIT] = "Look through your discard pile.//You may trash a non-Treasure//from it or from your hand.//Gain a card costing up to [3].//At the end of your Buy phase//this turn, if you didn't gain//any cards in it, exchange//this for a Madman.";
EnglishCardTexts[CardNames.HOVEL] = "When you gain a Victory card,//you may trash this from your//hand.";
EnglishCardTexts[CardNames.HUNTING_GROUNDS] = "|+4 Cards|//---//When you trash this, gain a//Duchy or 3 Estates.";
EnglishCardTexts[CardNames.IRONMONGER] = "|+1 Card|//|+1 Action|////Reveal the top card of your deck; you//may discard it. Either way, if it's an...//Action card, |+1 Action|//Treasure card, |+|[1]//Victory card,|+1 Card|";
EnglishCardTexts[CardNames.JUNK_DEALER] = "|+1 Card|//|+1 Action|//|+[1]|////Trash a card from your hand.";
EnglishCardTexts[CardNames.MADMAN] = "|+2 Actions|////Return this to the Madman//pile. If you do, |+1 Card|//per card in your hand.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.MARKET_SQUARE] = "|+1 Card|//|+1 Action|//|+1 Buy|//---//When one of your cards is trashed,//you may discard this from your//hand to gain a Gold.";
EnglishCardTexts[CardNames.MARAUDER] = "Gain a Spoils. Each other//player gains a Ruins.";
EnglishCardTexts[CardNames.MERCENARY] = "You may trash 2 cards from your//hand. If you did, |+2 Cards|, |+|[2],//and each other player discards//down to 3 cards in hand.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.MYSTIC] = "|+1 Action|//|+[2]|////Name a card, then reveal the//top card of your deck. If you//named it, put it into your hand.";
EnglishCardTexts[CardNames.NECROPOLIS] = "|+2 Actions|";
EnglishCardTexts[CardNames.OVERGROWN_ESTATE] = "{!0}//---//When you trash this, |+1 Card|.";
EnglishCardTexts[CardNames.PILLAGE] = "Trash this. If you did, gain//2 Spoils, and each other player//with 5 or more cards in hand//reveals their hand and discards//a card that you choose.";
EnglishCardTexts[CardNames.POOR_HOUSE] = "|+[4]|////Reveal your hand. -[1] per//Treasure card in your hand.//(You can't go below [0].)";
EnglishCardTexts[CardNames.PROCESSION] = "You may play a non-Duration//Action card from your//hand twice. Trash it.//Gain an Action card costing//exactly [1] more than it.";
EnglishCardTexts[CardNames.RATS] = "|+1 Card|//|+1 Action|//Gain a Rats. Trash a card from//your hand other than a Rats//(or reveal a hand of all Rats).//---//When you trash this, |+1 Card|.";
EnglishCardTexts[CardNames.REBUILD] = "|+1 Action|////Name a card. Reveal cards from your//deck until you reveal a//Victory card you did not name.//Discard the rest, trash the Victory//card, and gain a Victory card//costing up to [3] more than it.";
EnglishCardTexts[CardNames.ROGUE] = "|+[2]|////If there are any cards in the trash//costing from [3] to [6], gain one of//them. Otherwise, each other player//reveals the top 2 cards of their//deck, trashes one of them costing//from [3] to [6], and discards the rest.";
EnglishCardTexts[CardNames.RUINED_LIBRARY] = "|+1 Card|";
EnglishCardTexts[CardNames.RUINED_MARKET] = "|+1 Buy|";
EnglishCardTexts[CardNames.RUINED_VILLAGE] = "|+1 Action|";
EnglishCardTexts[CardNames.SAGE] = "|+1 Action|////Reveal cards from your deck//until you reveal one costing [3]//or more. Put that card into your//hand and discard the rest.";
EnglishCardTexts[CardNames.SCAVENGER] = "|+[2]|////You may put your deck into//your discard pile. Put a card//from your discard pile//onto your deck.";
EnglishCardTexts[CardNames.SIR_BAILEY] = "|+1 Card|//|+1 Action|////Each other player reveals the top//two cards of their deck, trashes one//of them costing from [3] to [6],//and discards the rest. If a Knight//is trashed by this, trash this.";
EnglishCardTexts[CardNames.SIR_DESTRY] = "|+2 Cards|////Each other player reveals the top//two cards of their deck, trashes one//of them costing from [3] to [6],//and discards the rest. If a Knight//is trashed by this, trash this.";
EnglishCardTexts[CardNames.SIR_MARTIN] = "|+2 Buys|////Each other player reveals the top//two cards of their deck, trashes one//of them costing from [3] to [6],//and discards the rest. If a Knight//is trashed by this, trash this.";
EnglishCardTexts[CardNames.SIR_MICHAEL] = "Each other player discards//down to 3 cards in hand. Each//other player reveals the top 2 cards//of their deck, trashes one of them//costing from [3] to [6], and//discards the rest. If a Knight//is trashed by this, trash this.";
EnglishCardTexts[CardNames.SIR_VANDER] = "Each other player reveals the top//two cards of their deck, trashes one//of them costing from [3] to [6],//and discards the rest. If a Knight//is trashed by this, trash this.//---//When you trash this, gain a Gold.";
EnglishCardTexts[CardNames.SPOILS] = "[!3]//When you play this, return it//to the Spoils pile.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.STOREROOM] = "|+1 Buy|////Discard any number of cards,//then draw that many. Then//discard any number of cards//for +[1] each.";
EnglishCardTexts[CardNames.SQUIRE] = "|+[1]|////Choose one: |+2 Actions|; //|+2 Buys|; or gain a Silver.//---//When you trash this,//gain an Attack card.";
EnglishCardTexts[CardNames.SURVIVORS] = "Look at the top two cards of//your deck. Discard them or put//them back in any order.";
EnglishCardTexts[CardNames.URCHIN] = "|+1 Card|//|+1 Action|////Each other player discards//down to 4 cards in hand.//---//When you play another Attack card//with this in play, you may first//trash this, to gain a Mercenary.";
EnglishCardTexts[CardNames.VAGRANT] = "|+1 Card|//|+1 Action|////Reveal the top card of your//deck. If it's a Curse, Ruins,//Shelter, or Victory card, put it//into your hand.";
EnglishCardTexts[CardNames.WANDERING_MINSTREL] = "|+1 Card|//|+2 Actions|////Reveal the top 3 cards of your//deck. Put the Action cards//back in any order and discard//the rest.";

EnglishCardTexts[CardNames.ADVISOR] = "|+1 Action|////Reveal the top 3 cards of your//deck. The player to your left//chooses one of them. Discard//that card and put the rest into//your hand.";
EnglishCardTexts[CardNames.BAKER] = "|+1 Card|//|+1 Action|//|+1 Coffers|//---//Setup: Each player gets //|+1 Coffers|.";
EnglishCardTexts[CardNames.BUTCHER] = "|+2 Coffers|////You may trash a card from your//hand, to gain a Card, costing//up to [1] more than it//per Coffers you spend.";
EnglishCardTexts[CardNames.CANDLESTICK_MAKER] = "|+1 Action|//|+1 Buy|//|+1 Coffers|";
EnglishCardTexts[CardNames.DOCTOR] = "Name a card.//Reveal the top 3 cards of//your deck. Trash the matches.//Put the rest back in any order.//---//Overpay: Per [1] overpaid,//look at the top card of your deck;//trash it, discard it, or put it back.";
EnglishCardTexts[CardNames.HERALD] = "|+1 Card|//|+1 Action|////Reveal the top card of your deck.//If it's an Action, play it.//---//Overpay: Per [1] overpaid,//put any card from your//discard pile onto your deck.";
EnglishCardTexts[CardNames.JOURNEYMAN] = "Name a card. Reveal cards//from your deck until you reveal//3 cards without that name. Put//those cards into your hand and//discard the rest.";
EnglishCardTexts[CardNames.MASTERPIECE] = "[!1]//---//Overpay: Gain a Silver//per [1] overpaid.";
EnglishCardTexts[CardNames.MERCHANT_GUILD] = "|+1 Buy|//|+[1]|////At the end of your Buy//phase this turn, |+1 Coffers|//per card you gained in it.";
EnglishCardTexts[CardNames.PLAZA] = "|+1 Card|//|+2 Actions|////You may discard a Treasure//for |+1 Coffers|.";
EnglishCardTexts[CardNames.TAXMAN] = "You may trash a Treasure from//your hand. Each other player with//5 or more cards in hand discards a//copy of it (or reveals a hand//without it). Gain a Treasure onto//your deck costing up to//[3] more than the trashed card.";
EnglishCardTexts[CardNames.SOOTHSAYER] = "Gain a Gold.//Each other player gains//a Curse, and if they did,//draws a card.";
EnglishCardTexts[CardNames.STONEMASON] = "Trash a card from your hand.//Gain 2 cards each costing less//than it.//---//Overpay: Gain 2//Action cards each costing//the amount overpaid.";

EnglishCardTexts[CardNames.ALMS] = "Once per turn: If you have no Treasures in play,//gain a card costing up to [4].";
EnglishCardTexts[CardNames.AMULET] = "Now and at the start of your//next turn, choose one: |+|[1];//or trash a card from your hand;//or gain a Silver.";
EnglishCardTexts[CardNames.ARTIFICER] = "|+1 Card|//|+1 Action|//|+[1]|////Discard any number of cards.//You may gain a card costing//exactly [1] per card discarded,//onto your deck.";
EnglishCardTexts[CardNames.BALL] = "Take your -[1] token. Gain 2 cards each costing up to [4].";
EnglishCardTexts[CardNames.BONFIRE] = "Trash up to 2 Coppers you have in play.";
EnglishCardTexts[CardNames.BORROW] = "Once per turn: |+1 Buy|. If your -1 Card token isn't on your//deck, put it there and |+|[1].";
EnglishCardTexts[CardNames.BRIDGE_TROLL] = "Each other player takes their//-[1] token. On this turn and//your next turn, cards cost//[1] less. Now and at the start//of your next turn: |+1 Buy|.";
EnglishCardTexts[CardNames.CARAVAN_GUARD] = "|+1 Card|//|+1 Action|//At the start of your next turn, |+|[1].//---//When another player plays an Attack//card, you may first play this from//your hand.";
EnglishCardTexts[CardNames.CHAMPION] = "|+1 Action|////For the rest of the game, when//another player plays an Attack,//it doesn't affect you, and when//you play an Action card,//you first get |+1 Action|.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.COIN_OF_THE_REALM] = "[!1]//Put this on your Tavern mat.//---//After you play an Action card,//you may call this,//for |+2 Actions|.";
EnglishCardTexts[CardNames.DISCIPLE] = "You may play an Action card from//your hand twice. Gain a copy of it.//---//When you discard this from play,//you may exchange it for a Teacher.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.DISTANT_LANDS] = "Put this on your Tavern mat.//---//Worth {4} if on your Tavern//mat at the end of the game//(otherwise worth {0}).";
EnglishCardTexts[CardNames.DUNGEON] = "|+1 Action|////Now and at the start of your//next turn: |+2 Cards|, then//discard 2 cards.";
EnglishCardTexts[CardNames.DUPLICATE] = "Put this on your Tavern mat.//---//When you gain a card costing//up to [6], you may call this,//to gain a copy of that card.";
EnglishCardTexts[CardNames.EXPEDITION] = "Draw 2 extra cards for your next hand.";
EnglishCardTexts[CardNames.FERRY] = "Move your -[2] token to an Action Supply pile.//(Cards from that pile cost [2] less on your turns.)";
EnglishCardTexts[CardNames.FUGITIVE] = "|+2 Cards|//|+1 Action|//Discard a card.//---//When you discard this from play,//you may exchange it for a Disciple.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.GEAR] = "|+2 Cards|////Set aside up to 2 cards from your//hand face down (under this).//At the start of your next turn,//put them into your hand.";
EnglishCardTexts[CardNames.GIANT] = "Turn your Journey token over (it//starts face up). Then if it's face down,//|+|[1]. If it's face up, |+|[5], and//each other player reveals the top//card of their deck, trashes it if it costs//from [3] to [6], and otherwise//discards it and gains a Curse.";
EnglishCardTexts[CardNames.GUIDE] = "|+1 Card|//|+1 Action|////Put this on your Tavern mat.//---//At the start of your turn, you//may call this, to discard your//hand and draw 5 cards.";
EnglishCardTexts[CardNames.HAUNTED_WOODS] = "At the start of your next//turn, |+3 Cards|.//Until then, when any other//player gains a card they//bought, they put their//hand onto their deck//in any order. ";
EnglishCardTexts[CardNames.HERO] = "|+[2]|//Gain a Treasure.//---//When you discard this from play,//you may exchange it//for a Champion.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.HIRELING] = "At the start of//each of your turns for//the rest of the game:////|+1 Card|";
EnglishCardTexts[CardNames.INHERITANCE] = "Once per game: Set aside a non-Command Action card from//the Supply costing up to [4]. Move your Estate token//to it. (During your turns, Estates are also Command Actions//with \"Play the card with your Estate token, leaving it there.\")";
EnglishCardTexts[CardNames.LOST_ARTS] = "Move your +1 Action token to an Action Supply pile.//(When you play a card from that pile, you first get |+1 Action|.)";
EnglishCardTexts[CardNames.LOST_CITY] = "|+2 Cards|//|+2 Actions|//---//When you gain this, each//other player draws a card.";
EnglishCardTexts[CardNames.MAGPIE] = "|+1 Card|//|+1 Action|////Reveal the top card of your//deck. If it's a Treasure, put it//into your hand. If it's an Action//or Victory card, gain a Magpie.";
EnglishCardTexts[CardNames.MESSENGER] = "|+1 Buy|//|+[2]|//You may put your deck into//your discard pile.//---//When this is the first card//you gain in your Buy phase,//gain a card costing up to [4], and//each other player gains a copy of it.";
EnglishCardTexts[CardNames.MISER] = "Choose one: Put a Copper//from your hand onto your//Tavern mat; or |+|[1] per//Copper on your Tavern mat.";
EnglishCardTexts[CardNames.MISSION] = "Take an extra turn after this one (but not//a 3rd turn in a row), during which you//can't buy cards. (You can still buy Events.)";
EnglishCardTexts[CardNames.PATHFINDING] = "Move your +1 Card token to an Action Supply pile.//(When you play a card from that pile, you first get |+1 Card|.)";
EnglishCardTexts[CardNames.PAGE] = "|+1 Card|//|+1 Action|//---//When you discard this from play,//you may exchange it for a//Treasure Hunter.";
EnglishCardTexts[CardNames.PEASANT] = "|+1 Buy|//|+[1]|//---//When you discard this from play,//you may exchange it for a//Soldier.";
EnglishCardTexts[CardNames.PILGRIMAGE] = "Once per turn: Turn your Journey token over (it starts face//up); then if it's face up, choose up to 3 differently named//cards you have in play and gain a copy of each.";
EnglishCardTexts[CardNames.PLAN] = "Move your Trashing token to an Action Supply pile.//(When you gain a card from that pile,//you may trash a card from your hand.)";
EnglishCardTexts[CardNames.PORT] = "|+1 Card|//|+2 Actions|//---//When you gain this,//gain another Port (that//doesn't come with another).";
EnglishCardTexts[CardNames.QUEST] = "You may discard an Attack, two Curses, or six cards.//If you do, gain a Gold.";
EnglishCardTexts[CardNames.RANGER] = "|+1 Buy|////Turn your Journey token over//(it starts face up). Then if it's//face up, |+5 Cards|.";
EnglishCardTexts[CardNames.RAID] = "Gain a Silver per Silver you have in play.//Each other player puts their -1 Card token on their deck.";
EnglishCardTexts[CardNames.RATCATCHER] = "|+1 Card|//|+1 Action|////Put this on your Tavern mat.//---//At the start of your turn, you//may call this, to trash a card//from your hand.";
EnglishCardTexts[CardNames.RAZE] = "|+1 Action|////Trash this or a card from your hand.//Look at one card from the top of// your deck per [1] the trashed card//costs. Put one of them into your//hand and discard the rest.";
EnglishCardTexts[CardNames.RELIC] = "[!2]////Each other player puts//their -1 Card token//on their deck.";
EnglishCardTexts[CardNames.ROYAL_CARRIAGE] = "|+1 Action|////Put this on your Tavern mat.//---//Directly after you finish//playing an Action card, if it's//still in play, you may call this,//to replay that Action.";
EnglishCardTexts[CardNames.SAVE] = "Once per turn: |+1 Buy|. Set aside a card from your hand,//and put it into your hand at end of turn (after drawing).";
EnglishCardTexts[CardNames.SCOUTING_PARTY] = "|+1 Buy|////Look at the top 5 cards of your deck.//Discard 3 and put the rest back in any order.";
EnglishCardTexts[CardNames.SEAWAY] = "Gain an Action card costing up to [4].//Move your +1 Buy token to its pile. (When you play a card//from that pile, you first get |+1 Buy|.)";
EnglishCardTexts[CardNames.SOLDIER] = "|+[2]|//|+|[1] per other Attack you have in//play. Each other player with 4 or//more cards in hand discards a card.//---//When you discard this from play,//you may exchange it for a Fugitive.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.STORYTELLER] = "|+1 Action|////Play up to 3 Treasures from//your hand. Then |+1 Card|,//and pay all of your [] and //|+1 Card| per [1] you paid.";
EnglishCardTexts[CardNames.SWAMP_HAG] = "At the start of your next//turn, +[3].//Until then, when any//other player gains a card//they bought, they gain//a Curse.";
EnglishCardTexts[CardNames.TEACHER] = "Put this on your Tavern mat.//---//At the start of your turn, you may call//this, to move your |+|1 Card, |+|1 Action,//|+|1 Buy, or |+|[1] token to an Action//Supply pile you have no tokens on.//(When you play a card from that pile,//you first get that bonus.)////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.TRAVELLING_FAIR] = "|+2 Buys|////When you gain a card this turn,//you may put it onto your deck.";
EnglishCardTexts[CardNames.TRADE] = "Trash up to 2 cards from your hand.//Gain a Silver per card you trashed.";
EnglishCardTexts[CardNames.TRAINING] = "Move your +[1] token to an Action Supply pile.//(When you play a card from that pile, you first get +[1].)";
EnglishCardTexts[CardNames.TRANSMOGRIFY] = "|+1 Action|////Put this on your Tavern mat.//---//At the start of your turn, you may//call this, to trash a card from your//hand, and gain a card costing up to// [1] more than it into your hand.";
EnglishCardTexts[CardNames.TREASURE_TROVE] = "[!2]////Gain a Gold and a Copper.";
EnglishCardTexts[CardNames.TREASURE_HUNTER] = "|+1 Action|//|+[1]|//Gain a Silver per card the player to//your right gained on their last turn.//---//When you discard this from play,//you may exchange it for a Warrior.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.WARRIOR] = "|+2 Cards|////Once per Traveller you have in play//(including this), each other player//discards the top card of their deck//and trashes it if it costs [3] or [4].//---//When you discard this from play,//you may exchange it for a Hero.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.WINE_MERCHANT] = "|+1 Buy|//|+[4]|////Put this on your Tavern mat.//---//At the end of your Buy phase, if you//have at least [2] unspent, you may//discard this from your Tavern mat.";
EnglishCardTexts[CardNames.ENCAMPMENT] = "|+2 Cards|//|+2 Actions|////You may reveal a Gold or Plunder//from your hand. If you do not,//set this aside, and return it to//its pile at the start of Clean-up.";
EnglishCardTexts[CardNames.PLUNDER] = "[!2]////+{1}";
EnglishCardTexts[CardNames.PATRICIAN] = "|+1 Card|//|+1 Action|////Reveal the top card of your//deck. If it costs [5] or more,//put it into your hand.";
EnglishCardTexts[CardNames.EMPORIUM] = "|+1 Card|//|+1 Action|//|+[1]|//---//When you gain this, if you have//at least 5 Action cards in play,//|+|{2}.";
EnglishCardTexts[CardNames.SETTLERS] = "|+1 Card|//|+1 Action|////You may reveal a Copper//from your discard pile//and put it into your hand.";
EnglishCardTexts[CardNames.BUSTLING_VILLAGE] = "|+1 Card|//|+3 Actions|////You may reveal a Settlers//from your discard pile//and put it into your hand.";
EnglishCardTexts[CardNames.CATAPULT] = "|+[1]|//// Trash a card from your hand. If//it costs [3] or more, each other//player gains a Curse. If it's a//Treasure, each other player//discards down to 3 cards in hand.";
EnglishCardTexts[CardNames.ROCKS] = "[!1]//---//When you gain or trash this, gain a//Silver; if it is your Buy phase, put//the Silver on your deck, otherwise//put it into your hand.";
EnglishCardTexts[CardNames.GLADIATOR] = "|+|[2]////Reveal a card from your hand.//The player to your left may//reveal a copy from their hand.//If they do not, |+|[1] and trash a//Gladiator from the Supply.";
EnglishCardTexts[CardNames.FORTUNE] = "|+1 Buy|//Double your [] if you//haven't yet this turn.//---//When you gain this,//gain a Gold per Gladiator//you have in play.";

EnglishCardTexts[CardNames.CASTLES] = "Sort the Castle pile by cost,//putting the more expensive//Castles on the bottom. For a//2-player game, use only one//of each Castle. Only the top//card of the pile can be//gained or bought.";
EnglishCardTexts[CardNames.HUMBLE_CASTLE] = "[!1]//---//Worth {1} per Castle you//have.";
EnglishCardTexts[CardNames.CRUMBLING_CASTLE] = "{!1}//---//When you gain this or trash//this, +{1} and gain a Silver.";
EnglishCardTexts[CardNames.SMALL_CASTLE] = "Trash this or a Castle from//your hand.//If you do, gain a Castle.//---//{!2}";
EnglishCardTexts[CardNames.HAUNTED_CASTLE] = "{!2}//---//When you gain this during//your turn, gain a Gold, and//each other player with 5 or//more cards in hand puts//2 of them onto their deck.";
EnglishCardTexts[CardNames.OPULENT_CASTLE] = "Discard any number of//Victory cards, revealed. //|+|[2] per card discarded.//---//{!3}";
EnglishCardTexts[CardNames.SPRAWLING_CASTLE] = "{!4}//---//When you gain this, gain a//Duchy or 3 Estates.";
EnglishCardTexts[CardNames.GRAND_CASTLE] = "{!5}//---//When you gain this, reveal your//hand. +{1} per Victory card in//your hand and/or in play.";
EnglishCardTexts[CardNames.KINGS_CASTLE] = "Worth {2} per Castle you//have.";
EnglishCardTexts[CardNames.ADVANCE] = "You may trash an Action card from your hand. If//you do, gain an Action card costing up to [6].";
EnglishCardTexts[CardNames.ANNEX] = "Shuffle all but up to 5 cards from your//discard pile into your deck. Gain a Duchy.";
EnglishCardTexts[CardNames.ARCHIVE] = "|+1 Action|////Set aside the top 3 cards of your//deck face down (you may look//at them). Now and at the start//of your next two turns, put one//into your hand.";
EnglishCardTexts[CardNames.AQUEDUCT] = "When you gain a Treasure, move {1} from its pile to this.//When you gain a Victory card, take the {} from this.//---//Setup: Put {8} on the Silver and Gold piles.";
EnglishCardTexts[CardNames.ARENA] = "At the start of your Buy phase, you may discard an Action//card. If you do, take {2} from here.//---//Setup: Put {6} here per player.";
EnglishCardTexts[CardNames.BANDIT_FORT] = "When scoring, {-2} for each Silver and each//Gold you have.";
EnglishCardTexts[CardNames.BANQUET] = "Gain 2 Coppers and a non-Victory card//costing up to [5].";
EnglishCardTexts[CardNames.BASILICA] = "When you gain a card in your Buy phase, if you//have [2] or more, take {2} from here.//---//Setup: Put {6} here per player.";
EnglishCardTexts[CardNames.BATHS] = "When you end your turn without having gained a card,//take {2} from here.//---//Setup: Put {6} here per player.";
EnglishCardTexts[CardNames.BATTLEFIELD] = "When you gain a Victory card, take {2} from here.//---//Setup: Put {6} here per player.";
EnglishCardTexts[CardNames.CAPITAL] = "[!6]//|+1 Buy|//---//When you discard this from play,//take [6D], and then you may pay//off [D].";
EnglishCardTexts[CardNames.CHARM] = "Choose one: |+1 Buy| and |+|[2];//or the next time you gain a//card this turn, you may also//gain a differently named//card with the same cost.";
EnglishCardTexts[CardNames.CHARIOT_RACE] = "|+1 Action|////Reveal the top card of your deck//and put it into your hand. The//player to your left reveals the top//card of their deck. If your card//costs more, |+|[1] and |+|{1}.";
EnglishCardTexts[CardNames.CITY_QUARTER] = "|+2 Actions|////Reveal your hand. |+1 Card|//per Action card revealed.";
EnglishCardTexts[CardNames.COLONNADE] = "When you gain an Action card in your Buy phase, if you//have a copy of it in play, take {2} from here.//---//Setup: Put {6} here per player.";
EnglishCardTexts[CardNames.CONQUEST] = "Gain 2 Silvers.//|+|{1} per Silver you've gained this turn.";
EnglishCardTexts[CardNames.CROWN] = "If it's your Action phase, you//may play an Action from your//hand twice.//If it's your Buy phase, you//may play a Treasure from your//hand twice.";
EnglishCardTexts[CardNames.DELVE] = "|+1 Buy|////Gain a Silver.";
EnglishCardTexts[CardNames.DEFILED_SHRINE] = "When you gain an Action, move {1} from its pile to this.//When you gain a Curse in your Buy phase, take the {} from this.//---//Setup: Put {2} on each non-Gathering Action Supply pile.";
EnglishCardTexts[CardNames.DOMINATE] = "Gain a Province. If you do, |+|{9}.";
EnglishCardTexts[CardNames.DONATE] = "At the start of your next turn, first, put your deck and//discard pile into your hand, trash any number of cards//from it, then shuffle the rest into your deck and draw 5 cards.";
EnglishCardTexts[CardNames.ENCHANTRESS] = "Until your next turn, the first//time each other player plays an//Action card on their turn, they get//|+1 Card| and |+1 Action| instead//of following its instructions.//At the start of your next turn,// |+2 Cards|";
EnglishCardTexts[CardNames.ENGINEER] = "Gain a card costing up to [4].//You may trash this.//If you do, gain a card//costing up to [4].";
EnglishCardTexts[CardNames.FARMERS_MARKET] = "|+1 Buy|////If there are {4} or more on the//Farmers' Market pile, take//them and trash this. Otherwise, //add {1} to the pile and then//|+|[1] per {1} on the pile.";
EnglishCardTexts[CardNames.FORUM] = "|+3 Cards|//|+1 Action|////Discard 2 cards.//---//When you gain this, |+1 Buy|.";
EnglishCardTexts[CardNames.FOUNTAIN] = "When scoring, {15} if you have at least 10 Coppers.";
EnglishCardTexts[CardNames.GROUNDSKEEPER] = "|+1 Card|//|+1 Action|////This turn, when you//gain a Victory card, |+|{1}.";
EnglishCardTexts[CardNames.KEEP] = "When scoring, {5} per differently named Treasure you have,//that you have more copies of than each other player,//or tied for most.";
EnglishCardTexts[CardNames.LABYRINTH] = "When you gain a second card in one of your turns,//take {2} from here.//---//Setup: Put {6} here per player.";
EnglishCardTexts[CardNames.LEGIONARY] = "|+|[3]////You may reveal a Gold from//your hand. If you do, each//other player discards down to//2 cards in hand, then draws//a card.";
EnglishCardTexts[CardNames.MOUNTAIN_PASS] = "When you are the first player to gain a Province,//each player bids once, up to [40D], ending with you.//High bidder gets |+|{8} and takes the [D] they bid.";
EnglishCardTexts[CardNames.MUSEUM] = "When scoring, {2} per differently named card//you have.";
EnglishCardTexts[CardNames.OBELISK] = "When scoring, {2} per card you have from//the chosen pile.//---//Setup: Choose a random Action Supply pile.";
EnglishCardTexts[CardNames.ORCHARD] = "When scoring, {4} per differently named Action card you//have 3 or more copies of.";
EnglishCardTexts[CardNames.OVERLORD] = "Play a non-Command//Action card from the//Supply costing up to [5],//leaving it there.";
EnglishCardTexts[CardNames.PALACE] = "When scoring, {3} per set you have//of Copper - Silver - Gold.";
EnglishCardTexts[CardNames.RITUAL] = "Gain a Curse. If you do, trash a card from your hand.// |+|{1} per [1] it cost.";
EnglishCardTexts[CardNames.ROYAL_BLACKSMITH] = "|+5 Cards|////Reveal your hand; discard//the Coppers.";
EnglishCardTexts[CardNames.SACRIFICE] = "Trash a card from your hand.//If it's an...////Action card, |+2 Cards|,// |+2 Actions|//Treasure card, |+|[2]//Victory card, |+|{2}";
EnglishCardTexts[CardNames.SALT_THE_EARTH] = "|+|{1}//Trash a Victory card from the Supply.";
EnglishCardTexts[CardNames.TAX] = "Add [2D] to a Supply pile.//---//Setup: Add [1D] to each Supply pile. When a player//gains a card in their Buy phase, they take the [D] from its pile.";
EnglishCardTexts[CardNames.TEMPLE] = "|+{1}|//Trash from 1 to 3 differently//named cards from your hand.//Add {1} to the Temple pile.//---//When you gain this, take the//{} from the Temple pile.";
EnglishCardTexts[CardNames.TOMB] = "When you trash a card, |+|{1}.";
EnglishCardTexts[CardNames.TOWER] = "When scoring, {1} per non-Victory card you//have from an empty Supply pile.";
EnglishCardTexts[CardNames.TRIUMPH] = "Gain an Estate.//If you did, |+|{1} per card you've gained this turn.";
EnglishCardTexts[CardNames.TRIUMPHAL_ARCH] = "When scoring, {3} per copy you have of the second most common//Action card among your cards (if it's a tie, count either).";
EnglishCardTexts[CardNames.VILLA] = "|+2 Actions|//|+1 Buy|//|+[1]|//---//When you gain this, put it into//your hand, |+1 Action|, and if it's//your Buy phase return to//your Action phase.";
EnglishCardTexts[CardNames.WALL] = "When scoring, {-1} per card you have//after the first 15.";
EnglishCardTexts[CardNames.WOLF_DEN] = "When scoring, {-3} per card you have exactly//one copy of.";
EnglishCardTexts[CardNames.WEDDING] = "|+|{1}//Gain a Gold.";
EnglishCardTexts[CardNames.WILD_HUNT] = "Choose one: |+3 Cards| and//add {1} to the Wild Hunt//pile; or gain an Estate,//and if you do, take the {}//from the pile.";
EnglishCardTexts[CardNames.WINDFALL] = "If your deck and discard pile are empty, gain 3 Golds.";

EnglishCardTexts[CardNames.BARD] = "|+[2]|//// Receive a Boon.";
EnglishCardTexts[CardNames.BLESSED_VILLAGE] = "|+1 Card|//|+2 Actions|//---//When you gain this, take a Boon. //Receive it now or at the start//of your next turn.";
EnglishCardTexts[CardNames.CHANGELING] = "Trash this. Gain a copy//of a card you have in play.//---//In games using this, when you gain//a card costing [3] or more, you may//exchange it for a Changeling.";
EnglishCardTexts[CardNames.COBBLER] = "At the start of your next turn,//gain a card to your hand costing//up to [4].";
EnglishCardTexts[CardNames.CONCLAVE] = "|+[2]|////You may play an Action//card from your hand that//you don't have a copy//of in play. If you//do, |+1 Action.|";
EnglishCardTexts[CardNames.CRYPT] = "Set aside any number of//non-Duration Treasures you//have in play, face down//(under this). While any remain,//at the start of each of your turns,//put one of them into your hand.";
EnglishCardTexts[CardNames.CURSED_VILLAGE] = "|+2 Actions|////Draw until you have 6 cards//in hand.//---//When you gain this,//receive a Hex.";
EnglishCardTexts[CardNames.DEN_OF_SIN] = "At the start of your next turn: //|+2 Cards|.//---//This is gained to your hand //(instead of your discard pile).";
EnglishCardTexts[CardNames.DEVILS_WORKSHOP] = "If the number of cards you've//gained this turn is://|2+: |gain an Imp;//|1: |gain a card costing up to [4];//|0: |gain a Gold.";
EnglishCardTexts[CardNames.DRUID] = "|+1 Buy|////Receive one of the set-aside//Boons (leaving it there).//---//Setup: Set aside the//top 3 Boons face up.";
EnglishCardTexts[CardNames.EXORCIST] = "Trash a card from your hand.//Gain a cheaper Spirit from//one of the Spirit piles.";
EnglishCardTexts[CardNames.FAITHFUL_HOUND] = "|+2 Cards|//---//When you discard this other than//during Clean-up, you may set it//aside, and put it into your hand//at end of turn.";
EnglishCardTexts[CardNames.FOOL] = "If you aren't the player with//Lost in the Woods, take it,//take 3 Boons, and receive//the Boons in any order.////%%(Heirloom: Lucky Coin)%%";
EnglishCardTexts[CardNames.GHOST_TOWN] = "At the start of your next turn: //|+1 Card |and |+1 Action|.//---//This is gained to your hand //(instead of your discard pile).";
EnglishCardTexts[CardNames.GUARDIAN] = "At the start of your next turn,// |+|[1]. Until then, when//another player plays an Attack//card, it doesn't affect you. //---//This is gained to your hand //(instead of your discard pile).";
EnglishCardTexts[CardNames.IDOL] = "[!2]//If you have an odd number//of Idols in play (counting//this), receive a Boon;//otherwise, each other player//gains a Curse.";
EnglishCardTexts[CardNames.LEPRECHAUN] = "Gain a Gold.////If you have exactly 7 cards//in play, gain a Wish.//Otherwise, receive a Hex.";
EnglishCardTexts[CardNames.MONASTERY] = "For each card you've gained this//turn, you may trash a card from//your hand or a Copper you have//in play.";
EnglishCardTexts[CardNames.NECROMANCER] = "Play a face up, non-Duration//Action card from the trash,//leaving it there and turning it//face down for the turn.//---//Setup: Put the 3 Zombies//into the trash.";
EnglishCardTexts[CardNames.NIGHT_WATCHMAN] = "Look at the top 5 cards of your//deck, discard any number, and//put the rest back in any order.//---//This is gained to your hand //(instead of your discard pile).";
EnglishCardTexts[CardNames.PIXIE] = "|+1 Card|//|+1 Action|////Discard the top Boon. You may//trash this to receive that Boon//twice.////%%(Heirloom: Goat)%%";
EnglishCardTexts[CardNames.POOKA] = "You may trash a Treasure//other than Cursed Gold from//your hand, for |+4 Cards|.////%%(Heirloom: Cursed Gold)%%";
EnglishCardTexts[CardNames.RAIDER] = "Each other player with 5 or more//cards in hand discards a copy//of a card you have in play//(or reveals they can't).//At the start of your next turn,//+[3].";
EnglishCardTexts[CardNames.SACRED_GROVE] = "|+1 Buy|//|+[3]|////Receive a Boon. If it doesn't//give +[1], each other player//may receive it.";
EnglishCardTexts[CardNames.SECRET_CAVE] = "|+1 Card|//|+1 Action|////You may discard 3 cards.//If you did, then at the start//of your next turn, +[3].////%%(Heirloom: Magic Lamp)%%";
EnglishCardTexts[CardNames.SHEPHERD] = "|+1 Action|////Discard any number of Victory//cards, revealing them.//|+2 Cards| per card discarded.////%%(Heirloom: Pasture)%%";
EnglishCardTexts[CardNames.SKULK] = "|+1 Buy|////Each other player receives//the next Hex.//---//When you gain this,//gain a Gold.";
EnglishCardTexts[CardNames.TORMENTOR] = "|+|[2]//// If you have no other cards//in play, gain an Imp.//Otherwise, each other player //receives the next Hex.";
EnglishCardTexts[CardNames.TRAGIC_HERO] = "|+3 Cards|//|+1 Buy|////If you have 8 or more cards//in hand (after drawing), trash//this and gain a Treasure.";
EnglishCardTexts[CardNames.TRACKER] = "|+[1]|////This turn, when you gain//a card, you may put//it onto your deck.////Receive a Boon.//%%(Heirloom: Pouch)%%";
EnglishCardTexts[CardNames.VAMPIRE] = "Each other player receives the//next Hex. Gain a card costing//up to [5] other than a Vampire.//Exchange this for a Bat.";
EnglishCardTexts[CardNames.WEREWOLF] = "If it's your Night phase, each//other player receives the//next Hex. Otherwise,//|+3 Cards|.";
EnglishCardTexts[CardNames.CEMETERY] = "{!2}//---//When you gain this, trash up//to 4 cards from your hand.//%%(Heirloom: Haunted Mirror)%%";
EnglishCardTexts[CardNames.CURSED_GOLD] = "[!3]//Gain a Curse.";
EnglishCardTexts[CardNames.LUCKY_COIN] = "[!1]//Gain a Silver.";
EnglishCardTexts[CardNames.GOAT] = "[!1]//You may trash a card//from your hand.";
EnglishCardTexts[CardNames.HAUNTED_MIRROR] = "[!1]//---//When you trash this, you may//discard an Action card,//to gain a Ghost.";
EnglishCardTexts[CardNames.MAGIC_LAMP] = "[!1]//If there are at least 6 cards//that you have exactly 1 copy//of in play, trash this.//If you do, gain 3 Wishes.";
EnglishCardTexts[CardNames.PASTURE] = "[!1]//---//Worth {1} per Estate you//have.";
EnglishCardTexts[CardNames.POUCH] = "[!1]//|+1 Buy|";
EnglishCardTexts[CardNames.BAT] = "Trash up to 2 cards from your//hand. If you trashed at least one,//exchange this for a Vampire.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.GHOST] = "Reveal cards from your deck until//you reveal an Action. Discard the//other cards and set aside the//Action. At the start of your//next turn, play it twice.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.IMP] = "|+2 Cards|////You may play an Action card from //your hand that you don't have//a copy of in play.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.WILL_O_WISP] = "|+1 Card|//|+1 Action|////Reveal the top card of your//deck. If it costs [2] or less,//put it into your hand.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.WISH] = "|+1 Action|////Return this to its pile.//If you did, gain a card to //your hand costing up to [6].////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.ZOMBIE_APPRENTICE] = "You may trash an Action card//from your hand for//|+3 Cards| and |+1 Action|.";
EnglishCardTexts[CardNames.ZOMBIE_MASON] = "Trash the top card of your//deck. You may gain a card//costing up to [1] more than it.";
EnglishCardTexts[CardNames.ZOMBIE_SPY] = "|+1 Card|//|+1 Action|////Look at the top card of your//deck. Discard it or put it back.";
EnglishCardTexts[CardNames.THE_EARTHS_GIFT] = "You may discard a Treasure to gain a card//costing up to [4].";
EnglishCardTexts[CardNames.THE_FIELDS_GIFT] = "|+1 Action|//|+[1]|////(Keep this until Clean-up.)";
EnglishCardTexts[CardNames.THE_FLAMES_GIFT] = "You may trash a card from your hand.";
EnglishCardTexts[CardNames.THE_FORESTS_GIFT] = "|+1 Buy|//|+[1]|////(Keep this until Clean-up.)";
EnglishCardTexts[CardNames.THE_MOONS_GIFT] = "Look through your discard pile.//You may put a card from it onto your deck.";
EnglishCardTexts[CardNames.THE_MOUNTAINS_GIFT] = "Gain a Silver.";
EnglishCardTexts[CardNames.THE_RIVERS_GIFT] = "|+1 Card| at the end of this turn.//(Keep this until Clean-up.)";
EnglishCardTexts[CardNames.THE_SEAS_GIFT] = "|+1 Card|";
EnglishCardTexts[CardNames.THE_SKYS_GIFT] = "You may discard 3 cards to gain a Gold.";
EnglishCardTexts[CardNames.THE_SUNS_GIFT] = "Look at the top 4 cards of your deck. Discard//any number of them and put the rest back in any order.";
EnglishCardTexts[CardNames.THE_SWAMPS_GIFT] = "Gain a Will-o'-Wisp.";
EnglishCardTexts[CardNames.THE_WINDS_GIFT] = "|+2 Cards|////Discard 2 cards.";
EnglishCardTexts[CardNames.BAD_OMENS] = "Put your deck into your discard pile.//Put 2 Coppers from it onto your deck//(or reveal you can't).";
EnglishCardTexts[CardNames.DELUSION] = "If you don't have Deluded or Envious,//take Deluded.";
EnglishCardTexts[CardNames.ENVY] = "If you don't have Deluded or Envious,//take Envious.";
EnglishCardTexts[CardNames.FAMINE] = "Reveal the top 3 cards of your deck. Discard//the Actions. Shuffle the rest into your deck.";
EnglishCardTexts[CardNames.FEAR] = "If you have at least 5 cards in hand, discard//an Action or Treasure (or reveal you can't).";
EnglishCardTexts[CardNames.GREED] = "Gain a Copper onto your deck.";
EnglishCardTexts[CardNames.HAUNTING] = "If you have at least 4 cards in hand,//put one of them onto your deck.";
EnglishCardTexts[CardNames.LOCUSTS] = "Trash the top card of your deck. If it's Copper//or Estate, gain a Curse. Otherwise, gain a cheaper//card that shares a type with it.";
EnglishCardTexts[CardNames.MISERY] = "If this is your first Misery this game, take Miserable.//Otherwise, flip it over to Twice Miserable.";
EnglishCardTexts[CardNames.PLAGUE] = "Gain a Curse to your hand.";
EnglishCardTexts[CardNames.POVERTY] = "Discard down to 3 cards in hand.";
EnglishCardTexts[CardNames.WAR] = "Reveal cards from your deck until revealing one//costing [3] or [4]. Trash it and discard the rest.";
EnglishCardTexts[CardNames.DELUDED] = "At the start of your Buy phase, return this,//and you can't buy Actions this turn.";
EnglishCardTexts[CardNames.ENVIOUS] = "At the start of your Buy phase, return this,//and Silver and Gold make [1] this turn.";
EnglishCardTexts[CardNames.TWICE_MISERABLE] = "{-4}";
EnglishCardTexts[CardNames.MISERABLE] = "{-2}";
EnglishCardTexts[CardNames.LOST_IN_THE_WOODS] = "At the start of your turn, you may discard//a card to receive a Boon.";

EnglishCardTexts[CardNames.ACTING_TROUPE] = "|+4 Villagers|////Trash this.";
EnglishCardTexts[CardNames.BORDER_GUARD] = "|+1 Action|////Reveal the top 2 cards of your//deck. Put one into your hand//and discard the other. If both//were Actions, take the//Lantern or Horn.";
EnglishCardTexts[CardNames.CARGO_SHIP] = "|+[2]|////Once this turn, when you gain//a card, you may set it aside//face up (on this). At the start of//your next turn, put it into your//hand.";
EnglishCardTexts[CardNames.DUCAT] = "|+1 Coffers|//|+1 Buy|//---//When you gain this, you may//trash a Copper from your hand.";
EnglishCardTexts[CardNames.EXPERIMENT] = "|+2 Cards|//|+1 Action|////Return this to its pile.//---//When you gain this, gain// another Experiment (that//doesn't come with another).";
EnglishCardTexts[CardNames.FLAG_BEARER] = "|+[2]|//---//When you gain or trash this,//take the Flag.";
EnglishCardTexts[CardNames.HIDEOUT] = "|+1 Card|//|+2 Actions|////Trash a card from your hand.//If it's a Victory card,//gain a Curse.";
EnglishCardTexts[CardNames.INVENTOR] = "Gain a card costing//up to [4], then cards//cost [1] less this turn.";
EnglishCardTexts[CardNames.IMPROVE] = "|+[2]|////At the start of Clean-up, you//may trash an Action card you//would discard from play this//turn, to gain a card costing//exactly [1] more than it.";
EnglishCardTexts[CardNames.LACKEYS] = "|+2 Cards|//---//When you gain this,//|+2 Villagers|.";
EnglishCardTexts[CardNames.MOUNTAIN_VILLAGE] = "|+2 Actions|////Put a card from your//discard pile into your hand;//if you can't, |+1 Card|.";
EnglishCardTexts[CardNames.PATRON] = "|+1 Villager|//|+[2]|//---//When something causes//you to reveal this (using//the word \"reveal\") in an//Action phase, |+1 Coffers|.";
EnglishCardTexts[CardNames.PRIEST] = "|+[2]|////Trash a card from your hand.//For the rest of this turn, when//you trash a card, |+|[2].";
EnglishCardTexts[CardNames.RESEARCH] = "|+1 Action|////Trash a card from your hand. Per// [1] it costs, set aside a card from your//deck face down (on this).//At the start of your next turn,//put those cards into your hand.";
EnglishCardTexts[CardNames.SILK_MERCHANT] = "|+2 Cards|//|+1 Buy|//---//When you gain or trash this,//|+1 Coffers| and |+1 Villager|.";
EnglishCardTexts[CardNames.OLD_WITCH] = "|+3 Cards|////Each other player gains a//Curse and may trash a Curse//from their hand.";
EnglishCardTexts[CardNames.RECRUITER] = "|+2 Cards|////Trash a card from your hand.//|+1 Villager| per [1] it costs.";
EnglishCardTexts[CardNames.SCEPTER] = "When you play this,//choose one: |+|[2];//or replay a non-Command//Action card you played this//turn that's still in play.";
EnglishCardTexts[CardNames.SCHOLAR] = "Discard your hand.//|+7 Cards|.";
EnglishCardTexts[CardNames.SCULPTOR] = "Gain a card to your hand//costing up to [4]. If it's a//Treasure, |+1 Villager|.";
EnglishCardTexts[CardNames.SEER] = "|+1 Card|//|+1 Action|////Reveal the top 3 cards of your//deck. Put the ones costing from// [2] to [4] into your hand. Put//the rest back in any order.";
EnglishCardTexts[CardNames.SPICES] = "[!2]//|+1 Buy|//---//When you gain this,//|+2 Coffers|.";
EnglishCardTexts[CardNames.SWASHBUCKLER] = "|+3 Cards|////If your discard pile has any//cards in it: |+1 Coffers|, then if//you have at least 4 Coffers//tokens, take the Treasure Chest.";
EnglishCardTexts[CardNames.TREASURER] = "|+[3]|////Choose one: Trash a Treasure//from your hand; or gain a//Treasure from the trash to your//hand; or take the Key.";
EnglishCardTexts[CardNames.VILLAIN] = "|+2 Coffers|////Each other player with 5 or//more cards in hand discards//one costing [2] or more (or//reveals they can't).";
EnglishCardTexts[CardNames.FLAG] = "When drawing your hand, |+1 Card|.";
EnglishCardTexts[CardNames.HORN] = "Once per turn, when you discard a Border Guard//from play, you may put it onto your deck.";
EnglishCardTexts[CardNames.KEY] = "At the start of your turn, |+|[1].";
EnglishCardTexts[CardNames.LANTERN] = "Border Guards you play reveal 3 cards and discard 2.//(It takes all 3 being Actions to take the Horn.)";
EnglishCardTexts[CardNames.TREASURE_CHEST] = "At the start of your Buy phase, gain a Gold.";
EnglishCardTexts[CardNames.ACADEMY] = "When you gain an Action card,//|+1 Villager|.";
EnglishCardTexts[CardNames.BARRACKS] = "At the start of your turn, |+1 Action|.";
EnglishCardTexts[CardNames.CANAL] = "During your turns, cards cost [1] less.";
EnglishCardTexts[CardNames.CAPITALISM] = "During your turns, Actions with +[] amounts//in their text are also Treasures.";
EnglishCardTexts[CardNames.CATHEDRAL] = "At the start of your turn,//trash a card from your hand.";
EnglishCardTexts[CardNames.CITADEL] = "The first time you play an Action card during//each of your turns, replay it afterwards.";
EnglishCardTexts[CardNames.CITY_GATE] = "At the start of your turn, |+1 Card|,//then put a card from your hand onto your deck.";
EnglishCardTexts[CardNames.CROP_ROTATION] = "At the start of your turn, you may discard//a Victory card for |+2 Cards|.";
EnglishCardTexts[CardNames.EXPLORATION] = "At the end of your Buy phase, if you didn't gain//any cards during it, |+1 Coffers| and |+1 Villager|.";
EnglishCardTexts[CardNames.FAIR] = "At the start of your turn, |+1 Buy|.";
EnglishCardTexts[CardNames.FLEET] = "After the game ends, there's an extra round//of turns just for players with this.";
EnglishCardTexts[CardNames.GUILDHALL] = "When you gain a Treasure, |+1 Coffers|.";
EnglishCardTexts[CardNames.INNOVATION] = "Once during each of your turns, when you//gain an Action card, you may play it.";
EnglishCardTexts[CardNames.PAGEANT] = "At the end of your Buy phase,//you may pay [1] for |+1 Coffers|.";
EnglishCardTexts[CardNames.PIAZZA] = "At the start of your turn, reveal the top card//of your deck. If it's an Action, play it.";
EnglishCardTexts[CardNames.ROAD_NETWORK] = "When another player gains a Victory card,//|+1 Card|.";
EnglishCardTexts[CardNames.SEWERS] = "When you trash a card other than with this,//you may trash a card from your hand.";
EnglishCardTexts[CardNames.SILOS] = "At the start of your turn, discard any number of//Coppers, revealed, and draw that many cards.";
EnglishCardTexts[CardNames.SINISTER_PLOT] = "At the start of your turn, add a token here,//or remove your tokens here for |+1 Card| each.";
EnglishCardTexts[CardNames.STAR_CHART] = "When you shuffle, you may pick//one of the cards to go on top.";

EnglishCardTexts[CardNames.AVANTO] = "|+3 Cards|////You may play a Sauna//from your hand.";
EnglishCardTexts[CardNames.BLACK_MARKET] = "|+[2]|////Reveal the top 3 cards of the Black Market//deck. Play any number of Treasures from//your hand. You may buy one of the//revealed cards. Put the rest on the bottom//of the Black Market deck in any order.//---//Setup: Make a Black Market deck out of//different unused Kingdom cards.";
EnglishCardTexts[CardNames.ENVOY] = "Reveal the top 5 cards of your//deck. The player to your left//chooses one. Discard that one//and put the rest into your hand.";
EnglishCardTexts[CardNames.GOVERNOR] = "|+1 Action|////Choose one; you get the version//in parentheses: Each player gets//|+1 (+3) Cards|; or each player//gains a Silver (Gold); or each//player may trash a card from their//hand and gain a card costing//exactly [1] ([2]) more.";
EnglishCardTexts[CardNames.PRINCE] = "You may set aside (on this) a//non-Duration non-Command//Action card from your hand//costing up to [4]. At the start of//each of your turns, play that//card, leaving it set aside.";
EnglishCardTexts[CardNames.SAUNA] = "|+1 Card|//|+1 Action|////You may play an Avanto//from your hand.////This turn, when you play//a Silver, you may trash a//card from your hand.";
EnglishCardTexts[CardNames.STASH] = "[!2]//---//When shuffling this, you may//look through your remaining//deck, and may put this//anywhere in the shuffled cards.";
EnglishCardTexts[CardNames.SUMMON] = "Gain an Action card costing up to [4]. Set it aside.//If you did, then at the start of your next turn, play it.";
EnglishCardTexts[CardNames.WALLED_VILLAGE] = "|+1 Card|//|+2 Actions|//---//At the start of Clean-up, if you//have this and no more than one//other Action card in play, you//may put this onto your deck.";
EnglishCardTexts[CardNames.PIG] = "PIG!,";
EnglishCardTexts[CardNames.DISMANTLE] = "Trash a card from your hand.//If it costs [1] or more, gain a//cheaper card and a Gold.";
EnglishCardTexts[CardNames.CHURCH] = "|+1 Action|////Set aside up to 3 cards from your//hand face down. At the//start of your next turn, put them//into your hand, then you may//trash a card from your hand.";
EnglishCardTexts[CardNames.CAPTAIN] = "Now and at the start//of your next turn://Play a non-Duration,//non-Command Action card//from the Supply costing//up to [4], leaving it there.";

EnglishCardTexts[CardNames.SLEIGH] = "Gain 2 Horses.//---//When you gain a card,//you may discard this//to put that card into your//hand or onto your deck.";
EnglishCardTexts[CardNames.HORSE] = "|+2 Cards|//|+1 Action|////Return this to its pile.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.BLACK_CAT] = "|+2 Cards|////If it isn't your turn, each//other player gains a Curse.//---//When another player gains//a Victory card, you may//play this from your hand.";
EnglishCardTexts[CardNames.SUPPLIES] = "[!1]//When you play this, gain//a Horse onto your deck.";
EnglishCardTexts[CardNames.CAMEL_TRAIN] = "Exile a non-Victory//card from the Supply.//---//When you gain this, Exile//a Gold from the Supply.";
EnglishCardTexts[CardNames.GOATHERD] = "|+1 Action|////You may trash a card//from your hand.//|+1 Card| per card the//player to your right//trashed on their last turn.";
EnglishCardTexts[CardNames.SHEEPDOG] = "|+2 Cards|//---//When you gain a card,//you may play this//from your hand.";
EnglishCardTexts[CardNames.SNOWY_VILLAGE] = "|+1 Card|//|+4 Actions|//|+1 Buy|////Ignore any further//|+Actions| you get this turn.";
EnglishCardTexts[CardNames.STOCKPILE] = "[!3]//|+1 Buy|////When you play this, Exile it.";
EnglishCardTexts[CardNames.BOUNTY_HUNTER] = "|+1 Action|////Exile a card from your hand.//If you didn't have a copy//of it in Exile, |+|[3].";
EnglishCardTexts[CardNames.CARDINAL] = "|+[2]|////Each other player reveals the//top two cards of their deck,//Exiles one costing from [3]//to [6], and discards the rest.";
EnglishCardTexts[CardNames.CAVALRY] = "Gain 2 Horses.//---//When you gain this,//|+2 Cards|, |+1 Buy|, and if//it's your Buy phase return//to your Action phase.";
EnglishCardTexts[CardNames.GROOM] = "Gain a card costing //up to [4]. If it's an...//Action card, gain a Horse//Treasure card, gain a Silver//Victory card,|+1 Card|// and |+1 Action|";
EnglishCardTexts[CardNames.HOSTELRY] = "|+1 Card|//|+2 Actions|//---//When you gain this, you//may discard any number of//Treasures, revealed, to gain//that many Horses.";
EnglishCardTexts[CardNames.VILLAGE_GREEN] = "Either now or at the//start of your next turn,// |+1 Card| and |+2 Actions.|//---//When you discard this//other than during Clean-up,//you may reveal it to play it.";
EnglishCardTexts[CardNames.BARGE] = "Either now or at the//start of your next turn,//|+3 Cards| and |+1 Buy.| ";
EnglishCardTexts[CardNames.COVEN] = "|+1 Action|//|+[2]|////Each other player Exiles//a Curse from the Supply.//If they can't, they discard//their Exiled Curses.";
EnglishCardTexts[CardNames.DISPLACE] = "Exile a card from your hand.//Gain a differently named//card costing up to [2] more//than it.";
EnglishCardTexts[CardNames.FALCONER] = "Gain a card to your hand//costing less than this.//---//When any player gains a card//with 2 or more types (Action,//Attack, etc.), you may play//this from your hand.";
EnglishCardTexts[CardNames.FISHERMAN] = "|+1 Card|//|+1 Action|//|+[1]|//---//During your turns, if your//discard pile is empty, this//costs [3] less.";
EnglishCardTexts[CardNames.GATEKEEPER] = "At the start of your next//turn, |+|[3]. Until then,//when another player gains//an Action or Treasure card//they don't have an Exiled//copy of, they Exile it.";
EnglishCardTexts[CardNames.HUNTING_LODGE] = "|+1 Card|//|+2 Actions|////You may discard your//hand for |+5 Cards|.";
EnglishCardTexts[CardNames.KILN] = "|+[2]|////The next time you play//a card this turn, you may//first gain a copy of it.";
EnglishCardTexts[CardNames.LIVERY] = "|+[3]|////This turn, when you//gain a card costing [4]//or more, gain a Horse.";
EnglishCardTexts[CardNames.MASTERMIND] = "At the start of your next turn,//you may play an Action card//from your hand three times.";
EnglishCardTexts[CardNames.PADDOCK] = "|+[2]|////Gain 2 Horses.//|+1 Action| per empty//Supply pile.";
EnglishCardTexts[CardNames.SANCTUARY] = "|+1 Card|//|+1 Action|//|+1 Buy|////You may Exile a card//from your hand.";
EnglishCardTexts[CardNames.DESTRIER] = "|+2 Cards|//|+1 Action|//---//During your turns, this//costs [1] less per card//you've gained this turn.";
EnglishCardTexts[CardNames.WAYFARER] = "|+3 Cards|////You may gain a Silver.//---//This has the same cost as//the last other card gained//this turn, if any.";
EnglishCardTexts[CardNames.ANIMAL_FAIR] = "|+[4]|//|+1 Buy| per empty//Supply pile.//---//Instead of paying this card's//cost, you may trash an//Action card from your hand.";
EnglishCardTexts[CardNames.SCRAP] = "Trash a card from your hand.//Choose a different thing//per [1] it costs: |+1 Card|;//|+1 Action|; |+1 Buy|; |+|[1];//gain a Silver; gain a Horse.";
EnglishCardTexts[CardNames.DELAY] = "You may set aside an Action card from your//hand. At the start of your next turn, play it.";
EnglishCardTexts[CardNames.DESPERATION] = "Once per turn: You may gain a Curse.//If you do, |+1 Buy| and |+|[2].";
EnglishCardTexts[CardNames.GAMBLE] = "|+1 Buy|////Reveal the top card of your deck. If it's a Treasure//or Action, you may play it. Otherwise, discard it.";
EnglishCardTexts[CardNames.PURSUE] = "|+1 Buy|////Name a card. Reveal the top 4 cards from your deck.//Put the matches back and discard the rest.";
EnglishCardTexts[CardNames.RIDE] = "Gain a Horse.";
EnglishCardTexts[CardNames.TOIL] = "|+1 Buy|////You may play an Action card from your hand.";
EnglishCardTexts[CardNames.ENHANCE] = "You may trash a non-Victory card from your//hand, to gain a card costing up to [2] more than it. ";
EnglishCardTexts[CardNames.MARCH] = "Look through your discard pile.//You may play an Action card from it.";
EnglishCardTexts[CardNames.TRANSPORT] = "Choose one: Exile an Action card from//the Supply; or put an Action card you//have in Exile onto your deck.";
EnglishCardTexts[CardNames.BANISH] = "Exile any number of cards with//the same name from your hand.";
EnglishCardTexts[CardNames.BARGAIN] = "Gain a non-Victory card costing up to [5].//Each other player gains a Horse.";
EnglishCardTexts[CardNames.INVEST] = "Exile an Action card from the Supply.//While it's in Exile, when another player//gains or Invests in a copy of it, |+2 Cards|.";
EnglishCardTexts[CardNames.SEIZE_THE_DAY] = "Once per game://Take an extra turn after this one.";
EnglishCardTexts[CardNames.COMMERCE] = "Gain a Gold per differently named//card you've gained this turn.";
EnglishCardTexts[CardNames.DEMAND] = "Gain a Horse and a card costing//up to [4], both onto your deck.";
EnglishCardTexts[CardNames.STAMPEDE] = "If you have 5 or fewer cards in play,//gain 5 Horses onto your deck.";
EnglishCardTexts[CardNames.REAP] = "Gain a Gold. Set it aside. If you do,//at the start of your next turn, play it.";
EnglishCardTexts[CardNames.ENCLAVE] = "Gain a Gold. Exile a Duchy from the Supply.";
EnglishCardTexts[CardNames.ALLIANCE] = "Gain a Province, a Duchy, an Estate,//a Gold, a Silver, and a Copper.";
EnglishCardTexts[CardNames.POPULATE] = "Gain one card from each Action Supply pile.";
EnglishCardTexts[CardNames.WAY_OF_THE_CAMEL] = "Exile a Gold from the Supply.";
EnglishCardTexts[CardNames.WAY_OF_THE_MONKEY] = "|+1 Buy|//|+[1]|";
EnglishCardTexts[CardNames.WAY_OF_THE_OX] = "|+2 Actions|";
EnglishCardTexts[CardNames.WAY_OF_THE_OTTER] = "|+2 Cards|";
EnglishCardTexts[CardNames.WAY_OF_THE_GOAT] = "Trash a card from your hand.";
EnglishCardTexts[CardNames.WAY_OF_THE_HORSE] = "|+2 Cards|//|+1 Action|////Return this to its pile.";
EnglishCardTexts[CardNames.WAY_OF_THE_MOLE] = "|+1 Action|////Discard your hand. |+3 Cards.|";
EnglishCardTexts[CardNames.WAY_OF_THE_MULE] = "|+1 Action|//|+[1]|";
EnglishCardTexts[CardNames.WAY_OF_THE_PIG] = "|+1 Card|//|+1 Action|";
EnglishCardTexts[CardNames.WAY_OF_THE_RAT] = "You may discard a Treasure//to gain a copy of this.";
EnglishCardTexts[CardNames.WAY_OF_THE_SEAL] = "|+[1]|////This turn, when you gain a card,//you may put it onto your deck.";
EnglishCardTexts[CardNames.WAY_OF_THE_SHEEP] = "|+[2]|";
EnglishCardTexts[CardNames.WAY_OF_THE_SQUIRREL] = "|+2 Cards| at the end of this turn.";
EnglishCardTexts[CardNames.WAY_OF_THE_TURTLE] = "Set this aside. If you did, play//it at the start of your next turn.";
EnglishCardTexts[CardNames.WAY_OF_THE_WORM] = "Exile an Estate from the Supply.";
EnglishCardTexts[CardNames.WAY_OF_THE_MOUSE] = "Play the set-aside card, leaving it there.//---//Setup: Set aside an unused Action costing [2] or [3].";
EnglishCardTexts[CardNames.WAY_OF_THE_CHAMELEON] = "Follow this card's instructions; each time//that would give you |+Cards| this turn,//you get |+|[] instead, and vice-versa.";
EnglishCardTexts[CardNames.WAY_OF_THE_BUTTERFLY] = "You may return this to its pile to gain//a card costing exactly [1] more than it.";
EnglishCardTexts[CardNames.WAY_OF_THE_FROG] = "|+1 Action|////When you discard this from play//this turn, put it onto your deck.";
EnglishCardTexts[CardNames.WAY_OF_THE_OWL] = "Draw until you have 6 cards in hand.";
EnglishCardTexts[CardNames.CARD_OF_THE_BUTTERFLY] = "";
EnglishCardTexts[CardNames.CARD_OF_THE_CAMEL] = "";
EnglishCardTexts[CardNames.CARD_OF_THE_CHAMELEON] = "";
EnglishCardTexts[CardNames.CARD_OF_THE_FROG] = "";
EnglishCardTexts[CardNames.CARD_OF_THE_GOAT] = "";
EnglishCardTexts[CardNames.CARD_OF_THE_HORSE] = "";
EnglishCardTexts[CardNames.CARD_OF_THE_MOLE] = "";
EnglishCardTexts[CardNames.CARD_OF_THE_MONKEY] = "";
EnglishCardTexts[CardNames.CARD_OF_THE_MOUSE] = "";
EnglishCardTexts[CardNames.CARD_OF_THE_MULE] = "";
EnglishCardTexts[CardNames.CARD_OF_THE_OTTER] = "";
EnglishCardTexts[CardNames.CARD_OF_THE_OWL] = "";
EnglishCardTexts[CardNames.CARD_OF_THE_OX] = "";
EnglishCardTexts[CardNames.CARD_OF_THE_PIG] = "";
EnglishCardTexts[CardNames.CARD_OF_THE_RAT] = "";
EnglishCardTexts[CardNames.CARD_OF_THE_SEAL] = "";
EnglishCardTexts[CardNames.CARD_OF_THE_SHEEP] = "";
EnglishCardTexts[CardNames.CARD_OF_THE_SQUIRREL] = "";
EnglishCardTexts[CardNames.CARD_OF_THE_TURTLE] = "";
EnglishCardTexts[CardNames.CARD_OF_THE_WORM] = "";

EnglishCardTexts[CardNames.LIGHTHOUSE] = "|+1 Action|////Now and at the start of your//next turn, |+|[1].//Until then, when another//player plays an Attack card,//it doesn't affect you.";
EnglishCardTexts[CardNames.TREASURY] = "|+1 Card|//|+1 Action|//|+[1]|////At the end of your Buy phase//this turn, if you didn't gain//a Victory card in it, you may//put this onto your deck.";
EnglishCardTexts[CardNames.MONKEY] = "Until your next turn, when the//player to your right gains//a card, |+1 Card|.////At the start of your next turn,//|+1 Card|.";
EnglishCardTexts[CardNames.BLOCKADE] = "Gain a card costing up to [4],//setting it aside.//At the start of your next turn,//put it into your hand. While//it's set aside, when another//player gains a copy of it on//their turn, they gain a Curse.";
EnglishCardTexts[CardNames.SAILOR] = "|+1 Action|////Once this turn, when you gain a//Duration card, you may play it.////At the start of your next turn,//+[2] and you may trash a//card from your hand.";
EnglishCardTexts[CardNames.PIRATE] = "At the start of your next//turn, gain a Treasure costing//up to [6] to your hand.//---//When any player gains//a Treasure, you may play//this from your hand.";
EnglishCardTexts[CardNames.CORSAIR] = "|+[2]|////At the start of your next turn,//|+1 Card|. Until then, each other//player trashes the first Silver//or Gold they play each turn.";
EnglishCardTexts[CardNames.SEA_CHART] = "|+1 Card|//|+1 Action|////Reveal the top card of your//deck. If you have a copy of it in//play, put it into your hand.";
EnglishCardTexts[CardNames.ASTROLABE] = "Now and at the start//of your next turn:////[!1]//|+1 Buy|";
EnglishCardTexts[CardNames.TIDE_POOLS] = "|+3 Cards|//|+1 Action|////At the start of your next//turn, discard 2 cards.";
EnglishCardTexts[CardNames.SEA_WITCH] = "|+2 Cards|////Each other player gains//a Curse.////At the start of your next turn,//|+2 Cards|, then discard 2 cards.";

EnglishCardTexts[CardNames.HOARD] = "[!2]////This turn, when you gain//a Victory card, if you//bought it, gain a Gold.";
EnglishCardTexts[CardNames.MINT] = "You may reveal a Treasure from//your hand. Gain a copy of it.////---//When you gain this, trash//all non-Duration Treasures//you have in play.";
EnglishCardTexts[CardNames.QUARRY] = "[!1]////This turn, Actions//cost [2] less.";
EnglishCardTexts[CardNames.ANVIL] = "[!1]////You may discard a//Treasure to gain a card//costing up to [4].";
EnglishCardTexts[CardNames.CLERK] = "|+[2]|//Each other player with 5//or more cards in hand//puts one onto their deck.//---//At the start of your turn, you//may play this from your hand.";
EnglishCardTexts[CardNames.INVESTMENT] = "Trash a card from your hand.//Choose one: +[1]; or trash//this to reveal your hand//for +{1} per differently//named Treasure there.";
EnglishCardTexts[CardNames.TIARA] = "|+1 Buy|////This turn, when you gain a//card, you may put it onto//your deck.////You may play a Treasure from//your hand twice.";
EnglishCardTexts[CardNames.CHARLATAN] = "|+[3]|//Each other player gains a Curse.////---//In games using this, Curse is//also a Treasure worth [1].";
EnglishCardTexts[CardNames.COLLECTION] = "[!2]//|+1 Buy|////This turn, when you gain//an Action card, +{1}.";
EnglishCardTexts[CardNames.CRYSTAL_BALL] = "[!1]////Look at the top card of//your deck. You may trash it,//discard it, or, if it's an Action//or Treasure, you may play it.";
EnglishCardTexts[CardNames.MAGNATE] = "Reveal your hand. //|+1 Card| per Treasure in it.";
EnglishCardTexts[CardNames.WAR_CHEST] = "The player to your left//names a card. Gain a card//costing up to [5] that//hasn't been named for//War Chests this turn.";

EnglishCardTexts[CardNames.FARMLAND] = "{!2}//---//When you gain this, trash a//card from your hand and//gain a non-Farmland card costing//exactly [2] more than it.";
EnglishCardTexts[CardNames.HAGGLER] = "|+[2]|////This turn, when you gain//a card, if you bought it,//gain a cheaper//non-Victory card.";
EnglishCardTexts[CardNames.HIGHWAY] = "|+1 Card|//|+1 Action|////This turn, cards cost [1] less.";
EnglishCardTexts[CardNames.BERSERKER] = "Gain a card costing less than this.//Each other player discards down//to 3 cards in hand.//---//When you gain this, if you have//an Action in play, play this.";
EnglishCardTexts[CardNames.GUARD_DOG] = "|+2 Cards|////If you have 5 or fewer cards//in hand, |+2 Cards|.//---//When another player plays an//Attack, you may first play this//from your hand.";
EnglishCardTexts[CardNames.NOMADS] = "|+1 Buy|//|+[2]|//---//When you gain or//trash this, +[2].";
EnglishCardTexts[CardNames.TRAIL] = "|+1 Card|//|+1 Action|//---//When you gain, trash,//or discard this other//than in Clean-up,//you may play it.";
EnglishCardTexts[CardNames.WEAVER] = "Gain two Silvers or a//card costing up to [4].//---//When you discard this//other than in Clean-up,//you may play it.";
EnglishCardTexts[CardNames.SOUK] = "|+1 Buy|//|+[7]|////-[1] per card in your hand.//(You can't go below [0].)//---//When you gain this, trash up//to 2 cards from your hand.";
EnglishCardTexts[CardNames.CAULDRON] = "[!2]//|+1 Buy|////The third time you gain//an Action this turn, each//other player gains a Curse.";
EnglishCardTexts[CardNames.WHEELWRIGHT] = "|+1 Card|//|+1 Action|////You may discard a card//to gain an Action card//costing as much or less.";
EnglishCardTexts[CardNames.WITCHS_HUT] = "|+4 Cards|////Discard 2 cards, revealed.//If they're both Actions,//each other player//gains a Curse.";

EnglishCardTexts[CardNames.UNUSED_SLOT_27] = "unused";
EnglishCardTexts[CardNames.UNUSED_SLOT_28] = "unused";
EnglishCardTexts[CardNames.UNUSED_SLOT_29] = "unused";
EnglishCardTexts[CardNames.UNUSED_SLOT_30] = "unused";
EnglishCardTexts[CardNames.UNUSED_SLOT_31] = "unused";
EnglishCardTexts[CardNames.UNUSED_SLOT_32] = "unused";
EnglishCardTexts[CardNames.UNUSED_SLOT_33] = "unused";
EnglishCardTexts[CardNames.UNUSED_SLOT_34] = "unused";
EnglishCardTexts[CardNames.UNUSED_SLOT_35] = "unused";
EnglishCardTexts[CardNames.UNUSED_SLOT_36] = "unused";
EnglishCardTexts[CardNames.UNUSED_SLOT_37] = "unused";
EnglishCardTexts[CardNames.UNUSED_SLOT_38] = "unused";
EnglishCardTexts[CardNames.UNUSED_SLOT_39] = "unused";
EnglishCardTexts[CardNames.UNUSED_SLOT_40] = "unused";
EnglishCardTexts[CardNames.UNUSED_SLOT_41] = "unused";
EnglishCardTexts[CardNames.MARCHLAND] = "Worth {1} per 3 Victory//cards you have (round down).//---//When you gain this, |+1 Buy|,//and discard any number of//cards for +[1] each.";

EnglishCardTexts[CardNames.TOWN] = "Choose one://|+1 Card| and |+2 Actions|;// or |+1 Buy| and +[2].";
EnglishCardTexts[CardNames.MODIFY] = "Trash a card from your hand.//Choose one: |+1 Card| and//|+1 Action|; or gain a card//costing up to [2] more//than the trashed card.";
EnglishCardTexts[CardNames.MERCHANT_CAMP] = "|+2 Actions|//+[1]//---//When you discard this//from play, you may//put it onto your deck.";
EnglishCardTexts[CardNames.SWAP] = "|+1 Card|//|+1 Action|////You may return an Action//from your hand to its pile,//to gain to your hand a different//Action card costing up to [5].";
EnglishCardTexts[CardNames.SKIRMISHER] = "|+1 Card|//|+1 Action|//|+[1]|//This turn, when you gain//an Attack card, each other//player discards down to//3 cards in hand.";
EnglishCardTexts[CardNames.HIGHWAYMAN] = "At the start of your next//turn, discard this from//play and |+3 Cards|.////Until then, the first Treasure//each other player plays//each turn does nothing.";
EnglishCardTexts[CardNames.BARBARIAN] = "|+[2]|//Each other player trashes the top//card of their deck. If it costs//[3] or more they gain a cheaper//card sharing a type with it;//otherwise they gain a Curse.";
EnglishCardTexts[CardNames.INNKEEPER] = "|+1 Action|////Choose one: |+1 Card|; or//|+3 Cards|, then discard//3 cards; or |+5 Cards|,//then discard 6 cards.";
EnglishCardTexts[CardNames.CAPITAL_CITY] = "|+1 Card|//|+2 Actions|////You may discard 2 cards for +[2].//You may pay [2] for |+2 Cards|.";
EnglishCardTexts[CardNames.SPECIALIST] = "//You may play an Action//or Treasure from your//hand. Choose one://Play it again;//or gain a copy of it.";
EnglishCardTexts[CardNames.CARPENTER] = "If no Supply piles are//empty, |+1 Action| and gain//a card costing up to [4].////Otherwise, trash a card from//your hand and gain a card//costing up to [2] more than it.";
EnglishCardTexts[CardNames.ROYAL_GALLEY] = "|+1 Card|////You may play a non-Duration//Action card from your hand.//Set it aside; if you did,//then at the start of your//next turn, play it.";
EnglishCardTexts[CardNames.MARQUIS] = "|+1 Buy|////|+1 Card| per card in//your hand. Discard down//to 10 cards in hand.";
EnglishCardTexts[CardNames.SENTINEL] = "Look at the top 5 cards//of your deck. You may//trash up to 2 of them.//Put the rest back//in any order.";
EnglishCardTexts[CardNames.COURIER] = "|+[1]|//Discard the top card of your//deck. Look through your//discard pile; you may play an//Action or Treasure from it.";
EnglishCardTexts[CardNames.GALLERIA] = "|+[3]|////This turn, when you//gain a card costing//[3] or [4], |+1 Buy|.";
EnglishCardTexts[CardNames.HUNTER] = "|+1 Action|////Reveal the top 3 cards of//your deck. From those cards,//put an Action, a Treasure,//and a Victory card into//your hand. Discard the rest.";

EnglishCardTexts[CardNames.SYCOPHANT] = "|+1 Action|//Discard 3 cards. If you//discarded at least one, |+|[3].//---//When you gain or//trash this, |+2 Favors|.";
EnglishCardTexts[CardNames.UNDERLING] = "|+1 Card|//|+1 Action|//|+1 Favor|";
EnglishCardTexts[CardNames.IMPORTER] = "At the start of your//next turn, gain a card//costing up to [5].//---//Setup: Each player//gets |+4 Favors|.";
EnglishCardTexts[CardNames.CONTRACT] = "[!2]//|+1 Favor|////You may set aside an Action//from your hand to play it//at the start of your next turn.";
EnglishCardTexts[CardNames.EMISSARY] = "|+3 Cards|////If this made you shuffle//(at least one card),//|+1 Action| and |+2 Favors|.";
EnglishCardTexts[CardNames.BAUBLE] = "Choose two different options://|+1 Buy|; +[1]; |+1 Favor|;//this turn, when you gain a//card, you may put it//onto your deck.";
EnglishCardTexts[CardNames.BROKER] = "Trash a card from your//hand and choose one:////|+1 Card| per [1] it costs;//or |+1 Action| per [1] it costs;//or +[1] per [1] it costs;//or |+1 Favor| per [1] it costs.";
EnglishCardTexts[CardNames.GUILDMASTER] = "|+[3]|////This turn, when you//gain a card, |+1 Favor|.";
EnglishCardTexts[CardNames.ARCHITECTS_GUILD] = "When you gain a card,//you may spend |2 Favors| to gain//a cheaper non-Victory card.";
EnglishCardTexts[CardNames.ORDER_OF_ASTROLOGERS] = "When shuffling, you may pick one card//per |Favor| you spend to go on top.";
EnglishCardTexts[CardNames.LEAGUE_OF_BANKERS] = "At the start of your Buy phase, +[1]//per |4 Favors| you have (round down).";
EnglishCardTexts[CardNames.MOUNTAIN_FOLK] = "At the start of your turn, you may//spend |5 Favors| for |+3 Cards|.";
EnglishCardTexts[CardNames.WOODWORKERS_GUILD] = "At the start of your Buy phase, you may spend//a |Favor| to trash an Action card from your hand.//If you did, gain an Action card.";
EnglishCardTexts[CardNames.PEACEFUL_CULT] = "At the start of your Buy phase, you may//spend any number of |Favors| to trash//that many cards from your hand.";
EnglishCardTexts[CardNames.CRAFTERS_GUILD] = "At the start of your turn, you may//spend |2 Favors| to gain a card//costing up to [4] onto your deck.";
EnglishCardTexts[CardNames.CAVE_DWELLERS] = "At the start of your turn, you may//spend a |Favor|, to discard a card then//draw a card. Repeat as desired.";
EnglishCardTexts[CardNames.PLATEAU_SHEPHERDS] = "When scoring, pair up your |Favors|//with cards you have costing [2],//for {2} per pair.";
EnglishCardTexts[CardNames.DESERT_GUIDES] = "At the start of your turn, you may//spend a |Favor| to discard your hand//and draw 5 cards. Repeat as desired.";
EnglishCardTexts[CardNames.FAMILY_OF_INVENTORS] = "At the start of your Buy phase, you may put a|Favor|// token you have on a non-Victory Supply pile.//Cards cost [1] less per |Favor| token on their piles.";
EnglishCardTexts[CardNames.ISLAND_FOLK] = "At the end of your turn, you may spend//|5 Favors| to take an extra turn after//this one (but not a 3rd turn in a row).";
EnglishCardTexts[CardNames.ORDER_OF_MASONS] = "When shuffling, you may pick//up to 2 cards per |Favor| you spend//to put into your discard pile.";
EnglishCardTexts[CardNames.BAND_OF_NOMADS] = "When you gain a card costing [3] or more,//you may spend a |Favor|, for |+1 Card|,//or |+1 Action|, or |+1 Buy|.";
EnglishCardTexts[CardNames.GANG_OF_PICKPOCKETS] = "At the start of your turn, discard//down to 4 cards in hand unless//you spend a |Favor|.";
EnglishCardTexts[CardNames.MARKET_TOWNS] = "At the start of your Buy phase,//you may spend a |Favor| to play an Action//card from your hand. Repeat as desired.";
EnglishCardTexts[CardNames.FOREST_DWELLERS] = "At the start of your turn, you may spend a |Favor|//to look at the top 3 cards of your deck, discard//any number and put the rest back in any order.";
EnglishCardTexts[CardNames.FELLOWSHIP_OF_SCRIBES] = "After playing an Action, if you have//4 or fewer cards in hand, you may//spend a |Favor| for |+1 Card|.";
EnglishCardTexts[CardNames.LEAGUE_OF_SHOPKEEPERS] = "After playing a Liaison,//if you have |5| or more |Favors|, |+|[1],//and if |10| or more, |+1 Action| and |+1 Buy|.";
EnglishCardTexts[CardNames.COASTAL_HAVEN] = "When discarding your hand in Clean-up, you may//spend any number of |Favors| to keep that many//cards in hand for next turn (you still draw 5).";
EnglishCardTexts[CardNames.CITY_STATE] = "When you gain an Action card//during your turn, you may//spend |2 Favors| to play it.";
EnglishCardTexts[CardNames.TRAPPERS_LODGE] = "When you gain a card, you may spend//a |Favor| to put it onto your deck.";
EnglishCardTexts[CardNames.CIRCLE_OF_WITCHES] = "After playing a Liaison, you may spend//|3 Favors| to have each other player gain a Curse.";
EnglishCardTexts[CardNames.AUGUR_PILE] = "This pile starts the game//with 4 copies each of//Herb Gatherer, Acolyte//Sorceress, and Sibyl, in that//order. Only the top card can//be gained or bought.";
EnglishCardTexts[CardNames.HERB_GATHERER] = "|+1 Buy|////Put your deck into your//discard pile. Look through//it and you may play//a Treasure from it.////You may rotate the Augurs.";
EnglishCardTexts[CardNames.ACOLYTE] = "//You may trash an//Action or Victory//card from your hand//to gain a Gold.////You may trash this//to gain an Augur.";
EnglishCardTexts[CardNames.SORCERESS] = "|+1 Action|////Name a card. Reveal the//top card of your deck and//put it into your hand.//If it's the named card, each//other player gains a Curse.";
EnglishCardTexts[CardNames.SIBYL] = "|+4 Cards|//|+1 Action|////Put a card from your hand//on top of your deck, and//another on the bottom.";
EnglishCardTexts[CardNames.CLASH_PILE] = "This pile starts the game//with 4 copies each of//Battle Plan, Archer, Warlord,//and Territory, in that order.//Only the top card can//be gained or bought.";
EnglishCardTexts[CardNames.BATTLE_PLAN] = "|+1 Card|//|+1 Action|////You may reveal an Attack card//from your hand for |+1 Card|.////You may rotate any Supply pile.";
EnglishCardTexts[CardNames.ARCHER] = "|+[2]|////Each other player with//5 or more cards in hand//reveals all but one,//and discards one of//them that you choose.";
EnglishCardTexts[CardNames.WARLORD] = "|+1 Action|////At the start of your next turn,//|+2 Cards|. Until then, other//players can't play an Action//from their hand that they have//2 or more copies of in play.";
EnglishCardTexts[CardNames.TERRITORY] = "Worth {1} per//differently named//Victory card you have.//---//When you gain this,//gain a Gold per//empty Supply pile.";
EnglishCardTexts[CardNames.FORT_PILE] = "This pile starts the game//with 4 copies each of Tent,//Garrison, Hill Fort, and//Stronghold, in that order.//Only the top card can//be gained or bought.";
EnglishCardTexts[CardNames.TENT] = "|+[2]|//You may rotate the Forts.//---//When you discard this//from play, you may//put it onto your deck.";
EnglishCardTexts[CardNames.GARRISON] = "|+[2]|////This turn, when you gain//a card, add a token here.//At the start of your//next turn, remove them//for |+1 Card| each.";
EnglishCardTexts[CardNames.HILL_FORT] = "Gain a card costing up//to [4]. Choose one://Put it into your hand;//or |+1 Card| and |+1 Action|.";
EnglishCardTexts[CardNames.STRONGHOLD] = "Choose one: |+|[3];//or at the start of your//next turn, |+3 Cards|.//---//{!2}";
EnglishCardTexts[CardNames.ODYSSEY_PILE] = "This pile starts the game//with 4 copies each of//Old Map, Voyage, Sunken//Treasure, and Distant Shore,//in that order. Only the top//card can be gained or bought.";
EnglishCardTexts[CardNames.OLD_MAP] = "|+1 Card|//|+1 Action|////Discard a card. |+1 Card|.////You may rotate//the Odysseys.";
EnglishCardTexts[CardNames.VOYAGE] = "|+1 Action|////Take an extra turn after this//one (but not a 3rd turn//in a row), during which//you can only play 3//cards from your hand.";
EnglishCardTexts[CardNames.SUNKEN_TREASURE] = "Gain an Action card//you don't have//a copy of in play.";
EnglishCardTexts[CardNames.DISTANT_SHORE] = "|+2 Cards|//|+1 Action|////Gain an Estate.//---//{!2}";
EnglishCardTexts[CardNames.TOWNSFOLK_PILE] = "This pile starts the game//with 4 copies each of//Town Crier, Blacksmith//Miller, and Elder, in that//order. Only the top card can//be gained or bought.";
EnglishCardTexts[CardNames.TOWN_CRIER] = "Choose one: |+|[2]; or//gain a Silver; or//|+1 Card| and |+1 Action|.////You may rotate//the Townsfolk. ";
EnglishCardTexts[CardNames.BLACKSMITH] = "Choose one://Draw until you have 6 cards//in hand; or |+2 Cards|;//or |+1 Card| and |+1 Action|.";
EnglishCardTexts[CardNames.MILLER] = "|+1 Action|////Look at the top 4 cards//of your deck. Put one//into your hand and//discard the rest.";
EnglishCardTexts[CardNames.ELDER] = "|+[2]|////You may play an Action card//from your hand. When it gives//you a choice of abilities//(with \"choose\") this turn,//you may choose an extra//(different) option.";
EnglishCardTexts[CardNames.WIZARD_PILE] = "This pile starts the game//with 4 copies each of//Student, Conjurer, Sorcerer,//and Lich, in that order.//Only the top card can be//gained or bought.";
EnglishCardTexts[CardNames.STUDENT] = "|+1 Action|//You may rotate the Wizards.////Trash a card from your hand.//If it's a Treasure, |+1 Favor|//and put this onto your deck.";
EnglishCardTexts[CardNames.CONJURER] = "Gain a card costing//up to [4].////At the start of your//next turn, put this//into your hand.";
EnglishCardTexts[CardNames.SORCERER] = "|+1 Card|//|+1 Action|////Each other player names a//card, then reveals the top card//of their deck. If wrong,//they gain a Curse.";
EnglishCardTexts[CardNames.LICH] = "|+6 Cards|//|+2 Actions|////Skip a turn.//---//When you trash this, discard//it and gain a cheaper card//from the trash.";

EnglishCardTexts[CardNames.BOON_DRAWPILE] = "";
EnglishCardTexts[CardNames.HEX_DRAWPILE] = "";

EnglishCardTexts[CardNames.CAGE] = "Set aside up to 4 cards from//your hand face down (on this).//The next time you gain a//Victory card, trash this, and put//the set aside cards into your//hand at end of turn.";
EnglishCardTexts[CardNames.GROTTO] = "|+1 Action|////Set aside up to 4 cards from//your hand face down (on this).//At the start of your next turn,//discard them, then draw as many.";
EnglishCardTexts[CardNames.JEWELLED_EGG] = "[!1]//|+1 Buy|//---//When you trash this, gain//a Loot.";
EnglishCardTexts[CardNames.SEARCH] = "|+[2]|////The next time a Supply pile//empties, trash this and//gain a Loot.";
EnglishCardTexts[CardNames.SHAMAN] = "|+1 Action|//|+[1]|////You may trash a card from//your hand.//---//In games using this, at the start//of your turn, gain a card from//the trash costing up to [6].";
EnglishCardTexts[CardNames.SECLUDED_SHRINE] = "|+[1]|////The next time you gain//a Treasure, trash up to//2 cards from your hand.";
EnglishCardTexts[CardNames.SIREN] = "Each other player gains//a Curse.////At the start of your next turn,//draw up to 8 cards in hand.//---//When you gain this, trash it//unless you trash an Action//from your hand.";
EnglishCardTexts[CardNames.STOWAWAY] = "At the start of your next//turn, |+2 Cards|.//---//When anyone gains a Duration//card, you may play this//from your hand.";
EnglishCardTexts[CardNames.TASKMASTER] = "|+1 Action|, |+|[1], and if you//gain a card costing exactly [5]//this turn, then at the start//of your next turn, repeat//this ability.";
EnglishCardTexts[CardNames.ABUNDANCE] = "The next time you gain//an Action card,//|+1 Buy| and |+|[3].";
EnglishCardTexts[CardNames.CABIN_BOY] = "|+1 Card|//|+1 Action|////At the start of your next turn,//choose one: |+|[2]; or trash this//to gain a Duration card.";
EnglishCardTexts[CardNames.CRUCIBLE] = "Trash a card from your hand.//|+|[1] per [1] it costs.";
EnglishCardTexts[CardNames.FLAGSHIP] = "|+[2]|////The next time you play a//non-Command Action//card, replay it.";
EnglishCardTexts[CardNames.FORTUNE_HUNTER] = "|+[2]|////Look at the top 3 cards of//your deck. You may play//a Treasure from them. Put//the rest back in any order.";
EnglishCardTexts[CardNames.GONDOLA] = "Either now or at the start of//your next turn: |+|[2].//---//When you gain this, you//may play an Action card//from your hand.";
EnglishCardTexts[CardNames.HARBOR_VILLAGE] = "|+1 Card|//|+2 Actions|////After the next Action you play//this turn, if it gave you//|+|[], |+|[1].";
EnglishCardTexts[CardNames.LANDING_PARTY] = "|+2 Cards|//|+2 Actions|////The next time the first card you// play on a turn is a Treasure,//put this onto your deck//afterwards.";
EnglishCardTexts[CardNames.MAPMAKER] = "Look at the top 4 cards//of your deck. Put 2 into your//hand and discard the rest.//---//When any player gains a Victory//card, you may play this//from your hand.";
EnglishCardTexts[CardNames.MAROON] = "Trash a card from your hand.//|+2 Cards| per type it has//(Action, Attack, etc.).";
EnglishCardTexts[CardNames.ROPE] = "[!1]//|+1 Buy|////At the start of your next turn,//|+1 Card| and you may trash a//card from your hand.";
EnglishCardTexts[CardNames.SWAMP_SHACKS] = "|+2 Actions|////|+1 Card| per 3 cards you//have in play (round down).";
EnglishCardTexts[CardNames.TOOLS] = "Gain a copy of a card// anyone has in play.";
EnglishCardTexts[CardNames.BURIED_TREASURE] = "At the start of your next//turn, |+1 Buy| and |+|[3].//---//When you gain this, play it.";
EnglishCardTexts[CardNames.CREW] = "|+3 Cards|////At the start of your next//turn, put this onto your deck.";
EnglishCardTexts[CardNames.CUTTHROAT] = "Each other player discards//down to 3 cards in hand.//The next time anyone gains//a Treasure costing [5] or//more, gain a Loot.";
EnglishCardTexts[CardNames.ENLARGE] = "Now and at the start of your//next turn: Trash a card from//your hand, and gain one//costing up to [2] more.";
EnglishCardTexts[CardNames.FIGURINE] = "|+2 Cards|////You may discard an Action//card for |+1 Buy| and |+|[1].";
EnglishCardTexts[CardNames.FIRST_MATE] = "Play any number of Action//cards with the same name//from your hand, then draw//until you have 6 cards//in hand.";
EnglishCardTexts[CardNames.FRIGATE] = "|+[3]|////Until the start of your next//turn, each time another player//plays an Action card, they//discard down to 4 cards//in hand afterwards.";
EnglishCardTexts[CardNames.LONGSHIP] = "|+2 Actions|////At the start of your next//turn, |+2 Cards|.";
EnglishCardTexts[CardNames.MINING_ROAD] = "|+1 Action|//|+1 Buy|//|+[2]|////Once this turn, when you//gain a Treasure, you may//play it.";
EnglishCardTexts[CardNames.PENDANT] = "|+|[1] per differently//named Treasure you//have in play.";
EnglishCardTexts[CardNames.PICKAXE] = "[!1]////Trash a card from your hand.//If it costs [3] or more,//gain a Loot to your hand.";
EnglishCardTexts[CardNames.PILGRIM] = "|+4 Cards|////Put a card from your hand//onto your deck.";
EnglishCardTexts[CardNames.QUARTERMASTER] = "At the start of each of your//turns for the rest of the game,//choose one: Gain a card costing//up to [4], setting it aside on//this; or put a card from this//into your hand.";
EnglishCardTexts[CardNames.SILVER_MINE] = "Gain a Treasure costing less//than this to your hand.";
EnglishCardTexts[CardNames.TRICKSTER] = "Each other player gains a Curse.//Once this turn, when you discard//a Treasure from play, you may//set it aside. Put it in your//hand at end of turn.";
EnglishCardTexts[CardNames.WEALTHY_VILLAGE] = "|+1 Card|//|+2 Actions|//---//When you gain this, if you//have at least 3 differently//named Treasures in play,//gain a Loot.";
EnglishCardTexts[CardNames.SACK_OF_LOOT] = "[!1]//|+1 Buy|//Gain a Loot.";
EnglishCardTexts[CardNames.KINGS_CACHE] = "You may play a Treasure from//your hand 3 times.";
EnglishCardTexts[CardNames.BURY] = "|+1 Buy|////Put any card from your discard pile//on the bottom of your deck.";
EnglishCardTexts[CardNames.AVOID] = "|+1 Buy|////The next time you shuffle this turn, pick up to 3//cards to put into your discard pile.";
EnglishCardTexts[CardNames.DELIVER] = "|+1 Buy|////This turn, each time you gain a card, set it aside,//and put it into your hand at end of turn.";
EnglishCardTexts[CardNames.PERIL] = "You may trash an Action card from//your hand to gain a Loot.";
EnglishCardTexts[CardNames.RUSH] = "|+1 Buy|////The next time you gain an Action card this turn, play it.";
EnglishCardTexts[CardNames.FORAY] = "Discard 3 cards, revealing them. If they//have 3 different names, gain a Loot.";
EnglishCardTexts[CardNames.LAUNCH] = "Once per turn: Return to your Action phase.//|+1 Card|, |+1 Action|, and |+1 Buy|.";
EnglishCardTexts[CardNames.MIRROR] = "|+1 Buy|////The next time you gain an Action card//this turn, gain a copy of it.";
EnglishCardTexts[CardNames.PREPARE] = "Set aside your hand face up. At the start of//your next turn, play those Actions and Treasures//in any order, then discard the rest.";
EnglishCardTexts[CardNames.SCROUNGE] = "Choose one: Trash a card from your hand;//or gain an Estate from the trash, and if you did,//gain a card costing up to [5].";
EnglishCardTexts[CardNames.JOURNEY] = "You don't discard cards from play in Clean-up//this turn. Take an extra turn after this one//(but not a 3rd turn in a row).";
EnglishCardTexts[CardNames.MAELSTROM] = "Trash 3 cards from your hand. Each other player with 5//or more cards in hand trashes one of them.";
EnglishCardTexts[CardNames.LOOTING] = "Gain a Loot.";
EnglishCardTexts[CardNames.INVASION] = "You may play an Attack from your hand. Gain a Duchy.//Gain an Action onto your deck. Gain a Loot; play it.";
EnglishCardTexts[CardNames.PROSPER] = "Gain a Loot, plus any number of//differently named Treasures.";
EnglishCardTexts[CardNames.AMPHORA] = "Either now or at the//start of your next turn://|+1 Buy| and |+|[3].";
EnglishCardTexts[CardNames.DOUBLOONS] = "[!3]////---//When you gain this,//gain a Gold.";
EnglishCardTexts[CardNames.ENDLESS_CHALICE] = "Now and at the start of//each of your turns for//the rest of the game://[!1]//|+1 Buy|";
EnglishCardTexts[CardNames.FIGUREHEAD] = "[!3]////At the start of your//next turn, |+2 Cards|.";
EnglishCardTexts[CardNames.HAMMER] = "[!3]////Gain a card costing//up to [4].";
EnglishCardTexts[CardNames.INSIGNIA] = "[!3]////This turn, when you//gain a card, you may//put it onto your deck.";
EnglishCardTexts[CardNames.JEWELS] = "[!3]//|+1 Buy|////At the start of your//next turn, put this//on the bottom of your deck.";
EnglishCardTexts[CardNames.ORB] = "Look through your discard//pile. Choose one: Play an//Action or Treasure from//it; or |+1 Buy| and |+|[3].";
EnglishCardTexts[CardNames.PRIZE_GOAT] = "[!3]//|+1 Buy|////You may trash a card//from your hand.";
EnglishCardTexts[CardNames.PUZZLE_BOX] = "[!3]//|+1 Buy|////You may set aside a card//from your hand face down.//Put it into your hand at//end of turn.";
EnglishCardTexts[CardNames.SEXTANT] = "[!3]//|+1 Buy|////Look at the top 5 cards of//your deck. Discard any//number. Put the rest//back in any order.";
EnglishCardTexts[CardNames.SHIELD] = "[!3]//|+1 Buy|//---//When another player plays//an Attack, you may first//reveal this from your hand//to be unaffected.";
EnglishCardTexts[CardNames.SPELL_SCROLL] = "Trash this to gain a//cheaper card. If it's//an Action or Treasure,//you may play it.";
EnglishCardTexts[CardNames.STAFF] = "[!3]//|+1 Buy|////You may play an Action//from your hand.";
EnglishCardTexts[CardNames.SWORD] = "[!3]//|+1 Buy|////Each other player discards//down to 4 cards in hand.";
EnglishCardTexts[CardNames.CHEAP] = "Cheap cards cost [1] less.";
EnglishCardTexts[CardNames.CURSED] = "When you gain a Cursed card, gain//a Loot and a Curse.";
EnglishCardTexts[CardNames.FAWNING] = "When you gain a Province,//gain a Fawning card.";
EnglishCardTexts[CardNames.FATED] = "When shuffling, you may look through the cards and reveal//Fated cards to put them on the top or bottom.";
EnglishCardTexts[CardNames.FRIENDLY] = "At the start of your Clean-up//phase, you may discard a Friendly//card to gain a Friendly card.";
EnglishCardTexts[CardNames.HASTY] = "When you gain a Hasty card, set//it aside, and play it at the start//of your next turn.";
EnglishCardTexts[CardNames.INHERITED] = "Setup: You start the game with an Inherited//card in place of a starting card you choose.";
EnglishCardTexts[CardNames.INSPIRING] = "After playing an Inspiring card on your turn,//you may play an Action from your hand that you//don't have a copy of in play.";
EnglishCardTexts[CardNames.NEARBY] = "When you gain a Nearby card, |+1 Buy|.";
EnglishCardTexts[CardNames.PATIENT] = "At the start of your Clean-up phase, you may//set aside Patient cards from your hand, to play them//at the start of your next turn.";
EnglishCardTexts[CardNames.PIOUS] = "When you gain a Pious card, you may//trash a card from your hand.";
EnglishCardTexts[CardNames.RECKLESS] = "Follow the instructions of played Reckless cards twice.//When discarding one from play, return it//to its pile.";
EnglishCardTexts[CardNames.RICH] = "When you gain a Rich card, gain a Silver.";
EnglishCardTexts[CardNames.SHY] = "At the start of your turn, you may//discard one Shy card for |+2 Cards|.";
EnglishCardTexts[CardNames.TIRELESS] = "When you discard a Tireless card from//play, set it aside, and put it//onto your deck at end of turn.";

EnglishCardTexts[CardNames.SHOP] = "|+1 Card|//|+[1]|//You may play an Action card//from your hand that you don't//have a copy of in play.\n";
EnglishCardTexts[CardNames.CARNIVAL] = "Reveal the top 4 cards//of your deck. Put one//of each differently named//card into your hand//and discard the rest.";
EnglishCardTexts[CardNames.FERRYMAN] = "|+2 Cards|//|+1 Action|//Discard a card.//---//Setup: Choose an unused Kingdom//pile costing [3] or [4]. Gain//one when you gain a Ferryman.";
EnglishCardTexts[CardNames.FARMHANDS] = "|+1 Card|//|+2 Actions|//---//When you gain this, you may//set aside an Action or Treasure//from your hand, and play it at//the start of your next turn.";
EnglishCardTexts[CardNames.JOUST] = "|+1 Card|//|+1 Action|//|+[1]|//You may set aside a Province//from your hand to gain any//Reward to your hand. Discard//the Province in Clean-up.";
EnglishCardTexts[CardNames.REWARDS_PILE] = "--";
EnglishCardTexts[CardNames.COURSER] = "Choose two different options://|+2 Cards|; |+2 Actions|;//|+|[2]; gain 4 Silvers.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.RENOWN] = "|+1 Buy|////This turn, cards cost [2] less.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.CORONET] = "You may play a non-Reward//Action from your hand twice.////You may play a non-Reward//Treasure from your hand twice.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.DEMESNE] = "|+2 Actions|//|+2 Buys|//Gain a Gold.//---//Worth {1} per Gold you have.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.HOUSECARL] = "|+1 Card| per differently named//Action card you have in play.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.HUGE_TURNIP] = "|+2 Coffers|//|+|[1] per Coffers you have.////%(This is not in the Supply.)%";
EnglishCardTexts[CardNames.FARRIER] = "|+1 Card|//|+1 Action|//|+1 Buy|//---//Overpay: |+1 Card| at the end of//this turn per [1] overpaid.";
EnglishCardTexts[CardNames.INFIRMARY] = "|+1 Card|//You may trash a card//from your hand.//---//Overpay: Play this once//per [1] overpaid.";
EnglishCardTexts[CardNames.FOOTPAD] = "|+2 Coffers|//Each other player discards//down to 3 cards in hand.//---//In games using this, when//you gain a card in an//Action phase, |+1 Card|.";

EnglishCardTexts[CardNames.FISHMONGER] = "|+1 Buy|//|+[1]|//---//You can play this from your//deck as if in your hand.";
EnglishCardTexts[CardNames.SNAKE_WITCH] = "|+1 Card|//|+1 Action|//If your hand has no duplicate//cards, you may reveal it and//return this to its pile, to have//each other player gain a Curse.";
EnglishCardTexts[CardNames.ARISTOCRAT] = "If the number of Aristocrats//you have in play is:////1 or 5: |+3 Actions|;//2 or 6: |+3 Cards|;//3 or 7: |+|[3];//4 or 8: |+3 Buys|.";
EnglishCardTexts[CardNames.CRAFTSMAN] = "|+[2D]|////Gain a card//costing up to [5].";
EnglishCardTexts[CardNames.RIVERBOAT] = "At the start of your next//turn, play the set aside//card, leaving it there.//---//Setup: Set aside an//unused non-Duration//Action card costing [5].";
EnglishCardTexts[CardNames.ROOT_CELLAR] = "|+3 Cards|//|+1 Action|//|+[3D]|";
EnglishCardTexts[CardNames.ALLEY] = "|+1 Card|//|+1 Action|//Discard a card.//---//You can play this from your//deck as if in your hand.";
EnglishCardTexts[CardNames.CHANGE] = "If you have any [D], |+|[3].////Otherwise, trash a card from//your hand, and gain a card//costing more [] than it. +[D]//equal to the difference in [].";
EnglishCardTexts[CardNames.NINJA] = "|+1 Card|//Each other player discards//down to 3 cards in hand.//---//You can play this from your//deck as if in your hand.";
EnglishCardTexts[CardNames.POET] = "|+1 <>|//|+1 Card|//|+1 Action|//Reveal the top card of your//deck. If it costs [3] or less,//put it into your hand.";
EnglishCardTexts[CardNames.RIVER_SHRINE] = "|+1 <>|////Trash up to 2 cards from your//hand. At the start of Clean-up,//if you didn't gain any cards in//your Buy phase this turn, gain//a card costing up to [4].";
EnglishCardTexts[CardNames.RUSTIC_VILLAGE] = "|+1 <>|//|+1 Card|//|+2 Actions|//You may discard//2 cards for |+1 Card|.";
EnglishCardTexts[CardNames.GOLD_MINE] = "|+1 Card|//|+1 Action|//|+1 Buy|//You may gain a//Gold and get +[4D].";
EnglishCardTexts[CardNames.IMPERIAL_ENVOY] = "|+5 Cards|//|+1 Buy|//|+[2D]|";
EnglishCardTexts[CardNames.KITSUNE] = "|+1 <>|//Choose two different options://|+|[2]; |+2 Actions|; each other//player gains a Curse; gain//a Silver.";
EnglishCardTexts[CardNames.LITTER] = "|+2 Cards|//|+2 Actions|//|+[1D]|";
EnglishCardTexts[CardNames.RICE_BROKER] = "|+1 Action|//Trash a card from your hand.//If it's a Treasure, |+2 Cards|.//If it's an Action, |+5 Cards|.";
EnglishCardTexts[CardNames.RONIN] = "Draw until you have//7 cards in hand.//---//You can play this from your//deck as if in your hand.";
EnglishCardTexts[CardNames.TANUKI] = "Trash a card from your//hand. Gain a card costing//up to [2] more than it.//---//You can play this from your//deck as if in your hand.";
EnglishCardTexts[CardNames.TEA_HOUSE] = "|+1 <>|//|+1 Card|//|+1 Action|//|+[2]|";
EnglishCardTexts[CardNames.SAMURAI] = "Each other player discards//down to 3 cards in hand (once).//At the start of each of//your turns this game, +[1].//%(This stays in play.)%";
EnglishCardTexts[CardNames.RICE] = "|+1 Buy|//|+|[1] per different type among//cards you have in play.";
EnglishCardTexts[CardNames.MOUNTAIN_SHRINE] = "|+1 <>|//+[2]//You may trash a card from//your hand. Then if there//are any Action cards//in the trash, |+2 Cards|.";
EnglishCardTexts[CardNames.DAIMYO] = "|+1 Card|//|+1 Action|//The next time you//play a non-Command//Action card this turn,//replay it afterwards.";
EnglishCardTexts[CardNames.ARTIST] = "|+1 Action|////|+1 Card| per card you//have exactly one//copy of in play.";
EnglishCardTexts[CardNames.APPROACHING_ARMY] = "After you play an Attack card, +[1].//---//Setup: Add an Attack kingdom card pile to the game.";
EnglishCardTexts[CardNames.BIDING_TIME] = "At the start of your Clean-up, set aside your hand face down.//At the start of your next turn, put those cards into your hand.";
EnglishCardTexts[CardNames.BUREAUCRACY] = "When you gain a card that doesn't//cost [0], gain a Copper.";
EnglishCardTexts[CardNames.DIVINE_WIND] = "When you remove the last| |<>, remove all//Kingdom card piles from the Supply, and//set up 10 new random piles.";
EnglishCardTexts[CardNames.ENLIGHTENMENT] = "Treasures are also Actions. When you play a//Treasure in an Action phase, instead of following//its instructions, |+1 Card| and |+1 Action|.";
EnglishCardTexts[CardNames.FLOURISHING_TRADE] = "Cards cost [1] less.//You may use Action plays as Buys.";
EnglishCardTexts[CardNames.GOOD_HARVEST] = "The first time you play each differently//named Treasure each turn, first, |+1 Buy| and +[1].";
EnglishCardTexts[CardNames.GREAT_LEADER] = "After each Action card you play, |+1 Action|.";
EnglishCardTexts[CardNames.GROWTH] = "When you gain a Treasure,//gain a cheaper card.";
EnglishCardTexts[CardNames.HARSH_WINTER] = "When you gain a card on your turn,//if there's [D] on its pile, take it;//otherwise put [2D] on its pile.";
EnglishCardTexts[CardNames.KIND_EMPEROR] = "At the start of your turn, and when you//remove the last| |<>: Gain an Action to your hand.";
EnglishCardTexts[CardNames.PANIC] = "When you play a Treasure, |+2 Buys|, and when you//discard one from play, return it to its pile.";
EnglishCardTexts[CardNames.PROGRESS] = "When you gain a card, put it onto your deck.";
EnglishCardTexts[CardNames.RAPID_EXPANSION] = "When you gain an Action or Treasure, set it//aside, and play it at the start of your next turn.";
EnglishCardTexts[CardNames.SICKNESS] = "At the start of your turn, choose one://Gain a Curse onto your deck; or discard 3 cards.";
EnglishCardTexts[CardNames.AMASS] = "If you have no Action cards in play,//gain an Action card costing up to [5].";
EnglishCardTexts[CardNames.ASCETICISM] = "Pay any amount of [] to trash//that many cards from your hand.";
EnglishCardTexts[CardNames.CREDIT] = "Gain an Action or Treasure costing//up to [8]. +[D] equal to its cost.";
EnglishCardTexts[CardNames.FORESIGHT] = "Reveal cards from your deck until revealing an Action.//Set it aside and discard the rest.//Put it into your hand at end of turn.";
EnglishCardTexts[CardNames.KINTSUGI] = "Trash a card from your hand. If you've gained a Gold this game,//gain a card costing up to [2] more than the trashed card.";
EnglishCardTexts[CardNames.PRACTICE] = "You may play an Action card from your hand twice.";
EnglishCardTexts[CardNames.SEA_TRADE] = "|+1 Card| per Action card you have in play.//Trash up to that many cards from your hand.";
EnglishCardTexts[CardNames.RECEIVE_TRIBUTE] = "If you've gained at least 3 cards this turn,//gain up to 3 differently named Action cards//you don't have copies of in play.";
EnglishCardTexts[CardNames.GATHER] = "Gain a card costing exactly [3],//a card costing exactly [4],//and a card costing exactly [5].";
EnglishCardTexts[CardNames.CONTINUE] = "Once per turn: Gain a non-Attack Action card costing up to [4].//Return to your Action phase and play it. |+1 Action| and |+1 Buy|.";