"use strict";

var DutchLogEntries = {};

DutchLogEntries[LogEntryNames.GAIN] = "Argument0 gains Argument1.";
DutchLogEntries[LogEntryNames.BUY_AND_GAIN] = "Argument0 buys and gains Argument1.";
DutchLogEntries[LogEntryNames.TRASH] = "Argument0 trashes Argument1.";
DutchLogEntries[LogEntryNames.DISCARD] = "Argument0 discards Argument1.";
DutchLogEntries[LogEntryNames.PLAY] = "Argument0 plays Argument1.";
DutchLogEntries[LogEntryNames.PLAY_AGAIN] = "Argument0 plays Argument1 again.";
DutchLogEntries[LogEntryNames.PLAY_THIRD] = "Argument0 plays Argument1 a third time.";
DutchLogEntries[LogEntryNames.TOPDECK] = "Argument0 topdecks Argument1.";
DutchLogEntries[LogEntryNames.DRAW] = "Argument0 draws Argument1.";
DutchLogEntries[LogEntryNames.DRAW_FROM] = "Argument0 draws Argument1. (Argument2)";
DutchLogEntries[LogEntryNames.REVEAL] = "Argument0 reveals Argument1.";
DutchLogEntries[LogEntryNames.REVEAL_SEARCHING] = "Argument0 reveals Argument1.";
DutchLogEntries[LogEntryNames.LOOK_AT] = "Argument0 looks at Argument1.";
DutchLogEntries[LogEntryNames.PUT_IN_HAND] = "Argument0 puts Argument1 into their hand.";
DutchLogEntries[LogEntryNames.SET_ASIDE] = "Argument0 sets Argument1 aside.";
DutchLogEntries[LogEntryNames.PUT_ON_MAT] = "Argument0 puts Argument1 on their Argument2.";
DutchLogEntries[LogEntryNames.CALL] = "Argument0 calls Argument1.";
DutchLogEntries[LogEntryNames.DECK_TO_DISCARD] = "Argument0 moves their deck to the discard.";
DutchLogEntries[LogEntryNames.BACK_ON_DECK] = "Argument0 puts Argument1 back onto their deck.";
DutchLogEntries[LogEntryNames.SHUFFLE_INTO_DECK] = "Argument0 shuffles Argument1 into their deck.";
DutchLogEntries[LogEntryNames.INSERT_IN_DECK] = "Argument0 inserts Argument1 into their deck.";
DutchLogEntries[LogEntryNames.EXCHANGE_RETURN] = "Argument0 returns Argument1.";
DutchLogEntries[LogEntryNames.EXCHANGE_RECEIVE] = "Argument0 receives Argument1.";
DutchLogEntries[LogEntryNames.PASS] = "Argument0 passes Argument1 to Argument2.";
DutchLogEntries[LogEntryNames.STARTS_WITH] = "Argument0 starts with Argument1.";
DutchLogEntries[LogEntryNames.BUY] = "Argument0 buys Argument1.";
DutchLogEntries[LogEntryNames.COUNTER_ADD] = "COUNTER_ADD";
DutchLogEntries[LogEntryNames.COUNTER_RESET] = "COUNTER_RESET";
DutchLogEntries[LogEntryNames.REACTS_WITH] = "Argument0 reacts with Argument1.";
DutchLogEntries[LogEntryNames.SHUFFLES] = "Argument0 shuffles their deck.";
DutchLogEntries[LogEntryNames.NEW_TURN] = "Argument0";
DutchLogEntries[LogEntryNames.WISH_CORRECT] = "Argument0 wishes for Argument1 and finds it.";
DutchLogEntries[LogEntryNames.WISH_WRONG] = "Argument0 wishes for Argument1 but reveals Argument2.";
DutchLogEntries[LogEntryNames.BLACK_MARKET_RESHUFFLE] = "Reshuffling the Black Market deck.";
DutchLogEntries[LogEntryNames.RETURN_TO] = "Argument0 returns Argument1 to Argument2.";
DutchLogEntries[LogEntryNames.PUT_ON_BOTTOM_OF] = "Argument0 puts Argument1 on the bottom of Argument2.";
DutchLogEntries[LogEntryNames.LOSE_TRACK_MOVED] = "Argument0 loses track of Argument1 (it moved).";
DutchLogEntries[LogEntryNames.LOSE_TRACK_HIDDEN] = "Argument0 loses track of Argument1 (it was covered up).";
DutchLogEntries[LogEntryNames.LOSE_TRACK_RESHUFFLED] = "Argument0 loses track of Argument1 (it was shuffled).";
DutchLogEntries[LogEntryNames.LOSE_TRACK_ANONYMOUS] = "Argument0 is lost track of.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_BANDIT_CAMP] = "Argument0.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_TOWER] = "Argument0.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH_ABSENT] = "No differently named Action cards.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH] = "Argument0.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_WALL] = "Argument0.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_WOLF_DEN] = "exactly one of Argument0.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_PALACE] = "Argument0.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_ORCHARD] = "Argument0.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_OBELISK] = "Argument0 cards from the Argument1-pile.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_MUSEUM] = "Argument0 differently named cards.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_KINGS_CASTLE] = "Argument0 Castles.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_KEEP] = "Argument0.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_HUMBLE_CASTLE] = "Argument0 Castles.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_FOUNTAIN] = "Argument0.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_FEODUM] = "Argument0.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_GARDENS] = "Argument0.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_VINEYARD] = "Argument0 Action cards.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_SILK_ROAD] = "Argument0 Victory cards.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_FAIRGROUNDS] = "Argument0 differently named cards.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_DUKE] = "Argument0.";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_DUH] = "";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_VP_TOKENS] = "VP tokens.";
DutchLogEntries[LogEntryNames.PUT_IN_HAND_FROM] = "Argument0 puts Argument1 in hand (Argument2).";
DutchLogEntries[LogEntryNames.STARTS_TURN] = "Argument0 starts their turn.";
DutchLogEntries[LogEntryNames.TAKE_VP_FROM] = "Argument0 takes Argument1 VP from Argument2.";
DutchLogEntries[LogEntryNames.MOVE_VP_FROM_TO] = "Argument0 moves Argument1 VP from Argument2 to Argument3.";
DutchLogEntries[LogEntryNames.OBELISK_CHOOSES_PILE] = "Obelisk chooses Argument0.";
DutchLogEntries[LogEntryNames.PLAYER_MOVES_TOKEN_TO] = "Argument0 moves Argument1 to Argument2.";
DutchLogEntries[LogEntryNames.PLAYER_GETS_VP] = "Argument0 gets Argument1 VP.";
DutchLogEntries[LogEntryNames.PLAYER_GETS_VP_FROM] = "Argument0 gets Argument1 VP from Argument2.";
DutchLogEntries[LogEntryNames.ADDS_VP_TO] = "Argument0 adds Argument1 VP to Argument2.";
DutchLogEntries[LogEntryNames.WAITING_FOR] = "Waiting for Argument0.";
DutchLogEntries[LogEntryNames.ALMS_FAIL] = "Argument0 buys Alms but has Argument1 in play.";
DutchLogEntries[LogEntryNames.BORROW_FAIL] = "Argument0 buys Borrow but already had Argument1";
DutchLogEntries[LogEntryNames.QUEST_FAIL_ATTACK] = "Argument0 failed to discard an Attack.";
DutchLogEntries[LogEntryNames.QUEST_FAIL_CURSES] = "Argument0 failed to discard Argument1.";
DutchLogEntries[LogEntryNames.QUEST_FAIL_DISCARD] = "Argument0 failed to discard 6 cards.";
DutchLogEntries[LogEntryNames.SAVE_FAIL] = "Argument0 had no cards to set aside.";
DutchLogEntries[LogEntryNames.SCOUTING_PARTY_FAIL] = "Argument0 had no cards to discard or topdeck.";
DutchLogEntries[LogEntryNames.BONFIRE_FAIL] = "Argument0 trashes nothing.";
DutchLogEntries[LogEntryNames.MISSION_FAIL] = "Mission fails because Argument0 already owned the previous turn.";
DutchLogEntries[LogEntryNames.MISSION_SUCCESS] = "Argument0 takes an extra turn after this one.";
DutchLogEntries[LogEntryNames.TRADE_FAIL] = "Argument0 trashes nothing.";
DutchLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_DOWN] = "Argument0 flips Argument1 face down.";
DutchLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_UP] = "Argument0 flips Argument1 face up.";
DutchLogEntries[LogEntryNames.TRIUMPH_FAIL] = "Argument0 failed to gain Argument1.";
DutchLogEntries[LogEntryNames.ADVANCE_FAIL] = "Argument0 didn't trash an Action card.";
DutchLogEntries[LogEntryNames.TAX_ADD_DEBT] = "Argument0 adds Argument1 to Argument2.";
DutchLogEntries[LogEntryNames.TAX_TAKE_DEBT] = "Argument0 takes Argument1 from Argument2.";
DutchLogEntries[LogEntryNames.RITUAL_FAIL] = "Argument0 failed to gain Argument1.";
DutchLogEntries[LogEntryNames.WINDFALL_FAIL] = "Argument0 isn't empty.";
DutchLogEntries[LogEntryNames.DOMINATE_FAIL] = "Argument0 failed to gain Argument1.";
DutchLogEntries[LogEntryNames.PLUS_ACTION_FROM] = "Argument0 gets +1 Action (from Argument1).";
DutchLogEntries[LogEntryNames.PLUS_BUY_FROM] = "Argument0 gets +1 Buy (from Argument1).";
DutchLogEntries[LogEntryNames.DRAWS_FROM] = "Argument0 draws Argument1 (from Argument2).";
DutchLogEntries[LogEntryNames.PLUS_COIN_FROM] = "Argument0 gets +1 Coin (from Argument1).";
DutchLogEntries[LogEntryNames.DRAWS_LESS_BECAUSE] = "Argument0 skips a draw (because of Argument1).";
DutchLogEntries[LogEntryNames.PRINCE_FAILS] = "";
DutchLogEntries[LogEntryNames.POSSESSION_TAKES_COIN_TOKENS] = "Argument0 takes the coin tokens instead.";
DutchLogEntries[LogEntryNames.POSSESSION_TAKES_DEBT_TOKENS] = "Argument0 takes the Debt instead.";
DutchLogEntries[LogEntryNames.POSSESION_TAKES_VP_TOKENS] = "Argument0 takes the VP tokens instead.";
DutchLogEntries[LogEntryNames.OUTPOST_FAIL_PREVIOUS_TURN] = "Outpost fails because Argument0 already owned the previous turn.";
DutchLogEntries[LogEntryNames.OUTPOST_FAIL_ALREADY_PLAYED] = "Outpost fails because Argument0 has already played it.";
DutchLogEntries[LogEntryNames.TAKES_DEBT] = "Argument0 takes Argument1 debt.";
DutchLogEntries[LogEntryNames.REPAYS_DEBT] = "Argument0 repays Argument1 debt.";
DutchLogEntries[LogEntryNames.REPAYS_SOME_DEBT] = "Argument0 repays Argument1 debt (Argument2 remaining).";
DutchLogEntries[LogEntryNames.RETURNS_TOKEN] = "Argument0 returns Argument1 set by Argument2.";
DutchLogEntries[LogEntryNames.SET_ASIDE_WITH] = "Argument0 sets Argument1 aside with Argument2.";
DutchLogEntries[LogEntryNames.BLOCKS_WITH] = "Argument0 blocks with Argument1.";
DutchLogEntries[LogEntryNames.NAMES] = "Argument0 names Argument1.";
DutchLogEntries[LogEntryNames.GAIN_ON_DRAWPILE] = "Argument0 gains Argument1 onto their drawpile.";
DutchLogEntries[LogEntryNames.GAIN_FROM_TRASH] = "Argument0 gains Argument1 from trash.";
DutchLogEntries[LogEntryNames.OBELISK_FAILS] = "Obelisk failed to select an Action Supply pile.";
DutchLogEntries[LogEntryNames.INHERITS] = "Argument0 inherits Argument1.";
DutchLogEntries[LogEntryNames.GAME_META_INFO] = "Argument0";
DutchLogEntries[LogEntryNames.THE_SKYS_GIFT_FAIL] = "Argument0 fails to discard for [The Skys Gift]";
DutchLogEntries[LogEntryNames.RECEIVES] = "Argument0 receives Argument1";
DutchLogEntries[LogEntryNames.EXPLAIN_POINTS_PASTURE] = "Argument0.";
DutchLogEntries[LogEntryNames.TAKE] = "Argument0 takes Argument1.";
DutchLogEntries[LogEntryNames.RETURN] = "Argument0 returns Argument1.";
DutchLogEntries[LogEntryNames.THE_SUNS_GIFT_FAIL] = "[The Suns Gift] has nothing to discard.";
DutchLogEntries[LogEntryNames.DRUID_SELECTS_BOONS] = "[Druid] sets Argument1 aside.";
DutchLogEntries[LogEntryNames.DISCARD_RECEIVABLE] = "Argument0 discards Argument1.";
DutchLogEntries[LogEntryNames.PLAY_ENCHANTED] = "Argument0 is enchanted by Argument1";
DutchLogEntries[LogEntryNames.PUT_IN_HAND_FROM_CHURCH] = "Argument0 puts Argument1 in hand ([Church]).";