"use strict";

webclient.component("scoreTable", {
    controller: ['tableService', 'kingdomService', '$scope', 'metaBroadcaster', function (tableService, kingdomService, $scope, metaBroadcaster) {
        var self = this;
        self.showSelector = false;
        self.toggleSelector = function () {
            self.showSelector = !self.showSelector;
            metaBroadcaster.send(Events.REDRAW);
            if (self.showSelector) {
                setTimeout(function () {
                    return window.document.getElementsByClassName("card-selector-input")[0].focus();
                }, 0);
            }
        };
        self.hideSelector = function () {
            self.showSelector = false;
        };
        self.shouldShowAdvancedRules = !kingdomService.kingdomIsDefault();
    }],
    template: "\n            <kingdom-rules\n              toggle-selector=\"$ctrl.toggleSelector\"\n              showing-selector=\"$ctrl.showSelector\"\n              should-show-advanced-rules=\"$ctrl.shouldShowAdvancedRules\">\n            </kingdom-rules>\n            <table-card-selector ng-show=\"$ctrl.showSelector\"></table-card-selector>\n            <game-rules></game-rules>\n            <div class=\"fullscreen\" ng-if=\"$ctrl.showSelector\" ng-click=\"$ctrl.hideSelector()\"></div>\n            "
});