"use strict";

webclient.component("timeoutRequest", {
    transclude: true,
    controller: ['game', '$scope', function (game, $scope) {
        var self = this;

        $scope.$on(Events.REDRAW, function () {
            safeDigest($scope);
        });
        $scope.$on(Events.GAME_MODEL_CREATED, function () {
            safeDigest($scope);
        });

        self.shouldShow = function () {
            return game.metaGameModel.timeoutOffer !== null && !isUndefined(game.playerModel.playerMe) && game.metaGameModel.timeoutOffer.playerIndex !== game.playerModel.playerMe.index;
        };
        self.click = function () {
            return game.metaGameModel.requestTimeout();
        };

        self.getWindowText = function () {
            var timeoutIndex = game.metaGameModel.timeoutOffer === null ? -1 : game.metaGameModel.timeoutOffer.playerIndex;
            var playerName = game.state.players[timeoutIndex].name;
            return getPhrase(Phrases.MAKE_RESIGN).replace('USERNAME', playerName);
        };
        self.getButtonText = function () {
            return getPhrase(Phrases.YES);
        };
    }],
    template: "\n            <modal-window ng-if=\"$ctrl.shouldShow()\">\n                <div class=\"timeout\">{{::$ctrl.getWindowText()}}</div>\n                <button class=\"lobby-button\" ng-click=\"$ctrl.click()\">{{::$ctrl.getButtonText()}}</button>\n            </modal-window>\n        "
});