"use strict";

var DutchErrors = {};

DutchErrors[FailureReasons.WRONG_PASSWORD] = "Wrong password.";
DutchErrors[FailureReasons.NOT_ENOUGH_PLAYERS] = "Not enough players.";
DutchErrors[FailureReasons.TOO_MANY_PLAYERS] = "Too many players.";
DutchErrors[FailureReasons.NEW_HOST_NOT_FOUND] = "Can't find the new host.";
DutchErrors[FailureReasons.ALREADY_JOINED_A_TABLE] = "You've already joined a table.";
DutchErrors[FailureReasons.NOT_ALLOWED_TO_JOIN] = "You are not allowed to join.";
DutchErrors[FailureReasons.BAD_CLIENT_VERSION] = "Your client is too old; please ctrl/cmd-reload the page to get a new one.";
DutchErrors[FailureReasons.UNKNOWN_RULE_INDEX] = "Unknown rule index.";
DutchErrors[FailureReasons.RULE_NOT_REMOVABLE] = "This rule is not removable.";
DutchErrors[FailureReasons.INVITEE_NOT_LOGGED_IN] = "Invitee is not logged in.";
DutchErrors[FailureReasons.NOT_ALLOWED_TO_PLAY] = "You are not allowed to play.";
DutchErrors[FailureReasons.TABLE_CHAT_REQUIRES_TABLE] = "Table chat requires a table.";
DutchErrors[FailureReasons.SPECTATOR_CHAT_REQUIRES_TABLE] = "Spectator chat requires a table.";
DutchErrors[FailureReasons.LOGIN_REQUIRED] = "You need to be logged in.";
DutchErrors[FailureReasons.COMMAND_REQUIRES_TABLE] = "This command requires a table.";
DutchErrors[FailureReasons.COMMAND_REQUIRES_HOST] = "This command requires host privileges.";
DutchErrors[FailureReasons.RULE_CONFLICTS] = "Conflicting rules.";
DutchErrors[FailureReasons.NO_GAMESERVER_AVAILABLE] = "No gameserver available.";
DutchErrors[FailureReasons.SELECTED_CARD_NOT_OWNED] = "You don't own the selected card.";
DutchErrors[FailureReasons.SELECTED_CARD_NOT_KINGDOM_CARD] = "The selected card is not a kingdom card.";
DutchErrors[FailureReasons.SELECTED_TOO_MANY_EVENTS_OR_LANDMARKS] = "Too many Events or Landmarks selected.";
DutchErrors[FailureReasons.REQUIRING_MORE_CARDS_THAN_AVAILABLE] = "More cards required than available.";
DutchErrors[FailureReasons.THIS_IS_NOT_YOUR_TABLE] = "This is not your table.";
DutchErrors[FailureReasons.ALREADY_LOGGED_IN] = "Already logged in.";
DutchErrors[FailureReasons.CANNOT_RECONNECT_WHEN_STILL_CONNECTED] = "Cannot reconnect when still connected.";
DutchErrors[FailureReasons.KICK_FAILED] = "Kick failed.";
DutchErrors[FailureReasons.ALREADY_STARTING] = "Already starting.";
DutchErrors[FailureReasons.ALREADY_STARTED] = "Already started.";
DutchErrors[FailureReasons.NOTHING_TO_KICK] = "Nothing to kick.";
DutchErrors[FailureReasons.INTERNAL_ERROR] = "Internal error.";
DutchErrors[FailureReasons.ILLEGAL_PROPERTY_CHANGE] = "Illegal property change";
DutchErrors[FailureReasons.CODE_ALREADY_USED] = "Code already used";
DutchErrors[FailureReasons.FAILED_CONFIRMING_EMAIL] = "Failed to confirm email";
DutchErrors[FailureReasons.FAILED_RETRIEVING_USER] = "Failed to retrieve user";
DutchErrors[FailureReasons.EMAIL_NOT_CONFIRMED_YET] = "You can't host a game before confirming your email.";
DutchErrors[FailureReasons.FAILURE_SENDING_EMAIL] = "Confirmation email could not be sent";
DutchErrors[FailureReasons.CONFIRM_EMAIL_FIRST] = "Sorry, we can't send you a password without a confirmed email address.";
DutchErrors[FailureReasons.OLD_PASSWORD_INCORRECT] = "Old password incorrect";
DutchErrors[FailureReasons.INCORRECT_PRICE] = "Price incorrect";
DutchErrors[FailureReasons.PURCHASE_IN_FUTURE] = "Purchase seems to come from the future";
DutchErrors[FailureReasons.PURCHASE_TOO_OLD] = "Purchase too old; please try again";
DutchErrors[FailureReasons.FAILED_SETTING_UP_PURCHASE] = "Failed setting up purchase";
DutchErrors[FailureReasons.FAILED_STORING_PURCHASE] = "Failed storing purchase";
DutchErrors[FailureReasons.DUPLICATE_USERNAME] = "This username already exists";
DutchErrors[FailureReasons.INVALID_USERNAME] = "Invalid username";
DutchErrors[FailureReasons.INVALID_PASSWORD] = "Invalid password";
DutchErrors[FailureReasons.INVALID_GENDER] = "Invalid gender";
DutchErrors[FailureReasons.PURCHASE_TOO_SHORT] = "Minimum subscription duration is 1 month";
DutchErrors[FailureReasons.PURCHASE_TOO_LONG] = "Currently we don't sell subscriptions beyond 2019";
DutchErrors[FailureReasons.METASERVER_INACTIVE] = "A new server version has been released; please ctrl/cmd-reload the page to connect to it.";
DutchErrors[FailureReasons.NO_SUCH_TABLE] = "No such table.";
DutchErrors[FailureReasons.NO_SUCH_USER] = "No such user.";
DutchErrors[FailureReasons.NO_SUCH_CODE] = "No such code.";
DutchErrors[FailureReasons.RESERVED_USERNAME] = "This username is reserved.";
DutchErrors[FailureReasons.PRICE_IS_ZERO] = "You can't make a purchase with zero cost.";
DutchErrors[FailureReasons.NO_SUCH_USERNAME] = "Username does not exist.";
DutchErrors[FailureReasons.NO_SUCH_GAMELOG] = "The requested game cannot be found.";
DutchErrors[FailureReasons.ILLEGAL_PLAYER_ORDER] = "Illegal player order.";
DutchErrors[FailureReasons.INVALID_SESSION_ID] = "Invalid Session ID.";
DutchErrors[FailureReasons.ACTION_CODE_EXPIRED] = "Action code expired.";
DutchErrors[FailureReasons.BOT_GAMES_CANT_BE_RATED] = "Games with bots can't be rated.";
DutchErrors[FailureReasons.TOO_MANY_PLAYERS_FOR_RATED_GAME] = "Games with >4 players can't be rated.";
DutchErrors[FailureReasons.ALREADY_ON_BLACKLIST] = "Already on blacklist.";
DutchErrors[FailureReasons.NOT_ON_BLACKLIST] = "Not on blacklist.";
DutchErrors[FailureReasons.ALREADY_ON_FRIENDLIST] = "Already on friendlist.";
DutchErrors[FailureReasons.NOT_ON_FRIENDLIST] = "Not on friendlist.";
DutchErrors[FailureReasons.SEARCHING_FOR_MYSELF] = "Can't add yourself.";
DutchErrors[FailureReasons.SEARCHED_FOR_BOT] = "Can't add a bot.";
DutchErrors[FailureReasons.USER_IS_BANNED] = "You have been banned from this site.";
DutchErrors[FailureReasons.WRONG_BONUS_CODE_TYPE] = "WRONG_BONUS_CODE_TYPE.";
DutchErrors[FailureReasons.ILLEGAL_BONUS_CODE] = "ILLEGAL_BONUS_CODE.";
DutchErrors[FailureReasons.GAME_ID_SHOULD_BE_NUMBER] = "Game Id should be a number.";

var DutchSuccessDescriptions = {};

DutchSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_FORGOT_PASSWORD] = "We've sent you an email to reset your password.";
DutchSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_CONFIRM_EMAIL] = "We've sent you an email to confirm your address.";
DutchSuccessDescriptions[SuccessDescriptions.PASSWORD_CHANGED] = "Password changed";
DutchSuccessDescriptions[SuccessDescriptions.KICK_SUCCESS] = "Kick successful";
DutchSuccessDescriptions[SuccessDescriptions.PURCHASE_SUCCESS] = "Purchase successful";
DutchSuccessDescriptions[SuccessDescriptions.EMAIL_CONFIRMED] = "Email confirmed";
DutchSuccessDescriptions[SuccessDescriptions.REFUND_SUCCESS] = "Purchase refunded";
DutchSuccessDescriptions[SuccessDescriptions.ACTION_CODE_USED] = "Successfully applied action code.";
DutchSuccessDescriptions[SuccessDescriptions.SUBSCRIPTION_UPDATED] = "Your subscription has been updated.";

var DutchTableRules = {};

DutchTableRules[TableRuleIds.PLAYERS_CAN_SEE_SPECTATOR_CHAT] = "Players can see spectator chat";
DutchTableRules[TableRuleIds.BANNED_CARDS] = "Banned cards";
DutchTableRules[TableRuleIds.REQUIRED_CARDS] = "Required cards";
DutchTableRules[TableRuleIds.MAX_EVENTS_OR_LANDMARKS] = "Max Events/Landmarks";
DutchTableRules[TableRuleIds.MIN_EVENTS_OR_LANDMARKS] = "Min Events/Landmarks";
DutchTableRules[TableRuleIds.USED_EXPANSIONS] = "Used expansions";
DutchTableRules[TableRuleIds.RESTRICTIVE_PLAYER_RULES] = "Only specified users can play";
DutchTableRules[TableRuleIds.SPECTATE_RULES] = "Allow spectators";
DutchTableRules[TableRuleIds.MIN_RATING] = "Min relative rating";
DutchTableRules[TableRuleIds.MAX_RATING] = "Max relative rating";
DutchTableRules[TableRuleIds.RANDOM_SEED] = "Specify random seed";
DutchTableRules[TableRuleIds.MIN_PLAYERS] = "Min players";
DutchTableRules[TableRuleIds.MAX_PLAYERS] = "Max players";
DutchTableRules[TableRuleIds.SPECIAL_KINGDOM_RULES] = "Special kingdom rules";
DutchTableRules[TableRuleIds.RESPECTED_CARD_LISTS] = "Respect liked/disliked/banned cards";
DutchTableRules[TableRuleIds.SHOW_VP_COUNTER] = "Show VP counter";
DutchTableRules[TableRuleIds.UNDO_SETTINGS] = "Undo settings";
DutchTableRules[TableRuleIds.ALWAYS_ALLOWED_PLAYER_RULES] = "ALWAYS_ALLOWED_PLAYER_RULES";
DutchTableRules[TableRuleIds.PLAYER_ORDER] = "Player order";
DutchTableRules[TableRuleIds.REPLAY_INSTRUCTIONS] = "Replay instructions";
DutchTableRules[TableRuleIds.RATED_GAME] = "Rated game";
DutchTableRules[TableRuleIds.PREVIEW_GAME] = "Nocturne Preview";

var DutchActivities = {};

DutchActivities[ActivityStati.LOGGED_IN] = "Logged in";
DutchActivities[ActivityStati.PLAYING] = "Playing";
DutchActivities[ActivityStati.SPECTATING] = "Spectating";
DutchActivities[ActivityStati.AT_TABLE_PLAYER] = "At table (player)";
DutchActivities[ActivityStati.AT_TABLE_SPECTATOR] = "At table (spec)";
DutchActivities[ActivityStati.DISCONNECTED] = "Disconnected";

var DutchTableStati = {};

DutchTableStati[TableStati.NEW] = "New";
DutchTableStati[TableStati.POST_GAME] = "Post-game";
DutchTableStati[TableStati.RUNNING] = "Running";
DutchTableStati[TableStati.ABANDONED] = "Abandoned";

var DutchLobbyButtons = {
    DONE: "Done",
    RESET: "Reset",
    CLEAR_SELECTION: "Clear Selection",
    NEW_TABLE: "New Table",
    START_GAME: "Start Game",
    LEAVE_TABLE: "Leave Table",
    SELECT_KINGDOM: "Select Kingdom Cards",
    SELECT_COLONIES: "Colonies: ",
    SELECT_SHELTERS: "Shelters: "
};

var DutchLobbyTexts = {
    AUTOCOMPLETE_FAILED: "No cards matching \"CARDS\" were found.",
    ADD_CARDS_AUTOCOMPLETE: "Enter cards to include in your kingdom",
    BAN_CARDS_AUTOCOMPLETE: "Enter cards to ban from your kingdom"
};

var DutchTernaryFieldTexts = {};

DutchTernaryFieldTexts[TernaryField.YES] = "Yes";
DutchTernaryFieldTexts[TernaryField.NO] = "No";
DutchTernaryFieldTexts[TernaryField.RANDOM] = "Random";

var DutchTabs = {};

DutchTabs[LobbyTabs.INBOX] = "Inbox";
DutchTabs[LobbyTabs.MATCH] = "Matching";
DutchTabs[LobbyTabs.FRIEND_LIST] = "Friend List";
DutchTabs[LobbyTabs.TABLES] = "Tables";
DutchTabs[LobbyTabs.NEW_TABLE] = "New Table";
DutchTabs[LobbyTabs.MY_TABLE] = "My Table";
DutchTabs[LobbyTabs.ACCOUNT] = "Account";
DutchTabs[LobbyTabs.STORE] = "Store";
DutchTabs[LobbyTabs.OPTIONS] = "Options";
DutchTabs[LobbyTabs.FAMILIAR_CARDS] = "Familiar Cards";
DutchTabs[LobbyTabs.LEADERBOARD] = "Leaderboard";
DutchTabs[LobbyTabs.CARDLISTS] = "Cardlists";

var DutchMatchTypes = {};

DutchMatchTypes[AutomatchTypes.QUICK_MATCH] = "Any Opponent";
DutchMatchTypes[AutomatchTypes.GOOD_MATCH] = "Equal Opponent";
DutchMatchTypes[AutomatchTypes.MATCH_WITH_EXPANSIONS] = "Match with Expansions";
DutchMatchTypes[AutomatchTypes.MATCH_WITH_BOT] = "Computer Opponent";
DutchMatchTypes[AutomatchTypes.MATCH_UNRATED] = "Human Opponent";

var DutchUndoSettings = {};

DutchUndoSettings[UndoSettings.NEVER] = "Never";
DutchUndoSettings[UndoSettings.ASK] = "Ask";
DutchUndoSettings[UndoSettings.ALWAYS] = "Always";

var DutchGroupIds = {};

DutchGroupIds[GroupIds.EVERYBODY] = "Everybody";
DutchGroupIds[GroupIds.NOBODY] = "Nobody";
DutchGroupIds[GroupIds.FRIENDS_OF] = "Friends of";
DutchGroupIds[GroupIds.LIST_PLAYER_IDS] = "Selected players:";

var DutchCountries = {};

DutchCountries[Countries.NON_EU] = "Non-EU";
DutchCountries[Countries.AT] = "Austria";
DutchCountries[Countries.BE] = "Belgium";
DutchCountries[Countries.BG] = "Bulgaria";
DutchCountries[Countries.HR] = "Croatia";
DutchCountries[Countries.CY] = "Cyprus";
DutchCountries[Countries.CZ] = "Czech Republic";
DutchCountries[Countries.DK] = "Denmark";
DutchCountries[Countries.EE] = "Estonia";
DutchCountries[Countries.FI] = "Finland";
DutchCountries[Countries.FR] = "France";
DutchCountries[Countries.DE] = "Germany";
DutchCountries[Countries.GR] = "Greece";
DutchCountries[Countries.HU] = "Hungary";
DutchCountries[Countries.IE] = "Ireland";
DutchCountries[Countries.IT] = "Italy";
DutchCountries[Countries.LV] = "Latvia";
DutchCountries[Countries.LT] = "Lithuania";
DutchCountries[Countries.LU] = "Luxembourg";
DutchCountries[Countries.MT] = "Malta";
DutchCountries[Countries.NL] = "Netherlands";
DutchCountries[Countries.PL] = "Poland";
DutchCountries[Countries.PT] = "Portugal";
DutchCountries[Countries.RO] = "Romania";
DutchCountries[Countries.SK] = "Slovakia";
DutchCountries[Countries.SI] = "Slovenia";
DutchCountries[Countries.ES] = "Spain";
DutchCountries[Countries.SE] = "Sweden";
DutchCountries[Countries.GB] = "United Kingdom";