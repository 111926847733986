"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Expansion = function () {
    function Expansion(name, fileName, visible, displayOrder) {
        _classCallCheck(this, Expansion);

        this.name = name;
        this.fileName = fileName;
        this.visible = visible;
        this.boxArtPath = 'images/box_art/' + stringDowncase(name.replace(/\s/g, '')) + '.jpg';
        this.displayOrder = displayOrder;
    }

    _createClass(Expansion, [{
        key: 'serialize',
        value: function serialize(writer) {
            writer.writeInt(getOrdinal(Expansions, this));
        }
    }, {
        key: 'toString',
        value: function toString() {
            return this.name;
        }
    }, {
        key: 'isNew',
        value: function isNew() {
            var _this = this;

            return NEW_EXPANSIONS.some(function (newExpansion) {
                return _this.name === newExpansion.name;
            });
        }
    }, {
        key: 'isForFree',
        value: function isForFree() {
            var _this2 = this;

            return FREE_EXPANSIONS.some(function (newExpansion) {
                return _this2.name === newExpansion.name;
            });
        }
    }, {
        key: 'translation',
        value: function translation() {
            return typeof LANGUAGE === 'undefined' ? this.name : LANGUAGE.getExpansionName[this];
        }
    }, {
        key: 'getExpansionSymbolURL',
        value: function getExpansionSymbolURL(isWhite) {
            return "images/elements/" + this.fileName + ('-small' + (isWhite ? '-white' : '') + '.png');
        }
    }], [{
        key: 'parse',
        value: function parse(reader) {
            return getByOrdinal(Expansions, reader.readInt());
        }
    }, {
        key: 'silverExpansions',
        value: function silverExpansions() {
            return SILVER_EXPANSIONS;
        }
    }, {
        key: 'goldExpansions',
        value: function goldExpansions() {
            return GOLD_EXPANSIONS;
        }
    }, {
        key: 'coreLevels',
        value: function coreLevels() {
            return [2, 3, 4, 5];
        }
    }, {
        key: 'completeLevels',
        value: function completeLevels() {
            return [2, 3, 4, 5, 6, 7, 8, 9, 10];
        }
    }]);

    return Expansion;
}();

var Expansions = {
    PROMOS: new Expansion("Promos", "promos", true, 99),
    BASE: new Expansion("Base", "base", true, 1),
    INTRIGUE: new Expansion("Intrigue", "intrigue", true, 2),
    SEASIDE: new Expansion("Seaside", "seaside", true, 3),
    ALCHEMY: new Expansion("Alchemy", "alchemy", true, 4),
    PROSPERITY: new Expansion("Prosperity", "prosperity", true, 5),
    CORNUCOPIA_GUILDS: new Expansion("Cornucopia & Guilds", "cornucopia-guilds", true, 6),
    HINTERLANDS: new Expansion("Hinterlands", "hinterlands", true, 7),
    DARK_AGES: new Expansion("Dark Ages", "dark-ages", true, 8),
    DEPRECATED_GUILDS: new Expansion("Old Guilds", null, false, 9),
    ADVENTURES: new Expansion("Adventures", "adventures", true, 10),
    EMPIRES: new Expansion("Empires", "empires", true, 11),
    NOCTURNE: new Expansion("Nocturne", "nocturne", true, 12),
    RENAISSANCE: new Expansion("Renaissance", "renaissance", true, 13),
    MENAGERIE: new Expansion("Menagerie", "menagerie", true, 14),
    DEPRECATED: new Expansion("Deprecated", "deprecated", true, 100),
    ALLIES: new Expansion("Allies", "allies", true, 15),
    PLUNDER: new Expansion("Plunder", "plunder", true, 16),
    RISING_SUN: new Expansion("Rising Sun", "rising-sun", true, 17)
};

var DISABLED_EXPANSIONS = [Expansions.DEPRECATED_GUILDS];

var ALL_EXPANSIONS = [Expansions.INTRIGUE, Expansions.SEASIDE, Expansions.ALCHEMY, Expansions.PROSPERITY, Expansions.CORNUCOPIA_GUILDS, Expansions.HINTERLANDS, Expansions.DARK_AGES, Expansions.ADVENTURES, Expansions.EMPIRES, Expansions.NOCTURNE, Expansions.RENAISSANCE, Expansions.MENAGERIE, Expansions.ALLIES, Expansions.DEPRECATED];
var HALF_EXPANSIONS = [Expansions.INTRIGUE, Expansions.SEASIDE, Expansions.PROSPERITY, Expansions.CORNUCOPIA_GUILDS, Expansions.HINTERLANDS];
var NEW_EXPANSIONS = [];
var FREE_EXPANSIONS = [Expansions.PROMOS, Expansions.BASE, Expansions.DEPRECATED];
var SILVER_EXPANSIONS = HALF_EXPANSIONS;
var GOLD_EXPANSIONS = ALL_EXPANSIONS.filter(function (expansion) {
    return SILVER_EXPANSIONS.indexOf(expansion) === -1 && expansion !== Expansions.DEPRECATED;
});

function StorePackage(levels) {
    var self = this;
    self.levels = levels;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var StorePackages = {
    HALF: new StorePackage(undefined),
    ALL: new StorePackage(undefined),
    CORE: new StorePackage(Expansion.coreLevels()),
    COMPLETE: new StorePackage(Expansion.completeLevels())
};