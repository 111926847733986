"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var publicDecisionAnswer = void 0;

function ServerState(value, description) {
    var self = this;
    self.value = value;
    self.description = description;
    self.uuid = generateUUID();
    self.toString = function () {
        return description;
    };
}

var ServerStates = {
    UP_ASKING_RECONNECT: new ServerState(4, 'up asking reconnect'),
    UP_NORMAL: new ServerState(3, 'up normal'),
    UP_NO_NEW_GAMES: new ServerState(2, 'up no new games'),
    BAD_CLIENT_VERSION: new ServerState(1, 'bad client version'),
    DOWN: new ServerState(0, 'down')
};

var TestConnectionHandler = function () {
    function TestConnectionHandler(connectionInfo, connection) {
        var _this = this;

        _classCallCheck(this, TestConnectionHandler);

        this.connectionInfo = connectionInfo;
        this.connection = connection;
        this.timerId = setTimeout(function () {
            return _this.processResult(ServerStates.DOWN);
        }, 5000);
    }

    _createClass(TestConnectionHandler, [{
        key: 'process',
        value: function process(reader) {
            clearTimeout(this.timerId);
            var ordinal = reader.readInt();
            this.processResult(getByOrdinal(ServerStates, ordinal));
        }
    }, {
        key: 'processResult',
        value: function processResult(serverState) {
            this.connectionInfo.serverState = serverState;
            this.connection.disconnect();
        }
    }, {
        key: 'onError',
        value: function onError() {
            clearTimeout(this.timerId);
        }
    }]);

    return TestConnectionHandler;
}();

webclient.service('connection', ['$rootScope', 'meta', 'game', 'serverConnection', 'serverMessenger', 'sessionService', 'serverToClientProcessor', 'metaBroadcaster', function ($rootScope, meta, game, serverConnection, serverMessenger, sessionService, serverToClientProcessor, metaBroadcaster) {
    var self = this;
    var scope = $rootScope.$new(true);
    self.joinInfo = null;
    self.hasKicked = false;
    var _hasLoadedGame = false;

    self.initialize = function () {
        if (!isDefined(USED_ALPHA_CONNECTION) && !isDefined(USED_BETA_CONNECTION)) {
            console.log("No connection options");
        } else if (!isDefined(USED_BETA_CONNECTION)) {
            console.log("Will use ALPHA because BETA is undefined");
            self.open(USED_ALPHA_CONNECTION);
        } else if (!isDefined(USED_ALPHA_CONNECTION)) {
            console.log("Will use BETA because ALPHA is undefined");
            self.open(USED_BETA_CONNECTION);
        } else {
            USED_ALPHA_CONNECTION.serverState = ServerStates.DOWN;
            USED_BETA_CONNECTION.serverState = ServerStates.DOWN;
            var session = sessionService.getStoredSession();
            var doneTesting = function doneTesting() {
                return self.connectionsTested();
            };
            var betaTest = function betaTest() {
                return self.testConnection('testing beta', USED_BETA_CONNECTION, session, new TestConnectionHandler(USED_BETA_CONNECTION, serverConnection), doneTesting);
            };
            var alphaTest = function alphaTest() {
                return self.testConnection('testing alpha', USED_ALPHA_CONNECTION, session, new TestConnectionHandler(USED_ALPHA_CONNECTION, serverConnection), betaTest);
            };
            alphaTest();
        }
    };

    self.connectionsTested = function () {
        console.log("Server on (%o:%o) reports status %o", USED_ALPHA_CONNECTION.host, USED_ALPHA_CONNECTION.port, USED_ALPHA_CONNECTION.serverState.description);
        console.log("Server on (%o:%o) reports status %o", USED_BETA_CONNECTION.host, USED_BETA_CONNECTION.port, USED_BETA_CONNECTION.serverState.description);
        var usedConnection = USED_ALPHA_CONNECTION.serverState.value >= USED_BETA_CONNECTION.serverState.value ? USED_ALPHA_CONNECTION : USED_BETA_CONNECTION;
        console.log("Will use connection %o:%o", usedConnection.host, usedConnection.port);
        self.open(usedConnection);
    };

    self.testConnection = function (description, connection, session, testConnectionHandler, onClose) {
        return serverConnection.connect(description, connection, false, function () {
            var writer = new Writer();
            writer.writeInt(getOrdinal(ClientToServerIds, ClientToServerIds.REQUEST_SERVER_STATE));
            writer.writeString(VERSION);
            writer.writeOptionalInt(session.playerId);
            writer.writeOptionalString(session.sessionId);
            serverConnection.send(writer.buffer);
        }, function () {
            return onClose();
        }, function () {
            return testConnectionHandler.onError();
        }, testConnectionHandler);
    };

    self.open = function (connection) {
        return serverConnection.connect('best connection', connection, true, function () {
            timeLog("Server connection established.");
            metaBroadcaster.send(Events.SERVER_CONNECTION_OPENED);
        }, function () {
            timeLog("Server connection closed");
            metaBroadcaster.send(Events.SERVER_CONNECTION_CLOSED);
            metaBroadcaster.send(Events.RETURN_TO_LOGIN);
        }, function (event) {
            timeLog("Server connection error", event);
            metaBroadcaster.send(Events.SERVER_CONNECTION_ERROR, event);
        }, serverToClientProcessor);
    };

    self.close = function () {
        return serverConnection.disconnect();
    };
    self.serverIsAlive = function () {
        return serverConnection.isAlive();
    };
    self.serverIsConnecting = function () {
        return serverConnection.isConnecting();
    };
    self.wasTimedOut = false;

    self.isConnected = function () {
        return self.serverIsAlive() && !self.serverIsConnecting();
    };
    self.isConnecting = function () {
        return self.serverIsAlive() && self.serverIsConnecting();
    };
    self.isAlive = function () {
        return self.serverIsAlive();
    };

    self.gameLoaded = function () {
        _hasLoadedGame = true;
        $rootScope.safeApply();
    };

    self.hasLoadedGame = function () {
        return _hasLoadedGame;
    };

    self.removeSessions = function (username, password, shouldRemoveGames) {
        serverMessenger.removeSessions(username, password, shouldRemoveGames);
    };

    self.login = function (username, password, shouldKick) {
        meta.setUsername(username);
        serverMessenger.login(username, password, shouldKick);
    };

    self.loginWithNewPassword = function (code, password) {
        serverMessenger.loginWithNewPassword(code, password);
    };

    self.loginWithCode = function (code) {
        serverMessenger.loginWithCode(code);
    };

    self.forgotPassword = function (username) {
        serverMessenger.forgotPassword(username);
    };

    self.changePassword = function (oldPassword, newPassword) {
        serverMessenger.changePassword(oldPassword, newPassword);
    };

    self.validateBonusCode = function (playerId, code) {
        serverMessenger.validateBonusCode(playerId, code);
    };

    self.acquireBonusExpansion = function (playerId, code, expansion) {
        console.log("Asking server to aqcuire bonus expansion %o %o %o", expansion, playerId, code);
        serverMessenger.useBonusCode(playerId, code, expansion);
    };

    self.undoRequest = function (event, index) {
        serverMessenger.undoRequest(index);
    };

    publicDecisionAnswer = self.undoRequest;

    scope.$on(Events.SERVER_CONNECTION_OPENED, function () {
        self.wasTimedOut = false;
    });

    scope.$on(Events.SERVER_RECONNECTED, function () {
        if (sessionService.hasStoredSession()) {
            timeLog("Logging in after forced reconnect.");
            sessionService.reconnect();
        } else {
            timeLog("Can't autologin - no session known.");
        }
    });

    $rootScope.$on(Events.INSTRUCT_TO_RECONNECT, function (connectToOtherServer) {
        serverConnection.reconnect(connectToOtherServer);
    });

    $rootScope.$on(Events.INSTRUCT_TO_TIME_OUT, function (e) {
        self.wasTimedOut = true;
        serverConnection.disconnect();
    });

    scope.$on(Events.CLEANUP_GAME, function () {
        return _hasLoadedGame = false;
    });
}]);