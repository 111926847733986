"use strict";

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var JapaneseEnvironment = new function () {
    var _this = this;

    this.enter = function () {
        document.getElementsByTagName('body').item(0).appendChild(this.langcss);
    };
    this.leave = function () {
        document.getElementsByTagName('body').item(0).removeChild(this.langcss);
    };
    this.langcss = document.createElement('link');
    this.langcss.rel = 'stylesheet';
    this.langcss.type = 'text/css';
    this.langcss.href = "css/game/language/japanese.css";
    this.emphasizer = "normal";

    this.titleFont = "'07LogoTypeGothic'";
    this.bodyFont = 'CineCaption';
    this.boldFont = "'07LogoTypeGothic'";

    this.strlen = function (str) {
        return str.length + JapaneseEnvironment.countfullwidthchar(str);
    };
    this.countfullwidthchar = function (str) {
        var full = 0,
            i = str.length,
            c;
        for (; i--;) {
            c = str.charCodeAt(i);
            if (c > 0x80 && (c < 0xff61 || c > 0xff9f) && (c < 0xf8f0 || c > 0xf8f3)) full++;
        }
        return full;
    };

    this.getPileName = function (pile, direction) {
        var str = JAPANESE.getCardName[pile.pileName].singular;
        if (pile.pileName !== CardNames.BLACK_MARKET_PILE) {
            str += "の山";
        }
        return colorTextByCardname(str, pile.pileName);
    };

    this.phraseCollection_Or = function (phrases) {
        return phrases.join("/");
    };

    this.abilityEventParser = function (abilityEvent, game, line) {
        var replacement = "";
        var playerIndex = abilityEvent.logEntryArguments[0].argument;
        if (playerIndex != game.model.hero.index) {
            var _playerParser = playerParser(playerIndex, game, line);

            var _playerParser2 = _slicedToArray(_playerParser, 2);

            replacement = _playerParser2[0];
            line = _playerParser2[1];

            replacement += "が";
        }

        if (abilityEvent.logEntryArguments.length > 1) {
            var cardString = "";

            var _cardNameParser = this.cardNameParser(abilityEvent.logEntryArguments[1].argument, game, line);

            var _cardNameParser2 = _slicedToArray(_cardNameParser, 2);

            cardString = _cardNameParser2[0];
            line = _cardNameParser2[1];

            replacement += cardString + "を";
        }

        var abilityName = abilityEvent.description.name;
        if (abilityName instanceof MovementType) {
            replacement += JAPANESE.getMovementType[abilityName].secondPerson;
        } else if (abilityName instanceof AbilityName) {
            replacement += JAPANESE.getAbilityDescription[abilityName];
        }

        if (abilityEvent.type === EventTypes.WHEN_WOULD) {
            replacement = replacement.slice(0, -2);
            replacement += JAPANESE.getPhrase[Phrases.WOULD];
        }
        replacement += "時に...";

        var removetext = /\x7F[^\x7F]/g;
        while (removetext.test(replacement)) {
            replacement = replacement.replace(removetext, "");
        }
        line = line.replace("OPENING_PHRASE", replacement);
        return [abilityEvent, line];
    };

    this.parseCardNames = function (cardFrequencies) {
        var size = cardFrequencies.length,
            isMixed = hasMixedVisibility(cardFrequencies.map(function (c) {
            return c.cardName;
        }));

        if (size === 0) return [new ClickableColoredText(getPhrase(Phrases.NOTHING))];

        var omit = cardFrequencies.every(function (c) {
            return c.frequency === 1;
        });
        var output = [];
        for (var i = 0; i < size; i++) {
            var omitit = cardFrequencies[i].cardName !== CardNames.BACK || size <= 1;
            output.push(this.getCardFrequencyString(cardFrequencies[i], omit && omitit));
            if (i < size - 1 && size > 2) output.push(new ClickableColoredText("、"));
        }
        return output;
    };
    this.getCardFrequencyString = function (cardFrequency, omit) {
        var cardName = cardFrequency.cardName,
            translation = TCHINESE.getCardName[cardName],
            frequency = cardFrequency.frequency;
        if (omit) {
            return colorTextByCardname(translation.singular, cardName);
        } else {
            var suffix = cardName.isEvent() ? "回" : "枚";
            return colorTextByCardname(translation.singular + frequency + suffix, cardName);
        }
    };

    this.amountParser = function (amount) {
        var output = [];
        if (amount.coin > 0) {
            output.push(new ClickableColoredText(getPhrase(Phrases.COIN) + amount.coin));
        }
        if (amount.potion > 0) {
            var prefix = output.length > 0 ? "、" : "";
            output.push(new ClickableColoredText(prefix + amount.potion + getPhrase(Phrases.POTION)));
        }
        if (amount.debt > 0) {
            var _prefix = output.length > 0 ? "、" : "";
            output.push(new ClickableColoredText(_prefix + amount.debt + getPhrase(Phrases.DEBT)));
        }
        return output;
    };

    this.getRequiredLandscapeTypesString = function (types) {
        var s = "";
        if (types.allows(LANDSCAPE_TYPES.EVENT)) s += "/" + JAPANESE.getType[Types.EVENT];
        if (types.allows(LANDSCAPE_TYPES.LANDMARK)) s += "/" + JAPANESE.getType[Types.LANDMARK];
        if (types.allows(LANDSCAPE_TYPES.PROJECT)) s += "/" + JAPANESE.getType[Types.PROJECT];
        if (types.allows(LANDSCAPE_TYPES.WAY)) s += "/" + JAPANESE.getType[Types.WAY];
        if (types.allows(LANDSCAPE_TYPES.NOTHING)) s += "/" + JAPANESE.getPhrase[Phrases.LANDSCAPE_UNUSED];
        return s.slice(1);
    };

    this.cardComparator = function (a, b) {
        if (a.isLandscape() !== b.isLandscape()) return a.isLandscape() ? 1 : -1;
        if (a.isEvent() !== b.isEvent()) return a.isEvent() ? -1 : 1;
        if (a.isLandmark() !== b.isLandmark()) return a.isLandmark() ? -1 : 1;
        if (a.isProject() !== b.isProject()) return a.isProject() ? -1 : 1;
        if (a.isWay() !== b.isWay()) return a.isWay() ? -1 : 1;
        if (a.isAlly() !== b.isAlly()) return a.isAlly() ? -1 : 1;
        if (a.cost.coin !== b.cost.coin) return a.cost.coin - b.cost.coin;
        if (a.cost.debt !== b.cost.debt) return a.cost.debt - b.cost.debt;
        if (a.cost.potion !== b.cost.potion) return a.cost.potion - b.cost.potion;
        var translatedNameA = EnglishCardNames[a].singular;
        var translatedNameB = EnglishCardNames[b].singular;
        return translatedNameA.localeCompare(translatedNameB);
    };

    this.determineFontStyle = function (isLandscape, lines) {
        var estimateLineLength = function estimateLineLength(line) {
            return _this.strlen(line.replace(/{!?\d+}/g, "x").replace(/\[!?\d+\]/g, "x").replace(/\|/g, ""));
        };
        var maxLineLength = lines.map(estimateLineLength).reduce(function (a, b) {
            return a > b ? a : b;
        });
        var cutoffs = isLandscape ? [35, 45, 50, 55, 60, 99] : [14, 19, 24, 30, 34, 99];
        var styles = ["huge-font", "large-font", "medium-font", "small-font", "tiny-font", "tiniest-font"];
        var index = cutoffs.findIndex(function (e) {
            return maxLineLength < e;
        });
        if (index === -1 || lines.length >= 9) index = 5;
        return styles[index];
    };
}();