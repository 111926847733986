"use strict";

var publicPlayerOrder = void 0;

webclient.service('playerOrder', ['$rootScope', 'tableService', 'meta', function ($rootScope, tableService, meta) {
    var self = this;
    var scope = $rootScope.$new(true);

    self.playerOrder = [];
    self.randomized = false;

    function updatePlayerOrder() {
        if (tableService.hasTable()) {
            var rawOrder = tableService.getRuleValue(TableRuleIds.PLAYER_ORDER);
            if (rawOrder.players.length > 0) {
                self.playerOrder = [];
                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = rawOrder.players[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var orderedPlayer = _step.value;

                        if (orderedPlayer.order < 0) {
                            self.randomized = true;
                            self.playerOrder[-orderedPlayer.order - 1] = orderedPlayer.namedId;
                        } else self.playerOrder[orderedPlayer.order] = orderedPlayer.namedId;
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }
            }
        } else {
            self.playerOrder = [];
        }
    }
}]);