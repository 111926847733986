"use strict";

var SpanishTypes = {};

SpanishTypes[Types.ACTION] = "Acción";
SpanishTypes[Types.TREASURE] = "Tesoro";
SpanishTypes[Types.ATTACK] = "Ataque";
SpanishTypes[Types.CURSE] = "Maldición";
SpanishTypes[Types.DURATION] = "Duración";
SpanishTypes[Types.EVENT] = "Evento";
SpanishTypes[Types.GATHERING] = "Colecta";
SpanishTypes[Types.KNIGHT] = "Caballero";
SpanishTypes[Types.LANDMARK] = "Hito";
SpanishTypes[Types.LOOTER] = "Saqueador";
SpanishTypes[Types.RUINS] = "Ruinas";
SpanishTypes[Types.TRAVELLER] = "Viajero";
SpanishTypes[Types.PRIZE] = "Premio";
SpanishTypes[Types.RESERVE] = "Reserva";
SpanishTypes[Types.VICTORY] = "Victoria";
SpanishTypes[Types.REACTION] = "Reacción";
SpanishTypes[Types.SHELTER] = "Cobijo";
SpanishTypes[Types.CASTLE] = "Castillo";
SpanishTypes[Types.FATE] = "Suerte";
SpanishTypes[Types.NIGHT] = "Noche";
SpanishTypes[Types.DOOM] = "Condena";
SpanishTypes[Types.HEIRLOOM] = "Legado";
SpanishTypes[Types.SPIRIT] = "Espíritu";
SpanishTypes[Types.ZOMBIE] = "Zombi";
SpanishTypes[Types.BOON] = "Bendición";
SpanishTypes[Types.HEX] = "Embrujo";
SpanishTypes[Types.STATE] = "Estado";
SpanishTypes[Types.PROJECT] = "Proyecto";
SpanishTypes[Types.COMMAND] = "Mandato";
SpanishTypes[Types.WAY] = "Senda";
SpanishTypes[Types.LIAISON] = "Liaison"; // <--TRANSLATE
SpanishTypes[Types.ALLY] = "Ally"; // <--TRANSLATE
SpanishTypes[Types.AUGUR] = "Augur"; // <--TRANSLATE
SpanishTypes[Types.CLASH] = "Clash"; // <--TRANSLATE
SpanishTypes[Types.FORT] = "Fort"; // <--TRANSLATE
SpanishTypes[Types.ODYSSEY] = "Odyssey"; // <--TRANSLATE
SpanishTypes[Types.TOWNSFOLK] = "Townsfolk"; // <--TRANSLATE
SpanishTypes[Types.WIZARD] = "Wizard"; // <--TRANSLATE
SpanishTypes[Types.REWARD] = "Reward";
SpanishTypes[Types.SHADOW] = "Shadow";
SpanishTypes[Types.OMEN] = "Omen";
SpanishTypes[Types.PROPHECY] = "Prophecy";

var SpanishZoneNames = {};

SpanishZoneNames["HandZone"] = "Mano";
SpanishZoneNames["DrawZone"] = "Mazo";
SpanishZoneNames["DiscardZone"] = "Pila de descartes";
SpanishZoneNames["TrashZone"] = "Eliminadas";
SpanishZoneNames["SupplyZone"] = "Pila de Suministro";
SpanishZoneNames["InPlayZone"] = "Área de Juego";
SpanishZoneNames["SetAsideZone"] = "Apartar";
SpanishZoneNames["TavernZone"] = "Tablero de Taberna";
SpanishZoneNames["NVZone"] = "Tablero de Aldea Indígena";
SpanishZoneNames["IslandZone"] = "Tablero de Isla";
SpanishZoneNames["ExileZone"] = "Exilio";
SpanishZoneNames["SetAsideZone"] = "Set Aside";

var SpanishMovementTypes = {};

SpanishMovementTypes[MovementTypes.PLAY] = new TranslatedMovementType("juegas", "juega");
SpanishMovementTypes[MovementTypes.DRAW] = new TranslatedMovementType("robas", "roba");
SpanishMovementTypes[MovementTypes.DISCARD] = new TranslatedMovementType("descartas", "descarta");
SpanishMovementTypes[MovementTypes.GAIN] = new TranslatedMovementType("ganas", "gana");
SpanishMovementTypes[MovementTypes.TRASH] = new TranslatedMovementType("eliminas", "elimina");
SpanishMovementTypes[MovementTypes.TOPDECK] = new TranslatedMovementType("pones sobre el mazo", "pone sobre el mazo");
SpanishMovementTypes[MovementTypes.REVEAL] = new TranslatedMovementType("revelas", "revela");
SpanishMovementTypes[MovementTypes.REVEAL_SEARCHING] = new TranslatedMovementType("revelas", "revela");
SpanishMovementTypes[MovementTypes.LOOK_AT] = new TranslatedMovementType("miras a", "mira a");
SpanishMovementTypes[MovementTypes.PUT_IN_HAND] = new TranslatedMovementType("la pones en la mano", "la pone en la mano");
SpanishMovementTypes[MovementTypes.SET_ASIDE] = new TranslatedMovementType("apartas", "aparta");
SpanishMovementTypes[MovementTypes.PUT_ON_MAT] = new TranslatedMovementType("mueves al tablero", "mueve al tablero");
SpanishMovementTypes[MovementTypes.DECK_TO_DISCARD] = new TranslatedMovementType("DECK_TO_DISCARD", "DECK_TO_DISCARD");
SpanishMovementTypes[MovementTypes.BACK_ON_DECK] = new TranslatedMovementType("BACK_ON_DECK", "BACK_ON_DECK");
SpanishMovementTypes[MovementTypes.SHUFFLE_INTO_DECK] = new TranslatedMovementType("SHUFFLE_INTO_DECK", "SHUFFLE_INTO_DECK");
SpanishMovementTypes[MovementTypes.INSERT_IN_DECK] = new TranslatedMovementType("INSERT_IN_DECK", "INSERT_IN_DECK");
SpanishMovementTypes[MovementTypes.EXCHANGE_RETURN] = new TranslatedMovementType("EXCHANGE_RETURN", "EXCHANGE_RETURN");
SpanishMovementTypes[MovementTypes.EXCHANGE_RECEIVE] = new TranslatedMovementType("EXCHANGE_RECEIVE", "EXCHANGE_RECEIVE");
SpanishMovementTypes[MovementTypes.PASS] = new TranslatedMovementType("PASS", "PASS");
SpanishMovementTypes[MovementTypes.RETURN_TO] = new TranslatedMovementType("devuelves", "devuelve");
SpanishMovementTypes[MovementTypes.PUT_ON_BOTTOM_OF] = new TranslatedMovementType("PUT_ON_BOTTOM_OF", "PUT_ON_BOTTOM_OF");
SpanishMovementTypes[MovementTypes.STARTS_WITH] = new TranslatedMovementType("STARTS_WITH", "STARTS_WITH");
SpanishMovementTypes[MovementTypes.TAKE] = new TranslatedMovementType("TAKE", "TAKE");
SpanishMovementTypes[MovementTypes.GET_STATE] = new TranslatedMovementType("GET_STATE", "GET_STATE");
SpanishMovementTypes[MovementTypes.RETURN_STATE] = new TranslatedMovementType("RETURN_STATE", "RETURN_STATE");
SpanishMovementTypes[MovementTypes.EXILE] = new TranslatedMovementType("EXILE", "EXILE");

var SpanishPileTokenTexts = {};

SpanishPileTokenTexts[TokenNames.PLUS_ACTION] = "+A";
SpanishPileTokenTexts[TokenNames.PLUS_BUY] = "+B";
SpanishPileTokenTexts[TokenNames.PLUS_CARD] = "+C";
SpanishPileTokenTexts[TokenNames.PLUS_COIN] = "+1";
SpanishPileTokenTexts[TokenNames.MINUS_COST] = "-2";
SpanishPileTokenTexts[TokenNames.TRASH] = "X";

var SpanishPileMarkers = {};

SpanishPileMarkers[PileMarkerTypes.BANE] = "Perdición";
SpanishPileMarkers[PileMarkerTypes.OBELISK] = "Obelisco";
SpanishPileMarkers[PileMarkerTypes.SPLIT] = "Compartida";
SpanishPileMarkers[PileMarkerTypes.MIXED] = "Mixta";

var SpanishTokenNames = {};

SpanishTokenNames[TokenNames.PLUS_ACTION] = "Ficha +Acción";
SpanishTokenNames[TokenNames.PLUS_BUY] = "Ficha +Compra";
SpanishTokenNames[TokenNames.PLUS_COIN] = "Ficha +Moneda";
SpanishTokenNames[TokenNames.PLUS_CARD] = "Ficha +Carta";
SpanishTokenNames[TokenNames.MINUS_COST] = "Ficha -Coste";
SpanishTokenNames[TokenNames.TRASH] = "Ficha Eliminar";
SpanishTokenNames[TokenNames.JOURNEY] = "Ficha Viaje";
SpanishTokenNames[TokenNames.MINUS_CARD] = "Ficha -Carta";
SpanishTokenNames[TokenNames.MINUS_COIN] = "Ficha -Moneda";

var SpanishKingdomViewerNames = {};

SpanishKingdomViewerNames[KingdomViewerGroups.SUPPLY_CARDS] = "Cartas en el Suministro";
SpanishKingdomViewerNames[KingdomViewerGroups.KINGDOM_LANDSCAPES] = "Eventos, Hitos, Proyectos y Sendas";
SpanishKingdomViewerNames[KingdomViewerGroups.NON_KINGDOM] = "Cartas fuera del Suministro";
SpanishKingdomViewerNames[KingdomViewerGroups.RUINS] = "Ruinas";
SpanishKingdomViewerNames[KingdomViewerGroups.DRUID_BOONS] = "Bendiciones del Druida";
SpanishKingdomViewerNames[KingdomViewerGroups.KNIGHTS] = "Caballeros";
SpanishKingdomViewerNames[KingdomViewerGroups.CASTLES] = "Castillos";
SpanishKingdomViewerNames[KingdomViewerGroups.PRIZES] = "Premios";
SpanishKingdomViewerNames[KingdomViewerGroups.REWARDS] = "Rewards";
SpanishKingdomViewerNames[KingdomViewerGroups.BOONS_HEXES] = "Bendiciones y Embrujos";
SpanishKingdomViewerNames[KingdomViewerGroups.STATES_ARTIFACTS] = "Estados y Artefactos";
SpanishKingdomViewerNames[KingdomViewerGroups.BLACK_MARKET] = "Mercado Negro";

// Missing lines as of 09.01.2023 18:45:07
SpanishTypes[Types.LOOT] = "Loot";
SpanishTypes[Types.TRAIT] = "Trait";
SpanishZoneNames["RevealZone"] = "Reveal";
SpanishZoneNames["TheBox"] = "the Box";
SpanishZoneNames["SkippableRevealZone"] = "Reveal";
SpanishKingdomViewerNames[KingdomViewerGroups.LOOT] = "Loot";