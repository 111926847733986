"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DruidBoonZone = function (_WindowedZone) {
    _inherits(DruidBoonZone, _WindowedZone);

    function DruidBoonZone() {
        _classCallCheck(this, DruidBoonZone);

        return _possibleConstructorReturn(this, (DruidBoonZone.__proto__ || Object.getPrototypeOf(DruidBoonZone)).apply(this, arguments));
    }

    _createClass(DruidBoonZone, [{
        key: "reposition",
        value: function reposition() {
            var _this2 = this;

            var shouldAnimate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            var druidZone = this.state.zones.find(function (z) {
                return !isUndefined(z) && z.pileName === CardNames.DRUID && z instanceof SupplyZone;
            });
            var druidBBox = druidZone.cardStacks[0].view.bbox;

            this.cardStacks.forEach(function (stack, index) {
                var x = druidBBox.width * 0.6;
                var width = druidBBox.width * 0.3;
                var height = width * CardSizes.FULL.landscapeRatio;
                width -= druidBBox.width;
                var y = computeX(druidBBox.height * 0.2, druidBBox.height * 0.8, height, height / 4, index, _this2.cardStacks.length, false);
                height -= druidBBox.height;
                var offset = new Rectangle(x, y, width, height, index);

                if (shouldAnimate) stack.animator = new TransitionAnimator(stack, simpleTrackedTransitionScript(offset), druidZone.cardStacks[0], function () {});else stack.reposition(x + druidBBox.x, y + druidBBox.y, width + druidBBox.width, height + druidBBox.height, druidBBox.zIndex + index);
                stack.view.canvas.style.pointerEvents = "none";
            });
        }
    }, {
        key: "initialize",
        value: function initialize(cardIds) {
            var _this3 = this;

            cardIds.forEach(function (id) {
                var targetName = _this3.state.cards[id].cardName;
                var targetStack = new CardStack(_this3.state.getAnonCard(targetName), _this3, CardSizes.MINI);
                targetStack.addCard(_this3.state.cards[id]);
            });
            this.reposition();
        }
    }, {
        key: "visible",
        get: function get() {
            return false;
        }
    }, {
        key: "repositionOnNewTurn",
        get: function get() {
            return true;
        }
    }]);

    return DruidBoonZone;
}(WindowedZone);