"use strict";

webclient.component("inbox", {
    bindings: {
        lobby: "<"
    },
    controller: [function () {
        var self = this;
        self.getNeedHelpText = function () {
            return getPhrase(Phrases.NEED_HELP);
        };
        self.getFaqText = function () {
            return getPhrase(Phrases.FAQ);
        };
        self.getNewToDominionText = function () {
            return getPhrase(Phrases.NEW_TO_DOMINION);
        };
    }],
    template: "\n            <div class=\"inbox-page\">\n                <message-list></message-list>\n                <message-view></message-view>\n                <div class=\"bottom-lobby-links\">\n                    <div class=\"bottom-lobby-link copper-button\" ng-click=\"$ctrl.lobby.loadFaq()\">{{$ctrl.getFaqText()}}</div>\n                    <div class=\"bottom-lobby-link copper-button\" ng-click=\"$ctrl.lobby.loadNewToDominion()\">{{$ctrl.getNewToDominionText()}}</div>\n                    <div class=\"bottom-lobby-link copper-button\" ng-click=\"$ctrl.lobby.loadForum()\">{{$ctrl.getNeedHelpText()}}</div>\n                </div>\n            </div>         \n            "
});