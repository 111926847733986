"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TableFilter = function () {
    function TableFilter(filter) {
        var defaultActive = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        _classCallCheck(this, TableFilter);

        this.filter = filter;
        this.uuid = generateUUID();
        this._isActive = defaultActive;
    }

    _createClass(TableFilter, [{
        key: "getFilter",
        value: function getFilter() {
            return this.filter;
        }
    }, {
        key: "activate",
        value: function activate() {
            this._isActive = true;
        }
    }, {
        key: "deActivate",
        value: function deActivate() {
            this._isActive = false;
        }
    }, {
        key: "isActive",
        value: function isActive() {
            return this._isActive;
        }
    }, {
        key: "toString",
        value: function toString() {
            return this.uuid;
        }
    }]);

    return TableFilter;
}();

var TableFilters = {
    ONLY_SHOW_NEW: new TableFilter(function (t) {
        return t.isNew();
    }, false),
    HIDE_BOT_GAMES: new TableFilter(function (t) {
        return !t.hasBots();
    }, false),
    HIDE_1P_GAMES: new TableFilter(function (t) {
        return !t.isSinglePlayer();
    }, false),
    HIDE_2P_GAMES: new TableFilter(function (t) {
        return !t.is2Player();
    }, true),
    HIDE_3XP_GAMES: new TableFilter(function (t) {
        return !t.isMultiPlayer();
    }, true)
};