"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CounterView = function () {
    function CounterView(counter) {
        var _this = this;

        var alwaysVisible = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        _classCallCheck(this, CounterView);

        this.node = document.createElement("counter-element");
        this.rendered = false;

        this.isText = counter.counterName.phrases !== null;
        if (this.isText) {
            this.node.classList.add("text-counter");
            this.textNode = document.createElement("counter-text-container");
            this.node.appendChild(this.textNode);
        } else if ([CounterNames.COINS, CounterNames.DEBT, CounterNames.SINISTER_PLOT, CounterNames.CARD_TOKENS, CounterNames.VP_TOKENS].includes(counter.counterName)) {
            this.node.classList.add("coin-element");
        }

        this.resizeListener = function () {
            if (_this.canvas === null) window.removeEventListener("resize", _this.resizeListener);else _this.update();
        };

        this.bbox = new Rectangle(1, 1, 0, 0);
        this.alwaysVisible = alwaysVisible;
        this.counter = counter;
        window.addEventListener("resize", this.resizeListener);
    }

    _createClass(CounterView, [{
        key: "reposition",
        value: function reposition(bbox) {
            this.bbox.x = bbox.x;
            this.bbox.y = bbox.y;
            this.bbox.width = bbox.width;
            this.bbox.height = bbox.height;
            this.bbox.zIndex = Math.floor(bbox.zIndex);
            this.bbox.rotZ = bbox.rotZ;
            this.bbox.opacity = Math.min(1, Math.max(0, bbox.opacity));
            this.bbox.rotY = bbox.rotY;

            this.node.style.left = this.bbox.x + "px";
            this.node.style.top = this.bbox.y + "px";
            this.node.style.width = this.bbox.width + "px";
            this.node.style.height = this.bbox.height + "px";
            this.update();
        }
    }, {
        key: "render",
        value: function render(node) {
            this.rendered = true;
            node.appendChild(this.node);
            this.renderNode = node;
        }
    }, {
        key: "unload",
        value: function unload() {
            this.destroy();
            window.removeEventListener("resize", this.resizeListener);
        }
    }, {
        key: "destroy",
        value: function destroy() {
            this.counter.views.splice(this.counter.views.indexOf(this), 1);
            if (this.rendered && this.renderNode === this.node.parentNode) {
                this.renderNode.removeChild(this.node);
            }
        }
    }, {
        key: "update",
        value: function update() {
            if (this.isText) {
                var color = this.counterName.colorClass;
                if ((this.counterName === CounterNames.ACTIONS || this.counterName === CounterNames.BUYS) && this.counter.value === 0) color = "#666";
                this.textNode.style.color = color;
                this.textNode.style.fontWeight = "bold";
                this.textNode.style.fontFamily = LANGUAGE.getEnvironment.hasOwnProperty("titleFont") ? LANGUAGE.getEnvironment.titleFont : "Montserrat";

                var phrase = void 0;
                if (LANGUAGE === RUSSIAN) {
                    var g = getRussianGrammaticalNumber(this.counter.value);
                    switch (g) {
                        case GrammaticalNumbers.MANY:
                            phrase = this.counterName.phrases.many;break;
                        case GrammaticalNumbers.SINGULAR:
                            phrase = this.counterName.phrases.singular;break;
                        case GrammaticalNumbers.PLURAL:
                            phrase = this.counterName.phrases.plural;break;
                    }
                } else {
                    phrase = LANGUAGE.getPhrase[this.counter.value === 1 ? this.counterName.phrases.singular : this.counterName.phrases.plural];
                }

                var fullText = this.counter.value + " " + phrase;
                fitTextInNode(fullText, this.textNode, this.bbox);
            } else {
                var artURL = this.counterName.bgURL;
                this.node.style.backgroundImage = "url(" + this.counterName.bgURL + ")";
                this.node.style.borderWidth = this.bbox.height * 0.05 + "px";
                this.node.style.color = this.counterName.colorClass;
                this.node.style.fontSize = this.bbox.height * 0.95 + "px";
                this.node.style.fontFamily = getComputedStyle(this.node).fontFamily;
                if (this.counter.counterName.shouldStroke) {
                    var strokeColor = this.counterName.colorClass === "white" ? "black" : "white";
                    this.node.style.webkitTextStroke = this.bbox.height * 0.1 + "px " + strokeColor;
                }
                clearChildNodes(this.node);
                var fitterBbox = new Rectangle(this.bbox.width * 0.1, this.bbox.height * 0.05, this.bbox.width * 0.8, this.bbox.height * 0.9);
                if (this.counter.counterName !== CounterNames.POTIONS || this.counter.value > 1) {
                    fitTextInNode(this.counter.value, this.node, fitterBbox, true);
                }
                if (this.alwaysVisible || this.counter.value > 0) {
                    this.node.classList.remove("invisible");
                } else {
                    this.node.classList.add("invisible");
                }
            }
        }
    }, {
        key: "counter",
        set: function set(counter) {
            if (counter !== this.privateCounter) {
                if (!isUndefined(this.privateCounter)) this.privateCounter.views.splice(this.privateCounter.views.indexOf(this), 1);
                this.privateCounter = counter;
                counter.views.push(this);
                this.update();
            }
        },
        get: function get() {
            return this.privateCounter;
        }
    }, {
        key: "counterName",
        get: function get() {
            return this.counter.counterName;
        }
    }]);

    return CounterView;
}();

var FreeWidthCounterView = function (_CounterView) {
    _inherits(FreeWidthCounterView, _CounterView);

    function FreeWidthCounterView(counter) {
        _classCallCheck(this, FreeWidthCounterView);

        var _this2 = _possibleConstructorReturn(this, (FreeWidthCounterView.__proto__ || Object.getPrototypeOf(FreeWidthCounterView)).call(this, counter));

        _this2.node.classList.add("free-width");
        return _this2;
    }

    _createClass(FreeWidthCounterView, [{
        key: "reposition",
        value: function reposition(bbox) {
            this.bbox.x = bbox.x;
            this.bbox.y = bbox.y;
            this.bbox.height = bbox.height;
            this.bbox.zIndex = Math.floor(bbox.zIndex);
            this.bbox.rotZ = bbox.rotZ;
            this.bbox.opacity = Math.min(1, Math.max(0, bbox.opacity));
            this.bbox.rotY = bbox.rotY;

            this.node.style.left = this.bbox.x + "px";
            this.node.style.top = this.bbox.y + "px";
            this.node.style.height = this.bbox.height + "px";
            this.update();
        }
    }, {
        key: "update",
        value: function update() {
            var color = this.counterName.colorClass;
            if ((this.counterName === CounterNames.ACTIONS || this.counterName === CounterNames.BUYS) && this.counter.value === 0) color = "#666";
            this.textNode.style.color = color;
            this.textNode.style.fontWeight = "bold";
            this.textNode.style.fontFamily = LANGUAGE.getEnvironment.hasOwnProperty("titleFont") ? LANGUAGE.getEnvironment.titleFont : "Montserrat";

            var phrase = void 0;
            if (LANGUAGE === RUSSIAN) {
                var g = getRussianGrammaticalNumber(this.counter.value);
                switch (g) {
                    case GrammaticalNumbers.MANY:
                        phrase = this.counterName.phrases.many;break;
                    case GrammaticalNumbers.SINGULAR:
                        phrase = this.counterName.phrases.singular;break;
                    case GrammaticalNumbers.PLURAL:
                        phrase = this.counterName.phrases.plural;break;
                }
            } else {
                phrase = LANGUAGE.getPhrase[this.counter.value === 1 ? this.counterName.phrases.singular : this.counterName.phrases.plural];
            }

            var fullText = this.counter.value + " " + phrase;
            this.textNode.innerHTML = fullText;
        }
    }]);

    return FreeWidthCounterView;
}(CounterView);

var ProjectCubeView = function () {
    function ProjectCubeView(player) {
        _classCallCheck(this, ProjectCubeView);

        this.node = document.createElement("project-cube");
        this.rendered = false;

        this.bbox = new Rectangle(1, 1, 0, 0);

        this.player = player;
        this.artURL = player.cubeImg;
        this.node.style.backgroundImage = "url(" + this.artURL + ")";
    }

    _createClass(ProjectCubeView, [{
        key: "reposition",
        value: function reposition(bbox) {
            this.bbox.x = bbox.x;
            this.bbox.y = bbox.y;
            this.bbox.width = bbox.width;
            this.bbox.height = bbox.height;
            this.bbox.zIndex = Math.floor(bbox.zIndex);
            this.bbox.rotZ = bbox.rotZ;
            this.bbox.opacity = Math.min(1, Math.max(0, bbox.opacity));
            this.bbox.rotY = bbox.rotY;

            this.node.style.left = this.bbox.x + "px";
            this.node.style.top = this.bbox.y + "px";
            this.node.style.width = this.bbox.width + "px";
            this.node.style.height = this.bbox.height + "px";
        }
    }, {
        key: "unload",
        value: function unload() {
            this.destroy();
        }
    }, {
        key: "destroy",
        value: function destroy() {
            if (this.rendered && this.renderNode === this.node.parentNode) {
                this.renderNode.removeChild(this.node);
            }
        }
    }, {
        key: "render",
        value: function render(node) {
            this.rendered = true;
            node.appendChild(this.node);
            this.renderNode = node;
        }
    }]);

    return ProjectCubeView;
}();

var TokenView = function () {
    function TokenView(token) {
        _classCallCheck(this, TokenView);

        this.node = document.createElement("token-element");
        this.rendered = false;

        this.token = token;
        token.views.push(this);
        if (this.token.tokenName.coloredBG) {
            this.node.classList.add("colored-token");
        }

        this.bbox = new Rectangle(1, 1, 0, 0);
    }

    _createClass(TokenView, [{
        key: "render",
        value: function render(node) {
            this.rendered = true;
            node.appendChild(this.node);
            this.renderNode = node;
        }
    }, {
        key: "reposition",
        value: function reposition(bbox) {
            this.bbox.x = bbox.x;
            this.bbox.y = bbox.y;
            this.bbox.width = bbox.width;
            this.bbox.height = bbox.height;
            this.bbox.zIndex = Math.floor(bbox.zIndex);
            this.bbox.rotZ = bbox.rotZ;
            this.bbox.opacity = Math.min(1, Math.max(0, bbox.opacity));
            this.bbox.rotY = bbox.rotY;

            this.node.style.left = this.bbox.x + "px";
            this.node.style.top = this.bbox.y + "px";
            this.node.style.width = this.bbox.width + "px";
            this.node.style.height = this.bbox.height + "px";
            this.update();
        }
    }, {
        key: "unload",
        value: function unload() {
            this.destroy();
        }
    }, {
        key: "destroy",
        value: function destroy() {
            this.token.views.splice(this.token.views.indexOf(this), 1);
            if (this.rendered && this.renderNode === this.node.parentNode) {
                this.renderNode.removeChild(this.node);
            }
        }
    }, {
        key: "update",
        value: function update() {
            if (!this.token.owner.isNobody && this.token.tokenName.coloredBG) {
                this.node.style.borderWidth = 0.05 * this.bbox.width + "px";
                this.node.style.backgroundColor = this.token.owner.color;
            }
            var artURL = this.token.isFlipped ? this.token.tokenName.flippedBG : this.token.tokenName.bgURL;
            this.node.style.backgroundImage = "url(" + artURL + ")";
        }
    }]);

    return TokenView;
}();

var ReminderView = function () {
    function ReminderView(reminder, game) {
        _classCallCheck(this, ReminderView);

        this.game = game;
        this.node = document.createElement("reminder-element");
        this.rendered = false;

        this.reminder = reminder;
        this.bbox = new Rectangle(1, 1, 0, 0);
    }

    _createClass(ReminderView, [{
        key: "render",
        value: function render(node) {
            var _this3 = this;

            this.rendered = true;
            node.appendChild(this.node);
            this.renderNode = node;
            this.node.oncontextmenu = function () {
                if (!_this3.reminder.cardName.isFake) _this3.game.studyModel.study(_this3.reminder.cardName);
            };
        }
    }, {
        key: "unload",
        value: function unload() {
            this.destroy();
        }
    }, {
        key: "destroy",
        value: function destroy() {
            if (this.rendered && this.renderNode === this.node.parentNode) {
                this.renderNode.removeChild(this.node);
            }
        }
    }, {
        key: "reposition",
        value: function reposition(bbox) {
            this.bbox.x = bbox.x;
            this.bbox.y = bbox.y;
            this.bbox.width = bbox.width;
            this.bbox.height = bbox.height;
            this.bbox.zIndex = Math.floor(bbox.zIndex);
            this.bbox.rotZ = bbox.rotZ;
            this.bbox.opacity = Math.min(1, Math.max(0, bbox.opacity));
            this.bbox.rotY = bbox.rotY;

            this.node.style.left = this.bbox.x + "px";
            this.node.style.top = this.bbox.y + "px";
            this.node.style.width = this.bbox.width + "px";
            this.node.style.height = this.bbox.height + "px";
            this.update();
        }
    }, {
        key: "update",
        value: function update() {
            var artURL = getReminderArtURL(this.reminder.cardName);
            this.node.style.backgroundImage = "url(" + artURL + ")";
            this.node.style.color = "white";
            this.node.style.fontSize = this.bbox.height * 0.95 + "px";
            this.node.style.webkitTextStroke = this.bbox.height * 0.05 + "px black";
            if (this.reminder.frequency > 1) {
                fitTextInNode(this.reminder.frequency, this.node, this.bbox, true);
            }
        }
    }]);

    return ReminderView;
}();