"use strict";

var publicReplayService = void 0;

webclient.service('replayService', ['$rootScope', 'tableService', 'serverMessenger', 'meta', function ($rootScope, tableService, serverMessenger, meta) {
    var self = this;
    var scope = $rootScope.$new(true);
    var shouldShow = false;

    if (isDebug) publicReplayService = self;

    self.shouldShowReplayWindow = function () {
        return shouldShow;
    };

    self.replayGame = function () {
        return shouldShow = true;
    };
    self.submitReplay = function (gameId, decisionIndex) {
        var r = new ReplayInstructions(gameId, decisionIndex, new PlayerList());
        tableService.changeRule(new TableRule(TableRuleIds.REPLAY_INSTRUCTIONS, r));
    };

    self.startReplayTable = function (gameId, decisionIndex) {
        var rules = [];
        var r = new ReplayInstructions(gameId, decisionIndex, new PlayerList());
        rules.push(new TableRule(TableRuleIds.REPLAY_INSTRUCTIONS, r));
        serverMessenger.newTableRequest(rules);
        shouldShow = false;
    };

    self.removeReplay = function () {
        return shouldShow = false;
    };

    self.getReplayButtonText = function () {
        return getPhrase(Phrases.LOAD_OLD_GAME);
    };
    self.getRemoveReplayButtonText = function () {
        return getPhrase(Phrases.REMOVE_REPLAY);
    };
    scope.$on(Events.PLAYER_LEFT, function (playerLeft) {
        if (playerLeft.playerId === meta.getMyId()) shouldShow = false;
    });

    self.getReplayInstructions = function () {
        return tableService.getRuleValue(TableRuleIds.REPLAY_INSTRUCTIONS);
    };

    self.toggleReplay = function () {
        if (!self.hasReplay()) return;
        self.submitReplay(0, 0);
    };

    self.hasReplay = function () {
        var replayInstructions = tableService.getRuleValue(TableRuleIds.REPLAY_INSTRUCTIONS);
        return replayInstructions.gameId !== 0;
    };

    self.getGameId = function () {
        var r = tableService.getRuleValue(TableRuleIds.REPLAY_INSTRUCTIONS);
        if (r.gameId === 0) return '-';
        return r.gameId;
    };

    self.getDecisionIndex = function () {
        var r = tableService.getRuleValue(TableRuleIds.REPLAY_INSTRUCTIONS);
        return r.decisionIndex;
    };
}]);