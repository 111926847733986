"use strict";

var CzechDeclineButtons = {};

CzechDeclineButtons[DeclineButtonIds.END_ACTION_PHASE] = "Ukončit Akce";
CzechDeclineButtons[DeclineButtonIds.END_BUY_PHASE] = "Ukončit Nákup";
CzechDeclineButtons[DeclineButtonIds.END_TURN] = "Ukončit tah";
CzechDeclineButtons[DeclineButtonIds.DONE_PLAYING_TREASURES] = "Žádné další Poklady";
CzechDeclineButtons[DeclineButtonIds.DONE_REACTING] = "Pokračovat";
CzechDeclineButtons[DeclineButtonIds.MINING_VILLAGE_DONT_TRASH] = "Nevyhazovat";
CzechDeclineButtons[DeclineButtonIds.PEARL_DIVER_DONT_TOPDECK] = "Nepřesouvat navrch";
CzechDeclineButtons[DeclineButtonIds.HARBINGER_DONT_TOPDECK] = "Nepřesouvat navrch";
CzechDeclineButtons[DeclineButtonIds.THRONE_ROOM_DONT] = "Nepoužít Trůn";
CzechDeclineButtons[DeclineButtonIds.SMUGGLERS_GAIN_IMPOSSIBLE_COPY] = "Nebrat si";
CzechDeclineButtons[DeclineButtonIds.EXPLORER_GAIN_SILVER] = "Vezmi si Stříbrňák";
CzechDeclineButtons[DeclineButtonIds.HERBALIST_DONT_TOPDECK] = "Nepřesouvat navrch";
CzechDeclineButtons[DeclineButtonIds.MINT_DONT_COPY] = "Nekopírovat";
CzechDeclineButtons[DeclineButtonIds.HAMLET_DONT_DISCARD] = "Neodkládat kartu";
CzechDeclineButtons[DeclineButtonIds.TOURNAMENT_DONT_REVEAL] = "Neukazovat";
CzechDeclineButtons[DeclineButtonIds.YOUNG_WITCH_DONT_REVEAL_BANE] = "Neukazovat";
CzechDeclineButtons[DeclineButtonIds.IGG_NO_COPPER] = "Žádný Měďák";
CzechDeclineButtons[DeclineButtonIds.COUNTERFEIT_NO_TREASURE] = "Nepoužít Padělání";
CzechDeclineButtons[DeclineButtonIds.CULTIST_DONT_PLAY_ANOTHER] = "Nepoužít vyznavače";
CzechDeclineButtons[DeclineButtonIds.IRONMONGER_KEEP] = "Nechat navrchu";
CzechDeclineButtons[DeclineButtonIds.PROCESSION_DONT] = "Nepoužít Proces";
CzechDeclineButtons[DeclineButtonIds.PLAZA_NO_DISCARD] = "Neodkládat kartu";
CzechDeclineButtons[DeclineButtonIds.DONT_OVERPAY] = "Nepřeplácet";
CzechDeclineButtons[DeclineButtonIds.DONE_SETTING_ASIDE] = "Dokončit vykládání";
CzechDeclineButtons[DeclineButtonIds.ARTIFICER_DONT] = "Neodkládat kartu";
CzechDeclineButtons[DeclineButtonIds.ENGINEER_DONT_TRASH] = "Nevyhazovat";
CzechDeclineButtons[DeclineButtonIds.ENCAMPMENT_DONT_REVEAL] = "Neukazovat";
CzechDeclineButtons[DeclineButtonIds.SETTLERS_DONT_TAKE_COPPER] = "Nevzít Meďák";
CzechDeclineButtons[DeclineButtonIds.GLADIATOR_DONT_REVEAL] = "Neukazovat";
CzechDeclineButtons[DeclineButtonIds.CROWN_DONT] = "Nepoužít Korunu";
CzechDeclineButtons[DeclineButtonIds.LEGIONARY_DONT_REVEAL] = "Neukazovat";
CzechDeclineButtons[DeclineButtonIds.SHUFFLE_ALL] = "Zamíchat vše";
CzechDeclineButtons[DeclineButtonIds.PASS] = "Složit";
CzechDeclineButtons[DeclineButtonIds.DONT_DISCARD] = "Neodkládat kartu";
CzechDeclineButtons[DeclineButtonIds.DONT_TRASH] = "Nevyhazovat";
CzechDeclineButtons[DeclineButtonIds.VAULT_NO_DISCARD] = "Neodkládat kartu";
CzechDeclineButtons[DeclineButtonIds.DONT_BUY] = "Nenakupovat";
CzechDeclineButtons[DeclineButtonIds.DONT_GAIN] = "Nezískat";
CzechDeclineButtons[DeclineButtonIds.DISCARD_ALL] = "Odložit vše";
CzechDeclineButtons[DeclineButtonIds.TORTURER_FAIL_CURSE] = "Vzít si Kletbu";
CzechDeclineButtons[DeclineButtonIds.TORTURER_GAIN_CURSE] = "Vzít si Kletbu";
CzechDeclineButtons[DeclineButtonIds.BUSTLING_VILLAGE_NO_SETTLERS] = "Nevzít Osadníky";
CzechDeclineButtons[DeclineButtonIds.PRINCE_NO] = "Nevykládat";
CzechDeclineButtons[DeclineButtonIds.SAUNA_DONT_CHAIN] = "Nehrát další";
CzechDeclineButtons[DeclineButtonIds.AVANTO_DONT_CHAIN] = "Nehrát další";
CzechDeclineButtons[DeclineButtonIds.CONCLAVE_DONT] = "Nehrát";
CzechDeclineButtons[DeclineButtonIds.IMP_DONT] = "Nehrát";
CzechDeclineButtons[DeclineButtonIds.NECROMANCER_DONT] = "Nehrát";
CzechDeclineButtons[DeclineButtonIds.NO_THANKS] = "Ne, děkuji";
CzechDeclineButtons[DeclineButtonIds.DONT_EXILE] = "Nehrát Vyhoštění";
CzechDeclineButtons[DeclineButtonIds.KINGS_COURT_DONT] = "Nehrát Krále";
CzechDeclineButtons[DeclineButtonIds.MASTERMIND_DONT] = "Nehrát Myslitele";
CzechDeclineButtons[DeclineButtonIds.DELAY_DONT] = "Nehrát Odklad";
CzechDeclineButtons[DeclineButtonIds.TOIL_DONT] = "Nehrát Dřinu";
CzechDeclineButtons[DeclineButtonIds.MARCH_DONT] = "Nehrát Pochod";
CzechDeclineButtons[DeclineButtonIds.DONT_TOPDECK] = "Nepřesouvat navrch";
CzechDeclineButtons[DeclineButtonIds.RANDOM_ORDER] = "Náhodné pořadí";
CzechDeclineButtons[DeclineButtonIds.HUNTING_LODGE_KEEP] = "Nechat si";
CzechDeclineButtons[DeclineButtonIds.SNAKE_WITCH_DONT_RETURN] = "Don't return"; // <-- translate

CzechDeclineButtons[DoneButtonIds.DONE_TRASHING] = "Potvrdit vyhození";
CzechDeclineButtons[DoneButtonIds.DONE_DISCARDING] = "Potvrdit odložení";
CzechDeclineButtons[DoneButtonIds.DONE_ORDERING] = "Dokončit řazení";
CzechDeclineButtons[DoneButtonIds.DONE_RETURNING] = "Dokončit vrácení";
CzechDeclineButtons[DoneButtonIds.DONE_SETTING_ASIDE] = "Dokončit vykládání";
CzechDeclineButtons[DoneButtonIds.DONE_SELECTING] = "Dokončit označení";
CzechDeclineButtons[DoneButtonIds.DONE_CHOOSING] = "Dokončit vybírání";
CzechDeclineButtons[DoneButtonIds.TRASH] = "Vyhodit";
CzechDeclineButtons[DoneButtonIds.DISCARD] = "Odložit";
CzechDeclineButtons[DoneButtonIds.PASS] = "Složit";
CzechDeclineButtons[DoneButtonIds.TOPDECK] = "Položit navrch";
CzechDeclineButtons[DoneButtonIds.EXILE] = "Hrát Exil";
CzechDeclineButtons[DoneButtonIds.OVERPAY] = "Přeplácet";

var CzechGameButtons = {};

CzechGameButtons[GameButtons.AUTOPLAY_TREASURES] = "Zahrát Poklady";
CzechGameButtons[GameButtons.USE_VILLAGER] = "Použít Vesničany";

var CzechDelayedQuestionButtons = {};

CzechDelayedQuestionButtons[CardNames.ARTIFICER] = "Použít Řemeslníka";
CzechDelayedQuestionButtons[CardNames.BUSTLING_VILLAGE] = "Vzít si Osadníky";
CzechDelayedQuestionButtons[CardNames.CULTIST] = "Hrát Vyznavače";
CzechDelayedQuestionButtons[CardNames.ENGINEER] = "Vyhodit Inženýra";
CzechDelayedQuestionButtons[CardNames.HAMLET] = "Odložit";
CzechDelayedQuestionButtons[CardNames.HARBINGER] = "Položit navrch";
CzechDelayedQuestionButtons[CardNames.ILL_GOTTEN_GAINS] = "Vzít si Měďák";
CzechDelayedQuestionButtons[CardNames.IRONMONGER] = "Odložit";
CzechDelayedQuestionButtons[CardNames.MINING_VILLAGE] = "Vyhodit";
CzechDelayedQuestionButtons[CardNames.PEARL_DIVER] = "Položit navrch";
CzechDelayedQuestionButtons[CardNames.PLAZA] = "Odložit";
CzechDelayedQuestionButtons[CardNames.SETTLERS] = "Vzít si Měďák";
CzechDelayedQuestionButtons[CardNames.HUNTING_LODGE] = "Odložit a vzít si 5 karet";

var CzechCleanupActions = {};

CzechCleanupActions[CleanupActions.TOPDECK] = new CleanupButtonText("Položit nvrch", "Odložit");
CzechCleanupActions[CleanupActions.EXCHANGE] = new CleanupButtonText("Vyměnit", "Nechat si");