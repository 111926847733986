"use strict";

var DutchDeclineButtons = {};

DutchDeclineButtons[DeclineButtonIds.END_ACTION_PHASE] = "End Actions";
DutchDeclineButtons[DeclineButtonIds.END_BUY_PHASE] = "End Buys";
DutchDeclineButtons[DeclineButtonIds.END_TURN] = "End Turn";
DutchDeclineButtons[DeclineButtonIds.DONE_PLAYING_TREASURES] = "No Treasures";
DutchDeclineButtons[DeclineButtonIds.DONE_REACTING] = "Done Reacting";
DutchDeclineButtons[DeclineButtonIds.MINING_VILLAGE_DONT_TRASH] = "Don't Trash";
DutchDeclineButtons[DeclineButtonIds.PEARL_DIVER_DONT_TOPDECK] = "Don't Topdeck";
DutchDeclineButtons[DeclineButtonIds.HARBINGER_DONT_TOPDECK] = "Don't Topdeck";
DutchDeclineButtons[DeclineButtonIds.VASSAL_DONT_PLAY] = "Don't Play";
DutchDeclineButtons[DeclineButtonIds.THRONE_ROOM_DONT] = "Don't Throne";
DutchDeclineButtons[DeclineButtonIds.SMUGGLERS_GAIN_IMPOSSIBLE_COPY] = "Don't Gain";
DutchDeclineButtons[DeclineButtonIds.EXPLORER_GAIN_SILVER] = "Gain Silver";
DutchDeclineButtons[DeclineButtonIds.HERBALIST_DONT_TOPDECK] = "Don't Topdeck";
DutchDeclineButtons[DeclineButtonIds.MINT_DONT_COPY] = "Don't Copy";
DutchDeclineButtons[DeclineButtonIds.HAMLET_DONT_DISCARD] = "Don't Discard";
DutchDeclineButtons[DeclineButtonIds.TOURNAMENT_DONT_REVEAL] = "Don't Reveal";
DutchDeclineButtons[DeclineButtonIds.YOUNG_WITCH_DONT_REVEAL_BANE] = "Don't Reveal";
DutchDeclineButtons[DeclineButtonIds.IGG_NO_COPPER] = "No Copper";
DutchDeclineButtons[DeclineButtonIds.COUNTERFEIT_NO_TREASURE] = "Don't Counterfeit";
DutchDeclineButtons[DeclineButtonIds.CULTIST_DONT_PLAY_ANOTHER] = "Don't chain Cultists";
DutchDeclineButtons[DeclineButtonIds.IRONMONGER_KEEP] = "Keep on top";
DutchDeclineButtons[DeclineButtonIds.PROCESSION_DONT] = "Don't Process";
DutchDeclineButtons[DeclineButtonIds.PLAZA_NO_DISCARD] = "Don't Discard";
DutchDeclineButtons[DeclineButtonIds.DONT_OVERPAY] = "Don't Overpay";
DutchDeclineButtons[DeclineButtonIds.DONE_SETTING_ASIDE] = "Done Setting Aside";
DutchDeclineButtons[DeclineButtonIds.ARTIFICER_DONT] = "Don't Discard";
DutchDeclineButtons[DeclineButtonIds.ENGINEER_DONT_TRASH] = "Don't Trash";
DutchDeclineButtons[DeclineButtonIds.ENCAMPMENT_DONT_REVEAL] = "Don't Reveal";
DutchDeclineButtons[DeclineButtonIds.SETTLERS_DONT_TAKE_COPPER] = "Don't take Copper";
DutchDeclineButtons[DeclineButtonIds.GLADIATOR_DONT_REVEAL] = "Don't Reveal";
DutchDeclineButtons[DeclineButtonIds.CROWN_DONT] = "Don't Crown";
DutchDeclineButtons[DeclineButtonIds.LEGIONARY_DONT_REVEAL] = "Don't Reveal";
DutchDeclineButtons[DeclineButtonIds.SHUFFLE_ALL] = "Shuffle All";
DutchDeclineButtons[DeclineButtonIds.PASS] = "Pass";
DutchDeclineButtons[DeclineButtonIds.DONT_DISCARD] = "Don't Discard";
DutchDeclineButtons[DeclineButtonIds.DONT_TRASH] = "Don't Trash";
DutchDeclineButtons[DeclineButtonIds.VAULT_NO_DISCARD] = "Don't Discard";
DutchDeclineButtons[DeclineButtonIds.DONT_BUY] = "Don't Buy";
DutchDeclineButtons[DeclineButtonIds.DONT_GAIN] = "Don't Gain";
DutchDeclineButtons[DeclineButtonIds.DISCARD_ALL] = "Discard All";
DutchDeclineButtons[DeclineButtonIds.TORTURER_FAIL_CURSE] = "Gain Curse";
DutchDeclineButtons[DeclineButtonIds.TORTURER_GAIN_CURSE] = "Gain Curse";
DutchDeclineButtons[DeclineButtonIds.BUSTLING_VILLAGE_NO_SETTLERS] = "Don't take Settlers";
DutchDeclineButtons[DeclineButtonIds.PRINCE_NO] = "Don't Set Aside";
DutchDeclineButtons[DeclineButtonIds.SAUNA_DONT_CHAIN] = "Don't chain";
DutchDeclineButtons[DeclineButtonIds.AVANTO_DONT_CHAIN] = "Don't chain";
DutchDeclineButtons[DeclineButtonIds.CONCLAVE_DONT] = "Don't play";
DutchDeclineButtons[DeclineButtonIds.IMP_DONT] = "Don't play";
DutchDeclineButtons[DeclineButtonIds.NECROMANCER_DONT] = "Don't play";

DutchDeclineButtons[DoneButtonIds.DONE_TRASHING] = "Confirm Trashing";
DutchDeclineButtons[DoneButtonIds.DONE_DISCARDING] = "Confirm Discarding";
DutchDeclineButtons[DoneButtonIds.DONE_ORDERING] = "Done Ordering";
DutchDeclineButtons[DoneButtonIds.DONE_RETURNING] = "Done Returning";
DutchDeclineButtons[DoneButtonIds.DONE_SETTING_ASIDE] = "Done Setting Aside";
DutchDeclineButtons[DoneButtonIds.DONE_SELECTING] = "Done Selecting";
DutchDeclineButtons[DoneButtonIds.DONE_CHOOSING] = "Done Choosing";
DutchDeclineButtons[DoneButtonIds.TRASH] = "Trash";
DutchDeclineButtons[DoneButtonIds.DISCARD] = "Discard";
DutchDeclineButtons[DoneButtonIds.PASS] = "Pass";
DutchDeclineButtons[DoneButtonIds.TOPDECK] = "Topdeck";

var DutchGameButtons = {};

DutchGameButtons[GameButtons.AUTOPLAY_TREASURES] = "Autoplay Treasures";

var DutchDelayedQuestionButtons = {};

DutchDelayedQuestionButtons[CardNames.ARTIFICER] = "Use Artificer";
DutchDelayedQuestionButtons[CardNames.BUSTLING_VILLAGE] = "Take Settlers";
DutchDelayedQuestionButtons[CardNames.CULTIST] = "Play Cultist";
DutchDelayedQuestionButtons[CardNames.ENGINEER] = "Trash Engineer";
DutchDelayedQuestionButtons[CardNames.HAMLET] = "Discard";
DutchDelayedQuestionButtons[CardNames.HARBINGER] = "Topdeck";
DutchDelayedQuestionButtons[CardNames.ILL_GOTTEN_GAINS] = "Gain Copper";
DutchDelayedQuestionButtons[CardNames.IRONMONGER] = "Discard";
DutchDelayedQuestionButtons[CardNames.MINING_VILLAGE] = "Trash";
DutchDelayedQuestionButtons[CardNames.PEARL_DIVER] = "Topdeck";
DutchDelayedQuestionButtons[CardNames.PLAZA] = "Discard";
DutchDelayedQuestionButtons[CardNames.SETTLERS] = "Take Copper";
DutchDelayedQuestionButtons[CardNames.VASSAL] = "Play Action";

var DutchCleanupActions = {};

DutchCleanupActions[CleanupActions.TOPDECK] = new CleanupButtonText("Topdeck", "Discard");
DutchCleanupActions[CleanupActions.EXCHANGE] = new CleanupButtonText("Exchange", "Keep");