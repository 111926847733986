"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

webclient.service('fullGameStateProcessor', ['meta', '$injector', function (meta, $injector) {

    var self = this;

    self.process = function (reader) {
        var isUpdate = reader.readBoolean();
        var gameId = reader.readLong();
        var game = $injector.get('game');
        var gameEventProcessor = $injector.get('gameEventProcessor');
        game.readState(isUpdate, reader);

        var n = reader.readInt();

        for (var i = 0; i < n; i++) {
            gameEventProcessor.process(reader);
        }
        var metaBroadcaster = $injector.get('metaBroadcaster');
        metaBroadcaster.send(Events.PRINT_JOIN_GAME, gameId);
    };
}]);

webclient.service('serverToClientProcessor', ['metaBroadcaster', 'serverMessageProcessor', 'fullGameStateProcessor', '$injector', function (metaBroadcaster, serverMessageProcessor, fullGameStateProcessor, $injector) {

    var self = this;

    self.receiveMessage = function (reader) {
        if (isDebug) timeLog("received chat message.");
        serverMessageProcessor.process(reader);
    };

    self.commandFailed = function (reader) {
        var failure = Failure.parse(reader);
        if (isDebug) timeLog("received Failure: %o", failure);
        timeLog("Meta: command failed: %o", failure.toTranslatedString());
        metaBroadcaster.send(Events.COMMAND_FAILED, failure);
    };

    self.loginSuccess = function (reader) {
        if (isDebug) timeLog("received login success.");
        var playerId = reader.readInt();
        var userName = reader.readString();
        var isReconnecting = reader.readBoolean();
        var previousReconnects = reader.readInt();
        var preferences = [];
        var size = reader.readInt();
        for (var i = 0; i < size; i++) {
            preferences.push(new UserPrefProcessor().process(reader));
        }

        var sessionId = reader.readString();
        var numberOfUnreadMails = reader.readInt();

        self.relationships(reader);
        var ipCountry = reader.readString();
        if (useDebugUrlParameters() && isDefined(DEBUG_URL_PARAMETERS.country)) {
            ipCountry = DEBUG_URL_PARAMETERS.country;
        }
        ;
        var cardLevelMap = self.processCardLevelMap(reader);
        var loginSuccess = new LoginSuccess(playerId, userName, isReconnecting, previousReconnects, sessionId, numberOfUnreadMails, ipCountry, cardLevelMap);
        metaBroadcaster.send(Events.USER_PREFERENCES, preferences);
        metaBroadcaster.send(Events.LOGIN_SUCCESS, loginSuccess);
    };

    self.tablesOverview = function (reader) {
        if (isDebug) timeLog("received: tables overview.");
        metaBroadcaster.send(Events.TABLES_OVERVIEW, reader.readArrayOf(TableSummary));
    };

    self.tableRuleChanged = function (reader) {
        if (isDebug) timeLog("received: table rule changed.");
        var change = new TableStateChange(Events.TABLE_RULE_CHANGED, RuleChange.parse(reader));
        var game = $injector.get('game');
        if (game.optionsModel) {
            game.optionsModel.handleChange(change.change);
        }
        metaBroadcaster.send(Events.TABLE_STATE_CHANGE, change);
    };

    self.hostChanged = function (reader) {
        if (isDebug) timeLog("received: host changed.");
        var change = new TableStateChange(Events.HOST_CHANGED, HostChange.parse(reader));
        metaBroadcaster.send(Events.TABLE_STATE_CHANGE, change);
    };

    self.playerJoined = function (reader) {
        if (isDebug) timeLog("received: player joined.");
        var change = new TableStateChange(Events.PLAYER_JOINED, PlayerJoined.parse(reader));
        metaBroadcaster.send(Events.TABLE_STATE_CHANGE, change);
    };

    self.playerLeft = function (reader) {
        if (isDebug) timeLog("received: player left.");
        var change = new TableStateChange(Events.PLAYER_LEFT, PlayerLeft.parse(reader));
        metaBroadcaster.send(Events.TABLE_STATE_CHANGE, change);
    };

    self.newPlayerStatus = function (reader) {
        if (isDebug) timeLog("received: new player status.");
        metaBroadcaster.send(Events.NEW_PLAYER_STATUS, NewPlayerStatus.parse(reader));
    };

    self.gameStarted = function (reader) {
        if (isDebug) timeLog("received: game started.");
        var gameStarted = GameStarted.parse(reader);
        timeLog("game id: " + gameStarted.gameId + ".");
        var change = new TableStateChange(Events.GAME_STARTED, gameStarted);
        metaBroadcaster.send(Events.TABLE_STATE_CHANGE, change);
    };

    self.tableJoined = function (reader) {
        if (isDebug) timeLog("received: table joined.");
        var details = new TableDetailsProcessor().process(reader);
        var showTableView = reader.readBoolean();
        var change = new TableStateChange(Events.TABLE_JOINED, details);
        metaBroadcaster.send(Events.TABLE_STATE_CHANGE, change);
        if (showTableView) {
            metaBroadcaster.send(Events.SHOW_TABLE, details);
        }
    };

    self.tableRemoved = function (reader) {
        if (isDebug) timeLog("received: table removed.");
    };

    self.newRole = function (reader) {
        if (isDebug) timeLog("received: new role.");
        var change = new TableStateChange(Events.NEW_ROLE, NewRole.parse(reader));
        metaBroadcaster.send(Events.TABLE_STATE_CHANGE, change);
    };

    self.tableDetails = function (reader) {
        if (isDebug) timeLog("received: table details.");
        var details = new TableDetailsProcessor().process(reader);
        var change = new TableStateChange(Events.TABLE_DETAILS, details);
        metaBroadcaster.send(Events.TABLE_STATE_CHANGE, change);
    };

    self.gameFinished = function (reader) {
        if (isDebug) timeLog("received: game finished.");
        var gameFinished = GameFinished.parse(reader);
        var change = new TableStateChange(Events.GAME_FINISHED, gameFinished);
        var game = $injector.get('game');
        game.animationDirector.endGame(gameFinished.gameResult);
        metaBroadcaster.send(Events.TABLE_STATE_CHANGE, change);
    };

    self.commandSucceeded = function (reader) {
        var id = reader.readInt();
        metaBroadcaster.send(Events.COMMAND_SUCCEEDED, getByOrdinal(SuccessDescriptions, id));
    };

    self.propertyChanged = function (reader) {
        if (isDebug) timeLog("received: property changed.");
        var preferences = [];
        var size = reader.readInt();
        for (var i = 0; i < size; i++) {
            preferences.push(new UserPrefProcessor().process(reader));
        }
        metaBroadcaster.send(Events.USER_PREFERENCES, preferences);
    };

    self.purchasePrice = function (reader) {
        if (isDebug) timeLog("received: purchase price.");
        var onlineProduct = new OnlineProduct(getByOrdinal(StorePackages, reader.readInt()), reader.readDate(), reader.readString());
        var purchaseTime = reader.readDate();
        var prices = reader.readArrayOf(Price);
        var purchase = new Purchase(onlineProduct, purchaseTime, prices);
        metaBroadcaster.send(Events.PURCHASE_PRICE, purchase);
    };

    self.paymentRedirect = function (reader) {
        if (isDebug) timeLog("received: paymentRedirect.");
        var redirectUrl = reader.readString();
        metaBroadcaster.send(Events.PURCHASE_REDIRECT, redirectUrl);
    };

    self.relationships = function (reader) {
        if (isDebug) timeLog("received: relationships.");
        var relationships = Relationships.parse(reader);
        metaBroadcaster.send(Events.RELATIONSHIPS, relationships);
    };

    self.automatchStatus = function (reader) {
        if (isDebug) timeLog("received: automatchStatus.");
        metaBroadcaster.send(Events.AUTOMATCH_STATUS, AutomatchStatus.parse(reader));
    };

    self.automatchFound = function (reader) {
        if (isDebug) timeLog("received: Automatch found.");
        var automatchFound = AutomatchFound.parse(reader);
        metaBroadcaster.send(Events.AUTOMATCH_FOUND, automatchFound);
    };

    self.botAdded = function (reader) {
        if (isDebug) timeLog("received: botAdded.");
        var change = new TableStateChange(Events.BOT_ADDED, BotAdded.parse(reader));
        metaBroadcaster.send(Events.TABLE_STATE_CHANGE, change);
    };

    self.mailSummaries = function (reader) {
        if (isDebug) timeLog("received: mailSummaries.");
        metaBroadcaster.send(Events.MAIL_SUMMARIES_RECEIVED, reader.readArrayOf(MailSummary));
    };

    self.mailMessages = function (reader) {
        if (isDebug) timeLog("received: mailMessages.");
        metaBroadcaster.send(Events.MAILS_RECEIVED, reader.readArrayOf(Mail));
    };

    self.leaderboard = function (reader) {
        if (isDebug) timeLog("received: leaderboard.");
        metaBroadcaster.send(Events.LEADERBOARD_RECEIVED, Leaderboard.parse(reader));
    };

    self.tableTerminated = function (reader) {
        if (isDebug) timeLog("received table terminated.");
        var tableId = reader.readLong();
        metaBroadcaster.send(Events.TABLE_TERMINATED, tableId);
    };

    self.playerReplaced = function (reader) {
        if (isDebug) timeLog("received player replaced.");
        metaBroadcaster.send(Events.PLAYER_REPLACED, PlayerReplaced.parse(reader));
    };

    self.playerDisconnected = function (reader) {
        if (isDebug) timeLog("received player disconnected.");
        var change = new TableStateChange(Events.PLAYER_DISCONNECTED, PlayerDisconnected.parse(reader));
        metaBroadcaster.send(Events.TABLE_STATE_CHANGE, change);
    };

    self.playerReconnected = function (reader) {
        if (isDebug) timeLog("received player reconnected.");
        var change = new TableStateChange(Events.PLAYER_RECONNECTED, PlayerReconnected.parse(reader));
        metaBroadcaster.send(Events.TABLE_STATE_CHANGE, change);
    };

    self.bonusCodeValidated = function (reader) {
        if (isDebug) timeLog("received bonus code validated.");
        var bonusCodeValidated = BonusCodeValidated.parse(reader);
        metaBroadcaster.send(Events.BONUS_CODE_VALIDATED, bonusCodeValidated);
    };

    self.purchaseResult = function (reader) {
        if (isDebug) timeLog("received purchase result");
        var purchaseResult = PurchaseResult.parse(reader);
        if (purchaseResult.isSuccess()) {
            metaBroadcaster.send(Events.PURCHASE_SUCCESS, purchaseResult);
        } else {
            throw new Error("Purchase failed");
        }
    };

    self.fullGameState = function (reader) {
        if (isDebug) timeLog("received full game state.");
        fullGameStateProcessor.process(reader);
    };

    self.questionAsked = function (reader) {
        if (isDebug) timeLog("received: questionAsked");
        var questionTypeProcessor = $injector.get('questionTypeProcessor');
        questionTypeProcessor.process(reader);
    };

    self.gameEventInfo = function (reader) {
        if (isDebug) timeLog("received: gameEventInfo");
        var gameEventProcessor = $injector.get('gameEventProcessor');
        gameEventProcessor.process(reader);
    };

    self.gameLogInfo = function (reader) {
        if (isDebug) timeLog("received: gameLogInfo");
        var startIndex = reader.readInt();
        var size = reader.readInt();
        var game = $injector.get('game');
        var gameStateChange = LogEntriesChange.parse(game, reader, startIndex, size);
        game.animationDirector.newAnimation(gameStateChange.createAnimation.bind(gameStateChange));
    };

    self.tickerInfo = function (reader) {
        if (isDebug) timeLog("received: tickerInfo");
        var game = $injector.get('game');
        var gameStateChange = TickerChange.parse(game, reader);
        game.animationDirector.newAnimation(gameStateChange.createAnimation.bind(gameStateChange));
    };

    self.metagameInfo = function (reader) {
        if (isDebug) timeLog("received: metagameInfo");
        var game = $injector.get('game');
        processMetagameInfo(game, reader);
    };

    self.pong = function (reader) {
        if (isDebug) timeLog("received: pong.");
        metaBroadcaster.send(Events.PONG_RECEIVED);
    };

    self.playerTimerPacket = function (reader) {
        timeLog("Recieved timer info");
        timeLog(reader.readLong());
    };

    self.processCardLevelMap = function (reader) {
        var map = new Map();
        var size = reader.readInt();
        for (var i = 0; i < size; i++) {
            var cardName = CardName.parse(reader);
            var level = reader.readInt();
            map.set(cardName, level);
        }
        return map;
    };

    self.instructToReconnect = function (reader) {
        var connectToOtherServer = reader.readBoolean();
        if (isDebug) timeLog("received: instruct to reconnect.");
        metaBroadcaster.send(Events.INSTRUCT_TO_RECONNECT, connectToOtherServer);
    };

    self.instructToTimeOut = function (reader) {
        if (isDebug) timeLog("received: instruct to timeOut.");
        metaBroadcaster.send(Events.INSTRUCT_TO_TIME_OUT);
    };

    self.processors = [self.receiveMessage, self.commandFailed, self.loginSuccess, self.tablesOverview, self.tableRuleChanged, self.hostChanged, self.playerJoined, self.playerLeft, self.newPlayerStatus, self.gameStarted, self.tableJoined, self.tableRemoved, self.newRole, self.tableDetails, self.gameFinished, self.commandSucceeded, self.propertyChanged, self.purchasePrice, self.paymentRedirect, self.relationships, self.automatchStatus, self.automatchFound, self.botAdded, self.mailSummaries, self.mailMessages, self.leaderboard, self.tableTerminated, self.playerReplaced, self.playerDisconnected, self.playerReconnected, self.bonusCodeValidated, self.purchaseResult, self.gameEventInfo, self.gameLogInfo, self.tickerInfo, self.metagameInfo, self.pong, self.questionAsked, self.fullGameState, self.instructToReconnect, self.instructToTimeOut];

    self.process = function (reader) {
        var index = reader.readInt();
        self.processors[index](reader);
    };
}]);

function TableDetailsProcessor() {
    var self = this;

    self.process = function (reader) {
        var tableId = reader.readLong();
        var hostId = reader.readInt();
        var players = reader.readArrayOf(NamedId);
        var readyToStart = reader.readBooleans();
        var bots = reader.readArrayOf(NamedId);
        var spectators = reader.readArrayOf(NamedId);
        var reconnectingPlayers = reader.readArrayOf(NamedId);
        var ruleProcessor = new TableRuleProcessor();
        var n = reader.readInt();
        var rules = [];
        var changedRules = [];
        for (var i = 0; i < n; i++) {
            rules.push(ruleProcessor.process(reader));
            changedRules.push(reader.readBoolean());
        }
        return new TableDetails(tableId, hostId, players, readyToStart, bots, spectators, reconnectingPlayers, rules, changedRules);
    };
}

webclient.service('serverMessageProcessor', ['metaBroadcaster', function (metaBroadcaster) {
    var self = this;

    self.chatMessage = function (reader) {
        var chatMessage = ChatMessage.parse(reader);
        metaBroadcaster.send(Events.CHAT_MESSAGE_RECEIVED, chatMessage);
    };

    self.invite = function (reader) {
        metaBroadcaster.send(Events.INVITE_RECEIVED, Invite.parse(reader));
    };

    self.inviteDeclined = function (reader) {};

    self.gameResult = function (reader) {};

    self.mail = function (reader) {
        metaBroadcaster.send(Events.MAIL_RECEIVED, Mail.parse(reader));
    };

    self.processors = [self.chatMessage, self.invite, self.inviteDeclined, self.gameResult, self.mail];

    self.process = function (reader) {
        // let index = reader.readInt();
        self.processors[0](reader);
    };
}]);

function TableRuleProcessor() {
    var self = this;

    self.playersCanSeeSpectatorChat = function (reader) {
        var b = reader.readBoolean();
        return new TableRule(TableRuleIds.PLAYERS_CAN_SEE_SPECTATOR_CHAT, b);
    };

    self.bannedCards = function (reader) {
        var cardNames = reader.readArrayOf(CardName);
        return new TableRule(TableRuleIds.BANNED_CARDS, cardNames);
    };

    self.requiredCards = function (reader) {
        var requiredKingdomSlots = reader.readArrayOf(RequiredKingdomSlot);
        return new TableRule(TableRuleIds.REQUIRED_CARDS, requiredKingdomSlots);
    };

    self.usedExpansions = function (reader) {
        var expansions = reader.readArrayOf(Expansion);
        return new TableRule(TableRuleIds.USED_EXPANSIONS, expansions);
    };

    self.restrictivePlayerRules = function (reader) {
        return new TableRule(TableRuleIds.RESTRICTIVE_PLAYER_RULES, Group.parse(reader));
    };

    self.spectateRules = function (reader) {
        var spectatorGroup = Group.parse(reader);
        // getByOrdinal(GroupIds, reader.readInt());
        // let arg = -1;
        // if (spectatorGroup === GroupIds.FRIENDS_OF) arg = reader.readInt();
        return new TableRule(TableRuleIds.SPECTATE_RULES, spectatorGroup);
    };

    self.minRating = function (reader) {
        return new TableRule(TableRuleIds.MIN_RATING, reader.readDouble());
    };

    self.maxRating = function (reader) {
        return new TableRule(TableRuleIds.MAX_RATING, reader.readDouble());
    };

    self.randomSeed = function (reader) {
        return new TableRule(TableRuleIds.RANDOM_SEED, reader.readBoolean(), reader.readLong());
    };

    self.minPlayers = function (reader) {
        return new TableRule(TableRuleIds.MIN_PLAYERS, reader.readInt());
    };

    self.maxPlayers = function (reader) {
        return new TableRule(TableRuleIds.MAX_PLAYERS, reader.readInt());
    };

    self.specialKingdomRules = function (reader) {
        var specialCards = SpecialKingdomRules.parse(reader);
        return new TableRule(TableRuleIds.SPECIAL_KINGDOM_RULES, specialCards);
    };

    self.respectedCardLists = function (reader) {
        return new TableRule(TableRuleIds.RESPECTED_CARD_LISTS, reader.readInts());
    };

    self.showVPCounter = function (reader) {
        return new TableRule(TableRuleIds.SHOW_VP_COUNTER, reader.readBoolean());
    };

    self.playerOrder = function (reader) {
        return new TableRule(TableRuleIds.PLAYER_ORDER, PlayerOrder.parse(reader));
    };

    self.undoSettings = function (reader) {
        return new TableRule(TableRuleIds.UNDO_SETTINGS, getByOrdinal(UndoSettings, reader.readInt()));
    };

    self.alwaysAllowedPlayerRules = function (reader) {
        return new TableRule(TableRuleIds.ALWAYS_ALLOWED_PLAYER_RULES, Group.parse(reader));
    };

    self.replayInstructions = function (reader) {
        return new TableRule(TableRuleIds.REPLAY_INSTRUCTIONS, ReplayInstructions.parse(reader));
    };

    self.ratedGame = function (reader) {
        var isRated = reader.readBoolean();
        return new TableRule(TableRuleIds.RATED_GAME, isRated);
    };

    self.previewGame = function (reader) {
        var isPreview = reader.readBoolean();
        return new TableRule(TableRuleIds.PREVIEW_GAME, isPreview);
    };

    self.scriptedRules = function (reader) {
        var enabledRules = reader.readArrayOf(AutomatchQuestion);
        return new TableRule(TableRuleIds.SCRIPTED_RULES, enabledRules);
    };

    self.cardPoolLevel = function (reader) {
        var cardPoolLevel = reader.readInt();
        return new TableRule(TableRuleIds.CARD_POOL_LEVEL, cardPoolLevel);
    };

    self.processors = [self.playersCanSeeSpectatorChat, self.bannedCards, self.requiredCards, self.usedExpansions, self.restrictivePlayerRules, self.spectateRules, self.minRating, self.maxRating, self.randomSeed, self.minPlayers, self.maxPlayers, self.specialKingdomRules, self.respectedCardLists, self.showVPCounter, self.playerOrder, self.undoSettings, self.alwaysAllowedPlayerRules, self.replayInstructions, self.ratedGame, self.previewGame, self.scriptedRules, self.cardPoolLevel];

    self.process = function (reader) {
        var index = reader.readInt();
        return self.processors[index](reader);
    };
}

function UserPrefProcessor() {
    var self = this;

    self.cardList = function (reader) {
        return new CardList(reader.readInt(), reader.readArrayOf(CardName));
    };

    self.expansionList = function (reader) {
        return reader.readArrayOf(Expansion);
    };

    self.playerList = function (reader) {
        return reader.readInts();
    };

    self.integer = function (reader) {
        return reader.readInt();
    };

    self.boolean = function (reader) {
        return reader.readBoolean();
    };

    self.string = function (reader) {
        return reader.readString();
    };

    self.processors = {};
    self.processors[UserPrefTypes.BOOLEAN] = self.boolean;
    self.processors[UserPrefTypes.INTEGER] = self.integer;
    self.processors[UserPrefTypes.STRING] = self.string;
    self.processors[UserPrefTypes.CARD_LIST] = self.cardList;
    self.processors[UserPrefTypes.OWNERSHIP] = Ownership.parse;
    self.processors[UserPrefTypes.MATCHING_CRITERIA] = MatchingCriteria.parse;
    self.processors[UserPrefTypes.AUTOPLAY_SETTINGS] = AutoPlaySettings.parse;
    self.processors[UserPrefTypes.EXPANSION_LIST] = self.expansionList;
    self.processors[UserPrefTypes.DELETED] = function () {
        return undefined;
    };

    self.process = function (reader) {
        var index = reader.readInt();
        var pref = getByOrdinal(UserPrefIds, index);
        var typeIndex = reader.readInt();
        var type = getByOrdinal(UserPrefTypes, typeIndex);
        if (type !== pref.type) {
            console.log("userpref type mismatch! pref/type: ", index, typeIndex);
        }
        var value = self.processors[type](reader);
        return new UserPreference(pref, value);
    };
}

var MetagameInfoParser = function MetagameInfoParser(playerIndex, decisionIndex) {
    _classCallCheck(this, MetagameInfoParser);

    this.playerIndex = playerIndex;
    this.decisionIndex = decisionIndex;
};

var UndoRequest = function (_MetagameInfoParser) {
    _inherits(UndoRequest, _MetagameInfoParser);

    function UndoRequest() {
        _classCallCheck(this, UndoRequest);

        return _possibleConstructorReturn(this, (UndoRequest.__proto__ || Object.getPrototypeOf(UndoRequest)).apply(this, arguments));
    }

    _createClass(UndoRequest, [{
        key: 'serialize',
        value: function serialize(writer) {
            writer.writeInt(this.playerIndex);
            writer.writeInt(this.decisionIndex);
        }
    }], [{
        key: 'associatedEvent',
        value: function associatedEvent() {
            return Events.UNDO_REQUEST;
        }
    }, {
        key: 'parse',
        value: function parse(reader) {
            return new UndoRequest(reader.readInt(), reader.readInt());
        }
    }]);

    return UndoRequest;
}(MetagameInfoParser);

var TimeoutOffer = function (_MetagameInfoParser2) {
    _inherits(TimeoutOffer, _MetagameInfoParser2);

    function TimeoutOffer() {
        _classCallCheck(this, TimeoutOffer);

        return _possibleConstructorReturn(this, (TimeoutOffer.__proto__ || Object.getPrototypeOf(TimeoutOffer)).apply(this, arguments));
    }

    _createClass(TimeoutOffer, null, [{
        key: 'associatedEvent',
        value: function associatedEvent() {
            return Events.TIMEOUT_OFFER;
        }
    }, {
        key: 'parse',
        value: function parse(reader) {
            return new TimeoutOffer(reader.readInt(), reader.readInt());
        }
    }]);

    return TimeoutOffer;
}(MetagameInfoParser);

var UndoRequestDenied = function (_MetagameInfoParser3) {
    _inherits(UndoRequestDenied, _MetagameInfoParser3);

    function UndoRequestDenied() {
        _classCallCheck(this, UndoRequestDenied);

        return _possibleConstructorReturn(this, (UndoRequestDenied.__proto__ || Object.getPrototypeOf(UndoRequestDenied)).apply(this, arguments));
    }

    _createClass(UndoRequestDenied, null, [{
        key: 'associatedEvent',
        value: function associatedEvent() {
            return Events.UNDO_REQUEST_DENIED;
        }
    }, {
        key: 'parse',
        value: function parse(reader) {
            return new UndoRequestDenied(reader.readInt(), reader.readInt());
        }
    }]);

    return UndoRequestDenied;
}(MetagameInfoParser);

var UndoRequestCancelled = function (_MetagameInfoParser4) {
    _inherits(UndoRequestCancelled, _MetagameInfoParser4);

    function UndoRequestCancelled() {
        _classCallCheck(this, UndoRequestCancelled);

        return _possibleConstructorReturn(this, (UndoRequestCancelled.__proto__ || Object.getPrototypeOf(UndoRequestCancelled)).apply(this, arguments));
    }

    _createClass(UndoRequestCancelled, null, [{
        key: 'associatedEvent',
        value: function associatedEvent() {
            return Events.UNDO_REQUEST_CANCELLED;
        }
    }, {
        key: 'parse',
        value: function parse(reader) {
            return new UndoRequestCancelled(reader.readInt(), reader.readInt());
        }
    }]);

    return UndoRequestCancelled;
}(MetagameInfoParser);

function processMetagameInfo(game, reader) {
    var processors = [UndoRequest, TimeoutOffer, UndoRequestDenied, UndoRequestCancelled];
    var processor = processors[reader.readInt()];
    game.metaGameModel.change(processor.associatedEvent(), processor.parse(reader));
}

webclient.service('questionTypeProcessor', ["game", function (game) {
    var self = this;

    var processors = [ChoiceQuestion, NumberQuestion, ComplexQuestion, DelayedQuestion, NameQuestion];

    self.process = function (reader) {
        var questionIndex = reader.readInt();
        var typeIndex = reader.readInt();
        var question = processors[typeIndex].parse(game, reader, [questionIndex]);
        game.animationDirector.newAnimation(question.createAnimation.bind(question));
    };
}]);

function QuestionElementProcessor(reader) {
    var self = this;

    self.processors = [QuestionCardElements, QuestionAbilityElements, QuestionExtraTurnElements, QuestionZoneElements, QuestionCardModeElements, QuestionCardNameElements, QuestionGameButtonElements, QuestionCleanupActionElements, QuestionBuyableCardElements];

    self.process = function () {
        var size = reader.readInt();
        if (size > 0) {
            var typeIndex = reader.readInt();
            return self.processors[typeIndex].parse(reader, size);
        } else {
            return new QuestionCardElements([]);
        }
    };
}

/*let spliceZone = () => {
    if (this.zoneIndex === game.state.zones.values().length - 1) {
        game.state.zones.values().splice(this.zoneIndex, 1);
        let nullIndex = game.state.zones.values().indexOf(null);
        if (nullIndex > -1) {
            game.state.zones.values().length = nullIndex;
        }
    } else {
        game.state.zones.values().splice(this.zoneIndex, 1, null);
    }
    game.state.dirtyZones = true;
    terminator();
}

// Sometimes the server derps and destroys the zone before all the cards leave
let destroyedZone = game.state.getZone(this.zoneIndex);
if (destroyedZone.cardCount() > 0) {
    let oldRemoveCard = destroyedZone.removeCard.bind(destroyedZone);
    destroyedZone.removeCard = (card, props) => {
        oldRemoveCard(card, props);
        if (destroyedZone.cardCount() === 0) {
            spliceZone();
        }
    }

    let oldRemoveAnonymousCard = destroyedZone.removeAnonymousCard.bind(destroyedZone);
    destroyedZone.removeAnonymousCard = (card, props) => {
        oldRemoveAnonymousCard(card, props);
        if (destroyedZone.cardCount() === 0) {
            spliceZone();
        }
    }
} else {
    spliceZone();
}*/

webclient.service('gameEventProcessor', ["game", function (game) {
    var self = this;

    var processors = [CardMove, CounterChange, PileUpdate, TurnDescription, Shuffle, Inspection, CostReduction, TokenChange, ZoneCreated, ZoneDestroyed, Rename, BehaviourChange, PilesStatus, RemindersChange, ImageChange, Association, CardStatus, ProjectBought, ChangeCardTypes, PileReorder];

    self.process = function (reader, state) {
        var index = reader.readInt();
        var change = processors[index].parse(game, reader);
        game.animationDirector.newAnimation(change.createAnimation.bind(change));
    };
}]);