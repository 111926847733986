"use strict";

var TITLE_WIDTH = 1528;
var TITLE_HEIGHT = 660;
var TITLE_RATIO = TITLE_WIDTH / TITLE_HEIGHT;

webclient.controller('LoginController', ['$scope', '$location', 'connection', 'chat', 'metaBroadcaster', 'sessionService', 'signUp', 'languageSelection', function ($scope, $location, connection, chat, metaBroadcaster, sessionService, signUp, languageSelection) {
    var login = this;
    login.username = "";
    login.password = "";
    login.repeatPassword = "";
    login.version = "1.9.2.2";
    login.shouldShowPasswordReset = false;
    login.shouldShowLogin = true;
    login.shouldShowLoading = false;
    login.statusText = "";
    var passwordResetCode = "";
    var loginCode = "";

    login.isConnected = connection.isConnected;
    login.isConnecting = connection.isConnecting;
    login.isAlive = connection.isAlive;
    login.wasTimedOut = connection.wasTimedOut;

    function checkForPasswordReset() {
        passwordResetCode = $location.search().passwordResetCode;
        if (isUndefined(passwordResetCode) || passwordResetCode === "") return;
        login.shouldShowPasswordReset = true;
        login.shouldShowLogin = false;
    }

    function checkLocalStorage() {
        if (!localStorageIsEnabled()) return;
        var languageCode = localStorage.language;
        languageSelection.selectByCode(languageCode, false);
    }

    function checkForLoginCode() {
        loginCode = $location.search().loginCode;
        if (isEmpty(loginCode)) return false;
        sessionService.clear();
        connection.loginWithCode(loginCode);
        window.history.pushState("", "", "index.html");
        return true;
    }

    function checkForSignUpCode() {
        var code = $location.search().signUpCode;
        if (isUndefined(code) || code === "") return false;
        login.signUp(code);
        return true;
    }

    function checkForSession() {
        if (sessionService.hasStoredSession() && !hasCancelledReconnect) {
            timeLog("yes found a session....");
            sessionService.login();
            return true;
        } else {
            timeLog("no session found");
            return false;
        }
    }

    function checkForLanguageParameter() {
        var languageCode = $location.search().lang;
        if (isUndefined(languageCode) || languageCode === "") return;
        languageSelection.selectByCode(languageCode);
    }

    function isIllegal() {
        var a = login.username === "" || login.password === "";
        var b = isUndefined(login.username) || isUndefined(login.password);
        return a || b || login.isConnecting();
    }

    function isIllegalReset() {
        var a = login.password === "" || login.repeatPassword === "";
        var b = isUndefined(login.password) || isUndefined(login.repeatPassword);
        var c = login.password !== login.repeatPassword;
        return a || b || c || login.isConnecting();
    }

    login.getVersionString = function () {
        return getPhrase(Phrases.VERSION) + ': ' + login.version;
    };

    login.canKick = function () {
        return !isIllegal();
    };

    login.forgotPassword = function () {
        if (login.username === "") {
            login.statusText = getPhrase(Phrases.ENTER_USERNAME);
            return;
        }
        connection.forgotPassword(login.username);
    };

    login.submit = function () {
        if (isIllegal()) return;
        login.shouldShowLoading = true;
        connection.login(login.username, login.password);
        connection.hasKicked = false;
        login.statusText = "";
    };

    function isValidKick() {
        if (login.username === "" || login.password === "") {
            login.statusText = getPhrase(Phrases.ENTER_LOGIN_CREDENTIALS);
            return false;
        }
        return true;
    }

    login.kick = function () {
        if (!isValidKick()) return;
        if (isIllegal()) return;
        connection.removeSessions(login.username, login.password, false);
        connection.hasKicked = true;
    };

    login.hardKick = function () {
        if (!isValidKick()) return;
        if (isIllegal()) return;
        connection.removeSessions(login.username, login.password, true);
        connection.hasKicked = true;
    };

    login.signUp = function () {
        var code = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";

        login.shouldShowLogin = false;
        signUp.signUp(code);
    };

    login.resetPassword = function () {
        if (isIllegalReset()) return;
        connection.loginWithNewPassword(passwordResetCode, login.password);
        $scope.$parent.pageDisplay.showLoadingPage();
        window.history.pushState("", "", "index.html");
    };

    login.getResetButtonStyle = function () {
        if (isIllegalReset()) return "disabled-button";
        return "legal-login activated";
    };

    login.getLoginButtonStyle = function () {
        if (isIllegal()) return "disabled-button";
        return "legal-login activated";
    };

    login.isFullscreen = function () {
        return document.fullscreenElement !== null;
    };
    login.toggleFullscreen = function () {
        if (login.isFullscreen()) {
            document.exitFullscreen();
        } else {
            document.documentElement.requestFullscreen();
        }
    };

    var loginErrors = [FailureReasons.WRONG_PASSWORD, FailureReasons.ALREADY_LOGGED_IN, FailureReasons.NO_SUCH_USERNAME, FailureReasons.BAD_CLIENT_VERSION, FailureReasons.METASERVER_INACTIVE];

    var isLoginError = function isLoginError(failure) {
        return loginErrors.indexOf(failure.failureReason) > -1;
    };

    $scope.$on(Events.COMMAND_FAILED, function (event, failure) {
        if (isLoginError(failure)) {
            login.statusText = failure.toTranslatedString();
            login.shouldShowLoading = false;
            safeDigest($scope);
        }
    });

    login.setSizes = function () {
        var candidateHeight = 0.55 * window.innerHeight;
        var candidateWidth = 0.9 * window.innerWidth;
        var titleWidth = Math.min(candidateHeight * TITLE_RATIO, candidateWidth);
        var titleHeight = titleWidth / TITLE_RATIO;
        var titleScale = titleWidth / TITLE_WIDTH;
        var titleLeft = (window.innerWidth - titleWidth) / 2;
        login.titleStyle = "left:" + titleLeft + "px;" + "transform:scale(" + titleScale + ");";

        var bottomHeight = window.innerHeight - titleHeight;
        var left = 0.24 * window.innerWidth >= 300 ? 38 : 100 * (1 - 300 / window.innerWidth) / 2;
        login.kickStyle = "bottom:" + 5 + "%;" + "left:" + 5 + "%;";
    };

    $scope.$on(Events.COMMAND_SUCCEEDED, function (event, reason) {
        if (reason === SuccessDescriptions.KICK_SUCCESS) {
            login.statusText = getPhrase(Phrases.KICK_SUCCEEDED);
            $scope.$digest();
        }
    });

    $scope.$on(Events.SERVER_CONNECTION_OPENED, function () {
        var b = login.shouldShowPasswordReset;
        if (!b) b |= checkForLoginCode();
        if (!b) b |= checkForSignUpCode();
        if (!b) checkForSession();
    });

    $scope.$on(Events.CLOSE_SIGNUP_FORM, function () {
        login.shouldShowLogin = true;
    });

    window.addEventListener(Events.RESIZE, function () {
        login.setSizes();
        $scope.$digest();
    });

    (function initialize() {
        login.setSizes();
        if (!connection.wasTimedOut) connection.initialize();
        checkLocalStorage();
        checkForLanguageParameter();
        checkForPasswordReset();
    })();

    login.getUsernameText = function () {
        return getPhrase(Phrases.USERNAME);
    };
    login.getPasswordText = function () {
        return getPhrase(Phrases.PASSWORD);
    };
    login.getLoginText = function () {
        return getPhrase(Phrases.LOGIN);
    };
    login.getVersionText = function () {
        return getPhrase(Phrases.VERSION);
    };
    login.getSignUpText = function () {
        return getPhrase(Phrases.SIGN_UP);
    };
    login.getForgotPwdText = function () {
        return getPhrase(Phrases.FORGOT_YOUR_PASSWORD);
    };
    login.getKickText = function () {
        return getPhrase(Phrases.KICK);
    };
    login.getKickResignText = function () {
        return getPhrase(Phrases.KICK_AND_RESIGN);
    };
    login.getNewPwdText = function () {
        return getPhrase(Phrases.NEW_PASSWORD);
    };
    login.getConfirmPwdText = function () {
        return getPhrase(Phrases.CONFIRM_PASSWORD);
    };
    login.getConnectionText = function () {
        return getPhrase(login.wasTimedOut ? Phrases.TIMED_OUT : Phrases.CONNECTION_CLOSED);
    };
    login.getRetryText = function () {
        return getPhrase(login.wasTimedOut ? Phrases.RECONNECT : Phrases.RETRY);
    };
    login.getLoadingText = function () {
        return getPhrase(Phrases.LOADING_GAME);
    };
    login.getSignupClass = function () {
        if (LANGUAGE === RUSSIAN) {
            return " smaller";
        } else {
            return "";
        }
    };
    login.getForumString = function () {
        return 'Shuffle iT ' + getPhrase(Phrases.FORUM);
    };
}]);