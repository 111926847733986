"use strict";

var RussianAutoPlays = {};

RussianAutoPlays[AutoPlays.MOAT] = ["Отключить", "Всегда показывать"];
RussianAutoPlays[AutoPlays.CROWN] = ["Отключить", "Играть в фазе покупки, если кончились действия"];
RussianAutoPlays[AutoPlays.CHANGELING] = ["Отключить", "По ситуации", "Никогда не обменивать"];
RussianAutoPlays[AutoPlays.URCHIN] = ["Отключить", "Никогда не выбрасывать"];
RussianAutoPlays[AutoPlays.MOUNTEBANK] = ["Отключить", "Всегда сбрасывать"];
RussianAutoPlays[AutoPlays.SETTLERS] = ["Отключить", "Всегда брать Медь"];
RussianAutoPlays[AutoPlays.BUSTLING_VILLAGE] = ["Отключить", "Всегда брать Поселенцев"];
RussianAutoPlays[AutoPlays.TOURNAMENT] = ["Отключить", "Всегда показывать Провинцию"];
RussianAutoPlays[AutoPlays.PEASANT] = ["Отключить", "Всегда менять", "Всегда оставлять"];
RussianAutoPlays[AutoPlays.SOLDIER] = ["Отключить", "Всегда менять", "Всегда оставлять"];
RussianAutoPlays[AutoPlays.FUGITIVE] = ["Отключить", "Всегда менять", "Всегда оставлять"];
RussianAutoPlays[AutoPlays.DISCIPLE] = ["Отключить", "Всегда менять", "Всегда оставлять"];
RussianAutoPlays[AutoPlays.PAGE] = ["Отключить", "Всегда менять", "Всегда оставлять"];
RussianAutoPlays[AutoPlays.TREASURE_HUNTER] = ["Отключить", "Всегда менять", "Всегда оставлять"];
RussianAutoPlays[AutoPlays.WARRIOR] = ["Отключить", "Всегда менять", "Всегда оставлять"];
RussianAutoPlays[AutoPlays.HERO] = ["Отключить", "Всегда менять", "Всегда оставлять"];
RussianAutoPlays[AutoPlays.FAITHFUL_HOUND] = ["Отключить", "Всегда откладывать"];
RussianAutoPlays[AutoPlays.YOUNG_WITCH] = ["Отключить", "Всегда показывать Погибель"];
RussianAutoPlays[AutoPlays.TREASURY] = ["Отключить", "Всегда класть наверх"];
RussianAutoPlays[AutoPlays.ALCHEMIST] = ["Отключить", "Всегда класть наверх"];
RussianAutoPlays[AutoPlays.WALLED_VILLAGE] = ["Отключить", "Всегда класть наверх"];
RussianAutoPlays[AutoPlays.HERBALIST] = ["Отключить", "В любом порядке"];
RussianAutoPlays[AutoPlays.ROYAL_SEAL] = ["Отключить", "Всегда класть наверх", "Никогда не класть наверх"];
RussianAutoPlays[AutoPlays.COIN_OF_THE_REALM] = ["Отключить", "По ситуации"];
RussianAutoPlays[AutoPlays.BORDER_GUARD] = ["Отключить", "Всегда класть наверх"];

RussianAutoPlays[AutoPlays.TRADER] = ["Отключить", "Не реагировать на получение Серебра"];
RussianAutoPlays[AutoPlays.PROVINCE] = ["Отключить", "Оставлять плохие карты в Изгнании"];
RussianAutoPlays[AutoPlays.CHAMPION] = ["Отключить", "Оптимальный пересмотр Путей"];
RussianAutoPlays[AutoPlays.WAY_OF_THE_BUTTERFLY] = ["Отключить", "Возвращать оптимально"];
RussianAutoPlays[AutoPlays.DESPERATION] = ["Отключить", "Всегда получать Проклятие"];
RussianAutoPlays[AutoPlays.TORTURER] = ["Отключить", "Не сбрасывать когда Проклятия закончились"];
RussianAutoPlays[AutoPlays.ARENA] = ["Отключить", "Не сбрасывать когда очки победы закончились"];
RussianAutoPlays[AutoPlays.PAGEANT] = ["Отключить", "Всегда получать монетку"];
RussianAutoPlays[AutoPlays.OLD_WITCH] = ["Отключить", "Всегда выкидывать Проклятие"];
RussianAutoPlays[AutoPlays.SINISTER_PLOT] = ["Отключить", "Никогда не тянуть ноль карт"];
RussianAutoPlays[AutoPlays.MONEYLENDER] = ["Отключить", "Всегда выкидывать Медь"];
RussianAutoPlays[AutoPlays.ENCAMPMENT] = ["Отключить", "По ситуации"];
RussianAutoPlays[AutoPlays.DUPLICATE] = ["Отключить", "Только карты которые можно получить", "Не Проклятия"];