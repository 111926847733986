"use strict";

var CzechAbilityNames = {};

CzechAbilityNames[AbilityNames.BUY] = "a";
CzechAbilityNames[AbilityNames.PLAY_ATTACK] = "play attack";
CzechAbilityNames[AbilityNames.DRAW_INSTRUCTIONS] = "b";
CzechAbilityNames[AbilityNames.PLAY_INSTRUCTIONS] = "play";
CzechAbilityNames[AbilityNames.RESOLVE_EVENT] = "c";
CzechAbilityNames[AbilityNames.GAIN_BY_NAME] = "d";

CzechAbilityNames[AbilityNames.FISHING_VILLAGE_ABILITY] = CzechCardNames[CardNames.FISHING_VILLAGE].singular;
CzechAbilityNames[AbilityNames.REMOVE_COST_REDUCTION] = "e";
CzechAbilityNames[AbilityNames.HAVEN_ABILITY] = CzechCardNames[CardNames.HAVEN].singular;
CzechAbilityNames[AbilityNames.SAVE_RETURN_TO_HAND] = CzechCardNames[CardNames.SAVE].singular;
CzechAbilityNames[AbilityNames.CARAVAN_ABILITY] = CzechCardNames[CardNames.CARAVAN].singular;
CzechAbilityNames[AbilityNames.MERCHANT_SHIP_ABILITY] = CzechCardNames[CardNames.MERCHANT_SHIP].singular;
CzechAbilityNames[AbilityNames.OUTPOST_ABILITY] = CzechCardNames[CardNames.OUTPOST].singular;
CzechAbilityNames[AbilityNames.TACTICIAN_ABILITY] = CzechCardNames[CardNames.TACTICIAN].singular;
CzechAbilityNames[AbilityNames.WHARF_ABILITY] = CzechCardNames[CardNames.WHARF].singular;
CzechAbilityNames[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = CzechCardNames[CardNames.HORSE_TRADERS].singular;
CzechAbilityNames[AbilityNames.DUCHESS_MAY_GAIN] = CzechCardNames[CardNames.DUCHESS].singular;
CzechAbilityNames[AbilityNames.FOOLS_GOLD_TRASH] = CzechCardNames[CardNames.FOOLS_GOLD].singular;
CzechAbilityNames[AbilityNames.NOBLE_BRIGAND] = CzechCardNames[CardNames.NOBLE_BRIGAND].singular;
CzechAbilityNames[AbilityNames.HAGGLER_ABILITY] = CzechCardNames[CardNames.HAGGLER].singular;
CzechAbilityNames[AbilityNames.INN_ABILITY] = CzechCardNames[CardNames.INN].singular;
CzechAbilityNames[AbilityNames.MANDARIN] = CzechCardNames[CardNames.MANDARIN].singular;
CzechAbilityNames[AbilityNames.FARMLAND] = CzechCardNames[CardNames.FARMLAND].singular;
CzechAbilityNames[AbilityNames.GAIN_CHEAPER] = "vezmi si levnější kartu";
CzechAbilityNames[AbilityNames.GAIN_TWO_RUINS] = CzechCardNames[CardNames.DEATH_CART].singular;
CzechAbilityNames[AbilityNames.HUNTING_GROUNDS] = CzechCardNames[CardNames.HUNTING_GROUNDS].singular;
CzechAbilityNames[AbilityNames.SQUIRE_GAIN_ATTACK] = CzechCardNames[CardNames.SQUIRE].singular;
CzechAbilityNames[AbilityNames.URCHIN_TRANSFORM] = CzechCardNames[CardNames.URCHIN].singular;
CzechAbilityNames[AbilityNames.FEODUM_GAIN_SILVERS] = CzechCardNames[CardNames.FEODUM].singular;
CzechAbilityNames[AbilityNames.DOCTOR_OVERPAY] = "Přeplať";
CzechAbilityNames[AbilityNames.HERALD_OVERPAY] = "Přeplať";
CzechAbilityNames[AbilityNames.MASTERPIECE_OVERPAY] = "Přeplať";
CzechAbilityNames[AbilityNames.STONEMASON_OVERPAY] = "Přeplať";
CzechAbilityNames[AbilityNames.EXCHANGE] = "Vyměň";
CzechAbilityNames[AbilityNames.SHUFFLE] = "Zamíchej";
CzechAbilityNames[AbilityNames.AMULET] = CzechCardNames[CardNames.AMULET].singular;
CzechAbilityNames[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = CzechCardNames[CardNames.CARAVAN_GUARD].singular;
CzechAbilityNames[AbilityNames.DUNGEON_ABILITY] = CzechCardNames[CardNames.DUNGEON].singular;
CzechAbilityNames[AbilityNames.GEAR_ABILITY] = CzechCardNames[CardNames.GEAR].singular;
CzechAbilityNames[AbilityNames.MESSENGER_GAIN] = CzechCardNames[CardNames.MESSENGER].singular;
CzechAbilityNames[AbilityNames.BRIDGE_TROLL_ABILITY] = CzechCardNames[CardNames.BRIDGE_TROLL].singular;
CzechAbilityNames[AbilityNames.HAUNTED_WOODS_DURATION] = "Vezmi si";
CzechAbilityNames[AbilityNames.HAUNTED_WOODS_TOPDECK] = "polož navrch";
CzechAbilityNames[AbilityNames.LOST_CITY] = CzechCardNames[CardNames.LOST_CITY].singular;
CzechAbilityNames[AbilityNames.WINE_MERCHANT_DISCARD] = CzechCardNames[CardNames.WINE_MERCHANT].singular;
CzechAbilityNames[AbilityNames.HIRELING] = CzechCardNames[CardNames.HIRELING].singular;
CzechAbilityNames[AbilityNames.SWAMP_HAG_DURATION] = CzechCardNames[CardNames.SWAMP_HAG].singular;
CzechAbilityNames[AbilityNames.TRASH_TOKEN_ABILITY] = CzechCardNames[CardNames.PLAN].singular;
CzechAbilityNames[AbilityNames.EMPORIUM_GAIN_POINTS] = CzechCardNames[CardNames.EMPORIUM].singular;
CzechAbilityNames[AbilityNames.ROCKS_GAINS_SILVER] = CzechCardNames[CardNames.ROCKS].singular;
CzechAbilityNames[AbilityNames.FORTUNE] = CzechCardNames[CardNames.FORTUNE].singular;
CzechAbilityNames[AbilityNames.CRUMBLING_CASTLE] = CzechCardNames[CardNames.CRUMBLING_CASTLE].singular;
CzechAbilityNames[AbilityNames.HAUNTED_CASTLE] = CzechCardNames[CardNames.HAUNTED_CASTLE].singular;
CzechAbilityNames[AbilityNames.SPRAWLING_CASTLE] = CzechCardNames[CardNames.SPRAWLING_CASTLE].singular;
CzechAbilityNames[AbilityNames.GRAND_CASTLE] = CzechCardNames[CardNames.GRAND_CASTLE].singular;
CzechAbilityNames[AbilityNames.ENCHANTRESS_ABILITY] = CzechCardNames[CardNames.ENCHANTRESS].singular;
CzechAbilityNames[AbilityNames.ENCHANTRESS_CANTRIP] = "+1 Karta, +1 Akce";
CzechAbilityNames[AbilityNames.TEMPLE] = CzechCardNames[CardNames.TEMPLE].singular;
CzechAbilityNames[AbilityNames.VILLA] = CzechCardNames[CardNames.VILLA].singular;
CzechAbilityNames[AbilityNames.ARCHIVE_ABILITY] = CzechCardNames[CardNames.ARCHIVE].singular;
CzechAbilityNames[AbilityNames.CAPITAL_TAKE_DEBT] = CzechCardNames[CardNames.CAPITAL].singular;
CzechAbilityNames[AbilityNames.CHARM] = CzechCardNames[CardNames.CHARM].singular;
CzechAbilityNames[AbilityNames.FORUM] = CzechCardNames[CardNames.FORUM].singular;
CzechAbilityNames[AbilityNames.HERMIT] = CzechCardNames[CardNames.HERMIT].singular;
CzechAbilityNames[AbilityNames.DONATE] = CzechCardNames[CardNames.DONATE].singular;
CzechAbilityNames[AbilityNames.TAX_RECEIVE] = CzechCardNames[CardNames.TAX].singular;
CzechAbilityNames[AbilityNames.DOMINATE] = CzechCardNames[CardNames.DOMINATE].singular;
CzechAbilityNames[AbilityNames.VP_TO_AQUEDUCT] = "VB na Akvadukt";
CzechAbilityNames[AbilityNames.VP_FROM_AQUEDUCT] = "VB z Akvaduktu";
CzechAbilityNames[AbilityNames.ARENA_DISCARD_ACTION] = CzechCardNames[CardNames.ARENA].singular;
CzechAbilityNames[AbilityNames.VP_FROM_BASILICA] = CzechCardNames[CardNames.BASILICA].singular;
CzechAbilityNames[AbilityNames.VP_FROM_BATHS] = CzechCardNames[CardNames.BATHS].singular;
CzechAbilityNames[AbilityNames.VP_FROM_BATTLEFIELD] = CzechCardNames[CardNames.BATTLEFIELD].singular;
CzechAbilityNames[AbilityNames.VP_FROM_COLONNADE] = CzechCardNames[CardNames.COLONNADE].singular;
CzechAbilityNames[AbilityNames.VP_TO_DEFILED_SHRINE] = "VP to Defiled Shrine";
CzechAbilityNames[AbilityNames.VP_FROM_DEFILED_SHRINE] = "VP from Defiled Shrine";
CzechAbilityNames[AbilityNames.VP_FROM_LABYRINTH] = CzechCardNames[CardNames.LABYRINTH].singular;
CzechAbilityNames[AbilityNames.MOUNTAIN_PASS_BIDDING] = CzechCardNames[CardNames.MOUNTAIN_PASS].singular;
CzechAbilityNames[AbilityNames.TOMB] = CzechCardNames[CardNames.TOMB].singular;
CzechAbilityNames[AbilityNames.LIGHTHOUSE_COIN] = CzechCardNames[CardNames.LIGHTHOUSE].singular;
CzechAbilityNames[AbilityNames.REACTION] = "Reaguje";
CzechAbilityNames[AbilityNames.CALL_GUIDE] = "vyvolej " + CzechCardNames[CardNames.GUIDE].singular;
CzechAbilityNames[AbilityNames.CALL_RATCATCHER] = "vyvolej " + CzechCardNames[CardNames.RATCATCHER].singular;
CzechAbilityNames[AbilityNames.CALL_TRANSMOGRIFY] = "vyvolej " + CzechCardNames[CardNames.TRANSMOGRIFY].singular;
CzechAbilityNames[AbilityNames.CALL_TEACHER] = "vyvolej " + CzechCardNames[CardNames.TEACHER].singular;
CzechAbilityNames[AbilityNames.CALL_DUPLICATE] = "vyvolej " + CzechCardNames[CardNames.DUPLICATE].singular;
CzechAbilityNames[AbilityNames.CALL_COIN] = "vyvolej " + CzechCardNames[CardNames.COIN_OF_THE_REALM].singular;
CzechAbilityNames[AbilityNames.CALL_ROYAL_CARRIAGE] = "vyvolej " + CzechCardNames[CardNames.ROYAL_CARRIAGE].singular;
CzechAbilityNames[AbilityNames.START_OF_TURN] = "začni svůj tah";
CzechAbilityNames[AbilityNames.START_BUY_PHASE] = "START_BUY_PHASE";
CzechAbilityNames[AbilityNames.END_OF_BUY_PHASE] = "END_OF_BUY_PHASE";
CzechAbilityNames[AbilityNames.BETWEEN_TURNS] = "BETWEEN_TURNS";
CzechAbilityNames[AbilityNames.END_OF_TURN] = "END_OF_TURN";
CzechAbilityNames[AbilityNames.SUMMON_PLAY] = CzechCardNames[CardNames.SUMMON].singular;
CzechAbilityNames[AbilityNames.CARAVAN_GUARD_REACTION] = CzechCardNames[CardNames.CARAVAN_GUARD].singular;
CzechAbilityNames[AbilityNames.TRAVELLING_FAIR_REACTION] = CzechCardNames[CardNames.TRAVELLING_FAIR].singular;
CzechAbilityNames[AbilityNames.MOAT_REACTION] = CzechCardNames[CardNames.MOAT].singular;
CzechAbilityNames[AbilityNames.HORSE_TRADERS_REACTION] = CzechCardNames[CardNames.HORSE_TRADERS].singular;
CzechAbilityNames[AbilityNames.BEGGAR_REACTION] = CzechCardNames[CardNames.BEGGAR].singular;
CzechAbilityNames[AbilityNames.MARKET_SQUARE_REACTION] = CzechCardNames[CardNames.MARKET_SQUARE].singular;
CzechAbilityNames[AbilityNames.TRADER_REACTION] = CzechCardNames[CardNames.TRADER].singular;
CzechAbilityNames[AbilityNames.DIPLOMAT_REACTION] = CzechCardNames[CardNames.DIPLOMAT].singular;
CzechAbilityNames[AbilityNames.TOPDECK_REACTION] = "polož navrch";
CzechAbilityNames[AbilityNames.WATCHTOWER_TRASH_REACTION] = "vyhoď";
CzechAbilityNames[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "polož navrch";
CzechAbilityNames[AbilityNames.PRINCE_PLAY] = CzechCardNames[CardNames.PRINCE].singular;
CzechAbilityNames[AbilityNames.PRINCE_SET_ASIDE] = CzechCardNames[CardNames.PRINCE].singular;
CzechAbilityNames[AbilityNames.DELUDED_RETURNED] = "return state";
CzechAbilityNames[AbilityNames.ENVIOUS_RETURNED] = "return state";
CzechAbilityNames[AbilityNames.RECEIVE_BOON] = "vezmi si Výhodu";
CzechAbilityNames[AbilityNames.COBBLER_ABILITY] = CzechCardNames[CardNames.COBBLER].singular;
CzechAbilityNames[AbilityNames.CRYPT_ABILITY] = CzechCardNames[CardNames.CRYPT].singular;
CzechAbilityNames[AbilityNames.DEN_OF_SIN_ABILITY] = CzechCardNames[CardNames.DEN_OF_SIN].singular;
CzechAbilityNames[AbilityNames.FAITHFUL_HOUND] = CzechCardNames[CardNames.FAITHFUL_HOUND].singular;
CzechAbilityNames[AbilityNames.FAITHFUL_HOUND_RETURN] = CzechCardNames[CardNames.FAITHFUL_HOUND].singular;
CzechAbilityNames[AbilityNames.GHOST_TOWN_ABILITY] = CzechCardNames[CardNames.GHOST_TOWN].singular;
CzechAbilityNames[AbilityNames.GUARDIAN_COIN] = CzechCardNames[CardNames.GUARDIAN].singular;
CzechAbilityNames[AbilityNames.RAIDER_ABILITY] = CzechCardNames[CardNames.RAIDER].singular;
CzechAbilityNames[AbilityNames.SECRET_CAVE] = CzechCardNames[CardNames.SECRET_CAVE].singular;
CzechAbilityNames[AbilityNames.CEMETERY] = CzechCardNames[CardNames.CEMETERY].singular;
CzechAbilityNames[AbilityNames.HAUNTED_MIRROR] = CzechCardNames[CardNames.HAUNTED_MIRROR].singular;
CzechAbilityNames[AbilityNames.GHOST] = CzechCardNames[CardNames.GHOST].singular;
CzechAbilityNames[AbilityNames.REMOVE_FLIPPED_STATUS] = "X";
CzechAbilityNames[AbilityNames.LOST_IN_THE_WOODS] = CzechCardNames[CardNames.LOST_IN_THE_WOODS].singular;
CzechAbilityNames[AbilityNames.BLESSED_VILLAGE] = CzechCardNames[CardNames.BLESSED_VILLAGE].singular;
CzechAbilityNames[AbilityNames.FORTRESS] = CzechCardNames[CardNames.FORTRESS].singular;
CzechAbilityNames[AbilityNames.RECEIVE_HEX] = "vezmi si Prokletí";
CzechAbilityNames[AbilityNames.HOVEL_TRASH] = CzechCardNames[CardNames.HOVEL].singular;
CzechAbilityNames[AbilityNames.TUNNEL_REVEAL] = CzechCardNames[CardNames.TUNNEL].singular;
CzechAbilityNames[AbilityNames.CHANGELING_MAY_EXCHANGE] = CzechCardNames[CardNames.CHANGELING].singular;
CzechAbilityNames[AbilityNames.DUCAT] = CzechCardNames[CardNames.DUCAT].singular;
CzechAbilityNames[AbilityNames.CARGO_SHIP_SET_ASIDE] = CzechCardNames[CardNames.CARGO_SHIP].singular;
CzechAbilityNames[AbilityNames.CARGO_SHIP_RETURN] = CzechCardNames[CardNames.CARGO_SHIP].singular;
CzechAbilityNames[AbilityNames.IMPROVE] = CzechCardNames[CardNames.IMPROVE].singular;
CzechAbilityNames[AbilityNames.PRIEST] = CzechCardNames[CardNames.PRIEST].singular;
CzechAbilityNames[AbilityNames.RESEARCH] = CzechCardNames[CardNames.RESEARCH].singular;
CzechAbilityNames[AbilityNames.SILK_MERCHANT] = CzechCardNames[CardNames.SILK_MERCHANT].singular;
CzechAbilityNames[AbilityNames.LACKEYS] = CzechCardNames[CardNames.LACKEYS].singular;
CzechAbilityNames[AbilityNames.SPICES] = CzechCardNames[CardNames.SPICES].singular;
CzechAbilityNames[AbilityNames.KEY] = CzechCardNames[CardNames.KEY].singular;
CzechAbilityNames[AbilityNames.TREASURE_CHEST] = CzechCardNames[CardNames.TREASURE_CHEST].singular;
CzechAbilityNames[AbilityNames.FLAG_BEARER] = CzechCardNames[CardNames.FLAG_BEARER].singular;
CzechAbilityNames[AbilityNames.CATHEDRAL] = CzechCardNames[CardNames.CATHEDRAL].singular;
CzechAbilityNames[AbilityNames.CITY_GATE] = CzechCardNames[CardNames.CITY_GATE].singular;
CzechAbilityNames[AbilityNames.PAGEANT] = CzechCardNames[CardNames.PAGEANT].singular;
CzechAbilityNames[AbilityNames.SEWERS_MAY_TRASH] = CzechCardNames[CardNames.SEWERS].singular;
CzechAbilityNames[AbilityNames.EXPLORATION] = CzechCardNames[CardNames.EXPLORATION].singular;
CzechAbilityNames[AbilityNames.FAIR] = CzechCardNames[CardNames.FAIR].singular;
CzechAbilityNames[AbilityNames.SILOS] = CzechCardNames[CardNames.SILOS].singular;
CzechAbilityNames[AbilityNames.ACADEMY] = CzechCardNames[CardNames.ACADEMY].singular;
CzechAbilityNames[AbilityNames.GUILDHALL] = CzechCardNames[CardNames.GUILDHALL].singular;
CzechAbilityNames[AbilityNames.PIAZZA] = CzechCardNames[CardNames.PIAZZA].singular;
CzechAbilityNames[AbilityNames.BARRACKS] = CzechCardNames[CardNames.BARRACKS].singular;
CzechAbilityNames[AbilityNames.CROP_ROTATION] = CzechCardNames[CardNames.CROP_ROTATION].singular;
CzechAbilityNames[AbilityNames.INNOVATION] = CzechCardNames[CardNames.INNOVATION].singular;
CzechAbilityNames[AbilityNames.CITADEL] = CzechCardNames[CardNames.CITADEL].singular;
CzechAbilityNames[AbilityNames.SINISTER_PLOT] = CzechCardNames[CardNames.SINISTER_PLOT].singular;
CzechAbilityNames[AbilityNames.ENCAMPMENT_RETURNED] = CzechCardNames[CardNames.ENCAMPMENT].singular;
CzechAbilityNames[AbilityNames.EMBARGO_GAIN_CURSE] = CzechCardNames[CardNames.EMBARGO].singular;
CzechAbilityNames[AbilityNames.POSSESSION_GAIN_INSTEAD] = CzechCardNames[CardNames.POSSESSION].singular;
CzechAbilityNames[AbilityNames.TRADE_ROUTE_MOVE_TOKEN] = CzechCardNames[CardNames.TRADE_ROUTE].singular;
CzechAbilityNames[AbilityNames.CAPTAIN] = CzechCardNames[CardNames.CAPTAIN].singular;
CzechAbilityNames[AbilityNames.CHURCH] = CzechCardNames[CardNames.CHURCH].singular;
CzechAbilityNames[AbilityNames.POSSESSION_SETS_ASIDE] = CzechCardNames[CardNames.POSSESSION].singular;
CzechAbilityNames[AbilityNames.MINT] = CzechCardNames[CardNames.MINT].singular;
CzechAbilityNames[AbilityNames.SLEIGH_TOPDECK_REACTION] = "polož navrch";
CzechAbilityNames[AbilityNames.SLEIGH_PUT_IN_HAND_REACTION] = "vezmi si do ruky";
CzechAbilityNames[AbilityNames.BLACK_CAT_REACTION] = CzechCardNames[CardNames.BLACK_CAT].singular;
CzechAbilityNames[AbilityNames.DISCARD_FROM_EXILE] = "odlož z Vyhoštění";
CzechAbilityNames[AbilityNames.EXILE_BY_NAME] = "vyhosti";
CzechAbilityNames[AbilityNames.SHEEPDOG_REACTION] = CzechCardNames[CardNames.SHEEPDOG].singular;
CzechAbilityNames[AbilityNames.CAVALRY] = CzechCardNames[CardNames.CAVALRY].singular;
CzechAbilityNames[AbilityNames.HOSTELRY] = CzechCardNames[CardNames.HOSTELRY].singular;
CzechAbilityNames[AbilityNames.VILLAGE_GREEN_VILLAGE] = CzechCardNames[CardNames.VILLAGE_GREEN].singular;
CzechAbilityNames[AbilityNames.VILLAGE_GREEN_PLAY] = CzechCardNames[CardNames.VILLAGE_GREEN].singular;
CzechAbilityNames[AbilityNames.BARGE] = CzechCardNames[CardNames.BARGE].singular;
CzechAbilityNames[AbilityNames.FALCONER_REACTION] = CzechCardNames[CardNames.FALCONER].singular;
CzechAbilityNames[AbilityNames.GATEKEEPER_EXILE] = CzechCardNames[CardNames.GATEKEEPER].singular;
CzechAbilityNames[AbilityNames.GATEKEEPER_DURATION] = CzechCardNames[CardNames.GATEKEEPER].singular;
CzechAbilityNames[AbilityNames.LIVERY] = CzechCardNames[CardNames.LIVERY].singular;
CzechAbilityNames[AbilityNames.MASTERMIND] = CzechCardNames[CardNames.MASTERMIND].singular;
CzechAbilityNames[AbilityNames.DELAY_PLAY] = CzechCardNames[CardNames.DELAY].singular;
CzechAbilityNames[AbilityNames.INVEST] = CzechCardNames[CardNames.INVEST].singular;
CzechAbilityNames[AbilityNames.REAP_PLAY] = CzechCardNames[CardNames.REAP].singular;
CzechAbilityNames[AbilityNames.PLUS_CARD_TOKEN] = "+Karta";
CzechAbilityNames[AbilityNames.PLUS_BUY_TOKEN] = "+Nákup";
CzechAbilityNames[AbilityNames.PLUS_ACTION_TOKEN] = "+Akce";
CzechAbilityNames[AbilityNames.PLUS_COIN_TOKEN] = "+Mince";
CzechAbilityNames[AbilityNames.KILN] = CzechCardNames[CardNames.KILN].singular;
CzechAbilityNames[AbilityNames.TURTLE_PLAY] = CzechCardNames[CardNames.WAY_OF_THE_TURTLE].singular;
CzechAbilityNames[AbilityNames.GIVE_PLUS_CARDS] = CzechAbilityNames[AbilityNames.PLUS_CARD_TOKEN];
CzechAbilityNames[AbilityNames.GIVE_PLUS_BUYS] = CzechAbilityNames[AbilityNames.PLUS_BUY_TOKEN];
CzechAbilityNames[AbilityNames.GIVE_PLUS_ACTIONS] = CzechAbilityNames[AbilityNames.PLUS_ACTION_TOKEN];
CzechAbilityNames[AbilityNames.GIVE_PLUS_COINS] = CzechAbilityNames[AbilityNames.PLUS_COIN_TOKEN];