"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ComplexQuestion = function (_Question) {
    _inherits(ComplexQuestion, _Question);

    function ComplexQuestion(game, index, description, subquestions) {
        _classCallCheck(this, ComplexQuestion);

        var _this = _possibleConstructorReturn(this, (ComplexQuestion.__proto__ || Object.getPrototypeOf(ComplexQuestion)).call(this, game, index, description));

        _this.questionClass = QuestionClasses.COMPLEX_QUESTION;
        _this.subquestions = subquestions;
        _this.subquestions.forEach(function (q) {
            q.parentQuestion = _this;
        });
        return _this;
    }

    _createClass(ComplexQuestion, [{
        key: "execute",
        value: function execute(game) {
            var isRoot = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

            _get(ComplexQuestion.prototype.__proto__ || Object.getPrototypeOf(ComplexQuestion.prototype), "execute", this).call(this, game, isRoot);
            this.subquestions.forEach(function (q) {
                return q.execute(game, false);
            });
        }
    }, {
        key: "setStory",
        value: function setStory() {
            if (this.subquestions.length > 0) this.subquestions[0].setStory();
        }
    }, {
        key: "getAnswer",
        value: function getAnswer() {
            if (this.type === QuestionTypes.COMPLEX_OR) {
                if (this.accumulatedAnswers.length === 0) return null;
                var index = this.accumulatedAnswers[0];
                var subquestion = this.subquestions[index];
                return [index].concat(subquestion.getAnswer());
            } else if (this.type === QuestionTypes.COMPLEX_AND) {
                return this.subquestions.map(function (subquestion, index) {
                    var answer = subquestion.getAnswer();
                    return [answer.length].concat(answer);
                }).reduce(function (a, b) {
                    return a.concat(b);
                }, []);
            }
        }
    }, {
        key: "shouldStackCards",
        get: function get() {
            return this.subquestions.some(function (q) {
                return q.shouldStackCards;
            });
        }
    }], [{
        key: "parse",
        value: function parse(game, reader, questionIndex) {
            var questions = [];
            var questionDescription = QuestionDescription.parse(reader);
            var size = reader.readInt();

            var processors = [ChoiceQuestion, NumberQuestion, ComplexQuestion, DelayedQuestion, NameQuestion];
            for (var i = 0; i < size; i++) {
                var questionType = reader.readInt();
                var question = processors[questionType].parse(game, reader, questionIndex.concat([i]));
                questions.push(question);
            }
            return new ComplexQuestion(game, questionIndex, questionDescription, questions);
        }
    }]);

    return ComplexQuestion;
}(Question);