"use strict";

var EnglishDeclineButtons = {};

EnglishDeclineButtons[DeclineButtonIds.END_ACTION_PHASE] = "End Actions";
EnglishDeclineButtons[DeclineButtonIds.END_BUY_PHASE] = "End Buys";
EnglishDeclineButtons[DeclineButtonIds.END_TURN] = "End Turn";
EnglishDeclineButtons[DeclineButtonIds.DONE_PLAYING_TREASURES] = "No Treasures";
EnglishDeclineButtons[DeclineButtonIds.DONE_REACTING] = "Continue";
EnglishDeclineButtons[DeclineButtonIds.MINING_VILLAGE_DONT_TRASH] = "Don't Trash";
EnglishDeclineButtons[DeclineButtonIds.PEARL_DIVER_DONT_TOPDECK] = "Don't Topdeck";
EnglishDeclineButtons[DeclineButtonIds.HARBINGER_DONT_TOPDECK] = "Don't Topdeck";
EnglishDeclineButtons[DeclineButtonIds.THRONE_ROOM_DONT] = "Don't Throne";
EnglishDeclineButtons[DeclineButtonIds.SMUGGLERS_GAIN_IMPOSSIBLE_COPY] = "Don't Gain";
EnglishDeclineButtons[DeclineButtonIds.EXPLORER_GAIN_SILVER] = "Gain Silver";
EnglishDeclineButtons[DeclineButtonIds.HERBALIST_DONT_TOPDECK] = "Don't Topdeck";
EnglishDeclineButtons[DeclineButtonIds.MINT_DONT_COPY] = "Don't Copy";
EnglishDeclineButtons[DeclineButtonIds.HAMLET_DONT_DISCARD] = "Don't Discard";
EnglishDeclineButtons[DeclineButtonIds.TOURNAMENT_DONT_REVEAL] = "Don't Reveal";
EnglishDeclineButtons[DeclineButtonIds.YOUNG_WITCH_DONT_REVEAL_BANE] = "Don't Reveal";
EnglishDeclineButtons[DeclineButtonIds.IGG_NO_COPPER] = "No Copper";
EnglishDeclineButtons[DeclineButtonIds.COUNTERFEIT_NO_TREASURE] = "Don't Counterfeit";
EnglishDeclineButtons[DeclineButtonIds.CULTIST_DONT_PLAY_ANOTHER] = "Don't chain Cultists";
EnglishDeclineButtons[DeclineButtonIds.IRONMONGER_KEEP] = "Keep on top";
EnglishDeclineButtons[DeclineButtonIds.PROCESSION_DONT] = "Don't Process";
EnglishDeclineButtons[DeclineButtonIds.PLAZA_NO_DISCARD] = "Don't Discard";
EnglishDeclineButtons[DeclineButtonIds.DONT_OVERPAY] = "Don't Overpay";
EnglishDeclineButtons[DeclineButtonIds.DONE_SETTING_ASIDE] = "Done Setting Aside";
EnglishDeclineButtons[DeclineButtonIds.ARTIFICER_DONT] = "Don't Discard";
EnglishDeclineButtons[DeclineButtonIds.ENGINEER_DONT_TRASH] = "Don't Trash";
EnglishDeclineButtons[DeclineButtonIds.ENCAMPMENT_DONT_REVEAL] = "Don't Reveal";
EnglishDeclineButtons[DeclineButtonIds.SETTLERS_DONT_TAKE_COPPER] = "Don't take Copper";
EnglishDeclineButtons[DeclineButtonIds.GLADIATOR_DONT_REVEAL] = "Don't Reveal";
EnglishDeclineButtons[DeclineButtonIds.CROWN_DONT] = "Don't Crown";
EnglishDeclineButtons[DeclineButtonIds.LEGIONARY_DONT_REVEAL] = "Don't Reveal";
EnglishDeclineButtons[DeclineButtonIds.SHUFFLE_ALL] = "Shuffle All";
EnglishDeclineButtons[DeclineButtonIds.PASS] = "Pass";
EnglishDeclineButtons[DeclineButtonIds.DONT_DISCARD] = "Don't Discard";
EnglishDeclineButtons[DeclineButtonIds.DONT_TRASH] = "Don't Trash";
EnglishDeclineButtons[DeclineButtonIds.VAULT_NO_DISCARD] = "Don't Discard";
EnglishDeclineButtons[DeclineButtonIds.DONT_BUY] = "Don't Buy";
EnglishDeclineButtons[DeclineButtonIds.DONT_GAIN] = "Don't Gain";
EnglishDeclineButtons[DeclineButtonIds.DISCARD_ALL] = "Discard All";
EnglishDeclineButtons[DeclineButtonIds.TORTURER_FAIL_CURSE] = "Gain Curse";
EnglishDeclineButtons[DeclineButtonIds.TORTURER_GAIN_CURSE] = "Gain Curse";
EnglishDeclineButtons[DeclineButtonIds.BUSTLING_VILLAGE_NO_SETTLERS] = "Don't take Settlers";
EnglishDeclineButtons[DeclineButtonIds.PRINCE_NO] = "Don't Set Aside";
EnglishDeclineButtons[DeclineButtonIds.SAUNA_DONT_CHAIN] = "Don't chain";
EnglishDeclineButtons[DeclineButtonIds.AVANTO_DONT_CHAIN] = "Don't chain";
EnglishDeclineButtons[DeclineButtonIds.CONCLAVE_DONT] = "Don't play";
EnglishDeclineButtons[DeclineButtonIds.IMP_DONT] = "Don't play";
EnglishDeclineButtons[DeclineButtonIds.NECROMANCER_DONT] = "Don't play";
EnglishDeclineButtons[DeclineButtonIds.NO_THANKS] = "No thanks";
EnglishDeclineButtons[DeclineButtonIds.DONT_EXILE] = "Don't Exile";
EnglishDeclineButtons[DeclineButtonIds.KINGS_COURT_DONT] = "Don't King";
EnglishDeclineButtons[DeclineButtonIds.MASTERMIND_DONT] = "Don't Mastermind";
EnglishDeclineButtons[DeclineButtonIds.DELAY_DONT] = "Don't Delay";
EnglishDeclineButtons[DeclineButtonIds.TOIL_DONT] = "Don't Toil";
EnglishDeclineButtons[DeclineButtonIds.MARCH_DONT] = "Don't March";
EnglishDeclineButtons[DeclineButtonIds.DONT_TOPDECK] = "Don't Topdeck";
EnglishDeclineButtons[DeclineButtonIds.RANDOM_ORDER] = "Random Order";
EnglishDeclineButtons[DeclineButtonIds.HUNTING_LODGE_KEEP] = "Keep";
EnglishDeclineButtons[DeclineButtonIds.TIARA_NO_TREASURE] = "Don't play";
EnglishDeclineButtons[DeclineButtonIds.SWAP_DONT] = "Don't Swap";
EnglishDeclineButtons[DeclineButtonIds.DONT_PLAY] = "Don't Play";
EnglishDeclineButtons[DeclineButtonIds.DONT_REVEAL] = "Don't Reveal";
EnglishDeclineButtons[DeclineButtonIds.DONT_ROTATE] = "Don't Rotate";
EnglishDeclineButtons[DeclineButtonIds.DONT] = "Don't";
EnglishDeclineButtons[DeclineButtonIds.SPEND_FAVOR] = "Spend Favor";
EnglishDeclineButtons[DeclineButtonIds.CONTINUE] = "Continue";
EnglishDeclineButtons[DeclineButtonIds.ORB_DONT] = "+1 Buy, +3 Coins";
EnglishDeclineButtons[DeclineButtonIds.DONT_PUZZLE_BOX] = "Don't Set Aside";
EnglishDeclineButtons[DeclineButtonIds.DONT_INSPIRING] = "Don't Play";
EnglishDeclineButtons[DeclineButtonIds.DONT_JOUST] = "Don't Set Aside";
EnglishDeclineButtons[DeclineButtonIds.SNAKE_WITCH_DONT_RETURN] = "Don't return";

EnglishDeclineButtons[DoneButtonIds.DONE_TRASHING] = "Confirm Trashing";
EnglishDeclineButtons[DoneButtonIds.DONE_DISCARDING] = "Confirm Discarding";
EnglishDeclineButtons[DoneButtonIds.DONE_ORDERING] = "Done Ordering";
EnglishDeclineButtons[DoneButtonIds.DONE_RETURNING] = "Done Returning";
EnglishDeclineButtons[DoneButtonIds.DONE_SETTING_ASIDE] = "Done Setting Aside";
EnglishDeclineButtons[DoneButtonIds.DONE_SELECTING] = "Done Selecting";
EnglishDeclineButtons[DoneButtonIds.DONE_CHOOSING] = "Done Choosing";
EnglishDeclineButtons[DoneButtonIds.TRASH] = "Trash";
EnglishDeclineButtons[DoneButtonIds.DISCARD] = "Discard";
EnglishDeclineButtons[DoneButtonIds.PASS] = "Pass";
EnglishDeclineButtons[DoneButtonIds.TOPDECK] = "Topdeck";
EnglishDeclineButtons[DoneButtonIds.BOTTOMDECK] = "Bottomdeck";
EnglishDeclineButtons[DoneButtonIds.EXILE] = "Exile";
EnglishDeclineButtons[DoneButtonIds.OVERPAY] = "Overpay";

var EnglishGameButtons = {};

EnglishGameButtons[GameButtons.AUTOPLAY_TREASURES] = "Autoplay Treasures";
EnglishGameButtons[GameButtons.USE_VILLAGER] = "Use Villager";
EnglishGameButtons[GameButtons.USE_FAVOR_TOPDECK] = "Use Favor to topdeck";
EnglishGameButtons[GameButtons.USE_FAVOR_DISCARD] = "Use Favor to discard";
EnglishGameButtons[GameButtons.COFFERS] = "Use Coffers";
EnglishGameButtons[GameButtons.PAY_OFF_DEBT] = "Pay off debt";

var EnglishDelayedQuestionButtons = {};

EnglishDelayedQuestionButtons[CardNames.ARTIFICER] = "Use Artificer";
EnglishDelayedQuestionButtons[CardNames.BUSTLING_VILLAGE] = "Take Settlers";
EnglishDelayedQuestionButtons[CardNames.CULTIST] = "Play Cultist";
EnglishDelayedQuestionButtons[CardNames.ENGINEER] = "Trash Engineer";
EnglishDelayedQuestionButtons[CardNames.HAMLET] = "Discard";
EnglishDelayedQuestionButtons[CardNames.HARBINGER] = "Topdeck";
EnglishDelayedQuestionButtons[CardNames.ILL_GOTTEN_GAINS] = "Gain Copper";
EnglishDelayedQuestionButtons[CardNames.IRONMONGER] = "Discard";
EnglishDelayedQuestionButtons[CardNames.MINING_VILLAGE] = "Trash";
EnglishDelayedQuestionButtons[CardNames.PEARL_DIVER] = "Topdeck";
EnglishDelayedQuestionButtons[CardNames.PLAZA] = "Discard";
EnglishDelayedQuestionButtons[CardNames.SETTLERS] = "Take Copper";
EnglishDelayedQuestionButtons[CardNames.HUNTING_LODGE] = "Discard & Draw 5";
EnglishDelayedQuestionButtons[CardNames.SNAKE_WITCH] = "Return & Curse";
EnglishDelayedQuestionButtons[CardNames.RUSTIC_VILLAGE] = "Discard 2 to draw 1";
EnglishDelayedQuestionButtons[CardNames.GOLD_MINE] = "Gold & 4 Debt";

var EnglishCleanupActions = {};

EnglishCleanupActions[CleanupActions.TOPDECK] = new CleanupButtonText("Topdeck", "Discard");
EnglishCleanupActions[CleanupActions.EXCHANGE] = new CleanupButtonText("Exchange", "Keep");