"use strict";

var SpanishAbilityNames = {};

SpanishAbilityNames[AbilityNames.BUY] = "a";
SpanishAbilityNames[AbilityNames.PLAY_ATTACK] = "juega Ataque";
SpanishAbilityNames[AbilityNames.DRAW_INSTRUCTIONS] = "b";
SpanishAbilityNames[AbilityNames.PLAY_INSTRUCTIONS] = "juega";
SpanishAbilityNames[AbilityNames.RESOLVE_EVENT] = "c";
SpanishAbilityNames[AbilityNames.GAIN_BY_NAME] = "d";

SpanishAbilityNames[AbilityNames.FISHING_VILLAGE_ABILITY] = SpanishCardNames[CardNames.FISHING_VILLAGE].singular;
SpanishAbilityNames[AbilityNames.REMOVE_COST_REDUCTION] = "e";
SpanishAbilityNames[AbilityNames.HAVEN_ABILITY] = SpanishCardNames[CardNames.HAVEN].singular;
SpanishAbilityNames[AbilityNames.SAVE_RETURN_TO_HAND] = SpanishCardNames[CardNames.SAVE].singular;
SpanishAbilityNames[AbilityNames.CARAVAN_ABILITY] = SpanishCardNames[CardNames.CARAVAN].singular;
SpanishAbilityNames[AbilityNames.MERCHANT_SHIP_ABILITY] = SpanishCardNames[CardNames.MERCHANT_SHIP].singular;
SpanishAbilityNames[AbilityNames.OUTPOST_ABILITY] = SpanishCardNames[CardNames.OUTPOST].singular;
SpanishAbilityNames[AbilityNames.TACTICIAN_ABILITY] = SpanishCardNames[CardNames.TACTICIAN].singular;
SpanishAbilityNames[AbilityNames.WHARF_ABILITY] = SpanishCardNames[CardNames.WHARF].singular;
SpanishAbilityNames[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = SpanishCardNames[CardNames.HORSE_TRADERS].singular;
SpanishAbilityNames[AbilityNames.DUCHESS_MAY_GAIN] = SpanishCardNames[CardNames.DUCHESS].singular;
SpanishAbilityNames[AbilityNames.FOOLS_GOLD_TRASH] = SpanishCardNames[CardNames.FOOLS_GOLD].singular;
SpanishAbilityNames[AbilityNames.NOBLE_BRIGAND] = SpanishCardNames[CardNames.NOBLE_BRIGAND].singular;
SpanishAbilityNames[AbilityNames.HAGGLER_ABILITY] = SpanishCardNames[CardNames.HAGGLER].singular;
SpanishAbilityNames[AbilityNames.INN_ABILITY] = SpanishCardNames[CardNames.INN].singular;
SpanishAbilityNames[AbilityNames.MANDARIN] = SpanishCardNames[CardNames.MANDARIN].singular;
SpanishAbilityNames[AbilityNames.FARMLAND] = SpanishCardNames[CardNames.FARMLAND].singular;
SpanishAbilityNames[AbilityNames.GAIN_CHEAPER] = "Gana una carta más barata";
SpanishAbilityNames[AbilityNames.GAIN_TWO_RUINS] = SpanishCardNames[CardNames.DEATH_CART].singular;
SpanishAbilityNames[AbilityNames.HUNTING_GROUNDS] = SpanishCardNames[CardNames.HUNTING_GROUNDS].singular;
SpanishAbilityNames[AbilityNames.SQUIRE_GAIN_ATTACK] = SpanishCardNames[CardNames.SQUIRE].singular;
SpanishAbilityNames[AbilityNames.URCHIN_TRANSFORM] = SpanishCardNames[CardNames.URCHIN].singular;
SpanishAbilityNames[AbilityNames.FEODUM_GAIN_SILVERS] = SpanishCardNames[CardNames.FEODUM].singular;
SpanishAbilityNames[AbilityNames.DOCTOR_OVERPAY] = "Pagar de más";
SpanishAbilityNames[AbilityNames.RESOLVE_DOCTOR_OVERPAY] = SpanishCardNames[CardNames.DOCTOR].singular;
SpanishAbilityNames[AbilityNames.HERALD_OVERPAY] = "Pagar de más";
SpanishAbilityNames[AbilityNames.RESOLVE_HERALD_OVERPAY] = SpanishCardNames[CardNames.HERALD].singular;
SpanishAbilityNames[AbilityNames.MASTERPIECE_OVERPAY] = "Pagar de más";
SpanishAbilityNames[AbilityNames.RESOLVE_MASTERPIECE_OVERPAY] = SpanishCardNames[CardNames.MASTERPIECE].singular;
SpanishAbilityNames[AbilityNames.STONEMASON_OVERPAY] = "Pagar de más";
SpanishAbilityNames[AbilityNames.RESOLVE_STONEMASON_OVERPAY] = SpanishCardNames[CardNames.STONEMASON].singular;
SpanishAbilityNames[AbilityNames.EXCHANGE] = "Intercambiar";
SpanishAbilityNames[AbilityNames.SHUFFLE] = "Barajar";
SpanishAbilityNames[AbilityNames.AMULET] = SpanishCardNames[CardNames.AMULET].singular;
SpanishAbilityNames[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = SpanishCardNames[CardNames.CARAVAN_GUARD].singular;
SpanishAbilityNames[AbilityNames.DUNGEON_ABILITY] = SpanishCardNames[CardNames.DUNGEON].singular;
SpanishAbilityNames[AbilityNames.GEAR_ABILITY] = SpanishCardNames[CardNames.GEAR].singular;
SpanishAbilityNames[AbilityNames.MESSENGER_GAIN] = SpanishCardNames[CardNames.MESSENGER].singular;
SpanishAbilityNames[AbilityNames.BRIDGE_TROLL_ABILITY] = SpanishCardNames[CardNames.BRIDGE_TROLL].singular;
SpanishAbilityNames[AbilityNames.HAUNTED_WOODS_DURATION] = "Robar";
SpanishAbilityNames[AbilityNames.HAUNTED_WOODS_TOPDECK] = "Poner sobre el mazo";
SpanishAbilityNames[AbilityNames.LOST_CITY] = SpanishCardNames[CardNames.LOST_CITY].singular;
SpanishAbilityNames[AbilityNames.WINE_MERCHANT_DISCARD] = SpanishCardNames[CardNames.WINE_MERCHANT].singular;
SpanishAbilityNames[AbilityNames.HIRELING] = SpanishCardNames[CardNames.HIRELING].singular;
SpanishAbilityNames[AbilityNames.SWAMP_HAG_DURATION] = SpanishCardNames[CardNames.SWAMP_HAG].singular;
SpanishAbilityNames[AbilityNames.TRASH_TOKEN_ABILITY] = SpanishCardNames[CardNames.PLAN].singular;
SpanishAbilityNames[AbilityNames.EMPORIUM_GAIN_POINTS] = SpanishCardNames[CardNames.EMPORIUM].singular;
SpanishAbilityNames[AbilityNames.ROCKS_GAINS_SILVER] = SpanishCardNames[CardNames.ROCKS].singular;
SpanishAbilityNames[AbilityNames.FORTUNE] = SpanishCardNames[CardNames.FORTUNE].singular;
SpanishAbilityNames[AbilityNames.CRUMBLING_CASTLE] = SpanishCardNames[CardNames.CRUMBLING_CASTLE].singular;
SpanishAbilityNames[AbilityNames.HAUNTED_CASTLE] = SpanishCardNames[CardNames.HAUNTED_CASTLE].singular;
SpanishAbilityNames[AbilityNames.SPRAWLING_CASTLE] = SpanishCardNames[CardNames.SPRAWLING_CASTLE].singular;
SpanishAbilityNames[AbilityNames.GRAND_CASTLE] = SpanishCardNames[CardNames.GRAND_CASTLE].singular;
SpanishAbilityNames[AbilityNames.ENCHANTRESS_ABILITY] = SpanishCardNames[CardNames.ENCHANTRESS].singular;
SpanishAbilityNames[AbilityNames.ENCHANTRESS_CANTRIP] = "+1 Carta, +1 Acción";
SpanishAbilityNames[AbilityNames.TEMPLE] = SpanishCardNames[CardNames.TEMPLE].singular;
SpanishAbilityNames[AbilityNames.VILLA] = SpanishCardNames[CardNames.VILLA].singular;
SpanishAbilityNames[AbilityNames.ARCHIVE_ABILITY] = SpanishCardNames[CardNames.ARCHIVE].singular;
SpanishAbilityNames[AbilityNames.CAPITAL_TAKE_DEBT] = SpanishCardNames[CardNames.CAPITAL].singular;
SpanishAbilityNames[AbilityNames.CHARM] = SpanishCardNames[CardNames.CHARM].singular;
SpanishAbilityNames[AbilityNames.FORUM] = SpanishCardNames[CardNames.FORUM].singular;
SpanishAbilityNames[AbilityNames.HERMIT] = SpanishCardNames[CardNames.HERMIT].singular;
SpanishAbilityNames[AbilityNames.DONATE] = SpanishCardNames[CardNames.DONATE].singular;
SpanishAbilityNames[AbilityNames.TAX_RECEIVE] = SpanishCardNames[CardNames.TAX].singular;
SpanishAbilityNames[AbilityNames.DOMINATE] = SpanishCardNames[CardNames.DOMINATE].singular;
SpanishAbilityNames[AbilityNames.VP_TO_AQUEDUCT] = "PV al Aqueducto";
SpanishAbilityNames[AbilityNames.VP_FROM_AQUEDUCT] = "PV del Aqueducto";
SpanishAbilityNames[AbilityNames.ARENA_DISCARD_ACTION] = SpanishCardNames[CardNames.ARENA].singular;
SpanishAbilityNames[AbilityNames.VP_FROM_BASILICA] = SpanishCardNames[CardNames.BASILICA].singular;
SpanishAbilityNames[AbilityNames.VP_FROM_BATHS] = SpanishCardNames[CardNames.BATHS].singular;
SpanishAbilityNames[AbilityNames.VP_FROM_BATTLEFIELD] = SpanishCardNames[CardNames.BATTLEFIELD].singular;
SpanishAbilityNames[AbilityNames.VP_FROM_COLONNADE] = SpanishCardNames[CardNames.COLONNADE].singular;
SpanishAbilityNames[AbilityNames.VP_TO_DEFILED_SHRINE] = "PV al Santuario Profanado";
SpanishAbilityNames[AbilityNames.VP_FROM_DEFILED_SHRINE] = "PV del Santuario Profanado";
SpanishAbilityNames[AbilityNames.VP_FROM_LABYRINTH] = SpanishCardNames[CardNames.LABYRINTH].singular;
SpanishAbilityNames[AbilityNames.MOUNTAIN_PASS_BIDDING] = SpanishCardNames[CardNames.MOUNTAIN_PASS].singular;
SpanishAbilityNames[AbilityNames.TOMB] = SpanishCardNames[CardNames.TOMB].singular;
SpanishAbilityNames[AbilityNames.LIGHTHOUSE_COIN] = SpanishCardNames[CardNames.LIGHTHOUSE].singular;
SpanishAbilityNames[AbilityNames.REACTION] = "Reacción";
SpanishAbilityNames[AbilityNames.CALL_GUIDE] = "Convoca " + SpanishCardNames[CardNames.GUIDE].singular;
SpanishAbilityNames[AbilityNames.CALL_RATCATCHER] = "Convoca " + SpanishCardNames[CardNames.RATCATCHER].singular;
SpanishAbilityNames[AbilityNames.CALL_TRANSMOGRIFY] = "Convoca " + SpanishCardNames[CardNames.TRANSMOGRIFY].singular;
SpanishAbilityNames[AbilityNames.CALL_TEACHER] = "Convoca " + SpanishCardNames[CardNames.TEACHER].singular;
SpanishAbilityNames[AbilityNames.CALL_DUPLICATE] = "Convoca " + SpanishCardNames[CardNames.DUPLICATE].singular;
SpanishAbilityNames[AbilityNames.CALL_COIN] = "Convoca " + SpanishCardNames[CardNames.COIN_OF_THE_REALM].singular;
SpanishAbilityNames[AbilityNames.CALL_ROYAL_CARRIAGE] = "Convoca " + SpanishCardNames[CardNames.ROYAL_CARRIAGE].singular;
SpanishAbilityNames[AbilityNames.START_OF_TURN] = "comienza tu turno";
SpanishAbilityNames[AbilityNames.START_BUY_PHASE] = "START_BUY_PHASE";
SpanishAbilityNames[AbilityNames.END_OF_BUY_PHASE] = "END_OF_BUY_PHASE";
SpanishAbilityNames[AbilityNames.BETWEEN_TURNS] = "BETWEEN_TURNS";
SpanishAbilityNames[AbilityNames.END_OF_TURN] = "END_OF_TURN";
SpanishAbilityNames[AbilityNames.SUMMON_PLAY] = SpanishCardNames[CardNames.SUMMON].singular;
SpanishAbilityNames[AbilityNames.CARAVAN_GUARD_REACTION] = SpanishCardNames[CardNames.CARAVAN_GUARD].singular;
SpanishAbilityNames[AbilityNames.TRAVELLING_FAIR_REACTION] = SpanishCardNames[CardNames.TRAVELLING_FAIR].singular;
SpanishAbilityNames[AbilityNames.MOAT_REACTION] = SpanishCardNames[CardNames.MOAT].singular;
SpanishAbilityNames[AbilityNames.HORSE_TRADERS_REACTION] = SpanishCardNames[CardNames.HORSE_TRADERS].singular;
SpanishAbilityNames[AbilityNames.BEGGAR_REACTION] = SpanishCardNames[CardNames.BEGGAR].singular;
SpanishAbilityNames[AbilityNames.MARKET_SQUARE_REACTION] = SpanishCardNames[CardNames.MARKET_SQUARE].singular;
SpanishAbilityNames[AbilityNames.TRADER_REACTION] = SpanishCardNames[CardNames.TRADER].singular;
SpanishAbilityNames[AbilityNames.DIPLOMAT_REACTION] = SpanishCardNames[CardNames.DIPLOMAT].singular;
SpanishAbilityNames[AbilityNames.TOPDECK_REACTION] = "Pon sobre el mazo";
SpanishAbilityNames[AbilityNames.WATCHTOWER_TRASH_REACTION] = "Elimina";
SpanishAbilityNames[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "Pon sobre el mazo";
SpanishAbilityNames[AbilityNames.PRINCE_PLAY] = SpanishCardNames[CardNames.PRINCE].singular;
SpanishAbilityNames[AbilityNames.PRINCE_SET_ASIDE] = SpanishCardNames[CardNames.PRINCE].singular;
SpanishAbilityNames[AbilityNames.DELUDED_RETURNED] = "devuelve Estado";
SpanishAbilityNames[AbilityNames.ENVIOUS_RETURNED] = "devuelve Estado";
SpanishAbilityNames[AbilityNames.RECEIVE_BOON] = "Recibe Bendición";
SpanishAbilityNames[AbilityNames.COBBLER_ABILITY] = SpanishCardNames[CardNames.COBBLER].singular;
SpanishAbilityNames[AbilityNames.CRYPT_ABILITY] = SpanishCardNames[CardNames.CRYPT].singular;
SpanishAbilityNames[AbilityNames.DEN_OF_SIN_ABILITY] = SpanishCardNames[CardNames.DEN_OF_SIN].singular;
SpanishAbilityNames[AbilityNames.FAITHFUL_HOUND] = SpanishCardNames[CardNames.FAITHFUL_HOUND].singular;
SpanishAbilityNames[AbilityNames.FAITHFUL_HOUND_RETURN] = SpanishCardNames[CardNames.FAITHFUL_HOUND].singular;
SpanishAbilityNames[AbilityNames.GHOST_TOWN_ABILITY] = SpanishCardNames[CardNames.GHOST_TOWN].singular;
SpanishAbilityNames[AbilityNames.GUARDIAN_COIN] = SpanishCardNames[CardNames.GUARDIAN].singular;
SpanishAbilityNames[AbilityNames.RAIDER_ABILITY] = SpanishCardNames[CardNames.RAIDER].singular;
SpanishAbilityNames[AbilityNames.SECRET_CAVE] = SpanishCardNames[CardNames.SECRET_CAVE].singular;
SpanishAbilityNames[AbilityNames.CEMETERY] = SpanishCardNames[CardNames.CEMETERY].singular;
SpanishAbilityNames[AbilityNames.HAUNTED_MIRROR] = SpanishCardNames[CardNames.HAUNTED_MIRROR].singular;
SpanishAbilityNames[AbilityNames.GHOST] = SpanishCardNames[CardNames.GHOST].singular;
SpanishAbilityNames[AbilityNames.REMOVE_FLIPPED_STATUS] = "X";
SpanishAbilityNames[AbilityNames.LOST_IN_THE_WOODS] = SpanishCardNames[CardNames.LOST_IN_THE_WOODS].singular;
SpanishAbilityNames[AbilityNames.BLESSED_VILLAGE] = SpanishCardNames[CardNames.BLESSED_VILLAGE].singular;
SpanishAbilityNames[AbilityNames.FORTRESS] = SpanishCardNames[CardNames.FORTRESS].singular;
SpanishAbilityNames[AbilityNames.RECEIVE_HEX] = "Recibe Embrujo";
SpanishAbilityNames[AbilityNames.HOVEL_TRASH] = SpanishCardNames[CardNames.HOVEL].singular;
SpanishAbilityNames[AbilityNames.TUNNEL_REVEAL] = SpanishCardNames[CardNames.TUNNEL].singular;
SpanishAbilityNames[AbilityNames.CHANGELING_MAY_EXCHANGE] = SpanishCardNames[CardNames.CHANGELING].singular;
SpanishAbilityNames[AbilityNames.DUCAT] = SpanishCardNames[CardNames.DUCAT].singular;
SpanishAbilityNames[AbilityNames.CARGO_SHIP_SET_ASIDE] = SpanishCardNames[CardNames.CARGO_SHIP].singular;
SpanishAbilityNames[AbilityNames.CARGO_SHIP_RETURN] = SpanishCardNames[CardNames.CARGO_SHIP].singular;
SpanishAbilityNames[AbilityNames.IMPROVE] = SpanishCardNames[CardNames.IMPROVE].singular;
SpanishAbilityNames[AbilityNames.PRIEST] = SpanishCardNames[CardNames.PRIEST].singular;
SpanishAbilityNames[AbilityNames.RESEARCH] = SpanishCardNames[CardNames.RESEARCH].singular;
SpanishAbilityNames[AbilityNames.SILK_MERCHANT] = SpanishCardNames[CardNames.SILK_MERCHANT].singular;
SpanishAbilityNames[AbilityNames.LACKEYS] = SpanishCardNames[CardNames.LACKEYS].singular;
SpanishAbilityNames[AbilityNames.SPICES] = SpanishCardNames[CardNames.SPICES].singular;
SpanishAbilityNames[AbilityNames.KEY] = SpanishCardNames[CardNames.KEY].singular;
SpanishAbilityNames[AbilityNames.TREASURE_CHEST] = SpanishCardNames[CardNames.TREASURE_CHEST].singular;
SpanishAbilityNames[AbilityNames.FLAG_BEARER] = SpanishCardNames[CardNames.FLAG_BEARER].singular;
SpanishAbilityNames[AbilityNames.CATHEDRAL] = SpanishCardNames[CardNames.CATHEDRAL].singular;
SpanishAbilityNames[AbilityNames.CITY_GATE] = SpanishCardNames[CardNames.CITY_GATE].singular;
SpanishAbilityNames[AbilityNames.PAGEANT] = SpanishCardNames[CardNames.PAGEANT].singular;
SpanishAbilityNames[AbilityNames.SEWERS_MAY_TRASH] = SpanishCardNames[CardNames.SEWERS].singular;
SpanishAbilityNames[AbilityNames.EXPLORATION] = SpanishCardNames[CardNames.EXPLORATION].singular;
SpanishAbilityNames[AbilityNames.FAIR] = SpanishCardNames[CardNames.FAIR].singular;
SpanishAbilityNames[AbilityNames.SILOS] = SpanishCardNames[CardNames.SILOS].singular;
SpanishAbilityNames[AbilityNames.ACADEMY] = SpanishCardNames[CardNames.ACADEMY].singular;
SpanishAbilityNames[AbilityNames.GUILDHALL] = SpanishCardNames[CardNames.GUILDHALL].singular;
SpanishAbilityNames[AbilityNames.PIAZZA] = SpanishCardNames[CardNames.PIAZZA].singular;
SpanishAbilityNames[AbilityNames.BARRACKS] = SpanishCardNames[CardNames.BARRACKS].singular;
SpanishAbilityNames[AbilityNames.CROP_ROTATION] = SpanishCardNames[CardNames.CROP_ROTATION].singular;
SpanishAbilityNames[AbilityNames.INNOVATION] = SpanishCardNames[CardNames.INNOVATION].singular;
SpanishAbilityNames[AbilityNames.CITADEL] = SpanishCardNames[CardNames.CITADEL].singular;
SpanishAbilityNames[AbilityNames.SINISTER_PLOT] = SpanishCardNames[CardNames.SINISTER_PLOT].singular;
SpanishAbilityNames[AbilityNames.ENCAMPMENT_RETURNED] = SpanishCardNames[CardNames.ENCAMPMENT].singular;
SpanishAbilityNames[AbilityNames.EMBARGO_GAIN_CURSE] = SpanishCardNames[CardNames.EMBARGO].singular;
SpanishAbilityNames[AbilityNames.POSSESSION_GAIN_INSTEAD] = SpanishCardNames[CardNames.POSSESSION].singular;
SpanishAbilityNames[AbilityNames.TRADE_ROUTE_MOVE_TOKEN] = SpanishCardNames[CardNames.TRADE_ROUTE].singular;
SpanishAbilityNames[AbilityNames.CAPTAIN] = SpanishCardNames[CardNames.CAPTAIN].singular;
SpanishAbilityNames[AbilityNames.CHURCH] = SpanishCardNames[CardNames.CHURCH].singular;
SpanishAbilityNames[AbilityNames.POSSESSION_SETS_ASIDE] = SpanishCardNames[CardNames.POSSESSION].singular;
SpanishAbilityNames[AbilityNames.MINT] = SpanishCardNames[CardNames.MINT].singular;
SpanishAbilityNames[AbilityNames.SLEIGH_TOPDECK_REACTION] = "Pon sobre el mazo";
SpanishAbilityNames[AbilityNames.SLEIGH_PUT_IN_HAND_REACTION] = "Pon en la mano";
SpanishAbilityNames[AbilityNames.BLACK_CAT_REACTION] = SpanishCardNames[CardNames.BLACK_CAT].singular;
SpanishAbilityNames[AbilityNames.DISCARD_FROM_EXILE] = "Descarta del Exilio";
SpanishAbilityNames[AbilityNames.EXILE_BY_NAME] = "Exilia";
SpanishAbilityNames[AbilityNames.SHEEPDOG_REACTION] = SpanishCardNames[CardNames.SHEEPDOG].singular;
SpanishAbilityNames[AbilityNames.CAVALRY] = SpanishCardNames[CardNames.CAVALRY].singular;
SpanishAbilityNames[AbilityNames.HOSTELRY] = SpanishCardNames[CardNames.HOSTELRY].singular;
SpanishAbilityNames[AbilityNames.VILLAGE_GREEN_VILLAGE] = SpanishCardNames[CardNames.VILLAGE_GREEN].singular;
SpanishAbilityNames[AbilityNames.VILLAGE_GREEN_PLAY] = SpanishCardNames[CardNames.VILLAGE_GREEN].singular;
SpanishAbilityNames[AbilityNames.BARGE] = SpanishCardNames[CardNames.BARGE].singular;
SpanishAbilityNames[AbilityNames.FALCONER_REACTION] = SpanishCardNames[CardNames.FALCONER].singular;
SpanishAbilityNames[AbilityNames.GATEKEEPER_EXILE] = SpanishCardNames[CardNames.GATEKEEPER].singular;
SpanishAbilityNames[AbilityNames.GATEKEEPER_DURATION] = SpanishCardNames[CardNames.GATEKEEPER].singular;
SpanishAbilityNames[AbilityNames.LIVERY] = SpanishCardNames[CardNames.LIVERY].singular;
SpanishAbilityNames[AbilityNames.MASTERMIND] = SpanishCardNames[CardNames.MASTERMIND].singular;
SpanishAbilityNames[AbilityNames.DELAY_PLAY] = SpanishCardNames[CardNames.DELAY].singular;
SpanishAbilityNames[AbilityNames.INVEST] = SpanishCardNames[CardNames.INVEST].singular;
SpanishAbilityNames[AbilityNames.REAP_PLAY] = SpanishCardNames[CardNames.REAP].singular;
SpanishAbilityNames[AbilityNames.PLUS_CARD_TOKEN] = "+Carta";
SpanishAbilityNames[AbilityNames.PLUS_BUY_TOKEN] = "+Compra";
SpanishAbilityNames[AbilityNames.PLUS_ACTION_TOKEN] = "+Acción";
SpanishAbilityNames[AbilityNames.PLUS_COIN_TOKEN] = "+Moneda";
SpanishAbilityNames[AbilityNames.KILN] = SpanishCardNames[CardNames.KILN].singular;
SpanishAbilityNames[AbilityNames.TURTLE_PLAY] = SpanishCardNames[CardNames.WAY_OF_THE_TURTLE].singular;
SpanishAbilityNames[AbilityNames.GIVE_PLUS_CARDS] = SpanishAbilityNames[AbilityNames.PLUS_CARD_TOKEN];
SpanishAbilityNames[AbilityNames.GIVE_PLUS_BUYS] = SpanishAbilityNames[AbilityNames.PLUS_BUY_TOKEN];
SpanishAbilityNames[AbilityNames.GIVE_PLUS_ACTIONS] = SpanishAbilityNames[AbilityNames.PLUS_ACTION_TOKEN];
SpanishAbilityNames[AbilityNames.GIVE_PLUS_COINS] = SpanishAbilityNames[AbilityNames.PLUS_COIN_TOKEN];

// Allies
SpanishAbilityNames[AbilityNames.CONJURER] = SpanishCardNames[CardNames.CONJURER].singular;
SpanishAbilityNames[AbilityNames.CONTRACT_ABILITY] = SpanishCardNames[CardNames.CONTRACT].singular;
SpanishAbilityNames[AbilityNames.ENCHANTRESS_REPLACE_ABILITY] = SpanishCardNames[CardNames.ENCHANTRESS].singular;
SpanishAbilityNames[AbilityNames.GALLERIA] = SpanishCardNames[CardNames.GALLERIA].singular;
SpanishAbilityNames[AbilityNames.GARRISON_ADD_TOKEN] = SpanishCardNames[CardNames.GARRISON].singular;
SpanishAbilityNames[AbilityNames.GARRISON_REMOVE_ABILITY] = SpanishCardNames[CardNames.GARRISON].singular;
SpanishAbilityNames[AbilityNames.GUILDMASTER] = SpanishCardNames[CardNames.GUILDMASTER].singular;
SpanishAbilityNames[AbilityNames.HIGHWAYMAN_NEXT_TURN_ABILITY] = SpanishCardNames[CardNames.HIGHWAYMAN].singular;
SpanishAbilityNames[AbilityNames.HIGHWAYMAN_NULLIFIED] = SpanishCardNames[CardNames.HIGHWAYMAN].singular;
SpanishAbilityNames[AbilityNames.HIGHWAYMAN_REPLACE_ABILITY] = SpanishCardNames[CardNames.HIGHWAYMAN].singular;
SpanishAbilityNames[AbilityNames.IMPORTER_ABILITY] = SpanishCardNames[CardNames.IMPORTER].singular;
SpanishAbilityNames[AbilityNames.LICH] = SpanishCardNames[CardNames.LICH].singular;
SpanishAbilityNames[AbilityNames.ROYAL_GALLEY_PLAY] = SpanishCardNames[CardNames.ROYAL_GALLEY].singular;
SpanishAbilityNames[AbilityNames.ROYAL_GALLEY_SET_ASIDE] = SpanishCardNames[CardNames.ROYAL_GALLEY].singular;
SpanishAbilityNames[AbilityNames.SKIRMISHER] = SpanishCardNames[CardNames.SKIRMISHER].singular;
SpanishAbilityNames[AbilityNames.STRONGHOLD_ABILITY] = SpanishCardNames[CardNames.STRONGHOLD].singular;
SpanishAbilityNames[AbilityNames.SYCOPHANT] = SpanishCardNames[CardNames.SYCOPHANT].singular;
SpanishAbilityNames[AbilityNames.TERRITORY_GAIN] = SpanishCardNames[CardNames.TERRITORY].singular;
SpanishAbilityNames[AbilityNames.WARLORD_ABILITY] = SpanishCardNames[CardNames.WARLORD].singular;

SpanishAbilityNames[AbilityNames.ARCHITECTS_GUILD] = SpanishCardNames[CardNames.ARCHITECTS_GUILD].singular;
SpanishAbilityNames[AbilityNames.BAND_OF_NOMADS_ACTION] = "Action"; // <--- TRANSLATE
SpanishAbilityNames[AbilityNames.BAND_OF_NOMADS_BUY] = "Buy"; // <--- TRANSLATE
SpanishAbilityNames[AbilityNames.BAND_OF_NOMADS_CARD] = "Card"; // <--- TRANSLATE
SpanishAbilityNames[AbilityNames.CAVE_DWELLERS] = SpanishCardNames[CardNames.CAVE_DWELLERS].singular;
SpanishAbilityNames[AbilityNames.CIRCLE_OF_WITCHES] = SpanishCardNames[CardNames.CIRCLE_OF_WITCHES].singular;
SpanishAbilityNames[AbilityNames.CITY_STATE] = SpanishCardNames[CardNames.CITY_STATE].singular;
SpanishAbilityNames[AbilityNames.CITY_STATE_PLAY] = SpanishCardNames[CardNames.CITY_STATE].singular;
SpanishAbilityNames[AbilityNames.COASTAL_HAVEN] = SpanishCardNames[CardNames.COASTAL_HAVEN].singular;
SpanishAbilityNames[AbilityNames.CRAFTERS_GUILD] = SpanishCardNames[CardNames.CRAFTERS_GUILD].singular;
SpanishAbilityNames[AbilityNames.DESERT_GUIDES] = SpanishCardNames[CardNames.DESERT_GUIDES].singular;
SpanishAbilityNames[AbilityNames.FAMILY_OF_INVENTORS] = SpanishCardNames[CardNames.FAMILY_OF_INVENTORS].singular;
SpanishAbilityNames[AbilityNames.FELLOWSHIP_OF_SCRIBES] = SpanishCardNames[CardNames.FELLOWSHIP_OF_SCRIBES].singular;
SpanishAbilityNames[AbilityNames.FOREST_DWELLERS] = SpanishCardNames[CardNames.FOREST_DWELLERS].singular;
SpanishAbilityNames[AbilityNames.GANG_OF_PICKPOCKETS] = SpanishCardNames[CardNames.GANG_OF_PICKPOCKETS].singular;
SpanishAbilityNames[AbilityNames.ISLAND_FOLK] = SpanishCardNames[CardNames.ISLAND_FOLK].singular;
SpanishAbilityNames[AbilityNames.LEAGUE_OF_BANKERS] = SpanishCardNames[CardNames.LEAGUE_OF_BANKERS].singular;
SpanishAbilityNames[AbilityNames.LEAGUE_OF_SHOPKEEPERS] = SpanishCardNames[CardNames.LEAGUE_OF_SHOPKEEPERS].singular;
SpanishAbilityNames[AbilityNames.MARKET_TOWNS] = SpanishCardNames[CardNames.MARKET_TOWNS].singular;
SpanishAbilityNames[AbilityNames.MOUNTAIN_FOLK] = SpanishCardNames[CardNames.MOUNTAIN_FOLK].singular;
SpanishAbilityNames[AbilityNames.PEACEFUL_CULT] = SpanishCardNames[CardNames.PEACEFUL_CULT].singular;
SpanishAbilityNames[AbilityNames.TRAPPERS_LODGE] = SpanishCardNames[CardNames.TRAPPERS_LODGE].singular;
SpanishAbilityNames[AbilityNames.WOODWORKERS_GUILD] = SpanishCardNames[CardNames.WOODWORKERS_GUILD].singular;

SpanishAbilityNames[AbilityNames.ASTROLABE_ABILITY] = SpanishCardNames[CardNames.ASTROLABE].singular;
SpanishAbilityNames[AbilityNames.BLOCKADE_ABILITY] = SpanishCardNames[CardNames.BLOCKADE].singular;
SpanishAbilityNames[AbilityNames.CORSAIR_NEXT_TURN_ABILITY] = SpanishCardNames[CardNames.CORSAIR].singular;
SpanishAbilityNames[AbilityNames.CORSAIR_TRASH] = SpanishCardNames[CardNames.CORSAIR].singular;
SpanishAbilityNames[AbilityNames.MONKEY_NEXT_TURN_ABILITY] = SpanishCardNames[CardNames.MONKEY].singular;
SpanishAbilityNames[AbilityNames.PIRATE_ABILITY] = SpanishCardNames[CardNames.PIRATE].singular;
SpanishAbilityNames[AbilityNames.PIRATE_REACTION] = SpanishCardNames[CardNames.PIRATE].singular;
SpanishAbilityNames[AbilityNames.SAILOR_NEXT_TURN] = SpanishCardNames[CardNames.SAILOR].singular;
SpanishAbilityNames[AbilityNames.SAILOR_REACTION] = SpanishCardNames[CardNames.SAILOR].singular;
SpanishAbilityNames[AbilityNames.SEA_WITCH_ABILITY] = SpanishCardNames[CardNames.SEA_WITCH].singular;
SpanishAbilityNames[AbilityNames.TIDE_POOLS_ABILITY] = SpanishCardNames[CardNames.TIDE_POOLS].singular;
SpanishAbilityNames[AbilityNames.TREASURY] = SpanishCardNames[CardNames.TREASURY].singular;
SpanishAbilityNames[AbilityNames.CLERK_ABILITY] = SpanishCardNames[CardNames.CLERK].singular;
SpanishAbilityNames[AbilityNames.BERSERKER] = SpanishCardNames[CardNames.BERSERKER].singular;
SpanishAbilityNames[AbilityNames.CAULDRON_CURSING] = SpanishCardNames[CardNames.CAULDRON].singular;
SpanishAbilityNames[AbilityNames.GUARD_DOG_REACTION] = SpanishCardNames[CardNames.GUARD_DOG].singular;
SpanishAbilityNames[AbilityNames.NOMADS] = SpanishCardNames[CardNames.NOMADS].singular;
SpanishAbilityNames[AbilityNames.SOUK] = SpanishCardNames[CardNames.SOUK].singular;
SpanishAbilityNames[AbilityNames.TRAIL_REACTION] = SpanishCardNames[CardNames.TRAIL].singular;
SpanishAbilityNames[AbilityNames.WEAVER_PLAY] = SpanishCardNames[CardNames.WEAVER].singular;
SpanishAbilityNames[AbilityNames.ALCHEMIST_TOPDECK] = SpanishCardNames[CardNames.ALCHEMIST].singular;

SpanishAbilityNames[AbilityNames.SAUNA_TRASH_ABILITY] = SpanishCardNames[CardNames.SAUNA].singular;
SpanishAbilityNames[AbilityNames.CHAMELEON_RESOLUTION] = SpanishCardNames[CardNames.WAY_OF_THE_CHAMELEON].singular;
SpanishAbilityNames[AbilityNames.MERCHANT_GUILD] = SpanishCardNames[CardNames.MERCHANT_GUILD].singular;
SpanishAbilityNames[AbilityNames.HERBALIST_TOPDECK] = SpanishCardNames[CardNames.HERBALIST].singular;
SpanishAbilityNames[AbilityNames.SCHEME_TOPDECK] = SpanishCardNames[CardNames.SCHEME].singular;
SpanishAbilityNames[AbilityNames.FROG_TOPDECK] = SpanishCardNames[CardNames.WAY_OF_THE_FROG].singular;
SpanishAbilityNames[AbilityNames.MERCHANT_CAMP_TOPDECK] = SpanishCardNames[CardNames.MERCHANT_CAMP].singular;
SpanishAbilityNames[AbilityNames.WALLED_VILLAGE_TOPDECK] = SpanishCardNames[CardNames.WALLED_VILLAGE].singular;
SpanishAbilityNames[AbilityNames.TENT_TOPDECK] = SpanishCardNames[CardNames.TENT].singular;
SpanishAbilityNames[AbilityNames.HORN_TOPDECK] = SpanishCardNames[CardNames.HORN].singular;
SpanishAbilityNames[AbilityNames.PAGE_EXCHANGE] = SpanishCardNames[CardNames.TREASURE_HUNTER].singular;
SpanishAbilityNames[AbilityNames.TREASURE_HUNTER_EXCHANGE] = SpanishCardNames[CardNames.WARRIOR].singular;
SpanishAbilityNames[AbilityNames.WARRIOR_EXCHANGE] = SpanishCardNames[CardNames.HERO].singular;
SpanishAbilityNames[AbilityNames.HERO_EXCHANGE] = SpanishCardNames[CardNames.CHAMPION].singular;
SpanishAbilityNames[AbilityNames.PEASANT_EXCHANGE] = SpanishCardNames[CardNames.SOLDIER].singular;
SpanishAbilityNames[AbilityNames.SOLDIER_EXCHANGE] = SpanishCardNames[CardNames.FUGITIVE].singular;
SpanishAbilityNames[AbilityNames.FUGITIVE_EXCHANGE] = SpanishCardNames[CardNames.DISCIPLE].singular;
SpanishAbilityNames[AbilityNames.DISCIPLE_EXCHANGE] = SpanishCardNames[CardNames.TEACHER].singular;
SpanishAbilityNames[AbilityNames.CAGE_TRASH] = SpanishCardNames[CardNames.CAGE].singular;
SpanishAbilityNames[AbilityNames.CAGE_PUT_IN_HAND] = SpanishCardNames[CardNames.CAGE].singular;
SpanishAbilityNames[AbilityNames.GROTTO] = SpanishCardNames[CardNames.GROTTO].singular;
SpanishAbilityNames[AbilityNames.JEWELLED_EGG_GAIN_LOOT] = SpanishCardNames[CardNames.JEWELLED_EGG].singular;
SpanishAbilityNames[AbilityNames.SEARCH] = SpanishCardNames[CardNames.SEARCH].singular;
SpanishAbilityNames[AbilityNames.SHAMAN] = SpanishCardNames[CardNames.SHAMAN].singular;
SpanishAbilityNames[AbilityNames.SECLUDED_SHRINE] = SpanishCardNames[CardNames.SECLUDED_SHRINE].singular;
SpanishAbilityNames[AbilityNames.SIREN_GAIN] = SpanishCardNames[CardNames.SIREN].singular;
SpanishAbilityNames[AbilityNames.SIREN_DURATION] = SpanishCardNames[CardNames.SIREN].singular;
SpanishAbilityNames[AbilityNames.STOWAWAY_DRAW] = SpanishCardNames[CardNames.STOWAWAY].singular;
SpanishAbilityNames[AbilityNames.STOWAWAY_REACTION] = SpanishCardNames[CardNames.STOWAWAY].singular;
SpanishAbilityNames[AbilityNames.TASKMASTER] = SpanishCardNames[CardNames.TASKMASTER].singular;
SpanishAbilityNames[AbilityNames.ABUNDANCE] = SpanishCardNames[CardNames.ABUNDANCE].singular;
SpanishAbilityNames[AbilityNames.CABIN_BOY] = SpanishCardNames[CardNames.CABIN_BOY].singular;
SpanishAbilityNames[AbilityNames.CRUCIBLE] = SpanishCardNames[CardNames.CRUCIBLE].singular;
SpanishAbilityNames[AbilityNames.FLAGSHIP_ACTIVATE] = SpanishCardNames[CardNames.FLAGSHIP].singular;
SpanishAbilityNames[AbilityNames.FLAGSHIP_REPLAY] = SpanishCardNames[CardNames.FLAGSHIP].singular;
SpanishAbilityNames[AbilityNames.GONDOLA_DURATION] = SpanishCardNames[CardNames.GONDOLA].singular;
SpanishAbilityNames[AbilityNames.GONDOLA_PLAY] = SpanishCardNames[CardNames.GONDOLA].singular;
SpanishAbilityNames[AbilityNames.HARBOR_VILLAGE] = SpanishCardNames[CardNames.HARBOR_VILLAGE].singular;
SpanishAbilityNames[AbilityNames.LANDING_PARTY] = SpanishCardNames[CardNames.LANDING_PARTY].singular;
SpanishAbilityNames[AbilityNames.MAPMAKER] = SpanishCardNames[CardNames.MAPMAKER].singular;
SpanishAbilityNames[AbilityNames.ROPE] = SpanishCardNames[CardNames.ROPE].singular;
SpanishAbilityNames[AbilityNames.BURIED_TREASURE_PLAY] = SpanishCardNames[CardNames.BURIED_TREASURE].singular;
SpanishAbilityNames[AbilityNames.BURIED_TREASURE_DURATION] = SpanishCardNames[CardNames.BURIED_TREASURE].singular;
SpanishAbilityNames[AbilityNames.CREW_DURATION] = SpanishCardNames[CardNames.CREW].singular;
SpanishAbilityNames[AbilityNames.CUTTHROAT_GAIN] = SpanishCardNames[CardNames.CUTTHROAT].singular;
SpanishAbilityNames[AbilityNames.ENLARGE] = SpanishCardNames[CardNames.ENLARGE].singular;
SpanishAbilityNames[AbilityNames.FRIGATE_DURATION] = SpanishCardNames[CardNames.FRIGATE].singular;
SpanishAbilityNames[AbilityNames.FRIGATE_DISCARD] = SpanishCardNames[CardNames.FRIGATE].singular;
SpanishAbilityNames[AbilityNames.LONGSHIP_DURATION] = SpanishCardNames[CardNames.LONGSHIP].singular;
SpanishAbilityNames[AbilityNames.MINING_ROAD] = SpanishCardNames[CardNames.MINING_ROAD].singular;
SpanishAbilityNames[AbilityNames.QUARTERMASTER] = SpanishCardNames[CardNames.QUARTERMASTER].singular;
SpanishAbilityNames[AbilityNames.TRICKSTER_REACTION] = SpanishCardNames[CardNames.TRICKSTER].singular;
SpanishAbilityNames[AbilityNames.TRICKSTER_RETURN_TO_HAND] = SpanishCardNames[CardNames.TRICKSTER].singular;
SpanishAbilityNames[AbilityNames.WEALTHY_VILLAGE] = SpanishCardNames[CardNames.WEALTHY_VILLAGE].singular;
SpanishAbilityNames[AbilityNames.DELIVER_SET_ASIDE] = SpanishCardNames[CardNames.DELIVER].singular;
SpanishAbilityNames[AbilityNames.DELIVER_RETURN] = SpanishCardNames[CardNames.DELIVER].singular;
SpanishAbilityNames[AbilityNames.RUSH] = SpanishCardNames[CardNames.RUSH].singular;
SpanishAbilityNames[AbilityNames.MIRROR] = SpanishCardNames[CardNames.MIRROR].singular;
SpanishAbilityNames[AbilityNames.PREPARE] = SpanishCardNames[CardNames.PREPARE].singular;
SpanishAbilityNames[AbilityNames.AMPHORA] = SpanishCardNames[CardNames.AMPHORA].singular;
SpanishAbilityNames[AbilityNames.DOUBLOONS] = SpanishCardNames[CardNames.DOUBLOONS].singular;
SpanishAbilityNames[AbilityNames.ENDLESS_CHALICE] = SpanishCardNames[CardNames.ENDLESS_CHALICE].singular;
SpanishAbilityNames[AbilityNames.FIGUREHEAD] = SpanishCardNames[CardNames.FIGUREHEAD].singular;
SpanishAbilityNames[AbilityNames.JEWELS] = SpanishCardNames[CardNames.JEWELS].singular;
SpanishAbilityNames[AbilityNames.PUZZLE_BOX_RETURN] = SpanishCardNames[CardNames.PUZZLE_BOX].singular;
SpanishAbilityNames[AbilityNames.SHIELD_REACTION] = SpanishCardNames[CardNames.SHIELD].singular;
SpanishAbilityNames[AbilityNames.SPELL_SCROLL] = SpanishCardNames[CardNames.SPELL_SCROLL].singular;
SpanishAbilityNames[AbilityNames.CURSED_GAIN] = SpanishCardNames[CardNames.CURSED].singular;
SpanishAbilityNames[AbilityNames.FAWNING_GAIN] = SpanishCardNames[CardNames.FAWNING].singular;
SpanishAbilityNames[AbilityNames.SCHEDULER] = SpanishCardNames[CardNames.FRIENDLY].singular;
SpanishAbilityNames[AbilityNames.FRIENDLY_ABILITY] = SpanishCardNames[CardNames.FRIENDLY].singular;
SpanishAbilityNames[AbilityNames.HASTY_SET_ASIDE] = SpanishCardNames[CardNames.HASTY].singular;
SpanishAbilityNames[AbilityNames.HASTY_PLAY] = SpanishCardNames[CardNames.HASTY].singular;
SpanishAbilityNames[AbilityNames.INHERITED] = SpanishCardNames[CardNames.INHERITED].singular;
SpanishAbilityNames[AbilityNames.INSPIRING] = SpanishCardNames[CardNames.INSPIRING].singular;
SpanishAbilityNames[AbilityNames.NEARBY] = SpanishCardNames[CardNames.NEARBY].singular;
SpanishAbilityNames[AbilityNames.PATIENT_SCHEDULER] = SpanishCardNames[CardNames.PATIENT].singular;
SpanishAbilityNames[AbilityNames.PATIENT_SET_ASIDE] = SpanishCardNames[CardNames.PATIENT].singular;
SpanishAbilityNames[AbilityNames.PATIENT_PLAY] = SpanishCardNames[CardNames.PATIENT].singular;
SpanishAbilityNames[AbilityNames.PIOUS] = SpanishCardNames[CardNames.PIOUS].singular;
SpanishAbilityNames[AbilityNames.RECKLESS_REPEAT] = SpanishCardNames[CardNames.RECKLESS].singular;
SpanishAbilityNames[AbilityNames.RECKLESS_RETURN] = SpanishCardNames[CardNames.RECKLESS].singular;
SpanishAbilityNames[AbilityNames.RICH_GAIN] = SpanishCardNames[CardNames.RICH].singular;
SpanishAbilityNames[AbilityNames.SHY] = SpanishCardNames[CardNames.SHY].singular;
SpanishAbilityNames[AbilityNames.TIRELESS_SET_ASIDE] = SpanishCardNames[CardNames.TIRELESS].singular;
SpanishAbilityNames[AbilityNames.TIRELESS_RETURN] = SpanishCardNames[CardNames.TIRELESS].singular;
SpanishAbilityNames[AbilityNames.STAR_CHART] = SpanishCardNames[CardNames.STAR_CHART].singular;
SpanishAbilityNames[AbilityNames.ORDER_OF_ASTROLOGERS] = SpanishCardNames[CardNames.ORDER_OF_ASTROLOGERS].singular;
SpanishAbilityNames[AbilityNames.ORDER_OF_MASONS] = SpanishCardNames[CardNames.ORDER_OF_MASONS].singular;
SpanishAbilityNames[AbilityNames.FATED] = SpanishCardNames[CardNames.FATED].singular;
SpanishAbilityNames[AbilityNames.AVOID] = SpanishCardNames[CardNames.AVOID].singular;
SpanishAbilityNames[AbilityNames.HERBALIST_ASK_FOR_DISCARD] = SpanishCardNames[CardNames.HERBALIST].singular;
SpanishAbilityNames[AbilityNames.SCHEME_ASK_FOR_DISCARD] = SpanishCardNames[CardNames.SCHEME].singular;
SpanishAbilityNames[AbilityNames.TRICKSTER_ASK_FOR_DISCARD] = SpanishCardNames[CardNames.TRICKSTER].singular;
SpanishAbilityNames[AbilityNames.MARCHLAND] = SpanishCardNames[CardNames.MARCHLAND].singular;
SpanishAbilityNames[AbilityNames.FARMHANDS_SET_ASIDE] = SpanishCardNames[CardNames.FARMHANDS].singular;
SpanishAbilityNames[AbilityNames.FARMHANDS_PLAY] = SpanishCardNames[CardNames.FARMHANDS].singular;
SpanishAbilityNames[AbilityNames.JOUST_DISCARD] = SpanishCardNames[CardNames.JOUST].singular;
SpanishAbilityNames[AbilityNames.INFIRMARY_OVERPAY] = SpanishCardNames[CardNames.INFIRMARY].singular;
SpanishAbilityNames[AbilityNames.RESOLVE_INFIRMARY_OVERPAY] = SpanishCardNames[CardNames.INFIRMARY].singular;
SpanishAbilityNames[AbilityNames.DRAW_FROM_FOOTPAD] = SpanishCardNames[CardNames.FOOTPAD].singular;
SpanishAbilityNames[AbilityNames.FARRIER_OVERPAY] = SpanishCardNames[CardNames.FARRIER].singular;
SpanishAbilityNames[AbilityNames.RESOLVE_FARRIER_OVERPAY] = SpanishCardNames[CardNames.FARRIER].singular;
SpanishAbilityNames[AbilityNames.DRAW_FROM_SQUIRREL] = SpanishCardNames[CardNames.WAY_OF_THE_SQUIRREL].singular;
SpanishAbilityNames[AbilityNames.DRAW_FROM_RIVER] = SpanishCardNames[CardNames.THE_RIVERS_GIFT].singular;
SpanishAbilityNames[AbilityNames.DRAW_FROM_FARRIER] = SpanishCardNames[CardNames.FARRIER].singular;
SpanishAbilityNames[AbilityNames.FERRYMAN] = SpanishCardNames[CardNames.FERRYMAN].singular;

SpanishAbilityNames[AbilityNames.BIDING_TIME_SET_ASIDE_HAND] = SpanishCardNames[CardNames.BIDING_TIME].singular;
SpanishAbilityNames[AbilityNames.BIDING_TIME_RETURN_TO_HAND] = SpanishCardNames[CardNames.BIDING_TIME].singular;
SpanishAbilityNames[AbilityNames.ENLIGHTENMENT_CANTRIP] = SpanishCardNames[CardNames.ENLIGHTENMENT].singular;
SpanishAbilityNames[AbilityNames.ENLIGHTENMENT_REPLACE_ABILITY] = SpanishCardNames[CardNames.ENLIGHTENMENT].singular;
SpanishAbilityNames[AbilityNames.GOOD_HARVEST] = SpanishCardNames[CardNames.GOOD_HARVEST].singular;
SpanishAbilityNames[AbilityNames.HARSH_WINTER] = SpanishCardNames[CardNames.HARSH_WINTER].singular;
SpanishAbilityNames[AbilityNames.KIND_EMPEROR] = SpanishCardNames[CardNames.KIND_EMPEROR].singular;
SpanishAbilityNames[AbilityNames.PANIC_BUYS] = SpanishCardNames[CardNames.PANIC].singular;
SpanishAbilityNames[AbilityNames.PANIC_RETURN] = SpanishCardNames[CardNames.PANIC].singular;
SpanishAbilityNames[AbilityNames.PROGRESS] = SpanishCardNames[CardNames.PROGRESS].singular;
SpanishAbilityNames[AbilityNames.RAPID_EXPANSION_SET_ASIDE] = SpanishCardNames[CardNames.RAPID_EXPANSION].singular;
SpanishAbilityNames[AbilityNames.RAPID_EXPANSION_PLAY_LATER] = SpanishCardNames[CardNames.RAPID_EXPANSION].singular;
SpanishAbilityNames[AbilityNames.SICKNESS] = SpanishCardNames[CardNames.SICKNESS].singular;
SpanishAbilityNames[AbilityNames.RIVER_SHRINE] = SpanishCardNames[CardNames.RIVER_SHRINE].singular;
SpanishAbilityNames[AbilityNames.SAMURAI] = SpanishCardNames[CardNames.SAMURAI].singular;
SpanishAbilityNames[AbilityNames.DAIMYO_REPLAY] = SpanishCardNames[CardNames.DAIMYO].singular;
SpanishAbilityNames[AbilityNames.FORESIGHT_RETURN_TO_HAND] = SpanishCardNames[CardNames.FORESIGHT].singular;