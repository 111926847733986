"use strict";

var MAX_CARDS_PER_ROW = 5;
var CARD_PADDING = 20;
var SPECIAL_RANDOMIZER_CARDS = [new CardObject(-1, CardNames.CASTLES, CardNames.CASTLES), new CardObject(-1, CardNames.KNIGHTS, CardNames.KNIGHTS)];

webclient.component("kingdomViewer", {
    controller: ["game", function (game) {
        var self = this;
        game.kingdomViewer = this;

        this.initialize = function () {
            var supplyCardNames = _.chain(game.state.zones).filter(function (z) {
                return !isUndefined(z) && z !== null && z instanceof SupplyCardZone && z.pileName !== CardNames.RUIN_PILE;
            }).map(function (z) {
                return z.pileName;
            }).orderBy([function (c) {
                return c.cost.coin;
            }, function (c) {
                return c.cost.potion;
            }, function (c) {
                return c.cost.debt;
            }, function (c) {
                return c.name;
            }], ['asc', 'asc', 'asc', 'asc']).value();

            game.state.cards.filter(function (c) {
                return c.pileName !== c.cardName;
            }).forEach(function (bottomHalf) {
                var supplyCardName = supplyCardNames.find(function (o) {
                    return o === bottomHalf.pileName;
                });
                if (supplyCardName !== undefined) {
                    supplyCardName.bottomCard = bottomHalf.cardName;
                }
            });

            function onlyUnique(value, index, array) {
                return array.indexOf(value) === index;
            }

            var black_market = _.chain(game.state.cards).filter(function (c) {
                return c.pileName === CardNames.BLACK_MARKET_PILE;
            }).map(function (c) {
                return c.cardName;
            }).orderBy([function (c) {
                return c.cost.coin;
            }, function (c) {
                return c.name;
            }], ['asc', 'asc']).value();

            var knights = _.chain(game.state.cardNames).filter(function (c) {
                return !black_market.includes(c) && c.isKnight();
            }).orderBy([function (c) {
                return c.cost.coin;
            }, function (c) {
                return c.name;
            }], ['asc', 'asc']).value();

            var castles = _.chain(game.state.cardNames).filter(function (c) {
                return !black_market.includes(c) && c.isCastle();
            }).orderBy([function (c) {
                return c.cost.coin;
            }, function (c) {
                return c.name;
            }], ['asc', 'asc']).value();

            var ruins = _.chain(game.state.cardNames).filter(function (c) {
                return !black_market.includes(c) && c.isRuin();
            }).value();

            if (ruins.length > 0) {
                // don't show the actual Ruins in the game, show all 5
                ruins = [CardNames.ABANDONED_MINE, CardNames.RUINED_LIBRARY, CardNames.RUINED_MARKET, CardNames.RUINED_VILLAGE, CardNames.SURVIVORS];
            }

            var prizes = _.chain(game.state.cardNames).filter(function (c) {
                return c.isPrize();
            }).value();

            var rewards = _.chain(game.state.cardNames).filter(function (c) {
                return c.isReward();
            }).filter(onlyUnique).value();

            var druid_boons = _.chain(game.state.zones).filter(function (z) {
                return !isUndefined(z) && z.zoneName === ZoneNames.DRUID_BOONS;
            }).flatMap(function (z) {
                return z.cards;
            }).map(function (c) {
                return c.cardName;
            }).orderBy([function (c) {
                return c.name;
            }], ['asc'])
            // .map(c => new LandscapeCard(game.getCardNameById(c)))
            .uniq().value();

            var kingdom_landscapes = _.chain(game.state.cardNames).filter(function (c) {
                return c.isStandardLandscape();
            }).orderBy([function (c) {
                return c.isEvent();
            }, function (c) {
                return c.isLandmark();
            }, function (c) {
                return c.isProject();
            }, function (c) {
                return c.isWay();
            }, function (c) {
                return c.isAlly();
            }, function (c) {
                return c.cost.coin;
            }, function (c) {
                return c.name;
            }], ['desc', 'desc', 'desc', 'desc', 'desc', 'asc', 'asc']).uniq().value();

            var non_kingdom = _.chain(game.state.cards).filter(function (c) {
                return !supplyCardNames.includes(c.pileName);
            }).map(function (c) {
                return c.cardName;
            }).filter(function (c) {
                return !c.isBaseCard() && !supplyCardNames.includes(c) && !knights.includes(c) && !castles.includes(c) && !prizes.includes(c) && !rewards.includes(c) && !black_market.includes(c) && !c.isRuin() && !c.isLandscape() && !c.isLoot();
            }).orderBy([function (c) {
                return c.cost.coin;
            }, function (c) {
                return c.name;
            }], ['asc', 'asc']).value();

            var boons_hexes = _.chain(game.state.cardNames).filter(function (c) {
                return c.isBoon() || c.isHex();
            }).orderBy([function (c) {
                return c.isBoon();
            }, function (c) {
                return c.isHex();
            }, function (c) {
                return c.name;
            }], ['desc', 'desc', 'asc'])
            // .map(c => new LandscapeCard(c))
            .uniq().value();

            var states_artifacts = _.chain(game.state.cardNames).filter(function (c) {
                return c.isState() || c.isArtifact();
            }).orderBy([function (c) {
                return c.isState();
            }, function (c) {
                return c.isArtifact();
            }, function (c) {
                return c.name;
            }], ['desc', 'desc', 'asc'])
            // .map(c => new LandscapeCard(c))
            .uniq().value();

            var loots = _.chain(game.state.cardNames).filter(function (c) {
                return c.isLoot();
            }).orderBy([function (c) {
                return c.name;
            }], ['asc']).uniq().value();

            var supply_cards = create_grouped_cards(supplyCardNames);
            castles = create_grouped_cards(castles);
            knights = create_grouped_cards(knights);
            prizes = create_grouped_cards(prizes);
            black_market = create_grouped_cards(black_market);
            non_kingdom = create_grouped_cards(non_kingdom);
            states_artifacts = create_grouped_single_name(states_artifacts);
            loots = create_grouped_single_name(loots);

            self.cardGroups = [{ name: LANGUAGE.KingdomViewerNames[KingdomViewerGroups.SUPPLY_CARDS], cards: supply_cards, wraping: "wrap-reverse" }, { name: LANGUAGE.KingdomViewerNames[KingdomViewerGroups.KINGDOM_LANDSCAPES], cards: kingdom_landscapes }, { name: LANGUAGE.KingdomViewerNames[KingdomViewerGroups.NON_KINGDOM], cards: non_kingdom }, { name: LANGUAGE.KingdomViewerNames[KingdomViewerGroups.RUINS], cards: ruins }, { name: LANGUAGE.KingdomViewerNames[KingdomViewerGroups.DRUID_BOONS], cards: druid_boons }, { name: LANGUAGE.KingdomViewerNames[KingdomViewerGroups.KNIGHTS], cards: knights }, { name: LANGUAGE.KingdomViewerNames[KingdomViewerGroups.CASTLES], cards: castles }, { name: LANGUAGE.KingdomViewerNames[KingdomViewerGroups.PRIZES], cards: prizes }, { name: LANGUAGE.KingdomViewerNames[KingdomViewerGroups.REWARDS], cards: rewards }, { name: LANGUAGE.KingdomViewerNames[KingdomViewerGroups.STATES_ARTIFACTS], cards: states_artifacts }, { name: LANGUAGE.KingdomViewerNames[KingdomViewerGroups.BOONS_HEXES], cards: boons_hexes }, { name: LANGUAGE.KingdomViewerNames[KingdomViewerGroups.LOOT], cards: loots }];
        };
        this.initialize();

        function create_grouped_cards(cardNames) {

            return _.chain(cardNames).groupBy(function (c) {
                return c.name;
            }).map(function (groups) {
                var card = groups[0];
                card.amount = groups.length;
                if (groups[0].bottomCard) {
                    card.bottomCard = groups[0].bottomCard;
                    card.bottomCard.amount = groups.length;
                }
                return card;
            }).value();
        }

        function create_grouped_single_name(cardNames) {
            return _.chain(cardNames).groupBy(function (c) {
                return c.name;
            }).map(function (groups) {
                return groups[0];
            }).value();
        }
    }],
    template: "\n    <div class=\"kingdom-viewer\">\n        <div ng-repeat=\"group in $ctrl.cardGroups\" ng-show=\"group.cards.length > 0\">\n            <div class=\"kingdom-viewer-header-container\">\n                <span class=\"kingdom-viewer-group-header\">{{ ::group.name }}</span>\n            </div>\n            <div class=\"kingdom-viewer-group\" style=\"flex-wrap: {{group.wraping}}; align-items: flex-start;\">\n                <div class=\"kingdom-viewer-card-wrapper\" ng-repeat=\"card in group.cards\">\n                    <kingdom-stack ng-if=\"card.bottomCard === undefined\" card=\"card\"></kingdom-stack>\n                    <kingdom-split-stack ng-if=\"card.bottomCard !== undefined\" top-card=\"card\"\n                                         bottom-card=\"card.bottomCard\"></kingdom-split-stack>\n                </div>\n            </div>\n        </div>\n    </div>\n    "
});