"use strict";

var JapaneseDeclineButtons = {};

JapaneseDeclineButtons[DeclineButtonIds.END_ACTION_PHASE] = "購入開始";
JapaneseDeclineButtons[DeclineButtonIds.END_BUY_PHASE] = "購入終了";
JapaneseDeclineButtons[DeclineButtonIds.END_TURN] = "ターン終了";
JapaneseDeclineButtons[DeclineButtonIds.DONE_PLAYING_TREASURES] = "財宝を使用しない";
JapaneseDeclineButtons[DeclineButtonIds.DONE_REACTING] = "リアクションしない";
JapaneseDeclineButtons[DeclineButtonIds.MINING_VILLAGE_DONT_TRASH] = "廃棄しない";
JapaneseDeclineButtons[DeclineButtonIds.PEARL_DIVER_DONT_TOPDECK] = "そのままにする";
JapaneseDeclineButtons[DeclineButtonIds.HARBINGER_DONT_TOPDECK] = "山札に置かない";
JapaneseDeclineButtons[DeclineButtonIds.THRONE_ROOM_DONT] = "複数回使用しない";
JapaneseDeclineButtons[DeclineButtonIds.SMUGGLERS_GAIN_IMPOSSIBLE_COPY] = "獲得しない";
JapaneseDeclineButtons[DeclineButtonIds.EXPLORER_GAIN_SILVER] = "銀貨を獲得する";
JapaneseDeclineButtons[DeclineButtonIds.HERBALIST_DONT_TOPDECK] = "財宝を山札に置かない";
JapaneseDeclineButtons[DeclineButtonIds.MINT_DONT_COPY] = "財宝を公開しない";
JapaneseDeclineButtons[DeclineButtonIds.HAMLET_DONT_DISCARD] = "捨て札にしない";
JapaneseDeclineButtons[DeclineButtonIds.TOURNAMENT_DONT_REVEAL] = "属州を公開しない";
JapaneseDeclineButtons[DeclineButtonIds.YOUNG_WITCH_DONT_REVEAL_BANE] = "呪いを獲得する";
JapaneseDeclineButtons[DeclineButtonIds.IGG_NO_COPPER] = "銅貨を獲得しない";
JapaneseDeclineButtons[DeclineButtonIds.COUNTERFEIT_NO_TREASURE] = "偽造しない";
JapaneseDeclineButtons[DeclineButtonIds.CULTIST_DONT_PLAY_ANOTHER] = "狂信者を使用しない";
JapaneseDeclineButtons[DeclineButtonIds.IRONMONGER_KEEP] = "そのままにする";
JapaneseDeclineButtons[DeclineButtonIds.PROCESSION_DONT] = "行進しない";
JapaneseDeclineButtons[DeclineButtonIds.PLAZA_NO_DISCARD] = "財宝を捨て札にしない";
JapaneseDeclineButtons[DeclineButtonIds.DONT_OVERPAY] = "過払いしない";
JapaneseDeclineButtons[DeclineButtonIds.DONE_SETTING_ASIDE] = "脇に置かない";
JapaneseDeclineButtons[DeclineButtonIds.ARTIFICER_DONT] = "捨て札にしない";
JapaneseDeclineButtons[DeclineButtonIds.ENGINEER_DONT_TRASH] = "技術者を廃棄しない";
JapaneseDeclineButtons[DeclineButtonIds.ENCAMPMENT_DONT_REVEAL] = "脇に置く";
JapaneseDeclineButtons[DeclineButtonIds.SETTLERS_DONT_TAKE_COPPER] = "銅貨を手札に加えない";
JapaneseDeclineButtons[DeclineButtonIds.GLADIATOR_DONT_REVEAL] = "同じカードを公開しない";
JapaneseDeclineButtons[DeclineButtonIds.CROWN_DONT] = "戴冠しない";
JapaneseDeclineButtons[DeclineButtonIds.LEGIONARY_DONT_REVEAL] = "金貨を公開しない";
JapaneseDeclineButtons[DeclineButtonIds.SHUFFLE_ALL] = "すべてシャッフルする";
JapaneseDeclineButtons[DeclineButtonIds.PASS] = "入札しない";
JapaneseDeclineButtons[DeclineButtonIds.DONT_DISCARD] = "捨て札にしない";
JapaneseDeclineButtons[DeclineButtonIds.DONT_TRASH] = "廃棄しない";
JapaneseDeclineButtons[DeclineButtonIds.VAULT_NO_DISCARD] = "捨て札にしない";
JapaneseDeclineButtons[DeclineButtonIds.DONT_BUY] = "購入しない";
JapaneseDeclineButtons[DeclineButtonIds.DONT_GAIN] = "獲得しない";
JapaneseDeclineButtons[DeclineButtonIds.DISCARD_ALL] = "すべて捨て札にする";
JapaneseDeclineButtons[DeclineButtonIds.TORTURER_FAIL_CURSE] = "捨て札にしない";
JapaneseDeclineButtons[DeclineButtonIds.TORTURER_GAIN_CURSE] = "呪いを獲得する";
JapaneseDeclineButtons[DeclineButtonIds.BUSTLING_VILLAGE_NO_SETTLERS] = "開拓者を手札に加えない";
JapaneseDeclineButtons[DeclineButtonIds.PRINCE_NO] = "王子で脇に置かない";
JapaneseDeclineButtons[DeclineButtonIds.SAUNA_DONT_CHAIN] = "アヴァントを使用しない";
JapaneseDeclineButtons[DeclineButtonIds.AVANTO_DONT_CHAIN] = "サウナを使用しない";
JapaneseDeclineButtons[DeclineButtonIds.CONCLAVE_DONT] = "使用しない";
JapaneseDeclineButtons[DeclineButtonIds.IMP_DONT] = "使用しない";
JapaneseDeclineButtons[DeclineButtonIds.NECROMANCER_DONT] = "使用しない";
JapaneseDeclineButtons[DeclineButtonIds.NO_THANKS] = "恩恵を受けない";
JapaneseDeclineButtons[DeclineButtonIds.DONT_EXILE] = "追放しない";
JapaneseDeclineButtons[DeclineButtonIds.KINGS_COURT_DONT] = "使用しない";
JapaneseDeclineButtons[DeclineButtonIds.MASTERMIND_DONT] = "使用しない";
JapaneseDeclineButtons[DeclineButtonIds.DELAY_DONT] = "脇に置かない";
JapaneseDeclineButtons[DeclineButtonIds.TOIL_DONT] = "使用しない";
JapaneseDeclineButtons[DeclineButtonIds.MARCH_DONT] = "使用しない";
JapaneseDeclineButtons[DeclineButtonIds.DONT_TOPDECK] = "山札の上に置かない";
JapaneseDeclineButtons[DeclineButtonIds.RANDOM_ORDER] = "順番を気にせず獲得";
JapaneseDeclineButtons[DeclineButtonIds.HUNTING_LODGE_KEEP] = "捨て札にしない";
JapaneseDeclineButtons[DeclineButtonIds.TIARA_NO_TREASURE] = "使用しない";
JapaneseDeclineButtons[DeclineButtonIds.SWAP_DONT] = "交換しない";
JapaneseDeclineButtons[DeclineButtonIds.DONT_PLAY] = "使用しない";
JapaneseDeclineButtons[DeclineButtonIds.DONT_REVEAL] = "公開しない";
JapaneseDeclineButtons[DeclineButtonIds.DONT_ROTATE] = "循環させない";
JapaneseDeclineButtons[DeclineButtonIds.DONT] = "しない";
JapaneseDeclineButtons[DeclineButtonIds.SPEND_FAVOR] = "好意を支払う";
JapaneseDeclineButtons[DeclineButtonIds.CONTINUE] = "続行";
JapaneseDeclineButtons[DeclineButtonIds.ORB_DONT] = "+1購入、+$3";
JapaneseDeclineButtons[DeclineButtonIds.DONT_PUZZLE_BOX] = "脇に置かない";
JapaneseDeclineButtons[DeclineButtonIds.DONT_INSPIRING] = "使用しない";
JapaneseDeclineButtons[DeclineButtonIds.DONT_JOUST] = "脇に置かない";
JapaneseDeclineButtons[DeclineButtonIds.SNAKE_WITCH_DONT_RETURN] = "山に戻さない";

JapaneseDeclineButtons[DoneButtonIds.DONE_TRASHING] = "廃棄選択確定";
JapaneseDeclineButtons[DoneButtonIds.DONE_DISCARDING] = "捨て札選択確定";
JapaneseDeclineButtons[DoneButtonIds.DONE_ORDERING] = "順番確定";
JapaneseDeclineButtons[DoneButtonIds.DONE_RETURNING] = "山に戻す選択を確定";
JapaneseDeclineButtons[DoneButtonIds.DONE_SETTING_ASIDE] = "脇に置く選択を確定";
JapaneseDeclineButtons[DoneButtonIds.DONE_SELECTING] = "選択終了";
JapaneseDeclineButtons[DoneButtonIds.DONE_CHOOSING] = "選択終了";
JapaneseDeclineButtons[DoneButtonIds.TRASH] = "廃棄する";
JapaneseDeclineButtons[DoneButtonIds.DISCARD] = "捨て札にする";
JapaneseDeclineButtons[DoneButtonIds.PASS] = "渡す";
JapaneseDeclineButtons[DoneButtonIds.TOPDECK] = "山札の上に置く";
JapaneseDeclineButtons[DoneButtonIds.BOTTOMDECK] = "山札の底に置く";
JapaneseDeclineButtons[DoneButtonIds.EXILE] = "追放する";
JapaneseDeclineButtons[DoneButtonIds.OVERPAY] = "過払い";

var JapaneseGameButtons = {};

JapaneseGameButtons[GameButtons.AUTOPLAY_TREASURES] = "財宝をすべて使用する";
JapaneseGameButtons[GameButtons.USE_VILLAGER] = "村人を使用する";
JapaneseGameButtons[GameButtons.USE_FAVOR_TOPDECK] = "好意を使って積み込む";
JapaneseGameButtons[GameButtons.USE_FAVOR_DISCARD] = "好意を使って捨て札に置く";
JapaneseGameButtons[GameButtons.COFFERS] = "財源を使う";

var JapaneseDelayedQuestionButtons = {};

JapaneseDelayedQuestionButtons[CardNames.ARTIFICER] = "製作する";
JapaneseDelayedQuestionButtons[CardNames.BUSTLING_VILLAGE] = "開拓者を手札に加える";
JapaneseDelayedQuestionButtons[CardNames.CULTIST] = "狂信者を使用する";
JapaneseDelayedQuestionButtons[CardNames.ENGINEER] = "技術者を廃棄する";
JapaneseDelayedQuestionButtons[CardNames.HAMLET] = "捨て札にする";
JapaneseDelayedQuestionButtons[CardNames.HARBINGER] = "山札に置く";
JapaneseDelayedQuestionButtons[CardNames.ILL_GOTTEN_GAINS] = "銅貨を獲得する";
JapaneseDelayedQuestionButtons[CardNames.IRONMONGER] = "捨て札にする";
JapaneseDelayedQuestionButtons[CardNames.MINING_VILLAGE] = "廃棄する";
JapaneseDelayedQuestionButtons[CardNames.PEARL_DIVER] = "上に置く";
JapaneseDelayedQuestionButtons[CardNames.PLAZA] = "財宝を捨て札にする";
JapaneseDelayedQuestionButtons[CardNames.SETTLERS] = "銅貨を手札に加える";
//JapaneseDelayedQuestionButtons[CardNames.VASSAL] = "使用する"; // this doesn't exist in Japanese
JapaneseDelayedQuestionButtons[CardNames.HUNTING_LODGE] = "捨て札にして5枚カードを引く";
JapaneseDelayedQuestionButtons[CardNames.SNAKE_WITCH] = "山に戻し、呪う";
JapaneseDelayedQuestionButtons[CardNames.RUSTIC_VILLAGE] = "2枚捨て札にして1枚カードを引く";
JapaneseDelayedQuestionButtons[CardNames.GOLD_MINE] = "金貨、4負債";

var JapaneseCleanupActions = {};

JapaneseCleanupActions[CleanupActions.TOPDECK] = new CleanupButtonText("山札に置く", "捨て札にする");
JapaneseCleanupActions[CleanupActions.EXCHANGE] = new CleanupButtonText("交換する", "維持する");