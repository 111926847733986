"use strict";

function QuestionClass(name) {
    this.name = name;
}

var QuestionClasses = {
    CHOICE_QUESTION: new QuestionClass("choice"),
    NUMBER_QUESTION: new QuestionClass("number"),
    NAME_QUESTION: new QuestionClass("name"),
    DELAYED_QUESTION: new QuestionClass("delayed"),
    COMPLEX_QUESTION: new QuestionClass("complex")
};

function QuestionTypeTag() {
    this.uuid = generateUUID();
    this.toString = function () {
        return self.uuid;
    };
}

var QuestionTypeTags = {
    IMMEDIATELY_SEND: new QuestionTypeTag(),
    NEVER_SEND: new QuestionTypeTag(),
    ORDER: new QuestionTypeTag(),
    NO_ORPHANS: new QuestionTypeTag(),
    NO_BUTTON: new QuestionTypeTag()
};

function QuestionType() {
    var tags = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [QuestionTypeTags.IMMEDIATELY_SEND];
    var actionType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Actions.PLAY;
    var doneButton = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    var singularDoneButton = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : doneButton;

    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
    self.immediatelySend = tags.includes(QuestionTypeTags.IMMEDIATELY_SEND);
    self.neverSend = tags.includes(QuestionTypeTags.NEVER_SEND);
    self.isOrder = tags.includes(QuestionTypeTags.ORDER);
    self.dontOrphan = tags.includes(QuestionTypeTags.NO_ORPHANS);
    self.noButton = tags.includes(QuestionTypeTags.NO_BUTTON);
    self.actionType = actionType;
    self.doneButton = doneButton;
    self.singularDoneButton = singularDoneButton;
    self.leftText = null;
    self.rightText = null;
}

var QuestionTypes = {
    PLAY: new QuestionType(),
    GAIN: new QuestionType(),
    DISCARD: new QuestionType([], Actions.DISCARD, DoneButtonIds.DONE_DISCARDING, DoneButtonIds.DISCARD),
    TRASH: new QuestionType([], Actions.TRASH, DoneButtonIds.DONE_TRASHING, DoneButtonIds.TRASH),
    TRASH_UNIQUE: new QuestionType([], Actions.TRASH, DoneButtonIds.DONE_TRASHING, DoneButtonIds.TRASH),
    SELECT_ZONE: new QuestionType(),
    SELECT_TURN: new QuestionType(),
    RESOLVE_ABILITY: new QuestionType([QuestionTypeTags.NO_ORPHANS, QuestionTypeTags.IMMEDIATELY_SEND], Actions.REACT),
    ORDER_CARDS: new QuestionType([QuestionTypeTags.NEVER_SEND, QuestionTypeTags.ORDER], Actions.PLAY, DoneButtonIds.DONE_ORDERING),
    BUY: new QuestionType([QuestionTypeTags.IMMEDIATELY_SEND], Actions.BUY),
    CHOOSE_MODE: new QuestionType([QuestionTypeTags.IMMEDIATELY_SEND], Actions.PLAY, DoneButtonIds.DONE_CHOOSING),
    HOW_MUCH_REPAY: new QuestionType(),
    HOW_MANY_SPEND: new QuestionType(),
    TOPDECK: new QuestionType([], Actions.DISCARD, DoneButtonIds.DONE_SELECTING, DoneButtonIds.TOPDECK),
    BOTTOMDECK: new QuestionType([], Actions.DISCARD, DoneButtonIds.DONE_SELECTING, DoneButtonIds.BOTTOMDECK),
    SET_ASIDE: new QuestionType([], Actions.DISCARD, DoneButtonIds.DONE_SETTING_ASIDE),
    REVEAL: new QuestionType(),
    PASS: new QuestionType([], Actions.PASS, DoneButtonIds.DONE_SELECTING, DoneButtonIds.PASS),
    COMPLEX_OR: new QuestionType(),
    COMPLEX_AND: new QuestionType(),
    WISH: new QuestionType(),
    DELAYED: new QuestionType(),
    PUT_IN_HAND: new QuestionType([], Actions.DISCARD, DoneButtonIds.DONE_SELECTING),
    KEEP_IN_DISCARD: new QuestionType([QuestionTypeTags.IMMEDIATELY_SEND], Actions.DISCARD, DoneButtonIds.DONE_SELECTING),
    BID: new QuestionType(),
    OVERPAY: new QuestionType(),
    SHUFFLE_INTO_DECK: new QuestionType([], Actions.DISCARD, DoneButtonIds.DONE_SELECTING),
    INSERT_INTO_DECK: new QuestionType([], Actions.DISCARD, DoneButtonIds.DONE_SELECTING),
    LOCATION: new QuestionType(),
    DONATE: new QuestionType(),
    TAKE_HOW_MANY: new QuestionType(),
    RETURN_TO_SUPPLY: new QuestionType([], Actions.PASS, DoneButtonIds.DONE_RETURNING),
    GAIN_PRIZE: new QuestionType(),
    PRIORITIZE_CARDS: new QuestionType([QuestionTypeTags.NEVER_SEND, QuestionTypeTags.ORDER], Actions.PLAY, DoneButtonIds.DONE_ORDERING),
    BUTTON: new QuestionType(),
    CLEANUP: new QuestionType([], Actions.NONE, DoneButtonIds.DONE_SELECTING),
    IMPERSONATE: new QuestionType(),
    RECEIVE: new QuestionType(),
    START_GAME: new QuestionType(),
    PLAY_USING_VILLAGER: new QuestionType([QuestionTypeTags.IMMEDIATELY_SEND], Actions.PLAY_USING_VILLAGER),
    EXILE: new QuestionType([], Actions.EXILE, DoneButtonIds.EXILE),
    USE_VILLAGERS: new QuestionType(),
    EXILE_WITH_SAME_NAME: new QuestionType([], Actions.EXILE, DoneButtonIds.EXILE),
    WAY: new QuestionType([QuestionTypeTags.NEVER_SEND], Actions.WAY),
    HOW_MANY_FAVORS: new QuestionType(),
    ROTATE: new QuestionType(),
    KEEP: new QuestionType([], Actions.DISCARD, DoneButtonIds.DONE_SELECTING),
    OVERPAY_COINS: new QuestionType([], Actions.NONE, DoneButtonIds.OVERPAY),
    OVERPAY_POTIONS: new QuestionType([], Actions.NONE, DoneButtonIds.OVERPAY),
    REJECT_ABILITY: new QuestionType([QuestionTypeTags.NO_BUTTON], Actions.NONE)

};

QuestionTypes.ORDER_CARDS.leftText = Phrases.BOTTOM;
QuestionTypes.ORDER_CARDS.rightText = Phrases.TOP;
QuestionTypes.PRIORITIZE_CARDS.leftText = Phrases.FIRST;
QuestionTypes.PRIORITIZE_CARDS.rightText = Phrases.LAST;