"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Duration = function () {
    function Duration(name) {
        var years = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
        var months = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
        var days = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
        var discount = arguments[4];

        _classCallCheck(this, Duration);

        this.years = years;
        this.months = months;
        this.days = days;
        this.discount = discount;
        this.name = name;
        this.start = new Date();
    }

    _createClass(Duration, [{
        key: 'setStartDate',
        value: function setStartDate(date) {
            this.start = date;
        }
    }, {
        key: 'setEndDate',
        value: function setEndDate(date) {
            var minDuration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;


            assert(date >= this.start);

            var days = Math.abs(date.getTime() - this.start.getTime()) / (60 * 60 * 24 * 1000);
            if (minDuration && days < minDuration) days = minDuration;
            var mark = new Duration('copy', 0, 0, days);

            this.months = mark.months;
            this.years = mark.years;
            this.days = mark.days;
        }
    }, {
        key: 'startDate',
        value: function startDate() {
            return this.start;
        }
    }, {
        key: 'endDate',
        value: function endDate() {

            var date = new Date(this.start);

            date.setYear(date.getFullYear() + this.years);
            date.setMonth(date.getMonth() + this.months);
            date.setDate(date.getDate() + this.days);

            return date;
        }
    }], [{
        key: 'copy',
        value: function copy(duration) {

            var years = duration.years;
            var months = duration.months;
            var days = duration.days;
            var discount = duration.discount;
            var name = 'copy of ' + duration.name;
            var copy = new Duration(name, years, months, days, discount);

            return copy;
        }
    }]);

    return Duration;
}();