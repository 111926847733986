"use strict";

var GermanCardTexts = {};

GermanCardTexts[CardNames.BACK] = "";
GermanCardTexts[CardNames.CURSE] = "";
GermanCardTexts[CardNames.COPPER] = "";
GermanCardTexts[CardNames.SILVER] = "";
GermanCardTexts[CardNames.GOLD] = "";
GermanCardTexts[CardNames.ESTATE] = "";
GermanCardTexts[CardNames.DUCHY] = "";
GermanCardTexts[CardNames.PROVINCE] = "";
GermanCardTexts[CardNames.ARTISAN] = "Nimm dir eine Karte//auf deine Hand,//die h\xF6chstens [5] kostet.//Lege eine Karte aus//deiner Hand auf//deinen Nachziehstapel.";
GermanCardTexts[CardNames.BANDIT] = "Nimm dir ein Gold.//Jeder andere Spieler deckt die//obersten beiden Karten seines//Nachziehstapels auf, entsorgt eine//der aufgedeckten Geldkarten au\xDFer//Kupfer und legt den Rest ab.";
GermanCardTexts[CardNames.BUREAUCRAT] = "Nimm dir ein Silber auf deinen//Nachziehstapel. Jeder andere//Spieler deckt eine Punktekarte aus//seiner Hand auf und legt sie auf//seinen Nachziehstapel, oder er//deckt seine Hand auf, wenn er//keine Punktekarte//auf der Hand hat.";
GermanCardTexts[CardNames.BUREAUCRAT] = "Nimm dir ein Silber auf//deinen Nachziehstapel. Jeder//andere Spieler deckt eine Punkte-//karte aus seiner Hand auf und legt//sie auf seinen Nachziehstapel,//oder er deckt seine Hand auf,// wenn er keine Punkte-//karte auf der Hand hat.";
GermanCardTexts[CardNames.BUREAUCRAT] = "Nimm dir ein Silber auf//deinen Nachziehstapel. Jeder//andere Spieler deckt eine//Punktekarte aus seiner Hand//auf und legt sie auf seinen//Nachziehstapel, oder er deckt//seine Hand auf, wenn er keine//Punktekarte auf der Hand hat.";
GermanCardTexts[CardNames.CELLAR] = "|+1 Aktion|////Lege eine beliebige Anzahl//Handkarten ab, dann ziehe//genauso viele Karten.";
GermanCardTexts[CardNames.CHAPEL] = "Entsorge bis zu vier Karten//aus deiner Hand.";
GermanCardTexts[CardNames.COUNCIL_ROOM] = "|+4 Karten|//|+1 Kauf|////Jeder andere Spieler//zieht eine Karte.";
GermanCardTexts[CardNames.FESTIVAL] = "|+2 Aktionen|//|+1 Kauf|//|+[2]|";
GermanCardTexts[CardNames.GARDENS] = "Wert {1} pro 10 Karten, die//du besitzt (abgerundet).";
GermanCardTexts[CardNames.HARBINGER] = "|+1 Karte|//|+1 Aktion|////Durchsuche deinen//Ablagestapel. Du darfst//eine der Karten auf deinen//Nachziehstapel legen.";
GermanCardTexts[CardNames.LABORATORY] = "|+2 Karten|//|+1 Aktion|";
GermanCardTexts[CardNames.LIBRARY] = "Ziehe Karten, bis du 7 auf der//Hand hast. \xDCberspringe alle//Aktionskarten deiner Wahl;//lege diese zur Seite und lege//sie hinterher ab.";
GermanCardTexts[CardNames.MARKET] = "|+1 Karte|//|+1 Aktion|//|+1 Kauf|//|+[1]|";
GermanCardTexts[CardNames.MERCHANT] = "|+1 Karte|//|+1 Aktion|////Wenn du das erste Mal in die-//sem Zug ein Silber spielst, +[1].";
GermanCardTexts[CardNames.MERCHANT] = "|+1 Karte|//|+1 Aktion|////Wenn du das erste Mal//in diesem Zug ein//Silber spielst, +[1].";
GermanCardTexts[CardNames.MILITIA] = "|+[2]|////Jeder andere Spieler legt alle//bis auf drei Karten von seiner//Hand ab.";
GermanCardTexts[CardNames.MINE] = "Du darfst eine Geldkarte aus//deiner Hand entsorgen.//Nimm dir eine Geldkarte,// die bis zu [3] mehr kostet.";
GermanCardTexts[CardNames.MOAT] = "|+2 Karten|//---//Wenn ein anderer Spieler eine//Angriffskarte spielt, darfst du//zuerst diese Karte aus deiner//Hand aufdecken, um von deren//Effekt nicht betroffen zu sein.";
GermanCardTexts[CardNames.MONEYLENDER] = "Du darfst ein Kupfer aus deiner//Hand f\xFCr +[3] entsorgen.";
GermanCardTexts[CardNames.POACHER] = "|+1 Karte|//|+1 Aktion|//|+[1]|////Lege f\xFCr jeden leeren//Vorratsstapel eine Karte//aus deiner Hand ab.";
GermanCardTexts[CardNames.REMODEL] = "Entsorge eine Karte aus deiner//Hand. Nimm dir eine Karte,//die bis zu [2] mehr kostet.";
GermanCardTexts[CardNames.SENTRY] = "|+1 Karte|//|+1 Aktion|////Sieh dir die obersten beiden//Karten deines Nachziehstapels an.//Entsorge und/oder lege eine beliebige//Anzahl davon ab. Lege den Rest in//beliebiger Reihenfolge zur\xFCck auf//deinen Nachziehstapel.";
GermanCardTexts[CardNames.SMITHY] = "|+3 Karten|";
GermanCardTexts[CardNames.THRONE_ROOM] = "Du darfst eine Aktionskarte aus//deiner Hand zweimal spielen.";
GermanCardTexts[CardNames.VASSAL] = "|+[2]|////Lege die oberste Karte deines//Nachziehstapels ab. Wenn es//eine Aktionskarte ist, darfst//du sie spielen.";
GermanCardTexts[CardNames.VILLAGE] = "|+1 Karte|//|+2 Aktionen|";
GermanCardTexts[CardNames.WITCH] = "|+2 Karten|////Jeder andere Spieler nimmt//sich einen Fluch.";
GermanCardTexts[CardNames.WORKSHOP] = "Nimm dir eine Karte,//die bis zu [4] kostet.";

GermanCardTexts[CardNames.COURTYARD] = "|+3 Karten|////Lege eine Karte//aus deiner Hand auf//deinen Nachziehstapel.";
GermanCardTexts[CardNames.CONSPIRATOR] = "|+[2]|////Wenn du in diesem Zug drei//oder mehr Aktionen gespielt//hast (diese eingeschlossen),//|+1 Karte| und |+1 Aktion|.";
GermanCardTexts[CardNames.COURTIER] = "//Decke eine Karte aus deiner//Hand auf. F\xFCr jeden Typ, den die// Karte hat (Aktion, Angriff, Geld,// usw.), w\xE4hle  eines: |+1 Aktion|;//oder |+1 Kauf|; oder +[3]; oder//nimm dir ein Gold. Du darfst je-//den Effekt nur einmal ausw\xE4hlen.";
GermanCardTexts[CardNames.BARON] = "|+1 Kauf|////Du darfst ein Anwesen aus//deiner Hand f\xFCr +[4] ablegen.//Wenn du das nicht machst,//nimm dir ein Anwesen.";
GermanCardTexts[CardNames.BRIDGE] = "|+1 Kauf|//|+[1]|////In diesem Zug kosten Karten//(\xFCberall) [1] weniger.";
GermanCardTexts[CardNames.DIPLOMAT] = "|+2 Karten|////Wenn du nach dem Ziehen 5 oder weniger//Karten auf der Hand hast, |+2 Aktionen|.//---//Wenn ein Mitspieler eine Angriffskarte//spielt und du 5 oder mehr Karten auf der//Hand hast, darfst du zuerst diese Karte//aus deiner Hand aufdecken, um 2 Karten//zu ziehen und danach 3 Karten aus//deiner Hand abzulegen.";
GermanCardTexts[CardNames.DUKE] = "Wert {1} pro Herzogtum,//das du besitzt.";
GermanCardTexts[CardNames.FARM] = "[!2]////---////{!2}";
GermanCardTexts[CardNames.NOBLES] = "W\xE4hle eines: |+3 Karten|;//oder |+2 Aktionen|.////---////{!2}";
GermanCardTexts[CardNames.IRONWORKS] = "Nimm dir eine Karte, die bis//zu [4] kostet. Wenn die//genommene Karte eine...////Aktionskarte ist, |+1 Aktion|//Geldkarte ist, |+|[1]//Punktekarte ist,|+1 Karte|.";
GermanCardTexts[CardNames.LURKER] = "|+1 Aktion|////W\xE4hle eines: Entsorge eine//Aktionskarte von einem//Vorratsstapel; oder nimm dir eine//Aktionskarte vom M\xFCll-Stapel.";
GermanCardTexts[CardNames.MASQUERADE] = "|+2 Karten|////Jeder Spieler gibt eine Handkarte//an den Spieler links von sich weiter//(alle gleichzeitig), wobei Spieler, die//keine Karten auf der Hand haben,//\xFCbersprungen werden. Danach darfst du//eine Karte aus deiner Hand entsorgen.";
GermanCardTexts[CardNames.MILL] = "|+1 Karte|//|+1 Aktion|//Du darfst 2 Karten aus deiner//Hand f\xFCr +[2] ablegen.//---//{!1}";
GermanCardTexts[CardNames.MINING_VILLAGE] = "|+1 Karte|//|+2 Aktionen|////Du darfst diese Karte//f\xFCr +[2] entsorgen.";
GermanCardTexts[CardNames.MINION] = "|+1 Aktion|////W\xE4hle eines: +[2]; oder lege//deine Handkarten ab, |+4 Karten|,// und jeder andere Spieler mit//mindestens 5 Karten auf der//Hand legt seine Handkarten// ab und zieht 4 Karten.";
GermanCardTexts[CardNames.PATROL] = "|+3 Karten|////Decke die obersten vier Karten//deines Nachziehstapels auf. //Nimm die Punktekarten und Fl\xFCche//auf die Hand. Lege den Rest in//beliebiger Reihenfolge zur\xFCck.";
GermanCardTexts[CardNames.PAWN] = "W\xE4hle zwei verschiedene: //|+1 Karte|;//|+1 Aktion|;//|+1 Kauf|;//+[1].";
GermanCardTexts[CardNames.REPLACE] = "Entsorge eine Karte aus deiner Hand.//Nimm dir eine Karte, die bis zu [2]//mehr kostet. Wenn die genommene//Karte eine Aktions- oder Geldkarte ist,//lege sie auf deinen Nachziehstapel;//wenn es eine Punktekarte ist, nimmt//sich jeder andere Spieler einen Fluch.";
GermanCardTexts[CardNames.SECRET_PASSAGE] = "|+2 Karten|//|+1 Aktion|////Lege eine deiner Handkarten//an eine beliebige Stelle inner-//halb deines Nachziehstapels.";
GermanCardTexts[CardNames.SHANTY_TOWN] = "|+2 Aktionen|////Decke deine Hand auf.//Wenn du keine Aktionskarten//auf der Hand hast, |+2 Karten|.";
GermanCardTexts[CardNames.STEWARD] = "W\xE4hle eines: |+2 Karten|;//oder +[2]; oder entsorge zwei//Karten aus deiner Hand.";
GermanCardTexts[CardNames.SWINDLER] = "|+[2]|////Jeder andere Spieler entsorgt die//oberste Karte seines Nachzieh-//stapels und nimmt eine Karte//deiner Wahl, die gleich viel kostet.";
GermanCardTexts[CardNames.SWINDLER] = "|+[2]|////Jeder andere Spieler entsorgt//die oberste Karte seines//Nachziehstapels und nimmt//eine Karte deiner Wahl,//die gleich viel kostet.";
GermanCardTexts[CardNames.TORTURER] = "|+3 Karten|////Jeder andere Spieler w\xE4hlt eines://Entweder er legt zwei Handkarten//ab; oder er nimmt sich einen Fluch//auf die Hand (die gew\xE4hlte Option//muss nicht durchf\xFChrbar sein).";
GermanCardTexts[CardNames.TRADING_POST] = "Entsorge zwei Karten aus deiner//Hand. Wenn du das gemacht hast,//nimm dir ein Silber auf die Hand.";
GermanCardTexts[CardNames.UPGRADE] = "|+1 Karte|//|+1 Aktion|////Entsorge eine Karte aus deiner//Hand. Nimm dir eine Karte,//die genau [1] mehr kostet.";
GermanCardTexts[CardNames.WISHING_WELL] = "|+1 Karte|//|+1 Aktion|////Nenne eine Karte, dann decke die//oberste Karte deines Nachzieh-//stapels auf. Ist es die genannte//Karte, nimm sie auf die Hand.";

GermanCardTexts[CardNames.AMBASSADOR] = "Decke eine Karte aus deiner//Hand auf. Lege bis zu zwei//Exemplare dieser Karte aus deiner//Hand zur\xFCck in den Vorrat. Dann//nimmt sich jeder andere Spieler//ein Exemplar dieser Karte.";
GermanCardTexts[CardNames.BAZAAR] = "|+1 Karte|//|+2 Aktionen|//|+[1]|";
GermanCardTexts[CardNames.CARAVAN] = "|+1 Karte|//|+1 Aktion|////Zu Beginn deines n\xE4chsten//Zuges: |+1 Karte|";
GermanCardTexts[CardNames.CUTPURSE] = "|+[2]|////Jeder andere Spieler legt ein//Kupfer aus seiner Hand ab, oder//er deckt seine Hand auf, wenn//er kein Kupfer auf der Hand hat.";
GermanCardTexts[CardNames.EMBARGO] = "|+[2]|////Entsorge diese Karte. Wenn du das//getan hast, lege einen Embargomarker//auf einen Vorratsstapel. (Bis zum Spiel-//ende, wenn ein Spieler eine Karte von //diesem Stapel kauft, nimmt dieser //Spieler sich einen Fluch).";
GermanCardTexts[CardNames.EXPLORER] = "Du darfst eine Provinz//aus deiner Hand//aufdecken. Wenn du//das machst, nimm dir ein//Gold auf die Hand.//Ansonsten, nimm dir ein//Silber auf die Hand.";
GermanCardTexts[CardNames.FISHING_VILLAGE] = "|+2 Aktionen|//|+[1]|////Zu Beginn deines n\xE4chsten// Zuges: |+1 Aktion| und |+|[1].";
GermanCardTexts[CardNames.GHOST_SHIP] = "|+2 Karten|////Jeder andere Spieler mit mindes-//tens 4 Karten auf der Hand legt//Karten aus seiner Hand oben auf//den Nachziehstapel, bis er nur//noch 3 Karten auf der Hand hat.";
GermanCardTexts[CardNames.HAVEN] = "|+1 Karte|//|+1 Aktion|////Lege eine Karte aus deiner Hand//verdeckt zur Seite. Zu Beginn//deines n\xE4chsten Zuges:// Nimm diese Karte auf deine Hand.";
GermanCardTexts[CardNames.ISLAND] = "Lege diese Karte und eine//Karte aus deiner Hand//offen auf dein Insel-Tableau.////---////{!2}";
GermanCardTexts[CardNames.LIGHTHOUSE] = "|+1 Aktion|//|+[1]|////Zu Beginn deines nächsten//Zuges: +[1]. Spielt bis dahin ein//Mitspieler eine Angriffskarte,//bist du von ihr nicht betroffen.";
GermanCardTexts[CardNames.LOOKOUT] = "|+1 Aktion|////Sieh dir die obersten 3 Karten//deines Nachziehstapels an. Ent-//sorge eine der Karten. Lege eine//der Karten ab. Lege die andere//auf deinen Nachziehstapel zur\xFCck.";
GermanCardTexts[CardNames.MERCHANT_SHIP] = "Jetzt und zu Beginn deines//n\xE4chsten Zuges: +[2]";
GermanCardTexts[CardNames.NATIVE_VILLAGE] = "|+2 Aktionen|////W\xE4hle eines: Lege die oberste Karte//deines Nachziehstapels verdeckt auf dein//Eingeborenendorf-Tableau; oder nimm//alle Karten von dem Tableau auf deine//Hand. Du darfst dir die Karten auf dem//Tableau jederzeit ansehen.";
GermanCardTexts[CardNames.NAVIGATOR] = "|+[2]|////Sieh dir die obersten 5 Karten//deines Nachziehstapels an.//Lege entweder alle ab oder in//beliebiger Reihenfolge zur\xFCck//auf deinen Nachziehstapel.";
GermanCardTexts[CardNames.OUTPOST] = "Du ziehst nur 3 Karten//f\xFCr deine n\xE4chste Hand.////Du erh\xE4ltst einen//zus\xE4tzlichen Zug nach//diesem Zug (aber keinen//3. Zug in Folge).";
GermanCardTexts[CardNames.PEARL_DIVER] = "|+1 Karte|//|+1 Aktion|////Sieh dir die unterste Karte//deines Nachziehstapels an.//Du darfst sie auf deinen//Nachziehstapel legen.";
GermanCardTexts[CardNames.PIRATE_SHIP] = "//////W\xE4hle eines: +[1] pro Marker auf deinem//Piratenschiff-Tableau; oder jeder Mit-//spieler deckt die obersten beiden Karten//seines Nachziehstapels auf, entsorgt eine//der aufgedeckten Geldkarten deiner Wahl//und legt den Rest ab, und wenn dabei// Geldkarten entsorgt wurden, lege einen//Marker auf dein Piratenschiff-Tableau.";
GermanCardTexts[CardNames.SALVAGER] = "|+1 Kauf|////Entsorge eine Karte aus//deiner Hand.+[1] pro [1],//das die Karte kostet.";
GermanCardTexts[CardNames.SEA_HAG] = "Jeder Mitspieler legt//die oberste Karte seines//Nachziehstapels ab und//nimmt dann einen Fluch//auf seinen Nachziehstapel.";
GermanCardTexts[CardNames.SMUGGLERS] = "Nimm dir ein Exemplar einer//Karte, die bis zu [6] kostet, die//sich der Spieler rechts von dir in//seinem letzten Zug genommen hat.";
GermanCardTexts[CardNames.TACTICIAN] = "Wenn du mindestens eine Karte//auf der Hand hast: Lege deine//Handkarten ab und zu Beginn//deines n\xE4chsten Zuges:// |+5 Karten|, |+1 Aktion|// und |+1 Kauf|.";
GermanCardTexts[CardNames.TREASURE_MAP] = "//Entsorge diese Karte und eine//Schatzkarte aus deiner Hand.////Wenn du zwei Schatzkarten//entsorgt hast, nimm 4 Gold//auf deinen Nachziehstapel.";
GermanCardTexts[CardNames.TREASURY] = "|+1 Karte|//|+1 Aktion|//|+[1]|////Am Ende deiner Kaufphase dieses//Zuges darfst du, wenn du in ihr keine//Punktekarte genommen hast, diese//Karte auf deinen Nachziehstapel legen.";
GermanCardTexts[CardNames.WAREHOUSE] = "|+3 Karten|//|+1 Aktion|////Lege 3 Handkarten ab.";
GermanCardTexts[CardNames.WHARF] = "Jetzt und zu Beginn deines//n\xE4chsten Zuges:////|+2 Karten|//|+1 Kauf|";
// Seaside 2E
GermanCardTexts[CardNames.MONKEY] = "Bis zu deinem nächsten//Zug: |+1 Karte|, wenn//dein rechter Mitspieler//eine Karte nimmt.//////Zu Beginn deines nächsten//Zuges: |+1 Karte|.";
GermanCardTexts[CardNames.BLOCKADE] = "Nimm eine Karte, die bis zu//[4] kostet, direkt zur Seite.////Zu Beginn deines nächsten Zuges://Nimm sie auf die Hand. Solange//sie zur Seite gelegt ist,//nimmt ein Mitspieler, der in//seinem Zug eine gleiche//Karte nimmt, einen Fluch.";
GermanCardTexts[CardNames.SAILOR] = "|+1 Aktion|////Einmal in diesem Zug://Wenn du eine Dauerkarte//nimmst, darfst du sie spielen.////Zu Beginn deines nächsten//Zuges: +[2] und du darfst eine//deiner Handkarten entsorgen.";
GermanCardTexts[CardNames.PIRATE] = "Zu Beginn deines nächsten//Zuges: Nimm eine Geldkarte,//die bis zu [6] kostet, auf//deine Hand.//---//Nimmt ein Spieler eine//Geldkarte, darfst du diese//Karte (die Piratin) aus//deiner Hand spielen.";
GermanCardTexts[CardNames.CORSAIR] = "|+[2]|//Zu Beginn deines nächsten//Zuges: |+1 Karte|. Bis dahin//entsorgt jeder Mitspieler//das erste Silber oder Gold,//das er in einem Zug spielt.";
GermanCardTexts[CardNames.SEA_CHART] = "|+1 Karte|//|+1 Aktion|////Decke die oberste Karte deines//Nachziehstapels auf. Nimm sie//auf deine Hand, wenn du eine//gleiche Karte im Spiel hast.";
GermanCardTexts[CardNames.ASTROLABE] = "Jetzt und zu Beginn//deines nächsten Zuges://////[!1]//|+1 Kauf|";
GermanCardTexts[CardNames.TIDE_POOLS] = "|+3 Karten|//|+1 Aktion|////Zu Beginn deines nächsten//Zuges: Lege 2 Karten ab.";
GermanCardTexts[CardNames.SEA_WITCH] = "|+2 Karten|////Jeder Mitspieler nimmt//einen Fluch.////Zu Beginn deines nächsten//Zuges: |+2 Karten|, lege//dann 2 Karten ab.";

GermanCardTexts[CardNames.ALCHEMIST] = "|+2 Karte|//|+1 Aktion|//Zu Beginn der Aufr\xE4umphase//dieses Zuges darfst du diese//Karte auf deinen Nachzieh-//stapel legen, wenn du einen//Trank im Spiel hast.";
GermanCardTexts[CardNames.APOTHECARY] = "|+1 Karte|//|+1 Aktion|////Decke die obersten 4 Karten deines//Nachziehstapels auf. Nimm die//aufgedeckten Kupfer und Tr\xE4nke//auf deine Hand. Lege den Rest//in beliebiger Reihenfolge zur\xFCck//auf deinen Nachziehstapel.";
GermanCardTexts[CardNames.APPRENTICE] = "|+1 Aktion|////Entsorge eine Karte//aus deiner Hand.////|+1 Karte| pro [1], das die Karte//kostet.//|+2 Karten|, wenn sie [P] kostet.";
GermanCardTexts[CardNames.FAMILIAR] = "|+1 Karte|//|+1 Aktion|////Jeder andere Spieler nimmt//sich einen Fluch.";
GermanCardTexts[CardNames.GOLEM] = "Decke Karten von deinem//Nachziehstapel auf, bis du 2//Aktionskarten aufgedeckt hast,//die keine Golem-Karten sind.//Lege die anderen Karten ab, dann//spiele die 2 Aktionskarten in//beliebiger Reihenfolge.";
GermanCardTexts[CardNames.HERBALIST] = "|+1 Kauf|//|+[1]|////Einmal in diesem Zug darfst du//eine Geldkarte auf deinen//Nachziehstapel legen, wenn//du sie aus dem Spiel ablegst.";
GermanCardTexts[CardNames.PHILOSOPHERS_STONE] = "Z\xE4hle die Karten in deinem//Nachziehstapel und Ablagestapel.//|+|[1] pro 5 Karten der Summe//der beiden Stapel (abgerundet).";
GermanCardTexts[CardNames.POSSESSION] = "Dein linker Mitspieler erh\xE4lt einen//Extrazug nach diesem Zug (aber keinen//2. Extrazug in Folge), in dem du alle//Karten sehen darfst, die er sieht,//und alle Entscheidungen f\xFCr ihn triffst.////Alle Karten und [D], die er in dem//Zug nehmen w\xFCrde, nimmst stattdessen//du; alle seine Karten, die entsorgt wer-//den, werden zur Seite gelegt und am Ende//des Zuges in seinen Ablagestapel gelegt.";
GermanCardTexts[CardNames.POTION] = "";
GermanCardTexts[CardNames.SCRYING_POOL] = "|+1 Aktion|////Jeder Spieler (auch du) deckt die oberste//Karte seines Nachziehstapels auf und legt//diese, nach deiner Wahl, entweder ab oder//zur\xFCck auf den Nachziehstapel. Dann//decke Karten von deinem Nachziehstapel//auf, bis du eine Karte aufdeckst, die keine//Aktion ist. Nimm alle aufgedeckten//Karten auf deine Hand.";
GermanCardTexts[CardNames.SCRYING_POOL] = "|+1 Aktion|////Jeder Spieler (auch du) deckt die oberste//Karte seines Nachziehstapels auf und legt//diese, nach deiner Wahl, entweder ab//oder zur\xFCck auf den Nachziehstapel.// Dann decke Karten von deinem Nach-//ziehstapel auf, bis du eine Karte auf-//deckst, die keine Aktion ist. Nimm alle//aufgedeckten Karten auf deine Hand.";
GermanCardTexts[CardNames.TRANSMUTE] = "Entsorge eine Karte aus deiner Hand. //Ist es eine...////Aktionskarte, nimm dir ein Herzogtum//Geldkarte, nimm dir eine Verwandlung//Punktekarte, nimm dir ein Gold";
GermanCardTexts[CardNames.UNIVERSITY] = "|+2 Aktionen|////Du darfst dir eine Aktionskarte//nehmen, die bis zu [5] kostet.";
GermanCardTexts[CardNames.VINEYARD] = "Wert {1} pro 3 Aktionskarten,//die du besitzt (abgerundet).";
GermanCardTexts[CardNames.BANK] = "+[1] pro Geldkarte, die//du im Spiel hast//(inklusive dieser).";
GermanCardTexts[CardNames.BISHOP] = "|+[1]|//|+{1}|//Entsorge eine deiner Hand-//karten. |+1|{} pro [2], die jene//kostet (abgerundet). Jeder//Mitspieler darf eine Karte//aus seiner Hand entsorgen.";
GermanCardTexts[CardNames.COLONY] = "";
GermanCardTexts[CardNames.CONTRABAND] = "[!3]////|+1 Kauf|////Der Spieler links von dir nennt//eine Karte. Diese Karte kannst//du in diesem Zug nicht kaufen.";
GermanCardTexts[CardNames.COUNTING_HOUSE] = "Durchsuche deinen//Ablagestapel. Decke eine//beliebige Anzahl Kupfer//daraus auf und nimm sie//auf die Hand.";
GermanCardTexts[CardNames.CITY] = "|+1 Karte|//|+2 Aktionen|////Wenn 1 oder mehr Vorratsstapel//leer sind: |+1 Karte|.//Wenn 2 oder mehr Vorratsstapel//leer sind: zus\xE4tzlich//|+1 Kauf| und |+|[1] .";
GermanCardTexts[CardNames.EXPAND] = "Entsorge eine deiner//Handkarten.//Nimm eine Karte,//die bis zu [3] mehr//als jene kostet.";
GermanCardTexts[CardNames.FORGE] = "//Entsorge beliebig viele//deiner Handkarten. Nimm//eine Karte, die genauso//viel kostet, wie jene Karten//in [1] zusammen.";
GermanCardTexts[CardNames.GRAND_MARKET] = "|+1 Karte|//|+1 Aktion|//|+1 Kauf|//|+[2]|//---//Du kannst diese Karte nicht kaufen,//wenn du Kupfer im Spiel hast.";
GermanCardTexts[CardNames.GOONS] = "|+1 Kauf|//|+[2]|////Jeder andere Spieler legt alle bis auf//drei Karten von seiner Hand ab.//---//Wenn du eine Karte kaufst, w\xE4hrend//du diese Karte im Spiel hast, |+|{1}.";
GermanCardTexts[CardNames.HOARD] = "[!2]////---////Wenn du eine Punktekarte kaufst,// w\xE4hrend du diese Karte im Spiel//hast, nimm dir ein Gold.";
GermanCardTexts[CardNames.KINGS_COURT] = "Du darfst eine Aktionskarte aus//deiner Hand dreimal spielen.";
GermanCardTexts[CardNames.LOAN] = "[!1]////Decke von deinem Nachziehstapel//Karten auf, bis du eine Geldkarte //aufdeckst. Entsorge sie oder//lege sie ab. Lege die anderen//aufgedeckten Karten ab.";
GermanCardTexts[CardNames.MINT] = "Du darfst eine Geldkarte aus//deiner Hand aufdecken.//Nimm dir eine solche Karte.//---//Wenn du diese Karte kaufst,//entsorge alle Geldkarten,//die du im Spiel hast.";
GermanCardTexts[CardNames.MONUMENT] = "|+|[2]////|+|{1}";
GermanCardTexts[CardNames.MOUNTEBANK] = "|+[2]|////Jeder andere Spieler darf einen//Fluch aus seiner Hand ablegen.//Wenn er das nicht macht, nimmt er//sich einen Fluch und ein Kupfer.";
GermanCardTexts[CardNames.PEDDLER] = "|+1 Karte|//|+1 Aktion|//|+[1]|//---//W\xE4hrend der Kaufphase eines//Spielers kostet diese Karte [2]//weniger pro Aktionskarte,//die er im Spiel hat.";
GermanCardTexts[CardNames.PLATINUM] = "";
GermanCardTexts[CardNames.QUARRY] = "[!1]////---////W\xE4hrend diese Karte im//Spiel ist, kosten Aktions-//karten [2] weniger.";
GermanCardTexts[CardNames.RABBLE] = "|+3 Karten|////Jeder Mitspieler deckt die//obersten 3 Karten seines//Nachziehstapels auf, legt alle//Aktions- und Geldkarten//davon ab und den Rest in//beliebiger Reihenfolge zur\xFCck.";
GermanCardTexts[CardNames.ROYAL_SEAL] = "[!2]////---////Wenn du eine Karte nimmst,//w\xE4hrend du diese Karte im Spiel //hast, darfst du jene Karte auf//deinen Nachziehstapel legen.";
GermanCardTexts[CardNames.TALISMAN] = "[!1]////---////Wenn du diese Karte im Spiel hast://Wenn du eine Karte kaufst, die [4]//oder weniger kostet, und die keine//Punktekarte ist, nimm dir ein//weiteres Exemplar jener Karte.";
GermanCardTexts[CardNames.TRADE_ROUTE] = "|+1 Kauf|//Entsorge eine Karte aus deiner Hand.//|+|[1] pro Marker auf dem Tableau// Handelsroute.//---//Spielvorbereitung: Lege auf jeden//Punktekarten-Vorratsstapel einen//Marker. Wenn die erste Karte von einem//der Stapel genommen wird, lege den//Marker auf das Tableau Handelsroute.";
GermanCardTexts[CardNames.VAULT] = "|+2 Karten|////Lege beliebig viele deiner//Handkarten f\xFCr je-//weils |+|[1] ab.////Jeder Mitspieler darf 2 Karten//ablegen, um 1 Karte zu ziehen.";
GermanCardTexts[CardNames.VENTURE] = "[!1]////Decke von deinem Nachziehstapel//Karten auf, bis du eine Geldkarte//aufdeckst. Lege die anderen//Karten ab. Spiele die Geldkarte.";
GermanCardTexts[CardNames.WATCHTOWER] = "Ziehe, bis du 6 Karten//auf deiner Hand hast.//---//Wenn du eine Karte nimmst, darfst//du diese Karte aus deiner Hand//aufdecken, um entweder jene//Karte zu entsorgen oder sie auf//deinen Nachziehstapel zu legen.";
GermanCardTexts[CardNames.WORKERS_VILLAGE] = "|+1 Karte|//|+2 Aktionen|//|+1 Kauf|";
GermanCardTexts[CardNames.PRIZE_PILE] = "";
GermanCardTexts[CardNames.BAG_OF_GOLD] = "|+1 Aktion|////Nimm dir ein Gold auf//deinen Nachziehstapel.////%(Diese Karte geh\xF6rt%//%nicht zum Vorrat.)%";
GermanCardTexts[CardNames.DIADEM] = "[!2]////|+|[1] pro ungenutzter Aktion,//die du hast (Aktion,//nicht Aktionskarte).////%(Diese Karte geh\xF6rt%//%nicht zum Vorrat.)%";
GermanCardTexts[CardNames.FAIRGROUNDS] = "Wert {2} f\xFCr je 5 Karten mit//unterschiedlichen Namen, die//du besitzt (abgerundet).";
GermanCardTexts[CardNames.FARMING_VILLAGE] = "|+2 Aktionen|////Decke Karten von deinem//Nachziehstapel auf, bis du eine//Aktions- oder Geldkarte aufdeckst.//Nimm diese auf die Hand und//lege den Rest ab.";
GermanCardTexts[CardNames.FOLLOWERS] = "|+2 Karten|////Nimm dir ein Anwesen. Jeder//andere Spieler nimmt sich einen//Fluch und legt alle bis auf drei//Karten von seiner Hand ab.//%(Diese Karte geh\xF6rt%//%nicht zum Vorrat.)%";
GermanCardTexts[CardNames.FORTUNE_TELLER] = "|+[2]|////Jeder andere Spieler deckt Karten//von seinem Nachziehstapel auf,//bis er eine Punktekarte oder//einen Fluch aufdeckt. Er legt//diese auf den Nachziehstapel//und den Rest ab.";
GermanCardTexts[CardNames.HAMLET] = "|+1 Karte|//|+1 Aktion|////Du darfst eine Karte aus deiner//Hand f\xFCr |+1 Aktion| ablegen.//Du darfst eine Karte aus deiner//Hand f\xFCr |+1 Kauf| ablegen.";
GermanCardTexts[CardNames.HARVEST] = "Decke die obersten 4 Karten//deines Nachziehstapels auf, dann//lege sie ab. +[1] pro Karte mit//unterschiedlichem Namen, die//du aufgedeckt hast.";
GermanCardTexts[CardNames.HARVEST] = "Decke die obersten 4//Karten deines Nachzieh-//stapels auf, dann lege sie ab.//+[1] pro Karte mit unter-//schiedlichem Namen, die//du aufgedeckt hast.";
GermanCardTexts[CardNames.HORSE_TRADERS] = "|+1 Kauf|//|+[3]|//Lege zwei Karten aus deiner Hand ab.//---//Wenn ein anderer Spieler eine Angriffs-//karte spielt, darfst du zuerst diese//Karte aus deiner Hand zur Seite legen.//Wenn du das machst, zu Beginn deines//n\xE4chsten Zuges |+1 Karte| und nimm//diese Karte zur\xFCck auf die Hand.";
GermanCardTexts[CardNames.HORN_OF_PLENTY] = "////Nimm eine Karte, die bis zu [!1]//pro Karte mit unterschiedlichem//Namen im Spiel kostet (diese//eingeschlossen).Wenn es eine//Punktekarte ist, entsorge//das F\xFCllhorn.";
GermanCardTexts[CardNames.HUNTING_PARTY] = "|+1 Karte|//|+1 Aktion|////Decke deine Hand auf. Decke Karten//von deinem Nachziehstapel auf, bis//du eine Karte aufdeckst, von der du//kein Exemplar auf der Hand hast.//Nimm diese Karte auf die Hand und//lege den Rest ab.";
GermanCardTexts[CardNames.JESTER] = "|+[2]|////Jeder andere Spieler legt die oberste//Karte seines Nachziehstapels ab.//Wenn es eine Punktekarte ist, nimmt der//Spieler einen Fluch; ansonsten nimmst//entweder du ein Exemplar dieser Karte//oder der Spieler - du entscheidest.";
GermanCardTexts[CardNames.MENAGERIE] = "|+1 Aktion|////Decke deine Hand auf.//Wenn die aufgedeckten Karten//alle unterschiedliche Namen//haben, |+3 Karten|.// Ansonsten, |+1 Karte|.";
GermanCardTexts[CardNames.PRINCESS] = "|+1 Kauf|////In diesem Zug kosten//alle Karten [2] weniger.////%(Diese Karte geh\xF6rt%//%nicht zum Vorrat.)%";
GermanCardTexts[CardNames.REMAKE] = "Mache dies zweimal: Entsorge//eine Karte aus deiner Hand,//dann nimm dir eine Karte, die//genau [1] mehr kostet.";
GermanCardTexts[CardNames.REMAKE] = "Mache dies zweimal://Entsorge eine Karte aus//deiner Hand, dann nimm//dir eine Karte, die//genau [1] mehr kostet.";
GermanCardTexts[CardNames.TOURNAMENT] = "|+1 Aktion|////Jeder Spieler darf eine Provinz aus//seiner Hand aufdecken. Wenn du das//machst, lege sie ab und nimm dir//einen Preis (vom Preisstapel) oder ein//Herzogtum auf deinen Nachziehstapel.//Wenn kein anderer Spieler das macht,//|+1 Karte| und +[1].";
GermanCardTexts[CardNames.TRUSTY_STEED] = "W\xE4hle zwei verschiedene: //|+2 Karten|;//|+2 Aktionen|;//+[2];//nimm dir 4 Silber und lege//deinen Nachziehstapel auf//deinen Ablagestapel.////%(Diese Karte geh\xF6rt nicht zum Vorrat.)%";
GermanCardTexts[CardNames.YOUNG_WITCH] = "|+2 Karten|////Lege 2 Karten aus deiner Hand ab.//Jeder Mitpieler nimmt einen Fluch,//falls er keine Bannkarte aus seiner//Hand aufdeckt.//---//Spielvorbereitung: Lege einen zus\xE4tz-//lichen K\xF6nigreich-Stapel aus, dessen//Karten zwischen [2] und [3] kosten.//Dessen Karten sind Bannkarten.";
GermanCardTexts[CardNames.BORDER_VILLAGE] = "|+1 Karte|//|+2 Aktionen|//---//Wenn du diese Karte nimmst,//nimm eine billigere Karte.";
GermanCardTexts[CardNames.CACHE] = "[!3]////---////Wenn du diese Karte nimmst,//nimm dir zwei Kupfer.";
GermanCardTexts[CardNames.CARTOGRAPHER] = "|+1 Karte|//|+1 Aktion|////Sieh die obersten 4 Karten//deines Nachziehstapels an.//Lege beliebig viele davon//ab, dann lege den Rest in//beliebiger Reihenfolge zur\xFCck.";
GermanCardTexts[CardNames.CROSSROADS] = "//Decke deine Handkarten auf:// |+1 Karte| pro//aufgedeckter Punktekarte.////Ist dies das erste Mal in diesem//Zug, dass du eine Wegkreuzung//gespielt hast: |+3 Aktionen|.";
GermanCardTexts[CardNames.DEVELOP] = "Entsorge eine deiner Hand-//karten. Nimm zwei Karten, von//denen eine genau [1] mehr//als sie kostet und eine genau//[1] weniger als sie kostet,//in beliebiger Reihenfolge//auf deinen Nachziehstapel.";
GermanCardTexts[CardNames.DUCHESS] = "|+[2]|////Jeder Spieler (du eingeschlossen)//sieht sich die oberste Karte seines//Nachziehstapels an und darf sie ablegen.//---//Wenn Herzogin im Vorrat ist, darfst//du dir eine Herzogin nehmen,//wenn du dir ein Herzogtum nimmst.";
GermanCardTexts[CardNames.EMBASSY] = "|+5 Karten|////Lege 3 Handkarten ab.//---//Wenn du dir diese Karte//nimmst, nimmt sich jeder//andere Spieler ein Silber.";
//GermanCardTexts[CardNames.FARMLAND] = `{!2}//---//Wenn du diese Karte kaufst, ent-//sorge eine Karte aus deiner Hand.//Nimm dir eine Karte, die genau [!2]//mehr als die entsorgte Karte kostet.`; //pre-2E wording
GermanCardTexts[CardNames.FARMLAND] = "{!2}//---//Wenn du diese Karte nimmst, ent-//sorge eine deiner Handkarten und//nimm eine Karte, die genau [2]//mehr kostet als die entsorgte Karte//und die kein Fruchtbares Land ist.";
GermanCardTexts[CardNames.FOOLS_GOLD] = "//Wenn dies das erste Mal in diesem//Zug ist, dass du ein Katzengold//gespielt hast: +[1], ansonsten +[4].//---//Nimmt ein Mitspieler eine Provinz,//darfst du diese Karte aus deiner//Hand entsorgen, um ein Gold auf//deinen Nachziehstapel zu nehmen.";
//GermanCardTexts[CardNames.HAGGLER] = `|+[2]|////---////Während du diese Karte im Spiel//hast und du eine Karte kaufst,//nimm dir eine Karte, die weniger//kostet und keine Punktekarte ist.`; //pre-2E wording
GermanCardTexts[CardNames.HAGGLER] = "|+[2]|////Wenn du in diesem Zug//eine von dir gekaufte//Karte nimmst, nimm eine//billigere Nicht-Punktekarte.";
//GermanCardTexts[CardNames.HIGHWAY] = `|+1 Karte|//|+1 Aktion|//---//Während diese Karte im Spiel ist,//kosten alle Karten [1] weniger.`; // pre-2E wording
GermanCardTexts[CardNames.HIGHWAY] = "|+1 Karte|//|+1 Aktion|//////In diesem Zug kosten//Karten [1] weniger.";
GermanCardTexts[CardNames.ILL_GOTTEN_GAINS] = "[!1]//Du darfst dir ein Kupfer//auf die Hand nehmen.//---//Wenn du diese Karte nimmst,//nimmt sich jeder andere//Spieler einen Fluch.";
GermanCardTexts[CardNames.INN] = "|+ 2 Karten|//|+2 Aktionen|////Lege 2 Karten ab.//---//Wenn du diese Karte nimmst,//decke beliebig viele//Aktionskarten von deinem//Ablagestapel auf und mische//sie in deinen Nachziehstapel.";
GermanCardTexts[CardNames.JACK_OF_ALL_TRADES] = "Nimm ein Silber. Sieh die//oberste Karte deines Nachzieh-//stapels an, du darfst sie ablegen.//Zieh, bis du 5 Handkarten hast.//Du darfst eine Nicht-Geldkarte//aus deiner Hand entsorgen.";
GermanCardTexts[CardNames.MANDARIN] = "|+[3]|//Lege eine Karte aus deiner Hand//auf deinen Nachziehstapel.//---//Wenn du diese Karte nimmst, lege//alle Geldkarten, die du im Spiel //hast, in beliebiger Reihenfolge//auf deinen Nachziehstapel.";
GermanCardTexts[CardNames.NOBLE_BRIGAND] = "|+[1]|//Jeder Mitpieler deckt die obersten 2 Kar-//ten seines Nachziehstapels auf, entsorgt//ein aufgedecktes Silber oder Gold nach//deiner Wahl und legt den Rest ab. Wer//keine Geldkarte aufdeckt, nimmt ein//Kupfer. Nimm die entsorgten Karten.//---//Wenn du diese Karte kaufst,//f\xFChre ihren Angriff aus.";
GermanCardTexts[CardNames.NOMAD_CAMP] = "|+1 Kauf|//|+[2]|//---//Diese Karte wird auf den//Nachziehstapel genommen//(anstatt auf den Ablagestapel).";
GermanCardTexts[CardNames.OASIS] = "|+1 Karte|//|+1 Aktion|//|+[1]|////Lege eine Karte ab.";
GermanCardTexts[CardNames.ORACLE] = "Jeder Spieler (du eingeschlossen)//deckt die obersten beiden Karten//seines Nachziehstapels auf und legt //sie ab oder zur\xFCck, nach deiner Wahl.//Jeder Spieler legt die Karten in der//Reihenfolge seiner Wahl zur\xFCck.//Du erh\xE4lst |+2 Karten|.";
GermanCardTexts[CardNames.ORACLE] = "//Jeder Spieler (du eingeschlossen)//deckt die obersten beiden Karten//seines Nachziehstapels auf und legt//sie ab oder zur\xFCck, nach deiner//Wahl. Jeder Spieler legt die Karten//in der Reihenfolge seiner Wahl//zur\xFCck. Du erh\xE4lst |+2 Karten|.";
GermanCardTexts[CardNames.MARGRAVE] = "|+3 Karten|//|+1 Kauf|////Jeder Mitspieler zieht eine//Karte und legt dann seine//Handkarten bis auf 3 ab.";
GermanCardTexts[CardNames.SCHEME] = "|+1 Karte|//|+1 Aktion|////In diesem Zug darfst du//eine Aktionskarte auf deinen//Nachziehstapel legen, wenn//du sie aus dem Spiel ablegst.";
GermanCardTexts[CardNames.SILK_ROAD] = "Wert {1} pro 4 Punktekarten,//die du besitzt (abgerundet).";
GermanCardTexts[CardNames.SPICE_MERCHANT] = "Du darfst eine Geldkarte aus//deiner Hand entsorgen, um//eins zu w\xE4hlen://// |+2 Karten| und| +1 Aktion|//oder//|+1 Kauf| und| +|[2].";
GermanCardTexts[CardNames.STABLES] = "Du darfst eine Geldkarte//ablegen f\xFCr//| +3 Karten| und| +1 Aktion|.";
GermanCardTexts[CardNames.TRADER] = "Entsorge eine deiner Handkarten.//Nimm pro [1], das die entsorgte//Karte kostet, ein Silber.//---//Wenn du eine Karte nimmst,//darfst du diese Karte aus deiner//Hand aufdecken, um jene Karte//in ein Silber einzutauschen.";
GermanCardTexts[CardNames.TUNNEL] = "{!2}//---//Wenn du diese Karte au\xDFerhalb//der Aufr\xE4umphase ablegst,//darfst du sie aufdecken, um//ein Gold nehmen.";
GermanCardTexts[CardNames.RUIN_PILE] = "";
GermanCardTexts[CardNames.KNIGHTS] = "Mische den Ritter-Stapel vor dem//Spiel. Lege ihn verdeckt in den//Vorrat au\xDFer der obersten Karte.//Nur die jeweils oberste Karte kann//genommen oder gekauft werden.";
GermanCardTexts[CardNames.ABANDONED_MINE] = "|+[1]|";
GermanCardTexts[CardNames.ALTAR] = "Entsorge eine Karte//aus deiner Hand.//Nimm dir eine Karte,//die bis zu [5] kostet.";
GermanCardTexts[CardNames.ARMORY] = "Nimm dir eine Karte, die bis//zu [4] kostet, und lege sie//auf deinen Nachziehstapel.";
GermanCardTexts[CardNames.BAND_OF_MISFITS] = "Spiele eine Aktionskarte aus//dem Vorrat, die keine//Befehlskarte ist und weniger//als diese Karte kostet//und lasse sie dort liegen.";
GermanCardTexts[CardNames.BANDIT_CAMP] = "|+1 Karte|//|+2 Aktionen|////Nimm dir eine Beute.";
GermanCardTexts[CardNames.BEGGAR] = "Nimm dir 3 Kupfer auf die Hand.//---//Wenn ein anderer Spieler eine//Angriffskarte spielt, darfst du zuerst//diese Karte ablegen und dir 2//Silber nehmen, wovon du eins auf//deinen Nachziehstapel legst.";
GermanCardTexts[CardNames.BEGGAR] = "//Nimm dir 3 Kupfer auf die Hand.//---//Wenn ein anderer Spieler eine//Angriffskarte spielt, darfst du//zuerst diese Karte ablegen und dir//2 Silber nehmen, wovon du eins//auf deinen Nachziehstapel legst.";
GermanCardTexts[CardNames.CATACOMBS] = "Sieh dir die obersten 3 Karten//deines Nachziehstapels an. W\xE4hle//eines: Nimm sie auf die Hand;//oder lege sie ab und |+3 Karten|.//---//Wenn du diese Karte entsorgst,// nimm dir eine Karte,//die weniger kostet.";
GermanCardTexts[CardNames.COUNT] = "W\xE4hle eines: Lege 2 Handkarten// ab; oder lege eine Handkarte auf//deinen Nachziehstapel;//oder nimm dir ein Kupfer.////W\xE4hle eines: +[3];//oder entsorge deine Handkarten;//oder nimm dir ein Herzogtum.";
GermanCardTexts[CardNames.COUNTERFEIT] = "[!1]////|+1 Kauf|////Du darfst eine Geldkarte, die//keine Dauerkarte ist, aus//deiner Hand zweimal//spielen. Entsorge sie.";
GermanCardTexts[CardNames.CULTIST] = "|+2 Karten|////Jeder andere Spieler nimmt sich//eine Ruine. Du darfst einen//Kultisten aus deiner Hand spielen.//---//Wenn du diese Karte entsorgst, //|+3 Karten|.";
GermanCardTexts[CardNames.DAME_ANNA] = "Du darfst bis zu 2 Karten//aus deiner Hand entsorgen.////Jeder andere Spieler deckt die obersten//beiden Karten seines Nachziehstapels//auf, entsorgt eine der beiden Karten, die//zwischen [3] und [6] kostet, und legt//den Rest ab. Wenn dabei ein Ritter//entsorgt wird, entsorge diese Karte.";
GermanCardTexts[CardNames.DAME_JOSEPHINE] = "Jeder andere Spieler deckt die//obersten beiden Karten seines Nachzieh-//stapels auf, entsorgt eine der beiden Kar-//ten, die zwischen [3] und [6] kostet, und//legt den Rest ab. Wenn dabei ein Ritter//entsorgt wird, entsorge diese Karte.//---//{!2}";
GermanCardTexts[CardNames.DAME_JOSEPHINE] = "Jeder Mitspieler deckt die//obersten beiden Karten seines//Nachziehstapels auf, entsorgt eine//der beiden Karten, die zwischen//[3] und [6] kostet, und legt den Rest//ab. Wenn dabei ein Ritter ent-//sorgt wird, entsorge diese Karte.//---//{!2}";
GermanCardTexts[CardNames.DAME_MOLLY] = "|+2 Aktionen|////Jeder andere Spieler deckt die obersten//beiden Karten seines Nachziehstapels//auf, entsorgt eine der beiden Karten, die//zwischen [3] und [6] kostet, und legt//den Rest ab. Wenn dabei ein Ritter//entsorgt wird, entsorge diese Karte.";
GermanCardTexts[CardNames.DAME_NATALIE] = "Du darfst dir eine Karte nehmen,//die bis zu [3] kostet.////Jeder andere Spieler deckt die obersten//beiden Karten seines Nachziehstapels//auf, entsorgt eine der beiden Karten, die//zwischen [3] und [6] kostet, und legt//den Rest ab. Wenn dabei ein Ritter//entsorgt wird, entsorge diese Karte.";
GermanCardTexts[CardNames.DAME_SYLVIA] = "|+[2]|////Jeder andere Spieler deckt die obersten//beiden Karten seines Nachziehstapels//auf, entsorgt eine der beiden Karten, die//zwischen [3] und [6] kostet, und legt//den Rest ab. Wenn dabei ein Ritter//entsorgt wird, entsorge diese Karte.";
GermanCardTexts[CardNames.DEATH_CART] = "Du darfst diese Karte oder eine //Aktionskarte aus deiner Hand//f\xFCr +[5] entsorgen.//---//Wenn du diese Karte nimmst,//nimm dir 2 Ruinen.";
GermanCardTexts[CardNames.FEODUM] = "Wert {1} pro drei Silber,//die du besitzt (abgerundet).//---//Wenn du diese Karte entsorgst,//nimm dir 3 Silber.";
GermanCardTexts[CardNames.FORAGER] = "|+1 Aktion|//|+1 Kauf|////Entsorge eine Karte aus deiner//Hand. Danach: |+|[1] pro Geld-//karte mit unterschiedlichem//Namen im M\xFCll-Stapel.";
GermanCardTexts[CardNames.FORTRESS] = "|+1 Karte|//|+2 Aktionen|//---//Wenn du diese Karte entsorgst,//nimm sie auf deine Hand.";
GermanCardTexts[CardNames.GRAVEROBBER] = "W\xE4hle eines: Nimm dir eine//Karte vom M\xFCll-Stapel, die//zwischen [3] und [6] kostet, auf//deinen Nachziehstapel; oder//entsorge eine Aktionskarte aus//deiner Hand und nimm dir eine//Karte, die bis zu [3] mehr kostet.";
GermanCardTexts[CardNames.HERMIT] = "//Durchsuche deinen Ablagestapel. Du//darfst eine Nicht-Geldkarte aus deiner//Hand oder deinem Ablagestapel ent-//sorgen. Nimm dir eine Karte, die bis//zu [3] kostet. Am Ende deiner Kauf-//phase in diesem Zug, falls du in ihr//keine Karte genommen hast, tausche//diese Karte gegen einen//Verr\xFCckten ein.";
GermanCardTexts[CardNames.HOVEL] = "Wenn du eine Punktekarte//nimmst, darfst du diese Karte//aus deiner Hand entsorgen.";
GermanCardTexts[CardNames.HUNTING_GROUNDS] = "|+4 Karten|//---//Wenn du diese Karte//entsorgst, nimm dir ein//Herzogtum oder 3 Anwesen.";
GermanCardTexts[CardNames.IRONMONGER] = "|+1 Karte|//|+1 Aktion|//Decke die oberste Karte deines//Nachziehstapels auf. Du darfst sie//ablegen. In jedem Fall, wenn es eine...//Aktionskarte ist, |+1 Aktion|//Geldkarte ist, |+|[1]     //Punktekarte ist, |+1 Karte|.";
GermanCardTexts[CardNames.JUNK_DEALER] = "|+1 Karte|//|+1 Aktion|//|+[1]|////Entsorge eine Karte//aus deiner Hand.";
GermanCardTexts[CardNames.MADMAN] = "|+2 Aktionen|//Lege diese Karte zur\xFCck auf//den Verr\xFCckten-Stapel. Wenn//du das machst, |+1 Karte| pro//Karte auf deiner Hand.////%(Diese Karte geh\xF6rt%//%nicht zum Vorrat.)%";
GermanCardTexts[CardNames.MARKET_SQUARE] = "|+1 Karte|//|+1 Aktion|//|+1 Kauf|//---//Wenn eine deiner Karten entsorgt//wird, darfst du diese Karte aus//deiner Hand ablegen, um dir ein//Gold zu nehmen.";
GermanCardTexts[CardNames.MARAUDER] = "Nimm dir eine Beute.//Jeder andere Spieler//nimmt sich eine Ruine.";
GermanCardTexts[CardNames.MERCENARY] = "Du darfst 2 Karten aus deiner//Hand entsorgen. Wenn du das//gemacht hast, |+2 Karten|,//+[2], und jeder andere Spieler//legt alle bis auf drei Karten von//seiner Hand ab.//%(Diese Karte geh\xF6rt%//%nicht zum Vorrat.)%";
GermanCardTexts[CardNames.MYSTIC] = "|+1 Aktion|//|+[2]|////Nenne eine Karte, dann decke die//oberste Karte deines Nachzieh-//stapels auf. Ist es die genannte//Karte, nimm sie auf die Hand.";
GermanCardTexts[CardNames.NECROPOLIS] = "|+2 Aktionen|";
GermanCardTexts[CardNames.OVERGROWN_ESTATE] = "{!0}////---////Wenn du diese Karte entsorgst,//|+1 Karte|.";
GermanCardTexts[CardNames.PILLAGE] = "Entsorge diese Karte.//Wenn du das gemacht hast,//nimm dir 2 Beuten und jeder//andere Spieler mit 5 oder mehr//Handkarten deckt seine Hand//auf und legt eine Karte// deiner Wahl ab.";
GermanCardTexts[CardNames.POOR_HOUSE] = "|+[4]|////Decke deine Hand auf.//-[1] pro Geldkarte auf deiner//Hand. (Du kannst nicht//unter [0] sinken.)";
GermanCardTexts[CardNames.PROCESSION] = "Du darfst eine Aktionskarte//aus deiner Hand, die keine//Dauerkarte ist, zweimal spielen.//Entsorge sie.//Nimm dir eine Aktionskarte, die//genau [1] mehr kostet.";
GermanCardTexts[CardNames.RATS] = "|+1 Karte|//|+1 Aktion|////Nimm dir eine Ratten-Karte. Entsorge//eine Nicht-Ratten-Karte aus deiner//Hand (oder decke deine Hand auf, wenn// du nur Ratten auf der Hand hast).//---//Wenn du diese Karte entsorgst,//|+1 Karte|.";
GermanCardTexts[CardNames.REBUILD] = "|+1 Aktion|////Nenne eine Karte. Decke Karten von//deinem Nachziehstapel auf, bis du//eine Punktekarte aufdeckst, die du//nicht genannt hast. Lege alle anderen//Karten ab. Entsorge die Punktekarte//und nimm dir eine Punktekarte, die//bis zu [3] mehr kostet.";
GermanCardTexts[CardNames.ROGUE] = "|+[2]|////Wenn Karten im M\xFCll-Stapel sind, die//zwischen [3] und [6] kosten, nimm dir//eine davon. Ansonsten deckt jeder//andere Spieler die obersten beiden//Karten seines Nachziehstapels auf,//entsorgt eine der beiden Karten, die//zwischen [3] und [6] kostet, und legt//den Rest ab.";
GermanCardTexts[CardNames.RUINED_LIBRARY] = "|+1 Karte|";
GermanCardTexts[CardNames.RUINED_MARKET] = "|+1 Kauf|";
GermanCardTexts[CardNames.RUINED_VILLAGE] = "|+1 Aktion|";
GermanCardTexts[CardNames.SAGE] = "|+1 Aktion|////Decke Karten von deinem//Nachziehstapel auf, bis du eine//Karte aufdeckst, die [3] oder mehr//kostet. Nimm diese Karte auf//deine Hand und lege den Rest ab.";
GermanCardTexts[CardNames.SCAVENGER] = "|+[2]|////Du darfst deinen Nachziehstapel //auf deinen Ablagestapel legen.//Durchsuche deinen Ablagestapel//und lege eine der Karten daraus//auf deinen Nachziehstapel.";
GermanCardTexts[CardNames.SIR_BAILEY] = "|+1 Karte|//|+1 Aktion|////Jeder andere Spieler deckt die obersten//beiden Karten seines Nachziehstapels//auf, entsorgt eine der beiden Karten, die//zwischen [3] und [6] kostet, und legt//den Rest ab. Wenn dabei ein Ritter//entsorgt wird, entsorge diese Karte.";
GermanCardTexts[CardNames.SIR_DESTRY] = "|+2 Karten|////Jeder andere Spieler deckt die obersten//beiden Karten seines Nachziehstapels//auf, entsorgt eine der beiden Karten, die//zwischen [3] und [6] kostet, und legt//den Rest ab. Wenn dabei ein Ritter//entsorgt wird, entsorge diese Karte.";
GermanCardTexts[CardNames.SIR_MARTIN] = "|+2 K\xE4ufe|////Jeder andere Spieler deckt die obersten//beiden Karten seines Nachziehstapels//auf, entsorgt eine der beiden Karten, die//zwischen [3] und [6] kostet, und legt//den Rest ab. Wenn dabei ein Ritter//entsorgt wird, entsorge diese Karte.";
GermanCardTexts[CardNames.SIR_MICHAEL] = "Jeder andere Spieler legt alle bis//auf drei Karten von seiner Hand ab.////Jeder andere Spieler deckt die obersten//beiden Karten seines Nachziehstapels//auf, entsorgt eine der beiden Karten, die//zwischen [3] und [6] kostet, und legt//den Rest ab. Wenn dabei ein Ritter//entsorgt wird, entsorge diese Karte.";
GermanCardTexts[CardNames.SIR_VANDER] = "////Jeder andere Spieler deckt die obersten//beiden Karten seines Nachziehstapels//auf, entsorgt eine der beiden Karten, die//zwischen [3] und [6] kostet, und legt//den Rest ab. Wenn dabei ein Ritter//entsorgt wird, entsorge diese Karte.//---//Wenn du diese Karte entsorgst,//nimm dir ein Gold.";
GermanCardTexts[CardNames.SPOILS] = "[!3]////Wenn du diese Karte spielst, lege//sie zur\xFCck auf den Beute-Stapel.////%(Diese Karte geh\xF6rt%//%nicht zum Vorrat.)%";
GermanCardTexts[CardNames.STOREROOM] = "|+1 Kauf|////Lege eine beliebige Anzahl//Karten ab, dann ziehe genauso//viele Karten. Lege danach eine//beliebige Anzahl Karten//f\xFCr |+|[1] pro Karte ab.";
GermanCardTexts[CardNames.SQUIRE] = "|+[1]|////W\xE4hle eines: |+2 Aktionen|;//oder |+2 K\xE4ufe|;//oder nimm dir ein Silber.//---//Wenn du diese Karte entsorgst,//nimm dir eine Angriffskarte.";
GermanCardTexts[CardNames.SURVIVORS] = "Sieh dir die obersten beiden//Karten deines Nachziehstapels an.//Lege sie ab oder in beliebiger//Reihenfolge zur\xFCck auf deinen//Nachziehstapel.";
GermanCardTexts[CardNames.URCHIN] = "|+1 Karte|//|+1 Aktion|////Jeder andere Spieler legt alle bis//auf 4 Karten von seiner Hand ab.//---//Wenn du eine andere Angriffskarte//spielst, w\xE4hrend diese Karte im Spiel//ist, darfst du zuerst diese Karte entsor-//gen, um dir einen S\xF6ldner zu nehmen.";
GermanCardTexts[CardNames.VAGRANT] = "|+1 Karte|//|+1 Aktion|////Decke die oberste Karte deines//Nachziehstapels auf. Wenn es ein//Fluch, eine Ruine, ein Unter-//schlupf, oder eine Punktekarte ist,//nimm sie auf die Hand.";
GermanCardTexts[CardNames.WANDERING_MINSTREL] = "|+1 Karte|//|+2 Aktionen|////Decke die obersten drei Karten//deines Nachziehstapels auf. Lege//die aufgedeckten Aktionskarten//in beliebiger Reihenfolge zur\xFCck//und lege den Rest ab.";
GermanCardTexts[CardNames.ADVISOR] = "|+1 Aktion|////Decke die obersten drei Karten//deines Nachziehstapels auf.//Der Spieler zu deiner Linken w\xE4hlt//eine Karte, die du ablegst. Nimm//die anderen Karten auf die Hand.";
GermanCardTexts[CardNames.BAKER] = "|+1 Karte|//|+1 Aktion|//|+1 Taler|//---//Spielvorbereitung: Jeder Spieler//bekommt |+1 Taler|.";
GermanCardTexts[CardNames.BUTCHER] = "|+2 Taler|////Du darfst eine Karte aus deiner Hand //entsorgen. Wenn du das machst, ent-//ferne beliebig viele Marker von der//Taler-Seite deines Tableaus und//nimm dir eine Karte, die h\xF6chstens//so viel kostet wie die entsorgte Karte//plus [1] pro entferntem Marker.";
GermanCardTexts[CardNames.CANDLESTICK_MAKER] = "|+1 Aktion|//|+1 Kauf|//|+1 Taler|";
GermanCardTexts[CardNames.DOCTOR] = "Nenne eine Karte. Decke die ober-//sten drei Karten deines Nachziehstapels//auf und entsorge die Treffer. Lege den//Rest in beliebiger Reihenfolge zur\xFCck.//---//\xDCberzahlen: Pro \xFCberzahltem [1],//sieh dir die oberste Karte deines//Nachziehstapels an; entsorge sie,//lege sie ab, oder lege sie zur\xFCck.";
GermanCardTexts[CardNames.HERALD] = "|+1 Karte|//|+1 Aktion|////Decke die oberste Karte deines//Nachziehstapels auf. Wenn es eine//Aktionskarte ist, spiele sie.//---//\xDCberzahlen: Pro \xFCberzahltem [1],//durchsuche deinen Ablagestapel//und lege 1 Karte daraus auf//deinen Nachziehstapel.";
GermanCardTexts[CardNames.JOURNEYMAN] = "Nenne eine Karte. Decke Karten//von deinem Nachziehstapel auf,//bis du drei Karten aufgedeckt//hast, die du nicht genannt hast.//Nimm diese Karten auf die Hand//und lege den Rest ab.";
GermanCardTexts[CardNames.MASTERPIECE] = "[!1]//---//\xDCberzahlen: Pro \xFCber-//zahltem [1], nimm//dir ein Silber.";
GermanCardTexts[CardNames.MERCHANT_GUILD] = "|+1 Kauf|//|+[1]|//////Am Ende deiner Kauf-//phase in diesem Zug,//|+1 Taler| pro Karte, die//du in ihr genommen hast.";
GermanCardTexts[CardNames.PLAZA] = "|+1 Karte|//|+2 Aktionen|//////Du darfst eine Geld-//karte ablegen.//Wenn du das machst,//|+1 Taler|.";
GermanCardTexts[CardNames.TAXMAN] = "Du darfst eine Geldkarte aus deiner//Hand entsorgen. Jeder andere Spieler//mit 5 oder mehr Handkarten legt eine//solche Karte ab (oder deckt seine Hand//ohne Exemplar davon auf). Nimm dir//eine Geldkarte, die bis zu [3] mehr//als die entsorgte Karte kostet, auf//deinen Nachziehstapel.";
GermanCardTexts[CardNames.SOOTHSAYER] = "Nimm dir ein Gold.//Jeder andere Spieler nimmt//sich einen Fluch, und wenn er//das macht, zieht er eine Karte.";
GermanCardTexts[CardNames.STONEMASON] = "Entsorge eine Karte aus deiner//Hand. Nimm dir zwei Karten,//die jeweils weniger kosten//als die entsorgte Karte.//---//\xDCberzahlen: Nimm dir zwei//Aktionskarten, die jeweils so viel//kosten, wie du \xFCberzahlt hast.";
GermanCardTexts[CardNames.ALMS] = "Einmal pro Zug: Wenn du keine//Geldkarten im Spiel hast, nimm dir//eine Karte, die bis zu [4] kostet.";
GermanCardTexts[CardNames.AMULET] = "Jetzt und zu Beginn deines//n\xE4chsten Zuges, w\xE4hle eines:// +[1];//oder entsorge eine Karte// aus deiner Hand;//oder nimm dir ein Silber.";
GermanCardTexts[CardNames.ARTIFICER] = "|+1 Karte|//|+1 Aktion|//|+[1]|//Lege eine beliebige Anzahl Karten//aus deiner Hand ab. Du darfst dir//eine Karte auf deinen Nachzieh-//stapel nehmen, die genau [1] pro//abgelegter Karte kostet.";
GermanCardTexts[CardNames.BALL] = "Nimm deinen -[1]-Marker.//Nimm dir zwei Karten, die je bis zu [4] kosten.";
GermanCardTexts[CardNames.BONFIRE] = "Entsorge bis zu zwei Kupfer,//die du im Spiel hast.";
GermanCardTexts[CardNames.BORROW] = "Einmal pro Zug: |+1 Kauf|. Wenn dein// -1-Karte-Marker nicht auf deinem//Nachziehstapel ist, lege ihn darauf und +[1].";
GermanCardTexts[CardNames.BRIDGE_TROLL] = "Jeder andere Spieler nimmt//seinen -[1]-Marker. In diesem//und deinem n\xE4chsten Zug//kosten Karten [1] weniger.////Jetzt und zu Beginn deines//n\xE4chsten Zuges: |+1 Kauf|.";
GermanCardTexts[CardNames.CARAVAN_GUARD] = "|+1 Karte|//|+1 Aktion|////Zu Beginn deines n\xE4chsten//Zuges: |+|[1].//---//Wenn ein Mitspieler eine Angriffs-//karte spielt, darfst du zuerst diese//Karte aus deiner Hand spielen.";
GermanCardTexts[CardNames.CHAMPION] = "|+1 Aktion|////F\xFCr den Rest des Spiels, wenn ein//Mitspieler eine Angriffskarte//spielt, bist du von deren Effekt//nicht betroffen und wenn du eine//Aktionskarte spielst, erh\xE4lst du//zuerst |+1 Aktion|.////%(Diese Karte geh\xF6rt nicht zum Vorrat.)%";
GermanCardTexts[CardNames.COIN_OF_THE_REALM] = "[!1]////Lege diese Karte auf//dein Wirtshaustableau.//---//Nachdem du eine Aktionskarte//gespielt hast, darfst du diese//Karte f\xFCr |+2 Aktionen| aufrufen.";
GermanCardTexts[CardNames.DISCIPLE] = "Du darfst eine Aktionskarte aus//deiner Hand zweimal spielen. Nimm//dir ein weiteres Exemplar dieser Karte.//---//Wenn du diese Karte aus dem Spiel//ablegst, darfst du sie gegen einen//Lehrer austauschen.//%(Diese Karte geh\xF6rt nicht zum Vorrat.)%";
GermanCardTexts[CardNames.DISTANT_LANDS] = "Lege diese Karte auf//dein Wirtshaustableau.//---//Wert {4}, wenn diese Karte//am Ende des Spiels auf deinem//Wirtshaustableau liegt.//(ansonsten Wert {0}).";
GermanCardTexts[CardNames.DUNGEON] = "|+1 Aktion|////Jetzt und zu Beginn deines//n\xE4chsten Zuges: |+2 Karten|, dann//lege 2 Karten aus deiner Hand ab.";
GermanCardTexts[CardNames.DUPLICATE] = "Lege diese Karte auf//dein Wirtshaustableau.//---//Wenn du eine Karte nimmst, die//bis zu [6] kostet, darfst du diese//Karte aufrufen, um eine weitere//solche Karte zu nehmen.";
GermanCardTexts[CardNames.EXPEDITION] = "Ziehe 2 zus\xE4tzliche Karten f\xFCr deine n\xE4chste Hand.";
GermanCardTexts[CardNames.FERRY] = "Lege deinen -[2]-Marker auf einen Aktions-//kartenstapel im Vorrat. (Karten von diesem Stapel//kosten w\xE4hrend deines Zuges [2] weniger.)";
GermanCardTexts[CardNames.FUGITIVE] = "|+2 Karten|//|+1 Aktion|////Lege eine Karte aus deiner Hand ab.//---//Wenn du diese Karte aus dem Spiel//ablegst, darfst du sie gegen einen//Sch\xFCler austauschen.//%(Diese Karte geh\xF6rt nicht zum Vorrat.)%";
GermanCardTexts[CardNames.GEAR] = "|+2 Karten|////Lege bis zu zwei Karten aus deiner//Hand verdeckt zur Seite (unter//diese Karte). Zu Beginn deines//n\xE4chsten Zuges: Nimm diese//Karten auf deine Hand.";
GermanCardTexts[CardNames.GIANT] = "//////////Drehe deinen Reise-Marker um (zu Spiel-//beginn zeigt die Vorderseite nach oben).//Danach, wenn die Vorderseite nach unten//zeigt, +[1]. Wenn sie nach oben zeigt,//+[5] und jeder andere Spieler deckt//die oberste Karte seines Nachziehstapels//auf und entsorgt sie, wenn sie zwischen//[3] und [6] kostet, ansonsten legt//er sie ab und nimmt sich einen Fluch.";
GermanCardTexts[CardNames.GUIDE] = "|+1 Karte|//|+1 Aktion|////Lege diese Karte auf//dein Wirtshaustableau.//---//Zu Beginn deines Zuges darfst du//diese Karte aufrufen, um deine Hand//abzulegen und 5 Karten zu ziehen.";
GermanCardTexts[CardNames.HAUNTED_WOODS] = "//Zu Beginn deines n\xE4chsten//Zuges: |+3 Karten|////Bis dahin legt jeder Mitspieler,//wenn er eine von ihm gekaufte//Karte nimmt, seine Handkarten//in beliebiger Reihenfolge auf//seinen Nachziehstapel.";
GermanCardTexts[CardNames.HERO] = "|+[2]|//Nimm dir eine Geldkarte.//---//Wenn du diese Karte aus dem Spiel//ablegst, darfst du sie gegen einen//Champion austauschen.//%(Diese Karte geh\xF6rt%//%nicht zum Vorrat.)%";
GermanCardTexts[CardNames.HIRELING] = "Zu Beginn jedes deiner Z\xFCge,//f\xFCr den Rest des Spiels:////|+1 Karte|";
GermanCardTexts[CardNames.INHERITANCE] = "Einmal pro Spiel: Lege eine Aktionskarte aus dem Vorrat, die keine Befehls-//karte ist und bis zu [4] kostet, zur Seite. Lege deinen Anwesen-Marker//darauf. (W\xE4hrend deiner Z\xFCge sind Anwesen auch \"Aktion-Befehl\"-Karten//mit \"Spiele die Karte unter deinem Anwesen-Marker und lasse sie dort.\")";
GermanCardTexts[CardNames.LOST_ARTS] = "Lege deinen +1-Aktion-Marker auf einen Aktions-//karten-Vorratsstapel. (Wenn du eine Karte von diesem//Stapel spielst, bekommst du zuerst |+1 Aktion|.)";
GermanCardTexts[CardNames.LOST_CITY] = "|+2 Karten|//|+2 Aktionen|//---//Wenn du diese Karte//nimmst, zieht jeder//andere Spieler eine Karte.";
GermanCardTexts[CardNames.MAGPIE] = "|+1 Karte|//|+1 Aktion|////Decke die oberste Karte deines//Nachziehstapels auf. Wenn es eine//Geldkarte ist, nimm sie auf die//Hand. Wenn es eine Aktions- oder//Punktekarte ist, nimm eine Elster.";
GermanCardTexts[CardNames.MESSENGER] = "|+1 Kauf|//|+[2]|////Du darfst deinen Nachziehstapel//auf deinen Ablagestapel legen.//---//Wenn dies die erste Karte ist, die du//in deiner Kaufphase nimmst, nimm eine//Karte, die bis zu [4] kostet, und jeder//Mitspieler nimmt eine gleiche Karte.";
GermanCardTexts[CardNames.MISER] = "W\xE4hle eines: Lege ein Kupfer//aus deiner Hand auf dein//Wirtshaustableau;//oder +[1] pro Kupfer auf//deinem Wirtshaustableau.";
GermanCardTexts[CardNames.MISSION] = "Du erh\xE4ltst einen zus\xE4tzlichen Zug nach diesem//Zug (aber keinen 3. Zug in Folge), in dem du//keine Karten (aber Ereignisse) kaufen kannst.";
GermanCardTexts[CardNames.PATHFINDING] = "Lege deinen +1-Karte-Marker auf einen Aktions-//karten-Vorratsstapel. (Wenn du eine Karte von die-//sem Stapel spielst, bekommst du zuerst |+1 Karte|.)";
GermanCardTexts[CardNames.PAGE] = "|+1 Karte|//|+1 Aktion|//---//Wenn du diese Karte aus dem Spiel//ablegst, darfst du sie gegen einen//Schatzsucher austauschen.";
GermanCardTexts[CardNames.PEASANT] = "|+1 Kauf|//|+[1]|//---//Wenn du diese Karte aus dem Spiel//ablegst, darfst du sie gegen einen//Soldaten austauschen.";
GermanCardTexts[CardNames.PILGRIMAGE] = "Einmal pro Zug: Drehe deinen Reise-Marker um (zu Beginn//des Spiels zeigt die Vorderseite nach oben); danach, wenn die//Vorderseite nach oben zeigt, nimm bis zu drei Karten mit unter-//schiedlichen Namen, von denen du ein Exemplar im Spiel hast.";
GermanCardTexts[CardNames.PLAN] = "Lege deinen Entsorgungsmarker auf einen Aktionskarten-//Vorratsstapel. (Wenn du eine Karte von diesem Stapel//nimmst, darfst du eine Karte aus deiner Hand entsorgen.)";
GermanCardTexts[CardNames.PORT] = "|+1 Karte|//|+2 Aktionen|//---//Wenn du diese Karte nimmst,//nimm eine weitere Hafenstadt//(f\xFCr die du keine weitere//Hafenstadt nimmst).";
GermanCardTexts[CardNames.QUEST] = "Du darfst eine Angriffskarte, zwei Fl\xFCche//oder sechs Karten aus deiner Hand ablegen.//Wenn du das machst, nimm dir ein Gold.";
GermanCardTexts[CardNames.RANGER] = "|+1 Kauf|////Drehe deinen Reise-Marker um//(zu Beginn des Spiels zeigt die//Vorderseite nach oben).//Danach, wenn die Vorderseite//nach oben zeigt, |+5 Karten|.";
GermanCardTexts[CardNames.RAID] = "Nimm dir ein Silber pro Silber, das du im Spiel//hast. Jeder andere Spieler legt seinen -1-Karte-//Marker auf seinen Nachziehstapel.";
GermanCardTexts[CardNames.RATCATCHER] = "|+1 Karte|//|+1 Aktion|////Lege diese Karte auf//dein Wirtshaustableau.//---//Zu Beginn deines Zuges darfst du//diese Karte aufrufen, um eine Karte//aus deiner Hand zu entsorgen.";
GermanCardTexts[CardNames.RAZE] = "|+1 Aktion|////Entsorge diese Karte oder eine Karte//aus deiner Hand. Sieh dir entsprechend//der []-Kosten der Karte die obersten//Karten deines Nachziehstapels an.//Nimm eine davon auf deine Hand //und lege den Rest ab.";
GermanCardTexts[CardNames.RELIC] = "[!2]////Jeder Mitspieler legt//seinen -1-Karte-Marker//auf seinen Nachziehstapel.";
GermanCardTexts[CardNames.ROYAL_CARRIAGE] = "|+1 Aktion|////Lege diese Karte auf//dein Wirtshaustableau.//---//Nachdem du eine Aktionskarte gespielt//hast, darfst du, wenn jene Aktionskarte//noch im Spiel ist, diese Karte aufrufen,// um jene Aktionskarte noch einmal//zu spielen.";
GermanCardTexts[CardNames.SAVE] = "  Einmal pro Zug: |+1 Kauf|. Lege eine Karte aus dei-  //ner Hand zur Seite. Nimm sie am Ende des Zuges//auf deine Hand zur\xFCck (nach dem Karten Ziehen).";
GermanCardTexts[CardNames.SCOUTING_PARTY] = "|+1 Kauf|////Sieh dir die obersten 5 Karten deines Nachziehstapels an.//Lege 3 davon ab und den Rest in beliebiger Reihenfolge zur\xFCck.";
GermanCardTexts[CardNames.SEAWAY] = "Nimm dir eine Aktionskarte, die bis zu [4] kostet. Lege//deinen +1-Kauf-Marker auf diesen Vorratsstapel. (Wenn du eine//Karte von diesem Stapel spielst, bekommst du zuerst |+1 Kauf|.)";
GermanCardTexts[CardNames.SOLDIER] = "|+[2]|////+[1] pro anderer Angriffskarte, die du//im Spiel hast. Jeder andere Spieler mit//4 oder mehr Karten auf der Hand legt//eine Karte aus seiner Hand ab.//---//Wenn du diese Karte aus dem Spiel//ablegst, darfst du sie gegen einen//Fl\xFCchtling austauschen. %(Diese Karte%//%geh\xF6rt nicht zum Vorrat.)%";
GermanCardTexts[CardNames.STORYTELLER] = "|+1 Aktion|////Spiele bis zu 3 Geldkarten aus//deiner Hand. Dann |+1 Karte|//und bezahle alle deine [] und//|+1 Karte| pro [1], das du//bezahlt hast.";
GermanCardTexts[CardNames.SWAMP_HAG] = "//Zu Beginn deines n\xE4chsten//Zuges: |+|[3].////Bis dahin nimmt jeder//Mitspieler, wenn er eine//von ihm gekaufte Karte//nimmt, einen Fluch.";
GermanCardTexts[CardNames.TEACHER] = "Lege diese Karte auf//dein Wirtshaustableau.//---//Zu Beginn deines Zuges darfst du diese//Karte aufrufen, um deinen +1-Karte-,//+1-Aktion-, +1 Kauf- oder +[1]-Marker//auf einen Aktionskarten-Vorratsstapel zu//legen, auf dem sich keiner deiner Marker//befindet. (Wenn du eine Karte von//diesem Stapel spielst, bekommst du//zuerst den jeweiligen Bonus.)////%(Diese Karte geh\xF6rt nicht zum Vorrat.)%";
GermanCardTexts[CardNames.TRAVELLING_FAIR] = "|+2 K\xE4ufe|////Wenn du in diesem Zug eine Karte nimmst,//darfst du sie auf deinen Nachziehstapel legen.";
GermanCardTexts[CardNames.TRADE] = "Entsorge bis zu 2 Karten aus deiner//Hand. Nimm dir ein Silber f\xFCr jede//Karte, die du dabei entsorgt hast.";
GermanCardTexts[CardNames.TRAINING] = "Lege deinen +[1] Marker auf einen Aktionskarten-//Vorratsstapel. (Wenn du eine Karte von diesem//Stapel spielst, bekommst du zuerst +[1].)";
GermanCardTexts[CardNames.TRANSMOGRIFY] = "|+1 Aktion|////Lege diese Karte auf//dein Wirtshaustableau.//---//Zu Beginn deines Zuges darfst du//diese Karte aufrufen, um eine Karte//aus deiner Hand zu entsorgen und dir//eine Karte auf die Hand zu nehmen,//die bis zu [1] mehr kostet.";
GermanCardTexts[CardNames.TREASURE_TROVE] = "[!2]////Nimm ein Gold und ein Kupfer.";
GermanCardTexts[CardNames.TREASURE_HUNTER] = "|+1 Aktion|//|+[1]|////Nimm dir ein Silber f\xFCr jede Karte,//die der Spieler rechts von dir in//seinem letzten Zug genommen hat.//---//Wenn du diese Karte aus dem Spiel//ablegst, darfst du sie gegen einen//Krieger austauschen.%(Diese Karte%//%geh\xF6rt nicht zum Vorrat.)%";
GermanCardTexts[CardNames.WARRIOR] = "|+2 Karten|////F\xFCr jeden Reisenden, den du im Spiel//hast (diesen eingeschlossen), legt jeder//Mitspieler die oberste Karte von seinem//Nachziehstapel ab und entsorgt sie,//wenn sie zwischen [3] und [4] kostet.//---//Wenn du diese Karte aus dem//Spiel ablegst, darfst du sie gegen//einen Held austauschen.//%(Diese Karte geh\xF6rt nicht zum Vorrat.)%";
GermanCardTexts[CardNames.WINE_MERCHANT] = "|+1 Kauf|//|+[4]|////Lege diese Karte auf//dein Wirtshaustableau.//---//Wenn du am Ende deiner Kaufphase//mindestens [2] nicht ausgegeben//hast, darfst du diese Karte von//deinem Wirtshaustableau ablegen.";
GermanCardTexts[CardNames.ENCAMPMENT] = "|+2 Karten|//|+2 Aktionen|////Du darfst ein Gold oder Diebesgut//aus deiner Hand aufdecken. Wenn//du das nicht machst, lege diese Karte//zur Seite und lege sie zu Beginn der//Aufr\xE4umphase zur\xFCck auf ihren Stapel.";
GermanCardTexts[CardNames.PLUNDER] = "[!2]////|+{1}|";
GermanCardTexts[CardNames.PATRICIAN] = "|+1 Karte|//|+1 Aktion|////Decke die oberste Karte deines//Nachziehstapels auf. Wenn sie [5]//oder mehr kostet, nimm sie//auf die Hand.";
GermanCardTexts[CardNames.EMPORIUM] = "|+1 Karte|//|+1 Aktion|//|+[1]|//---//Wenn du diese Karte nimmst,//w\xE4hrend du mindestens 5//Aktionskarten im Spiel hast,+{2}.";
GermanCardTexts[CardNames.SETTLERS] = "|+1 Karte|//|+1 Aktion|////Du darfst ein Kupfer aus//deinem Ablagestapel//aufdecken und auf//die Hand nehmen.";
GermanCardTexts[CardNames.BUSTLING_VILLAGE] = "|+1 Karte|//|+3 Aktionen|////Du darfst einen Siedler aus//deinem Ablagestapel//aufdecken und auf//die Hand nehmen.";
GermanCardTexts[CardNames.CATAPULT] = "|+[1]|////Entsorge eine Karte aus deiner Hand.//Kostet sie [3] oder mehr, nimmt sich//jeder andere Spieler einen Fluch.//Ist es eine Geldkarte, legt jeder//andere Spieler alle bis auf//drei Karten von seiner Hand ab.";
GermanCardTexts[CardNames.ROCKS] = "[!1]//---//Wenn du diese Karte nimmst oder//entsorgst, nimm dir ein Silber.//Wenn es deine Kaufphase ist, lege das//Silber auf deinen Nachziehstapel.//Sonst nimm es auf die Hand.";
GermanCardTexts[CardNames.GLADIATOR] = "|+[2]|////Decke eine Karte aus deiner//Hand auf. Der Spieler zu deiner//Linken darf ein Exemplar da-//von aufdecken. Wenn er das//nicht macht, +[1] und entsorge// einen Gladiator vom Vorrat.";
GermanCardTexts[CardNames.FORTUNE] = "|+1 Kauf|////Wenn du dies spielst, verdopple dein//[] Geld, wenn du es nicht bereits//in diesem Zug verdoppelt hast.//---//Wenn du diese Karte nimmst, nimm//dir ein Gold pro Gladiator,//den du im Spiel hast.";
GermanCardTexts[CardNames.CASTLES] = "Sortiere Schl\xF6sser nach den//Kosten, die teuren geh\xF6ren//nach unten. In einem 2-Spieler//Spiel kommt jedes Schloss nur//einmal in den Stapel. Nur das//oberste Schloss kann gekauft//oder genommen werden.";
GermanCardTexts[CardNames.HUMBLE_CASTLE] = "[!1]////---////Wert {1} pro Schloss,//das du besitzt.";
GermanCardTexts[CardNames.CRUMBLING_CASTLE] = "{!1}//---//Wenn du diese Karte nimmst//oder entsorgst,+{1} und//nimm dir ein Silber.";
GermanCardTexts[CardNames.SMALL_CASTLE] = "//Entsorge diese Karte//oder ein Schloss aus deiner//Hand. Wenn du das machst,//nimm dir ein Schloss.//---////{!2}";
GermanCardTexts[CardNames.HAUNTED_CASTLE] = "{!2}//---//Wenn du dir diese Karte in deinem//Zug nimmst, nimm dir ein Gold//und jeder andere Spieler mit 5 oder//mehr Karten auf der Hand legt zwei//davon auf seinen Nachziehstapel.";
GermanCardTexts[CardNames.OPULENT_CASTLE] = "Lege beliebig viele //Punktekarten aufgedeckt ab.//+[2] pro Karte,//die abgelegt wurde.//---//{!3}";
GermanCardTexts[CardNames.SPRAWLING_CASTLE] = "{!4}//---//Wenn du diese Karte nimmst,// nimm dir ein Herzogtum//oder drei Anwesen.";
GermanCardTexts[CardNames.GRAND_CASTLE] = "{!5}//---//Wenn du dir diese Karte nimmst,// decke deine Hand auf.//+{1} pro Punktekarte auf deiner//Hand und/oder im Spiel.";
GermanCardTexts[CardNames.KINGS_CASTLE] = "Wert {2} pro Schloss,//das du besitzt.";
GermanCardTexts[CardNames.ADVANCE] = "Du darfst eine Aktionskarte aus deiner Hand//entsorgen. Wenn du das machst, nimm dir eine//Aktionskarte, die bis zu [6] kostet.";
GermanCardTexts[CardNames.ANNEX] = "Durchsuche deinen Ablagestapel. Mische alle//au\xDFer bis zu 5 Karten in deinen Nachziehstapel.//Nimm dir ein Herzogtum.";
GermanCardTexts[CardNames.ARCHIVE] = "|+1 Aktion|//Lege die obersten drei Karten//deines Nachziehstapels verdeckt//zur Seite (du darfst sie ansehen).//Jetzt und zu Beginn der n\xE4chsten//beiden Z\xFCge nimmst du eine der//Karten auf die Hand.";
GermanCardTexts[CardNames.AQUEDUCT] = "Wenn du eine Geldkarte nimmst, lege {} von ihrem Stapel hier-//her. Wenn du eine Punktekarte nimmst, nimm die {} von hier.//---//Spielvorbereitung: Lege je {8} auf den Silber- und Goldstapel.";
GermanCardTexts[CardNames.ARENA] = "Zu Beginn deiner Kaufphase darfst du eine Aktionskarte //aus deiner Hand ablegen, um {2} von hier zu nehmen.//---//Spielvorbereitung: Lege {6} pro Spieler hierher.";
GermanCardTexts[CardNames.BANDIT_FORT] = "Beim Abrechnen der Siegpunkte,-{2} f\xFCr jedes//Silber und f\xFCr jedes Gold, das du besitzt.";
GermanCardTexts[CardNames.BANQUET] = "Nimm dir 2 Kupfer und eine Nicht-Punktekarte,//die bis zu [5] kostet.";
GermanCardTexts[CardNames.BASILICA] = "Wenn du eine Karte w\xE4hrend deiner Kaufphase nimmst// und danach [2] oder mehr \xFCbrig hast, nimm {2} von hier.//---//Spielvorbereitung: Lege {6} pro Spieler hierher.";
GermanCardTexts[CardNames.BATHS] = "Wenn du deinen Zug beendest, ohne eine Karte//genommen zu haben, nimm {2} von hier.//---//Spielvorbereitung: Lege {6} pro Spieler hierher.";
GermanCardTexts[CardNames.BATTLEFIELD] = "Wenn du eine Punktekarte nimmst, nimm {2} von hier.  //---//Spielvorbereitung: Lege je {6} pro Spieler hierher.";
GermanCardTexts[CardNames.CAPITAL] = "[!6]////|+1 Kauf|//---//Wenn du diese Karte aus dem//Spiel ablegst, nimm [6D], dann//darfst du [D] zur\xFCckzahlen.";
GermanCardTexts[CardNames.CHARM] = "W\xE4hle eins: |+1 Kauf| und +[2];//oder wenn du das n\xE4chste Mal//in diesem Zug eine Karte//nimmst, darfst du zus\xE4tzlich//eine Karte mit anderem Namen//nehmen, die gleich viel kostet.";
GermanCardTexts[CardNames.CHARIOT_RACE] = "|+1 Aktion|//Decke die oberste Karte deines//Nachziehstapels auf und nimm//sie auf die Hand. Der Spieler zu//deiner Linken deckt die oberste//Karte seines Nachziehstapels//auf. Wenn deine Karte mehr//kostet, +[1] und +{1}.";
GermanCardTexts[CardNames.CITY_QUARTER] = "|+2 Aktionen|//////Decke deine Hand auf. |+1 Karte|//pro aufgedeckter Aktionskarte.";
GermanCardTexts[CardNames.COLONNADE] = "Wenn du eine Aktionskarte in deiner Kaufphase nimmst, falls//du eine gleiche Karte im Spiel hast, nimm {2} von hier.//---//Spielvorbereitung: Lege {6} pro Spieler hierher.";
GermanCardTexts[CardNames.CONQUEST] = "Nimm dir 2 Silber. +{1} pro Silber,//das du in diesem Zug genommen hast.";
GermanCardTexts[CardNames.CROWN] = "//Wenn es deine Aktionsphase ist,//darfst du eine Aktionskarte aus//deiner Hand zweimal spielen.//Wenn es deine Kaufphase ist,//darfst du eine Geldkarte aus//deiner Hand zweimal spielen.";
GermanCardTexts[CardNames.DELVE] = "|+1 Kauf|//Nimm dir ein Silber.";
GermanCardTexts[CardNames.DEFILED_SHRINE] = "Wenn du eine Aktionskarte nimmst, lege {1} von ihrem Stapel hierher.//Wenn du einen Fluch in deiner Kaufphase nimmst, nimm die {} von hier.//---//Spielvorbereitung: Lege {2} auf jeden Aktions-Vorratsstapel,//der nicht vom Typ \"Sammlung\" ist.";
GermanCardTexts[CardNames.DOMINATE] = "Nimm dir eine Provinz. Wenn du das machst, +{9}.";
GermanCardTexts[CardNames.DONATE] = "Zu Beginn deines n\xE4chsten Zuges, zuerst: Nimm alle Karten//aus deinem Nachziehstapel und deinem Ablagestapel auf deine//Hand. Entsorge beliebig viele deiner Handkarten, dann mische//deine Hand in deinen Nachziehstapel und ziehe 5 Karten.";
GermanCardTexts[CardNames.ENCHANTRESS] = "Bis zu deinem n\xE4chsten Zug://Die erste in deren Zug//ausgespielte Aktionskarte jedes//anderen Spielers produziert// |+1 Karte| und |+1 Aktion|//anstatt der Instruktionen// auf der Karte.////Zu Beginn deines n\xE4chsten Zuges:// |+2 Karten|";
GermanCardTexts[CardNames.ENGINEER] = "Nimm dir eine Karte, die bis zu [4]//kostet. Du darfst die Ingenieurin//entsorgen. Wenn du das machst,//nimm dir eine Karte, die bis//zu [4] kostet.";
GermanCardTexts[CardNames.FARMERS_MARKET] = "|+1 Kauf|////Wenn {4} oder mehr auf dem//Bauernmarkt-Stapel liegen,//nimm sie und entsorge diese Karte.//Sonst lege {1} auf den Stapel und//+[1] pro {1} auf dem Stapel.";
GermanCardTexts[CardNames.FORUM] = "|+3 Karten|//|+1 Aktion|//Lege 2 Handkarten ab.//---//Wenn du diese Karte nimmst,//|+1 Kauf.|";
GermanCardTexts[CardNames.FOUNTAIN] = "Wenn du beim Abrechnen der Siegpunkte//mindestens 10 Kupfer besitzt, {15}.";
GermanCardTexts[CardNames.GROUNDSKEEPER] = "|+1 Karte|//|+1 Aktion|////Wenn du in diesem Zug eine//Punktekarte nimmst, |+|{1}.";
GermanCardTexts[CardNames.KEEP] = "Beim Abrechnen der Siegpunkte, {5} pro Geldkarte mit//   unterschiedlichem Namen, von der kein Spieler mehr  //besitzt als du (und du mindestens eine besitzt).";
GermanCardTexts[CardNames.LABYRINTH] = "Wenn du eine zweite Karte in einem deiner Z\xFCge//nimmst, nimm {2} von hier.//---//Spielvorbereitung: Lege {6} pro Spieler hierher.";
GermanCardTexts[CardNames.LEGIONARY] = "|+[3]|////Du darfst ein Gold von deiner Hand//aufdecken. Wenn du das machst,//legt jeder andere Spieler alle bis//auf zwei Karten von seiner Hand//ab. Danach zieht er eine Karte.";
GermanCardTexts[CardNames.MOUNTAIN_PASS] = "Wenn du als erster Spieler eine Provinz nimmst,//bietet jeder Spieler einmal bis zu [40D], mit dir zum//Schluss. Der H\xF6chstbietende bekommt +{8}//und nimmt [D] entsprechend seines Gebots.";
GermanCardTexts[CardNames.MUSEUM] = "Beim Abrechnen der Siegpunkte, {2} pro Karte//mit unterschiedlichem Namen, die du besitzt.";
GermanCardTexts[CardNames.OBELISK] = "Beim Abrechnen der Siegpunkte, {2} pro Karte,//die du von dem ausgew\xE4hlten Vorratsstapel besitzt.//---//Spielv.: W\xE4hle einen Aktions-Vorratsstapel zuf\xE4llig aus.";
GermanCardTexts[CardNames.ORCHARD] = "Beim Abrechnen der Siegpunkte, {4}//pro Aktionskarte mit unterschiedlichem Namen,//von der du 3 oder mehr Exemplare besitzt.";
GermanCardTexts[CardNames.OVERLORD] = "Spiele eine Aktionskarte//aus dem Vorrat, die//keine Befehlskarte ist// und bis zu [5] kostet//und lasse sie dort liegen.";
GermanCardTexts[CardNames.PALACE] = "Beim Abrechnen der Siegpunkte, {3} pro//vollst\xE4ndigem Satz aus Kupfer - Silber - Gold,//das du besitzt.";
GermanCardTexts[CardNames.RITUAL] = "Nimm dir einen Fluch. Wenn du das machst, entsorge//eine Karte aus deiner Hand.+{1} pro [1], das sie kostete.";
GermanCardTexts[CardNames.ROYAL_BLACKSMITH] = "|+5 Karten|//////Decke deine Hand auf//und lege alle Kupfer ab.";
GermanCardTexts[CardNames.SACRIFICE] = "Entsorge eine Karte aus deiner//Hand. Ist es eine...// Aktionskarte:// |+2 Karten|, |+2 Aktionen|//Geldkarte: +[2]//Punktekarte:+{2}";
GermanCardTexts[CardNames.SALT_THE_EARTH] = "+{1}//Entsorge eine Punktekarte aus dem Vorrat.";
GermanCardTexts[CardNames.TAX] = "Lege [2D] auf einen Vorratsstapel.//---//Spielvorbereitung: Lege [1D] auf jeden Vorratsstapel.//Wenn ein Spieler eine Karte in seiner Kaufphase//nimmt, nimmt er die [D] von diesem Vorratsstapel.";
GermanCardTexts[CardNames.TEMPLE] = "|+{1}|//Entsorge zwischen 1 und 3//Karten mit unterschiedlichem//Namen aus deiner Hand. Lege//{1} auf den Tempelstapel.//---//Wenn du diese Karte nimmst,//nimm die {} vom Tempelstapel.";
GermanCardTexts[CardNames.TOMB] = "Wenn du eine Karte entsorgst,+{1}.";
GermanCardTexts[CardNames.TOWER] = "Beim Abrechnen der Siegpunkte, {1} pro Nicht-Punkte-//  karte von einem leeren Vorratsstapel, die du besitzt. ";
GermanCardTexts[CardNames.TRIUMPH] = "Nimm dir ein Anwesen. Wenn du das gemacht//hast, +{1} pro Karte, die du in diesem Zug//genommen hast.";
GermanCardTexts[CardNames.TRIUMPHAL_ARCH] = "Beim Abrechnen der Siegpunkte, {3} pro//Exemplar der zweith\xE4ufigsten Aktionskarte, die//du besitzt. (bei Gleichstand z\xE4hle eine der Sorten)";
GermanCardTexts[CardNames.VILLA] = "|+2 Aktionen|//|+1 Kauf|//|+[1]|//---//Wenn du diese Karte nimmst,//nimm sie auf die Hand, |+1 Aktion|,//und wenn es deine Kaufphase ist,//gehe zur\xFCck zur Aktionsphase.";
GermanCardTexts[CardNames.WALL] = "Beim Abrechnen der Siegpunkte,{-1}//f\xFCr jede Karte nach der 15., die du besitzt.";
GermanCardTexts[CardNames.WOLF_DEN] = "Beim Abrechnen der Siegpunkte, {-3} f\xFCr jede//Karte, von der du genau ein Exemplar besitzt.";
GermanCardTexts[CardNames.WEDDING] = "+{1}//Nimm dir ein Gold.";
GermanCardTexts[CardNames.WILD_HUNT] = "W\xE4hle eins: |+3 Karten| und//lege {1} auf den Wilde Jagd-Stapel;//oder nimm dir ein Anwesen,//und wenn du das machst, nimm//die {} vom Wilde Jagd-Stapel.";
GermanCardTexts[CardNames.WINDFALL] = "Wenn dein Nachziehstapel und dein Ablagestapel//leer sind, nimm dir 3 Gold.";
GermanCardTexts[CardNames.PIG] = "";
GermanCardTexts[CardNames.MINUS_CARD] = "";
GermanCardTexts[CardNames.MINUS_COIN] = "";
GermanCardTexts[CardNames.STATE_LIMBO] = "";
GermanCardTexts[CardNames.DRUID_BOONS] = "";
GermanCardTexts[CardNames.BOON_DRAWPILE] = "";
GermanCardTexts[CardNames.BOON_DISCARDPILE] = "";
GermanCardTexts[CardNames.THE_EARTHS_GIFT] = "Du darfst eine Geldkarte ablegen, um dir//eine Karte zu nehmen, die bis zu [4] kostet.";
GermanCardTexts[CardNames.THE_FIELDS_GIFT] = "|+1 Aktion|//|+[1]|//(Behalte diese Gabe bis zur Aufr\xE4umphase.)";
GermanCardTexts[CardNames.THE_FLAMES_GIFT] = "Du darfst eine Handkarte entsorgen.";
GermanCardTexts[CardNames.THE_FORESTS_GIFT] = "|+1 Kauf|//|+[1]|//(Behalte diese Gabe bis zur Aufr\xE4umphase.)";
GermanCardTexts[CardNames.THE_MOONS_GIFT] = "Durchsuche deinen Ablagestapel. Du darfst//eine Karte auf deinen Nachziehstapel legen.";
GermanCardTexts[CardNames.THE_MOUNTAINS_GIFT] = "Nimm dir ein Silber.";
GermanCardTexts[CardNames.THE_RIVERS_GIFT] = "|+1 Karte| am Ende dieses Zuges.//(Behalte diese Gabe bis zur Aufr\xE4umphase.)";
GermanCardTexts[CardNames.THE_SEAS_GIFT] = "|+1 Karte|";
GermanCardTexts[CardNames.THE_SKYS_GIFT] = "Du darfst 3 Karten ablegen,//um dir ein Gold zu nehmen.";
GermanCardTexts[CardNames.THE_SUNS_GIFT] = "Sieh dir die obersten 4 Karten deines Nachziehstapels an.//Lege eine beliebige Anzahl ab und lege den Rest in//beliebiger Reihenfolge zur\xFCck.";
GermanCardTexts[CardNames.THE_SWAMPS_GIFT] = "Nimm dir ein Irrlicht.";
GermanCardTexts[CardNames.THE_WINDS_GIFT] = "|+2 Karten|//Lege 2 Handkarten ab.";
GermanCardTexts[CardNames.HEX_DRAWPILE] = "";
GermanCardTexts[CardNames.HEX_DISCARDPILE] = "";
GermanCardTexts[CardNames.BAD_OMENS] = "Lege deinen Nachziehstapel auf deinen Ablage-//stapel. Durchsuche deinen Ablagestapel und lege//2 Kupfer auf deinen Nachziehstapel (oder decke//den Ablagestapel auf, wenn das nicht m\xF6glich ist).";
GermanCardTexts[CardNames.DELUSION] = "Wenn du nicht |Get\xE4uscht| oder |Neidisch|//hast, erhalte |Get\xE4uscht|.";
GermanCardTexts[CardNames.ENVY] = "Wenn du nicht |Get\xE4uscht| oder |Neidisch|//hast, erhalte |Neidisch|.";
GermanCardTexts[CardNames.FAMINE] = "Decke die obersten 3 Karten deines Nachziehstapels//auf. Lege die Aktionskarten ab. Mische den Rest//in deinen Nachziehstapel.";
GermanCardTexts[CardNames.FEAR] = "Wenn du mindestens 5 Karten auf der Hand hast,//lege eine Aktions- oder Geldkarte ab (oder decke//die Hand auf, wenn dies nicht m\xF6glich ist).";
GermanCardTexts[CardNames.GREED] = "Nimm dir ein Kupfer auf//deinen Nachziehstapel.";
GermanCardTexts[CardNames.HAUNTING] = "Wenn du mindestens 4 Karten auf der Hand hast,//lege eine davon auf deinen Nachziehstapel.";
GermanCardTexts[CardNames.LOCUSTS] = "  Entsorge die oberste Karte deines Nachziehstapels. Wenn es  //ein Kupfer oder Anwesen ist, nimm dir einen Fluch. Ansons-//ten, nimm dir eine Karte, die weniger kostet und mindestens//einen Kartentyp mit der entsorgten Karte gemeinsam hat.";
GermanCardTexts[CardNames.MISERY] = "Wenn dieses dein erstes Elend in diesem Spiel ist,//erhalte |Elendig|. Ansonsten, drehe es um zu//|Doppelt Elendig|.";
GermanCardTexts[CardNames.PLAGUE] = "Nimm dir einen Fluch auf die Hand.";
GermanCardTexts[CardNames.POVERTY] = "Lege alle bis auf 3 Karten//von deiner Hand ab.";
GermanCardTexts[CardNames.WAR] = "Decke Karten von deinem Nachziehstapel auf,//bis du eine aufdeckst, die [3] oder [4] kostet.//Entsorge diese und lege den Rest ab.";
GermanCardTexts[CardNames.MISERABLE] = "{!-2}";
GermanCardTexts[CardNames.TWICE_MISERABLE] = "{!-4}";
GermanCardTexts[CardNames.ENVIOUS] = "Zu Beginn deiner Kaufphase, lege diesen Zustand//zur\xFCck und Silber und Gold produzieren//in diesem Zug [1].   ";
GermanCardTexts[CardNames.DELUDED] = "Zu Beginn deiner Kaufphase, lege diesen Zustand//zur\xFCck und du kannst in diesem Zug keine//Aktionskarten kaufen.";
GermanCardTexts[CardNames.LOST_IN_THE_WOODS] = "Zu Beginn deines Zuges darfst du eine Karte//ablegen, um eine Gabe zu empfangen.";
GermanCardTexts[CardNames.BARD] = "|+[2]|//Du empf\xE4ngst eine Gabe.";
GermanCardTexts[CardNames.BLESSED_VILLAGE] = "|+1 Karte|//|+2 Aktionen|//---//Wenn du diese Karte//nimmst, erhalte eine Gabe. Du//empf\xE4ngst sie jetzt oder zu Beginn//deines n\xE4chsten Zuges.";
GermanCardTexts[CardNames.CHANGELING] = "Entsorge diese Karte. Nimm//dir ein Exemplar einer Karte,//die du im Spiel hast.//---//In Spielen, in denen diese Karte//verwendet wird: Wenn du eine//Karte nimmst, die [3] oder mehr// kostet, darfst du sie gegen einen//Wechselbalg austauschen.";
GermanCardTexts[CardNames.CEMETERY] = "{!2}//---////Wenn du diese Karte nimmst,//entsorge bis zu 4 Handkarten.////%%(Erbst\xFCck: Zauberspiegel)%%";
GermanCardTexts[CardNames.COBBLER] = "Zu Beginn deines n\xE4chsten//Zuges, nimm dir eine Karte,//die bis zu [4] kostet,//auf die Hand.";
GermanCardTexts[CardNames.CONCLAVE] = "|+[2]|////Du darfst eine Aktionskarte aus//deiner Hand spielen, von der du//kein Exemplar im Spiel hast.//Wenn du das machst, |+1 Aktion.|";
GermanCardTexts[CardNames.CRYPT] = "Lege eine beliebige Anzahl//Geldkarten, die keine Dauerkarten//sind und die du im Spiel//hast, verdeckt zur Seite (unter//diese Karte). Solange Karten//\xFCbrig sind, zu Beginn jedes//deiner Z\xFCge, nimm eine//der Karten auf die Hand.";
GermanCardTexts[CardNames.CURSED_VILLAGE] = "|+2 Aktionen|////Ziehe Karten, bis du 6 auf//der Hand hast.//---//Wenn du diese Karte nimmst,//empf\xE4ngst du eine Plage.";
GermanCardTexts[CardNames.DEN_OF_SIN] = "Zu Beginn deines n\xE4chsten//Zuges: |+2 Karten|.//---//Diese Karte wird auf die//Hand genommen (anstatt//auf den Ablagestapel).";
GermanCardTexts[CardNames.DEVILS_WORKSHOP] = "Wenn die Anzahl der Karten, die//du in diesem Zug genommen hast...////|2+ ist: |nimm dir ein Teufelchen;//| 1 ist: |nimm dir eine Karte, die//bis zu [4] kostet;//|0 ist: |nimm dir ein Gold.        ";
GermanCardTexts[CardNames.DRUID] = "|+1 Kauf|////Du empf\xE4ngst eine der zur//Seite gelegten Gaben (sie// bleiben dort liegen).//---//Spielvorbereitung://Lege 3 Gaben offen zur Seite.";
GermanCardTexts[CardNames.EXORCIST] = "Entsorge eine Handkarte.//Nimm dir eine Erscheinung,//die weniger kostet, von einem//der Erscheinungs-Stapel.";
GermanCardTexts[CardNames.FAITHFUL_HOUND] = "|+2 Karten|//---//Wenn du diese Karte au\xDFerhalb//der Aufr\xE4umphase ablegst, darfst//du sie zur Seite legen und sie am//Ende des Zuges zur\xFCck auf//die Hand nehmen.";
GermanCardTexts[CardNames.FOOL] = "//Wenn du nicht der Spieler mit//|Im Wald verirrt| bist, erhalte//diesen Zustand, 3 Gaben und//du empf\xE4ngst diese Gaben in//beliebiger Reihenfolge.////%%(Erbst\xFCck: Gl\xFCckstaler)%%";
GermanCardTexts[CardNames.GHOST_TOWN] = "Zu Beginn deines//n\xE4chsten Zuges: |+1 Karte|//und |+1 Aktion|.//---//Diese Karte wird auf die//Hand genommen (anstatt//auf den Ablagestapel).";
GermanCardTexts[CardNames.GUARDIAN] = "Zu Beginn deines n\xE4chsten//Zuges, +[1].//Bis dahin: Wenn ein anderer Spieler//eine Angriffskarte spielt, bist du//von deren Effekt nicht betroffen.//---//Diese Karte wird auf die Hand ge-//nommen (nicht auf den Ablagestapel).";
GermanCardTexts[CardNames.IDOL] = "[!2]////Wenn du eine ungerade Anzahl//G\xF6tzen im Spiel hast, empf\xE4ngst//du eine Gabe; anderenfalls nimmt//jeder Mitspieler einen Fluch.";
GermanCardTexts[CardNames.LEPRECHAUN] = "Nimm dir ein Gold. Wenn//du genau 7 Karten im Spiel//hast, nimm dir einen//Wunsch. Ansonsten//empf\xE4ngst du eine Plage.";
GermanCardTexts[CardNames.MONASTERY] = "F\xFCr jede Karte, die du in//diesem Zug genommen hast,//darfst du eine Handkarte//oder ein Kupfer, das du im//Spiel hast, entsorgen.";
GermanCardTexts[CardNames.NECROMANCER] = "Spiele eine mit der Bildseite nach//oben liegende Aktionskarte aus dem//M\xFCll-Stapel, die keine Dauerkarte ist,//wobei die Karte dort bleibt und bis//zum Ende des Zuges umgedreht wird.//---//Spielvorbereitung: Lege die//3 Zombies auf den M\xFCll-Stapel.";
GermanCardTexts[CardNames.NIGHT_WATCHMAN] = "Sieh dir die obersten 5 Karten//deines Nachziehstapels an, lege//eine beliebige Anzahl Karten ab//und lege den Rest in beliebiger//Reihenfolge zur\xFCck.//---//Diese Karte wird auf die Hand genommen//(anstatt auf den Ablagestapel).";
GermanCardTexts[CardNames.PIXIE] = "|+1 Karte|//|+1 Aktion|////Lege die oberste Gabe ab. Du//darfst diese Karte entsorgen, um//die Gabe zweimal zu empfangen.////%%(Erbst\xFCck: Ziege)%%";
GermanCardTexts[CardNames.POOKA] = "Du darfst eine Geldkarte//au\xDFer Verfluchtes Gold aus//deiner Hand entsorgen f\xFCr //|+4 Karten|.//////%%(Erbst\xFCck: Verfluchtes Gold)%%";
GermanCardTexts[CardNames.RAIDER] = "Jeder andere Spieler mit 5 oder//mehr Handkarten legt ein Exemplar//einer Karte, die du im Spiel hast,//ab (oder deckt die Hand auf, wenn//dies nicht m\xF6glich ist).//Zu Beginn deines n\xE4chsten Zuges,//+[3].";
GermanCardTexts[CardNames.SACRED_GROVE] = "|+1 Kauf|//|+[3]|////Du empf\xE4ngst eine Gabe.//Wenn diese nicht +[1] enh\xE4lt,//d\xFCrfen alle anderen Spieler//sie auch empfangen.";
GermanCardTexts[CardNames.SECRET_CAVE] = "|+1 Karte|//|+1 Aktion|////Du darfst 3 Karten ablegen. Wenn//du das gemacht hast, zu Beginn//deines n\xE4chsten Zuges, +[3].////%%(Erbst\xFCck: Wunderlampe)%%";
GermanCardTexts[CardNames.SHEPHERD] = "|+1 Aktion|////Lege eine beliebige Anzahl//Punktekarten ab, wobei du//sie aufdeckst. |+2 Karten|// pro abgelegter Karte.////%%(Erbst\xFCck: Weideland)%%";
GermanCardTexts[CardNames.SKULK] = "|+1 Kauf|////Jeder andere Spieler empf\xE4ngt//die n\xE4chste Plage.//---//Wenn du diese Karte nimmst,//nimm dir ein Gold.";
GermanCardTexts[CardNames.TORMENTOR] = "|+[2]|////Wenn du keine andere Karte//im Spiel hast, nimm dir ein//Teufelchen; ansonsten//empf\xE4ngt jeder andere//Spieler die n\xE4chste Plage.";
GermanCardTexts[CardNames.TRAGIC_HERO] = "|+3 Karten|//|+1 Kauf|//Wenn du 8 oder mehr Kar-//ten auf Hand hast (nach//dem Ziehen), entsorge//diese Karte und nimm//dir eine Geldkarte.";
GermanCardTexts[CardNames.TRACKER] = "|+[1]|//Wenn du in diesem Zug//eine Karte nimmst,//darfst du sie auf deinen//Nachziehstapel legen.////Du empf\xE4ngst eine Gabe.//%%(Erbst\xFCck: Beutel)%%";
GermanCardTexts[CardNames.VAMPIRE] = "Jeder andere Spieler empf\xE4ngt die//n\xE4chste Plage. Nimm dir eine//Karte, die bis zu [5] kostet, au\xDFer//einer Vampirin. Tausche diese//Karte gegen eine Fledermaus aus.";
GermanCardTexts[CardNames.WEREWOLF] = "Wenn deine Nachtphase ist,//empf\xE4ngt jeder andere Spieler//die n\xE4chste Plage. Ansonsten,//|+3 Karten|.";
GermanCardTexts[CardNames.CURSED_GOLD] = "[!3]////Nimm einen Fluch.";
GermanCardTexts[CardNames.GOAT] = "[!1]////Du darfst eine//Handkarte entsorgen";
GermanCardTexts[CardNames.HAUNTED_MIRROR] = "[!1]////---////Wenn du diese Karte entsorgst,//darfst du eine Aktionskarte able-//gen, um einen Geist zu nehmen.";
GermanCardTexts[CardNames.LUCKY_COIN] = "[!1]////Wenn du diese Karte spielst,//nimm dir ein Silber.";
GermanCardTexts[CardNames.MAGIC_LAMP] = "[!1]//Wenn du wenigstens 6 Karten//mit jeweils genau einem//Exemplar im Spiel hast, ent-//sorge diese Karte. Wenn du das//machst, nimm dir 3 W\xFCnsche.";
GermanCardTexts[CardNames.PASTURE] = "[!1]////---////Wert {!1} pro Anwesen,//das du besitzt.";
GermanCardTexts[CardNames.POUCH] = "[!1]////|+1 Kauf|";
GermanCardTexts[CardNames.BAT] = "Entsorge bis zu 2 Handkarten.//Wenn du wenigstens eine entsorgt//hast, tausche diese Karte gegen//eine Vampirin aus.////%(Diese Karte geh\xF6rt%//%nicht zum Vorrat.)%";
GermanCardTexts[CardNames.GHOST] = "Decke Karten von deinem Nachziehstapel//auf, bis du eine Aktionskarte aufdeckst.//Lege die anderen Karten ab und lege//die Aktionskarte zur Seite. Zu Beginn//deines n\xE4chsten Zuges, spiele diese//Aktionskarte zweimal.////%(Diese Karte geh\xF6rt nicht zum Vorrat.)%";
GermanCardTexts[CardNames.IMP] = "|+2 Karten|////Du darfst eine Aktionskarte aus//deiner Hand spielen, von der//du kein Exemplar im Spiel hast.////%(Diese Karte geh\xF6rt%//%nicht zum Vorrat.)%";
GermanCardTexts[CardNames.WILL_O_WISP] = "|+1 Karte|//|+1 Aktion|////Decke die oberste Karte deines//Nachziehstapels auf.//Wenn sie [2] oder weniger kostet,//nimm sie auf die Hand.////%(Diese Karte geh\xF6rt nicht zum Vorrat.)%";
GermanCardTexts[CardNames.WISH] = "|+1 Aktion|////Lege diese Karte zur\xFCck auf ihren//Stapel. Wenn du das gemacht hast,//nimm dir eine Karte, die bis zu//[6] kostet, auf die Hand.////%(Diese Karte geh\xF6rt nicht zum Vorrat.)%";
GermanCardTexts[CardNames.ZOMBIE_APPRENTICE] = "Du darfst eine Aktionskarte//aus deiner Hand f\xFCr |+3 Karten|//und |+1 Aktion| entsorgen.";
GermanCardTexts[CardNames.ZOMBIE_MASON] = "Entsorge die oberste Karte//deines Nachziehstapels.//Du darfst dir eine Karte nehmen,//die bis zu [1] mehr kostet.";
GermanCardTexts[CardNames.ZOMBIE_SPY] = "|+1 Karte|//|+1 Aktion|////Sieh dir die oberste Karte deines//Nachziehstapels an. Du darfst sie//ablegen oder zur\xFCcklegen.";
GermanCardTexts[CardNames.ACTING_TROUPE] = "|+4 Dorfbewohner|////Entsorge diese Karte.";
GermanCardTexts[CardNames.BORDER_GUARD] = "|+1 Aktion|////Decke die obersten beiden Kar-//ten deines Nachziehstapels auf.//Nimm eine davon auf die Hand//und lege die andere ab. Falls bei-//des Aktionskarten waren, erhalte//die Laterne oder das Horn.";
GermanCardTexts[CardNames.CARGO_SHIP] = "|+[2]|////Einmal in diesem Zug, wenn du//eine Karte nimmst, darfst du sie//offen zur Seite legen (auf diese//Karte). Zu Beginn deines//n\xE4chsten Zuges nimmst du sie//auf die Hand.";
GermanCardTexts[CardNames.DUCAT] = "|+1 Taler|//|+1 Kauf|//---//Wenn du diese Karte nimmst,//darfst du ein Kupfer aus//deiner Hand entsorgen.";
GermanCardTexts[CardNames.EXPERIMENT] = "|+2 Karten|//|+1 Aktion|//Lege diese Karte zur\xFCck//auf ihren Stapel.//---//Wenn du diese Karte nimmst,//nimm dir noch ein Experiment//(das aber kein weiteres mitbringt).";
GermanCardTexts[CardNames.FLAG_BEARER] = "|+[2]|////---////Wenn du diese Karte//nimmst oder entsorgst,//erhalte die Fahne.";
GermanCardTexts[CardNames.HIDEOUT] = "|+1 Karte|//|+2 Aktionen|////Entsorge eine Karte aus deiner//Hand. Ist es eine Punktekarte,//nimm dir einen Fluch.";
GermanCardTexts[CardNames.INVENTOR] = "Nimm dir eine Karte,//die bis zu [4] kostet.//Ab jetzt kosten Karten [1]//weniger in diesem Zug.";
GermanCardTexts[CardNames.IMPROVE] = "|+[2]|////Zu Beginn der Aufr\xE4umphase//darfst du eine Aktionskarte entsor-//gen, die du aus dem Spiel ablegen//w\xFCrdest, um eine Karte zu neh-//men, die genau [1] mehr kostet.";
GermanCardTexts[CardNames.LACKEYS] = "|+2 Karten|////---////Wenn du diese Karte// nimmst, |+2 Dorf-|//|bewohner|.";
GermanCardTexts[CardNames.MOUNTAIN_VILLAGE] = "|+2 Aktionen|////Durchsuche deinen Ablage-//stapel und nimm eine Karte//daraus auf die Hand. Falls das//nicht geht, |+1 Karte|.";
GermanCardTexts[CardNames.PATRON] = "|+1 Dorfbewohner|//|+[2]|//---//Wenn etwas dich diese Karte in//einer Aktionsphase aufdecken//l\xE4sst (mit Worten wie \u201Edecke auf\u201C//oder \u201Eaufgedeckt\u201C), |+1 Taler|.";
GermanCardTexts[CardNames.PRIEST] = "|+[2]|////Entsorge eine Karte aus//deiner Hand. F\xFCr den Rest des//Zuges, immer wenn du eine//Karte entsorgst, |+|[2].";
GermanCardTexts[CardNames.RESEARCH] = "|+1 Aktion|////Entsorge eine Karte aus deiner//Hand. Pro [1], das sie kostet, lege//eine Karte von deinem Nachzieh-//stapel verdeckt beiseite (auf//diese Karte). Zu Beginn deines//n\xE4chsten Zuges, nimm diese//Karten auf die Hand.";
GermanCardTexts[CardNames.RESEARCH] = "|+1 Aktion|//Entsorge eine Karte aus deiner//Hand. Pro [1], das sie kostet, lege//eine Karte von deinem Nachzieh-//stapel verdeckt beiseite (auf//diese Karte). Zu Beginn deines//n\xE4chsten Zuges, nimm diese//Karten auf die Hand.";
GermanCardTexts[CardNames.SILK_MERCHANT] = "|+2 Karten|//|+1 Kauf|//---//Wenn du diese Karte nimmst//oder entsorgst, |+1 Taler| und//|+1 Dorfbewohner|.";
GermanCardTexts[CardNames.OLD_WITCH] = "|+3 Karten|////Jeder andere Spieler nimmt//sich einen Fluch und darf//einen Fluch aus seiner//Hand entsorgen.";
GermanCardTexts[CardNames.RECRUITER] = "|+2 Karten|////Entsorge eine Karte//aus deiner Hand.// |+1 Dorfbewohner|//pro [1], das sie kostet.";
GermanCardTexts[CardNames.SCEPTER] = "W\xE4hle eines: |+|[2];////oder spiele eine immer noch//im Spiel befindliche Aktions-//karte, die keine Befehlskarte//ist und die du in diesem Zug//gespielt hast, noch einmal.";
GermanCardTexts[CardNames.SCHOLAR] = "Lege deine Hand ab.//|+7 Karten|.";
GermanCardTexts[CardNames.SCULPTOR] = "Nimm eine Karte auf die//Hand, die bis zu [4]//kostet. Ist es eine Geld-//karte, |+1 Dorfbewohner|.";
GermanCardTexts[CardNames.SEER] = "|+1 Karte|//|+1 Aktion|////Decke die obersten 3 Karten deines//Nachziehstapels auf. Nimm alle//Karten, die zwischen [2] und [4] kos-//ten, auf die Hand und lege den Rest//in beliebiger Reihenfolge zur\xFCck.";
GermanCardTexts[CardNames.SEER] = "|+1 Karte|//|+1 Aktion|////Decke die obersten 3 Karten deines//Nachziehstapels auf. Nimm alle Kar-//ten, die zwischen [2] und [4] kos-//ten, auf die Hand und lege den Rest//in beliebiger Reihenfolge zur\xFCck.";
GermanCardTexts[CardNames.SPICES] = "[!2]////|+1 Kauf|//---//Wenn du diese Karte//nimmst, |+2 Taler|.";
GermanCardTexts[CardNames.SWASHBUCKLER] = "|+3 Karten|////Falls dein Ablagestapel Karten//enth\xE4lt: |+1 Taler|, und wenn//du jetzt mindestens 4 Taler//hast, erhalte die Schatzkiste.";
GermanCardTexts[CardNames.TREASURER] = "|+[3]|////W\xE4hle eines: Entsorge eine//Geldkarte aus deiner Hand;//oder nimm eine Geldkarte aus//dem M\xFCll auf die Hand;//oder erhalte den Schl\xFCssel.";
GermanCardTexts[CardNames.VILLAIN] = "|+2 Taler|////Jeder andere Spieler mit 5 oder//mehr Karten auf der Hand legt//eine Karte ab, die [2] oder//mehr kostet (oder deckt die//Hand auf, wenn es nicht geht).";
GermanCardTexts[CardNames.FLAG] = "Wenn Du die Handkarten f\xFCr deinen//n\xE4chsten Zug ziehst,|+1 Karte|.";
GermanCardTexts[CardNames.HORN] = "Einmal pro Zug, wenn du einen Grenzposten aus//dem Spiel ablegst, darfst du ihn auf//deinen Nachziehstapel legen.";
GermanCardTexts[CardNames.KEY] = "Zu Beginn deines Zuges, |+|[1].";
GermanCardTexts[CardNames.LANTERN] = "Grenzposten die du spielst decken 3 Karten auf//und legen 2 ab. Nur wenn alle 3 aufgedeckten//Karten Aktionen sind, kannst du das Horn erhalten.";
GermanCardTexts[CardNames.TREASURE_CHEST] = "Zu Beginn deiner Kaufphase,//nimm dir ein Gold.";
GermanCardTexts[CardNames.ACADEMY] = "Wenn du eine Aktionskarte nimmst,//|+1 Dorfbewohner|.";
GermanCardTexts[CardNames.BARRACKS] = "Zu Beginn deines Zuges, |+1 Aktion|.";
GermanCardTexts[CardNames.CANAL] = "W\xE4hrend deiner Z\xFCge kosten//Karten [1] weniger.";
GermanCardTexts[CardNames.CAPITALISM] = "W\xE4hrend deiner Z\xFCge sind Aktionen mit//+[]-Betr\xE4gen im Text auch Geldkarten.";
GermanCardTexts[CardNames.CATHEDRAL] = "Zu Beginn deines Zuges,//entsorge eine Karte aus deiner Hand.";
GermanCardTexts[CardNames.CITADEL] = "Das erste Mal in jedem deiner Z\xFCge,//wenn du eine Aktionskarte spielst,//spiele sie danach noch einmal.";
GermanCardTexts[CardNames.CITY_GATE] = "Zu Beginn deines Zuges, |+1 Karte| und//lege dann eine Karte aus deiner Hand//auf deinen Nachziehstapel.";
GermanCardTexts[CardNames.CROP_ROTATION] = "Zu Beginn deines Zuges darfst du eine//Punktekarte aus deiner Hand ablegen f\xFCr//|+2 Karten|.";
GermanCardTexts[CardNames.EXPLORATION] = "Am Ende deiner Kaufphase, wenn du in//ihr keine Karte genommen hast, //|+1 Taler| und |+1 Dorfbewohner|.";
GermanCardTexts[CardNames.FAIR] = "Zu Beginn deines Zuges, |+1 Kauf|.";
GermanCardTexts[CardNames.FLEET] = "Nach dem Spielende gibt es eine//extra Runde mit Z\xFCgen nur f\xFCr Spieler,//die dies gekauft haben.";
GermanCardTexts[CardNames.GUILDHALL] = "Wenn du eine Geldkarte nimmst,//|+1 Taler|.";
GermanCardTexts[CardNames.INNOVATION] = "Einmal in jedem deiner Z\xFCge darfst du, wenn//du eine Aktionskarte nimmst, jene spielen.";
GermanCardTexts[CardNames.PAGEANT] = "Am Ende deiner Kaufphase darfst du//[1] bezahlen f\xFCr |+1 Taler|.";
GermanCardTexts[CardNames.PIAZZA] = "Zu Beginn deines Zuges, decke die oberste//Karte deines Nachziehstapels auf.//Ist es eine Aktionskarte, spiele sie.";
GermanCardTexts[CardNames.ROAD_NETWORK] = "Wenn ein anderer Spieler eine//Punktekarte nimmt, |+1 Karte|.";
GermanCardTexts[CardNames.SEWERS] = "Wenn du eine Karte entsorgst (au\xDFer mit dieser//F\xE4higkeit), darfst du eine Karte//aus deiner Hand entsorgen.";
GermanCardTexts[CardNames.SILOS] = "Zu Beginn deines Zuges, lege eine beliebige//Anzahl Kupfer aufgedeckt ab und//ziehe danach so viele Karten.";
GermanCardTexts[CardNames.SINISTER_PLOT] = "Zu Beginn deines Zuges, lege einen Marker hier//drauf oder entferne alle deine Marker von hier//und erhalte f\xFCr jeden |+1 Karte|.";
GermanCardTexts[CardNames.STAR_CHART] = "Wenn du mischst, darfst du eine der//Karten aussuchen und nach oben legen.";
GermanCardTexts[CardNames.SAUNA] = "|+1 Karte|//|+1 Aktion|////Du darfst ein Eisloch aus//deiner Hand spielen.////Wenn du in diesem Zug ein Silber//spielst, darfst du eine Karte//aus deiner Hand entsorgen.";
GermanCardTexts[CardNames.AVANTO] = "|+3 Karten|//////Du darfst eine Sauna//von deiner Hand spielen.";
GermanCardTexts[CardNames.BLACK_MARKET] = "|+[2]|//Decke die obersten 3 Karten des//Schwarzmarkt-Decks auf. Spiele eine//beliebige Anzahl Geldkarten aus deiner//Hand. Du darfst eine der aufgedeckten//Karten kaufen. Lege den Rest in//beliebiger Reihenfolge unter//das Schwarzmarkt-Deck.//---//Spielvorbereitung: Erstelle ein//Schwarzmarkt-Deck aus ungenutzten//K\xF6nigreichkarten.";
GermanCardTexts[CardNames.ENVOY] = "Decke die obersten 5 Karten//deines Nachziehstapels auf.////Der Spieler links von dir w\xE4hlt//eine der Karten. Lege diese//Karte ab und nimm den//Rest auf deine Hand.";
GermanCardTexts[CardNames.GOVERNOR] = "|+1 Aktion|////W\xE4hle eines, f\xFCr dich gelten//die Angaben in Klammern://Jeder Spieler bekommt |+1 (+3) Karten|;//oder jeder Spieler nimmt sich ein Silber//(Gold); oder jeder Spieler darf eine//Karte aus seiner Hand entsorgen und//sich eine Karte nehmen, die genau//[1]([2]) mehr kostet.";
GermanCardTexts[CardNames.PRINCE] = "//Du darfst eine Aktionskarte, die//keine Befehls- oder Dauerkarte//ist und bis zu [4] kostet, von//deiner Hand zur Seite legen//(auf diese Karte).////Spiele sie zu Beginn jedes//deiner Züge, aber lasse//sie beiseite liegen.";
GermanCardTexts[CardNames.STASH] = "[!2]//---//Wenn du deinen Nachziehstapel mischst,//und diese Karte im Nachziehstapel ist,//darfst du sie an einer beliebigen Stelle //in deinem Nachziehstapel platzieren.";
GermanCardTexts[CardNames.SUMMON] = "Nimm dir eine Aktionskarte, die bis zu [4] kostet.//Lege sie zur Seite. Wenn du das machst,//zu Beginn deines n\xE4chsten Zuges, spiele sie.";
GermanCardTexts[CardNames.WALLED_VILLAGE] = "|+1 Karte|//|+2 Aktionen|//---//Zu Beginn deiner Aufr\xE4umphase,//wenn du diese und nicht mehr als//eine weitere Aktionskarte im Spiel//hast, darfst du diese Karte auf//deinen Nachziehstapel legen.";
GermanCardTexts[CardNames.BLACK_MARKET_PILE] = "";
GermanCardTexts[CardNames.DISMANTLE] = "Entsorge eine Handkarte.//Wenn sie [1] oder mehr//kostet, nimm dir eine//Karte, die weniger//kostet, und ein Gold.";
GermanCardTexts[CardNames.CAPTAIN] = "Jetzt und zu Beginn deines//n\xE4chsten Zuges: Spiele eine//Aktionskarte im Vorrat, die//keine Dauer- oder Befehls-//karte ist und bis zu [4] kostet//und lasse sie dort liegen.";
GermanCardTexts[CardNames.CHURCH] = "|+1 Aktion|////Lege bis zu 3 deiner Handkarten//verdeckt zur Seite. Nimm sie zu//Beginn deines n\xE4chsten Zuges auf//deine Hand, danach darfst du eine//deiner Handkarten entsorgen.";
GermanCardTexts[CardNames.BLACK_CAT] = "|+2 Karten|//Falls es nicht dein Zug ist, nimmt//jeder andere Spieler einen Fluch.//---//Wenn ein anderer Spieler eine//Punktekarte nimmt, darfst du diese//Karte aus deiner Hand spielen.";
GermanCardTexts[CardNames.SLEIGH] = "Nimm dir 2 Pferde.//---//Wenn du eine Karte nimmst, darfst//du diese Karte aus deiner Hand//ablegen, um jene Karte auf die//Hand zu bekommen oder auf//deinen Nachziehstapel zu legen.";
GermanCardTexts[CardNames.SUPPLIES] = "[!1]////Nimm ein Pferd auf//deinen Nachziehstapel.";
GermanCardTexts[CardNames.CAMEL_TRAIN] = "Lege eine Nicht-Punktekarte//aus dem Vorrat ins Exil.//---//Wenn du diese Karte//nimmst, lege ein Gold//aus dem Vorrat ins Exil.";
GermanCardTexts[CardNames.GOATHERD] = "|+1 Aktion|////Du darfst eine Karte aus//deiner Hand entsorgen.////|+1 Karte| pro Karte, die der//Spieler rechts von dir in sei-//nem letzen Zug entsorgt hat.";
GermanCardTexts[CardNames.SCRAP] = "Entsorge eine Karte aus//deiner Hand. Pro [1], das sie//kostet, w\xE4hle einen anderen//Effekt: |+1 Karte; +1 Aktion;|//|+1 Kauf; +|[1]; nimm dir ein//Silber; nimm dir ein Pferd.";
GermanCardTexts[CardNames.SHEEPDOG] = "|+2 Karten|//---//Wenn du eine Karte nimmst,//darfst du diese Karte hier//aus deiner Hand spielen.";
GermanCardTexts[CardNames.SNOWY_VILLAGE] = "|+1 Karte|//|+4 Aktionen|//|+1 Kauf|////Ignoriere alle weiteren //|+Aktionen|, die du in//diesem Zug bekommst.";
GermanCardTexts[CardNames.STOCKPILE] = "[!3]////|+1 Kauf|////Lege diese Karte ins Exil.";
GermanCardTexts[CardNames.BOUNTY_HUNTER] = "|+1 Aktion|////Lege eine Karte aus deiner//Hand ins Exil. Falls//du keine gleiche Karte//im Exil hattest, |+|[3].";
GermanCardTexts[CardNames.CARDINAL] = "|+[2]|////Jeder andere Spieler deckt die//beiden obersten Karten seines//Nachziehstapels auf, legt eine da-//von, die zwischen [3] und [6] kos-//tet, ins Exil und legt den Rest ab.";
GermanCardTexts[CardNames.CAVALRY] = "Nimm dir 2 Pferde.//---//Wenn du diese Karte nimmst,//|+2 Karten|, |+1 Kauf| und//wenn es deine Kaufphase ist,//gehe zur\xFCck zur Aktionsphase.";
GermanCardTexts[CardNames.GROOM] = "Nimm dir eine Karte, die// bis zu [4] kostet. Ist es eine...////Aktionskarte: nimm dir ein Pferd;//Geldkarte: nimm dir ein Silber;//Punktekarte: |+1 Karte |und //|+1 Aktion|.";
GermanCardTexts[CardNames.HOSTELRY] = "|+1 Karte|//|+2 Aktionen|//---//Wenn du diese Karte nimmst,//lege eine beliebige Anzahl Geld-//karten aufgedeckt ab, um dir//genauso viele Pferde zu nehmen.";
GermanCardTexts[CardNames.VILLAGE_GREEN] = "Entweder jetzt oder zu//Beginn deines n\xE4chsten Zuges,//// |+1 Karte| und |+2 Aktionen.|//---//Wenn du diese Karte au\xDFerhalb der//Aufr\xE4umphase ablegst, darfst du//sie aufdecken, um sie zu spielen.";
GermanCardTexts[CardNames.BARGE] = "Entweder jetzt oder //zu Beginn deines//n\xE4chsten Zuges,//// |+3 Karten| und |+1 Kauf.|";
GermanCardTexts[CardNames.COVEN] = "|+1 Aktion|//|+[2]|////Jeder andere Spieler legt einen//Fluch aus dem Vorrat in sein Exil.//Falls das nicht geht, legt der//Spieler alle seine Fl\xFCche aus//seinem Exil ab.";
GermanCardTexts[CardNames.DISPLACE] = "Lege eine Karte aus //deiner Hand ins Exil.//Nimm dir eine Karte mit//anderem Namen, die//h\xF6chstens [2] mehr kostet.";
GermanCardTexts[CardNames.FALCONER] = "Nimm eine Karte auf die//Hand, die weniger als diese kostet.//---//Wenn ein Spieler sich eine Karte//mit mehr als 1 Typ nimmt (Akti-//on, Angriff, usw.), darfst du diese//Karte aus deiner Hand spielen.";
GermanCardTexts[CardNames.FISHERMAN] = "|+1 Karte|//|+1 Aktion|//|+[1]|//---//W\xE4hrend deiner Z\xFCge, wenn//dein Ablagestapel leer ist, kos-//tet diese Karte [3] weniger.";
GermanCardTexts[CardNames.GATEKEEPER] = "//Zu Beginn deines n\xE4chsten Zu-//ges |+|[3]. Bis dahin, wenn ein//anderer Spieler eine Aktions-//oder Geldkarte nimmt, ohne//eine gleiche Karte im Exil zu//haben, legt er sie ins Exil.";
GermanCardTexts[CardNames.HUNTING_LODGE] = "|+1 Karte|//|+2 Aktionen|////Du darfst deine Hand//ablegen, um |+5 Karten|//zu erhalten.";
GermanCardTexts[CardNames.KILN] = "|+[2]|////Wenn du das n\xE4chste Mal in//diesem Zug eine Karte spielst,//darfst du zuerst ein weiteres //Exemplar jener Karte nehmen.";
GermanCardTexts[CardNames.LIVERY] = "|+[3]|////Wenn du in diesem Zug//eine Karte nimmst, die//[4] oder mehr kostet,//dann nimm dir ein Pferd.";
GermanCardTexts[CardNames.MASTERMIND] = "Zu Beginn deines n\xE4chsten//Zuges darfst du eine //Aktionskarte aus deiner//Hand dreimal spielen.";
GermanCardTexts[CardNames.PADDOCK] = "|+[2]|////Nimm dir 2 Pferde.////|+1 Aktion| f\xFCr jeden//leeren Vorratsstapel.";
GermanCardTexts[CardNames.SANCTUARY] = "|+1 Karte|//|+1 Aktion|//|+1 Kauf|////Du darfst eine Karte aus//deiner Hand ins Exil legen.";
GermanCardTexts[CardNames.DESTRIER] = "|+2 Karten|//|+1 Aktion|//---//W\xE4hrend deiner Z\xFCge kostet//diese Karte [1] weniger pro//Karte, die du in diesem//Zug genommen hast.";
GermanCardTexts[CardNames.WAYFARER] = "|+3 Karten|////Du darfst ein Silber nehmen.//---//Diese Karte kostet so viel wie//die letzte andere in diesem//Zug genommene Karte,//falls es eine solche gibt.";
GermanCardTexts[CardNames.ANIMAL_FAIR] = "|+[4]|////|+1 Kauf| f\xFCr jeden//leeren Vorratsstapel.//---//Statt die Kosten dieser Karte zu//zahlen, darfst du eine Aktions-//karte aus deiner Hand entsorgen.";
GermanCardTexts[CardNames.HORSE] = "|+2 Karten|//|+1 Aktion|////Lege diese Karte zur\xFCck//auf den Pferde-Stapel.////%(Diese Karte geh\xF6rt%//%nicht zum Vorrrat.)%";
GermanCardTexts[CardNames.WAY_OF_THE_BUTTERFLY] = "Du darfst diese Karte auf ihren Stapel legen, um//eine Karte zu nehmen, die genau [1] mehr kostet.";
GermanCardTexts[CardNames.WAY_OF_THE_CAMEL] = "Lege ein Gold aus dem Vorrat ins Exil.";
GermanCardTexts[CardNames.WAY_OF_THE_CHAMELEON] = "Folge den Anweisungen dieser Karte; wenn dir//das in diesem Zug |+Karten| bringen w\xFCrde,//bekommst du |+|[] stattdessen, und umgekehrt.";
GermanCardTexts[CardNames.WAY_OF_THE_FROG] = "|+1 Aktion|////Wenn Du diese Karte in diesem Zug aus dem Spiel//ablegst, lege sie auf deinen Nachziehstapel.";
GermanCardTexts[CardNames.WAY_OF_THE_GOAT] = "Entsorge eine Karte aus deiner Hand.";
GermanCardTexts[CardNames.WAY_OF_THE_HORSE] = "|+2 Karten|//|+1 Aktion|//Lege diese Karte zur\xFCck auf ihren Stapel.";
GermanCardTexts[CardNames.WAY_OF_THE_MOLE] = "|+1 Aktion|////Lege deine Handkarten ab. |+3 Karten.|";
GermanCardTexts[CardNames.WAY_OF_THE_MONKEY] = "|+1 Kauf|//|+[1]|";
GermanCardTexts[CardNames.WAY_OF_THE_MOUSE] = "Spiele die beiseite gelegte Karte, und lasse sie dort liegen.//---//Spielvorbereitung: Lege eine ungenutzte// Aktionskarte beiseite, die [2] oder [3] kostet.";
GermanCardTexts[CardNames.WAY_OF_THE_MULE] = "|+1 Aktion|//|+[1]|";
GermanCardTexts[CardNames.WAY_OF_THE_OTTER] = "|+2 Karten|";
GermanCardTexts[CardNames.WAY_OF_THE_OWL] = "Ziehe Karten, bis du 6 auf der Hand hast.";
GermanCardTexts[CardNames.WAY_OF_THE_OX] = "|+2 Aktionen|";
GermanCardTexts[CardNames.WAY_OF_THE_PIG] = "|+1 Karte|//|+1 Aktion|";
GermanCardTexts[CardNames.WAY_OF_THE_RAT] = "Du darfst eine Geldkarte ablegen, um dir ein//weiteres Exemplar dieser Karte hier zu nehmen.";
GermanCardTexts[CardNames.WAY_OF_THE_SEAL] = "|+[1]|////Wenn du in diesem Zug eine Karte nimmst,//darfst du sie auf deinen Nachziehstapel legen.";
GermanCardTexts[CardNames.WAY_OF_THE_SHEEP] = "|+[2]|";
GermanCardTexts[CardNames.WAY_OF_THE_SQUIRREL] = "|+2 Karten| am Ende dieses Zuges.";
GermanCardTexts[CardNames.WAY_OF_THE_TURTLE] = "Lege diese Karte zur Seite. Konntest du das tun,//spiele sie zu Beginn deines n\xE4chsten Zuges.";
GermanCardTexts[CardNames.WAY_OF_THE_WORM] = "Lege ein Anwesen aus dem Vorrat ins Exil.";
GermanCardTexts[CardNames.DELAY] = "Du darfst eine Aktionskarte aus//deiner Hand zur Seite legen. Spiele//sie zu Beginn deines n\xE4chsten Zuges.";
GermanCardTexts[CardNames.DESPERATION] = "Einmal pro Zug: Du darfst dir einen Fluch//nehmen. Wenn du das tust, |+1 Kauf| und |+|[2].";
GermanCardTexts[CardNames.GAMBLE] = "|+1 Kauf.|//Decke die oberste Karte deines Nachziehstapels auf.//Ist es eine Aktions- oder Geldkarte, darfst du sie spielen.//Lege die Karte ab, wenn sie nicht gespielt wurde.  ";
GermanCardTexts[CardNames.PURSUE] = "|+1 Kauf|//Nenne eine Karte. Decke die obersten 4 Karten//deines Nachziehstapels auf. Lege die Treffer//zur\xFCck und die anderen ab.";
GermanCardTexts[CardNames.RIDE] = "Nimm dir ein Pferd.";
GermanCardTexts[CardNames.TOIL] = "|+1 Kauf|////Du darfst eine Aktionskarte//aus deiner Hand spielen.";
GermanCardTexts[CardNames.ENHANCE] = "Du darfst eine Nicht-Punktekarte aus deiner//Hand entsorgen, um dir eine Karte zu nehmen,//die h\xF6chstens [2] mehr kostet.";
GermanCardTexts[CardNames.MARCH] = "Durchsuche deinen Ablagestapel.//Du darfst eine Aktionskarte daraus spielen.";
GermanCardTexts[CardNames.TRANSPORT] = "W\xE4hle eines: Lege eine Aktionskarte aus dem//Vorrat ins Exil; oder lege eine Aktionskarte aus//deinem Exil auf deinen Nachziehstapel.";
GermanCardTexts[CardNames.BANISH] = "Lege eine beliebige Anzahl Karten mit//gleichem Namen aus Deiner Hand ins Exil.";
GermanCardTexts[CardNames.BARGAIN] = "Nimm dir eine Nicht-Punktekarte, die h\xF6chstens [5]//kostet. Jeder andere Spieler nimmt sich ein Pferd.";
GermanCardTexts[CardNames.INVEST] = "Lege eine Aktionskarte aus dem Vorrat ins Exil. So-//lange sie dort ist, wenn ein anderer Spieler sich eine//gleiche Karte nimmt oder in sie investiert,|+2 Karten|.";
GermanCardTexts[CardNames.SEIZE_THE_DAY] = "Einmal pro Spiel: Du erh\xE4ltst//einen zus\xE4tzlichen Zug nach diesem Zug.";
GermanCardTexts[CardNames.COMMERCE] = "Nimm dir ein Gold f\xFCr jede Karte mit//unterschiedlichem Namen, die du//in diesem Zug genommen hast.";
GermanCardTexts[CardNames.DEMAND] = "Nimm dir ein Pferd und dann eine Karte,//die h\xF6chstens [4] kostet,//jeweils auf deinen Nachziehstapel.";
GermanCardTexts[CardNames.STAMPEDE] = "Falls Du 5 oder weniger Karten im Spiel hast,//nimm dir 5 Pferde auf deinen Nachziehstapel.";
GermanCardTexts[CardNames.REAP] = "Nimm dir ein Gold. Lege es zur Seite. Konntest//du das tun, dann spiele es zu Beginn deines//n\xE4chsten Zuges.";
GermanCardTexts[CardNames.ENCLAVE] = "Nimm dir ein Gold.//Lege ein Herzogtum aus dem Vorrat ins Exil.";
GermanCardTexts[CardNames.ALLIANCE] = "Nimm dir eine Provinz, ein Herzogtum, ein//Anwesen, ein Gold, ein Silber und ein Kupfer.";
GermanCardTexts[CardNames.POPULATE] = "Nimm dir eine Karte von jedem//Aktionskartenstapel im Vorrat.";
GermanCardTexts[CardNames.CARD_OF_THE_BUTTERFLY] = "";
GermanCardTexts[CardNames.CARD_OF_THE_CAMEL] = "";
GermanCardTexts[CardNames.CARD_OF_THE_CHAMELEON] = "";
GermanCardTexts[CardNames.CARD_OF_THE_FROG] = "";
GermanCardTexts[CardNames.CARD_OF_THE_GOAT] = "";
GermanCardTexts[CardNames.CARD_OF_THE_HORSE] = "";
GermanCardTexts[CardNames.CARD_OF_THE_MOLE] = "";
GermanCardTexts[CardNames.CARD_OF_THE_MONKEY] = "";
GermanCardTexts[CardNames.CARD_OF_THE_MOUSE] = "";
GermanCardTexts[CardNames.CARD_OF_THE_MULE] = "";
GermanCardTexts[CardNames.CARD_OF_THE_OTTER] = "";
GermanCardTexts[CardNames.CARD_OF_THE_OWL] = "";
GermanCardTexts[CardNames.CARD_OF_THE_OX] = "";
GermanCardTexts[CardNames.CARD_OF_THE_PIG] = "";
GermanCardTexts[CardNames.CARD_OF_THE_RAT] = "";
GermanCardTexts[CardNames.CARD_OF_THE_SEAL] = "";
GermanCardTexts[CardNames.CARD_OF_THE_SHEEP] = "";
GermanCardTexts[CardNames.CARD_OF_THE_SQUIRREL] = "";
GermanCardTexts[CardNames.CARD_OF_THE_TURTLE] = "";
GermanCardTexts[CardNames.CARD_OF_THE_WORM] = "";

GermanCardTexts[CardNames.TOWN] = "Wähle eins:////|+1 Karte| und |+2 Aktionen|;////oder |+1 Kauf| and +[2].";
GermanCardTexts[CardNames.MODIFY] = "Entsorge eine//deiner Handkarten.////Wähle eins:// |+1 Karte| und |+1 Aktion|;//oder nimm eine Karte,//die bis zu [2] mehr kostet//als die entsorgte Karte.";
GermanCardTexts[CardNames.MERCHANT_CAMP] = "|+2 Aktionen|//|+[1]|//---//Wenn du diese Karte aus//dem Spiel ablegst, darfst//du sie auf deinen//Nachziehstapel legen.";
GermanCardTexts[CardNames.SWAP] = "|+1 Karte|//|+1 Aktion|//Du darfst eine Aktionskarte aus//deiner Hand auf ihren Stapel//zurücklegen, um eine andere//Aktionskarte auf deine Hand//zu nehmen, die bis zu [5] kostet.";
GermanCardTexts[CardNames.SKIRMISHER] = "|+1 Karte|//|+1 Aktion|//|+[1]|//Wenn du in diesem Zug//eine Angriffskarte nimmst,//legt jeder Mitspieler seine//Handkarten bis auf 3 ab.";
GermanCardTexts[CardNames.HIGHWAYMAN] = "////Zu Beginn deines nächsten Zuges:// Lege diese Karte aus dem Spiel ab// und |+3 Karten|.////Bis dahin hat die erste Geldkarte,//die jeder Mitspieler in jedem Zug//spielt, bei ihrem Spielen keine//Auswirkungen.";
GermanCardTexts[CardNames.BARBARIAN] = "|+[2]|//Jeder Mitspieler entsorgt die//oberste Karte seines Nachzieh-//stapels. Kostet sie [3] oder mehr,//nimmt er eine billigere Karte, die//mit der entsorgten Karte mindes-//tens 1 Kartentyp gemeinsam hat;//ansonsten nimmt er einen Fluch.";
GermanCardTexts[CardNames.INNKEEPER] = "|+1 Aktion|////Wähle eins://|+1 Karte| oder////|+3 Karten| und lege dann 3//Karten aus deiner Hand ab oder////|+5 Karten| und lege dann 6//Karten aus deiner Hand ab.";
GermanCardTexts[CardNames.CAPITAL_CITY] = "|+1 Karte|//|+2 Aktionen|//Du darfst 2 Karten aus//deiner Hand ablegen//für +[2].//Du darfst [2] zahlen//für |+2 Karten|.";
GermanCardTexts[CardNames.SPECIALIST] = "Du darfst eine Aktions-//oder Geldkarte aus//deiner Hand spielen.////Wähle eins://Spiele sie noch einmal;//oder nimm eine//gleiche Karte.";
GermanCardTexts[CardNames.CARPENTER] = "////Wenn kein Vorratsstapel//leer ist:  |+1 Aktion| und//nimm eine Karte, die bis//zu [4] kostet.////Ansonsten: Entsorge eine//deiner Handkarten und//nimm eine Karte, die bis//zu [2] mehr kostet als jene.";
GermanCardTexts[CardNames.ROYAL_GALLEY] = "|+1 Karte|////Du darfst eine Aktionskarte aus//deiner Hand spielen, die keine//Dauerkarte ist. Lege sie zur//Seite. Wenn du das getan hast,//spiele sie zu Beginn deines//nächsten Zuges.";
GermanCardTexts[CardNames.MARQUIS] = "|+1 Kauf|////|+1 Karte| pro Karte auf//deiner Hand. Lege deine//Handkarten bis auf 10 ab.";
GermanCardTexts[CardNames.SENTINEL] = "//////Sieh die obersten 5 Karten//deines Nachziehstapels an.//Du darfst bis zu 2 davon//entsorgen. Legen den Rest//in beliebiger Reihenfolge//zurück.";
GermanCardTexts[CardNames.COURIER] = "|+[1]|////Lege die oberste Karte deines//Nachziehstapels ab. Sieh//deinen Ablagestapel durch;//du darfst eine Aktions- oder//Geldkarte daraus spielen.";
GermanCardTexts[CardNames.GALLERIA] = "|+[3]|//////Wenn du in diesem//Zug eine Karte nimmst,// die [3] oder [4] kostet://|+1 Kauf|.";
GermanCardTexts[CardNames.HUNTER] = "|+1 Aktion|//Decke die obersten 3 Karten//deines Nachziehstapels auf.//Nimm davon eine Aktions-,//eine Geld- und eine Punkte-//karte auf deine Hand.//Lege den Rest ab.";

GermanCardTexts[CardNames.SYCOPHANT] = "|+1 Aktion|//Lege 3 Karten aus deiner//Hand ab. Hast du mindestens//1 Karte abgelegt, |+|[3].//---//Wenn du diese Karte nimmst//oder entsorgst: |+2 Gefallen|.";
GermanCardTexts[CardNames.UNDERLING] = "|+1 Karte|//|+1 Aktion|//|+1 Gefallen|";
GermanCardTexts[CardNames.IMPORTER] = "Zu Beginn deines nächsten//Zuges: Nimm eine Karte,//die bis zu [5] kostet.//---//Spielvorbereitung: Jeder// Spieler erhält |+4 Gefallen|.";
GermanCardTexts[CardNames.CONTRACT] = "[!2]//|+1 Gefallen|//Du darfst eine Aktionskarte//aus deiner Hand zur Seite//legen, um sie zu Beginn deines//nächsten Zuges zu spielen.";
GermanCardTexts[CardNames.EMISSARY] = "|+3 Karten|////Hast du dadurch gemischt//(mindestens 1 Karte): ////|+1 Aktion| und |+2 Gefallen|.";
GermanCardTexts[CardNames.BAUBLE] = "Wähle 2 verschiedene:////|+1 Kauf|;  |+|[1];//|+1 Gefallen|;//wenn du in diesem Zug//eine Karte nimmst,//darfst du sie auf deinen//Nachziehstapel legen.";
GermanCardTexts[CardNames.BROKER] = "Entsorge eine deiner//Handkarten und wähle eins:////|+1 Karte| pro [1] ihrer Kosten;//|+1 Aktion| pro [1] ihrer Kosten;//|+|[1] pro [1] ihrer Kosten;//|+1 Gefallen| pro [1] ihrer Kosten.";
GermanCardTexts[CardNames.GUILDMASTER] = "|+[3]|////Wenn du in diesem//Zug eine Karte nimmst://|+1 Gefallen|.";

GermanCardTexts[CardNames.ARCHITECTS_GUILD] = "Wenn du eine Karte nimmst, darfst du//2 Gefallen einlösen, um eine billigere//Nicht-Punktekarte zu nehmen.";
GermanCardTexts[CardNames.ORDER_OF_ASTROLOGERS] = "Wenn du mischst, darfst du pro Gefallen, den du //einlöst, eine der zu mischenden Karten auswählen,//um sie nach dem Mischen nach oben zu legen.";
GermanCardTexts[CardNames.LEAGUE_OF_BANKERS] = "Zu Beginn deiner Kaufphase://|+|[1] pro 4 Gefallen,//die du hast (abgerundet).";
GermanCardTexts[CardNames.MOUNTAIN_FOLK] = "Zu Beginn deines Zuges darfst du//5 Gefallen einlösen für |+3 Karten|.";
GermanCardTexts[CardNames.WOODWORKERS_GUILD] = "Zu Beginn deiner Kaufphase darfst du 1 Gefallen ein-//  lösen, um eine Aktionskarte aus deiner Hand zu ent-  //sorgen. Hast du das getan, nimm eine Aktionskarte.";
GermanCardTexts[CardNames.PEACEFUL_CULT] = "Zu Beginn deiner Kaufphase darfst du//beliebig viele Gefallen einlösen, um genauso//viele deiner Handkarten zu entsorgen.";
GermanCardTexts[CardNames.CRAFTERS_GUILD] = "Zu Beginn deines Zuges darfst du 2 Gefallen//einlösen, um eine Karte, die bis zu [4]//kostet, auf deinen Nachziehstapel nehmen.";
GermanCardTexts[CardNames.CAVE_DWELLERS] = " Zu Beginn deines Zuges darfst du 1 Gefallen einlösen, //um eine Karte aus deiner Hand abzulegen und danach//eine Karte zu ziehen. Wiederhole das beliebig oft.";
GermanCardTexts[CardNames.PLATEAU_SHEPHERDS] = "Beim Zählen der Punkte: Bilde Paare aus je//einem deiner Gefallen mit je einer deiner//Karten, die [2] kosten; pro Paar {2}.";
GermanCardTexts[CardNames.DESERT_GUIDES] = "Zu Beginn deines Zuges darfst du 1 Gefallen//einlösen, um deine Handkarten abzulegen und//5 Karten zu ziehen. Wiederhole das beliebig oft.";
GermanCardTexts[CardNames.FAMILY_OF_INVENTORS] = "Zu Beginn deiner Kaufphase darfst du 1 deiner//Gefallen-Marker auf einen Nicht-Punkte-//Vorratsstapel legen. Karten kosten [1] weniger//pro Gefallen-Marker auf ihrem Stapel.";
GermanCardTexts[CardNames.ISLAND_FOLK] = "Am Ende deines Zuges darfst du 5 Gefallen//einlösen, um einen zusätzlichen Zug nach diesem//Zug zu erhalten (aber keinen 3. Zug in Folge).";
GermanCardTexts[CardNames.ORDER_OF_MASONS] = "Wenn du mischst, darfst du pro Gefallen, den du ein-//löst, bis zu 2 der zu mischenden Karten wählen, um sie //nach dem Mischen auf deinen Ablagestapel zu legen.";
GermanCardTexts[CardNames.BAND_OF_NOMADS] = "Wenn du eine Karte nimmst, die [3] oder//mehr kostet, darfst du 1 Gefallen einlösen für//|+1 Karte|; oder |+1 Aktion|; oder |+1 Kauf|.";
GermanCardTexts[CardNames.GANG_OF_PICKPOCKETS] = "Zu Beginn deines Zuges: Lege deine//Handkarten bis auf 4 ab, es sei denn,//du löst einen Gefallen ein.";
GermanCardTexts[CardNames.MARKET_TOWNS] = "Zu Beginn deiner Kaufphase darfst du einen//Gefallen einlösen, um eine Aktionskarte aus dei-//ner Hand zu spielen. Wiederhole das beliebig oft.";
GermanCardTexts[CardNames.FOREST_DWELLERS] = "Zu Beginn deines Zuges darfst du 1 Gefallen einlösen,  //um die obersten 3 Karten des Nachziehstapels//anzusehen, beliebig viele davon ab- und den Rest//in beliebiger Reihenfolge zurückzulegen.";
GermanCardTexts[CardNames.FELLOWSHIP_OF_SCRIBES] = "Hast du 4 oder weniger Handkarten,//nachdem du eine Aktionskarte gespielt hast,//darfst du 1 Gefallen einlösen für |+1 Karte|.";
GermanCardTexts[CardNames.LEAGUE_OF_SHOPKEEPERS] = "Nachdem du eine Kontaktkarte gespielt hast://Hast du 5 oder mehr Gefallen: |+|[1], und//hast du 10 oder mehr: |+1 Aktion| und |+1 Kauf|.";
GermanCardTexts[CardNames.COASTAL_HAVEN] = "Beim Ablegen deiner Handkarten in der Aufräum-// phase darfst du beliebig viele Gefallen einlösen, um  //genauso viele Karten für den nächsten Zug auf//deiner Hand zu behalten (du ziehst trotzdem 5).";
GermanCardTexts[CardNames.CITY_STATE] = "Wenn du während deines Zuges eine//Aktionskarte nimmst, darfst du 2//Gefallen einlösen, um sie zu spielen.";
GermanCardTexts[CardNames.TRAPPERS_LODGE] = "Wenn du eine Karte nimmst, darfst//du 1 Gefallen einlösen, um sie auf//deinen Nachziehstapel zu legen.";
GermanCardTexts[CardNames.CIRCLE_OF_WITCHES] = "Nachdem du eine Kontaktkarte gespielt//hast, darfst du 3 Gefallen einlösen. Tust//du das, nimmt jeder Mitspieler einen Fluch.";

GermanCardTexts[CardNames.AUGUR_PILE] = "Dieser Stapel besteht zu Beginn//aus je 4 Karten " + GermanCardNames[CardNames.HERB_GATHERER].singular + ",//" + GermanCardNames[CardNames.ACOLYTE].singular + ", " + GermanCardNames[CardNames.SORCERESS].singular + " und//" + GermanCardNames[CardNames.SIBYL].singular + " (von oben nach unten).//Nur die oberste Karte//darf genommen werden.";
GermanCardTexts[CardNames.HERB_GATHERER] = "|+1 Kauf|//Lege die Karten deines Nachzieh-//stapels auf deinen Ablagestapel//und sieh ihn durch; du darfst//eine Geldkarte daraus spielen.//Du darfst die//Augurinnen rotieren.";
GermanCardTexts[CardNames.ACOLYTE] = "Du darfst eine Aktions- oder//Punktekarte aus deiner Hand//entsorgen, um ein Gold zu//nehmen. Du darfst diese Karte//entsorgen, um eine Augurin-//karte zu nehmen.";
GermanCardTexts[CardNames.SORCERESS] = "|+1 Aktion|//Nenne eine Karte. Decke//die oberste Karte deines//Nachziehstapels auf und//nimm sie auf die Hand. Ist//es die genannte, nimmt jeder//Mitspieler einen Fluch.";
GermanCardTexts[CardNames.SIBYL] = "|+4 Karten|//|+1 Aktion|//Lege eine deiner//Handkarten auf deinen//Nachziehstapel und//eine weitere unter ihn.";
GermanCardTexts[CardNames.CLASH_PILE] = "Dieser Stapel besteht zu Beginn//aus je 4 Karten " + GermanCardNames[CardNames.BATTLE_PLAN].singular + ",//" + GermanCardNames[CardNames.ARCHER].singular + ", " + GermanCardNames[CardNames.WARLORD].singular + " und//" + GermanCardNames[CardNames.TERRITORY].singular + " (von oben nach unten).//Nur die oberste Karte//darf genommen werden.";
GermanCardTexts[CardNames.BATTLE_PLAN] = "|+1 Karte|//|+1 Aktion|//Du darfst eine Angriffskarte//aus deiner Hand aufdecken//für |+1 Karte|.////Du darfst einen beliebigen// Vorratsstapel rotieren.";
GermanCardTexts[CardNames.ARCHER] = "|+[2]|//Jeder Mitspieler mit 5//oder mehr Handkarten//deckt sie alle außer einer//auf und legt eine davon//ab, die du wählst.";
GermanCardTexts[CardNames.WARLORD] = "|+1 Aktion|//Zu Beginn deines nächsten//Zuges: |+2 Karten|.////Bis dahin dürfen Mitspieler//keine Aktionskarten aus ihrer//Hand spielen, von der sie//2 oder mehr gleiche Karten//im Spiel haben.";
GermanCardTexts[CardNames.TERRITORY] = "Wert {1} pro Punktekarte//mit unterschiedlichem Namen//im eigenen Kartensatz.//---//Wenn du diese Karte//nimmst, nimm ein Gold//pro leerem Vorrratsstapel.";
GermanCardTexts[CardNames.FORT_PILE] = "Dieser Stapel besteht zu//Beginn aus je 4 Karten " + GermanCardNames[CardNames.TENT].singular + ",//" + GermanCardNames[CardNames.GARRISON].singular + ", " + GermanCardNames[CardNames.HILL_FORT].singular + " und//" + GermanCardNames[CardNames.STRONGHOLD].singular + " (von oben nach unten).//Nur die oberste Karte//darf genommen werden.";
GermanCardTexts[CardNames.TENT] = "|+[2]|//Du darfst die Bastionen rotieren.////---////Wenn du diese Karte aus dem//Spiel ablegst, darfst du sie auf//deinen Nachziehstapel legen.";
GermanCardTexts[CardNames.GARRISON] = "|+[2]|//Wenn du in diesem Zug eine//Karte nimmst, lege einen//Marker auf diese Karte.////Zu Beginn deines nächsten//Zuges: Lege alle diese Marker//ab für |+1 Karte| pro Marker.";
GermanCardTexts[CardNames.HILL_FORT] = "Nimm eine Karte,//die bis zu [4] kostet.////Wähle eins://Nimm sie auf die Hand;// oder//|+1 Karte| und |+1 Aktion|.";
GermanCardTexts[CardNames.STRONGHOLD] = "Wähle eins: |+|[3];//oder//zu Beginn deines nächsten//Zuges: |+3 Karten|.//---//{!2}";
GermanCardTexts[CardNames.ODYSSEY_PILE] = "Dieser Stapel besteht zu Beginn//aus je 4 Karten " + GermanCardNames[CardNames.OLD_MAP].singular + ",//" + GermanCardNames[CardNames.VOYAGE].singular + ", " + GermanCardNames[CardNames.SUNKEN_TREASURE].singular + " und//" + GermanCardNames[CardNames.DISTANT_SHORE].singular + " (von oben nach unten).//Nur die oberste Karte//darf genommen werden.";
GermanCardTexts[CardNames.OLD_MAP] = "|+1 Karte|//|+1 Aktion|//Lege eine Karte aus//deiner Hand ab. |+1 Karte|.////Du darfst die//Irrfahrten rotieren.";
GermanCardTexts[CardNames.VOYAGE] = "|+1 Aktion|////Du erhälst einen zusätzlichen//Zug nach diesem Zug (aber//keinen 3. Zug in Folge), in//dem du nur 3 Karten aus//deiner Hand spielen darfst.";
GermanCardTexts[CardNames.SUNKEN_TREASURE] = "Nimm eine Aktions-//karte, von der du//keine gleiche im//Spiel hast.";
GermanCardTexts[CardNames.DISTANT_SHORE] = "|+2 Karten|//|+1 Aktion|//Nimm ein Anwesen.//---//{!2}";
GermanCardTexts[CardNames.TOWNSFOLK_PILE] = "Dieser Stapel besteht zu Beginn//aus je 4 Karten " + GermanCardNames[CardNames.TOWN_CRIER].singular + ",//" + GermanCardNames[CardNames.BLACKSMITH].singular + ", " + GermanCardNames[CardNames.MILLER].singular + " und//" + GermanCardNames[CardNames.ELDER].singular + " (von oben nach unten).//Nur die oberste Karte//darf genommen werden.";
GermanCardTexts[CardNames.TOWN_CRIER] = "Wähle eins:// |+|[2];//oder nimm ein Silber;//oder |+1 Karte| und// |+1 Aktion|.////Du darfst die Bürger//rotieren.";
GermanCardTexts[CardNames.BLACKSMITH] = "Wähle eins://Zieh, bis du 6//Handkarten hast;//oder |+2 Karten|;//oder |+1 Karte| und//|+1 Aktion|.";
GermanCardTexts[CardNames.MILLER] = "|+1 Aktion|//Sieh die obersten 4//Karten deines Nachzieh-//stapels an. Nimm eine//davon auf deine Hand//und lege den Rest ab.";
GermanCardTexts[CardNames.ELDER] = "|+[2]|//Du darfst eine Aktionskarte aus//deiner Hand spielen. Wenn sie//dich in diesem Zug aus verschie-//denen Anweisungen w\xE4hlen l\xE4sst//(mittels \"w\xE4hle\"), darfst du//eine zus\xE4tzliche, ungenutze//Option davon w\xE4hlen.";
GermanCardTexts[CardNames.WIZARD_PILE] = "Dieser Stapel besteht zu Beginn//aus je 4 Karten " + GermanCardNames[CardNames.STUDENT].singular + ",//" + GermanCardNames[CardNames.CONJURER].singular + ", " + GermanCardNames[CardNames.SORCERER].singular + " und//" + GermanCardNames[CardNames.LICH].singular + " (von oben nach unten).//Nur die oberste Karte//darf genommen werden.";
GermanCardTexts[CardNames.STUDENT] = "|+1 Aktion|//Du darfst die Zauberer//rotieren.////Entsorge eine deiner//Handkarten. Ist es eine Geld-//karte: |+1 Gefallen| und lege//diese Karte auf deinen//Nachziehstapel.";
GermanCardTexts[CardNames.CONJURER] = "Nimm eine Karte, die//bis zu [4] kostet.////Zu Beginn deines näch-//sten Zuges: Nimm diese//Karte auf deine Hand.";
GermanCardTexts[CardNames.SORCERER] = "|+1 Karte|//|+1 Aktion|//Jeder Mitspieler nennt eine//Karte und deckt dann die//oberste Karte seines//Nachziehstapels auf. Ist es//nicht die genannte,//nimmt er einen Fluch.";
GermanCardTexts[CardNames.LICH] = "|+6 Karten|//|+2 Aktionen|//Setze einen Zug aus.//---//Wenn du diese Karte entsorgst,//lege sie ab und nimm dir eine//billigere Karte aus dem Müll.";
GermanCardTexts[CardNames.HOARD] = "[!2]////Wenn du in diesem Zug//eine von dir gekaufte//Punktekarte nimmst,//nimm ein Gold.";
GermanCardTexts[CardNames.MINT] = "//Du darfst eine Geldkarte aus//deiner Hand aufdecken,//um eine gleiche zu nehmen.//---//Wenn du diese Karte nimmst,//entsorge alle Geldkarten,//die du im Spiel hast und die//keine Dauerkarten sind.";
GermanCardTexts[CardNames.QUARRY] = "[!1]////In diesem Zug kosten//Aktionskarten [2] weniger.";
GermanCardTexts[CardNames.ANVIL] = "[!1]////Du darfst eine Geldkarte//ablegen, um eine Karte//zu nehmen, die bis//zu [4] kostet.";
GermanCardTexts[CardNames.CLERK] = "|+[2]|//Jeder Mitspieler mit 5 oder mehr//Handkarten legt eine Handkarte//auf seinen Nachziehstapel.//---//Zu Beginn deines Zuges darfst//du diese Karte aus deiner//Hand spielen.";
GermanCardTexts[CardNames.INVESTMENT] = "Entsorge eine deiner//Handkarten.//Wähle eins: |+|[1] oder entsorge//diese Karte, um deine Hand-//karten aufzudecken für |+|{1}//pro Geldkarte unterschied-//lichen Namens darunter.";
GermanCardTexts[CardNames.TIARA] = "|+1 Kauf|////Wenn du in diesem Zug eine//Karte nimmst, darfst du sie auf//deinen Nachziehstapel legen.////Du darfst eine Geldkarte aus//deiner Hand zweimal spielen.";
GermanCardTexts[CardNames.CHARLATAN] = "|+[3]|//Jeder Mitspieler//nimmt einen Fluch.//---//In Spielen mit dieser Karte//ist ein Fluch auch eine//Geldkarte mit dem Wert [1].";
GermanCardTexts[CardNames.COLLECTION] = "[!2]//|+1 Kauf|////Wenn du in diesem Zug eine//Aktionskarte nimmst: |+|{1}.";
GermanCardTexts[CardNames.CRYSTAL_BALL] = "[!1]//Sieh dir die oberste Karte//deines Nachziehstapels an. Du//darfst jene entsorgen, ablegen//oder, falls es eine Aktions-//oder Geldkarte ist, spielen.";
GermanCardTexts[CardNames.MAGNATE] = "Decke deine//Handkarten auf.//|+1 Karte| pro//Geldkarte darunter.";
GermanCardTexts[CardNames.WAR_CHEST] = "Dein linker Mitspieler nennt//eine Karte. Nimm eine Karte,//die bis zu [5] kostet und die//in diesem Zug noch nicht//für eine Waffenkiste//genannt wurde.";

GermanCardTexts[CardNames.BERSERKER] = "Nimm eine Karte, die//weniger kostet als diese.//Jeder Mitspieler legt seine//Handkarten bis auf 3 ab.//---//Wenn du diese Karte nimmst://Falls du eine Aktionskarte im//Spiel hast, spiele diese Karte.";
GermanCardTexts[CardNames.GUARD_DOG] = "|+2 Karten|//Wenn du 5 oder weniger Hand-//karten hast: |+2 Karten|.//---//Wenn ein Mitspieler eine Angriffs-//karte spielt, darfst du zuerst diese//Karte aus deiner Hand spielen.";
GermanCardTexts[CardNames.NOMADS] = "|+1 Kauf|//|+[2]|//---//Wenn du diese Karte nimmst//oder entsorgst: |+|[2].";
GermanCardTexts[CardNames.TRAIL] = "|+1 Karte|//|+1 Aktion|//---//Wenn du diese Karte außerhalb//der Aufräumphase nimmst,//entsorgst oder ablegst,//darfst du sie spielen.";
GermanCardTexts[CardNames.WEAVER] = "Nimm 2 Silber oder eine//Karte, die bis zu [4] kostet.//---//Wenn du diese Karte außer-//halb der Aufräumphase ab-//legst, darfst du sie spielen.";
GermanCardTexts[CardNames.SOUK] = "|+1 Kauf|//|+[7]|////-[1] pro Handkarte (du kannst//nicht weniger als [0] haben).//---//Wenn du diese Karte nimmst,//entsorge bis zu 2 deiner Handkarten.";
GermanCardTexts[CardNames.CAULDRON] = "[!2]//|+1 Kauf|////Wenn du in diesem Zug//zum dritten Mal eine//Aktionskarte nimmst, nimmt//jeder Mitspieler einen Fluch.";
GermanCardTexts[CardNames.WHEELWRIGHT] = "|+1 Karte|//|+1 Aktion|////Du darfst eine Karte ablegen,//um eine Aktionskarte zu nehmen,//die gleich viel oder weniger//kostet als die abgelegte Karte.";
GermanCardTexts[CardNames.WITCHS_HUT] = "|+4 Karten|//////Lege 2 Karten aufgedeckt//ab. Wenn beides Aktions-//karten sind, nimmt jeder//Mitspieler einen Fluch.";

GermanCardTexts[CardNames.CAGE] = "Lege bis zu 4 deiner Hand-//karten verdeckt zur Seite//(auf diese Karte).//Das nächste Mal, wenn//du eine Punktekarte nimmst,//entsorge diese Karte und nimm//die zur Seite gelegten Karten am//Ende des Zuges auf deine Hand.";
GermanCardTexts[CardNames.GROTTO] = "|+1 Aktion|////Lege bis zu 4 deiner Handkarten//verdeckt zur Seite (auf//diese Karte).////Zu Beginn deines nächsten//Zuges lege sie ab, ziehe dann//gleich viele.";
GermanCardTexts[CardNames.JEWELLED_EGG] = "[!1]//|+1 Kauf|//---//Wenn du diese Karte//entsorgst, nimm eine//Kostbarkeit.";
GermanCardTexts[CardNames.SEARCH] = "|+[2]|////Das nächste Mal, wenn//ein Vorratsstapel leer wird,//entsorge diese Karte und//nimm eine Kostbarkeit.";
GermanCardTexts[CardNames.SHAMAN] = "|+1 Aktion|//|+[1]|////Du darfst eine deiner Handkarten//entsorgen.//---//In Spielen mit dieser Karte nimm zu//Beginn deines Zuges eine Karte aus//dem Müll, die bis zu [6] kostet.";
GermanCardTexts[CardNames.SECLUDED_SHRINE] = "|+[1]|////Das nächste Mal, wenn du//eine Geldkarte nimmst,//entsorge bis zu 2 deiner//Handkarten.";
GermanCardTexts[CardNames.SIREN] = " //Jeder Mitspieler nimmt einen Fluch.//Zieh zu Beginn deines nächsten//Zuges, bis du 8 Handkarten hast.//---//Wenn du diese Karte nimmst,//entsorge sie, es sei denn, du//entsorgst eine Aktionskarte//aus deiner Hand.";
GermanCardTexts[CardNames.STOWAWAY] = "Zu Beginn deines//nächsten Zuges://|+2 Karten|.//---//Wenn irgendjemand eine//Dauerkarte nimmt, darfst//du diese Karte aus deiner//Hand spielen.";
GermanCardTexts[CardNames.TASKMASTER] = " //|+1 Aktion|, |+|[1] und wenn//du in diesem Zug eine Karte//nimmst, die genau [5] kostet,//wiederhole diese Fähigkeit//zu Beginn deines nächsten//Zuges.";
GermanCardTexts[CardNames.ABUNDANCE] = "Das nächste//Mal, wenn du eine//Aktionskarte nimmst:////|+1 Kauf| und |+|[3].";
GermanCardTexts[CardNames.CABIN_BOY] = "|+1 Karte|//|+1 Aktion|////Zu Beginn deines nächsten//Zuges wähle eins: |+|[2] oder//entsorge diese Karte, um//eine Dauerkarte zu nehmen.";
GermanCardTexts[CardNames.CRUCIBLE] = "Entsorge eine deiner//Handkarten.|+|[1] pro [1],//das sie kostet.";
GermanCardTexts[CardNames.FLAGSHIP] = "|+[2]|////Das nächste Mal, wenn//du eine Aktionskarte spielst,//die keine Befehlskarte ist,//spiele sie erneut.";
GermanCardTexts[CardNames.FORTUNE_HUNTER] = "|+[2]|////Sieh dir die obersten 3 Karten//deines Nachziehstapels an.//Du darfst eine Geldkarte von//ihnen spielen. Lege den Rest in//beliebiger Reihenfolge zurück.";
GermanCardTexts[CardNames.GONDOLA] = "Entweder jetzt oder zu Beginn//deines nächsten Zuges: |+|[2].//---//Wenn du diese Karte nimmst,//darfst du eine Aktionskarte//aus deiner Hand spielen.";
GermanCardTexts[CardNames.HARBOR_VILLAGE] = "|+1 Karte|//|+2 Aktionen|////Nach der nächsten Aktions-//karte, die du in diesem Zug//spielst: Wenn sie dir |+|[]//gab: |+|[1].";
GermanCardTexts[CardNames.LANDING_PARTY] = "|+2 Karten|//|+2 Aktionen|////Das nächste Mal, wenn du als//erste Karte in einem Zug eine//Geldkarte spielst, lege diese//Karte danach auf deinen//Nachziehstapel.";
GermanCardTexts[CardNames.MAPMAKER] = "Sieh dir die obersten 4 Karten//deines Nachziehstapels an.//Nimm 2 auf deine Hand//und lege den Rest ab.//---//Wenn ein Spieler eine Punkte-//karte nimmt, darfst du diese//Karte aus deiner Hand spielen.";
GermanCardTexts[CardNames.MAROON] = "Entsorge eine deiner//Handkarten. |+2 Karten|//pro Kartentyp, den sie hat////(Aktion, Angriff, usw.).";
GermanCardTexts[CardNames.ROPE] = "[!1]//|+1 Kauf|////Zu Beginn deines nächsten//Zuges: |+1 Karte|, und du//darfst eine deiner Hand-//karten entsorgen.";
GermanCardTexts[CardNames.SWAMP_SHACKS] = "|+2 Aktionen|//////|+1 Karte| pro 3 Karten,//die du im Spiel hast//(abgerundet).";
GermanCardTexts[CardNames.TOOLS] = "Nimm eine Karte,//von der irgendjemand//eine gleiche Karte//im Spiel hat.";
GermanCardTexts[CardNames.BURIED_TREASURE] = "Zu Beginn deines nächsten//Zuges: |+1 Kauf| und |+|[3].//---//Wenn du diese Karte//nimmst, spiele sie.";
GermanCardTexts[CardNames.CREW] = "|+3 Karten|////Zu Beginn deines nächsten//Zuges lege diese Karte auf//deinen Nachziehstapel.";
GermanCardTexts[CardNames.CUTTHROAT] = "Jeder Mitspieler legt seine//Handkarten bis auf 3 ab.//////Das nächste Mal, wenn irgend-//jemand eine Geldkarte nimmt,//die [5] oder mehr kostet,//nimm eine Kostbarkeit.";
GermanCardTexts[CardNames.ENLARGE] = "Jetzt und zu Beginn//deines nächsten Zuges:////Entsorge eine deiner//Handkarten und nimm//eine Karte, die bis zu//[2] mehr kostet.";
GermanCardTexts[CardNames.FIGURINE] = "|+2 Karten|//////Du darfst eine//Aktionskarte ablegen//für |+1 Kauf| und |+|[1].";
GermanCardTexts[CardNames.FIRST_MATE] = "Spiele beliebig viele//Aktionskarten mit//gleichem Namen aus//deiner Hand, dann zieh,//bis du 6 Handkarten hast.";
GermanCardTexts[CardNames.FRIGATE] = "|+[3]|//Bis zum Beginn deines//nächsten Zuges://Jedes Mal, wenn ein Mit-//spieler eine Aktionskarte//spielt, legt er danach seine//Handkarten bis auf 4 ab.";
GermanCardTexts[CardNames.LONGSHIP] = "|+2 Aktionen|//////Zu Beginn deines//nächsten Zuges:////|+2 Karten|.";
GermanCardTexts[CardNames.MINING_ROAD] = "|+1 Aktion|//|+1 Kauf|//|+[2]|////Einmal in diesem Zug://Wenn du eine Geldkarte//nimmst, darfst du sie spielen.";
GermanCardTexts[CardNames.PENDANT] = "|+|[1] pro Geldkarte mit//unterschiedlichem Namen,//die du im Spiel hast.";
GermanCardTexts[CardNames.PICKAXE] = "[!1]////Entsorge eine deiner Hand-//karten. Wenn sie [3] oder//mehr kostet, nimm eine//Kostbarkeit auf die Hand.";
GermanCardTexts[CardNames.PILGRIM] = "|+4 Karten|//////Lege eine deiner//Handkarten auf deinen//Nachziehstapel.";
GermanCardTexts[CardNames.QUARTERMASTER] = "Zu Beginn jedes deiner Züge//bis zum Spielende wähle eins:////Nimm eine Karte, die bis zu//[4] kostet, direkt zur Seite//(auf diese Karte) oder nimm//eine auf diese Karte gelegte//Karte auf deine Hand.";
GermanCardTexts[CardNames.SILVER_MINE] = "Nimm eine Geldkarte//auf deine Hand, die weniger//kostet als diese Karte.";
GermanCardTexts[CardNames.TRICKSTER] = "Jeder Mitspieler nimmt//einen Fluch. Einmal in diesem//Zug darfst du, wenn du eine//Geldkarte aus dem Spiel ab-//legst, jene zur Seite legen.//Nimm sie am Ende des//Zuges auf deine Hand.";
GermanCardTexts[CardNames.WEALTHY_VILLAGE] = "|+1 Karte|//|+2 Aktionen|//---//Wenn du diese Karte nimmst://Hast du mindestens 3 Geldkarten//mit unterschiedlichen Namen//im Spiel, nimm eine Kostbarkeit.";
GermanCardTexts[CardNames.SACK_OF_LOOT] = "[!1]//|+1 Kauf|////Nimm eine Kostbarkeit.";
GermanCardTexts[CardNames.KINGS_CACHE] = "Du darfst eine//Geldkarte aus deiner//Hand dreimal spielen.";

GermanCardTexts[CardNames.BURY] = "|+1 Kauf|////Lege eine beliebige Karte deines//Ablagestapels unter deinen Nachziehstapel.";
GermanCardTexts[CardNames.AVOID] = "|+1 Kauf|////Wenn du in diesem Zug zum nächsten Mal mischst, wähle bis//zu 3 jener Karten und lege sie auf deinen Ablagestapel.";
GermanCardTexts[CardNames.DELIVER] = "|+1 Kauf|////Jedes Mal, wenn du in diesem Zug eine Karte nimmst, lege sie//zur Seite und nimm sie am Ende des Zuges auf deine Hand.";
GermanCardTexts[CardNames.PERIL] = "Du darfst eine Aktionskarte aus deiner Hand entsorgen,//um eine Kostbarkeit zu nehmen.";
GermanCardTexts[CardNames.RUSH] = "|+1 Kauf|////Das nächste Mal, wenn du in diesem//Zug eine Aktionskarte nimmst, spiele sie.";
GermanCardTexts[CardNames.FORAY] = "Lege 3 Karten aufgedeckt ab. Wenn sie 3//unterschiedliche Namen haben, nimm eine Kostbarkeit.";
GermanCardTexts[CardNames.LAUNCH] = "Einmal pro Zug: Kehre in deine Aktionsphase zurück.//|+1 Karte|, |+1 Aktion| und |+1 Kauf|.";
GermanCardTexts[CardNames.MIRROR] = "|+1 Kauf|////Das nächste Mal, wenn du in diesem Zug eine//Aktionskarte nimmst, nimm eine gleiche Karte.";
GermanCardTexts[CardNames.PREPARE] = "Lege deine Handkarten mit der Bildseite nach oben//zur Seite. Zu Beginn deines nächsten Zuges spiele//die Aktions- und Geldkarten davon in beliebiger//Reihenfolge, lege dann den Rest ab.";
GermanCardTexts[CardNames.SCROUNGE] = "Wähle eins: Entsorge eine deiner Handkarten oder//nimm ein Anwesen aus dem Müll und wenn du das//getan hast, nimm eine Karte, die bis zu[5] kostet.";
GermanCardTexts[CardNames.JOURNEY] = "Du legst in diesem Zug in der Aufräumphase keine//Karten aus dem Spiel ab. Du erhälst einen zusätzlichen//Zug nach diesem Zug (aber keinen 3. Zug in Folge).";
GermanCardTexts[CardNames.MAELSTROM] = "Entsorge 3 deiner Handkarten. Jeder Mitspieler//mit 5 oder mehr Handkarten entsorgt eine von ihnen.";
GermanCardTexts[CardNames.LOOTING] = "Nimm eine Kostbarkeit.";
GermanCardTexts[CardNames.INVASION] = "Du darfst eine Angriffskarte aus deiner Hand spielen.//Nimm ein Herzogtum. Nimm eine Aktionskarte auf//deinen Nachziehstapel. Nimm eine Kostbarkeit; spiele sie.";
GermanCardTexts[CardNames.PROSPER] = "Nimm eine Kostbarkeit und zusätzlich beliebig//viele Geldkarten mit unterschiedlichen Namen.";

GermanCardTexts[CardNames.AMPHORA] = "Entweder jetzt oder zu//Beginn deines nächsten//Zuges:////|+1 Kauf| und |+|[3].";
GermanCardTexts[CardNames.DOUBLOONS] = "[!3]////---//Wenn du diese Karte//nimmst, nimm ein Gold.";
GermanCardTexts[CardNames.ENDLESS_CHALICE] = "Jetzt und zu Beginn//aller deiner Züge bis//zum Spielende:////[!1]//|+1 Kauf|";
GermanCardTexts[CardNames.FIGUREHEAD] = "[!3]////Zu Beginn deines//nächsten Zuges:////|+2 Karten|.";
GermanCardTexts[CardNames.HAMMER] = "[!3]////Nimm eine Karte,//die bis zu [4] kostet.";
GermanCardTexts[CardNames.INSIGNIA] = "[!3]////Wenn du in diesem Zug//eine Karte nimmst, darfst//du sie auf deinen Nachzieh-//stapel legen.";
GermanCardTexts[CardNames.JEWELS] = "[!3]//|+1 Kauf|////Zu Beginn deines nächsten//Zuges lege diese Karte unter//deinen Nachziehstapel.";
GermanCardTexts[CardNames.ORB] = "Sieh deinen Ablagestapel//durch. Wähle eins://Spiele daraus eine//Aktions- oder eine Geldkarte//oder//|+1 Kauf| und |+|[3].";
GermanCardTexts[CardNames.PRIZE_GOAT] = "[!3]//|+1 Kauf|////Du darfst eine deiner//Handkarten entsorgen.";
GermanCardTexts[CardNames.PUZZLE_BOX] = "[!3]//|+1 Kauf|//Du darfst eine deiner Hand-//karten verdeckt zur Seite//legen. Nimm sie am Ende//des Zuges auf deine Hand.";
GermanCardTexts[CardNames.SEXTANT] = "[!3]//|+1 Kauf|////Sieh die obersten 5 Karten deines//Nachziehstapels an. Lege//beliebig viele//davon ab und den Rest in//beliebiger Reihenfolge zurück.";
GermanCardTexts[CardNames.SHIELD] = "[!3]//|+1 Kauf|//---//Spielt ein Mitspieler eine An-//griffskarte, darfst du zuerst diese//Karte aus deiner Hand aufde-//cken, um nicht betroffen zu sein.";
GermanCardTexts[CardNames.SPELL_SCROLL] = "//Entsorge diese Karte, um eine//billigere Karte zu nehmen.////Wenn jene eine Aktions-//oder Geldkarte ist, darfst//du sie spielen.";
GermanCardTexts[CardNames.STAFF] = "[!3]//|+1 Kauf|////Du darfst eine//Aktionskarte aus//deiner Hand spielen.";
GermanCardTexts[CardNames.SWORD] = "[!3]//|+1 Kauf|////Jeder Mitspieler legt seine//Handkarten bis auf 4 ab.";

GermanCardTexts[CardNames.CHEAP] = "Billige Karten kosten [1] weniger.";
GermanCardTexts[CardNames.CURSED] = "//Wenn du eine Verfluchte Karte nimmst,//nimm eine Kostbarkeit und einen Fluch.";
GermanCardTexts[CardNames.FAWNING] = "Wenn du eine Provinz nimmst,//nimm eine Aufdringliche Karte.";
GermanCardTexts[CardNames.FATED] = "Beim Mischen darfst du die Karten durchsehen//und Vorherbestimmte Karten aufdecken,//um sie nach oben oder unten zu legen.";
GermanCardTexts[CardNames.FRIENDLY] = "Zu Beginn deiner Aufräumphase//darfst du 1 Freundliche Karte ablegen,//um 1 Freundliche Karte zu nehmen.";
GermanCardTexts[CardNames.HASTY] = "Wenn du eine Eilige Karte nimmst,//lege sie zur Seite und spiele sie zu//Beginn deines nächsten Zuges.";
GermanCardTexts[CardNames.INHERITED] = "Spielvorbereitung: Du beginnst das Spiel mit//einer Geerbten Karte anstelle einer Startkarte deiner Wahl.";
GermanCardTexts[CardNames.INSPIRING] = "//Nachdem du in deinem Zug eine Inspirierende Karte//gespielt hast, darfst du eine Aktionskarte aus deiner//Hand spielen, von der du keine gleiche im Spiel hast.";
GermanCardTexts[CardNames.NEARBY] = "//Wenn du eine Benachbarte Karte nimmst://|+1 Kauf|.";
GermanCardTexts[CardNames.PATIENT] = "Zu Beginn deiner Aufräumphase darfst du//Geduldige Karten aus deiner Hand zur Seite legen,//um sie zu Beginn deines nächsten Zuges zu spielen.";
GermanCardTexts[CardNames.PIOUS] = "Wenn du eine Fromme Karte nimmst,//darfst du eine deiner Handkarten entsorgen.";
GermanCardTexts[CardNames.RECKLESS] = "//Befolge die Anweisungen der gespielten Waghalsigen//Karten zweimal. Wenn du eine Waghalsige Karte aus//dem Spiel ablegst, lege sie auf ihren Stapel zurück.";
GermanCardTexts[CardNames.RICH] = "Wenn du eine Reiche Karte//nimmst, nimm ein Silber.";
GermanCardTexts[CardNames.SHY] = "Zu Beginn deines Zuges darfst du//1 Scheue Karte für |+2 Karten| ablegen.";
GermanCardTexts[CardNames.TIRELESS] = "Wenn du eine Unermüdliche Karte aus dem//Spiel ablegst, lege sie zur Seite, und lege sie//am Ende des Zuges auf deinen Nachziehstapel.";
GermanCardTexts[CardNames.MARCHLAND] = "Wert {1} pro 3 Punkte-//karten in deinem//Kartensatz (abgerundet).//---//Wenn du diese Karte nimmst:// |+1 Kauf| und lege beliebig// viele deiner Handkarten für//+[1] ab.";

GermanCardTexts[CardNames.SHOP] = "|+1 Karte|//|+[1]|//Du darfst eine Aktionskarte//aus deiner Hand spielen,//von der du kein Exemplar//im Spiel hast.";
GermanCardTexts[CardNames.CARNIVAL] = "Decke die obersten//4 Karten deines//Nachziehstapels auf.//Nimm eine jeder Karte//mit unterschiedlichem//Namen auf die Hand//und lege den Rest ab.";
GermanCardTexts[CardNames.FERRYMAN] = "|+2 Karten|//|+1 Aktion|//Lege eine Handkarte ab.//---//Spielvorbereitung: Wähle einen//ungenutzten Königreichstapel,//der [3] oder [4] kostet. Nimm//eine Karte davon, wenn du//einen Fährmann nimmst.";
GermanCardTexts[CardNames.FARMHANDS] = "|+1 Karte|//|+2 Aktionen|//---//Wenn du diese Karte nimmst,//darfst du eine Aktions- oder//Geldkarte aus deiner Hand zur//Seite legen und sie zu Beginn//deines nächsten Zuges spielen.";
GermanCardTexts[CardNames.JOUST] = "|+1 Karte|//|+1 Aktion|//|+[1]|//Du darfst eine Provinz aus deiner//Hand zur Seite legen, um eine//beliebige Belohnung auf deine//Hand zu nehmen. Lege die//Provinz in der Aufräumphase ab.";
GermanCardTexts[CardNames.REWARDS_PILE] = "--";
GermanCardTexts[CardNames.COURSER] = "Wähle zwei verschiedene:////|+2 Karten|;//|+2 Aktionen|;//+[2];//nimm 4 Silber.////%(Diese Karte gehört%// %nicht zum Vorrat.)%";
GermanCardTexts[CardNames.RENOWN] = "|+1 Kauf|////In diesem Zug kosten//alle Karten [2] weniger.////%(Diese Karte gehört%//%nicht zum Vorrat.)%`";
GermanCardTexts[CardNames.CORONET] = "Du darfst eine Aktionskarte,//die keine Belohnung ist, aus//deiner Hand zweimal spielen.////Du darfst eine Geldkarte,//die keine Belohnung ist, aus//deiner Hand zweimal spielen.////%(Diese Karte gehört%//%nicht zum Vorrat.)%";
GermanCardTexts[CardNames.DEMESNE] = "|+2 Aktionen|//|+2 Käufe|//Nimm ein Gold.//---//Wert {1} pro Gold in//deinem Kartensatz.////%(Diese Karte gehört%//%nicht zum Vorrat.)%";
GermanCardTexts[CardNames.HOUSECARL] = "|+1 Karte| pro//Aktionskarte mit//unterschiedlichem//Namen, die du im Spiel hast.////%(Diese Karte gehört%//%nicht zum Vorrat.)%";
GermanCardTexts[CardNames.HUGE_TURNIP] = "|+2 Taler|////|+|[1] pro Taler, die du hast.////%(Diese Karte gehört%//%nicht zum Vorrat.)%";
GermanCardTexts[CardNames.FARRIER] = "|+1 Karte|//|+1 Aktion|//|+1 Kauf|//---//Überzahlen: |+1 Karte|//am Ende dieses Zuges//pro überzahltem [1].";
GermanCardTexts[CardNames.INFIRMARY] = "|+1 Karte|//Du darfst eine Karte//aus deiner Hand entsorgen.//---//Überzahlen: Spiele//diese Karte einmal// pro überzahltem [1].";
GermanCardTexts[CardNames.FOOTPAD] = "|+2 Taler|//Jeder Mitspieler legt seine//Handkarten bis auf 3 ab.//---//In Spielen mit dieser//Karte, wenn du eine Karte//in einer Aktionsphase//nimmst, |+1 Karte|.";

GermanCardTexts[CardNames.FISHMONGER] = "|+1 Kauf|//|+[1]|//---//Du kannst diese Karte aus//deinem Nachziehstapel spielen,//als wäre sie in deiner Hand.";
GermanCardTexts[CardNames.SNAKE_WITCH] = "|+1 Karte|//|+1 Aktion|////Sind alle deine Handkarten ver-//schieden, darfst du sie aufdecken//und diese Karte auf ihren Stapel//zurücklegen, damit jeder// Mitspieler einen Fluch nimmt.";
GermanCardTexts[CardNames.ARISTOCRAT] = "//Ist die Anzahl an Aristokratin-//nen, die du im Spiel hast:////1 oder 5: |+3 Aktionen|;//2 oder 6: |+3 Karten|;//3 oder 7: |+|[3];//4 oder 8: |+3 Käufe|.";
GermanCardTexts[CardNames.CRAFTSMAN] = "|+[2D]|////Nimm eine Karte,//die bis zu [5] kostet.";
GermanCardTexts[CardNames.RIVERBOAT] = "//Zu Beginn deines nächsten Zuges://Spiele die zur Seite gelegte Karte;//lass sie dabei dort liegen.//---//Spielvorbereitung: Legt eine//ungenutzte Aktionskarte zur//Seite, die [5] kostet und//keine Dauerkarte ist.";
GermanCardTexts[CardNames.ROOT_CELLAR] = "|+3 Karten|//|+1 Aktion|//|+[3D]|";
GermanCardTexts[CardNames.ALLEY] = "|+1 Karte|//|+1 Aktion|//Lege 1 Karte ab.//---//Du kannst diese Karte aus//deinem Nachziehstapel spielen,//als wäre sie in deiner Hand.";
GermanCardTexts[CardNames.CHANGE] = "Wenn du [D] hast: |+|[3].////Ansonsten entsorge eine//beliebige deiner Handkarten//und nimm eine Karte, die//mehr [] kostet als jene.|+|[D]//in Höhe der Differenz in [].";
GermanCardTexts[CardNames.NINJA] = "|+1 Karte|//Jeder Mitspieler legt seine//Handkarten bis auf 3 ab. //---//Du kannst diese Karte aus//deinem Nachziehstapel spielen,//als wäre sie in deiner Hand.";
GermanCardTexts[CardNames.POET] = "|+1 <>|//|+1 Karte|//|+1 Aktion|//Decke die oberste Karte//deines Nachziehstapels auf.//Wenn sie [3] oder weniger kostet,//nimm sie auf deine Hand.";
GermanCardTexts[CardNames.RIVER_SHRINE] = "|+1 <>|////Entsorge bis zu 2 deiner Hand-//karten. Zu Beginn der Aufräum-//phase: Wenn du in diesem Zug//in deiner Kaufphase keine Karte//genommen hast, nimm eine//Karte, die bis zu [4] kostet.";
GermanCardTexts[CardNames.RUSTIC_VILLAGE] = "|+1 <>|//|+1 Karte|//|+2 Aktionen|////Du darfst 2 Karten für//|+1 Karte| ablegen.";
GermanCardTexts[CardNames.GOLD_MINE] = "|+1 Karte|//|+1 Aktion|//|+1 Kauf|////Du darfst ein Gold nehmen//und |+|[4D]erhalten.";
GermanCardTexts[CardNames.IMPERIAL_ENVOY] = "|+5 Karten|//|+1 Kauf|//|+[2D]|";
GermanCardTexts[CardNames.KITSUNE] = "|+1 <>|////Wähle zwei verschiedene://|+2 Aktionen|;//|+|[2];//jeder Mitspieler nimmt//einen Fluch;//nimm ein Silber.";
GermanCardTexts[CardNames.LITTER] = "|+2 Karten|//|+2 Aktionen|//|+[1D]|";
GermanCardTexts[CardNames.RICE_BROKER] = "|+1 Aktion|//Entsorge eine//deiner Handkarten.////Ist es eine Geldkarte://|+2 Karten|.//Ist es eine Aktionskarte://|+5 Karten|.";
GermanCardTexts[CardNames.RONIN] = "Zieh, bis du//7 Handkarten hast.//---//Du kannst diese Karte aus//deinem Nachziehstapel spielen,//als wäre sie in deiner Hand.";
GermanCardTexts[CardNames.TANUKI] = "Entsorge eine deiner//Handkarten. Nimm eine//Karte, die bis zu [2] mehr//kostet als jene.//---//Du kannst diese Karte aus//deinem Nachziehstapel spielen,//als wäre sie in deiner Hand.";
GermanCardTexts[CardNames.TEA_HOUSE] = "|+1 <>|//|+1 Karte|//|+1 Aktion|//|+[2]|";
GermanCardTexts[CardNames.SAMURAI] = "Jeder Mitspieler legt seine//Handkarten bis auf 3 ab//(einmalig).////Zu Beginn jedes deiner Züge//bis zum Spielende: +[1].////%(Diese Karte bleibt im Spiel.)%";
GermanCardTexts[CardNames.RICE] = "|+1 Kauf|////|+|[1] pro//unterschiedlichem Kartentyp//der Karten, die du//im Spiel hast.";
GermanCardTexts[CardNames.MOUNTAIN_SHRINE] = "|+1 <>|//+[2]//Du darfst eine deiner//Handkarten entsorgen.//Wenn dann mindestens eine//Aktionskarte im Müll ist://|+2 Karten|.";
GermanCardTexts[CardNames.DAIMYO] = "|+1 Karte|//|+1 Aktion|//Das nächste Mal, wenn du//in diesem Zug eine//Aktionskarte spielst, die//keine Befehlskarte ist,//spiele sie danach erneut.";
GermanCardTexts[CardNames.ARTIST] = "|+1 Aktion|////|+1 Karte| pro Karte,//von der du genau ein//Exemplar im Spiel hast.";
GermanCardTexts[CardNames.APPROACHING_ARMY] = "Nachdem du eine Angriffskarte gespielt hast: |+|[1].//---//Spielvorbereitung: Legt einen zusätzlichen//Angriffs-Königreichstapel in den Vorrat.";
GermanCardTexts[CardNames.BIDING_TIME] = "Zu Beginn deiner Aufräumphase: Lege deine//Handkarten verdeckt zur Seite. Zu Beginn deines//nächsten Zuges: Nimm jene Karten auf deine Hand.";
GermanCardTexts[CardNames.BUREAUCRACY] = "Wenn du eine Karte nimmst, die//nicht [0] kostet, nimm ein Kupfer.";
GermanCardTexts[CardNames.DIVINE_WIND] = "Wenn du die letzte| |<>| |entfernst, entfernt alle//Königreichstapel aus dem Vorrat und legt//10 neue, zufällige Königreichstapel aus.";
GermanCardTexts[CardNames.ENLIGHTENMENT] = "Geldkarten sind auch Aktionskarten. Spielst du eine Geld-//karte in einer Aktionsphase, führst du ihre Anweisungen//nicht aus; stattdessen |+1 Karte| und |+1 Aktion|.";
GermanCardTexts[CardNames.FLOURISHING_TRADE] = "Karten kosten [1] weniger.//Du darfst Aktionen (nicht Aktionskarten)//als Käufe verwenden.";
GermanCardTexts[CardNames.GOOD_HARVEST] = "Wenn du zum ersten Mal in einem//Zug ein Exemplar einer Geldkarte//spielst, zuerst |+1 Kauf| and |+|[1].";
GermanCardTexts[CardNames.GREAT_LEADER] = "Nach jeder Aktionskarte,//die du spielst: |+1 Aktion|.";
GermanCardTexts[CardNames.GROWTH] = "Wenn du eine Geldkarte nimmst,//nimm eine billigere Karte.";
GermanCardTexts[CardNames.HARSH_WINTER] = "Wenn du in deinem Zug eine Karte nimmst,//auf deren Stapel [D] liegen: Nimm die [D].//Falls nicht, lege [2D] auf ihren Stapel.";
GermanCardTexts[CardNames.KIND_EMPEROR] = "Zu Beginn deines Zuges und wenn//du die letzte| |<>| |entfernst: Nimm//eine Aktionskarte auf deine Hand.";
GermanCardTexts[CardNames.PANIC] = "Wenn du eine Geldkarte spielst: |+2 Käufe|.//Lege Geldkarten, die du aus dem//Spiel ablegst, auf ihren Stapel zurück.";
GermanCardTexts[CardNames.PROGRESS] = "Wenn du eine Karte nimmst,//lege sie auf deinen Nachziehstapel.";
GermanCardTexts[CardNames.RAPID_EXPANSION] = "Wenn du eine Aktions- oder Geldkarte//nimmst, leg sie zur Seite und spiele//sie zu Beginn deines nächsten Zuges.";
GermanCardTexts[CardNames.SICKNESS] = "Zu Beginn deines Zuges, wähle eins://Nimm einen Fluch auf deinen Nachziehstapel//oder lege 3 Karten ab.";
GermanCardTexts[CardNames.AMASS] = "Wenn du keine Aktionskarten im Spiel hast,//nimm eine Aktionskarte, die bis zu [5] kostet.";
GermanCardTexts[CardNames.ASCETICISM] = "Zahle beliebig viele [], um die gleiche Anzahl//deiner Handkarten zu entsorgen.";
GermanCardTexts[CardNames.CREDIT] = "Nimm eine Aktions- oder Geldkarte, die bis zu [8] kostet.//+[D] in der Höhe ihrer Kosten.";
GermanCardTexts[CardNames.FORESIGHT] = "Decke so lange Karten von deinem Nachziehstapel auf, bis du//eine Aktionskarte aufdeckst. Lege die Aktionskarte zur Seite und//lege den Rest ab. Nimm sie am Ende des Zuges auf die Hand.";
GermanCardTexts[CardNames.KINTSUGI] = "Entsorge eine deiner Handkarten. Wenn du in diesem//Spiel ein Gold genommen hast, nimm eine Karte, die//bis zu [2] mehr kostet als die entsorgte Karte.";
GermanCardTexts[CardNames.PRACTICE] = "Du darfst eine Aktionskarte//aus deiner Hand zweimal spielen.";
GermanCardTexts[CardNames.SEA_TRADE] = "|+1 Karte| pro Aktionskarte, die du im Spiel hast.//Entsorge maximal so viele deiner Handkarten.";
GermanCardTexts[CardNames.RECEIVE_TRIBUTE] = "Wenn du in diesem Zug mindestens 3 Karten genommen//hast, nimm bis zu drei Aktionskarten mit unterschiedlichem//Namen, von denen du keine gleiche Karte im Spiel hast.";
GermanCardTexts[CardNames.GATHER] = "Nimm eine Karte, die genau [3] kostet,//eine Karte, die genau [4] kostet,//und eine Karte, die genau [5] kostet.";
GermanCardTexts[CardNames.CONTINUE] = "Einmal pro Zug: Nimm eine Aktionskarte, die keine//Angriffskarte ist und bis zu [4] kostet. Kehre in deine//Aktionsphase zurück und spiele sie.|+1 Aktion| und |+1 Kauf|.";