"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ChoiceQuestion = function (_Question) {
    _inherits(ChoiceQuestion, _Question);

    function ChoiceQuestion(game, index, description, min, max) {
        var content = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : [];
        var declineButtonId = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : -1;
        var preselected = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : [];
        var affectedCards = arguments.length > 8 && arguments[8] !== undefined ? arguments[8] : [];

        _classCallCheck(this, ChoiceQuestion);

        var _this = _possibleConstructorReturn(this, (ChoiceQuestion.__proto__ || Object.getPrototypeOf(ChoiceQuestion)).call(this, game, index, description));

        _this.min = min;
        _this.max = max;
        _this.content = content;
        _this.content.question = _this;
        _this.declineButtonId = declineButtonId;
        _this.doneButton = _this.min === 1 && _this.max === 1 ? _this.type.singularDoneButton : _this.type.doneButton;
        _this.accumulatedAnswers = [].concat(_toConsumableArray(preselected));
        _this.affectedCards = affectedCards;
        _this.associatedCards = [];
        _this.buttons = [];
        return _this;
    }

    _createClass(ChoiceQuestion, [{
        key: "computeAffectedCardIds",
        value: function computeAffectedCardIds() {
            switch (this.content.type) {
                case QuestionElementTypes.BUYABLE_CARDS:
                    var cardMap = function cardMap(entry) {
                        return shouldUseAutobuy || entry.isSimple ? entry.id : null;
                    };
                    return this.content.elements.map(cardMap).filter(function (t) {
                        return t !== null;
                    });
                    break;
                case QuestionElementTypes.CARDS:
                    return this.content.elements;
                    break;
                case QuestionElementTypes.ABILITIES:
                    return this.content.elements.map(function (entry) {
                        return entry.association;
                    });
                    break;
                default:
                    return [];
            }
        }
    }, {
        key: "canAccept",
        value: function canAccept(answer) {
            var _this2 = this;

            if (this.type.isOrder) return false;

            if (this.accumulatedAnswers.includes(answer)) return true;

            if (this.accumulatedAnswers.length < this.max) {
                if (this.type === QuestionTypes.TRASH_UNIQUE) {
                    var priorCardNames = this.accumulatedAnswers.map(function (i) {
                        return _this2.content.elements[i];
                    }).map(function (id) {
                        return _this2.game.state.cards[id].cardName;
                    });
                    var newCardName = this.game.state.cards[this.content.elements[answer]].cardName;
                    return !priorCardNames.includes(newCardName);
                } else if (this.type === QuestionTypes.EXILE_WITH_SAME_NAME) {
                    if (this.accumulatedAnswers.length === 0) return true;
                    var priorCardName = this.game.state.cards[this.content.elements[this.accumulatedAnswers[0]]].cardName;
                    var _newCardName = this.game.state.cards[this.content.elements[answer]].cardName;
                    return _newCardName === priorCardName;
                } else {
                    return true;
                }
            }
            return this.accumulatedAnswers.length < this.max || this.accumulatedAnswers.includes(answer);
        }
    }, {
        key: "answer",
        value: function answer(_answer) {
            var _this3 = this;

            if (this.canAccept(_answer)) {
                if (this.accumulatedAnswers.includes(_answer)) this.accumulatedAnswers.splice(this.accumulatedAnswers.indexOf(_answer), 1);else this.accumulatedAnswers.push(_answer);

                if (this.type === QuestionTypes.WAY) {
                    if (this.accumulatedAnswers.length > 0) {
                        var wayCard = this.game.state.cards[this.content.elements[this.accumulatedAnswers[0]]].cardName;
                        this.affectedCards.forEach(function (id) {
                            return _this3.game.state.cards[id].image = wayCard.changedImage;
                        });
                    } else {
                        this.affectedCards.forEach(function (id) {
                            return _this3.game.state.cards[id].revertImage();
                        });
                    }
                }

                var shouldImmediatelySend = this.type.immediatelySend || !this.type.neverSend && shouldUseImmediateSendExceptions;
                if (this.max === 1 && shouldImmediatelySend) this.send();else {
                    this.updateButtons();
                    this.associatedCards.forEach(function (c) {
                        return c.onAnswerChange(_this3);
                    });
                }
            }
        }
    }, {
        key: "accumulateAnswer",
        value: function accumulateAnswer(answer) {
            if (!this.accumulatedAnswers.includes(answer)) this.accumulatedAnswers.push(answer);
        }
    }, {
        key: "answerDecline",
        value: function answerDecline() {
            this.accumulatedAnswers = [];
            this.send();
        }
    }, {
        key: "answerDone",
        value: function answerDone() {
            this.send();
        }
    }, {
        key: "getAnswer",
        value: function getAnswer() {
            var _this4 = this;

            if (this.type === QuestionTypes.WAY) {
                this.affectedCards.forEach(function (id) {
                    return _this4.game.state.cards[id].revertImage();
                });
            }
            if (this.min <= this.accumulatedAnswers.length) return this.accumulatedAnswers;else return [];
        }
    }, {
        key: "clearAnswers",
        value: function clearAnswers() {
            var _this5 = this;

            _get(ChoiceQuestion.prototype.__proto__ || Object.getPrototypeOf(ChoiceQuestion.prototype), "clearAnswers", this).call(this);
            this.associatedCards.forEach(function (c) {
                return c.onAnswerChange(_this5);
            });
            this.updateButtons();
        }
    }, {
        key: "createButtons",
        value: function createButtons() {
            var addedButtons = [];
            var noDoneButton = this.type === QuestionTypes.CHOOSE_MODE && this.max === 1 || shouldUseImmediateSendExceptions && this.max === 1 && this.min === 1;
            if (!noDoneButton) {
                var doneButtonInfo = this.type.doneButton;
                if (doneButtonInfo !== null) {
                    this.doneButton = new DoneGameButtonView(this, LANGUAGE.getDeclineButtonText[doneButtonInfo], getButtonArtURL(doneButtonInfo));
                    addedButtons.push(this.doneButton);
                    this.doneButton.enabled = this.accumulatedAnswers.length >= this.min;
                    this.doneButton.isSideButton = this.type === QuestionTypes.RESOLVE_ABILITY;
                }
            }

            if (this.declineButtonId > -1) {
                var declineButtonInfo = getByOrdinal(DeclineButtonIds, this.declineButtonId);
                if (declineButtonInfo.isEndButton) this.declineButton = new EndButtonView(this, LANGUAGE.getDeclineButtonText[declineButtonInfo], getButtonArtURL(declineButtonInfo));else this.declineButton = new DeclineGameButtonView(this, LANGUAGE.getDeclineButtonText[declineButtonInfo], getButtonArtURL(declineButtonInfo));
                this.declineButton.isSideButton = this.type === QuestionTypes.RESOLVE_ABILITY;
                addedButtons.push(this.declineButton);
            }

            if (this.max > 0 && !this.type.isOrder) {
                this.undoButton = new UndoButtonView(this, 0, getPhrase(Phrases.UNDO), "images/buttons/refuse.png");
                this.undoButton.visibility = false;
                addedButtons.push(this.undoButton);
            }

            this.game.questionModel.newButtons(addedButtons);
            this.updateButtons();
        }
    }, {
        key: "getShortcutButtons",
        value: function getShortcutButtons() {
            if (this.story.questionId === QuestionIds.PAWN) {
                return [new ShortcutButtonView(this, [0, 1], CardModes.PAWN_CARD_ACTION)];
            } else if (this.story.questionId === QuestionIds.TRUSTY_STEED) {
                return [new ShortcutButtonView(this, [0, 1], CardModes.TRUSTY_STEED_CARDS_ACTIONS)];
            } else if (this.story.questionId === QuestionIds.COURSER) {
                return [new ShortcutButtonView(this, [0, 1], CardModes.COURSER_CARDS_ACTIONS)];
            }
            return [];
        }
    }, {
        key: "updateButtons",
        value: function updateButtons() {
            var showDecline = this.accumulatedAnswers.length === 0 || this.type.isOrder;
            if (this.declineButton) {
                this.declineButton.visibility = showDecline;
            } else {
                showDecline = false;
            }
            if (this.doneButton) {
                this.doneButton.visibility = !showDecline || this.type.isOrder;
                this.doneButton.enabled = this.accumulatedAnswers.length >= this.min;
                var label = "";
                if (this.min > 1) {
                    if (this.accumulatedAnswers.length < this.min) {
                        label += this.min - this.accumulatedAnswers.length;
                    }
                    if (this.max > this.min && this.accumulatedAnswers.length < this.max) {
                        label += "+";
                    }
                }
                this.doneButton.label = label;
            }
            if (this.undoButton) {
                this.undoButton.visibility = this.accumulatedAnswers.length > 0;
            }
            this.buttons.forEach(function (b) {
                return b.redraw();
            });
            this.game.questionModel.positionButtons();
        }
    }, {
        key: "execute",
        value: function execute(game) {
            var _this6 = this;

            var isRoot = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

            this.createButtons();
            var buttons = this.getShortcutButtons().concat(this.content.extractButtons(game));
            this.buttons = buttons;
            this.game.questionModel.newButtons(buttons);
            this.associatedCards = this.content.createAssociations(game.state);
            _get(ChoiceQuestion.prototype.__proto__ || Object.getPrototypeOf(ChoiceQuestion.prototype), "execute", this).call(this, game, isRoot);
            if (this.story.questionId.stackCards) {
                this.game.state.zones.filter(function (z) {
                    return z instanceof RevealZone;
                }).forEach(function (z) {
                    return z.collateCards();
                });
            }
            if (this.type.isOrder) {
                if (this.content.elements.some(function (id) {
                    return !_this6.game.state.cards[id].cardStacks.some(function (s) {
                        return s.location instanceof RevealZone;
                    });
                })) {
                    this.content.elements.forEach(function (id) {
                        var card = _this6.game.state.cards[id];
                        var targetStack = _this6.game.state.orphanage.addCard(card);
                        targetStack.addCard(card);
                        targetStack.removeFilter("intangible");
                    });
                    this.game.state.orphanage.enableOrdering(this);
                }

                this.game.state.zones.filter(function (z) {
                    return z instanceof RevealZone;
                }).forEach(function (z) {
                    if (_this6.content.elements.some(function (id) {
                        return _this6.game.state.cards[id].cardStacks.some(function (s) {
                            return s.location === z;
                        });
                    })) z.enableOrdering(_this6);
                });
            }
        }
    }, {
        key: "createAnimation",
        value: function createAnimation() {
            if (this.type.isOrder) {
                return new EndBlockedQuestionAnimation(this);
            } else {
                return _get(ChoiceQuestion.prototype.__proto__ || Object.getPrototypeOf(ChoiceQuestion.prototype), "createAnimation", this).call(this, this);
            }
        }
    }, {
        key: "shouldStackCards",
        get: function get() {
            return this.story.questionId.stackCards;
        }
    }], [{
        key: "parse",
        value: function parse(game, reader, questionIndex) {
            var questionDescription = QuestionDescription.parse(reader);
            var min = reader.readInt();
            var max = reader.readInt();
            var questionElements = new QuestionElementProcessor(reader).process();
            var doneButtonId = reader.readInt();
            var preselected = reader.readInts();
            var affectedCards = reader.readInts();
            return new ChoiceQuestion(game, questionIndex, questionDescription, min, max, questionElements, doneButtonId, preselected, affectedCards);
        }
    }]);

    return ChoiceQuestion;
}(Question);