"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PileUpdate = function (_GameStateChange) {
    _inherits(PileUpdate, _GameStateChange);

    function PileUpdate(game, index, topCardId) {
        _classCallCheck(this, PileUpdate);

        var _this = _possibleConstructorReturn(this, (PileUpdate.__proto__ || Object.getPrototypeOf(PileUpdate)).call(this, game));

        _this.index = index;
        _this.topCardId = topCardId;
        return _this;
    }

    _createClass(PileUpdate, [{
        key: "execute",
        value: function execute(game, terminator) {
            var zone = game.state.zones[this.index];
            if (this.topCardId > -1) {
                if (!(zone instanceof NonSupplyZone)) {
                    var targetCard = game.state.cards[this.topCardId];
                    var stack = zone.cardStacks[0];
                    if (!isUndefined(stack)) {
                        if (!stack.cards.includes(targetCard)) {
                            if (stack.anonymousCards > 0) {
                                stack.anonymousCards--;
                            } else {
                                stack.cards.forEach(function (c) {
                                    return stack.removeCard(c);
                                });
                            }
                            stack.addCard(targetCard);
                        }
                    }
                    stack.topCard = targetCard;
                }
            } else {
                if (zone instanceof SupplyZone) {
                    zone.cardStacks.forEach(function (s) {
                        s.cards.forEach(function (c) {
                            return s.removeCard(c);
                        });
                        s.anonymousCards = 0;
                    });
                } else {
                    zone.cardStacks.forEach(function (s) {
                        if (s.trackedAnimators.length === 0) s.destroy();else s.addFilter("intangible");
                    });
                }
            }
            terminator();
        }
    }, {
        key: "createAnimation",
        value: function createAnimation() {
            return new PileAffectingAnimation(this, this.index);
        }
    }], [{
        key: "parse",
        value: function parse(game, reader) {
            var index = reader.readInt();
            var topCardId = reader.readInt();
            return new PileUpdate(game, index, topCardId);
        }
    }]);

    return PileUpdate;
}(GameStateChange);