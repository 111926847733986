"use strict";

var FrenchLogEntries = {};

FrenchLogEntries[LogEntryNames.GAIN] = "Argument0 reçoit Argument1.";
FrenchLogEntries[LogEntryNames.GAIN_WITH] = "Argument0 reçoit Argument1. (Argument2)";
FrenchLogEntries[LogEntryNames.BUY_AND_GAIN] = "Argument0 achète et reçoit Argument1.";
FrenchLogEntries[LogEntryNames.TRASH] = "Argument0 écarte Argument1.";
FrenchLogEntries[LogEntryNames.DISCARD] = "Argument0 défausse Argument1.";
FrenchLogEntries[LogEntryNames.DISCARD_WITH] = "Argument0 défausse Argument1. (Argument2)";
FrenchLogEntries[LogEntryNames.PLAY] = "Argument0 joue Argument1.";
FrenchLogEntries[LogEntryNames.PLAY_AGAIN] = "Argument0 joue à nouveau Argument1.";
FrenchLogEntries[LogEntryNames.PLAY_THIRD] = "Argument0 joue une troisième fois Argument1.";
FrenchLogEntries[LogEntryNames.TOPDECK] = "Argument0 place Argument1 sur sa pioche.";
FrenchLogEntries[LogEntryNames.DRAW] = "Argument0 pioche Argument1.";
FrenchLogEntries[LogEntryNames.DRAW_FROM] = "Argument0 pioche Argument1. (Argument2)";
FrenchLogEntries[LogEntryNames.REVEAL] = "Argument0 dévoile Argument1.";
FrenchLogEntries[LogEntryNames.REVEAL_SEARCHING] = "Argument0 dévoile Argument1.";
FrenchLogEntries[LogEntryNames.REVEAL_FINDING] = "Argument0 dévoile Argument1.";
FrenchLogEntries[LogEntryNames.REVEAL_SEARCHING_AND_FINDING] = "Argument0 dévoile Argument1 et enfin Argument2.";
FrenchLogEntries[LogEntryNames.REVEAL_HAND] = "Argument0 dévoile Argument1.";
FrenchLogEntries[LogEntryNames.REVEAL_DISCARD] = "Argument0 dévoile Argument1.";
FrenchLogEntries[LogEntryNames.LOOK_AT] = "Argument0 regarde Argument1.";
FrenchLogEntries[LogEntryNames.PUT_IN_HAND] = "Argument0 prend en main Argument1.";
FrenchLogEntries[LogEntryNames.SET_ASIDE] = "Argument0 met de côté Argument1.";
FrenchLogEntries[LogEntryNames.PUT_ON_MAT] = "Argument0 met Argument1 sur son Argument2.";
FrenchLogEntries[LogEntryNames.CALL] = "Argument0 recourt à Argument1.";
FrenchLogEntries[LogEntryNames.DECK_TO_DISCARD] = "Argument0 place sa pioche dans sa défausse.";
FrenchLogEntries[LogEntryNames.BACK_ON_DECK] = "Argument0 replace Argument1 sur sa pioche.";
FrenchLogEntries[LogEntryNames.SHUFFLE_INTO_DECK] = "Argument0 mélange Argument1 dans sa pioche.";
FrenchLogEntries[LogEntryNames.INSERT_IN_DECK] = "Argument0 insère Argument1 dans sa pioche.";
FrenchLogEntries[LogEntryNames.EXCHANGE_RETURN] = "Argument0 retourne Argument1.";
FrenchLogEntries[LogEntryNames.EXCHANGE_RECEIVE] = "Argument0 obtient en échange Argument1.";
FrenchLogEntries[LogEntryNames.PASS] = "Argument0 passe Argument1 à Argument2.";
FrenchLogEntries[LogEntryNames.STARTS_WITH] = "Argument0 commence avec Argument1.";
FrenchLogEntries[LogEntryNames.BUY] = "Argument0 achète Argument1.";
FrenchLogEntries[LogEntryNames.COUNTER_ADD] = "COUNTER_ADD";
FrenchLogEntries[LogEntryNames.COUNTER_RESET] = "COUNTER_RESET";
FrenchLogEntries[LogEntryNames.REACTS_WITH] = "Argument0 réagit avec Argument1.";
FrenchLogEntries[LogEntryNames.SHUFFLES] = "Argument0 mélange sa pioche.";
FrenchLogEntries[LogEntryNames.NEW_TURN] = "Argument0";
FrenchLogEntries[LogEntryNames.WISH_CORRECT] = "Argument0 nomme et dévoile Argument1.";
FrenchLogEntries[LogEntryNames.WISH_WRONG] = "Argument0 nomme Argument1 mais dévoile Argument2.";
FrenchLogEntries[LogEntryNames.BLACK_MARKET_RESHUFFLE] = "Le paquet du marché noir est mélangé.";
FrenchLogEntries[LogEntryNames.RETURN_TO] = "Argument0 remet Argument1 dans Argument2.";
FrenchLogEntries[LogEntryNames.PUT_ON_BOTTOM_OF] = "Argument0 place Argument1 en dessous de Argument2.";
FrenchLogEntries[LogEntryNames.LOSE_TRACK_MOVED] = "Argument0 ne peut plus déplacer Argument1 (qui a été déplacé).";
FrenchLogEntries[LogEntryNames.LOSE_TRACK_HIDDEN] = "Argument0 ne peut plus déplacer Argument1 (qui a été recouvert).";
FrenchLogEntries[LogEntryNames.LOSE_TRACK_RESHUFFLED] = "Argument0 ne peut plus déplacer Argument1 (qui a été mélangé).";
FrenchLogEntries[LogEntryNames.LOSE_TRACK_ANONYMOUS] = "Argument0 ne peut plus être déplacé.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_BANDIT_CAMP] = "Argument0.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_TOWER] = "Argument0.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH_ABSENT] = "Aucune carte Action.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH] = "Argument0.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_WALL] = "Argument0.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_WOLF_DEN] = "exactement un exemplaire de Argument0.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_PALACE] = "Argument0.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_ORCHARD] = "Argument0.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_OBELISK] = "Argument0 cartes de la pile Argument1.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_MUSEUM] = "Argument0 cartes de noms différents.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_KINGS_CASTLE] = "Argument0 Châteaux.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_KEEP] = "Argument0.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_HUMBLE_CASTLE] = "Argument0 Châteaux.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_FOUNTAIN] = "Argument0.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_FEODUM] = "Argument0.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_GARDENS] = "Argument0.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_VINEYARD] = "Argument0 cartes Action.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_SILK_ROAD] = "Argument0 cartes Victoire.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_FAIRGROUNDS] = "Argument0 cartes de noms différents.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_DUKE] = "Argument0.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_DUH] = "";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_VP_TOKENS] = "jetons VP.";
FrenchLogEntries[LogEntryNames.PUT_IN_HAND_FROM] = "Argument0 prend en main Argument1 (Argument2).";
FrenchLogEntries[LogEntryNames.STARTS_TURN] = "Argument0 commence son tour.";
FrenchLogEntries[LogEntryNames.TAKE_VP_FROM] = "Argument0 prend Argument1 VP depuis Argument2.";
FrenchLogEntries[LogEntryNames.MOVE_VP_FROM_TO] = "Argument0 déplace Argument1 VP depuis Argument2 vers Argument3.";
FrenchLogEntries[LogEntryNames.OBELISK_CHOOSES_PILE] = "L'Obélisque choisit Argument0.";
FrenchLogEntries[LogEntryNames.PLAYER_MOVES_TOKEN_TO] = "Argument0 déplace son Argument1 vers Argument2.";
FrenchLogEntries[LogEntryNames.PLAYER_GETS_VP] = "Argument0 reçoit Argument1 VP.";
FrenchLogEntries[LogEntryNames.PLAYER_GETS_VP_FROM] = "Argument0 reçoit Argument1 VP depuis Argument2.";
FrenchLogEntries[LogEntryNames.ADDS_VP_TO] = "Argument0 ajoute Argument1 VP à Argument2.";
FrenchLogEntries[LogEntryNames.WAITING_FOR] = "En attente de Argument0.";
FrenchLogEntries[LogEntryNames.ALMS_FAIL] = "Argument0 achète une Aumône mais a Argument1 en jeu.";
FrenchLogEntries[LogEntryNames.BORROW_FAIL] = "Argument0 achète un Emprunt mais a déjà Argument1";
FrenchLogEntries[LogEntryNames.QUEST_FAIL_ATTACK] = "Argument0 ne parvient pas à défausser une Attaque.";
FrenchLogEntries[LogEntryNames.QUEST_FAIL_CURSES] = "Argument0 ne parvient pas à défausser Argument1.";
FrenchLogEntries[LogEntryNames.QUEST_FAIL_DISCARD] = "Argument0 ne parvient pas à défausser 6 cartes.";
FrenchLogEntries[LogEntryNames.SAVE_FAIL] = "Argument0 n'a aucune carte à mettre de côté.";
FrenchLogEntries[LogEntryNames.SCOUTING_PARTY_FAIL] = "Argument0 n'a aucune carte à défausser ou à mettre sur sa pioche.";
FrenchLogEntries[LogEntryNames.BONFIRE_FAIL] = "Argument0 n'écarte rien.";
FrenchLogEntries[LogEntryNames.MISSION_SUCCESS] = "Argument0 joue un tour supplémentaire après celui-ci.";
FrenchLogEntries[LogEntryNames.TRADE_FAIL] = "Argument0 n'écarte rien.";
FrenchLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_DOWN] = "Argument0 retourne Argument1 face cachée.";
FrenchLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_UP] = "Argument0 retourne Argument1 face visible.";
FrenchLogEntries[LogEntryNames.TRIUMPH_FAIL] = "Argument0 ne parvient pas à gagner Argument1.";
FrenchLogEntries[LogEntryNames.ADVANCE_FAIL] = "Argument0 n'a pas écarté de carte Action.";
FrenchLogEntries[LogEntryNames.TAX_ADD_DEBT] = "Argument0 ajoute Argument1 à Argument2.";
FrenchLogEntries[LogEntryNames.TAX_TAKE_DEBT] = "Argument0 prend Argument1 depuis Argument2.";
FrenchLogEntries[LogEntryNames.RITUAL_FAIL] = "Argument0 ne parvient pas à gagner Argument1.";
FrenchLogEntries[LogEntryNames.WINDFALL_FAIL] = "Argument0 n'est pas vide.";
FrenchLogEntries[LogEntryNames.DOMINATE_FAIL] = "Argument0 ne parvient pas à gagner Argument1.";
FrenchLogEntries[LogEntryNames.PLUS_ACTION_FROM] = "Argument0 reçoit +1 Action (par Argument1).";
FrenchLogEntries[LogEntryNames.PLUS_BUY_FROM] = "Argument0 reçoit +1 Achat (par Argument1).";
FrenchLogEntries[LogEntryNames.DRAWS_FROM] = "Argument0 pioche Argument1 (par Argument2).";
FrenchLogEntries[LogEntryNames.PLUS_COIN_FROM] = "Argument0 reçoit +1 Pièce (par Argument1).";
FrenchLogEntries[LogEntryNames.DRAWS_LESS_BECAUSE] = "Argument0 ne pioche pas une carte (à cause de Argument1).";
FrenchLogEntries[LogEntryNames.PRINCE_FAILS] = "";
FrenchLogEntries[LogEntryNames.POSSESSION_TAKES_COIN_TOKENS] = "Argument0 prend les jetons Pièce à sa place.";
FrenchLogEntries[LogEntryNames.POSSESSION_TAKES_DEBT_TOKENS] = "Argument0 prend les jetons Dette à sa place.";
FrenchLogEntries[LogEntryNames.POSSESION_TAKES_VP_TOKENS] = "Argument0 prend les jetons VP à sa place.";
FrenchLogEntries[LogEntryNames.TAKES_DEBT] = "Argument0 prend Argument1 jetons Dette.";
FrenchLogEntries[LogEntryNames.REPAYS_DEBT] = "Argument0 rembourse Argument1 jetons Dette.";
FrenchLogEntries[LogEntryNames.REPAYS_SOME_DEBT] = "Argument0 rembourse Argument1 jetons Dette (il en reste Argument2).";
FrenchLogEntries[LogEntryNames.RETURNS_TOKEN] = "Argument0 rend Argument1 placé par Argument2.";
FrenchLogEntries[LogEntryNames.SET_ASIDE_WITH] = "Argument0 met de côté Argument1 par Argument2.";
FrenchLogEntries[LogEntryNames.BLOCKS_WITH] = "Argument0 empêche avec Argument1.";
FrenchLogEntries[LogEntryNames.NAMES] = "Argument0 nomme Argument1.";
FrenchLogEntries[LogEntryNames.GAIN_ON_DRAWPILE] = "Argument0 reçoit Argument1 sur sa pioche.";
FrenchLogEntries[LogEntryNames.GAIN_FROM_TRASH] = "Argument0 reçoit Argument1 depuis le rebut.";
FrenchLogEntries[LogEntryNames.OBELISK_FAILS] = "L'Obélisque ne parvient pas à choisir une pile de cartes Action de la réserve.";
FrenchLogEntries[LogEntryNames.INHERITS] = "Argument0 hérite de Argument1.";
FrenchLogEntries[LogEntryNames.GAME_META_INFO] = "Argument0";
FrenchLogEntries[LogEntryNames.THE_SKYS_GIFT_FAIL] = "Argument0 ne parvient pas à défausser pour [The Skys Gift]";
FrenchLogEntries[LogEntryNames.RECEIVES] = "Argument0 applique Argument1.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_PASTURE] = "Argument0.";
FrenchLogEntries[LogEntryNames.TAKE] = "Argument0 prend Argument1.";
FrenchLogEntries[LogEntryNames.RETURN] = "Argument0 retourne Argument1.";
FrenchLogEntries[LogEntryNames.THE_SUNS_GIFT_FAIL] = "[The Suns Gift] n'a rien à défausser.";
FrenchLogEntries[LogEntryNames.DRUID_SELECTS_BOONS] = "[Druid] met Argument1 de côté.";
FrenchLogEntries[LogEntryNames.DISCARD_RECEIVABLE] = "Argument0 défausse Argument1.";
FrenchLogEntries[LogEntryNames.PLAY_ENCHANTED] = "Argument0 est enchanté par Argument1";
FrenchLogEntries[LogEntryNames.GAIN_ANOTHER_EXPERIMENT] = "Argument0 reçoit une autre [Experiment]";
FrenchLogEntries[LogEntryNames.COFFERS_FROM_PATRON] = "Argument0 obtient un Coffres d'un [Patron].";
FrenchLogEntries[LogEntryNames.GETS_COFFER] = "Argument0 obtient +Argument1 Coffres.";
FrenchLogEntries[LogEntryNames.GETS_COFFERS] = "Argument0 obtient +Argument1 Coffres.";
FrenchLogEntries[LogEntryNames.GETS_COFFER_FROM] = "Argument0 obtient +Argument1 Coffres. (Argument2)";
FrenchLogEntries[LogEntryNames.GETS_COFFERS_FROM] = "Argument0 obtient +Argument1 Coffres. (Argument2)";
FrenchLogEntries[LogEntryNames.USES_COFFER] = "Argument0 utilise Argument1 jeton de ses Coffres. (+$Argument1)";
FrenchLogEntries[LogEntryNames.USES_COFFERS] = "Argument0 utilise Argument1 jetons de ses Coffres. (+$Argument1)";
FrenchLogEntries[LogEntryNames.GETS_VILLAGER] = "Argument0 obtient +Argument1 Villageois.";
FrenchLogEntries[LogEntryNames.GETS_VILLAGERS] = "Argument0 obtient +Argument1 Villageois.";
FrenchLogEntries[LogEntryNames.GETS_VILLAGER_FROM] = "Argument0 obtient +Argument1 Villageois. (Argument2)";
FrenchLogEntries[LogEntryNames.GETS_VILLAGERS_FROM] = "Argument0 obtient +Argument1 Villageois. (Argument2)";
FrenchLogEntries[LogEntryNames.USES_VILLAGER] = "Argument0 utilise Argument1 Villageois.";
FrenchLogEntries[LogEntryNames.USES_VILLAGERS] = "Argument0 utilise Argument1 Villageois.";
FrenchLogEntries[LogEntryNames.BEGINS_BUYPHASE] = "Argument0 begins their buy phase."; // please translate
FrenchLogEntries[LogEntryNames.ENDS_BUYPHASE] = "Argument0 termine sa phase Achat.";
FrenchLogEntries[LogEntryNames.GETS_ACTION] = "Argument0 obtient +Argument1 Action.";
FrenchLogEntries[LogEntryNames.GETS_ACTIONS] = "Argument0 obtient +Argument1 Actions.";
FrenchLogEntries[LogEntryNames.GETS_ACTION_FROM] = "Argument0 obtient +Argument1 Action. (Argument2)";
FrenchLogEntries[LogEntryNames.GETS_ACTIONS_FROM] = "Argument0 obtient +Argument1 Actions. (Argument2)";
FrenchLogEntries[LogEntryNames.GETS_BUY] = "Argument0 obtient +Argument1 Achat.";
FrenchLogEntries[LogEntryNames.GETS_BUYS] = "Argument0 obtient +Argument1 Achats.";
FrenchLogEntries[LogEntryNames.GETS_BUY_FROM] = "Argument0 obtient +Argument1 Achat. (Argument2)";
FrenchLogEntries[LogEntryNames.GETS_BUYS_FROM] = "Argument0 obtient +Argument1 Achats. (Argument2)";
FrenchLogEntries[LogEntryNames.GETS_COIN] = "Argument0 obtient +Argument1 Pièce.";
FrenchLogEntries[LogEntryNames.GETS_COINS] = "Argument0 obtient +Argument1 Pièces.";
FrenchLogEntries[LogEntryNames.GETS_COIN_FROM] = "Argument0 obtient +Argument1 Pièce. (Argument2)";
FrenchLogEntries[LogEntryNames.GETS_COINS_FROM] = "Argument0 obtient +Argument1 Pièces. (Argument2)";
FrenchLogEntries[LogEntryNames.LOSES_COIN] = "Argument0 perd Argument1 Pièce.";
FrenchLogEntries[LogEntryNames.LOSES_COINS] = "Argument0 perd Argument1 Pièces.";
FrenchLogEntries[LogEntryNames.BOTTOMDECKS] = "Argument0 place Argument1 en bas de sa pioche.";
FrenchLogEntries[LogEntryNames.TRASH_WITH] = "Argument0 écarte Argument1. (Argument2)";
FrenchLogEntries[LogEntryNames.PRIEST_ACTIVATES] = "désormais, quand vous écartez une carte, +$Argument0.";
FrenchLogEntries[LogEntryNames.PLAY_TREASURES_FOR] = "Argument0 joue Argument1. (+$Argument2)";
FrenchLogEntries[LogEntryNames.PLAY_TREASURES_AGAIN_FOR] = "Argument0 joue à nouveau Argument1. (+$Argument2)";
FrenchLogEntries[LogEntryNames.RETURNS_MINUS_COIN_TOKEN] = "Argument0 rend Argument1 placé par Argument2. (-$1)";
FrenchLogEntries[LogEntryNames.NOT_IN_PLAY_PREVENTED_PLAYING] = "Argument0 n'était pas en jeu, donc n'a pas pu être joué.";
FrenchLogEntries[LogEntryNames.PILLAGE_FAIL] = "[Pillage] n'a pas été écarté, donc n'a pas d'effet.";
FrenchLogEntries[LogEntryNames.DEATH_CART_FAIL] = "[Death Cart] n'a pas écarté, donc n'a pas d'effet.";
FrenchLogEntries[LogEntryNames.TACTICIAN_FAIL] = "[Tactician] n'a pas défaussé, donc n'a pas d'effet.";
FrenchLogEntries[LogEntryNames.EMBARGO_FAIL] = "[Embargo] n'a pas été écarté, donc n'a pas d'effet.";
FrenchLogEntries[LogEntryNames.EXILE] = "Argument0 exile Argument1.";
FrenchLogEntries[LogEntryNames.DISCARD_FROM_EXILE] = "Argument0 défausse Argument1 depuis l'Exil.";
FrenchLogEntries[LogEntryNames.DISCARD_REVEALED] = "Argument0 défausser et dévoile Argument1.";
FrenchLogEntries[LogEntryNames.PLAY_WITH] = FrenchLogEntries[LogEntryNames.PLAY] + " (Argument2)";
FrenchLogEntries[LogEntryNames.PLAY_AGAIN_WITH] = FrenchLogEntries[LogEntryNames.PLAY] + " (Argument2)";
FrenchLogEntries[LogEntryNames.PLAY_THIRD_WITH] = FrenchLogEntries[LogEntryNames.PLAY] + " (Argument2)";
FrenchLogEntries[LogEntryNames.IS_INVESTED] = "Argument0 pioche Argument2 cartes quand un adversaire reçoit ou investit dans Argument1.";
FrenchLogEntries[LogEntryNames.COMMERCE_FAIL] = "Argument0 n'a pas reçu de cartes.";
FrenchLogEntries[LogEntryNames.STAMPEDE_FAIL] = "Argument0 a acheté [Stampede] avec Argument1 cartes en jeu.";
FrenchLogEntries[LogEntryNames.PLAY_USING_WAY] = "Argument0 joue Argument1 en utilisant Argument2.";
FrenchLogEntries[LogEntryNames.WAY_OF_THE_HORSE_FAILS_NO_PILE] = "Argument0 n'a pas de pile où retourner.";
FrenchLogEntries[LogEntryNames.WAY_OF_THE_HORSE_FAILS_NOT_IN_PLAY] = "[Way of the Horse] échoue à déplacer Argument0, car pas en jeu.";
FrenchLogEntries[LogEntryNames.WAY_OF_THE_TURTLE_FAILS_TO_SET_ASIDE] = "[Way of the Turtle] échoue à mettre Argument0 de côté.";
FrenchLogEntries[LogEntryNames.USES_WAY] = "Argument0 uses Argument1.";
FrenchLogEntries[LogEntryNames.WOULD_GET_COIN] = "Argument0 aurait obtenu +$Argument1.";
FrenchLogEntries[LogEntryNames.WOULD_DRAW] = "Argument0 aurait pioché Argument1.";
FrenchLogEntries[LogEntryNames.TOPDECK_WITH] = "Argument0 place Argument1 sur la pioche par Argument2.";
FrenchLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_NO_PILE] = "Argument0 n'a pas de pile où retourner.";
FrenchLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_NOT_IN_PLAY] = "[Way of the Butterfly] échoue à déplacer Argument0, qui n'est pas en jeu.";
FrenchLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_INTENTIONAL] = "Argument0 décide de ne pas retourner Argument1 sur sa pile.";
FrenchLogEntries[LogEntryNames.PLAY_NULLIFIED] = "Argument0 joue Argument1 (annulé par [Highwayman]).";
FrenchLogEntries[LogEntryNames.GETS_FAVOR] = "Argument0 reçoit +Argument1 Faveur.";
FrenchLogEntries[LogEntryNames.GETS_FAVORS] = "Argument0 reçoit +Argument1 Faveurs.";
FrenchLogEntries[LogEntryNames.GETS_FAVOR_FROM] = "Argument0 reçoit +Argument1 Faveur. (Argument2)";
FrenchLogEntries[LogEntryNames.GETS_FAVORS_FROM] = "Argument0 reçoit +Argument1 Faveurs. (Argument2)";
FrenchLogEntries[LogEntryNames.SPENDS_FAVOR] = "Argument0 dépense Argument1 Faveur.";
FrenchLogEntries[LogEntryNames.SPENDS_FAVORS] = "Argument0 dépense Argument1 Faveurs.";
FrenchLogEntries[LogEntryNames.ROTATES_EMPTY_PILE] = "Argument0 tourne une pile vide";
FrenchLogEntries[LogEntryNames.ROTATES_IDENTICAL_PILE] = "Argument0 tourne une pile de cartes identiques.";
FrenchLogEntries[LogEntryNames.ROTATES] = "Argument0 tourne Argument1.";
FrenchLogEntries[LogEntryNames.ISLAND_FOLK] = "Argument0 joue un tour supplémentaire après celui-ci.";
FrenchLogEntries[LogEntryNames.ROYAL_GALLEY_FAIL] = "[Royal Galley] échoue à mettre de côté.";
FrenchLogEntries[LogEntryNames.FAILS_TO_PLAY] = "Argument0 échoue à jouer Argument1";
FrenchLogEntries[LogEntryNames.FAILS_TO_PLAY_WRONG_LOCATION] = "Argument0 échoue à jouer Argument1 car pas dans Argument2";
FrenchLogEntries[LogEntryNames.STRANDS_CARDS] = "Argument0 échoue Argument1";
FrenchLogEntries[LogEntryNames.VOYAGE_SUCCESS] = "Argument0 joue un tour supplémentaire de Voyage après celui-ci.";
FrenchLogEntries[LogEntryNames.GARRISON_ADDS_TOKEN] = "Argument0 ajoute un jeton à [Garrison], maintenant à Argument1.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_PLATEAU_SHEPHERDS] = "Argument0 Cartes, Argument1 Faveurs.";
FrenchLogEntries[LogEntryNames.PUT_IN_DISCARD] = "Argument0 place Argument1 dans sa défausse.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_TERRITORY] = "Argument0 cartes Victoire de nom différent.";
FrenchLogEntries[LogEntryNames.SORCERESS_GUESS] = "Argument0 devine Argument1.";
FrenchLogEntries[LogEntryNames.SORCERER_GUESS] = "Argument0 devine Argument1.";
FrenchLogEntries[LogEntryNames.SKIPS_A_TURN_REMAINING] = "Argument0 passe un tour (reste Argument1).";
FrenchLogEntries[LogEntryNames.SKIPS_A_TURN] = "Argument0 passe un tour.";
FrenchLogEntries[LogEntryNames.CANCELS_A_TURN] = "Argument0 échoue à jouer un tour (par Argument1).";
FrenchLogEntries[LogEntryNames.WILL_SKIP_A_TURN] = "Argument0 va passer un tour.";
FrenchLogEntries[LogEntryNames.WILL_SKIP_A_TURN_COUNT] = "Argument0 va passer un tour (au total Argument1).";
FrenchLogEntries[LogEntryNames.KEEPS_IN_HAND] = "Argument0 garde Argument1 un main.";
FrenchLogEntries[LogEntryNames.GAIN_SETTING_ASIDE] = "Argument0 reçoit et met de côté Argument1.";
FrenchLogEntries[LogEntryNames.GAIN_SETTING_ASIDE] = "Argument0 reçoit et met de côté Argument1.";
FrenchLogEntries[LogEntryNames.PLAYER_BIDS] = "Argument0 mise Argument1 jetons Dette";
FrenchLogEntries[LogEntryNames.INVESTMENT_FAIL] = "[Investment] ne peut pas s'écarter car il n'est pas en jeu.";
FrenchLogEntries[LogEntryNames.PLAY_TREASURES_THIRD_FOR] = FrenchLogEntries[LogEntryNames.PLAY_THIRD] + " (+$Argument2)";
FrenchLogEntries[LogEntryNames.PLAYER_ADDS_FAVOR_TOKEN_TO] = "Argument0 met une jeton Faveur sur Argument1. ([Family of Inventors])";
FrenchLogEntries[LogEntryNames.BEGINS_CLEANUP_PHASE] = "Argument0 commence sa phase Ajustement.";
FrenchLogEntries[LogEntryNames.PREMOVES] = "Argument0 pré-déplace Argument1.";
FrenchLogEntries[LogEntryNames.SPELL_SCROLL_FAILS] = "[Spell Scroll] n'est pas écarté, donc ne fait rien.";
FrenchLogEntries[LogEntryNames.PERIL_FAIL] = "[Peril] n'a pas écarté, donc ne fait rien.";
FrenchLogEntries[LogEntryNames.ACQUIRES_TRAIT] = "Le Trait Argument1 est acquis par Argument0.";
FrenchLogEntries[LogEntryNames.TRAIT_FAILS] = "Argument0 échoue à choisir une pile Action ou Trésor.";
FrenchLogEntries[LogEntryNames.SHUFFLES_CARDS] = "Argument0 mélange Argument1.";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_MARCHLAND] = "Argument0 cartes Victoire.";

FrenchLogEntries[LogEntryNames.FARMHANDS_FAIL] = "[Farmhands] échoue à mettre une carte de côté";
FrenchLogEntries[LogEntryNames.FARMHANDS_REFUSE] = "[Farmhands] n'a pas mis de carte de côté";
FrenchLogEntries[LogEntryNames.EXPLAIN_POINTS_DEMESNE] = "Argument0 Ors.";
FrenchLogEntries[LogEntryNames.REACTS_PLAYING] = "Argument0 réagit avec Argument1, en le jouant.";
FrenchLogEntries[LogEntryNames.PLAY_ENLIGHTENED] = "Argument0 joue Argument1 pour +1 Card +1 Action";
FrenchLogEntries[LogEntryNames.PANIC_FAILS] = "[Panic] échoue à retourner Argument0";
FrenchLogEntries[LogEntryNames.PANIC_FAILS_NO_PILE] = "[Panic] ne peut pas retourner Argument0 faute de pile.";
FrenchLogEntries[LogEntryNames.AMASS_FAILS] = "Argument0 achète [Amass] mais a Argument1 en jeu.";