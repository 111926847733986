"use strict";

webclient.component('landscapeSelector', {
    bindings: {
        types: "<",
        index: "<",
        isHost: "<"
    },
    controller: ["$element", "tableService", "kingdomService", function ($element, tableService, kingdomService) {
        var self = this;

        self.realLandscapeTypes = [LANDSCAPE_TYPES.EVENT, LANDSCAPE_TYPES.LANDMARK, LANDSCAPE_TYPES.PROJECT, LANDSCAPE_TYPES.WAY, LANDSCAPE_TYPES.TRAIT];
        self.typeIsActive = function (t) {
            return Math.floor(self.types / t) % 2;
        };
        self.shouldShow = function () {
            return self.types > 1;
        };
        self.getText = function (t) {
            switch (t) {
                case LANDSCAPE_TYPES.EVENT:
                    return LANGUAGE.getType[Types.EVENT];
                case LANDSCAPE_TYPES.LANDMARK:
                    return LANGUAGE.getType[Types.LANDMARK];
                case LANDSCAPE_TYPES.PROJECT:
                    return LANGUAGE.getType[Types.PROJECT];
                case LANDSCAPE_TYPES.WAY:
                    return LANGUAGE.getType[Types.WAY];
                case LANDSCAPE_TYPES.TRAIT:
                    return LANGUAGE.getType[Types.TRAIT];
            }
        };
        self.getBackground = function (t) {
            switch (t) {
                case LANDSCAPE_TYPES.EVENT:
                    return "event";
                case LANDSCAPE_TYPES.LANDMARK:
                    return "landmark";
                case LANDSCAPE_TYPES.PROJECT:
                    return "project";
                case LANDSCAPE_TYPES.WAY:
                    return "way";
                case LANDSCAPE_TYPES.TRAIT:
                    return "trait";
            }
        };
        self.nothingText = LANGUAGE.getPhrase[Phrases.LANDSCAPE_UNUSED];

        self.toggleType = function (t) {
            if (self.isHost) {
                var types = self.typeIsActive(t) ? self.types - t : self.types + t;
                var kingdom = tableService.getRuleValue(TableRuleIds.REQUIRED_CARDS);
                var landscapeSlots = kingdom.filter(function (s) {
                    return "landscapeTypes" in s;
                });
                if (types <= 1) {
                    var index = kingdom.indexOf(landscapeSlots[self.index]);
                    kingdom.splice(index, 1);
                } else {
                    landscapeSlots[self.index].landscapeTypes = types;
                }
                self.types = types;
                tableService.changeRule(new TableRule(TableRuleIds.REQUIRED_CARDS, -1, kingdom));
                if (self.hovered) self.tooltip();
                tableService.changeRule(new TableRule(TableRuleIds.RATED_GAME, false));
            }
        };

        self.remove = function () {
            kingdomService.removeLandscapeSlot(self.index);
        };

        var setTooltip = function setTooltip() {
            var typeWords = [];
            self.realLandscapeTypes.forEach(function (t) {
                if (self.typeIsActive(t)) typeWords.push(self.getText(t));
            });
            if (self.typeIsActive(1)) typeWords.push(self.nothingText);

            if (typeWords.length > 0) document.getElementById("tooltip").innerHTML = LANGUAGE.getEnvironment.phraseCollection_Or(typeWords);else self.hideTooltip();
        };

        var positionTooltip = function positionTooltip() {
            var boundingBox = $element[0].querySelector(".landscape-selector").getBoundingClientRect();
            var iconOffset = $element[0].querySelector(".landscape-selector").offsetWidth / 2;
            var tooltipOffset = document.getElementById("tooltip").offsetWidth / 2;
            document.getElementById("tooltip").style.top = boundingBox.top - boundingBox.height / 2 + "px";
            document.getElementById("tooltip").style.left = boundingBox.left + iconOffset - tooltipOffset + "px";
        };
        self.hovered = false;
        self.tooltip = function () {
            document.getElementById("tooltip").className = "table-tooltip";
            setTooltip();
            positionTooltip();
            self.hovered = true;
        };
        self.hideTooltip = function () {
            document.getElementById("tooltip").className = "table-tooltip hidden";
            self.hovered = false;
        };
        self.longPress = function () {
            if (self.hovered) self.hideTooltip();else self.tooltip();
        };
    }],
    template: "\n        <div\n          class=\"landscape-selector unselectable\"\n          ng-class=\"{hovered: $ctrl.hovered}\"\n          ng-if=\"$ctrl.shouldShow()\"\n          ng-long-press=\"$ctrl.longPress()\"\n          ng-mouseover=\"$ctrl.tooltip()\"\n          ng-mouseleave=\"$ctrl.hideTooltip()\">\n            <div\n              class=\"landscape-selector-remove\"\n              ng-if=\"$ctrl.isHost\"\n              ng-click=\"$ctrl.remove()\">\n            </div>\n            <div class=\"landscape-selector-part-container\"\n                  ng-repeat=\"t in ::$ctrl.realLandscapeTypes\">\n                <div\n                  class=\"landscape-selector-part\"\n                  ng-class=\"{active: $ctrl.typeIsActive(t)}\"\n                  ng-click=\"$ctrl.toggleType(t)\"\n                  style=\"background-image: url('images/cards/templates/{{$ctrl.getBackground(t)}}-selector.webp')\">\n                    <div class=\"landscape-selector-text\">{{::$ctrl.getText(t)}}</div>\n                </div>\n            </div>\n            <div\n              class=\"landscape-selector-part active\"\n              ng-click=\"$ctrl.remove()\"\n              ng-if=\"$ctrl.isAlly()\"\n              style=\"background-image: url('images/cards/templates/ally-micro.webp')\">\n                <div class=\"landscape-selector-text-ally\">{{::$ctrl.getAllyText()}}</div>\n            </div>\n            <div\n              class=\"landscape-selector-none\"\n              ng-click=\"$ctrl.toggleType(1)\"\n              ng-class=\"{inactive: !$ctrl.typeIsActive(1)}\">\n            </div>\n        </div>\n    "
});