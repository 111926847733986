"use strict";

webclient.component("userPreference", {
    bindings: {
        id: "<"
    },
    controllerAs: "userPref",
    controller: ['preferences', function (preferences) {
        var userPref = this;

        userPref.pref = preferences.getUserPreference(userPref.id);

        if (isUndefined(userPref.pref)) {
            userPref.pref = new UserPreference(userPref.id, null);
        }

        userPref.type = userPref.id.type;

        userPref.labels = LANGUAGE.getUserPreferences;

        function displayBoolean(bool) {
            var result = bool ? "true" : "false";
            return result;
        }

        function displayInteger(int) {
            return int;
        }

        function displayString(string) {
            return string;
        }

        function displayPassword(pwd) {
            return "*****";
        }

        function displayCardList(cards) {
            if (cards.list) return cards.list.map(function (c) {
                return LANGUAGE.getCardName[c].singular;
            }).join(LANGUAGE.getPhrase[Phrases.COMMA]);
        }

        function displayPlayerList(players) {
            return players.join(getPhrase(Phrases.COMMA));
        }

        var translators = {};
        translators[UserPrefTypes.AUTOPLAY_SETTINGS] = displayBoolean;
        translators[UserPrefTypes.BOOLEAN] = displayBoolean;
        translators[UserPrefTypes.INTEGER] = displayInteger;
        translators[UserPrefTypes.STRING] = displayString;
        translators[UserPrefTypes.PASSWORD] = displayPassword;
        translators[UserPrefTypes.EMAIL] = displayString;
        translators[UserPrefTypes.CARD_LIST] = displayCardList;

        userPref.displayArguments = function (args, prefId) {
            return translators[prefId.type](args);
        };

        userPref.submit = function (pref) {
            preferences.setUserPreference(pref);
        };

        userPref.autoplay = function () {
            return userPref.type === UserPrefTypes.AUTOPLAY_SETTINGS;
        };
        userPref.boolean = function () {
            return userPref.type === UserPrefTypes.BOOLEAN;
        };
        userPref.string = function () {
            return userPref.type === UserPrefTypes.STRING;
        };
        userPref.integer = function () {
            return userPref.type === UserPrefTypes.INTEGER;
        };
        userPref.cardList = function () {
            return userPref.type === UserPrefTypes.CARD_LIST;
        };
        userPref.owned = function () {
            return userPref.type === UserPrefTypes.OWNERSHIP;
        };
        userPref.enum = function () {
            return userPref.type === UserPrefTypes.ENUM;
        };

        userPref.isNonAutoplay = function () {
            return userPref.boolean() || userPref.integer();
        };
    }],
    template: "\n            <user-pref-autoplay ng-if=\"userPref.autoplay()\" pref=\"userPref.pref\"></user-pref-autoplay>\n            <label ng-if=\"userPref.isNonAutoplay()\">{{userPref.labels[userPref.pref.id]}}: </label>\n            <div class=\"user-pref-separator\" ng-if=\"userPref.isNonAutoplay()\"></div>\n            <user-pref-boolean ng-if=\"userPref.boolean()\" pref=\"userPref.pref\"></user-pref-boolean>\n            <user-pref-integer ng-if=\"userPref.integer()\" pref=\"userPref.pref\"></user-pref-integer>\n        "
});