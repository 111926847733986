"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var BasicVictoryZone = function (_SupplyZone) {
    _inherits(BasicVictoryZone, _SupplyZone);

    function BasicVictoryZone(state, index, pileName, owner, createdBy, attachedTrait) {
        _classCallCheck(this, BasicVictoryZone);

        var _this = _possibleConstructorReturn(this, (BasicVictoryZone.__proto__ || Object.getPrototypeOf(BasicVictoryZone)).call(this, state, index, pileName, owner, createdBy, attachedTrait));

        _this.indexOfType = _this.state.zones.filter(function (z) {
            return z instanceof BasicVictoryZone;
        }).length;
        return _this;
    }

    _createClass(BasicVictoryZone, [{
        key: "reposition",
        value: function reposition() {
            if (this.layoutModel.layout === null) return;
            var vw = window.innerWidth * 0.01;
            var vh = window.innerHeight * 0.01;
            var baseWidth = Math.min(vh * BASE_SUPPLY_HEIGHT / this.layoutModel.baseRows / CardSizes.MINI.ratio, vw * LEFT_COLUMN_WIDTH / 2);
            var baseHeight = baseWidth * CardSizes.MINI.ratio;

            var x = 0;
            var y = this.indexOfType * baseHeight + this.layoutModel.baseOffset;
            var width = baseWidth;
            var height = baseHeight;
            this.cardStacks[0].reposition(x + width * 0.005, y + height * 0.015, width * 0.97, height * 0.97);
        }
    }]);

    return BasicVictoryZone;
}(SupplyZone);

// let zoneNameSupplyVictory = new ZoneName(
//     "BASIC_VICTORY_PILE", null, [ZoneTypes.SUPPLY],
//     supplyPileInserter(CardSizes.MINI),
//     supplyPileRemover(CardSizes.MINI),
//     null,
//     null,
//     (zone) => {
//         let vw = window.innerWidth * 0.01;
//         let vh = window.innerHeight * 0.01;
//         let baseWidth = Math.min
//             (
//                 vh * BASE_SUPPLY_HEIGHT / this.layoutModel.baseRows / CardSizes.MINI.ratio,
//                 vw * LEFT_COLUMN_WIDTH / 2
//             )
//         let baseHeight = baseWidth * CardSizes.MINI.ratio;

//         zone.bbox.x = 0;
//         zone.bbox.y = zone.indexOfType * baseHeight + zone.state.layoutService.baseOffset;
//         zone.bbox.width = baseWidth;
//         zone.bbox.height = baseHeight;
//         supplyPilePositioner(CardSizes.MINI)(zone);
//     },
//     supplyAnimatedInserter(CardSizes.MINI)
// );