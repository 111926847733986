"use strict";

var EsperantoErrors = {};

EsperantoErrors[FailureReasons.WRONG_PASSWORD] = "Malĝusta pasvorto.";
EsperantoErrors[FailureReasons.NOT_ENOUGH_PLAYERS] = "Ne sufiĉaj ludantoj.";
EsperantoErrors[FailureReasons.TOO_MANY_PLAYERS] = "Tro multaj ludantoj.";
EsperantoErrors[FailureReasons.NEW_HOST_NOT_FOUND] = "Ne povas trovi la novan gastiganton.";
EsperantoErrors[FailureReasons.ALREADY_JOINED_A_TABLE] = "Vi jam aliĝis tablon.";
EsperantoErrors[FailureReasons.NOT_ALLOWED_TO_JOIN] = "Vi ne rajtas aliĝi.";
EsperantoErrors[FailureReasons.BAD_CLIENT_VERSION] = "Via kliento estas tro malnova; bonvolu ctrl/cmd-refreŝigu la paĝon por recevi novan.";
EsperantoErrors[FailureReasons.UNKNOWN_RULE_INDEX] = "Nekonata regulindekso.";
EsperantoErrors[FailureReasons.RULE_NOT_REMOVABLE] = "Tiu regula ne estas forigebla.";
EsperantoErrors[FailureReasons.INVITEE_NOT_LOGGED_IN] = "Invitito ne ensalutis.";
EsperantoErrors[FailureReasons.NOT_ALLOWED_TO_PLAY] = "Vi ne rajtas ludi.";
EsperantoErrors[FailureReasons.TABLE_CHAT_REQUIRES_TABLE] = "Tablobabilado postulas tablon.";
EsperantoErrors[FailureReasons.SPECTATOR_CHAT_REQUIRES_TABLE] = "Spektantobabilado postulas tablon.";
EsperantoErrors[FailureReasons.LOGIN_REQUIRED] = "Vi devas ensaluti.";
EsperantoErrors[FailureReasons.COMMAND_REQUIRES_TABLE] = "Ĉi tiu komando postulas tablon.";
EsperantoErrors[FailureReasons.COMMAND_REQUIRES_HOST] = "Ĉi tiu komando postulas gastigantajn privilegiojn.";
EsperantoErrors[FailureReasons.RULE_CONFLICTS] = "Konfliktantaj reguloj.";
EsperantoErrors[FailureReasons.NO_GAMESERVER_AVAILABLE] = "Neniu ludservilo havebla.";
EsperantoErrors[FailureReasons.SELECTED_CARD_NOT_OWNED] = "Vi ne posedas la elektitan karton.";
EsperantoErrors[FailureReasons.SELECTED_CARD_NOT_KINGDOM_CARD] = "La elektitan karton ne estas regnokarto.";
EsperantoErrors[FailureReasons.SELECTED_TOO_MANY_EVENTS_OR_LANDMARKS] = "Tro multaj Okazoj aŭ Rekoniloj elektitaj.";
EsperantoErrors[FailureReasons.THIS_IS_NOT_YOUR_TABLE] = "Ĉi tio ne estas via tablo.";
EsperantoErrors[FailureReasons.ALREADY_LOGGED_IN] = "Jam ensalutita.";
EsperantoErrors[FailureReasons.CANNOT_RECONNECT_WHEN_STILL_CONNECTED] = "Ne povas rekonekti kiam daŭre konektita.";
EsperantoErrors[FailureReasons.KICK_FAILED] = "Elĵeto fiaskis.";
EsperantoErrors[FailureReasons.ALREADY_STARTING] = "Jam komencas.";
EsperantoErrors[FailureReasons.ALREADY_STARTED] = "Jam komencis.";
EsperantoErrors[FailureReasons.NOTHING_TO_KICK] = "Nenio por elĵeti.";
EsperantoErrors[FailureReasons.INTERNAL_ERROR] = "Interna eraro.";
EsperantoErrors[FailureReasons.ILLEGAL_PROPERTY_CHANGE] = "Kontraŭleĝa Ecoŝanĝo";
EsperantoErrors[FailureReasons.CODE_ALREADY_USED] = "Kodo jam uzita";
EsperantoErrors[FailureReasons.FAILED_CONFIRMING_EMAIL] = "Fiaskas konfirmi retadreson";
EsperantoErrors[FailureReasons.FAILED_RETRIEVING_USER] = "Fiaskas repreni uzanto";
EsperantoErrors[FailureReasons.EMAIL_NOT_CONFIRMED_YET] = "Vi ne povas gastigi ludon antaŭ ke vi konfirmas vian retadreson.";
EsperantoErrors[FailureReasons.FAILURE_SENDING_EMAIL] = "Konfirmanta retmesaĝo ne povis esti sendita";
EsperantoErrors[FailureReasons.CONFIRM_EMAIL_FIRST] = "Ni bedaŭras, ni ne povas sendi al vi pasvorton sen konfirmita retadreso.";
EsperantoErrors[FailureReasons.OLD_PASSWORD_INCORRECT] = "Malnova pasvorto malĝusta";
EsperantoErrors[FailureReasons.INCORRECT_PRICE] = "Prezo malĝusta";
EsperantoErrors[FailureReasons.PURCHASE_IN_FUTURE] = "Aĉeto ŝajnas veni el estonteco";
EsperantoErrors[FailureReasons.PURCHASE_TOO_OLD] = "Aĉeto estas tro malnova; bonvolu provu denove";
EsperantoErrors[FailureReasons.FAILED_SETTING_UP_PURCHASE] = "Fiaskas agordi aĉeton";
EsperantoErrors[FailureReasons.FAILED_STORING_PURCHASE] = "Fiaskas konservi aĉeton";
EsperantoErrors[FailureReasons.DUPLICATE_USERNAME] = "Ĉi tiu salutnomo jam ekzistas";
EsperantoErrors[FailureReasons.INVALID_USERNAME] = "Nevalida salutnomo";
EsperantoErrors[FailureReasons.INVALID_PASSWORD] = "Nevalida pasvorto";
EsperantoErrors[FailureReasons.INVALID_GENDER] = "Nevalida ĝendro";
EsperantoErrors[FailureReasons.PURCHASE_TOO_SHORT] = "Minimuma abondaŭro estas 1 monato";
EsperantoErrors[FailureReasons.PURCHASE_TOO_LONG] = "Nuntempe ni ne vendas abonoj preter 2024";
EsperantoErrors[FailureReasons.METASERVER_INACTIVE] = "Nova servilversio estis eldonita; bonvolu ctrl/cmd-refreŝigu la paĝon por konekti al ĝi.";
EsperantoErrors[FailureReasons.NO_SUCH_TABLE] = "Tablo ne ekzistas.";
EsperantoErrors[FailureReasons.NO_SUCH_USER] = "Uzanto ne ekzistas.";
EsperantoErrors[FailureReasons.NO_SUCH_CODE] = "Kodo ne ekzistas.";
EsperantoErrors[FailureReasons.RESERVED_USERNAME] = "Ĉi tiu salutnomo estas rezervita.";
EsperantoErrors[FailureReasons.PRICE_IS_ZERO] = "Vi ne povas aĉeti ion kun nula kosto.";
EsperantoErrors[FailureReasons.NO_SUCH_USERNAME] = "Salutnomo ne ekzistas.";
EsperantoErrors[FailureReasons.NO_SUCH_GAMELOG] = "La petita ludo ne povas esti trovita.";
EsperantoErrors[FailureReasons.ILLEGAL_PLAYER_ORDER] = "Kontraŭleĝa ludantordo.";
EsperantoErrors[FailureReasons.INVALID_SESSION_ID] = "Nevalida Sesia ID.";
EsperantoErrors[FailureReasons.ACTION_CODE_EXPIRED] = "Agkodo eksvalidiĝis.";
EsperantoErrors[FailureReasons.BOT_GAMES_CANT_BE_RATED] = "Ludoj kun robotoj ne povas esti poentaj.";
EsperantoErrors[FailureReasons.TOO_MANY_PLAYERS_FOR_RATED_GAME] = "Ludoj kun >4 ludantoj ne povas esti poentaj.";
EsperantoErrors[FailureReasons.ALREADY_ON_BLACKLIST] = "Jam sur barlisto.";
EsperantoErrors[FailureReasons.NOT_ON_BLACKLIST] = "Ne sur barlisto.";
EsperantoErrors[FailureReasons.ALREADY_ON_FRIENDLIST] = "Jam sur amiklisto.";
EsperantoErrors[FailureReasons.NOT_ON_FRIENDLIST] = "Ne sur amiklisto.";
EsperantoErrors[FailureReasons.SEARCHING_FOR_MYSELF] = "Ne povas aldoni sin.";
EsperantoErrors[FailureReasons.SEARCHED_FOR_BOT] = "Ne povas aldonu roboton.";
EsperantoErrors[FailureReasons.USER_IS_BANNED] = "Vi estis malpermesita de ĉi tiu retejo.";
EsperantoErrors[FailureReasons.WRONG_BONUS_CODE_TYPE] = "WRONG_BONUS_CODE_TYPE.";
EsperantoErrors[FailureReasons.ILLEGAL_BONUS_CODE] = "ILLEGAL_BONUS_CODE.";
EsperantoErrors[FailureReasons.GAME_ID_SHOULD_BE_NUMBER] = "ID de Ludo devas esti nomo.";
EsperantoErrors[FailureReasons.NOT_SUBSCRIBED_TO] = "Ne abonita";
EsperantoErrors[FailureReasons.KINGDOM_HAS_TOO_MANY_CARDS] = "Regno havas tro da kartoj.";
EsperantoErrors[FailureReasons.NO_CARDS_AVAILABLE_TO_FILL_KINGDOM] = "Neniuj kartoj haveblaj por plenigi Regno.";
EsperantoErrors[FailureReasons.FAILED_TO_SELECT_BANE_CARD] = "Fiaskis elekti Malhelpkarton.";
EsperantoErrors[FailureReasons.FAILED_TO_SELECT_MOUSE_CARD] = "Fiaskis elekti Muskarton.";
EsperantoErrors[FailureReasons.FAILED_TO_SELECT_FERRYMAN_CARD] = "Fiaskis elekti Pramistkarton.";
EsperantoErrors[FailureReasons.FAILED_TO_SELECT_PREVIEW_CARDS] = "Fiaskis elekti antaŭrigarditan karton.";
EsperantoErrors[FailureReasons.FAILED_TO_SELECT_ALLY] = "Fiaskis elekti Aliancanon.";
EsperantoErrors[FailureReasons.FAILED_TO_SELECT_LIASON] = "Fiaskis elekti Kontaktulo.";
EsperantoErrors[FailureReasons.REQUIRING_RANDOM_CARD_FROM_DISABLED_EXPANSION] = "Postulas hazardan karton el malŝaltata etendigo.";
EsperantoErrors[FailureReasons.COMMAND_REQUIRES_PLAYER] = "This command is only for players.";

var EsperantoSuccessDescriptions = {};

EsperantoSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_FORGOT_PASSWORD] = "Ni sendis al vi retmesaĝon por restarigi vian pasvorton.";
EsperantoSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_CONFIRM_EMAIL] = "Ni sendis al vi retmesaĝon por konfirmi vian retadreson.";
EsperantoSuccessDescriptions[SuccessDescriptions.PASSWORD_CHANGED] = "Pasvorto ŝanĝita";
EsperantoSuccessDescriptions[SuccessDescriptions.KICK_SUCCESS] = "Elĵeti sukcesa";
EsperantoSuccessDescriptions[SuccessDescriptions.PURCHASE_SUCCESS] = "Aĉeto sukcesa";
EsperantoSuccessDescriptions[SuccessDescriptions.EMAIL_CONFIRMED] = "Retadreso konfirmita";
EsperantoSuccessDescriptions[SuccessDescriptions.REFUND_SUCCESS] = "Aĉeto repagita";
EsperantoSuccessDescriptions[SuccessDescriptions.ACTION_CODE_USED] = "Sukcese uzis agkodon.";
EsperantoSuccessDescriptions[SuccessDescriptions.SUBSCRIPTION_UPDATED] = "Via abono estis ĝisdatigita.";

var EsperantoTableRules = {};

EsperantoTableRules[TableRuleIds.PLAYERS_CAN_SEE_SPECTATOR_CHAT] = "Ludantoj povas vidi spektantbabiladon";
EsperantoTableRules[TableRuleIds.BANNED_CARDS] = "Malpermesataj kartoj";
EsperantoTableRules[TableRuleIds.REQUIRED_CARDS] = "Postulataj kartoj";
EsperantoTableRules[TableRuleIds.USED_EXPANSIONS] = "Uzataj etendigoj";
EsperantoTableRules[TableRuleIds.RESTRICTIVE_PLAYER_RULES] = "Nur specifitaj uzantoj povas ludi";
EsperantoTableRules[TableRuleIds.SPECTATE_RULES] = "Permesu spektantoj";
EsperantoTableRules[TableRuleIds.MIN_RATING] = "Min relativa takso";
EsperantoTableRules[TableRuleIds.MAX_RATING] = "Maks relativa takso";
EsperantoTableRules[TableRuleIds.RANDOM_SEED] = "Specifu hazardan semon";
EsperantoTableRules[TableRuleIds.MIN_PLAYERS] = "Min ludantoj";
EsperantoTableRules[TableRuleIds.MAX_PLAYERS] = "Maks ludantoj";
EsperantoTableRules[TableRuleIds.SPECIAL_KINGDOM_RULES] = "Speciala regnoreguloj";
EsperantoTableRules[TableRuleIds.RESPECTED_CARD_LISTS] = "Observu ŝatatajn/malŝatatajn/malpermesatajn kartojn";
EsperantoTableRules[TableRuleIds.SHOW_VP_COUNTER] = "Montru VP nombrilo";
EsperantoTableRules[TableRuleIds.UNDO_SETTINGS] = "Malfaru agordoj";
EsperantoTableRules[TableRuleIds.ALWAYS_ALLOWED_PLAYER_RULES] = "ALWAYS_ALLOWED_PLAYER_RULES";
EsperantoTableRules[TableRuleIds.PLAYER_ORDER] = "Ludantordo";
EsperantoTableRules[TableRuleIds.REPLAY_INSTRUCTIONS] = "Reludu instrukciojn";
EsperantoTableRules[TableRuleIds.RATED_GAME] = "Poenta ludo";
EsperantoTableRules[TableRuleIds.PREVIEW_GAME] = "Bestaro Antaŭrigardo";
EsperantoTableRules[TableRuleIds.SCRIPTED_RULES] = "Speciala Regnoreguloj";
EsperantoTableRules[TableRuleIds.CARD_POOL_LEVEL] = "Kartgrupnivelo";

var EsperantoActivities = {};

EsperantoActivities[ActivityStati.LOGGED_IN] = "Ensalutis";
EsperantoActivities[ActivityStati.PLAYING] = "Ludas";
EsperantoActivities[ActivityStati.SPECTATING] = "Spektas";
EsperantoActivities[ActivityStati.AT_TABLE_PLAYER] = "Ĉe tablo (ludanto)";
EsperantoActivities[ActivityStati.AT_TABLE_SPECTATOR] = "Ĉe tablo (spek)";
EsperantoActivities[ActivityStati.DISCONNECTED] = "Malkonektis";

var EsperantoTableStati = {};

EsperantoTableStati[TableStati.NEW] = "Nova";
EsperantoTableStati[TableStati.POST_GAME] = "Post-ludo";
EsperantoTableStati[TableStati.RUNNING] = "Ludas";
EsperantoTableStati[TableStati.ABANDONED] = "Forlasita";

var EsperantoLobbyButtons = {
    DONE: "Finita",
    RESET: "Rekomencu",
    CLEAR_SELECTION: "Viŝu Elektaĵon",
    SHOW_TEXT_KINGDOM: "Montru Teksta Regno",
    NEW_TABLE: "Nova Tablo",
    START_GAME: "Komencu Ludon",
    LEAVE_TABLE: "Foriru Tablon",
    SELECT_KINGDOM: "Elektu Regnokartoj",
    LIST_BANNED: "Barita",
    LIST_DISLIKED: "Malamita",
    LIST_LIKED: "Amita",
    LIST_FAMILIAR: "Familiara",
    LOAD_FROM_END: "Ŝarĝu el Fino",
    SELECT_COLONIES: "Kolonioj: ",
    SELECT_SHELTERS: "Ŝirmejoj: "
};

var EsperantoLobbyTexts = {
    AUTOCOMPLETE_FAILED: "Neniuj kartoj kongruante \"CARDS\" estis trovitaj.",
    ADD_CARDS_AUTOCOMPLETE: "Enigu kartojn por inkluzivi en via regno",
    BAN_CARDS_AUTOCOMPLETE: "Enigu kartojn por malpermesi de via regno",
    KINGDOM_OPTIONS: "Regnelektoj",
    GAME_OPTIONS: "Ludelektoj",
    RESPECTED_CARDS: "Respektitaj Kartoj",
    RELATIVE_LEVEL: "Relativa Nivelo",
    LEVEL_INTERVAL: "${min} al ${maks}",
    EMPTY_SLOT: "Malplena Kanelo",
    REMOVE_SLOT: "Forigu Kanelon",
    ADD_SLOT: "Aldonu Kanelon",
    RANDOMIZE_PLAYER_ORDER: "Hazardigu Ludantordo",
    CHAT: "Babilado",
    SELECT_CARDS: "Elektu Kartojn"
};

var EsperantoTernaryFieldTexts = {};

EsperantoTernaryFieldTexts[TernaryField.YES] = "Jes";
EsperantoTernaryFieldTexts[TernaryField.NO] = "Ne";
EsperantoTernaryFieldTexts[TernaryField.RANDOM] = "Hazarde";

var EsperantoTabs = {};

EsperantoTabs[LobbyTabs.INBOX] = "Ricevujo";
EsperantoTabs[LobbyTabs.MATCH] = "Matĉoj";
EsperantoTabs[LobbyTabs.FRIEND_LIST] = "Amiklisto";
EsperantoTabs[LobbyTabs.TABLES] = "Tabloj";
EsperantoTabs[LobbyTabs.NEW_TABLE] = "Nova Tablo";
EsperantoTabs[LobbyTabs.MY_TABLE] = "Mia Tablo";
EsperantoTabs[LobbyTabs.ACCOUNT] = "Konto";
EsperantoTabs[LobbyTabs.STORE] = "Vendejo";
EsperantoTabs[LobbyTabs.OPTIONS] = "Elektoj";
EsperantoTabs[LobbyTabs.FAMILIAR_CARDS] = "Familiaraj Kartoj";
EsperantoTabs[LobbyTabs.LEADERBOARD] = "Ĉampionejo";
EsperantoTabs[LobbyTabs.CARDLISTS] = "Kartlistoj";

var EsperantoMatchTypes = {};

EsperantoMatchTypes[AutomatchTypes.QUICK_MATCH] = "Iu ajn Kontraŭulo";
EsperantoMatchTypes[AutomatchTypes.GOOD_MATCH] = "Egala Kontraŭulo";
EsperantoMatchTypes[AutomatchTypes.MATCH_WITH_EXPANSIONS] = "Matĉo kun Etendigoj";
EsperantoMatchTypes[AutomatchTypes.MATCH_WITH_BOT] = "Robotkontraŭulo";
EsperantoMatchTypes[AutomatchTypes.MATCH_UNRATED] = "Homkontraŭulo";

var EsperantoUndoSettings = {};

EsperantoUndoSettings[UndoSettings.NEVER] = "Neniu";
EsperantoUndoSettings[UndoSettings.ASK] = "Demandu";
EsperantoUndoSettings[UndoSettings.ALWAYS] = "Ĉiam";

var EsperantoGroupIds = {};

EsperantoGroupIds[GroupIds.EVERYBODY] = "Ĉiuj";
EsperantoGroupIds[GroupIds.NOBODY] = "Neniu";
EsperantoGroupIds[GroupIds.FRIENDS_OF] = "Amikoj de";
EsperantoGroupIds[GroupIds.LIST_PLAYER_IDS] = "Elektitaj ludantoj:";

var EsperantoCountries = {};

EsperantoCountries[Countries.NON_EU] = "Ne-EU";
EsperantoCountries[Countries.AT] = "Aŭstrio";
EsperantoCountries[Countries.BE] = "Belgio";
EsperantoCountries[Countries.BG] = "Bulgarujo";
EsperantoCountries[Countries.HR] = "Kroatio";
EsperantoCountries[Countries.CY] = "Kipro";
EsperantoCountries[Countries.CZ] = "Ĉeĥio";
EsperantoCountries[Countries.DK] = "Danlando";
EsperantoCountries[Countries.EE] = "Estonio";
EsperantoCountries[Countries.FI] = "Finnlando";
EsperantoCountries[Countries.FR] = "Francujo";
EsperantoCountries[Countries.DE] = "Germanujo";
EsperantoCountries[Countries.GR] = "Grekio";
EsperantoCountries[Countries.HU] = "Hungario";
EsperantoCountries[Countries.IE] = "Irlando";
EsperantoCountries[Countries.IT] = "Italujo";
EsperantoCountries[Countries.LV] = "Latvio";
EsperantoCountries[Countries.LT] = "Litovio";
EsperantoCountries[Countries.LU] = "Luksemburgio";
EsperantoCountries[Countries.MT] = "Malto";
EsperantoCountries[Countries.NL] = "Nederlando";
EsperantoCountries[Countries.PL] = "Pollando";
EsperantoCountries[Countries.PT] = "Portugalujo";
EsperantoCountries[Countries.RO] = "Rumanio";
EsperantoCountries[Countries.SK] = "Slovakio";
EsperantoCountries[Countries.SI] = "Slovenio";
EsperantoCountries[Countries.ES] = "Hispanujo";
EsperantoCountries[Countries.SE] = "Svedio";
EsperantoCountries[Countries.GB] = "Unuiĝinta Reĝlando";

var EsperantoAutomatchQuestions = {};
var EsperantoAutomatchValues = {};

EsperantoAutomatchQuestions[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS] = "Ludantkvanto";
EsperantoAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS] = {};
EsperantoAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.REQUIRE] = "2P";
EsperantoAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.EITHER] = "2P aŭ 3P";
EsperantoAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.REJECT] = "3P";

EsperantoAutomatchQuestions[AUTOMATCH_QUESTION.RATED_GAME] = "Poentaj ludoj";
EsperantoAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME] = {};
EsperantoAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.REQUIRE] = "Poenta";
EsperantoAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.EITHER] = "Poenta aŭ Nepoenta";
EsperantoAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.REJECT] = "Nepoenta";

EsperantoAutomatchQuestions[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME] = "Ekstraj Prirabado";
EsperantoAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME] = {};
EsperantoAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.REQUIRE] = "Jes";
EsperantoAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.EITHER] = "Jes aŭ Ne";
EsperantoAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.REJECT] = "Ne";

EsperantoAutomatchQuestions[AUTOMATCH_QUESTION.PREVIEW_GAME] = "2e Previews";
EsperantoAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME] = {};
EsperantoAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.REQUIRE] = "Jes";
EsperantoAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.REJECT] = "Ne";
EsperantoAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.EITHER] = "Jes aŭ Ne";