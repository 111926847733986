"use strict";

var publicTableService = void 0;

webclient.service('tableService', ['$rootScope', 'meta', 'metaBroadcaster', 'serverMessenger', 'relations', function ($rootScope, meta, metaBroadcaster, serverMessenger, relations) {
    var self = this;
    var scope = $rootScope.$new(true);
    var tableRuleStrings = [];
    self.chatLines = [];
    self.message = "";

    publicTableService = self;

    self.hasTable = function () {
        return !isUndefined(meta.model.me.tableDetails) && meta.model.me.tableDetails !== "";
    };

    self.getTable = function () {
        if (!self.hasTable()) throw new Error("Requesting table but none was found.");
        return meta.model.me.tableDetails;
    };

    self.getRuleValue = function (id) {
        if (self.hasTable()) {
            var t = self.getTable();
            var r = t.getRule(id);
            if (isUndefined(r)) {
                console.log("uh oh could not find " + id);
                return false;
            }
            return r.value;
        } else {
            return [];
        }
    };

    self.getRuleArguments = function (id) {
        var t = self.getTable();
        var r = t.getRule(id);
        return r.arguments;
    };

    self.changeRule = function (rule) {
        var id = self.getTableId();
        serverMessenger.changeTableRule(id, rule);
    };

    self.leave = function () {
        return serverMessenger.leaveTable(self.getTableId(), meta.model.me.id);
    };
    self.kick = function (id) {
        return serverMessenger.leaveTable(self.getTableId(), id);
    };
    self.ready = function () {
        return serverMessenger.startGameRequest(self.getTableId(), true);
    };
    self.notReady = function () {
        return serverMessenger.startGameRequest(self.getTableId(), false);
    };
    self.continueWithBots = function () {
        return serverMessenger.continueWithBots(self.getTableId());
    };

    self.clearTable = function () {
        meta.model.me.tableDetails = "";
        meta.model.me.tableId = -1;
    };

    scope.$on(Events.TABLE_STATE_CHANGE, function (event, stateChange) {
        changeState(stateChange);
        metaBroadcaster.send(stateChange.type, stateChange.change);
    });

    scope.$on(Events.AUTOMATCH_FOUND, function (event, automatchFound) {
        joinTable(automatchFound.tableDetails);
    });

    scope.$on(Events.TABLE_TERMINATED, function () {
        return self.clearTable();
    });

    function changeState(stateChange) {
        var type = stateChange.type;
        var change = stateChange.change;
        if (type === Events.TABLE_JOINED) {
            joinTable(change);
        } else if (type === Events.TABLE_DETAILS) {
            if (isUndefined(meta.model.me.tableDetails)) return;
            if (meta.model.me.tableDetails.tableId !== change.tableId) return;
            meta.model.me.tableDetails = change;
        } else if (type === Events.GAME_FINISHED) {
            meta.model.me.tableDetails = change.tableDetails;
            self.gameResult = change.gameResult;
            self.continueAllowed = change.continueAllowed;
            console.log("storing the game result %o", self.gameResult);
        } else if (type === Events.PLAYER_JOINED) {
            addPlayer(change);
        } else if (type === Events.PLAYER_LEFT) {
            removePlayer(change);
        } else if (type === Events.BOT_ADDED) {
            addBot(change);
        } else if (type === Events.TABLE_RULE_CHANGED) {
            changeTableRule(change);
        } else if (type === Events.NEW_ROLE) {
            newRole(change);
        } else if (type === Events.HOST_CHANGED) {
            changeHost(change);
        }
    }

    function joinTable(tableDetails) {
        meta.model.me.tableDetails = tableDetails;
    }

    function changeTableRule(ruleChange) {
        if (!self.hasTable()) return;
        var t = self.getTable();
        if (ruleChange.tableId !== t.tableId) throw new Error("Adding rule to a table that isn't mine.");
        t.changeRule(ruleChange.rule);
        console.log("Table service processing the new table rule %o", ruleChange);
        unReadyPlayers();
        $rootScope.$digest();
    }

    function newRole(newRole) {
        if (self.getTableId() !== newRole.tableId) return;
        var t = self.getTable();
        t.processRoleChange(newRole.playerId, newRole.isPlayer);
        t.setReady(newRole.playerId, newRole.readyToStart);
        if (newRole.isPlayer) {
            addIdToOrder(newRole.playerId);
        } else {
            removeIdFromOrder(newRole.playerId);
        }
        $rootScope.$digest();
    }

    function changeHost(hostChange) {
        if (self.getTableId() !== hostChange.tableId) return;
        var t = self.getTable();
        t.setHost(hostChange.newHost);
        $rootScope.$digest();
    }

    function unReadyPlayers() {
        if (!self.hasTable()) return;
        var t = self.getTable();
        t.readyToStart = t.readyToStart.map(function (r) {
            return false;
        });
    }

    function addIdToOrder(id) {
        var order = self.getRuleValue(TableRuleIds.PLAYER_ORDER);
        if (order.players.some(function (entry) {
            return entry.namedId.id === id;
        })) return;
        var namedId = new NamedId(id, "");
        if (self.heroIsHost()) {
            if (order.players.length > 0) {
                order.players.push(new OrderedPlayer(namedId, order.players.length));
                self.changeRule(new TableRule(TableRuleIds.PLAYER_ORDER, order));
            }
        }
    }

    function removeIdFromOrder(id) {
        var namedId = new NamedId(id, "");
        if (self.heroIsHost()) {
            var order = self.getRuleValue(TableRuleIds.PLAYER_ORDER);
            if (order.players.length > 0) {
                var index = order.players.findIndex(function (p) {
                    return p.namedId.id === id;
                });
                if (index > -1) {
                    var targetOrder = order.players[index].order;
                    order.players.forEach(function (p) {
                        if (p.order > targetOrder) {
                            p.order--;
                        }
                    });
                    order.players.splice(index, 1);
                    self.changeRule(new TableRule(TableRuleIds.PLAYER_ORDER, order));
                }
            }
        }
    }

    function addPlayer(joinInfo) {
        meta.extractPlayerName(joinInfo);
        if (meta.model.me.tableDetails.tableId !== joinInfo.tableId) return;
        if (joinInfo.isPlayer) {
            self.getTable().addPlayer(new Person(joinInfo.playerId, joinInfo.playerName));
            addIdToOrder(joinInfo.playerId);
        } else {
            self.getTable().addSpectator(new Person(joinInfo.playerId, joinInfo.playerName));
        }
        $rootScope.safeApply();
    }

    function addBot(botAdded) {
        if (!self.hasTable()) return;
        if (meta.model.me.tableDetails.tableId !== botAdded.tableId) return;
        self.getTable().addBot(botAdded.namedId);
        addIdToOrder(botAdded.namedId.id);
        $rootScope.safeApply();
    }

    function removePlayer(playerLeft) {
        self.leaveInfo = playerLeft;
        if (meta.model.me.tableDetails.tableId !== playerLeft.tableId) return;
        self.getTable().removePlayer(playerLeft.playerId);
        self.getTable().removeSpectator(playerLeft.playerId);
        self.getTable().removeBot(playerLeft.playerId);
        removeIdFromOrder(playerLeft.playerId);
        if (playerLeft.is(meta.model.me.id)) {
            self.clearTable();
            $rootScope.$broadcast(Events.RETURN_TO_LOBBY);
        }
        $rootScope.safeApply();
    }

    self.isHost = function (id) {
        if (!self.hasTable()) return false;
        return meta.model.me.tableDetails.isHost(id);
    };

    self.heroIsReady = function () {
        if (!self.hasTable()) return false;
        var t = self.getTable();
        return t.isReady(meta.getMyId());
    };

    self.isReady = function (id) {
        if (!self.hasTable()) return false;
        var t = self.getTable();
        return t.isReady(id);
    };

    self.heroIsHost = function () {
        return self.isHost(meta.getMyId());
    };
    self.getTableId = function () {
        return self.hasTable() ? meta.model.me.tableDetails.tableId : -1;
    };
    self.shouldShowVPCounter = function () {
        if (!self.hasTable()) return true;
        return self.getRuleValue(TableRuleIds.SHOW_VP_COUNTER);
    };
    self.isPlayer = function (id) {
        if (!self.hasTable()) return false;
        var t = self.getTable();
        return t.isPlayer(id);
    };
    self.isBot = function (id) {
        if (!self.hasTable()) return false;
        var t = self.getTable();
        return t.isBot(id);
    };
    self.isBotGame = function () {
        if (!self.hasTable()) return false;
        var t = self.getTable();
        return t.isBotGame();
    };
    self.heroIsPlayer = function () {
        return self.isPlayer(meta.model.me.id);
    };
    self.hasOpenSeats = function () {
        if (!self.hasTable()) return false;
        return self.getTable().hasOpenSeats();
    };
    self.openSeatCount = function () {
        if (!self.hasTable()) return false;
        return self.getTable().openSeatCount();
    };
    self.sitDown = function () {
        return serverMessenger.sitDown(self.getTableId());
    };
    self.standUp = function () {
        return serverMessenger.standUp(self.getTableId(), meta.model.me.id);
    };
    self.addBot = function () {
        return serverMessenger.addBot(self.getTableId());
    };
    self.getSpectatorIds = function () {
        if (self.hasTable()) {
            var rawList = self.getTable().spectators;
            return rawList.filter(function (id) {
                return !relations.isBlacklisted(id);
            });
        } else {
            return [];
        }
    };
    self.hasChangedRules = function () {
        if (!self.hasTable()) return false;
        return self.getTable().hasChangedRules();
    };

    self.getPlayerNameById = function (id) {
        if (!self.hasTable()) return "NO_TABLE_NO_NAME";
        return meta.model.me.tableDetails.getPlayerNameById(id);
    };

    self.getPlayers = function () {
        if (!self.hasTable()) return [];
        return self.getTable().getPlayers();
    };

    self.getBeings = function () {
        if (!self.hasTable()) return [];
        return self.getTable().getBeings();
    };

    self.getSpectatorNameById = function (id) {
        if (!self.hasTable()) return "NO_TABLE_NO_SPEC";
        return meta.model.me.tableDetails.getSpectatorNameById(id);
    };
}]);