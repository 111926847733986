"use strict";

webclient.component("userPrefBoolean", {
    bindings: {
        pref: "="
    },
    controllerAs: "userPref",
    controller: ['preferences', function (preferences) {
        var userPref = this;

        userPref.toggleBoolean = function (pref) {
            pref.arguments = !pref.arguments;
            preferences.setUserPreference(pref);
        };
    }],
    template: "<div class=\"switch-button\"\n                  ng-class=\"{active:userPref.pref.arguments}\"\n                  ng-click=\"userPref.toggleBoolean(userPref.pref)\">\n                    <div class=\"switch-button-slider\">\n                    </div>\n                </div>"
});