"use strict";

webclient.component("renderedText", {
	bindings: {
		text: "<"
	},
	controller: function controller() {
		var self = this;
		self.blocks = [];
		self.cachedText = "";
		self.getBlocks = function () {
			if (self.text === self.cachedText) return self.blocks;
			var blocks = [];
			var text = self.text;

			while (text.length > 0) {
				var match = text.match(/^([^\[]+)/);
				if (match) {
					blocks.push({ isText: true, text: match[1] });
					text = text.slice(match[0].length);
				}
				match = text.match(/^\[(.*?)\]/);
				if (match) {
					var cost = { coin: 0, potion: 0, debt: 0 };
					var forceShow = "";
					if (match[1] === "P") cost.potion = 1;else if (match[1].match(/\d+D/)) cost.debt = parseInt(match[1].match(/(\d+)D/)[1]);else if (match[1] === "D") forceShow = "debt";else if (match[1] === "") forceShow = "coin";else cost.coin = parseInt(match[1]);
					blocks.push({ isText: false, cost: cost, forceShow: forceShow });
					text = text.slice(match[0].length);
				}
			}
			self.cachedText = self.text;
			self.blocks = blocks;
			return blocks;
		};

		self.trackBy = function (b, i) {
			if (b.isText) return b.text;else {
				return i + "#" + b.cost.coin + b.cost.potion + b.cost.debt;
			}
		};
	},
	template: "\n        <span ng-repeat-start=\"b in $ctrl.getBlocks() track by $index\" ng-if=\"b.isText\">{{b.text}}</span><cost-container\n            ng-repeat-end\n            \n            ng-if=\"!b.isText\"\n            coin=\"b.cost.coin\"\n            potion=\"b.cost.potion\"\n            debt=\"b.cost.debt\"\n            force-show=\"b.forceShow\">\n        </cost-container>\n    "
});