"use strict";

webclient.component("messageList", {
    controller: ['inboxService', function (inboxService) {
        var self = this;

        self.hasSummaries = function () {
            return inboxService.hasSummaries();
        };
        self.getMessages = function () {
            return inboxService.getSummaries();
        };
        self.getMessageStyles = function (message) {
            var styles = [];
            if (message.messageId === inboxService.getSelectedId()) {
                styles.push("selected-message");
            }
            if (message.status === MailStati.READ) {
                styles.push("has-read");
            }
            return styles.join(' ');
        };
        self.selectMessage = function (m) {
            return inboxService.selectMessage(m.messageId);
        };
        self.getMessagesText = function () {
            return getPhrase(Phrases.MESSAGES);
        };
    }],
    template: "\n            <div class=\"message-list\">\n                <div class=\"message-list-header\" ng-if=\"$ctrl.hasSummaries()\">\n                    {{$ctrl.getMessagesText()}}\n                </div>                \n                <div class=\"message-list-inner\">\n                    <div ng-repeat=\"m in $ctrl.getMessages()\" class=\"message-list-item {{$ctrl.getMessageStyles(m)}}\" ng-click=\"$ctrl.selectMessage(m)\">{{m.subject}}</div>\n                </div>\n            </div>         \n            "
});