"use strict";
// Like Phrase but multiline

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Paragraph = function () {
  function Paragraph(lines) {
    _classCallCheck(this, Paragraph);

    this.lines = lines;
  }

  _createClass(Paragraph, [{
    key: "toHTML",
    value: function toHTML() {
      var htmlTagName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;


      var parts = this.lines.map(function (line) {
        return isUndefined(htmlTagName) ? line : "<" + htmlTagName + ">" + line + "</" + htmlTagName + ">";
      });
      var html = parts.join(' ');

      return html;
    }
  }], [{
    key: "checkKeyConsistency",
    value: function checkKeyConsistency() {

      var keys = Object.keys(Paragraphs);
      var languageKeys = undefined;

      LANGUAGES.forEach(function (language) {

        languageKeys = Object.keys(language.paragraphs);

        assert(sameSet(keys, languageKeys));
      });
    }
  }]);

  return Paragraph;
}();

// When you make a new paragraph, add its key to this map and to the language
// specific map for each language in the ./LANGUAGE/paragraphs.js file.


var Paragraphs = {

  STORE_INTRO: undefined,
  STORE_BUYING: undefined,
  STORE_SUBSCRIPTIONS: undefined,
  STORE_DOLLARS: undefined
};

var EnglishParagraphs = {};
var FrenchParagraphs = {};
var GermanParagraphs = {};
var JapaneseParagraphs = {};
var RussianParagraphs = {};
var TChineseParagraphs = {};
var DutchParagraphs = {};
var SpanishParagraphs = {};
var CzechParagraphs = {};
var EsperantoParagraphs = {};