"use strict";

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CleanupWindow = function (_RenderableObject) {
    _inherits(CleanupWindow, _RenderableObject);

    function CleanupWindow(game) {
        _classCallCheck(this, CleanupWindow);

        var _this = _possibleConstructorReturn(this, (CleanupWindow.__proto__ || Object.getPrototypeOf(CleanupWindow)).call(this));

        _this.game = game;
        _this.state = _this.game.state;
        _this.bbox = new Rectangle(0, 0, 0, 0);
        _this.customBbox = false;
        _this.cleanupButtons = [];
        _this.windowView = new WindowView(_this);
        return _this;
    }

    _createClass(CleanupWindow, [{
        key: "newButtons",
        value: function newButtons(buttons) {
            var _cleanupButtons,
                _this2 = this;

            (_cleanupButtons = this.cleanupButtons).push.apply(_cleanupButtons, _toConsumableArray(buttons));
            buttons.forEach(function (b) {
                return b.cleanupWindow = _this2;
            });
            this.cleanupButtons.slice(1).forEach(function (button) {
                var idx = _this2.cleanupButtons.indexOf(button);
                if (!button.grabbing) {
                    if (button.merge(_this2.cleanupButtons[idx - 1])) {
                        _this2.cleanupButtons.splice(idx, 1);
                        _this2.game.questionModel.removeButton(button);
                    }
                }
            });
            if (buttons.length > 0) this.updateAnswers(buttons[0].question);
            this.show();
        }
    }, {
        key: "show",
        value: function show() {
            var vh = window.innerHeight / 100;
            var vw = window.innerWidth / 100;
            this.bbox.zIndex = 13000;

            var width = 48 * vh;
            var height = Math.min(65, 12 + 9 * this.cleanupButtons.length) * vh;
            var x = ((LEFT_COLUMN_WIDTH + (100 - RIGHT_COLUMN_WIDTH)) * vw - width) / 2;
            var y = MAIN_SUPPLY_HEIGHT / 2 * vh;

            if (!this.customBbox) this.repositionWindow(x, y, width, height);else this.reposition();
        }
    }, {
        key: "hide",
        value: function hide() {
            this.cleanupButtons.forEach(function (i) {
                return i.canvas.remove();
            });
            this.cleanupButtons = [];
            this.windowView.redraw();
        }
    }, {
        key: "repositionWindow",
        value: function repositionWindow(x, y, width, height) {
            this.bbox.x = x;
            this.bbox.y = y;
            this.bbox.width = width;
            this.bbox.height = height;
            this.reposition();
        }
    }, {
        key: "reposition",
        value: function reposition() {
            var _this3 = this;

            var vh = window.innerHeight / 100;
            var vw = window.innerWidth / 100;

            var left = this.bbox.x + this.bbox.width * 0.05;
            var right = this.bbox.x + this.bbox.width * 0.95;
            var width = 40 * vh;
            var x = Math.max(this.bbox.x + this.bbox.width * 0.025, (left + right - width) / 2);
            var y = this.bbox.y + 8 * vh;

            this.cleanupButtons.forEach(function (item, index) {
                var width = right - left;
                var height = Math.min((_this3.bbox.height - 12 * vh) / _this3.cleanupButtons.length, 8 * vh);
                var zIndex = _this3.bbox.zIndex + 5 + index;

                item.repositionCanvas(x, y, zIndex);
                item.redraw();
                y += height;
            });

            this.windowView.redraw();
        }
    }, {
        key: "updateAnswers",
        value: function updateAnswers(question) {
            question.clearAnswers();
            this.cleanupButtons.forEach(function (button) {
                button.cleanupItems.forEach(function (item) {
                    if (item.isEnabled) {
                        question.accumulatedAnswers.push(item.index);
                    }
                });
            });
            question.updateButtons();
        }
    }, {
        key: "visible",
        get: function get() {
            return this.cleanupButtons.length > 0;
        }
    }, {
        key: "showCauses",
        get: function get() {
            return this.cleanupButtons;
        }
    }, {
        key: "zoneName",
        get: function get() {
            return Phrases.PLAN_CLEAN_UP;
        }
    }]);

    return CleanupWindow;
}(RenderableObject);

var CleanupButtonView = function (_GameButtonView) {
    _inherits(CleanupButtonView, _GameButtonView);

    function CleanupButtonView(question, answer, label, cleanupItem) {
        _classCallCheck(this, CleanupButtonView);

        var _this4 = _possibleConstructorReturn(this, (CleanupButtonView.__proto__ || Object.getPrototypeOf(CleanupButtonView)).call(this, question, answer, label));

        _this4.cleanupItems = [cleanupItem];
        _this4.cardNames = cleanupItem.cardNames;
        _this4.bgURLs = _this4.cardNames.map(function (cardName) {
            return getFullArtURL(cardName);
        });
        _this4.bgURLs.push('images/elements/card-counter.png');
        _this4.isOptional = cleanupItem.isOptional;

        _this4.keepHovered = false;
        _this4.changeHovered = false;
        _this4.keepHoverState = 0;
        _this4.changeHoverState = 0;
        _this4.grabbing = false;
        return _this4;
    }

    _createClass(CleanupButtonView, [{
        key: "updateAnswers",
        value: function updateAnswers() {
            this.cleanupWindow.updateAnswers(this.question);
        }
    }, {
        key: "render",
        value: function render(node) {
            var _this5 = this;

            _get(CleanupButtonView.prototype.__proto__ || Object.getPrototypeOf(CleanupButtonView.prototype), "render", this).call(this, node);
            this.canvas.onmousemove = function (e) {
                var _canvas$getBoundingCl = _this5.canvas.getBoundingClientRect(),
                    width = _canvas$getBoundingCl.width,
                    height = _canvas$getBoundingCl.height;

                var keepHovered = _this5.keepHovered;
                var changeHovered = _this5.changeHovered;
                if (!_this5.isOptional) {
                    _this5.keepHovered = false;
                    _this5.changeHovered = false;
                    _this5.canvas.style.cursor = _this5.allowsReordering ? "grab" : "default";
                } else {
                    if (e.offsetX < 1.3 * height || e.offsetX < width - 1.3 * height && e.offsetY > height / 2 && e.offsetY < 0.8 * height) {
                        _this5.keepHovered = true;
                        _this5.changeHovered = false;
                        _this5.canvas.style.cursor = "pointer";
                    } else if (e.offsetX > width - 1.3 * height || e.offsetX > height && e.offsetY < height / 2 && e.offsetY > 0.2 * height) {
                        _this5.changeHovered = true;
                        _this5.keepHovered = false;
                        _this5.canvas.style.cursor = "pointer";
                    } else {
                        _this5.keepHovered = false;
                        _this5.changeHovered = false;
                        _this5.canvas.style.cursor = _this5.allowsReordering ? "grab" : "default";
                    }
                }
                if (keepHovered !== _this5.keepHovered || changeHovered !== _this5.changeHovered) _this5.tick();
            };
            this.canvas.onmouseout = function () {
                _this5.hovered = false;
                _this5.keepHovered = false;
                _this5.changeHovered = false;
                _this5.tick();
            };

            var mouseDownHandler = function mouseDownHandler(offsetX, offsetY) {
                if (!_this5.keepHovered && !_this5.changeHovered && _this5.allowsReordering) {
                    var movingButton = _this5.splice();
                    if (movingButton === null) movingButton = _this5;
                    movingButton.canvas.style.cursor = "grabbing";
                    var moveListener = function moveListener(eventX, eventY) {
                        var x = eventX - offsetX;
                        var y = eventY - offsetY;
                        movingButton.repositionCanvas(x, y);

                        var upButtons = _this5.cleanupWindow.cleanupButtons.filter(function (b) {
                            return !b.allowsReordering || b.canvas.getBoundingClientRect().y < y;
                        });
                        var index = _this5.cleanupWindow.cleanupButtons.indexOf(movingButton);
                        if (upButtons.length !== index) {
                            _this5.cleanupWindow.cleanupButtons.splice(index, 1);
                            var lastButton = upButtons[upButtons.length - 1];
                            _this5.cleanupWindow.cleanupButtons.splice(_this5.cleanupWindow.cleanupButtons.indexOf(lastButton) + 1, 0, movingButton);
                            _this5.cleanupWindow.reposition();
                            _this5.updateAnswers();
                        }
                    };
                    var mouseMoveHandler = function mouseMoveHandler(e) {
                        return moveListener(e.clientX, e.clientY);
                    };
                    var touchMoveHandler = function touchMoveHandler(e) {
                        return moveListener(e.touches[0].clientX, e.touches[0].clientY);
                    };
                    var dropListener = function dropListener() {
                        document.removeEventListener("mousemove", mouseMoveHandler);
                        document.removeEventListener("touchmove", touchMoveHandler);
                        document.removeEventListener("mouseup", dropListener);
                        document.removeEventListener("touchend", dropListener);
                        document.removeEventListener("touchcancel", dropListener);
                        movingButton.grabbing = false;
                        _this5.cleanupWindow.newButtons([]);
                        movingButton.canvas.style.cursor = movingButton.allowsReordering ? "grab" : "default";
                    };
                    document.addEventListener("mousemove", mouseMoveHandler);
                    document.addEventListener("touchmove", touchMoveHandler);
                    document.addEventListener("mouseup", dropListener);
                    document.addEventListener("touchend", dropListener);
                    document.addEventListener("touchcancel", dropListener);
                }
            };

            this.canvas.onmousedown = function (e) {
                return mouseDownHandler(e.offsetX, e.offsetY);
            };
            this.canvas.ontouchstart = function (e) {
                return mouseDownHandler(e.touches[0].clientX - e.target.getBoundingClientRect().x, e.touches[0].clientY - e.target.getBoundingClientRect().y);
            };
        }
    }, {
        key: "resizeCanvas",
        value: function resizeCanvas() {
            var vh = window.innerHeight / 100;
            var width = Math.min(40 * vh, this.cleanupWindow.bbox.width * 0.95);
            var height = Math.min((this.cleanupWindow.bbox.height - 12 * vh) / this.cleanupWindow.cleanupButtons.length - vh, 8 * vh);

            this.canvas.style.position = "fixed";
            this.canvas.style.width = width + "px";
            this.canvas.style.height = height + "px";

            this.canvas.width = width * 2;
            this.canvas.height = height * 2;
        }
    }, {
        key: "tick",
        value: function tick() {
            var _this6 = this;

            var keepTicking = false;
            if (this.keepHovered) {
                this.keepHoverState += 0.1;
                if (this.keepHoverState > 1) this.keepHoverState = 1;else keepTicking = true;
            } else {
                this.keepHoverState -= 0.1;
                if (this.keepHoverState < 0) this.keepHoverState = 0;else keepTicking = true;
            }

            if (this.changeHovered) {
                this.changeHoverState += 0.1;
                if (this.changeHoverState > 1) this.changeHoverState = 1;else keepTicking = true;
            } else {
                this.changeHoverState -= 0.1;
                if (this.changeHoverState < 0) this.changeHoverState = 0;else keepTicking = true;
            }
            if (this.hovered) {
                this.hoverState += 0.1;
                if (this.hoverState > 1) this.hoverState = 1;else keepTicking = true;
            } else {
                this.hoverState -= 0.1;
                if (this.hoverState < 0) this.hoverState = 0;else keepTicking = true;
            }
            this.redraw();
            if (keepTicking) requestAnimationFrame(function () {
                return _this6.tick();
            });
        }
    }, {
        key: "merge",
        value: function merge(other) {
            var _this7 = this;

            if (other.cardNames.length === this.cardNames.length && other.cardNames.every(function (name, i) {
                return _this7.cardNames[i] === name;
            }) && other.cleanupAction == this.cleanupAction) {
                var _other$cleanupItems;

                (_other$cleanupItems = other.cleanupItems).push.apply(_other$cleanupItems, _toConsumableArray(this.cleanupItems));
                other.redraw();
                this.rendered = true; // prevents double rendering
                this.canvas.remove();
                return true;
            }
            return false;
        }
    }, {
        key: "splice",
        value: function splice() {
            if (this.cleanupItems.length > 1) {
                var cleanupItem = this.cleanupItems.pop();
                var newButton = new CleanupButtonView(this.question, this.answer, "", cleanupItem);
                newButton.cleanupWindow = this.cleanupWindow;
                newButton.grabbing = true;
                this.question.game.questionModel.newButtons([newButton]);
                newButton.repositionCanvas(this.canvas.getBoundingClientRect().x, this.canvas.getBoundingClientRect().y);
                return newButton;
            }
            return null;
        }
    }, {
        key: "onClick",
        value: function onClick(e) {
            if (this.keepHovered) {
                var target = this.cleanupItems.find(function (i) {
                    return i.isEnabled;
                });
                if (!isUndefined(target)) {
                    target.isEnabled = false;
                    this.updateAnswers();

                    if (this.question.max === 1 && shouldUseImmediateSendExceptions) this.question.send();
                }
            } else if (this.changeHovered) {
                var _target = this.cleanupItems.find(function (i) {
                    return !i.isEnabled;
                });
                if (!isUndefined(_target)) {
                    _target.isEnabled = true;
                    this.updateAnswers();

                    if (this.question.max === 1 && shouldUseImmediateSendExceptions) this.question.send();
                }
            }
            this.redraw();
        }
    }, {
        key: "redrawBackground",
        value: function redrawBackground() {
            var _ref = [this.canvas.width, this.canvas.height, this.context],
                w = _ref[0],
                h = _ref[1],
                ctx = _ref[2];

            var r = h * 0.1;
            ctx.lineWidth = r / 3;
            ctx.fillStyle = rgbToHex(25 * this.hoverState, 10 * this.hoverState, 0 * this.hoverState);
            ctx.strokeStyle = "white";
            ctx.clearRect(0, 0, w, h);
            ctx.beginPath();
            ctx.rect(0, 0, w, h);
            ctx.globalAlpha = 0.8 + 0.15 * Curves.SYMMETRIC.getYFromX(this.hoverState);
            ctx.fill();

            if (this.cleanupItems[0].allowsReordering) {
                ctx.fillStyle = "white";
                ctx.beginPath();
                ctx.moveTo(w / 2, 0.98 * h);
                ctx.lineTo(w / 2 - 0.1 * h, 0.88 * h);
                ctx.lineTo(w / 2 + 0.1 * h, 0.88 * h);
                ctx.fill();
                ctx.fillStyle = "white";
                ctx.beginPath();
                ctx.moveTo(w / 2, 0.02 * h);
                ctx.lineTo(w / 2 - 0.1 * h, 0.12 * h);
                ctx.lineTo(w / 2 + 0.1 * h, 0.12 * h);
                ctx.fill();
                ctx.globalAlpha = 1;
            }
            this.redrawCards();
        }
    }, {
        key: "redrawCards",
        value: function redrawCards() {
            var _this8 = this;

            var _ref2 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref2[0],
                h = _ref2[1],
                ctx = _ref2[2];

            var height = h;
            var width = 1.3 * height;
            var x = (w - width) / 2;
            if (this.cardNames.length === 0) this.redrawText();else {
                Preloader.loadAll(this.bgURLs, function (sources) {
                    ctx.strokeStyle = "white";
                    ctx.lineWidth = 0.02 * h;
                    if (_this8.isOptional) {
                        var size = h * 0.33;
                        var source = sources[sources.length - 1];
                        var changeCount = _this8.cleanupItems.filter(function (i) {
                            return i.isEnabled;
                        }).length;
                        ctx.filter = changeCount === 0 && _this8.cardNames.length > 1 ? "grayscale(100%)" : "none";
                        ctx.globalAlpha = changeCount > 0 ? 1 : _this8.changeHoverState * 0.4 + 0.6;

                        if (_this8.cardNames.length === 1) {
                            drawFilledImage(ctx, sources[0], w - width, 0, width, height, false);
                        } else {
                            drawFilledImage(ctx, sources[1], w - width, 0, width, height, false);
                        }
                        if (changeCount > 0) {
                            ctx.drawImage(source, w - width, 0, size, size);
                            ctx.fillStyle = "white";
                            drawFittedText(ctx, changeCount, new Rectangle(w - width + size * 0.1, size * 0.1, size * 0.8, size * 0.8), 1, "Montserrat");
                        }
                        ctx.globalAlpha = _this8.changeHoverState * 0.4 + 0.6;
                        ctx.beginPath();
                        ctx.rect(w - width, 0, width, height);
                        ctx.stroke();
                        ctx.beginPath();
                        ctx.moveTo(0.3 * w, 0.43 * h);
                        ctx.lineTo(0.7 * w, 0.43 * h);
                        ctx.lineTo(0.7 * w - 0.1 * h, 0.3 * h);
                        ctx.stroke();
                        ctx.globalAlpha = 1;

                        var keepCount = _this8.cleanupItems.filter(function (i) {
                            return !i.isEnabled;
                        }).length;
                        ctx.filter = keepCount === 0 || _this8.cardNames.length === 1 ? "grayscale(100%)" : "none";
                        ctx.globalAlpha = keepCount > 0 ? 1 : _this8.keepHoverState * 0.4 + 0.6;
                        drawFilledImage(ctx, sources[0], 0, 0, width, height, false);
                        if (keepCount > 0) {
                            ctx.filter = "none";
                            ctx.drawImage(source, 0, 0, size, size);
                            ctx.fillStyle = "white";
                            drawFittedText(ctx, keepCount, new Rectangle(size * 0.1, size * 0.1, size * 0.8, size * 0.8), 1, "Montserrat");
                        }
                        ctx.globalAlpha = _this8.keepHoverState * 0.4 + 0.6;
                        ctx.beginPath();
                        ctx.rect(0, 0, width, height);
                        ctx.stroke();
                        ctx.strokeStyle = "white";
                        ctx.beginPath();
                        ctx.moveTo(0.7 * w, 0.57 * h);
                        ctx.lineTo(0.3 * w, 0.57 * h);
                        ctx.lineTo(0.3 * w + 0.1 * h, 0.7 * h);
                        ctx.stroke();
                        ctx.filter = "none";
                    } else {
                        if (_this8.cardNames.length === 1) {
                            drawFilledImage(ctx, sources[0], (w - width) / 2, 0, width, height);
                            ctx.beginPath();
                            ctx.rect(x, (w - width) / 2, 0, width, height);
                            ctx.stroke();
                        } else {
                            var _size = h * 0.33;
                            var _source = sources[sources.length - 1];
                            if (_this8.cleanupItems.length > 1) {
                                ctx.drawImage(_source, 0, 0, _size, _size);
                                ctx.fillStyle = "white";
                                drawFittedText(ctx, _this8.cleanupItems.length, new Rectangle(_size * 0.1, _size * 0.1, _size * 0.8, _size * 0.8), 1, "Montserrat");
                            }
                            drawFilledImage(ctx, sources[0], w / 2 - width - 0.15 * h, 0, width, height, false);
                            ctx.beginPath();
                            ctx.rect(w / 2 - width - 0.15 * h, 0, width, height);
                            ctx.stroke();
                            drawFilledImage(ctx, sources[1], w / 2 + 0.15 * h, 0, width, height, false);
                            ctx.beginPath();
                            ctx.rect(w / 2 + 0.15 * h, 0, width, height);
                            ctx.stroke();
                        }
                    }

                    _this8.redrawText();
                });
            }
        }
    }, {
        key: "redrawText",
        value: function redrawText() {
            if (!this.isOptional) return;
            var _ref3 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref3[0],
                h = _ref3[1],
                ctx = _ref3[2];

            var topTextBbox = new Rectangle(0.3 * w, 0.15 * h, 0.4 * w, 0.25 * h);
            var bottomTextBbox = new Rectangle(0.3 * w, 0.65 * h, 0.4 * w, 0.25 * h);
            var texts = LANGUAGE.getCleanupAction[this.cleanupItems[0].cleanupAction];
            var titleFont = LANGUAGE.getEnvironment.hasOwnProperty("titleFont") ? LANGUAGE.getEnvironment.titleFont : "TrajanPro-Bold";
            ctx.globalAlpha = this.keepHoverState * 0.4 + 0.6;
            ctx.fillStyle = rgbToHex(150, 150, 150 + 100 * this.keepHoverState);
            drawFittedText(ctx, texts.disableText, bottomTextBbox, 0.8, titleFont);
            ctx.globalAlpha = this.changeHoverState * 0.4 + 0.6;
            ctx.fillStyle = rgbToHex(150, 150, 150 + 100 * this.changeHoverState);
            drawFittedText(ctx, texts.enableText, topTextBbox, 0.8, titleFont);
            ctx.globalAlpha = 1;
        }
    }, {
        key: "sortingGroup",
        get: function get() {
            return 0;
        }
    }, {
        key: "hasNormalLayout",
        get: function get() {
            return false;
        }
    }, {
        key: "allowsReordering",
        get: function get() {
            return this.cleanupItems[0].allowsReordering;
        }
    }]);

    return CleanupButtonView;
}(GameButtonView);