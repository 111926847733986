"use strict";

var RussianTypes = {};

RussianTypes[Types.ACTION] = "Действие";
RussianTypes[Types.TREASURE] = "Сокровище";
RussianTypes[Types.ATTACK] = "Атака";
RussianTypes[Types.CURSE] = "Проклятие";
RussianTypes[Types.DURATION] = "Длительность";
RussianTypes[Types.EVENT] = "Событие";
RussianTypes[Types.GATHERING] = "Скопление";
RussianTypes[Types.KNIGHT] = "Рыцарь";
RussianTypes[Types.LANDMARK] = "Веха";
RussianTypes[Types.LOOTER] = "Грабитель";
RussianTypes[Types.RUINS] = "Руины";
RussianTypes[Types.TRAVELLER] = "Путешественник";
RussianTypes[Types.PRIZE] = "Трофей";
RussianTypes[Types.RESERVE] = "Запас";
RussianTypes[Types.VICTORY] = "Победа";
RussianTypes[Types.REACTION] = "Реакция";
RussianTypes[Types.SHELTER] = "Убежище";
RussianTypes[Types.CASTLE] = "Замок";
RussianTypes[Types.FATE] = "Судьба";
RussianTypes[Types.NIGHT] = "Ночь";
RussianTypes[Types.DOOM] = "Рок";
RussianTypes[Types.HEIRLOOM] = "Наследие";
RussianTypes[Types.SPIRIT] = "Дух";
RussianTypes[Types.ZOMBIE] = "Зомби";
RussianTypes[Types.BOON] = "Благодеяние";
RussianTypes[Types.HEX] = "Порча";
RussianTypes[Types.STATE] = "Состояние";
RussianTypes[Types.PROJECT] = "Проект";
RussianTypes[Types.COMMAND] = "Команда";
RussianTypes[Types.WAY] = "Путь";
RussianTypes[Types.LIAISON] = "Связи";
RussianTypes[Types.ALLY] = "Союзник";
RussianTypes[Types.AUGUR] = "Авгур";
RussianTypes[Types.CLASH] = "Столкновение";
RussianTypes[Types.FORT] = "Форт";
RussianTypes[Types.ODYSSEY] = "Одиссея";
RussianTypes[Types.TOWNSFOLK] = "Горожанин";
RussianTypes[Types.WIZARD] = "Волшебник";
RussianTypes[Types.LOOT] = "Грабленое";
RussianTypes[Types.TRAIT] = "Черта";
RussianTypes[Types.REWARD] = "Награда";
RussianTypes[Types.SHADOW] = "Тень";
RussianTypes[Types.OMEN] = "Знамение";
RussianTypes[Types.PROPHECY] = "Пророчество";

var RussianZoneNames = {};

RussianZoneNames["HandZone"] = "Рука";
RussianZoneNames["DrawZone"] = "Колода";
RussianZoneNames["DiscardZone"] = "Сброс";
RussianZoneNames["TrashZone"] = "Свалка";
RussianZoneNames["SupplyZone"] = "Стопка Резерва";
RussianZoneNames["InPlayZone"] = "Игра";
RussianZoneNames["SetAsideZone"] = "Отложенные карты";
RussianZoneNames["TavernZone"] = "Мат Таверны";
RussianZoneNames["NVZone"] = "Мат Поселения Аборигенов";
RussianZoneNames["IslandZone"] = "Островной Мат";
RussianZoneNames["ExileZone"] = "Изгнание";
RussianZoneNames["RevealZone"] = "Раскрытые карты";
RussianZoneNames["SetAsideZone"] = "Отложенные карты";
RussianZoneNames["SkippableRevealZone"] = "Раскрытые карты";
RussianZoneNames["TheBox"] = "Коробка";

var RussianMovementTypes = {};

RussianMovementTypes[MovementTypes.PLAY] = new TranslatedMovementType("играть", "играет");
RussianMovementTypes[MovementTypes.DRAW] = new TranslatedMovementType("тянуть", "тянет");
RussianMovementTypes[MovementTypes.DISCARD] = new TranslatedMovementType("сбросить", "сбрасывает");
RussianMovementTypes[MovementTypes.GAIN] = new TranslatedMovementType("получить", "получает");
RussianMovementTypes[MovementTypes.TRASH] = new TranslatedMovementType("выкинуть", "выкидывает");
RussianMovementTypes[MovementTypes.TOPDECK] = new TranslatedMovementType("положить на верх колоды", "кладёт на верх колоды");
RussianMovementTypes[MovementTypes.REVEAL] = new TranslatedMovementType("раскрыть", "раскрывает");
RussianMovementTypes[MovementTypes.REVEAL_SEARCHING] = new TranslatedMovementType("раскрыть", "раскрывает");
RussianMovementTypes[MovementTypes.LOOK_AT] = new TranslatedMovementType("смотреть на", "смотрит на");
RussianMovementTypes[MovementTypes.PUT_IN_HAND] = new TranslatedMovementType("класть в руку", "кладёт в руку");
RussianMovementTypes[MovementTypes.SET_ASIDE] = new TranslatedMovementType("отложить", "откладывает");
RussianMovementTypes[MovementTypes.PUT_ON_MAT] = new TranslatedMovementType("положить на мат", "кладёт на мат");
RussianMovementTypes[MovementTypes.DECK_TO_DISCARD] = new TranslatedMovementType("раскрыть с колоды и положить в сброс", "раскрывает с колоды и кладёт в сброс");
RussianMovementTypes[MovementTypes.BACK_ON_DECK] = new TranslatedMovementType("положить назад на верх колоды", "кладёт назад на верх колоды");
RussianMovementTypes[MovementTypes.SHUFFLE_INTO_DECK] = new TranslatedMovementType("замешать в колоду", "замешивает в колоду");
RussianMovementTypes[MovementTypes.INSERT_IN_DECK] = new TranslatedMovementType("положить в колоду", "кладёт в колоду");
RussianMovementTypes[MovementTypes.EXCHANGE_RETURN] = new TranslatedMovementType("возвратить", "возвращает");
RussianMovementTypes[MovementTypes.EXCHANGE_RECEIVE] = new TranslatedMovementType("обменять на", "обменивает на");
RussianMovementTypes[MovementTypes.PASS] = new TranslatedMovementType("передать", "передаёт");
RussianMovementTypes[MovementTypes.RETURN_TO] = new TranslatedMovementType("возвратить в", "возвращает в");
RussianMovementTypes[MovementTypes.PUT_ON_BOTTOM_OF] = new TranslatedMovementType("положить в низ", "кладёт в низ");
RussianMovementTypes[MovementTypes.STARTS_WITH] = new TranslatedMovementType("начать с", "начинает с");
RussianMovementTypes[MovementTypes.TAKE] = new TranslatedMovementType("TAKE", "TAKE");
RussianMovementTypes[MovementTypes.GET_STATE] = new TranslatedMovementType("GET_STATE", "GET_STATE");
RussianMovementTypes[MovementTypes.RETURN_STATE] = new TranslatedMovementType("RETURN_STATE", "RETURN_STATE");
RussianMovementTypes[MovementTypes.EXILE] = new TranslatedMovementType("EXILE", "EXILE");

var RussianPileTokenTexts = {};

RussianPileTokenTexts[TokenNames.PLUS_ACTION] = "+Д";
RussianPileTokenTexts[TokenNames.PLUS_BUY] = "+П";
RussianPileTokenTexts[TokenNames.PLUS_CARD] = "+К";
RussianPileTokenTexts[TokenNames.PLUS_COIN] = "+1";
RussianPileTokenTexts[TokenNames.MINUS_COST] = "-2";
RussianPileTokenTexts[TokenNames.TRASH] = "X";

var RussianPileMarkers = {};

RussianPileMarkers[PileMarkerTypes.BANE] = "Погибель";
RussianPileMarkers[PileMarkerTypes.OBELISK] = "Обелиск";
RussianPileMarkers[PileMarkerTypes.SPLIT] = "Двойная";
RussianPileMarkers[PileMarkerTypes.MIXED] = "Смешанная";

var RussianTokenNames = {};

RussianTokenNames[TokenNames.PLUS_ACTION] = "Жетон +1 Действия";
RussianTokenNames[TokenNames.PLUS_BUY] = "Жетон +1 Покупки";
RussianTokenNames[TokenNames.PLUS_COIN] = "Жетон +1 Монеты";
RussianTokenNames[TokenNames.PLUS_CARD] = "Жетон +1 Карты";
RussianTokenNames[TokenNames.MINUS_COST] = "Жетон -2";
RussianTokenNames[TokenNames.TRASH] = "Жетон Свалки";
RussianTokenNames[TokenNames.JOURNEY] = "Жетон Путешествий";
RussianTokenNames[TokenNames.MINUS_CARD] = "Жетон -1 Карты";
RussianTokenNames[TokenNames.MINUS_COIN] = "Жетон -1 Монеты";

var RussianKingdomViewerNames = {};

RussianKingdomViewerNames[KingdomViewerGroups.SUPPLY_CARDS] = "Карты в резерве";
RussianKingdomViewerNames[KingdomViewerGroups.KINGDOM_LANDSCAPES] = "События, вехи, проекты, пути, союзники, черты и пророчества";
RussianKingdomViewerNames[KingdomViewerGroups.NON_KINGDOM] = "Карты вне резерва";
RussianKingdomViewerNames[KingdomViewerGroups.DRUID_BOONS] = "Благодеяния Друида";
RussianKingdomViewerNames[KingdomViewerGroups.KNIGHTS] = "Рыцари";
RussianKingdomViewerNames[KingdomViewerGroups.CASTLES] = "Замки";
RussianKingdomViewerNames[KingdomViewerGroups.BOONS_HEXES] = "Благодеяния и Порчи";
RussianKingdomViewerNames[KingdomViewerGroups.STATES_ARTIFACTS] = "Состояния и Артефакты";
RussianKingdomViewerNames[KingdomViewerGroups.BLACK_MARKET] = "Чёрный Рынок";
RussianKingdomViewerNames[KingdomViewerGroups.PRIZES] = "Трофеи";
RussianKingdomViewerNames[KingdomViewerGroups.REWARDS] = "Награды";
RussianKingdomViewerNames[KingdomViewerGroups.LOOT] = "Грабленое";
RussianKingdomViewerNames[KingdomViewerGroups.RUINS] = "Руины";