"use strict";

var RussianQuestionDescriptions = {};

RussianQuestionDescriptions[QuestionIds.GAME_RESOLVE_ABILITY] = ["Выполните обязательную инструкцию.", "(Выберите под журналом)"];
RussianQuestionDescriptions[QuestionIds.GAME_MAY_RESOLVE_ABILITY] = ["выполнить инструкцию.", "(Выберите под журналом)"];
RussianQuestionDescriptions[QuestionIds.GAME_MAY_REACT_WITH] = ["сыграть Реакцию.", "(Выберите под журналом)"];
RussianQuestionDescriptions[QuestionIds.GAME_TURN_SELECT] = ["Выбор хода.", "Какой ход играть следующим?"];
RussianQuestionDescriptions[QuestionIds.GAME_MAY_BUY] = ["делать покупки.", "Можете делать покупки."];
RussianQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING] = ["покупать карты.", "Можете покупать карты."];
RussianQuestionDescriptions[QuestionIds.GAME_MAY_BUY_NONCARDS] = ["Делать покупки (нельзя покупать карты).", "Можете делать покупки (События), но нельзя покупать карты."];
RussianQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING_NONCARDS] = ["Делать покупки (нельзя покупать карты)", "Можете делать покупки (События), но нельзя покупать карты."];
RussianQuestionDescriptions[QuestionIds.GAME_REPAY_HOW_MUCH] = ["заплатить долг.", "Можете заплатить долг"];
RussianQuestionDescriptions[QuestionIds.GAME_SPEND_COIN_TOKENS] = ["потратить золотые жетоны.", "Можете потратить золотые жетоны."];
RussianQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_ACTION] = ["сыграть карту Действия из руки.", "Можете играть Действия."];
RussianQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_TREASURES] = ["сыграть Сокровища.", "Можете сыграть Сокровища."];
RussianQuestionDescriptions[QuestionIds.GAME_PLAY_ALL_TREASURES] = ["сыграть все Сокровища.", "GAME_PLAY_ALL_TREASURES"];
RussianQuestionDescriptions[QuestionIds.GAME_ACTION_PHASE] = ["Фаза действий", "Можете играть Действия."];
RussianQuestionDescriptions[QuestionIds.GAME_BUY_PHASE] = ["Фаза покупок", "Можете покупать карты."];
RussianQuestionDescriptions[QuestionIds.GAME_BUY_PHASE_NONCARDS] = ["Фаза покупок", "Можете делать покупки (События), но нельзя покупать карты."];
RussianQuestionDescriptions[QuestionIds.GAME_CLEANUP_PHASE] = ["Выберите порядок действий.", "Выберите порядок выполнения для фазы расчистки."];
RussianQuestionDescriptions[QuestionIds.GAME_USE_VILLAGER] = ["Можете использовать Хуторянина.", "Можете использовать Хуторянина."];
RussianQuestionDescriptions[QuestionIds.GAME_USE_COFFERS] = ["Можете использовать Монетки.", "Можете использовать Монетки"];
RussianQuestionDescriptions[QuestionIds.ARTISAN_GAIN] = ["Получите в руку карту не дороже 5$.", "Получите карту."];
RussianQuestionDescriptions[QuestionIds.ARTISAN_TOPDECK] = ["Положите карту из руки на верх колоды.", "Положите карту на верх колоды."];
RussianQuestionDescriptions[QuestionIds.BANDIT] = ["Выкиньте карту PLAYER0.", "Выкиньте карту PLAYER0."];
RussianQuestionDescriptions[QuestionIds.BUREAUCRAT] = ["Раскройте карту Победы из руки и положите её на верх колоды.", "Положите карту Победы на верх колоды."];
RussianQuestionDescriptions[QuestionIds.CELLAR] = ["Сбросьте любое число карт, затем вытяните столько же.", "Сбросьте, затем тяните карты."];
RussianQuestionDescriptions[QuestionIds.CHAPEL] = ["Выкиньте до четырёх карт из руки.", "Выкиньте до четырёх карт."];
RussianQuestionDescriptions[QuestionIds.HARBINGER_TOPDECK] = ["Можете положить карту из сброса на верх колоды.", "Можете положить карту на верх колоды."];
RussianQuestionDescriptions[QuestionIds.LIBRARY] = ["Можете пропустить и отложить в сторону CARDS0.", "Можете пропустить CARDS0."];
RussianQuestionDescriptions[QuestionIds.MILITIA] = ["Сбросьте до трёх карт в руке.", "Сбросьте до трёх карт в руке."];
RussianQuestionDescriptions[QuestionIds.MINE_TRASH] = ["Можете выкинуть Сокровище из руки. Получите в руку Сокровище стоимостью не более чем на 3$ дороже выкинутого.", "Можете улучшить Сокровище"];
RussianQuestionDescriptions[QuestionIds.MINE_GAIN] = ["Получите в руку Сокровище не дороже AMOUNT0.", "Получите Сокровище не дороже AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.MONEYLENDER] = ["Можете выкинуть карту Меди из руки, тогда +3$.", "Можете выкинуть Медь."];
RussianQuestionDescriptions[QuestionIds.POACHER] = ["Сбросьте Argument0.", "Сбросьте Argument0."];
RussianQuestionDescriptions[QuestionIds.REMODEL_TRASH] = ["Выкиньте карту из руки. Получите карту стоимостью не более чем на 2$ дороже выкинутой.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.REMODEL_GAIN] = ["Получите карту не дороже AMOUNT0.", "Получите карту не дороже AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.SENTRY_TRASH] = ["Выкиньте любое количество карт", "Вы можете выкинуть"];
RussianQuestionDescriptions[QuestionIds.SENTRY_DISCARD] = ["Сбросьте любое количество карт", "Вы можете сбросить"];
RussianQuestionDescriptions[QuestionIds.SENTRY_TOPDECK] = ["Положите их назад в любом порядке", "Положите на верх колоды"];
RussianQuestionDescriptions[QuestionIds.THRONE_ROOM] = ["Можете сыграть карту Действия из руки дважды.", "Можете сыграть Действие дважды."];
RussianQuestionDescriptions[QuestionIds.VASSAL] = ["Можете сыграть CARDS0 из сброса.", "Можете сыграть CARDS0"];
RussianQuestionDescriptions[QuestionIds.WORKSHOP] = ["Получите карту не дороже 4$.", "Получите карту."];
RussianQuestionDescriptions[QuestionIds.TRASHING_TOKEN] = ["Можете выкинуть карту из руки.", "Можете выкинуть карту."];
RussianQuestionDescriptions[QuestionIds.ALMS] = ["Получите карту не дороже 4$.", "Получите карту."];
RussianQuestionDescriptions[QuestionIds.AMULET_MODE] = ["Выберите одно из трёх: +1$; или выкиньте карту из руки; или получите Серебро.", "Выберите, как сыграть Амулет."];
RussianQuestionDescriptions[QuestionIds.AMULET_TRASH] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.ARTIFICER_DELAY] = ["Можете сбросить любое число карт из руки. Можете получить карту стоимостью ровно 1$ за каждую сброшенную карту, положив её на верх колоды.", "Можете использовать Ремесленника."];
RussianQuestionDescriptions[QuestionIds.ARTIFICER_DISCARD] = ["Сбросьте любое число карт. Можете получить карту стоимостью ровно 1$ за каждую сброшенную карту, положив её на верх колоды.", "Сбрасывайте, затем получите карту."];
RussianQuestionDescriptions[QuestionIds.ARTIFICER_GAIN] = ["Можете получить карту стоимостью ровно AMOUNT0, положив её на верх колоды.", "Можете получить карту стоимостью ровно AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.BALL_FIRST] = ["Получите 2 карты не дороже 4$ каждая.", "Получите две карты."];
RussianQuestionDescriptions[QuestionIds.BALL_SECOND] = ["Получите ещё одну карту не дороже 4$.", "Получите ещё одну карту."];
RussianQuestionDescriptions[QuestionIds.BONFIRE] = ["Выкиньте до двух карт Меди, которые есть у вас в игре.", "Выкиньте до двух карт Меди, которые есть у вас в игре."];
RussianQuestionDescriptions[QuestionIds.DISCIPLE] = ["Можете дважды сыграть карту Действия из руки. Получите её копию.", "Сыграйте дважды, затем получите копию."];
RussianQuestionDescriptions[QuestionIds.DUNGEON] = ["Сбросьте две карты.", "Сбросьте две карты."];
RussianQuestionDescriptions[QuestionIds.FERRY] = ["Передвиньте ваш Жетон -2$ на стопку карт Действия.", "Передвиньте ваш Жетон -2$."];
RussianQuestionDescriptions[QuestionIds.FUGITIVE] = ["Сбросьте карту.", "Сбросьте карту."];
RussianQuestionDescriptions[QuestionIds.GEAR] = ["Отложите в сторону до двух карт из руки. В начале вашего следующего хода возьмите их в руку.", "Отложите до двух карт."];
RussianQuestionDescriptions[QuestionIds.HAUNTED_WOODS] = ["Положите вашу руку на верх колоды в любом порядке.", "Положите руку на верх колоды."];
RussianQuestionDescriptions[QuestionIds.HERO] = ["Получите Сокровище.", "Получите Сокровище."];
RussianQuestionDescriptions[QuestionIds.LOST_ARTS_NEW] = ["Положите ваш Жетон +1 Действия на стопку карт Действия.", "Передвиньте ваш Жетон +1 Действия."];
RussianQuestionDescriptions[QuestionIds.LOST_ARTS_MOVE] = ["Передвиньте ваш Жетон +1 Действия на стопку карт Действия", "Передвиньте ваш Жетон +1 Действия."];
RussianQuestionDescriptions[QuestionIds.MESSENGER_DISCARD] = ["Можете положить вашу колоду в ваш сброс. ", "Можете сбросить вашу колоду."];
RussianQuestionDescriptions[QuestionIds.MESSENGER_GAIN] = ["Получите карту не дороже 4$, каждый другой игрок получает её копию.", "Выберите карту не дороже $4"];
RussianQuestionDescriptions[QuestionIds.MISER] = ["Выберите одно: Положите карту Меди из руки на мат; или +AMOUNT0", "Медь на мат или +AMOUNT0?"];
RussianQuestionDescriptions[QuestionIds.PATHFINDING_NEW] = ["Положите ваш Жетон +1 Карты на стопку карт Действия.", "Передвиньте ваш Жетон +1 Карты."];
RussianQuestionDescriptions[QuestionIds.PATHFINDING_MOVE] = ["Передвиньте ваш Жетон +1 Карты на стопку карт Действия.", "Передвиньте ваш Жетон +1 Карты."];
RussianQuestionDescriptions[QuestionIds.PILGRIMAGE_FIRST] = ["Можете выбрать до трёх различных карт у вас в игре, и получите копию каждой из них.", "Выберите три карты."];
RussianQuestionDescriptions[QuestionIds.PILGRIMAGE_SECOND] = ["Можете выбрать ещё две различных карты у вас в игре, и получите копию каждой из них.", "Выберите ещё две карты."];
RussianQuestionDescriptions[QuestionIds.PILGRIMAGE_THIRD] = ["Можете выбрать ещё одну карту у вас в игре, и получите её копию.", "Выберите ещё одну карту."];
RussianQuestionDescriptions[QuestionIds.PLAN_NEW] = ["Положите ваш Жетон Свалки на стопку карт Действия.", "Передвиньте ваш Жетон Свалки."];
RussianQuestionDescriptions[QuestionIds.PLAN_MOVE] = ["Передвиньте ваш Жетон Свалки на стопку карт Действия.", "Передвиньте ваш Жетон Свалки."];
RussianQuestionDescriptions[QuestionIds.QUEST_MODE] = ["Можете сбросить Атаку, два Проклятья, или шесть карт.", "Выберите, что сбросить"];
RussianQuestionDescriptions[QuestionIds.QUEST_ATTACK] = ["Сбросьте карту Атаки.", "Сбросьте Атаку."];
RussianQuestionDescriptions[QuestionIds.QUEST_6CARDS] = ["Сбросьте шесть карт.", "Сбросьте шесть карт."];
RussianQuestionDescriptions[QuestionIds.RATCATCHER] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_PLAY] = ["Можете выкинуть Разрушение из игры.", "Можете выкинуть Разрушение."];
RussianQuestionDescriptions[QuestionIds.RAZE_ALL] = ["Выкиньте карту из руки или Разрушение из игры.", "Выкиньте карту или Разрушение."];
RussianQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_HAND] = ["Можете выкинуть карту ищ руки.", "Можете выкинуть карту."];
RussianQuestionDescriptions[QuestionIds.RAZE_CHOOSE_CARD] = ["Выберите карту и положите её в руку.", "Положите карту в руку."];
RussianQuestionDescriptions[QuestionIds.SAVE] = ["Отложите в сторону карту из руки, и положите её в руку в конце хода (после фазы Расчистки).", "Отложите карту."];
RussianQuestionDescriptions[QuestionIds.SCOUTING_PARTY_DISCARD] = ["Сбросьте NUMBER0 карт.", "Сбросьте NUMBER0 карт."];
RussianQuestionDescriptions[QuestionIds.SCOUTING_PARTY_TOPDECK] = ["Положите оставшиеся две карту на верх колоды в любом порядке.", "Положите эти карты на верх колоды."];
RussianQuestionDescriptions[QuestionIds.SEAWAY] = ["Получите карту Действия не дороже 4$. Передвиньте ваш Жетон +1 Покупки на её стопку.", "Получите карту Действия."];
RussianQuestionDescriptions[QuestionIds.SOLDIER] = ["Сбросьте карту.", "Сбросьте карту."];
RussianQuestionDescriptions[QuestionIds.STORYTELLER_FIRST] = ["Сыграйте до трёх Сокровищ из руки.", "Сыграйте до трёх Сокровищ."];
RussianQuestionDescriptions[QuestionIds.STORYTELLER_SECOND] = ["Сыграйте ещё до двух Сокровищ из руки.", "Сыграйте ещё до двух Сокровищ."];
RussianQuestionDescriptions[QuestionIds.STORYTELLER_THIRD] = ["Можете сыграть ещё одно Сокровище из руки.", "Можете сыграть ещё одно Сокровище."];
RussianQuestionDescriptions[QuestionIds.TEACHER_CHOOSE_TOKEN] = ["Выберите, какой жетон передвинуть.", "Выберите жетон."];
RussianQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_MOVE] = ["Положите ваш Жетон +1 Действия на стопку карт Действия, на которой у вас нет жетонов.", "Передвиньте Жетон +1 Действия."];
RussianQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_NEW] = ["Передвиньте ваш Жетон +1 Действия на стопку карт Действия, на которой у вас нет жетонов.", "Передвиньте Жетон +1 Действия."];
RussianQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_MOVE] = ["Положите ваш Жетон +1 Карты на стопку карт Действия, на которой у вас нет жетонов.", "Передвиньте Жетон +1 Карты."];
RussianQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_NEW] = ["Передвиньте ваш Жетон +1 Карты на стопку карт Действия, на которой у вас нет жетонов.", "Передвиньте Жетон +1 Карты."];
RussianQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_MOVE] = ["Положите ваш Жетон +1 Монеты на стопку карт Действия, на которой у вас нет жетонов.", "Передвиньте Жетон +1 Монеты."];
RussianQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_NEW] = ["Передвиньте ваш Жетон +1 Монеты на стопку карт Действия, на которой у вас нет жетонов.", "Передвиньте Жетон +1 Монеты."];
RussianQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_MOVE] = ["Положите ваш Жетон +1 Покупки на стопку карт Действия, на которой у вас нет жетонов.", "Передвиньте Жетон +1 Покупки."];
RussianQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_NEW] = ["Передвиньте ваш Жетон +1 Покупки на стопку карт Действия, на которой у вас нет жетонов.", "Передвиньте Жетон +1 Покупки."];
RussianQuestionDescriptions[QuestionIds.TRADE] = ["Выкиньте до двух карт из руки. Получите Серебро за каждую выкинутую карту.", "Выкиньте до двух карт."];
RussianQuestionDescriptions[QuestionIds.TRAINING_NEW] = ["Положите ваш Жетон +1 Монеты на стопку карт Действия.", "Передвиньте Жетон +1 Монеты."];
RussianQuestionDescriptions[QuestionIds.TRAINING_MOVE] = ["Передвиньте ваш Жетон +1 Монеты на стопку карт Действия.", "Передвиньте Жетон +1 Монеты."];
RussianQuestionDescriptions[QuestionIds.TRANSMOGRIFY_TRASH] = ["Выкиньте карту из руки, получите карту не более чем на 1$ дороже выкинутой, и положите её в руку.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.TRANSMOGRIFY_GAIN] = ["Получите в руку карту не дороже AMOUNT0.", "Получите карту не дороже AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.APOTHECARY] = ["Положите раскрытые карты на верх колоды в любом порядке.", "Положите эти карты на верз колоды."];
RussianQuestionDescriptions[QuestionIds.APPRENTICE] = ["Выкиньте карту из руки. +1 Карта за каждую $1 её стоимости. +2 Карты, если в её стоимости было [P].", "Выкиньте карту, затем тяните карты."];
RussianQuestionDescriptions[QuestionIds.GOLEM] = ["Выберите, какое из Действий сыграть первым.", "Выберите порядок игры Действий."];
RussianQuestionDescriptions[QuestionIds.HERBALIST] = ["Можете положить одно из ваших Сокровищ из игры на верх колоды.", "Можете положить Сокровище на верх колоды."];
RussianQuestionDescriptions[QuestionIds.HERBALISTS] = ["Какие Сокровища положить на верх колоды", "Положите на верх колоды Argument0 Сокровищ"];
RussianQuestionDescriptions[QuestionIds.SCRYING_POOL_YOU] = ["Оставьте или сбросьте CARDS0", "Вы: оставьте или сброьте CARDS0"];
RussianQuestionDescriptions[QuestionIds.SCRYING_POOL_OTHER] = ["Выберите, PLAYER1 оставляет или сбрасывает CARDS0.", "PLAYER1: оставить или сбросить CARDS0."];
RussianQuestionDescriptions[QuestionIds.TRANSMUTE] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.UNIVERSITY] = ["Можете получить карту Действия не дороже 5$.", "Можете получить карту Действия."];
RussianQuestionDescriptions[QuestionIds.FOLLOWERS] = ["Сбросьте до трёх карт в руке.", "Сбросьте до трёх карт в руке."];
RussianQuestionDescriptions[QuestionIds.DIADEM] = ["Использовать Хуторян, чтобы получить больше монет за Действия?", "Использоавть Хуторян?"];
RussianQuestionDescriptions[QuestionIds.HAMLET] = ["Можете сбрость карты, чтобы получить +Действие и/или +Покупку.", "Можете сбросить карты."];
RussianQuestionDescriptions[QuestionIds.HAMLET_ACTION] = ["Сбросьте карту и получите +1 Действие.", "Сбросьте за +1 Действие."];
RussianQuestionDescriptions[QuestionIds.HAMLET_BUY] = ["Сбросьте карту и получите +1 Покупку.", "Сбросьте за +1 Покупку."];
RussianQuestionDescriptions[QuestionIds.HAMLET_BOTH] = ["Сбросьте две карты.", "Сбросьте две карты."];
RussianQuestionDescriptions[QuestionIds.HORN_OF_PLENTY] = ["Получите карту не дороже AMOUNT0. Если это карту победы, выкиньте Рог Изобилия.", "Получите карту не дороже AMOUNT0"];
RussianQuestionDescriptions[QuestionIds.HORSE_TRADERS] = ["Сбросьте две карты.", "Сбросьте две карты."];
RussianQuestionDescriptions[QuestionIds.JESTER] = ["Выберите, кто получает CARDS0: Вы или PLAYER1.", "Выберите, кто получает CARDS0"];
RussianQuestionDescriptions[QuestionIds.REMAKE_TRASH_FIRST] = ["Выполните это дважды: Выкиньте карту из руки, затем получите карту стоимостью ровно на 1$ больше выкинутой.", "Выкиньте первую карту."];
RussianQuestionDescriptions[QuestionIds.REMAKE_TRASH_SECOND] = ["Выкиньте ещё одну карту из руки, затем получите карту стоимостью ровно на 1$ больше выкинутой.", "Выкиньте вторую карту."];
RussianQuestionDescriptions[QuestionIds.REMAKE_GAIN_FIRST] = ["Получите карту стоимостью ровно COST0", "Получите карту"];
RussianQuestionDescriptions[QuestionIds.REMAKE_GAIN_SECOND] = ["Получите карту стоимостью ровно COST0", "Получите карту"];
RussianQuestionDescriptions[QuestionIds.TOURNAMENT_GAIN_PRIZE_OR_DUCHY] = ["Получите на верх колоды любой Трофей (из стопки Трофеев) или Герцогство.", "Получите Трофей или Герццогство."];
RussianQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_PRIZE] = ["Можете получить Трофей на верх колоды.", "Можете получить Трофей."];
RussianQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_DUCHY] = ["Можете получить Герцогство на верх колоды.", "Можете получить Герцогство."];
RussianQuestionDescriptions[QuestionIds.TOURNAMENT_REVEAL] = ["Можете раскрыть Провинцию.", "Можете раскрыть Провинцию."];
RussianQuestionDescriptions[QuestionIds.TRUSTY_STEED] = ["Выберите, как сыграть Верного Коня.", "Выберите, как сыграть Верного Коня."];
RussianQuestionDescriptions[QuestionIds.YOUNG_WITCH] = ["Сбросьте две карты.", "Сбросьте две карты."];
RussianQuestionDescriptions[QuestionIds.YOUNG_WITCH_BANE] = ["Можете раскрыть CARDS0 и заблокировать Юную Ведьму.", "Раскрыть CARDS0 чотбы заблокировать Юную Ведьму?"];
RussianQuestionDescriptions[QuestionIds.ALTAR_TRASH] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.ALTAR_GAIN] = ["Получите карту не дороже $5.", "Получите карту."];
RussianQuestionDescriptions[QuestionIds.ARMORY] = ["Получите карту не дороже $4 на верх колоды.", "Получите карту."];
RussianQuestionDescriptions[QuestionIds.CATACOMBS_MODE] = ["Положите CARDS0 в руку; или сбросьте их и +3 Карты.", "Оставить или вытянуть ещё?"];
RussianQuestionDescriptions[QuestionIds.CATACOMBS_GAIN] = ["Получите карту дешевле AMOUNT0.", "Получите карту дешевле AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.COUNT_PAY] = ["Сбросьте 2 карты; или положите карту из руки на верх колоды; или получите Медь.", "Сделайте выбор."];
RussianQuestionDescriptions[QuestionIds.COUNT_DISCARD] = ["Сбросьте две карты.", "Сбросьте две карты."];
RussianQuestionDescriptions[QuestionIds.COUNT_TOPDECK] = ["Положите карту из руки на верх колоды.", "Положите карту на верх колоды"];
RussianQuestionDescriptions[QuestionIds.COUNT_PROFIT] = ["+3$; или выкиньте вашу руку; или получите Герцогство.", "Сделайте выбор."];
RussianQuestionDescriptions[QuestionIds.COUNTERFEIT] = ["Можете сыграть Сокровище из руки дважды. Тогда выкиньте его.", "Сыграйте дважды и выкиньте."];
RussianQuestionDescriptions[QuestionIds.CULTIST] = ["Можете сыграть Культиста из руки.", "Сыграть ещё одного Культиста?"];
RussianQuestionDescriptions[QuestionIds.DAME_ANNA] = ["Можете выкинуть до двух карт из руки.", "Выкиньте до двух карт."];
RussianQuestionDescriptions[QuestionIds.DAME_NATALIE] = ["Вы можете получить карту не дороже 3$.", "Можете получить карту."];
RussianQuestionDescriptions[QuestionIds.DEATH_CART_INPLAY] = ["Вы можете выкинуть карту Действия из руки. Иначе выкиньте Труповозку из игры.", "Можете выкинуть Действие."];
RussianQuestionDescriptions[QuestionIds.DEATH_CART_GONE] = ["Вы можете выкинуть карту Действия из руки, или попытаться выкинуть Труповозку из игры.", "Можете выкинуть Действие."];
RussianQuestionDescriptions[QuestionIds.FORAGER] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.KNIGHTS] = ["Выберите, что выкинуть.", "Выберите, что выкинуть."];
RussianQuestionDescriptions[QuestionIds.KNIGHTS_ALREADY_DIES] = ["Выберите, что выкинуть (CARDS0 точно умрёт).", "Выберите, что выкинуть."];
RussianQuestionDescriptions[QuestionIds.KNIGHTS_STILL_MATTERS] = ["Выберите, что выкинуть (CARDS1 будет выкинута, если вы выберите CARDS0).", "Выберите, что выкинуть."];
RussianQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_EXPAND] = ["Можете выкинуть карту из руки и получить карту на 3$ дороже выкинутой.", "Можете выкинуть карту."];
RussianQuestionDescriptions[QuestionIds.GRAVEROBBER_EXPAND] = ["Выкиньте карту из руки и получите карту на 3$ дороже выкинутой.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_GAIN] = ["Можете получить карту со Свалки на верх колоды", "Можете получить карту со Свалки."];
RussianQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN] = ["Получите карту со Свалки на верх колоды.", "Получите карту со Свалки."];
RussianQuestionDescriptions[QuestionIds.GRAVEROBBER_COMPLEX] = ["Играя Расхитителя Могил", "Получите карту со Свалки или выкиньте карту Действия"];
RussianQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN_SUPPLY] = ["Получите карту не дороже AMOUNT0.", "Получите карту не дороже AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.HERMIT_TRASH] = ["Можете выкинуть карту из сброса или из руки (но не Сокровище).", "Можете выкинуть карту."];
RussianQuestionDescriptions[QuestionIds.HERMIT_GAIN] = ["Получите карту не дороже 3$.", "Получите карту."];
RussianQuestionDescriptions[QuestionIds.HUNTING_GROUNDS] = ["Получите Герцогство или 3 Поместья.", "Герцогство или 3 Поместья?"];
RussianQuestionDescriptions[QuestionIds.IRONMONGER] = ["Можете оставить или сбросить CARDS0.", "Можете сбросить CARDS0."];
RussianQuestionDescriptions[QuestionIds.JUNK_DEALER] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.MERCENARY_ONE] = ["Можете выкинуть карту из руки (ничего за это не получите).", "Можете выкинуть карту."];
RussianQuestionDescriptions[QuestionIds.MERCENARY_MULTIPLE] = ["Можете выкинуть две карту из руки.", "Можете выкинуть две карты."];
RussianQuestionDescriptions[QuestionIds.MERCENARY_DISCARD] = ["Сбросьте до трёх карт в руке.", "Сбросьте до трёх карт."];
RussianQuestionDescriptions[QuestionIds.MYSTIC] = ["Угадайте карту с верх вашей колоды.", "Назовите карту."];
RussianQuestionDescriptions[QuestionIds.PILLAGE] = ["Выберите, что PLAYER0 сбросит.", "Выберите, что PLAYER0 сбросит."];
RussianQuestionDescriptions[QuestionIds.PROCESSION_PLAY] = ["Вы можете сыграть карту Действия из руки дважды.", "Можете сыграть Действие дважды."];
RussianQuestionDescriptions[QuestionIds.PROCESSION_GAIN] = ["Получите действие стоимостью ровно AMOUNT0.", "Получите Действие стоимостью ровно AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.RATS_TRASH_ANY] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.RATS_TRASH_OTHER] = ["Выкиньте карту из руки, но не карту Крыс.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.REBUILD_SKIP] = ["Назовите карту Победы (или Медь).", "Назовите карту, которая будет пропущена."];
RussianQuestionDescriptions[QuestionIds.REBUILD_GAIN] = ["Получите карту Победы не дороже AMOUNT0.", "Получите карту Победы не дороже AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.ROGUE_TRASH] = ["Выберите карту, которую PLAYER0 выкинет.", "Выкиньте карту у PLAYER0."];
RussianQuestionDescriptions[QuestionIds.ROGUE_GAIN] = ["Получите карту со Свалки стоимостью от $3 до $6.", "Получите карту со Свалки."];
RussianQuestionDescriptions[QuestionIds.SCAVENGER] = ["Можете положить вашу колоду в сброс.", "Можете сбросить вашу колоду."];
RussianQuestionDescriptions[QuestionIds.SCAVENGER_TOPDECK] = ["Положите карту из сброса на верх колоды.", "Положите карту на верх колоды."];
RussianQuestionDescriptions[QuestionIds.SIR_MICHAEL] = ["Сбросьте до трёх карт в руке.", "Сбросьте до трёх карт."];
RussianQuestionDescriptions[QuestionIds.SQUIRE_MODE] = ["Выберите одно: +2 Действия; или +2 Покупки; или получить Серебро.", "Выберите, как сыграть Оруженосца."];
RussianQuestionDescriptions[QuestionIds.SQUIRE_GAIN] = ["Получите карту Атаки.", "Получите Атаку."];
RussianQuestionDescriptions[QuestionIds.STOREROOM_CARDS] = ["Сбросьте любое число карт, затем вытяните столько же.", "Сбросить за карты."];
RussianQuestionDescriptions[QuestionIds.STOREROOM_COINS] = ["Сбросьте любое число карт и получите +1$ за каждую.", "Сбросить за монеты."];
RussianQuestionDescriptions[QuestionIds.SURVIVORS] = ["Положите эти две карты на верх колоды в любом порядке или сбросьте их.", "Оставить наверху или сбросить?"];
RussianQuestionDescriptions[QuestionIds.URCHIN] = ["Сбросьте до четырёх карт в руке.", "Сбросьте до четырёх карт."];
RussianQuestionDescriptions[QuestionIds.WANDERING_MINSTREL] = ["Положите карты действия назад в любом порядке.", "Положите назад в любом порядке."];
RussianQuestionDescriptions[QuestionIds.ADVANCE_TRASH] = ["Можете выкинуть карту Действия из руки.", "Можете выкинуть Действие."];
RussianQuestionDescriptions[QuestionIds.ADVANCE_GAIN] = ["Получите карту Действия не дороже $6.", "Получите Действие."];
RussianQuestionDescriptions[QuestionIds.ANNEX] = ["Выберите 5 или меньше карт, чтобы замешать их в вашу колоду.", "Выберите 5 или меньше карт."];
RussianQuestionDescriptions[QuestionIds.ARCHIVE] = ["Выберите, какую карту положить в руку.", "Выберите карту."];
RussianQuestionDescriptions[QuestionIds.ARENA] = ["Можете сбросить карту Действия за NUMBER0VP.", "Арена: Сбросить за NUMBER0VP?"];
RussianQuestionDescriptions[QuestionIds.BANQUET] = ["Можете получить карту (не карту Победы) не дороже $5.", "Получите карту."];
RussianQuestionDescriptions[QuestionIds.BUSTLING_VILLAGE] = ["Можете положить Поселенцев в руку.", "Можете взять Поселенцев."];
RussianQuestionDescriptions[QuestionIds.CATAPULT_TRASH] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.CATAPULT_DISCARD] = ["Сбросьте до трёх карт в руке.", "Сбросьте до трёх карт."];
RussianQuestionDescriptions[QuestionIds.CHARM_MODE] = ["Выберите, как сыграть Безделушку.", "Выберите, как сыграть Безделушку."];
RussianQuestionDescriptions[QuestionIds.CHARM_GAIN] = ["Получите карту (не CARDS0) стоимостью ровно AMOUNT1.", "Получите карту (не CARDS0) стоимостью ровно AMOUNT1."];
RussianQuestionDescriptions[QuestionIds.CROWN_ACTION] = ["Можете сыграть карту Действия из руки дважды.", "Можете сыграть Действие дважды."];
RussianQuestionDescriptions[QuestionIds.CROWN_TREASURE] = ["Можете сыграть Сокровище из руки дважды.", "Можете сыграть Сокровище дважды."];
RussianQuestionDescriptions[QuestionIds.DONATE] = ["Выкиньте любое число карт.", "Выкиньте любое число карт."];
RussianQuestionDescriptions[QuestionIds.ENCAMPMENT] = ["Можете раскрыть Золото или Награбленное из руки.", "Можете раскрыть Золото или Награбленное."];
RussianQuestionDescriptions[QuestionIds.ENGINEER_GAIN] = ["Получите карту не дороже $4.", "Получите карту."];
RussianQuestionDescriptions[QuestionIds.ENGINEER_TRASH] = ["Можете выкинуть Инженера.", "Можете выкинуть Инженера."];
RussianQuestionDescriptions[QuestionIds.FORUM] = ["Сбросьте две карты.", "Сбросьте две карты."];
RussianQuestionDescriptions[QuestionIds.GLADIATOR_REVEAL] = ["Раскройте карту из руки.", "Раскройте карту."];
RussianQuestionDescriptions[QuestionIds.GLADIATOR_PRETEND] = ["Сделать вид, что думаете, раскрывать ли карту.", "..."];
RussianQuestionDescriptions[QuestionIds.GLADIATOR_BLOCK] = ["Можете раскрыть CARDS0 из руки.", "Можете раскрыть CARDS0"];
RussianQuestionDescriptions[QuestionIds.HAUNTED_CASTLE] = ["Положите 2 карты из руки на верх колоды.", "Положите 2 карты на верх колоды."];
RussianQuestionDescriptions[QuestionIds.LEGIONARY_REVEAL] = ["Можете раскрыть Золото из руки.", "Можете раскрыть Золото."];
RussianQuestionDescriptions[QuestionIds.LEGIONARY_DISCARD] = ["Сбросьте до двух карт в руке.", "Сбросьте до двух карт."];
RussianQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_NEW] = ["Можете сделать ставку до 40D.", "Можете сделать ставку до 40D."];
RussianQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_OVERBID] = ["Можете сделать ставку до 40D. Сейчас PLAYER0 поставил NUMBER1.", "Можете сделать ставку до 40D"];
RussianQuestionDescriptions[QuestionIds.OPULENT_CASTLE] = ["Сбросьте карты Победы из руки по +$2 за каждую. ", "Сбросьте карты по +$2 за каждую."];
RussianQuestionDescriptions[QuestionIds.RITUAL] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.SACRIFICE] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.SALT_THE_EARTH] = ["Выкиньте карту победы из Резерва.", "Выкиньте из Резерва."];
RussianQuestionDescriptions[QuestionIds.SETTLERS] = ["Можете положить карту Меди из сброса в руку.", "Можете взять карту Меди."];
RussianQuestionDescriptions[QuestionIds.SMALL_CASTLE_IN_PLAY] = ["Выкиньте Маленький Замок из игры или другой Замок из руки.", "Выкиньте Замок."];
RussianQuestionDescriptions[QuestionIds.SMALL_CASTLE_GONE] = ["Выкиньте Замок из руки.", "Выкиньте Замок."];
RussianQuestionDescriptions[QuestionIds.SMALL_CASTLE_GAIN] = ["Получите Замок.", "Получите Замок."];
RussianQuestionDescriptions[QuestionIds.SPRAWLING_CASTLE] = ["Получите Герцогство или 3 Поместья.", "Герцогство или 3 Поместья?"];
RussianQuestionDescriptions[QuestionIds.TAX] = ["Выберите стопку Резерва, на которую добавите (2D).", "Добавьте (2D) на стопку Резерва."];
RussianQuestionDescriptions[QuestionIds.TEMPLE] = ["Выкиньте от одной до трёх разных карт из руки.", "Выкиньте от одной до трёх карт."];
RussianQuestionDescriptions[QuestionIds.WILD_HUNT] = ["Выберите, либо +3 карты, либо получите Поместье и очки Победы.", "Карты или очки Победы?"];
RussianQuestionDescriptions[QuestionIds.ADVISOR] = ["Выберите, какую карту PLAYER0 сбрасывает.", "Какую карту PLAYER0 сбросит?"];
RussianQuestionDescriptions[QuestionIds.BUTCHER_TRASH] = ["Можете выкинуть карту из руки.", "Можете выкинуть карту."];
RussianQuestionDescriptions[QuestionIds.BUTCHER_GAIN] = ["Получите карту не дороже AMOUNT0.", "Получите карту не дороже AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.DOCTOR_PLAY] = ["Назовите карту, которую хотите выкинуть.", "Назовите карту."];
RussianQuestionDescriptions[QuestionIds.DOCTOR_TOPDECK] = ["Положите эти карты назад в любом порядке.", "Положите эти карты на верх колоды."];
RussianQuestionDescriptions[QuestionIds.DOCTOR_OVERPAY] = ["Можете переплатить за Доктора.", "Можете переплатить."];
RussianQuestionDescriptions[QuestionIds.DOCTOR_MODE] = ["Что делать с CARDS0?", "Что делать с CARDS0?"];
RussianQuestionDescriptions[QuestionIds.DOCTOR_MODE_MANY] = ["Что делать с CARDS0 (ещё NUMBER1)?", "CARDS0 (ещё NUMBER1)?"];
RussianQuestionDescriptions[QuestionIds.HERALD_OVERPAY] = ["Можете переплатить за Глашатая.", "Можете переплатить."];
RussianQuestionDescriptions[QuestionIds.HERALD_CHOOSE] = ["Положите карту из Сброса на верх колоды.", "Положите карту на верх колоды."];
RussianQuestionDescriptions[QuestionIds.JOURNEYMAN] = ["Назовите карту, которую не хотите вытащить.", "Наховите карту."];
RussianQuestionDescriptions[QuestionIds.MASTERPIECE] = ["Вы можете переплатить ха Шедевр.", "Вы можете переплатить."];
RussianQuestionDescriptions[QuestionIds.PLAZA_DELAY] = ["Можете сбросить Сокровище и получить Золотой Жетон.", "Можете сбросить Сокровище."];
RussianQuestionDescriptions[QuestionIds.PLAZA_DISCARD] = ["Сбросьте Сокровище.", "Сбросьте Сокровище."];
RussianQuestionDescriptions[QuestionIds.STONEMASON_TRASH] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.STONEMASON_GAIN1] = ["Получите две карты, каждая дешевле AMOUNT0.", "Получите две карты, каждая дешевле AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.STONEMASON_GAIN2] = ["Получите ещё одну карту дешевле AMOUNT0.", "Получите ещё одну карту дешевле AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.STONEMASON_OVERPAY] = ["Можете переплатить за Каменщика, получив карты Действия не дороже AMOUNT0.", "Можете переплатить за Каменщика."];
RussianQuestionDescriptions[QuestionIds.STONEMASON_GAIN_EQUAL] = ["Получите ещё одно Действие стоимостью ровно AMOUNT0.", "Получите ещё одно Действие стоимостью ровно AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.TAXMAN_TRASH] = ["Можете выкинуть Сокровище из руки.", "Можете выкинуть Сокровище."];
RussianQuestionDescriptions[QuestionIds.TAXMAN_GAIN] = ["Получите Сокровище не дороже AMOUNT0 на верх колоды.", "Получите Сокровище не дороже AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.BORDER_VILLAGE] = ["Получите карту дешевле AMOUNT0.", "Получите карту дешевле AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.CARTOGRAPHER_DISCARD] = ["Сбросьте любое число карт.", "Сбросьте любое число карт."];
RussianQuestionDescriptions[QuestionIds.CARTOGRAPHER_TOPDECK] = ["Положите оставшиеся карты на верх колоды в любом порядке.", "Положите эти карты на верх колоды."];
RussianQuestionDescriptions[QuestionIds.DEVELOP_TRASH] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.DEVELOP_GAIN1] = ["Получите на верх колоды карту стоимостью AMOUNT0 или Argument1.", "Получите карту стоимостью AMOUNT0 или Argument1."];
RussianQuestionDescriptions[QuestionIds.DEVELOP_GAIN2] = ["Получите на верх колоды карту стоимостью AMOUNT0.", "Получите карту стоимостью AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.DUCHESS] = ["Можете сбросить CARDS0.", "Можете сбросить CARDS0"];
RussianQuestionDescriptions[QuestionIds.EMBASSY] = ["Сбросьте три карты.", "Сбросьте три карты."];
RussianQuestionDescriptions[QuestionIds.FARMLAND_TRASH] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.FARMLAND_GAIN] = ["Получите карту стоимостью ровно AMOUNT0.", "Получите карту стоимостью ровно AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.HAGGLER] = ["Получите карту (не карту Победы) дешевле AMOUNT0.", "Получите карту дешевле AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.ILL_GOTTEN_GAINS] = ["Можете получить карту Меди в руку.", "Можете получить Медь."];
RussianQuestionDescriptions[QuestionIds.INN_DISCARD] = ["Сбросьте две карты.", "Сбросьте две карты."];
RussianQuestionDescriptions[QuestionIds.INN_SHUFFLE] = ["Замешайте любое количество карт Действия из сброса в колоду.", "Замешайте Действия в колоду."];
RussianQuestionDescriptions[QuestionIds.JACK_DISCARD] = ["Можете сбросить CARDS0.", "Можете сбросить CARDS0"];
RussianQuestionDescriptions[QuestionIds.JACK_TRASH] = ["Можете выкинуть карту (не Сокровище) из руки.", "Можете выкинуть карту."];
RussianQuestionDescriptions[QuestionIds.MANDARIN_PLAY] = ["Положите карту из руки на верх колоды.", "Положите карту на верх колоды."];
RussianQuestionDescriptions[QuestionIds.MANDARIN_GAIN] = ["Положите все ваши сокровища из игры на верх колоды в любом порядке.", "Положите Сокровища на верх колоды."];
RussianQuestionDescriptions[QuestionIds.MARGRAVE] = ["Сбросьте до трёх карт в руке.", "Сбросьте до трёх карт в руке."];
RussianQuestionDescriptions[QuestionIds.NOBLE_BRIGAND] = ["Выберите Сокровище, которое будет выкинуто.", "Выберите Сокровище."];
RussianQuestionDescriptions[QuestionIds.OASIS] = ["Сбросьте карту.", "Сбросьте карту."];
RussianQuestionDescriptions[QuestionIds.ORACLE_DISCARD] = ["Можете сбросить CARDS0 у PLAYER1.", "Должен ли PLAYER1 сбросить эти карты?"];
RussianQuestionDescriptions[QuestionIds.ORACLE_TOPDECK] = ["Положите эти карты на верх колоды в любом порядке.", "Положите эти карты на верх колоды."];
RussianQuestionDescriptions[QuestionIds.SCHEME] = ["Можете выбрать, какую карту положить на верх колоды.", "Можете положить карту действия на верх колоды."];
RussianQuestionDescriptions[QuestionIds.SCHEMES] = ["Какие карты положить на верх колоды?", "Можете положить на верх колоды Argument0 карт."];
RussianQuestionDescriptions[QuestionIds.SPICE_MERCHANT_TRASH] = ["Можете выкинуть Сокровище из руки.", "Можете выкинуть Сокровище."];
RussianQuestionDescriptions[QuestionIds.SPICE_MERCHANT_MODE] = ["Выберите: либо +2 карты и +1 Действие; либо +1 Покупка и +$2.", "Выберите, как сыграть Торговца Специями?"];
RussianQuestionDescriptions[QuestionIds.STABLES] = ["Можете сбросить Сокровище.", "Можете сбросить Сокровище."];
RussianQuestionDescriptions[QuestionIds.TRADER_TRASH] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.BARON] = ["Можете сбросить Поместье.", "Можете сбросить Поместье."];
RussianQuestionDescriptions[QuestionIds.COURTIER_REVEAL] = ["Раскройте карту из руки.", "Раскройте карту."];
RussianQuestionDescriptions[QuestionIds.COURTYARD] = ["Положите карту из руки на верх колоды.", "Положите карту на верх колоды."];
RussianQuestionDescriptions[QuestionIds.DIPLOMAT] = ["Сбросьте три карты.", "Сбросьте три карты."];
RussianQuestionDescriptions[QuestionIds.IRONWORKS] = ["Получите карту не дороже $4.", "Получите карту."];
RussianQuestionDescriptions[QuestionIds.LURKER_MAY_TRASH] = ["Можете выкинуть карту Действия из Резерва.", "Можете выкинуть карту из Резерва."];
RussianQuestionDescriptions[QuestionIds.LURKER_TRASH] = ["Выкиньте карту Действия из Резерва.", "Выкиньте карту из Резерва."];
RussianQuestionDescriptions[QuestionIds.LURKER_MAY_GAIN] = ["Можете получить карту Действия со Свалки.", "Можете получить карту со Свалки."];
RussianQuestionDescriptions[QuestionIds.LURKER_GAIN] = ["Получите карту Действия со Свалки.", "Получите карту со Свалки."];
RussianQuestionDescriptions[QuestionIds.MASQUERADE_PASS] = ["Передайте карту PLAYER0.", "Передайте карту PLAYER0."];
RussianQuestionDescriptions[QuestionIds.MASQUERADE_TRASH] = ["Можете выкинуть карту из руки.", "Можете выкинуть карту."];
RussianQuestionDescriptions[QuestionIds.MILL] = ["Можете сбросить две карты.", "Можете сбросить две карты."];
RussianQuestionDescriptions[QuestionIds.MINING_VILLAGE] = ["Можете выкинуть Шахтёрский Посёлок.", "Можете выкинуть Шахтёрский Посёлок."];
RussianQuestionDescriptions[QuestionIds.MINION] = ["Выберите: +$2 или вытянуть новую руку.", "+$2 или вытянуть новую руку?"];
RussianQuestionDescriptions[QuestionIds.NOBLES] = ["Выберите: +3 карты; или +2 Действия.", "+3 Карты или +2 Действия?"];
RussianQuestionDescriptions[QuestionIds.PATROL] = ["Положите эти карты назад в любом порядке.", "Положите эти карту на верх колоды."];
RussianQuestionDescriptions[QuestionIds.PAWN] = ["Выберите, как сыграть Подневольного.", "Выберите, как сыграть Подневольного."];
RussianQuestionDescriptions[QuestionIds.REPLACE_TRASH] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.REPLACE_GAIN] = ["Получите карту не дороже AMOUNT0.", "Получите карту не дороже AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.SECRET_PASSAGE_TOPDECK] = ["Положите карту на верх вашей пустой колоды.", "Положите карту на верх колоды."];
RussianQuestionDescriptions[QuestionIds.SECRET_PASSAGE_INSERT] = ["Выберите карту, чтоб положить её на верх колоды.", "Выберите карту и куда её положить."];
RussianQuestionDescriptions[QuestionIds.STEWARD_MODE] = ["Выберите: +2 Карты; или +$2; или выкиньте две карты из руки.", "Выберите, как сыграть Управляющего."];
RussianQuestionDescriptions[QuestionIds.STEWARD_TRASH] = ["Выберите две карты, которые будут выкинуты.", "Выберите две карты."];
RussianQuestionDescriptions[QuestionIds.SWINDLER] = ["Выберите, какую карту получит PLAYER0.", "Выберите карту для PLAYER0."];
RussianQuestionDescriptions[QuestionIds.TRADING_POST] = ["Выкиньте две карты из руки.", "Выкиньте две карты."];
RussianQuestionDescriptions[QuestionIds.UPGRADE_TRASH] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.UPGRADE_GAIN] = ["Получите карту стоимостью ровно AMOUNT0.", "Получите карту стоимостью ровно AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.WISHING_WELL] = ["Угадайте, какая карту лежит на верху вашей колоды.", "Загадайте карту."];
RussianQuestionDescriptions[QuestionIds.SUMMON] = ["Получите карту действия не дороже $4.", "Вызовить карту."];
RussianQuestionDescriptions[QuestionIds.GOVERNOR_MODE] = ["Выберите, как сыграть Губернатора.", "Выберите, как сыграть Губернатора."];
RussianQuestionDescriptions[QuestionIds.GOVERNOR_TRASH] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.GOVERNOR_MAY_TRASH] = ["Можете выкинуть карту из руки.", "Можете выкинуть карту."];
RussianQuestionDescriptions[QuestionIds.GOVERNOR_GAIN] = ["Получите карту стоимостью ровно Argument0", "Получите карту."];
RussianQuestionDescriptions[QuestionIds.SAUNA_CHAIN] = ["Можете сыграть Прорубь.", "Можете сыграть Прорубь."];
RussianQuestionDescriptions[QuestionIds.SAUNA_MAY_TRASH] = ["Можете выкинуть карту с помощью Сауны.", "Можете выкинуть карту."];
RussianQuestionDescriptions[QuestionIds.AVANTO_CHAIN] = ["Можете сыграть Сауну.", "Можете сыграть Сауну."];

RussianQuestionDescriptions[QuestionIds.BISHOP_SELF] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.BISHOP_OTHER] = ["Можете выкинуть карту из руки.", "Можете выкинуть карту из руки."];
RussianQuestionDescriptions[QuestionIds.CONTRABAND] = ["Назовите карту, которую PLAYER0 не сможет покупать в свой ход.", "Запретите карту."];
RussianQuestionDescriptions[QuestionIds.COUNTING_HOUSE] = ["Как много карт Меди взять в руку?", "Заберите Медь."];
RussianQuestionDescriptions[QuestionIds.EXPAND_TRASH] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.EXPAND_GAIN] = ["Получите карту не дороже AMOUNT0.", "Получите карту не дороже AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.FORGE_TRASH] = ["Выкиньте любое число карт из руки.", "Выкиньте любое число карт."];
RussianQuestionDescriptions[QuestionIds.FORGE_GAIN] = ["Получите карту стоимостью ровно AMOUNT0.", "Получите карту стоимостью AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.GOONS] = ["Сбросьте до трёх карт в руке.", "Сбросьте до трёх карт в руке."];
RussianQuestionDescriptions[QuestionIds.KINGS_COURT] = ["Можете сыграть карту Действия трижды.", "Можете сыграть Действие трижды."];
RussianQuestionDescriptions[QuestionIds.LOAN] = ["Сбросьте или выкиньте CARDS0.", "Сбросьте или выкиньте CARDS0."];
RussianQuestionDescriptions[QuestionIds.MINT] = ["Можете раскрыть Сокровище из руки. Получите его копию.", "Можете получить копию Сокровища."];
RussianQuestionDescriptions[QuestionIds.MOUNTEBANK] = ["Можете сбросить Проклятие.", "Можете сбросить Проклятие."];
RussianQuestionDescriptions[QuestionIds.RABBLE] = ["Положите эти карты назад в любом порядке.", "Положите эти карты на верх колоды."];
RussianQuestionDescriptions[QuestionIds.TRADE_ROUTE] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.VAULT_SELF] = ["Сбросьте любое число карт.", "Сбросьте любое число карт."];
RussianQuestionDescriptions[QuestionIds.VAULT_OTHER] = ["Можете сбросить две карты.", "Можете сбросить две карты."];
RussianQuestionDescriptions[QuestionIds.AMBASSADOR_REVEAL] = ["Раскройте карту из руки.", "Раскройте карту из руки."];
RussianQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_ONE] = ["Можете возвратить CARDS0 в Резерв.", "Можете возвратить CARDS0."];
RussianQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_TWO] = ["Возвратите 0, 1 или 2 CARDS0 в Резерв.", "Возвратите 0, 1 или 2 карты."];
RussianQuestionDescriptions[QuestionIds.EMBARGO] = ["Добавьте Жетон Эмбарго на стопку Резерва.", "Наложите эмбарго на стопку."];
RussianQuestionDescriptions[QuestionIds.EXPLORER] = ["Можете раскрыть Провинцию.", "Можете раскрыть Провинцию."];
RussianQuestionDescriptions[QuestionIds.GHOST_SHIP] = ["Положите NUMBER0 карт на верх колоды.", "Положите NUMBER0 карт на верх колоды."];
RussianQuestionDescriptions[QuestionIds.HAVEN] = ["Отложите карту в сторону.", "Отложите карту в сторону."];
RussianQuestionDescriptions[QuestionIds.ISLAND] = ["Положите карту из руки на Островной Мат.", "Положите карту на мат."];
RussianQuestionDescriptions[QuestionIds.LOOKOUT_TRASH] = ["Выкиньте карту.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.LOOKOUT_DISCARD] = ["Сбросьте карту.", "Сбросьте карту."];
RussianQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_TAKE] = ["Можете положить CARDS0 в руку.", "Забрать Argument0?."];
RussianQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_SET_ASIDE] = ["Можете положить верхнюю карту колоды на мат.", "Можете положите карту на мат."];
RussianQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_BOTH] = ["Положите карту на мат или возьмите CARDS0 в руку.", "Забрать Argument0?"];
RussianQuestionDescriptions[QuestionIds.NAVIGATOR] = ["Положите эти 5 карт назад в любом порядке, или сбросьте их все.", "Положите на верх колоды или сбросьте."];
RussianQuestionDescriptions[QuestionIds.PEARL_DIVER] = ["Можете положить CARDS0 на верх колоды.", "Можете положить CARDS0 на верх колоды."];
RussianQuestionDescriptions[QuestionIds.PIRATE_SHIP_MODE] = ["Выберите: +AMOUNT0 или атака.", "+AMOUNT0 или атаковать?"];
RussianQuestionDescriptions[QuestionIds.PIRATE_SHIP_TRASH] = ["Выберите, какое Сокровище выкинуть.", "Выберите, какое Сокровище выкинуть."];
RussianQuestionDescriptions[QuestionIds.SALVAGER] = ["Выкиньте карту из руки.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.SMUGGLERS_MAY_GAIN] = ["Можете получить копию карты.", "Можете получить карту."];
RussianQuestionDescriptions[QuestionIds.SMUGGLERS_GAIN] = ["Получите копию карты.", "Получите карту."];
RussianQuestionDescriptions[QuestionIds.WAREHOUSE] = ["Сбросьте три карты.", "Сбросьте три карты."];
RussianQuestionDescriptions[QuestionIds.LURKER_COMPLEX] = ["Когда играете Притаившегося", "Когда играете Притаившегося"];
RussianQuestionDescriptions[QuestionIds.BLACK_MARKET_COMPLEX] = ["Играете Чёрный Рынок", "Играете Чёрный Рынок"];
RussianQuestionDescriptions[QuestionIds.BLACK_MARKET_BUY] = ["купите одну из раскрытых карт.", "Можете купить"];
RussianQuestionDescriptions[QuestionIds.BLACK_MARKET_PLAY] = ["сыграйте Сокровища из руки.", "Можете сыграть Сокровища."];
RussianQuestionDescriptions[QuestionIds.ENVOY] = ["Выберите, какую карту сбросить.", "Сбросьте карту."];
//RussianQuestionDescriptions[QuestionIds.PRINCE_MODE] = ["PRINCE_MODE", "Отложить Принца в сторону?"];
RussianQuestionDescriptions[QuestionIds.PRINCE_MAY_SET_ASIDE] = ["Можете отложить карту в сторону ", "Можете отложить карту в сторону."];
RussianQuestionDescriptions[QuestionIds.TORTURER_MAY_CURSE] = ["Можете получить Проклятие.", "Можете получить Проклятие."];
RussianQuestionDescriptions[QuestionIds.TORTURER_MAY_DISCARD] = ["Можете сбросить две карты.", "Можете сбросить две карты."];
RussianQuestionDescriptions[QuestionIds.TORTURER_DISCARD_OR_CURSE] = ["Получите Проклятие или сбросьте две карты.", "Сбросьте две карты или получите Проклятие."];
//RussianQuestionDescriptions[QuestionIds.WHAT_TO_IMPERSONATE] = ["Выберите, какую карту сымитировать.", "Выберите, какую карту сымитировать."];
RussianQuestionDescriptions[QuestionIds.INHERITANCE] = ["Выберите карту для унаследования.", "Выберите карту для унаследования"];

RussianQuestionDescriptions[QuestionIds.SHORT_YOU_MAY_REACT] = ["-", "Можете отреагировать"];
RussianQuestionDescriptions[QuestionIds.SHORT_WHEN_GAIN] = ["-", "Можете получить Argument0..."];
RussianQuestionDescriptions[QuestionIds.SHORT_WHEN_WOULD_GAIN] = ["-", "Вы будете получать Argument0..."];

RussianQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL_SPECIFIC] = ["-", "Играйте или вызовите Argument0..."];
RussianQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL] = ["-", "Играйте или вызывайте Действия."];
RussianQuestionDescriptions[QuestionIds.SHORT_PLAY] = ["-", "Можете играть Действия."];
RussianQuestionDescriptions[QuestionIds.SHORT_CALL_SPECIFIC] = ["-", "Можете вызвать Argument0"];
RussianQuestionDescriptions[QuestionIds.SHORT_CALL] = ["-", "Можете вызывать карты."];

RussianQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_DISCARD] = ["Вы можете сбросить Сокровище", "Вы можете сбросить"];
RussianQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_GAIN] = ["Получите карту стоимостью до $4.", "Получите карту"];
RussianQuestionDescriptions[QuestionIds.THE_FLAMES_GIFT] = ["Вы можете выкинуть карту из руки.", "Вы можете выкинуть"];
RussianQuestionDescriptions[QuestionIds.THE_MOONS_GIFT] = ["Вы можете положить карту на верх колоды.", "Вы можете положить на верх"];
RussianQuestionDescriptions[QuestionIds.THE_SKYS_GIFT] = ["Вы можете сбросить 3 карты, чтобы получить Золото.", "Вы можете сбросить"];
RussianQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_TOPDECK] = ["Положите остальные обратно в любом порядке", "положите обратно"];
RussianQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_DISCARD] = ["Сбросьте любое количество", "Сбросьте любое количество"];
RussianQuestionDescriptions[QuestionIds.THE_WINDS_GIFT] = ["Сбросьте 2 карты.", "Сбросьте 2 карты"];
RussianQuestionDescriptions[QuestionIds.FEAR_DISCARD] = ["Сбросьте Действие или Сокровище", "Сбросьте карту"];
RussianQuestionDescriptions[QuestionIds.HAUNTING] = ["Положите карту на верх колоды", "Положите карту на верх"];
RussianQuestionDescriptions[QuestionIds.LOCUSTS] = ["Получите карту того же типа дешевле.", "Получите карту дешевле"];
RussianQuestionDescriptions[QuestionIds.POVERTY] = ["Сбросьте до 3 карт в руке", "Сбросьте до 3"];
RussianQuestionDescriptions[QuestionIds.BAT] = ["Вы можете выкинуть до 2 карт из руки", "Вы можете выкинуть"];
RussianQuestionDescriptions[QuestionIds.BLESSED_VILLAGE_MODE] = ["Когда получить Argument0?", "Когда получить?"];
RussianQuestionDescriptions[QuestionIds.CEMETERY] = ["Вы можете выкинуть до 4 карт", "Вы можете выкинуть"];
RussianQuestionDescriptions[QuestionIds.CHANGELING_GAIN_COPY] = ["Получите копию карты, которая есть у вас в игре", "Получите карту"];
RussianQuestionDescriptions[QuestionIds.CHANGELING_REPLACE] = ["", ""];
RussianQuestionDescriptions[QuestionIds.COBBLER] = ["Получите в руку карту не дороже $4.", "Получите в руку"];
RussianQuestionDescriptions[QuestionIds.CONCLAVE] = ["Вы можете сыграть Действие", "Вы можете сыграть Действие"];
RussianQuestionDescriptions[QuestionIds.CRYPT_SET_ASIDE] = ["Отложите любое количество Сокровищ из игры", "Отложите любое количество"];
RussianQuestionDescriptions[QuestionIds.CRYPT_RETURN_TO_HAND] = ["Выберите карту и верните ее в руку.", "Выберите карту"];
RussianQuestionDescriptions[QuestionIds.DEVILS_WORKSHOP] = ["Получите карту стоимостью до $4.", "Получите карту"];
RussianQuestionDescriptions[QuestionIds.DRUID] = ["Какое Благодеяние получить?", "Какое Благодеяние?"];
RussianQuestionDescriptions[QuestionIds.EXORCIST_TRASH] = ["Выбросите карту из руки.", "Выбросите карту"];
RussianQuestionDescriptions[QuestionIds.EXORCIST_GAIN] = ["Получите Духа дешевле", "Получите Духа дешевле"];
RussianQuestionDescriptions[QuestionIds.FOOL] = ["Какое Благодеяние следующим?", "Какое Благодеяние следующим?"];
RussianQuestionDescriptions[QuestionIds.GOAT] = ["Вы можете выкинуть карту", "Вы можете выкинуть"];
RussianQuestionDescriptions[QuestionIds.HAUNTED_MIRROR] = ["Вы можете сбросить Действие, чтобы получить Призрака", "Вы можете сбросить"];
RussianQuestionDescriptions[QuestionIds.IMP] = ["Вы можете сыграть Действие, которого нет у вас в игре", "Что играть?"];
RussianQuestionDescriptions[QuestionIds.NECROMANCER] = ["Играйте со Свалки", "Играйте со Свалки"];
RussianQuestionDescriptions[QuestionIds.MONASTERY] = ["Вы можете выкинуть до AMOUNT0 карт из руки или карт Меди из игры.", "Вы можете выкинуть до AMOUNT0 карт"];
RussianQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_TOPDECK] = ["Положить остальные на верх колоды", "Положить остальные на верх"];
RussianQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_DISCARD] = ["Сбросьте любое количество", "Сбросьте любое количество"];
RussianQuestionDescriptions[QuestionIds.PIXIE_MODE] = ["Выкинуть Фею, чтобы получить дважды?", "Выкинуть Фею?"];
RussianQuestionDescriptions[QuestionIds.POOKA] = ["Вы можете выкинуть Сокровище (не Проклятое Золото)", "Вы можете выкинуть"];
RussianQuestionDescriptions[QuestionIds.RAIDER] = ["Сбросьте карту", "Сбросьте карту"];
RussianQuestionDescriptions[QuestionIds.SACRED_GROVE_MODE] = ["Тоже получить Argument0?", "получить Argument0?"];
RussianQuestionDescriptions[QuestionIds.SECRET_CAVE] = ["Вы можете сбросить 3 карты за +$3 на следующем ходу", "Вы можете сбросить"];
RussianQuestionDescriptions[QuestionIds.SHEPHERD] = ["Сбросьте карты Победы за +2 карты каждая", "Вы можете сбросить"];
RussianQuestionDescriptions[QuestionIds.TRAGIC_HERO] = ["Получите Сокровище", "Получите Сокровище"];
RussianQuestionDescriptions[QuestionIds.VAMPIRE] = ["Получите карту стоимостью до 5 (не Вампира)", "Получите карту"];
RussianQuestionDescriptions[QuestionIds.WISH] = ["Получите карту стоимостью до 6 в руку", "Получите карту"];
RussianQuestionDescriptions[QuestionIds.ZOMBIE_APPRENTICE] = ["Вы можете выкинуть Действие", "Вы можете выкинуть"];
RussianQuestionDescriptions[QuestionIds.ZOMBIE_MASON] = ["Вы можете получить карту", "Вы можете получить карту"];
RussianQuestionDescriptions[QuestionIds.ZOMBIE_SPY] = ["Вы можете сбросить", "Вы можете сбросить"];
RussianQuestionDescriptions[QuestionIds.WHAT_NIGHT_TO_PLAY] = ["Вы можете сыграть Ночь", "Какую Ночь сыграть?"];
RussianQuestionDescriptions[QuestionIds.LOST_IN_THE_WOODS] = ["Вы можете сбросить карту, чтобы получить Благодеяние", "Сбросить за Благодеяние?"];
RussianQuestionDescriptions[QuestionIds.START_GAME] = ["Посмотрите на вашу стартовую колоду", "Ваша стартовая колода:"];
RussianQuestionDescriptions[QuestionIds.DISMANTLE_TRASH] = ["Выкиньте карту из руки", "Выкиньте карту"];
RussianQuestionDescriptions[QuestionIds.DISMANTLE_GAIN] = ["Получите карту дешевле чем AMOUNT0", "Получите карту дешевле чем AMOUNT0"];

RussianQuestionDescriptions[QuestionIds.DUCAT] = ["Можете выкинуть карту Меди.", "Можете выкинуть карту Меди"];
RussianQuestionDescriptions[QuestionIds.IMPROVE_TRASH] = ["Можете выкинуть карту Действия из игры", "Можете усовершенствовать"];
RussianQuestionDescriptions[QuestionIds.IMPROVE_GAIN] = ["Получите карту стоимостью ровно AMOUNT0.", "Получите карту стоимостью ровно AMOUNT0"];
RussianQuestionDescriptions[QuestionIds.HIDEOUT] = ["Выкиньте карту. Если это карта Победы, получите Проклятие", "Выкиньте карту"];
RussianQuestionDescriptions[QuestionIds.MOUNTAIN_VILLAGE] = ["Положите карту из Сброса в руку", "Положите карту в руку"];
RussianQuestionDescriptions[QuestionIds.PRIEST] = ["Выкиньте карту из руки", "Выкиньте карту"];
RussianQuestionDescriptions[QuestionIds.RESEARCH_TRASH] = ["Выкиньте карту из руки", "Выкиньте карту"];
RussianQuestionDescriptions[QuestionIds.OLD_WITCH] = ["Можете выкинуть Проклятие из руки", "Можете выкинуть"];
RussianQuestionDescriptions[QuestionIds.RECRUITER] = ["Выкиньте карту из руки", "Выкиньте карту"];
RussianQuestionDescriptions[QuestionIds.SCEPTER_COMPLEX] = ["Когда играете Скипетр", "Сыграть ещё раз или +2 Монеты?"];
RussianQuestionDescriptions[QuestionIds.SCEPTER_MODE] = ["Получите +2 Монеты", "Получите +2 Монеты"];
RussianQuestionDescriptions[QuestionIds.SCEPTER_PLAY] = ["Сыграйте карту Действия из игры", "Сыграйте ещё раз"];
RussianQuestionDescriptions[QuestionIds.SCULPTOR] = ["Получите в руку карту не дороже 4$.", "Получите карту в руку"];
RussianQuestionDescriptions[QuestionIds.SEER] = ["Положите на верх колды в любом порядке", "Положите назад"];
RussianQuestionDescriptions[QuestionIds.VILLAIN] = ["Сбросьте карту стоимостью 2$ или больше.", "Сбросьте карту"];
RussianQuestionDescriptions[QuestionIds.TREASURER_COMPLEX] = ["Когда играете Казначея", "Когда играете Казначея"];
RussianQuestionDescriptions[QuestionIds.TREASURER_TRASH] = ["Выкиньте Сокровище", "Выкиньте Сокровище"];
RussianQuestionDescriptions[QuestionIds.TREASURER_GAIN] = ["Получите Сокровище в руку", "Получите Сокровище"];
RussianQuestionDescriptions[QuestionIds.TREASURER_KEY] = ["Возьмите Ключ", "Возьмите Ключ"];
RussianQuestionDescriptions[QuestionIds.TREASURER_FAIL] = ["Ничего не делать", "Ничего не делать"];
RussianQuestionDescriptions[QuestionIds.BORDER_GUARD_PUT_IN_HAND] = ["Возьмите одну из раскрытых карт в руку", "Возьмите карту в руку"];
RussianQuestionDescriptions[QuestionIds.BORDER_GUARD_MODE] = ["Какой артефакт возьмёте?", "Рог или Фонарь?"];
RussianQuestionDescriptions[QuestionIds.CATHEDRAL] = ["Выкиньте карту из руки", "Выкиньте карту"];
RussianQuestionDescriptions[QuestionIds.CITY_GATE] = ["Положите карту из руки на верх колоды.", "Положите карту на верх колоды."];
RussianQuestionDescriptions[QuestionIds.PAGEANT] = ["Можете заплатить $1 за +1 Монетку.", "$1 за +1 Монетку?"];
RussianQuestionDescriptions[QuestionIds.SEWERS] = ["Можете выкинуть до AMOUNT0 карт из руки. (" + RussianCardNames[CardNames.SEWERS].singular + ")", "Можете выкинуть до AMOUNT0 (" + RussianCardNames[CardNames.SEWERS].singular + ")"];
RussianQuestionDescriptions[QuestionIds.SILOS] = ["Можете сбросить карты Меди, +1 Карта за каждую", "Можете сбросить"];
RussianQuestionDescriptions[QuestionIds.CROP_ROTATION] = ["Сбросить карту Победы за две карты?", "Можете сбросить"];
RussianQuestionDescriptions[QuestionIds.SINISTER_PLOT] = ["Вытянуть AMOUNT0 или добавить жетон?", "Вытянуть AMOUNT0 или добавить жетон?"];
RussianQuestionDescriptions[QuestionIds.STAR_CHART] = ["Выберите карту и положите на верх колоды", "Выберите карту и положите на верх колоды"];
RussianQuestionDescriptions[QuestionIds.CAPTAIN] = ["Выберите карту Действия из Резерва не дороже $4 и сыграйте её", "Выберите карту из Резерва"];
RussianQuestionDescriptions[QuestionIds.CHURCH_SET_ASIDE] = ["Отложите до трёх карт из руки", "Отложите до трёх карт"];
RussianQuestionDescriptions[QuestionIds.CHURCH_TRASH] = ["Можете выкинуть карту из руки.", "Можете выкинуть карту"];
RussianQuestionDescriptions[QuestionIds.COURTIER_MODE] = ["Выберите NUMBER0: +1 Действие; или +1 Покупка; или +$3; или получите Золото. Выбранные эффекты должны быть разными.", "Выберите NUMBER0 эффектов"];

RussianQuestionDescriptions[QuestionIds.CAMEL_TRAIN] = ["Изгоните карты из резерва.", "Изгоните карту"];
RussianQuestionDescriptions[QuestionIds.GOATHERD] = ["Можете выкинуть карту из руки.", "Можете выкинуть карту"];
RussianQuestionDescriptions[QuestionIds.SNOWY_VILLAGE] = ["Можете использовать хуторян", "Использовать хуторян?"];
RussianQuestionDescriptions[QuestionIds.BOUNTY_HUNTER] = ["Изгоните карту из руки", "Изгоните карту"];
RussianQuestionDescriptions[QuestionIds.CARDINAL_EXILE] = ["Изгоните карту", "Изгоните карту"];
RussianQuestionDescriptions[QuestionIds.GROOM] = ["Получите карту не дороже AMOUNT0.", "Получите карту не дороже AMOUNT0"];
RussianQuestionDescriptions[QuestionIds.HOSTELRY] = ["Сбросить и получить Лошадей", "Сбросить и получить Лошадей"];
RussianQuestionDescriptions[QuestionIds.VILLAGE_GREEN] = ["Сейчас или в следующий ход?", "Сейчас или в следующий ход?"];
RussianQuestionDescriptions[QuestionIds.BARGE] = ["Сейчас или в следующий ход?", "Сейчас или в следующий ход?"];
RussianQuestionDescriptions[QuestionIds.DISPLACE_EXILE] = ["Изгоните карту из руки", "Изгоните карту"];
RussianQuestionDescriptions[QuestionIds.DISPLACE_GAIN] = ["Получите карту не дороже AMOUNT0.", "Получите карту не дороже AMOUNT0"];
RussianQuestionDescriptions[QuestionIds.FALCONER] = ["Получите карту в руку", "Получите карту в руку"];
RussianQuestionDescriptions[QuestionIds.HUNTING_LODGE] = ["Сбросить и вытянуть 5 карт?", "Сбросить и вытянуть 5 карт?"];
RussianQuestionDescriptions[QuestionIds.MASTERMIND] = ["Можете сыграть карту Действия три раза.", "Можете сыграть карту Действия три раза"];
RussianQuestionDescriptions[QuestionIds.SANCTUARY] = ["Можете изгнать карту из руки", "Можете изгнать карту"];
RussianQuestionDescriptions[QuestionIds.WAYFARER] = ["Можете получить Серебро", "Можете получить Серебро"];
RussianQuestionDescriptions[QuestionIds.DELAY] = ["Можете отложить карту Действия", "Можете отложить карту Действия"];
RussianQuestionDescriptions[QuestionIds.DESPERATION] = ["Можете получить Проклятие", "Можете получить Проклятие"];
RussianQuestionDescriptions[QuestionIds.GAMBLE] = ["Сыграть Argument0?", "Сыграть Argument0?"];
RussianQuestionDescriptions[QuestionIds.PURSUE] = ["Какую карту оставить наверху?", "Какую карту оставить наверху?"];
RussianQuestionDescriptions[QuestionIds.TOIL] = ["Можете сыграть карту", "Можете сыграть карту"];
RussianQuestionDescriptions[QuestionIds.ENHANCE_TRASH] = ["Можете выкинуть карту (не карту Победы)", "Можете выкинуть карту"];
RussianQuestionDescriptions[QuestionIds.ENHANCE_GAIN] = ["Получите карту не дороже AMOUNT0.", "Получите карту не дороже AMOUNT0"];
RussianQuestionDescriptions[QuestionIds.MARCH] = ["Можете сыграть карту из сброса", "Можете сыграть карту из сброса"];
RussianQuestionDescriptions[QuestionIds.TRANSPORT_MAY_EXILE] = ["Можете изгнать карту Действия", "Можете изгнать карту Действия"];
RussianQuestionDescriptions[QuestionIds.TRANSPORT_EXILE] = ["Изгоните карту Действия", "Изгоните карту Действия"];
RussianQuestionDescriptions[QuestionIds.TRANSPORT_MAY_TOPDECK] = ["Можете положить изнанную карту Действия на верх колоды", "Можете положить изнанную карту Действия на верх колоды"];
RussianQuestionDescriptions[QuestionIds.TRANSPORT_TOPDECK] = ["Положите изнанную карту Действия на верх колоды", "Положите изнанную карту Действия на верх колоды"];
RussianQuestionDescriptions[QuestionIds.TRANSPORT_COMPLEX] = ["Изгоните из Резерва; или положите на верх колоды из Изгнания", "Изгоните из Резерва; или положите на верх колоды из Изгнания"];
RussianQuestionDescriptions[QuestionIds.BANISH] = ["Изгоните карты с одинаковыми именами", "Изгоните карты с одинаковыми именами"];
RussianQuestionDescriptions[QuestionIds.BARGAIN] = ["Получите карту не дороже $5 (не карту Победы)", "Получите карту не дороже $5 (не карту Победы)"];
RussianQuestionDescriptions[QuestionIds.INVEST] = ["Изгоните карту Действия чтобы Инвесировать в неё", "Изгоните карту Действия чтобы Инвесировать в неё"];
RussianQuestionDescriptions[QuestionIds.DEMAND] = ["Получите карту не дороже $4 на верх колоды", "Получите карту не дороже $4 на верх колоды"];
RussianQuestionDescriptions[QuestionIds.POPULATE] = ["Какую следующую карту получить?", "Какую следующую карту получить?"];
RussianQuestionDescriptions[QuestionIds.RECONSIDER_WAY] = ["Пересмотрите Путь, с которым вы играли Argument0", "Пересмотрите Путь, с которым вы играли Argument0"];
RussianQuestionDescriptions[QuestionIds.CONSIDER_WAY] = ["Рассмотрите Путь, с которым вы играли Argument0", "Рассмотрите Путь, с которым вы играли Argument0"];
RussianQuestionDescriptions[QuestionIds.WAY_OF_THE_GOAT] = ["Выкиньте карту из руки.", "Выкиньте карту из руки."];
RussianQuestionDescriptions[QuestionIds.WAY_OF_THE_RAT] = ["Сбросьте Сокровище и получите копию", "Сбросьте Сокровище и получите копию"];
RussianQuestionDescriptions[QuestionIds.SCRAP_TRASH] = ["Выкиньте карту из руки.", "Выкиньте карту из руки."];
RussianQuestionDescriptions[QuestionIds.SCRAP_MODES] = ["Выберите эффекты для Argument0", "Выберите эффекты для Argument0"];
RussianQuestionDescriptions[QuestionIds.ANIMAL_FAIR_TRASH] = ["Выкиньте карту Действия из руки", "Выкиньте карту Действия из руки"];
RussianQuestionDescriptions[QuestionIds.ANIMAL_FAIR_MAY_TRASH] = ["Можете выкинуть карту Действия из руки", "Можете выкинуть карту Действия из руки"];
RussianQuestionDescriptions[QuestionIds.BUTTERFLY_RETURN] = ["Вернуть Argument0 в стопку?", "Вернуть Argument0 в соответствующую стопку?"];
RussianQuestionDescriptions[QuestionIds.BUTTERFLY_GAIN] = RussianQuestionDescriptions[QuestionIds.UPGRADE_GAIN];

RussianQuestionDescriptions[QuestionIds.BLOCKADE] = ["", "Получите карту"];
RussianQuestionDescriptions[QuestionIds.PIRATE] = ["", "Получите карту в руку"];
RussianQuestionDescriptions[QuestionIds.SAILOR] = ["", "Можете выкинуть карту"];
RussianQuestionDescriptions[QuestionIds.TIDE_POOLS] = RussianQuestionDescriptions[QuestionIds.DUNGEON];
RussianQuestionDescriptions[QuestionIds.SEA_WITCH] = RussianQuestionDescriptions[QuestionIds.DUNGEON];

RussianQuestionDescriptions[QuestionIds.TIARA_MODE] = ["", "Как сыграть Тиару?"];
RussianQuestionDescriptions[QuestionIds.TIARA_PLAY] = ["", "Какое Сокровище сыграть дважды?"];
RussianQuestionDescriptions[QuestionIds.CRYSTAL_BALL] = ["", "Что делать с Argument0?"];
RussianQuestionDescriptions[QuestionIds.WAR_CHEST_PROHIBIT] = ["", "Запретить карту"];
RussianQuestionDescriptions[QuestionIds.WAR_CHEST_GAIN] = ["", "Получить карту"];
RussianQuestionDescriptions[QuestionIds.ANVIL_DISCARD] = RussianQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_DISCARD];
RussianQuestionDescriptions[QuestionIds.ANVIL_GAIN] = RussianQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_GAIN];
RussianQuestionDescriptions[QuestionIds.CLERK] = ["", "Положить на верх колоды"];
RussianQuestionDescriptions[QuestionIds.INVESTMENT_TRASH] = RussianQuestionDescriptions[QuestionIds.TRADE_ROUTE];
RussianQuestionDescriptions[QuestionIds.INVESTMENT_MODE] = ["", "Как сыграть Вложение?"];

// Allies
RussianQuestionDescriptions[QuestionIds.TOWN_MODE] = ["", "Как сыграть Городок?"];
RussianQuestionDescriptions[QuestionIds.MODIFY_TRASH] = ["", "Выкиньте карту"];
RussianQuestionDescriptions[QuestionIds.MODIFY_MODE] = ["", "Как сыграть Модификацию?"];
RussianQuestionDescriptions[QuestionIds.MODIFY_GAIN] = ["", "Получите карту"];
RussianQuestionDescriptions[QuestionIds.SWAP_RETURN] = ["", "Можете вернуть карту"];
RussianQuestionDescriptions[QuestionIds.SWAP_GAIN] = ["", "Получите карту Действия"];
RussianQuestionDescriptions[QuestionIds.SKIRMISHER] = ["", "Сбросьте до трёх"];
RussianQuestionDescriptions[QuestionIds.WOODWORKERS_GUILD_TRASH] = ["", "Выкиньте карту Действия"];
RussianQuestionDescriptions[QuestionIds.WOODWORKERS_GUILD_GAIN] = ["", "Получите карту Действия"];
RussianQuestionDescriptions[QuestionIds.CRAFTERS_GUILD] = ["", "Потратить две услуги?"];
RussianQuestionDescriptions[QuestionIds.CAVE_DWELLERS] = ["", "Потратить Услугу?"];
RussianQuestionDescriptions[QuestionIds.DESERT_GUIDES] = ["", "Сохранить руку?"];
RussianQuestionDescriptions[QuestionIds.BAND_OF_NOMADS] = ["", "Потратить Услуги за Покупки?"];
RussianQuestionDescriptions[QuestionIds.SYCOPHANT] = ["", "Сбросьте три карты"];
RussianQuestionDescriptions[QuestionIds.IMPORTER] = ["", "Получите карту не дороже $5"];
RussianQuestionDescriptions[QuestionIds.CONTRACT] = ["Y", "Можете сыграть карту Действия в следующем ходу"];
RussianQuestionDescriptions[QuestionIds.BAUBLE] = ["", "Как сыграть Безделушку?"];
RussianQuestionDescriptions[QuestionIds.BROKER_TRASH] = ["", "Что выкинуть?"];
RussianQuestionDescriptions[QuestionIds.BROKER_MODE] = ["W", "Выберите опцию"];
RussianQuestionDescriptions[QuestionIds.MARKET_TOWNS_FIRST] = ["", "Какое Действие сыграть?"];
RussianQuestionDescriptions[QuestionIds.MARKET_TOWNS_AGAIN] = ["", "Сыграть ещё Действие за Услугу?"];
RussianQuestionDescriptions[QuestionIds.BATTLE_PLAN_REVEAL] = ["", "Раскрыть Атаку за Карту?"];
RussianQuestionDescriptions[QuestionIds.BATTLE_PLAN_ROTATE] = ["", "Прокрутить стопку?"];
RussianQuestionDescriptions[QuestionIds.BARBARIAN] = ["", "Получите карту дешевле"];
RussianQuestionDescriptions[QuestionIds.INNKEEPER_MODE] = ["", "Как сыграть Трактирщика?"];
RussianQuestionDescriptions[QuestionIds.INNKEEPER_DISCARD] = ["", "Сбросьте Argument0 Карт"];
RussianQuestionDescriptions[QuestionIds.CAPITAL_CITY_MODE] = ["", "Сбросить или заплатить?"];
RussianQuestionDescriptions[QuestionIds.CAPITAL_CITY_DISCARD] = ["", "Сбросьте 2 Карты"];
RussianQuestionDescriptions[QuestionIds.SPECIALIST_PLAY] = ["", "Какую карту сыграть?"];
RussianQuestionDescriptions[QuestionIds.SPECIALIST_MODE] = ["", "Сыграть Argument0 ещё раз или получить копию?"];
RussianQuestionDescriptions[QuestionIds.GANG_OF_PICKPOCKETS] = ["", "Сбросить или потратить Услугу?"];
RussianQuestionDescriptions[QuestionIds.COASTAL_HAVEN] = ["", "Какие карты оставить в руке?"];
RussianQuestionDescriptions[QuestionIds.CARPENTER_GAIN] = RussianQuestionDescriptions[QuestionIds.WORKSHOP];
RussianQuestionDescriptions[QuestionIds.CARPENTER_TRASH] = RussianQuestionDescriptions[QuestionIds.REMODEL_TRASH];
RussianQuestionDescriptions[QuestionIds.CARPENTER_GAIN_UP_TO] = RussianQuestionDescriptions[QuestionIds.REMODEL_GAIN];
RussianQuestionDescriptions[QuestionIds.MAY_ROTATE_FORTS] = ["", "Можете прокрутить Форты"];
RussianQuestionDescriptions[QuestionIds.ROYAL_GALLEY] = ["", "Можете сыграть карту Действия"];
RussianQuestionDescriptions[QuestionIds.HILL_FORT_GAIN] = RussianQuestionDescriptions[QuestionIds.WORKSHOP];
RussianQuestionDescriptions[QuestionIds.HILL_FORT_MODE] = ["", "Как сыграть Форт на Холме?"];
RussianQuestionDescriptions[QuestionIds.STRONGHOLD_MODE] = ["", "Как сыграть Оплот?"];
RussianQuestionDescriptions[QuestionIds.MAY_ROTATE_ODYSSEYS] = ["Y", "Можете прокрутить Одиссеи"];
RussianQuestionDescriptions[QuestionIds.OLD_MAP_DISCARD] = ["", "Сбросьте карту"];
RussianQuestionDescriptions[QuestionIds.SUNKEN_TREASURE] = ["", "Получите карту Действия которой нет у вас в игре"];
RussianQuestionDescriptions[QuestionIds.PEACEFUL_CULT] = ["", "Можете выбрасывать за Услуги"];
RussianQuestionDescriptions[QuestionIds.ORDER_OF_ASTROLOGERS] = ["", "Можете тратить Услуги и класть на верх колоды"];
RussianQuestionDescriptions[QuestionIds.STAR_CHART_ASTROLOGERS_COMBINED] = ["", "Можете тратить Услуги и класть на верх колоды (первый раз бесплатно)"];
RussianQuestionDescriptions[QuestionIds.ORDER_OF_MASONS] = ["", "Можете выбирать по две карты за Услугу чтобы положить их в Сброс"];
RussianQuestionDescriptions[QuestionIds.ARCHER_HIDE] = ["", "Выберите карту которую не хотите раскрывать"];
RussianQuestionDescriptions[QuestionIds.ARCHER_DISCARD] = RussianQuestionDescriptions[QuestionIds.PILLAGE];
RussianQuestionDescriptions[QuestionIds.MARQUIS] = ["", "Сбросьте до десяти"];
RussianQuestionDescriptions[QuestionIds.HERB_GATHERER_PLAY] = ["", "Какое Сокровище сыграть?"];
RussianQuestionDescriptions[QuestionIds.MAY_ROTATE_AUGURS] = ["", "Можете прокрутить Авгуров"];
RussianQuestionDescriptions[QuestionIds.ACOLYTE] = ["", "Выкинуть и получить Золото?"];
RussianQuestionDescriptions[QuestionIds.ACOLYTE_SELF] = ["", "Выкинуть и получить Авгура?"];
RussianQuestionDescriptions[QuestionIds.SORCERESS] = ["", "Угадайте карту"];
RussianQuestionDescriptions[QuestionIds.SIBYL_TOPDECK] = ["", "Положите на верх колоды"];
RussianQuestionDescriptions[QuestionIds.SIBYL_BOTTOMDECK] = ["", "Положите под низ"];
RussianQuestionDescriptions[QuestionIds.MAY_ROTATE_WIZARDS] = ["", "Можете прокрутить Волшебников"];
RussianQuestionDescriptions[QuestionIds.STUDENT] = ["", "Выкиньте карту"];
RussianQuestionDescriptions[QuestionIds.CONJURER] = RussianQuestionDescriptions[QuestionIds.WORKSHOP];
RussianQuestionDescriptions[QuestionIds.SORCERER] = ["", "Угадайте карту"];
RussianQuestionDescriptions[QuestionIds.LICH] = ["", "Получите карту со Свалки"];
RussianQuestionDescriptions[QuestionIds.MAY_ROTATE_TOWNSFOLK] = ["", "Можете прокрутить Горожан"];
RussianQuestionDescriptions[QuestionIds.TOWN_CRIER] = ["", "Как сыграть Городского Плакальщика?"];
RussianQuestionDescriptions[QuestionIds.MILLER] = ["", "Как сыграть Мельника?"];
RussianQuestionDescriptions[QuestionIds.ELDER] = ["", "Можете сыграть карту"];
RussianQuestionDescriptions[QuestionIds.SENTINEL_TRASH] = ["", "Выкиньте до двух карт"];
RussianQuestionDescriptions[QuestionIds.SENTINEL_TOPDECK] = RussianQuestionDescriptions[QuestionIds.APOTHECARY];
RussianQuestionDescriptions[QuestionIds.COURIER] = ["", "Можете сыграть карту"];
RussianQuestionDescriptions[QuestionIds.HUNTER_ACTION] = ["", "Положите карту Действия в руку"];
RussianQuestionDescriptions[QuestionIds.HUNTER_TREASURE] = ["", "Положите Сокровище в руку"];
RussianQuestionDescriptions[QuestionIds.HUNTER_VICTORY] = ["", "Положите карту Победы в руку"];
RussianQuestionDescriptions[QuestionIds.LURKER_MODE] = ["", "Как сыграть Притаившегося?"];
RussianQuestionDescriptions[QuestionIds.GRAVEROBBER_MODE] = ["", "Как сыграть Расхитителя Могил?"];
RussianQuestionDescriptions[QuestionIds.TREASURER_MODE] = ["", "Как сыграть Казначея?"];
RussianQuestionDescriptions[QuestionIds.ARCHITECTS_GUILD] = ["", "Что получить?"];
RussianQuestionDescriptions[QuestionIds.FAMILY_OF_INVENTORS] = ["", "Какую стопку сделать дешевле?"];
RussianQuestionDescriptions[QuestionIds.FOREST_DWELLERS_DISCARD] = ["", "Сбросьте любое количество"];
RussianQuestionDescriptions[QuestionIds.FOREST_DWELLERS_TOPDECK] = ["", "Положите на верх колоды"];
RussianQuestionDescriptions[QuestionIds.USE_FAVOR_WHEN_SHUFFLING] = ["", "Потратить услугу?"];
RussianQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS] = ["", "Можете потратить Монетки"];
RussianQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_BASILICA] = ["", "Можете потратить Монетки для Базилики"];
RussianQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_SWASHBUCKLER] = ["", "Можете потратить Монетки чтобы на получать Сундук с Сокровищами"];
RussianQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_FORTUNE] = ["", "Можете потратить Монетки перед удваиванием"];

RussianQuestionDescriptions[QuestionIds.WHEELWRIGHT_DISCARD] = ["", "Сбросить карту"];
RussianQuestionDescriptions[QuestionIds.WHEELWRIGHT_GAIN] = ["", "Получите карту не дороже AMOUNT0"];
RussianQuestionDescriptions[QuestionIds.BERSERKER_DISCARD] = RussianQuestionDescriptions[QuestionIds.MILITIA];
RussianQuestionDescriptions[QuestionIds.BERSERKER_GAIN] = ["", "Получите карту дешевле"];
RussianQuestionDescriptions[QuestionIds.WEAVER] = ["", "Получите карту или 2 Серебра"];
RussianQuestionDescriptions[QuestionIds.SOUK] = ["", "Выкиньте до двух карт"];
RussianQuestionDescriptions[QuestionIds.WITCHS_HUT] = ["", "Сбросьте 2 карты"];
RussianQuestionDescriptions[QuestionIds.STONEMASON_OVERPAY_AMOUNT] = ["Можете переплатить за Каменщика, получив карту Действия стоимостью до AMOUNT0.", "Сколько переплачивать?"];

//Plunder
RussianQuestionDescriptions[QuestionIds.CAGE_SET_ASIDE] = ["Можете отложить до четврёх карт.", "Можете отложить до четврёх карт."];
RussianQuestionDescriptions[QuestionIds.GROTTO_SET_ASIDE] = ["Можете отложить до четврёх карт.", "Можете отложить до четврёх карт."];
RussianQuestionDescriptions[QuestionIds.SHAMAN_GAIN] = ["Получите карту не дороже 6 со Свалки.", "Получите карту не дороже 6 со Свалки."];
RussianQuestionDescriptions[QuestionIds.SHAMAN_TRASH] = ["Можете выкинуть карту из руки.", "Можете выкинуть карту из руки."];
RussianQuestionDescriptions[QuestionIds.SECLUDED_SHRINE] = ["Можете выкинуть до двух карт из руки.", "Можете выкинуть до двух карт из руки."];
RussianQuestionDescriptions[QuestionIds.SIREN] = ["Можете выкинуть карту Действия.", "Можете выкинуть карту Действия."];
RussianQuestionDescriptions[QuestionIds.CABIN_BOY_MODE] = ["Выберите: +2 Монеты, или выкиньте [Cabin Boy] чтобы получить Длительность.", "Выберите: +2 Монеты, или выкиньте [Cabin Boy] чтобы получить Длительность."];
RussianQuestionDescriptions[QuestionIds.CABIN_BOY_GAIN] = ["Получите карту Длительности.", "Получите карту Длительности."];
RussianQuestionDescriptions[QuestionIds.CRUCIBLE] = ["Выкиньте карту из руки.", "Выкиньте карту из руки."];
RussianQuestionDescriptions[QuestionIds.FORTUNE_HUNTER_PLAY] = ["Сыграйте Сокровище.", "Сыграйте Сокровище."];
RussianQuestionDescriptions[QuestionIds.FORTUNE_HUNTER_TOPDECK] = ["Положите на верх колоды.", "Положите на верх колоды."];
RussianQuestionDescriptions[QuestionIds.GONDOLA_MODE] = ["+2 Монеты сейчас или потом?", "+2 Монеты сейчас или потом?"];
RussianQuestionDescriptions[QuestionIds.GONDOLA_PLAY] = ["Можете сыграть Действие из руки.", "Можете сыграть Действие из руки."];
RussianQuestionDescriptions[QuestionIds.MAPMAKER] = ["Положите две карты в руку.", "Положите две карты в руку."];
RussianQuestionDescriptions[QuestionIds.MAROON] = ["Выкиньте карту.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.ROPE_TRASH] = ["Можете выкинуть карту из руки.", "Можете выкинуть карту из руки."];
RussianQuestionDescriptions[QuestionIds.TOOLS] = ["Получите копию карты, которая есть у кого-нибудь в игре.", "Получите копию карты, которая есть у кого-нибудь в игре."];
RussianQuestionDescriptions[QuestionIds.CUTTHROAT_DISCARD] = ["Сбросьте до трёх карт в руке.", "Сбросьте до трёх карт в руке."];
RussianQuestionDescriptions[QuestionIds.ENLARGE_TRASH] = ["Выкиньте карту.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.ENLARGE_GAIN] = ["Получите карту не дороже AMOUNT0.", "Получите карту не дороже AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.FIGURINE] = ["Можете сбросить Действие за +1 Монету и +1 Покупку.", "Можете сбросить Действие за +1 Монету и +1 Покупку."];
RussianQuestionDescriptions[QuestionIds.FIRST_MATE] = ["Можете сыграть карту.", "Можете сыграть карту."];
RussianQuestionDescriptions[QuestionIds.FRIGATE] = ["Сбросьте до четырёх карт в руке.", "Сбросьте до четырёх карт в руке."];
RussianQuestionDescriptions[QuestionIds.PICKAXE] = ["Выкиньте карту и руки.", "Выкиньте карту и руки."];
RussianQuestionDescriptions[QuestionIds.PILGRIM_TOPDECK] = ["Положите карту из руки на верх колоды.", "Положите карту из руки на верх колоды."];
RussianQuestionDescriptions[QuestionIds.QUARTERMASTER_MODE] = ["Получите карту не дороже 4, или возьмите карту с Argument0.", "Получите карту не дороже 4, или возьмите карту с Argument0."];
RussianQuestionDescriptions[QuestionIds.QUARTERMASTER_GAIN] = ["Получите карту не дороже 4.", "Получите карту не дороже 4."];
RussianQuestionDescriptions[QuestionIds.QUARTERMASTER_TAKE] = ["Положите карту в руку.", "Положите карту в руку."];
RussianQuestionDescriptions[QuestionIds.TRICKSTER] = ["Можете отложите Сокровище из игры в сторону.", "Можете отложите Сокровище из игры в сторону."];
RussianQuestionDescriptions[QuestionIds.SILVER_MINE] = ["Получите Сокровище в руку.", "Получите Сокровище в руку."];
RussianQuestionDescriptions[QuestionIds.KINGS_CACHE] = ["Можете сыграть Сокровище 3 раза.", "Можете сыграть Сокровище 3 раза."];
RussianQuestionDescriptions[QuestionIds.BURY] = ["Положите карту под низ колоды.", "Положите карту под низ колоды."];
RussianQuestionDescriptions[QuestionIds.PERIL] = ["Можете выкинуть Действие чтобы получить Грабленое.", "Можете выкинуть Действие чтобы получить Грабленое."];
RussianQuestionDescriptions[QuestionIds.PREPARE] = ["Сыграйте отложенную карту.", "Сыграйте отложенную карту."];
RussianQuestionDescriptions[QuestionIds.FORAY] = ["Сбросьте три карты.", "Сбросьте три карты."];
RussianQuestionDescriptions[QuestionIds.SCROUNGE_COMPLEX] = ["Когда играете Попрошайничество,", "Когда играете Попрошайничество,"];
RussianQuestionDescriptions[QuestionIds.SCROUNGE_TRASH] = ["Выкиньте карту из руки.", "Выкиньте карту из руки."];
RussianQuestionDescriptions[QuestionIds.SCROUNGE_MAY_TRASH] = ["Можете выкинуть карту из руки.", "Можете выкинуть карту из руки."];
RussianQuestionDescriptions[QuestionIds.SCROUNGE_GAIN_ESTATE] = ["Получите Поместье.", "Получите Поместье."];
RussianQuestionDescriptions[QuestionIds.SCROUNGE_MAY_GAIN_ESTATE] = ["Можете получить Поместье.", "Можете получить Поместье."];
RussianQuestionDescriptions[QuestionIds.SCROUNGE_GAIN] = ["Получите карту не дороже пяти.", "Получите карту не дороже пяти."];
RussianQuestionDescriptions[QuestionIds.MAELSTROM_TRASH] = ["Выкиньте карту.", "Выкиньте карту."];
RussianQuestionDescriptions[QuestionIds.MAELSTROM_OPPONENT_TRASH] = ["Выкиньте Argument0 карт.", "Выкиньте Argument0 карт."];
RussianQuestionDescriptions[QuestionIds.INVASION_PLAY] = ["Можете сыграть Атаку.", "Можете сыграть Атаку."];
RussianQuestionDescriptions[QuestionIds.INVASION_GAIN] = ["Получите карту Действия.", "Получите карту Действия."];
RussianQuestionDescriptions[QuestionIds.PROSPER] = ["Получите другое Сокровище.", "Получите другое Сокровище."];
RussianQuestionDescriptions[QuestionIds.AMPHORA_MODE] = ["+1 Покупка, +3 Монеты сейчас или потом?", "+1 Покупка, +3 Монеты сейчас или потом?"];
RussianQuestionDescriptions[QuestionIds.HAMMER] = ["Получите карту не дороже 4.", "Получите карту не дороже 4."];
RussianQuestionDescriptions[QuestionIds.ORB] = ["Сыграйте Действие или Сокровище.", "Сыграйте Действие или Сокровище."];
RussianQuestionDescriptions[QuestionIds.PRIZE_GOAT] = ["Можете выкинуть карту.", "Можете выкинуть карту."];
RussianQuestionDescriptions[QuestionIds.PUZZLE_BOX] = ["Отложите карту в сторону, положите её в руку в конце хода.", "Отложите в сторону"];
RussianQuestionDescriptions[QuestionIds.SEXTANT_DISCARD] = ["Сбросьте любое количество карт.", "Сбросьте любое количество карт."];
RussianQuestionDescriptions[QuestionIds.SEXTANT_TOPDECK] = ["Положите назад в любом порядке.", "Положите назад в любом порядке."];
RussianQuestionDescriptions[QuestionIds.SPELL_SCROLL_GAIN] = ["Получите карту дешевле чем AMOUNT0.", "Получите карту дешевле чем AMOUNT0."];
RussianQuestionDescriptions[QuestionIds.SPELL_SCROLL_PLAY] = ["Сыграть Argument0?", "Сыграть Argument0?"];
RussianQuestionDescriptions[QuestionIds.STAFF] = ["Можете сыграть карту Действия.", "Можете сыграть карту Действия."];
RussianQuestionDescriptions[QuestionIds.SWORD] = ["Сбросьте до четырёх карт в руке.", "Сбросьте до четырёх карт в руке."];
RussianQuestionDescriptions[QuestionIds.FRIENDLY] = ["Сбросьте [Friendly] карту, получите ещё одну.", "Сбросьте [Friendly] карту, получите ещё одну."];
RussianQuestionDescriptions[QuestionIds.INHERITED] = ["Замените начальную карту на [Inherited].", "Замените начальную карту на [Inherited]."];
RussianQuestionDescriptions[QuestionIds.INSPIRING] = ["Можете сыграть Действие, которого у вас нет в игре.", "Можете сыграть Действие, которого у вас нет в игре ([Inspiring])."];
RussianQuestionDescriptions[QuestionIds.PATIENT_SET_ASIDE] = ["Отложите [Patient] карты, чтобы сыграть из в следующем ходу.", "Отложите [Patient] карты, чтобы сыграть из в следующем ходу."];
RussianQuestionDescriptions[QuestionIds.PATIENT_PLAY] = ["Какую [Patient] карту сыграть следующей?", "Какую [Patient] карту сыграть следующей?"];
RussianQuestionDescriptions[QuestionIds.PIOUS_TRASH] = ["Можете выкинуть карту из руки.", "Можете выкинуть карту из руки."];
RussianQuestionDescriptions[QuestionIds.SHY] = ["Сбросьте [Shy] карту за +2 Карты.", "Сбросьте [Shy] карту за +2 Карты."];
RussianQuestionDescriptions[QuestionIds.FATED_TOPDECK] = ["Можете положить [Fated] карты на верх колоды.", "Можете положить [Fated] карты на верх колоды."];
RussianQuestionDescriptions[QuestionIds.FATED_BOTTOMDECK] = ["Можете положить [Fated] карты под низ колоды.", "Можете положить [Fated] карты под низ колоды."];
RussianQuestionDescriptions[QuestionIds.AVOID] = ["Положите до трёх карт в сброс.", "Положите до трёх карт в сброс."];
RussianQuestionDescriptions[QuestionIds.TRICKSTER_DISCARD] = ["Какое Сокровище отложить?", "Какое Сокровище отложить?"];
RussianQuestionDescriptions[QuestionIds.BAND_OF_MISFITS] = ["Сыграйте карту дешевле этой.", "Сыграйте карту"];
RussianQuestionDescriptions[QuestionIds.DEATH_CART] = ["Можете выкинуть карту Действия из руки или Труповозку из игры.", "Можете выкинуть Труповозку или карту Действия."];
RussianQuestionDescriptions[QuestionIds.OVERLORD] = ["Сыграйте карту Действия не дороже $5. ", "Сыграйте карту"];
RussianQuestionDescriptions[QuestionIds.HORN_TOPDECK] = ["Какого [Border Guard] положить наверх?", "Какого [Border Guard] положить наверх?"];
RussianQuestionDescriptions[QuestionIds.BLACKSMITH] = ["Как сыграть Кузнеца?", "Как сыграть Кузнеца?"];
RussianQuestionDescriptions[QuestionIds.MARCHLAND] = ["", "Можете сбросить карты Победы по +$1 за каждую"];

RussianQuestionDescriptions[QuestionIds.FARMHANDS] = ["Какую карту сыграть в начале вашего следующего хода?.", "Какую карту сыграть?"];
RussianQuestionDescriptions[QuestionIds.COURSER] = ["Выберите как сыграть Скакуна.", "Как сыграть Скакуна"];
RussianQuestionDescriptions[QuestionIds.CORONET_ACTION] = ["Какое Действие сыграть дважды?", "Какое Действие сыграть дважды?"];
RussianQuestionDescriptions[QuestionIds.CORONET_TREASURE] = ["Какое Сокровище сыграть дважды?", "Какое Сокровище сыграть дважды?"];
RussianQuestionDescriptions[QuestionIds.JOUST_SET_ASIDE] = ["Отложить Провинцию в сторону?", "Отложить Провинцию в сторону?"];
RussianQuestionDescriptions[QuestionIds.JOUST_GAIN_REWARD] = ["Какую Награду получить в руку?", "Выберите Награду"];
RussianQuestionDescriptions[QuestionIds.INFIRMARY_TRASH] = ["Можете выкинуть карту.", "Можете выкинуть карту."];
RussianQuestionDescriptions[QuestionIds.INFIRMARY_OVERPAY] = ["Можете переплатить за Лазарет.", "Можете переплатить"];
RussianQuestionDescriptions[QuestionIds.FOOTPAD] = RussianQuestionDescriptions[QuestionIds.MILITIA];
RussianQuestionDescriptions[QuestionIds.FARRIER_OVERPAY] = ["Можете переплатить за Паромщика.", "Можете переплатить"];
RussianQuestionDescriptions[QuestionIds.SHOP] = RussianQuestionDescriptions[QuestionIds.CONCLAVE];
RussianQuestionDescriptions[QuestionIds.FERRYMAN] = RussianQuestionDescriptions[QuestionIds.FUGITIVE];

RussianQuestionDescriptions[QuestionIds.ORDER_BOTTOMDECKED_CARDS] = ["Выберите порядок внизу колоды", "Выберите порядок"];
RussianQuestionDescriptions[QuestionIds.GROWTH] = RussianQuestionDescriptions[QuestionIds.BORDER_VILLAGE];
RussianQuestionDescriptions[QuestionIds.KIND_EMPEROR] = ["Получите Действие в руку", "Получите Действие"];
RussianQuestionDescriptions[QuestionIds.SICKNESS_MODE] = ["", ""];
RussianQuestionDescriptions[QuestionIds.SICKNESS_DISCARD] = ["", ""];
RussianQuestionDescriptions[QuestionIds.SICKNESS] = ["Сбросьте три карты или получите Проклятие на верх колоды", "Сбосьте карты или получите Проклятие"];
RussianQuestionDescriptions[QuestionIds.SNAKE_WITCH] = ["Вернть [Snake Witch] чтобы каждый другой игрок получил Проклятие?", "Вернуть [Snake Witch] и раздать Проклятия?"];
RussianQuestionDescriptions[QuestionIds.CRAFTSMAN] = ["Получите карту не дороже 5 монет.", "Получите карту не дороже 5 монет."];
RussianQuestionDescriptions[QuestionIds.ALLEY] = RussianQuestionDescriptions[QuestionIds.OASIS];
RussianQuestionDescriptions[QuestionIds.CHANGE_TRASH] = ["Выкиньте карту из руки", "Выкиньте карту из руки"];
RussianQuestionDescriptions[QuestionIds.CHANGE_GAIN] = ["Получите карту и возьмите Долг", "Получите карту и возьмите Долг"];
RussianQuestionDescriptions[QuestionIds.NINJA] = RussianQuestionDescriptions[QuestionIds.MILITIA];
RussianQuestionDescriptions[QuestionIds.RIVER_SHRINE_TRASH] = ["Выкиньте до двух карт", "Выкиньте до двух карт"];
RussianQuestionDescriptions[QuestionIds.RIVER_SHRINE_GAIN] = ["Получите карту не дороже четырёх.", "Получите карту не дороже четырёх."];
RussianQuestionDescriptions[QuestionIds.RUSTIC_VILLAGE_CHOICE] = ["Сбросить две карты и вытянуть одну?", "Сбросить две карты и вытянуть одну?"];
RussianQuestionDescriptions[QuestionIds.RUSTIC_VILLAGE_DISCARD] = ["Какие две карты сбросить?", "Какие две карты сбросить?"];
RussianQuestionDescriptions[QuestionIds.GOLD_MINE] = ["Получить Золото и 4 долга?", "Получить Золото и 4 долга?"];
RussianQuestionDescriptions[QuestionIds.KITSUNE] = ["Выберите как сыграть Кицунэ", "Выберите как сыграть Кицунэ"];
RussianQuestionDescriptions[QuestionIds.TANUKI_TRASH] = RussianQuestionDescriptions[QuestionIds.REMODEL_TRASH];
RussianQuestionDescriptions[QuestionIds.TANUKI_GAIN] = RussianQuestionDescriptions[QuestionIds.REMODEL_GAIN];
RussianQuestionDescriptions[QuestionIds.SAMURAI] = RussianQuestionDescriptions[QuestionIds.MILITIA];
RussianQuestionDescriptions[QuestionIds.MOUNTAIN_SHRINE] = ["Можете выкинуть карту из руки.", "Можете выкинуть карту из руки."];
RussianQuestionDescriptions[QuestionIds.AMASS] = ["Получите Действие не дороже 5 монет.", "Получите Действие не дороже 5 монет."];
RussianQuestionDescriptions[QuestionIds.ASCETICISM] = ["Выкиньте до Argument0 карт.", "Выкиньте до Argument0 карт."];
RussianQuestionDescriptions[QuestionIds.CONTINUE] = ["Получите и сыграйте карту Действия (не Атаку).", "Получите и сыграйте карту Действия (не Атаку)."];
RussianQuestionDescriptions[QuestionIds.CREDIT] = ["Получите Действие или Сокровище не дороже 8 монет.", "Получите Действие или Сокровище не дороже 8 монет."];
RussianQuestionDescriptions[QuestionIds.KINTSUGI_TRASH] = ["Выкиньте карту из руки.", "Выкиньте карту из руки."];
RussianQuestionDescriptions[QuestionIds.KINTSUGI_GAIN] = RussianQuestionDescriptions[QuestionIds.REMODEL_GAIN];
RussianQuestionDescriptions[QuestionIds.PRACTICE] = RussianQuestionDescriptions[QuestionIds.THRONE_ROOM];
RussianQuestionDescriptions[QuestionIds.SEA_TRADE] = ["Выкиньте до Argument0 карт.", "Выкиньте до Argument0 карт."];
RussianQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_FIRST] = ["Можете получить 3 карты Действия которых нет у вас в игре.", "Можете получить 3 карты Действия которых нет у вас в игре."];
RussianQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_SECOND] = ["Можете получить 2 карты Действия которых нет у вас в игре.", "Можете получить 2 карты Действия которых нет у вас в игре."];
RussianQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_THIRD] = ["Можете получить карту Действия которой нет у вас в игре.", "Можете получить карту Действия которой нет у вас в игре."];
RussianQuestionDescriptions[QuestionIds.GATHER] = ["Получите карту стоимостью ровно Argument0.", "Получите карту стоимостью ровно Argument0."];