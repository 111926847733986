"use strict";

var CzechLogEntries = {};

CzechLogEntries[LogEntryNames.GAIN] = "Argument0 získává Argument1.";
CzechLogEntries[LogEntryNames.BUY_AND_GAIN] = "Argument0 kupuje a získává Argument1.";
CzechLogEntries[LogEntryNames.TRASH] = "Argument0 vyhazuje Argument1.";
CzechLogEntries[LogEntryNames.DISCARD] = "Argument0 odkládá Argument1.";
CzechLogEntries[LogEntryNames.PLAY] = "Argument0 hraje Argument1.";
CzechLogEntries[LogEntryNames.PLAY_AGAIN] = "Argument0 hraje Argument1 znovu.";
CzechLogEntries[LogEntryNames.PLAY_THIRD] = "Argument0 hraje Argument1 potřetí.";
CzechLogEntries[LogEntryNames.TOPDECK] = "Argument0 dává navrch Argument1.";
CzechLogEntries[LogEntryNames.DRAW] = "Argument0 si bere Argument1.";
CzechLogEntries[LogEntryNames.DRAW_FROM] = "Argument0 si bere Argument1. (Argument 2)";
CzechLogEntries[LogEntryNames.REVEAL] = "Argument0 odhaluje Argument1.";
CzechLogEntries[LogEntryNames.REVEAL_SEARCHING] = "Argument0 odhaluje Argument1.";
CzechLogEntries[LogEntryNames.LOOK_AT] = "Argument0 se dívá na Argument1.";
CzechLogEntries[LogEntryNames.PUT_IN_HAND] = "Argument0 si dává Argument1 do ruky.";
CzechLogEntries[LogEntryNames.SET_ASIDE] = "Argument0 dává Argument1 na stranu.";
CzechLogEntries[LogEntryNames.PUT_ON_MAT] = "Argument0 dává Argument1 na jejich Argument2.";
CzechLogEntries[LogEntryNames.CALL] = "Argument0 vyvolává Argument1.";
CzechLogEntries[LogEntryNames.DECK_TO_DISCARD] = "Argument0 přesouvá svůj balíček na odkládací.";
CzechLogEntries[LogEntryNames.BACK_ON_DECK] = "Argument0 dává Argument1 zpět do svého balíčku.";
CzechLogEntries[LogEntryNames.SHUFFLE_INTO_DECK] = "Argument0 zamíchává Argument1 do svého balíčku.";
CzechLogEntries[LogEntryNames.INSERT_IN_DECK] = "Argument0 vkládá Argument1 do svého balíčku.";
CzechLogEntries[LogEntryNames.EXCHANGE_RETURN] = "Argument0 vrací Argument1.";
CzechLogEntries[LogEntryNames.EXCHANGE_RECEIVE] = "Argument0 získává Argument1.";
CzechLogEntries[LogEntryNames.PASS] = "Argument0 skládá Argument1 na Argument2.";
CzechLogEntries[LogEntryNames.STARTS_WITH] = "Argument0 začíná s Argument1.";
CzechLogEntries[LogEntryNames.BUY] = "Argument0 kupuje Argument1.";
CzechLogEntries[LogEntryNames.COUNTER_ADD] = "COUNTER_ADD";
CzechLogEntries[LogEntryNames.COUNTER_RESET] = "COUNTER_RESET";
CzechLogEntries[LogEntryNames.REACTS_WITH] = "Argument0 reaguje s Argument1.";
CzechLogEntries[LogEntryNames.SHUFFLES] = "Argument0 míchá svůj balíček.";
CzechLogEntries[LogEntryNames.NEW_TURN] = "Argument0";
CzechLogEntries[LogEntryNames.WISH_CORRECT] = "Argument0 si přeje Argument1 a nachází ho.";
CzechLogEntries[LogEntryNames.WISH_WRONG] = "Argument0 si přeje Argument1 ale odhaluje Argument2.";
CzechLogEntries[LogEntryNames.BLACK_MARKET_RESHUFFLE] = "Míchá znovu balíčkem Černého trhu.";
CzechLogEntries[LogEntryNames.RETURN_TO] = "Argument0 vrací Argument1 na Argument2.";
CzechLogEntries[LogEntryNames.PUT_ON_BOTTOM_OF] = "Argument0 dává Argument1 na vršek Argument2.";
CzechLogEntries[LogEntryNames.LOSE_TRACK_MOVED] = "Argument0 už nemůže přesunout Argument1 (byl již přesunut).";
CzechLogEntries[LogEntryNames.LOSE_TRACK_HIDDEN] = "Argument0 už nemůže přesunout Argument1 (byl překryt).";
CzechLogEntries[LogEntryNames.LOSE_TRACK_RESHUFFLED] = "Argument0 už nemůže přesunout Argument1 (byl zamíchán).";
CzechLogEntries[LogEntryNames.LOSE_TRACK_ANONYMOUS] = "Argument0 se nemůže dál pohnout.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_BANDIT_CAMP] = "Argument0.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_TOWER] = "Argument0.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH_ABSENT] = "Žádné jinak pojmenované Akční karty.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH] = "Argument0.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_WALL] = "Argument0.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_WOLF_DEN] = "přesně jeden Argument0.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_PALACE] = "Argument0.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_ORCHARD] = "Argument0.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_OBELISK] = "Argument0 karty z balíčku Argument1.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_MUSEUM] = "Argument0 jinak pojmenovaných karet.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_KINGS_CASTLE] = "Argument0 Hradů.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_KEEP] = "Argument0.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_HUMBLE_CASTLE] = "Argument0 Hradů.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_FOUNTAIN] = "Argument0.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_FEODUM] = "Argument0.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_GARDENS] = "Argument0.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_VINEYARD] = "Argument0 Akčních karet.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_SILK_ROAD] = "Argument0 Vítezných karet.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_FAIRGROUNDS] = "Argument0 jinak pojmenovaných karet.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_DUKE] = "Argument0.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_DUH] = "";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_VP_TOKENS] = "VB žetony.";
CzechLogEntries[LogEntryNames.PUT_IN_HAND_FROM] = "Argument0 dává Argument1 do ruky (Argument2).";
CzechLogEntries[LogEntryNames.STARTS_TURN] = "Argument0 začíná tah.";
CzechLogEntries[LogEntryNames.TAKE_VP_FROM] = "Argument0 bere Argument1 VB z Argument2.";
CzechLogEntries[LogEntryNames.MOVE_VP_FROM_TO] = "Argument0 přesouvá Argument1 VB z Argument2 na Argument3.";
CzechLogEntries[LogEntryNames.OBELISK_CHOOSES_PILE] = "Obelisk vybírá Argument0.";
CzechLogEntries[LogEntryNames.PLAYER_MOVES_TOKEN_TO] = "Argument0 přesouvá Argument1 na Argument2.";
CzechLogEntries[LogEntryNames.PLAYER_GETS_VP] = "Argument0 dostává Argument1 VB.";
CzechLogEntries[LogEntryNames.PLAYER_GETS_VP_FROM] = "Argument0 dostává Argument1 VB z Argument2.";
CzechLogEntries[LogEntryNames.ADDS_VP_TO] = "Argument0 přidává Argument1 VB na Argument2.";
CzechLogEntries[LogEntryNames.WAITING_FOR] = "Čeká na Argument0.";
CzechLogEntries[LogEntryNames.ALMS_FAIL] = "Argument0 kupuje Almužnu ale má zahraný Argument1.";
CzechLogEntries[LogEntryNames.BORROW_FAIL] = "Argument0 kupuje Dlužníka ale již má Argument1";
CzechLogEntries[LogEntryNames.QUEST_FAIL_ATTACK] = "Argument0 neuspěl v odložení Akce.";
CzechLogEntries[LogEntryNames.QUEST_FAIL_CURSES] = "Argument0 neuspěl v odložení Argument1.";
CzechLogEntries[LogEntryNames.QUEST_FAIL_DISCARD] = "Argument0 neuspěl v odložení šesti karet.";
CzechLogEntries[LogEntryNames.SAVE_FAIL] = "Argument0 nemá žádné karty, které by dal na stranu.";
CzechLogEntries[LogEntryNames.SCOUTING_PARTY_FAIL] = "Argument0 nemá žádné karty, které by odložil nebo položil navrch.";
CzechLogEntries[LogEntryNames.BONFIRE_FAIL] = "Argument0 nic nevyhazuje.";
CzechLogEntries[LogEntryNames.MISSION_FAIL] = "Poslání selhalo, protože byl Argument0 již použit předchozí tah.";
CzechLogEntries[LogEntryNames.MISSION_SUCCESS] = "Argument0 získává po odehrání tahu nový tah.";
CzechLogEntries[LogEntryNames.TRADE_FAIL] = "Argument0 nic nevyhazuje.";
CzechLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_DOWN] = "Argument0 otáčí Argument1 lícem dolu.";
CzechLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_UP] = "Argument0 otáčí Argument1 lícem nahoru.";
CzechLogEntries[LogEntryNames.TRIUMPH_FAIL] = "Argument0 nauspěl v získání Argument1.";
CzechLogEntries[LogEntryNames.ADVANCE_FAIL] = "Argument0 nevyhodil kartu Akce.";
CzechLogEntries[LogEntryNames.TAX_ADD_DEBT] = "Argument0 přidává Argument1 na Argument2.";
CzechLogEntries[LogEntryNames.TAX_TAKE_DEBT] = "Argument0 si bere Argument1 z Argument2.";
CzechLogEntries[LogEntryNames.RITUAL_FAIL] = "Argument0 neuspěl v získání Argument1.";
CzechLogEntries[LogEntryNames.WINDFALL_FAIL] = "Argument0 není prázdný.";
CzechLogEntries[LogEntryNames.DOMINATE_FAIL] = "Argument0 neuspěl v získání Argument1.";
CzechLogEntries[LogEntryNames.PLUS_ACTION_FROM] = "Argument0 dostává +1 Akci (Argument1).";
CzechLogEntries[LogEntryNames.PLUS_BUY_FROM] = "Argument0 dostává +1 Nákup (Argument1).";
CzechLogEntries[LogEntryNames.DRAWS_FROM] = "Argument0 si bere Argument1 (Argument2).";
CzechLogEntries[LogEntryNames.PLUS_COIN_FROM] = "Argument0 si bere +1 Minci (Argument1).";
CzechLogEntries[LogEntryNames.DRAWS_LESS_BECAUSE] = "Argument0 přeskakuje získání (kvůli pravidlu Argument1).";
CzechLogEntries[LogEntryNames.PRINCE_FAILS] = "";
CzechLogEntries[LogEntryNames.POSSESSION_TAKES_COIN_TOKENS] = "Argument0 si místo toho bere žeton Mince.";
CzechLogEntries[LogEntryNames.POSSESSION_TAKES_DEBT_TOKENS] = "Argument0 si místo toho bere Dluh.";
CzechLogEntries[LogEntryNames.POSSESION_TAKES_VP_TOKENS] = "Argument0 si místo toho bere žeton VB.";
CzechLogEntries[LogEntryNames.OUTPOST_FAIL_PREVIOUS_TURN] = "Předsunutá hlídka selhala, protože byl Argument0 již použit předchozí tah.";
CzechLogEntries[LogEntryNames.OUTPOST_FAIL_ALREADY_PLAYED] = "Předsunutá hlídka selhala, protože ji již Argument0 zahrál.";
CzechLogEntries[LogEntryNames.OUTPOST_FAIL_NOT_NAMED_OUTPOST] = "Efekt Předsunuté hlídky selhal, protože název karty není [Outpost].";
CzechLogEntries[LogEntryNames.TAKES_DEBT] = "Argument0 si bere Dluh Argument1.";
CzechLogEntries[LogEntryNames.REPAYS_DEBT] = "Argument0 platí znovu Dluh Argument1.";
CzechLogEntries[LogEntryNames.REPAYS_SOME_DEBT] = "Argument0 platí znovu Dluh Argument1 (Argument2 platí).";
CzechLogEntries[LogEntryNames.RETURNS_TOKEN] = "Argument0 vrací Argument1 dle funkce Argument2.";
CzechLogEntries[LogEntryNames.SET_ASIDE_WITH] = "Argument0 dává na stranu Argument1 s Argument2.";
CzechLogEntries[LogEntryNames.BLOCKS_WITH] = "Argument0 blokuje s Argument1.";
CzechLogEntries[LogEntryNames.NAMES] = "Argument0 jmenuje Argument1.";
CzechLogEntries[LogEntryNames.GAIN_ON_DRAWPILE] = "Argument0 získává Argument1 navrch svého balíčku.";
CzechLogEntries[LogEntryNames.GAIN_FROM_TRASH] = "Argument0 získává Argument1 ze Smetiště.";
CzechLogEntries[LogEntryNames.OBELISK_FAILS] = "Obelisk selhal ve výběru hromádky Akcí.";
CzechLogEntries[LogEntryNames.INHERITS] = "Argument0 dědí Argument1.";
CzechLogEntries[LogEntryNames.GAME_META_INFO] = "Argument0";
CzechLogEntries[LogEntryNames.THE_SKYS_GIFT_FAIL] = "Argument0 selhává při odložení za [The Skys Gift]";
CzechLogEntries[LogEntryNames.RECEIVES] = "Argument0 získává Argument1.";
CzechLogEntries[LogEntryNames.EXPLAIN_POINTS_PASTURE] = "Argument0.";
CzechLogEntries[LogEntryNames.TAKE] = "Argument0 si bere Argument1.";
CzechLogEntries[LogEntryNames.RETURN] = "Argument0 vrací Argument1.";
CzechLogEntries[LogEntryNames.THE_SUNS_GIFT_FAIL] = "[The Suns Gift] nemá nic na odložení.";
CzechLogEntries[LogEntryNames.DRUID_SELECTS_BOONS] = "[Druid] dává Argument1 na stranu.";
CzechLogEntries[LogEntryNames.DISCARD_RECEIVABLE] = "Argument0 odkládá Argument1.";
CzechLogEntries[LogEntryNames.PLAY_ENCHANTED] = "Argument0 je okouzlen Argument1";
CzechLogEntries[LogEntryNames.GAIN_ANOTHER_EXPERIMENT] = "Argument0 získává další [Experiment]";
CzechLogEntries[LogEntryNames.COFFERS_FROM_PATRON] = "Argument0 si bere Meďák z [^nPatron].";
CzechLogEntries[LogEntryNames.GETS_COFFER] = "Argument0 si bere +Argument1 Měďák.";
CzechLogEntries[LogEntryNames.GETS_COFFERS] = "Argument0 si bere +Argument1 Měďák.";
CzechLogEntries[LogEntryNames.GETS_COFFER_FROM] = "Argument0 si bere +Argument1 Měďák. (Argument2)";
CzechLogEntries[LogEntryNames.GETS_COFFERS_FROM] = "Argument0 si bere +Argument1 Měďák. (Argument2)";
CzechLogEntries[LogEntryNames.USES_COFFER] = "Argument0 používá Argument1 Meďák. (+$Argument1)";
CzechLogEntries[LogEntryNames.USES_COFFERS] = "Argument0 používá Argument1 Měďák. (+$Argument1)";
CzechLogEntries[LogEntryNames.GETS_VILLAGER] = "Argument0 získává +Argument1 Vesničana.";
CzechLogEntries[LogEntryNames.GETS_VILLAGERS] = "Argument0 získává +Argument1 Vesničany.";
CzechLogEntries[LogEntryNames.GETS_VILLAGER_FROM] = "Argument0 získává +Argument1 Vesničany. (Argument2)";
CzechLogEntries[LogEntryNames.GETS_VILLAGERS_FROM] = "Argument0 získává +Argument1 Vesničany. (Argument2)";
CzechLogEntries[LogEntryNames.USES_VILLAGER] = "Argument0 používá Argument1 Vecničana.";
CzechLogEntries[LogEntryNames.USES_VILLAGERS] = "Argument0 používá Argument1 Vesničany.";
CzechLogEntries[LogEntryNames.ENDS_BUYPHASE] = "Argument0 ukončuje fázi Nákup.";
CzechLogEntries[LogEntryNames.GETS_ACTION] = "Argument0 si bere +Argument1 Akci.";
CzechLogEntries[LogEntryNames.GETS_ACTIONS] = "Argument0 si bere +Argument1 Akci.";
CzechLogEntries[LogEntryNames.GETS_ACTION_FROM] = "Argument0 si bere +Argument1 Akci. (Argument2)";
CzechLogEntries[LogEntryNames.GETS_ACTIONS_FROM] = "Argument0 si bere +Argument1 Akci. (Argument2)";
CzechLogEntries[LogEntryNames.GETS_BUY] = "Argument0 si bere +Argument1 Nákup.";
CzechLogEntries[LogEntryNames.GETS_BUYS] = "Argument0 si bere +Argument1 Nákup.";
CzechLogEntries[LogEntryNames.GETS_BUY_FROM] = "Argument0 si bere +Argument1 Nákup. (Argument2)";
CzechLogEntries[LogEntryNames.GETS_BUYS_FROM] = "Argument0 si bere +Argument1 Nákup. (Argument2)";
CzechLogEntries[LogEntryNames.GETS_COIN] = "Argument0 si bere +$Argument1.";
CzechLogEntries[LogEntryNames.GETS_COINS] = "Argument0 si bere +$Argument1.";
CzechLogEntries[LogEntryNames.GETS_COIN_FROM] = "Argument0 si bere +$Argument1. (Argument2)";
CzechLogEntries[LogEntryNames.GETS_COINS_FROM] = "Argument0 si bere +$Argument1. (Argument2)";
CzechLogEntries[LogEntryNames.LOSES_COIN] = "Argument0 ztrácí Argument1 Minci.";
CzechLogEntries[LogEntryNames.LOSES_COINS] = "Argument0 ztrácí Argument1 Mince.";
CzechLogEntries[LogEntryNames.BOTTOMDECKS] = "Argument0 si dává Argument1 dospod svého balíčku.";
CzechLogEntries[LogEntryNames.TRASH_WITH] = "Argument0 vyhazuje Argument1. (Argument2)";
CzechLogEntries[LogEntryNames.PRIEST_ACTIVATES] = "pokud odteď vyhodíš karu +$Argument0.";
CzechLogEntries[LogEntryNames.PLAY_TREASURES_FOR] = "Argument0 hraje Argument1. (+$Argument2)";
CzechLogEntries[LogEntryNames.PLAY_TREASURES_AGAIN_FOR] = "Argument0 hraje Argument1 znovu. (+$Argument2)";
CzechLogEntries[LogEntryNames.RETURNS_MINUS_COIN_TOKEN] = "Argument0 vrací Argument1 dle funkce Argument2. (-$1)";
CzechLogEntries[LogEntryNames.NOT_IN_PLAY_PREVENTED_PLAYING] = "Argument0 nebyl zahrán, tudíž nemůže být vyložen.";
CzechLogEntries[LogEntryNames.PILLAGE_FAIL] = "[Pillage] nebyl vyhozen takže se nic nestalo.";
CzechLogEntries[LogEntryNames.DEATH_CART_FAIL] = "[Death Cart] nebyl vyhozen takže se nic nestalo.";
CzechLogEntries[LogEntryNames.TACTICIAN_FAIL] = "[Tactician] nebyl odložen takže se nic nestalo.";
CzechLogEntries[LogEntryNames.EMBARGO_FAIL] = "[Embargo] nebyl vyhozen takže se nic nestalo.";
CzechLogEntries[LogEntryNames.EXILE] = "Argument0 vyhošťuje Argument1.";
CzechLogEntries[LogEntryNames.DISCARD_FROM_EXILE] = "Argument0 odkládá Argument1 z Vyhoštění.";
CzechLogEntries[LogEntryNames.DISCARD_REVEALED] = "Argument0 odkládá a odhaluje Argument1.";
CzechLogEntries[LogEntryNames.PLAY_WITH] = CzechLogEntries[LogEntryNames.PLAY] + " (Argument2)";
CzechLogEntries[LogEntryNames.PLAY_AGAIN_WITH] = CzechLogEntries[LogEntryNames.PLAY] + " (Argument2)";
CzechLogEntries[LogEntryNames.PLAY_THIRD_WITH] = CzechLogEntries[LogEntryNames.PLAY] + " (Argument2)";
CzechLogEntries[LogEntryNames.IS_INVESTED] = "Argument0 si bere karty Argument2 pokud prtihráč získá nebo vyšetří Argument1.";
CzechLogEntries[LogEntryNames.COMMERCE_FAIL] = "Argument0 nedostal žádně karty.";
CzechLogEntries[LogEntryNames.STAMPEDE_FAIL] = "Argument0 kupuje [Stampede] se zahranou kartou Argument1.";
CzechLogEntries[LogEntryNames.PLAY_USING_WAY] = "Argument0 hraje Argument1 použitím Argument2.";
CzechLogEntries[LogEntryNames.WAY_OF_THE_HORSE_FAILS_NO_PILE] = "Argument0 nemá žádnou hromádku kam se vrátit.";
CzechLogEntries[LogEntryNames.WAY_OF_THE_HORSE_FAILS_NOT_IN_PLAY] = "[Way Of The Horse] selhal v přesunutí Argument0, protože nebyl zahrán.";
CzechLogEntries[LogEntryNames.WAY_OF_THE_TURTLE_FAILS_TO_SET_ASIDE] = "[Way Of The Turtle] selhal když dával Argument0 na stranu.";
CzechLogEntries[LogEntryNames.USES_WAY] = "Argument0 používá Argument1.";
CzechLogEntries[LogEntryNames.WOULD_GET_COIN] = "Argument0 si může vzít +$Argument1.";
CzechLogEntries[LogEntryNames.WOULD_DRAW] = "Argument0 si může vzít Argument1.";
CzechLogEntries[LogEntryNames.TOPDECK_WITH] = "Argument0 dává navrch Argument1 s Argument2.";
CzechLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_NO_PILE] = "Argument0 nemá žádnou hromádku kam se vrátit.";
CzechLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_NOT_IN_PLAY] = "[Way Of The Butterfly] selhal v přesunu Argument0, protože nebyl zahrán.";
CzechLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_INTENTIONAL] = "Argument0 se rozhodl nevrátit Argument1 na hromádku.";