"use strict";

webclient.component("undoRequest", {
    transclude: true,
    controller: ['game', "$scope", function (game, $scope) {
        var self = this;

        $scope.$on(Events.REDRAW, function () {
            safeDigest($scope);
        });
        $scope.$on(Events.GAME_MODEL_CREATED, function () {
            safeDigest($scope);
        });
        self.shouldShow = function () {
            return game.metaGameModel.undoRequest !== null && !isUndefined(game.playerModel.playerMe) && game.metaGameModel.undoRequest.playerIndex !== game.playerModel.playerMe.index;
        };

        self.grant = function () {
            return game.metaGameModel.grantUndoRequest();
        };
        self.decline = function () {
            return game.metaGameModel.denyUndoRequest();
        };

        self.getWindowText = function () {
            var decisions = game.logModel.entries.filter(function (e) {
                return e instanceof DecisionEntry;
            });
            var n = decisions.length - game.metaGameModel.undoRequest.decisionIndex + 1;
            if (n === 1) return getPhrase(Phrases.UNDO_REQUEST_SINGULAR);
            return getPhrase(Phrases.UNDO_REQUEST_PLURAL).replace('NUMBER', n);
        };

        self.getGrantText = function () {
            return getPhrase(Phrases.GRANT);
        };
        self.getDeclineText = function () {
            return getPhrase(Phrases.DENY);
        };
    }],
    template: "\n            <modal-window ng-if=\"$ctrl.shouldShow()\">\n                <div class=\"timeout\">{{$ctrl.getWindowText()}}</div>\n                <div>\n                    <button class=\"lobby-button\" ng-click=\"$ctrl.grant()\" >{{::$ctrl.getGrantText()}}</button>\n                    <button class=\"lobby-button\" ng-click=\"$ctrl.decline()\" >{{::$ctrl.getDeclineText()}}</button>\n                </div>\n            </modal-window>\n        "
});