"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var NumberQuestion = function (_Question) {
    _inherits(NumberQuestion, _Question);

    function NumberQuestion(game, index, description, min, max) {
        var defaultAnswer = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 0;
        var declineButtonId = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : -1;

        _classCallCheck(this, NumberQuestion);

        var _this = _possibleConstructorReturn(this, (NumberQuestion.__proto__ || Object.getPrototypeOf(NumberQuestion)).call(this, game, index, description));

        _this.questionClass = QuestionClasses.NUMBER_QUESTION;
        _this.min = min;
        _this.max = max;
        _this.defaultAnswer = defaultAnswer;
        _this.accumulatedAnswers = [defaultAnswer];
        _this.declineButtonId = declineButtonId;
        return _this;
    }

    _createClass(NumberQuestion, [{
        key: "answer",
        value: function answer(_answer) {
            this.accumulatedAnswers = [_answer];
            this.send();
        }
    }, {
        key: "execute",
        value: function execute(game) {
            var isRoot = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

            if (!this.type.noButton) {
                var isBundled = this.parentQuestion !== null && this.parentQuestion.type === QuestionTypes.COMPLEX_AND;
                var newButtons = [new NumericButtonView(this, !isBundled)];
                if (isBundled && this.parentQuestion.subquestions.every(function (q) {
                    return q instanceof NumberQuestion;
                }) && this === this.parentQuestion.subquestions[0]) newButtons.push(new BundledDoneButtonView(this.parentQuestion));
                if (this.declineButtonId > -1) {
                    var declineButtonInfo = getByOrdinal(DeclineButtonIds, this.declineButtonId);
                    if (declineButtonInfo.isEndButton) newButtons.push(new EndButtonView(this, LANGUAGE.getDeclineButtonText[declineButtonInfo], getButtonArtURL(declineButtonInfo)));else newButtons.push(new DeclineGameButtonView(this, LANGUAGE.getDeclineButtonText[declineButtonInfo], getButtonArtURL(declineButtonInfo)));
                }
                this.game.questionModel.newButtons(newButtons);
            }
            _get(NumberQuestion.prototype.__proto__ || Object.getPrototypeOf(NumberQuestion.prototype), "execute", this).call(this, game, isRoot);
        }
    }, {
        key: "answerDecline",
        value: function answerDecline() {
            this.accumulatedAnswers = [];
            this.send();
        }
    }, {
        key: "getLabel",
        value: function getLabel(amount) {
            var suffix = void 0;
            switch (this.type) {
                case QuestionTypes.HOW_MUCH_REPAY:
                    return amount + " " + getPhrase(Phrases.DEBT);
                case QuestionTypes.HOW_MANY_SPEND:
                    if (shouldUseRussianMany(amount)) {
                        suffix = getPhrase(Phrases.TOKENS_MANY);
                    }
                    suffix = amount > 1 ? getPhrase(Phrases.TOKENS) : getPhrase(Phrases.TOKEN);
                    return amount + " " + suffix;
                case QuestionTypes.HOW_MANY_FAVORS:
                    if (shouldUseRussianMany(amount)) {
                        suffix = getPhrase(Phrases.FAVORS_MANY);
                    }
                    suffix = amount > 1 ? getPhrase(Phrases.FAVORS) : getPhrase(Phrases.FAVOR);
                    return amount + " " + suffix;
                case QuestionTypes.USE_VILLAGERS:
                    return amount + " " + getPhrase(Phrases.VILLAGERS);
                case QuestionTypes.OVERPAY:
                    return getPhrase(Phrases.OVERPAY) + " " + amount;
                case QuestionTypes.OVERPAY_COINS:
                    return amount + " " + getPhrase(Phrases.COINS);
                case QuestionTypes.OVERPAY_POTIONS:
                    return amount + " " + getPhrase(Phrases.POTIONS);
                case QuestionTypes.BID:
                    return getPhrase(Phrases.BID) + " " + amount;
                case QuestionTypes.TAKE_HOW_MANY:
                    return getPhrase(Phrases.TAKE) + " " + amount;
                case QuestionTypes.LOCATION:
                    if (amount === this.min) return getPhrase(Phrases.BOTTOM);
                    if (amount === this.max) return getPhrase(Phrases.TOP);else return getPhrase(Phrases.LOCATION) + " " + amount;
                case QuestionTypes.REJECT_ABILITY:
                    return "";
                default:
                    throw new Error("Invalid question type for number input.");
            }
        }
    }, {
        key: "leftLabel",
        get: function get() {
            if (this.type === QuestionTypes.LOCATION) return getPhrase(Phrases.BOTTOM);else return this.min;
        }
    }, {
        key: "rightLabel",
        get: function get() {
            if (this.type === QuestionTypes.LOCATION) return getPhrase(Phrases.TOP);else return this.max;
        }
    }], [{
        key: "parse",
        value: function parse(game, reader, questionIndex) {
            var questionDescription = QuestionDescription.parse(reader);
            var min = reader.readInt();
            var max = reader.readInt();
            var defaultAnswer = reader.readInt();
            var doneButtonId = reader.readInt();
            return new NumberQuestion(game, questionIndex, questionDescription, min, max, defaultAnswer, doneButtonId);
        }
    }]);

    return NumberQuestion;
}(Question);