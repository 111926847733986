"use strict";
// subscription to a non-free expansion

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var StoreSubscription = function () {
    function StoreSubscription(expansionName, dates) {
        _classCallCheck(this, StoreSubscription);

        this.expansionName = expansionName;
        this.start = earliestDate(dates);
        this.end = latestDate(dates);
    }

    _createClass(StoreSubscription, [{
        key: "startDate",
        value: function startDate() {
            return this.start;
        }
    }, {
        key: "endDate",
        value: function endDate() {
            return this.end;
        }
    }, {
        key: "name",
        value: function name() {
            return this.expansionName;
        }

        // Should come from meta-server, have to look for it.

    }], [{
        key: "maxSubscriptionDate",
        value: function maxSubscriptionDate() {
            return new Date('2028-12-31');
        }
    }]);

    return StoreSubscription;
}();