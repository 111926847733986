"use strict";

var SpanishExpansionNames = {};

SpanishExpansionNames[Expansions.BASE] = 'Base';
SpanishExpansionNames[Expansions.INTRIGUE] = 'Intriga';
SpanishExpansionNames[Expansions.SEASIDE] = 'Terramar';
SpanishExpansionNames[Expansions.ALCHEMY] = 'Alquimia';
SpanishExpansionNames[Expansions.PROSPERITY] = 'Prosperidad';
SpanishExpansionNames[Expansions.CORNUCOPIA_GUILDS] = 'Cornucopia & Gremios';
SpanishExpansionNames[Expansions.HINTERLANDS] = 'Comarcas';
SpanishExpansionNames[Expansions.DARK_AGES] = 'Edad Oscura';
SpanishExpansionNames[Expansions.ADVENTURES] = 'Aventuras';
SpanishExpansionNames[Expansions.EMPIRES] = 'Imperios';
SpanishExpansionNames[Expansions.PROMOS] = 'Promos';
SpanishExpansionNames[Expansions.NOCTURNE] = 'Nocturno';
SpanishExpansionNames[Expansions.RENAISSANCE] = 'Renacimiento';
SpanishExpansionNames[Expansions.MENAGERIE] = 'Bestiario';
SpanishExpansionNames[Expansions.ALLIES] = 'Allies'; // <--TRANSLATE
SpanishExpansionNames[Expansions.DEPRECATED] = 'Deprecated'; // <-- TRANSLATE

// Missing lines as of 09.01.2023 18:45:04
SpanishExpansionNames[Expansions.PLUNDER] = 'Plünderer';
SpanishExpansionNames[Expansions.RISING_SUN] = 'Rising Sun';