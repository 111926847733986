"use strict";

webclient.component("questionList", {

    bindings: {

        questionList: "=ngModel",
        hideFuture: '='
    },

    controllerAs: "ctrl",

    controller: [function () {

        var ctrl = this;

        ctrl.bindQuestionsToList = function () {

            var questions = ctrl.questionList.toArray();

            // Give the question list controller to each question.
            questions.forEach(function (question) {
                question.angularListController = ctrl;
            });

            // Automatically go to the next question when answering a question.
            questions.forEach(function (question) {

                var nextQuestion = question.questionList.successor(question);

                if (question.changedFunction === undefined) {

                    question.changedFunction = function () {

                        if (nextQuestion !== undefined) {

                            if (question.isCurrent() && question.hasError() && question.isVisited()) {
                                alert(question.errorMessage());
                            } else {
                                question.angularListController.onclickQuestionList(false, nextQuestion);
                            }
                        }
                    };
                }
            });
        };

        ctrl.resetRow = function (row) {
            row.classList.remove('current-question');
            row.classList.remove('previous-question');
            row.classList.remove('next-question');
            row.classList.remove('shrunken');
            row.classList.remove('grown');
            row.classList.remove('shrinking');
            row.classList.remove('growing');
        };

        ctrl.makeCurrentRow = function (row) {
            ctrl.resetRow(row);row.classList.add('current-question');
        };
        ctrl.makeNextRow = function (row) {
            ctrl.resetRow(row);row.classList.add('next-question');
        };
        ctrl.makePreviousRow = function (row) {
            ctrl.resetRow(row);row.classList.add('previous-question');
        };
        ctrl.makeRegularRow = function (row) {
            ctrl.resetRow(row);
        };
        ctrl.row = function (question) {
            return ctrl.rows()[ctrl.questionList.toArray().indexOf(question)];
        };
        ctrl.questions = function () {
            return ctrl.questionList.toArray();
        };
        ctrl.questionInRow = function (row) {
            return ctrl.questions().find(function (question) {
                return ctrl.row(question) === row;
            });
        };

        // To hide future rows of future questions.
        ctrl.rowCurrent = function () {
            return ctrl.rows().find(function (row) {
                return row.classList.contains('current-question');
            });
        };
        ctrl.rowIndex = function (row) {
            return ctrl.rows().indexOf(row);
        };
        ctrl.afterCurrent = function (row) {
            return ctrl.rowIndex(row) > ctrl.rowIndex(ctrl.rowCurrent());
        };
        ctrl.isVisitedRow = function (row) {
            return ctrl.questionInRow(row).isVisited();
        };
        ctrl.hideRow = function (row) {
            return ctrl.hideFuture && ctrl.afterCurrent(row) && !ctrl.isVisitedRow(row);
        };
        ctrl.visibleRows = function () {
            return ctrl.rows().filter(function (row) {
                return !ctrl.hideRow(row);
            });
        };
        ctrl.hideQuestion = function (question) {
            return ctrl.hideRow(ctrl.row(question));
        };
        ctrl.inLastVisibleRow = function (question) {
            return arrayEqualsLastItem(ctrl.visibleRows(), ctrl.row(question));
        };

        ctrl.animateQuestionRows = function (current, lastCurrent) {

            var indexCurrent = ctrl.rows().indexOf(current);
            var indexLastCurrent = ctrl.rows().indexOf(lastCurrent);

            ctrl.rows().forEach(function (row, index) {

                if (index === indexCurrent || index === indexCurrent - 1 || index === indexCurrent + 1) {

                    if (index === indexCurrent) {
                        ctrl.makeCurrentRow(row);
                    }
                    if (index === indexCurrent - 1) {
                        ctrl.makePreviousRow(row);
                    }
                    if (index === indexCurrent + 1) {
                        ctrl.makeNextRow(row);
                    }
                } else {

                    ctrl.makeRegularRow(row);
                }

                if (index !== indexCurrent && index !== indexLastCurrent) {
                    row.classList.add('shrunken');
                }
            });

            if (lastCurrent !== undefined && lastCurrent !== current) {
                lastCurrent.classList.add('shrinking');
            }

            current.classList.add('growing');
        };

        ctrl.rows = function () {

            var domList = document.getElementsByClassName('question-row');
            var array = [];

            for (var i = 0; i < domList.length; i++) {
                array.push(domList[i]);
            }

            return array;
        };

        // To call this function without click event, call it as ctrl.onclickQuestionList(false,question)
        ctrl.onclickQuestionList = function (event, question) {

            if (event && event.target.constructor.name === "HTMLSelectElement") {
                return;
            }

            var previousQuestion = ctrl.questionList.currentQuestion();

            if (ctrl.changeHandled) {
                return;
            } else {
                ctrl.changeHandled = true;
            }

            timeLog('component questionList handles clicking of question: ');

            if (question !== previousQuestion) {

                var currentRow = ctrl.row(question);
                var previousRow = ctrl.row(previousQuestion !== undefined ? previousQuestion : question);

                ctrl.animateQuestionRows(currentRow, previousRow);
            }

            ctrl.questionList.setCurrent(question);

            ctrl.changeHandled = false;
        };
    }],

    templateUrl: 'html/related-questions/question-list-1.9.2.2.html'
});