"use strict";

webclient.component("playerToken", {
    bindings: {
        token: "<"
    },
    controller: ["$scope", function ($scope) {
        var self = this;

        self.tokenColor = self.token.owner.color.color;
        self.tokenBg = "images/tokens/" + self.token.tokenName.bgURL + ".png";
        var updatePosition = function updatePosition() {
            self.styleString = "background-color:" + self.tokenColor + ";" + self.token.bbox.getStyleString();
        };
        updatePosition();
        $scope.$watch("$ctrl.token.bbox", updatePosition, true);
    }],
    template: "<div class=\"pile-token\" style=\"{{$ctrl.styleString}}\">\n                        <div class=\"pile-token-icon\" style=\"background-image:url('{{$ctrl.tokenBg}}');\"></div>\n                    </div>"
});

webclient.component("pileCounter", {
    bindings: {
        counter: "<"
    },
    controller: ["$scope", "$element", function ($scope, $element) {
        var self = this;

        self.counterBg = "images/tokens/" + self.counter.counterName.bgURL + ".png";

        var canvas = $element[0].getElementsByTagName("canvas")[0];
        var ctx = canvas.getContext('2d');
        var w = void 0,
            h = void 0;

        var drawBackground = function drawBackground() {
            Preloader.load(self.counterBg, function (source) {
                ctx.drawImage(source, 0, 0, w, h);
                ctx.fillStyle = self.counter.counterName.colorClass;
                ctx.strokeStyle = self.counter.counterName.colorClass === "white" ? "black" : "white";
                drawFittedText(ctx, self.counter.value, new Rectangle(w * 0.05, h * 0.08, w * 0.9, h * 0.9), 1, "Minion-Bold", "", true);
            });
        };
        var fullRedraw = function fullRedraw() {
            reposition();
            var baselineSize = self.counter.bbox.height * 2;
            canvas.height = baselineSize;
            canvas.width = baselineSize;
            w = canvas.width;
            h = canvas.height;
            drawBackground();
        };

        var reposition = function reposition() {
            canvas.style.left = self.counter.bbox.x + "px";
            canvas.style.top = self.counter.bbox.y + "px";
            canvas.style.width = self.counter.bbox.width + "px";
            canvas.style.height = self.counter.bbox.height + "px";
            canvas.style.zIndex = self.counter.bbox.zIndex;
        };
        fullRedraw();

        $scope.$watch('$ctrl.counter.value', fullRedraw);
        $scope.$watch('$ctrl.counter.bbox.x', reposition);
        $scope.$watch('$ctrl.counter.bbox.y', reposition);
        $scope.$watch('$ctrl.counter.dirty', fullRedraw);
    }],
    template: "<canvas></canvas>"
});