"use strict";

var DutchTypes = {};

DutchTypes[Types.ACTION] = "Action";
DutchTypes[Types.TREASURE] = "Treasure";
DutchTypes[Types.ATTACK] = "Attack";
DutchTypes[Types.CURSE] = "Curse";
DutchTypes[Types.DURATION] = "Duration";
DutchTypes[Types.EVENT] = "Event";
DutchTypes[Types.GATHERING] = "Gathering";
DutchTypes[Types.KNIGHT] = "Knight";
DutchTypes[Types.LANDMARK] = "Landmark";
DutchTypes[Types.LOOTER] = "Looter";
DutchTypes[Types.RUINS] = "Ruins";
DutchTypes[Types.TRAVELLER] = "Traveller";
DutchTypes[Types.PRIZE] = "Prize";
DutchTypes[Types.RESERVE] = "Reserve";
DutchTypes[Types.VICTORY] = "Victory";
DutchTypes[Types.REACTION] = "Reaction";
DutchTypes[Types.SHELTER] = "Shelter";
DutchTypes[Types.CASTLE] = "Castle";
DutchTypes[Types.FATE] = "Fate";
DutchTypes[Types.NIGHT] = "Night";
DutchTypes[Types.DOOM] = "Doom";
DutchTypes[Types.HEIRLOOM] = "Heirloom";
DutchTypes[Types.SPIRIT] = "Spirit";
DutchTypes[Types.ZOMBIE] = "Zombie";
DutchTypes[Types.BOON] = "Boon";
DutchTypes[Types.HEX] = "Hex";
DutchTypes[Types.STATE] = "State";
DutchTypes[Types.COMMAND] = "Command";

var DutchZoneNames = {};

DutchZoneNames["HandZone"] = "Hand";
DutchZoneNames["DrawZone"] = "Draw Pile";
DutchZoneNames["DiscardZone"] = "Discard Pile";
DutchZoneNames["TrashZone"] = "Trash";
DutchZoneNames["SupplyZone"] = "Supply Pile";
DutchZoneNames["InPlayZone"] = "Play Area";
DutchZoneNames["TavernZone"] = "Tavern mat";
DutchZoneNames["NVZone"] = "Native Village mat";
DutchZoneNames["IslandZone"] = "Island mat";

var DutchMovementTypes = {};

DutchMovementTypes[MovementTypes.PLAY] = new TranslatedMovementType("play", "plays");
DutchMovementTypes[MovementTypes.DRAW] = new TranslatedMovementType("draw", "draws");
DutchMovementTypes[MovementTypes.DISCARD] = new TranslatedMovementType("discard", "discards");
DutchMovementTypes[MovementTypes.GAIN] = new TranslatedMovementType("gain", "gains");
DutchMovementTypes[MovementTypes.TRASH] = new TranslatedMovementType("trash", "trashes");
DutchMovementTypes[MovementTypes.TOPDECK] = new TranslatedMovementType("topdeck", "topdecks");
DutchMovementTypes[MovementTypes.REVEAL] = new TranslatedMovementType("reveal", "reveals");
DutchMovementTypes[MovementTypes.LOOK_AT] = new TranslatedMovementType("look at", "looks at");
DutchMovementTypes[MovementTypes.PUT_IN_HAND] = new TranslatedMovementType("put it in hand", "puts it in hand");
DutchMovementTypes[MovementTypes.SET_ASIDE] = new TranslatedMovementType("set it aside", "sets it aside");
DutchMovementTypes[MovementTypes.PUT_ON_MAT] = new TranslatedMovementType("put on mat", "puts on mat");
DutchMovementTypes[MovementTypes.DECK_TO_DISCARD] = new TranslatedMovementType("DECK_TO_DISCARD", "DECK_TO_DISCARD");
DutchMovementTypes[MovementTypes.BACK_ON_DECK] = new TranslatedMovementType("BACK_ON_DECK", "BACK_ON_DECK");
DutchMovementTypes[MovementTypes.SHUFFLE_INTO_DECK] = new TranslatedMovementType("SHUFFLE_INTO_DECK", "SHUFFLE_INTO_DECK");
DutchMovementTypes[MovementTypes.INSERT_IN_DECK] = new TranslatedMovementType("INSERT_IN_DECK", "INSERT_IN_DECK");
DutchMovementTypes[MovementTypes.EXCHANGE_RETURN] = new TranslatedMovementType("EXCHANGE_RETURN", "EXCHANGE_RETURN");
DutchMovementTypes[MovementTypes.EXCHANGE_RECEIVE] = new TranslatedMovementType("EXCHANGE_RECEIVE", "EXCHANGE_RECEIVE");
DutchMovementTypes[MovementTypes.PASS] = new TranslatedMovementType("PASS", "PASS");
DutchMovementTypes[MovementTypes.RETURN_TO] = new TranslatedMovementType("RETURN_TO", "RETURN_TO");
DutchMovementTypes[MovementTypes.PUT_ON_BOTTOM_OF] = new TranslatedMovementType("PUT_ON_BOTTOM_OF", "PUT_ON_BOTTOM_OF");
DutchMovementTypes[MovementTypes.STARTS_WITH] = new TranslatedMovementType("STARTS_WITH", "STARTS_WITH");
DutchMovementTypes[MovementTypes.TAKE] = new TranslatedMovementType("TAKE", "TAKE");
DutchMovementTypes[MovementTypes.GET_STATE] = new TranslatedMovementType("GET_STATE", "GET_STATE");
DutchMovementTypes[MovementTypes.RETURN_STATE] = new TranslatedMovementType("RETURN_STATE", "RETURN_STATE");

var DutchPileTokenTexts = {};

DutchPileTokenTexts[TokenNames.PLUS_ACTION] = "+A";
DutchPileTokenTexts[TokenNames.PLUS_BUY] = "+B";
DutchPileTokenTexts[TokenNames.PLUS_CARD] = "+C";
DutchPileTokenTexts[TokenNames.PLUS_COIN] = "+1";
DutchPileTokenTexts[TokenNames.MINUS_COST] = "-2";
DutchPileTokenTexts[TokenNames.TRASH] = "X";

var DutchPileMarkers = {};

DutchPileMarkers[PileMarkerTypes.BANE] = "Bane";
DutchPileMarkers[PileMarkerTypes.OBELISK] = "Obelisk";
DutchPileMarkers[PileMarkerTypes.SPLIT] = "Split";
DutchPileMarkers[PileMarkerTypes.MIXED] = "Mixed";

var DutchTokenNames = {};

DutchTokenNames[TokenNames.PLUS_ACTION] = "+Action token";
DutchTokenNames[TokenNames.PLUS_BUY] = "+Buy token";
DutchTokenNames[TokenNames.PLUS_COIN] = "+Coin token";
DutchTokenNames[TokenNames.PLUS_CARD] = "+Card token";
DutchTokenNames[TokenNames.MINUS_COST] = "-Cost token";
DutchTokenNames[TokenNames.TRASH] = "Trashing token";
DutchTokenNames[TokenNames.JOURNEY] = "Journey token";
DutchTokenNames[TokenNames.MINUS_CARD] = "-Card token";
DutchTokenNames[TokenNames.MINUS_COIN] = "-Coin token";

var DutchKingdomViewerNames = {};
DutchKingdomViewerNames[KingdomViewerGroups.SUPPLY_CARDS] = "Cards in Supply";
DutchKingdomViewerNames[KingdomViewerGroups.KINGDOM_LANDSCAPES] = "Events,Landmarks and Projects";
DutchKingdomViewerNames[KingdomViewerGroups.NON_KINGDOM] = "Non-Kingdom Cards";
DutchKingdomViewerNames[KingdomViewerGroups.RUINS] = "Ruins";
DutchKingdomViewerNames[KingdomViewerGroups.DRUID_BOONS] = "Druid Boons";
DutchKingdomViewerNames[KingdomViewerGroups.KNIGHTS] = "Knights";
DutchKingdomViewerNames[KingdomViewerGroups.CASTLES] = "Castles";
DutchKingdomViewerNames[KingdomViewerGroups.PRIZES] = "Prizes";
DutchKingdomViewerNames[KingdomViewerGroups.BOONS_HEXES] = "Boons and Hexes";
DutchKingdomViewerNames[KingdomViewerGroups.STATES_ARTIFACTS] = "States and Artifacts";
DutchKingdomViewerNames[KingdomViewerGroups.BLACK_MARKET] = "Black Market";