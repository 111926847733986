"use strict";

function Writer() {
    var self = this;
    self.buffer = new ArrayBuffer(0);

    var encoder = {};

    if (shouldUseTextEncoder) {
        encoder = new TextEncoder("utf-8");
    }

    self.writeInt = function (n) {
        var length = self.buffer.byteLength;
        var a = new DataView(extendBuffer(self.buffer, 4));
        a.setInt32(length, n, false);
        self.buffer = a.buffer;
    };

    self.writeInts = function (ints) {
        var n = ints.length;
        self.writeInt(n);
        for (var i = 0; i < n; i++) {
            self.writeInt(ints[i]);
        }
    };

    self.writeBoolean = function (boolean) {
        var length = self.buffer.byteLength;
        var a = new DataView(extendBuffer(self.buffer, 1));
        a.setInt8(length, boolean);
        self.buffer = a.buffer;
    };

    var maxInt32 = Math.pow(2, 32);

    self.writeLong = function (long) {
        var a = Math.floor(long / maxInt32);
        var b = long - a * maxInt32;
        self.writeInt(a);
        self.writeInt(b);
    };

    self.writeDouble = function (d) {
        var length = self.buffer.byteLength;
        var a = new DataView(extendBuffer(self.buffer, 8));
        a.setFloat64(length, d, false);
        self.buffer = a.buffer;
    };

    self.writeString = function (s) {
        var e = s;
        if (shouldUseTextEncoder) e = encoder.encode(s);
        var stringLength = e.length;

        self.writeInt(stringLength);
        var bufferLength = self.buffer.byteLength;
        var a = new Uint8Array(extendBuffer(self.buffer, stringLength));
        if (shouldUseTextEncoder) {
            a.set(e, bufferLength);
        } else {
            a.set(stringToByteArray(e), bufferLength);
        }
        self.buffer = a.buffer;
    };

    self.writeOptionalString = function (s) {
        if (isNonEmpty(s)) {
            self.writeBoolean(true);
            self.writeString(s);
        } else {
            self.writeBoolean(false);
        }
    };

    self.writeOptionalInt = function (i) {
        if (isNonEmpty(i)) {
            self.writeBoolean(true);
            self.writeInt(i);
        } else {
            self.writeBoolean(false);
        }
    };

    self.writeTypedArray = function (serializables) {
        var n = serializables.length;
        self.writeInt(n);
        for (var i = 0; i < n; i++) {
            serializables[i].serialize(self);
        }
    };

    self.writeMapObject = function (map, keyWriter, valueWriter) {
        self.writeInt(map.size);
        map.forEach(function (v, k) {
            keyWriter(self, k);
            valueWriter(self, v);
        });
    };

    self.writeIntMap = function (map) {
        var keys = Object.keys(map);
        self.writeInt(keys.length);
        keys.forEach(function (key) {
            self.writeInt(key);
            self.writeInt(map[key]);
        });
    };
}

function stringToByteArray(s) {
    var length = s.length;
    var a = new Uint8Array(length);
    for (var i = 0; i < length; i++) {
        a[i] = s.charCodeAt(i);
    }
    return a;
}

function extendBuffer(b, amount) {
    var length = b.byteLength;
    var a = new Uint8Array(length + amount);
    a.set(new Uint8Array(b));
    return a.buffer;
}