"use strict";

var FrenchUserPreferences = {};

FrenchUserPreferences[UserPrefIds.EMAIL] = "E-mail";
FrenchUserPreferences[UserPrefIds.CARD_OWNERSHIP] = "Cartes possédées";
FrenchUserPreferences[UserPrefIds.LIKED_CARDS] = "Cartes appréciées";
FrenchUserPreferences[UserPrefIds.DISLIKED_CARDS] = "Cartes peu appréciées";
FrenchUserPreferences[UserPrefIds.BANNED_CARDS] = "Cartes bannies";
FrenchUserPreferences[UserPrefIds.MOVE_KINGDOM] = "Déplacer le royaume et la zone de jeu";
FrenchUserPreferences[UserPrefIds.CONFIRM_ACTIONS] = "Confirmer l'écart et la défausse et l'exil d'une seule carte";
FrenchUserPreferences[UserPrefIds.PREFERRED_BACKGROUND] = "Arrière-plan favori";
FrenchUserPreferences[UserPrefIds.USE_AUTOBUY] = "Utiliser l'achat automatique";
FrenchUserPreferences[UserPrefIds.FOLLOWERS_SEE_ACTIVITY] = "Montrer votre activité à ceux qui vous suivent";
FrenchUserPreferences[UserPrefIds.NOTIFICATION_SOUNDS] = "Bip sonore de notification";
FrenchUserPreferences[UserPrefIds.SOUND_FX] = "Son fx";
FrenchUserPreferences[UserPrefIds.SPECTATORS_SEE_HAND] = "Les spectateurs peuvent voir mes cartes";
FrenchUserPreferences[UserPrefIds.AUTOPLAY_SETTINGS] = "Réponses automatisées pour les cartes suivantes";
FrenchUserPreferences[UserPrefIds.LOG_FIXED_BASIC_BONUS] = "Log avec +Actions, +Achat, +Pièce";
FrenchUserPreferences[UserPrefIds.TRANSPARENT_WAY_BUTTON] = "Boutons Voie transparents";
FrenchUserPreferences[UserPrefIds.CHAT_VISIBLE] = "La discussion est visible";
FrenchUserPreferences[UserPrefIds.SPECTATOR_CHAT_IN_AUTOMATCH] = "Montrer la discussion des spectateurs pour l'appariement automatique";
FrenchUserPreferences[UserPrefIds.ADVANCED_AUTOMATCH] = "Menu d'appariement automatique avancé";
FrenchUserPreferences[UserPrefIds.CARD_POOL_LEVEL] = "Niveau du pool de cartes";
FrenchUserPreferences[UserPrefIds.ANIMATION_TYPE] = "Type d'animation";
FrenchUserPreferences[UserPrefIds.PREMOVES] = "Pré-mouvement";

FrenchUserPreferences.USERNAME = "Nom d'utilisateur";
FrenchUserPreferences.PASSWORD = "Mot de passe";
FrenchUserPreferences.OLD_PASSWORD = "Ancien mot de passe";
FrenchUserPreferences.NEW_PASSWORD = "Nouveau mot de passe";
FrenchUserPreferences.REPEAT_PASSWORD = "Retapez le nouveau mot de passe";
FrenchUserPreferences.OWNERSHIP_INTRO = "Vos abonnements actuels :";
FrenchUserPreferences.OWNERSHIP_INTRO_EMPTY = "Vous n'avez aucun abonnement.";
FrenchUserPreferences.FAMILIAR_WITH = "Je connais bien";
FrenchUserPreferences.USE_BONUS_CODE = "Utiliser un code bonus";
FrenchUserPreferences.BONUS_CODE_ORIGIN = "Votre code bonus est de : ";
FrenchUserPreferences.BONUS_CODE_CHOOSE_EXPANSION = "Choisir une extension : ";

var FrenchProfileButtons = {};

FrenchProfileButtons[true] = "Oui";
FrenchProfileButtons[false] = "Non";
FrenchProfileButtons[UserPrefIds.FAMILIAR_CARDS] = {
    ALL: "Toute cette extension",
    NONE: "Rien de cette extension",
    ALL_EXPANSIONS: "Toutes les extensions"
};

var FrenchStoreLabels = {};

FrenchStoreLabels[StorePackages.HALF] = "Abonnement Argent";
FrenchStoreLabels[StorePackages.ALL] = "Abonnement Or";

FrenchStoreLabels[PriceCategories.PURCHASED] = ''; // "Extension achetées : ";
FrenchStoreLabels[PriceCategories.ALREADY_OWNED] = "Déjà abonné";
FrenchStoreLabels[PriceCategories.DISCOUNT] = "Réduction";
FrenchStoreLabels[PriceCategories.TRANSACTION] = "Frais de transaction";
FrenchStoreLabels[PriceCategories.SUBTOTAL] = "Sous-total";
FrenchStoreLabels[PriceCategories.VAT] = "TVA";
FrenchStoreLabels[PriceCategories.TOTAL] = "Total";

FrenchStoreLabels.SELLER_INFO = "Infos vendeur";
FrenchStoreLabels.PRODUCT_CONFIRM = "Acheter jusqu'à DATE: EXPANSIONS";
FrenchStoreLabels.PAYMENT_HANDLER = "Le paiement va être effectué par notre prestataire Mollie.";
FrenchStoreLabels.PROCEED_TO_PAYMENT = "Poursuivre le paiement";

var FrenchAutoplayLabels = {};

FrenchAutoplayLabels.REGULAR_OPTIONS = "Options standard";
FrenchAutoplayLabels.AUTOPLAY_OPTIONS = "Réponses automatisées";
FrenchAutoplayLabels.AUTOPLAY_DOCUMENTATION_GENERIC = "Que sont les réponses automatisées ?";
FrenchAutoplayLabels.AUTOPLAY_DOCUMENTATION_SPECIFIC = "-options spécifiques";
FrenchAutoplayLabels.CUSTOMIZE_OPTIONS = "Personnaliser les options";
FrenchAutoplayLabels.ALL_OFF = "Tout désactiver";
FrenchAutoplayLabels.ALL_DEFAULT = "Tout mettre par défaut";
FrenchAutoplayLabels.ALL_CONVENIENT = "Tout mettre au plus pratique";

var FrenchAnimationTypes = {};
FrenchAnimationTypes[AnimationOptions.NEW_PLAYER] = "Lent";
FrenchAnimationTypes[AnimationOptions.BEGINNER] = "Normal";
FrenchAnimationTypes[AnimationOptions.MEDIUM] = "Moyen";
FrenchAnimationTypes[AnimationOptions.ADVANCED] = "Rapide";
FrenchAnimationTypes[AnimationOptions.VERYFAST] = "Très rapide";
FrenchAnimationTypes[AnimationOptions.INSTANT] = "Pas d'animation";

// Missing lines as of 08.01.2023 18:54:59
FrenchStoreLabels[StorePackages.CORE] = "Abonnement basique";
FrenchStoreLabels[StorePackages.COMPLETE] = "Abonnement complet";