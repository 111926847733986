"use strict";

var RussianEnvironment = new function () {
    this.enter = function () {};
    this.leave = function () {};
    this.strlen = EnglishEnvironment.strlen;

    this.getPileName = EnglishEnvironment.getPileName;
    this.pileNameSeparator = " ";

    this.phraseCollection_Or = function (phrases) {
        return EnglishEnvironment.phraseCollection(phrases, RussianPhrases[Phrases.COMMA], RussianPhrases[Phrases.OR]);
    };

    this.amountParser = function (amount) {
        var output = [];
        if (amount.coin > 0) {
            var countable = getRussianGrammaticalNumber(amount.coin);
            var suffix = void 0;
            if (countable === GrammaticalNumbers.SINGULAR) {
                suffix = getPhrase(Phrases.COIN);
            } else if (countable === GrammaticalNumbers.PLURAL) {
                suffix = getPhrase(Phrases.COINS);
            } else if (countable === GrammaticalNumbers.MANY) {
                suffix = getPhrase(Phrases.COINS_MANY);
            }
            output.push(new ClickableColoredText(amount.coin + " " + suffix));
        }
        if (amount.potion > 0) {
            var prefix = output.length > 0 ? amount.debt > 0 ? " " + LANGUAGE.getPhrase[Phrases.AND] + " " : LANGUAGE.getPhrase[Phrases.COMMA] : "";

            var _countable = getRussianGrammaticalNumber(amount.potion);
            var _suffix = void 0;
            if (_countable === GrammaticalNumbers.SINGULAR) {
                _suffix = getPhrase(Phrases.POTION);
            } else if (_countable === GrammaticalNumbers.PLURAL) {
                _suffix = getPhrase(Phrases.POTIONS);
            } else if (_countable === GrammaticalNumbers.MANY) {
                _suffix = getPhrase(Phrases.POTIONS_MANY);
            }
            output.push(new ClickableColoredText("" + prefix + amount.potion + " " + _suffix));
        }
        if (amount.debt > 0) {
            var _prefix = output.length > 0 ? " " + LANGUAGE.getPhrase[Phrases.AND] + " " : "";
            output.push(new ClickableColoredText("" + _prefix + amount.debt + " " + LANGUAGE.getPhrase[Phrases.DEBT]));
        }
        return output;
    };

    this.cardComparator = EnglishEnvironment.cardComparator;

    this.determineFontStyle = function (isLandscape, lines) {
        var estimateLineLength = function estimateLineLength(line) {
            return line.replace(/{!?\d+}/, function (m) {
                return m + "xx";
            }).replace(/\[!?\d+\]/, "xx").length;
        };
        var maxLineLength = lines.map(estimateLineLength).reduce(function (a, b) {
            return a > b ? a : b;
        });
        var cutoffs = isLandscape ? [45, 55, 62, 70, 99] : [18, 23, 27, 32, 99];
        var styles = ["huge-font", "large-font", "medium-font", "small-font", "tiny-font"];
        var index = cutoffs.findIndex(function (e) {
            return maxLineLength < e;
        });
        if (index === -1 || lines.length >= 9) index = 4;
        return styles[index];
    };
}();

function getRussianGrammaticalNumber(n) {
    n = n % 100;
    if (n > 4 && n < 21) return GrammaticalNumbers.MANY;
    var m = n % 10;
    if (m === 1) return GrammaticalNumbers.SINGULAR;
    if (m > 1 && m < 5) return GrammaticalNumbers.PLURAL;
    return GrammaticalNumbers.MANY;
}

function shouldUseRussianMany(frequency) {
    return (frequency === 0 || frequency > 4) && LANGUAGE === RUSSIAN;
}