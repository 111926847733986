"use strict";

webclient.component("friendsTable", {
    controller: ['relations', function (relations) {
        var self = this;

        self.hasFriends = function () {
            return relations.hasFriends();
        };
        self.getRelations = function () {
            var rawRelations = relations.getRelations();
            rawRelations.sort(function (a, b) {
                var lookup = function lookup(status) {
                    return Object.values(RelationStati).indexOf(status);
                };
                return lookup(a.relationStatus) - lookup(b.relationStatus);
            });
            return rawRelations;
        };
        self.getRelationImage = function (relation) {
            return relations.getImageName(relation);
        };

        self.click = function (relation) {
            var status = relation.relationStatus;
            if (status === RelationStati.FRIEND) {
                relations.unFollow(relation.getName());
            } else if (status === RelationStati.FOLLOWER) {
                relations.addFriend(relation.getName());
            } else if (status === RelationStati.FOLLOWING) {
                relations.unFollow(relation.getName());
            } else {
                throw new Error("Unknown relationship status.");
            }
        };

        self.getButtonText = function (relation) {
            var status = relation.relationStatus;
            if (status === RelationStati.FRIEND) {
                return getPhrase(Phrases.UNFRIEND).capitalizeFirstLetter();
            } else if (status === RelationStati.FOLLOWER) {
                return getPhrase(Phrases.BEFRIEND).capitalizeFirstLetter();
            } else if (status === RelationStati.FOLLOWING) {
                return getPhrase(Phrases.UNFRIEND).capitalizeFirstLetter();
            } else {
                throw new Error("Unknown relationship status.");
            }
        };

        self.getToolTipText = function (relation) {
            return relations.getTooltipText(relation);
        };
        self.getNoFriendsText = function () {
            return getPhrase(Phrases.FRIENDLIST_EMPTY);
        };
    }],
    template: "\n            <div class=\"friends-table-content-container\">\n                <div ng-repeat=\"relation in $ctrl.getRelations() track by relation.getName()\" class=\"friends-table-row\">\n                    <div class=\"friends-user-column\">{{relation.getName()}}</div>\n                    <div class=\"user-pref-separator\"></div>\n                    <div class=\"friends-status-column\">\n                        <img class=\"friends-image\"\n                             ng-src=\"images/elements/{{$ctrl.getRelationImage(relation)}}.png\"\n                             title=\"{{$ctrl.getToolTipText(relation)}}\">\n                    </div>\n                    <div class=\"friends-action-column\">\n                        <button class=\"friends-button oval-button\" ng-click=\"$ctrl.click(relation)\">{{$ctrl.getButtonText(relation)}}</button>\n                    </div>\n                </div>\n                <div ng-if=\"!$ctrl.hasFriends()\" class=\"empty-relation-list\">{{$ctrl.getNoFriendsText()}}</div>\n            </div>\n            "
});