"use strict";

var CardNameAssociations = new Map();

var getCardNameAssociations = function getCardNameAssociations(cardName) {
	if (CardNameAssociations.has(cardName)) return CardNameAssociations.get(cardName);
	return [];
};

var setCardNameAssociations = function setCardNameAssociations() {
	var genericListHandler = function genericListHandler(list) {
		list.forEach(function (cardName) {
			CardNameAssociations.set(cardName, list.filter(function (n) {
				return n !== cardName;
			}));
		});
	};

	var prizesAndTournament = [CardNames.BAG_OF_GOLD, CardNames.DIADEM, CardNames.FOLLOWERS, CardNames.PRINCESS, CardNames.TOURNAMENT, CardNames.TRUSTY_STEED];

	genericListHandler(prizesAndTournament);

	var rewardsAndJoust = [CardNames.COURSER, CardNames.CORONET, CardNames.DEMESNE, CardNames.HOUSECARL, CardNames.HUGE_TURNIP, CardNames.JOUST, CardNames.RENOWN];

	genericListHandler(rewardsAndJoust);

	var knights = [CardNames.DAME_ANNA, CardNames.DAME_JOSEPHINE, CardNames.DAME_MOLLY, CardNames.DAME_NATALIE, CardNames.DAME_SYLVIA, CardNames.SIR_BAILEY, CardNames.SIR_DESTRY, CardNames.SIR_MARTIN, CardNames.SIR_MICHAEL, CardNames.SIR_VANDER];

	genericListHandler(knights);
	CardNameAssociations.set(CardNames.KNIGHTS, knights);

	var ruins = [CardNames.ABANDONED_MINE, CardNames.RUINED_LIBRARY, CardNames.RUINED_MARKET, CardNames.RUINED_VILLAGE, CardNames.SURVIVORS];

	genericListHandler(ruins);
	CardNameAssociations.set(CardNames.RUIN_PILE, ruins);

	CardNameAssociations.set(CardNames.BANDIT_CAMP, [CardNames.SPOILS]);
	CardNameAssociations.set(CardNames.CULTIST, [CardNames.RUIN_PILE]);
	CardNameAssociations.set(CardNames.DEATH_CART, [CardNames.RUIN_PILE]);
	CardNameAssociations.set(CardNames.MARAUDER, [CardNames.RUIN_PILE, CardNames.SPOILS]);
	CardNameAssociations.set(CardNames.PILLAGE, [CardNames.SPOILS]);

	CardNameAssociations.set(CardNames.HERMIT, [CardNames.MADMAN]);
	CardNameAssociations.set(CardNames.MADMAN, [CardNames.HERMIT]);
	CardNameAssociations.set(CardNames.URCHIN, [CardNames.MERCENARY]);
	CardNameAssociations.set(CardNames.MERCENARY, [CardNames.URCHIN]);

	var pageLine = [CardNames.PAGE, CardNames.TREASURE_HUNTER, CardNames.WARRIOR, CardNames.HERO, CardNames.CHAMPION];

	var peasantLine = [CardNames.PEASANT, CardNames.SOLDIER, CardNames.FUGITIVE, CardNames.DISCIPLE, CardNames.TEACHER];

	genericListHandler(pageLine);
	genericListHandler(peasantLine);

	var castles = [CardNames.CASTLES, CardNames.HUMBLE_CASTLE, CardNames.CRUMBLING_CASTLE, CardNames.SMALL_CASTLE, CardNames.HAUNTED_CASTLE, CardNames.OPULENT_CASTLE, CardNames.SPRAWLING_CASTLE, CardNames.GRAND_CASTLE, CardNames.KINGS_CASTLE];

	genericListHandler(castles);
	CardNameAssociations.set(CardNames.CASTLES, castles);

	var splitPiles = [[CardNames.ENCAMPMENT, CardNames.PLUNDER], [CardNames.PATRICIAN, CardNames.EMPORIUM], [CardNames.SETTLERS, CardNames.BUSTLING_VILLAGE], [CardNames.CATAPULT, CardNames.ROCKS], [CardNames.GLADIATOR, CardNames.FORTUNE], [CardNames.SAUNA, CardNames.AVANTO]];
	splitPiles.forEach(genericListHandler);

	// Dammit nocturne
	CardNameAssociations.set(CardNames.WILL_O_WISP, [CardNames.EXORCIST, CardNames.THE_SWAMPS_GIFT]);
	CardNameAssociations.set(CardNames.IMP, [CardNames.DEVILS_WORKSHOP, CardNames.EXORCIST, CardNames.TORMENTOR]);
	CardNameAssociations.set(CardNames.GHOST, [CardNames.EXORCIST, CardNames.HAUNTED_MIRROR]);
	CardNameAssociations.set(CardNames.WISH, [CardNames.LEPRECHAUN, CardNames.MAGIC_LAMP]);
	CardNameAssociations.set(CardNames.EXORCIST, [CardNames.WILL_O_WISP, CardNames.IMP, CardNames.GHOST]);
	CardNameAssociations.set(CardNames.DEVILS_WORKSHOP, [CardNames.IMP]);
	CardNameAssociations.set(CardNames.TORMENTOR, [CardNames.IMP, CardNames.HEX_DISCARDPILE]);
	CardNameAssociations.set(CardNames.HAUNTED_MIRROR, [CardNames.CEMETERY, CardNames.GHOST]);
	CardNameAssociations.set(CardNames.LEPRECHAUN, [CardNames.WISH, CardNames.HEX_DISCARDPILE]);
	CardNameAssociations.set(CardNames.MAGIC_LAMP, [CardNames.WISH, CardNames.SECRET_CAVE]);
	CardNameAssociations.set(CardNames.SKULK, [CardNames.HEX_DISCARDPILE]);
	CardNameAssociations.set(CardNames.CURSED_VILLAGE, [CardNames.HEX_DISCARDPILE]);
	CardNameAssociations.set(CardNames.VAMPIRE, [CardNames.HEX_DISCARDPILE, CardNames.BAT]);
	CardNameAssociations.set(CardNames.BAT, [CardNames.VAMPIRE]);
	CardNameAssociations.set(CardNames.WEREWOLF, [CardNames.HEX_DISCARDPILE]);
	CardNameAssociations.set(CardNames.DRUID, [CardNames.BOON_DISCARDPILE]);
	CardNameAssociations.set(CardNames.SACRED_GROVE, [CardNames.BOON_DISCARDPILE]);
	CardNameAssociations.set(CardNames.PIXIE, [CardNames.BOON_DISCARDPILE, CardNames.GOAT]);
	CardNameAssociations.set(CardNames.BARD, [CardNames.BOON_DISCARDPILE]);
	CardNameAssociations.set(CardNames.BLESSED_VILLAGE, [CardNames.BOON_DISCARDPILE]);
	CardNameAssociations.set(CardNames.IDOL, [CardNames.BOON_DISCARDPILE]);
	CardNameAssociations.set(CardNames.SACRED_GROVE, [CardNames.BOON_DISCARDPILE]);
	CardNameAssociations.set(CardNames.FOOL, [CardNames.LUCKY_COIN, CardNames.LOST_IN_THE_WOODS, CardNames.BOON_DISCARDPILE]);
	CardNameAssociations.set(CardNames.LUCKY_COIN, [CardNames.FOOL]);
	CardNameAssociations.set(CardNames.LOST_IN_THE_WOODS, [CardNames.FOOL]);
	CardNameAssociations.set(CardNames.POUCH, [CardNames.TRACKER]);
	CardNameAssociations.set(CardNames.TRACKER, [CardNames.POUCH, CardNames.BOON_DISCARDPILE]);
	CardNameAssociations.set(CardNames.CURSED_GOLD, [CardNames.POOKA]);
	CardNameAssociations.set(CardNames.POOKA, [CardNames.CURSED_GOLD]);
	CardNameAssociations.set(CardNames.GOAT, [CardNames.PIXIE]);
	CardNameAssociations.set(CardNames.CEMETERY, [CardNames.HAUNTED_MIRROR]);
	CardNameAssociations.set(CardNames.SECRET_CAVE, [CardNames.MAGIC_LAMP]);
	CardNameAssociations.set(CardNames.PASTURE, [CardNames.SHEPHERD]);
	CardNameAssociations.set(CardNames.SHEPHERD, [CardNames.PASTURE]);

	var boons = [CardNames.THE_EARTHS_GIFT, CardNames.THE_FIELDS_GIFT, CardNames.THE_FLAMES_GIFT, CardNames.THE_FORESTS_GIFT, CardNames.THE_MOONS_GIFT, CardNames.THE_MOUNTAINS_GIFT, CardNames.THE_RIVERS_GIFT, CardNames.THE_SEAS_GIFT, CardNames.THE_SKYS_GIFT, CardNames.THE_SUNS_GIFT, CardNames.THE_SWAMPS_GIFT, CardNames.THE_WINDS_GIFT];

	boons.forEach(function (boon) {
		return CardNameAssociations.set(boon, [CardNames.BOON_DISCARDPILE]);
	});
	CardNameAssociations.set(CardNames.BOON_DISCARDPILE, boons);
	CardNameAssociations.set(CardNames.THE_SWAMPS_GIFT, [CardNames.WILL_O_WISP, CardNames.BOON_DISCARDPILE]);

	var hexes = [CardNames.BAD_OMENS, CardNames.DELUSION, CardNames.ENVY, CardNames.FAMINE, CardNames.FEAR, CardNames.GREED, CardNames.HAUNTING, CardNames.LOCUSTS, CardNames.MISERY, CardNames.PLAGUE, CardNames.POVERTY, CardNames.WAR];

	hexes.forEach(function (hex) {
		return CardNameAssociations.set(hex, [CardNames.HEX_DISCARDPILE]);
	});
	CardNameAssociations.set(CardNames.HEX_DISCARDPILE, hexes);
	CardNameAssociations.set(CardNames.DELUSION, [CardNames.DELUDED, CardNames.ENVIOUS, CardNames.HEX_DISCARDPILE]);
	CardNameAssociations.set(CardNames.DELUDED, [CardNames.DELUSION]);
	CardNameAssociations.set(CardNames.ENVY, [CardNames.ENVIOUS, CardNames.DELUDED, CardNames.HEX_DISCARDPILE]);
	CardNameAssociations.set(CardNames.ENVIOUS, [CardNames.ENVY]);
	CardNameAssociations.set(CardNames.MISERY, [CardNames.MISERABLE, CardNames.TWICE_MISERABLE, CardNames.HEX_DISCARDPILE]);
	CardNameAssociations.set(CardNames.MISERABLE, [CardNames.MISERY, CardNames.TWICE_MISERABLE]);
	CardNameAssociations.set(CardNames.TWICE_MISERABLE, [CardNames.MISERY, CardNames.MISERABLE]);

	var zombiesAndNecro = [CardNames.NECROMANCER, CardNames.ZOMBIE_APPRENTICE, CardNames.ZOMBIE_MASON, CardNames.ZOMBIE_SPY];
	genericListHandler(zombiesAndNecro);

	var artifactsAndFriends = [[CardNames.FLAG, CardNames.FLAG_BEARER], [CardNames.HORN, CardNames.LANTERN, CardNames.BORDER_GUARD], [CardNames.KEY, CardNames.TREASURER], [CardNames.TREASURE_CHEST, CardNames.SWASHBUCKLER]];
	artifactsAndFriends.forEach(genericListHandler);

	var horsers = [CardNames.SLEIGH, CardNames.SUPPLIES, CardNames.SCRAP, CardNames.CAVALRY, CardNames.GROOM, CardNames.HOSTELRY, CardNames.LIVERY, CardNames.PADDOCK, CardNames.RIDE, CardNames.DEMAND, CardNames.STAMPEDE];
	horsers.forEach(function (card) {
		return CardNameAssociations.set(card, [CardNames.HORSE]);
	});

	// Allies

	var alliesSplits = [[CardNames.HERB_GATHERER, CardNames.ACOLYTE, CardNames.SORCERESS, CardNames.SIBYL], [CardNames.BATTLE_PLAN, CardNames.ARCHER, CardNames.WARLORD, CardNames.TERRITORY], [CardNames.TENT, CardNames.GARRISON, CardNames.HILL_FORT, CardNames.STRONGHOLD], [CardNames.OLD_MAP, CardNames.VOYAGE, CardNames.SUNKEN_TREASURE, CardNames.DISTANT_SHORE], [CardNames.TOWN_CRIER, CardNames.BLACKSMITH, CardNames.MILLER, CardNames.ELDER], [CardNames.STUDENT, CardNames.CONJURER, CardNames.SORCERER, CardNames.LICH]];

	var pileNames = [CardNames.AUGUR_PILE, CardNames.CLASH_PILE, CardNames.FORT_PILE, CardNames.ODYSSEY_PILE, CardNames.TOWNSFOLK_PILE, CardNames.WIZARD_PILE];
	alliesSplits.forEach(function (list, i) {
		genericListHandler(list);
		CardNameAssociations.set(pileNames[i], list);
	});

	// 2Es
	CardNameAssociations.set(CardNames.RUINS_PROWLER, [CardNames.RUIN_PILE]);
	CardNameAssociations.set(CardNames.BAG_OF_TRICKS, [CardNames.SPOILS]);

	var youthFriends = [CardNames.YOUTH, CardNames.CREATOR, CardNames.DESTROYER];
	genericListHandler(youthFriends);
	CardNameAssociations.set(CardNames.DESPOILER, [CardNames.SPOILS]);
	CardNameAssociations.set(CardNames.INVADER, [CardNames.RUIN_PILE]);
	CardNameAssociations.set(CardNames.WAR_ELEPHANT, [CardNames.RUIN_PILE]);

	var loots = [CardNames.AMPHORA, CardNames.DOUBLOONS, CardNames.ENDLESS_CHALICE, CardNames.FIGUREHEAD, CardNames.HAMMER, CardNames.INSIGNIA, CardNames.JEWELS, CardNames.ORB, CardNames.PRIZE_GOAT, CardNames.PUZZLE_BOX, CardNames.SEXTANT, CardNames.SHIELD, CardNames.SPELL_SCROLL, CardNames.STAFF, CardNames.SWORD];
	var lootGenerators = [CardNames.JEWELLED_EGG, CardNames.SEARCH, CardNames.CUTTHROAT, CardNames.PICKAXE, CardNames.WEALTHY_VILLAGE, CardNames.SACK_OF_LOOT, CardNames.PERIL, CardNames.FORAY, CardNames.LOOTING, CardNames.INVASION, CardNames.PROSPER, CardNames.CURSED];
	loots.forEach(function (loot) {
		return CardNameAssociations.set(loot, loots.filter(function (l) {
			return l !== loot;
		}));
	});
	lootGenerators.forEach(function (c) {
		return CardNameAssociations.set(c, loots);
	});
};

setCardNameAssociations();