"use strict";

var EsperantoAutoPlays = {};

EsperantoAutoPlays[AutoPlays.MOAT] = ["Elŝaltita", "Ĉiam malkaŝu"];
EsperantoAutoPlays[AutoPlays.TRADER] = ["Elŝaltita", "Ne reagu al Arĝentogajno"];
EsperantoAutoPlays[AutoPlays.CROWN] = ["Elŝaltita", "Aŭtomate finu Agfazon"];
EsperantoAutoPlays[AutoPlays.CHANGELING] = ["Elŝaltita", "Demandu ruze", "Neniam interŝanĝu"];
EsperantoAutoPlays[AutoPlays.URCHIN] = ["Elŝaltita", "Neniam rubigu"];
EsperantoAutoPlays[AutoPlays.MOUNTEBANK] = ["Elŝaltita", "Ĉiam forĵetu"];
EsperantoAutoPlays[AutoPlays.SETTLERS] = ["Elŝaltita", "Ĉiam prenu Kupron"];
EsperantoAutoPlays[AutoPlays.BUSTLING_VILLAGE] = ["Elŝaltita", "Ĉiam prenu Setlantjojn"];
EsperantoAutoPlays[AutoPlays.TOURNAMENT] = ["Elŝaltita", "Ĉiam malkaŝu Provincon"];
EsperantoAutoPlays[AutoPlays.PEASANT] = ["Elŝaltita", "Defaŭlte interŝanĝu", "Defaŭlte gardu"];
EsperantoAutoPlays[AutoPlays.SOLDIER] = ["Elŝaltita", "Defaŭlte interŝanĝu", "Defaŭlte gardu"];
EsperantoAutoPlays[AutoPlays.FUGITIVE] = ["Elŝaltita", "Defaŭlte interŝanĝu", "Defaŭlte gardu"];
EsperantoAutoPlays[AutoPlays.DISCIPLE] = ["Elŝaltita", "Defaŭlte interŝanĝu", "Defaŭlte gardu"];
EsperantoAutoPlays[AutoPlays.PAGE] = ["Elŝaltita", "Defaŭlte interŝanĝu", "Defaŭlte gardu"];
EsperantoAutoPlays[AutoPlays.TREASURE_HUNTER] = ["Elŝaltita", "Defaŭlte interŝanĝu", "Defaŭlte gardu"];
EsperantoAutoPlays[AutoPlays.WARRIOR] = ["Elŝaltita", "Defaŭlte interŝanĝu", "Defaŭlte gardu"];
EsperantoAutoPlays[AutoPlays.HERO] = ["Elŝaltita", "Defaŭlte interŝanĝu", "Defaŭlte gardu"];
EsperantoAutoPlays[AutoPlays.FAITHFUL_HOUND] = ["Elŝaltita", "Ĉiam flankenmetu"];
EsperantoAutoPlays[AutoPlays.YOUNG_WITCH] = ["Elŝaltita", "Ĉiam malkaŝu Malhelpon"];
EsperantoAutoPlays[AutoPlays.TREASURY] = ["Elŝaltita", "Ĉiam surmetu"];
EsperantoAutoPlays[AutoPlays.ALCHEMIST] = ["Elŝaltita", "Ĉiam surmetu"];
EsperantoAutoPlays[AutoPlays.WALLED_VILLAGE] = ["Elŝaltita", "Ĉiam surmetu"];
EsperantoAutoPlays[AutoPlays.HERBALIST] = ["Elŝaltita", "Ajna ordo"];
EsperantoAutoPlays[AutoPlays.ROYAL_SEAL] = ["Elŝaltita", "Ĉiam surmetu", "Neniam surmetu"];
EsperantoAutoPlays[AutoPlays.COIN_OF_THE_REALM] = ["Elŝaltita", "Demandu ruze"];
EsperantoAutoPlays[AutoPlays.BORDER_GUARD] = ["Elŝaltita", "Ĉiam surmetu"];
EsperantoAutoPlays[AutoPlays.PROVINCE] = ["Elŝaltita", "Gardu malbonajn kartojn en Ekzilo"];
EsperantoAutoPlays[AutoPlays.CHAMPION] = ["Elŝaltita", "Rekonsideru ruze Vojojn"];
EsperantoAutoPlays[AutoPlays.WAY_OF_THE_BUTTERFLY] = ["Elŝaltita", "Redonu ruze"];
EsperantoAutoPlays[AutoPlays.DESPERATION] = ["Elŝaltita", "Ĉiam gajnu Malbenon"];
EsperantoAutoPlays[AutoPlays.TORTURER] = ["Elŝaltita", "Ne forĵetu senbezone"];
EsperantoAutoPlays[AutoPlays.ARENA] = ["Elŝaltita", "Ne forĵetu senbezone"];
EsperantoAutoPlays[AutoPlays.PAGEANT] = ["Elŝaltita", "Ĉiam prenu Monrezervon"];
EsperantoAutoPlays[AutoPlays.OLD_WITCH] = ["Elŝaltita", "Ĉiam rubigu Malbenon"];
EsperantoAutoPlays[AutoPlays.SINISTER_PLOT] = ["Elŝaltita", "Neniam tiru nul kartojn"];
EsperantoAutoPlays[AutoPlays.MONEYLENDER] = ["Elŝaltita", "Ĉiam rubigu Kupron"];
EsperantoAutoPlays[AutoPlays.ENCAMPMENT] = ["Elŝaltita", "Malkaŝu ruze"];
EsperantoAutoPlays[AutoPlays.DUPLICATE] = ["Elŝaltita", "Nur gajnebla kartoj", "Ankaŭ neniom Malbenojn"];