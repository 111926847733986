"use strict";

webclient.component('genderSelect', {
    template: '<div class="gender-select-container"><div dropdown-select="$ctrl.options"\n                    dropdown-model="$ctrl.selectedItem"\n                    dropdown-item-label="text"\n                    dropdown-onchange="$ctrl.onChange(selected)">\n            </div></div>',
    bindings: {
        placeholder: '@',
        ngModel: '='
    },
    controller: function controller() {
        var self = this;
        self.options = [];
        self.selectedItem = { text: "Show me as..." };

        var genders = getValues(POSSESSIVE_DETERMINERS);
        genders.forEach(function (g) {
            self.options.push({ text: g.toString(), value: getOrdinal(POSSESSIVE_DETERMINERS, g) });
        });

        self.onChange = function (obj) {
            self.ngModel = obj.value;
            // timeLog("PossessiveDeterminer: ", obj.value);
        };
    }
});