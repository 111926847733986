"use strict";

var publicLeaderboard = void 0;

webclient.service('leaderboardService', ['$rootScope', 'meta', function ($rootScope, meta) {
    var self = this;
    var scope = $rootScope.$new(true);
    var leaderboard2P = [];
    var leaderboard34P = [];
    var selectedType = LEADERBOARD_TYPES.TWO_PLAYER;

    if (isDebug) publicLeaderboard = this;

    var selectedEntry = new LeaderboardEntry(new NamedId(0, ''), 0, 0, 0, 0, 0, 0, 0, 0, 0);

    self.getLeaderboard = function () {
        if (selectedType === LEADERBOARD_TYPES.TWO_PLAYER) {
            return leaderboard2P;
        } else if (selectedType === LEADERBOARD_TYPES.THREE_PLUS_PLAYER) {
            return leaderboard34P;
        } else {
            throw new Error('Unknown leaderboard type.');
        }
    };

    self.select = function (e) {
        return selectedEntry = e;
    };
    self.getSelectedEntry = function () {
        return selectedEntry;
    };

    self.selectType = function (t) {
        return selectedType = t;
    };
    self.getSelectedType = function () {
        return selectedType;
    };

    scope.$on(Events.LEADERBOARD_RECEIVED, function (event, leaderboard) {
        leaderboard2P = leaderboard.leaderboard2P;
        leaderboard34P = leaderboard.leaderboard34P;
        var board = self.getLeaderboard();
        var candidate = board.find(function (e) {
            return e.getId() === meta.getMyId();
        });
        if (!isUndefined(candidate)) selectedEntry = candidate;
        $rootScope.safeApply();
    });
}]);