"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function EntryString(string, index) {
    var bullet = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

    var self = this;
    self.string = string;
    self.index = index;
    self.isBullet = bullet;
}

var LogEntry = function () {
    function LogEntry(index, depth, name, logArguments) {
        _classCallCheck(this, LogEntry);

        this.index = index;
        this.depth = depth;
        this.name = name;
        this.logArguments = logArguments;
        this.renderedLines = [];
    }

    _createClass(LogEntry, null, [{
        key: "parse",
        value: function parse(reader) {
            var lineIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : -1;

            var ordinal = reader.readInt();
            var name = getByOrdinal(LogEntryNames, ordinal);
            var depth = reader.readInt();
            var logArguments = reader.readArrayOf(LogArgument);

            return new LogEntry(lineIndex, depth, name, logArguments);
        }
    }]);

    return LogEntry;
}();

var DecisionEntry = function () {
    function DecisionEntry(index, decisionIndex, playerIndex, decision, autoPlayed) {
        _classCallCheck(this, DecisionEntry);

        this.index = index;
        this.decisionIndex = decisionIndex;
        this.playerIndex = playerIndex;
        this.decision = decision;
        this.autoPlayed = autoPlayed;
        this.renderedLines = [];
    }

    _createClass(DecisionEntry, null, [{
        key: "parse",
        value: function parse(reader) {
            var lineIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : -1;

            var decisionIndex = reader.readInt();
            var playerIndex = reader.readInt();
            var decision = reader.readInts();
            var autoPlayed = reader.readBoolean();
            return new DecisionEntry(lineIndex, decisionIndex, playerIndex, decision, autoPlayed);
        }
    }]);

    return DecisionEntry;
}();

var GameLog = function () {
    function GameLog(logEntries, decisionEntries) {
        _classCallCheck(this, GameLog);

        this.logEntries = logEntries;
        this.decisionEntries = decisionEntries;
        this.indexedEntries = logEntries.concat(decisionEntries).sort(function (a, b) {
            return a.index - b.index;
        });
    }

    _createClass(GameLog, null, [{
        key: "parse",
        value: function parse(reader, startIndex, size) {
            var logEntries = [];
            var decisionEntries = [];
            for (var i = startIndex; i < size; i++) {
                var type = reader.readInt();
                if (type === 0) {
                    logEntries.push(LogEntry.parse(reader, i));
                } else {
                    decisionEntries.push(DecisionEntry.parse(reader, i));
                }
            }
            return new GameLog(logEntries, decisionEntries);
        }
    }]);

    return GameLog;
}();

var OwnedZone = function () {
    function OwnedZone(playerIndex, zoneIndex) {
        _classCallCheck(this, OwnedZone);

        this.playerIndex = playerIndex;
        this.zoneIndex = zoneIndex;
    }

    _createClass(OwnedZone, null, [{
        key: "parse",
        value: function parse(reader) {
            var playerIndex = reader.readInt();
            var zoneIndex = reader.readInt();
            return new OwnedZone(playerIndex, zoneIndex);
        }
    }]);

    return OwnedZone;
}();

var LoggedTurn = function () {
    function LoggedTurn(ownerId, turnNumber, turnType, controllerId) {
        _classCallCheck(this, LoggedTurn);

        this.ownerId = ownerId;
        this.turnNumber = turnNumber;
        this.turnType = turnType;
        this.controllerId = controllerId;
    }

    _createClass(LoggedTurn, null, [{
        key: "parse",
        value: function parse(reader) {
            var owner = reader.readInt();
            var number = reader.readInt();
            var type = reader.readInt();
            var controller = reader.readInt();
            return new LoggedTurn(owner, number, type, controller);
        }
    }]);

    return LoggedTurn;
}();

var LogArgument = function () {
    function LogArgument(type, argument) {
        _classCallCheck(this, LogArgument);

        this.type = type;
        this.argument = argument;
    }

    _createClass(LogArgument, null, [{
        key: "parse",
        value: function parse(reader) {
            var type = reader.readInt();
            var argument = getValues(LogArgumentParsers)[type](reader);
            return new LogArgument(type, argument);
        }
    }]);

    return LogArgument;
}();

var LogArgumentParsers = {
    CARD_NAMES: function CARD_NAMES(reader) {
        return reader.readArrayOf(CardFrequency);
    },
    PLAYER: function PLAYER(reader) {
        return reader.readInt();
    },
    ZONE: function ZONE(reader) {
        return reader.readInt();
    },
    OWN_ZONE: function OWN_ZONE(reader) {
        return OwnedZone.parse(reader);
    },
    NUMBER: function NUMBER(reader) {
        return reader.readInt();
    },
    COUNTER: function COUNTER(reader) {
        return reader.readInt();
    },
    TURN_DESCRIPTION: function TURN_DESCRIPTION(reader) {
        return LoggedTurn.parse(reader);
    },
    AMOUNT: function AMOUNT(reader) {
        return Cost.parse(reader);
    },
    ABILITY_DESCRIPTION: function ABILITY_DESCRIPTION(reader) {
        return Ability.parse(reader);
    },
    EVENT: function EVENT(reader) {
        return AbilityEvent.parse(reader);
    },
    CARD: function CARD(reader) {
        return reader.readInt();
    },
    SINGULAR_CARD: function SINGULAR_CARD(reader) {
        return CardName.parse(reader);
    },
    TOKEN: function TOKEN(reader) {
        return reader.readInt();
    },
    DIRECTIONAL_ZONE: function DIRECTIONAL_ZONE(reader) {
        return DirectionalZone.parse(reader);
    },
    PLAYERS: function PLAYERS(reader) {
        return reader.readInts();
    },
    METAGAME_INFO: function METAGAME_INFO(reader) {
        return MetaGameInfo.parse(reader);
    }
};

function LogEntryName() {
    var lineClass = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";

    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
    self.lineClass = lineClass;
}

var LogEntryNames = {
    GAIN: new LogEntryName(),
    GAIN_WITH: new LogEntryName(),
    BUY_AND_GAIN: new LogEntryName(),
    TRASH: new LogEntryName(),
    DISCARD: new LogEntryName(),
    DISCARD_WITH: new LogEntryName(),
    PLAY: new LogEntryName(),
    PLAY_AGAIN: new LogEntryName(),
    PLAY_THIRD: new LogEntryName(),
    TOPDECK: new LogEntryName(),
    DRAW: new LogEntryName(),
    REVEAL: new LogEntryName(),
    REVEAL_SEARCHING: new LogEntryName(),
    REVEAL_FINDING: new LogEntryName(),
    REVEAL_SEARCHING_AND_FINDING: new LogEntryName(),
    REVEAL_HAND: new LogEntryName(),
    REVEAL_DISCARD: new LogEntryName(),
    LOOK_AT: new LogEntryName(),
    PUT_IN_HAND: new LogEntryName(),
    SET_ASIDE: new LogEntryName(),
    PUT_ON_MAT: new LogEntryName(),
    CALL: new LogEntryName(),
    DECK_TO_DISCARD: new LogEntryName(),
    BACK_ON_DECK: new LogEntryName(),
    SHUFFLE_INTO_DECK: new LogEntryName(),
    INSERT_IN_DECK: new LogEntryName(),
    EXCHANGE_RETURN: new LogEntryName(),
    EXCHANGE_RECEIVE: new LogEntryName(),
    PASS: new LogEntryName(),
    STARTS_WITH: new LogEntryName(),
    BUY: new LogEntryName(),
    COUNTER_ADD: new LogEntryName(),
    COUNTER_RESET: new LogEntryName(),
    REACTS_WITH: new LogEntryName(),
    REACTS_PLAYING: new LogEntryName(),
    SHUFFLES: new LogEntryName("shuffles-line"),
    NEW_TURN: new LogEntryName("new-turn-line"),
    WISH_CORRECT: new LogEntryName(),
    WISH_WRONG: new LogEntryName(),
    BLACK_MARKET_RESHUFFLE: new LogEntryName(),
    RETURN_TO: new LogEntryName(),
    PUT_ON_BOTTOM_OF: new LogEntryName(),

    LOSE_TRACK_MOVED: new LogEntryName(),
    LOSE_TRACK_HIDDEN: new LogEntryName(),
    LOSE_TRACK_RESHUFFLED: new LogEntryName(),
    LOSE_TRACK_ANONYMOUS: new LogEntryName(),

    // NEW_REGULAR_TURN: new LogEntryName(),
    // NEW_OUTPOST_TURN: new LogEntryName(),
    // NEW_POSSESSION_TURN: new LogEntryName(),
    // NEW_MISSION_TURN: new LogEntryName(),
    // NEW_BETWEEN_TURNS: new LogEntryName(),

    EXPLAIN_POINTS_BANDIT_CAMP: new LogEntryName(),
    EXPLAIN_POINTS_TOWER: new LogEntryName(),
    EXPLAIN_POINTS_TRIUMPHAL_ARCH_ABSENT: new LogEntryName(),
    EXPLAIN_POINTS_TRIUMPHAL_ARCH: new LogEntryName(),
    EXPLAIN_POINTS_WALL: new LogEntryName(),
    EXPLAIN_POINTS_WOLF_DEN: new LogEntryName(),
    EXPLAIN_POINTS_PALACE: new LogEntryName(),
    EXPLAIN_POINTS_ORCHARD: new LogEntryName(),
    EXPLAIN_POINTS_OBELISK: new LogEntryName(),
    EXPLAIN_POINTS_MUSEUM: new LogEntryName(),
    EXPLAIN_POINTS_KINGS_CASTLE: new LogEntryName(),
    EXPLAIN_POINTS_KEEP: new LogEntryName(),
    EXPLAIN_POINTS_HUMBLE_CASTLE: new LogEntryName(),
    EXPLAIN_POINTS_FOUNTAIN: new LogEntryName(),
    EXPLAIN_POINTS_FEODUM: new LogEntryName(),
    EXPLAIN_POINTS_GARDENS: new LogEntryName(),
    EXPLAIN_POINTS_VINEYARD: new LogEntryName(),
    EXPLAIN_POINTS_SILK_ROAD: new LogEntryName(),
    EXPLAIN_POINTS_FAIRGROUNDS: new LogEntryName(),
    EXPLAIN_POINTS_DUKE: new LogEntryName(),
    EXPLAIN_POINTS_DUH: new LogEntryName(),
    EXPLAIN_POINTS_VP_TOKENS: new LogEntryName(),

    PUT_IN_HAND_FROM: new LogEntryName(),
    STARTS_TURN: new LogEntryName(),
    TAKE_VP_FROM: new LogEntryName(),
    MOVE_VP_FROM_TO: new LogEntryName(),
    OBELISK_CHOOSES_PILE: new LogEntryName(),
    PLAYER_MOVES_TOKEN_TO: new LogEntryName(),
    PLAYER_GETS_VP: new LogEntryName(),
    PLAYER_GETS_VP_FROM: new LogEntryName(),
    ADDS_VP_TO: new LogEntryName(),
    WAITING_FOR: new LogEntryName(),
    ALMS_FAIL: new LogEntryName(),
    BORROW_FAIL: new LogEntryName(),
    QUEST_FAIL_ATTACK: new LogEntryName(),
    QUEST_FAIL_CURSES: new LogEntryName(),
    QUEST_FAIL_DISCARD: new LogEntryName(),
    SAVE_FAIL: new LogEntryName(),
    SCOUTING_PARTY_FAIL: new LogEntryName(),
    BONFIRE_FAIL: new LogEntryName(),
    MISSION_SUCCESS: new LogEntryName(),
    TRADE_FAIL: new LogEntryName(),
    PLAYER_FLIPS_TOKEN_DOWN: new LogEntryName(),
    PLAYER_FLIPS_TOKEN_UP: new LogEntryName(),
    TRIUMPH_FAIL: new LogEntryName(),
    ADVANCE_FAIL: new LogEntryName(),
    TAX_ADD_DEBT: new LogEntryName(),
    TAX_TAKE_DEBT: new LogEntryName(),
    RITUAL_FAIL: new LogEntryName(),
    WINDFALL_FAIL: new LogEntryName(),
    DOMINATE_FAIL: new LogEntryName(),
    PLUS_ACTION_FROM: new LogEntryName(),
    PLUS_BUY_FROM: new LogEntryName(),
    DRAWS_FROM: new LogEntryName(),
    PLUS_COIN_FROM: new LogEntryName(),
    DRAWS_LESS_BECAUSE: new LogEntryName(),
    PRINCE_FAILS: new LogEntryName(),
    POSSESSION_TAKES_COIN_TOKENS: new LogEntryName(),
    POSSESSION_TAKES_DEBT_TOKENS: new LogEntryName(),
    POSSESION_TAKES_VP_TOKENS: new LogEntryName(),
    TAKES_DEBT: new LogEntryName(),
    REPAYS_DEBT: new LogEntryName(),
    REPAYS_SOME_DEBT: new LogEntryName(),
    RETURNS_TOKEN: new LogEntryName(),
    SET_ASIDE_WITH: new LogEntryName(),
    BLOCKS_WITH: new LogEntryName(),
    NAMES: new LogEntryName(),
    GAIN_ON_DRAWPILE: new LogEntryName(),
    GAIN_FROM_TRASH: new LogEntryName(),
    OBELISK_FAILS: new LogEntryName(),
    INHERITS: new LogEntryName(),
    GAME_META_INFO: new LogEntryName(),
    THE_SKYS_GIFT_FAIL: new LogEntryName(),
    RECEIVES: new LogEntryName(),
    EXPLAIN_POINTS_PASTURE: new LogEntryName(),
    TAKE: new LogEntryName(),
    RETURN: new LogEntryName(),
    THE_SUNS_GIFT_FAIL: new LogEntryName(),
    DRUID_SELECTS_BOONS: new LogEntryName(),
    DISCARD_RECEIVABLE: new LogEntryName(),
    PLAY_ENCHANTED: new LogEntryName(),
    GAIN_ANOTHER_EXPERIMENT: new LogEntryName(),
    COFFERS_FROM_PATRON: new LogEntryName(),
    GETS_COFFER: new LogEntryName(),
    GETS_COFFERS: new LogEntryName(),
    GETS_COFFER_FROM: new LogEntryName(),
    GETS_COFFERS_FROM: new LogEntryName(),
    USES_COFFER: new LogEntryName(),
    USES_COFFERS: new LogEntryName(),
    GETS_VILLAGER: new LogEntryName(),
    GETS_VILLAGERS: new LogEntryName(),
    GETS_VILLAGER_FROM: new LogEntryName(),
    GETS_VILLAGERS_FROM: new LogEntryName(),
    USES_VILLAGER: new LogEntryName(),
    USES_VILLAGERS: new LogEntryName(),
    BEGINS_BUYPHASE: new LogEntryName(),
    ENDS_BUYPHASE: new LogEntryName(),
    GETS_ACTION: new LogEntryName(),
    GETS_ACTIONS: new LogEntryName(),
    GETS_ACTION_FROM: new LogEntryName(),
    GETS_ACTIONS_FROM: new LogEntryName(),
    GETS_BUY: new LogEntryName(),
    GETS_BUYS: new LogEntryName(),
    GETS_BUY_FROM: new LogEntryName(),
    GETS_BUYS_FROM: new LogEntryName(),
    GETS_COIN: new LogEntryName(),
    GETS_COINS: new LogEntryName(),
    GETS_COIN_FROM: new LogEntryName(),
    GETS_COINS_FROM: new LogEntryName(),
    DRAW_FROM: new LogEntryName(),
    LOSES_COIN: new LogEntryName(),
    LOSES_COINS: new LogEntryName(),
    BOTTOMDECKS: new LogEntryName(),
    TRASH_WITH: new LogEntryName(),
    PRIEST_ACTIVATES: new LogEntryName("italics-line"),
    PLAY_TREASURES_FOR: new LogEntryName(),
    PLAY_TREASURES_AGAIN_FOR: new LogEntryName(),
    PLAY_TREASURES_THIRD_FOR: new LogEntryName(),
    RETURNS_MINUS_COIN_TOKEN: new LogEntryName(),
    NOT_IN_PLAY_PREVENTED_PLAYING: new LogEntryName(),
    PILLAGE_FAIL: new LogEntryName(),
    DEATH_CART_FAIL: new LogEntryName(),
    TACTICIAN_FAIL: new LogEntryName(),
    EMBARGO_FAIL: new LogEntryName(),
    EXILE: new LogEntryName(),
    DISCARD_FROM_EXILE: new LogEntryName(),
    DISCARD_REVEALED: new LogEntryName(),
    PLAY_WITH: new LogEntryName(),
    PLAY_AGAIN_WITH: new LogEntryName(),
    PLAY_THIRD_WITH: new LogEntryName(),
    IS_INVESTED: new LogEntryName(),
    COMMERCE_FAIL: new LogEntryName(),
    STAMPEDE_FAIL: new LogEntryName(),
    PLAY_USING_WAY: new LogEntryName(),
    WAY_OF_THE_HORSE_FAILS_NO_PILE: new LogEntryName(),
    WAY_OF_THE_HORSE_FAILS_NOT_IN_PLAY: new LogEntryName(),
    WAY_OF_THE_TURTLE_FAILS_TO_SET_ASIDE: new LogEntryName(),
    USES_WAY: new LogEntryName(),
    WOULD_GET_COIN: new LogEntryName(),
    WOULD_DRAW: new LogEntryName(),
    TOPDECK_WITH: new LogEntryName(),
    WAY_OF_THE_BUTTERFLY_FAILS_NO_PILE: new LogEntryName(),
    WAY_OF_THE_BUTTERFLY_FAILS_NOT_IN_PLAY: new LogEntryName(),
    WAY_OF_THE_BUTTERFLY_FAILS_INTENTIONAL: new LogEntryName(),
    PLAY_NULLIFIED: new LogEntryName(),
    GETS_FAVOR: new LogEntryName(),
    GETS_FAVORS: new LogEntryName(),
    GETS_FAVOR_FROM: new LogEntryName(),
    GETS_FAVORS_FROM: new LogEntryName(),
    SPENDS_FAVOR: new LogEntryName(),
    SPENDS_FAVORS: new LogEntryName(),
    ROTATES_EMPTY_PILE: new LogEntryName(),
    ROTATES_IDENTICAL_PILE: new LogEntryName(),
    ROTATES: new LogEntryName(),
    ISLAND_FOLK: new LogEntryName(),
    ROYAL_GALLEY_FAIL: new LogEntryName(),
    FAILS_TO_PLAY: new LogEntryName(),
    FAILS_TO_PLAY_WRONG_LOCATION: new LogEntryName(),
    STRANDS_CARDS: new LogEntryName(),
    VOYAGE_SUCCESS: new LogEntryName(),
    GARRISON_ADDS_TOKEN: new LogEntryName(),
    EXPLAIN_POINTS_PLATEAU_SHEPHERDS: new LogEntryName(),
    PUT_IN_DISCARD: new LogEntryName(),
    EXPLAIN_POINTS_TERRITORY: new LogEntryName(),
    SORCERESS_GUESS: new LogEntryName(),
    SORCERER_GUESS: new LogEntryName(),
    SKIPS_A_TURN_REMAINING: new LogEntryName(),
    SKIPS_A_TURN: new LogEntryName(),
    CANCELS_A_TURN: new LogEntryName(),
    WILL_SKIP_A_TURN: new LogEntryName(),
    WILL_SKIP_A_TURN_COUNT: new LogEntryName(),
    KEEPS_IN_HAND: new LogEntryName(),
    INVESTMENT_FAIL: new LogEntryName(),
    GAIN_SETTING_ASIDE: new LogEntryName(),
    EXPLAIN_POINTS_MARCHLAND: new LogEntryName(),
    PLAYER_BIDS: new LogEntryName(),
    PLAYER_ADDS_FAVOR_TOKEN_TO: new LogEntryName(),
    SPELL_SCROLL_FAILS: new LogEntryName(),
    PERIL_FAIL: new LogEntryName(),
    BEGINS_CLEANUP_PHASE: new LogEntryName(),
    ACQUIRES_TRAIT: new LogEntryName(),
    TRAIT_FAILS: new LogEntryName(),
    SHUFFLES_CARDS: new LogEntryName(),
    FARMHANDS_FAIL: new LogEntryName(),
    FARMHANDS_REFUSE: new LogEntryName(),
    EXPLAIN_POINTS_DEMESNE: new LogEntryName(),
    PLAY_ENLIGHTENED: new LogEntryName(),
    PANIC_FAILS: new LogEntryName(),
    PANIC_FAILS_NO_PILE: new LogEntryName(),
    AMASS_FAILS: new LogEntryName(),
    // This won't be correlated with anything serverside - make sure it's after the last "real" one
    PREMOVES: new LogEntryName()
};