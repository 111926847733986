"use strict";

var publicTable = void 0;

webclient.controller('TableController', ['$scope', 'meta', 'tableService', 'chat', 'metaBroadcaster', 'uiGridConstants', 'serverMessenger', function ($scope, meta, tableService, chat, metaBroadcaster, uiGridConstants, serverMessenger) {
    var tableDisplay = this;
    tableDisplay.chatLines = [];
    tableDisplay.tableRules = [];
    tableDisplay.chat = chat;

    tableDisplay.gridOptions = { enableRowSelection: false, enableRowHeaderSelection: false };
    tableDisplay.gridOptions.columnDefs = [{ name: 'players', width: "150", cellClass: 'no-separator' }, { name: 'spectators', width: "*", cellClass: 'no-separator' }];
    // 150s above should be * or 50%, but not working yet

    tableDisplay.gridOptions.multiSelect = false;
    tableDisplay.gridOptions.modifierKeysToMultiSelect = false;
    tableDisplay.gridOptions.noUnselect = true;
    tableDisplay.gridOptions.enableColumnMenus = false;
    tableDisplay.gridOptions.enableHorizontalScrollbar = uiGridConstants.scrollbars.NEVER;

    tableDisplay.gridOptions.data = [];

    tableDisplay.getKingdomString = function (cardNames) {
        if (isUndefined(cardNames)) return "";
        return cardNames.map(function (c) {
            return getSingularCardName(c);
        }).join(', ');
    };

    tableDisplay.seatOptions = [1, 2, 3, 4, 5, 6];
    tableDisplay.numberOfSeats = 1;
    tableDisplay.table = tableService.details;

    tableDisplay.chatMessage = "";

    tableDisplay.amIHost = function () {
        return tableService.isHost(meta.model.me.id);
    };

    var fillPlayerTable = function fillPlayerTable(details) {
        tableDisplay.gridOptions.data = [];
        var playerIds = details.players;
        var players = details.playerNames;
        var spectatorIds = details.spectators;
        var spectators = details.spectatorNames;
        var n = Math.max(players.length, spectators.length);
        for (var i = 0; i < n; i++) {
            tableDisplay.gridOptions.data.push({
                'players': isUndefined(players[i]) ? "" : players[i] + (tableDisplay.table.isHost(playerIds[i]) ? " (host)" : ""),
                'spectators': isUndefined(spectators[i]) ? "" : spectators[i] + (tableDisplay.table.isHost(spectatorIds[i]) ? " (host)" : "")
            });
        }
    };

    $scope.$on(Events.TABLE_DETAILS, function (event, details) {
        if (isUndefined(tableDisplay.table)) return;
        if (details.tableId !== tableDisplay.table.tableId) return;
        publicTable = details;
        fillPlayerTable(details);
        $scope.$digest();
    });

    function joinTable(tableDetails) {
        tableDisplay.table = tableDetails;
        publicTable = tableDetails;
        meta.model.me.tableDetails = tableDetails;
        fillPlayerTable(tableDetails);
    }

    if (tableService.hasTable()) {
        joinTable(tableService.getTable());
    }

    $scope.$on(Events.SHOW_TABLE, function (event, tableDetails) {
        joinTable(tableDetails);
        if (typeof $scope.lobbyDisplay !== "undefined") {
            $scope.lobbyDisplay.showTable();
        }
        $scope.$digest();
    });

    $scope.$on(Events.PLAYER_JOINED, function (event, playerJoined) {
        fillPlayerTable(tableService.getTable());
        $scope.$digest();
    });

    $scope.$on(Events.NEW_ROLE, function (event, newRole) {
        var table = tableDisplay.table;
        fillPlayerTable(table);
        $scope.$digest();
    });

    $scope.$on(Events.PLAYER_LEFT, function (event, playerLeft) {
        var table = tableDisplay.table;
        if (isUndefined(table)) throw new Error("A player is leaving an undefined table.");
        var tableId = playerLeft.tableId;
        if (tableId !== table.tableId) throw new Error("Leave message does not pertain to currently active table.");
        var playerId = playerLeft.playerId;
        if (meta.model.me.id === playerId) return;
        fillPlayerTable(tableService.getTable());
        $scope.$digest();
    });

    $scope.$on(Events.HOST_CHANGED, function (event, hostChange) {
        var table = tableDisplay.table;
        if (isUndefined(table)) return;
        if (hostChange.tableId !== table.tableId) return;
        table.hostId = hostChange.newHost;
        fillPlayerTable(table);
        $scope.$digest();
    });

    tableDisplay.sendChat = function () {
        if (tableDisplay.chat.message === "") return;
        var receiver = meta.getTableIdString();
        var sender = 'p' + meta.getMyId();
        serverMessenger.chatMessage(receiver, sender, tableDisplay.chat.message);
        tableDisplay.chat.message = "";
    };

    $scope.$on(Events.CHAT_MESSAGE_PROCESSED, function () {
        $scope.$digest();
        scrollToBottom();
    });

    function scrollToBottom() {
        try {
            var chatElement = document.getElementsByClassName("table-chat-display")[0];
            var shouldScrollToBottom = chatElement.scrollHeight - chatElement.clientHeight <= chatElement.scrollTop + 100;
            if (shouldScrollToBottom) chatElement.scrollTop = chatElement.scrollHeight - chatElement.clientHeight;
        } catch (e) {
            // Do nothing
        }
    }

    function forceScrollToBottom() {
        try {
            var chatElement = document.getElementsByClassName("table-chat-display")[0];
            chatElement.scrollTop = chatElement.scrollHeight - chatElement.clientHeight;
        } catch (e) {
            // Do nothing
        }
    }

    setTimeout(forceScrollToBottom(), 1000);

    $scope.$on(Events.LOBBY_PAGE_LOADED, function () {
        return forceScrollToBottom();
    });

    tableDisplay.startGame = function () {
        serverMessenger.startGameRequest(tableDisplay.table.tableId, true);
    };

    tableDisplay.leaveTable = function () {
        serverMessenger.leaveTable(tableDisplay.table.tableId, meta.model.me.id);
    };

    tableDisplay.viewLobby = function () {
        console.log("returning to lobby because viewLobby was called");
        metaBroadcaster.send(Events.RETURN_TO_LOBBY);
    };
}]);