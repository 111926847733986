"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var RevealZone = function (_WindowedZone) {
    _inherits(RevealZone, _WindowedZone);

    function RevealZone() {
        _classCallCheck(this, RevealZone);

        return _possibleConstructorReturn(this, (RevealZone.__proto__ || Object.getPrototypeOf(RevealZone)).apply(this, arguments));
    }

    _createClass(RevealZone, [{
        key: "initialize",
        value: function initialize(cardIds) {
            _get(RevealZone.prototype.__proto__ || Object.getPrototypeOf(RevealZone.prototype), "initialize", this).call(this, cardIds);
            if (cardIds.length > 0) this.show(this);
        }
    }, {
        key: "addCard",
        value: function addCard(card) {
            var returnStack = _get(RevealZone.prototype.__proto__ || Object.getPrototypeOf(RevealZone.prototype), "addCard", this).call(this, card);
            this.show(this);
            return returnStack;
        }
    }, {
        key: "addAnonymousCard",
        value: function addAnonymousCard() {
            var returnStack = _get(RevealZone.prototype.__proto__ || Object.getPrototypeOf(RevealZone.prototype), "addAnonymousCard", this).call(this);
            this.show(this);
            return returnStack;
        }
    }, {
        key: "spliceCard",
        value: function spliceCard(card) {
            var returnStack = _get(RevealZone.prototype.__proto__ || Object.getPrototypeOf(RevealZone.prototype), "spliceCard", this).call(this, card);
            if (this.cardStacks.length === 0) this.hide(this);
            return returnStack;
        }
    }, {
        key: "spliceAnonymousCard",
        value: function spliceAnonymousCard() {
            var returnStack = _get(RevealZone.prototype.__proto__ || Object.getPrototypeOf(RevealZone.prototype), "spliceAnonymousCard", this).call(this);
            if (this.cardStacks.length === 0) this.hide(this);
            return returnStack;
        }
    }, {
        key: "enableOrdering",
        value: function enableOrdering(question) {
            var _this2 = this;

            if (this.cardStacks.length === 0) return;
            this.leftText = question.type.leftText === null ? "" : getPhrase(question.type.leftText);
            this.rightText = question.type.rightText === null ? "" : getPhrase(question.type.rightText);

            var accumulateAnswers = function accumulateAnswers() {
                question.accumulatedAnswers = [];
                _this2.cardStacks.forEach(function (stack) {
                    var index = question.content.elements.indexOf(stack.topCard.id);
                    if (index > -1) question.accumulateAnswer(index);else stack.addFilter("ignored-order");
                });
                question.updateButtons();
            };
            accumulateAnswers();
            this.reposition(true);

            this.cardStacks.forEach(function (stack) {
                stack.view.canvas.style.cursor = "grab";
                var mouseDownHandler = function mouseDownHandler(offsetX, offsetY) {
                    stack.addFilter("free-grab");
                    stack.view.canvas.style.cursor = "grabbing";
                    var moveListener = function moveListener(clientX, clientY) {
                        stack.view.bbox.y = clientY - offsetY;
                        stack.view.bbox.x = clientX - offsetX;
                        stack.view.positionCanvas();

                        var leftStacks = _this2.cardStacks.filter(function (s) {
                            return !s.hasFilter("ignored-order") && s.view.bbox.x < stack.view.bbox.x;
                        });
                        var index = _this2.cardStacks.indexOf(stack);
                        if (leftStacks.length !== index) {
                            _this2.cardStacks.splice(index, 1);
                            var lastStack = leftStacks[leftStacks.length - 1];
                            _this2.cardStacks.splice(_this2.cardStacks.indexOf(lastStack) + 1, 0, stack);
                            _this2.reposition(true);
                        }
                    };
                    var mouseMoveHandler = function mouseMoveHandler(e) {
                        return moveListener(e.clientX, e.clientY);
                    };
                    var touchMoveHandler = function touchMoveHandler(e) {
                        return moveListener(e.touches[0].clientX, e.touches[0].clientY);
                    };
                    var dropListener = function dropListener() {
                        document.removeEventListener("mousemove", mouseMoveHandler);
                        document.removeEventListener("touchmove", touchMoveHandler);
                        document.removeEventListener("mouseup", dropListener);
                        document.removeEventListener("touchend", dropListener);
                        document.removeEventListener("touchcancel", dropListener);
                        stack.removeFilter("free-grab");
                        accumulateAnswers();
                        _this2.reposition();
                        stack.view.canvas.style.cursor = "grab";
                    };
                    document.addEventListener("mousemove", mouseMoveHandler);
                    document.addEventListener("touchmove", touchMoveHandler);
                    document.addEventListener("mouseup", dropListener);
                    document.addEventListener("touchend", dropListener);
                    document.addEventListener("touchcancel", dropListener);
                };
                stack.view.dragHandler = function (x, y) {
                    return mouseDownHandler(x, y);
                };
            });
        }
    }, {
        key: "disableOrdering",
        value: function disableOrdering() {
            this.cardStacks.forEach(function (stack) {
                stack.view.canvas.style.cursor = "initial";
                stack.view.canvas.dragHandler = null;
                stack.removeFilter("ignored-order");
            });
            this.leftText = "";
            this.rightText = "";
        }
    }, {
        key: "zoneName",
        get: function get() {
            return "RevealZone";
        }
    }, {
        key: "shouldSort",
        get: function get() {
            return this.shouldStack;
        }
    }, {
        key: "titleBarSize",
        get: function get() {
            return 0;
        }
    }]);

    return RevealZone;
}(WindowedZone);