"use strict";

// possible ability descriptions that could use "Argument0" to be more helpful
//
// Argument0 is some number an effect relates to
// ----------------------------------------------
// SpanishAbilityDescriptions[AbilityNames.EMPORIUM_GAIN_POINTS] = "+Argument0 PV (" + SpanishCardNames[CardNames.EMPORIUM].singular + ")";
//    the following needs the (server side?) magic that takes "3" y "Gold" y turns it into the language correct version of "3 Golds"
// SpanishAbilityDescriptions[AbilityNames.FORTUNE] = "gain Argument0 (" + SpanishCardNames[CardNames.FORTUNE].singular + ")";
// SpanishAbilityDescriptions[AbilityNames.GRAND_CASTLE] = "+Argument0 PV (" + SpanishCardNames[CardNames.GRAND_CASTLE].singular + ")";
// SpanishAbilityDescriptions[AbilityNames.TEMPLE] = "take all (Argument0) PV from the " + SpanishCardNames[CardNames.TEMPLE].singular + " pile";
// SpanishAbilityDescriptions[AbilityNames.TAX_RECEIVE] = "take all (Argument0) debt from the pile you bought from (" + SpanishCardNames[CardNames.TAX].singular + ")";
// SpanishAbilityDescriptions[AbilityNames.VP_FROM_AQUEDUCT] = "take all (Argument0) PV from " + SpanishCardNames[CardNames.AQUEDUCT].singular;
// SpanishAbilityDescriptions[AbilityNames.VP_FROM_DEFILED_SHRINE] = "take all (Argument0) PV from " + SpanishCardNames[CardNames.DEFILED_SHRINE].singular;


var SpanishAbilityDescriptions = {};

SpanishAbilityDescriptions[AbilityNames.BUY] = "compra";
SpanishAbilityDescriptions[AbilityNames.PLAY_ATTACK] = "juega";
SpanishAbilityDescriptions[AbilityNames.DRAW_INSTRUCTIONS] = "roba Argument0";
SpanishAbilityDescriptions[AbilityNames.PLAY_INSTRUCTIONS] = "juega";
SpanishAbilityDescriptions[AbilityNames.RESOLVE_EVENT] = "RESOLVE_EVENT";
SpanishAbilityDescriptions[AbilityNames.GAIN_BY_NAME] = "gana Argument0 (Argument1)";
SpanishAbilityDescriptions[AbilityNames.FISHING_VILLAGE_ABILITY] = "+1 Acción, +$1";
SpanishAbilityDescriptions[AbilityNames.REMOVE_COST_REDUCTION] = "REMOVE_COST_REDUCTION";
SpanishAbilityDescriptions[AbilityNames.HAVEN_ABILITY] = "pon Argument0 en tu mano";
SpanishAbilityDescriptions[AbilityNames.SAVE_RETURN_TO_HAND] = "pon Argument0 en tu mano";
SpanishAbilityDescriptions[AbilityNames.CARAVAN_ABILITY] = "+1 Carta";
SpanishAbilityDescriptions[AbilityNames.MERCHANT_SHIP_ABILITY] = "+$2";
SpanishAbilityDescriptions[AbilityNames.OUTPOST_ABILITY] = "[Outpost]";
SpanishAbilityDescriptions[AbilityNames.TACTICIAN_ABILITY] = "+5 Cartas, +1 Acción, +1 Compra";
SpanishAbilityDescriptions[AbilityNames.WHARF_ABILITY] = "+2 Cartas, +1 Compra";
SpanishAbilityDescriptions[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = "+1 Carta, pon [Horse Traders] en tu mano";
SpanishAbilityDescriptions[AbilityNames.DUCHESS_MAY_GAIN] = "gana [^nDuchess]";
SpanishAbilityDescriptions[AbilityNames.FOOLS_GOLD_TRASH] = "elimina [Fools Gold] para poner [^nGold] sobre el mazo";
SpanishAbilityDescriptions[AbilityNames.NOBLE_BRIGAND] = "intenta sustraer [^nSilver] o [^nGold] a tus rivales";
SpanishAbilityDescriptions[AbilityNames.HAGGLER_ABILITY] = "gana una carta más barata que no sea de Victoria";
SpanishAbilityDescriptions[AbilityNames.INN_ABILITY] = "baraja las Acciones de tu descarte en tu mazo";
SpanishAbilityDescriptions[AbilityNames.MANDARIN] = "pon todos tus Tesoros en juego sobre el mazo";
SpanishAbilityDescriptions[AbilityNames.FARMLAND] = "remodela una carta de tu mano a una de exactamente $2 más";
SpanishAbilityDescriptions[AbilityNames.GAIN_CHEAPER] = "gana una carta más barata";
SpanishAbilityDescriptions[AbilityNames.GAIN_TWO_RUINS] = "gana 2 Ruinas";
SpanishAbilityDescriptions[AbilityNames.HUNTING_GROUNDS] = "gana [^nDuchy] o 3 [^pEstate]";
SpanishAbilityDescriptions[AbilityNames.SQUIRE_GAIN_ATTACK] = "gana un Ataque";
SpanishAbilityDescriptions[AbilityNames.URCHIN_TRANSFORM] = "elimina [Urchin] para ganar [^nMercenary]";
SpanishAbilityDescriptions[AbilityNames.FEODUM_GAIN_SILVERS] = "gana 3 [^pSilver]";
SpanishAbilityDescriptions[AbilityNames.DOCTOR_OVERPAY] = "paga de más para eliminar o descartar cartas de tu mazo";
SpanishAbilityDescriptions[AbilityNames.RESOLVE_DOCTOR_OVERPAY] = "trash or discard cards from your deck";
SpanishAbilityDescriptions[AbilityNames.HERALD_OVERPAY] = "paga de más para poner cartas del descarte sobre tu mazo";
SpanishAbilityDescriptions[AbilityNames.RESOLVE_HERALD_OVERPAY] = "topdeck cards from your discard";
SpanishAbilityDescriptions[AbilityNames.MASTERPIECE_OVERPAY] = "paga de más para ganar [^pSilver]";
SpanishAbilityDescriptions[AbilityNames.RESOLVE_MASTERPIECE_OVERPAY] = "gain [^pSilver]";
SpanishAbilityDescriptions[AbilityNames.STONEMASON_OVERPAY] = "paga de más para ganar 2 cartas de Acción";
SpanishAbilityDescriptions[AbilityNames.RESOLVE_STONEMASON_OVERPAY] = "gain 2 Action cards";
SpanishAbilityDescriptions[AbilityNames.EXCHANGE] = "EXCHANGE";
SpanishAbilityDescriptions[AbilityNames.AMULET] = "+$1; o elimina una carta; o gana [^nSilver]";
SpanishAbilityDescriptions[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = "+$1";
SpanishAbilityDescriptions[AbilityNames.DUNGEON_ABILITY] = "+2 Cartas, luego descarta 2 cartas";
SpanishAbilityDescriptions[AbilityNames.GEAR_ABILITY] = "pon Argument0 en tu mano";
SpanishAbilityDescriptions[AbilityNames.MESSENGER_GAIN] = "todos los jugadores ganan la misma carta de hasta $4";
SpanishAbilityDescriptions[AbilityNames.BRIDGE_TROLL_ABILITY] = "+1 Compra";
SpanishAbilityDescriptions[AbilityNames.HAUNTED_WOODS_DURATION] = "+3 Cartas";
SpanishAbilityDescriptions[AbilityNames.HAUNTED_WOODS_TOPDECK] = "poner todo sobre el mazo ([Haunted Woods] del rival)";
SpanishAbilityDescriptions[AbilityNames.LOST_CITY] = "+1 Carta ([Lost City] del rival)";
SpanishAbilityDescriptions[AbilityNames.WINE_MERCHANT_DISCARD] = "descarta [^nWine Merchant] de tu Taberna";
SpanishAbilityDescriptions[AbilityNames.HIRELING] = "+1 Carta";
SpanishAbilityDescriptions[AbilityNames.SWAMP_HAG_DURATION] = "+$3";
SpanishAbilityDescriptions[AbilityNames.TRASH_TOKEN_ABILITY] = "elimina una carta (ficha de Eliminar)";
SpanishAbilityDescriptions[AbilityNames.EMPORIUM_GAIN_POINTS] = "+2 PV, si tienes al menos 5 cartas de Acción en juego";
SpanishAbilityDescriptions[AbilityNames.ROCKS_GAINS_SILVER] = "gana [^nSilver]";
SpanishAbilityDescriptions[AbilityNames.FORTUNE] = "gana [^nGold] por [Gladiator] en juego";
SpanishAbilityDescriptions[AbilityNames.CRUMBLING_CASTLE] = "+1 PV y gana [^nSilver]";
SpanishAbilityDescriptions[AbilityNames.HAUNTED_CASTLE] = "gana [^nGold] y los rivales deben poner sobre el mazo";
SpanishAbilityDescriptions[AbilityNames.SPRAWLING_CASTLE] = "gana [^nDuchy] o 3 [^pEstate]";
SpanishAbilityDescriptions[AbilityNames.GRAND_CASTLE] = "+1 PV por carta de Victoria en juego o en tu mano";
SpanishAbilityDescriptions[AbilityNames.ENCHANTRESS_ABILITY] = "+2 Cartas";
SpanishAbilityDescriptions[AbilityNames.ENCHANTRESS_CANTRIP] = "+1 Carta, +1 Acción";
SpanishAbilityDescriptions[AbilityNames.TEMPLE] = "recoge PV de la pila de [Temple]";
SpanishAbilityDescriptions[AbilityNames.VILLA] = "pon [Villa] en tu mano, +1 Acción, posiblemente vuelve a la fase de Acción";
SpanishAbilityDescriptions[AbilityNames.ARCHIVE_ABILITY] = "coge una de Argument0";
SpanishAbilityDescriptions[AbilityNames.CAPITAL_TAKE_DEBT] = "coge 6 de Deuda, luego puedes saldar Deuda";
SpanishAbilityDescriptions[AbilityNames.CHARM] = "gana una carta differente con el mismo coste";
SpanishAbilityDescriptions[AbilityNames.FORUM] = "+1 Compra";
SpanishAbilityDescriptions[AbilityNames.HERMIT] = "elimina esta, gana [^nMadman]";
SpanishAbilityDescriptions[AbilityNames.DONATE] = "elimina de tu mano, mazo y pila de descartes, combinadas";
SpanishAbilityDescriptions[AbilityNames.TAX_RECEIVE] = "coge la deuda de la pila de la que compraste";
SpanishAbilityDescriptions[AbilityNames.DOMINATE] = "gana [^nProvince], luego +9 PV si lo hiciste";
SpanishAbilityDescriptions[AbilityNames.VP_TO_AQUEDUCT] = "mueve 1 PV de la pila de Tesoro a [Aqueduct]";
SpanishAbilityDescriptions[AbilityNames.VP_FROM_AQUEDUCT] = "coge todos los PV del [Aqueduct]";
SpanishAbilityDescriptions[AbilityNames.ARENA_DISCARD_ACTION] = "descarta una Acción para coger 2 PV de [Arena]";
SpanishAbilityDescriptions[AbilityNames.VP_FROM_BASILICA] = "coge 2 PV de [Basilica] si tienes $2 o más";
SpanishAbilityDescriptions[AbilityNames.VP_FROM_BATHS] = "coge 2 PV de [Baths]";
SpanishAbilityDescriptions[AbilityNames.VP_FROM_BATTLEFIELD] = "coge 2 PV de [Battlefield]";
SpanishAbilityDescriptions[AbilityNames.VP_FROM_COLONNADE] = "coge 2 PV de [Colonnade] if you bought una carta you have en juego";
SpanishAbilityDescriptions[AbilityNames.VP_TO_DEFILED_SHRINE] = "mueve 1 PV de la pila de Acción a [Defiled Shrine]";
SpanishAbilityDescriptions[AbilityNames.VP_FROM_DEFILED_SHRINE] = "coge todos los PV de [Defiled Shrine]";
SpanishAbilityDescriptions[AbilityNames.VP_FROM_LABYRINTH] = "coge 2 PV de [Labyrinth]";
SpanishAbilityDescriptions[AbilityNames.MOUNTAIN_PASS_BIDDING] = "apuesta Deuda para recibir +8 PV";
SpanishAbilityDescriptions[AbilityNames.TOMB] = "+1 PV";
SpanishAbilityDescriptions[AbilityNames.LIGHTHOUSE_COIN] = "+$1";
SpanishAbilityDescriptions[AbilityNames.REACTION] = "REACCIÓN";
SpanishAbilityDescriptions[AbilityNames.CALL_GUIDE] = "convoca [Guide] para descartar la mano, +5 Cartas";
SpanishAbilityDescriptions[AbilityNames.CALL_RATCATCHER] = "convoca [Ratcatcher] para eliminar una carta";
SpanishAbilityDescriptions[AbilityNames.CALL_TRANSMOGRIFY] = "convoca [Transmogrify] para mejorar una carta";
SpanishAbilityDescriptions[AbilityNames.CALL_TEACHER] = "convoca [Teacher] para mover una ficha de Aventuras pa una pila de Acción";
SpanishAbilityDescriptions[AbilityNames.CALL_DUPLICATE] = "convoca [Duplicate] para ganar una copia de Argument0";
SpanishAbilityDescriptions[AbilityNames.CALL_COIN] = "convoca [Coin Of The Realm] para +2 Acciones";
SpanishAbilityDescriptions[AbilityNames.CALL_ROYAL_CARRIAGE] = "convoca [Royal Carriage] para jugar Argument0 de nuevo";
SpanishAbilityDescriptions[AbilityNames.START_OF_TURN] = "comienza tu turno";
SpanishAbilityDescriptions[AbilityNames.START_BUY_PHASE] = "comienza tu fase de compra";
SpanishAbilityDescriptions[AbilityNames.END_OF_BUY_PHASE] = "finaliza tu fase de compra";
SpanishAbilityDescriptions[AbilityNames.BETWEEN_TURNS] = "está entre turnos";
SpanishAbilityDescriptions[AbilityNames.END_OF_TURN] = "finaliza tu turno";
SpanishAbilityDescriptions[AbilityNames.SUMMON_PLAY] = "juega Argument0";
SpanishAbilityDescriptions[AbilityNames.CARAVAN_GUARD_REACTION] = "juega [Caravan Guard]";
SpanishAbilityDescriptions[AbilityNames.TRAVELLING_FAIR_REACTION] = "puedes poner Argument0 sobre el mazo";
SpanishAbilityDescriptions[AbilityNames.MOAT_REACTION] = "revela [Moat] para bloquear el ataque";
SpanishAbilityDescriptions[AbilityNames.HORSE_TRADERS_REACTION] = "aparta [Horse Traders] para +1 Carta el próximo turno";
SpanishAbilityDescriptions[AbilityNames.BEGGAR_REACTION] = "descarta [Beggar] para ganar 2 [^pSilver], una sobre tu mazo";
SpanishAbilityDescriptions[AbilityNames.MARKET_SQUARE_REACTION] = "descarta [Market Square] para ganar [^nGold]";
SpanishAbilityDescriptions[AbilityNames.TRADER_REACTION] = "gana [^nSilver] en lugar de Argument0";
SpanishAbilityDescriptions[AbilityNames.DIPLOMAT_REACTION] = "+2 Cartas, descarta 3 cartas";
SpanishAbilityDescriptions[AbilityNames.TOPDECK_REACTION] = "pon Argument0 sobre el mazo";
SpanishAbilityDescriptions[AbilityNames.WATCHTOWER_TRASH_REACTION] = "elimina Argument0";
SpanishAbilityDescriptions[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "pon Argument0 sobre el mazo";
SpanishAbilityDescriptions[AbilityNames.PRINCE_PLAY] = "juega Argument0";
SpanishAbilityDescriptions[AbilityNames.TUNNEL_REVEAL] = "gana [^nGold]";
SpanishAbilityDescriptions[AbilityNames.POSSESSION_GAIN_INSTEAD] = "Argument0 gana Argument2 en lugar de Argument1";
SpanishAbilityDescriptions[AbilityNames.HOVEL_TRASH] = "elimina [Hovel]";
SpanishAbilityDescriptions[AbilityNames.DELUDED_RETURNED] = "devuelve [Deluded]";
SpanishAbilityDescriptions[AbilityNames.ENVIOUS_RETURNED] = "devuelve [Envious]";
SpanishAbilityDescriptions[AbilityNames.RECEIVE_BOON] = "recibe Argument0";
SpanishAbilityDescriptions[AbilityNames.COBBLER_ABILITY] = "gana una carta de hasta $4 a tu mano";
SpanishAbilityDescriptions[AbilityNames.CRYPT_ABILITY] = "coge una de Argument0";
SpanishAbilityDescriptions[AbilityNames.DEN_OF_SIN_ABILITY] = "+2 Cartas";
SpanishAbilityDescriptions[AbilityNames.FAITHFUL_HOUND] = "aparta [Faithful Hound]";
SpanishAbilityDescriptions[AbilityNames.FAITHFUL_HOUND_RETURN] = "devuelve [Faithful Hound] a tu mano";
SpanishAbilityDescriptions[AbilityNames.GHOST_TOWN_ABILITY] = "+1 Carta, +1 Acción";
SpanishAbilityDescriptions[AbilityNames.GUARDIAN_COIN] = "+$1";
SpanishAbilityDescriptions[AbilityNames.RAIDER_ABILITY] = "+$3";
SpanishAbilityDescriptions[AbilityNames.SECRET_CAVE] = "+$3";
SpanishAbilityDescriptions[AbilityNames.CEMETERY] = "elimina hasta 4 Cartas";
SpanishAbilityDescriptions[AbilityNames.HAUNTED_MIRROR] = "descarta una Acción para ganar [^nGhost]";
SpanishAbilityDescriptions[AbilityNames.GHOST] = "juega Argument0 dos veces";
SpanishAbilityDescriptions[AbilityNames.REMOVE_FLIPPED_STATUS] = "voltea una de las cartas eliminadas";
SpanishAbilityDescriptions[AbilityNames.LOST_IN_THE_WOODS] = "descarta una carta para recibir una Bendición";
SpanishAbilityDescriptions[AbilityNames.BLESSED_VILLAGE] = "coge una Bendición";
SpanishAbilityDescriptions[AbilityNames.FORTRESS] = "pon [Fortress] en tu mano";
SpanishAbilityDescriptions[AbilityNames.RECEIVE_HEX] = "recibe un Embrujo";
SpanishAbilityDescriptions[AbilityNames.CHANGELING_MAY_EXCHANGE] = "cambia Argument0 por [^nChangeling]";
SpanishAbilityDescriptions[AbilityNames.TRADE_ROUTE_MOVE_TOKEN] = "Mueve una Moneda al tablero de Ruta Comercial";
SpanishAbilityDescriptions[AbilityNames.DUCAT] = "elimina [^nCopper]";
SpanishAbilityDescriptions[AbilityNames.CARGO_SHIP_SET_ASIDE] = "aparta Argument0";
SpanishAbilityDescriptions[AbilityNames.CARGO_SHIP_RETURN] = "pon Argument0 en tu mano";
SpanishAbilityDescriptions[AbilityNames.IMPROVE] = "perfecciona una Acción en juego";
SpanishAbilityDescriptions[AbilityNames.PRIEST] = "+$2";
SpanishAbilityDescriptions[AbilityNames.RESEARCH] = "pon Argument0 en tu mano";
SpanishAbilityDescriptions[AbilityNames.SILK_MERCHANT] = "+1 Cofre, +1 Aldeano";
SpanishAbilityDescriptions[AbilityNames.LACKEYS] = "+2 Aldeanos";
SpanishAbilityDescriptions[AbilityNames.SPICES] = "+2 Cofres";
SpanishAbilityDescriptions[AbilityNames.KEY] = "+$1";
SpanishAbilityDescriptions[AbilityNames.TREASURE_CHEST] = "gana [^nGold]";
SpanishAbilityDescriptions[AbilityNames.FLAG_BEARER] = "coge [^nFlag]";
SpanishAbilityDescriptions[AbilityNames.CATHEDRAL] = "elimina una carta";
SpanishAbilityDescriptions[AbilityNames.CITY_GATE] = "+1 Carta, luego pon sobre el mazo";
SpanishAbilityDescriptions[AbilityNames.PAGEANT] = "paga $1 para +1 Cofre";
SpanishAbilityDescriptions[AbilityNames.SEWERS_MAY_TRASH] = "elimina otra Carta";
SpanishAbilityDescriptions[AbilityNames.EXPLORATION] = "+1 Cofre, +1 Aldeano";
SpanishAbilityDescriptions[AbilityNames.FAIR] = "+1 compra";
SpanishAbilityDescriptions[AbilityNames.SILOS] = "descarta Cobres por cartas";
SpanishAbilityDescriptions[AbilityNames.ACADEMY] = "+1 Aldeano";
SpanishAbilityDescriptions[AbilityNames.GUILDHALL] = "+1 Cofre";
SpanishAbilityDescriptions[AbilityNames.PIAZZA] = "revela y juega si es Acción";
SpanishAbilityDescriptions[AbilityNames.BARRACKS] = "+1 Acción";
SpanishAbilityDescriptions[AbilityNames.CROP_ROTATION] = "descarta carta de Victoria para +2 Cartas";
SpanishAbilityDescriptions[AbilityNames.INNOVATION] = "aparta y juega Argument0";
SpanishAbilityDescriptions[AbilityNames.CITADEL] = "juega Argument0 de nuevo";
SpanishAbilityDescriptions[AbilityNames.SINISTER_PLOT] = "roba o añade una ficha";
SpanishAbilityDescriptions[AbilityNames.ENCAMPMENT_RETURNED] = "devuelve [Encampment] al Suministro";
SpanishAbilityDescriptions[AbilityNames.EMBARGO_GAIN_CURSE] = "gana [^nCurse]";
SpanishAbilityDescriptions[AbilityNames.CAPTAIN] = "Juega una Acción desde el Suministro";
SpanishAbilityDescriptions[AbilityNames.CHURCH] = "pon Argument0 en tu mano, luego puedes eliminar";
SpanishAbilityDescriptions[AbilityNames.POSSESSION_SETS_ASIDE] = "aparta Argument0";
SpanishAbilityDescriptions[AbilityNames.MINT] = "Elimina todos tus Tesoros en juego";
SpanishAbilityDescriptions[AbilityNames.SLEIGH_TOPDECK_REACTION] = "Descarta [Sleigh] para poner Argument0 sobre el mazo";
SpanishAbilityDescriptions[AbilityNames.SLEIGH_PUT_IN_HAND_REACTION] = "Descarta [Sleigh] para poner Argument0 en tu mano";
SpanishAbilityDescriptions[AbilityNames.BLACK_CAT_REACTION] = "Juega [Black Cat]";
SpanishAbilityDescriptions[AbilityNames.DISCARD_FROM_EXILE] = "Descarta Argument0 del Exilio.";
SpanishAbilityDescriptions[AbilityNames.EXILE_BY_NAME] = "Exilia Argument0";
SpanishAbilityDescriptions[AbilityNames.SHEEPDOG_REACTION] = "Juega [Sheepdog]";
SpanishAbilityDescriptions[AbilityNames.CAVALRY] = "+2 Cartas, +1 Compra y vuelve a tu fase de Acción.";
SpanishAbilityDescriptions[AbilityNames.HOSTELRY] = "Descarta Tesoros por Caballos";
SpanishAbilityDescriptions[AbilityNames.VILLAGE_GREEN_VILLAGE] = "+1 Carta, +2 Acciones";
SpanishAbilityDescriptions[AbilityNames.VILLAGE_GREEN_PLAY] = "Juega [Village Green]";
SpanishAbilityDescriptions[AbilityNames.BARGE] = "+3 Cartas, +1 Compra";
SpanishAbilityDescriptions[AbilityNames.FALCONER_REACTION] = "Gana una Carta más barata";
SpanishAbilityDescriptions[AbilityNames.GATEKEEPER_EXILE] = "Exilia Argument0";
SpanishAbilityDescriptions[AbilityNames.GATEKEEPER_DURATION] = "+3 Monedas";
SpanishAbilityDescriptions[AbilityNames.LIVERY] = "Gana un Caballo";
SpanishAbilityDescriptions[AbilityNames.MASTERMIND] = "Puedes jugar una carta tres veces";
SpanishAbilityDescriptions[AbilityNames.DELAY_PLAY] = "Juega Argument0";
SpanishAbilityDescriptions[AbilityNames.INVEST] = "INVEST ABILITY (never shown to user)";
SpanishAbilityDescriptions[AbilityNames.REAP_PLAY] = "Juega [Gold] apartado";
SpanishAbilityDescriptions[AbilityNames.PLUS_CARD_TOKEN] = "+Carta de la ficha";
SpanishAbilityDescriptions[AbilityNames.PLUS_ACTION_TOKEN] = "+Acción de la ficha";
SpanishAbilityDescriptions[AbilityNames.PLUS_BUY_TOKEN] = "+Compra de la ficha";
SpanishAbilityDescriptions[AbilityNames.PLUS_COIN_TOKEN] = "+Moneda de la ficha";
SpanishAbilityDescriptions[AbilityNames.KILN] = "gana una copia de Argument0";
SpanishAbilityDescriptions[AbilityNames.TURTLE_PLAY] = "Juega Argument0 apartado";
SpanishAbilityDescriptions[AbilityNames.GIVE_PLUS_CARDS] = "+Carta";
SpanishAbilityDescriptions[AbilityNames.GIVE_PLUS_ACTIONS] = "+Acción";
SpanishAbilityDescriptions[AbilityNames.GIVE_PLUS_BUYS] = "+Compra";
SpanishAbilityDescriptions[AbilityNames.GIVE_PLUS_COINS] = "+Moneda";
SpanishAbilityDescriptions[AbilityNames.MONKEY_NEXT_TURN_ABILITY] = "+1 Card";
SpanishAbilityDescriptions[AbilityNames.BLOCKADE_ABILITY] = "put Argument0 in your hand";
SpanishAbilityDescriptions[AbilityNames.PIRATE_ABILITY] = "gain a Treasure to your hand";
SpanishAbilityDescriptions[AbilityNames.PIRATE_REACTION] = "Play [Pirate]";
SpanishAbilityDescriptions[AbilityNames.CORSAIR_NEXT_TURN_ABILITY] = "+1 Card";
SpanishAbilityDescriptions[AbilityNames.CORSAIR_TRASH] = "Trash Argument0";
SpanishAbilityDescriptions[AbilityNames.SAILOR_NEXT_TURN] = "+$2, and you may trash";
SpanishAbilityDescriptions[AbilityNames.SAILOR_REACTION] = "Play Argument0";
SpanishAbilityDescriptions[AbilityNames.ASTROLABE_ABILITY] = "+$1, +1 Buy";
SpanishAbilityDescriptions[AbilityNames.TIDE_POOLS_ABILITY] = "Discard 2 Cards";
SpanishAbilityDescriptions[AbilityNames.SEA_WITCH_ABILITY] = "+2 Cartas, luego descarta 2 cartas";
SpanishAbilityDescriptions[AbilityNames.SKIRMISHER] = "other players discard down to 3";
SpanishAbilityDescriptions[AbilityNames.ENCHANTRESS_REPLACE_ABILITY] = "Replacement by Hechizera";
SpanishAbilityDescriptions[AbilityNames.HIGHWAYMAN_NEXT_TURN_ABILITY] = "Discard Highwayman, +3 Cards";
SpanishAbilityDescriptions[AbilityNames.HIGHWAYMAN_REPLACE_ABILITY] = "Replacement by Highwayman";
SpanishAbilityDescriptions[AbilityNames.HIGHWAYMAN_NULLIFIED] = "Play Nullified";
SpanishAbilityDescriptions[AbilityNames.LEAGUE_OF_BANKERS] = "+$1 per 4 Favors";
SpanishAbilityDescriptions[AbilityNames.WOODWORKERS_GUILD] = "Spend a Favor and trash an Action to gain an Action.";
SpanishAbilityDescriptions[AbilityNames.CRAFTERS_GUILD] = "Spend 2 Favors to gain";
SpanishAbilityDescriptions[AbilityNames.CAVE_DWELLERS] = "Spend a Favor to discard then draw";
SpanishAbilityDescriptions[AbilityNames.DESERT_GUIDES] = "Spend a Favor to discard and draw";
SpanishAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_CARD] = "Spend a Favor for +1 Card";
SpanishAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_ACTION] = "Spend a Favor for +1 Action";
SpanishAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_BUY] = "Spend a Favor for +1 Buy";
SpanishAbilityDescriptions[AbilityNames.SYCOPHANT] = "+2 Favors";
SpanishAbilityDescriptions[AbilityNames.IMPORTER_ABILITY] = "Gain a card up to 5";
SpanishAbilityDescriptions[AbilityNames.CONTRACT_ABILITY] = "play Argument0";
SpanishAbilityDescriptions[AbilityNames.MARKET_TOWNS] = "Spend a Favor to play an Action";
SpanishAbilityDescriptions[AbilityNames.TRAPPERS_LODGE] = "Spend a Favor to topdeck Argument0";
SpanishAbilityDescriptions[AbilityNames.GUILDMASTER] = "+1 Favor";
SpanishAbilityDescriptions[AbilityNames.ISLAND_FOLK] = "Spend 5 Favors for an extra Turn";
SpanishAbilityDescriptions[AbilityNames.GANG_OF_PICKPOCKETS] = "Discard down to 4 unless you spend a Favor";
SpanishAbilityDescriptions[AbilityNames.COASTAL_HAVEN] = "Keep cards in hand for next turn";
SpanishAbilityDescriptions[AbilityNames.CITY_STATE] = "Spend 2 Favors to Play Argument0";
SpanishAbilityDescriptions[AbilityNames.CITY_STATE_PLAY] = "---";
SpanishAbilityDescriptions[AbilityNames.CIRCLE_OF_WITCHES] = "Spend 3 Favors to give opponents a Curse";
SpanishAbilityDescriptions[AbilityNames.ROYAL_GALLEY_SET_ASIDE] = "Set aside with Royal Galley";
SpanishAbilityDescriptions[AbilityNames.ROYAL_GALLEY_PLAY] = "Play Argument0";
SpanishAbilityDescriptions[AbilityNames.STRONGHOLD_ABILITY] = "+3 Cards";
SpanishAbilityDescriptions[AbilityNames.MOUNTAIN_FOLK] = "Spend 5 Favors to draw 3 Cards";
SpanishAbilityDescriptions[AbilityNames.PEACEFUL_CULT] = "Spend Favors to trash";
SpanishAbilityDescriptions[AbilityNames.FELLOWSHIP_OF_SCRIBES] = "Spend a Favor to draw a Card";
SpanishAbilityDescriptions[AbilityNames.LEAGUE_OF_SHOPKEEPERS] = "Shopkeeper";
SpanishAbilityDescriptions[AbilityNames.TERRITORY_GAIN] = "Gain a Gold per empty supply pile";
SpanishAbilityDescriptions[AbilityNames.WARLORD_ABILITY] = "+2 Cards";
SpanishAbilityDescriptions[AbilityNames.GARRISON_ADD_TOKEN] = "Add a token";
SpanishAbilityDescriptions[AbilityNames.GARRISON_REMOVE_ABILITY] = "+Argument0 Cards";
SpanishAbilityDescriptions[AbilityNames.CONJURER] = "Put this into your Hand";
SpanishAbilityDescriptions[AbilityNames.LICH] = "Discard and Gain from trash";
SpanishAbilityDescriptions[AbilityNames.GALLERIA] = "+1 Buy";
SpanishAbilityDescriptions[AbilityNames.ARCHITECTS_GUILD] = "Gain a cheaper non-Victory";
SpanishAbilityDescriptions[AbilityNames.FAMILY_OF_INVENTORS] = "Make a pile cheaper";
SpanishAbilityDescriptions[AbilityNames.FOREST_DWELLERS] = "Scout 3 cards";
SpanishAbilityDescriptions[AbilityNames.CLERK_ABILITY] = "Play [Clerk]";
SpanishAbilityDescriptions[AbilityNames.TREASURY] = "Topdeck Treasury";
SpanishAbilityDescriptions[AbilityNames.MERCHANT_GUILD] = "Get Coffers per gained card";

SpanishAbilityDescriptions[AbilityNames.CLERK_ABILITY] = "Play [Clerk]";
SpanishAbilityDescriptions[AbilityNames.GUARD_DOG_REACTION] = "Play [Guard Dog]";
SpanishAbilityDescriptions[AbilityNames.BERSERKER] = "Play [Berserker]";
SpanishAbilityDescriptions[AbilityNames.NOMADS] = "+2 Coins";
SpanishAbilityDescriptions[AbilityNames.TRAIL_REACTION] = "Play [Trail]";
SpanishAbilityDescriptions[AbilityNames.WEAVER_PLAY] = "Play [Weaver]";
SpanishAbilityDescriptions[AbilityNames.SOUK] = "Trash up to 2";
SpanishAbilityDescriptions[AbilityNames.CAULDRON_CURSING] = "Each other player gains a Curse";
SpanishAbilityDescriptions[AbilityNames.ALCHEMIST_TOPDECK] = "Topdeck Alchemist";

// Missing lines as of 09.01.2023 18:44:55
SpanishAbilityDescriptions[AbilityNames.HERBALIST_TOPDECK] = "Topdeck Argument0 with [Herbalist]";
SpanishAbilityDescriptions[AbilityNames.SCHEME_TOPDECK] = "Topdeck Argument0 with [Scheme]";
SpanishAbilityDescriptions[AbilityNames.FROG_TOPDECK] = "Topdeck Argument0";
SpanishAbilityDescriptions[AbilityNames.MERCHANT_CAMP_TOPDECK] = "Topdeck [Merchant Camp]";
SpanishAbilityDescriptions[AbilityNames.WALLED_VILLAGE_TOPDECK] = "Topdeck [Walled Village]";
SpanishAbilityDescriptions[AbilityNames.TENT_TOPDECK] = "Topdeck [Tent]";
SpanishAbilityDescriptions[AbilityNames.HORN_TOPDECK] = "Topdeck [Border Guard] with [Horn]";
SpanishAbilityDescriptions[AbilityNames.PAGE_EXCHANGE] = "Exchange [Page] for a [Treasure Hunter]";
SpanishAbilityDescriptions[AbilityNames.TREASURE_HUNTER_EXCHANGE] = "Exchange [Treasure Hunter] for a [Warrior]";
SpanishAbilityDescriptions[AbilityNames.WARRIOR_EXCHANGE] = "Exchange [Warrior] for a [Hero]";
SpanishAbilityDescriptions[AbilityNames.HERO_EXCHANGE] = "Exchange [Hero] for a [Champion]";
SpanishAbilityDescriptions[AbilityNames.PEASANT_EXCHANGE] = "Exchange [Peasant] for a [Soldier]";
SpanishAbilityDescriptions[AbilityNames.SOLDIER_EXCHANGE] = "Exchange [Soldier] for a [Fugitive]";
SpanishAbilityDescriptions[AbilityNames.FUGITIVE_EXCHANGE] = "Exchange [Fugitive] for a [Disciple]";
SpanishAbilityDescriptions[AbilityNames.DISCIPLE_EXCHANGE] = "Exchange [Disciple] for a [Teacher]";

SpanishAbilityDescriptions[AbilityNames.CAGE_TRASH] = "Trash [Cage]";
SpanishAbilityDescriptions[AbilityNames.CAGE_PUT_IN_HAND] = "Put Argument0 in Hand";
SpanishAbilityDescriptions[AbilityNames.GROTTO] = "Discard Argument0, then draw";
SpanishAbilityDescriptions[AbilityNames.JEWELLED_EGG_GAIN_LOOT] = "Gain a Loot.";
SpanishAbilityDescriptions[AbilityNames.SEARCH] = "Trash [Search], and gain a Loot.";
SpanishAbilityDescriptions[AbilityNames.SHAMAN] = "Gain a card from the trash costing up to 6.";
SpanishAbilityDescriptions[AbilityNames.SECLUDED_SHRINE] = "Trash up to 2 cards";
SpanishAbilityDescriptions[AbilityNames.SIREN_GAIN] = "Trash an Action, or [Siren]";
SpanishAbilityDescriptions[AbilityNames.SIREN_DURATION] = "Draw up to 8 cards";
SpanishAbilityDescriptions[AbilityNames.STOWAWAY_DRAW] = "Draw 2 cards";
SpanishAbilityDescriptions[AbilityNames.STOWAWAY_REACTION] = "Play [Stowaway]";
SpanishAbilityDescriptions[AbilityNames.TASKMASTER] = "+1 Action, +1 Coin";
SpanishAbilityDescriptions[AbilityNames.ABUNDANCE] = "+1 Buy, +3 Coins";
SpanishAbilityDescriptions[AbilityNames.CABIN_BOY] = "+2 Coins, or trash [Cabin Boy] to gain a Duration.";
SpanishAbilityDescriptions[AbilityNames.FLAGSHIP_ACTIVATE] = "";
SpanishAbilityDescriptions[AbilityNames.FLAGSHIP_REPLAY] = "Replay Argument0";
SpanishAbilityDescriptions[AbilityNames.GONDOLA_DURATION] = "+2 Coins";
SpanishAbilityDescriptions[AbilityNames.GONDOLA_PLAY] = "Play an Action card";
SpanishAbilityDescriptions[AbilityNames.HARBOR_VILLAGE] = "+1 Coin";
SpanishAbilityDescriptions[AbilityNames.LANDING_PARTY] = "Topdeck [Landing Party]";
SpanishAbilityDescriptions[AbilityNames.MAPMAKER] = "Play [Mapmaker]";
SpanishAbilityDescriptions[AbilityNames.ROPE] = "+1 Card, then you may trash a card";
SpanishAbilityDescriptions[AbilityNames.BURIED_TREASURE_DURATION] = "+1 Buy, +3 Coins";
SpanishAbilityDescriptions[AbilityNames.BURIED_TREASURE_PLAY] = "Play [Buried Treasure]";
SpanishAbilityDescriptions[AbilityNames.CREW_DURATION] = "Topdeck [Crew]";
SpanishAbilityDescriptions[AbilityNames.CUTTHROAT_GAIN] = "Gain a Loot";
SpanishAbilityDescriptions[AbilityNames.ENLARGE] = "Trash a card, to gain one costing up to 2 more.";
SpanishAbilityDescriptions[AbilityNames.FRIGATE_DURATION] = "";
SpanishAbilityDescriptions[AbilityNames.FRIGATE_DISCARD] = "Discard down to 4 cards in hand.";
SpanishAbilityDescriptions[AbilityNames.LONGSHIP_DURATION] = "+2 Cards";
SpanishAbilityDescriptions[AbilityNames.MINING_ROAD] = "Play Argument0";
SpanishAbilityDescriptions[AbilityNames.QUARTERMASTER] = "Resolve [Quartermaster]";
SpanishAbilityDescriptions[AbilityNames.TRICKSTER_REACTION] = "Set aside a Treasure";
SpanishAbilityDescriptions[AbilityNames.TRICKSTER_RETURN_TO_HAND] = "Put Argument0 in hand";
SpanishAbilityDescriptions[AbilityNames.WEALTHY_VILLAGE] = "Gain a Loot";
SpanishAbilityDescriptions[AbilityNames.DELIVER_SET_ASIDE] = "Set aside Argument0";
SpanishAbilityDescriptions[AbilityNames.DELIVER_RETURN] = "Put Argument0 in hand";
SpanishAbilityDescriptions[AbilityNames.RUSH] = "Play Argument0";
SpanishAbilityDescriptions[AbilityNames.MIRROR] = "Gain another Argument0";
SpanishAbilityDescriptions[AbilityNames.PREPARE] = "Play a set-aside card";
SpanishAbilityDescriptions[AbilityNames.AMPHORA] = "+1 Buy, +3 Coins";
SpanishAbilityDescriptions[AbilityNames.DOUBLOONS] = "Gain a Gold";
SpanishAbilityDescriptions[AbilityNames.ENDLESS_CHALICE] = "+1 Coin, +1 Buy";
SpanishAbilityDescriptions[AbilityNames.FIGUREHEAD] = "+2 Cards";
SpanishAbilityDescriptions[AbilityNames.JEWELS] = "Put [Jewels] on the bottom of your deck.";
SpanishAbilityDescriptions[AbilityNames.PUZZLE_BOX_RETURN] = "Put Argument0 in hand";
SpanishAbilityDescriptions[AbilityNames.SHIELD_REACTION] = "Reveal [Shield] to be unaffected by the attack.";
SpanishAbilityDescriptions[AbilityNames.SPELL_SCROLL] = "Play Argument0";
SpanishAbilityDescriptions[AbilityNames.CURSED_GAIN] = "Gain a Loot and a Curse";
SpanishAbilityDescriptions[AbilityNames.FAWNING_GAIN] = "Gain a [Fawning] card";
SpanishAbilityDescriptions[AbilityNames.SCHEDULER] = "[Friendly] (seeing this is a bug!)";
SpanishAbilityDescriptions[AbilityNames.FRIENDLY_ABILITY] = "Discard a [Friendly] card to gain another";
SpanishAbilityDescriptions[AbilityNames.HASTY_SET_ASIDE] = "Set Argument0 aside";
SpanishAbilityDescriptions[AbilityNames.HASTY_PLAY] = "Play Argument0";
SpanishAbilityDescriptions[AbilityNames.INHERITED] = "Replace a starting card.";
SpanishAbilityDescriptions[AbilityNames.INSPIRING] = "Play a card that you don't have in play.";
SpanishAbilityDescriptions[AbilityNames.NEARBY] = "+1 Buy";
SpanishAbilityDescriptions[AbilityNames.PATIENT_SCHEDULER] = "[Patient] (seeing this is a bug!)";
SpanishAbilityDescriptions[AbilityNames.PATIENT_SET_ASIDE] = "Set aside [Patient] cards.";
SpanishAbilityDescriptions[AbilityNames.PATIENT_PLAY] = "Play Argument0";
SpanishAbilityDescriptions[AbilityNames.PIOUS] = "Trash a card";
SpanishAbilityDescriptions[AbilityNames.RECKLESS_REPEAT] = "Follow the instructions of Argument0 again.";
SpanishAbilityDescriptions[AbilityNames.RECKLESS_RETURN] = "Return Argument0 to its pile.";
SpanishAbilityDescriptions[AbilityNames.RICH_GAIN] = "Gain [^nSilver]";
SpanishAbilityDescriptions[AbilityNames.SHY] = "Discard a [Shy] card for +2 Cards";
SpanishAbilityDescriptions[AbilityNames.TIRELESS_SET_ASIDE] = "Set aside Argument0";
SpanishAbilityDescriptions[AbilityNames.TIRELESS_RETURN] = "Topdeck Argument0";
SpanishAbilityDescriptions[AbilityNames.STAR_CHART] = "Topdeck";
SpanishAbilityDescriptions[AbilityNames.ORDER_OF_ASTROLOGERS] = "Topdeck";
SpanishAbilityDescriptions[AbilityNames.ORDER_OF_MASONS] = "Discard";
SpanishAbilityDescriptions[AbilityNames.FATED] = "Topdeck and/or Discard";
SpanishAbilityDescriptions[AbilityNames.AVOID] = "Discard";
SpanishAbilityDescriptions[AbilityNames.HERBALIST_ASK_FOR_DISCARD] = "Topdeck a Treasure";
SpanishAbilityDescriptions[AbilityNames.SCHEME_ASK_FOR_DISCARD] = "Topdeck an Action";
SpanishAbilityDescriptions[AbilityNames.TRICKSTER_ASK_FOR_DISCARD] = "Set aside a Treasure";
SpanishAbilityDescriptions[AbilityNames.MARCHLAND] = "+1 Buy, Discard cards for +$1 each";
SpanishAbilityDescriptions[AbilityNames.FARMHANDS_SET_ASIDE] = "Set aside an Action or Treasure";
SpanishAbilityDescriptions[AbilityNames.FARMHANDS_PLAY] = "play Argument0";
SpanishAbilityDescriptions[AbilityNames.JOUST_DISCARD] = "Discard a Province";
SpanishAbilityDescriptions[AbilityNames.INFIRMARY_OVERPAY] = "overpay to play [Infirmary]";
SpanishAbilityDescriptions[AbilityNames.RESOLVE_INFIRMARY_OVERPAY] = "play [Infirmary]";
SpanishAbilityDescriptions[AbilityNames.DRAW_FROM_FOOTPAD] = "Draw a card";
SpanishAbilityDescriptions[AbilityNames.FARRIER_OVERPAY] = "overpay for +Cards at end of turn.";
SpanishAbilityDescriptions[AbilityNames.RESOLVE_FARRIER_OVERPAY] = "resolve [Farrier] overpay";
SpanishAbilityDescriptions[AbilityNames.DRAW_FROM_SQUIRREL] = "Draw 2 extra Cards";
SpanishAbilityDescriptions[AbilityNames.DRAW_FROM_RIVER] = "Draw 1 extra Cards";
SpanishAbilityDescriptions[AbilityNames.DRAW_FROM_FARRIER] = "Draw Argument0 extra Cards";
SpanishAbilityDescriptions[AbilityNames.FERRYMAN] = "Gain Argument0";

SpanishAbilityDescriptions[AbilityNames.BIDING_TIME_SET_ASIDE_HAND] = "Set Cards in hand Aside";
SpanishAbilityDescriptions[AbilityNames.BIDING_TIME_RETURN_TO_HAND] = "Return cards to hand";
SpanishAbilityDescriptions[AbilityNames.ENLIGHTENMENT_CANTRIP] = "+1 Card, +1 Action";
SpanishAbilityDescriptions[AbilityNames.ENLIGHTENMENT_REPLACE_ABILITY] = "Replacement by Enlightenment";
SpanishAbilityDescriptions[AbilityNames.GOOD_HARVEST] = "+1 Buy +1 Coin";
SpanishAbilityDescriptions[AbilityNames.HARSH_WINTER] = "Take Debt or put it on the pile";
SpanishAbilityDescriptions[AbilityNames.KIND_EMPEROR] = "Gain an Action to your hand";
SpanishAbilityDescriptions[AbilityNames.PANIC_BUYS] = "+2 Buys";
SpanishAbilityDescriptions[AbilityNames.PANIC_RETURN] = "Return Argument0 to its pile";
SpanishAbilityDescriptions[AbilityNames.PROGRESS] = "Put Argument0 onto your deck";
SpanishAbilityDescriptions[AbilityNames.RAPID_EXPANSION_SET_ASIDE] = "Set Argument0 aside";
SpanishAbilityDescriptions[AbilityNames.RAPID_EXPANSION_PLAY_LATER] = "Play Argument0";
SpanishAbilityDescriptions[AbilityNames.SICKNESS] = "Curse onto your deck or discard 3 cards";
SpanishAbilityDescriptions[AbilityNames.RIVER_SHRINE] = "Gain a card up to 4 Coins";
SpanishAbilityDescriptions[AbilityNames.SAMURAI] = "+1 Coin";
SpanishAbilityDescriptions[AbilityNames.DAIMYO_REPLAY] = "Replay Argument0";
SpanishAbilityDescriptions[AbilityNames.FORESIGHT_RETURN_TO_HAND] = SpanishAbilityDescriptions[AbilityNames.SAVE_RETURN_TO_HAND];