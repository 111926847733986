"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SkippableRevealZone = function (_Zone) {
    _inherits(SkippableRevealZone, _Zone);

    function SkippableRevealZone() {
        _classCallCheck(this, SkippableRevealZone);

        return _possibleConstructorReturn(this, (SkippableRevealZone.__proto__ || Object.getPrototypeOf(SkippableRevealZone)).apply(this, arguments));
    }

    _createClass(SkippableRevealZone, [{
        key: "reposition",
        value: function reposition() {
            var _this2 = this;

            var comparator = function comparator(a, b) {
                if (a.topCard.cardName !== b.topCard.cardName) {
                    if (a.topCard.cardName === CardNames.BACK) return 1;
                    if (b.topCard.cardName === CardNames.BACK) return -1;
                    return stackCardNameSorter(a.topCard.cardName, b.topCard.cardName);
                }
                if (a === _this2.leadStack) return -1;
                if (b === _this2.leadStack) return 1;
                return 0;
            };
            this.cardStacks.sort(comparator);

            var vh = 0.01 * window.innerHeight;
            var vw = 0.01 * window.innerWidth;

            var h = void 0,
                w = void 0,
                margin = void 0,
                left = void 0,
                right = void 0,
                top = void 0,
                zIndex = void 0;
            var hasAnonymousStacks = this.cardStacks.some(function (s) {
                return s.topCard.cardName === CardNames.BACK;
            });

            if (this.owner.isHero) {
                h = 20 * vh;
                w = h / CardSizes.FULL.ratio;
                left = 2 * w - vw;
                right = 50 * vw;
                top = 75 * vh;
                zIndex = 10;
            } else {
                h = 17 * vh;
                w = h / CardSizes.FULL.ratio;
                left = (1.5 + LEFT_COLUMN_WIDTH) * vw + w;
                right = 50 * vw;
                top = this.layoutModel.isSpec ? 18 * vh : 4 * vh;
                zIndex = 10;
            }
            margin = 1 * vh;

            this.cardStacks.forEach(function (cardStack, i) {
                cardStack.linearlyPosition(_this2.cardStacks.length, i, h, margin, left, right, top, zIndex, false);
            });
        }
    }, {
        key: "initialize",
        value: function initialize(cardIds) {
            var _this3 = this;

            cardIds.forEach(function (id) {
                if (id === -1) {
                    var targetStack = new CardStack(_this3.state.getAnonCard(CardNames.BACK), _this3);
                    if (_this3.leadStack === null) _this3.leadStack = targetStack;
                    _this3.leadStack.addAnonymousCard();
                } else {
                    var targetName = _this3.state.cards[id].cardName;
                    var _targetStack = _this3.cardStacks.find(function (s) {
                        return s.topCard.cardName === targetName;
                    });
                    if (isUndefined(_targetStack)) {
                        _targetStack = new CardStack(_this3.state.getAnonCard(targetName), _this3);
                    }
                    _targetStack.addCard(_this3.state.cards[id]);
                }
            });
            this.reposition();
        }
    }, {
        key: "addCard",
        value: function addCard(card) {
            var targetStack = this.cardStacks.find(function (s) {
                return s.topCard.cardName === card.cardName;
            });
            if (isUndefined(targetStack)) {
                targetStack = new CardStack(this.state.getAnonCard(card.cardName), this);
                targetStack.addFilter("intangible");
            }
            this.reposition(true);
            return targetStack;
        }
    }, {
        key: "addAnonymousCard",
        value: function addAnonymousCard() {
            var targetStack = this.cardStacks.find(function (s) {
                return s.topCard.cardName === CardNames.BACK;
            });
            if (isUndefined(targetStack)) {
                targetStack = new CardStack(this.state.getAnonCard(CardNames.BACK), this);
                targetStack.addFilter("intangible");
            }
            this.reposition(true);
            return targetStack;
        }
    }, {
        key: "getScript",
        value: function getScript(fromZone, stack, targetStack, props) {
            if (props.isInstant) return [];

            if (fromZone instanceof DrawZone && fromZone.owner === this.owner) {
                var width = targetStack.view.bbox.width;
                var height = targetStack.view.bbox.height;
                if (props.type === ClientAnimationTypes.MINIMAL) {
                    return [new Keyframe(0, new RelativeTarget(new Rectangle(0, 0, 0, 0, 10, 0, -1, 0)), Curves.LINEAR), new Keyframe(props.speed * 0.6, new StackTarget(new Rectangle(0, 0, 0, 0, 10, 0, 0, 0)), Curves.SMOOTH), new Keyframe(props.delay, new StackTarget(new Rectangle(0, 0, 0, 0, 10, 0, 0, 0)), Curves.LINEAR)];
                }
                return [new Keyframe(props.speed * 0.6, new StackTarget(new Rectangle(-0.5 * width, 0, 0, 0, 10, -10, 0, 90)), Curves.SMOOTH, ["appears-anonymous"]), new Keyframe(props.speed * 1.2, new StackTarget(new Rectangle(0, 0, 0, 0, 10, 0, 0, 0)), Curves.SMOOTH, [], ["appears-anonymous"]), new Keyframe(props.delay, new StackTarget(new Rectangle(0, 0, 0, 0, 10, 0, 0, 0)), Curves.LINEAR)];
            } else {
                return [];
            }
        }
    }, {
        key: "zoneName",
        get: function get() {
            return "SkippableRevealZone";
        }
    }, {
        key: "isOwnershipDependent",
        get: function get() {
            return true;
        }
    }]);

    return SkippableRevealZone;
}(Zone);