"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DiscardZone = function (_Zone) {
    _inherits(DiscardZone, _Zone);

    function DiscardZone() {
        _classCallCheck(this, DiscardZone);

        return _possibleConstructorReturn(this, (DiscardZone.__proto__ || Object.getPrototypeOf(DiscardZone)).apply(this, arguments));
    }

    _createClass(DiscardZone, [{
        key: "initialize",
        value: function initialize(cardIds) {
            var _this2 = this;

            cardIds.forEach(function (id) {
                if (_this2.cardStacks.length === 0) {
                    var newStack = new CardStack(_this2.state.getAnonCard(CardNames.BACK), _this2);
                    newStack.getCounter = function () {
                        return -1;
                    };
                }
                var stack = _this2.cardStacks[0];
                if (id > -1) {
                    if (stack.cards.length > 0) {
                        stack.removeCard(stack.cards[0]);
                    }
                    stack.addCard(_this2.state.cards[id]);
                }
            });
            this.reposition();
        }
    }, {
        key: "reposition",
        value: function reposition() {
            var vh = 0.01 * window.innerHeight;
            var vw = 0.01 * window.innerWidth;

            var zIndex = 0;
            var x = void 0,
                y = void 0,
                w = void 0,
                h = void 0;

            if (this.owner.isHero) {
                h = 18 * vh;
                w = h / CardSizes.FULL.ratio;
                x = 0.5 * vw;
                y = 86 * vh;
            } else {
                h = 17 * vh;
                w = h / CardSizes.FULL.ratio;
                x = (1 + LEFT_COLUMN_WIDTH) * vw;
                y = this.layoutModel.isSpec ? 4 * vh : -10 * vh;
            }

            if (this.cardStacks.length > 0) {
                if (this.owner.isActiveOpponent && !this.layoutModel.isSpec) this.cardStacks[0].addFilter("inverted-card");else this.cardStacks[0].removeFilter("inverted-card");

                if (this.owner.isActiveOpponent || this.owner.isHero) this.cardStacks[0].removeFilter("zone-hidden");else this.cardStacks[0].addFilter("zone-hidden");
                this.cardStacks[0].reposition(x, y, w, h, zIndex);
            }
        }
    }, {
        key: "addCard",
        value: function addCard(card) {
            return this.addAnonymousCard();
        }
    }, {
        key: "addAnonymousCard",
        value: function addAnonymousCard() {
            if (this.cardStacks.length === 0) {
                var newStack = new CardStack(this.state.getAnonCard(CardNames.BACK), this);
                newStack.getCounter = function () {
                    return -1;
                };
                newStack.addFilter("intangible");
                this.reposition();
            }
            return this.cardStacks[0];
        }
    }, {
        key: "mergeStacks",
        value: function mergeStacks(targetStack, transitionStack) {
            if (transitionStack.cards.length > 0) {
                targetStack.addCard(transitionStack.cards[0]);
                if (targetStack.cards.length > 0) {
                    targetStack.cards.slice(1).forEach(function (c) {
                        return targetStack.removeCard(c);
                    });
                }
            }
            transitionStack.destroy(targetStack);
            targetStack.removeFilter("intangible");
        }
    }, {
        key: "spliceCard",
        value: function spliceCard(card) {
            var targetStack = this.cardStacks[0];
            var newStack = new CardStack(this.state.getAnonCard(card.cardName), this.state.nowhere);
            newStack.addCard(card);
            newStack.copyPosition(targetStack);
            return newStack;
        }
    }, {
        key: "spliceAnonymousCard",
        value: function spliceAnonymousCard() {
            var targetStack = this.cardStacks[0];
            var newStack = new CardStack(this.state.getAnonCard(CardNames.BACK), this.state.nowhere);
            newStack.anonymousCards++;
            newStack.copyPosition(targetStack);
            return newStack;
        }
    }, {
        key: "getScript",
        value: function getScript(fromZone, stack, targetStack, props) {
            if (props.isInstant) return [];

            if (fromZone instanceof HandZone && fromZone.owner === this.owner) {
                switch (props.type) {
                    case ClientAnimationTypes.MINIMAL:
                        return _get(DiscardZone.prototype.__proto__ || Object.getPrototypeOf(DiscardZone.prototype), "getScript", this).call(this, fromZone, stack, targetStack, props);
                    default:
                        var width = stack.view.bbox.width;
                        var height = stack.view.bbox.height;
                        return [new Keyframe(props.speed * 0.7, new RelativeTarget(new Rectangle(0, 0.9 * height, 0, -0.9 * height, 10, 0, -1)), Curves.PARABOLIC_START)];
                }
            } else {
                var _width = stack.view.bbox.width;
                var _height = stack.view.bbox.height;
                if (fromZone instanceof SupplyZone || fromZone instanceof RevealZone) {
                    return [new Keyframe(props.speed * 0.8, new RelativeTarget(new Rectangle(-0.05 * _width, -0.4 * _height, 0.1 * _width, 0.1 * _height, 10, 0, 0)), Curves.SMOOTH, ["unclickable"]), new Keyframe(props.speed * 0.5, new RelativeTarget(new Rectangle(0, -0.2 * _height, 0, 0, 10, 0, -1)), Curves.SMOOTH, [], ["unclickable"])];
                } else {
                    return [new Keyframe(props.speed * 0.7, new RelativeTarget(new Rectangle(-0.3 * _width, 0, 0, 0, 10, 0, -1)), Curves.PARABOLIC_START)];
                }
            }
        }
    }, {
        key: "zoneName",
        get: function get() {
            return "DiscardZone";
        }
    }, {
        key: "isOwnershipDependent",
        get: function get() {
            return true;
        }
    }]);

    return DiscardZone;
}(Zone);