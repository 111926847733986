"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CardMove = function (_GameStateChange) {
    _inherits(CardMove, _GameStateChange);

    function CardMove(game, fromZoneIndex, toZoneIndex, cardIds, cardIdsAfterMoving, movementType, cardAnimationClass) {
        _classCallCheck(this, CardMove);

        var _this = _possibleConstructorReturn(this, (CardMove.__proto__ || Object.getPrototypeOf(CardMove)).call(this, game));

        _this.fromZoneIndex = fromZoneIndex;
        _this.toZoneIndex = toZoneIndex;
        _this.cardIds = cardIds;
        _this.cardIdsAfterMoving = cardIdsAfterMoving;
        _this.movementType = movementType;
        _this.cardAnimationClass = cardAnimationClass;
        return _this;
    }

    _createClass(CardMove, [{
        key: "execute",
        value: function execute(game, terminator) {
            var _this2 = this;

            var toZone = game.state.zones[this.toZoneIndex];
            var fromZone = game.state.zones[this.fromZoneIndex];
            var isInstant = getAnimationSettings().isInstant;
            var props = getAnimationSettings().map.get(this.cardAnimationClass);
            if (!document.hasFocus()) {
                props = AnimationOptions.INSTANT.map.get(this.cardAnimationClass);
                isInstant = true;
            }
            if (isUndefined(fromZone)) console.error("undefined fromZone for card move");

            var finishCount = 0;
            var callback = function callback() {
                finishCount++;
                if (finishCount === _this2.cardIds.length) {
                    terminator();
                }
            };

            var targetStacks = [];
            var transitionStacks = this.cardIds.map(function (id, index) {
                var stack = id === -1 ? fromZone.spliceAnonymousCard() : fromZone.spliceCard(game.state.cards[id]);
                var endid = _this2.cardIdsAfterMoving[index];
                if (endid !== id) {
                    if (endid === -1) {
                        stack.removeCard(game.state.cards[id]);
                        stack.anonymousCards++;
                    } else {
                        stack.anonymousCards--;
                        stack.addCard(game.state.cards[endid]);
                    }
                }
                var targetStack = endid === -1 ? toZone.addAnonymousCard() : toZone.addCard(game.state.cards[endid]);
                if (isInstant) {
                    toZone.mergeStacks(targetStack, stack);
                } else {
                    stack.animator = new Animator(stack, toZone.getScript(fromZone, stack, targetStack, props), targetStack, callback);
                }
                return stack;
            });
            if (isInstant) terminator();
        }
    }, {
        key: "createAnimation",
        value: function createAnimation(game) {
            var newAnimation = void 0;
            if (getAnimationSettings().isInstant) {
                newAnimation = new PileDependentAnimation(this, this.toZoneIndex);
            } else {
                var props = getAnimationSettings().map.get(this.cardAnimationClass);
                newAnimation = new AnimatedCardMove(this, this.cardIdsAfterMoving, props);
            }
            return newAnimation;
        }
    }], [{
        key: "parse",
        value: function parse(game, reader) {
            var fromZoneIndex = reader.readInt();
            var toZoneIndex = reader.readInt();
            var cardIds = reader.readInts();
            var cardIdsAfterMoving = reader.readInts();
            var movementType = getByOrdinal(MovementTypes, reader.readInt());
            var cardAnimationClass = getByOrdinal(AnimationClassIds, reader.readInt());
            return new CardMove(game, fromZoneIndex, toZoneIndex, cardIds, cardIdsAfterMoving, movementType, cardAnimationClass);
        }
    }]);

    return CardMove;
}(GameStateChange);