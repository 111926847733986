"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var WindowView = function (_RenderableObject) {
    _inherits(WindowView, _RenderableObject);

    function WindowView(zone) {
        _classCallCheck(this, WindowView);

        var _this = _possibleConstructorReturn(this, (WindowView.__proto__ || Object.getPrototypeOf(WindowView)).call(this));

        _this.zone = zone;
        if (!isUndefined(_this.gameArea)) {
            _this.render(_this.gameArea.gameTabArea);
        }
        return _this;
    }

    _createClass(WindowView, [{
        key: "unload",
        value: function unload() {
            _get(WindowView.prototype.__proto__ || Object.getPrototypeOf(WindowView.prototype), "unload", this).call(this);
            delete this.zone.windowView;
        }
    }, {
        key: "render",
        value: function render(node) {
            var _this2 = this;

            _get(WindowView.prototype.__proto__ || Object.getPrototypeOf(WindowView.prototype), "render", this).call(this, node);
            this.cursorLock = false;
            this.closing = false;
            this.redraw();
            var THRESHOLD = 32;
            var mouseMoveHandler = function mouseMoveHandler(offsetX, offsetY) {
                if (_this2.cursorLock) return;
                if (offsetY < _this2.closeButtonSize && offsetX > _this2.canvas.clientWidth - _this2.closeButtonSize) {
                    _this2.canvas.style.cursor = "pointer";
                    return;
                }

                var isWResize = offsetX < THRESHOLD;
                var isEResize = offsetX > _this2.canvas.clientWidth - THRESHOLD;
                var isNResize = offsetY < THRESHOLD;
                var isSResize = offsetY > _this2.canvas.clientHeight - THRESHOLD;
                if (isWResize) {
                    if (isNResize) {
                        _this2.canvas.style.cursor = "nwse-resize";
                    } else if (isSResize) {
                        _this2.canvas.style.cursor = "nesw-resize";
                    } else {
                        _this2.canvas.style.cursor = "ew-resize";
                    }
                } else if (isEResize) {
                    if (isNResize) {
                        _this2.canvas.style.cursor = "nesw-resize";
                    } else if (isSResize) {
                        _this2.canvas.style.cursor = "nwse-resize";
                    } else {
                        _this2.canvas.style.cursor = "ew-resize";
                    }
                } else {
                    if (isNResize || isSResize) _this2.canvas.style.cursor = "ns-resize";else _this2.canvas.style.cursor = "grab";
                }
            };
            var mouseDownHandler = function mouseDownHandler(offsetX, offsetY) {
                _this2.zone.customBbox = true;
                if (offsetY < _this2.closeButtonSize && offsetX > _this2.canvas.clientWidth - _this2.closeButtonSize) {
                    _this2.canvas.style.cursor = "pointer";
                    _this2.closing = true;
                } else {
                    var isWResize = offsetX < THRESHOLD;
                    var isEResize = offsetX > _this2.canvas.clientWidth - THRESHOLD;
                    var isNResize = offsetY < THRESHOLD;
                    var isSResize = offsetY > _this2.canvas.clientHeight - THRESHOLD;
                    if (isWResize) {
                        if (isNResize) {
                            _this2.canvas.style.cursor = "nwse-resize";
                        } else if (isSResize) {
                            _this2.canvas.style.cursor = "nesw-resize";
                        } else {
                            _this2.canvas.style.cursor = "ew-resize";
                        }
                    } else if (isEResize) {
                        if (isNResize) {
                            _this2.canvas.style.cursor = "nesw-resize";
                        } else if (isSResize) {
                            _this2.canvas.style.cursor = "nwse-resize";
                        } else {
                            _this2.canvas.style.cursor = "ew-resize";
                        }
                    } else {
                        if (isNResize || isSResize) _this2.canvas.style.cursor = "ns-resize";else _this2.canvas.style.cursor = "grabbing";
                    }
                    _this2.cursorLock = true;
                    var originalX = _this2.zone.bbox.x;
                    var originalY = _this2.zone.bbox.y;
                    var originalWidth = _this2.zone.bbox.width;
                    var originalHeight = _this2.zone.bbox.height;

                    var moveListener = function moveListener(clientX, clientY) {
                        if (isNResize || !isSResize && !isEResize && !isWResize) {
                            _this2.zone.bbox.y = clientY - offsetY;
                            if (isNResize) _this2.zone.bbox.height = originalY + originalHeight - _this2.zone.bbox.y;
                        }
                        if (isSResize) {
                            _this2.zone.bbox.height = clientY + originalHeight - offsetY - _this2.zone.bbox.y;
                        }
                        if (isWResize || !isEResize && !isSResize && !isNResize) {
                            _this2.zone.bbox.x = clientX - offsetX;
                            if (isWResize) _this2.zone.bbox.width = originalX + originalWidth - _this2.zone.bbox.x;
                        }
                        if (isEResize) {
                            _this2.zone.bbox.width = clientX + originalWidth - offsetX - _this2.zone.bbox.x;
                        }
                        if (isNResize || isSResize || isEResize || isWResize) {
                            _this2.zone.bbox.x = Math.min(originalX + originalWidth - 0.10 * window.innerWidth, _this2.zone.bbox.x);
                            _this2.zone.bbox.y = Math.min(originalY + originalHeight - 0.15 * window.innerHeight, _this2.zone.bbox.y);
                            _this2.zone.bbox.width = Math.max(_this2.zone.bbox.width, 0.10 * window.innerWidth);
                            _this2.zone.bbox.height = Math.max(_this2.zone.bbox.height, 0.15 * window.innerHeight);
                        }
                        _this2.repositionCanvas();
                        _this2.zone.reposition();
                    };
                    var _mouseMoveHandler = function _mouseMoveHandler(e) {
                        return moveListener(e.clientX, e.clientY);
                    };
                    var touchMoveHandler = function touchMoveHandler(e) {
                        return moveListener(e.touches[0].clientX, e.touches[0].clientY);
                    };

                    var dropListener = function dropListener() {
                        document.removeEventListener("mousemove", _mouseMoveHandler);
                        document.removeEventListener("touchmove", touchMoveHandler);
                        document.removeEventListener("mouseup", dropListener);
                        document.removeEventListener("touchend", dropListener);
                        document.removeEventListener("touchcancel", dropListener);
                        _this2.cursorLock = false;
                        _this2.canvas.style.cursor = "grab";
                    };
                    document.addEventListener("mousemove", _mouseMoveHandler);
                    document.addEventListener("touchmove", touchMoveHandler);
                    document.addEventListener("mouseup", dropListener);
                    document.addEventListener("touchend", dropListener);
                    document.addEventListener("touchcancel", dropListener);
                }
            };

            this.canvas.onmousemove = function (e) {
                return mouseMoveHandler(e.offsetX, e.offsetY);
            };
            this.canvas.onmousedown = function (e) {
                return mouseDownHandler(e.offsetX, e.offsetY);
            };
            this.canvas.ontouchmove = function (e) {
                return mouseMoveHandler(e.touches[0].clientX - e.target.getBoundingClientRect().x, e.touches[0].clientY - e.target.getBoundingClientRect().y);
            };
            this.canvas.ontouchstart = function (e) {
                return mouseDownHandler(e.touches[0].clientX - e.target.getBoundingClientRect().x, e.touches[0].clientY - e.target.getBoundingClientRect().y);
            };

            this.canvas.onmouseup = function (e) {
                if (e.offsetY < _this2.closeButtonSize && e.offsetX > _this2.canvas.clientWidth - _this2.closeButtonSize && _this2.closing) {
                    _this2.zone.hideAll();
                }
                _this2.closing = false;
            };
        }
    }, {
        key: "repositionCanvas",
        value: function repositionCanvas() {
            this.canvas.style.position = "fixed";
            this.canvas.style.width = this.zone.bbox.width + "px";
            this.canvas.style.height = this.zone.bbox.height + "px";
            this.canvas.style.transform = "translateX(" + this.zone.bbox.x + "px) translateY(" + this.zone.bbox.y + "px)";
            this.canvas.style.zIndex = this.zone.bbox.zIndex;
            this.canvas.style.display = this.zone.showWindow ? "initial" : "none";
        }
    }, {
        key: "redrawBackground",
        value: function redrawBackground() {
            var _this3 = this;

            this.canvas.width = this.zone.bbox.width;
            this.canvas.height = this.zone.bbox.height;
            var _ref = [this.canvas.width, this.canvas.height, this.context],
                w = _ref[0],
                h = _ref[1],
                ctx = _ref[2];

            var r = 0.2 * window.innerHeight / 100;
            ctx.strokeStyle = "#EEE";
            ctx.lineWidth = r;
            ctx.fillStyle = "black";
            ctx.globalAlpha = 0.8;

            ctx.beginPath();
            ctx.rect(r, r, w - r - 1, h - r - 1);
            ctx.fill();
            ctx.globalAlpha = 1;
            ctx.stroke();

            var crossBbox = new Rectangle(w - this.closeButtonSize, 0, this.closeButtonSize, this.closeButtonSize);
            var artURL = 'images/elements/close-button.png';
            Preloader.load(artURL, function (source) {
                ctx.drawImage(source, w - _this3.closeButtonSize, 2, _this3.closeButtonSize, _this3.closeButtonSize);
                _this3.redrawText();
            });
        }
    }, {
        key: "redrawText",
        value: function redrawText() {
            var _ref2 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref2[0],
                h = _ref2[1],
                ctx = _ref2[2];

            var vh = window.innerHeight / 100;
            var title = (this.zone.zoneName in LANGUAGE.getZoneName ? LANGUAGE.getZoneName : LANGUAGE.getPhrase)[this.zone.zoneName];
            var owner = this.zone.owner;
            var titleFont = LANGUAGE.getEnvironment.hasOwnProperty("titleFont") ? LANGUAGE.getEnvironment.titleFont : "TrajanPro-Bold";
            // self.showSubtitle = !self.zone.zoneName.isRevealZone && !self.zone.owner.isNobody;
            if (this.zone instanceof RevealZone) {
                var leftBbox = new Rectangle(1 * vh, h - 3.5 * vh, 10 * vh, 2.5 * vh);
                var rightBbox = new Rectangle(w - 11 * vh, h - 3.5 * vh, 10 * vh, 2.5 * vh);
                ctx.fillStyle = "white";
                drawFittedText(ctx, this.zone.leftText, leftBbox, 1, titleFont, "");
                drawFittedText(ctx, this.zone.rightText, rightBbox, 1, titleFont, "");
            } else {
                var bbox = new Rectangle(1 * vh, 2 * vh, w - 2 * vh, 3.5 * vh);
                ctx.strokeStyle = "black";
                ctx.fillStyle = "white";
                drawFittedText(ctx, title, bbox, 1, titleFont, "", true);
            }
        }
    }, {
        key: "redraw",
        value: function redraw() {
            this.repositionCanvas();
            this.redrawBackground();
        }
    }, {
        key: "gameArea",
        get: function get() {
            return this.zone.state.game.gameArea;
        }
    }, {
        key: "closeButtonSize",
        get: function get() {
            var vh = window.innerHeight / 100;
            return 3 * vh;
        }
    }]);

    return WindowView;
}(RenderableObject);