"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ZoneDestroyed = function (_GameStateChange) {
    _inherits(ZoneDestroyed, _GameStateChange);

    function ZoneDestroyed(index) {
        _classCallCheck(this, ZoneDestroyed);

        var _this = _possibleConstructorReturn(this, (ZoneDestroyed.__proto__ || Object.getPrototypeOf(ZoneDestroyed)).call(this));

        _this.zoneIndex = index;
        return _this;
    }

    _createClass(ZoneDestroyed, [{
        key: "execute",
        value: function execute(game, terminator) {
            var destroyedZone = game.state.zones[this.zoneIndex];
            if (destroyedZone.cardCount > 0) {
                throw new Error("Server instructs us to destroy non-empty zone %o", destroyedZone);
            }
            destroyedZone.tabViews.forEach(function (t) {
                return t.removeZone(destroyedZone);
            });
            destroyedZone.unload();
            delete game.state.zones[this.zoneIndex];
            terminator();
        }
    }, {
        key: "createAnimation",
        value: function createAnimation(game) {
            return new PileConstructingAnimation(this, this.zoneIndex);
        }
    }], [{
        key: "parse",
        value: function parse(game, reader) {
            return new ZoneDestroyed(reader.readInt());
        }
    }]);

    return ZoneDestroyed;
}(GameStateChange);