"use strict";

var GermanTypes = {};

GermanTypes[Types.ACTION] = "Aktion";
GermanTypes[Types.TREASURE] = "Geld";
GermanTypes[Types.ATTACK] = "Angriff";
GermanTypes[Types.CURSE] = "Fluch";
GermanTypes[Types.DURATION] = "Dauer";
GermanTypes[Types.EVENT] = "Ereignis";
GermanTypes[Types.GATHERING] = "Sammlung";
GermanTypes[Types.KNIGHT] = "Ritter";
GermanTypes[Types.LANDMARK] = "Landmarke";
GermanTypes[Types.LOOTER] = "Plündern";
GermanTypes[Types.RUINS] = "Ruine";
GermanTypes[Types.TRAVELLER] = "Reisender";
GermanTypes[Types.PRIZE] = "Preis";
GermanTypes[Types.RESERVE] = "Reserve";
GermanTypes[Types.VICTORY] = "Punkte";
GermanTypes[Types.REACTION] = "Reaktion";
GermanTypes[Types.SHELTER] = "Unterschlupf";
GermanTypes[Types.CASTLE] = "Schloss";
GermanTypes[Types.FATE] = "Segen";
GermanTypes[Types.NIGHT] = "Nacht";
GermanTypes[Types.DOOM] = "Unheil";
GermanTypes[Types.HEIRLOOM] = "Erbstück";
GermanTypes[Types.SPIRIT] = "Erscheinung";
GermanTypes[Types.ZOMBIE] = "Zombie";
GermanTypes[Types.BOON] = "Gabe";
GermanTypes[Types.HEX] = "Plage";
GermanTypes[Types.STATE] = "Zustand";
GermanTypes[Types.PROJECT] = "Projekt";
GermanTypes[Types.COMMAND] = "Befehl";
GermanTypes[Types.WAY] = "Weg";
GermanTypes[Types.LIAISON] = "Kontakt";
GermanTypes[Types.ALLY] = "Verbündeter";
GermanTypes[Types.AUGUR] = "Augurin";
GermanTypes[Types.CLASH] = "Konflikt";
GermanTypes[Types.FORT] = "Bastion";
GermanTypes[Types.ODYSSEY] = "Irrfahrt";
GermanTypes[Types.TOWNSFOLK] = "Bürger";
GermanTypes[Types.WIZARD] = "Zauberer";
GermanTypes[Types.LOOT] = "Kostbarkeit";
GermanTypes[Types.TRAIT] = "Merkmal";
GermanTypes[Types.REWARD] = "Belohnung";
GermanTypes[Types.SHADOW] = "Schatten";
GermanTypes[Types.OMEN] = "Omen";
GermanTypes[Types.PROPHECY] = "Prophezeiung";

var GermanZoneNames = {};

GermanZoneNames["HandZone"] = "Hand";
GermanZoneNames["DrawZone"] = "Nachziehstapel";
GermanZoneNames["DiscardZone"] = "Ablagestapel";
GermanZoneNames["TrashZone"] = "Müllstapel";
GermanZoneNames["SupplyZone"] = "Vorratsstapel";
GermanZoneNames["InPlayZone"] = "Spiel";
GermanZoneNames["SetAsideZone"] = "Beiseite gelegt";
GermanZoneNames["TavernZone"] = "Wirtshaus-Tableau";
GermanZoneNames["NVZone"] = "Eingeborenendorf-Tableau";
GermanZoneNames["IslandZone"] = "Insel-Tableau";
GermanZoneNames["ExileZone"] = "Exil";
GermanZoneNames["SetAsideZone"] = "Beiseite gelegt";
GermanZoneNames["TheBox"] = "die Schachtel";
GermanZoneNames["RevealZone"] = "Aufgedeckt";
GermanZoneNames["SkippableRevealZone"] = "Aufgedeckt";

var GermanMovementTypes = {};

GermanMovementTypes[MovementTypes.PLAY] = new TranslatedMovementType("spielst", "spielt");
GermanMovementTypes[MovementTypes.DRAW] = new TranslatedMovementType("ziehst", "zieht");
GermanMovementTypes[MovementTypes.DISCARD] = new TranslatedMovementType("legst ab", "legt ab");
GermanMovementTypes[MovementTypes.GAIN] = new TranslatedMovementType("nimmst", "nimmt");
GermanMovementTypes[MovementTypes.TRASH] = new TranslatedMovementType("entsorgst", "entsorgt");
GermanMovementTypes[MovementTypes.TOPDECK] = new TranslatedMovementType("legst auf den Nachziehstapel", "legt auf den Nachziehstapel");
GermanMovementTypes[MovementTypes.REVEAL] = new TranslatedMovementType("deckst auf", "deckt auf");
GermanMovementTypes[MovementTypes.REVEAL_SEARCHING] = new TranslatedMovementType("deckst auf", "deckt auf");
GermanMovementTypes[MovementTypes.LOOK_AT] = new TranslatedMovementType("schaust an", "schaut an");
GermanMovementTypes[MovementTypes.PUT_IN_HAND] = new TranslatedMovementType("nimmst es auf die Hand", "nimmt es auf die Hand");
GermanMovementTypes[MovementTypes.SET_ASIDE] = new TranslatedMovementType("legst zur Seite", "legt es zur Seite");
GermanMovementTypes[MovementTypes.PUT_ON_MAT] = new TranslatedMovementType("legst auf auf Tableau", "legt auf Tableau");
GermanMovementTypes[MovementTypes.DECK_TO_DISCARD] = new TranslatedMovementType("legst vom Stapel ab", "legt vom Stapel ab");
GermanMovementTypes[MovementTypes.BACK_ON_DECK] = new TranslatedMovementType("BACK_ON_DECK", "BACK_ON_DECK");
GermanMovementTypes[MovementTypes.SHUFFLE_INTO_DECK] = new TranslatedMovementType("mischst in den Stapel", "mischt in den Stapel");
GermanMovementTypes[MovementTypes.INSERT_IN_DECK] = new TranslatedMovementType("INSERT_IN_DECK", "INSERT_IN_DECK");
GermanMovementTypes[MovementTypes.EXCHANGE_RETURN] = new TranslatedMovementType("EXCHANGE_RETURN", "EXCHANGE_RETURN");
GermanMovementTypes[MovementTypes.EXCHANGE_RECEIVE] = new TranslatedMovementType("EXCHANGE_RECEIVE", "EXCHANGE_RECEIVE");
GermanMovementTypes[MovementTypes.PASS] = new TranslatedMovementType("PASS", "PASS");
GermanMovementTypes[MovementTypes.RETURN_TO] = new TranslatedMovementType("legst zurück", "legt zurück");
GermanMovementTypes[MovementTypes.PUT_ON_BOTTOM_OF] = new TranslatedMovementType("PUT_ON_BOTTOM_OF", "PUT_ON_BOTTOM_OF");
GermanMovementTypes[MovementTypes.STARTS_WITH] = new TranslatedMovementType("STARTS_WITH", "STARTS_WITH");
GermanMovementTypes[MovementTypes.TAKE] = new TranslatedMovementType("TAKE", "TAKE");
GermanMovementTypes[MovementTypes.GET_STATE] = new TranslatedMovementType("GET_STATE", "GET_STATE");
GermanMovementTypes[MovementTypes.RETURN_STATE] = new TranslatedMovementType("RETURN_STATE", "RETURN_STATE");
GermanMovementTypes[MovementTypes.EXILE] = new TranslatedMovementType("EXILE", "EXILE");

var GermanPileTokenTexts = {};

GermanPileTokenTexts[TokenNames.PLUS_ACTION] = "+A";
GermanPileTokenTexts[TokenNames.PLUS_BUY] = "+Kauf";
GermanPileTokenTexts[TokenNames.PLUS_CARD] = "+Kar";
GermanPileTokenTexts[TokenNames.PLUS_COIN] = "+1";
GermanPileTokenTexts[TokenNames.MINUS_COST] = "-2";
GermanPileTokenTexts[TokenNames.TRASH] = "X";

var GermanPileMarkers = {};

GermanPileMarkers[PileMarkerTypes.BANE] = "Bann";
GermanPileMarkers[PileMarkerTypes.OBELISK] = "Obelisk";
GermanPileMarkers[PileMarkerTypes.SPLIT] = "Geteilt";
GermanPileMarkers[PileMarkerTypes.MIXED] = "Gemischt";

var GermanTokenNames = {};

GermanTokenNames[TokenNames.PLUS_ACTION] = "+Aktion-Marker";
GermanTokenNames[TokenNames.PLUS_BUY] = "+Kauf-Marker";
GermanTokenNames[TokenNames.PLUS_COIN] = "+Geld-Marker";
GermanTokenNames[TokenNames.PLUS_CARD] = "+Karte-Marker";
GermanTokenNames[TokenNames.MINUS_COST] = "-Kosten-Marker";
GermanTokenNames[TokenNames.TRASH] = "Entsorgungs-Marker";
GermanTokenNames[TokenNames.JOURNEY] = "Reise-Marker";
GermanTokenNames[TokenNames.MINUS_CARD] = "-Karte-Marker";
GermanTokenNames[TokenNames.MINUS_COIN] = "-Geld-Marker";

var GermanKingdomViewerNames = {};

GermanKingdomViewerNames[KingdomViewerGroups.SUPPLY_CARDS] = "Karten im Vorrat";
GermanKingdomViewerNames[KingdomViewerGroups.KINGDOM_LANDSCAPES] = "Ereignisse, Landmarken, Projekte, Wege, Verbündete, Merkmale und Prophezeiungen";
GermanKingdomViewerNames[KingdomViewerGroups.NON_KINGDOM] = "Nicht-Königreich-Karten";
GermanKingdomViewerNames[KingdomViewerGroups.RUINS] = "Ruinen";
GermanKingdomViewerNames[KingdomViewerGroups.DRUID_BOONS] = "Gaben der Druidin";
GermanKingdomViewerNames[KingdomViewerGroups.KNIGHTS] = "Ritter";
GermanKingdomViewerNames[KingdomViewerGroups.CASTLES] = "Schlösser";
GermanKingdomViewerNames[KingdomViewerGroups.PRIZES] = "Preise";
GermanKingdomViewerNames[KingdomViewerGroups.REWARDS] = "Belohnungen";
GermanKingdomViewerNames[KingdomViewerGroups.BOONS_HEXES] = "Gaben und Plagen";
GermanKingdomViewerNames[KingdomViewerGroups.STATES_ARTIFACTS] = "Zustände und Artefakte";
GermanKingdomViewerNames[KingdomViewerGroups.BLACK_MARKET] = "Schwarzmarkt";
GermanKingdomViewerNames[KingdomViewerGroups.LOOT] = "Kostbarkeiten";