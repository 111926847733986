"use strict";

var EsperantoLogEntries = {};

EsperantoLogEntries[LogEntryNames.GAIN] = "Argument0 gajnas Argument1.";
EsperantoLogEntries[LogEntryNames.GAIN_WITH] = "Argument0 gajnas Argument1. (Argument2)";
EsperantoLogEntries[LogEntryNames.BUY_AND_GAIN] = "Argument0 aĉetas kaj gajnas Argument1.";
EsperantoLogEntries[LogEntryNames.TRASH] = "Argument0 rubigas Argument1.";
EsperantoLogEntries[LogEntryNames.DISCARD] = "Argument0 forĵetas Argument1.";
EsperantoLogEntries[LogEntryNames.DISCARD_WITH] = "Argument0 forĵetas Argument1. (Argument2)";
EsperantoLogEntries[LogEntryNames.PLAY] = "Argument0 ludas Argument1.";
EsperantoLogEntries[LogEntryNames.PLAY_AGAIN] = "Argument0 reludas Argument1.";
EsperantoLogEntries[LogEntryNames.PLAY_THIRD] = "Argument0 ludas Argument1 trian fojon.";
EsperantoLogEntries[LogEntryNames.TOPDECK] = "Argument0 surmetas Argument1.";
EsperantoLogEntries[LogEntryNames.DRAW] = "Argument0 tiras Argument1.";
EsperantoLogEntries[LogEntryNames.DRAW_FROM] = "Argument0 tiras Argument1. (Argument2)";
EsperantoLogEntries[LogEntryNames.REVEAL] = "Argument0 malkaŝas Argument1.";
EsperantoLogEntries[LogEntryNames.REVEAL_SEARCHING] = "Argument0 malkaŝas Argument1.";
EsperantoLogEntries[LogEntryNames.REVEAL_FINDING] = "Argument0 reveals Argument1.";
EsperantoLogEntries[LogEntryNames.REVEAL_SEARCHING_AND_FINDING] = "Argument0 reveals Argument1 and finally Argument2.";
EsperantoLogEntries[LogEntryNames.REVEAL_HAND] = "Argument0 reveals their hand: Argument1.";
EsperantoLogEntries[LogEntryNames.REVEAL_DISCARD] = "Argument0 reveals their discard: Argument1.";
EsperantoLogEntries[LogEntryNames.LOOK_AT] = "Argument0 rigardas Argument1.";
EsperantoLogEntries[LogEntryNames.PUT_IN_HAND] = "Argument0 metas Argument1 en ties manon.";
EsperantoLogEntries[LogEntryNames.SET_ASIDE] = "Argument0 flankenmetas Argument1.";
EsperantoLogEntries[LogEntryNames.PUT_ON_MAT] = "Argument0 metas Argument1 sur ties Argument2.";
EsperantoLogEntries[LogEntryNames.CALL] = "Argument0 alvokas Argument1.";
EsperantoLogEntries[LogEntryNames.DECK_TO_DISCARD] = "Argument0 metas ties kartaron en vian forĵetan stakon.";
EsperantoLogEntries[LogEntryNames.BACK_ON_DECK] = "Argument0 remetas Argument1 sur ties kartaron.";
EsperantoLogEntries[LogEntryNames.SHUFFLE_INTO_DECK] = "Argument0 miksas Argument1 en ties kartaron.";
EsperantoLogEntries[LogEntryNames.INSERT_IN_DECK] = "Argument0 metas Argument1 en ties kartaron.";
EsperantoLogEntries[LogEntryNames.EXCHANGE_RETURN] = "Argument0 redonas Argument1.";
EsperantoLogEntries[LogEntryNames.EXCHANGE_RECEIVE] = "Argument0 ricevas Argument1.";
EsperantoLogEntries[LogEntryNames.PASS] = "Argument0 donas Argument1 al Argument2.";
EsperantoLogEntries[LogEntryNames.STARTS_WITH] = "Argument0 komence havas Argument1.";
EsperantoLogEntries[LogEntryNames.BUY] = "Argument0 aĉetas Argument1.";
EsperantoLogEntries[LogEntryNames.COUNTER_ADD] = "COUNTER_ADD";
EsperantoLogEntries[LogEntryNames.COUNTER_RESET] = "COUNTER_RESET";
EsperantoLogEntries[LogEntryNames.REACTS_WITH] = "Argument0 reagas uzante Argument1.";
EsperantoLogEntries[LogEntryNames.SHUFFLES] = "Argument0 miksas ties kartaron.";
EsperantoLogEntries[LogEntryNames.NEW_TURN] = "Argument0";
EsperantoLogEntries[LogEntryNames.WISH_CORRECT] = "Argument0 deziras por Argument1 kaj trovas ĝin.";
EsperantoLogEntries[LogEntryNames.WISH_WRONG] = "Argument0 deziras por Argument1 sed malkaŝas Argument2.";
EsperantoLogEntries[LogEntryNames.BLACK_MARKET_RESHUFFLE] = "Remiksante la kartaron de Nigra Merkato.";
EsperantoLogEntries[LogEntryNames.RETURN_TO] = "Argument0 redonas Argument1 al Argument2.";
EsperantoLogEntries[LogEntryNames.PUT_ON_BOTTOM_OF] = "Argument0 metas Argument1 sur la fundo de Argument2.";
EsperantoLogEntries[LogEntryNames.LOSE_TRACK_MOVED] = "Argument0 ne povas plu movi Argument1 (ĝi jam movis).";
EsperantoLogEntries[LogEntryNames.LOSE_TRACK_HIDDEN] = "Argument0 ne povas plu movi Argument1 (ĝi estis kovrita).";
EsperantoLogEntries[LogEntryNames.LOSE_TRACK_RESHUFFLED] = "Argument0 ne povas plu movi Argument1 (ĝi estis miksita).";
EsperantoLogEntries[LogEntryNames.LOSE_TRACK_ANONYMOUS] = "Argument0 ne povas plu movi.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_BANDIT_CAMP] = "Argument0.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_TOWER] = "Argument0.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH_ABSENT] = "Nul malsame nomataj Agkartoj.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH] = "Argument0.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_WALL] = "Argument0.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_WOLF_DEN] = "precize unu de Argument0.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_PALACE] = "Argument0.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_ORCHARD] = "Argument0.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_OBELISK] = "Argument0 kartoj el la Argument1-stako.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_MUSEUM] = "Argument0 malsame nomataj kartoj.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_KINGS_CASTLE] = "Argument0 Kasteloj.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_KEEP] = "Argument0.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_HUMBLE_CASTLE] = "Argument0 Kasteloj.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_FOUNTAIN] = "Argument0.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_FEODUM] = "Argument0.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_GARDENS] = "Argument0.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_VINEYARD] = "Argument0 Agkartoj.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_SILK_ROAD] = "Argument0 Venkkartoj.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_FAIRGROUNDS] = "Argument0 malsame nomataj kartoj.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_DUKE] = "Argument0.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_DUH] = "";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_VP_TOKENS] = "VP jetonoj.";
EsperantoLogEntries[LogEntryNames.PUT_IN_HAND_FROM] = "Argument0 metas Argument1 en manon (Argument2).";
EsperantoLogEntries[LogEntryNames.STARTS_TURN] = "Argument0 komencas ties vicon.";
EsperantoLogEntries[LogEntryNames.TAKE_VP_FROM] = "Argument0 prenas Argument1 VP el Argument2.";
EsperantoLogEntries[LogEntryNames.MOVE_VP_FROM_TO] = "Argument0 movas Argument1 VP el Argument2 al Argument3.";
EsperantoLogEntries[LogEntryNames.OBELISK_CHOOSES_PILE] = "[Obelisk] elektas Argument0.";
EsperantoLogEntries[LogEntryNames.PLAYER_MOVES_TOKEN_TO] = "Argument0 movas Argument1 al Argument2.";
EsperantoLogEntries[LogEntryNames.PLAYER_GETS_VP] = "Argument0 ricevas Argument1 VP.";
EsperantoLogEntries[LogEntryNames.PLAYER_GETS_VP_FROM] = "Argument0 ricevas Argument1 VP el Argument2.";
EsperantoLogEntries[LogEntryNames.ADDS_VP_TO] = "Argument0 aldonas Argument1 VP al Argument2.";
EsperantoLogEntries[LogEntryNames.WAITING_FOR] = "Atendante por Argument0.";
EsperantoLogEntries[LogEntryNames.ALMS_FAIL] = "Argument0 aĉetas [Alms] sed havas Argument1 en ludo.";
EsperantoLogEntries[LogEntryNames.BORROW_FAIL] = "Argument0 aĉetas [Borrow] sed jam havis Argument1";
EsperantoLogEntries[LogEntryNames.QUEST_FAIL_ATTACK] = "Argument0 fiaskas malkaŝi Atakkarton.";
EsperantoLogEntries[LogEntryNames.QUEST_FAIL_CURSES] = "Argument0 fiaskas malkaŝi Argument1.";
EsperantoLogEntries[LogEntryNames.QUEST_FAIL_DISCARD] = "Argument0 fiaskas malkaŝi 6 kartojn.";
EsperantoLogEntries[LogEntryNames.SAVE_FAIL] = "Argument0 ne havis kartojn por flankenmeti.";
EsperantoLogEntries[LogEntryNames.SCOUTING_PARTY_FAIL] = "Argument0 ne havis kartojn por forĵeti aŭ surmeti.";
EsperantoLogEntries[LogEntryNames.BONFIRE_FAIL] = "Argument0 rubigas nenion.";
EsperantoLogEntries[LogEntryNames.MISSION_SUCCESS] = "Argument0 prenas ekstran vicon antaŭ ĉi tiun.";
EsperantoLogEntries[LogEntryNames.TRADE_FAIL] = "Argument0 rubigas nenion.";
EsperantoLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_DOWN] = "Argument0 renversas Argument1 surventre.";
EsperantoLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_UP] = "Argument0 renversas Argument1 surdorse.";
EsperantoLogEntries[LogEntryNames.TRIUMPH_FAIL] = "Argument0 fiaskas gajni Argument1.";
EsperantoLogEntries[LogEntryNames.ADVANCE_FAIL] = "Argument0 ne rubigis Agkarton.";
EsperantoLogEntries[LogEntryNames.TAX_ADD_DEBT] = "Argument0 aldonas Argument1 al Argument2.";
EsperantoLogEntries[LogEntryNames.TAX_TAKE_DEBT] = "Argument0 prenas Argument1 el Argument2.";
EsperantoLogEntries[LogEntryNames.RITUAL_FAIL] = "Argument0 fiaskas gajni Argument1.";
EsperantoLogEntries[LogEntryNames.WINDFALL_FAIL] = "Argument0 ne estas malplena.";
EsperantoLogEntries[LogEntryNames.DOMINATE_FAIL] = "Argument0 fiaska gajni Argument1.";
EsperantoLogEntries[LogEntryNames.PLUS_ACTION_FROM] = "Argument0 ricevas +1 Ago (el Argument1).";
EsperantoLogEntries[LogEntryNames.PLUS_BUY_FROM] = "Argument0 ricevas +1 Aĉeto (el Argument1).";
EsperantoLogEntries[LogEntryNames.DRAWS_FROM] = "Argument0 tiras Argument1 (el Argument2).";
EsperantoLogEntries[LogEntryNames.PLUS_COIN_FROM] = "Argument0 ricevas +1 Monero (from Argument1).";
EsperantoLogEntries[LogEntryNames.DRAWS_LESS_BECAUSE] = "Argument0 preterpasas unu tiron (pro Argument1).";
EsperantoLogEntries[LogEntryNames.PRINCE_FAILS] = "";
EsperantoLogEntries[LogEntryNames.POSSESSION_TAKES_COIN_TOKENS] = "Argument0 takes the coin tokens instead.";
EsperantoLogEntries[LogEntryNames.POSSESSION_TAKES_DEBT_TOKENS] = "Argument0 prenas la Ŝuldon anstataŭe.";
EsperantoLogEntries[LogEntryNames.POSSESION_TAKES_VP_TOKENS] = "Argument0 takes the VP tokens instead.";
EsperantoLogEntries[LogEntryNames.TAKES_DEBT] = "Argument0 prenas Argument1 ŝuldon.";
EsperantoLogEntries[LogEntryNames.REPAYS_DEBT] = "Argument0 repagas Argument1 ŝuldon.";
EsperantoLogEntries[LogEntryNames.REPAYS_SOME_DEBT] = "Argument0 repagas Argument1 ŝuldon (Argument2 restas).";
EsperantoLogEntries[LogEntryNames.RETURNS_TOKEN] = "Argument0 redonas Argument1 movis de Argument2.";
EsperantoLogEntries[LogEntryNames.SET_ASIDE_WITH] = "Argument0 flankenmetas Argument1 kun Argument2.";
EsperantoLogEntries[LogEntryNames.BLOCKS_WITH] = "Argument0 baras kun Argument1.";
EsperantoLogEntries[LogEntryNames.NAMES] = "Argument0 nomumas \"Argument1\".";
EsperantoLogEntries[LogEntryNames.GAIN_ON_DRAWPILE] = "Argument0 gajnas Argument1 sur ties kartaron.";
EsperantoLogEntries[LogEntryNames.GAIN_FROM_TRASH] = "Argument0 gajnas Argument1 el la Rubujo.";
EsperantoLogEntries[LogEntryNames.OBELISK_FAILS] = "[Obelisk] fiaskas elekti Agan Provizstakon.";
EsperantoLogEntries[LogEntryNames.INHERITS] = "Argument0 heredas Argument1.";
EsperantoLogEntries[LogEntryNames.GAME_META_INFO] = "Argument0";
EsperantoLogEntries[LogEntryNames.THE_SKYS_GIFT_FAIL] = "Argument0 fiaskas forĵeti por [The Skys Gift]";
EsperantoLogEntries[LogEntryNames.RECEIVES] = "Argument0 ricevas Argument1.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_PASTURE] = "Argument0.";
EsperantoLogEntries[LogEntryNames.TAKE] = "Argument0 prenas Argument1.";
EsperantoLogEntries[LogEntryNames.RETURN] = "Argument0 redonas Argument1.";
EsperantoLogEntries[LogEntryNames.THE_SUNS_GIFT_FAIL] = "[The Suns Gift] havas nenion por forĵeti.";
EsperantoLogEntries[LogEntryNames.DRUID_SELECTS_BOONS] = "[Druid] flankenmetas Argument1.";
EsperantoLogEntries[LogEntryNames.DISCARD_RECEIVABLE] = "Argument0 forĵetas Argument1.";
EsperantoLogEntries[LogEntryNames.PLAY_ENCHANTED] = "Argument0 estas ĉarmita de Argument1";
EsperantoLogEntries[LogEntryNames.GAIN_ANOTHER_EXPERIMENT] = "Argument0 gajnas alian [^nExperiment]";
EsperantoLogEntries[LogEntryNames.COFFERS_FROM_PATRON] = "Argument0 ricevas Monrezervon el [Patron].";
EsperantoLogEntries[LogEntryNames.GETS_COFFER] = "Argument0 ricevas +Argument1 Monrezervo.";
EsperantoLogEntries[LogEntryNames.GETS_COFFERS] = "Argument0 ricevas +Argument1 Monrezervoj.";
EsperantoLogEntries[LogEntryNames.GETS_COFFER_FROM] = "Argument0 ricevas +Argument1 Monrezervo. (Argument2)";
EsperantoLogEntries[LogEntryNames.GETS_COFFERS_FROM] = "Argument0 gets +Argument1 Monrezervoj. (Argument2)";
EsperantoLogEntries[LogEntryNames.USES_COFFER] = "Argument0 uzas Argument1 Monrezervon. (+$Argument1)";
EsperantoLogEntries[LogEntryNames.USES_COFFERS] = "Argument0 uzas Argument1 Monrezervojn. (+$Argument1)";
EsperantoLogEntries[LogEntryNames.GETS_VILLAGER] = "Argument0 ricevas +Argument1 Vilaĝano.";
EsperantoLogEntries[LogEntryNames.GETS_VILLAGERS] = "Argument0 ricevas +Argument1 Vilaĝanoj.";
EsperantoLogEntries[LogEntryNames.GETS_VILLAGER_FROM] = "Argument0 ricevas +Argument1 Vilaĝano. (Argument2)";
EsperantoLogEntries[LogEntryNames.GETS_VILLAGERS_FROM] = "Argument0 ricevas +Argument1 Vilaĝanoj. (Argument2)";
EsperantoLogEntries[LogEntryNames.USES_VILLAGER] = "Argument0 uzas Argument1 Vilaĝanon.";
EsperantoLogEntries[LogEntryNames.USES_VILLAGERS] = "Argument0 uzas Argument1 Vilaĝanojn.";
EsperantoLogEntries[LogEntryNames.BEGINS_BUYPHASE] = "Argument0 begins their buy phase."; // please translate
EsperantoLogEntries[LogEntryNames.ENDS_BUYPHASE] = "Argument0 finas ties Aĉetfazo.";
EsperantoLogEntries[LogEntryNames.GETS_ACTION] = "Argument0 ricevas +Argument1 Ago.";
EsperantoLogEntries[LogEntryNames.GETS_ACTIONS] = "Argument0 ricevas +Argument1 Agoj.";
EsperantoLogEntries[LogEntryNames.GETS_ACTION_FROM] = "Argument0 ricevas +Argument1 Ago. (Argument2)";
EsperantoLogEntries[LogEntryNames.GETS_ACTIONS_FROM] = "Argument0 ricevas +Argument1 Agoj. (Argument2)";
EsperantoLogEntries[LogEntryNames.GETS_BUY] = "Argument0 ricevas +Argument1 Aĉeto.";
EsperantoLogEntries[LogEntryNames.GETS_BUYS] = "Argument0 ricevas +Argument1 Aĉetoj.";
EsperantoLogEntries[LogEntryNames.GETS_BUY_FROM] = "Argument0 ricevas +Argument1 Aĉeto. (Argument2)";
EsperantoLogEntries[LogEntryNames.GETS_BUYS_FROM] = "Argument0 ricevas +Argument1 Aĉetoj. (Argument2)";
EsperantoLogEntries[LogEntryNames.GETS_COIN] = "Argument0 ricevas +$Argument1.";
EsperantoLogEntries[LogEntryNames.GETS_COINS] = "Argument0 ricevas +$Argument1.";
EsperantoLogEntries[LogEntryNames.GETS_COIN_FROM] = "Argument0 ricevas +$Argument1. (Argument2)";
EsperantoLogEntries[LogEntryNames.GETS_COINS_FROM] = "Argument0 ricevas +$Argument1. (Argument2)";
EsperantoLogEntries[LogEntryNames.LOSES_COIN] = "Argument0 perdas Argument1 Moneron.";
EsperantoLogEntries[LogEntryNames.LOSES_COINS] = "Argument0 perdas Argument1 Monerojn.";
EsperantoLogEntries[LogEntryNames.BOTTOMDECKS] = "Argument0 metas Argument1 sur la fundon de ties kartaro.";
EsperantoLogEntries[LogEntryNames.TRASH_WITH] = "Argument0 rubigas Argument1. (Argument2)";
EsperantoLogEntries[LogEntryNames.PRIEST_ACTIVATES] = "ekde nun, kiam vi rubigas karton, +$Argument0.";
EsperantoLogEntries[LogEntryNames.PLAY_TREASURES_FOR] = "Argument0 ludas Argument1. (+$Argument2)";
EsperantoLogEntries[LogEntryNames.PLAY_TREASURES_AGAIN_FOR] = "Argument0 reludas Argument1. (+$Argument2)";
EsperantoLogEntries[LogEntryNames.RETURNS_MINUS_COIN_TOKEN] = "Argument0 redonas Argument1 movis de Argument2. (-$1)";
EsperantoLogEntries[LogEntryNames.NOT_IN_PLAY_PREVENTED_PLAYING] = "Argument0 ne estis en ludo, do ĝi ne povis esti ludita.";
EsperantoLogEntries[LogEntryNames.PILLAGE_FAIL] = "[Pillage] ne estis rubigita, do faras nenion.";
EsperantoLogEntries[LogEntryNames.DEATH_CART_FAIL] = "[Death Cart] ne rubigis, do faras nenion.";
EsperantoLogEntries[LogEntryNames.TACTICIAN_FAIL] = "[Tactician] ne forĵetis, do faras nenion.";
EsperantoLogEntries[LogEntryNames.EMBARGO_FAIL] = "[Embargo] ne estis rubigita, do faras nenion.";
EsperantoLogEntries[LogEntryNames.EXILE] = "Argument0 Ekzilas Argument1.";
EsperantoLogEntries[LogEntryNames.DISCARD_FROM_EXILE] = "Argument0 forĵetas Argument1 el Ekzilo.";
EsperantoLogEntries[LogEntryNames.DISCARD_REVEALED] = "Argument0 forĵetas kaj malkaŝas Argument1.";
EsperantoLogEntries[LogEntryNames.PLAY_WITH] = EsperantoLogEntries[LogEntryNames.PLAY] + " (Argument2)";
EsperantoLogEntries[LogEntryNames.PLAY_AGAIN_WITH] = EsperantoLogEntries[LogEntryNames.PLAY] + " (Argument2)";
EsperantoLogEntries[LogEntryNames.PLAY_THIRD_WITH] = EsperantoLogEntries[LogEntryNames.PLAY] + " (Argument2)";
EsperantoLogEntries[LogEntryNames.IS_INVESTED] = "Argument0 tiras Argument2 kartojn kiam kontraŭulo gajnas aŭ investas en Argument1.";
EsperantoLogEntries[LogEntryNames.COMMERCE_FAIL] = "Argument0 ne gajnis iujn kartojn.";
EsperantoLogEntries[LogEntryNames.STAMPEDE_FAIL] = "Argument0 aĉetis [Stampede] kun Argument1 kartoj en ludo.";
EsperantoLogEntries[LogEntryNames.PLAY_USING_WAY] = "Argument0 ludas Argument1 kun Argument2.";
EsperantoLogEntries[LogEntryNames.WAY_OF_THE_HORSE_FAILS_NO_PILE] = "Argument0 ne havas stakon por redoni.";
EsperantoLogEntries[LogEntryNames.WAY_OF_THE_HORSE_FAILS_NOT_IN_PLAY] = "[Way Of The Horse] fiaskas movi Argument0, ĉar ne estas en ludo.";
EsperantoLogEntries[LogEntryNames.WAY_OF_THE_TURTLE_FAILS_TO_SET_ASIDE] = "[Way Of The Turtle] fiaskas flankenmeti Argument0.";
EsperantoLogEntries[LogEntryNames.USES_WAY] = "Argument0 uzas Argument1.";
EsperantoLogEntries[LogEntryNames.WOULD_GET_COIN] = "Argument0 ricevus +$Argument1.";
EsperantoLogEntries[LogEntryNames.WOULD_DRAW] = "Argument0 tirus Argument1.";
EsperantoLogEntries[LogEntryNames.TOPDECK_WITH] = "Argument0 surmetas Argument1 kun Argument2.";
EsperantoLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_NO_PILE] = "Argument0 ne havas stakon por redoni.";
EsperantoLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_NOT_IN_PLAY] = "[Way Of The Butterfly] fiaskas movi Argument0, ĉar ne estas en ludo.";
EsperantoLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_INTENTIONAL] = "Argument0 decidis ne redoni Argument1 al ĝia stako.";
EsperantoLogEntries[LogEntryNames.PLAY_NULLIFIED] = "Argument0 ludas Argument1 (nuligita de [Highwayman]).";
EsperantoLogEntries[LogEntryNames.GETS_FAVOR] = "Argument0 ricevas +Argument1 Komplezon.";
EsperantoLogEntries[LogEntryNames.GETS_FAVORS] = "Argument0 ricevas +Argument1 Komplezojn.";
EsperantoLogEntries[LogEntryNames.GETS_FAVOR_FROM] = "Argument0 ricevas +Argument1 Komplezon. (Argument2)";
EsperantoLogEntries[LogEntryNames.GETS_FAVORS_FROM] = "Argument0 ricevas +Argument1 Komplezojn. (Argument2)";
EsperantoLogEntries[LogEntryNames.SPENDS_FAVOR] = "Argument0 elspezas Argument1 Komplezon.";
EsperantoLogEntries[LogEntryNames.SPENDS_FAVORS] = "Argument0 elspezas Argument1 Komplezojn.";
EsperantoLogEntries[LogEntryNames.ROTATES_EMPTY_PILE] = "Argument0 rotacias malplenan stakon";
EsperantoLogEntries[LogEntryNames.ROTATES_IDENTICAL_PILE] = "Argument0 rotacias homogenan stakon.";
EsperantoLogEntries[LogEntryNames.ROTATES] = "Argument0 rotacias Argument1.";
EsperantoLogEntries[LogEntryNames.ISLAND_FOLK] = "Argument0 ludas ekstran vicon post ĉi tion.";
EsperantoLogEntries[LogEntryNames.ROYAL_GALLEY_FAIL] = "[Royal Galley] fiaskas flankenmeti.";
EsperantoLogEntries[LogEntryNames.FAILS_TO_PLAY] = "Argument0 fiaskas ludi Argument1";
EsperantoLogEntries[LogEntryNames.FAILS_TO_PLAY_WRONG_LOCATION] = "Argument0 fiaskas ludi Argument1 ĉar ĝi ne estas en Argument2";
EsperantoLogEntries[LogEntryNames.STRANDS_CARDS] = "Argument0 forlasitas Argument1";
EsperantoLogEntries[LogEntryNames.VOYAGE_SUCCESS] = "Argument0 ludas ekstran Voyaĝvicon post ĉi tion.";
EsperantoLogEntries[LogEntryNames.GARRISON_ADDS_TOKEN] = "Argument0 aldonas jetonon al [Garrison], nun ĉe Argument1.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_PLATEAU_SHEPHERDS] = "Argument0 Kartoj, Argument1 Komplezoj.";
EsperantoLogEntries[LogEntryNames.PUT_IN_DISCARD] = "Argument0 metas Argument1 en ties forĵeta stako.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_TERRITORY] = "Argument0 malsame nomataj Venkkartoj.";
EsperantoLogEntries[LogEntryNames.SORCERESS_GUESS] = "Argument0 konjektas Argument1.";
EsperantoLogEntries[LogEntryNames.SORCERER_GUESS] = "Argument0 konjektas Argument1.";
EsperantoLogEntries[LogEntryNames.SKIPS_A_TURN_REMAINING] = "Argument0 preterpasas vicon (Argument1 daŭras).";
EsperantoLogEntries[LogEntryNames.SKIPS_A_TURN] = "Argument0 preterpasas vicon.";
EsperantoLogEntries[LogEntryNames.CANCELS_A_TURN] = "Argument0 fails to take a Turn (from Argument1).";
EsperantoLogEntries[LogEntryNames.WILL_SKIP_A_TURN] = "Argument0 preterpasos vicon.";
EsperantoLogEntries[LogEntryNames.WILL_SKIP_A_TURN_COUNT] = "Argument0 preterpasos vicon (nun Argument1).";
EsperantoLogEntries[LogEntryNames.KEEPS_IN_HAND] = "Argument0 gardas Argument1 mane.";
EsperantoLogEntries[LogEntryNames.INVESTMENT_FAIL] = "[Investment] ne povas rubigi sin ĉar ĝi ne estas en ludo.";
EsperantoLogEntries[LogEntryNames.GAIN_SETTING_ASIDE] = "Argument0 gajnas Argument1 flankenmetante ĝin.";

EsperantoLogEntries[LogEntryNames.PLAYER_BIDS] = "Argument0 proponas Argument1 ŝuldon";
EsperantoLogEntries[LogEntryNames.PLAYER_ADDS_FAVOR_TOKEN_TO] = "Argument0 places a Favor token on Argument1. ([Family of Inventors])"; //please translate
EsperantoLogEntries[LogEntryNames.PLAY_TREASURES_THIRD_FOR] = EsperantoLogEntries[LogEntryNames.PLAY_THIRD] + " (+$Argument2)";

// Missing lines as of 09.01.2023 19:11:50
EsperantoLogEntries[LogEntryNames.BEGINS_CLEANUP_PHASE] = "Argument0 begins their Cleanup phase.";
EsperantoLogEntries[LogEntryNames.PREMOVES] = "Argument0 premoves Argument1.";
EsperantoLogEntries[LogEntryNames.SPELL_SCROLL_FAILS] = "[Spell Scroll] didn't trash, so does nothing.";
EsperantoLogEntries[LogEntryNames.PERIL_FAIL] = "[Peril] didn't trash, so does nothing.";
EsperantoLogEntries[LogEntryNames.ACQUIRES_TRAIT] = "The Argument1 Trait is acquired by Argument0.";
EsperantoLogEntries[LogEntryNames.TRAIT_FAILS] = "Argument0 fails to select an Action or Treasure pile.";
EsperantoLogEntries[LogEntryNames.SHUFFLES_CARDS] = "Argument0 shuffles Argument1.";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_MARCHLAND] = "Argument0 Victory cards.";
EsperantoLogEntries[LogEntryNames.FARMHANDS_FAIL] = "[Farmhands] failed to set aside a card";
EsperantoLogEntries[LogEntryNames.FARMHANDS_REFUSE] = "[Farmhands] didn't set aside a card";
EsperantoLogEntries[LogEntryNames.EXPLAIN_POINTS_DEMESNE] = "Argument0 Golds.";
EsperantoLogEntries[LogEntryNames.REACTS_PLAYING] = "Argument0 reacts with Argument1, playing it.";
EsperantoLogEntries[LogEntryNames.PLAY_ENLIGHTENED] = "Argument0 plays Argument1 for +1 Card +1 Action";
EsperantoLogEntries[LogEntryNames.PANIC_FAILS] = "[Panic] fails to return Argument0";
EsperantoLogEntries[LogEntryNames.PANIC_FAILS_NO_PILE] = "[Panic] can't return Argument0 without a pile.";
EsperantoLogEntries[LogEntryNames.AMASS_FAILS] = "Argument0 buys [Amass] but has Argument1 in play.";