"use strict";

webclient.component("loadingGame", {
    transclude: true,
    controller: ['connection', 'game', function (connection, game) {
        var self = this;

        self.shouldShow = function () {
            return game.isRunning() && !connection.hasLoadedGame();
        };
        self.getWindowText = function () {
            return getPhrase(Phrases.LOADING_GAME);
        };
    }],
    template: "\n            <modal-window ng-if=\"$ctrl.shouldShow()\">\n                <div class=\"timeout\">{{::$ctrl.getWindowText()}}</div>\n            </modal-window>\n        "
});