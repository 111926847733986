"use strict";

webclient.service('autoplayService', ['game', 'preferences', 'serverMessenger', function (game, preferences, serverMessenger) {

    var service = this;

    service.autoplayable = function () {
        return isDefined(service.autoplaySetting);
    };
    service.autoplaying = function () {
        return service.autoplayable() && service.autoplaySetting.value !== 0;
    };
    service.userPreference = function () {
        return preferences[UserPrefIds.AUTOPLAY_SETTINGS];
    };
    service.userAutoplaySettings = function () {
        return service.userPreference().arguments;
    };

    service.predefinedSettings = {

        ALL_OFF: 'setAllOff',
        ALL_DEFAULT: 'setDefault',
        ALL_CONVENIENT: 'setConvenient'
        // , UNDO:           'undo'
    };

    service.setPredefinedSettings = function (settingsName) {

        var functionName = service.predefinedSettings[settingsName];
        var saveInHistory = settingsName !== 'Undo';

        service.userAutoplaySettings()[functionName]();
        service.allAutoplaySettings = service.userAutoplaySettings();
        service.mode = 'user';
        service.saveSettings(saveInHistory);
    };

    service.selectSettings = function (mode) {

        if (mode === 'game') {
            if (isDefined(game.optionsModel.autoplaySettings)) {

                service.allAutoplaySettings = game.optionsModel.autoplaySettings;
            } else {
                throw "Autoplay service in mode 'game', but did not get autoplay settings from game server.";
            }
        } else if (mode === 'user') {
            service.allAutoplaySettings = service.userAutoplaySettings();
        } else {
            throw "Expecting autoplay settings for either 'user' or 'game', got: " + mode;
        }

        service.mode = mode;
    };

    service.setCardName = function (cardName) {

        service.autoplaySetting = AutoPlay.findInMap(cardName.name, service.allAutoplaySettings);
        if (service.autoplayable()) service.cardName = service.autoplaySetting.cardName;
    };

    service.toggle = function () {
        var saveInHistory = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;


        if (service.autoplayable()) {

            service.autoplaySetting.toggle();
            service.saveSettings(saveInHistory);
        }
    };

    service.reset = function () {

        service.cardName = undefined;
        service.allAutoplaySettings = undefined;
        service.autoplaySetting = undefined;
    };

    service.changeAutoplaySetting = function (value) {
        var saveInHistory = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;


        if (service.autoplayable()) {

            service.autoplaySetting.setValue(value);
            service.saveSettings(saveInHistory);
        } else {
            throw "Can only set autoplay value for cards with autoplay options.";
        }
    };

    service.init = function (mode, cardName) {

        service.reset();
        service.selectSettings(mode);
        if (isDefined(cardName)) {
            service.setCardName(cardName);
        }
    };

    service.saveSettings = function () {
        var saveInHistory = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;


        if (service.mode === 'game' || service.mode === 'user') {
            if (saveInHistory) {
                service.allAutoplaySettings.saveInHistory();
            }
            var preference = new UserPreference(UserPrefIds.AUTOPLAY_SETTINGS, service.allAutoplaySettings);
            serverMessenger.setUserPreference(preference);
        } else {
            console.log('Cannot save autoplay settings for mode ' + mode);
        }
    };

    service.statusText = function () {
        return service.autoplaySetting.getTranslatedLabel();
    };
    service.cssClass = function () {
        return service.autoplaySetting.value === 0 ? '' : ' active';
    };
    service.values = function () {
        return service.autoplaySetting.optionValues();
    };
    service.descriptions = function () {
        return service.autoplaySetting.optionDescriptions();
    };

    service.value = function () {
        return service.autoplayable() && service.autoplaySetting.value;
    };
    service.description = function () {
        return service.autoplayable() && service.descriptions()[service.value()];
    };
    service.name = function () {
        return service.autoplayable() && service.autoplaySetting.settingName();
    };
    service.specificHelpLink = function () {
        return service.autoplayable() && "./doc/" + LANGUAGE.getName() + "/autoplay.html#" + toDashedLowerCase(service.cardName.name);
    };
    service.genericHelpLink = function () {
        return "./doc/" + LANGUAGE.getName() + "/autoplay.html#general";
    };
    service.specificLinkText = function () {
        return service.autoplayable() && LANGUAGE.getCardName[service.cardName].singular + LANGUAGE.getAutoplayLabels.AUTOPLAY_DOCUMENTATION_SPECIFIC;
    };
    service.genericLinkText = function () {
        return LANGUAGE.getAutoplayLabels.AUTOPLAY_DOCUMENTATION_GENERIC;
    };
}]);