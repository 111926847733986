"use strict";

webclient.component('scoreTableButtons', {
    controller: ['tableService', 'metaBroadcaster', function (tableService, metaBroadcaster) {
        var self = this;
        self.leave = function () {
            catchAll(function () {
                return tableService.leave();
            });
            var waitToLeave = function waitToLeave() {
                if (!tableService.hasTable()) {
                    console.log("returning to lobby because I left my own table");
                    metaBroadcaster.send(Events.RETURN_TO_LOBBY);
                } else {
                    setTimeout(waitToLeave, 100);
                }
            };
            setTimeout(waitToLeave, 100);
        };
        self.ready = function () {
            return tableService.ready();
        };
        self.notReady = function () {
            return tableService.notReady();
        };
        self.isHost = function () {
            return tableService.heroIsHost();
        };

        self.readyClick = function () {
            return self.isReady() ? tableService.notReady() : tableService.ready();
        };

        self.isReady = function () {
            return tableService.heroIsReady();
        };
        self.continueWithBotsClick = function () {
            return tableService.continueWithBots();
        };
        self.continueAllowed = function () {
            return tableService.continueAllowed;
        };

        self.editTable = function () {
            console.log("returning to lobby because I clicked edit table");
            metaBroadcaster.send(Events.RETURN_TO_LOBBY);
        };

        self.shouldHidePlay = false;

        self.shouldShowPlay = function () {
            return !self.isPlayer() && tableService.hasOpenSeats();
        };

        self.play = function () {
            self.shouldHidePlay = true;
            tableService.sitDown();
        };

        self.getLeaveText = function () {
            return getPhrase(Phrases.LEAVE_TABLE);
        };
        self.getContinueWithBotsText = function () {
            return getPhrase(Phrases.CONTINUE_WITH_BOTS);
        };
        self.getReadyText = function () {
            return self.isReady() ? getPhrase(Phrases.NOT_READY) : getPhrase(Phrases.READY);
        };
        self.getEditText = function () {
            return getPhrase(Phrases.EDIT_TABLE);
        };
        self.getPlayText = function () {
            return getPhrase(Phrases.PLAY);
        };

        self.isPlayer = function () {
            return tableService.heroIsPlayer();
        };
    }],
    template: '\n                <div class=\'table-buttons\'>\n                    <button class=\'lobby-button stone-button\'\n                            ng-show="!$ctrl.shouldHidePlay"\n                            ng-if=\'$ctrl.shouldShowPlay()\'\n                            ng-click=\'$ctrl.play()\'>{{$ctrl.getPlayText()}}</button>\n                    <button class=\'lobby-button gold-button\'\n                            ng-if=\'$ctrl.isPlayer()\'\n                            ng-click=\'$ctrl.readyClick()\'>{{$ctrl.getReadyText()}}</button>\n                    <button class=\'lobby-button copper-button\' \n                            ng-click=\'$ctrl.leave()\'>{{$ctrl.getLeaveText()}}</button>\n                    <button class=\'lobby-button copper-button\' \n                            ng-if=\'$ctrl.continueAllowed()\'\n                            ng-click=\'$ctrl.continueWithBotsClick()\'>{{$ctrl.getContinueWithBotsText()}}</button>\n                </div>\n            '
});