"use strict";

// possible ability descriptions that could use "Argument0" to be more helpful
//
// Argument0 is some number an effect relates to
// ----------------------------------------------
// CzechAbilityDescriptions[AbilityNames.EMPORIUM_GAIN_POINTS] = "+Argument0 VP (" + CzechCardNames[CardNames.EMPORIUM].singular + ")";
//    the following needs the (server side?) magic that takes "3" and "Gold" and turns it into the language correct version of "3 Golds"
// CzechAbilityDescriptions[AbilityNames.FORTUNE] = "gain Argument0 (" + CzechCardNames[CardNames.FORTUNE].singular + ")";
// CzechAbilityDescriptions[AbilityNames.GRAND_CASTLE] = "+Argument0 VP (" + CzechCardNames[CardNames.GRAND_CASTLE].singular + ")";
// CzechAbilityDescriptions[AbilityNames.TEMPLE] = "take all (Argument0) VP from the " + CzechCardNames[CardNames.TEMPLE].singular + " pile";
// CzechAbilityDescriptions[AbilityNames.TAX_RECEIVE] = "take all (Argument0) debt from the pile you bought from (" + CzechCardNames[CardNames.TAX].singular + ")";
// CzechAbilityDescriptions[AbilityNames.VP_FROM_AQUEDUCT] = "take all (Argument0) VP from " + CzechCardNames[CardNames.AQUEDUCT].singular;
// CzechAbilityDescriptions[AbilityNames.VP_FROM_DEFILED_SHRINE] = "take all (Argument0) VP from " + CzechCardNames[CardNames.DEFILED_SHRINE].singular;


var CzechAbilityDescriptions = {};

CzechAbilityDescriptions[AbilityNames.BUY] = "kupuj";
CzechAbilityDescriptions[AbilityNames.PLAY_ATTACK] = "hraj";
CzechAbilityDescriptions[AbilityNames.DRAW_INSTRUCTIONS] = "vezmi si Argument0";
CzechAbilityDescriptions[AbilityNames.PLAY_INSTRUCTIONS] = "hraj";
CzechAbilityDescriptions[AbilityNames.RESOLVE_EVENT] = "RESOLVE_EVENT";
CzechAbilityDescriptions[AbilityNames.GAIN_BY_NAME] = "vezmi si Argument0 (Argument1)";
CzechAbilityDescriptions[AbilityNames.FISHING_VILLAGE_ABILITY] = "+1 Akce, +$1";
CzechAbilityDescriptions[AbilityNames.REMOVE_COST_REDUCTION] = "REMOVE_COST_REDUCTION";
CzechAbilityDescriptions[AbilityNames.HAVEN_ABILITY] = "přidej si Argument0 do své ruky";
CzechAbilityDescriptions[AbilityNames.SAVE_RETURN_TO_HAND] = "vrať Argument0 do své ruky";
CzechAbilityDescriptions[AbilityNames.CARAVAN_ABILITY] = "+1 Karta";
CzechAbilityDescriptions[AbilityNames.MERCHANT_SHIP_ABILITY] = "+$2";
CzechAbilityDescriptions[AbilityNames.OUTPOST_ABILITY] = "[Outpost]";
CzechAbilityDescriptions[AbilityNames.TACTICIAN_ABILITY] = "+5 Karet, +1 Akce, +1 Nákup";
CzechAbilityDescriptions[AbilityNames.WHARF_ABILITY] = "+2 Karty, +1 Nákup";
CzechAbilityDescriptions[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = "+1 Karta, přidej si [Horse Traders] do své ruky";
CzechAbilityDescriptions[AbilityNames.DUCHESS_MAY_GAIN] = "vezmi si [^nDuchess]";
CzechAbilityDescriptions[AbilityNames.FOOLS_GOLD_TRASH] = "vyhoď [Fools Gold] a dej si navrch balíčku [^nGold]";
CzechAbilityDescriptions[AbilityNames.NOBLE_BRIGAND] = "můžeš okrást protihráče o [^nSilver] nebo o [^nGold]";
CzechAbilityDescriptions[AbilityNames.HAGGLER_ABILITY] = "vezmi si levnější kartu (kromě Vítezných bodů)";
CzechAbilityDescriptions[AbilityNames.INN_ABILITY] = "zamíchej Akce ze svého odhazovacího balíčku a dej je navrch svého dobíracího";
CzechAbilityDescriptions[AbilityNames.MANDARIN] = "dej navrch svého balíčku všechny své Poklady";
CzechAbilityDescriptions[AbilityNames.FARMLAND] = "přetvoř kartu na ruce na kartu přesně o $2";
CzechAbilityDescriptions[AbilityNames.GAIN_CHEAPER] = "vezmi si levnější kartu";
CzechAbilityDescriptions[AbilityNames.GAIN_TWO_RUINS] = "vezmi si 2 Ruiny";
CzechAbilityDescriptions[AbilityNames.HUNTING_GROUNDS] = "vezmi si [^nDuchy] nebo 3 [^pEstate]";
CzechAbilityDescriptions[AbilityNames.SQUIRE_GAIN_ATTACK] = "vezmi si kartu Útoku";
CzechAbilityDescriptions[AbilityNames.URCHIN_TRANSFORM] = "vyhoď [Urchin] a vezmi si [^nMercenary]";
CzechAbilityDescriptions[AbilityNames.FEODUM_GAIN_SILVERS] = "vezmi si 3 [^pSilver]";
CzechAbilityDescriptions[AbilityNames.DOCTOR_OVERPAY] = "můžeš přeplait abys vyhodil nebo odložil karty ze svého balíčku";
CzechAbilityDescriptions[AbilityNames.HERALD_OVERPAY] = "můžeš přeplatit abys přesunul karty z odkladacího na dobírací balíček";
CzechAbilityDescriptions[AbilityNames.MASTERPIECE_OVERPAY] = "můžeš přeplatit abys získal [^pSilver]";
CzechAbilityDescriptions[AbilityNames.STONEMASON_OVERPAY] = "můžeš přeplatit abys získal 2 karty Akce";
CzechAbilityDescriptions[AbilityNames.EXCHANGE] = "VÝMĚNA";
CzechAbilityDescriptions[AbilityNames.AMULET] = "+$1; nebo vyhoď kartu; nebo si vezmi [^nSilver]";
CzechAbilityDescriptions[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = "+$1";
CzechAbilityDescriptions[AbilityNames.DUNGEON_ABILITY] = "+2 Karty, poté odlož 2 karty";
CzechAbilityDescriptions[AbilityNames.GEAR_ABILITY] = "přidej Argument0 dos vé ruky";
CzechAbilityDescriptions[AbilityNames.MESSENGER_GAIN] = "všichni hráči získávají stejnou kartu stojící $4 nebo méně";
CzechAbilityDescriptions[AbilityNames.BRIDGE_TROLL_ABILITY] = "+1 Nákup";
CzechAbilityDescriptions[AbilityNames.HAUNTED_WOODS_DURATION] = "+3 Karty";
CzechAbilityDescriptions[AbilityNames.HAUNTED_WOODS_TOPDECK] = "polož své karty navrch balíčku (protihráč [Haunted Woods])";
CzechAbilityDescriptions[AbilityNames.LOST_CITY] = "+1 Karta (protihráč [Lost City])";
CzechAbilityDescriptions[AbilityNames.WINE_MERCHANT_DISCARD] = "odlož [^nWine Merchant] ze své desky Hospody";
CzechAbilityDescriptions[AbilityNames.HIRELING] = "+1 Karta";
CzechAbilityDescriptions[AbilityNames.SWAMP_HAG_DURATION] = "+$3";
CzechAbilityDescriptions[AbilityNames.TRASH_TOKEN_ABILITY] = "vyhoď kartu (vyhoď žeton)";
CzechAbilityDescriptions[AbilityNames.EMPORIUM_GAIN_POINTS] = "+2 VB, pokud máš alespoň 5 zahraných karet Akcí";
CzechAbilityDescriptions[AbilityNames.ROCKS_GAINS_SILVER] = "vezmi si [^nSilver]";
CzechAbilityDescriptions[AbilityNames.FORTUNE] = "vezmi si [^nGold] za každého zahraného [^nGladiator]";
CzechAbilityDescriptions[AbilityNames.CRUMBLING_CASTLE] = "+1 VB a vezmi si [^nSilver]";
CzechAbilityDescriptions[AbilityNames.HAUNTED_CASTLE] = "vezmi si [^nGold] a protihráči musí dát navrch balíčku stejnou kartu";
CzechAbilityDescriptions[AbilityNames.SPRAWLING_CASTLE] = "vezmi si [^nDuchy] nebo 3 [^pEstate]";
CzechAbilityDescriptions[AbilityNames.GRAND_CASTLE] = "+1 VB za každou kartu VB v zahraných kartách nebo na ruce";
CzechAbilityDescriptions[AbilityNames.ENCHANTRESS_ABILITY] = "+2 Karty";
CzechAbilityDescriptions[AbilityNames.ENCHANTRESS_CANTRIP] = "+1 Karta, +1 Akce";
CzechAbilityDescriptions[AbilityNames.TEMPLE] = "vezmi si všechny VB z hromádky [Temple]";
CzechAbilityDescriptions[AbilityNames.VILLA] = "vezmi si [Villa] do své ruky, +1 Akce a můžeš se vrátit do fáze Akce";
CzechAbilityDescriptions[AbilityNames.ARCHIVE_ABILITY] = "vezmi jednu kartu z Argument0";
CzechAbilityDescriptions[AbilityNames.CAPITAL_TAKE_DEBT] = "vezmi si 6 Dluhů, poté se můžeš vyplatiti";
CzechAbilityDescriptions[AbilityNames.CHARM] = "vezmi si jinou kartu se stejnou hodnotou";
CzechAbilityDescriptions[AbilityNames.FORUM] = "+1 Nákup";
CzechAbilityDescriptions[AbilityNames.HERMIT] = "vyhoď tuto kartu a vezmi si [^nMadman]";
CzechAbilityDescriptions[AbilityNames.DONATE] = "vyhoď kombinaci ze své ruky, dobíracího a odkládacího balíčku";
CzechAbilityDescriptions[AbilityNames.TAX_RECEIVE] = "vezmi si Dluh z hromádky, kde jsi nakoupil";
CzechAbilityDescriptions[AbilityNames.DOMINATE] = "vezmi si [^nProvince], pokud tak učiníš - +9 VB";
CzechAbilityDescriptions[AbilityNames.VP_TO_AQUEDUCT] = "přesuň 1 VB z hromádky Pokladů na [Aqueduct]";
CzechAbilityDescriptions[AbilityNames.VP_FROM_AQUEDUCT] = "vezmi si všechny VB z karty [Aqueduct]";
CzechAbilityDescriptions[AbilityNames.ARENA_DISCARD_ACTION] = "odlož Akci aby sis mohl vzít 2 VB z hromádky [Arena]";
CzechAbilityDescriptions[AbilityNames.VP_FROM_BASILICA] = "vezmi si 2 VB z hromádky [Basilica] pokud máš $2 nebo více";
CzechAbilityDescriptions[AbilityNames.VP_FROM_BATHS] = "vezmi si 2 VB z hromádky [Baths]";
CzechAbilityDescriptions[AbilityNames.VP_FROM_BATTLEFIELD] = "vezmi si 2 VB z hromádky [Battlefield]";
CzechAbilityDescriptions[AbilityNames.VP_FROM_COLONNADE] = "vezmi si 2 VB z hromádky [Colonnade] pokud jsi koupil kartu, kterou máš zahranou";
CzechAbilityDescriptions[AbilityNames.VP_TO_DEFILED_SHRINE] = "přesuň 1 VB z hromádky Akcí na [Defiled Shrine]";
CzechAbilityDescriptions[AbilityNames.VP_FROM_DEFILED_SHRINE] = "vezmi si všechny VB z hromádky [Defiled Shrine]";
CzechAbilityDescriptions[AbilityNames.VP_FROM_LABYRINTH] = "vezmi si 2 VB z hromádky [Labyrinth]";
CzechAbilityDescriptions[AbilityNames.MOUNTAIN_PASS_BIDDING] = "nabídni Dluh abys dostal +8 VB";
CzechAbilityDescriptions[AbilityNames.TOMB] = "+1 VB";
CzechAbilityDescriptions[AbilityNames.LIGHTHOUSE_COIN] = "+$1";
CzechAbilityDescriptions[AbilityNames.REACTION] = "REACTION";
CzechAbilityDescriptions[AbilityNames.CALL_GUIDE] = "vyvolej kartu [Guide] a odlož karty na ruce, +5 Karet";
CzechAbilityDescriptions[AbilityNames.CALL_RATCATCHER] = "vyvolej kartu [Ratcatcher] a vyhoď Kartu";
CzechAbilityDescriptions[AbilityNames.CALL_TRANSMOGRIFY] = "vyvolej kartu [Transmogrify] a vylepši Kartu";
CzechAbilityDescriptions[AbilityNames.CALL_TEACHER] = "vyvolej kartu [Teacher] pro přesun žetonu Dobrodružství na hromádku Akcí";
CzechAbilityDescriptions[AbilityNames.CALL_DUPLICATE] = "vyvolej kartu [Duplicate] pro získání kopie Argument0";
CzechAbilityDescriptions[AbilityNames.CALL_COIN] = "vyvolej kartu [Coin Of The Realm] pro +2 Akce";
CzechAbilityDescriptions[AbilityNames.CALL_ROYAL_CARRIAGE] = "vyvolej kartu [Royal Carriage] pro zopakování Argument0";
CzechAbilityDescriptions[AbilityNames.START_OF_TURN] = "začni svůj tah";
CzechAbilityDescriptions[AbilityNames.START_BUY_PHASE] = "začni svou fázi Nákupu";
CzechAbilityDescriptions[AbilityNames.END_OF_BUY_PHASE] = "ukonči svou fázi Nákupu";
CzechAbilityDescriptions[AbilityNames.BETWEEN_TURNS] = "mezi tahy";
CzechAbilityDescriptions[AbilityNames.END_OF_TURN] = "ukonči svůj tah";
CzechAbilityDescriptions[AbilityNames.SUMMON_PLAY] = "hraj Argument0";
CzechAbilityDescriptions[AbilityNames.CARAVAN_GUARD_REACTION] = "hraj kartu [Caravan Guard]";
CzechAbilityDescriptions[AbilityNames.TRAVELLING_FAIR_REACTION] = "můžeš přesunout Argument0 navrch balíčku";
CzechAbilityDescriptions[AbilityNames.MOAT_REACTION] = "odhal [Moat] pro odražení útoku";
CzechAbilityDescriptions[AbilityNames.HORSE_TRADERS_REACTION] = "dej na stranu [^nHorse Traders] pro +1 Kartu v příštím tahu";
CzechAbilityDescriptions[AbilityNames.BEGGAR_REACTION] = "odlož kartu [Beggar] k získání 2 [^pSilver], jedno navrch balíčku";
CzechAbilityDescriptions[AbilityNames.MARKET_SQUARE_REACTION] = "odlož kartu [Market Square] k získání [^pGold]";
CzechAbilityDescriptions[AbilityNames.TRADER_REACTION] = "vezmi si [^nSilver] namísto Argument0";
CzechAbilityDescriptions[AbilityNames.DIPLOMAT_REACTION] = "+2 Karty, odlož 3 karty";
CzechAbilityDescriptions[AbilityNames.TOPDECK_REACTION] = "dej si navrch balíčku Argument0";
CzechAbilityDescriptions[AbilityNames.WATCHTOWER_TRASH_REACTION] = "vyhoď Argument0";
CzechAbilityDescriptions[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "dej si navrch balíčku Argument0";
CzechAbilityDescriptions[AbilityNames.PRINCE_PLAY] = "hraj Argument0";
CzechAbilityDescriptions[AbilityNames.TUNNEL_REVEAL] = "vezmi si [^nGold]";
CzechAbilityDescriptions[AbilityNames.POSSESSION_GAIN_INSTEAD] = "Argument0 získává Argument2 namísto Argument1";
CzechAbilityDescriptions[AbilityNames.HOVEL_TRASH] = "trash [Hovel]";
CzechAbilityDescriptions[AbilityNames.DELUDED_RETURNED] = "vrať [^nDeluded]";
CzechAbilityDescriptions[AbilityNames.ENVIOUS_RETURNED] = "vrať [^nEnvious]";
CzechAbilityDescriptions[AbilityNames.RECEIVE_BOON] = "získej Argument0";
CzechAbilityDescriptions[AbilityNames.COBBLER_ABILITY] = "vezmi si kartu o hodnotě $4 (nebo levnější) do ruky";
CzechAbilityDescriptions[AbilityNames.CRYPT_ABILITY] = "vezmi si jeden z Argument0";
CzechAbilityDescriptions[AbilityNames.DEN_OF_SIN_ABILITY] = "+2 Karty";
CzechAbilityDescriptions[AbilityNames.FAITHFUL_HOUND] = "dej na stranu [^nFaithful Hound]";
CzechAbilityDescriptions[AbilityNames.FAITHFUL_HOUND_RETURN] = "vrať [^nFaithful Hound] do své ruky";
CzechAbilityDescriptions[AbilityNames.GHOST_TOWN_ABILITY] = "+1 Karta, +1 Akce";
CzechAbilityDescriptions[AbilityNames.GUARDIAN_COIN] = "+$1";
CzechAbilityDescriptions[AbilityNames.RAIDER_ABILITY] = "+$3";
CzechAbilityDescriptions[AbilityNames.SECRET_CAVE] = "+$3";
CzechAbilityDescriptions[AbilityNames.CEMETERY] = "vyhoď až 4 karty";
CzechAbilityDescriptions[AbilityNames.HAUNTED_MIRROR] = "odlož Akci pro získání [^nGhost]";
CzechAbilityDescriptions[AbilityNames.GHOST] = "hraj Argument0 dvakrát";
CzechAbilityDescriptions[AbilityNames.REMOVE_FLIPPED_STATUS] = "protoč karty na Smetišti";
CzechAbilityDescriptions[AbilityNames.LOST_IN_THE_WOODS] = "odlož kartu a získej Výhodu";
CzechAbilityDescriptions[AbilityNames.BLESSED_VILLAGE] = "vezmi si Výhodu";
CzechAbilityDescriptions[AbilityNames.FORTRESS] = "vezmi si [^nFortress] do ruky";
CzechAbilityDescriptions[AbilityNames.RECEIVE_HEX] = "zísej Prokletí";
CzechAbilityDescriptions[AbilityNames.CHANGELING_MAY_EXCHANGE] = "vyměň Argument0 za [^nChangeling]";
CzechAbilityDescriptions[AbilityNames.TRADE_ROUTE_MOVE_TOKEN] = "Přesuň žeton mince na Desku Obchodní cesty";
CzechAbilityDescriptions[AbilityNames.DUCAT] = "trash [^nCopper]";
CzechAbilityDescriptions[AbilityNames.CARGO_SHIP_SET_ASIDE] = "dej na stranu Argument0";
CzechAbilityDescriptions[AbilityNames.CARGO_SHIP_RETURN] = "vezmi si Argument0 do ruky";
CzechAbilityDescriptions[AbilityNames.IMPROVE] = "vylepši si zahranou Akci";
CzechAbilityDescriptions[AbilityNames.PRIEST] = "+$2";
CzechAbilityDescriptions[AbilityNames.RESEARCH] = "vezmi si Argument0 do ruky";
CzechAbilityDescriptions[AbilityNames.SILK_MERCHANT] = "+1 Měďák, +1 Vesničan";
CzechAbilityDescriptions[AbilityNames.LACKEYS] = "+2 Vesničani";
CzechAbilityDescriptions[AbilityNames.SPICES] = "+2 Měďáky";
CzechAbilityDescriptions[AbilityNames.KEY] = "+$1";
CzechAbilityDescriptions[AbilityNames.TREASURE_CHEST] = "vezmi si [^nGold]";
CzechAbilityDescriptions[AbilityNames.FLAG_BEARER] = "vezmi si [^nFlag]";
CzechAbilityDescriptions[AbilityNames.CATHEDRAL] = "vyhoď kartu";
CzechAbilityDescriptions[AbilityNames.CITY_GATE] = "+1 Karta, poté ji dejte navrch balíčku";
CzechAbilityDescriptions[AbilityNames.PAGEANT] = "zaplať $1 za +1 Měďák";
CzechAbilityDescriptions[AbilityNames.SEWERS_MAY_TRASH] = "vyhoď jinou kartu";
CzechAbilityDescriptions[AbilityNames.EXPLORATION] = "+1 Měďák, +1 Vesničan";
CzechAbilityDescriptions[AbilityNames.FAIR] = "+1 Nákup";
CzechAbilityDescriptions[AbilityNames.SILOS] = "odlož Měďáky za dobrání karet";
CzechAbilityDescriptions[AbilityNames.ACADEMY] = "+1 Vesničan";
CzechAbilityDescriptions[AbilityNames.GUILDHALL] = "+1 Měďák";
CzechAbilityDescriptions[AbilityNames.PIAZZA] = "odhal a hraj Akci";
CzechAbilityDescriptions[AbilityNames.BARRACKS] = "+1 Akce";
CzechAbilityDescriptions[AbilityNames.CROP_ROTATION] = "odlož Vítezné body za +2 Karty";
CzechAbilityDescriptions[AbilityNames.INNOVATION] = "odlož na stranu a hraj Argument0";
CzechAbilityDescriptions[AbilityNames.CITADEL] = "hraj Argument0 znova";
CzechAbilityDescriptions[AbilityNames.SINISTER_PLOT] = "dober nebo přidej žeton";
CzechAbilityDescriptions[AbilityNames.ENCAMPMENT_RETURNED] = "vrať [^nEncampment] do Zásoby";
CzechAbilityDescriptions[AbilityNames.EMBARGO_GAIN_CURSE] = "vezmi si [^nCurse]";
CzechAbilityDescriptions[AbilityNames.CAPTAIN] = "zahraj akci ze zásoby";
CzechAbilityDescriptions[AbilityNames.CHURCH] = "vezmi si Argument0 do ruky, poté můžeš vyhodit";
CzechAbilityDescriptions[AbilityNames.POSSESSION_SETS_ASIDE] = "dej na stranu Argument0";
CzechAbilityDescriptions[AbilityNames.MINT] = "Vyhoď všechny zahrané Poklady";
CzechAbilityDescriptions[AbilityNames.SLEIGH_TOPDECK_REACTION] = "odlož [^nSleigh] abys dal navrch balíčku Argument0";
CzechAbilityDescriptions[AbilityNames.SLEIGH_PUT_IN_HAND_REACTION] = "odlož [^nSleigh] aby sis vzal Argument0 do ruky";
CzechAbilityDescriptions[AbilityNames.BLACK_CAT_REACTION] = "Zahraj [^nBlack Cat]";
CzechAbilityDescriptions[AbilityNames.DISCARD_FROM_EXILE] = "odlož Argument0 z Vyhoštění.";
CzechAbilityDescriptions[AbilityNames.EXILE_BY_NAME] = "Vyhosti Argument0";
CzechAbilityDescriptions[AbilityNames.SHEEPDOG_REACTION] = "Zahraj [^nSheepdog]";
CzechAbilityDescriptions[AbilityNames.CAVALRY] = "+2 Karty, +1 Nákup a vrať se k fázi Akce.";
CzechAbilityDescriptions[AbilityNames.HOSTELRY] = "odlož Poklady pro Koně";
CzechAbilityDescriptions[AbilityNames.VILLAGE_GREEN_VILLAGE] = "+1 Karta, +2 Akce";
CzechAbilityDescriptions[AbilityNames.VILLAGE_GREEN_PLAY] = "Zahraj [^nVillage Green]";
CzechAbilityDescriptions[AbilityNames.BARGE] = "+3 Karty, +1 Nákup";
CzechAbilityDescriptions[AbilityNames.FALCONER_REACTION] = "vezmi si levnější Kartu";
CzechAbilityDescriptions[AbilityNames.GATEKEEPER_EXILE] = "Vyhosti Argument0";
CzechAbilityDescriptions[AbilityNames.GATEKEEPER_DURATION] = "+3 Mince";
CzechAbilityDescriptions[AbilityNames.LIVERY] = "vezmi si Koně";
CzechAbilityDescriptions[AbilityNames.MASTERMIND] = "můžeš hrát kartu třikrát";
CzechAbilityDescriptions[AbilityNames.DELAY_PLAY] = "hraj Argument0";
CzechAbilityDescriptions[AbilityNames.INVEST] = "INVEST ABILITY (never shown to user)";
CzechAbilityDescriptions[AbilityNames.REAP_PLAY] = "zahraj Odkladiště [Gold]";
CzechAbilityDescriptions[AbilityNames.PLUS_CARD_TOKEN] = "+Karta ze žetonu";
CzechAbilityDescriptions[AbilityNames.PLUS_ACTION_TOKEN] = "+Akce ze žetonu";
CzechAbilityDescriptions[AbilityNames.PLUS_BUY_TOKEN] = "+Nákup ze žetonu";
CzechAbilityDescriptions[AbilityNames.PLUS_COIN_TOKEN] = "+Mince ze žetonu";
CzechAbilityDescriptions[AbilityNames.KILN] = "vezmi si kopii Argument0";
CzechAbilityDescriptions[AbilityNames.TURTLE_PLAY] = "zahraj Odkladiště Argument0";
CzechAbilityDescriptions[AbilityNames.GIVE_PLUS_CARDS] = "+Karta";
CzechAbilityDescriptions[AbilityNames.GIVE_PLUS_ACTIONS] = "+Akce";
CzechAbilityDescriptions[AbilityNames.GIVE_PLUS_BUYS] = "+Nákup";
CzechAbilityDescriptions[AbilityNames.GIVE_PLUS_COINS] = "+Mince";