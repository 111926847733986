"use strict";

var SpanishCardTexts = {};

SpanishCardTexts[CardNames.BACK] = "";
SpanishCardTexts[CardNames.CURSE] = "";
SpanishCardTexts[CardNames.COPPER] = "";
SpanishCardTexts[CardNames.SILVER] = "";
SpanishCardTexts[CardNames.GOLD] = "";
SpanishCardTexts[CardNames.ESTATE] = "";
SpanishCardTexts[CardNames.DUCHY] = "";
SpanishCardTexts[CardNames.PROVINCE] = "";
SpanishCardTexts[CardNames.ARTISAN] = "Gana una carta a tu mano//que cueste hasta [5].////Pon una carta de tu mano//sobre tu mazo.";
SpanishCardTexts[CardNames.BANDIT] = "Gana un Oro.//Cada rival revela las 2//cartas superiores de su mazo,//elimina uno de los Tesoros//revelados que no sea Cobre//y descarta el resto.";
SpanishCardTexts[CardNames.BUREAUCRAT] = "Gana una Plata sobre tu mazo.//Cada rival revela una carta//de Victoria y la pone sobre//su mazo (o revela una mano//sin cartas de Victoria).";
SpanishCardTexts[CardNames.CELLAR] = "|+1 Acci\xF3n|////Descarta las cartas que quieras//y roba la misma cantidad.";
SpanishCardTexts[CardNames.CHAPEL] = "Elimina hasta 4 cartas//de tu mano.";
SpanishCardTexts[CardNames.COUNCIL_ROOM] = "|+4 Cartas|//|+1 Compra|////Cada rival//roba una carta.";
SpanishCardTexts[CardNames.FESTIVAL] = "|+2 Acciones|//|+1 Compra|//|+[2]|";
SpanishCardTexts[CardNames.GARDENS] = "Vale {1} por cada//10 cartas que tengas//(redondeando hacia abajo).";
SpanishCardTexts[CardNames.HARBINGER] = "|+1 Carta|//|+1 Acci\xF3n|////Mira tu pila de descartes.//Puedes poner una de esas//cartas sobre tu mazo.";
SpanishCardTexts[CardNames.LABORATORY] = "|+2 Cartas|//|+1 Acci\xF3n|";
SpanishCardTexts[CardNames.LIBRARY] = "Roba hasta tener 7 cartas//en la mano, omitiendo//cualquier Acci\xF3n que desees;//aparta esas cartas, para//descartarlas despu\xE9s.";
SpanishCardTexts[CardNames.MARKET] = "|+1 Carta|//|+1 Acci\xF3n|//|+1 Compra|//|+[1]|";
SpanishCardTexts[CardNames.MERCHANT] = "|+1 Carta|//|+1 Acci\xF3n|////La primera vez que jugues//una Plata este turno, |+|[1].";
SpanishCardTexts[CardNames.MILITIA] = "|+[2]|////Los dem\xE1s jugadores//se descartan hasta quedarse//con 3 cartas en la mano.";
SpanishCardTexts[CardNames.MINE] = "Puedes eliminar un Tesoro//de tu mano. Gana un Tesoro//a tu mano que cueste hasta [3]//m\xE1s que la carta eliminada.";
SpanishCardTexts[CardNames.MOAT] = "|+2 Cartas|//---//Cuando otro jugador juegue//un Ataque, puedes revelar//esta carta de tu mano//para que no te afecte.";
SpanishCardTexts[CardNames.MONEYLENDER] = "Puedes eliminar un Cobre//de tu mano para |+|[3].";
SpanishCardTexts[CardNames.POACHER] = "|+1 Carta|//|+1 Acci\xF3n|//|+[1]|////Descarta una carta por cada//pila de Suministro vac\xEDa.";
SpanishCardTexts[CardNames.REMODEL] = "Elimina una carta de tu//mano. Gana una carta//que cueste hasta [2] m\xE1s//que la carta eliminada.";
SpanishCardTexts[CardNames.SENTRY] = "|+1 Carta|//|+1 Acci\xF3n|////Mira las 2 cartas superiores de//tu mazo. Elimina y/o descarta//cualquier n\xFAmero de ellas.//Coloca el resto sobre//el mazo en cualquier orden.";
SpanishCardTexts[CardNames.SMITHY] = "|+3 Cartas|";
SpanishCardTexts[CardNames.THRONE_ROOM] = "Puedes jugar dos veces//una Acci\xF3n de tu mano.";
SpanishCardTexts[CardNames.VASSAL] = "|+[2]|////Descarta la carta superior//de tu mazo. Si es una carta//de Acci\xF3n, puedes jugarla.";
SpanishCardTexts[CardNames.VILLAGE] = "|+1 Carta|//|+2 Acciones|";
SpanishCardTexts[CardNames.WITCH] = "|+2 Cartas|////Cada rival gana//una Maldici\xF3n.";
SpanishCardTexts[CardNames.WORKSHOP] = "Gana una carta con//un coste m\xE1ximo de [4].";
SpanishCardTexts[CardNames.COURTYARD] = "|+3 Cartas|////Pon una carta de tu mano//encima de tu mazo.";
SpanishCardTexts[CardNames.CONSPIRATOR] = "|+[2]|////Si has jugado al menos 3 Acciones//este turno (contando esta carta)://|+1 Carta|, |+1 Acci\xF3n|.";
SpanishCardTexts[CardNames.COURTIER] = "Revela una carta de tu mano.//Por cada tip que tenga (Acci\xF3n,//Ataque, etc), elige una://|+1 Acci\xF3n|; o |+1 Compra|; o//|+|[3]; o gana un Oro. Las//elecciones deben ser distintas.";
SpanishCardTexts[CardNames.BARON] = "|+1 Compra|////Puedes descartar una carta de Finca.//Si lo haces, |+|[4].//Si no, ganas una carta de Finca.";
SpanishCardTexts[CardNames.BRIDGE] = "|+1 Compra|//|+[1]|////Todas las cartas (incluyendo las//de las manos de los jugadores)//cuestan [1] menos en este turno,//pero nunca menos que [0].";
SpanishCardTexts[CardNames.DIPLOMAT] = "|+2 Cartas|////Si tienes 5 o menos cartas en mano//(tras robar), |+2 Acci\xF3nes|.//---//Cuando otro jugador juegue un//Ataque, puedes revelar esto desde//una mano de 5 o m\xE1s cartas para//robar 2 cartas y descartar 3.";
SpanishCardTexts[CardNames.DUKE] = "Consigues {1}//por cada carta Ducado//que tengas en tu mazo.";
SpanishCardTexts[CardNames.FARM] = "[!2]//---//{!2}";
SpanishCardTexts[CardNames.NOBLES] = "{!2}//---//Elige una opci\xF3n://|+3 Cartas|; o bien |+2 Acci\xF3nes|.";
SpanishCardTexts[CardNames.IRONWORKS] = "Gana una carta que tenga//un coste m\xE1ximo de [4].//Si se trata de una carta...//de Acci\xF3n, |+1 Acci\xF3n|//de Tesoro, |+[1]|//de Victoria,|+1 Carta|";
SpanishCardTexts[CardNames.LURKER] = "|+1 Acci\xF3n|////Elige una: Elimina una carta de//Acci\xF3n del Suministro, o gana//una carta de Acci\xF3n de las//cartas eliminadas.";
SpanishCardTexts[CardNames.MASQUERADE] = "|+2 Cartas|////Cada jugador pasa una carta de su mano//al jugador de su izquierda, al mismo//tiempo. Despu\xE9s, puedes eliminar//una carta de tu mano.";
SpanishCardTexts[CardNames.MILL] = "{!1}////|+1 Carta|//|+1 Acci\xF3n|//---//Puedes descartar 2 cartas para |+|[2].";
SpanishCardTexts[CardNames.MINING_VILLAGE] = "|+1 Carta|//|+2 Acci\xF3nes|////Puedes eliminar esta carta//inmediatamente. Si lo haces, |+|[2].";
SpanishCardTexts[CardNames.MINION] = "|+1 Acci\xF3n|////Elige una opci\xF3n: |+|[2];//o bien descarta tu mano, |+4 Cartas|//y los dem\xE1s jugadores que tengan al//menos 5 cartas en su mano descartan//su mano para robar 4 cartas nuevas.";
SpanishCardTexts[CardNames.PATROL] = "|+3 Cartas|////Revela las 4 cartas superiores//de tu mazo. Pon las cartas de//Victoria y de Maldici\xF3n en tu//mano. Devuelve el resto//en cualquier orden.";
SpanishCardTexts[CardNames.PAWN] = "Elige dos opciones distintas: //|+1 Carta|; //|+1 Acci\xF3n|; //|+1 Compra|; //|+|[1].";
SpanishCardTexts[CardNames.REPLACE] = "Elimina una carta de tu mano.//Gana una carta que cueste hasta//[2] m\xE1s que la carta eliminada.//Si la carta ganada es de Acci\xF3n o//Tesoro ponla sobre tu mazo. Si es//de Victoria, cada rival//gana una Maldici\xF3n.";
SpanishCardTexts[CardNames.SECRET_PASSAGE] = "|+2 Cartas|//|+1 Acci\xF3n|////Coge una carta de tu mano y//col\xF3cala en cualquier posici\xF3n//en tu mazo.";
SpanishCardTexts[CardNames.SHANTY_TOWN] = "|+2 Acciones|////Revela toda tu mano.//Si no tienes ninguna//carta de Acci\xF3n en ella,//|+2 Cartas|.";
SpanishCardTexts[CardNames.STEWARD] = "Elige una opci\xF3n: //|+2 Cartas|; o//|+|[2]; o bien//eliminar 2 cartas de tu mano.";
SpanishCardTexts[CardNames.SWINDLER] = "|+[2]|////Cada jugador (menos t\xFA) elimina la//carta superior de su mazo y gana una//carta (que t\xFA eliges) con el mismo//coste que la carta eliminada.";
SpanishCardTexts[CardNames.TORTURER] = "|+3 Cartas|////Cada jugador (menos t\xFA)//elige una opci\xF3n://descartar dos cartas; o bien//ganar una carta de Maldici\xF3n,//poni\xE9ndola en su mano.";
SpanishCardTexts[CardNames.TRADING_POST] = "Elimina 2 cartas de tu mano.//Si lo haces, ganas una carta//de Plata, que pones en tu mano.";
SpanishCardTexts[CardNames.UPGRADE] = "|+1 Carta|//|+1 Acci\xF3n|////Elimina una carta de tu mano.//Gana una carta que cueste//exactamente [1] m\xE1s.";
SpanishCardTexts[CardNames.WISHING_WELL] = "|+1 Carta|//|+1 Acci\xF3n|////Nombra una carta.//Revela la carta superior de tu mazo.//Si se trata de la carta nombrada,//ponla en tu mano.";
SpanishCardTexts[CardNames.AMBASSADOR] = "Revela una carta de tu mano.//Devuelve hasta 2 copias de dicha//carta, de tu mano al Suministro.//Despues, cada jugador (menos t\xFA)//gana una copia de esa misma carta.";
SpanishCardTexts[CardNames.BAZAAR] = "|+1 Carta|//|+2 Acciones|//|+[1]|";
SpanishCardTexts[CardNames.CARAVAN] = "|+1 Carta|//|+1 Acci\xF3n|////Al comienzo de tu//pr\xF3ximo turno, |+1 Carta|.";
SpanishCardTexts[CardNames.CUTPURSE] = "|+[2]|////Cada jugador (menos t\xFA)//descarta un Cobre (o revela su//mano si no tiene ning\xFAn Cobre).";
SpanishCardTexts[CardNames.EMBARGO] = "|+[2]|////Elimina esta carta para//colocar un marcador de Embargo//sobre una pila del Suministro.//---//Cuando un jugador compre una carta,//gana una carta de Maldici\xF3n por cada//marcador de Embargo que tenga//su pila del Suministro.";
SpanishCardTexts[CardNames.EXPLORER] = "Puedes revelar una Provincia de//tu mano. Si lo haces, gana un Oro.//Si no lo haces, gana una Plata.//La carta ganada va directamente//un tu mano.";
SpanishCardTexts[CardNames.FISHING_VILLAGE] = "|+2 Acciones|//|+[1]|////Al comienzo de tu pr\xF3ximo turno://|+1 Acci\xF3n| y |+|[1]";
SpanishCardTexts[CardNames.GHOST_SHIP] = "|+2 Cartas|////Cada jugador (menos t\xFA) con//4 cartas en su mano, pone cartas de//su mano encima de su mazo, hasta//quedarse con 3 cartas en la mano.";
SpanishCardTexts[CardNames.HAVEN] = "|+1 Carta|//|+1 Acci\xF3n|////Aparta, boca abajo, una carta//de tu mano. Al comienzo de tu//pr\xF3ximo turno, ponla de//nuevo en tu mano.";
SpanishCardTexts[CardNames.ISLAND] = "{!2}////Aparta esta carta y otra//cualquiera de tu mano.//Devu\xE9lvelas un tu mazo al//final de la partida.//---";
SpanishCardTexts[CardNames.LIGHTHOUSE] = "|+1 Acci\xF3n|////Ahora y al comienzo//de tu pr\xF3ximo turno: |+|[1].//---//Mientras esta carta est\xE9 en juego,//cuando otro jugador juegue una//carta de Ataque, \xE9ste no te afectar\xE1.";
SpanishCardTexts[CardNames.LOOKOUT] = "|+1 Acci\xF3n|////Mira las 3 cartas superiores//de tu mazo: elimina una de ellas,//descarta otra y coloca la tercera//encima de tu mazo.";
SpanishCardTexts[CardNames.MERCHANT_SHIP] = "Ahora y al comienzo//de tu pr\xF3ximo turno: |+|[2].";
SpanishCardTexts[CardNames.NATIVE_VILLAGE] = "|+2 Acciones|////Elige una opci\xF3n: aparta, boca abajo, sobre tu//tablero de Aldea Ind\xEDgena, la carta superior//de tu mazo; o bien coloca en tu mano todas las//cartas que haya en dicho tablero.//Puedes mirar, en cualquier momento, las cartas//que hay en tu tablero de Aldea Ind\xEDgena. Al final//de la partida, devuelve todas cartas un tu mazo.";
SpanishCardTexts[CardNames.NAVIGATOR] = "|+[2]|////Mira las 5 cartas superiores de tu//mazo. Elige uno: descartar las//5 cartas; o devolverlas un tu mazo,//en el orden que quieras.";
SpanishCardTexts[CardNames.OUTPOST] = "En la Fase de Mantenimiento de//este turno, roba s\xF3lo 3 cartas//(en vez de 5).//A continuaci\xF3n, haz un turno adicional//(pero nunca puedes hacer m\xE1s//de dos turnos consecutivos).";
SpanishCardTexts[CardNames.PEARL_DIVER] = "|+1 Carta|//|+1 Acci\xF3n|////Mira la \xFAltima carta de tu mazo.//Puedes ponerla encima del mazo.";
SpanishCardTexts[CardNames.PIRATE_SHIP] = "Elige uno: |+|[1] por cada marcador//de Moneda en su tablero de Barca Pirata//o cada jugador revela las 2 cartas superiores//de su mazo, elimina el Tesoro revelado//que t\xFA decidas, descarta las dem\xE1s cartas//reveladas y t\xFA ganas un marcador de Moneda//un su tablero de Barco Pirata (si alguien ha//eliminado alg\xFAn Tesoro).";
SpanishCardTexts[CardNames.SALVAGER] = "|+1 Compra|////Elimina una carta de tu mano.//|+|[1] igual as coste de la carta//eliminada.";
SpanishCardTexts[CardNames.SEA_HAG] = "Cada jugador (menos t\xFA) descarta//la carta superior de su mazo,//gana una carta de Maldici\xF3n//y la coloca encima de su mazo.";
SpanishCardTexts[CardNames.SMUGGLERS] = "Gana una copia de una de las cartas//que el jugador de tu derecha haya//ganada en su \xFAltimo turno,//con un coste m\xE1ximo de [6].";
SpanishCardTexts[CardNames.TACTICIAN] = "Descarta todo tu mano.//Si descarta alguna carta, al//comienzo de tu pr\xF3ximo turno://|+5 Cartas|, |+1 Acci\xF3n|,//|+1 Compra|.";
SpanishCardTexts[CardNames.TREASURE_MAP] = "Elimina esta carta y otro Mapa//del Tesoro de tu mano. Si lo//haces, ganas 4 cartas de Oro,//encima de tu mazo.";
SpanishCardTexts[CardNames.TREASURY] = "|+1 Carta|//|+1 Acci\xF3n|//|+[1]|//---//Al descartar esta carta de tu \xE1rea//de juego, si no has comprado ninguna//carta de Victoria en este turno, puedes//poner esta carta encima de tu mazo.";
SpanishCardTexts[CardNames.WAREHOUSE] = "|+3 Cartas|//|+1 Acci\xF3n|////Descarta 3 cartas.";
SpanishCardTexts[CardNames.WHARF] = "Ahora y al comienzo//de tu pr\xF3ximo turno://|+2 Cartas |y |+1 Compra|.";
SpanishCardTexts[CardNames.ALCHEMIST] = "|+2 Cartas|//|+1 Acci\xF3n|//---//Cuando descartes esta carta//del \xE1rea de juego, si tienes//una Poci\xF3n en juego, puedes//poner esta carta sobre tu mazo.";
SpanishCardTexts[CardNames.APOTHECARY] = "|+1 Carta|//|+1 Acci\xF3n|////Revela las 4 cartas superiores//de tu mazo. Pon los Cobres y//Pociones en tu mano. Devuelve//el resto en cualquier orden.";
SpanishCardTexts[CardNames.APPRENTICE] = "|+1 Acci\xF3n|////Elimina una carta de tu mano.//|+1 Carta| por cada [] que cueste.//|+2 Cartas| si incluye [P] en su coste.";
SpanishCardTexts[CardNames.FAMILIAR] = "|+1 Carta|//|+1 Acci\xF3n|////Cada rival gana//una Maldici\xF3n.";
SpanishCardTexts[CardNames.GOLEM] = "Revela cartas de tu mazo hasta//revelar 2 cartas de Acci\xF3n que//no sean Golems. Descarta el//resto, y luego, juega las cartas//de Acci\xF3n en cualquier orden.";
SpanishCardTexts[CardNames.HERBALIST] = "|+1 Compra|//|+[1]|//---//Cuando descartes esta carta//del \xE1rea de juego, puedes//poner uno de tus Tesoros//en juego sobre tu mazo.";
SpanishCardTexts[CardNames.PHILOSOPHERS_STONE] = "Al jugar esta carta, cuenta//tu mazo y pila de descartes.//Vale [1] por cada 5 cartas//que contengan en total//(redondeando hacia abajo).";
SpanishCardTexts[CardNames.POSSESSION] = "El rival de tu izquierda juega un//turno adicional despu\xE9s de este,//en el que ver\xE1s cualquier carta que vea//y tomar\xE1s todas sus decisiones.//Cualquier carta o [D] que gane, la ganas//t\xFA en su lugar; cualquiera de sus cartas//que sea eliminada, es apartada y devuelta//a su pila de descartes al final del turno.";
SpanishCardTexts[CardNames.POTION] = "";
SpanishCardTexts[CardNames.SCRYING_POOL] = "|+1 Acci\xF3n|////Cada rival revela la carta superior//de su mazo y, o bien la descarta,//o la devuelve al mazo, t\xFA eliges.//Luego, revela cartas de tu mazo hasta//revelar una que no sea Acci\xF3n. Pon//todas las cartas reveladas en tu mano.";
SpanishCardTexts[CardNames.TRANSMUTE] = "Elimina una carta de tu mano.//Si es una carta de...//Acci\xF3n, gana un Ducado//Tesoro, gana un Transmutar//Victoria, gana un Oro";
SpanishCardTexts[CardNames.UNIVERSITY] = "|+2 Acciones|////Puedes ganar una carta de//Acci\xF3n que cueste hasta [5].";
SpanishCardTexts[CardNames.VINEYARD] = "Vale {1} por cada 3//cartas de Acci\xF3n que tengas//(redondeando hacia abajo).";
SpanishCardTexts[CardNames.BANK] = "|+|[1] por cada Tesoro que//tengas en juego (contando este).";
SpanishCardTexts[CardNames.BISHOP] = "|+{1}|//|+[1]|////Elimina una carta de tu mano.//|+|{1} por cada [2] que cueste//(redondeando hacia abajo).////Cada rival puede eliminar//una carta de su mano.";
SpanishCardTexts[CardNames.COLONY] = "";
SpanishCardTexts[CardNames.CONTRABAND] = "[!3]////|+1 Compra|////El rival de tu izquierda nombra//una carta. No puedes comprarla//este turno.";
SpanishCardTexts[CardNames.COUNTING_HOUSE] = "Mira en tu pila de descartes,//revela cualquier n\xFAmero//de Cobres de ella,//y ponlos en tu mano.";
SpanishCardTexts[CardNames.CITY] = "|+1 Carta|//|+2 Acciones|////Si hay una o m\xE1s pilas de//Suministro vac\xEDas, |+1 Carta|.//Si hay dos o m\xE1s,//|+1 Compra| y |+|[1].";
SpanishCardTexts[CardNames.EXPAND] = "Elimina una carta de tu mano.//Gana una carta que cueste//hasta [3] m\xE1s que ella.";
SpanishCardTexts[CardNames.FORGE] = "Elimina cualquier n\xFAmero//de cartas de tu mano. Gana una//carta que cueste exactamente//igual que el coste total en []//de las cartas eliminadas.";
SpanishCardTexts[CardNames.GRAND_MARKET] = "|+1 Carta|//|+1 Acci\xF3n|//|+1 Compra|//|+[2]|//---//No puedes comprar esta carta //si tienes alg\xFAn Cobre en juego.";
SpanishCardTexts[CardNames.GOONS] = "|+1 Compra|//|+[2]|////Cada rival se descarta hasta//tener 3 cartas en la mano.//---//Mientras que tienes este en juego,//cuando compres una carta, |+|{1}.";
SpanishCardTexts[CardNames.HOARD] = "[!2]//---//Mientras que tienes este en//juego, cuando compres una//carta de Victoria, gana un Oro.";
SpanishCardTexts[CardNames.KINGS_COURT] = "Puedes jugar una carta de//Acci\xF3n de tu mano tres veces.";
SpanishCardTexts[CardNames.LOAN] = "[!1]////Revela cartas de tu mazo//hasta revelar un Tesoro.//Desc\xE1rtalo o elim\xEDnalo.//Descarta las otras cartas.";
SpanishCardTexts[CardNames.MINT] = "Puedes revelar un Tesoro de//tu mano. Gana una copia.//---//Cuando compres esta carta,//elimina todos los Tesoros//que tengas en juego.";
SpanishCardTexts[CardNames.MONUMENT] = "|+{1}|//|+[2]|";
SpanishCardTexts[CardNames.MOUNTEBANK] = "|+[2]|////Cada rival puede descartar//una Maldici\xF3n. Si no lo hace,//gana una Maldici\xF3n y un Cobre.";
SpanishCardTexts[CardNames.PEDDLER] = "|+1 Carta|//|+1 Acci\xF3n|//|+[1]|//---//Durante el fase de Compra,//cuesta [2] menos por carta de//Acci\xF3n que tengas en juego.";
SpanishCardTexts[CardNames.PLATINUM] = "";
SpanishCardTexts[CardNames.QUARRY] = "[2]//---//Mientras est\xE9 en juego, las cartas//de Acci\xF3n cuestan [!1] menos";
SpanishCardTexts[CardNames.RABBLE] = "|+3 Cartas|////Cada rival revela las 3 cartas//superiores de su mazo,//descarta las Acciones y//Tesoros, y devuelve el resto//en el orden que quiera.";
SpanishCardTexts[CardNames.ROYAL_SEAL] = "[!2]//---//Mientras que tienes este en//juego, cuando ganes una carta,//puedes ponerla sobre tu mazo.";
SpanishCardTexts[CardNames.TALISMAN] = "[4]//---//Mientras que tienes este en juego, //cuando compres una carta//(que no sea Victoria) que cueste//[!1] o menos, gana una copia.";
SpanishCardTexts[CardNames.TRADE_ROUTE] = "|+1 Compra|////Elimina una carta de tu mano.//|+|[1] por cada ficha en el//tablero de Ruta Comercial.//---//Preparaci\xF3n: a\xF1ade una ficha a cada//pila de Victoria del Suministro;//mu\xE9vela al tablero de Ruta Comercial//cuando se gane una carta de esa pila.";
SpanishCardTexts[CardNames.VAULT] = "|+2 Cartas|////Descarta cualquier n\xFAmero //de cartas, para |+|[1] por carta.//Cada rival puede descartar//2 cartas para robar una.";
SpanishCardTexts[CardNames.VENTURE] = "[!1]////Revela cartas de tu mazo hasta//revelar un Tesoro. Descarta el//resto. Luego, juega el Tesoro.";
SpanishCardTexts[CardNames.WATCHTOWER] = "Roba hasta tener//6 cartas en la mano.//---//Cuando ganes una carta,//puedes revelar esto de tu//mano, para eliminar esa carta//o ponerla sobre tu mazo.";
SpanishCardTexts[CardNames.WORKERS_VILLAGE] = "|+1 Carta|//|+2 Acciones|//|+1 Compra|";
SpanishCardTexts[CardNames.PRIZE_PILE] = "";
SpanishCardTexts[CardNames.BAG_OF_GOLD] = "|+1 Acci\xF3n|////Gana un Oro sobre tu mazo.//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.DIADEM] = "[1]////Al jugar esta carta,| +|[!2]//por cada Acci\xF3n que tengas//(Acci\xF3n, no carta de Acci\xF3n).//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.FAIRGROUNDS] = "Vale {2} por cada 5 cartas de//distinto nombre que tengas//(redondeando hacia abajo).";
SpanishCardTexts[CardNames.FARMING_VILLAGE] = "|+2 Acciones|////Revela cartas de tu mazo//hasta revelar un Tesoro o//carta de Acci\xF3n. Ponla en//tu mano y descarta el resto.";
SpanishCardTexts[CardNames.FOLLOWERS] = "|+2 Cartas|////Gana un Finca. Cada//rival gana una Maldici\xF3n//y se descarta hasta tener//3 cartas en la mano.//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.FORTUNE_TELLER] = "|+[2]|//////Cada rival revela cartas de//la parte superior de su mazo//hasta revelar un carta de Victoria//o una Maldici\xF3n. La pone sobre//el mazo y descarta el resto.";
SpanishCardTexts[CardNames.HAMLET] = "|+1 Carta|//|+1 Acci\xF3n|////Puedes descartar una carta//para |+1 Acci\xF3n|.//Puedes descartar una carta //para |+1 Compra|.";
SpanishCardTexts[CardNames.HARVEST] = "Revela las 4 cartas superiores//de tu mazo. Luego, desc\xE1rtalas.//|+|[1] por cada carta revelada//con distinto nombre.";
SpanishCardTexts[CardNames.HORSE_TRADERS] = "|+1 Compra|//|+[3]|////Descarta 2 cartas.//---//Cuando un rival juegue un Ataque,//puedes primero apartar esta carta//de tu mano. Si lo haces, al inicio//de tu pr\xF3ximo turno, +|1 Carta|//y devuelves esta a tu mano.";
SpanishCardTexts[CardNames.HORN_OF_PLENTY] = "[1]////Al jugar esta carta, gana una//carta que cueste hasta [!0] por //cada carta de distinto nombre que//tengas en juego (contando esta).//Si es de Victoria, elimina esta.";
SpanishCardTexts[CardNames.HUNTING_PARTY] = "|+1 Carta|//|+1 Acci\xF3n|////Revela tu mano. Revela cartas//de tu mazo hasta revelar una//que no sea copia de una //en tu mano. Ponla en tu mano//y descarta el resto.";
SpanishCardTexts[CardNames.JESTER] = "|+[2]|////Cada rival descarta la carta superior//de su mazo. Si es de Victoria, el rival//gana una Maldici\xF3n; si no lo es,//gana una copia de la carta descartada//o lo haces t\xFA, t\xFA eliges.";
SpanishCardTexts[CardNames.MENAGERIE] = "|+1 Acci\xF3n|////Revela tu mano. Si las //cartas reveladas tienen todas//diferente nombre, |+3 Cartas|.//En caso contrario, |+1 Carta.|";
SpanishCardTexts[CardNames.PRINCESS] = "|+1 Compra|//---//Mientras est\xE9 en juego,//las cartas cuestan [2] menos.//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.REMAKE] = "Haz esto dos veces: elimina//una carta de tu mano, luego,//gana una carta que cueste//exactamente [1] m\xE1s que ella.";
SpanishCardTexts[CardNames.TOURNAMENT] = "|+1 Acci\xF3n|////Cada jugador puede revelar una//Provincia de su mano. Si t\xFA lo haces,//desc\xE1rtala y gana cualquier Premio//(de la pila de Premios) o un Ducado,//sobre tu mazo. Si ning\xFAn rival revela//una Provincia |+1 Carta| y |+|[1].";
SpanishCardTexts[CardNames.TRUSTY_STEED] = "Elige dos diferentes://|+2 Cartas|; |+2 Acciones|; //|+|[2]; gana 4 Platas y pon tu//mazo en tu pila de descartes.//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.YOUNG_WITCH] = "|+2 Cartas|////Descarta 2 cartas. Cada rival puede//revelar una Perdici\xF3n de su mano;//si no lo hace, gana una Maldici\xF3n.//---//Preparaci\xF3n: a\xF1ade una pila de Reino//m\xE1s al Suministro, que cueste [2] o [3].//Las cartas de esa pila son Perdiciones.";
SpanishCardTexts[CardNames.BORDER_VILLAGE] = "|+1 Carta|//|+2 Acciones|//---//Cuando ganes esta carta,//gana una carta m\xE1s barata.";
SpanishCardTexts[CardNames.CACHE] = "[!3]//---//Cuando ganes esta carta,//gana 2 Cobres.";
SpanishCardTexts[CardNames.CARTOGRAPHER] = "|+1 Carta|//|+1 Acci\xF3n|////Mira las 4 cartas superiores//de tu mazo. Descarta las que//quieras, y luego, devuelve//el resto en cualquier orden.";
SpanishCardTexts[CardNames.CROSSROADS] = "Revela tu mano.| +1 Carta|//por carta de Victoria revelada.//Si es la primera vez que juegas//una Encrucijada este turno,//| +3 Acciones|.";
SpanishCardTexts[CardNames.DEVELOP] = "Elimina una carta de tu mano.//Gana dos cartas sobre tu mazo,//una que cueste exactamente//[1] m\xE1s que ella, y otra//que cueste exactamente [1]//menos, en cualquier orden.";
SpanishCardTexts[CardNames.DUCHESS] = "|+[2]|////Cada jugador (incluido t\xFA) mira//la carta superior de su mazo//y puede descartarla.//---//En partidas con esta carta,//cuando ganes un Ducado,//puedes ganar una Duquesa.";
SpanishCardTexts[CardNames.EMBASSY] = "|+5 Cartas|////Descarta 3 cartas.//---//Cuando ganes esta carta,//cada rival gana una Plata.";
SpanishCardTexts[CardNames.FARMLAND] = "[2]//---//Cuando compres esta carta, elimina//una carta de tu mano y gana una carta//que cueste exactamente {!2} m\xE1s.";
SpanishCardTexts[CardNames.FOOLS_GOLD] = "Vale [1] si es la primera vez//que juegas un Oro Falso este//turno, en caso contrario, vale [4].//---//Cuando un rival gane una//Provincia, puedes eliminar esto//de tu mano, para ganar un Oro//sobre tu mazo.";
SpanishCardTexts[CardNames.HAGGLER] = "|+[2]|//---//Mientras est\xE9 en juego,//cuando compres una carta,//gana una carta m\xE1s barata//(que no sea de Victoria).";
SpanishCardTexts[CardNames.HIGHWAY] = "|+1 Carta|//|+1 Acci\xF3n|//---//Mientras est\xE9 en juego,//las cartas cuestan [1] menos.";
SpanishCardTexts[CardNames.ILL_GOTTEN_GAINS] = "[!1]////Puedes ganar un Cobre a tu mano.//---//Cuando la ganes,//cada rival gana una Maldici\xF3n.";
SpanishCardTexts[CardNames.INN] = "|+2 Cartas|//|+2 Acciones|////Descarta 2 cartas.//---//Cuando ganes esta carta, mira en tu pila//de descartes, revela cualquier n\xFAmero de//cartas de Acci\xF3n de ella (puedes incluir//esta), y bar\xE1jalas con tu mazo.";
SpanishCardTexts[CardNames.JACK_OF_ALL_TRADES] = "Gana una Plata.////Mira la carta superior de tu//mazo; puedes descartarla.////Roba hasta tener//5 cartas en la mano.////Puedes eliminar de tu mano//una carta que no sea Tesoro.";
SpanishCardTexts[CardNames.MANDARIN] = "|+[3]|////Pon una carta de tu mano//sobre tu mazo.//---//Cuando ganes esta carta, pon todos//los Tesoros que tengas en juego//sobre tu mazo en cualquier orden.";
SpanishCardTexts[CardNames.NOBLE_BRIGAND] = "|+[1]|////Cada rival revela las 2 cartas//superiores de su mazo, elimina una//Plata u Oro revelado a tu elecci\xF3n,//descarta el resto, y gana un Cobre//si no revel\xF3 ning\xFAn Tesoro.//T\xFA ganas las cartas eliminadas.//Cuando compras esto, haz su Ataque.";
SpanishCardTexts[CardNames.NOMAD_CAMP] = "---//|+1 Compra|//|+[2]|////Esta carta se gana sobre tu mazo//(en lugar de a tu pila de descartes).";
SpanishCardTexts[CardNames.OASIS] = "|+1 Carta|//|+1 Acci\xF3n|//|+[1]|////Descarta una carta.";
SpanishCardTexts[CardNames.ORACLE] = "Cada jugador (t\xFA incluido)//revela las 2 cartas superiores//de su mazo y las descarta o//las devuelve al mazo, t\xFA eliges.//Cada jugador elige el orden al//devolverlas. Luego, |+2 Cartas.|";
SpanishCardTexts[CardNames.MARGRAVE] = "|+3 Cartas|//|+1 Compra|////Cada rival roba una carta.//Luego, se descarta hasta//tener 3 cartas en la mano.";
SpanishCardTexts[CardNames.SCHEME] = "|+1 Carta|//|+1 Acci\xF3n|////Este turno, puedes poner una//de tu cartas de Acci\xF3n sobre//tu mazo cuando la descartes//del \xE1rea de juego.";
SpanishCardTexts[CardNames.SILK_ROAD] = "Vale {1} por cada 4 cartas //de Victoria que tengas//(redondeando hacia abajo).";
SpanishCardTexts[CardNames.SPICE_MERCHANT] = "Puedes eliminar un Tesoro//de tu mano para elegir una://|+2 Cartas| y| +1 Acci\xF3n|;//o| +1 Compra |y| +|[2].";
SpanishCardTexts[CardNames.STABLES] = "Puedes descartar un Tesoro//para |+3 Cartas| y| +1 Acci\xF3n|.";
SpanishCardTexts[CardNames.TRADER] = "Elimina una carta de tu mano.//Gana una Plata por [1] de coste.//---//Cuando ganes una carta,//puedes revelar esta de tu mano//para, en su lugar, ganar una Plata.";
SpanishCardTexts[CardNames.TUNNEL] = "{!2}//---//Cuando lo descartes fuera de la//fase de Mantenimiento, puedes//revelarlo para ganar un Oro.";
SpanishCardTexts[CardNames.RUIN_PILE] = "";
SpanishCardTexts[CardNames.KNIGHTS] = "Baraja la pila de Caballeros//antes de usarla en una partida.//Mant\xE9nlas bocabajo, excepto//la carta superior, que es//la \xFAnica que puede ser //comprada o ganada.";
SpanishCardTexts[CardNames.ABANDONED_MINE] = "|+|[1]";
SpanishCardTexts[CardNames.ALTAR] = "Elimina una carta de tu mano.//Gana una carta//que cueste hasta [5].";
SpanishCardTexts[CardNames.ARMORY] = "Gana una carta sobre tu mazo//que cueste hasta [4].";
SpanishCardTexts[CardNames.BAND_OF_MISFITS] = "Juega una carta de Acci\xF3n del//Suministro (que no sea Mandato)//que cueste menos que esta carta,//sin moverla.";
SpanishCardTexts[CardNames.BANDIT_CAMP] = "|+1 Carta|//|+2 Acciones|////Gana un Bot\xEDn.";
SpanishCardTexts[CardNames.BEGGAR] = "Gana 3 Cobres a tu mano.//---//Cuando un rival juegue un//Ataque, puedes primero descartar//esta carta para ganar 2 Platas,//poniendo una sobre tu mazo.";
SpanishCardTexts[CardNames.CATACOMBS] = "Mira las 3 cartas superiores//de tu mazo. Elige una://ponlas en tu mano;//o desc\xE1rtalas y |+3 Cartas|.//---//Cuando elimines esta carta, //gana una carta m\xE1s barata.";
SpanishCardTexts[CardNames.COUNT] = "Elige una: descarta 2 cartas; o//pon una carta de tu mano sobre//tu mazo; o gana un Cobre.////Elige una: |+|[3]; o elimina tu//mano; o gana un Ducado.";
SpanishCardTexts[CardNames.COUNTERFEIT] = "[!1]////|+1 Compra|////Al jugar esta carta, puedes jugar//un Tesoro de tu mano dos veces.//Si lo haces, elimina ese Tesoro.";
SpanishCardTexts[CardNames.CULTIST] = "|+2 Cartas|////Cada rival gana una Ruina.//Puedes jugar un Sectario//de tu mano.//---//Al eliminarlo, |+3 Cartas|.";
SpanishCardTexts[CardNames.DAME_ANNA] = "Puedes eliminar hasta 2 cartas//de tu mano. Cada rival revela las//2 cartas superiores de su mazo,//elimina una que cueste entre//[3] y [6], y descarta el resto.//Si otro Caballero es eliminado//de este modo, elimina este.";
SpanishCardTexts[CardNames.DAME_JOSEPHINE] = "{!2}//---//Cada rival revela las 2 cartas//superiores de su mazo, elimina una//que cueste entre [3] y [6], y descarta//el resto. Si otro Caballero es//eliminado de este modo, elimina este.";
SpanishCardTexts[CardNames.DAME_MOLLY] = "|+2 Acciones|////Cada rival revela las 2 cartas//superiores de su mazo, elimina//una que cueste entre [3] y [6],//y descarta el resto.//Si otro Caballero es eliminado//de este modo, elimina este.";
SpanishCardTexts[CardNames.DAME_NATALIE] = "Puedes ganar una carta que//cueste hasta [3]. Cada rival revela// las 2 cartas superiores de su mazo,//elimina una que cueste entre//[3] y [6], y descarta el resto.//Si otro Caballero es eliminado//de este modo, elimina este.";
SpanishCardTexts[CardNames.DAME_SYLVIA] = "|+[2]|////Cada rival revela las 2 cartas//superiores de su mazo, elimina//una que cueste entre [3] y [6],//y descarta el resto.//Si otro Caballero es eliminado//de este modo, elimina este.";
SpanishCardTexts[CardNames.DEATH_CART] = "Puedes eliminar esta o//una carta de Acci\xF3n de//tu mano, para |+|[5].//---//Cuando ganes esta carta,//gana 2 Ruinas.";
SpanishCardTexts[CardNames.FEODUM] = "Vale {1} por cada//tres Platas que tengas//(redondeando hacia abajo).//---//Cuando elimines esta carta,//gana 3 Platas.";
SpanishCardTexts[CardNames.FORAGER] = "|+1 Acci\xF3n|//|+1 Compra|////Elimina una carta de tu mano,//luego, |+|[1] por cada Tesoro//de diferente nombre entre//las cartas eliminadas.";
SpanishCardTexts[CardNames.FORTRESS] = "|+1 Carta|//|+2 Acciones|//---//Cuando elimines esta carta,//ponla en tu mano.";
SpanishCardTexts[CardNames.GRAVEROBBER] = "Elige una: gana una carta eliminada//que cueste entre [3] y [6], sobre//tu mazo; o elimina una carta de//Acci\xF3n de tu mano y gana una carta//que cueste hasta [3] m\xE1s que ella.";
SpanishCardTexts[CardNames.HERMIT] = "Mira en tu pila de descartes.//Puedes eliminar una carta que no//sea Tesoro de ah\xED o de tu mano.//Gana una carta que cueste hasta [3].//---//Al descartarlo del \xE1rea de juego,//si no compraste ninguna carta este//turno, elim\xEDnalo y gana un Loco";
SpanishCardTexts[CardNames.HOVEL] = "Cuando compres una carta//de Victoria, puedes eliminar//esta de tu mano."; // TRANSLATE, english text changed "When you buy..." to "When you gain..."
SpanishCardTexts[CardNames.HUNTING_GROUNDS] = "|+4 Cartas|//---//Cuando elimines esta carta,//gana un Ducado o 3 Fincas.";
SpanishCardTexts[CardNames.IRONMONGER] = "|+1 Carta|//|+1 Acci\xF3n|//Revela la carta superior de tu mazo;// puedes descartarla. En cualquier caso,//si es una carta de...//Acci\xF3n,|[1]+1 Acci\xF3n|//Tesoro,|+|//Victoria,|+1 Carta|";
SpanishCardTexts[CardNames.JUNK_DEALER] = "|+1 Carta|//|+1 Acci\xF3n|//|+[1]|////Elimina una carta de tu mano.";
SpanishCardTexts[CardNames.MADMAN] = "|+2 Acciones|////Devu\xE9lvelo a la pila de Locos.//Si lo haces, |+1 Carta|//por cada carta en tu mano.//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.MARKET_SQUARE] = "|+1 Carta|//|+1 Acci\xF3n|//|+1 Compra|//---//Cuando una de tus cartas es//eliminada, puedes descartar esta//de tu mano para ganar un Oro.";
SpanishCardTexts[CardNames.MARAUDER] = "Gana un Bot\xEDn. Cada rival//gana una Ruina.";
SpanishCardTexts[CardNames.MERCENARY] = "Puedes eliminar 2 cartas de tu//mano. Si lo hiciste, |+2 Cartas|,//|+|[2], y cada rival se descarta//hasta tener 3 cartas en la mano.//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.MYSTIC] = "|+1 Acci\xF3n|//|+[2]|////Nombra una carta, luego,//revela la carta superior de//tu mazo. Si es la nombrada,//ponla un tu mano.";
SpanishCardTexts[CardNames.NECROPOLIS] = "|+2 Acciones|";
SpanishCardTexts[CardNames.OVERGROWN_ESTATE] = "{!0}//---//Cuando elimines esta carta, //|+1 Carta|.";
SpanishCardTexts[CardNames.PILLAGE] = "Elimina esta carta. Si lo hiciste,//gana 2 Botines, y cada rival//con 5 o m\xE1s cartas en la mano//revela su mano y descarta//una carta que t\xFA elijas.";
SpanishCardTexts[CardNames.POOR_HOUSE] = "|+[4]|////Revela tu mano. -[1] por//cada Tesoro en tu mano//(no puede bajar de [0]).";
SpanishCardTexts[CardNames.PROCESSION] = "Puedes jugar dos veces una carta//de Acci\xF3n que no sea Duraci\xF3n//de tu mano. Elim\xEDnala. Gana//una carta de Acci\xF3n que cueste//exactamente [1] m\xE1s que ella.";
SpanishCardTexts[CardNames.RATS] = "|+1 Carta|//|+1 Acci\xF3n|////Gana unas Ratas. Elimina una//carta de tu mano que no sea Ratas//(o revela un mano llena de Ratas).//---//Cuando elimines esto, |+1 Carta|.";
SpanishCardTexts[CardNames.REBUILD] = "|+1 Acci\xF3n|////Nombra una carta. Revela cartas//de tu mazo hasta revelar una carta//de Victoria distinta a la nombrada.//Descarta el resto, elimina esa//carta, y gana una carta de Victoria//que cueste hasta [3] m\xE1s que ella.";
SpanishCardTexts[CardNames.ROGUE] = "|+[2]|////Si hay cartas eliminadas que//cuesten entre [3] y [6], gana una de//ellas. En caso contrario, cada rival//revela las 2 cartas superiores de su//mazo, elimina una que cueste//entre [3] y [6], y descarta el resto.";
SpanishCardTexts[CardNames.RUINED_LIBRARY] = "|+1 Carta|";
SpanishCardTexts[CardNames.RUINED_MARKET] = "|+1 Compra|";
SpanishCardTexts[CardNames.RUINED_VILLAGE] = "|+1 Acci\xF3n|";
SpanishCardTexts[CardNames.SAGE] = "|+1 Acci\xF3n|////Revela cartas de tu mazo hasta//revelar una que cueste [3]//o m\xE1s. Pon esa carta en tu//mano y descarta el resto.";
SpanishCardTexts[CardNames.SCAVENGER] = "|+[2]|////Puedes poner tu mazo//en tu pila de descartes.//Pon una carta de tu pila//de descartes sobre tu mazo.";
SpanishCardTexts[CardNames.SIR_BAILEY] = "|+1 Carta|//|+1 Acci\xF3n|////Cada rival revela las 2 cartas//superiores de su mazo, elimina//una que cueste entre [3] y [6], y//descarta el resto. Si otro Caballero es//eliminado de este modo, elimina este.";
SpanishCardTexts[CardNames.SIR_DESTRY] = "|+2 Cartas|////Cada rival revela las 2 cartas//superiores de su mazo, elimina//una que cueste entre [3] y [6], y//descarta el resto. Si otro Caballero es//eliminado de este modo, elimina este.";
SpanishCardTexts[CardNames.SIR_MARTIN] = "|+2 Compras|////Cada rival revela las 2 cartas//superiores de su mazo, elimina//una que cueste entre [3] y [6], y//descarta el resto. Si otro Caballero es//eliminado de este modo, elimina este.";
SpanishCardTexts[CardNames.SIR_MICHAEL] = "Cada rival se descarta hasta//tener 3 cartas en la mano.//Cada rival revela las 2 cartas//superiores de su mazo, elimina//una que cueste entre [3] y [6], y//descarta el resto. Si otro Caballero es//eliminado de este modo, elimina este.";
SpanishCardTexts[CardNames.SIR_VANDER] = "Cada rival revela las 2 cartas//superiores de su mazo, elimina//una que cueste entre [3] y [6], y//descarta el resto. Si otro Caballero es//eliminado de este modo, elimina este.//---//Cuando elimines esto, gana un Oro.";
SpanishCardTexts[CardNames.SPOILS] = "[!3]////Cuando juegues esta carta,//devu\xE9lvela a la pila de Botines.//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.STOREROOM] = "|+1 Compra|////Descarta cualquier n\xFAmero de cartas,//luego, roba esa misma cantidad.//Descarta cualquier n\xFAmero de//cartas para +[1] por carta.";
SpanishCardTexts[CardNames.SQUIRE] = "|+[1]|////Elige una: |+2 Acciones|; //|+2 Compras|; o gana una Plata.//---//Cuando elimines esta carta,//gana una carta de Ataque.";
SpanishCardTexts[CardNames.SURVIVORS] = "Mira las 2 cartas superiores//de tu mazo. Desc\xE1rtalas o//devu\xE9lvelas en cualquier orden.";
SpanishCardTexts[CardNames.URCHIN] = "|+1 Carta|//|+1 Acci\xF3n|////Cada rival se descarta hasta//tener 4 cartas en la mano.//---//Cuando juegues otro Ataque con este//en juego, puedes primero eliminar//este, para ganar un Mercenario.";
SpanishCardTexts[CardNames.VAGRANT] = "|+1 Carta|//|+1 Acci\xF3n|////Revela la carta superior de tu//mazo. Si es una Maldici\xF3n,//Ruina, Cobijo, o Victoria,//ponla en tu mano.";
SpanishCardTexts[CardNames.WANDERING_MINSTREL] = "|+1 Carta|//|+2 Acciones|////Revela las 3 cartas superiores//de tu mazo. Devuelve las cartas//de Acci\xF3n en cualquier orden y//descarta el resto.";
SpanishCardTexts[CardNames.ADVISOR] = "|+1 Acci\xF3n|////Revela las 3 cartas//superiores de tu mazo.//El jugador de tu izquierda//elige una. Desc\xE1rtala y pon//el resto en tu mano.";
SpanishCardTexts[CardNames.BAKER] = "|+1 Carta|//|+1 Acci\xF3n|//|+1 Cofre|//---//Preparaci\xF3n: cada jugador//recibe |+1 Cofre|.";
SpanishCardTexts[CardNames.BUTCHER] = "|+2 Cofres|////Puedes eliminar una carta de tu//mano. Si lo haces, quita cualquier//n\xFAmero de fichas de tus Cofres//y gana una carta que cueste hasta//el coste de la carta eliminada//m\xE1s [1] por ficha quitada.";
SpanishCardTexts[CardNames.CANDLESTICK_MAKER] = "|+1 Acci\xF3n|//|+1 Compra|//|+1 Cofre|";
SpanishCardTexts[CardNames.DOCTOR] = "Nombra una carta. Revela 3 cartas//del mazo. Elimina las que coincidan//y devuelve el resto en cualquier orden.//---//Cuando lo compres, puedes pagar de m\xE1s.//Por cada [1] de sobrecoste mira la carta//superior de tu mazo; elim\xEDnala, desc\xE1rtala//o vuelve un ponerla sobre el mazo.";
SpanishCardTexts[CardNames.HERALD] = "|+1 Carta|//|+1 Acci\xF3n|////Revela la carta superior de tu mazo.//Si es una Acci\xF3n, ju\xE9gala.//---//Cuando lo compres, puedes//pagar de m\xE1s. Por cada [1]//de sobrecoste, pon una carta de//tu pila de descartes sobre tu mazo.";
SpanishCardTexts[CardNames.JOURNEYMAN] = "Nombra una carta. Revela//cartas de tu mazo hasta que//reveles 3 sin ese nombre.//Pon esas cartas en tu mano//y descarta el resto.";
SpanishCardTexts[CardNames.MASTERPIECE] = "[1]//---//Cuando lo compres, puedes//pagar de m\xE1s. Por cada [!1] de//sobrecoste, gana una Plata.";
SpanishCardTexts[CardNames.MERCHANT_GUILD] = "|+1 Compra|//---//|+[1]|////Mientras est\xE9 en juego, cuando//compres una carta, |+1 Cofre|."; // TRANSLATE , Coffers are now only given at end of buy phase
SpanishCardTexts[CardNames.PLAZA] = "|+1 Carta|//|+2 Acciones|////Puedes descartar un Tesoro//para |+1 Cofre|.";
SpanishCardTexts[CardNames.TAXMAN] = "Puedes eliminar un Tesoro de//tu mano. Cada rival con 5 o m\xE1s//cartas en la mano descarta una//copia (o revela su mano sin ella).//Gana un Tesoro sobre tu mazo//que cueste hasta [3] m\xE1s//que la carta eliminada.";
SpanishCardTexts[CardNames.SOOTHSAYER] = "Gana un Oro.//Cada rival gana una Maldici\xF3n,//y si lo hace, roba una carta.";
SpanishCardTexts[CardNames.STONEMASON] = "Elimina una carta de tu mano.//Gana 2 cartas que cuesten//menos que ella.//---//Cuando lo compres, puedes//pagar de m\xE1s. Si lo haces, gana//2 cartas de Acci\xF3n que cuesten//el sobrecoste que pagaste.";
SpanishCardTexts[CardNames.ALMS] = "Una vez por turno: si no tienes Tesoros en juego,//gana una carta que cueste hasta [4].";
SpanishCardTexts[CardNames.AMULET] = "Ahora y al incio de tu//pr\xF3ximo turno, elige una: |+|[1];//o elimina una carta de tu mano;//o gana una Plata.";
SpanishCardTexts[CardNames.ARTIFICER] = "|+1 Carta|//|+1 Acci\xF3n|//|+[1]|////Descarta un n\xFAmero de cartas.//Puedes ganar, sobre tu mazo,//otra que cueste exactamente [1]//por carta descartada.";
SpanishCardTexts[CardNames.BALL] = "Coge tu ficha de -[1]. Gana 2 cartas que cuesten hasta [4].";
SpanishCardTexts[CardNames.BONFIRE] = "Elimina hasta 2 cartas que tengas en juego."; //TRANSLATE , english text changed to "Trash up to 2 non-Duration cards you have in play.";
SpanishCardTexts[CardNames.BORROW] = "Una vez por turno: |+1 Compra|. Si tu ficha de -1 Carta//no est\xE1 sobre tu mazo, ponla ah\xED y |+|[1].";
SpanishCardTexts[CardNames.BRIDGE_TROLL] = "Cada rival coge su ficha -[1].//Ahora y al inicio de tu//pr\xF3ximo turno: |+1 Compra|.//---//Mientras est\xE9 en juego, las cartas//cuestan [1] menos en tus turnos.";
SpanishCardTexts[CardNames.CARAVAN_GUARD] = "|+1 Carta|//|+1 Acci\xF3n|////Al inicio de tu pr\xF3ximo turno, |+|[1].//---//Cuando un rival juegue un Ataque,//puedes antes jugar esta carta de//tu mano% (|+1 Acci\xF3n| no tiene%//%efecto si no es tu turno.)%";
SpanishCardTexts[CardNames.CHAMPION] = "|+1 Acci\xF3n|//Durante el resto de la partida,//cuando un rival juegue un Ataque,//este no te afecta, y cuando juegues//una Acci\xF3n, |+1 Acci\xF3n|.//%(Se mantiene en juego.%//%No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.COIN_OF_THE_REALM] = "[!1]////Cuando juegues esta carta,//mu\xE9vela un tu Taberna.//---//Justo al terminar de jugar//una Acci\xF3n, puedes convocar//esta carta, para |+2 Acciones|.";
SpanishCardTexts[CardNames.DISCIPLE] = "Puedes jugar una carta de Acci\xF3n//de tu mano dos veces. Gana una copia.//---//Al descartarlo de tu \xE1rea de juego,//puedes cambiarlo por un Maestro.//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.DISTANT_LANDS] = "Mu\xE9vela un tu Taberna.//---//Vale {4} si est\xE1 en tu//Taberna al final de la partida//(en caso contrario vale {0}).";
SpanishCardTexts[CardNames.DUNGEON] = "|+1 Acci\xF3n|////Ahora y al inicio de tu//pr\xF3ximo turno: |+2 Cartas|,//luego, descarta 2 cartas.";
SpanishCardTexts[CardNames.DUPLICATE] = "Mu\xE9vela un tu Taberna.//---//Cuando ganes una carta que cueste//hasta [6], puedes convocarla,//para ganar una copia de esa carta.";
SpanishCardTexts[CardNames.EXPEDITION] = "Roba 2 cartas adicionales para tu pr\xF3xima mano.";
SpanishCardTexts[CardNames.FERRY] = "Mueve tu ficha -[2] un una pila de Acci\xF3n del Suministro.//(Las cartas de esa pila cuestan [2] menos en tus turnos.)";
SpanishCardTexts[CardNames.FUGITIVE] = "|+2 Cartas|//|+1 Acci\xF3n|////Descarta una carta.//---//Al descartarlo de tu \xE1rea de juego,//puedes cambiarlo por un Disc\xEDpulo.//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.GEAR] = "|+2 Cartas|////Aparta hasta 2 cartas de tu mano//bocabajo (debajo de esta).//Al inicio de tu pr\xF3ximo turno,//ponlas en tu mano.";
SpanishCardTexts[CardNames.GIANT] = "Gira tu ficha de Viaje//(comienza bocarriba). Luego, si est\xE1//bocabajo, |+|[1]. Si est\xE1 bocarriba, //|+|[5], y cada rival revela la carta//superior de su mazo, la elimina si//cuesta de [3] un [6], y de lo contrario//la descarta y gana una Maldici\xF3n.";
SpanishCardTexts[CardNames.GUIDE] = "|+1 Carta|//|+1 Acci\xF3n|////Mu\xE9vela un tu Taberna.//---//Al inicio de tu pr\xF3ximo turno,//puedes convocarla para descartar//tu mano y robar 5 cartas.";
SpanishCardTexts[CardNames.HAUNTED_WOODS] = "Hasta tu pr\xF3ximo turno,//cuando un rival compre una carta,//pone su mano sobre su mazo//en cualquier orden.//Al inicio de tu pr\xF3ximo turno,////|+3 Cartas|";
SpanishCardTexts[CardNames.HERO] = "|+[2]|//---//Gana un Tesoro.////Al descartarla de tu \xE1rea de juego,//puedes cambiarla por una Campeona.//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.HIRELING] = "Al inicio de cada uno//de tus turnos, hasta//el final de la partida:////|+1 Carta|////%(Se mantiene en juego.)%";
SpanishCardTexts[CardNames.INHERITANCE] = "Una vez por partida: aparta una Acci\xF3n de hasta [4](que no sea Mandato)//del Suministro. Mueve tu ficha de Finca ah\xED. (Durante tus turnos, las Fincas//son tambi\xE9n Acciones con \"Juega la carta con la ficha de Finca, sin moverla.\")";
SpanishCardTexts[CardNames.LOST_ARTS] = "Pon tu ficha de +1 Acci\xF3n en una pila de Acci\xF3n del Suministro.//(Al jugar una carta de esa pila, primero recibe |+1 Acci\xF3n|.)";
SpanishCardTexts[CardNames.LOST_CITY] = "|+2 Cartas|//|+2 Acciones|//---//Cuando ganes esta carta,//cada rival roba una carta.";
SpanishCardTexts[CardNames.MAGPIE] = "|+1 Carta|//|+1 Acci\xF3n|////Revela la carta superior de//tu mazo. Si es un Tesoro, ponlo//en tu mano. Si es una Acci\xF3n//o Victoria, gana una Urraca.";
SpanishCardTexts[CardNames.MESSENGER] = "|+1 Compra|//|+[2]|//---//Puedes poner tu mazo//en tu pila de descartes.////Cuando esta sea tu primera compra//en un turno, gana una carta hasta [4],//y cada rival gana una copia.";
SpanishCardTexts[CardNames.MISER] = "Elige una: pon un Cobre//de tu mano en tu Taberna;//o |+|[1] por cada//Cobre en tu Taberna.";
SpanishCardTexts[CardNames.MISSION] = "Una vez por turno: si el turno anterior no fue tuyo, juega otro//turno despu\xE9s de este, durante el cual no puedes comprar cartas.";
SpanishCardTexts[CardNames.PATHFINDING] = "Pon tu ficha de +1 Carta en una pila de Acci\xF3n del Suministro.//(Al jugar una carta de esa pila, primero recibe |+1 Carta|.)";
SpanishCardTexts[CardNames.PAGE] = "|+1 Carta|//|+1 Acci\xF3n|//---//Al descartarla de tu \xE1rea de//juego, puedes cambiarla por//una Cazadora de Tesoros.";
SpanishCardTexts[CardNames.PEASANT] = "|+1 Compra|//---//|+[1]|////Al descartarlo de tu \xE1rea de//juego, puedes cambiarlo por//un Soldado.";
SpanishCardTexts[CardNames.PILGRIMAGE] = "Una vez por turno: gira tu ficha de Viaje (comienza bocarriba);// luego, si est\xE1 bocarriba, elige hasta 3 cartas de distinto//nombre que tengas en juego y gana una copia de cada.";
SpanishCardTexts[CardNames.PLAN] = "Pon tu ficha de Eliminar en una pila de Acci\xF3n del Suministro.//(Al comprar una carta de esa pila, puedes eliminar//una carta de tu mano.)";
SpanishCardTexts[CardNames.PORT] = "|+1 Carta|//|+2 Acciones|//---//Cuando compres esta carta,//gana otra Aldea Portuaria.";
SpanishCardTexts[CardNames.QUEST] = "Puedes descartar un Ataque, dos Maldiciones,//o seis cartas. Si lo haces, gana un Oro.";
SpanishCardTexts[CardNames.RANGER] = "|+1 Compra|////Gira tu ficha de Viaje//(comienza bocarriba). Luego,//si est\xE1 bocarriba, |+5 Cartas|.";
SpanishCardTexts[CardNames.RAID] = "Gana una Plata por cada Plata que tengas en juego.//Cada rival pone su ficha de -1 Carta sobre su mazo.";
SpanishCardTexts[CardNames.RATCATCHER] = "|+1 Carta|//|+1 Acci\xF3n|////Mu\xE9vela un tu Taberna.//---//Al inicio de tu turno, puedes//convocarlo, para eliminar//una carta de tu mano.";
SpanishCardTexts[CardNames.RAZE] = "|+1 Acci\xF3n|////Elimina esta carta, o una de tu mano.//Mira una carta de la parte superior//de tu mazo por cada [1] cueste//la carta eliminada. Pon una//en tu mano y descarta el resto.";
SpanishCardTexts[CardNames.RELIC] = "[!2]////Cuando lo juegues,//cada rival pone su ficha//de -1 Carta sobre su mazo.";
SpanishCardTexts[CardNames.ROYAL_CARRIAGE] = "|+1 Acci\xF3n|////Mu\xE9velo un tu Taberna.//---//Justo al terminar de jugar//una carta de Acci\xF3n, si sigue//en juego, puedes convocarlo//para repetir esa Acci\xF3n.";
SpanishCardTexts[CardNames.SAVE] = "Una vez por turno: |+1 Compra|. Aparta una carta de//tu mano, y ponla en tu mano al final del turno (tras robar).";
SpanishCardTexts[CardNames.SCOUTING_PARTY] = "|+1 Compra|////Mira las 5 cartas superiores de tu mazo.//Descarta 3 y devuelve el resto en cualquier orden.";
SpanishCardTexts[CardNames.SEAWAY] = "Gana una carta de Acci\xF3n que cueste hasta [4].//Pon tu ficha de +1 Compra en su pila. (Al jugar//una carta de esa pila, primero recibe |+1 Compra|.)";
SpanishCardTexts[CardNames.SOLDIER] = "|+[1]|//---//|+|[2] por cada otro Ataque que tengas//en juego. Cada rival con 4 o m\xE1s//cartas en mano descarta una carta.////Al descartarlo de tu \xE1rea de juego,//puedes cambiarlo por un Fugitivo.//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.STORYTELLER] = "|+1 Acci\xF3n|//|+[1]|////Juega hasta 3 Tesoros de tu//mano. Luego, paga todo tu []//(incluyendo el [1] de esta) y//roba una carta por [1] pagado.";
SpanishCardTexts[CardNames.SWAMP_HAG] = "Hasta tu pr\xF3ximo turno, cuando//un rival compre una carta,//gana una Maldici\xF3n.//Al inicio de tu pr\xF3ximo turno:////|+[3]|";
SpanishCardTexts[CardNames.TEACHER] = "Mu\xE9velo un tu Taberna.//---//Al inicio de tu turno, puedes convocarlo//para mover tu ficha de |+|1 Carta, |+|1 Acci\xF3n,//|+|1 Compra, o |+|[1] un una pila de Acci\xF3n//del Suministro que no tenga fichas.//(Al jugar una carta de esa pila,//primero recibes ese bonus.)//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.TRAVELLING_FAIR] = "|+2 Compras|////Cuando ganes una carta este turno,//puedes ponerla sobre tu mazo.";
SpanishCardTexts[CardNames.TRADE] = "Elimina hasta 2 cartas de tu mano.//Gana una Plata por carta eliminada.";
SpanishCardTexts[CardNames.TRAINING] = "Pon tu ficha +[1] en una pila de Acci\xF3n del Suministro.//(Al jugar una carta de esa pila, primero recibe +[1].)";
SpanishCardTexts[CardNames.TRANSMOGRIFY] = "|+1 Acci\xF3n|////Mu\xE9velo un tu Taberna.//---//Al inicio de tu turno, puedes convocarlo//para eliminar una carta de tu mano,//y ganar un tu mano una que cueste//hasta [1] m\xE1s que la eliminada.";
SpanishCardTexts[CardNames.TREASURE_TROVE] = "[!2]////Cuando juegues esta carta,//gana un Oro y un Cobre.";
SpanishCardTexts[CardNames.TREASURE_HUNTER] = "|+1 Acci\xF3n|//|+[1]|//---//Gana una Plata por cada carta que//el jugador de tu derecha //gan\xF3 en su \xFAltimo turno.////Al descartarla de tu \xE1rea de juego,//puedes cambiarla por una Guerrera.//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.WARRIOR] = "|+2 Cartas|////Una vez por cada Viajero que tengas//en juego (incluido este), cada rival//descarta la carta superior de su mazo//y la elimina si cuesta [3] o [4].//---//Al descartarla de tu \xE1rea de juego,//puedes cambiarla por una Hero\xEDna.//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.WINE_MERCHANT] = "|+1 Compra|//|+[4]|//---//Mu\xE9velo un tu Taberna.////Al final de tu fase de compras,//si tienes al menos [2] sin gastar,//puedes descartarlo de tu Taberna.";
SpanishCardTexts[CardNames.ENCAMPMENT] = "|+2 Cartas|//|+2 Acciones|////Puedes revelar un Oro o Saqueo de//tu mano. Si no lo haces, ap\xE1rtalo,//y devu\xE9lvelo al Suministro//al inicio del Mantenimiento.";
SpanishCardTexts[CardNames.PLUNDER] = "{+1}//[!2]";
SpanishCardTexts[CardNames.PATRICIAN] = "|+1 Carta|//|+1 Acci\xF3n|//////Revela la carta superior de tu//mazo. Si cuesta [5] o m\xE1s,//ponla un tu mano.";
SpanishCardTexts[CardNames.EMPORIUM] = "---//|+1 Carta|//|+1 Acci\xF3n|//|+[1]|////Cuando lo ganes, si tienes//al menos 5 cartas de Acci\xF3n//en juego {+2}.";
SpanishCardTexts[CardNames.SETTLERS] = "|+1 Carta|//|+1 Acci\xF3n|//////Mira en tu pila de descartes.//Puedes revelar un Cobre//de ella y ponerlo en tu mano.";
SpanishCardTexts[CardNames.BUSTLING_VILLAGE] = "|+1 Carta|//|+3 Acciones|//////Mira en tu pila de descartes.//Puedes revelar unos Colonos//de ella y ponerlos en tu mano.";
SpanishCardTexts[CardNames.CATAPULT] = "|+|[1]////Elimina una carta de tu mano.//Si cuesta [3] o m\xE1s, cada rival//gana una Maldici\xF3n. Si es un//Tesoro, cada rival se descarta//hasta tener 3 cartas en mano.";
SpanishCardTexts[CardNames.ROCKS] = "[!1]//---//Cuando ganes o elimines esta carta//gana una Plata; si es tu fase//de compra, ponla sobre tu mazo,//si no, ponla en tu mano.";
SpanishCardTexts[CardNames.GLADIATOR] = "|+|[2]////Revela una carta de tu mano.//El rival de tu izquierda puede//revelar una copia de su mano.//Si no lo hace, |+|[1] y elimina un//Gladiador del Suministro.";
SpanishCardTexts[CardNames.FORTUNE] = "|+1 Compra|////Al jugar esto, dobla tu []//si no lo has hecho ya este turno.//---//Cuando lo ganes, gana un Oro por//Gladiador que tengas en juego.";
SpanishCardTexts[CardNames.CASTLES] = "Ordena la pila de Castillos//por coste, con los m\xE1s caros//debajo. Para una partida de//2 jugadores, usa solo uno//de cada. Solo la carta//superior de la pila puede//ser ganada o comprada.";
SpanishCardTexts[CardNames.HUMBLE_CASTLE] = "{1}//---//Vale [!1] por cada//Castillo que tengas.";
SpanishCardTexts[CardNames.CRUMBLING_CASTLE] = "{+1}//---//Cuando lo ganes o elimines,//{!1} y gana una Plata.";
SpanishCardTexts[CardNames.SMALL_CASTLE] = "{!2}//---//Elimina este u otro//Castillo de tu mano.//Si lo haces, gana un Castillo.";
SpanishCardTexts[CardNames.HAUNTED_CASTLE] = "{!2}//---//Cuando lo ganes durante tu turno,//gana un Oro, y cada rival con//5 o m\xE1s cartas en mano pone 2//cartas de su mano sobre su mazo.";
SpanishCardTexts[CardNames.OPULENT_CASTLE] = "[2]//---//Descarta cualquier n\xFAmero//de cartas de Victoria, reveladas.//|+|{!3} por carta descartada.";
SpanishCardTexts[CardNames.SPRAWLING_CASTLE] = "{!4}//---//Cuando lo ganes, gana//un Ducado o 3 Fincas.";
SpanishCardTexts[CardNames.GRAND_CASTLE] = "{+1}//---//Cuando lo ganes, revela tu mano.//{!5} por carta de Victoria//en mano y/o en juego.";
SpanishCardTexts[CardNames.KINGS_CASTLE] = "Vale {2} por cada//Castillo que tengas.";
SpanishCardTexts[CardNames.ADVANCE] = "Puedes eliminar una carta de Acci\xF3n de tu mano.//Si lo haces, gana una Acci\xF3n que cueste hasta [6].";
SpanishCardTexts[CardNames.ANNEX] = "Baraja todas excepto hasta 5 cartas de tu//pila de descartes con tu mazo. Gana un Ducado.";
SpanishCardTexts[CardNames.ARCHIVE] = "|+1 Acci\xF3n|////Aparta las 3 cartas superiores//de tu mazo bocabajo (puedes//mirarlas). Ahora y al inicio//de tus pr\xF3ximos dos turnos,//pon una en tu mano.";
SpanishCardTexts[CardNames.AQUEDUCT] = "Cuando ganes un Tesoro, mueve {} de su pila aqu\xED.//Cuando ganes un carta de Victoria, coge los {8} de aqu\xED.//---//Preparaci\xF3n: pon {1} en las pilas de Plata y Oro.";
SpanishCardTexts[CardNames.ARENA] = "Al inicio de tu fase de Compra, puedes descartar//una carta de Acci\xF3n. Si lo haces, coge {2} de aqu\xED.//---//Preparaci\xF3n: pon {6} por jugador aqu\xED.";
SpanishCardTexts[CardNames.BANDIT_FORT] = "Al puntuar,{-2} por cada Plata//y cada Oro que tengas.";
SpanishCardTexts[CardNames.BANQUET] = "Gana 2 Cobres y una carta (que no sea de Victoria)//que cueste hasta [5].";
SpanishCardTexts[CardNames.BASILICA] = "Cuando compres una carta, si tienes {2} o m\xE1s sobrantes,//coge {6} de aqu\xED.//---//Preparaci\xF3n: pon [2] por jugador aqu\xED.";
SpanishCardTexts[CardNames.BATHS] = "Cuando acabes tu turno sin haber ganado una carta,//coge {2} de aqu\xED.//---//Preparaci\xF3n: pon {6} por jugador aqu\xED.";
SpanishCardTexts[CardNames.BATTLEFIELD] = "Cuando ganes un carta de Victoria, coge {2} de aqu\xED.//---//Preparaci\xF3n: pon {6} por jugador aqu\xED.";
SpanishCardTexts[CardNames.CAPITAL] = "[D]////|+1 Compra|//---//Cuando lo descartes de tu \xE1rea//de juego, toma [6D], y luego,//puedes saldar [!6].";
SpanishCardTexts[CardNames.CHARM] = "Al jugarlo, elige una://|+1 Compra| y |+|[2]; o//la pr\xF3xima vez que compres//una carta este turno, puedes//tambi\xE9n ganar una carta//distinta con el mismo coste.";
SpanishCardTexts[CardNames.CHARIOT_RACE] = "|+1 Acci\xF3n|////Revela la carta superior de//tu mazo y ponla un tu mano.//El jugador de tu izquierda revela//la carta superior de su mazo.//Si la tuya cuesta m\xE1s, |+|[1] y {+1}.";
SpanishCardTexts[CardNames.CITY_QUARTER] = "|+2 Acciones|//////Revela tu mano. |+1 Carta|//por carta de Acci\xF3n revelada.";
SpanishCardTexts[CardNames.COLONNADE] = "Al comprar una carta de Acci\xF3n, si tienes una copia en juego,//coge {2} de aqu\xED.//---//Preparaci\xF3n: pon {6} por jugador aqu\xED.";
SpanishCardTexts[CardNames.CONQUEST] = "Gana 2 Platas.//{+1} por Plata que hayas ganado este turno.";
SpanishCardTexts[CardNames.CROWN] = "Si es tu fase de Acci\xF3n,//puedes jugar una Acci\xF3n//de tu mano dos veces.//Si es tu fase de Compra,//puedes jugar un Tesoro//de tu mano dos veces.";
SpanishCardTexts[CardNames.DELVE] = "|+1 Compra|//Gana una Plata.";
SpanishCardTexts[CardNames.DEFILED_SHRINE] = "Cuando ganes una Acci\xF3n, mueve {2} de su pila aqu\xED.//Cuando compres una Maldici\xF3n, coge los {} de aqu\xED.//---//Preparaci\xF3n: pon {1} en cada pila de Acci\xF3n (que no sea Colecta).";
SpanishCardTexts[CardNames.DOMINATE] = "Gana una Provincia. Si lo haces,{+9}.";
SpanishCardTexts[CardNames.DONATE] = "Despu\xE9s de este turno, pon todas las cartas de tu mazo//y pila de descartes en tu mano, elimina cualquier cantidad, //baraja tu mano a tu mazo, luego, roba 5 cartas."; // TRANSLATE (changed) effect is now at end of turn, not after the turn
SpanishCardTexts[CardNames.ENCHANTRESS] = "Hasta tu pr\xF3ximo turno, la//primera vez que cada rival juegue//una Acci\xF3n en su turno, obtiene//|+1 Carta| y |+1 Acci\xF3n| en lugar//de seguir sus instrucciones.//////Al inicio de tu pr\xF3ximo turno,//|+2 Cartas|";
SpanishCardTexts[CardNames.ENGINEER] = "Gana una carta//que cueste hasta [4].//Puedes eliminar esta.//Si lo haces, gana una carta//que cueste hasta [4].";
SpanishCardTexts[CardNames.FARMERS_MARKET] = "|+1 Compra|////Si hay {4} o m\xE1s en la pila//de Mercado Agr\xEDcola, rec\xF3gelos//y elimina esta carta. En caso//contrario, a\xF1ade {1} a la pila//y luego, |+|[1] por {1} en la pila.";
SpanishCardTexts[CardNames.FORUM] = "|+3 Cartas|//|+1 Acci\xF3n|////Descarta 2 cartas.//---//Al comprar esto, |+1 Compra|.";
SpanishCardTexts[CardNames.FOUNTAIN] = "Al puntuar,{15} si tienes al menos 10 Cobres.";
SpanishCardTexts[CardNames.GROUNDSKEEPER] = "|+1 Carta|//|+1 Acci\xF3n|//---//Mientras est\xE9 en juego, cuando//ganes una carta de Victoria,{+1}.";
SpanishCardTexts[CardNames.KEEP] = "Al puntuar,{5} por Tesoro de distinto nombre que tengas,//del cual tengas igual o mayor n\xFAmero de copias//que cualquiera de tus rivales.";
SpanishCardTexts[CardNames.LABYRINTH] = "Cuando ganes una segunda carta en tu turno,//coge {2} de aqu\xED.//---//Preparaci\xF3n: pon {6} por jugador aqu\xED.";
SpanishCardTexts[CardNames.LEGIONARY] = "|+|[3]////Puedes revelar un Oro de tu//mano. Si lo haces, cada rival//descarta hasta tener 2 cartas//&&& en la mano, luego, roba una.";
SpanishCardTexts[CardNames.MOUNTAIN_PASS] = "Cuando seas el primer jugador en ganar una Provincia, despu\xE9s//de ese turno, cada jugador apuesta, hasta [40D], acabando en t\xED.//La apuesta m\xE1s alta {+8} y toma la [D] que apost\xF3."; // TRANSLATE (changed) effect is now at end of turn, not after the turn
SpanishCardTexts[CardNames.MUSEUM] = "Al puntuar,{2} por cada carta//con distinto nombre que tengas.";
SpanishCardTexts[CardNames.OBELISK] = "Al puntuar,{2} por cada carta que tengas//de la pila elegida.//---//Preparaci\xF3n: elige una pila de Acci\xF3n aleatoria del Suministro.";
SpanishCardTexts[CardNames.ORCHARD] = "Al puntuar,{4} por cada carta de Acci\xF3n //de distinto nombre de la que tengas 3 o m\xE1s copias.";
SpanishCardTexts[CardNames.OVERLORD] = "Juega una carta de Acci\xF3n//(que no sea Mandato)//del Suministro, que cueste//hasta [5], sin moverla.";
SpanishCardTexts[CardNames.PALACE] = "Al puntuar,{3} por cada conjunto que tengas//de Cobre - Plata - Oro.";
SpanishCardTexts[CardNames.RITUAL] = "Gana una Maldici\xF3n. Si lo haces, elimina//una carta de tu mano.{+1} por [1] de coste.";
SpanishCardTexts[CardNames.ROYAL_BLACKSMITH] = "|+5 Cartas|////Revela tu mano;//descarta los Cobres.";
SpanishCardTexts[CardNames.SACRIFICE] = "Elimina una carta de tu mano.//Si es...////Acci\xF3n, |+2 Cartas, +2 Acciones|//Tesoro, |+|[2]//Victoria, |+|{2}";
SpanishCardTexts[CardNames.SALT_THE_EARTH] = "{+1}//Elimina un carta de Victoria del Suministro.";
SpanishCardTexts[CardNames.TAX] = "A\xF1ade [2D] a una pila del Suministro.//---//Preparaci\xF3n: a\xF1ade [1D] a cada pila del Suministro. Cuando//un jugador compre una carta, coge la [D] de su pila.";
SpanishCardTexts[CardNames.TEMPLE] = "{}////Elimina de 1 a 3 cartas con//distinto nombre de tu mano.//A\xF1ade {1} a la pila de Templos.//---//Cuando ganes esta carta, coge los {+1}//de la pila de Templos.";
SpanishCardTexts[CardNames.TOMB] = "Al eliminar una carta,{+1}.";
SpanishCardTexts[CardNames.TOWER] = "Al puntuar,{1} por cada carta (que no sea de Victoria)//que tengas de una pila vac\xEDa del Suministro.";
SpanishCardTexts[CardNames.TRIUMPH] = "Gana un Finca. Si lo hiciste,//{+1} por cada carta que hayas ganado este turno.";
SpanishCardTexts[CardNames.TRIUMPHAL_ARCH] = "Al puntuar,{3} por copia de la segunda carta de Acci\xF3n//m\xE1s com\xFAn entre tu cartas (si es un empate, cuenta una de ellas).";
SpanishCardTexts[CardNames.VILLA] = "|+2 Acciones|//|+1 Compra|//---//|+[1]|////Cuando ganes esta carta, ponla en tu mano,//|+1 Acci\xF3n|, y si es tu fase de Compra//vuelve a tu fase de Acci\xF3n.";
SpanishCardTexts[CardNames.WALL] = "Al puntuar,{-1} por cada carta que tengas//despu\xE9s de las primeras 15.";
SpanishCardTexts[CardNames.WOLF_DEN] = "Al puntuar,{-3} por carta de la que tengas//exactamente una copia.";
SpanishCardTexts[CardNames.WEDDING] = "{+1}//Gana un Oro.";
SpanishCardTexts[CardNames.WILD_HUNT] = "Elige una: |+3 Cartas|//y a\xF1ade {1} a la pila//de Cacer\xEDas Salvajes;//o gana una Finca. Si lo haces,//coge los {} de la pila.";
SpanishCardTexts[CardNames.WINDFALL] = "Si tu mazo y pila de descartes estan vac\xEDos, gana 3 Oros.";
SpanishCardTexts[CardNames.PIG] = "Cerdo!,";
SpanishCardTexts[CardNames.MINUS_CARD] = "";
SpanishCardTexts[CardNames.MINUS_COIN] = "";
SpanishCardTexts[CardNames.STATE_LIMBO] = "";
SpanishCardTexts[CardNames.DRUID_BOONS] = "";
SpanishCardTexts[CardNames.BOON_DRAWPILE] = "";
SpanishCardTexts[CardNames.BOON_DISCARDPILE] = "";
SpanishCardTexts[CardNames.THE_EARTHS_GIFT] = "Puedes descartar un Tesoro para ganar una carta//que cueste hasta [4].";
SpanishCardTexts[CardNames.THE_FIELDS_GIFT] = "|+1 Acci\xF3n|//|+[1]|////(Qu\xE9date esta carta hasta el Mantenimiento.)";
SpanishCardTexts[CardNames.THE_FLAMES_GIFT] = "Puedes eliminar una carta de tu mano.";
SpanishCardTexts[CardNames.THE_FORESTS_GIFT] = "|+1 Compra|//|+[1]|////(Qu\xE9date esta carta hasta el Mantenimiento.)";
SpanishCardTexts[CardNames.THE_MOONS_GIFT] = "Mira en tu pila de descartes.//Puedes poner una carta de ellas sobre tu mazo.";
SpanishCardTexts[CardNames.THE_MOUNTAINS_GIFT] = "Gana una Plata.";
SpanishCardTexts[CardNames.THE_RIVERS_GIFT] = "|+1 Carta| al final de este turno.//(Qu\xE9date esta carta hasta el Mantenimiento.)";
SpanishCardTexts[CardNames.THE_SEAS_GIFT] = "|+1 Carta|";
SpanishCardTexts[CardNames.THE_SKYS_GIFT] = "Puedes descartar 3 cartas para ganar un Oro.";
SpanishCardTexts[CardNames.THE_SUNS_GIFT] = "Mira las 4 cartas superiores de tu mazo. Descarta cualquier//n\xFAmero de ellas y devuelve el resto en cualquier orden.";
SpanishCardTexts[CardNames.THE_SWAMPS_GIFT] = "Gana un Fuego Fatuo.";
SpanishCardTexts[CardNames.THE_WINDS_GIFT] = "|+2 Cartas|//Descarta 2 cartas.";
SpanishCardTexts[CardNames.HEX_DRAWPILE] = "";
SpanishCardTexts[CardNames.HEX_DISCARDPILE] = "";
SpanishCardTexts[CardNames.BAD_OMENS] = "Pon tu mazo en tu pila de descartes.//Pon 2 Cobres de ella sobre tu mazo.//(o revela si no puedes).";
SpanishCardTexts[CardNames.DELUSION] = "Si no tienes Alucinado o Envidioso,//recibe Alucinado.";
SpanishCardTexts[CardNames.ENVY] = "Si no tienes Alucinado o Envidioso,//recibe Envidioso.";
SpanishCardTexts[CardNames.FAMINE] = "Revela las 3 cartas superiores de tu mazo. Descarta//las Acciones. Baraja el resto con tu mazo.";
SpanishCardTexts[CardNames.FEAR] = "Si tienes al menos 5 cartas en mano, descarta//una Acci\xF3n o Tesoro (o revela si no puedes).";
SpanishCardTexts[CardNames.GREED] = "Gana un Cobre sobre tu mazo.";
SpanishCardTexts[CardNames.HAUNTING] = "Si tienes al menos 4 cartas en mano,//pon una de ellas sobre tu mazo.";
SpanishCardTexts[CardNames.LOCUSTS] = "Elimina la carta superior de tu mazo. Si es Cobre o//Finca, gana una Maldici\xF3n. En caso contrario, gana una//carta m\xE1s barata que comparta tipo con ella.";
SpanishCardTexts[CardNames.MISERY] = "Si es tu primera Miseria esta partida, recibe Miserable.//En caso contrario, dale la vuelta para Doble Miserable.";
SpanishCardTexts[CardNames.PLAGUE] = "Gana una Maldici\xF3n a tu mano.";
SpanishCardTexts[CardNames.POVERTY] = "Descarta hasta tener 3 cartas en mano.";
SpanishCardTexts[CardNames.WAR] = "Revela cartas de tu mazo hasta revelar una//que cueste [3] o [4]. Elim\xEDnala y descarta el resto.";
SpanishCardTexts[CardNames.MISERABLE] = "{!-2}";
SpanishCardTexts[CardNames.TWICE_MISERABLE] = "{!-4}";
SpanishCardTexts[CardNames.ENVIOUS] = "Al inicio de tu fase de Compra, devu\xE9lvelo,//y Plata y Oro generan [1] este turno.";
SpanishCardTexts[CardNames.DELUDED] = "Al inicio de tu fase de Compra, devu\xE9lvelo,//y no puedes comprar Acciones este turno.";
SpanishCardTexts[CardNames.LOST_IN_THE_WOODS] = "Al inicio de tu turno, puedes descartar//una carta para recibir una Bendici\xF3n.";
SpanishCardTexts[CardNames.BARD] = "|+|[2]//Recibe una Bendici\xF3n.";
SpanishCardTexts[CardNames.BLESSED_VILLAGE] = "|+1 Carta|//|+2 Acciones|//---//Cuando ganes esta carta, coge//una Bendici\xF3n. Rec\xEDbela ahora//o al inicio de tu pr\xF3ximo turno.";
SpanishCardTexts[CardNames.CHANGELING] = "Elimina esta carta. Gana un copia//de una carta que tengas en juego.//---//En las partidas en las que se use,//cuando ganes una carta que cueste//[3] o m\xE1s, puedes cambiarla//por un Xan\xEDn.";
SpanishCardTexts[CardNames.CEMETERY] = "{!2}//---//Cuando ganes esta carta, elimina//hasta 4 cartas de tu mano.//%%(Legado: Espejo Encantado)%%";
SpanishCardTexts[CardNames.COBBLER] = "Al inicio de tu pr\xF3ximo turno,//gana una carta a tu mano//que cueste hasta [4].";
SpanishCardTexts[CardNames.CONCLAVE] = "|+|[2]//Puedes jugar una carta de Acci\xF3n//de tu mano de la que no tengas//una copia en juego. Si lo haces,//|+1 Acci\xF3n.|";
SpanishCardTexts[CardNames.CRYPT] = "Aparta cualquier n\xFAmero de//Tesoros que tengas en juego,//bocabajo (bajo esta carta).//Mientras quede alguno,//al inicio de cada uno de tus turnos,//pon uno de ellos en tu mano.";
SpanishCardTexts[CardNames.CURSED_VILLAGE] = "|+2 Acciones|////Roba hasta tener//6 cartas en la mano.//---//Cuando ganes esta carta,//recibe un Embrujo.";
SpanishCardTexts[CardNames.DEN_OF_SIN] = "Al inicio de tu pr\xF3ximo turno://|+2 Cartas|.//---//Esta carta se gana a tu mano//(en lugar de a tu pila de descartes).";
SpanishCardTexts[CardNames.DEVILS_WORKSHOP] = "Si el n\xFAmero de cartas//que has ganado este turno es:////|2+: |gana un Diablillo;//|1: |gana una carta de hasta [4];//|0: |gana un Oro.";
SpanishCardTexts[CardNames.DRUID] = "|+1 Compra|////Recibe una de las Bendiciones//apartadas (sin moverla).//---//Preparaci\xF3n: aparta las 3//Bendiciones superiores, bocarriba.";
SpanishCardTexts[CardNames.EXORCIST] = "Elimina una carta de tu mano.//Gana un Esp\xEDritu m\xE1s barato de//una de las pilas de Esp\xEDritus.";
SpanishCardTexts[CardNames.FAITHFUL_HOUND] = "|+2 Cartas|//---//Cuando descartes esta carta fuera//de tu fase de Mantenimiento,//puedes apartarla, y ponerla//en un tu mano al final del turno.";
SpanishCardTexts[CardNames.FOOL] = "Si no eres el jugador con//Perdido en el Bosque, rec\xEDbelo,//coge 3 Bendiciones,//y rec\xEDbelas en cualquier orden.////%%(Legado: Moneda de la Suerte)%%";
SpanishCardTexts[CardNames.GHOST_TOWN] = "Al inicio de tu pr\xF3ximo turno://|+1 Carta |y |+1 Acci\xF3n|.//---//Esta carta se gana a tu mano//(en lugar de a tu pila de descartes).";
SpanishCardTexts[CardNames.GUARDIAN] = "Hasta tu pr\xF3ximo turno,//cuando un rival juegue un Ataque,//este no te afecta. Al inicio//de tu pr\xF3ximo turno, |+|[1].//---//Esta carta se gana a tu mano//(en lugar de a tu pila de descartes).";
SpanishCardTexts[CardNames.IDOL] = "[!2]////Si despu\xE9s de jugar esta carta//tienes un n\xFAmero impar de \xCDdolos//en juego, recibe una Bendici\xF3n;//si tienes un n\xFAmero par,//cada rival gana una Maldici\xF3n.";
SpanishCardTexts[CardNames.LEPRECHAUN] = "Gana un Oro. Si tienes exactamente//7 cartas en juego, gana un Deseo.//En caso contrario, recibe un Embrujo.";
SpanishCardTexts[CardNames.MONASTERY] = "Por cada carta que hayas ganado//este turno, puedes eliminar //una carta de tu mano o un Cobre//que tengas en juego.";
SpanishCardTexts[CardNames.NECROMANCER] = "Juega una carta de Acci\xF3n//bocarriba (que no sea Duraci\xF3n) de,//las eliminadas, sin moverla, y g\xEDrala //bocabajo por lo que queda de turno.//---//Preparaci\xF3n: pon los 3 Zombis//en las cartas eliminadas.";
SpanishCardTexts[CardNames.NIGHT_WATCHMAN] = "Mira las 5 cartas superiores de tu//mazo, descarta cualquier cantidad,//y devuelve el resto en cualquier orden.//---//Esta carta se gana a tu mano//(en lugar de a tu pila de descartes).";
SpanishCardTexts[CardNames.PIXIE] = "|+1 Carta|//|+1 Acci\xF3n|////Descarta la Bendici\xF3n superior.//Puedes eliminar esta carta para//recibir esa Bendici\xF3n dos veces.//%%(Legado: Cabra)%%";
SpanishCardTexts[CardNames.POOKA] = "Puedes eliminar un Tesoro//que no sea Oro Maldito de//tu mano, para |+4 Cartas|.//%%(Legado: Oro Maldito)%%";
SpanishCardTexts[CardNames.RAIDER] = "Cada rival con 5 o m\xE1s cartas//en mano descarta una copia//de una carta que tengas en juego//(o revela su mano si no puede).//Al inicio de tu pr\xF3ximo turno,//+[3].";
SpanishCardTexts[CardNames.SACRED_GROVE] = "|+1 Compra|//|+[3]|////Recibe una Bendici\xF3n.//Si no da +[1], cada rival//puede recibirla.";
SpanishCardTexts[CardNames.SECRET_CAVE] = "|+1 Carta|//|+1 Acci\xF3n|////Puedes descartar 3 cartas.//Si lo hiciste, al inicio//de tu pr\xF3ximo turno, +[3].//%%(Legado: L\xE1mpara M\xE1gica)%%";
SpanishCardTexts[CardNames.SHEPHERD] = "|+1 Acci\xF3n|////Descarta cualquier n\xFAmero de//cartas de Victoria, revel\xE1ndolas.//|+2 Cartas| por carta descartada.//%%(Legado: Pasto)%%";
SpanishCardTexts[CardNames.SKULK] = "|+1 Compra|////Cada rival recibe//el siguiente Embrujo.//---//Cuando ganes esta carta,//gana un Oro.";
SpanishCardTexts[CardNames.TORMENTOR] = "|+|[2]////Si no tienes otras cartas//en juego, gana un Diablillo.//En caso contrario, cada rival //recibe el siguiente Embrujo.";
SpanishCardTexts[CardNames.TRAGIC_HERO] = "|+3 Cartas|//|+1 Compra|//////Si tienes 8 o m\xE1s cartas//en la mano (tras robar), elimina//esta carta y gana un Tesoro.";
SpanishCardTexts[CardNames.TRACKER] = "|+[1]|////Recibe una Bendici\xF3n.//---//Mientras est\xE9 en juego,//cuando ganes una carta,//puedes ponerla sobre tu mazo.//%%(Legado: Bolsa)%%";
SpanishCardTexts[CardNames.VAMPIRE] = "Cada rival recibe el siguiente//Embrujo. Gana una carta que cueste//hasta [5] que no sea una Vampiresa.//Cambia esta carta por un Murci\xE9lago.";
SpanishCardTexts[CardNames.WEREWOLF] = "Si es tu fase de Noche,//cada rival recibe el siguiente//Embrujo. En caso contrario,//|+3 Cartas|.";
SpanishCardTexts[CardNames.CURSED_GOLD] = "[!3]////Al jugar esta carta,//gana una Maldici\xF3n.";
SpanishCardTexts[CardNames.GOAT] = "[!1]////Al jugar esta carta,//puedes eliminar una carta//de tu mano.";
SpanishCardTexts[CardNames.HAUNTED_MIRROR] = "[!1]//---//Cuando elimines esta carta, puedes//descartar una carta de Acci\xF3n//para ganar un Fantasma.";
SpanishCardTexts[CardNames.LUCKY_COIN] = "[!1]////Al jugar esta carta,//gana una Plata.";
SpanishCardTexts[CardNames.MAGIC_LAMP] = "[!1]////Al jugar esta carta, si hay al menos//6 cartas de las que tengas exactamente//una copia en juego, elim\xEDnala. //Si lo haces, gana 3 Deseos.";
SpanishCardTexts[CardNames.PASTURE] = "{1}//---//Vale [!1] por cada Finca//que tengas.";
SpanishCardTexts[CardNames.POUCH] = "[!1]////|+1 Compra|";
SpanishCardTexts[CardNames.BAT] = "Elimina hasta 2 cartas de tu//mano. Si eliminaste al menos una,//cambia esta carta por una Vampiresa.//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.GHOST] = "Revela cartas de tu mazo hasta//revelar una Acci\xF3n. Descarta las//otras cartas y aparta la//Acci\xF3n. Al inicio de tu//pr\xF3ximo turno, ju\xE9gala dos veces.//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.IMP] = "|+2 Cartas|////Puedes jugar una carta de Acci\xF3n//de tu mano de la que no tengas//una copia en juego.//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.WILL_O_WISP] = "|+1 Carta|//|+1 Acci\xF3n|////Revela la carta superior de tu//mazo. Si cuesta [2] o menos,//ponla un tu mano.//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.WISH] = "|+1 Acci\xF3n|////Devuelve esta carta a su pila.//Si lo hiciste, gana una carta a//tu mano que cueste hasta [6].//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.ZOMBIE_APPRENTICE] = "Puedes eliminar una carta//de Acci\xF3n de tu mano para//|+3 Cartas |y |+1 Acci\xF3n|.";
SpanishCardTexts[CardNames.ZOMBIE_MASON] = "Elimina la carta superior//de tu mazo. Puedes ganar//una carta que cueste hasta [1]//m\xE1s que ella.";
SpanishCardTexts[CardNames.ZOMBIE_SPY] = "|+1 Carta|//|+1 Acci\xF3n|////Mira la carta superior de tu//mazo. Desc\xE1rtala o devu\xE9lvela.";
SpanishCardTexts[CardNames.ACTING_TROUPE] = "|+4 Aldeanos|//Elimina esta carta.";
SpanishCardTexts[CardNames.BORDER_GUARD] = "|+1 Acci\xF3n|////Revela las 2 cartas superiores//de tu mazo. Pon una en//tu mano y descarta el resto.//Si ambas eran Acciones, recibe//la Linterna o el Cuerno.";
SpanishCardTexts[CardNames.CARGO_SHIP] = "|+[2]|////Una vez este turno, al ganar//una carta, puedes apartarla//bocarriba (sobre esta).//Al inicio de tu pr\xF3ximo turno,//ponla en tu mano.";
SpanishCardTexts[CardNames.DUCAT] = "|+1 Cofre|//|+1 Compra|//---//Cuando ganes esta carta, puedes//eliminar un Cobre de tu mano.";
SpanishCardTexts[CardNames.EXPERIMENT] = "|+2 Cartas|//|+1 Acci\xF3n|//Devu\xE9lvelo al Suministro.//---//Cuando ganes esta carta,//gana otro Experimento//(ese no viene con otro).";
SpanishCardTexts[CardNames.FLAG_BEARER] = "|+[2]|//---//Cuando ganes o elimines//esta carta, recibe la Bandera.";
SpanishCardTexts[CardNames.HIDEOUT] = "|+1 Carta|//|+2 Acciones|////Elimina una carta de tu mano.//Si es un carta de Victoria,//gana una Maldici\xF3n.";
SpanishCardTexts[CardNames.INVENTOR] = "Gana una carta que cueste//hasta [4], luego, las cartas//cuestan [1] menos este turno.";
SpanishCardTexts[CardNames.IMPROVE] = "|+[2]|////Al inicio de tu Mantenimiento,//puedes eliminar una carta de Acci\xF3n//que fuera a ser descartada del \xE1rea//de juego este turno, para ganar//una carta que cueste//exactamente [1] m\xE1s que ella.";
SpanishCardTexts[CardNames.LACKEYS] = "|+2 Cartas|//---//Cuando ganes esta carta,//|+2 Aldeanos|.";
SpanishCardTexts[CardNames.MOUNTAIN_VILLAGE] = "|+2 Acciones|////Pon una carta de tu//pila de descartes en tu mano;//si no puedes, |+1 Carta|.";
SpanishCardTexts[CardNames.PATRON] = "|+1 Aldeano|//|+[2]|//---//Cuando algo causa que reveles//esta carta (usando el verbo//\"revelar\"), |+1 Cofre|."; // TRANSLATE, Patron works now only when revealed in an Action phase
SpanishCardTexts[CardNames.PRIEST] = "|+[2]|////Elimina una carta de tu mano.//Por el resto del turno, cuando//elimines una carta, |+|[2].";
SpanishCardTexts[CardNames.RESEARCH] = "|+1 Acci\xF3n|////Elimina una carta de tu mano. Por//cada [1] de coste, aparta una carta//de tu mazo bocabajo (sobre esta).//Al inicio de tu pr\xF3ximo turno,//pon una de esas cartas en tu mano.";
SpanishCardTexts[CardNames.SILK_MERCHANT] = "|+2 Cartas|//|+1 Compra|//---//Cuando ganes o //elimines esta carta,//|+1 Cofre| y |+1 Aldeano|.";
SpanishCardTexts[CardNames.OLD_WITCH] = "|+3 Cartas|////Cada rival gana una//Maldici\xF3n y puede eliminar //una Maldici\xF3n de su mano.";
SpanishCardTexts[CardNames.RECRUITER] = "|+2 Cartas|////Elimina una carta de tu mano.//|+1 Aldeano| por [1] de coste.";
SpanishCardTexts[CardNames.SCEPTER] = "[2]////Cuando juegues esta carta,//elige una:&&&|+&&&|;//o juega de nuevo una carta//de Acci\xF3n jugada este turno//que siga en juego.";
SpanishCardTexts[CardNames.SCHOLAR] = "Descarta tu mano.//|+7 Cartas|.";
SpanishCardTexts[CardNames.SCULPTOR] = "Gana una carta a tu mano//que cueste hasta [4]. Si es//un Tesoro, |+1 Aldeano|.";
SpanishCardTexts[CardNames.SEER] = "|+1 Carta|//|+1 Acci\xF3n|////Revela las 3 cartas superiores de//tu mazo. Pon las que cuesten de//[2] a [4] en tu mano. Devuelve//el resto en cualquier orden.";
SpanishCardTexts[CardNames.SPICES] = "[2]////|+1 Compra|//---//Cuando ganes esta carta,//|+2 Cofres|.";
SpanishCardTexts[CardNames.SWASHBUCKLER] = "|+3 Cartas|////Si tu pila de descartes tiene//alguna carta: |+1 Cofre|, luego,//si tienes al menos 4 Cofres//recibe el Cofre del Tesoro.";
SpanishCardTexts[CardNames.TREASURER] = "|+[3]|////Elige una: elimina un Tesoro//de tu mano; o gana un//Tesoro eliminado a tu//mano; o recibe la Llave.";
SpanishCardTexts[CardNames.VILLAIN] = "|+2 Cofres|////Cada rival con 5 o m\xE1s//cartas en mano descarta//una que cueste [2] o m\xE1s//(o revela que no puede).";
SpanishCardTexts[CardNames.FLAG] = "Cuando robes tu mano, |+1 Carta|.";
SpanishCardTexts[CardNames.HORN] = "Una vez por turno, al descartar un Guarda Fronterizo//del \xE1rea de juego, puedes ponerlo sobre tu mazo.";
SpanishCardTexts[CardNames.KEY] = "Al inicio de tu turno, |+|[1].";
SpanishCardTexts[CardNames.LANTERN] = "Los Guardas Fronterizos que juegues revelan 3 cartas y descartan 2.//(Necesitan que las 3 sean Acciones para recibir el Cuerno.)";
SpanishCardTexts[CardNames.TREASURE_CHEST] = "Al inicio de tu fase de Compra, gana un Oro.";
SpanishCardTexts[CardNames.ACADEMY] = "Cuando ganes una carta de Acci\xF3n,//|+1 Aldeano|.";
SpanishCardTexts[CardNames.BARRACKS] = "Al inicio de tu turno, |+1 Acci\xF3n|.";
SpanishCardTexts[CardNames.CANAL] = "Durante tus turnos, las cartas cuestan [1] menos.";
SpanishCardTexts[CardNames.CAPITALISM] = "Durante tus turnos, las Acciones con cantidades//+[] en su texto son tambi\xE9n Tesoros.";
SpanishCardTexts[CardNames.CATHEDRAL] = "Al inicio de tu turno,//elimina una carta de tu mano.";
SpanishCardTexts[CardNames.CITADEL] = "La primera vez que juegues una carta de Acci\xF3n durante//cada uno de tus turnos, ju\xE9galo de nuevo a continuaci\xF3n."; // TRANSLATE; use the same phrase as on Scepter and Royal Carriage (english: "Replay")
SpanishCardTexts[CardNames.CITY_GATE] = "Al inicio de tu turno, |+1 Carta|,//luego, pon una carta de tu mano sobre tu mazo.";
SpanishCardTexts[CardNames.CROP_ROTATION] = "Al inicio de tu turno, puedes descartar//una carta de Victoria para |+2 Cartas|.";
SpanishCardTexts[CardNames.EXPLORATION] = "Al final de tu fase de Compra, si no compraste//ninguna carta, |+1 Cofre| y |+1 Aldeano|.";
SpanishCardTexts[CardNames.FAIR] = "Al inicio de tu turno, |+1 Compra|.";
SpanishCardTexts[CardNames.FLEET] = "Despu\xE9s de que la partida finalice, juega una ronda//adicional de turnos para los jugadores con este Proyecto.";
SpanishCardTexts[CardNames.GUILDHALL] = "Cuando ganes un Tesoro, |+1 Cofre|.";
SpanishCardTexts[CardNames.INNOVATION] = "La primera vez que ganes una carta de Acci\xF3n en cada//uno de tus turnos, puedes apartarla. Si lo haces, ju\xE9gala.";
SpanishCardTexts[CardNames.PAGEANT] = "Al final de tu fase de Compra,//puedes pagar [1] para |+1 Cofre|.";
SpanishCardTexts[CardNames.PIAZZA] = "Al inicio de tu turno, revela la carta superior//de tu mazo. Si es una Acci\xF3n, ju\xE9gala.";
SpanishCardTexts[CardNames.ROAD_NETWORK] = "Cuando un rival gane una carta de Victoria,//|+1 Carta|.";
SpanishCardTexts[CardNames.SEWERS] = "Cuando elimines una carta con algo que no sea esto,//puedes eliminar una carta de tu mano.";
SpanishCardTexts[CardNames.SILOS] = "Al inicio de tu turno, descarta cualquier n\xFAmero de//Cobres, revel\xE1ndolos, y roba ese mismo n\xFAmero de cartas.";
SpanishCardTexts[CardNames.SINISTER_PLOT] = "Al inicio de tu turno, a\xF1ade una ficha aqu\xED,//o quita las fichas para |+1 Carta| por ficha.";
SpanishCardTexts[CardNames.STAR_CHART] = "Cuando barajes, puedes elegir una//de las cartas para ponerla encima del mazo.";
SpanishCardTexts[CardNames.SAUNA] = "|+1 Carta|//|+1 Acci\xF3n|////Puedes jugar un Avanto//de tu mano.//---//Mientras est\xE9 en juego, al jugar//una Plata, puedes eliminar//una carta de tu mano.";
SpanishCardTexts[CardNames.AVANTO] = "|+3 Cartas|////Puedes jugar una Sauna//de tu mano.";
SpanishCardTexts[CardNames.BLACK_MARKET] = "|+[2]|////Revela las 3 cartas superiores del mazo de//Mercado Negro. Juega cualquier n\xFAmero de//Tesoros de tu mano. Puedes comprar una de//las cartas reveladas. Pon el resto debajo del//mazo de Mercado Negro en cualquier orden.//---//Preparaci\xF3n: haz un mazo de Mercado Negro//con diferentes cartas de Reino sin usar.";
SpanishCardTexts[CardNames.ENVOY] = "Revela las 5 cartas superiores//de tu mazo. El rival de//tu izquierda elige una.//Descarta esa carta//y pon el resto en tu mano.";
SpanishCardTexts[CardNames.GOVERNOR] = "|+1 Acci\xF3n|////Elige una; tu recibes la versi\xF3n entre//par\xE9ntesis: cada jugador recibe//|+1 (+3) Cartas|; o cada jugador gana//una Plata (Oro); o cada jugador//puede eliminar una carta de su//mano y ganar una carta que cueste//exactamente [1]([2]) m\xE1s.";
SpanishCardTexts[CardNames.PRINCE] = "Puedes apartar esta carta. Si lo//haces, aparta una carta de Acci\xF3n//de tu mano que cueste hasta [4].//Al inicio de cada uno de tus turnos,//juega esa Acci\xF3n, apart\xE1ndola de//nuevo al descartarla del \xE1rea de juego.//(Deja de jugarla si no logras apartarla//el turno que la jugaste.)"; // TRANSLATE, english text changed to "You may set aside (on this)//a non-Duration Action card//from your hand costing up//to [4]. At the start of each//of your turns, play that//card, leaving it set aside.";
// Spanish text: "You may set aside (on this)//a non-Duration Action card//from your hand costing up//to [4]. At the start of each//of your turns, play that//card, leaving it set aside.";
SpanishCardTexts[CardNames.STASH] = "[!2]//---//Cuando barajes, si esta carta//est\xE1 en tu mazo, puedes ponerla//en cualquier lugar de tu mazo.";
SpanishCardTexts[CardNames.SUMMON] = "Gana una carta de Acci\xF3n que cueste hasta [4]. Ap\xE1rtala.//Si lo hiciste, ju\xE9gala al inicio de tu pr\xF3ximo turno.";
SpanishCardTexts[CardNames.WALLED_VILLAGE] = "|+1 Carta|//|+2 Acciones|//---//Al inicio de tu Mantenimiento,//si tienes esta y no m\xE1s de otra//carta de Acci\xF3n en juego, puedes//poner esta carta sobre tu mazo.";
SpanishCardTexts[CardNames.BLACK_MARKET_PILE] = "";
SpanishCardTexts[CardNames.DISMANTLE] = "Elimina una carta de tu mano.//Si cuesta [1] o m\xE1s, gana una//carta m\xE1s barata y un Oro.";
SpanishCardTexts[CardNames.CAPTAIN] = "Ahora y al inicio de// tu pr\xF3ximo turno://juega una carta de Acci\xF3n//del Suministro, (que no sea//Duraci\xF3n o Mandato) que//cueste hasta [4], sin moverla.";
SpanishCardTexts[CardNames.CHURCH] = "|+1 Acci\xF3n|////Aparta hasta 3 cartas de tu//mano bocabajo. Al inicio//de tu pr\xF3ximo turno, ponlas//en tu mano. Luego, puedes//eliminar una carta de tu mano.";
SpanishCardTexts[CardNames.BLACK_CAT] = "|+2 Cartas|//Si no es tu turno, cada//rival gana una Maldici\xF3n.//---//Cuando un rival gane una//carta de Victoria, puedes//jugar esta carta de tu mano.";
SpanishCardTexts[CardNames.SLEIGH] = "Gana 2 Caballos.//---//Cuando ganes una carta,//puedes descartar esto//para poner la carta ganada//en tu mano o sobre tu mazo.";
SpanishCardTexts[CardNames.SUPPLIES] = "[!1]////Al jugar esta carta, gana//un Caballo sobre tu mazo.";
SpanishCardTexts[CardNames.CAMEL_TRAIN] = "Exilia una carta del Suministro//(que no sea Victoria).//---//Cuando ganes esta carta, exilia//un Oro del Suministro.";
SpanishCardTexts[CardNames.GOATHERD] = "|+1 Acci\xF3n|////Puedes eliminar//una carta de tu mano.////|+1 Carta| por cada carta//que el jugador de tu derecha//elimin\xF3 en su \xFAltimo turno.";
SpanishCardTexts[CardNames.SCRAP] = "Elimina una carta de tu mano.//Elige una cosa diferente por//cada [1] de coste: |+1 Carta|;//|+1 Acci\xF3n|; |+1 Compra|; |+|[1];//gana una Plata; gana un Caballo.";
SpanishCardTexts[CardNames.SHEEPDOG] = "|+2 Cartas|//---//Cuando ganes una carta,//puedes jugar esta carta//de tu mano.";
SpanishCardTexts[CardNames.SNOWY_VILLAGE] = "|+1 Carta|//|+4 Acciones|//|+1 Compra|////Ignora cualquier |+Acciones|//adicional que recibas este turno.";
SpanishCardTexts[CardNames.STOCKPILE] = "[!3]////|+1 Compra|////Al jugar esta carta, ex\xEDliala.";
SpanishCardTexts[CardNames.BOUNTY_HUNTER] = "|+1 Acci\xF3n|////Exilia una carta de tu mano.//Si no ten\xEDas una copia suya//en el Exilio, |+|[3].";
SpanishCardTexts[CardNames.CARDINAL] = "|+[2]|////Cada rival revela las 2 cartas//superiores de su mazo,//Exilia una que cueste entre [3]//y [6], y descarta el resto.";
SpanishCardTexts[CardNames.CAVALRY] = "Gana 2 Caballos.//---//Cuando ganes esta carta,//|+2 Cartas|, |+1 Compra|, y si//es tu fase de Compra vuelve//a tu fase de Acci\xF3n.";
SpanishCardTexts[CardNames.GROOM] = "Gana una carta que cueste //hasta [4]. Si es una carta de...////Acci\xF3n, gana un Caballo//Tesoro, gana una Plata//Victoria,|+1 Carta|//&&& y |+1 Acci\xF3n|";
SpanishCardTexts[CardNames.HOSTELRY] = "|+1 Carta|//|+2 Acciones|//---//Cuando ganes esta carta, puedes//descartar cualquier cantidad de//Tesoros, revel\xE1ndolos, para ganar//esa misma cantidad de Caballos.";
SpanishCardTexts[CardNames.VILLAGE_GREEN] = "Bien ahora, o al inicio//de tu pr\xF3ximo turno,//|+1 Carta| y |+2 Acciones.|//---//Cuando descartes esta carta fuera//de tu fase de Mantenimiento,//puedes revelarla para jugarla.";
SpanishCardTexts[CardNames.BARGE] = "Bien ahora, o al inicio//de tu pr\xF3ximo turno,//|+3 Cartas| y |+1 Compra.|";
SpanishCardTexts[CardNames.COVEN] = "|+1 Acci\xF3n|//|+[2]|////Cada rival exilia una//Maldici\xF3n del Suministro.//Si no puede, descarta//sus Maldiciones exiliadas.";
SpanishCardTexts[CardNames.DISPLACE] = "Exilia una carta de tu mano.//Gana una carta de distinto//nombre que cueste//hasta [2] m\xE1s que ella.";
SpanishCardTexts[CardNames.FALCONER] = "Gana una carta a tu mano//que cueste menos que esta.//---//Cuando cualquier jugador gane//una carta con 2 o m\xE1s tipos//(Acci\xF3n, Ataque, etc.), puedes//jugar esta de tu mano.";
SpanishCardTexts[CardNames.FISHERMAN] = "|+1 Carta|//|+1 Acci\xF3n|//|+[3]|//---//Durante tus turnos, si tu//pila de descartes est\xE1 vac\xEDa,//esta carta cuesta [1] menos.";
SpanishCardTexts[CardNames.GATEKEEPER] = "Al inicio de tu pr\xF3ximo//turno, |+[3]|. Hasta entonces,//cuando un rival gane//una Acci\xF3n o un Tesoro//del que no tenga una//copia exiliada, lo exilia.";
SpanishCardTexts[CardNames.HUNTING_LODGE] = "|+1 Carta|//|+2 Acciones|////Puedes descartar tu//mano para |+5 Cartas|.";
SpanishCardTexts[CardNames.KILN] = "|+[2]|////La pr\xF3xima vez que juegues//una carta este turno, puedes//primero ganar una copia.";
SpanishCardTexts[CardNames.LIVERY] = "|+[3]|////Este turno, cuando//ganes una carta que cueste//[4] o m\xE1s, gana un Caballo.";
SpanishCardTexts[CardNames.MASTERMIND] = "Al inicio de tu pr\xF3ximo//turno, puedes jugar una//carta de Acci\xF3n de tu//mano tres veces.";
SpanishCardTexts[CardNames.PADDOCK] = "|+[2]|////Gana 2 Caballos.////|+1 Acci\xF3n| por cada//pila del Suministro vac\xEDa.";
SpanishCardTexts[CardNames.SANCTUARY] = "|+1 Carta|//|+1 Acci\xF3n|//|+1 Compra|////Puedes exiliar una//carta de tu mano.";
SpanishCardTexts[CardNames.DESTRIER] = "|+2 Cartas|//|+1 Acci\xF3n|//---//Durante tus turnos, esta carta//cuesta [1] menos por carta//que hayas ganado este turno.";
SpanishCardTexts[CardNames.WAYFARER] = "|+3 Cartas|////Puedes ganar una Plata.//---//Esta carta tiene el mismo //coste que la \xFAltima carta//ganada este turno, si la hay.";
SpanishCardTexts[CardNames.ANIMAL_FAIR] = "|+[4]|////|+1 Compra| por cada pila//vac\xEDa del Suministro.//---//En lugar de pagar el coste de//esta carta, puedes eliminar una//carta de Acci\xF3n de tu mano.";
SpanishCardTexts[CardNames.HORSE] = "|+2 Cartas|//|+1 Acci\xF3n|////Devuelve esta carta//a la pila de Caballos.//%(No pertenece al Suministro.)%";
SpanishCardTexts[CardNames.WAY_OF_THE_BUTTERFLY] = "Puedes devolver esta carta a su pila para ganar//una carta que cueste exactamente [1] m\xE1s que ella.";
SpanishCardTexts[CardNames.WAY_OF_THE_CAMEL] = "Exilia un Oro del Suministro.";
SpanishCardTexts[CardNames.WAY_OF_THE_CHAMELEON] = "Sigue las instrucciones de esta carta;//cada vez que te diera |+Cartas| este turno,//recibes |+|[] en su lugar, y viceversa.";
SpanishCardTexts[CardNames.WAY_OF_THE_FROG] = "|+1 Acci\xF3n|////Cuando lo descartes del \xE1rea de juego//este turno, ponlo sobre tu mazo.";
SpanishCardTexts[CardNames.WAY_OF_THE_GOAT] = "Elimina una carta de tu mano.";
SpanishCardTexts[CardNames.WAY_OF_THE_HORSE] = "|+2 Cartas|//|+1 Acci\xF3n|////Devu\xE9lvelo a su pila.";
SpanishCardTexts[CardNames.WAY_OF_THE_MOLE] = "|+1 Acci\xF3n|////Descarta tu mano. |+3 Cartas.|";
SpanishCardTexts[CardNames.WAY_OF_THE_MONKEY] = "|+1 Compra|//|+[1]|";
SpanishCardTexts[CardNames.WAY_OF_THE_MOUSE] = "Juega la carta apartada, sin moverla.//---//Preparaci\xF3n: aparta una Acci\xF3n no usada que cueste [2] o [3].";
SpanishCardTexts[CardNames.WAY_OF_THE_MULE] = "|+1 Acci\xF3n|//|+[1]|";
SpanishCardTexts[CardNames.WAY_OF_THE_OTTER] = "|+2 Cartas|";
SpanishCardTexts[CardNames.WAY_OF_THE_OWL] = "Roba hasta tener 6 cartas en la mano.";
SpanishCardTexts[CardNames.WAY_OF_THE_OX] = "|+2 Acciones|";
SpanishCardTexts[CardNames.WAY_OF_THE_PIG] = "|+1 Carta|//|+1 Acci\xF3n|";
SpanishCardTexts[CardNames.WAY_OF_THE_RAT] = "Puedes descartar un Tesoro//para ganar una copia de esta carta.";
SpanishCardTexts[CardNames.WAY_OF_THE_SEAL] = "|+[1]|////Este turno, cuando ganes una carta,//puedes ponerla sobre tu mazo.";
SpanishCardTexts[CardNames.WAY_OF_THE_SHEEP] = "|+[2]|";
SpanishCardTexts[CardNames.WAY_OF_THE_SQUIRREL] = "|+2 Cartas| al final de este turno.";
SpanishCardTexts[CardNames.WAY_OF_THE_TURTLE] = "Aparta esta carta. Si lo hiciste,//ju\xE9gala al inicio de tu pr\xF3ximo turno.";
SpanishCardTexts[CardNames.WAY_OF_THE_WORM] = "Exilia una Finca del Suministro.";
SpanishCardTexts[CardNames.DELAY] = "Puedes apartar una carta de Acci\xF3n de tu//mano. Al inicio de tu pr\xF3ximo turno, ju\xE9gala.";
SpanishCardTexts[CardNames.DESPERATION] = "Una vez por turno: puedes ganar una Maldici\xF3n.//Si lo haces, |+1 Compra| y |+|[2].";
SpanishCardTexts[CardNames.GAMBLE] = "|+1 Compra|////Revela la carta superior de tu mazo. Si es un Tesoro//o Acci\xF3n, puedes jugarlo. En caso contrario, desc\xE1rtalo.";
SpanishCardTexts[CardNames.PURSUE] = "|+1 Compra|////Nombra una carta. Revela las 4 cartas superiores de//tu mazo. Devuelve las coincidencias y descarta el resto.";
SpanishCardTexts[CardNames.RIDE] = "Gana un Caballo.";
SpanishCardTexts[CardNames.TOIL] = "|+1 Compra|////Puedes jugar una carta de Acci\xF3n de tu mano.";
SpanishCardTexts[CardNames.ENHANCE] = "Puedes eliminar una carta (que no sea Victoria) de tu mano//para ganar una carta que cueste hasta [2] m\xE1s que ella. ";
SpanishCardTexts[CardNames.MARCH] = "Mira en tu pila de descartes.//Puedes jugar una carta de Acci\xF3n de ella.";
SpanishCardTexts[CardNames.TRANSPORT] = "Elige una: exilia una carta de Acci\xF3n del//Suministro; o pon una carta de Acci\xF3n//que tengas en el Exilio sobre tu mazo.";
SpanishCardTexts[CardNames.BANISH] = "Exilia cualquier n\xFAmero de cartas con//el mismo nombre de tu mano.";
SpanishCardTexts[CardNames.BARGAIN] = "Gana una carta (que no sea de Victoria)//que cueste hasta [5]. Cada rival gana un Caballo.";
SpanishCardTexts[CardNames.INVEST] = "Exilia una carta de Acci\xF3n del Suministro.//Mientras est\xE9 en el Exilio, cuando un rival//gane o Invierta en una copia, |+2 Cartas|.";
SpanishCardTexts[CardNames.SEIZE_THE_DAY] = "Una vez por partida://juega un turno adicional despu\xE9s de este.";
SpanishCardTexts[CardNames.COMMERCE] = "Gana un Oro por cada carta de nombre//distinto que hayas ganado este turno.";
SpanishCardTexts[CardNames.DEMAND] = "Gana un Caballo y una carta que cueste//hasta [4], ambas sobre tu mazo.";
SpanishCardTexts[CardNames.STAMPEDE] = "Si tienes 5 o menos cartas en juego,//gana 5 Caballos sobre tu mazo.";
SpanishCardTexts[CardNames.REAP] = "Gana un Oro. Ap\xE1rtalo. Si lo haces,//al inicio de tu pr\xF3ximo turno, ju\xE9galo.";
SpanishCardTexts[CardNames.ENCLAVE] = "Gana un Oro. Exilia un Ducado del Suministro.";
SpanishCardTexts[CardNames.ALLIANCE] = "Gana una Provincia, un Ducado, una Finca,//un Oro, una Plata, y un Cobre.";
SpanishCardTexts[CardNames.POPULATE] = "Gana una carta de cada pila//de Acci\xF3n del Suministro.";
SpanishCardTexts[CardNames.CARD_OF_THE_BUTTERFLY] = "";
SpanishCardTexts[CardNames.CARD_OF_THE_CAMEL] = "";
SpanishCardTexts[CardNames.CARD_OF_THE_CHAMELEON] = "";
SpanishCardTexts[CardNames.CARD_OF_THE_FROG] = "";
SpanishCardTexts[CardNames.CARD_OF_THE_GOAT] = "";
SpanishCardTexts[CardNames.CARD_OF_THE_HORSE] = "";
SpanishCardTexts[CardNames.CARD_OF_THE_MOLE] = "";
SpanishCardTexts[CardNames.CARD_OF_THE_MONKEY] = "";
SpanishCardTexts[CardNames.CARD_OF_THE_MOUSE] = "";
SpanishCardTexts[CardNames.CARD_OF_THE_MULE] = "";
SpanishCardTexts[CardNames.CARD_OF_THE_OTTER] = "";
SpanishCardTexts[CardNames.CARD_OF_THE_OWL] = "";
SpanishCardTexts[CardNames.CARD_OF_THE_OX] = "";
SpanishCardTexts[CardNames.CARD_OF_THE_PIG] = "";
SpanishCardTexts[CardNames.CARD_OF_THE_RAT] = "";
SpanishCardTexts[CardNames.CARD_OF_THE_SEAL] = "";
SpanishCardTexts[CardNames.CARD_OF_THE_SHEEP] = "";
SpanishCardTexts[CardNames.CARD_OF_THE_SQUIRREL] = "";
SpanishCardTexts[CardNames.CARD_OF_THE_TURTLE] = "";
SpanishCardTexts[CardNames.CARD_OF_THE_WORM] = "";

SpanishCardTexts[CardNames.TOWN] = "Choose one://|+1 Card| and |+2 Actions|;// or |+1 Buy| and +[2].";
SpanishCardTexts[CardNames.MODIFY] = "Trash a card from your hand.//Choose one: |+1 Card| and//|+1 Action|; or gain a card//costing up to [2] more//than the trashed card.";
SpanishCardTexts[CardNames.MERCHANT_CAMP] = "|+2 Actions|//+[1]//---//When you discard this//from play, you may//put it onto your deck.";
SpanishCardTexts[CardNames.SWAP] = "|+1 Card|//|+1 Action|//You may return an Action card//from your hand to its pile,//to gain to your hand a different//Action card costing up to [5].";
SpanishCardTexts[CardNames.SKIRMISHER] = "|+1 Card|//|+1 Action|//|+[1]|//This turn, when you gain//an Attack card, each other//player discards down to//3 cards in hand.";
SpanishCardTexts[CardNames.HIGHWAYMAN] = "At the start of your next//turn, discard this from//play and |+3 Cards|.//Until then, the first Treasure//each other player plays//each turn does nothing.";
SpanishCardTexts[CardNames.BARBARIAN] = "|+[2]|//Each other player trashes the top//card of their deck. If it costs//[3] or more they gain a cheaper//card sharing a type with it;//otherwise they gain a Curse.";
SpanishCardTexts[CardNames.INNKEEPER] = "|+1 Action|//Choose one: |+1 Card|; //or |+3 Cards|, discard 3 Cards;//or |+5 Cards|, discard 6 cards.";
SpanishCardTexts[CardNames.CAPITAL_CITY] = "|+1 Card|//|+2 Actions|//You may discard 2 cards for +[2].//You may pay [2] for |+2 Cards|.";
SpanishCardTexts[CardNames.SPECIALIST] = "You may play an Action or//Treasure from your hand.//Choose one: Play it again;//or gain a copy of it.";
SpanishCardTexts[CardNames.CARPENTER] = "If no Supply piles are//empty, |+1 Action| and gain//a card costing up to [4].//Otherwise, trash a card from//your hand and gain a card//costing up to [2] more than it.";
SpanishCardTexts[CardNames.ROYAL_GALLEY] = "|+1 Card|//You may play a non-Duration//Action card from your hand.//Set it aside; if you did,//then at the start of your//next turn, play it.";
SpanishCardTexts[CardNames.MARQUIS] = "|+1 Buy|//|+1 Card| per card in//your hand. Discard down//to 10 cards in hand.";
SpanishCardTexts[CardNames.SENTINEL] = "Look at the top five cards//of your deck. Trash up to//two of them. Put the//rest back in any order.";
SpanishCardTexts[CardNames.COURIER] = "|+[1]|//Discard the top card of your//deck. Look through your//discard pile; you may play an//Action or Treasure from it.";
SpanishCardTexts[CardNames.GALLERIA] = "|+[3]|//This turn, when you//gain a card costing//[3] or [4], |+1 Buy|.";
SpanishCardTexts[CardNames.HUNTER] = "|+1 Action|//Reveal the top 3 cards of//your deck. From among them,//put an Action, a Treasure,//and a Victory card into//your hand. Discard the rest.";

SpanishCardTexts[CardNames.SYCOPHANT] = "|+1 Action|//Discard 3 cards. If you//discarded at least one, |+|[3].//---//When you gain or//trash this, |+2 Favors|.";
SpanishCardTexts[CardNames.UNDERLING] = "|+1 Card|//|+1 Action|//|+1 Favor|";
SpanishCardTexts[CardNames.IMPORTER] = "At the start of your//next turn, gain a card//costing up to [5].//---//Setup: Each player//gets +4 Favors.";
SpanishCardTexts[CardNames.CONTRACT] = "[!2]//|+1 Favor|//You may set aside an Action//from your hand to play it//at the start of your next turn.";
SpanishCardTexts[CardNames.EMISSARY] = "|+3 Cards|//If this made you shuffle//(at least one card),//|+1 Action| and |+2 Favors|.";
SpanishCardTexts[CardNames.BAUBLE] = "Choose two different options://|+1 Buy|; +[1]; |+1 Favor|;//this turn, when you gain a//card, you may put it//onto your deck.";
SpanishCardTexts[CardNames.BROKER] = "Trash a card from your hand.//Choose one, to get once per//[1] the trashed card costs:// |+1 Card|; |+1 Action|;//+[1]; or |+1 Favor|.";
SpanishCardTexts[CardNames.GUILDMASTER] = "|+[3]|//This turn, when you//gain a card, |+1 Favor|.";
SpanishCardTexts[CardNames.ARCHITECTS_GUILD] = "When you gain a card, you may spend//|2 Favors| to gain a cheaper non-Victory card.";
SpanishCardTexts[CardNames.ORDER_OF_ASTROLOGERS] = "When shuffling, you may pick one card//per |Favor| you spend to go on top.";
SpanishCardTexts[CardNames.LEAGUE_OF_BANKERS] = "At the start of your Buy phase, +[1]//per |4 Favor| tokens you have (round down).";
SpanishCardTexts[CardNames.MOUNTAIN_FOLK] = "At the start of your turn, you may//spend |5 Favors| for |+3 Cards|";
SpanishCardTexts[CardNames.WOODWORKERS_GUILD] = "At the start of your Buy phase, you may spend//a |Favor| to trash an Action card from your hand.//If you did, gain an Action card.";
SpanishCardTexts[CardNames.PEACEFUL_CULT] = "At the start of your Buy phase, spend any number of//|Favors| to trash that many cards from your hand.";
SpanishCardTexts[CardNames.CRAFTERS_GUILD] = "At the start of your turn, you may spend |2 Favors|//to gain a card costing up to [4] onto your deck.";
SpanishCardTexts[CardNames.CAVE_DWELLERS] = "At the start of your turn, you may spend a |Favor|,//to discard a card then draw a card. Repeat as desired.";
SpanishCardTexts[CardNames.PLATEAU_SHEPHERDS] = "When scoring, pair up your |Favors| with cards//you have costing [2], for {2} per pair.";
SpanishCardTexts[CardNames.DESERT_GUIDES] = "At the start of your turn, you may spend a |Favor| to//discard your hand and draw 5 cards. Repeat as desired.";
SpanishCardTexts[CardNames.FAMILY_OF_INVENTORS] = "At the start of your Buy phase, you may put a |Favor|//token on a non-Victory Supply pile. Cards in piles cost//[1] less per |Favor| token on them.";
SpanishCardTexts[CardNames.ISLAND_FOLK] = "At the end of your turn, if the previous//turn wasn't yours, you may spend//|5 Favors| to take another turn.";
SpanishCardTexts[CardNames.ORDER_OF_MASONS] = "When shuffling, you may pick up to two cards per//|Favor| you spend to put into your discard pile.";
SpanishCardTexts[CardNames.BAND_OF_NOMADS] = "When you gain a card costing [3] or more, you may//spend a Favor, for |+1 Card|, or |+1 Action|, or |+1 Buy|.";
SpanishCardTexts[CardNames.GANG_OF_PICKPOCKETS] = "At the start of your turn, discard down to//4 cards in hand unless you spend a |Favor|.";
SpanishCardTexts[CardNames.MARKET_TOWNS] = "At the start of your Buy phase,//you may spend a |Favor| to play an Action//card from your hand. Repeat as desired.";
SpanishCardTexts[CardNames.FOREST_DWELLERS] = "At the start of your turn, you may spend a |Favor| to//look at the top 3 cards of your deck. Discard any//number and put the rest back in any order.";
SpanishCardTexts[CardNames.FELLOWSHIP_OF_SCRIBES] = "After playing an Action, if you have 4 or fewer cards//in hand, you may spend a |Favor| for |+1 Card|.";
SpanishCardTexts[CardNames.LEAGUE_OF_SHOPKEEPERS] = "After you play a Liaison,//if you have |5| or more |Favors|, |+|[1],//and if |10| or more, |+1 Action| and |+1 Buy|.";
SpanishCardTexts[CardNames.COASTAL_HAVEN] = "When discarding your hand in Clean-up, you may spend//any number of |Favors| to keep that many cards//in hand for next turn (you still draw 5).";
SpanishCardTexts[CardNames.CITY_STATE] = "When you gain an Action card during your turn,//you may spend |2 Favors| to play it.";
SpanishCardTexts[CardNames.TRAPPERS_LODGE] = "When you gain a card, you may spend//a |Favor| to put it onto your deck.";
SpanishCardTexts[CardNames.CIRCLE_OF_WITCHES] = "After you play a Liaison, you may spend |3 Favors|//to have each other player gain a Curse.";
SpanishCardTexts[CardNames.AUGUR_PILE] = "This pile starts the game//with 4 copies each of//Herb Gatherer, Acolyte//Sorceress, and Sibyl, in that//order. Only the top card can//be gained or bought.";
SpanishCardTexts[CardNames.HERB_GATHERER] = "|+1 Buy|//Put your deck into your//discard pile. Look through//it and you may play//a Treasure from it. You//may rotate the Augurs.";
SpanishCardTexts[CardNames.ACOLYTE] = "You may trash an//Action or Victory card//from your hand to gain//a Gold. You may trash//this to gain an Augur.";
SpanishCardTexts[CardNames.SORCERESS] = "|+1 Action|//Name a card. Reveal the//top card of your deck and//put it into your hand.//If it matches, each other//player gains a Curse.";
SpanishCardTexts[CardNames.SIBYL] = "|+4 Cards|//|+1 Action|//Put a card from your hand//on top of your deck, and//another on the bottom.";
SpanishCardTexts[CardNames.CLASH_PILE] = "This pile starts the game//with 4 copies each of//Battle Plan, Archer, Warlord,//and Territory, in that order.//Only the top card can//be gained or bought.";
SpanishCardTexts[CardNames.BATTLE_PLAN] = "|+1 Card|//|+1 Action|//You may reveal an Attack card//from your hand for |+1 Card|.//You may rotate any Supply pile.";
SpanishCardTexts[CardNames.ARCHER] = "|+[2]|//Each other player with 5//or more cards in hand reveals//all but one and discards//one of those you choose.";
SpanishCardTexts[CardNames.WARLORD] = "|+1 Action|//At the start of your next turn,//|+2 Cards|. Until then, other//players can't play an Action//from their hand that they have//2 or more copies of in play.";
SpanishCardTexts[CardNames.TERRITORY] = "Worth {1} per differently//named Victory card you have.//---//When you gain this, gain//a Gold per empty Supply pile.";
SpanishCardTexts[CardNames.FORT_PILE] = "This pile starts the game//with 4 copies each of Tent,//Garrison, Hill Fort, and//Stronghold, in that order.//Only the top card can//be gained or bought.";
SpanishCardTexts[CardNames.TENT] = "|+[2]|//You may rotate the Forts.//---//When you discard this//from play, you may//put it onto your deck.";
SpanishCardTexts[CardNames.GARRISON] = "|+[2]|//This turn, when you gain//a card, add a token here.//At the start of your//next turn, remove them//for |+1 Card| each.";
SpanishCardTexts[CardNames.HILL_FORT] = "Gain a card costing up//to [4]. Choose one://Put it into your hand;//or |+1 Card| and//|+1 Action|.";
SpanishCardTexts[CardNames.STRONGHOLD] = "Choose one: |+|[3];//or at the start of your//next turn, |+3 Cards.|//---//{!2}";
SpanishCardTexts[CardNames.ODYSSEY_PILE] = "This pile starts the game//with 4 copies each of//Old Map, Voyage, Sunken//Treasure, and Distant Shore,//in that order. Only the top//card can be gained or bought.";
SpanishCardTexts[CardNames.OLD_MAP] = "|+1 Card|//|+1 Action|//Discard a card. |+1 Card|.//You may rotate the Odysseys.";
SpanishCardTexts[CardNames.VOYAGE] = "|+1 Action|//If the previous turn wasn't//yours, take an extra turn//after this one, during which//you can only play 3 cards//from your hand.";
SpanishCardTexts[CardNames.SUNKEN_TREASURE] = "Gain an Action card//you don't have//a copy of in play.";
SpanishCardTexts[CardNames.DISTANT_SHORE] = "|+2 Cards|//|+1 Action|//Gain an Estate.//---//{!2}";
SpanishCardTexts[CardNames.TOWNSFOLK_PILE] = "This pile starts the game//with 4 copies each of//Town Crier, Blacksmith//Miller, and Elder, in that//order. Only the top card can//be gained or bought.";
SpanishCardTexts[CardNames.TOWN_CRIER] = "Choose one: |+|[2]; or//gain a Silver; or//|+1 Card| and |+1 Action|.////You may rotate//the Townsfolk. ";
SpanishCardTexts[CardNames.BLACKSMITH] = "Choose one: draw until you//have six cards in hand; or//|+2 Cards|; or//|+1 Card| and |+1 Action|.";
SpanishCardTexts[CardNames.MILLER] = "|+1 Action|//Look at the top four cards of//your deck. Put one into your//hand and discard the rest.";
SpanishCardTexts[CardNames.ELDER] = "|+[2]|//You may play an Action card from//your hand. If it gives you a choice//of abilities (e.g. \"choose one\")//this turn, you may choose//an extra (different) thing.";
SpanishCardTexts[CardNames.WIZARD_PILE] = "This pile starts the game//with 4 copies each of//Student, Conjurer, Sorcerer,//and Lich, in that order.//Only the top card can be//gained or bought.";
SpanishCardTexts[CardNames.STUDENT] = "|+1 Action|//You may rotate the Wizards.//Trash a card from your hand.//If it's a Treasure, |+1 Favor|// and put this onto your deck.";
SpanishCardTexts[CardNames.CONJURER] = "Gain a card costing//up to [4]. At the start//of your next turn,//put this into your hand.";
SpanishCardTexts[CardNames.SORCERER] = "|+1 Card|//|+1 Action|//Each other player names//a card, then reveals the//top card of their deck. If//wrong, they gain a Curse.";
SpanishCardTexts[CardNames.LICH] = "|+6 Cards|//|+2 Actions|//Skip a turn.//---//When you trash this, discard//it and gain a cheaper card//from the trash.";

SpanishCardTexts[CardNames.MONKEY] = "Until your next turn, when the//player to your right gains//a card, |+1 Card|. At the start//of your next turn, |+1 Card|.";
SpanishCardTexts[CardNames.BLOCKADE] = "Gain a card costing up to [4],//setting it aside. At the start of//your next turn, put it into your//hand. While it's set aside,//when another player gains//a copy of it on their turn,//they gain a Curse.";
SpanishCardTexts[CardNames.SAILOR] = "|+1 Action|//Once this turn, when you gain a//Duration card, you may play it.//At the start of your next turn,//+[2] and you may trash a//card from your hand.";
SpanishCardTexts[CardNames.PIRATE] = "At the start of your next//turn, gain a Treasure costing//up to [6] to your hand.//---//When any player gains//a Treasure, you may play//this from your hand.";
SpanishCardTexts[CardNames.CORSAIR] = "|+[2]|//At the start of your next turn,//|+1 Card|. Until then, each other//player trashes the first Silver//or Gold they play each turn.";
SpanishCardTexts[CardNames.SEA_CHART] = "|+1 Card|//|+1 Action|//Reveal the top card of your deck.//Put it into your hand if//you have a copy of it in play.";
SpanishCardTexts[CardNames.ASTROLABE] = "Now and at the start//of your next turn:////[!1]//|+1 Buy|";
SpanishCardTexts[CardNames.TIDE_POOLS] = "|+3 Cards|//|+1 Action|//At the start of your next//turn, discard 2 cards.";
SpanishCardTexts[CardNames.SEA_WITCH] = "|+2 Cards|//Each other player gains//a Curse. At the start of//your next turn, |+2 Cards|,//then discard 2 cards.";

SpanishCardTexts[CardNames.HOARD] = "[!2]//---//Mientras que tienes este en//juego, cuando compres una//carta de Victoria, gana un Oro.";
SpanishCardTexts[CardNames.MINT] = "Puedes revelar un Tesoro de//tu mano. Gana una copia.//---//Cuando compres esta carta,//elimina todos los Tesoros//que tengas en juego.";
SpanishCardTexts[CardNames.QUARRY] = "[2]//---//Mientras est\xE9 en juego, las cartas//de Acci\xF3n cuestan [!1] menos";
SpanishCardTexts[CardNames.ANVIL] = "[!1]//You may discard a//Treasure to gain a card//costing up to [4].";
SpanishCardTexts[CardNames.CLERK] = "|+[2]|//Each other player with 5//or more cards in hand//puts one onto their deck.//---//At the start of your turn, you//may play this from your hand.";
SpanishCardTexts[CardNames.INVESTMENT] = "Trash a card from your hand.//Choose one: +[1]; or trash//this to reveal your hand//for +{1} per differently//named Treasure there.";
SpanishCardTexts[CardNames.TIARA] = "|+1 Buy|//This turn, when you gain a//card, you may put it onto//your deck. You may play a//Treasure from your hand twice.";
SpanishCardTexts[CardNames.CHARLATAN] = "|+[3]|//Each other player gains a Curse.//---//In games using this, Curse is//also a Treasure worth [1].";
SpanishCardTexts[CardNames.COLLECTION] = "[!2]//|+1 Buy|//This turn, when you gain//an Action card, +{1}.";
SpanishCardTexts[CardNames.CRYSTAL_BALL] = "[!1]//Look at the top card of//your deck. You may trash it,//discard it, or, if it's an Action//or Treasure, you may play it.";
SpanishCardTexts[CardNames.MAGNATE] = "Reveal your hand. //|+1 Card| per Treasure in it.";
SpanishCardTexts[CardNames.WAR_CHEST] = "The player to your left//names a card. Gain a card//costing up to [5] that//hasn't been named for//War Chests this turn.";

SpanishCardTexts[CardNames.FARMLAND] = "[2]//---//Cuando compres esta carta, elimina//una carta de tu mano y gana una carta//que cueste exactamente {!2} m\xE1s.";
SpanishCardTexts[CardNames.HAGGLER] = "|+[2]|//---//Mientras est\xE9 en juego,//cuando compres una carta,//gana una carta m\xE1s barata//(que no sea de Victoria).";
SpanishCardTexts[CardNames.HIGHWAY] = "|+1 Carta|//|+1 Acci\xF3n|//---//Mientras est\xE9 en juego,//las cartas cuestan [1] menos.";
SpanishCardTexts[CardNames.BERSERKER] = "Gain a card costing less than this.//Each other player discards down//to 3 cards in hand.//---//When you gain this, if you have//an Action in play, play this.";
SpanishCardTexts[CardNames.GUARD_DOG] = "|+2 Cards|//If you have 5 or fewer cards//in hand, |+2 Cards|.//---//When another player plays an//Attack, you may first play this//from your hand.";
SpanishCardTexts[CardNames.NOMADS] = "|+1 Buy|//|+[2]|//---//When you gain or//trash this, +[2].";
SpanishCardTexts[CardNames.TRAIL] = "|+1 Card|//|+1 Action|//---//When you gain, trash,//or discard this other//than in Clean-up,//you may play it.";
SpanishCardTexts[CardNames.WEAVER] = "Gain two Silvers or a//card costing up to [4].//---//When you discard this//other than in Clean-up,//you may play it.";
SpanishCardTexts[CardNames.SOUK] = "|+1 Buy|//|+[7]|//-[1] per card in your hand.//(You can't go below [0].)//---//When you gain this, trash up//to 2 cards from your hand.";
SpanishCardTexts[CardNames.CAULDRON] = "[!2]//|+1 Buy|//The third time you gain//an Action this turn, each//other player gains a Curse.";
SpanishCardTexts[CardNames.WHEELWRIGHT] = "|+1 Card|//|+1 Action|//You may discard a card//to gain an Action card//costing as much or less.";
SpanishCardTexts[CardNames.WITCHS_HUT] = "|+4 Cards|//Discard 2 cards, revealed.//If they're both Actions,//each other player//gains a Curse.";

// Missing lines as of 09.01.2023 18:45:04
SpanishCardTexts[CardNames.CAGE] = "Set aside up to 4 cards from//your hand face down (on this).//The next time you gain a//Victory card, trash this, and put//the set aside cards into your//hand at end of turn.";
SpanishCardTexts[CardNames.GROTTO] = "|+1 Action|////Set aside up to 4 cards from//your hand face down (on this).//At the start of your next turn,//discard them, then draw as many.";
SpanishCardTexts[CardNames.JEWELLED_EGG] = "[!1]//|+1 Buy|//---//When you trash this, gain//a Loot.";
SpanishCardTexts[CardNames.SEARCH] = "|+[2]|////The next time a Supply pile//empties, trash this and//gain a Loot.";
SpanishCardTexts[CardNames.SHAMAN] = "|+1 Action|//|+[1]|////You may trash a card from//your hand.//---//In games using this, at the start//of your turn, gain a card from//the trash costing up to [6].";
SpanishCardTexts[CardNames.SECLUDED_SHRINE] = "|+[1]|////The next time you gain//a Treasure, trash up to//2 cards from your hand.";
SpanishCardTexts[CardNames.SIREN] = "Each other player gains//a Curse.////At the start of your next turn,//draw up to 8 cards in hand.//---//When you gain this, trash it//unless you trash an Action//from your hand.";
SpanishCardTexts[CardNames.STOWAWAY] = "At the start of your next//turn, |+2 Cards|.//---//When anyone gains a Duration//card, you may play this//from your hand.";
SpanishCardTexts[CardNames.TASKMASTER] = "|+1 Action|, |+|[1], and if you//gain a card costing exactly [5]//this turn, then at the start//of your next turn, repeat//this ability.";
SpanishCardTexts[CardNames.ABUNDANCE] = "The next time you gain//an Action card,//|+1 Buy| and |+|[3].";
SpanishCardTexts[CardNames.CABIN_BOY] = "|+1 Card|//|+1 Action|////At the start of your next turn,//choose one: |+|[2]; or trash this//to gain a Duration card.";
SpanishCardTexts[CardNames.CRUCIBLE] = "Trash a card from your hand.//|+|[1] per [1] it costs.";
SpanishCardTexts[CardNames.FLAGSHIP] = "|+[2]|////The next time you play a//non-Command Action//card, replay it.";
SpanishCardTexts[CardNames.FORTUNE_HUNTER] = "|+[2]|////Look at the top 3 cards of//your deck. You may play//a Treasure from them. Put//the rest back in any order.";
SpanishCardTexts[CardNames.GONDOLA] = "Either now or at the start of//your next turn: |+|[2].//---//When you gain this, you//may play an Action card//from your hand.";
SpanishCardTexts[CardNames.HARBOR_VILLAGE] = "|+1 Card|//|+2 Actions|////After the next Action you play//this turn, if it gave you//|+|[], |+|[1].";
SpanishCardTexts[CardNames.LANDING_PARTY] = "|+2 Cards|//|+2 Actions|////The next time the first card you// play on a turn is a Treasure,//put this onto your deck//afterwards.";
SpanishCardTexts[CardNames.MAPMAKER] = "Look at the top 4 cards//of your deck. Put 2 into your//hand and discard the rest.//---//When any player gains a Victory//card, you may play this//from your hand.";
SpanishCardTexts[CardNames.MAROON] = "Trash a card from your hand.//|+2 Cards| per type it has//(Action, Attack, etc.).";
SpanishCardTexts[CardNames.ROPE] = "[!1]//|+1 Buy|////At the start of your next turn,//|+1 Card| and you may trash a//card from your hand.";
SpanishCardTexts[CardNames.SWAMP_SHACKS] = "|+2 Actions|////|+1 Card| per 3 cards you//have in play (round down).";
SpanishCardTexts[CardNames.TOOLS] = "Gain a copy of a card// anyone has in play.";
SpanishCardTexts[CardNames.BURIED_TREASURE] = "At the start of your next//turn, |+1 Buy| and |+|[3].//---//When you gain this, play it.";
SpanishCardTexts[CardNames.CREW] = "|+3 Cards|////At the start of your next//turn, put this onto your deck.";
SpanishCardTexts[CardNames.CUTTHROAT] = "Each other player discards//down to 3 cards in hand.//The next time anyone gains//a Treasure costing [5] or//more, gain a Loot.";
SpanishCardTexts[CardNames.ENLARGE] = "Now and at the start of your//next turn: Trash a card from//your hand, and gain one//costing up to [2] more.";
SpanishCardTexts[CardNames.FIGURINE] = "|+2 Cards|////You may discard an Action//card for |+1 Buy| and |+|[1].";
SpanishCardTexts[CardNames.FIRST_MATE] = "Play any number of Action//cards with the same name//from your hand, then draw//until you have 6 cards//in hand.";
SpanishCardTexts[CardNames.FRIGATE] = "|+[3]|////Until the start of your next//turn, each time another player//plays an Action card, they//discard down to 4 cards//in hand afterwards.";
SpanishCardTexts[CardNames.LONGSHIP] = "|+2 Actions|////At the start of your next//turn, |+2 Cards|.";
SpanishCardTexts[CardNames.MINING_ROAD] = "|+1 Action|//|+1 Buy|//|+[2]|////Once this turn, when you//gain a Treasure, you may//play it.";
SpanishCardTexts[CardNames.PENDANT] = "|+|[1] per differently//named Treasure you//have in play.";
SpanishCardTexts[CardNames.PICKAXE] = "[!1]////Trash a card from your hand.//If it costs [3] or more,//gain a Loot to your hand.";
SpanishCardTexts[CardNames.PILGRIM] = "|+4 Cards|////Put a card from your hand//onto your deck.";
SpanishCardTexts[CardNames.QUARTERMASTER] = "At the start of each of your//turns for the rest of the game,//choose one: Gain a card costing//up to [4], setting it aside on//this; or put a card from this//into your hand.";
SpanishCardTexts[CardNames.SILVER_MINE] = "Gain a Treasure costing less//than this to your hand.";
SpanishCardTexts[CardNames.TRICKSTER] = "Each other player gains a Curse.//Once this turn, when you discard//a Treasure from play, you may//set it aside. Put it in your//hand at end of turn.";
SpanishCardTexts[CardNames.WEALTHY_VILLAGE] = "|+1 Card|//|+2 Actions|//---//When you gain this, if you//have at least 3 differently//named Treasures in play,//gain a Loot.";
SpanishCardTexts[CardNames.SACK_OF_LOOT] = "[!1]//|+1 Buy|//Gain a Loot.";
SpanishCardTexts[CardNames.KINGS_CACHE] = "You may play a Treasure from//your hand 3 times.";
SpanishCardTexts[CardNames.BURY] = "|+1 Buy|////Put any card from your discard pile//on the bottom of your deck.";
SpanishCardTexts[CardNames.AVOID] = "|+1 Buy|////The next time you shuffle this turn, pick up to 3//cards to put into your discard pile.";
SpanishCardTexts[CardNames.DELIVER] = "|+1 Buy|////This turn, each time you gain a card, set it aside,//and put it into your hand at end of turn.";
SpanishCardTexts[CardNames.PERIL] = "You may trash an Action card from//your hand to gain a Loot.";
SpanishCardTexts[CardNames.RUSH] = "|+1 Buy|////The next time you gain an Action card this turn, play it.";
SpanishCardTexts[CardNames.FORAY] = "Discard 3 cards, revealing them. If they//have 3 different names, gain a Loot.";
SpanishCardTexts[CardNames.LAUNCH] = "Once per turn: Return to your Action phase.//|+1 Card|, |+1 Action|, and |+1 Buy|.";
SpanishCardTexts[CardNames.MIRROR] = "|+1 Buy|////The next time you gain an Action card//this turn, gain a copy of it.";
SpanishCardTexts[CardNames.PREPARE] = "Set aside your hand face up. At the start of//your next turn, play those Actions and Treasures//in any order, then discard the rest.";
SpanishCardTexts[CardNames.SCROUNGE] = "Choose one: Trash a card from your hand;//or gain an Estate from the trash, and if you did,//gain a card costing up to [5].";
SpanishCardTexts[CardNames.JOURNEY] = "Once per turn: If the previous turn wasn't yours, you don't//discard cards from play in Clean-up this turn, and take//an extra turn after this one.";
SpanishCardTexts[CardNames.MAELSTROM] = "Trash 3 cards from your hand. Each other player with 5//or more cards in hand trashes one of them.";
SpanishCardTexts[CardNames.LOOTING] = "Gain a Loot.";
SpanishCardTexts[CardNames.INVASION] = "You may play an Attack from your hand. Gain a Duchy.//Gain an Action onto your deck. Gain a Loot; play it.";
SpanishCardTexts[CardNames.PROSPER] = "Gain a Loot, plus any number of//differently named Treasures.";
SpanishCardTexts[CardNames.AMPHORA] = "Either now or at the//start of your next turn://|+1 Buy| and |+|[3].";
SpanishCardTexts[CardNames.DOUBLOONS] = "[!3]////---//When you gain this,//gain a Gold.";
SpanishCardTexts[CardNames.ENDLESS_CHALICE] = "Now and at the start of//each of your turns for//the rest of the game://[!1]//|+1 Buy|";
SpanishCardTexts[CardNames.FIGUREHEAD] = "[!3]////At the start of your//next turn, |+2 Cards|.";
SpanishCardTexts[CardNames.HAMMER] = "[!3]////Gain a card costing//up to [4].";
SpanishCardTexts[CardNames.INSIGNIA] = "[!3]////This turn, when you//gain a card, you may//put it onto your deck.";
SpanishCardTexts[CardNames.JEWELS] = "[!3]//|+1 Buy|////At the start of your//next turn, put this//on the bottom of your deck.";
SpanishCardTexts[CardNames.ORB] = "Look through your discard//pile. Choose one: Play an//Action or Treasure from//it; or |+1 Buy| and |+|[3].";
SpanishCardTexts[CardNames.PRIZE_GOAT] = "[!3]//|+1 Buy|////You may trash a card//from your hand.";
SpanishCardTexts[CardNames.PUZZLE_BOX] = "[!3]//|+1 Buy|////You may set aside a card//from your hand face down.//Put it into your hand at//end of turn.";
SpanishCardTexts[CardNames.SEXTANT] = "[!3]//|+1 Buy|////Look at the top 5 cards of//your deck. Discard any//number. Put the rest//back in any order.";
SpanishCardTexts[CardNames.SHIELD] = "[!3]//|+1 Buy|//---//When another player plays//an Attack, you may first//reveal this from your hand//to be unaffected.";
SpanishCardTexts[CardNames.SPELL_SCROLL] = "Trash this to gain a//cheaper card. If it's//an Action or Treasure,//you may play it.";
SpanishCardTexts[CardNames.STAFF] = "[!3]//|+1 Buy|////You may play an Action//from your hand.";
SpanishCardTexts[CardNames.SWORD] = "[!3]//|+1 Buy|////Each other player discards//down to 4 cards in hand.";
SpanishCardTexts[CardNames.CHEAP] = "Cheap cards cost [1] less.";
SpanishCardTexts[CardNames.CURSED] = "When you gain a Cursed card, gain//a Loot and a Curse.";
SpanishCardTexts[CardNames.FAWNING] = "When you gain a Province,//gain a Fawning card.";
SpanishCardTexts[CardNames.FATED] = "When shuffling, you may look through the cards and reveal//Fated cards to put them on the top or bottom.";
SpanishCardTexts[CardNames.FRIENDLY] = "At the start of your Clean-up//phase, you may discard a Friendly//card to gain a Friendly card.";
SpanishCardTexts[CardNames.HASTY] = "When you gain a Hasty card, set//it aside, and play it at the start//of your next turn.";
SpanishCardTexts[CardNames.INHERITED] = "Setup: You start the game with an Inherited//card in place of a starting card you choose.";
SpanishCardTexts[CardNames.INSPIRING] = "After playing an Inspiring card on your turn,//you may play an Action from your hand that you//don't have a copy of in play.";
SpanishCardTexts[CardNames.NEARBY] = "When you gain a Nearby card, |+1 Buy|.";
SpanishCardTexts[CardNames.PATIENT] = "At the start of your Clean-up phase, you may//set aside Patient cards from your hand, to play them//at the start of your next turn.";
SpanishCardTexts[CardNames.PIOUS] = "When you gain a Pious card, you may//trash a card from your hand.";
SpanishCardTexts[CardNames.RECKLESS] = "Follow the instructions of played Reckless cards twice.//When discarding one from play, return it//to its pile.";
SpanishCardTexts[CardNames.RICH] = "When you gain a Rich card, gain a Silver.";
SpanishCardTexts[CardNames.SHY] = "At the start of your turn, you may//discard one Shy card for |+2 Cards|.";
SpanishCardTexts[CardNames.TIRELESS] = "When you discard a Tireless card from//play, set it aside, and put it//onto your deck at end of turn.";
SpanishCardTexts[CardNames.MARCHLAND] = "Worth {1} per 3//Victory cards you have.//---//When you gain this, |+1 Buy|,//and discard any number of//cards for +[1] each.";

SpanishCardTexts[CardNames.SHOP] = "|+1 Card|//|+[1]|//You may play an Action card//from your hand that you don't//have a copy of in play.\n";
SpanishCardTexts[CardNames.CARNIVAL] = "Reveal the top 4 cards//of your deck. Put one//of each differently named//card into your hand//and discard the rest.";
SpanishCardTexts[CardNames.FERRYMAN] = "|+2 Cards|//|+1 Action|//Discard a card.//---//Setup: Choose an unused Kingdom//pile costing [3] or [4]. Gain//one when you gain a Ferryman.";
SpanishCardTexts[CardNames.FARMHANDS] = "|+1 Card|//|+2 Actions|//---//When you gain this, you may//set aside an Action or Treasure//from your hand, and play it at//the start of your next turn.";
SpanishCardTexts[CardNames.JOUST] = "|+1 Card|//|+1 Action|//|+[1]|//You may set aside a Province//from your hand to gain any//Reward to your hand. Discard//the Province in Clean-up.";
SpanishCardTexts[CardNames.REWARDS_PILE] = "--";
SpanishCardTexts[CardNames.COURSER] = "Choose two different options://|+2 Cards|; |+2 Actions|;//|+|[2]; gain 4 Silvers.////%(This is not in the Supply.)%";
SpanishCardTexts[CardNames.RENOWN] = "|+1 Buy|////This turn, cards cost [2] less.////%(This is not in the Supply.)%";
SpanishCardTexts[CardNames.CORONET] = "You may play a non-Reward//Action from your hand twice.////You may play a non-Reward//Treasure from your hand twice.////%(This is not in the Supply.)%";
SpanishCardTexts[CardNames.DEMESNE] = "|+2 Actions|//|+2 Buys|//Gain a Gold.//---//Worth {1} per Gold you have.////%(This is not in the Supply.)%";
SpanishCardTexts[CardNames.HOUSECARL] = "|+1 Card| per differently named//Action card you have in play.////%(This is not in the Supply.)%";
SpanishCardTexts[CardNames.HUGE_TURNIP] = "|+2 Coffers|//|+|[1] per Coffers you have.////%(This is not in the Supply.)%";
SpanishCardTexts[CardNames.FARRIER] = "|+1 Card|//|+1 Action|//|+1 Buy|//---//Overpay: |+1 Card| at the end of//this turn per [1] overpaid.";
SpanishCardTexts[CardNames.INFIRMARY] = "|+1 Card|//You may trash a card//from your hand.//---//Overpay: Play this once//per [1] overpaid.";
SpanishCardTexts[CardNames.FOOTPAD] = "|+2 Coffers|//Each other player discards//down to 3 cards in hand.//---//In games using this, when//you gain a card in an//Action phase, |+1 Card|.\n";

SpanishCardTexts[CardNames.FISHMONGER] = "|+1 Buy|//|+[1]|//---//You can play this from your//deck as if in your hand.";
SpanishCardTexts[CardNames.SNAKE_WITCH] = "|+1 Card|//|+1 Action|//If your hand has no duplicate//cards, you may reveal it and//return this to its pile, to have//each other player gain a Curse.";
SpanishCardTexts[CardNames.ARISTOCRAT] = "If the number of Aristocrats//you have in play is:////1 or 5: |+3 Actions|;//2 or 6: |+3 Cards|;//3 or 7: |+|[3];//4 or 8: |+3 Buys|.";
SpanishCardTexts[CardNames.CRAFTSMAN] = "|+[2D]|////Gain a card//costing up to [5].";
SpanishCardTexts[CardNames.RIVERBOAT] = "At the start of your next//turn, play the set aside//card, leaving it there.//---//Setup: Set aside an//unused non-Duration//Action card costing [5].";
SpanishCardTexts[CardNames.ROOT_CELLAR] = "|+3 Cards|//|+1 Action|//|+[3D]|";
SpanishCardTexts[CardNames.ALLEY] = "|+1 Card|//|+1 Action|//Discard a card.//---//You can play this from your//deck as if in your hand.";
SpanishCardTexts[CardNames.CHANGE] = "If you have any [D], |+|[3].////Otherwise, trash a card from//your hand, and gain a card//costing more [] than it. +[D]//equal to the difference in [].";
SpanishCardTexts[CardNames.NINJA] = "|+1 Card|//Each other player discards//down to 3 cards in hand.//---//You can play this from your//deck as if in your hand.";
SpanishCardTexts[CardNames.POET] = "|+1 <>|//|+1 Card|//|+1 Action|//Reveal the top card of your//deck. If it costs [3] or less,//put it into your hand.";
SpanishCardTexts[CardNames.RIVER_SHRINE] = "|+1 <>|////Trash up to 2 cards from your//hand. At the start of Clean-up,//if you didn't gain any cards in//your Buy phase this turn, gain//a card costing up to [4].";
SpanishCardTexts[CardNames.RUSTIC_VILLAGE] = "|+1 <>|//|+1 Card|//|+2 Actions|//You may discard//2 cards for |+1 Card|.";
SpanishCardTexts[CardNames.GOLD_MINE] = "|+1 Card|//|+1 Action|//|+1 Buy|//You may gain a//Gold and get +[4D].";
SpanishCardTexts[CardNames.IMPERIAL_ENVOY] = "|+5 Cards|//|+1 Buy|//|+[2D]|";
SpanishCardTexts[CardNames.KITSUNE] = "|+1 <>|//Choose two different options://|+|[2]; |+2 Actions|; each other//player gains a Curse; gain//a Silver.";
SpanishCardTexts[CardNames.LITTER] = "|+2 Cards|//|+2 Actions|//|+[1D]|";
SpanishCardTexts[CardNames.RICE_BROKER] = "|+1 Action|//Trash a card from your hand.//If it's a Treasure, |+2 Cards|.//If it's an Action, |+5 Cards|.";
SpanishCardTexts[CardNames.RONIN] = "Draw until you have//7 cards in hand.//---//You can play this from your//deck as if in your hand.";
SpanishCardTexts[CardNames.TANUKI] = "Trash a card from your//hand. Gain a card costing//up to [2] more than it.//---//You can play this from your//deck as if in your hand.";
SpanishCardTexts[CardNames.TEA_HOUSE] = "|+1 <>|//|+1 Card|//|+1 Action|//|+[2]|";
SpanishCardTexts[CardNames.SAMURAI] = "Each other player discards//down to 3 cards in hand (once).//At the start of each of//your turns this game, +[1].//%(This stays in play.)%";
SpanishCardTexts[CardNames.RICE] = "|+1 Buy|//|+|[1] per different type among//cards you have in play.";
SpanishCardTexts[CardNames.MOUNTAIN_SHRINE] = "|+1 <>|//+[2]//You may trash a card from//your hand. Then if there//are any Action cards//in the trash, |+2 Cards|";
SpanishCardTexts[CardNames.DAIMYO] = "|+1 Card|//|+1 Action|//The next time you//play a non-Command//Action card this turn,//replay it afterwards.";
SpanishCardTexts[CardNames.ARTIST] = "|+1 Action|////|+1 Card| per card you//have exactly one//copy of in play.";
SpanishCardTexts[CardNames.APPROACHING_ARMY] = "After you play an Attack card, +[1].//---//Setup: Add an Attack to the game.";
SpanishCardTexts[CardNames.BIDING_TIME] = "At the start of your Clean-up, set aside your hand face down.//At the start of your next turn, put those cards into your hand.";
SpanishCardTexts[CardNames.BUREAUCRACY] = "When you gain a card that doesn't//cost [0], gain a Copper.";
SpanishCardTexts[CardNames.DIVINE_WIND] = "When you remove the last| |<>, remove all//Kingdom cards from the Supply, and//set up 10 new random ones.";
SpanishCardTexts[CardNames.ENLIGHTENMENT] = "Treasures are also Actions. When you play a//Treasure in an Action phase, instead of following//its instructions, |+1 Card| and |+1 Action|.";
SpanishCardTexts[CardNames.FLOURISHING_TRADE] = "Cards cost [1] less.//You may use Action plays as Buys.";
SpanishCardTexts[CardNames.GOOD_HARVEST] = "The first time you play each differently//named Treasure each turn, first, |+1 Buy| and +[1].";
SpanishCardTexts[CardNames.GREAT_LEADER] = "After each Action card you play, |+1 Action|.";
SpanishCardTexts[CardNames.GROWTH] = "When you gain a Treasure,//gain a cheaper card.";
SpanishCardTexts[CardNames.HARSH_WINTER] = "When you gain a card on your turn,//if there's [D] on its pile, take it;//otherwise put [2D] on its pile.";
SpanishCardTexts[CardNames.KIND_EMPEROR] = "At the start of your turn, and when you//remove the last| |<>: Gain an Action to your hand.";
SpanishCardTexts[CardNames.PANIC] = "When you play a Treasure, |+2 Buys|, and when you//discard one from play, return it to its pile.";
SpanishCardTexts[CardNames.PROGRESS] = "When you gain a card, put it onto your deck.";
SpanishCardTexts[CardNames.RAPID_EXPANSION] = "When you gain an Action or Treasure, set it//aside, and play it at the start of your next turn.";
SpanishCardTexts[CardNames.SICKNESS] = "At the start of your turn, choose one://Gain a Curse onto your deck; or discard 3 cards.";
SpanishCardTexts[CardNames.AMASS] = "If you have no Action cards in play,//gain an Action card costing up to [5].";
SpanishCardTexts[CardNames.ASCETICISM] = "Pay any amount of [] to trash//that many cards from your hand.";
SpanishCardTexts[CardNames.CREDIT] = "Gain an Action or Treasure costing//up to [8]. +[D] equal to its cost.";
SpanishCardTexts[CardNames.FORESIGHT] = "Reveal cards from your deck until revealing an Action.//Set it aside and discard the rest.//Put it into your hand at end of turn.";
SpanishCardTexts[CardNames.KINTSUGI] = "Trash a card from your hand. If you've gained a Gold this game,//gain a card costing up to [2] more than the trashed card.";
SpanishCardTexts[CardNames.PRACTICE] = "You may play an Action card from your hand twice.";
SpanishCardTexts[CardNames.SEA_TRADE] = "|+1 Card| per Action card you have in play.//Trash up to that many cards from your hand.";
SpanishCardTexts[CardNames.RECEIVE_TRIBUTE] = "If you've gained at least 3 cards this turn,//gain up to 3 differently named Action cards//you don't have copies of in play.";
SpanishCardTexts[CardNames.GATHER] = "Gain a card costing exactly [3],//a card costing exactly [4],//and a card costing exactly [5].";
SpanishCardTexts[CardNames.CONTINUE] = "Once per turn: Gain a non-Attack Action card costing up to [4].//Return to your Action phase and play it. |+1 Action| and |+1 Buy|.";