"use strict";

webclient.component("cardCounter", {
    bindings: {
        count: '<'
    },
    controller: ["$scope", "$element", function ($scope, $element) {
        var self = this;

        var canvas = $element[0].getElementsByTagName("canvas")[0];
        var ctx = canvas.getContext('2d');
        var w = void 0,
            h = void 0,
            cornerRadius = void 0;

        var redrawAll = function redrawAll() {
            w = canvas.clientWidth * 2;
            h = w;
            canvas.width = w;
            canvas.height = h;

            var artURL = 'images/elements/card-counter.png';
            Preloader.load(artURL, function (source) {
                ctx.drawImage(source, 0, 0, w, h);
                ctx.fillStyle = "white";
                drawFittedText(ctx, self.count.toString(), new Rectangle(w * 0.1, h * 0.1, w * 0.8, h * 0.8), 1, "Montserrat");
            });
        };
        var cachedCount = -1;
        var update = function update() {
            var currentW = canvas.clientWidth;
            if (currentW !== w || self.count !== cachedCount) {
                cachedCount = self.count;
                redrawAll();
            }
            requestAnimationFrame(update);
        };
        update();
    }],
    template: "\n        <canvas ng-show=\"$ctrl.count > -1\">\n        </canvas>"
});