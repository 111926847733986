"use strict";

var TChineseTypes = {};

TChineseTypes[Types.ACTION] = "行動卡";
TChineseTypes[Types.TREASURE] = "錢幣卡";
TChineseTypes[Types.ATTACK] = "攻擊卡";
TChineseTypes[Types.CURSE] = "詛咒卡";
TChineseTypes[Types.DURATION] = "持續卡";
TChineseTypes[Types.EVENT] = "事件";
TChineseTypes[Types.GATHERING] = "蒐集";
TChineseTypes[Types.KNIGHT] = "騎士";
TChineseTypes[Types.LANDMARK] = "地標";
TChineseTypes[Types.LOOTER] = "掠奪者";
TChineseTypes[Types.RUINS] = "廢墟";
TChineseTypes[Types.TRAVELLER] = "旅者";
TChineseTypes[Types.PRIZE] = "獎勵卡";
TChineseTypes[Types.RESERVE] = "保留卡";
TChineseTypes[Types.VICTORY] = "分數卡";
TChineseTypes[Types.REACTION] = "應對卡";
TChineseTypes[Types.SHELTER] = "避難所";
TChineseTypes[Types.CASTLE] = "城堡";
TChineseTypes[Types.FATE] = "命運";
TChineseTypes[Types.NIGHT] = "夜晚";
TChineseTypes[Types.DOOM] = "厄運";
TChineseTypes[Types.HEIRLOOM] = "傳家寶";
TChineseTypes[Types.SPIRIT] = "靈體";
TChineseTypes[Types.ZOMBIE] = "殭屍";
TChineseTypes[Types.BOON] = "祝福";
TChineseTypes[Types.HEX] = "咒術";
TChineseTypes[Types.STATE] = "狀態";
TChineseTypes[Types.PROJECT] = "計畫";
TChineseTypes[Types.COMMAND] = "命令";
TChineseTypes[Types.WAY] = "法則";
TChineseTypes[Types.LIAISON] = "聯絡人";
TChineseTypes[Types.ALLY] = "同盟";
TChineseTypes[Types.AUGUR] = "占兆官";
TChineseTypes[Types.CLASH] = "衝突";
TChineseTypes[Types.FORT] = "碉堡";
TChineseTypes[Types.ODYSSEY] = "奧德賽";
TChineseTypes[Types.TOWNSFOLK] = "市民";
TChineseTypes[Types.WIZARD] = "巫師";
TChineseTypes[Types.LOOT] = "贓物";
TChineseTypes[Types.TRAIT] = "特性";
TChineseTypes[Types.REWARD] = "獎賞";
TChineseTypes[Types.SHADOW] = "陰影";
TChineseTypes[Types.OMEN] = "預兆";
TChineseTypes[Types.PROPHECY] = "預言";

var TChineseZoneNames = {};

TChineseZoneNames["HandZone"] = "手上";
TChineseZoneNames["DrawZone"] = "抽牌堆";
TChineseZoneNames["DiscardZone"] = "棄卡堆";
TChineseZoneNames["TrashZone"] = "移除";
TChineseZoneNames["SupplyZone"] = "供應區";
TChineseZoneNames["InPlayZone"] = "出牌區";
TChineseZoneNames["TavernZone"] = "旅行板塊";
TChineseZoneNames["NVZone"] = "原住民板塊";
TChineseZoneNames["IslandZone"] = "小島板塊";
TChineseZoneNames["ExileZone"] = "被驅逐的卡";
TChineseZoneNames["SetAsideZone"] = "放到一邊";
TChineseZoneNames["SkippableRevealZone"] = "展示";

var TChineseMovementTypes = {};

TChineseMovementTypes[MovementTypes.PLAY] = new TranslatedMovementType("打出", "打出");
TChineseMovementTypes[MovementTypes.DRAW] = new TranslatedMovementType("抽卡", "抽卡");
TChineseMovementTypes[MovementTypes.DISCARD] = new TranslatedMovementType("棄卡", "棄卡");
TChineseMovementTypes[MovementTypes.GAIN] = new TranslatedMovementType("獲得", "獲得");
TChineseMovementTypes[MovementTypes.TRASH] = new TranslatedMovementType("移除", "移除");
TChineseMovementTypes[MovementTypes.TOPDECK] = new TranslatedMovementType("放牌庫頂", "放牌庫頂");
TChineseMovementTypes[MovementTypes.REVEAL] = new TranslatedMovementType("展示", "展示");
TChineseMovementTypes[MovementTypes.REVEAL_SEARCHING] = new TranslatedMovementType("展示", "展示");
TChineseMovementTypes[MovementTypes.LOOK_AT] = new TranslatedMovementType("觀看", "觀看");
TChineseMovementTypes[MovementTypes.PUT_IN_HAND] = new TranslatedMovementType("放到手中", "放到手中");
TChineseMovementTypes[MovementTypes.SET_ASIDE] = new TranslatedMovementType("放置一邊", "放置一邊");
TChineseMovementTypes[MovementTypes.PUT_ON_MAT] = new TranslatedMovementType("放到板塊上", "放到板塊上");
TChineseMovementTypes[MovementTypes.DECK_TO_DISCARD] = new TranslatedMovementType("牌堆到棄牌堆", "牌堆到棄牌堆");
TChineseMovementTypes[MovementTypes.BACK_ON_DECK] = new TranslatedMovementType("回到牌堆", "回到牌堆");
TChineseMovementTypes[MovementTypes.SHUFFLE_INTO_DECK] = new TranslatedMovementType("洗入牌堆", "洗入牌堆");
TChineseMovementTypes[MovementTypes.INSERT_IN_DECK] = new TranslatedMovementType("插入牌庫裡", "插入牌庫裡");
TChineseMovementTypes[MovementTypes.EXCHANGE_RETURN] = new TranslatedMovementType("歸還", "歸還");
TChineseMovementTypes[MovementTypes.EXCHANGE_RECEIVE] = new TranslatedMovementType("收到", "收到");
TChineseMovementTypes[MovementTypes.PASS] = new TranslatedMovementType("跳過", "跳過");
TChineseMovementTypes[MovementTypes.RETURN_TO] = new TranslatedMovementType("歸還到", "歸還到");
TChineseMovementTypes[MovementTypes.PUT_ON_BOTTOM_OF] = new TranslatedMovementType("放到底部", "放到底部");
TChineseMovementTypes[MovementTypes.STARTS_WITH] = new TranslatedMovementType("起始卡片：", "起始卡片：");
TChineseMovementTypes[MovementTypes.TAKE] = new TranslatedMovementType("獲得", "獲得");
TChineseMovementTypes[MovementTypes.GET_STATE] = new TranslatedMovementType("獲得狀態", "獲得狀態");
TChineseMovementTypes[MovementTypes.RETURN_STATE] = new TranslatedMovementType("歸還狀態", "歸還狀態");
TChineseMovementTypes[MovementTypes.EXILE] = new TranslatedMovementType("驅逐", "驅逐");

var TChinesePileTokenTexts = {};

TChinesePileTokenTexts[TokenNames.PLUS_ACTION] = "+動";
TChinesePileTokenTexts[TokenNames.PLUS_BUY] = "+買";
TChinesePileTokenTexts[TokenNames.PLUS_CARD] = "+卡";
TChinesePileTokenTexts[TokenNames.PLUS_COIN] = "+1";
TChinesePileTokenTexts[TokenNames.MINUS_COST] = "-2";
TChinesePileTokenTexts[TokenNames.TRASH] = "X";

var TChinesePileMarkers = {};

TChinesePileMarkers[PileMarkerTypes.BANE] = "毒藥";
TChinesePileMarkers[PileMarkerTypes.OBELISK] = "方尖碑";
TChinesePileMarkers[PileMarkerTypes.SPLIT] = "各半";
TChinesePileMarkers[PileMarkerTypes.MIXED] = "混合";

var TChineseTokenNames = {};

TChineseTokenNames[TokenNames.PLUS_ACTION] = "+行動 指示物";
TChineseTokenNames[TokenNames.PLUS_BUY] = "+購買 指示物";
TChineseTokenNames[TokenNames.PLUS_COIN] = "+預算 指示物";
TChineseTokenNames[TokenNames.PLUS_CARD] = "+卡片 指示物";
TChineseTokenNames[TokenNames.MINUS_COST] = "-價值 指示物";
TChineseTokenNames[TokenNames.TRASH] = "移除指示物";
TChineseTokenNames[TokenNames.JOURNEY] = "旅行指示物";
TChineseTokenNames[TokenNames.MINUS_CARD] = "-卡片 指示物";
TChineseTokenNames[TokenNames.MINUS_COIN] = "-預算 指示物";

var TChineseKingdomViewerNames = {};

TChineseKingdomViewerNames[KingdomViewerGroups.SUPPLY_CARDS] = "供應區的卡片";
TChineseKingdomViewerNames[KingdomViewerGroups.KINGDOM_LANDSCAPES] = "事件、地標、計畫";
TChineseKingdomViewerNames[KingdomViewerGroups.NON_KINGDOM] = "其他卡片";
TChineseKingdomViewerNames[KingdomViewerGroups.RUINS] = "五種廢墟";
TChineseKingdomViewerNames[KingdomViewerGroups.DRUID_BOONS] = "德魯伊擁有的祝福";
TChineseKingdomViewerNames[KingdomViewerGroups.KNIGHTS] = "十種騎士";
TChineseKingdomViewerNames[KingdomViewerGroups.CASTLES] = "八種城堡";
TChineseKingdomViewerNames[KingdomViewerGroups.BOONS_HEXES] = "狀態、祝福、咒術";
TChineseKingdomViewerNames[KingdomViewerGroups.STATES_ARTIFACTS] = "狀態與工藝品";
TChineseKingdomViewerNames[KingdomViewerGroups.BLACK_MARKET] = "黑市的卡片";
TChineseKingdomViewerNames[KingdomViewerGroups.PRIZES] = "五種獎勵卡";
TChineseKingdomViewerNames[KingdomViewerGroups.REWARDS] = "Rewards";

// Missing lines as of 09.01.2023 20:41:42
TChineseZoneNames["RevealZone"] = "展示";
TChineseZoneNames["TheBox"] = "箱子";
TChineseKingdomViewerNames[KingdomViewerGroups.LOOT] = "掠奪品";