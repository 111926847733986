"use strict";

var EnglishErrors = {};

EnglishErrors[FailureReasons.WRONG_PASSWORD] = "Wrong password.";
EnglishErrors[FailureReasons.NOT_ENOUGH_PLAYERS] = "Not enough players.";
EnglishErrors[FailureReasons.TOO_MANY_PLAYERS] = "Too many players.";
EnglishErrors[FailureReasons.NEW_HOST_NOT_FOUND] = "Can't find the new host.";
EnglishErrors[FailureReasons.ALREADY_JOINED_A_TABLE] = "You've already joined a table.";
EnglishErrors[FailureReasons.NOT_ALLOWED_TO_JOIN] = "You are not allowed to join.";
EnglishErrors[FailureReasons.BAD_CLIENT_VERSION] = "Your client is too old; please ctrl/cmd-reload the page to get a new one.";
EnglishErrors[FailureReasons.UNKNOWN_RULE_INDEX] = "Unknown rule index.";
EnglishErrors[FailureReasons.RULE_NOT_REMOVABLE] = "This rule is not removable.";
EnglishErrors[FailureReasons.INVITEE_NOT_LOGGED_IN] = "Invitee is not logged in.";
EnglishErrors[FailureReasons.NOT_ALLOWED_TO_PLAY] = "You are not allowed to play.";
EnglishErrors[FailureReasons.TABLE_CHAT_REQUIRES_TABLE] = "Table chat requires a table.";
EnglishErrors[FailureReasons.SPECTATOR_CHAT_REQUIRES_TABLE] = "Spectator chat requires a table.";
EnglishErrors[FailureReasons.LOGIN_REQUIRED] = "You need to be logged in.";
EnglishErrors[FailureReasons.COMMAND_REQUIRES_TABLE] = "This command requires a table.";
EnglishErrors[FailureReasons.COMMAND_REQUIRES_HOST] = "This command requires host privileges.";
EnglishErrors[FailureReasons.COMMAND_REQUIRES_PLAYER] = "This command is only for players.";
EnglishErrors[FailureReasons.RULE_CONFLICTS] = "Conflicting rules.";
EnglishErrors[FailureReasons.NO_GAMESERVER_AVAILABLE] = "No gameserver available.";
EnglishErrors[FailureReasons.SELECTED_CARD_NOT_OWNED] = "You don't own the selected card.";
EnglishErrors[FailureReasons.SELECTED_CARD_NOT_KINGDOM_CARD] = "The selected card is not a kingdom card.";
EnglishErrors[FailureReasons.SELECTED_TOO_MANY_EVENTS_OR_LANDMARKS] = "Too many Events or Landmarks selected.";
EnglishErrors[FailureReasons.THIS_IS_NOT_YOUR_TABLE] = "This is not your table.";
EnglishErrors[FailureReasons.ALREADY_LOGGED_IN] = "Already logged in.";
EnglishErrors[FailureReasons.CANNOT_RECONNECT_WHEN_STILL_CONNECTED] = "Cannot reconnect when still connected.";
EnglishErrors[FailureReasons.KICK_FAILED] = "Kick failed.";
EnglishErrors[FailureReasons.ALREADY_STARTING] = "Already starting.";
EnglishErrors[FailureReasons.ALREADY_STARTED] = "Already started.";
EnglishErrors[FailureReasons.NOTHING_TO_KICK] = "Nothing to kick.";
EnglishErrors[FailureReasons.INTERNAL_ERROR] = "Internal error.";
EnglishErrors[FailureReasons.ILLEGAL_PROPERTY_CHANGE] = "Illegal property change";
EnglishErrors[FailureReasons.CODE_ALREADY_USED] = "Code already used";
EnglishErrors[FailureReasons.FAILED_CONFIRMING_EMAIL] = "Failed to confirm email";
EnglishErrors[FailureReasons.FAILED_RETRIEVING_USER] = "Failed to retrieve user";
EnglishErrors[FailureReasons.EMAIL_NOT_CONFIRMED_YET] = "You can't host a game before confirming your email.";
EnglishErrors[FailureReasons.FAILURE_SENDING_EMAIL] = "Confirmation email could not be sent";
EnglishErrors[FailureReasons.CONFIRM_EMAIL_FIRST] = "Sorry, we can't send you a password without a confirmed email address.";
EnglishErrors[FailureReasons.OLD_PASSWORD_INCORRECT] = "Old password incorrect";
EnglishErrors[FailureReasons.INCORRECT_PRICE] = "Price incorrect";
EnglishErrors[FailureReasons.PURCHASE_IN_FUTURE] = "Purchase seems to come from the future";
EnglishErrors[FailureReasons.PURCHASE_TOO_OLD] = "Purchase too old; please try again";
EnglishErrors[FailureReasons.FAILED_SETTING_UP_PURCHASE] = "Failed setting up purchase";
EnglishErrors[FailureReasons.FAILED_STORING_PURCHASE] = "Failed storing purchase";
EnglishErrors[FailureReasons.DUPLICATE_USERNAME] = "This username already exists";
EnglishErrors[FailureReasons.INVALID_USERNAME] = "Invalid username";
EnglishErrors[FailureReasons.INVALID_PASSWORD] = "Invalid password";
EnglishErrors[FailureReasons.INVALID_GENDER] = "Invalid gender";
EnglishErrors[FailureReasons.PURCHASE_TOO_SHORT] = "Minimum subscription duration is 1 month";
EnglishErrors[FailureReasons.PURCHASE_TOO_LONG] = "Currently we don't sell subscriptions beyond 2024";
EnglishErrors[FailureReasons.METASERVER_INACTIVE] = "A new server version has been released; please ctrl/cmd-reload the page to connect to it.";
EnglishErrors[FailureReasons.NO_SUCH_TABLE] = "No such table.";
EnglishErrors[FailureReasons.NO_SUCH_USER] = "No such user.";
EnglishErrors[FailureReasons.NO_SUCH_CODE] = "No such code.";
EnglishErrors[FailureReasons.RESERVED_USERNAME] = "This username is reserved.";
EnglishErrors[FailureReasons.PRICE_IS_ZERO] = "You can't make a purchase with zero cost.";
EnglishErrors[FailureReasons.NO_SUCH_USERNAME] = "Username does not exist.";
EnglishErrors[FailureReasons.NO_SUCH_GAMELOG] = "The requested game cannot be found.";
EnglishErrors[FailureReasons.ILLEGAL_PLAYER_ORDER] = "Illegal player order.";
EnglishErrors[FailureReasons.INVALID_SESSION_ID] = "Invalid Session ID.";
EnglishErrors[FailureReasons.ACTION_CODE_EXPIRED] = "Action code expired.";
EnglishErrors[FailureReasons.BOT_GAMES_CANT_BE_RATED] = "Games with bots can't be rated.";
EnglishErrors[FailureReasons.TOO_MANY_PLAYERS_FOR_RATED_GAME] = "Games with >4 players can't be rated.";
EnglishErrors[FailureReasons.ALREADY_ON_BLACKLIST] = "Already on blacklist.";
EnglishErrors[FailureReasons.NOT_ON_BLACKLIST] = "Not on blacklist.";
EnglishErrors[FailureReasons.ALREADY_ON_FRIENDLIST] = "Already on friendlist.";
EnglishErrors[FailureReasons.NOT_ON_FRIENDLIST] = "Not on friendlist.";
EnglishErrors[FailureReasons.SEARCHING_FOR_MYSELF] = "Can't add yourself.";
EnglishErrors[FailureReasons.SEARCHED_FOR_BOT] = "Can't add a bot.";
EnglishErrors[FailureReasons.USER_IS_BANNED] = "You have been banned from this site.";
EnglishErrors[FailureReasons.WRONG_BONUS_CODE_TYPE] = "WRONG_BONUS_CODE_TYPE.";
EnglishErrors[FailureReasons.ILLEGAL_BONUS_CODE] = "ILLEGAL_BONUS_CODE.";
EnglishErrors[FailureReasons.GAME_ID_SHOULD_BE_NUMBER] = "Game Id should be a number.";
EnglishErrors[FailureReasons.NOT_SUBSCRIBED_TO] = "Not subscribed to";
EnglishErrors[FailureReasons.KINGDOM_HAS_TOO_MANY_CARDS] = "Kingdom has too many cards.";
EnglishErrors[FailureReasons.NO_CARDS_AVAILABLE_TO_FILL_KINGDOM] = "No cards available to fill kingdom.";
EnglishErrors[FailureReasons.FAILED_TO_SELECT_BANE_CARD] = "Failed to select bane card.";
EnglishErrors[FailureReasons.FAILED_TO_SELECT_MOUSE_CARD] = "Failed to select mouse card.";
EnglishErrors[FailureReasons.FAILED_TO_SELECT_FERRYMAN_CARD] = "Failed to select Ferryman card.";
EnglishErrors[FailureReasons.FAILED_TO_SELECT_PREVIEW_CARDS] = "Failed to select preview card.";
EnglishErrors[FailureReasons.FAILED_TO_SELECT_ALLY] = "Failed to select Ally.";
EnglishErrors[FailureReasons.FAILED_TO_SELECT_LIASON] = "Failed to select Liason.";
EnglishErrors[FailureReasons.REQUIRING_RANDOM_CARD_FROM_DISABLED_EXPANSION] = "Requiring a random card from a disabled expansion.";
EnglishErrors[FailureReasons.NO_RECONNECT_AVAILABLE] = "Requested reconnect but no reconnect is available.";
EnglishErrors[FailureReasons.COMMAND_REQUIRES_GAME] = "This command requires a game.";
EnglishErrors[FailureReasons.ILLEGAL_ANSWER] = "Provided an illegal answer in this game.";
EnglishErrors[FailureReasons.ILLEGAL_REQUEST] = "Illegal request in this game.";
EnglishErrors[FailureReasons.NO_SUCH_UNDO] = "No such undo.";
EnglishErrors[FailureReasons.SERVER_DOESNT_ALLOW_NEW_GAMES] = "Server doesn't allow new games.";
EnglishErrors[FailureReasons.SERVER_IS_DOWN] = "Server is down. Please reload the page.";
EnglishErrors[FailureReasons.OTHER_SERVER_IS_UNAVAILABLE] = "Other server is unavailable.";
EnglishErrors[FailureReasons.ALREADY_IN_GAME] = "Already in a game.";

var EnglishSuccessDescriptions = {};

EnglishSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_FORGOT_PASSWORD] = "We've sent you an email to reset your password.";
EnglishSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_CONFIRM_EMAIL] = "We've sent you an email to confirm your address.";
EnglishSuccessDescriptions[SuccessDescriptions.PASSWORD_CHANGED] = "Password changed";
EnglishSuccessDescriptions[SuccessDescriptions.KICK_SUCCESS] = "Kick successful";
EnglishSuccessDescriptions[SuccessDescriptions.PURCHASE_SUCCESS] = "Purchase successful";
EnglishSuccessDescriptions[SuccessDescriptions.EMAIL_CONFIRMED] = "Email confirmed";
EnglishSuccessDescriptions[SuccessDescriptions.REFUND_SUCCESS] = "Purchase refunded";
EnglishSuccessDescriptions[SuccessDescriptions.ACTION_CODE_USED] = "Successfully applied action code.";
EnglishSuccessDescriptions[SuccessDescriptions.SUBSCRIPTION_UPDATED] = "Your subscription has been updated.";
EnglishSuccessDescriptions[SuccessDescriptions.SUCCESSFULLY_CONNECTED_NEW_SERVER] = "Successfully connected to the new server.";
EnglishSuccessDescriptions[SuccessDescriptions.SUCCESSFULLY_CONNECTED_OLD_SERVER] = "Successfully connected to the old server.";

var EnglishTableRules = {};

EnglishTableRules[TableRuleIds.PLAYERS_CAN_SEE_SPECTATOR_CHAT] = "Players can see spectator chat";
EnglishTableRules[TableRuleIds.BANNED_CARDS] = "Banned cards";
EnglishTableRules[TableRuleIds.REQUIRED_CARDS] = "Required cards";
EnglishTableRules[TableRuleIds.USED_EXPANSIONS] = "Used expansions";
EnglishTableRules[TableRuleIds.RESTRICTIVE_PLAYER_RULES] = "Only specified users can play";
EnglishTableRules[TableRuleIds.SPECTATE_RULES] = "Allow spectators";
EnglishTableRules[TableRuleIds.MIN_RATING] = "Min relative rating";
EnglishTableRules[TableRuleIds.MAX_RATING] = "Max relative rating";
EnglishTableRules[TableRuleIds.RANDOM_SEED] = "Specify random seed";
EnglishTableRules[TableRuleIds.MIN_PLAYERS] = "Min players";
EnglishTableRules[TableRuleIds.MAX_PLAYERS] = "Max players";
EnglishTableRules[TableRuleIds.SPECIAL_KINGDOM_RULES] = "Special kingdom rules";
EnglishTableRules[TableRuleIds.RESPECTED_CARD_LISTS] = "Respect liked/disliked/banned cards";
EnglishTableRules[TableRuleIds.SHOW_VP_COUNTER] = "Show VP counter";
EnglishTableRules[TableRuleIds.UNDO_SETTINGS] = "Undo settings";
EnglishTableRules[TableRuleIds.ALWAYS_ALLOWED_PLAYER_RULES] = "ALWAYS_ALLOWED_PLAYER_RULES";
EnglishTableRules[TableRuleIds.PLAYER_ORDER] = "Player order";
EnglishTableRules[TableRuleIds.REPLAY_INSTRUCTIONS] = "Replay instructions";
EnglishTableRules[TableRuleIds.RATED_GAME] = "Rated game";
EnglishTableRules[TableRuleIds.PREVIEW_GAME] = "Menagerie Preview";
EnglishTableRules[TableRuleIds.SCRIPTED_RULES] = "Special Kingdom Rules";
EnglishTableRules[TableRuleIds.CARD_POOL_LEVEL] = "Card Pool Level";

var EnglishActivities = {};

EnglishActivities[ActivityStati.LOGGED_IN] = "Logged in";
EnglishActivities[ActivityStati.PLAYING] = "Playing";
EnglishActivities[ActivityStati.SPECTATING] = "Spectating";
EnglishActivities[ActivityStati.AT_TABLE_PLAYER] = "At table (player)";
EnglishActivities[ActivityStati.AT_TABLE_SPECTATOR] = "At table (spec)";
EnglishActivities[ActivityStati.DISCONNECTED] = "Disconnected";

var EnglishTableStati = {};

EnglishTableStati[TableStati.NEW] = "New";
EnglishTableStati[TableStati.POST_GAME] = "Post-game";
EnglishTableStati[TableStati.RUNNING] = "Running";
EnglishTableStati[TableStati.ABANDONED] = "Abandoned";
EnglishTableStati[TableStati.TRANSFERRED] = "Transferred";

var EnglishLobbyButtons = {
    DONE: "Done",
    RESET: "Reset",
    CLEAR_SELECTION: "Clear Selection",
    SHOW_TEXT_KINGDOM: "Show Text Kingdom",
    NEW_TABLE: "New Table",
    START_GAME: "Start Game",
    LEAVE_TABLE: "Leave Table",
    SELECT_KINGDOM: "Select Kingdom Cards",
    LIST_BANNED: "Banned",
    LIST_DISLIKED: "Disliked",
    LIST_LIKED: "Liked",
    LIST_FAMILIAR: "Familiar",
    LOAD_FROM_END: "Load from End",
    SELECT_COLONIES: "Colonies: ",
    SELECT_SHELTERS: "Shelters: "
};

var EnglishLobbyTexts = {
    AUTOCOMPLETE_FAILED: "No cards matching \"CARDS\" were found.",
    ADD_CARDS_AUTOCOMPLETE: "Enter cards to include in your kingdom",
    BAN_CARDS_AUTOCOMPLETE: "Enter cards to ban from your kingdom",
    KINGDOM_OPTIONS: "Kingdom Options",
    GAME_OPTIONS: "Game Options",
    RESPECTED_CARDS: "Respected Cards",
    RELATIVE_LEVEL: "Relative Level",
    LEVEL_INTERVAL: "${min} to ${max}",
    EMPTY_SLOT: "Empty Slot",
    REMOVE_SLOT: "Remove Slot",
    ADD_SLOT: "Add Slot",
    RANDOMIZE_PLAYER_ORDER: "Randomize Player Order",
    CHAT: "Chat",
    SELECT_CARDS: "Select Cards"
};

var EnglishTernaryFieldTexts = {};

EnglishTernaryFieldTexts[TernaryField.YES] = "Yes";
EnglishTernaryFieldTexts[TernaryField.NO] = "No";
EnglishTernaryFieldTexts[TernaryField.RANDOM] = "Random";

var EnglishTabs = {};

EnglishTabs[LobbyTabs.INBOX] = "Inbox";
EnglishTabs[LobbyTabs.MATCH] = "Automatch";
EnglishTabs[LobbyTabs.FRIEND_LIST] = "Friend List";
EnglishTabs[LobbyTabs.TABLES] = "Tables";
EnglishTabs[LobbyTabs.NEW_TABLE] = "New Table";
EnglishTabs[LobbyTabs.MY_TABLE] = "My Table";
EnglishTabs[LobbyTabs.ACCOUNT] = "Account";
EnglishTabs[LobbyTabs.STORE] = "Store";
EnglishTabs[LobbyTabs.OPTIONS] = "Options";
EnglishTabs[LobbyTabs.FAMILIAR_CARDS] = "Familiar Cards";
EnglishTabs[LobbyTabs.LEADERBOARD] = "Leaderboard";
EnglishTabs[LobbyTabs.CARDLISTS] = "Cardlists";

var EnglishMatchTypes = {};

EnglishMatchTypes[AutomatchTypes.QUICK_MATCH] = "Any Opponent";
EnglishMatchTypes[AutomatchTypes.GOOD_MATCH] = "Equal Opponent";
EnglishMatchTypes[AutomatchTypes.MATCH_WITH_EXPANSIONS] = "Match with Expansions";
EnglishMatchTypes[AutomatchTypes.MATCH_WITH_BOT] = "Computer Opponent";
EnglishMatchTypes[AutomatchTypes.MATCH_UNRATED] = "Human Opponent";

var EnglishUndoSettings = {};

EnglishUndoSettings[UndoSettings.NEVER] = "Never";
EnglishUndoSettings[UndoSettings.ASK] = "Ask";
EnglishUndoSettings[UndoSettings.ALWAYS] = "Always";

var EnglishGroupIds = {};

EnglishGroupIds[GroupIds.EVERYBODY] = "Everybody";
EnglishGroupIds[GroupIds.NOBODY] = "Nobody";
EnglishGroupIds[GroupIds.FRIENDS_OF] = "Friends of";
EnglishGroupIds[GroupIds.LIST_PLAYER_IDS] = "Selected players:";

var EnglishCountries = {};

EnglishCountries[Countries.NON_EU] = "Non-EU";
EnglishCountries[Countries.AT] = "Austria";
EnglishCountries[Countries.BE] = "Belgium";
EnglishCountries[Countries.BG] = "Bulgaria";
EnglishCountries[Countries.HR] = "Croatia";
EnglishCountries[Countries.CY] = "Cyprus";
EnglishCountries[Countries.CZ] = "Czech Republic";
EnglishCountries[Countries.DK] = "Denmark";
EnglishCountries[Countries.EE] = "Estonia";
EnglishCountries[Countries.FI] = "Finland";
EnglishCountries[Countries.FR] = "France";
EnglishCountries[Countries.DE] = "Germany";
EnglishCountries[Countries.GR] = "Greece";
EnglishCountries[Countries.HU] = "Hungary";
EnglishCountries[Countries.IE] = "Ireland";
EnglishCountries[Countries.IT] = "Italy";
EnglishCountries[Countries.LV] = "Latvia";
EnglishCountries[Countries.LT] = "Lithuania";
EnglishCountries[Countries.LU] = "Luxembourg";
EnglishCountries[Countries.MT] = "Malta";
EnglishCountries[Countries.NL] = "Netherlands";
EnglishCountries[Countries.PL] = "Poland";
EnglishCountries[Countries.PT] = "Portugal";
EnglishCountries[Countries.RO] = "Romania";
EnglishCountries[Countries.SK] = "Slovakia";
EnglishCountries[Countries.SI] = "Slovenia";
EnglishCountries[Countries.ES] = "Spain";
EnglishCountries[Countries.SE] = "Sweden";
EnglishCountries[Countries.GB] = "United Kingdom";

var EnglishAutomatchQuestions = {};
var EnglishAutomatchValues = {};

EnglishAutomatchQuestions[AUTOMATCH_QUESTION.PREVIEW_GAME] = "Previews";
EnglishAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME] = {};
EnglishAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.REQUIRE] = "Yes";
EnglishAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.EITHER] = "Yes or No";
EnglishAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.REJECT] = "No";

EnglishAutomatchQuestions[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS] = "Player count";
EnglishAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS] = {};
EnglishAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.REQUIRE] = "2P";
EnglishAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.EITHER] = "2P or 3P";
EnglishAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.REJECT] = "3P";

EnglishAutomatchQuestions[AUTOMATCH_QUESTION.RATED_GAME] = "Rated games";
EnglishAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME] = {};
EnglishAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.REQUIRE] = "Rated";
EnglishAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.EITHER] = "Rated or Unrated";
EnglishAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.REJECT] = "Unrated";

EnglishAutomatchQuestions[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME] = "Extra Rising Sun";
EnglishAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME] = {};
EnglishAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.REQUIRE] = "Yes";
EnglishAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.EITHER] = "Yes or No";
EnglishAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.REJECT] = "No";