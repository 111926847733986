"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var RenderableObject = function () {
    function RenderableObject() {
        _classCallCheck(this, RenderableObject);

        this.canvas = document.createElement("canvas");
        this.context = this.canvas.getContext("2d");
        this.rendered = false;
    }

    _createClass(RenderableObject, [{
        key: "unload",
        value: function unload() {
            if (this.rendered && this.canvas !== null) this.canvas.remove();
            this.canvas = null;
            this.context = null;
        }
    }, {
        key: "render",
        value: function render(node) {
            if (this.rendered) return;
            this.rendered = true;
            node.appendChild(this.canvas);
        }
    }]);

    return RenderableObject;
}();