"use strict";

var TChineseEnvironment = new function () {
    this.enter = function () {
        document.getElementsByTagName('body').item(0).appendChild(this.langcss);
    };
    this.leave = function () {
        document.getElementsByTagName('body').item(0).removeChild(this.langcss);
    };
    this.langcss = document.createElement('link');
    this.langcss.rel = 'stylesheet';
    this.langcss.type = 'text/css';
    this.langcss.href = "css/game/language/tchinese.css";

    this.titleFont = 'Microsoft JhengHei';
    this.bodyFont = 'DFKai';

    this.strlen = JapaneseEnvironment.strlen;

    this.getPileName = EnglishEnvironment.getPileName;
    this.pileNameSeparator = "-";

    this.phraseCollection_Or = function (phrases) {
        return EnglishEnvironment.phraseCollection(phrases, TChinesePhrases[Phrases.COMMA], TChinesePhrases[Phrases.OR]);
    };

    this.parseCardNames = function (cardFrequencies) {
        var size = cardFrequencies.length,
            isMixed = hasMixedVisibility(cardFrequencies.map(function (c) {
            return c.cardName;
        }));

        if (size === 0) return [new ClickableColoredText(getPhrase(Phrases.NOTHING))];

        var output = [];
        for (var i = 0; i < size; i++) {
            output.push(this.getCardFrequencyString(cardFrequencies[i], isMixed));
            if (i < size - 1 && size > 2) output.push(new ClickableColoredText("、"));
        }
        return output;
    };
    this.getCardFrequencyString = function (cardFrequency, isMixed) {
        var cardName = cardFrequency.cardName,
            translation = TCHINESE.getCardName[cardName],
            frequency = cardFrequency.frequency;
        if (frequency === 1) {
            var prefix = isMixed ? TCHINESE.getPhrase[Phrases.ANOTHER] : "";
            return colorTextByCardname(prefix + translation.singular, cardName);
        } else {
            var _prefix = isMixed ? TCHINESE.getPhrase[Phrases.OTHER] : "";
            return colorTextByCardname(_prefix + frequency + "張" + translation.singular, cardName);
        }
    };

    this.amountParser = function (amount) {
        var output = [];
        if (amount.coin > 0) {
            output.push(new ClickableColoredText(amount.coin + getPhrase(Phrases.COIN)));
        }
        if (amount.potion > 0) {
            var prefix = output.length > 0 ? "、" : "";
            output.push(new ClickableColoredText(prefix + amount.potion + getPhrase(Phrases.POTION)));
        }
        if (amount.debt > 0) {
            var _prefix2 = output.length > 0 ? "、" : "";
            output.push(new ClickableColoredText(_prefix2 + amount.debt + getPhrase(Phrases.DEBT)));
        }
        return output;
    };

    this.cardComparator = EnglishEnvironment.cardComparator;

    this.determineFontStyle = function (isLandscape, lines) {
        var estimateLineLength = function estimateLineLength(line) {
            return line.replace(/{!?\d+}/g, "x").replace(/\[!?\d+\]/g, "x").replace(/\|/g, "").length;
        };
        var maxLineLength = lines.map(estimateLineLength).reduce(function (a, b) {
            return a > b ? a : b;
        });
        var cutoffs = isLandscape ? [17, 25, 30, 35, 99] : [9, 11, 14, 18, 99];
        var styles = ["huge-font", "large-font", "medium-font", "small-font", "tiny-font"];
        var index = cutoffs.findIndex(function (e) {
            return maxLineLength < e;
        });
        if (index === -1 || lines.length >= 9) index = 4;
        return styles[index];
    };
}();