"use strict";

var VILLAGER_WIDTH = 130;
var VILLAGER_HEIGHT = 130;
var COFFER_WIDTH = 130;
var COFFER_HEIGHT = 130;
var FAVOR_WIDTH = 130;
var FAVOR_HEIGHT = 130;

webclient.component("villagerCounter", {
    bindings: {
        value: '<',
        scale: '<'
    },
    controller: ['answerExchange', function (answerExchange) {
        var self = this;
        self.getSizeClass = function () {
            return ["", "", "", "three-digits", "four-digits", "five-digits"][nDigits(self.value)];
        };
        self.click = function () {
            return answerExchange.useVillager();
        };
    }],
    template: "<div class=\"status-bar-container clickable-image\" ng-if=\"$ctrl.value > 0\" ng-click=\"$ctrl.click()\" ng-class=\"$ctrl.getSizeClass()\">\n                <img class=\"status-bar-image\" src=\"images/elements/villagers.png\">\n                <div class=\"status-bar-text white_shadow\">{{$ctrl.value}}</div>\n            </div>"
});

webclient.component("cofferCounter", {
    bindings: {
        value: '<',
        scale: '<'
    },
    controller: ['answerExchange', function (answerExchange) {
        var self = this;
        self.getSizeClass = function () {
            return ["", "", "", "three-digits", "four-digits", "five-digits"][nDigits(self.value)];
        };
        self.click = function () {
            return answerExchange.useCoffers();
        };
    }],
    template: "<div class=\"status-bar-container\" ng-if=\"$ctrl.value > 0\" ng-click=\"$ctrl.click()\" ng-class=\"$ctrl.getSizeClass()\">\n                <img class=\"status-bar-image\" src=\"images/elements/coffers.png\">\n                <div class=\"status-bar-text white black_shadow\">{{$ctrl.value}}</div>\n            </div>"
});

webclient.component("favorsCounter", {
    bindings: {
        value: '<',
        scale: '<'
    },
    controller: [function () {
        var self = this;
        self.getSizeClass = function () {
            return ["", "", "", "three-digits", "four-digits", "five-digits"][nDigits(self.value)];
        };
    }],
    template: "<div class=\"status-bar-container\" ng-if=\"$ctrl.value > 0\" ng-class=\"$ctrl.getSizeClass()\">\n                <img class=\"status-bar-image\" src=\"images/elements/favors.png\">\n                <div class=\"status-bar-text white black_shadow\">{{$ctrl.value}}</div>\n            </div>"
});

webclient.component("voyageCounter", {
    bindings: {
        value: '<',
        scale: '<'
    },
    controller: [function () {
        var self = this;
        self.getSizeClass = function () {
            return ["", "", "", "three-digits", "four-digits", "five-digits"][nDigits(self.value)];
        };
    }],
    template: "<div class=\"status-bar-container\" ng-if=\"$ctrl.value > 0\" ng-class=\"$ctrl.getSizeClass()\">\n                <img class=\"status-bar-image\" src=\"images/elements/voyage.png\">\n                <div class=\"status-bar-text white black_shadow\">{{$ctrl.value}}</div>\n            </div>"
});