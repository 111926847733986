"use strict";

webclient.component("tableStarter", {
    bindings: {
        min: '<',
        max: '<'
    },
    controller: ['serverMessenger', '$rootScope', function (serverMessenger, $rootScope) {
        var self = this;
        self.OTHER_SERVER_AVAILABLE = true;
        self.start = function () {
            var rules = [];
            rules.push(new TableRule(TableRuleIds.MIN_PLAYERS, 2));
            rules.push(new TableRule(TableRuleIds.MAX_PLAYERS, 2));
            rules.push(new TableRule(TableRuleIds.USED_EXPANSIONS, -1, ObjectValues(Expansions).filter(function (e) {
                return e !== Expansions.DEPRECATED;
            })));
            serverMessenger.newTableRequest(rules);
        };
        self.getCreateText = function () {
            return getPhrase(Phrases.CREATE_TABLE);
        };
        self.connect = function () {
            serverMessenger.requestConnectToOtherServer();
        };
        self.getConnectText = function () {
            return getPhrase(Phrases.OTHER_SERVER);
        };
    }],
    template: "\n            <div class=\"table-start\">\n                <button class=\"table-starter-button gold-button larger-font\"\n                        ng-click=\"$ctrl.start()\">{{$ctrl.getCreateText()}}</button>\n                <button ng-if=\"$ctrl.OTHER_SERVER_AVAILABLE\" class=\"table-starter-button gold-button larger-font\"\n                        ng-click=\"$ctrl.connect()\">{{$ctrl.getConnectText()}}</button>\n            </div>\n            "
});