"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TemplateLayer = function (_StackViewLayer) {
    _inherits(TemplateLayer, _StackViewLayer);

    function TemplateLayer(rootNode, cardStack) {
        _classCallCheck(this, TemplateLayer);

        var _this = _possibleConstructorReturn(this, (TemplateLayer.__proto__ || Object.getPrototypeOf(TemplateLayer)).call(this, rootNode, cardStack));

        _this.node.style.zIndex = 2;
        _this.node.classList.add("template-layer");
        _this.crossNode = document.createElement("div");
        return _this;
    }

    _createClass(TemplateLayer, [{
        key: "redraw",
        value: function redraw(w, h) {
            var card = this.cardStack.topCard;
            var cardName = card.cardName;
            var cardSize = this.cardStack.cardSize;
            var types = card.types;
            var templateURL = void 0;
            if (cardName === CardNames.BACK || this.cardStack.hasFilter("appears-anonymous")) {
                switch (cardSize) {
                    case CardSizes.FULL:
                        templateURL = getFullBackURL();
                        break;
                    default:
                        templateURL = getMiniBackURL();
                        break;
                }
            } else if (cardName.isBack()) {
                templateURL = getFullArtURL(card.image);
            } else {
                switch (cardSize) {
                    case CardSizes.FULL:
                        templateURL = getFullTemplateURL(types, cardName.isBaseCard(), cardName.hasHeirloom());
                        break;
                    case CardSizes.MINI:
                        templateURL = getMiniTemplateURL(types, cardName.isLandscape());
                        break;
                    case CardSizes.MICRO:
                        templateURL = getMicroTemplateURL(types);
                        break;
                }
            }
            var isInverted = this.cardStack.hasFilter("inverted-card");

            var margin = 0.02 * h;

            if (isInverted) {
                this.node.classList.add("inverted");
            } else {
                this.node.classList.remove("inverted");
            }

            this.node.style.left = margin + "px";
            this.node.style.top = margin + "px";
            this.node.style.width = w - 2 * margin + "px";
            this.node.style.height = h - 2 * margin + "px";
            this.node.style.backgroundImage = "url(" + templateURL + ")";
        }
    }]);

    return TemplateLayer;
}(StackViewLayer);