"use strict";

var RussianLogEntries = {};

RussianLogEntries[LogEntryNames.GAIN] = "Argument0 получает Argument1.";
RussianLogEntries[LogEntryNames.GAIN_WITH] = "Argument0 получает Argument1. (Argument2)";
RussianLogEntries[LogEntryNames.BUY] = "Argument0 покупает Argument1.";
RussianLogEntries[LogEntryNames.BUY_AND_GAIN] = "Argument0 покупает и получает Argument1.";
RussianLogEntries[LogEntryNames.TRASH] = "Argument0 выкидывает Argument1.";
RussianLogEntries[LogEntryNames.DISCARD] = "Argument0 сбрасывает Argument1.";
RussianLogEntries[LogEntryNames.DISCARD_WITH] = "Argument0 сбрасывает Argument1. (Argument2)";
RussianLogEntries[LogEntryNames.PLAY] = "Argument0 играет Argument1.";
RussianLogEntries[LogEntryNames.PLAY_AGAIN] = "Argument0 играет Argument1 ещё раз.";
RussianLogEntries[LogEntryNames.PLAY_THIRD] = "Argument0 играет Argument1 в третий раз.";
RussianLogEntries[LogEntryNames.COUNTER_ADD] = "COUNTER_ADD";
RussianLogEntries[LogEntryNames.COUNTER_RESET] = "COUNTER_RESET";
RussianLogEntries[LogEntryNames.TOPDECK] = "Argument0 кладёт Argument1 на верх колоды.";
RussianLogEntries[LogEntryNames.DRAW] = "Argument0 тянет Argument1.";
RussianLogEntries[LogEntryNames.DRAW_FROM] = "Argument0 тянет Argument1. (Argument2)";
RussianLogEntries[LogEntryNames.REVEAL] = "Argument0 раскрывает Argument1.";
RussianLogEntries[LogEntryNames.REVEAL_SEARCHING] = "Argument0 раскрывает Argument1.";
RussianLogEntries[LogEntryNames.REVEAL_SEARCHING_AND_FINDING] = "Argument0 раскрывает Argument1 и Argument2.";
RussianLogEntries[LogEntryNames.REVEAL_FINDING] = "Argument0 раскрывает Argument1.";
RussianLogEntries[LogEntryNames.REVEAL_HAND] = "Argument0 раскрывает свою руку: Argument1.";
RussianLogEntries[LogEntryNames.REVEAL_DISCARD] = "Argument0 раскрывает свой сброс: Argument1.";
RussianLogEntries[LogEntryNames.LOOK_AT] = "Argument0 смотрит на Argument1.";
RussianLogEntries[LogEntryNames.PUT_IN_HAND] = "Argument0 кладёт Argument1 в руку.";
RussianLogEntries[LogEntryNames.SET_ASIDE] = "Argument0 откладывает Argument1 в сторону.";
RussianLogEntries[LogEntryNames.PUT_ON_MAT] = "Argument0 кладёт Argument1 на его Argument2.";
RussianLogEntries[LogEntryNames.CALL] = "Argument0 вызывает Argument1.";
RussianLogEntries[LogEntryNames.DECK_TO_DISCARD] = "Argument0 кладёт свою колоды в сброс.";
RussianLogEntries[LogEntryNames.BACK_ON_DECK] = "Argument0 кладёт Argument1 обратно на верх колоды.";
RussianLogEntries[LogEntryNames.SHUFFLE_INTO_DECK] = "Argument0 замешивает Argument1 в свою колоду.";
RussianLogEntries[LogEntryNames.INSERT_IN_DECK] = "Argument0 вставляет Argument1 в свою колоду.";
RussianLogEntries[LogEntryNames.PASS] = "Argument0 передаёт Argument1 Argument2.";
RussianLogEntries[LogEntryNames.STARTS_WITH] = "Argument0 начинает с: Argument1.";
RussianLogEntries[LogEntryNames.REACTS_WITH] = "Argument0 реагирует Argument1.";
RussianLogEntries[LogEntryNames.SHUFFLES] = "Argument0 тасует колоду.";
RussianLogEntries[LogEntryNames.NEW_TURN] = "Argument0";
RussianLogEntries[LogEntryNames.WISH_CORRECT] = "Argument0 загадывает Argument1 и угадывает правильно.";
RussianLogEntries[LogEntryNames.WISH_WRONG] = "Argument0 загадывает Argument1, но раскрывает Argument2.";
RussianLogEntries[LogEntryNames.BLACK_MARKET_RESHUFFLE] = "Тасуется колода Чёрного Рынка.";
RussianLogEntries[LogEntryNames.RETURN_TO] = "Argument0 возвращает Argument1 Argument2.";

RussianLogEntries[LogEntryNames.PUT_ON_BOTTOM_OF] = "Argument0 кладёт Argument1 под низ Argument2.";
RussianLogEntries[LogEntryNames.LOSE_TRACK_MOVED] = "Argument0 не находит Argument1 (было перемещено).";
RussianLogEntries[LogEntryNames.LOSE_TRACK_HIDDEN] = "Argument0 не находит Argument1 (было накрыто).";
RussianLogEntries[LogEntryNames.LOSE_TRACK_RESHUFFLED] = "Argument0 не находит Argument1 (было замешано).";
RussianLogEntries[LogEntryNames.LOSE_TRACK_ANONYMOUS] = "Не найти Argument0.";

RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_BANDIT_CAMP] = "Argument0.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_TOWER] = "Argument0.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH_ABSENT] = "Нет разных карт Действия.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH] = "Argument0.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_WALL] = "Argument0.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_WOLF_DEN] = "ровно одна копия Argument0.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_PALACE] = "Argument0.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_ORCHARD] = "Argument0.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_OBELISK] = "Argument0 карт из стопки Argument1.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_MUSEUM] = "Argument0 разных карт.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_KINGS_CASTLE] = "Argument0 Замков.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_KEEP] = "Argument0.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_HUMBLE_CASTLE] = "Argument0 Замков.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_FOUNTAIN] = "Argument0.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_FEODUM] = "Argument0.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_GARDENS] = "Argument0.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_VINEYARD] = "Argument0 карт Действия.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_SILK_ROAD] = "Argument0 карт Победы.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_FAIRGROUNDS] = "Argument0 разных карт.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_DUKE] = "Argument0.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_DUH] = "";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_VP_TOKENS] = "Очки Победы.";

RussianLogEntries[LogEntryNames.PUT_IN_HAND_FROM] = "Argument0 кладёт Argument1 в руку (Argument2).";
RussianLogEntries[LogEntryNames.STARTS_TURN] = "Argument0 начинает свой ход.";

RussianLogEntries[LogEntryNames.TAKE_VP_FROM] = "Argument0 забирает Argument1 очков Победы с Argument2.";
RussianLogEntries[LogEntryNames.MOVE_VP_FROM_TO] = "Argument0 перемещает Argument1 очков Победы с Argument2 на Argument3.";
RussianLogEntries[LogEntryNames.OBELISK_CHOOSES_PILE] = "Obelisk выбирает Argument0.";
RussianLogEntries[LogEntryNames.PLAYER_MOVES_TOKEN_TO] = "Argument0 передвигает Argument1 на Argument2.";
RussianLogEntries[LogEntryNames.PLAYER_GETS_VP] = "Argument0 получает Argument1 очков Победы.";
RussianLogEntries[LogEntryNames.PLAYER_GETS_VP_FROM] = "Argument0 получает Argument1 очков Победы с Argument2.";
RussianLogEntries[LogEntryNames.ADDS_VP_TO] = "Argument0 добавляет Argument1 очков Победы к Argument2.";
RussianLogEntries[LogEntryNames.ALMS_FAIL] = "Argument0 покупает Милостыню, но у него в игре есть Argument1.";
RussianLogEntries[LogEntryNames.BORROW_FAIL] = "Argument0 покупает Займ, но у него уже был Argument1.";
RussianLogEntries[LogEntryNames.QUEST_FAIL_ATTACK] = "Argument0 не смог сбросить карту Атаки.";
RussianLogEntries[LogEntryNames.QUEST_FAIL_CURSES] = "Argument0 не смог сбросить Argument1.";
RussianLogEntries[LogEntryNames.QUEST_FAIL_DISCARD] = "Argument0 не смог сбросить 6 карт.";
RussianLogEntries[LogEntryNames.SAVE_FAIL] = "Argument0 не может отложить ничего в сторону.";
RussianLogEntries[LogEntryNames.SCOUTING_PARTY_FAIL] = "Argument0 не может ничего сбросить или положить на верх колоды.";
RussianLogEntries[LogEntryNames.BONFIRE_FAIL] = "Argument0 ничего не выкидывает.";
RussianLogEntries[LogEntryNames.MISSION_SUCCESS] = "У Argument0 будет дополнительный ход после текущего.";
RussianLogEntries[LogEntryNames.TRADE_FAIL] = "Argument0 ничего не выкидывает.";
RussianLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_DOWN] = "Argument0 переворачивает Argument1 лицом вниз.";
RussianLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_UP] = "Argument0 переворачивает Argument1 лицом вверх.";

RussianLogEntries[LogEntryNames.TRIUMPH_FAIL] = "Argument0 не может получить Argument1.";
RussianLogEntries[LogEntryNames.ADVANCE_FAIL] = "Argument0 не может получить Argument1.";
RussianLogEntries[LogEntryNames.TAX_ADD_DEBT] = "Argument0 добавляет Argument1 на Argument2.";
RussianLogEntries[LogEntryNames.TAX_TAKE_DEBT] = "Argument0 берёт Argument1 с Argument2.";
RussianLogEntries[LogEntryNames.RITUAL_FAIL] = "Argument0 не может получить Argument1.";
RussianLogEntries[LogEntryNames.WINDFALL_FAIL] = "Argument0 не пусты.";
RussianLogEntries[LogEntryNames.DOMINATE_FAIL] = "Argument0 не может получить Argument1.";

RussianLogEntries[LogEntryNames.PLUS_ACTION_FROM] = "Argument0 получает +1 Действие (от Argument1).";
RussianLogEntries[LogEntryNames.PLUS_BUY_FROM] = "Argument0 получает +1 Покупку (от Argument1).";
RussianLogEntries[LogEntryNames.DRAWS_FROM] = "Argument0 тянет Argument1 (от Argument2).";
RussianLogEntries[LogEntryNames.PLUS_COIN_FROM] = "Argument0 получает +1 Монету (от Argument1).";
RussianLogEntries[LogEntryNames.DRAWS_LESS_BECAUSE] = "Argument0 не тянет карту (из-за of Argument1).";

RussianLogEntries[LogEntryNames.WAITING_FOR] = "В ожидании Argument0.";
RussianLogEntries[LogEntryNames.EXCHANGE_RETURN] = "Argument0 вохвращает Argument1.";
RussianLogEntries[LogEntryNames.EXCHANGE_RECEIVE] = "Argument0 получает Argument1.";

RussianLogEntries[LogEntryNames.PRINCE_FAILS] = "";
RussianLogEntries[LogEntryNames.POSSESSION_TAKES_COIN_TOKENS] = "Argument0 забирает Золотые Жетоны.";
RussianLogEntries[LogEntryNames.POSSESSION_TAKES_DEBT_TOKENS] = "Argument0 забирает Долг.";
RussianLogEntries[LogEntryNames.POSSESION_TAKES_VP_TOKENS] = "Argument0 забирает очки Победы.";

RussianLogEntries[LogEntryNames.TAKES_DEBT] = "Argument0 берёт Argument1 долга.";
RussianLogEntries[LogEntryNames.REPAYS_DEBT] = "Argument0 платит Argument1 долга.";
RussianLogEntries[LogEntryNames.REPAYS_SOME_DEBT] = "Argument0 платит Argument1 долга (ещё Argument2 остаётся).";
RussianLogEntries[LogEntryNames.RETURNS_TOKEN] = "Argument0 возвращает Argument1, оставленный Argument2.";

RussianLogEntries[LogEntryNames.SET_ASIDE_WITH] = "Argument0 откладывает Argument1 в сторону с помощью Argument2.";
RussianLogEntries[LogEntryNames.BLOCKS_WITH] = "Argument0 блокирует с помощью Argument1.";
RussianLogEntries[LogEntryNames.NAMES] = "Argument0 называет Argument1.";
RussianLogEntries[LogEntryNames.GAIN_ON_DRAWPILE] = "Argument0 получает Argument1 на верх колоды.";
RussianLogEntries[LogEntryNames.GAIN_FROM_TRASH] = "Argument0 получает Argument1 со Свалки.";
RussianLogEntries[LogEntryNames.OBELISK_FAILS] = "Обелиск не смог выбрать стопку карт Действия.";
RussianLogEntries[LogEntryNames.INHERITS] = "Argument0 унаследовал Argument1.";
RussianLogEntries[LogEntryNames.GAME_META_INFO] = "Argument0";

RussianLogEntries[LogEntryNames.THE_SKYS_GIFT_FAIL] = "Argument0 нечего сбрасывать для принятия " + getColoredRussianCardName(CardNames.THE_SKYS_GIFT);
RussianLogEntries[LogEntryNames.RECEIVES] = "Argument0 получает Argument1.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_PASTURE] = "Argument0.";
RussianLogEntries[LogEntryNames.TAKE] = "Argument0 получает Argument1.";
RussianLogEntries[LogEntryNames.RETURN] = "Argument0 возвращает Argument1.";
RussianLogEntries[LogEntryNames.THE_SUNS_GIFT_FAIL] = getColoredRussianCardName(CardNames.THE_SUNS_GIFT) + " нечего сбрасывать.";
RussianLogEntries[LogEntryNames.DRUID_SELECTS_BOONS] = getColoredRussianCardName(CardNames.DRUID) + " откладывает Argument1.";
RussianLogEntries[LogEntryNames.DISCARD_RECEIVABLE] = "Argument0 сбрасывает Argument1.";
RussianLogEntries[LogEntryNames.PLAY_ENCHANTED] = "Argument0 зачарован Argument1";

RussianLogEntries[LogEntryNames.GAIN_ANOTHER_EXPERIMENT] = "Argument0 получает ещё один " + getColoredRussianCardName(CardNames.EXPERIMENT);
RussianLogEntries[LogEntryNames.COFFERS_FROM_PATRON] = "Argument0 получает Монетку от " + getColoredRussianCardName(CardNames.PATRON) + ".";
RussianLogEntries[LogEntryNames.GETS_COFFER] = "Argument0 получает +Argument1 Монетку.";
RussianLogEntries[LogEntryNames.GETS_COFFERS] = "Argument0 получает +Argument1 Монетки.";
RussianLogEntries[LogEntryNames.GETS_COFFER_FROM] = "Argument0 получает +Argument1 Монетку. (Argument2)";
RussianLogEntries[LogEntryNames.GETS_COFFERS_FROM] = "Argument0 получает +Argument1 Монетки. (Argument2)";
RussianLogEntries[LogEntryNames.USES_COFFER] = "Argument0 тратит Argument1 Монеток. (+$Argument1)";
RussianLogEntries[LogEntryNames.USES_COFFERS] = "Argument0 тратит Argument1 Монеток. (+$Argument1)";
RussianLogEntries[LogEntryNames.GETS_VILLAGER_FROM] = "Argument0 получает +Argument1 Хуторянина. (Argument2)";
RussianLogEntries[LogEntryNames.GETS_VILLAGERS_FROM] = "Argument0 получает +Argument1 Хуторян. (Argument2)";
RussianLogEntries[LogEntryNames.GETS_VILLAGER] = "Argument0 получает +Argument1 Хуторянина.";
RussianLogEntries[LogEntryNames.GETS_VILLAGERS] = "Argument0 получает +Argument1 Хуторян.";
RussianLogEntries[LogEntryNames.USES_VILLAGER] = "Argument0 тратит Argument1 Хуторянина.";
RussianLogEntries[LogEntryNames.USES_VILLAGERS] = "Argument0 тратит Argument1 Хуторян.";
RussianLogEntries[LogEntryNames.BEGINS_BUYPHASE] = "Argument0 начинает свою фазу Покупки.";
RussianLogEntries[LogEntryNames.ENDS_BUYPHASE] = "Argument0 заканчивает свою фазу Покупки.";
RussianLogEntries[LogEntryNames.GETS_ACTION] = "Argument0 получает +Argument1 Действие.";
RussianLogEntries[LogEntryNames.GETS_ACTIONS] = "Argument0 получает +Argument1 Действия.";
RussianLogEntries[LogEntryNames.GETS_ACTION_FROM] = "Argument0 получает +Argument1 Действие. (Argument2)";
RussianLogEntries[LogEntryNames.GETS_ACTIONS_FROM] = "Argument0 получает +Argument1 Действия. (Argument2)";
RussianLogEntries[LogEntryNames.GETS_BUY] = "Argument0 получает +Argument1 Покупку.";
RussianLogEntries[LogEntryNames.GETS_BUYS] = "Argument0 получает +Argument1 Покупки.";
RussianLogEntries[LogEntryNames.GETS_BUY_FROM] = "Argument0 получает +Argument1 Покупку. (Argument2)";
RussianLogEntries[LogEntryNames.GETS_BUYS_FROM] = "Argument0 получает +Argument1 Покупки. (Argument2)";
RussianLogEntries[LogEntryNames.GETS_COIN] = "Argument0 получает +Argument1 Монету.";
RussianLogEntries[LogEntryNames.GETS_COINS] = "Argument0 получает +Argument1 Монеты.";
RussianLogEntries[LogEntryNames.GETS_COIN_FROM] = "Argument0 получает +Argument1 Монету. (Argument2)";
RussianLogEntries[LogEntryNames.GETS_COINS_FROM] = "Argument0 получает +Argument1 Монеты. (Argument2)";
RussianLogEntries[LogEntryNames.LOSES_COIN] = "Argument0 теряет Argument1 Монету.";
RussianLogEntries[LogEntryNames.LOSES_COINS] = "Argument0 теряет Argument1 Монеты.";
RussianLogEntries[LogEntryNames.BOTTOMDECKS] = "Argument0 кладёт Argument1 на верх колоды.";
RussianLogEntries[LogEntryNames.TRASH_WITH] = "Argument0 выкидывает Argument1. (Argument2)";
RussianLogEntries[LogEntryNames.PRIEST_ACTIVATES] = "теперь каждый раз, когда вы выкидываете карту, +$Argument0.";
RussianLogEntries[LogEntryNames.PLAY_TREASURES_FOR] = RussianLogEntries[LogEntryNames.PLAY] + " (+$Argument2)";
RussianLogEntries[LogEntryNames.PLAY_TREASURES_AGAIN_FOR] = RussianLogEntries[LogEntryNames.PLAY_AGAIN] + " (+$Argument2)";
RussianLogEntries[LogEntryNames.RETURNS_MINUS_COIN_TOKEN] = RussianLogEntries[LogEntryNames.RETURNS_TOKEN] + " (-$1)";
RussianLogEntries[LogEntryNames.NOT_IN_PLAY_PREVENTED_PLAYING] = "Argument0 не был в игре, так что его невозможно сыграть.";
RussianLogEntries[LogEntryNames.PILLAGE_FAIL] = getColoredRussianCardName(CardNames.PILLAGE) + " не был выкинут, поэтому ничего не происходит.";
RussianLogEntries[LogEntryNames.DEATH_CART_FAIL] = getColoredRussianCardName(CardNames.DEATH_CART) + " не была выкинута, поэтому ничего не происходит.";
RussianLogEntries[LogEntryNames.TACTICIAN_FAIL] = getColoredRussianCardName(CardNames.TACTICIAN) + " не сбросил карт, поэтому ничего не происходит.";
RussianLogEntries[LogEntryNames.EMBARGO_FAIL] = getColoredRussianCardName(CardNames.EMBARGO) + " не было выкинуто, поэтому ничего не происходит.";
RussianLogEntries[LogEntryNames.EXILE] = "Argument0 изгоняет Argument1.";
RussianLogEntries[LogEntryNames.DISCARD_FROM_EXILE] = "Argument0 сбрасывает Argument1 из Изгнания.";
RussianLogEntries[LogEntryNames.DISCARD_REVEALED] = "Argument0 сбрасывает и раскрывает Argument1.";
RussianLogEntries[LogEntryNames.PLAY_WITH] = RussianLogEntries[LogEntryNames.PLAY] + " (Argument2)";
RussianLogEntries[LogEntryNames.PLAY_AGAIN_WITH] = RussianLogEntries[LogEntryNames.PLAY] + " (Argument2)";
RussianLogEntries[LogEntryNames.PLAY_THIRD_WITH] = RussianLogEntries[LogEntryNames.PLAY] + " (Argument2)";
RussianLogEntries[LogEntryNames.IS_INVESTED] = "Argument0 тянет Argument2 карт когда соперник получает или инвестирует в Argument1.";
RussianLogEntries[LogEntryNames.COMMERCE_FAIL] = "Argument0 не получает карт.";
RussianLogEntries[LogEntryNames.STAMPEDE_FAIL] = "Argument0 купил " + getColoredRussianCardName(CardNames.STAMPEDE) + " с Argument1 картами в игре.";
RussianLogEntries[LogEntryNames.PLAY_USING_WAY] = "Argument0 играет Argument1 используя Argument2.";
RussianLogEntries[LogEntryNames.WAY_OF_THE_HORSE_FAILS_NO_PILE] = "Нет стопки чтобы вернуть Argument0.";
RussianLogEntries[LogEntryNames.WAY_OF_THE_HORSE_FAILS_NOT_IN_PLAY] = getColoredRussianCardName(CardNames.WAY_OF_THE_HORSE) + " не может вернуть Argument0, потому что его нет в игре.";
RussianLogEntries[LogEntryNames.WAY_OF_THE_TURTLE_FAILS_TO_SET_ASIDE] = getColoredRussianCardName(CardNames.WAY_OF_THE_TURTLE) + " не может отложить Argument0.";
RussianLogEntries[LogEntryNames.USES_WAY] = "Argument0 использует Argument1.";
RussianLogEntries[LogEntryNames.WOULD_GET_COIN] = "Argument0 получает +$Argument1.";
RussianLogEntries[LogEntryNames.WOULD_DRAW] = "Argument0 тянет Argument1 карт.";
RussianLogEntries[LogEntryNames.TOPDECK_WITH] = "Argument0 кладёт Argument1 на верх колоды используя Argument2.";
RussianLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_NO_PILE] = "Нет стопки, чтобы вернуть Argument0.";
RussianLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_NOT_IN_PLAY] = getColoredRussianCardName(CardNames.WAY_OF_THE_BUTTERFLY) + " не может вернуть Argument0, так как Argument0 не в игре.";
RussianLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_INTENTIONAL] = "Argument0 решил не возвращать Argument1 в стопку.";
RussianLogEntries[LogEntryNames.PLAYER_BIDS] = "Argument0 ставит Argument1 долга";

//Seaside 2E

RussianLogEntries[LogEntryNames.GAIN_SETTING_ASIDE] = "Argument0 получает Argument1 и откладывает его в сторону.";

//Prosperity 2E

RussianLogEntries[LogEntryNames.INVESTMENT_FAIL] = getColoredRussianCardName(CardNames.INVESTMENT) + " не может быть выкинуто, так как оно не в игре.";

//new Promo


//Allies

RussianLogEntries[LogEntryNames.PLAY_NULLIFIED] = "Argument0 играет Argument1 (ануллировано " + getColoredRussianCardName(CardNames.HIGHWAYMAN) + ").";
RussianLogEntries[LogEntryNames.GETS_FAVOR] = "Argument0 получает +Argument1 Услуг.";
RussianLogEntries[LogEntryNames.GETS_FAVORS] = "Argument0 получет +Argument1 Услуг.";
RussianLogEntries[LogEntryNames.GETS_FAVOR_FROM] = "Argument0 получает +Argument1 Услуг. (Argument2)";
RussianLogEntries[LogEntryNames.GETS_FAVORS_FROM] = "Argument0 получает +Argument1 Услуг. (Argument2)";
RussianLogEntries[LogEntryNames.SPENDS_FAVOR] = "Argument0 тратит Argument1 услуг.";
RussianLogEntries[LogEntryNames.SPENDS_FAVORS] = "Argument0 тратит Argument1 Услуг.";
RussianLogEntries[LogEntryNames.ROTATES_EMPTY_PILE] = "Argument0 прокручивает пустую стопку";
RussianLogEntries[LogEntryNames.ROTATES_IDENTICAL_PILE] = "Argument0 прокручивает стопку с одинаковыми картами.";
RussianLogEntries[LogEntryNames.ROTATES] = "Argument0 прокручивает Argument1.";
RussianLogEntries[LogEntryNames.ISLAND_FOLK] = "Argument0 получает дополнительный ход после текущего.";
RussianLogEntries[LogEntryNames.ROYAL_GALLEY_FAIL] = getColoredRussianCardName(CardNames.ROYAL_GALLEY) + " не может отложить в сторону.";
RussianLogEntries[LogEntryNames.FAILS_TO_PLAY] = "Argument0 не может сыграть Argument1";
RussianLogEntries[LogEntryNames.FAILS_TO_PLAY_WRONG_LOCATION] = "Argument0 не может сыграть Argument1 так как это не Argument2";
RussianLogEntries[LogEntryNames.STRANDS_CARDS] = "Argument0 strands Argument1";
RussianLogEntries[LogEntryNames.VOYAGE_SUCCESS] = "Argument0 получает дополнительный ход после текущего (Путешествие).";
RussianLogEntries[LogEntryNames.GARRISON_ADDS_TOKEN] = "Argument0 добавляет жетон на " + getColoredRussianCardName(CardNames.GARRISON) + ", теперь их Argument1.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_PLATEAU_SHEPHERDS] = "Argument0 Карт, Argument1 Услуг.";
RussianLogEntries[LogEntryNames.PUT_IN_DISCARD] = "Argument0 кладёт Argument1 в сброс.";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_TERRITORY] = "Argument0 разных карт Победы.";
RussianLogEntries[LogEntryNames.SORCERESS_GUESS] = "Argument0 угадывает Argument1.";
RussianLogEntries[LogEntryNames.SORCERER_GUESS] = "Argument0 угадывает Argument1.";
RussianLogEntries[LogEntryNames.SKIPS_A_TURN_REMAINING] = "Argument0 пропускает ход (Argument1 осталось).";
RussianLogEntries[LogEntryNames.SKIPS_A_TURN] = "Argument0 пропускает ход.";
RussianLogEntries[LogEntryNames.CANCELS_A_TURN] = "Argument0 не может получить ход (от Argument1).";
RussianLogEntries[LogEntryNames.WILL_SKIP_A_TURN] = "Argument0 должен будет пропустить ход.";
RussianLogEntries[LogEntryNames.WILL_SKIP_A_TURN_COUNT] = "Argument0 должен будет пропустить ход (сейчас Argument1).";
RussianLogEntries[LogEntryNames.KEEPS_IN_HAND] = "Argument0 оставляет Argument1 в руке.";
RussianLogEntries[LogEntryNames.BEGINS_BUYPHASE] = "Argument0 начинает свою фазу Покупок.";
RussianLogEntries[LogEntryNames.PLAYER_ADDS_FAVOR_TOKEN_TO] = "Argument0 кладёт жетон Услуги на Argument1. ([Family of Inventors])";
RussianLogEntries[LogEntryNames.BEGINS_CLEANUP_PHASE] = "Argument0 начинает свою фазу Расчистки.";
RussianLogEntries[LogEntryNames.PREMOVES] = "Argument0 предваряет Argument1.";

//Plunder
RussianLogEntries[LogEntryNames.SPELL_SCROLL_FAILS] = "[Spell Scroll] ничего не выкинул, так что ничего не делает.";
RussianLogEntries[LogEntryNames.PERIL_FAIL] = "[Peril] ничего не выкинул, так что ничего не делает.";
RussianLogEntries[LogEntryNames.ACQUIRES_TRAIT] = "Argument1 Черта достаётся Argument0.";
RussianLogEntries[LogEntryNames.TRAIT_FAILS] = "У Argument0 не получается выбрать стопку Действий или Сокровищ.";
RussianLogEntries[LogEntryNames.SHUFFLES_CARDS] = "Argument0 тасует Argument1.";
RussianLogEntries[LogEntryNames.PLAY_TREASURES_THIRD_FOR] = RussianLogEntries[LogEntryNames.PLAY_THIRD] + " (+$Argument2)";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_MARCHLAND] = "Argument0 карт Победы.";

RussianLogEntries[LogEntryNames.FARMHANDS_FAIL] = "Батраки не смогли отложить карту в сторону";
RussianLogEntries[LogEntryNames.FARMHANDS_REFUSE] = "Батраки не откладывают карту в сторону";
RussianLogEntries[LogEntryNames.EXPLAIN_POINTS_DEMESNE] = "Argument0 Золота.";
RussianLogEntries[LogEntryNames.REACTS_PLAYING] = "Argument0 реагирует с Argument1, играя его.";

RussianLogEntries[LogEntryNames.PLAY_ENLIGHTENED] = "Argument0 играет Argument1 как +1 Карта +1 Действие";
RussianLogEntries[LogEntryNames.PANIC_FAILS] = "[Panic] не может вернуть Argument0";
RussianLogEntries[LogEntryNames.PANIC_FAILS_NO_PILE] = "[Panic] не может вернуть Argument0 (нет стопки).";
RussianLogEntries[LogEntryNames.AMASS_FAILS] = "Argument0 пкупает [Amass] но имеет Argument1 в игре.";