"use strict";

var EsperantoCardModes = {};

EsperantoCardModes[CardModes.PAWN_CARD_ACTION] = "Karto & Ago";
EsperantoCardModes[CardModes.PAWN_CARD] = "+1 Karto";
EsperantoCardModes[CardModes.PAWN_ACTION] = "+1 Ago";
EsperantoCardModes[CardModes.PAWN_BUY] = "+1 Aĉeto";
EsperantoCardModes[CardModes.PAWN_COIN] = "+1 Monero";
EsperantoCardModes[CardModes.STEWARD_CARDS] = "+2 Kartoj";
EsperantoCardModes[CardModes.STEWARD_COINS] = "+2 Moneroj";
EsperantoCardModes[CardModes.STEWARD_TRASH] = "Rubigu 2";
EsperantoCardModes[CardModes.MINION_COINS] = "+2 Moneroj";
EsperantoCardModes[CardModes.MINION_NEWHAND] = "Forĵetu";
EsperantoCardModes[CardModes.NOBLES_CARDS] = "+3 Kartoj";
EsperantoCardModes[CardModes.NOBLES_ACTIONS] = "+2 Agoj";
EsperantoCardModes[CardModes.COURTIER_ACTION] = "+1 Ago";
EsperantoCardModes[CardModes.COURTIER_BUY] = "+1 Aĉeto";
EsperantoCardModes[CardModes.COURTIER_COINS] = "+3 Moneroj";
EsperantoCardModes[CardModes.COURTIER_GOLD] = "Gajnu Oron";
EsperantoCardModes[CardModes.NATIVE_VILLAGE_SET_ASIDE] = "Flankenmetu";
EsperantoCardModes[CardModes.NATIVE_VILLAGE_RETURN] = "Kolektu";
EsperantoCardModes[CardModes.PIRATE_SHIP_RAID] = "Ataku";
EsperantoCardModes[CardModes.PIRATE_SHIP_PROFIT] = "Profitu";
EsperantoCardModes[CardModes.SCRYING_POOL_TOPDECK] = "Surmetu";
EsperantoCardModes[CardModes.SCRYING_POOL_DISCARD] = "Forĵetu";
EsperantoCardModes[CardModes.LOAN_DISCARD] = "Forĵetu";
EsperantoCardModes[CardModes.LOAN_TRASH] = "Rubigu";
EsperantoCardModes[CardModes.TRUSTY_STEED_CARDS_ACTIONS] = "Kartoj & Agoj";
EsperantoCardModes[CardModes.TRUSTY_STEED_CARDS] = "+2 Kartoj";
EsperantoCardModes[CardModes.TRUSTY_STEED_ACTIONS] = "+2 Agoj";
EsperantoCardModes[CardModes.TRUSTY_STEED_COINS] = "+2 Moneroj";
EsperantoCardModes[CardModes.TRUSTY_STEED_SILVERS] = "+4 Arĝentoj";
EsperantoCardModes[CardModes.JESTER_THEY_GAIN] = "Kontraŭulo Gajnas";
EsperantoCardModes[CardModes.JESTER_YOU_GAIN] = "Vi Gajnas";
EsperantoCardModes[CardModes.DUCHESS_KEEP] = "Gardu";
EsperantoCardModes[CardModes.DUCHESS_DISCARD] = "Forĵetu";
EsperantoCardModes[CardModes.ORACLE_TOPDECK] = "Surmetu";
EsperantoCardModes[CardModes.ORACLE_DISCARD] = "Forĵetu";
EsperantoCardModes[CardModes.JACK_KEEP] = "Gardu";
EsperantoCardModes[CardModes.JACK_DISCARD] = "Forĵetu";
EsperantoCardModes[CardModes.SPICE_MERCHANT_CARDS] = "Kartoj, Ago";
EsperantoCardModes[CardModes.SPICE_MERCHANT_COINS] = "Moneroj, Aĉeto";
EsperantoCardModes[CardModes.CATACOMBS_TAKE] = "Metu en Manon";
EsperantoCardModes[CardModes.CATACOMBS_DISCARD] = "Forĵetu";
EsperantoCardModes[CardModes.COUNT_DISCARD] = "Forĵetu";
EsperantoCardModes[CardModes.COUNT_TOPDECK] = "Surmetu";
EsperantoCardModes[CardModes.COUNT_GAIN_COPPER] = "Gajnu Kupron";
EsperantoCardModes[CardModes.COUNT_COINS] = "+3 Moneroj";
EsperantoCardModes[CardModes.COUNT_TRASH_HAND] = "Rubigu Manon";
EsperantoCardModes[CardModes.COUNT_GAIN_DUCHY] = "Gajnu Duklandon";
EsperantoCardModes[CardModes.HUNTING_GROUNDS_ESTATES] = "Gajnu 3 Bienojn";
EsperantoCardModes[CardModes.HUNTING_GROUNDS_DUCHY] = "Gajnu Duklandon";
EsperantoCardModes[CardModes.IRONMONGER_KEEP] = "Gardu";
EsperantoCardModes[CardModes.IRONMONGER_DISCARD] = "Forĵetu";
EsperantoCardModes[CardModes.SCAVENGER_DISCARD] = "Forĵetu";
EsperantoCardModes[CardModes.SCAVENGER_KEEP] = "Daŭru";
EsperantoCardModes[CardModes.SQUIRE_ACTIONS] = "+2 Agoj";
EsperantoCardModes[CardModes.SQUIRE_BUYS] = "+2 Aĉetoj";
EsperantoCardModes[CardModes.SQUIRE_SILVER] = "Gajnu Arĝenton";
EsperantoCardModes[CardModes.MERCENARY_DONT_TRASH] = "Ne Rubigu";
EsperantoCardModes[CardModes.DOCTOR_TRASH] = "Rubigu";
EsperantoCardModes[CardModes.DOCTOR_DISCARD] = "Forĵetu";
EsperantoCardModes[CardModes.DOCTOR_TOPDECK] = "Surmetu";
EsperantoCardModes[CardModes.AMULET_COIN] = "+1 Monero";
EsperantoCardModes[CardModes.AMULET_TRASH] = "Rubigu";
EsperantoCardModes[CardModes.AMULET_SILVER] = "Gajnu Arĝenton";
EsperantoCardModes[CardModes.MISER_COPPER_TO_MAT] = "Kupron al Mato";
EsperantoCardModes[CardModes.MISER_PROFIT] = "Profitu";
EsperantoCardModes[CardModes.MESSENGER_DISCARD] = "Forĵetu";
EsperantoCardModes[CardModes.MESSENGER_KEEP] = "Gardu";
EsperantoCardModes[CardModes.QUEST_ATTACK] = "Atako";
EsperantoCardModes[CardModes.QUEST_CURSES] = "2 Malbenoj";
EsperantoCardModes[CardModes.QUEST_CARDS] = "6 Kartoj";
EsperantoCardModes[CardModes.TEACHER_CARD] = "Karto";
EsperantoCardModes[CardModes.TEACHER_ACTION] = "Ago";
EsperantoCardModes[CardModes.TEACHER_BUY] = "Aĉeto";
EsperantoCardModes[CardModes.TEACHER_COIN] = "Monero";
EsperantoCardModes[CardModes.LIBRARY_DRAW_IT] = "Gardu";
EsperantoCardModes[CardModes.LIBRARY_SKIP_IT] = "Preterpasu";
EsperantoCardModes[CardModes.GLADIATOR_PRETEND] = "Ne Malkaŝu";
EsperantoCardModes[CardModes.SPRAWLING_CASTLE_ESTATES] = "Gajnu 3 Bienojn";
EsperantoCardModes[CardModes.SPRAWLING_CASTLE_DUCHY] = "Gajnu Duklandon";
EsperantoCardModes[CardModes.CHARM_BUY_MONEY] = "+Aĉeto, +2 Moneroj";
EsperantoCardModes[CardModes.CHARM_COPY] = "Gajnu Karton";
EsperantoCardModes[CardModes.WILD_HUNT_CARDS] = "Kartoj";
EsperantoCardModes[CardModes.WILD_HUNT_POINTS] = "Poentoj";
EsperantoCardModes[CardModes.PRINCE_NO] = "Daŭru";
EsperantoCardModes[CardModes.HAMLET_ACTION] = "Forĵetu por Ago";
EsperantoCardModes[CardModes.HAMLET_BUY] = "Forĵetu por Aĉeto";
EsperantoCardModes[CardModes.HAMLET_BOTH] = "Ambaŭ";
EsperantoCardModes[CardModes.GOVERNOR_CARDS] = "+3 Kartoj";
EsperantoCardModes[CardModes.GOVERNOR_TREASURE] = "Gajnu Oron";
EsperantoCardModes[CardModes.GOVERNOR_REMODEL] = "Rubigu & Gajnu";
EsperantoCardModes[CardModes.BLESSED_VILLAGE_NOW] = "Ricevu Nun";
EsperantoCardModes[CardModes.BLESSED_VILLAGE_LATER] = "Sekva Vico";
EsperantoCardModes[CardModes.PIXIE_TRASH] = "Rubigu";
EsperantoCardModes[CardModes.PIXIE_KEEP] = "Gardu";
EsperantoCardModes[CardModes.SACRED_GROVE_RECEIVE] = "Ricevu Ĝin";
EsperantoCardModes[CardModes.SACRED_GROVE_NO] = "Ne, Dankon";
EsperantoCardModes[CardModes.ZOMBIE_SPY_DISCARD] = "Forĵetu";
EsperantoCardModes[CardModes.ZOMBIE_SPY_TOPDECK] = "Surmetu";
EsperantoCardModes[CardModes.START_GAME] = "Komencu Ludon";
EsperantoCardModes[CardModes.PEARL_DIVER_TOPDECK] = EsperantoPhrases[Phrases.TOP];
EsperantoCardModes[CardModes.PEARL_DIVER_BOTTOMDECK] = EsperantoPhrases[Phrases.BOTTOM];
EsperantoCardModes[CardModes.SCEPTER_FAIL_ACTION] = "Fiasku Agon";
EsperantoCardModes[CardModes.SCEPTER_ADD_COINS] = "+2 Moneroj";
EsperantoCardModes[CardModes.TREASURER_FAIL] = "Faru Nenion";
EsperantoCardModes[CardModes.TREASURER_KEY] = "Prenu la Ŝlosilon";
EsperantoCardModes[CardModes.BORDER_GUARD_LANTERN] = "Lanterno";
EsperantoCardModes[CardModes.BORDER_GUARD_HORN] = "Korno";
EsperantoCardModes[CardModes.BORDER_GUARD_FAIL] = "Faru Nenion";
EsperantoCardModes[CardModes.PAGEANT_YES] = "+1 Monrezervo";
EsperantoCardModes[CardModes.PAGEANT_NO] = "Ne, Dankon";
EsperantoCardModes[CardModes.SINISTER_PLOT_ADD] = "Aldonu Jetonon";
EsperantoCardModes[CardModes.SINISTER_PLOT_DRAW] = "Tiru";
EsperantoCardModes[CardModes.PLAY] = "Ludu";
EsperantoCardModes[CardModes.DONT_PLAY] = "Ne Ludu";
EsperantoCardModes[CardModes.VILLAGE_GREEN_NOW] = "Nun";
EsperantoCardModes[CardModes.VILLAGE_GREEN_LATER] = "Sekva Vico";
EsperantoCardModes[CardModes.BARGE_NOW] = "Nun";
EsperantoCardModes[CardModes.BARGE_LATER] = "Sekva Vico";
EsperantoCardModes[CardModes.SCRAP_CARD] = "+1 Karto";
EsperantoCardModes[CardModes.SCRAP_ACTION] = "+1 Ago";
EsperantoCardModes[CardModes.SCRAP_BUY] = "+1 Aĉeto";
EsperantoCardModes[CardModes.SCRAP_COIN] = "+1 Monero";
EsperantoCardModes[CardModes.SCRAP_SILVER] = "Gajnu Arĝenton";
EsperantoCardModes[CardModes.SCRAP_HORSE] = "Gajnu Ĉevalon";
EsperantoCardModes[CardModes.MODE_OF_THE_BUTTERFLY] = "Papilie";
EsperantoCardModes[CardModes.MODE_OF_THE_CAMEL] = "Kamele";
EsperantoCardModes[CardModes.MODE_OF_THE_CHAMELEON] = "Ĥameleone";
EsperantoCardModes[CardModes.MODE_OF_THE_FROG] = "Rane";
EsperantoCardModes[CardModes.MODE_OF_THE_GOAT] = "Kapre";
EsperantoCardModes[CardModes.MODE_OF_THE_HORSE] = "Ĉevale";
EsperantoCardModes[CardModes.MODE_OF_THE_MOLE] = "Talpe";
EsperantoCardModes[CardModes.MODE_OF_THE_MONKEY] = "Simie";
EsperantoCardModes[CardModes.MODE_OF_THE_MOUSE] = "Muse";
EsperantoCardModes[CardModes.MODE_OF_THE_MULE] = "Mule";
EsperantoCardModes[CardModes.MODE_OF_THE_OTTER] = "Lutre";
EsperantoCardModes[CardModes.MODE_OF_THE_OWL] = "Strige";
EsperantoCardModes[CardModes.MODE_OF_THE_OX] = "Okse";
EsperantoCardModes[CardModes.MODE_OF_THE_PIG] = "Porke";
EsperantoCardModes[CardModes.MODE_OF_THE_RAT] = "Rate";
EsperantoCardModes[CardModes.MODE_OF_THE_SEAL] = "Foke";
EsperantoCardModes[CardModes.MODE_OF_THE_SHEEP] = "Ŝafe";
EsperantoCardModes[CardModes.MODE_OF_THE_SQUIRREL] = "Sciure";
EsperantoCardModes[CardModes.MODE_OF_THE_TURTLE] = "Testude";
EsperantoCardModes[CardModes.MODE_OF_THE_WORM] = "Verme";
EsperantoCardModes[CardModes.BUTTERFLY_RETURN_IT] = "Redonu Ĝin";
EsperantoCardModes[CardModes.BUTTERFLY_DO_NOTHING] = "Faru Nenion";
EsperantoCardModes[CardModes.WAYFARER_SILVER] = "Gajnu Arĝenton";
EsperantoCardModes[CardModes.DESPERATION_CURSE] = "Gajnu Malbenon";
EsperantoCardModes[CardModes.CRYSTAL_BALL_TRASH] = "Rubigu";
EsperantoCardModes[CardModes.CRYSTAL_BALL_DISCARD] = "Forĵetu";
EsperantoCardModes[CardModes.CRYSTAL_BALL_PLAY] = "Ludu";
EsperantoCardModes[CardModes.CRYSTAL_BALL_TOPDECK] = "Surmetu";
EsperantoCardModes[CardModes.TOWN_VILLAGE] = "+1 Karto +2 Agoj";
EsperantoCardModes[CardModes.TOWN_COINS_BUY] = "+1 Aĉeto +2 Moneroj";
EsperantoCardModes[CardModes.MODIFY_CANTRIP] = "+1 Karto +1 Ago";
EsperantoCardModes[CardModes.MODIFY_GAIN] = "Gajnu karton";
EsperantoCardModes[CardModes.DESERT_GUIDES_KEEP] = "Gardu ĝin";
EsperantoCardModes[CardModes.DESERT_GUIDES_NEW_HAND] = "Nova mano";
EsperantoCardModes[CardModes.BAUBLE_BUY] = "+1 Aĉeto";
EsperantoCardModes[CardModes.BAUBLE_COIN] = "+1 Monero";
EsperantoCardModes[CardModes.BAUBLE_FAVOR] = "+1 Komplzeo";
EsperantoCardModes[CardModes.BAUBLE_TOPDECK] = "Surmetado";
EsperantoCardModes[CardModes.BROKER_CARDS] = "+Kartoj";
EsperantoCardModes[CardModes.BROKER_ACTIONS] = "+Agoj";
EsperantoCardModes[CardModes.BROKER_COINS] = "+Moneroj";
EsperantoCardModes[CardModes.BROKER_FAVORS] = "+Komplezoj";
EsperantoCardModes[CardModes.INNKEEPER_CARD] = "+1 Karto";
EsperantoCardModes[CardModes.INNKEEPER_3] = "+3 Kartoj, Forĵetu 3";
EsperantoCardModes[CardModes.INNKEEPER_5_6] = "+5 Kartoj, Forĵetu 6";
EsperantoCardModes[CardModes.CAPITAL_CITY_DISCARD] = "Forĵetu 2 por +$2";
EsperantoCardModes[CardModes.CAPITAL_CITY_PAY] = "$2 por +2 Kartoj";
EsperantoCardModes[CardModes.SPECIALIST_PLAY_AGAIN] = "Reludu";
EsperantoCardModes[CardModes.SPECIALIST_GAIN_COPY] = "Gajnu kopion";
EsperantoCardModes[CardModes.HILL_FORT_TO_HAND] = "Manmetu";
EsperantoCardModes[CardModes.HILL_FORT_CANTRIP] = "+1 Karto +1 Ago";
EsperantoCardModes[CardModes.STRONGHOLD_COINS] = "+3 Moneroj";
EsperantoCardModes[CardModes.STRONGHOLD_CARDS] = "sekva vico: +3 Kartoj";
EsperantoCardModes[CardModes.TOWN_CRIER_COINS] = "+2 Moneroj";
EsperantoCardModes[CardModes.TOWN_CRIER_SILVER] = "gajnu Arĝenton";
EsperantoCardModes[CardModes.TOWN_CRIER_CANTRIP] = "+1 Karto +1 Ago";
EsperantoCardModes[CardModes.BLACKSMITH_OWL] = "tiru ĝis 6";
EsperantoCardModes[CardModes.BLACKSMITH_OTTER] = "+2 Kartoj";
EsperantoCardModes[CardModes.BLACKSMITH_PIG] = "+1 Karto +1 Ago";
EsperantoCardModes[CardModes.LURKER_TRASH] = "Rubigu el Provizo";
EsperantoCardModes[CardModes.LURKER_GAIN] = "Gajnu el Rubujo";
EsperantoCardModes[CardModes.GRAVEROBBER_GAIN] = "Gajnu sur kartaro";
EsperantoCardModes[CardModes.GRAVEROBBER_TRASH] = "Rubigu el mano";
EsperantoCardModes[CardModes.TREASURER_TRASH] = "Rubigu";
EsperantoCardModes[CardModes.TREASURER_GAIN] = "Gajnu el Rubujo";
EsperantoCardModes[CardModes.INVESTMENT_COIN] = "+$1";
EsperantoCardModes[CardModes.INVESTMENT_VP] = "rubigu por +VP";
EsperantoCardModes[CardModes.WEAVER_SILVERS] = "2 Arĝentojn";
EsperantoCardModes[CardModes.UNUSED_CARDMODE_7] = "unused";
EsperantoCardModes[CardModes.UNUSED_CARDMODE_8] = "unused";
EsperantoCardModes[CardModes.UNUSED_CARDMODE_9] = "unused";
EsperantoCardModes[CardModes.UNUSED_CARDMODE_10] = "unused";
EsperantoCardModes[CardModes.UNUSED_CARDMODE_11] = "unused";
EsperantoCardModes[CardModes.COURSER_CARDS_ACTIONS] = "Cards & Actions";
EsperantoCardModes[CardModes.COURSER_CARDS] = "+2 Cards";
EsperantoCardModes[CardModes.COURSER_ACTIONS] = "+2 Actions";
EsperantoCardModes[CardModes.COURSER_COINS] = "+2 Coins";
EsperantoCardModes[CardModes.COURSER_SILVERS] = "+4 Silvers";
EsperantoCardModes[CardModes.SICKNESS_FAIL_DISCARD] = "Fail to discard";
EsperantoCardModes[CardModes.SICKNESS_CURSE] = "Curse onto your deck";
EsperantoCardModes[CardModes.SICKNESS_FAIL_CURSE] = "Fail to gain a Curse";
EsperantoCardModes[CardModes.SICKNESS_DISCARD] = "Discard 3 cards";
EsperantoCardModes[CardModes.KITSUNE_ACTIONS] = "+2 Actions";
EsperantoCardModes[CardModes.KITSUNE_COINS] = "+2 Coins";
EsperantoCardModes[CardModes.KITSUNE_CURSE] = "Give Curse";
EsperantoCardModes[CardModes.KITSUNE_SILVER] = "Gain a Silver";

EsperantoCardModes[ExtraTurnTypes.ISLAND_FOLK] = EsperantoCardNames[CardNames.ISLAND_FOLK].singular;
EsperantoCardModes[ExtraTurnTypes.VOYAGE] = EsperantoCardNames[CardNames.VOYAGE].singular;
EsperantoCardModes[ExtraTurnTypes.JOURNEY] = EsperantoCardNames[CardNames.JOURNEY].singular;

EsperantoCardModes[ExtraTurnTypes.MISSION] = EsperantoCardNames[CardNames.MISSION].singular;
EsperantoCardModes[ExtraTurnTypes.OUTPOST] = EsperantoCardNames[CardNames.OUTPOST].singular;
EsperantoCardModes[ExtraTurnTypes.POSSESSION] = EsperantoCardNames[CardNames.POSSESSION].singular;
EsperantoCardModes[ExtraTurnTypes.FLEET] = EsperantoCardNames[CardNames.FLEET].singular;
EsperantoCardModes[ExtraTurnTypes.SEIZE_THE_DAY] = EsperantoCardNames[CardNames.SEIZE_THE_DAY].singular;

// Missing lines as of 09.01.2023 19:11:39
EsperantoCardModes[CardModes.CABIN_BOY_COINS] = "+2 Coins";
EsperantoCardModes[CardModes.CABIN_BOY_GAIN] = "Trash for Duration";
EsperantoCardModes[CardModes.GONDOLA_NOW] = "+2 Coins";
EsperantoCardModes[CardModes.GONDOLA_LATER] = "Next Turn";
EsperantoCardModes[CardModes.QUARTERMASTER_GAIN] = "Gain a card";
EsperantoCardModes[CardModes.QUARTERMASTER_TAKE] = "Take a card";
EsperantoCardModes[CardModes.AMPHORA_NOW] = "+1 Buy, +3 Coins";
EsperantoCardModes[CardModes.AMPHORA_LATER] = "Later";
EsperantoCardModes[CardModes.SPELL_SCROLL_PLAY] = "Play";
EsperantoCardModes[CardModes.SPELL_SCROLL_REFUSE] = "Don't Play";