"use strict";
// possible ability descriptions that could use "Argument0" to be more helpful
//
// Argument0 is (a list of) set aside card(s):
// --------------------------------------------
// DutchAbilityDescriptions[AbilityNames.HAVEN_ABILITY] = "take Argument0 (" + DutchCardNames[CardNames.HAVEN].singular + ")";
// DutchAbilityDescriptions[AbilityNames.SAVE_RETURN_TO_HAND] = "take Argument0  (" + DutchCardNames[CardNames.SAVE].singular + ")";
// DutchAbilityDescriptions[AbilityNames.GEAR_ABILITY] = "take Argument0 (" + DutchCardNames[CardNames.GEAR].singular + ")";
//
//
// Argument0 is some cost that an effect relates to
// --------------------------------------------
// DutchAbilityDescriptions[AbilityNames.HAGGLER_ABILITY] = "gain a non-Victory card cheaper than Argument0 (" + DutchCardNames[CardNames.HAGGLER].singular + ")";
// DutchAbilityDescriptions[AbilityNames.GAIN_CHEAPER] = "gain a card cheaper than Argument0";
// DutchAbilityDescriptions[AbilityNames.CHARM] = "may gain a different card costing Argument0 (" + DutchCardNames[CardNames.CHARM].singular +")";
//
//
// Argument0 is the name of some card that an effect relates to
// ------------------------------------------------------------
// DutchAbilityDescriptions[AbilityNames.CALL_DUPLICATE] = "may call " + DutchCardNames[CardNames.DUPLICATE].noun + " to gain a copy of Argument0";
// DutchAbilityDescriptions[AbilityNames.CALL_ROYAL_CARRIAGE] = "may call " + DutchCardNames[CardNames.ROYAL_CARRIAGE].noun + " to replay Argument0";
// DutchAbilityDescriptions[AbilityNames.TOPDECK_REACTION] = "may topdeck Argument0";
// DutchAbilityDescriptions[AbilityNames.WATCHTOWER_TRASH_REACTION] = "may reveal " + DutchCardNames[CardNames.WATCHTOWER].noun + " to trash Argument0";
// DutchAbilityDescriptions[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "may reveal " + DutchCardNames[CardNames.WATCHTOWER].noun + " to topdeck Argument0";
//
//
// Argument0 is some number an effect relates to
// ----------------------------------------------
// DutchAbilityDescriptions[AbilityNames.EMPORIUM_GAIN_POINTS] = "+Argument0 VP (" + DutchCardNames[CardNames.EMPORIUM].singular + ")";
//    the following needs the (server side?) magic that takes "3" and "Gold" and turns it into the language correct version of "3 Golds"
// DutchAbilityDescriptions[AbilityNames.FORTUNE] = "gain Argument0 (" + DutchCardNames[CardNames.FORTUNE].singular + ")";
// DutchAbilityDescriptions[AbilityNames.GRAND_CASTLE] = "+Argument0 VP (" + DutchCardNames[CardNames.GRAND_CASTLE].singular + ")";
// DutchAbilityDescriptions[AbilityNames.TEMPLE] = "take all (Argument0) VP from the " + DutchCardNames[CardNames.TEMPLE].singular + " pile";
// DutchAbilityDescriptions[AbilityNames.TAX_RECEIVE] = "take all (Argument0) debt from the pile you bought from (" + DutchCardNames[CardNames.TAX].singular + ")";
// DutchAbilityDescriptions[AbilityNames.VP_FROM_AQUEDUCT] = "take all (Argument0) VP from " + DutchCardNames[CardNames.AQUEDUCT].singular;
// DutchAbilityDescriptions[AbilityNames.VP_FROM_DEFILED_SHRINE] = "take all (Argument0) VP from " + DutchCardNames[CardNames.DEFILED_SHRINE].singular;
//
//
// Misc
// ----
//     in the following Argument0 is either "" or "and return to your Action phase ", depending on wether the gainer is in their buy phase at the moment.
// DutchAbilityDescriptions[AbilityNames.VILLA] = "put this into your hand, +1 Action Argument0(" + DutchCardNames[CardNames.VILLA].singular + ")";
//     establishing the source of the effect
// DutchAbilityDescriptions[AbilityNames.RECEIVE_HEX] = "receive a Hex (Argument0)";


var DutchAbilityDescriptions = {};

DutchAbilityDescriptions[AbilityNames.BUY] = "buy";
DutchAbilityDescriptions[AbilityNames.PLAY_ATTACK] = "plays";
DutchAbilityDescriptions[AbilityNames.DRAW_INSTRUCTIONS] = "DRAW_INSTRUCTIONS";
DutchAbilityDescriptions[AbilityNames.PLAY_INSTRUCTIONS] = "play";
DutchAbilityDescriptions[AbilityNames.RESOLVE_EVENT] = "RESOLVE_EVENT";
DutchAbilityDescriptions[AbilityNames.GAIN_BY_NAME] = "gain Argument0 (Argument1)";

DutchAbilityDescriptions[AbilityNames.FISHING_VILLAGE_ABILITY] = "+1 Action, +$1 (" + DutchCardNames[CardNames.FISHING_VILLAGE].singular + ")";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.REMOVE_COST_REDUCTION] = "REMOVE_COST_REDUCTION";
DutchAbilityDescriptions[AbilityNames.HAVEN_ABILITY] = "put set aside card in your hand (" + DutchCardNames[CardNames.HAVEN].singular + ")";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.SAVE_RETURN_TO_HAND] = "put card in your hand (" + DutchCardNames[CardNames.SAVE].singular + ")";
DutchAbilityDescriptions[AbilityNames.CARAVAN_ABILITY] = "+1 Card (" + DutchCardNames[CardNames.CARAVAN].singular + ")";
DutchAbilityDescriptions[AbilityNames.MERCHANT_SHIP_ABILITY] = "+$2 (" + DutchCardNames[CardNames.MERCHANT_SHIP].singular + ")";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.OUTPOST_ABILITY] = DutchCardNames[CardNames.OUTPOST].singular;
DutchAbilityDescriptions[AbilityNames.TACTICIAN_ABILITY] = "+5 Cards, +1 Action, +1 Buy (" + DutchCardNames[CardNames.TACTICIAN].singular + ")";
DutchAbilityDescriptions[AbilityNames.WHARF_ABILITY] = "+2 Cards, +1 Buy (" + DutchCardNames[CardNames.WHARF].singular + ")";
DutchAbilityDescriptions[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = "+1 Card, put this in your hand (" + DutchCardNames[CardNames.HORSE_TRADERS].singular + ")";
DutchAbilityDescriptions[AbilityNames.DUCHESS_MAY_GAIN] = "may gain " + DutchCardNames[CardNames.DUCHESS].noun;
DutchAbilityDescriptions[AbilityNames.FOOLS_GOLD_TRASH] = "you may trash " + DutchCardNames[CardNames.FOOLS_GOLD].noun + " to topdeck " + DutchCardNames[CardNames.GOLD].noun;
DutchAbilityDescriptions[AbilityNames.NOBLE_BRIGAND] = "try to rob opponents for " + DutchCardNames[CardNames.SILVER].singular + " or " + DutchCardNames[CardNames.GOLD].singular + " (" + DutchCardNames[CardNames.NOBLE_BRIGAND].singular + ")";
DutchAbilityDescriptions[AbilityNames.HAGGLER_ABILITY] = "gain a cheaper non-Victory card (" + DutchCardNames[CardNames.HAGGLER].singular + ")";
DutchAbilityDescriptions[AbilityNames.INN_ABILITY] = "shuffle Actions from discard into your deck (" + DutchCardNames[CardNames.INN].singular + ")";
DutchAbilityDescriptions[AbilityNames.MANDARIN] = "topdeck all your Treasures in play (" + DutchCardNames[CardNames.MANDARIN].singular + ")";
DutchAbilityDescriptions[AbilityNames.FARMLAND] = "remodel a hand card by exactly $2 (" + DutchCardNames[CardNames.FARMLAND].singular + ")";
DutchAbilityDescriptions[AbilityNames.GAIN_CHEAPER] = "gain a cheaper card";
DutchAbilityDescriptions[AbilityNames.GAIN_TWO_RUINS] = "gain 2 Ruins";
DutchAbilityDescriptions[AbilityNames.HUNTING_GROUNDS] = "gain " + DutchCardNames[CardNames.DUCHY].noun + " or 3 " + DutchCardNames[CardNames.ESTATE].plural + " (" + DutchCardNames[CardNames.HUNTING_GROUNDS].singular + ")";
DutchAbilityDescriptions[AbilityNames.SQUIRE_GAIN_ATTACK] = "gain an Attack card (" + DutchCardNames[CardNames.SQUIRE].singular + ")";
DutchAbilityDescriptions[AbilityNames.URCHIN_TRANSFORM] = "may trash " + DutchCardNames[CardNames.URCHIN].noun + " to gain " + DutchCardNames[CardNames.MERCENARY].noun;
DutchAbilityDescriptions[AbilityNames.FEODUM_GAIN_SILVERS] = "gain 3 " + DutchCardNames[CardNames.SILVER].plural + " (" + DutchCardNames[CardNames.FEODUM].singular + ")";
DutchAbilityDescriptions[AbilityNames.DOCTOR_OVERPAY] = "may overpay to trash or discard cards from your deck (" + DutchCardNames[CardNames.DOCTOR].singular + ")";
DutchAbilityDescriptions[AbilityNames.HERALD_OVERPAY] = "may overpay to topdeck cards from your discard (" + DutchCardNames[CardNames.HERALD].singular + ")";
DutchAbilityDescriptions[AbilityNames.MASTERPIECE_OVERPAY] = "may overpay to gain " + DutchCardNames[CardNames.SILVER].plural + " (" + DutchCardNames[CardNames.MASTERPIECE].singular + ")";
DutchAbilityDescriptions[AbilityNames.STONEMASON_OVERPAY] = "may overpay to gain 2 Action cards (" + DutchCardNames[CardNames.STONEMASON].singular + ")";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.EXCHANGE] = "EXCHANGE";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.SHUFFLE] = "SHUFFLE";
DutchAbilityDescriptions[AbilityNames.AMULET] = "+$1; or trash a card; or gain " + DutchCardNames[CardNames.SILVER].noun + " (" + DutchCardNames[CardNames.AMULET].singular + ")";
DutchAbilityDescriptions[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = "+$1 (" + DutchCardNames[CardNames.CARAVAN_GUARD].singular + ")";
DutchAbilityDescriptions[AbilityNames.DUNGEON_ABILITY] = "+2 Cards, then discard 2 cards (" + DutchCardNames[CardNames.DUNGEON].singular + ")";
DutchAbilityDescriptions[AbilityNames.GEAR_ABILITY] = "put set aside card(s) in your hand (" + DutchCardNames[CardNames.GEAR].singular + ")";
DutchAbilityDescriptions[AbilityNames.MESSENGER_GAIN] = "all players gain the same $4 (or cheaper) card (" + DutchCardNames[CardNames.MESSENGER].singular + ")";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.BRIDGE_TROLL_ABILITY] = "+1 Buy (" + DutchCardNames[CardNames.BRIDGE_TROLL].singular + ")";
DutchAbilityDescriptions[AbilityNames.HAUNTED_WOODS_DURATION] = "+3 Cards (" + DutchCardNames[CardNames.HAUNTED_WOODS].singular + ")";
DutchAbilityDescriptions[AbilityNames.HAUNTED_WOODS_TOPDECK] = "topdeck all cards in your hand (opponent's " + DutchCardNames[CardNames.HAUNTED_WOODS].singular + ")";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.LOST_CITY] = "+1 Card (opponent's " + DutchCardNames[CardNames.LOST_CITY].singular + ")";
DutchAbilityDescriptions[AbilityNames.WINE_MERCHANT_DISCARD] = "may discard " + DutchCardNames[CardNames.WINE_MERCHANT].noun + " from your Tavern mat";
DutchAbilityDescriptions[AbilityNames.HIRELING] = "+1 Card (" + DutchCardNames[CardNames.HIRELING].singular + ")";
DutchAbilityDescriptions[AbilityNames.SWAMP_HAG_DURATION] = "+$3 (" + DutchCardNames[CardNames.SWAMP_HAG].singular + ")";
DutchAbilityDescriptions[AbilityNames.TRASH_TOKEN_ABILITY] = "may trash a card (Trashing Token)";
DutchAbilityDescriptions[AbilityNames.EMPORIUM_GAIN_POINTS] = "+2 VP, if you have at least 5 Action cards in play (" + DutchCardNames[CardNames.EMPORIUM].singular + ")";
DutchAbilityDescriptions[AbilityNames.ROCKS_GAINS_SILVER] = "gain " + DutchCardNames[CardNames.SILVER].noun + " (" + DutchCardNames[CardNames.ROCKS].singular + ")";
DutchAbilityDescriptions[AbilityNames.FORTUNE] = "gain " + DutchCardNames[CardNames.GOLD].noun + " per " + DutchCardNames[CardNames.GLADIATOR].singular + " in play (" + DutchCardNames[CardNames.FORTUNE].singular + ")";
DutchAbilityDescriptions[AbilityNames.CRUMBLING_CASTLE] = "+1 VP and gain " + DutchCardNames[CardNames.SILVER].noun + " (" + DutchCardNames[CardNames.CRUMBLING_CASTLE].singular + ")";
DutchAbilityDescriptions[AbilityNames.HAUNTED_CASTLE] = "gain " + DutchCardNames[CardNames.GOLD].noun + " and opponents must topdeck some cards (" + DutchCardNames[CardNames.HAUNTED_CASTLE].singular + ")";
DutchAbilityDescriptions[AbilityNames.SPRAWLING_CASTLE] = "gain " + DutchCardNames[CardNames.DUCHY].noun + " or 3 " + DutchCardNames[CardNames.ESTATE].plural + " (" + DutchCardNames[CardNames.SPRAWLING_CASTLE].singular + ")";
DutchAbilityDescriptions[AbilityNames.GRAND_CASTLE] = "+1 VP per Victory card in play or your hand (" + DutchCardNames[CardNames.GRAND_CASTLE].singular + ")";
DutchAbilityDescriptions[AbilityNames.ENCHANTRESS_ABILITY] = "+2 Cards (" + DutchCardNames[CardNames.ENCHANTRESS].singular + ")";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.ENCHANTRESS_CANTRIP] = "+1 Card, +1 Action (" + DutchCardNames[CardNames.ENCHANTRESS].singular + ")";
DutchAbilityDescriptions[AbilityNames.TEMPLE] = "take all VP from the " + DutchCardNames[CardNames.TEMPLE].singular + " pile";
DutchAbilityDescriptions[AbilityNames.VILLA] = "put this into your hand, +1 Action, possibly return to your Action phase (" + DutchCardNames[CardNames.VILLA].singular + ")";
DutchAbilityDescriptions[AbilityNames.ARCHIVE_ABILITY] = "take one of: Argument0 (" + DutchCardNames[CardNames.ARCHIVE].singular + ")";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.CAPITAL_TAKE_DEBT] = "take 6 debt, then you may pay off debt (" + DutchCardNames[CardNames.CAPITAL].singular + ")";
DutchAbilityDescriptions[AbilityNames.CHARM] = "may gain different card with the same cost (" + DutchCardNames[CardNames.CHARM].singular + ")";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.FORUM] = "+1 Buy (" + DutchCardNames[CardNames.FORUM].singular + ")";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.HERMIT] = "trash this, gain a Madman (" + DutchCardNames[CardNames.HERMIT].singular + ")";
DutchAbilityDescriptions[AbilityNames.DONATE] = "trash from your combined hand, deck and discard pile (" + DutchCardNames[CardNames.DONATE].singular + ")";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.TAX_RECEIVE] = "take the debt from the pile you bought from (" + DutchCardNames[CardNames.TAX].singular + ")";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.DOMINATE] = "gain " + DutchCardNames[CardNames.PROVINCE].noun + ", then +9 VP if you did (" + DutchCardNames[CardNames.DOMINATE].singular + ")";
DutchAbilityDescriptions[AbilityNames.VP_TO_AQUEDUCT] = "move 1 VP from Treasure pile to " + DutchCardNames[CardNames.AQUEDUCT].singular;
DutchAbilityDescriptions[AbilityNames.VP_FROM_AQUEDUCT] = "take all VP from " + DutchCardNames[CardNames.AQUEDUCT].singular;
DutchAbilityDescriptions[AbilityNames.ARENA_DISCARD_ACTION] = "may discard an Action to take 2 VP from " + DutchCardNames[CardNames.ARENA].singular;
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.VP_FROM_BASILICA] = "take 2 VP from " + DutchCardNames[CardNames.BASILICA].singular + " if you have $2 or more";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.VP_FROM_BATHS] = "take 2 VP from " + DutchCardNames[CardNames.BATHS].singular;
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.VP_FROM_BATTLEFIELD] = "take 2 VP from " + DutchCardNames[CardNames.BATTLEFIELD].singular;
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.VP_FROM_COLONNADE] = "take 2 VP from " + DutchCardNames[CardNames.COLONNADE].singular + " if you bought a card you have in play";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.VP_TO_DEFILED_SHRINE] = "move 1 VP from Action pile to " + DutchCardNames[CardNames.DEFILED_SHRINE].singular;
DutchAbilityDescriptions[AbilityNames.VP_FROM_DEFILED_SHRINE] = "take all VP from " + DutchCardNames[CardNames.DEFILED_SHRINE].singular;
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.VP_FROM_LABYRINTH] = "take 2 VP from " + DutchCardNames[CardNames.LABYRINTH].singular;
DutchAbilityDescriptions[AbilityNames.MOUNTAIN_PASS_BIDDING] = "bid debt to get +8 VP (" + DutchCardNames[CardNames.MOUNTAIN_PASS].singular + ")";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.TOMB] = "+1 VP (" + DutchCardNames[CardNames.TOMB].singular + ")";
DutchAbilityDescriptions[AbilityNames.LIGHTHOUSE_COIN] = "+$1 (" + DutchCardNames[CardNames.LIGHTHOUSE].singular + ")";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.REACTION] = "REACTION";
DutchAbilityDescriptions[AbilityNames.CALL_GUIDE] = "may call " + DutchCardNames[CardNames.GUIDE].noun + " to discard hand, +5 Cards";
DutchAbilityDescriptions[AbilityNames.CALL_RATCATCHER] = "may call " + DutchCardNames[CardNames.RATCATCHER].noun + " to trash a card";
DutchAbilityDescriptions[AbilityNames.CALL_TRANSMOGRIFY] = "may call " + DutchCardNames[CardNames.TRANSMOGRIFY].noun + " to upgrade a card";
DutchAbilityDescriptions[AbilityNames.CALL_TEACHER] = "may call " + DutchCardNames[CardNames.TEACHER].noun + " to move an Adventures token to an Action pile";
DutchAbilityDescriptions[AbilityNames.CALL_DUPLICATE] = "may call " + DutchCardNames[CardNames.DUPLICATE].noun + " to gain a copy of that card";
DutchAbilityDescriptions[AbilityNames.CALL_COIN] = "call " + DutchCardNames[CardNames.COIN_OF_THE_REALM].noun + " for +2 Actions";
DutchAbilityDescriptions[AbilityNames.CALL_ROYAL_CARRIAGE] = "call " + DutchCardNames[CardNames.ROYAL_CARRIAGE].noun + " to replay the last Action";
DutchAbilityDescriptions[AbilityNames.START_OF_TURN] = "start your turn";
DutchAbilityDescriptions[AbilityNames.START_BUY_PHASE] = "start your buy phase";
DutchAbilityDescriptions[AbilityNames.END_OF_BUY_PHASE] = "end your buy phase";
DutchAbilityDescriptions[AbilityNames.BETWEEN_TURNS] = "are between turns";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.END_OF_TURN] = "end your turn";
DutchAbilityDescriptions[AbilityNames.SUMMON_PLAY] = "play Argument0 (" + DutchCardNames[CardNames.SUMMON].singular + ")";
DutchAbilityDescriptions[AbilityNames.CARAVAN_GUARD_REACTION] = "you may play " + DutchCardNames[CardNames.CARAVAN_GUARD].noun;
// Ingix: could not cause below phrase to be used, I'm pretty sure it is unused and superceded by DutchAbilityDescriptions[AbilityNames.TOPDECK_REACTION] below
DutchAbilityDescriptions[AbilityNames.TRAVELLING_FAIR_REACTION] = "may topdeck it (" + DutchCardNames[CardNames.TRAVELLING_FAIR].singular + ")";
DutchAbilityDescriptions[AbilityNames.MOAT_REACTION] = "you may reveal " + DutchCardNames[CardNames.MOAT].noun + " to block the attack";
DutchAbilityDescriptions[AbilityNames.HORSE_TRADERS_REACTION] = "you may set aside " + DutchCardNames[CardNames.HORSE_TRADERS].noun + " for +1 Card next turn";
DutchAbilityDescriptions[AbilityNames.BEGGAR_REACTION] = "you may discard " + DutchCardNames[CardNames.BEGGAR].noun + " to gain 2 " + DutchCardNames[CardNames.SILVER].plural + ", one onto your deck";
DutchAbilityDescriptions[AbilityNames.MARKET_SQUARE_REACTION] = "may discard " + DutchCardNames[CardNames.MARKET_SQUARE].noun + " to gain " + DutchCardNames[CardNames.GOLD].noun;
DutchAbilityDescriptions[AbilityNames.TRADER_REACTION] = "you may reveal " + DutchCardNames[CardNames.TRADER].noun + " to instead gain " + DutchCardNames[CardNames.SILVER].noun;
DutchAbilityDescriptions[AbilityNames.DIPLOMAT_REACTION] = "you may reveal " + DutchCardNames[CardNames.DIPLOMAT].noun + " for +2 Cards, discard 3 cards";
DutchAbilityDescriptions[AbilityNames.TOPDECK_REACTION] = "may topdeck it";
DutchAbilityDescriptions[AbilityNames.WATCHTOWER_TRASH_REACTION] = "may reveal " + DutchCardNames[CardNames.WATCHTOWER].noun + " to trash it";
DutchAbilityDescriptions[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "may reveal " + DutchCardNames[CardNames.WATCHTOWER].noun + " to topdeck it";
DutchAbilityDescriptions[AbilityNames.PRINCE_PLAY] = "play Argument0 (" + DutchCardNames[CardNames.PRINCE].singular + ")";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.PRINCE_SET_ASIDE] = "set it aside (" + DutchCardNames[CardNames.PRINCE].singular + ")";
DutchAbilityDescriptions[AbilityNames.TUNNEL_REVEAL] = "may reveal " + DutchCardNames[CardNames.TUNNEL].noun + " to gain " + DutchCardNames[CardNames.GOLD].noun;
DutchAbilityDescriptions[AbilityNames.POSSESSION_GAIN_INSTEAD] = "Argument0 gains Argument2 instead of Argument1 (" + DutchCardNames[CardNames.POSSESSION].singular + ")";
DutchAbilityDescriptions[AbilityNames.HOVEL_TRASH] = "may trash " + DutchCardNames[CardNames.HOVEL].noun;
DutchAbilityDescriptions[AbilityNames.DELUDED_RETURNED] = "return " + DutchCardNames[CardNames.DELUDED].singular;
DutchAbilityDescriptions[AbilityNames.ENVIOUS_RETURNED] = "return " + DutchCardNames[CardNames.ENVIOUS].singular;
DutchAbilityDescriptions[AbilityNames.RECEIVE_BOON] = "receive Argument0";
DutchAbilityDescriptions[AbilityNames.COBBLER_ABILITY] = "gain a $4 (or cheaper) card to your hand (" + DutchCardNames[CardNames.COBBLER].singular + ")";
DutchAbilityDescriptions[AbilityNames.DEN_OF_SIN_ABILITY] = "+2 Cards (" + DutchCardNames[CardNames.DEN_OF_SIN].singular + ")";
DutchAbilityDescriptions[AbilityNames.FAITHFUL_HOUND] = "may set " + DutchCardNames[CardNames.FAITHFUL_HOUND].singular + " aside (itself)";
DutchAbilityDescriptions[AbilityNames.FAITHFUL_HOUND_RETURN] = "return this to your hand (" + DutchCardNames[CardNames.FAITHFUL_HOUND].singular + ")";
DutchAbilityDescriptions[AbilityNames.GHOST_TOWN_ABILITY] = "+1 Card, +1 Action (" + DutchCardNames[CardNames.GHOST_TOWN].singular + ")";
DutchAbilityDescriptions[AbilityNames.GUARDIAN_COIN] = "+$1 (" + DutchCardNames[CardNames.GUARDIAN].singular + ")";
DutchAbilityDescriptions[AbilityNames.RAIDER_ABILITY] = "+$3 (" + DutchCardNames[CardNames.RAIDER].singular + ")";
DutchAbilityDescriptions[AbilityNames.SECRET_CAVE] = "+$3 (" + DutchCardNames[CardNames.SECRET_CAVE].singular + ")";
DutchAbilityDescriptions[AbilityNames.CEMETERY] = "trash up to 4 cards (" + DutchCardNames[CardNames.CEMETERY].singular + ")";
DutchAbilityDescriptions[AbilityNames.HAUNTED_MIRROR] = "may discard an Action to gain " + DutchCardNames[CardNames.GHOST].noun + " (" + DutchCardNames[CardNames.HAUNTED_MIRROR].singular + ")";
DutchAbilityDescriptions[AbilityNames.GHOST] = "play Argument0 twice (" + DutchCardNames[CardNames.GHOST].singular + ")";
DutchAbilityDescriptions[AbilityNames.CRYPT_ABILITY] = "take one of: Argument0 (" + DutchCardNames[CardNames.CRYPT].singular + ")";
// Ingix: could not cause below phrase to be used
DutchAbilityDescriptions[AbilityNames.REMOVE_FLIPPED_STATUS] = "unflip cards in the trash";
DutchAbilityDescriptions[AbilityNames.LOST_IN_THE_WOODS] = "may discard a card to receive a Boon";
DutchAbilityDescriptions[AbilityNames.BLESSED_VILLAGE] = "take a Boon";
DutchAbilityDescriptions[AbilityNames.FORTRESS] = "put " + DutchCardNames[CardNames.FORTRESS].singular + " into your hand";
DutchAbilityDescriptions[AbilityNames.RECEIVE_HEX] = "receive a Hex";
DutchAbilityDescriptions[AbilityNames.CHANGELING_MAY_EXCHANGE] = "may exchange Argument0 for " + DutchCardNames[CardNames.CHANGELING].noun;
DutchAbilityDescriptions[AbilityNames.CHURCH] = "put Argument0 in your hand, then you may trash (" + DutchCardNames[CardNames.CHURCH].singular + ")";
DutchAbilityDescriptions[AbilityNames.CAPTAIN] = "Play an action from the supply(" + DutchCardNames[CardNames.CAPTAIN].singular + ")";