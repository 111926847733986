"use strict";

var JapaneseAbilityDescriptions = {};

JapaneseAbilityDescriptions[AbilityNames.BUY] = "購入する";
JapaneseAbilityDescriptions[AbilityNames.PLAY_ATTACK] = "使用する";
JapaneseAbilityDescriptions[AbilityNames.DRAW_INSTRUCTIONS] = "+Argument0カードを引く";
JapaneseAbilityDescriptions[AbilityNames.PLAY_INSTRUCTIONS] = "使用する";
JapaneseAbilityDescriptions[AbilityNames.RESOLVE_EVENT] = "イベントを解決する";
JapaneseAbilityDescriptions[AbilityNames.GAIN_BY_NAME] = "Argument1でArgument0を獲得する";
JapaneseAbilityDescriptions[AbilityNames.FISHING_VILLAGE_ABILITY] = "+1アクション、+$1";
JapaneseAbilityDescriptions[AbilityNames.REMOVE_COST_REDUCTION] = "コスト軽減を終える";
JapaneseAbilityDescriptions[AbilityNames.HAVEN_ABILITY] = "Argument0を手札に加える";
JapaneseAbilityDescriptions[AbilityNames.SAVE_RETURN_TO_HAND] = "Argument0を手札に加える";
JapaneseAbilityDescriptions[AbilityNames.CARAVAN_ABILITY] = "+1カードを引く";
JapaneseAbilityDescriptions[AbilityNames.MERCHANT_SHIP_ABILITY] = "+$2";
JapaneseAbilityDescriptions[AbilityNames.OUTPOST_ABILITY] = "[Outpost]";
JapaneseAbilityDescriptions[AbilityNames.TACTICIAN_ABILITY] = "+5カードを引く、+1アクション、+1購入";
JapaneseAbilityDescriptions[AbilityNames.WHARF_ABILITY] = "+2カードを引く、+1購入";
JapaneseAbilityDescriptions[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = "+1カードを引く、[Horse Traders]を手札に加える";
JapaneseAbilityDescriptions[AbilityNames.DUCHESS_MAY_GAIN] = "[Duchess]を獲得する";
JapaneseAbilityDescriptions[AbilityNames.FOOLS_GOLD_TRASH] = "[Fools Gold]を[^nGold]にする";
JapaneseAbilityDescriptions[AbilityNames.NOBLE_BRIGAND] = "攻撃する";
JapaneseAbilityDescriptions[AbilityNames.HAGGLER_ABILITY] = "勝利点以外のより安いカードを獲得する";
JapaneseAbilityDescriptions[AbilityNames.INN_ABILITY] = "捨て札からアクションカードを積み込みシャッフルする";
JapaneseAbilityDescriptions[AbilityNames.MANDARIN] = "場の財宝を積み込む";
JapaneseAbilityDescriptions[AbilityNames.FARMLAND] = "コストがちょうど$2高いカードに改築する";
JapaneseAbilityDescriptions[AbilityNames.GAIN_CHEAPER] = "より安いカードを獲得する";
JapaneseAbilityDescriptions[AbilityNames.GAIN_TWO_RUINS] = "廃墟2枚を獲得する";
JapaneseAbilityDescriptions[AbilityNames.HUNTING_GROUNDS] = "[^nDuchy]か[^pEstate]3枚を獲得する";
JapaneseAbilityDescriptions[AbilityNames.SQUIRE_GAIN_ATTACK] = "アタックカードを獲得する";
JapaneseAbilityDescriptions[AbilityNames.URCHIN_TRANSFORM] = "[Urchin]を[^nMercenary]にする";
JapaneseAbilityDescriptions[AbilityNames.FEODUM_GAIN_SILVERS] = "[^pSilver]3枚を獲得する";
JapaneseAbilityDescriptions[AbilityNames.DOCTOR_OVERPAY] = "[Doctor]に過払いする";
JapaneseAbilityDescriptions[AbilityNames.RESOLVE_DOCTOR_OVERPAY] = "廃棄または捨て札にしてもよい";
JapaneseAbilityDescriptions[AbilityNames.HERALD_OVERPAY] = "過払いして捨て札から積み込む";
JapaneseAbilityDescriptions[AbilityNames.RESOLVE_HERALD_OVERPAY] = "捨て札から積み込む";
JapaneseAbilityDescriptions[AbilityNames.MASTERPIECE_OVERPAY] = "過払いしただけ[^pSilver]を獲得する";
JapaneseAbilityDescriptions[AbilityNames.RESOLVE_MASTERPIECE_OVERPAY] = "[^pSilver]を獲得する";
JapaneseAbilityDescriptions[AbilityNames.STONEMASON_OVERPAY] = "過払いしてアクションカード2枚を獲得する";
JapaneseAbilityDescriptions[AbilityNames.RESOLVE_STONEMASON_OVERPAY] = "アクションカード2枚を獲得する";
JapaneseAbilityDescriptions[AbilityNames.EXCHANGE] = "交換する";
JapaneseAbilityDescriptions[AbilityNames.AMULET] = "効果を選択する";
JapaneseAbilityDescriptions[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = "+$1";
JapaneseAbilityDescriptions[AbilityNames.DUNGEON_ABILITY] = "手札を入れ替える";
JapaneseAbilityDescriptions[AbilityNames.GEAR_ABILITY] = "Argument0を手札に加える";
JapaneseAbilityDescriptions[AbilityNames.MESSENGER_GAIN] = "全員にコスト$4以下のカードを配る";
JapaneseAbilityDescriptions[AbilityNames.BRIDGE_TROLL_ABILITY] = "+1購入";
JapaneseAbilityDescriptions[AbilityNames.HAUNTED_WOODS_DURATION] = "+3カードを引く";
JapaneseAbilityDescriptions[AbilityNames.HAUNTED_WOODS_TOPDECK] = "対戦相手の[Haunted Woods]で手札を山札に戻す";
JapaneseAbilityDescriptions[AbilityNames.LOST_CITY] = "対戦相手の[Lost City]獲得により+1カードを引く";
JapaneseAbilityDescriptions[AbilityNames.WINE_MERCHANT_DISCARD] = "酒場マットから捨て札にする";
JapaneseAbilityDescriptions[AbilityNames.HIRELING] = "+1カードを引く";
JapaneseAbilityDescriptions[AbilityNames.SWAMP_HAG_DURATION] = "+$3";
JapaneseAbilityDescriptions[AbilityNames.TRASH_TOKEN_ABILITY] = "廃棄トークンで廃棄する";
JapaneseAbilityDescriptions[AbilityNames.EMPORIUM_GAIN_POINTS] = "場にアクションカードが5枚以上ある場合+2vp";
JapaneseAbilityDescriptions[AbilityNames.ROCKS_GAINS_SILVER] = "[^nSilver]を獲得する";
JapaneseAbilityDescriptions[AbilityNames.FORTUNE] = "場の[Gladiator]の枚数だけ[^nGold]を獲得する";
JapaneseAbilityDescriptions[AbilityNames.CRUMBLING_CASTLE] = "+1vp、[^nSilver]を獲得する";
JapaneseAbilityDescriptions[AbilityNames.HAUNTED_CASTLE] = "[^nGold]を獲得し、対戦相手は手札2枚を山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.SPRAWLING_CASTLE] = "[^nDuchy]か[^pEstate]3枚を獲得する";
JapaneseAbilityDescriptions[AbilityNames.GRAND_CASTLE] = "場とあなたの手札の勝利点の枚数だけ+1vp";
JapaneseAbilityDescriptions[AbilityNames.ENCHANTRESS_ABILITY] = "+2カードを引く";
JapaneseAbilityDescriptions[AbilityNames.ENCHANTRESS_CANTRIP] = "+1カードを引く、+1アクション";
JapaneseAbilityDescriptions[AbilityNames.TEMPLE] = "[Temple]の山からvpを得る";
JapaneseAbilityDescriptions[AbilityNames.VILLA] = "手札に加え+1アクション、可能ならアクションフェイズに戻る";
JapaneseAbilityDescriptions[AbilityNames.ARCHIVE_ABILITY] = "Argument0のうち1枚を手札に加える";
JapaneseAbilityDescriptions[AbilityNames.CAPITAL_TAKE_DEBT] = "6負債を得た後、負債を返済する";
JapaneseAbilityDescriptions[AbilityNames.CHARM] = "同じコストを持つ異なる名前のカード1枚を獲得する";
JapaneseAbilityDescriptions[AbilityNames.FORUM] = "+1購入";
JapaneseAbilityDescriptions[AbilityNames.HERMIT] = "[Hermit]を[^nMadman]にする";
JapaneseAbilityDescriptions[AbilityNames.DONATE] = "廃棄する";
JapaneseAbilityDescriptions[AbilityNames.TAX_RECEIVE] = "山から負債を受け取る";
JapaneseAbilityDescriptions[AbilityNames.DOMINATE] = "[^nProvince]を獲得する。そうした場合、+9vp";
JapaneseAbilityDescriptions[AbilityNames.VP_TO_AQUEDUCT] = "[Aqueduct]にvpを移動する";
JapaneseAbilityDescriptions[AbilityNames.VP_FROM_AQUEDUCT] = "[Aqueduct]からvpを得る";
JapaneseAbilityDescriptions[AbilityNames.ARENA_DISCARD_ACTION] = "アクションカードを捨て札にして[Arena]から2vpを得る";
JapaneseAbilityDescriptions[AbilityNames.VP_FROM_BASILICA] = "$2持っている場合[Basilica]を2vpを得る";
JapaneseAbilityDescriptions[AbilityNames.VP_FROM_BATHS] = "[Baths]から2vpを得る";
JapaneseAbilityDescriptions[AbilityNames.VP_FROM_BATTLEFIELD] = "[Battlefield]から2vpを得る";
JapaneseAbilityDescriptions[AbilityNames.VP_FROM_COLONNADE] = "獲得したカードと同じアクションカードが場にある場合[Colonnade]から2vpを得る";
JapaneseAbilityDescriptions[AbilityNames.VP_TO_DEFILED_SHRINE] = "[Defiled Shrine]にvpを移動する";
JapaneseAbilityDescriptions[AbilityNames.VP_FROM_DEFILED_SHRINE] = "[Defiled Shrine]からvpを得る";
JapaneseAbilityDescriptions[AbilityNames.VP_FROM_LABYRINTH] = "[Labyrinth]から2vpを得る";
JapaneseAbilityDescriptions[AbilityNames.MOUNTAIN_PASS_BIDDING] = "+8VPを賭けて負債を入札する";
JapaneseAbilityDescriptions[AbilityNames.TOMB] = "+1vp";
JapaneseAbilityDescriptions[AbilityNames.LIGHTHOUSE_COIN] = "+$1";
JapaneseAbilityDescriptions[AbilityNames.REACTION] = "リアクションする";
JapaneseAbilityDescriptions[AbilityNames.CALL_GUIDE] = "[Guide]を呼び出して手札を入れ替える";
JapaneseAbilityDescriptions[AbilityNames.CALL_RATCATCHER] = "[Ratcatcher]を呼び出して廃棄する";
JapaneseAbilityDescriptions[AbilityNames.CALL_TRANSMOGRIFY] = "[Transmogrify]を呼び出して改良する";
JapaneseAbilityDescriptions[AbilityNames.CALL_TEACHER] = "[Teacher]を呼び出してトークンを移動する";
JapaneseAbilityDescriptions[AbilityNames.CALL_DUPLICATE] = "[Duplicate]を呼び出してArgument0をもう1枚獲得する";
JapaneseAbilityDescriptions[AbilityNames.CALL_COIN] = "[Coin Of The Realm]を呼び出して+2アクション";
JapaneseAbilityDescriptions[AbilityNames.CALL_ROYAL_CARRIAGE] = "[Royal Carriage]を呼び出してArgument0を再使用する";
JapaneseAbilityDescriptions[AbilityNames.START_OF_TURN] = "ターン開始";
JapaneseAbilityDescriptions[AbilityNames.START_BUY_PHASE] = "購入フェイズ開始";
JapaneseAbilityDescriptions[AbilityNames.END_OF_BUY_PHASE] = "購入フェイズ終了";
JapaneseAbilityDescriptions[AbilityNames.BETWEEN_TURNS] = "ターンの間";
JapaneseAbilityDescriptions[AbilityNames.END_OF_TURN] = "ターン終了";
JapaneseAbilityDescriptions[AbilityNames.SUMMON_PLAY] = "Argument0を召喚する";
JapaneseAbilityDescriptions[AbilityNames.CARAVAN_GUARD_REACTION] = "[Caravan Guard]を使用する";
JapaneseAbilityDescriptions[AbilityNames.TRAVELLING_FAIR_REACTION] = "Argument0を山札の上に置いてもよい";
JapaneseAbilityDescriptions[AbilityNames.MOAT_REACTION] = "[Moat]を公開する";
JapaneseAbilityDescriptions[AbilityNames.HORSE_TRADERS_REACTION] = "[Horse Traders]を脇に置く";
JapaneseAbilityDescriptions[AbilityNames.BEGGAR_REACTION] = "[Beggar]で[^pSilver]2枚を獲得する";
JapaneseAbilityDescriptions[AbilityNames.MARKET_SQUARE_REACTION] = "捨て札にして[^nGold]を獲得する";
JapaneseAbilityDescriptions[AbilityNames.TRADER_REACTION] = "Argument0を[^nSilver]に交換する";
JapaneseAbilityDescriptions[AbilityNames.DIPLOMAT_REACTION] = "[Diplomat]を公開する";
JapaneseAbilityDescriptions[AbilityNames.TOPDECK_REACTION] = "Argument0を山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.WATCHTOWER_TRASH_REACTION] = "Argument0を廃棄する";
JapaneseAbilityDescriptions[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "Argument0を山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.PRINCE_PLAY] = "Argument0を使用する";
JapaneseAbilityDescriptions[AbilityNames.TUNNEL_REVEAL] = "公開して[^nGold]を獲得する";
JapaneseAbilityDescriptions[AbilityNames.POSSESSION_GAIN_INSTEAD] = "Argument0がArgument1の代わりにArgument2を獲得する";
JapaneseAbilityDescriptions[AbilityNames.HOVEL_TRASH] = "[Hovel]を廃棄する";
JapaneseAbilityDescriptions[AbilityNames.DELUDED_RETURNED] = "[Deluded]を戻す";
JapaneseAbilityDescriptions[AbilityNames.ENVIOUS_RETURNED] = "[Envious]を戻す";
JapaneseAbilityDescriptions[AbilityNames.RECEIVE_BOON] = "Argument0を受ける";
JapaneseAbilityDescriptions[AbilityNames.COBBLER_ABILITY] = "コスト$4以下のカードを手札に獲得する";
JapaneseAbilityDescriptions[AbilityNames.CRYPT_ABILITY] = "Argument0のうち1枚を手札に加える";
JapaneseAbilityDescriptions[AbilityNames.DEN_OF_SIN_ABILITY] = "+2カードを引く";
JapaneseAbilityDescriptions[AbilityNames.FAITHFUL_HOUND] = "脇に置く";
JapaneseAbilityDescriptions[AbilityNames.FAITHFUL_HOUND_RETURN] = "手札に加える";
JapaneseAbilityDescriptions[AbilityNames.GHOST_TOWN_ABILITY] = "+1カードを引く、+1アクション";
JapaneseAbilityDescriptions[AbilityNames.GUARDIAN_COIN] = "+$1";
JapaneseAbilityDescriptions[AbilityNames.RAIDER_ABILITY] = "+$3";
JapaneseAbilityDescriptions[AbilityNames.SECRET_CAVE] = "+$3";
JapaneseAbilityDescriptions[AbilityNames.CEMETERY] = "手札から4枚以下を廃棄する";
JapaneseAbilityDescriptions[AbilityNames.HAUNTED_MIRROR] = "アクションカードを捨て札にして[^nGhost]を獲得する";
JapaneseAbilityDescriptions[AbilityNames.GHOST] = "Argument0を2度使用する";
JapaneseAbilityDescriptions[AbilityNames.REMOVE_FLIPPED_STATUS] = "廃棄置き場のカードを表にする";
JapaneseAbilityDescriptions[AbilityNames.LOST_IN_THE_WOODS] = "手札1枚を捨て札にして祝福を受ける";
JapaneseAbilityDescriptions[AbilityNames.BLESSED_VILLAGE] = "祝福を得る";
JapaneseAbilityDescriptions[AbilityNames.FORTRESS] = "[Fortress]を手札に加える";
JapaneseAbilityDescriptions[AbilityNames.RECEIVE_HEX] = "呪詛を受ける";
JapaneseAbilityDescriptions[AbilityNames.CHANGELING_MAY_EXCHANGE] = "Argument0を[^nChangeling]に交換する";
JapaneseAbilityDescriptions[AbilityNames.TRADE_ROUTE_MOVE_TOKEN] = "[Trade Route]マットにトークンを移動する";
JapaneseAbilityDescriptions[AbilityNames.DUCAT] = "手札の[^nCopper]1枚を廃棄する";
JapaneseAbilityDescriptions[AbilityNames.CARGO_SHIP_SET_ASIDE] = "Argument0を脇に置く";
JapaneseAbilityDescriptions[AbilityNames.CARGO_SHIP_RETURN] = "Argument0を手札に加える";
JapaneseAbilityDescriptions[AbilityNames.IMPROVE] = "場のアクションカード1枚を増築する";
JapaneseAbilityDescriptions[AbilityNames.PRIEST] = "+$2";
JapaneseAbilityDescriptions[AbilityNames.RESEARCH] = "Argument0を手札に加える";
JapaneseAbilityDescriptions[AbilityNames.SILK_MERCHANT] = "トークンを得る";
JapaneseAbilityDescriptions[AbilityNames.LACKEYS] = "+2村人";
JapaneseAbilityDescriptions[AbilityNames.SPICES] = "+2財源";
JapaneseAbilityDescriptions[AbilityNames.KEY] = "+$1";
JapaneseAbilityDescriptions[AbilityNames.TREASURE_CHEST] = "[^nGold]を獲得する";
JapaneseAbilityDescriptions[AbilityNames.FLAG_BEARER] = "[^nFlag]を得る";
JapaneseAbilityDescriptions[AbilityNames.CATHEDRAL] = "手札1枚を廃棄する";
JapaneseAbilityDescriptions[AbilityNames.CITY_GATE] = "[City Gate]で手札を入れ替える";
JapaneseAbilityDescriptions[AbilityNames.PAGEANT] = "$1払って+1財源";
JapaneseAbilityDescriptions[AbilityNames.SEWERS_MAY_TRASH] = "もう1枚廃棄する";
JapaneseAbilityDescriptions[AbilityNames.EXPLORATION] = "トークンを得る";
JapaneseAbilityDescriptions[AbilityNames.FAIR] = "+1購入";
JapaneseAbilityDescriptions[AbilityNames.SILOS] = "[Copper]を捨て札にしてカードを引く";
JapaneseAbilityDescriptions[AbilityNames.ACADEMY] = "+1村人";
JapaneseAbilityDescriptions[AbilityNames.GUILDHALL] = "+1財源";
JapaneseAbilityDescriptions[AbilityNames.PIAZZA] = "山札を公開しアクションカードの場合は使用する";
JapaneseAbilityDescriptions[AbilityNames.BARRACKS] = "+1アクション";
JapaneseAbilityDescriptions[AbilityNames.CROP_ROTATION] = "勝利点を捨て札にして+2カードを引く";
JapaneseAbilityDescriptions[AbilityNames.INNOVATION] = "Argument0を脇に置き使用する";
JapaneseAbilityDescriptions[AbilityNames.CITADEL] = "Argument0を再使用する";
JapaneseAbilityDescriptions[AbilityNames.SINISTER_PLOT] = "[Sinister Plot]をめぐらす";
JapaneseAbilityDescriptions[AbilityNames.ENCAMPMENT_RETURNED] = "[Encampment]をサプライに戻す";
JapaneseAbilityDescriptions[AbilityNames.EMBARGO_GAIN_CURSE] = "[^nCurse]を獲得する";
JapaneseAbilityDescriptions[AbilityNames.CAPTAIN] = "サプライから使用する";
JapaneseAbilityDescriptions[AbilityNames.CHURCH] = "Argument0を手札に加えた後、手札1枚を廃棄してもよい";
JapaneseAbilityDescriptions[AbilityNames.POSSESSION_SETS_ASIDE] = "undefined";
JapaneseAbilityDescriptions[AbilityNames.MINT] = "場の財宝をすべて廃棄する";
JapaneseAbilityDescriptions[AbilityNames.SLEIGH_TOPDECK_REACTION] = "[Sleigh]を捨て札にしてArgument0を山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.SLEIGH_PUT_IN_HAND_REACTION] = "[Sleigh]を捨て札にしてArgument0を手札に加える";
JapaneseAbilityDescriptions[AbilityNames.BLACK_CAT_REACTION] = "[Black Cat]を使用する";
JapaneseAbilityDescriptions[AbilityNames.DISCARD_FROM_EXILE] = "追放マットからArgument0を捨て札にする";
JapaneseAbilityDescriptions[AbilityNames.EXILE_BY_NAME] = "Argument0を追放する";
JapaneseAbilityDescriptions[AbilityNames.SHEEPDOG_REACTION] = "[Sheepdog]を使用する";
JapaneseAbilityDescriptions[AbilityNames.CAVALRY] = "+2カードを引く、+1購入、可能ならアクションフェイズに戻る";
JapaneseAbilityDescriptions[AbilityNames.HOSTELRY] = "財宝を捨て札にして[Horse]を獲得する";
JapaneseAbilityDescriptions[AbilityNames.VILLAGE_GREEN_VILLAGE] = "+1カードを引く、+2アクション";
JapaneseAbilityDescriptions[AbilityNames.VILLAGE_GREEN_PLAY] = "[Village Green]を使用する";
JapaneseAbilityDescriptions[AbilityNames.BARGE] = "+3カードを引く、+1購入";
JapaneseAbilityDescriptions[AbilityNames.FALCONER_REACTION] = "より安いカードを獲得する";
JapaneseAbilityDescriptions[AbilityNames.GATEKEEPER_EXILE] = "Argument0を追放する";
JapaneseAbilityDescriptions[AbilityNames.GATEKEEPER_DURATION] = "+$3";
JapaneseAbilityDescriptions[AbilityNames.LIVERY] = "[Horse]1枚を獲得する";
JapaneseAbilityDescriptions[AbilityNames.MASTERMIND] = "カードを3回使用してもよい";
JapaneseAbilityDescriptions[AbilityNames.DELAY_PLAY] = "Argument0を使用する";
JapaneseAbilityDescriptions[AbilityNames.INVEST] = "INVEST ABILITY (never shown to user)";
JapaneseAbilityDescriptions[AbilityNames.REAP_PLAY] = "脇に置いた[Gold]を使用する";
JapaneseAbilityDescriptions[AbilityNames.PLUS_CARD_TOKEN] = "+1カードを引く (トークン)";
JapaneseAbilityDescriptions[AbilityNames.PLUS_ACTION_TOKEN] = "+1アクション (トークン)";
JapaneseAbilityDescriptions[AbilityNames.PLUS_BUY_TOKEN] = "+1購入 (トークン)";
JapaneseAbilityDescriptions[AbilityNames.PLUS_COIN_TOKEN] = "+$1 (トークン)";
JapaneseAbilityDescriptions[AbilityNames.KILN] = "Argument0を獲得する";
JapaneseAbilityDescriptions[AbilityNames.TURTLE_PLAY] = "脇に置いたArgument0を使用する";
JapaneseAbilityDescriptions[AbilityNames.GIVE_PLUS_CARDS] = "+1カードを引く";
JapaneseAbilityDescriptions[AbilityNames.GIVE_PLUS_ACTIONS] = "+1アクション";
JapaneseAbilityDescriptions[AbilityNames.GIVE_PLUS_BUYS] = "+1購入";
JapaneseAbilityDescriptions[AbilityNames.GIVE_PLUS_COINS] = "+$1";
JapaneseAbilityDescriptions[AbilityNames.MONKEY_NEXT_TURN_ABILITY] = "+1カードを引く";
JapaneseAbilityDescriptions[AbilityNames.BLOCKADE_ABILITY] = "Argument0を手札に加える";
JapaneseAbilityDescriptions[AbilityNames.PIRATE_ABILITY] = "財宝カードを手札に獲得する";
JapaneseAbilityDescriptions[AbilityNames.PIRATE_REACTION] = "[Pirate]を使用する";
JapaneseAbilityDescriptions[AbilityNames.CORSAIR_NEXT_TURN_ABILITY] = "+1カードを引く";
JapaneseAbilityDescriptions[AbilityNames.CORSAIR_TRASH] = "Argument0を廃棄する";
JapaneseAbilityDescriptions[AbilityNames.SAILOR_NEXT_TURN] = "+$2、その後、廃棄してもよい";
JapaneseAbilityDescriptions[AbilityNames.SAILOR_REACTION] = "Argument0を使用する";
JapaneseAbilityDescriptions[AbilityNames.SKIRMISHER] = "他のプレイヤーの手札を3枚まで捨て札にさせる";
JapaneseAbilityDescriptions[AbilityNames.ENCHANTRESS_REPLACE_ABILITY] = "[Enchantress]で置換する";
JapaneseAbilityDescriptions[AbilityNames.HIGHWAYMAN_NEXT_TURN_ABILITY] = "[Highwayman]を捨て札にして、+3カードを引く。";
JapaneseAbilityDescriptions[AbilityNames.HIGHWAYMAN_REPLACE_ABILITY] = "[Highwayman]で置換する";
JapaneseAbilityDescriptions[AbilityNames.HIGHWAYMAN_NULLIFIED] = "使用を無効化する";
JapaneseAbilityDescriptions[AbilityNames.LEAGUE_OF_BANKERS] = "4好意につき+$1";
JapaneseAbilityDescriptions[AbilityNames.WOODWORKERS_GUILD] = "好意を支払ってアクションカードを廃棄し昇進させる";
JapaneseAbilityDescriptions[AbilityNames.CRAFTERS_GUILD] = "2好意を支払ってカードを獲得する";
JapaneseAbilityDescriptions[AbilityNames.CAVE_DWELLERS] = "好意を支払い、手札1枚を捨て札にしてカードを引く";
JapaneseAbilityDescriptions[AbilityNames.DESERT_GUIDES] = "1好意を支払って手札を引き直す";
JapaneseAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_CARD] = "好意を支払って+1カードを引く";
JapaneseAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_ACTION] = "好意を支払って+1アクション";
JapaneseAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_BUY] = "好意を支払って+1購入";
JapaneseAbilityDescriptions[AbilityNames.SYCOPHANT] = "+2好意";
JapaneseAbilityDescriptions[AbilityNames.IMPORTER_ABILITY] = "コスト$5までのカードを獲得する";
JapaneseAbilityDescriptions[AbilityNames.CONTRACT_ABILITY] = "Argument0を使用する";
JapaneseAbilityDescriptions[AbilityNames.MARKET_TOWNS] = "1好意を支払ってアクションカードを使用する";
JapaneseAbilityDescriptions[AbilityNames.TRAPPERS_LODGE] = "好意を支払ってArgument0を山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.GUILDMASTER] = "+1好意";
JapaneseAbilityDescriptions[AbilityNames.ISLAND_FOLK] = "5好意を支払って追加ターンを得る";
JapaneseAbilityDescriptions[AbilityNames.GANG_OF_PICKPOCKETS] = "1好意を支払うか、手札が4枚になるように捨て札にする";
JapaneseAbilityDescriptions[AbilityNames.COASTAL_HAVEN] = "カードを次のターンの手札に保留する";
JapaneseAbilityDescriptions[AbilityNames.CITY_STATE] = "2好意を支払ってArgument0を使用する";
JapaneseAbilityDescriptions[AbilityNames.CITY_STATE_PLAY] = "---";
JapaneseAbilityDescriptions[AbilityNames.CIRCLE_OF_WITCHES] = "3好意を支払って呪いを配る";
JapaneseAbilityDescriptions[AbilityNames.ROYAL_GALLEY_SET_ASIDE] = "[Royal Galley]で脇に置く";
JapaneseAbilityDescriptions[AbilityNames.ROYAL_GALLEY_PLAY] = "Argument0を使用する";
JapaneseAbilityDescriptions[AbilityNames.STRONGHOLD_ABILITY] = "+3カードを引く";
JapaneseAbilityDescriptions[AbilityNames.MOUNTAIN_FOLK] = "5好意を支払って+3カードを引く";
JapaneseAbilityDescriptions[AbilityNames.PEACEFUL_CULT] = "好意を支払って廃棄する";
JapaneseAbilityDescriptions[AbilityNames.FELLOWSHIP_OF_SCRIBES] = "1好意を支払って+1カードを引く";
JapaneseAbilityDescriptions[AbilityNames.LEAGUE_OF_SHOPKEEPERS] = "[League of Shopkeepers]";
JapaneseAbilityDescriptions[AbilityNames.TERRITORY_GAIN] = "サプライの空の山の数だけ[Gold]を獲得する";
JapaneseAbilityDescriptions[AbilityNames.WARLORD_ABILITY] = "+2カードを引く";
JapaneseAbilityDescriptions[AbilityNames.GARRISON_ADD_TOKEN] = "トークンを追加する";
JapaneseAbilityDescriptions[AbilityNames.GARRISON_REMOVE_ABILITY] = "+Argument0カードを引く";
JapaneseAbilityDescriptions[AbilityNames.CONJURER] = "[Conjurer]を手札に加える";
JapaneseAbilityDescriptions[AbilityNames.LICH] = "[Lich]を廃棄置き場から捨て札にして、廃棄置き場からカードを獲得する";
JapaneseAbilityDescriptions[AbilityNames.GALLERIA] = "+1購入";
JapaneseAbilityDescriptions[AbilityNames.ARCHITECTS_GUILD] = "より安く勝利点でないカードを獲得する";
JapaneseAbilityDescriptions[AbilityNames.FAMILY_OF_INVENTORS] = "コストを安くする山を選ぶ";
JapaneseAbilityDescriptions[AbilityNames.FOREST_DWELLERS] = "山札の上3枚を偵察する";
JapaneseAbilityDescriptions[AbilityNames.ASTROLABE_ABILITY] = "+$1、+1購入";
JapaneseAbilityDescriptions[AbilityNames.TIDE_POOLS_ABILITY] = "手札2枚を捨て札にする";
JapaneseAbilityDescriptions[AbilityNames.SEA_WITCH_ABILITY] = "+2カードを引く、手札2枚を捨て札にする";
JapaneseAbilityDescriptions[AbilityNames.CLERK_ABILITY] = "[Clerk]を使用する";
JapaneseAbilityDescriptions[AbilityNames.GUARD_DOG_REACTION] = "[Guard Dog]を使用する";
JapaneseAbilityDescriptions[AbilityNames.BERSERKER] = "[Berserker]を使用する";
JapaneseAbilityDescriptions[AbilityNames.NOMADS] = "+$2";
JapaneseAbilityDescriptions[AbilityNames.TRAIL_REACTION] = "[Trail]を使用する";
JapaneseAbilityDescriptions[AbilityNames.WEAVER_PLAY] = "[Weaver]を使用する";
JapaneseAbilityDescriptions[AbilityNames.SOUK] = "手札2枚以下を廃棄する";
JapaneseAbilityDescriptions[AbilityNames.CAULDRON_CURSING] = "他のプレイヤーは呪いを獲得する";
JapaneseAbilityDescriptions[AbilityNames.TREASURY] = "[Treasury]を山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.MERCHANT_GUILD] = "獲得したカードの枚数分財源を得る";
JapaneseAbilityDescriptions[AbilityNames.ALCHEMIST_TOPDECK] = "[Alchemist]を山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.HERBALIST_TOPDECK] = "[Herbalist]でArgument0を山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.SCHEME_TOPDECK] = "[Scheme]でArgument0を山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.FROG_TOPDECK] = "Argument0を山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.MERCHANT_CAMP_TOPDECK] = "[Merchant Camp]を山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.WALLED_VILLAGE_TOPDECK] = "[Walled Village]を山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.TENT_TOPDECK] = "[Tent]を山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.HORN_TOPDECK] = "[Horn]で[Border Guard]を山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.PAGE_EXCHANGE] = "[Page]を[Treasure Hunter]に交換する";
JapaneseAbilityDescriptions[AbilityNames.TREASURE_HUNTER_EXCHANGE] = "[Treasure Hunter]を[Warrior]に交換する";
JapaneseAbilityDescriptions[AbilityNames.WARRIOR_EXCHANGE] = "[Warrior]を[Hero]に交換する";
JapaneseAbilityDescriptions[AbilityNames.HERO_EXCHANGE] = "[Hero]を[Champion]に交換する";
JapaneseAbilityDescriptions[AbilityNames.PEASANT_EXCHANGE] = "[Peasant]を[Soldier]に交換する";
JapaneseAbilityDescriptions[AbilityNames.SOLDIER_EXCHANGE] = "[Soldier]を[Fugitive]に交換する";
JapaneseAbilityDescriptions[AbilityNames.FUGITIVE_EXCHANGE] = "[Fugitive]を[Disciple]に交換する";
JapaneseAbilityDescriptions[AbilityNames.DISCIPLE_EXCHANGE] = "[Disciple]を[Teacher]に交換する";
JapaneseAbilityDescriptions[AbilityNames.CAGE_TRASH] = "[Cage]を廃棄する";
JapaneseAbilityDescriptions[AbilityNames.CAGE_PUT_IN_HAND] = "Argument0を手札に加える";
JapaneseAbilityDescriptions[AbilityNames.GROTTO] = "Argument0を捨て札にし、カードを引く";
JapaneseAbilityDescriptions[AbilityNames.JEWELLED_EGG_GAIN_LOOT] = "戦利品を獲得する";
JapaneseAbilityDescriptions[AbilityNames.SEARCH] = "[Search]を廃棄し、戦利品を獲得する";
JapaneseAbilityDescriptions[AbilityNames.SHAMAN] = "コスト$6以下のカードを廃棄置き場から獲得する";
JapaneseAbilityDescriptions[AbilityNames.SECLUDED_SHRINE] = "手札2枚以下を廃棄する";
JapaneseAbilityDescriptions[AbilityNames.SIREN_GAIN] = "アクションカード1枚を廃棄するか、[Siren]を廃棄する";
JapaneseAbilityDescriptions[AbilityNames.SIREN_DURATION] = "手札が8枚になるまでカードを引く";
JapaneseAbilityDescriptions[AbilityNames.STOWAWAY_DRAW] = "+2カードを引く";
JapaneseAbilityDescriptions[AbilityNames.STOWAWAY_REACTION] = "[Stowaway]を使用する";
JapaneseAbilityDescriptions[AbilityNames.TASKMASTER] = "+1アクション、+$1";
JapaneseAbilityDescriptions[AbilityNames.ABUNDANCE] = "+1購入、+$3";
JapaneseAbilityDescriptions[AbilityNames.CABIN_BOY] = "+$2、または[Cabin Boy]を廃棄する";
JapaneseAbilityDescriptions[AbilityNames.FLAGSHIP_ACTIVATE] = "";
JapaneseAbilityDescriptions[AbilityNames.FLAGSHIP_REPLAY] = "Argument0を再使用する";
JapaneseAbilityDescriptions[AbilityNames.GONDOLA_DURATION] = "+$2";
JapaneseAbilityDescriptions[AbilityNames.GONDOLA_PLAY] = "アクションカード1枚を使用する";
JapaneseAbilityDescriptions[AbilityNames.HARBOR_VILLAGE] = "+$1";
JapaneseAbilityDescriptions[AbilityNames.LANDING_PARTY] = "[Landing Party]を山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.MAPMAKER] = "[Mapmaker]を使用する";
JapaneseAbilityDescriptions[AbilityNames.ROPE] = "+1カードを引く、手札1枚を廃棄してもよい";
JapaneseAbilityDescriptions[AbilityNames.BURIED_TREASURE_DURATION] = "+1購入、+$3";
JapaneseAbilityDescriptions[AbilityNames.BURIED_TREASURE_PLAY] = "[Buried Treasure]を使用する";
JapaneseAbilityDescriptions[AbilityNames.CREW_DURATION] = "[Crew]を山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.CUTTHROAT_GAIN] = "戦利品を獲得する";
JapaneseAbilityDescriptions[AbilityNames.ENLARGE] = "手札1枚を廃棄し、それよりコストが最大$2高いカードを獲得する";
JapaneseAbilityDescriptions[AbilityNames.FRIGATE_DURATION] = "";
JapaneseAbilityDescriptions[AbilityNames.FRIGATE_DISCARD] = "手札が4枚になるまで捨て札にする";
JapaneseAbilityDescriptions[AbilityNames.LONGSHIP_DURATION] = "+2カードを引く";
JapaneseAbilityDescriptions[AbilityNames.MINING_ROAD] = "Argument0を使用する";
JapaneseAbilityDescriptions[AbilityNames.QUARTERMASTER] = "[Quartermaster]を解決する";
JapaneseAbilityDescriptions[AbilityNames.TRICKSTER_REACTION] = "財宝1枚を脇に置く";
JapaneseAbilityDescriptions[AbilityNames.TRICKSTER_RETURN_TO_HAND] = "Argument0を手札に加える";
JapaneseAbilityDescriptions[AbilityNames.WEALTHY_VILLAGE] = "戦利品を獲得する";
JapaneseAbilityDescriptions[AbilityNames.DELIVER_SET_ASIDE] = "Argument0を脇に置く";
JapaneseAbilityDescriptions[AbilityNames.DELIVER_RETURN] = "Argument0を手札に加える";
JapaneseAbilityDescriptions[AbilityNames.RUSH] = "Argument0を使用する";
JapaneseAbilityDescriptions[AbilityNames.MIRROR] = "追加のArgument0を獲得する";
JapaneseAbilityDescriptions[AbilityNames.PREPARE] = "脇に置いたカードを使用する";
JapaneseAbilityDescriptions[AbilityNames.AMPHORA] = "+1購入、+$3";
JapaneseAbilityDescriptions[AbilityNames.DOUBLOONS] = "[Gold]を獲得する";
JapaneseAbilityDescriptions[AbilityNames.ENDLESS_CHALICE] = "+$1、+1購入";
JapaneseAbilityDescriptions[AbilityNames.FIGUREHEAD] = "+2カードを引く";
JapaneseAbilityDescriptions[AbilityNames.JEWELS] = "[Jewels]を山札の一番下に置く";
JapaneseAbilityDescriptions[AbilityNames.PUZZLE_BOX_RETURN] = "Argument0を手札に加える";
JapaneseAbilityDescriptions[AbilityNames.SHIELD_REACTION] = "[Shield]を公開する";
JapaneseAbilityDescriptions[AbilityNames.SPELL_SCROLL] = "Argument0を使用する";
JapaneseAbilityDescriptions[AbilityNames.CURSED_GAIN] = "戦利品と呪いを獲得する";
JapaneseAbilityDescriptions[AbilityNames.FAWNING_GAIN] = "[Fawning]カードを獲得する";
JapaneseAbilityDescriptions[AbilityNames.SCHEDULER] = "[Friendly] (表示されている場合はバグです！)";
JapaneseAbilityDescriptions[AbilityNames.FRIENDLY_ABILITY] = "[Friendly]カードを捨て札にし、1枚獲得する";
JapaneseAbilityDescriptions[AbilityNames.HASTY_SET_ASIDE] = "Argument0を脇に置く";
JapaneseAbilityDescriptions[AbilityNames.HASTY_PLAY] = "Argument0を使用する";
JapaneseAbilityDescriptions[AbilityNames.INHERITED] = "ゲーム開始時の自分のカード1枚と入れ替える";
JapaneseAbilityDescriptions[AbilityNames.INSPIRING] = "場に出していないカード1枚を使用する";
JapaneseAbilityDescriptions[AbilityNames.NEARBY] = "+1購入";
JapaneseAbilityDescriptions[AbilityNames.PATIENT_SCHEDULER] = "[Patient] (表示されている場合はバグです！)";
JapaneseAbilityDescriptions[AbilityNames.PATIENT_SET_ASIDE] = "[Patient]カードを脇に置く";
JapaneseAbilityDescriptions[AbilityNames.PATIENT_PLAY] = "Argument0を使用する";
JapaneseAbilityDescriptions[AbilityNames.PIOUS] = "廃棄する";
JapaneseAbilityDescriptions[AbilityNames.RECKLESS_REPEAT] = "Argument0の指示に再度従う";
JapaneseAbilityDescriptions[AbilityNames.RECKLESS_RETURN] = "Argument0を山に戻す";
JapaneseAbilityDescriptions[AbilityNames.RICH_GAIN] = "[^nSilver]を獲得する";
JapaneseAbilityDescriptions[AbilityNames.SHY] = "+2カードを引くために[Shy]カードを捨て札にする";
JapaneseAbilityDescriptions[AbilityNames.TIRELESS_SET_ASIDE] = "Argument0を脇に置く";
JapaneseAbilityDescriptions[AbilityNames.TIRELESS_RETURN] = "Argument0を山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.STAR_CHART] = "シャッフルの一番上に積み込む";
JapaneseAbilityDescriptions[AbilityNames.ORDER_OF_ASTROLOGERS] = "シャッフルの一番上に積み込む";
JapaneseAbilityDescriptions[AbilityNames.ORDER_OF_MASONS] = "捨て札に置く";
JapaneseAbilityDescriptions[AbilityNames.FATED] = "シャッフルの一番上か一番下に積み込む";
JapaneseAbilityDescriptions[AbilityNames.AVOID] = "捨て札に置く";
JapaneseAbilityDescriptions[AbilityNames.HERBALIST_ASK_FOR_DISCARD] = "財宝カードを山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.SCHEME_ASK_FOR_DISCARD] = "アクションカードを山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.TRICKSTER_ASK_FOR_DISCARD] = "財宝カードを脇に置く";
JapaneseAbilityDescriptions[AbilityNames.MARCHLAND] = "+1購入、手札を好きな枚数捨て札にし、1枚につき+$1";
JapaneseAbilityDescriptions[AbilityNames.FARMHANDS_SET_ASIDE] = "アクションか財宝1枚を脇に置く";
JapaneseAbilityDescriptions[AbilityNames.FARMHANDS_PLAY] = "Argument0を使用する";
JapaneseAbilityDescriptions[AbilityNames.JOUST_DISCARD] = "属州1枚を捨て札にする";
JapaneseAbilityDescriptions[AbilityNames.INFIRMARY_OVERPAY] = "[Infirmary]を使用するために過払いする";
JapaneseAbilityDescriptions[AbilityNames.RESOLVE_INFIRMARY_OVERPAY] = "[Infirmary]を使用する";
JapaneseAbilityDescriptions[AbilityNames.DRAW_FROM_FOOTPAD] = "カードを引く";
JapaneseAbilityDescriptions[AbilityNames.FARRIER_OVERPAY] = "ターン終了時に追加でカードを引くために過払いする";
JapaneseAbilityDescriptions[AbilityNames.RESOLVE_FARRIER_OVERPAY] = "[Farrier]の過払いを解決する";
JapaneseAbilityDescriptions[AbilityNames.DRAW_FROM_SQUIRREL] = "追加でカード2枚を引く";
JapaneseAbilityDescriptions[AbilityNames.DRAW_FROM_RIVER] = "追加でカード1枚を引く";
JapaneseAbilityDescriptions[AbilityNames.DRAW_FROM_FARRIER] = "追加でカードArgument0枚を引く";
JapaneseAbilityDescriptions[AbilityNames.FERRYMAN] = "Argument0を獲得する";

JapaneseAbilityDescriptions[AbilityNames.BIDING_TIME_SET_ASIDE_HAND] = "手札のカードを脇に置く";
JapaneseAbilityDescriptions[AbilityNames.BIDING_TIME_RETURN_TO_HAND] = "カードを手札に加える";
JapaneseAbilityDescriptions[AbilityNames.ENLIGHTENMENT_CANTRIP] = "+1カードを引く、+1アクション";
JapaneseAbilityDescriptions[AbilityNames.ENLIGHTENMENT_REPLACE_ABILITY] = "[Enlightenment]による効果上書き";
JapaneseAbilityDescriptions[AbilityNames.GOOD_HARVEST] = "+1購入、+$1";
JapaneseAbilityDescriptions[AbilityNames.HARSH_WINTER] = "負債を得るか、山に負債を置く";
JapaneseAbilityDescriptions[AbilityNames.KIND_EMPEROR] = "アクションカードを手札に獲得する";
JapaneseAbilityDescriptions[AbilityNames.PANIC_BUYS] = "+2購入";
JapaneseAbilityDescriptions[AbilityNames.PANIC_RETURN] = "Argument0を山に戻す";
JapaneseAbilityDescriptions[AbilityNames.PROGRESS] = "Argument0を山札の上に置く";
JapaneseAbilityDescriptions[AbilityNames.RAPID_EXPANSION_SET_ASIDE] = "Argument0を脇に置く";
JapaneseAbilityDescriptions[AbilityNames.RAPID_EXPANSION_PLAY_LATER] = "Argument0を使用する";
JapaneseAbilityDescriptions[AbilityNames.SICKNESS] = "山札の上に呪いを獲得する、または3枚捨て札にする";
JapaneseAbilityDescriptions[AbilityNames.RIVER_SHRINE] = "$4以下のカードを獲得する";
JapaneseAbilityDescriptions[AbilityNames.SAMURAI] = "+$1";
JapaneseAbilityDescriptions[AbilityNames.DAIMYO_REPLAY] = "Argument0を再使用する";
JapaneseAbilityDescriptions[AbilityNames.FORESIGHT_RETURN_TO_HAND] = JapaneseAbilityDescriptions[AbilityNames.SAVE_RETURN_TO_HAND];