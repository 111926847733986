"use strict";

webclient.component("specListLine", {
    bindings: {
        pid: '<',
        being: '<'
    },
    controller: ["meta", "tableService", "serverMessenger", "relations", function (meta, table, serverMessenger, relations) {
        var self = this;
        if (self.being) {
            self.name = self.being.name;
        } else {
            self.name = table.getSpectatorNameById(self.pid);
            self.being = { id: self.pid, name: self.name };
        }

        self.isOtherPerson = meta.getMyId() != self.pid && !table.isBot(self.pid);

        if (table.isPlayer(self.being.id) || table.isBot(self.being.id)) {
            var playerIndex = table.getBeings().map(function (p) {
                return p.id;
            }).indexOf(self.being.id);
            self.color = Object.values(PlayerColors)[playerIndex].color;
        } else {
            self.color = "white";
        }

        self.isBlacklisted = function () {
            return relations.isBlacklisted(self.pid);
        };
        self.isFollowing = function () {
            return relations.isFollowing(self.pid);
        };

        self.blacklist = function () {
            var status = self.isBlacklisted();
            timeLog('blacklist ' + !status);
            if (status) {
                relations.removeFromBlacklist(self.name);
            } else {
                relations.addNamedIdToBlacklist(self.being);
            }
            serverMessenger.changeBlacklistStatus(self.being, !status);
        };

        self.friend = function () {
            var status = self.isFollowing();
            timeLog('friend ' + !status);
            if (status) {
                relations.unFollow(self.name);
            } else {
                relations.followNamedId(self.being);
            }
            serverMessenger.changeFriendStatus(self.being, !status);
        };

        self.friendText = function () {
            return getPhrase(self.isFollowing() ? Phrases.UNFRIEND : Phrases.BEFRIEND_USER);
        };
        self.blacklistText = function () {
            return getPhrase(self.isBlacklisted() ? Phrases.UNBLACKLIST : Phrases.BLACKLIST_USER);
        };
    }],
    template: "<div class=\"spec-list-line\">\n            <div class=\"spec-list-blacklist\"\n                 ng-if=\"$ctrl.isOtherPerson\"\n                 ng-click=\"$ctrl.blacklist()\"\n                 ng-class=\"{active: $ctrl.isBlacklisted()}\">\n                <div class=\"spec-indicator-tooltip\"> {{$ctrl.blacklistText()}} </div>\n            </div>\n            <div class=\"spec-list-friend\"\n                 ng-if=\"$ctrl.isOtherPerson\"\n                 ng-click=\"$ctrl.friend()\"\n                 ng-class=\"{active: $ctrl.isFollowing()}\">\n                <div class=\"spec-indicator-tooltip\"> {{$ctrl.friendText()}} </div>\n            </div>\n            <div class=\"spec-list-name unselectable\" style=\"color: {{$ctrl.color}};\">{{$ctrl.name}}</div>\n        </div>"
});