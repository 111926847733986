"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function colorTextByCardname(s, cardName) {
    return new ClickableColoredText(s, cardName.getCardColor(), cardName);
}

var ClickableColoredText = function ClickableColoredText(text) {
    var color = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var studyCard = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    var lineBreak = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

    _classCallCheck(this, ClickableColoredText);

    this.text = text;
    this.color = color;
    this.studyCard = studyCard;
    this.lineBreak = lineBreak;
    this.style = this.color ? { color: this.color } : {};
    if (this.studyCard !== null) this.style.cursor = "help";
};

var LogLineParser = function () {
    function LogLineParser(gamestate, players, shouldShowFullName) {
        _classCallCheck(this, LogLineParser);

        this.gamestate = gamestate;
        this.players = players;
        this.shouldShowFullName = shouldShowFullName;
    }

    _createClass(LogLineParser, [{
        key: "unusedParser",
        value: function unusedParser() {
            return [new ClickableColoredText("This shouldn't be here.")];
        }
    }, {
        key: "getCardFrequencyString",
        value: function getCardFrequencyString(cardFrequency, isMixed) {
            var cardName = cardFrequency.cardName;
            var translation = LANGUAGE.getCardName[cardName];
            var frequency = cardFrequency.frequency;
            if (frequency === 1) {
                var string = translation.noun;
                return colorTextByCardname(string, cardName);
            } else {
                var prefix = isMixed ? LANGUAGE.getPhrase[Phrases.OTHER] + " " : "";
                var t = shouldUseRussianMany(frequency) ? translation.many : translation.plural;
                return colorTextByCardname(frequency + " " + prefix + t, cardName);
            }
        }
    }, {
        key: "cardNameParser",
        value: function cardNameParser(cardFrequencies) {
            var environmentParser = LANGUAGE.getEnvironment["cardNameParser"];
            if (environmentParser) return environmentParser(cardFrequencies);

            var size = cardFrequencies.length;
            if (size === 0) return [new ClickableColoredText(getPhrase(Phrases.NOTHING))];
            var isMixed = hasMixedVisibility(cardFrequencies.map(function (c) {
                return c.cardName;
            }));
            var output = [];
            for (var i = 0; i < size; i++) {
                if (i === size - 1 && i > 0) {
                    var andPrefix = size > 2 ? "" : " ";
                    output.push(new ClickableColoredText(andPrefix + LANGUAGE.getPhrase[Phrases.AND] + " "));
                }
                output.push(this.getCardFrequencyString(cardFrequencies[i], isMixed));
                if (i < size - 1 && size > 2) output.push(new ClickableColoredText(LANGUAGE.getPhrase[Phrases.COMMA]));
            }
            return output;
        }
    }, {
        key: "playerParser",
        value: function playerParser(playerIndex) {
            var forceFullNames = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            var player = this.players[playerIndex];
            var name = this.shouldShowFullName || forceFullNames ? player.name : player.initials;
            return [new ClickableColoredText(name, player.color)];
        }
    }, {
        key: "zoneParser",
        value: function zoneParser(zoneIndex) {
            var zone = this.gamestate.zones[zoneIndex];
            if (zone instanceof SingleLandscapeZone) {
                var cardName = zone.cardStacks[0].topCard.cardName;
                var pileString = LANGUAGE.getCardName[cardName].singular;
                return [colorTextByCardname(pileString, cardName)];
            } else if (zone instanceof SupplyZone) {
                return [LANGUAGE.getEnvironment.getPileName(zone)];
            }
            return [new ClickableColoredText(LANGUAGE.getZoneName[zone.zoneName])];
        }
    }, {
        key: "numberParser",
        value: function numberParser(number) {
            return [new ClickableColoredText(number)];
        }
    }, {
        key: "turnParser",
        value: function turnParser(turnDescription) {
            var playerName = this.players[turnDescription.ownerId].name;
            var turnType = getByOrdinal(ExtraTurnTypes, turnDescription.turnType);
            if (turnType === ExtraTurnTypes.BETWEEN) {
                return [new ClickableColoredText(getPhrase(Phrases.BETWEEN_TURNS))];
            } else {
                var turnString = turnDescription.turnType > 1 ? " [" + LANGUAGE.getCardName[turnType.cardName].singular + "]" : "";
                return [new ClickableColoredText(getPhrase(Phrases.TURN) + " " + turnDescription.turnNumber + " - " + playerName + turnString)];
            }
        }
    }, {
        key: "amountParser",
        value: function amountParser(amount) {
            var environmentParser = LANGUAGE.getEnvironment["amountParser"];
            if (environmentParser) return environmentParser(amount);

            var output = [];
            if (amount.coin > 0 || amount.potion == 0 && amount.debt == 0) {
                var suffix = void 0;
                if (amount.coin === 1) {
                    suffix = getPhrase(Phrases.COIN);
                } else {
                    suffix = getPhrase(Phrases.COINS);
                }
                output.push(new ClickableColoredText(amount.coin + " " + suffix));
            }
            if (amount.potion > 0) {
                var prefix = output.length > 0 ? amount.debt > 0 ? " " + LANGUAGE.getPhrase[Phrases.AND] + " " : LANGUAGE.getPhrase[Phrases.COMMA] : "";
                var _suffix = void 0;
                if (amount.potion === 1) {
                    _suffix = getPhrase(Phrases.POTION);
                } else {
                    _suffix = getPhrase(Phrases.POTIONS);
                }
                output.push(new ClickableColoredText("" + prefix + amount.potion + " " + _suffix));
            }
            if (amount.debt > 0) {
                var _prefix = output.length > 0 ? " " + LANGUAGE.getPhrase[Phrases.AND] + " " : "";
                output.push(new ClickableColoredText("" + _prefix + amount.debt + " " + LANGUAGE.getPhrase[Phrases.DEBT]));
            }
            return output;
        }
    }, {
        key: "abilityParser",
        value: function abilityParser(ability) {
            var output = [];
            if (ability.name instanceof AbilityName) {

                var rawText = LANGUAGE.getAbilityDescription[ability.name];

                if (isUndefined(rawText)) {
                    console.log("Missing translation for %o in language %o", ability.name, LANGUAGE.getOrdinal());
                    console.log("Map is %o", LANGUAGE.getAbilityDescription);
                }
                return this.parseLineWithArguments(rawText, ability.logEntryArguments);
            } else {
                try {
                    var id = ability.association;
                    var text = void 0;
                    if (id > -1) {
                        text = LANGUAGE.getPhrase[Phrases.MOVEMENT_VERB_OBJECT];
                        text.replace(/OBJECT/, LANGUAGE.getCardName[state.cards[id].cardName].singular);
                    } else {
                        text = LANGUAGE.getPhrase[Phrases.MOVEMENT_VERB];
                    }
                    text = text.replace(/VERB/, LANGUAGE.getMovementType[a.name].secondPerson);
                    return [new ClickableColoredText(text)];
                } catch (e) {
                    console.log("ERROR PARSING ABILITY... %o", e);
                    console.log("a = %o", ability);
                }
            }
        }
    }, {
        key: "abilityEventParser",
        value: function abilityEventParser(abilityEvent) {
            var environmentParser = LANGUAGE.getEnvironment["abilityEventParser"];
            if (environmentParser) return environmentParser(abilityEvent);

            var output = [];
            var playerIndex = abilityEvent.logEntryArguments[0].argument;
            if (!this.players[playerIndex].isMe) {
                output.push.apply(output, _toConsumableArray(this.playerParser(playerIndex)));
            } else {
                output.push(new ClickableColoredText(LANGUAGE.getPhrase[Phrases.YOU]));
            }
            if (abilityEvent.type === EventTypes.WHEN_WOULD) {
                output.push(new ClickableColoredText(" " + LANGUAGE.getPhrase[Phrases.WOULD]));
            }

            var abilityName = abilityEvent.description.name;
            if (abilityName instanceof MovementType) {
                output.push(new ClickableColoredText(" " + LANGUAGE.getMovementType[abilityEvent.description.name].secondPerson));
            } else if (abilityName instanceof AbilityName) {
                output.push(new ClickableColoredText(" " + LANGUAGE.getAbilityDescription[abilityEvent.description.name]));
            }

            var cardBlocks = [];
            if (abilityEvent.logEntryArguments.length > 1) {
                cardBlocks = this.cardNameParser(abilityEvent.logEntryArguments[1].argument, state, line);
                cardBlocks[0].text = " " + cardBlocks[0].text;
            }
            output.push.apply(output, _toConsumableArray(cardBlocks));

            output.push(new ClickableColoredText(" " + LANGUAGE.getPhrase[Phrases.AND].toLowerCase() + "..."));
            return output;
        }
    }, {
        key: "cardParser",
        value: function cardParser(argument) {
            var cardName = this.gamestate.cards[argument].cardName;
            return this.cardNameParser([new CardFrequency(cardName, 1)]);
        }
    }, {
        key: "singularCardParser",
        value: function singularCardParser(argument) {
            var s = LANGUAGE.getCardName[argument].singular;
            return [colorTextByCardname(s, argument)];
        }
    }, {
        key: "tokenParser",
        value: function tokenParser(argument) {
            var token = this.gamestate.tokens[argument];
            return [new ClickableColoredText(LANGUAGE.getTokenName[token.tokenName])];
        }
    }, {
        key: "playersParser",
        value: function playersParser(argument) {
            var output = [];
            var size = argument.length;
            for (var i = 0; i < size; i++) {
                if (i === size - 1 && i > 0) {
                    var andPrefix = size > 2 ? "" : " ";
                    output.push(new ClickableColoredText(andPrefix + LANGUAGE.getPhrase[Phrases.AND] + " "));
                }
                output.push.apply(output, _toConsumableArray(this.playerParser(argument[i])));
                if (i < size - 1 && size > 2) output.push(new ClickableColoredText(LANGUAGE.getPhrase[Phrases.COMMA]));
            }
            return output;
        }
    }, {
        key: "translatedListOfCardnames",
        value: function translatedListOfCardnames(cards) {
            var output = [];
            cards.forEach(function (c, i) {
                if (i === cards.length - 1 && i > 0) {
                    var andPrefix = cards.length > 2 ? "" : " ";
                    output.push(new ClickableColoredText(andPrefix + LANGUAGE.getPhrase[Phrases.AND] + " "));
                }
                output.push(colorTextByCardname(LANGUAGE.getCardName[c].singular, c));
                if (i < cards.length - 1 && cards.length > 2) output.push(new ClickableColoredText(LANGUAGE.getPhrase[Phrases.COMMA]));
            });
            output[output.length - 1].lineBreak = true;
            return output;
        }
    }, {
        key: "directionalZoneParser",
        value: function directionalZoneParser(directionalZone) {
            var zoneIndex = directionalZone.index;
            var zone = this.gamestate.zones[zoneIndex];
            if (zone instanceof SingleLandscapeZone) {
                var cardName = zone.cardStacks[0].topCard.cardName;
                var pileString = LANGUAGE.getCardName[cardName].singular;
                return [colorTextByCardname(pileString, cardName)];
            }
            if (zone instanceof SupplyZone) {
                return [LANGUAGE.getEnvironment.getPileName(this.gamestate.zones[zoneIndex], directionalZone.direction)];
            }
            return [new ClickableColoredText(LANGUAGE.getZoneName[zone.zoneName])];
        }
    }, {
        key: "metaGameInfoParser",
        value: function metaGameInfoParser(metaGameInfo) {
            var _this = this;

            var output = [];
            var ratedPhrase = metaGameInfo.isRated ? getPhrase(Phrases.RATED) : getPhrase(Phrases.UNRATED);
            output.push(new ClickableColoredText(getPhrase(Phrases.GAME) + " #" + metaGameInfo.gameId + ", " + ratedPhrase + ".", null, null, true));
            if (metaGameInfo.isRated) {
                var playerIdsIterator = metaGameInfo.levelMap.keys();

                var _loop = function _loop(i) {
                    var id = playerIdsIterator.next().value;
                    output.push.apply(output, _toConsumableArray(_this.playerParser(_this.players.findIndex(function (player) {
                        return player.playerId === id;
                    }), true)));
                    var level = Math.round(100 * metaGameInfo.levelMap.get(id)) / 100;
                    output.push(new ClickableColoredText(": " + level, null, null, true));
                };

                for (var i = 0; i < metaGameInfo.levelMap.size; i++) {
                    _loop(i);
                }
            }
            output.push(new ClickableColoredText("\n" + getPhrase(Phrases.USED_CARD_POOL_LEVEL) + metaGameInfo.cardPoolLevel, null, null, true));
            if (metaGameInfo.changedLikelyhoodCards.length > 0) {
                output.push(new ClickableColoredText("" + getPhrase(Phrases.KINGDOM_GENERATOR_PERCENTAGES), null, null, true));
                metaGameInfo.changedLikelyhoodCards.forEach(function (changedLikelyhood) {
                    output.push(new ClickableColoredText("   " + changedLikelyhood.likelyhood + "%: "));
                    output.push.apply(output, _toConsumableArray(_this.translatedListOfCardnames(changedLikelyhood.cards)));
                });
            }
            return output;
        }
    }, {
        key: "parseLineArgument",
        value: function parseLineArgument(data) {
            var dataParsers = [this.cardNameParser, this.playerParser, this.zoneParser, this.unusedParser, this.numberParser, this.unusedParser, this.turnParser, this.amountParser, this.abilityParser, this.abilityEventParser, this.cardParser, this.singularCardParser, this.tokenParser, this.directionalZoneParser, this.playersParser, this.metaGameInfoParser];
            if (isUndefined(data)) return [];
            var parser = dataParsers[data.type].bind(this);
            return parser(data.argument);
        }
    }, {
        key: "parseLineWithArguments",
        value: function parseLineWithArguments(rawText, logArguments) {
            var _this2 = this;

            var textStrings = rawText.split(/(?:Argument|NUMBER|PLAYER|CARDS|AMOUNT)\d+/g);
            var argRegex = /(?:Argument|NUMBER|PLAYER|CARDS|AMOUNT)(\d+)/g;
            var argumentMatches = [];
            var match = void 0;
            while (match = argRegex.exec(rawText)) {
                argumentMatches.push(parseInt(match[1]));
            }
            var argumentData = argumentMatches.map(function (l) {
                return logArguments[l];
            });
            var blocks = [];
            textStrings.forEach(function (s, i) {
                var plainTexts = s.split(/\[.*?\]/g);
                var blockRegex = /\[(.*?)\]/g;
                var cardBlocks = [];
                while (match = blockRegex.exec(s)) {
                    cardBlocks.push(match);
                }
                var cardBlockCardNames = cardBlocks.map(function (s) {
                    var matchString = s[1];
                    var mode = "singular";
                    if (s[1][0] === "^") {
                        matchString = s[1].slice(2);
                        if (s[1][1] === "p") mode = "plural";else mode = "noun";
                    }
                    var cardName = Object.values(CardNames).find(function (cardName) {
                        return cardName.name === matchString;
                    });
                    return [LANGUAGE.getCardName[cardName][mode], cardName];
                });

                plainTexts.forEach(function (t, j) {
                    blocks.push(new ClickableColoredText(t));
                    if (j < cardBlocks.length) {
                        blocks.push(colorTextByCardname.apply(undefined, _toConsumableArray(cardBlockCardNames[j])));
                    }
                });
                if (i < argumentData.length) blocks.push.apply(blocks, _toConsumableArray(_this2.parseLineArgument(argumentData[i])));
            });

            // if (blocks.length > 1 && blocks[blocks.length - 1].text.length === 1) {
            //     blocks[blocks.length - 2].text += blocks[blocks.length - 1].text;
            //     blocks = blocks.slice(0, blocks.length - 1);
            // }
            return blocks;
        }
    }, {
        key: "parseStory",
        value: function parseStory(story) {
            // this is terrible, sad.
            if (story instanceof Array) {
                return story;
            } else if (story instanceof Ability) {
                return this.parseLineWithArguments(LANGUAGE.getAbilityDescription[story.name], story.logEntryArguments);
            } else if (story.hasContext()) {
                return this.parseLineArgument(story.context);
            } else {
                return this.parseLineWithArguments(LANGUAGE.getStory[story.questionId][1], story.arguments);
            }
        }
    }]);

    return LogLineParser;
}();

webclient.component("logLine", {
    bindings: {
        logEntry: "<",
        showUndos: "<",
        isPremoveLine: "<"
    },
    controller: ["game", "metaBroadcaster", "premoves", function (game, metaBroadcaster, premoves) {
        var self = this;

        var parser = new LogLineParser(game.state, game.state.players, false);
        var blocks = [];

        if (self.logEntry instanceof LogEntry) blocks = parser.parseLineWithArguments(LANGUAGE.getLogEntry[self.logEntry.name], self.logEntry.logArguments);
        self.lines = [];
        var currentLine = [];
        blocks.forEach(function (block) {
            currentLine.push(block);
            if (block.lineBreak) {
                self.lines.push(currentLine);
                currentLine = [];
            }
        });
        if (currentLine.length > 0) self.lines.push(currentLine);

        var normalizer = function normalizer(x) {
            return x / (x + 10) * 50;
        };
        self.paddingLeft = normalizer(self.logEntry.depth);

        self.study = function (block) {
            if (block.studyCard !== null) metaBroadcaster.send(Events.CARD_STUDY_REQUEST, block.studyCard);
        };

        self.shouldShowUndo = function () {
            return self.logEntry === game.logModel.undoLine || self.showUndos && self.logEntry instanceof DecisionEntry && (self.logEntry.index === 0 || !(game.logModel.entries[self.logEntry.index - 1] instanceof DecisionEntry));
        };
        self.undo = function () {
            var undoableDecisions = game.logModel.entries.slice(self.logEntry.index).filter(function (e) {
                return e instanceof DecisionEntry;
            }).length;
            game.requestUndo(undoableDecisions, false);
        };

        self.undoIsThick = function () {
            var index = self.logEntry.index;
            while (index < game.logModel.entries.length && game.logModel.entries[index] instanceof DecisionEntry) {
                index++;
            }
            return index < game.logModel.entries.length && game.logModel.entries[index].name.lineClass === "new-turn-line";
        };

        self.cancelPremove = function () {
            if (self.isPremoveLine) {
                premoves.cancelPremove(self.logEntry.index);
            }
        };
    }],
    template: "\n        <div\n          class=\"log-line {{$ctrl.logEntry.name.lineClass}}\"\n          style=\"padding-left: {{$ctrl.paddingLeft}}%; width:{{98 - $ctrl.paddingLeft}}%;\"\n          ng-click=\"$ctrl.cancelPremove()\"\n          ng-repeat=\"line in $ctrl.lines\">\n            <div class=\"log-line-block\" ng-repeat=\"block in line\"><span \n                    ng-style=\"{{block.style}}\"\n                    on-long-press=\"$ctrl.study(block)\"\n                    ng-right-click=\"$ctrl.study(block)\">{{block.text}}</span></div>\n        </div>\n        <div class=\"undo-line\" ng-class=\"{thick: $ctrl.undoIsThick()}\" ng-if=\"$ctrl.shouldShowUndo()\" ng-click=\"$ctrl.undo()\">\n            <div class=\"undo-line-inner\">\n            </div>\n        </div>\n    "
});