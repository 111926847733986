"use strict";

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

webclient.component("nameInput", {
    bindings: {
        question: "<"
    },
    controller: ['game', function (game) {
        var self = this;

        self.entry = "";

        var resultCutoff = 0;

        var cardNames = Object.values(CardNames);
        cardNames = cardNames.filter(function (c) {
            return !(c.isLandscape() || c.isFake);
        });
        var excludedNames = [CardNames.BACK, CardNames.BLACK_MARKET_PILE, CardNames.PRIZE_PILE, CardNames.REWARDS_PILE, CardNames.RUIN_PILE, CardNames.KNIGHTS, CardNames.CASTLES];
        var nameables = cardNames.filter(function (c) {
            return excludedNames.includes(c);
        });

        var getPrefixedCards = function getPrefixedCards(entry) {
            var prefix = entry.toLowerCase();
            if (prefix == "") {
                return [];
            } else {
                var queryMap = new Map();
                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = cardNames[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var card = _step.value;

                        var queryLevel = searchFilter(prefix)(card);
                        if (queryLevel > 0 && queryLevel <= 5) queryMap.set(card, queryLevel);
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                var cards = [].concat(_toConsumableArray(queryMap.keys()));
                cards.sort(function (a, b) {
                    return queryMap.get(a) - queryMap.get(b);
                });
                return cards;
            }
        };
        self.getWindowCards = function (entry) {
            return getPrefixedCards(entry).slice(resultCutoff, resultCutoff + 20);
        };

        self.name = function (c) {
            self.question.answer(getOrdinal(CardNames, c));
        };

        self.activeClass = function () {
            return self.entry.length > 0 ? " active" : "";
        };
        self.placeholder = function () {
            return getPhrase(Phrases.NAME_CARD);
        };

        self.canScrollLeft = function () {
            return resultCutoff > 0;
        };
        self.scrollLeft = function () {
            resultCutoff -= 15;
            if (resultCutoff < 0) resultCutoff = 0;
        };

        self.canScrollRight = function () {
            return resultCutoff < getPrefixedCards(self.entry).length - 20;
        };
        self.scrollRight = function () {
            resultCutoff += 15;
            if (resultCutoff > getPrefixedCards(self.entry).length - 20) resultCutoff = getPrefixedCards(self.entry).length - 20;
        };

        self.scrollBack = function () {
            resultCutoff = 0;
        };
    }],
    template: "\n<div class=\"name-input\">\n  <input class=\"name-input-entry{{$ctrl.activeClass()}}\"\n         ng-model=\"$ctrl.entry\" ng-change=\"$ctrl.scrollBack()\" type=\"text\" placeholder=\"{{$ctrl.placeholder()}}\">\n  <div\n      class=\"name-input-gallery\"\n      ng-if=\"$ctrl.entry.length > 0\">\n    <div class=\"name-input-scroll-left\" ng-if=\"$ctrl.canScrollLeft()\" ng-click=\"$ctrl.scrollLeft()\"></div>\n    <div class=\"name-input-scroll-right\" ng-if=\"$ctrl.canScrollRight()\" ng-click=\"$ctrl.scrollRight()\"></div>\n    <gallery-card\n        ng-repeat=\"c in $ctrl.getWindowCards($ctrl.entry)\"\n        card=\"c\"\n        ng-click=\"$ctrl.name(c)\"\n        index=\"$index\">\n    </gallery-card>\n  </div>\n</div>\n        "
});

webclient.component("galleryCard", {
    bindings: {
        card: "<",
        index: "<"
    },
    controller: ["$element", "$scope", "game", function ($element, $scope, game) {
        var self = this;
        var anonStack = new CardStack(game.state.getAnonCard(this.card), game.state.nowhere);
        var cardNode = $element[0].getElementsByClassName("gallery-card")[0];
        var redraw = function redraw() {
            var rectangle = cardNode.getBoundingClientRect();
            anonStack.reposition(rectangle.x, rectangle.y, cardNode.clientWidth, cardNode.clientHeight, 5000 + self.index + 1);
            anonStack.view.canvas.style.pointerEvents = "none";
            requestAnimationFrame(redraw);
        };
        requestAnimationFrame(redraw);
        var resizeListener = window.addEventListener("resize", redraw);
        $scope.$on("$destroy", function () {
            window.removeEventListener("resize", resizeListener);
        });
    }],
    template: "\n<div class=\"gallery-card\"></div>\n"
});