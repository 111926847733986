"use strict";

webclient.component('countrySelect', {
    template: '<select ng-model="countrySelect.ngModel" ng-change="countrySelect.ngChange()" ng-options="c.code as countrySelect.getTranslatedCountry(c) for c in countrySelect.countries">\n                   <option ng-if="placeholder" value="">{{countrySelect.placeholder}}</option>\n               </select>',
    bindings: {
        placeholder: '@',
        ngModel: '=',
        ngChange: '&'
    },
    controllerAs: "countrySelect",
    controller: function controller() {
        this.countries = getValues(Countries);
        this.getTranslatedCountry = function (c) {
            return LANGUAGE.getCountry[c];
        };
    }
});