"use strict";

webclient.component("friendActivities", {
    controller: ['friendService', function (friendService) {
        var self = this;
        self.friendService = friendService;
        self.getFriendsText = function () {
            return getPhrase(Phrases.FRIENDS);
        };
        self.getNoFriendsText = function () {
            return getPhrase(Phrases.NO_FRIENDS_ONLINE);
        };
    }],
    template: "\n        <div class=\"automatch-page automatch-table automatch-friends-div\">\n            <div class=\"fieldset-div automatch-fieldset automatch-top  automatch-friends-fieldset\">\n                <div class=\"fieldset-title\">{{::$ctrl.getFriendsText()}}</div>\n                <div class=\"automatch-friends-table-container\">\n                    <table class=\"automatch-friends-table\" ng-if=\"$ctrl.friendService.getFriendActivities().length > 0\">\n                        <tr ng-repeat=\"activity in $ctrl.friendService.getFriendActivities()\">\n                            <td class=\"friend-activities-name-column\">{{activity.playerName}}</td>\n                            <td>\n                                <button class=\"automatch-friends-buttons oval-button\" ng-if=\"activity.shouldShowJoinButton()\" ng-click=\"$ctrl.friendService.joinTable(activity.tableId)\">\n                                    {{activity.getJoinButtonText()}}\n                                </button>\n                            </td>\n                            <td>{{activity.getStatusString()}}</td>\n                        </tr>\n                    </table>\n                    <div class=\"no-friends-text\" ng-if=\"$ctrl.friendService.getFriendActivities().length == 0\">\n                        {{$ctrl.getNoFriendsText()}}\n                    </div>\n                </div>\n            </div>\n        </div>\n    "
});