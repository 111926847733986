"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MotionTarget = function () {
    function MotionTarget() {
        _classCallCheck(this, MotionTarget);
    }

    _createClass(MotionTarget, [{
        key: "initialize",
        value: function initialize(animator, view) {
            this.animator = animator;
            this.startBbox = view.bbox.copy();
        }
    }, {
        key: "targetStack",
        get: function get() {
            return this.animator.targetStack;
        }
    }]);

    return MotionTarget;
}();

var StackTarget = function (_MotionTarget) {
    _inherits(StackTarget, _MotionTarget);

    function StackTarget() {
        var offset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        _classCallCheck(this, StackTarget);

        var _this = _possibleConstructorReturn(this, (StackTarget.__proto__ || Object.getPrototypeOf(StackTarget)).call(this));

        _this.offset = offset === null ? new Rectangle(0, 0, 0, 0) : offset;
        return _this;
    }

    _createClass(StackTarget, [{
        key: "interpolate",
        value: function interpolate(factor) {
            var endBbox = this.targetStack.view.bbox.offset(this.offset);
            if (factor < 0) return this.startBbox;
            if (factor > 1) return endBbox.copy();
            return this.startBbox.linearInterpolate(endBbox, factor);
        }
    }]);

    return StackTarget;
}(MotionTarget);

var RelativeTarget = function (_MotionTarget2) {
    _inherits(RelativeTarget, _MotionTarget2);

    function RelativeTarget() {
        var offset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        _classCallCheck(this, RelativeTarget);

        var _this2 = _possibleConstructorReturn(this, (RelativeTarget.__proto__ || Object.getPrototypeOf(RelativeTarget)).call(this));

        _this2.offset = offset === null ? new Rectangle(0, 0, 0, 0) : offset;
        return _this2;
    }

    _createClass(RelativeTarget, [{
        key: "interpolate",
        value: function interpolate(factor) {
            var endBbox = this.targetStack.view.bbox;
            if (factor < 0) return this.startBbox;
            if (factor > 1) return this.startBbox.offset(this.offset);
            return this.startBbox.linearInterpolate(this.startBbox.offset(this.offset), factor);
        }
    }]);

    return RelativeTarget;
}(MotionTarget);

var AbsoluteTarget = function (_MotionTarget3) {
    _inherits(AbsoluteTarget, _MotionTarget3);

    function AbsoluteTarget(bbox) {
        _classCallCheck(this, AbsoluteTarget);

        var _this3 = _possibleConstructorReturn(this, (AbsoluteTarget.__proto__ || Object.getPrototypeOf(AbsoluteTarget)).call(this));

        _this3.bbox = bbox;
        return _this3;
    }

    _createClass(AbsoluteTarget, [{
        key: "interpolate",
        value: function interpolate(factor) {
            var endBbox = this.bbox;
            if (factor < 0) return this.startBbox;
            if (factor > 1) return this.bbox;
            return this.startBbox.linearInterpolate(this.bbox, factor);
        }
    }]);

    return AbsoluteTarget;
}(MotionTarget);

var Curves = {
    LINEAR: new Bezier(0.5, 0.5, 0.5, 0.5),
    SMOOTH: new Bezier(0.25, 0.25, 0.25, 1),
    SYMMETRIC: new Bezier(0.25, 0, 0.75, 1),
    PARABOLIC_START: new Bezier(0, 0.3, 0.7, 1),
    PARABOLIC_END: new Bezier(0.3, 0, 1, 0.7)
};

var Keyframe = function () {
    function Keyframe(time, target) {
        var curve = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Curves.LINEAR;
        var addedFilters = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
        var removedFilters = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];

        _classCallCheck(this, Keyframe);

        this.time = time;
        this.target = target;
        this.curve = curve;
        this.addedFilters = addedFilters;
        this.removedFilters = removedFilters;
    }

    _createClass(Keyframe, [{
        key: "initialize",
        value: function initialize(animator, view) {
            this.target.initialize(animator, view);
        }
    }, {
        key: "interpolate",
        value: function interpolate(elapsed) {
            var factor = this.time === 0 ? 1 : this.curve.getYFromX(elapsed / this.time);
            return this.target.interpolate(factor);
        }
    }]);

    return Keyframe;
}();

var Animator = function () {
    function Animator(stack, script, targetStack, terminator) {
        var _this4 = this;

        _classCallCheck(this, Animator);

        this.stack = stack;
        this.stack.interruptCurrentAnimator();
        this.script = script;
        this.targetStack = targetStack;
        targetStack.trackedAnimators.push(this);
        this.terminator = terminator;

        this.thisKeyStart = performance.now();
        if (this.script.length > 0) {
            var currentKey = this.script[0];
            currentKey.initialize(this, this.stack.view);
            currentKey.addedFilters.forEach(function (f) {
                return _this4.stack.addFilter(f);
            });
            currentKey.removedFilters.forEach(function (f) {
                return _this4.stack.removeFilter(f);
            });
        }
        this.animate(this.thisKeyStart);
        this.interrupted = false;
    }

    _createClass(Animator, [{
        key: "interrupt",
        value: function interrupt() {
            this.script = [];
            this.interrupted = true;
        }
    }, {
        key: "animate",
        value: function animate(timestamp) {
            var _this5 = this;

            if (this.stack.destroyed) return;
            if (isUndefined(this.targetStack.view)) return;
            if (this.targetStack.destroyed) {
                console.error("targetstack got destroyed prematurely", this.targetStack);
            }
            if (this.script.length === 0) {
                if (this.interrupted) return;else {
                    this.targetStack.location.mergeStacks(this.targetStack, this.stack);
                    this.targetStack.trackedAnimators.splice(this.targetStack.trackedAnimators.indexOf(this), 1);
                    return this.terminator();
                }
            }
            var currentKey = this.script[0];
            var elapsed = timestamp - this.thisKeyStart;
            if (elapsed > currentKey.time) {
                this.script.shift();
                this.thisKeyStart = timestamp;
                if (this.script.length > 0) {
                    currentKey = this.script[0];
                    currentKey.initialize(this, this.stack.view);
                    var bbox = currentKey.interpolate(0);
                    this.stack.repositionBbox(bbox);
                    currentKey.addedFilters.forEach(function (f) {
                        return _this5.stack.addFilter(f);
                    });
                    currentKey.removedFilters.forEach(function (f) {
                        return _this5.stack.removeFilter(f);
                    });
                } else {
                    var _bbox = currentKey.interpolate(currentKey.time);
                    this.stack.repositionBbox(_bbox);
                }
            } else {
                var _bbox2 = currentKey.interpolate(elapsed);
                this.stack.repositionBbox(_bbox2);
            }
            requestAnimationFrame(this.animate.bind(this));
        }
    }, {
        key: "retarget",
        value: function retarget(target) {
            this.targetStack.trackedAnimators.splice(this.targetStack.trackedAnimators.indexOf(this), 1);
            this.targetStack = target;
            target.trackedAnimators.push(this);
        }
    }]);

    return Animator;
}();

var TransitionAnimator = function () {
    function TransitionAnimator(stack, script, targetStack, terminator) {
        var _this6 = this;

        _classCallCheck(this, TransitionAnimator);

        this.stack = stack;
        this.stack.interruptCurrentAnimator();
        this.script = script;
        this.terminator = terminator;
        this.targetStack = targetStack;

        this.thisKeyStart = performance.now();
        if (this.script.length > 0) {
            var currentKey = this.script[0];
            currentKey.initialize(this, this.stack.view);
            var bbox = currentKey.interpolate(0);
            this.stack.view.copyBbox(bbox);
            currentKey.addedFilters.forEach(function (f) {
                return _this6.targetStack.addFilter(f);
            });
            currentKey.removedFilters.forEach(function (f) {
                return _this6.targetStack.removeFilter(f);
            });
        }
        this.animate(this.thisKeyStart);
        this.interrupted = false;
    }

    _createClass(TransitionAnimator, [{
        key: "interrupt",
        value: function interrupt() {
            this.script = [];
            this.interrupted = true;
        }
    }, {
        key: "animate",
        value: function animate(timestamp) {
            var _this7 = this;

            if (this.stack.destroyed) return;
            if (this.script.length === 0) {
                this.stack.animator = null;
                if (this.interrupted) return;else return this.terminator();
            }
            var currentKey = this.script[0];
            var elapsed = timestamp - this.thisKeyStart;
            if (elapsed > currentKey.time) {
                this.script.shift();
                this.thisKeyStart = timestamp;
                if (this.script.length > 0) {
                    var _currentKey = this.script[0];
                    _currentKey.initialize(this, this.stack.view);
                    var bbox = _currentKey.interpolate(0);
                    this.stack.repositionBbox(bbox);
                    _currentKey.addedFilters.forEach(function (f) {
                        return _this7.targetStack.addFilter(f);
                    });
                    _currentKey.removedFilters.forEach(function (f) {
                        return _this7.targetStack.removeFilter(f);
                    });
                } else {
                    var _bbox3 = currentKey.interpolate(currentKey.time);
                    this.stack.repositionBbox(_bbox3);
                }
            } else {
                var _bbox4 = currentKey.interpolate(elapsed);
                this.stack.repositionBbox(_bbox4);
            }
            requestAnimationFrame(this.animate.bind(this));
        }
    }]);

    return TransitionAnimator;
}();

function delayScript(delay, script) {
    if (script.length === 0) return script;
    return [new Keyframe(delay, new RelativeTarget(), Curves.LINEAR)].concat(script);
}

function selectedStackCreateScript(baseStack) {
    var _baseStack$view$bbox = baseStack.view.bbox,
        width = _baseStack$view$bbox.width,
        height = _baseStack$view$bbox.height;

    var settings = getAnimationSettings();
    return [new Keyframe(settings.map.get(AnimationClassIds.TRANSITION).speed, new StackTarget(new Rectangle(-0.1 * width, -0.25 * height, 0, 0, -2, -10)), Curves.SMOOTH)];
}

function simpleTrackedTransitionScript() {
    var offset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

    var settings = getAnimationSettings();
    return [new Keyframe(settings.map.get(AnimationClassIds.TRANSITION).speed, new StackTarget(offset), Curves.SMOOTH)];
}

function reorderTransitionScript(baseStack) {
    var settings = getAnimationSettings();
    var _baseStack$view$bbox2 = baseStack.view.bbox,
        width = _baseStack$view$bbox2.width,
        height = _baseStack$view$bbox2.height;

    var midairOffset = new Rectangle(0.1 * width, -0.85 * height, -0.2 * width, -0.2 * height, 2, 0);
    return [new Keyframe(settings.map.get(AnimationClassIds.TRANSITION).speed, new StackTarget(midairOffset), Curves.PARABOLIC_START), new Keyframe(settings.map.get(AnimationClassIds.TRANSITION).speed, new StackTarget(new Rectangle(0, 0, 0, 0, -5, 0)), Curves.PARABOLIC_END)];
}

function reorderBaseTransition(bbox) {
    var settings = getAnimationSettings();
    return [new Keyframe(settings.map.get(AnimationClassIds.TRANSITION).speed * 2, new AbsoluteTarget(bbox.offset(new Rectangle(0, 0, 0, 0, 10))), Curves.LINEAR), new Keyframe(settings.map.get(AnimationClassIds.TRANSITION).speed, new AbsoluteTarget(bbox), Curves.LINEAR)];
}

function absoluteTransitionScript(bbox) {
    var settings = getAnimationSettings();
    return [new Keyframe(settings.map.get(AnimationClassIds.TRANSITION).speed, new AbsoluteTarget(bbox), Curves.SMOOTH)];
}

function flipScript(stack) {
    var settings = getAnimationSettings();
    var height = stack.view.bbox.height;
    return [new Keyframe(props.speed, new StackTarget(new Rectangle(0, -0.2 * height, 0, 0, 10, 0, 0, 30)), Curves.PARABOLIC_START)];
}

function unflipScript(stack) {
    var settings = getAnimationSettings();
    var height = stack.view.bbox.height;
    return [new Keyframe(props.speed, new StackTarget(new Rectangle(0, 0.2 * height, 0, 0, -10, 0, 0, -30)), Curves.PARABOLIC_END)];
}