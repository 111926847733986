"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PremoveDial = function (_RenderableObject) {
    _inherits(PremoveDial, _RenderableObject);

    function PremoveDial(centerX, centerY, cardStack) {
        _classCallCheck(this, PremoveDial);

        var _this = _possibleConstructorReturn(this, (PremoveDial.__proto__ || Object.getPrototypeOf(PremoveDial)).call(this));

        _this.sectorWeights = [1, 0, 0, 0, 0];
        _this.centerX = centerX;
        _this.centerY = centerY;
        _this.cardStack = cardStack;
        _this.unloaded = false;
        _this.hoveredSector = 0;
        return _this;
    }

    _createClass(PremoveDial, [{
        key: "unload",
        value: function unload() {
            _get(PremoveDial.prototype.__proto__ || Object.getPrototypeOf(PremoveDial.prototype), "unload", this).call(this);
            this.unloaded = true;
        }
    }, {
        key: "render",
        value: function render(node) {
            _get(PremoveDial.prototype.__proto__ || Object.getPrototypeOf(PremoveDial.prototype), "render", this).call(this, node);
            var vh = window.innerHeight / 100;
            this.canvas.width = 32 * vh;
            this.canvas.height = 32 * vh;
            this.canvas.style.width = 16 * vh + "px";
            this.canvas.style.height = 16 * vh + "px";

            this.canvas.style.left = this.centerX - this.canvas.clientWidth / 2 + "px";
            this.canvas.style.top = this.centerY - this.canvas.clientHeight / 2 + "px";
            this.canvas.style.position = "fixed";
        }
    }, {
        key: "redrawCenterSector",
        value: function redrawCenterSector() {
            var _ref = [this.canvas.width, this.canvas.height, this.context],
                w = _ref[0],
                h = _ref[1],
                ctx = _ref[2];

            var margin = h / 40;
            ctx.beginPath();
            ctx.arc(w / 2, h / 2, w / 5 - (1 - this.sectorWeights[0]) * margin, 0, 2 * Math.PI);
            var lum = this.sectorWeights[0];
            ctx.fillStyle = "rgba(" + lum * 160 + ", " + lum * 160 + ", " + lum * 240 + ", 0.8)";
            ctx.fill();
        }
    }, {
        key: "redrawSector",
        value: function redrawSector(idx) {
            var _ref2 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref2[0],
                h = _ref2[1],
                ctx = _ref2[2];

            var margin = h / 40;

            var t_margin = Math.PI * 0.02;
            var t_1 = Math.PI / 4 * (2 * idx + 7) + t_margin;
            var t_2 = t_1 + Math.PI / 2 - 2 * t_margin;

            var r_inner = w / 5 + (1 - this.sectorWeights[1 + idx]) * margin;
            var r_outer = w / 2 - (1 - this.sectorWeights[1 + idx]) * margin;

            ctx.beginPath();
            ctx.arc(w / 2, h / 2, r_outer, t_1, t_2);
            ctx.lineTo(w / 2 + r_inner * Math.cos(t_2), h / 2 + r_inner * Math.sin(t_2));
            ctx.arc(w / 2, h / 2, r_inner, t_2, t_1, true);
            ctx.lineTo(w / 2 + r_outer * Math.cos(t_1), h / 2 + r_outer * Math.sin(t_1));
            var lum = this.sectorWeights[1 + idx];
            ctx.fillStyle = "rgba(" + lum * 160 + ", " + lum * 160 + ", " + lum * 240 + ", 0.8)";
            ctx.fill();

            var sources = ["images/buttons/discard.png", "images/buttons/buy.png", "images/buttons/react.png", "images/buttons/play.png"];
            var t_m = (t_1 + t_2) / 2;
            var r_m = (r_inner + r_outer) / 2;
            var center = [w / 2 + Math.cos(t_m) * r_m, h / 2 + Math.sin(t_m) * r_m];
            var bbox = new Rectangle(center[0] - w / 8, center[1] - h / 8, w / 4, h / 4);
            Preloader.load(sources[idx], function (source) {
                return ctx.drawImage(source, bbox.x, bbox.y, bbox.width, bbox.height);
            });
        }
    }, {
        key: "tick",
        value: function tick(eventX, eventY) {
            var _this2 = this;

            if (this.unloaded) return;
            var endpoint = [eventX - this.centerX, eventY - this.centerY];
            var norm = Math.sqrt(Math.pow(endpoint[0], 2) + Math.pow(endpoint[1], 2));
            if (norm < 0.027 * window.innerHeight) {
                this.sectorWeights[0] += 0.04;
                this.hoveredSector = 0;
            } else {
                var rawAngle = Math.atan2(endpoint[1], endpoint[0]) - Math.PI / 4;
                var sector = (Math.floor(rawAngle / Math.PI * 2) + 5) % 4;
                this.sectorWeights[1 + sector] += 0.04;
                this.hoveredSector = 1 + sector;
            }
            var keepTicking = false;
            for (var i = 0; i < 5; i++) {
                this.sectorWeights[i] -= 0.02;
                if (this.sectorWeights[i] > 0 && this.sectorWeights[i] < 1) keepTicking = true;
                this.sectorWeights[i] = Math.min(1, Math.max(0, this.sectorWeights[i]));
            }
            this.redraw();
            if (keepTicking) {
                requestAnimationFrame(function () {
                    return _this2.tick(eventX, eventY);
                });
            }
        }
    }, {
        key: "redraw",
        value: function redraw() {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.redrawCenterSector();
            for (var i = 0; i < 4; i++) {
                this.redrawSector(i);
            }
        }
    }]);

    return PremoveDial;
}(RenderableObject);