"use strict";

var JapaneseCardModes = {};

JapaneseCardModes[CardModes.PAWN_CARD_ACTION] = "カードを引く、アクションで確定";
JapaneseCardModes[CardModes.PAWN_CARD] = "+1カードを引く";
JapaneseCardModes[CardModes.PAWN_ACTION] = "+1アクション";
JapaneseCardModes[CardModes.PAWN_BUY] = "+1購入";
JapaneseCardModes[CardModes.PAWN_COIN] = "+$1";
JapaneseCardModes[CardModes.STEWARD_CARDS] = "カードを引く";
JapaneseCardModes[CardModes.STEWARD_COINS] = "$";
JapaneseCardModes[CardModes.STEWARD_TRASH] = "廃棄する";
JapaneseCardModes[CardModes.MINION_COINS] = "$";
JapaneseCardModes[CardModes.MINION_NEWHAND] = "捨て札にする";
JapaneseCardModes[CardModes.NOBLES_CARDS] = "カードを引く";
JapaneseCardModes[CardModes.NOBLES_ACTIONS] = "アクション";
JapaneseCardModes[CardModes.COURTIER_ACTION] = "+1アクション";
JapaneseCardModes[CardModes.COURTIER_BUY] = "+1購入";
JapaneseCardModes[CardModes.COURTIER_COINS] = "+$3";
JapaneseCardModes[CardModes.COURTIER_GOLD] = "金貨を獲得";
JapaneseCardModes[CardModes.NATIVE_VILLAGE_SET_ASIDE] = "マットに置く";
JapaneseCardModes[CardModes.NATIVE_VILLAGE_RETURN] = "手札に加える";
JapaneseCardModes[CardModes.PIRATE_SHIP_RAID] = "攻撃する";
JapaneseCardModes[CardModes.PIRATE_SHIP_PROFIT] = "$";
JapaneseCardModes[CardModes.SCRYING_POOL_TOPDECK] = "そのままにする";
JapaneseCardModes[CardModes.SCRYING_POOL_DISCARD] = "捨て札にする";
JapaneseCardModes[CardModes.LOAN_DISCARD] = "捨て札にする";
JapaneseCardModes[CardModes.LOAN_TRASH] = "廃棄する";
JapaneseCardModes[CardModes.TRUSTY_STEED_CARDS_ACTIONS] = "カードを引く、アクションで確定";
JapaneseCardModes[CardModes.TRUSTY_STEED_CARDS] = "+2カードを引く";
JapaneseCardModes[CardModes.TRUSTY_STEED_ACTIONS] = "+2アクション";
JapaneseCardModes[CardModes.TRUSTY_STEED_COINS] = "+$2";
JapaneseCardModes[CardModes.TRUSTY_STEED_SILVERS] = "銀貨を獲得";
JapaneseCardModes[CardModes.JESTER_THEY_GAIN] = "相手";
JapaneseCardModes[CardModes.JESTER_YOU_GAIN] = "あなた";
JapaneseCardModes[CardModes.DUCHESS_KEEP] = "そのままにする";
JapaneseCardModes[CardModes.DUCHESS_DISCARD] = "捨て札にする";
JapaneseCardModes[CardModes.ORACLE_TOPDECK] = "山札に置く";
JapaneseCardModes[CardModes.ORACLE_DISCARD] = "捨て札にする";
JapaneseCardModes[CardModes.JACK_KEEP] = "そのままにする";
JapaneseCardModes[CardModes.JACK_DISCARD] = "捨て札にする";
JapaneseCardModes[CardModes.SPICE_MERCHANT_CARDS] = "カードを引く、アクション";
JapaneseCardModes[CardModes.SPICE_MERCHANT_COINS] = "$、購入";
JapaneseCardModes[CardModes.CATACOMBS_TAKE] = "手札に加える";
JapaneseCardModes[CardModes.CATACOMBS_DISCARD] = "捨て札にする";
JapaneseCardModes[CardModes.COUNT_DISCARD] = "捨て札にする";
JapaneseCardModes[CardModes.COUNT_TOPDECK] = "山札に置く";
JapaneseCardModes[CardModes.COUNT_GAIN_COPPER] = "銅貨を獲得する";
JapaneseCardModes[CardModes.COUNT_COINS] = "$";
JapaneseCardModes[CardModes.COUNT_TRASH_HAND] = "廃棄する";
JapaneseCardModes[CardModes.COUNT_GAIN_DUCHY] = "公領を獲得する";
JapaneseCardModes[CardModes.HUNTING_GROUNDS_ESTATES] = "屋敷3枚";
JapaneseCardModes[CardModes.HUNTING_GROUNDS_DUCHY] = "公領";
JapaneseCardModes[CardModes.IRONMONGER_KEEP] = "そのままにする";
JapaneseCardModes[CardModes.IRONMONGER_DISCARD] = "捨て札にする";
JapaneseCardModes[CardModes.SCAVENGER_DISCARD] = "捨て札置き場に置く";
JapaneseCardModes[CardModes.SCAVENGER_KEEP] = "そのままにする";
JapaneseCardModes[CardModes.SQUIRE_ACTIONS] = "アクション";
JapaneseCardModes[CardModes.SQUIRE_BUYS] = "購入";
JapaneseCardModes[CardModes.SQUIRE_SILVER] = "銀貨を獲得する";
JapaneseCardModes[CardModes.MERCENARY_DONT_TRASH] = "廃棄しない";
JapaneseCardModes[CardModes.DOCTOR_TRASH] = "廃棄する";
JapaneseCardModes[CardModes.DOCTOR_DISCARD] = "捨て札にする";
JapaneseCardModes[CardModes.DOCTOR_TOPDECK] = "そのままにする";
JapaneseCardModes[CardModes.AMULET_COIN] = "$";
JapaneseCardModes[CardModes.AMULET_TRASH] = "廃棄する";
JapaneseCardModes[CardModes.AMULET_SILVER] = "銀貨を獲得する";
JapaneseCardModes[CardModes.MISER_COPPER_TO_MAT] = "マットに置く";
JapaneseCardModes[CardModes.MISER_PROFIT] = "$";
JapaneseCardModes[CardModes.MESSENGER_DISCARD] = "捨て札置き場に置く";
JapaneseCardModes[CardModes.MESSENGER_KEEP] = "そのままにする";
JapaneseCardModes[CardModes.QUEST_ATTACK] = "アタックカード";
JapaneseCardModes[CardModes.QUEST_CURSES] = "呪い2枚";
JapaneseCardModes[CardModes.QUEST_CARDS] = "カード6枚";
JapaneseCardModes[CardModes.TEACHER_CARD] = "+1カードトークン";
JapaneseCardModes[CardModes.TEACHER_ACTION] = "+1アクショントークン";
JapaneseCardModes[CardModes.TEACHER_BUY] = "+1購入トークン";
JapaneseCardModes[CardModes.TEACHER_COIN] = "+$1トークン";
JapaneseCardModes[CardModes.LIBRARY_DRAW_IT] = "脇に置かない";
JapaneseCardModes[CardModes.LIBRARY_SKIP_IT] = "脇に置く";
JapaneseCardModes[CardModes.GLADIATOR_PRETEND] = "公開しない";
JapaneseCardModes[CardModes.SPRAWLING_CASTLE_ESTATES] = "屋敷3枚";
JapaneseCardModes[CardModes.SPRAWLING_CASTLE_DUCHY] = "公領";
JapaneseCardModes[CardModes.CHARM_BUY_MONEY] = "購入、$";
JapaneseCardModes[CardModes.CHARM_COPY] = "獲得する";
JapaneseCardModes[CardModes.WILD_HUNT_CARDS] = "カードを引く";
JapaneseCardModes[CardModes.WILD_HUNT_POINTS] = "屋敷を獲得する";
JapaneseCardModes[CardModes.PRINCE_SET_ASIDE] = "王子を脇に置く";
JapaneseCardModes[CardModes.PRINCE_NO] = "王子を脇に置かない";
JapaneseCardModes[CardModes.HAMLET_ACTION] = "アクション";
JapaneseCardModes[CardModes.HAMLET_BUY] = "購入";
JapaneseCardModes[CardModes.HAMLET_BOTH] = "アクション、購入";
JapaneseCardModes[CardModes.GOVERNOR_CARDS] = "カードを引く";
JapaneseCardModes[CardModes.GOVERNOR_TREASURE] = "金貨を獲得する";
JapaneseCardModes[CardModes.GOVERNOR_REMODEL] = "廃棄して獲得する";
JapaneseCardModes[CardModes.BLESSED_VILLAGE_NOW] = "今受ける";
JapaneseCardModes[CardModes.BLESSED_VILLAGE_LATER] = "次のターンに受ける";
JapaneseCardModes[CardModes.PIXIE_TRASH] = "廃棄する";
JapaneseCardModes[CardModes.PIXIE_KEEP] = "廃棄しない";
JapaneseCardModes[CardModes.SACRED_GROVE_RECEIVE] = "祝福を受ける";
JapaneseCardModes[CardModes.SACRED_GROVE_NO] = "祝福を受けない";
JapaneseCardModes[CardModes.ZOMBIE_SPY_DISCARD] = "捨て札にする";
JapaneseCardModes[CardModes.ZOMBIE_SPY_TOPDECK] = "そのままにする";
JapaneseCardModes[CardModes.START_GAME] = "ゲームを開始する";
JapaneseCardModes[CardModes.PEARL_DIVER_TOPDECK] = "上に置く";
JapaneseCardModes[CardModes.PEARL_DIVER_BOTTOMDECK] = "そのままにする";
JapaneseCardModes[CardModes.SCEPTER_FAIL_ACTION] = "何もしない";
JapaneseCardModes[CardModes.SCEPTER_ADD_COINS] = "+$2";
JapaneseCardModes[CardModes.TREASURER_FAIL] = "何もしない";
JapaneseCardModes[CardModes.TREASURER_KEY] = "鍵を得る";
JapaneseCardModes[CardModes.BORDER_GUARD_LANTERN] = "角灯";
JapaneseCardModes[CardModes.BORDER_GUARD_HORN] = "角笛";
JapaneseCardModes[CardModes.BORDER_GUARD_FAIL] = "何もしない";
JapaneseCardModes[CardModes.PAGEANT_YES] = "+1財源";
JapaneseCardModes[CardModes.PAGEANT_NO] = "恩恵を受けない";
JapaneseCardModes[CardModes.SINISTER_PLOT_ADD] = "トークンを追加する";
JapaneseCardModes[CardModes.SINISTER_PLOT_DRAW] = "カードを引く";
JapaneseCardModes[CardModes.PLAY] = "使用";
JapaneseCardModes[CardModes.DONT_PLAY] = "使用しない";
JapaneseCardModes[CardModes.VILLAGE_GREEN_NOW] = "現在";
JapaneseCardModes[CardModes.VILLAGE_GREEN_LATER] = "次のターン";
JapaneseCardModes[CardModes.BARGE_NOW] = "現在";
JapaneseCardModes[CardModes.BARGE_LATER] = "次のターン";
JapaneseCardModes[CardModes.SCRAP_CARD] = "+1カードを引く";
JapaneseCardModes[CardModes.SCRAP_ACTION] = "+1アクション";
JapaneseCardModes[CardModes.SCRAP_BUY] = "+1購入";
JapaneseCardModes[CardModes.SCRAP_COIN] = "+$1";
JapaneseCardModes[CardModes.SCRAP_SILVER] = "銀貨を獲得";
JapaneseCardModes[CardModes.SCRAP_HORSE] = "馬を獲得";
JapaneseCardModes[CardModes.MODE_OF_THE_BUTTERFLY] = "チョウ";
JapaneseCardModes[CardModes.MODE_OF_THE_CAMEL] = "ラクダ";
JapaneseCardModes[CardModes.MODE_OF_THE_CHAMELEON] = "カメレオン";
JapaneseCardModes[CardModes.MODE_OF_THE_FROG] = "カエル";
JapaneseCardModes[CardModes.MODE_OF_THE_GOAT] = "ヤギ";
JapaneseCardModes[CardModes.MODE_OF_THE_HORSE] = "馬";
JapaneseCardModes[CardModes.MODE_OF_THE_MOLE] = "モグラ";
JapaneseCardModes[CardModes.MODE_OF_THE_MONKEY] = "サル";
JapaneseCardModes[CardModes.MODE_OF_THE_MOUSE] = "ハツカネズミ";
JapaneseCardModes[CardModes.MODE_OF_THE_MULE] = "ラバ";
JapaneseCardModes[CardModes.MODE_OF_THE_OTTER] = "カワウソ";
JapaneseCardModes[CardModes.MODE_OF_THE_OWL] = "フクロウ";
JapaneseCardModes[CardModes.MODE_OF_THE_OX] = "雄牛";
JapaneseCardModes[CardModes.MODE_OF_THE_PIG] = "豚";
JapaneseCardModes[CardModes.MODE_OF_THE_RAT] = "ドブネズミ";
JapaneseCardModes[CardModes.MODE_OF_THE_SEAL] = "アザラシ";
JapaneseCardModes[CardModes.MODE_OF_THE_SHEEP] = "羊";
JapaneseCardModes[CardModes.MODE_OF_THE_SQUIRREL] = "リス";
JapaneseCardModes[CardModes.MODE_OF_THE_TURTLE] = "ウミガメ";
JapaneseCardModes[CardModes.MODE_OF_THE_WORM] = "ミミズ";
JapaneseCardModes[CardModes.BUTTERFLY_RETURN_IT] = "山に戻す";
JapaneseCardModes[CardModes.BUTTERFLY_DO_NOTHING] = "何もしない";
JapaneseCardModes[CardModes.WAYFARER_SILVER] = "銀貨を獲得";
JapaneseCardModes[CardModes.DESPERATION_CURSE] = "呪いを獲得";
JapaneseCardModes[CardModes.CRYSTAL_BALL_TRASH] = "廃棄";
JapaneseCardModes[CardModes.CRYSTAL_BALL_DISCARD] = "捨て札";
JapaneseCardModes[CardModes.CRYSTAL_BALL_PLAY] = "使用";
JapaneseCardModes[CardModes.CRYSTAL_BALL_TOPDECK] = "山札に戻す";
JapaneseCardModes[CardModes.TOWN_VILLAGE] = "カードを引く、アクション";
JapaneseCardModes[CardModes.TOWN_COINS_BUY] = "購入、$";
JapaneseCardModes[CardModes.MODIFY_CANTRIP] = "カードを引く、アクション";
JapaneseCardModes[CardModes.MODIFY_GAIN] = "カードを獲得";
JapaneseCardModes[CardModes.DESERT_GUIDES_KEEP] = "そのまま";
JapaneseCardModes[CardModes.DESERT_GUIDES_NEW_HAND] = "捨て札にする";
JapaneseCardModes[CardModes.BAUBLE_BUY] = "+1購入";
JapaneseCardModes[CardModes.BAUBLE_COIN] = "+$1";
JapaneseCardModes[CardModes.BAUBLE_FAVOR] = "+1好意";
JapaneseCardModes[CardModes.BAUBLE_TOPDECK] = "山札に置く";
JapaneseCardModes[CardModes.BROKER_CARDS] = "カードを引く";
JapaneseCardModes[CardModes.BROKER_ACTIONS] = "アクション";
JapaneseCardModes[CardModes.BROKER_COINS] = "$";
JapaneseCardModes[CardModes.BROKER_FAVORS] = "好意";
JapaneseCardModes[CardModes.INNKEEPER_CARD] = "+1カードを引く";
JapaneseCardModes[CardModes.INNKEEPER_3] = "+3カードを引く、3枚捨て札";
JapaneseCardModes[CardModes.INNKEEPER_5_6] = "+5カードを引く、6枚捨て札";
JapaneseCardModes[CardModes.CAPITAL_CITY_DISCARD] = "2枚捨て札にして+$2";
JapaneseCardModes[CardModes.CAPITAL_CITY_PAY] = "$2支払い+2カードを引く";
JapaneseCardModes[CardModes.SPECIALIST_PLAY_AGAIN] = "再度使用";
JapaneseCardModes[CardModes.SPECIALIST_GAIN_COPY] = "獲得";
JapaneseCardModes[CardModes.HILL_FORT_TO_HAND] = "手札に加える";
JapaneseCardModes[CardModes.HILL_FORT_CANTRIP] = "カードを引く、アクション";
JapaneseCardModes[CardModes.STRONGHOLD_COINS] = "+$3";
JapaneseCardModes[CardModes.STRONGHOLD_CARDS] = "次ターン: +3カードを引く";
JapaneseCardModes[CardModes.TOWN_CRIER_COINS] = "+$2";
JapaneseCardModes[CardModes.TOWN_CRIER_SILVER] = "銀貨を獲得";
JapaneseCardModes[CardModes.TOWN_CRIER_CANTRIP] = "カードを引く、アクション";
JapaneseCardModes[CardModes.BLACKSMITH_OWL] = "手札6枚まで引く";
JapaneseCardModes[CardModes.BLACKSMITH_OTTER] = "+2カードを引く";
JapaneseCardModes[CardModes.BLACKSMITH_PIG] = "+1カードを引く、+1アクション";
JapaneseCardModes[CardModes.LURKER_TRASH] = "サプライから廃棄";
JapaneseCardModes[CardModes.LURKER_GAIN] = "廃棄置き場から獲得";
JapaneseCardModes[CardModes.GRAVEROBBER_GAIN] = "山札の上に獲得";
JapaneseCardModes[CardModes.GRAVEROBBER_TRASH] = "手札を廃棄して獲得";
JapaneseCardModes[CardModes.TREASURER_TRASH] = "廃棄";
JapaneseCardModes[CardModes.TREASURER_GAIN] = "廃棄置き場から獲得";
JapaneseCardModes[CardModes.INVESTMENT_COIN] = "+$1";
JapaneseCardModes[CardModes.INVESTMENT_VP] = "廃棄して+VP";
JapaneseCardModes[CardModes.WEAVER_SILVERS] = "銀貨2枚";
JapaneseCardModes[CardModes.CABIN_BOY_COINS] = "+$2";
JapaneseCardModes[CardModes.CABIN_BOY_GAIN] = "廃棄する";
JapaneseCardModes[CardModes.GONDOLA_NOW] = "+$2";
JapaneseCardModes[CardModes.GONDOLA_LATER] = "次のターン";
JapaneseCardModes[CardModes.QUARTERMASTER_GAIN] = "獲得する";
JapaneseCardModes[CardModes.QUARTERMASTER_TAKE] = "手札に加える";
JapaneseCardModes[CardModes.AMPHORA_NOW] = "現在";
JapaneseCardModes[CardModes.AMPHORA_LATER] = "次のターン";
JapaneseCardModes[CardModes.SPELL_SCROLL_PLAY] = "使用する";
JapaneseCardModes[CardModes.SPELL_SCROLL_REFUSE] = "使用しない";
JapaneseCardModes[CardModes.COURSER_CARDS_ACTIONS] = "カードを引く、アクションで確定";
JapaneseCardModes[CardModes.COURSER_CARDS] = "+2カードを引く";
JapaneseCardModes[CardModes.COURSER_ACTIONS] = "+2アクション";
JapaneseCardModes[CardModes.COURSER_COINS] = "+$2";
JapaneseCardModes[CardModes.COURSER_SILVERS] = "銀貨を獲得";
JapaneseCardModes[CardModes.SICKNESS_FAIL_DISCARD] = "呪いを獲得しない";
JapaneseCardModes[CardModes.SICKNESS_CURSE] = "呪いを山札に獲得";
JapaneseCardModes[CardModes.SICKNESS_FAIL_CURSE] = "捨て札にしない";
JapaneseCardModes[CardModes.SICKNESS_DISCARD] = "3枚捨て札にする";
JapaneseCardModes[CardModes.KITSUNE_ACTIONS] = "+2アクション";
JapaneseCardModes[CardModes.KITSUNE_COINS] = "+$2";
JapaneseCardModes[CardModes.KITSUNE_CURSE] = "呪いを配る";
JapaneseCardModes[CardModes.KITSUNE_SILVER] = "銀貨を獲得";

JapaneseCardModes[ExtraTurnTypes.OUTPOST] = JapaneseCardNames[CardNames.OUTPOST].singular;
JapaneseCardModes[ExtraTurnTypes.POSSESSION] = JapaneseCardNames[CardNames.POSSESSION].singular;
JapaneseCardModes[ExtraTurnTypes.MISSION] = JapaneseCardNames[CardNames.MISSION].singular;
JapaneseCardModes[ExtraTurnTypes.FLEET] = JapaneseCardNames[CardNames.FLEET].singular;
JapaneseCardModes[ExtraTurnTypes.SEIZE_THE_DAY] = JapaneseCardNames[CardNames.SEIZE_THE_DAY].singular;
JapaneseCardModes[ExtraTurnTypes.ISLAND_FOLK] = JapaneseCardNames[CardNames.ISLAND_FOLK].singular;
JapaneseCardModes[ExtraTurnTypes.VOYAGE] = JapaneseCardNames[CardNames.VOYAGE].singular;
JapaneseCardModes[ExtraTurnTypes.JOURNEY] = JapaneseCardNames[CardNames.JOURNEY].singular;