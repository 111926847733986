"use strict";

var JapaneseCardNames = {};

JapaneseCardNames[CardNames.BACK] = new TranslatedCardName("カード");
JapaneseCardNames[CardNames.CURSE] = new TranslatedCardName("呪い");
JapaneseCardNames[CardNames.COPPER] = new TranslatedCardName("銅貨");
JapaneseCardNames[CardNames.SILVER] = new TranslatedCardName("銀貨");
JapaneseCardNames[CardNames.GOLD] = new TranslatedCardName("金貨");
JapaneseCardNames[CardNames.ESTATE] = new TranslatedCardName("屋敷");
JapaneseCardNames[CardNames.DUCHY] = new TranslatedCardName("公領");
JapaneseCardNames[CardNames.PROVINCE] = new TranslatedCardName("属州");

JapaneseCardNames[CardNames.ARTISAN] = new TranslatedCardName("職人");
JapaneseCardNames[CardNames.BANDIT] = new TranslatedCardName("山賊");
JapaneseCardNames[CardNames.BUREAUCRAT] = new TranslatedCardName("役人");
JapaneseCardNames[CardNames.CELLAR] = new TranslatedCardName("地下貯蔵庫");
JapaneseCardNames[CardNames.CHAPEL] = new TranslatedCardName("礼拝堂");
JapaneseCardNames[CardNames.COUNCIL_ROOM] = new TranslatedCardName("議事堂");
JapaneseCardNames[CardNames.FESTIVAL] = new TranslatedCardName("祝祭");
JapaneseCardNames[CardNames.GARDENS] = new TranslatedCardName("庭園");
JapaneseCardNames[CardNames.HARBINGER] = new TranslatedCardName("前駆者");
JapaneseCardNames[CardNames.LABORATORY] = new TranslatedCardName("研究所");
JapaneseCardNames[CardNames.LIBRARY] = new TranslatedCardName("書庫");
JapaneseCardNames[CardNames.MARKET] = new TranslatedCardName("市場");
JapaneseCardNames[CardNames.MERCHANT] = new TranslatedCardName("商人");
JapaneseCardNames[CardNames.MILITIA] = new TranslatedCardName("民兵");
JapaneseCardNames[CardNames.MINE] = new TranslatedCardName("鉱山");
JapaneseCardNames[CardNames.MOAT] = new TranslatedCardName("堀");
JapaneseCardNames[CardNames.MONEYLENDER] = new TranslatedCardName("金貸し");
JapaneseCardNames[CardNames.POACHER] = new TranslatedCardName("密猟者");
JapaneseCardNames[CardNames.REMODEL] = new TranslatedCardName("改築");
JapaneseCardNames[CardNames.SENTRY] = new TranslatedCardName("衛兵");
JapaneseCardNames[CardNames.SMITHY] = new TranslatedCardName("鍛冶屋");
JapaneseCardNames[CardNames.THRONE_ROOM] = new TranslatedCardName("玉座の間");
JapaneseCardNames[CardNames.VASSAL] = new TranslatedCardName("家臣");
JapaneseCardNames[CardNames.VILLAGE] = new TranslatedCardName("村");
JapaneseCardNames[CardNames.WITCH] = new TranslatedCardName("魔女");
JapaneseCardNames[CardNames.WORKSHOP] = new TranslatedCardName("工房");

JapaneseCardNames[CardNames.BARON] = new TranslatedCardName("男爵");
JapaneseCardNames[CardNames.BRIDGE] = new TranslatedCardName("橋");
JapaneseCardNames[CardNames.CONSPIRATOR] = new TranslatedCardName("共謀者");
JapaneseCardNames[CardNames.COURTIER] = new TranslatedCardName("廷臣");
JapaneseCardNames[CardNames.COURTYARD] = new TranslatedCardName("中庭");
JapaneseCardNames[CardNames.DIPLOMAT] = new TranslatedCardName("外交官");
JapaneseCardNames[CardNames.DUKE] = new TranslatedCardName("公爵");
JapaneseCardNames[CardNames.FARM] = new TranslatedCardName("農場");
JapaneseCardNames[CardNames.IRONWORKS] = new TranslatedCardName("鉄工所");
JapaneseCardNames[CardNames.LURKER] = new TranslatedCardName("待ち伏せ");
JapaneseCardNames[CardNames.MASQUERADE] = new TranslatedCardName("仮面舞踏会");
JapaneseCardNames[CardNames.MILL] = new TranslatedCardName("風車");
JapaneseCardNames[CardNames.MINING_VILLAGE] = new TranslatedCardName("鉱山の村");
JapaneseCardNames[CardNames.MINION] = new TranslatedCardName("寵臣");
JapaneseCardNames[CardNames.NOBLES] = new TranslatedCardName("貴族");
JapaneseCardNames[CardNames.PATROL] = new TranslatedCardName("パトロール");
JapaneseCardNames[CardNames.PAWN] = new TranslatedCardName("手先");
JapaneseCardNames[CardNames.REPLACE] = new TranslatedCardName("身代わり");
JapaneseCardNames[CardNames.SECRET_PASSAGE] = new TranslatedCardName("隠し通路");
JapaneseCardNames[CardNames.SHANTY_TOWN] = new TranslatedCardName("貧民街");
JapaneseCardNames[CardNames.STEWARD] = new TranslatedCardName("執事");
JapaneseCardNames[CardNames.SWINDLER] = new TranslatedCardName("詐欺師");
JapaneseCardNames[CardNames.TORTURER] = new TranslatedCardName("拷問人");
JapaneseCardNames[CardNames.TRADING_POST] = new TranslatedCardName("交易場");
JapaneseCardNames[CardNames.UPGRADE] = new TranslatedCardName("改良");
JapaneseCardNames[CardNames.WISHING_WELL] = new TranslatedCardName("願いの井戸");

JapaneseCardNames[CardNames.AMBASSADOR] = new TranslatedCardName("大使");
JapaneseCardNames[CardNames.BAZAAR] = new TranslatedCardName("バザー");
JapaneseCardNames[CardNames.CARAVAN] = new TranslatedCardName("隊商");
JapaneseCardNames[CardNames.CUTPURSE] = new TranslatedCardName("巾着切り");
JapaneseCardNames[CardNames.EMBARGO] = new TranslatedCardName("抑留");
JapaneseCardNames[CardNames.EXPLORER] = new TranslatedCardName("探検家");
JapaneseCardNames[CardNames.FISHING_VILLAGE] = new TranslatedCardName("漁村");
JapaneseCardNames[CardNames.GHOST_SHIP] = new TranslatedCardName("幽霊船");
JapaneseCardNames[CardNames.HAVEN] = new TranslatedCardName("停泊所");
JapaneseCardNames[CardNames.ISLAND] = new TranslatedCardName("島");
JapaneseCardNames[CardNames.LIGHTHOUSE] = new TranslatedCardName("灯台");
JapaneseCardNames[CardNames.LOOKOUT] = new TranslatedCardName("見張り");
JapaneseCardNames[CardNames.MERCHANT_SHIP] = new TranslatedCardName("商船");
JapaneseCardNames[CardNames.NATIVE_VILLAGE] = new TranslatedCardName("原住民の村");
JapaneseCardNames[CardNames.NAVIGATOR] = new TranslatedCardName("航海士");
JapaneseCardNames[CardNames.OUTPOST] = new TranslatedCardName("前哨地");
JapaneseCardNames[CardNames.PEARL_DIVER] = new TranslatedCardName("真珠採り");
JapaneseCardNames[CardNames.PIRATE_SHIP] = new TranslatedCardName("海賊船");
JapaneseCardNames[CardNames.SALVAGER] = new TranslatedCardName("引揚水夫");
JapaneseCardNames[CardNames.SEA_HAG] = new TranslatedCardName("海の妖婆");
JapaneseCardNames[CardNames.SMUGGLERS] = new TranslatedCardName("密輸人");
JapaneseCardNames[CardNames.TACTICIAN] = new TranslatedCardName("策士");
JapaneseCardNames[CardNames.TREASURE_MAP] = new TranslatedCardName("宝の地図");
JapaneseCardNames[CardNames.TREASURY] = new TranslatedCardName("宝物庫");
JapaneseCardNames[CardNames.WAREHOUSE] = new TranslatedCardName("倉庫");
JapaneseCardNames[CardNames.WHARF] = new TranslatedCardName("船着場");

JapaneseCardNames[CardNames.ALCHEMIST] = new TranslatedCardName("錬金術師");
JapaneseCardNames[CardNames.APOTHECARY] = new TranslatedCardName("薬師");
JapaneseCardNames[CardNames.APPRENTICE] = new TranslatedCardName("弟子");
JapaneseCardNames[CardNames.FAMILIAR] = new TranslatedCardName("使い魔");
JapaneseCardNames[CardNames.GOLEM] = new TranslatedCardName("ゴーレム");
JapaneseCardNames[CardNames.HERBALIST] = new TranslatedCardName("薬草商");
JapaneseCardNames[CardNames.PHILOSOPHERS_STONE] = new TranslatedCardName("賢者の石");
JapaneseCardNames[CardNames.POSSESSION] = new TranslatedCardName("支配");
JapaneseCardNames[CardNames.POTION] = new TranslatedCardName("ポーション");
JapaneseCardNames[CardNames.SCRYING_POOL] = new TranslatedCardName("念視の泉");
JapaneseCardNames[CardNames.TRANSMUTE] = new TranslatedCardName("変成");
JapaneseCardNames[CardNames.UNIVERSITY] = new TranslatedCardName("大学");
JapaneseCardNames[CardNames.VINEYARD] = new TranslatedCardName("ブドウ園");

JapaneseCardNames[CardNames.BANK] = new TranslatedCardName("銀行");
JapaneseCardNames[CardNames.BISHOP] = new TranslatedCardName("司教");
JapaneseCardNames[CardNames.CITY] = new TranslatedCardName("都市");
JapaneseCardNames[CardNames.COLONY] = new TranslatedCardName("植民地");
JapaneseCardNames[CardNames.CONTRABAND] = new TranslatedCardName("禁制品");
JapaneseCardNames[CardNames.COUNTING_HOUSE] = new TranslatedCardName("会計所");
JapaneseCardNames[CardNames.EXPAND] = new TranslatedCardName("拡張");
JapaneseCardNames[CardNames.FORGE] = new TranslatedCardName("鍛造");
JapaneseCardNames[CardNames.GOONS] = new TranslatedCardName("ならず者");
JapaneseCardNames[CardNames.GRAND_MARKET] = new TranslatedCardName("大市場");
JapaneseCardNames[CardNames.HOARD] = new TranslatedCardName("隠し財産");
JapaneseCardNames[CardNames.KINGS_COURT] = new TranslatedCardName("宮廷");
JapaneseCardNames[CardNames.LOAN] = new TranslatedCardName("借金");
JapaneseCardNames[CardNames.MINT] = new TranslatedCardName("造幣所");
JapaneseCardNames[CardNames.MONUMENT] = new TranslatedCardName("記念碑");
JapaneseCardNames[CardNames.MOUNTEBANK] = new TranslatedCardName("香具師");
JapaneseCardNames[CardNames.PEDDLER] = new TranslatedCardName("行商人");
JapaneseCardNames[CardNames.PLATINUM] = new TranslatedCardName("白金貨");
JapaneseCardNames[CardNames.QUARRY] = new TranslatedCardName("石切場");
JapaneseCardNames[CardNames.RABBLE] = new TranslatedCardName("大衆");
JapaneseCardNames[CardNames.ROYAL_SEAL] = new TranslatedCardName("玉璽");
JapaneseCardNames[CardNames.TALISMAN] = new TranslatedCardName("護符");
JapaneseCardNames[CardNames.TRADE_ROUTE] = new TranslatedCardName("交易路");
JapaneseCardNames[CardNames.VAULT] = new TranslatedCardName("保管庫");
JapaneseCardNames[CardNames.VENTURE] = new TranslatedCardName("投機");
JapaneseCardNames[CardNames.WATCHTOWER] = new TranslatedCardName("望楼");
JapaneseCardNames[CardNames.WORKERS_VILLAGE] = new TranslatedCardName("労働者の村");

JapaneseCardNames[CardNames.BAG_OF_GOLD] = new TranslatedCardName("金貨袋");
JapaneseCardNames[CardNames.DIADEM] = new TranslatedCardName("王冠");
JapaneseCardNames[CardNames.FAIRGROUNDS] = new TranslatedCardName("品評会");
JapaneseCardNames[CardNames.FARMING_VILLAGE] = new TranslatedCardName("農村");
JapaneseCardNames[CardNames.FOLLOWERS] = new TranslatedCardName("郎党");
JapaneseCardNames[CardNames.FORTUNE_TELLER] = new TranslatedCardName("占い師");
JapaneseCardNames[CardNames.HAMLET] = new TranslatedCardName("村落");
JapaneseCardNames[CardNames.HARVEST] = new TranslatedCardName("収穫");
JapaneseCardNames[CardNames.HORN_OF_PLENTY] = new TranslatedCardName("豊穣の角笛");
JapaneseCardNames[CardNames.HORSE_TRADERS] = new TranslatedCardName("馬商人");
JapaneseCardNames[CardNames.HUNTING_PARTY] = new TranslatedCardName("狩猟団");
JapaneseCardNames[CardNames.JESTER] = new TranslatedCardName("道化師");
JapaneseCardNames[CardNames.MENAGERIE] = new TranslatedCardName("移動動物園");
JapaneseCardNames[CardNames.PRINCESS] = new TranslatedCardName("王女");
JapaneseCardNames[CardNames.REMAKE] = new TranslatedCardName("再建");
JapaneseCardNames[CardNames.TOURNAMENT] = new TranslatedCardName("馬上槍試合");
JapaneseCardNames[CardNames.TRUSTY_STEED] = new TranslatedCardName("名馬");
JapaneseCardNames[CardNames.YOUNG_WITCH] = new TranslatedCardName("魔女娘");

JapaneseCardNames[CardNames.PRIZE_PILE] = new TranslatedCardName("褒賞");

JapaneseCardNames[CardNames.BORDER_VILLAGE] = new TranslatedCardName("国境の村");
JapaneseCardNames[CardNames.CACHE] = new TranslatedCardName("埋蔵金");
JapaneseCardNames[CardNames.CARTOGRAPHER] = new TranslatedCardName("地図職人");
JapaneseCardNames[CardNames.CROSSROADS] = new TranslatedCardName("岐路");
JapaneseCardNames[CardNames.DEVELOP] = new TranslatedCardName("開発");
JapaneseCardNames[CardNames.DUCHESS] = new TranslatedCardName("公爵夫人");
JapaneseCardNames[CardNames.EMBASSY] = new TranslatedCardName("大使館");
JapaneseCardNames[CardNames.FARMLAND] = new TranslatedCardName("農地");
JapaneseCardNames[CardNames.FOOLS_GOLD] = new TranslatedCardName("愚者の黄金");
JapaneseCardNames[CardNames.HAGGLER] = new TranslatedCardName("値切り屋");
JapaneseCardNames[CardNames.HIGHWAY] = new TranslatedCardName("街道");
JapaneseCardNames[CardNames.ILL_GOTTEN_GAINS] = new TranslatedCardName("不正利得");
JapaneseCardNames[CardNames.INN] = new TranslatedCardName("宿屋");
JapaneseCardNames[CardNames.JACK_OF_ALL_TRADES] = new TranslatedCardName("よろずや");
JapaneseCardNames[CardNames.MANDARIN] = new TranslatedCardName("官吏");
JapaneseCardNames[CardNames.MARGRAVE] = new TranslatedCardName("辺境伯");
JapaneseCardNames[CardNames.NOBLE_BRIGAND] = new TranslatedCardName("義賊");
JapaneseCardNames[CardNames.NOMAD_CAMP] = new TranslatedCardName("遊牧民の野営地");
JapaneseCardNames[CardNames.OASIS] = new TranslatedCardName("オアシス");
JapaneseCardNames[CardNames.ORACLE] = new TranslatedCardName("神託");
JapaneseCardNames[CardNames.SCHEME] = new TranslatedCardName("画策");
JapaneseCardNames[CardNames.SILK_ROAD] = new TranslatedCardName("シルクロード");
JapaneseCardNames[CardNames.SPICE_MERCHANT] = new TranslatedCardName("香辛料商人");
JapaneseCardNames[CardNames.STABLES] = new TranslatedCardName("厩舎");
JapaneseCardNames[CardNames.TRADER] = new TranslatedCardName("交易人");
JapaneseCardNames[CardNames.TUNNEL] = new TranslatedCardName("坑道");

JapaneseCardNames[CardNames.ABANDONED_MINE] = new TranslatedCardName("廃坑");
JapaneseCardNames[CardNames.ALTAR] = new TranslatedCardName("祭壇");
JapaneseCardNames[CardNames.ARMORY] = new TranslatedCardName("武器庫");
JapaneseCardNames[CardNames.BAND_OF_MISFITS] = new TranslatedCardName("はみだし者");
JapaneseCardNames[CardNames.BANDIT_CAMP] = new TranslatedCardName("山賊の宿営地");
JapaneseCardNames[CardNames.BEGGAR] = new TranslatedCardName("物乞い");
JapaneseCardNames[CardNames.CATACOMBS] = new TranslatedCardName("地下墓所");
JapaneseCardNames[CardNames.COUNT] = new TranslatedCardName("伯爵");
JapaneseCardNames[CardNames.COUNTERFEIT] = new TranslatedCardName("偽造通貨");
JapaneseCardNames[CardNames.CULTIST] = new TranslatedCardName("狂信者");
JapaneseCardNames[CardNames.DAME_ANNA] = new TranslatedCardName("デイム・アンナ");
JapaneseCardNames[CardNames.DAME_JOSEPHINE] = new TranslatedCardName("デイム・ジョセフィーヌ");
JapaneseCardNames[CardNames.DAME_MOLLY] = new TranslatedCardName("デイム・モリー");
JapaneseCardNames[CardNames.DAME_NATALIE] = new TranslatedCardName("デイム・ナタリー");
JapaneseCardNames[CardNames.DAME_SYLVIA] = new TranslatedCardName("デイム・シルビア");
JapaneseCardNames[CardNames.DEATH_CART] = new TranslatedCardName("死の荷車");
JapaneseCardNames[CardNames.FEODUM] = new TranslatedCardName("封土");
JapaneseCardNames[CardNames.FORAGER] = new TranslatedCardName("採集者");
JapaneseCardNames[CardNames.FORTRESS] = new TranslatedCardName("城塞");
JapaneseCardNames[CardNames.GRAVEROBBER] = new TranslatedCardName("墓暴き");
JapaneseCardNames[CardNames.HERMIT] = new TranslatedCardName("隠遁者");
JapaneseCardNames[CardNames.HOVEL] = new TranslatedCardName("納屋");
JapaneseCardNames[CardNames.HUNTING_GROUNDS] = new TranslatedCardName("狩場");
JapaneseCardNames[CardNames.IRONMONGER] = new TranslatedCardName("金物商");
JapaneseCardNames[CardNames.JUNK_DEALER] = new TranslatedCardName("屑屋");
JapaneseCardNames[CardNames.MADMAN] = new TranslatedCardName("狂人");
JapaneseCardNames[CardNames.MARAUDER] = new TranslatedCardName("襲撃者");
JapaneseCardNames[CardNames.MARKET_SQUARE] = new TranslatedCardName("青空市場");
JapaneseCardNames[CardNames.MERCENARY] = new TranslatedCardName("傭兵");
JapaneseCardNames[CardNames.MYSTIC] = new TranslatedCardName("秘術師");
JapaneseCardNames[CardNames.NECROPOLIS] = new TranslatedCardName("共同墓地");
JapaneseCardNames[CardNames.OVERGROWN_ESTATE] = new TranslatedCardName("草茂る屋敷");
JapaneseCardNames[CardNames.PILLAGE] = new TranslatedCardName("略奪");
JapaneseCardNames[CardNames.POOR_HOUSE] = new TranslatedCardName("救貧院");
JapaneseCardNames[CardNames.PROCESSION] = new TranslatedCardName("行進");
JapaneseCardNames[CardNames.RATS] = new TranslatedCardName("ネズミ");
JapaneseCardNames[CardNames.REBUILD] = new TranslatedCardName("建て直し");
JapaneseCardNames[CardNames.ROGUE] = new TranslatedCardName("盗賊");
JapaneseCardNames[CardNames.RUINED_LIBRARY] = new TranslatedCardName("図書館跡地");
JapaneseCardNames[CardNames.RUINED_MARKET] = new TranslatedCardName("市場跡地");
JapaneseCardNames[CardNames.RUINED_VILLAGE] = new TranslatedCardName("廃村");
JapaneseCardNames[CardNames.SAGE] = new TranslatedCardName("賢者");
JapaneseCardNames[CardNames.SCAVENGER] = new TranslatedCardName("ゴミあさり");
JapaneseCardNames[CardNames.SIR_BAILEY] = new TranslatedCardName("サー・ベイリー");
JapaneseCardNames[CardNames.SIR_DESTRY] = new TranslatedCardName("サー・デストリー");
JapaneseCardNames[CardNames.SIR_MARTIN] = new TranslatedCardName("サー・マーチン");
JapaneseCardNames[CardNames.SIR_MICHAEL] = new TranslatedCardName("サー・マイケル");
JapaneseCardNames[CardNames.SIR_VANDER] = new TranslatedCardName("サー・ヴァンデル");
JapaneseCardNames[CardNames.SPOILS] = new TranslatedCardName("略奪品");
JapaneseCardNames[CardNames.SQUIRE] = new TranslatedCardName("従者");
JapaneseCardNames[CardNames.STOREROOM] = new TranslatedCardName("物置");
JapaneseCardNames[CardNames.SURVIVORS] = new TranslatedCardName("生存者");
JapaneseCardNames[CardNames.URCHIN] = new TranslatedCardName("浮浪児");
JapaneseCardNames[CardNames.VAGRANT] = new TranslatedCardName("浮浪者");
JapaneseCardNames[CardNames.WANDERING_MINSTREL] = new TranslatedCardName("吟遊詩人");

JapaneseCardNames[CardNames.RUIN_PILE] = new TranslatedCardName("廃墟");
JapaneseCardNames[CardNames.KNIGHTS] = new TranslatedCardName("騎士");

JapaneseCardNames[CardNames.ADVISOR] = new TranslatedCardName("助言者");
JapaneseCardNames[CardNames.BAKER] = new TranslatedCardName("パン屋");
JapaneseCardNames[CardNames.BUTCHER] = new TranslatedCardName("肉屋");
JapaneseCardNames[CardNames.CANDLESTICK_MAKER] = new TranslatedCardName("蝋燭職人");
JapaneseCardNames[CardNames.DOCTOR] = new TranslatedCardName("医者");
JapaneseCardNames[CardNames.HERALD] = new TranslatedCardName("伝令官");
JapaneseCardNames[CardNames.JOURNEYMAN] = new TranslatedCardName("熟練工");
JapaneseCardNames[CardNames.MASTERPIECE] = new TranslatedCardName("名品");
JapaneseCardNames[CardNames.MERCHANT_GUILD] = new TranslatedCardName("商人ギルド");
JapaneseCardNames[CardNames.PLAZA] = new TranslatedCardName("広場");
JapaneseCardNames[CardNames.SOOTHSAYER] = new TranslatedCardName("予言者");
JapaneseCardNames[CardNames.STONEMASON] = new TranslatedCardName("石工");
JapaneseCardNames[CardNames.TAXMAN] = new TranslatedCardName("収税吏");

JapaneseCardNames[CardNames.AMULET] = new TranslatedCardName("魔除け");
JapaneseCardNames[CardNames.ARTIFICER] = new TranslatedCardName("工匠");
JapaneseCardNames[CardNames.BRIDGE_TROLL] = new TranslatedCardName("橋の下のトロル");
JapaneseCardNames[CardNames.CARAVAN_GUARD] = new TranslatedCardName("隊商の護衛");
JapaneseCardNames[CardNames.CHAMPION] = new TranslatedCardName("チャンピオン");
JapaneseCardNames[CardNames.COIN_OF_THE_REALM] = new TranslatedCardName("法貨");
JapaneseCardNames[CardNames.DISCIPLE] = new TranslatedCardName("門下生");
JapaneseCardNames[CardNames.DISTANT_LANDS] = new TranslatedCardName("遠隔地");
JapaneseCardNames[CardNames.DUNGEON] = new TranslatedCardName("地下牢");
JapaneseCardNames[CardNames.DUPLICATE] = new TranslatedCardName("複製");
JapaneseCardNames[CardNames.FUGITIVE] = new TranslatedCardName("脱走兵");
JapaneseCardNames[CardNames.GEAR] = new TranslatedCardName("道具");
JapaneseCardNames[CardNames.GIANT] = new TranslatedCardName("巨人");
JapaneseCardNames[CardNames.GUIDE] = new TranslatedCardName("案内人");
JapaneseCardNames[CardNames.HAUNTED_WOODS] = new TranslatedCardName("呪いの森");
JapaneseCardNames[CardNames.HERO] = new TranslatedCardName("ヒーロー");
JapaneseCardNames[CardNames.HIRELING] = new TranslatedCardName("雇人");
JapaneseCardNames[CardNames.LOST_CITY] = new TranslatedCardName("失われし都市");
JapaneseCardNames[CardNames.MAGPIE] = new TranslatedCardName("カササギ");
JapaneseCardNames[CardNames.MESSENGER] = new TranslatedCardName("使者");
JapaneseCardNames[CardNames.MISER] = new TranslatedCardName("守銭奴");
JapaneseCardNames[CardNames.PAGE] = new TranslatedCardName("騎士見習い");
JapaneseCardNames[CardNames.PEASANT] = new TranslatedCardName("農民");
JapaneseCardNames[CardNames.PORT] = new TranslatedCardName("港町");
JapaneseCardNames[CardNames.RANGER] = new TranslatedCardName("山守");
JapaneseCardNames[CardNames.RATCATCHER] = new TranslatedCardName("鼠取り");
JapaneseCardNames[CardNames.RAZE] = new TranslatedCardName("倒壊");
JapaneseCardNames[CardNames.RELIC] = new TranslatedCardName("遺物");
JapaneseCardNames[CardNames.ROYAL_CARRIAGE] = new TranslatedCardName("御料車");
JapaneseCardNames[CardNames.SOLDIER] = new TranslatedCardName("兵士");
JapaneseCardNames[CardNames.STORYTELLER] = new TranslatedCardName("語り部");
JapaneseCardNames[CardNames.SWAMP_HAG] = new TranslatedCardName("沼の妖婆");
JapaneseCardNames[CardNames.TEACHER] = new TranslatedCardName("教師");
JapaneseCardNames[CardNames.TRANSMOGRIFY] = new TranslatedCardName("変容");
JapaneseCardNames[CardNames.TREASURE_TROVE] = new TranslatedCardName("掘出物");
JapaneseCardNames[CardNames.TREASURE_HUNTER] = new TranslatedCardName("トレジャーハンター");
JapaneseCardNames[CardNames.WARRIOR] = new TranslatedCardName("ウォリアー");
JapaneseCardNames[CardNames.WINE_MERCHANT] = new TranslatedCardName("ワイン商");

JapaneseCardNames[CardNames.ALMS] = new TranslatedCardName("施し");
JapaneseCardNames[CardNames.BALL] = new TranslatedCardName("舞踏会");
JapaneseCardNames[CardNames.BONFIRE] = new TranslatedCardName("焚火");
JapaneseCardNames[CardNames.BORROW] = new TranslatedCardName("借入");
JapaneseCardNames[CardNames.EXPEDITION] = new TranslatedCardName("探検");
JapaneseCardNames[CardNames.FERRY] = new TranslatedCardName("渡し船");
JapaneseCardNames[CardNames.INHERITANCE] = new TranslatedCardName("相続");
JapaneseCardNames[CardNames.LOST_ARTS] = new TranslatedCardName("失われた技術");
JapaneseCardNames[CardNames.MISSION] = new TranslatedCardName("使節団");
JapaneseCardNames[CardNames.PATHFINDING] = new TranslatedCardName("誘導");
JapaneseCardNames[CardNames.PILGRIMAGE] = new TranslatedCardName("巡礼");
JapaneseCardNames[CardNames.PLAN] = new TranslatedCardName("立案");
JapaneseCardNames[CardNames.QUEST] = new TranslatedCardName("探索");
JapaneseCardNames[CardNames.RAID] = new TranslatedCardName("奇襲");
JapaneseCardNames[CardNames.SAVE] = new TranslatedCardName("保存");
JapaneseCardNames[CardNames.SCOUTING_PARTY] = new TranslatedCardName("偵察隊");
JapaneseCardNames[CardNames.SEAWAY] = new TranslatedCardName("海路");
JapaneseCardNames[CardNames.TRADE] = new TranslatedCardName("交易");
JapaneseCardNames[CardNames.TRAINING] = new TranslatedCardName("鍛錬");
JapaneseCardNames[CardNames.TRAVELLING_FAIR] = new TranslatedCardName("移動遊園地");

JapaneseCardNames[CardNames.ARCHIVE] = new TranslatedCardName("資料庫");
JapaneseCardNames[CardNames.BUSTLING_VILLAGE] = new TranslatedCardName("騒がしい村");
JapaneseCardNames[CardNames.CAPITAL] = new TranslatedCardName("元手");
JapaneseCardNames[CardNames.CATAPULT] = new TranslatedCardName("投石機");
JapaneseCardNames[CardNames.CHARIOT_RACE] = new TranslatedCardName("戦車競走");
JapaneseCardNames[CardNames.CHARM] = new TranslatedCardName("御守り");
JapaneseCardNames[CardNames.CITY_QUARTER] = new TranslatedCardName("市街");
JapaneseCardNames[CardNames.CROWN] = new TranslatedCardName("冠");
JapaneseCardNames[CardNames.CRUMBLING_CASTLE] = new TranslatedCardName("崩れた城");
JapaneseCardNames[CardNames.EMPORIUM] = new TranslatedCardName("エンポリウム");
JapaneseCardNames[CardNames.ENCAMPMENT] = new TranslatedCardName("陣地");
JapaneseCardNames[CardNames.ENCHANTRESS] = new TranslatedCardName("女魔術師");
JapaneseCardNames[CardNames.ENGINEER] = new TranslatedCardName("技術者");
JapaneseCardNames[CardNames.FARMERS_MARKET] = new TranslatedCardName("農家の市場");
JapaneseCardNames[CardNames.FORTUNE] = new TranslatedCardName("大金");
JapaneseCardNames[CardNames.FORUM] = new TranslatedCardName("公共広場");
JapaneseCardNames[CardNames.GLADIATOR] = new TranslatedCardName("剣闘士");
JapaneseCardNames[CardNames.GRAND_CASTLE] = new TranslatedCardName("壮大な城");
JapaneseCardNames[CardNames.GROUNDSKEEPER] = new TranslatedCardName("庭師");
JapaneseCardNames[CardNames.HAUNTED_CASTLE] = new TranslatedCardName("幽霊城");
JapaneseCardNames[CardNames.HUMBLE_CASTLE] = new TranslatedCardName("粗末な城");
JapaneseCardNames[CardNames.KINGS_CASTLE] = new TranslatedCardName("王城");
JapaneseCardNames[CardNames.LEGIONARY] = new TranslatedCardName("軍団兵");
JapaneseCardNames[CardNames.OPULENT_CASTLE] = new TranslatedCardName("華やかな城");
JapaneseCardNames[CardNames.OVERLORD] = new TranslatedCardName("大君主");
JapaneseCardNames[CardNames.PATRICIAN] = new TranslatedCardName("パトリキ");
JapaneseCardNames[CardNames.PLUNDER] = new TranslatedCardName("鹵獲品");
JapaneseCardNames[CardNames.ROCKS] = new TranslatedCardName("石");
JapaneseCardNames[CardNames.ROYAL_BLACKSMITH] = new TranslatedCardName("王室の鍛冶屋");
JapaneseCardNames[CardNames.SACRIFICE] = new TranslatedCardName("生贄");
JapaneseCardNames[CardNames.SETTLERS] = new TranslatedCardName("開拓者");
JapaneseCardNames[CardNames.SMALL_CASTLE] = new TranslatedCardName("小さい城");
JapaneseCardNames[CardNames.SPRAWLING_CASTLE] = new TranslatedCardName("広大な城");
JapaneseCardNames[CardNames.TEMPLE] = new TranslatedCardName("神殿");
JapaneseCardNames[CardNames.VILLA] = new TranslatedCardName("ヴィラ");
JapaneseCardNames[CardNames.WILD_HUNT] = new TranslatedCardName("ワイルドハント");

JapaneseCardNames[CardNames.CASTLES] = new TranslatedCardName("城");

JapaneseCardNames[CardNames.ADVANCE] = new TranslatedCardName("昇進");
JapaneseCardNames[CardNames.ANNEX] = new TranslatedCardName("併合");
JapaneseCardNames[CardNames.BANQUET] = new TranslatedCardName("宴会");
JapaneseCardNames[CardNames.CONQUEST] = new TranslatedCardName("征服");
JapaneseCardNames[CardNames.DELVE] = new TranslatedCardName("掘進");
JapaneseCardNames[CardNames.DOMINATE] = new TranslatedCardName("制圧");
JapaneseCardNames[CardNames.DONATE] = new TranslatedCardName("寄付");
JapaneseCardNames[CardNames.RITUAL] = new TranslatedCardName("儀式");
JapaneseCardNames[CardNames.SALT_THE_EARTH] = new TranslatedCardName("大地への塩まき");
JapaneseCardNames[CardNames.TAX] = new TranslatedCardName("徴税");
JapaneseCardNames[CardNames.TRIUMPH] = new TranslatedCardName("凱旋");
JapaneseCardNames[CardNames.WEDDING] = new TranslatedCardName("結婚式");
JapaneseCardNames[CardNames.WINDFALL] = new TranslatedCardName("意外な授かり物");

JapaneseCardNames[CardNames.AQUEDUCT] = new TranslatedCardName("水道橋");
JapaneseCardNames[CardNames.ARENA] = new TranslatedCardName("闘技場");
JapaneseCardNames[CardNames.BANDIT_FORT] = new TranslatedCardName("山賊の砦");
JapaneseCardNames[CardNames.BASILICA] = new TranslatedCardName("公会堂");
JapaneseCardNames[CardNames.BATHS] = new TranslatedCardName("浴場");
JapaneseCardNames[CardNames.BATTLEFIELD] = new TranslatedCardName("戦場");
JapaneseCardNames[CardNames.COLONNADE] = new TranslatedCardName("列柱");
JapaneseCardNames[CardNames.DEFILED_SHRINE] = new TranslatedCardName("汚された神殿");
JapaneseCardNames[CardNames.FOUNTAIN] = new TranslatedCardName("噴水");
JapaneseCardNames[CardNames.KEEP] = new TranslatedCardName("砦");
JapaneseCardNames[CardNames.LABYRINTH] = new TranslatedCardName("迷宮");
JapaneseCardNames[CardNames.MOUNTAIN_PASS] = new TranslatedCardName("峠");
JapaneseCardNames[CardNames.MUSEUM] = new TranslatedCardName("博物館");
JapaneseCardNames[CardNames.OBELISK] = new TranslatedCardName("オベリスク");
JapaneseCardNames[CardNames.ORCHARD] = new TranslatedCardName("果樹園");
JapaneseCardNames[CardNames.PALACE] = new TranslatedCardName("宮殿");
JapaneseCardNames[CardNames.TOMB] = new TranslatedCardName("墓標");
JapaneseCardNames[CardNames.TOWER] = new TranslatedCardName("塔");
JapaneseCardNames[CardNames.TRIUMPHAL_ARCH] = new TranslatedCardName("凱旋門");
JapaneseCardNames[CardNames.WALL] = new TranslatedCardName("壁");
JapaneseCardNames[CardNames.WOLF_DEN] = new TranslatedCardName("狼の巣");

JapaneseCardNames[CardNames.BARD] = new TranslatedCardName("詩人");
JapaneseCardNames[CardNames.BAT] = new TranslatedCardName("コウモリ");
JapaneseCardNames[CardNames.BLESSED_VILLAGE] = new TranslatedCardName("恵みの村");
JapaneseCardNames[CardNames.CEMETERY] = new TranslatedCardName("墓地");
JapaneseCardNames[CardNames.CHANGELING] = new TranslatedCardName("取り替え子");
JapaneseCardNames[CardNames.COBBLER] = new TranslatedCardName("カブラー");
JapaneseCardNames[CardNames.CONCLAVE] = new TranslatedCardName("コンクラーベ");
JapaneseCardNames[CardNames.CRYPT] = new TranslatedCardName("納骨堂");
JapaneseCardNames[CardNames.CURSED_GOLD] = new TranslatedCardName("呪われた金貨");
JapaneseCardNames[CardNames.CURSED_VILLAGE] = new TranslatedCardName("呪われた村");
JapaneseCardNames[CardNames.DEN_OF_SIN] = new TranslatedCardName("悪人のアジト");
JapaneseCardNames[CardNames.DEVILS_WORKSHOP] = new TranslatedCardName("悪魔の工房");
JapaneseCardNames[CardNames.DRUID] = new TranslatedCardName("ドルイド");
JapaneseCardNames[CardNames.EXORCIST] = new TranslatedCardName("悪魔祓い");
JapaneseCardNames[CardNames.FAITHFUL_HOUND] = new TranslatedCardName("忠犬");
JapaneseCardNames[CardNames.FOOL] = new TranslatedCardName("愚者");
JapaneseCardNames[CardNames.GHOST] = new TranslatedCardName("幽霊");
JapaneseCardNames[CardNames.GHOST_TOWN] = new TranslatedCardName("ゴーストタウン");
JapaneseCardNames[CardNames.GOAT] = new TranslatedCardName("ヤギ");
JapaneseCardNames[CardNames.GUARDIAN] = new TranslatedCardName("守護者");
JapaneseCardNames[CardNames.HAUNTED_MIRROR] = new TranslatedCardName("呪いの鏡");
JapaneseCardNames[CardNames.IDOL] = new TranslatedCardName("偶像");
JapaneseCardNames[CardNames.IMP] = new TranslatedCardName("インプ");
JapaneseCardNames[CardNames.LEPRECHAUN] = new TranslatedCardName("レプラコーン");
JapaneseCardNames[CardNames.LUCKY_COIN] = new TranslatedCardName("幸運のコイン");
JapaneseCardNames[CardNames.MAGIC_LAMP] = new TranslatedCardName("魔法のランプ");
JapaneseCardNames[CardNames.MONASTERY] = new TranslatedCardName("修道院");
JapaneseCardNames[CardNames.NECROMANCER] = new TranslatedCardName("ネクロマンサー");
JapaneseCardNames[CardNames.NIGHT_WATCHMAN] = new TranslatedCardName("夜警");
JapaneseCardNames[CardNames.PASTURE] = new TranslatedCardName("牧草地");
JapaneseCardNames[CardNames.PIXIE] = new TranslatedCardName("ピクシー");
JapaneseCardNames[CardNames.POOKA] = new TranslatedCardName("プーカ");
JapaneseCardNames[CardNames.POUCH] = new TranslatedCardName("革袋");
JapaneseCardNames[CardNames.RAIDER] = new TranslatedCardName("夜襲");
JapaneseCardNames[CardNames.SACRED_GROVE] = new TranslatedCardName("聖なる木立ち");
JapaneseCardNames[CardNames.SECRET_CAVE] = new TranslatedCardName("秘密の洞窟");
JapaneseCardNames[CardNames.SHEPHERD] = new TranslatedCardName("羊飼い");
JapaneseCardNames[CardNames.SKULK] = new TranslatedCardName("暗躍者");
JapaneseCardNames[CardNames.TORMENTOR] = new TranslatedCardName("迫害者");
JapaneseCardNames[CardNames.TRACKER] = new TranslatedCardName("追跡者");
JapaneseCardNames[CardNames.TRAGIC_HERO] = new TranslatedCardName("悲劇のヒーロー");
JapaneseCardNames[CardNames.VAMPIRE] = new TranslatedCardName("吸血鬼");
JapaneseCardNames[CardNames.WEREWOLF] = new TranslatedCardName("人狼");
JapaneseCardNames[CardNames.WILL_O_WISP] = new TranslatedCardName("ウィル・オ・ウィスプ");
JapaneseCardNames[CardNames.WISH] = new TranslatedCardName("願い");
JapaneseCardNames[CardNames.ZOMBIE_APPRENTICE] = new TranslatedCardName("ゾンビの弟子");
JapaneseCardNames[CardNames.ZOMBIE_MASON] = new TranslatedCardName("ゾンビの石工");
JapaneseCardNames[CardNames.ZOMBIE_SPY] = new TranslatedCardName("ゾンビの密偵");

JapaneseCardNames[CardNames.BOON_DRAWPILE] = new TranslatedCardName("祝福の山札");
JapaneseCardNames[CardNames.BOON_DISCARDPILE] = new TranslatedCardName("祝福の捨て札");
JapaneseCardNames[CardNames.THE_EARTHS_GIFT] = new TranslatedCardName("大地の恵み");
JapaneseCardNames[CardNames.THE_FIELDS_GIFT] = new TranslatedCardName("田畑の恵み");
JapaneseCardNames[CardNames.THE_FLAMES_GIFT] = new TranslatedCardName("炎の恵み");
JapaneseCardNames[CardNames.THE_FORESTS_GIFT] = new TranslatedCardName("森の恵み");
JapaneseCardNames[CardNames.THE_MOONS_GIFT] = new TranslatedCardName("月の恵み");
JapaneseCardNames[CardNames.THE_MOUNTAINS_GIFT] = new TranslatedCardName("山の恵み");
JapaneseCardNames[CardNames.THE_RIVERS_GIFT] = new TranslatedCardName("川の恵み");
JapaneseCardNames[CardNames.THE_SEAS_GIFT] = new TranslatedCardName("海の恵み");
JapaneseCardNames[CardNames.THE_SKYS_GIFT] = new TranslatedCardName("空の恵み");
JapaneseCardNames[CardNames.THE_SUNS_GIFT] = new TranslatedCardName("太陽の恵み");
JapaneseCardNames[CardNames.THE_SWAMPS_GIFT] = new TranslatedCardName("沼の恵み");
JapaneseCardNames[CardNames.THE_WINDS_GIFT] = new TranslatedCardName("風の恵み");

JapaneseCardNames[CardNames.HEX_DRAWPILE] = new TranslatedCardName("呪詛の山札");
JapaneseCardNames[CardNames.HEX_DISCARDPILE] = new TranslatedCardName("呪詛の捨て札");
JapaneseCardNames[CardNames.BAD_OMENS] = new TranslatedCardName("凶兆");
JapaneseCardNames[CardNames.DELUSION] = new TranslatedCardName("幻惑");
JapaneseCardNames[CardNames.ENVY] = new TranslatedCardName("羨望");
JapaneseCardNames[CardNames.FAMINE] = new TranslatedCardName("飢饉");
JapaneseCardNames[CardNames.FEAR] = new TranslatedCardName("恐怖");
JapaneseCardNames[CardNames.GREED] = new TranslatedCardName("貪欲");
JapaneseCardNames[CardNames.HAUNTING] = new TranslatedCardName("憑依");
JapaneseCardNames[CardNames.LOCUSTS] = new TranslatedCardName("蝗害");
JapaneseCardNames[CardNames.MISERY] = new TranslatedCardName("みじめな生活");
JapaneseCardNames[CardNames.PLAGUE] = new TranslatedCardName("疫病");
JapaneseCardNames[CardNames.POVERTY] = new TranslatedCardName("貧困");
JapaneseCardNames[CardNames.WAR] = new TranslatedCardName("戦争");

JapaneseCardNames[CardNames.DELUDED] = new TranslatedCardName("錯乱");
JapaneseCardNames[CardNames.ENVIOUS] = new TranslatedCardName("嫉妬");
JapaneseCardNames[CardNames.LOST_IN_THE_WOODS] = new TranslatedCardName("森の迷子");
JapaneseCardNames[CardNames.MISERABLE] = new TranslatedCardName("生活苦");
JapaneseCardNames[CardNames.TWICE_MISERABLE] = new TranslatedCardName("二重苦");

JapaneseCardNames[CardNames.ACTING_TROUPE] = new TranslatedCardName("劇団");
JapaneseCardNames[CardNames.BORDER_GUARD] = new TranslatedCardName("国境警備隊");
JapaneseCardNames[CardNames.CARGO_SHIP] = new TranslatedCardName("貨物船");
JapaneseCardNames[CardNames.DUCAT] = new TranslatedCardName("ドゥカート金貨");
JapaneseCardNames[CardNames.EXPERIMENT] = new TranslatedCardName("実験");
JapaneseCardNames[CardNames.FLAG_BEARER] = new TranslatedCardName("旗手");
JapaneseCardNames[CardNames.HIDEOUT] = new TranslatedCardName("根城");
JapaneseCardNames[CardNames.IMPROVE] = new TranslatedCardName("増築");
JapaneseCardNames[CardNames.INVENTOR] = new TranslatedCardName("発明家");
JapaneseCardNames[CardNames.LACKEYS] = new TranslatedCardName("追従者");
JapaneseCardNames[CardNames.MOUNTAIN_VILLAGE] = new TranslatedCardName("山村");
JapaneseCardNames[CardNames.OLD_WITCH] = new TranslatedCardName("老魔女");
JapaneseCardNames[CardNames.PATRON] = new TranslatedCardName("パトロン");
JapaneseCardNames[CardNames.PRIEST] = new TranslatedCardName("司祭");
JapaneseCardNames[CardNames.RECRUITER] = new TranslatedCardName("徴募官");
JapaneseCardNames[CardNames.RESEARCH] = new TranslatedCardName("研究");
JapaneseCardNames[CardNames.SCEPTER] = new TranslatedCardName("王笏");
JapaneseCardNames[CardNames.SCHOLAR] = new TranslatedCardName("学者");
JapaneseCardNames[CardNames.SCULPTOR] = new TranslatedCardName("彫刻家");
JapaneseCardNames[CardNames.SEER] = new TranslatedCardName("先見者");
JapaneseCardNames[CardNames.SILK_MERCHANT] = new TranslatedCardName("絹商人");
JapaneseCardNames[CardNames.SPICES] = new TranslatedCardName("香辛料");
JapaneseCardNames[CardNames.SWASHBUCKLER] = new TranslatedCardName("剣客");
JapaneseCardNames[CardNames.TREASURER] = new TranslatedCardName("出納官");
JapaneseCardNames[CardNames.VILLAIN] = new TranslatedCardName("悪党");

JapaneseCardNames[CardNames.FLAG] = new TranslatedCardName("旗");
JapaneseCardNames[CardNames.HORN] = new TranslatedCardName("角笛");
JapaneseCardNames[CardNames.KEY] = new TranslatedCardName("鍵");
JapaneseCardNames[CardNames.LANTERN] = new TranslatedCardName("ランタン");
JapaneseCardNames[CardNames.TREASURE_CHEST] = new TranslatedCardName("宝箱");

JapaneseCardNames[CardNames.ACADEMY] = new TranslatedCardName("学園");
JapaneseCardNames[CardNames.BARRACKS] = new TranslatedCardName("兵舎");
JapaneseCardNames[CardNames.CANAL] = new TranslatedCardName("運河");
JapaneseCardNames[CardNames.CAPITALISM] = new TranslatedCardName("資本主義");
JapaneseCardNames[CardNames.CATHEDRAL] = new TranslatedCardName("大聖堂");
JapaneseCardNames[CardNames.CITADEL] = new TranslatedCardName("山砦");
JapaneseCardNames[CardNames.CITY_GATE] = new TranslatedCardName("城門");
JapaneseCardNames[CardNames.CROP_ROTATION] = new TranslatedCardName("輪作");
JapaneseCardNames[CardNames.EXPLORATION] = new TranslatedCardName("探査");
JapaneseCardNames[CardNames.FAIR] = new TranslatedCardName("縁日");
JapaneseCardNames[CardNames.FLEET] = new TranslatedCardName("艦隊");
JapaneseCardNames[CardNames.GUILDHALL] = new TranslatedCardName("ギルド集会所");
JapaneseCardNames[CardNames.INNOVATION] = new TranslatedCardName("技術革新");
JapaneseCardNames[CardNames.PAGEANT] = new TranslatedCardName("野外劇");
JapaneseCardNames[CardNames.PIAZZA] = new TranslatedCardName("ピアッツァ");
JapaneseCardNames[CardNames.ROAD_NETWORK] = new TranslatedCardName("道路網");
JapaneseCardNames[CardNames.SEWERS] = new TranslatedCardName("下水道");
JapaneseCardNames[CardNames.SILOS] = new TranslatedCardName("サイロ");
JapaneseCardNames[CardNames.SINISTER_PLOT] = new TranslatedCardName("悪巧み");
JapaneseCardNames[CardNames.STAR_CHART] = new TranslatedCardName("星図");

JapaneseCardNames[CardNames.ANIMAL_FAIR] = new TranslatedCardName("動物見本市");
JapaneseCardNames[CardNames.BARGE] = new TranslatedCardName("艀");
JapaneseCardNames[CardNames.BLACK_CAT] = new TranslatedCardName("黒猫");
JapaneseCardNames[CardNames.BOUNTY_HUNTER] = new TranslatedCardName("賞金稼ぎ");
JapaneseCardNames[CardNames.CAMEL_TRAIN] = new TranslatedCardName("ラクダの隊列");
JapaneseCardNames[CardNames.CARDINAL] = new TranslatedCardName("枢機卿");
JapaneseCardNames[CardNames.CAVALRY] = new TranslatedCardName("騎兵隊");
JapaneseCardNames[CardNames.COVEN] = new TranslatedCardName("魔女の集会");
JapaneseCardNames[CardNames.DESTRIER] = new TranslatedCardName("デストリエ");
JapaneseCardNames[CardNames.DISPLACE] = new TranslatedCardName("強制退去");
JapaneseCardNames[CardNames.FALCONER] = new TranslatedCardName("鷹匠");
JapaneseCardNames[CardNames.FISHERMAN] = new TranslatedCardName("漁師");
JapaneseCardNames[CardNames.GATEKEEPER] = new TranslatedCardName("門番");
JapaneseCardNames[CardNames.GOATHERD] = new TranslatedCardName("ヤギ飼い");
JapaneseCardNames[CardNames.GROOM] = new TranslatedCardName("馬丁");
JapaneseCardNames[CardNames.HORSE] = new TranslatedCardName("馬");
JapaneseCardNames[CardNames.HOSTELRY] = new TranslatedCardName("旅籠");
JapaneseCardNames[CardNames.HUNTING_LODGE] = new TranslatedCardName("狩猟小屋");
JapaneseCardNames[CardNames.KILN] = new TranslatedCardName("炉");
JapaneseCardNames[CardNames.LIVERY] = new TranslatedCardName("貸し馬屋");
JapaneseCardNames[CardNames.MASTERMIND] = new TranslatedCardName("首謀者");
JapaneseCardNames[CardNames.PADDOCK] = new TranslatedCardName("パドック");
JapaneseCardNames[CardNames.SANCTUARY] = new TranslatedCardName("聖域");
JapaneseCardNames[CardNames.SCRAP] = new TranslatedCardName("がらくた");
JapaneseCardNames[CardNames.SHEEPDOG] = new TranslatedCardName("牧羊犬");
JapaneseCardNames[CardNames.SLEIGH] = new TranslatedCardName("そり");
JapaneseCardNames[CardNames.SNOWY_VILLAGE] = new TranslatedCardName("雪深い村");
JapaneseCardNames[CardNames.STOCKPILE] = new TranslatedCardName("備蓄品");
JapaneseCardNames[CardNames.SUPPLIES] = new TranslatedCardName("配給品");
JapaneseCardNames[CardNames.VILLAGE_GREEN] = new TranslatedCardName("村有緑地");
JapaneseCardNames[CardNames.WAYFARER] = new TranslatedCardName("行人");

JapaneseCardNames[CardNames.ALLIANCE] = new TranslatedCardName("同盟");
JapaneseCardNames[CardNames.BANISH] = new TranslatedCardName("放逐");
JapaneseCardNames[CardNames.BARGAIN] = new TranslatedCardName("特価品");
JapaneseCardNames[CardNames.COMMERCE] = new TranslatedCardName("商売");
JapaneseCardNames[CardNames.DELAY] = new TranslatedCardName("遅延");
JapaneseCardNames[CardNames.DEMAND] = new TranslatedCardName("要求");
JapaneseCardNames[CardNames.DESPERATION] = new TranslatedCardName("絶望");
JapaneseCardNames[CardNames.ENCLAVE] = new TranslatedCardName("包領");
JapaneseCardNames[CardNames.ENHANCE] = new TranslatedCardName("増大");
JapaneseCardNames[CardNames.GAMBLE] = new TranslatedCardName("博打");
JapaneseCardNames[CardNames.INVEST] = new TranslatedCardName("投資");
JapaneseCardNames[CardNames.MARCH] = new TranslatedCardName("進軍");
JapaneseCardNames[CardNames.POPULATE] = new TranslatedCardName("植民");
JapaneseCardNames[CardNames.PURSUE] = new TranslatedCardName("追求");
JapaneseCardNames[CardNames.REAP] = new TranslatedCardName("刈り入れ");
JapaneseCardNames[CardNames.RIDE] = new TranslatedCardName("乗馬");
JapaneseCardNames[CardNames.SEIZE_THE_DAY] = new TranslatedCardName("今を生きる");
JapaneseCardNames[CardNames.STAMPEDE] = new TranslatedCardName("暴走");
JapaneseCardNames[CardNames.TOIL] = new TranslatedCardName("苦労");
JapaneseCardNames[CardNames.TRANSPORT] = new TranslatedCardName("輸送");

JapaneseCardNames[CardNames.WAY_OF_THE_BUTTERFLY] = new TranslatedCardName("チョウの習性");
JapaneseCardNames[CardNames.WAY_OF_THE_CAMEL] = new TranslatedCardName("ラクダの習性");
JapaneseCardNames[CardNames.WAY_OF_THE_CHAMELEON] = new TranslatedCardName("カメレオンの習性");
JapaneseCardNames[CardNames.WAY_OF_THE_FROG] = new TranslatedCardName("カエルの習性");
JapaneseCardNames[CardNames.WAY_OF_THE_GOAT] = new TranslatedCardName("ヤギの習性");
JapaneseCardNames[CardNames.WAY_OF_THE_HORSE] = new TranslatedCardName("馬の習性");
JapaneseCardNames[CardNames.WAY_OF_THE_MOLE] = new TranslatedCardName("モグラの習性");
JapaneseCardNames[CardNames.WAY_OF_THE_MONKEY] = new TranslatedCardName("サルの習性");
JapaneseCardNames[CardNames.WAY_OF_THE_MOUSE] = new TranslatedCardName("ハツカネズミの習性");
JapaneseCardNames[CardNames.WAY_OF_THE_MULE] = new TranslatedCardName("ラバの習性");
JapaneseCardNames[CardNames.WAY_OF_THE_OTTER] = new TranslatedCardName("カワウソの習性");
JapaneseCardNames[CardNames.WAY_OF_THE_OWL] = new TranslatedCardName("フクロウの習性");
JapaneseCardNames[CardNames.WAY_OF_THE_OX] = new TranslatedCardName("雄牛の習性");
JapaneseCardNames[CardNames.WAY_OF_THE_PIG] = new TranslatedCardName("豚の習性");
JapaneseCardNames[CardNames.WAY_OF_THE_RAT] = new TranslatedCardName("ドブネズミの習性");
JapaneseCardNames[CardNames.WAY_OF_THE_SEAL] = new TranslatedCardName("アザラシの習性");
JapaneseCardNames[CardNames.WAY_OF_THE_SHEEP] = new TranslatedCardName("羊の習性");
JapaneseCardNames[CardNames.WAY_OF_THE_SQUIRREL] = new TranslatedCardName("リスの習性");
JapaneseCardNames[CardNames.WAY_OF_THE_TURTLE] = new TranslatedCardName("ウミガメの習性");
JapaneseCardNames[CardNames.WAY_OF_THE_WORM] = new TranslatedCardName("ミミズの習性");

JapaneseCardNames[CardNames.CARD_OF_THE_BUTTERFLY] = "";
JapaneseCardNames[CardNames.CARD_OF_THE_CAMEL] = "";
JapaneseCardNames[CardNames.CARD_OF_THE_CHAMELEON] = "";
JapaneseCardNames[CardNames.CARD_OF_THE_FROG] = "";
JapaneseCardNames[CardNames.CARD_OF_THE_GOAT] = "";
JapaneseCardNames[CardNames.CARD_OF_THE_HORSE] = "";
JapaneseCardNames[CardNames.CARD_OF_THE_MOLE] = "";
JapaneseCardNames[CardNames.CARD_OF_THE_MONKEY] = "";
JapaneseCardNames[CardNames.CARD_OF_THE_MOUSE] = "";
JapaneseCardNames[CardNames.CARD_OF_THE_MULE] = "";
JapaneseCardNames[CardNames.CARD_OF_THE_OTTER] = "";
JapaneseCardNames[CardNames.CARD_OF_THE_OWL] = "";
JapaneseCardNames[CardNames.CARD_OF_THE_OX] = "";
JapaneseCardNames[CardNames.CARD_OF_THE_PIG] = "";
JapaneseCardNames[CardNames.CARD_OF_THE_RAT] = "";
JapaneseCardNames[CardNames.CARD_OF_THE_SEAL] = "";
JapaneseCardNames[CardNames.CARD_OF_THE_SHEEP] = "";
JapaneseCardNames[CardNames.CARD_OF_THE_SQUIRREL] = "";
JapaneseCardNames[CardNames.CARD_OF_THE_TURTLE] = "";
JapaneseCardNames[CardNames.CARD_OF_THE_WORM] = "";

JapaneseCardNames[CardNames.AVANTO] = new TranslatedCardName("アヴァント");
JapaneseCardNames[CardNames.BLACK_MARKET] = new TranslatedCardName("闇市場");
JapaneseCardNames[CardNames.DISMANTLE] = new TranslatedCardName("取り壊し");
JapaneseCardNames[CardNames.CAPTAIN] = new TranslatedCardName("船長");
JapaneseCardNames[CardNames.CHURCH] = new TranslatedCardName("教会");
JapaneseCardNames[CardNames.ENVOY] = new TranslatedCardName("公使");
JapaneseCardNames[CardNames.GOVERNOR] = new TranslatedCardName("総督");
JapaneseCardNames[CardNames.MARCHLAND] = new TranslatedCardName("辺境", "辺境", "辺境");
JapaneseCardNames[CardNames.PRINCE] = new TranslatedCardName("王子");
JapaneseCardNames[CardNames.SAUNA] = new TranslatedCardName("サウナ");
JapaneseCardNames[CardNames.STASH] = new TranslatedCardName("へそくり");
JapaneseCardNames[CardNames.WALLED_VILLAGE] = new TranslatedCardName("囲郭村");

JapaneseCardNames[CardNames.SUMMON] = new TranslatedCardName("召喚");

JapaneseCardNames[CardNames.BLACK_MARKET_PILE] = new TranslatedCardName("闇市場デッキ");

JapaneseCardNames[CardNames.TOWN] = new TranslatedCardName("町", "町", "町");
JapaneseCardNames[CardNames.MODIFY] = new TranslatedCardName("改造", "改造", "改造");
JapaneseCardNames[CardNames.MERCHANT_CAMP] = new TranslatedCardName("商人の野営地", "商人の野営地", "商人の野営地");
JapaneseCardNames[CardNames.SWAP] = new TranslatedCardName("交換", "交換", "交換");
JapaneseCardNames[CardNames.SKIRMISHER] = new TranslatedCardName("散兵", "散兵", "散兵");
JapaneseCardNames[CardNames.HIGHWAYMAN] = new TranslatedCardName("追いはぎ", "追いはぎ", "追いはぎ");
JapaneseCardNames[CardNames.BARBARIAN] = new TranslatedCardName("蛮族", "蛮族", "蛮族");
JapaneseCardNames[CardNames.INNKEEPER] = new TranslatedCardName("宿屋の主人", "宿屋の主人", "宿屋の主人");
JapaneseCardNames[CardNames.CAPITAL_CITY] = new TranslatedCardName("首都", "首都", "首都");
JapaneseCardNames[CardNames.SPECIALIST] = new TranslatedCardName("専門家", "専門家", "専門家");
JapaneseCardNames[CardNames.CARPENTER] = new TranslatedCardName("大工", "大工", "大工");
JapaneseCardNames[CardNames.ROYAL_GALLEY] = new TranslatedCardName("王家のガレー船", "王家のガレー船", "王家のガレー船");
JapaneseCardNames[CardNames.MARQUIS] = new TranslatedCardName("侯爵", "侯爵", "侯爵");
JapaneseCardNames[CardNames.SENTINEL] = new TranslatedCardName("歩哨", "歩哨", "歩哨");
JapaneseCardNames[CardNames.COURIER] = new TranslatedCardName("急使", "急使", "急使");
JapaneseCardNames[CardNames.GALLERIA] = new TranslatedCardName("ガレリア", "ガレリア", "ガレリア");
JapaneseCardNames[CardNames.HUNTER] = new TranslatedCardName("狩人", "狩人", "狩人");
JapaneseCardNames[CardNames.SYCOPHANT] = new TranslatedCardName("ごますり", "ごますり", "ごますり");
JapaneseCardNames[CardNames.UNDERLING] = new TranslatedCardName("下役", "下役", "下役");
JapaneseCardNames[CardNames.IMPORTER] = new TranslatedCardName("輸入者", "輸入者", "輸入者");
JapaneseCardNames[CardNames.CONTRACT] = new TranslatedCardName("契約書", "契約書", "契約書");
JapaneseCardNames[CardNames.EMISSARY] = new TranslatedCardName("密使", "密使", "密使");
JapaneseCardNames[CardNames.BAUBLE] = new TranslatedCardName("道化棒", "道化棒", "道化棒");
JapaneseCardNames[CardNames.BROKER] = new TranslatedCardName("仲買人", "仲買人", "仲買人");
JapaneseCardNames[CardNames.GUILDMASTER] = new TranslatedCardName("ギルドマスター", "ギルドマスター", "ギルドマスター");

JapaneseCardNames[CardNames.ARCHITECTS_GUILD] = new TranslatedCardName("建築家ギルド");
JapaneseCardNames[CardNames.ORDER_OF_ASTROLOGERS] = new TranslatedCardName("占星術師団");
JapaneseCardNames[CardNames.LEAGUE_OF_BANKERS] = new TranslatedCardName("銀行家連盟");
JapaneseCardNames[CardNames.MOUNTAIN_FOLK] = new TranslatedCardName("山の民");
JapaneseCardNames[CardNames.WOODWORKERS_GUILD] = new TranslatedCardName("木工ギルド");
JapaneseCardNames[CardNames.PEACEFUL_CULT] = new TranslatedCardName("平和的教団");
JapaneseCardNames[CardNames.CRAFTERS_GUILD] = new TranslatedCardName("工芸家ギルド");
JapaneseCardNames[CardNames.CAVE_DWELLERS] = new TranslatedCardName("穴居民");
JapaneseCardNames[CardNames.PLATEAU_SHEPHERDS] = new TranslatedCardName("高原の羊飼い");
JapaneseCardNames[CardNames.DESERT_GUIDES] = new TranslatedCardName("砂漠の案内人");
JapaneseCardNames[CardNames.FAMILY_OF_INVENTORS] = new TranslatedCardName("発明家の家族");
JapaneseCardNames[CardNames.ISLAND_FOLK] = new TranslatedCardName("島民");
JapaneseCardNames[CardNames.ORDER_OF_MASONS] = new TranslatedCardName("メイソン団");
JapaneseCardNames[CardNames.BAND_OF_NOMADS] = new TranslatedCardName("遊牧民団");
JapaneseCardNames[CardNames.GANG_OF_PICKPOCKETS] = new TranslatedCardName("すり師団");
JapaneseCardNames[CardNames.MARKET_TOWNS] = new TranslatedCardName("市場の町");
JapaneseCardNames[CardNames.FOREST_DWELLERS] = new TranslatedCardName("森の居住者");
JapaneseCardNames[CardNames.FELLOWSHIP_OF_SCRIBES] = new TranslatedCardName("写本士の仲間たち");
JapaneseCardNames[CardNames.LEAGUE_OF_SHOPKEEPERS] = new TranslatedCardName("小売店主連盟");
JapaneseCardNames[CardNames.COASTAL_HAVEN] = new TranslatedCardName("沿岸の避難港");
JapaneseCardNames[CardNames.CITY_STATE] = new TranslatedCardName("都市国家");
JapaneseCardNames[CardNames.TRAPPERS_LODGE] = new TranslatedCardName("罠師の小屋");
JapaneseCardNames[CardNames.CIRCLE_OF_WITCHES] = new TranslatedCardName("魔女の輪");

JapaneseCardNames[CardNames.AUGUR_PILE] = new TranslatedCardName("卜占官");
JapaneseCardNames[CardNames.HERB_GATHERER] = new TranslatedCardName("薬草集め", "薬草集め", "薬草集め");
JapaneseCardNames[CardNames.ACOLYTE] = new TranslatedCardName("侍祭", "侍祭", "侍祭");
JapaneseCardNames[CardNames.SORCERESS] = new TranslatedCardName("女魔導士", "女魔導士", "女魔導士");
JapaneseCardNames[CardNames.SIBYL] = new TranslatedCardName("女予言者", "女予言者", "女予言者");
JapaneseCardNames[CardNames.CLASH_PILE] = new TranslatedCardName("衝突");
JapaneseCardNames[CardNames.BATTLE_PLAN] = new TranslatedCardName("戦闘計画", "戦闘計画", "戦闘計画");
JapaneseCardNames[CardNames.ARCHER] = new TranslatedCardName("射手", "射手", "射手");
JapaneseCardNames[CardNames.WARLORD] = new TranslatedCardName("将軍", "将軍", "将軍");
JapaneseCardNames[CardNames.TERRITORY] = new TranslatedCardName("領土", "領土", "領土");
JapaneseCardNames[CardNames.FORT_PILE] = new TranslatedCardName("城砦");
JapaneseCardNames[CardNames.TENT] = new TranslatedCardName("天幕", "天幕", "天幕");
JapaneseCardNames[CardNames.GARRISON] = new TranslatedCardName("駐屯地", "駐屯地", "駐屯地");
JapaneseCardNames[CardNames.HILL_FORT] = new TranslatedCardName("堡塁", "堡塁", "堡塁");
JapaneseCardNames[CardNames.STRONGHOLD] = new TranslatedCardName("要塞", "要塞", "要塞");
JapaneseCardNames[CardNames.ODYSSEY_PILE] = new TranslatedCardName("叙事詩");
JapaneseCardNames[CardNames.OLD_MAP] = new TranslatedCardName("古地図", "古地図", "古地図");
JapaneseCardNames[CardNames.VOYAGE] = new TranslatedCardName("航海", "航海", "航海");
JapaneseCardNames[CardNames.SUNKEN_TREASURE] = new TranslatedCardName("沈没船の財宝", "沈没船の財宝", "沈没船の財宝");
JapaneseCardNames[CardNames.DISTANT_SHORE] = new TranslatedCardName("遠い海岸", "遠い海岸", "遠い海岸");
JapaneseCardNames[CardNames.TOWNSFOLK_PILE] = new TranslatedCardName("町民");
JapaneseCardNames[CardNames.TOWN_CRIER] = new TranslatedCardName("触れ役", "触れ役", "触れ役");
JapaneseCardNames[CardNames.BLACKSMITH] = new TranslatedCardName("蹄鉄工", "蹄鉄工", "蹄鉄工");
JapaneseCardNames[CardNames.MILLER] = new TranslatedCardName("粉屋", "粉屋", "粉屋");
JapaneseCardNames[CardNames.ELDER] = new TranslatedCardName("長老", "長老", "長老");
JapaneseCardNames[CardNames.WIZARD_PILE] = new TranslatedCardName("魔法使い");
JapaneseCardNames[CardNames.STUDENT] = new TranslatedCardName("生徒", "生徒", "生徒");
JapaneseCardNames[CardNames.CONJURER] = new TranslatedCardName("霊術師", "霊術師", "霊術師");
JapaneseCardNames[CardNames.SORCERER] = new TranslatedCardName("魔導士", "魔導士", "魔導士");
JapaneseCardNames[CardNames.LICH] = new TranslatedCardName("リッチ", "リッチ", "リッチ");

JapaneseCardNames[CardNames.MONKEY] = new TranslatedCardName("サル", "サル", "サル");
JapaneseCardNames[CardNames.BLOCKADE] = new TranslatedCardName("封鎖", "封鎖", "封鎖");
JapaneseCardNames[CardNames.SAILOR] = new TranslatedCardName("船乗り", "船乗り", "船乗り");
JapaneseCardNames[CardNames.PIRATE] = new TranslatedCardName("海賊", "海賊", "海賊");
JapaneseCardNames[CardNames.CORSAIR] = new TranslatedCardName("コルセア", "コルセア", "コルセア");
JapaneseCardNames[CardNames.SEA_CHART] = new TranslatedCardName("海図", "海図", "海図");
JapaneseCardNames[CardNames.ASTROLABE] = new TranslatedCardName("アストロラーベ", "アストロラーベ", "アストロラーベ");
JapaneseCardNames[CardNames.TIDE_POOLS] = new TranslatedCardName("潮溜り", "潮溜り", "潮溜り");
JapaneseCardNames[CardNames.SEA_WITCH] = new TranslatedCardName("海の魔女", "海の魔女", "海の魔女");

JapaneseCardNames[CardNames.ANVIL] = new TranslatedCardName("金床", "金床", "金床");
JapaneseCardNames[CardNames.CLERK] = new TranslatedCardName("書記", "書記", "書記");
JapaneseCardNames[CardNames.INVESTMENT] = new TranslatedCardName("出資", "出資", "出資");
JapaneseCardNames[CardNames.TIARA] = new TranslatedCardName("ティアラ", "ティアラ", "ティアラ");
JapaneseCardNames[CardNames.CHARLATAN] = new TranslatedCardName("山師", "山師", "山師");
JapaneseCardNames[CardNames.COLLECTION] = new TranslatedCardName("収集品", "収集品", "収集品");
JapaneseCardNames[CardNames.CRYSTAL_BALL] = new TranslatedCardName("水晶球", "水晶球", "水晶球");
JapaneseCardNames[CardNames.MAGNATE] = new TranslatedCardName("有力者", "有力者", "有力者");
JapaneseCardNames[CardNames.WAR_CHEST] = new TranslatedCardName("軍用金", "軍用金", "軍用金");

JapaneseCardNames[CardNames.GUARD_DOG] = new TranslatedCardName("番犬", "番犬", "番犬");
JapaneseCardNames[CardNames.BERSERKER] = new TranslatedCardName("狂戦士", "狂戦士", "狂戦士");
JapaneseCardNames[CardNames.NOMADS] = new TranslatedCardName("遊牧民", "遊牧民", "遊牧民");
JapaneseCardNames[CardNames.TRAIL] = new TranslatedCardName("進路", "進路", "進路");
JapaneseCardNames[CardNames.WEAVER] = new TranslatedCardName("織工", "織工", "織工");
JapaneseCardNames[CardNames.SOUK] = new TranslatedCardName("スーク", "スーク", "スーク");
JapaneseCardNames[CardNames.CAULDRON] = new TranslatedCardName("大釜", "大釜", "大釜");
JapaneseCardNames[CardNames.WHEELWRIGHT] = new TranslatedCardName("車大工", "車大工", "車大工");
JapaneseCardNames[CardNames.WITCHS_HUT] = new TranslatedCardName("魔女の小屋", "魔女の小屋", "魔女の小屋");

JapaneseCardNames[CardNames.CAGE] = new TranslatedCardName("檻", "檻", "檻");
JapaneseCardNames[CardNames.GROTTO] = new TranslatedCardName("岩屋", "岩屋", "岩屋");
JapaneseCardNames[CardNames.JEWELLED_EGG] = new TranslatedCardName("宝飾卵", "宝飾卵", "宝飾卵");
JapaneseCardNames[CardNames.SEARCH] = new TranslatedCardName("調査", "調査", "調査");
JapaneseCardNames[CardNames.SHAMAN] = new TranslatedCardName("シャーマン", "シャーマン", "シャーマン");
JapaneseCardNames[CardNames.SECLUDED_SHRINE] = new TranslatedCardName("秘境の社", "秘境の社", "秘境の社");
JapaneseCardNames[CardNames.SIREN] = new TranslatedCardName("セイレーン", "セイレーン", "セイレーン");
JapaneseCardNames[CardNames.STOWAWAY] = new TranslatedCardName("密航者", "密航者", "密航者");
JapaneseCardNames[CardNames.TASKMASTER] = new TranslatedCardName("現場監督", "現場監督", "現場監督");
JapaneseCardNames[CardNames.ABUNDANCE] = new TranslatedCardName("豊穣", "豊穣", "豊穣");
JapaneseCardNames[CardNames.CABIN_BOY] = new TranslatedCardName("キャビンボーイ", "キャビンボーイ", "キャビンボーイ");
JapaneseCardNames[CardNames.CRUCIBLE] = new TranslatedCardName("坩堝", "坩堝", "坩堝");
JapaneseCardNames[CardNames.FLAGSHIP] = new TranslatedCardName("旗艦", "旗艦", "旗艦");
JapaneseCardNames[CardNames.FORTUNE_HUNTER] = new TranslatedCardName("財産目当て", "財産目当て", "財産目当て");
JapaneseCardNames[CardNames.GONDOLA] = new TranslatedCardName("ゴンドラ", "ゴンドラ", "ゴンドラ");
JapaneseCardNames[CardNames.HARBOR_VILLAGE] = new TranslatedCardName("港の村", "港の村", "港の村");
JapaneseCardNames[CardNames.LANDING_PARTY] = new TranslatedCardName("上陸部隊", "上陸部隊", "上陸部隊");
JapaneseCardNames[CardNames.MAPMAKER] = new TranslatedCardName("地図作り", "地図作り", "地図作り");
JapaneseCardNames[CardNames.MAROON] = new TranslatedCardName("置き去り", "置き去り", "置き去り");
JapaneseCardNames[CardNames.ROPE] = new TranslatedCardName("縄", "縄", "縄");
JapaneseCardNames[CardNames.SWAMP_SHACKS] = new TranslatedCardName("沼地の小屋", "沼地の小屋", "沼地の小屋");
JapaneseCardNames[CardNames.TOOLS] = new TranslatedCardName("工具", "工具", "工具");
JapaneseCardNames[CardNames.BURIED_TREASURE] = new TranslatedCardName("埋められた財宝", "埋められた財宝", "埋められた財宝");
JapaneseCardNames[CardNames.CREW] = new TranslatedCardName("乗組員", "乗組員", "乗組員");
JapaneseCardNames[CardNames.CUTTHROAT] = new TranslatedCardName("切り裂き魔", "切り裂き魔", "切り裂き魔");
JapaneseCardNames[CardNames.ENLARGE] = new TranslatedCardName("拡大", "拡大", "拡大");
JapaneseCardNames[CardNames.FIGURINE] = new TranslatedCardName("小像", "小像", "小像");
JapaneseCardNames[CardNames.FIRST_MATE] = new TranslatedCardName("一等航海士", "一等航海士", "一等航海士");
JapaneseCardNames[CardNames.FRIGATE] = new TranslatedCardName("フリゲート船", "フリゲート船", "フリゲート船");
JapaneseCardNames[CardNames.LONGSHIP] = new TranslatedCardName("ロングシップ", "ロングシップ", "ロングシップ");
JapaneseCardNames[CardNames.MINING_ROAD] = new TranslatedCardName("鉱山道路", "鉱山道路", "鉱山道路");
JapaneseCardNames[CardNames.PENDANT] = new TranslatedCardName("ペンダント", "ペンダント", "ペンダント");
JapaneseCardNames[CardNames.PICKAXE] = new TranslatedCardName("つるはし", "つるはし", "つるはし");
JapaneseCardNames[CardNames.PILGRIM] = new TranslatedCardName("巡礼者", "巡礼者", "巡礼者");
JapaneseCardNames[CardNames.QUARTERMASTER] = new TranslatedCardName("操舵手", "操舵手", "操舵手");
JapaneseCardNames[CardNames.SILVER_MINE] = new TranslatedCardName("銀山", "銀山", "銀山");
JapaneseCardNames[CardNames.TRICKSTER] = new TranslatedCardName("トリックスター", "トリックスター", "トリックスター");
JapaneseCardNames[CardNames.WEALTHY_VILLAGE] = new TranslatedCardName("価値ある村", "価値ある村", "価値ある村");
JapaneseCardNames[CardNames.SACK_OF_LOOT] = new TranslatedCardName("戦利品の袋", "戦利品の袋", "戦利品の袋");
JapaneseCardNames[CardNames.KINGS_CACHE] = new TranslatedCardName("王の隠し財産", "王の隠し財産", "王の隠し財産");

JapaneseCardNames[CardNames.BURY] = new TranslatedCardName("埋葬", "埋葬", "埋葬");
JapaneseCardNames[CardNames.AVOID] = new TranslatedCardName("回避", "回避", "回避");
JapaneseCardNames[CardNames.DELIVER] = new TranslatedCardName("配達", "配達", "配達");
JapaneseCardNames[CardNames.PERIL] = new TranslatedCardName("危難", "危難", "危難");
JapaneseCardNames[CardNames.RUSH] = new TranslatedCardName("突貫", "突貫", "突貫");
JapaneseCardNames[CardNames.FORAY] = new TranslatedCardName("襲撃", "襲撃", "襲撃");
JapaneseCardNames[CardNames.LAUNCH] = new TranslatedCardName("発進", "発進", "発進");
JapaneseCardNames[CardNames.MIRROR] = new TranslatedCardName("鏡映", "鏡映", "鏡映");
JapaneseCardNames[CardNames.PREPARE] = new TranslatedCardName("準備", "準備", "準備");
JapaneseCardNames[CardNames.SCROUNGE] = new TranslatedCardName("物色", "物色", "物色");
JapaneseCardNames[CardNames.JOURNEY] = new TranslatedCardName("旅行", "旅行", "旅行");
JapaneseCardNames[CardNames.MAELSTROM] = new TranslatedCardName("大渦巻", "大渦巻", "大渦巻");
JapaneseCardNames[CardNames.LOOTING] = new TranslatedCardName("略奪行為", "略奪行為", "略奪行為");
JapaneseCardNames[CardNames.INVASION] = new TranslatedCardName("侵略", "侵略", "侵略");
JapaneseCardNames[CardNames.PROSPER] = new TranslatedCardName("繁栄", "繁栄", "繁栄");

JapaneseCardNames[CardNames.LOOT_PILE] = new TranslatedCardName("戦利品");
JapaneseCardNames[CardNames.AMPHORA] = new TranslatedCardName("アンフォラ", "アンフォラ", "アンフォラ");
JapaneseCardNames[CardNames.DOUBLOONS] = new TranslatedCardName("ダブロン金貨", "ダブロン金貨", "ダブロン金貨");
JapaneseCardNames[CardNames.ENDLESS_CHALICE] = new TranslatedCardName("尽きぬ杯", "尽きぬ杯", "尽きぬ杯");
JapaneseCardNames[CardNames.FIGUREHEAD] = new TranslatedCardName("船首像", "船首像", "船首像");
JapaneseCardNames[CardNames.HAMMER] = new TranslatedCardName("ハンマー", "ハンマー", "ハンマー");
JapaneseCardNames[CardNames.INSIGNIA] = new TranslatedCardName("勲章", "勲章", "勲章");
JapaneseCardNames[CardNames.JEWELS] = new TranslatedCardName("宝石", "宝石", "宝石");
JapaneseCardNames[CardNames.ORB] = new TranslatedCardName("宝珠", "宝珠", "宝珠");
JapaneseCardNames[CardNames.PRIZE_GOAT] = new TranslatedCardName("賞品のヤギ", "賞品のヤギ", "賞品のヤギ");
JapaneseCardNames[CardNames.PUZZLE_BOX] = new TranslatedCardName("パズルボックス", "パズルボックス", "パズルボックス");
JapaneseCardNames[CardNames.SEXTANT] = new TranslatedCardName("六分儀", "六分儀", "六分儀");
JapaneseCardNames[CardNames.SHIELD] = new TranslatedCardName("盾", "盾", "盾");
JapaneseCardNames[CardNames.SPELL_SCROLL] = new TranslatedCardName("呪符の巻物", "呪符の巻物", "呪符の巻物");
JapaneseCardNames[CardNames.STAFF] = new TranslatedCardName("杖", "杖", "杖");
JapaneseCardNames[CardNames.SWORD] = new TranslatedCardName("剣", "剣", "剣");

JapaneseCardNames[CardNames.CHEAP] = new TranslatedCardName("安価な", "安価な", "安価な");
JapaneseCardNames[CardNames.CURSED] = new TranslatedCardName("呪われた", "呪われた", "呪われた");
JapaneseCardNames[CardNames.FATED] = new TranslatedCardName("運命の", "運命の", "運命の");
JapaneseCardNames[CardNames.FAWNING] = new TranslatedCardName("へつらう", "へつらう", "へつらう");
JapaneseCardNames[CardNames.FRIENDLY] = new TranslatedCardName("友好的な", "友好的な", "友好的な");
JapaneseCardNames[CardNames.HASTY] = new TranslatedCardName("せっかちな", "せっかちな", "せっかちな");
JapaneseCardNames[CardNames.INHERITED] = new TranslatedCardName("受け継がれた", "受け継がれた", "受け継がれた");
JapaneseCardNames[CardNames.INSPIRING] = new TranslatedCardName("鼓舞する", "鼓舞する", "鼓舞する");
JapaneseCardNames[CardNames.NEARBY] = new TranslatedCardName("近隣の", "近隣の", "近隣の");
JapaneseCardNames[CardNames.PATIENT] = new TranslatedCardName("忍耐強い", "忍耐強い", "忍耐強い");
JapaneseCardNames[CardNames.PIOUS] = new TranslatedCardName("敬虔な", "敬虔な", "敬虔な");
JapaneseCardNames[CardNames.RECKLESS] = new TranslatedCardName("無謀な", "無謀な", "無謀な");
JapaneseCardNames[CardNames.RICH] = new TranslatedCardName("豊かな", "豊かな", "豊かな");
JapaneseCardNames[CardNames.SHY] = new TranslatedCardName("内気な", "内気な", "内気な");
JapaneseCardNames[CardNames.TIRELESS] = new TranslatedCardName("疲れ知らずの", "疲れ知らずの", "疲れ知らずの");

JapaneseCardNames[CardNames.CARNIVAL] = new TranslatedCardName("謝肉祭", "謝肉祭", "謝肉祭");
JapaneseCardNames[CardNames.FERRYMAN] = new TranslatedCardName("渡し守", "渡し守", "渡し守");
JapaneseCardNames[CardNames.FARMHANDS] = new TranslatedCardName("耕作者", "耕作者", "耕作者");
JapaneseCardNames[CardNames.JOUST] = new TranslatedCardName("一騎討ち", "一騎討ち", "一騎討ち");
JapaneseCardNames[CardNames.SHOP] = new TranslatedCardName("店", "店", "店");
JapaneseCardNames[CardNames.REWARDS_PILE] = new TranslatedCardName("恩賞", "恩賞", "恩賞");
JapaneseCardNames[CardNames.CORONET] = new TranslatedCardName("宝冠", "宝冠", "宝冠");
JapaneseCardNames[CardNames.COURSER] = new TranslatedCardName("駿馬", "駿馬", "駿馬");
JapaneseCardNames[CardNames.DEMESNE] = new TranslatedCardName("御料地", "御料地", "御料地");
JapaneseCardNames[CardNames.HOUSECARL] = new TranslatedCardName("ハスカール", "ハスカール", "ハスカール");
JapaneseCardNames[CardNames.HUGE_TURNIP] = new TranslatedCardName("大きなかぶ", "大きなかぶ", "大きなかぶ");
JapaneseCardNames[CardNames.RENOWN] = new TranslatedCardName("名声", "名声", "名声");
JapaneseCardNames[CardNames.FARRIER] = new TranslatedCardName("装蹄師", "装蹄師", "装蹄師");
JapaneseCardNames[CardNames.FOOTPAD] = new TranslatedCardName("野盗", "野盗", "野盗");
JapaneseCardNames[CardNames.INFIRMARY] = new TranslatedCardName("診療所", "診療所", "診療所");
JapaneseCardNames[CardNames.FISHMONGER] = new TranslatedCardName("魚屋", "魚屋", "魚屋");
JapaneseCardNames[CardNames.SNAKE_WITCH] = new TranslatedCardName("蛇巫", "蛇巫", "蛇巫");
JapaneseCardNames[CardNames.ARISTOCRAT] = new TranslatedCardName("公家", "公家", "公家");
JapaneseCardNames[CardNames.CRAFTSMAN] = new TranslatedCardName("名工", "名工", "名工");
JapaneseCardNames[CardNames.RIVERBOAT] = new TranslatedCardName("川船", "川船", "川船");
JapaneseCardNames[CardNames.ROOT_CELLAR] = new TranslatedCardName("むろ", "むろ", "むろ");
JapaneseCardNames[CardNames.ALLEY] = new TranslatedCardName("横丁", "横丁", "横丁");
JapaneseCardNames[CardNames.CHANGE] = new TranslatedCardName("変化", "変化", "変化");
JapaneseCardNames[CardNames.NINJA] = new TranslatedCardName("忍者", "忍者", "忍者");
JapaneseCardNames[CardNames.POET] = new TranslatedCardName("歌人", "歌人", "歌人");
JapaneseCardNames[CardNames.RIVER_SHRINE] = new TranslatedCardName("川の神社", "川の神社", "川の神社");
JapaneseCardNames[CardNames.RUSTIC_VILLAGE] = new TranslatedCardName("田舎の村", "田舎の村", "田舎の村");
JapaneseCardNames[CardNames.GOLD_MINE] = new TranslatedCardName("金山", "金山", "金山");
JapaneseCardNames[CardNames.IMPERIAL_ENVOY] = new TranslatedCardName("帝の使い", "帝の使い", "帝の使い");
JapaneseCardNames[CardNames.KITSUNE] = new TranslatedCardName("狐", "狐", "狐");
JapaneseCardNames[CardNames.LITTER] = new TranslatedCardName("輿", "輿", "輿");
JapaneseCardNames[CardNames.RICE_BROKER] = new TranslatedCardName("米商人", "米商人", "米商人");
JapaneseCardNames[CardNames.RONIN] = new TranslatedCardName("浪人", "浪人", "浪人");
JapaneseCardNames[CardNames.TANUKI] = new TranslatedCardName("狸", "狸", "狸");
JapaneseCardNames[CardNames.TEA_HOUSE] = new TranslatedCardName("茶屋", "茶屋", "茶屋");
JapaneseCardNames[CardNames.SAMURAI] = new TranslatedCardName("侍", "侍", "侍");
JapaneseCardNames[CardNames.RICE] = new TranslatedCardName("米", "米", "米");
JapaneseCardNames[CardNames.MOUNTAIN_SHRINE] = new TranslatedCardName("山の神社", "山の神社", "山の神社");
JapaneseCardNames[CardNames.DAIMYO] = new TranslatedCardName("大名", "大名", "大名");
JapaneseCardNames[CardNames.ARTIST] = new TranslatedCardName("美術家", "美術家", "美術家");
JapaneseCardNames[CardNames.APPROACHING_ARMY] = new TranslatedCardName("来寇");
JapaneseCardNames[CardNames.BIDING_TIME] = new TranslatedCardName("待機");
JapaneseCardNames[CardNames.BUREAUCRACY] = new TranslatedCardName("官僚制");
JapaneseCardNames[CardNames.DIVINE_WIND] = new TranslatedCardName("神風");
JapaneseCardNames[CardNames.ENLIGHTENMENT] = new TranslatedCardName("悟り");
JapaneseCardNames[CardNames.FLOURISHING_TRADE] = new TranslatedCardName("商売繁盛");
JapaneseCardNames[CardNames.GOOD_HARVEST] = new TranslatedCardName("豊作");
JapaneseCardNames[CardNames.GREAT_LEADER] = new TranslatedCardName("名将");
JapaneseCardNames[CardNames.GROWTH] = new TranslatedCardName("発展");
JapaneseCardNames[CardNames.HARSH_WINTER] = new TranslatedCardName("厳冬");
JapaneseCardNames[CardNames.KIND_EMPEROR] = new TranslatedCardName("帝の思し召し");
JapaneseCardNames[CardNames.PANIC] = new TranslatedCardName("混乱");
JapaneseCardNames[CardNames.PROGRESS] = new TranslatedCardName("進歩");
JapaneseCardNames[CardNames.RAPID_EXPANSION] = new TranslatedCardName("急拡大");
JapaneseCardNames[CardNames.SICKNESS] = new TranslatedCardName("病");
JapaneseCardNames[CardNames.AMASS] = new TranslatedCardName("蓄財");
JapaneseCardNames[CardNames.ASCETICISM] = new TranslatedCardName("修行");
JapaneseCardNames[CardNames.CREDIT] = new TranslatedCardName("掛買い");
JapaneseCardNames[CardNames.FORESIGHT] = new TranslatedCardName("予見");
JapaneseCardNames[CardNames.KINTSUGI] = new TranslatedCardName("金継ぎ");
JapaneseCardNames[CardNames.PRACTICE] = new TranslatedCardName("稽古");
JapaneseCardNames[CardNames.SEA_TRADE] = new TranslatedCardName("廻船");
JapaneseCardNames[CardNames.RECEIVE_TRIBUTE] = new TranslatedCardName("朝貢");
JapaneseCardNames[CardNames.GATHER] = new TranslatedCardName("摘み取り");
JapaneseCardNames[CardNames.CONTINUE] = new TranslatedCardName("継続");