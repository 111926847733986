"use strict";

var addCardColor = function addCardColor(s) {
    return s;
};
var hasMixedVisibility = function hasMixedVisibility(cardNames) {
    return cardNames.some(function (c) {
        return c === CardNames.BACK;
    }) && cardNames.some(function (c) {
        return c !== CardNames.BACK;
    });
};

function addTextColor(text, color) {
    return '<span style="color:' + color + '">' + text + '</span>';
}

function getColoredEnglishCardName(cardName) {
    return "[" + cardName.name + "]";
}

function getColoredEnglishCardNamePlural(cardName) {
    return "[^p" + cardName.name + "]";
}

function getColoredEnglishCardNameNoun(cardName) {
    return "[^n" + cardName.name + "]";
}

function getColoredGermanCardName(cardName) {
    return "[" + cardName.name + "]";
}

function getColoredGermanCardNamePlural(cardName) {
    return "[^p" + cardName.name + "]";
}

function getColoredGermanCardNameNoun(cardName) {
    return "[^n" + cardName.name + "]";
}

function getColoredRussianCardName(cardName) {
    return "[" + cardName.name + "]";
}

function getColoredRussianCardNamePlural(cardName) {
    return "[^p" + cardName.name + "]";
}

function getColoredRussianCardNameNoun(cardName) {
    return "[^n" + cardName.name + "]";
}

function getColoredFrenchCardName(cardName) {
    return "[" + cardName.name + "]";
}

function getColoredFrenchCardNamePlural(cardName) {
    return "[^p" + cardName.name + "]";
}

function getColoredFrenchCardNameNoun(cardName) {
    return "[^n" + cardName.name + "]";
}

function getColoredJapaneseCardName(cardName) {
    return "[" + cardName.name + "]";
}

function getColoredJapaneseCardNamePlural(cardName) {
    return "[^p" + cardName.name + "]";
}

function getColoredJapaneseCardNameNoun(cardName) {
    return "[^n" + cardName.name + "]";
}

function getColoredTChineseCardName(cardName) {
    return "[" + cardName.name + "]";
}

function getColoredTChineseCardNamePlural(cardName) {
    return "[^p" + cardName.name + "]";
}

function getColoredTChineseCardNameNoun(cardName) {
    return "[^n" + cardName.name + "]";
}

function getColoredDutchCardName(cardName) {
    return "[" + cardName.name + "]";
}

function getColoredDutchCardNamePlural(cardName) {
    return "[^p" + cardName.name + "]";
}

function getColoredDutchCardNameNoun(cardName) {
    return "[^n" + cardName.name + "]";
}

function getColoredSpanishCardName(cardName) {
    return "[" + cardName.name + "]";
}

function getColoredSpanishCardNamePlural(cardName) {
    return "[^p" + cardName.name + "]";
}

function getColoredSpanishCardNameNoun(cardName) {
    return "[^n" + cardName.name + "]";
}

function getColoredCzechCardName(cardName) {
    return "[" + cardName.name + "]";
}

function getColoredCzechCardNamePlural(cardName) {
    return "[^p" + cardName.name + "]";
}

function getColoredCzechCardNameNoun(cardName) {
    return "[^n" + cardName.name + "]";
}

function getColoredEsperantoCardName(cardName) {
    return "[" + cardName.name + "]";
}

function getColoredEsperantoCardNamePlural(cardName) {
    return "[^p" + cardName.name + "]";
}

function getColoredEsperantoCardNameNoun(cardName) {
    return "[^n" + cardName.name + "]";
}