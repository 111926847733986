"use strict";

var GermanLevelIntroductions = ["In der ersten Stufe spielst du mit den Karten aus dem Basisspiel. Es werden keine Karten aus Erweiterungen benutzt.", "Stufe 2 fügt keine neuen Konzepte hinzu, sondern viele einfache Karten aus den Erweiterungen."];

var GermanGameConcepts = {};
GermanGameConcepts[GameConcepts.CHOICE_CARDS] = new TranslatedGameConcept("Effektauswahl", "Einige Karten lassen dir eine Wahlm\xF6glichkeit f\xFCr ihren Effekt. Zum Beispiel kann Verwalter eines von 3 Dingen tun, die du im Spiel \xFCber 3 Kn\xF6pfe ausw\xE4hlen kannst.\nAndere Karten, wie Wunschbrunnen, lassen dich eine Karte benennen.\nDu kannst auf eine Karte klicken, um sie auszuw\xE4hlen, oder tr\xE4gst den Namen in ein Eingabefeld ein.");

GermanGameConcepts[GameConcepts.DURATIONS] = new TranslatedGameConcept("Dauerkarten", "Dauerkarten sind orange und k\xF6nnen etwas in einem sp\xE4teren Zug tun. \nDas einzige spezielle an ihnen ist, dass sie bis zu dem Zug im Spiel bleiben, in dem sie letztmals etwas tun.\nWenn du zum Beispiel Ausr\xFCstung spielst, dann ziehst du 2 Karten und kannst bis zu zwei Karten von deiner Hand beiseite legen.\nWenn du keine Karten beiseite gelegt hast, ist Ausr\xFCstung vollst\xE4ndig abgearbeitet und wird am Ende dieses Zuges abgelegt.\nWenn du aber mindestens 1 Karte beiseite gelegt hast, bleibt Ausr\xFCstung im Spiel. Du bekommst die Karte(n) in deinem n\xE4chsten Zug auf die Hand und Ausr\xFCstung wird dann am Ende des Zuges abgelegt.\nEin Thronsaal, der eine Dauerkarte spielt, bleibt mit ihr im Spiel.");

GermanGameConcepts[GameConcepts.KINGDOM_TREASURES] = new TranslatedGameConcept("Spezielle Geldkarten", "Geldkarten m\xFCssen nicht immer nur Geld einbringen, sie k\xF6nnen genau wie Aktionskarten auch anderes tun. \nWenn du zum Beispiel Schatz spielst, erh\xE4lst du nicht nur [2], sondern nimmst zus\xE4tzlich noch ein Gold und ein Kupfer.");

GermanGameConcepts[GameConcepts.PLATINUM_COLONY] = new TranslatedGameConcept("Platin & Kolonie", "Platin ist eine Geldkarte mit Wert [5]; Kolonie ist eine Punktekarte, die 10 Siegpunkte wert ist. Das sind keine K\xF6nigreichkarten, sie erscheinen \xFCber den Gold- und Provinzstapeln. \nSie erscheinen zuf\xE4llig, basierend auf der Anzahl der Bl\xFCtezeitkarten im K\xF6nigreich - 10% Wahrscheinlichkeit pro solcher Karte. Sie sind immer zusammen (oder garnicht) bei einem Spiel dabei.\nIn Spielen mit Kolonie endet das Spiel auch, wenn der Koloniestapel am Zugende leer ist (das Spiel endet auch wie normal, wenn dann der Provinzstapel oder 3 beliebige Stapel leer sind).");

GermanGameConcepts[GameConcepts.WHEN_GAIN_BUY] = new TranslatedGameConcept("Beim Nehmen/Kaufen", "Einige Karten machen etwas, wenn sie genommen oder gekauft werden. Dies sind leicht unterschiedliche Bedingungen und sie werden zu leicht verschiedenen Zeiten abgearbeitet.\nWenn du eine Karte kaufst, machst du erst die Effekte, die passieren, wenn sie gekauft wird. Dann erst nimmst du dir die Karte und machst dann die Effekte, die passieren, wenn sie genommen wird.\nDu kannst Karten auch durch bestimmte Effekte nehmen ohne sie zu kaufen, zum Beispiel mit Umbau.\nWenn du also eine Karte \"umbaust\" (mit Umbau entsorgst) und dir ein Grenzdorf nimmst, dann nimmst du dir sofort nach dem Grenzdorf eine billigere Karte.  \nAber wenn du eine Karte in ein Forum \"umbaust\", passiert nichts besonderes, denn du hast das Forum ja nicht gekauft.\nWenn du ein Grenzorf kaufst, dann nimmst du es und dann nimmst du dir eine billigere Karte. Wenn du ein Forum kaufst, erh\xE4lst du +1 Kauf und dann nimmst du dir das Forum.\nNachdem du die Karte genommen oder gekauft hast, macht eine solche F\xE4higkeit nichts mehr. Ein Grenzdorf in deinem Deck ist wie ein normales Dorf, nur dass es [6] kostet.");

GermanGameConcepts[GameConcepts.WHEN_TRASH] = new TranslatedGameConcept("Beim Entsorgen", "Einige Karten machen etwas, wenn sie entsorgt werden.\nDie Karte muss wirklich von woanders in den M\xFCllstapel gelegt werden, damit der Effekt passiert.\nDer Effekt kann w\xE4hrend der Abarbeitung einer anderen Karte passieren.\nWenn du zum Beispiel Katakomben mittels Umbau entsorgst, nimmst du dir erst eine billigere Karte als Katakomben und dann nimmst du dir eine Karte, die bis zu [2] mehr kostet als Katakomben (wegen des Umbaus).");

GermanGameConcepts[GameConcepts.VP_TOKENS] = new TranslatedGameConcept("Siegpunktmarker", "Siegpunktmarker sind eine M\xF6glichkeit, Siegpunkte zu erhalten, ohne dass eine Punktekarte in dein Deck geht.\nJedes Mal, wenn du zum Beispiel Denkmal spielst, erh\xE4lst du einen weiteren Siegpunktmarker. Es gibt kein Limit f\xFCr die Anzahl der Siegpunktmaker, die du so bekommen kannst.\nSiegpunktmarker sind in deinem angezeigten Punktestand enthalten und werden nochmals separat ausgewiesen.");

GermanGameConcepts[GameConcepts.COFFERS] = new TranslatedGameConcept("Taler", "Einige Karten geben dir Taler, das sind [], die du \xFCber den Zug hinaus aufheben kannst, bist du sie brauchst.\nDu kannst jederzeit in deinem Zug eine beliebige Anzahl Taler in [] umwandeln (\"ausgeben\").\nDu kannst Taler im selben Zug ausgeben, in dem du sie erhalten hast, inklusive der Kaufphase.\nZum Beispiel geben dir Gew\xFCrze 2 Taler, wenn du sie nimmst.");

GermanGameConcepts[GameConcepts.VILLAGERS] = new TranslatedGameConcept("Dorfbewohner", "Einige Karten geben dir Dorfbewohner, das sind Aktionen, die die \xFCber den Zug hinaus aufheben kannst, bis du sie brauchst.\nZu einem beliebigen Zeitpunkt in deiner Aktionsphase kannst du beliebig viele Dorfbewohner in Aktionen umwandeln (\"einsetzen\"). Normalerweise setzt du immer nur einen Dorfbewohner auf einmal ein.\nZum Beispiel gibt dir Diener 2 Dorfbewohner, wenn du sie nimmst. \nDu spielst vielleicht mal in einem Zug Diener und ziehst damit eine Schmiede, die du aus Mangel an Aktionen nicht mehr spielen kannst. Dann kannst du einen deiner Dorfbewohner einsetzen, um eine Aktion zu erhalten und weiter Aktionskarten (wie die Schmiede) spielen zu k\xF6nnen.");

GermanGameConcepts[GameConcepts.EXILE] = new TranslatedGameConcept("Exil", "Jeder Spieler hat sein eigenes Exil-Tableu, auf das Karten gelegt werden k\xF6nnen (eine Karte wird \"ins Exil gelegt\").\nWenn eine Karte direkt aus dem Vorrat in dein Exil gelegt wird, l\xF6st das keine \"beim Nehmen\" F\xE4higkeiten aus, aber die Karte ist trotzdem deine (z.B. beim Abrechnen der Siegpunkte am Spielende). \nWenn du eine Karte nimmnst, darfst du alle anderen Karten gleichen Namens von deinem Exil-Tableau ablegen (auf deinen Ablagestapel). Du kannst nur alle solchen Karten oder keine ablegen, \"nur ein paar\" ist nicht erlaubt.\nWenn du zum Beispiel die Kopfgeldj\xE4gerin spielst, legst du eine Karte aus deiner Hand ins Exil und falls du keine Karte gleichen Namens dort hattest, bekommst du [3].\nDu k\xF6nntest ein Anwesen aus deiner Hand ins Exil legen, dadurch wirst du das Anwesen nie wieder ziehen, aber es z\xE4hlt immer noch f\xFCr dich in der Abrechung.\nWenn du sp\xE4ter ein Anwesen nimmst, und du aus irgendwelchen Gr\xFCnden das Anwesen aus dem Exil wieder im deinem Deck haben wolltest, k\xF6nntest du es jetzt ablegen.");

GermanGameConcepts[GameConcepts.REACTIONS] = new TranslatedGameConcept("Reaktionskarten", "\nReaktionskarten sind blau und haben eine F\xE4higkeit, die zu einer ungew\xF6hnlichen Zeit genutzt werden kann.\nMeistens wird die Karte aus deiner Hand aufgedeckt, um die F\xE4higkeit zu nutzen, manchmal musst du sie auch ablegen und manchmal spielst du sie.\nDu kennst den Burggraben, der Angriffe aufh\xE4lt; aber andere Reaktionskarten tun das nicht unbedingt, sie tun, was immer sie auch sagen.\nDer Hirtenhund l\xE4sst dich zum Beispiel ihn selbst spielen, wenn du eine Karte nimmst, auch wenn gerade ein anderer Spieler am Zug ist.\nDu ziehst dann 2 Karten und kannst dann noch einen Hirtenhund spielen, selbst einen, den du gerade gezogen hast.");

GermanGameConcepts[GameConcepts.FAVORS] = new TranslatedGameConcept("Gefallen", "Kontaktkarten haben eine F\xE4higkeit, die dir Gefallen geben. \nIn einem Spiel mit einem oder mehreren Kontaktkarten ist immer genau ein Verb\xFCndeter mit dabei. Das ist eine Querkarte die angibt, wie, wann und wof\xFCr die Gefallen in diesem Spiel verwendet (\"eingel\xF6st\") werden k\xF6nnen.\nAls Beispiel k\xF6nntest in einem Spiel mit dem Untergebenen die H\xFCtte der Fallensteller als Verb\xFCndeten haben. Das bedeutet, dass du immer dann, wenn du in diesem Spiel eine Karte nimmst, einen Gefallen einl\xF6sen kannst, um diese Karte auf deinen Nachziehstapel zu legen.\nDu beginnst das Spiel mit 1 Gefallen (oder 5 in Spielen mit der Importeurin), und kannst ihn schon ab dem ersten Zug einl\xF6sen.");

GermanGameConcepts[GameConcepts.WHEN_DISCARD] = new TranslatedGameConcept("Beim Ablegen", "Einige Karte tun etwas, wenn sie abgelegt werden.\nManche haben noch spezielle Bedingungen, der Tunnel z.B. darf nicht in der Aufr\xE4umphase abgelegt werden, um die F\xE4higkeit zu aktivieren. Wenn du ihn (in einer anderen Phase) ablegst, darfst du ihn aufdecken und dir ein Gold nehmen.\nAndere Karten m\xFCssen f\xFCr ihren Effekt aus dem Spiel abgelegt werden, \xFCblicherweise in der Aufr\xE4umphase. Sie m\xFCssen deshalb erstmal irgendwie ins Spiel kommen. Wenn du z.B. die Schatzkammer spielst, hast du in der Aufr\xE4umphase die M\xF6glichkeit, sie auf deinen Nachziehstapel zu legen, falls du in diesem Zug keine Punktekarte gekauft hast.");

GermanGameConcepts[GameConcepts.TRASH_INTERACTION] = new TranslatedGameConcept("Interaktion mit Müll", "Einige Karten beinflussen und/oder benutzen Karten aus dem M\xFCll-Stapel.\nDu kannst den M\xFCll-Stapel jederzeit durch Klicken auf das M\xFCllfeld ansehen.\nMit der Herumtreiberin kannst du zum Beispiel Aktionskarten aus dem M\xFCll-Stapel nehmen. Es kommt nicht darauf an, wie sie dort hingekommen sind.");

GermanGameConcepts[GameConcepts.UNIQUE_MATS] = new TranslatedGameConcept("Einzigartige Tableus", "Einige Karten haben spezielle, nur f\xFCr sie relevante, Tableus, von denen meistens jeder Spieler ein Exemplar bekommt.\nZum Beispiel hat das Eingeborenendorf ein Tableau pro Spieler, und wenn du ein Eingeborenendorf spielst, kannst du entweder eine Karte auf dein Tableau legen, oder alle Karten von dort auf die Hand nehmen. Was auf das jeweilige Tableau gelegt wird, ist auf der Karte beschrieben.");

GermanGameConcepts[GameConcepts.EVENTS] = new TranslatedGameConcept("Ereignisse ", "Manchmal hat ein Spiel eine Karte im Querformat (\"Querkarte\"), die das Spiel ver\xE4ndert.\nEreignisse haben Kosten und einen Effekt. Du kannst einen Kauf in deiner Kaufphase nutzen, die Kosten bezahlen und dann passiert der Effekt.\nWenn Du mehrere K\xE4ufe hast, kannst du einen Effekt auch mehrfach kaufen (au\xDFer der Text verbietet das).\nDu kannst eine Karte und ein Ereignis kaufen, oder jede andere Kombination.\nDas Kaufen eines Ereignisses ist nicht das Kaufen einer Karte, und Ereignisse sind nicht von Effekten betroffen, die sich auf Karten beziehen, wie z.B. die Br\xFCcke.\n");

GermanGameConcepts[GameConcepts.RESERVE] = new TranslatedGameConcept("Reserve", "Reservekarten sind hellbraun und haben eine F\xE4higkeit, die sie auf dein Wirtschaftstableu legen, und eine andere F\xE4higkeit, sie davon wieder \"aufzurufen\".\nEine solche Karte aufzurufen bedeutet, sie ins Spiel zu bringen.\nEs sind grunds\xE4tzlich Karten, die einen bestimmten Effekt f\xFCr sp\xE4ter aufsparen.\nWenn du zum Beispiel den Rattenf\xE4nger spielst, ziehst du 1 Karte und erh\xE4lst 1 Aktion und legst den Rattenf\xE4nger auf das Tableu.\nZu Beginn eines sp\xE4teren deiner Z\xFCge kannst du dann den Rattenf\xE4nger aufrufen, ihn damit ins Spiel bringen und eine Karte aus deiner Hand entsorgen.\nDer Rattenf\xE4nger wird dann in der Aufr\xE4umphase abgelegt.");

GermanGameConcepts[GameConcepts.ADVENTURES_TOKENS] = new TranslatedGameConcept("Abenteuer-Marker", "Die Abenteuer-Erweiterung kommt mit einer Reihe von Markern, von denen jeder Spieler einen Satz bekommt.\nEinige werden auf Vorratsstapel gelegt, andere liegen vor einem Spieler.\nZum Beispiel ist Training ein Ereignis, das einen Spieler seinen +[1]-Marker auf einen Stapel legen l\xE4sst. F\xFCr den Rest des Spieles erhalt dieser Spieler dann [1], wenn er eine Karte dieses Stapel spielt.\nEs gibt \xE4hnliche Marker f\xFCr +1 Aktion, +1 Karte und +1 Kauf.\nWildh\xFCter benutzt den Reise-Marker, der jedesmal umgedreht wird, wenn du den Wildh\xFCter spielst. Du ziehst also die 5 Karten nur bei jedem zweiten Spielen eines Wildh\xFCters.\nEs gibt auch einen -[2]-Marker, der die Kosten eines Stapel in deinem Zug um [2] reduziert, sowie einen Entsorgungsmarker, der dich eine Karte entsorgen l\xE4sst, wenn du eine Karte von dem Stapel kaufst, auf dem er liegt. Die -[1]-Marker und -1-Karte-Marker enthalten dir den n\xE4chsten [1] vor, den du erhalten w\xFCrdest bzw. die n\xE4chste Karte, die du ziehen w\xFCrdest.");

GermanGameConcepts[GameConcepts.NIGHT_PHASE] = new TranslatedGameConcept("Nacht", "Nocturne f\xFCgt dem Spiel eine neue Phase hinzu, die Nachtphase.\nDiese Phase findet nach der Kaufphase und vor der Aufr\xE4umphase statt.\nIn ihr kannst du eine beliebige Anzahl Nachtkarten spielen.\nDas ist alles, Nachtkarten sind einfach Karten, die man in der Nachtphase spielt.\nDas ist im Allgemeinen die einzige Phase, in der du sie spielen kannst, denn es sind weder Aktions- noch Geldkarten.\nZum Beispiel ist Kloster eine Nachtkarte, in der Nachtphase kannst du sie spielen, um einige Karten zu entsorgen, abh\xE4ngig davon, wie viele Karten du in diesem Zug genommen hast.\nMehrere Nachtkarten sind auch Dauerkarten, und einige gehen direkt auf deine Hand, wenn du sie nimmst, wie z.B. der S\xFCndenpfuhl.\nWenn du den S\xFCndenpfuhl also kaufst, nimmst du ihn direkt auf die Hand ... und dann kommt deine Nachtphase und du kannst ihn sofort spielen.");

GermanGameConcepts[GameConcepts.SHELTERS] = new TranslatedGameConcept("Unterschlüpfe", "Die 3 Unterschl\xFCpfe erscheinen zuf\xE4llig in einem Spiel, basiered auf der Anzahl der Karten aus Dark Ages im K\xF6nigreich - 10% Wahrscheinlichkeit pro solcher Karte.\nSie erscheinen immer zusammen und liegen nicht im Vorrat, sondern ersetzen die Start-Anwesen.\nAnstatt also mit 7 Kupfer und 3 Anwesen das Spiel zu beginnen, hat jeder Spieler 7 Kupfer, ein Verfallenes Anwesen, eine Totenstadt und eine H\xFCtte.");

GermanGameConcepts[GameConcepts.HORSES] = new TranslatedGameConcept("Pferde", "Pferd ist eine Nichtvorratskarte (nicht kaufbar), die du durch einige Karten nehmen darfst.\nJedesmal wenn du Nachschub spielst, nimmst du dir ein Pferd (auf deinen Nachziehstapel).\nWenn du ein Pferd spielst, ziehst du 2 Karten, erh\xE4lst eine Aktion und legst das Pferd zur\xFCck auf den Pferdestapel.\nPferde k\xF6nnen wie andere Karte auch auf andere Art verwendet werden, du kannst zum Beispiel ein Pferd mit Umbau entsorgen (dann geht es auch in den M\xFCll und nicht zur\xFCck in seinen Stapel).\nWenn der Pferdestapel leer ist, k\xF6nnen keine Pferde mehr genommen werden (bis wieder welche zur\xFCckgelegt werden).\nPferde k\xF6nnen nur \xFCber Karten genommen werden, die sich ausdr\xFCcklich auf Pferde beziehen.\nDer Pferdestapel z\xE4hlt nicht f\xFCr das Spielende mit 3 leeren Stapeln.");

GermanGameConcepts[GameConcepts.PROJECTS] = new TranslatedGameConcept("Projekte", "Projekte sind ein weiterer Typ von Querkarten. In Spielen mit ihnen erh\xE4lst du 2 kleine W\xFCrfel in deiner Spielerfarbe.\nIn deiner Kaufphase kannst du einen Kauf einsetzen und die angegebenen Kosten bezahlen, um einen deiner W\xFCrfel auf das Projekt zu legen.\nDieser W\xFCrfel verschafft dir die F\xE4higkeit des Projektes f\xFCr den Rest des Spieles.\nZum Beispiel kostet Kleiner Markt [4], und wenn du ihn kaufst, erh\xE4lst du 1 Kauf zu Beginn jedes deiner Z\xFCge, f\xFCr den Rest des Spiels.\nWie Ereignisse sind auch Projekte keine \"Karten\" und werden damit durch Karten wie Br\xFCcke nicht billiger.\nDu kannst einen W\xFCrfel nach dem Kaufen nicht mehr verschieben und in einem Spiel mit 3 Projekten deshalb nur 2 kaufen.\nDu kannst ein Projekt auch nur einmal pro Spiel kaufen (also nicht 2 W\xFCrfel auf dasselbe Projekt legen).");

GermanGameConcepts[GameConcepts.LANDMARKS] = new TranslatedGameConcept("Landmarken", "Landmarken sind ein weiterer Typ von Querkarten.\nSie geben weitere M\xF6glichkeiten, Siegpunkte zu erhalten, entweder mit Siegpunktmarkern oder \xFCber spezielle Regeln, die w\xE4hrend der Abrechnung zu mehr (oder weniger) Siegpunkten f\xFChren.\nZum Beispiel gibt der Turm 1 Siegpunkt am Ende des Spieles f\xFCr jede Nicht-Punktekarte eines leeren Vorratsstapels, die du besitzt.\nDie im Spiel angezeigte Siegpunktzahl ber\xFCcksichtigt auch die Landmarken.");

GermanGameConcepts[GameConcepts.EXTRA_CARDS] = new TranslatedGameConcept("Extrakarten", "Einige Karten ben\xF6tigen Extrakarten, die nicht zum Vorrat geh\xF6ren.\nWie Pferde, k\xF6nnen diese Extrakarten nicht gekauft und nur genommen werden, wenn eine Karte sich ausdr\xFCcklich auf sie bezieht, normalerweise durch Nennung des Namens. Die Stapel der Extrakarten z\xE4hlen nicht f\xFCr das Spielende mit 3 leeren Stapeln.\nZum Beispiel kann ein Eremit unter bestimmten Bedingungen gegen einen Verr\xFCckten \"getauscht\" werden. Eremit ist die einzige Karte, die den Verr\xFCckten ben\xF6tigt.\nEs gibt auch 3 Karten, die Ruinen ben\xF6tigen, das ist ein Vorrtatsstapel, der nur benutzt wird, wenn eine Karte ihn ben\xF6tigt. Die Karten im Ruinenstapel sind gemischt, es gibt 5 verschiedene Ruinen. Zwei der Karten verteilen Ruinen an die Gegner, so wie eine Hexe Fl\xFCche verteilt.\nNocturne hat eine Gruppe von 3 Extrakarten vom Typ Erscheinung. Einige Karten be\xF6tigen eine spezielle Erscheinung und eine ben\xF6tigt alle 3.");

GermanGameConcepts[GameConcepts.SPLIT_PILES] = new TranslatedGameConcept("Gemischte Stapel", "Einige Stapel enthalten mehrere verschiedene Karten, du kannst immer nur die gerade oben liegende Karte nehmen. Willst du weiter unten liegende Karten bekommen, musst du die dar\xFCberliegenden irgendwie wegbekommen (z.B. in dem du sie alle nimmst oder kaufst).\nEmpires hat in seinen gemischten Stapeln 2 verschiedene Karten, erst 5x die billigere Karte, dann 5x eine andere. Zum Beispiel hat einer dieser Stapel 5 Siedler und darunter liegen 5 Emsige D\xF6rfer.\nVerb\xFCndete hat in seinen gemischten Stapeln 4 verschiedene Karten zu je 4 Exemplaren, wieder nach Kosten sortiert mit der billigsten oben. \nBei diesen Stapeln aus Verb\xFCndete hat die am Anfang oben liegende Karte (wie die Kr\xE4utersammlerin) immer eine \"rotieren\"-F\xE4higkeit. Diese erlaubt dir, alle im Moment oben liegenden gleiche Karten unter den Stapel zu legen (um schneller an die Karten darunter zu gelangen).\nEs gibt auch einen Stapel mit 8 verschiedenen Karten - die Schl\xF6sser - und ein Stapel hat 10 verschiedene Karten, die gemischt sind - die Ritter.");

GermanGameConcepts[GameConcepts.ARTIFACTS] = new TranslatedGameConcept("Artefakte", "Vier Karten beziehen sich auf ein Artefakt. Diese Artefakte geben ihren Besitzern bestimmte F\xE4higkeiten und z\xE4hlen selbst nicht als Karten.\nZum Beispiel erlaubt die Fahne ihrem Besitzer, eine Karte mehr f\xFCr die neue Hand zu ziehen. Um sie zu erhalten, musst du einen Fahnentr\xE4ger nehmen oder entsorgen. \nNur ein Spieler kann ein bestimmtes Artefakt zu einem Zeitpunkt besitzen. Wenn du also die Fahne erh\xE4lst, w\xE4hrend ein anderer Spieler sie hat, nimmst du sie ihm weg.");

GermanGameConcepts[GameConcepts.BOONS_HEXES] = new TranslatedGameConcept("Gaben & Plagen", "Nocturne hat Gaben und Plagen.\nDas sind kleine Decks aus jeweils 12 Karten mit verschiedenen Effekten. Die Gaben haben gute Effekte, die Plagen schlechte (werden von Angriffen verwendet).\nWenn eine Karte sagt \"Du empf\xE4ngst eine Gabe\", wie die Minnes\xE4ngerin, drehst du die oberste Karte des Gabenstapels um (vorher mischen, wenn leer) und f\xFChrst ihren Effekt aus.\nEine Gabe ist zum Beispiel das Geschenk des Meeres, du ziehst einfach eine Karte.\nPlagen sind \xE4hnlich, nur eben schlecht. Wenn du mehr als einen Gegner hast, bekommen alle dieselbe Plage.");

GermanGameConcepts[GameConcepts.HEIRLOOMS] = new TranslatedGameConcept("Erbstücke", "\nNocturne hat Erbst\xFCcke, das sind Karten, die Start-Kupfer ersetzen.\nSie werden benutzt, wenn die zugeh\xF6rige K\xF6nigreichkarte benutzt wird. Also wird in Spielen mit der Sch\xE4ferin pro Spieler ein Weideland benutzt, dass ein Kupfer aus den 10 Anfangskarten ersetzt. Du f\xE4ngst das Spiel dann also mit 3 Anwesen, 6 Kupfer und einem Weideland an.\nNat\xFCrlich kann ein Spiel auch mehrere Erbst\xFCcke enthalten und/oder Unterschl\xFCpfe statt Anwesen. Das Spiel zeigt dir die verwendeten 10 Karten vor dem Spiel an, und protokolliert sie am Start des Logs.");

GermanGameConcepts[GameConcepts.STRANGE_COSTS] = new TranslatedGameConcept("Ungewöhnliche Kosten", "Einige Karten haben ungew\xF6hnliche Kosten. Schlachtross kostet zum Beispiel [1] weniger pro Karte, die du in diesem Zug genommen hast. Hast du 3 Karten genommen, kostest es nur noch [3]. \nDiese \xC4nderung gilt f\xFCr alle entsprechenden Karten, auch au\xDFerhalb des Vorrats.\nDas Programm zeigt immer die aktuellen Kosten an.");

GermanGameConcepts[GameConcepts.OVERPAY] = new TranslatedGameConcept("Überbezahlen", "Einige Karten erlauben dir, mehr zu zahlen, wenn du sie kaufst, um einen Effekt zu erreichen.\nWenn du zum Beispiel Meisterst\xFCck kaufst, bringt dir jedes weitere bezahlte [1] ein Silber ein.\nDas verandert nicht die Kosten der Meisterst\xFCcks, die bleiben bei [3]. Du bezahlst nur mehr, um den Effekt zu bekommen.");

GermanGameConcepts[GameConcepts.COMMAND] = new TranslatedGameConcept("Befehl", "Einige Karten k\xF6nnen andere Karten spielen, ohne sie zu bewegen.\nDu bekommst dadurch den normalen Effekt der Karte, aber z.B. keine \"W\xE4hrend diese Karte im Spiel ist\"-Boni.\nZum Beispiel k\xF6nnen Vogelfreie eine billigere Karte aus dem Vorrat spielen. \nEinige dieser Karten haben oder beziehen sich auf den Typ \"Befehl\", um eine Schleife von Karten zu verhindern, die sich gegenseitig spielen. \nKarten wie Vogelfreie bleiben so lange im Spiel, wie die Karte, die sie spielen, im Spiel geblieben w\xE4re. Spielt Vogelfreie also ein Fischerdorf, bleibt es bis zu deinem n\xE4chsten Zug im Spiel.");

GermanGameConcepts[GameConcepts.POTION] = new TranslatedGameConcept("Tränke", "Einige Karten haben [P] in ihren Kosten.\nDiese Resource kann man nur bekommen, wenn man einen Trank spielt (der immer im Vorrat ist, wenn eine Karte ihn braucht). Ansonsten verh\xE4lt es sich \xE4hnlich wie mit [1]: Man bekommt es und gibt es dann aus.\nWenn du [2] und [P] hast, kannst du zum Beispiel eine Universit\xE4t kaufen. Um eine weitere Universit\xE4t zu kaufen, brauchst du weitere [2] und eine weiteres [P].\nWenn Effekte die Kosten von Karten in [] abfragen, ignorieren sie das [P]-Symbol. \nBeim Vergleich von Kosten ist [P] mehr als nichts, aber weder weniger noch mehr als irgendeine Menge von [].\nDu kannst eine Univerit\xE4t mittels Umbau in eine Karte umbauen, die [4] und [P] kostet, oder in eine Karte, die [4] kostet ([4] ist \"bis zu\" [4] und [P]). Du kannst aber ein Kupfer nicht in eine Universit\xE4t umbauen.\nRitter entsorgen Karten, die zwischen [3] und [6] kosten. Eine Karte mit [P] in ihren Kosten kostet nicht zwischen [3] und [6].");

GermanGameConcepts[GameConcepts.DEBT] = new TranslatedGameConcept("Schulden", "Schulden lassen dich eine Karte jetzt kaufen und sp\xE4ter f\xFCr sie bezahlen.\nEinige Karten haben [D] in ihren Kosten. Das sind Schulden, du bezahlst keine [] f\xFCr die [D], sondern nimmst dir stattdessen gleich viele [D]-Marker.\nW\xE4hrend du [D]-Marker hast, kannst du keine Karten, Ereignisse oder Projekte kaufen.\nIn deiner Kaufphase, kannst du sowohl vor, nach und zwischen dem Kaufen von Karten Schulden tilgen, zum Kurs von [1] f\xFCr [1D]-Marker. Das Tilgen kosten keinen Kauf, es eine F\xE4higkeit, die du in deiner Kaufphase nutzen kannst.\nAlso Beispiel nehmen wir an, du hast [3] und willst ein Stadtviertel kaufen. Du nimmst [8D] und tilgst sofort [3D]-Marker mit deinen [3]. In deinem n\xE4chsten Zug kannst du zun\xE4chst keine Karten kaufen, du musst die Restschulden von [5D] erst tilgen. Falls du [7] h\xE4ttest, k\xF6nntest du die [5D] tilgen und dann etwas f\xFCr [2] kaufen, oder noch ein Stadtviertel. Einige Dinge kosten sowohl [] als auch [D], du musst die [] haben, um diese zu kaufen.\n\n[D] sind nicht [], wenn Effekte die Kosten von Karten in [] abfragen, ignorieren sie [D]. Eine Karte mit [D]-Kosten und ohne []-Kosten hat implizit [0] in ihren Kosten. Beim Vergleich von Kosten ist [D] mehr als nichts, aber weder weniger noch mehr als irgendeine Menge von []. Du kannst ein Stadtviertel in einen Lehnsherren umbauen, der auch [8D] kostet und kannst ein Stadtviertel in ein Anwesen umbauen, das ja \"bis zu\" [2] und [8D] kostet. Ritter entsorgen Karten, die zwischen [3] und [6] kosten. Eine Karte mit [D] in ihren Kosten kostet nicht zwischen [3] und [6].");

GermanGameConcepts[GameConcepts.WAYS] = new TranslatedGameConcept("Wege", "Wege sind eine weitere Art von Querkarten.\nSie geben Aktionskarten w\xE4hrend des gesamten Spiels eine weitere Option.\nZum Beispel kannst du in einem Spiel mit Weg des Schafs f\xFCr jede deiner Aktionskarten beim Spielen w\xE4hlen, ob ihr normaler Effekt eintritt oder ob sie [2] erzeugt. \nWege ersetzen nur den oberen Teil bei Karten mit Trennstrich, der Rest passiert zur selben Zeit wie sonst auch.");

GermanGameConcepts[GameConcepts.DEBT_COSTS] = new TranslatedGameConcept("Schulden", "Schulden lassen dich eine Karte jetzt kaufen und sp\xE4ter f\xFCr sie bezahlen.\nEinige Karten haben [D] in ihren Kosten. Das sind Schulden, du bezahlst keine [] f\xFCr die [D], sondern nimmst dir stattdessen gleich viele [D]-Marker.\nW\xE4hrend du [D]-Marker hast, kannst du keine Karten, Ereignisse oder Projekte kaufen.\nW\xE4hrend deines Zuges kannst du Schulden tilgen, zum Kurs von [1] je [1D]-Marker.\nAls Beispiel nehmen wir an, du hast [3] und willst ein Stadtviertel kaufen. Du nimmst [8D] und tilgst sofort [3D]-Marker mit deinen [3]. In deinem n\xE4chsten Zug kannst du zun\xE4chst keine Karten kaufen, du musst die Restschulden von [5D] erst tilgen. Falls du [7] h\xE4ttest, k\xF6nntest du die [5D] tilgen und dann etwas f\xFCr [2] kaufen, oder noch ein Stadtviertel. Einige Dinge kosten sowohl [] als auch [D], du musst die [] haben, um diese zu kaufen.\n\n[D] sind nicht [], wenn Effekte die Kosten von Karten in [] abfragen, ignorieren sie [D]. Eine Karte mit [D]-Kosten und ohne []-Kosten hat implizit [0] in ihren Kosten. Beim Vergleich von Kosten ist [D] mehr als nichts, aber weder weniger noch mehr als irgendeine Menge von []. Du kannst ein Stadtviertel in einen Lehnsherren umbauen, der auch [8D] kostet und kannst ein Stadtviertel in ein Anwesen umbauen, das ja \"bis zu\" [2] und [8D] kostet. Ritter entsorgen Karten, die zwischen [3] und [6] kosten. Eine Karte mit [D] in ihren Kosten kostet nicht zwischen [3] und [6].");

GermanGameConcepts[GameConcepts.ON_PLAY_DEBT] = new TranslatedGameConcept("Schulden durch Effekte", "Einige Karten geben [D] als Teil ihres Effektes; +[3D] gibt dir 3 Schuldenmarker.\nW\xE4hrend du diese [D]-Marker hast, kannst du keine Karten, Ereignisse oder Projekte kaufen; \nw\xE4hrend deiner Z\xFCge kannst du Schulden zum Kurs von [1] je [1D] tilgen.\nZum Beispiel gibt dir Erdkeller beim Spielen [3D]. \nIn deiner Kaufphase spielst du dann z.B. 5 Kupfer, tilgst die [3D], and hast [2] \xFCbrig zum Kaufen.\n[D]-Marker bleiben zwischen den Z\xFCgen erhalten, sie verschwinden nur durch Tilgung.");

GermanGameConcepts[GameConcepts.SHADOW] = new TranslatedGameConcept("Schatten", "Schattenkarten k\xF6nnen aus deinem Nachziehstapel gespielt werden. Sie haben einzigartige R\xFCckseiten und werden beim Mischen immer ganz nach unten gelegt.\nImmer, wenn du eine Aktionskarte aus deiner Hand spielen kannst - ob normal oder z.B. mit Hilfe eines Thronsaals - kannst du dich entscheiden, f\xFCr diesen Spielen eine Schattenkarte aus deinem Nachziehstapel zu benutzen.\nSchattenkarte sind nicht in deiner Hand, wenn sie in deinem Nachziehstapel sind; du kannst sie dann nicht ablegen oder \xE4hnliches.\nDu kannst sie aus dem Nachziehstapel nur spielen. Du kannst sie auch aus deiner Hand spielen, falls du sie ziehst.");

GermanGameConcepts[GameConcepts.OMEN_PROPHECY] = new TranslatedGameConcept("Omen & Prophezeiungen", "Ein Omen hat einen +1 [Sun] Effekt und bewirkt die Hinzunahme einer Prophezeiung zum Spiel. Die Prophezeiung started mit [Sun]-Markern auf sich (5 bei 2 Spielern), und der +1 [Sun] \nEffekt eines Omens entfernt einen dieser Marker. Zu Beginn des Spieles ist die F\xE4higkeit der Prophezeiung nocht nicht aktiv, sondern erst (f\xFCr den Rest des Spiels), wenn der letzte Marker entfernt wird.\nIn einem Spiel mit Rustikales Dorf als Omen und Gro\xDFer Anf\xFChrer als Prophezeiung wird bei jedem Spielen von Rustikales Dorf ein [Sun]-Marker von Gro\xDFer Anf\xFChrer entfernt. Sobald alle Marker entfernt wurden, gibt jede gespielte Aktion als zus\xE4tzlichen Effekt +1 Aktion.");