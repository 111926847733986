"use strict";

var publicInbox = void 0;

webclient.service('inboxService', ['$rootScope', 'serverMessenger', function ($rootScope, serverMessenger) {
    var self = this;
    var scope = $rootScope.$new(true);
    var _shouldShow = false;
    var messages = [];
    var summaries = [];
    var selectedMessage = "";
    var isFetching = false;
    var isWaitingForUnreadSummaries = false;
    if (isDebug) publicInbox = self;

    function setNewSelectedMessage() {
        selectedMessage = self.getFirstUnreadMessage();
    }

    self.shouldShow = function () {
        return _shouldShow;
    };

    self.show = function () {
        _shouldShow = true;
        serverMessenger.requestMailSummaries(0);
        isWaitingForUnreadSummaries = true;
    };

    self.hasSummaries = function () {
        return summaries.length > 0;
    };
    self.hasNewMessages = function () {
        return summaries.some(function (m) {
            return m.status === MailStati.UNREAD;
        }) || isWaitingForUnreadSummaries;
    };
    self.hasMessages = function () {
        return messages.length > 0;
    };
    self.getMessages = function () {
        return messages;
    };
    self.getSummaries = function () {
        return summaries;
    };

    self.getSelectedMessage = function () {
        if (selectedMessage === "") selectedMessage = self.getFirstUnreadMessage();
        return selectedMessage;
    };

    self.getSelectedId = function () {
        if (!self.hasMessages()) return -1;
        return self.getSelectedMessage().messageId;
    };

    self.getFirstUnreadSummary = function () {
        var mail = summaries.find(function (m) {
            return m.status === MailStati.UNREAD;
        });
        if (isUndefined(mail)) return summaries[0];
        return mail;
    };

    self.getFirstUnreadMessage = function () {
        var mail = messages.find(function (m) {
            return m.status === MailStati.UNREAD;
        });
        if (isUndefined(mail)) {
            if (self.hasMessages()) {
                return messages[0];
            } else {
                return "not a mail";
            }
        }
        return mail;
    };

    self.selectMessage = function (messageId) {
        var mail = messages.find(function (m) {
            return m.messageId === messageId;
        });
        if (isUndefined(mail)) {
            serverMessenger.requestMailMessages([messageId]);
            return;
        }
        selectedMessage = mail;
    };

    self.fetchMessage = function () {
        if (isFetching) return;
        if (!self.hasSummaries()) throw new Error('Trying to fetch message but there are no summaries.');
        isFetching = true;
        var summary = self.getFirstUnreadSummary();
        serverMessenger.requestMailMessages([summary.messageId]);
    };

    function orderSummaries() {
        summaries.sort(summaryOrder);
    }

    function summaryOrder(a, b) {
        if (a.status === b.status) return orderByDate(a, b);
        return orderByStatus(a, b);
    }

    function orderByDate(a, b) {
        return a.date - b.date;
    }

    function orderByStatus(a, b) {
        if (a.status === MailStati.UNREAD) return -1;
        if (b.status === MailStati.UNREAD) return 1;
        return 0;
    }

    self.deleteMessage = function () {
        serverMessenger.changeMailStatus(selectedMessage.messageId, MailStati.DELETED);
        var summary = summaries.find(function (s) {
            return s.messageId = selectedMessage.messageId;
        });
        removeElement(messages, selectedMessage);
        removeElement(summaries, summary);
        setNewSelectedMessage();
        orderSummaries();
    };

    self.markMessageAsRead = function () {
        selectedMessage.status = MailStati.READ;
        var summary = summaries.find(function (s) {
            return s.messageId = selectedMessage.messageId;
        });
        summary.status = MailStati.READ;
        serverMessenger.changeMailStatus(selectedMessage.messageId, MailStati.READ);
        setNewSelectedMessage();
        orderSummaries();
    };

    self.hasReadMessage = function () {
        return !isUndefined(selectedMessage) && selectedMessage.status === MailStati.READ;
    };

    function processNewMail(mail) {
        messages.push(mail);
        if (!isUndefined(mail) && !summaries.some(function (s) {
            return s.messageId === mail.messageId;
        })) {
            summaries.push(mail.getSummary());
        }
        orderSummaries();
    }

    function setSummaries(mailSummaries) {
        summaries = mailSummaries;
        orderSummaries();
    }

    scope.$on(Events.LOGIN_SUCCESS, function (e, loginSuccess) {
        if (loginSuccess.isReconnecting) return;
        if (loginSuccess.numberOfUnreadMails === 0) return;
        serverMessenger.requestMailSummaries(0);
        isWaitingForUnreadSummaries = true;
    });

    scope.$on(Events.MAIL_SUMMARIES_RECEIVED, function (e, mailSummaries) {
        setSummaries(mailSummaries);
        isWaitingForUnreadSummaries = false;
    });

    scope.$on(Events.MAIL_RECEIVED, function (e, mail) {
        processNewMail(mail);
        $rootScope.safeApply();
    });

    scope.$on(Events.MAILS_RECEIVED, function (e, mails) {
        isFetching = false;
        var m = mails[0];
        processNewMail(m);
        selectedMessage = m;
        $rootScope.safeApply();
    });
}]);