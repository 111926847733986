"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SingleLandscapeZone = function (_SupplyZone) {
    _inherits(SingleLandscapeZone, _SupplyZone);

    function SingleLandscapeZone(state, index, pileName, owner, createdBy, attachedTrait) {
        _classCallCheck(this, SingleLandscapeZone);

        var _this = _possibleConstructorReturn(this, (SingleLandscapeZone.__proto__ || Object.getPrototypeOf(SingleLandscapeZone)).call(this, state, index, pileName, owner, createdBy, attachedTrait));

        _this.indexOfType = _this.state.zones.filter(function (z) {
            return z instanceof SingleLandscapeZone;
        }).length;

        var newStack = new CardStack(_this.state.getAnonCard(pileName), _this, CardSizes.MINI);
        newStack.getCounter = function () {
            return -1;
        };
        return _this;
    }

    _createClass(SingleLandscapeZone, [{
        key: "initialize",
        value: function initialize(cardIds) {
            var _this2 = this;

            cardIds.forEach(function (id) {
                if (id === -1) _this2.cardStacks[0].addAnonymousCard();else {
                    _this2.cardStacks[0].addCard(_this2.state.cards[id]);
                }
            });
            this.reposition();
        }
    }, {
        key: "reposition",
        value: function reposition() {
            var shouldAnimate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            if (this.layoutModel.layout === null) return;
            this.bbox = this.layoutModel.layout.landscapeElements[this.indexOfType].bbox;

            var repositionStack = function repositionStack(stack, x, y, width, height, zIndex) {
                if (shouldAnimate) stack.animator = new TransitionAnimator(stack, absoluteTransitionScript(new Rectangle(x, y, width, height, zIndex)), stack, function () {});else stack.reposition(x, y, width, height, zIndex);
            };
            repositionStack(this.cardStacks[0], this.bbox.x + this.bbox.width * 0.005, this.bbox.y + this.bbox.height * 0.015, this.bbox.width * 0.97, this.bbox.height * 0.97, this.bbox.zIndex);
        }
    }]);

    return SingleLandscapeZone;
}(SupplyZone);