"use strict";

var CzechPhrases = {};

CzechPhrases[Phrases.THE] = "";
CzechPhrases[Phrases.THE_FROM] = "";
CzechPhrases[Phrases.THE_TO] = "";
CzechPhrases[Phrases.AND] = "a";
CzechPhrases[Phrases.OR] = "nebo";
CzechPhrases[Phrases.OTHER] = "jiný";
CzechPhrases[Phrases.ANOTHER] = "další";
CzechPhrases[Phrases.PILE] = "hromádka";
CzechPhrases[Phrases.UNDO] = "Zpět";
CzechPhrases[Phrases.YOU] = "Ty";
CzechPhrases[Phrases.WOULD] = "Měl bys";
CzechPhrases[Phrases.YOU_MAY] = "Můžeš...";
CzechPhrases[Phrases.COMMA] = ", ";
CzechPhrases[Phrases.PERIOD] = ".";
CzechPhrases[Phrases.COMPLEX_STORY_LINE] = "První, Druhý";
CzechPhrases[Phrases.COIN] = "Mince";
CzechPhrases[Phrases.COINS] = "Mince";
CzechPhrases[Phrases.COINS_MANY] = "Mincí";
CzechPhrases[Phrases.POTION] = "Lektvar";
CzechPhrases[Phrases.POTIONS] = "Lektvary";
CzechPhrases[Phrases.POTIONS_MANY] = "Lektvarů";
CzechPhrases[Phrases.DEBT] = "Dluh";

CzechPhrases[Phrases.VERSION] = "Verze";
CzechPhrases[Phrases.USERNAME] = "Přezdívka";
CzechPhrases[Phrases.PASSWORD] = "Heslo";
CzechPhrases[Phrases.CONFIRM_PASSWORD] = "Potvrdit heslo";
CzechPhrases[Phrases.FORGOT_YOUR_PASSWORD] = "Zapomněli jste heslo?";
CzechPhrases[Phrases.CREATE_ACCOUNT] = "Vytvořit účet";
CzechPhrases[Phrases.EMAIL] = "Email";
CzechPhrases[Phrases.SIGN_UP] = "Zaregistrovat";
CzechPhrases[Phrases.LOGIN] = "Přihlásit";
CzechPhrases[Phrases.KICK] = "Vykopnout";
CzechPhrases[Phrases.RESIGN] = "Vzdát se";
CzechPhrases[Phrases.CREATE_TABLE] = "Vytvořit stůl";
CzechPhrases[Phrases.LEAVE_TABLE] = "Opustit stůl";
CzechPhrases[Phrases.MESSAGE] = "zpráva";
CzechPhrases[Phrases.START_GAME] = "Začít hru";
CzechPhrases[Phrases.READY] = "Připraven";
CzechPhrases[Phrases.NOT_READY] = "Nepřipraven";
CzechPhrases[Phrases.PLAYERS] = "Hráči";
CzechPhrases[Phrases.SPECTATORS] = "Pozorovatelé";
CzechPhrases[Phrases.HOST] = "Vytvořit";
CzechPhrases[Phrases.RANDOM] = "Náhodný";
CzechPhrases[Phrases.BASIC_OPTIONS] = "Základní nastavení";
CzechPhrases[Phrases.ADVANCED_OPTIONS] = "Rozšířené nastavení";
CzechPhrases[Phrases.YES] = "Ano";
CzechPhrases[Phrases.NO] = "Ne";
CzechPhrases[Phrases.FRIENDS_ONLY] = "Jen přátelé";
CzechPhrases[Phrases.EVENTS_AND_LANDMARKS] = "Události a Milníky";
CzechPhrases[Phrases.TRUE] = "pravda";
CzechPhrases[Phrases.FALSE] = "nepravda";
CzechPhrases[Phrases.QUICK_MATCH] = "Rychlá hra";
CzechPhrases[Phrases.GOOD_MATCH] = "Dobrá hra";
CzechPhrases[Phrases.CANCEL] = "Zrušit";
CzechPhrases[Phrases.VIEW_TRASH] = "Smetiště";
CzechPhrases[Phrases.VIEW_KINGDOM] = "Království";
CzechPhrases[Phrases.VIEW_PLAY_AREA] = "Herní pole";
CzechPhrases[Phrases.LEAVE] = "Odejít";
CzechPhrases[Phrases.TOTAL_IN_QUEUE] = "Uživatelů ve frontě";
CzechPhrases[Phrases.EDIT_TABLE] = "Změnit stůl";
CzechPhrases[Phrases.NEED_HELP] = "Potřebujete pomoct?";
CzechPhrases[Phrases.PLAY] = "Hraj";
CzechPhrases[Phrases.MAKE_RESIGN] = "Donutit USERNAME vzdát se?";
CzechPhrases[Phrases.UNDO_REQUEST_SINGULAR] = "Požadavek na 1 krok zpět";
CzechPhrases[Phrases.UNDO_REQUEST_PLURAL] = "Požadavek na NUMBER kroky zpět";
CzechPhrases[Phrases.UNDO_REQUEST_MANY] = "Požadavek na NUMBER kroků zpět";
CzechPhrases[Phrases.PENDING_UNDO_REQUEST] = "Čekání na požadavek Zpět...";
CzechPhrases[Phrases.SPECTATOR_UNDO_REQUEST] = "USERNAME požádal o krok zpět";
CzechPhrases[Phrases.GRANT] = "Uděleno";
CzechPhrases[Phrases.ACCEPT] = "Přijmout";
CzechPhrases[Phrases.DECLINE] = "Odmítnout";
CzechPhrases[Phrases.DENY] = "Odmítnout";
CzechPhrases[Phrases.UNDO_DENIED] = "Požadavek Zpět byl zamítnut.";
CzechPhrases[Phrases.UNDO_CANCELLED] = "Požadavek Zpět byl zrušen.";
CzechPhrases[Phrases.TOC_SENTENCE] = "Četl jsem a přijímám TERMS_OF_SERVICE.";
CzechPhrases[Phrases.TERMS_OF_SERVICE] = "Licenční ujednání";
CzechPhrases[Phrases.TERMS_OF_SERVICE_URL] = ""; /* edit it after writing translated terms of service */
CzechPhrases[Phrases.RECONNECTING] = "Opakované připojení...";
CzechPhrases[Phrases.PLAYER_JOINED_TABLE] = "PLAYER_NAME se přidal ke stolu.";
CzechPhrases[Phrases.PLAYER_LEFT_TABLE] = "PLAYER_NAME odešel od stolu.";
CzechPhrases[Phrases.JOINING_GAME_MESSAGE] = "Připojování ke hře #NUMBER na GAMESERVER_NAME.";
CzechPhrases[Phrases.SIGNING_UP] = "Vytvářím účet...";
CzechPhrases[Phrases.ACTION] = "Akce";
CzechPhrases[Phrases.ACTIONS] = "Akce";
CzechPhrases[Phrases.BUY] = "Nákup";
CzechPhrases[Phrases.BUYS] = "Nákupy";
CzechPhrases[Phrases.KICK_SUCCEEDED] = "Vykopnutí úspěšné";
CzechPhrases[Phrases.CONNECTION_CLOSED] = "Připojení nevylo navázáno nebo se ztratilo.";
CzechPhrases[Phrases.NEW_PASSWORD] = "Nové heslo";
CzechPhrases[Phrases.RETRY] = "Opakovat";
CzechPhrases[Phrases.FRIEND] = "Přítel";
CzechPhrases[Phrases.ACTIVITY] = "Aktivita";
CzechPhrases[Phrases.STATUS] = "Status";
CzechPhrases[Phrases.PURCHASE] = "Nákup";
CzechPhrases[Phrases.UNTIL] = "než";
CzechPhrases[Phrases.BUY_FOR_AMOUNT] = "Koupit za AMOUNT";
CzechPhrases[Phrases.CHANGE_PASSWORD] = "Změnit";
CzechPhrases[Phrases.HIDE] = "Skrýt";
CzechPhrases[Phrases.CONFIRM] = "Potvrdit";
CzechPhrases[Phrases.JOIN] = "pripojit";
CzechPhrases[Phrases.TURN] = "Tah";
CzechPhrases[Phrases.CARD] = "Karta";
CzechPhrases[Phrases.DESCRIPTION] = "Popis";
CzechPhrases[Phrases.SCORE] = "Skóre";
CzechPhrases[Phrases.TURNS] = "Tahy";
CzechPhrases[Phrases.NOTHING] = "nic";
CzechPhrases[Phrases.SPECTATE] = "pozorovat";
CzechPhrases[Phrases.FRIENDS] = "Přátelé";
CzechPhrases[Phrases.FOLLOWING] = "Sledovat";
CzechPhrases[Phrases.FOLLOWERS] = "Sledující";
CzechPhrases[Phrases.UNFRIEND] = "Odebrat přítele";
CzechPhrases[Phrases.UNFOLLOW] = "Přestat sledovat";
CzechPhrases[Phrases.BEFRIEND] = "Přidat přítele";
CzechPhrases[Phrases.FRIENDS_EXPLANATION] = "Vzájemní přátelé";
CzechPhrases[Phrases.FOLLOWING_EXPLANATION] = "Odebral jsi přátele";
CzechPhrases[Phrases.FOLLOWERS_EXPLANATION] = "Odebrali tě z přátel";
CzechPhrases[Phrases.ADD] = "Přidat";
CzechPhrases[Phrases.TYPE_FRIEND] = "Napiš přezdívku přítele";
CzechPhrases[Phrases.UNLOCKS] = "odemyká";
CzechPhrases[Phrases.SELECT_YOUR_COUNTRY] = "Vyberte vaši zemi";
CzechPhrases[Phrases.SELECT_A_COUNTRY] = "Vyberte zemi";
CzechPhrases[Phrases.KICK_AND_RESIGN] = "Vykopnout & Vzdát se";
CzechPhrases[Phrases.BETWEEN_TURNS] = "Mezi tahy";
CzechPhrases[Phrases.PLAYER] = "Hráč";
CzechPhrases[Phrases.RESIGNED] = "to vzdal";
CzechPhrases[Phrases.YOU_RESIGNED] = "Ty jsi to vzdal";
CzechPhrases[Phrases.YOU_LOSE] = "Prohrál jsi";
CzechPhrases[Phrases.YOU_TIE] = "Remíza";
CzechPhrases[Phrases.YOU_WIN] = "Vyhrál jsi";
CzechPhrases[Phrases.BUYS_MANY] = "Nákupy";
CzechPhrases[Phrases.ACTIONS_MANY] = "Akce";
CzechPhrases[Phrases.TOKEN] = "Měďák";
CzechPhrases[Phrases.TOKENS] = "Měďáky";
CzechPhrases[Phrases.TOKENS_MANY] = "Měďáků";
CzechPhrases[Phrases.OVERPAY] = "Přeplatit";
CzechPhrases[Phrases.BID] = "Přihodit";
CzechPhrases[Phrases.TAKE] = "Vzít si";
CzechPhrases[Phrases.BOTTOM] = "Spodek";
CzechPhrases[Phrases.TOP] = "Vršek";
CzechPhrases[Phrases.LOCATION] = "Umístění";
CzechPhrases[Phrases.CLEAN_UP] = "Vyčištění";
CzechPhrases[Phrases.PLAN_CLEAN_UP] = "Naplánuj vyčištění";
CzechPhrases[Phrases.EXECUTE] = "Vykonat";
CzechPhrases[Phrases.MOVEMENT_VERB] = "VERB.";
CzechPhrases[Phrases.MOVEMENT_VERB_OBJECT] = "VERB OBJECT.";
CzechPhrases[Phrases.FORUM] = "Forum";
CzechPhrases[Phrases.VIEW_LOG] = "Výpis";
CzechPhrases[Phrases.REPLAY_GAME] = "Opakovat hru";
CzechPhrases[Phrases.REMOVE_REPLAY] = "Odstranit opakování";
CzechPhrases[Phrases.SHELTERS] = "Přístřešky";
CzechPhrases[Phrases.NO_SHELTERS] = "Žádné Přístřešky";
CzechPhrases[Phrases.COLONIES] = "Kolonie";
CzechPhrases[Phrases.NO_COLONIES] = "Žádné Kolonie";
CzechPhrases[Phrases.PLAYER_ORDER] = "Pořadí hráčů";
CzechPhrases[Phrases.ENTER_USERNAME] = "Vlož svou přezdívku.";
CzechPhrases[Phrases.ENTER_LOGIN_CREDENTIALS] = "Vlož své přihlašovací údaje.";
CzechPhrases[Phrases.SUBMIT] = "Odeslat";
CzechPhrases[Phrases.DONE] = "Hotovo";
CzechPhrases[Phrases.GAME_ID] = "ID číslo hry";
CzechPhrases[Phrases.DECISION_INDEX] = "Rozhodovací Index";
CzechPhrases[Phrases.ORIGINAL_PLAYER] = "Původní hráč";
CzechPhrases[Phrases.ORIGINAL_PLAYERS] = "původní hráči";
CzechPhrases[Phrases.ADD_BOT] = "Přidat Počítač";
CzechPhrases[Phrases.DECISION] = "Rozhodnutí";
CzechPhrases[Phrases.LOAD_GAME] = "Načíst hru";
CzechPhrases[Phrases.CHANGE_REPLAY] = "Změnit Opakování";
CzechPhrases[Phrases.NAME_CARD] = "jmenuj kartu";
CzechPhrases[Phrases.LOAD_OLD_GAME] = "Načíst starší hru";
CzechPhrases[Phrases.CANCEL_RESIGN] = "Zrušit & Vzdát se";
CzechPhrases[Phrases.LOGOUT] = "Odhlásit";
CzechPhrases[Phrases.FIRST] = "První";
CzechPhrases[Phrases.LAST] = "Poslední";
CzechPhrases[Phrases.INBOX] = "Přijaté";
CzechPhrases[Phrases.MESSAGES] = "Zprávy";
CzechPhrases[Phrases.MARK_READ] = "Označit jako přečtené";
CzechPhrases[Phrases.DELETE] = "Smazat";
CzechPhrases[Phrases.NO_MESSAGES] = "Žádné zprávy";
CzechPhrases[Phrases.LEADERBOARD] = "Výsledková tabulka";
CzechPhrases[Phrases.LAST_UPDATED] = "Aktualizováno";
CzechPhrases[Phrases.RANK] = "#";
CzechPhrases[Phrases.NAME] = "jméno";
CzechPhrases[Phrases.RATING] = "úroveň";
CzechPhrases[Phrases.TREND] = "";
CzechPhrases[Phrases.VP] = "VB";
CzechPhrases[Phrases.GAME_ID_SHOULD_BE_NUMBER] = "ID číslo Hry musí být číslo.";
CzechPhrases[Phrases.FAQ] = "Otázky a Odpovědi";
CzechPhrases[Phrases.RESULTS] = "Výsledky";
CzechPhrases[Phrases.SKILL] = "μ";
CzechPhrases[Phrases.SKILL_RANGE] = "Rozsah zkušeností";
CzechPhrases[Phrases.DEVIATION] = "φ";
CzechPhrases[Phrases.VOLATILITY] = "σ";
CzechPhrases[Phrases.GAMES] = "Hry";
CzechPhrases[Phrases.GLICKO2] = "Glicko-2";
CzechPhrases[Phrases.IMPLEMENTATION] = "Implementace";
CzechPhrases[Phrases.RATED_QUEUE] = "Uživatelé v hodnocené frontě";
CzechPhrases[Phrases.UNRATED_QUEUE] = "Uživatelé v nehodnocené frontě";
CzechPhrases[Phrases.UNRATED_MATCH] = "Nehodnocená hra";
CzechPhrases[Phrases.LEVEL] = "úroveň";
CzechPhrases[Phrases.TWO_PLAYERS] = "2 Hráči";
CzechPhrases[Phrases.THREE_FOUR_PLAYERS] = "3-4 Hráči";
CzechPhrases[Phrases.GAME] = "Hra";
CzechPhrases[Phrases.RATED] = "hodnoceno";
CzechPhrases[Phrases.UNRATED] = "nehodnoceno";
CzechPhrases[Phrases.OTHER_CARDS] = "ostatní";
CzechPhrases[Phrases.OTHER_CARD] = "ostatních";
CzechPhrases[Phrases.YOUR_TURN] = "Tvůj tah!";
CzechPhrases[Phrases.LOADING_GAME] = "Načítání hry...";
CzechPhrases[Phrases.BLACKLIST] = "Černá listina";
CzechPhrases[Phrases.REMOVE] = "Odebrat";
CzechPhrases[Phrases.BLACKLIST_USER] = "Přidat uživatele do Černé listiny";
CzechPhrases[Phrases.BEFRIEND_USER] = "Přidat uživatele do Přátel";
CzechPhrases[Phrases.BLACKLISTED] = "Přidán do Černé listiny";
CzechPhrases[Phrases.FRIENDLIST_EMPTY] = "Tvůj seznam přátel je momentálně prázdný.";
CzechPhrases[Phrases.BLACKLIST_EMPTY] = "Tvá Černá listina je momentálně prázdná.";
CzechPhrases[Phrases.PLAYERS_OF_TOTAL_PLAYERS] = "NUM_PLAYERS z NUM_TOTAL_PLAYERS";
CzechPhrases[Phrases.SHOW_RUNNING_GAMES] = "Ukázat běžící hry";
CzechPhrases[Phrases.SHOW_1P_GAMES] = "Ukázat hry s 1 hráčem";
CzechPhrases[Phrases.SHOW_2P_GAMES] = "Ukázat hry s 2 hráči";
CzechPhrases[Phrases.SHOW_3XP_GAMES] = "Ukázat hry s 3+ hráči";
CzechPhrases[Phrases.SHOW_BOT_GAMES] = "Ukázat hry s Počítači";
CzechPhrases[Phrases.FIND_GAME] = "Automatický start";
CzechPhrases[Phrases.FIND_BOT_GAME] = "Hra s Počítači";
CzechPhrases[Phrases.START_SEARCH] = "Začít vyhledávání";
CzechPhrases[Phrases.CANCEL_SEARCH] = "Zrušit vyhledávání";
CzechPhrases[Phrases.SEARCHING_2P] = "Dva hráči";
CzechPhrases[Phrases.SEARCHING_3P] = "Tři hráči";
CzechPhrases[Phrases.SEARCHING_RATED] = "Hodnocená hra";
CzechPhrases[Phrases.SEARCHING_PRACTICE] = "Zkušební hra";
CzechPhrases[Phrases.MAX] = "Max"; // TRANSLATE ME
CzechPhrases[Phrases.MIN] = "Min"; // TRANSLATE ME
CzechPhrases[Phrases.SEARCHING] = "hledání";
CzechPhrases[Phrases.START_BOT_GAME_1] = "1 Počítač";
CzechPhrases[Phrases.START_BOT_GAME_2] = "2 Počítače";
CzechPhrases[Phrases.START_BOT_GAME_3] = "3 Počítače";
CzechPhrases[Phrases.NO_FRIENDS_ONLINE] = "Nejsou připojeni žádní přátelé";
CzechPhrases[Phrases.AUTOMATCH_FEEDBACK] = "Automatický start ve frontě";
CzechPhrases[Phrases.NOBODY_ELSE_IN_QUEUE] = "Nikdo jiný ve frontě není.";
CzechPhrases[Phrases.CANCEL_SMALL] = "Zrušit";
CzechPhrases[Phrases.MESSAGE_SPECTATORS] = "zprávy Pozorovatelů";
CzechPhrases[Phrases.MESSAGE_PLAYERS] = "zprávy Hráčů";
CzechPhrases[Phrases.PLAYER_REPLACED] = "LEAVING_PLAYER byl nahrazen hráčem REPLACEMENT.";
CzechPhrases[Phrases.RESIGN_PROMPT] = "Vzdát se?";
CzechPhrases[Phrases.RESIGN_MULTIPLE_OPPONENTS] = "Varování: Vzdávání se hry s více hráči může být považováno jako nesportovní.";
CzechPhrases[Phrases.CONTINUE_WITH_BOTS] = "Pokračovat s Počítači";
CzechPhrases[Phrases.PLAYER_DISCONNECTED] = "PLAYER_NAME se odpojil.";
CzechPhrases[Phrases.PLAYER_RECONNECTED] = "PLAYER_NAME se připojil znovu.";
CzechPhrases[Phrases.VALIDATE_BONUS_CODE] = "Ověřit";
CzechPhrases[Phrases.NEW_TO_DOMINION] = "Jak hrát";
CzechPhrases[Phrases.SEARCHING_PREVIEWS] = "Ukázky Zveřince";
CzechPhrases[Phrases.AUTOPLAY_CLEANUP] = "Automatické hraní v uklízecí fázi";
CzechPhrases[Phrases.AUTOPLAY_INTERFACE] = "Výchozí automatické hraní";
CzechPhrases[Phrases.AUTOPLAY_OTHER] = "Jiné automatické hraní";
CzechPhrases[Phrases.GAME_ENDED] = "Hra skončila.";
CzechPhrases[Phrases.OK] = "Ok";
CzechPhrases[Phrases.HAS_RESIGNED] = "PLAYER_NAME se vzdal.";
CzechPhrases[Phrases.MOUSE_TOY] = "Karta dána vedle pro Způsob Myši";
// Intro page of Store tab in Lobby
CzechPhrases[Phrases.STORE_WELCOME] = "Dominion Obchod Online";
CzechPhrases[Phrases.ONE_MONTH] = "1 měsíc";
CzechPhrases[Phrases.TWO_MONTHS] = "2 měsíce";
CzechPhrases[Phrases.THREE_MONTHS] = "3 měsíce";
CzechPhrases[Phrases.SIX_MONTHS] = "6 měsíců";
CzechPhrases[Phrases.NINE_MONTHS] = "9 měsíců";
CzechPhrases[Phrases.FIFTEEN_MONTHS] = "15 měsíců";
CzechPhrases[Phrases.EIGHTEEN_MONTHS] = "18 mšsíců";
CzechPhrases[Phrases.ONE_YEAR] = "1 rok";
CzechPhrases[Phrases.TWO_YEAR] = "2 roky";
CzechPhrases[Phrases.CHOOSE_SUBSCRIPTION] = "Vyberte předplatné: ";
CzechPhrases[Phrases.CHOOSE_DURATION] = "Vyberte délku předplatného začínající dneškem.";
CzechPhrases[Phrases.CONTINUE] = "Pokračovat";
CzechPhrases[Phrases.ADD_LATEST_EXPANSION] = "Chcete přidat Zvěřinec do vašeho předplatného?";
CzechPhrases[Phrases.INTRODUCTION_TO_STORE] = "Úvod do obchodu";
CzechPhrases[Phrases.CHECK_YOUR_PURCHASE] = "Zkontrolujte si údaje o platbě";
CzechPhrases[Phrases.SELLER_INFO] = "Informace o prodejci";
CzechPhrases[Phrases.INVOICE_NEED_ANSWERS] = "Pro zobrazení faktury je potřeba správně zodpovědět všechny otázky.";
CzechPhrases[Phrases.BUYING_SUBSCRIPTION_TO] = "Nákup předplatného";
CzechPhrases[Phrases.BUYING_SUBSCRIPTION_TILL] = "Nákup předplatného doteď";
CzechPhrases[Phrases.AMOUNT] = "Množství";
CzechPhrases[Phrases.ITEM] = "Předmět";
CzechPhrases[Phrases.YOUR_SUBSCRIPTION] = "Vaše předplatné ";
CzechPhrases[Phrases.YOUR_ACCOUNT] = "Váč účet ";
CzechPhrases[Phrases.REDIRECTED_TO_PAYMENT_PROVIDER] = "Přepojuji platbu na novou kartu prhlížeče...";
CzechPhrases[Phrases.PAYMENT_REDIRECTION_FAILED] = "Pokud jste nebyl přepojen, ujistěte se, že máte povolené otevírání nových karet.";
CzechPhrases[Phrases.REDIRECT_FAILED_BUTTON] = "Pokud přepojení selhalo, stiskněte zde";
CzechPhrases[Phrases.REDIRECT_YOURSELF] = "Pokud neproběhlo přepojení, stiskněte zde";
CzechPhrases[Phrases.THIS_LINK] = "tento odkaz";
CzechPhrases[Phrases.ZERO_INVOICE_1] = "Vámi zvolené předplatné nepřidá nic nového do vašeho stávajícího.";
CzechPhrases[Phrases.ZERO_INVOICE_2] = "Prosím zvolte jiné předplatné nebo jinou délku.";
CzechPhrases[Phrases.SORRY_STORE_SERVICE_DOWN] = "Obchod je momentálně uzavřen. Omlouváme se za nepříjemnosti.";
CzechPhrases[Phrases.CONNECTING_TO_STORE_SERVICE] = "Připojuji...";
CzechPhrases[Phrases.UPGRADE_DURATION] = "Než stávající předplatné vyprší";
CzechPhrases[Phrases.FOR_FREE] = "Zdarma";
CzechPhrases[Phrases.MAXED_OUT_SUBSCRIPTIONS] = "Vaše předplatné má maximální délku a obsahuje všechny rozšíření";
CzechPhrases[Phrases.IP_COUNTRY_MISMATCH] = "Zadaná země se neshoduje s IP adresou vašeho PC";
CzechPhrases[Phrases.EXPIRES_AT] = "předplatné končí ";
CzechPhrases[Phrases.TOOLTIP_RANDOM_SELECTION] = "Stiskni pro přidání náhodné karty z ARGUMENT0 do Království.";
CzechPhrases[Phrases.TOOLTIP_LANDSCAPE_SLOT] = "Tento slot by měl být pro ARGUMENT0. Stiskni obrázek pro změnu. Odstraň označení pro odebrání slotu.";
CzechPhrases[Phrases.LANDSCAPE_UNUSED] = "prázdný";
CzechPhrases[Phrases.TOOLTIP_LANDSCAPE_PLUS_SLOT] = "Vytvoř nový slot pro karty krajiny a poté vyber, které typy krajin se mohou objevit.";
CzechPhrases[Phrases.LANDSCAPE] = "krajina";

CzechPhrases[Phrases.PREVIEW_BOT_MATCH] = "Ukázka proti Počítači";

CzechPhrases[Phrases.TOOLTIP_USE_COFFERS] = "Stiskni pro útratu 1 Měďáku";
CzechPhrases[Phrases.TOOLTIP_CANT_USE_COFFERS] = "Utrácet Meďáky můžeš jen během fáze Nákupu než začneš nakupovat";
CzechPhrases[Phrases.TOOLTIP_USE_VILLAGER] = "Stiskni pro použití 1 Vesničana";
CzechPhrases[Phrases.TOOLTIP_CANT_USE_VILLAGER] = "Vesničany můžeš použít jen ve fázi Akce";
//CzechPhrases[Phrases.CARDS_BANNED_BY_PLAYERS]        = "Karty zakázané hráči: ";
CzechPhrases[Phrases.AUTOCOMPLETE_CARD_LISTS] = "Vlož karty pro přidání";
CzechPhrases[Phrases.RESPECTED_CARD_LISTS] = "Respektovat seznam karet";
CzechPhrases[Phrases.BANNED_CARDS] = "zakázané karty";
CzechPhrases[Phrases.LIKED_CARDS] = "oblíbené karty";
CzechPhrases[Phrases.DISLIKED_CARDS] = "neoblíbené karty";
CzechPhrases[Phrases.KINGDOM_GENERATOR_PERCENTAGES] = "Království vytvořené s těmito procenty:";
CzechPhrases[Phrases.VILLAGERS] = "Vesničani";
CzechPhrases[Phrases.DAYS] = "dny";
CzechPhrases[Phrases.SPECIAL_KINGDOM_RULES] = "Speciální pravidla Království:";
CzechPhrases[Phrases.SHOW_CHAT] = "Show Chat";
CzechPhrases[Phrases.HIDE_CHAT] = "Hide Chat";
CzechPhrases[Phrases.UNBLACKLIST] = "Remove from blacklist";
CzechPhrases[Phrases.AUTOMATCH_LABEL_CARD_POOL_RANGE] = "Pool range";
CzechPhrases[Phrases.AUTOMATCH_LABEL_EXPANSIONS] = "Expansions";
CzechPhrases[Phrases.AUTOMATCH_LABEL_RATING] = "Rating";
CzechPhrases[Phrases.BASE_ONLY] = "Base";
CzechPhrases[Phrases.SIMPLE] = "Simple";
CzechPhrases[Phrases.TOOLTIP_OWNED_ENABLED] = "Click to disable this expansion.<br/>When disabled, you won't bring it to your games.";
CzechPhrases[Phrases.TOOLTIP_OWNED_DISABLED] = "Click to enable this expansion.<br/>When enabled, you will bring it to your games";
CzechPhrases[Phrases.TOOLTIP_VOID_ENABLED] = "Click to disable this expansion.<br/>Disabling has no effect if you're not subscribed.";
CzechPhrases[Phrases.TOOLTIP_VOID_DISABLED] = "Click to enable this expansion.<br/>Enabling has no effect if you're not subscribed.";
CzechPhrases[Phrases.TOOLTIP_BASE_SET] = "You can't disable the base set.";
CzechPhrases[Phrases.CHOOSE_CARD_POOL] = "Choose Card Pool";
CzechPhrases[Phrases.USE_LEVEL] = "Use level";
CzechPhrases[Phrases.VIEW_INTRODUCTION_CARD_SELECTION] = "View introduction to card selection";
CzechPhrases[Phrases.VIEW_CARD_POOL_LEVELS] = "View Card Pool levels";
CzechPhrases[Phrases.CARD_POOL_BOX_HEADER] = "Card Pool";
CzechPhrases[Phrases.CARD_POOL_LEVEL] = "Level";
CzechPhrases[Phrases.CARD_POOL_LEVEL_SMALL] = "level";
CzechPhrases[Phrases.USED_CARD_POOL_LEVEL] = "Card Pool: level ";
CzechPhrases[Phrases.SHOW_LEVEL] = "Card Pool: ";
CzechPhrases[Phrases.SUBSCRIPTION_YES] = "Thank you for subscribing.";
CzechPhrases[Phrases.SUBSCRIPTION_NO] = "You don't have a subscription.";
CzechPhrases[Phrases.SUBSCRIPTION_FOR] = "Your subscription lasts another";
CzechPhrases[Phrases.EMPTY_BECAUSE_DISABLED] = "You can't select cards from this expansion because you have it disabled in the Account tab.";
CzechPhrases[Phrases.EMPTY_BECAUSE_NOT_SUBSCRIBED] = "You can't select cards from this expansion because you don't have a subscription to it.";
CzechPhrases[Phrases.EXPLAIN_SUBSCRIPTIONS] = "\nThis site allows you to play with the base set for free. \nIf you want to play with the expansions or want to support the site, please consider subscribing. \nYou can also try to find an opponent that provides the cards. If you want to wait for such an opponent in the queue, check the \"Wait for Expansions\"-checkbox.\n";
CzechPhrases[Phrases.CARD_SELECTION_INTRODUCTION_TEXT] = ["<span class=\"game-concept\">Automatch</span> offers three different ways to limit the cards you play with.\n    Alternatively, you can create a <span class=\"game-concept\">New Table</span> and find your own opponents, allowing you to manually select everything.", "<div class=\"header\">Card Pool</div>\n        If you're in the process of learning the cards, this is probably what you want to use. \n        We've created 10 different levels, starting with the base set. \n        You are completely free to choose your own progression here. \n        You can read about the new mechanics on each level beforehand. \n        <span class=\"game-concept\">Card Pool</span> should be set to the level you're comfortable with.\n        <span class=\"game-concept\">Pool range</span> is something you could set to zero, meaning you always wait for a game at exactly the desired level. \n        You can also set it higher if you're willing to help out others or just want a faster search.", "<div class=\"header\">Expansion Selection</div>\n        Subscribers to the site can select which expansions they bring along to their games, either by clicking <span class=\"game-concept\">Manage my expansions</span> or using the top-menu <span class=\"game-concept\">Account</span>.\n        <span class=\"game-concept\">Wait for expansions</span> is a feature for non-subscribers. It allows you to wait in the queue until a subscriber wants to play with you.", "<div class=\"header\">Cardlists</div>\n        The top-menu <span class=\"game-concept\">Cardlists</span> allows you to create small personal lists of cards. Cards you ban will never appear in your games, and cards you dislike will be selected 50% less."];

CzechPhrases[Phrases.LEVEL_DESCRIPTIONS] = ["Base only", "Simple cards", "Duration, Special Treasures, Platinum & Colony", "When-gain, When-trash, VP tokens", "Coffers, Villagers, Exile, This turn", "While-in-play, When discard, Mats", "Events, Reserve, Adventures Tokens", "Projects, Landmarks, Night, Shelters, Horses", "Extra cards, Artifacts, Boons, Hexes, Heirlooms", "All cards"];
CzechPhrases[Phrases.NEW_CARDS] = "New Cards";
CzechPhrases[Phrases.INHERITANCE_INSTRUCTIONS] = "Play the card with//your Estate token,//leaving it there.";
CzechPhrases[Phrases.INDIVIDUAL_EXPANSIONS] = "Individual Expansions";
CzechPhrases[Phrases.WARNING_SEARCHING_SUBSCRIBER] = "Searching for an opponent with a Subscription";
CzechPhrases[Phrases.WARNING_MAX_RATING_LOW] = "Max rating is set quite low";
CzechPhrases[Phrases.WARNING_MIN_RATING_HIGH] = "Min rating is set quite high";