"use strict";

webclient.service('sessionService', ['$rootScope', 'serverMessenger', function ($rootScope, serverMessenger) {
    var self = this;
    var scope = $rootScope.$new(true);
    var session = null;

    self.getSession = function () {
        return session;
    };

    self.clear = function () {
        session = null;
        if (localStorageIsEnabled()) {
            localStorage.playerId = "";
            localStorage.sessionId = "";
        }
        if (sessionStorageIsEnabled()) {
            sessionStorage.sessionId = "";
            sessionStorage.playerId = "";
        }
    };

    self.hasStoredSession = function () {
        if (!localStorageIsEnabled()) return false;
        if (!sessionStorageIsEnabled()) return false;
        var id = parseInt(localStorage.playerId);
        if (isNaN(id)) return false;
        var hasLocalStorageSessionId = isNonEmpty(localStorage.sessionId);
        var hasSessionStorageSessionId = isNonEmpty(sessionStorage.sessionId);
        if (hasLocalStorageSessionId && !hasSessionStorageSessionId) return true;
        if (!hasLocalStorageSessionId && hasSessionStorageSessionId) return true;
        return localStorage.sessionId === sessionStorage.sessionId;
    };

    self.getStoredSession = function () {
        var playerId = "";
        var sessionId = "";
        if (isNonEmpty(localStorage.sessionId)) {
            playerId = localStorage.playerId;
            sessionId = localStorage.sessionId;
        } else {
            playerId = sessionStorage.playerId;
            sessionId = sessionStorage.sessionId;
        }
        return new Session(playerId, sessionId);
    };

    self.login = function () {
        var session = self.getStoredSession();
        timeLog("Attempting login with session id " + session.sessionId);
        serverMessenger.loginWithSession(session.playerId, session.sessionId);
    };

    self.reconnect = function () {
        var session = self.getStoredSession();
        timeLog("Attempting reconnect with session id " + session.sessionId);
        serverMessenger.reconnectWithSession(session.playerId, session.sessionId);
    };

    scope.$on(Events.LOGIN_SUCCESS, function (event, loginSuccess) {
        session = new Session(loginSuccess.playerId, loginSuccess.sessionId);
        if (localStorageIsEnabled()) {
            localStorage.playerId = loginSuccess.playerId;
            localStorage.sessionId = loginSuccess.sessionId;
        }
        if (sessionStorageIsEnabled()) {
            sessionStorage.playerId = loginSuccess.playerId;
            sessionStorage.sessionId = loginSuccess.sessionId;
        }
    });

    var REASONS_TO_CLEAR_SESSION = [FailureReasons.INVALID_SESSION_ID, FailureReasons.ALREADY_LOGGED_IN, FailureReasons.BAD_CLIENT_VERSION];

    scope.$on(Events.COMMAND_FAILED, function (event, failure) {
        if (REASONS_TO_CLEAR_SESSION.some(function (r) {
            return r === failure.failureReason;
        })) {
            self.clear();
        }
    });

    scope.$on(Events.LOGOUT, function () {
        serverMessenger.logout();
        self.clear();
        location.reload();
    });
}]);