"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PanelSizes = {
	NONE: "collapsed",
	MINI: "mini",
	FULL: "full"
};

var OptionsModel = function () {
	function OptionsModel(game, serverMessenger, gameBroadcaster, preferences) {
		_classCallCheck(this, OptionsModel);

		this.game = game;
		this.gameBroadcaster = gameBroadcaster;
		this.serverMessenger = serverMessenger;
		this.preferences = preferences;
		this.renderedPanels = 0;

		this.chatWeight = preferences.getUserPrefValue(UserPrefIds.CHAT_PANEL_WEIGHT);
		this.logWeight = preferences.getUserPrefValue(UserPrefIds.LOG_PANEL_WEIGHT);
		processPreferences(this.preferences);
	}

	_createClass(OptionsModel, [{
		key: "normalize",
		value: function normalize() {
			if (this.chatWeight + this.logWeight < 10) {
				this.chatWeight = 40;
				this.logWeight = 60;
			}
			this.chatWeight = Math.floor(this.chatWeight);
			this.logWeight = Math.ceil(this.logWeight);
			this.syncPreference(UserPrefIds.CHAT_PANEL_WEIGHT, this.chatWeight);
			this.syncPreference(UserPrefIds.LOG_PANEL_WEIGHT, this.logWeight);
		}
	}, {
		key: "readAutoplaySettings",
		value: function readAutoplaySettings(reader) {
			this.autoplaySettings = AutoPlaySettings.parse(reader);
		}
	}, {
		key: "readTableOptions",
		value: function readTableOptions(reader) {
			this.tableOptions = TableOptions.parse(reader);
		}
	}, {
		key: "handleChange",
		value: function handleChange(change) {
			if (change.rule.id instanceof TableRuleId) {
				if (change.rule.id === TableRuleIds.PLAYERS_CAN_SEE_SPECTATOR_CHAT) {
					this.tableOptions.playersSeeSpecChat = change.rule.value;
					this.game.chatView.playersSeeSpecChat = change.rule.value;
					this.game.chatView.digest();
				}
			}
		}
	}, {
		key: "syncPreference",
		value: function syncPreference(preferenceType, value) {
			var preference = new UserPreference(preferenceType, value);
			this.preferences.setUserPrefValue(preferenceType, value);
			this.serverMessenger.setUserPreference(preference);
			processPreferences(this.preferences);
			this.game.questionModel.resetQuestions();
		}
	}, {
		key: "getPreference",
		value: function getPreference(preferenceType) {
			return this.preferences.getUserPrefValue(preferenceType);
		}
	}, {
		key: "getChatClass",
		value: function getChatClass() {
			return getByOrdinal(PanelSizes, this.chatMode);
		}
	}, {
		key: "getLogClass",
		value: function getLogClass() {
			return getByOrdinal(PanelSizes, this.logMode);
		}
	}, {
		key: "updatePanelHeights",
		value: function updatePanelHeights() {
			if (this.renderedPanels < 3) return;
			var specWeight = this.game.specView.showing ? 20 : 0;
			var totalWeight = this.logWeight + this.chatWeight + specWeight;
			this.game.logModel.logView.height = totalWeight === 0 ? 0 : 95.5 * this.logWeight / totalWeight;
			this.game.chatView.height = totalWeight === 0 ? 0 : 95.5 * this.chatWeight / totalWeight;
			this.game.chatView.top = totalWeight === 0 ? 0 : 95.5 * (this.logWeight + specWeight) / totalWeight;
			this.game.specView.height = totalWeight === 0 ? 0 : 95.5 * specWeight / totalWeight;
			this.game.specView.top = totalWeight === 0 ? 0 : 95.5 * this.logWeight / totalWeight;

			this.game.chatView.digest();
			this.game.specView.digest();
		}
	}, {
		key: "panelRendered",
		value: function panelRendered() {
			this.renderedPanels++;
			if (this.renderedPanels === 3) {
				this.updatePanelHeights();
			}
		}
	}, {
		key: "toggleChat",
		value: function toggleChat() {
			this.chatMode = 1 - this.chatMode;
			if (this.chatMode > 1) this.chatMode = 0;
			this.syncPreference(UserPrefIds.CHAT_PANEL_WEIGHT, this.chatMode);
		}
	}, {
		key: "toggleLog",
		value: function toggleLog() {
			this.logMode++;
			if (this.logMode > 1) this.logMode = 0;
			this.syncPreference(UserPrefIds.LOG_PANEL_WEIGHT, this.logMode);
		}
	}, {
		key: "toggleBooleanPreference",
		value: function toggleBooleanPreference(preference) {
			this.syncPreference(preference, !this.preferences.getUserPrefValue(preference));
		}
	}, {
		key: "setBackground",
		value: function setBackground(n) {
			this.gameBroadcaster.send(Events.SET_BACKGROUND, n);
			this.syncPreference(UserPrefIds.PREFERRED_BACKGROUND, n);
		}
	}, {
		key: "setAnimations",
		value: function setAnimations(n) {
			animationType = n;
			this.syncPreference(UserPrefIds.ANIMATION_TYPE, n);
		}
	}, {
		key: "hideErrataWarning",
		value: function hideErrataWarning() {
			this.syncPreference(UserPrefIds.SHOW_REWORDING_2022_WARNING, false);
		}
	}, {
		key: "chatMode",
		set: function set(value) {
			this.privateChatMode = value;
			if (!isUndefined(this.game.chatView)) {
				this.game.chatView.showing = this.privateChatMode > 0;
				this.updatePanelHeights();
			}
		},
		get: function get() {
			return this.privateChatMode;
		}
	}, {
		key: "logMode",
		set: function set(value) {
			this.privateLogMode = value;
			if (!isUndefined(this.game.logModel.logView)) {
				this.game.logModel.logView.showing = this.privateLogMode > 0;
				this.updatePanelHeights();
			}
		},
		get: function get() {
			return this.privateLogMode;
		}
	}]);

	return OptionsModel;
}();