"use strict";

var ENGLISH = {
    getOrdinal: function getOrdinal() {
        return 0;
    },
    getName: function getName() {
        return "english";
    },
    getLocalizedDescription: function getLocalizedDescription() {
        return "English";
    },
    isVisible: function isVisible() {
        return true;
    },
    getExpansionName: EnglishExpansionNames,
    getCardName: EnglishCardNames,
    getCardText: EnglishCardTexts,
    getLogEntry: EnglishLogEntries,
    getPhrase: EnglishPhrases,
    getZoneName: EnglishZoneNames,
    getTokenName: EnglishTokenNames,
    getDeclineButtonText: EnglishDeclineButtons,
    getStory: EnglishQuestionDescriptions,
    getAbility: EnglishAbilityNames,
    getAbilityDescription: EnglishAbilityDescriptions,
    getMovementType: EnglishMovementTypes,
    getError: EnglishErrors,
    getSuccessDescriptions: EnglishSuccessDescriptions,
    getTableRule: EnglishTableRules,
    getActivity: EnglishActivities,
    getCardMode: EnglishCardModes,
    getGameButton: EnglishGameButtons,
    getDelayedQuestionButton: EnglishDelayedQuestionButtons,
    getPileTokenText: EnglishPileTokenTexts,
    getPileMarker: EnglishPileMarkers,
    getTableStatus: EnglishTableStati,
    getType: EnglishTypes,
    getLobbyButtons: EnglishLobbyButtons,
    getLobbyTexts: EnglishLobbyTexts,
    getTernaryFieldTexts: EnglishTernaryFieldTexts,
    getCleanupAction: EnglishCleanupActions,
    getUserPreferences: EnglishUserPreferences,
    getProfileButtons: EnglishProfileButtons,
    getTabLabels: EnglishTabs,
    getStoreLabels: EnglishStoreLabels,
    getMatchTypes: EnglishMatchTypes,
    getUndoSettings: EnglishUndoSettings,
    getGroupId: EnglishGroupIds,
    getCountry: EnglishCountries,
    getEnvironment: EnglishEnvironment,
    getAutoPlays: EnglishAutoPlays,
    getAutoplayLabels: EnglishAutoplayLabels,
    getSortOrderLocale: function getSortOrderLocale() {
        return "en";
    },
    paragraphs: EnglishParagraphs,
    KingdomViewerNames: EnglishKingdomViewerNames,
    automatchQuestions: EnglishAutomatchQuestions,
    automatchValues: EnglishAutomatchValues,
    getLevelIntroductions: EnglishLevelIntroductions,
    getGameConcepts: EnglishGameConcepts,
    getAnimationType: EnglishAnimationTypes
};

var RUSSIAN = {
    getOrdinal: function getOrdinal() {
        return 1;
    },
    getName: function getName() {
        return "russian";
    },
    getLocalizedDescription: function getLocalizedDescription() {
        return "Русский";
    },
    isVisible: function isVisible() {
        return true;
    },
    getExpansionName: RussianExpansionNames,
    getCardName: RussianCardNames,
    getCardText: RussianCardTexts,
    getLogEntry: RussianLogEntries,
    getPhrase: RussianPhrases,
    getZoneName: RussianZoneNames,
    getTokenName: RussianTokenNames,
    getDeclineButtonText: RussianDeclineButtons,
    getStory: RussianQuestionDescriptions,
    getAbility: RussianAbilityNames,
    getAbilityDescription: RussianAbilityDescriptions,
    getMovementType: RussianMovementTypes,
    getError: RussianErrors,
    getSuccessDescriptions: RussianSuccessDescriptions,
    getTableRule: RussianTableRules,
    getActivity: RussianActivities,
    getCardMode: RussianCardModes,
    getGameButton: RussianGameButtons,
    getDelayedQuestionButton: RussianDelayedQuestionButtons,
    getPileTokenText: RussianPileTokenTexts,
    getPileMarker: RussianPileMarkers,
    getTableStatus: RussianTableStati,
    getType: RussianTypes,
    getLobbyButtons: RussianLobbyButtons,
    getLobbyTexts: RussianLobbyTexts,
    getTernaryFieldTexts: RussianTernaryFieldTexts,
    getCleanupAction: RussianCleanupActions,
    getUserPreferences: RussianUserPreferences,
    getProfileButtons: RussianProfileButtons,
    getTabLabels: RussianTabs,
    getStoreLabels: RussianStoreLabels,
    getMatchTypes: RussianMatchTypes,
    getUndoSettings: RussianUndoSettings,
    getGroupId: RussianGroupIds,
    getCountry: RussianCountries,
    getEnvironment: RussianEnvironment,
    getAutoPlays: RussianAutoPlays,
    getAutoplayLabels: RussianAutoplayLabels,
    getSortOrderLocale: function getSortOrderLocale() {
        return "ru";
    },
    paragraphs: RussianParagraphs,
    KingdomViewerNames: RussianKingdomViewerNames,
    automatchQuestions: RussianAutomatchQuestions,
    automatchValues: RussianAutomatchValues,
    getLevelIntroductions: RussianLevelIntroductions,
    getGameConcepts: RussianGameConcepts,
    getAnimationType: RussianAnimationTypes
};

var GERMAN = {
    getOrdinal: function getOrdinal() {
        return 2;
    },
    getName: function getName() {
        return "german";
    },
    getLocalizedDescription: function getLocalizedDescription() {
        return "Deutsch";
    },
    isVisible: function isVisible() {
        return true;
    },
    getExpansionName: GermanExpansionNames,
    getCardName: GermanCardNames,
    getCardText: GermanCardTexts,
    getLogEntry: GermanLogEntries,
    getPhrase: GermanPhrases,
    getZoneName: GermanZoneNames,
    getTokenName: GermanTokenNames,
    getDeclineButtonText: GermanDeclineButtons,
    getStory: GermanQuestionDescriptions,
    getAbility: GermanAbilityNames,
    getAbilityDescription: GermanAbilityDescriptions,
    getMovementType: GermanMovementTypes,
    getError: GermanErrors,
    getSuccessDescriptions: GermanSuccessDescriptions,
    getTableRule: GermanTableRules,
    getActivity: GermanActivities,
    getCardMode: GermanCardModes,
    getGameButton: GermanGameButtons,
    getDelayedQuestionButton: GermanDelayedQuestionButtons,
    getPileTokenText: GermanPileTokenTexts,
    getPileMarker: GermanPileMarkers,
    getTableStatus: GermanTableStati,
    getType: GermanTypes,
    getLobbyButtons: GermanLobbyButtons,
    getLobbyTexts: GermanLobbyTexts,
    getTernaryFieldTexts: GermanTernaryFieldTexts,
    getCleanupAction: GermanCleanupActions,
    getUserPreferences: GermanUserPreferences,
    getProfileButtons: GermanProfileButtons,
    getTabLabels: GermanTabs,
    getStoreLabels: GermanStoreLabels,
    getMatchTypes: GermanMatchTypes,
    getUndoSettings: GermanUndoSettings,
    getGroupId: GermanGroupIds,
    getCountry: GermanCountries,
    getEnvironment: GermanEnvironment,
    getAutoPlays: GermanAutoPlays,
    getAutoplayLabels: GermanAutoplayLabels,
    getSortOrderLocale: function getSortOrderLocale() {
        return "de";
    },
    paragraphs: GermanParagraphs,
    KingdomViewerNames: GermanKingdomViewerNames,
    automatchQuestions: GermanAutomatchQuestions,
    automatchValues: GermanAutomatchValues,
    getLevelIntroductions: GermanLevelIntroductions,
    getGameConcepts: GermanGameConcepts,
    getAnimationType: GermanAnimationTypes
};

var FRENCH = {
    getOrdinal: function getOrdinal() {
        return 3;
    },
    getName: function getName() {
        return "french";
    },
    getLocalizedDescription: function getLocalizedDescription() {
        return "Français";
    },
    isVisible: function isVisible() {
        return true;
    },
    getExpansionName: FrenchExpansionNames,
    getCardName: FrenchCardNames,
    getCardText: FrenchCardTexts,
    getLogEntry: FrenchLogEntries,
    getPhrase: FrenchPhrases,
    getZoneName: FrenchZoneNames,
    getTokenName: FrenchTokenNames,
    getDeclineButtonText: FrenchDeclineButtons,
    getStory: FrenchQuestionDescriptions,
    getAbility: FrenchAbilityNames,
    getAbilityDescription: FrenchAbilityDescriptions,
    getMovementType: FrenchMovementTypes,
    getError: FrenchErrors,
    getSuccessDescriptions: FrenchSuccessDescriptions,
    getTableRule: FrenchTableRules,
    getActivity: FrenchActivities,
    getCardMode: FrenchCardModes,
    getGameButton: FrenchGameButtons,
    getDelayedQuestionButton: FrenchDelayedQuestionButtons,
    getPileTokenText: FrenchPileTokenTexts,
    getPileMarker: FrenchPileMarkers,
    getTableStatus: FrenchTableStati,
    getType: FrenchTypes,
    getLobbyButtons: FrenchLobbyButtons,
    getLobbyTexts: FrenchLobbyTexts,
    getTernaryFieldTexts: FrenchTernaryFieldTexts,
    getCleanupAction: FrenchCleanupActions,
    getUserPreferences: FrenchUserPreferences,
    getProfileButtons: FrenchProfileButtons,
    getTabLabels: FrenchTabs,
    getStoreLabels: FrenchStoreLabels,
    getMatchTypes: FrenchMatchTypes,
    getUndoSettings: FrenchUndoSettings,
    getGroupId: FrenchGroupIds,
    getCountry: FrenchCountries,
    getEnvironment: FrenchEnvironment,
    getAutoPlays: FrenchAutoPlays,
    getAutoplayLabels: FrenchAutoplayLabels,
    getSortOrderLocale: function getSortOrderLocale() {
        return "fr";
    },
    paragraphs: FrenchParagraphs,
    KingdomViewerNames: FrenchKingdomViewerNames,
    automatchQuestions: FrenchAutomatchQuestions,
    automatchValues: FrenchAutomatchValues,
    getLevelIntroductions: FrenchLevelIntroductions,
    getGameConcepts: FrenchGameConcepts,
    getAnimationType: FrenchAnimationTypes
};

var JAPANESE = {
    getOrdinal: function getOrdinal() {
        return 4;
    },
    getName: function getName() {
        return "japanese";
    },
    getLocalizedDescription: function getLocalizedDescription() {
        return "日本語";
    },
    isVisible: function isVisible() {
        return true;
    },
    getExpansionName: JapaneseExpansionNames,
    getCardName: JapaneseCardNames,
    getCardText: JapaneseCardTexts,
    getLogEntry: JapaneseLogEntries,
    getPhrase: JapanesePhrases,
    getZoneName: JapaneseZoneNames,
    getTokenName: JapaneseTokenNames,
    getDeclineButtonText: JapaneseDeclineButtons,
    getStory: JapaneseQuestionDescriptions,
    getAbility: JapaneseAbilityNames,
    getAbilityDescription: JapaneseAbilityDescriptions,
    getMovementType: JapaneseMovementTypes,
    getError: JapaneseErrors,
    getSuccessDescriptions: JapaneseSuccessDescriptions,
    getTableRule: JapaneseTableRules,
    getActivity: JapaneseActivities,
    getCardMode: JapaneseCardModes,
    getGameButton: JapaneseGameButtons,
    getDelayedQuestionButton: JapaneseDelayedQuestionButtons,
    getPileTokenText: JapanesePileTokenTexts,
    getPileMarker: JapanesePileMarkers,
    getTableStatus: JapaneseTableStati,
    getType: JapaneseTypes,
    getLobbyButtons: JapaneseLobbyButtons,
    getLobbyTexts: JapaneseLobbyTexts,
    getTernaryFieldTexts: JapaneseTernaryFieldTexts,
    getCleanupAction: JapaneseCleanupActions,
    getUserPreferences: JapaneseUserPreferences,
    getProfileButtons: JapaneseProfileButtons,
    getTabLabels: JapaneseTabs,
    getStoreLabels: JapaneseStoreLabels,
    getMatchTypes: JapaneseMatchTypes,
    getUndoSettings: JapaneseUndoSettings,
    getGroupId: JapaneseGroupIds,
    getCountry: JapaneseCountries,
    getEnvironment: JapaneseEnvironment,
    getAutoPlays: JapaneseAutoPlays,
    getAutoplayLabels: JapaneseAutoplayLabels,
    getSortOrderLocale: function getSortOrderLocale() {
        return "ja";
    },
    paragraphs: JapaneseParagraphs,
    KingdomViewerNames: JapaneseKingdomViewerNames,
    automatchQuestions: JapaneseAutomatchQuestions,
    automatchValues: JapaneseAutomatchValues,
    getLevelIntroductions: JapaneseLevelIntroductions,
    getGameConcepts: JapaneseGameConcepts,
    getAnimationType: JapaneseAnimationTypes
};

var TCHINESE = {
    getOrdinal: function getOrdinal() {
        return 5;
    },
    getName: function getName() {
        return "tchinese";
    },
    getLocalizedDescription: function getLocalizedDescription() {
        return "繁體中文";
    },
    isVisible: function isVisible() {
        return true;
    },
    getExpansionName: TChineseExpansionNames,
    getCardName: TChineseCardNames,
    getCardText: TChineseCardTexts,
    getLogEntry: TChineseLogEntries,
    getPhrase: TChinesePhrases,
    getZoneName: TChineseZoneNames,
    getTokenName: TChineseTokenNames,
    getDeclineButtonText: TChineseDeclineButtons,
    getStory: TChineseQuestionDescriptions,
    getAbility: TChineseAbilityNames,
    getAbilityDescription: TChineseAbilityDescriptions,
    getMovementType: TChineseMovementTypes,
    getError: TChineseErrors,
    getSuccessDescriptions: TChineseSuccessDescriptions,
    getTableRule: TChineseTableRules,
    getActivity: TChineseActivities,
    getCardMode: TChineseCardModes,
    getGameButton: TChineseGameButtons,
    getDelayedQuestionButton: TChineseDelayedQuestionButtons,
    getPileTokenText: TChinesePileTokenTexts,
    getPileMarker: TChinesePileMarkers,
    getTableStatus: TChineseTableStati,
    getType: TChineseTypes,
    getLobbyButtons: TChineseLobbyButtons,
    getLobbyTexts: TChineseLobbyTexts,
    getTernaryFieldTexts: TChineseTernaryFieldTexts,
    getCleanupAction: TChineseCleanupActions,
    getUserPreferences: TChineseUserPreferences,
    getProfileButtons: TChineseProfileButtons,
    getTabLabels: TChineseTabs,
    getStoreLabels: TChineseStoreLabels,
    getMatchTypes: TChineseMatchTypes,
    getUndoSettings: TChineseUndoSettings,
    getGroupId: TChineseGroupIds,
    getCountry: TChineseCountries,
    getEnvironment: TChineseEnvironment,
    getAutoPlays: TChineseAutoPlays,
    getAutoplayLabels: TChineseAutoplayLabels,
    getSortOrderLocale: function getSortOrderLocale() {
        return "zh-Hant";
    },
    paragraphs: TChineseParagraphs,
    KingdomViewerNames: TChineseKingdomViewerNames,
    automatchQuestions: TChineseAutomatchQuestions,
    automatchValues: TChineseAutomatchValues,
    getLevelIntroductions: TChineseLevelIntroductions,
    getGameConcepts: TChineseGameConcepts,
    getAnimationType: TChineseAnimationTypes
};

var DUTCH = {
    getOrdinal: function getOrdinal() {
        return 6;
    },
    getName: function getName() {
        return "dutch";
    },
    getLocalizedDescription: function getLocalizedDescription() {
        return "Nederlands";
    },
    isVisible: function isVisible() {
        return false;
    },
    getExpansionName: DutchExpansionNames,
    getCardName: DutchCardNames,
    getCardText: DutchCardTexts,
    getLogEntry: DutchLogEntries,
    getPhrase: DutchPhrases,
    getZoneName: DutchZoneNames,
    getTokenName: DutchTokenNames,
    getDeclineButtonText: DutchDeclineButtons,
    getStory: DutchQuestionDescriptions,
    getAbility: DutchAbilityNames,
    getAbilityDescription: DutchAbilityDescriptions,
    getMovementType: DutchMovementTypes,
    getError: DutchErrors,
    getSuccessDescriptions: DutchSuccessDescriptions,
    getTableRule: DutchTableRules,
    getActivity: DutchActivities,
    getCardMode: DutchCardModes,
    getGameButton: DutchGameButtons,
    getDelayedQuestionButton: DutchDelayedQuestionButtons,
    getPileTokenText: DutchPileTokenTexts,
    getPileMarker: DutchPileMarkers,
    getTableStatus: DutchTableStati,
    getType: DutchTypes,
    getLobbyButtons: DutchLobbyButtons,
    getLobbyTexts: DutchLobbyTexts,
    getTernaryFieldTexts: DutchTernaryFieldTexts,
    getCleanupAction: DutchCleanupActions,
    getUserPreferences: DutchUserPreferences,
    getProfileButtons: DutchProfileButtons,
    getTabLabels: DutchTabs,
    getStoreLabels: DutchStoreLabels,
    getMatchTypes: DutchMatchTypes,
    getUndoSettings: DutchUndoSettings,
    getGroupId: DutchGroupIds,
    getCountry: DutchCountries,
    getEnvironment: DutchEnvironment,
    getAutoPlays: DutchAutoPlays,
    getAutoplayLabels: DutchAutoplayLabels,
    getSortOrderLocale: function getSortOrderLocale() {
        return "nl";
    },
    paragraphs: DutchParagraphs,
    KingdomViewerNames: DutchKingdomViewerNames
    //    automatchQuestions: DurchAutomatchQuestions,
    //    automatchValues: DutchAutomatchValues,
};

var SPANISH = {
    getOrdinal: function getOrdinal() {
        return 7;
    },
    getName: function getName() {
        return "spanish";
    },
    getLocalizedDescription: function getLocalizedDescription() {
        return "Español";
    },
    isVisible: function isVisible() {
        return false;
    },
    getExpansionName: SpanishExpansionNames,
    getCardName: SpanishCardNames,
    getCardText: SpanishCardTexts,
    getLogEntry: SpanishLogEntries,
    getPhrase: SpanishPhrases,
    getZoneName: SpanishZoneNames,
    getTokenName: SpanishTokenNames,
    getDeclineButtonText: SpanishDeclineButtons,
    getStory: SpanishQuestionDescriptions,
    getAbility: SpanishAbilityNames,
    getAbilityDescription: SpanishAbilityDescriptions,
    getMovementType: SpanishMovementTypes,
    getError: SpanishErrors,
    getSuccessDescriptions: SpanishSuccessDescriptions,
    getTableRule: SpanishTableRules,
    getActivity: SpanishActivities,
    getCardMode: SpanishCardModes,
    getGameButton: SpanishGameButtons,
    getDelayedQuestionButton: SpanishDelayedQuestionButtons,
    getPileTokenText: SpanishPileTokenTexts,
    getPileMarker: SpanishPileMarkers,
    getTableStatus: SpanishTableStati,
    getType: SpanishTypes,
    getLobbyButtons: SpanishLobbyButtons,
    getLobbyTexts: SpanishLobbyTexts,
    getTernaryFieldTexts: SpanishTernaryFieldTexts,
    getCleanupAction: SpanishCleanupActions,
    getUserPreferences: SpanishUserPreferences,
    getProfileButtons: SpanishProfileButtons,
    getTabLabels: SpanishTabs,
    getStoreLabels: SpanishStoreLabels,
    getMatchTypes: SpanishMatchTypes,
    getUndoSettings: SpanishUndoSettings,
    getGroupId: SpanishGroupIds,
    getCountry: SpanishCountries,
    getEnvironment: SpanishEnvironment,
    getAutoPlays: SpanishAutoPlays,
    getAutoplayLabels: SpanishAutoplayLabels,
    getSortOrderLocale: function getSortOrderLocale() {
        return "es";
    },
    paragraphs: SpanishParagraphs,
    KingdomViewerNames: SpanishKingdomViewerNames,
    automatchQuestions: SpanishAutomatchQuestions,
    automatchValues: SpanishAutomatchValues,
    getLevelIntroductions: SpanishLevelIntroductions,
    getGameConcepts: SpanishGameConcepts,
    getAnimationType: SpanishAnimationTypes
};

var CZECH = {
    getOrdinal: function getOrdinal() {
        return 8;
    },
    getName: function getName() {
        return "czech";
    },
    getLocalizedDescription: function getLocalizedDescription() {
        return "Czech";
    },
    isVisible: function isVisible() {
        return false;
    },
    getExpansionName: CzechExpansionNames,
    getCardName: CzechCardNames,
    getCardText: CzechCardTexts,
    getLogEntry: CzechLogEntries,
    getPhrase: CzechPhrases,
    getZoneName: CzechZoneNames,
    getTokenName: CzechTokenNames,
    getDeclineButtonText: CzechDeclineButtons,
    getStory: CzechQuestionDescriptions,
    getAbility: CzechAbilityNames,
    getAbilityDescription: CzechAbilityDescriptions,
    getMovementType: CzechMovementTypes,
    getError: CzechErrors,
    getSuccessDescriptions: CzechSuccessDescriptions,
    getTableRule: CzechTableRules,
    getActivity: CzechActivities,
    getCardMode: CzechCardModes,
    getGameButton: CzechGameButtons,
    getDelayedQuestionButton: CzechDelayedQuestionButtons,
    getPileTokenText: CzechPileTokenTexts,
    getPileMarker: CzechPileMarkers,
    getTableStatus: CzechTableStati,
    getType: CzechTypes,
    getLobbyButtons: CzechLobbyButtons,
    getLobbyTexts: CzechLobbyTexts,
    getTernaryFieldTexts: CzechTernaryFieldTexts,
    getCleanupAction: CzechCleanupActions,
    getUserPreferences: CzechUserPreferences,
    getProfileButtons: CzechProfileButtons,
    getTabLabels: CzechTabs,
    getStoreLabels: CzechStoreLabels,
    getMatchTypes: CzechMatchTypes,
    getUndoSettings: CzechUndoSettings,
    getGroupId: CzechGroupIds,
    getCountry: CzechCountries,
    getEnvironment: CzechEnvironment,
    getAutoPlays: CzechAutoPlays,
    getAutoplayLabels: CzechAutoplayLabels,
    getSortOrderLocale: function getSortOrderLocale() {
        return "cs";
    },
    paragraphs: CzechParagraphs,
    KingdomViewerNames: CzechKingdomViewerNames,
    automatchQuestions: CzechAutomatchQuestions,
    automatchValues: CzechAutomatchValues,
    getLevelIntroductions: CzechLevelIntroductions,
    getGameConcepts: CzechGameConcepts
};

var ESPERANTO = {
    getOrdinal: function getOrdinal() {
        return 9;
    },
    getName: function getName() {
        return "esperanto";
    },
    getLocalizedDescription: function getLocalizedDescription() {
        return "Esperanto";
    },
    isVisible: function isVisible() {
        return false;
    },
    getExpansionName: EsperantoExpansionNames,
    getCardName: EsperantoCardNames,
    getCardText: EsperantoCardTexts,
    getLogEntry: EsperantoLogEntries,
    getPhrase: EsperantoPhrases,
    getZoneName: EsperantoZoneNames,
    getTokenName: EsperantoTokenNames,
    getDeclineButtonText: EsperantoDeclineButtons,
    getStory: EsperantoQuestionDescriptions,
    getAbility: EsperantoAbilityNames,
    getAbilityDescription: EsperantoAbilityDescriptions,
    getMovementType: EsperantoMovementTypes,
    getError: EsperantoErrors,
    getSuccessDescriptions: EsperantoSuccessDescriptions,
    getTableRule: EsperantoTableRules,
    getActivity: EsperantoActivities,
    getCardMode: EsperantoCardModes,
    getGameButton: EsperantoGameButtons,
    getDelayedQuestionButton: EsperantoDelayedQuestionButtons,
    getPileTokenText: EsperantoPileTokenTexts,
    getPileMarker: EsperantoPileMarkers,
    getTableStatus: EsperantoTableStati,
    getType: EsperantoTypes,
    getLobbyButtons: EsperantoLobbyButtons,
    getLobbyTexts: EsperantoLobbyTexts,
    getTernaryFieldTexts: EsperantoTernaryFieldTexts,
    getCleanupAction: EsperantoCleanupActions,
    getUserPreferences: EsperantoUserPreferences,
    getProfileButtons: EsperantoProfileButtons,
    getTabLabels: EsperantoTabs,
    getStoreLabels: EsperantoStoreLabels,
    getMatchTypes: EsperantoMatchTypes,
    getUndoSettings: EsperantoUndoSettings,
    getGroupId: EsperantoGroupIds,
    getCountry: EsperantoCountries,
    getEnvironment: EsperantoEnvironment,
    getAutoPlays: EsperantoAutoPlays,
    getAutoplayLabels: EsperantoAutoplayLabels,
    getSortOrderLocale: function getSortOrderLocale() {
        return "eo";
    },
    paragraphs: EsperantoParagraphs,
    KingdomViewerNames: EsperantoKingdomViewerNames,
    automatchQuestions: EsperantoAutomatchQuestions,
    automatchValues: EsperantoAutomatchValues,
    getLevelIntroductions: EsperantoLevelIntroductions,
    getGameConcepts: EsperantoGameConcepts,
    getAnimationType: EsperantoAnimationTypes
};

var LANGUAGE = ENGLISH;

var LANGUAGES = [ENGLISH, RUSSIAN, GERMAN, FRENCH, JAPANESE, TCHINESE, SPANISH, CZECH, ESPERANTO];

var checkLanguage = function checkLanguage(language) {
    if (language === ENGLISH) return;
    for (var key in ENGLISH) {
        var value = ENGLISH[key];
        if (value instanceof Object) {
            if (!(key in language)) {
                // console.error(`Language ${language.getName()} missing translations for ${key}`);
                language[key] = value;
                continue;
            }
            for (var name in value) {
                if (!(name in language[key])) {
                    // console.error(`Language ${language.getName()} missing translations for ${name}`);
                    language[key][name] = ENGLISH[key][name];
                }
            }
        }
    }
};

LANGUAGES.forEach(checkLanguage);