"use strict";

webclient.factory("debounce", ['$timeout', function ($timeout) {

    var timeout = {};
    return function (id, fn) {
        var time = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 500;

        $timeout.cancel(timeout[id]);
        timeout[id] = $timeout(fn, time);
    };
}]);