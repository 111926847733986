"use strict";

webclient.component("trendElement", {
    bindings: {
        change: '<'
    },
    controller: [function () {
        var self = this;
        self.isEven = function () {
            return self.change === 0;
        };
        self.isUp = function () {
            return self.change > 0;
        };
        self.isDown = function () {
            return self.change < 0;
        };
        self.getChange = function () {
            return Math.abs(self.change);
        };
    }],
    template: "\n            <div class=\"trend-element\">\n                <div class=\"trend-even\" ng-if=\"$ctrl.isEven()\">-</div>\n                <div class=\"trend-up\" ng-if=\"$ctrl.isUp()\">\u25B2 {{::$ctrl.getChange()}}</div>\n                <div class=\"trend-down\" ng-if=\"$ctrl.isDown()\">\u25BC {{::$ctrl.getChange()}}</div>\n            </div>\n            "
});