"use strict";

webclient.component("cardlist", {
    controllerAs: "ctrl",
    controller: "cardlistController",
    bindings: {
        close: "&",
        active: "<"
    },
    template: "\n<div class=\"cardlist-window\">\n    <div id=\"tooltip\" class=\"table-tooltip hidden\"></div>\n    <div class=\"cardlist-display-row\">\n        <div class=\"cardlist-button-container\">\n            <button class=\"oval-button cardlist-button\" ng-repeat=\"cardlist in ::ctrl.cardlists\" ng-class=\"{active: ctrl.selectedList===cardlist}\" ng-click=\"ctrl.selectList(cardlist)\">\n                {{cardlist.translation()}}\n            </button>\n        </div>\n    </div>\n    <div class=\"cardlist-display-row\" ng-if=\"ctrl.selectedList !== null\">\n        <div class=\"cardlist-selector-card\" ng-repeat=\"card in ctrl.selectedCards track by [card,$index]\" >\n            <anonymous-card card-name=\"card.cardName\" card-size=\"ctrl.cardSizeMini\" ng-click=\"ctrl.removeCardObject(card)\"></anonymous-card>\n        </div>\n        <div class=\"cardlist-selector-card\" ng-repeat=\"card in ctrl.availableCardSlots track by $index\">\n            <div class=\"random-card\"></div>\n        </div>\n        <div class=\"cardlist-selector-spacer\"></div>\n        <div class=\"cardlist-selector-landscape\" ng-repeat=\"card in ctrl.selectedLandscapes track by [card,$index]\">\n            <anonymous-card card-name=\"card.cardName\" card-size=\"ctrl.cardSizeMini\" ng-click=\"ctrl.removeCardObject(card)\"></anonymous-card>\n        </div>\n        <div class=\"cardlist-selector-landscape\" ng-repeat=\"card in ctrl.availableLandscapeSlots track by $index\">\n            <div class=\"random-landscape\"></div>\n        </div>\n    </div>\n    <card-selector\n        member-check=\"ctrl.activeListHasCard\"\n        card-adder=\"ctrl.cardAdder\"\n        show-all=\"ctrl.selectedList===null\">\n    </card-selector>\n</div>"
});