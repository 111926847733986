"use strict";

function TranslatedCardName(singular) {
    var plural = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var noun = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    var many = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';

    var self = this;
    self.singular = singular;
    self.plural = plural === null ? singular : plural;
    self.noun = noun === null ? singular : noun;
    self.many = many;
}

function TranslatedMovementType(secondPerson, thirdPerson) {
    var self = this;
    self.secondPerson = secondPerson;
    self.thirdPerson = thirdPerson;
}

function Phrase() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var Phrases = {
    THE: new Phrase(),
    THE_FROM: new Phrase(),
    THE_TO: new Phrase(),
    AND: new Phrase(),
    OR: new Phrase(),
    OTHER: new Phrase(),
    ANOTHER: new Phrase(),
    PILE: new Phrase(),
    QUICK_UNDO: new Phrase(),
    UNDO_BUTTON: new Phrase(),
    UNDO: new Phrase(),
    YOU: new Phrase(),
    WOULD: new Phrase(),
    YOU_MAY: new Phrase(),
    COMMA: new Phrase(),
    PERIOD: new Phrase(),
    COMPLEX_STORY_LINE: new Phrase(),
    COIN: new Phrase(),
    COINS: new Phrase(),
    COINS_MANY: new Phrase(),
    POTION: new Phrase(),
    POTIONS: new Phrase(),
    POTIONS_MANY: new Phrase(),
    DEBT: new Phrase(),
    VERSION: new Phrase(),
    CREATE_ACCOUNT: new Phrase(),
    USERNAME: new Phrase(),
    PASSWORD: new Phrase(),
    CONFIRM_PASSWORD: new Phrase(),
    FORGOT_YOUR_PASSWORD: new Phrase(),
    EMAIL: new Phrase(),
    SIGN_UP: new Phrase(),
    LOGIN: new Phrase(),
    KICK: new Phrase(),
    RESIGN: new Phrase(),
    CREATE_TABLE: new Phrase(),
    LEAVE_TABLE: new Phrase(),
    MESSAGE: new Phrase(),
    MESSAGE_SPECTATORS: new Phrase(),
    MESSAGE_PLAYERS: new Phrase(),
    START_GAME: new Phrase(),
    READY: new Phrase(),
    NOT_READY: new Phrase(),
    PLAYERS: new Phrase(),
    SPECTATORS: new Phrase(),
    HOST: new Phrase(),
    RANDOM: new Phrase(),
    BASIC_OPTIONS: new Phrase(),
    ADVANCED_OPTIONS: new Phrase(),
    YES: new Phrase(),
    NO: new Phrase(),
    FRIENDS_ONLY: new Phrase(),
    EVENTS_AND_LANDMARKS: new Phrase(),
    TRUE: new Phrase(),
    FALSE: new Phrase(),
    CANCEL: new Phrase(),
    VIEW_TRASH: new Phrase(),
    VIEW_KINGDOM: new Phrase(),
    VIEW_PLAY_AREA: new Phrase(),
    LEAVE: new Phrase(),
    TOTAL_IN_QUEUE: new Phrase(),
    EDIT_TABLE: new Phrase(),
    NEED_HELP: new Phrase(),
    PLAY: new Phrase(),
    MAKE_RESIGN: new Phrase(),
    UNDO_REQUEST_SINGULAR: new Phrase(),
    UNDO_REQUEST_PLURAL: new Phrase(),
    UNDO_REQUEST_MANY: new Phrase(),
    ACCEPT: new Phrase(),
    DECLINE: new Phrase(),
    GRANT: new Phrase(),
    DENY: new Phrase(),
    PENDING_UNDO_REQUEST: new Phrase(),
    SPECTATOR_UNDO_REQUEST: new Phrase(),
    UNDO_DENIED: new Phrase(),
    UNDO_CANCELLED: new Phrase(),
    TOC_SENTENCE: new Phrase(),
    TERMS_OF_SERVICE: new Phrase(),
    TERMS_OF_SERVICE_URL: new Phrase(),
    RECONNECTING: new Phrase(),
    PLAYER: new Phrase(),
    PLAYER_JOINED_TABLE: new Phrase(),
    PLAYER_LEFT_TABLE: new Phrase(),
    JOINING_GAME_MESSAGE: new Phrase(),
    SIGNING_UP: new Phrase(),
    ACTION: new Phrase(),
    ACTIONS: new Phrase(),
    BUY: new Phrase(),
    BUYS: new Phrase(),
    KICK_SUCCEEDED: new Phrase(),
    CONNECTION_CLOSED: new Phrase(),
    NEW_PASSWORD: new Phrase(),
    RETRY: new Phrase(),
    FRIEND: new Phrase(),
    ACTIVITY: new Phrase(),
    STATUS: new Phrase(),
    PURCHASE: new Phrase(),
    UNTIL: new Phrase(),
    BUY_FOR_AMOUNT: new Phrase(),
    HIDE: new Phrase(),
    CONFIRM: new Phrase(),
    JOIN: new Phrase(),
    TURN: new Phrase(),
    CARD: new Phrase(),
    DESCRIPTION: new Phrase(),
    SCORE: new Phrase(),
    TURNS: new Phrase(),
    NOTHING: new Phrase(),
    SPECTATE: new Phrase(),
    FRIENDS: new Phrase(),
    FOLLOWING: new Phrase(),
    FOLLOWERS: new Phrase(),
    UNFRIEND: new Phrase(),
    UNFOLLOW: new Phrase(),
    BEFRIEND: new Phrase(),
    FRIENDS_EXPLANATION: new Phrase(),
    FOLLOWING_EXPLANATION: new Phrase(),
    FOLLOWERS_EXPLANATION: new Phrase(),
    ADD: new Phrase(),
    TYPE_FRIEND: new Phrase(),
    UNLOCKS: new Phrase(),
    SELECT_YOUR_COUNTRY: new Phrase(),
    SELECT_A_COUNTRY: new Phrase(),
    KICK_AND_RESIGN: new Phrase(),
    BETWEEN_TURNS: new Phrase(),
    RESIGNED: new Phrase(),
    YOU_RESIGNED: new Phrase(),
    YOU_LOSE: new Phrase(),
    YOU_TIE: new Phrase(),
    YOU_WIN: new Phrase(),
    BUYS_MANY: new Phrase(),
    ACTIONS_MANY: new Phrase(),
    TOKEN: new Phrase(),
    TOKENS: new Phrase(),
    TOKENS_MANY: new Phrase(),
    OVERPAY: new Phrase(),
    BID: new Phrase(),
    TAKE: new Phrase(),
    BOTTOM: new Phrase(),
    TOP: new Phrase(),
    LOCATION: new Phrase(),
    CLEAN_UP: new Phrase(),
    PLAN_CLEAN_UP: new Phrase(),
    EXECUTE: new Phrase(),
    MOVEMENT_VERB: new Phrase(),
    MOVEMENT_VERB_OBJECT: new Phrase(),
    FORUM: new Phrase(),
    VIEW_LOG: new Phrase(),
    REPLAY_GAME: new Phrase(),
    REMOVE_REPLAY: new Phrase(),
    SHELTERS: new Phrase(),
    NO_SHELTERS: new Phrase(),
    COLONIES: new Phrase(),
    NO_COLONIES: new Phrase(),
    PLAYER_ORDER: new Phrase(),
    ENTER_USERNAME: new Phrase(),
    ENTER_LOGIN_CREDENTIALS: new Phrase(),
    SUBMIT: new Phrase(),
    DONE: new Phrase(),
    GAME_ID: new Phrase(),
    DECISION_INDEX: new Phrase(),
    ORIGINAL_PLAYER: new Phrase(),
    ORIGINAL_PLAYERS: new Phrase(),
    GAME_ID_SHOULD_BE_NUMBER: new Phrase(),
    ADD_BOT: new Phrase(),
    DECISION: new Phrase(),
    LOAD_GAME: new Phrase(),
    CHANGE_REPLAY: new Phrase(),
    NAME_CARD: new Phrase(),
    LOAD_OLD_GAME: new Phrase(),
    BOT: new Phrase(),
    CANCEL_RESIGN: new Phrase(),
    LOGOUT: new Phrase(),
    FIRST: new Phrase(),
    LAST: new Phrase(),
    INBOX: new Phrase(),
    MESSAGES: new Phrase(),
    MARK_READ: new Phrase(),
    DELETE: new Phrase(),
    NO_MESSAGES: new Phrase(),
    LEADERBOARD: new Phrase(),
    LAST_UPDATED: new Phrase(),
    RANK: new Phrase(),
    NAME: new Phrase(),
    RATING: new Phrase(),
    TREND: new Phrase(),
    VP: new Phrase(),
    FAQ: new Phrase(),
    RESULTS: new Phrase(),
    SKILL: new Phrase(),
    SKILL_RANGE: new Phrase(),
    DEVIATION: new Phrase(),
    VOLATILITY: new Phrase(),
    GAMES: new Phrase(),
    GLICKO2: new Phrase(),
    IMPLEMENTATION: new Phrase(),
    RATED_QUEUE: new Phrase(),
    UNRATED_QUEUE: new Phrase(),
    UNRATED_MATCH: new Phrase(),
    LEVEL: new Phrase(),
    TWO_PLAYERS: new Phrase(),
    THREE_FOUR_PLAYERS: new Phrase(),
    FIND_GAME: new Phrase(),
    FIND_BOT_GAME: new Phrase(),
    SEARCHING_2P: new Phrase(),
    SEARCHING_3P: new Phrase(),
    SEARCHING_RATED: new Phrase(),
    SEARCHING_PRACTICE: new Phrase(),
    MAX: new Phrase(),
    MIN: new Phrase(),
    START_SEARCH: new Phrase(),
    CANCEL_SEARCH: new Phrase(),
    GAME: new Phrase(),
    RATED: new Phrase(),
    UNRATED: new Phrase(),
    OTHER_CARDS: new Phrase(),
    OTHER_CARD: new Phrase(),
    YOUR_TURN: new Phrase(),
    LOADING_GAME: new Phrase(),
    BLACKLIST: new Phrase(),
    REMOVE: new Phrase(),
    BLACKLIST_USER: new Phrase(),
    BEFRIEND_USER: new Phrase(),
    BLACKLISTED: new Phrase(),
    FRIENDLIST_EMPTY: new Phrase(),
    BLACKLIST_EMPTY: new Phrase(),
    PLAYERS_OF_TOTAL_PLAYERS: new Phrase(),
    SHOW_RUNNING_GAMES: new Phrase(),
    SHOW_1P_GAMES: new Phrase(),
    SHOW_2P_GAMES: new Phrase(),
    SHOW_3XP_GAMES: new Phrase(),
    SHOW_BOT_GAMES: new Phrase(),
    CURRENTLY_SEARCHING: new Phrase(),
    SEARCHING: new Phrase(),
    START_BOT_GAME_1: new Phrase(),
    START_BOT_GAME_2: new Phrase(),
    START_BOT_GAME_3: new Phrase(),
    NO_FRIENDS_ONLINE: new Phrase(),
    AUTOMATCH_FEEDBACK: new Phrase(),
    NOBODY_ELSE_IN_QUEUE: new Phrase(),
    CANCEL_SMALL: new Phrase(),
    PLAYER_REPLACED: new Phrase(),
    RESIGN_PROMPT: new Phrase(),
    RESIGN_MULTIPLE_OPPONENTS: new Phrase(),
    CONTINUE_WITH_BOTS: new Phrase(),
    PLAYER_DISCONNECTED: new Phrase(),
    PLAYER_RECONNECTED: new Phrase(),
    VALIDATE_BONUS_CODE: new Phrase(),
    NEW_TO_DOMINION: new Phrase(),
    SEARCHING_PREVIEWS: new Phrase(),
    AUTOPLAY_CLEANUP: new Phrase(),
    AUTOPLAY_INTERFACE: new Phrase(),
    AUTOPLAY_OTHER: new Phrase(),
    STORE_WELCOME: new Phrase(),
    STORE_INTRO_1: new Phrase(),
    STORE_INTRO_2: new Phrase(),
    STORE_INTRO_3: new Phrase(),
    STORE_INTRO_US_1: new Phrase(),
    STORE_INTRO_US_2: new Phrase(),
    ONE_MONTH: new Phrase(),
    TWO_MONTHS: new Phrase(),
    THREE_MONTHS: new Phrase(),
    ONE_YEAR: new Phrase(),
    COMPLETE_SUBSCRIPTION: new Phrase(),
    CORE_SUBSCRIPTION: new Phrase(),
    COMPLETE_SUBSCRIPTION_PRICE: new Phrase(),
    COMPLETE_SUBSCRIPTION_EXPLANATION: new Phrase(),
    CORE_SUBSCRIPTION_PRICE: new Phrase(),
    CORE_SUBSCRIPTION_EXPLANATION: new Phrase(),
    CHOOSE_SUBSCRIPTION: new Phrase(),
    CHOOSE_DURATION: new Phrase(),
    CONTINUE: new Phrase(),
    INTRODUCTION_TO_STORE: new Phrase(),
    QUICK_MATCH: new Phrase(),
    GOOD_MATCH: new Phrase(),
    CHANGE_PASSWORD: new Phrase(),
    ADD_LATEST_EXPANSION: new Phrase(),
    CHECK_YOUR_PURCHASE: new Phrase(),
    SELLER_INFO: new Phrase(),
    GAME_ENDED: new Phrase(),
    HAS_RESIGNED: new Phrase(),
    OK: new Phrase(),
    INVOICE_NEED_ANSWERS: new Phrase(),
    BUYING_SUBSCRIPTION_TO: new Phrase(),
    BUYING_SUBSCRIPTION_TILL: new Phrase(),
    AMOUNT: new Phrase(),
    ITEM: new Phrase(),
    YOUR_SUBSCRIPTION: new Phrase(),
    YOUR_ACCOUNT: new Phrase(),
    SIX_MONTHS: new Phrase(),
    NINE_MONTHS: new Phrase(),
    FIFTEEN_MONTHS: new Phrase(),
    TWO_YEAR: new Phrase(),
    REDIRECTED_TO_PAYMENT_PROVIDER: new Phrase(),
    REDIRECT_YOURSELF: new Phrase(),
    THIS_LINK: new Phrase(),
    ZERO_INVOICE_1: new Phrase(),
    ZERO_INVOICE_2: new Phrase(),
    SORRY_STORE_SERVICE_DOWN: new Phrase(),
    CONNECTING_TO_STORE_SERVICE: new Phrase(),
    PAYMENT_REDIRECTION_FAILED: new Phrase(),
    REDIRECT_FAILED_BUTTON: new Phrase(),
    UPGRADE_DURATION: new Phrase(),
    EIGHTEEN_MONTHS: new Phrase(),
    FOR_FREE: new Phrase(),
    MAXED_OUT_SUBSCRIPTIONS: new Phrase(),
    STORE_INTRO: new Phrase(),
    STORE_INFO_OPTIONS: new Phrase(),
    STORE_INFO_BUYING_DETAILS: new Phrase(),
    STORE_INFO_DOLLARS: new Phrase(),
    IP_COUNTRY_MISMATCH: new Phrase(),
    EXPIRES_AT: new Phrase(),
    TOOLTIP_RANDOM_SELECTION: new Phrase(),
    TOOLTIP_LANDSCAPE_SLOT: new Phrase(),
    LANDSCAPE_UNUSED: new Phrase(),
    LANDSCAPE: new Phrase(),
    TOOLTIP_LANDSCAPE_PLUS_SLOT: new Phrase(),
    VILLAGER: new Phrase(),
    VILLAGERS: new Phrase(),
    PREVIEW_BOT_MATCH: new Phrase(),
    TOOLTIP_USE_COFFERS: new Phrase(),
    TOOLTIP_CANT_USE_COFFERS: new Phrase(),
    TOOLTIP_USE_VILLAGER: new Phrase(),
    TOOLTIP_CANT_USE_VILLAGER: new Phrase(),
    CARDS_BANNED_BY_PLAYERS: new Phrase(),
    AUTOCOMPLETE_CARD_LISTS: new Phrase(),
    RESPECTED_CARD_LISTS: new Phrase(),
    BANNED_CARDS: new Phrase(),
    DISLIKED_CARDS: new Phrase(),
    LIKED_CARDS: new Phrase(),
    KINGDOM_GENERATOR_PERCENTAGES: new Phrase(),
    MOUSE_TOY: new Phrase(),
    DAYS: new Phrase(),
    SHOW_CHAT: new Phrase(),
    HIDE_CHAT: new Phrase(),
    UNBLACKLIST: new Phrase(),
    AUTOMATCH_LABEL_CARD_POOL_RANGE: new Phrase(),
    AUTOMATCH_LABEL_EXPANSIONS: new Phrase(),
    AUTOMATCH_LABEL_RATING: new Phrase(),
    BASE_ONLY: new Phrase(),
    SIMPLE: new Phrase(),
    TOOLTIP_OWNED_ENABLED: new Phrase(),
    TOOLTIP_OWNED_DISABLED: new Phrase(),
    TOOLTIP_VOID_ENABLED: new Phrase(),
    TOOLTIP_VOID_DISABLED: new Phrase(),
    TOOLTIP_BASE_SET: new Phrase(),
    EXPLAIN_SUBSCRIPTIONS: new Phrase(),
    TOOLTIP_MIN_MAX_EXPANSIONS_TABLE: new Phrase(),
    CHOOSE_CARD_POOL: new Phrase(),
    USE_LEVEL: new Phrase(),
    VIEW_INTRODUCTION_CARD_SELECTION: new Phrase(),
    CARD_SELECTION_INTRODUCTION_TEXT: new Phrase(),
    VIEW_CARD_POOL_LEVELS: new Phrase(),
    CARD_POOL_BOX_HEADER: new Phrase(),
    CARD_POOL_LEVEL: new Phrase(),
    MIN_CARD_POOL_LEVEL: new Phrase(),
    CARD_POOL_LEVEL_SMALL: new Phrase(),
    USED_CARD_POOL_LEVEL: new Phrase(),
    LEVEL_DESCRIPTIONS: new Phrase(),
    NEW_CARDS: new Phrase(),
    SHOW_LEVEL: new Phrase(),
    SUBSCRIPTION_YES: new Phrase(),
    SUBSCRIPTION_FOR: new Phrase(),
    SUBSCRIPTION_NO: new Phrase(),
    EMPTY_BECAUSE_NOT_SUBSCRIBED: new Phrase(),
    EMPTY_BECAUSE_DISABLED: new Phrase(),
    INHERITANCE_INSTRUCTIONS: new Phrase(),
    FAVOR: new Phrase(),
    FAVORS: new Phrase(),
    FAVORS_MANY: new Phrase(),
    FERRYMAN_CARD: new Phrase(),
    RIVERBOAT_CARD: new Phrase(),
    NO_PONG_RECEIVED: new Phrase(),
    MESSAGE_INDEX_MISMATCH: new Phrase(),
    INDIVIDUAL_EXPANSIONS: new Phrase(),
    WARNING_SEARCHING_SUBSCRIBER: new Phrase(),
    WARNING_MAX_RATING_LOW: new Phrase(),
    WARNING_MIN_RATING_HIGH: new Phrase(),
    SEARCHING_2E_PREVIEWS: new Phrase(),
    WARNING_ERRATA: new Phrase(),
    DONT_SHOW_THIS_MESSAGE_AGAIN: new Phrase(),
    REPLAY_LAST_KINGDOM: new Phrase(),
    RECONNECTING_FAILED_MESSAGE: new Phrase(),
    TRY_AGAIN: new Phrase(),
    RETURN_TO_LOBBY: new Phrase(),
    ALL: new Phrase(),
    INSTRUCT_TO_RECONNECT: new Phrase(),
    TIMED_OUT: new Phrase(),
    RECONNECT: new Phrase(),
    SHADOW_CARDS: new Phrase(),
    OTHER_SERVER: new Phrase()
};

function CleanupButtonText(enableText, disableText) {
    var self = this;
    self.enableText = enableText;
    self.disableText = disableText;
}

function CleanupAction() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var CleanupActions = {
    TOPDECK: new CleanupAction(),
    EXCHANGE: new CleanupAction()
};

function GrammaticalNumber() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var GrammaticalNumbers = {
    SINGULAR: new GrammaticalNumber(),
    PLURAL: new GrammaticalNumber(),
    MANY: new GrammaticalNumber()
};

var CARDLIST_LABELS = [Phrases.BANNED_CARDS, undefined, Phrases.LIKED_CARDS, Phrases.DISLIKED_CARDS];