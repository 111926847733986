"use strict";

webclient.component("reminders", {
    bindings: {
        player: '<'
    },
    controller: ['game', 'gameBroadcaster', '$scope', function (game, gameBroadcaster, $scope) {
        var self = this;

        var getLengthClass = function getLengthClass(number) {
            if (number > 999) return "four-digits";else if (number > 99) return "three-digits";else return "";
        };
        self.getReminders = function () {
            return self.player.activeReminders;
        };
        self.getClasses = function (reminder) {
            return (reminder.blinking ? "reminder-art blinking-reminder" : "reminder-art") + getLengthClass(reminder.frequency);
        };
        self.getArtURL = function (reminder) {
            return getReminderArtURL(reminder.cardName);
        };

        self.handleClick = function (reminder, event) {
            gameBroadcaster.send(Events.CARD_STUDY_REQUEST, reminder.cardName);
        };
        self.getText = function (reminder) {
            return reminder.frequency > 1 ? reminder.frequency : "";
        };
    }],

    template: "\n    <div ng-if=\"$ctrl.getReminders().length > 0\" class=\"reminders-container\">\n        <div\n          ng-repeat=\"reminder in $ctrl.getReminders()\"\n          class=\"player-counter reminder-counter {{getClasses(reminder)}}\">\n            <img      \n                ng-right-click=\"$ctrl.handleClick(reminder)\" \n                on-long-press=\"$ctrl.handleClick(reminder)\" \n                ng-src=\"{{$ctrl.getArtURL(reminder)}}\">\n            <span> {{$ctrl.getText(reminder)}} </span>\n        </div>\n    </div>"
});