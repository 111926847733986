"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var LANDSCAPE_TYPES = {
    TRAIT: 32,
    WAY: 16,
    PROJECT: 8,
    LANDMARK: 4,
    EVENT: 2,
    NOTHING: 1,
    ALL: 63
};

var RequiredKingdomSlot = function () {
    function RequiredKingdomSlot() {
        _classCallCheck(this, RequiredKingdomSlot);
    }

    _createClass(RequiredKingdomSlot, null, [{
        key: "parse",
        value: function parse(reader) {
            var type = reader.readInt();
            if (type === 0) {
                var cardName = CardName.parse(reader);
                return new RequiredCard(cardName);
            } else if (type === 1) {
                var expansionName = Expansion.parse(reader);
                return new RequiredExpansion(expansionName);
            } else if (type === 2) {
                var bitmask = reader.readInt();
                return new RequiredLandscapeTypes(bitmask);
            } else {
                throw new Error("Unknown required kingdom slot type: " + type);
            }
        }
    }]);

    return RequiredKingdomSlot;
}();

var RequiredCard = function (_RequiredKingdomSlot) {
    _inherits(RequiredCard, _RequiredKingdomSlot);

    function RequiredCard(cardName) {
        _classCallCheck(this, RequiredCard);

        var _this = _possibleConstructorReturn(this, (RequiredCard.__proto__ || Object.getPrototypeOf(RequiredCard)).call(this));

        _this.cardName = cardName;
        return _this;
    }

    _createClass(RequiredCard, [{
        key: "serialize",
        value: function serialize(writer) {
            writer.writeInt(0);
            writer.writeInt(getOrdinal(CardNames, this.cardName));
        }
    }, {
        key: "visualize",
        value: function visualize() {
            return addCardColor(LANGUAGE.getCardName[this.cardName].singular, this.cardName);
        }
    }]);

    return RequiredCard;
}(RequiredKingdomSlot);

var RequiredExpansion = function (_RequiredKingdomSlot2) {
    _inherits(RequiredExpansion, _RequiredKingdomSlot2);

    function RequiredExpansion(expansion) {
        _classCallCheck(this, RequiredExpansion);

        var _this2 = _possibleConstructorReturn(this, (RequiredExpansion.__proto__ || Object.getPrototypeOf(RequiredExpansion)).call(this));

        _this2.expansion = expansion;
        return _this2;
    }

    _createClass(RequiredExpansion, [{
        key: "serialize",
        value: function serialize(writer) {
            writer.writeInt(1);
            writer.writeInt(getOrdinal(Expansions, this.expansion));
        }
    }, {
        key: "visualize",
        value: function visualize() {
            var setSel = SelectionSet.fromExpansion(this.expansion, null);
            return setSel.uniqueDisplayName;
        }
    }]);

    return RequiredExpansion;
}(RequiredKingdomSlot);

var RequiredLandscapeTypes = function (_RequiredKingdomSlot3) {
    _inherits(RequiredLandscapeTypes, _RequiredKingdomSlot3);

    function RequiredLandscapeTypes(landscapeTypes) {
        _classCallCheck(this, RequiredLandscapeTypes);

        // Stopgap till we make the initial table not have an ally slot
        var _this3 = _possibleConstructorReturn(this, (RequiredLandscapeTypes.__proto__ || Object.getPrototypeOf(RequiredLandscapeTypes)).call(this));

        if (landscapeTypes & LANDSCAPE_TYPES.ALL > 0) _this3.landscapeTypes = landscapeTypes & LANDSCAPE_TYPES.ALL;else _this3.landscapeTypes = landscapeTypes;

        // if ((landscapeTypes & (-LANDSCAPE_TYPES.ALL - 1)) !== 0) {
        //     if (isDebug) console.log ("invalid landscapeTypes on construct: ",landscapeTypes); 
        // }

        // if (this.landscapeTypes === 0) {
        //     this.landscapeTypes = LANDSCAPE_TYPES.NOTHING;
        //     if (isDebug) console.log ("this.landscapeTypes was initially 0!");
        // }
        return _this3;
    }

    _createClass(RequiredLandscapeTypes, [{
        key: "serialize",
        value: function serialize(writer) {
            writer.writeInt(2);

            //check if <> 0
            var lsT = this.landscapeTypes;
            if (lsT === 0) {
                if (isDebug) console.log("invalid landscapeTypes on write: ", lsT);
                lsT = LANDSCAPE_TYPES.NOTHING;
            }

            writer.writeInt(lsT);
        }
    }, {
        key: "allows",
        value: function allows(landscapeType) {
            return (this.landscapeTypes & landscapeType) !== 0;
        }
    }, {
        key: "allowTypes",
        value: function allowTypes(newLandscapeTypes) {
            this.landscapeTypes |= newLandscapeTypes;
        }
    }, {
        key: "disallowTypes",
        value: function disallowTypes(newLandscapeTypes) {
            this.landscapeTypes &= -newLandscapeTypes - 1;
        }
    }, {
        key: "getString",
        value: function getString() {
            if (LANGUAGE.getEnvironment.getRequiredLandscapeTypesString) return LANGUAGE.getEnvironment.getRequiredLandscapeTypesString(this);

            if (this.landscapeTypes === LANDSCAPE_TYPES.ALL) return LANGUAGE.getPhrase[Phrases.LANDSCAPE] + " " + LANGUAGE.getPhrase[Phrases.OR] + " " + LANGUAGE.getPhrase[Phrases.LANDSCAPE_UNUSED];

            if (this.landscapeTypes === (LANDSCAPE_TYPES.ALL & -LANDSCAPE_TYPES.NOTHING - 1)) return LANGUAGE.getPhrase[Phrases.LANDSCAPE];

            var landscapeString = "";
            if (this.allows(LANDSCAPE_TYPES.EVENT)) landscapeString += "/" + LANGUAGE.getType[Types.EVENT];
            if (this.allows(LANDSCAPE_TYPES.LANDMARK)) landscapeString += "/" + LANGUAGE.getType[Types.LANDMARK];
            if (this.allows(LANDSCAPE_TYPES.PROJECT)) landscapeString += "/" + LANGUAGE.getType[Types.PROJECT];
            if (this.allows(LANDSCAPE_TYPES.WAY)) landscapeString += "/" + LANGUAGE.getType[Types.WAY];
            if (this.allows(LANDSCAPE_TYPES.NOTHING)) landscapeString += " " + LANGUAGE.getPhrase[Phrases.OR] + " " + LANGUAGE.getPhrase[Phrases.LANDSCAPE_UNUSED];

            return landscapeString.slice(1);
        }
    }, {
        key: "visualize",
        value: function visualize() {
            return this.getString();
        }
    }], [{
        key: "getAll",
        value: function getAll() {
            // assumes that LANDSCAPE_TYPES has the bitfield filled from LSB first, and LSB is LANDSCAPE_TYPES.NOTHING
            // we skip 0 (invalid) and 1 (NOTHING, which is not needed because it represents the remaining slots anyway)
            var allLandscapeTypesComb = [];
            for (var i = 2; i <= LANDSCAPE_TYPES.ALL; i++) {
                allLandscapeTypesComb.push(i);
            }return allLandscapeTypesComb;
        }
    }]);

    return RequiredLandscapeTypes;
}(RequiredKingdomSlot);