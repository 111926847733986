"use strict";

var EsperantoUserPreferences = {};

EsperantoUserPreferences[UserPrefIds.EMAIL] = "Retpoŝto";
EsperantoUserPreferences[UserPrefIds.CARD_OWNERSHIP] = "Poseditaj kartoj";
EsperantoUserPreferences[UserPrefIds.LIKED_CARDS] = "Ŝatitaj kartoj";
EsperantoUserPreferences[UserPrefIds.DISLIKED_CARDS] = "Malŝatitaj kartoj";
EsperantoUserPreferences[UserPrefIds.BANNED_CARDS] = "Malpermesitaj kartoj";
EsperantoUserPreferences[UserPrefIds.MOVE_KINGDOM] = "Movu regnon kaj ludlokon";
EsperantoUserPreferences[UserPrefIds.CONFIRM_ACTIONS] = "Konfirumu unusole rubigi, forĵeti & Ekzili";
EsperantoUserPreferences[UserPrefIds.PREFERRED_BACKGROUND] = "Preferita fono";
EsperantoUserPreferences[UserPrefIds.USE_AUTOBUY] = "Uzu aŭtoaĉeton";
EsperantoUserPreferences[UserPrefIds.FOLLOWERS_SEE_ACTIVITY] = "Sekvantoj vidas vian agadon";
EsperantoUserPreferences[UserPrefIds.NOTIFICATION_SOUNDS] = "Ludu avizsonojn";
EsperantoUserPreferences[UserPrefIds.SOUND_FX] = "Ludu sonefektojn";
EsperantoUserPreferences[UserPrefIds.SPECTATORS_SEE_HAND] = "Spektantoj povas vidi miajn kartojn";
EsperantoUserPreferences[UserPrefIds.AUTOPLAY_SETTINGS] = "Aŭtoludu la sekvajn kartojn";
EsperantoUserPreferences[UserPrefIds.LOG_FIXED_BASIC_BONUS] = "Raportu ĉiujn +Agoj, +Aĉetoj, +Moneroj";
EsperantoUserPreferences[UserPrefIds.MAKE_THRONE_ROOM_ORANGE] = "Koloru oranĝe Tronoĉambro, kiu restas en ludo";
EsperantoUserPreferences[UserPrefIds.MAKE_DURATIONS_NOT_ORANGE] = "Dinamike forigu oranĝkoloron el Daŭrokartoj";
EsperantoUserPreferences[UserPrefIds.TRANSPARENT_WAY_BUTTON] = "Travidebla butonoj de Vojoj";
EsperantoUserPreferences[UserPrefIds.CHAT_VISIBLE] = "Babilado estas videbla";
EsperantoUserPreferences[UserPrefIds.SPECTATOR_CHAT_IN_AUTOMATCH] = "Vidas spektantbabiladon en aŭtomatĉo";
EsperantoUserPreferences[UserPrefIds.CARD_POOL_LEVEL] = "Kartgrupnivelo";
EsperantoUserPreferences[UserPrefIds.ANIMATION_TYPE] = "Animacispeco";
EsperantoUserPreferences[UserPrefIds.PREMOVES] = "Antaŭmovoj";

EsperantoUserPreferences.USERNAME = "Salutnomo";
EsperantoUserPreferences.PASSWORD = "Pasvorto";
EsperantoUserPreferences.OLD_PASSWORD = "Malnova pasvorto";
EsperantoUserPreferences.NEW_PASSWORD = "Nova pasvorto";
EsperantoUserPreferences.REPEAT_PASSWORD = "Retajpu novan pasvorton";
EsperantoUserPreferences.OWNERSHIP_INTRO = "Via nuntempa abonoj:";
EsperantoUserPreferences.OWNERSHIP_INTRO_EMPTY = "Vi nuntempe ne havas abonojn.";
EsperantoUserPreferences.FAMILIAR_WITH = "Mi konas: ";
EsperantoUserPreferences.USE_BONUS_CODE = "Uzu varban kodon";
EsperantoUserPreferences.BONUS_CODE_ORIGIN = "Via varba kodo estas el: ";
EsperantoUserPreferences.BONUS_CODE_CHOOSE_EXPANSION = "Elektu etendigon: ";

var EsperantoProfileButtons = {};

EsperantoProfileButtons[true] = "Jes";
EsperantoProfileButtons[false] = "Ne";
EsperantoProfileButtons[UserPrefIds.FAMILIAR_CARDS] = {
    ALL: "Ĉi tiun etendigon",
    NONE: "Nenion el ĉi tiu etendigo",
    ALL_EXPANSIONS: "Ĉiujn etendigojn"
};

var EsperantoStoreLabels = {};

EsperantoStoreLabels[StorePackages.HALF] = "Arĝenta abono";
EsperantoStoreLabels[StorePackages.ALL] = "Ora abono";

EsperantoStoreLabels[PriceCategories.PURCHASED] = "Aĉetu etendigojn: ";
EsperantoStoreLabels[PriceCategories.ALREADY_OWNED] = "Jam abonis";
EsperantoStoreLabels[PriceCategories.DISCOUNT] = "Rabato";
EsperantoStoreLabels[PriceCategories.TRANSACTION] = "Transakcikotizo";
EsperantoStoreLabels[PriceCategories.SUBTOTAL] = "Subsumo";
EsperantoStoreLabels[PriceCategories.VAT] = "AVI";
EsperantoStoreLabels[PriceCategories.TOTAL] = "Sumo";

EsperantoStoreLabels.SELLER_INFO = "Vendistinformo";
EsperantoStoreLabels.PRODUCT_CONFIRM = "Aĉetante ĝis DATE: EXPANSIONS";
EsperantoStoreLabels.PAYMENT_HANDLER = "Pago estos traktita de nia paganta provizanto Mollie.";
EsperantoStoreLabels.PROCEED_TO_PAYMENT = "Iru al pago";

var EsperantoAutoplayLabels = {};

EsperantoAutoplayLabels.REGULAR_OPTIONS = "Ordinaraj Elektoj";
EsperantoAutoplayLabels.AUTOPLAY_OPTIONS = "Aŭtoludo Elektoj";
EsperantoAutoplayLabels.AUTOPLAY_DOCUMENTATION_GENERIC = "Kio estas Aŭtoludo?";
EsperantoAutoplayLabels.AUTOPLAY_DOCUMENTATION_SPECIFIC = "-specifaj elektoj";
EsperantoAutoplayLabels.CUSTOMIZE_OPTIONS = "Tajloru elektojn";
EsperantoAutoplayLabels.ALL_OFF = "Ĉiuj elŝaltitaj";
EsperantoAutoplayLabels.ALL_DEFAULT = "Ĉiuj defaŭltaj";
EsperantoAutoplayLabels.ALL_CONVENIENT = "Ĉiuj taŭgaj";

var EsperantoAnimationTypes = {};
EsperantoAnimationTypes[AnimationOptions.NEW_PLAYER] = "Malrapidega";
EsperantoAnimationTypes[AnimationOptions.BEGINNER] = "Malrapida";
EsperantoAnimationTypes[AnimationOptions.MEDIUM] = "Norma";
EsperantoAnimationTypes[AnimationOptions.ADVANCED] = "Rapida";
EsperantoAnimationTypes[AnimationOptions.VERYFAST] = "Rapidega";
EsperantoAnimationTypes[AnimationOptions.INSTANT] = "Tuja";

// Missing lines as of 09.01.2023 19:11:51
EsperantoStoreLabels[StorePackages.CORE] = "Core subscription";
EsperantoStoreLabels[StorePackages.COMPLETE] = "Complete subscription";