"use strict";

var RussianAbilityDescriptions = {};

RussianAbilityDescriptions[AbilityNames.BUY] = "Купить";
RussianAbilityDescriptions[AbilityNames.PLAY_ATTACK] = "Сыграть";
RussianAbilityDescriptions[AbilityNames.DRAW_INSTRUCTIONS] = "Вытяните Argument0 карт(ы)";
RussianAbilityDescriptions[AbilityNames.PLAY_INSTRUCTIONS] = "PLAY_INSTRUCTIONS";
RussianAbilityDescriptions[AbilityNames.RESOLVE_EVENT] = "RESOLVE_EVENT";
RussianAbilityDescriptions[AbilityNames.GAIN_BY_NAME] = "Получите Argument0 (Argument1)";
RussianAbilityDescriptions[AbilityNames.FISHING_VILLAGE_ABILITY] = "+1 Действие (Рыбацкая Деревня)";
RussianAbilityDescriptions[AbilityNames.REMOVE_COST_REDUCTION] = "REMOVE_COST_REDUCTION";
RussianAbilityDescriptions[AbilityNames.HAVEN_ABILITY] = "Гавань";
RussianAbilityDescriptions[AbilityNames.SAVE_RETURN_TO_HAND] = "Сбережение";
RussianAbilityDescriptions[AbilityNames.CARAVAN_ABILITY] = "Караван";
RussianAbilityDescriptions[AbilityNames.MERCHANT_SHIP_ABILITY] = "Торговый Корабль";
RussianAbilityDescriptions[AbilityNames.OUTPOST_ABILITY] = "Аванпост";
RussianAbilityDescriptions[AbilityNames.TACTICIAN_ABILITY] = "Тактик";
RussianAbilityDescriptions[AbilityNames.WHARF_ABILITY] = "+2 Карты, +1 Покупка (Верфь)";
RussianAbilityDescriptions[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = "Торговцы Лошадьми";
RussianAbilityDescriptions[AbilityNames.DUCHESS_MAY_GAIN] = "Можете получить Герцогиню";
RussianAbilityDescriptions[AbilityNames.FOOLS_GOLD_TRASH] = "Можете выкинуть Золото Дураков";
RussianAbilityDescriptions[AbilityNames.NOBLE_BRIGAND] = "Благородный Разбойник";
RussianAbilityDescriptions[AbilityNames.HAGGLER_ABILITY] = "Торгаш";
RussianAbilityDescriptions[AbilityNames.INN_ABILITY] = "Гостиница";
RussianAbilityDescriptions[AbilityNames.MANDARIN] = "Мандарин";
RussianAbilityDescriptions[AbilityNames.FARMLAND] = "Угодья";
RussianAbilityDescriptions[AbilityNames.GAIN_CHEAPER] = "Получить карту дешевле";
RussianAbilityDescriptions[AbilityNames.GAIN_TWO_RUINS] = "Получить 2 Руины";
RussianAbilityDescriptions[AbilityNames.HUNTING_GROUNDS] = "Охотничьи Угодья";
RussianAbilityDescriptions[AbilityNames.SQUIRE_GAIN_ATTACK] = "Получить карту Атаки";
RussianAbilityDescriptions[AbilityNames.URCHIN_TRANSFORM] = "Можно выкинуть Беспризорника из игры.";
RussianAbilityDescriptions[AbilityNames.FEODUM_GAIN_SILVERS] = "Получить 3 Серебра";
RussianAbilityDescriptions[AbilityNames.DOCTOR_OVERPAY] = "Переплатить за Доктора";
RussianAbilityDescriptions[AbilityNames.RESOLVE_DOCTOR_OVERPAY] = "Выкиньте или сбросьте карты с колоды";
RussianAbilityDescriptions[AbilityNames.HERALD_OVERPAY] = "Переплатить за Глашатая";
RussianAbilityDescriptions[AbilityNames.RESOLVE_HERALD_OVERPAY] = "Положите карты из сброса на верх колоды";
RussianAbilityDescriptions[AbilityNames.MASTERPIECE_OVERPAY] = "Переплатить за Шедевр";
RussianAbilityDescriptions[AbilityNames.RESOLVE_MASTERPIECE_OVERPAY] = "Получите [^pSilver]";
RussianAbilityDescriptions[AbilityNames.STONEMASON_OVERPAY] = "Переплатить за Каменщика";
RussianAbilityDescriptions[AbilityNames.RESOLVE_STONEMASON_OVERPAY] = "Получите 2 карты Действия";
RussianAbilityDescriptions[AbilityNames.EXCHANGE] = "Обменять";
RussianAbilityDescriptions[AbilityNames.AMULET] = "Амулет";
RussianAbilityDescriptions[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = "Охрана Каравана";
RussianAbilityDescriptions[AbilityNames.DUNGEON_ABILITY] = "Подземелье";
RussianAbilityDescriptions[AbilityNames.GEAR_ABILITY] = "Снаряжение";
RussianAbilityDescriptions[AbilityNames.MESSENGER_GAIN] = "Посланник";
RussianAbilityDescriptions[AbilityNames.BRIDGE_TROLL_ABILITY] = "Тролль под Мостом";
RussianAbilityDescriptions[AbilityNames.HAUNTED_WOODS_DURATION] = "Тянуть карты (Призрачный Лес)";
RussianAbilityDescriptions[AbilityNames.HAUNTED_WOODS_TOPDECK] = "Положить на верх колоды (Призрачный Лес)";
RussianAbilityDescriptions[AbilityNames.LOST_CITY] = "Затерянный Город";
RussianAbilityDescriptions[AbilityNames.WINE_MERCHANT_DISCARD] = "Сбросить Винный Торговец";
RussianAbilityDescriptions[AbilityNames.HIRELING] = "Наёмник";
RussianAbilityDescriptions[AbilityNames.SWAMP_HAG_DURATION] = "Болотная Ведьма";
RussianAbilityDescriptions[AbilityNames.TRASH_TOKEN_ABILITY] = "Жетон Свалки";
RussianAbilityDescriptions[AbilityNames.EMPORIUM_GAIN_POINTS] = "Получить очки Победы за Эмпорий";
RussianAbilityDescriptions[AbilityNames.ROCKS_GAINS_SILVER] = "Получить Серебро";
RussianAbilityDescriptions[AbilityNames.FORTUNE] = "Состояние";
RussianAbilityDescriptions[AbilityNames.CRUMBLING_CASTLE] = "Полуразрушенный Замок";
RussianAbilityDescriptions[AbilityNames.HAUNTED_CASTLE] = "Замок с Привидениями";
RussianAbilityDescriptions[AbilityNames.SPRAWLING_CASTLE] = "Разросшийся Замок";
RussianAbilityDescriptions[AbilityNames.GRAND_CASTLE] = "Великий Замок";
RussianAbilityDescriptions[AbilityNames.ENCHANTRESS_ABILITY] = "+2 Карты";
RussianAbilityDescriptions[AbilityNames.ENCHANTRESS_CANTRIP] = "+1 Карта, +1 Действие";
RussianAbilityDescriptions[AbilityNames.TEMPLE] = "Храм";
RussianAbilityDescriptions[AbilityNames.VILLA] = "Вилла";
RussianAbilityDescriptions[AbilityNames.ARCHIVE_ABILITY] = "Архив (Argument0).";
RussianAbilityDescriptions[AbilityNames.CAPITAL_TAKE_DEBT] = "Взять долг (Капитал)";
RussianAbilityDescriptions[AbilityNames.CHARM] = "Безделушка";
RussianAbilityDescriptions[AbilityNames.FORUM] = "Форум";
RussianAbilityDescriptions[AbilityNames.HERMIT] = "Выкинуть Отшельника";
RussianAbilityDescriptions[AbilityNames.DONATE] = "Пожертвование";
RussianAbilityDescriptions[AbilityNames.TAX_RECEIVE] = "Получить долг (Налог)";
RussianAbilityDescriptions[AbilityNames.DOMINATE] = "Господство";
RussianAbilityDescriptions[AbilityNames.VP_TO_AQUEDUCT] = "Очки Победы на Акведук";
RussianAbilityDescriptions[AbilityNames.VP_FROM_AQUEDUCT] = "Очки Победы с Акведука";
RussianAbilityDescriptions[AbilityNames.ARENA_DISCARD_ACTION] = "Арена";
RussianAbilityDescriptions[AbilityNames.VP_FROM_BASILICA] = "Базилика";
RussianAbilityDescriptions[AbilityNames.VP_FROM_BATHS] = "Купальни";
RussianAbilityDescriptions[AbilityNames.VP_FROM_BATTLEFIELD] = "Поле Боя";
RussianAbilityDescriptions[AbilityNames.VP_FROM_COLONNADE] = "Колоннада";
RussianAbilityDescriptions[AbilityNames.VP_TO_DEFILED_SHRINE] = "Очки Победы на Осквернённую Святыню";
RussianAbilityDescriptions[AbilityNames.VP_FROM_DEFILED_SHRINE] = "Очки Победы с Осквернённой Святыни";
RussianAbilityDescriptions[AbilityNames.VP_FROM_LABYRINTH] = "Лабиринт";
RussianAbilityDescriptions[AbilityNames.MOUNTAIN_PASS_BIDDING] = "Горный Перевал";
RussianAbilityDescriptions[AbilityNames.TOMB] = "Гробница";
RussianAbilityDescriptions[AbilityNames.LIGHTHOUSE_COIN] = "Маяк";
RussianAbilityDescriptions[AbilityNames.REACTION] = "Реакция";
RussianAbilityDescriptions[AbilityNames.CALL_GUIDE] = "Вызвать Проводника";
RussianAbilityDescriptions[AbilityNames.CALL_RATCATCHER] = "Вызвать Крысолова";
RussianAbilityDescriptions[AbilityNames.CALL_TRANSMOGRIFY] = "Вызвать Метаморфозу";
RussianAbilityDescriptions[AbilityNames.CALL_TEACHER] = "Вызвать Учителя";
RussianAbilityDescriptions[AbilityNames.CALL_DUPLICATE] = "Вызвать Дублирование";
RussianAbilityDescriptions[AbilityNames.CALL_COIN] = "Вызвать Королевскую Монету";
RussianAbilityDescriptions[AbilityNames.CALL_ROYAL_CARRIAGE] = "Вызвать Королевскую Карету";
RussianAbilityDescriptions[AbilityNames.START_OF_TURN] = "Начать ход";
RussianAbilityDescriptions[AbilityNames.START_BUY_PHASE] = "Начать фазу покупок";
RussianAbilityDescriptions[AbilityNames.END_OF_BUY_PHASE] = "Закончить фазу покупок";
RussianAbilityDescriptions[AbilityNames.BETWEEN_TURNS] = "BETWEEN_TURNS";
RussianAbilityDescriptions[AbilityNames.END_OF_TURN] = "END_OF_TURN";
RussianAbilityDescriptions[AbilityNames.SUMMON_PLAY] = "Зов Argument0.";
RussianAbilityDescriptions[AbilityNames.CARAVAN_GUARD_REACTION] = "Можете сыграть Охрану Каравана.";
RussianAbilityDescriptions[AbilityNames.TRAVELLING_FAIR_REACTION] = "На верх колоды (Странствующий Балаган)";
RussianAbilityDescriptions[AbilityNames.MOAT_REACTION] = "Можете раскрыть Ров.";
RussianAbilityDescriptions[AbilityNames.HORSE_TRADERS_REACTION] = "Можете раскрыть Торговцев Лошадьми.";
RussianAbilityDescriptions[AbilityNames.BEGGAR_REACTION] = "Можете раскрыть Попрошайку.";
RussianAbilityDescriptions[AbilityNames.MARKET_SQUARE_REACTION] = "Можете раскрыть Рыночную Площадь.";
RussianAbilityDescriptions[AbilityNames.TRADER_REACTION] = "Можете раскрыть Торговецы.";
RussianAbilityDescriptions[AbilityNames.DIPLOMAT_REACTION] = "Можете раскрыть Дипломата";
RussianAbilityDescriptions[AbilityNames.TOPDECK_REACTION] = "Можете положить наверх.";
RussianAbilityDescriptions[AbilityNames.WATCHTOWER_TRASH_REACTION] = "Можете раскрыть Сторожевую Башню, чтобы выкинуть Argument0.";
RussianAbilityDescriptions[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "Можете раскрыть Сторожевую Башню, чтобы положить Argument0 наверх.";
RussianAbilityDescriptions[AbilityNames.PRINCE_PLAY] = "Сыграть Argument0 (Принц).";
RussianAbilityDescriptions[AbilityNames.TUNNEL_REVEAL] = "Раскрыть Туннель.";
RussianAbilityDescriptions[AbilityNames.POSSESSION_GAIN_INSTEAD] = "Argument0 получает Argument2 вместо Argument1.";
RussianAbilityDescriptions[AbilityNames.HOVEL_TRASH] = "Выкинуть Лачугу.";
RussianAbilityDescriptions[AbilityNames.DELUDED_RETURNED] = "Вернуть Сбитого С Толку";
RussianAbilityDescriptions[AbilityNames.ENVIOUS_RETURNED] = "Вернуть Завистливого";
RussianAbilityDescriptions[AbilityNames.RECEIVE_BOON] = "Принять Argument0";
RussianAbilityDescriptions[AbilityNames.COBBLER_ABILITY] = "Получить карту";
RussianAbilityDescriptions[AbilityNames.CRYPT_ABILITY] = "Склеп (Argument0).";
RussianAbilityDescriptions[AbilityNames.DEN_OF_SIN_ABILITY] = "+2 Карты (Логово Греха)";
RussianAbilityDescriptions[AbilityNames.FAITHFUL_HOUND] = "Верный Пес";
RussianAbilityDescriptions[AbilityNames.FAITHFUL_HOUND_RETURN] = "Верный Пес";
RussianAbilityDescriptions[AbilityNames.GHOST_TOWN_ABILITY] = "+1 Карта, +1 Действие (Город-Призрак)";
RussianAbilityDescriptions[AbilityNames.GUARDIAN_COIN] = "+1 Монета (Страж)";
RussianAbilityDescriptions[AbilityNames.RAIDER_ABILITY] = "+3 Монеты (Налетчик)";
RussianAbilityDescriptions[AbilityNames.SECRET_CAVE] = "+3 Монеты (Тайный Грот)";
RussianAbilityDescriptions[AbilityNames.CEMETERY] = "Выкиньте до 4 карт";
RussianAbilityDescriptions[AbilityNames.HAUNTED_MIRROR] = "Призрачное Зеркало";
RussianAbilityDescriptions[AbilityNames.GHOST] = "Призрак Argument0.";
RussianAbilityDescriptions[AbilityNames.REMOVE_FLIPPED_STATUS] = "X";
RussianAbilityDescriptions[AbilityNames.LOST_IN_THE_WOODS] = "Можете сбросить карту, чтобы принять Благодеяние";
RussianAbilityDescriptions[AbilityNames.BLESSED_VILLAGE] = "Принять Благодеяние";
RussianAbilityDescriptions[AbilityNames.FORTRESS] = "Положить Крепость в руку.";
RussianAbilityDescriptions[AbilityNames.RECEIVE_HEX] = "Принять Порчу";
RussianAbilityDescriptions[AbilityNames.CHANGELING_MAY_EXCHANGE] = "Можете обменять Argument0 на Подменыша";
RussianAbilityDescriptions[AbilityNames.TRADE_ROUTE_MOVE_TOKEN] = "Положить жетон на мат Торгового Пути";
RussianAbilityDescriptions[AbilityNames.DUCAT] = "Можете выкинуть Медь";
RussianAbilityDescriptions[AbilityNames.CARGO_SHIP_SET_ASIDE] = "Отложите Argument0 вместе с Грузовой Корабль";
RussianAbilityDescriptions[AbilityNames.CARGO_SHIP_RETURN] = "Верните Argument0 вместе с Грузовой Корабль";
RussianAbilityDescriptions[AbilityNames.IMPROVE] = "Усовершенствуйте карту Действия из игры";
RussianAbilityDescriptions[AbilityNames.PRIEST] = "+$2 (Священник)";
RussianAbilityDescriptions[AbilityNames.RESEARCH] = "Положите Argument0 в руку (Исследование)";
RussianAbilityDescriptions[AbilityNames.SILK_MERCHANT] = "+1 Монетка, +1 Хуторянин (Торговец Шёлком)";
RussianAbilityDescriptions[AbilityNames.LACKEYS] = "+2 Хуторянина (Лакей)";
RussianAbilityDescriptions[AbilityNames.SPICES] = "+2 Монетки (Специи)";
RussianAbilityDescriptions[AbilityNames.KEY] = "+$1 (Ключ)";
RussianAbilityDescriptions[AbilityNames.TREASURE_CHEST] = "Получите Золото (Сундук с Сокровищами)";
RussianAbilityDescriptions[AbilityNames.FLAG_BEARER] = "Возьмите Флаг";
RussianAbilityDescriptions[AbilityNames.CATHEDRAL] = "Выкиньте карту (Собор)";
RussianAbilityDescriptions[AbilityNames.CITY_GATE] = "+1 Карта, затем положите карту на верх колоды (Городские Ворота)";
RussianAbilityDescriptions[AbilityNames.PAGEANT] = "Заплатите $1 за +1 Монетку (Карнавальное Шествие)";
RussianAbilityDescriptions[AbilityNames.SEWERS_MAY_TRASH] = "Выкиньте ещё одну карту (Канализация)";
RussianAbilityDescriptions[AbilityNames.EXPLORATION] = "+1 Монетка, +1 Хуторянин (Эксплорация)";
RussianAbilityDescriptions[AbilityNames.FAIR] = "+1 Покупка (Торжище)";
RussianAbilityDescriptions[AbilityNames.SILOS] = "Сбросьте Медь, чтобы вытянуть карты (Силос)";
RussianAbilityDescriptions[AbilityNames.ACADEMY] = "+1 Хуторянин (Академия)";
RussianAbilityDescriptions[AbilityNames.GUILDHALL] = "+1 Монетка (Ратуша)";
RussianAbilityDescriptions[AbilityNames.PIAZZA] = "Раскройте карту и сыграйте её, если это Действие (Базарная Площадь)";
RussianAbilityDescriptions[AbilityNames.BARRACKS] = "+1 Действие (Казарма)";
RussianAbilityDescriptions[AbilityNames.CROP_ROTATION] = "Можете сбросить карту Победы за +2 Карты (Севооборот)";
RussianAbilityDescriptions[AbilityNames.INNOVATION] = "Отложите и сыграйте (Инновация)";
RussianAbilityDescriptions[AbilityNames.CITADEL] = "Сыграйте Действие ещё раз (Цитадель)";
RussianAbilityDescriptions[AbilityNames.SINISTER_PLOT] = "Вытяните карты или добавьте жетон (Зловещий Заговор)";
RussianAbilityDescriptions[AbilityNames.ENCAMPMENT_RETURNED] = "Верните Лагерь в Резерв";
RussianAbilityDescriptions[AbilityNames.EMBARGO_GAIN_CURSE] = "Получите [^nCurse]";
RussianAbilityDescriptions[AbilityNames.CAPTAIN] = "сыграйте карту Действия из Резерва(Капитан)";
RussianAbilityDescriptions[AbilityNames.CHURCH] = "положите Argument0 в руку, затем можете выкинуть карту (Церковь)";
RussianAbilityDescriptions[AbilityNames.POSSESSION_SETS_ASIDE] = "Отложите Argument0 вместе";
RussianAbilityDescriptions[AbilityNames.MINT] = "Выкиньте все ваши Сокровища из игры";
RussianAbilityDescriptions[AbilityNames.SLEIGH_TOPDECK_REACTION] = "Сбросьте Сани чтобы положить Argument0 на верх колоды";
RussianAbilityDescriptions[AbilityNames.SLEIGH_PUT_IN_HAND_REACTION] = "Сбросьте Сани чтобы положить Argument0 в руку";
RussianAbilityDescriptions[AbilityNames.BLACK_CAT_REACTION] = "Сыграйте Чёрного Кота";
RussianAbilityDescriptions[AbilityNames.DISCARD_FROM_EXILE] = "Сбросьте Argument0 из Изгнания.";
RussianAbilityDescriptions[AbilityNames.EXILE_BY_NAME] = "Изгоните Argument0";
RussianAbilityDescriptions[AbilityNames.SHEEPDOG_REACTION] = "Сыграйте Английскую Овчарку";
RussianAbilityDescriptions[AbilityNames.CAVALRY] = "+2 Карты, +1 Покупка и вернитесь в фазу действий.";
RussianAbilityDescriptions[AbilityNames.HOSTELRY] = "Сбросьте Сокровища и получите Лошадей";
RussianAbilityDescriptions[AbilityNames.VILLAGE_GREEN_VILLAGE] = "+1 Карта, +2 Действия";
RussianAbilityDescriptions[AbilityNames.VILLAGE_GREEN_PLAY] = "Сыграйте Лужайку";
RussianAbilityDescriptions[AbilityNames.BARGE] = "+3 Карты, +1 Покупка";
RussianAbilityDescriptions[AbilityNames.FALCONER_REACTION] = "Получите карту дешевле";
RussianAbilityDescriptions[AbilityNames.GATEKEEPER_EXILE] = "Изгоните Argument0";
RussianAbilityDescriptions[AbilityNames.GATEKEEPER_DURATION] = "+3 Монеты";
RussianAbilityDescriptions[AbilityNames.LIVERY] = "Получите Лошадь";
RussianAbilityDescriptions[AbilityNames.MASTERMIND] = "Вы можете сыграть карту трижды";
RussianAbilityDescriptions[AbilityNames.DELAY_PLAY] = "Сыграйте Argument0";
RussianAbilityDescriptions[AbilityNames.INVEST] = "INVEST ABILITY (never shown to user)";
RussianAbilityDescriptions[AbilityNames.REAP_PLAY] = "Сыграйте отложенное Золото";
RussianAbilityDescriptions[AbilityNames.PLUS_CARD_TOKEN] = "+Карта (жетон)";
RussianAbilityDescriptions[AbilityNames.PLUS_ACTION_TOKEN] = "+Действие (жетон)";
RussianAbilityDescriptions[AbilityNames.PLUS_BUY_TOKEN] = "+Покупка (жетон)";
RussianAbilityDescriptions[AbilityNames.PLUS_COIN_TOKEN] = "+Монета (жетон)";
RussianAbilityDescriptions[AbilityNames.KILN] = "Получите копию Argument0";
RussianAbilityDescriptions[AbilityNames.TURTLE_PLAY] = "Сыграйте Argument0";
RussianAbilityDescriptions[AbilityNames.GIVE_PLUS_CARDS] = "+Карта";
RussianAbilityDescriptions[AbilityNames.GIVE_PLUS_ACTIONS] = "+Действие";
RussianAbilityDescriptions[AbilityNames.GIVE_PLUS_BUYS] = "+Покупка";
RussianAbilityDescriptions[AbilityNames.GIVE_PLUS_COINS] = "+Монета";
RussianAbilityDescriptions[AbilityNames.MONKEY_NEXT_TURN_ABILITY] = "+1 Карта";
RussianAbilityDescriptions[AbilityNames.BLOCKADE_ABILITY] = "положите Argument0 в руку";
RussianAbilityDescriptions[AbilityNames.PIRATE_ABILITY] = "получите Сокровище в руку";
RussianAbilityDescriptions[AbilityNames.PIRATE_REACTION] = "Сыграйте Пирата";
RussianAbilityDescriptions[AbilityNames.CORSAIR_NEXT_TURN_ABILITY] = "+1 Карта";
RussianAbilityDescriptions[AbilityNames.CORSAIR_TRASH] = "Выкиньте Argument0";
RussianAbilityDescriptions[AbilityNames.SAILOR_NEXT_TURN] = "+$2, можете выкинуть карту";
RussianAbilityDescriptions[AbilityNames.SAILOR_REACTION] = "Сыграйте Argument0";
RussianAbilityDescriptions[AbilityNames.SKIRMISHER] = "каждый другой игрок сбрасывает до 3";
RussianAbilityDescriptions[AbilityNames.ENCHANTRESS_REPLACE_ABILITY] = "Заменено Заклинательницей";
RussianAbilityDescriptions[AbilityNames.HIGHWAYMAN_NEXT_TURN_ABILITY] = "Сбросьте Разбойника, +3 Карты";
RussianAbilityDescriptions[AbilityNames.HIGHWAYMAN_REPLACE_ABILITY] = "Заменено Разбойником";
RussianAbilityDescriptions[AbilityNames.HIGHWAYMAN_NULLIFIED] = "Обнулено";
RussianAbilityDescriptions[AbilityNames.LEAGUE_OF_BANKERS] = "+$1 за 4 Услуги";
RussianAbilityDescriptions[AbilityNames.WOODWORKERS_GUILD] = "Потратьте Услугу чтобы выкинуть Действие и получить Действие.";
RussianAbilityDescriptions[AbilityNames.CRAFTERS_GUILD] = "Потратьте 2 Услуги чтобы получить карту";
RussianAbilityDescriptions[AbilityNames.CAVE_DWELLERS] = "Потратьте услугу чтобы сбросить и вытянуть карту";
RussianAbilityDescriptions[AbilityNames.DESERT_GUIDES] = "Потратьте услугу чтобы сбросить и вытянуть карты";
RussianAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_CARD] = "Потратьте Услугу за +1 Карту";
RussianAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_ACTION] = "Потратьте Услугу за +1 Действие";
RussianAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_BUY] = "Потратьте Услугу за +1 Покупку";
RussianAbilityDescriptions[AbilityNames.SYCOPHANT] = "+2 Услуги";
RussianAbilityDescriptions[AbilityNames.IMPORTER_ABILITY] = "Получите карту не дороже 5";
RussianAbilityDescriptions[AbilityNames.CONTRACT_ABILITY] = "сыграйте Argument0";
RussianAbilityDescriptions[AbilityNames.MARKET_TOWNS] = "Потратьте Услугу чтобы сыграть Действие";
RussianAbilityDescriptions[AbilityNames.TRAPPERS_LODGE] = "Потратьте Услугу чтобы положить Argument0 на верх колоды";
RussianAbilityDescriptions[AbilityNames.GUILDMASTER] = "+1 Услуга";
RussianAbilityDescriptions[AbilityNames.ISLAND_FOLK] = "Поратьте 5 Услуг за дополнительный ход";
RussianAbilityDescriptions[AbilityNames.GANG_OF_PICKPOCKETS] = "Сбросьте до 4 или потратьте Услугу";
RussianAbilityDescriptions[AbilityNames.COASTAL_HAVEN] = "Оставьте карты в руке до следующего хода";
RussianAbilityDescriptions[AbilityNames.CITY_STATE] = "Потратьте 2 Услуги чтобы сыграть Argument0";
RussianAbilityDescriptions[AbilityNames.CITY_STATE_PLAY] = "---";
RussianAbilityDescriptions[AbilityNames.CIRCLE_OF_WITCHES] = "Потратьте 3 Услуги чтобы другие игроки получили Проклятия";
RussianAbilityDescriptions[AbilityNames.ROYAL_GALLEY_SET_ASIDE] = "Отложить в сторону (Королевская Галера)";
RussianAbilityDescriptions[AbilityNames.ROYAL_GALLEY_PLAY] = "Сыграть Argument0";
RussianAbilityDescriptions[AbilityNames.STRONGHOLD_ABILITY] = "+3 Карты";
RussianAbilityDescriptions[AbilityNames.MOUNTAIN_FOLK] = "Потратьте пять Услуг чтобы вытянуть 3 Карты";
RussianAbilityDescriptions[AbilityNames.PEACEFUL_CULT] = "Потратьте Услуги чтобы выкинуть карты из руки";
RussianAbilityDescriptions[AbilityNames.FELLOWSHIP_OF_SCRIBES] = "Потратьте Услугу чтобы вытянуть карту";
RussianAbilityDescriptions[AbilityNames.LEAGUE_OF_SHOPKEEPERS] = "Владелец Магазина";
RussianAbilityDescriptions[AbilityNames.TERRITORY_GAIN] = "Получите Золото за каждую пустую стопку";
RussianAbilityDescriptions[AbilityNames.WARLORD_ABILITY] = "+2 Карты";
RussianAbilityDescriptions[AbilityNames.GARRISON_ADD_TOKEN] = "Положите жетон";
RussianAbilityDescriptions[AbilityNames.GARRISON_REMOVE_ABILITY] = "+Argument0 Карт";
RussianAbilityDescriptions[AbilityNames.CONJURER] = "Положите эту карту в руку";
RussianAbilityDescriptions[AbilityNames.LICH] = "Сбросьте и получите карту дешевле со Свалки";
RussianAbilityDescriptions[AbilityNames.GALLERIA] = "+1 Покупка";
RussianAbilityDescriptions[AbilityNames.ARCHITECTS_GUILD] = "Получите карту дешевле (не карту Победы)";
RussianAbilityDescriptions[AbilityNames.FAMILY_OF_INVENTORS] = "Сделать стопку дешевле";
RussianAbilityDescriptions[AbilityNames.FOREST_DWELLERS] = "Посмотрите на 3 карты";
RussianAbilityDescriptions[AbilityNames.ASTROLABE_ABILITY] = "+$1, +1 Покупка";
RussianAbilityDescriptions[AbilityNames.TIDE_POOLS_ABILITY] = "Сбросьте 2 Карты";
RussianAbilityDescriptions[AbilityNames.SEA_WITCH_ABILITY] = "Подземелье";
RussianAbilityDescriptions[AbilityNames.CLERK_ABILITY] = "Сыграйте Клерка";
RussianAbilityDescriptions[AbilityNames.TREASURY] = "Положите Казну на верх колоды";
RussianAbilityDescriptions[AbilityNames.MERCHANT_GUILD] = "Монеточки за полученные карты";

RussianAbilityDescriptions[AbilityNames.GUARD_DOG_REACTION] = "Сыграйте Сторожевую Собаку";
RussianAbilityDescriptions[AbilityNames.BERSERKER] = "Сыграйте Берсерка";
RussianAbilityDescriptions[AbilityNames.NOMADS] = "+2 Монеты";
RussianAbilityDescriptions[AbilityNames.TRAIL_REACTION] = "Сыграйте Тропу";
RussianAbilityDescriptions[AbilityNames.WEAVER_PLAY] = "Сыграйте Ткача";
RussianAbilityDescriptions[AbilityNames.SOUK] = "Выкиньте до двух карт";
RussianAbilityDescriptions[AbilityNames.CAULDRON_CURSING] = "Каждый другой игрок получает Проклятие";
RussianAbilityDescriptions[AbilityNames.ALCHEMIST_TOPDECK] = "Положить Алхимика на верх колоды";

RussianAbilityDescriptions[AbilityNames.HERBALIST_TOPDECK] = "Положите Argument0 на верх колоды [Herbalist]";
RussianAbilityDescriptions[AbilityNames.SCHEME_TOPDECK] = "Положите Argument0 на верх колоды [Scheme]";
RussianAbilityDescriptions[AbilityNames.FROG_TOPDECK] = "Положите Argument0 на верх колоды";
RussianAbilityDescriptions[AbilityNames.MERCHANT_CAMP_TOPDECK] = "Положите [Merchant Camp] на верх колоды";
RussianAbilityDescriptions[AbilityNames.WALLED_VILLAGE_TOPDECK] = "Положите [Walled Village] на верх колоды";
RussianAbilityDescriptions[AbilityNames.TENT_TOPDECK] = "Положите [Tent] на верх колоды";
RussianAbilityDescriptions[AbilityNames.HORN_TOPDECK] = "Положите [Border Guard] на верх колоды ([Horn])";
RussianAbilityDescriptions[AbilityNames.PAGE_EXCHANGE] = "Обменяйте [Page] на [Treasure Hunter]";
RussianAbilityDescriptions[AbilityNames.TREASURE_HUNTER_EXCHANGE] = "Обменяйте [Treasure Hunter] на [Warrior]";
RussianAbilityDescriptions[AbilityNames.WARRIOR_EXCHANGE] = "Обменяйте [Warrior] на [Hero]";
RussianAbilityDescriptions[AbilityNames.HERO_EXCHANGE] = "Обменяйте [Hero] на [Champion]";
RussianAbilityDescriptions[AbilityNames.PEASANT_EXCHANGE] = "Обменяйте [Peasant] на [Soldier]";
RussianAbilityDescriptions[AbilityNames.SOLDIER_EXCHANGE] = "Обменяйте [Soldier] на [Fugitive]";
RussianAbilityDescriptions[AbilityNames.FUGITIVE_EXCHANGE] = "Обменяйте [Fugitive] на [Disciple]";
RussianAbilityDescriptions[AbilityNames.DISCIPLE_EXCHANGE] = "Обменяйте [Disciple] на [Teacher]";
RussianAbilityDescriptions[AbilityNames.CAGE_TRASH] = "Выкиньте [Cage]";
RussianAbilityDescriptions[AbilityNames.CAGE_PUT_IN_HAND] = "Положите Argument0 в руку";
RussianAbilityDescriptions[AbilityNames.GROTTO] = "Сбросьте Argument0, затем тяните карты";
RussianAbilityDescriptions[AbilityNames.JEWELLED_EGG_GAIN_LOOT] = "Получите Грабленое";
RussianAbilityDescriptions[AbilityNames.SEARCH] = "Выкиньте [Search], затем получите Грабленое";
RussianAbilityDescriptions[AbilityNames.SHAMAN] = "Получите карту со Свалки не дороже 6";
RussianAbilityDescriptions[AbilityNames.SECLUDED_SHRINE] = "Выкиньте до 2 карт";
RussianAbilityDescriptions[AbilityNames.SIREN_GAIN] = "Выкиньте Действие, или [Siren]";
RussianAbilityDescriptions[AbilityNames.SIREN_DURATION] = "Тяните до восьми карт в руке";
RussianAbilityDescriptions[AbilityNames.STOWAWAY_DRAW] = "Тяните две карты";
RussianAbilityDescriptions[AbilityNames.STOWAWAY_REACTION] = "Сыграйте [Stowaway]";
RussianAbilityDescriptions[AbilityNames.TASKMASTER] = "+1 Действие, +1 Монеты";
RussianAbilityDescriptions[AbilityNames.ABUNDANCE] = "+1 Покупка, +3 Монеты";
RussianAbilityDescriptions[AbilityNames.CABIN_BOY] = "+2 Монеты, или выкиньте [Cabin Boy] чтобы получить Длительность";
RussianAbilityDescriptions[AbilityNames.FLAGSHIP_ACTIVATE] = "";
RussianAbilityDescriptions[AbilityNames.FLAGSHIP_REPLAY] = "Сыграйте Argument0 ещё раз";
RussianAbilityDescriptions[AbilityNames.GONDOLA_DURATION] = "+2 Монеты";
RussianAbilityDescriptions[AbilityNames.GONDOLA_PLAY] = "Сыграйте карту Действия";
RussianAbilityDescriptions[AbilityNames.HARBOR_VILLAGE] = "+1 Монета";
RussianAbilityDescriptions[AbilityNames.LANDING_PARTY] = "Положите [Landing Party] на верх колоды";
RussianAbilityDescriptions[AbilityNames.MAPMAKER] = "Сыграйте [Mapmaker]";
RussianAbilityDescriptions[AbilityNames.ROPE] = "+1 Карта, затем можете выкинуть карту";
RussianAbilityDescriptions[AbilityNames.BURIED_TREASURE_DURATION] = "+1 Покупка, +3 Монеты";
RussianAbilityDescriptions[AbilityNames.BURIED_TREASURE_PLAY] = "Сыграйте [Buried Treasure]";
RussianAbilityDescriptions[AbilityNames.CREW_DURATION] = "Положите [Crew] на верх колоды";
RussianAbilityDescriptions[AbilityNames.CUTTHROAT_GAIN] = "Получите Грабленое";
RussianAbilityDescriptions[AbilityNames.ENLARGE] = "Выкиньте карту, получите карту не больше чем на 2 дороже выкинутой";
RussianAbilityDescriptions[AbilityNames.FRIGATE_DURATION] = "";
RussianAbilityDescriptions[AbilityNames.FRIGATE_DISCARD] = "Сбросьте до четырёх карт в руке";
RussianAbilityDescriptions[AbilityNames.LONGSHIP_DURATION] = "+2 Карты";
RussianAbilityDescriptions[AbilityNames.MINING_ROAD] = "Сыграйте Argument0";
RussianAbilityDescriptions[AbilityNames.QUARTERMASTER] = "[Quartermaster]";
RussianAbilityDescriptions[AbilityNames.TRICKSTER_REACTION] = "Отложите Сокровище в сторону";
RussianAbilityDescriptions[AbilityNames.TRICKSTER_RETURN_TO_HAND] = "Положите Argument0 в руку";
RussianAbilityDescriptions[AbilityNames.WEALTHY_VILLAGE] = "Получите Грабленое";
RussianAbilityDescriptions[AbilityNames.DELIVER_SET_ASIDE] = "Отложите Argument0 в сторону";
RussianAbilityDescriptions[AbilityNames.DELIVER_RETURN] = "Положите Argument0 в руку";
RussianAbilityDescriptions[AbilityNames.RUSH] = "Сыграйте Argument0";
RussianAbilityDescriptions[AbilityNames.MIRROR] = "Получите ещё Argument0";
RussianAbilityDescriptions[AbilityNames.PREPARE] = "Сыграйте отложенную карту";
RussianAbilityDescriptions[AbilityNames.AMPHORA] = "+1 Покупка, +3 Монеты";
RussianAbilityDescriptions[AbilityNames.DOUBLOONS] = "Получите Золото";
RussianAbilityDescriptions[AbilityNames.ENDLESS_CHALICE] = "+1 Монета, +1 Покупка";
RussianAbilityDescriptions[AbilityNames.FIGUREHEAD] = "+2 Карты";
RussianAbilityDescriptions[AbilityNames.JEWELS] = "Положите [Jewels] под низ колоды";
RussianAbilityDescriptions[AbilityNames.PUZZLE_BOX_RETURN] = "Положите Argument0 в руку";
RussianAbilityDescriptions[AbilityNames.SHIELD_REACTION] = "Раскройте [Shield] чтобы защититься от атаки";
RussianAbilityDescriptions[AbilityNames.SPELL_SCROLL] = "Сыграйте Argument0";
RussianAbilityDescriptions[AbilityNames.CURSED_GAIN] = "Получите Грабленое и Проклятие";
RussianAbilityDescriptions[AbilityNames.FAWNING_GAIN] = "Получите [Fawning] карту";
RussianAbilityDescriptions[AbilityNames.SCHEDULER] = "[Friendly] (seeing this is a bug!)";
RussianAbilityDescriptions[AbilityNames.FRIENDLY_ABILITY] = "Сбросьте [Friendly] карту чтобы получить её копию";
RussianAbilityDescriptions[AbilityNames.HASTY_SET_ASIDE] = "Отложите Argument0 в сторону";
RussianAbilityDescriptions[AbilityNames.HASTY_PLAY] = "Сыграйте Argument0";
RussianAbilityDescriptions[AbilityNames.INHERITED] = "Замените стартовую карту";
RussianAbilityDescriptions[AbilityNames.INSPIRING] = "Сыграйте карту, которой нет у вас в игре";
RussianAbilityDescriptions[AbilityNames.NEARBY] = "+1 Покупка";
RussianAbilityDescriptions[AbilityNames.PATIENT_SCHEDULER] = "[Patient] (seeing this is a bug!)";
RussianAbilityDescriptions[AbilityNames.PATIENT_SET_ASIDE] = "Отложите [Patient] карты в сторону";
RussianAbilityDescriptions[AbilityNames.PATIENT_PLAY] = "Сыграйте Argument0";
RussianAbilityDescriptions[AbilityNames.PIOUS] = "Выкиньте карту";
RussianAbilityDescriptions[AbilityNames.RECKLESS_REPEAT] = "Сыграйте Argument0 снова";
RussianAbilityDescriptions[AbilityNames.RECKLESS_RETURN] = "Верните Argument0 в стопку";
RussianAbilityDescriptions[AbilityNames.RICH_GAIN] = "Получите [^nSilver]";
RussianAbilityDescriptions[AbilityNames.SHY] = "Сбросьте [Shy] карту за +2 Карты";
RussianAbilityDescriptions[AbilityNames.TIRELESS_SET_ASIDE] = "Отложите Argument0 в сторону";
RussianAbilityDescriptions[AbilityNames.TIRELESS_RETURN] = "Положите Argument0 на верх колоды";
RussianAbilityDescriptions[AbilityNames.STAR_CHART] = "Положите на верх колоды";
RussianAbilityDescriptions[AbilityNames.ORDER_OF_ASTROLOGERS] = "Положите на верх колоды";
RussianAbilityDescriptions[AbilityNames.ORDER_OF_MASONS] = "Сбросьте";
RussianAbilityDescriptions[AbilityNames.FATED] = "Положите на верх колоды и/или сбросьте";
RussianAbilityDescriptions[AbilityNames.AVOID] = "Сбросьте";
RussianAbilityDescriptions[AbilityNames.HERBALIST_ASK_FOR_DISCARD] = "Положите Сокровище на верх колоды";
RussianAbilityDescriptions[AbilityNames.SCHEME_ASK_FOR_DISCARD] = "Положите Действие на верх колоды";
RussianAbilityDescriptions[AbilityNames.TRICKSTER_ASK_FOR_DISCARD] = "Отложите Сокровище в сторону";
RussianAbilityDescriptions[AbilityNames.MARCHLAND] = "+1 Покупка, Сбросьте карты получая +$1 за каждую";
RussianAbilityDescriptions[AbilityNames.FARMHANDS_SET_ASIDE] = "Отложите Действие или Сокровище в сторону";
RussianAbilityDescriptions[AbilityNames.FARMHANDS_PLAY] = "Сыграйте Argument0";
RussianAbilityDescriptions[AbilityNames.JOUST_DISCARD] = "Сбросьте Провинцию";
RussianAbilityDescriptions[AbilityNames.INFIRMARY_OVERPAY] = "Переплатите за Лазарет";
RussianAbilityDescriptions[AbilityNames.RESOLVE_INFIRMARY_OVERPAY] = "Сыграйте Лазарет";
RussianAbilityDescriptions[AbilityNames.DRAW_FROM_FOOTPAD] = "Вытяните карту";
RussianAbilityDescriptions[AbilityNames.FARRIER_OVERPAY] = "Переплатить за +Карты в конце хода.";
RussianAbilityDescriptions[AbilityNames.RESOLVE_FARRIER_OVERPAY] = "Переплата за Скакуна";
RussianAbilityDescriptions[AbilityNames.DRAW_FROM_SQUIRREL] = "Вытяните две карты";
RussianAbilityDescriptions[AbilityNames.DRAW_FROM_RIVER] = "Вытяните одну карту";
RussianAbilityDescriptions[AbilityNames.DRAW_FROM_FARRIER] = "Вытяните Argument0 карт";
RussianAbilityDescriptions[AbilityNames.FERRYMAN] = "Получите Argument0";

RussianAbilityDescriptions[AbilityNames.BIDING_TIME_SET_ASIDE_HAND] = "Отложите карты из руки в сторону";
RussianAbilityDescriptions[AbilityNames.BIDING_TIME_RETURN_TO_HAND] = "Верните карты в руку";
RussianAbilityDescriptions[AbilityNames.ENLIGHTENMENT_CANTRIP] = "+1 Карта, +1 Действие";
RussianAbilityDescriptions[AbilityNames.ENLIGHTENMENT_REPLACE_ABILITY] = "Заменено Просвещением";
RussianAbilityDescriptions[AbilityNames.GOOD_HARVEST] = "+1 Покупка, +1 Монета";
RussianAbilityDescriptions[AbilityNames.HARSH_WINTER] = "Возьмите Долг или положите его на стопку";
RussianAbilityDescriptions[AbilityNames.KIND_EMPEROR] = "Получите Действие в руку";
RussianAbilityDescriptions[AbilityNames.PANIC_BUYS] = "+2 Покупки";
RussianAbilityDescriptions[AbilityNames.PANIC_RETURN] = "Верните Argument0 в стопку";
RussianAbilityDescriptions[AbilityNames.PROGRESS] = "Положите Argument0 н верх колоды";
RussianAbilityDescriptions[AbilityNames.RAPID_EXPANSION_SET_ASIDE] = "Отложите Argument0 в сторону";
RussianAbilityDescriptions[AbilityNames.RAPID_EXPANSION_PLAY_LATER] = "Сыграйте Argument0";
RussianAbilityDescriptions[AbilityNames.SICKNESS] = "Проклятие на верх колоды или сбросьте 3 карты";
RussianAbilityDescriptions[AbilityNames.RIVER_SHRINE] = "Получите карту до четырёх монет";
RussianAbilityDescriptions[AbilityNames.SAMURAI] = "+1 Монета";
RussianAbilityDescriptions[AbilityNames.DAIMYO_REPLAY] = "Сыграйте Argument0 снова";
RussianAbilityDescriptions[AbilityNames.FORESIGHT_RETURN_TO_HAND] = RussianAbilityDescriptions[AbilityNames.SAVE_RETURN_TO_HAND];