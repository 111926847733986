"use strict";

var CzechAutoPlays = {};

CzechAutoPlays[AutoPlays.MOAT] = ["Vypnuto", "Vždy odhalit"];
CzechAutoPlays[AutoPlays.TRADER] = ["Vypnuto", "Nereagovat na příjem Stříbrňáku"];
CzechAutoPlays[AutoPlays.CROWN] = ["Vypnuto", "Automaticky ukončit akce"];
CzechAutoPlays[AutoPlays.CHANGELING] = ["Vypnuto", "Chytré zeptání", "Nikdy neměnit"];
CzechAutoPlays[AutoPlays.URCHIN] = ["Vypnuto", "Nikdy nevyhazovat"];
CzechAutoPlays[AutoPlays.MOUNTEBANK] = ["Vypnuto", "Vždy odložit"];
CzechAutoPlays[AutoPlays.SETTLERS] = ["Vypnuto", "Vždy si vzít Měďák"];
CzechAutoPlays[AutoPlays.BUSTLING_VILLAGE] = ["Vypnuto", "Vždy si vzít Osadníky"];
CzechAutoPlays[AutoPlays.TOURNAMENT] = ["Vypnuto", "Vždy odhalit Provincii"];
CzechAutoPlays[AutoPlays.PEASANT] = ["Vypnuto", "Výchozí výměna", "Výchozí ponechání"];
CzechAutoPlays[AutoPlays.SOLDIER] = ["Vypnuto", "Výchozí výměna", "Výchozí ponechání"];
CzechAutoPlays[AutoPlays.FUGITIVE] = ["Vypnuto", "Výchozí výměna", "Výchozí ponechání"];
CzechAutoPlays[AutoPlays.DISCIPLE] = ["Vypnuto", "Výchozí výměna", "Výchozí ponechání"];
CzechAutoPlays[AutoPlays.PAGE] = ["Vypnuto", "Výchozí výměna", "Výchozí ponechání"];
CzechAutoPlays[AutoPlays.TREASURE_HUNTER] = ["Vypnuto", "Výchozí výměna", "Výchozí ponechání"];
CzechAutoPlays[AutoPlays.WARRIOR] = ["Vypnuto", "Výchozí výměna", "Výchozí ponechání"];
CzechAutoPlays[AutoPlays.HERO] = ["Vypnuto", "Výchozí výměna", "Výchozí ponechání"];
CzechAutoPlays[AutoPlays.FAITHFUL_HOUND] = ["Vypnuto", "Vždy dát na stranu"];
CzechAutoPlays[AutoPlays.YOUNG_WITCH] = ["Vypnuto", "Vždy odhalit Prokletou kartu"];
CzechAutoPlays[AutoPlays.TREASURY] = ["Vypnuto", "Vždy dát navrch"];
CzechAutoPlays[AutoPlays.ALCHEMIST] = ["Vypnuto", "Vždy dát navrch"];
CzechAutoPlays[AutoPlays.WALLED_VILLAGE] = ["Vypnuto", "Vždy dát navrch"];
CzechAutoPlays[AutoPlays.HERBALIST] = ["Vypnuto", "Jakékoliv pořadí"];
CzechAutoPlays[AutoPlays.ROYAL_SEAL] = ["Vypnuto", "Vždy dát navrch", "Nikdy nedávat navrch"];
CzechAutoPlays[AutoPlays.COIN_OF_THE_REALM] = ["Vypnuto", "Chytré zeptání"];
CzechAutoPlays[AutoPlays.BORDER_GUARD] = ["Vypnuto", "Vždy dát navrch"];
CzechAutoPlays[AutoPlays.PROVINCE] = ["Vypnuto", "Nechat špatné karty ve Vyhnanství"];
CzechAutoPlays[AutoPlays.CHAMPION] = ["Vypnuto", "Chytré rozhodnutí"];
CzechAutoPlays[AutoPlays.WAY_OF_THE_BUTTERFLY] = ["Vypnuto", "Chytré vrácení"];
CzechAutoPlays[AutoPlays.DESPERATION] = ["Vypnuto", "Vždy si vzít Kletbu"];
CzechAutoPlays[AutoPlays.TORTURER] = ["Vypnuto", "Neodkládat zbytečné"];
CzechAutoPlays[AutoPlays.ARENA] = ["Vypnuto", "Neodkládat zbytečné"];
CzechAutoPlays[AutoPlays.PAGEANT] = ["Vypnuto", "Vždy si vzít Měďáky"];
CzechAutoPlays[AutoPlays.OLD_WITCH] = ["Vypnuto", "Vždy vyhodit Kletbu"];
CzechAutoPlays[AutoPlays.SINISTER_PLOT] = ["Vypnuto", "Nikdy nebrat prázdné karty"];
CzechAutoPlays[AutoPlays.MONEYLENDER] = ["Vypnuto", "Vždy vyhodit Měďák"];