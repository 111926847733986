"use strict";

var FrenchCardNames = {};

FrenchCardNames[CardNames.BACK] = new TranslatedCardName("carte", "cartes", "une carte");
FrenchCardNames[CardNames.CURSE] = new TranslatedCardName("Malédiction", "Malédictions", "une Malédiction");
FrenchCardNames[CardNames.COPPER] = new TranslatedCardName("Cuivre", "Cuivres", "un Cuivre");
FrenchCardNames[CardNames.SILVER] = new TranslatedCardName("Argent", "Argents", "un Argent");
FrenchCardNames[CardNames.GOLD] = new TranslatedCardName("Or", "Ors", "un Or");
FrenchCardNames[CardNames.ESTATE] = new TranslatedCardName("Domaine", "Domaines", "un Domaine");
FrenchCardNames[CardNames.DUCHY] = new TranslatedCardName("Duché", "Duchés", "un Duché");
FrenchCardNames[CardNames.PROVINCE] = new TranslatedCardName("Province", "Provinces", "une Province");
FrenchCardNames[CardNames.ARTISAN] = new TranslatedCardName("Artisan", "Artisans", "un Artisan");
FrenchCardNames[CardNames.BANDIT] = new TranslatedCardName("Brigand", "Brigands", "un Brigand");
FrenchCardNames[CardNames.BUREAUCRAT] = new TranslatedCardName("Bureaucrate", "Bureaucrates", "un Bureaucrate");
FrenchCardNames[CardNames.CELLAR] = new TranslatedCardName("Cave", "Caves", "une Cave");
FrenchCardNames[CardNames.CHAPEL] = new TranslatedCardName("Chapelle", "Chapelles", "une Chapelle");
FrenchCardNames[CardNames.COUNCIL_ROOM] = new TranslatedCardName("Chambre du conseil", "Chambres du conseil", "une Chambre du conseil");
FrenchCardNames[CardNames.FESTIVAL] = new TranslatedCardName("Festival", "Festivals", "un Festival");
FrenchCardNames[CardNames.GARDENS] = new TranslatedCardName("Jardins", "Jardins", "des Jardins");
FrenchCardNames[CardNames.HARBINGER] = new TranslatedCardName("Présage", "Présages", "un Présage");
FrenchCardNames[CardNames.LABORATORY] = new TranslatedCardName("Laboratoire", "Laboratoires", "un Laboratoire");
FrenchCardNames[CardNames.LIBRARY] = new TranslatedCardName("Bibliothèque", "Bibliothèques", "une Bibliothèque");
FrenchCardNames[CardNames.MARKET] = new TranslatedCardName("Marché", "Marchés", "un Marché");
FrenchCardNames[CardNames.MERCHANT] = new TranslatedCardName("Marchand", "Marchands", "un Marchand");
FrenchCardNames[CardNames.MILITIA] = new TranslatedCardName("Milice", "Milices", "une Milice");
FrenchCardNames[CardNames.MINE] = new TranslatedCardName("Mine", "Mines", "une Mine");
FrenchCardNames[CardNames.MOAT] = new TranslatedCardName("Douves", "Douves", "des Douves");
FrenchCardNames[CardNames.MONEYLENDER] = new TranslatedCardName("Prêteur sur gages", "Prêteurs sur gages", "un Prêteur sur gages");
FrenchCardNames[CardNames.POACHER] = new TranslatedCardName("Braconnier", "Braconniers", "un Braconnier");
FrenchCardNames[CardNames.REMODEL] = new TranslatedCardName("Rénovation", "Rénovations", "une Rénovation");
FrenchCardNames[CardNames.SENTRY] = new TranslatedCardName("Sentinelle", "Sentinelles", "une Sentinelle");
FrenchCardNames[CardNames.SMITHY] = new TranslatedCardName("Forgeron", "Forgerons", "un Forgeron");
FrenchCardNames[CardNames.THRONE_ROOM] = new TranslatedCardName("Salle du trône", "Salles du trône", "une Salle du trône");
FrenchCardNames[CardNames.VASSAL] = new TranslatedCardName("Vassal", "Vassaux", "un Vassal");
FrenchCardNames[CardNames.VILLAGE] = new TranslatedCardName("Village", "Villages", "un Village");
FrenchCardNames[CardNames.WITCH] = new TranslatedCardName("Sorcière", "Sorcières", "une Sorcière");
FrenchCardNames[CardNames.WORKSHOP] = new TranslatedCardName("Atelier", "Ateliers", "un Atelier");

FrenchCardNames[CardNames.COURTYARD] = new TranslatedCardName("Cour", "Cours", "une Cour");
FrenchCardNames[CardNames.CONSPIRATOR] = new TranslatedCardName("Conspirateur", "Conspirateurs", "un Conspirateur");
FrenchCardNames[CardNames.COURTIER] = new TranslatedCardName("Courtisan", "Courtisans", "un Courtisan");
FrenchCardNames[CardNames.BARON] = new TranslatedCardName("Baron", "Barons", "un Baron");
FrenchCardNames[CardNames.BRIDGE] = new TranslatedCardName("Pont", "Ponts", "un Pont");
FrenchCardNames[CardNames.DIPLOMAT] = new TranslatedCardName("Diplomate", "Diplomates", "un Diplomate");
FrenchCardNames[CardNames.DUKE] = new TranslatedCardName("Duc", "Ducs", "un Duc");
FrenchCardNames[CardNames.HAREM] = new TranslatedCardName("Ferme", "Fermes", "une Ferme");
FrenchCardNames[CardNames.NOBLES] = new TranslatedCardName("Nobles", "Nobles", "des Nobles");
FrenchCardNames[CardNames.IRONWORKS] = new TranslatedCardName("Fonderie", "Fonderies", "une Fonderie");
FrenchCardNames[CardNames.LURKER] = new TranslatedCardName("Rôdeur", "Rôdeurs", "un Rôdeur");
FrenchCardNames[CardNames.MASQUERADE] = new TranslatedCardName("Mascarade", "Mascarades", "une Mascarade");
FrenchCardNames[CardNames.MILL] = new TranslatedCardName("Moulin", "Moulins", "un Moulin");
FrenchCardNames[CardNames.MINING_VILLAGE] = new TranslatedCardName("Village minier", "Villages miniers", "un Village minier");
FrenchCardNames[CardNames.MINION] = new TranslatedCardName("Larbin", "Larbins", "un Larbin");
FrenchCardNames[CardNames.PATROL] = new TranslatedCardName("Patrouille", "Patrouilles", "une Patrouille");
FrenchCardNames[CardNames.PAWN] = new TranslatedCardName("Pion", "Pions", "un Pion");
FrenchCardNames[CardNames.REPLACE] = new TranslatedCardName("Remplacement", "Remplacements", "un Remplacement");
FrenchCardNames[CardNames.SECRET_PASSAGE] = new TranslatedCardName("Passage secret", "Passages secrets", "un Passage secret");
FrenchCardNames[CardNames.SHANTY_TOWN] = new TranslatedCardName("Taudis", "Taudis", "un Taudis");
FrenchCardNames[CardNames.STEWARD] = new TranslatedCardName("Intendant", "Intendants", "un Intendant");
FrenchCardNames[CardNames.SWINDLER] = new TranslatedCardName("Escroc", "Escrocs", "un Escroc");
FrenchCardNames[CardNames.TORTURER] = new TranslatedCardName("Bourreau", "Bourreaux", "un Bourreau");
FrenchCardNames[CardNames.TRADING_POST] = new TranslatedCardName("Comptoir", "Comptoirs", "un Comptoir");
FrenchCardNames[CardNames.UPGRADE] = new TranslatedCardName("Mise à niveau", "Mises à niveau", "une Mise à niveau");
FrenchCardNames[CardNames.WISHING_WELL] = new TranslatedCardName("Puits aux souhaits", "Puits aux souhaits", "un Puits aux souhaits");

FrenchCardNames[CardNames.AMBASSADOR] = new TranslatedCardName("Ambassadeur", "Ambassadeurs", "un Ambassadeur");
FrenchCardNames[CardNames.BAZAAR] = new TranslatedCardName("Bazar", "Bazars", "un Bazar");
FrenchCardNames[CardNames.CARAVAN] = new TranslatedCardName("Caravane", "Caravanes", "une Caravane");
FrenchCardNames[CardNames.CUTPURSE] = new TranslatedCardName("Coupeur de bourse", "Coupeurs de bourse", "un Coupeur de bourse");
FrenchCardNames[CardNames.EMBARGO] = new TranslatedCardName("Embargo", "Embargos", "un Embargo");
FrenchCardNames[CardNames.EXPLORER] = new TranslatedCardName("Explorateur", "Explorateur", "un Explorateur");
FrenchCardNames[CardNames.FISHING_VILLAGE] = new TranslatedCardName("Village de pêcheurs", "Villages de pêcheurs", "un Village de pêcheurs");
FrenchCardNames[CardNames.GHOST_SHIP] = new TranslatedCardName("Vaisseau fantôme", "Vaisseaux fantômes", "un Vaisseau fantôme");
FrenchCardNames[CardNames.HAVEN] = new TranslatedCardName("Havre", "Havres", "un Havre");
FrenchCardNames[CardNames.ISLAND] = new TranslatedCardName("Île", "Îles", "une Île");
FrenchCardNames[CardNames.LIGHTHOUSE] = new TranslatedCardName("Phare", "Phares", "un Phare");
FrenchCardNames[CardNames.LOOKOUT] = new TranslatedCardName("Vigie", "Vigies", "une Vigie");
FrenchCardNames[CardNames.MERCHANT_SHIP] = new TranslatedCardName("Navire Marchand", "Navires Marchands", "un Navire Marchand");
FrenchCardNames[CardNames.NATIVE_VILLAGE] = new TranslatedCardName("Village indigène", "Villages indigènes", "un Village indigène");
FrenchCardNames[CardNames.NAVIGATOR] = new TranslatedCardName("Navigateur", "Navigateurs", "un Navigateur");
FrenchCardNames[CardNames.OUTPOST] = new TranslatedCardName("Avant-poste", "Avant-postes", "un Avant-poste");
FrenchCardNames[CardNames.PEARL_DIVER] = new TranslatedCardName("Plongeur de perles", "Plongeurs de perles", "un Plongeur de perles");
FrenchCardNames[CardNames.PIRATE_SHIP] = new TranslatedCardName("Bateau pirate", "Bateaux pirates", "un Bateau pirate");
FrenchCardNames[CardNames.SALVAGER] = new TranslatedCardName("Sauveteur", "Sauveteurs", "un Sauveteur");
FrenchCardNames[CardNames.SEA_HAG] = new TranslatedCardName("Sorcière de mer", "Sorcières de mer", "une Sorcière de mer");
FrenchCardNames[CardNames.SMUGGLERS] = new TranslatedCardName("Contrebandiers", "Contrebandiers", "des Contrebandiers");
FrenchCardNames[CardNames.TACTICIAN] = new TranslatedCardName("Tacticien", "Tacticiens", "un Tacticien");
FrenchCardNames[CardNames.TREASURE_MAP] = new TranslatedCardName("Carte aux trésors", "Cartes aux trésors", "une Carte aux trésors");
FrenchCardNames[CardNames.TREASURY] = new TranslatedCardName("Trésorerie", "Trésoreries", "une Trésorerie");
FrenchCardNames[CardNames.WAREHOUSE] = new TranslatedCardName("Entrepôt", "Entrepôts", "un Entrepôt");
FrenchCardNames[CardNames.WHARF] = new TranslatedCardName("Quai", "Quais", "un Quai");

FrenchCardNames[CardNames.ALCHEMIST] = new TranslatedCardName("Alchimiste", "Alchimistes", "un Alchimiste");
FrenchCardNames[CardNames.APOTHECARY] = new TranslatedCardName("Apothicaire", "Apothicaires", "un Apothicaire");
FrenchCardNames[CardNames.APPRENTICE] = new TranslatedCardName("Apprenti", "Apprentis", "un Apprenti");
FrenchCardNames[CardNames.FAMILIAR] = new TranslatedCardName("Familier", "Familiers", "un Familier");
FrenchCardNames[CardNames.GOLEM] = new TranslatedCardName("Golem", "Golems", "un Golem");
FrenchCardNames[CardNames.HERBALIST] = new TranslatedCardName("Herboriste", "Herboristes", "un Herboriste");
FrenchCardNames[CardNames.PHILOSOPHERS_STONE] = new TranslatedCardName("Pierre philosophale", "Pierres philosophales", "une Pierre philosophale");
FrenchCardNames[CardNames.POSSESSION] = new TranslatedCardName("Possession", "Possessions", "une Possession");
FrenchCardNames[CardNames.POTION] = new TranslatedCardName("Potion", "Potions", "une Potion");
FrenchCardNames[CardNames.SCRYING_POOL] = new TranslatedCardName("Bassin divinatoire", "Bassins divinatoires", "un Bassin divinatoire");
FrenchCardNames[CardNames.TRANSMUTE] = new TranslatedCardName("Transmutation", "Transmutations", "une Transmutation");
FrenchCardNames[CardNames.UNIVERSITY] = new TranslatedCardName("Université", "Universités", "une Université");
FrenchCardNames[CardNames.VINEYARD] = new TranslatedCardName("Vignoble", "Vignobles", "un Vignoble");

FrenchCardNames[CardNames.BANK] = new TranslatedCardName("Banque", "Banques", "une Banque");
FrenchCardNames[CardNames.BISHOP] = new TranslatedCardName("Évêque", "Évêques", "un Évêque");
FrenchCardNames[CardNames.COLONY] = new TranslatedCardName("Colonie", "Colonies", "une Colonie");
FrenchCardNames[CardNames.CONTRABAND] = new TranslatedCardName("Contrebande", "Contrebandes", "une Contrebande");
FrenchCardNames[CardNames.COUNTING_HOUSE] = new TranslatedCardName("Bureau de comptabilité", "Bureaux de comptabilité", "un Bureau de comptabilité");
FrenchCardNames[CardNames.CITY] = new TranslatedCardName("Ville", "Villes", "une Ville");
FrenchCardNames[CardNames.EXPAND] = new TranslatedCardName("Agrandissement", "Agrandissements", "un Agrandissement");
FrenchCardNames[CardNames.FORGE] = new TranslatedCardName("Forge", "Forges", "une Forge");
FrenchCardNames[CardNames.GRAND_MARKET] = new TranslatedCardName("Grand marché", "Grands marchés", "un Grand marché");
FrenchCardNames[CardNames.GOONS] = new TranslatedCardName("Fiers-à-bras", "Fiers-à-bras", "des Fiers-à-bras");
FrenchCardNames[CardNames.HOARD] = new TranslatedCardName("Magot", "Magots", "un Magot");
FrenchCardNames[CardNames.KINGS_COURT] = new TranslatedCardName("Cour du roi", "Cours du roi", "une Cour du roi");
FrenchCardNames[CardNames.LOAN] = new TranslatedCardName("Prêt", "Prêts", "un Prêt");
FrenchCardNames[CardNames.MINT] = new TranslatedCardName("Hôtel de la monnaie", "Hôtels de la monnaie", "un Hôtel de la monnaie");
FrenchCardNames[CardNames.MONUMENT] = new TranslatedCardName("Monument", "Monuments", "un Monument");
FrenchCardNames[CardNames.MOUNTEBANK] = new TranslatedCardName("Charlatan", "Charlatans", "un Charlatan");
FrenchCardNames[CardNames.PEDDLER] = new TranslatedCardName("Colporteur", "Colporteurs", "un Colporteur");
FrenchCardNames[CardNames.PLATINUM] = new TranslatedCardName("Platine", "Platines", "un Platine");
FrenchCardNames[CardNames.QUARRY] = new TranslatedCardName("Carrière", "Carrières", "une Carrière");
FrenchCardNames[CardNames.RABBLE] = new TranslatedCardName("Foule", "Foules", "une Foule");
FrenchCardNames[CardNames.ROYAL_SEAL] = new TranslatedCardName("Sceau royal", "Sceaux royaux", "un Sceau royal");
FrenchCardNames[CardNames.TALISMAN] = new TranslatedCardName("Talisman", "Talismans", "un Talisman");
FrenchCardNames[CardNames.TRADE_ROUTE] = new TranslatedCardName("Route commerciale", "Routes commerciales", "une Route commerciale");
FrenchCardNames[CardNames.VAULT] = new TranslatedCardName("Chambre forte", "Chambres fortes", "une Chambre forte");
FrenchCardNames[CardNames.VENTURE] = new TranslatedCardName("Entreprise risquée", "Entreprises risquées", "une Entreprise risquée");
FrenchCardNames[CardNames.WATCHTOWER] = new TranslatedCardName("Mirador", "Miradors", "un Mirador");
FrenchCardNames[CardNames.WORKERS_VILLAGE] = new TranslatedCardName("Village ouvrier", "Villages ouvriers", "un Village ouvrier");

FrenchCardNames[CardNames.BAG_OF_GOLD] = new TranslatedCardName("Sac d'Or", "Sacs d'Or", "un Sac d'Or");
FrenchCardNames[CardNames.DIADEM] = new TranslatedCardName("Diadème", "Diadèmes", "un Diadème");
FrenchCardNames[CardNames.FAIRGROUNDS] = new TranslatedCardName("Champ de foire", "Champs de foire", "un Champ de foire");
FrenchCardNames[CardNames.FARMING_VILLAGE] = new TranslatedCardName("Village agricole", "Villages agricoles", "un Village agricole");
FrenchCardNames[CardNames.FOLLOWERS] = new TranslatedCardName("Partisans", "Partisans", "des Partisans");
FrenchCardNames[CardNames.FORTUNE_TELLER] = new TranslatedCardName("Diseuse de bonne aventure", "Diseuses de bonne aventure", "une Diseuse de bonne aventure");
FrenchCardNames[CardNames.HAMLET] = new TranslatedCardName("Hameau", "Hameaux", "un Hameau");
FrenchCardNames[CardNames.HARVEST] = new TranslatedCardName("Récolte", "Récoltes", "une Récolte");
FrenchCardNames[CardNames.HORSE_TRADERS] = new TranslatedCardName("Maquignons", "Maquignons", "des Maquignons");
FrenchCardNames[CardNames.HORN_OF_PLENTY] = new TranslatedCardName("Corne d'abondance", "Cornes d'abondance", "une Corne d'abondance");
FrenchCardNames[CardNames.HUNTING_PARTY] = new TranslatedCardName("Chasseurs", "Chasseurs", "des Chasseurs");
FrenchCardNames[CardNames.JESTER] = new TranslatedCardName("Bouffon", "Bouffons", "un Bouffon");
FrenchCardNames[CardNames.MENAGERIE] = new TranslatedCardName("Ménagerie", "Ménageries", "une Ménagerie");
FrenchCardNames[CardNames.PRINCESS] = new TranslatedCardName("Princesse", "Princesses", "une Princesse");
FrenchCardNames[CardNames.REMAKE] = new TranslatedCardName("Renouvellement", "Renouvellements", "un Renouvellement");
FrenchCardNames[CardNames.TOURNAMENT] = new TranslatedCardName("Tournoi", "Tournois", "un Tournoi");
FrenchCardNames[CardNames.TRUSTY_STEED] = new TranslatedCardName("Fidèle destrier", "Fidèles destriers", "un Fidèle destrier");
FrenchCardNames[CardNames.YOUNG_WITCH] = new TranslatedCardName("Jeune Sorcière", "Jeunes Sorcières", "une Jeune Sorcière");

FrenchCardNames[CardNames.BORDER_VILLAGE] = new TranslatedCardName("Village frontalier", "Villages frontaliers", "un Village frontalier");
FrenchCardNames[CardNames.CACHE] = new TranslatedCardName("Cache", "Caches", "une Cache");
FrenchCardNames[CardNames.CARTOGRAPHER] = new TranslatedCardName("Cartographe", "Cartographes", "un Cartographe");
FrenchCardNames[CardNames.CROSSROADS] = new TranslatedCardName("Carrefour", "Carrefours", "un Carrefour");
FrenchCardNames[CardNames.DEVELOP] = new TranslatedCardName("Développement", "Développements", "un Développement");
FrenchCardNames[CardNames.DUCHESS] = new TranslatedCardName("Duchesse", "Duchesses", "une Duchesse");
FrenchCardNames[CardNames.EMBASSY] = new TranslatedCardName("Ambassade", "Ambassades", "une Ambassade");
FrenchCardNames[CardNames.FARMLAND] = new TranslatedCardName("Terre agricole", "Terres agricoles", "une Terre agricole");
FrenchCardNames[CardNames.FOOLS_GOLD] = new TranslatedCardName("Or des fous", "Ors des fous", "un Or des fous");
FrenchCardNames[CardNames.HAGGLER] = new TranslatedCardName("Marchandeur", "Marchandeurs", "un Marchandeur");
FrenchCardNames[CardNames.HIGHWAY] = new TranslatedCardName("Route", "Routes", "une Route");
FrenchCardNames[CardNames.ILL_GOTTEN_GAINS] = new TranslatedCardName("Argent noir", "Argents noirs", "un Argent noir");
FrenchCardNames[CardNames.INN] = new TranslatedCardName("Auberge", "Auberges", "une Auberge");
FrenchCardNames[CardNames.JACK_OF_ALL_TRADES] = new TranslatedCardName("Touche-à-tout", "Touche-à-tout", "un Touche-à-tout");
FrenchCardNames[CardNames.MANDARIN] = new TranslatedCardName("Mandarin", "Mandarins", "un Mandarin");
FrenchCardNames[CardNames.NOBLE_BRIGAND] = new TranslatedCardName("Noble Brigand", "Nobles Brigands", "un Noble Brigand");
FrenchCardNames[CardNames.NOMAD_CAMP] = new TranslatedCardName("Campement nomade", "Campements nomades", "un Campement nomade");
FrenchCardNames[CardNames.OASIS] = new TranslatedCardName("Oasis", "Oasis", "une Oasis");
FrenchCardNames[CardNames.ORACLE] = new TranslatedCardName("Oracle", "Oracles", "un Oracle");
FrenchCardNames[CardNames.MARGRAVE] = new TranslatedCardName("Margrave", "Margraves", "un Margrave");
FrenchCardNames[CardNames.SCHEME] = new TranslatedCardName("Complot", "Complots", "un Complot");
FrenchCardNames[CardNames.SILK_ROAD] = new TranslatedCardName("Route de la soie", "Routes de la soie", "une Route de la soie");
FrenchCardNames[CardNames.SPICE_MERCHANT] = new TranslatedCardName("Marchand d'épices", "Marchands d'épices", "un Marchand d'épices");
FrenchCardNames[CardNames.STABLES] = new TranslatedCardName("Écuries", "Écuries", "des Écuries");
FrenchCardNames[CardNames.TRADER] = new TranslatedCardName("Troqueuse", "Troqueuses", "une Troqueuse");
FrenchCardNames[CardNames.TUNNEL] = new TranslatedCardName("Tunnel", "Tunnels", "un Tunnel");

FrenchCardNames[CardNames.RUIN_PILE] = new TranslatedCardName("Ruines", "Ruines", "des Ruines");
FrenchCardNames[CardNames.KNIGHTS] = new TranslatedCardName("Chevaliers", "Chevaliers", "des Chevaliers");
FrenchCardNames[CardNames.ABANDONED_MINE] = new TranslatedCardName("Mine abandonnée", "Mines abandonnées", "une Mine abandonnée");
FrenchCardNames[CardNames.ALTAR] = new TranslatedCardName("Autel", "Autels", "un Autel");
FrenchCardNames[CardNames.ARMORY] = new TranslatedCardName("Armurerie", "Armureries", "une Armurerie");
FrenchCardNames[CardNames.BAND_OF_MISFITS] = new TranslatedCardName("Malfaiteurs", "Malfaiteurs", "des Malfaiteurs");
FrenchCardNames[CardNames.BANDIT_CAMP] = new TranslatedCardName("Camp de bandits", "Camps de bandits", "un Camp de bandits");
FrenchCardNames[CardNames.BEGGAR] = new TranslatedCardName("Mendiant", "Mendiants", "un Mendiant");
FrenchCardNames[CardNames.CATACOMBS] = new TranslatedCardName("Catacombes", "Catacombes", "des Catacombes");
FrenchCardNames[CardNames.COUNT] = new TranslatedCardName("Comte", "Comtes", "un Comte");
FrenchCardNames[CardNames.COUNTERFEIT] = new TranslatedCardName("Contrefaçon", "Contrefaçons", "une Contrefaçon");
FrenchCardNames[CardNames.CULTIST] = new TranslatedCardName("Cultiste", "Cultistes", "un Cultiste");
FrenchCardNames[CardNames.DAME_ANNA] = new TranslatedCardName("Dame Anna", "Dame Anna", "Dame Anna");
FrenchCardNames[CardNames.DAME_JOSEPHINE] = new TranslatedCardName("Dame Josephine", "Dame Josephine", "Dame Josephine");
FrenchCardNames[CardNames.DAME_MOLLY] = new TranslatedCardName("Dame Molly", "Dame Molly", "Dame Molly");
FrenchCardNames[CardNames.DAME_NATALIE] = new TranslatedCardName("Dame Natalie", "Dame Natalie", "Dame Natalie");
FrenchCardNames[CardNames.DAME_SYLVIA] = new TranslatedCardName("Dame Sylvia", "Dame Sylvia", "Dame Sylvia");
FrenchCardNames[CardNames.DEATH_CART] = new TranslatedCardName("Charrette de cadavres", "Charrettes de cadavres", "une Charrette de cadavres");
FrenchCardNames[CardNames.FEODUM] = new TranslatedCardName("Fief", "Fiefs", "un Fief");
FrenchCardNames[CardNames.FORAGER] = new TranslatedCardName("Cueilleur", "Cueilleurs", "un Cueilleur");
FrenchCardNames[CardNames.FORTRESS] = new TranslatedCardName("Forteresse", "Forteresses", "une Forteresse");
FrenchCardNames[CardNames.GRAVEROBBER] = new TranslatedCardName("Pilleur de tombes", "Pilleurs de tombes", "un Pilleur de tombes");
FrenchCardNames[CardNames.HERMIT] = new TranslatedCardName("Ermite", "Ermites", "un Ermite");
FrenchCardNames[CardNames.HOVEL] = new TranslatedCardName("Cabane", "Cabanes", "une Cabane");
FrenchCardNames[CardNames.HUNTING_GROUNDS] = new TranslatedCardName("Territoire de chasse", "Territoires de chasse", "un Territoire de chasse");
FrenchCardNames[CardNames.IRONMONGER] = new TranslatedCardName("Ferronnier", "Ferronniers", "un Ferronnier");
FrenchCardNames[CardNames.JUNK_DEALER] = new TranslatedCardName("Brocanteur", "Brocanteurs", "un Brocanteur");
FrenchCardNames[CardNames.MADMAN] = new TranslatedCardName("Fou", "Fous", "un Fou");
FrenchCardNames[CardNames.MARKET_SQUARE] = new TranslatedCardName("Place du marché", "Places du marché", "une Place du marché");
FrenchCardNames[CardNames.MARAUDER] = new TranslatedCardName("Maraudeur", "Maraudeurs", "un Maraudeur");
FrenchCardNames[CardNames.MERCENARY] = new TranslatedCardName("Mercenaire", "Mercenaires", "un Mercenaire");
FrenchCardNames[CardNames.MYSTIC] = new TranslatedCardName("Mystique", "Mystiques", "un Mystique");
FrenchCardNames[CardNames.NECROPOLIS] = new TranslatedCardName("Nécropole", "Nécropoles", "une Nécropole");
FrenchCardNames[CardNames.OVERGROWN_ESTATE] = new TranslatedCardName("Domaine luxuriant", "Domaines luxuriants", "un Domaine luxuriant");
FrenchCardNames[CardNames.PILLAGE] = new TranslatedCardName("Pillage", "Pillages", "un Pillage");
FrenchCardNames[CardNames.POOR_HOUSE] = new TranslatedCardName("Hospice", "Hospices", "un Hospice");
FrenchCardNames[CardNames.PROCESSION] = new TranslatedCardName("Procession", "Processions", "une Procession");
FrenchCardNames[CardNames.RATS] = new TranslatedCardName("Rats", "Rats", "des Rats");
FrenchCardNames[CardNames.REBUILD] = new TranslatedCardName("Reconstruction", "Reconstructions", "une Reconstruction");
FrenchCardNames[CardNames.ROGUE] = new TranslatedCardName("Bandit", "Bandits", "un Bandit");
FrenchCardNames[CardNames.RUINED_LIBRARY] = new TranslatedCardName("Bibliothèque en ruines", "Bibliothèques en ruines", "une Bibliothèque en ruines");
FrenchCardNames[CardNames.RUINED_MARKET] = new TranslatedCardName("Marché en ruines", "Marchés en ruines", "un Marché en ruines");
FrenchCardNames[CardNames.RUINED_VILLAGE] = new TranslatedCardName("Village en ruines", "Villages en ruines", "un Village en ruines");
FrenchCardNames[CardNames.SAGE] = new TranslatedCardName("Sage", "Sages", "un Sage");
FrenchCardNames[CardNames.SCAVENGER] = new TranslatedCardName("Pilleur", "Pilleurs", "un Pilleur");
FrenchCardNames[CardNames.SIR_BAILEY] = new TranslatedCardName("Sir Bailey", "Sir Bailey", "Sir Bailey");
FrenchCardNames[CardNames.SIR_DESTRY] = new TranslatedCardName("Sir Destry", "Sir Destry", "Sir Destry");
FrenchCardNames[CardNames.SIR_MARTIN] = new TranslatedCardName("Sir Martin", "Sir Martin", "Sir Martin");
FrenchCardNames[CardNames.SIR_MICHAEL] = new TranslatedCardName("Sir Michael", "Sir Michael", "Sir Michael");
FrenchCardNames[CardNames.SIR_VANDER] = new TranslatedCardName("Sir Vander", "Sir Vander", "Sir Vander");
FrenchCardNames[CardNames.SPOILS] = new TranslatedCardName("Butin", "Butins", "un Butin");
FrenchCardNames[CardNames.STOREROOM] = new TranslatedCardName("Salle d'entreposage", "Salles d'entreposage", "une Salle d'entreposage");
FrenchCardNames[CardNames.SQUIRE] = new TranslatedCardName("Écuyer", "Écuyers", "un Écuyer");
FrenchCardNames[CardNames.SURVIVORS] = new TranslatedCardName("Rescapés", "Rescapés", "des Rescapés");
FrenchCardNames[CardNames.URCHIN] = new TranslatedCardName("Orphelin", "Orphelins", "un Orphelin");
FrenchCardNames[CardNames.VAGRANT] = new TranslatedCardName("Vagabond", "Vagabonds", "un Vagabond");
FrenchCardNames[CardNames.WANDERING_MINSTREL] = new TranslatedCardName("Ménestrel errant", "Ménestrels errants", "un Ménestrel errant");

FrenchCardNames[CardNames.ADVISOR] = new TranslatedCardName("Conseiller", "Conseillers", "un Conseiller");
FrenchCardNames[CardNames.BAKER] = new TranslatedCardName("Boulanger", "Boulangers", "un Boulanger");
FrenchCardNames[CardNames.BUTCHER] = new TranslatedCardName("Boucher", "Bouchers", "un Boucher");
FrenchCardNames[CardNames.CANDLESTICK_MAKER] = new TranslatedCardName("Cirier", "Ciriers", "un Cirier");
FrenchCardNames[CardNames.DOCTOR] = new TranslatedCardName("Médecin", "Médecins", "un Médecin");
FrenchCardNames[CardNames.HERALD] = new TranslatedCardName("Héraut", "Hérauts", "un Héraut");
FrenchCardNames[CardNames.JOURNEYMAN] = new TranslatedCardName("Compagnon", "Compagnons", "un Compagnon");
FrenchCardNames[CardNames.MASTERPIECE] = new TranslatedCardName("Chef-d'œuvre", "Chefs-d'œuvre", "un Chef-d'œuvre");
FrenchCardNames[CardNames.MERCHANT_GUILD] = new TranslatedCardName("Guilde des marchands", "Guildes des marchands", "une Guilde des marchands");
FrenchCardNames[CardNames.PLAZA] = new TranslatedCardName("Place du village", "Places du village", "une Place du village");
FrenchCardNames[CardNames.TAXMAN] = new TranslatedCardName("Percepteur", "Percepteurs", "un Percepteur");
FrenchCardNames[CardNames.SOOTHSAYER] = new TranslatedCardName("Devin", "Devins", "un Devin");
FrenchCardNames[CardNames.STONEMASON] = new TranslatedCardName("Tailleur de pierre", "Tailleurs de pierre", "un Tailleur de pierre");

FrenchCardNames[CardNames.ALMS] = new TranslatedCardName("Aumône", "Aumônes", "une Aumône");
FrenchCardNames[CardNames.AMULET] = new TranslatedCardName("Amulette", "Amulettes", "une Amulette");
FrenchCardNames[CardNames.ARTIFICER] = new TranslatedCardName("Maître artisan", "Maîtres artisans", "un Maître artisan");
FrenchCardNames[CardNames.BALL] = new TranslatedCardName("Bal", "Bals", "un Bal");
FrenchCardNames[CardNames.BONFIRE] = new TranslatedCardName("Feu de joie", "Feux de joie", "un Feu de joie");
FrenchCardNames[CardNames.BORROW] = new TranslatedCardName("Emprunt", "Emprunts", "un Emprunt");
FrenchCardNames[CardNames.BRIDGE_TROLL] = new TranslatedCardName("Pont aux trolls", "Ponts aux trolls", "un Pont aux trolls");
FrenchCardNames[CardNames.CARAVAN_GUARD] = new TranslatedCardName("Escorte", "Escortes", "une Escorte");
FrenchCardNames[CardNames.CHAMPION] = new TranslatedCardName("Championne", "Championnes", "une Championne");
FrenchCardNames[CardNames.COIN_OF_THE_REALM] = new TranslatedCardName("Monnaie royale", "Monnaies royales", "une Monnaie royale");
FrenchCardNames[CardNames.DISCIPLE] = new TranslatedCardName("Disciple", "Disciples", "un Disciple");
FrenchCardNames[CardNames.DISTANT_LANDS] = new TranslatedCardName("Terres lointaines", "Terres lointaines", "des Terres lointaines");
FrenchCardNames[CardNames.DUNGEON] = new TranslatedCardName("Donjon", "Donjons", "un Donjon");
FrenchCardNames[CardNames.DUPLICATE] = new TranslatedCardName("Copie", "Copies", "une Copie");
FrenchCardNames[CardNames.EXPEDITION] = new TranslatedCardName("Expédition", "Expéditions", "une Expédition");
FrenchCardNames[CardNames.FERRY] = new TranslatedCardName("Bac", "Bacs", "un Bac");
FrenchCardNames[CardNames.FUGITIVE] = new TranslatedCardName("Fugitif", "Fugitifs", "un Fugitif");
FrenchCardNames[CardNames.GEAR] = new TranslatedCardName("Équipement", "Équipements", "un Équipement");
FrenchCardNames[CardNames.GIANT] = new TranslatedCardName("Géant", "Géants", "un Géant");
FrenchCardNames[CardNames.GUIDE] = new TranslatedCardName("Guide", "Guides", "un Guide");
FrenchCardNames[CardNames.HAUNTED_WOODS] = new TranslatedCardName("Bois hantés", "Bois hantés", "des Bois hantés");
FrenchCardNames[CardNames.HERO] = new TranslatedCardName("Héroïne", "Héroïnes", "une Héroïne");
FrenchCardNames[CardNames.HIRELING] = new TranslatedCardName("Recrue", "Recrues", "une Recrue");
FrenchCardNames[CardNames.INHERITANCE] = new TranslatedCardName("Héritage", "Héritages", "un Héritage");
FrenchCardNames[CardNames.LOST_ARTS] = new TranslatedCardName("Arts anciens", "Arts anciens", "des Arts anciens");
FrenchCardNames[CardNames.LOST_CITY] = new TranslatedCardName("Cité perdue", "Cités perdues", "une Cité perdue");
FrenchCardNames[CardNames.MAGPIE] = new TranslatedCardName("Pie voleuse", "Pies voleuses", "une Pie voleuse");
FrenchCardNames[CardNames.MESSENGER] = new TranslatedCardName("Courrier", "Courriers", "un Courrier");
FrenchCardNames[CardNames.MISER] = new TranslatedCardName("Miséreux", "Miséreux", "un Miséreux");
FrenchCardNames[CardNames.MISSION] = new TranslatedCardName("Mission", "Missions", "une Mission");
FrenchCardNames[CardNames.PATHFINDING] = new TranslatedCardName("Reconnaissance", "Reconnaissances", "une Reconnaissance");
FrenchCardNames[CardNames.PAGE] = new TranslatedCardName("Page", "Pages", "une Page");
FrenchCardNames[CardNames.PEASANT] = new TranslatedCardName("Paysan", "Paysans", "un Paysan");
FrenchCardNames[CardNames.PILGRIMAGE] = new TranslatedCardName("Pèlerinage", "Pèlerinages", "un Pèlerinage");
FrenchCardNames[CardNames.PLAN] = new TranslatedCardName("Plan de bataille", "Plans de bataille ", "un Plan de bataille");
FrenchCardNames[CardNames.PORT] = new TranslatedCardName("Ville portuaire", "Villes portuaires", "une Ville portuaire");
FrenchCardNames[CardNames.QUEST] = new TranslatedCardName("Quête", "Quêtes", "une Quête");
FrenchCardNames[CardNames.RANGER] = new TranslatedCardName("Forestier", "Forestiers", "un Forestier");
FrenchCardNames[CardNames.RAID] = new TranslatedCardName("Raid", "Raids", "un Raid");
FrenchCardNames[CardNames.RATCATCHER] = new TranslatedCardName("Chasseur de rats", "Chasseurs de rats", "un Chasseur de rats");
FrenchCardNames[CardNames.RAZE] = new TranslatedCardName("Démolition", "Démolitions", "une Démolition");
FrenchCardNames[CardNames.RELIC] = new TranslatedCardName("Relique", "Reliques", "une Relique");
FrenchCardNames[CardNames.ROYAL_CARRIAGE] = new TranslatedCardName("Cortège royal", "Cortèges royaux", "un Cortège royal");
FrenchCardNames[CardNames.SAVE] = new TranslatedCardName("Resserre", "Resserres", "une Resserre");
FrenchCardNames[CardNames.SCOUTING_PARTY] = new TranslatedCardName("Pistage", "Pistages", "un Pistage");
FrenchCardNames[CardNames.SEAWAY] = new TranslatedCardName("Route maritime", "Routes maritimes", "une Route maritime");
FrenchCardNames[CardNames.SOLDIER] = new TranslatedCardName("Soldat", "Soldats", "un Soldat");
FrenchCardNames[CardNames.STORYTELLER] = new TranslatedCardName("Conteuse", "Conteuses", "une Conteuse");
FrenchCardNames[CardNames.SWAMP_HAG] = new TranslatedCardName("Sorcière des marais", "Sorcières des marais", "une Sorcière des marais");
FrenchCardNames[CardNames.TEACHER] = new TranslatedCardName("Maître", "Maîtres", "un Maître");
FrenchCardNames[CardNames.TRAVELLING_FAIR] = new TranslatedCardName("Forains", "Forains", "des Forains");
FrenchCardNames[CardNames.TRADE] = new TranslatedCardName("Commerce", "Commerces", "un Commerce");
FrenchCardNames[CardNames.TRAINING] = new TranslatedCardName("Entraînement", "Entraînements", "un Entraînement");
FrenchCardNames[CardNames.TRANSMOGRIFY] = new TranslatedCardName("Transfiguration", "Transfigurations", "une Transfiguration");
FrenchCardNames[CardNames.TREASURE_TROVE] = new TranslatedCardName("Pierres précieuses", "Pierres précieuses", "des Pierres précieuses");
FrenchCardNames[CardNames.TREASURE_HUNTER] = new TranslatedCardName("Chasseuse de trésors", "Chasseuses de trésors", "une Chasseuse de trésor");
FrenchCardNames[CardNames.WARRIOR] = new TranslatedCardName("Guerrière", "Guerrières", "une Guerrière");
FrenchCardNames[CardNames.WINE_MERCHANT] = new TranslatedCardName("Marchand de vin", "Marchands de vin", "un Marchand de vin");

FrenchCardNames[CardNames.ENCAMPMENT] = new TranslatedCardName("Camp", "Camps", "un Camp");
FrenchCardNames[CardNames.PLUNDER] = new TranslatedCardName("Saccage", "Saccages", "un Saccage");
FrenchCardNames[CardNames.PATRICIAN] = new TranslatedCardName("Patricien", "Patriciens", "un Patricien");
FrenchCardNames[CardNames.EMPORIUM] = new TranslatedCardName("Emporium", "Emporiums", "un Emporium");
FrenchCardNames[CardNames.SETTLERS] = new TranslatedCardName("Colons", "Colons", "des Colons");
FrenchCardNames[CardNames.BUSTLING_VILLAGE] = new TranslatedCardName("Village en effervescence", "Villages en effervescence", "un Village en effervescence");
FrenchCardNames[CardNames.CATAPULT] = new TranslatedCardName("Catapulte", "Catapultes", "une Catapulte");
FrenchCardNames[CardNames.ROCKS] = new TranslatedCardName("Rocher", "Rochers", "un Rocher");
FrenchCardNames[CardNames.GLADIATOR] = new TranslatedCardName("Gladiateur", "Gladiateurs", "un Gladiateur");
FrenchCardNames[CardNames.FORTUNE] = new TranslatedCardName("Fortune", "Fortunes", "une Fortune");
FrenchCardNames[CardNames.CASTLES] = new TranslatedCardName("Châteaux", "Châteaux", "des Châteaux");
FrenchCardNames[CardNames.HUMBLE_CASTLE] = new TranslatedCardName("Château modeste", "Châteaux modestes", "un Château modeste");
FrenchCardNames[CardNames.CRUMBLING_CASTLE] = new TranslatedCardName("Château délabré", "Châteaux délabrés", "un Château délabré");
FrenchCardNames[CardNames.SMALL_CASTLE] = new TranslatedCardName("Petit château", "Petits châteaux", "un Petit château");
FrenchCardNames[CardNames.HAUNTED_CASTLE] = new TranslatedCardName("Château hanté", "Châteaux hantés", "un Château hanté");
FrenchCardNames[CardNames.OPULENT_CASTLE] = new TranslatedCardName("Somptueux château", "Somptueux châteaux", "un Somptueux château");
FrenchCardNames[CardNames.SPRAWLING_CASTLE] = new TranslatedCardName("Château prospère", "Châteaux prospères", "un Château prospère");
FrenchCardNames[CardNames.GRAND_CASTLE] = new TranslatedCardName("Grand Château", "Grands Châteaux", "un Grand Château");
FrenchCardNames[CardNames.KINGS_CASTLE] = new TranslatedCardName("Château royal", "Châteaux royaux", "un Château royal");
FrenchCardNames[CardNames.ADVANCE] = new TranslatedCardName("Adoubement", "Adoubements", "an Adoubement");
FrenchCardNames[CardNames.ANNEX] = new TranslatedCardName("Annexion", "Annexions", "une Annexion");
FrenchCardNames[CardNames.ARCHIVE] = new TranslatedCardName("Archives", "Archives", "des Archives");
FrenchCardNames[CardNames.AQUEDUCT] = new TranslatedCardName("Aqueduc", "Aqueducs", "un Aqueduc");
FrenchCardNames[CardNames.ARENA] = new TranslatedCardName("Arène", "Arènes", "une Arène");
FrenchCardNames[CardNames.BANDIT_FORT] = new TranslatedCardName("Fort des bandits", "Forts des bandits", "un Fort des Bandits");
FrenchCardNames[CardNames.BANQUET] = new TranslatedCardName("Banquet", "Banquets", "un Banquet");
FrenchCardNames[CardNames.BASILICA] = new TranslatedCardName("Basilique", "Basiliques", "une Basilique");
FrenchCardNames[CardNames.BATHS] = new TranslatedCardName("Bains", "Bains", "des Bains");
FrenchCardNames[CardNames.BATTLEFIELD] = new TranslatedCardName("Champ de bataille", "Champs de bataille", "un Champ de bataille");
FrenchCardNames[CardNames.CAPITAL] = new TranslatedCardName("Capital", "Capitaux", "un Capital");
FrenchCardNames[CardNames.CHARM] = new TranslatedCardName("Sort", "Sorts", "un Sort");
FrenchCardNames[CardNames.CHARIOT_RACE] = new TranslatedCardName("Course de chars", "Courses de chars", "une Course de chars");
FrenchCardNames[CardNames.CITY_QUARTER] = new TranslatedCardName("Faubourg", "Faubourgs", "un Faubourg");
FrenchCardNames[CardNames.COLONNADE] = new TranslatedCardName("Colonnade", "Colonnades", "une Colonnade");
FrenchCardNames[CardNames.CONQUEST] = new TranslatedCardName("Conquête", "Conquêtes", "une Conquête");
FrenchCardNames[CardNames.CROWN] = new TranslatedCardName("Couronne", "Couronnes", "une Couronne");
FrenchCardNames[CardNames.DELVE] = new TranslatedCardName("Trouée", "Trouées", "une Trouée");
FrenchCardNames[CardNames.DEFILED_SHRINE] = new TranslatedCardName("Profanation", "Profanations", "une Profanation");
FrenchCardNames[CardNames.DOMINATE] = new TranslatedCardName("Domination", "Dominations", "une Domination");
FrenchCardNames[CardNames.DONATE] = new TranslatedCardName("Donation", "Donation", "une Donation");
FrenchCardNames[CardNames.ENCHANTRESS] = new TranslatedCardName("Magicienne", "Magiciennes", "une Magicienne");
FrenchCardNames[CardNames.ENGINEER] = new TranslatedCardName("Ingénieur", "Ingénieurs", "une Ingénieur");
FrenchCardNames[CardNames.FARMERS_MARKET] = new TranslatedCardName("Marché agricole", "Marchés agricoles", "un Marché agricole");
FrenchCardNames[CardNames.FORUM] = new TranslatedCardName("Forum", "Forums", "un Forum");
FrenchCardNames[CardNames.FOUNTAIN] = new TranslatedCardName("Fontaine", "Fontaines", "une Fontaine");
FrenchCardNames[CardNames.GROUNDSKEEPER] = new TranslatedCardName("Jardinière", "Jardinières", "une Jardinière");
FrenchCardNames[CardNames.KEEP] = new TranslatedCardName("Fort", "Forts", "un Fort");
FrenchCardNames[CardNames.LABYRINTH] = new TranslatedCardName("Labyrinthe", "Labyrinthes", "un Labyrinthe");
FrenchCardNames[CardNames.LEGIONARY] = new TranslatedCardName("Légionnaire", "Légionnaires", "un Légionnaire");
FrenchCardNames[CardNames.MOUNTAIN_PASS] = new TranslatedCardName("Col", "Cols", "un Col");
FrenchCardNames[CardNames.MUSEUM] = new TranslatedCardName("Musée", "Musées", "un Musée");
FrenchCardNames[CardNames.OBELISK] = new TranslatedCardName("Obélisque", "Obélisques", "un Obélisque");
FrenchCardNames[CardNames.ORCHARD] = new TranslatedCardName("Verger", "Vergers", "un Verger");
FrenchCardNames[CardNames.OVERLORD] = new TranslatedCardName("Seigneur", "Seigneurs", "un Seigneur");
FrenchCardNames[CardNames.PALACE] = new TranslatedCardName("Palais", "Palais", "un Palais");
FrenchCardNames[CardNames.RITUAL] = new TranslatedCardName("Rituel", "Rituels", "un Rituel");
FrenchCardNames[CardNames.ROYAL_BLACKSMITH] = new TranslatedCardName("Forgeron royal", "Forgerons royaux", "un Forgeron royal");
FrenchCardNames[CardNames.SACRIFICE] = new TranslatedCardName("Sacrifice", "Sacrifices", "un Sacrifice");
FrenchCardNames[CardNames.SALT_THE_EARTH] = new TranslatedCardName("Épandage de sel", "Épandages de sel", "un Épandage de sel");
FrenchCardNames[CardNames.TAX] = new TranslatedCardName("Taxe", "Taxes", "une Taxe");
FrenchCardNames[CardNames.TEMPLE] = new TranslatedCardName("Temple", "Temples", "un Temple");
FrenchCardNames[CardNames.TOMB] = new TranslatedCardName("Tombe", "Tombes", "une Tombe");
FrenchCardNames[CardNames.TOWER] = new TranslatedCardName("Tour", "Tours", "une Tour");
FrenchCardNames[CardNames.TRIUMPH] = new TranslatedCardName("Triomphe", "Triomphes", "un Triomphe");
FrenchCardNames[CardNames.TRIUMPHAL_ARCH] = new TranslatedCardName("Arc de triomphe", "Arcs de triomphe", "un Arc de triomphe");
FrenchCardNames[CardNames.VILLA] = new TranslatedCardName("Villa", "Villas", "une Villa");
FrenchCardNames[CardNames.WALL] = new TranslatedCardName("Remparts", "Remparts", "des Remparts");
FrenchCardNames[CardNames.WOLF_DEN] = new TranslatedCardName("Tanière des loups", "Tanières des loups", "une Tanières des loups");
FrenchCardNames[CardNames.WEDDING] = new TranslatedCardName("Mariage", "Mariages", "un Mariage");
FrenchCardNames[CardNames.WILD_HUNT] = new TranslatedCardName("Chasse fantastique", "Chasses fantastiques", "une Chasse fantastique");
FrenchCardNames[CardNames.WINDFALL] = new TranslatedCardName("Manne", "Mannes", "une Manne");

FrenchCardNames[CardNames.BOON_DRAWPILE] = new TranslatedCardName("Aubaines", "Aubaines", "Aubaines");
FrenchCardNames[CardNames.BOON_DISCARDPILE] = new TranslatedCardName("Défausse des Aubaines", "Défausses des Aubaines", "la défausse des Aubaines");
FrenchCardNames[CardNames.THE_EARTHS_GIFT] = new TranslatedCardName("Don de la Terre", "Dons de la Terre", "un Don de la Terre");
FrenchCardNames[CardNames.THE_FIELDS_GIFT] = new TranslatedCardName("Don des Champs", "Dons des Champs", "un Don des Champs");
FrenchCardNames[CardNames.THE_FLAMES_GIFT] = new TranslatedCardName("Don des Flammes", "Dons des Flammes", "un Don des Flammes");
FrenchCardNames[CardNames.THE_FORESTS_GIFT] = new TranslatedCardName("Don des Forêts", "Dons des Forêts", "un Don des Forêts");
FrenchCardNames[CardNames.THE_MOONS_GIFT] = new TranslatedCardName("Don de la Lune", "Dons de la Lune", "un Don de la Lune");
FrenchCardNames[CardNames.THE_MOUNTAINS_GIFT] = new TranslatedCardName("Don des Montagnes", "Dons des Montagnes", "un Don des Montagnes");
FrenchCardNames[CardNames.THE_RIVERS_GIFT] = new TranslatedCardName("Don de la Rivière", "Dons de la Rivière", "un Don de la Rivière");
FrenchCardNames[CardNames.THE_SEAS_GIFT] = new TranslatedCardName("Don de la Mer", "Dons de la Mer", "un Don de la Mer");
FrenchCardNames[CardNames.THE_SKYS_GIFT] = new TranslatedCardName("Don du Ciel", "Dons du Ciel", "un Don du Ciel");
FrenchCardNames[CardNames.THE_SUNS_GIFT] = new TranslatedCardName("Don du Soleil", "Dons du Soleil", "un Don du Soleil");
FrenchCardNames[CardNames.THE_SWAMPS_GIFT] = new TranslatedCardName("Don des Marais", "Dons des Marais", "un Don des Marais");
FrenchCardNames[CardNames.THE_WINDS_GIFT] = new TranslatedCardName("Don du Vent", "Dons du Vent", "un Don du Vent");
FrenchCardNames[CardNames.HEX_DRAWPILE] = new TranslatedCardName("Sortilèges", "Sortilèges", "Sortilèges");
FrenchCardNames[CardNames.HEX_DISCARDPILE] = new TranslatedCardName("Défausse des Sortilèges", "Défausses des Sortilèges", "la Défausse des Sortilèges");
FrenchCardNames[CardNames.BAD_OMENS] = new TranslatedCardName("Mauvais augure", "Mauvais augures", "un Mauvais augure");
FrenchCardNames[CardNames.DELUSION] = new TranslatedCardName("Envoûtement", "Envoûtements", "un Envoûtement");
FrenchCardNames[CardNames.ENVY] = new TranslatedCardName("Jalousie", "Jalousies", "une Jalousie");
FrenchCardNames[CardNames.FAMINE] = new TranslatedCardName("Famine", "Famines", "une Famine");
FrenchCardNames[CardNames.FEAR] = new TranslatedCardName("Peur", "Peurs", "une Peur");
FrenchCardNames[CardNames.GREED] = new TranslatedCardName("Avidité", "Avidités", "une Avidité");
FrenchCardNames[CardNames.HAUNTING] = new TranslatedCardName("Obsession", "Obsessions", "une Obsession");
FrenchCardNames[CardNames.LOCUSTS] = new TranslatedCardName("Sauterelles", "Sauterelles", "des Sauterelles");
FrenchCardNames[CardNames.MISERY] = new TranslatedCardName("Détresse", "Détresses", "une Détresse");
FrenchCardNames[CardNames.PLAGUE] = new TranslatedCardName("Peste", "Pestes", "une Peste");
FrenchCardNames[CardNames.POVERTY] = new TranslatedCardName("Pauvreté", "Pauvretés", "une Pauvreté");
FrenchCardNames[CardNames.WAR] = new TranslatedCardName("Guerre", "Guerres", "une Guerre");
FrenchCardNames[CardNames.MISERABLE] = new TranslatedCardName("En détresse", "En détresse", "En détresse");
FrenchCardNames[CardNames.TWICE_MISERABLE] = new TranslatedCardName("En grande détresse", "En grande détresse", "En grande détresse");
FrenchCardNames[CardNames.ENVIOUS] = new TranslatedCardName("Jaloux", "Jaloux", "Jaloux");
FrenchCardNames[CardNames.DELUDED] = new TranslatedCardName("Envoûté", "Envoûté", "Envoûté");
FrenchCardNames[CardNames.LOST_IN_THE_WOODS] = new TranslatedCardName("Perdu dans les bois", "Perdu dans les bois", "Perdu dans les bois");
FrenchCardNames[CardNames.BARD] = new TranslatedCardName("Barde", "Bardes", "une Barde");
FrenchCardNames[CardNames.BLESSED_VILLAGE] = new TranslatedCardName("Village béni", "Villages bénis", "un Village béni");
FrenchCardNames[CardNames.CHANGELING] = new TranslatedCardName("Changelin", "Changelins", "un Changelin");
FrenchCardNames[CardNames.CEMETERY] = new TranslatedCardName("Cimetière", "Cimetières", "un Cimetière");
FrenchCardNames[CardNames.COBBLER] = new TranslatedCardName("Cordonnier", "Cordonniers", "un Cordonnier");
FrenchCardNames[CardNames.CONCLAVE] = new TranslatedCardName("Conclave", "Conclaves", "un Conclave");
FrenchCardNames[CardNames.CRYPT] = new TranslatedCardName("Crypte", "Cryptes", "une Crypte");
FrenchCardNames[CardNames.CURSED_VILLAGE] = new TranslatedCardName("Village maudit", "Villages maudits", "un Village maudit");
FrenchCardNames[CardNames.DEN_OF_SIN] = new TranslatedCardName("Antre du péché", "Antres du péché", "une Antre du péché");
FrenchCardNames[CardNames.DEVILS_WORKSHOP] = new TranslatedCardName("Atelier du diable", "Ateliers du diable", "un Atelier du diable");
FrenchCardNames[CardNames.DRUID] = new TranslatedCardName("Druide", "Druides", "un Druide");
FrenchCardNames[CardNames.EXORCIST] = new TranslatedCardName("Exorciste", "Exorcistes", "une Exorciste");
FrenchCardNames[CardNames.FAITHFUL_HOUND] = new TranslatedCardName("Chien fidèle", "Chiens fidèles", "un Chien fidèle");
FrenchCardNames[CardNames.FOOL] = new TranslatedCardName("Benêt", "Benêts", "un Benêt");
FrenchCardNames[CardNames.GHOST_TOWN] = new TranslatedCardName("Ville fantôme", "Villes fantômes", "une Ville fantôme");
FrenchCardNames[CardNames.GUARDIAN] = new TranslatedCardName("Gardienne", "Gardiennes", "une Gardienne");
FrenchCardNames[CardNames.IDOL] = new TranslatedCardName("Idole", "Idoles", "une Idole");
FrenchCardNames[CardNames.LEPRECHAUN] = new TranslatedCardName("Leprechaun", "Leprechauns", "un Leprechaun");
FrenchCardNames[CardNames.MONASTERY] = new TranslatedCardName("Monastère", "Monastères", "un Monastère");
FrenchCardNames[CardNames.NECROMANCER] = new TranslatedCardName("Nécromancien", "Nécromanciens", "un Nécromancien");
FrenchCardNames[CardNames.NIGHT_WATCHMAN] = new TranslatedCardName("Veilleur de nuit", "Veilleurs de nuit", "un Veilleur de nuit");
FrenchCardNames[CardNames.PIXIE] = new TranslatedCardName("Pixie", "Pixies", "une Pixie");
FrenchCardNames[CardNames.POOKA] = new TranslatedCardName("Pooka", "Pookas", "un Pooka");
FrenchCardNames[CardNames.RAIDER] = new TranslatedCardName("Razzia", "Razzias", "une Razzia");
FrenchCardNames[CardNames.SACRED_GROVE] = new TranslatedCardName("Bois sacré", "Bois sacrés", "un Bois sacré");
FrenchCardNames[CardNames.SECRET_CAVE] = new TranslatedCardName("Grotte secrète", "Grottes secrètes", "une Grotte secrète");
FrenchCardNames[CardNames.SHEPHERD] = new TranslatedCardName("Berger", "Bergers", "un Berger");
FrenchCardNames[CardNames.SKULK] = new TranslatedCardName("Malandrin", "Malandrins", "un Malandrin");
FrenchCardNames[CardNames.TORMENTOR] = new TranslatedCardName("Persécuteur", "Persécuteurs", "un Persécuteur");
FrenchCardNames[CardNames.TRAGIC_HERO] = new TranslatedCardName("Héros de tragédie", "Héros de tragédie", "un Héros de tragédie");
FrenchCardNames[CardNames.TRACKER] = new TranslatedCardName("Traqueur", "Traqueurs", "un Traqueur");
FrenchCardNames[CardNames.VAMPIRE] = new TranslatedCardName("Vampire", "Vampires", "une Vampire");
FrenchCardNames[CardNames.WEREWOLF] = new TranslatedCardName("Loup-garou", "Loups-garous", "un Loup-Garou");
FrenchCardNames[CardNames.CURSED_GOLD] = new TranslatedCardName("Or maudit", "Ors maudits", "un Or maudit");
FrenchCardNames[CardNames.GOAT] = new TranslatedCardName("Chèvre", "Chèvres", "une Chèvre");
FrenchCardNames[CardNames.HAUNTED_MIRROR] = new TranslatedCardName("Miroir hanté", "Miroirs hantés", "un Miroir hanté");
FrenchCardNames[CardNames.LUCKY_COIN] = new TranslatedCardName("Porte-bonheur", "Porte-bonheur", "un Porte-bonheur");
FrenchCardNames[CardNames.MAGIC_LAMP] = new TranslatedCardName("Lampe magique", "Lampes magiques", "une Lampe magique");
FrenchCardNames[CardNames.PASTURE] = new TranslatedCardName("Pâturage", "Pâturages", "un Pâturage");
FrenchCardNames[CardNames.POUCH] = new TranslatedCardName("Pochette", "Pochettes", "une Pochette");
FrenchCardNames[CardNames.BAT] = new TranslatedCardName("Chauve-souris", "Chauve-souris", "une Chauve-souris");
FrenchCardNames[CardNames.GHOST] = new TranslatedCardName("Fantôme", "Fantômes", "une Fantôme");
FrenchCardNames[CardNames.IMP] = new TranslatedCardName("Farfadet", "Farfadets", "un Farfadet");
FrenchCardNames[CardNames.WILL_O_WISP] = new TranslatedCardName("Feu follet", "Feux follets", "un Feu follet");
FrenchCardNames[CardNames.WISH] = new TranslatedCardName("Vœu", "Vœux", "un Vœu");
FrenchCardNames[CardNames.ZOMBIE_APPRENTICE] = new TranslatedCardName("Zombie Apprenti", "Zombies Apprentis", "un Zombie Apprenti");
FrenchCardNames[CardNames.ZOMBIE_MASON] = new TranslatedCardName("Zombie Maçon", "Zombies Maçons", "un Zombie Maçon");
FrenchCardNames[CardNames.ZOMBIE_SPY] = new TranslatedCardName("Zombie Espion", "Zombies Espions", "un Zombie Espion");

FrenchCardNames[CardNames.ACTING_TROUPE] = new TranslatedCardName("Troupe de théâtre", "Troupes de théâtre", "une Troupe de théâtre");
FrenchCardNames[CardNames.BORDER_GUARD] = new TranslatedCardName("Garde-frontière", "Gardes-frontières", "une Garde-frontière");
FrenchCardNames[CardNames.CARGO_SHIP] = new TranslatedCardName("Cargo", "Cargos", "un Cargo");
FrenchCardNames[CardNames.DUCAT] = new TranslatedCardName("Ducat", "Ducats", "un Ducat");
FrenchCardNames[CardNames.EXPERIMENT] = new TranslatedCardName("Expérience", "Expériences", "une Expérience");
FrenchCardNames[CardNames.FLAG_BEARER] = new TranslatedCardName("Porte-drapeau", "Porte-drapeaux", "un Porte-drapeau");
FrenchCardNames[CardNames.HIDEOUT] = new TranslatedCardName("Planque", "Planques", "une Planque");
FrenchCardNames[CardNames.INVENTOR] = new TranslatedCardName("Inventeur", "Inventeurs", "un Inventeur");
FrenchCardNames[CardNames.IMPROVE] = new TranslatedCardName("Amélioration", "Améliorations", "une Amélioration");
FrenchCardNames[CardNames.LACKEYS] = new TranslatedCardName("Laquais", "Laquais", "des Laquais");
FrenchCardNames[CardNames.MOUNTAIN_VILLAGE] = new TranslatedCardName("Village de montagne", "Villages de montagne", "un Village de montagne");
FrenchCardNames[CardNames.PATRON] = new TranslatedCardName("Mécène", "Mécènes", "un Mécène");
FrenchCardNames[CardNames.PRIEST] = new TranslatedCardName("Prêtre", "Prêtres", "un Prêtre");
FrenchCardNames[CardNames.RESEARCH] = new TranslatedCardName("Recherche", "Recherches", "une Recherche");
FrenchCardNames[CardNames.SILK_MERCHANT] = new TranslatedCardName("Marchande de soie", "Marchandes de soie", "une Marchande de soie");
FrenchCardNames[CardNames.OLD_WITCH] = new TranslatedCardName("Vieille sorcière", "Vieilles sorcières", "une Vieille sorcière");
FrenchCardNames[CardNames.RECRUITER] = new TranslatedCardName("Recruteur", "Recruteurs", "un Recruteur");
FrenchCardNames[CardNames.SCEPTER] = new TranslatedCardName("Sceptre", "Sceptres", "un Sceptre");
FrenchCardNames[CardNames.SCHOLAR] = new TranslatedCardName("Érudite", "Érudites", "une Érudite");
FrenchCardNames[CardNames.SCULPTOR] = new TranslatedCardName("Sculptrice", "Sculptrices", "une Sculptrice");
FrenchCardNames[CardNames.SEER] = new TranslatedCardName("Prophète", "Prophètes", "un Prophète");
FrenchCardNames[CardNames.SPICES] = new TranslatedCardName("Épices", "Épices", "des Épices");
FrenchCardNames[CardNames.SWASHBUCKLER] = new TranslatedCardName("Bretteuse", "Bretteuses", "une Bretteuse");
FrenchCardNames[CardNames.TREASURER] = new TranslatedCardName("Trésorière", "Trésorières", "une Trésorière");
FrenchCardNames[CardNames.VILLAIN] = new TranslatedCardName("Scélérat", "Scélérats", "un Scélérat");
FrenchCardNames[CardNames.FLAG] = new TranslatedCardName("Drapeau");
FrenchCardNames[CardNames.HORN] = new TranslatedCardName("Corne");
FrenchCardNames[CardNames.KEY] = new TranslatedCardName("Clé");
FrenchCardNames[CardNames.LANTERN] = new TranslatedCardName("Lanterne");
FrenchCardNames[CardNames.TREASURE_CHEST] = new TranslatedCardName("Coffre au trésor");
FrenchCardNames[CardNames.ACADEMY] = new TranslatedCardName("Académie");
FrenchCardNames[CardNames.BARRACKS] = new TranslatedCardName("Caserne");
FrenchCardNames[CardNames.CANAL] = new TranslatedCardName("Canal");
FrenchCardNames[CardNames.CAPITALISM] = new TranslatedCardName("Capitalisme");
FrenchCardNames[CardNames.CATHEDRAL] = new TranslatedCardName("Cathédrale");
FrenchCardNames[CardNames.CITADEL] = new TranslatedCardName("Citadelle");
FrenchCardNames[CardNames.CITY_GATE] = new TranslatedCardName("Porte");
FrenchCardNames[CardNames.CROP_ROTATION] = new TranslatedCardName("Assolement");
FrenchCardNames[CardNames.EXPLORATION] = new TranslatedCardName("Exploration");
FrenchCardNames[CardNames.FAIR] = new TranslatedCardName("Foire");
FrenchCardNames[CardNames.FLEET] = new TranslatedCardName("Flotte");
FrenchCardNames[CardNames.GUILDHALL] = new TranslatedCardName("Hôtel de Ville");
FrenchCardNames[CardNames.INNOVATION] = new TranslatedCardName("Innovation");
FrenchCardNames[CardNames.PAGEANT] = new TranslatedCardName("Spectacle");
FrenchCardNames[CardNames.PIAZZA] = new TranslatedCardName("Piazza");
FrenchCardNames[CardNames.ROAD_NETWORK] = new TranslatedCardName("Réseau routier");
FrenchCardNames[CardNames.SEWERS] = new TranslatedCardName("Égouts");
FrenchCardNames[CardNames.SILOS] = new TranslatedCardName("Silos");
FrenchCardNames[CardNames.SINISTER_PLOT] = new TranslatedCardName("Machination");
FrenchCardNames[CardNames.STAR_CHART] = new TranslatedCardName("Carte céleste");

FrenchCardNames[CardNames.AVANTO] = new TranslatedCardName("Trou dans la glace", "Trous dans la glace", "un Trou dans la glace");
FrenchCardNames[CardNames.BLACK_MARKET] = new TranslatedCardName("Marché noir", "Marchés noirs", "un Marché noir");
FrenchCardNames[CardNames.ENVOY] = new TranslatedCardName("Délégué", "Délégués", "un Délégué");
FrenchCardNames[CardNames.GOVERNOR] = new TranslatedCardName("Gouverneur", "Gouverneurs", "un Gouverneur");
FrenchCardNames[CardNames.PRINCE] = new TranslatedCardName("Prince", "Princes", "un Prince");
FrenchCardNames[CardNames.SAUNA] = new TranslatedCardName("Sauna", "Saunas", "un Sauna");
FrenchCardNames[CardNames.STASH] = new TranslatedCardName("Cachette", "Cachettes", "une Cachette");
FrenchCardNames[CardNames.SUMMON] = new TranslatedCardName("Convocation", "Convocation", "une Convocation");
FrenchCardNames[CardNames.WALLED_VILLAGE] = new TranslatedCardName("Ville fortifiée", "Villes fortifiées", "une Ville fortifiée");
FrenchCardNames[CardNames.BLACK_MARKET_PILE] = new TranslatedCardName("Deck Marché noir", "Decks Marché noir", "un Deck Marché noir");
FrenchCardNames[CardNames.DISMANTLE] = new TranslatedCardName("Démantèlement", "Démantèlements", "un Démantèlement");
FrenchCardNames[CardNames.CHURCH] = new TranslatedCardName("Église", "Églises", "une Église");
FrenchCardNames[CardNames.CAPTAIN] = new TranslatedCardName("Capitaine", "Capitaines", "un Capitaine");

FrenchCardNames[CardNames.BLACK_CAT] = new TranslatedCardName("Chat noir", "Chats noirs", "un Chat noir");
FrenchCardNames[CardNames.SLEIGH] = new TranslatedCardName("Traîneau", "Traîneaux", "un Traîneau");
FrenchCardNames[CardNames.SUPPLIES] = new TranslatedCardName("Réserves", "Réserves", "des Réserves");
FrenchCardNames[CardNames.CAMEL_TRAIN] = new TranslatedCardName("Caravane de chameaux", "Caravanes de chameaux", "une Caravane de chameaux");
FrenchCardNames[CardNames.GOATHERD] = new TranslatedCardName("Chevrière", "Chevrières", "une Chevrière");
FrenchCardNames[CardNames.SCRAP] = new TranslatedCardName("Ferraille", "Ferrailles", "une Ferraille");
FrenchCardNames[CardNames.SHEEPDOG] = new TranslatedCardName("Chien de berger", "Chiens de berger", "un Chien de berger");
FrenchCardNames[CardNames.SNOWY_VILLAGE] = new TranslatedCardName("Village enneigé", "Villages enneigés", "un Village enneigé");
FrenchCardNames[CardNames.STOCKPILE] = new TranslatedCardName("Stocks", "Stocks", "des Stocks");
FrenchCardNames[CardNames.BOUNTY_HUNTER] = new TranslatedCardName("Chasseur de primes", "Chasseurs de primes", "un Chasseur de primes");
FrenchCardNames[CardNames.CARDINAL] = new TranslatedCardName("Cardinal", "Cardinaux", "un Cardinal");
FrenchCardNames[CardNames.CAVALRY] = new TranslatedCardName("Cavalerie", "Cavaleries", "une Cavalerie");
FrenchCardNames[CardNames.GROOM] = new TranslatedCardName("Palefrenier", "Palefreniers", "un Palefrenier");
FrenchCardNames[CardNames.HOSTELRY] = new TranslatedCardName("Hôtel", "Hôtels", "un Hôtel");
FrenchCardNames[CardNames.VILLAGE_GREEN] = new TranslatedCardName("Espace vert", "Espaces verts", "un Espace vert");
FrenchCardNames[CardNames.BARGE] = new TranslatedCardName("Chaland", "Chalands", "un Chaland");
FrenchCardNames[CardNames.COVEN] = new TranslatedCardName("Clan de sorcières", "Clans de sorcières", "un Clan de sorcières");
FrenchCardNames[CardNames.DISPLACE] = new TranslatedCardName("Déplacement", "Déplacements", "un Déplacement");
FrenchCardNames[CardNames.FALCONER] = new TranslatedCardName("Fauconnière", "Fauconnières", "une Fauconnière");
FrenchCardNames[CardNames.FISHERMAN] = new TranslatedCardName("Pêcheur", "Pêcheurs", "un Pêcheur");
FrenchCardNames[CardNames.GATEKEEPER] = new TranslatedCardName("Garde", "Gardes", "une Garde");
FrenchCardNames[CardNames.HUNTING_LODGE] = new TranslatedCardName("Pavillon de chasse", "Pavillons de chasse", "un Pavillon de chasse");
FrenchCardNames[CardNames.KILN] = new TranslatedCardName("Four", "Fours", "un Four");
FrenchCardNames[CardNames.LIVERY] = new TranslatedCardName("Pension", "Pensions", "une Pension");
FrenchCardNames[CardNames.MASTERMIND] = new TranslatedCardName("Génie", "Génies", "un Génie");
FrenchCardNames[CardNames.PADDOCK] = new TranslatedCardName("Enclos", "Enclos", "un Enclos");
FrenchCardNames[CardNames.SANCTUARY] = new TranslatedCardName("Sanctuaire", "Sanctuaires", "un Sanctuaire");
FrenchCardNames[CardNames.DESTRIER] = new TranslatedCardName("Destrier", "Destriers", "un Destrier");
FrenchCardNames[CardNames.WAYFARER] = new TranslatedCardName("Voyageuse", "Voyageuse", "une Voyageuse");
FrenchCardNames[CardNames.ANIMAL_FAIR] = new TranslatedCardName("Foire aux bestiaux", "Foires aux bestiaux", "une Foire aux bestiaux");
FrenchCardNames[CardNames.HORSE] = new TranslatedCardName("Cheval", "Chevaux", "un Cheval");
FrenchCardNames[CardNames.WAY_OF_THE_BUTTERFLY] = new TranslatedCardName("Voie du papillon");
FrenchCardNames[CardNames.WAY_OF_THE_CAMEL] = new TranslatedCardName("Voie du chameau");
FrenchCardNames[CardNames.WAY_OF_THE_CHAMELEON] = new TranslatedCardName("Voie du caméléon");
FrenchCardNames[CardNames.WAY_OF_THE_FROG] = new TranslatedCardName("Voie de la grenouille");
FrenchCardNames[CardNames.WAY_OF_THE_GOAT] = new TranslatedCardName("Voie de la chèvre");
FrenchCardNames[CardNames.WAY_OF_THE_HORSE] = new TranslatedCardName("Voie du cheval");
FrenchCardNames[CardNames.WAY_OF_THE_MOLE] = new TranslatedCardName("Voie de la taupe");
FrenchCardNames[CardNames.WAY_OF_THE_MONKEY] = new TranslatedCardName("Voie du singe");
FrenchCardNames[CardNames.WAY_OF_THE_MOUSE] = new TranslatedCardName("Voie de la souris");
FrenchCardNames[CardNames.WAY_OF_THE_MULE] = new TranslatedCardName("Voie de la mule");
FrenchCardNames[CardNames.WAY_OF_THE_OTTER] = new TranslatedCardName("Voie de la loutre");
FrenchCardNames[CardNames.WAY_OF_THE_OWL] = new TranslatedCardName("Voie du hibou");
FrenchCardNames[CardNames.WAY_OF_THE_OX] = new TranslatedCardName("Voie du bœuf");
FrenchCardNames[CardNames.WAY_OF_THE_PIG] = new TranslatedCardName("Voie du porc");
FrenchCardNames[CardNames.WAY_OF_THE_RAT] = new TranslatedCardName("Voie du rat");
FrenchCardNames[CardNames.WAY_OF_THE_SEAL] = new TranslatedCardName("Voie du phoque");
FrenchCardNames[CardNames.WAY_OF_THE_SHEEP] = new TranslatedCardName("Voie du mouton");
FrenchCardNames[CardNames.WAY_OF_THE_SQUIRREL] = new TranslatedCardName("Voie de l'écureuil");
FrenchCardNames[CardNames.WAY_OF_THE_TURTLE] = new TranslatedCardName("Voie de la tortue");
FrenchCardNames[CardNames.WAY_OF_THE_WORM] = new TranslatedCardName("Voie du ver");
FrenchCardNames[CardNames.DELAY] = new TranslatedCardName("Retard");
FrenchCardNames[CardNames.DESPERATION] = new TranslatedCardName("Désespoir");
FrenchCardNames[CardNames.GAMBLE] = new TranslatedCardName("Pari");
FrenchCardNames[CardNames.PURSUE] = new TranslatedCardName("Poursuite");
FrenchCardNames[CardNames.RIDE] = new TranslatedCardName("Chevauchée");
FrenchCardNames[CardNames.TOIL] = new TranslatedCardName("Labeur");
FrenchCardNames[CardNames.ENHANCE] = new TranslatedCardName("Rehaussement");
FrenchCardNames[CardNames.MARCH] = new TranslatedCardName("Marche");
FrenchCardNames[CardNames.TRANSPORT] = new TranslatedCardName("Transport");
FrenchCardNames[CardNames.BANISH] = new TranslatedCardName("Bannissement");
FrenchCardNames[CardNames.BARGAIN] = new TranslatedCardName("Affaire");
FrenchCardNames[CardNames.INVEST] = new TranslatedCardName("Investissement");
FrenchCardNames[CardNames.SEIZE_THE_DAY] = new TranslatedCardName("Carpe Diem");
FrenchCardNames[CardNames.COMMERCE] = new TranslatedCardName("Négoce");
FrenchCardNames[CardNames.DEMAND] = new TranslatedCardName("Demande");
FrenchCardNames[CardNames.STAMPEDE] = new TranslatedCardName("Ruée");
FrenchCardNames[CardNames.REAP] = new TranslatedCardName("Moisson");
FrenchCardNames[CardNames.ENCLAVE] = new TranslatedCardName("Enclave");
FrenchCardNames[CardNames.ALLIANCE] = new TranslatedCardName("Alliance");
FrenchCardNames[CardNames.POPULATE] = new TranslatedCardName("Peuplement");

//old
//FrenchCardNames[CardNames.PRIZE_PILE] = new TranslatedCardName("Pile des prix", "Pile des prix", "la Pile des prix");

//new
FrenchCardNames[CardNames.PRIZE_PILE] = new TranslatedCardName("Prix", "Prix", "Prix");
FrenchCardNames[CardNames.CARD_OF_THE_BUTTERFLY] = "";
FrenchCardNames[CardNames.CARD_OF_THE_CAMEL] = "";
FrenchCardNames[CardNames.CARD_OF_THE_CHAMELEON] = "";
FrenchCardNames[CardNames.CARD_OF_THE_FROG] = "";
FrenchCardNames[CardNames.CARD_OF_THE_GOAT] = "";
FrenchCardNames[CardNames.CARD_OF_THE_HORSE] = "";
FrenchCardNames[CardNames.CARD_OF_THE_MOLE] = "";
FrenchCardNames[CardNames.CARD_OF_THE_MONKEY] = "";
FrenchCardNames[CardNames.CARD_OF_THE_MOUSE] = "";
FrenchCardNames[CardNames.CARD_OF_THE_MULE] = "";
FrenchCardNames[CardNames.CARD_OF_THE_OTTER] = "";
FrenchCardNames[CardNames.CARD_OF_THE_OWL] = "";
FrenchCardNames[CardNames.CARD_OF_THE_OX] = "";
FrenchCardNames[CardNames.CARD_OF_THE_PIG] = "";
FrenchCardNames[CardNames.CARD_OF_THE_RAT] = "";
FrenchCardNames[CardNames.CARD_OF_THE_SEAL] = "";
FrenchCardNames[CardNames.CARD_OF_THE_SHEEP] = "";
FrenchCardNames[CardNames.CARD_OF_THE_SQUIRREL] = "";
FrenchCardNames[CardNames.CARD_OF_THE_TURTLE] = "";
FrenchCardNames[CardNames.CARD_OF_THE_WORM] = "";

FrenchCardNames[CardNames.TOWN] = new TranslatedCardName("Bourg", "Bourgs", "un Bourg");
FrenchCardNames[CardNames.MODIFY] = new TranslatedCardName("Modification", "Modifications", "une Modification");
FrenchCardNames[CardNames.MERCHANT_CAMP] = new TranslatedCardName("Camp de Marchands", "Camps de Marchands", "un Camp de Marchands");
FrenchCardNames[CardNames.SWAP] = new TranslatedCardName("Troc", "Trocs", "un Troc");
FrenchCardNames[CardNames.SKIRMISHER] = new TranslatedCardName("Tirailleur", "Tirailleurs", "un Tirailleur");
FrenchCardNames[CardNames.HIGHWAYMAN] = new TranslatedCardName("Bandit de Grand Chemin", "Bandits de Grand Chemin", "un Bandit de Grand Chemin");
FrenchCardNames[CardNames.BARBARIAN] = new TranslatedCardName("Barbare", "Barbares", "un Barbare");
FrenchCardNames[CardNames.INNKEEPER] = new TranslatedCardName("Aubergiste", "Aubergistes", "une Aubergiste");
FrenchCardNames[CardNames.CAPITAL_CITY] = new TranslatedCardName("Capitale", "Capitales", "une Capitale");
FrenchCardNames[CardNames.SPECIALIST] = new TranslatedCardName("Spécialiste", "Spécialistes", "une Spécialiste");
FrenchCardNames[CardNames.CARPENTER] = new TranslatedCardName("Charpentière", "Charpentières", "une Charpentière");
FrenchCardNames[CardNames.ROYAL_GALLEY] = new TranslatedCardName("Galère Royale", "Galères Royales", "une Galère Royale");
FrenchCardNames[CardNames.MARQUIS] = new TranslatedCardName("Marquis", "Marquis", "un Marquis");
FrenchCardNames[CardNames.SENTINEL] = new TranslatedCardName("Guetteur", "Guetteurs", "un Guetteur");
FrenchCardNames[CardNames.COURIER] = new TranslatedCardName("Courrier Rapide", "Courriers Rapides", "un Courrier Rapide");
FrenchCardNames[CardNames.GALLERIA] = new TranslatedCardName("Marché Couvert", "Marchés Couverts", "un Marché Couvert");
FrenchCardNames[CardNames.HUNTER] = new TranslatedCardName("Chasseuse", "Chasseuses", "une Chasseuse");

FrenchCardNames[CardNames.SYCOPHANT] = new TranslatedCardName("Sycophante", "Sycophantes", "un Sycophante");
FrenchCardNames[CardNames.UNDERLING] = new TranslatedCardName("Subalterne", "Subalternes", "un Subalterne");
FrenchCardNames[CardNames.IMPORTER] = new TranslatedCardName("Importateur", "Importateurs", "un Importateur");
FrenchCardNames[CardNames.CONTRACT] = new TranslatedCardName("Contrat", "Contrats", "un Contrat");
FrenchCardNames[CardNames.EMISSARY] = new TranslatedCardName("Émissaire", "Émissaires", "une Émissaire");
FrenchCardNames[CardNames.BAUBLE] = new TranslatedCardName("Babiole", "Babioles", "une Babiole");
FrenchCardNames[CardNames.BROKER] = new TranslatedCardName("Courtier", "Courtiers", "un Courtier");
FrenchCardNames[CardNames.GUILDMASTER] = new TranslatedCardName("Maître de Guilde", "Maîtres de Guilde", "une Maître de Guilde");

FrenchCardNames[CardNames.ARCHITECTS_GUILD] = new TranslatedCardName("Guilde des Architectes");
FrenchCardNames[CardNames.ORDER_OF_ASTROLOGERS] = new TranslatedCardName("Ordre des Astrologues");
FrenchCardNames[CardNames.LEAGUE_OF_BANKERS] = new TranslatedCardName("Ligue des Banquiers");
FrenchCardNames[CardNames.MOUNTAIN_FOLK] = new TranslatedCardName("Montagnards");
FrenchCardNames[CardNames.WOODWORKERS_GUILD] = new TranslatedCardName("Guilde des Menuisiers");
FrenchCardNames[CardNames.PEACEFUL_CULT] = new TranslatedCardName("Culte Pacifique");
FrenchCardNames[CardNames.CRAFTERS_GUILD] = new TranslatedCardName("Guilde des Artisans");
FrenchCardNames[CardNames.CAVE_DWELLERS] = new TranslatedCardName("Troglodytes");
FrenchCardNames[CardNames.PLATEAU_SHEPHERDS] = new TranslatedCardName("Bergers du Plateau");
FrenchCardNames[CardNames.DESERT_GUIDES] = new TranslatedCardName("Guides du Désert");
FrenchCardNames[CardNames.FAMILY_OF_INVENTORS] = new TranslatedCardName("Famille d'Inventeurs");
FrenchCardNames[CardNames.ISLAND_FOLK] = new TranslatedCardName("Peuple des Îles");
FrenchCardNames[CardNames.ORDER_OF_MASONS] = new TranslatedCardName("Ordre des Maçons");
FrenchCardNames[CardNames.BAND_OF_NOMADS] = new TranslatedCardName("Bande de Nomades");
FrenchCardNames[CardNames.GANG_OF_PICKPOCKETS] = new TranslatedCardName("Détrousseurs");
FrenchCardNames[CardNames.MARKET_TOWNS] = new TranslatedCardName("Villes Commerçantes");
FrenchCardNames[CardNames.FOREST_DWELLERS] = new TranslatedCardName("Habitants des Forêts");
FrenchCardNames[CardNames.FELLOWSHIP_OF_SCRIBES] = new TranslatedCardName("Confrérie des Scribes");
FrenchCardNames[CardNames.LEAGUE_OF_SHOPKEEPERS] = new TranslatedCardName("Ligue des Commerçants");
FrenchCardNames[CardNames.COASTAL_HAVEN] = new TranslatedCardName("Havre Côtier");
FrenchCardNames[CardNames.CITY_STATE] = new TranslatedCardName("Cité-État");
FrenchCardNames[CardNames.TRAPPERS_LODGE] = new TranslatedCardName("Cabane des Trappeurs");
FrenchCardNames[CardNames.CIRCLE_OF_WITCHES] = new TranslatedCardName("Cercle des Sorcières");

FrenchCardNames[CardNames.AUGUR_PILE] = new TranslatedCardName("Augures");
FrenchCardNames[CardNames.HERB_GATHERER] = new TranslatedCardName("Cueilleuse d’Herbes", "Cueilleuses d’Herbes", "une Cueilleuse d’Herbes ");
FrenchCardNames[CardNames.ACOLYTE] = new TranslatedCardName("Acolyte", "Acolytes", "une Acolyte");
FrenchCardNames[CardNames.SORCERESS] = new TranslatedCardName("Sorcière Maléfique", "Sorcières Maléfiques", "une Sorcière Maléfique");
FrenchCardNames[CardNames.SIBYL] = new TranslatedCardName("Sibylle", "Sibylles", "une Sibylle");
FrenchCardNames[CardNames.CLASH_PILE] = new TranslatedCardName("Affrontements");
FrenchCardNames[CardNames.BATTLE_PLAN] = new TranslatedCardName("Tactique", "Tactiques", "une Tactique");
FrenchCardNames[CardNames.ARCHER] = new TranslatedCardName("Archère", "Archères", "une Archère");
FrenchCardNames[CardNames.WARLORD] = new TranslatedCardName("Seigneur de Guerre", "Seigneurs de Guerre", "un Seigneur de Guerre");
FrenchCardNames[CardNames.TERRITORY] = new TranslatedCardName("Territoire", "Territoires", "un Territoire");
FrenchCardNames[CardNames.FORT_PILE] = new TranslatedCardName("Fortifications");
FrenchCardNames[CardNames.TENT] = new TranslatedCardName("Tente", "Tentes", "une Tente");
FrenchCardNames[CardNames.GARRISON] = new TranslatedCardName("Garnison", "Garnisons", "une Garnison");
FrenchCardNames[CardNames.HILL_FORT] = new TranslatedCardName("Fort de la Colline", "Forts de la Colline", "un Fort de la Colline");
FrenchCardNames[CardNames.STRONGHOLD] = new TranslatedCardName("Bastion", "Bastions", "un Bastion");
FrenchCardNames[CardNames.ODYSSEY_PILE] = new TranslatedCardName("Odyssées");
FrenchCardNames[CardNames.OLD_MAP] = new TranslatedCardName("Vieille Carte", "Vieilles Cartes", "une Vieille Carte");
FrenchCardNames[CardNames.VOYAGE] = new TranslatedCardName("Voyage", "Voyages", "un Voyage");
FrenchCardNames[CardNames.SUNKEN_TREASURE] = new TranslatedCardName("Trésor Englouti", "Trésors Engloutis", "un Trésor Englouti");
FrenchCardNames[CardNames.DISTANT_SHORE] = new TranslatedCardName("Rivage Lointain", "Rivages Lointains", "un Rivage Lointain");
FrenchCardNames[CardNames.TOWNSFOLK_PILE] = new TranslatedCardName("Citoyens");
FrenchCardNames[CardNames.TOWN_CRIER] = new TranslatedCardName("Crieuse Publique", "Crieuses Publiques", "une Crieuse Publique");
FrenchCardNames[CardNames.BLACKSMITH] = new TranslatedCardName("Forgeur", "Forgeurs", "un Forgeur");
FrenchCardNames[CardNames.MILLER] = new TranslatedCardName("Meunier", "Meuniers", "un Meunier");
FrenchCardNames[CardNames.ELDER] = new TranslatedCardName("Aînée", "Aînées", "une Aînée");
FrenchCardNames[CardNames.WIZARD_PILE] = new TranslatedCardName("Magiciens");
FrenchCardNames[CardNames.STUDENT] = new TranslatedCardName("Apprenti Magicien", "Apprentis Magiciens", "un Apprenti Magicien");
FrenchCardNames[CardNames.CONJURER] = new TranslatedCardName("Illusionniste", "Illusionnistes", "un Illusionniste");
FrenchCardNames[CardNames.SORCERER] = new TranslatedCardName("Sorcier", "Sorciers", "un Sorcier");
FrenchCardNames[CardNames.LICH] = new TranslatedCardName("Liche", "Liches", "une Liche");

FrenchCardNames[CardNames.MONKEY] = new TranslatedCardName("Singe", "Singes", "un Singe");
FrenchCardNames[CardNames.BLOCKADE] = new TranslatedCardName("Blocus", "Blocus", "un Blocus");
FrenchCardNames[CardNames.SAILOR] = new TranslatedCardName("Navigatrice", "Navigatrices", "une Navigatrice");
FrenchCardNames[CardNames.PIRATE] = new TranslatedCardName("Pirate", "Pirates", "une Pirate");
FrenchCardNames[CardNames.CORSAIR] = new TranslatedCardName("Corsaire", "Corsaires", "un Corsaire");
FrenchCardNames[CardNames.SEA_CHART] = new TranslatedCardName("Carte Marine", "Cartes Marines", "une Carte Marine");
FrenchCardNames[CardNames.ASTROLABE] = new TranslatedCardName("Astrolabe", "Astrolabes", "un Astrolabe");
FrenchCardNames[CardNames.TIDE_POOLS] = new TranslatedCardName("Marée", "Marées", "une Marée");
FrenchCardNames[CardNames.SEA_WITCH] = new TranslatedCardName("Sorcière Marine", "Sorcières Marines", "une Sorcière Marine");

FrenchCardNames[CardNames.ANVIL] = new TranslatedCardName("Enclume", "Enclumes", "une Enclume");
FrenchCardNames[CardNames.CLERK] = new TranslatedCardName("Comptable", "Comptables", "une Comptable");
FrenchCardNames[CardNames.INVESTMENT] = new TranslatedCardName("Placement", "Placements", "un Placement");
FrenchCardNames[CardNames.TIARA] = new TranslatedCardName("Couronne de mariée", "Couronnes de mariée", "une Couronne de mariée");
FrenchCardNames[CardNames.CHARLATAN] = new TranslatedCardName("Charlatane", "Charlatanes", "une Charlatane");
FrenchCardNames[CardNames.COLLECTION] = new TranslatedCardName("Collection", "Collections", "une Collection");
FrenchCardNames[CardNames.CRYSTAL_BALL] = new TranslatedCardName("Boule de cristal", "Boules de cristal", "une Boule de cristal");
FrenchCardNames[CardNames.MAGNATE] = new TranslatedCardName("Magnat", "Magnats", "un Magnat");
FrenchCardNames[CardNames.WAR_CHEST] = new TranslatedCardName("Trésor de guerre", "Trésors de guerre", "un Trésor de guerre");

FrenchCardNames[CardNames.GUARD_DOG] = new TranslatedCardName("Chien de garde", "Chiens de garde", "un Chien de garde");
FrenchCardNames[CardNames.BERSERKER] = new TranslatedCardName("Berserker", "Berserkers", "un Berserker");
FrenchCardNames[CardNames.NOMADS] = new TranslatedCardName("Nomades", "Nomades", "des Nomades");
FrenchCardNames[CardNames.TRAIL] = new TranslatedCardName("Sentier", "Sentiers", "un Sentier");
FrenchCardNames[CardNames.WEAVER] = new TranslatedCardName("Tisserande", "Tisserandes", "une Tisserande");
FrenchCardNames[CardNames.SOUK] = new TranslatedCardName("Souk", "Souks", "un Souk");
FrenchCardNames[CardNames.CAULDRON] = new TranslatedCardName("Chaudron", "Chaudrons", "un Chaudron");
FrenchCardNames[CardNames.WHEELWRIGHT] = new TranslatedCardName("Charronne", "Charronnes", "une Charronne");
FrenchCardNames[CardNames.WITCHS_HUT] = new TranslatedCardName("Cabane de sorcière", "Cabanes de sorcière", "une Cabane de sorcière");

FrenchCardNames[CardNames.CAGE] = new TranslatedCardName("Cage", "Cages", "une Cage");
FrenchCardNames[CardNames.GROTTO] = new TranslatedCardName("Grotte", "Grottes", "une Grotte");
FrenchCardNames[CardNames.JEWELLED_EGG] = new TranslatedCardName("Œuf précieux", "Œufs précieux", "un Œuf précieux");
FrenchCardNames[CardNames.SEARCH] = new TranslatedCardName("Chasse au trésor", "Chasses au trésor", "une Chasse au trésor");
FrenchCardNames[CardNames.SHAMAN] = new TranslatedCardName("Chamane", "Chamanes", "une Chamane");
FrenchCardNames[CardNames.SECLUDED_SHRINE] = new TranslatedCardName("Sanctuaire Isolé", "Sanctuaires Isolés", "un Sanctuaire Isolé");
FrenchCardNames[CardNames.SIREN] = new TranslatedCardName("Sirène", "Sirènes", "une Sirène");
FrenchCardNames[CardNames.STOWAWAY] = new TranslatedCardName("Clandestine", "Clandestines", "une Clandestine");
FrenchCardNames[CardNames.TASKMASTER] = new TranslatedCardName("Surveillant", "Surveillants", "un Surveillant");
FrenchCardNames[CardNames.ABUNDANCE] = new TranslatedCardName("Abondance", "Abondances", "une Abondance");
FrenchCardNames[CardNames.CABIN_BOY] = new TranslatedCardName("Mousse", "Mousses", "un Mousse");
FrenchCardNames[CardNames.CRUCIBLE] = new TranslatedCardName("Creuset", "Creusets", "un Creuset");
FrenchCardNames[CardNames.FLAGSHIP] = new TranslatedCardName("Navire Amiral", "Navires Amiraux", "un Navire Amiral");
FrenchCardNames[CardNames.FORTUNE_HUNTER] = new TranslatedCardName("Aventurière", "Aventurières", "une Aventurière");
FrenchCardNames[CardNames.GONDOLA] = new TranslatedCardName("Gondole", "Gondoles", "une Gondole");
FrenchCardNames[CardNames.HARBOR_VILLAGE] = new TranslatedCardName("Village Portuaire", "Villages Portuaires", "un Village Portuaire");
FrenchCardNames[CardNames.LANDING_PARTY] = new TranslatedCardName("Débarquement", "Débarquements", "un Débarquement");
FrenchCardNames[CardNames.MAPMAKER] = new TranslatedCardName("Cartographe Marine", "Cartographes Marines", "une Cartographe Marine");
FrenchCardNames[CardNames.MAROON] = new TranslatedCardName("Abandon", "Abandons", "un Abandon");
FrenchCardNames[CardNames.ROPE] = new TranslatedCardName("Corde", "Cordes", "une Corde");
FrenchCardNames[CardNames.SWAMP_SHACKS] = new TranslatedCardName("Cabane des marais", "Cabanes des marais", "une Cabane des marais");
FrenchCardNames[CardNames.TOOLS] = new TranslatedCardName("Outils", "Outils", "des Outils");
FrenchCardNames[CardNames.BURIED_TREASURE] = new TranslatedCardName("Trésor enfoui", "Trésors enfouis", "un Trésor enfoui");
FrenchCardNames[CardNames.CREW] = new TranslatedCardName("Équipage", "Équipages", "un Équipage");
FrenchCardNames[CardNames.CUTTHROAT] = new TranslatedCardName("Coupe-gorge", "Coupe-gorges", "un Coupe-gorge");
FrenchCardNames[CardNames.ENLARGE] = new TranslatedCardName("Élargissement", "Élargissements", "un Élargissement");
FrenchCardNames[CardNames.FIGURINE] = new TranslatedCardName("Figurine", "Figurines", "une Figurine");
FrenchCardNames[CardNames.FIRST_MATE] = new TranslatedCardName("Seconde", "Secondes", "une Seconde");
FrenchCardNames[CardNames.FRIGATE] = new TranslatedCardName("Frégate", "Frégates", "une Frégate");
FrenchCardNames[CardNames.LONGSHIP] = new TranslatedCardName("Drakkar", "Drakkars", "un Drakkar");
FrenchCardNames[CardNames.MINING_ROAD] = new TranslatedCardName("Chemin minier", "Chemins miniers", "un Chemin minier");
FrenchCardNames[CardNames.PENDANT] = new TranslatedCardName("Pendentif", "Pendentifs", "un Pendentif");
FrenchCardNames[CardNames.PICKAXE] = new TranslatedCardName("Pioche", "Pioches", "une Pioche");
FrenchCardNames[CardNames.PILGRIM] = new TranslatedCardName("Pèlerin", "Pèlerins", "un Pèlerin");
FrenchCardNames[CardNames.QUARTERMASTER] = new TranslatedCardName("Quartier-maître", "Quartier-maîtres", "un Quartier-maître");
FrenchCardNames[CardNames.SILVER_MINE] = new TranslatedCardName("Mine d'argent", "Mines d'argent", "une Mine d'argent");
FrenchCardNames[CardNames.TRICKSTER] = new TranslatedCardName("Bonneteur", "Bonneteurs", "un Bonneteur");
FrenchCardNames[CardNames.WEALTHY_VILLAGE] = new TranslatedCardName("Village prospère", "Villages prospères", "un Village prospère");
FrenchCardNames[CardNames.SACK_OF_LOOT] = new TranslatedCardName("Sac à trophées", "Sacs à trophées", "un Sac à trophées");
FrenchCardNames[CardNames.KINGS_CACHE] = new TranslatedCardName("Cache du roi", "Caches du roi", "un Cache du roi");
FrenchCardNames[CardNames.BURY] = new TranslatedCardName("Enterrement", "Enterrements", "un Enterrement");
FrenchCardNames[CardNames.AVOID] = new TranslatedCardName("Évitement", "Évitements", "un Évitement");
FrenchCardNames[CardNames.DELIVER] = new TranslatedCardName("Livraison", "Livraisons", "une Livraison");
FrenchCardNames[CardNames.PERIL] = new TranslatedCardName("Péril", "Périls", "un Péril");
FrenchCardNames[CardNames.RUSH] = new TranslatedCardName("Précipitation", "Précipitations", "une Précipitation");
FrenchCardNames[CardNames.FORAY] = new TranslatedCardName("Incursion", "Incursions", "une Incursion");
FrenchCardNames[CardNames.LAUNCH] = new TranslatedCardName("Embarquement", "Embarquements", "Un Embarquement");
FrenchCardNames[CardNames.MIRROR] = new TranslatedCardName("Imitation", "Imitations", "une Imitation");
FrenchCardNames[CardNames.PREPARE] = new TranslatedCardName("Préparation", "Préparations", "une Préparation");
FrenchCardNames[CardNames.SCROUNGE] = new TranslatedCardName("Maraude", "Maraudes", "une Maraude");
FrenchCardNames[CardNames.JOURNEY] = new TranslatedCardName("Traversée", "Traversées", "une Traversée");
FrenchCardNames[CardNames.MAELSTROM] = new TranslatedCardName("Maelstrom", "Maelstroms", "un Maelstrom");
FrenchCardNames[CardNames.LOOTING] = new TranslatedCardName("Chasse aux trophées", "Chasses aux trophées", "une Chasse aux trophées");
FrenchCardNames[CardNames.INVASION] = new TranslatedCardName("Invasion", "Invasions", "une Invasion");
FrenchCardNames[CardNames.PROSPER] = new TranslatedCardName("Prospérité", "Prospérités", "une Prospérité");
FrenchCardNames[CardNames.LOOT_PILE] = new TranslatedCardName("Trophée", "Trophées", "un Trophée");
FrenchCardNames[CardNames.AMPHORA] = new TranslatedCardName("Amphore", "Amphores", "une Amphore");
FrenchCardNames[CardNames.DOUBLOONS] = new TranslatedCardName("Doublons", "Doublons", "des Doublons");
FrenchCardNames[CardNames.ENDLESS_CHALICE] = new TranslatedCardName("Calice inépuisable", "Calices inépuisables", "un Calice inépuisable");
FrenchCardNames[CardNames.FIGUREHEAD] = new TranslatedCardName("Figure de proue", "Figures de proue", "une Figure de proue");
FrenchCardNames[CardNames.HAMMER] = new TranslatedCardName("Marteau", "Marteaux", "un Marteau");
FrenchCardNames[CardNames.INSIGNIA] = new TranslatedCardName("Insigne", "Insignes", "un Insigne");
FrenchCardNames[CardNames.JEWELS] = new TranslatedCardName("Bijoux", "Bijoux", "des Bijoux");
FrenchCardNames[CardNames.ORB] = new TranslatedCardName("Orbe", "Orbes", "un Orbe");
FrenchCardNames[CardNames.PRIZE_GOAT] = new TranslatedCardName("Chèvre de race", "Chèvres de race", "une Chèvre de race");
FrenchCardNames[CardNames.PUZZLE_BOX] = new TranslatedCardName("Boîte mystérieuse", "Boîtes mystérieuses", "une Boîte mystérieuse");
FrenchCardNames[CardNames.SEXTANT] = new TranslatedCardName("Sextant", "Sextants", "un Sextant");
FrenchCardNames[CardNames.SHIELD] = new TranslatedCardName("Bouclier", "Boucliers", "un Bouclier");
FrenchCardNames[CardNames.SPELL_SCROLL] = new TranslatedCardName("Manuscrit magique", "Manuscrits magiques", "un Manuscrit magique");
FrenchCardNames[CardNames.STAFF] = new TranslatedCardName("Bâton", "Bâtons", "un Bâton");
FrenchCardNames[CardNames.SWORD] = new TranslatedCardName("Épée", "Épées", "une Épée");
FrenchCardNames[CardNames.CHEAP] = new TranslatedCardName("Abordable", "", "");
FrenchCardNames[CardNames.CURSED] = new TranslatedCardName("Maudit", "", "");
FrenchCardNames[CardNames.FATED] = new TranslatedCardName("Destiné", "", "");
FrenchCardNames[CardNames.FAWNING] = new TranslatedCardName("Servile", "", "");
FrenchCardNames[CardNames.FRIENDLY] = new TranslatedCardName("Amical", "", "");
FrenchCardNames[CardNames.HASTY] = new TranslatedCardName("Impatient", "", "");
FrenchCardNames[CardNames.INHERITED] = new TranslatedCardName("Hérité", "", "");
FrenchCardNames[CardNames.INSPIRING] = new TranslatedCardName("Exaltant", "", "");
FrenchCardNames[CardNames.NEARBY] = new TranslatedCardName("Proche", "", "");
FrenchCardNames[CardNames.PATIENT] = new TranslatedCardName("Patient", "", "");
FrenchCardNames[CardNames.PIOUS] = new TranslatedCardName("Pieux", "", "");
FrenchCardNames[CardNames.RECKLESS] = new TranslatedCardName("Téméraire", "", "");
FrenchCardNames[CardNames.RICH] = new TranslatedCardName("Riche", "", "");
FrenchCardNames[CardNames.SHY] = new TranslatedCardName("Timide", "", "");
FrenchCardNames[CardNames.TIRELESS] = new TranslatedCardName("Infatigable", "", "");
FrenchCardNames[CardNames.MARCHLAND] = new TranslatedCardName("Confins", "Confins", "des Confins");
FrenchCardNames[CardNames.SHOP] = new TranslatedCardName("Échoppe", "Échoppes", "une Échoppe");
FrenchCardNames[CardNames.CARNIVAL] = new TranslatedCardName("Carnaval", "Carnavals", "un Carnaval");
FrenchCardNames[CardNames.FERRYMAN] = new TranslatedCardName("Passeur", "Passeurs", "un Passeur");
FrenchCardNames[CardNames.FARMHANDS] = new TranslatedCardName("Glaneuses", "Glaneuses", "des Glaneuses");
FrenchCardNames[CardNames.JOUST] = new TranslatedCardName("Joute", "Joutes", "une Joute");
FrenchCardNames[CardNames.REWARDS_PILE] = new TranslatedCardName("Récompense", "Récompenses", "une Récompense");
FrenchCardNames[CardNames.COURSER] = new TranslatedCardName("Coursier", "Coursiers", "un Coursier");
FrenchCardNames[CardNames.RENOWN] = new TranslatedCardName("Renommée", "Renommées", "une Renommée");
FrenchCardNames[CardNames.CORONET] = new TranslatedCardName("Couronne de noblesse", "Couronnes de noblesse", "une Couronne de noblesse");
FrenchCardNames[CardNames.DEMESNE] = new TranslatedCardName("Propriété", "Propriétés", "une Propriété");
FrenchCardNames[CardNames.HOUSECARL] = new TranslatedCardName("Housecarl", "Housecarls", "un Housecarl");
FrenchCardNames[CardNames.HUGE_TURNIP] = new TranslatedCardName("Navet géant", "Navets géants", "un Navet géant");
FrenchCardNames[CardNames.FARRIER] = new TranslatedCardName("Maréchal-ferrant", "Maréchaux-ferrants", "un Maréchal-ferrant");
FrenchCardNames[CardNames.INFIRMARY] = new TranslatedCardName("Infirmerie", "Infirmeries", "une Infirmerie");
FrenchCardNames[CardNames.FOOTPAD] = new TranslatedCardName("Gredin", "Gredins", "un Gredin");

FrenchCardNames[CardNames.FISHMONGER] = new TranslatedCardName("Poissonnière", "Poissonnières", "une Poissonnière");
FrenchCardNames[CardNames.SNAKE_WITCH] = new TranslatedCardName("Sorcière aux serpents", "Sorcières aux serpents", "une Sorcière aux serpents");
FrenchCardNames[CardNames.ARISTOCRAT] = new TranslatedCardName("Aristocrate", "Aristocrates", "une Aristocrate");
FrenchCardNames[CardNames.CRAFTSMAN] = new TranslatedCardName("Ouvrier", "Ouvriers", "un Ouvrier");
FrenchCardNames[CardNames.RIVERBOAT] = new TranslatedCardName("Bateau fluvial", "Bateaux fluviaux", "un Bateau fluvial");
FrenchCardNames[CardNames.ROOT_CELLAR] = new TranslatedCardName("Cellier à légumes", "Celliers à légumes", "un Cellier à légumes");
FrenchCardNames[CardNames.ALLEY] = new TranslatedCardName("Ruelle", "Ruelles", "une Ruelle");
FrenchCardNames[CardNames.CHANGE] = new TranslatedCardName("Changement", "Changements", "un Changement");
FrenchCardNames[CardNames.NINJA] = new TranslatedCardName("Ninja", "Ninjas", "un Ninja");
FrenchCardNames[CardNames.POET] = new TranslatedCardName("Poétesse", "Poétesses", "une Poétesse");
FrenchCardNames[CardNames.RIVER_SHRINE] = new TranslatedCardName("Sanctuaire de la rivière", "Sanctuaires de la rivière", "un Sanctuaire de la rivière");
FrenchCardNames[CardNames.RUSTIC_VILLAGE] = new TranslatedCardName("Village rustique", "Villages rustiques", "un Village rustique");
FrenchCardNames[CardNames.GOLD_MINE] = new TranslatedCardName("Mine d'or", "Mines d'or", "une Mine d'or");
FrenchCardNames[CardNames.IMPERIAL_ENVOY] = new TranslatedCardName("Délégué impérial", "Délégués impériaux", "un Délégué impérial");
FrenchCardNames[CardNames.KITSUNE] = new TranslatedCardName("Kitsune", "Kitsunes", "un Kitsune");
FrenchCardNames[CardNames.LITTER] = new TranslatedCardName("Chaise à porteurs", "Chaises à porteurs", "une Chaise à porteurs");
FrenchCardNames[CardNames.RICE_BROKER] = new TranslatedCardName("Courtier en riz", "Courtiers en riz", "un Courtier en riz");
FrenchCardNames[CardNames.RONIN] = new TranslatedCardName("Ronin", "Ronins", "un Ronin");
FrenchCardNames[CardNames.TANUKI] = new TranslatedCardName("Tanuki", "Tanukis", "un Tanuki");
FrenchCardNames[CardNames.TEA_HOUSE] = new TranslatedCardName("Salon de thé", "Salons de thé", "un Salon de thé");
FrenchCardNames[CardNames.SAMURAI] = new TranslatedCardName("Samouraï", "Samouraïs", "un Samouraï");
FrenchCardNames[CardNames.RICE] = new TranslatedCardName("Riz", "Riz", "un Riz");
FrenchCardNames[CardNames.MOUNTAIN_SHRINE] = new TranslatedCardName("Sanctuaire de la montagne", "Sanctuaires de la montagne", "un Sanctuaire de la montagne");
FrenchCardNames[CardNames.DAIMYO] = new TranslatedCardName("Daimyo", "Daimyos", "un Daimyo");
FrenchCardNames[CardNames.ARTIST] = new TranslatedCardName("Artiste", "Artistes", "un Artiste");
FrenchCardNames[CardNames.APPROACHING_ARMY] = new TranslatedCardName("Armée en approche");
FrenchCardNames[CardNames.BIDING_TIME] = new TranslatedCardName("Attendre son heure");
FrenchCardNames[CardNames.BUREAUCRACY] = new TranslatedCardName("Bureaucratie");
FrenchCardNames[CardNames.DIVINE_WIND] = new TranslatedCardName("Vent divin");
FrenchCardNames[CardNames.ENLIGHTENMENT] = new TranslatedCardName("Illumination");
FrenchCardNames[CardNames.FLOURISHING_TRADE] = new TranslatedCardName("Commerce florissant");
FrenchCardNames[CardNames.GOOD_HARVEST] = new TranslatedCardName("Bonne récolte");
FrenchCardNames[CardNames.GREAT_LEADER] = new TranslatedCardName("Grand dirigeant");
FrenchCardNames[CardNames.GROWTH] = new TranslatedCardName("Croissance");
FrenchCardNames[CardNames.HARSH_WINTER] = new TranslatedCardName("Hiver rude");
FrenchCardNames[CardNames.KIND_EMPEROR] = new TranslatedCardName("Bon empereur");
FrenchCardNames[CardNames.PANIC] = new TranslatedCardName("Panique");
FrenchCardNames[CardNames.PROGRESS] = new TranslatedCardName("Progrès");
FrenchCardNames[CardNames.RAPID_EXPANSION] = new TranslatedCardName("Expansion rapide");
FrenchCardNames[CardNames.SICKNESS] = new TranslatedCardName("Maladie");
FrenchCardNames[CardNames.AMASS] = new TranslatedCardName("Amassement");
FrenchCardNames[CardNames.ASCETICISM] = new TranslatedCardName("Ascèse");
FrenchCardNames[CardNames.CREDIT] = new TranslatedCardName("Crédit");
FrenchCardNames[CardNames.FORESIGHT] = new TranslatedCardName("Prévoyance");
FrenchCardNames[CardNames.KINTSUGI] = new TranslatedCardName("Kintsugi");
FrenchCardNames[CardNames.PRACTICE] = new TranslatedCardName("Entraînement");
FrenchCardNames[CardNames.SEA_TRADE] = new TranslatedCardName("Commerce maritime");
FrenchCardNames[CardNames.RECEIVE_TRIBUTE] = new TranslatedCardName("Hommage rendu");
FrenchCardNames[CardNames.GATHER] = new TranslatedCardName("Cueillette");
FrenchCardNames[CardNames.CONTINUE] = new TranslatedCardName("Prolongation");