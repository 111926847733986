"use strict";

var CzechErrors = {};

CzechErrors[FailureReasons.WRONG_PASSWORD] = "Špatné heslo.";
CzechErrors[FailureReasons.NOT_ENOUGH_PLAYERS] = "Nedostatek hráčů.";
CzechErrors[FailureReasons.TOO_MANY_PLAYERS] = "Moc hráčů.";
CzechErrors[FailureReasons.NEW_HOST_NOT_FOUND] = "Nenaleze nový hostitel.";
CzechErrors[FailureReasons.ALREADY_JOINED_A_TABLE] = "U tohoto stolu jsi již připojen.";
CzechErrors[FailureReasons.NOT_ALLOWED_TO_JOIN] = "Nemáš povolení se připojit.";
CzechErrors[FailureReasons.BAD_CLIENT_VERSION] = "Váš klient je zastaralý; Stiskněte CTRL+R pro aktualizaci.";
CzechErrors[FailureReasons.UNKNOWN_RULE_INDEX] = "Neznámý příznak pravidel.";
CzechErrors[FailureReasons.RULE_NOT_REMOVABLE] = "Toto pravidlo se nedá odebrat.";
CzechErrors[FailureReasons.INVITEE_NOT_LOGGED_IN] = "Pozvaní se ještě nepřipojili.";
CzechErrors[FailureReasons.NOT_ALLOWED_TO_PLAY] = "Nemáš povolení hrát.";
CzechErrors[FailureReasons.TABLE_CHAT_REQUIRES_TABLE] = "Chatování u stolu vyžaduje stůl.";
CzechErrors[FailureReasons.SPECTATOR_CHAT_REQUIRES_TABLE] = "Chat pozorovatelů vyžaduje stůl.";
CzechErrors[FailureReasons.LOGIN_REQUIRED] = "Musíš být přihlášeni.";
CzechErrors[FailureReasons.COMMAND_REQUIRES_TABLE] = "Tento příkaz vyžaduje stůl.";
CzechErrors[FailureReasons.COMMAND_REQUIRES_HOST] = "Tento příkaz vyžaduje práva hostitele.";
CzechErrors[FailureReasons.RULE_CONFLICTS] = "Pravidla jsou v konfliktu.";
CzechErrors[FailureReasons.NO_GAMESERVER_AVAILABLE] = "Žádný server není dostupný.";
CzechErrors[FailureReasons.SELECTED_CARD_NOT_OWNED] = "Nevlastníš označenou kartu.";
CzechErrors[FailureReasons.SELECTED_CARD_NOT_KINGDOM_CARD] = "Označená karta není karta Království.";
CzechErrors[FailureReasons.SELECTED_TOO_MANY_EVENTS_OR_LANDMARKS] = "Moc označených Událostí nebo Milníků.";
CzechErrors[FailureReasons.REQUIRING_MORE_CARDS_THAN_AVAILABLE] = "Je vyžadováno víc karet než je dostupných.";
CzechErrors[FailureReasons.THIS_IS_NOT_YOUR_TABLE] = "Toto není tvůj stůl.";
CzechErrors[FailureReasons.ALREADY_LOGGED_IN] = "Již přihlášen.";
CzechErrors[FailureReasons.CANNOT_RECONNECT_WHEN_STILL_CONNECTED] = "Nelze obnovit když jsi připojen.";
CzechErrors[FailureReasons.KICK_FAILED] = "Vykopnutí selhalo.";
CzechErrors[FailureReasons.ALREADY_STARTING] = "Spouštění již začíná.";
CzechErrors[FailureReasons.ALREADY_STARTED] = "Spuštění již začalo.";
CzechErrors[FailureReasons.NOTHING_TO_KICK] = "Nelze nikoho vykopnout.";
CzechErrors[FailureReasons.INTERNAL_ERROR] = "Vnitřní chyba.";
CzechErrors[FailureReasons.ILLEGAL_PROPERTY_CHANGE] = "Nezákonná výměna majetku";
CzechErrors[FailureReasons.CODE_ALREADY_USED] = "Kód byl již použit";
CzechErrors[FailureReasons.FAILED_CONFIRMING_EMAIL] = "Potvrzení emailu selhalo";
CzechErrors[FailureReasons.FAILED_RETRIEVING_USER] = "Obnovení uživatele selhalo";
CzechErrors[FailureReasons.EMAIL_NOT_CONFIRMED_YET] = "Nemůžeš vytvořit hru před potvrzením emailu.";
CzechErrors[FailureReasons.FAILURE_SENDING_EMAIL] = "Potvrzovací email se nepodařilo poslat";
CzechErrors[FailureReasons.CONFIRM_EMAIL_FIRST] = "Omlouváme se ale nemůžem ti poslat heslo bez ověření emailové adresy.";
CzechErrors[FailureReasons.OLD_PASSWORD_INCORRECT] = "Nesprávné staré heslo";
CzechErrors[FailureReasons.INCORRECT_PRICE] = "Nesprávná cena";
CzechErrors[FailureReasons.PURCHASE_IN_FUTURE] = "Zdá se, že platba přišla z budoucnosti";
CzechErrors[FailureReasons.PURCHASE_TOO_OLD] = "Platba je příliš stará; prosím zkus to znovu";
CzechErrors[FailureReasons.FAILED_SETTING_UP_PURCHASE] = "Nastavení platby selhalo";
CzechErrors[FailureReasons.FAILED_STORING_PURCHASE] = "Ukládání platby selhalo";
CzechErrors[FailureReasons.DUPLICATE_USERNAME] = "Tato přezdívka je již používána";
CzechErrors[FailureReasons.INVALID_USERNAME] = "Neplatná přezdívka";
CzechErrors[FailureReasons.INVALID_PASSWORD] = "Neplatné heslo";
CzechErrors[FailureReasons.INVALID_GENDER] = "Neplatné pohlaví";
CzechErrors[FailureReasons.PURCHASE_TOO_SHORT] = "Nejkratší doba předplatného je 1 měsíc";
CzechErrors[FailureReasons.PURCHASE_TOO_LONG] = "V tuto chvíli neprodáváme předplatné na delší dobu než rok 2021";
CzechErrors[FailureReasons.METASERVER_INACTIVE] = "Vyšla nová verze serveru; prosím stiskněte CTRL+R pro aktualizaci.";
CzechErrors[FailureReasons.NO_SUCH_TABLE] = "Stůl nenalezen.";
CzechErrors[FailureReasons.NO_SUCH_USER] = "Uživatel nenalezen.";
CzechErrors[FailureReasons.NO_SUCH_CODE] = "Kód nenalezen.";
CzechErrors[FailureReasons.RESERVED_USERNAME] = "Tato přezdívka je rezervována.";
CzechErrors[FailureReasons.PRICE_IS_ZERO] = "Nemůžeš vytvořit platbu o nulové hodnotě.";
CzechErrors[FailureReasons.NO_SUCH_USERNAME] = "Přezdívka neexistuje.";
CzechErrors[FailureReasons.NO_SUCH_GAMELOG] = "Požadovaná hra nenalezena.";
CzechErrors[FailureReasons.ILLEGAL_PLAYER_ORDER] = "Pořadí nezákonných hráčů.";
CzechErrors[FailureReasons.INVALID_SESSION_ID] = "Neplatné ID hry.";
CzechErrors[FailureReasons.ACTION_CODE_EXPIRED] = "Akční kód vypršel.";
CzechErrors[FailureReasons.BOT_GAMES_CANT_BE_RATED] = "Hry s roboty nejsou hodnoceny.";
CzechErrors[FailureReasons.TOO_MANY_PLAYERS_FOR_RATED_GAME] = "Hry s více než čtyřmi hráči nejsou hodnoceny.";
CzechErrors[FailureReasons.ALREADY_ON_BLACKLIST] = "Již je na Černé listině.";
CzechErrors[FailureReasons.NOT_ON_BLACKLIST] = "Není na Černé listině.";
CzechErrors[FailureReasons.ALREADY_ON_FRIENDLIST] = "Již je v seznamu přátel.";
CzechErrors[FailureReasons.NOT_ON_FRIENDLIST] = "Není v seznamu přátel.";
CzechErrors[FailureReasons.SEARCHING_FOR_MYSELF] = "Nemůžeš přidat sám sebe.";
CzechErrors[FailureReasons.SEARCHED_FOR_BOT] = "Nemůžeš přidat robota.";
CzechErrors[FailureReasons.USER_IS_BANNED] = "Bl jsi vyhoštěn z této sítě.";
CzechErrors[FailureReasons.WRONG_BONUS_CODE_TYPE] = "WRONG_BONUS_CODE_TYPE.";
CzechErrors[FailureReasons.ILLEGAL_BONUS_CODE] = "ILLEGAL_BONUS_CODE.";
CzechErrors[FailureReasons.GAME_ID_SHOULD_BE_NUMBER] = "ID číslo Hry musí být číslo.";

var CzechSuccessDescriptions = {};

CzechSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_FORGOT_PASSWORD] = "Odeslali jsme ti email pro obnovu hesla.";
CzechSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_CONFIRM_EMAIL] = "Odeslali jsme emai pro potvrzení tvé adresy.";
CzechSuccessDescriptions[SuccessDescriptions.PASSWORD_CHANGED] = "Heslo změněno";
CzechSuccessDescriptions[SuccessDescriptions.KICK_SUCCESS] = "Vykopnutí úspěšné";
CzechSuccessDescriptions[SuccessDescriptions.PURCHASE_SUCCESS] = "Platba dokončena";
CzechSuccessDescriptions[SuccessDescriptions.EMAIL_CONFIRMED] = "Email potvrzen";
CzechSuccessDescriptions[SuccessDescriptions.REFUND_SUCCESS] = "Platba vrácena";
CzechSuccessDescriptions[SuccessDescriptions.ACTION_CODE_USED] = "Akční k=od úspěšně zaveden.";
CzechSuccessDescriptions[SuccessDescriptions.SUBSCRIPTION_UPDATED] = "Vaše předplatné bylo aktualizováno.";

var CzechTableRules = {};

CzechTableRules[TableRuleIds.PLAYERS_CAN_SEE_SPECTATOR_CHAT] = "Hráči mohou vidět chat pozorovatelů";
CzechTableRules[TableRuleIds.BANNED_CARDS] = "Zakázané karty";
CzechTableRules[TableRuleIds.REQUIRED_CARDS] = "Požadované karty";
CzechTableRules[TableRuleIds.USED_EXPANSIONS] = "Použité rozšíření";
CzechTableRules[TableRuleIds.RESTRICTIVE_PLAYER_RULES] = "Pouze určení uživatelé mohou hrát";
CzechTableRules[TableRuleIds.SPECTATE_RULES] = "Povolit pozorovatele";
CzechTableRules[TableRuleIds.MIN_RATING] = "Minimální hodnocení";
CzechTableRules[TableRuleIds.MAX_RATING] = "Maximální hodnocení";
CzechTableRules[TableRuleIds.RANDOM_SEED] = "Specifikovat náhodný SEED";
CzechTableRules[TableRuleIds.MIN_PLAYERS] = "Minimum hráčů";
CzechTableRules[TableRuleIds.MAX_PLAYERS] = "Maximum hráčů";
CzechTableRules[TableRuleIds.SPECIAL_KINGDOM_RULES] = "Speciální Královské pravidlo";
CzechTableRules[TableRuleIds.RESPECTED_CARD_LISTS] = "Respektovat oblíbené/neoblíbené/zakázané karty";
CzechTableRules[TableRuleIds.SHOW_VP_COUNTER] = "Ukazovat počítadlo VB";
CzechTableRules[TableRuleIds.UNDO_SETTINGS] = "Nastavení tlačítka Zpět";
CzechTableRules[TableRuleIds.ALWAYS_ALLOWED_PLAYER_RULES] = "ALWAYS_ALLOWED_PLAYER_RULES";
CzechTableRules[TableRuleIds.PLAYER_ORDER] = "Pořadí hráčů";
CzechTableRules[TableRuleIds.REPLAY_INSTRUCTIONS] = "Přehrát instrukce";
CzechTableRules[TableRuleIds.RATED_GAME] = "Hodnocená hra";
CzechTableRules[TableRuleIds.PREVIEW_GAME] = "Ukázka Zvěřince";
CzechTableRules[TableRuleIds.SCRIPTED_RULES] = "Speciální Královské pravidla";
CzechTableRules[TableRuleIds.CARD_POOL_LEVEL] = "Card Pool Level"; // TRANSLATE ME

var CzechActivities = {};

CzechActivities[ActivityStati.LOGGED_IN] = "Přihlášen";
CzechActivities[ActivityStati.PLAYING] = "Hraje";
CzechActivities[ActivityStati.SPECTATING] = "Pozoruje";
CzechActivities[ActivityStati.AT_TABLE_PLAYER] = "U stolu (hráč)";
CzechActivities[ActivityStati.AT_TABLE_SPECTATOR] = "U stolu (Pozorovatel)";
CzechActivities[ActivityStati.DISCONNECTED] = "Odhlášen";

var CzechTableStati = {};

CzechTableStati[TableStati.NEW] = "Nový";
CzechTableStati[TableStati.POST_GAME] = "Po hře";
CzechTableStati[TableStati.RUNNING] = "Běžící";
CzechTableStati[TableStati.ABANDONED] = "Opuštěn";

var CzechLobbyButtons = {
    DONE: "Hotovo",
    RESET: "Restartovat",
    CLEAR_SELECTION: "Vyčistit výběr",
    NEW_TABLE: "Nový stůl",
    START_GAME: "Začít hru",
    LEAVE_TABLE: "Opustit stůl",
    SELECT_KINGDOM: "Vybrat karty Království",
    LIST_BANNED: "Banned", // ** TRANSLATE PLEASE **
    LIST_DISLIKED: "Disliked", // ** TRANSLATE PLEASE **
    LIST_LIKED: "Liked", // ** TRANSLATE PLEASE **
    LIST_FAMILIAR: "Familiar", // ** TRANSLATE PLEASE **
    LOAD_FROM_END: "Load from End", // ** TRANSLATE PLEASE **    
    SELECT_COLONIES: "Kolonie: ",
    SELECT_SHELTERS: "Úkryty: "
};

var CzechLobbyTexts = {
    AUTOCOMPLETE_FAILED: "Pro výběr \"CARDS\" neodpovídají žádné karty.",
    ADD_CARDS_AUTOCOMPLETE: "Vlož karty pro přidání do tvého Království",
    BAN_CARDS_AUTOCOMPLETE: "Vlož karty pro zakázání výběru ve tvám Království",
    KINGDOM_OPTIONS: "Kingdom Options", // ** TRANSLATE PLEASE **
    GAME_OPTIONS: "Game Options", // ** TRANSLATE PLEASE **
    RESPECTED_CARDS: "Respected Cards", // ** TRANSLATE PLEASE **    
    RELATIVE_LEVEL: "Relative Level", // ** TRANSLATE PLEASE **
    LEVEL_INTERVAL: "${min} to ${max}", // ** TRANSLATE PLEASE **
    EMPTY_SLOT: "Empty Slot", // ** TRANSLATE PLEASE **
    REMOVE_SLOT: "Remove Slot", // ** TRANSLATE PLEASE **
    ADD_SLOT: "Add Slot", // ** TRANSLATE PLEASE **
    RANDOMIZE_PLAYER_ORDER: "Randomize Player Order", // ** TRANSLATE PLEASE **
    CHAT: "Chat", // ** TRANSLATE PLEASE **
    SELECT_CARDS: "Select Cards"
};

var CzechTernaryFieldTexts = {};

CzechTernaryFieldTexts[TernaryField.YES] = "Ano";
CzechTernaryFieldTexts[TernaryField.NO] = "Ne";
CzechTernaryFieldTexts[TernaryField.RANDOM] = "Náhodné";

var CzechTabs = {};

CzechTabs[LobbyTabs.INBOX] = "Přijaté";
CzechTabs[LobbyTabs.MATCH] = "Hledání";
CzechTabs[LobbyTabs.FRIEND_LIST] = "Seznam Přátel";
CzechTabs[LobbyTabs.TABLES] = "Stoly";
CzechTabs[LobbyTabs.NEW_TABLE] = "Nový stůl";
CzechTabs[LobbyTabs.MY_TABLE] = "Můj stůl";
CzechTabs[LobbyTabs.ACCOUNT] = "Účet";
CzechTabs[LobbyTabs.STORE] = "Obchod";
CzechTabs[LobbyTabs.OPTIONS] = "Nastavení";
CzechTabs[LobbyTabs.FAMILIAR_CARDS] = "Známé karty";
CzechTabs[LobbyTabs.LEADERBOARD] = "Výsledkové tabulky";
CzechTabs[LobbyTabs.CARDLISTS] = "Seznam karet";

var CzechMatchTypes = {};

CzechMatchTypes[AutomatchTypes.QUICK_MATCH] = "Jakýkoliv protihráč";
CzechMatchTypes[AutomatchTypes.GOOD_MATCH] = "Rovnocenný protihráč";
CzechMatchTypes[AutomatchTypes.MATCH_WITH_EXPANSIONS] = "Najít s rozšířením";
CzechMatchTypes[AutomatchTypes.MATCH_WITH_BOT] = "Počítačový protihráč";
CzechMatchTypes[AutomatchTypes.MATCH_UNRATED] = "Živý protihráč";

var CzechUndoSettings = {};

CzechUndoSettings[UndoSettings.NEVER] = "Nikdy";
CzechUndoSettings[UndoSettings.ASK] = "Ptát se";
CzechUndoSettings[UndoSettings.ALWAYS] = "Vždy";

var CzechGroupIds = {};

CzechGroupIds[GroupIds.EVERYBODY] = "Všichni";
CzechGroupIds[GroupIds.NOBODY] = "Nikdo";
CzechGroupIds[GroupIds.FRIENDS_OF] = "Přátelé";
CzechGroupIds[GroupIds.LIST_PLAYER_IDS] = "Vybraní hráči:";

var CzechCountries = {};

CzechCountries[Countries.NON_EU] = "Mimo EU";
CzechCountries[Countries.AT] = "Rakousko";
CzechCountries[Countries.BE] = "Belgie";
CzechCountries[Countries.BG] = "Bulharsko";
CzechCountries[Countries.HR] = "Chorvatsko";
CzechCountries[Countries.CY] = "Kypr";
CzechCountries[Countries.CZ] = "Česká Republika";
CzechCountries[Countries.DK] = "Dánsko";
CzechCountries[Countries.EE] = "Estonsko";
CzechCountries[Countries.FI] = "Finsko";
CzechCountries[Countries.FR] = "Francie";
CzechCountries[Countries.DE] = "Německo";
CzechCountries[Countries.GR] = "Řecko";
CzechCountries[Countries.HU] = "Maďarsko";
CzechCountries[Countries.IE] = "Irsko";
CzechCountries[Countries.IT] = "Itálie";
CzechCountries[Countries.LV] = "Lotyšsko";
CzechCountries[Countries.LT] = "Litva";
CzechCountries[Countries.LU] = "Luxembursko";
CzechCountries[Countries.MT] = "Malta";
CzechCountries[Countries.NL] = "Holandsko";
CzechCountries[Countries.PL] = "Polsko";
CzechCountries[Countries.PT] = "Portugalsko";
CzechCountries[Countries.RO] = "Rumunsko";
CzechCountries[Countries.SK] = "Slovensko";
CzechCountries[Countries.SI] = "Slovinsko";
CzechCountries[Countries.ES] = "Španělsko";
CzechCountries[Countries.SE] = "Švédsko";
CzechCountries[Countries.GB] = "Spojené Království";

var CzechAutomatchQuestions = {};
var CzechAutomatchValues = {};

CzechAutomatchQuestions[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS] = "Počet hráčů";
CzechAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS] = {};
CzechAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.REQUIRE] = "2";
CzechAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.EITHER] = "2 nebo 3";
CzechAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.REJECT] = "3";

CzechAutomatchQuestions[AUTOMATCH_QUESTION.RATED_GAME] = "Hodnocené hry";
CzechAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME] = {};
CzechAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.REQUIRE] = "Hodnocené";
CzechAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.EITHER] = "Hodnocené nebo Nehodnocené";
CzechAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.REJECT] = "Nehodnocené";

CzechAutomatchQuestions[AUTOMATCH_QUESTION.EXPANSIONS] = "Rozšíření";
CzechAutomatchValues[AUTOMATCH_QUESTION.EXPANSIONS] = {};
CzechAutomatchValues[AUTOMATCH_QUESTION.EXPANSIONS][MATCHING_PREFERENCE.REQUIRE] = "Čekání na rozšíření";
CzechAutomatchValues[AUTOMATCH_QUESTION.EXPANSIONS][MATCHING_PREFERENCE.EITHER] = "Upřednostněné rozšíření";
CzechAutomatchValues[AUTOMATCH_QUESTION.EXPANSIONS][MATCHING_PREFERENCE.REJECT] = "Pouze základní hra";

CzechAutomatchQuestions[AUTOMATCH_QUESTION.MENAGERIE_GAME] = "Zvěřinec";
CzechAutomatchValues[AUTOMATCH_QUESTION.MENAGERIE_GAME] = {};
CzechAutomatchValues[AUTOMATCH_QUESTION.MENAGERIE_GAME][MATCHING_PREFERENCE.REQUIRE] = "Ano";
CzechAutomatchValues[AUTOMATCH_QUESTION.MENAGERIE_GAME][MATCHING_PREFERENCE.EITHER] = "Ano nebo Ne";
CzechAutomatchValues[AUTOMATCH_QUESTION.MENAGERIE_GAME][MATCHING_PREFERENCE.REJECT] = "Ne";