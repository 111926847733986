"use strict";

var FrenchAbilityNames = {};

FrenchAbilityNames[AbilityNames.BUY] = "a";
FrenchAbilityNames[AbilityNames.PLAY_ATTACK] = "jouer une attaque";
FrenchAbilityNames[AbilityNames.DRAW_INSTRUCTIONS] = "b";
FrenchAbilityNames[AbilityNames.PLAY_INSTRUCTIONS] = "jouer";
FrenchAbilityNames[AbilityNames.RESOLVE_EVENT] = "c";
FrenchAbilityNames[AbilityNames.GAIN_BY_NAME] = "d";

FrenchAbilityNames[AbilityNames.FISHING_VILLAGE_ABILITY] = FrenchCardNames[CardNames.FISHING_VILLAGE].singular;
FrenchAbilityNames[AbilityNames.REMOVE_COST_REDUCTION] = "e";
FrenchAbilityNames[AbilityNames.HAVEN_ABILITY] = FrenchCardNames[CardNames.HAVEN].singular;
FrenchAbilityNames[AbilityNames.SAVE_RETURN_TO_HAND] = FrenchCardNames[CardNames.SAVE].singular;
FrenchAbilityNames[AbilityNames.CARAVAN_ABILITY] = FrenchCardNames[CardNames.CARAVAN].singular;
FrenchAbilityNames[AbilityNames.MERCHANT_SHIP_ABILITY] = FrenchCardNames[CardNames.MERCHANT_SHIP].singular;
FrenchAbilityNames[AbilityNames.OUTPOST_ABILITY] = FrenchCardNames[CardNames.OUTPOST].singular;
FrenchAbilityNames[AbilityNames.TACTICIAN_ABILITY] = FrenchCardNames[CardNames.TACTICIAN].singular;
FrenchAbilityNames[AbilityNames.WHARF_ABILITY] = FrenchCardNames[CardNames.WHARF].singular;
FrenchAbilityNames[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = FrenchCardNames[CardNames.HORSE_TRADERS].singular;
FrenchAbilityNames[AbilityNames.DUCHESS_MAY_GAIN] = FrenchCardNames[CardNames.DUCHESS].singular;
FrenchAbilityNames[AbilityNames.FOOLS_GOLD_TRASH] = FrenchCardNames[CardNames.FOOLS_GOLD].singular;
FrenchAbilityNames[AbilityNames.NOBLE_BRIGAND] = FrenchCardNames[CardNames.NOBLE_BRIGAND].singular;
FrenchAbilityNames[AbilityNames.HAGGLER_ABILITY] = FrenchCardNames[CardNames.HAGGLER].singular;
FrenchAbilityNames[AbilityNames.INN_ABILITY] = FrenchCardNames[CardNames.INN].singular;
FrenchAbilityNames[AbilityNames.MANDARIN] = FrenchCardNames[CardNames.MANDARIN].singular;
FrenchAbilityNames[AbilityNames.FARMLAND] = FrenchCardNames[CardNames.FARMLAND].singular;
FrenchAbilityNames[AbilityNames.GAIN_CHEAPER] = "Recevoir une carte moins chère";
FrenchAbilityNames[AbilityNames.GAIN_TWO_RUINS] = FrenchCardNames[CardNames.DEATH_CART].singular;
FrenchAbilityNames[AbilityNames.HUNTING_GROUNDS] = FrenchCardNames[CardNames.HUNTING_GROUNDS].singular;
FrenchAbilityNames[AbilityNames.SQUIRE_GAIN_ATTACK] = FrenchCardNames[CardNames.SQUIRE].singular;
FrenchAbilityNames[AbilityNames.URCHIN_TRANSFORM] = FrenchCardNames[CardNames.URCHIN].singular;
FrenchAbilityNames[AbilityNames.FEODUM_GAIN_SILVERS] = FrenchCardNames[CardNames.FEODUM].singular;
FrenchAbilityNames[AbilityNames.DOCTOR_OVERPAY] = "Surpayer le Docteur";
FrenchAbilityNames[AbilityNames.RESOLVE_DOCTOR_OVERPAY] = FrenchCardNames[CardNames.DOCTOR].singular;
FrenchAbilityNames[AbilityNames.HERALD_OVERPAY] = "Surpayer le Héraut";
FrenchAbilityNames[AbilityNames.RESOLVE_HERALD_OVERPAY] = FrenchCardNames[CardNames.HERALD].singular;
FrenchAbilityNames[AbilityNames.MASTERPIECE_OVERPAY] = "Surpayer le chef-d'œuvre";
FrenchAbilityNames[AbilityNames.RESOLVE_MASTERPIECE_OVERPAY] = FrenchCardNames[CardNames.MASTERPIECE].singular;
FrenchAbilityNames[AbilityNames.STONEMASON_OVERPAY] = "Surpayer le Tailleur de pierres";
FrenchAbilityNames[AbilityNames.RESOLVE_STONEMASON_OVERPAY] = FrenchCardNames[CardNames.STONEMASON].singular;
FrenchAbilityNames[AbilityNames.EXCHANGE] = "Échanger";
FrenchAbilityNames[AbilityNames.SHUFFLE] = "Mélanger";
FrenchAbilityNames[AbilityNames.AMULET] = FrenchCardNames[CardNames.AMULET].singular;
FrenchAbilityNames[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = FrenchCardNames[CardNames.CARAVAN_GUARD].singular;
FrenchAbilityNames[AbilityNames.DUNGEON_ABILITY] = FrenchCardNames[CardNames.DUNGEON].singular;
FrenchAbilityNames[AbilityNames.GEAR_ABILITY] = FrenchCardNames[CardNames.GEAR].singular;
FrenchAbilityNames[AbilityNames.MESSENGER_GAIN] = FrenchCardNames[CardNames.MESSENGER].singular;
FrenchAbilityNames[AbilityNames.BRIDGE_TROLL_ABILITY] = FrenchCardNames[CardNames.BRIDGE_TROLL].singular;
FrenchAbilityNames[AbilityNames.HAUNTED_WOODS_DURATION] = "Piocher (Bois hantés)";
FrenchAbilityNames[AbilityNames.HAUNTED_WOODS_TOPDECK] = "Mettre sur la pioche (Bois hantés)";
FrenchAbilityNames[AbilityNames.LOST_CITY] = FrenchCardNames[CardNames.LOST_CITY].singular;
FrenchAbilityNames[AbilityNames.WINE_MERCHANT_DISCARD] = FrenchCardNames[CardNames.WINE_MERCHANT].singular;
FrenchAbilityNames[AbilityNames.HIRELING] = FrenchCardNames[CardNames.HIRELING].singular;
FrenchAbilityNames[AbilityNames.SWAMP_HAG_DURATION] = FrenchCardNames[CardNames.SWAMP_HAG].singular;
FrenchAbilityNames[AbilityNames.TRASH_TOKEN_ABILITY] = FrenchCardNames[CardNames.PLAN].singular;
FrenchAbilityNames[AbilityNames.EMPORIUM_GAIN_POINTS] = FrenchCardNames[CardNames.EMPORIUM].singular;
FrenchAbilityNames[AbilityNames.ROCKS_GAINS_SILVER] = FrenchCardNames[CardNames.ROCKS].singular;
FrenchAbilityNames[AbilityNames.FORTUNE] = FrenchCardNames[CardNames.FORTUNE].singular;
FrenchAbilityNames[AbilityNames.CRUMBLING_CASTLE] = FrenchCardNames[CardNames.CRUMBLING_CASTLE].singular;
FrenchAbilityNames[AbilityNames.HAUNTED_CASTLE] = FrenchCardNames[CardNames.HAUNTED_CASTLE].singular;
FrenchAbilityNames[AbilityNames.SPRAWLING_CASTLE] = FrenchCardNames[CardNames.SPRAWLING_CASTLE].singular;
FrenchAbilityNames[AbilityNames.GRAND_CASTLE] = FrenchCardNames[CardNames.GRAND_CASTLE].singular;
FrenchAbilityNames[AbilityNames.ENCHANTRESS_ABILITY] = "+2 Cartes";
FrenchAbilityNames[AbilityNames.ENCHANTRESS_CANTRIP] = "+1 Carte, +1 Action";
FrenchAbilityNames[AbilityNames.TEMPLE] = FrenchCardNames[CardNames.TEMPLE].singular;
FrenchAbilityNames[AbilityNames.VILLA] = FrenchCardNames[CardNames.VILLA].singular;
FrenchAbilityNames[AbilityNames.ARCHIVE_ABILITY] = FrenchCardNames[CardNames.ARCHIVE].singular;
FrenchAbilityNames[AbilityNames.CAPITAL_TAKE_DEBT] = FrenchCardNames[CardNames.CAPITAL].singular;
FrenchAbilityNames[AbilityNames.CHARM] = FrenchCardNames[CardNames.CHARM].singular;
FrenchAbilityNames[AbilityNames.FORUM] = FrenchCardNames[CardNames.FORUM].singular;
FrenchAbilityNames[AbilityNames.HERMIT] = FrenchCardNames[CardNames.HERMIT].singular;
FrenchAbilityNames[AbilityNames.DONATE] = FrenchCardNames[CardNames.DONATE].singular;
FrenchAbilityNames[AbilityNames.TAX_RECEIVE] = FrenchCardNames[CardNames.TAX].singular;
FrenchAbilityNames[AbilityNames.DOMINATE] = FrenchCardNames[CardNames.DOMINATE].singular;
FrenchAbilityNames[AbilityNames.VP_TO_AQUEDUCT] = "Transférer les PV vers l'Aqueduc";
FrenchAbilityNames[AbilityNames.VP_FROM_AQUEDUCT] = "Prendre les PV depuis l'Aqueduc";
FrenchAbilityNames[AbilityNames.ARENA_DISCARD_ACTION] = FrenchCardNames[CardNames.ARENA].singular;
FrenchAbilityNames[AbilityNames.VP_FROM_BASILICA] = FrenchCardNames[CardNames.BASILICA].singular;
FrenchAbilityNames[AbilityNames.VP_FROM_BATHS] = FrenchCardNames[CardNames.BATHS].singular;
FrenchAbilityNames[AbilityNames.VP_FROM_BATTLEFIELD] = FrenchCardNames[CardNames.BATTLEFIELD].singular;
FrenchAbilityNames[AbilityNames.VP_FROM_COLONNADE] = FrenchCardNames[CardNames.COLONNADE].singular;
FrenchAbilityNames[AbilityNames.VP_TO_DEFILED_SHRINE] = "Transférer les PV vers la Profanation";
FrenchAbilityNames[AbilityNames.VP_FROM_DEFILED_SHRINE] = "Transférer les PV depuis la Profanation";
FrenchAbilityNames[AbilityNames.VP_FROM_LABYRINTH] = FrenchCardNames[CardNames.LABYRINTH].singular;
FrenchAbilityNames[AbilityNames.MOUNTAIN_PASS_BIDDING] = FrenchCardNames[CardNames.MOUNTAIN_PASS].singular;
FrenchAbilityNames[AbilityNames.TOMB] = FrenchCardNames[CardNames.TOMB].singular;
FrenchAbilityNames[AbilityNames.LIGHTHOUSE_COIN] = FrenchCardNames[CardNames.LIGHTHOUSE].singular;
FrenchAbilityNames[AbilityNames.REACTION] = "Réagir";
FrenchAbilityNames[AbilityNames.CALL_GUIDE] = "Recourir à " + FrenchCardNames[CardNames.GUIDE].singular;
FrenchAbilityNames[AbilityNames.CALL_RATCATCHER] = "Rec. à " + FrenchCardNames[CardNames.RATCATCHER].singular;
FrenchAbilityNames[AbilityNames.CALL_TRANSMOGRIFY] = "Rec. à " + FrenchCardNames[CardNames.TRANSMOGRIFY].singular;
FrenchAbilityNames[AbilityNames.CALL_TEACHER] = "Recourir à " + FrenchCardNames[CardNames.TEACHER].singular;
FrenchAbilityNames[AbilityNames.CALL_DUPLICATE] = "Recourir à " + FrenchCardNames[CardNames.DUPLICATE].singular;
FrenchAbilityNames[AbilityNames.CALL_COIN] = "Rec. à " + FrenchCardNames[CardNames.COIN_OF_THE_REALM].singular;
FrenchAbilityNames[AbilityNames.CALL_ROYAL_CARRIAGE] = "Rec. à " + FrenchCardNames[CardNames.ROYAL_CARRIAGE].singular;
FrenchAbilityNames[AbilityNames.START_OF_TURN] = "débuter votre tour";
FrenchAbilityNames[AbilityNames.START_BUY_PHASE] = "START_BUY_PHASE";
FrenchAbilityNames[AbilityNames.END_OF_BUY_PHASE] = "END_OF_BUY_PHASE";
FrenchAbilityNames[AbilityNames.BETWEEN_TURNS] = "BETWEEN_TURNS";
FrenchAbilityNames[AbilityNames.END_OF_TURN] = "END_OF_TURN";
FrenchAbilityNames[AbilityNames.SUMMON_PLAY] = FrenchCardNames[CardNames.SUMMON].singular;
FrenchAbilityNames[AbilityNames.CARAVAN_GUARD_REACTION] = FrenchCardNames[CardNames.CARAVAN_GUARD].singular;
FrenchAbilityNames[AbilityNames.TRAVELLING_FAIR_REACTION] = FrenchCardNames[CardNames.TRAVELLING_FAIR].singular;
FrenchAbilityNames[AbilityNames.MOAT_REACTION] = FrenchCardNames[CardNames.MOAT].singular;
FrenchAbilityNames[AbilityNames.HORSE_TRADERS_REACTION] = FrenchCardNames[CardNames.HORSE_TRADERS].singular;
FrenchAbilityNames[AbilityNames.BEGGAR_REACTION] = FrenchCardNames[CardNames.BEGGAR].singular;
FrenchAbilityNames[AbilityNames.MARKET_SQUARE_REACTION] = FrenchCardNames[CardNames.MARKET_SQUARE].singular;
FrenchAbilityNames[AbilityNames.TRADER_REACTION] = FrenchCardNames[CardNames.TRADER].singular;
FrenchAbilityNames[AbilityNames.DIPLOMAT_REACTION] = FrenchCardNames[CardNames.DIPLOMAT].singular;
FrenchAbilityNames[AbilityNames.TOPDECK_REACTION] = "Mettre sur la pioche";
FrenchAbilityNames[AbilityNames.WATCHTOWER_TRASH_REACTION] = "Écarter";
FrenchAbilityNames[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "Mettre sur la pioche";
FrenchAbilityNames[AbilityNames.PRINCE_PLAY] = FrenchCardNames[CardNames.PRINCE].singular;
FrenchAbilityNames[AbilityNames.PRINCE_SET_ASIDE] = FrenchCardNames[CardNames.PRINCE].singular;
FrenchAbilityNames[AbilityNames.DELUDED_RETURNED] = "retourner l'état";
FrenchAbilityNames[AbilityNames.ENVIOUS_RETURNED] = "retourner l'état";
FrenchAbilityNames[AbilityNames.RECEIVE_BOON] = "Appliquer une Aubaine";
FrenchAbilityNames[AbilityNames.COBBLER_ABILITY] = FrenchCardNames[CardNames.COBBLER].singular;
FrenchAbilityNames[AbilityNames.CRYPT_ABILITY] = FrenchCardNames[CardNames.CRYPT].singular;
FrenchAbilityNames[AbilityNames.DEN_OF_SIN_ABILITY] = FrenchCardNames[CardNames.DEN_OF_SIN].singular;
FrenchAbilityNames[AbilityNames.FAITHFUL_HOUND] = FrenchCardNames[CardNames.FAITHFUL_HOUND].singular;
FrenchAbilityNames[AbilityNames.FAITHFUL_HOUND_RETURN] = FrenchCardNames[CardNames.FAITHFUL_HOUND].singular;
FrenchAbilityNames[AbilityNames.GHOST_TOWN_ABILITY] = FrenchCardNames[CardNames.GHOST_TOWN].singular;
FrenchAbilityNames[AbilityNames.GUARDIAN_COIN] = FrenchCardNames[CardNames.GUARDIAN].singular;
FrenchAbilityNames[AbilityNames.RAIDER_ABILITY] = FrenchCardNames[CardNames.RAIDER].singular;
FrenchAbilityNames[AbilityNames.SECRET_CAVE] = FrenchCardNames[CardNames.SECRET_CAVE].singular;
FrenchAbilityNames[AbilityNames.CEMETERY] = FrenchCardNames[CardNames.CEMETERY].singular;
FrenchAbilityNames[AbilityNames.HAUNTED_MIRROR] = FrenchCardNames[CardNames.HAUNTED_MIRROR].singular;
FrenchAbilityNames[AbilityNames.GHOST] = FrenchCardNames[CardNames.GHOST].singular;
FrenchAbilityNames[AbilityNames.REMOVE_FLIPPED_STATUS] = "X";
FrenchAbilityNames[AbilityNames.LOST_IN_THE_WOODS] = FrenchCardNames[CardNames.LOST_IN_THE_WOODS].singular;
FrenchAbilityNames[AbilityNames.BLESSED_VILLAGE] = FrenchCardNames[CardNames.BLESSED_VILLAGE].singular;
FrenchAbilityNames[AbilityNames.FORTRESS] = FrenchCardNames[CardNames.FORTRESS].singular;
FrenchAbilityNames[AbilityNames.RECEIVE_HEX] = "Appliquer un Sortilège";
FrenchAbilityNames[AbilityNames.HOVEL_TRASH] = FrenchCardNames[CardNames.HOVEL].singular;
FrenchAbilityNames[AbilityNames.TUNNEL_REVEAL] = FrenchCardNames[CardNames.TUNNEL].singular;
FrenchAbilityNames[AbilityNames.CHANGELING_MAY_EXCHANGE] = "Changer en " + FrenchCardNames[CardNames.CHANGELING].singular;

FrenchAbilityNames[AbilityNames.DUCAT] = FrenchCardNames[CardNames.DUCAT].singular;
FrenchAbilityNames[AbilityNames.CARGO_SHIP_SET_ASIDE] = FrenchCardNames[CardNames.CARGO_SHIP].singular;
FrenchAbilityNames[AbilityNames.CARGO_SHIP_RETURN] = FrenchCardNames[CardNames.CARGO_SHIP].singular;
FrenchAbilityNames[AbilityNames.IMPROVE] = FrenchCardNames[CardNames.IMPROVE].singular;
FrenchAbilityNames[AbilityNames.PRIEST] = FrenchCardNames[CardNames.PRIEST].singular;
FrenchAbilityNames[AbilityNames.RESEARCH] = FrenchCardNames[CardNames.RESEARCH].singular;
FrenchAbilityNames[AbilityNames.SILK_MERCHANT] = FrenchCardNames[CardNames.SILK_MERCHANT].singular;
FrenchAbilityNames[AbilityNames.LACKEYS] = FrenchCardNames[CardNames.LACKEYS].singular;
FrenchAbilityNames[AbilityNames.SPICES] = FrenchCardNames[CardNames.SPICES].singular;
FrenchAbilityNames[AbilityNames.KEY] = FrenchCardNames[CardNames.KEY].singular;
FrenchAbilityNames[AbilityNames.TREASURE_CHEST] = FrenchCardNames[CardNames.TREASURE_CHEST].singular;
FrenchAbilityNames[AbilityNames.FLAG_BEARER] = FrenchCardNames[CardNames.FLAG_BEARER].singular;
FrenchAbilityNames[AbilityNames.CATHEDRAL] = FrenchCardNames[CardNames.CATHEDRAL].singular;
FrenchAbilityNames[AbilityNames.CITY_GATE] = FrenchCardNames[CardNames.CITY_GATE].singular;
FrenchAbilityNames[AbilityNames.PAGEANT] = FrenchCardNames[CardNames.PAGEANT].singular;
FrenchAbilityNames[AbilityNames.SEWERS_MAY_TRASH] = FrenchCardNames[CardNames.SEWERS].singular;
FrenchAbilityNames[AbilityNames.EXPLORATION] = FrenchCardNames[CardNames.EXPLORATION].singular;
FrenchAbilityNames[AbilityNames.FAIR] = FrenchCardNames[CardNames.FAIR].singular;
FrenchAbilityNames[AbilityNames.SILOS] = FrenchCardNames[CardNames.SILOS].singular;
FrenchAbilityNames[AbilityNames.ACADEMY] = FrenchCardNames[CardNames.ACADEMY].singular;
FrenchAbilityNames[AbilityNames.GUILDHALL] = FrenchCardNames[CardNames.GUILDHALL].singular;
FrenchAbilityNames[AbilityNames.PIAZZA] = FrenchCardNames[CardNames.PIAZZA].singular;
FrenchAbilityNames[AbilityNames.BARRACKS] = FrenchCardNames[CardNames.BARRACKS].singular;
FrenchAbilityNames[AbilityNames.CROP_ROTATION] = FrenchCardNames[CardNames.CROP_ROTATION].singular;
FrenchAbilityNames[AbilityNames.INNOVATION] = FrenchCardNames[CardNames.INNOVATION].singular;
FrenchAbilityNames[AbilityNames.CITADEL] = FrenchCardNames[CardNames.CITADEL].singular;
FrenchAbilityNames[AbilityNames.SINISTER_PLOT] = FrenchCardNames[CardNames.SINISTER_PLOT].singular;
FrenchAbilityNames[AbilityNames.ENCAMPMENT_RETURNED] = FrenchCardNames[CardNames.ENCAMPMENT].singular;
FrenchAbilityNames[AbilityNames.EMBARGO_GAIN_CURSE] = FrenchCardNames[CardNames.EMBARGO].singular;
FrenchAbilityNames[AbilityNames.POSSESSION_GAIN_INSTEAD] = FrenchCardNames[CardNames.POSSESSION].singular;
FrenchAbilityNames[AbilityNames.TRADE_ROUTE_MOVE_TOKEN] = FrenchCardNames[CardNames.TRADE_ROUTE].singular;
FrenchAbilityNames[AbilityNames.CAPTAIN] = FrenchCardNames[CardNames.CAPTAIN].singular;
FrenchAbilityNames[AbilityNames.CHURCH] = FrenchCardNames[CardNames.CHURCH].singular;
FrenchAbilityNames[AbilityNames.POSSESSION_SETS_ASIDE] = FrenchCardNames[CardNames.POSSESSION].singular;
FrenchAbilityNames[AbilityNames.MINT] = FrenchCardNames[CardNames.MINT].singular;

FrenchAbilityNames[AbilityNames.SLEIGH_TOPDECK_REACTION] = "Mettre sur la pioche";
FrenchAbilityNames[AbilityNames.SLEIGH_PUT_IN_HAND_REACTION] = "Prendre en main";
FrenchAbilityNames[AbilityNames.BLACK_CAT_REACTION] = FrenchCardNames[CardNames.BLACK_CAT].singular;
FrenchAbilityNames[AbilityNames.DISCARD_FROM_EXILE] = "Défausser depuis l'exil";
FrenchAbilityNames[AbilityNames.EXILE_BY_NAME] = "Exiler";
FrenchAbilityNames[AbilityNames.SHEEPDOG_REACTION] = FrenchCardNames[CardNames.SHEEPDOG].singular;
FrenchAbilityNames[AbilityNames.CAVALRY] = FrenchCardNames[CardNames.CAVALRY].singular;
FrenchAbilityNames[AbilityNames.HOSTELRY] = FrenchCardNames[CardNames.HOSTELRY].singular;
FrenchAbilityNames[AbilityNames.VILLAGE_GREEN_VILLAGE] = FrenchCardNames[CardNames.VILLAGE_GREEN].singular;
FrenchAbilityNames[AbilityNames.VILLAGE_GREEN_PLAY] = FrenchCardNames[CardNames.VILLAGE_GREEN].singular;
FrenchAbilityNames[AbilityNames.BARGE] = FrenchCardNames[CardNames.BARGE].singular;
FrenchAbilityNames[AbilityNames.FALCONER_REACTION] = FrenchCardNames[CardNames.FALCONER].singular;
FrenchAbilityNames[AbilityNames.GATEKEEPER_EXILE] = FrenchCardNames[CardNames.GATEKEEPER].singular;
FrenchAbilityNames[AbilityNames.GATEKEEPER_DURATION] = FrenchCardNames[CardNames.GATEKEEPER].singular;
FrenchAbilityNames[AbilityNames.LIVERY] = FrenchCardNames[CardNames.LIVERY].singular;
FrenchAbilityNames[AbilityNames.MASTERMIND] = FrenchCardNames[CardNames.MASTERMIND].singular;
FrenchAbilityNames[AbilityNames.DELAY_PLAY] = FrenchCardNames[CardNames.DELAY].singular;
FrenchAbilityNames[AbilityNames.INVEST] = FrenchCardNames[CardNames.INVEST].singular;
FrenchAbilityNames[AbilityNames.REAP_PLAY] = FrenchCardNames[CardNames.REAP].singular;
FrenchAbilityNames[AbilityNames.PLUS_CARD_TOKEN] = "+Carte";
FrenchAbilityNames[AbilityNames.PLUS_BUY_TOKEN] = "+Achat";
FrenchAbilityNames[AbilityNames.PLUS_ACTION_TOKEN] = "+Action";
FrenchAbilityNames[AbilityNames.PLUS_COIN_TOKEN] = "+Pièce";
FrenchAbilityNames[AbilityNames.KILN] = FrenchCardNames[CardNames.KILN].singular;
FrenchAbilityNames[AbilityNames.TURTLE_PLAY] = FrenchCardNames[CardNames.WAY_OF_THE_TURTLE].singular;
FrenchAbilityNames[AbilityNames.GIVE_PLUS_CARDS] = FrenchAbilityNames[AbilityNames.PLUS_CARD_TOKEN];
FrenchAbilityNames[AbilityNames.GIVE_PLUS_BUYS] = FrenchAbilityNames[AbilityNames.PLUS_BUY_TOKEN];
FrenchAbilityNames[AbilityNames.GIVE_PLUS_ACTIONS] = FrenchAbilityNames[AbilityNames.PLUS_ACTION_TOKEN];
FrenchAbilityNames[AbilityNames.GIVE_PLUS_COINS] = FrenchAbilityNames[AbilityNames.PLUS_COIN_TOKEN];

// Allies
FrenchAbilityNames[AbilityNames.CONJURER] = FrenchCardNames[CardNames.CONJURER].singular;
FrenchAbilityNames[AbilityNames.CONTRACT_ABILITY] = FrenchCardNames[CardNames.CONTRACT].singular;
FrenchAbilityNames[AbilityNames.ENCHANTRESS_REPLACE_ABILITY] = FrenchCardNames[CardNames.ENCHANTRESS].singular;
FrenchAbilityNames[AbilityNames.GALLERIA] = FrenchCardNames[CardNames.GALLERIA].singular;
FrenchAbilityNames[AbilityNames.GARRISON_ADD_TOKEN] = FrenchCardNames[CardNames.GARRISON].singular;
FrenchAbilityNames[AbilityNames.GARRISON_REMOVE_ABILITY] = FrenchCardNames[CardNames.GARRISON].singular;
FrenchAbilityNames[AbilityNames.GUILDMASTER] = FrenchCardNames[CardNames.GUILDMASTER].singular;
FrenchAbilityNames[AbilityNames.HIGHWAYMAN_NEXT_TURN_ABILITY] = FrenchCardNames[CardNames.HIGHWAYMAN].singular;
FrenchAbilityNames[AbilityNames.HIGHWAYMAN_NULLIFIED] = FrenchCardNames[CardNames.HIGHWAYMAN].singular;
FrenchAbilityNames[AbilityNames.HIGHWAYMAN_REPLACE_ABILITY] = FrenchCardNames[CardNames.HIGHWAYMAN].singular;
FrenchAbilityNames[AbilityNames.IMPORTER_ABILITY] = FrenchCardNames[CardNames.IMPORTER].singular;
FrenchAbilityNames[AbilityNames.LICH] = FrenchCardNames[CardNames.LICH].singular;
FrenchAbilityNames[AbilityNames.ROYAL_GALLEY_PLAY] = FrenchCardNames[CardNames.ROYAL_GALLEY].singular;
FrenchAbilityNames[AbilityNames.ROYAL_GALLEY_SET_ASIDE] = FrenchCardNames[CardNames.ROYAL_GALLEY].singular;
FrenchAbilityNames[AbilityNames.SKIRMISHER] = FrenchCardNames[CardNames.SKIRMISHER].singular;
FrenchAbilityNames[AbilityNames.STRONGHOLD_ABILITY] = FrenchCardNames[CardNames.STRONGHOLD].singular;
FrenchAbilityNames[AbilityNames.SYCOPHANT] = FrenchCardNames[CardNames.SYCOPHANT].singular;
FrenchAbilityNames[AbilityNames.TERRITORY_GAIN] = FrenchCardNames[CardNames.TERRITORY].singular;
FrenchAbilityNames[AbilityNames.WARLORD_ABILITY] = FrenchCardNames[CardNames.WARLORD].singular;

FrenchAbilityNames[AbilityNames.ARCHITECTS_GUILD] = FrenchCardNames[CardNames.ARCHITECTS_GUILD].singular;
FrenchAbilityNames[AbilityNames.BAND_OF_NOMADS_ACTION] = "Action";
FrenchAbilityNames[AbilityNames.BAND_OF_NOMADS_BUY] = "Achat";
FrenchAbilityNames[AbilityNames.BAND_OF_NOMADS_CARD] = "Carte";
FrenchAbilityNames[AbilityNames.CAVE_DWELLERS] = FrenchCardNames[CardNames.CAVE_DWELLERS].singular;
FrenchAbilityNames[AbilityNames.CIRCLE_OF_WITCHES] = FrenchCardNames[CardNames.CIRCLE_OF_WITCHES].singular;
FrenchAbilityNames[AbilityNames.CITY_STATE] = FrenchCardNames[CardNames.CITY_STATE].singular;
FrenchAbilityNames[AbilityNames.CITY_STATE_PLAY] = FrenchCardNames[CardNames.CITY_STATE].singular;
FrenchAbilityNames[AbilityNames.COASTAL_HAVEN] = FrenchCardNames[CardNames.COASTAL_HAVEN].singular;
FrenchAbilityNames[AbilityNames.CRAFTERS_GUILD] = FrenchCardNames[CardNames.CRAFTERS_GUILD].singular;
FrenchAbilityNames[AbilityNames.DESERT_GUIDES] = FrenchCardNames[CardNames.DESERT_GUIDES].singular;
FrenchAbilityNames[AbilityNames.FAMILY_OF_INVENTORS] = FrenchCardNames[CardNames.FAMILY_OF_INVENTORS].singular;
FrenchAbilityNames[AbilityNames.FELLOWSHIP_OF_SCRIBES] = FrenchCardNames[CardNames.FELLOWSHIP_OF_SCRIBES].singular;
FrenchAbilityNames[AbilityNames.FOREST_DWELLERS] = FrenchCardNames[CardNames.FOREST_DWELLERS].singular;
FrenchAbilityNames[AbilityNames.GANG_OF_PICKPOCKETS] = FrenchCardNames[CardNames.GANG_OF_PICKPOCKETS].singular;
FrenchAbilityNames[AbilityNames.ISLAND_FOLK] = FrenchCardNames[CardNames.ISLAND_FOLK].singular;
FrenchAbilityNames[AbilityNames.LEAGUE_OF_BANKERS] = FrenchCardNames[CardNames.LEAGUE_OF_BANKERS].singular;
FrenchAbilityNames[AbilityNames.LEAGUE_OF_SHOPKEEPERS] = FrenchCardNames[CardNames.LEAGUE_OF_SHOPKEEPERS].singular;
FrenchAbilityNames[AbilityNames.MARKET_TOWNS] = FrenchCardNames[CardNames.MARKET_TOWNS].singular;
FrenchAbilityNames[AbilityNames.MOUNTAIN_FOLK] = FrenchCardNames[CardNames.MOUNTAIN_FOLK].singular;
FrenchAbilityNames[AbilityNames.PEACEFUL_CULT] = FrenchCardNames[CardNames.PEACEFUL_CULT].singular;
FrenchAbilityNames[AbilityNames.TRAPPERS_LODGE] = FrenchCardNames[CardNames.TRAPPERS_LODGE].singular;
FrenchAbilityNames[AbilityNames.WOODWORKERS_GUILD] = FrenchCardNames[CardNames.WOODWORKERS_GUILD].singular;

FrenchAbilityNames[AbilityNames.ASTROLABE_ABILITY] = FrenchCardNames[CardNames.ASTROLABE].singular;
FrenchAbilityNames[AbilityNames.BLOCKADE_ABILITY] = FrenchCardNames[CardNames.BLOCKADE].singular;
FrenchAbilityNames[AbilityNames.CORSAIR_NEXT_TURN_ABILITY] = FrenchCardNames[CardNames.CORSAIR].singular;
FrenchAbilityNames[AbilityNames.CORSAIR_TRASH] = FrenchCardNames[CardNames.CORSAIR].singular;
FrenchAbilityNames[AbilityNames.MONKEY_NEXT_TURN_ABILITY] = FrenchCardNames[CardNames.MONKEY].singular;
FrenchAbilityNames[AbilityNames.PIRATE_ABILITY] = FrenchCardNames[CardNames.PIRATE].singular;
FrenchAbilityNames[AbilityNames.PIRATE_REACTION] = FrenchCardNames[CardNames.PIRATE].singular;
FrenchAbilityNames[AbilityNames.SAILOR_NEXT_TURN] = FrenchCardNames[CardNames.SAILOR].singular;
FrenchAbilityNames[AbilityNames.SAILOR_REACTION] = FrenchCardNames[CardNames.SAILOR].singular;
FrenchAbilityNames[AbilityNames.SEA_WITCH_ABILITY] = FrenchCardNames[CardNames.SEA_WITCH].singular;
FrenchAbilityNames[AbilityNames.TIDE_POOLS_ABILITY] = FrenchCardNames[CardNames.TIDE_POOLS].singular;
FrenchAbilityNames[AbilityNames.TREASURY] = FrenchCardNames[CardNames.TREASURY].singular;
FrenchAbilityNames[AbilityNames.CLERK_ABILITY] = FrenchCardNames[CardNames.CLERK].singular;
FrenchAbilityNames[AbilityNames.BERSERKER] = FrenchCardNames[CardNames.BERSERKER].singular;
FrenchAbilityNames[AbilityNames.CAULDRON_CURSING] = FrenchCardNames[CardNames.CAULDRON].singular;
FrenchAbilityNames[AbilityNames.GUARD_DOG_REACTION] = FrenchCardNames[CardNames.GUARD_DOG].singular;
FrenchAbilityNames[AbilityNames.NOMADS] = FrenchCardNames[CardNames.NOMADS].singular;
FrenchAbilityNames[AbilityNames.SOUK] = FrenchCardNames[CardNames.SOUK].singular;
FrenchAbilityNames[AbilityNames.TRAIL_REACTION] = FrenchCardNames[CardNames.TRAIL].singular;
FrenchAbilityNames[AbilityNames.WEAVER_PLAY] = FrenchCardNames[CardNames.WEAVER].singular;
FrenchAbilityNames[AbilityNames.ALCHEMIST_TOPDECK] = FrenchCardNames[CardNames.ALCHEMIST].singular;

FrenchAbilityNames[AbilityNames.MERCHANT_GUILD] = FrenchCardNames[CardNames.MERCHANT_GUILD].singular;
FrenchAbilityNames[AbilityNames.HERBALIST_TOPDECK] = FrenchCardNames[CardNames.HERBALIST].singular;
FrenchAbilityNames[AbilityNames.SCHEME_TOPDECK] = FrenchCardNames[CardNames.SCHEME].singular;
FrenchAbilityNames[AbilityNames.FROG_TOPDECK] = FrenchCardNames[CardNames.WAY_OF_THE_FROG].singular;
FrenchAbilityNames[AbilityNames.MERCHANT_CAMP_TOPDECK] = FrenchCardNames[CardNames.MERCHANT_CAMP].singular;
FrenchAbilityNames[AbilityNames.WALLED_VILLAGE_TOPDECK] = FrenchCardNames[CardNames.WALLED_VILLAGE].singular;
FrenchAbilityNames[AbilityNames.TENT_TOPDECK] = FrenchCardNames[CardNames.TENT].singular;
FrenchAbilityNames[AbilityNames.HORN_TOPDECK] = FrenchCardNames[CardNames.HORN].singular;
FrenchAbilityNames[AbilityNames.PAGE_EXCHANGE] = FrenchCardNames[CardNames.TREASURE_HUNTER].singular;
FrenchAbilityNames[AbilityNames.TREASURE_HUNTER_EXCHANGE] = FrenchCardNames[CardNames.WARRIOR].singular;
FrenchAbilityNames[AbilityNames.WARRIOR_EXCHANGE] = FrenchCardNames[CardNames.HERO].singular;
FrenchAbilityNames[AbilityNames.HERO_EXCHANGE] = FrenchCardNames[CardNames.CHAMPION].singular;
FrenchAbilityNames[AbilityNames.PEASANT_EXCHANGE] = FrenchCardNames[CardNames.SOLDIER].singular;
FrenchAbilityNames[AbilityNames.SOLDIER_EXCHANGE] = FrenchCardNames[CardNames.FUGITIVE].singular;
FrenchAbilityNames[AbilityNames.FUGITIVE_EXCHANGE] = FrenchCardNames[CardNames.DISCIPLE].singular;
FrenchAbilityNames[AbilityNames.DISCIPLE_EXCHANGE] = FrenchCardNames[CardNames.TEACHER].singular;

FrenchAbilityNames[AbilityNames.CAGE_TRASH] = FrenchCardNames[CardNames.CAGE].singular;
FrenchAbilityNames[AbilityNames.CAGE_PUT_IN_HAND] = FrenchCardNames[CardNames.CAGE].singular;
FrenchAbilityNames[AbilityNames.GROTTO] = FrenchCardNames[CardNames.GROTTO].singular;
FrenchAbilityNames[AbilityNames.JEWELLED_EGG_GAIN_LOOT] = FrenchCardNames[CardNames.JEWELLED_EGG].singular;
FrenchAbilityNames[AbilityNames.SEARCH] = FrenchCardNames[CardNames.SEARCH].singular;
FrenchAbilityNames[AbilityNames.SHAMAN] = FrenchCardNames[CardNames.SHAMAN].singular;
FrenchAbilityNames[AbilityNames.SECLUDED_SHRINE] = FrenchCardNames[CardNames.SECLUDED_SHRINE].singular;
FrenchAbilityNames[AbilityNames.SIREN_GAIN] = FrenchCardNames[CardNames.SIREN].singular;
FrenchAbilityNames[AbilityNames.SIREN_DURATION] = FrenchCardNames[CardNames.SIREN].singular;
FrenchAbilityNames[AbilityNames.STOWAWAY_DRAW] = FrenchCardNames[CardNames.STOWAWAY].singular;
FrenchAbilityNames[AbilityNames.STOWAWAY_REACTION] = FrenchCardNames[CardNames.STOWAWAY].singular;
FrenchAbilityNames[AbilityNames.TASKMASTER] = FrenchCardNames[CardNames.TASKMASTER].singular;
FrenchAbilityNames[AbilityNames.ABUNDANCE] = FrenchCardNames[CardNames.ABUNDANCE].singular;
FrenchAbilityNames[AbilityNames.CABIN_BOY] = FrenchCardNames[CardNames.CABIN_BOY].singular;
FrenchAbilityNames[AbilityNames.CRUCIBLE] = FrenchCardNames[CardNames.CRUCIBLE].singular;
FrenchAbilityNames[AbilityNames.FLAGSHIP_ACTIVATE] = FrenchCardNames[CardNames.FLAGSHIP].singular;
FrenchAbilityNames[AbilityNames.FLAGSHIP_REPLAY] = FrenchCardNames[CardNames.FLAGSHIP].singular;
FrenchAbilityNames[AbilityNames.GONDOLA_DURATION] = FrenchCardNames[CardNames.GONDOLA].singular;
FrenchAbilityNames[AbilityNames.GONDOLA_PLAY] = FrenchCardNames[CardNames.GONDOLA].singular;
FrenchAbilityNames[AbilityNames.HARBOR_VILLAGE] = FrenchCardNames[CardNames.HARBOR_VILLAGE].singular;
FrenchAbilityNames[AbilityNames.LANDING_PARTY] = FrenchCardNames[CardNames.LANDING_PARTY].singular;
FrenchAbilityNames[AbilityNames.MAPMAKER] = FrenchCardNames[CardNames.MAPMAKER].singular;
FrenchAbilityNames[AbilityNames.ROPE] = FrenchCardNames[CardNames.ROPE].singular;
FrenchAbilityNames[AbilityNames.BURIED_TREASURE_PLAY] = FrenchCardNames[CardNames.BURIED_TREASURE].singular;
FrenchAbilityNames[AbilityNames.BURIED_TREASURE_DURATION] = FrenchCardNames[CardNames.BURIED_TREASURE].singular;
FrenchAbilityNames[AbilityNames.CREW_DURATION] = FrenchCardNames[CardNames.CREW].singular;
FrenchAbilityNames[AbilityNames.CUTTHROAT_GAIN] = FrenchCardNames[CardNames.CUTTHROAT].singular;
FrenchAbilityNames[AbilityNames.ENLARGE] = FrenchCardNames[CardNames.ENLARGE].singular;
FrenchAbilityNames[AbilityNames.FRIGATE_DURATION] = FrenchCardNames[CardNames.FRIGATE].singular;
FrenchAbilityNames[AbilityNames.FRIGATE_DISCARD] = FrenchCardNames[CardNames.FRIGATE].singular;
FrenchAbilityNames[AbilityNames.LONGSHIP_DURATION] = FrenchCardNames[CardNames.LONGSHIP].singular;
FrenchAbilityNames[AbilityNames.MINING_ROAD] = FrenchCardNames[CardNames.MINING_ROAD].singular;
FrenchAbilityNames[AbilityNames.QUARTERMASTER] = FrenchCardNames[CardNames.QUARTERMASTER].singular;
FrenchAbilityNames[AbilityNames.TRICKSTER_REACTION] = FrenchCardNames[CardNames.TRICKSTER].singular;
FrenchAbilityNames[AbilityNames.TRICKSTER_RETURN_TO_HAND] = FrenchCardNames[CardNames.TRICKSTER].singular;
FrenchAbilityNames[AbilityNames.WEALTHY_VILLAGE] = FrenchCardNames[CardNames.WEALTHY_VILLAGE].singular;
FrenchAbilityNames[AbilityNames.DELIVER_SET_ASIDE] = FrenchCardNames[CardNames.DELIVER].singular;
FrenchAbilityNames[AbilityNames.DELIVER_RETURN] = FrenchCardNames[CardNames.DELIVER].singular;
FrenchAbilityNames[AbilityNames.RUSH] = FrenchCardNames[CardNames.RUSH].singular;
FrenchAbilityNames[AbilityNames.MIRROR] = FrenchCardNames[CardNames.MIRROR].singular;
FrenchAbilityNames[AbilityNames.PREPARE] = FrenchCardNames[CardNames.PREPARE].singular;
FrenchAbilityNames[AbilityNames.AMPHORA] = FrenchCardNames[CardNames.AMPHORA].singular;
FrenchAbilityNames[AbilityNames.DOUBLOONS] = FrenchCardNames[CardNames.DOUBLOONS].singular;
FrenchAbilityNames[AbilityNames.ENDLESS_CHALICE] = FrenchCardNames[CardNames.ENDLESS_CHALICE].singular;
FrenchAbilityNames[AbilityNames.FIGUREHEAD] = FrenchCardNames[CardNames.FIGUREHEAD].singular;
FrenchAbilityNames[AbilityNames.JEWELS] = FrenchCardNames[CardNames.JEWELS].singular;
FrenchAbilityNames[AbilityNames.PUZZLE_BOX_RETURN] = FrenchCardNames[CardNames.PUZZLE_BOX].singular;
FrenchAbilityNames[AbilityNames.SHIELD_REACTION] = FrenchCardNames[CardNames.SHIELD].singular;
FrenchAbilityNames[AbilityNames.SPELL_SCROLL] = FrenchCardNames[CardNames.SPELL_SCROLL].singular;
FrenchAbilityNames[AbilityNames.CURSED_GAIN] = FrenchCardNames[CardNames.CURSED].singular;
FrenchAbilityNames[AbilityNames.FAWNING_GAIN] = FrenchCardNames[CardNames.FAWNING].singular;
FrenchAbilityNames[AbilityNames.SCHEDULER] = FrenchCardNames[CardNames.FRIENDLY].singular;
FrenchAbilityNames[AbilityNames.FRIENDLY_ABILITY] = FrenchCardNames[CardNames.FRIENDLY].singular;
FrenchAbilityNames[AbilityNames.HASTY_SET_ASIDE] = FrenchCardNames[CardNames.HASTY].singular;
FrenchAbilityNames[AbilityNames.HASTY_PLAY] = FrenchCardNames[CardNames.HASTY].singular;
FrenchAbilityNames[AbilityNames.INHERITED] = FrenchCardNames[CardNames.INHERITED].singular;
FrenchAbilityNames[AbilityNames.INSPIRING] = FrenchCardNames[CardNames.INSPIRING].singular;
FrenchAbilityNames[AbilityNames.NEARBY] = FrenchCardNames[CardNames.NEARBY].singular;
FrenchAbilityNames[AbilityNames.PATIENT_SCHEDULER] = FrenchCardNames[CardNames.PATIENT].singular;
FrenchAbilityNames[AbilityNames.PATIENT_SET_ASIDE] = FrenchCardNames[CardNames.PATIENT].singular;
FrenchAbilityNames[AbilityNames.PATIENT_PLAY] = FrenchCardNames[CardNames.PATIENT].singular;
FrenchAbilityNames[AbilityNames.PIOUS] = FrenchCardNames[CardNames.PIOUS].singular;
FrenchAbilityNames[AbilityNames.RECKLESS_REPEAT] = FrenchCardNames[CardNames.RECKLESS].singular;
FrenchAbilityNames[AbilityNames.RECKLESS_RETURN] = FrenchCardNames[CardNames.RECKLESS].singular;
FrenchAbilityNames[AbilityNames.RICH_GAIN] = FrenchCardNames[CardNames.RICH].singular;
FrenchAbilityNames[AbilityNames.SHY] = FrenchCardNames[CardNames.SHY].singular;
FrenchAbilityNames[AbilityNames.TIRELESS_SET_ASIDE] = FrenchCardNames[CardNames.TIRELESS].singular;
FrenchAbilityNames[AbilityNames.TIRELESS_RETURN] = FrenchCardNames[CardNames.TIRELESS].singular;
FrenchAbilityNames[AbilityNames.STAR_CHART] = FrenchCardNames[CardNames.STAR_CHART].singular;
FrenchAbilityNames[AbilityNames.ORDER_OF_ASTROLOGERS] = FrenchCardNames[CardNames.ORDER_OF_ASTROLOGERS].singular;
FrenchAbilityNames[AbilityNames.ORDER_OF_MASONS] = FrenchCardNames[CardNames.ORDER_OF_MASONS].singular;
FrenchAbilityNames[AbilityNames.FATED] = FrenchCardNames[CardNames.FATED].singular;
FrenchAbilityNames[AbilityNames.AVOID] = FrenchCardNames[CardNames.AVOID].singular;
FrenchAbilityNames[AbilityNames.HERBALIST_ASK_FOR_DISCARD] = FrenchCardNames[CardNames.HERBALIST].singular;
FrenchAbilityNames[AbilityNames.SCHEME_ASK_FOR_DISCARD] = FrenchCardNames[CardNames.SCHEME].singular;
FrenchAbilityNames[AbilityNames.TRICKSTER_ASK_FOR_DISCARD] = FrenchCardNames[CardNames.TRICKSTER].singular;
FrenchAbilityNames[AbilityNames.MARCHLAND] = FrenchCardNames[CardNames.MARCHLAND].singular;
FrenchAbilityNames[AbilityNames.FARMHANDS_SET_ASIDE] = FrenchCardNames[CardNames.FARMHANDS].singular;
FrenchAbilityNames[AbilityNames.FARMHANDS_PLAY] = FrenchCardNames[CardNames.FARMHANDS].singular;
FrenchAbilityNames[AbilityNames.JOUST_DISCARD] = FrenchCardNames[CardNames.JOUST].singular;
FrenchAbilityNames[AbilityNames.INFIRMARY_OVERPAY] = FrenchCardNames[CardNames.INFIRMARY].singular;
FrenchAbilityNames[AbilityNames.RESOLVE_INFIRMARY_OVERPAY] = FrenchCardNames[CardNames.INFIRMARY].singular;
FrenchAbilityNames[AbilityNames.DRAW_FROM_FOOTPAD] = FrenchCardNames[CardNames.FOOTPAD].singular;
FrenchAbilityNames[AbilityNames.FARRIER_OVERPAY] = FrenchCardNames[CardNames.FARRIER].singular;
FrenchAbilityNames[AbilityNames.RESOLVE_FARRIER_OVERPAY] = FrenchCardNames[CardNames.FARRIER].singular;
FrenchAbilityNames[AbilityNames.DRAW_FROM_SQUIRREL] = FrenchCardNames[CardNames.WAY_OF_THE_SQUIRREL].singular;
FrenchAbilityNames[AbilityNames.DRAW_FROM_RIVER] = FrenchCardNames[CardNames.THE_RIVERS_GIFT].singular;
FrenchAbilityNames[AbilityNames.DRAW_FROM_FARRIER] = FrenchCardNames[CardNames.FARRIER].singular;
FrenchAbilityNames[AbilityNames.FERRYMAN] = FrenchCardNames[CardNames.FERRYMAN].singular;

FrenchAbilityNames[AbilityNames.BIDING_TIME_SET_ASIDE_HAND] = FrenchCardNames[CardNames.BIDING_TIME].singular;
FrenchAbilityNames[AbilityNames.BIDING_TIME_RETURN_TO_HAND] = FrenchCardNames[CardNames.BIDING_TIME].singular;
FrenchAbilityNames[AbilityNames.ENLIGHTENMENT_CANTRIP] = FrenchCardNames[CardNames.ENLIGHTENMENT].singular;
FrenchAbilityNames[AbilityNames.ENLIGHTENMENT_REPLACE_ABILITY] = FrenchCardNames[CardNames.ENLIGHTENMENT].singular;
FrenchAbilityNames[AbilityNames.GOOD_HARVEST] = FrenchCardNames[CardNames.GOOD_HARVEST].singular;
FrenchAbilityNames[AbilityNames.HARSH_WINTER] = FrenchCardNames[CardNames.HARSH_WINTER].singular;
FrenchAbilityNames[AbilityNames.KIND_EMPEROR] = FrenchCardNames[CardNames.KIND_EMPEROR].singular;
FrenchAbilityNames[AbilityNames.PANIC_BUYS] = FrenchCardNames[CardNames.PANIC].singular;
FrenchAbilityNames[AbilityNames.PANIC_RETURN] = FrenchCardNames[CardNames.PANIC].singular;
FrenchAbilityNames[AbilityNames.PROGRESS] = FrenchCardNames[CardNames.PROGRESS].singular;
FrenchAbilityNames[AbilityNames.RAPID_EXPANSION_SET_ASIDE] = FrenchCardNames[CardNames.RAPID_EXPANSION].singular;
FrenchAbilityNames[AbilityNames.RAPID_EXPANSION_PLAY_LATER] = FrenchCardNames[CardNames.RAPID_EXPANSION].singular;
FrenchAbilityNames[AbilityNames.SICKNESS] = FrenchCardNames[CardNames.SICKNESS].singular;
FrenchAbilityNames[AbilityNames.RIVER_SHRINE] = FrenchCardNames[CardNames.RIVER_SHRINE].singular;
FrenchAbilityNames[AbilityNames.SAMURAI] = FrenchCardNames[CardNames.SAMURAI].singular;
FrenchAbilityNames[AbilityNames.DAIMYO_REPLAY] = FrenchCardNames[CardNames.DAIMYO].singular;
FrenchAbilityNames[AbilityNames.FORESIGHT_RETURN_TO_HAND] = FrenchCardNames[CardNames.FORESIGHT].singular;