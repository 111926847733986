"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var InteractiveNonSupplyZone = function (_NonSupplyZone) {
    _inherits(InteractiveNonSupplyZone, _NonSupplyZone);

    function InteractiveNonSupplyZone() {
        _classCallCheck(this, InteractiveNonSupplyZone);

        return _possibleConstructorReturn(this, (InteractiveNonSupplyZone.__proto__ || Object.getPrototypeOf(InteractiveNonSupplyZone)).apply(this, arguments));
    }

    _createClass(InteractiveNonSupplyZone, [{
        key: "initialize",
        value: function initialize(cardIds) {
            var _this2 = this;

            var newStack = new CardStack(this.state.getAnonCard(this.pileName), this, CardSizes.MICRO);
            newStack.getCounter = function () {
                return newStack.cards.length + newStack.anonymousCards;
            };
            newStack.updateCount();
            newStack.addFilter("shifted-name");
            newStack.addFilter("empty");
            if (this.pileName === CardNames.REWARDS_PILE) {
                this.cardStacks[0].addFilter("force-stack-card");
            }
            cardIds.forEach(function (id) {
                if (id === -1) _this2.cardStacks[0].addAnonymousCard();else {
                    var card = _this2.state.cards[id];
                    _this2.cardStacks[0].addCard(card);
                }
            });
            this.reposition();
        }
    }, {
        key: "addCard",
        value: function addCard(card) {
            return this.cardStacks[0];
        }
    }, {
        key: "mergeStacks",
        value: function mergeStacks(targetStack, transitionStack) {
            transitionStack.cards.forEach(function (c) {
                return targetStack.addCard(c);
            });
            targetStack.anonymousCards += transitionStack.anonymousCards;
            transitionStack.destroy(targetStack);
            targetStack.removeFilter("intangible");
        }
    }, {
        key: "spliceCard",
        value: function spliceCard(card) {
            this.cardStacks[0].removeCard(card);
            var newStack = new CardStack(this.state.getAnonCard(card.cardName), this.state.nowhere);
            newStack.addCard(card);
            newStack.copyPosition(this.cardStacks[0]);
            return newStack;
        }
    }, {
        key: "spliceAnonymousCard",
        value: function spliceAnonymousCard() {
            this.cardStacks[0].removeCard(this.cardStacks[0].topCard);
            var newStack = new CardStack(this.state.getAnonCard(CardNames.BACK), this.state.nowhere);
            newStack.anonymousCards++;
            newStack.copyPosition(this.cardStacks[0]);
            if (this.cardStacks[0].cards.length === 0) this.cardStacks[0].addFilter("empty");
            return newStack;
        }
    }]);

    return InteractiveNonSupplyZone;
}(NonSupplyZone);