"use strict";

var DutchCardModes = {};

DutchCardModes[CardModes.PAWN_CARD_ACTION] = "Card, Action";
DutchCardModes[CardModes.PAWN_CARD_COIN] = "Card, Coin";
DutchCardModes[CardModes.PAWN_CARD_BUY] = "Card, Buy";
DutchCardModes[CardModes.PAWN_ACTION_COIN] = "Action, Coin";
DutchCardModes[CardModes.PAWN_ACTION_BUY] = "Action, Buy";
DutchCardModes[CardModes.PAWN_COIN_BUY] = "Coin, Buy";
DutchCardModes[CardModes.STEWARD_CARDS] = "+2 Cards";
DutchCardModes[CardModes.STEWARD_COINS] = "+2 Coins";
DutchCardModes[CardModes.STEWARD_TRASH] = "Trash 2";
DutchCardModes[CardModes.MINION_COINS] = "+2 Coins";
DutchCardModes[CardModes.MINION_NEWHAND] = "Discard";
DutchCardModes[CardModes.NOBLES_CARDS] = "+3 Cards";
DutchCardModes[CardModes.NOBLES_ACTIONS] = "+2 Actions";
DutchCardModes[CardModes.COURTIER_ACTION] = "Action";
DutchCardModes[CardModes.COURTIER_BUY] = "Buy";
DutchCardModes[CardModes.COURTIER_COINS] = "Coins";
DutchCardModes[CardModes.COURTIER_GOLD] = "Gain Gold";
DutchCardModes[CardModes.COURTIER_ACTION_BUY] = "Action, Buy";
DutchCardModes[CardModes.COURTIER_ACTION_COINS] = "Action, Coins";
DutchCardModes[CardModes.COURTIER_ACTION_GOLD] = "Action, Gold";
DutchCardModes[CardModes.COURTIER_BUY_COINS] = "Buy, Coins";
DutchCardModes[CardModes.COURTIER_BUY_GOLD] = "Buy, Gold";
DutchCardModes[CardModes.COURTIER_COINS_GOLD] = "Coins, Gold";
DutchCardModes[CardModes.COURTIER_ACTION_BUY_COINS] = "Action, Buy, Coins";
DutchCardModes[CardModes.COURTIER_ACTION_BUY_GOLD] = "Action, Buy, Gold";
DutchCardModes[CardModes.COURTIER_ACTION_COINS_GOLD] = "Action, Coins, Gold";
DutchCardModes[CardModes.COURTIER_BUY_COINS_GOLD] = "Buy, Coins, Gold";
DutchCardModes[CardModes.COURTIER_ALL] = "All";
DutchCardModes[CardModes.NATIVE_VILLAGE_SET_ASIDE] = "Set Aside";
DutchCardModes[CardModes.NATIVE_VILLAGE_RETURN] = "Pick Up";
DutchCardModes[CardModes.PIRATE_SHIP_RAID] = "Attack";
DutchCardModes[CardModes.PIRATE_SHIP_PROFIT] = "Profit";
DutchCardModes[CardModes.SCRYING_POOL_TOPDECK] = "Topdeck";
DutchCardModes[CardModes.SCRYING_POOL_DISCARD] = "Discard";
DutchCardModes[CardModes.LOAN_DISCARD] = "Discard";
DutchCardModes[CardModes.LOAN_TRASH] = "Trash";
DutchCardModes[CardModes.TRUSTY_STEED_CARDS_ACTIONS] = "Cards, Actions";
DutchCardModes[CardModes.TRUSTY_STEED_CARDS_COINS] = "Cards, Coins";
DutchCardModes[CardModes.TRUSTY_STEED_CARDS_SILVERS] = "Cards, Silvers";
DutchCardModes[CardModes.TRUSTY_STEED_ACTIONS_COINS] = "Actions, Coins";
DutchCardModes[CardModes.TRUSTY_STEED_ACTIONS_SILVERS] = "Actions, Silvers";
DutchCardModes[CardModes.TRUSTY_STEED_COINS_SILVERS] = "Coins, Silvers";
DutchCardModes[CardModes.JESTER_THEY_GAIN] = "Opponent Gains";
DutchCardModes[CardModes.JESTER_YOU_GAIN] = "You Gain";
DutchCardModes[CardModes.DUCHESS_KEEP] = "Keep";
DutchCardModes[CardModes.DUCHESS_DISCARD] = "Discard";
DutchCardModes[CardModes.ORACLE_TOPDECK] = "Topdeck";
DutchCardModes[CardModes.ORACLE_DISCARD] = "Discard";
DutchCardModes[CardModes.JACK_KEEP] = "Keep";
DutchCardModes[CardModes.JACK_DISCARD] = "Discard";
DutchCardModes[CardModes.SPICE_MERCHANT_CARDS] = "Cards, Action";
DutchCardModes[CardModes.SPICE_MERCHANT_COINS] = "Coins, Buy";
DutchCardModes[CardModes.CATACOMBS_TAKE] = "Put in Hand";
DutchCardModes[CardModes.CATACOMBS_DISCARD] = "Discard";
DutchCardModes[CardModes.COUNT_DISCARD] = "Discard";
DutchCardModes[CardModes.COUNT_TOPDECK] = "Topdeck";
DutchCardModes[CardModes.COUNT_GAIN_COPPER] = "Gain Copper";
DutchCardModes[CardModes.COUNT_COINS] = "+3 Coins";
DutchCardModes[CardModes.COUNT_TRASH_HAND] = "Trash Hand";
DutchCardModes[CardModes.COUNT_GAIN_DUCHY] = "Gain Duchy";
DutchCardModes[CardModes.HUNTING_GROUNDS_ESTATES] = "Gain 3 Estates";
DutchCardModes[CardModes.HUNTING_GROUNDS_DUCHY] = "Gain Duchy";
DutchCardModes[CardModes.IRONMONGER_KEEP] = "Keep";
DutchCardModes[CardModes.IRONMONGER_DISCARD] = "Discard";
DutchCardModes[CardModes.SCAVENGER_DISCARD] = "Discard";
DutchCardModes[CardModes.SCAVENGER_KEEP] = "Continue";
DutchCardModes[CardModes.SQUIRE_ACTIONS] = "+2 Actions";
DutchCardModes[CardModes.SQUIRE_BUYS] = "+2 Buys";
DutchCardModes[CardModes.SQUIRE_SILVER] = "Gain Silver";
DutchCardModes[CardModes.MERCENARY_DONT_TRASH] = "Don't Trash";
DutchCardModes[CardModes.DOCTOR_TRASH] = "Trash";
DutchCardModes[CardModes.DOCTOR_DISCARD] = "Discard";
DutchCardModes[CardModes.DOCTOR_TOPDECK] = "Topdeck";
DutchCardModes[CardModes.AMULET_COIN] = "+1 Coin";
DutchCardModes[CardModes.AMULET_TRASH] = "Trash";
DutchCardModes[CardModes.AMULET_SILVER] = "Gain Silver";
DutchCardModes[CardModes.MISER_COPPER_TO_MAT] = "Copper to Mat";
DutchCardModes[CardModes.MISER_PROFIT] = "Profit";
DutchCardModes[CardModes.MESSENGER_DISCARD] = "Discard";
DutchCardModes[CardModes.MESSENGER_KEEP] = "Keep";
DutchCardModes[CardModes.QUEST_ATTACK] = "Attack";
DutchCardModes[CardModes.QUEST_CURSES] = "2 Curses";
DutchCardModes[CardModes.QUEST_CARDS] = "6 Cards";
DutchCardModes[CardModes.TEACHER_CARD] = "Card";
DutchCardModes[CardModes.TEACHER_ACTION] = "Action";
DutchCardModes[CardModes.TEACHER_BUY] = "Buy";
DutchCardModes[CardModes.TEACHER_COIN] = "Coin";
DutchCardModes[CardModes.LIBRARY_DRAW_IT] = "Keep";
DutchCardModes[CardModes.LIBRARY_SKIP_IT] = "Skip";
DutchCardModes[CardModes.GLADIATOR_PRETEND] = "Don't Reveal";
DutchCardModes[CardModes.SPRAWLING_CASTLE_ESTATES] = "Gain 3 Estates";
DutchCardModes[CardModes.SPRAWLING_CASTLE_DUCHY] = "Gain Duchy";
DutchCardModes[CardModes.CHARM_BUY_MONEY] = "+Buy, +2 Coins";
DutchCardModes[CardModes.CHARM_COPY] = "Gain Card";
DutchCardModes[CardModes.WILD_HUNT_CARDS] = "Cards";
DutchCardModes[CardModes.WILD_HUNT_POINTS] = "Points";
DutchCardModes[CardModes.PRINCE_SET_ASIDE] = "Set Aside";
DutchCardModes[CardModes.PRINCE_NO] = "Continue";
DutchCardModes[CardModes.HAMLET_ACTION] = "Discard for Action";
DutchCardModes[CardModes.HAMLET_BUY] = "Discard for Buy";
DutchCardModes[CardModes.HAMLET_BOTH] = "Both";
DutchCardModes[CardModes.GOVERNOR_CARDS] = "+3 Cards";
DutchCardModes[CardModes.GOVERNOR_TREASURE] = "Gain Gold";
DutchCardModes[CardModes.GOVERNOR_REMODEL] = "Trash & Gain";
DutchCardModes[CardModes.BLESSED_VILLAGE_NOW] = "Receive now";
DutchCardModes[CardModes.BLESSED_VILLAGE_LATER] = "Next turn";
DutchCardModes[CardModes.PIXIE_TRASH] = "Trash";
DutchCardModes[CardModes.PIXIE_KEEP] = "Keep";
DutchCardModes[CardModes.SACRED_GROVE_RECEIVE] = "Receive it";
DutchCardModes[CardModes.SACRED_GROVE_NO] = "No thanks";
DutchCardModes[CardModes.ZOMBIE_SPY_DISCARD] = "Discard";
DutchCardModes[CardModes.ZOMBIE_SPY_TOPDECK] = "Topdeck";
DutchCardModes[CardModes.START_GAME] = "Start Game";
DutchCardModes[CardModes.PEARL_DIVER_TOPDECK] = DutchPhrases[Phrases.TOP];
DutchCardModes[CardModes.PEARL_DIVER_BOTTOMDECK] = DutchPhrases[Phrases.BOTTOM];
DutchCardModes[ExtraTurnTypes.MISSION] = DutchCardNames[CardNames.MISSION].singular;
DutchCardModes[ExtraTurnTypes.OUTPOST] = DutchCardNames[CardNames.OUTPOST].singular;
DutchCardModes[ExtraTurnTypes.POSSESSION] = DutchCardNames[CardNames.POSSESSION].singular;