"use strict";

var SpanishLogEntries = {};

SpanishLogEntries[LogEntryNames.GAIN] = "Argument0 gana Argument1.";
SpanishLogEntries[LogEntryNames.GAIN_WITH] = "Argument0 gana Argument1. (Argument2)";
SpanishLogEntries[LogEntryNames.BUY_AND_GAIN] = "Argument0 compra y gana Argument1.";
SpanishLogEntries[LogEntryNames.TRASH] = "Argument0 elimina Argument1.";
SpanishLogEntries[LogEntryNames.DISCARD] = "Argument0 descarta Argument1.";
SpanishLogEntries[LogEntryNames.DISCARD_WITH] = "Argument0 descarta Argument1. (Argument2)";
SpanishLogEntries[LogEntryNames.PLAY] = "Argument0 juega Argument1.";
SpanishLogEntries[LogEntryNames.PLAY_AGAIN] = "Argument0 juega Argument1 de nuevo.";
SpanishLogEntries[LogEntryNames.PLAY_THIRD] = "Argument0 juega Argument1 por tercera vez.";
SpanishLogEntries[LogEntryNames.TOPDECK] = "Argument0 pone Argument1 sobre el mazo.";
SpanishLogEntries[LogEntryNames.DRAW] = "Argument0 roba Argument1.";
SpanishLogEntries[LogEntryNames.DRAW_FROM] = "Argument0 roba Argument1. (Argument2)";
SpanishLogEntries[LogEntryNames.REVEAL] = "Argument0 revela Argument1.";
SpanishLogEntries[LogEntryNames.REVEAL_SEARCHING] = "Argument0 revela Argument1.";
SpanishLogEntries[LogEntryNames.REVEAL_FINDING] = "Argument0 revela Argument1.";
SpanishLogEntries[LogEntryNames.REVEAL_SEARCHING_AND_FINDING] = "Argument0 revela Argument1 y finalmente Argument2.";
SpanishLogEntries[LogEntryNames.REVEAL_HAND] = "Argument0 revela su mano: Argument1.";
SpanishLogEntries[LogEntryNames.REVEAL_DISCARD] = "Argument0 revela sus descartes: Argument1.";
SpanishLogEntries[LogEntryNames.LOOK_AT] = "Argument0 mira a Argument1.";
SpanishLogEntries[LogEntryNames.PUT_IN_HAND] = "Argument0 pone Argument1 en su mano.";
SpanishLogEntries[LogEntryNames.SET_ASIDE] = "Argument0 aparta Argument1.";
SpanishLogEntries[LogEntryNames.PUT_ON_MAT] = "Argument0 pone Argument1 en su Argument2.";
SpanishLogEntries[LogEntryNames.CALL] = "Argument0 convoca Argument1.";
SpanishLogEntries[LogEntryNames.DECK_TO_DISCARD] = "Argument0 mueve su mazo al descarte.";
SpanishLogEntries[LogEntryNames.BACK_ON_DECK] = "Argument0 pone Argument1 de vuelta sobre su mazo.";
SpanishLogEntries[LogEntryNames.SHUFFLE_INTO_DECK] = "Argument0 baraja Argument1 en su mazo.";
SpanishLogEntries[LogEntryNames.INSERT_IN_DECK] = "Argument0 inserta Argument1 en su mazo.";
SpanishLogEntries[LogEntryNames.EXCHANGE_RETURN] = "Argument0 devuelve Argument1.";
SpanishLogEntries[LogEntryNames.EXCHANGE_RECEIVE] = "Argument0 recibe Argument1.";
SpanishLogEntries[LogEntryNames.PASS] = "Argument0 pasa Argument1 a Argument2.";
SpanishLogEntries[LogEntryNames.STARTS_WITH] = "Argument0 comienza con Argument1.";
SpanishLogEntries[LogEntryNames.BUY] = "Argument0 compra Argument1.";
SpanishLogEntries[LogEntryNames.COUNTER_ADD] = "COUNTER_ADD";
SpanishLogEntries[LogEntryNames.COUNTER_RESET] = "COUNTER_RESET";
SpanishLogEntries[LogEntryNames.REACTS_WITH] = "Argument0 reacciona con Argument1.";
SpanishLogEntries[LogEntryNames.SHUFFLES] = "Argument0 baraja su mazo.";
SpanishLogEntries[LogEntryNames.NEW_TURN] = "Argument0";
SpanishLogEntries[LogEntryNames.WISH_CORRECT] = "Argument0 desea Argument1 y lo consigue.";
SpanishLogEntries[LogEntryNames.WISH_WRONG] = "Argument0 desea Argument1 pero revela Argument2.";
SpanishLogEntries[LogEntryNames.BLACK_MARKET_RESHUFFLE] = "Barajando el mazo de Mercado Negro.";
SpanishLogEntries[LogEntryNames.RETURN_TO] = "Argument0 devuelve Argument1 a Argument2.";
SpanishLogEntries[LogEntryNames.PUT_ON_BOTTOM_OF] = "Argument0 pone Argument1 en el fondo de Argument2.";
SpanishLogEntries[LogEntryNames.LOSE_TRACK_MOVED] = "Argument0 ya no puede mover Argument1 (ya se movió).";
SpanishLogEntries[LogEntryNames.LOSE_TRACK_HIDDEN] = "Argument0 ya no puede mover Argument1 (está cubierto).";
SpanishLogEntries[LogEntryNames.LOSE_TRACK_RESHUFFLED] = "Argument0 ya no puede mover Argument1 (fué barajado).";
SpanishLogEntries[LogEntryNames.LOSE_TRACK_ANONYMOUS] = "Argument0 ya no se puede mover.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_BANDIT_CAMP] = "Argument0.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_TOWER] = "Argument0.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH_ABSENT] = "No hay más de un tipo de carta de Acción.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH] = "Argument0.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_WALL] = "Argument0.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_WOLF_DEN] = "exactamente una copia de Argument0.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_PALACE] = "Argument0.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_ORCHARD] = "Argument0.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_OBELISK] = "Argument0 cartas de la pila de Argument1.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_MUSEUM] = "Argument0 cartas de distinto nombre.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_KINGS_CASTLE] = "Argument0 Castillos.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_KEEP] = "Argument0.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_HUMBLE_CASTLE] = "Argument0 Castillos.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_FOUNTAIN] = "Argument0.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_FEODUM] = "Argument0.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_GARDENS] = "Argument0.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_VINEYARD] = "Argument0 cartas de Acción.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_SILK_ROAD] = "Argument0 cartas de Victoria.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_FAIRGROUNDS] = "Argument0 cartas de distinto nombre.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_DUKE] = "Argument0.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_DUH] = "";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_VP_TOKENS] = "fichas de PV.";
SpanishLogEntries[LogEntryNames.PUT_IN_HAND_FROM] = "Argument0 pone Argument1 en la mano (Argument2).";
SpanishLogEntries[LogEntryNames.STARTS_TURN] = "Argument0 comienza su turno.";
SpanishLogEntries[LogEntryNames.TAKE_VP_FROM] = "Argument0 toma Argument1 PV de Argument2.";
SpanishLogEntries[LogEntryNames.MOVE_VP_FROM_TO] = "Argument0 mueve Argument1 PV from Argument2 to Argument3.";
SpanishLogEntries[LogEntryNames.OBELISK_CHOOSES_PILE] = "Obelisk chooses Argument0.";
SpanishLogEntries[LogEntryNames.PLAYER_MOVES_TOKEN_TO] = "Argument0 mueve Argument1 a Argument2.";
SpanishLogEntries[LogEntryNames.PLAYER_GETS_VP] = "Argument0 recibe Argument1 PV.";
SpanishLogEntries[LogEntryNames.PLAYER_GETS_VP_FROM] = "Argument0 recibe Argument1 PV de Argument2.";
SpanishLogEntries[LogEntryNames.ADDS_VP_TO] = "Argument0 añade Argument1 PV a Argument2.";
SpanishLogEntries[LogEntryNames.WAITING_FOR] = "Esperando a Argument0.";
SpanishLogEntries[LogEntryNames.ALMS_FAIL] = "Argument0 compra Limosna pero tiene Argument1 en juego.";
SpanishLogEntries[LogEntryNames.BORROW_FAIL] = "Argument0 compra Crédito pero ya tiene Argument1";
SpanishLogEntries[LogEntryNames.QUEST_FAIL_ATTACK] = "Argument0 no pudo descartar un Ataque.";
SpanishLogEntries[LogEntryNames.QUEST_FAIL_CURSES] = "Argument0 no pudo descartar Argument1.";
SpanishLogEntries[LogEntryNames.QUEST_FAIL_DISCARD] = "Argument0 no pudo descartar 6 cartas.";
SpanishLogEntries[LogEntryNames.SAVE_FAIL] = "Argument0 no tenía cartas para apartar.";
SpanishLogEntries[LogEntryNames.SCOUTING_PARTY_FAIL] = "Argument0 no tenía cartas para descartar o poner sobre el mazo.";
SpanishLogEntries[LogEntryNames.BONFIRE_FAIL] = "Argument0 no elimina nada.";
SpanishLogEntries[LogEntryNames.MISSION_SUCCESS] = "Argument0 juega un turno extra después de este.";
SpanishLogEntries[LogEntryNames.TRADE_FAIL] = "Argument0 no elimina nada.";
SpanishLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_DOWN] = "Argument0 pone Argument1 boca abajo.";
SpanishLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_UP] = "Argument0 pone Argument1 boca arriba.";
SpanishLogEntries[LogEntryNames.TRIUMPH_FAIL] = "Argument0 no pudo ganar Argument1.";
SpanishLogEntries[LogEntryNames.ADVANCE_FAIL] = "Argument0 no eliminó una Acción.";
SpanishLogEntries[LogEntryNames.TAX_ADD_DEBT] = "Argument0 añade Argument1 a Argument2.";
SpanishLogEntries[LogEntryNames.TAX_TAKE_DEBT] = "Argument0 coge Argument1 de Argument2.";
SpanishLogEntries[LogEntryNames.RITUAL_FAIL] = "Argument0 no pudo ganar Argument1.";
SpanishLogEntries[LogEntryNames.WINDFALL_FAIL] = "Argument0 no está vacía.";
SpanishLogEntries[LogEntryNames.DOMINATE_FAIL] = "Argument0 no pudo ganar Argument1.";
SpanishLogEntries[LogEntryNames.PLUS_ACTION_FROM] = "Argument0 recibe +1 Acción (from Argument1).";
SpanishLogEntries[LogEntryNames.PLUS_BUY_FROM] = "Argument0 recibe +1 Compra (from Argument1).";
SpanishLogEntries[LogEntryNames.DRAWS_FROM] = "Argument0 roba Argument1 (de Argument2).";
SpanishLogEntries[LogEntryNames.PLUS_COIN_FROM] = "Argument0 recibe +1 Moneda (de Argument1).";
SpanishLogEntries[LogEntryNames.DRAWS_LESS_BECAUSE] = "Argument0 no roba una carta (por Argument1).";
SpanishLogEntries[LogEntryNames.PRINCE_FAILS] = "";
SpanishLogEntries[LogEntryNames.POSSESSION_TAKES_COIN_TOKENS] = "Argument0 coge las fichas de Moneda en su lugar.";
SpanishLogEntries[LogEntryNames.POSSESSION_TAKES_DEBT_TOKENS] = "Argument0 coge la Deuda en su lugar.";
SpanishLogEntries[LogEntryNames.POSSESION_TAKES_VP_TOKENS] = "Argument0 coge las fichas de PV en su lugar.";
SpanishLogEntries[LogEntryNames.TAKES_DEBT] = "Argument0 coge Argument1 de Deuda.";
SpanishLogEntries[LogEntryNames.REPAYS_DEBT] = "Argument0 salda Argument1 de Deuda.";
SpanishLogEntries[LogEntryNames.REPAYS_SOME_DEBT] = "Argument0 paga Argument1 de Deuda (quedan Argument2).";
SpanishLogEntries[LogEntryNames.RETURNS_TOKEN] = "Argument0 devuelve Argument1 puesto por Argument2.";
SpanishLogEntries[LogEntryNames.SET_ASIDE_WITH] = "Argument0 aparta Argument1 con Argument2.";
SpanishLogEntries[LogEntryNames.BLOCKS_WITH] = "Argument0 bloquea con Argument1.";
SpanishLogEntries[LogEntryNames.NAMES] = "Argument0 nombra Argument1.";
SpanishLogEntries[LogEntryNames.GAIN_ON_DRAWPILE] = "Argument0 gana Argument1 sobre su mazo.";
SpanishLogEntries[LogEntryNames.GAIN_FROM_TRASH] = "Argument0 gana Argument1 de las cartas Eliminadas.";
SpanishLogEntries[LogEntryNames.OBELISK_FAILS] = "Obelisk no pudo seleccionar una pila Acciones del Suministro.";
SpanishLogEntries[LogEntryNames.INHERITS] = "Argument0 hereda Argument1.";
SpanishLogEntries[LogEntryNames.GAME_META_INFO] = "Argument0";
SpanishLogEntries[LogEntryNames.THE_SKYS_GIFT_FAIL] = "Argument0 no pudo descartar para [The Skys Gift]";
SpanishLogEntries[LogEntryNames.RECEIVES] = "Argument0 recibe Argument1.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_PASTURE] = "Argument0.";
SpanishLogEntries[LogEntryNames.TAKE] = "Argument0 coge Argument1.";
SpanishLogEntries[LogEntryNames.RETURN] = "Argument0 devuelve Argument1.";
SpanishLogEntries[LogEntryNames.THE_SUNS_GIFT_FAIL] = "[The Suns Gift] no tiene nada para descartar.";
SpanishLogEntries[LogEntryNames.DRUID_SELECTS_BOONS] = "[Druid] aparta Argument1.";
SpanishLogEntries[LogEntryNames.DISCARD_RECEIVABLE] = "Argument0 descarta Argument1.";
SpanishLogEntries[LogEntryNames.PLAY_ENCHANTED] = "Argument0 está hechizado por Argument1";
SpanishLogEntries[LogEntryNames.GAIN_ANOTHER_EXPERIMENT] = "Argument0 gana otro [Experiment]";
SpanishLogEntries[LogEntryNames.COFFERS_FROM_PATRON] = "Argument0 recibe un Cofre de [Patron].";
SpanishLogEntries[LogEntryNames.GETS_COFFER] = "Argument0 recibe +Argument1 Cofres.";
SpanishLogEntries[LogEntryNames.GETS_COFFERS] = "Argument0 recibe +Argument1 Cofres.";
SpanishLogEntries[LogEntryNames.GETS_COFFER_FROM] = "Argument0 recibe +Argument1 Cofres. (Argument2)";
SpanishLogEntries[LogEntryNames.GETS_COFFERS_FROM] = "Argument0 recibe +Argument1 Cofres. (Argument2)";
SpanishLogEntries[LogEntryNames.USES_COFFER] = "Argument0 usa Argument1 Cofres. (+$Argument1)";
SpanishLogEntries[LogEntryNames.USES_COFFERS] = "Argument0 usa Argument1 Cofres. (+$Argument1)";
SpanishLogEntries[LogEntryNames.GETS_VILLAGER] = "Argument0 recibe +Argument1 Aldeano.";
SpanishLogEntries[LogEntryNames.GETS_VILLAGERS] = "Argument0 recibe +Argument1 Aldeanos.";
SpanishLogEntries[LogEntryNames.GETS_VILLAGER_FROM] = "Argument0 recibe +Argument1 Aldeano. (Argument2)";
SpanishLogEntries[LogEntryNames.GETS_VILLAGERS_FROM] = "Argument0 recibe +Argument1 Aldeanos. (Argument2)";
SpanishLogEntries[LogEntryNames.USES_VILLAGER] = "Argument0 usa Argument1 Aldeano.";
SpanishLogEntries[LogEntryNames.USES_VILLAGERS] = "Argument0 usa Argument1 Aldeanos.";
SpanishLogEntries[LogEntryNames.BEGINS_BUYPHASE] = "Argument0 begins their buy phase."; // please translate
SpanishLogEntries[LogEntryNames.ENDS_BUYPHASE] = "Argument0 termina su fase de Compra.";
SpanishLogEntries[LogEntryNames.GETS_ACTION] = "Argument0 recibe +Argument1 Acción.";
SpanishLogEntries[LogEntryNames.GETS_ACTIONS] = "Argument0 recibe +Argument1 Acciones.";
SpanishLogEntries[LogEntryNames.GETS_ACTION_FROM] = "Argument0 recibe +Argument1 Acción. (Argument2)";
SpanishLogEntries[LogEntryNames.GETS_ACTIONS_FROM] = "Argument0 recibe +Argument1 Acciones. (Argument2)";
SpanishLogEntries[LogEntryNames.GETS_BUY] = "Argument0 recibe +Argument1 Compra.";
SpanishLogEntries[LogEntryNames.GETS_BUYS] = "Argument0 recibe +Argument1 Compras.";
SpanishLogEntries[LogEntryNames.GETS_BUY_FROM] = "Argument0 recibe +Argument1 Compra. (Argument2)";
SpanishLogEntries[LogEntryNames.GETS_BUYS_FROM] = "Argument0 recibe +Argument1 Compras. (Argument2)";
SpanishLogEntries[LogEntryNames.GETS_COIN] = "Argument0 recibe +$Argument1.";
SpanishLogEntries[LogEntryNames.GETS_COINS] = "Argument0 recibe +$Argument1.";
SpanishLogEntries[LogEntryNames.GETS_COIN_FROM] = "Argument0 recibe +$Argument1. (Argument2)";
SpanishLogEntries[LogEntryNames.GETS_COINS_FROM] = "Argument0 recibe +$Argument1. (Argument2)";
SpanishLogEntries[LogEntryNames.LOSES_COIN] = "Argument0 pierde Argument1 Moneda.";
SpanishLogEntries[LogEntryNames.LOSES_COINS] = "Argument0 pierde Argument1 Monedas.";
SpanishLogEntries[LogEntryNames.BOTTOMDECKS] = "Argument0 pone Argument1 en el fondo de su mazo.";
SpanishLogEntries[LogEntryNames.TRASH_WITH] = "Argument0 elimina Argument1. (Argument2)";
SpanishLogEntries[LogEntryNames.PRIEST_ACTIVATES] = "a partir de ahora, cuando elimines una carta, +$Argument0.";
SpanishLogEntries[LogEntryNames.PLAY_TREASURES_FOR] = "Argument0 juega Argument1. (+$Argument2)";
SpanishLogEntries[LogEntryNames.PLAY_TREASURES_AGAIN_FOR] = "Argument0 juega Argument1 de nuevo. (+$Argument2)";
SpanishLogEntries[LogEntryNames.RETURNS_MINUS_COIN_TOKEN] = "Argument0 devuelve Argument1 puesto por Argument2. (-$1)";
SpanishLogEntries[LogEntryNames.NOT_IN_PLAY_PREVENTED_PLAYING] = "Argument0 no estaba en juego, y no se pudo jugar.";
SpanishLogEntries[LogEntryNames.PILLAGE_FAIL] = "[Pillage] no fue eliminada, y no hace nada.";
SpanishLogEntries[LogEntryNames.DEATH_CART_FAIL] = "[Death Cart] no eliminó, y no hace nada.";
SpanishLogEntries[LogEntryNames.TACTICIAN_FAIL] = "[Tactician] no descartó, y no hace nada.";
SpanishLogEntries[LogEntryNames.EMBARGO_FAIL] = "[Embargo] no fue eliminada, y no hace nada.";
SpanishLogEntries[LogEntryNames.EXILE] = "Argument0 exilia Argument1.";
SpanishLogEntries[LogEntryNames.DISCARD_FROM_EXILE] = "Argument0 descarta Argument1 del Exilio.";
SpanishLogEntries[LogEntryNames.DISCARD_REVEALED] = "Argument0 descarta y revela Argument1.";
SpanishLogEntries[LogEntryNames.PLAY_WITH] = SpanishLogEntries[LogEntryNames.PLAY] + " (Argument2)";
SpanishLogEntries[LogEntryNames.PLAY_AGAIN_WITH] = SpanishLogEntries[LogEntryNames.PLAY] + " (Argument2)";
SpanishLogEntries[LogEntryNames.PLAY_THIRD_WITH] = SpanishLogEntries[LogEntryNames.PLAY] + " (Argument2)";
SpanishLogEntries[LogEntryNames.IS_INVESTED] = "Argument0 roba Argument2 cartas cuando un rival gana o invierte en Argument1.";
SpanishLogEntries[LogEntryNames.COMMERCE_FAIL] = "Argument0 no había ganado cartas.";
SpanishLogEntries[LogEntryNames.STAMPEDE_FAIL] = "Argument0 compró [Stampede] con Argument1 cartas en juego.";
SpanishLogEntries[LogEntryNames.PLAY_USING_WAY] = "Argument0 juega Argument1 usando Argument2.";
SpanishLogEntries[LogEntryNames.WAY_OF_THE_HORSE_FAILS_NO_PILE] = "Argument0 no tiene pila a la que volver.";
SpanishLogEntries[LogEntryNames.WAY_OF_THE_HORSE_FAILS_NOT_IN_PLAY] = "[Way Of The Horse] no puede mover Argument0, porque no está en juego.";
SpanishLogEntries[LogEntryNames.WAY_OF_THE_TURTLE_FAILS_TO_SET_ASIDE] = "[Way Of The Turtle] no puede apartar Argument0.";
SpanishLogEntries[LogEntryNames.USES_WAY] = "Argument0 usa Argument1.";
SpanishLogEntries[LogEntryNames.WOULD_GET_COIN] = "Argument0 recibiría +$Argument1.";
SpanishLogEntries[LogEntryNames.WOULD_DRAW] = "Argument0 robaría Argument1.";
SpanishLogEntries[LogEntryNames.TOPDECK_WITH] = "Argument0 pone Argument1 sobre su mazo con Argument2.";
SpanishLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_NO_PILE] = "Argument0 no tiene pila a la que volver.";
SpanishLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_NOT_IN_PLAY] = "[Way Of The Butterfly] no puede mover Argument0, porque no está en juego.";
SpanishLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_INTENTIONAL] = "Argument0 decidió no devolver Argument1 a su pila.";
SpanishLogEntries[LogEntryNames.INVESTMENT_FAIL] = "[Investment] can't trash itself because it's not in play.";
SpanishLogEntries[LogEntryNames.PLAY_NULLIFIED] = "Argument0 plays Argument1 (nullified by [Highwayman]).";
SpanishLogEntries[LogEntryNames.GETS_FAVOR] = "Argument0 gets +Argument1 Favor.";
SpanishLogEntries[LogEntryNames.GETS_FAVORS] = "Argument0 gets +Argument1 Favors.";
SpanishLogEntries[LogEntryNames.GETS_FAVOR_FROM] = "Argument0 gets +Argument1 Favor. (Argument2)";
SpanishLogEntries[LogEntryNames.GETS_FAVORS_FROM] = "Argument0 gets +Argument1 Favors. (Argument2)";
SpanishLogEntries[LogEntryNames.SPENDS_FAVOR] = "Argument0 spends Argument1 Favor.";
SpanishLogEntries[LogEntryNames.SPENDS_FAVORS] = "Argument0 spends Argument1 Favors.";
SpanishLogEntries[LogEntryNames.ROTATES_EMPTY_PILE] = "Argument0 rotates an empty pile";
SpanishLogEntries[LogEntryNames.ROTATES_IDENTICAL_PILE] = "Argument0 rotates a pile of identical cards.";
SpanishLogEntries[LogEntryNames.ROTATES] = "Argument0 rotates Argument1.";
SpanishLogEntries[LogEntryNames.ISLAND_FOLK] = "Argument0 takes an extra turn after this one.";
SpanishLogEntries[LogEntryNames.ROYAL_GALLEY_FAIL] = "[Royal Galley] fails to set aside.";
SpanishLogEntries[LogEntryNames.FAILS_TO_PLAY] = "Argument0 fails to play Argument1";
SpanishLogEntries[LogEntryNames.FAILS_TO_PLAY_WRONG_LOCATION] = "Argument0 fails to play Argument1 because it isn't in Argument2"; // example "Ingix fails to play a Village Green because it isn't in Discard pile"
SpanishLogEntries[LogEntryNames.STRANDS_CARDS] = "Argument0 strands Argument1";
SpanishLogEntries[LogEntryNames.VOYAGE_SUCCESS] = "Argument0 takes an extra Voyage turn after this one.";
SpanishLogEntries[LogEntryNames.GARRISON_ADDS_TOKEN] = "Argument0 adds a token to [Garrison], now at Argument1.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_PLATEAU_SHEPHERDS] = "Argument0 Cards, Argument1 Favors.";
SpanishLogEntries[LogEntryNames.PUT_IN_DISCARD] = "Argument0 puts Argument1 into their discard pile.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_TERRITORY] = "Argument0 differently named victory cards.";
SpanishLogEntries[LogEntryNames.SORCERESS_GUESS] = "Argument0 guesses Argument1.";
SpanishLogEntries[LogEntryNames.SORCERER_GUESS] = "Argument0 guesses Argument1.";
SpanishLogEntries[LogEntryNames.SKIPS_A_TURN_REMAINING] = "Argument0 skips a Turn (Argument1 remaining).";
SpanishLogEntries[LogEntryNames.SKIPS_A_TURN] = "Argument0 skips a Turn.";
SpanishLogEntries[LogEntryNames.CANCELS_A_TURN] = "Argument0 fails to take a Turn (from Argument1).";
SpanishLogEntries[LogEntryNames.WILL_SKIP_A_TURN] = "Argument0 will skip a Turn.";
SpanishLogEntries[LogEntryNames.WILL_SKIP_A_TURN_COUNT] = "Argument0 will skip a Turn (now Argument1).";
SpanishLogEntries[LogEntryNames.KEEPS_IN_HAND] = "Argument0 keeps Argument1 in hand.";

SpanishLogEntries[LogEntryNames.GAIN_SETTING_ASIDE] = "Argument0 gains Argument1 setting it aside.";
SpanishLogEntries[LogEntryNames.PLAYER_BIDS] = "Argument0 bids Argument1 debt";
SpanishLogEntries[LogEntryNames.PLAYER_ADDS_FAVOR_TOKEN_TO] = "Argument0 places a Favor token on Argument1. ([Family of Inventors])"; //please translate
SpanishLogEntries[LogEntryNames.PLAY_TREASURES_THIRD_FOR] = SpanishLogEntries[LogEntryNames.PLAY_THIRD] + " (+$Argument2)";

// Missing lines as of 09.01.2023 18:45:07
SpanishLogEntries[LogEntryNames.BEGINS_CLEANUP_PHASE] = "Argument0 begins their Cleanup phase.";
SpanishLogEntries[LogEntryNames.PREMOVES] = "Argument0 premoves Argument1.";
SpanishLogEntries[LogEntryNames.SPELL_SCROLL_FAILS] = "[Spell Scroll] didn't trash, so does nothing.";
SpanishLogEntries[LogEntryNames.PERIL_FAIL] = "[Peril] didn't trash, so does nothing.";
SpanishLogEntries[LogEntryNames.ACQUIRES_TRAIT] = "The Argument1 Trait is acquired by Argument0.";
SpanishLogEntries[LogEntryNames.TRAIT_FAILS] = "Argument0 fails to select an Action or Treasure pile.";
SpanishLogEntries[LogEntryNames.SHUFFLES_CARDS] = "Argument0 shuffles Argument1.";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_MARCHLAND] = "Argument0 Victory cards.";

SpanishLogEntries[LogEntryNames.FARMHANDS_FAIL] = "[Farmhands] failed to set aside a card";
SpanishLogEntries[LogEntryNames.FARMHANDS_REFUSE] = "[Farmhands] didn't set aside a card";
SpanishLogEntries[LogEntryNames.EXPLAIN_POINTS_DEMESNE] = "Argument0 Golds.";
SpanishLogEntries[LogEntryNames.REACTS_PLAYING] = "Argument0 reacts with Argument1, playing it.";

SpanishLogEntries[LogEntryNames.PLAY_ENLIGHTENED] = "Argument0 plays Argument1 for +1 Card +1 Action";
SpanishLogEntries[LogEntryNames.PANIC_FAILS] = "[Panic] fails to return Argument0";
SpanishLogEntries[LogEntryNames.PANIC_FAILS_NO_PILE] = "[Panic] can't return Argument0 without a pile.";
SpanishLogEntries[LogEntryNames.AMASS_FAILS] = "Argument0 buys [Amass] but has Argument1 in play.";