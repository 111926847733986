"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CardName = function () {
    function CardName(name, expansion, cost, types, isKingdomPile, sortingGroup) {
        var _this = this;

        var isFake = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;

        _classCallCheck(this, CardName);

        this.name = name;
        this.expansion = expansion;
        this.cost = cost;
        this.types = types;
        this._isKingdomPile = isKingdomPile;
        this.sortingGroup = sortingGroup;
        this.isFake = isFake;
        this.cardModes = [];

        this.toString = function () {
            return _this.name;
        };
        this.isBasicVictory = function () {
            return basicVictoryCards.indexOf(_this) > -1;
        };
        this.isVictory = function () {
            return _this.types.indexOf(Types.VICTORY) > -1;
        };
        this.isBaseCard = function () {
            return baseCards.indexOf(_this) > -1;
        };
        this.isAction = function () {
            return _this.types.indexOf(Types.ACTION) > -1;
        };
        this.isGathering = function () {
            return _this.types.indexOf(Types.GATHERING) > -1;
        };
        this.isBasicTreasure = function () {
            return basicTreasureCards.indexOf(_this) > -1;
        };
        this.isTreasure = function () {
            return _this.types.indexOf(Types.TREASURE) > -1;
        };
        this.isKingdomCard = function () {
            return !_this.isBaseCard() && !_this.cost.isSpecial;
        };
        this.isKingdomPile = function () {
            return _this._isKingdomPile;
        };
        this.isTraveller = function () {
            return _this.types.indexOf(Types.TRAVELLER) > -1;
        };
        this.isRuin = function () {
            return _this.types.indexOf(Types.RUINS) > -1;
        };
        this.isLooter = function () {
            return _this.types.indexOf(Types.LOOTER) > -1;
        };
        this.isKnight = function () {
            return _this.types.indexOf(Types.KNIGHT) > -1;
        };
        this.isCastle = function () {
            return _this.types.indexOf(Types.CASTLE) > -1;
        };
        this.isPrize = function () {
            return _this.types.indexOf(Types.PRIZE) > -1;
        };
        this.isReward = function () {
            return _this.types.indexOf(Types.REWARD) > -1;
        };
        this.isEvent = function () {
            return _this.types.indexOf(Types.EVENT) > -1;
        };
        this.isLandmark = function () {
            return _this.types.indexOf(Types.LANDMARK) > -1;
        };
        this.isReserve = function () {
            return _this.types.indexOf(Types.RESERVE) > -1;
        };
        this.isReaction = function () {
            return _this.types.indexOf(Types.REACTION) > -1;
        };
        this.isBoon = function () {
            return _this.types.indexOf(Types.BOON) > -1 || _this === CardNames.BOON_DRAWPILE;
        };
        this.isHex = function () {
            return _this.types.indexOf(Types.HEX) > -1 || _this === CardNames.HEX_DRAWPILE;
        };
        this.isState = function () {
            return _this.types.indexOf(Types.STATE) > -1;
        };
        this.isArtifact = function () {
            return _this.types.indexOf(Types.ARTIFACT) > -1;
        };
        this.isProject = function () {
            return _this.types.indexOf(Types.PROJECT) > -1;
        };
        this.isWay = function () {
            return _this.types.indexOf(Types.WAY) > -1;
        };
        this.isNight = function () {
            return _this.types.indexOf(Types.NIGHT) > -1;
        };
        this.isDuration = function () {
            return _this.types.indexOf(Types.DURATION) > -1;
        };
        this.isAlly = function () {
            return _this.types.indexOf(Types.ALLY) > -1;
        };
        this.isLiaison = function () {
            return _this.types.indexOf(Types.LIAISON) > -1;
        };
        this.isRandomizer = function () {
            return _this.types.indexOf(Types.RANDOMIZER) > -1;
        };
        this.isLoot = function () {
            return _this.types.indexOf(Types.LOOT) > -1;
        };
        this.isTrait = function () {
            return _this.types.indexOf(Types.TRAIT) > -1;
        };
        this.isProphecy = function () {
            return _this.types.indexOf(Types.PROPHECY) > -1;
        };
        this.hasDarkTextBox = function () {
            return _this.isNight();
        };
        this.hasDarkTitleBox = function () {
            return _this.isNight() && !(_this.isAction() || _this.isDuration());
        };
        this.isStandardLandscape = function () {
            return _this.isEvent() || _this.isLandmark() || _this.isProject() || _this.isWay() || _this.isAlly() || _this.isTrait() || _this.isProphecy();
        };
        this.isLandscape = function () {
            return _this.isStandardLandscape() || _this.isBoon() || _this.isHex() || _this.isState() || _this.isArtifact();
        };
        this.isBack = function () {
            return [CardNames.BACK, CardNames.BOON_DISCARDPILE, CardNames.HEX_DISCARDPILE].includes(_this);
        };
        this.hasHeirloom = function () {
            return HEIRLOOM_CARDS.includes(_this);
        };
        this.setCoinProduction = function (coinProduction) {
            return _this.coinProduction = coinProduction;
        };
        this.textColor = function () {
            return _this.hasDarkTextBox() ? '#DDD' : 'black';
        };
        this.titleColor = function () {
            return _this.hasDarkTitleBox() ? '#DDD' : 'black';
        };
        this.microCardTitleColor = function () {
            return _this.hasDarkTitleBox() ? '#DDD' : 'black';
        };
        this.shouldShowCost = function () {
            return !_this.isLandscape() || _this.isEvent() || _this.isProject();
        };
    }

    _createClass(CardName, [{
        key: 'serialize',
        value: function serialize(writer) {
            writer.writeInt(getOrdinal(CardNames, this));
        }
    }, {
        key: 'getCardColor',
        value: function getCardColor() {
            if (this.isVictory()) {
                return "#66CD00";
            } else if (this.isEvent()) {
                return "#c1acd5";
            } else if (this.isDuration()) {
                return "#FF9900";
            } else if (this.isReserve()) {
                return "#e6bf7f";
            } else if (this.isTreasure()) {
                return "#FFFF33";
            } else if (this.isReaction()) {
                return "#507EC5";
            } else if (this.types.indexOf(Types.CURSE) > -1) {
                return "#db4cdb";
            } else if (this.isHex()) {
                return "#6b61e7";
            } else if (this.isBoon()) {
                return "#e4e050";
            } else if (this.isState()) {
                return "#b95f3c";
            } else if (this.isProject()) {
                return "#e0958a";
            } else if (this.isArtifact()) {
                return "#c6936a";
            } else if (this.isLandmark()) {
                return "#268D00";
            } else if (this.isWay()) {
                return "#bbddff";
            }
            return "white";
        }
    }], [{
        key: 'parse',
        value: function parse(reader) {
            return getByOrdinal(CardNames, reader.readInt());
        }
    }]);

    return CardName;
}();

/*
    1: +2+ actions
    2: terminal draw
    3: cantrip
    4: terminals
    5: treasures
    6: special treasures
    7: night
    8: victory
    9: curses, ruins, things never in hand
 */

var CardNames = {
    BACK: new CardName("Back", Expansions.BASE, new Cost(0), [], false, 9),
    CURSE: new CardName("Curse", Expansions.BASE, new Cost(0), [Types.CURSE], false, 9),
    COPPER: new CardName("Copper", Expansions.BASE, new Cost(0), [Types.TREASURE], false, 5),
    SILVER: new CardName("Silver", Expansions.BASE, new Cost(3), [Types.TREASURE], false, 5),
    GOLD: new CardName("Gold", Expansions.BASE, new Cost(6), [Types.TREASURE], false, 5),
    ESTATE: new CardName("Estate", Expansions.BASE, new Cost(2), [Types.VICTORY], false, 8),
    DUCHY: new CardName("Duchy", Expansions.BASE, new Cost(5), [Types.VICTORY], false, 8),
    PROVINCE: new CardName("Province", Expansions.BASE, new Cost(8), [Types.VICTORY], false, 8),
    ARTISAN: new CardName("Artisan", Expansions.BASE, new Cost(6), [Types.ACTION], true, 4),
    BANDIT: new CardName("Bandit", Expansions.BASE, new Cost(5), [Types.ACTION, Types.ATTACK], true, 4),
    BUREAUCRAT: new CardName("Bureaucrat", Expansions.BASE, new Cost(4), [Types.ACTION, Types.ATTACK], true, 4),
    CELLAR: new CardName("Cellar", Expansions.BASE, new Cost(2), [Types.ACTION], true, 3),
    CHAPEL: new CardName("Chapel", Expansions.BASE, new Cost(2), [Types.ACTION], true, 4),
    COUNCIL_ROOM: new CardName("Council Room", Expansions.BASE, new Cost(5), [Types.ACTION], true, 2),
    FESTIVAL: new CardName("Festival", Expansions.BASE, new Cost(5), [Types.ACTION], true, 1),
    GARDENS: new CardName("Gardens", Expansions.BASE, new Cost(4), [Types.VICTORY], true, 8),
    HARBINGER: new CardName("Harbinger", Expansions.BASE, new Cost(3), [Types.ACTION], true, 3),
    LABORATORY: new CardName("Laboratory", Expansions.BASE, new Cost(5), [Types.ACTION], true, 2),
    LIBRARY: new CardName("Library", Expansions.BASE, new Cost(5), [Types.ACTION], true, 2),
    MARKET: new CardName("Market", Expansions.BASE, new Cost(5), [Types.ACTION], true, 3),
    MERCHANT: new CardName("Merchant", Expansions.BASE, new Cost(3), [Types.ACTION], true, 3),
    MILITIA: new CardName("Militia", Expansions.BASE, new Cost(4), [Types.ACTION, Types.ATTACK], true, 4),
    MINE: new CardName("Mine", Expansions.BASE, new Cost(5), [Types.ACTION], true, 4),
    MOAT: new CardName("Moat", Expansions.BASE, new Cost(2), [Types.ACTION, Types.REACTION], true, 2),
    MONEYLENDER: new CardName("Moneylender", Expansions.BASE, new Cost(4), [Types.ACTION], true, 4),
    POACHER: new CardName("Poacher", Expansions.BASE, new Cost(4), [Types.ACTION], true, 3),
    REMODEL: new CardName("Remodel", Expansions.BASE, new Cost(4), [Types.ACTION], true, 4),
    SENTRY: new CardName("Sentry", Expansions.BASE, new Cost(5), [Types.ACTION], true, 3),
    SMITHY: new CardName("Smithy", Expansions.BASE, new Cost(4), [Types.ACTION], true, 2),
    THRONE_ROOM: new CardName("Throne Room", Expansions.BASE, new Cost(4), [Types.ACTION], true, 0),
    VASSAL: new CardName("Vassal", Expansions.BASE, new Cost(3), [Types.ACTION], true, 4),
    VILLAGE: new CardName("Village", Expansions.BASE, new Cost(3), [Types.ACTION], true, 1),
    WITCH: new CardName("Witch", Expansions.BASE, new Cost(5), [Types.ACTION, Types.ATTACK], true, 2),
    WORKSHOP: new CardName("Workshop", Expansions.BASE, new Cost(3), [Types.ACTION], true, 4),

    COURTYARD: new CardName("Courtyard", Expansions.INTRIGUE, new Cost(2), [Types.ACTION], true, 2),
    CONSPIRATOR: new CardName("Conspirator", Expansions.INTRIGUE, new Cost(4), [Types.ACTION], true, 4),
    COURTIER: new CardName("Courtier", Expansions.INTRIGUE, new Cost(5), [Types.ACTION], true, 4),
    BARON: new CardName("Baron", Expansions.INTRIGUE, new Cost(4), [Types.ACTION], true, 4),
    BRIDGE: new CardName("Bridge", Expansions.INTRIGUE, new Cost(4), [Types.ACTION], true, 4),
    DIPLOMAT: new CardName("Diplomat", Expansions.INTRIGUE, new Cost(4), [Types.ACTION, Types.REACTION], true, 2),
    DUKE: new CardName("Duke", Expansions.INTRIGUE, new Cost(5), [Types.VICTORY], true, 8),
    FARM: new CardName("Farm", Expansions.INTRIGUE, new Cost(6), [Types.TREASURE, Types.VICTORY], true, 5),
    NOBLES: new CardName("Nobles", Expansions.INTRIGUE, new Cost(6), [Types.ACTION, Types.VICTORY], true, 2),
    IRONWORKS: new CardName("Ironworks", Expansions.INTRIGUE, new Cost(4), [Types.ACTION], true, 3),
    LURKER: new CardName("Lurker", Expansions.INTRIGUE, new Cost(2), [Types.ACTION], true, 3),
    MASQUERADE: new CardName("Masquerade", Expansions.INTRIGUE, new Cost(3), [Types.ACTION], true, 2),
    MILL: new CardName("Mill", Expansions.INTRIGUE, new Cost(4), [Types.ACTION, Types.VICTORY], true, 3),
    MINING_VILLAGE: new CardName("Mining Village", Expansions.INTRIGUE, new Cost(4), [Types.ACTION], true, 1),
    MINION: new CardName("Minion", Expansions.INTRIGUE, new Cost(5), [Types.ACTION, Types.ATTACK], true, 2),
    PATROL: new CardName("Patrol", Expansions.INTRIGUE, new Cost(5), [Types.ACTION], true, 2),
    PAWN: new CardName("Pawn", Expansions.INTRIGUE, new Cost(2), [Types.ACTION], true, 3),
    REPLACE: new CardName("Replace", Expansions.INTRIGUE, new Cost(5), [Types.ACTION, Types.ATTACK], true, 4),
    SECRET_PASSAGE: new CardName("Secret Passage", Expansions.INTRIGUE, new Cost(4), [Types.ACTION], true, 3),
    SHANTY_TOWN: new CardName("Shanty Town", Expansions.INTRIGUE, new Cost(3), [Types.ACTION], true, 1),
    STEWARD: new CardName("Steward", Expansions.INTRIGUE, new Cost(3), [Types.ACTION], true, 2),
    SWINDLER: new CardName("Swindler", Expansions.INTRIGUE, new Cost(3), [Types.ACTION, Types.ATTACK], true, 4),
    TORTURER: new CardName("Torturer", Expansions.INTRIGUE, new Cost(5), [Types.ACTION, Types.ATTACK], true, 2),
    TRADING_POST: new CardName("Trading Post", Expansions.INTRIGUE, new Cost(5), [Types.ACTION], true, 4),
    UPGRADE: new CardName("Upgrade", Expansions.INTRIGUE, new Cost(5), [Types.ACTION], true, 3),
    WISHING_WELL: new CardName("Wishing Well", Expansions.INTRIGUE, new Cost(3), [Types.ACTION], true, 3),

    AMBASSADOR: new CardName("Ambassador", Expansions.DEPRECATED, new Cost(3), [Types.ACTION, Types.ATTACK], true, 4),
    BAZAAR: new CardName("Bazaar", Expansions.SEASIDE, new Cost(5), [Types.ACTION], true, 1),
    CARAVAN: new CardName("Caravan", Expansions.SEASIDE, new Cost(4), [Types.ACTION, Types.DURATION], true, 3),
    CUTPURSE: new CardName("Cutpurse", Expansions.SEASIDE, new Cost(4), [Types.ACTION, Types.ATTACK], true, 4),
    EMBARGO: new CardName("Embargo", Expansions.DEPRECATED, new Cost(2), [Types.ACTION], true, 4),
    EXPLORER: new CardName("Explorer", Expansions.DEPRECATED, new Cost(5), [Types.ACTION], true, 4),
    FISHING_VILLAGE: new CardName("Fishing Village", Expansions.SEASIDE, new Cost(3), [Types.ACTION, Types.DURATION], true, 1),
    GHOST_SHIP: new CardName("Ghost Ship", Expansions.DEPRECATED, new Cost(5), [Types.ACTION, Types.ATTACK], true, 2),
    HAVEN: new CardName("Haven", Expansions.SEASIDE, new Cost(2), [Types.ACTION, Types.DURATION], true, 3),
    ISLAND: new CardName("Island", Expansions.SEASIDE, new Cost(4), [Types.ACTION, Types.VICTORY], true, 4),
    LIGHTHOUSE: new CardName("Lighthouse", Expansions.SEASIDE, new Cost(2), [Types.ACTION, Types.DURATION], true, 3),
    LOOKOUT: new CardName("Lookout", Expansions.SEASIDE, new Cost(3), [Types.ACTION], true, 3),
    MERCHANT_SHIP: new CardName("Merchant Ship", Expansions.SEASIDE, new Cost(5), [Types.ACTION, Types.DURATION], true, 4),
    NATIVE_VILLAGE: new CardName("Native Village", Expansions.SEASIDE, new Cost(2), [Types.ACTION], true, 1),
    NAVIGATOR: new CardName("Navigator", Expansions.DEPRECATED, new Cost(4), [Types.ACTION], true, 4),
    OUTPOST: new CardName("Outpost", Expansions.SEASIDE, new Cost(5), [Types.ACTION, Types.DURATION], true, 4),
    PEARL_DIVER: new CardName("Pearl Diver", Expansions.DEPRECATED, new Cost(2), [Types.ACTION], true, 3),
    PIRATE_SHIP: new CardName("Pirate Ship", Expansions.DEPRECATED, new Cost(4), [Types.ACTION, Types.ATTACK], true, 4),
    SALVAGER: new CardName("Salvager", Expansions.SEASIDE, new Cost(4), [Types.ACTION], true, 4),
    SEA_HAG: new CardName("Sea Hag", Expansions.DEPRECATED, new Cost(4), [Types.ACTION, Types.ATTACK], true, 4),
    SMUGGLERS: new CardName("Smugglers", Expansions.SEASIDE, new Cost(3), [Types.ACTION], true, 4),
    TACTICIAN: new CardName("Tactician", Expansions.SEASIDE, new Cost(5), [Types.ACTION, Types.DURATION], true, 4),
    TREASURE_MAP: new CardName("Treasure Map", Expansions.SEASIDE, new Cost(4), [Types.ACTION], true, 4),
    TREASURY: new CardName("Treasury", Expansions.SEASIDE, new Cost(5), [Types.ACTION], true, 3),
    WAREHOUSE: new CardName("Warehouse", Expansions.SEASIDE, new Cost(3), [Types.ACTION], true, 3),
    WHARF: new CardName("Wharf", Expansions.SEASIDE, new Cost(5), [Types.ACTION, Types.DURATION], true, 2),

    ALCHEMIST: new CardName("Alchemist", Expansions.ALCHEMY, new Cost(3, 1), [Types.ACTION], true, 2),
    APOTHECARY: new CardName("Apothecary", Expansions.ALCHEMY, new Cost(2, 1), [Types.ACTION], true, 3),
    APPRENTICE: new CardName("Apprentice", Expansions.ALCHEMY, new Cost(5), [Types.ACTION], true, 3),
    FAMILIAR: new CardName("Familiar", Expansions.ALCHEMY, new Cost(3, 1), [Types.ACTION, Types.ATTACK], true, 3),
    GOLEM: new CardName("Golem", Expansions.ALCHEMY, new Cost(4, 1), [Types.ACTION], true, 0),
    HERBALIST: new CardName("Herbalist", Expansions.ALCHEMY, new Cost(2), [Types.ACTION], true, 4),
    PHILOSOPHERS_STONE: new CardName("Philosophers Stone", Expansions.ALCHEMY, new Cost(3, 1), [Types.TREASURE], true, 6),
    POSSESSION: new CardName("Possession", Expansions.ALCHEMY, new Cost(6, 1), [Types.ACTION], true, 4),
    POTION: new CardName("Potion", Expansions.ALCHEMY, new Cost(4), [Types.TREASURE], false, 5),
    SCRYING_POOL: new CardName("Scrying Pool", Expansions.ALCHEMY, new Cost(2, 1), [Types.ACTION, Types.ATTACK], true, 2),
    TRANSMUTE: new CardName("Transmute", Expansions.ALCHEMY, new Cost(0, 1), [Types.ACTION], true, 4),
    UNIVERSITY: new CardName("University", Expansions.ALCHEMY, new Cost(2, 1), [Types.ACTION], true, 1),
    VINEYARD: new CardName("Vineyard", Expansions.ALCHEMY, new Cost(0, 1), [Types.VICTORY], true, 8),

    BANK: new CardName("Bank", Expansions.PROSPERITY, new Cost(7), [Types.TREASURE], true, 6),
    BISHOP: new CardName("Bishop", Expansions.PROSPERITY, new Cost(4), [Types.ACTION], true, 4),
    COLONY: new CardName("Colony", Expansions.PROSPERITY, new Cost(11), [Types.VICTORY], false, 8),
    CONTRABAND: new CardName("Contraband", Expansions.DEPRECATED, new Cost(5), [Types.TREASURE], true, 6),
    COUNTING_HOUSE: new CardName("Counting House", Expansions.DEPRECATED, new Cost(5), [Types.ACTION], true, 4),
    CITY: new CardName("City", Expansions.PROSPERITY, new Cost(5), [Types.ACTION], true, 1),
    EXPAND: new CardName("Expand", Expansions.PROSPERITY, new Cost(7), [Types.ACTION], true, 4),
    FORGE: new CardName("Forge", Expansions.PROSPERITY, new Cost(7), [Types.ACTION], true, 4),
    GRAND_MARKET: new CardName("Grand Market", Expansions.PROSPERITY, new Cost(6), [Types.ACTION], true, 3),
    GOONS: new CardName("Goons", Expansions.DEPRECATED, new Cost(6), [Types.ACTION, Types.ATTACK], true, 4),
    HOARD: new CardName("Hoard", Expansions.PROSPERITY, new Cost(6), [Types.TREASURE], true, 6),
    KINGS_COURT: new CardName("Kings Court", Expansions.PROSPERITY, new Cost(7), [Types.ACTION], true, 0),
    LOAN: new CardName("Loan", Expansions.DEPRECATED, new Cost(3), [Types.TREASURE], true, 6),
    MINT: new CardName("Mint", Expansions.PROSPERITY, new Cost(5), [Types.ACTION], true, 4),
    MONUMENT: new CardName("Monument", Expansions.PROSPERITY, new Cost(4), [Types.ACTION], true, 4),
    MOUNTEBANK: new CardName("Mountebank", Expansions.DEPRECATED, new Cost(5), [Types.ACTION, Types.ATTACK], true, 4),
    PEDDLER: new CardName("Peddler", Expansions.PROSPERITY, new Cost(8), [Types.ACTION], true, 3),
    PLATINUM: new CardName("Platinum", Expansions.PROSPERITY, new Cost(9), [Types.TREASURE], false, 5),
    QUARRY: new CardName("Quarry", Expansions.PROSPERITY, new Cost(4), [Types.TREASURE], true, 6),
    RABBLE: new CardName("Rabble", Expansions.PROSPERITY, new Cost(5), [Types.ACTION, Types.ATTACK], true, 2),
    ROYAL_SEAL: new CardName("Royal Seal", Expansions.DEPRECATED, new Cost(5), [Types.TREASURE], true, 5),
    TALISMAN: new CardName("Talisman", Expansions.DEPRECATED, new Cost(4), [Types.TREASURE], true, 6),
    TRADE_ROUTE: new CardName("Trade Route", Expansions.DEPRECATED, new Cost(3), [Types.ACTION], true, 4),
    VAULT: new CardName("Vault", Expansions.PROSPERITY, new Cost(5), [Types.ACTION], true, 2),
    VENTURE: new CardName("Venture", Expansions.DEPRECATED, new Cost(5), [Types.TREASURE], true, 6),
    WATCHTOWER: new CardName("Watchtower", Expansions.PROSPERITY, new Cost(3), [Types.ACTION, Types.REACTION], true, 2),
    WORKERS_VILLAGE: new CardName("Workers Village", Expansions.PROSPERITY, new Cost(4), [Types.ACTION], true, 1),

    PRIZE_PILE: new CardName("Prize Pile", Expansions.DEPRECATED, new Cost(0), [], false, 9, true),
    BAG_OF_GOLD: new CardName("Bag Of Gold", Expansions.DEPRECATED, new Cost(0), [Types.ACTION, Types.PRIZE], false, 3),
    DIADEM: new CardName("Diadem", Expansions.DEPRECATED, new Cost(0), [Types.TREASURE, Types.PRIZE], false, 5),
    FAIRGROUNDS: new CardName("Fairgrounds", Expansions.CORNUCOPIA_GUILDS, new Cost(6), [Types.VICTORY], true, 8),
    FARMING_VILLAGE: new CardName("Farming Village", Expansions.DEPRECATED, new Cost(4), [Types.ACTION], true, 1),
    FOLLOWERS: new CardName("Followers", Expansions.DEPRECATED, new Cost(0), [Types.ACTION, Types.ATTACK, Types.PRIZE], false, 2),
    FORTUNE_TELLER: new CardName("Fortune Teller", Expansions.DEPRECATED, new Cost(3), [Types.ACTION, Types.ATTACK], true, 4),
    HAMLET: new CardName("Hamlet", Expansions.CORNUCOPIA_GUILDS, new Cost(2), [Types.ACTION], true, 1),
    HARVEST: new CardName("Harvest", Expansions.DEPRECATED, new Cost(5), [Types.ACTION], true, 4),
    HORSE_TRADERS: new CardName("Horse Traders", Expansions.DEPRECATED, new Cost(4), [Types.ACTION, Types.REACTION], true, 4),
    HORN_OF_PLENTY: new CardName("Horn Of Plenty", Expansions.CORNUCOPIA_GUILDS, new Cost(5), [Types.TREASURE], true, 6),
    HUNTING_PARTY: new CardName("Hunting Party", Expansions.CORNUCOPIA_GUILDS, new Cost(5), [Types.ACTION], true, 2),
    JESTER: new CardName("Jester", Expansions.CORNUCOPIA_GUILDS, new Cost(5), [Types.ACTION, Types.ATTACK], true, 4),
    MENAGERIE: new CardName("Menagerie", Expansions.CORNUCOPIA_GUILDS, new Cost(3), [Types.ACTION], true, 2),
    PRINCESS: new CardName("Princess", Expansions.DEPRECATED, new Cost(0), [Types.ACTION, Types.PRIZE], false, 4),
    REMAKE: new CardName("Remake", Expansions.CORNUCOPIA_GUILDS, new Cost(4), [Types.ACTION], true, 4),
    TOURNAMENT: new CardName("Tournament", Expansions.DEPRECATED, new Cost(4), [Types.ACTION], true, 3),
    TRUSTY_STEED: new CardName("Trusty Steed", Expansions.DEPRECATED, new Cost(0), [Types.ACTION, Types.PRIZE], false, 1),
    YOUNG_WITCH: new CardName("Young Witch", Expansions.CORNUCOPIA_GUILDS, new Cost(4), [Types.ACTION, Types.ATTACK], true, 4),

    BORDER_VILLAGE: new CardName("Border Village", Expansions.HINTERLANDS, new Cost(6), [Types.ACTION], true, 1),
    CACHE: new CardName("Cache", Expansions.DEPRECATED, new Cost(5), [Types.TREASURE], true, 5),
    CARTOGRAPHER: new CardName("Cartographer", Expansions.HINTERLANDS, new Cost(5), [Types.ACTION], true, 3),
    CROSSROADS: new CardName("Crossroads", Expansions.HINTERLANDS, new Cost(2), [Types.ACTION], true, 1),
    DEVELOP: new CardName("Develop", Expansions.HINTERLANDS, new Cost(3), [Types.ACTION], true, 4),
    DUCHESS: new CardName("Duchess", Expansions.DEPRECATED, new Cost(2), [Types.ACTION], true, 4),
    EMBASSY: new CardName("Embassy", Expansions.DEPRECATED, new Cost(5), [Types.ACTION], true, 2),
    FARMLAND: new CardName("Farmland", Expansions.HINTERLANDS, new Cost(6), [Types.VICTORY], true, 8),
    FOOLS_GOLD: new CardName("Fools Gold", Expansions.HINTERLANDS, new Cost(2), [Types.TREASURE, Types.REACTION], true, 5),
    HAGGLER: new CardName("Haggler", Expansions.HINTERLANDS, new Cost(5), [Types.ACTION], true, 4),
    HIGHWAY: new CardName("Highway", Expansions.HINTERLANDS, new Cost(5), [Types.ACTION], true, 3),
    ILL_GOTTEN_GAINS: new CardName("Ill Gotten Gains", Expansions.DEPRECATED, new Cost(5), [Types.TREASURE], true, 6),
    INN: new CardName("Inn", Expansions.HINTERLANDS, new Cost(5), [Types.ACTION], true, 1),
    JACK_OF_ALL_TRADES: new CardName("Jack Of All Trades", Expansions.HINTERLANDS, new Cost(4), [Types.ACTION], true, 4),
    MANDARIN: new CardName("Mandarin", Expansions.DEPRECATED, new Cost(5), [Types.ACTION], true, 4),
    NOBLE_BRIGAND: new CardName("Noble Brigand", Expansions.DEPRECATED, new Cost(4), [Types.ACTION, Types.ATTACK], true, 4),
    NOMAD_CAMP: new CardName("Nomad Camp", Expansions.DEPRECATED, new Cost(4), [Types.ACTION], true, 4),
    OASIS: new CardName("Oasis", Expansions.HINTERLANDS, new Cost(3), [Types.ACTION], true, 3),
    ORACLE: new CardName("Oracle", Expansions.DEPRECATED, new Cost(3), [Types.ACTION, Types.ATTACK], true, 2),
    MARGRAVE: new CardName("Margrave", Expansions.HINTERLANDS, new Cost(5), [Types.ACTION, Types.ATTACK], true, 2),
    SCHEME: new CardName("Scheme", Expansions.HINTERLANDS, new Cost(3), [Types.ACTION], true, 3),
    SILK_ROAD: new CardName("Silk Road", Expansions.DEPRECATED, new Cost(4), [Types.VICTORY], true, 8),
    SPICE_MERCHANT: new CardName("Spice Merchant", Expansions.HINTERLANDS, new Cost(4), [Types.ACTION], true, 4),
    STABLES: new CardName("Stables", Expansions.HINTERLANDS, new Cost(5), [Types.ACTION], true, 2),
    TRADER: new CardName("Trader", Expansions.HINTERLANDS, new Cost(4), [Types.ACTION, Types.REACTION], true, 4),
    TUNNEL: new CardName("Tunnel", Expansions.HINTERLANDS, new Cost(3), [Types.VICTORY, Types.REACTION], true, 8),

    RUIN_PILE: new CardName("Ruin Pile", Expansions.DARK_AGES, new Cost(0), [Types.ACTION, Types.RUINS, Types.RANDOMIZER], true, 9, true),
    KNIGHTS: new CardName("Knights", Expansions.DARK_AGES, new Cost(5), [Types.ACTION, Types.ATTACK, Types.KNIGHT, Types.RANDOMIZER], true, 9),
    ABANDONED_MINE: new CardName("Abandoned Mine", Expansions.DARK_AGES, new Cost(0), [Types.ACTION, Types.RUINS], false, 9),
    ALTAR: new CardName("Altar", Expansions.DARK_AGES, new Cost(6), [Types.ACTION], true, 4),
    ARMORY: new CardName("Armory", Expansions.DARK_AGES, new Cost(4), [Types.ACTION], true, 4),
    BAND_OF_MISFITS: new CardName("Band Of Misfits", Expansions.DARK_AGES, new Cost(5), [Types.ACTION, Types.COMMAND], true, 4),
    BANDIT_CAMP: new CardName("Bandit Camp", Expansions.DARK_AGES, new Cost(5), [Types.ACTION], true, 1),
    BEGGAR: new CardName("Beggar", Expansions.DARK_AGES, new Cost(2), [Types.ACTION, Types.REACTION], true, 4),
    CATACOMBS: new CardName("Catacombs", Expansions.DARK_AGES, new Cost(5), [Types.ACTION], true, 2),
    COUNT: new CardName("Count", Expansions.DARK_AGES, new Cost(5), [Types.ACTION], true, 4),
    COUNTERFEIT: new CardName("Counterfeit", Expansions.DARK_AGES, new Cost(5), [Types.TREASURE], true, 6),
    CULTIST: new CardName("Cultist", Expansions.DARK_AGES, new Cost(5), [Types.ACTION, Types.ATTACK, Types.LOOTER], true, 2),
    DAME_ANNA: new CardName("Dame Anna", Expansions.DARK_AGES, new Cost(5), [Types.ACTION, Types.ATTACK, Types.KNIGHT], false, 4),
    DAME_JOSEPHINE: new CardName("Dame Josephine", Expansions.DARK_AGES, new Cost(5), [Types.ACTION, Types.ATTACK, Types.KNIGHT, Types.VICTORY], false, 4),
    DAME_MOLLY: new CardName("Dame Molly", Expansions.DARK_AGES, new Cost(5), [Types.ACTION, Types.ATTACK, Types.KNIGHT], false, 4),
    DAME_NATALIE: new CardName("Dame Natalie", Expansions.DARK_AGES, new Cost(5), [Types.ACTION, Types.ATTACK, Types.KNIGHT], false, 4),
    DAME_SYLVIA: new CardName("Dame Sylvia", Expansions.DARK_AGES, new Cost(5), [Types.ACTION, Types.ATTACK, Types.KNIGHT], false, 4),
    DEATH_CART: new CardName("Death Cart", Expansions.DARK_AGES, new Cost(4), [Types.ACTION, Types.LOOTER], true, 4),
    FEODUM: new CardName("Feodum", Expansions.DARK_AGES, new Cost(4), [Types.VICTORY], true, 8),
    FORAGER: new CardName("Forager", Expansions.DARK_AGES, new Cost(3), [Types.ACTION], true, 3),
    FORTRESS: new CardName("Fortress", Expansions.DARK_AGES, new Cost(4), [Types.ACTION], true, 1),
    GRAVEROBBER: new CardName("Graverobber", Expansions.DARK_AGES, new Cost(5), [Types.ACTION], true, 4),
    HERMIT: new CardName("Hermit", Expansions.DARK_AGES, new Cost(3), [Types.ACTION], true, 4),
    HOVEL: new CardName("Hovel", Expansions.DARK_AGES, new Cost(1), [Types.REACTION, Types.SHELTER], false, 8),
    HUNTING_GROUNDS: new CardName("Hunting Grounds", Expansions.DARK_AGES, new Cost(6), [Types.ACTION], true, 2),
    IRONMONGER: new CardName("Ironmonger", Expansions.DARK_AGES, new Cost(4), [Types.ACTION], true, 1),
    JUNK_DEALER: new CardName("Junk Dealer", Expansions.DARK_AGES, new Cost(5), [Types.ACTION], true, 3),
    MADMAN: new CardName("Madman", Expansions.DARK_AGES, new Cost(0), [Types.ACTION], false, 1),
    MARKET_SQUARE: new CardName("Market Square", Expansions.DARK_AGES, new Cost(3), [Types.ACTION, Types.REACTION], true, 3),
    MARAUDER: new CardName("Marauder", Expansions.DARK_AGES, new Cost(4), [Types.ACTION, Types.ATTACK, Types.LOOTER], true, 4),
    MERCENARY: new CardName("Mercenary", Expansions.DARK_AGES, new Cost(0), [Types.ACTION, Types.ATTACK], false, 2),
    MYSTIC: new CardName("Mystic", Expansions.DARK_AGES, new Cost(5), [Types.ACTION], true, 3),
    NECROPOLIS: new CardName("Necropolis", Expansions.DARK_AGES, new Cost(1), [Types.ACTION, Types.SHELTER], false, 1),
    OVERGROWN_ESTATE: new CardName("Overgrown Estate", Expansions.DARK_AGES, new Cost(1), [Types.VICTORY, Types.SHELTER], false, 8),
    PILLAGE: new CardName("Pillage", Expansions.DARK_AGES, new Cost(5), [Types.ACTION, Types.ATTACK], true, 4),
    POOR_HOUSE: new CardName("Poor House", Expansions.DARK_AGES, new Cost(1), [Types.ACTION], true, 4),
    PROCESSION: new CardName("Procession", Expansions.DARK_AGES, new Cost(4), [Types.ACTION], true, 0),
    RATS: new CardName("Rats", Expansions.DARK_AGES, new Cost(4), [Types.ACTION], true, 3),
    REBUILD: new CardName("Rebuild", Expansions.DARK_AGES, new Cost(5), [Types.ACTION], true, 3),
    ROGUE: new CardName("Rogue", Expansions.DARK_AGES, new Cost(5), [Types.ACTION, Types.ATTACK], true, 4),
    RUINED_LIBRARY: new CardName("Ruined Library", Expansions.DARK_AGES, new Cost(0), [Types.ACTION, Types.RUINS], false, 9),
    RUINED_MARKET: new CardName("Ruined Market", Expansions.DARK_AGES, new Cost(0), [Types.ACTION, Types.RUINS], false, 9),
    RUINED_VILLAGE: new CardName("Ruined Village", Expansions.DARK_AGES, new Cost(0), [Types.ACTION, Types.RUINS], false, 9),
    SAGE: new CardName("Sage", Expansions.DARK_AGES, new Cost(3), [Types.ACTION], true, 3),
    SCAVENGER: new CardName("Scavenger", Expansions.DARK_AGES, new Cost(4), [Types.ACTION], true, 4),
    SIR_BAILEY: new CardName("Sir Bailey", Expansions.DARK_AGES, new Cost(5), [Types.ACTION, Types.ATTACK, Types.KNIGHT], false, 4),
    SIR_DESTRY: new CardName("Sir Destry", Expansions.DARK_AGES, new Cost(5), [Types.ACTION, Types.ATTACK, Types.KNIGHT], false, 4),
    SIR_MARTIN: new CardName("Sir Martin", Expansions.DARK_AGES, new Cost(4), [Types.ACTION, Types.ATTACK, Types.KNIGHT], false, 4),
    SIR_MICHAEL: new CardName("Sir Michael", Expansions.DARK_AGES, new Cost(5), [Types.ACTION, Types.ATTACK, Types.KNIGHT], false, 4),
    SIR_VANDER: new CardName("Sir Vander", Expansions.DARK_AGES, new Cost(5), [Types.ACTION, Types.ATTACK, Types.KNIGHT], false, 4),
    SPOILS: new CardName("Spoils", Expansions.DARK_AGES, new Cost(0), [Types.TREASURE], false, 6),
    STOREROOM: new CardName("Storeroom", Expansions.DARK_AGES, new Cost(3), [Types.ACTION], true, 4),
    SQUIRE: new CardName("Squire", Expansions.DARK_AGES, new Cost(2), [Types.ACTION], true, 1),
    SURVIVORS: new CardName("Survivors", Expansions.DARK_AGES, new Cost(0), [Types.ACTION, Types.RUINS], false, 9),
    URCHIN: new CardName("Urchin", Expansions.DARK_AGES, new Cost(3), [Types.ACTION, Types.ATTACK], true, 3),
    VAGRANT: new CardName("Vagrant", Expansions.DARK_AGES, new Cost(2), [Types.ACTION], true, 3),
    WANDERING_MINSTREL: new CardName("Wandering Minstrel", Expansions.DARK_AGES, new Cost(4), [Types.ACTION], true, 1),

    ADVISOR: new CardName("Advisor", Expansions.CORNUCOPIA_GUILDS, new Cost(4), [Types.ACTION], true, 2),
    BAKER: new CardName("Baker", Expansions.CORNUCOPIA_GUILDS, new Cost(5), [Types.ACTION], true, 3),
    BUTCHER: new CardName("Butcher", Expansions.CORNUCOPIA_GUILDS, new Cost(5), [Types.ACTION], true, 4),
    CANDLESTICK_MAKER: new CardName("Candlestick Maker", Expansions.CORNUCOPIA_GUILDS, new Cost(2), [Types.ACTION], true, 3),
    DOCTOR: new CardName("Doctor", Expansions.DEPRECATED, new Cost(3), [Types.ACTION], true, 4),
    HERALD: new CardName("Herald", Expansions.CORNUCOPIA_GUILDS, new Cost(4), [Types.ACTION], true, 1),
    JOURNEYMAN: new CardName("Journeyman", Expansions.CORNUCOPIA_GUILDS, new Cost(5), [Types.ACTION], true, 2),
    MASTERPIECE: new CardName("Masterpiece", Expansions.DEPRECATED, new Cost(3), [Types.TREASURE], true, 5),
    MERCHANT_GUILD: new CardName("Merchant Guild", Expansions.CORNUCOPIA_GUILDS, new Cost(5), [Types.ACTION], true, 4),
    PLAZA: new CardName("Plaza", Expansions.CORNUCOPIA_GUILDS, new Cost(4), [Types.ACTION], true, 1),
    TAXMAN: new CardName("Taxman", Expansions.DEPRECATED, new Cost(4), [Types.ACTION, Types.ATTACK], true, 4),
    SOOTHSAYER: new CardName("Soothsayer", Expansions.CORNUCOPIA_GUILDS, new Cost(5), [Types.ACTION, Types.ATTACK], true, 4),
    STONEMASON: new CardName("Stonemason", Expansions.CORNUCOPIA_GUILDS, new Cost(2), [Types.ACTION], true, 4),

    ALMS: new CardName("Alms", Expansions.ADVENTURES, new Cost(0), [Types.EVENT], true, 9),
    AMULET: new CardName("Amulet", Expansions.ADVENTURES, new Cost(3), [Types.ACTION, Types.DURATION], true, 4),
    ARTIFICER: new CardName("Artificer", Expansions.ADVENTURES, new Cost(5), [Types.ACTION], true, 3),
    BALL: new CardName("Ball", Expansions.ADVENTURES, new Cost(5), [Types.EVENT], true, 9),
    BONFIRE: new CardName("Bonfire", Expansions.ADVENTURES, new Cost(3), [Types.EVENT], true, 9),
    BORROW: new CardName("Borrow", Expansions.ADVENTURES, new Cost(0), [Types.EVENT], true, 9),
    BRIDGE_TROLL: new CardName("Bridge Troll", Expansions.ADVENTURES, new Cost(5), [Types.ACTION, Types.DURATION, Types.ATTACK], true, 4),
    CARAVAN_GUARD: new CardName("Caravan Guard", Expansions.ADVENTURES, new Cost(3), [Types.ACTION, Types.DURATION, Types.REACTION], true, 0),
    CHAMPION: new CardName("Champion", Expansions.ADVENTURES, new Cost(6), [Types.ACTION, Types.DURATION], false, 0),
    COIN_OF_THE_REALM: new CardName("Coin Of The Realm", Expansions.ADVENTURES, new Cost(2), [Types.TREASURE, Types.RESERVE], true, 1),
    DISCIPLE: new CardName("Disciple", Expansions.ADVENTURES, new Cost(5), [Types.ACTION, Types.TRAVELLER], false, 0),
    DISTANT_LANDS: new CardName("Distant Lands", Expansions.ADVENTURES, new Cost(5), [Types.ACTION, Types.RESERVE, Types.VICTORY], true, 4),
    DUNGEON: new CardName("Dungeon", Expansions.ADVENTURES, new Cost(3), [Types.ACTION, Types.DURATION], true, 3),
    DUPLICATE: new CardName("Duplicate", Expansions.ADVENTURES, new Cost(4), [Types.ACTION, Types.RESERVE], true, 4),
    EXPEDITION: new CardName("Expedition", Expansions.ADVENTURES, new Cost(3), [Types.EVENT], true, 9),
    FERRY: new CardName("Ferry", Expansions.ADVENTURES, new Cost(3), [Types.EVENT], true, 9),
    FUGITIVE: new CardName("Fugitive", Expansions.ADVENTURES, new Cost(4), [Types.ACTION, Types.TRAVELLER], false, 0),
    GEAR: new CardName("Gear", Expansions.ADVENTURES, new Cost(3), [Types.ACTION, Types.DURATION], true, 2),
    GIANT: new CardName("Giant", Expansions.ADVENTURES, new Cost(5), [Types.ACTION, Types.ATTACK], true, 4),
    GUIDE: new CardName("Guide", Expansions.ADVENTURES, new Cost(3), [Types.ACTION, Types.RESERVE], true, 3),
    HAUNTED_WOODS: new CardName("Haunted Woods", Expansions.ADVENTURES, new Cost(5), [Types.ACTION, Types.DURATION, Types.ATTACK], true, 4),
    HERO: new CardName("Hero", Expansions.ADVENTURES, new Cost(5), [Types.ACTION, Types.TRAVELLER], false, 0),
    HIRELING: new CardName("Hireling", Expansions.ADVENTURES, new Cost(6), [Types.ACTION, Types.DURATION], true, 4),
    INHERITANCE: new CardName("Inheritance", Expansions.ADVENTURES, new Cost(7), [Types.EVENT], true, 9),
    LOST_ARTS: new CardName("Lost Arts", Expansions.ADVENTURES, new Cost(6), [Types.EVENT], true, 9),
    LOST_CITY: new CardName("Lost City", Expansions.ADVENTURES, new Cost(5), [Types.ACTION], true, 1),
    MAGPIE: new CardName("Magpie", Expansions.ADVENTURES, new Cost(4), [Types.ACTION], true, 3),
    MESSENGER: new CardName("Messenger", Expansions.ADVENTURES, new Cost(4), [Types.ACTION], true, 4),
    MISER: new CardName("Miser", Expansions.ADVENTURES, new Cost(4), [Types.ACTION], true, 4),
    MISSION: new CardName("Mission", Expansions.ADVENTURES, new Cost(4), [Types.EVENT], true, 9),
    PATHFINDING: new CardName("Pathfinding", Expansions.ADVENTURES, new Cost(8), [Types.EVENT], true, 9),
    PAGE: new CardName("Page", Expansions.ADVENTURES, new Cost(2), [Types.ACTION, Types.TRAVELLER], true, 0),
    PEASANT: new CardName("Peasant", Expansions.ADVENTURES, new Cost(2), [Types.ACTION, Types.TRAVELLER], true, 0),
    PILGRIMAGE: new CardName("Pilgrimage", Expansions.ADVENTURES, new Cost(4), [Types.EVENT], true, 9),
    PLAN: new CardName("Plan", Expansions.ADVENTURES, new Cost(3), [Types.EVENT], true, 9),
    PORT: new CardName("Port", Expansions.ADVENTURES, new Cost(4), [Types.ACTION], true, 1),
    QUEST: new CardName("Quest", Expansions.ADVENTURES, new Cost(0), [Types.EVENT], true, 9),
    RANGER: new CardName("Ranger", Expansions.ADVENTURES, new Cost(4), [Types.ACTION], true, 2),
    RAID: new CardName("Raid", Expansions.ADVENTURES, new Cost(5), [Types.EVENT], true, 9),
    RATCATCHER: new CardName("Ratcatcher", Expansions.ADVENTURES, new Cost(2), [Types.ACTION, Types.RESERVE], true, 3),
    RAZE: new CardName("Raze", Expansions.ADVENTURES, new Cost(2), [Types.ACTION], true, 3),
    RELIC: new CardName("Relic", Expansions.ADVENTURES, new Cost(5), [Types.TREASURE, Types.ATTACK], true, 6),
    ROYAL_CARRIAGE: new CardName("Royal Carriage", Expansions.ADVENTURES, new Cost(5), [Types.ACTION, Types.RESERVE], true, 0),
    SAVE: new CardName("Save", Expansions.ADVENTURES, new Cost(1), [Types.EVENT], true, 9),
    SCOUTING_PARTY: new CardName("Scouting Party", Expansions.ADVENTURES, new Cost(2), [Types.EVENT], true, 9),
    SEAWAY: new CardName("Seaway", Expansions.ADVENTURES, new Cost(5), [Types.EVENT], true, 9),
    SOLDIER: new CardName("Soldier", Expansions.ADVENTURES, new Cost(3), [Types.ACTION, Types.ATTACK, Types.TRAVELLER], false, 0),
    STORYTELLER: new CardName("Storyteller", Expansions.ADVENTURES, new Cost(5), [Types.ACTION], true, 2),
    SWAMP_HAG: new CardName("Swamp Hag", Expansions.ADVENTURES, new Cost(5), [Types.ACTION, Types.DURATION, Types.ATTACK], true, 4),
    TEACHER: new CardName("Teacher", Expansions.ADVENTURES, new Cost(6), [Types.ACTION, Types.RESERVE], false, 0),
    TRAVELLING_FAIR: new CardName("Travelling Fair", Expansions.ADVENTURES, new Cost(2), [Types.EVENT], true, 9),
    TRADE: new CardName("Trade", Expansions.ADVENTURES, new Cost(5), [Types.EVENT], true, 9),
    TRAINING: new CardName("Training", Expansions.ADVENTURES, new Cost(6), [Types.EVENT], true, 9),
    TRANSMOGRIFY: new CardName("Transmogrify", Expansions.ADVENTURES, new Cost(4), [Types.ACTION, Types.RESERVE], true, 3),
    TREASURE_TROVE: new CardName("Treasure Trove", Expansions.ADVENTURES, new Cost(5), [Types.TREASURE], true, 6),
    TREASURE_HUNTER: new CardName("Treasure Hunter", Expansions.ADVENTURES, new Cost(3), [Types.ACTION, Types.TRAVELLER], false, 0),
    WARRIOR: new CardName("Warrior", Expansions.ADVENTURES, new Cost(4), [Types.ACTION, Types.ATTACK, Types.TRAVELLER], false, 0),
    WINE_MERCHANT: new CardName("Wine Merchant", Expansions.ADVENTURES, new Cost(5), [Types.ACTION, Types.RESERVE], true, 4),

    ENCAMPMENT: new CardName("Encampment", Expansions.EMPIRES, new Cost(2), [Types.ACTION], true, 1),
    PLUNDER: new CardName("Plunder", Expansions.EMPIRES, new Cost(5), [Types.TREASURE], false, 5),
    PATRICIAN: new CardName("Patrician", Expansions.EMPIRES, new Cost(2), [Types.ACTION], true, 3),
    EMPORIUM: new CardName("Emporium", Expansions.EMPIRES, new Cost(5), [Types.ACTION], false, 3),
    SETTLERS: new CardName("Settlers", Expansions.EMPIRES, new Cost(2), [Types.ACTION], true, 3),
    BUSTLING_VILLAGE: new CardName("Bustling Village", Expansions.EMPIRES, new Cost(5), [Types.ACTION], false, 1),
    CATAPULT: new CardName("Catapult", Expansions.EMPIRES, new Cost(3), [Types.ACTION, Types.ATTACK], true, 4),
    ROCKS: new CardName("Rocks", Expansions.EMPIRES, new Cost(4), [Types.TREASURE], false, 5),
    GLADIATOR: new CardName("Gladiator", Expansions.EMPIRES, new Cost(3), [Types.ACTION], true, 4),
    FORTUNE: new CardName("Fortune", Expansions.EMPIRES, new Cost(8, 0, 8), [Types.TREASURE], false, 6),
    CASTLES: new CardName("Castles", Expansions.EMPIRES, new Cost(3), [Types.VICTORY, Types.CASTLE, Types.RANDOMIZER], true, 9),
    HUMBLE_CASTLE: new CardName("Humble Castle", Expansions.EMPIRES, new Cost(3), [Types.TREASURE, Types.VICTORY, Types.CASTLE], false, 6),
    CRUMBLING_CASTLE: new CardName("Crumbling Castle", Expansions.EMPIRES, new Cost(4), [Types.VICTORY, Types.CASTLE], false, 8),
    SMALL_CASTLE: new CardName("Small Castle", Expansions.EMPIRES, new Cost(5), [Types.ACTION, Types.VICTORY, Types.CASTLE], false, 4),
    HAUNTED_CASTLE: new CardName("Haunted Castle", Expansions.EMPIRES, new Cost(6), [Types.VICTORY, Types.CASTLE], false, 8),
    OPULENT_CASTLE: new CardName("Opulent Castle", Expansions.EMPIRES, new Cost(7), [Types.ACTION, Types.VICTORY, Types.CASTLE], false, 4),
    SPRAWLING_CASTLE: new CardName("Sprawling Castle", Expansions.EMPIRES, new Cost(8), [Types.VICTORY, Types.CASTLE], false, 8),
    GRAND_CASTLE: new CardName("Grand Castle", Expansions.EMPIRES, new Cost(9), [Types.VICTORY, Types.CASTLE], false, 8),
    KINGS_CASTLE: new CardName("Kings Castle", Expansions.EMPIRES, new Cost(10), [Types.VICTORY, Types.CASTLE], false, 8),
    ADVANCE: new CardName("Advance", Expansions.EMPIRES, new Cost(0), [Types.EVENT], true, 9),
    ANNEX: new CardName("Annex", Expansions.EMPIRES, new Cost(0, 0, 8), [Types.EVENT], true, 9),
    ARCHIVE: new CardName("Archive", Expansions.EMPIRES, new Cost(5), [Types.ACTION, Types.DURATION], true, 3),
    AQUEDUCT: new CardName("Aqueduct", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    ARENA: new CardName("Arena", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    BANDIT_FORT: new CardName("Bandit Fort", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    BANQUET: new CardName("Banquet", Expansions.EMPIRES, new Cost(3), [Types.EVENT], true, 9),
    BASILICA: new CardName("Basilica", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    BATHS: new CardName("Baths", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    BATTLEFIELD: new CardName("Battlefield", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    CAPITAL: new CardName("Capital", Expansions.EMPIRES, new Cost(5), [Types.TREASURE], true, 6),
    CHARM: new CardName("Charm", Expansions.EMPIRES, new Cost(5), [Types.TREASURE], true, 6),
    CHARIOT_RACE: new CardName("Chariot Race", Expansions.EMPIRES, new Cost(3), [Types.ACTION], true, 3),
    CITY_QUARTER: new CardName("City Quarter", Expansions.EMPIRES, new Cost(0, 0, 8), [Types.ACTION], true, 1),
    COLONNADE: new CardName("Colonnade", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    CONQUEST: new CardName("Conquest", Expansions.EMPIRES, new Cost(6), [Types.EVENT], true, 9),
    CROWN: new CardName("Crown", Expansions.EMPIRES, new Cost(5), [Types.ACTION, Types.TREASURE], true, 0),
    DELVE: new CardName("Delve", Expansions.EMPIRES, new Cost(2), [Types.EVENT], true, 9),
    DEFILED_SHRINE: new CardName("Defiled Shrine", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    DOMINATE: new CardName("Dominate", Expansions.EMPIRES, new Cost(14), [Types.EVENT], true, 9),
    DONATE: new CardName("Donate", Expansions.EMPIRES, new Cost(0, 0, 8), [Types.EVENT], true, 9),
    ENCHANTRESS: new CardName("Enchantress", Expansions.EMPIRES, new Cost(3), [Types.ACTION, Types.DURATION, Types.ATTACK], true, 4),
    ENGINEER: new CardName("Engineer", Expansions.EMPIRES, new Cost(0, 0, 4), [Types.ACTION], true, 4),
    FARMERS_MARKET: new CardName("Farmers Market", Expansions.EMPIRES, new Cost(3), [Types.ACTION, Types.GATHERING], true, 3),
    FORUM: new CardName("Forum", Expansions.EMPIRES, new Cost(5), [Types.ACTION], true, 4),
    FOUNTAIN: new CardName("Fountain", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    GROUNDSKEEPER: new CardName("Groundskeeper", Expansions.EMPIRES, new Cost(5), [Types.ACTION], true, 3),
    KEEP: new CardName("Keep", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    LABYRINTH: new CardName("Labyrinth", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    LEGIONARY: new CardName("Legionary", Expansions.EMPIRES, new Cost(5), [Types.ACTION, Types.ATTACK], true, 4),
    MOUNTAIN_PASS: new CardName("Mountain Pass", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    MUSEUM: new CardName("Museum", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    OBELISK: new CardName("Obelisk", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    ORCHARD: new CardName("Orchard", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    OVERLORD: new CardName("Overlord", Expansions.EMPIRES, new Cost(0, 0, 8), [Types.ACTION, Types.COMMAND], true, 4),
    PALACE: new CardName("Palace", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    RITUAL: new CardName("Ritual", Expansions.EMPIRES, new Cost(4), [Types.EVENT], true, 9),
    ROYAL_BLACKSMITH: new CardName("Royal Blacksmith", Expansions.EMPIRES, new Cost(0, 0, 8), [Types.ACTION], true, 2),
    SACRIFICE: new CardName("Sacrifice", Expansions.EMPIRES, new Cost(4), [Types.ACTION], true, 4),
    SALT_THE_EARTH: new CardName("Salt The Earth", Expansions.EMPIRES, new Cost(4), [Types.EVENT], true, 9),
    TAX: new CardName("Tax", Expansions.EMPIRES, new Cost(2), [Types.EVENT], true, 9),
    TEMPLE: new CardName("Temple", Expansions.EMPIRES, new Cost(4), [Types.ACTION, Types.GATHERING], true, 4),
    TOMB: new CardName("Tomb", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    TOWER: new CardName("Tower", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    TRIUMPH: new CardName("Triumph", Expansions.EMPIRES, new Cost(0, 0, 5), [Types.EVENT], true, 9),
    TRIUMPHAL_ARCH: new CardName("Triumphal Arch", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    VILLA: new CardName("Villa", Expansions.EMPIRES, new Cost(4), [Types.ACTION], true, 1),
    WALL: new CardName("Wall", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    WOLF_DEN: new CardName("Wolf Den", Expansions.EMPIRES, new Cost(0), [Types.LANDMARK], true, 9),
    WEDDING: new CardName("Wedding", Expansions.EMPIRES, new Cost(4, 0, 3), [Types.EVENT], true, 9),
    WILD_HUNT: new CardName("Wild Hunt", Expansions.EMPIRES, new Cost(5), [Types.ACTION, Types.GATHERING], true, 2),
    WINDFALL: new CardName("Windfall", Expansions.EMPIRES, new Cost(5), [Types.EVENT], true, 9),
    PIG: new CardName("Pig", Expansions.EMPIRES, new Cost(0), [], false, 9, true),
    MINUS_CARD: new CardName("MinusCard", Expansions.ADVENTURES, new Cost(0), [], false, 9, true),
    MINUS_COIN: new CardName("MinusCoin", Expansions.ADVENTURES, new Cost(0), [], false, 9, true),

    STATE_LIMBO: new CardName("State Limbo", Expansions.NOCTURNE, new Cost(0), [Types.STATE], false, 9, true),
    DRUID_BOONS: new CardName("Druid Boons", Expansions.NOCTURNE, new Cost(0), [Types.BOON], false, 9, true),
    BOON_DRAWPILE: new CardName("Boon Drawpile", Expansions.NOCTURNE, new Cost(0), [Types.BOON], false, 9, true),
    BOON_DISCARDPILE: new CardName("Boon Discardpile", Expansions.NOCTURNE, new Cost(0), [Types.BOON], false, 9),
    THE_EARTHS_GIFT: new CardName("The Earths Gift", Expansions.NOCTURNE, new Cost(0), [Types.BOON], false, 9),
    THE_FIELDS_GIFT: new CardName("The Fields Gift", Expansions.NOCTURNE, new Cost(0), [Types.BOON], false, 9),
    THE_FLAMES_GIFT: new CardName("The Flames Gift", Expansions.NOCTURNE, new Cost(0), [Types.BOON], false, 9),
    THE_FORESTS_GIFT: new CardName("The Forests Gift", Expansions.NOCTURNE, new Cost(0), [Types.BOON], false, 9),
    THE_MOONS_GIFT: new CardName("The Moons Gift", Expansions.NOCTURNE, new Cost(0), [Types.BOON], false, 9),
    THE_MOUNTAINS_GIFT: new CardName("The Mountains Gift", Expansions.NOCTURNE, new Cost(0), [Types.BOON], false, 9),
    THE_RIVERS_GIFT: new CardName("The Rivers Gift", Expansions.NOCTURNE, new Cost(0), [Types.BOON], false, 9),
    THE_SEAS_GIFT: new CardName("The Seas Gift", Expansions.NOCTURNE, new Cost(0), [Types.BOON], false, 9),
    THE_SKYS_GIFT: new CardName("The Skys Gift", Expansions.NOCTURNE, new Cost(0), [Types.BOON], false, 9),
    THE_SUNS_GIFT: new CardName("The Suns Gift", Expansions.NOCTURNE, new Cost(0), [Types.BOON], false, 9),
    THE_SWAMPS_GIFT: new CardName("The Swamps Gift", Expansions.NOCTURNE, new Cost(0), [Types.BOON], false, 9),
    THE_WINDS_GIFT: new CardName("The Winds Gift", Expansions.NOCTURNE, new Cost(0), [Types.BOON], false, 9),
    HEX_DRAWPILE: new CardName("Hex Drawpile", Expansions.NOCTURNE, new Cost(0), [Types.HEX], false, 9, true),
    HEX_DISCARDPILE: new CardName("Hex Discardpile", Expansions.NOCTURNE, new Cost(0), [Types.HEX], false, 9),
    BAD_OMENS: new CardName("Bad Omens", Expansions.NOCTURNE, new Cost(0), [Types.HEX], false, 9),
    DELUSION: new CardName("Delusion", Expansions.NOCTURNE, new Cost(0), [Types.HEX], false, 9),
    ENVY: new CardName("Envy", Expansions.NOCTURNE, new Cost(0), [Types.HEX], false, 9),
    FAMINE: new CardName("Famine", Expansions.NOCTURNE, new Cost(0), [Types.HEX], false, 9),
    FEAR: new CardName("Fear", Expansions.NOCTURNE, new Cost(0), [Types.HEX], false, 9),
    GREED: new CardName("Greed", Expansions.NOCTURNE, new Cost(0), [Types.HEX], false, 9),
    HAUNTING: new CardName("Haunting", Expansions.NOCTURNE, new Cost(0), [Types.HEX], false, 9),
    LOCUSTS: new CardName("Locusts", Expansions.NOCTURNE, new Cost(0), [Types.HEX], false, 9),
    MISERY: new CardName("Misery", Expansions.NOCTURNE, new Cost(0), [Types.HEX], false, 9),
    PLAGUE: new CardName("Plague", Expansions.NOCTURNE, new Cost(0), [Types.HEX], false, 9),
    POVERTY: new CardName("Poverty", Expansions.NOCTURNE, new Cost(0), [Types.HEX], false, 9),
    WAR: new CardName("War", Expansions.NOCTURNE, new Cost(0), [Types.HEX], false, 9),
    MISERABLE: new CardName("Miserable", Expansions.NOCTURNE, new Cost(0), [Types.STATE], false, 9),
    TWICE_MISERABLE: new CardName("Twice Miserable", Expansions.NOCTURNE, new Cost(0), [Types.STATE], false, 9),
    ENVIOUS: new CardName("Envious", Expansions.NOCTURNE, new Cost(0), [Types.STATE], false, 9),
    DELUDED: new CardName("Deluded", Expansions.NOCTURNE, new Cost(0), [Types.STATE], false, 9),
    LOST_IN_THE_WOODS: new CardName("Lost In The Woods", Expansions.NOCTURNE, new Cost(0), [Types.STATE], false, 9),
    BARD: new CardName("Bard", Expansions.NOCTURNE, new Cost(4), [Types.ACTION, Types.FATE], true, 4),
    BLESSED_VILLAGE: new CardName("Blessed Village", Expansions.NOCTURNE, new Cost(4), [Types.ACTION, Types.FATE], true, 1),
    CHANGELING: new CardName("Changeling", Expansions.NOCTURNE, new Cost(3), [Types.NIGHT], true, 7),
    CEMETERY: new CardName("Cemetery", Expansions.NOCTURNE, new Cost(4), [Types.VICTORY], true, 8),
    COBBLER: new CardName("Cobbler", Expansions.NOCTURNE, new Cost(5), [Types.NIGHT, Types.DURATION], true, 7),
    CONCLAVE: new CardName("Conclave", Expansions.NOCTURNE, new Cost(4), [Types.ACTION], true, 1),
    CRYPT: new CardName("Crypt", Expansions.NOCTURNE, new Cost(5), [Types.NIGHT, Types.DURATION], true, 7),
    CURSED_VILLAGE: new CardName("Cursed Village", Expansions.NOCTURNE, new Cost(5), [Types.ACTION, Types.DOOM], true, 1),
    DEN_OF_SIN: new CardName("Den Of Sin", Expansions.NOCTURNE, new Cost(5), [Types.NIGHT, Types.DURATION], true, 7),
    DEVILS_WORKSHOP: new CardName("Devils Workshop", Expansions.NOCTURNE, new Cost(4), [Types.NIGHT], true, 7),
    DRUID: new CardName("Druid", Expansions.NOCTURNE, new Cost(2), [Types.ACTION, Types.FATE], true, 4),
    EXORCIST: new CardName("Exorcist", Expansions.NOCTURNE, new Cost(4), [Types.NIGHT], true, 7),
    FAITHFUL_HOUND: new CardName("Faithful Hound", Expansions.NOCTURNE, new Cost(2), [Types.ACTION, Types.REACTION], true, 2),
    FOOL: new CardName("Fool", Expansions.NOCTURNE, new Cost(3), [Types.ACTION, Types.FATE], true, 4),
    GHOST_TOWN: new CardName("Ghost Town", Expansions.NOCTURNE, new Cost(3), [Types.NIGHT, Types.DURATION], true, 7),
    GUARDIAN: new CardName("Guardian", Expansions.NOCTURNE, new Cost(2), [Types.NIGHT, Types.DURATION], true, 7),
    IDOL: new CardName("Idol", Expansions.NOCTURNE, new Cost(5), [Types.TREASURE, Types.ATTACK, Types.FATE], true, 6),
    LEPRECHAUN: new CardName("Leprechaun", Expansions.NOCTURNE, new Cost(3), [Types.ACTION, Types.DOOM], true, 4),
    MONASTERY: new CardName("Monastery", Expansions.NOCTURNE, new Cost(2), [Types.NIGHT], true, 7),
    NECROMANCER: new CardName("Necromancer", Expansions.NOCTURNE, new Cost(4), [Types.ACTION], true, 4),
    NIGHT_WATCHMAN: new CardName("Night Watchman", Expansions.NOCTURNE, new Cost(3), [Types.NIGHT], true, 7),
    PIXIE: new CardName("Pixie", Expansions.NOCTURNE, new Cost(2), [Types.ACTION, Types.FATE], true, 3),
    POOKA: new CardName("Pooka", Expansions.NOCTURNE, new Cost(5), [Types.ACTION], true, 2),
    RAIDER: new CardName("Raider", Expansions.NOCTURNE, new Cost(6), [Types.NIGHT, Types.DURATION, Types.ATTACK], true, 7),
    SACRED_GROVE: new CardName("Sacred Grove", Expansions.NOCTURNE, new Cost(5), [Types.ACTION, Types.FATE], true, 4),
    SECRET_CAVE: new CardName("Secret Cave", Expansions.NOCTURNE, new Cost(3), [Types.ACTION, Types.DURATION], true, 3),
    SHEPHERD: new CardName("Shepherd", Expansions.NOCTURNE, new Cost(4), [Types.ACTION], true, 2),
    SKULK: new CardName("Skulk", Expansions.NOCTURNE, new Cost(4), [Types.ACTION, Types.ATTACK, Types.DOOM], true, 4),
    TORMENTOR: new CardName("Tormentor", Expansions.NOCTURNE, new Cost(5), [Types.ACTION, Types.ATTACK, Types.DOOM], true, 4),
    TRAGIC_HERO: new CardName("Tragic Hero", Expansions.NOCTURNE, new Cost(5), [Types.ACTION], true, 2),
    TRACKER: new CardName("Tracker", Expansions.NOCTURNE, new Cost(2), [Types.ACTION, Types.FATE], true, 4),
    VAMPIRE: new CardName("Vampire", Expansions.NOCTURNE, new Cost(5), [Types.NIGHT, Types.ATTACK, Types.DOOM], true, 7),
    WEREWOLF: new CardName("Werewolf", Expansions.NOCTURNE, new Cost(5), [Types.ACTION, Types.NIGHT, Types.ATTACK, Types.DOOM], true, 2),
    CURSED_GOLD: new CardName("Cursed Gold", Expansions.NOCTURNE, new Cost(4), [Types.TREASURE, Types.HEIRLOOM], false, 6),
    GOAT: new CardName("Goat", Expansions.NOCTURNE, new Cost(2), [Types.TREASURE, Types.HEIRLOOM], false, 6),
    HAUNTED_MIRROR: new CardName("Haunted Mirror", Expansions.NOCTURNE, new Cost(0), [Types.TREASURE, Types.HEIRLOOM], false, 6),
    LUCKY_COIN: new CardName("Lucky Coin", Expansions.NOCTURNE, new Cost(4), [Types.TREASURE, Types.HEIRLOOM], false, 6),
    MAGIC_LAMP: new CardName("Magic Lamp", Expansions.NOCTURNE, new Cost(0), [Types.TREASURE, Types.HEIRLOOM], false, 5),
    PASTURE: new CardName("Pasture", Expansions.NOCTURNE, new Cost(2), [Types.TREASURE, Types.VICTORY, Types.HEIRLOOM], false, 5),
    POUCH: new CardName("Pouch", Expansions.NOCTURNE, new Cost(2), [Types.TREASURE, Types.HEIRLOOM], false, 5),
    BAT: new CardName("Bat", Expansions.NOCTURNE, new Cost(2), [Types.NIGHT], false, 7),
    GHOST: new CardName("Ghost", Expansions.NOCTURNE, new Cost(4), [Types.NIGHT, Types.DURATION, Types.SPIRIT], false, 7),
    IMP: new CardName("Imp", Expansions.NOCTURNE, new Cost(2), [Types.ACTION, Types.SPIRIT], false, 2),
    WILL_O_WISP: new CardName("Will O Wisp", Expansions.NOCTURNE, new Cost(0), [Types.ACTION, Types.SPIRIT], false, 3),
    WISH: new CardName("Wish", Expansions.NOCTURNE, new Cost(0), [Types.ACTION], false, 3),
    ZOMBIE_APPRENTICE: new CardName("Zombie Apprentice", Expansions.NOCTURNE, new Cost(3), [Types.ACTION, Types.ZOMBIE], false, 3),
    ZOMBIE_MASON: new CardName("Zombie Mason", Expansions.NOCTURNE, new Cost(3), [Types.ACTION, Types.ZOMBIE], false, 4),
    ZOMBIE_SPY: new CardName("Zombie Spy", Expansions.NOCTURNE, new Cost(3), [Types.ACTION, Types.ZOMBIE], false, 3),

    ACTING_TROUPE: new CardName("Acting Troupe", Expansions.RENAISSANCE, new Cost(3), [Types.ACTION], true, 1),
    BORDER_GUARD: new CardName("Border Guard", Expansions.RENAISSANCE, new Cost(2), [Types.ACTION], true, 3),
    CARGO_SHIP: new CardName("Cargo Ship", Expansions.RENAISSANCE, new Cost(3), [Types.ACTION, Types.DURATION], true, 4),
    DUCAT: new CardName("Ducat", Expansions.RENAISSANCE, new Cost(2), [Types.TREASURE], true, 5),
    EXPERIMENT: new CardName("Experiment", Expansions.RENAISSANCE, new Cost(3), [Types.ACTION], true, 2),
    FLAG_BEARER: new CardName("Flag Bearer", Expansions.RENAISSANCE, new Cost(4), [Types.ACTION], true, 4),
    HIDEOUT: new CardName("Hideout", Expansions.RENAISSANCE, new Cost(4), [Types.ACTION], true, 1),
    INVENTOR: new CardName("Inventor", Expansions.RENAISSANCE, new Cost(4), [Types.ACTION], true, 4),
    IMPROVE: new CardName("Improve", Expansions.RENAISSANCE, new Cost(3), [Types.ACTION], true, 4),
    LACKEYS: new CardName("Lackeys", Expansions.RENAISSANCE, new Cost(2), [Types.ACTION], true, 2),
    MOUNTAIN_VILLAGE: new CardName("Mountain Village", Expansions.RENAISSANCE, new Cost(4), [Types.ACTION], true, 1),
    PATRON: new CardName("Patron", Expansions.RENAISSANCE, new Cost(4), [Types.ACTION, Types.REACTION], true, 3),
    PRIEST: new CardName("Priest", Expansions.RENAISSANCE, new Cost(4), [Types.ACTION], true, 4),
    RESEARCH: new CardName("Research", Expansions.RENAISSANCE, new Cost(4), [Types.ACTION, Types.DURATION], true, 3),
    SILK_MERCHANT: new CardName("Silk Merchant", Expansions.RENAISSANCE, new Cost(4), [Types.ACTION], true, 2),
    OLD_WITCH: new CardName("Old Witch", Expansions.RENAISSANCE, new Cost(5), [Types.ACTION, Types.ATTACK], true, 2),
    RECRUITER: new CardName("Recruiter", Expansions.RENAISSANCE, new Cost(5), [Types.ACTION], true, 2),
    SCEPTER: new CardName("Scepter", Expansions.RENAISSANCE, new Cost(5), [Types.TREASURE, Types.COMMAND], true, 6),
    SCHOLAR: new CardName("Scholar", Expansions.RENAISSANCE, new Cost(5), [Types.ACTION], true, 2),
    SCULPTOR: new CardName("Sculptor", Expansions.RENAISSANCE, new Cost(5), [Types.ACTION], true, 4),
    SEER: new CardName("Seer", Expansions.RENAISSANCE, new Cost(5), [Types.ACTION], true, 2),
    SPICES: new CardName("Spices", Expansions.RENAISSANCE, new Cost(5), [Types.TREASURE], true, 5),
    SWASHBUCKLER: new CardName("Swashbuckler", Expansions.RENAISSANCE, new Cost(5), [Types.ACTION], true, 2),
    TREASURER: new CardName("Treasurer", Expansions.RENAISSANCE, new Cost(5), [Types.ACTION], true, 4),
    VILLAIN: new CardName("Villain", Expansions.RENAISSANCE, new Cost(5), [Types.ACTION, Types.ATTACK], true, 4),
    FLAG: new CardName("Flag", Expansions.RENAISSANCE, new Cost(0), [Types.ARTIFACT], false, 9),
    HORN: new CardName("Horn", Expansions.RENAISSANCE, new Cost(0), [Types.ARTIFACT], false, 9),
    KEY: new CardName("Key", Expansions.RENAISSANCE, new Cost(0), [Types.ARTIFACT], false, 9),
    LANTERN: new CardName("Lantern", Expansions.RENAISSANCE, new Cost(0), [Types.ARTIFACT], false, 9),
    TREASURE_CHEST: new CardName("Treasure Chest", Expansions.RENAISSANCE, new Cost(0), [Types.ARTIFACT], false, 9),
    ACADEMY: new CardName("Academy", Expansions.RENAISSANCE, new Cost(5), [Types.PROJECT], true, 9),
    BARRACKS: new CardName("Barracks", Expansions.RENAISSANCE, new Cost(6), [Types.PROJECT], true, 9),
    CANAL: new CardName("Canal", Expansions.RENAISSANCE, new Cost(7), [Types.PROJECT], true, 9),
    CAPITALISM: new CardName("Capitalism", Expansions.RENAISSANCE, new Cost(5), [Types.PROJECT], true, 9),
    CATHEDRAL: new CardName("Cathedral", Expansions.RENAISSANCE, new Cost(3), [Types.PROJECT], true, 9),
    CITADEL: new CardName("Citadel", Expansions.RENAISSANCE, new Cost(8), [Types.PROJECT], true, 9),
    CITY_GATE: new CardName("City Gate", Expansions.RENAISSANCE, new Cost(3), [Types.PROJECT], true, 9),
    CROP_ROTATION: new CardName("Crop Rotation", Expansions.RENAISSANCE, new Cost(6), [Types.PROJECT], true, 9),
    EXPLORATION: new CardName("Exploration", Expansions.RENAISSANCE, new Cost(4), [Types.PROJECT], true, 9),
    FAIR: new CardName("Fair", Expansions.RENAISSANCE, new Cost(4), [Types.PROJECT], true, 9),
    FLEET: new CardName("Fleet", Expansions.RENAISSANCE, new Cost(5), [Types.PROJECT], true, 9),
    GUILDHALL: new CardName("Guildhall", Expansions.RENAISSANCE, new Cost(5), [Types.PROJECT], true, 9),
    INNOVATION: new CardName("Innovation", Expansions.RENAISSANCE, new Cost(6), [Types.PROJECT], true, 9),
    PAGEANT: new CardName("Pageant", Expansions.RENAISSANCE, new Cost(3), [Types.PROJECT], true, 9),
    PIAZZA: new CardName("Piazza", Expansions.RENAISSANCE, new Cost(5), [Types.PROJECT], true, 9),
    ROAD_NETWORK: new CardName("Road Network", Expansions.RENAISSANCE, new Cost(5), [Types.PROJECT], true, 9),
    SEWERS: new CardName("Sewers", Expansions.RENAISSANCE, new Cost(3), [Types.PROJECT], true, 9),
    SILOS: new CardName("Silos", Expansions.RENAISSANCE, new Cost(4), [Types.PROJECT], true, 9),
    SINISTER_PLOT: new CardName("Sinister Plot", Expansions.RENAISSANCE, new Cost(4), [Types.PROJECT], true, 9),
    STAR_CHART: new CardName("Star Chart", Expansions.RENAISSANCE, new Cost(3), [Types.PROJECT], true, 9),

    SAUNA: new CardName("Sauna", Expansions.PROMOS, new Cost(4), [Types.ACTION], true, 1),
    AVANTO: new CardName("Avanto", Expansions.PROMOS, new Cost(5), [Types.ACTION], false, 2),
    BLACK_MARKET: new CardName("Black Market", Expansions.PROMOS, new Cost(3), [Types.ACTION], true, 4),
    ENVOY: new CardName("Envoy", Expansions.PROMOS, new Cost(4), [Types.ACTION], true, 2),
    GOVERNOR: new CardName("Governor", Expansions.PROMOS, new Cost(5), [Types.ACTION], true, 2),
    PRINCE: new CardName("Prince", Expansions.PROMOS, new Cost(8), [Types.ACTION, Types.DURATION, Types.COMMAND], true, 4),
    STASH: new CardName("Stash", Expansions.PROMOS, new Cost(5), [Types.TREASURE], true, 6),
    SUMMON: new CardName("Summon", Expansions.PROMOS, new Cost(5), [Types.EVENT], true, 9),
    WALLED_VILLAGE: new CardName("Walled Village", Expansions.PROMOS, new Cost(4), [Types.ACTION], true, 1),
    BLACK_MARKET_PILE: new CardName("Black Market Pile", Expansions.PROMOS, new Cost(0), [], false, 9, true),
    DISMANTLE: new CardName("Dismantle", Expansions.PROMOS, new Cost(4), [Types.ACTION], true, 4),
    CAPTAIN: new CardName("Captain", Expansions.PROMOS, new Cost(6), [Types.ACTION, Types.DURATION, Types.COMMAND], true, 4),
    CHURCH: new CardName("Church", Expansions.PROMOS, new Cost(3), [Types.ACTION, Types.DURATION], true, 3),
    MARCHLAND: new CardName("Marchland", Expansions.PROMOS, new Cost(5), [Types.VICTORY], true, 8),

    BLACK_CAT: new CardName("Black Cat", Expansions.MENAGERIE, new Cost(2), [Types.ACTION, Types.ATTACK, Types.REACTION], true, 2),
    SLEIGH: new CardName("Sleigh", Expansions.MENAGERIE, new Cost(2), [Types.ACTION, Types.REACTION], true, 4),
    SUPPLIES: new CardName("Supplies", Expansions.MENAGERIE, new Cost(2), [Types.TREASURE], true, 6),
    CAMEL_TRAIN: new CardName("Camel Train", Expansions.MENAGERIE, new Cost(3), [Types.ACTION], true, 4),
    GOATHERD: new CardName("Goatherd", Expansions.MENAGERIE, new Cost(3), [Types.ACTION], true, 3),
    SCRAP: new CardName("Scrap", Expansions.MENAGERIE, new Cost(3), [Types.ACTION], true, 3),
    SHEEPDOG: new CardName("Sheepdog", Expansions.MENAGERIE, new Cost(3), [Types.ACTION, Types.REACTION], true, 2),
    SNOWY_VILLAGE: new CardName("Snowy Village", Expansions.MENAGERIE, new Cost(3), [Types.ACTION], true, 1),
    STOCKPILE: new CardName("Stockpile", Expansions.MENAGERIE, new Cost(3), [Types.TREASURE], true, 6),
    BOUNTY_HUNTER: new CardName("Bounty Hunter", Expansions.MENAGERIE, new Cost(4), [Types.ACTION], true, 3),
    CARDINAL: new CardName("Cardinal", Expansions.MENAGERIE, new Cost(4), [Types.ACTION, Types.ATTACK], true, 4),
    CAVALRY: new CardName("Cavalry", Expansions.MENAGERIE, new Cost(4), [Types.ACTION], true, 4),
    GROOM: new CardName("Groom", Expansions.MENAGERIE, new Cost(4), [Types.ACTION], true, 4),
    HOSTELRY: new CardName("Hostelry", Expansions.MENAGERIE, new Cost(4), [Types.ACTION], true, 1),
    VILLAGE_GREEN: new CardName("Village Green", Expansions.MENAGERIE, new Cost(4), [Types.ACTION, Types.DURATION, Types.REACTION], true, 1),
    BARGE: new CardName("Barge", Expansions.MENAGERIE, new Cost(5), [Types.ACTION, Types.DURATION], true, 2),
    COVEN: new CardName("Coven", Expansions.MENAGERIE, new Cost(5), [Types.ACTION, Types.ATTACK], true, 3),
    DISPLACE: new CardName("Displace", Expansions.MENAGERIE, new Cost(5), [Types.ACTION], true, 4),
    FALCONER: new CardName("Falconer", Expansions.MENAGERIE, new Cost(5), [Types.ACTION, Types.REACTION], true, 4),
    FISHERMAN: new CardName("Fisherman", Expansions.MENAGERIE, new Cost(5), [Types.ACTION], true, 3),
    GATEKEEPER: new CardName("Gatekeeper", Expansions.MENAGERIE, new Cost(5), [Types.ACTION, Types.DURATION, Types.ATTACK], true, 4),
    HUNTING_LODGE: new CardName("Hunting Lodge", Expansions.MENAGERIE, new Cost(5), [Types.ACTION], true, 1),
    KILN: new CardName("Kiln", Expansions.MENAGERIE, new Cost(5), [Types.ACTION], true, 4),
    LIVERY: new CardName("Livery", Expansions.MENAGERIE, new Cost(5), [Types.ACTION], true, 4),
    MASTERMIND: new CardName("Mastermind", Expansions.MENAGERIE, new Cost(5), [Types.ACTION, Types.DURATION], true, 4),
    PADDOCK: new CardName("Paddock", Expansions.MENAGERIE, new Cost(5), [Types.ACTION], true, 4),
    SANCTUARY: new CardName("Sanctuary", Expansions.MENAGERIE, new Cost(5), [Types.ACTION], true, 3),
    DESTRIER: new CardName("Destrier", Expansions.MENAGERIE, new Cost(6), [Types.ACTION], true, 3),
    WAYFARER: new CardName("Wayfarer", Expansions.MENAGERIE, new Cost(6), [Types.ACTION], true, 2),
    ANIMAL_FAIR: new CardName("Animal Fair", Expansions.MENAGERIE, new Cost(7), [Types.ACTION], true, 4),

    HORSE: new CardName("Horse", Expansions.MENAGERIE, new Cost(3), [Types.ACTION], false, 3),

    WAY_OF_THE_BUTTERFLY: new CardName("Way Of The Butterfly", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),
    WAY_OF_THE_CAMEL: new CardName("Way Of The Camel", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),
    WAY_OF_THE_CHAMELEON: new CardName("Way Of The Chameleon", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),
    WAY_OF_THE_FROG: new CardName("Way Of The Frog", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),
    WAY_OF_THE_GOAT: new CardName("Way Of The Goat", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),
    WAY_OF_THE_HORSE: new CardName("Way Of The Horse", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),
    WAY_OF_THE_MOLE: new CardName("Way Of The Mole", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),
    WAY_OF_THE_MONKEY: new CardName("Way Of The Monkey", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),
    WAY_OF_THE_MOUSE: new CardName("Way Of The Mouse", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),
    WAY_OF_THE_MULE: new CardName("Way Of The Mule", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),
    WAY_OF_THE_OTTER: new CardName("Way Of The Otter", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),
    WAY_OF_THE_OWL: new CardName("Way Of The Owl", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),
    WAY_OF_THE_OX: new CardName("Way Of The Ox", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),
    WAY_OF_THE_PIG: new CardName("Way Of The Pig", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),
    WAY_OF_THE_RAT: new CardName("Way Of The Rat", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),
    WAY_OF_THE_SEAL: new CardName("Way Of The Seal", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),
    WAY_OF_THE_SHEEP: new CardName("Way Of The Sheep", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),
    WAY_OF_THE_SQUIRREL: new CardName("Way Of The Squirrel", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),
    WAY_OF_THE_TURTLE: new CardName("Way Of The Turtle", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),
    WAY_OF_THE_WORM: new CardName("Way Of The Worm", Expansions.MENAGERIE, new Cost(0), [Types.WAY], true, 9),

    DELAY: new CardName("Delay", Expansions.MENAGERIE, new Cost(0), [Types.EVENT], true, 9),
    DESPERATION: new CardName("Desperation", Expansions.MENAGERIE, new Cost(0), [Types.EVENT], true, 9),
    GAMBLE: new CardName("Gamble", Expansions.MENAGERIE, new Cost(2), [Types.EVENT], true, 9),
    PURSUE: new CardName("Pursue", Expansions.MENAGERIE, new Cost(2), [Types.EVENT], true, 9),
    RIDE: new CardName("Ride", Expansions.MENAGERIE, new Cost(2), [Types.EVENT], true, 9),
    TOIL: new CardName("Toil", Expansions.MENAGERIE, new Cost(2), [Types.EVENT], true, 9),
    ENHANCE: new CardName("Enhance", Expansions.MENAGERIE, new Cost(3), [Types.EVENT], true, 9),
    MARCH: new CardName("March", Expansions.MENAGERIE, new Cost(3), [Types.EVENT], true, 9),
    TRANSPORT: new CardName("Transport", Expansions.MENAGERIE, new Cost(3), [Types.EVENT], true, 9),
    BANISH: new CardName("Banish", Expansions.MENAGERIE, new Cost(4), [Types.EVENT], true, 9),
    BARGAIN: new CardName("Bargain", Expansions.MENAGERIE, new Cost(4), [Types.EVENT], true, 9),
    INVEST: new CardName("Invest", Expansions.MENAGERIE, new Cost(4), [Types.EVENT], true, 9),
    SEIZE_THE_DAY: new CardName("Seize The Day", Expansions.MENAGERIE, new Cost(4), [Types.EVENT], true, 9),
    COMMERCE: new CardName("Commerce", Expansions.MENAGERIE, new Cost(5), [Types.EVENT], true, 9),
    DEMAND: new CardName("Demand", Expansions.MENAGERIE, new Cost(5), [Types.EVENT], true, 9),
    STAMPEDE: new CardName("Stampede", Expansions.MENAGERIE, new Cost(5), [Types.EVENT], true, 9),
    REAP: new CardName("Reap", Expansions.MENAGERIE, new Cost(7), [Types.EVENT], true, 9),
    ENCLAVE: new CardName("Enclave", Expansions.MENAGERIE, new Cost(8), [Types.EVENT], true, 9),
    ALLIANCE: new CardName("Alliance", Expansions.MENAGERIE, new Cost(10), [Types.EVENT], true, 9),
    POPULATE: new CardName("Populate", Expansions.MENAGERIE, new Cost(10), [Types.EVENT], true, 9),

    CARD_OF_THE_BUTTERFLY: new CardName("Card Of The Butterfly", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),
    CARD_OF_THE_CAMEL: new CardName("Card Of The Camel", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),
    CARD_OF_THE_CHAMELEON: new CardName("Card Of The Chameleon", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),
    CARD_OF_THE_FROG: new CardName("Card Of The Frog", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),
    CARD_OF_THE_GOAT: new CardName("Card Of The Goat", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),
    CARD_OF_THE_HORSE: new CardName("Card Of The Horse", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),
    CARD_OF_THE_MOLE: new CardName("Card Of The Mole", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),
    CARD_OF_THE_MONKEY: new CardName("Card Of The Monkey", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),
    CARD_OF_THE_MOUSE: new CardName("Card Of The Mouse", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),
    CARD_OF_THE_MULE: new CardName("Card Of The Mule", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),
    CARD_OF_THE_OTTER: new CardName("Card Of The Otter", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),
    CARD_OF_THE_OWL: new CardName("Card Of The Owl", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),
    CARD_OF_THE_OX: new CardName("Card Of The Ox", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),
    CARD_OF_THE_PIG: new CardName("Card Of The Pig", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),
    CARD_OF_THE_RAT: new CardName("Card Of The Rat", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),
    CARD_OF_THE_SEAL: new CardName("Card Of The Seal", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),
    CARD_OF_THE_SHEEP: new CardName("Card Of The Sheep", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),
    CARD_OF_THE_SQUIRREL: new CardName("Card Of The Squirrel", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),
    CARD_OF_THE_TURTLE: new CardName("Card Of The Turtle", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),
    CARD_OF_THE_WORM: new CardName("Card Of The Worm", Expansions.MENAGERIE, new Cost(0), [], false, 9, true),

    MONKEY: new CardName("Monkey", Expansions.SEASIDE, new Cost(3), [Types.ACTION, Types.DURATION], true, 2),
    BLOCKADE: new CardName("Blockade", Expansions.SEASIDE, new Cost(4), [Types.ACTION, Types.DURATION, Types.ATTACK], true, 4),
    SAILOR: new CardName("Sailor", Expansions.SEASIDE, new Cost(4), [Types.ACTION, Types.DURATION], true, 4),
    PIRATE: new CardName("Pirate", Expansions.SEASIDE, new Cost(5), [Types.ACTION, Types.DURATION, Types.REACTION], true, 4),
    CORSAIR: new CardName("Corsair", Expansions.SEASIDE, new Cost(5), [Types.ACTION, Types.DURATION, Types.ATTACK], true, 4),
    SEA_CHART: new CardName("Sea Chart", Expansions.SEASIDE, new Cost(3), [Types.ACTION], true, 2),
    ASTROLABE: new CardName("Astrolabe", Expansions.SEASIDE, new Cost(3), [Types.TREASURE, Types.DURATION], true, 5),
    TIDE_POOLS: new CardName("Tide Pools", Expansions.SEASIDE, new Cost(4), [Types.ACTION, Types.DURATION], true, 3),
    SEA_WITCH: new CardName("Sea Witch", Expansions.SEASIDE, new Cost(5), [Types.ACTION, Types.DURATION, Types.ATTACK], true, 2),

    ANVIL: new CardName("Anvil", Expansions.PROSPERITY, new Cost(3), [Types.TREASURE], true, 6),
    CLERK: new CardName("Clerk", Expansions.PROSPERITY, new Cost(4), [Types.ACTION, Types.REACTION, Types.ATTACK], true, 4),
    INVESTMENT: new CardName("Investment", Expansions.PROSPERITY, new Cost(4), [Types.TREASURE], true, 6),
    TIARA: new CardName("Tiara", Expansions.PROSPERITY, new Cost(4), [Types.TREASURE], true, 6),
    CHARLATAN: new CardName("Charlatan", Expansions.PROSPERITY, new Cost(5), [Types.ACTION, Types.ATTACK], true, 4),
    COLLECTION: new CardName("Collection", Expansions.PROSPERITY, new Cost(5), [Types.TREASURE], true, 6),
    CRYSTAL_BALL: new CardName("Crystal Ball", Expansions.PROSPERITY, new Cost(5), [Types.TREASURE], true, 6),
    MAGNATE: new CardName("Magnate", Expansions.PROSPERITY, new Cost(5), [Types.ACTION], true, 2),
    WAR_CHEST: new CardName("War Chest", Expansions.PROSPERITY, new Cost(5), [Types.TREASURE], true, 6),

    GUARD_DOG: new CardName("Guard Dog", Expansions.HINTERLANDS, new Cost(3), [Types.ACTION, Types.REACTION], true, 2),
    BERSERKER: new CardName("Berserker", Expansions.HINTERLANDS, new Cost(5), [Types.ACTION, Types.ATTACK], true, 4),
    NOMADS: new CardName("Nomads", Expansions.HINTERLANDS, new Cost(4), [Types.ACTION], true, 4),
    TRAIL: new CardName("Trail", Expansions.HINTERLANDS, new Cost(4), [Types.ACTION, Types.REACTION], true, 3),
    WEAVER: new CardName("Weaver", Expansions.HINTERLANDS, new Cost(4), [Types.ACTION, Types.REACTION], true, 4),
    SOUK: new CardName("Souk", Expansions.HINTERLANDS, new Cost(5), [Types.ACTION], true, 4),
    CAULDRON: new CardName("Cauldron", Expansions.HINTERLANDS, new Cost(5), [Types.TREASURE, Types.ATTACK], true, 5),
    WHEELWRIGHT: new CardName("Wheelwright", Expansions.HINTERLANDS, new Cost(5), [Types.ACTION], true, 3),
    WITCHS_HUT: new CardName("Witchs Hut", Expansions.HINTERLANDS, new Cost(5), [Types.ACTION, Types.ATTACK], true, 2),

    UNUSED_SLOT_27: new CardName("unused", null, new Cost(0), [], false, 0, true),
    UNUSED_SLOT_28: new CardName("unused", null, new Cost(0), [], false, 0, true),
    UNUSED_SLOT_29: new CardName("unused", null, new Cost(0), [], false, 0, true),
    UNUSED_SLOT_30: new CardName("unused", null, new Cost(0), [], false, 0, true),
    UNUSED_SLOT_31: new CardName("unused", null, new Cost(0), [], false, 0, true),
    UNUSED_SLOT_32: new CardName("unused", null, new Cost(0), [], false, 0, true),
    UNUSED_SLOT_33: new CardName("unused", null, new Cost(0), [], false, 0, true),
    UNUSED_SLOT_34: new CardName("unused", null, new Cost(0), [], false, 0, true),
    UNUSED_SLOT_35: new CardName("unused", null, new Cost(0), [], false, 0, true),
    UNUSED_SLOT_36: new CardName("unused", null, new Cost(0), [], false, 0, true),
    UNUSED_SLOT_37: new CardName("unused", null, new Cost(0), [], false, 0, true),
    UNUSED_SLOT_38: new CardName("unused", null, new Cost(0), [], false, 0, true),
    UNUSED_SLOT_39: new CardName("unused", null, new Cost(0), [], false, 0, true),
    SHOP: new CardName("Shop", Expansions.CORNUCOPIA_GUILDS, new Cost(3), [Types.ACTION], true, 3),
    CARNIVAL: new CardName("Carnival", Expansions.CORNUCOPIA_GUILDS, new Cost(5), [Types.ACTION], true, 4),
    FERRYMAN: new CardName("Ferryman", Expansions.CORNUCOPIA_GUILDS, new Cost(5), [Types.ACTION], true, 3),

    TOWN: new CardName("Town", Expansions.ALLIES, new Cost(4), [Types.ACTION], true, 1),
    MODIFY: new CardName("Modify", Expansions.ALLIES, new Cost(5), [Types.ACTION], true, 3),
    MERCHANT_CAMP: new CardName("Merchant Camp", Expansions.ALLIES, new Cost(3), [Types.ACTION], true, 1),
    SWAP: new CardName("Swap", Expansions.ALLIES, new Cost(5), [Types.ACTION], true, 3),
    SKIRMISHER: new CardName("Skirmisher", Expansions.ALLIES, new Cost(5), [Types.ACTION, Types.ATTACK], true, 3),
    HIGHWAYMAN: new CardName("Highwayman", Expansions.ALLIES, new Cost(5), [Types.ACTION, Types.DURATION, Types.ATTACK], true, 4),
    BARBARIAN: new CardName("Barbarian", Expansions.ALLIES, new Cost(5), [Types.ACTION, Types.ATTACK], true, 1),
    INNKEEPER: new CardName("Innkeeper", Expansions.ALLIES, new Cost(4), [Types.ACTION], true, 1),
    CAPITAL_CITY: new CardName("Capital City", Expansions.ALLIES, new Cost(5), [Types.ACTION], true, 1),
    SPECIALIST: new CardName("Specialist", Expansions.ALLIES, new Cost(5), [Types.ACTION], true, 1),
    CARPENTER: new CardName("Carpenter", Expansions.ALLIES, new Cost(4), [Types.ACTION], true, 1),
    ROYAL_GALLEY: new CardName("Royal Galley", Expansions.ALLIES, new Cost(4), [Types.ACTION, Types.DURATION], true, 1),
    MARQUIS: new CardName("Marquis", Expansions.ALLIES, new Cost(6), [Types.ACTION], true, 2),
    SENTINEL: new CardName("Sentinel", Expansions.ALLIES, new Cost(3), [Types.ACTION], true, 4),
    COURIER: new CardName("Courier", Expansions.ALLIES, new Cost(4), [Types.ACTION], true, 3),
    GALLERIA: new CardName("Galleria", Expansions.ALLIES, new Cost(5), [Types.ACTION], true, 4),
    HUNTER: new CardName("Hunter", Expansions.ALLIES, new Cost(5), [Types.ACTION], true, 3),

    SYCOPHANT: new CardName("Sycophant", Expansions.ALLIES, new Cost(2), [Types.ACTION, Types.LIAISON], true, 3),
    UNDERLING: new CardName("Underling", Expansions.ALLIES, new Cost(3), [Types.ACTION, Types.LIAISON], true, 3),
    IMPORTER: new CardName("Importer", Expansions.ALLIES, new Cost(3), [Types.ACTION, Types.DURATION, Types.LIAISON], true, 4),
    CONTRACT: new CardName("Contract", Expansions.ALLIES, new Cost(5), [Types.TREASURE, Types.DURATION, Types.LIAISON], true, 6),
    EMISSARY: new CardName("Emissary", Expansions.ALLIES, new Cost(5), [Types.ACTION, Types.LIAISON], true, 3),
    BAUBLE: new CardName("Bauble", Expansions.ALLIES, new Cost(2), [Types.TREASURE, Types.LIAISON], true, 6),
    BROKER: new CardName("Broker", Expansions.ALLIES, new Cost(4), [Types.ACTION, Types.LIAISON], true, 1),
    GUILDMASTER: new CardName("Guildmaster", Expansions.ALLIES, new Cost(5), [Types.ACTION, Types.LIAISON], true, 4),

    ARCHITECTS_GUILD: new CardName("Architects Guild", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    ORDER_OF_ASTROLOGERS: new CardName("Order of Astrologers", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    LEAGUE_OF_BANKERS: new CardName("League of Bankers", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    MOUNTAIN_FOLK: new CardName("Mountain Folk", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    WOODWORKERS_GUILD: new CardName("Woodworkers Guild", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    PEACEFUL_CULT: new CardName("Peaceful Cult", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    CRAFTERS_GUILD: new CardName("Crafters Guild", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    CAVE_DWELLERS: new CardName("Cave Dwellers", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    PLATEAU_SHEPHERDS: new CardName("Plateau Shepherds", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    DESERT_GUIDES: new CardName("Desert Guides", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    FAMILY_OF_INVENTORS: new CardName("Family of Inventors", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    ISLAND_FOLK: new CardName("Island Folk", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    ORDER_OF_MASONS: new CardName("Order of Masons", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    BAND_OF_NOMADS: new CardName("Band of Nomads", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    GANG_OF_PICKPOCKETS: new CardName("Gang of Pickpockets", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    MARKET_TOWNS: new CardName("Market Towns", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    FOREST_DWELLERS: new CardName("Forest Dwellers", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    FELLOWSHIP_OF_SCRIBES: new CardName("Fellowship of Scribes", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    LEAGUE_OF_SHOPKEEPERS: new CardName("League of Shopkeepers", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    COASTAL_HAVEN: new CardName("Coastal Haven", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    CITY_STATE: new CardName("City-state", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    TRAPPERS_LODGE: new CardName("Trappers Lodge", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),
    CIRCLE_OF_WITCHES: new CardName("Circle of Witches", Expansions.ALLIES, new Cost(0), [Types.ALLY], true, 9),

    AUGUR_PILE: new CardName("Augur Pile", Expansions.ALLIES, new Cost(3), [Types.ACTION, Types.AUGUR, Types.RANDOMIZER], true, 9),
    HERB_GATHERER: new CardName("Herb Gatherer", Expansions.ALLIES, new Cost(3), [Types.ACTION, Types.AUGUR], false, 4),
    ACOLYTE: new CardName("Acolyte", Expansions.ALLIES, new Cost(4), [Types.ACTION, Types.AUGUR], false, 4),
    SORCERESS: new CardName("Sorceress", Expansions.ALLIES, new Cost(5), [Types.ACTION, Types.ATTACK, Types.AUGUR], false, 3),
    SIBYL: new CardName("Sibyl", Expansions.ALLIES, new Cost(6), [Types.ACTION, Types.AUGUR], false, 3),

    CLASH_PILE: new CardName("Clash Pile", Expansions.ALLIES, new Cost(3), [Types.ACTION, Types.CLASH, Types.RANDOMIZER], true, 9),
    BATTLE_PLAN: new CardName("Battle Plan", Expansions.ALLIES, new Cost(3), [Types.ACTION, Types.CLASH], false, 3),
    ARCHER: new CardName("Archer", Expansions.ALLIES, new Cost(4), [Types.ACTION, Types.ATTACK, Types.CLASH], false, 4),
    WARLORD: new CardName("Warlord", Expansions.ALLIES, new Cost(5), [Types.ACTION, Types.DURATION, Types.ATTACK, Types.CLASH], false, 3),
    TERRITORY: new CardName("Territory", Expansions.ALLIES, new Cost(6), [Types.VICTORY, Types.CLASH], false, 8),

    FORT_PILE: new CardName("Fort Pile", Expansions.ALLIES, new Cost(3), [Types.ACTION, Types.FORT, Types.RANDOMIZER], true, 9),
    TENT: new CardName("Tent", Expansions.ALLIES, new Cost(3), [Types.ACTION, Types.FORT], false, 4),
    GARRISON: new CardName("Garrison", Expansions.ALLIES, new Cost(4), [Types.ACTION, Types.DURATION, Types.FORT], false, 4),
    HILL_FORT: new CardName("Hill Fort", Expansions.ALLIES, new Cost(5), [Types.ACTION, Types.FORT], false, 3),
    STRONGHOLD: new CardName("Stronghold", Expansions.ALLIES, new Cost(6), [Types.ACTION, Types.VICTORY, Types.DURATION, Types.FORT], false, 2),

    ODYSSEY_PILE: new CardName("Odyssey Pile", Expansions.ALLIES, new Cost(3), [Types.ACTION, Types.ODYSSEY, Types.RANDOMIZER], true, 9),
    OLD_MAP: new CardName("Old Map", Expansions.ALLIES, new Cost(3), [Types.ACTION, Types.ODYSSEY], false, 3),
    VOYAGE: new CardName("Voyage", Expansions.ALLIES, new Cost(4), [Types.ACTION, Types.DURATION, Types.ODYSSEY], false, 3),
    SUNKEN_TREASURE: new CardName("Sunken Treasure", Expansions.ALLIES, new Cost(5), [Types.TREASURE, Types.ODYSSEY], false, 6),
    DISTANT_SHORE: new CardName("Distant Shore", Expansions.ALLIES, new Cost(6), [Types.ACTION, Types.VICTORY, Types.ODYSSEY], false, 3),

    TOWNSFOLK_PILE: new CardName("Townsfolk Pile", Expansions.ALLIES, new Cost(2), [Types.ACTION, Types.TOWNSFOLK, Types.RANDOMIZER], true, 9),
    TOWN_CRIER: new CardName("Town Crier", Expansions.ALLIES, new Cost(2), [Types.ACTION, Types.TOWNSFOLK], false, 3),
    BLACKSMITH: new CardName("Blacksmith", Expansions.ALLIES, new Cost(3), [Types.ACTION, Types.TOWNSFOLK], false, 3),
    MILLER: new CardName("Miller", Expansions.ALLIES, new Cost(4), [Types.ACTION, Types.TOWNSFOLK], false, 3),
    ELDER: new CardName("Elder", Expansions.ALLIES, new Cost(5), [Types.ACTION, Types.TOWNSFOLK], false, 4),

    WIZARD_PILE: new CardName("Wizard Pile", Expansions.ALLIES, new Cost(3), [Types.ACTION, Types.WIZARD, Types.RANDOMIZER], true, 9),
    STUDENT: new CardName("Student", Expansions.ALLIES, new Cost(3), [Types.ACTION, Types.WIZARD, Types.LIAISON], false, 3),
    CONJURER: new CardName("Conjurer", Expansions.ALLIES, new Cost(4), [Types.ACTION, Types.DURATION, Types.WIZARD], false, 4),
    SORCERER: new CardName("Sorcerer", Expansions.ALLIES, new Cost(5), [Types.ACTION, Types.ATTACK, Types.WIZARD], false, 3),
    LICH: new CardName("Lich", Expansions.ALLIES, new Cost(6), [Types.ACTION, Types.WIZARD], false, 1),

    CHARLATAN_CURSE: new CardName("Charlatan Curse", Expansions.PROSPERITY, new Cost(0), [], false, 9, true),

    CAGE: new CardName("Cage", Expansions.PLUNDER, new Cost(2), [Types.TREASURE, Types.DURATION], true, 6),
    GROTTO: new CardName("Grotto", Expansions.PLUNDER, new Cost(2), [Types.ACTION, Types.DURATION], true, 4),
    JEWELLED_EGG: new CardName("Jewelled Egg", Expansions.PLUNDER, new Cost(2), [Types.TREASURE], true, 5),
    SEARCH: new CardName("Search", Expansions.PLUNDER, new Cost(2), [Types.ACTION, Types.DURATION], true, 4),
    SHAMAN: new CardName("Shaman", Expansions.PLUNDER, new Cost(2), [Types.ACTION], true, 3),
    SECLUDED_SHRINE: new CardName("Secluded Shrine", Expansions.PLUNDER, new Cost(3), [Types.ACTION, Types.DURATION], true, 4),
    SIREN: new CardName("Siren", Expansions.PLUNDER, new Cost(3), [Types.ACTION, Types.DURATION, Types.ATTACK], true, 4),
    STOWAWAY: new CardName("Stowaway", Expansions.PLUNDER, new Cost(3), [Types.ACTION, Types.DURATION, Types.REACTION], true, 4),
    TASKMASTER: new CardName("Taskmaster", Expansions.PLUNDER, new Cost(3), [Types.ACTION, Types.DURATION], true, 3),
    ABUNDANCE: new CardName("Abundance", Expansions.PLUNDER, new Cost(4), [Types.TREASURE, Types.DURATION], true, 6),
    CABIN_BOY: new CardName("Cabin Boy", Expansions.PLUNDER, new Cost(4), [Types.ACTION, Types.DURATION], true, 3),
    CRUCIBLE: new CardName("Crucible", Expansions.PLUNDER, new Cost(4), [Types.TREASURE], true, 6),
    FLAGSHIP: new CardName("Flagship", Expansions.PLUNDER, new Cost(4), [Types.ACTION, Types.DURATION, Types.COMMAND], true, 4),
    FORTUNE_HUNTER: new CardName("Fortune Hunter", Expansions.PLUNDER, new Cost(4), [Types.ACTION], true, 4),
    GONDOLA: new CardName("Gondola", Expansions.PLUNDER, new Cost(4), [Types.TREASURE, Types.DURATION], true, 5),
    HARBOR_VILLAGE: new CardName("Harbor Village", Expansions.PLUNDER, new Cost(4), [Types.ACTION], true, 1),
    LANDING_PARTY: new CardName("Landing Party", Expansions.PLUNDER, new Cost(4), [Types.ACTION, Types.DURATION], true, 1),
    MAPMAKER: new CardName("Mapmaker", Expansions.PLUNDER, new Cost(4), [Types.ACTION, Types.REACTION], true, 2),
    MAROON: new CardName("Maroon", Expansions.PLUNDER, new Cost(4), [Types.ACTION], true, 4),
    ROPE: new CardName("Rope", Expansions.PLUNDER, new Cost(4), [Types.TREASURE, Types.DURATION], true, 6),
    SWAMP_SHACKS: new CardName("Swamp Shacks", Expansions.PLUNDER, new Cost(4), [Types.ACTION], true, 1),
    TOOLS: new CardName("Tools", Expansions.PLUNDER, new Cost(4), [Types.TREASURE], true, 6),
    BURIED_TREASURE: new CardName("Buried Treasure", Expansions.PLUNDER, new Cost(5), [Types.TREASURE, Types.DURATION], true, 5),
    CREW: new CardName("Crew", Expansions.PLUNDER, new Cost(5), [Types.ACTION, Types.DURATION], true, 2),
    CUTTHROAT: new CardName("Cutthroat", Expansions.PLUNDER, new Cost(5), [Types.ACTION, Types.DURATION, Types.ATTACK], true, 4),
    ENLARGE: new CardName("Enlarge", Expansions.PLUNDER, new Cost(5), [Types.ACTION, Types.DURATION], true, 4),
    FIGURINE: new CardName("Figurine", Expansions.PLUNDER, new Cost(5), [Types.TREASURE], true, 6),
    FIRST_MATE: new CardName("First Mate", Expansions.PLUNDER, new Cost(5), [Types.ACTION], true, 1),
    FRIGATE: new CardName("Frigate", Expansions.PLUNDER, new Cost(5), [Types.ACTION, Types.DURATION, Types.ATTACK], true, 4),
    LONGSHIP: new CardName("Longship", Expansions.PLUNDER, new Cost(5), [Types.ACTION, Types.DURATION], true, 1),
    MINING_ROAD: new CardName("Mining Road", Expansions.PLUNDER, new Cost(5), [Types.ACTION], true, 3),
    PENDANT: new CardName("Pendant", Expansions.PLUNDER, new Cost(5), [Types.TREASURE], true, 6),
    PICKAXE: new CardName("Pickaxe", Expansions.PLUNDER, new Cost(5), [Types.TREASURE], true, 6),
    PILGRIM: new CardName("Pilgrim", Expansions.PLUNDER, new Cost(5), [Types.ACTION], true, 2),
    QUARTERMASTER: new CardName("Quartermaster", Expansions.PLUNDER, new Cost(5), [Types.ACTION, Types.DURATION], true, 4),
    SILVER_MINE: new CardName("Silver Mine", Expansions.PLUNDER, new Cost(5), [Types.TREASURE], true, 6),
    TRICKSTER: new CardName("Trickster", Expansions.PLUNDER, new Cost(5), [Types.ACTION, Types.ATTACK], true, 4),
    WEALTHY_VILLAGE: new CardName("Wealthy Village", Expansions.PLUNDER, new Cost(5), [Types.ACTION], true, 1),
    SACK_OF_LOOT: new CardName("Sack of Loot", Expansions.PLUNDER, new Cost(6), [Types.TREASURE], true, 6),
    KINGS_CACHE: new CardName("King's Cache", Expansions.PLUNDER, new Cost(7), [Types.TREASURE], true, 6),
    BURY: new CardName("Bury", Expansions.PLUNDER, new Cost(1), [Types.EVENT], true, 9),
    AVOID: new CardName("Avoid", Expansions.PLUNDER, new Cost(2), [Types.EVENT], true, 9),
    DELIVER: new CardName("Deliver", Expansions.PLUNDER, new Cost(2), [Types.EVENT], true, 9),
    PERIL: new CardName("Peril", Expansions.PLUNDER, new Cost(2), [Types.EVENT], true, 9),
    RUSH: new CardName("Rush", Expansions.PLUNDER, new Cost(2), [Types.EVENT], true, 9),
    FORAY: new CardName("Foray", Expansions.PLUNDER, new Cost(3), [Types.EVENT], true, 9),
    LAUNCH: new CardName("Launch", Expansions.PLUNDER, new Cost(3), [Types.EVENT], true, 9),
    MIRROR: new CardName("Mirror", Expansions.PLUNDER, new Cost(3), [Types.EVENT], true, 9),
    PREPARE: new CardName("Prepare", Expansions.PLUNDER, new Cost(3), [Types.EVENT], true, 9),
    SCROUNGE: new CardName("Scrounge", Expansions.PLUNDER, new Cost(3), [Types.EVENT], true, 9),
    JOURNEY: new CardName("Journey", Expansions.PLUNDER, new Cost(4), [Types.EVENT], true, 9),
    MAELSTROM: new CardName("Maelstrom", Expansions.PLUNDER, new Cost(4), [Types.EVENT], true, 9),
    LOOTING: new CardName("Looting", Expansions.PLUNDER, new Cost(6), [Types.EVENT], true, 9),
    INVASION: new CardName("Invasion", Expansions.PLUNDER, new Cost(10), [Types.EVENT], true, 9),
    PROSPER: new CardName("Prosper", Expansions.PLUNDER, new Cost(10), [Types.EVENT], true, 9),
    LOOT_PILE: new CardName("Loot Pile", Expansions.PLUNDER, new Cost(0), [], false, 9, true),
    AMPHORA: new CardName("Amphora", Expansions.PLUNDER, new Cost(7), [Types.TREASURE, Types.DURATION, Types.LOOT], false, 5),
    DOUBLOONS: new CardName("Doubloons", Expansions.PLUNDER, new Cost(7), [Types.TREASURE, Types.LOOT], false, 5),
    ENDLESS_CHALICE: new CardName("Endless Chalice", Expansions.PLUNDER, new Cost(7), [Types.TREASURE, Types.DURATION, Types.LOOT], false, 5),
    FIGUREHEAD: new CardName("Figurehead", Expansions.PLUNDER, new Cost(7), [Types.TREASURE, Types.DURATION, Types.LOOT], false, 5),
    HAMMER: new CardName("Hammer", Expansions.PLUNDER, new Cost(7), [Types.TREASURE, Types.LOOT], false, 5),
    INSIGNIA: new CardName("Insignia", Expansions.PLUNDER, new Cost(7), [Types.TREASURE, Types.LOOT], false, 5),
    JEWELS: new CardName("Jewels", Expansions.PLUNDER, new Cost(7), [Types.TREASURE, Types.DURATION, Types.LOOT], false, 5),
    ORB: new CardName("Orb", Expansions.PLUNDER, new Cost(7), [Types.TREASURE, Types.LOOT], false, 5),
    PRIZE_GOAT: new CardName("Prize Goat", Expansions.PLUNDER, new Cost(7), [Types.TREASURE, Types.LOOT], false, 5),
    PUZZLE_BOX: new CardName("Puzzle Box", Expansions.PLUNDER, new Cost(7), [Types.TREASURE, Types.LOOT], false, 5),
    SEXTANT: new CardName("Sextant", Expansions.PLUNDER, new Cost(7), [Types.TREASURE, Types.LOOT], false, 5),
    SHIELD: new CardName("Shield", Expansions.PLUNDER, new Cost(7), [Types.TREASURE, Types.REACTION, Types.LOOT], false, 5),
    SPELL_SCROLL: new CardName("Spell Scroll", Expansions.PLUNDER, new Cost(7), [Types.ACTION, Types.TREASURE, Types.LOOT], false, 5),
    STAFF: new CardName("Staff", Expansions.PLUNDER, new Cost(7), [Types.TREASURE, Types.LOOT], false, 5),
    SWORD: new CardName("Sword", Expansions.PLUNDER, new Cost(7), [Types.TREASURE, Types.ATTACK, Types.LOOT], false, 5),
    CHEAP: new CardName("Cheap", Expansions.PLUNDER, new Cost(0), [Types.TRAIT], true, 9),
    CURSED: new CardName("Cursed", Expansions.PLUNDER, new Cost(0), [Types.TRAIT], true, 9),
    FATED: new CardName("Fated", Expansions.PLUNDER, new Cost(0), [Types.TRAIT], true, 9),
    FAWNING: new CardName("Fawning", Expansions.PLUNDER, new Cost(0), [Types.TRAIT], true, 9),
    FRIENDLY: new CardName("Friendly", Expansions.PLUNDER, new Cost(0), [Types.TRAIT], true, 9),
    HASTY: new CardName("Hasty", Expansions.PLUNDER, new Cost(0), [Types.TRAIT], true, 9),
    INHERITED: new CardName("Inherited", Expansions.PLUNDER, new Cost(0), [Types.TRAIT], true, 9),
    INSPIRING: new CardName("Inspiring", Expansions.PLUNDER, new Cost(0), [Types.TRAIT], true, 9),
    NEARBY: new CardName("Nearby", Expansions.PLUNDER, new Cost(0), [Types.TRAIT], true, 9),
    PATIENT: new CardName("Patient", Expansions.PLUNDER, new Cost(0), [Types.TRAIT], true, 9),
    PIOUS: new CardName("Pious", Expansions.PLUNDER, new Cost(0), [Types.TRAIT], true, 9),
    RECKLESS: new CardName("Reckless", Expansions.PLUNDER, new Cost(0), [Types.TRAIT], true, 9),
    RICH: new CardName("Rich", Expansions.PLUNDER, new Cost(0), [Types.TRAIT], true, 9),
    SHY: new CardName("Shy", Expansions.PLUNDER, new Cost(0), [Types.TRAIT], true, 9),
    TIRELESS: new CardName("Tireless", Expansions.PLUNDER, new Cost(0), [Types.TRAIT], true, 9),

    FARMHANDS: new CardName("Farmhands", Expansions.CORNUCOPIA_GUILDS, new Cost(4), [Types.ACTION], true, 1),
    JOUST: new CardName("Joust", Expansions.CORNUCOPIA_GUILDS, new Cost(5), [Types.ACTION], true, 3),
    REWARDS_PILE: new CardName("Rewards Pile", Expansions.CORNUCOPIA_GUILDS, new Cost(0), [], false, 9, true),
    COURSER: new CardName("Courser", Expansions.CORNUCOPIA_GUILDS, new Cost(0), [Types.ACTION, Types.REWARD], false, 1),
    RENOWN: new CardName("Renown", Expansions.CORNUCOPIA_GUILDS, new Cost(0), [Types.ACTION, Types.REWARD], false, 4),
    CORONET: new CardName("Coronet", Expansions.CORNUCOPIA_GUILDS, new Cost(0), [Types.ACTION, Types.TREASURE, Types.REWARD], false, 1),
    DEMESNE: new CardName("Demesne", Expansions.CORNUCOPIA_GUILDS, new Cost(0), [Types.ACTION, Types.VICTORY, Types.REWARD], false, 1),
    HOUSECARL: new CardName("Housecarl", Expansions.CORNUCOPIA_GUILDS, new Cost(0), [Types.ACTION, Types.REWARD], false, 4),
    HUGE_TURNIP: new CardName("Huge Turnip", Expansions.CORNUCOPIA_GUILDS, new Cost(0), [Types.TREASURE, Types.REWARD], false, 4),
    FARRIER: new CardName("Farrier", Expansions.CORNUCOPIA_GUILDS, new Cost(2), [Types.ACTION], true, 3),
    INFIRMARY: new CardName("Infirmary", Expansions.CORNUCOPIA_GUILDS, new Cost(3), [Types.ACTION], true, 4),
    FOOTPAD: new CardName("Footpad", Expansions.CORNUCOPIA_GUILDS, new Cost(5), [Types.ACTION, Types.ATTACK], true, 4),

    FISHMONGER: new CardName("Fishmonger", Expansions.RISING_SUN, new Cost(2), [Types.ACTION, Types.SHADOW], true, 4),
    SNAKE_WITCH: new CardName("Snake Witch", Expansions.RISING_SUN, new Cost(2), [Types.ACTION, Types.ATTACK], true, 3),
    ARISTOCRAT: new CardName("Aristocrat", Expansions.RISING_SUN, new Cost(3), [Types.ACTION], true, 1),
    CRAFTSMAN: new CardName("Craftsman", Expansions.RISING_SUN, new Cost(3), [Types.ACTION], true, 4),
    RIVERBOAT: new CardName("Riverboat", Expansions.RISING_SUN, new Cost(3), [Types.ACTION, Types.DURATION], true, 4),
    ROOT_CELLAR: new CardName("Root Cellar", Expansions.RISING_SUN, new Cost(3), [Types.ACTION], true, 3),
    ALLEY: new CardName("Alley", Expansions.RISING_SUN, new Cost(4), [Types.ACTION, Types.SHADOW], true, 3),
    CHANGE: new CardName("Change", Expansions.RISING_SUN, new Cost(4), [Types.ACTION], true, 4),
    NINJA: new CardName("Ninja", Expansions.RISING_SUN, new Cost(4), [Types.ACTION, Types.ATTACK, Types.SHADOW], true, 4),
    POET: new CardName("Poet", Expansions.RISING_SUN, new Cost(4), [Types.ACTION, Types.OMEN], true, 3),
    RIVER_SHRINE: new CardName("River Shrine", Expansions.RISING_SUN, new Cost(4), [Types.ACTION, Types.OMEN], true, 4),
    RUSTIC_VILLAGE: new CardName("Rustic Village", Expansions.RISING_SUN, new Cost(4), [Types.ACTION, Types.OMEN], true, 1),
    GOLD_MINE: new CardName("Gold Mine", Expansions.RISING_SUN, new Cost(5), [Types.ACTION], true, 3),
    IMPERIAL_ENVOY: new CardName("Imperial Envoy", Expansions.RISING_SUN, new Cost(5), [Types.ACTION], true, 2),
    KITSUNE: new CardName("Kitsune", Expansions.RISING_SUN, new Cost(5), [Types.ACTION, Types.ATTACK, Types.OMEN], true, 1),
    LITTER: new CardName("Litter", Expansions.RISING_SUN, new Cost(5), [Types.ACTION], true, 1),
    RICE_BROKER: new CardName("Rice Broker", Expansions.RISING_SUN, new Cost(5), [Types.ACTION], true, 2),
    RONIN: new CardName("Ronin", Expansions.RISING_SUN, new Cost(5), [Types.ACTION, Types.SHADOW], true, 2),
    TANUKI: new CardName("Tanuki", Expansions.RISING_SUN, new Cost(5), [Types.ACTION, Types.SHADOW], true, 4),
    TEA_HOUSE: new CardName("Tea House", Expansions.RISING_SUN, new Cost(5), [Types.ACTION, Types.OMEN], true, 3),
    SAMURAI: new CardName("Samurai", Expansions.RISING_SUN, new Cost(6), [Types.ACTION, Types.DURATION, Types.ATTACK], true, 4),
    RICE: new CardName("Rice", Expansions.RISING_SUN, new Cost(7), [Types.TREASURE], true, 5),
    MOUNTAIN_SHRINE: new CardName("Mountain Shrine", Expansions.RISING_SUN, new Cost(0, 0, 5, false), [Types.ACTION, Types.OMEN], true, 2),
    DAIMYO: new CardName("Daimyo", Expansions.RISING_SUN, new Cost(0, 0, 6, false), [Types.ACTION, Types.COMMAND], true, 3),
    ARTIST: new CardName("Artist", Expansions.RISING_SUN, new Cost(0, 0, 8, false), [Types.ACTION], true, 3),
    APPROACHING_ARMY: new CardName("Approaching Army", Expansions.RISING_SUN, new Cost(0), [Types.PROPHECY], true, 9),
    BIDING_TIME: new CardName("Biding Time", Expansions.RISING_SUN, new Cost(0), [Types.PROPHECY], true, 9),
    BUREAUCRACY: new CardName("Bureaucracy", Expansions.RISING_SUN, new Cost(0), [Types.PROPHECY], true, 9),
    DIVINE_WIND: new CardName("Divine Wind", Expansions.RISING_SUN, new Cost(0), [Types.PROPHECY], true, 9),
    ENLIGHTENMENT: new CardName("Enlightenment", Expansions.RISING_SUN, new Cost(0), [Types.PROPHECY], true, 9),
    FLOURISHING_TRADE: new CardName("Flourishing Trade", Expansions.RISING_SUN, new Cost(0), [Types.PROPHECY], true, 9),
    GOOD_HARVEST: new CardName("Good Harvest", Expansions.RISING_SUN, new Cost(0), [Types.PROPHECY], true, 9),
    GREAT_LEADER: new CardName("Great Leader", Expansions.RISING_SUN, new Cost(0), [Types.PROPHECY], true, 9),
    GROWTH: new CardName("Growth", Expansions.RISING_SUN, new Cost(0), [Types.PROPHECY], true, 9),
    HARSH_WINTER: new CardName("Harsh Winter", Expansions.RISING_SUN, new Cost(0), [Types.PROPHECY], true, 9),
    KIND_EMPEROR: new CardName("Kind Emperor", Expansions.RISING_SUN, new Cost(0), [Types.PROPHECY], true, 9),
    PANIC: new CardName("Panic", Expansions.RISING_SUN, new Cost(0), [Types.PROPHECY], true, 9),
    PROGRESS: new CardName("Progress", Expansions.RISING_SUN, new Cost(0), [Types.PROPHECY], true, 9),
    RAPID_EXPANSION: new CardName("Rapid Expansion", Expansions.RISING_SUN, new Cost(0), [Types.PROPHECY], true, 9),
    SICKNESS: new CardName("Sickness", Expansions.RISING_SUN, new Cost(0), [Types.PROPHECY], true, 9),
    AMASS: new CardName("Amass", Expansions.RISING_SUN, new Cost(2), [Types.EVENT], true, 9),
    ASCETICISM: new CardName("Asceticism", Expansions.RISING_SUN, new Cost(2), [Types.EVENT], true, 9),
    CREDIT: new CardName("Credit", Expansions.RISING_SUN, new Cost(2), [Types.EVENT], true, 9),
    FORESIGHT: new CardName("Foresight", Expansions.RISING_SUN, new Cost(2), [Types.EVENT], true, 9),
    KINTSUGI: new CardName("Kintsugi", Expansions.RISING_SUN, new Cost(3), [Types.EVENT], true, 9),
    PRACTICE: new CardName("Practice", Expansions.RISING_SUN, new Cost(3), [Types.EVENT], true, 9),
    SEA_TRADE: new CardName("Sea Trade", Expansions.RISING_SUN, new Cost(4), [Types.EVENT], true, 9),
    RECEIVE_TRIBUTE: new CardName("Receive Tribute", Expansions.RISING_SUN, new Cost(5), [Types.EVENT], true, 9),
    GATHER: new CardName("Gather", Expansions.RISING_SUN, new Cost(7), [Types.EVENT], true, 9),
    CONTINUE: new CardName("Continue", Expansions.RISING_SUN, new Cost(0, 0, 8, false), [Types.EVENT], true, 9)

    /*
    1: +2+ actions
    2: terminal draw
    3: nonterminal
    4: terminals
    5: treasures
    6: special treasures
    7: night
    8: victory
    9: curses, ruins, things never in hand
    */

};

CardNames.COPPER.setCoinProduction(new Cost(1));
CardNames.SILVER.setCoinProduction(new Cost(2));
CardNames.GOLD.setCoinProduction(new Cost(3));
CardNames.PLATINUM.setCoinProduction(new Cost(5));
CardNames.POTION.setCoinProduction(new Cost(0, 1));

CardNames.FARM.setCoinProduction(new Cost(2));
CardNames.SPOILS.setCoinProduction(new Cost(3));
CardNames.CONTRABAND.setCoinProduction(new Cost(3));
CardNames.HOARD.setCoinProduction(new Cost(2));
CardNames.TALISMAN.setCoinProduction(new Cost(1));
CardNames.ILL_GOTTEN_GAINS.setCoinProduction(new Cost(1));
CardNames.PHILOSOPHERS_STONE.setCoinProduction(new Cost('?'));
CardNames.DIADEM.setCoinProduction(new Cost('?'));
CardNames.LOAN.setCoinProduction(new Cost(1));
CardNames.CACHE.setCoinProduction(new Cost(3));
CardNames.FOOLS_GOLD.setCoinProduction(new Cost('?'));
CardNames.QUARRY.setCoinProduction(new Cost(1));
CardNames.ROYAL_SEAL.setCoinProduction(new Cost(2));
CardNames.STASH.setCoinProduction(new Cost(2));
CardNames.VENTURE.setCoinProduction(new Cost(1));
CardNames.BANK.setCoinProduction(new Cost('?'));
CardNames.COUNTERFEIT.setCoinProduction(new Cost(1));
CardNames.MASTERPIECE.setCoinProduction(new Cost(1));
CardNames.COIN_OF_THE_REALM.setCoinProduction(new Cost(1));
CardNames.RELIC.setCoinProduction(new Cost(2));
CardNames.TREASURE_TROVE.setCoinProduction(new Cost(2));
CardNames.PLUNDER.setCoinProduction(new Cost(2));
CardNames.ROCKS.setCoinProduction(new Cost(1));
CardNames.FORTUNE.setCoinProduction(new Cost("x2"));
CardNames.CHARM.setCoinProduction(new Cost("?"));
CardNames.CAPITAL.setCoinProduction(new Cost(6));
CardNames.HUMBLE_CASTLE.setCoinProduction(new Cost(1));
CardNames.PASTURE.setCoinProduction(new Cost(1));
CardNames.MAGIC_LAMP.setCoinProduction(new Cost(1));
CardNames.LUCKY_COIN.setCoinProduction(new Cost(1));
CardNames.HAUNTED_MIRROR.setCoinProduction(new Cost(1));
CardNames.GOAT.setCoinProduction(new Cost(1));
CardNames.CURSED_GOLD.setCoinProduction(new Cost(3));
CardNames.IDOL.setCoinProduction(new Cost(2));
CardNames.POUCH.setCoinProduction(new Cost(1));
CardNames.SCEPTER.setCoinProduction(new Cost("?"));
CardNames.SPICES.setCoinProduction(new Cost(2));
CardNames.SUPPLIES.setCoinProduction(new Cost(1));
CardNames.STOCKPILE.setCoinProduction(new Cost(3));
CardNames.BAUBLE.setCoinProduction(new Cost("?"));
CardNames.CONTRACT.setCoinProduction(new Cost(2));
CardNames.SUNKEN_TREASURE.setCoinProduction(new Cost("?"));
CardNames.ANVIL.setCoinProduction(new Cost(1));
CardNames.COLLECTION.setCoinProduction(new Cost(2));
CardNames.CRYSTAL_BALL.setCoinProduction(new Cost(1));
CardNames.CAULDRON.setCoinProduction(new Cost(2));
CardNames.JEWELLED_EGG.setCoinProduction(new Cost("1"));
CardNames.CRUCIBLE.setCoinProduction(new Cost("?"));
CardNames.GONDOLA.setCoinProduction(new Cost("?"));
CardNames.ROPE.setCoinProduction(new Cost("1"));
CardNames.FIGURINE.setCoinProduction(new Cost("?"));
CardNames.PENDANT.setCoinProduction(new Cost("?"));
CardNames.PICKAXE.setCoinProduction(new Cost(1));
CardNames.SACK_OF_LOOT.setCoinProduction(new Cost("1"));
CardNames.AMPHORA.setCoinProduction(new Cost("?"));
CardNames.DOUBLOONS.setCoinProduction(new Cost(3));
CardNames.ENDLESS_CHALICE.setCoinProduction(new Cost(1));
CardNames.FIGUREHEAD.setCoinProduction(new Cost(3));
CardNames.HAMMER.setCoinProduction(new Cost(3));
CardNames.INSIGNIA.setCoinProduction(new Cost(3));
CardNames.JEWELS.setCoinProduction(new Cost(3));
CardNames.ORB.setCoinProduction(new Cost("?"));
CardNames.PRIZE_GOAT.setCoinProduction(new Cost(3));
CardNames.PUZZLE_BOX.setCoinProduction(new Cost(3));
CardNames.SEXTANT.setCoinProduction(new Cost(3));
CardNames.SHIELD.setCoinProduction(new Cost(3));
CardNames.STAFF.setCoinProduction(new Cost(3));
CardNames.SWORD.setCoinProduction(new Cost(3));
CardNames.HUGE_TURNIP.setCoinProduction(new Cost("?"));

CardNames.WAY_OF_THE_BUTTERFLY.changedImage = CardNames.CARD_OF_THE_BUTTERFLY;
CardNames.WAY_OF_THE_CAMEL.changedImage = CardNames.CARD_OF_THE_CAMEL;
CardNames.WAY_OF_THE_CHAMELEON.changedImage = CardNames.CARD_OF_THE_CHAMELEON;
CardNames.WAY_OF_THE_FROG.changedImage = CardNames.CARD_OF_THE_FROG;
CardNames.WAY_OF_THE_GOAT.changedImage = CardNames.CARD_OF_THE_GOAT;
CardNames.WAY_OF_THE_HORSE.changedImage = CardNames.CARD_OF_THE_HORSE;
CardNames.WAY_OF_THE_MOLE.changedImage = CardNames.CARD_OF_THE_MOLE;
CardNames.WAY_OF_THE_MONKEY.changedImage = CardNames.CARD_OF_THE_MONKEY;
CardNames.WAY_OF_THE_MOUSE.changedImage = CardNames.CARD_OF_THE_MOUSE;
CardNames.WAY_OF_THE_MULE.changedImage = CardNames.CARD_OF_THE_MULE;
CardNames.WAY_OF_THE_OTTER.changedImage = CardNames.CARD_OF_THE_OTTER;
CardNames.WAY_OF_THE_OWL.changedImage = CardNames.CARD_OF_THE_OWL;
CardNames.WAY_OF_THE_OX.changedImage = CardNames.CARD_OF_THE_OX;
CardNames.WAY_OF_THE_PIG.changedImage = CardNames.CARD_OF_THE_PIG;
CardNames.WAY_OF_THE_RAT.changedImage = CardNames.CARD_OF_THE_RAT;
CardNames.WAY_OF_THE_SEAL.changedImage = CardNames.CARD_OF_THE_SEAL;
CardNames.WAY_OF_THE_SHEEP.changedImage = CardNames.CARD_OF_THE_SHEEP;
CardNames.WAY_OF_THE_SQUIRREL.changedImage = CardNames.CARD_OF_THE_SQUIRREL;
CardNames.WAY_OF_THE_TURTLE.changedImage = CardNames.CARD_OF_THE_TURTLE;
CardNames.WAY_OF_THE_WORM.changedImage = CardNames.CARD_OF_THE_WORM;

var PAGE_UPGRADES = [CardNames.TREASURE_HUNTER, CardNames.WARRIOR, CardNames.HERO, CardNames.CHAMPION];
var PEASANT_UPGRADES = [CardNames.SOLDIER, CardNames.FUGITIVE, CardNames.DISCIPLE, CardNames.TEACHER];
var HEIRLOOM_CARDS = [CardNames.PIXIE, CardNames.TRACKER, CardNames.FOOL, CardNames.SECRET_CAVE, CardNames.FOOL, CardNames.CEMETERY, CardNames.SHEPHERD, CardNames.POOKA];
var TEMPORARY_IMAGES = [CardNames.CARD_OF_THE_BUTTERFLY, CardNames.CARD_OF_THE_CAMEL, CardNames.CARD_OF_THE_CHAMELEON, CardNames.CARD_OF_THE_FROG, CardNames.CARD_OF_THE_GOAT, CardNames.CARD_OF_THE_HORSE, CardNames.CARD_OF_THE_MOLE, CardNames.CARD_OF_THE_MONKEY, CardNames.CARD_OF_THE_MOUSE, CardNames.CARD_OF_THE_MULE, CardNames.CARD_OF_THE_OTTER, CardNames.CARD_OF_THE_OWL, CardNames.CARD_OF_THE_OX, CardNames.CARD_OF_THE_PIG, CardNames.CARD_OF_THE_RAT, CardNames.CARD_OF_THE_SEAL, CardNames.CARD_OF_THE_SHEEP, CardNames.CARD_OF_THE_SQUIRREL, CardNames.CARD_OF_THE_TURTLE, CardNames.CARD_OF_THE_WORM, CardNames.PIG];
var BOONS = [CardNames.THE_EARTHS_GIFT, CardNames.THE_FIELDS_GIFT, CardNames.THE_FLAMES_GIFT, CardNames.THE_FORESTS_GIFT, CardNames.THE_MOONS_GIFT, CardNames.THE_MOUNTAINS_GIFT, CardNames.THE_RIVERS_GIFT, CardNames.THE_SEAS_GIFT, CardNames.THE_SKYS_GIFT, CardNames.THE_SUNS_GIFT, CardNames.THE_SWAMPS_GIFT, CardNames.THE_WINDS_GIFT];

var basicVictoryCards = [CardNames.CURSE, CardNames.ESTATE, CardNames.DUCHY, CardNames.PROVINCE, CardNames.COLONY, CardNames.CHARLATAN_CURSE];

var basicTreasureCards = [CardNames.COPPER, CardNames.SILVER, CardNames.GOLD, CardNames.POTION, CardNames.PLATINUM];

var baseCards = basicVictoryCards.concat(basicTreasureCards);

var BANNED_CARDS = [CardNames.STASH];