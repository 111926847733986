"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var STRING_ANSWER_TYPES = ['text', 'select', 'select-buttons'];
var ANSWER_TYPES = ['boolean', 'info'].concat(STRING_ANSWER_TYPES);
var QUESTION_ERROR_CODES = {

    STRING_EXPECTED: 'Given answer is not a String',
    NUMBER_EXPECTED: 'Given answer is not a Number',
    INVALID_ANSWER: 'Given answer is not valid (cause unknown)',
    INVALID_ANSWER_TYPE: 'Type of given answer is not correct',
    TYPE_DOES_NOT_EXIST: 'Parameter type must be one of: ' + ANSWER_TYPES.join(', '),
    QUESTION_EXPECTED: 'Given value is not of class Question'
};

var RelatedQuestion = function () {
    function RelatedQuestion(string, type) {
        var validator = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;

        _classCallCheck(this, RelatedQuestion);

        this.errors = QUESTION_LIST_ERROR_CODES;
        this.errorMachine = new ErrorMachine(QUESTION_LIST_ERROR_CODES, QuestionList.name);

        if (ANSWER_TYPES.indexOf(type) === -1) {
            this.errorMachine.error(QUESTION_ERROR_CODES.TYPE_DOES_NOT_EXIST, type);
        }

        this.angularListController = undefined;
        this.redirectUrl = undefined;
        this.validator = validator;
        this.text = string;
        this.required = true;
        this.answerType = type;
        this.answered = type === 'info';
        this.visited = false;
        this.relations = [];
        this.answerLabels = [];
        this.answerDescriptions = [];
        this.answerObjects = [];
        this.answer = undefined;
        this.changedFunction = undefined;
        this.currentFunction = undefined;
        this.htmlUrl = undefined; // Template for full view question.
        this.htmlKnowledge = undefined; // Extra knowledge, Javascript Object, to be presented in full view.
        this.questionList = undefined;
        this.label = undefined;
        this.errorText = undefined;
        this.shortText = this.text;

        if (this.validator === undefined) {
            this.validator = this.typeValidator;
        }
    }

    _createClass(RelatedQuestion, [{
        key: 'setErrorText',
        value: function setErrorText(text) {

            this.errorText = text;
        }
    }, {
        key: 'errorMessage',
        value: function errorMessage() {

            return isUndefined(this.errorText) ? '' : this.errorText;
        }
    }, {
        key: 'setCurrentFunction',
        value: function setCurrentFunction(method) {

            this.currentFunction = method;
        }
    }, {
        key: 'onBecommingCurrent',
        value: function onBecommingCurrent() {

            if (isDefined(this.currentFunction)) {
                this.currentFunction();
            }
        }
    }, {
        key: 'setValidator',
        value: function setValidator(validatorFunction, errorMessage) {

            this.validator = validatorFunction;
        }
    }, {
        key: 'setHtmlKnowledge',
        value: function setHtmlKnowledge(object) {
            this.htmlKnowledge = object;
        }
    }, {
        key: 'validAnswer',
        value: function validAnswer() {

            if (this.answer === undefined) {
                return true;
            }

            return this.validator(this.answer) && this.relations.every(function (relation) {
                return relation.validAnswerCombination();
            });
        }
    }, {
        key: 'index',
        value: function index() {
            return this.questionList === undefined ? -1 : this.questionList.toArray().indexOf(this);
        }
    }, {
        key: 'isLast',
        value: function isLast() {
            return this.questionList === undefined ? false : this.questionList.lastQuestion() === this;
        }
    }, {
        key: 'isCurrent',
        value: function isCurrent() {
            return this.questionList === undefined ? false : this.questionList.currentQuestion() === this;
        }
    }, {
        key: 'isVisited',
        value: function isVisited() {
            return this.visited;
        }
    }, {
        key: 'isRequired',
        value: function isRequired() {
            return this.required;
        }
    }, {
        key: 'isAnswered',
        value: function isAnswered() {
            return this.answered || this.answerType === 'info' && this.isVisited();
        }
    }, {
        key: 'hasError',
        value: function hasError() {
            return this.isAnswered() && !this.validAnswer();
        }
    }, {
        key: 'hasWarning',
        value: function hasWarning() {
            return this.isRequired() && !this.isAnswered() && this.afterCurrent();
        }
    }, {
        key: 'beforeCurrent',
        value: function beforeCurrent() {
            return this.questionList !== undefined && this.questionList.beforeCurrent(this);
        }
    }, {
        key: 'afterCurrent',
        value: function afterCurrent() {
            return this.questionList !== undefined && this.questionList.afterCurrent(this);
        }
    }, {
        key: 'hasHtmlUrl',
        value: function hasHtmlUrl() {
            return this.htmlUrl && this.htmlUrl !== '';
        }
    }, {
        key: 'hasLabel',
        value: function hasLabel() {
            return this.label && this.label !== '';
        }
    }, {
        key: 'toString',
        value: function toString() {
            return this.text;
        }
    }, {
        key: 'answerIndex',
        value: function answerIndex() {
            return this.answerLabels.indexOf(this.answer);
        }
    }, {
        key: 'answerObject',
        value: function answerObject() {
            return this.answerObjects[this.answerIndex()];
        }
    }, {
        key: 'hasRedirectUrl',
        value: function hasRedirectUrl() {
            return this.redirectUrl !== undefined;
        }
    }, {
        key: 'priorQuestions',
        value: function priorQuestions() {

            var self = this;

            if (this.questionList === undefined) {
                return [];
            } else {
                return this.questionList.toArray().filter(function (question) {
                    return question.index() < self.index();
                });
            }
        }
    }, {
        key: 'isNotCurrentAndAnswered',
        value: function isNotCurrentAndAnswered() {
            return !this.isCurrent() && this.isAnswered();
        }
    }, {
        key: 'isNotCurrentAndNotAnswered',
        value: function isNotCurrentAndNotAnswered() {
            return !this.isCurrent() && !this.isAnswered();
        }
    }, {
        key: 'setShortText',
        value: function setShortText(text) {
            this.shortText = text;
        }
    }, {
        key: 'setHtmlUrl',
        value: function setHtmlUrl(url) {
            this.htmlUrl = url;
        }
    }, {
        key: 'setLabel',
        value: function setLabel(label) {
            this.label = label;
        }
    }, {
        key: 'setRequired',
        value: function setRequired() {
            var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
            this.required = value;
        }
    }, {
        key: 'setVisited',
        value: function setVisited() {
            var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
            this.visited = value;
        }
    }, {
        key: 'addRelation',
        value: function addRelation(relation) {
            this.relations.push(relation);
        }
    }, {
        key: 'labelIndex',
        value: function labelIndex(label) {
            return this.answerLabels.indexOf(label);
        }
    }, {
        key: 'labelToObject',
        value: function labelToObject(label) {
            return this.answerObjects[this.labelIndex(label)];
        }

        // Use translated text when you give this labels.

    }, {
        key: 'setAnswerLabels',
        value: function setAnswerLabels() {
            var _this = this;

            var array = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];


            array.forEach(function (answer) {

                if (!_this.typeValidator(answer)) {
                    throw _this.errorMachine.error(QUESTION_ERROR_CODES.INVALID_ANSWER_TYPE, answer);
                }
            });

            this.answerLabels = array;
        }
    }, {
        key: 'answerText',
        value: function answerText() {

            if (isUndefined(this.answer)) {
                return '-';
            }

            var object = this.answerObject() || this.answer;

            // if (object instanceof Duration) { return object.endDate().toDateString(); }

            return this.answerLabel();
        }
    }, {
        key: 'setAnswerObjects',
        value: function setAnswerObjects(array) {
            var _this2 = this;

            var relabel = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;


            this.answerObjects = array;

            if (this.answerLabels.length === 0 || relabel) {
                this.answerLabels = array.map(function (object) {
                    return _this2.objectToAnswerLabel(object);
                });
            }
            if (this.answerObjects.some(function (o) {
                return o.description;
            })) {
                this.answerDescriptions = array.map(function (object) {
                    return object.description;
                });
            }
        }
    }, {
        key: 'objectToAnswerLabel',
        value: function objectToAnswerLabel(object) {

            if (typeof object === 'string') {
                return object;
            }
            if (object.name !== undefined) {
                return object.name;
            }
            if (object.label !== undefined) {
                return object.label;
            }

            return object.toString();
        }
    }, {
        key: 'answerLabel',
        value: function answerLabel() {

            if (this.answer === undefined) {
                return 'undefined';
            }
            if (this.answerLabels.length > 1) {
                return this.objectToAnswerLabel(this.answer);
            }
            if (this.answerType === 'text') {
                return this.answer;
            }
            if (typeof this.answer === 'string') {
                return this.answer;
            }
            if (this.answerType === 'boolean') {
                return this.answer ? getPhrase(PHRASES.TRUE) : getPhrase(PHRASES.FALSE);
            }

            return this.answer.toString();
        }
    }, {
        key: 'commaSeperatedAnswerLabels',
        value: function commaSeperatedAnswerLabels() {

            var strings = this.answerLabels.map(function (answer) {
                return answer.toString();
            });

            return strings.join(',');
        }
    }, {
        key: 'typeValidator',
        value: function typeValidator(answer) {

            return STRING_ANSWER_TYPES.indexOf(this.answerType) !== -1 ? typeof answer === 'string' : (typeof answer === 'undefined' ? 'undefined' : _typeof(answer)) === this.answerType;
        }
    }, {
        key: 'setAnswer',
        value: function setAnswer(answer) {
            var setRelatedAnswers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
            var validateAnswer = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;


            this.questionList.tryToBeInitialQuestion(this);

            if (!this.typeValidator(answer)) {

                console.log('Expected answer of type ' + this.answerType);
                throw this.errorMachine.error(QUESTION_ERROR_CODES.INVALID_ANSWER_TYPE, answer);
            }

            this.answer = answer;
            this.setAnswered();

            if (setRelatedAnswers) {
                this.relations.forEach(function (relation) {
                    return relation.setLinkedAnswer();
                });
            }

            if (this.changedFunction !== undefined) {
                this.changedFunction();
            }
            if (validateAnswer && !this.validAnswer(answer)) {
                timeLog(this.errorMachine.error(QUESTION_ERROR_CODES.INVALID_ANSWER, answer));
            }

            if (this.questionList.isInitialQuestion(this)) {
                this.questionList.onChangeDo();
            };
        }
    }, {
        key: 'visitAndAnswer',
        value: function visitAndAnswer(answer) {
            var setRelatedAnswers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
            this.setAnswer(answer, setRelatedAnswers);this.setVisited(true);
        }
    }, {
        key: 'setAnswered',
        value: function setAnswered() {

            if (this.answer === undefined) {
                this.answered = false;return;
            }
            if (this.answer === null) {
                this.answered = false;return;
            }

            if (this.answerType === 'text' && this.answer === '') {
                this.answered = false;return;
            }
            if (this.answerType === 'select' && this.answer === '') {
                this.answered = false;return;
            }

            this.answered = true;
        }
    }, {
        key: 'relateQuestion',
        value: function relateQuestion(otherQuestion, answerValidator, answerLinkage) {

            var relation = new QuestionRelation(this, otherQuestion, answerValidator, answerLinkage);

            this.addRelation(relation);
        }
    }], [{
        key: 'validDateAnswer',
        value: function validDateAnswer(answer) {
            return answer instanceof Date;
        }
    }]);

    return RelatedQuestion;
}();