"use strict";

var FrenchPhrases = {};

FrenchPhrases[Phrases.THE] = "le";
FrenchPhrases[Phrases.THE_FROM] = "le";
FrenchPhrases[Phrases.THE_TO] = "le";
FrenchPhrases[Phrases.AND] = "et";
FrenchPhrases[Phrases.OR] = "ou";
FrenchPhrases[Phrases.OTHER] = "autre";
FrenchPhrases[Phrases.ANOTHER] = "un autre";
FrenchPhrases[Phrases.PILE] = "pile";
FrenchPhrases[Phrases.QUICK_UNDO] = "Annuler 1";
FrenchPhrases[Phrases.UNDO_BUTTON] = "Retour arrière";
FrenchPhrases[Phrases.UNDO] = "Annuler";
FrenchPhrases[Phrases.YOU] = "Vous";
FrenchPhrases[Phrases.WOULD] = "would";
FrenchPhrases[Phrases.YOU_MAY] = "Vous pouvez...";
FrenchPhrases[Phrases.COMMA] = ", ";
FrenchPhrases[Phrases.PERIOD] = ".";
FrenchPhrases[Phrases.COMPLEX_STORY_LINE] = "FIRST, SECOND";
FrenchPhrases[Phrases.COIN] = "Pièce";
FrenchPhrases[Phrases.COINS] = "Pièces";
FrenchPhrases[Phrases.COINS_MANY] = "Pièces";
FrenchPhrases[Phrases.POTION] = "Potion";
FrenchPhrases[Phrases.POTIONS] = "Potions";
FrenchPhrases[Phrases.POTIONS_MANY] = "Potions";
FrenchPhrases[Phrases.DEBT] = "Dette";

FrenchPhrases[Phrases.VERSION] = "Version";
FrenchPhrases[Phrases.USERNAME] = "Nom d'utilisateur";
FrenchPhrases[Phrases.PASSWORD] = "Mot de passe";
FrenchPhrases[Phrases.CONFIRM_PASSWORD] = "Confirmer le mot de passe";
FrenchPhrases[Phrases.FORGOT_YOUR_PASSWORD] = "Mot de passe oublié ?";
FrenchPhrases[Phrases.CREATE_ACCOUNT] = "Créer un compte";
FrenchPhrases[Phrases.EMAIL] = "E-mail";
FrenchPhrases[Phrases.SIGN_UP] = "S'inscrire";
FrenchPhrases[Phrases.LOGIN] = "Login";
FrenchPhrases[Phrases.KICK] = "Exclure";
FrenchPhrases[Phrases.RESIGN] = "Abandonner";
FrenchPhrases[Phrases.CREATE_TABLE] = "Créer une table";
FrenchPhrases[Phrases.LEAVE_TABLE] = "Quitter la table";
FrenchPhrases[Phrases.MESSAGE] = "message";
FrenchPhrases[Phrases.MESSAGE_SPECTATORS] = "message aux spectateurs";
FrenchPhrases[Phrases.MESSAGE_PLAYERS] = "message aux joueurs";
FrenchPhrases[Phrases.START_GAME] = "Commencer la partie";
FrenchPhrases[Phrases.READY] = "Prêt";
FrenchPhrases[Phrases.NOT_READY] = "Pas prêt";
FrenchPhrases[Phrases.PLAYERS] = "Joueurs";
FrenchPhrases[Phrases.SPECTATORS] = "Spectateurs";
FrenchPhrases[Phrases.HOST] = "Hôte";
FrenchPhrases[Phrases.RANDOM] = "Au hasard";
FrenchPhrases[Phrases.BASIC_OPTIONS] = "Options basiques";
FrenchPhrases[Phrases.ADVANCED_OPTIONS] = "Options avancées";
FrenchPhrases[Phrases.YES] = "Oui";
FrenchPhrases[Phrases.NO] = "Non";
FrenchPhrases[Phrases.FRIENDS_ONLY] = "Seulement les amis";
FrenchPhrases[Phrases.EVENTS_AND_LANDMARKS] = "Événements et Jalons";
FrenchPhrases[Phrases.TRUE] = "vrai";
FrenchPhrases[Phrases.FALSE] = "faux";
FrenchPhrases[Phrases.QUICK_MATCH] = "Appariement rapide";
FrenchPhrases[Phrases.GOOD_MATCH] = "Appariement de qualité";
FrenchPhrases[Phrases.CANCEL] = "Annuler";
FrenchPhrases[Phrases.VIEW_TRASH] = "Rebut";
FrenchPhrases[Phrases.VIEW_KINGDOM] = "Royaume";
FrenchPhrases[Phrases.VIEW_PLAY_AREA] = "Zone de jeu";
FrenchPhrases[Phrases.LEAVE] = "Quitter";
FrenchPhrases[Phrases.TOTAL_IN_QUEUE] = "Utilisateurs dans la file d'attente";
FrenchPhrases[Phrases.EDIT_TABLE] = "Éditer la table";
FrenchPhrases[Phrases.NEED_HELP] = "Besoin d'aide ?";
FrenchPhrases[Phrases.PLAY] = "Jouer";
FrenchPhrases[Phrases.MAKE_RESIGN] = "Forcer USERNAME à abandonner";
FrenchPhrases[Phrases.UNDO_REQUEST_SINGULAR] = "Demande d'annuler 1 étape";
FrenchPhrases[Phrases.UNDO_REQUEST_PLURAL] = "Demande d'annuler NUMBER étapes";
FrenchPhrases[Phrases.UNDO_REQUEST_MANY] = "Demande d'annuler NUMBER étapes";
FrenchPhrases[Phrases.PENDING_UNDO_REQUEST] = "Demande d'annulation en cours...";
FrenchPhrases[Phrases.SPECTATOR_UNDO_REQUEST] = "USERNAME a demandé une annulation";
FrenchPhrases[Phrases.GRANT] = "Accorder";
FrenchPhrases[Phrases.ACCEPT] = "Accepter";
FrenchPhrases[Phrases.DECLINE] = "Refuser";
FrenchPhrases[Phrases.DENY] = "Refuser";
FrenchPhrases[Phrases.UNDO_DENIED] = "Demande d'annulation refusée.";
FrenchPhrases[Phrases.UNDO_CANCELLED] = "Demande d'annulation annulée.";
FrenchPhrases[Phrases.TOC_SENTENCE] = "J'ai lu et accepté les TERMS_OF_SERVICE.";
FrenchPhrases[Phrases.TERMS_OF_SERVICE] = "Conditions d'utilisation";
FrenchPhrases[Phrases.TERMS_OF_SERVICE_URL] = ""; /* edit it after writing translated terms of service */
FrenchPhrases[Phrases.RECONNECTING] = "Reconnexion...";
FrenchPhrases[Phrases.PLAYER_JOINED_TABLE] = "PLAYER_NAME a rejoint la table.";
FrenchPhrases[Phrases.PLAYER_LEFT_TABLE] = "PLAYER_NAME a quitté la table.";
FrenchPhrases[Phrases.JOINING_GAME_MESSAGE] = "En train de rejoindre la partie #NUMBER sur GAMESERVER_NAME.";
FrenchPhrases[Phrases.SIGNING_UP] = "Enregistrement en cours...";
FrenchPhrases[Phrases.ACTION] = "Action";
FrenchPhrases[Phrases.ACTIONS] = "Actions";
FrenchPhrases[Phrases.BUY] = "Achat";
FrenchPhrases[Phrases.BUYS] = "Achats";
FrenchPhrases[Phrases.KICK_SUCCEEDED] = "Exclusion réussie";
FrenchPhrases[Phrases.CONNECTION_CLOSED] = "La connexion au serveur a été interrompue ou n'a pas pu être établie.";
FrenchPhrases[Phrases.NEW_PASSWORD] = "Nouveau mot de passe";
FrenchPhrases[Phrases.RETRY] = "Réessayer";
FrenchPhrases[Phrases.FRIEND] = "Ami";
FrenchPhrases[Phrases.ACTIVITY] = "Activité";
FrenchPhrases[Phrases.STATUS] = "État";
FrenchPhrases[Phrases.PURCHASE] = "Achat";
FrenchPhrases[Phrases.UNTIL] = "jusqu'à";
FrenchPhrases[Phrases.BUY_FOR_AMOUNT] = "Acheter pour AMOUNT";
FrenchPhrases[Phrases.CHANGE_PASSWORD] = "Changer";
FrenchPhrases[Phrases.HIDE] = "Cacher";
FrenchPhrases[Phrases.CONFIRM] = "Confirmer";
FrenchPhrases[Phrases.JOIN] = "rejoint";
FrenchPhrases[Phrases.TURN] = "Tour";
FrenchPhrases[Phrases.CARD] = "Carte";
FrenchPhrases[Phrases.DESCRIPTION] = "Description";
FrenchPhrases[Phrases.SCORE] = "Score";
FrenchPhrases[Phrases.TURNS] = "Tours";
FrenchPhrases[Phrases.NOTHING] = "rien";
FrenchPhrases[Phrases.SPECTATE] = "Regarder";
FrenchPhrases[Phrases.FRIENDS] = "Amis";
FrenchPhrases[Phrases.FOLLOWING] = "Vous suivez";
FrenchPhrases[Phrases.FOLLOWERS] = "Vous êtes suivi par";
FrenchPhrases[Phrases.UNFRIEND] = "ne plus aimer";
FrenchPhrases[Phrases.UNFOLLOW] = "ne plus suivre";
FrenchPhrases[Phrases.BEFRIEND] = "être ami";
FrenchPhrases[Phrases.FRIENDS_EXPLANATION] = "Ami réciproque";
FrenchPhrases[Phrases.FOLLOWING_EXPLANATION] = "Vous les suivez";
FrenchPhrases[Phrases.FOLLOWERS_EXPLANATION] = "Ils vous suivent";
FrenchPhrases[Phrases.ADD] = "Ajouter";
FrenchPhrases[Phrases.TYPE_FRIEND] = "Entrez le nom d'un ami";
FrenchPhrases[Phrases.UNLOCKS] = "débloque";
FrenchPhrases[Phrases.SELECT_YOUR_COUNTRY] = "Choisissez votre pays";
FrenchPhrases[Phrases.SELECT_A_COUNTRY] = "Choisissez un pays";
FrenchPhrases[Phrases.KICK_AND_RESIGN] = "Exclure et abandonner";
FrenchPhrases[Phrases.BETWEEN_TURNS] = "Entre les tours";
FrenchPhrases[Phrases.PLAYER] = "Joueur";
FrenchPhrases[Phrases.RESIGNED] = "a abandonné";
FrenchPhrases[Phrases.YOU_RESIGNED] = "Vous avez abandonné";
FrenchPhrases[Phrases.YOU_LOSE] = "Vous avez perdu";
FrenchPhrases[Phrases.YOU_TIE] = "Vous avez fait match nul";
FrenchPhrases[Phrases.YOU_WIN] = "Vous avez gagné";
FrenchPhrases[Phrases.BUYS_MANY] = "Achats";
FrenchPhrases[Phrases.ACTIONS_MANY] = "Actions";
FrenchPhrases[Phrases.TOKEN] = "jeton";
FrenchPhrases[Phrases.TOKENS] = "jetons";
FrenchPhrases[Phrases.TOKENS_MANY] = "jetons";
FrenchPhrases[Phrases.OVERPAY] = "Surpayer";
FrenchPhrases[Phrases.BID] = "Ench.";
FrenchPhrases[Phrases.TAKE] = "Prendre";
FrenchPhrases[Phrases.BOTTOM] = "Bas";
FrenchPhrases[Phrases.TOP] = "Haut";
FrenchPhrases[Phrases.LOCATION] = "Lieu";
FrenchPhrases[Phrases.CLEAN_UP] = "Ajustement";
FrenchPhrases[Phrases.PLAN_CLEAN_UP] = "Préparer votre Ajustement";
FrenchPhrases[Phrases.EXECUTE] = "Exécuter";
FrenchPhrases[Phrases.MOVEMENT_VERB] = "VERB.";
FrenchPhrases[Phrases.MOVEMENT_VERB_OBJECT] = "VERB OBJECT.";
FrenchPhrases[Phrases.FORUM] = "Forum";
FrenchPhrases[Phrases.VIEW_LOG] = "Log";
FrenchPhrases[Phrases.REPLAY_GAME] = "Rejouer la partie";
FrenchPhrases[Phrases.REMOVE_REPLAY] = "Finir de rejouer";
FrenchPhrases[Phrases.SHELTERS] = "Refuges";
FrenchPhrases[Phrases.NO_SHELTERS] = "Pas de Refuges";
FrenchPhrases[Phrases.COLONIES] = "Colonies";
FrenchPhrases[Phrases.NO_COLONIES] = "Pas de Colonies";
FrenchPhrases[Phrases.PLAYER_ORDER] = "Ordre des joueurs";
FrenchPhrases[Phrases.ENTER_USERNAME] = "Merci d'entrer votre nom d'utilisateur.";
FrenchPhrases[Phrases.ENTER_LOGIN_CREDENTIALS] = "Merci d'entrer les références de votre login.";
FrenchPhrases[Phrases.SUBMIT] = "Soumettre";
FrenchPhrases[Phrases.DONE] = "OK";
FrenchPhrases[Phrases.GAME_ID] = "Numéro de partie";
FrenchPhrases[Phrases.DECISION_INDEX] = "Index des décisions";
FrenchPhrases[Phrases.ORIGINAL_PLAYER] = "Joueur d'origine";
FrenchPhrases[Phrases.ORIGINAL_PLAYERS] = "joueurs d'origine";
FrenchPhrases[Phrases.ADD_BOT] = "Ajouter une AI";
FrenchPhrases[Phrases.DECISION] = "Décision";
FrenchPhrases[Phrases.LOAD_GAME] = "Charger la partie";
FrenchPhrases[Phrases.CHANGE_REPLAY] = "Changer (rejouer)";
FrenchPhrases[Phrases.NAME_CARD] = "nommer une carte";
FrenchPhrases[Phrases.LOAD_OLD_GAME] = "Charger une ancienne partie";
FrenchPhrases[Phrases.BOT] = "AI";
FrenchPhrases[Phrases.CANCEL_RESIGN] = "Annuler et abandonner";
FrenchPhrases[Phrases.LOGOUT] = "Déconnexion";
FrenchPhrases[Phrases.FIRST] = "Premier";
FrenchPhrases[Phrases.LAST] = "Dernier";
FrenchPhrases[Phrases.INBOX] = "Boîte de réception";
FrenchPhrases[Phrases.MESSAGES] = "Messages";
FrenchPhrases[Phrases.MARK_READ] = "Marquer comme lus";
FrenchPhrases[Phrases.DELETE] = "Effacer";
FrenchPhrases[Phrases.NO_MESSAGES] = "Pas de message";
FrenchPhrases[Phrases.LEADERBOARD] = "Classement";
FrenchPhrases[Phrases.LAST_UPDATED] = "Dernière vise à jour";
FrenchPhrases[Phrases.RANK] = "#";
FrenchPhrases[Phrases.NAME] = "nom";
FrenchPhrases[Phrases.RATING] = "niveau";
FrenchPhrases[Phrases.TREND] = "";
FrenchPhrases[Phrases.VP] = "PV";
FrenchPhrases[Phrases.GAME_ID_SHOULD_BE_NUMBER] = "Le numéro de partie doit être un nombre.";
FrenchPhrases[Phrases.FAQ] = "FAQ";
FrenchPhrases[Phrases.RESULTS] = "Résultats";
FrenchPhrases[Phrases.SKILL] = "μ";
FrenchPhrases[Phrases.SKILL_RANGE] = "Intervalle de niveau";
FrenchPhrases[Phrases.DEVIATION] = "φ";
FrenchPhrases[Phrases.VOLATILITY] = "σ";
FrenchPhrases[Phrases.GAMES] = "Parties";
FrenchPhrases[Phrases.GLICKO2] = "Glicko-2";
FrenchPhrases[Phrases.IMPLEMENTATION] = "Implémentation";
FrenchPhrases[Phrases.RATED_QUEUE] = "Utilisateurs dans la file d'attente classée";
FrenchPhrases[Phrases.UNRATED_QUEUE] = "Utilisateurs dans la file d'attente non classée";
FrenchPhrases[Phrases.UNRATED_MATCH] = "Appariement non classé";
FrenchPhrases[Phrases.LEVEL] = "niveau";
FrenchPhrases[Phrases.TWO_PLAYERS] = "2 Joueurs";
FrenchPhrases[Phrases.THREE_FOUR_PLAYERS] = "3-4 Joueurs";
FrenchPhrases[Phrases.GAME] = "Partie";
FrenchPhrases[Phrases.RATED] = "classée";
FrenchPhrases[Phrases.UNRATED] = "non classée";
FrenchPhrases[Phrases.OTHER_CARDS] = "autres cartes";
FrenchPhrases[Phrases.OTHER_CARD] = "autre carte";
FrenchPhrases[Phrases.YOUR_TURN] = "A vous de jouer!";
FrenchPhrases[Phrases.LOADING_GAME] = "Chargement de la partie...";
FrenchPhrases[Phrases.BLACKLIST] = "Liste noire";
FrenchPhrases[Phrases.REMOVE] = "Enlever";
FrenchPhrases[Phrases.BLACKLIST_USER] = "Mettre sur liste noire";
FrenchPhrases[Phrases.BEFRIEND_USER] = "Être ami";
FrenchPhrases[Phrases.BLACKLISTED] = "Sur liste noire";
FrenchPhrases[Phrases.FRIENDLIST_EMPTY] = "Votre liste d'amis est vide.";
FrenchPhrases[Phrases.BLACKLIST_EMPTY] = "Votre liste noire est vide.";
FrenchPhrases[Phrases.PLAYERS_OF_TOTAL_PLAYERS] = "NUM_PLAYERS sur NUM_TOTAL_PLAYERS";
FrenchPhrases[Phrases.SHOW_RUNNING_GAMES] = "Montrer les parties en cours";
FrenchPhrases[Phrases.SHOW_1P_GAMES] = "Montrer les parties à 1 joueur";
FrenchPhrases[Phrases.SHOW_2P_GAMES] = "Montrer les parties à 2 joueurs";
FrenchPhrases[Phrases.SHOW_3XP_GAMES] = "Montrer les parties à 3+ joueurs";
FrenchPhrases[Phrases.SHOW_BOT_GAMES] = "Montrer les parties avec AI";
FrenchPhrases[Phrases.FIND_GAME] = "Appariement automatique";
FrenchPhrases[Phrases.FIND_BOT_GAME] = "Partie avec AI";
FrenchPhrases[Phrases.START_SEARCH] = "Rechercher";
FrenchPhrases[Phrases.CANCEL_SEARCH] = "Annuler la recherche";
FrenchPhrases[Phrases.SEARCHING_2P] = "2 joueurs";
FrenchPhrases[Phrases.SEARCHING_3P] = "3 joueurs";
FrenchPhrases[Phrases.SEARCHING_RATED] = "Partie classée";
FrenchPhrases[Phrases.SEARCHING_PRACTICE] = "Entraînement";
FrenchPhrases[Phrases.WAIT_FOR_EXPANSIONS] = "En attente d'extensions";
FrenchPhrases[Phrases.MAX] = "Maxi";
FrenchPhrases[Phrases.MIN] = "Mini";
FrenchPhrases[Phrases.SEARCHING] = "recherche";
FrenchPhrases[Phrases.START_BOT_GAME_1] = "1 AI";
FrenchPhrases[Phrases.START_BOT_GAME_2] = "2 AI";
FrenchPhrases[Phrases.START_BOT_GAME_3] = "3 AI";
FrenchPhrases[Phrases.NO_FRIENDS_ONLINE] = "Aucun ami en ligne";
FrenchPhrases[Phrases.AUTOMATCH_FEEDBACK] = "File d'attente d'appariement automatique";
FrenchPhrases[Phrases.NOBODY_ELSE_IN_QUEUE] = "Personne d'autre dans la file d'attente.";
FrenchPhrases[Phrases.CANCEL_SMALL] = "Annuler";
FrenchPhrases[Phrases.PLAYER_REPLACED] = "LEAVING_PLAYER a été remplacé par REPLACEMENT.";
FrenchPhrases[Phrases.RESIGN_PROMPT] = "Abandonner?";
FrenchPhrases[Phrases.RESIGN_MULTIPLE_OPPONENTS] = "Attention: abandonner une partie avec plusieurs adversaires peut être considéré comme anti-sportif.";
FrenchPhrases[Phrases.CONTINUE_WITH_BOTS] = "Continuer avec des AI";
FrenchPhrases[Phrases.PLAYER_DISCONNECTED] = "PLAYER_NAME déconnecté.";
FrenchPhrases[Phrases.PLAYER_RECONNECTED] = "PLAYER_NAME reconnecté.";
FrenchPhrases[Phrases.VALIDATE_BONUS_CODE] = "Valider";
FrenchPhrases[Phrases.NEW_TO_DOMINION] = "Comment jouer ?";
FrenchPhrases[Phrases.SEARCHING_PREVIEWS] = "Ménagerie en avant-première";
FrenchPhrases[Phrases.AUTOPLAY_CLEANUP] = "Réponses automatisées en phase d'Ajustement";
FrenchPhrases[Phrases.AUTOPLAY_INTERFACE] = "Réponses automatisées par défaut";
FrenchPhrases[Phrases.AUTOPLAY_OTHER] = "Autres réponses automatisées";
FrenchPhrases[Phrases.GAME_ENDED] = "La partie est finie.";
FrenchPhrases[Phrases.OK] = "Ok";
FrenchPhrases[Phrases.HAS_RESIGNED] = "PLAYER_NAME a abandonné.";

// Intro page of Store tab in Lobby
FrenchPhrases[Phrases.STORE_WELCOME] = "Boutique Dominion Online";
FrenchPhrases[Phrases.ONE_MONTH] = "1 mois";
FrenchPhrases[Phrases.TWO_MONTHS] = "2 mois";
FrenchPhrases[Phrases.THREE_MONTHS] = "3 mois";
FrenchPhrases[Phrases.ONE_YEAR] = "1 an";
FrenchPhrases[Phrases.CHOOSE_SUBSCRIPTION] = "Choisissez votre abonnement : ";
FrenchPhrases[Phrases.CHOOSE_DURATION] = "Choisissez la durée de votre abonnement, à compter d'aujourd'hui.";
FrenchPhrases[Phrases.CONTINUE] = "Continuer";
FrenchPhrases[Phrases.ADD_LATEST_EXPANSION] = "Souhaitez-vous ajouter Renaissance à votre abonnement actuel ?";
FrenchPhrases[Phrases.INTRODUCTION_TO_STORE] = "Bienvenue à la boutique";
FrenchPhrases[Phrases.CHECK_YOUR_PURCHASE] = "Vérifiez les détails de votre achat";
FrenchPhrases[Phrases.SELLER_INFO] = "Informations vendeur";
FrenchPhrases[Phrases.BUYING_SUBSCRIPTION_TO] = "Achat d'un abonnement pour";
FrenchPhrases[Phrases.BUYING_SUBSCRIPTION_TILL] = "Achat d'un abonnement jusqu'à";
FrenchPhrases[Phrases.AMOUNT] = "Montant";
FrenchPhrases[Phrases.ITEM] = "Article";
FrenchPhrases[Phrases.YOUR_SUBSCRIPTION] = "Votre abonnement";
FrenchPhrases[Phrases.YOUR_ACCOUNT] = "Votre compte";
FrenchPhrases[Phrases.SIX_MONTHS] = "6 mois";
FrenchPhrases[Phrases.NINE_MONTHS] = "9 mois";
FrenchPhrases[Phrases.FIFTEEN_MONTHS] = "15 mois";
FrenchPhrases[Phrases.TWO_YEAR] = "2 ans";
FrenchPhrases[Phrases.CORE_SUBSCRIPTION] = "Abonnement basique";
FrenchPhrases[Phrases.COMPLETE_SUBSCRIPTION] = "Abonnement complet";
FrenchPhrases[Phrases.CORE_SUBSCRIPTION_PRICE] = "(2,15€ / mois)";
FrenchPhrases[Phrases.CORE_SUBSCRIPTION_EXPLANATION] = "La moitié de cartes, les plus simples";
FrenchPhrases[Phrases.COMPLETE_SUBSCRIPTION_PRICE] = "(4,30€ / mois)";
FrenchPhrases[Phrases.COMPLETE_SUBSCRIPTION_EXPLANATION] = "Toutes les cartes";
FrenchPhrases[Phrases.REDIRECTED_TO_PAYMENT_PROVIDER] = "Redirection vers l'intermédiaire de paiement dans un nouvel onglet...";
FrenchPhrases[Phrases.REDIRECT_YOURSELF] = "Si vous n'êtes pas redirigés automatiquement, cliquez sur";
FrenchPhrases[Phrases.THIS_LINK] = "ce lien";
FrenchPhrases[Phrases.ZERO_INVOICE_1] = "L'abonnement sélectionné n'ajoute rien à votre abonnement actuel.";
FrenchPhrases[Phrases.ZERO_INVOICE_2] = "Merci de choisir un autre abonnement, ou une durée plus longue.";
FrenchPhrases[Phrases.SORRY_STORE_SERVICE_DOWN] = "La boutique est actuellement indisponible. Excusez-nous pour la gêne occasionnée.";
FrenchPhrases[Phrases.CONNECTING_TO_STORE_SERVICE] = "Connexion...";
FrenchPhrases[Phrases.PAYMENT_REDIRECTION_FAILED] = "Si vous n'avez pas été redirigé, merci de vérifier que votre navigateur autorise l'ouverture de nouveaux onglets.";
FrenchPhrases[Phrases.REDIRECT_FAILED_BUTTON] = "Cliquer quand la redirection a échoué";
FrenchPhrases[Phrases.UPGRADE_DURATION] = "Jusqu'à ce que l'abonnement actuel expire";
FrenchPhrases[Phrases.EIGHTEEN_MONTHS] = "18 mois";
FrenchPhrases[Phrases.FOR_FREE] = "Gratuit";
FrenchPhrases[Phrases.INVOICE_NEED_ANSWERS] = "Pour afficher la facture vous devez donner une réponse valide aux questions ci-dessus.";
FrenchPhrases[Phrases.MAXED_OUT_SUBSCRIPTIONS] = "Votre abonnement a la durée maximale et inclut toutes les extensions actuelles";
FrenchPhrases[Phrases.IP_COUNTRY_MISMATCH] = "Le pays sélectionné ne correspond par à votre adresse IP";
FrenchPhrases[Phrases.EXPIRES_AT] = ": l'abonnement se termine à";
FrenchPhrases[Phrases.TOOLTIP_RANDOM_SELECTION] = "Cliquez pour ajouter au royaume une carte aléatoire de ARGUMENT0.";
FrenchPhrases[Phrases.TOOLTIP_LANDSCAPE_SLOT] = "Cet emplacement pourrait être ARGUMENT0. Cliquez sur les images pour le changer. Enlevez toutes les marques pour enlever l'emplacement.";
FrenchPhrases[Phrases.LANDSCAPE_UNUSED] = "vide";
FrenchPhrases[Phrases.TOOLTIP_LANDSCAPE_PLUS_SLOT] = "Créez un nouvel emplacement pour les cartes au format paysage, puis choisissez quel(s) type(s) peuvent y apparaître.";
FrenchPhrases[Phrases.LANDSCAPE] = "carte paysage";

FrenchPhrases[Phrases.PREVIEW_BOT_MATCH] = "Preview contre l'IA";

//FrenchPhrases[Phrases.CARDS_BANNED_BY_PLAYERS]        = "Cards banned by players: ";
FrenchPhrases[Phrases.AUTOCOMPLETE_CARD_LISTS] = "Entrez les cartes à ajouter";
FrenchPhrases[Phrases.RESPECTED_CARD_LISTS] = "Respecter les listes de cartes";
FrenchPhrases[Phrases.BANNED_CARDS] = "cartes bannies";
FrenchPhrases[Phrases.LIKED_CARDS] = "cartes appréciées";
FrenchPhrases[Phrases.DISLIKED_CARDS] = "cartes peu appréciées";
FrenchPhrases[Phrases.KINGDOM_GENERATOR_PERCENTAGES] = "Royaume généré avec ces pourcentages relatifs :";

FrenchPhrases[Phrases.MOUSE_TOY] = "carte mise de côté par la Voie de la Souris";
FrenchPhrases[Phrases.VILLAGERS] = "Villageois";
FrenchPhrases[Phrases.DAYS] = "jours";
FrenchPhrases[Phrases.SPECIAL_KINGDOM_RULES] = "Règles spéciales pour le royaume :";
FrenchPhrases[Phrases.SHOW_CHAT] = "Montrer la discussion";
FrenchPhrases[Phrases.HIDE_CHAT] = "Cacher la discussion";
FrenchPhrases[Phrases.UNBLACKLIST] = "Retirer de la liste noire";
FrenchPhrases[Phrases.INHERITANCE_INSTRUCTIONS] = "Jouez la carte sous//votre jeton Domaine,//sans la déplacer";
FrenchPhrases[Phrases.TOOLTIP_OWNED_ENABLED] = "Cliquez pour désactiver cette extension.<br/>Si désactivée, vous ne l'apportez pas à vos parties.";
FrenchPhrases[Phrases.TOOLTIP_OWNED_DISABLED] = "Cliquez pour activer cette extension.<br/>Si activée, vous l'apportez à vos parties.";
FrenchPhrases[Phrases.TOOLTIP_VOID_ENABLED] = "Cliquez pour désactiver cette extension.<br/>Désactiver n'a pas d'effet si vous n'êtes pas abonné.";
FrenchPhrases[Phrases.TOOLTIP_VOID_DISABLED] = "Cliquez pour activer cette extension.<br/>Activer n'a pas d'effet si vous n'êtes pas abonné.";
FrenchPhrases[Phrases.TOOLTIP_BASE_SET] = "Vous ne pouvez pas désactiver la boîte de base.";
FrenchPhrases[Phrases.CHOOSE_CARD_POOL] = "Choisir un Niveau du Pool";
FrenchPhrases[Phrases.USE_LEVEL] = "Utiliser le niveau";
FrenchPhrases[Phrases.VIEW_INTRODUCTION_CARD_SELECTION] = "Voir l'introduction à la sélection de cartes";
FrenchPhrases[Phrases.VIEW_CARD_POOL_LEVELS] = "Voir les niveaux de Pool de cartes";
FrenchPhrases[Phrases.CARD_POOL_BOX_HEADER] = "Pool de cartes";
FrenchPhrases[Phrases.CARD_POOL_LEVEL] = "Niveau";
FrenchPhrases[Phrases.MIN_CARD_POOL_LEVEL] = "Niveau Minimum";
FrenchPhrases[Phrases.CARD_POOL_LEVEL_SMALL] = "niveau";
FrenchPhrases[Phrases.USED_CARD_POOL_LEVEL] = "Pool de cartes : niveau ";
FrenchPhrases[Phrases.SHOW_LEVEL] = "Pool de cartes : ";
FrenchPhrases[Phrases.SUBSCRIPTION_YES] = "Merci de vous être abonné.";
FrenchPhrases[Phrases.SUBSCRIPTION_NO] = "Vous n'avez pas d'abonnement.";
FrenchPhrases[Phrases.SUBSCRIPTION_FOR] = "Votre abonnement dure pendant encore";
FrenchPhrases[Phrases.EMPTY_BECAUSE_DISABLED] = "Vous ne pouvez pas sélectionner de cartes de cette extension car vous l'avez désactivée dans l'onglet Compte.";
FrenchPhrases[Phrases.EMPTY_BECAUSE_NOT_SUBSCRIBED] = "Vous ne pouvez pas sélectionner de cartes de cette extension car vous n'y êtes pas abonné.";
FrenchPhrases[Phrases.EXPLAIN_SUBSCRIPTIONS] = "\nCe site vous permet de jouer gratuitement avec la bo\xEEte de base. \nSi vous souhaitez jouer avec les extensions, ou si vous voulez soutenir le site, merci de penser \xE0 vous abonner.\nVous pouvez aussi essayer de trouver un adversaire qui fournit les cartes. Pour attendre un tel adversaire, cocher la case \"En attente d'extensions\".\n";
FrenchPhrases[Phrases.CARD_SELECTION_INTRODUCTION_TEXT] = ["<span class=\"game-concept\">L'appariement automatique</span> propose deux moyens diff\xE9rent de limiter les cartes avec lesquelles jouer.\n    Par ailleurs, vous pouvez cr\xE9er une <span class=\"game-concept\">Nouvelle Table</span> et trouver vos propres adversaires, vous permettant de tout choisir  manuellement.", "<div class=\"header\">Pool de Cartes</div>\n        Si vous \xEAtes en phase de d\xE9couverte des cartes, c'est probablement ce que vous voulez utiliser.\n        Nous avons cr\xE9\xE9 10 diff\xE9rents niveaux, en commen\xE7ant par la bo\xEEte de base.\n        Vous \xEAtes totalement libre de choisir votre propre progression. \n        Vous vous d\xE9couvrir auparavant les nouveuax m\xE9canismes propos\xE9s dans chaque niveau. \n        <span class=\"game-concept\">Pool de Cartes</span> devrait \xEAtre le niveau auquel vous \xEAtes \xE0 l'aise.\n        <span class=\"game-concept\">Niveau minimum</span> affecte l'appariement automatique; c'est le niveau minimum auquel vous \xEAtes d'accord pour jouer. Vous pouvez le baisser pour\n        aider les autres, ou pour un appariement plus rapide.", "<div class=\"header\">Listes de cartes</div>\n        Le bouton <span class=\"game-concept\">Listes de Cartes</span> dans le menu Option vous permet de cr\xE9er des petites listes de cartes personnelles.\n        Les cartes bannies n'appara\xEEtront jamais dans vos parties, et les cartes peu appr\xE9ci\xE9es seront choisies deux fois moins souvent."];

FrenchPhrases[Phrases.LEVEL_DESCRIPTIONS] = ["Jeu de base uniquement", "Cartes simples", "Durée, Trésors spéciaux, Platine et Colonie", "Quand vous recevez/achetez, Quand vous écartez, Jetons PV", "Coffres, Villageois, Exil, Réactions", "Tant que cette carte est en jeu, Quand vous défaussez, Interaction avec le Rebut, Plateaux uniques", "Événements, Réserve, Jetons Aventures", "Nuit, Refuges, Chevaux, Projets, Jalons", "Cartes supplémentaires, Piles divisées, Artefacts, Aubaines et Sortilèges, Patrimoines", "Coûts inusuels, Surpayer, Ordre, Potions, Dette, Voies"];
FrenchPhrases[Phrases.NEW_CARDS] = "Nouvelles cartes";
FrenchPhrases[Phrases.FAVOR] = "Faveur";
FrenchPhrases[Phrases.FAVORS] = "Faveurs";
FrenchPhrases[Phrases.FAVORS_MANY] = "Faveurs";
FrenchPhrases[Phrases.INDIVIDUAL_EXPANSIONS] = "Extensions Individuelles";
FrenchPhrases[Phrases.WARNING_SEARCHING_SUBSCRIBER] = "En recherche d'un adversaire Abonné";
FrenchPhrases[Phrases.WARNING_MAX_RATING_LOW] = "Le paramètre classement Maximum est assez bas";
FrenchPhrases[Phrases.WARNING_MIN_RATING_HIGH] = "Le paramètre classement Minimal est assez haut";
FrenchPhrases[Phrases.SEARCHING_2E_PREVIEWS] = "2e Previews";
FrenchPhrases[Phrases.WARNING_ERRATA] = "Note : certaines des cartes de cette partie ont été récemment modifiées.";
FrenchPhrases[Phrases.DONT_SHOW_THIS_MESSAGE_AGAIN] = "Ne plus montrer ce message.";
FrenchPhrases[Phrases.REPLAY_LAST_KINGDOM] = "Rejouer le dernier Royaume";

// Missing lines as of 08.01.2023 18:54:59
FrenchPhrases[Phrases.AUTOMATCH_LABEL_CARD_POOL_RANGE] = "Fourchette de pool";
FrenchPhrases[Phrases.AUTOMATCH_LABEL_EXPANSIONS] = "Extensions";
FrenchPhrases[Phrases.AUTOMATCH_LABEL_RATING] = "Classement";
FrenchPhrases[Phrases.BASE_ONLY] = "Base";
FrenchPhrases[Phrases.SIMPLE] = "Simple";
FrenchPhrases[Phrases.NO_PONG_RECEIVED] = "Attention : la connexion avec le serveur est peut-être perdue.";
FrenchPhrases[Phrases.MESSAGE_INDEX_MISMATCH] = "Attention : la connexion avec le serveur n'est pas stable.";
FrenchPhrases[Phrases.RECONNECTING_FAILED_MESSAGE] = "Vous vous êtes reconnecté à cette partie COUNT dois.";
FrenchPhrases[Phrases.TRY_AGAIN] = "Se connecter à nouveau";
FrenchPhrases[Phrases.RETURN_TO_LOBBY] = "Retourner au lobby";
FrenchPhrases[Phrases.ALL] = "Tous"; // used as tooltip for button that selects all expansions in the kingdom builder
FrenchPhrases[Phrases.TIMED_OUT] = "Timed out";
FrenchPhrases[Phrases.RECONNECT] = "Reconnect";