"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CounterLayer = function (_StackViewLayer) {
    _inherits(CounterLayer, _StackViewLayer);

    function CounterLayer(rootNode, cardStack) {
        _classCallCheck(this, CounterLayer);

        var _this = _possibleConstructorReturn(this, (CounterLayer.__proto__ || Object.getPrototypeOf(CounterLayer)).call(this, rootNode, cardStack));

        _this.node.style.zIndex = 13;
        _this.node.classList.add("counter-layer");
        _this.rootNode = rootNode;
        return _this;
    }

    _createClass(CounterLayer, [{
        key: "redraw",
        value: function redraw(w, h) {
            var counter = this.cardStack.count;
            var isInverted = this.cardStack.hasFilter("inverted-card");
            var isNortheast = this.cardStack.hasFilter("northeast-counter");

            clearChildNodes(this.node);

            if (counter === -1) {
                this.node.classList.add("invisible");
                return;
            } else {
                this.node.classList.remove("invisible");
            }

            var size = Math.min(h, w * 0.23);
            var x = isInverted || isNortheast ? w - size : 0;
            var y = isInverted ? h - size : 0;
            assignPositionToNode(new Rectangle(x, y, size, size), this.node);
            this.node.style.color = "white";
            this.node.style.fontSize = size + "px";
            this.node.style.fontFamily = "Montserrat";
            fitTextInNode(counter.toString(), this.node, new Rectangle(0, 0, size * 0.8, size * 0.8));
        }
    }]);

    return CounterLayer;
}(StackViewLayer);