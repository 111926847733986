"use strict";

webclient.component("gameEndedNotification", {
    transclude: true,
    controller: ['game', 'meta', '$scope', 'gameBroadcaster', function (game, meta, $scope, gameBroadcaster) {
        var self = this;

        $scope.$on(Events.REDRAW, function () {
            safeDigest($scope);
        });
        $scope.$on(Events.GAME_MODEL_CREATED, function () {
            safeDigest($scope);
        });
        self.shouldShow = function () {
            return game.metaGameModel.gameResult !== null;
        };
        self.ok = function () {
            gameBroadcaster.send(Events.CLEANUP_GAME);
        };

        self.getWindowText = function () {
            return getPhrase(Phrases.GAME_ENDED);
        };
        self.wasResigned = function () {
            return isDefined(game.metaGameModel.getResigningPlayerId());
        };
        self.getResignerText = function () {
            var resigningPlayerId = game.metaGameModel.getResigningPlayerId();
            return getPhrase(Phrases.HAS_RESIGNED).replace('PLAYER_NAME', meta.getPlayerNameById(resigningPlayerId));
        };

        self.getOkText = function () {
            return getPhrase(Phrases.OK);
        };
    }],
    template: "\n            <modal-window ng-if=\"$ctrl.shouldShow()\">\n                <div class=\"timeout\">{{::$ctrl.getWindowText()}}</div>\n                <div ng-if=\"$ctrl.wasResigned()\" class=\"timeout\">{{::$ctrl.getResignerText()}}</div>\n                <button class=\"lobby-button\" ng-click=\"$ctrl.ok()\" >{{::$ctrl.getOkText()}}</button>\n            </modal-window>\n        "
});