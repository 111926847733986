"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var GameStateChange = function () {
    function GameStateChange(game) {
        _classCallCheck(this, GameStateChange);

        this.game = game;
    }

    _createClass(GameStateChange, [{
        key: "createAnimation",
        value: function createAnimation() {
            return new InstantStateChangeAnimation(this);
        }
    }, {
        key: "execute",
        value: function execute() {
            return;
        }
    }]);

    return GameStateChange;
}();