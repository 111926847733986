"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function PossessiveDeterminer(string) {
    var self = this;
    self.string = string;
    self.toString = function () {
        return self.string;
    };
}

var POSSESSIVE_DETERMINERS = {
    HIS: new PossessiveDeterminer("His"),
    HER: new PossessiveDeterminer("Her"),
    THEIR: new PossessiveDeterminer("Their")
};

function AccountInformation(username, password, email, gender) {
    var self = this;
    self.username = username;
    self.password = password;
    self.email = email;
    self.gender = gender;
}

var AccountImport = function () {
    function AccountImport(username, password, code, gender) {
        _classCallCheck(this, AccountImport);

        this.username = username;
        this.password = password;
        this.code = code;
        this.gender = gender;
    }

    _createClass(AccountImport, [{
        key: "serialize",
        value: function serialize(writer) {
            writer.writeString(this.username);
            writer.writeString(this.password);
            writer.writeString(this.code);
            writer.writeInt(this.gender);
        }
    }]);

    return AccountImport;
}();