"use strict";

var FrenchCardModes = {};

FrenchCardModes[CardModes.PAWN_CARD_ACTION] = "Carte & Action";
FrenchCardModes[CardModes.PAWN_CARD] = "+1 Carte";
FrenchCardModes[CardModes.PAWN_ACTION] = "+1 Action";
FrenchCardModes[CardModes.PAWN_BUY] = "+1 Achat";
FrenchCardModes[CardModes.PAWN_COIN] = "+1 Pièce";
FrenchCardModes[CardModes.STEWARD_CARDS] = "+2 Cartes";
FrenchCardModes[CardModes.STEWARD_COINS] = "+2 Pièces";
FrenchCardModes[CardModes.STEWARD_TRASH] = "Écarter 2";
FrenchCardModes[CardModes.MINION_COINS] = "+2 Pièces";
FrenchCardModes[CardModes.MINION_NEWHAND] = "Défausser";
FrenchCardModes[CardModes.NOBLES_CARDS] = "+3 Cartes";
FrenchCardModes[CardModes.NOBLES_ACTIONS] = "+2 Actions";
FrenchCardModes[CardModes.COURTIER_ACTION] = "+1 Action";
FrenchCardModes[CardModes.COURTIER_BUY] = "+1 Achat";
FrenchCardModes[CardModes.COURTIER_COINS] = "+3 Pièces";
FrenchCardModes[CardModes.COURTIER_GOLD] = "Recevoir un Or";
FrenchCardModes[CardModes.NATIVE_VILLAGE_SET_ASIDE] = "Mettre de côté";
FrenchCardModes[CardModes.NATIVE_VILLAGE_RETURN] = "Prendre en main";
FrenchCardModes[CardModes.PIRATE_SHIP_RAID] = "Attaquer";
FrenchCardModes[CardModes.PIRATE_SHIP_PROFIT] = "Pièces";
FrenchCardModes[CardModes.SCRYING_POOL_TOPDECK] = "Mettre sur la pioche";
FrenchCardModes[CardModes.SCRYING_POOL_DISCARD] = "Défausser";
FrenchCardModes[CardModes.LOAN_DISCARD] = "Défausser";
FrenchCardModes[CardModes.LOAN_TRASH] = "Écarter";
FrenchCardModes[CardModes.TRUSTY_STEED_CARDS_ACTIONS] = "Cartes & Actions";
FrenchCardModes[CardModes.TRUSTY_STEED_CARDS] = "+2 Cartes";
FrenchCardModes[CardModes.TRUSTY_STEED_ACTIONS] = "+2 Actions";
FrenchCardModes[CardModes.TRUSTY_STEED_COINS] = "+2 Pièces";
FrenchCardModes[CardModes.TRUSTY_STEED_SILVERS] = "+4 Argents";
FrenchCardModes[CardModes.JESTER_THEY_GAIN] = "L'adversaire reçoit";
FrenchCardModes[CardModes.JESTER_YOU_GAIN] = "Vous recevez";
FrenchCardModes[CardModes.DUCHESS_KEEP] = "Laisser";
FrenchCardModes[CardModes.DUCHESS_DISCARD] = "Défausser";
FrenchCardModes[CardModes.ORACLE_TOPDECK] = "Remettre sur la pioche";
FrenchCardModes[CardModes.ORACLE_DISCARD] = "Défausser";
FrenchCardModes[CardModes.JACK_KEEP] = "Laisser";
FrenchCardModes[CardModes.JACK_DISCARD] = "Défausser";
FrenchCardModes[CardModes.SPICE_MERCHANT_CARDS] = "Cartes, Action";
FrenchCardModes[CardModes.SPICE_MERCHANT_COINS] = "Pièces, Achat";
FrenchCardModes[CardModes.CATACOMBS_TAKE] = "Prendre en main";
FrenchCardModes[CardModes.CATACOMBS_DISCARD] = "Défausser";
FrenchCardModes[CardModes.COUNT_DISCARD] = "Défausser";
FrenchCardModes[CardModes.COUNT_TOPDECK] = "Mettre sur la pioche";
FrenchCardModes[CardModes.COUNT_GAIN_COPPER] = "Recevoir un Cuivre";
FrenchCardModes[CardModes.COUNT_COINS] = "+3 Pièces";
FrenchCardModes[CardModes.COUNT_TRASH_HAND] = "Écarter la main";
FrenchCardModes[CardModes.COUNT_GAIN_DUCHY] = "Recevoir un Duché";
FrenchCardModes[CardModes.HUNTING_GROUNDS_ESTATES] = "Recevoir 3 Domaines";
FrenchCardModes[CardModes.HUNTING_GROUNDS_DUCHY] = "Recevoir un Duché";
FrenchCardModes[CardModes.IRONMONGER_KEEP] = "Laisser";
FrenchCardModes[CardModes.IRONMONGER_DISCARD] = "Défausser";
FrenchCardModes[CardModes.SCAVENGER_DISCARD] = "Défausser";
FrenchCardModes[CardModes.SCAVENGER_KEEP] = "Continuer";
FrenchCardModes[CardModes.SQUIRE_ACTIONS] = "+2 Actions";
FrenchCardModes[CardModes.SQUIRE_BUYS] = "+2 Achats";
FrenchCardModes[CardModes.SQUIRE_SILVER] = "Recevoir un Argent";
FrenchCardModes[CardModes.MERCENARY_DONT_TRASH] = "Ne pas écarter";
FrenchCardModes[CardModes.DOCTOR_TRASH] = "Écarter";
FrenchCardModes[CardModes.DOCTOR_DISCARD] = "Défausser";
FrenchCardModes[CardModes.DOCTOR_TOPDECK] = "Remettre sur la pioche";
FrenchCardModes[CardModes.AMULET_COIN] = "+1 Pièce";
FrenchCardModes[CardModes.AMULET_TRASH] = "Écarter";
FrenchCardModes[CardModes.AMULET_SILVER] = "Recevoir un Argent";
FrenchCardModes[CardModes.MISER_COPPER_TO_MAT] = "Cuivre sur la taverne";
FrenchCardModes[CardModes.MISER_PROFIT] = "Pièces";
FrenchCardModes[CardModes.MESSENGER_DISCARD] = "Défausser";
FrenchCardModes[CardModes.MESSENGER_KEEP] = "Ne pas défausser";
FrenchCardModes[CardModes.QUEST_ATTACK] = "Attaque";
FrenchCardModes[CardModes.QUEST_CURSES] = "2 Malédictions";
FrenchCardModes[CardModes.QUEST_CARDS] = "6 Cartes";
FrenchCardModes[CardModes.TEACHER_CARD] = "Carte";
FrenchCardModes[CardModes.TEACHER_ACTION] = "Action";
FrenchCardModes[CardModes.TEACHER_BUY] = "Achat";
FrenchCardModes[CardModes.TEACHER_COIN] = "Pièce";
FrenchCardModes[CardModes.LIBRARY_DRAW_IT] = "Prendre";
FrenchCardModes[CardModes.LIBRARY_SKIP_IT] = "Mettre de côté";
FrenchCardModes[CardModes.GLADIATOR_PRETEND] = "Ne pas dévoiler";
FrenchCardModes[CardModes.SPRAWLING_CASTLE_ESTATES] = "Recevoir 3 Domaines";
FrenchCardModes[CardModes.SPRAWLING_CASTLE_DUCHY] = "Recevoir un Duché";
FrenchCardModes[CardModes.CHARM_BUY_MONEY] = "+Achat, +2 Pièces";
FrenchCardModes[CardModes.CHARM_COPY] = "Recevoir une Carte";
FrenchCardModes[CardModes.WILD_HUNT_CARDS] = "Cartes";
FrenchCardModes[CardModes.WILD_HUNT_POINTS] = "Points";
FrenchCardModes[CardModes.PRINCE_SET_ASIDE] = "Mettre de côté";
FrenchCardModes[CardModes.PRINCE_NO] = "Continuer";
FrenchCardModes[CardModes.HAMLET_ACTION] = "Défausser pour une Action";
FrenchCardModes[CardModes.HAMLET_BUY] = "Défausser pour un Achat";
FrenchCardModes[CardModes.HAMLET_BOTH] = "Les deux à la fois";
FrenchCardModes[CardModes.GOVERNOR_CARDS] = "+3 Cartes";
FrenchCardModes[CardModes.GOVERNOR_TREASURE] = "Recevoir un Or";
FrenchCardModes[CardModes.GOVERNOR_REMODEL] = "Rénover";
FrenchCardModes[CardModes.BLESSED_VILLAGE_NOW] = "Appliquer maintenant";
FrenchCardModes[CardModes.BLESSED_VILLAGE_LATER] = "Au prochain tour";
FrenchCardModes[CardModes.PIXIE_TRASH] = "Écarter";
FrenchCardModes[CardModes.PIXIE_KEEP] = "Garder";
FrenchCardModes[CardModes.SACRED_GROVE_RECEIVE] = "L'appliquer";
FrenchCardModes[CardModes.SACRED_GROVE_NO] = "Non merci";
FrenchCardModes[CardModes.ZOMBIE_SPY_DISCARD] = "Défausser";
FrenchCardModes[CardModes.ZOMBIE_SPY_TOPDECK] = "Replacer";
FrenchCardModes[CardModes.START_GAME] = "Commencer la partie";
FrenchCardModes[CardModes.PEARL_DIVER_TOPDECK] = FrenchPhrases[Phrases.TOP];
FrenchCardModes[CardModes.PEARL_DIVER_BOTTOMDECK] = FrenchPhrases[Phrases.BOTTOM];
FrenchCardModes[CardModes.SCEPTER_FAIL_ACTION] = "Ne rien faire";
FrenchCardModes[CardModes.SCEPTER_ADD_COINS] = "+2 Pièces";
FrenchCardModes[CardModes.TREASURER_FAIL] = "Ne rien faire";
FrenchCardModes[CardModes.TREASURER_KEY] = "Prendre la Clé";
FrenchCardModes[CardModes.BORDER_GUARD_LANTERN] = "Lanterne";
FrenchCardModes[CardModes.BORDER_GUARD_HORN] = "Corne";
FrenchCardModes[CardModes.BORDER_GUARD_FAIL] = "Ne rien faire";
FrenchCardModes[CardModes.PAGEANT_YES] = "+1 Coffres";
FrenchCardModes[CardModes.PAGEANT_NO] = "Non merci";
FrenchCardModes[CardModes.SINISTER_PLOT_ADD] = "Ajouter un jeton";
FrenchCardModes[CardModes.SINISTER_PLOT_DRAW] = "Piocher";
FrenchCardModes[CardModes.PLAY] = "Jouer";
FrenchCardModes[CardModes.DONT_PLAY] = "Ne pas jouer";
FrenchCardModes[CardModes.VILLAGE_GREEN_NOW] = "Maintenant";
FrenchCardModes[CardModes.VILLAGE_GREEN_LATER] = "Au prochain tour";
FrenchCardModes[CardModes.BARGE_NOW] = "Maintenant";
FrenchCardModes[CardModes.BARGE_LATER] = "Au prochain tour";
FrenchCardModes[CardModes.SCRAP_CARD] = "+1 Carte";
FrenchCardModes[CardModes.SCRAP_ACTION] = "+1 Action";
FrenchCardModes[CardModes.SCRAP_BUY] = "+1 Achat";
FrenchCardModes[CardModes.SCRAP_COIN] = "+1 Pièce";
FrenchCardModes[CardModes.SCRAP_SILVER] = "recevoir un Argent";
FrenchCardModes[CardModes.SCRAP_HORSE] = "recevoir un Cheval";
FrenchCardModes[CardModes.MODE_OF_THE_BUTTERFLY] = "Papillon";
FrenchCardModes[CardModes.MODE_OF_THE_CAMEL] = "Chameau";
FrenchCardModes[CardModes.MODE_OF_THE_CHAMELEON] = "Caméléon";
FrenchCardModes[CardModes.MODE_OF_THE_FROG] = "Grenouille";
FrenchCardModes[CardModes.MODE_OF_THE_GOAT] = "Chèvre";
FrenchCardModes[CardModes.MODE_OF_THE_HORSE] = "Cheval";
FrenchCardModes[CardModes.MODE_OF_THE_MOLE] = "Taupe";
FrenchCardModes[CardModes.MODE_OF_THE_MONKEY] = "Singe";
FrenchCardModes[CardModes.MODE_OF_THE_MOUSE] = "Souris";
FrenchCardModes[CardModes.MODE_OF_THE_MULE] = "Mule";
FrenchCardModes[CardModes.MODE_OF_THE_OTTER] = "Loutre";
FrenchCardModes[CardModes.MODE_OF_THE_OWL] = "Hibou";
FrenchCardModes[CardModes.MODE_OF_THE_OX] = "Bœuf";
FrenchCardModes[CardModes.MODE_OF_THE_PIG] = "Porc";
FrenchCardModes[CardModes.MODE_OF_THE_RAT] = "Rat";
FrenchCardModes[CardModes.MODE_OF_THE_SEAL] = "Phoque";
FrenchCardModes[CardModes.MODE_OF_THE_SHEEP] = "Mouton";
FrenchCardModes[CardModes.MODE_OF_THE_SQUIRREL] = "Écureuil";
FrenchCardModes[CardModes.MODE_OF_THE_TURTLE] = "Tortue";
FrenchCardModes[CardModes.MODE_OF_THE_WORM] = "Ver";
FrenchCardModes[CardModes.BUTTERFLY_RETURN_IT] = "Le retourner";
FrenchCardModes[CardModes.BUTTERFLY_DO_NOTHING] = "Ne rien faire";
FrenchCardModes[CardModes.WAYFARER_SILVER] = "Recevoir un Argent";
FrenchCardModes[CardModes.DESPERATION_CURSE] = "Recevoir une Malédiction";
FrenchCardModes[CardModes.CRYSTAL_BALL_TRASH] = "Écarter";
FrenchCardModes[CardModes.CRYSTAL_BALL_DISCARD] = "Défausser";
FrenchCardModes[CardModes.CRYSTAL_BALL_PLAY] = "Jouer";
FrenchCardModes[CardModes.CRYSTAL_BALL_TOPDECK] = "Placer sur la pioche";
FrenchCardModes[CardModes.TOWN_VILLAGE] = "+1 Carte +2 Actions";
FrenchCardModes[CardModes.TOWN_COINS_BUY] = "+1 Achat +2 Pièces";
FrenchCardModes[CardModes.MODIFY_CANTRIP] = "+1 Carte +1 Action";
FrenchCardModes[CardModes.MODIFY_GAIN] = "Recevoir une carte";
FrenchCardModes[CardModes.DESERT_GUIDES_KEEP] = "Conserver";
FrenchCardModes[CardModes.DESERT_GUIDES_NEW_HAND] = "Nouvelle main";
FrenchCardModes[CardModes.BAUBLE_BUY] = "+1 Achat";
FrenchCardModes[CardModes.BAUBLE_COIN] = "+1 Pièce";
FrenchCardModes[CardModes.BAUBLE_FAVOR] = "+1 Faveur";
FrenchCardModes[CardModes.BAUBLE_TOPDECK] = "Mettre sur la pioche";
FrenchCardModes[CardModes.BROKER_CARDS] = "+Cartes";
FrenchCardModes[CardModes.BROKER_ACTIONS] = "+Actions";
FrenchCardModes[CardModes.BROKER_COINS] = "+Pièces";
FrenchCardModes[CardModes.BROKER_FAVORS] = "+Faveurs";
FrenchCardModes[CardModes.INNKEEPER_CARD] = "+1 Carte";
FrenchCardModes[CardModes.INNKEEPER_3] = "+3 Cartes, Défausser 3";
FrenchCardModes[CardModes.INNKEEPER_5_6] = "+5 Cartes, Défausser 6";
FrenchCardModes[CardModes.CAPITAL_CITY_DISCARD] = "Défausser 2 pour +$2";
FrenchCardModes[CardModes.CAPITAL_CITY_PAY] = "$2 pour +2 Cartes";
FrenchCardModes[CardModes.SPECIALIST_PLAY_AGAIN] = "Rejouer";
FrenchCardModes[CardModes.SPECIALIST_GAIN_COPY] = "Recevoir un exemplaire";
FrenchCardModes[CardModes.HILL_FORT_TO_HAND] = "Prendre en main";
FrenchCardModes[CardModes.HILL_FORT_CANTRIP] = "+1 Carte +1 Action";
FrenchCardModes[CardModes.STRONGHOLD_COINS] = "+3 Pièces";
FrenchCardModes[CardModes.STRONGHOLD_CARDS] = "prochain tour : +3 Cartes";
FrenchCardModes[CardModes.TOWN_CRIER_COINS] = "+2 Pièces";
FrenchCardModes[CardModes.TOWN_CRIER_SILVER] = "recevoir un Argent";
FrenchCardModes[CardModes.TOWN_CRIER_CANTRIP] = "+1 Carte +1 Action";
FrenchCardModes[CardModes.BLACKSMITH_OWL] = "piocher jusqu'à 6";
FrenchCardModes[CardModes.BLACKSMITH_OTTER] = "+2 Cartes";
FrenchCardModes[CardModes.BLACKSMITH_PIG] = "+1 Carte +1 Action";
FrenchCardModes[CardModes.LURKER_TRASH] = "Écarter depuis la réserver";
FrenchCardModes[CardModes.LURKER_GAIN] = "Recevoir depuis le Rebut";
FrenchCardModes[CardModes.GRAVEROBBER_GAIN] = "Recevoir sur la pioche";
FrenchCardModes[CardModes.GRAVEROBBER_TRASH] = "Agrandir depuis la main";
FrenchCardModes[CardModes.TREASURER_TRASH] = "Écarter";
FrenchCardModes[CardModes.TREASURER_GAIN] = "Recevoir depuis le Rebut";
FrenchCardModes[CardModes.INVESTMENT_COIN] = "+$1";
FrenchCardModes[CardModes.INVESTMENT_VP] = "écarter pour +PV";
FrenchCardModes[CardModes.WEAVER_SILVERS] = "2 Argents";
FrenchCardModes[CardModes.COURSER_CARDS_ACTIONS] = "Cartes & Actions";
FrenchCardModes[CardModes.COURSER_CARDS] = "+2 Cartes";
FrenchCardModes[CardModes.COURSER_ACTIONS] = "+2 Actions";
FrenchCardModes[CardModes.COURSER_COINS] = "+2 Pièces";
FrenchCardModes[CardModes.COURSER_SILVERS] = "+4 Argents";
FrenchCardModes[CardModes.SICKNESS_FAIL_DISCARD] = "Échouer à défausser";
FrenchCardModes[CardModes.SICKNESS_CURSE] = "Malédiction sur votre pioche";
FrenchCardModes[CardModes.SICKNESS_FAIL_CURSE] = "Échouer à recevoir une Malédiction";
FrenchCardModes[CardModes.SICKNESS_DISCARD] = "Défausser 3 cartes";
FrenchCardModes[CardModes.KITSUNE_ACTIONS] = "+2 Actions";
FrenchCardModes[CardModes.KITSUNE_COINS] = "+2 Pièces";
FrenchCardModes[CardModes.KITSUNE_CURSE] = "Donner une Malédiction";
FrenchCardModes[CardModes.KITSUNE_SILVER] = "Recevoir un Argent";

FrenchCardModes[ExtraTurnTypes.MISSION] = FrenchCardNames[CardNames.MISSION].singular;
FrenchCardModes[ExtraTurnTypes.OUTPOST] = FrenchCardNames[CardNames.OUTPOST].singular;
FrenchCardModes[ExtraTurnTypes.POSSESSION] = FrenchCardNames[CardNames.POSSESSION].singular;
FrenchCardModes[ExtraTurnTypes.FLEET] = FrenchCardNames[CardNames.FLEET].singular;
FrenchCardModes[ExtraTurnTypes.SEIZE_THE_DAY] = FrenchCardNames[CardNames.SEIZE_THE_DAY].singular;
FrenchCardModes[ExtraTurnTypes.ISLAND_FOLK] = FrenchCardNames[CardNames.ISLAND_FOLK].singular;
FrenchCardModes[ExtraTurnTypes.VOYAGE] = FrenchCardNames[CardNames.VOYAGE].singular;
FrenchCardModes[ExtraTurnTypes.JOURNEY] = FrenchCardNames[CardNames.JOURNEY].singular;

// Missing lines as of 08.01.2023 18:54:47
FrenchCardModes[CardModes.CABIN_BOY_COINS] = "+2 Pièces";
FrenchCardModes[CardModes.CABIN_BOY_GAIN] = "Écarter pour une Durée";
FrenchCardModes[CardModes.GONDOLA_NOW] = "+2 Pièces";
FrenchCardModes[CardModes.GONDOLA_LATER] = "Au prochain tour";
FrenchCardModes[CardModes.QUARTERMASTER_GAIN] = "Recevoir une carte";
FrenchCardModes[CardModes.QUARTERMASTER_TAKE] = "Prendre une carte";
FrenchCardModes[CardModes.AMPHORA_NOW] = "+1 Achat, +3 Pièces";
FrenchCardModes[CardModes.AMPHORA_LATER] = "Plus tard";
FrenchCardModes[CardModes.SPELL_SCROLL_PLAY] = "Jouer";
FrenchCardModes[CardModes.SPELL_SCROLL_REFUSE] = "Ne pas jouer";