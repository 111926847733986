"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ChangeCardTypes = function (_GameStateChange) {
    _inherits(ChangeCardTypes, _GameStateChange);

    function ChangeCardTypes(cardIndices, addedTypes, removedTypes) {
        _classCallCheck(this, ChangeCardTypes);

        var _this = _possibleConstructorReturn(this, (ChangeCardTypes.__proto__ || Object.getPrototypeOf(ChangeCardTypes)).call(this));

        _this.cardIndices = cardIndices;
        _this.addedTypes = addedTypes;
        _this.removedTypes = removedTypes;
        return _this;
    }

    _createClass(ChangeCardTypes, [{
        key: "execute",
        value: function execute(game) {
            var _this2 = this;

            this.cardIndices.forEach(function (id) {
                var card = game.state.cards[id];
                card.updateTypes(_this2);
            });
        }
    }], [{
        key: "parse",
        value: function parse(game, reader) {
            var cardIndices = reader.readInts();
            var addedTypes = reader.readEnumArray(Types);
            var removedTypes = reader.readEnumArray(Types);
            return new ChangeCardTypes(cardIndices, addedTypes, removedTypes);
        }
    }]);

    return ChangeCardTypes;
}(GameStateChange);