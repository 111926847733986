"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var LeaderboardType = function () {
    function LeaderboardType() {
        _classCallCheck(this, LeaderboardType);

        this.uuid = generateUUID();
    }

    _createClass(LeaderboardType, [{
        key: "toString",
        value: function toString() {
            return this.uuid;
        }
    }]);

    return LeaderboardType;
}();

var LEADERBOARD_TYPES = {
    TWO_PLAYER: new LeaderboardType(),
    TOP_TEN: new LeaderboardType(),
    THREE_PLUS_PLAYER: new LeaderboardType()
};