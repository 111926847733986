"use strict";

var GermanErrors = {};

GermanErrors[FailureReasons.WRONG_PASSWORD] = "Falsches Passwort.";
GermanErrors[FailureReasons.NOT_ENOUGH_PLAYERS] = "Nicht genügend Spieler.";
GermanErrors[FailureReasons.TOO_MANY_PLAYERS] = "Zu viele Spieler.";
GermanErrors[FailureReasons.NEW_HOST_NOT_FOUND] = "Kann den neuen Host nicht finden.";
GermanErrors[FailureReasons.ALREADY_JOINED_A_TABLE] = "Du bist bereits einem Tisch beigetreten.";
GermanErrors[FailureReasons.NOT_ALLOWED_TO_JOIN] = "Du darfst dem Tisch nicht beitreten.";
GermanErrors[FailureReasons.BAD_CLIENT_VERSION] = "Deine Version ist veraltet; bitte drücke Strg/Cmd-neu laden der Seite, für die aktuelle Version.";
GermanErrors[FailureReasons.UNKNOWN_RULE_INDEX] = "Unbekannter Regelindex.";
GermanErrors[FailureReasons.RULE_NOT_REMOVABLE] = "Diese Regel kann nicht entfernt werden.";
GermanErrors[FailureReasons.INVITEE_NOT_LOGGED_IN] = "Eingeladener Spieler ist nicht eingeloggt.";
GermanErrors[FailureReasons.NOT_ALLOWED_TO_PLAY] = "Du darfst nicht spielen.";
GermanErrors[FailureReasons.TABLE_CHAT_REQUIRES_TABLE] = "Der Tisch-Chat benötigt einen Tisch.";
GermanErrors[FailureReasons.SPECTATOR_CHAT_REQUIRES_TABLE] = "Der Zuschauer-Chat benötigt einen Tisch.";
GermanErrors[FailureReasons.LOGIN_REQUIRED] = "Du musst eingeloggt sein.";
GermanErrors[FailureReasons.COMMAND_REQUIRES_TABLE] = "Dieser Befehl benötigt einen Tisch.";
GermanErrors[FailureReasons.COMMAND_REQUIRES_HOST] = "Dieser Befehl benötigt Host-Rechte.";
GermanErrors[FailureReasons.RULE_CONFLICTS] = "Widersprüchliche Regeln.";
GermanErrors[FailureReasons.NO_GAMESERVER_AVAILABLE] = "Kein Spielserver verfügbar.";
GermanErrors[FailureReasons.SELECTED_CARD_NOT_OWNED] = "Du besitzt die ausgewählte Karte nicht.";
GermanErrors[FailureReasons.SELECTED_CARD_NOT_KINGDOM_CARD] = "Die ausgewählte Karte ist nicht im Königreich.";
GermanErrors[FailureReasons.SELECTED_TOO_MANY_EVENTS_OR_LANDMARKS] = "Zu viele Landmarken oder Ereignisse ausgewählt.";
GermanErrors[FailureReasons.THIS_IS_NOT_YOUR_TABLE] = "Das ist nicht dein Tisch.";
GermanErrors[FailureReasons.ALREADY_LOGGED_IN] = "Bereits eingeloggt.";
GermanErrors[FailureReasons.CANNOT_RECONNECT_WHEN_STILL_CONNECTED] = "Erneutes verbinden nicht möglich, während du noch verbunden bist.";
GermanErrors[FailureReasons.KICK_FAILED] = "Entfernen fehlgeschlagen.";
GermanErrors[FailureReasons.ALREADY_STARTING] = "Startet bereits.";
GermanErrors[FailureReasons.ALREADY_STARTED] = "Hat bereits angefangen.";
GermanErrors[FailureReasons.NOTHING_TO_KICK] = "Niemand zu entfernen.";
GermanErrors[FailureReasons.INTERNAL_ERROR] = "Interner Fehler.";
GermanErrors[FailureReasons.ILLEGAL_PROPERTY_CHANGE] = "Nicht erlaubtes Merkmal geändert";
GermanErrors[FailureReasons.CODE_ALREADY_USED] = "Code bereits verwendet";
GermanErrors[FailureReasons.FAILED_CONFIRMING_EMAIL] = "Konnte Email nicht bestätigen";
GermanErrors[FailureReasons.FAILED_RETRIEVING_USER] = "Konnte Benutzer nicht abrufen";
GermanErrors[FailureReasons.EMAIL_NOT_CONFIRMED_YET] = "Du kannst kein Spiel starten, so lange die Email-Adresse nicht bestätigt ist.";
GermanErrors[FailureReasons.FAILURE_SENDING_EMAIL] = "Bestätigungs-Email konnte nicht verschickt werden";
GermanErrors[FailureReasons.CONFIRM_EMAIL_FIRST] = "Es tut uns leid, wir können kein Passwort ohne bestätigte Email-Adresse verschicken.";
GermanErrors[FailureReasons.OLD_PASSWORD_INCORRECT] = "Altes Passwort nicht korrekt";
GermanErrors[FailureReasons.INCORRECT_PRICE] = "Preis nicht korrekt";
GermanErrors[FailureReasons.PURCHASE_IN_FUTURE] = "Kauf scheint aus der Zukunft zu stammen";
GermanErrors[FailureReasons.PURCHASE_TOO_OLD] = "Kauf zu alt. Tut uns leid, versuche es noch einmal";
GermanErrors[FailureReasons.FAILED_SETTING_UP_PURCHASE] = "Kauf konnte nicht eingerichtet werden";
GermanErrors[FailureReasons.FAILED_STORING_PURCHASE] = "Kauf konnte nicht gespeichert werden";
GermanErrors[FailureReasons.DUPLICATE_USERNAME] = "Dieser Benutzername existiert bereits";
GermanErrors[FailureReasons.INVALID_USERNAME] = "Ungültiger Benutzername";
GermanErrors[FailureReasons.INVALID_PASSWORD] = "Ungültiges Passwort";
GermanErrors[FailureReasons.INVALID_GENDER] = "Ungültiges Geschlecht";
GermanErrors[FailureReasons.PURCHASE_TOO_SHORT] = "Minimale Abonnement-Dauer ist ein Monat";
GermanErrors[FailureReasons.PURCHASE_TOO_LONG] = "Derzeit verkaufen wir keine Abonnements für nach 2024";
GermanErrors[FailureReasons.METASERVER_INACTIVE] = "Eine neue Serverversion wurde veröffentlicht; bitte drücke Strg/Cmd-neu laden der Seite, für die aktuelle Version.";
GermanErrors[FailureReasons.NO_SUCH_TABLE] = "Dieser Tisch ist nicht vorhanden.";
GermanErrors[FailureReasons.NO_SUCH_USER] = "Dieser Benutzer ist nicht vorhanden.";
GermanErrors[FailureReasons.NO_SUCH_CODE] = "Diesser Code ist nicht vorhanden.";
GermanErrors[FailureReasons.RESERVED_USERNAME] = "Dieser Benutzername ist reserviert.";
GermanErrors[FailureReasons.PRICE_IS_ZERO] = "Du kannst nichts ohne Kosten kaufen.";
GermanErrors[FailureReasons.NO_SUCH_USERNAME] = "Benutzername existiert nicht.";
GermanErrors[FailureReasons.NO_SUCH_GAMELOG] = "Das angefragte Spiel existiert nicht.";
GermanErrors[FailureReasons.ILLEGAL_PLAYER_ORDER] = "Ungültige Spieler-Reihenfolge.";
GermanErrors[FailureReasons.INVALID_SESSION_ID] = "Ungültige Sitzungs-ID.";
GermanErrors[FailureReasons.ACTION_CODE_EXPIRED] = "Aktionscode abgelaufen.";
GermanErrors[FailureReasons.BOT_GAMES_CANT_BE_RATED] = "Spiele mit Bots können nicht gewertet sein.";
GermanErrors[FailureReasons.TOO_MANY_PLAYERS_FOR_RATED_GAME] = "Spiele mit >4 Spielern können nicht gewertet sein.";
GermanErrors[FailureReasons.ALREADY_ON_BLACKLIST] = "Bereits auf der schwarzen Liste.";
GermanErrors[FailureReasons.NOT_ON_BLACKLIST] = "Nicht auf der schwarzen Liste.";
GermanErrors[FailureReasons.ALREADY_ON_FRIENDLIST] = "Bereits auf der Freundesliste.";
GermanErrors[FailureReasons.NOT_ON_FRIENDLIST] = "Nicht auf der Freundesliste.";
GermanErrors[FailureReasons.SEARCHING_FOR_MYSELF] = "Du kannst dich nicht selbst hinzufügen.";
GermanErrors[FailureReasons.SEARCHED_FOR_BOT] = "Du kannst keinen Bot hinzufügen.";
GermanErrors[FailureReasons.USER_IS_BANNED] = "Sie wurden auf dieser Seite gesperrt.";
GermanErrors[FailureReasons.WRONG_BONUS_CODE_TYPE] = "Falscher Typ des Bonus-Codes.";
GermanErrors[FailureReasons.ILLEGAL_BONUS_CODE] = "Ungültiger Bonus-Code.";
GermanErrors[FailureReasons.GAME_ID_SHOULD_BE_NUMBER] = "Spiel-ID muss eine Zahl sein.";
GermanErrors[FailureReasons.NOT_SUBSCRIBED_TO] = "Kein Abonnement dafür.";
GermanErrors[FailureReasons.KINGDOM_HAS_TOO_MANY_CARDS] = "Zu viele Karten im Königreich.";
GermanErrors[FailureReasons.NO_CARDS_AVAILABLE_TO_FILL_KINGDOM] = "Keine Karten, um Königreich zu füllen.";
GermanErrors[FailureReasons.FAILED_TO_SELECT_BANE_CARD] = "Konnte keine Bannkarte finden.";
GermanErrors[FailureReasons.FAILED_TO_SELECT_MOUSE_CARD] = "Konnte keine Karte für Maus finden.";
GermanErrors[FailureReasons.FAILED_TO_SELECT_FERRYMAN_CARD] = "Konnte keine Karte für Fährmann finden.";
GermanErrors[FailureReasons.FAILED_TO_SELECT_PREVIEW_CARDS] = "Konnte keine Vorschaukarte finden.";
GermanErrors[FailureReasons.FAILED_TO_SELECT_ALLY] = "Konnte keinen Verbündeten finden.";
GermanErrors[FailureReasons.FAILED_TO_SELECT_LIASON] = "Konte keinen Kontakt finden.";
GermanErrors[FailureReasons.REQUIRING_RANDOM_CARD_FROM_DISABLED_EXPANSION] = "Karte aus inaktiver Erweiterung gefordert.";
GermanErrors[FailureReasons.COMMAND_REQUIRES_PLAYER] = "Dieser Befehl ist nur für Spieler.";
GermanErrors[FailureReasons.COMMAND_REQUIRES_GAME] = "Dieser Befehl benötigt ein Spiel.";
GermanErrors[FailureReasons.ILLEGAL_ANSWER] = "Es gab eine invalide Antwort im Spiel.";
GermanErrors[FailureReasons.ILLEGAL_REQUEST] = "Es gab eine invalide Anforderung im Spiel.";
GermanErrors[FailureReasons.NO_SUCH_UNDO] = "So ein Undo gibt es nicht.";
GermanErrors[FailureReasons.SERVER_DOESNT_ALLOW_NEW_GAMES] = "Dieser Server erlaubt keine neuen Spiele. Bitte lade die Seite neu.";
GermanErrors[FailureReasons.SERVER_IS_DOWN] = "Dieser Server ist nicht aktiv. Bitte lade die Seite neu.";

var GermanSuccessDescriptions = {};

GermanSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_FORGOT_PASSWORD] = "Wir haben dir eine Email geschickt, um dein Passwort zurückzusetzen.";
GermanSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_CONFIRM_EMAIL] = "Wir haben dir eine Email geschickt, um deine Email-Adresse zu bestätigen.";
GermanSuccessDescriptions[SuccessDescriptions.PASSWORD_CHANGED] = "Passwort geändert";
GermanSuccessDescriptions[SuccessDescriptions.KICK_SUCCESS] = "Entfernen erfolgreich";
GermanSuccessDescriptions[SuccessDescriptions.PURCHASE_SUCCESS] = "Kauf erfolgreich";
GermanSuccessDescriptions[SuccessDescriptions.EMAIL_CONFIRMED] = "Email bestätigt";
GermanSuccessDescriptions[SuccessDescriptions.REFUND_SUCCESS] = "Kauf zurückerstattet";
GermanSuccessDescriptions[SuccessDescriptions.ACTION_CODE_USED] = "Aktionscode erfolgreich genutzt.";
GermanSuccessDescriptions[SuccessDescriptions.SUBSCRIPTION_UPDATED] = "Dein Abonnement wurde aktualisiert.";

var GermanTableRules = {};

GermanTableRules[TableRuleIds.PLAYERS_CAN_SEE_SPECTATOR_CHAT] = "Zeige Spielern den Zuschauerchat";
GermanTableRules[TableRuleIds.BANNED_CARDS] = "Verbotene Karten";
GermanTableRules[TableRuleIds.REQUIRED_CARDS] = "Benötigte Karten";
GermanTableRules[TableRuleIds.USED_EXPANSIONS] = "Benutzte Erweiterungen";
GermanTableRules[TableRuleIds.RESTRICTIVE_PLAYER_RULES] = "Nur angegebene Spieler können spielen";
GermanTableRules[TableRuleIds.SPECTATE_RULES] = "Erlaube Zuschauer";
GermanTableRules[TableRuleIds.MIN_RATING] = "Min relative Bewertung";
GermanTableRules[TableRuleIds.MAX_RATING] = "Max relative Bewertung";
GermanTableRules[TableRuleIds.RANDOM_SEED] = "Anfangswert für Zufallsgenerator angeben";
GermanTableRules[TableRuleIds.MIN_PLAYERS] = "Min Spieler";
GermanTableRules[TableRuleIds.MAX_PLAYERS] = "Max Spieler";
GermanTableRules[TableRuleIds.SPECIAL_KINGDOM_RULES] = "Spezielle Königreich-Regeln";
GermanTableRules[TableRuleIds.RESPECTED_CARD_LISTS] = "Berücksichtige beliebte/unbeliebte/verbotene Karten";
GermanTableRules[TableRuleIds.SHOW_VP_COUNTER] = "Zeige Punktezähler";
GermanTableRules[TableRuleIds.UNDO_SETTINGS] = "Einstellung zurücksetzen";
GermanTableRules[TableRuleIds.ALWAYS_ALLOWED_PLAYER_RULES] = "ALWAYS_ALLOWED_PLAYER_RULES";
GermanTableRules[TableRuleIds.PLAYER_ORDER] = "Spieler-Reihenfolge";
GermanTableRules[TableRuleIds.REPLAY_INSTRUCTIONS] = "Anweisungen wiederholen";
GermanTableRules[TableRuleIds.RATED_GAME] = "Gewertetes Spiel";
GermanTableRules[TableRuleIds.PREVIEW_GAME] = "Menagerie Vorschau";
GermanTableRules[TableRuleIds.SCRIPTED_RULES] = "Spezielle Königreich-Regeln";
GermanTableRules[TableRuleIds.CARD_POOL_LEVEL] = "Kartenstufe";

var GermanActivities = {};

GermanActivities[ActivityStati.LOGGED_IN] = "Eingeloggt";
GermanActivities[ActivityStati.PLAYING] = "Spielt";
GermanActivities[ActivityStati.SPECTATING] = "Schaut zu";
GermanActivities[ActivityStati.AT_TABLE_PLAYER] = "An Tisch (Spieler)";
GermanActivities[ActivityStati.AT_TABLE_SPECTATOR] = "An Tisch (Zuschauer)";
GermanActivities[ActivityStati.DISCONNECTED] = "Verbindung getrennt";

var GermanTableStati = {};

GermanTableStati[TableStati.NEW] = "Neu";
GermanTableStati[TableStati.POST_GAME] = "Nach dem Spiel";
GermanTableStati[TableStati.RUNNING] = "Läuft";
GermanTableStati[TableStati.ABANDONED] = "Abgebrochen";

var GermanLobbyButtons = {
    DONE: "Fertig",
    RESET: "Zurücksetzen",
    CLEAR_SELECTION: "Auswahl entfernen",
    SHOW_TEXT_KINGDOM: "Königreich als Text",
    NEW_TABLE: "Neuer Tisch",
    START_GAME: "Spiel starten",
    LEAVE_TABLE: "Tisch verlassen",
    SELECT_KINGDOM: "Königreich-Karten auswählen",
    LIST_BANNED: "verbotene",
    LIST_DISLIKED: "unbeliebte",
    LIST_LIKED: "beliebte",
    LIST_FAMILIAR: "bekannte",
    LOAD_FROM_END: "Laden am Ende",
    SELECT_COLONIES: "Kolonien: ",
    SELECT_SHELTERS: "Unterschlüpfe: "
};

var GermanLobbyTexts = {
    AUTOCOMPLETE_FAILED: "Es wurden keine Karten gefunden, die \"CARDS\" entsprechen.",
    ADD_CARDS_AUTOCOMPLETE: "Karten für Königreich eingeben",
    BAN_CARDS_AUTOCOMPLETE: "Verbiete Karten für dein Königreich",
    KINGDOM_OPTIONS: "Königreich-Optionen",
    GAME_OPTIONS: "Spiel-Optionen",
    RESPECTED_CARDS: "Berücksichtigte Karten",
    RELATIVE_LEVEL: "relative Gegnerspielstärke",
    LEVEL_INTERVAL: "${min} bis ${max}",
    EMPTY_SLOT: "Leerer Platz",
    REMOVE_SLOT: "Platz entfernen",
    ADD_SLOT: "Platz hinzufügen",
    RANDOMIZE_PLAYER_ORDER: "Zufällige Spieler-Reihenfolge",
    CHAT: "Chat",
    SELECT_CARDS: "Wähle Karten"
};

var GermanTernaryFieldTexts = {};

GermanTernaryFieldTexts[TernaryField.YES] = "Ja";
GermanTernaryFieldTexts[TernaryField.NO] = "Nein";
GermanTernaryFieldTexts[TernaryField.RANDOM] = "Zufall";

var GermanTabs = {};

GermanTabs[LobbyTabs.INBOX] = "Postfach";
GermanTabs[LobbyTabs.MATCH] = "Spiel finden";
GermanTabs[LobbyTabs.FRIEND_LIST] = "Freundesliste";
GermanTabs[LobbyTabs.TABLES] = "Tische";
GermanTabs[LobbyTabs.NEW_TABLE] = "Neuer Tisch";
GermanTabs[LobbyTabs.MY_TABLE] = "Mein Tisch";
GermanTabs[LobbyTabs.ACCOUNT] = "Mein Konto";
GermanTabs[LobbyTabs.STORE] = "Einkaufen";
GermanTabs[LobbyTabs.OPTIONS] = "Optionen";
GermanTabs[LobbyTabs.FAMILIAR_CARDS] = "Bekannte Karten";
GermanTabs[LobbyTabs.LEADERBOARD] = "Rangliste";
GermanTabs[LobbyTabs.CARDLISTS] = "Kartenlisten";

var GermanMatchTypes = {};

GermanMatchTypes[AutomatchTypes.QUICK_MATCH] = "Beliebiger Gegner";
GermanMatchTypes[AutomatchTypes.GOOD_MATCH] = "Gleichwertiger Gegner";
GermanMatchTypes[AutomatchTypes.MATCH_WITH_EXPANSIONS] = "Spiel mit Erweiterungen";
GermanMatchTypes[AutomatchTypes.MATCH_WITH_BOT] = "Computergegner";
GermanMatchTypes[AutomatchTypes.MATCH_UNRATED] = "Menschlicher Gegner";

var GermanUndoSettings = {};

GermanUndoSettings[UndoSettings.NEVER] = "Nie";
GermanUndoSettings[UndoSettings.ASK] = "Nachfragen";
GermanUndoSettings[UndoSettings.ALWAYS] = "Immer";

var GermanGroupIds = {};

GermanGroupIds[GroupIds.EVERYBODY] = "Jeder";
GermanGroupIds[GroupIds.NOBODY] = "Niemand";
GermanGroupIds[GroupIds.FRIENDS_OF] = "Freunde von";
GermanGroupIds[GroupIds.LIST_PLAYER_IDS] = "ausgewählte Spieler:";

var GermanCountries = {};

GermanCountries[Countries.NON_EU] = "Nicht-EU";
GermanCountries[Countries.AT] = "Österreich";
GermanCountries[Countries.BE] = "Belgien";
GermanCountries[Countries.BG] = "Bulgarien";
GermanCountries[Countries.HR] = "Kroatien";
GermanCountries[Countries.CY] = "Zypern";
GermanCountries[Countries.CZ] = "Tschechische Republik";
GermanCountries[Countries.DK] = "Dänemark";
GermanCountries[Countries.EE] = "Estland";
GermanCountries[Countries.FI] = "Finnland";
GermanCountries[Countries.FR] = "Frankreich";
GermanCountries[Countries.DE] = "Deutschland";
GermanCountries[Countries.GR] = "Griechenland";
GermanCountries[Countries.HU] = "Ungarn";
GermanCountries[Countries.IE] = "Irland";
GermanCountries[Countries.IT] = "Italien";
GermanCountries[Countries.LV] = "Lettland";
GermanCountries[Countries.LT] = "Litauen";
GermanCountries[Countries.LU] = "Luxemburg";
GermanCountries[Countries.MT] = "Malta";
GermanCountries[Countries.NL] = "Niederlande";
GermanCountries[Countries.PL] = "Polen";
GermanCountries[Countries.PT] = "Portugal";
GermanCountries[Countries.RO] = "Rumänien";
GermanCountries[Countries.SK] = "Slowakei";
GermanCountries[Countries.SI] = "Slowenien";
GermanCountries[Countries.ES] = "Spanien";
GermanCountries[Countries.SE] = "Schweden";
GermanCountries[Countries.GB] = "Vereinigtes Königreich";

var GermanAutomatchQuestions = {};
var GermanAutomatchValues = {};

GermanAutomatchQuestions[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS] = "Spielerzahl";
GermanAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS] = {};
GermanAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.REQUIRE] = "2";
GermanAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.EITHER] = "2 oder 3";
GermanAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.REJECT] = "3";

GermanAutomatchQuestions[AUTOMATCH_QUESTION.RATED_GAME] = "Spielwertung";
GermanAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME] = {};
GermanAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.REQUIRE] = "gewertet";
GermanAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.EITHER] = "gewertet oder ungewertet";
GermanAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.REJECT] = "ungewertet";

GermanAutomatchQuestions[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME] = "Extra Rising Sun";
GermanAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME] = {};
GermanAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.REQUIRE] = "ja";
GermanAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.EITHER] = "ja oder nein";
GermanAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.REJECT] = "nein";

GermanAutomatchQuestions[AUTOMATCH_QUESTION.PREVIEW_GAME] = "Vorschau Rising Sun";
GermanAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME] = {};
GermanAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.REQUIRE] = "ja";
GermanAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.REJECT] = "nein";
GermanAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.EITHER] = "ja oder nein";