"use strict";

webclient.component("ctrlOwnership", {
    bindings: {
        pref: "=ngModel"
    },
    controllerAs: "ctrl",
    controller: ['preferences', function (preferences) {
        var ctrl = this;

        ctrl.cardUniverseFilter = function (cardUniverse) {
            return cardUniverse.expansions.some(ctrl.expansionFilter);
        };

        ctrl.expansionFilter = function (expansion) {
            return expansion !== Expansions.BASE;
        };

        ctrl.submit = function (pref) {
            preferences.setUserPreference(pref);
        };

        ctrl.stringify = cardNamesToString;

        ctrl.dateString = function (date) {
            if (LANGUAGE === RUSSIAN) return date.toLocaleDateString('ru-RU');
            if (LANGUAGE === GERMAN) return date.toLocaleDateString('de-DE');
            return date.toDateString();
        };

        ctrl.intro = function () {
            if (ctrl.pref.arguments.hasAnything()) {
                return LANGUAGE.getUserPreferences.OWNERSHIP_INTRO;
            } else {
                return LANGUAGE.getUserPreferences.OWNERSHIP_INTRO_EMPTY;
            }
        };

        ctrl.getUntilText = function () {
            return getPhrase(Phrases.UNTIL);
        };
        ctrl.phrases = PHRASES;
        ctrl.getUntilText = function () {
            return getPhrase(Phrases.UNTIL);
        };

        $scope.$on(Events.OWNERSHIP_CHANGED, function (event, pref) {
            ctrl.pref = pref;
        });
    }],
    template: "<div class=\"test aad\" ng-include=\"'html/store/intro-1.9.2.2.html'\"></div><div style=\"color: white;\">{{ctrl.test()}}</div>"
});