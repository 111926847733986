"use strict";

webclient.component("userPrefString", {
    bindings: {
        pref: "="
    },
    controllerAs: "userPref",
    controller: ['preferences', function (preferences) {
        var userPref = this;
        var _hasSubmitted = false;
        var lastSubmitTime = 0;

        function sufficientTimeHasPassed() {
            var time = new Date().getTime();
            return time - lastSubmitTime > 3000;
        }

        userPref.submit = function (pref) {
            _hasSubmitted = true;
            if (!validateEmail(pref.arguments)) return;
            preferences.setUserPreference(pref);
            lastSubmitTime = new Date().getTime();
        };

        function validateEmail(s) {
            var b = isUndefined(s) ? false : s.length > 5;
            b &= s.indexOf('@') > -1;
            b &= s.indexOf('.') > -1;
            if (!b) alert("Please enter a valid e-mail address");
            return b;
        }

        userPref.shouldShowButton = function () {
            if (!_hasSubmitted) return true;
            return sufficientTimeHasPassed();
        };

        userPref.getLabel = function () {
            return LANGUAGE.getUserPreferences[userPref.pref.id];
        };
        userPref.getConfirmText = function () {
            return getPhrase(Phrases.CONFIRM);
        };
    }],
    template: "\n                <label>{{userPref.getLabel()}}: </label>\n                <input type=\"text\" class=\"string-pref\" ng-model=\"userPref.pref.arguments\"/>\n                <button ng-show=\"userPref.shouldShowButton()\" class=\"profile-button copper-button\" ng-click=\"userPref.submit(userPref.pref)\">\n                    {{userPref.getConfirmText()}}\n                </button>\n"
});