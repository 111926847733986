"use strict";

var TChineseCardNames = {};

TChineseCardNames[CardNames.BACK] = new TranslatedCardName("卡片", "張卡片", "1張卡片");
TChineseCardNames[CardNames.CURSE] = new TranslatedCardName("詛咒", "張詛咒", "1張詛咒");
TChineseCardNames[CardNames.COPPER] = new TranslatedCardName("銅幣", "張銅幣", "1張銅幣");
TChineseCardNames[CardNames.SILVER] = new TranslatedCardName("銀幣", "張銀幣", "1張銀幣");
TChineseCardNames[CardNames.GOLD] = new TranslatedCardName("黃金", "張黃金", "1張黃金");
TChineseCardNames[CardNames.ESTATE] = new TranslatedCardName("莊園", "張莊園", "1張莊園");
TChineseCardNames[CardNames.DUCHY] = new TranslatedCardName("公國", "張公國", "1張公國");
TChineseCardNames[CardNames.PROVINCE] = new TranslatedCardName("行省", "張行省", "1張行省");
TChineseCardNames[CardNames.ARTISAN] = new TranslatedCardName("藝術家", "張藝術家", "1張藝術家");
TChineseCardNames[CardNames.BANDIT] = new TranslatedCardName("強盜", "張強盜", "1張強盜");
TChineseCardNames[CardNames.BUREAUCRAT] = new TranslatedCardName("官員", "張官員", "1張官員");
TChineseCardNames[CardNames.CELLAR] = new TranslatedCardName("地窖", "張地窖", "1張地窖");
TChineseCardNames[CardNames.CHAPEL] = new TranslatedCardName("禮拜堂", "張禮拜堂", "1張禮拜堂");
TChineseCardNames[CardNames.COUNCIL_ROOM] = new TranslatedCardName("議事廳", "張議事廳", "1張議事廳");
TChineseCardNames[CardNames.FESTIVAL] = new TranslatedCardName("慶典", "張慶典", "1張慶典");
TChineseCardNames[CardNames.GARDENS] = new TranslatedCardName("花園", "張花園", "1張花園");
TChineseCardNames[CardNames.HARBINGER] = new TranslatedCardName("先鋒", "張先鋒", "1張先鋒");
TChineseCardNames[CardNames.LABORATORY] = new TranslatedCardName("實驗室", "張實驗室", "1張實驗室");
TChineseCardNames[CardNames.LIBRARY] = new TranslatedCardName("圖書館", "張圖書館", "1張圖書館");
TChineseCardNames[CardNames.MARKET] = new TranslatedCardName("市集", "張市集", "1張市集");
TChineseCardNames[CardNames.MERCHANT] = new TranslatedCardName("商人", "張商人", "1張商人");
TChineseCardNames[CardNames.MILITIA] = new TranslatedCardName("義勇軍", "張義勇軍", "1張義勇軍");
TChineseCardNames[CardNames.MINE] = new TranslatedCardName("礦坑", "張礦坑", "1張礦坑");
TChineseCardNames[CardNames.MOAT] = new TranslatedCardName("護城河", "張護城河", "1張護城河");
TChineseCardNames[CardNames.MONEYLENDER] = new TranslatedCardName("錢莊", "張錢莊", "1張錢莊");
TChineseCardNames[CardNames.POACHER] = new TranslatedCardName("盜獵者", "張盜獵者", "1張盜獵者");
TChineseCardNames[CardNames.REMODEL] = new TranslatedCardName("重建", "張重建", "1張重建");
TChineseCardNames[CardNames.SENTRY] = new TranslatedCardName("哨兵", "張哨兵", "1張哨兵");
TChineseCardNames[CardNames.SMITHY] = new TranslatedCardName("鐵匠", "張鐵匠", "1張鐵匠");
TChineseCardNames[CardNames.THRONE_ROOM] = new TranslatedCardName("王座", "張王座", "1張王座");
TChineseCardNames[CardNames.VASSAL] = new TranslatedCardName("家臣", "張家臣", "1張家臣");
TChineseCardNames[CardNames.VILLAGE] = new TranslatedCardName("村莊", "張村莊", "1張村莊");
TChineseCardNames[CardNames.WITCH] = new TranslatedCardName("女巫", "張女巫", "1張女巫");
TChineseCardNames[CardNames.WORKSHOP] = new TranslatedCardName("工作室", "張工作室", "1張工作室");

TChineseCardNames[CardNames.COURTYARD] = new TranslatedCardName("庭院", "張庭院", "1張庭院");
TChineseCardNames[CardNames.CONSPIRATOR] = new TranslatedCardName("共謀者", "張共謀者", "1張共謀者");
TChineseCardNames[CardNames.COURTIER] = new TranslatedCardName("奉承者", "張奉承者", "1張奉承者");
TChineseCardNames[CardNames.BARON] = new TranslatedCardName("男爵", "張男爵", "1張男爵");
TChineseCardNames[CardNames.BRIDGE] = new TranslatedCardName("橋梁", "張橋梁", "1張橋梁");
TChineseCardNames[CardNames.DIPLOMAT] = new TranslatedCardName("外交官", "張外交官", "1張外交官");
TChineseCardNames[CardNames.DUKE] = new TranslatedCardName("公爵", "張公爵", "1張公爵");
TChineseCardNames[CardNames.FARM] = new TranslatedCardName("農場", "張農場", "1張農場");
TChineseCardNames[CardNames.NOBLES] = new TranslatedCardName("貴族", "張貴族", "1張貴族");
TChineseCardNames[CardNames.IRONWORKS] = new TranslatedCardName("打鐵舖", "張打鐵舖", "1張打鐵舖");
TChineseCardNames[CardNames.LURKER] = new TranslatedCardName("潛伏者", "張潛伏者", "1張潛伏者");
TChineseCardNames[CardNames.MASQUERADE] = new TranslatedCardName("化裝舞會", "張化裝舞會", "1張化裝舞會");
TChineseCardNames[CardNames.MILL] = new TranslatedCardName("磨坊", "張磨坊", "1張磨坊");
TChineseCardNames[CardNames.MINING_VILLAGE] = new TranslatedCardName("礦業小鎮", "張礦業小鎮", "1張礦業小鎮");
TChineseCardNames[CardNames.MINION] = new TranslatedCardName("爪牙", "張爪牙", "1張爪牙");
TChineseCardNames[CardNames.PATROL] = new TranslatedCardName("偵查兵", "張偵查兵", "1張偵查兵");
TChineseCardNames[CardNames.PAWN] = new TranslatedCardName("小兵", "張小兵", "1張小兵");
TChineseCardNames[CardNames.REPLACE] = new TranslatedCardName("替身", "張替身", "1張替身");
TChineseCardNames[CardNames.SECRET_PASSAGE] = new TranslatedCardName("密道", "張密道", "1張密道");
TChineseCardNames[CardNames.SHANTY_TOWN] = new TranslatedCardName("貧民窟", "張貧民窟", "1張貧民窟");
TChineseCardNames[CardNames.STEWARD] = new TranslatedCardName("男侍", "張男侍", "1張男侍");
TChineseCardNames[CardNames.SWINDLER] = new TranslatedCardName("騙徒", "張騙徒", "1張騙徒");
TChineseCardNames[CardNames.TORTURER] = new TranslatedCardName("拷問", "張拷問", "1張拷問");
TChineseCardNames[CardNames.TRADING_POST] = new TranslatedCardName("商棧", "張商棧", "1張商棧");
TChineseCardNames[CardNames.UPGRADE] = new TranslatedCardName("升級", "張升級", "1張升級");
TChineseCardNames[CardNames.WISHING_WELL] = new TranslatedCardName("許願井", "張許願井", "1張許願井");

TChineseCardNames[CardNames.AMBASSADOR] = new TranslatedCardName("大使", "張大使", "1張大使");
TChineseCardNames[CardNames.BAZAAR] = new TranslatedCardName("趕集", "張趕集", "1張趕集");
TChineseCardNames[CardNames.CARAVAN] = new TranslatedCardName("商隊", "張商隊", "1張商隊");
TChineseCardNames[CardNames.CUTPURSE] = new TranslatedCardName("扒手", "張扒手", "1張扒手");
TChineseCardNames[CardNames.EMBARGO] = new TranslatedCardName("封港令", "張封港令", "1張封港令");
TChineseCardNames[CardNames.EXPLORER] = new TranslatedCardName("探險者", "張探險者", "1張探險者");
TChineseCardNames[CardNames.FISHING_VILLAGE] = new TranslatedCardName("漁村", "張漁村", "1張漁村");
TChineseCardNames[CardNames.GHOST_SHIP] = new TranslatedCardName("幽靈船", "張幽靈船", "1張幽靈船");
TChineseCardNames[CardNames.HAVEN] = new TranslatedCardName("港口", "張港口", "1張港口");
TChineseCardNames[CardNames.ISLAND] = new TranslatedCardName("島嶼", "張島嶼", "1張島嶼");
TChineseCardNames[CardNames.LIGHTHOUSE] = new TranslatedCardName("燈塔", "張燈塔", "1張燈塔");
TChineseCardNames[CardNames.LOOKOUT] = new TranslatedCardName("瞭望員", "張瞭望員", "1張瞭望員");
TChineseCardNames[CardNames.MERCHANT_SHIP] = new TranslatedCardName("商船", "張商船", "1張商船");
TChineseCardNames[CardNames.NATIVE_VILLAGE] = new TranslatedCardName("原生部落", "張原生部落", "1張原生部落");
TChineseCardNames[CardNames.NAVIGATOR] = new TranslatedCardName("領航員", "張領航員", "1張領航員");
TChineseCardNames[CardNames.OUTPOST] = new TranslatedCardName("前哨", "張前哨", "1張前哨");
TChineseCardNames[CardNames.PEARL_DIVER] = new TranslatedCardName("採珠人", "張採珠人", "1張採珠人");
TChineseCardNames[CardNames.PIRATE_SHIP] = new TranslatedCardName("海盜船", "張海盜船", "1張海盜船");
TChineseCardNames[CardNames.SALVAGER] = new TranslatedCardName("打撈員", "張打撈員", "1張打撈員");
TChineseCardNames[CardNames.SEA_HAG] = new TranslatedCardName("海巫", "張海巫", "1張海巫");
TChineseCardNames[CardNames.SMUGGLERS] = new TranslatedCardName("走私者", "張走私者", "1張走私者");
TChineseCardNames[CardNames.TACTICIAN] = new TranslatedCardName("策士", "張策士", "1張策士");
TChineseCardNames[CardNames.TREASURE_MAP] = new TranslatedCardName("藏寶圖", "張藏寶圖", "1張藏寶圖");
TChineseCardNames[CardNames.TREASURY] = new TranslatedCardName("金庫", "張金庫", "1張金庫");
TChineseCardNames[CardNames.WAREHOUSE] = new TranslatedCardName("倉庫", "張倉庫", "1張倉庫");
TChineseCardNames[CardNames.WHARF] = new TranslatedCardName("碼頭", "張碼頭", "1張碼頭");

TChineseCardNames[CardNames.ALCHEMIST] = new TranslatedCardName("煉金術士", "張煉金術士", "1張煉金術士");
TChineseCardNames[CardNames.APOTHECARY] = new TranslatedCardName("藥劑師", "張藥劑師", "1張藥劑師");
TChineseCardNames[CardNames.APPRENTICE] = new TranslatedCardName("學徒", "張學徒", "1張學徒");
TChineseCardNames[CardNames.FAMILIAR] = new TranslatedCardName("使魔", "張使魔", "1張使魔");
TChineseCardNames[CardNames.GOLEM] = new TranslatedCardName("魔像", "張魔像", "1張魔像");
TChineseCardNames[CardNames.HERBALIST] = new TranslatedCardName("中藥舖", "張中藥舖", "1張中藥舖");
TChineseCardNames[CardNames.PHILOSOPHERS_STONE] = new TranslatedCardName("賢者之石", "張賢者之石", "1張賢者之石");
TChineseCardNames[CardNames.POSSESSION] = new TranslatedCardName("佔據", "張佔據", "1張佔據");
TChineseCardNames[CardNames.POTION] = new TranslatedCardName("藥劑", "張藥劑", "1張藥劑");
TChineseCardNames[CardNames.SCRYING_POOL] = new TranslatedCardName("占卜", "張占卜", "1張占卜");
TChineseCardNames[CardNames.TRANSMUTE] = new TranslatedCardName("煉成", "張煉成", "1張煉成");
TChineseCardNames[CardNames.UNIVERSITY] = new TranslatedCardName("大學", "張大學", "1張大學");
TChineseCardNames[CardNames.VINEYARD] = new TranslatedCardName("葡萄園", "張葡萄園", "1張葡萄園");

TChineseCardNames[CardNames.BANK] = new TranslatedCardName("銀行", "張銀行", "1張銀行");
TChineseCardNames[CardNames.BISHOP] = new TranslatedCardName("主教", "張主教", "1張主教");
TChineseCardNames[CardNames.COLONY] = new TranslatedCardName("殖民地", "張殖民地", "1張殖民地");
TChineseCardNames[CardNames.CONTRABAND] = new TranslatedCardName("違禁品", "張違禁品", "1張違禁品");
TChineseCardNames[CardNames.COUNTING_HOUSE] = new TranslatedCardName("會計部", "張會計部", "1張會計部");
TChineseCardNames[CardNames.CITY] = new TranslatedCardName("都市", "張都市", "1張都市");
TChineseCardNames[CardNames.EXPAND] = new TranslatedCardName("擴建", "張擴建", "1張擴建");
TChineseCardNames[CardNames.FORGE] = new TranslatedCardName("鍛造", "張鍛造", "1張鍛造");
TChineseCardNames[CardNames.GRAND_MARKET] = new TranslatedCardName("大市場", "張大市場", "1張大市場");
TChineseCardNames[CardNames.GOONS] = new TranslatedCardName("暴徒", "張暴徒", "1張暴徒");
TChineseCardNames[CardNames.HOARD] = new TranslatedCardName("儲藏室", "張儲藏室", "1張儲藏室");
TChineseCardNames[CardNames.KINGS_COURT] = new TranslatedCardName("王宮", "張王宮", "1張王宮");
TChineseCardNames[CardNames.LOAN] = new TranslatedCardName("借貸", "張借貸", "1張借貸");
TChineseCardNames[CardNames.MINT] = new TranslatedCardName("鑄幣局", "張鑄幣局", "1張鑄幣局");
TChineseCardNames[CardNames.MONUMENT] = new TranslatedCardName("紀念碑", "張紀念碑", "1張紀念碑");
TChineseCardNames[CardNames.MOUNTEBANK] = new TranslatedCardName("詐騙", "張詐騙", "1張詐騙");
TChineseCardNames[CardNames.PEDDLER] = new TranslatedCardName("小販", "張小販", "1張小販");
TChineseCardNames[CardNames.PLATINUM] = new TranslatedCardName("白金", "張白金", "1張白金");
TChineseCardNames[CardNames.QUARRY] = new TranslatedCardName("採石場", "張採石場", "1張採石場");
TChineseCardNames[CardNames.RABBLE] = new TranslatedCardName("暴民", "張暴民", "1張暴民");
TChineseCardNames[CardNames.ROYAL_SEAL] = new TranslatedCardName("玉璽", "張玉璽", "1張玉璽");
TChineseCardNames[CardNames.TALISMAN] = new TranslatedCardName("幸運符", "張幸運符", "1張幸運符");
TChineseCardNames[CardNames.TRADE_ROUTE] = new TranslatedCardName("商路", "張商路", "1張商路");
TChineseCardNames[CardNames.VAULT] = new TranslatedCardName("寶庫", "張寶庫", "1張寶庫");
TChineseCardNames[CardNames.VENTURE] = new TranslatedCardName("募資", "張募資", "1張募資");
TChineseCardNames[CardNames.WATCHTOWER] = new TranslatedCardName("瞭望台", "張瞭望台", "1張瞭望台");
TChineseCardNames[CardNames.WORKERS_VILLAGE] = new TranslatedCardName("工人村", "張工人村", "1張工人村");

TChineseCardNames[CardNames.BAG_OF_GOLD] = new TranslatedCardName("錢袋", "張錢袋", "1張錢袋");
TChineseCardNames[CardNames.DIADEM] = new TranslatedCardName("王冠", "張王冠", "1張王冠");
TChineseCardNames[CardNames.FAIRGROUNDS] = new TranslatedCardName("博覽會", "張博覽會", "1張博覽會");
TChineseCardNames[CardNames.FARMING_VILLAGE] = new TranslatedCardName("鄉下", "張鄉下", "1張鄉下");
TChineseCardNames[CardNames.FOLLOWERS] = new TranslatedCardName("支持者", "張支持者", "1張支持者");
TChineseCardNames[CardNames.FORTUNE_TELLER] = new TranslatedCardName("算命家", "張算命家", "1張算命家");
TChineseCardNames[CardNames.HAMLET] = new TranslatedCardName("村落", "張村落", "1張村落");
TChineseCardNames[CardNames.HARVEST] = new TranslatedCardName("收割", "張收割", "1張收割");
TChineseCardNames[CardNames.HORSE_TRADERS] = new TranslatedCardName("馬商", "張馬商", "1張馬商");
TChineseCardNames[CardNames.HORN_OF_PLENTY] = new TranslatedCardName("豐收", "張豐收", "1張豐收");
TChineseCardNames[CardNames.HUNTING_PARTY] = new TranslatedCardName("狩獵", "張狩獵", "1張狩獵");
TChineseCardNames[CardNames.JESTER] = new TranslatedCardName("弄臣", "張弄臣", "1張弄臣");
TChineseCardNames[CardNames.MENAGERIE] = new TranslatedCardName("動物園", "張動物園", "1張動物園");
TChineseCardNames[CardNames.PRINCESS] = new TranslatedCardName("公主", "張公主", "1張公主");
TChineseCardNames[CardNames.REMAKE] = new TranslatedCardName("重造", "張重造", "1張重造");
TChineseCardNames[CardNames.TOURNAMENT] = new TranslatedCardName("競技", "張競技", "1張競技");
TChineseCardNames[CardNames.TRUSTY_STEED] = new TranslatedCardName("良駒", "張良駒", "1張良駒");
TChineseCardNames[CardNames.YOUNG_WITCH] = new TranslatedCardName("小魔女", "張小魔女", "1張小魔女");

TChineseCardNames[CardNames.BORDER_VILLAGE] = new TranslatedCardName("國界村莊", "張國界村莊", "1張國界村莊");
TChineseCardNames[CardNames.CACHE] = new TranslatedCardName("祕寶", "張祕寶", "1張祕寶");
TChineseCardNames[CardNames.CARTOGRAPHER] = new TranslatedCardName("製圖師", "張製圖師", "1張製圖師");
TChineseCardNames[CardNames.CROSSROADS] = new TranslatedCardName("十字路口", "張十字路口", "1張十字路口");
TChineseCardNames[CardNames.DEVELOP] = new TranslatedCardName("發展", "張發展", "1張發展");
TChineseCardNames[CardNames.DUCHESS] = new TranslatedCardName("女公爵", "張女公爵", "1張女公爵");
TChineseCardNames[CardNames.EMBASSY] = new TranslatedCardName("大使館", "張大使館", "1張大使館");
TChineseCardNames[CardNames.FARMLAND] = new TranslatedCardName("農地", "張農地", "1張農地");
TChineseCardNames[CardNames.FOOLS_GOLD] = new TranslatedCardName("愚人金", "張愚人金", "1張愚人金");
TChineseCardNames[CardNames.HAGGLER] = new TranslatedCardName("奧客", "張奧客", "1張奧客");
TChineseCardNames[CardNames.HIGHWAY] = new TranslatedCardName("幹道", "張幹道", "1張幹道");
TChineseCardNames[CardNames.ILL_GOTTEN_GAINS] = new TranslatedCardName("非法所得", "張非法所得", "1張非法所得");
TChineseCardNames[CardNames.INN] = new TranslatedCardName("旅館", "張旅館", "1張旅館");
TChineseCardNames[CardNames.JACK_OF_ALL_TRADES] = new TranslatedCardName("雜貨商", "張雜貨商", "1張雜貨商");
TChineseCardNames[CardNames.MANDARIN] = new TranslatedCardName("官吏", "張官吏", "1張官吏");
TChineseCardNames[CardNames.NOBLE_BRIGAND] = new TranslatedCardName("義賊", "張義賊", "1張義賊");
TChineseCardNames[CardNames.NOMAD_CAMP] = new TranslatedCardName("遊牧營地", "張遊牧營地", "1張遊牧營地");
TChineseCardNames[CardNames.OASIS] = new TranslatedCardName("綠洲", "張綠洲", "1張綠洲");
TChineseCardNames[CardNames.ORACLE] = new TranslatedCardName("神諭", "張神諭", "1張神諭");
TChineseCardNames[CardNames.MARGRAVE] = new TranslatedCardName("藩侯", "張藩侯", "1張藩侯");
TChineseCardNames[CardNames.SCHEME] = new TranslatedCardName("陰謀", "張陰謀", "1張陰謀");
TChineseCardNames[CardNames.SILK_ROAD] = new TranslatedCardName("絲路", "張絲路", "1張絲路");
TChineseCardNames[CardNames.SPICE_MERCHANT] = new TranslatedCardName("香料商人", "張香料商人", "1張香料商人");
TChineseCardNames[CardNames.STABLES] = new TranslatedCardName("馬廄", "張馬廄", "1張馬廄");
TChineseCardNames[CardNames.TRADER] = new TranslatedCardName("路邊攤", "張路邊攤", "1張路邊攤");
TChineseCardNames[CardNames.TUNNEL] = new TranslatedCardName("坑道", "張坑道", "1張坑道");

TChineseCardNames[CardNames.RUIN_PILE] = new TranslatedCardName("廢墟", "張廢墟", "1張廢墟");
TChineseCardNames[CardNames.KNIGHTS] = new TranslatedCardName("騎士", "張騎士", "1張騎士");
TChineseCardNames[CardNames.ABANDONED_MINE] = new TranslatedCardName("廢棄礦坑", "張廢棄礦坑", "1張廢棄礦坑");
TChineseCardNames[CardNames.ALTAR] = new TranslatedCardName("祭壇", "張祭壇", "1張祭壇");
TChineseCardNames[CardNames.ARMORY] = new TranslatedCardName("武器庫", "張武器庫", "1張武器庫");
TChineseCardNames[CardNames.BAND_OF_MISFITS] = new TranslatedCardName("狐群狗黨", "張狐群狗黨", "1張狐群狗黨");
TChineseCardNames[CardNames.BANDIT_CAMP] = new TranslatedCardName("土匪窩", "張土匪窩", "1張土匪窩");
TChineseCardNames[CardNames.BEGGAR] = new TranslatedCardName("乞丐", "張乞丐", "1張乞丐");
TChineseCardNames[CardNames.CATACOMBS] = new TranslatedCardName("地下墓穴", "張地下墓穴", "1張地下墓穴");
TChineseCardNames[CardNames.COUNT] = new TranslatedCardName("談判", "張談判", "1張談判");
TChineseCardNames[CardNames.COUNTERFEIT] = new TranslatedCardName("偽幣", "張偽幣", "1張偽幣");
TChineseCardNames[CardNames.CULTIST] = new TranslatedCardName("邪教徒", "張邪教徒", "1張邪教徒");
TChineseCardNames[CardNames.DAME_ANNA] = new TranslatedCardName("安娜女士", "張安娜女士", "1張安娜女士");
TChineseCardNames[CardNames.DAME_JOSEPHINE] = new TranslatedCardName("約瑟芬女士", "張約瑟芬女士", "1張約瑟芬女士");
TChineseCardNames[CardNames.DAME_MOLLY] = new TranslatedCardName("茉莉女士", "張茉莉女士", "1張茉莉女士");
TChineseCardNames[CardNames.DAME_NATALIE] = new TranslatedCardName("娜塔莉女士", "張娜塔莉女士", "1張娜塔莉女士");
TChineseCardNames[CardNames.DAME_SYLVIA] = new TranslatedCardName("希薇亞女士", "張希薇亞女士", "1張希薇亞女士");
TChineseCardNames[CardNames.DEATH_CART] = new TranslatedCardName("運屍車", "張運屍車", "1張運屍車");
TChineseCardNames[CardNames.FEODUM] = new TranslatedCardName("封地", "張封地", "1張封地");
TChineseCardNames[CardNames.FORAGER] = new TranslatedCardName("採集", "張採集", "1張採集");
TChineseCardNames[CardNames.FORTRESS] = new TranslatedCardName("要塞", "張要塞", "1張要塞");
TChineseCardNames[CardNames.GRAVEROBBER] = new TranslatedCardName("盜墓者", "張盜墓者", "1張盜墓者");
TChineseCardNames[CardNames.HERMIT] = new TranslatedCardName("隱士", "張隱士", "1張隱士");
TChineseCardNames[CardNames.HOVEL] = new TranslatedCardName("茅屋", "張茅屋", "1張茅屋");
TChineseCardNames[CardNames.HUNTING_GROUNDS] = new TranslatedCardName("狩獵場", "張狩獵場", "1張狩獵場");
TChineseCardNames[CardNames.IRONMONGER] = new TranslatedCardName("五金商人", "張五金商人", "1張五金商人");
TChineseCardNames[CardNames.JUNK_DEALER] = new TranslatedCardName("回收商", "張回收商", "1張回收商");
TChineseCardNames[CardNames.MADMAN] = new TranslatedCardName("瘋人", "張瘋人", "1張瘋人");
TChineseCardNames[CardNames.MARKET_SQUARE] = new TranslatedCardName("市集廣場", "張市集廣場", "1張市集廣場");
TChineseCardNames[CardNames.MARAUDER] = new TranslatedCardName("襲擊者", "張襲擊者", "1張襲擊者");
TChineseCardNames[CardNames.MERCENARY] = new TranslatedCardName("傭兵", "張傭兵", "1張傭兵");
TChineseCardNames[CardNames.MYSTIC] = new TranslatedCardName("秘術師", "張秘術師", "1張秘術師");
TChineseCardNames[CardNames.NECROPOLIS] = new TranslatedCardName("墓園", "張墓園", "1張墓園");
TChineseCardNames[CardNames.OVERGROWN_ESTATE] = new TranslatedCardName("廢棄房屋", "張廢棄房屋", "1張廢棄房屋");
TChineseCardNames[CardNames.PILLAGE] = new TranslatedCardName("掠奪", "張掠奪", "1張掠奪");
TChineseCardNames[CardNames.POOR_HOUSE] = new TranslatedCardName("收容所", "張收容所", "1張收容所");
TChineseCardNames[CardNames.PROCESSION] = new TranslatedCardName("進擊", "張進擊", "1張進擊");
TChineseCardNames[CardNames.RATS] = new TranslatedCardName("老鼠", "張老鼠", "1張老鼠");
TChineseCardNames[CardNames.REBUILD] = new TranslatedCardName("重蓋", "張重蓋", "1張重蓋");
TChineseCardNames[CardNames.ROGUE] = new TranslatedCardName("盜賊", "張盜賊", "1張盜賊");
TChineseCardNames[CardNames.RUINED_LIBRARY] = new TranslatedCardName("圖書館遺址", "張圖書館遺址", "1張圖書館遺址");
TChineseCardNames[CardNames.RUINED_MARKET] = new TranslatedCardName("市場殘骸", "張市場殘骸", "1張市場殘骸");
TChineseCardNames[CardNames.RUINED_VILLAGE] = new TranslatedCardName("荒蕪村落", "張荒蕪村落", "1張荒蕪村落");
TChineseCardNames[CardNames.SAGE] = new TranslatedCardName("智者", "張智者", "1張智者");
TChineseCardNames[CardNames.SCAVENGER] = new TranslatedCardName("清道夫", "張清道夫", "1張清道夫");
TChineseCardNames[CardNames.SIR_BAILEY] = new TranslatedCardName("貝里騎士", "張貝里騎士", "1張貝里騎士");
TChineseCardNames[CardNames.SIR_DESTRY] = new TranslatedCardName("迪斯奇騎士", "張迪斯奇騎士", "1張迪斯奇騎士");
TChineseCardNames[CardNames.SIR_MARTIN] = new TranslatedCardName("馬丁騎士", "張馬丁騎士", "1張馬丁騎士");
TChineseCardNames[CardNames.SIR_MICHAEL] = new TranslatedCardName("邁克爾騎士", "張邁克爾騎士", "1張邁克爾騎士");
TChineseCardNames[CardNames.SIR_VANDER] = new TranslatedCardName("范德騎士", "張范德騎士", "1張范德騎士");
TChineseCardNames[CardNames.SPOILS] = new TranslatedCardName("戰利品", "張戰利品", "1張戰利品");
TChineseCardNames[CardNames.STOREROOM] = new TranslatedCardName("庫房", "張庫房", "1張庫房");
TChineseCardNames[CardNames.SQUIRE] = new TranslatedCardName("侍從", "張侍從", "1張侍從");
TChineseCardNames[CardNames.SURVIVORS] = new TranslatedCardName("難民", "張難民", "1張難民");
TChineseCardNames[CardNames.URCHIN] = new TranslatedCardName("貧童", "張貧童", "1張貧童");
TChineseCardNames[CardNames.VAGRANT] = new TranslatedCardName("流浪漢", "張流浪漢", "1張流浪漢");
TChineseCardNames[CardNames.WANDERING_MINSTREL] = new TranslatedCardName("吟遊詩人", "張吟遊詩人", "1張吟遊詩人");

TChineseCardNames[CardNames.ADVISOR] = new TranslatedCardName("顧問", "張顧問", "1張顧問");
TChineseCardNames[CardNames.BAKER] = new TranslatedCardName("烘焙師", "張烘焙師", "1張烘焙師");
TChineseCardNames[CardNames.BUTCHER] = new TranslatedCardName("屠夫", "張屠夫", "1張屠夫");
TChineseCardNames[CardNames.CANDLESTICK_MAKER] = new TranslatedCardName("蠟燭匠", "張蠟燭匠", "1張蠟燭匠");
TChineseCardNames[CardNames.DOCTOR] = new TranslatedCardName("大夫", "張大夫", "1張大夫");
TChineseCardNames[CardNames.HERALD] = new TranslatedCardName("傳令官", "張傳令官", "1張傳令官");
TChineseCardNames[CardNames.JOURNEYMAN] = new TranslatedCardName("約聘工", "張約聘工", "1張約聘工");
TChineseCardNames[CardNames.MASTERPIECE] = new TranslatedCardName("傑作", "張傑作", "1張傑作");
TChineseCardNames[CardNames.MERCHANT_GUILD] = new TranslatedCardName("商會", "張商會", "1張商會");
TChineseCardNames[CardNames.PLAZA] = new TranslatedCardName("廣場", "張廣場", "1張廣場");
TChineseCardNames[CardNames.TAXMAN] = new TranslatedCardName("收稅員", "張收稅員", "1張收稅員");
TChineseCardNames[CardNames.SOOTHSAYER] = new TranslatedCardName("預言家", "張預言家", "1張預言家");
TChineseCardNames[CardNames.STONEMASON] = new TranslatedCardName("石匠", "張石匠", "1張石匠");

TChineseCardNames[CardNames.ALMS] = new TranslatedCardName("施捨", "張施捨", "1張施捨");
TChineseCardNames[CardNames.AMULET] = new TranslatedCardName("護身符", "張護身符", "1張護身符");
TChineseCardNames[CardNames.ARTIFICER] = new TranslatedCardName("技工", "張技工", "1張技工");
TChineseCardNames[CardNames.BALL] = new TranslatedCardName("派對", "張派對", "1張派對");
TChineseCardNames[CardNames.BONFIRE] = new TranslatedCardName("營火", "張營火", "1張營火");
TChineseCardNames[CardNames.BORROW] = new TranslatedCardName("借款", "張借款", "1張借款");
TChineseCardNames[CardNames.BRIDGE_TROLL] = new TranslatedCardName("橋下巨人", "張橋下巨人", "1張橋下巨人");
TChineseCardNames[CardNames.CARAVAN_GUARD] = new TranslatedCardName("護衛", "張護衛", "1張護衛");
TChineseCardNames[CardNames.CHAMPION] = new TranslatedCardName("勝利者", "張勝利者", "1張勝利者");
TChineseCardNames[CardNames.COIN_OF_THE_REALM] = new TranslatedCardName("國幣", "張國幣", "1張國幣");
TChineseCardNames[CardNames.DISCIPLE] = new TranslatedCardName("門徒", "張門徒", "1張門徒");
TChineseCardNames[CardNames.DISTANT_LANDS] = new TranslatedCardName("邊疆", "張邊疆", "1張邊疆");
TChineseCardNames[CardNames.DUNGEON] = new TranslatedCardName("地牢", "張地牢", "1張地牢");
TChineseCardNames[CardNames.DUPLICATE] = new TranslatedCardName("複製", "張複製", "1張複製");
TChineseCardNames[CardNames.EXPEDITION] = new TranslatedCardName("遠征", "張遠征", "1張遠征");
TChineseCardNames[CardNames.FERRY] = new TranslatedCardName("渡輪", "張渡輪", "1張渡輪");
TChineseCardNames[CardNames.FUGITIVE] = new TranslatedCardName("逃兵", "張逃兵", "1張逃兵");
TChineseCardNames[CardNames.GEAR] = new TranslatedCardName("工具", "張工具", "1張工具");
TChineseCardNames[CardNames.GIANT] = new TranslatedCardName("巨人", "張巨人", "1張巨人");
TChineseCardNames[CardNames.GUIDE] = new TranslatedCardName("導遊", "張導遊", "1張導遊");
TChineseCardNames[CardNames.HAUNTED_WOODS] = new TranslatedCardName("恐懼叢林", "張恐懼叢林", "1張恐懼叢林");
TChineseCardNames[CardNames.HERO] = new TranslatedCardName("英雄", "張英雄", "1張英雄");
TChineseCardNames[CardNames.HIRELING] = new TranslatedCardName("雇傭", "張雇傭", "1張雇傭");
TChineseCardNames[CardNames.INHERITANCE] = new TranslatedCardName("繼承", "張繼承", "1張繼承");
TChineseCardNames[CardNames.LOST_ARTS] = new TranslatedCardName("失傳工藝", "張失傳工藝", "1張失傳工藝");
TChineseCardNames[CardNames.LOST_CITY] = new TranslatedCardName("失落之城", "張失落之城", "1張失落之城");
TChineseCardNames[CardNames.MAGPIE] = new TranslatedCardName("喜鵲", "張喜鵲", "1張喜鵲");
TChineseCardNames[CardNames.MESSENGER] = new TranslatedCardName("信使", "張信使", "1張信使");
TChineseCardNames[CardNames.MISER] = new TranslatedCardName("守財奴", "張守財奴", "1張守財奴");
TChineseCardNames[CardNames.MISSION] = new TranslatedCardName("任務", "張任務", "1張任務");
TChineseCardNames[CardNames.PATHFINDING] = new TranslatedCardName("追蹤", "張追蹤", "1張追蹤");
TChineseCardNames[CardNames.PAGE] = new TranslatedCardName("見習騎士", "張見習騎士", "1張見習騎士");
TChineseCardNames[CardNames.PEASANT] = new TranslatedCardName("農夫", "張農夫", "1張農夫");
TChineseCardNames[CardNames.PILGRIMAGE] = new TranslatedCardName("巡禮", "張巡禮", "1張巡禮");
TChineseCardNames[CardNames.PLAN] = new TranslatedCardName("計畫", "張計畫", "1張計畫");
TChineseCardNames[CardNames.PORT] = new TranslatedCardName("海港", "張海港", "1張海港");
TChineseCardNames[CardNames.QUEST] = new TranslatedCardName("試煉", "張試煉", "1張試煉");
TChineseCardNames[CardNames.RANGER] = new TranslatedCardName("遊俠", "張遊俠", "1張遊俠");
TChineseCardNames[CardNames.RAID] = new TranslatedCardName("襲擊", "張襲擊", "1張襲擊");
TChineseCardNames[CardNames.RATCATCHER] = new TranslatedCardName("捕鼠人", "張捕鼠人", "1張捕鼠人");
TChineseCardNames[CardNames.RAZE] = new TranslatedCardName("破壞", "張破壞", "1張破壞");
TChineseCardNames[CardNames.RELIC] = new TranslatedCardName("遺物", "張遺物", "1張遺物");
TChineseCardNames[CardNames.ROYAL_CARRIAGE] = new TranslatedCardName("皇家馬車", "張皇家馬車", "1張皇家馬車");
TChineseCardNames[CardNames.SAVE] = new TranslatedCardName("儲蓄", "張儲蓄", "1張儲蓄");
TChineseCardNames[CardNames.SCOUTING_PARTY] = new TranslatedCardName("探勘隊", "張探勘隊", "1張探勘隊");
TChineseCardNames[CardNames.SEAWAY] = new TranslatedCardName("海路", "張海路", "1張海路");
TChineseCardNames[CardNames.SOLDIER] = new TranslatedCardName("軍人", "張軍人", "1張軍人");
TChineseCardNames[CardNames.STORYTELLER] = new TranslatedCardName("說書人", "張說書人", "1張說書人");
TChineseCardNames[CardNames.SWAMP_HAG] = new TranslatedCardName("沼澤女巫", "張沼澤女巫", "1張沼澤女巫");
TChineseCardNames[CardNames.TEACHER] = new TranslatedCardName("師傅", "張師傅", "1張師傅");
TChineseCardNames[CardNames.TRAVELLING_FAIR] = new TranslatedCardName("遊行", "張遊行", "1張遊行");
TChineseCardNames[CardNames.TRADE] = new TranslatedCardName("交易", "張交易", "1張交易");
TChineseCardNames[CardNames.TRAINING] = new TranslatedCardName("集訓", "張集訓", "1張集訓");
TChineseCardNames[CardNames.TRANSMOGRIFY] = new TranslatedCardName("變形術", "張變形術", "1張變形術");
TChineseCardNames[CardNames.TREASURE_TROVE] = new TranslatedCardName("財寶", "張財寶", "1張財寶");
TChineseCardNames[CardNames.TREASURE_HUNTER] = new TranslatedCardName("寶物獵人", "張寶物獵人", "1張寶物獵人");
TChineseCardNames[CardNames.WARRIOR] = new TranslatedCardName("戰士", "張戰士", "1張戰士");
TChineseCardNames[CardNames.WINE_MERCHANT] = new TranslatedCardName("酒商", "張酒商", "1張酒商");

TChineseCardNames[CardNames.ENCAMPMENT] = new TranslatedCardName("營地", "張營地", "1張營地");
TChineseCardNames[CardNames.PLUNDER] = new TranslatedCardName("掠奪品", "張掠奪品", "1張掠奪品");
TChineseCardNames[CardNames.PATRICIAN] = new TranslatedCardName("羅馬貴族", "張羅馬貴族", "1張羅馬貴族");
TChineseCardNames[CardNames.EMPORIUM] = new TranslatedCardName("商場", "張商場", "1張商場");
TChineseCardNames[CardNames.SETTLERS] = new TranslatedCardName("拓荒者", "張拓荒者", "1張拓荒者");
TChineseCardNames[CardNames.BUSTLING_VILLAGE] = new TranslatedCardName("鬧市", "張鬧市", "1張鬧市");
TChineseCardNames[CardNames.CATAPULT] = new TranslatedCardName("投石機", "張投石機", "1張投石機");
TChineseCardNames[CardNames.ROCKS] = new TranslatedCardName("石塊", "張石塊", "1張石塊");
TChineseCardNames[CardNames.GLADIATOR] = new TranslatedCardName("劍鬥士", "張劍鬥士", "1張劍鬥士");
TChineseCardNames[CardNames.FORTUNE] = new TranslatedCardName("大祕寶", "張大祕寶", "1張大祕寶");
TChineseCardNames[CardNames.CASTLES] = new TranslatedCardName("城堡", "張城堡", "1張城堡");
TChineseCardNames[CardNames.HUMBLE_CASTLE] = new TranslatedCardName("簡陋的城堡", "張簡陋的城堡", "1張簡陋的城堡");
TChineseCardNames[CardNames.CRUMBLING_CASTLE] = new TranslatedCardName("崩塌的城堡", "張崩塌的城堡", "1張崩塌的城堡");
TChineseCardNames[CardNames.SMALL_CASTLE] = new TranslatedCardName("迷你的城堡", "張迷你的城堡", "1張迷你的城堡");
TChineseCardNames[CardNames.HAUNTED_CASTLE] = new TranslatedCardName("惡靈的城堡", "張惡靈的城堡", "1張惡靈的城堡");
TChineseCardNames[CardNames.OPULENT_CASTLE] = new TranslatedCardName("奢華的城堡", "張奢華的城堡", "1張奢華的城堡");
TChineseCardNames[CardNames.SPRAWLING_CASTLE] = new TranslatedCardName("廣闊的城堡", "張廣闊的城堡", "1張廣闊的城堡");
TChineseCardNames[CardNames.GRAND_CASTLE] = new TranslatedCardName("雄偉的城堡", "張雄偉的城堡", "1張雄偉的城堡");
TChineseCardNames[CardNames.KINGS_CASTLE] = new TranslatedCardName("王者的城堡", "張王者的城堡", "1張王者的城堡");
TChineseCardNames[CardNames.ADVANCE] = new TranslatedCardName("提拔", "張提拔", "1張提拔");
TChineseCardNames[CardNames.ANNEX] = new TranslatedCardName("吞併", "張吞併", "1張吞併");
TChineseCardNames[CardNames.ARCHIVE] = new TranslatedCardName("檔案室", "張檔案室", "1張檔案室");
TChineseCardNames[CardNames.AQUEDUCT] = new TranslatedCardName("引水渠", "張引水渠", "1張引水渠");
TChineseCardNames[CardNames.ARENA] = new TranslatedCardName("競技場", "張競技場", "1張競技場");
TChineseCardNames[CardNames.BANDIT_FORT] = new TranslatedCardName("山匪窩", "張山匪窩", "1張山匪窩");
TChineseCardNames[CardNames.BANQUET] = new TranslatedCardName("盛宴", "張盛宴", "1張盛宴");
TChineseCardNames[CardNames.BASILICA] = new TranslatedCardName("殿堂", "張殿堂", "1張殿堂");
TChineseCardNames[CardNames.BATHS] = new TranslatedCardName("浴場", "張浴場", "1張浴場");
TChineseCardNames[CardNames.BATTLEFIELD] = new TranslatedCardName("戰場", "張戰場", "1張戰場");
TChineseCardNames[CardNames.CAPITAL] = new TranslatedCardName("融資", "張融資", "1張融資");
TChineseCardNames[CardNames.CHARM] = new TranslatedCardName("幸運物", "張幸運物", "1張幸運物");
TChineseCardNames[CardNames.CHARIOT_RACE] = new TranslatedCardName("賽馬車", "張賽馬車", "1張賽馬車");
TChineseCardNames[CardNames.CITY_QUARTER] = new TranslatedCardName("市中心", "張市中心", "1張市中心");
TChineseCardNames[CardNames.COLONNADE] = new TranslatedCardName("列柱", "張列柱", "1張列柱");
TChineseCardNames[CardNames.CONQUEST] = new TranslatedCardName("征服", "張征服", "1張征服");
TChineseCardNames[CardNames.CROWN] = new TranslatedCardName("皇冠", "張皇冠", "1張皇冠");
TChineseCardNames[CardNames.DELVE] = new TranslatedCardName("挖掘", "張挖掘", "1張挖掘");
TChineseCardNames[CardNames.DEFILED_SHRINE] = new TranslatedCardName("髒亂的寺廟", "張髒亂的寺廟", "1張髒亂的寺廟");
TChineseCardNames[CardNames.DOMINATE] = new TranslatedCardName("主宰", "張主宰", "1張主宰");
TChineseCardNames[CardNames.DONATE] = new TranslatedCardName("捐獻", "張捐獻", "1張捐獻");
TChineseCardNames[CardNames.ENCHANTRESS] = new TranslatedCardName("魔女", "張魔女", "1張魔女");
TChineseCardNames[CardNames.ENGINEER] = new TranslatedCardName("工程師", "張工程師", "1張工程師");
TChineseCardNames[CardNames.FARMERS_MARKET] = new TranslatedCardName("果菜市場", "張果菜市場", "1張果菜市場");
TChineseCardNames[CardNames.FORUM] = new TranslatedCardName("羅馬廣場", "張羅馬廣場", "1張羅馬廣場");
TChineseCardNames[CardNames.FOUNTAIN] = new TranslatedCardName("噴水池", "張噴水池", "1張噴水池");
TChineseCardNames[CardNames.GROUNDSKEEPER] = new TranslatedCardName("管理員", "張管理員", "1張管理員");
TChineseCardNames[CardNames.KEEP] = new TranslatedCardName("城堡主樓", "張城堡主樓", "1張城堡主樓");
TChineseCardNames[CardNames.LABYRINTH] = new TranslatedCardName("迷宮", "張迷宮", "1張迷宮");
TChineseCardNames[CardNames.LEGIONARY] = new TranslatedCardName("羅馬戰士", "張羅馬戰士", "1張羅馬戰士");
TChineseCardNames[CardNames.MOUNTAIN_PASS] = new TranslatedCardName("山間小路", "張山間小路", "1張山間小路");
TChineseCardNames[CardNames.MUSEUM] = new TranslatedCardName("博物館", "張博物館", "1張博物館");
TChineseCardNames[CardNames.OBELISK] = new TranslatedCardName("方尖碑", "張方尖碑", "1張方尖碑");
TChineseCardNames[CardNames.ORCHARD] = new TranslatedCardName("果園", "張果園", "1張果園");
TChineseCardNames[CardNames.OVERLORD] = new TranslatedCardName("霸主", "張霸主", "1張霸主");
TChineseCardNames[CardNames.PALACE] = new TranslatedCardName("宮殿", "張宮殿", "1張宮殿");
TChineseCardNames[CardNames.RITUAL] = new TranslatedCardName("儀式", "張儀式", "1張儀式");
TChineseCardNames[CardNames.ROYAL_BLACKSMITH] = new TranslatedCardName("御用鐵匠", "張御用鐵匠", "1張御用鐵匠");
TChineseCardNames[CardNames.SACRIFICE] = new TranslatedCardName("獻祭", "張獻祭", "1張獻祭");
TChineseCardNames[CardNames.SALT_THE_EARTH] = new TranslatedCardName("鹽溶大地", "張鹽溶大地", "1張鹽溶大地");
TChineseCardNames[CardNames.TAX] = new TranslatedCardName("徵稅", "張徵稅", "1張徵稅");
TChineseCardNames[CardNames.TEMPLE] = new TranslatedCardName("寺廟", "張寺廟", "1張寺廟");
TChineseCardNames[CardNames.TOMB] = new TranslatedCardName("墓地", "張墓地", "1張墓地");
TChineseCardNames[CardNames.TOWER] = new TranslatedCardName("主塔", "張主塔", "1張主塔");
TChineseCardNames[CardNames.TRIUMPH] = new TranslatedCardName("凱旋", "張凱旋", "1張凱旋");
TChineseCardNames[CardNames.TRIUMPHAL_ARCH] = new TranslatedCardName("凱旋門", "張凱旋門", "1張凱旋門");
TChineseCardNames[CardNames.VILLA] = new TranslatedCardName("別墅", "張別墅", "1張別墅");
TChineseCardNames[CardNames.WALL] = new TranslatedCardName("高牆", "張高牆", "1張高牆");
TChineseCardNames[CardNames.WOLF_DEN] = new TranslatedCardName("狼窩", "張狼窩", "1張狼窩");
TChineseCardNames[CardNames.WEDDING] = new TranslatedCardName("婚禮", "張婚禮", "1張婚禮");
TChineseCardNames[CardNames.WILD_HUNT] = new TranslatedCardName("狂獵", "張狂獵", "1張狂獵");
TChineseCardNames[CardNames.WINDFALL] = new TranslatedCardName("意外之財", "張意外之財", "1張意外之財");

TChineseCardNames[CardNames.BOON_DRAWPILE] = new TranslatedCardName("祝福牌堆", "張祝福牌堆", "祝福牌堆");
TChineseCardNames[CardNames.BOON_DISCARDPILE] = new TranslatedCardName("祝福棄牌堆", "張祝福棄牌堆", "祝福棄牌堆");
TChineseCardNames[CardNames.THE_EARTHS_GIFT] = new TranslatedCardName("大地的祝福", "張大地的祝福", "大地的祝福");
TChineseCardNames[CardNames.THE_FIELDS_GIFT] = new TranslatedCardName("田野的祝福", "張田野的祝福", "田野的祝福");
TChineseCardNames[CardNames.THE_FLAMES_GIFT] = new TranslatedCardName("火焰的祝福", "張火焰的祝福", "火焰的祝福");
TChineseCardNames[CardNames.THE_FORESTS_GIFT] = new TranslatedCardName("森林的祝福", "張森林的祝福", "森林的祝福");
TChineseCardNames[CardNames.THE_MOONS_GIFT] = new TranslatedCardName("月亮的祝福", "張月亮的禮物", "月亮的祝福");
TChineseCardNames[CardNames.THE_MOUNTAINS_GIFT] = new TranslatedCardName("山岳的祝福", "張山岳的祝福", "山岳的祝福");
TChineseCardNames[CardNames.THE_RIVERS_GIFT] = new TranslatedCardName("河流的祝福", "張河流的祝福", "河流的祝福");
TChineseCardNames[CardNames.THE_SEAS_GIFT] = new TranslatedCardName("大海的祝福", "張大海的祝福", "大海的祝福");
TChineseCardNames[CardNames.THE_SKYS_GIFT] = new TranslatedCardName("天空的祝福", "張天空的祝福", "天空的祝福");
TChineseCardNames[CardNames.THE_SUNS_GIFT] = new TranslatedCardName("太陽的祝福", "張太陽的祝福", "太陽的祝福");
TChineseCardNames[CardNames.THE_SWAMPS_GIFT] = new TranslatedCardName("沼澤的祝福", "張沼澤的祝福", "沼澤的祝福");
TChineseCardNames[CardNames.THE_WINDS_GIFT] = new TranslatedCardName("和風的祝福", "張和風的祝福", "和風的祝福");
TChineseCardNames[CardNames.HEX_DRAWPILE] = new TranslatedCardName("咒術牌堆", "張咒術牌堆", "咒術牌堆");
TChineseCardNames[CardNames.HEX_DISCARDPILE] = new TranslatedCardName("咒術棄牌堆", "張咒術棄牌堆", "咒術棄牌堆");
TChineseCardNames[CardNames.BAD_OMENS] = new TranslatedCardName("凶兆", "張凶兆", "凶兆");
TChineseCardNames[CardNames.DELUSION] = new TranslatedCardName("妄想", "張妄想", "妄想");
TChineseCardNames[CardNames.ENVY] = new TranslatedCardName("羨慕", "張羨慕", "羨慕");
TChineseCardNames[CardNames.FAMINE] = new TranslatedCardName("飢荒", "張飢荒", "飢荒");
TChineseCardNames[CardNames.FEAR] = new TranslatedCardName("恐懼", "張恐懼", "恐懼");
TChineseCardNames[CardNames.GREED] = new TranslatedCardName("貪婪", "張貪婪", "貪婪");
TChineseCardNames[CardNames.HAUNTING] = new TranslatedCardName("鬧鬼", "張鬧鬼", "鬧鬼");
TChineseCardNames[CardNames.LOCUSTS] = new TranslatedCardName("蝗蟲", "張蝗蟲", "蝗蟲");
TChineseCardNames[CardNames.MISERY] = new TranslatedCardName("悲慘", "張悲慘", "悲慘");
TChineseCardNames[CardNames.PLAGUE] = new TranslatedCardName("瘟疫", "張瘟疫", "瘟疫");
TChineseCardNames[CardNames.POVERTY] = new TranslatedCardName("貧窮", "張貧窮", "貧窮");
TChineseCardNames[CardNames.WAR] = new TranslatedCardName("戰爭", "張戰爭", "戰爭");
TChineseCardNames[CardNames.MISERABLE] = new TranslatedCardName("慘劇", "張慘劇", "慘劇");
TChineseCardNames[CardNames.TWICE_MISERABLE] = new TranslatedCardName("兩次慘劇", "張兩次慘劇", "兩次慘劇");
TChineseCardNames[CardNames.ENVIOUS] = new TranslatedCardName("嫉妒", "張嫉妒", "嫉妒");
TChineseCardNames[CardNames.DELUDED] = new TranslatedCardName("幻覺", "張幻覺", "幻覺");
TChineseCardNames[CardNames.LOST_IN_THE_WOODS] = new TranslatedCardName("在樹林迷失方向", "張在樹林迷失方向", "在樹林迷失方向");
TChineseCardNames[CardNames.BARD] = new TranslatedCardName("詩人", "張詩人", "1張詩人");
TChineseCardNames[CardNames.BLESSED_VILLAGE] = new TranslatedCardName("祝福之村", "張祝福之村", "1張祝福之村");
TChineseCardNames[CardNames.CHANGELING] = new TranslatedCardName("調換兒", "張調換兒", "1張調換兒");
TChineseCardNames[CardNames.CEMETERY] = new TranslatedCardName("公墓", "張公墓", "1張公墓");
TChineseCardNames[CardNames.COBBLER] = new TranslatedCardName("鞋匠", "張鞋匠", "1張鞋匠");
TChineseCardNames[CardNames.CONCLAVE] = new TranslatedCardName("秘密議會", "張秘密議會", "1張秘密議會");
TChineseCardNames[CardNames.CRYPT] = new TranslatedCardName("地下墓室", "張地下墓室", "1張地下墓室");
TChineseCardNames[CardNames.CURSED_VILLAGE] = new TranslatedCardName("詛咒之村", "張詛咒之村", "1張詛咒之村");
TChineseCardNames[CardNames.DEN_OF_SIN] = new TranslatedCardName("風化區", "張風化區", "1張風化區");
TChineseCardNames[CardNames.DEVILS_WORKSHOP] = new TranslatedCardName("惡魔工作室", "張惡魔工作室", "1張惡魔工作室");
TChineseCardNames[CardNames.DRUID] = new TranslatedCardName("德魯伊", "張德魯伊", "1張德魯伊");
TChineseCardNames[CardNames.EXORCIST] = new TranslatedCardName("驅魔師", "張驅魔師", "1張驅魔師");
TChineseCardNames[CardNames.FAITHFUL_HOUND] = new TranslatedCardName("忠犬", "張忠犬", "1張忠犬");
TChineseCardNames[CardNames.FOOL] = new TranslatedCardName("傻瓜", "張傻瓜", "1張傻瓜");
TChineseCardNames[CardNames.GHOST_TOWN] = new TranslatedCardName("鬼城", "張鬼城", "1張鬼城");
TChineseCardNames[CardNames.GUARDIAN] = new TranslatedCardName("守衛", "張守衛", "1張守衛");
TChineseCardNames[CardNames.IDOL] = new TranslatedCardName("神像", "張神像", "1張神像");
TChineseCardNames[CardNames.LEPRECHAUN] = new TranslatedCardName("矮精靈", "張矮精靈", "1張矮精靈");
TChineseCardNames[CardNames.MONASTERY] = new TranslatedCardName("修道院", "張修道院", "1張修道院");
TChineseCardNames[CardNames.NECROMANCER] = new TranslatedCardName("死靈法師", "張死靈法師", "1張死靈法師");
TChineseCardNames[CardNames.NIGHT_WATCHMAN] = new TranslatedCardName("守夜人", "張守夜人", "1張守夜人");
TChineseCardNames[CardNames.PIXIE] = new TranslatedCardName("精靈", "張精靈", "1張精靈");
TChineseCardNames[CardNames.POOKA] = new TranslatedCardName("愛爾蘭妖精", "張愛爾蘭妖精", "1張愛爾蘭妖精");
TChineseCardNames[CardNames.RAIDER] = new TranslatedCardName("入侵者", "張入侵者", "1張入侵者");
TChineseCardNames[CardNames.SACRED_GROVE] = new TranslatedCardName("森之聖域", "張森之聖域", "1張森之聖域");
TChineseCardNames[CardNames.SECRET_CAVE] = new TranslatedCardName("秘密洞穴", "張秘密洞穴", "1張秘密洞穴");
TChineseCardNames[CardNames.SHEPHERD] = new TranslatedCardName("牧羊人", "張牧羊人", "1張牧羊人");
TChineseCardNames[CardNames.SKULK] = new TranslatedCardName("躲避者", "張躲避者", "1張躲避者");
TChineseCardNames[CardNames.TORMENTOR] = new TranslatedCardName("執法者", "張執法者", "1張執法者");
TChineseCardNames[CardNames.TRAGIC_HERO] = new TranslatedCardName("悲劇英雄", "張悲劇英雄", "1張悲劇英雄");
TChineseCardNames[CardNames.TRACKER] = new TranslatedCardName("追蹤者", "張追蹤者", "1張追蹤者");
TChineseCardNames[CardNames.VAMPIRE] = new TranslatedCardName("吸血鬼", "張吸血鬼", "1張吸血鬼");
TChineseCardNames[CardNames.WEREWOLF] = new TranslatedCardName("狼人", "張狼人", "1張狼人");
TChineseCardNames[CardNames.CURSED_GOLD] = new TranslatedCardName("詛咒金幣", "張詛咒金幣", "1張詛咒金幣");
TChineseCardNames[CardNames.GOAT] = new TranslatedCardName("山羊", "張山羊", "1張山羊");
TChineseCardNames[CardNames.HAUNTED_MIRROR] = new TranslatedCardName("鬼入鏡", "張鬼入鏡", "1張鬼入鏡");
TChineseCardNames[CardNames.LUCKY_COIN] = new TranslatedCardName("幸運幣", "張幸運幣", "1張幸運幣");
TChineseCardNames[CardNames.MAGIC_LAMP] = new TranslatedCardName("神燈", "張神燈", "1張神燈");
TChineseCardNames[CardNames.PASTURE] = new TranslatedCardName("牧場", "張牧場", "1張牧場");
TChineseCardNames[CardNames.POUCH] = new TranslatedCardName("錢包", "張錢包", "1張錢包");
TChineseCardNames[CardNames.BAT] = new TranslatedCardName("蝙蝠", "張蝙蝠", "1張蝙蝠");
TChineseCardNames[CardNames.GHOST] = new TranslatedCardName("鬼魂", "張鬼魂", "1張鬼魂");
TChineseCardNames[CardNames.IMP] = new TranslatedCardName("小鬼", "張小鬼", "1張小鬼");
TChineseCardNames[CardNames.WILL_O_WISP] = new TranslatedCardName("鬼火", "張鬼火", "1張鬼火");
TChineseCardNames[CardNames.WISH] = new TranslatedCardName("願望", "張願望", "1張願望");
TChineseCardNames[CardNames.ZOMBIE_APPRENTICE] = new TranslatedCardName("殭屍學徒", "張殭屍學徒", "1張殭屍學徒");
TChineseCardNames[CardNames.ZOMBIE_MASON] = new TranslatedCardName("殭屍石匠", "張殭屍石匠", "1張殭屍石匠");
TChineseCardNames[CardNames.ZOMBIE_SPY] = new TranslatedCardName("殭屍間諜", "張殭屍間諜", "1張殭屍間諜");

TChineseCardNames[CardNames.ACTING_TROUPE] = new TranslatedCardName("舞台演員", "張舞台演員", "1張舞台演員");
TChineseCardNames[CardNames.BORDER_GUARD] = new TranslatedCardName("邊境守衛", "張邊境守衛", "1張邊境守衛");
TChineseCardNames[CardNames.CARGO_SHIP] = new TranslatedCardName("貨船", "張貨船", "1張貨船");
TChineseCardNames[CardNames.DUCAT] = new TranslatedCardName("達克特", "張達克特", "1張達克特");
TChineseCardNames[CardNames.EXPERIMENT] = new TranslatedCardName("實驗", "張實驗", "1張實驗");
TChineseCardNames[CardNames.FLAG_BEARER] = new TranslatedCardName("舉旗手", "張舉旗手", "1張舉旗手");
TChineseCardNames[CardNames.HIDEOUT] = new TranslatedCardName("藏身處", "張藏身處", "1張藏身處");
TChineseCardNames[CardNames.INVENTOR] = new TranslatedCardName("發明家", "張發明家", "1張發明家");
TChineseCardNames[CardNames.IMPROVE] = new TranslatedCardName("改良", "張改良", "1張改良");
TChineseCardNames[CardNames.LACKEYS] = new TranslatedCardName("	奴才", "張奴才", "1張奴才");
TChineseCardNames[CardNames.MOUNTAIN_VILLAGE] = new TranslatedCardName("山莊", "張山莊", "1張山莊");
TChineseCardNames[CardNames.PATRON] = new TranslatedCardName("贊助人", "張贊助人", "1張贊助人");
TChineseCardNames[CardNames.PRIEST] = new TranslatedCardName("祭司", "張祭司", "1張祭司");
TChineseCardNames[CardNames.RESEARCH] = new TranslatedCardName("研究", "張研究", "1張研究");
TChineseCardNames[CardNames.SILK_MERCHANT] = new TranslatedCardName("布商", "張布商", "1張布商");
TChineseCardNames[CardNames.OLD_WITCH] = new TranslatedCardName("老女巫", "張老女巫", "1張老女巫");
TChineseCardNames[CardNames.RECRUITER] = new TranslatedCardName("招募員", "張招募員", "1張招募員");
TChineseCardNames[CardNames.SCEPTER] = new TranslatedCardName("權杖", "張權杖", "1張權杖");
TChineseCardNames[CardNames.SCHOLAR] = new TranslatedCardName("學者", "張學者", "1張學者");
TChineseCardNames[CardNames.SCULPTOR] = new TranslatedCardName("雕刻家", "張雕刻家", "1張雕刻家");
TChineseCardNames[CardNames.SEER] = new TranslatedCardName("先知", "張先知", "1張先知");
TChineseCardNames[CardNames.SPICES] = new TranslatedCardName("香料", "張香料", "1張香料");
TChineseCardNames[CardNames.SWASHBUCKLER] = new TranslatedCardName("劍客", "張劍客", "1張劍客");
TChineseCardNames[CardNames.TREASURER] = new TranslatedCardName("收稅人", "張收稅人", "1張收稅人");
TChineseCardNames[CardNames.VILLAIN] = new TranslatedCardName("敵人", "張敵人", "1張敵人");
TChineseCardNames[CardNames.FLAG] = new TranslatedCardName("旗幟");
TChineseCardNames[CardNames.HORN] = new TranslatedCardName("號角");
TChineseCardNames[CardNames.KEY] = new TranslatedCardName("金鑰");
TChineseCardNames[CardNames.LANTERN] = new TranslatedCardName("燈籠");
TChineseCardNames[CardNames.TREASURE_CHEST] = new TranslatedCardName("寶箱");
TChineseCardNames[CardNames.ACADEMY] = new TranslatedCardName("學院");
TChineseCardNames[CardNames.BARRACKS] = new TranslatedCardName("兵營");
TChineseCardNames[CardNames.CANAL] = new TranslatedCardName("運河");
TChineseCardNames[CardNames.CAPITALISM] = new TranslatedCardName("資本主義");
TChineseCardNames[CardNames.CATHEDRAL] = new TranslatedCardName("大教堂");
TChineseCardNames[CardNames.CITADEL] = new TranslatedCardName("堡壘");
TChineseCardNames[CardNames.CITY_GATE] = new TranslatedCardName("城門");
TChineseCardNames[CardNames.CROP_ROTATION] = new TranslatedCardName("輪耕");
TChineseCardNames[CardNames.EXPLORATION] = new TranslatedCardName("探索");
TChineseCardNames[CardNames.FAIR] = new TranslatedCardName("小市場");
TChineseCardNames[CardNames.FLEET] = new TranslatedCardName("船隊");
TChineseCardNames[CardNames.GUILDHALL] = new TranslatedCardName("市政廳");
TChineseCardNames[CardNames.INNOVATION] = new TranslatedCardName("創新");
TChineseCardNames[CardNames.PAGEANT] = new TranslatedCardName("歷史劇");
TChineseCardNames[CardNames.PIAZZA] = new TranslatedCardName("聖馬可廣場");
TChineseCardNames[CardNames.ROAD_NETWORK] = new TranslatedCardName("道路網");
TChineseCardNames[CardNames.SEWERS] = new TranslatedCardName("下水道");
TChineseCardNames[CardNames.SILOS] = new TranslatedCardName("筒倉");
TChineseCardNames[CardNames.SINISTER_PLOT] = new TranslatedCardName("惡毒手段");
TChineseCardNames[CardNames.STAR_CHART] = new TranslatedCardName("星空圖");

TChineseCardNames[CardNames.AVANTO] = new TranslatedCardName("冰湖", "張冰湖", "1張冰湖");
TChineseCardNames[CardNames.BLACK_MARKET] = new TranslatedCardName("黑市", "張黑市", "1張黑市");
TChineseCardNames[CardNames.ENVOY] = new TranslatedCardName("使節", "張使節", "1張使節");
TChineseCardNames[CardNames.GOVERNOR] = new TranslatedCardName("總督", "張總督", "1張總督");
TChineseCardNames[CardNames.PRINCE] = new TranslatedCardName("王子", "張王子", "1張王子");
TChineseCardNames[CardNames.SAUNA] = new TranslatedCardName("桑拿", "張桑拿", "1張桑拿");
TChineseCardNames[CardNames.STASH] = new TranslatedCardName("私房錢", "張私房錢", "1張私房錢");
TChineseCardNames[CardNames.SUMMON] = new TranslatedCardName("召喚", "張召喚", "1張召喚");
TChineseCardNames[CardNames.WALLED_VILLAGE] = new TranslatedCardName("卡卡頌村", "張卡卡頌村", "1張卡卡頌村");
TChineseCardNames[CardNames.BLACK_MARKET_PILE] = new TranslatedCardName("黑市牌堆", "黑市牌堆", "黑市牌堆");
TChineseCardNames[CardNames.DISMANTLE] = new TranslatedCardName("拆除", "張拆除", "1張拆除");
TChineseCardNames[CardNames.CHURCH] = new TranslatedCardName("教會", "教會", "1張教會");
TChineseCardNames[CardNames.CAPTAIN] = new TranslatedCardName("船長", "船長", "1張船長");

TChineseCardNames[CardNames.BLACK_CAT] = new TranslatedCardName("黑貓", "黑貓", "1張黑貓");
TChineseCardNames[CardNames.SLEIGH] = new TranslatedCardName("雪橇", "雪橇", "1張雪橇");
TChineseCardNames[CardNames.SUPPLIES] = new TranslatedCardName("輜重", "輜重", "1張輜重");
TChineseCardNames[CardNames.CAMEL_TRAIN] = new TranslatedCardName("駱駝商隊", "駱駝商隊", "1張駱駝商隊");
TChineseCardNames[CardNames.GOATHERD] = new TranslatedCardName("牧羊人", "牧羊女", "1張牧羊女");
TChineseCardNames[CardNames.SCRAP] = new TranslatedCardName("廢棄", "廢棄", "1張廢棄");
TChineseCardNames[CardNames.SHEEPDOG] = new TranslatedCardName("牧羊犬", "牧羊犬", "1張牧羊犬");
TChineseCardNames[CardNames.SNOWY_VILLAGE] = new TranslatedCardName("雪之村", "雪之村", "1張雪之村");
TChineseCardNames[CardNames.STOCKPILE] = new TranslatedCardName("儲備品", "儲備品", "1張儲備品");
TChineseCardNames[CardNames.BOUNTY_HUNTER] = new TranslatedCardName("賞金獵人", "賞金獵人", "1張賞金獵人");
TChineseCardNames[CardNames.CARDINAL] = new TranslatedCardName("樞機", "樞機", "1張樞機");
TChineseCardNames[CardNames.CAVALRY] = new TranslatedCardName("騎兵", "騎兵", "1張騎兵");
TChineseCardNames[CardNames.GROOM] = new TranslatedCardName("馬伕", "馬伕", "1張馬伕");
TChineseCardNames[CardNames.HOSTELRY] = new TranslatedCardName("客棧", "客棧", "1張客棧");
TChineseCardNames[CardNames.VILLAGE_GREEN] = new TranslatedCardName("鄉村", "鄉村", "1張鄉村");
TChineseCardNames[CardNames.BARGE] = new TranslatedCardName("駁船", "駁船", "1張駁船");
TChineseCardNames[CardNames.COVEN] = new TranslatedCardName("女巫團", "女巫團", "1張女巫團");
TChineseCardNames[CardNames.DISPLACE] = new TranslatedCardName("替換", "替換", "1張替換");
TChineseCardNames[CardNames.FALCONER] = new TranslatedCardName("訓鷹人", "訓鷹人", "1張訓鷹人");
TChineseCardNames[CardNames.FISHERMAN] = new TranslatedCardName("漁夫", "漁夫", "1張漁夫");
TChineseCardNames[CardNames.GATEKEEPER] = new TranslatedCardName("守門人", "守門人", "1張守門人");
TChineseCardNames[CardNames.HUNTING_LODGE] = new TranslatedCardName("狩獵小屋", "狩獵小屋", "1張狩獵小屋");
TChineseCardNames[CardNames.KILN] = new TranslatedCardName("窯", "窯", "1張窯");
TChineseCardNames[CardNames.LIVERY] = new TranslatedCardName("馬車行", "馬車行", "1張馬車行");
TChineseCardNames[CardNames.MASTERMIND] = new TranslatedCardName("策劃者", "策劃者", "1張策劃者");
TChineseCardNames[CardNames.PADDOCK] = new TranslatedCardName("圍場", "圍場", "1張圍場");
TChineseCardNames[CardNames.SANCTUARY] = new TranslatedCardName("保護區", "保護區", "1張保護區");
TChineseCardNames[CardNames.DESTRIER] = new TranslatedCardName("戰馬", "戰馬", "1張戰馬");
TChineseCardNames[CardNames.WAYFARER] = new TranslatedCardName("旅人", "旅人", "1張旅人");
TChineseCardNames[CardNames.ANIMAL_FAIR] = new TranslatedCardName("動物博覽會", "動物博覽會", "1張動物博覽會");
TChineseCardNames[CardNames.HORSE] = new TranslatedCardName("馬", "馬", "1張馬");
TChineseCardNames[CardNames.WAY_OF_THE_BUTTERFLY] = new TranslatedCardName("蝴蝶");
TChineseCardNames[CardNames.WAY_OF_THE_CAMEL] = new TranslatedCardName("駱駝");
TChineseCardNames[CardNames.WAY_OF_THE_CHAMELEON] = new TranslatedCardName("變色龍");
TChineseCardNames[CardNames.WAY_OF_THE_FROG] = new TranslatedCardName("青蛙");
TChineseCardNames[CardNames.WAY_OF_THE_GOAT] = new TranslatedCardName("山羊");
TChineseCardNames[CardNames.WAY_OF_THE_HORSE] = new TranslatedCardName("馬");
TChineseCardNames[CardNames.WAY_OF_THE_MOLE] = new TranslatedCardName("鼴鼠");
TChineseCardNames[CardNames.WAY_OF_THE_MONKEY] = new TranslatedCardName("猴");
TChineseCardNames[CardNames.WAY_OF_THE_MOUSE] = new TranslatedCardName("倉鼠");
TChineseCardNames[CardNames.WAY_OF_THE_MULE] = new TranslatedCardName("騾子");
TChineseCardNames[CardNames.WAY_OF_THE_OTTER] = new TranslatedCardName("水獺");
TChineseCardNames[CardNames.WAY_OF_THE_OWL] = new TranslatedCardName("貓頭鷹");
TChineseCardNames[CardNames.WAY_OF_THE_OX] = new TranslatedCardName("公牛");
TChineseCardNames[CardNames.WAY_OF_THE_PIG] = new TranslatedCardName("豬豬");
TChineseCardNames[CardNames.WAY_OF_THE_RAT] = new TranslatedCardName("田鼠");
TChineseCardNames[CardNames.WAY_OF_THE_SEAL] = new TranslatedCardName("海豹");
TChineseCardNames[CardNames.WAY_OF_THE_SHEEP] = new TranslatedCardName("綿羊");
TChineseCardNames[CardNames.WAY_OF_THE_SQUIRREL] = new TranslatedCardName("松鼠");
TChineseCardNames[CardNames.WAY_OF_THE_TURTLE] = new TranslatedCardName("烏龜");
TChineseCardNames[CardNames.WAY_OF_THE_WORM] = new TranslatedCardName("蠕蟲");
TChineseCardNames[CardNames.DELAY] = new TranslatedCardName("姍姍來遲");
TChineseCardNames[CardNames.DESPERATION] = new TranslatedCardName("破釜沉舟");
TChineseCardNames[CardNames.GAMBLE] = new TranslatedCardName("拼死一搏");
TChineseCardNames[CardNames.PURSUE] = new TranslatedCardName("跟蹤追擊");
TChineseCardNames[CardNames.RIDE] = new TranslatedCardName("南航北騎");
TChineseCardNames[CardNames.TOIL] = new TranslatedCardName("寒耕熱耘");
TChineseCardNames[CardNames.ENHANCE] = new TranslatedCardName("煥然一新");
TChineseCardNames[CardNames.MARCH] = new TranslatedCardName("行兵列陣");
TChineseCardNames[CardNames.TRANSPORT] = new TranslatedCardName("舟車之利");
TChineseCardNames[CardNames.BANISH] = new TranslatedCardName("放諸四裔");
TChineseCardNames[CardNames.BARGAIN] = new TranslatedCardName("討價還價");
TChineseCardNames[CardNames.INVEST] = new TranslatedCardName("共同投資");
TChineseCardNames[CardNames.SEIZE_THE_DAY] = new TranslatedCardName("一刻千金");
TChineseCardNames[CardNames.COMMERCE] = new TranslatedCardName("抱布貿絲");
TChineseCardNames[CardNames.DEMAND] = new TranslatedCardName("有求斯應");
TChineseCardNames[CardNames.STAMPEDE] = new TranslatedCardName("馬不停蹄");
TChineseCardNames[CardNames.REAP] = new TranslatedCardName("務本力穡");
TChineseCardNames[CardNames.ENCLAVE] = new TranslatedCardName("境內飛地");
TChineseCardNames[CardNames.ALLIANCE] = new TranslatedCardName("割臂同盟");
TChineseCardNames[CardNames.POPULATE] = new TranslatedCardName("移居他鄉");

TChineseCardNames[CardNames.PRIZE_PILE] = new TranslatedCardName("獎勵卡", "獎勵卡", "獎勵卡");

TChineseCardNames[CardNames.CARD_OF_THE_BUTTERFLY] = "";
TChineseCardNames[CardNames.CARD_OF_THE_CAMEL] = "";
TChineseCardNames[CardNames.CARD_OF_THE_CHAMELEON] = "";
TChineseCardNames[CardNames.CARD_OF_THE_FROG] = "";
TChineseCardNames[CardNames.CARD_OF_THE_GOAT] = "";
TChineseCardNames[CardNames.CARD_OF_THE_HORSE] = "";
TChineseCardNames[CardNames.CARD_OF_THE_MOLE] = "";
TChineseCardNames[CardNames.CARD_OF_THE_MONKEY] = "";
TChineseCardNames[CardNames.CARD_OF_THE_MOUSE] = "";
TChineseCardNames[CardNames.CARD_OF_THE_MULE] = "";
TChineseCardNames[CardNames.CARD_OF_THE_OTTER] = "";
TChineseCardNames[CardNames.CARD_OF_THE_OWL] = "";
TChineseCardNames[CardNames.CARD_OF_THE_OX] = "";
TChineseCardNames[CardNames.CARD_OF_THE_PIG] = "";
TChineseCardNames[CardNames.CARD_OF_THE_RAT] = "";
TChineseCardNames[CardNames.CARD_OF_THE_SEAL] = "";
TChineseCardNames[CardNames.CARD_OF_THE_SHEEP] = "";
TChineseCardNames[CardNames.CARD_OF_THE_SQUIRREL] = "";
TChineseCardNames[CardNames.CARD_OF_THE_TURTLE] = "";
TChineseCardNames[CardNames.CARD_OF_THE_WORM] = "";

// new Seaside
TChineseCardNames[CardNames.MONKEY] = new TranslatedCardName("猴子", "張猴子", "1張猴子");
TChineseCardNames[CardNames.BLOCKADE] = new TranslatedCardName("封鎖", "張封鎖", "1張封鎖");
TChineseCardNames[CardNames.SAILOR] = new TranslatedCardName("水手", "張水手", "1張水手");
TChineseCardNames[CardNames.PIRATE] = new TranslatedCardName("海盜", "張海盜", "1張海盜");
TChineseCardNames[CardNames.CORSAIR] = new TranslatedCardName("海賊船", "張海賊船", "1張海賊船");
TChineseCardNames[CardNames.SEA_CHART] = new TranslatedCardName("海圖", "張海圖", "1張海圖");
TChineseCardNames[CardNames.ASTROLABE] = new TranslatedCardName("星盤", "張星盤", "1張星盤");
TChineseCardNames[CardNames.TIDE_POOLS] = new TranslatedCardName("潮池", "張潮池", "1張潮池");
TChineseCardNames[CardNames.SEA_WITCH] = new TranslatedCardName("海上女巫", "張海上女巫", "1張海上女巫");

// Allies
TChineseCardNames[CardNames.TOWN] = new TranslatedCardName("城鎮", "張城鎮", "1張城鎮");
TChineseCardNames[CardNames.MODIFY] = new TranslatedCardName("修建", "張修建", "1張修建");
TChineseCardNames[CardNames.MERCHANT_CAMP] = new TranslatedCardName("商人營地", "張商人營地", "1張商人營地");
TChineseCardNames[CardNames.SWAP] = new TranslatedCardName("交換", "張交換", "1張交換");
TChineseCardNames[CardNames.SKIRMISHER] = new TranslatedCardName("散兵", "張散兵", "1張散兵");
TChineseCardNames[CardNames.HIGHWAYMAN] = new TranslatedCardName("攔路強盜", "張攔路強盜", "1張攔路強盜");
TChineseCardNames[CardNames.BARBARIAN] = new TranslatedCardName("野蠻人", "張野蠻人", "1張野蠻人");
TChineseCardNames[CardNames.INNKEEPER] = new TranslatedCardName("旅店老闆", "張旅店老闆", "1張旅店老闆");
TChineseCardNames[CardNames.CAPITAL_CITY] = new TranslatedCardName("首都", "張首都", "1張首都");
TChineseCardNames[CardNames.SPECIALIST] = new TranslatedCardName("專家", "張專家", "1張專家");
TChineseCardNames[CardNames.CARPENTER] = new TranslatedCardName("木匠", "張木匠", "1張木匠");
TChineseCardNames[CardNames.ROYAL_GALLEY] = new TranslatedCardName("皇家槳帆船", "張皇家槳帆船", "1張皇家槳帆船");
TChineseCardNames[CardNames.MARQUIS] = new TranslatedCardName("侯爵", "張侯爵", "1張侯爵");
TChineseCardNames[CardNames.SENTINEL] = new TranslatedCardName("崗哨", "張崗哨", "1張崗哨");
TChineseCardNames[CardNames.COURIER] = new TranslatedCardName("快遞", "張快遞", "1張快遞");
TChineseCardNames[CardNames.GALLERIA] = new TranslatedCardName("拱廊街", "張拱廊街", "1張拱廊街");
TChineseCardNames[CardNames.HUNTER] = new TranslatedCardName("獵人", "張獵人", "一張獵人");

TChineseCardNames[CardNames.SYCOPHANT] = new TranslatedCardName("馬屁精", "張馬屁精", "1張馬屁精");
TChineseCardNames[CardNames.UNDERLING] = new TranslatedCardName("下屬", "張下屬", "1張下屬");
TChineseCardNames[CardNames.IMPORTER] = new TranslatedCardName("進口商", "張進口商", "1張進口商");
TChineseCardNames[CardNames.CONTRACT] = new TranslatedCardName("合約", "張合約", "1張合約");
TChineseCardNames[CardNames.EMISSARY] = new TranslatedCardName("使者", "張使者", "1張使者");
TChineseCardNames[CardNames.BAUBLE] = new TranslatedCardName("飾品", "張飾品", "1張飾品");
TChineseCardNames[CardNames.BROKER] = new TranslatedCardName("掮客", "張掮客", "1張掮客");
TChineseCardNames[CardNames.GUILDMASTER] = new TranslatedCardName("公會會長", "張公會會長", "1張公會會長");

TChineseCardNames[CardNames.ARCHITECTS_GUILD] = new TranslatedCardName("建築師公會");
TChineseCardNames[CardNames.ORDER_OF_ASTROLOGERS] = new TranslatedCardName("占星師的指令");
TChineseCardNames[CardNames.LEAGUE_OF_BANKERS] = new TranslatedCardName("銀行家聯盟");
TChineseCardNames[CardNames.MOUNTAIN_FOLK] = new TranslatedCardName(" 山地居民");
TChineseCardNames[CardNames.WOODWORKERS_GUILD] = new TranslatedCardName("木工師公會");
TChineseCardNames[CardNames.PEACEFUL_CULT] = new TranslatedCardName("和平禮拜");
TChineseCardNames[CardNames.CRAFTERS_GUILD] = new TranslatedCardName("工藝師公會");
TChineseCardNames[CardNames.CAVE_DWELLERS] = new TranslatedCardName("穴居人");
TChineseCardNames[CardNames.PLATEAU_SHEPHERDS] = new TranslatedCardName("高原牧羊人");
TChineseCardNames[CardNames.DESERT_GUIDES] = new TranslatedCardName("沙漠導遊");
TChineseCardNames[CardNames.FAMILY_OF_INVENTORS] = new TranslatedCardName("發明家族");
TChineseCardNames[CardNames.ISLAND_FOLK] = new TranslatedCardName("島上居民");
TChineseCardNames[CardNames.ORDER_OF_MASONS] = new TranslatedCardName("石匠的指令");
TChineseCardNames[CardNames.BAND_OF_NOMADS] = new TranslatedCardName("遊牧民族");
TChineseCardNames[CardNames.GANG_OF_PICKPOCKETS] = new TranslatedCardName("扒手集團");
TChineseCardNames[CardNames.MARKET_TOWNS] = new TranslatedCardName("市集小鎮");
TChineseCardNames[CardNames.FOREST_DWELLERS] = new TranslatedCardName("森林中的居民");
TChineseCardNames[CardNames.FELLOWSHIP_OF_SCRIBES] = new TranslatedCardName("抄寫員協會");
TChineseCardNames[CardNames.LEAGUE_OF_SHOPKEEPERS] = new TranslatedCardName("店主聯盟");
TChineseCardNames[CardNames.COASTAL_HAVEN] = new TranslatedCardName("沿海港口");
TChineseCardNames[CardNames.CITY_STATE] = new TranslatedCardName("城邦");
TChineseCardNames[CardNames.TRAPPERS_LODGE] = new TranslatedCardName("獵人小屋");
TChineseCardNames[CardNames.CIRCLE_OF_WITCHES] = new TranslatedCardName("女巫圈");

TChineseCardNames[CardNames.AUGUR_PILE] = new TranslatedCardName("占兆官");
TChineseCardNames[CardNames.HERB_GATHERER] = new TranslatedCardName("採藥人", "張採藥人", "1張採藥人");
TChineseCardNames[CardNames.ACOLYTE] = new TranslatedCardName("侍僧", "張侍僧", "1張侍僧");
TChineseCardNames[CardNames.SORCERESS] = new TranslatedCardName("女術士", "張女術士", "1張女術士");
TChineseCardNames[CardNames.SIBYL] = new TranslatedCardName("女預言家", "張女預言家", "1張女預言家");
TChineseCardNames[CardNames.CLASH_PILE] = new TranslatedCardName("衝突");
TChineseCardNames[CardNames.BATTLE_PLAN] = new TranslatedCardName("作戰計劃", "張作戰計劃", "1張作戰計劃");
TChineseCardNames[CardNames.ARCHER] = new TranslatedCardName("射手", "張射手", "1張射手");
TChineseCardNames[CardNames.WARLORD] = new TranslatedCardName("軍閥", "張軍閥", "1張軍閥");
TChineseCardNames[CardNames.TERRITORY] = new TranslatedCardName("領地", "張領地", "1張領地");
TChineseCardNames[CardNames.FORT_PILE] = new TranslatedCardName("碉堡");
TChineseCardNames[CardNames.TENT] = new TranslatedCardName("帳篷", "張帳篷", "1張帳篷");
TChineseCardNames[CardNames.GARRISON] = new TranslatedCardName("屯駐地", "張屯駐地", "1張屯駐地");
TChineseCardNames[CardNames.HILL_FORT] = new TranslatedCardName("山寨", "張山寨", "1張山寨");
TChineseCardNames[CardNames.STRONGHOLD] = new TranslatedCardName("據點", "張據點", "1張據點");
TChineseCardNames[CardNames.ODYSSEY_PILE] = new TranslatedCardName("奧德賽");
TChineseCardNames[CardNames.OLD_MAP] = new TranslatedCardName("舊地圖", "張舊地圖", "1張舊地圖");
TChineseCardNames[CardNames.VOYAGE] = new TranslatedCardName("航海", "張航海", "1張航海");
TChineseCardNames[CardNames.SUNKEN_TREASURE] = new TranslatedCardName("沉沒的寶藏", "張沉沒的寶藏", "1張沉沒的寶藏");
TChineseCardNames[CardNames.DISTANT_SHORE] = new TranslatedCardName("遙遠的彼岸", "張遙遠的彼岸", "1張遙遠的彼岸");
TChineseCardNames[CardNames.TOWNSFOLK_PILE] = new TranslatedCardName("市民");
TChineseCardNames[CardNames.TOWN_CRIER] = new TranslatedCardName("街頭公告員", "張街頭公告員", "1張街頭公告員");
TChineseCardNames[CardNames.BLACKSMITH] = new TranslatedCardName("鐵工", "張鐵工", "1張鐵工");
TChineseCardNames[CardNames.MILLER] = new TranslatedCardName("磨坊主", "張磨坊主", "1張磨坊主");
TChineseCardNames[CardNames.ELDER] = new TranslatedCardName("長老", "張長老", "1張長老");
TChineseCardNames[CardNames.WIZARD_PILE] = new TranslatedCardName("巫師");
TChineseCardNames[CardNames.STUDENT] = new TranslatedCardName("學生", "張學生", "1張學生");
TChineseCardNames[CardNames.CONJURER] = new TranslatedCardName("幻術士", "張幻術士", "1張幻術士");
TChineseCardNames[CardNames.SORCERER] = new TranslatedCardName("術士", "張術士", "1張術士");
TChineseCardNames[CardNames.LICH] = new TranslatedCardName("巫妖", "巫妖", "1張巫妖");

TChineseCardNames[CardNames.ANVIL] = new TranslatedCardName("鐵砧", "張鐵砧", "1張鐵砧");
TChineseCardNames[CardNames.CLERK] = new TranslatedCardName("書記", "張書記", "1張書記");
TChineseCardNames[CardNames.INVESTMENT] = new TranslatedCardName("投資", "張投資", "1張投資");
TChineseCardNames[CardNames.TIARA] = new TranslatedCardName("箍冠", "張箍冠", "1張箍冠");
TChineseCardNames[CardNames.CHARLATAN] = new TranslatedCardName("庸醫", "張庸醫", "1張庸醫");
TChineseCardNames[CardNames.COLLECTION] = new TranslatedCardName("收藏品", "張收藏品", "1張收藏品");
TChineseCardNames[CardNames.CRYSTAL_BALL] = new TranslatedCardName("水晶球", "張水晶球", "1張水晶球");
TChineseCardNames[CardNames.MAGNATE] = new TranslatedCardName("巨頭", "張巨頭", "1張巨頭");
TChineseCardNames[CardNames.WAR_CHEST] = new TranslatedCardName("戰爭寶箱", "張戰爭寶箱", "1張戰爭寶箱");

TChineseCardNames[CardNames.GUARD_DOG] = new TranslatedCardName("看門狗", "張看門狗", "1張看門狗");
TChineseCardNames[CardNames.BERSERKER] = new TranslatedCardName("狂戰士", "張狂戰士", "1張狂戰士");
TChineseCardNames[CardNames.NOMADS] = new TranslatedCardName("游居者", "張游居者", "1張游居者");
TChineseCardNames[CardNames.TRAIL] = new TranslatedCardName("小路", "張小路", "1張小路");
TChineseCardNames[CardNames.WEAVER] = new TranslatedCardName("紡織者", "張紡織者", "1張紡織者");
TChineseCardNames[CardNames.SOUK] = new TranslatedCardName("露天市場", "張露天市場", "1張露天市場");
TChineseCardNames[CardNames.CAULDRON] = new TranslatedCardName("大釜", "張大釜", "1張大釜");
TChineseCardNames[CardNames.WHEELWRIGHT] = new TranslatedCardName("輪匠", "張輪匠", "1張輪匠");
TChineseCardNames[CardNames.WITCHS_HUT] = new TranslatedCardName("女巫小屋", "張女巫小屋", "1張女巫小屋");

TChineseCardNames[CardNames.CAGE] = new TranslatedCardName("籠子", "張籠子", "1張籠子");
TChineseCardNames[CardNames.GROTTO] = new TranslatedCardName("岩穴", "張岩穴", "1張岩穴");
TChineseCardNames[CardNames.JEWELLED_EGG] = new TranslatedCardName("珠寶彩蛋", "張珠寶彩蛋", "1張珠寶彩蛋");
TChineseCardNames[CardNames.SEARCH] = new TranslatedCardName("搜索", "張搜索", "1張搜索");
TChineseCardNames[CardNames.SHAMAN] = new TranslatedCardName("薩滿", "張薩滿", "1張薩滿");
TChineseCardNames[CardNames.SECLUDED_SHRINE] = new TranslatedCardName("僻靜的神殿", "張僻靜的神殿", "1張僻靜的神殿");
TChineseCardNames[CardNames.SIREN] = new TranslatedCardName("塞壬", "張", "1張塞壬");
TChineseCardNames[CardNames.STOWAWAY] = new TranslatedCardName("偷渡客", "張偷渡客", "1張偷渡客");
TChineseCardNames[CardNames.TASKMASTER] = new TranslatedCardName("工頭", "張工頭", "1張工頭");
TChineseCardNames[CardNames.ABUNDANCE] = new TranslatedCardName("富裕", "張富裕", "1張富裕");
TChineseCardNames[CardNames.CABIN_BOY] = new TranslatedCardName("船上侍者", "張船上侍者", "1張船上侍者");
TChineseCardNames[CardNames.CRUCIBLE] = new TranslatedCardName("坩堝", "張坩堝", "1張坩堝");
TChineseCardNames[CardNames.FLAGSHIP] = new TranslatedCardName("旗艦", "張旗艦", "1張旗艦");
TChineseCardNames[CardNames.FORTUNE_HUNTER] = new TranslatedCardName("財富獵人", "張財富獵人", "1張財富獵人");
TChineseCardNames[CardNames.GONDOLA] = new TranslatedCardName("貢多拉", "張貢多拉", "1張貢多拉");
TChineseCardNames[CardNames.HARBOR_VILLAGE] = new TranslatedCardName("港口村莊", "張港口村莊", "1張港口村莊");
TChineseCardNames[CardNames.LANDING_PARTY] = new TranslatedCardName("登陸隊", "張登陸隊", "1張登陸隊");
TChineseCardNames[CardNames.MAPMAKER] = new TranslatedCardName("繪圖師", "張繪圖師", "1張繪圖師");
TChineseCardNames[CardNames.MAROON] = new TranslatedCardName("放逐", "張放逐", "1張放逐");
TChineseCardNames[CardNames.ROPE] = new TranslatedCardName("繩子", "張繩子", "1張繩子");
TChineseCardNames[CardNames.SWAMP_SHACKS] = new TranslatedCardName("沼澤小屋", "張沼澤小屋", "1張沼澤小屋");
TChineseCardNames[CardNames.TOOLS] = new TranslatedCardName("用具", "張用具", "1張用具");
TChineseCardNames[CardNames.BURIED_TREASURE] = new TranslatedCardName("埋沒的寶藏", "張埋沒的寶藏", "1張埋沒的寶藏");
TChineseCardNames[CardNames.CREW] = new TranslatedCardName("船員", "張船員", "1張船員");
TChineseCardNames[CardNames.CUTTHROAT] = new TranslatedCardName("殺手", "張殺手", "1張殺手");
TChineseCardNames[CardNames.ENLARGE] = new TranslatedCardName("擴大", "張擴大", "1張擴大");
TChineseCardNames[CardNames.FIGURINE] = new TranslatedCardName("小雕像", "張小雕像", "1張小雕像");
TChineseCardNames[CardNames.FIRST_MATE] = new TranslatedCardName("大副", "張大副", "1張大副");
TChineseCardNames[CardNames.FRIGATE] = new TranslatedCardName("護衛艦", "張護衛艦", "1張護衛艦");
TChineseCardNames[CardNames.LONGSHIP] = new TranslatedCardName("長船", "張長船", "1張長船");
TChineseCardNames[CardNames.MINING_ROAD] = new TranslatedCardName("礦山之路", "張礦山之路", "1張礦山之路");
TChineseCardNames[CardNames.PENDANT] = new TranslatedCardName("垂飾", "張垂飾", "1張垂飾");
TChineseCardNames[CardNames.PICKAXE] = new TranslatedCardName("十字搞", "張十字搞", "1張十字搞");
TChineseCardNames[CardNames.PILGRIM] = new TranslatedCardName("朝聖", "張朝聖", "1張朝聖");
TChineseCardNames[CardNames.QUARTERMASTER] = new TranslatedCardName("舵手", "張舵手", "1張舵手");
TChineseCardNames[CardNames.SILVER_MINE] = new TranslatedCardName("銀礦", "張銀礦", "1張銀礦");
TChineseCardNames[CardNames.TRICKSTER] = new TranslatedCardName("騙子", "張騙子", "1張騙子");
TChineseCardNames[CardNames.WEALTHY_VILLAGE] = new TranslatedCardName("富裕的村落", "張富裕的村落", "1張富裕的村落");
TChineseCardNames[CardNames.SACK_OF_LOOT] = new TranslatedCardName("贓物包", "贓物包", "1張贓物包");
TChineseCardNames[CardNames.KINGS_CACHE] = new TranslatedCardName("國王的藏寶庫", "張國王的藏寶庫", "1張國王的藏寶庫");
TChineseCardNames[CardNames.BURY] = new TranslatedCardName("下葬", "張下葬", "1張下葬");
TChineseCardNames[CardNames.AVOID] = new TranslatedCardName("躲避", "張躲避", "1張躲避");
TChineseCardNames[CardNames.DELIVER] = new TranslatedCardName("傳遞", "張傳遞", "1張傳遞");
TChineseCardNames[CardNames.PERIL] = new TranslatedCardName("冒險", "張冒險", "1張冒險");
TChineseCardNames[CardNames.RUSH] = new TranslatedCardName("趕工", "張趕工", "1張趕工");
TChineseCardNames[CardNames.FORAY] = new TranslatedCardName("突襲", "張突襲", "1張突襲");
TChineseCardNames[CardNames.LAUNCH] = new TranslatedCardName("下水", "張下水", "1張下水");
TChineseCardNames[CardNames.MIRROR] = new TranslatedCardName("鏡射", "張鏡射", "1張鏡射");
TChineseCardNames[CardNames.PREPARE] = new TranslatedCardName("準備", "張準備", "1張準備");
TChineseCardNames[CardNames.SCROUNGE] = new TranslatedCardName("乞討", "張乞討", "1張乞討");
TChineseCardNames[CardNames.JOURNEY] = new TranslatedCardName("旅行", "張旅行", "1張旅行");
TChineseCardNames[CardNames.MAELSTROM] = new TranslatedCardName("漩渦", "張漩渦", "1張漩渦");
TChineseCardNames[CardNames.LOOTING] = new TranslatedCardName("劫掠", "張劫掠", "1張劫掠");
TChineseCardNames[CardNames.INVASION] = new TranslatedCardName("入侵", "張入侵", "1張入侵");
TChineseCardNames[CardNames.PROSPER] = new TranslatedCardName("繁榮", "張繁榮", "1張繁榮");
TChineseCardNames[CardNames.LOOT_PILE] = new TranslatedCardName("贓物牌堆");
TChineseCardNames[CardNames.AMPHORA] = new TranslatedCardName("雙耳瓶", "雙耳瓶", "1張雙耳瓶");
TChineseCardNames[CardNames.DOUBLOONS] = new TranslatedCardName("金幣", "張金幣", "1張金幣");
TChineseCardNames[CardNames.ENDLESS_CHALICE] = new TranslatedCardName("永恆聖杯", "張永恆聖杯", "1張永恆聖杯");
TChineseCardNames[CardNames.FIGUREHEAD] = new TranslatedCardName("艏飾像", "張艏飾像", "1張艏飾像");
TChineseCardNames[CardNames.HAMMER] = new TranslatedCardName("鐵鎚", "張鐵鎚", "1張鐵鎚");
TChineseCardNames[CardNames.INSIGNIA] = new TranslatedCardName("徽章", "張徽章", "1張徽章");
TChineseCardNames[CardNames.JEWELS] = new TranslatedCardName("寶石", "張寶石", "1張寶石");
TChineseCardNames[CardNames.ORB] = new TranslatedCardName("珠寶", "張珠寶", "1張珠寶");
TChineseCardNames[CardNames.PRIZE_GOAT] = new TranslatedCardName("頂級山羊", "張頂級山羊", "1張頂級山羊");
TChineseCardNames[CardNames.PUZZLE_BOX] = new TranslatedCardName("迷箱", "張迷箱", "1張迷箱");
TChineseCardNames[CardNames.SEXTANT] = new TranslatedCardName("六分儀", "張六分儀", "1張六分儀");
TChineseCardNames[CardNames.SHIELD] = new TranslatedCardName("盾牌", "張盾牌", "1張盾牌");
TChineseCardNames[CardNames.SPELL_SCROLL] = new TranslatedCardName("法術卷軸", "張法術卷軸", "1張法術卷軸");
TChineseCardNames[CardNames.STAFF] = new TranslatedCardName("長杖", "張長杖", "1張長杖");
TChineseCardNames[CardNames.SWORD] = new TranslatedCardName("寶劍", "張寶劍", "1張寶劍");
TChineseCardNames[CardNames.CHEAP] = new TranslatedCardName("便宜的", "張便宜的", "1張便宜的");
TChineseCardNames[CardNames.CURSED] = new TranslatedCardName("被詛咒的", "張被詛咒的", "1張被詛咒的");
TChineseCardNames[CardNames.FATED] = new TranslatedCardName("命中注定的", "張命中注定的", "1張命中注定的");
TChineseCardNames[CardNames.FAWNING] = new TranslatedCardName("奉承的", "張奉承的", "1張奉承的");
TChineseCardNames[CardNames.FRIENDLY] = new TranslatedCardName("友善的", "張友善的", "1張友善的");
TChineseCardNames[CardNames.HASTY] = new TranslatedCardName("急躁的", "張急躁的", "1張急躁的");
TChineseCardNames[CardNames.INHERITED] = new TranslatedCardName("繼承來的", "張繼承來的", "繼承來的");
TChineseCardNames[CardNames.INSPIRING] = new TranslatedCardName("鼓舞人心的", "張鼓舞人心的", "1張鼓舞人心的");
TChineseCardNames[CardNames.NEARBY] = new TranslatedCardName("附近的", "張附近的", "1張附近的");
TChineseCardNames[CardNames.PATIENT] = new TranslatedCardName("有耐心的", "張有耐心的", "1張有耐心的");
TChineseCardNames[CardNames.PIOUS] = new TranslatedCardName("虔誠的", "張虔誠的", "1張虔誠的");
TChineseCardNames[CardNames.RECKLESS] = new TranslatedCardName("魯莽的", "張魯莽的", "1張魯莽的");
TChineseCardNames[CardNames.RICH] = new TranslatedCardName("有錢的", "張有錢的", "1張有錢的");
TChineseCardNames[CardNames.SHY] = new TranslatedCardName("怕生的", "張怕生的", "1張怕生的");
TChineseCardNames[CardNames.TIRELESS] = new TranslatedCardName("不怕累的", "張不怕累的", "1張不怕累的");
TChineseCardNames[CardNames.MARCHLAND] = new TranslatedCardName("邊境地帶", "張邊境地帶", "1張邊境地帶");

TChineseCardNames[CardNames.SHOP] = new TranslatedCardName("商店", "張商店", "1張商店");
TChineseCardNames[CardNames.CARNIVAL] = new TranslatedCardName("嘉年華會", "張嘉年華會", "1張嘉年華會");
TChineseCardNames[CardNames.FERRYMAN] = new TranslatedCardName("渡船夫", "張渡船夫", "1張渡船夫");
TChineseCardNames[CardNames.FARMHANDS] = new TranslatedCardName("農場工人", "張農場工人", "1張農場工人");
TChineseCardNames[CardNames.JOUST] = new TranslatedCardName("馬上比武", "張馬上比武", "1張馬上比武");
TChineseCardNames[CardNames.REWARDS_PILE] = new TranslatedCardName("獎品", "張獎品", "1張獎品");
TChineseCardNames[CardNames.COURSER] = new TranslatedCardName("駿馬", "張駿馬", "1張駿馬");
TChineseCardNames[CardNames.RENOWN] = new TranslatedCardName("名望", "張名望", "1張名望");
TChineseCardNames[CardNames.CORONET] = new TranslatedCardName("冠冕", "張冠冕", "1張冠冕");
TChineseCardNames[CardNames.DEMESNE] = new TranslatedCardName("領域", "張領域", "1張領域");
TChineseCardNames[CardNames.HOUSECARL] = new TranslatedCardName("家庭保鏢", "張家庭保鏢", "1張家庭保鏢");
TChineseCardNames[CardNames.HUGE_TURNIP] = new TranslatedCardName("大蕪菁", "張大蕪菁", "1張大蕪菁");
TChineseCardNames[CardNames.FARRIER] = new TranslatedCardName("蹄鐵工", "張蹄鐵工", "1張蹄鐵工");
TChineseCardNames[CardNames.INFIRMARY] = new TranslatedCardName("醫院", "張醫院", "1張醫院");
TChineseCardNames[CardNames.FOOTPAD] = new TranslatedCardName("攔路賊", "張攔路賊", "1張攔路賊");
TChineseCardNames[CardNames.FISHMONGER] = new TranslatedCardName("魚販", "張魚販", "1張魚販");
TChineseCardNames[CardNames.SNAKE_WITCH] = new TranslatedCardName("蛇魔女", "張蛇魔女", "1張蛇魔女");
TChineseCardNames[CardNames.ARISTOCRAT] = new TranslatedCardName("名門", "張名門", "1張名門");
TChineseCardNames[CardNames.CRAFTSMAN] = new TranslatedCardName("工匠", "張工匠", "1張工匠");
TChineseCardNames[CardNames.RIVERBOAT] = new TranslatedCardName("河船", "張河船", "1張河船");
TChineseCardNames[CardNames.ROOT_CELLAR] = new TranslatedCardName("根地窖", "張根地窖", "1張根地窖");
TChineseCardNames[CardNames.ALLEY] = new TranslatedCardName("小巷", "張小巷", "1張小巷");
TChineseCardNames[CardNames.CHANGE] = new TranslatedCardName("改變", "張改變", "1張改變");
TChineseCardNames[CardNames.NINJA] = new TranslatedCardName("忍者", "張忍者", "1張忍者");
TChineseCardNames[CardNames.POET] = new TranslatedCardName("詩人", "張詩人", "1張詩人");
TChineseCardNames[CardNames.RIVER_SHRINE] = new TranslatedCardName("河邊神社", "張河邊神社", "1張河邊神社");
TChineseCardNames[CardNames.RUSTIC_VILLAGE] = new TranslatedCardName("質樸村莊", "張質樸村莊", "1張質樸村莊");
TChineseCardNames[CardNames.GOLD_MINE] = new TranslatedCardName("黃金礦坑", "張黃金礦坑", "1張黃金礦坑");
TChineseCardNames[CardNames.IMPERIAL_ENVOY] = new TranslatedCardName("欽差", "張欽差", "1張欽差");
TChineseCardNames[CardNames.KITSUNE] = new TranslatedCardName("狐狸", "張狐狸", "1張狐狸");
TChineseCardNames[CardNames.LITTER] = new TranslatedCardName("轎子", "張轎子", "1張轎子");
TChineseCardNames[CardNames.RICE_BROKER] = new TranslatedCardName("札差", "張札差", "1張札差");
TChineseCardNames[CardNames.RONIN] = new TranslatedCardName("浪人", "張浪人", "1張浪人");
TChineseCardNames[CardNames.TANUKI] = new TranslatedCardName("狸貓", "張狸貓", "1張狸貓");
TChineseCardNames[CardNames.TEA_HOUSE] = new TranslatedCardName("茶館", "張茶館", "1張茶館");
TChineseCardNames[CardNames.SAMURAI] = new TranslatedCardName("武士", "張武士", "1張武士");
TChineseCardNames[CardNames.RICE] = new TranslatedCardName("白米", "張白米", "1張白米");
TChineseCardNames[CardNames.MOUNTAIN_SHRINE] = new TranslatedCardName("高山神社", "張高山神社", "1張高山神社");
TChineseCardNames[CardNames.DAIMYO] = new TranslatedCardName("大名", "大名", "1張大名");
TChineseCardNames[CardNames.ARTIST] = new TranslatedCardName("美術家", "張美術家", "1張美術家");
TChineseCardNames[CardNames.APPROACHING_ARMY] = new TranslatedCardName("逼近的大軍");
TChineseCardNames[CardNames.BIDING_TIME] = new TranslatedCardName("耐心等待時機");
TChineseCardNames[CardNames.BUREAUCRACY] = new TranslatedCardName("官僚體制");
TChineseCardNames[CardNames.DIVINE_WIND] = new TranslatedCardName("神風");
TChineseCardNames[CardNames.ENLIGHTENMENT] = new TranslatedCardName("領悟");
TChineseCardNames[CardNames.FLOURISHING_TRADE] = new TranslatedCardName("繁榮貿易");
TChineseCardNames[CardNames.GOOD_HARVEST] = new TranslatedCardName("大豐收");
TChineseCardNames[CardNames.GREAT_LEADER] = new TranslatedCardName("好上司");
TChineseCardNames[CardNames.GROWTH] = new TranslatedCardName("成長");
TChineseCardNames[CardNames.HARSH_WINTER] = new TranslatedCardName("嚴冬");
TChineseCardNames[CardNames.KIND_EMPEROR] = new TranslatedCardName("三神器");
TChineseCardNames[CardNames.PANIC] = new TranslatedCardName("恐慌");
TChineseCardNames[CardNames.PROGRESS] = new TranslatedCardName("進展");
TChineseCardNames[CardNames.RAPID_EXPANSION] = new TranslatedCardName("快速擴張");
TChineseCardNames[CardNames.SICKNESS] = new TranslatedCardName("疾病");
TChineseCardNames[CardNames.AMASS] = new TranslatedCardName("收集");
TChineseCardNames[CardNames.ASCETICISM] = new TranslatedCardName("苦行者");
TChineseCardNames[CardNames.CREDIT] = new TranslatedCardName("貸款");
TChineseCardNames[CardNames.FORESIGHT] = new TranslatedCardName("預卜");
TChineseCardNames[CardNames.KINTSUGI] = new TranslatedCardName("金繼");
TChineseCardNames[CardNames.PRACTICE] = new TranslatedCardName("練習");
TChineseCardNames[CardNames.SEA_TRADE] = new TranslatedCardName("海上貿易");
TChineseCardNames[CardNames.RECEIVE_TRIBUTE] = new TranslatedCardName("進貢");
TChineseCardNames[CardNames.GATHER] = new TranslatedCardName("聚集");
TChineseCardNames[CardNames.CONTINUE] = new TranslatedCardName("持續");