"use strict";

var FrenchLevelIntroductions = ["On the first level you play with the base game; no expansions are used.", "Level 2 adds no new game concepts, but a lot of simple cards from a variety of expansions."];

var FrenchGameConcepts = {};
FrenchGameConcepts[GameConcepts.CHOICE_CARDS] = new TranslatedGameConcept("Choice Cards", "Some cards give you a choice of what to do. For example Steward can do one of three things;\nwhen playing it you have three buttons to choose from.\nOther cards, like Wishing Well, let you name a card.\nYou can click on a card to pick that one, or type in the name of another card.");

FrenchGameConcepts[GameConcepts.DURATIONS] = new TranslatedGameConcept("Durations", "Duration cards are orange, and can do something on a future turn. \nThe only thing special about them is, they stay in play until the turn they finish doing things. \nFor example when you play Gear, you draw two cards, and can set aside up to two cards from your hand. \nIf you don't set aside any cards, Gear is done, and will be discarded that turn. \nIf you do set aside cards though, Gear stays in play; on your next turn you get the cards, and Gear is discarded at the end of that turn. \nA Throne Room played on a Duration card stays in play with it.");

FrenchGameConcepts[GameConcepts.KINGDOM_TREASURES] = new TranslatedGameConcept("Special Treasures", "Treasures don't need to just make coins; they can do things, just like Action cards. \nFor example when you play Venture, in addition to getting [1], you reveal cards from your deck until revealing a Treasure, and play it.");

FrenchGameConcepts[GameConcepts.PLATINUM_COLONY] = new TranslatedGameConcept("Platinum & Colony", "Platinum is a treasure worth [5]; Colony is a Victory card worth 10 VP. These aren't kingdom cards; they show up next to Gold and Province.\nThey appear randomly, based on the frequency of Prosperity cards in the board - 10% chance per such card. They always appear together.\nIn games with Colony, the Colony pile being empty ends the game (the game can also still end due to Provinces or three empty piles).");

FrenchGameConcepts[GameConcepts.WHEN_GAIN_BUY] = new TranslatedGameConcept("When Gain/Buy", "Some cards do something when gained or bought. Those aren't quite the same thing, and don't have the same timing.\nWhen you buy a card, first you do things that happen when you buy it, then you actually gain the card, then you do things that happen when you gain it.\nYou can also gain cards without buying them, e.g. with Remodel.\nSo for example if you Remodel a card into a Border Village, then right after you gain the Border Village, you'll gain a cheaper card. \nBut if you Remodel a card into a Forum, nothing special happens; you didn't buy the Forum.\nIf you buy a Border Village, you'll gain it, then gain a cheaper card; and if you buy a Forum, you'll get +1 Buy, then gain it.\nAfter you've gained or bought the card, that ability is no longer doing anything for you; \na Border Village in your deck is just like a Village, except that it costs [6].");

FrenchGameConcepts[GameConcepts.WHEN_TRASH] = new TranslatedGameConcept("When Trash", "Some cards do something when trashed.\nThe card has to actually be put into the trash for the effect to happen.\nThe effect can happen in the middle of resolving another card;\nfor example if you trash a Catacombs with Remodel, first you'll gain a card costing less than Catacombs, \nand then you'll gain a card costing up to [2] more than Catacombs due to Remodel.");

FrenchGameConcepts[GameConcepts.VP_TOKENS] = new TranslatedGameConcept("VP Tokens", "VP tokens provide a way to make VP, towards winning, without a Victory card going into your deck.\nFor example every time you play Monument, you get another point. There's no limit to how many points this can gain you.\nVP tokens are included in your score as displayed on the screen, and also displayed separately.");

FrenchGameConcepts[GameConcepts.COFFERS] = new TranslatedGameConcept("Coffers", "Some cards give you +Coffers; this is [] you can save until you want to use it.\nDuring your turn, you can convert any amount of Coffers tokens into [].\nYou can spend the Coffers the same turn you get it, including during your Buy phase.\nFor example, Spices gives you two Coffers when you gain it.");

FrenchGameConcepts[GameConcepts.VILLAGERS] = new TranslatedGameConcept("Villagers", "Some cards give you +Villagers; these are +Actions that you can save until you want to use them.\nDuring your action phase, you can convert any amount of Villagers into +1 Action each; normally you'll use them one at a time.\nFor example, Lackeys gives you two Villagers when you gain it.\nYou might on some turn play Lackeys, draw a Smithy you can't play, and then use one of your Villagers for +1 Action, so that you can keep playing cards.");

FrenchGameConcepts[GameConcepts.EXILE] = new TranslatedGameConcept("Exile", "Each player has their own Exile mat that cards can go on.\n\"Exile a card\" means to put it onto your mat;\nif the card was in the Supply, this doesn't trigger \"when you gain this\" abilities, but the card is still yours now (for example when counting score).\nWhen you gain a card, you may also discard all copies of it from your mat if you want to (putting them into your discard pile).\nYou can't discard just some of them; it's all or none.\nFor example when you play Bounty Hunter, you exile a card from your hand, and if you didn't already have a copy of it on the mat, you get +[3].\nYou could exile an Estate from your hand; that's like getting rid of the Estate, since you'll never draw it, but it still counts for your score at the end.\nIf you later gained an Estate, and wanted the Exiled Estate in your deck for some reason, you could discard it.");

FrenchGameConcepts[GameConcepts.REACTIONS] = new TranslatedGameConcept("Reactions", "Reaction cards are blue, and have an ability that can be used at an unusual time.\nOften you reveal the card from your hand to use the ability; sometimes you also discard it; sometimes you play it.\nYou are familiar with Moat, which stops attacks; other Reactions don't necessarily do that, they just do whatever they say.\nFor example Sheepdog lets you play it whenever you gain a card; it might even be another player's turn.\nYou draw two cards, and can still play another Sheepdog then, even one you just drew.");

FrenchGameConcepts[GameConcepts.FAVORS] = new TranslatedGameConcept("Favors", "Liaison cards have an ability that can give you Favor tokens. \nIn a game with one or more Liaisons, there will always be exactly one Ally; \nthis a landscape-style card that tells you what Favor tokens can be used to do this game. \nFor example in a game with Underling, you might have Trappers' Lodge as the Ally; \nthen each time you gain a card that game, you can spend a Favor token to put the card onto your deck. \nYou start the game with one Favor token (or five in games with Importer), and can even use it on your first turn.");

FrenchGameConcepts[GameConcepts.WHEN_DISCARD] = new TranslatedGameConcept("When Discard", "Some cards do something when discarded.\nSome care about being discarded from your hand, like Tunnel does; when you discard it, you can reveal it to gain a Gold.\nOthers do something when discarded from play, which normally is in Clean-up; they have to have been put into play in order to do this.\nFor example when you play Treasury, you will get a chance in Clean-up to put it onto your deck, provided you didn't buy a Victory card this turn.");

FrenchGameConcepts[GameConcepts.TRASH_INTERACTION] = new TranslatedGameConcept("Interact with Trash", "A few cards interact with the Trash pile. \nYou can look at the Trash at any time by toggling the view in the upper right.\nFor example Lurker can gain Action cards from the Trash.\nIt doesn't matter how they got there.");

FrenchGameConcepts[GameConcepts.UNIQUE_MATS] = new TranslatedGameConcept("Unique Mats", "Some cards have their own unique mats, which each player gets their own copy of.\nFor example Native Village has a mat, and can either add a card to your mat for it, or take all the cards from it.\nEach of these mats has its contents indicated differently.");

FrenchGameConcepts[GameConcepts.EVENTS] = new TranslatedGameConcept("Events ", "Sometimes the game has landscape-style cards that modify the game somehow.\nEvents are the first of these.\nEvents have a cost and an effect; you can use a Buy in your Buy phase and pay the cost, to generate the effect.\nIf you have multiple Buys you can buy one of the effects more than once (unless it says otherwise).\nYou can buy a card plus an Event, and so on.\nBuying an Event is not buying a card, and Events aren't affected by things that refer to \"cards,\" like Bridge.");

FrenchGameConcepts[GameConcepts.RESERVE] = new TranslatedGameConcept("Reserve", "Reserve cards are tan, and have an ability that puts them onto your Reserve mat, plus another ability that can \"call\" them off of it.\n\"Calling\" such a card puts it into play.\nSo overall they are cards that let you save an effect for sometime in the future.\nFor example, when you play Ratcatcher, you get +1 Card, +1 Action, and put Ratcatcher onto your mat.\nThen at the start of some future turn, you can call the Ratcatcher, moving it to play and trashing a card from your hand.\nRatcatcher is then discarded that turn in Clean-up.");

FrenchGameConcepts[GameConcepts.ADVENTURES_TOKENS] = new TranslatedGameConcept("Adventures Tokens", "The Adventures expansion comes with a variety of tokens, which each player gets a set of.\nSome go onto supply piles; others sit in front of a player somewhere.\nFor example Training is an Event that lets a player put a +[1] token on a pile;\nthen for the rest of the game, the player gets +[1] when playing a card from that pile.\nThere are similar tokens for +1 Action, +1 Card, and +1 Buy.\nRanger uses the Journey token, which turns over each time Ranger is played;\nthis means that every 2nd play of Ranger will get you the +5 Cards.\nThere are also: a -[2] cost token, which makes one pile cheaper on your turns;\na Trashing token, which lets you trash a card when buying a card from the pile with it;\nand -[1] and -1 Card tokens, which deprive you of the next +[1] you'd get or card you'd draw.");

FrenchGameConcepts[GameConcepts.NIGHT_PHASE] = new TranslatedGameConcept("Night", "Nocturne adds a new phase, the Night phase.\nThis phase happens after the Buy phase and before Clean-up.\nDuring it, you can play any number of Night cards.\nThat's all there is to it; Night cards are cards that you play in that phase.\nBy default that's the only way you can play them; they aren't Actions, they aren't Treasures.\nFor example Monastery is a Night card; in the Night phase you can play it to trash some number of cards, depending on how many cards you've gained this turn.\nSeveral Night cards are also Duration cards, and some of them go to your hand when gained; Den of Sin is one of these.\nSo when you buy Den of Sin, you gain it, put it into your hand... and then it's your Night phase, and you can play it immediately.");

FrenchGameConcepts[GameConcepts.SHELTERS] = new TranslatedGameConcept("Shelters", "The Shelters appear randomly, based on the frequency of Dark Ages cards in the board - 10% chance per such card.\nThey always appear together.\nThey aren't Supply cards; they replace starting Estates.\nSo instead of 7 Coppers and 3 Estates, you have 7 Coppers, an Overgrown Estate, a Necropolis, and a Hovel.");

FrenchGameConcepts[GameConcepts.HORSES] = new TranslatedGameConcept("Horses", "Horse is a non-Supply card that some cards can gain for you.\nFor example each time you play Supplies, you gain a Horse (onto your deck).\nWhen you play a Horse, you get +2 Cards and +1 Action, and return the Horse to its pile.\nHorses can also be used in other ways that cards can be used, e.g. you can Remodel a Horse (meaning it goes to the trash, not to its pile).\nIf the Horse pile is empty, Horses can't be gained (until more Horses return to it).\nHorse can't be gained other ways, only via cards that specifically gain Horses.\nThe Horse pile doesn't count towards the 3-pile game ending.");

FrenchGameConcepts[GameConcepts.PROJECTS] = new TranslatedGameConcept("Projects", "Projects are another type of landscape-style card. In games with them, you get two cubes in your player color.\nIn your Buy phase, you can use a Buy and pay the indicated cost to place a cube on a Project.\nThis cube grants you the Project's ability for the rest of the game.\nFor example, Fair costs [4], and if you buy it you'll have +1 Buy on your turns for the rest of the game.\nAs with Events, Projects aren't \"cards\" and so e.g. don't get cheaper due to Bridge.\nYou can't move a cube later; in a game with three Projects, you only get to buy two of them.\nYou can only buy each Project once per game, you can't put two cubes on one.");

FrenchGameConcepts[GameConcepts.LANDMARKS] = new TranslatedGameConcept("Landmarks", "Landmarks are another type of landscape-style card.\nThey provide some additional way to score VP, either with VP tokens or just added onto scores at the end of the game.\nFor example, Tower provides 1 VP at the end of the game per card you have from an empty non-Victory Supply pile.\nYour VP total tracks the effects of Landmarks.");

FrenchGameConcepts[GameConcepts.EXTRA_CARDS] = new TranslatedGameConcept("Extra Cards", "Some cards involve different non-Supply piles.\nLike Horses, the non-Supply cards can only be gained via the cards that specifically gain them, and the piles don't count towards the game end condition.\nFor example Hermit has a condition that causes you to trade it for a Madman. \nHermit is the only card that uses Madman.\n\nThere are also three cards that use Ruins, which are a Supply pile only put out when a card uses them.\nThe cards in the Ruins pile are shuffled; there are five different ones possible.\nTwo cards give out these cards, like how Witch gives out Curses.\n\n\nNocturne has a group of non-Supply piles called Spirits. Some cards refer to individual Spirits, and one refers to the group.\n");

FrenchGameConcepts[GameConcepts.SPLIT_PILES] = new TranslatedGameConcept("Split Piles", "\nSome piles have two different cards in them.\nThere are 5 copies of the cheaper card, then 5 copies of the other card.\nFor example one of these piles has 5 Settlers, and under them 5 Bustling Villages.\nYou can only gain the top card of the pile; if you want a Bustling Village, someone has to get the 5th Settlers.\n\nThere is also one pile that has 8 different cards in it - the Castles - and one pile that has 10 different cards, shuffled - the Knights.\n");

FrenchGameConcepts[GameConcepts.ARTIFACTS] = new TranslatedGameConcept("Artifacts", "Four cards refer to an Artifact.\nThe Artifacts themselves are just tracked information, not cards.\nFor example the Flag confers an extra card to its bearer when drawing.\nTo get it, you need to gain or trash a Flag Bearer.\nOnly one player can have an Artifact at a time;\nif you get the Flag when someone else has it, you take it from them.");

FrenchGameConcepts[GameConcepts.BOONS_HEXES] = new TranslatedGameConcept("Boons & Hexes", "Nocturne has Boons and Hexes.\nThese are small decks of different effects;\nBoons are good and Hexes are bad (used by Attacks).\nWhen a card says \"Receive a Boon,\" like Bard does, you turn over the next Boon and do whatever it says (shuffling them when needed).\nFor example one Boon is The Sea's Gift; you just get +1 Card.\nHexes are similar but bad; in multiplayer, when you give each other player a Hex, they all get the same one.");

FrenchGameConcepts[GameConcepts.HEIRLOOMS] = new TranslatedGameConcept("Heirlooms", "Nocturne has Heirlooms, which are cards that replace starting Coppers.\nThey are used whenever a corresponding kingdom card is used; for example, in games with Shepherd, Pasture is used.\nThey just replace a single starting Copper; in games with Shepherd, you have 3 Estates, 6 Coppers, and a Pasture.\nOf course you might have more than one card producing an Heirloom in a game, and might have both Shelters and an Heirloom.\nThe log indicates what the starting deck contents are.");

FrenchGameConcepts[GameConcepts.STRANGE_COSTS] = new TranslatedGameConcept("Unusual Costs", "Some cards have an unusual cost of some kind.\nFor example Destrier costs [1] less per card you've gained that turn.\nIf you gained three cards, it would cost [3].\nThese changes always apply to all copies of a card.\nThe program will update the costs to reflect what they currently are.");

FrenchGameConcepts[GameConcepts.OVERPAY] = new TranslatedGameConcept("Overpay", "A few cards let you pay extra when you buy them, to generate an effect.\nFor example when you buy Masterpiece, each extra [1] you pay gains you a Silver.\nThis doesn't change the cost of Masterpiece itself, it still costs [3].\nYou just pay more to get the effect.");

FrenchGameConcepts[GameConcepts.COMMAND] = new TranslatedGameConcept("Command", "Some cards can play another card without moving it.\nYou generate the effect of playing a card, but don't get e.g. \"while this is in play\" bonuses.\nFor example Band of Misfits can play a cheaper card from the Supply.\nSome of these cards use the \"Command\" type to avoid letting you generate a loop of cards playing other cards; that's all it means.\nCards like Band of Misfits stay in play as long as the card they played would have, e.g. if Band of Misfits plays Fishing Village, it stays out until next turn.");

FrenchGameConcepts[GameConcepts.POTION] = new TranslatedGameConcept("Potions", "Some cards have [P] in the cost.\nThis is a resource you can only get by playing a Potion (and those games always include Potion as a Supply pile).\nIt's otherwise similar to []; you get it, you spend it.\nIf you have [2] and [P], you can buy University, for example.\nTo buy a 2nd University you'd need another [2] and also another [P].\n\nWhen cards check a card's cost in coins, they ignore the [P] symbol.\nWhen comparing costs, [P] is more than nothing, but neither more nor less than a [] amount.\nYou can Remodel a University into a card costing [4] and [P], or a card costing [4] ([4] is \"up to\" [4] and [P]), but you can't Remodel a Copper into a University.\nKnights trash cards costing from [3] to [6]; a card with [P] in its cost does not cost from [3] to [6].");

FrenchGameConcepts[GameConcepts.DEBT] = new TranslatedGameConcept("Debt", "Debt lets you buy a card now and pay for it later.\nSome cards have [D] in the cost. This represents debt; you don't pay any [] to buy [D] costs, but instead take that many [D] tokens.\nWhile you have [D] tokens, you can't buy cards or pay for Events or Projects;\nin your Buy phase, both before and after buying cards (and in-between buys), you can pay off [D] at [1] per [1D].\nPaying off [D] doesn't use up a Buy; it's just something you can do in your Buy phase.\nSo for example you might have [3] and decide to buy City Quarter.\nYou take [8D] and immediately pay off [3D] with your [3].\nOn your next turn, you can't buy cards yet, you have to pay off the remaining [5D] first.\nIf you had [7] you could pay off the [5D] and then still buy something for [2], or buy another City Quarter.\nA few things cost both [] and [D]; you have to have the [] to pay for those.\n\n[D] isn't []; when cards check a card's cost in coins, they ignore [D].\nA card with a [D] cost and no [] cost has an implicit [0] in its cost.\nWhen comparing costs, [D] is more than nothing, but neither more nor less than a [] amount.\nYou can Remodel a City Quarter into an Overlord, which also costs [8D], and can Remodel a City Quarter into an Estate, which costs \"up to\" [2] plus [8D].\nKnights trash cards costing from [3] to [6]; cards with [D] in the cost do not cost from [3] to [6].");

FrenchGameConcepts[GameConcepts.WAYS] = new TranslatedGameConcept("Ways", "Ways are another kind of landscape-style card.\nEach one gives all Action cards another option, all through the game.\nFor example, in a game with Way of the Sheep, every time you play an Action card, you can either do what it normally does, or just get +[2] out of it.\nWays only replace the top part of cards with a dividing line; the rest still happens whenever it says it does.\n");

FrenchGameConcepts[GameConcepts.DEBT_COSTS] = new TranslatedGameConcept("Debt Costs", "Debt lets you buy a card now and pay for it later. Some cards have [D] in the cost. This represents debt; \nyou don't pay any [] for a [D] cost, but instead take that many [D] tokens. While you have [D] tokens, you can't buy cards or pay for Events or Projects;\nduring your turns, you can pay off [D] at [1] per [1D]. Paying off [D] doesn't use up a Buy. \nSo for example you might have [3] and decide to buy City Quarter. You take [8D] and immediately pay off [3D] with your [3].\nOn your next turn you can't buy cards yet, you still have to pay off the remaining [5D] first. \nIf you had [7] you could pay off the [5D] and still buy something for [2], or buy another City Quarter.\nA few things cost both [] and [D]; you have to have the [] to pay for those.\n\n[D] isn't []; when cards check a card's cost in coins, they ignore [D]. A card with a [D] cost and no [] cost has an implicit [0] in its cost.\nWhen comparing costs, [D] is more than nothing, but neither more nor less than a [] amount. You can Remodel a City Quarter into an Overlord.");

FrenchGameConcepts[GameConcepts.ON_PLAY_DEBT] = new TranslatedGameConcept("On-play Debt", "Some cards give [D] when played; +[3D] gives you 3 debt tokens.\nWhile you have [D] tokens, you can't buy cards or pay for Events or Projects; \nduring your turns, you can pay off [D] at [1] per [1D]. Paying off [D] doesn't use up a Buy. \nSo for example you could play Root Cellar and get [3D]. \nIn your Buy phase you play 5 Coppers, pay off the [3D], and have [2] left to spend.\n[D] lasts between turns, as long as it hasn't been paid.");

FrenchGameConcepts[GameConcepts.SHADOW] = new TranslatedGameConcept("Shadow", "Shadow cards can be played from your deck. They have unique backs, and go to the bottom of your deck when shuffling.\nWhenever you can play an Action card from your hand - whether normally, or via a card like Throne Room - you can choose to use that play on a Shadow card from your deck.\nShadow cards aren't in your hand when they're in your deck; you can't discard them and so on.\nYou just get to play them from your deck. You can also play them normally from your hand if you draw them.");

FrenchGameConcepts[GameConcepts.OMEN_PROPHECY] = new TranslatedGameConcept("Omens & Prophecies", "An Omen causes the game to include a Prophecy, and has a +1 [Sun] ability. The Prophecy starts with [Sun] tokens on it (5 with 2 players), and +1 [Sun] \nremoves a token from the Prophecy. At the start of the game, the ability on the Prophecy doesn't do anything; \nwhen the last token is removed, the Prophecy ability becomes active, changing the rest of the game. \nFor example in a game with Rustic Village and Great Leader, each time you play Rustic Village, you remove a token from Great Leader; \nonce it has no tokens on it, every Action played will produce +1 Action.");