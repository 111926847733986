"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var LogModel = function () {
    function LogModel(game, serverMessenger) {
        _classCallCheck(this, LogModel);

        this.game = game;
        this.serverMessenger = serverMessenger;
        this.entries = [];
        this.premoveLogEntries = [];
        this.statusBarView = new StatusBarView(this);
        this.logView = new LogView(this.game, this);
        this.ticker = null;
    }

    _createClass(LogModel, [{
        key: "restoreTicker",
        value: function restoreTicker() {
            this.displayedTicker = this.privateTicker;
            this.statusBarView.onTickerChange();
        }
    }, {
        key: "newLogEntry",
        value: function newLogEntry(logEntry) {
            if (logEntry instanceof LogEntry) {
                this.entries.filter(function (e) {
                    return e.index >= logEntry.index;
                }).forEach(function (e) {
                    e.renderedLines.forEach(function (l) {
                        return l.remove();
                    });
                });
                this.entries = this.entries.filter(function (e) {
                    return e.index < logEntry.index;
                });
                this.logView.parseLogEntry(logEntry);
            } else if (logEntry instanceof DecisionEntry) {
                this.entries.filter(function (e) {
                    return e.index === logEntry.index;
                }).forEach(function (e) {
                    e.renderedLines.forEach(function (l) {
                        return l.remove();
                    });
                });
                this.entries = this.entries.filter(function (e) {
                    return e.index !== logEntry.index;
                });
                this.game.metaGameModel.hideResignWindow();
                this.logView.addDecisionEntry(logEntry);
            } else {
                throw new Error('Unknown log entry type.');
            }
            this.entries.push(logEntry);
        }
    }, {
        key: "newLogEntries",
        value: function newLogEntries(logEntries) {
            logEntries.forEach(this.newLogEntry.bind(this));
        }
    }, {
        key: "undoTo",
        value: function undoTo(entry) {
            this.logView.showingUndos = false;
            this.serverMessenger.undoRequest(entry.decisionIndex);
        }
    }, {
        key: "setPremoveEntries",
        value: function setPremoveEntries(entries) {
            this.premoveLogEntries = entries;
            this.logView.updatePremoveLogEntries();
        }
    }, {
        key: "ticker",
        set: function set(newTicker) {
            this.privateTicker = newTicker;
            this.displayedTicker = this.privateTicker;
            this.statusBarView.onTickerChange();
        },
        get: function get() {
            return this.displayedTicker;
        }
    }, {
        key: "tempTicker",
        set: function set(newTicker) {
            this.displayedTicker = newTicker;
            this.statusBarView.onTickerChange();
        }
    }]);

    return LogModel;
}();