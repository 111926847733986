"use strict";

var TChineseCardModes = {};

TChineseCardModes[CardModes.PAWN_CARD_ACTION] = "卡片& 行動";
TChineseCardModes[CardModes.PAWN_CARD] = "+1 卡片";
TChineseCardModes[CardModes.PAWN_ACTION] = "+1 行動";
TChineseCardModes[CardModes.PAWN_BUY] = "+1 購買";
TChineseCardModes[CardModes.PAWN_COIN] = "+1 預算";
TChineseCardModes[CardModes.STEWARD_CARDS] = "+2 卡片";
TChineseCardModes[CardModes.STEWARD_COINS] = "+2 預算";
TChineseCardModes[CardModes.STEWARD_TRASH] = "移除 2";
TChineseCardModes[CardModes.MINION_COINS] = "+2 預算";
TChineseCardModes[CardModes.MINION_NEWHAND] = "棄卡";
TChineseCardModes[CardModes.NOBLES_CARDS] = "+3 卡片";
TChineseCardModes[CardModes.NOBLES_ACTIONS] = "+2 行動";
TChineseCardModes[CardModes.COURTIER_ACTION] = "+1 行動";
TChineseCardModes[CardModes.COURTIER_BUY] = "+1 購買";
TChineseCardModes[CardModes.COURTIER_COINS] = "+3 預算";
TChineseCardModes[CardModes.COURTIER_GOLD] = "獲得黃金";
TChineseCardModes[CardModes.NATIVE_VILLAGE_SET_ASIDE] = "藏到部落上";
TChineseCardModes[CardModes.NATIVE_VILLAGE_RETURN] = "從部落拿回";
TChineseCardModes[CardModes.PIRATE_SHIP_RAID] = "攻擊！";
TChineseCardModes[CardModes.PIRATE_SHIP_PROFIT] = "獲益";
TChineseCardModes[CardModes.SCRYING_POOL_TOPDECK] = "放置牌頂";
TChineseCardModes[CardModes.SCRYING_POOL_DISCARD] = "棄卡";
TChineseCardModes[CardModes.LOAN_DISCARD] = "棄卡";
TChineseCardModes[CardModes.LOAN_TRASH] = "移除";
TChineseCardModes[CardModes.TRUSTY_STEED_CARDS_ACTIONS] = "卡片& 行動";
TChineseCardModes[CardModes.TRUSTY_STEED_CARDS] = "+2 卡片";
TChineseCardModes[CardModes.TRUSTY_STEED_ACTIONS] = "+2 行動";
TChineseCardModes[CardModes.TRUSTY_STEED_COINS] = "+2 預算";
TChineseCardModes[CardModes.TRUSTY_STEED_SILVERS] = "4張銀幣";
TChineseCardModes[CardModes.JESTER_THEY_GAIN] = "對手獲得";
TChineseCardModes[CardModes.JESTER_YOU_GAIN] = "我獲得";
TChineseCardModes[CardModes.DUCHESS_KEEP] = "保留";
TChineseCardModes[CardModes.DUCHESS_DISCARD] = "棄卡";
TChineseCardModes[CardModes.ORACLE_TOPDECK] = "放置牌頂";
TChineseCardModes[CardModes.ORACLE_DISCARD] = "棄卡";
TChineseCardModes[CardModes.JACK_KEEP] = "保留";
TChineseCardModes[CardModes.JACK_DISCARD] = "棄卡";
TChineseCardModes[CardModes.SPICE_MERCHANT_CARDS] = "卡片, 行動";
TChineseCardModes[CardModes.SPICE_MERCHANT_COINS] = "預算, 購買";
TChineseCardModes[CardModes.CATACOMBS_TAKE] = "加到手中";
TChineseCardModes[CardModes.CATACOMBS_DISCARD] = "棄卡";
TChineseCardModes[CardModes.COUNT_DISCARD] = "棄卡";
TChineseCardModes[CardModes.COUNT_TOPDECK] = "放置牌頂";
TChineseCardModes[CardModes.COUNT_GAIN_COPPER] = "獲得 銅幣";
TChineseCardModes[CardModes.COUNT_COINS] = "+3 預算";
TChineseCardModes[CardModes.COUNT_TRASH_HAND] = "移除手牌";
TChineseCardModes[CardModes.COUNT_GAIN_DUCHY] = "獲得公國";
TChineseCardModes[CardModes.HUNTING_GROUNDS_ESTATES] = "獲得 3 莊園";
TChineseCardModes[CardModes.HUNTING_GROUNDS_DUCHY] = "獲得公國";
TChineseCardModes[CardModes.IRONMONGER_KEEP] = "保留";
TChineseCardModes[CardModes.IRONMONGER_DISCARD] = "棄卡";
TChineseCardModes[CardModes.SCAVENGER_DISCARD] = "棄卡";
TChineseCardModes[CardModes.SCAVENGER_KEEP] = "繼續";
TChineseCardModes[CardModes.SQUIRE_ACTIONS] = "+2 行動";
TChineseCardModes[CardModes.SQUIRE_BUYS] = "+2 購買";
TChineseCardModes[CardModes.SQUIRE_SILVER] = "獲得銀幣";
TChineseCardModes[CardModes.MERCENARY_DONT_TRASH] = "不移除";
TChineseCardModes[CardModes.DOCTOR_TRASH] = "移除";
TChineseCardModes[CardModes.DOCTOR_DISCARD] = "棄卡";
TChineseCardModes[CardModes.DOCTOR_TOPDECK] = "放置牌頂";
TChineseCardModes[CardModes.AMULET_COIN] = "+1 預算";
TChineseCardModes[CardModes.AMULET_TRASH] = "移除";
TChineseCardModes[CardModes.AMULET_SILVER] = "獲得銀幣";
TChineseCardModes[CardModes.MISER_COPPER_TO_MAT] = "銅幣放到板塊上";
TChineseCardModes[CardModes.MISER_PROFIT] = "獲益";
TChineseCardModes[CardModes.MESSENGER_DISCARD] = "放到棄牌堆";
TChineseCardModes[CardModes.MESSENGER_KEEP] = "保留";
TChineseCardModes[CardModes.QUEST_ATTACK] = "攻擊卡";
TChineseCardModes[CardModes.QUEST_CURSES] = "2 詛咒";
TChineseCardModes[CardModes.QUEST_CARDS] = "6 卡片";
TChineseCardModes[CardModes.TEACHER_CARD] = "卡片";
TChineseCardModes[CardModes.TEACHER_ACTION] = "行動";
TChineseCardModes[CardModes.TEACHER_BUY] = "購買";
TChineseCardModes[CardModes.TEACHER_COIN] = "預算";
TChineseCardModes[CardModes.LIBRARY_DRAW_IT] = "保留";
TChineseCardModes[CardModes.LIBRARY_SKIP_IT] = "跳過";
TChineseCardModes[CardModes.GLADIATOR_PRETEND] = "不展示";
TChineseCardModes[CardModes.SPRAWLING_CASTLE_ESTATES] = "獲得 3 莊園";
TChineseCardModes[CardModes.SPRAWLING_CASTLE_DUCHY] = "獲得公國";
TChineseCardModes[CardModes.CHARM_BUY_MONEY] = "+購買, +2 預算";
TChineseCardModes[CardModes.CHARM_COPY] = "獲得 卡片";
TChineseCardModes[CardModes.WILD_HUNT_CARDS] = "卡片";
TChineseCardModes[CardModes.WILD_HUNT_POINTS] = "分數";
TChineseCardModes[CardModes.PRINCE_SET_ASIDE] = "放置一邊";
TChineseCardModes[CardModes.PRINCE_NO] = "繼續";
TChineseCardModes[CardModes.HAMLET_ACTION] = "棄卡換取行動";
TChineseCardModes[CardModes.HAMLET_BUY] = "棄卡換取購買";
TChineseCardModes[CardModes.HAMLET_BOTH] = "兩個";
TChineseCardModes[CardModes.GOVERNOR_CARDS] = "+3 卡片";
TChineseCardModes[CardModes.GOVERNOR_TREASURE] = "獲得 黃金";
TChineseCardModes[CardModes.GOVERNOR_REMODEL] = "重建";
TChineseCardModes[CardModes.BLESSED_VILLAGE_NOW] = "現在獲得";
TChineseCardModes[CardModes.BLESSED_VILLAGE_LATER] = "下一回合";
TChineseCardModes[CardModes.PIXIE_TRASH] = "移除";
TChineseCardModes[CardModes.PIXIE_KEEP] = "保留";
TChineseCardModes[CardModes.SACRED_GROVE_RECEIVE] = "接收他";
TChineseCardModes[CardModes.SACRED_GROVE_NO] = "不了，謝謝";
TChineseCardModes[CardModes.ZOMBIE_SPY_DISCARD] = "棄卡";
TChineseCardModes[CardModes.ZOMBIE_SPY_TOPDECK] = "放置牌頂";
TChineseCardModes[CardModes.START_GAME] = "開始遊戲";
TChineseCardModes[CardModes.PEARL_DIVER_TOPDECK] = TChinesePhrases[Phrases.TOP];
TChineseCardModes[CardModes.PEARL_DIVER_BOTTOMDECK] = TChinesePhrases[Phrases.BOTTOM];
TChineseCardModes[CardModes.SCEPTER_FAIL_ACTION] = "選擇重做行動";
TChineseCardModes[CardModes.SCEPTER_ADD_COINS] = "+2 預算";
TChineseCardModes[CardModes.TREASURER_FAIL] = "不做事";
TChineseCardModes[CardModes.TREASURER_KEY] = "拿取金鑰";
TChineseCardModes[CardModes.BORDER_GUARD_LANTERN] = "燈籠";
TChineseCardModes[CardModes.BORDER_GUARD_HORN] = "號角";
TChineseCardModes[CardModes.BORDER_GUARD_FAIL] = "不做事";
TChineseCardModes[CardModes.PAGEANT_YES] = "+1 存款";
TChineseCardModes[CardModes.PAGEANT_NO] = "不了";
TChineseCardModes[CardModes.SINISTER_PLOT_ADD] = "累積指示物";
TChineseCardModes[CardModes.SINISTER_PLOT_DRAW] = "抽卡";
TChineseCardModes[CardModes.PLAY] = "打出行動";
TChineseCardModes[CardModes.DONT_PLAY] = "不打出";
TChineseCardModes[CardModes.VILLAGE_GREEN_NOW] = "現在發動";
TChineseCardModes[CardModes.VILLAGE_GREEN_LATER] = "下次再來";
TChineseCardModes[CardModes.BARGE_NOW] = "現在發動";
TChineseCardModes[CardModes.BARGE_LATER] = "下次再來";
TChineseCardModes[CardModes.SCRAP_CARD] = "+1 卡片";
TChineseCardModes[CardModes.SCRAP_ACTION] = "+1 行動";
TChineseCardModes[CardModes.SCRAP_BUY] = "+1 購買";
TChineseCardModes[CardModes.SCRAP_COIN] = "+1 預算";
TChineseCardModes[CardModes.SCRAP_SILVER] = "獲得一張銀幣";
TChineseCardModes[CardModes.SCRAP_HORSE] = "獲得一張馬";
TChineseCardModes[CardModes.MODE_OF_THE_BUTTERFLY] = "使用蝴蝶";
TChineseCardModes[CardModes.MODE_OF_THE_CAMEL] = "使用駱駝";
TChineseCardModes[CardModes.MODE_OF_THE_CHAMELEON] = "使用變色龍";
TChineseCardModes[CardModes.MODE_OF_THE_FROG] = "使用青蛙";
TChineseCardModes[CardModes.MODE_OF_THE_GOAT] = "使用山羊";
TChineseCardModes[CardModes.MODE_OF_THE_HORSE] = "使用馬";
TChineseCardModes[CardModes.MODE_OF_THE_MOLE] = "使用鼴鼠";
TChineseCardModes[CardModes.MODE_OF_THE_MONKEY] = "使用猴子";
TChineseCardModes[CardModes.MODE_OF_THE_MOUSE] = "使用倉鼠";
TChineseCardModes[CardModes.MODE_OF_THE_MULE] = "使用驢子";
TChineseCardModes[CardModes.MODE_OF_THE_OTTER] = "使用水獺";
TChineseCardModes[CardModes.MODE_OF_THE_OWL] = "使用貓頭鷹";
TChineseCardModes[CardModes.MODE_OF_THE_OX] = "使用公牛";
TChineseCardModes[CardModes.MODE_OF_THE_PIG] = "使用豬豬";
TChineseCardModes[CardModes.MODE_OF_THE_RAT] = "使用田鼠";
TChineseCardModes[CardModes.MODE_OF_THE_SEAL] = "使用海豹";
TChineseCardModes[CardModes.MODE_OF_THE_SHEEP] = "使用綿羊";
TChineseCardModes[CardModes.MODE_OF_THE_SQUIRREL] = "使用松鼠";
TChineseCardModes[CardModes.MODE_OF_THE_TURTLE] = "使用烏龜";
TChineseCardModes[CardModes.MODE_OF_THE_WORM] = "使用蠕蟲";
TChineseCardModes[CardModes.BUTTERFLY_RETURN_IT] = "歸還它";
TChineseCardModes[CardModes.BUTTERFLY_DO_NOTHING] = "不發動效果";
TChineseCardModes[CardModes.WAYFARER_SILVER] = "獲得銀幣";
TChineseCardModes[CardModes.DESPERATION_CURSE] = "獲得詛咒";
TChineseCardModes[CardModes.CRYSTAL_BALL_TRASH] = "移除";
TChineseCardModes[CardModes.CRYSTAL_BALL_DISCARD] = "棄掉";
TChineseCardModes[CardModes.CRYSTAL_BALL_PLAY] = "打出";
TChineseCardModes[CardModes.CRYSTAL_BALL_TOPDECK] = "放回牌庫頂";
TChineseCardModes[CardModes.TOWN_VILLAGE] = "+1 卡 +2 行動";
TChineseCardModes[CardModes.TOWN_COINS_BUY] = "+1 購買 +2 預算";
TChineseCardModes[CardModes.MODIFY_CANTRIP] = "+1 卡片 +1 行動";
TChineseCardModes[CardModes.MODIFY_GAIN] = "獲得一張卡";
TChineseCardModes[CardModes.DESERT_GUIDES_KEEP] = "保留手牌";
TChineseCardModes[CardModes.DESERT_GUIDES_NEW_HAND] = "棄掉後重抽五張牌";
TChineseCardModes[CardModes.BAUBLE_BUY] = "+1 購買";
TChineseCardModes[CardModes.BAUBLE_COIN] = "+1 預算";
TChineseCardModes[CardModes.BAUBLE_FAVOR] = "+1 支持";
TChineseCardModes[CardModes.BAUBLE_TOPDECK] = "放牌庫頂";
TChineseCardModes[CardModes.BROKER_CARDS] = "+抽卡";
TChineseCardModes[CardModes.BROKER_ACTIONS] = "+行動";
TChineseCardModes[CardModes.BROKER_COINS] = "+預算";
TChineseCardModes[CardModes.BROKER_FAVORS] = "+支持";
TChineseCardModes[CardModes.INNKEEPER_CARD] = "+1 卡片";
TChineseCardModes[CardModes.INNKEEPER_3] = "+3 卡片, 棄掉 3張";
TChineseCardModes[CardModes.INNKEEPER_5_6] = "+5 卡片, 棄掉 6張";
TChineseCardModes[CardModes.CAPITAL_CITY_DISCARD] = "棄掉兩張卡片換取 +$2";
TChineseCardModes[CardModes.CAPITAL_CITY_PAY] = "花費 $2 來換取 +2 卡片";
TChineseCardModes[CardModes.SPECIALIST_PLAY_AGAIN] = "再發動一次效果";
TChineseCardModes[CardModes.SPECIALIST_GAIN_COPY] = "獲得一張同名卡";
TChineseCardModes[CardModes.HILL_FORT_TO_HAND] = "加入手中";
TChineseCardModes[CardModes.HILL_FORT_CANTRIP] = "+1 卡片 +1 行動";
TChineseCardModes[CardModes.STRONGHOLD_COINS] = "+3 預算";
TChineseCardModes[CardModes.STRONGHOLD_CARDS] = "下回合: +3 卡片";
TChineseCardModes[CardModes.TOWN_CRIER_COINS] = "+2 預算";
TChineseCardModes[CardModes.TOWN_CRIER_SILVER] = "獲得一張銀幣";
TChineseCardModes[CardModes.TOWN_CRIER_CANTRIP] = "+1 卡片 +1 行動";
TChineseCardModes[CardModes.BLACKSMITH_OWL] = "抽到手牌 6張";
TChineseCardModes[CardModes.BLACKSMITH_OTTER] = "+2 卡片";
TChineseCardModes[CardModes.BLACKSMITH_PIG] = "+1 卡片 +1 行動";
TChineseCardModes[CardModes.LURKER_TRASH] = "移除供應區的卡";
TChineseCardModes[CardModes.LURKER_GAIN] = "獲得一張被移除的卡";
TChineseCardModes[CardModes.GRAVEROBBER_GAIN] = "獲得並放置牌庫頂";
TChineseCardModes[CardModes.GRAVEROBBER_TRASH] = "移除一張手牌";
TChineseCardModes[CardModes.TREASURER_TRASH] = "移除";
TChineseCardModes[CardModes.TREASURER_GAIN] = "獲得一張被移除的卡";
TChineseCardModes[CardModes.INVESTMENT_COIN] = "+$1";
TChineseCardModes[CardModes.INVESTMENT_VP] = "移除來換取 +VP";
TChineseCardModes[CardModes.WEAVER_SILVERS] = "2 張銀幣";
TChineseCardModes[CardModes.COURSER_CARDS_ACTIONS] = "抽卡和加行動";
TChineseCardModes[CardModes.COURSER_CARDS] = "+2 卡片";
TChineseCardModes[CardModes.COURSER_ACTIONS] = "+2 行動";
TChineseCardModes[CardModes.COURSER_COINS] = "+2 預算";
TChineseCardModes[CardModes.COURSER_SILVERS] = "+4 銀幣";
TChineseCardModes[CardModes.SICKNESS_FAIL_DISCARD] = "無法棄卡";
TChineseCardModes[CardModes.SICKNESS_CURSE] = "獲得詛咒到牌庫頂";
TChineseCardModes[CardModes.SICKNESS_FAIL_CURSE] = "無法獲得詛咒";
TChineseCardModes[CardModes.SICKNESS_DISCARD] = "棄掉三張手牌";
TChineseCardModes[CardModes.KITSUNE_ACTIONS] = "+2 行動";
TChineseCardModes[CardModes.KITSUNE_COINS] = "+2 預算";
TChineseCardModes[CardModes.KITSUNE_CURSE] = "贈送詛咒";
TChineseCardModes[CardModes.KITSUNE_SILVER] = "獲得銀幣";

TChineseCardModes[ExtraTurnTypes.MISSION] = TChineseCardNames[CardNames.MISSION].singular;
TChineseCardModes[ExtraTurnTypes.OUTPOST] = TChineseCardNames[CardNames.OUTPOST].singular;
TChineseCardModes[ExtraTurnTypes.POSSESSION] = TChineseCardNames[CardNames.POSSESSION].singular;
TChineseCardModes[ExtraTurnTypes.FLEET] = TChineseCardNames[CardNames.FLEET].singular;
TChineseCardModes[ExtraTurnTypes.SEIZE_THE_DAY] = TChineseCardNames[CardNames.SEIZE_THE_DAY].singular;
TChineseCardModes[ExtraTurnTypes.ISLAND_FOLK] = TChineseCardNames[CardNames.ISLAND_FOLK].singular;
TChineseCardModes[ExtraTurnTypes.VOYAGE] = TChineseCardNames[CardNames.VOYAGE].singular;
TChineseCardModes[ExtraTurnTypes.JOURNEY] = TChineseCardNames[CardNames.JOURNEY].singular;

TChineseCardModes[CardModes.WEAVER_SILVERS] = "2張銀幣";
TChineseCardModes[CardModes.UNUSED_CARDMODE_7] = "unused";
TChineseCardModes[CardModes.UNUSED_CARDMODE_8] = "unused";
TChineseCardModes[CardModes.UNUSED_CARDMODE_9] = "unused";
TChineseCardModes[CardModes.UNUSED_CARDMODE_10] = "unused";
TChineseCardModes[CardModes.UNUSED_CARDMODE_11] = "unused";
TChineseCardModes[CardModes.CABIN_BOY_COINS] = "+2 預算";
TChineseCardModes[CardModes.CABIN_BOY_GAIN] = "移除換取持續卡";
TChineseCardModes[CardModes.GONDOLA_NOW] = "+2 預算";
TChineseCardModes[CardModes.GONDOLA_LATER] = "下回合";
TChineseCardModes[CardModes.QUARTERMASTER_GAIN] = "獲得一張卡";
TChineseCardModes[CardModes.QUARTERMASTER_TAKE] = "拿一張卡";
TChineseCardModes[CardModes.AMPHORA_NOW] = "+1 購買, +3 預算";
TChineseCardModes[CardModes.AMPHORA_LATER] = "晚點";
TChineseCardModes[CardModes.SPELL_SCROLL_PLAY] = "發動效果";
TChineseCardModes[CardModes.SPELL_SCROLL_REFUSE] = "不發動";