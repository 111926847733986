"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var setTooltip = function setTooltip($element, cssSelector) {
    var boundingBox = $element[0].querySelector(cssSelector).getBoundingClientRect();
    var tooltipBox = document.getElementById("tooltip").getBoundingClientRect();
    var iconOffset = $element[0].querySelector(cssSelector).offsetWidth / 2;
    var tooltipOffset = document.getElementById("tooltip").offsetWidth / 2;
    var top = boundingBox.top - tooltipBox.height - 5;
    document.getElementById("tooltip").style.top = top + "px";
    var left = boundingBox.left + iconOffset - tooltipOffset;
    if (left < 10) left = 10;
    document.getElementById("tooltip").style.left = left + "px";
};

var showTooltip = function showTooltip($element, tooltip) {
    document.getElementById("tooltip").className = "table-tooltip";
    document.getElementById("tooltip").innerHTML = tooltip.getValue();
    setTooltip($element, tooltip.cssSelector);
};

var hideTooltip = function hideTooltip() {
    document.getElementById("tooltip").className = "table-tooltip hidden";
};

var Tooltip = function () {
    function Tooltip(cssSelector, textValue, textFunction) {
        _classCallCheck(this, Tooltip);

        if (textValue === undefined && textFunction === undefined) throw new Error("can't provide tooltip text value");
        this.cssSelector = cssSelector;
        this.textValue = textValue;
        this.textFunction = textFunction;
    }

    _createClass(Tooltip, [{
        key: "getValue",
        value: function getValue() {
            return this.textValue === undefined ? this.textFunction() : this.textValue;
        }
    }]);

    return Tooltip;
}();