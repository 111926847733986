"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CardObject = function () {
    function CardObject(state, id, cardName, pileName) {
        _classCallCheck(this, CardObject);

        this.state = state;
        this.id = id;
        this.cardName = cardName;
        this.pileName = pileName;

        this.zone = state.nowhere;
        this.counters = [];
        this.cardStacks = [];
        this.question = null;
        this.answer = null;
        this.associations = [];
        this.cardStatus = CardStatus.default(this.state.game, this.id);

        this.serverImage = this.cardName;
        this.addedTypes = [];
        this.cost = cardName.cost;
    }

    _createClass(CardObject, [{
        key: "revertImage",
        value: function revertImage() {
            this.image = this.serverImage;
        }
    }, {
        key: "associateAnswer",
        value: function associateAnswer(question, answer) {
            this.question = question;
            this.answer = answer;
            this.onAnswerChange(question);
        }
    }, {
        key: "onAnswerChange",
        value: function onAnswerChange(question) {
            this.cardStacks.forEach(function (s) {
                return s.updateAnswers(question);
            });
        }
    }, {
        key: "addToStack",
        value: function addToStack(stack) {
            this.cardStacks.push(stack);
        }
    }, {
        key: "stacksOnTop",
        value: function stacksOnTop() {
            var _this = this;

            return this.cardStacks.filter(function (s) {
                return s.topCard === _this;
            });
        }
    }, {
        key: "isIdenticalTo",
        value: function isIdenticalTo(other) {
            // bad kludge for garrison
            var NEVER_STACK_CARDNAMES = [CardNames.GARRISON];
            if (this.image !== other.image) return false;
            if (this.cardStatus === other.cardStatus) return true;
            return this.cardStatus.staysOut === other.cardStatus.staysOut && this.cardStatus.setAsideCardLists.length === 0 && other.cardStatus.setAsideCardLists.length === 0 && !NEVER_STACK_CARDNAMES.includes(this.cardName) && !NEVER_STACK_CARDNAMES.includes(other.cardName);
        }
    }, {
        key: "isCompatibleWith",
        value: function isCompatibleWith(other) {
            return other.cardName === this.cardName && other.cardStatus.isCompatible(this.cardStatus);
        }
    }, {
        key: "removeFromStack",
        value: function removeFromStack(stack) {
            this.cardStacks.splice(this.cardStacks.indexOf(stack), 1);
        }
    }, {
        key: "updateTypes",
        value: function updateTypes(_ref) {
            var _this2 = this;

            var addedTypes = _ref.addedTypes,
                removedTypes = _ref.removedTypes;

            addedTypes.forEach(function (t) {
                if (!_this2.addedTypes.includes(t)) _this2.addedTypes.push(t);
            });
            removedTypes.forEach(function (t) {
                var index = _this2.addedTypes.indexOf(t);
                if (index > -1) _this2.addedTypes.splice(index, 1);
            });
            this.stacksOnTop().forEach(function (s) {
                return s.typesChanged();
            });
        }
    }, {
        key: "checkIfOrphaned",
        value: function checkIfOrphaned() {
            if (this.cardName.isLandscape()) {
                if (this.question !== null && !(this.question instanceof NameQuestion) && !this.question.type.dontOrphan) {
                    if (!this.cardStacks.some(function (s) {
                        return s.location.visible;
                    })) {
                        var targetStack = this.state.orphanage.addCard(this);
                        targetStack.addCard(this);
                        targetStack.removeFilter("intangible");
                    }
                }
            }
        }
    }, {
        key: "zone",
        set: function set(zone) {
            if (!isUndefined(this.privateZone) && this.privateZone !== this.state.nowhere) console.error("Tried to locate a card with a zone");
            this.privateZone = zone;
        },
        get: function get() {
            console.error("why are you using this?");
            return this.privateZone;
        }
    }, {
        key: "cardStatus",
        set: function set(cardStatus) {
            this.privateCardStatus = cardStatus;
            this.cardStacks.forEach(function (s) {
                return s.cardStatusChanged();
            });
        },
        get: function get() {
            return this.privateCardStatus;
        }
    }, {
        key: "serverImage",
        set: function set(image) {
            this.privateServerImage = image;
            this.image = image;
        },
        get: function get() {
            return this.privateServerImage;
        }
    }, {
        key: "image",
        set: function set(image) {
            this.privateImage = image;
            this.stacksOnTop().forEach(function (s) {
                return s.imageChanged();
            });
        },
        get: function get() {
            return this.privateImage;
        }
    }, {
        key: "types",
        get: function get() {
            return this.cardName.types.concat(this.addedTypes);
        }
    }, {
        key: "cost",
        set: function set(cost) {
            this.privateCost = cost;
            this.stacksOnTop().forEach(function (s) {
                return s.redrawCoins();
            });
        },
        get: function get() {
            return this.privateCost;
        }
    }, {
        key: "isSelected",
        get: function get() {
            if (this.question === null || this.question.type.isOrder) return false;
            return this.question.accumulatedAnswers.includes(this.answer);
        }
    }]);

    return CardObject;
}();