"use strict";

var DutchCardNames = {};

DutchCardNames[CardNames.BACK] = new TranslatedCardName("card", "cards", "a card");
DutchCardNames[CardNames.CURSE] = new TranslatedCardName("Curse", "Curses", "a Curse");
DutchCardNames[CardNames.COPPER] = new TranslatedCardName("Copper", "Coppers", "a Copper");
DutchCardNames[CardNames.SILVER] = new TranslatedCardName("Silver", "Silvers", "a Silver");
DutchCardNames[CardNames.GOLD] = new TranslatedCardName("Gold", "Golds", "a Gold");
DutchCardNames[CardNames.ESTATE] = new TranslatedCardName("Estate", "Estates", "an Estate");
DutchCardNames[CardNames.DUCHY] = new TranslatedCardName("Duchy", "Duchies", "a Duchy");
DutchCardNames[CardNames.PROVINCE] = new TranslatedCardName("Province", "Provinces", "a Province");
DutchCardNames[CardNames.ARTISAN] = new TranslatedCardName("Artisan", "Artisans", "an Artisan");
DutchCardNames[CardNames.BANDIT] = new TranslatedCardName("Bandit", "Bandits", "a Bandit");
DutchCardNames[CardNames.BUREAUCRAT] = new TranslatedCardName("Bureaucrat", "Bureaucrats", "a Bureaucrat");
DutchCardNames[CardNames.CELLAR] = new TranslatedCardName("Cellar", "Cellars", "a Cellar");
DutchCardNames[CardNames.CHAPEL] = new TranslatedCardName("Chapel", "Chapels", "a Chapel");
DutchCardNames[CardNames.COUNCIL_ROOM] = new TranslatedCardName("Council Room", "Council Rooms", "a Council Room");
DutchCardNames[CardNames.FESTIVAL] = new TranslatedCardName("Festival", "Festivals", "a Festival");
DutchCardNames[CardNames.GARDENS] = new TranslatedCardName("Gardens", "Gardens", "a Gardens");
DutchCardNames[CardNames.HARBINGER] = new TranslatedCardName("Harbinger", "Harbingers", "a Harbinger");
DutchCardNames[CardNames.LABORATORY] = new TranslatedCardName("Laboratory", "Laboratories", "a Laboratory");
DutchCardNames[CardNames.LIBRARY] = new TranslatedCardName("Library", "Libraries", "a Library");
DutchCardNames[CardNames.MARKET] = new TranslatedCardName("Market", "Markets", "a Market");
DutchCardNames[CardNames.MERCHANT] = new TranslatedCardName("Merchant", "Merchants", "a Merchant");
DutchCardNames[CardNames.MILITIA] = new TranslatedCardName("Militia", "Militias", "a Militia");
DutchCardNames[CardNames.MINE] = new TranslatedCardName("Mine", "Mines", "a Mine");
DutchCardNames[CardNames.MOAT] = new TranslatedCardName("Moat", "Moats", "a Moat");
DutchCardNames[CardNames.MONEYLENDER] = new TranslatedCardName("Moneylender", "Moneylenders", "a Moneylender");
DutchCardNames[CardNames.POACHER] = new TranslatedCardName("Poacher", "Poachers", "a Poacher");
DutchCardNames[CardNames.REMODEL] = new TranslatedCardName("Remodel", "Remodels", "a Remodel");
DutchCardNames[CardNames.SENTRY] = new TranslatedCardName("Sentry", "Sentries", "a Sentry");
DutchCardNames[CardNames.SMITHY] = new TranslatedCardName("Smithy", "Smithies", "a Smithy");
DutchCardNames[CardNames.THRONE_ROOM] = new TranslatedCardName("Throne Room", "Throne Rooms", "a Throne Room");
DutchCardNames[CardNames.VASSAL] = new TranslatedCardName("Vassal", "Vassals", "a Vassal");
DutchCardNames[CardNames.VILLAGE] = new TranslatedCardName("Village", "Villages", "a Village");
DutchCardNames[CardNames.WITCH] = new TranslatedCardName("Witch", "Witches", "a Witch");
DutchCardNames[CardNames.WORKSHOP] = new TranslatedCardName("Workshop", "Workshops", "a Workshop");

DutchCardNames[CardNames.COURTYARD] = new TranslatedCardName("Courtyard", "Courtyards", "a Courtyard");
DutchCardNames[CardNames.CONSPIRATOR] = new TranslatedCardName("Conspirator", "Conspirators", "a Conspirator");
DutchCardNames[CardNames.COURTIER] = new TranslatedCardName("Courtier", "Courtiers", "a Courtier");
DutchCardNames[CardNames.BARON] = new TranslatedCardName("Baron", "Barons", "a Baron");
DutchCardNames[CardNames.BRIDGE] = new TranslatedCardName("Bridge", "Bridges", "a Bridge");
DutchCardNames[CardNames.DIPLOMAT] = new TranslatedCardName("Diplomat", "Diplomats", "a Diplomat");
DutchCardNames[CardNames.DUKE] = new TranslatedCardName("Duke", "Dukes", "a Duke");
DutchCardNames[CardNames.HAREM] = new TranslatedCardName("Harem", "Harems", "a Harem");
DutchCardNames[CardNames.NOBLES] = new TranslatedCardName("Nobles", "Nobles", "a Nobles");
DutchCardNames[CardNames.IRONWORKS] = new TranslatedCardName("Ironworks", "Ironworks", "an Ironworks");
DutchCardNames[CardNames.LURKER] = new TranslatedCardName("Lurker", "Lurkers", "a Lurker");
DutchCardNames[CardNames.MASQUERADE] = new TranslatedCardName("Masquerade", "Masquerades", "a Masquerade");
DutchCardNames[CardNames.MILL] = new TranslatedCardName("Mill", "Mills", "a Mill");
DutchCardNames[CardNames.MINING_VILLAGE] = new TranslatedCardName("Mining Village", "Mining Villages", "a Mining Village");
DutchCardNames[CardNames.MINION] = new TranslatedCardName("Minion", "Minions", "a Minion");
DutchCardNames[CardNames.PATROL] = new TranslatedCardName("Patrol", "Patrols", "a Patrol");
DutchCardNames[CardNames.PAWN] = new TranslatedCardName("Pawn", "Pawns", "a Pawn");
DutchCardNames[CardNames.REPLACE] = new TranslatedCardName("Replace", "Replaces", "a Replace");
DutchCardNames[CardNames.SECRET_PASSAGE] = new TranslatedCardName("Secret Passage", "Secret Passages", "a Secret Passage");
DutchCardNames[CardNames.SHANTY_TOWN] = new TranslatedCardName("Shanty Town", "Shanty Towns", "a Shanty Town");
DutchCardNames[CardNames.STEWARD] = new TranslatedCardName("Steward", "Stewards", "a Steward");
DutchCardNames[CardNames.SWINDLER] = new TranslatedCardName("Swindler", "Swindlers", "a Swindler");
DutchCardNames[CardNames.TORTURER] = new TranslatedCardName("Torturer", "Torturers", "a Torturer");
DutchCardNames[CardNames.TRADING_POST] = new TranslatedCardName("Trading Post", "Trading Posts", "a Trading Post");
DutchCardNames[CardNames.UPGRADE] = new TranslatedCardName("Upgrade", "Upgrades", "an Upgrade");
DutchCardNames[CardNames.WISHING_WELL] = new TranslatedCardName("Wishing Well", "Wishing Wells", "a Wishing Well");

DutchCardNames[CardNames.AMBASSADOR] = new TranslatedCardName("Ambassador", "Ambassadors", "an Ambassador");
DutchCardNames[CardNames.BAZAAR] = new TranslatedCardName("Bazaar", "Bazaars", "a Bazaar");
DutchCardNames[CardNames.CARAVAN] = new TranslatedCardName("Caravan", "Caravans", "a Caravan");
DutchCardNames[CardNames.CUTPURSE] = new TranslatedCardName("Cutpurse", "Cutpurses", "a Cutpurse");
DutchCardNames[CardNames.EMBARGO] = new TranslatedCardName("Embargo", "Embargos", "an Embargo");
DutchCardNames[CardNames.EXPLORER] = new TranslatedCardName("Explorer", "Explorers", "an Explorer");
DutchCardNames[CardNames.FISHING_VILLAGE] = new TranslatedCardName("Fishing Village", "Fishing Villages", "a Fishing Village");
DutchCardNames[CardNames.GHOST_SHIP] = new TranslatedCardName("Ghost Ship", "Ghost Ships", "a Ghost Ship");
DutchCardNames[CardNames.HAVEN] = new TranslatedCardName("Haven", "Havens", "a Haven");
DutchCardNames[CardNames.ISLAND] = new TranslatedCardName("Island", "Islands", "an Island");
DutchCardNames[CardNames.LIGHTHOUSE] = new TranslatedCardName("Lighthouse", "Lighthouses", "a Lighthouse");
DutchCardNames[CardNames.LOOKOUT] = new TranslatedCardName("Lookout", "Lookouts", "a Lookout");
DutchCardNames[CardNames.MERCHANT_SHIP] = new TranslatedCardName("Merchant Ship", "Merchant Ships", "a Merchant Ship");
DutchCardNames[CardNames.NATIVE_VILLAGE] = new TranslatedCardName("Native Village", "Native Villages", "a Native Village");
DutchCardNames[CardNames.NAVIGATOR] = new TranslatedCardName("Navigator", "Navigators", "a Navigator");
DutchCardNames[CardNames.OUTPOST] = new TranslatedCardName("Outpost", "Outposts", "an Outpost");
DutchCardNames[CardNames.PEARL_DIVER] = new TranslatedCardName("Pearl Diver", "Pearl Divers", "a Pearl Diver");
DutchCardNames[CardNames.PIRATE_SHIP] = new TranslatedCardName("Pirate Ship", "Pirate Ships", "a Pirate Ship");
DutchCardNames[CardNames.SALVAGER] = new TranslatedCardName("Salvager", "Salvagers", "a Salvager");
DutchCardNames[CardNames.SEA_HAG] = new TranslatedCardName("Sea Hag", "Sea Hags", "a Sea Hag");
DutchCardNames[CardNames.SMUGGLERS] = new TranslatedCardName("Smugglers", "Smugglers", "a Smugglers");
DutchCardNames[CardNames.TACTICIAN] = new TranslatedCardName("Tactician", "Tacticians", "a Tactician");
DutchCardNames[CardNames.TREASURE_MAP] = new TranslatedCardName("Treasure Map", "Treasure Maps", "a Treasure Map");
DutchCardNames[CardNames.TREASURY] = new TranslatedCardName("Treasury", "Treasuries", "a Treasury");
DutchCardNames[CardNames.WAREHOUSE] = new TranslatedCardName("Warehouse", "Warehouses", "a Warehouse");
DutchCardNames[CardNames.WHARF] = new TranslatedCardName("Wharf", "Wharves", "a Wharf");

DutchCardNames[CardNames.ALCHEMIST] = new TranslatedCardName("Alchemist", "Alchemists", "an Alchemist");
DutchCardNames[CardNames.APOTHECARY] = new TranslatedCardName("Apothecary", "Apothecaries", "an Apothecary");
DutchCardNames[CardNames.APPRENTICE] = new TranslatedCardName("Apprentice", "Apprentices", "an Apprentice");
DutchCardNames[CardNames.FAMILIAR] = new TranslatedCardName("Familiar", "Familiars", "a Familiar");
DutchCardNames[CardNames.GOLEM] = new TranslatedCardName("Golem", "Golems", "a Golem");
DutchCardNames[CardNames.HERBALIST] = new TranslatedCardName("Herbalist", "Herbalists", "a Herbalist");
DutchCardNames[CardNames.PHILOSOPHERS_STONE] = new TranslatedCardName("Philosopher's Stone", "Philosopher's Stones", "a Philosopher's Stone");
DutchCardNames[CardNames.POSSESSION] = new TranslatedCardName("Possession", "Possessions", "a Possession");
DutchCardNames[CardNames.POTION] = new TranslatedCardName("Potion", "Potions", "a Potion");
DutchCardNames[CardNames.SCRYING_POOL] = new TranslatedCardName("Scrying Pool", "Scrying Pools", "a Scrying Pool");
DutchCardNames[CardNames.TRANSMUTE] = new TranslatedCardName("Transmute", "Transmutes", "a Transmute");
DutchCardNames[CardNames.UNIVERSITY] = new TranslatedCardName("University", "Universities", "a University");
DutchCardNames[CardNames.VINEYARD] = new TranslatedCardName("Vineyard", "Vineyards", "a Vineyard");

DutchCardNames[CardNames.BANK] = new TranslatedCardName("Bank", "Banks", "a Bank");
DutchCardNames[CardNames.BISHOP] = new TranslatedCardName("Bishop", "Bishops", "a Bishop");
DutchCardNames[CardNames.COLONY] = new TranslatedCardName("Colony", "Colonies", "a Colony");
DutchCardNames[CardNames.CONTRABAND] = new TranslatedCardName("Contraband", "Contrabands", "a Contraband");
DutchCardNames[CardNames.COUNTING_HOUSE] = new TranslatedCardName("Counting House", "Counting Houses", "a Counting House");
DutchCardNames[CardNames.CITY] = new TranslatedCardName("City", "Cities", "a City");
DutchCardNames[CardNames.EXPAND] = new TranslatedCardName("Expand", "Expands", "an Expand");
DutchCardNames[CardNames.FORGE] = new TranslatedCardName("Forge", "Forges", "a Forge");
DutchCardNames[CardNames.GRAND_MARKET] = new TranslatedCardName("Grand Market", "Grand Markets", "a Grand Market");
DutchCardNames[CardNames.GOONS] = new TranslatedCardName("Goons", "Goons", "a Goons");
DutchCardNames[CardNames.HOARD] = new TranslatedCardName("Hoard", "Hoards", "a Hoard");
DutchCardNames[CardNames.KINGS_COURT] = new TranslatedCardName("King's Court", "King's Courts", "a King's Court");
DutchCardNames[CardNames.LOAN] = new TranslatedCardName("Loan", "Loans", "a Loan");
DutchCardNames[CardNames.MINT] = new TranslatedCardName("Mint", "Mints", "a Mint");
DutchCardNames[CardNames.MONUMENT] = new TranslatedCardName("Monument", "Monuments", "a Monument");
DutchCardNames[CardNames.MOUNTEBANK] = new TranslatedCardName("Mountebank", "Mountebanks", "a Mountebank");
DutchCardNames[CardNames.PEDDLER] = new TranslatedCardName("Peddler", "Peddlers", "a Peddler");
DutchCardNames[CardNames.PLATINUM] = new TranslatedCardName("Platinum", "Platina", "a Platinum");
DutchCardNames[CardNames.QUARRY] = new TranslatedCardName("Quarry", "Quarries", "a Quarry");
DutchCardNames[CardNames.RABBLE] = new TranslatedCardName("Rabble", "Rabbles", "a Rabble");
DutchCardNames[CardNames.ROYAL_SEAL] = new TranslatedCardName("Royal Seal", "Royal Seals", "a Royal Seal");
DutchCardNames[CardNames.TALISMAN] = new TranslatedCardName("Talisman", "Talismans", "a Talisman");
DutchCardNames[CardNames.TRADE_ROUTE] = new TranslatedCardName("Trade Route", "Trade Routes", "a Trade Route");
DutchCardNames[CardNames.VAULT] = new TranslatedCardName("Vault", "Vaults", "a Vault");
DutchCardNames[CardNames.VENTURE] = new TranslatedCardName("Venture", "Ventures", "a Venture");
DutchCardNames[CardNames.WATCHTOWER] = new TranslatedCardName("Watchtower", "Watchtowers", "a Watchtower");
DutchCardNames[CardNames.WORKERS_VILLAGE] = new TranslatedCardName("Worker's Village", "Worker's Villages", "a Worker's Village");

DutchCardNames[CardNames.PRIZE_PILE] = new TranslatedCardName("Prize Pile", "Prize Piles", "a Prize Pile");
DutchCardNames[CardNames.BAG_OF_GOLD] = new TranslatedCardName("Bag of Gold", "Bags of Gold", "a Bag of Gold");
DutchCardNames[CardNames.DIADEM] = new TranslatedCardName("Diadem", "Diadems", "a Diadem");
DutchCardNames[CardNames.FAIRGROUNDS] = new TranslatedCardName("Fairgrounds", "Fairgrounds", "a Fairgrounds");
DutchCardNames[CardNames.FARMING_VILLAGE] = new TranslatedCardName("Farming Village", "Farming Villages", "a Farming Village");
DutchCardNames[CardNames.FOLLOWERS] = new TranslatedCardName("Followers", "Followers", "a Followers");
DutchCardNames[CardNames.FORTUNE_TELLER] = new TranslatedCardName("Fortune Teller", "Fortune Tellers", "a Fortune Teller");
DutchCardNames[CardNames.HAMLET] = new TranslatedCardName("Hamlet", "Hamlets", "a Hamlet");
DutchCardNames[CardNames.HARVEST] = new TranslatedCardName("Harvest", "Harvests", "a Harvest");
DutchCardNames[CardNames.HORSE_TRADERS] = new TranslatedCardName("Horse Traders", "Horse Traders", "a Horse Traders");
DutchCardNames[CardNames.HORN_OF_PLENTY] = new TranslatedCardName("Horn of Plenty", "Horns of Plenty", "a Horn of Plenty");
DutchCardNames[CardNames.HUNTING_PARTY] = new TranslatedCardName("Hunting Party", "Hunting Parties", "a Hunting Party");
DutchCardNames[CardNames.JESTER] = new TranslatedCardName("Jester", "Jesters", "a Jester");
DutchCardNames[CardNames.MENAGERIE] = new TranslatedCardName("Menagerie", "Menageries", "a Menagerie");
DutchCardNames[CardNames.PRINCESS] = new TranslatedCardName("Princess", "Princesses", "a Princess");
DutchCardNames[CardNames.REMAKE] = new TranslatedCardName("Remake", "Remakes", "a Remake");
DutchCardNames[CardNames.TOURNAMENT] = new TranslatedCardName("Tournament", "Tournaments", "a Tournament");
DutchCardNames[CardNames.TRUSTY_STEED] = new TranslatedCardName("Trusty Steed", "Trusty Steeds", "a Trusty Steed");
DutchCardNames[CardNames.YOUNG_WITCH] = new TranslatedCardName("Young Witch", "Young Witches", "a Young Witch");

DutchCardNames[CardNames.BORDER_VILLAGE] = new TranslatedCardName("Border Village", "Border Villages", "a Border Village");
DutchCardNames[CardNames.CACHE] = new TranslatedCardName("Cache", "Caches", "a Cache");
DutchCardNames[CardNames.CARTOGRAPHER] = new TranslatedCardName("Cartographer", "Cartographers", "a Cartographer");
DutchCardNames[CardNames.CROSSROADS] = new TranslatedCardName("Crossroads", "Crossroads", "a Crossroads");
DutchCardNames[CardNames.DEVELOP] = new TranslatedCardName("Develop", "Develops", "a Develop");
DutchCardNames[CardNames.DUCHESS] = new TranslatedCardName("Duchess", "Duchesses", "a Duchess");
DutchCardNames[CardNames.EMBASSY] = new TranslatedCardName("Embassy", "Embassies", "an Embassy");
DutchCardNames[CardNames.FARMLAND] = new TranslatedCardName("Farmland", "Farmlands", "a Farmland");
DutchCardNames[CardNames.FOOLS_GOLD] = new TranslatedCardName("Fool's Gold", "Fool's Golds", "a Fool's Gold");
DutchCardNames[CardNames.HAGGLER] = new TranslatedCardName("Haggler", "Hagglers", "a Haggler");
DutchCardNames[CardNames.HIGHWAY] = new TranslatedCardName("Highway", "Highways", "a Highway");
DutchCardNames[CardNames.ILL_GOTTEN_GAINS] = new TranslatedCardName("Ill-Gotten Gains", "Ill-Gotten Gains", "an Ill-Gotten Gains");
DutchCardNames[CardNames.INN] = new TranslatedCardName("Inn", "Inns", "an Inn");
DutchCardNames[CardNames.JACK_OF_ALL_TRADES] = new TranslatedCardName("Jack of All Trades", "Jacks of All Trades", "a Jack of All Trades");
DutchCardNames[CardNames.MANDARIN] = new TranslatedCardName("Mandarin", "Mandarins", "a Mandarin");
DutchCardNames[CardNames.NOBLE_BRIGAND] = new TranslatedCardName("Noble Brigand", "Noble Brigands", "a Noble Brigand");
DutchCardNames[CardNames.NOMAD_CAMP] = new TranslatedCardName("Nomad Camp", "Nomad Camps", "a Nomad Camp");
DutchCardNames[CardNames.OASIS] = new TranslatedCardName("Oasis", "Oases", "an Oasis");
DutchCardNames[CardNames.ORACLE] = new TranslatedCardName("Oracle", "Oracles", "an Oracle");
DutchCardNames[CardNames.MARGRAVE] = new TranslatedCardName("Margrave", "Margraves", "a Margrave");
DutchCardNames[CardNames.SCHEME] = new TranslatedCardName("Scheme", "Schemes", "a Scheme");
DutchCardNames[CardNames.SILK_ROAD] = new TranslatedCardName("Silk Road", "Silk Roads", "a Silk Road");
DutchCardNames[CardNames.SPICE_MERCHANT] = new TranslatedCardName("Spice Merchant", "Spice Merchants", "a Spice Merchant");
DutchCardNames[CardNames.STABLES] = new TranslatedCardName("Stables", "Stables", "a Stables");
DutchCardNames[CardNames.TRADER] = new TranslatedCardName("Trader", "Traders", "a Trader");
DutchCardNames[CardNames.TUNNEL] = new TranslatedCardName("Tunnel", "Tunnels", "a Tunnel");

DutchCardNames[CardNames.RUIN_PILE] = new TranslatedCardName("Ruins", "Ruins", "a Ruins");
DutchCardNames[CardNames.KNIGHTS] = new TranslatedCardName("Knights", "Knights", "a Knights");
DutchCardNames[CardNames.ABANDONED_MINE] = new TranslatedCardName("Abandoned Mine", "Abandoned Mines", "an Abandoned Mine");
DutchCardNames[CardNames.ALTAR] = new TranslatedCardName("Altar", "Altars", "an Altar");
DutchCardNames[CardNames.ARMORY] = new TranslatedCardName("Armory", "Armories", "an Armory");
DutchCardNames[CardNames.BAND_OF_MISFITS] = new TranslatedCardName("Band of Misfits", "Bands of Misfits", "a Band of Misfits");
DutchCardNames[CardNames.BANDIT_CAMP] = new TranslatedCardName("Bandit Camp", "Bandit Camps", "a Bandit Camp");
DutchCardNames[CardNames.BEGGAR] = new TranslatedCardName("Beggar", "Beggars", "a Beggar");
DutchCardNames[CardNames.CATACOMBS] = new TranslatedCardName("Catacombs", "Catacombs", "a Catacombs");
DutchCardNames[CardNames.COUNT] = new TranslatedCardName("Count", "Counts", "a Count");
DutchCardNames[CardNames.COUNTERFEIT] = new TranslatedCardName("Counterfeit", "Counterfeits", "a Counterfeit");
DutchCardNames[CardNames.CULTIST] = new TranslatedCardName("Cultist", "Cultists", "a Cultist");
DutchCardNames[CardNames.DAME_ANNA] = new TranslatedCardName("Dame Anna", "Dame Annas", "a Dame Anna");
DutchCardNames[CardNames.DAME_JOSEPHINE] = new TranslatedCardName("Dame Josephine", "Dame Josephines", "a Dame Josephine");
DutchCardNames[CardNames.DAME_MOLLY] = new TranslatedCardName("Dame Molly", "Dame Mollies", "a Dame Molly");
DutchCardNames[CardNames.DAME_NATALIE] = new TranslatedCardName("Dame Natalie", "Dame Natalies", "a Dame Natalie");
DutchCardNames[CardNames.DAME_SYLVIA] = new TranslatedCardName("Dame Sylvia", "Dame Sylvias", "a Dame Sylvia");
DutchCardNames[CardNames.DEATH_CART] = new TranslatedCardName("Death Cart", "Death Carts", "a Death Cart");
DutchCardNames[CardNames.FEODUM] = new TranslatedCardName("Feodum", "Feoda", "a Feodum");
DutchCardNames[CardNames.FORAGER] = new TranslatedCardName("Forager", "Foragers", "a Forager");
DutchCardNames[CardNames.FORTRESS] = new TranslatedCardName("Fortress", "Fortresses", "a Fortress");
DutchCardNames[CardNames.GRAVEROBBER] = new TranslatedCardName("Graverobber", "Graverobbers", "a Graverobber");
DutchCardNames[CardNames.HERMIT] = new TranslatedCardName("Hermit", "Hermits", "a Hermit");
DutchCardNames[CardNames.HOVEL] = new TranslatedCardName("Hovel", "Hovels", "a Hovel");
DutchCardNames[CardNames.HUNTING_GROUNDS] = new TranslatedCardName("Hunting Grounds", "Hunting Grounds", "a Hunting Grounds");
DutchCardNames[CardNames.IRONMONGER] = new TranslatedCardName("Ironmonger", "Ironmongers", "an Ironmonger");
DutchCardNames[CardNames.JUNK_DEALER] = new TranslatedCardName("Junk Dealer", "Junk Dealers", "a Junk Dealer");
DutchCardNames[CardNames.MADMAN] = new TranslatedCardName("Madman", "Madmen", "a Madman");
DutchCardNames[CardNames.MARKET_SQUARE] = new TranslatedCardName("Market Square", "Market Squares", "a Market Square");
DutchCardNames[CardNames.MARAUDER] = new TranslatedCardName("Marauder", "Marauders", "a Marauder");
DutchCardNames[CardNames.MERCENARY] = new TranslatedCardName("Mercenary", "Mercenaries", "a Mercenary");
DutchCardNames[CardNames.MYSTIC] = new TranslatedCardName("Mystic", "Mystics", "a Mystic");
DutchCardNames[CardNames.NECROPOLIS] = new TranslatedCardName("Necropolis", "Necropolis", "a Necropolis");
DutchCardNames[CardNames.OVERGROWN_ESTATE] = new TranslatedCardName("Overgrown Estate", "Overgrown Estates", "an Overgrown Estate");
DutchCardNames[CardNames.PILLAGE] = new TranslatedCardName("Pillage", "Pillages", "a Pillage");
DutchCardNames[CardNames.POOR_HOUSE] = new TranslatedCardName("Poor House", "Poor Houses", "a Poor House");
DutchCardNames[CardNames.PROCESSION] = new TranslatedCardName("Procession", "Processions", "a Procession");
DutchCardNames[CardNames.RATS] = new TranslatedCardName("Rats", "Rats", "a Rats");
DutchCardNames[CardNames.REBUILD] = new TranslatedCardName("Rebuild", "Rebuilds", "a Rebuild");
DutchCardNames[CardNames.ROGUE] = new TranslatedCardName("Rogue", "Rogues", "a Rogue");
DutchCardNames[CardNames.RUINED_LIBRARY] = new TranslatedCardName("Ruined Library", "Ruined Libraries", "a Ruined Library");
DutchCardNames[CardNames.RUINED_MARKET] = new TranslatedCardName("Ruined Market", "Ruined Markets", "a Ruined Market");
DutchCardNames[CardNames.RUINED_VILLAGE] = new TranslatedCardName("Ruined Village", "Ruined Villages", "a Ruined Village");
DutchCardNames[CardNames.SAGE] = new TranslatedCardName("Sage", "Sages", "a Sage");
DutchCardNames[CardNames.SCAVENGER] = new TranslatedCardName("Scavenger", "Scavengers", "a Scavenger");
DutchCardNames[CardNames.SIR_BAILEY] = new TranslatedCardName("Sir Bailey", "Sir Baileys", "a Sir Bailey");
DutchCardNames[CardNames.SIR_DESTRY] = new TranslatedCardName("Sir Destry", "Sir Destries", "a Sir Destry");
DutchCardNames[CardNames.SIR_MARTIN] = new TranslatedCardName("Sir Martin", "Sir Martins", "a Sir Martin");
DutchCardNames[CardNames.SIR_MICHAEL] = new TranslatedCardName("Sir Michael", "Sir Michaels", "a Sir Michael");
DutchCardNames[CardNames.SIR_VANDER] = new TranslatedCardName("Sir Vander", "Sir Vanders", "a Sir Vander");
DutchCardNames[CardNames.SPOILS] = new TranslatedCardName("Spoils", "Spoils", "a Spoils");
DutchCardNames[CardNames.STOREROOM] = new TranslatedCardName("Storeroom", "Storerooms", "a Storeroom");
DutchCardNames[CardNames.SQUIRE] = new TranslatedCardName("Squire", "Squires", "a Squire");
DutchCardNames[CardNames.SURVIVORS] = new TranslatedCardName("Survivors", "Survivors", "a Survivors");
DutchCardNames[CardNames.URCHIN] = new TranslatedCardName("Urchin", "Urchins", "an Urchin");
DutchCardNames[CardNames.VAGRANT] = new TranslatedCardName("Vagrant", "Vagrants", "a Vagrant");
DutchCardNames[CardNames.WANDERING_MINSTREL] = new TranslatedCardName("Wandering Minstrel", "Wandering Minstrels", "a Wandering Minstrel");

DutchCardNames[CardNames.ADVISOR] = new TranslatedCardName("Advisor", "Advisors", "an Advisor");
DutchCardNames[CardNames.BAKER] = new TranslatedCardName("Baker", "Bakers", "a Baker");
DutchCardNames[CardNames.BUTCHER] = new TranslatedCardName("Butcher", "Butchers", "a Butcher");
DutchCardNames[CardNames.CANDLESTICK_MAKER] = new TranslatedCardName("Candlestick Maker", "Candlestick Makers", "a Candlestick Maker");
DutchCardNames[CardNames.DOCTOR] = new TranslatedCardName("Doctor", "Doctors", "a Doctor");
DutchCardNames[CardNames.HERALD] = new TranslatedCardName("Herald", "Heralds", "a Herald");
DutchCardNames[CardNames.JOURNEYMAN] = new TranslatedCardName("Journeyman", "Journeymen", "a Journeyman");
DutchCardNames[CardNames.MASTERPIECE] = new TranslatedCardName("Masterpiece", "Masterpieces", "a Masterpiece");
DutchCardNames[CardNames.MERCHANT_GUILD] = new TranslatedCardName("Merchant Guild", "Merchant Guilds", "a Merchant Guild");
DutchCardNames[CardNames.PLAZA] = new TranslatedCardName("Plaza", "Plazas", "a Plaza");
DutchCardNames[CardNames.TAXMAN] = new TranslatedCardName("Taxman", "Taxmen", "a Taxman");
DutchCardNames[CardNames.SOOTHSAYER] = new TranslatedCardName("Soothsayer", "Soothsayers", "a Soothsayer");
DutchCardNames[CardNames.STONEMASON] = new TranslatedCardName("Stonemason", "Stonemasons", "a Stonemason");

DutchCardNames[CardNames.ALMS] = new TranslatedCardName("Alms", "Alms", "an Alms");
DutchCardNames[CardNames.AMULET] = new TranslatedCardName("Amulet", "Amulets", "an Amulet");
DutchCardNames[CardNames.ARTIFICER] = new TranslatedCardName("Artificer", "Artificers", "an Artificer");
DutchCardNames[CardNames.BALL] = new TranslatedCardName("Ball", "Balls", "a Ball");
DutchCardNames[CardNames.BONFIRE] = new TranslatedCardName("Bonfire", "Bonfires", "a Bonfire");
DutchCardNames[CardNames.BORROW] = new TranslatedCardName("Borrow", "Borrows", "a Borrow");
DutchCardNames[CardNames.BRIDGE_TROLL] = new TranslatedCardName("Bridge Troll", "Bridge Trolls", "a Bridge Troll");
DutchCardNames[CardNames.CARAVAN_GUARD] = new TranslatedCardName("Caravan Guard", "Caravan Guards", "a Caravan Guard");
DutchCardNames[CardNames.CHAMPION] = new TranslatedCardName("Champion", "Champions", "a Champion");
DutchCardNames[CardNames.COIN_OF_THE_REALM] = new TranslatedCardName("Coin of the Realm", "Coins of the Realm", "a Coin of the Realm");
DutchCardNames[CardNames.DISCIPLE] = new TranslatedCardName("Disciple", "Disciples", "a Disciple");
DutchCardNames[CardNames.DISTANT_LANDS] = new TranslatedCardName("Distant Lands", "Distant Lands", "a Distant Lands");
DutchCardNames[CardNames.DUNGEON] = new TranslatedCardName("Dungeon", "Dungeons", "a Dungeon");
DutchCardNames[CardNames.DUPLICATE] = new TranslatedCardName("Duplicate", "Duplicates", "a Duplicate");
DutchCardNames[CardNames.EXPEDITION] = new TranslatedCardName("Expedition", "Expeditions", "an Expedition");
DutchCardNames[CardNames.FERRY] = new TranslatedCardName("Ferry", "Ferries", "a Ferry");
DutchCardNames[CardNames.FUGITIVE] = new TranslatedCardName("Fugitive", "Fugitives", "a Fugitive");
DutchCardNames[CardNames.GEAR] = new TranslatedCardName("Gear", "Gears", "a Gear");
DutchCardNames[CardNames.GIANT] = new TranslatedCardName("Giant", "Giants", "a Giant");
DutchCardNames[CardNames.GUIDE] = new TranslatedCardName("Guide", "Guides", "a Guide");
DutchCardNames[CardNames.HAUNTED_WOODS] = new TranslatedCardName("Haunted Woods", "Haunted Woods", "a Haunted Woods");
DutchCardNames[CardNames.HERO] = new TranslatedCardName("Hero", "Heroes", "a Hero");
DutchCardNames[CardNames.HIRELING] = new TranslatedCardName("Hireling", "Hirelings", "a Hireling");
DutchCardNames[CardNames.INHERITANCE] = new TranslatedCardName("Inheritance", "Inheritances", "an Inheritance");
DutchCardNames[CardNames.LOST_ARTS] = new TranslatedCardName("Lost Arts", "Lost Arts", "a Lost Arts");
DutchCardNames[CardNames.LOST_CITY] = new TranslatedCardName("Lost City", "Lost Cities", "a Lost City");
DutchCardNames[CardNames.MAGPIE] = new TranslatedCardName("Magpie", "Magpies", "a Magpie");
DutchCardNames[CardNames.MESSENGER] = new TranslatedCardName("Messenger", "Messengers", "a Messenger");
DutchCardNames[CardNames.MISER] = new TranslatedCardName("Miser", "Misers", "a Miser");
DutchCardNames[CardNames.MISSION] = new TranslatedCardName("Mission", "Missions", "a Mission");
DutchCardNames[CardNames.PATHFINDING] = new TranslatedCardName("Pathfinding", "Pathfindings", "a Pathfinding");
DutchCardNames[CardNames.PAGE] = new TranslatedCardName("Page", "Pages", "a Page");
DutchCardNames[CardNames.PEASANT] = new TranslatedCardName("Peasant", "Peasants", "a Peasant");
DutchCardNames[CardNames.PILGRIMAGE] = new TranslatedCardName("Pilgrimage", "Pilgrimages", "a Pilgrimage");
DutchCardNames[CardNames.PLAN] = new TranslatedCardName("Plan", "Plans", "a Plan");
DutchCardNames[CardNames.PORT] = new TranslatedCardName("Port", "Ports", "a Port");
DutchCardNames[CardNames.QUEST] = new TranslatedCardName("Quest", "Quests", "a Quest");
DutchCardNames[CardNames.RANGER] = new TranslatedCardName("Ranger", "Rangers", "a Ranger");
DutchCardNames[CardNames.RAID] = new TranslatedCardName("Raid", "Raids", "a Raid");
DutchCardNames[CardNames.RATCATCHER] = new TranslatedCardName("Ratcatcher", "Ratcatchers", "a Ratcatcher");
DutchCardNames[CardNames.RAZE] = new TranslatedCardName("Raze", "Razes", "a Raze");
DutchCardNames[CardNames.RELIC] = new TranslatedCardName("Relic", "Relics", "a Relic");
DutchCardNames[CardNames.ROYAL_CARRIAGE] = new TranslatedCardName("Royal Carriage", "Royal Carriages", "a Royal Carriage");
DutchCardNames[CardNames.SAVE] = new TranslatedCardName("Save", "Saves", "a Save");
DutchCardNames[CardNames.SCOUTING_PARTY] = new TranslatedCardName("Scouting Party", "Scouting Parties", "a Scouting Party");
DutchCardNames[CardNames.SEAWAY] = new TranslatedCardName("Seaway", "Seaways", "a Seaway");
DutchCardNames[CardNames.SOLDIER] = new TranslatedCardName("Soldier", "Soldiers", "a Soldier");
DutchCardNames[CardNames.STORYTELLER] = new TranslatedCardName("Storyteller", "Storytellers", "a Storyteller");
DutchCardNames[CardNames.SWAMP_HAG] = new TranslatedCardName("Swamp Hag", "Swamp Hags", "a Swamp Hag");
DutchCardNames[CardNames.TEACHER] = new TranslatedCardName("Teacher", "Teachers", "a Teacher");
DutchCardNames[CardNames.TRAVELLING_FAIR] = new TranslatedCardName("Travelling Fair", "Travelling Fairs", "a Travelling Fair");
DutchCardNames[CardNames.TRADE] = new TranslatedCardName("Trade", "Trades", "a Trade");
DutchCardNames[CardNames.TRAINING] = new TranslatedCardName("Training", "Trainings", "a Training");
DutchCardNames[CardNames.TRANSMOGRIFY] = new TranslatedCardName("Transmogrify", "Transmogrifies", "a Transmogrify");
DutchCardNames[CardNames.TREASURE_TROVE] = new TranslatedCardName("Treasure Trove", "Treasure Troves", "a Treasure Trove");
DutchCardNames[CardNames.TREASURE_HUNTER] = new TranslatedCardName("Treasure Hunter", "Treasure Hunters", "a Treasure Hunter");
DutchCardNames[CardNames.WARRIOR] = new TranslatedCardName("Warrior", "Warriors", "a Warrior");
DutchCardNames[CardNames.WINE_MERCHANT] = new TranslatedCardName("Wine Merchant", "Wine Merchants", "a Wine Merchant");

DutchCardNames[CardNames.ENCAMPMENT] = new TranslatedCardName("Encampment", "Encampments", "an Encampment");
DutchCardNames[CardNames.PLUNDER] = new TranslatedCardName("Plunder", "Plunders", "a Plunder");
DutchCardNames[CardNames.PATRICIAN] = new TranslatedCardName("Patrician", "Patricians", "a Patrician");
DutchCardNames[CardNames.EMPORIUM] = new TranslatedCardName("Emporium", "Emporia", "an Emporium");
DutchCardNames[CardNames.SETTLERS] = new TranslatedCardName("Settlers", "Settlers", "a Settlers");
DutchCardNames[CardNames.BUSTLING_VILLAGE] = new TranslatedCardName("Bustling Village", "Bustling Villages", "a Bustling Village");
DutchCardNames[CardNames.CATAPULT] = new TranslatedCardName("Catapult", "Catapults", "a Catapult");
DutchCardNames[CardNames.ROCKS] = new TranslatedCardName("Rocks", "Rocks", "a Rocks");
DutchCardNames[CardNames.GLADIATOR] = new TranslatedCardName("Gladiator", "Gladiators", "a Gladiator");
DutchCardNames[CardNames.FORTUNE] = new TranslatedCardName("Fortune", "Fortunes", "a Fortune");
DutchCardNames[CardNames.CASTLES] = new TranslatedCardName("Castles", "Castles", "a Castles");
DutchCardNames[CardNames.HUMBLE_CASTLE] = new TranslatedCardName("Humble Castle", "Humble Castles", "a Humble Castle");
DutchCardNames[CardNames.CRUMBLING_CASTLE] = new TranslatedCardName("Crumbling Castle", "Crumbling Castles", "a Crumbling Castle");
DutchCardNames[CardNames.SMALL_CASTLE] = new TranslatedCardName("Small Castle", "Small Castles", "a Small Castle");
DutchCardNames[CardNames.HAUNTED_CASTLE] = new TranslatedCardName("Haunted Castle", "Haunted Castles", "a Haunted Castle");
DutchCardNames[CardNames.OPULENT_CASTLE] = new TranslatedCardName("Opulent Castle", "Opulent Castles", "an Opulent Castle");
DutchCardNames[CardNames.SPRAWLING_CASTLE] = new TranslatedCardName("Sprawling Castle", "Sprawling Castles", "a Sprawling Castle");
DutchCardNames[CardNames.GRAND_CASTLE] = new TranslatedCardName("Grand Castle", "Grand Castles", "a Grand Castle");
DutchCardNames[CardNames.KINGS_CASTLE] = new TranslatedCardName("King's Castle", "King's Castles", "a King's Castle");
DutchCardNames[CardNames.ADVANCE] = new TranslatedCardName("Advance", "Advances", "an Advance");
DutchCardNames[CardNames.ANNEX] = new TranslatedCardName("Annex", "Annexes", "an Annex");
DutchCardNames[CardNames.ARCHIVE] = new TranslatedCardName("Archive", "Archives", "an Archive");
DutchCardNames[CardNames.AQUEDUCT] = new TranslatedCardName("Aqueduct", "Aqueducts", "an Aqueduct");
DutchCardNames[CardNames.ARENA] = new TranslatedCardName("Arena", "Arenas", "an Arena");
DutchCardNames[CardNames.BANDIT_FORT] = new TranslatedCardName("Bandit Fort", "Bandit Forts", "a Bandit Fort");
DutchCardNames[CardNames.BANQUET] = new TranslatedCardName("Banquet", "Banquets", "a Banquet");
DutchCardNames[CardNames.BASILICA] = new TranslatedCardName("Basilica", "Basilicas", "a Basilica");
DutchCardNames[CardNames.BATHS] = new TranslatedCardName("Baths", "Baths", "a Baths");
DutchCardNames[CardNames.BATTLEFIELD] = new TranslatedCardName("Battlefield", "Battlefields", "a Battlefield");
DutchCardNames[CardNames.CAPITAL] = new TranslatedCardName("Capital", "Capitals", "a Capital");
DutchCardNames[CardNames.CHARM] = new TranslatedCardName("Charm", "Charms", "a Charm");
DutchCardNames[CardNames.CHARIOT_RACE] = new TranslatedCardName("Chariot Race", "Chariot Races", "a Chariot Race");
DutchCardNames[CardNames.CITY_QUARTER] = new TranslatedCardName("City Quarter", "City Quarters", "a City Quarter");
DutchCardNames[CardNames.COLONNADE] = new TranslatedCardName("Colonnade", "Colonnades", "a Colonnade");
DutchCardNames[CardNames.CONQUEST] = new TranslatedCardName("Conquest", "Conquests", "a Conquest");
DutchCardNames[CardNames.CROWN] = new TranslatedCardName("Crown", "Crowns", "a Crown");
DutchCardNames[CardNames.DELVE] = new TranslatedCardName("Delve", "Delves", "a Delve");
DutchCardNames[CardNames.DEFILED_SHRINE] = new TranslatedCardName("Defiled Shrine", "Defiled Shrines", "a Defiled Shrine");
DutchCardNames[CardNames.DOMINATE] = new TranslatedCardName("Dominate", "Dominates", "a Dominate");
DutchCardNames[CardNames.DONATE] = new TranslatedCardName("Donate", "Donates", "a Donate");
DutchCardNames[CardNames.ENCHANTRESS] = new TranslatedCardName("Enchantress", "Enchantresses", "an Enchantress");
DutchCardNames[CardNames.ENGINEER] = new TranslatedCardName("Engineer", "Engineers", "an Engineer");
DutchCardNames[CardNames.FARMERS_MARKET] = new TranslatedCardName("Farmers' Market", "Farmers' Markets", "a Farmers' Market");
DutchCardNames[CardNames.FORUM] = new TranslatedCardName("Forum", "Forums", "a Forum");
DutchCardNames[CardNames.FOUNTAIN] = new TranslatedCardName("Fountain", "Fountains", "a Fountain");
DutchCardNames[CardNames.GROUNDSKEEPER] = new TranslatedCardName("Groundskeeper", "Groundskeepers", "a Groundskeeper");
DutchCardNames[CardNames.KEEP] = new TranslatedCardName("Keep", "Keeps", "a Keep");
DutchCardNames[CardNames.LABYRINTH] = new TranslatedCardName("Labyrinth", "Labyrinths", "a Labyrinth");
DutchCardNames[CardNames.LEGIONARY] = new TranslatedCardName("Legionary", "Legionaries", "a Legionary");
DutchCardNames[CardNames.MOUNTAIN_PASS] = new TranslatedCardName("Mountain Pass", "Mountain Passes", "a Mountain Pass");
DutchCardNames[CardNames.MUSEUM] = new TranslatedCardName("Museum", "Museums", "a Museum");
DutchCardNames[CardNames.OBELISK] = new TranslatedCardName("Obelisk", "Obelisks", "an Obelisk");
DutchCardNames[CardNames.ORCHARD] = new TranslatedCardName("Orchard", "Orchards", "an Orchard");
DutchCardNames[CardNames.OVERLORD] = new TranslatedCardName("Overlord", "Overlords", "an Overlord");
DutchCardNames[CardNames.PALACE] = new TranslatedCardName("Palace", "Palaces", "a Palace");
DutchCardNames[CardNames.RITUAL] = new TranslatedCardName("Ritual", "Rituals", "a Ritual");
DutchCardNames[CardNames.ROYAL_BLACKSMITH] = new TranslatedCardName("Royal Blacksmith", "Royal Blacksmiths", "a Royal Blacksmith");
DutchCardNames[CardNames.SACRIFICE] = new TranslatedCardName("Sacrifice", "Sacrifices", "a Sacrifice");
DutchCardNames[CardNames.SALT_THE_EARTH] = new TranslatedCardName("Salt the Earth", "Salt the Earths", "a Salt the Earth");
DutchCardNames[CardNames.TAX] = new TranslatedCardName("Tax", "Taxes", "a Tax");
DutchCardNames[CardNames.TEMPLE] = new TranslatedCardName("Temple", "Temples", "a Temple");
DutchCardNames[CardNames.TOMB] = new TranslatedCardName("Tomb", "Tombs", "a Tomb");
DutchCardNames[CardNames.TOWER] = new TranslatedCardName("Tower", "Towers", "a Tower");
DutchCardNames[CardNames.TRIUMPH] = new TranslatedCardName("Triumph", "Triumphs", "a Triumph");
DutchCardNames[CardNames.TRIUMPHAL_ARCH] = new TranslatedCardName("Triumphal Arch", "Triumphal Arches", "a Triumphal Arch");
DutchCardNames[CardNames.VILLA] = new TranslatedCardName("Villa", "Villas", "a Villa");
DutchCardNames[CardNames.WALL] = new TranslatedCardName("Wall", "Walls", "a Wall");
DutchCardNames[CardNames.WOLF_DEN] = new TranslatedCardName("Wolf Den", "Wolf Dens", "a Wolf Den");
DutchCardNames[CardNames.WEDDING] = new TranslatedCardName("Wedding", "Weddings", "a Wedding");
DutchCardNames[CardNames.WILD_HUNT] = new TranslatedCardName("Wild Hunt", "Wild Hunts", "a Wild Hunt");
DutchCardNames[CardNames.WINDFALL] = new TranslatedCardName("Windfall", "Windfalls", "a Windfall");

DutchCardNames[CardNames.BOON_DRAWPILE] = new TranslatedCardName("Boon Drawpile", "Boon Drawpiles", "a Boon Drawpile");
DutchCardNames[CardNames.BOON_DISCARDPILE] = new TranslatedCardName("Boon Discardpile", "Boon Discardpiles", "a Boon Discardpile");
DutchCardNames[CardNames.THE_EARTHS_GIFT] = new TranslatedCardName("The Earth's Gift", "The Earth's Gifts", "The Earth's Gift");
DutchCardNames[CardNames.THE_FIELDS_GIFT] = new TranslatedCardName("The Field's Gift", "The Field's Gifts", "The Field's Gift");
DutchCardNames[CardNames.THE_FLAMES_GIFT] = new TranslatedCardName("The Flame's Gift", "The Flame's Gifts", "The Flame's Gift");
DutchCardNames[CardNames.THE_FORESTS_GIFT] = new TranslatedCardName("The Forest's Gift", "The Forest's Gifts", "The Forest's Gift");
DutchCardNames[CardNames.THE_MOONS_GIFT] = new TranslatedCardName("The Moon's Gift", "The Moon's Gifts", "The Moon's Gift");
DutchCardNames[CardNames.THE_MOUNTAINS_GIFT] = new TranslatedCardName("The Mountain's Gift", "The Mountain's Gifts", "The Mountain's Gift");
DutchCardNames[CardNames.THE_RIVERS_GIFT] = new TranslatedCardName("The River's Gift", "The River's Gifts", "The River's Gift");
DutchCardNames[CardNames.THE_SEAS_GIFT] = new TranslatedCardName("The Sea's Gift", "The Sea's Gifts", "The Sea's Gift");
DutchCardNames[CardNames.THE_SKYS_GIFT] = new TranslatedCardName("The Sky's Gift", "The Sky's Gifts", "The Sky's Gift");
DutchCardNames[CardNames.THE_SUNS_GIFT] = new TranslatedCardName("The Sun's Gift", "The Sun's Gifts", "The Sun's Gift");
DutchCardNames[CardNames.THE_SWAMPS_GIFT] = new TranslatedCardName("The Swamp's Gift", "The Swamp's Gifts", "The Swamp's Gift");
DutchCardNames[CardNames.THE_WINDS_GIFT] = new TranslatedCardName("The Wind's Gift", "The Wind's Gifts", "The Wind's Gift");
DutchCardNames[CardNames.HEX_DRAWPILE] = new TranslatedCardName("Hex Drawpile", "Hex Drawpiles", "a Hex Drawpile");
DutchCardNames[CardNames.HEX_DISCARDPILE] = new TranslatedCardName("Hex Discardpile", "Hex Discardpiles", "a Hex Discardpile");
DutchCardNames[CardNames.BAD_OMENS] = new TranslatedCardName("Bad Omens", "Bad Omens", "Bad Omens");
DutchCardNames[CardNames.DELUSION] = new TranslatedCardName("Delusion", "Delusions", "Delusion");
DutchCardNames[CardNames.ENVY] = new TranslatedCardName("Envy", "Envies", "Envy");
DutchCardNames[CardNames.FAMINE] = new TranslatedCardName("Famine", "Famines", "Famine");
DutchCardNames[CardNames.FEAR] = new TranslatedCardName("Fear", "Fears", "Fear");
DutchCardNames[CardNames.GREED] = new TranslatedCardName("Greed", "Greeds", "Greed");
DutchCardNames[CardNames.HAUNTING] = new TranslatedCardName("Haunting", "Hauntings", "Haunting");
DutchCardNames[CardNames.LOCUSTS] = new TranslatedCardName("Locusts", "Locusts", "Locusts");
DutchCardNames[CardNames.MISERY] = new TranslatedCardName("Misery", "Miseries", "Misery");
DutchCardNames[CardNames.PLAGUE] = new TranslatedCardName("Plague", "Plagues", "Plague");
DutchCardNames[CardNames.POVERTY] = new TranslatedCardName("Poverty", "Poverties", "Poverty");
DutchCardNames[CardNames.WAR] = new TranslatedCardName("War", "Wars", "War");
DutchCardNames[CardNames.MISERABLE] = new TranslatedCardName("Miserable", "Miserables", "Miserable");
DutchCardNames[CardNames.TWICE_MISERABLE] = new TranslatedCardName("Twice Miserable", "Twice Miserables", "Twice Miserable");
DutchCardNames[CardNames.ENVIOUS] = new TranslatedCardName("Envious", "Envious", "Envious");
DutchCardNames[CardNames.DELUDED] = new TranslatedCardName("Deluded", "Deludeds", "Deluded");
DutchCardNames[CardNames.LOST_IN_THE_WOODS] = new TranslatedCardName("Lost In The Woods", "Lost In The Woods", "Lost In The Woods");
DutchCardNames[CardNames.BARD] = new TranslatedCardName("Bard", "Bards", "a Bard");
DutchCardNames[CardNames.BLESSED_VILLAGE] = new TranslatedCardName("Blessed Village", "Blessed Villages", "a Blessed Village");
DutchCardNames[CardNames.CHANGELING] = new TranslatedCardName("Changeling", "Changelings", "a Changeling");
DutchCardNames[CardNames.CEMETERY] = new TranslatedCardName("Cemetery", "Cemeteries", "a Cemetery");
DutchCardNames[CardNames.COBBLER] = new TranslatedCardName("Cobbler", "Cobblers", "a Cobbler");
DutchCardNames[CardNames.CONCLAVE] = new TranslatedCardName("Conclave", "Conclaves", "a Conclave");
DutchCardNames[CardNames.CRYPT] = new TranslatedCardName("Crypt", "Crypts", "a Crypt");
DutchCardNames[CardNames.CURSED_VILLAGE] = new TranslatedCardName("Cursed Village", "Cursed Villages", "a Cursed Village");
DutchCardNames[CardNames.DEN_OF_SIN] = new TranslatedCardName("Den Of Sin", "Dens Of Sin", "a Den Of Sin");
DutchCardNames[CardNames.DEVILS_WORKSHOP] = new TranslatedCardName("Devil's Workshop", "Devil's Workshops", "a Devil's Workshop");
DutchCardNames[CardNames.DRUID] = new TranslatedCardName("Druid", "Druids", "a Druid");
DutchCardNames[CardNames.EXORCIST] = new TranslatedCardName("Exorcist", "Exorcists", "an Exorcist");
DutchCardNames[CardNames.FAITHFUL_HOUND] = new TranslatedCardName("Faithful Hound", "Faithful Hounds", "a Faithful Hound");
DutchCardNames[CardNames.FOOL] = new TranslatedCardName("Fool", "Fools", "a Fool");
DutchCardNames[CardNames.GHOST_TOWN] = new TranslatedCardName("Ghost Town", "Ghost Towns", "a Ghost Town");
DutchCardNames[CardNames.GUARDIAN] = new TranslatedCardName("Guardian", "Guardians", "a Guardian");
DutchCardNames[CardNames.IDOL] = new TranslatedCardName("Idol", "Idols", "an Idol");
DutchCardNames[CardNames.LEPRECHAUN] = new TranslatedCardName("Leprechaun", "Leprechauns", "a Leprechaun");
DutchCardNames[CardNames.MONASTERY] = new TranslatedCardName("Monastery", "Monasteries", "a Monastery");
DutchCardNames[CardNames.NECROMANCER] = new TranslatedCardName("Necromancer", "Necromancers", "a Necromancer");
DutchCardNames[CardNames.NIGHT_WATCHMAN] = new TranslatedCardName("Night Watchman", "Night Watchmen", "a Night Watchman");
DutchCardNames[CardNames.PIXIE] = new TranslatedCardName("Pixie", "Pixies", "a Pixie");
DutchCardNames[CardNames.POOKA] = new TranslatedCardName("Pooka", "Pookas", "a Pooka");
DutchCardNames[CardNames.RAIDER] = new TranslatedCardName("Raider", "Raiders", "a Raider");
DutchCardNames[CardNames.SACRED_GROVE] = new TranslatedCardName("Sacred Grove", "Sacred Groves", "a Sacred Grove");
DutchCardNames[CardNames.SECRET_CAVE] = new TranslatedCardName("Secret Cave", "Secret Caves", "a Secret Cave");
DutchCardNames[CardNames.SHEPHERD] = new TranslatedCardName("Shepherd", "Shepherds", "a Shepherd");
DutchCardNames[CardNames.SKULK] = new TranslatedCardName("Skulk", "Skulks", "a Skulk");
DutchCardNames[CardNames.TORMENTOR] = new TranslatedCardName("Tormentor", "Tormentors", "a Tormentor");
DutchCardNames[CardNames.TRAGIC_HERO] = new TranslatedCardName("Tragic Hero", "Tragic Heroes", "a Tragic Hero");
DutchCardNames[CardNames.TRACKER] = new TranslatedCardName("Tracker", "Trackers", "a Tracker");
DutchCardNames[CardNames.VAMPIRE] = new TranslatedCardName("Vampire", "Vampires", "a Vampire");
DutchCardNames[CardNames.WEREWOLF] = new TranslatedCardName("Werewolf", "Werewolves", "a Werewolf");
DutchCardNames[CardNames.CURSED_GOLD] = new TranslatedCardName("Cursed Gold", "Cursed Golds", "a Cursed Gold");
DutchCardNames[CardNames.GOAT] = new TranslatedCardName("Goat", "Goats", "a Goat");
DutchCardNames[CardNames.HAUNTED_MIRROR] = new TranslatedCardName("Haunted Mirror", "Haunted Mirrors", "a Haunted Mirror");
DutchCardNames[CardNames.LUCKY_COIN] = new TranslatedCardName("Lucky Coin", "Lucky Coins", "a Lucky Coin");
DutchCardNames[CardNames.MAGIC_LAMP] = new TranslatedCardName("Magic Lamp", "Magic Lamps", "a Magic Lamp");
DutchCardNames[CardNames.PASTURE] = new TranslatedCardName("Pasture", "Pastures", "a Pasture");
DutchCardNames[CardNames.POUCH] = new TranslatedCardName("Pouch", "Pouches", "a Pouch");
DutchCardNames[CardNames.BAT] = new TranslatedCardName("Bat", "Bats", "a Bat");
DutchCardNames[CardNames.GHOST] = new TranslatedCardName("Ghost", "Ghosts", "a Ghost");
DutchCardNames[CardNames.IMP] = new TranslatedCardName("Imp", "Imps", "an Imp");
DutchCardNames[CardNames.WILL_O_WISP] = new TranslatedCardName("Will-o'-wisp", "Will-o'-wisps", "a Will-o'-wisp");
DutchCardNames[CardNames.WISH] = new TranslatedCardName("Wish", "Wishes", "a Wish");
DutchCardNames[CardNames.ZOMBIE_APPRENTICE] = new TranslatedCardName("Zombie Apprentice", "Zombie Apprentices", "a Zombie Apprentice");
DutchCardNames[CardNames.ZOMBIE_MASON] = new TranslatedCardName("Zombie Mason", "Zombie Masons", "a Zombie Mason");
DutchCardNames[CardNames.ZOMBIE_SPY] = new TranslatedCardName("Zombie Spy", "Zombie Spies", "a Zombie Spy");

DutchCardNames[CardNames.AVANTO] = new TranslatedCardName("Avanto", "Avantos", "an Avanto");
DutchCardNames[CardNames.BLACK_MARKET] = new TranslatedCardName("Black Market", "Black Markets", "a Black Market");
DutchCardNames[CardNames.ENVOY] = new TranslatedCardName("Envoy", "Envoys", "an Envoy");
DutchCardNames[CardNames.GOVERNOR] = new TranslatedCardName("Governor", "Governors", "a Governor");
DutchCardNames[CardNames.PRINCE] = new TranslatedCardName("Prince", "Princes", "a Prince");
DutchCardNames[CardNames.SAUNA] = new TranslatedCardName("Sauna", "Saunas", "a Sauna");
DutchCardNames[CardNames.STASH] = new TranslatedCardName("Stash", "Stashes", "a Stash");
DutchCardNames[CardNames.SUMMON] = new TranslatedCardName("Summon", "Summons", "a Summon");
DutchCardNames[CardNames.WALLED_VILLAGE] = new TranslatedCardName("Walled Village", "Walled Villages", "a Walled Village");
DutchCardNames[CardNames.BLACK_MARKET_PILE] = new TranslatedCardName("Black Market Deck", "Black Market Decks", "a Black Market Deck");
DutchCardNames[CardNames.DISMANTLE] = new TranslatedCardName("Dismantle", "Dismantles", "a Dismantle");
DutchCardNames[CardNames.CHURCH] = new TranslatedCardName("Church", "Churches", "a Church");
DutchCardNames[CardNames.CAPTAIN] = new TranslatedCardName("Captain", "Captains", "a Captain");