"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SupplyZone = function (_Zone) {
    _inherits(SupplyZone, _Zone);

    function SupplyZone() {
        _classCallCheck(this, SupplyZone);

        return _possibleConstructorReturn(this, (SupplyZone.__proto__ || Object.getPrototypeOf(SupplyZone)).apply(this, arguments));
    }

    _createClass(SupplyZone, [{
        key: "reposition",
        value: function reposition() {
            var shouldAnimate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            if (!this.isVisible) return;
            var repositionStack = function repositionStack(stack, x, y, width, height, zIndex) {
                if (shouldAnimate) stack.animator = new TransitionAnimator(stack, absoluteTransitionScript(new Rectangle(x, y, width, height, zIndex)), stack, function () {});else stack.reposition(x, y, width, height, zIndex);
            };

            if (this.layoutModel.layout === null) return;
            this.bbox = this.layoutModel.layout.cardElements[this.indexOfType].bbox.copy();
            if (this.attachedTrait !== CardNames.BACK) {
                this.bbox.x += this.bbox.width * 0.07;
                this.bbox.y += this.bbox.height * 0.14;
                this.bbox.width *= 0.86;
                this.bbox.height *= 0.86;
            }

            if (this.cardStacks[0].hasFilter("shadowed-supply")) {
                repositionStack(this.cardStacks[0], this.bbox.x + this.bbox.width * 0.005, this.bbox.y + this.bbox.height * 0.015, 0.95 * this.bbox.width * 0.97, 0.95 * this.bbox.height * 0.97, this.bbox.zIndex);
            } else {
                repositionStack(this.cardStacks[0], this.bbox.x + this.bbox.width * 0.005, this.bbox.y + this.bbox.height * 0.015, this.bbox.width * 0.97, this.bbox.height * 0.97, this.bbox.zIndex);
            }
        }
    }, {
        key: "initialize",
        value: function initialize(cardIds) {
            var _this2 = this;

            var newStack = new CardStack(this.state.getAnonCard(this.pileName), this, CardSizes.MINI);
            newStack.getCounter = function () {
                return newStack.cards.length + newStack.anonymousCards;
            };
            newStack.updateCount();
            newStack.addFilter("shifted-name");
            newStack.addFilter("empty");
            cardIds.forEach(function (id) {
                newStack.removeFilter("empty");
                if (id === -1) newStack.addAnonymousCard();else {
                    var card = _this2.state.cards[id];
                    newStack.addCard(card);
                    if (newStack.cards.some(function (c) {
                        return c.cardName !== card.cardName;
                    }) && !_this2.markers.some(function (marker) {
                        return marker.type === PileMarkerTypes.MIXED;
                    })) {
                        newStack.addFilter("shadowed-supply");
                    }
                }
            });
            this.reposition();
        }
    }, {
        key: "addCard",
        value: function addCard(card) {
            if (this.cardStacks[0].cards.some(function (c) {
                return c.cardName !== card.cardName;
            }) && !this.markers.some(function (marker) {
                return marker.type === PileMarkerTypes.MIXED;
            })) {
                this.cardStacks[0].addFilter("shadowed-supply");
            }
            return this.cardStacks[0];
        }
    }, {
        key: "addMarker",
        value: function addMarker(marker) {
            if (marker.type === PileMarkerTypes.MIXED) this.cardStacks.forEach(function (s) {
                return s.removeFilter("shadowed-supply");
            });
            _get(SupplyZone.prototype.__proto__ || Object.getPrototypeOf(SupplyZone.prototype), "addMarker", this).call(this, marker);
        }
    }, {
        key: "addAnonymousCard",
        value: function addAnonymousCard() {
            return this.cardStacks[0];
        }
    }, {
        key: "mergeStacks",
        value: function mergeStacks(targetStack, transitionStack) {
            _get(SupplyZone.prototype.__proto__ || Object.getPrototypeOf(SupplyZone.prototype), "mergeStacks", this).call(this, targetStack, transitionStack);
            this.cardStacks[0].removeFilter("empty");
        }
    }, {
        key: "spliceCard",
        value: function spliceCard(card) {
            var _this3 = this;

            this.cardStacks[0].removeCard(card);
            var newStack = new CardStack(this.state.getAnonCard(card.cardName), this.state.nowhere);
            newStack.addCard(card);
            newStack.copyPosition(this.cardStacks[0]);
            if (this.cardStacks[0].cards.length === 0) this.cardStacks[0].addFilter("empty");
            if (this.cardStacks[0].cards.every(function (c) {
                return c.cardName === _this3.cardStacks[0].topCard.cardName;
            })) {
                this.cardStacks[0].removeFilter("shadowed-supply");
                this.reposition();
            }
            return newStack;
        }
    }, {
        key: "spliceAnonymousCard",
        value: function spliceAnonymousCard() {
            console.error("removing anonymous card from supply");
            this.cardStacks[0].removeCard(this.cardStacks[0].topCard);
            var newStack = new CardStack(this.state.getAnonCard(CardNames.BACK), this.state.nowhere);
            newStack.anonymousCards++;
            newStack.copyPosition(this.cardStacks[0]);
            if (this.cardStacks[0].cards.length === 0) this.cardStacks[0].addFilter("empty");
            return newStack;
        }
    }, {
        key: "getScript",
        value: function getScript(fromZone, stack, targetStack, props) {
            if (props.isInstant) return [];
            switch (props.type) {
                case ClientAnimationTypes.MINIMAL:
                case ClientAnimationTypes.NORMAL:
                    var width = stack.view.bbox.width;
                    var height = stack.view.bbox.height;
                    return [new Keyframe(props.speed * 0.7, new RelativeTarget(new Rectangle(0, 0.9 * height, 0, -0.9 * height, 10, 0, -1)), Curves.PARABOLIC_START)];
                    break;
                case ClientAnimationTypes.HIGHLIGHTED:
                    return _get(SupplyZone.prototype.__proto__ || Object.getPrototypeOf(SupplyZone.prototype), "getScript", this).call(this, fromZone, stack, targetStack, props);
                    break;
            }
        }
    }, {
        key: "repositionOnNewTurn",
        get: function get() {
            return true;
        }
    }]);

    return SupplyZone;
}(Zone);

var SupplyCardZone = function (_SupplyZone) {
    _inherits(SupplyCardZone, _SupplyZone);

    function SupplyCardZone(state, index, pileName, owner, createdBy, attachedTrait, isVisible) {
        _classCallCheck(this, SupplyCardZone);

        var _this4 = _possibleConstructorReturn(this, (SupplyCardZone.__proto__ || Object.getPrototypeOf(SupplyCardZone)).call(this, state, index, pileName, owner, createdBy, attachedTrait, isVisible));

        _this4.indexOfType = _this4.state.zones.filter(function (z) {
            return z instanceof SupplyZone;
        }).length;
        return _this4;
    }

    return SupplyCardZone;
}(SupplyZone);