"use strict";

var JapaneseCardTexts = {};

JapaneseCardTexts[CardNames.BACK] = "";
JapaneseCardTexts[CardNames.CURSE] = "";
JapaneseCardTexts[CardNames.COPPER] = "";
JapaneseCardTexts[CardNames.SILVER] = "";
JapaneseCardTexts[CardNames.GOLD] = "";
JapaneseCardTexts[CardNames.ESTATE] = "";
JapaneseCardTexts[CardNames.DUCHY] = "";
JapaneseCardTexts[CardNames.PROVINCE] = "";
JapaneseCardTexts[CardNames.ARTISAN] = "\u30B3\u30B9\u30C8[5]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C91\u679A\u3092//\u624B\u672D\u306B\u7372\u5F97\u3059\u308B\u3002//\u624B\u672D1\u679A\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.BANDIT] = "\u91D1\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E//\u4E0A\u304B\u30892\u679A\u3092\u516C\u958B\u3057\u3001\u9285\u8CA8\u4EE5\u5916\u306E//\u516C\u958B\u3057\u305F\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u3001//\u6B8B\u308A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.BUREAUCRAT] = "\u9280\u8CA81\u679A\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7372\u5F97\u3059\u308B\u3002//\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u624B\u672D\u306E//\u52DD\u5229\u70B9\u30AB\u30FC\u30C91\u679A\u3092\u516C\u958B\u3057\u3001\u5C71\u672D//\u306E\u4E0A\u306B\u7F6E\u304F(\u52DD\u5229\u70B9\u30AB\u30FC\u30C9\u304C\u306A\u3044//\u5834\u5408\u3001\u624B\u672D\u3092\u516C\u958B\u3059\u308B)\u3002";
JapaneseCardTexts[CardNames.CELLAR] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D\u3092\u597D\u304D\u306A\u679A\u6570\u6368\u3066//\u672D\u306B\u3057\u30011\u679A\u306B\u3064\u304D\u3001//|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.CHAPEL] = "\u624B\u672D\u3092\u6700\u59274\u679A\u5EC3\u68C4//\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.COUNCIL_ROOM] = "|\uFF0B\uFF14\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u8CFC\u5165|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001//1\u679A\u30AB\u30FC\u30C9\u3092\u5F15\u304F\u3002";
JapaneseCardTexts[CardNames.FESTIVAL] = "|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B\uFF11\u3000\u8CFC\u5165|//|\uFF0B[2]|";
JapaneseCardTexts[CardNames.GARDENS] = "\u3042\u306A\u305F\u306E\u30AB\u30FC\u30C910\u679A//(\u7AEF\u6570\u5207\u308A\u6368\u3066)\u306B\u3064\u304D//{1}\u3002";
JapaneseCardTexts[CardNames.HARBINGER] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u6368\u3066\u672D\u7F6E\u304D\u5834\u306E\u30AB\u30FC\u30C9\u3092//\u3059\u3079\u3066\u898B\u308B\u3002\u305D\u306E\u4E2D\u306E1\u679A\u3092//\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.LABORATORY] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|";
JapaneseCardTexts[CardNames.LIBRARY] = "\u624B\u672D\u304C7\u679A\u306B\u306A\u308B\u3088\u3046\u306B\u3001\u597D\u304D\u306A//\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u3092\u4F55\u679A\u3067\u3082//\u98DB\u3070\u3057\u306A\u304C\u3089\u30011\u679A\u305A\u3064\u30AB\u30FC\u30C9\u3092//\u5F15\u304F\u3002\u98DB\u3070\u3059\u3082\u306E\u306F\u8107\u306B\u7F6E\u304D\u3001//\u5F15\u304D\u7D42\u3048\u305F\u5F8C\u3001\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.MARKET] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B\uFF11\u3000\u8CFC\u5165|//|\uFF0B[1]|";
JapaneseCardTexts[CardNames.MERCHANT] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u3053\u306E\u30BF\u30FC\u30F3\u521D\u3081\u3066\u9280\u8CA8\u3092//\u4F7F\u7528\u3059\u308B\u3068\u304D\u3001|\uFF0B[1]|\u3002";
JapaneseCardTexts[CardNames.MILITIA] = "|\uFF0B[2]|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001//\u624B\u672D\u304C3\u679A\u306B\u306A\u308B\u3088\u3046\u306B//\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.MINE] = "\u624B\u672D\u306E\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4//\u3057\u3066\u3082\u3088\u3044\u3002\u305D\u308C\u3088\u308A\u30B3\u30B9\u30C8//\u304C\u6700\u5927[3]\u9AD8\u3044\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A//\u3092\u624B\u672D\u306B\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.MOAT] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//---//\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u304C\u30A2\u30BF\u30C3\u30AF\u30AB\u30FC\u30C9\u3092\u4F7F\u7528//\u3059\u308B\u3068\u304D\u3001\u305D\u306E\u89E3\u6C7A\u524D\u306B\u3001\u624B\u672D\u304B\u3089\u3053\u308C//\u3092\u516C\u958B\u3057\u3066\u3082\u3088\u3044\u3002\u516C\u958B\u3057\u305F\u5834\u5408\u3001\u305D\u306E//\u30A2\u30BF\u30C3\u30AF\u30AB\u30FC\u30C9\u306E\u5F71\u97FF\u3092\u53D7\u3051\u306A\u3044\u3002";
JapaneseCardTexts[CardNames.MONEYLENDER] = "|\uFF0B|[3]\u306E\u305F\u3081\u306B\u3001\u624B\u672D\u306E//\u9285\u8CA81\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.POACHER] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B[1]|////\u7A7A\u306E\u30B5\u30D7\u30E9\u30A41\u5C71\u306B\u3064\u304D//\u624B\u672D1\u679A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.REMODEL] = "\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002//\u305D\u308C\u3088\u308A\u30B3\u30B9\u30C8\u304C\u6700\u5927[2]//\u9AD8\u3044\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.SENTRY] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u5C71\u672D\u306E\u4E0A\u304B\u30892\u679A\u3092\u898B\u308B\u3002\u305D\u306E\u4E2D//\u306E\u597D\u304D\u306A\u679A\u6570\u3092\u5EC3\u68C4\u3057\u3001\u597D\u304D\u306A//\u679A\u6570\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002\u6B8B\u308A\u3092//\u597D\u304D\u306A\u9806\u756A\u3067\u5C71\u672D\u306E\u4E0A\u306B\u623B\u3059\u3002";
JapaneseCardTexts[CardNames.SMITHY] = "|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|";
JapaneseCardTexts[CardNames.THRONE_ROOM] = "\u624B\u672D\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9//1\u679A\u30922\u56DE\u4F7F\u7528\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.VASSAL] = "|\uFF0B[2]|////\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u6368\u3066\u672D\u306B//\u3059\u308B\u3002\u305D\u308C\u304C\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u306E//\u5834\u5408\u3001\u5834\u306B\u51FA\u3057\u3066\u4F7F\u7528\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.VILLAGE] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|";
JapaneseCardTexts[CardNames.WITCH] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001//\u546A\u30441\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.WORKSHOP] = "\u30B3\u30B9\u30C8[4]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C9//1\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";

//Intrigue
JapaneseCardTexts[CardNames.BARON] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|////|\uFF0B|[4]\u306E\u305F\u3081\u306B\u3001\u5C4B\u65771\u679A\u3092\u6368\u3066\u672D//\u306B\u3057\u3066\u3082\u3088\u3044\u3002\u6368\u3066\u672D\u306B\u3057\u306A\u304B\u3063//\u305F\u5834\u5408\u3001\u5C4B\u65771\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.BRIDGE] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|//|\uFF0B[1]|////\u3053\u306E\u30BF\u30FC\u30F3\u3001\u3059\u3079\u3066\u306E//\u30AB\u30FC\u30C9\u306E\u30B3\u30B9\u30C8\u306F[1]//\u4E0B\u304C\u308B\u3002";
JapaneseCardTexts[CardNames.COURTYARD] = "|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////\u624B\u672D1\u679A\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.CONSPIRATOR] = "|\uFF0B[2]|////\u3053\u306E\u30BF\u30FC\u30F3\u306B (\u3053\u308C\u3082\u542B\u3081\u3066)//3\u56DE\u4EE5\u4E0A\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u3092\u4F7F\u7528//\u3057\u305F\u5834\u5408\u3001|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3001//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u3002";
JapaneseCardTexts[CardNames.COURTIER] = "\u624B\u672D1\u679A\u3092\u516C\u958B\u3059\u308B\u3002\u305D\u308C\u304C\u6301\u3064//\u7A2E\u5225(\u30A2\u30AF\u30B7\u30E7\u30F3\u3001\u30A2\u30BF\u30C3\u30AF\u306A\u3069)//1\u3064\u306B\u3064\u304D\u3001\u6B21\u306E\u3046\u30611\u3064\u3092\u9078\u3076//(\u7570\u306A\u308B\u3082\u306E\u3092\u9078\u3076):////\u300C|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u300D;//\u300C|\uFF0B\uFF11\u3000\u8CFC\u5165|\u300D;\u300C|\uFF0B|[3]\u300D;//\u300C\u91D1\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u300D";
JapaneseCardTexts[CardNames.DIPLOMAT] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//\u5F15\u304D\u7D42\u3048\u305F\u5F8C\u3001\u624B\u672D\u304C5\u679A\u4EE5\u4E0B\u306E\u5834\u5408\u3001//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u3002//---//\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u304C\u30A2\u30BF\u30C3\u30AF\u30AB\u30FC\u30C9\u3092\u4F7F\u7528//\u3059\u308B\u3068\u304D\u3001\u305D\u306E\u89E3\u6C7A\u524D\u306B\u30015\u679A\u4EE5\u4E0A\u306E\u624B\u672D//\u304B\u3089\u3053\u308C\u3092\u516C\u958B\u3057\u3066\u3082\u3088\u3044\u3002\u516C\u958B\u3057\u305F\u5834//\u5408\u30012\u679A\u30AB\u30FC\u30C9\u3092\u5F15\u304D3\u679A\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.DUKE] = "\u3042\u306A\u305F\u306E\u516C\u98181\u679A\u306B\u3064\u304D{1}\u3002";
JapaneseCardTexts[CardNames.FARM] = "[!2]//---//{!2}";
JapaneseCardTexts[CardNames.IRONWORKS] = "\u30B3\u30B9\u30C8[4]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C91\u679A\u3092//\u7372\u5F97\u3059\u308B\u3002\u7372\u5F97\u3057\u305F\u30AB\u30FC\u30C9\u304C...////\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001//|\uFF0B\uFF11 \u30A2\u30AF\u30B7\u30E7\u30F3| ////\u8CA1\u5B9D\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001|\uFF0B|[1]////\u52DD\u5229\u70B9\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001//|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F| ";
JapaneseCardTexts[CardNames.LURKER] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u6B21\u306E\u3046\u30611\u3064\u3092\u9078\u3076:\u300C\u30B5\u30D7\u30E9\u30A4\u306E//\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3059\u308B\u300D;//\u300C\u5EC3\u68C4\u7F6E\u304D\u5834\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9//1\u679A\u3092\u7372\u5F97\u3059\u308B\u300D";
JapaneseCardTexts[CardNames.MASQUERADE] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////\u624B\u672D\u304C\u3042\u308B\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1//\u540C\u6642\u306B\u3001\u5DE6\u96A3\u306E\u305D\u306E\u3088\u3046\u306A\u30D7//\u30EC\u30A4\u30E4\u30FC\u306B\u624B\u672D1\u679A\u3092\u6E21\u3059\u3002//\u305D\u306E\u5F8C\u3001\u3042\u306A\u305F\u306F\u624B\u672D1\u679A\u3092//\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.MILL] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////|\uFF0B|[2]\u306E\u305F\u3081\u306B\u3001\u624B\u672D2\u679A\u3092//\u6368\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002//---//{!1}";
JapaneseCardTexts[CardNames.MINING_VILLAGE] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////|\uFF0B|[2]\u306E\u305F\u3081\u306B\u3001\u3053\u308C\u3092//\u5834\u304B\u3089\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.MINION] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u6B21\u306E\u3046\u30611\u3064\u3092\u9078\u3076:////\u300C|\uFF0B|[2]\u300D;\u300C\u3042\u306A\u305F\u3068\u624B\u672D\u304C//5\u679A\u4EE5\u4E0A\u306E\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F//\u5168\u54E1\u3001\u624B\u672D\u3092\u3059\u3079\u3066\u6368\u3066\u672D//\u306B\u3057\u3001|\uFF0B\uFF14\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u300D";
JapaneseCardTexts[CardNames.NOBLES] = "\u6B21\u306E\u3046\u30611\u3064\u3092\u9078\u3076:// \u300C|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u300D; //  \u300C|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u300D  //---//{!2}";
JapaneseCardTexts[CardNames.PATROL] = "|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////\u5C71\u672D\u306E\u4E0A\u304B\u30894\u679A\u3092\u516C\u958B\u3059\u308B\u3002//\u516C\u958B\u3057\u305F\u52DD\u5229\u70B9\u30AB\u30FC\u30C9\u3068\u546A\u3044 //\u3092\u624B\u672D\u306B\u52A0\u3048\u308B\u3002\u6B8B\u308A\u3092\u597D\u304D //\u306A\u9806\u756A\u3067\u5C71\u672D\u306E\u4E0A\u306B\u623B\u3059\u3002";
JapaneseCardTexts[CardNames.PAWN] = "\u6B21\u306E\u3046\u30612\u3064\u3092\u9078\u3076//(\u7570\u306A\u308B\u3082\u306E\u3092\u9078\u3076):////\u300C|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u300D;//\u300C|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u300D;//\u300C|\uFF0B\uFF11\u3000\u8CFC\u5165|\u300D;\u300C|\uFF0B|[1]\u300D";
JapaneseCardTexts[CardNames.REPLACE] = " \u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002\u305D\u308C\u3088\u308A//\u30B3\u30B9\u30C8\u304C\u6700\u5927[2]\u9AD8\u3044\u30AB\u30FC\u30C91\u679A//\u3092\u7372\u5F97\u3059\u308B\u3002\u7372\u5F97\u3057\u305F\u306E\u304C\u30A2\u30AF//\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u304B\u8CA1\u5B9D\u30AB\u30FC\u30C9\u306E\u5834//\u5408\u3001\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u304F\u3002\u7372\u5F97\u3057\u305F//\u306E\u304C\u52DD\u5229\u70B9\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001\u4ED6\u306E//\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u546A\u30441\u679A\u3092//\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.SECRET_PASSAGE] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D1\u679A\u3092\u5C71\u672D\u306E\u597D\u304D\u306A//\u3068\u3053\u308D\u306B\u52A0\u3048\u308B\u3002";
JapaneseCardTexts[CardNames.SHANTY_TOWN] = "|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D\u3092\u516C\u958B\u3059\u308B\u3002\u305D\u306E\u4E2D\u306B//\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u304C1\u679A\u3082\u306A\u3044//\u5834\u5408\u3001|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.STEWARD] = "\u6B21\u306E\u3046\u30611\u3064\u3092\u9078\u3076://\u300C|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u300D;//\u300C|\uFF0B|[2]\u300D;//\u300C\u624B\u672D2\u679A\u3092\u5EC3\u68C4\u3059\u308B\u300D";
JapaneseCardTexts[CardNames.SWINDLER] = "|\uFF0B[2]|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E//\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u5EC3\u68C4\u3057\u3001\u305D\u308C//\u3068\u540C\u30B3\u30B9\u30C8\u306E\u3042\u306A\u305F\u304C\u9078\u629E\u3059\u308B//\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.TORTURER] = "|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001//\u6B21\u306E\u3046\u30611\u3064\u3092\u9078\u3076://\u300C\u624B\u672D2\u679A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u300D;//\u300C\u546A\u30441\u679A\u3092\u624B\u672D\u306B\u7372\u5F97\u3059\u308B\u300D";
JapaneseCardTexts[CardNames.TRADING_POST] = "\u624B\u672D2\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002//2\u679A\u3092\u5EC3\u68C4\u3057\u305F\u5834\u5408\u3001//\u9280\u8CA81\u679A\u3092\u624B\u672D\u306B//\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.UPGRADE] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002\u305D\u308C\u3088\u308A//\u30B3\u30B9\u30C8\u304C[1]\u9AD8\u3044\u30AB\u30FC\u30C91\u679A\u3092//\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.WISHING_WELL] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u30AB\u30FC\u30C91\u679A\u3092\u6307\u5B9A\u3057\u3001\u5C71\u672D\u306E\u4E00\u756A//\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u516C\u958B\u3059\u308B\u3002\u516C\u958B\u3057\u305F//\u30AB\u30FC\u30C9\u3092\u6307\u5B9A\u3057\u3066\u3044\u305F\u5834\u5408\u3001\u624B\u672D//\u306B\u52A0\u3048\u308B\u3002";

//Seaside
JapaneseCardTexts[CardNames.AMBASSADOR] = "\u624B\u672D1\u679A\u3092\u516C\u958B\u3059\u308B\u3002\u516C\u958B\u3057\u305F\u306E//\u3068\u540C\u3058\u30AB\u30FC\u30C9\u3092\u6700\u59272\u679A\u624B\u672D\u304B\u3089//\u30B5\u30D7\u30E9\u30A4\u306B\u623B\u3057\u3066\u3082\u3088\u3044\u3002\u4ED6\u306E//\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u305D\u308C\u3068\u540C\u3058//\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.BAZAAR] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B[1]|";
JapaneseCardTexts[CardNames.CARAVAN] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001//|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.CUTPURSE] = "|\uFF0B[2]|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u9285\u8CA81\u679A//\u3092\u6368\u3066\u672D\u306B\u3059\u308B(\u9285\u8CA8\u304C\u306A\u3044\u5834\u5408\u3001//\u624B\u672D\u3092\u516C\u958B\u3059\u308B)\u3002";
JapaneseCardTexts[CardNames.EMBARGO] = "|\uFF0B[2]|////\u3053\u308C\u3092\u5EC3\u68C4\u3059\u308B\u3002\u305D\u3046\u3057\u305F\u5834\u5408\u3001//\u30B5\u30D7\u30E9\u30A41\u5C71\u306E\u4E0A\u306B\u6291\u7559\u30C8\u30FC\u30AF\u30F3//1\u679A\u3092\u8FFD\u52A0\u3059\u308B(\u30B2\u30FC\u30E0\u7D42\u4E86\u307E\u3067\u3001//\u305D\u306E\u5C71\u304B\u3089\u30AB\u30FC\u30C91\u679A\u3092\u8CFC\u5165\u3059\u308B//\u3068\u304D\u3001\u546A\u30441\u679A\u3092\u7372\u5F97\u3059\u308B)\u3002";
JapaneseCardTexts[CardNames.EXPLORER] = "\u624B\u672D\u306E\u5C5E\u5DDE1\u679A\u3092\u516C\u958B\u3057\u3066\u3082\u3088\u3044\u3002//\u516C\u958B\u3057\u305F\u5834\u5408\u3001\u91D1\u8CA81\u679A\u3092\u624B\u672D\u306B//\u7372\u5F97\u3059\u308B\u3002\u516C\u958B\u3057\u306A\u304B\u3063\u305F\u5834\u5408\u3001//\u9280\u8CA81\u679A\u3092\u624B\u672D\u306B\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.FISHING_VILLAGE] = "|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B[1]|////\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u3001|\uFF0B|[1]\u3002";
JapaneseCardTexts[CardNames.GHOST_SHIP] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u624B\u672D\u304C//3\u679A\u306B\u306A\u308B\u3088\u3046\u306B\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.HAVEN] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D1\u679A\u3092(\u3053\u306E\u30AB\u30FC\u30C9\u306E)\u8107\u306B//\u4F0F\u305B\u3066\u7F6E\u304F\u3002\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3//\u306E\u958B\u59CB\u6642\u306B\u3001\u305D\u308C\u3092\u624B\u672D\u306B\u52A0\u3048\u308B\u3002";
JapaneseCardTexts[CardNames.ISLAND] = "\u3053\u308C\u3068\u624B\u672D1\u679A\u3092\u3042\u306A\u305F\u306E\u5CF6//\u30DE\u30C3\u30C8\u306E\u4E0A\u306B\u8868\u5411\u304D\u306B\u7F6E\u304F\u3002//---//{!2}";
JapaneseCardTexts[CardNames.LIGHTHOUSE] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u73FE\u5728\u3068\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E//\u958B\u59CB\u6642\u306B\u3001|\uFF0B|[1]\u3002////\u305D\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u307E\u3067\u3001\u4ED6\u306E//\u30D7\u30EC\u30A4\u30E4\u30FC\u304C\u30A2\u30BF\u30C3\u30AF\u30AB\u30FC\u30C9\u3092//\u4F7F\u7528\u3059\u308B\u3068\u304D\u3001\u305D\u306E\u5F71\u97FF\u3092\u53D7\u3051\u306A\u3044\u3002";
JapaneseCardTexts[CardNames.LOOKOUT] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u5C71\u672D\u306E\u4E0A\u304B\u30893\u679A\u3092\u898B\u308B\u3002\u305D\u306E\u4E2D//\u306E1\u679A\u3092\u5EC3\u68C4\u3057\u3066\u30011\u679A\u3092\u6368\u3066\u672D//\u306B\u3059\u308B\u3002\u6B8B\u308A\u306E1\u679A\u3092\u5C71\u672D\u306E\u4E0A\u306B//\u623B\u3059\u3002";
JapaneseCardTexts[CardNames.MERCHANT_SHIP] = "\u73FE\u5728\u3068\u3042\u306A\u305F\u306E\u6B21\u306E//\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001//|\uFF0B|[2]\u3002";
JapaneseCardTexts[CardNames.NATIVE_VILLAGE] = "|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u6B21\u306E\u3046\u30611\u3064\u3092\u9078\u3076:\u300C\u5C71\u672D\u306E\u4E00\u756A\u4E0A//\u306E\u30AB\u30FC\u30C9\u3092\u3001\u3042\u306A\u305F\u306E\u539F\u4F4F\u6C11\u306E\u6751//\u30DE\u30C3\u30C8\u306E\u4E0A\u306B\u4F0F\u305B\u3066\u7F6E\u304F(\u3042\u306A\u305F\u306F//\u898B\u3066\u3082\u3088\u3044)\u300D;\u300C\u3042\u306A\u305F\u306E\u539F\u4F4F\u6C11\u306E//\u6751\u30DE\u30C3\u30C8\u306E\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u3059\u3079\u3066\u624B\u672D//\u306B\u52A0\u3048\u308B\u300D";
JapaneseCardTexts[CardNames.NAVIGATOR] = "|\uFF0B[2]|////\u5C71\u672D\u306E\u4E0A\u304B\u30895\u679A\u3092\u898B\u308B\u3002//\u305D\u308C\u3089\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u304B\u3001//\u597D\u304D\u306A\u9806\u756A\u3067\u5C71\u672D\u306E\u4E0A\u306B\u623B\u3059\u3002";
JapaneseCardTexts[CardNames.OUTPOST] = "\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u624B\u672D\u3092\u5F15\u304F\u3068//\u304D\u30013\u679A\u306E\u307F\u30AB\u30FC\u30C9\u3092\u5F15\u304F\u3002////\u3053\u306E\u30BF\u30FC\u30F3\u306E\u5F8C\u306B\u8FFD\u52A0\u306E//1\u30BF\u30FC\u30F3\u3092\u5F97\u308B(\u305F\u3060\u3057\u3001//\u9023\u7D9A3\u30BF\u30FC\u30F3\u3068\u306A\u308B\u5834\u5408//\u306F\u5F97\u3089\u308C\u306A\u3044)\u3002";
JapaneseCardTexts[CardNames.PEARL_DIVER] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u5C71\u672D\u306E\u4E00\u756A\u4E0B\u306E\u30AB\u30FC\u30C9\u3092\u898B\u308B\u3002//\u305D\u308C\u3092\u4E00\u756A\u4E0A\u306B\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.PIRATE_SHIP] = " \u6B21\u306E\u3046\u30611\u3064\u3092\u9078\u3076:\u300C\u3042\u306A\u305F\u306E\u6D77\u8CCA\u8239//\u30DE\u30C3\u30C8\u306E\u4E0A\u306E\u6D77\u8CCA\u8239\u30C8\u30FC\u30AF\u30F31\u679A\u306B\u3064\u304D//|\uFF0B|[1]\u300D;\u300C\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D//\u306E\u4E0A\u304B\u30892\u679A\u3092\u516C\u958B\u3057\u3001\u305D\u306E\u4E2D\u306E\u3042\u306A\u305F\u304C//\u9078\u629E\u3059\u308B\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u3001\u6B8B\u308A\u3092//\u6368\u3066\u672D\u306B\u3059\u308B\u3002\u8AB0\u304B\u304C\u8CA1\u5B9D\u30AB\u30FC\u30C9\u3092\u5EC3\u68C4//\u3057\u305F\u5834\u5408\u3001\u3042\u306A\u305F\u306F\u81EA\u5206\u306E\u6D77\u8CCA\u8239\u30DE\u30C3\u30C8//\u306E\u4E0A\u306B\u6D77\u8CCA\u8239\u30C8\u30FC\u30AF\u30F31\u679A\u3092\u8FFD\u52A0\u3059\u308B\u300D";
JapaneseCardTexts[CardNames.SALVAGER] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|//////\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002////\u305D\u306E\u30B3\u30B9\u30C8[1]\u306B\u3064\u304D\u3001//|\uFF0B|[1]\u3002";
JapaneseCardTexts[CardNames.SEA_HAG] = "\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E//\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u6368\u3066\u672D\u306B\u3057\u3001//\u546A\u30441\u679A\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.SMUGGLERS] = "\u53F3\u96A3\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306E\u6700\u5F8C\u306E\u30BF\u30FC\u30F3//\u306B\u305D\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u304C\u7372\u5F97\u3057\u305F\u3001//\u30B3\u30B9\u30C8[6]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97//\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.TACTICIAN] = "\u624B\u672D\u304C\u3042\u308B\u5834\u5408\u3001\u3059\u3079\u3066\u6368\u3066\u672D//\u306B\u3057\u3066\u3001\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E//\u958B\u59CB\u6642\u306B\u3001|\uFF0B\uFF15\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3001//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u3001|\uFF0B\uFF11\u3000\u8CFC\u5165|\u3002";
JapaneseCardTexts[CardNames.TREASURE_MAP] = "\u3053\u308C\u3068\u624B\u672D\u306E\u5B9D\u306E\u5730\u56F3\u3092\u5EC3\u68C4//\u3059\u308B\u30022\u679A\u306E\u5B9D\u306E\u5730\u56F3\u3092\u5EC3\u68C4//\u3057\u305F\u5834\u5408\u3001\u91D1\u8CA84\u679A\u3092\u5C71\u672D\u306E//\u4E0A\u306B\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.TREASURY] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B[1]|////\u3053\u306E\u30BF\u30FC\u30F3\u3001\u3042\u306A\u305F\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA//\u306E\u7D42\u4E86\u6642\u3001\u305D\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA\u3067\u52DD\u5229//\u70B9\u30AB\u30FC\u30C9\u3092\u7372\u5F97\u3057\u306A\u304B\u3063\u305F\u5834\u5408\u3001//\u3053\u308C\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.WAREHOUSE] = "|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//// \u624B\u672D3\u679A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002 ";
JapaneseCardTexts[CardNames.WHARF] = " \u73FE\u5728\u3068\u3042\u306A\u305F\u306E\u6B21\u306E//\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001////|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u8CFC\u5165|";

//Alchemy
JapaneseCardTexts[CardNames.ALCHEMIST] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u3053\u306E\u30BF\u30FC\u30F3\u3001\u30AF\u30EA\u30FC\u30F3\u30A2\u30C3\u30D7//\u30D5\u30A7\u30A4\u30BA\u306E\u958B\u59CB\u6642\u306B\u3001\u5834\u306B\u30DD\u30FC//\u30B7\u30E7\u30F3\u3092\u51FA\u3057\u3066\u3044\u308B\u5834\u5408\u3001\u3053\u308C//\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.APOTHECARY] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//// \u5C71\u672D\u306E\u4E0A\u304B\u30894\u679A\u3092\u516C\u958B\u3059\u308B\u3002//\u516C\u958B\u3057\u305F\u9285\u8CA8\u3068\u30DD\u30FC\u30B7\u30E7\u30F3\u3092//\u624B\u672D\u306B\u52A0\u3048\u308B\u3002\u6B8B\u308A\u3092\u597D\u304D\u306A//\u9806\u756A\u3067\u5C71\u672D\u306E\u4E0A\u306B\u623B\u3059\u3002";
JapaneseCardTexts[CardNames.APPRENTICE] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002////    \u305D\u306E\u30B3\u30B9\u30C8[1]\u306B\u3064\u304D    //|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002//\u30B3\u30B9\u30C8\u306B[P]\u304C\u3042\u308B\u5834\u5408\u3001//|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.FAMILIAR] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001//\u546A\u30441\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.GOLEM] = "\u30B4\u30FC\u30EC\u30E0\u4EE5\u5916\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9//2\u679A\u304C\u516C\u958B\u3055\u308C\u308B\u307E\u3067\u5C71\u672D\u3092\u4E0A\u304B\u3089//\u516C\u958B\u3059\u308B\u3002\u516C\u958B\u3057\u305F\u4ED6\u306E\u30AB\u30FC\u30C9\u3092//\u6368\u3066\u672D\u306B\u3059\u308B\u3002\u305D\u306E\u30A2\u30AF\u30B7\u30E7\u30F3//\u30AB\u30FC\u30C92\u679A\u3092\u597D\u304D\u306A\u9806\u756A\u3067\u4F7F\u7528\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.HERBALIST] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|//|\uFF0B[1]|//\u3053\u306E\u30BF\u30FC\u30F3\u3001\u8CA1\u5B9D\u30AB\u30FC\u30C9//1\u679A\u3092\u5834\u304B\u3089\u6368\u3066\u672D\u306B\u3057//\u305F\u3068\u304D\u3001\u305D\u308C\u3092\u5C71\u672D\u306E\u4E0A//\u306B\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.PHILOSOPHERS_STONE] = "\u5C71\u672D\u3068\u6368\u3066\u672D\u306E\u5408\u8A08\u679A\u6570\u3092//\u6570\u3048\u30015\u679A(\u7AEF\u6570\u5207\u308A\u6368\u3066)//\u306B\u3064\u304D\u3001|+|[1]\u3002";
JapaneseCardTexts[CardNames.POSSESSION] = "\u5DE6\u96A3\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u3053\u306E\u30BF\u30FC\u30F3\u306E\u5F8C\u306B//\u8FFD\u52A0\u306E1\u30BF\u30FC\u30F3\u3092\u5F97\u308B(\u305F\u3060\u3057\u30012\u9023\u7D9A\u3067//\u8FFD\u52A0\u30BF\u30FC\u30F3\u3068\u306A\u308B\u5834\u5408\u306F\u5F97\u3089\u308C\u306A\u3044)\u3002//\u305D\u306E\u30BF\u30FC\u30F3\u3001\u3042\u306A\u305F\u306F\u305D\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u304C//\u898B\u308B\u3053\u3068\u306E\u3067\u304D\u308B\u30AB\u30FC\u30C9\u3092\u3059\u3079\u3066\u898B\u308B//\u3053\u3068\u304C\u3067\u304D\u3001\u305D\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306E\u4EE3\u308F\u308A\u306B//\u3059\u3079\u3066\u3092\u6C7A\u5B9A\u3059\u308B\u3002\u307E\u305F\u3001\u305D\u306E\u30D7\u30EC\u30A4\u30E4\u30FC//\u304C\u7372\u5F97\u3057\u3088\u3046\u3068\u3059\u308B\u30AB\u30FC\u30C9\u3068[D]\u306F\u3042\u306A\u305F//\u304C\u4EE3\u308F\u308A\u306B\u7372\u5F97\u3059\u308B\u3002\u307E\u305F\u3001\u5EC3\u68C4\u3055\u308C\u308B//\u305D\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306E\u30AB\u30FC\u30C9\u306F\u8107\u306B\u7F6E\u304D\u3001//\u30BF\u30FC\u30F3\u7D42\u4E86\u6642\u306B\u6368\u3066\u672D\u7F6E\u304D\u5834\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.SCRYING_POOL] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E\u4E00\u756A\u4E0A//\u306E\u30AB\u30FC\u30C9\u3092\u516C\u958B\u3057\u3001\u3042\u306A\u305F\u306E\u9078\u629E//\u306B\u3088\u308A\u3001\u6368\u3066\u672D\u306B\u3059\u308B\u304B\u5C71\u672D\u306E\u4E0A\u306B//\u623B\u3059\u3002\u305D\u306E\u5F8C\u3001\u3042\u306A\u305F\u306F\u30A2\u30AF\u30B7\u30E7\u30F3//\u30AB\u30FC\u30C9\u4EE5\u5916\u306E\u30AB\u30FC\u30C91\u679A\u304C\u516C\u958B\u3055\u308C //\u308B\u307E\u3067\u5C71\u672D\u3092\u4E0A\u304B\u3089\u516C\u958B\u3059\u308B\u3002\u516C\u958B//\u3057\u305F\u30AB\u30FC\u30C9\u3092\u3059\u3079\u3066\u624B\u672D\u306B\u52A0\u3048\u308B\u3002";
JapaneseCardTexts[CardNames.TRANSMUTE] = " \u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002\u305D\u308C\u304C...//\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001//\u516C\u98181\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//\u8CA1\u5B9D\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001\u5909\u6210//1\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//\u52DD\u5229\u70B9\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001\u91D1\u8CA8//1\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.UNIVERSITY] = "|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u30B3\u30B9\u30C8[5]\u4EE5\u4E0B\u306E\u30A2\u30AF\u30B7\u30E7\u30F3//\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.VINEYARD] = "\u3042\u306A\u305F\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C93\u679A//(\u7AEF\u6570\u5207\u308A\u6368\u3066)\u306B\u3064\u304D{1}\u3002";

JapaneseCardTexts[CardNames.POTION] = "";

//Propserity
JapaneseCardTexts[CardNames.BANK] = "\u3053\u308C\u3082\u542B\u3081\u305F\u3042\u306A\u305F\u306E\u5834\u306E\u8CA1\u5B9D//\u30AB\u30FC\u30C91\u679A\u306B\u3064\u304D|\uFF0B|[1]\u3002";
JapaneseCardTexts[CardNames.BISHOP] = "|\uFF0B[1]|//|\uFF0B{1}|////\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002\u305D\u306E\u30B3\u30B9\u30C8//[2](\u7AEF\u6570\u5207\u308A\u6368\u3066) \u306B\u3064\u304D|\uFF0B|{1}\u3002//\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u624B\u672D//1\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.CONTRABAND] = "[!3]//|\uFF0B\uFF11\u3000\u8CFC\u5165|//\u5DE6\u96A3\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u30AB\u30FC\u30C91\u679A\u3092//\u6307\u5B9A\u3059\u308B\u3002\u3053\u306E\u30BF\u30FC\u30F3\u3001\u3042\u306A\u305F//\u306F\u305D\u306E\u30AB\u30FC\u30C9\u3092\u8CFC\u5165\u3067\u304D\u306A\u3044\u3002";
JapaneseCardTexts[CardNames.COUNTING_HOUSE] = "\u6368\u3066\u672D\u7F6E\u304D\u5834\u306E\u30AB\u30FC\u30C9\u3092\u3059\u3079\u3066//\u898B\u3066\u3001\u305D\u306E\u4E2D\u306E\u597D\u304D\u306A\u679A\u6570\u306E//\u9285\u8CA8\u3092\u516C\u958B\u3057\u3001\u624B\u672D\u306B\u52A0\u3048\u308B\u3002";
JapaneseCardTexts[CardNames.CITY] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u30B5\u30D7\u30E9\u30A4\u306B\u7A7A\u306E\u5C71\u304C\u3042\u308B\u5834\u5408\u3001//\u8FFD\u52A0\u3067|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002//2\u5C71\u4EE5\u4E0A\u7A7A\u306E\u5834\u5408\u3001\u3055\u3089\u306B\u8FFD\u52A0//\u3067|\uFF0B\uFF11\u3000\u8CFC\u5165|\u3001|\uFF0B|[1]\u3002";
JapaneseCardTexts[CardNames.EXPAND] = "\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002//\u305D\u308C\u3088\u308A\u30B3\u30B9\u30C8\u304C\u6700\u5927//[3]\u9AD8\u3044\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97//\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.FORGE] = "\u624B\u672D\u304B\u3089\u597D\u304D\u306A\u679A\u6570\u306E\u30AB\u30FC\u30C9\u3092//\u5EC3\u68C4\u3059\u308B\u3002\u5EC3\u68C4\u3057\u305F\u30AB\u30FC\u30C9\u306E[]//\u30B3\u30B9\u30C8\u306E\u5408\u8A08\u3068\u540C\u30B3\u30B9\u30C8\u306E\u30AB\u30FC\u30C9//1\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.GRAND_MARKET] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B\uFF11\u3000\u8CFC\u5165|//|\uFF0B[2]|//---//\u9285\u8CA8\u3092\u5834\u306B\u51FA\u3057\u3066\u3044\u308B\u9593\u3001\u3053\u306E//\u30AB\u30FC\u30C9\u3092\u8CFC\u5165\u3067\u304D\u306A\u3044\u3002";
JapaneseCardTexts[CardNames.GOONS] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|//|\uFF0B[2]|//\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u624B\u672D\u304C//3\u679A\u306B\u306A\u308B\u3088\u3046\u306B\u6368\u3066\u672D\u306B\u3059\u308B\u3002//---//\u3053\u308C\u3092\u5834\u306B\u51FA\u3057\u3066\u3044\u308B\u9593\u3001\u30AB\u30FC\u30C9//1\u679A\u3092\u8CFC\u5165\u3059\u308B\u3068\u304D\u3001|\uFF0B|{1}\u3002";
JapaneseCardTexts[CardNames.HOARD] = "[!2]////\u3053\u306E\u30BF\u30FC\u30F3\u3001\u52DD\u5229\u70B9\u30AB\u30FC\u30C91\u679A\u3092//\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u305D\u308C\u304C\u8CFC\u5165\u3057\u305F//\u3082\u306E\u306E\u5834\u5408\u3001\u91D1\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.KINGS_COURT] = "\u624B\u672D\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9 //1\u679A\u30923\u56DE\u4F7F\u7528\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.LOAN] = "[!1]//\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A\u304C\u516C\u958B\u3055\u308C\u308B\u307E\u3067//\u5C71\u672D\u3092\u4E0A\u304B\u3089\u516C\u958B\u3059\u308B\u3002\u305D\u306E1\u679A//\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u304B\u5EC3\u68C4\u3059\u308B\u3002\u516C\u958B//\u3057\u305F\u4ED6\u306E\u30AB\u30FC\u30C9\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.MINT] = "\u624B\u672D\u306E\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A\u3092\u516C\u958B\u3057\u3066//\u3082\u3088\u3044\u3002\u305D\u308C\u3068\u540C\u3058\u30AB\u30FC\u30C91\u679A\u3092//\u7372\u5F97\u3059\u308B\u3002//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u5834\u306E\u6301\u7D9A//\u3067\u306A\u3044\u8CA1\u5B9D\u30AB\u30FC\u30C9\u3092\u3059\u3079\u3066\u5EC3\u68C4//\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.MONUMENT] = "|\uFF0B[2]|//|\uFF0B{1}|";
JapaneseCardTexts[CardNames.MOUNTEBANK] = "|\uFF0B[2]|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u546A\u30441\u679A//\u3092\u6368\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u304F\u3001\u6368\u3066\u672D//\u306B\u3057\u306A\u304B\u3063\u305F\u5834\u5408\u3001\u546A\u30441\u679A\u3068//\u9285\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.PEDDLER] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B[1]|//---//\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA\u4E2D\u3001\u3053\u306E\u30AB\u30FC\u30C9\u306E\u30B3\u30B9\u30C8//\u306F\u30BF\u30FC\u30F3\u30D7\u30EC\u30A4\u30E4\u30FC\u306E\u5834\u306E\u30A2\u30AF\u30B7\u30E7\u30F3//\u30AB\u30FC\u30C91\u679A\u306B\u3064\u304D[2]\u4E0B\u304C\u308B\u3002";
JapaneseCardTexts[CardNames.QUARRY] = "[!1]////\u3053\u306E\u30BF\u30FC\u30F3\u3001\u30A2\u30AF\u30B7\u30E7\u30F3//\u30AB\u30FC\u30C9\u306E\u30B3\u30B9\u30C8\u306F[2]\u4E0B\u304C\u308B\u3002";
JapaneseCardTexts[CardNames.RABBLE] = "|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E//\u4E0A\u304B\u30893\u679A\u3092\u516C\u958B\u3057\u3001\u30A2\u30AF\u30B7\u30E7\u30F3//\u30AB\u30FC\u30C9\u3068\u8CA1\u5B9D\u30AB\u30FC\u30C9\u3092\u6368\u3066\u672D\u306B\u3057\u3001//\u6B8B\u308A\u3092\u597D\u304D\u306A\u9806\u756A\u3067\u5C71\u672D\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.ROYAL_SEAL] = "[!2]//---//\u3053\u308C\u3092\u5834\u306B\u51FA\u3057\u3066\u3044\u308B\u9593\u3001\u30AB\u30FC\u30C9//1\u679A\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u305D\u308C\u3092\u5C71\u672D//\u306E\u4E0A\u306B\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002"; //
JapaneseCardTexts[CardNames.TALISMAN] = "[!1]//---//\u3053\u308C\u3092\u5834\u306B\u51FA\u3057\u3066\u3044\u308B\u9593\u3001\u30B3\u30B9\u30C8//[4]\u4EE5\u4E0B\u306E\u52DD\u5229\u70B9\u4EE5\u5916\u306E\u30AB\u30FC\u30C91\u679A//\u3092\u8CFC\u5165\u3059\u308B\u3068\u304D\u3001\u305D\u308C\u3068\u540C\u3058\u30AB\u30FC//\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.TRADE_ROUTE] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|//\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002\u4EA4\u6613\u8DEF\u30DE\u30C3\u30C8\u306E//\u4E0A\u306E\u4EA4\u6613\u8DEF\u30C8\u30FC\u30AF\u30F31\u679A\u306B\u3064\u304D|\uFF0B|[1]\u3002//---//\u6E96\u5099:\u52DD\u5229\u70B9\u30AB\u30FC\u30C9\u306E\u30B5\u30D7\u30E9\u30A4\u306E\u5C71\u3059\u3079\u3066//\u306E\u4E0A\u306B\u4EA4\u6613\u8DEF\u30C8\u30FC\u30AF\u30F31\u679A\u3092\u8FFD\u52A0\u3059\u308B\u3002\u305D//\u306E\u5C71\u306E\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u4EA4\u6613\u8DEF//\u30C8\u30FC\u30AF\u30F3\u3092\u4EA4\u6613\u8DEF\u30DE\u30C3\u30C8\u306E\u4E0A\u306B\u79FB\u52D5\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.VAULT] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////\u624B\u672D\u3092\u597D\u304D\u306A\u679A\u6570\u6368\u3066\u672D\u306B//\u3057\u30011\u679A\u306B\u3064\u304D|\uFF0B|[1]\u3002\u4ED6\u306E\u30D7//\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u30011\u679A\u30AB\u30FC\u30C9//\u3092\u5F15\u304F\u305F\u3081\u306B\u3001\u624B\u672D2\u679A\u3092\u6368//\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.VENTURE] = "[!1]//\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A\u304C\u516C\u958B\u3055\u308C\u308B\u307E\u3067//\u5C71\u672D\u3092\u4E0A\u304B\u3089\u516C\u958B\u3059\u308B\u3002\u516C\u958B\u3057\u305F//\u4ED6\u306E\u30AB\u30FC\u30C9\u3092\u6368\u3066\u672D\u306B\u3057\u3001\u305D\u306E//\u8CA1\u5B9D\u30AB\u30FC\u30C9\u3092\u4F7F\u7528\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.WATCHTOWER] = "\u624B\u672D\u304C6\u679A\u306B\u306A\u308B\u3088\u3046\u306B//\u30AB\u30FC\u30C9\u3092\u5F15\u304F\u3002//---// \u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001//\u624B\u672D\u304B\u3089\u3053\u308C\u3092\u516C\u958B\u3057\u3066\u3082//\u3088\u3044\u3002\u516C\u958B\u3057\u305F\u5834\u5408\u3001\u7372\u5F97//\u3057\u305F\u30AB\u30FC\u30C9\u3092\u5EC3\u68C4\u3059\u308B\u304B\u3001//\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.WORKERS_VILLAGE] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B\uFF11\u3000\u8CFC\u5165|";

JapaneseCardTexts[CardNames.COLONY] = "";
JapaneseCardTexts[CardNames.PLATINUM] = "";

//Cornucopia
JapaneseCardTexts[CardNames.FAIRGROUNDS] = "\u3042\u306A\u305F\u306E\u30AB\u30FC\u30C95\u7A2E\u985E//(\u7AEF\u6570\u5207\u308A\u6368\u3066)\u306B\u3064\u304D//{2}\u3002";
JapaneseCardTexts[CardNames.FARMING_VILLAGE] = "|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u8CA1\u5B9D\u30AB\u30FC\u30C9\u304B\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9//1\u679A\u304C\u516C\u958B\u3055\u308C\u308B\u307E\u3067\u5C71\u672D\u3092\u4E0A//\u304B\u3089\u516C\u958B\u3059\u308B\u3002\u305D\u306E1\u679A\u3092\u624B\u672D//\u306B\u52A0\u3048\u3001\u6B8B\u308A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.FORTUNE_TELLER] = "|\uFF0B[2]|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u52DD\u5229\u70B9//\u30AB\u30FC\u30C9\u304B\u546A\u30441\u679A\u304C\u516C\u958B\u3055\u308C\u308B//\u307E\u3067\u5C71\u672D\u3092\u4E0A\u304B\u3089\u516C\u958B\u3057\u3001\u305D\u306E//1\u679A\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u304D\u3001\u6B8B\u308A\u3092//\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.HAMLET] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u306E\u305F\u3081\u306B\u3001//\u624B\u672D1\u679A\u3092\u6368\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002//\u305D\u306E\u5F8C\u3001|\uFF0B\uFF11\u3000\u8CFC\u5165|\u306E\u305F\u3081\u306B\u3001//\u624B\u672D1\u679A\u3092\u6368\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.HARVEST] = "\u5C71\u672D\u306E\u4E0A\u304B\u30894\u679A\u3092\u516C\u958B\u3057\u3001//\u6368\u3066\u672D\u306B\u3059\u308B\u3002\u516C\u958B\u3057\u305F//\u30AB\u30FC\u30C91\u7A2E\u985E\u306B\u3064\u304D|\uFF0B|[1]\u3002";
JapaneseCardTexts[CardNames.HORSE_TRADERS] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|//|\uFF0B[3]|//\u624B\u672D2\u679A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002//---//\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u304C\u30A2\u30BF\u30C3\u30AF\u30AB\u30FC\u30C9\u3092\u4F7F\u7528//\u3059\u308B\u3068\u304D\u3001\u305D\u306E\u89E3\u6C7A\u524D\u306B\u3001\u624B\u672D\u304B\u3089\u3053\u308C//\u3092\u8107\u306B\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002\u8107\u306B\u7F6E\u3044\u305F\u5834\u5408\u3001//\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001|\uFF0B\uFF11\u3000|//|\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3001\u3053\u308C\u3092\u624B\u672D\u306B\u623B\u3059\u3002";
JapaneseCardTexts[CardNames.HORN_OF_PLENTY] = "\u5834\u306B\u51FA\u3057\u3066\u3044\u308B\u30AB\u30FC\u30C9\u306E\u7A2E\u985E\u3092//(\u3053\u308C\u3082\u542B\u3081\u3066) \u6570\u3048\u30011\u7A2E\u985E//\u306B\u3064\u304D\u30B3\u30B9\u30C8[1]\u3068\u3057\u3066\u3001\u5408\u8A08//\u4EE5\u4E0B\u306E\u30B3\u30B9\u30C8\u306E\u30AB\u30FC\u30C91\u679A\u3092//\u7372\u5F97\u3059\u308B\u3002\u305D\u308C\u304C\u52DD\u5229\u70B9\u30AB\u30FC//\u30C9\u306E\u5834\u5408\u3001\u3053\u308C\u3092\u5EC3\u68C4\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.HUNTING_PARTY] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D\u3092\u516C\u958B\u3059\u308B\u3002\u624B\u672D\u306E\u3044\u305A\u308C\u3068\u3082//\u7570\u306A\u308B\u30AB\u30FC\u30C91\u679A\u304C\u516C\u958B\u3055\u308C\u308B\u307E\u3067//\u5C71\u672D\u3092\u4E0A\u304B\u3089\u516C\u958B\u3059\u308B\u3002\u305D\u306E1\u679A\u3092//\u624B\u672D\u306B\u52A0\u3048\u3001\u6B8B\u308A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.JESTER] = "|\uFF0B[2]|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E\u4E00\u756A\u4E0A//\u306E\u30AB\u30FC\u30C9\u3092\u6368\u3066\u672D\u306B\u3057\u3001\u305D\u308C\u304C\u52DD\u5229\u70B9//\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001\u546A\u30441\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//\u305D\u308C\u4EE5\u5916\u306E\u5834\u5408\u3001\u305D\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u304B//\u3042\u306A\u305F\u306E\u3046\u3061\u3001\u3042\u306A\u305F\u306E\u9078\u3093\u3060\u65B9\u304C\u3001//\u305D\u306E\u6368\u3066\u672D\u306B\u306A\u3063\u305F\u30AB\u30FC\u30C9\u3068\u540C\u30581\u679A//\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.MENAGERIE] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D\u3092\u516C\u958B\u3059\u308B\u3002\u305D\u306E\u4E2D//  \u306B\u540C\u3058\u30AB\u30FC\u30C9\u304C\u306A\u3044\u5834\u5408\u3001//|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002//\u305D\u308C\u4EE5\u5916\u306E\u5834\u5408\u3001//|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.REMAKE] = "\u4EE5\u4E0B\u30922\u5EA6\u5B9F\u884C\u3059\u308B:////\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3057\u3001//\u305D\u308C\u3088\u308A\u30B3\u30B9\u30C8\u304C //[1]\u591A\u3044\u30AB\u30FC\u30C91\u679A\u3092//\u7372\u5F97\u3059\u308B\u3002//";
JapaneseCardTexts[CardNames.TOURNAMENT] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u624B\u672D\u306E\u5C5E\u5DDE//1\u679A\u3092\u516C\u958B\u3057\u3066\u3082\u3088\u3044\u3002\u3042\u306A\u305F\u304C//\u516C\u958B\u3057\u305F\u5834\u5408\u3001\u305D\u308C\u3092\u6368\u3066\u672D\u306B//\u3057\u3001(\u8912\u8CDE\u306E\u5C71\u304B\u3089)\u8912\u8CDE\u30AB\u30FC\u30C9//1\u679A\u304B\u3001\u516C\u98181\u679A\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7372//\u5F97\u3059\u308B\u3002\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u304C\u8AB0\u3082//\u516C\u958B\u3057\u306A\u304B\u3063\u305F\u5834\u5408\u3001//|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3001|\uFF0B|[1]\u3002";
JapaneseCardTexts[CardNames.YOUNG_WITCH] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////\u624B\u672D2\u679A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u624B\u672D\u306E\u707D\u3044//\u30AB\u30FC\u30C91\u679A\u3092\u516C\u958B\u3057\u306A\u3044\u5834\u5408\u3001\u546A\u3044//1\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//---//\u6E96\u5099:\u30B3\u30B9\u30C8[2]\u307E\u305F\u306F[3]\u306E\u738B\u56FD//\u30AB\u30FC\u30C9\u306E\u5C71\u3092\u8FFD\u52A0\u3067\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304F\u3002//\u305D\u306E\u5C71\u306E\u30AB\u30FC\u30C9\u3092\u707D\u3044\u30AB\u30FC\u30C9\u3068\u3059\u308B\u3002";

JapaneseCardTexts[CardNames.PRIZE_PILE] = "";
JapaneseCardTexts[CardNames.BAG_OF_GOLD] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u91D1\u8CA81\u679A\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7372\u5F97\u3059\u308B\u3002////////////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";
JapaneseCardTexts[CardNames.DIADEM] = "[!2]//////\u672A\u4F7F\u7528\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u6A291\u3064\u306B\u3064\u304D\u3001//|\uFF0B|[1]\u3002//////////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";
JapaneseCardTexts[CardNames.FOLLOWERS] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////\u5C4B\u65771\u679A\u3092\u7372\u5F97\u3059\u308B\u3002////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u546A\u30441\u679A\u3092//\u7372\u5F97\u3057\u3001\u624B\u672D\u304C3\u679A\u306B\u306A\u308B\u3088\u3046\u306B//\u6368\u3066\u672D\u306B\u3059\u308B\u3002////////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";
JapaneseCardTexts[CardNames.PRINCESS] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|////\u3053\u306E\u30BF\u30FC\u30F3\u3001\u3059\u3079\u3066\u306E\u30AB\u30FC\u30C9\u306E//\u30B3\u30B9\u30C8\u306F[2]\u4E0B\u304C\u308B\u3002////////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";
JapaneseCardTexts[CardNames.TRUSTY_STEED] = " \u6B21\u306E\u3046\u30612\u3064\u3092\u9078\u3076(\u7570\u306A\u308B\u3082\u306E//\u3092\u9078\u3076):\u300C|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u300D;//\u300C|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u300D;\u300C|\uFF0B|[2]\u300D;//\u300C\u9280\u8CA84\u679A\u3092\u7372\u5F97\u3057\u3001\u5C71\u672D\u3092\u6368\u3066//\u672D\u7F6E\u304D\u5834\u306B\u7F6E\u304F\u300D////////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";

//Hinterlands
JapaneseCardTexts[CardNames.BORDER_VILLAGE] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u3053\u308C\u3088\u308A//\u5B89\u3044\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.CACHE] = "[!3]//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001//\u9285\u8CA82\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.CARTOGRAPHER] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u5C71\u672D\u306E\u4E0A\u304B\u30894\u679A\u3092\u898B\u308B\u3002\u305D\u306E\u4E2D//\u306E\u597D\u304D\u306A\u679A\u6570\u3092\u6368\u3066\u672D\u306B\u3057\u3001\u6B8B\u308A//\u3092\u597D\u304D\u306A\u9806\u756A\u3067\u5C71\u672D\u306E\u4E0A\u306B\u623B\u3059\u3002";
JapaneseCardTexts[CardNames.CROSSROADS] = "\u624B\u672D\u3092\u516C\u958B\u3057\u3001\u305D\u306E\u4E2D\u306E// \u52DD\u5229\u70B9\u30AB\u30FC\u30C91\u679A\u306B\u3064\u304D\u3001//|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002//\u3053\u308C\u304C\u3053\u306E\u30BF\u30FC\u30F3\u306B\u521D\u3081//\u3066\u4F7F\u7528\u3057\u305F\u5C90\u8DEF\u306E\u5834\u5408\u3001//|\uFF0B\uFF13\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u3002";
JapaneseCardTexts[CardNames.DEVELOP] = "\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002\u305D\u308C\u3088\u308A//\u30B3\u30B9\u30C8\u304C[1]\u9AD8\u3044\u30AB\u30FC\u30C91\u679A\u3001//[1]\u4F4E\u3044\u30AB\u30FC\u30C91\u679A\u3092\u597D\u304D\u306A//\u9806\u756A\u3067\u5C71\u672D\u306E\u4E0A\u306B\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.DUCHESS] = "|\uFF0B[2]|//\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E\u4E00\u756A\u4E0A//\u306E\u30AB\u30FC\u30C9\u3092\u898B\u3066\u3001\u305D\u306E\u5F8C\u3001\u305D\u308C\u3092//\u6368\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002//---//\u516C\u7235\u592B\u4EBA\u3092\u4F7F\u3046\u30B2\u30FC\u30E0\u3067\u306F\u3001\u516C\u9818//1\u679A\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u516C\u7235\u592B\u4EBA1\u679A//\u3092\u7372\u5F97\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.EMBASSY] = "|\uFF0B\uFF15\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////\u624B\u672D3\u679A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u4ED6\u306E\u30D7\u30EC//\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u9280\u8CA81\u679A\u3092\u7372\u5F97//\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.FARMLAND] = "{!2}//---//これを獲得したとき、手札1枚//を廃棄し、それよりコストが//[2]高い農地以外のカード1枚を//獲得する。";
JapaneseCardTexts[CardNames.FOOLS_GOLD] = "\u3053\u308C\u304C\u3053\u306E\u30BF\u30FC\u30F3\u306B\u521D\u3081\u3066  //\u4F7F\u7528\u3057\u305F\u611A\u8005\u306E\u9EC4\u91D1\u306E\u5834\u5408\u3001//|\uFF0B|[1]\u3001\u305D\u308C\u4EE5\u5916\u306E\u5834\u5408\u3001|\uFF0B|[4]\u3002//---//\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u304C\u5C5E\u5DDE1\u679A\u3092\u7372\u5F97//\u3057\u305F\u3068\u304D\u3001\u624B\u672D\u304B\u3089\u3053\u308C\u3092\u5EC3\u68C4//\u3057\u3066\u3082\u3088\u3044\u3002\u5EC3\u68C4\u3057\u305F\u5834\u5408\u3001\u91D1//\u8CA81\u679A\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.HAGGLER] = "|＋[2]|////このターン、カード1枚を獲得//したとき、それが購入したもの//の場合、それより安く勝利点で//ないカード1枚を獲得する。";
JapaneseCardTexts[CardNames.HIGHWAY] = "|＋１　カードを引く|//|＋１　アクション|////このターン、すべてのカード//のコストは[1]下がる。";
JapaneseCardTexts[CardNames.ILL_GOTTEN_GAINS] = "[!1]////\u9285\u8CA81\u679A\u3092\u624B\u672D\u306B\u7372\u5F97\u3057\u3066\u3082\u3088\u3044\u3002//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u4ED6\u306E\u30D7\u30EC\u30A4//\u30E4\u30FC\u306F\u5168\u54E1\u3001\u546A\u30441\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.INN] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D2\u679A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u6368\u3066\u672D\u7F6E\u304D\u5834//\u306E\u30AB\u30FC\u30C9\u3092\u3059\u3079\u3066\u898B\u3066\u3001\u305D\u306E\u4E2D\u306E//\u597D\u304D\u306A\u679A\u6570\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u3092//\u516C\u958B\u3057 (\u3053\u308C\u3092\u542B\u3081\u3066\u3082\u3088\u3044)\u3001//\u5C71\u672D\u306B\u6DF7\u305C\u30B7\u30E3\u30C3\u30D5\u30EB\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.JACK_OF_ALL_TRADES] = " \u9280\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002////\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u898B\u308B\u3002//\u305D\u308C\u3092\u6368\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002////\u624B\u672D\u304C5\u679A\u306B\u306A\u308B\u3088\u3046\u306B\u30AB\u30FC\u30C9//\u3092\u5F15\u304F\u3002////\u8CA1\u5B9D\u30AB\u30FC\u30C9\u4EE5\u5916\u306E\u624B\u672D1\u679A\u3092//\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.MANDARIN] = "|\uFF0B[3]|////\u624B\u672D1\u679A\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u304F\u3002//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u5834\u306E\u8CA1\u5B9D//\u30AB\u30FC\u30C9\u3092\u3059\u3079\u3066\u597D\u304D\u306A\u9806\u756A\u3067\u5C71//\u672D\u306E\u4E0A\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.NOBLE_BRIGAND] = "|\uFF0B[1]|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E\u4E0A\u304B\u30892\u679A//\u3092\u516C\u958B\u3057\u3001\u305D\u306E\u3046\u3061\u3042\u306A\u305F\u306E\u9078\u3093\u3060\u9280\u8CA8//1\u679A\u304B\u91D1\u8CA81\u679A\u3092\u5EC3\u68C4\u3057\u3001\u6B8B\u308A\u3092\u6368\u3066\u672D\u306B//\u3057\u3001\u8CA1\u5B9D\u30AB\u30FC\u30C9\u3092\u516C\u958B\u3057\u306A\u304B\u3063\u305F\u5834\u5408\u3001//\u9285\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002\u3042\u306A\u305F\u306F\u3053\u306E\u52B9\u679C//\u3067\u5EC3\u68C4\u3055\u308C\u305F\u30AB\u30FC\u30C9\u3092\u3059\u3079\u3066\u7372\u5F97\u3059\u308B\u3002//---//\u3053\u308C\u3092\u8CFC\u5165\u3059\u308B\u3068\u304D\u3001\u4E0A\u8A18\u306E\u653B\u6483\u3092\u884C\u3046\u3002";
JapaneseCardTexts[CardNames.NOMAD_CAMP] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|//|\uFF0B[2]|//---//\u3053\u306E\u30AB\u30FC\u30C9\u306F\u3001\u6368\u3066\u672D\u7F6E\u304D\u5834\u3067\u306F//\u306A\u304F\u3001\u5C71\u672D\u306E\u4E0A\u306B\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.OASIS] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B[1]|//// \u624B\u672D1\u679A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002 ";
JapaneseCardTexts[CardNames.ORACLE] = "\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E//\u4E0A\u304B\u30892\u679A\u3092\u516C\u958B\u3057\u3001\u3042\u306A\u305F\u306E//\u9078\u629E\u306B\u3088\u308A\u3001\u305D\u308C\u3089\u3092\u6368\u3066\u672D//\u306B\u3059\u308B\u304B\u81EA\u5206\u306E\u597D\u304D\u306A\u9806\u756A\u3067//\u5C71\u672D\u306B\u623B\u3059\u3002\u305D\u306E\u5F8C\u3001\u3042\u306A\u305F//\u306F\u3001|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.MARGRAVE] = "|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u8CFC\u5165|//// \u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001//1\u679A\u30AB\u30FC\u30C9\u3092\u5F15\u304D\u3001\u624B\u672D//\u304C3\u679A\u306B\u306A\u308B\u3088\u3046\u306B\u6368\u3066//\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.SCHEME] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u3053\u306E\u30BF\u30FC\u30F3\u3001\u5834\u306E\u30A2\u30AF\u30B7\u30E7\u30F3//\u30AB\u30FC\u30C9\u306E\u3046\u30611\u679A\u3092\u6368\u3066\u672D\u306B//\u3057\u305F\u3068\u304D\u3001\u305D\u308C\u3092\u5C71\u672D\u306E\u4E0A\u306B//\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.SILK_ROAD] = "\u3042\u306A\u305F\u306E\u52DD\u5229\u70B9\u30AB\u30FC\u30C94\u679A//(\u7AEF\u6570\u5207\u308A\u6368\u3066)\u306B\u3064\u304D{1}\u3002";
JapaneseCardTexts[CardNames.SPICE_MERCHANT] = "\u6B21\u306E\u3046\u30611\u3064\u3092\u9078\u3076\u305F\u3081//\u306B\u3001\u624B\u672D\u306E\u8CA1\u5B9D\u30AB\u30FC\u30C9//1\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044:////\u300C|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3001//|\uFF0B\uFF11 \u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u300D;////\u300C|\uFF0B\uFF11\u3000\u8CFC\u5165|\u3001|\uFF0B|[2]\u300D";
JapaneseCardTexts[CardNames.STABLES] = "//\u624B\u672D\u306E\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A\u3092//\u6368\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002////\u6368\u3066\u672D\u306B\u3057\u305F\u5834\u5408\u3001////|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3001//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u3002";
JapaneseCardTexts[CardNames.TRADER] = "\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002\u305D\u306E//\u30B3\u30B9\u30C8[1]\u306B\u3064\u304D\u9280\u8CA81\u679A\u3092//\u7372\u5F97\u3059\u308B\u3002//---//\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001//\u624B\u672D\u304B\u3089\u3053\u308C\u3092\u516C\u958B\u3057\u3066\u3082//\u3088\u3044\u3002\u516C\u958B\u3057\u305F\u5834\u5408\u3001\u305D\u308C//\u3092\u9280\u8CA81\u679A\u3068\u4EA4\u63DB\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.TUNNEL] = "{!2}//---//\u3053\u306E\u30AB\u30FC\u30C9\u3092\u30AF\u30EA\u30FC\u30F3\u30A2\u30C3\u30D7//\u30D5\u30A7\u30A4\u30BA\u4EE5\u5916\u306E\u5834\u9762\u3067\u6368\u3066\u672D\u306B//\u3057\u305F\u3068\u304D\u306B\u3001\u3053\u308C\u3092\u516C\u958B\u3057\u3066\u3082//\u3088\u3044\u3002\u516C\u958B\u3057\u305F\u5834\u5408\u3001\u91D1\u8CA81\u679A//\u3092\u7372\u5F97\u3059\u308B\u3002";

//Dark Ages
JapaneseCardTexts[CardNames.ALTAR] = "\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002////\u30B3\u30B9\u30C8[5]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C9//1\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.ARMORY] = "\u30B3\u30B9\u30C8[4]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C91\u679A\u3092//\u5C71\u672D\u306E\u4E0A\u306B\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.BAND_OF_MISFITS] = "\u30B5\u30D7\u30E9\u30A4\u306B\u3042\u308B\u3001\u3053\u308C\u3088\u308A//\u5B89\u304F\u547D\u4EE4\u30AB\u30FC\u30C9\u3067\u306A\u3044\u30A2\u30AF//\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u3001\u52D5\u304B//\u3055\u305A\u306B\u4F7F\u7528\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.BANDIT_CAMP] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////  \u7565\u596A\u54C11\u679A\u3092\u7372\u5F97\u3059\u308B\u3002 ";
JapaneseCardTexts[CardNames.BEGGAR] = "\u624B\u672D\u306B\u9285\u8CA83\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//---//\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u304C\u30A2\u30BF\u30C3\u30AF\u30AB\u30FC\u30C9//\u3092\u4F7F\u7528\u3059\u308B\u3068\u304D\u3001\u305D\u306E\u89E3\u6C7A\u524D\u306B\u3001//\u3053\u308C\u3092\u6368\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002\u6368\u3066//\u672D\u306B\u3057\u305F\u5834\u5408\u3001\u9280\u8CA81\u679A\u3092\u5C71\u672D\u306E//\u4E0A\u306B\u3001\u9280\u8CA81\u679A\u3092\u6368\u3066\u672D\u7F6E\u304D\u5834\u306B//\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.CATACOMBS] = "\u5C71\u672D\u306E\u4E0A\u304B\u30893\u679A\u3092\u898B\u308B\u3002\u6B21\u306E//\u3046\u30611\u3064\u3092\u9078\u3076:\u300C\u898B\u305F3\u679A\u3092\u624B//\u672D\u306B\u52A0\u3048\u308B\u300D;\u300C\u305D\u308C\u3089\u3092\u6368\u3066//\u672D\u306B\u3057\u3001|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u300D//---//\u3053\u308C\u3092\u5EC3\u68C4\u3057\u305F\u3068\u304D\u3001\u3053\u308C\u3088\u308A//\u5B89\u3044\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.COUNT] = " \u6B21\u306E\u3046\u30611\u3064\u3092\u9078\u3076:\u300C\u624B\u672D//2\u679A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u300D;\u300C\u624B//\u672D1\u679A\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u304F\u300D;//\u300C\u9285\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u300D////\u6B21\u306E\u3046\u30611\u3064\u3092\u9078\u3076:\u300C|\uFF0B|[3]\u300D;//\u300C\u624B\u672D\u3092\u3059\u3079\u3066\u5EC3\u68C4\u3059\u308B\u300D;//\u300C\u516C\u98181\u679A\u3092\u7372\u5F97\u3059\u308B\u300D";
JapaneseCardTexts[CardNames.COUNTERFEIT] = "[!1]//|\uFF0B\uFF11\u3000\u8CFC\u5165|////\u624B\u672D\u306E\u6301\u7D9A\u3067\u306A\u3044\u8CA1\u5B9D\u30AB\u30FC\u30C9//1\u679A\u3092\u30012\u56DE\u4F7F\u7528\u3057\u3066\u3082\u3088\u3044\u3002//\u305D\u306E\u5F8C\u3001\u305D\u308C\u3092\u5EC3\u68C4\u3059\u308B\u3002//";
JapaneseCardTexts[CardNames.CULTIST] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5EC3\u589F//\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002\u3042\u306A\u305F//\u306F\u624B\u672D\u306E\u72C2\u4FE1\u80051\u679A\u3092\u4F7F\u7528\u3057\u3066//\u3082\u3088\u3044\u3002//---//\u3053\u308C\u3092\u5EC3\u68C4\u3057\u305F\u3068\u304D\u3001//|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.DEATH_CART] = "|\uFF0B|[5]\u306E\u305F\u3081\u306B\u3001\u3053\u308C\u304B\u624B\u672D\u306E//\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057//\u3066\u3082\u3088\u3044\u3002//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u5EC3\u589F//\u30AB\u30FC\u30C92\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.FEODUM] = "\u3042\u306A\u305F\u306E\u9280\u8CA83\u679A//(\u7AEF\u6570\u5207\u308A\u6368\u3066)\u306B\u3064\u304D//{1}\u3002//---//\u3053\u308C\u3092\u5EC3\u68C4\u3057\u305F\u3068\u304D\u3001//\u9280\u8CA83\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.FORAGER] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B\uFF11\u3000\u8CFC\u5165|////\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3057\u3001\u305D\u306E\u5F8C\u3001\u5EC3\u68C4//\u7F6E\u304D\u5834\u306B\u3042\u308B\u7570\u306A\u308B\u8CA1\u5B9D\u30AB\u30FC\u30C9//1\u7A2E\u985E\u306B\u3064\u304D|\uFF0B|[1]\u3002";
JapaneseCardTexts[CardNames.FORTRESS] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//---//\u3053\u308C\u3092\u5EC3\u68C4\u3057\u305F\u3068\u304D\u3001//\u624B\u672D\u306B\u52A0\u3048\u308B\u3002";
JapaneseCardTexts[CardNames.GRAVEROBBER] = "\u6B21\u306E\u3046\u30611\u3064\u3092\u9078\u3076:\u300C\u5EC3\u68C4\u7F6E\u304D\u5834\u306B//\u3042\u308B\u30B3\u30B9\u30C8[3]\u304B\u3089[6]\u306E\u30AB\u30FC\u30C91\u679A//\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7372\u5F97\u3059\u308B\u300D;//\u300C\u624B\u672D\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092//\u5EC3\u68C4\u3057\u3001\u305D\u308C\u3088\u308A\u30B3\u30B9\u30C8\u304C\u6700\u5927[3]//\u9AD8\u3044\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u300D";
JapaneseCardTexts[CardNames.HERMIT] = " \u6368\u3066\u672D\u7F6E\u304D\u5834\u306E\u30AB\u30FC\u30C9\u3092\u3059\u3079\u3066\u898B\u308B\u3002//\u624B\u672D\u304B\u6368\u3066\u672D\u7F6E\u304D\u5834\u304B\u3089\u8CA1\u5B9D\u30AB\u30FC\u30C9//\u4EE5\u5916\u306E\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002//////\u30B3\u30B9\u30C8[3]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//////\u3053\u306E\u30BF\u30FC\u30F3\u3001\u3042\u306A\u305F\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA//\u306E\u7D42\u4E86\u6642\u3001\u305D\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA\u3067\u4F55\u3082//\u30AB\u30FC\u30C9\u3092\u7372\u5F97\u3057\u306A\u304B\u3063\u305F\u5834\u5408\u3001\u3053\u308C//\u3092\u72C2\u4EBA1\u679A\u3068\u4EA4\u63DB\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.HUNTING_GROUNDS] = "|\uFF0B\uFF14\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////---////\u3053\u308C\u3092\u5EC3\u68C4\u3057\u305F\u3068\u304D\u3001//\u516C\u98181\u679A\u304B\u5C4B\u65773\u679A\u3092//\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.IRONMONGER] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u516C\u958B\u3059\u308B\u3002\u305D\u308C//\u3092\u6368\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002\u6368\u3066\u672D\u306B\u3057\u3066\u3082//\u3057\u306A\u304F\u3066\u3082\u3001\u516C\u958B\u3057\u305F\u30AB\u30FC\u30C9\u304C...////\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//\u8CA1\u5B9D\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001|\uFF0B|[1]//\u52DD\u5229\u70B9\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|";
JapaneseCardTexts[CardNames.JUNK_DEALER] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B[1]|////\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.KNIGHTS] = "  \u9A0E\u58EB\u3092\u4F7F\u3046\u30B2\u30FC\u30E0\u306E\u958B\u59CB\u524D\u306B\u3001//\u9A0E\u58EB\u306E\u5C71\u3092\u30B7\u30E3\u30C3\u30D5\u30EB\u3059\u308B\u3002//\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u4EE5\u5916\u306F\u88CF\u306B\u3059//\u308B\u3002\u5C71\u306E\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u306E\u307F//\u8CFC\u5165\u30FB\u7372\u5F97\u3067\u304D\u308B\u3002";
JapaneseCardTexts[CardNames.MARKET_SQUARE] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B\uFF11\u3000\u8CFC\u5165|//---//\u3042\u306A\u305F\u306E\u30AB\u30FC\u30C91\u679A\u304C\u5EC3\u68C4\u3055\u308C\u305F\u3068\u304D\u3001\u3053//\u308C\u3092\u624B\u672D\u304B\u3089\u6368\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002\u6368\u3066//\u672D\u306B\u3057\u305F\u5834\u5408\u3001\u91D1\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.MARAUDER] = "\u7565\u596A\u54C11\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001//\u5EC3\u589F\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97 //\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.MYSTIC] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B[2]|////\u30AB\u30FC\u30C91\u679A\u3092\u6307\u5B9A\u3057\u3001\u5C71\u672D\u306E\u4E00\u756A//\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u516C\u958B\u3059\u308B\u3002\u516C\u958B\u3057\u305F//\u30AB\u30FC\u30C9\u3092\u6307\u5B9A\u3057\u3066\u3044\u305F\u5834\u5408\u3001\u624B\u672D//\u306B\u52A0\u3048\u308B\u3002";
JapaneseCardTexts[CardNames.PILLAGE] = "  \u3053\u308C\u3092\u5EC3\u68C4\u3059\u308B\u3002\u305D\u3046\u3057\u305F\u5834\u5408\u3001//\u7565\u596A\u54C12\u679A\u3092\u7372\u5F97\u3057\u3001\u624B\u672D\u304C5\u679A//\u4EE5\u4E0A\u306E\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001//\u624B\u672D\u3092\u516C\u958B\u3057\u3001\u3042\u306A\u305F\u304C\u9078\u629E\u3059//\u308B\u30AB\u30FC\u30C91\u679A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.POOR_HOUSE] = "|\uFF0B[4]|////\u624B\u672D\u3092\u516C\u958B\u3059\u308B\u3002\u305D\u306E\u4E2D\u306E//\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A\u306B\u3064\u304D-[1]//(\u305F\u3060\u3057[0]\u672A\u6E80\u306B\u306F\u306A\u3089\u306A\u3044)\u3002";
JapaneseCardTexts[CardNames.PROCESSION] = "\u624B\u672D\u306E\u6301\u7D9A\u30AB\u30FC\u30C9\u4EE5\u5916\u306E\u30A2\u30AF\u30B7\u30E7\u30F3//\u30AB\u30FC\u30C91\u679A\u30922\u56DE\u4F7F\u7528\u3057\u3066\u3082\u3088\u3044\u3002//\u305D\u308C\u3092\u5EC3\u68C4\u3059\u308B\u3002\u305D\u308C\u3088\u308A\u30B3\u30B9\u30C8\u304C//[1]\u9AD8\u3044\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97//\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.RATS] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u30CD\u30BA\u30DF1\u679A\u3092\u7372\u5F97\u3059\u308B\u3002\u30CD\u30BA\u30DF\u4EE5\u5916//\u306E\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B (\u30CD\u30BA\u30DF\u306E\u307F//\u306E\u5834\u5408\u3001\u624B\u672D\u3092\u516C\u958B\u3059\u308B)\u3002//---//\u3053\u308C\u3092\u5EC3\u68C4\u3057\u305F\u3068\u304D\u3001//|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.REBUILD] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u30AB\u30FC\u30C91\u679A\u3092\u6307\u5B9A\u3059\u308B\u3002\u6307\u5B9A\u3057\u306A\u304B\u3063\u305F//\u52DD\u5229\u70B9\u30AB\u30FC\u30C91\u679A\u304C\u516C\u958B\u3055\u308C\u308B\u307E\u3067\u3001//\u5C71\u672D\u3092\u4E0A\u304B\u3089\u516C\u958B\u3059\u308B\u3002\u4ED6\u306E\u30AB\u30FC\u30C9\u3092//\u6368\u3066\u672D\u306B\u3057\u3001\u305D\u306E\u52DD\u5229\u70B9\u30AB\u30FC\u30C9\u3092\u5EC3\u68C4//\u3057\u3001\u305D\u308C\u3088\u308A\u30B3\u30B9\u30C8\u304C\u6700\u5927[3]\u9AD8\u3044\u52DD\u5229//\u70B9\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.ROGUE] = "|\uFF0B[2]|////\u5EC3\u68C4\u7F6E\u304D\u5834\u306B\u30B3\u30B9\u30C8[3]\u304B\u3089[6]\u306E\u30AB\u30FC\u30C9//\u304C\u3042\u308B\u5834\u5408\u3001\u305D\u306E\u4E2D\u306E1\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//\u306A\u3044\u5834\u5408\u3001\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001//\u5C71\u672D\u306E\u4E0A\u304B\u30892\u679A\u3092\u516C\u958B\u3057\u3001\u305D\u306E\u4E2D\u306E//\u30B3\u30B9\u30C8\u304C[3]\u304B\u3089[6]\u306E\u30AB\u30FC\u30C91\u679A\u3092//\u5EC3\u68C4\u3057\u3001\u6B8B\u308A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.SAGE] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u30B3\u30B9\u30C8[3]\u4EE5\u4E0A\u306E\u30AB\u30FC\u30C91\u679A\u304C//\u516C\u958B\u3055\u308C\u308B\u307E\u3067\u3001\u5C71\u672D\u306E\u4E0A\u304B\u3089//\u30AB\u30FC\u30C9\u3092\u516C\u958B\u3059\u308B\u3002\u305D\u308C\u3092\u624B\u672D//\u306B\u52A0\u3048\u3001\u6B8B\u308A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.SCAVENGER] = "|\uFF0B[2]|////\u5C71\u672D\u3092\u6368\u3066\u672D\u7F6E\u304D\u5834\u306B\u7F6E\u3044\u3066\u3082//\u3088\u3044\u3002\u6368\u3066\u672D\u7F6E\u304D\u5834\u304B\u3089\u30AB\u30FC\u30C9//1\u679A\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.STOREROOM] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|////\u624B\u672D\u3092\u597D\u304D\u306A\u679A\u6570\u6368\u3066\u672D\u306B\u3057\u3001//\u540C\u3058\u679A\u6570\u306E\u30AB\u30FC\u30C9\u3092\u5F15\u304F\u3002////\u624B\u672D\u3092\u597D\u304D\u306A\u679A\u6570\u6368\u3066\u672D\u306B\u3057\u3001//1\u679A\u306B\u3064\u304D|\uFF0B|[1]\u3002";
JapaneseCardTexts[CardNames.SQUIRE] = "|\uFF0B[1]|////\u6B21\u306E\u3046\u30611\u3064\u3092\u9078\u3076:\u300C|\uFF0B\uFF12\u3000\u30A2|//|\u30AF\u30B7\u30E7\u30F3|\u300D;\u300C|\uFF0B\uFF12\u3000\u8CFC\u5165|\u300D;//\u300C\u9280\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u300D//---//\u3053\u308C\u3092\u5EC3\u68C4\u3057\u305F\u3068\u304D\u3001\u30A2\u30BF\u30C3\u30AF//\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.URCHIN] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u624B\u672D\u304C4\u679A\u306B//\u306A\u308B\u3088\u3046\u306B\u6368\u3066\u672D\u306B\u3059\u308B\u3002//---//\u3053\u308C\u304C\u5834\u306B\u3042\u308B\u9593\u3001\u4ED6\u306E\u30A2\u30BF\u30C3\u30AF\u30AB\u30FC\u30C9//\u3092\u4F7F\u7528\u3059\u308B\u3068\u304D\u3001\u5148\u306B\u3053\u308C\u3092\u5EC3\u68C4\u3057\u3066\u3082//\u3088\u3044\u3002\u5EC3\u68C4\u3057\u305F\u5834\u5408\u3001\u50AD\u51751\u679A\u3092\u7372\u5F97\u3059//\u308B\u3002";
JapaneseCardTexts[CardNames.VAGRANT] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u516C\u958B\u3059\u308B\u3002//\u305D\u308C\u304C\u546A\u3044\u3001\u5EC3\u589F\u3001\u907F\u96E3\u6240\u3001\u52DD\u5229//\u70B9\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001\u624B\u672D\u306B\u52A0\u3048\u308B\u3002";
JapaneseCardTexts[CardNames.WANDERING_MINSTREL] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u5C71\u672D\u306E\u4E0A\u304B\u30893\u679A\u3092\u516C\u958B\u3059\u308B\u3002\u305D\u306E//\u4E2D\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u3092\u597D\u304D\u306A\u9806\u756A//\u3067\u5C71\u672D\u306E\u4E0A\u306B\u623B\u3057\u3001\u6B8B\u308A\u3092\u6368\u3066\u672D//\u306B\u3059\u308B\u3002";

JapaneseCardTexts[CardNames.DAME_ANNA] = "\u624B\u672D\u304B\u30892\u679A\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C9\u3092\u5EC3\u68C4\u3059\u308B\u3002//\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E\u4E0A\u304B\u3089//2\u679A\u3092\u516C\u958B\u3057\u3001\u305D\u306E\u4E2D\u306E\u30B3\u30B9\u30C8\u304C[3]//\u304B\u3089[6]\u306E\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u3001\u6B8B\u308A\u3092//\u6368\u3066\u672D\u306B\u3059\u308B\u3002\u8AB0\u304B\u304C\u9A0E\u58EB\u3092\u5EC3\u68C4\u3057\u305F//\u5834\u5408\u3001\u3042\u306A\u305F\u306F\u3053\u308C\u3092\u5EC3\u68C4\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.DAME_JOSEPHINE] = "\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E\u4E0A\u304B\u3089//2\u679A\u3092\u516C\u958B\u3057\u3001\u305D\u306E\u4E2D\u306E\u30B3\u30B9\u30C8\u304C[3]//\u304B\u3089[6]\u306E\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u3001\u6B8B\u308A\u3092//\u6368\u3066\u672D\u306B\u3059\u308B\u3002\u8AB0\u304B\u304C\u9A0E\u58EB\u3092\u5EC3\u68C4\u3057\u305F//\u5834\u5408\u3001\u3042\u306A\u305F\u306F\u3053\u308C\u3092\u5EC3\u68C4\u3059\u308B\u3002//---//{!2}";
JapaneseCardTexts[CardNames.DAME_MOLLY] = "|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E\u4E0A\u304B\u3089//2\u679A\u3092\u516C\u958B\u3057\u3001\u305D\u306E\u4E2D\u306E\u30B3\u30B9\u30C8\u304C[3]//\u304B\u3089[6]\u306E\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u3001\u6B8B\u308A\u3092//\u6368\u3066\u672D\u306B\u3059\u308B\u3002\u8AB0\u304B\u304C\u9A0E\u58EB\u3092\u5EC3\u68C4\u3057\u305F//\u5834\u5408\u3001\u3042\u306A\u305F\u306F\u3053\u308C\u3092\u5EC3\u68C4\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.DAME_NATALIE] = "\u30B3\u30B9\u30C8[3]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u3066//\u3082\u3088\u3044\u3002//\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E\u4E0A\u304B\u3089//2\u679A\u3092\u516C\u958B\u3057\u3001\u305D\u306E\u4E2D\u306E\u30B3\u30B9\u30C8\u304C[3]//\u304B\u3089[6]\u306E\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u3001\u6B8B\u308A\u3092//\u6368\u3066\u672D\u306B\u3059\u308B\u3002\u8AB0\u304B\u304C\u9A0E\u58EB\u3092\u5EC3\u68C4\u3057\u305F//\u5834\u5408\u3001\u3042\u306A\u305F\u306F\u3053\u308C\u3092\u5EC3\u68C4\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.DAME_SYLVIA] = "|\uFF0B[2]|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E\u4E0A\u304B\u3089//2\u679A\u3092\u516C\u958B\u3057\u3001\u305D\u306E\u4E2D\u306E\u30B3\u30B9\u30C8\u304C[3]//\u304B\u3089[6]\u306E\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u3001\u6B8B\u308A\u3092//\u6368\u3066\u672D\u306B\u3059\u308B\u3002\u8AB0\u304B\u304C\u9A0E\u58EB\u3092\u5EC3\u68C4\u3057\u305F//\u5834\u5408\u3001\u3042\u306A\u305F\u306F\u3053\u308C\u3092\u5EC3\u68C4\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.SIR_BAILEY] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E\u4E0A\u304B\u3089//2\u679A\u3092\u516C\u958B\u3057\u3001\u305D\u306E\u4E2D\u306E\u30B3\u30B9\u30C8\u304C[3]//\u304B\u3089[6]\u306E\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u3001\u6B8B\u308A\u3092//\u6368\u3066\u672D\u306B\u3059\u308B\u3002\u8AB0\u304B\u304C\u9A0E\u58EB\u3092\u5EC3\u68C4\u3057\u305F//\u5834\u5408\u3001\u3042\u306A\u305F\u306F\u3053\u308C\u3092\u5EC3\u68C4\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.SIR_DESTRY] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E\u4E0A\u304B\u3089//2\u679A\u3092\u516C\u958B\u3057\u3001\u305D\u306E\u4E2D\u306E\u30B3\u30B9\u30C8\u304C[3]//\u304B\u3089[6]\u306E\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u3001\u6B8B\u308A\u3092//\u6368\u3066\u672D\u306B\u3059\u308B\u3002\u8AB0\u304B\u304C\u9A0E\u58EB\u3092\u5EC3\u68C4\u3057\u305F//\u5834\u5408\u3001\u3042\u306A\u305F\u306F\u3053\u308C\u3092\u5EC3\u68C4\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.SIR_MARTIN] = "|\uFF0B\uFF12\u3000\u8CFC\u5165|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E\u4E0A\u304B\u3089//2\u679A\u3092\u516C\u958B\u3057\u3001\u305D\u306E\u4E2D\u306E\u30B3\u30B9\u30C8\u304C[3]//\u304B\u3089[6]\u306E\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u3001\u6B8B\u308A\u3092//\u6368\u3066\u672D\u306B\u3059\u308B\u3002\u8AB0\u304B\u304C\u9A0E\u58EB\u3092\u5EC3\u68C4\u3057\u305F//\u5834\u5408\u3001\u3042\u306A\u305F\u306F\u3053\u308C\u3092\u5EC3\u68C4\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.SIR_MICHAEL] = "\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u624B\u672D\u304C3\u679A\u306B//\u306A\u308B\u3088\u3046\u306B\u6368\u3066\u672D\u306B\u3059\u308B\u3002\u305D\u306E\u5F8C\u3001//\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E\u4E0A\u304B\u3089//2\u679A\u3092\u516C\u958B\u3057\u3001\u305D\u306E\u4E2D\u306E\u30B3\u30B9\u30C8\u304C[3]//\u304B\u3089[6]\u306E\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u3001\u6B8B\u308A\u3092//\u6368\u3066\u672D\u306B\u3059\u308B\u3002\u8AB0\u304B\u304C\u9A0E\u58EB\u3092\u5EC3\u68C4\u3057\u305F//\u5834\u5408\u3001\u3042\u306A\u305F\u306F\u3053\u308C\u3092\u5EC3\u68C4\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.SIR_VANDER] = "\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E\u4E0A\u304B\u3089//2\u679A\u3092\u516C\u958B\u3057\u3001\u305D\u306E\u4E2D\u306E\u30B3\u30B9\u30C8\u304C[3]//\u304B\u3089[6]\u306E\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u3001\u6B8B\u308A\u3092//\u6368\u3066\u672D\u306B\u3059\u308B\u3002\u8AB0\u304B\u304C\u9A0E\u58EB\u3092\u5EC3\u68C4\u3057\u305F//\u5834\u5408\u3001\u3042\u306A\u305F\u306F\u3053\u308C\u3092\u5EC3\u68C4\u3059\u308B\u3002//---//\u3053\u308C\u3092\u5EC3\u68C4\u3057\u305F\u3068\u304D\u3001\u91D1\u8CA81\u679A\u3092\u7372\u5F97//\u3059\u308B\u3002";

JapaneseCardTexts[CardNames.HOVEL] = "\u52DD\u5229\u70B9\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97//\u3057\u305F\u3068\u304D\u3001\u3053\u308C\u3092\u624B\u672D//\u304B\u3089\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.NECROPOLIS] = "|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|";
JapaneseCardTexts[CardNames.OVERGROWN_ESTATE] = "{!0}//---//\u3053\u308C\u3092\u5EC3\u68C4\u3057\u305F\u3068\u304D\u3001//|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.MADMAN] = "|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u3053\u308C\u3092\u72C2\u4EBA\u306E\u5C71\u306B\u623B\u3059\u3002//\u623B\u3057\u305F\u5834\u5408\u3001\u624B\u672D1\u679A\u306B\u3064\u304D\u3001//|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002//////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";
JapaneseCardTexts[CardNames.MERCENARY] = " \u624B\u672D2\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u30022\u679A//\u3092\u5EC3\u68C4\u3057\u305F\u5834\u5408\u3001|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9|//|\u3092\u5F15\u304F|\u3001|\uFF0B|[2]\u3001\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC//\u306F\u5168\u54E1\u3001\u624B\u672D\u304C3\u679A\u306B\u306A\u308B\u3088\u3046\u306B//\u6368\u3066\u672D\u306B\u3059\u308B\u3002//////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";

JapaneseCardTexts[CardNames.RUIN_PILE] = "";
JapaneseCardTexts[CardNames.ABANDONED_MINE] = "|\uFF0B[1]|";
JapaneseCardTexts[CardNames.RUINED_LIBRARY] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|";
JapaneseCardTexts[CardNames.RUINED_MARKET] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|";
JapaneseCardTexts[CardNames.RUINED_VILLAGE] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|";
JapaneseCardTexts[CardNames.SURVIVORS] = "\u5C71\u672D\u306E\u4E0A\u304B\u30892\u679A\u3092\u898B\u308B\u3002\u4E21\u65B9\u3092//\u6368\u3066\u672D\u306B\u3059\u308B\u304B\u3001\u597D\u304D\u306A\u9806\u756A\u3067//\u5C71\u672D\u306B\u623B\u3059\u3002";

JapaneseCardTexts[CardNames.SPOILS] = "[!3]//\u3053\u308C\u3092\u7565\u596A\u54C1\u306E\u5C71\u306B\u623B\u3059\u3002////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";

//Guilds
JapaneseCardTexts[CardNames.ADVISOR] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u5C71\u672D\u306E\u4E0A\u304B\u30893\u679A\u3092\u516C\u958B\u3059\u308B\u3002\u5DE6//\u96A3\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u305D\u306E\u4E2D\u306E1\u679A\u3092//\u9078\u629E\u3059\u308B\u3002\u3042\u306A\u305F\u306F\u305D\u308C\u3092\u6368\u3066\u672D//\u306B\u3057\u3001\u6B8B\u308A\u3092\u624B\u672D\u306B\u52A0\u3048\u308B\u3002";
JapaneseCardTexts[CardNames.BAKER] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B\uFF11\u3000\u8CA1\u6E90|//---//\u6E96\u5099:\u30D7\u30EC\u30A4\u30E4\u30FC\u5168\u54E1\u306B\u3001//|\uFF0B\uFF11\u3000\u8CA1\u6E90|\u3002";
JapaneseCardTexts[CardNames.BUTCHER] = "|\uFF0B\uFF12\u3000\u8CA1\u6E90|////\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002//\u305D\u3046\u3057\u305F\u5834\u5408\u3001\u305D\u306E\u30AB\u30FC\u30C9\u306E//\u30B3\u30B9\u30C8\u306B\u3001\u8CA1\u6E90\u3092\u652F\u6255\u3046\u3060\u3051//[1]\u3092\u52A0\u3048\u305F\u5408\u8A08\u4EE5\u4E0B\u306E\u30B3\u30B9\u30C8//\u306E\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.CANDLESTICK_MAKER] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B\uFF11\u3000\u8CFC\u5165|//|\uFF0B\uFF11\u3000\u8CA1\u6E90|";
JapaneseCardTexts[CardNames.DOCTOR] = " \u30AB\u30FC\u30C91\u679A\u3092\u6307\u5B9A\u3059\u308B\u3002\u5C71\u672D\u306E\u4E0A//\u304B\u30893\u679A\u3092\u516C\u958B\u3059\u308B\u3002\u305D\u306E\u4E2D\u306E\u6307\u5B9A//\u3057\u305F\u30AB\u30FC\u30C9\u3092\u3059\u3079\u3066\u5EC3\u68C4\u3059\u308B\u3002\u6B8B // \u308A\u3092\u597D\u304D\u306A\u9806\u756A\u3067\u5C71\u672D\u306E\u4E0A\u306B\u623B\u3059\u3002//---//\u904E\u6255\u3044:\u8FFD\u52A0\u3067\u652F\u6255\u3063\u305F[1]\u306B\u3064\u304D//1\u56DE\u3001\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u898B//\u308B\u3002\u305D\u308C\u3092\u5EC3\u68C4\u3059\u308B\u304B\u3001\u6368\u3066\u672D//\u306B\u3059\u308B\u304B\u3001\u5C71\u672D\u306E\u4E0A\u306B\u623B\u3059\u3002";
JapaneseCardTexts[CardNames.HERALD] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u516C\u958B\u3059\u308B\u3002//\u305D\u308C\u304C\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001  //\u5834\u306B\u51FA\u3057\u3066\u4F7F\u7528\u3059\u308B\u3002//---//\u904E\u6255\u3044:\u8FFD\u52A0\u3067\u652F\u6255\u3063\u305F[1]\u306B\u3064\u304D//1\u679A\u3001\u6368\u3066\u672D\u7F6E\u304D\u5834\u304B\u3089\u30AB\u30FC\u30C9\u3092//\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.JOURNEYMAN] = "\u30AB\u30FC\u30C91\u679A\u3092\u6307\u5B9A\u3059\u308B\u3002\u305D\u308C\u4EE5\u5916//\u306E3\u679A\u304C\u516C\u958B\u3055\u308C\u308B\u307E\u3067\u5C71\u672D\u3092\u4E0A//\u304B\u3089\u516C\u958B\u3059\u308B\u3002\u305D\u306E3\u679A\u3092\u624B\u672D\u306B//\u52A0\u3048\u3001\u6B8B\u308A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.MASTERPIECE] = "[!1]////---//\u904E\u6255\u3044:\u8FFD\u52A0\u3067\u652F\u6255\u3063\u305F[1]\u306B//\u3064\u304D1\u679A\u3001\u9280\u8CA8\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.MERCHANT_GUILD] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|//|\uFF0B[1]|////\u3053\u306E\u30BF\u30FC\u30F3\u3001\u3042\u306A\u305F\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA\u306E//\u7D42\u4E86\u6642\u3001\u305D\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA\u3067\u7372\u5F97\u3057\u305F//\u30AB\u30FC\u30C91\u679A\u306B\u3064\u304D\u3001|\uFF0B\uFF11\u3000\u8CA1\u6E90|\u3002";
JapaneseCardTexts[CardNames.PLAZA] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////|\uFF0B\uFF11\u3000\u8CA1\u6E90|\u306E\u305F\u3081\u306B\u3001\u8CA1//\u5B9D\u30AB\u30FC\u30C91\u679A\u3092\u6368\u3066\u672D\u306B//\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.TAXMAN] = "\u624B\u672D\u306E\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082//\u3088\u3044\u3002\u624B\u672D\u304C5\u679A\u4EE5\u4E0A\u306E\u4ED6\u306E\u30D7\u30EC\u30A4//\u30E4\u30FC\u306F\u5168\u54E1\u3001\u305D\u308C\u3068\u540C\u3058\u30AB\u30FC\u30C91\u679A//\u3092\u6368\u3066\u672D\u306B\u3059\u308B(\u540C\u3058\u30AB\u30FC\u30C9\u304C\u306A\u3044//\u5834\u5408\u3001\u624B\u672D\u3092\u516C\u958B\u3059\u308B)\u3002\u305D\u306E\u5F8C\u3001//\u3042\u306A\u305F\u306F\u5EC3\u68C4\u3057\u305F\u30AB\u30FC\u30C9\u3088\u308A\u30B3\u30B9\u30C8//\u304C\u6700\u5927[3]\u9AD8\u3044\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A\u3092\u5C71\u672D//\u306E\u4E0A\u306B\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.SOOTHSAYER] = "\u91D1\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//// \u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001//\u546A\u30441\u679A\u3092\u7372\u5F97\u3057\u3001\u546A\u3044//\u3092\u7372\u5F97\u3057\u305F\u5834\u5408\u30011\u679A//\u30AB\u30FC\u30C9\u3092\u5F15\u304F\u3002";
JapaneseCardTexts[CardNames.STONEMASON] = "\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002//\u305D\u308C\u3088\u308A\u30B3\u30B9\u30C8\u304C\u5B89\u3044// \u30AB\u30FC\u30C92\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//---//\u904E\u6255\u3044:\u8FFD\u52A0\u306E\u652F\u6255\u3044\u3068//\u540C\u30B3\u30B9\u30C8\u306E\u30A2\u30AF\u30B7\u30E7\u30F3//\u30AB\u30FC\u30C92\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";

//Adventures
JapaneseCardTexts[CardNames.AMULET] = "\u73FE\u5728\u3068\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E//\u958B\u59CB\u6642\u306B\u3001\u6B21\u306E\u3046\u30611\u3064\u3092\u9078//\u3076:\u300C|\uFF0B|[1]\u300D;\u300C\u624B\u672D1\u679A\u3092\u5EC3\u68C4//\u3059\u308B\u300D;\u300C\u9280\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u300D";
JapaneseCardTexts[CardNames.ARTIFICER] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B[1]|////\u624B\u672D\u3092\u597D\u304D\u306A\u679A\u6570\u6368\u3066\u672D\u306B\u3059\u308B\u3002//\u6368\u3066\u672D\u306B\u3057\u305F1\u679A\u306B\u3064\u304D\u30B3\u30B9\u30C8//[1]\u3068\u3057\u3066\u3001\u3061\u3087\u3046\u3069\u305D\u306E\u30B3\u30B9\u30C8//\u3092\u6301\u3064\u30AB\u30FC\u30C91\u679A\u3092\u5C71\u672D\u306E\u4E0A\u306B//\u7372\u5F97\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.CARAVAN_GUARD] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001//|\uFF0B|[1]\u3002//---//\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u304C\u30A2\u30BF\u30C3\u30AF\u30AB\u30FC\u30C9\u3092//\u4F7F\u7528\u3059\u308B\u3068\u304D\u3001\u305D\u306E\u89E3\u6C7A\u524D\u306B\u3001\u624B\u672D//\u304B\u3089\u3053\u308C\u3092\u4F7F\u7528\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.COIN_OF_THE_REALM] = "[!1]////\u3053\u308C\u3092\u3042\u306A\u305F\u306E\u9152\u5834\u30DE\u30C3\u30C8\u306E//\u4E0A\u306B\u7F6E\u304F\u3002//---//\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u4F7F\u7528\u3057\u305F// \u5F8C\u3001|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u306E\u305F\u3081\u306B\u3001//\u3053\u308C\u3092\u547C\u3073\u51FA\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.DISTANT_LANDS] = "\u3053\u308C\u3092\u3042\u306A\u305F\u306E\u9152\u5834\u30DE\u30C3\u30C8\u306E//\u4E0A\u306B\u7F6E\u304F\u3002//---//\u3053\u308C\u304C\u9152\u5834\u30DE\u30C3\u30C8\u306E\u4E0A\u306B//\u3042\u308B\u5834\u5408\u3001{4}\u3002\u305D\u308C\u4EE5\u5916//\u306E\u5834\u5408\u3001{0}\u3002";
JapaneseCardTexts[CardNames.DUNGEON] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u73FE\u5728\u3068\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B//\u59CB\u6642\u306B\u3001|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3001//\u624B\u672D2\u679A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.DUPLICATE] = "\u3053\u308C\u3092\u3042\u306A\u305F\u306E\u9152\u5834\u30DE\u30C3\u30C8\u306E//\u4E0A\u306B\u7F6E\u304F\u3002//---//\u30B3\u30B9\u30C8[6]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97//\u3057\u305F\u3068\u304D\u3001\u540C\u3058\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97//\u3059\u308B\u305F\u3081\u306B\u3001\u3053\u308C\u3092\u547C\u3073\u51FA\u3057\u3066//\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.GEAR] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////\u624B\u672D\u3092\u6700\u59272\u679A\u4F0F\u305B\u3066\u8107\u306B//\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002\u3042\u306A\u305F\u306E//\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001//\u305D\u308C\u3089\u3092\u624B\u672D\u306B\u52A0\u3048\u308B\u3002";
JapaneseCardTexts[CardNames.GIANT] = "\u65C5\u30C8\u30FC\u30AF\u30F3\u3092\u88CF\u8FD4\u3059//(\u958B\u59CB\u6642\u306F\u8868\u5411\u304D)\u3002\u305D\u3057\u3066//\u88CF\u5411\u304D\u306B\u306A\u3063\u305F\u5834\u5408\u3001|\uFF0B|[1]\u3002////\u8868\u5411\u304D\u306B\u306A\u3063\u305F\u5834\u5408\u3001|\uFF0B|[5]\u3001\u4ED6\u306E//\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E\u4E00\u756A\u4E0A//\u306E\u30AB\u30FC\u30C9\u3092\u516C\u958B\u3057\u3001\u305D\u308C\u304C\u30B3\u30B9\u30C8//[3]\u304B\u3089[6]\u306E\u5834\u5408\u306F\u5EC3\u68C4\u3057\u3001\u305D\u308C\u4EE5//\u5916\u306E\u5834\u5408\u3001\u305D\u308C\u3092\u6368\u3066\u672D\u306B\u3057\u546A\u3044//1\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.GUIDE] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//\u3053\u308C\u3092\u3042\u306A\u305F\u306E\u9152\u5834\u30DE\u30C3\u30C8\u306E\u4E0A\u306B\u7F6E\u304F\u3002//---//\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001\u624B\u672D\u3092//\u3059\u3079\u3066\u6368\u3066\u672D\u306B\u3057\u30665\u679A\u30AB\u30FC\u30C9\u3092\u5F15\u304F//\u305F\u3081\u306B\u3001\u3053\u308C\u3092\u547C\u3073\u51FA\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.HAUNTED_WOODS] = "\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642//\u306B\u3001|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002////\u305D\u308C\u307E\u3067\u3001\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F//\u8CFC\u5165\u306B\u3088\u308A\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97 //\u3057\u305F\u3068\u304D\u3001\u624B\u672D\u3092\u3059\u3079\u3066\u597D\u304D//\u306A\u9806\u756A\u3067\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.HIRELING] = "\u30B2\u30FC\u30E0\u7D42\u4E86\u307E\u3067\u3001\u3042\u306A\u305F//\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001//////|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|";
JapaneseCardTexts[CardNames.LOST_CITY] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u4ED6\u306E\u30D7\u30EC\u30A4//\u30E4\u30FC\u306F\u5168\u54E1\u30011\u679A\u30AB\u30FC\u30C9\u3092\u5F15\u304F\u3002";
JapaneseCardTexts[CardNames.MAGPIE] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u516C\u958B//\u3059\u308B\u3002\u305D\u308C\u304C\u8CA1\u5B9D\u30AB\u30FC\u30C9\u306E\u5834//\u5408\u3001\u624B\u672D\u306B\u52A0\u3048\u308B\u3002\u305D\u308C\u304C\u30A2//\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u307E\u305F\u306F\u52DD\u5229\u70B9//\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001\u30AB\u30B5\u30B5\u30AE1\u679A //\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.MESSENGER] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|//|\uFF0B[2]|//\u5C71\u672D\u3092\u6368\u3066\u672D\u7F6E\u304D\u5834\u306B\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002//---//\u3053\u308C\u304C\u3042\u306A\u305F\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA\u306B\u6700\u521D\u306B//\u7372\u5F97\u3057\u305F\u3082\u306E\u306E\u5834\u5408\u3001\u30B3\u30B9\u30C8[4]\u4EE5\u4E0B\u306E//\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u3001\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC//\u306F\u5168\u54E1\u3001\u305D\u308C\u3068\u540C\u3058\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97//\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.MISER] = "\u6B21\u306E\u3046\u30611\u3064\u3092\u9078\u3076:\u300C\u624B\u672D\u306E//\u9285\u8CA81\u679A\u3092\u9152\u5834\u30DE\u30C3\u30C8\u306B\u7F6E\u304F\u300D;//\u300C\u9152\u5834\u30DE\u30C3\u30C8\u306E\u4E0A\u306E\u9285\u8CA81\u679A\u306B//\u3064\u304D|\uFF0B|[1]\u300D";
JapaneseCardTexts[CardNames.PAGE] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//---//\u3053\u308C\u3092\u5834\u304B\u3089\u6368\u3066\u672D\u306B\u3057\u305F\u3068\u304D\u3001//\u3053\u308C\u3092\u30C8\u30EC\u30B8\u30E3\u30FC\u30CF\u30F3\u30BF\u30FC\u3068//\u4EA4\u63DB\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.PEASANT] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|//|\uFF0B[1]|//---//\u3053\u308C\u3092\u5834\u304B\u3089\u6368\u3066\u672D\u306B\u3057\u305F\u3068\u304D\u3001//\u3053\u308C\u3092\u5175\u58EB\u3068\u4EA4\u63DB\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.PORT] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u8FFD\u52A0\u3067//\u6E2F\u753A1\u679A\u3092\u7372\u5F97\u3059\u308B(\u8FFD\u52A0\u5206\u306F//\u305D\u308C\u4EE5\u4E0A\u7372\u5F97\u3055\u305B\u306A\u3044)\u3002";
JapaneseCardTexts[CardNames.RANGER] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|////\u65C5\u30C8\u30FC\u30AF\u30F3\u3092\u88CF\u8FD4\u3059//(\u958B\u59CB\u6642\u306F\u8868\u5411\u304D)\u3002\u305D\u3057//\u3066\u8868\u5411\u304D\u306B\u306A\u3063\u305F\u5834\u5408\u3001////|\uFF0B\uFF15\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.RATCATCHER] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u3053\u308C\u3092\u3042\u306A\u305F\u306E\u9152\u5834\u30DE\u30C3\u30C8\u306E\u4E0A\u306B\u7F6E\u304F\u3002//---//\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001\u624B\u672D1\u679A//\u3092\u5EC3\u68C4\u3059\u308B\u305F\u3081\u306B\u3001\u3053\u308C\u3092\u547C\u3073\u51FA\u3057//\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.RAZE] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u3053\u306E\u30AB\u30FC\u30C9\u304B\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059//\u308B\u3002\u5EC3\u68C4\u3057\u305F\u30AB\u30FC\u30C9\u306E\u30B3\u30B9\u30C8//[1]\u306B\u3064\u304D1\u679A\u3001\u5C71\u672D\u306E\u4E0A\u304B\u3089//\u30AB\u30FC\u30C9\u3092\u898B\u308B\u3002\u305D\u306E\u3046\u30611\u679A//\u3092\u624B\u672D\u306B\u52A0\u3048\u3001\u6B8B\u308A\u3092\u6368\u3066//\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.RELIC] = "[!2]////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E//\u4E0A\u306B\u81EA\u5206\u306E\uFF0D\uFF11\u30AB\u30FC\u30C9\u30C8\u30FC\u30AF\u30F3//\u3092\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.ROYAL_CARRIAGE] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u3053\u308C\u3092\u3042\u306A\u305F\u306E\u9152\u5834\u30DE\u30C3\u30C8\u306E//\u4E0A\u306B\u7F6E\u304F\u3002//---//\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u4F7F\u7528\u3057\u305F//\u5F8C\u3001\u305D\u308C\u304C\u5834\u306B\u6B8B\u3063\u3066\u3044\u308B\u5834\u5408\u3001//\u518D\u4F7F\u7528\u3059\u308B\u305F\u3081\u306B\u3001\u3053\u308C\u3092\u547C\u3073//\u51FA\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.STORYTELLER] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//\u624B\u672D\u304B\u3089\u8CA1\u5B9D\u30AB\u30FC\u30C9\u3092\u6700//\u59273\u679A\u4F7F\u7528\u3057\u3066\u3082\u3088\u3044\u3002//|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3001// \u6B21\u306B[]\u3092\u3059\u3079\u3066\u652F\u6255\u3044\u3001//\u652F\u6255\u3063\u305F[1]\u306B\u3064\u304D\u3001//|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.SWAMP_HAG] = "\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB//\u6642\u306B\u3001|\uFF0B|[3]\u3002////\u305D\u308C\u307E\u3067\u3001\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC//\u306F\u8CFC\u5165\u306B\u3088\u308A\u30AB\u30FC\u30C91\u679A\u3092//\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u546A\u30441\u679A\u3092//\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.TRANSMOGRIFY] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u3053\u308C\u3092\u3042\u306A\u305F\u306E\u9152\u5834\u30DE\u30C3\u30C8\u306E\u4E0A\u306B//\u7F6E\u304F\u3002//---//\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001\u624B\u672D//1\u679A\u3092\u5EC3\u68C4\u3057\u305D\u308C\u3088\u308A\u30B3\u30B9\u30C8\u304C\u6700//\u5927[1]\u9AD8\u3044\u30AB\u30FC\u30C91\u679A\u3092\u624B\u672D\u306B\u7372\u5F97//\u3059\u308B\u305F\u3081\u306B\u3001\u3053\u308C\u3092\u547C\u3073\u51FA\u3057\u3066\u3082//\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.TREASURE_TROVE] = "[!2]////\u91D1\u8CA81\u679A\u3068\u9285\u8CA81\u679A\u3092//\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.WINE_MERCHANT] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|//|\uFF0B[4]|//\u3053\u308C\u3092\u3042\u306A\u305F\u306E\u9152\u5834\u30DE\u30C3\u30C8\u306E\u4E0A\u306B\u7F6E\u304F\u3002//---//\u3042\u306A\u305F\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA\u306E\u7D42\u4E86\u6642\u3001[2]//\u4EE5\u4E0A\u6B8B\u3063\u3066\u3044\u308B\u5834\u5408\u3001\u3053\u308C\u3092\u9152\u5834//\u30DE\u30C3\u30C8\u304B\u3089\u6368\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002";

JapaneseCardTexts[CardNames.TREASURE_HUNTER] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B[1]|//\u3042\u306A\u305F\u306E\u53F3\u96A3\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306E\u6700\u5F8C\u306E\u30BF//\u30FC\u30F3\u306B\u305D\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u304C\u7372\u5F97\u3057\u305F\u30AB\u30FC//\u30C9\u306E\u679A\u6570\u3068\u540C\u3058\u679A\u6570\u306E\u9280\u8CA8\u3092\u7372\u5F97\u3059\u308B\u3002//---//\u3053\u308C\u3092\u5834\u304B\u3089\u6368\u3066\u672D\u306B\u3057\u305F\u3068\u304D\u3001\u3053\u308C//\u3092\u30A6\u30A9\u30EA\u30A2\u30FC\u3068\u4EA4\u63DB\u3057\u3066\u3082\u3088\u3044\u3002////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";
JapaneseCardTexts[CardNames.WARRIOR] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//\u3053\u308C\u3082\u542B\u3081\u305F\u3042\u306A\u305F\u306E\u5834\u306E\u30C8\u30E9\u30D9\u30E9\u30FC\u30AB\u30FC//\u30C91\u679A\u306B\u3064\u304D\u3001\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71//\u672D\u306E\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u6368\u3066\u672D\u306B\u3057\u3001\u305D\u306E\u30B3//\u30B9\u30C8\u304C[3]\u307E\u305F\u306F[4]\u306E\u5834\u5408\u3001\u5EC3\u68C4\u3059\u308B\u3002//---//\u3053\u308C\u3092\u5834\u304B\u3089\u6368\u3066\u672D\u306B\u3057\u305F\u3068\u304D\u3001//\u3053\u308C\u3092\u30D2\u30FC\u30ED\u30FC\u3068\u4EA4\u63DB\u3057\u3066\u3082\u3088\u3044\u3002//////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";
JapaneseCardTexts[CardNames.HERO] = "|\uFF0B[2]|//\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//---//\u3053\u308C\u3092\u5834\u304B\u3089\u6368\u3066\u672D\u306B\u3057\u305F\u3068\u304D\u3001\u3053\u308C//\u3092\u30C1\u30E3\u30F3\u30D4\u30AA\u30F3\u3068\u4EA4\u63DB\u3057\u3066\u3082\u3088\u3044\u3002//////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";
JapaneseCardTexts[CardNames.CHAMPION] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u30B2\u30FC\u30E0\u7D42\u4E86\u307E\u3067\u3001\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u304C//\u30A2\u30BF\u30C3\u30AF\u30AB\u30FC\u30C9\u3092\u4F7F\u7528\u3059\u308B\u3068\u304D\u3001\u305D//\u306E\u5F71\u97FF\u3092\u53D7\u3051\u305A\u3001\u3042\u306A\u305F\u304C\u30A2\u30AF\u30B7\u30E7\u30F3//\u30AB\u30FC\u30C91\u679A\u3092\u4F7F\u7528\u3059\u308B\u3068\u304D\u3001\u305D\u306E\u89E3\u6C7A//\u524D\u306B\u3001|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u3002////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";
JapaneseCardTexts[CardNames.SOLDIER] = "|\uFF0B[2]|////\u3042\u306A\u305F\u306E\u5834\u306E\u4ED6\u306E\u30A2\u30BF\u30C3\u30AF\u30AB\u30FC\u30C91\u679A//\u306B\u3064\u304D|\uFF0B|[1]\u3002\u624B\u672D\u304C4\u679A\u4EE5\u4E0A\u306E\u4ED6\u306E//\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u30011\u679A\u6368\u3066\u672D\u306B\u3059\u308B\u3002//---//\u3053\u308C\u3092\u5834\u304B\u3089\u6368\u3066\u672D\u306B\u3057\u305F\u3068\u304D\u3001//\u3053\u308C\u3092\u8131\u8D70\u5175\u3068\u4EA4\u63DB\u3057\u3066\u3082\u3088\u3044\u3002////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";
JapaneseCardTexts[CardNames.FUGITIVE] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//\u624B\u672D1\u679A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002//---//\u3053\u308C\u3092\u5834\u304B\u3089\u6368\u3066\u672D\u306B\u3057\u305F\u3068\u304D\u3001//\u3053\u308C\u3092\u9580\u4E0B\u751F\u3068\u4EA4\u63DB\u3057\u3066\u3082\u3088\u3044\u3002//////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";
JapaneseCardTexts[CardNames.DISCIPLE] = "\u624B\u672D\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092//2\u56DE\u4F7F\u7528\u3057\u3066\u3082\u3088\u3044\u3002\u305D\u308C\u3068\u540C\u3058//\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//---//\u3053\u308C\u3092\u5834\u304B\u3089\u6368\u3066\u672D\u306B\u3057\u305F\u3068\u304D\u3001//\u3053\u308C\u3092\u6559\u5E2B\u3068\u4EA4\u63DB\u3057\u3066\u3082\u3088\u3044\u3002//////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";
JapaneseCardTexts[CardNames.TEACHER] = "\u3053\u308C\u3092\u3042\u306A\u305F\u306E\u9152\u5834\u30DE\u30C3\u30C8\u306E\u4E0A\u306B\u7F6E\u304F\u3002//---//\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001\u3053\u308C\u3092\u547C\u3073//\u51FA\u3057\u3066\u3082\u3088\u3044\u3002\u547C\u3073\u51FA\u3057\u305F\u5834\u5408\u3001\u3042\u306A\u305F\u306E//\uFF0B\uFF11\u30AB\u30FC\u30C9\u3001\uFF0B\uFF11\u30A2\u30AF\u30B7\u30E7\u30F3\u3001\uFF0B\uFF11\u8CFC\u5165\u3001//\uFF0B[1]\u30C8\u30FC\u30AF\u30F3\u306E\u3044\u305A\u308C\u304B\u3092\u3001\u3042\u306A\u305F\u306E\u30C8\u30FC//\u30AF\u30F3\u304C\u5168\u304F\u4E57\u3063\u3066\u3044\u306A\u3044\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9//\u306E\u30B5\u30D7\u30E9\u30A4\u306E\u5C71\u306B\u79FB\u52D5\u3059\u308B\u3002  (\u3042\u306A\u305F\u304C//\u305D\u306E\u5C71\u306B\u7531\u6765\u3059\u308B\u30AB\u30FC\u30C9\u3092\u4F7F\u7528\u3059\u308B\u3068\u304D\u3001//\u3042\u306A\u305F\u306F\u5148\u306B\u305D\u306E\u7279\u5178\u3092\u5F97\u308B\u3002)////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";

JapaneseCardTexts[CardNames.ALMS] = "1\u30BF\u30FC\u30F3\u306B1\u5EA6\u306E\u307F:\u5834\u306B\u8CA1\u5B9D\u30AB\u30FC\u30C9\u304C\u306A\u3044\u5834\u5408\u3001//\u30B3\u30B9\u30C8[4]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.BALL] = "\u3042\u306A\u305F\u306E\uFF0D[1]\u30C8\u30FC\u30AF\u30F3\u3092\u5F97\u308B\u3002//\u30B3\u30B9\u30C8[4]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C92\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.BONFIRE] = "\u3042\u306A\u305F\u306E\u5834\u306E\u9285\u8CA8\u3092\u6700\u59272\u679A\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.BORROW] = "1\u30BF\u30FC\u30F3\u306B1\u5EA6\u306E\u307F:|\uFF0B\uFF11\u3000\u8CFC\u5165|\u3001\u5C71\u672D\u306E\u4E0A\u306B\u3042\u306A\u305F\u306E\uFF0D\uFF11//\u30AB\u30FC\u30C9\u30C8\u30FC\u30AF\u30F3\u304C\u306A\u3044\u5834\u5408\u3001\u305D\u308C\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u304D\u3001|\uFF0B|[1]\u3002";
JapaneseCardTexts[CardNames.BRIDGE_TROLL] = "\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u81EA//\u5206\u306E\uFF0D[1]\u30C8\u30FC\u30AF\u30F3\u3092\u5F97\u308B\u3002////\u3053\u306E\u30BF\u30FC\u30F3\u3068\u3042\u306A\u305F\u306E\u6B21\u306E//\u30BF\u30FC\u30F3\u4E2D\u3001\u3059\u3079\u3066\u306E\u30AB\u30FC\u30C9//\u306E\u30B3\u30B9\u30C8\u306F[1]\u4E0B\u304C\u308B\u3002////\u73FE\u5728\u3068\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3//\u306E\u958B\u59CB\u6642\u306B\u3001|\uFF0B\uFF11\u3000\u8CFC\u5165|\u3002";
JapaneseCardTexts[CardNames.EXPEDITION] = "\u30AF\u30EA\u30FC\u30F3\u30A2\u30C3\u30D7\u30D5\u30A7\u30A4\u30BA\u306B\u8FFD\u52A0\u30672\u679A\u30AB\u30FC\u30C9\u3092\u5F15\u304F\u3002";
JapaneseCardTexts[CardNames.FERRY] = "\u3042\u306A\u305F\u306E\uFF0D[2]\u30C8\u30FC\u30AF\u30F3\u3092\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u306E\u30B5\u30D7\u30E9\u30A4\u306E//\u5C71\u306B\u79FB\u52D5\u3059\u308B(\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u4E2D\u3001\u305D\u306E\u5C71\u306B\u7531\u6765\u3059\u308B//\u30AB\u30FC\u30C9\u306E\u30B3\u30B9\u30C8\u306F[2]\u4E0B\u304C\u308B)\u3002";
JapaneseCardTexts[CardNames.INHERITANCE] = "\u30B2\u30FC\u30E0\u4E2D\u306B1\u5EA6\u306E\u307F:\u30B5\u30D7\u30E9\u30A4\u304B\u3089\u547D\u4EE4\u30AB\u30FC\u30C9\u4EE5\u5916\u306E\u30B3\u30B9\u30C8[4]\u4EE5\u4E0B\u306E\u30A2\u30AF//\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u8107\u306B\u7F6E\u304F\u3002\u305D\u306E\u4E0A\u306B\u3042\u306A\u305F\u306E\u5C4B\u6577\u30C8\u30FC\u30AF\u30F3\u3092\u79FB\u52D5\u3059\u308B//(\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u4E2D\u3001\u5C4B\u6577\u306F\u8FFD\u52A0\u3067\u547D\u4EE4\u304B\u3064\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u3067\u3082\u3042\u308A\u3001\u80FD//\u529B\u300C\u3042\u306A\u305F\u306E\u5C4B\u6577\u30C8\u30FC\u30AF\u30F3\u3092\u4E57\u305B\u305F\u30AB\u30FC\u30C9\u3092\u52D5\u304B\u3055\u305A\u306B\u4F7F\u7528\u3059\u308B\u300D\u3092\u6301\u3064)\u3002";
JapaneseCardTexts[CardNames.LOST_ARTS] = "\u3042\u306A\u305F\u306E\uFF0B\uFF11\u30A2\u30AF\u30B7\u30E7\u30F3\u30C8\u30FC\u30AF\u30F3\u3092\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9//\u306E\u30B5\u30D7\u30E9\u30A4\u306E\u5C71\u306B\u79FB\u52D5\u3059\u308B(\u3042\u306A\u305F\u304C\u305D\u306E\u5C71\u306B\u7531\u6765\u3059\u308B//\u30AB\u30FC\u30C9\u3092\u4F7F\u7528\u3059\u308B\u3068\u304D\u3001\u5148\u306B|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u3092\u5F97\u308B)\u3002";
JapaneseCardTexts[CardNames.MISSION] = "\u3053\u306E\u30BF\u30FC\u30F3\u306E\u5F8C\u306B\u8FFD\u52A0\u306E1\u30BF\u30FC\u30F3\u3092\u5F97\u308B(\u305F\u3060\u3057\u3001\u9023\u7D9A//3\u30BF\u30FC\u30F3\u3068\u306A\u308B\u5834\u5408\u306F\u5F97\u3089\u308C\u306A\u3044)\u3002\u305D\u306E\u30BF\u30FC\u30F3\u3001\u30AB\u30FC//\u30C9\u3092\u8CFC\u5165\u3067\u304D\u306A\u3044(\u30A4\u30D9\u30F3\u30C8\u306F\u8CFC\u5165\u53EF)\u3002";
JapaneseCardTexts[CardNames.PATHFINDING] = "\u3042\u306A\u305F\u306E\uFF0B\uFF11\u30AB\u30FC\u30C9\u30C8\u30FC\u30AF\u30F3\u3092\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u306E//\u30B5\u30D7\u30E9\u30A4\u306E\u5C71\u306B\u79FB\u52D5\u3059\u308B (\u3042\u306A\u305F\u304C\u305D\u306E\u5C71\u306B\u7531\u6765\u3059\u308B//\u30AB\u30FC\u30C9\u3092\u4F7F\u7528\u3059\u308B\u3068\u304D\u3001\u5148\u306B|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|)\u3002";
JapaneseCardTexts[CardNames.PILGRIMAGE] = "1\u30BF\u30FC\u30F3\u306B1\u5EA6\u306E\u307F:\u65C5\u30C8\u30FC\u30AF\u30F3\u3092\u88CF\u8FD4\u3057(\u958B\u59CB\u6642\u306F\u8868\u5411\u304D)\u3001//\u8868\u5411\u304D\u306B\u306A\u3063\u305F\u5834\u5408\u3001\u5834\u306E\u30AB\u30FC\u30C9\u3092\u6700\u59273\u7A2E\u985E\u9078\u3073\u3001\u305D\u308C\u3089//\u3068\u540C\u3058\u30AB\u30FC\u30C9\u30921\u679A\u305A\u3064\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.PLAN] = "\u3042\u306A\u305F\u306E\u5EC3\u68C4\u30C8\u30FC\u30AF\u30F3\u3092\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u306E\u30B5\u30D7\u30E9\u30A4\u306E//\u5C71\u306B\u79FB\u52D5\u3059\u308B(\u3042\u306A\u305F\u304C\u305D\u306E\u5C71\u304B\u3089\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F//\u3068\u304D\u3001\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044)\u3002";
JapaneseCardTexts[CardNames.QUEST] = "\u30A2\u30BF\u30C3\u30AF\u30AB\u30FC\u30C91\u679A\u304B\u3001\u546A\u30442\u679A\u304B\u3001\u30AB\u30FC\u30C96\u679A\u3092\u6368\u3066//\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002\u305D\u3046\u3057\u305F\u5834\u5408\u3001\u91D1\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.RAID] = "\u5834\u306E\u9280\u8CA81\u679A\u306B\u3064\u304D\u9280\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC//\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E\u4E0A\u306B\u81EA\u5206\u306E\uFF0D\uFF11\u30AB\u30FC\u30C9\u30C8\u30FC\u30AF\u30F3\u3092\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.SAVE] = "1\u30BF\u30FC\u30F3\u306B1\u5EA6\u306E\u307F:|\uFF0B\uFF11\u3000\u8CFC\u5165|\u3001\u624B\u672D1\u679A\u3092\u8107\u306B\u7F6E\u304D\u3001//\u30BF\u30FC\u30F3\u7D42\u4E86\u6642(\u624B\u672D\u3092\u5F15\u3044\u305F\u5F8C)\u306B\u305D\u308C\u3092\u624B\u672D\u306B\u52A0\u3048\u308B\u3002";
JapaneseCardTexts[CardNames.SCOUTING_PARTY] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|//\u5C71\u672D\u306E\u4E0A\u304B\u30895\u679A\u3092\u898B\u308B\u3002\u305D\u306E\u4E2D\u306E3\u679A\u3092\u6368\u3066\u672D\u306B\u3057\u3001//\u6B8B\u308A\u3092\u597D\u304D\u306A\u9806\u756A\u3067\u5C71\u672D\u306E\u4E0A\u306B\u623B\u3059\u3002";
JapaneseCardTexts[CardNames.SEAWAY] = "\u30B3\u30B9\u30C8[4]\u4EE5\u4E0B\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002\u305D\u306E\u30AB\u30FC\u30C9//\u306E\u5C71\u306B\u3042\u306A\u305F\u306E\uFF0B\uFF11\u8CFC\u5165\u30C8\u30FC\u30AF\u30F3\u3092\u79FB\u52D5\u3059\u308B(\u3042\u306A\u305F\u304C\u305D\u306E\u5C71//\u306B\u7531\u6765\u3059\u308B\u30AB\u30FC\u30C9\u3092\u4F7F\u7528\u3059\u308B\u3068\u304D\u3001\u5148\u306B|\uFF0B\uFF11\u3000\u8CFC\u5165|\u3092\u5F97\u308B)\u3002";
JapaneseCardTexts[CardNames.TRAVELLING_FAIR] = "|\uFF0B\uFF12\u3000\u8CFC\u5165|//\u3053\u306E\u30BF\u30FC\u30F3\u3001\u3042\u306A\u305F\u304C\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001//\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.TRADE] = "\u624B\u672D\u3092\u6700\u59272\u679A\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002\u5EC3\u68C4\u3057\u305F//\u30AB\u30FC\u30C9\u3068\u540C\u3058\u679A\u6570\u306E\u9280\u8CA8\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.TRAINING] = "\u3042\u306A\u305F\u306E\uFF0B[1]\u30C8\u30FC\u30AF\u30F3\u3092\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u306E\u30B5\u30D7\u30E9\u30A4//\u306E\u5C71\u306B\u79FB\u52D5\u3059\u308B(\u3042\u306A\u305F\u304C\u305D\u306E\u5C71\u306B\u7531\u6765\u3059\u308B\u30AB\u30FC\u30C9\u3092//\u4F7F\u7528\u3059\u308B\u3068\u304D\u3001\u5148\u306B|\uFF0B|[1]\u3092\u5F97\u308B)\u3002";

//Empires
JapaneseCardTexts[CardNames.ARCHIVE] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u5C71\u672D\u306E\u4E0A\u304B\u30893\u679A\u3092\u4F0F\u305B\u3066//\u8107\u306B\u7F6E\u304F (\u3042\u306A\u305F\u306F\u898B\u3066\u3082//\u3088\u3044)\u3002\u73FE\u5728\u3068\u3042\u306A\u305F\u306E\u6B21\u306E//2\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001\u305D\u306E//\u4E2D\u306E1\u679A\u3092\u624B\u672D\u306B\u52A0\u3048\u308B\u3002";
JapaneseCardTexts[CardNames.CAPITAL] = "[!6]//|\uFF0B\uFF11\u3000\u8CFC\u5165|//---//\u3053\u308C\u3092\u5834\u304B\u3089\u6368\u3066\u672D\u306B\u3057\u305F\u3068\u304D\u3001//[6D]\u3092\u5F97\u3066\u3001\u305D\u306E\u5F8C\u306B[D]\u3092\u8FD4\u6E08//\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.CHARM] = "\u6B21\u306E\u3046\u30611\u3064\u3092\u9078\u3076:////\u300C|\uFF0B|[2]\u3001|\uFF0B\uFF11\u3000\u8CFC\u5165|\u300D;//\u300C\u3053\u306E\u30BF\u30FC\u30F3\u3001\u6B21\u306B\u30AB\u30FC\u30C9// \u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u305D\u308C\u3068// \u540C\u30B3\u30B9\u30C8\u306E\u7570\u306A\u308B\u30AB\u30FC\u30C9//1\u679A\u3092\u7372\u5F97\u3057\u3066\u3082\u3088\u3044\u300D";
JapaneseCardTexts[CardNames.CHARIOT_RACE] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u516C\u958B\u3057\u3001//\u624B\u672D\u306B\u52A0\u3048\u308B\u3002\u5DE6\u96A3\u306E\u30D7\u30EC\u30A4\u30E4\u30FC//\u306F\u81EA\u5206\u306E\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u3092//\u516C\u958B\u3059\u308B\u3002\u3042\u306A\u305F\u306E\u30AB\u30FC\u30C9\u306E\u65B9\u304C//\u30B3\u30B9\u30C8\u304C\u9AD8\u3044\u5834\u5408\u3001|\uFF0B|[1]\u3001|\uFF0B|{1}\u3002";
JapaneseCardTexts[CardNames.CITY_QUARTER] = "|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D\u3092\u516C\u958B\u3057\u3001\u305D\u306E\u4E2D\u306E\u30A2//\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u306B\u3064\u304D\u3001//|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.CROWN] = "\u3042\u306A\u305F\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30D5\u30A7\u30A4\u30BA//\u4E2D\u306E\u5834\u5408\u3001\u624B\u672D\u306E\u30A2\u30AF\u30B7\u30E7\u30F3//\u30AB\u30FC\u30C91\u679A\u30922\u56DE\u4F7F\u7528\u3057\u3066\u3082\u3088\u3044\u3002//\u3042\u306A\u305F\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA\u4E2D\u306E\u5834\u5408\u3001//\u624B\u672D\u306E\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A\u30922\u56DE\u4F7F\u7528//\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.ENCHANTRESS] = "\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u307E\u3067\u3001\u4ED6//\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u304C\u5404\u81EA\u306E\u30BF\u30FC\u30F3\u306B\u521D//\u3081\u3066\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u3092\u4F7F\u7528\u3059\u308B//\u3068\u304D\u3001\u305D\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u305D\u306E\u6307\u793A//\u306E\u4EE3\u308F\u308A\u306B|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3001//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u3092\u5F97\u308B\u3002////\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u3001//|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.ENGINEER] = "\u30B3\u30B9\u30C8[4]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C91\u679A\u3092//\u7372\u5F97\u3059\u308B\u3002////\u3053\u308C\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002\u5EC3//\u68C4\u3057\u305F\u5834\u5408\u3001\u8FFD\u52A0\u3067\u30B3\u30B9\u30C8//[4]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97//\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.FARMERS_MARKET] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|////\u8FB2\u5BB6\u306E\u5E02\u5834\u306E\u5C71\u306E\u4E0A\u306B{4}\u4EE5\u4E0A//\u3042\u308B\u5834\u5408\u3001{}\u3092\u3059\u3079\u3066\u5F97\u3066//\u3053\u308C\u3092\u5EC3\u68C4\u3059\u308B\u3002////\u305D\u308C\u4EE5\u5916\u306E\u5834\u5408\u3001\u8FB2\u5BB6\u306E\u5E02\u5834\u306E//\u5C71\u306E\u4E0A\u306B{1}\u8FFD\u52A0\u3057\u3001\u305D\u306E\u5F8C\u3001//\u5C71\u306E\u4E0A\u306E{1}\u306B\u3064\u304D\u3001|\uFF0B|[1]\u3002";
JapaneseCardTexts[CardNames.FORUM] = "|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D2\u679A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001//|\uFF0B\uFF11\u3000\u8CFC\u5165|\u3002";
JapaneseCardTexts[CardNames.GROUNDSKEEPER] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u3053\u306E\u30BF\u30FC\u30F3\u3001\u52DD\u5229\u70B9\u30AB\u30FC\u30C9//1\u679A\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001|\uFF0B|{1}\u3002";
JapaneseCardTexts[CardNames.LEGIONARY] = "|\uFF0B[3]|////\u624B\u672D\u304B\u3089\u91D1\u8CA81\u679A\u3092\u516C\u958B\u3057\u3066//\u3082\u3088\u3044\u3002\u516C\u958B\u3057\u305F\u5834\u5408\u3001\u4ED6\u306E//\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u624B\u672D\u304C//2\u679A\u306B\u306A\u308B\u3088\u3046\u306B\u6368\u3066\u672D\u306B\u3057\u3001//\u305D\u306E\u5F8C1\u679A\u30AB\u30FC\u30C9\u3092\u5F15\u304F\u3002";
JapaneseCardTexts[CardNames.OVERLORD] = "\u30B5\u30D7\u30E9\u30A4\u306B\u3042\u308B\u30B3\u30B9\u30C8[5]\u4EE5\u4E0B//\u306E\u3001\u547D\u4EE4\u30AB\u30FC\u30C9\u3067\u306F\u306A\u3044\u30A2\u30AF//\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u3001\u52D5\u304B\u3055//\u305A\u306B\u4F7F\u7528\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.ROYAL_BLACKSMITH] = "|\uFF0B\uFF15\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////\u624B\u672D\u3092\u516C\u958B\u3057\u3001\u9285\u8CA8\u3092//\u3059\u3079\u3066\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.SACRIFICE] = " \u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002//\u305D\u306E\u30AB\u30FC\u30C9\u304C...////\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001//|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3001// |\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3| ////\u8CA1\u5B9D\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001|\uFF0B|[2]////\u52DD\u5229\u70B9\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001|\uFF0B|{2}";
JapaneseCardTexts[CardNames.TEMPLE] = "|\uFF0B{1}|////\u624B\u672D\u304B\u30891\u679A\u4EE5\u4E0A3\u679A\u4EE5\u4E0B\u306E//\u7570\u306A\u308B\u30AB\u30FC\u30C9\u3092\u5EC3\u68C4\u3059\u308B\u3002////\u795E\u6BBF\u306E\u5C71\u306E\u4E0A\u306B{1}\u3092\u8FFD\u52A0\u3059\u308B\u3002////---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u795E\u6BBF\u306E//\u5C71\u306E\u4E0A\u306E{}\u3092\u3059\u3079\u3066\u5F97\u308B\u3002";
JapaneseCardTexts[CardNames.VILLA] = "|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B\uFF11\u3000\u8CFC\u5165|//|\uFF0B[1]|//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u624B\u672D\u306B\u52A0\u3048\u3001//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u3001\u3042\u306A\u305F\u306E\u8CFC\u5165//\u30D5\u30A7\u30A4\u30BA\u4E2D\u306E\u5834\u5408\u3001\u30A2\u30AF\u30B7\u30E7\u30F3//\u30D5\u30A7\u30A4\u30BA\u306B\u623B\u308B\u3002";
JapaneseCardTexts[CardNames.WILD_HUNT] = "\u6B21\u306E\u3046\u30611\u3064\u3092\u9078\u3076:////\u300C|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3001\u30EF\u30A4//\u30EB\u30C9\u30CF\u30F3\u30C8\u306E\u5C71\u306E\u4E0A\u306B{1}\u3092//\u8FFD\u52A0\u3059\u308B\u300D;////\u300C\u5C4B\u6577\u3092\u7372\u5F97\u3059\u308B\u3002\u7372\u5F97\u3057\u305F//\u5834\u5408\u3001\u30EF\u30A4\u30EB\u30C9\u30CF\u30F3\u30C8\u306E\u5C71\u306E//\u4E0A\u306E{}\u3092\u3059\u3079\u3066\u5F97\u308B\u300D";

JapaneseCardTexts[CardNames.ADVANCE] = "\u624B\u672D\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002\u5EC3\u68C4\u3057\u305F//\u5834\u5408\u3001\u30B3\u30B9\u30C8[6]\u4EE5\u4E0B\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.ANNEX] = "\u6368\u3066\u672D\u7F6E\u304D\u5834\u306E\u30AB\u30FC\u30C9\u306E\u3046\u3061\u3001\u6700\u5927\u30675\u679A\u3092\u9664\u3044\u305F\u6B8B\u308A\u3092//\u3059\u3079\u3066\u5C71\u672D\u306B\u6DF7\u305C\u30B7\u30E3\u30C3\u30D5\u30EB\u3059\u308B\u3002\u516C\u98181\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.BANQUET] = "\u9285\u8CA82\u679A\u3068\u30B3\u30B9\u30C8[5]\u4EE5\u4E0B\u306E\u52DD\u5229\u70B9//\u4EE5\u5916\u306E\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.CONQUEST] = "\u9280\u8CA82\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//\u3053\u306E\u30BF\u30FC\u30F3\u306B\u7372\u5F97\u3057\u305F\u9280\u8CA81\u679A\u306B\u3064\u304D|\uFF0B|{1}\u3002";
JapaneseCardTexts[CardNames.DELVE] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|//\u9280\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.DOMINATE] = "\u5C5E\u5DDE1\u679A\u3092\u7372\u5F97\u3059\u308B\u3002\u7372\u5F97\u3057\u305F\u5834\u5408\u3001|\uFF0B|{9}\u3002";
JapaneseCardTexts[CardNames.DONATE] = "\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u3001\u4ED6\u306E\u52B9\u679C\u306E\u89E3\u6C7A\u524D\u306B\u3001\u5C71\u672D\u3068\u6368\u3066\u672D\u7F6E\u304D\u5834//\u306E\u30AB\u30FC\u30C9\u3092\u3059\u3079\u3066\u624B\u672D\u306B\u52A0\u3048\u3001\u305D\u3053\u304B\u3089\u597D\u304D\u306A\u679A\u6570\u306E\u30AB\u30FC\u30C9\u3092\u5EC3\u68C4\u3057\u3001\u624B//\u672D\u3092\u3059\u3079\u3066\u5C71\u672D\u306B\u6DF7\u305C\u30B7\u30E3\u30C3\u30D5\u30EB\u3059\u308B\u3002\u305D\u306E\u5F8C5\u679A\u30AB\u30FC\u30C9\u3092\u5F15\u304F\u3002";
JapaneseCardTexts[CardNames.RITUAL] = "\u546A\u30441\u679A\u3092\u7372\u5F97\u3059\u308B\u3002\u7372\u5F97\u3057\u305F\u5834\u5408\u3001\u624B\u672D1\u679A\u3092\u5EC3\u68C4//\u3059\u308B\u3002\u5EC3\u68C4\u3057\u305F\u30AB\u30FC\u30C9\u306E\u5EC3\u68C4\u524D\u306E\u30B3\u30B9\u30C8[1]\u306B\u3064\u304D//|\uFF0B|{1}\u3002";
JapaneseCardTexts[CardNames.SALT_THE_EARTH] = "|\uFF0B{1}|//\u30B5\u30D7\u30E9\u30A4\u304B\u3089\u52DD\u5229\u70B9\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.TAX] = "\u30B5\u30D7\u30E9\u30A41\u5C71\u306E\u4E0A\u306B[2D]\u3092\u8FFD\u52A0\u3059\u308B\u3002//---//\u6E96\u5099:\u3059\u3079\u3066\u306E\u30B5\u30D7\u30E9\u30A4\u306E\u5C71\u306E\u4E0A\u306B[1D]\u3092\u8FFD\u52A0\u3059\u308B\u3002//\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u81EA\u5206\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA\u306B\u30AB\u30FC\u30C9\u3092\u7372\u5F97\u3057\u305F//\u3068\u304D\u3001\u305D\u306E\u30AB\u30FC\u30C9\u306E\u5C71\u306E\u4E0A\u306E[D]\u3092\u3059\u3079\u3066\u5F97\u308B\u3002";
JapaneseCardTexts[CardNames.TRIUMPH] = "\u5C4B\u65771\u679A\u3092\u7372\u5F97\u3059\u308B\u3002\u7372\u5F97\u3057\u305F\u5834\u5408\u3001\u3053\u306E\u30BF\u30FC\u30F3//\u306B\u7372\u5F97\u3057\u305F\u30AB\u30FC\u30C91\u679A\u306B\u3064\u304D|\uFF0B|{1}\u3002";

JapaneseCardTexts[CardNames.AQUEDUCT] = "\u3042\u306A\u305F\u304C\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u305D\u306E\u5C71\u306E\u4E0A\u306E{1}\u3092\u3053\u306E\u4E0A\u306B\u79FB\u52D5//\u3059\u308B\u3002\u3042\u306A\u305F\u304C\u52DD\u5229\u70B9\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u3053\u306E\u4E0A\u306E{}\u3092\u5F97\u308B\u3002//---//\u6E96\u5099:\u9280\u8CA8\u30FB\u91D1\u8CA8\u306E\u5C71\u306E\u4E0A\u306B{8}\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.ARENA] = "\u3042\u306A\u305F\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA\u306E\u958B\u59CB\u6642\u3001\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092//\u6368\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002\u6368\u3066\u672D\u306B\u3057\u305F\u5834\u5408\u3001\u3053\u306E\u4E0A\u304B\u3089{2}\u5F97\u308B\u3002//---//\u6E96\u5099:\u30D7\u30EC\u30A4\u30E4\u30FC1\u4EBA\u306B\u3064\u304D{6}\u3092\u3053\u306E\u4E0A\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.BANDIT_FORT] = "\u3042\u306A\u305F\u306E\u9280\u8CA8\u307E\u305F\u306F\u91D1\u8CA81\u679A\u306B\u3064\u304D{-2}\u3002";
JapaneseCardTexts[CardNames.BASILICA] = "\u3042\u306A\u305F\u304C\u81EA\u5206\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA\u306B\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F//\u3068\u304D\u3001[2]\u4EE5\u4E0A\u6301\u3063\u3066\u3044\u308B\u5834\u5408\u3001\u3053\u306E\u4E0A\u304B\u3089{2}\u5F97\u308B\u3002//---//\u6E96\u5099:\u30D7\u30EC\u30A4\u30E4\u30FC1\u4EBA\u306B\u3064\u304D{6}\u3092\u3053\u306E\u4E0A\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.BATHS] = "\u3042\u306A\u305F\u304C\u4F55\u3082\u30AB\u30FC\u30C9\u3092\u7372\u5F97\u305B\u305A\u81EA\u5206\u306E//\u30BF\u30FC\u30F3\u3092\u7D42\u3048\u308B\u3068\u304D\u3001\u3053\u306E\u4E0A\u304B\u3089{2}\u5F97\u308B\u3002//---//\u6E96\u5099:\u30D7\u30EC\u30A4\u30E4\u30FC1\u4EBA\u306B\u3064\u304D{6}\u3092\u3053\u306E\u4E0A\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.BATTLEFIELD] = "\u3042\u306A\u305F\u304C\u52DD\u5229\u70B9\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u3053\u306E\u4E0A\u304B\u3089{2}\u5F97\u308B\u3002//---//\u6E96\u5099:\u30D7\u30EC\u30A4\u30E4\u30FC1\u4EBA\u306B\u3064\u304D{6}\u3092\u3053\u306E\u4E0A\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.COLONNADE] = "\u3042\u306A\u305F\u304C\u81EA\u5206\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA\u306B\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u7372//\u5F97\u3057\u305F\u3068\u304D\u3001\u5834\u306B\u305D\u308C\u3068\u540C\u3058\u30AB\u30FC\u30C9\u3092\u51FA\u3057\u3066\u3044\u308B\u5834\u5408\u3001//\u3053\u306E\u4E0A\u304B\u3089{2}\u5F97\u308B\u3002//---//\u6E96\u5099:\u30D7\u30EC\u30A4\u30E4\u30FC1\u4EBA\u306B\u3064\u304D{6}\u3092\u3053\u306E\u4E0A\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.DEFILED_SHRINE] = "\u3042\u306A\u305F\u304C\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u305D\u306E\u5C71\u306E\u4E0A\u306E{1}\u3092//\u3053\u306E\u4E0A\u306B\u79FB\u52D5\u3059\u308B\u3002\u3042\u306A\u305F\u304C\u81EA\u5206\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA\u306B\u546A\u30441\u679A\u3092\u7372\u5F97//\u3057\u305F\u3068\u304D\u3001\u3053\u306E\u4E0A\u306E{}\u3092\u3059\u3079\u3066\u5F97\u308B\u3002//---//\u6E96\u5099:\u96C6\u5408\u4EE5\u5916\u306E\u5404\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u306E\u30B5\u30D7\u30E9\u30A4\u306E\u5C71\u306E\u4E0A\u306B{2}\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.FOUNTAIN] = "\u30B2\u30FC\u30E0\u7D42\u4E86\u6642\u3001\u3042\u306A\u305F\u304C10\u679A\u4EE5\u4E0A//\u9285\u8CA8\u3092\u6301\u3063\u3066\u3044\u308B\u5834\u5408\u3001{15}\u3002";
JapaneseCardTexts[CardNames.KEEP] = "\u30B2\u30FC\u30E0\u7D42\u4E86\u6642\u3001\u3042\u306A\u305F\u304C\u8AB0\u3088\u308A\u3082\u591A\u304F(\u540C\u6570\u3067\u3082//\u3088\u3044)\u6301\u3063\u3066\u3044\u308B\u8CA1\u5B9D\u30AB\u30FC\u30C91\u7A2E\u985E\u306B\u3064\u304D{5}\u3002";
JapaneseCardTexts[CardNames.LABYRINTH] = "\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u306B2\u679A\u76EE\u306E\u30AB\u30FC\u30C9\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u3053\u306E\u4E0A\u304B\u3089{2}\u5F97\u308B\u3002//---//\u6E96\u5099:\u30D7\u30EC\u30A4\u30E4\u30FC1\u4EBA\u306B\u3064\u304D{6}\u3092\u3053\u306E\u4E0A\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.MOUNTAIN_PASS] = "\u5C5E\u5DDE\u304C\u6700\u521D\u306B\u7372\u5F97\u3055\u308C\u305F\u3068\u304D\u3001\u7372\u5F97\u3057\u305F\u30D7\u30EC\u30A4\u30E4\u30FC\u3092\u6700\u5F8C//\u3068\u3057\u3066\u3001\u5404\u30D7\u30EC\u30A4\u30E4\u30FC\u306F1\u56DE\u305A\u3064\u3001[40D]\u3092\u4E0A\u9650\u3068\u3057\u3066\u7AF6\u308A\u3092//\u3059\u308B\u3002\u6700\u9AD8\u5165\u672D\u8005\u306F\u3001|\uFF0B|{8}\u3001\u63D0\u793A\u3057\u305F[D]\u3092\u5F97\u308B\u3002";
JapaneseCardTexts[CardNames.MUSEUM] = "\u3042\u306A\u305F\u306E\u30AB\u30FC\u30C91\u7A2E\u985E\u306B\u3064\u304D{2}\u3002";
JapaneseCardTexts[CardNames.OBELISK] = "\u30B2\u30FC\u30E0\u7D42\u4E86\u6642\u3001\u6307\u5B9A\u3055\u308C\u305F\u5C71\u306B\u7531\u6765\u3059\u308B\u3042\u306A\u305F\u306E\u30AB\u30FC\u30C91\u679A\u306B\u3064\u304D{2}\u3002//---//\u6E96\u5099:\u30E9\u30F3\u30C0\u30E0\u306B\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u306E\u30B5\u30D7\u30E9\u30A4\u306E\u5C71\u3092\u6307\u5B9A\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.ORCHARD] = "\u30B2\u30FC\u30E0\u7D42\u4E86\u6642\u3001\u3042\u306A\u305F\u304C3\u679A\u4EE5\u4E0A\u6301\u3063\u3066\u3044\u308B//\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u7A2E\u985E\u306B\u3064\u304D{4}\u3002";
JapaneseCardTexts[CardNames.PALACE] = "\u30B2\u30FC\u30E0\u7D42\u4E86\u6642\u3001\u3042\u306A\u305F\u304C\u6301\u3063\u3066\u3044\u308B//\u9285\u8CA8\u30FB\u9280\u8CA8\u30FB\u91D1\u8CA81\u7D44\u306B\u3064\u304D{3}\u3002";
JapaneseCardTexts[CardNames.TOMB] = "\u3042\u306A\u305F\u304C\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u305F\u3068\u304D\u3001|\uFF0B|{1}\u3002";
JapaneseCardTexts[CardNames.TOWER] = "\u30B2\u30FC\u30E0\u7D42\u4E86\u6642\u3001\u7A7A\u306E\u30B5\u30D7\u30E9\u30A4\u306E\u5C71\u306B\u7531\u6765\u3059\u308B//\u3042\u306A\u305F\u306E\u52DD\u5229\u70B9\u4EE5\u5916\u306E\u30AB\u30FC\u30C91\u679A\u306B\u3064\u304D{1}\u3002";
JapaneseCardTexts[CardNames.TRIUMPHAL_ARCH] = "\u30B2\u30FC\u30E0\u7D42\u4E86\u6642\u3001\u3042\u306A\u305F\u304C\u6301\u3063\u3066\u3044\u308B\u4E2D\u30672\u756A\u76EE\u306B//\u591A\u3044\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u306B\u3064\u304D{3}(\u540C\u6570\u306E\u5834\u5408\u3001//\u3044\u305A\u308C\u304B1\u7A2E\u985E\u3092\u6570\u3048\u308B)\u3002";
JapaneseCardTexts[CardNames.WALL] = "\u30B2\u30FC\u30E0\u7D42\u4E86\u6642\u3001\u3042\u306A\u305F\u304C\u6301\u3063\u3066\u3044\u308B//15\u679A\u3092\u8D85\u3048\u308B\u30AB\u30FC\u30C91\u679A\u306B\u3064\u304D|\uFF0D|{1}\u3002";
JapaneseCardTexts[CardNames.WOLF_DEN] = "\u30B2\u30FC\u30E0\u7D42\u4E86\u6642\u3001\u3042\u306A\u305F\u304C1\u679A\u3060\u3051//\u6301\u3063\u3066\u3044\u308B\u30AB\u30FC\u30C91\u7A2E\u985E\u306B\u3064\u304D|\uFF0D|{3}\u3002";
JapaneseCardTexts[CardNames.WEDDING] = "|\uFF0B|{1}//\u91D1\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.WINDFALL] = "\u5C71\u672D\u3068\u6368\u3066\u672D\u304C\u306A\u3044\u5834\u5408\u3001\u91D1\u8CA83\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";

JapaneseCardTexts[CardNames.ENCAMPMENT] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D\u304B\u3089\u91D1\u8CA8\u304B\u9E75\u7372\u54C1\u3092\u516C\u958B\u3057\u3066\u3082//\u3088\u3044\u3002\u516C\u958B\u3057\u306A\u304B\u3063\u305F\u5834\u5408\u3001\u3053\u308C\u3092//\u8107\u306B\u7F6E\u304D\u3001\u30AF\u30EA\u30FC\u30F3\u30A2\u30C3\u30D7\u30D5\u30A7\u30A4\u30BA//\u306E\u958B\u59CB\u6642\u306B\u9663\u5730\u306E\u5C71\u306B\u623B\u3059\u3002";
JapaneseCardTexts[CardNames.PLUNDER] = "[!2]//|\uFF0B{1}|";
JapaneseCardTexts[CardNames.PATRICIAN] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u516C\u958B\u3059\u308B\u3002//\u305D\u308C\u304C\u30B3\u30B9\u30C8[5]\u4EE5\u4E0A\u306E\u5834\u5408\u3001//\u624B\u672D\u306B\u52A0\u3048\u308B\u3002";
JapaneseCardTexts[CardNames.EMPORIUM] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B[1]|//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u5834\u306B5\u679A\u4EE5\u4E0A\u306E//\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u304C\u3042\u308B\u5834\u5408\u3001|\uFF0B|{2}\u3002";
JapaneseCardTexts[CardNames.SETTLERS] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u6368\u3066\u672D\u7F6E\u304D\u5834\u304B\u3089\u9285\u8CA81\u679A\u3092//\u516C\u958B\u3057\u3066\u624B\u672D\u306B\u52A0\u3048\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.BUSTLING_VILLAGE] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF13\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u6368\u3066\u672D\u7F6E\u304D\u5834\u304B\u3089\u958B\u62D3\u80051\u679A\u3092//\u516C\u958B\u3057\u3066\u624B\u672D\u306B\u52A0\u3048\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.CATAPULT] = "|\uFF0B[1]|////\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002\u305D\u308C\u304C\u30B3\u30B9\u30C8[3]//\u4EE5\u4E0A\u306E\u5834\u5408\u3001\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001//\u546A\u30441\u679A\u3092\u7372\u5F97\u3059\u308B\u3002\u8CA1\u5B9D\u30AB\u30FC\u30C9\u306E\u5834//\u5408\u3001\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u624B\u672D\u304C//3\u679A\u306B\u306A\u308B\u3088\u3046\u306B\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.ROCKS] = "[!1]//---//\u3053\u308C\u3092\u7372\u5F97\u307E\u305F\u306F\u5EC3\u68C4\u3057\u305F\u3068\u304D\u3001\u9280\u8CA8//1\u679A\u3092\u7372\u5F97\u3059\u308B\u3002\u3042\u306A\u305F\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA//\u4E2D\u306E\u5834\u5408\u3001\u305D\u308C\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u304D\u3001\u305D\u308C//\u4EE5\u5916\u306E\u5834\u5408\u3001\u624B\u672D\u306B\u52A0\u3048\u308B\u3002";
JapaneseCardTexts[CardNames.GLADIATOR] = "|\uFF0B[2]|////\u624B\u672D1\u679A\u3092\u516C\u958B\u3059\u308B\u3002\u5DE6\u96A3\u306E\u30D7\u30EC\u30A4//\u30E4\u30FC\u306F\u540C\u3058\u30AB\u30FC\u30C9\u3092\u516C\u958B\u3057\u3066\u3082\u3088//\u3044\u3002\u516C\u958B\u3057\u306A\u304B\u3063\u305F\u5834\u5408\u3001\u3042\u306A\u305F//\u306F|\uFF0B|[1]\u3001\u30B5\u30D7\u30E9\u30A4\u306E\u5263\u95D8\u58EB1\u679A\u3092//\u5EC3\u68C4\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.FORTUNE] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|////\u3053\u308C\u304C\u3053\u306E\u30BF\u30FC\u30F3\u306B\u521D\u3081\u3066\u4F7F\u7528\u3057\u305F//\u5927\u91D1\u306E\u5834\u5408\u3001\u73FE\u5728\u306E[]\u30922\u500D\u306B\u3059\u308B\u3002//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u5834\u306E\u5263\u95D8\u58EB//1\u679A\u306B\u3064\u304D\u91D1\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";

JapaneseCardTexts[CardNames.CASTLES] = "\u57CE\u30AB\u30FC\u30C9\u3092\u30B3\u30B9\u30C8\u306E\u5B89\u3044\u3082\u306E\u304C//\u4E0A\u306B\u306A\u308B\u3088\u3046\u91CD\u306D\u3066\u5C71\u3092\u4F5C\u308B\u3002//2\u4EBA\u6226\u3067\u306F\u54041\u679A\u306E\u307F\u7528\u3044\u308B\u3002//\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u306E\u307F\u8CFC\u5165\u30FB\u7372\u5F97//\u3067\u304D\u308B\u3002";
JapaneseCardTexts[CardNames.HUMBLE_CASTLE] = "[!1]//---//\u3042\u306A\u305F\u306E\u57CE\u30AB\u30FC\u30C91\u679A\u306B\u3064\u304D{1}\u3002";
JapaneseCardTexts[CardNames.CRUMBLING_CASTLE] = "{!1}//---//\u3053\u308C\u3092\u7372\u5F97\u307E\u305F\u306F\u5EC3\u68C4\u3057\u305F\u3068\u304D\u3001//|\uFF0B|{1}\u3001\u9280\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.SMALL_CASTLE] = "\u624B\u672D\u306E\u57CE\u30AB\u30FC\u30C91\u679A\u304B\u3001\u3053\u308C\u3092//\u5EC3\u68C4\u3059\u308B\u3002\u5EC3\u68C4\u3057\u305F\u5834\u5408\u3001\u57CE//\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//---//{!2}";
JapaneseCardTexts[CardNames.HAUNTED_CASTLE] = "{!2}//---//\u3053\u308C\u3092\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u306B\u7372\u5F97\u3057//\u305F\u3068\u304D\u3001\u91D1\u8CA81\u679A\u3092\u7372\u5F97\u3057\u3001\u624B\u672D//\u304C5\u679A\u4EE5\u4E0A\u306E\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168//\u54E1\u3001\u624B\u672D2\u679A\u3092\u5C71\u672D\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.OPULENT_CASTLE] = "{!3}//---//\u597D\u304D\u306A\u679A\u6570\u306E\u52DD\u5229\u70B9\u30AB\u30FC\u30C9\u3092//\u516C\u958B\u3057\u3066\u6368\u3066\u672D\u306B\u3057\u30011\u679A\u306B//\u3064\u304D|\uFF0B|[2]\u3002";
JapaneseCardTexts[CardNames.SPRAWLING_CASTLE] = "{!4}//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u516C\u9818//1\u679A\u304B\u5C4B\u65773\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.GRAND_CASTLE] = "{!5}//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u624B\u672D\u3092\u516C\u958B\u3059//\u308B\u3002\u624B\u672D\u3068\u3059\u3079\u3066\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306E\u5834//\u306E\u52DD\u5229\u70B9\u30AB\u30FC\u30C91\u679A\u306B\u3064\u304D|\uFF0B|{1}\u3002";
JapaneseCardTexts[CardNames.KINGS_CASTLE] = "\u3042\u306A\u305F\u306E\u57CE\u30AB\u30FC\u30C91\u679A\u306B\u3064\u304D{2}\u3002";

JapaneseCardTexts[CardNames.PIG] = "";

//Nocturne
JapaneseCardTexts[CardNames.MINUS_CARD] = "";
JapaneseCardTexts[CardNames.MINUS_COIN] = "";
JapaneseCardTexts[CardNames.STATE_LIMBO] = "";
JapaneseCardTexts[CardNames.DRUID_BOONS] = "";

JapaneseCardTexts[CardNames.BOON_DRAWPILE] = "";
JapaneseCardTexts[CardNames.BOON_DISCARDPILE] = "";

JapaneseCardTexts[CardNames.THE_EARTHS_GIFT] = "\u30B3\u30B9\u30C8[4]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u305F\u3081\u306B\u3001//\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A\u3092\u6368\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.THE_FIELDS_GIFT] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B[1]|//(\u3053\u308C\u3092\u30AF\u30EA\u30FC\u30F3\u30A2\u30C3\u30D7\u30D5\u30A7\u30A4\u30BA\u307E\u3067\u4FDD\u6301\u3059\u308B\u3002)";
JapaneseCardTexts[CardNames.THE_FLAMES_GIFT] = "\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.THE_FORESTS_GIFT] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|//|\uFF0B[1]|//(\u3053\u308C\u3092\u30AF\u30EA\u30FC\u30F3\u30A2\u30C3\u30D7\u30D5\u30A7\u30A4\u30BA\u307E\u3067\u4FDD\u6301\u3059\u308B\u3002)";
JapaneseCardTexts[CardNames.THE_MOONS_GIFT] = "\u6368\u3066\u672D\u7F6E\u304D\u5834\u3092\u898B\u308B\u3002\u305D\u306E\u4E2D\u306E1\u679A\u3092//\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u306B\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.THE_MOUNTAINS_GIFT] = "\u9280\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.THE_RIVERS_GIFT] = "\u3053\u306E\u30BF\u30FC\u30F3\u306E\u7D42\u4E86\u6642\u3001|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//(\u3053\u308C\u3092\u30AF\u30EA\u30FC\u30F3\u30A2\u30C3\u30D7\u30D5\u30A7\u30A4\u30BA\u307E\u3067\u4FDD\u6301\u3059\u308B\u3002)";
JapaneseCardTexts[CardNames.THE_SEAS_GIFT] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|";
JapaneseCardTexts[CardNames.THE_SKYS_GIFT] = "\u91D1\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u305F\u3081\u306B\u3001\u624B\u672D3\u679A\u3092//\u6368\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.THE_SUNS_GIFT] = "\u5C71\u672D\u306E\u4E0A\u304B\u30894\u679A\u3092\u898B\u308B\u3002\u305D\u306E\u4E2D\u306E\u597D\u304D\u306A\u679A\u6570\u3092//\u6368\u3066\u672D\u306B\u3057\u3001\u6B8B\u308A\u3092\u597D\u304D\u306A\u9806\u756A\u3067\u5C71\u672D\u306B\u623B\u3059\u3002";
JapaneseCardTexts[CardNames.THE_SWAMPS_GIFT] = "\u30A6\u30A3\u30EB\u30FB\u30AA\u30FB\u30A6\u30A3\u30B9\u30D71\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.THE_WINDS_GIFT] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//\u624B\u672D2\u679A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002";

JapaneseCardTexts[CardNames.HEX_DRAWPILE] = "";
JapaneseCardTexts[CardNames.HEX_DISCARDPILE] = "";

JapaneseCardTexts[CardNames.BAD_OMENS] = "\u5C71\u672D\u3092\u6368\u3066\u672D\u7F6E\u304D\u5834\u306B\u7F6E\u304F\u3002\u6368\u3066\u672D\u7F6E\u304D\u5834\u306E\u9285\u8CA8//2\u679A\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u304F(\u306A\u3044\u5834\u5408\u3001\u516C\u958B\u3059\u308B)\u3002";
JapaneseCardTexts[CardNames.DELUSION] = "\u932F\u4E71\u3082\u5AC9\u59AC\u3082\u6301\u3063\u3066\u3044\u306A\u3044\u5834\u5408\u3001\u932F\u4E71\u3092\u5F97\u308B\u3002";
JapaneseCardTexts[CardNames.ENVY] = "\u932F\u4E71\u3082\u5AC9\u59AC\u3082\u6301\u3063\u3066\u3044\u306A\u3044\u5834\u5408\u3001\u5AC9\u59AC\u3092\u5F97\u308B\u3002";
JapaneseCardTexts[CardNames.FAMINE] = "\u5C71\u672D\u306E\u4E0A\u304B\u30893\u679A\u3092\u516C\u958B\u3059\u308B\u3002\u305D\u306E\u4E2D\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u3092//\u3059\u3079\u3066\u6368\u3066\u672D\u306B\u3059\u308B\u3002\u6B8B\u308A\u3092\u5C71\u672D\u306B\u6DF7\u305C\u30B7\u30E3\u30C3\u30D5\u30EB\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.FEAR] = "\u624B\u672D\u304C5\u679A\u4EE5\u4E0A\u306E\u5834\u5408\u3001\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u304B\u8CA1\u5B9D//\u30AB\u30FC\u30C91\u679A\u3092\u6368\u3066\u672D\u306B\u3059\u308B(\u306A\u3044\u5834\u5408\u3001\u516C\u958B\u3059\u308B)\u3002";
JapaneseCardTexts[CardNames.GREED] = "\u9285\u8CA81\u679A\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.HAUNTING] = "\u624B\u672D\u304C4\u679A\u4EE5\u4E0A\u306E\u5834\u5408\u3001\u305D\u306E\u4E2D\u306E1\u679A\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.LOCUSTS] = "\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u5EC3\u68C4\u3059\u308B\u3002\u305D\u308C\u304C\u9285\u8CA8\u307E\u305F\u306F\u5C4B\u6577//\u306E\u5834\u5408\u3001\u546A\u30441\u679A\u3092\u7372\u5F97\u3059\u308B\u3002\u305D\u308C\u4EE5\u5916\u306E\u5834\u5408\u3001\u5EC3\u68C4\u3057\u305F//\u30AB\u30FC\u30C9\u3088\u308A\u5B89\u304F\u540C\u3058\u7A2E\u5225\u3092\u6301\u3064\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.MISERY] = "\u3053\u308C\u304C\u3042\u306A\u305F\u306B\u3068\u3063\u3066\u3053\u306E\u30B2\u30FC\u30E0\u3067\u521D\u3081\u3066\u306E//\u307F\u3058\u3081\u306A\u751F\u6D3B\u3067\u3042\u308B\u5834\u5408\u3001\u751F\u6D3B\u82E6\u3092\u5F97\u308B\u3002//\u305D\u308C\u4EE5\u5916\u306E\u5834\u5408\u3001\u751F\u6D3B\u82E6\u3092\u4E8C\u91CD\u82E6\u306B\u88CF\u8FD4\u3059\u3002";
JapaneseCardTexts[CardNames.PLAGUE] = "\u546A\u30441\u679A\u3092\u624B\u672D\u306B\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.POVERTY] = "\u624B\u672D\u304C3\u679A\u306B\u306A\u308B\u3088\u3046\u306B\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.WAR] = "\u30B3\u30B9\u30C8[3]\u307E\u305F\u306F[4]\u306E\u30AB\u30FC\u30C9\u304C\u516C\u958B\u3055\u308C\u308B\u307E\u3067\u3001\u5C71\u672D\u306E\u4E0A\u304B\u3089//\u30AB\u30FC\u30C9\u3092\u516C\u958B\u3059\u308B\u3002\u305D\u308C\u3092\u5EC3\u68C4\u3057\u3001\u6B8B\u308A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002";

JapaneseCardTexts[CardNames.MISERABLE] = "{-2}";
JapaneseCardTexts[CardNames.TWICE_MISERABLE] = "{-4}";
JapaneseCardTexts[CardNames.ENVIOUS] = "\u3042\u306A\u305F\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA\u306E\u958B\u59CB\u6642\u3001\u3053\u308C\u3092\u623B\u3059\u3002//\u3053\u306E\u30BF\u30FC\u30F3\u3001\u9280\u8CA8\u3068\u91D1\u8CA8\u306F[1]\u306E\u307F\u751F\u307F\u51FA\u3059\u3002";
JapaneseCardTexts[CardNames.DELUDED] = "\u3042\u306A\u305F\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA\u306E\u958B\u59CB\u6642\u3001\u3053\u308C\u3092\u623B\u3059\u3002//\u3053\u306E\u30BF\u30FC\u30F3\u3001\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u3092\u8CFC\u5165\u3067\u304D\u306A\u3044\u3002";
JapaneseCardTexts[CardNames.LOST_IN_THE_WOODS] = "\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u3001\u795D\u798F\u3092\u53D7\u3051\u308B//\u305F\u3081\u306B\u3001\u624B\u672D1\u679A\u3092\u6368\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002";

JapaneseCardTexts[CardNames.BARD] = "|\uFF0B[2]|//// \u795D\u798F\u30921\u3064\u53D7\u3051\u308B\u3002 ";
JapaneseCardTexts[CardNames.BLESSED_VILLAGE] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u795D\u798F\u30921\u3064\u5F97\u308B\u3002//\u305D\u306E\u795D\u798F\u3092\u76F4\u3061\u306B\u53D7\u3051\u308B\u304B\u3001\u3042\u306A\u305F\u306E//\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u53D7\u3051\u308B\u304B\u9078\u3076\u3002";
JapaneseCardTexts[CardNames.CHANGELING] = "\u3053\u308C\u3092\u5EC3\u68C4\u3059\u308B\u3002\u5834\u306E\u30AB\u30FC\u30C91\u679A//\u3068\u540C\u3058\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//---//\u53D6\u308A\u66FF\u3048\u5B50\u3092\u4F7F\u7528\u3059\u308B\u30B2\u30FC\u30E0\u3067\u3001\u30B3\u30B9\u30C8//[3]\u4EE5\u4E0A\u306E\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001//\u305D\u308C\u3092\u53D6\u308A\u66FF\u3048\u5B50\u3068\u4EA4\u63DB\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.CEMETERY] = "{!2}//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u624B\u672D//\u3092\u6700\u59274\u679A\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002////%%(\u5BB6\u5B9D: \u546A\u3044\u306E\u93E1)%%";
JapaneseCardTexts[CardNames.COBBLER] = "\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001//\u30B3\u30B9\u30C8[4]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C91\u679A\u3092\u624B\u672D//\u306B\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.CONCLAVE] = "|\uFF0B[2]|////\u5834\u306B\u306A\u3044\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092//\u624B\u672D\u304B\u3089\u4F7F\u7528\u3057\u3066\u3082\u3088\u3044\u3002\u4F7F\u7528//\u3059\u308B\u5834\u5408\u3001|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u3002";
JapaneseCardTexts[CardNames.CRYPT] = "\u5834\u306E\u6301\u7D9A\u3067\u306A\u3044\u8CA1\u5B9D\u30AB\u30FC\u30C9\u3092//\u597D\u304D\u306A\u679A\u6570(\u3053\u306E\u30AB\u30FC\u30C9\u306E)//\u8107\u306B\u4F0F\u305B\u3066\u7F6E\u304F\u3002\u6B8B\u308A\u304C\u3042\u308B//\u9650\u308A\u3001\u3042\u306A\u305F\u306E\u5404\u30BF\u30FC\u30F3\u306E\u958B//\u59CB\u6642\u306B\u3001\u305D\u306E\u4E2D\u306E1\u679A\u3092\u624B\u672D//\u306B\u52A0\u3048\u308B\u3002";
JapaneseCardTexts[CardNames.CURSED_VILLAGE] = "|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//// \u624B\u672D\u304C6\u679A\u306B\u306A\u308B\u3088\u3046\u306B //\u30AB\u30FC\u30C9\u3092\u5F15\u304F\u3002//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001//\u546A\u8A5B\u30921\u3064\u53D7\u3051\u308B\u3002";
JapaneseCardTexts[CardNames.DEN_OF_SIN] = "\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001// |\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F| //---//\u3053\u306E\u30AB\u30FC\u30C9\u306F\u3001\u6368\u3066\u672D\u7F6E\u304D\u5834\u3067\u306F//\u306A\u304F\u3001\u624B\u672D\u306B\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.DEVILS_WORKSHOP] = "\u3053\u306E\u30BF\u30FC\u30F3\u306B\u7372\u5F97\u3057\u305F\u30AB\u30FC\u30C9\u304C...//2\u679A\u4EE5\u4E0A\u306E\u5834\u5408: \u30A4\u30F3\u30D71\u679A\u3092\u7372\u5F97//\u3059\u308B\u3002//1\u679A\u306E\u5834\u5408: \u30B3\u30B9\u30C8[4]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C9//1\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//0\u679A\u306E\u5834\u5408: \u91D1\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.DRUID] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|////\u8107\u306B\u7F6E\u3044\u3066\u3042\u308B\u795D\u798F\u306E\u3046\u30611\u3064\u3092//(\u305D\u3053\u304B\u3089\u52D5\u304B\u3055\u305A)\u53D7\u3051\u308B\u3002//---//\u6E96\u5099:\u795D\u798F3\u679A\u3092\u8107\u306B\u8868\u5411\u304D\u3067\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.EXORCIST] = "\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002//\u5EC3\u68C4\u3057\u305F\u30AB\u30FC\u30C9\u3088\u308A\u3082//\u5B89\u3044\u7CBE\u970A\u30AB\u30FC\u30C91\u679A\u3092 //\u305D\u306E\u7CBE\u970A\u306E\u5C71\u304B\u3089\u7372\u5F97//\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.FAITHFUL_HOUND] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//---//\u3053\u306E\u30AB\u30FC\u30C9\u3092\u30AF\u30EA\u30FC\u30F3\u30A2\u30C3\u30D7\u30D5\u30A7\u30A4\u30BA//\u4EE5\u5916\u306E\u5834\u9762\u3067\u6368\u3066\u672D\u306B\u3057\u305F\u3068\u304D\u3001\u3053\u308C//\u3092\u8107\u306B\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002\u8107\u306B\u7F6E\u3044\u305F\u5834\u5408\u3001//\u3053\u306E\u30BF\u30FC\u30F3\u306E\u7D42\u4E86\u6642\u306B\u624B\u672D\u306B\u52A0\u3048\u308B\u3002";
JapaneseCardTexts[CardNames.FOOL] = " \u68EE\u306E\u8FF7\u5B50\u3067\u306A\u3044\u5834\u5408\u3001//\u68EE\u306E\u8FF7\u5B50\u30683\u3064\u306E\u795D\u798F//\u3092\u5F97\u3066\u3001\u597D\u304D\u306A\u9806\u756A\u3067//\u795D\u798F\u3092\u53D7\u3051\u308B\u3002////%%(\u5BB6\u5B9D: \u5E78\u904B\u306E\u30B3\u30A4\u30F3)%%";
JapaneseCardTexts[CardNames.GHOST_TOWN] = "\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001////|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3001//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3| //---//\u3053\u306E\u30AB\u30FC\u30C9\u306F\u3001\u6368\u3066\u672D\u7F6E\u304D\u5834\u3067\u306F//\u306A\u304F\u3001\u624B\u672D\u306B\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.GUARDIAN] = "\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E//\u958B\u59CB\u6642\u306B\u3001|\uFF0B|[1]\u3002////\u305D\u308C\u307E\u3067\u3001\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u304C//\u30A2\u30BF\u30C3\u30AF\u30AB\u30FC\u30C9\u3092\u4F7F\u7528\u3059\u308B//\u3068\u304D\u3001\u305D\u306E\u5F71\u97FF\u3092\u53D7\u3051\u306A\u3044\u3002//---//\u3053\u306E\u30AB\u30FC\u30C9\u306F\u3001\u6368\u3066\u672D\u7F6E\u304D\u5834//\u3067\u306F\u306A\u304F\u3001\u624B\u672D\u306B\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.IDOL] = "[!2]//\u5834\u306B\u5076\u50CF\u3092(\u3053\u308C\u3092\u542B\u3081\u3066)//\u5947\u6570\u679A\u51FA\u3057\u3066\u3044\u308B\u5834\u5408\u3001\u795D//\u798F\u30921\u3064\u53D7\u3051\u308B\u3002//\u5076\u6570\u679A\u306E\u5834\u5408\u3001\u4ED6\u306E\u30D7\u30EC\u30A4//\u30E4\u30FC\u306F\u5168\u54E1\u3001\u546A\u30441\u679A\u3092\u7372//\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.LEPRECHAUN] = "\u91D1\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002////\u5834\u306E\u30AB\u30FC\u30C9\u304C7\u679A\u306E\u5834\u5408\u3001//\u9858\u30441\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//\u305D\u308C\u4EE5\u5916\u306E\u5834\u5408\u3001\u546A\u8A5B\u3092//1\u3064\u53D7\u3051\u308B\u3002";
JapaneseCardTexts[CardNames.MONASTERY] = "\u3053\u306E\u30BF\u30FC\u30F3\u306B\u7372\u5F97\u3057\u305F//\u30AB\u30FC\u30C91\u679A\u306B\u3064\u304D\u3001\u624B\u672D//1\u679A\u304B\u3001\u5834\u306E\u9285\u8CA81\u679A\u3092//\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.NECROMANCER] = "\u5EC3\u68C4\u7F6E\u304D\u5834\u306E\u4E2D\u304B\u3089\u3001\u6301\u7D9A\u30AB\u30FC\u30C9//\u3067\u306A\u3044\u8868\u5411\u304D\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9//1\u679A\u3092\u9078\u3076\u3002\u305D\u308C\u3092\u3053\u306E\u30BF\u30FC\u30F3\u306E//\u9593\u3060\u3051\u88CF\u8FD4\u3057\u306B\u3057\u3001\u305D\u3053\u304B\u3089//\u52D5\u304B\u3055\u305A\u306B\u4F7F\u7528\u3059\u308B\u3002//---//\u6E96\u5099: \u5EC3\u68C4\u7F6E\u304D\u5834\u306B\u30BE\u30F3\u30D3//3\u7A2E\u985E\u3092\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.NIGHT_WATCHMAN] = "\u5C71\u672D\u306E\u4E0A\u304B\u30895\u679A\u3092\u898B\u308B\u3002\u305D\u306E\u4E2D\u306E//\u597D\u304D\u306A\u679A\u6570\u3092\u6368\u3066\u672D\u306B\u3057\u3001\u6B8B\u308A\u3092//\u597D\u304D\u306A\u9806\u756A\u3067\u5C71\u672D\u306B\u623B\u3059\u3002//---//\u3053\u306E\u30AB\u30FC\u30C9\u306F\u3001\u6368\u3066\u672D\u7F6E\u304D\u5834\u3067\u306F//\u306A\u304F\u3001\u624B\u672D\u306B\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.PIXIE] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u4E00\u756A\u4E0A\u306E\u795D\u798F\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002//\u3053\u308C\u3092\u5EC3\u68C4\u3057\u3066\u3001\u305D\u306E\u795D\u798F\u3092//2\u56DE\u53D7\u3051\u3066\u3082\u3088\u3044\u3002////%%(\u5BB6\u5B9D: \u30E4\u30AE)%%";
JapaneseCardTexts[CardNames.POOKA] = "\u546A\u308F\u308C\u305F\u91D1\u8CA8\u4EE5\u5916\u306E\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A//\u3092\u624B\u672D\u304B\u3089\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002\u305D\u3046//\u3057\u305F\u5834\u5408\u3001|\uFF0B\uFF14\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002////%%(\u5BB6\u5B9D: \u546A\u308F\u308C\u305F\u91D1\u8CA8)%%";
JapaneseCardTexts[CardNames.RAIDER] = "\u624B\u672D\u304C5\u679A\u4EE5\u4E0A\u306E\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC//\u306F\u5168\u54E1\u3001\u3042\u306A\u305F\u306E\u5834\u306B\u3042\u308B\u30AB\u30FC\u30C9//\u3068\u540C\u3058\u30AB\u30FC\u30C91\u679A\u3092\u6368\u3066\u672D\u306B\u3059\u308B//(\u306A\u3044\u5834\u5408\u3001\u624B\u672D\u3092\u516C\u958B\u3059\u308B)\u3002////\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001//|\uFF0B|[3]\u3002";
JapaneseCardTexts[CardNames.SACRED_GROVE] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|//|\uFF0B[3]|////\u795D\u798F\u30921\u3064\u53D7\u3051\u308B\u3002\u305D\u308C\u304C|\uFF0B|[1]\u3092//\u4E0E\u3048\u306A\u3044\u5834\u5408\u3001\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC//\u306F\u5168\u54E1\u3001\u305D\u306E\u795D\u798F\u3092\u53D7\u3051\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.SECRET_CAVE] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D3\u679A\u3092\u6368\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002//\u6368\u3066\u672D\u306B\u3057\u305F\u5834\u5408\u3001\u3042\u306A\u305F\u306E//\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001|\uFF0B|[3]\u3002////%%(\u5BB6\u5B9D: \u9B54\u6CD5\u306E\u30E9\u30F3\u30D7)%%";
JapaneseCardTexts[CardNames.SHEPHERD] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u597D\u304D\u306A\u679A\u6570\u306E\u52DD\u5229\u70B9\u30AB\u30FC\u30C9\u3092//\u516C\u958B\u3057\u3066\u6368\u3066\u672D\u306B\u3057\u30011\u679A\u306B//\u3064\u304D\u3001|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002////%%(\u5BB6\u5B9D: \u7267\u8349\u5730)%%";
JapaneseCardTexts[CardNames.SKULK] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|////\u4E00\u756A\u4E0A\u306E\u546A\u8A5B\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002//\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u305D\u306E//\u546A\u8A5B\u3092\u53D7\u3051\u308B\u3002//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001//\u91D1\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.TORMENTOR] = "|\uFF0B[2]|////\u5834\u306B\u4ED6\u306E\u30AB\u30FC\u30C9\u304C\u306A\u3044\u5834\u5408\u3001//\u30A4\u30F3\u30D71\u679A\u3092\u7372\u5F97\u3059\u308B\u3002////\u305D\u308C\u4EE5\u5916\u306E\u5834\u5408\u3001\u4E00\u756A\u4E0A\u306E\u546A\u8A5B//\u3092\u6368\u3066\u672D\u306B\u3057\u3001\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC//\u306F\u5168\u54E1\u3001\u305D\u306E\u546A\u8A5B\u3092\u53D7\u3051\u308B\u3002";
JapaneseCardTexts[CardNames.TRAGIC_HERO] = "|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u8CFC\u5165|////\u5F15\u304D\u7D42\u3048\u305F\u5F8C\u3001\u624B\u672D\u304C8\u679A\u4EE5\u4E0A\u306E//\u5834\u5408\u3001\u3053\u308C\u3092\u5EC3\u68C4\u3057\u3066\u8CA1\u5B9D\u30AB\u30FC\u30C9//1\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.TRACKER] = "|\uFF0B[1]|//\u3053\u306E\u30BF\u30FC\u30F3\u3001\u30AB\u30FC\u30C91\u679A\u3092//\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u305D\u308C\u3092\u5C71\u672D//\u306E\u4E0A\u306B\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002////\u795D\u798F\u30921\u3064\u53D7\u3051\u308B\u3002////%%(\u5BB6\u5B9D: \u9769\u888B)%%";
JapaneseCardTexts[CardNames.VAMPIRE] = "\u4E00\u756A\u4E0A\u306E\u546A\u8A5B\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002//\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u305D\u306E//\u546A\u8A5B\u3092\u53D7\u3051\u308B\u3002\u305D\u306E\u5F8C\u3001\u3042\u306A//\u305F\u306F\u5438\u8840\u9B3C\u4EE5\u5916\u306E\u30B3\u30B9\u30C8[5]//\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//\u3053\u308C\u3092\u30B3\u30A6\u30E2\u30EA\u3068\u4EA4\u63DB\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.WEREWOLF] = "\u3042\u306A\u305F\u306E\u591C\u30D5\u30A7\u30A4\u30BA\u4E2D\u306E//\u5834\u5408\u3001\u4E00\u756A\u4E0A\u306E\u546A\u8A5B\u3092\u6368\u3066//\u672D\u306B\u3057\u3001\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F//\u5168\u54E1\u3001\u305D\u306E\u546A\u8A5B\u3092\u53D7\u3051\u308B\u3002////\u305D\u308C\u4EE5\u5916\u306E\u5834\u5408\u3001\u3042\u306A\u305F\u306F//|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.CURSED_GOLD] = "[!3]////\u546A\u30441\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.GOAT] = "[!1]////\u624B\u672D1\u679A\u3092\u5EC3\u68C4//\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.HAUNTED_MIRROR] = "[!1]//---//\u3053\u308C\u3092\u5EC3\u68C4\u3057\u305F\u3068\u304D\u3001\u5E7D\u970A//1\u679A\u3092\u7372\u5F97\u3059\u308B\u305F\u3081\u306B\u3001\u30A2//\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u6368\u3066//\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.LUCKY_COIN] = "[!1]////\u9280\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.MAGIC_LAMP] = "[!1]////\u5834\u306B1\u679A\u3057\u304B\u306A\u3044\u30AB\u30FC\u30C9\u304C//6\u7A2E\u985E\u4EE5\u4E0A\u3042\u308B\u5834\u5408\u3001\u3053\u308C//\u3092\u5EC3\u68C4\u3059\u308B\u3002\u5EC3\u68C4\u3057\u305F\u5834//\u5408\u3001\u9858\u30443\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.PASTURE] = "[!1]//---//\u3042\u306A\u305F\u306E\u5C4B\u65771\u679A\u306B\u3064\u304D{1}\u3002";
JapaneseCardTexts[CardNames.POUCH] = "[!1]//|\uFF0B\uFF11\u3000\u8CFC\u5165|";
JapaneseCardTexts[CardNames.BAT] = "\u624B\u672D\u3092\u6700\u59272\u679A\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002//1\u679A\u4EE5\u4E0A\u5EC3\u68C4\u3057\u305F\u5834\u5408\u3001\u3053\u308C\u3092//\u5438\u8840\u9B3C\u3068\u4EA4\u63DB\u3059\u308B\u3002//////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";
JapaneseCardTexts[CardNames.GHOST] = "\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u304C\u516C\u958B\u3055\u308C\u308B\u307E\u3067\u3001//\u5C71\u672D\u306E\u4E0A\u304B\u3089\u30AB\u30FC\u30C9\u3092\u516C\u958B\u3059\u308B\u3002\u4ED6\u306E//\u30AB\u30FC\u30C9\u3092\u6368\u3066\u672D\u306B\u3057\u3001\u305D\u306E\u30A2\u30AF\u30B7\u30E7\u30F3//\u30AB\u30FC\u30C9\u3092\u8107\u306B\u7F6E\u304F\u3002\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3//\u306E\u958B\u59CB\u6642\u306B\u3001\u305D\u308C\u30922\u56DE\u4F7F\u7528\u3059\u308B\u3002//////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";
JapaneseCardTexts[CardNames.IMP] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////\u5834\u306B\u306A\u3044\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092//\u624B\u672D\u304B\u3089\u4F7F\u7528\u3057\u3066\u3082\u3088\u3044\u3002//////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";
JapaneseCardTexts[CardNames.WILL_O_WISP] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u516C\u958B\u3059\u308B\u3002//\u305D\u308C\u304C\u30B3\u30B9\u30C8[2]\u4EE5\u4E0B\u306E\u5834\u5408\u3001\u624B\u672D//\u306B\u52A0\u3048\u308B\u3002//////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";
JapaneseCardTexts[CardNames.WISH] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u3053\u308C\u3092\u9858\u3044\u306E\u5C71\u306B\u623B\u3059\u3002\u623B\u3057\u305F\u5834\u5408\u3001//\u30B3\u30B9\u30C8[6]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C91\u679A\u3092\u624B\u672D\u306B//\u7372\u5F97\u3059\u308B\u3002//////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";
JapaneseCardTexts[CardNames.ZOMBIE_APPRENTICE] = "\u624B\u672D\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9//1\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002//\u5EC3\u68C4\u3057\u305F\u5834\u5408\u3001////|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3001//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u3002";
JapaneseCardTexts[CardNames.ZOMBIE_MASON] = "\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u5EC3\u68C4//\u3059\u308B\u3002\u305D\u308C\u3088\u308A\u30B3\u30B9\u30C8\u304C\u6700\u5927//[1]\u9AD8\u3044\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u3066\u3082//\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.ZOMBIE_SPY] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u898B\u308B\u3002//\u305D\u308C\u3092\u6368\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002";

//Renaissance
JapaneseCardTexts[CardNames.ACTING_TROUPE] = "|\uFF0B\uFF14\u3000\u6751\u4EBA|////   \u3053\u308C\u3092\u5EC3\u68C4\u3059\u308B\u3002 ";
JapaneseCardTexts[CardNames.BORDER_GUARD] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u5C71\u672D\u306E\u4E0A\u304B\u30892\u679A\u3092\u516C\u958B\u3059\u308B\u3002\u7247\u65B9//\u3092\u624B\u672D\u306B\u52A0\u3048\u3001\u3082\u3046\u7247\u65B9\u3092\u6368\u3066\u672D\u306B//\u3059\u308B\u3002\u4E21\u65B9\u304C\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u306E\u5834//\u5408\u3001\u30E9\u30F3\u30BF\u30F3\u304B\u89D2\u7B1B\u3092\u5F97\u308B\u3002";
JapaneseCardTexts[CardNames.CARGO_SHIP] = "|\uFF0B[2]|////\u3053\u306E\u30BF\u30FC\u30F3\u3001\u3042\u306A\u305F\u304C\u7372\u5F97\u3059\u308B//\u30AB\u30FC\u30C9\u306E\u3046\u30611\u679A\u3092\u3001(\u3053\u306E\u30AB\u30FC\u30C9//\u306E)\u8107\u306B\u8868\u5411\u304D\u306B\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002//\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001//\u305D\u308C\u3092\u3042\u306A\u305F\u306E\u624B\u672D\u306B\u52A0\u3048\u308B\u3002";
JapaneseCardTexts[CardNames.DUCAT] = "|\uFF0B\uFF11\u3000\u8CA1\u6E90|//|\uFF0B\uFF11\u3000\u8CFC\u5165|//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u624B\u672D\u306E//\u9285\u8CA81\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.EXPERIMENT] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u3053\u308C\u3092\u5B9F\u9A13\u306E\u5C71\u306B\u623B\u3059\u3002//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u8FFD\u52A0\u3067//\u5B9F\u9A131\u679A\u3092\u7372\u5F97\u3059\u308B(\u8FFD\u52A0\u5206\u306F//\u305D\u308C\u4EE5\u4E0A\u7372\u5F97\u3055\u305B\u306A\u3044)\u3002";
JapaneseCardTexts[CardNames.FLAG_BEARER] = "|\uFF0B[2]|////---//\u3053\u308C\u3092\u7372\u5F97\u307E\u305F\u306F\u5EC3\u68C4//\u3057\u305F\u3068\u304D\u3001\u65D7\u3092\u5F97\u308B\u3002";
JapaneseCardTexts[CardNames.HIDEOUT] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002\u305D\u308C\u304C//\u52DD\u5229\u70B9\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001\u546A\u3044//1\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.INVENTOR] = "\u30B3\u30B9\u30C8[4]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C9//1\u679A\u3092\u7372\u5F97\u3059\u308B\u3002////\u3053\u306E\u30BF\u30FC\u30F3\u3001\u3059\u3079\u3066//\u306E\u30AB\u30FC\u30C9\u306E\u30B3\u30B9\u30C8\u306F//[1]\u4E0B\u304C\u308B\u3002";
JapaneseCardTexts[CardNames.IMPROVE] = "|\uFF0B[2]|//\u30AF\u30EA\u30FC\u30F3\u30A2\u30C3\u30D7\u30D5\u30A7\u30A4\u30BA\u306E\u958B//\u59CB\u6642\u306B\u3001\u3053\u306E\u30BF\u30FC\u30F3\u5834\u304B\u3089\u6368//\u3066\u672D\u306B\u3059\u308B\u4E88\u5B9A\u306E\u30A2\u30AF\u30B7\u30E7\u30F3//\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002//\u5EC3\u68C4\u3057\u305F\u5834\u5408\u3001\u305D\u308C\u3088\u308A\u30B3\u30B9//\u30C8\u304C[1]\u9AD8\u3044\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97//\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.LACKEYS] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001//|\uFF0B\uFF12\u3000\u6751\u4EBA|\u3002";
JapaneseCardTexts[CardNames.MOUNTAIN_VILLAGE] = "|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u6368\u3066\u672D\u7F6E\u304D\u5834\u304B\u3089\u30AB\u30FC\u30C9//1\u679A\u3092\u624B\u672D\u306B\u52A0\u3048\u308B\u3002//\u624B\u672D\u306B\u52A0\u3048\u3089\u308C\u306A\u3044\u5834\u5408\u3001//|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.OLD_WITCH] = "|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001//\u546A\u30441\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//\u305D\u306E\u5F8C\u3001\u624B\u672D\u306E\u546A\u3044//1\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.PATRON] = "|\uFF0B\uFF11\u3000\u6751\u4EBA|//|\uFF0B[2]|//---//\u30A2\u30AF\u30B7\u30E7\u30F3\u30D5\u30A7\u30A4\u30BA\u4E2D\u306B//\u3053\u308C\u3092\u516C\u958B\u3057\u305F\u3068\u304D\u3001//|\uFF0B\uFF11\u3000\u8CA1\u6E90|\u3002";
JapaneseCardTexts[CardNames.PRIEST] = "|\uFF0B[2]|////\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002//\u305D\u306E\u5F8C\u3001\u3053\u306E\u30BF\u30FC\u30F3\u3001//\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u305F //\u3068\u304D\u3001|\uFF0B|[2]\u3002";
JapaneseCardTexts[CardNames.RECRUITER] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//////\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002//\u305D\u306E\u30B3\u30B9\u30C8[1]\u306B\u3064\u304D//|\uFF0B\uFF11\u3000\u6751\u4EBA|\u3002";
JapaneseCardTexts[CardNames.RESEARCH] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002\u305D\u306E\u30B3\u30B9\u30C8//[1]\u306B\u3064\u304D1\u679A\u3001\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u306E//\u30AB\u30FC\u30C9\u3092(\u3053\u306E\u30AB\u30FC\u30C9\u306E)\u8107\u306B\u4F0F//\u305B\u3066\u7F6E\u304F\u3002\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3//\u306E\u958B\u59CB\u6642\u306B\u3001\u305D\u308C\u3089\u3092\u624B\u672D\u306B\u52A0//\u3048\u308B\u3002";
JapaneseCardTexts[CardNames.SCEPTER] = "\u6B21\u306E\u3046\u30611\u3064\u3092\u9078\u3076:////\u300C|\uFF0B|[2]\u300D;////\u300C\u3053\u306E\u30BF\u30FC\u30F3\u306B\u4F7F\u7528\u3057\u5834//\u306B\u51FA\u305F\u307E\u307E\u306E\u547D\u4EE4\u3067\u306A\u3044//\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092//\u518D\u4F7F\u7528\u3059\u308B\u300D";
JapaneseCardTexts[CardNames.SCHOLAR] = "\u624B\u672D\u3092\u3059\u3079\u3066\u6368\u3066\u672D\u306B\u3059\u308B\u3002////|\uFF0B\uFF17\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|";
JapaneseCardTexts[CardNames.SCULPTOR] = "\u30B3\u30B9\u30C8[4]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C9// 1\u679A\u3092\u624B\u672D\u306B\u7372\u5F97\u3059\u308B\u3002//\u305D\u308C\u304C\u8CA1\u5B9D\u30AB\u30FC\u30C9\u306E\u5834//\u5408\u3001|\uFF0B\uFF11\u3000\u6751\u4EBA|\u3002";
JapaneseCardTexts[CardNames.SEER] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u5C71\u672D\u306E\u4E0A\u304B\u30893\u679A\u3092\u516C\u958B\u3059\u308B\u3002\u305D\u306E//\u4E2D\u306E\u30B3\u30B9\u30C8\u304C[2]\u304B\u3089[4]\u306E\u30AB\u30FC\u30C9\u3092//\u624B\u672D\u306B\u52A0\u3048\u308B\u3002\u6B8B\u308A\u3092\u597D\u304D\u306A\u9806\u756A//\u3067\u5C71\u672D\u306B\u623B\u3059\u3002";
JapaneseCardTexts[CardNames.SILK_MERCHANT] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u8CFC\u5165|//---//\u3053\u308C\u3092\u7372\u5F97\u307E\u305F\u306F\u5EC3\u68C4\u3057\u305F\u3068\u304D\u3001//|\uFF0B\uFF11\u3000\u6751\u4EBA|\u3001|\uFF0B\uFF11\u3000\u8CA1\u6E90|\u3002";
JapaneseCardTexts[CardNames.SPICES] = "[!2]//|\uFF0B\uFF11\u3000\u8CFC\u5165|////---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001//|\uFF0B\uFF12\u3000\u8CA1\u6E90|\u3002";
JapaneseCardTexts[CardNames.SWASHBUCKLER] = "|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////\u6368\u3066\u672D\u7F6E\u304D\u5834\u306B\u30AB\u30FC\u30C9\u304C\u3042\u308B//\u5834\u5408|\uFF0B\uFF11\u3000\u8CA1\u6E90|\u3001\u305D\u306E\u3046\u3048\u3067//\u8CA1\u6E90\u30924\u679A\u4EE5\u4E0A\u6240\u6301\u3057\u3066\u3044\u308B//\u5834\u5408\u3001\u5B9D\u7BB1\u3092\u5F97\u308B\u3002";
JapaneseCardTexts[CardNames.TREASURER] = "|\uFF0B[3]|////\u6B21\u306E\u3046\u30611\u3064\u3092\u9078\u3076:\u300C\u624B\u672D\u306E//\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3059\u308B\u300D;//\u300C\u5EC3\u68C4\u7F6E\u304D\u5834\u306E\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A//\u3092\u624B\u672D\u306B\u7372\u5F97\u3059\u308B\u300D;\u300C\u9375\u3092//\u5F97\u308B\u300D";
JapaneseCardTexts[CardNames.VILLAIN] = "|\uFF0B\uFF12\u3000\u8CA1\u6E90|////\u624B\u672D\u304C5\u679A\u4EE5\u4E0A\u306E\u4ED6\u306E\u30D7\u30EC\u30A4//\u30E4\u30FC\u306F\u5168\u54E1\u3001\u30B3\u30B9\u30C8[2]\u4EE5\u4E0A//\u306E\u30AB\u30FC\u30C91\u679A\u3092\u6368\u3066\u672D\u306B\u3059\u308B//(\u306A\u3044\u5834\u5408\u3001\u624B\u672D\u3092\u516C\u958B\u3059\u308B)\u3002";

JapaneseCardTexts[CardNames.FLAG] = "\u30AF\u30EA\u30FC\u30F3\u30A2\u30C3\u30D7\u30D5\u30A7\u30A4\u30BA\u4E2D\u3001\u6B21\u306E\u30BF\u30FC\u30F3\u306E//\u624B\u672D\u3092\u5F15\u304F\u3068\u304D\u3001|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F\u3002|";
JapaneseCardTexts[CardNames.HORN] = "1\u30BF\u30FC\u30F3\u306B1\u56DE\u306E\u307F\u3001\u5834\u304B\u3089\u56FD\u5883\u8B66\u5099\u968A\u3092\u6368\u3066\u672D\u306B//\u3057\u305F\u3068\u304D\u3001\u305D\u308C\u3092\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u306B\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.KEY] = "\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001|\uFF0B|[1]\u3002";
JapaneseCardTexts[CardNames.LANTERN] = "\u3042\u306A\u305F\u306E\u4F7F\u7528\u3059\u308B\u56FD\u5883\u8B66\u5099\u968A\u306F3\u679A\u30AB\u30FC\u30C9\u3092\u516C\u958B\u3057\u30012\u679A//\u6368\u3066\u672D\u306B\u3059\u308B\u3088\u3046\u306B\u306A\u308B\u3002\u305D\u306E3\u679A\u304C\u3059\u3079\u3066\u30A2\u30AF\u30B7\u30E7\u30F3//\u30AB\u30FC\u30C9\u306E\u5834\u5408\u306E\u307F\u3001\u89D2\u7B1B\u3092\u5F97\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.TREASURE_CHEST] = "\u3042\u306A\u305F\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA\u306E\u958B\u59CB\u6642\u306B\u3001//\u91D1\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";

JapaneseCardTexts[CardNames.ACADEMY] = "\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001|\uFF0B\uFF11\u3000\u6751\u4EBA|\u3002";
JapaneseCardTexts[CardNames.BARRACKS] = "\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u3002";
JapaneseCardTexts[CardNames.CANAL] = "\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u4E2D\u3001\u30AB\u30FC\u30C9\u306E\u30B3\u30B9\u30C8\u306F[1]\u4E0B\u304C\u308B\u3002";
JapaneseCardTexts[CardNames.CAPITALISM] = "\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u4E2D\u3001\u6307\u793A\u6587\u306B\u300C|\uFF0B|[]\u300D\u3068\u66F8\u3044\u3066//\u3042\u308B\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u306F\u3001\u8CA1\u5B9D\u30AB\u30FC\u30C9\u3067\u3082\u3042\u308B\u3002";
JapaneseCardTexts[CardNames.CATHEDRAL] = "\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.CITADEL] = "\u3042\u306A\u305F\u306E\u5404\u30BF\u30FC\u30F3\u306B\u3042\u306A\u305F\u304C\u521D\u3081\u3066\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9//1\u679A\u3092\u4F7F\u7528\u3057\u305F\u5F8C\u3001\u305D\u308C\u3092\u518D\u4F7F\u7528\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.CITY_GATE] = "\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3001//\u624B\u672D1\u679A\u3092\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.CROP_ROTATION] = "\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001\u52DD\u5229\u70B9\u30AB\u30FC\u30C91\u679A\u3092\u6368\u3066\u672D\u306B//\u3057\u3066\u3082\u3088\u3044\u3002\u6368\u3066\u672D\u306B\u3057\u305F\u5834\u5408\u3001|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.EXPLORATION] = "\u3042\u306A\u305F\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA\u306E\u7D42\u4E86\u6642\u3001\u305D\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA\u3067\u4F55\u3082//\u30AB\u30FC\u30C9\u3092\u7372\u5F97\u3057\u3066\u3044\u306A\u3044\u5834\u5408\u3001|\uFF0B\uFF11\u3000\u8CA1\u6E90|\u3001|\uFF0B\uFF11\u3000\u6751\u4EBA|\u3002";
JapaneseCardTexts[CardNames.FAIR] = "\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001|\uFF0B\uFF11\u3000\u8CFC\u5165|\u3002";
JapaneseCardTexts[CardNames.FLEET] = "\u30B2\u30FC\u30E0\u7D42\u4E86\u6642\u3001\u3053\u308C\u3092\u6301\u3064\u30D7\u30EC\u30A4\u30E4\u30FC\u306E\u307F\u3001//\u8FFD\u52A0\u30671\u30BF\u30FC\u30F3\u3092\u5F97\u308B\u3002";
JapaneseCardTexts[CardNames.GUILDHALL] = "\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001|\uFF0B\uFF11\u3000\u8CA1\u6E90|\u3002";
JapaneseCardTexts[CardNames.INNOVATION] = "\u3042\u306A\u305F\u306E\u5404\u30BF\u30FC\u30F3\u4E2D\u306B1\u56DE\u306E\u307F\u3001\u30A2\u30AF\u30B7\u30E7\u30F3//\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u4F7F\u7528\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.PAGEANT] = "\u3042\u306A\u305F\u306E\u8CFC\u5165\u30D5\u30A7\u30A4\u30BA\u306E\u7D42\u4E86\u6642\u3001//|\uFF0B\uFF11\u3000\u8CA1\u6E90|\u306E\u305F\u3081\u306B[1]\u3092\u652F\u6255\u3063\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.PIAZZA] = "\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u3092\u516C\u958B//\u3059\u308B\u3002\u305D\u308C\u304C\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001\u4F7F\u7528\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.ROAD_NETWORK] = "\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u304C\u52DD\u5229\u70B9\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001//|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.SEWERS] = "\u3053\u306E\u52B9\u679C\u4EE5\u5916\u3067\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u305F\u3068\u304D\u3001//\u8FFD\u52A0\u3067\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.SILOS] = "\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001\u597D\u304D\u306A\u679A\u6570\u306E\u9285\u8CA8\u3092//\u516C\u958B\u3057\u3066\u6368\u3066\u672D\u306B\u3057\u3001\u540C\u3058\u679A\u6570\u306E\u30AB\u30FC\u30C9\u3092\u5F15\u304F\u3002";
JapaneseCardTexts[CardNames.SINISTER_PLOT] = "\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001\u3042\u306A\u305F\u306E\u30C8\u30FC\u30AF\u30F3\u3092//\u3053\u3053\u306B1\u679A\u52A0\u3048\u308B\u304B\u3001\u305D\u308C\u3089\u3059\u3079\u3066\u3092\u53D6\u308A\u9664\u304D\u3001//\u53D6\u308A\u9664\u3044\u305F1\u679A\u306B\u3064\u304D|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.STAR_CHART] = "\u30B7\u30E3\u30C3\u30D5\u30EB\u3059\u308B\u3068\u304D\u3001\u305D\u306E\u3046\u30611\u679A\u3092\u53D6\u308A\u51FA\u3057//\u30B7\u30E3\u30C3\u30D5\u30EB\u3057\u305F\u30AB\u30FC\u30C9\u306E\u4E00\u756A\u4E0A\u306B\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002";

//Promos
JapaneseCardTexts[CardNames.BLACK_MARKET_PILE] = "";
JapaneseCardTexts[CardNames.BLACK_MARKET] = "|\uFF0B[2]|////\u95C7\u5E02\u5834\u30C7\u30C3\u30AD\u306E\u4E0A\u304B\u30893\u679A\u3092\u516C\u958B\u3059\u308B\u3002\u624B//\u672D\u304B\u3089\u597D\u304D\u306A\u679A\u6570\u306E\u8CA1\u5B9D\u30AB\u30FC\u30C9\u3092\u4F7F\u7528\u3059//\u308B\u3002\u516C\u958B\u3057\u305F\u4E2D\u306E1\u679A\u3092\u8CFC\u5165\u3057\u3066\u3082\u3088\u3044\u3002//\u6B8B\u308A\u3092\u597D\u304D\u306A\u9806\u756A\u3067\u95C7\u5E02\u5834\u30C7\u30C3\u30AD\u306E\u4E0B\u306B//\u7F6E\u304F\u3002//---//\u6E96\u5099:\u3053\u306E\u30B2\u30FC\u30E0\u3067\u4F7F\u308F\u306A\u3044\u738B\u56FD\u30AB\u30FC\u30C9//\u54041\u679A\u3067\u95C7\u5E02\u5834\u30C7\u30C3\u30AD\u3092\u4F5C\u308B\u3002";
JapaneseCardTexts[CardNames.STASH] = "[!2]//\u3053\u308C\u3092\u542B\u3081\u3066\u5C71\u672D\u3092\u30B7\u30E3\u30C3\u30D5\u30EB\u3059\u308B//\u3068\u304D\u3001\u3053\u308C\u3092\u5C71\u672D\u306E\u597D\u304D\u306A\u5834\u6240\u306B\u52A0//\u3048\u3066\u3082\u3088\u3044 (\u5C71\u672D\u306E\u4E2D\u3092\u898B\u3066\u306F\u3044\u3051//\u306A\u3044)\u3002";
JapaneseCardTexts[CardNames.ENVOY] = "\u5C71\u672D\u306E\u4E0A\u304B\u30895\u679A\u3092\u516C\u958B\u3059\u308B\u3002\u5DE6//\u96A3\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u3001\u305D\u306E\u4E2D\u306E1\u679A//\u3092\u9078\u629E\u3059\u308B\u3002\u3042\u306A\u305F\u306F\u305D\u308C\u3092\u6368//\u3066\u672D\u306B\u3057\u3001\u6B8B\u308A\u3092\u624B\u672D\u306B\u52A0\u3048\u308B\u3002";
JapaneseCardTexts[CardNames.WALLED_VILLAGE] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//---//\u30AF\u30EA\u30FC\u30F3\u30A2\u30C3\u30D7\u30D5\u30A7\u30A4\u30BA\u306E\u958B\u59CB\u6642\u306B\u3001//\u5834\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u304C\u3053\u308C\u4EE5\u5916\u306B//1\u679A\u4EE5\u4E0B\u306E\u5834\u5408\u3001\u3053\u308C\u3092\u5C71\u672D\u306E\u4E0A\u306B//\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.GOVERNOR] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u6B21\u306E\u3046\u30611\u3064\u3092\u3042\u306A\u305F\u304C\u9078\u3073\u3001\u30D7\u30EC\u30A4\u30E4//\u30FC\u5168\u54E1\u304C\u5B9F\u884C\u3059\u308B(\u3042\u306A\u305F\u306F()\u5185\u306E\u52B9\u679C//\u3092\u5F97\u308B):\u300C1(3)\u679A\u30AB\u30FC\u30C9\u3092\u5F15\u304F\u300D;\u300C\u9280//\u8CA8(\u91D1\u8CA8)1\u679A\u3092\u7372\u5F97\u3059\u308B\u300D;\u300C\u624B\u672D1\u679A\u3092//\u5EC3\u68C4\u3057\u3066\u3082\u3088\u304F\u3001\u5EC3\u68C4\u3057\u305F\u5834\u5408\u3001\u305D\u308C//\u3088\u308A\u30B3\u30B9\u30C8\u304C[1]([2])\u9AD8\u3044\u30AB\u30FC\u30C91\u679A\u3092//\u7372\u5F97\u3059\u308B\u300D";
JapaneseCardTexts[CardNames.PRINCE] = "\u624B\u672D\u304B\u3089\u30B3\u30B9\u30C8[4]\u4EE5\u4E0B\u306E\u6301\u7D9A//\u3067\u3082\u547D\u4EE4\u3067\u3082\u306A\u3044\u30A2\u30AF\u30B7\u30E7\u30F3//\u30AB\u30FC\u30C91\u679A\u3092(\u3053\u306E\u30AB\u30FC\u30C9\u306E)//\u8107\u306B\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002\u3042\u306A\u305F\u306E//\u5404\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u3001\u305D\u308C\u3092\u52D5//\u304B\u3055\u305A\u306B\u4F7F\u7528\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.SUMMON] = "\u30B3\u30B9\u30C8[4]\u4EE5\u4E0B\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002\u305D\u308C\u3092\u8107//\u306B\u7F6E\u304F\u3002\u8107\u306B\u7F6E\u3044\u305F\u5834\u5408\u3001\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001//\u305D\u308C\u3092\u4F7F\u7528\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.SAUNA] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D\u306E\u30A2\u30F4\u30A1\u30F3\u30C81\u679A\u3092\u4F7F\u7528\u3057\u3066//\u3082\u3088\u3044\u3002////\u3053\u306E\u30BF\u30FC\u30F3\u3001\u9280\u8CA81\u679A\u3092\u4F7F\u7528\u3059\u308B//\u3068\u304D\u3001\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.AVANTO] = "|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////\u624B\u672D\u306E\u30B5\u30A6\u30CA1\u679A\u3092\u4F7F\u7528\u3057\u3066\u3082//\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.DISMANTLE] = "\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002//\u305D\u306E\u30B3\u30B9\u30C8\u304C[1]\u4EE5\u4E0A\u306E\u5834\u5408\u3001//\u305D\u308C\u3088\u308A\u5B89\u3044\u30AB\u30FC\u30C91\u679A\u3068//\u91D1\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.CAPTAIN] = "\u73FE\u5728\u3068\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642//\u306B\u3001\u30B5\u30D7\u30E9\u30A4\u306B\u3042\u308B\u3001\u6301\u7D9A\u30AB\u30FC\u30C9\u3067\u3082//\u547D\u4EE4\u30AB\u30FC\u30C9\u3067\u3082\u306A\u3044\u30B3\u30B9\u30C8[4]\u4EE5\u4E0B\u306E//\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u3001\u52D5\u304B\u3055\u305A\u306B//\u4F7F\u7528\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.CHURCH] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D\u3092\u6700\u59273\u679A\u8107\u306B\u4F0F\u305B\u3066\u7F6E//\u3044\u3066\u3082\u3088\u3044\u3002\u3042\u306A\u305F\u306E\u6B21\u306E//\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u305D\u308C\u3089\u3092//\u624B\u672D\u306B\u52A0\u3048\u3001\u305D\u306E\u5F8C\u3001\u624B\u672D//1\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.MARCHLAND] = "あなたの勝利点カード//3枚(端数切り捨て)に//つき、{1}。//---//これを獲得したとき、//|＋１　購入|、手札を//好きな枚数捨て札に//し、1枚につき|＋|[1]。";

//Menagerie
JapaneseCardTexts[CardNames.BLACK_CAT] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//\u3042\u306A\u305F\u306E\u30BF\u30FC\u30F3\u3067\u306F\u306A\u3044\u5834\u5408\u3001\u4ED6\u306E//\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u546A\u30441\u679A\u3092\u7372\u5F97//\u3059\u308B\u3002//---//\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u304C\u52DD\u5229\u70B9\u30AB\u30FC\u30C91\u679A\u3092//\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u624B\u672D\u304B\u3089\u3053\u308C\u3092\u4F7F\u7528//\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.SLEIGH] = "\u99AC2\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//---//\u3042\u306A\u305F\u304C\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F//\u3068\u304D\u3001\u305D\u308C\u3092\u624B\u672D\u306B\u52A0\u3048\u308B\u304B//\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u304F\u305F\u3081\u306B\u3001\u3053\u308C\u3092//\u6368\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.SUPPLIES] = "[!1]////\u99AC1\u679A\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.CAMEL_TRAIN] = "\u30B5\u30D7\u30E9\u30A4\u304B\u3089\u52DD\u5229\u70B9\u4EE5\u5916\u306E//\u30AB\u30FC\u30C91\u679A\u3092\u8FFD\u653E\u3059\u308B\u3002//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u30B5\u30D7\u30E9\u30A4//\u306E\u91D1\u8CA81\u679A\u3092\u8FFD\u653E\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.GOATHERD] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002////\u53F3\u96A3\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306E\u6700\u5F8C\u306E//\u30BF\u30FC\u30F3\u306B\u305D\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u304C//\u5EC3\u68C4\u3057\u305F\u30AB\u30FC\u30C91\u679A\u306B\u3064\u304D//|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.SCRAP] = "\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002////\u305D\u306E\u30B3\u30B9\u30C8[1]\u306B\u3064\u304D1\u3064\u3001\u305D\u308C\u305E\u308C//\u7570\u306A\u308B\u3082\u306E\u3092\u6B21\u306E\u4E2D\u304B\u3089\u9078\u3076:////\u300C|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u300D;\u300C|\uFF0B\uFF11\u3000\u30A2\u30AF|//|\u30B7\u30E7\u30F3|\u300D;\u300C|\uFF0B\uFF11\u3000\u8CFC\u5165|\u300D;\u300C|\uFF0B|[1]\u300D;//\u300C\u9280\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u300D;\u300C\u99AC1\u679A\u3092\u7372\u5F97//\u3059\u308B\u300D";
JapaneseCardTexts[CardNames.SHEEPDOG] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|////---//\u3042\u306A\u305F\u304C\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F//\u3068\u304D\u3001\u624B\u672D\u304B\u3089\u3053\u308C\u3092\u4F7F\u7528\u3057\u3066//\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.SNOWY_VILLAGE] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF14\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B\uFF11\u3000\u8CFC\u5165|////\u3053\u306E\u30BF\u30FC\u30F3\u3001\u4ECA\u5F8C\u5F97\u308B//|\uFF0B\u30A2\u30AF\u30B7\u30E7\u30F3|\u3092\u3059\u3079\u3066//\u7121\u8996\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.STOCKPILE] = "[!3]//|\uFF0B\uFF11\u3000\u8CFC\u5165|////\u3053\u308C\u3092\u8FFD\u653E\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.BOUNTY_HUNTER] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D1\u679A\u3092\u8FFD\u653E\u3059\u308B\u3002////\u305D\u308C\u3068\u540C\u3058\u30AB\u30FC\u30C9\u3092\u8FFD\u653E\u3057\u3066//\u3044\u306A\u304B\u3063\u305F\u5834\u5408\u3001|\uFF0B|[3]\u3002";
JapaneseCardTexts[CardNames.CARDINAL] = "|\uFF0B[2]|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u5C71\u672D\u306E//\u4E0A\u304B\u30892\u679A\u3092\u516C\u958B\u3057\u3001\u305D\u306E\u4E2D\u306E//\u30B3\u30B9\u30C8\u304C[3]\u304B\u3089[6]\u306E\u30AB\u30FC\u30C91\u679A//\u3092\u8FFD\u653E\u3057\u3001\u6B8B\u308A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.CAVALRY] = "\u99AC2\u679A\u3092\u7372\u5F97\u3059\u308B\u3002////---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092|//|\u5F15\u304F|\u3001|\uFF0B\uFF11\u3000\u8CFC\u5165|\u3001\u3042\u306A\u305F\u306E\u8CFC\u5165\u30D5\u30A7//\u30A4\u30BA\u4E2D\u306E\u5834\u5408\u3001\u30A2\u30AF\u30B7\u30E7\u30F3\u30D5\u30A7\u30A4\u30BA\u306B//\u623B\u308B\u3002";
JapaneseCardTexts[CardNames.GROOM] = "\u30B3\u30B9\u30C8[4]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002////\u7372\u5F97\u3057\u305F\u30AB\u30FC\u30C9\u304C...//\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001\u99AC1\u679A\u3092\u7372\u5F97//\u3059\u308B\u3002//\u8CA1\u5B9D\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001\u9280\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//\u52DD\u5229\u70B9\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092|//|\u5F15\u304F|\u3001|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u3002";
JapaneseCardTexts[CardNames.HOSTELRY] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//---//\u3053\u308C\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u597D\u304D\u306A\u679A\u6570//\u306E\u8CA1\u5B9D\u30AB\u30FC\u30C9\u3092\u516C\u958B\u3057\u3066\u6368\u3066\u672D\u306B//\u3057\u3066\u3082\u3088\u3044\u3002\u305D\u3046\u3057\u305F\u5834\u5408\u3001\u540C\u3058//\u679A\u6570\u306E\u99AC\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.VILLAGE_GREEN] = "\u73FE\u5728\u307E\u305F\u306F\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E//\u958B\u59CB\u6642\u306B\u3001|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3001//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u3002//---//\u3053\u306E\u30AB\u30FC\u30C9\u3092\u30AF\u30EA\u30FC\u30F3\u30A2\u30C3\u30D7//\u30D5\u30A7\u30A4\u30BA\u4EE5\u5916\u306E\u5834\u9762\u3067\u6368\u3066\u672D//\u306B\u3057\u305F\u3068\u304D\u306B\u3001\u5834\u306B\u51FA\u3057\u3066\u4F7F//\u7528\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.BARGE] = "\u73FE\u5728\u307E\u305F\u306F\u3042\u306A\u305F\u306E\u6B21\u306E//\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001////|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u8CFC\u5165|";
JapaneseCardTexts[CardNames.COVEN] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B[2]|////\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u30B5\u30D7\u30E9\u30A4\u306E//\u546A\u30441\u679A\u3092\u8FFD\u653E\u3059\u308B\u3002\u8FFD\u653E\u3067\u304D\u306A\u304B\u3063//\u305F\u5834\u5408\u3001\u305D\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u8FFD\u653E\u3057\u3066//\u3044\u308B\u546A\u3044\u3092\u3059\u3079\u3066\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.DISPLACE] = "\u624B\u672D1\u679A\u3092\u8FFD\u653E\u3059\u308B\u3002//\u305D\u308C\u3088\u308A\u30B3\u30B9\u30C8\u304C\u6700\u5927[2]\u9AD8\u3044//\u7570\u306A\u308B\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.FALCONER] = "\u3053\u308C\u3088\u308A\u5B89\u3044\u30AB\u30FC\u30C91\u679A\u3092\u624B\u672D\u306B//\u7372\u5F97\u3059\u308B\u3002//---//\u8AB0\u304B\u304C\u7A2E\u5225(\u30A2\u30AF\u30B7\u30E7\u30F3\u3001\u30A2\u30BF\u30C3\u30AF\u306A\u3069)\u3092//2\u3064\u4EE5\u4E0A\u6301\u3064\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001//\u624B\u672D\u304B\u3089\u3053\u308C\u3092\u4F7F\u7528\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.FISHERMAN] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B[1]|//---//\u30BF\u30FC\u30F3\u4E2D\u3001\u30BF\u30FC\u30F3\u30D7\u30EC\u30A4\u30E4\u30FC\u306E\u6368\u3066//\u672D\u7F6E\u304D\u5834\u304C\u7A7A\u306E\u5834\u5408\u3001\u3053\u306E\u30AB\u30FC\u30C9//\u306E\u30B3\u30B9\u30C8\u306F[3]\u4E0B\u304C\u308B\u3002";
JapaneseCardTexts[CardNames.GATEKEEPER] = "\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001//|\uFF0B|[3]\u3002\u305D\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u307E\u3067\u3001//\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u8FFD\u653E\u3057\u3066\u3044\u306A\u3044//\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u304B\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A//\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001\u305D\u308C\u3092\u8FFD\u653E\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.HUNTING_LODGE] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u624B\u672D\u3092\u3059\u3079\u3066\u6368\u3066\u672D\u306B\u3057//  \u3066\u3082\u3088\u3044\u3002\u305D\u3046\u3057\u305F\u5834\u5408\u3001//|\uFF0B\uFF15\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.KILN] = "|\uFF0B[2]|////\u3053\u306E\u30BF\u30FC\u30F3\u3001\u6B21\u306B\u30AB\u30FC\u30C9\u3092\u4F7F\u7528\u3059//\u308B\u3068\u304D\u3001\u305D\u306E\u89E3\u6C7A\u524D\u306B\u3001\u305D\u308C\u3068\u540C//\u3058\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.LIVERY] = "|\uFF0B[3]|////\u3053\u306E\u30BF\u30FC\u30F3\u3001\u30B3\u30B9\u30C8[4]\u4EE5\u4E0A\u306E//\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001//\u99AC1\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.MASTERMIND] = "\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E//\u958B\u59CB\u6642\u306B\u3001\u624B\u672D\u306E\u30A2\u30AF//\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u30923\u56DE//\u4F7F\u7528\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.PADDOCK] = "|\uFF0B[2]|////\u99AC2\u679A\u3092\u7372\u5F97\u3059\u308B\u3002////\u30B5\u30D7\u30E9\u30A4\u306E\u7A7A\u306E\u5C711\u3064\u306B\u3064//\u304D\u3001|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|\u3002";
JapaneseCardTexts[CardNames.SANCTUARY] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B\uFF11\u3000\u8CFC\u5165|////\u624B\u672D1\u679A\u3092\u8FFD\u653E\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.DESTRIER] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//---//\u30BF\u30FC\u30F3\u4E2D\u3001\u3053\u306E\u30AB\u30FC\u30C9\u306E\u30B3\u30B9\u30C8\u306F//\u305D\u306E\u30BF\u30FC\u30F3\u306B\u30BF\u30FC\u30F3\u30D7\u30EC\u30A4\u30E4\u30FC\u304C//\u7372\u5F97\u3057\u305F\u30AB\u30FC\u30C91\u679A\u306B\u3064\u304D[1]\u4E0B\u304C\u308B\u3002";
JapaneseCardTexts[CardNames.WAYFARER] = "|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//\u9280\u8CA81\u679A\u3092\u7372\u5F97\u3057\u3066\u3082\u3088\u3044\u3002//---//\u3053\u306E\u30AB\u30FC\u30C9\u306E\u30B3\u30B9\u30C8\u306F\u3001\u3053\u306E\u30BF\u30FC\u30F3//\u8AB0\u304B\u304C\u30AB\u30FC\u30C9\u3092\u7372\u5F97\u3057\u3066\u3044\u305F\u5834\u5408\u3001//\u305D\u306E\u3046\u3061\u6700\u5F8C\u306B\u7372\u5F97\u3055\u308C\u305F\u4ED6\u306E\u30AB\u30FC//\u30C9\u3068\u540C\u3058\u306B\u306A\u308B\u3002";
JapaneseCardTexts[CardNames.ANIMAL_FAIR] = "|\uFF0B[4]|////\u30B5\u30D7\u30E9\u30A4\u306E\u7A7A\u306E\u5C711\u3064\u306B\u3064\u304D\u3001//|\uFF0B\uFF11\u3000\u8CFC\u5165|\u3002//---//\u3053\u306E\u30AB\u30FC\u30C9\u306E\u30B3\u30B9\u30C8\u3092\u652F\u6255\u3046//\u4EE3\u308F\u308A\u306B\u3001\u624B\u672D\u306E\u30A2\u30AF\u30B7\u30E7\u30F3//\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002";

JapaneseCardTexts[CardNames.HORSE] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|////\u3053\u308C\u3092\u99AC\u306E\u5C71\u306B\u623B\u3059\u3002////////%(\u3053\u306E\u30AB\u30FC\u30C9\u306F\u30B5\u30D7\u30E9\u30A4\u306B\u7F6E\u304B\u306A\u3044\u3002)%";

JapaneseCardTexts[CardNames.WAY_OF_THE_BUTTERFLY] = "\u3053\u308C\u3092\u3053\u306E\u30AB\u30FC\u30C9\u306E\u5C71\u306B\u623B\u3057\u3066\u3082\u3088\u3044\u3002\u305D\u3046\u3057\u305F\u5834\u5408\u3001//\u305D\u308C\u3088\u308A\u30B3\u30B9\u30C8\u304C[1]\u9AD8\u3044\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.WAY_OF_THE_CAMEL] = "\u30B5\u30D7\u30E9\u30A4\u306E\u91D1\u8CA81\u679A\u3092\u8FFD\u653E\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.WAY_OF_THE_CHAMELEON] = "\u3053\u306E\u30AB\u30FC\u30C9\u306E\u6307\u793A\u306B\u5F93\u3046\u304C\u3001\u305D\u306E\u52B9\u679C\u3067\u3053\u306E\u30BF\u30FC\u30F3\u3042\u306A\u305F\u304C//\u5F97\u3088\u3046\u3068\u3059\u308B|\uFF0B\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3068|\uFF0B|[]\u306F\u3001\u4EE3\u308F\u308A\u306B\u305D\u308C\u305E\u308C//|\uFF0B|[]\u3068|\uFF0B\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u306B\u306A\u308B\u3002";
JapaneseCardTexts[CardNames.WAY_OF_THE_FROG] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//\u3053\u306E\u30BF\u30FC\u30F3\u3001\u3053\u308C\u3092\u5834\u304B\u3089\u6368\u3066\u672D\u306B\u3057\u305F\u3068\u304D\u3001//\u3053\u308C\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.WAY_OF_THE_GOAT] = "\u624B\u672D1\u679A\u3092\u5EC3\u68C4\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.WAY_OF_THE_HORSE] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//\u3053\u308C\u3092\u3053\u306E\u30AB\u30FC\u30C9\u306E\u5C71\u306B\u623B\u3059\u3002";
JapaneseCardTexts[CardNames.WAY_OF_THE_MOLE] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//\u624B\u672D\u3092\u3059\u3079\u3066\u6368\u3066\u672D\u306B\u3059\u308B\u3002|\uFF0B\uFF13\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.WAY_OF_THE_MONKEY] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|//|\uFF0B[1]|";
JapaneseCardTexts[CardNames.WAY_OF_THE_MOUSE] = "\u8107\u306B\u6E96\u5099\u3057\u305F\u30AB\u30FC\u30C9\u3092\u52D5\u304B\u3055\u305A\u306B\u4F7F\u7528\u3059\u308B\u3002//---//\u6E96\u5099:\u3053\u306E\u30B2\u30FC\u30E0\u3067\u4F7F\u308F\u306A\u3044\u3001\u30B3\u30B9\u30C8[2]\u307E\u305F\u306F[3]\u306E//\u30A2\u30AF\u30B7\u30E7\u30F3\u306E\u738B\u56FD\u30AB\u30FC\u30C91\u679A\u3092\u8107\u306B\u7F6E\u304F\u3002";
JapaneseCardTexts[CardNames.WAY_OF_THE_MULE] = "|\uFF0B\uFF11\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|//|\uFF0B[1]|";
JapaneseCardTexts[CardNames.WAY_OF_THE_OTTER] = "|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|";
JapaneseCardTexts[CardNames.WAY_OF_THE_OWL] = "\u624B\u672D\u304C6\u679A\u306B\u306A\u308B\u3088\u3046\u306B\u30AB\u30FC\u30C9\u3092\u5F15\u304F\u3002";
JapaneseCardTexts[CardNames.WAY_OF_THE_OX] = "|\uFF0B\uFF12\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|";
JapaneseCardTexts[CardNames.WAY_OF_THE_PIG] = "|\uFF0B\uFF11\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|//|\uFF0B\u3000\u30A2\u30AF\u30B7\u30E7\u30F3|";
JapaneseCardTexts[CardNames.WAY_OF_THE_RAT] = "\u3053\u308C\u3068\u540C\u3058\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u305F\u3081\u306B\u3001//\u8CA1\u5B9D\u30AB\u30FC\u30C91\u679A\u3092\u6368\u3066\u672D\u306B\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.WAY_OF_THE_SEAL] = "|\uFF0B[1]|//\u3053\u306E\u30BF\u30FC\u30F3\u3001\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3057\u305F\u3068\u304D\u3001//\u305D\u308C\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.WAY_OF_THE_SHEEP] = "|\uFF0B[2]|";
JapaneseCardTexts[CardNames.WAY_OF_THE_SQUIRREL] = "\u3053\u306E\u30BF\u30FC\u30F3\u306E\u7D42\u4E86\u6642\u3001|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.WAY_OF_THE_TURTLE] = "\u3053\u308C\u3092\u8107\u306B\u7F6E\u304F\u3002\u8107\u306B\u7F6E\u3044\u305F\u5834\u5408\u3001\u3042\u306A\u305F\u306E//\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001\u305D\u308C\u3092\u4F7F\u7528\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.WAY_OF_THE_WORM] = "\u30B5\u30D7\u30E9\u30A4\u306E\u5C4B\u65771\u679A\u3092\u8FFD\u653E\u3059\u308B\u3002";

JapaneseCardTexts[CardNames.DELAY] = "\u624B\u672D\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u8107\u306B\u7F6E\u3044\u3066\u3082\u3088\u3044\u3002//\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001\u305D\u308C\u3092\u4F7F\u7528\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.DESPERATION] = "1\u30BF\u30FC\u30F3\u306B1\u5EA6\u306E\u307F:\u546A\u30441\u679A\u3092\u7372\u5F97\u3057\u3066\u3082\u3088\u3044\u3002//\u305D\u3046\u3057\u305F\u5834\u5408\u3001|\uFF0B\uFF11\u3000\u8CFC\u5165|\u3001|\uFF0B|[2]\u3002";
JapaneseCardTexts[CardNames.GAMBLE] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|\u3002\u5C71\u672D\u306E\u4E00\u756A\u4E0A\u306E\u30AB\u30FC\u30C9\u3092\u516C\u958B\u3059\u308B\u3002\u305D\u308C\u304C//\u8CA1\u5B9D\u30AB\u30FC\u30C9\u304B\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u306E\u5834\u5408\u3001\u4F7F\u7528\u3057\u3066\u3082\u3088\u3044\u3002//\u4F7F\u7528\u3067\u304D\u306A\u3044\u30FB\u3057\u306A\u3044\u5834\u5408\u3001\u305D\u308C\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.PURSUE] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|\u3002\u30AB\u30FC\u30C91\u679A\u3092\u6307\u5B9A\u3059\u308B\u3002\u5C71\u672D\u306E\u4E0A\u304B\u30894\u679A\u3092//\u516C\u958B\u3059\u308B\u3002\u305D\u306E\u4E2D\u306E\u6307\u5B9A\u3057\u305F\u30AB\u30FC\u30C9\u3092\u5C71\u672D\u306E\u4E0A\u306B\u623B\u3057\u3001//\u6B8B\u308A\u3092\u6368\u3066\u672D\u306B\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.RIDE] = "\u99AC1\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.TOIL] = "|\uFF0B\uFF11\u3000\u8CFC\u5165|//\u624B\u672D\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u4F7F\u7528\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.ENHANCE] = "\u624B\u672D\u304B\u3089\u52DD\u5229\u70B9\u4EE5\u5916\u306E\u30AB\u30FC\u30C91\u679A\u3092\u5EC3\u68C4\u3057\u3066\u3082\u3088\u3044\u3002\u305D\u3046//\u3057\u305F\u5834\u5408\u3001\u305D\u308C\u3088\u308A\u30B3\u30B9\u30C8\u304C\u6700\u5927[2]\u9AD8\u3044\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97//\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.MARCH] = "\u6368\u3066\u672D\u7F6E\u304D\u5834\u306E\u30AB\u30FC\u30C9\u3092\u3059\u3079\u3066\u898B\u308B\u3002\u305D\u306E\u4E2D\u306E//\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u4F7F\u7528\u3057\u3066\u3082\u3088\u3044\u3002";
JapaneseCardTexts[CardNames.TRANSPORT] = "\u6B21\u306E\u3046\u30611\u3064\u3092\u9078\u3076:\u300C\u30B5\u30D7\u30E9\u30A4\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092//\u8FFD\u653E\u3059\u308B\u300D;\u300C\u3042\u306A\u305F\u304C\u8FFD\u653E\u3057\u3066\u3044\u308B\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A//\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7F6E\u304F\u300D";
JapaneseCardTexts[CardNames.BANISH] = "\u624B\u672D\u306E\u30AB\u30FC\u30C91\u7A2E\u985E\u3092\u597D\u304D\u306A\u679A\u6570\u8FFD\u653E\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.BARGAIN] = "\u30B3\u30B9\u30C8[5]\u4EE5\u4E0B\u306E\u52DD\u5229\u70B9\u4EE5\u5916\u306E\u30AB\u30FC\u30C91\u679A\u3092\u7372\u5F97\u3059\u308B\u3002//\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u306F\u5168\u54E1\u3001\u99AC1\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.INVEST] = "\u30B5\u30D7\u30E9\u30A4\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C91\u679A\u3092\u8FFD\u653E\u3059\u308B\u3002\u305D\u308C\u304C\u8FFD\u653E//\u3055\u308C\u3066\u3044\u308B\u9593\u3001\u4ED6\u306E\u30D7\u30EC\u30A4\u30E4\u30FC\u304C\u305D\u308C\u3068\u540C\u3058\u30AB\u30FC\u30C91\u679A\u3092//\u7372\u5F97\u307E\u305F\u306F\u6295\u8CC7\u3067\u8FFD\u653E\u3057\u305F\u3068\u304D\u3001|\uFF0B\uFF12\u3000\u30AB\u30FC\u30C9\u3092\u5F15\u304F|\u3002";
JapaneseCardTexts[CardNames.SEIZE_THE_DAY] = "\u30B2\u30FC\u30E0\u4E2D\u306B1\u5EA6\u306E\u307F:\u3053\u306E\u30BF\u30FC\u30F3\u306E\u5F8C\u306B\u8FFD\u52A0\u306E1\u30BF\u30FC\u30F3\u3092\u5F97\u308B\u3002";
JapaneseCardTexts[CardNames.COMMERCE] = "\u3053\u306E\u30BF\u30FC\u30F3\u3042\u306A\u305F\u304C\u7372\u5F97\u3057\u305F\u7570\u306A\u308B\u30AB\u30FC\u30C9//1\u7A2E\u985E\u306B\u3064\u304D\u91D1\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.DEMAND] = "\u99AC1\u679A\u3068\u30B3\u30B9\u30C8[4]\u4EE5\u4E0B\u306E\u30AB\u30FC\u30C91\u679A\u3092\u5C71\u672D\u306E\u4E0A\u306B\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.STAMPEDE] = "\u5834\u306B\u3042\u308B\u30AB\u30FC\u30C9\u304C5\u679A\u4EE5\u4E0B\u306E\u5834\u5408\u3001\u99AC5\u679A\u3092\u5C71\u672D\u306E//\u4E0A\u306B\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.REAP] = "\u91D1\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002\u305D\u308C\u3092\u8107\u306B\u7F6E\u304F\u3002\u8107\u306B\u7F6E\u3044\u305F\u5834\u5408\u3001//\u3042\u306A\u305F\u306E\u6B21\u306E\u30BF\u30FC\u30F3\u306E\u958B\u59CB\u6642\u306B\u3001\u305D\u308C\u3092\u4F7F\u7528\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.ENCLAVE] = "\u91D1\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002\u30B5\u30D7\u30E9\u30A4\u306E\u516C\u98181\u679A\u3092\u8FFD\u653E\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.ALLIANCE] = "\u5C5E\u5DDE1\u679A\u3001\u516C\u98181\u679A\u3001\u5C4B\u65771\u679A\u3001\u91D1\u8CA81\u679A\u3001\u9280\u8CA81\u679A\u3001//\u9285\u8CA81\u679A\u3092\u7372\u5F97\u3059\u308B\u3002";
JapaneseCardTexts[CardNames.POPULATE] = "\u30B5\u30D7\u30E9\u30A4\u306B\u3042\u308B\u3059\u3079\u3066\u306E\u30A2\u30AF\u30B7\u30E7\u30F3\u30AB\u30FC\u30C9\u306E\u5C71\u304B\u3089//1\u679A\u305A\u3064\u7372\u5F97\u3059\u308B\u3002";

JapaneseCardTexts[CardNames.CARD_OF_THE_BUTTERFLY] = "";
JapaneseCardTexts[CardNames.CARD_OF_THE_CAMEL] = "";
JapaneseCardTexts[CardNames.CARD_OF_THE_CHAMELEON] = "";
JapaneseCardTexts[CardNames.CARD_OF_THE_FROG] = "";
JapaneseCardTexts[CardNames.CARD_OF_THE_GOAT] = "";
JapaneseCardTexts[CardNames.CARD_OF_THE_HORSE] = "";
JapaneseCardTexts[CardNames.CARD_OF_THE_MOLE] = "";
JapaneseCardTexts[CardNames.CARD_OF_THE_MONKEY] = "";
JapaneseCardTexts[CardNames.CARD_OF_THE_MOUSE] = "";
JapaneseCardTexts[CardNames.CARD_OF_THE_MULE] = "";
JapaneseCardTexts[CardNames.CARD_OF_THE_OTTER] = "";
JapaneseCardTexts[CardNames.CARD_OF_THE_OWL] = "";
JapaneseCardTexts[CardNames.CARD_OF_THE_OX] = "";
JapaneseCardTexts[CardNames.CARD_OF_THE_PIG] = "";
JapaneseCardTexts[CardNames.CARD_OF_THE_RAT] = "";
JapaneseCardTexts[CardNames.CARD_OF_THE_SEAL] = "";
JapaneseCardTexts[CardNames.CARD_OF_THE_SHEEP] = "";
JapaneseCardTexts[CardNames.CARD_OF_THE_SQUIRREL] = "";
JapaneseCardTexts[CardNames.CARD_OF_THE_TURTLE] = "";
JapaneseCardTexts[CardNames.CARD_OF_THE_WORM] = "";

//Allies
JapaneseCardTexts[CardNames.TOWN] = "次のうち1つを選ぶ:////「|＋１　カードを引く|、//|＋２　アクション|」;////「|＋１　購入|、|＋|[2]」";
JapaneseCardTexts[CardNames.MODIFY] = "手札1枚を廃棄する。次のうち//1つを選ぶ:「|＋１　カードを|//|引く|、|＋１　アクション|」;//「廃棄したカードよりコスト//が最大[2]高いカード1枚を//獲得する」";
JapaneseCardTexts[CardNames.MERCHANT_CAMP] = "|＋２　アクション|//|＋[1]|//---//これを場から捨て札にしたとき、//山札の上に置いてもよい。";
JapaneseCardTexts[CardNames.SWAP] = "|＋１　アクション|//|＋１　カードを引く|////手札のアクションカード1枚を//そのカードの山に戻してもよ//い。そうした場合、名前の異//なるコスト[5]以下のアクション//カード1枚を手札に獲得する。";
JapaneseCardTexts[CardNames.SKIRMISHER] = "|＋１　カードを引く|//|＋１　アクション|//|＋[1]|//////このターン、アタックカード1枚を//獲得したとき、他のプレイヤーは全員、//手札が3枚になるように捨て札にする。";
JapaneseCardTexts[CardNames.HIGHWAYMAN] = "あなたの次のターンの開始時に、//これを場から捨て札にし、//|＋３　カードを引く|。////そのターンの開始時まで、他の//プレイヤーが各ターンに初めて//使用する財宝カードは無効になる。";
JapaneseCardTexts[CardNames.BARBARIAN] = "|＋[2]|////他のプレイヤーは全員、山札の//一番上のカードを廃棄する。//それがコスト[3]以上の場合、そ//のプレイヤーは廃棄したカード//より安く同じ種別を持つカード// 1枚を獲得し、それ以外の場合、//呪い1枚を獲得する。";
JapaneseCardTexts[CardNames.INNKEEPER] = "|＋１　アクション|////次のうち1つを選ぶ:////「|＋１　カードを引く|」;////「|＋３　カードを引く|、//手札3枚を捨て札にする」;////「|＋５　カードを引く|、//手札6枚を捨て札にする」";
JapaneseCardTexts[CardNames.CAPITAL_CITY] = "|＋１　カードを引く|//|＋２　アクション|////|＋|[2]のために手札2枚を//捨て札にしてもよい。//|＋２　カードを引く|ために//[2]を支払ってもよい。";
JapaneseCardTexts[CardNames.SPECIALIST] = "手札のアクションカード//1枚か財宝カード1枚を//使用してもよい。次の//うち1つを選ぶ:////「それを再度使用する」;//「それと同じカード1枚を//獲得する」";
JapaneseCardTexts[CardNames.CARPENTER] = "サプライの山がどれも空でない//場合、|＋１　アクション|、コスト//[4]以下のカード1枚を獲得する。////それ以外の場合、手札1枚を廃棄//し、それよりコストが最大[2]高い//カード1枚を獲得する。";
JapaneseCardTexts[CardNames.ROYAL_GALLEY] = "|＋１　カードを引く|////手札から持続でないアクション//カード1枚を使用してもよい。//それを脇に置く。そうした場合、//あなたの次のターンの開始時、//それを使用する。";
JapaneseCardTexts[CardNames.MARQUIS] = "|＋１　購入|////手札1枚につき、//|＋１　カードを引く|。////手札が10枚になるよ//うに捨て札にする。";
JapaneseCardTexts[CardNames.SENTINEL] = "山札の上から5枚を見る。//そのうち最大で2枚カード//を廃棄してよい。残りを//好きな順番で戻す。";
JapaneseCardTexts[CardNames.COURIER] = "|＋[1]|////山札の一番上のカードを捨て札に//する。捨て札置き場のカードを//すべて見る。そのうちアクション//カード1枚か財宝カード1枚を使用//してもよい。";
JapaneseCardTexts[CardNames.GALLERIA] = "|＋[3]|////このターン、コスト[3]または//[4]のカード1枚を獲得したとき、//|＋１　購入|。";
JapaneseCardTexts[CardNames.HUNTER] = "|＋１　アクション|////山札の上から3枚を公開する。//そのうちアクションカード1枚、//財宝カード1枚、勝利点カード//1枚をこの順に手札に加える。//残りを捨て札にする。";

JapaneseCardTexts[CardNames.SYCOPHANT] = "|＋１　アクション|////手札3枚を捨て札にする。//1枚でも捨て札にした場合、//|＋|[3]。//---//これを獲得または廃棄したとき、//|＋２　好意|。";
JapaneseCardTexts[CardNames.UNDERLING] = "|＋１　カードを引く|//|＋１　アクション|//|＋１　好意|";
JapaneseCardTexts[CardNames.IMPORTER] = "あなたの次のターンの開始時、//コスト[5]以下のカード1枚を//獲得する。//---//準備: プレイヤーは全員、//|＋４　好意|。";
JapaneseCardTexts[CardNames.CONTRACT] = "[!2]//|＋１　好意|//手札のアクションカード1枚を//脇に置いてもよい。そうした場合、//あなたの次のターンの開始時、//それを使用する。";
JapaneseCardTexts[CardNames.EMISSARY] = "|＋３　カードを引く|////これによってカードを1枚でも//シャッフルする場合、//|＋１　アクション|、|＋２　好意|。";
JapaneseCardTexts[CardNames.BAUBLE] = "次のうち異なる2つを選ぶ:////「|＋１　購入|」;「|＋|[1]」;//「|＋１　好意|」;「このターン、//カード1枚を獲得したとき、//それを山札の上に置いてもよい」";
JapaneseCardTexts[CardNames.BROKER] = "手札1枚を廃棄し、次のうち1つを//選ぶ:「そのコスト[1]につき|＋１|//|カードを引く|」;「そのコスト[1]//につき|＋１　アクション|」;「そ//のコスト[1]につき|＋|[1]」;「その//コスト[1]につき|＋１　好意|」";
JapaneseCardTexts[CardNames.GUILDMASTER] = "|＋[3]|////このターン、カード1枚を//獲得したとき、|＋１　好意|。";
JapaneseCardTexts[CardNames.ARCHITECTS_GUILD] = "カード1枚を獲得したとき、|２好意|を支払う//ことで、それより安く勝利点でないカード//1枚を獲得してもよい。";
JapaneseCardTexts[CardNames.ORDER_OF_ASTROLOGERS] = "シャッフルするとき、|好意|を好きなだけ支払い、//その枚数のカードを取り出し、シャッフルした//カードの一番上に置いてもよい。";
JapaneseCardTexts[CardNames.LEAGUE_OF_BANKERS] = "あなたの購入フェイズの開始時、あなたの持つ//トークン|４好意| (端数切り捨て) につき|＋|[1]。";
JapaneseCardTexts[CardNames.MOUNTAIN_FOLK] = "あなたのターンの開始時、|＋３　カードを引く|ために//|５好意|を支払ってもよい。";
JapaneseCardTexts[CardNames.WOODWORKERS_GUILD] = "あなたの購入フェイズの開始時、|１好意|を支払うことで、//手札のアクションカード1枚を廃棄してもよい。//そうした場合、アクションカード1枚を獲得する。";
JapaneseCardTexts[CardNames.PEACEFUL_CULT] = "あなたの購入フェイズの開始時、|好意|を好きなだけ//支払い、その枚数の手札を廃棄してもよい。";
JapaneseCardTexts[CardNames.CRAFTERS_GUILD] = "あなたのターンの開始時、|２好意|を支払うことで、//コスト[4]以下のカード1枚を山札の上に獲得してもよい。";
JapaneseCardTexts[CardNames.CAVE_DWELLERS] = "あなたのターンの開始時、|１好意|を支払ってもよい。//そうした場合、手札1枚を捨て札にし、カード1枚を引く。//以上を何度でも繰り返してよい。";
JapaneseCardTexts[CardNames.PLATEAU_SHEPHERDS] = "得点計算のとき、|１好意|とコスト[2]の//カード1枚の組1つにつき、{2}。";
JapaneseCardTexts[CardNames.DESERT_GUIDES] = "あなたのターンの開始時、|１好意|を支払ってもよい。そう//した場合、手札をすべて捨て札にして、カード5枚を引く。//以上を何度でも繰り返してよい。";
JapaneseCardTexts[CardNames.FAMILY_OF_INVENTORS] = "あなたの購入フェイズの開始時、あなたの|１好意|トークンを//サプライの勝利点でない山1つに置いてもよい。その山の//カードは置かれた全員の|好意|トークン1つにつき[1]安くなる。";
JapaneseCardTexts[CardNames.ISLAND_FOLK] = "あなたのターンの終了時、|５好意|を支払うことで、//そのターンの後に追加の1ターンを得てもよい//(ただし、連続3ターンとなる場合は得られない)。";
JapaneseCardTexts[CardNames.ORDER_OF_MASONS] = "シャッフルするとき、|好意|を好きなだけ支払い、//その|１好意|につきカードを最大2枚シャッフル//から取り出し捨て札に置いてもよい。";
JapaneseCardTexts[CardNames.BAND_OF_NOMADS] = "コスト[3]以上のカード1枚を獲得したとき、|１好意|を//支払ってもよい。そうした場合、|＋１　カードを引く|、//または|＋１　アクション|、または|＋１　購入|。";
JapaneseCardTexts[CardNames.GANG_OF_PICKPOCKETS] = "あなたのターンの開始時、|１好意|を支払ってもよい。//そうしない場合、手札が4枚になるように捨て札にする。";
JapaneseCardTexts[CardNames.MARKET_TOWNS] = "あなたの購入フェイズの開始時、|１好意|を支払うことで、//手札のアクションカード1枚を使用してもよい。//以上を何度でも繰り返してよい。";
JapaneseCardTexts[CardNames.FOREST_DWELLERS] = "あなたのターンの開始時、|１好意|を支払ってもよい。//そうした場合、山札の上から3枚を見る。そのうち//好きな枚数を捨て札にし、残りを好きな順番で戻す。";
JapaneseCardTexts[CardNames.FELLOWSHIP_OF_SCRIBES] = "アクションカード1枚を使用した後、手札が4枚以下の場合、//|＋１　カードを引く|ために|１好意|を支払ってもよい。";
JapaneseCardTexts[CardNames.LEAGUE_OF_SHOPKEEPERS] = "連携カード1枚を使用した後、|５好意|以上持っている//場合、|＋|[1]、|10好意|以上持っている場合、追加で//|＋１　アクション|、|＋１　購入|。";
JapaneseCardTexts[CardNames.COASTAL_HAVEN] = "クリーンアップフェイズにあなたが手札をすべて//捨て札にするとき、|好意|を好きなだけ支払ってもよい。//そうした場合、その枚数の手札を次のターンに//保留する (保留したカードに加えて5枚引く)。";
JapaneseCardTexts[CardNames.CITY_STATE] = "アクションカード1枚をあなたのターンに獲得したとき、//|２好意|を支払うことで、それを使用してもよい。";
JapaneseCardTexts[CardNames.TRAPPERS_LODGE] = "カード1枚を獲得したとき、|１好意|を支払う//ことで、それを山札の上に置いてもよい。";
JapaneseCardTexts[CardNames.CIRCLE_OF_WITCHES] = "連携カード1枚を使用した後、|３好意|を//支払ってもよい。そうした場合、他の//プレイヤーは全員、呪い1枚を獲得する。";
JapaneseCardTexts[CardNames.AUGUR_PILE] = "山を準備するにあたって//薬草集め、侍祭、女魔導士、//女予言者各4枚を上からこの//順番になるように積む。山//の一番上のカードのみ購入・//獲得できる。";
JapaneseCardTexts[CardNames.HERB_GATHERER] = "|＋１　購入|////山札を捨て札置き場に置く。//捨て札をすべて見て、その中から//財宝カード1枚を使用してもよい。////卜占官の山を循環させてもよい。";
JapaneseCardTexts[CardNames.ACOLYTE] = "金貨1枚を獲得するために、ア//クションカードか勝利点カード//1枚を手札から廃棄してもよい。////卜占官1枚を獲得するために、//これを廃棄してもよい。";
JapaneseCardTexts[CardNames.SORCERESS] = "|＋１　アクション|////カード1枚を指定する。山札の一番上//のカードを公開し、手札に加える。//そのカードを指定していた場合、//他のプレイヤーは全員、呪い1枚を//獲得する。";
JapaneseCardTexts[CardNames.SIBYL] = "|＋４　カードを引く|//|＋１　アクション|////手札1枚を山札の上に置き、//もう1枚を山札の一番下に置く。";
JapaneseCardTexts[CardNames.CLASH_PILE] = "山を準備するにあたって//戦闘計画、射手、将軍、//領土各4枚を上からこの//順番になるように積む。//山の一番上のカードのみ//購入・獲得できる。";
JapaneseCardTexts[CardNames.BATTLE_PLAN] = "|＋１　カードを引く|//|＋１　アクション|////|＋１　カードを引く|ために、手札の//アタックカード1枚を公開してもよい。////サプライの山1つを循環させてもよい。";
JapaneseCardTexts[CardNames.ARCHER] = "|＋[2]|////手札が5枚以上の他のプレ//イヤーは全員、1枚を除い//て手札をすべて公開し、//そのうちあなたが選んだ//1枚を捨て札にする。";
JapaneseCardTexts[CardNames.WARLORD] = "|＋１　アクション|////あなたの次のターンの開始時、//|＋２　カードを引く|。////そのターンの開始時まで、他のプレ//イヤーは全員、2枚以上自分で場に//出しているアクションカードを手札//から使用することができない。";
JapaneseCardTexts[CardNames.TERRITORY] = "あなたの持つ異なる勝利//点カード1種類につき、//{1}。//---//これを獲得したとき、//サプライの空の山1つに//つき金貨1枚を獲得する。";
JapaneseCardTexts[CardNames.FORT_PILE] = "山を準備するにあたって//天幕、駐屯地、堡塁、要塞//各4枚を上からこの順番に//なるように積む。山の一番//上のカードのみ購入・獲得//できる。";
JapaneseCardTexts[CardNames.TENT] = "|＋[2]|////城砦の山を循環させてもよい。//---//これを場から捨て札にしたとき、//山札の上に置いてもよい。";
JapaneseCardTexts[CardNames.GARRISON] = "|＋[2]|////このターン、カード1枚を獲得した//とき、トークン1枚を場に出ている//このカードの上に置く。あなたの//次のターンの開始時、そのトーク//ンをすべて取り除き、その数だけ//|＋１　カードを引く|。";
JapaneseCardTexts[CardNames.HILL_FORT] = "コスト[4]以下のカード//1枚を獲得する。////次のうち1つを選ぶ:////「それを手札に加える」;//「|＋１　カードを引く|、//|＋１　アクション|」";
JapaneseCardTexts[CardNames.STRONGHOLD] = "次のうち1つを選ぶ://「|＋|[3]」;「あなたの次のターンの//開始時、|＋３　カードを引く|」////---//{!2}";
JapaneseCardTexts[CardNames.ODYSSEY_PILE] = "山を準備するにあたって//古地図、航海、沈没船の//財宝、遠い海岸各4枚を上//からこの順番になるように//積む。山の一番上のカード//のみ購入・獲得できる。";
JapaneseCardTexts[CardNames.OLD_MAP] = "|＋１　カードを引く|//|＋１　アクション|////手札1枚を捨て札にする。//|＋１　カードを引く|。////叙事詩の山を循環させてもよい。";
JapaneseCardTexts[CardNames.VOYAGE] = "|＋１　アクション|////このターンの後に追加の//1ターンを得る(ただし、//連続3ターンとなる場合は//得られない)。そのターン//使用できる手札は3枚まで//とする。";
JapaneseCardTexts[CardNames.SUNKEN_TREASURE] = "場に出していない//アクションカード//1枚を獲得する。";
JapaneseCardTexts[CardNames.DISTANT_SHORE] = "//|＋２　カードを引く|//|＋１　アクション|////屋敷1枚を獲得する。//---//{!2}";
JapaneseCardTexts[CardNames.TOWNSFOLK_PILE] = "山を準備するにあたって//触れ役、蹄鉄工、粉屋、//長老各4枚を上からこの//順番になるように積む。//山の一番上のカードのみ//購入・獲得できる。";
JapaneseCardTexts[CardNames.TOWN_CRIER] = "次のうち1つを選ぶ://「|＋|[2]」;「銀貨1枚を獲得//する」;「|＋１　カードを|//|引く|、|＋１　アクション|」////町民の山を循環させてもよい。";
JapaneseCardTexts[CardNames.BLACKSMITH] = "次のうち1つを選ぶ:////「手札が6枚になるように//カードを引く」;//「|＋２　カードを引く|」;//「|＋１　カードを引く|、//|＋１　アクション|」";
JapaneseCardTexts[CardNames.MILLER] = "|＋１　アクション|////山札の上から4枚を見る。//1枚を手札に加え、残り//を捨て札にする。";
JapaneseCardTexts[CardNames.ELDER] = "|＋[2]|////手札のアクションカード1枚//を使用してもよい。それに//よりこのターンに(「選ぶ」//という指示で)能力を選ぶと//き、追加で異なるもの1つを//選んでもよい。";
JapaneseCardTexts[CardNames.WIZARD_PILE] = "山を準備するにあたって//生徒、霊術師、魔導士、//リッチ各4枚を上からこの//順番になるように積む。//山の一番上のカードのみ//購入・獲得できる。";
JapaneseCardTexts[CardNames.STUDENT] = "|＋１　アクション|////魔法使いの山を循環させてもよい。////手札1枚を廃棄する。財宝カードを//廃棄した場合、|＋１　好意|、さらに//これを山札の上に置く。";
JapaneseCardTexts[CardNames.CONJURER] = "コスト[4]以下のカード1枚を//獲得する。////あなたの次のターンの開始時、//このカード (霊術師) を//場から手札に加える。";
JapaneseCardTexts[CardNames.SORCERER] = "|＋１　カードを引く|//|＋１　アクション|////他のプレイヤーは全員、カード1枚を//指定し、山札の一番上のカードを公開//する。それが指定したカードでない場合、//そのプレイヤーは呪い1枚を獲得する。";
JapaneseCardTexts[CardNames.LICH] = "|＋６　カードを引く|//|＋２　アクション|////あなたは1ターンスキップする。//---//これを廃棄したとき、これを//廃棄置き場から捨て札にし、//これより安いカード1枚を廃棄//置き場から獲得する。";

//Seaside 2E
JapaneseCardTexts[CardNames.MONKEY] = "あなたの次のターンまで、右隣の//プレイヤーがカード1枚を獲得し//たとき、|＋１　カードを引く|。////あなたの次のターンの開始時に、//|＋１　カードを引く|。";
JapaneseCardTexts[CardNames.BLOCKADE] = "コスト[4]以下のカード1枚を脇に//獲得する。あなたの次のターンの//開始時に、手札に加える。脇に//ある間、他のプレイヤーは自分の//ターンにそれと同じカード1枚を//獲得したとき、呪い1枚を獲得する。";
JapaneseCardTexts[CardNames.SAILOR] = "|＋１　アクション|////このターンに1度、持続//カード1枚を獲得したとき、//それを使用してもよい。////あなたの次のターンの開始//時に、|＋|[2]、手札1枚を廃//棄してもよい。";
JapaneseCardTexts[CardNames.PIRATE] = "あなたの次のターンの開始時に、//コスト[6]以下の財宝カード1枚を//手札に獲得する。////---////誰かが財宝カード1枚を獲得//したとき、あなたは手札から//これを使用してもよい。";
JapaneseCardTexts[CardNames.CORSAIR] = "|+[2]|////あなたの次のターンの開始時に、//|＋１　カードを引く|。////そのターンの開始時まで、他の//プレイヤーは全員、各ターンに//初めて使用した銀貨か金貨1枚を//廃棄する。";
JapaneseCardTexts[CardNames.SEA_CHART] = "|＋１　カードを引く|//|＋１　アクション|////山札の一番上のカードを//公開する。同じカードを//場に出している場合、公開//したカードを手札に加える。";
JapaneseCardTexts[CardNames.ASTROLABE] = "現在とあなたの次の//ターンの開始時に、////[!1]//|＋１　購入|";
JapaneseCardTexts[CardNames.TIDE_POOLS] = "|＋３　カードを引く|//|＋１　アクション|////あなたの次のターンの開始時//に、手札2枚を捨て札にする。";
JapaneseCardTexts[CardNames.SEA_WITCH] = "|＋２　カードを引く|//////他のプレイヤーは全員、//呪い1枚を獲得する。////あなたは、あなたの次のターンの//開始時に、|＋２　カードを引く|、//手札2枚を捨て札にする。";

//Prosperity 2E
JapaneseCardTexts[CardNames.ANVIL] = "[!1]////コスト[4]以下のカード1枚を//獲得するために、財宝カード//1枚を捨て札にしてもよい。";
JapaneseCardTexts[CardNames.CLERK] = "|＋[2]|////手札が5枚以上の他のプレイヤー//は全員、その中の1枚を山札//の上に置く。////---//あなたのターンの開始時、手札//からこれを使用してもよい。";
JapaneseCardTexts[CardNames.INVESTMENT] = "手札1枚を廃棄する。////次のうち1つを選ぶ:「|＋|[1]」;//「これを廃棄する。そうした//場合、手札を公開し、その中//の異なる財宝カード1種類に//つき、|＋|{1}」"; //名前の異なる→異なる
JapaneseCardTexts[CardNames.TIARA] = "|＋１　購入|////このターン、カード1枚を//獲得したとき、それを山札//の上に置いてもよい。////手札の財宝カード1枚を2回//使用してもよい。";
JapaneseCardTexts[CardNames.CHARLATAN] = "|＋[3]|////他のプレイヤーは全員、//呪い1枚を獲得する。//---//山師を使うゲームでは、//呪いは財宝カードでも //あり、[1]を生む。";
JapaneseCardTexts[CardNames.COLLECTION] = "[!2]//|＋１　購入|////このターン、アクションカード//1枚を獲得したとき、|＋|{1}。";
JapaneseCardTexts[CardNames.CRYSTAL_BALL] = "[!1]////山札の一番上のカードを見る。//それを廃棄するか、捨て札にす//るか、それがアクションカード//または財宝カードの場合、使用//してもよい(どれもしない場合、//山札の上に戻す)。";
JapaneseCardTexts[CardNames.MAGNATE] = "手札を公開する。その中//の財宝カード1枚につき、//|＋１　カードを引く|。";
JapaneseCardTexts[CardNames.WAR_CHEST] = "左隣のプレイヤーは//カード1枚を指定する。//あなたはこのターンに//軍用金で指定されて//いないコスト[5]以下の//カード1枚を獲得する。";

//Hinterlands 2E
JapaneseCardTexts[CardNames.BERSERKER] = "これより安いカード1枚を//獲得する。////他のプレイヤーは全員、手札//が3枚になるように捨て札に//する。//---//これを獲得したとき、場にア//クションカードを出している//場合、これを使用する。";
JapaneseCardTexts[CardNames.GUARD_DOG] = "|＋２　カードを引く|////引き終えた後、手札が5枚以下の//場合、|＋２　カードを引く|。//---//他のプレイヤーがアタックカード//を使用するとき、その解決前に、//手札からこれを使用してもよい。";
JapaneseCardTexts[CardNames.NOMADS] = "|＋１　購入|//|+[2]|//---//これを獲得または廃棄//したとき、|＋|[2]。";
JapaneseCardTexts[CardNames.TRAIL] = "|＋１　カードを引く|//|＋１　アクション|//---//このカードをクリーンアップ//フェイズ以外の場面で獲得・//廃棄・捨て札にしたときに、//場に出して使用してもよい。";
JapaneseCardTexts[CardNames.WEAVER] = "銀貨2枚またはコスト[4]以下//のカード1枚を獲得する。//---//このカードをクリーンアップ//フェイズ以外の場面で捨て札に//したときに、これを使用しても//よい。";
JapaneseCardTexts[CardNames.SOUK] = "|＋１　購入|//|+[7]|////手札1枚につき、|-|[1] (ただし//[0]未満にはならない)。//---//これを獲得したとき、手札を//最大2枚廃棄してもよい。";
JapaneseCardTexts[CardNames.CAULDRON] = "[!2]//|＋１　購入|////このターン、3枚目のアクション//カードを獲得したとき、他のプ//レイヤーは全員、呪い1枚を獲得//する。";
JapaneseCardTexts[CardNames.WHEELWRIGHT] = "|＋１　カードを引く|//|＋１　アクション|////手札1枚を捨て札にしても//よい。そうした場合、コス//トがそれ以下のアクション//カード1枚を獲得する。 ";
JapaneseCardTexts[CardNames.WITCHS_HUT] = "|＋４　カードを引く|////手札2枚を公開して捨て札//にする。2枚ともアクショ//ンカードの場合、他のプ//レイヤーは全員、呪い1枚//を獲得する。";

//Plunder
JapaneseCardTexts[CardNames.CAGE] = "手札を最大4枚(このカードの)//脇に伏せて置いてもよい。次//に勝利点カード1枚を獲得した//とき、このカードを場から廃棄//し、脇に置いたカードをターン//終了時に手札に加える。";
JapaneseCardTexts[CardNames.GROTTO] = "|＋１　アクション|////手札を最大4枚(このカードの)//脇に伏せて置いてもよい。////あなたの次のターンの開始//時、それらを捨て札にし、//同じ枚数のカードを引く。";
JapaneseCardTexts[CardNames.JEWELLED_EGG] = "[!1]//|＋１　購入|//---//これを廃棄したとき、//戦利品1枚を獲得する。";
JapaneseCardTexts[CardNames.SEARCH] = "|＋[2]|////次にサプライ1山が空//になったとき、これを//場から廃棄し、戦利品//1枚を獲得する。 ";
JapaneseCardTexts[CardNames.SHAMAN] = "|＋１　アクション|//|＋[1]|////手札1枚を廃棄してもよい。//---//シャーマンを使うゲームでは、//あなたは自分の各ターンの開始//時に、廃棄置き場からコスト[6]//以下のカード1枚を獲得する。";
JapaneseCardTexts[CardNames.SECLUDED_SHRINE] = "|＋[1]|////次に財宝カード1枚を獲得//したとき、手札を最大2枚//廃棄してもよい。";
JapaneseCardTexts[CardNames.SIREN] = "他のプレイヤーは全員、呪い//1枚を獲得する。あなたの次の//ターンの開始時、あなたは手札が//8枚になるようにカードを引く。//---//これを獲得したとき、手札から//アクションカード1枚を廃棄して//もよい。廃棄しない場合、これ//を廃棄する。";
JapaneseCardTexts[CardNames.STOWAWAY] = "あなたの次のターンの//開始時に、////|＋２　カードを引く|。//---//誰かが持続カード1枚を獲得//したとき、あなたは手札から//これを使用してもよい。";
JapaneseCardTexts[CardNames.TASKMASTER] = "|＋１　アクション|、|＋|[1]、//このターン、これより後に//あなたがコスト[5]のカード//を獲得した場合、あなたの//次のターンの開始時に、こ//のカードの能力を冒頭から//繰り返す。";
JapaneseCardTexts[CardNames.ABUNDANCE] = "次にアクションカード//1枚を獲得したとき、////|＋１　購入|、|＋|[3]。";
JapaneseCardTexts[CardNames.CABIN_BOY] = "|＋1　カードを引く|//|＋１　アクション|////あなたの次のターンの開始時、//次のうち1つを選ぶ:「|＋|[2]」;//「持続カード1枚を獲得するため//に、これを場から廃棄する」";
JapaneseCardTexts[CardNames.CRUCIBLE] = "手札1枚を廃棄する。//そのコスト[1]につき、//|＋|[1]。";
JapaneseCardTexts[CardNames.FLAGSHIP] = "|＋[2]|////次に命令カード以外の//アクションカード1枚を//使用したとき、それを//再使用する。";
JapaneseCardTexts[CardNames.FORTUNE_HUNTER] = "|＋[2]|////山札の上から3枚を見る。//その中の財宝カード1枚を//使用してもよい。残りを//好きな順番で山札の上に//戻す。";
JapaneseCardTexts[CardNames.GONDOLA] = "現在またはあなたの次の//ターンの開始時に、|＋|[2]。//---//これを獲得したとき、手札//のアクションカード1枚を//使用してもよい。";
JapaneseCardTexts[CardNames.HARBOR_VILLAGE] = "|＋１　カードを引く|//|＋２　アクション|////このターン次にアクション//カード1枚を使用した後、//その効果で|＋|[]を得て//いた場合、|＋|[1]。";
JapaneseCardTexts[CardNames.LANDING_PARTY] = "|＋２　カードを引く|//|＋２　アクション|////次にターン中最初に使用//するカードが財宝カード//であるとき、その後に、//これを山札の上に置く。";
JapaneseCardTexts[CardNames.MAPMAKER] = "山札の上から4枚を見る。その//中の2枚を手札に加え、残りを//捨て札にする。//---//誰かが勝利点カード1枚を獲得//したとき、あなたはこれを手札//から使用してもよい。";
JapaneseCardTexts[CardNames.MAROON] = "手札1枚を廃棄する。それ//が持つ種別(アクション、//アタックなど)1つにつき////|＋２　カードを引く|。";
JapaneseCardTexts[CardNames.ROPE] = "[!1]//|＋１　購入|////あなたの次のターンの開始時//に、|＋１　カードを引く|、//手札1枚を廃棄してもよい。";
JapaneseCardTexts[CardNames.SWAMP_SHACKS] = "|＋２　アクション|////場に出しているカード3枚//(端数切り捨て)につき、//|＋１　カードを引く|。";
JapaneseCardTexts[CardNames.TOOLS] = "(自分を含む)誰かが場//に出しているのと同じ//カード1枚を獲得する。";
JapaneseCardTexts[CardNames.BURIED_TREASURE] = "あなたの次のターンの開始時、////|＋１　購入|、|＋|[3]。////---//これを獲得したとき、使用する。";
JapaneseCardTexts[CardNames.CREW] = "|＋３　カードを引く|////あなたの次のターンの開始時、//これを場から山札の上に置く。";
JapaneseCardTexts[CardNames.CUTTHROAT] = "他のプレイヤーは全員、手札が//3枚になるように捨て札にする。////次に(自分を含む)誰かがコスト//[5]以上の財宝カード1枚を獲得//したとき、あなたは戦利品1枚//を獲得する。";
JapaneseCardTexts[CardNames.ENLARGE] = "現在とあなたの次の//ターンの開始時:////手札1枚を廃棄し、それより//コストが最大[2]高いカード//1枚を獲得する。";
JapaneseCardTexts[CardNames.FIGURINE] = "|＋２　カードを引く|////手札のアクションカード//1枚を捨て札にしても//よい。そうした場合、//|＋１　購入|、|＋|[1]。";
JapaneseCardTexts[CardNames.FIRST_MATE] = "手札から、名前が互いに//完全に一致するアクション//カードを好きな枚数使用し//てもよい。その後、手札が//6枚になるようにカードを//引く。";
JapaneseCardTexts[CardNames.FRIGATE] = "|＋[3]|////あなたの次のターンの開始時//まで、他のプレイヤーはアク//ションカード1枚を使用する//たび、その後に、手札が4枚//になるように捨て札にする。";
JapaneseCardTexts[CardNames.LONGSHIP] = "|＋２　アクション|////あなたの次のターンの開始時、//|＋２　カードを引く|。";
JapaneseCardTexts[CardNames.MINING_ROAD] = "|＋１　アクション|//|＋１　購入|//|＋[2]|////このターンに1度、財宝カ//ード1枚を獲得したとき、//それを使用してもよい。";
JapaneseCardTexts[CardNames.PENDANT] = "場に出している異なる//財宝カード1種類に//つき、|＋|[1]。";
JapaneseCardTexts[CardNames.PICKAXE] = "[!1]//手札1枚を廃棄する。//そのコストが[3]以上//の場合、戦利品1枚を//手札に獲得する。";
JapaneseCardTexts[CardNames.PILGRIM] = "|＋４　カードを引く|////手札1枚を山札の上に置く。";
JapaneseCardTexts[CardNames.QUARTERMASTER] = "ゲーム終了まで、あなたの各ター//ンの開始時、次のうち1つを選ぶ:////「コスト[4]以下のカード1枚を//(このカードの)脇に獲得する」;////「このカードの脇にあるカード//1枚を手札に加える」";
JapaneseCardTexts[CardNames.SILVER_MINE] = "これより安い財宝カード//1枚を手札に獲得する。";
JapaneseCardTexts[CardNames.TRICKSTER] = "他のプレイヤーは全員、//呪い1枚を獲得する。////このターンに1度、財宝//カード1枚を場から捨て//札にしたとき、それを脇に//置いてもよい。ターン終了//時、それを手札に加える。";
JapaneseCardTexts[CardNames.WEALTHY_VILLAGE] = "|＋１　カードを引く|//|＋２　アクション|//---//これを獲得したとき、異なる//財宝カードを3種類以上場に//出している場合、戦利品1枚//を獲得する。";
JapaneseCardTexts[CardNames.SACK_OF_LOOT] = "[!1]//|＋１　購入|////戦利品1枚を獲得する。";
JapaneseCardTexts[CardNames.KINGS_CACHE] = "手札の財宝カード1枚を//3回使用してもよい。";

JapaneseCardTexts[CardNames.BURY] = "|＋１　購入|//捨て札置き場のカード1枚を山札の一番下に置く。";
JapaneseCardTexts[CardNames.AVOID] = "|＋１　購入|//このターン次にシャッフルするとき、カードを最大3枚//シャッフルから取り出し捨て札に置く。";
JapaneseCardTexts[CardNames.DELIVER] = "|＋１　購入|//このターン、カード1枚を獲得するたびにそれを//脇に置き、ターン終了時に手札に加える。";
JapaneseCardTexts[CardNames.PERIL] = "戦利品1枚を獲得するために、手札から//アクションカード1枚を廃棄してもよい。";
JapaneseCardTexts[CardNames.RUSH] = "|＋１　購入|//このターン次にアクションカード1枚を//獲得したとき、それを使用する。";
JapaneseCardTexts[CardNames.FORAY] = "手札3枚を公開して捨て札にする。その3枚が//異なるカードの場合、戦利品1枚を獲得する。";
JapaneseCardTexts[CardNames.LAUNCH] = "1ターンに1度のみ://アクションフェイズに戻る。|＋１　カードを引く|、//|＋１　アクション|、|＋１　購入|。";
JapaneseCardTexts[CardNames.MIRROR] = "|＋１　購入|//このターン次にアクションカード1枚を獲得した//とき、追加で同じカード1枚を獲得する。";
JapaneseCardTexts[CardNames.PREPARE] = "手札をすべて表向きに脇に置く。あなたの次のターンの//開始時、その中のアクションカードと財宝カードを好き//な順番ですべて使用し、その後、残りを捨て札にする。";
JapaneseCardTexts[CardNames.SCROUNGE] = "次のうち1つを選ぶ:「手札1枚を廃棄する」;//「廃棄置き場から屋敷1枚を獲得する。獲得した//場合、コスト[5]以下のカード1枚を獲得する」";
JapaneseCardTexts[CardNames.JOURNEY] = "このターン、あなたはクリーンアップフェイズに場のカード//を捨て札にしない。このターンの後に追加の1ターンを得る//(ただし、連続3ターンとなる場合は得られない)。";
JapaneseCardTexts[CardNames.MAELSTROM] = "手札3枚を廃棄する。手札が5枚以上の他の//プレイヤーは全員、手札1枚を廃棄する。";
JapaneseCardTexts[CardNames.LOOTING] = "戦利品1枚を獲得する。";
JapaneseCardTexts[CardNames.INVASION] = "手札からアタックカード1枚を使用してもよい。//公領1枚を獲得する。アクションカード1枚を山札//の上に獲得する。戦利品1枚を獲得し、使用する。";
JapaneseCardTexts[CardNames.PROSPER] = "戦利品1枚と、好きな枚数の互いに異なる財宝カードを獲得する。";

JapaneseCardTexts[CardNames.AMPHORA] = "現在またはあなたの次//のターンの開始時に、////|＋１　購入|、|＋|[3]。";
JapaneseCardTexts[CardNames.DOUBLOONS] = "[!3]////---//これを獲得したとき、//金貨1枚を獲得する。";
JapaneseCardTexts[CardNames.ENDLESS_CHALICE] = "現在と、ゲーム終了まで//あなたの各ターンの開始時に、////[!1]//|＋１　購入|";
JapaneseCardTexts[CardNames.FIGUREHEAD] = "[!3]////あなたの次のターンの//開始時、////|＋２　カードを引く|。";
JapaneseCardTexts[CardNames.HAMMER] = "[!3]////コスト[4]以下のカード//1枚を獲得する。";
JapaneseCardTexts[CardNames.INSIGNIA] = "[!3]////このターン、カード1枚を//獲得したとき、それを山札//の上に置いてもよい。";
JapaneseCardTexts[CardNames.JEWELS] = "[!3]//|＋１　購入|//あなたの次のターンの//開始時に、これを山札//の一番下に置く。";
JapaneseCardTexts[CardNames.ORB] = "捨て札置き場のカード//をすべて見る。次のうち//1つを選ぶ:「その中の//アクションカードか財宝//カード1枚を使用する」;//「|＋１　購入|、|＋|[3]」";
JapaneseCardTexts[CardNames.PRIZE_GOAT] = "[!3]//|＋１　購入|////手札1枚を廃棄してもよい。";
JapaneseCardTexts[CardNames.PUZZLE_BOX] = "[!3]//|＋１　購入|////手札1枚を脇に伏せて置い//てもよい。ターン終了時//にそれを手札に加える。";
JapaneseCardTexts[CardNames.SEXTANT] = "[!3]//|＋１　購入|////山札の上から5枚を見る。その中//の好きな枚数を捨て札にする。//残りを好きな順番で山札の上に//戻す。";
JapaneseCardTexts[CardNames.SHIELD] = "[!3]//|＋１　購入|//---////他のプレイヤーがアタックカードを使用//するとき、その解決前に、手札からこれ//を公開してもよい。公開した場合、その//アタックカードの影響を受けない。";
JapaneseCardTexts[CardNames.SPELL_SCROLL] = "これを廃棄する。廃棄//した場合、これより安い// カード1枚を獲得する。//それがアクションまたは//財宝カードの場合、使用//してもよい。";
JapaneseCardTexts[CardNames.STAFF] = "[!3]//|＋１　購入|////手札からアクションカード//1枚を使用してもよい。";
JapaneseCardTexts[CardNames.SWORD] = "[!3]//|＋１　購入|//他のプレイヤーは全員、//手札が4枚になるように//捨て札にする。";

JapaneseCardTexts[CardNames.CHEAP] = "安価なカードのコストは[1]下がる。";
JapaneseCardTexts[CardNames.CURSED] = "呪われたカード1枚を獲得したとき、//戦利品1枚と呪い1枚を獲得する。";
JapaneseCardTexts[CardNames.FATED] = "シャッフルするとき、それらのカードをすべて見て、その//中の運命のカードを何枚でも公開してもよい。公開した各//カードをシャッフルしたカードの一番上か一番下に置く。";
JapaneseCardTexts[CardNames.FAWNING] = "属州1枚を獲得したとき、へつらう//カード1枚を獲得する。";
JapaneseCardTexts[CardNames.FRIENDLY] = "あなたのクリーンアップフェイズの開始時、手札の//友好的なカードのうち1枚を捨て札にしてもよい。//そうした場合、友好的なカード1枚を獲得する。";
JapaneseCardTexts[CardNames.HASTY] = "せっかちなカード1枚を獲得したとき、それを脇に//置き、あなたの次のターンの開始時に使用する。";
JapaneseCardTexts[CardNames.INHERITED] = "準備:ゲーム開始時の自分のカード1枚を選び、//受け継がれたカード1枚と入れ替える。";
JapaneseCardTexts[CardNames.INSPIRING] = "あなたのターンに鼓舞するカードを使用した後、//場に出していないアクションカード1枚を手札//から使用してもよい。";
JapaneseCardTexts[CardNames.NEARBY] = "近隣のカード1枚を獲得したとき、|＋１　購入|。";
JapaneseCardTexts[CardNames.PATIENT] = "あなたのクリーンアップフェイズの開始時に、手札から//忍耐強いカードを何枚でも脇に置いてもよい。そうした//場合、あなたの次のターンの開始時にそれらを使用する。";
JapaneseCardTexts[CardNames.PIOUS] = "敬虔なカード1枚を獲得したとき、//手札1枚を廃棄してもよい。";
JapaneseCardTexts[CardNames.RECKLESS] = "無謀なカードは1度の使用で2回指示に従う。//無謀なカードを場から捨て札にしたとき、//それをそのカードの山に戻す。";
JapaneseCardTexts[CardNames.RICH] = "豊かなカード1枚を獲得したとき、//銀貨1枚を獲得する。";
JapaneseCardTexts[CardNames.SHY] = "あなたのターンの開始時に、手札の内気な//カードのうち1枚を捨て札にしてもよい。//そうした場合、|＋２　カードを引く|。";
JapaneseCardTexts[CardNames.TIRELESS] = "疲れ知らずのカードを場から捨て札にしたとき、//それを脇に置き、ターン終了時に山札の上に置く。";

//Cornucopia & Guilds 2E
JapaneseCardTexts[CardNames.CARNIVAL] = "山札の上から4枚を公開//する。その中の互いに//異なるカード1枚ずつを//手札に加え、残りを捨//て札にする。";
JapaneseCardTexts[CardNames.FARMHANDS] = "|＋１　カードを引く|//|＋２　アクション|//---//これを獲得したとき、手札のアク//ションカードか財宝カード1枚を//脇に置いてもよい。あなたの次の//ターンの開始時、それを使用する。";
JapaneseCardTexts[CardNames.FERRYMAN] = "|＋２　カードを引く|//|＋１　アクション|//手札1枚を捨て札にする。//---//準備:このゲームで使わないコスト//[3]か[4]の王国カードの山1つを//選ぶ。渡し守1枚を獲得したとき、//その山からも1枚獲得する。";
JapaneseCardTexts[CardNames.JOUST] = "|＋１　カードを引く|//|＋１　アクション|//|＋[1]|////恩賞1枚を手札に獲得するために、//属州1枚を脇に置いてもよい。その//属州はクリーンアップフェイズに//捨て札にする。";
JapaneseCardTexts[CardNames.SHOP] = "|＋１　カードを引く|//|＋[1]|////場に出していないアクション//カード1枚を手札から使用して//もよい。";
JapaneseCardTexts[CardNames.REWARDS_PILE] = "--";
JapaneseCardTexts[CardNames.CORONET] = "手札から恩賞でないアクション//カード1枚を2回使用してもよい。////手札から恩賞でない財宝カード//1枚を2回使用してもよい。//////////%(このカードはサプライに置かない。)%";
JapaneseCardTexts[CardNames.COURSER] = " 次のうち異なる2つを選ぶ:////「|＋２　カードを引く|」;//「|＋２　アクション|」;//「|＋|[2]」;//「銀貨4枚を獲得する」////////%(このカードはサプライに置かない。)%";
JapaneseCardTexts[CardNames.DEMESNE] = "|＋２　アクション|//|＋２　購入|////金貨1枚を獲得する。//---//あなたの金貨1枚につき{1}。//////%(このカードはサプライに置かない。)%";
JapaneseCardTexts[CardNames.HOUSECARL] = "場に出している異なるアクションカード//1種類につき、|＋１　カードを引く|。//////////%(このカードはサプライに置かない。)%";
JapaneseCardTexts[CardNames.HUGE_TURNIP] = "//|＋２　財源|////あなたの持っている1財源につき、|＋|[1]。////////%(このカードはサプライに置かない。)%";
JapaneseCardTexts[CardNames.RENOWN] = "|＋１　購入|////このターン、すべてのカードの//コストは[2]下がる。////////%(このカードはサプライに置かない。)%";
JapaneseCardTexts[CardNames.FARRIER] = "|＋１　カードを引く|//|＋１　アクション|//|＋１　購入|//---//過払い:追加で支払った[1]に//つき、このターンの終了時、//|＋１　カードを引く|。";
JapaneseCardTexts[CardNames.FOOTPAD] = "//|＋２　財源|////他のプレイヤーは全員、手札が3枚//になるように捨て札にする。//---//これを使うゲームで、誰かが自他の//アクションフェイズにカード1枚を//獲得したとき、そのプレイヤーは//|＋１　カードを引く|。";
JapaneseCardTexts[CardNames.INFIRMARY] = "|＋１　カードを引く|////手札1枚を廃棄してもよい。//---//過払い:追加で支払った[1]に//つき1回、これを使用する。";

//Rising Sun
JapaneseCardTexts[CardNames.FISHMONGER] = "|＋１　購入|//|＋[1]|//---//これは手札からと同様に//山札からも使用できる。";
JapaneseCardTexts[CardNames.SNAKE_WITCH] = "|＋１　カードを引く|//|＋１　アクション|//手札のカードがすべて異なる//場合、手札を公開しこれを//このカードの山に戻しても//よい。そうした場合、他の//プレイヤーは全員、呪い1枚//を獲得する。";
JapaneseCardTexts[CardNames.ARISTOCRAT] = "場に出している公家の枚数が...////1枚か5枚の場合、|＋３　アクション|//2枚か6枚の場合、|＋３　カードを引く|//3枚か7枚の場合、|＋|[3]//4枚か8枚の場合、|＋３　購入|";
JapaneseCardTexts[CardNames.CRAFTSMAN] = "|＋[2D]|////コスト[5]以下のカード//1枚を獲得する。";
JapaneseCardTexts[CardNames.RIVERBOAT] = "あなたの次のターンの開始//時に、脇に準備したカードを//動かさずに使用する。//---//準備:このゲームで使わない、//持続ではなくアクションで//あるコスト[5]の王国カード//1枚を脇に置く。";
JapaneseCardTexts[CardNames.ROOT_CELLAR] = "|＋３　カードを引く|//|＋１　アクション|//|＋[3D]|";
JapaneseCardTexts[CardNames.ALLEY] = "|＋１　カードを引く|//|＋１　アクション|////手札1枚を捨て札にする。//---//これは手札からと同様に//山札からも使用できる。";
JapaneseCardTexts[CardNames.CHANGE] = "[D]を持っている場合、|＋|[3]。//////それ以外の場合、手札1枚を//廃棄し、それよりコストの[]//が高いカード1枚を獲得する。//[]の差に等しい数だけ、|＋|[D]。";
JapaneseCardTexts[CardNames.NINJA] = "|＋１　カードを引く|////他のプレイヤーは全員、//手札が3枚になるように//捨て札にする。//---//これは手札からと同様に//山札からも使用できる。";
JapaneseCardTexts[CardNames.POET] = "|＋１ <>|//|＋１　カードを引く|//|＋１　アクション|//山札の一番上のカードを公開//する。それがコスト[3]以下//の場合、手札に加える。";
JapaneseCardTexts[CardNames.RIVER_SHRINE] = "|＋１ <>|////手札を最大2枚廃棄してもよい。////クリーンアップフェイズの開始//時、このターン購入フェイズに//カードを獲得しなかった場合、//コスト[4]以下のカード1枚を//獲得する。";
JapaneseCardTexts[CardNames.RUSTIC_VILLAGE] = "|＋１ <>|//|＋１　カードを引く|//|＋２　アクション|//////|＋１　カードを引く|ために//手札2枚を捨て札にしても//よい。";
JapaneseCardTexts[CardNames.GOLD_MINE] = "|＋１　カードを引く|//|＋１　アクション|//|＋１　購入|//////金貨1枚と|＋|[4D]を//獲得してもよい。";
JapaneseCardTexts[CardNames.IMPERIAL_ENVOY] = "|＋５　カードを引く|//|＋１　購入|//|＋|[2D]";
JapaneseCardTexts[CardNames.KITSUNE] = "|＋１ <>|////次のうち異なる2つを選ぶ:////「|+|[2]」;//「|＋２　アクション|」;//「他のプレイヤーは全員、//呪い1枚を獲得する」;//「銀貨1枚を獲得する」";
JapaneseCardTexts[CardNames.LITTER] = "|＋２　カードを引く|//|＋２　アクション|//|+[1D]|";
JapaneseCardTexts[CardNames.RICE_BROKER] = "|＋１　アクション|//////手札1枚を廃棄する。////それが財宝カードの場合、//|＋２　カードを引く|。////それがアクションカードの場合、//|＋５　カードを引く|。";
JapaneseCardTexts[CardNames.RONIN] = "手札が7枚になるように//カードを引く。//---//これは手札からと同様に//山札からも使用できる。";
JapaneseCardTexts[CardNames.TANUKI] = "手札1枚を廃棄する。//それよりコストが最大[2]//高いカード1枚を獲得する。//---//これは手札からと同様に//山札からも使用できる。";
JapaneseCardTexts[CardNames.TEA_HOUSE] = "|＋１ <>|//|＋１　カードを引く|//|＋１　アクション|//|＋[2]|";
JapaneseCardTexts[CardNames.SAMURAI] = "他のプレイヤーは全員、手札が//3枚になるように捨て札にする//（1度のみ）。////ゲーム終了まで、あなたのターン//の開始時に、|＋|[1]。//////%(このカードは場に残り続ける。)%";
JapaneseCardTexts[CardNames.RICE] = "|＋１　購入|//////場に出しているカードの//異なる種別1つにつき、//|＋|[1]。";
JapaneseCardTexts[CardNames.MOUNTAIN_SHRINE] = "|＋１ <>|//|＋[2]|////手札1枚を廃棄してもよい。//その後、廃棄置き場にアク//ションカードがある場合、//|＋２　カードを引く|。";
JapaneseCardTexts[CardNames.DAIMYO] = "|＋１　カードを引く|//|＋１　アクション|////このターン、次に命令で//はないアクションカード//1枚を使用したとき、それ//を再使用する。";
JapaneseCardTexts[CardNames.ARTIST] = "|＋１　アクション|////1枚だけ場に出している//カード1枚につき、//|＋１　カードを引く|。";
JapaneseCardTexts[CardNames.APPROACHING_ARMY] = "アタックカード1枚を使用したとき、|＋|[1]。//---//準備:ゲームにアタックである王国カードの山1つを追加する。";
JapaneseCardTexts[CardNames.BIDING_TIME] = "あなたのクリーンアップフェイズの開始時に、手札をすべて伏せて脇に置く。//あなたの次のターンの開始時に、それらのカードを手札に加える。";
JapaneseCardTexts[CardNames.BUREAUCRACY] = "コスト[0]でないカードを1枚を獲得したとき、銅貨1枚を獲得する。";
JapaneseCardTexts[CardNames.DIVINE_WIND] = "最後の<>を取り除いたとき、サプライにある王国カードの山をすべて//取り除き、新しくランダムに王国カードの山10個を追加する。";
JapaneseCardTexts[CardNames.ENLIGHTENMENT] = "財宝カードはアクションカードでもある。アクション//フェイズに財宝カードを使用するとき、その指示に従う//代わりに、|＋１　カードを引く|、|＋１　アクション|。";
JapaneseCardTexts[CardNames.FLOURISHING_TRADE] = "すべてのカードのコストは[1]下がる。//アクション権を購入権として使ってよい。";
JapaneseCardTexts[CardNames.GOOD_HARVEST] = "各ターン中、名前の異なる財宝カードを初めて//使用するたび、先に、|＋１　購入|、|＋|[1]。";
JapaneseCardTexts[CardNames.GREAT_LEADER] = "アクションカード1枚を使用するたび、その後に、|＋１　アクション|。";
JapaneseCardTexts[CardNames.GROWTH] = "財宝カード1枚を獲得したとき、それより安いカード1枚を獲得する。";
JapaneseCardTexts[CardNames.HARSH_WINTER] = "あなたのターンにカード1枚を獲得したとき、//その山に[D]がある場合、それを得る。//それ以外の場合、その山に[2D]を置く。";
JapaneseCardTexts[CardNames.KIND_EMPEROR] = "あなたのターンの開始時とあなたが最後の<>を取り除いたとき、//アクションカード1枚を手札に獲得する。";
JapaneseCardTexts[CardNames.PANIC] = "財宝カード1枚を使用したとき、|＋２　購入|、そのカードを//場から捨て札にしたとき、それをそのカードの山に戻す。";
JapaneseCardTexts[CardNames.PROGRESS] = "カード1枚を獲得したとき、それを山札の上に置く。";
JapaneseCardTexts[CardNames.RAPID_EXPANSION] = "アクションカードか財宝カード1枚を獲得したとき、それを//脇に置き、あなたの次のターンの開始時に、使用する。";
JapaneseCardTexts[CardNames.SICKNESS] = "あなたのターンの開始時に、次のうち1つを選ぶ://「呪い1枚を山札の上に獲得する」;「手札3枚を捨て札にする」";
JapaneseCardTexts[CardNames.AMASS] = "アクションカードを1枚も場に出していない場合、//コスト[5]以下のアクションカード1枚を獲得する。";
JapaneseCardTexts[CardNames.ASCETICISM] = "[]を好きなだけ支払い、それと同じ枚数の手札を廃棄する。";
JapaneseCardTexts[CardNames.CREDIT] = "コスト[8]以下のアクションカードまたは財宝カード//1枚を獲得する。そのコストに等しい数だけ、|＋|[D]。";
JapaneseCardTexts[CardNames.FORESIGHT] = "アクションカード1枚が公開されるまで山札を上から//公開する。その1枚を脇に置き、残りを捨て札にする。//ターン終了時、そのカードを手札に加える。";
JapaneseCardTexts[CardNames.KINTSUGI] = "手札1枚を廃棄する。このゲーム中に金貨1枚を獲得していた場合、//廃棄したカードよりコストが最大[2]高いカード1枚を獲得する。";
JapaneseCardTexts[CardNames.PRACTICE] = "手札のアクションカード1枚を2回使用してもよい。";
JapaneseCardTexts[CardNames.SEA_TRADE] = "場に出しているアクションカード1枚につき、|＋１　カードを引く|。//その枚数以下の手札を廃棄してもよい。";
JapaneseCardTexts[CardNames.RECEIVE_TRIBUTE] = "このターン3枚以上カードを獲得していた場合、場に出していない//アクションカードを1枚ずつ3種類まで獲得してもよい。";
JapaneseCardTexts[CardNames.GATHER] = "コスト[3]、[4]、[5]のカードを1枚ずつ獲得する。";
JapaneseCardTexts[CardNames.CONTINUE] = "1ターンに1度のみ:アタックでないコスト[4]以下のアクションカード//1枚を獲得する。アクションフェイズに戻り、それを使用する。//|＋１　アクション|、|＋１　購入|。";