"use strict";

var SpanishPhrases = {};

SpanishPhrases[Phrases.THE] = "la"; // Gasku devuelve un Caballo a _*_*_el Caballo pila.
SpanishPhrases[Phrases.THE_FROM] = "el";
SpanishPhrases[Phrases.THE_TO] = "el";
SpanishPhrases[Phrases.AND] = "y";
SpanishPhrases[Phrases.OR] = "o";
SpanishPhrases[Phrases.ANOTHER] = "otra";
SpanishPhrases[Phrases.OTHER] = "otra";
SpanishPhrases[Phrases.PILE] = "pila";
SpanishPhrases[Phrases.UNDO] = "Deshacer";
SpanishPhrases[Phrases.YOU] = "Tú";
SpanishPhrases[Phrases.WOULD] = "_would_";
SpanishPhrases[Phrases.YOU_MAY] = "Puedes...";
SpanishPhrases[Phrases.COMMA] = ", ";
SpanishPhrases[Phrases.PERIOD] = ".";
SpanishPhrases[Phrases.COMPLEX_STORY_LINE] = "PRIMERO, SEGUNDO";
SpanishPhrases[Phrases.COIN] = "Moneda";
SpanishPhrases[Phrases.COINS] = "Monedas";
SpanishPhrases[Phrases.COINS_MANY] = "Monedas";
SpanishPhrases[Phrases.POTION] = "Poción";
SpanishPhrases[Phrases.POTIONS] = "Pociones";
SpanishPhrases[Phrases.POTIONS_MANY] = "Pociones";
SpanishPhrases[Phrases.DEBT] = "Deuda";

SpanishPhrases[Phrases.VERSION] = "Versión";
SpanishPhrases[Phrases.USERNAME] = "Nombre de usuario";
SpanishPhrases[Phrases.PASSWORD] = "Contraseña";
SpanishPhrases[Phrases.CONFIRM_PASSWORD] = "Confirmar contraseña";
SpanishPhrases[Phrases.FORGOT_YOUR_PASSWORD] = "¿Olvidaste la contraseña?";
SpanishPhrases[Phrases.CREATE_ACCOUNT] = "Crear cuenta";
SpanishPhrases[Phrases.EMAIL] = "Email";
SpanishPhrases[Phrases.SIGN_UP] = "Registrarse";
SpanishPhrases[Phrases.LOGIN] = "Entrar";
SpanishPhrases[Phrases.KICK] = "Expulsar";
SpanishPhrases[Phrases.RESIGN] = "Rendirse";
SpanishPhrases[Phrases.CREATE_TABLE] = "Crear Mesa";
SpanishPhrases[Phrases.LEAVE_TABLE] = "Abandonar Mesa";
SpanishPhrases[Phrases.MESSAGE] = "mensaje";
SpanishPhrases[Phrases.START_GAME] = "Comenzar";
SpanishPhrases[Phrases.READY] = "Preparado";
SpanishPhrases[Phrases.NOT_READY] = "No preparado";
SpanishPhrases[Phrases.PLAYERS] = "Jugadores";
SpanishPhrases[Phrases.SPECTATORS] = "Espectadores";
SpanishPhrases[Phrases.HOST] = "Anfitrión";
SpanishPhrases[Phrases.RANDOM] = "Aleatorio";
SpanishPhrases[Phrases.BASIC_OPTIONS] = "Ajustes Básicos";
SpanishPhrases[Phrases.ADVANCED_OPTIONS] = "Ajustes Avanzados";
SpanishPhrases[Phrases.YES] = "Sí";
SpanishPhrases[Phrases.NO] = "No";
SpanishPhrases[Phrases.FRIENDS_ONLY] = "Solo Amigos";
SpanishPhrases[Phrases.EVENTS_AND_LANDMARKS] = "Eventos e Hitos";
SpanishPhrases[Phrases.TRUE] = "Sí";
SpanishPhrases[Phrases.FALSE] = "No";
SpanishPhrases[Phrases.QUICK_MATCH] = "Partida Rápida";
SpanishPhrases[Phrases.GOOD_MATCH] = "Partida igualada";
SpanishPhrases[Phrases.WAIT_FOR_EXPANSIONS] = "Esperar a Expansiones";
SpanishPhrases[Phrases.CANCEL] = "Cancelar";
SpanishPhrases[Phrases.VIEW_TRASH] = "Eliminadas";
SpanishPhrases[Phrases.VIEW_KINGDOM] = "Reino";
SpanishPhrases[Phrases.VIEW_PLAY_AREA] = "Área de Juego";
SpanishPhrases[Phrases.LEAVE] = "Abandonar";
SpanishPhrases[Phrases.TOTAL_IN_QUEUE] = "Usuarios en cola";
SpanishPhrases[Phrases.EDIT_TABLE] = "Editar Mesa";
SpanishPhrases[Phrases.NEED_HELP] = "¿Necesitas ayuda?";
SpanishPhrases[Phrases.PLAY] = "Jugar";
SpanishPhrases[Phrases.MAKE_RESIGN] = "¿Forzar que USERNAME se rinda?";
SpanishPhrases[Phrases.UNDO_REQUEST_SINGULAR] = "Petición para deshacer 1 paso";
SpanishPhrases[Phrases.UNDO_REQUEST_PLURAL] = "Petición para deshacer NUMBER pasos";
SpanishPhrases[Phrases.UNDO_REQUEST_MANY] = "Petición para deshacer NUMBER pasos";
SpanishPhrases[Phrases.PENDING_UNDO_REQUEST] = "Petición para deshacer en curso...";
SpanishPhrases[Phrases.SPECTATOR_UNDO_REQUEST] = "USERNAME ha pedido deshacer";
SpanishPhrases[Phrases.GRANT] = "Conceder";
SpanishPhrases[Phrases.ACCEPT] = "Aceptar";
SpanishPhrases[Phrases.DECLINE] = "Declinar";
SpanishPhrases[Phrases.DENY] = "Denegar";
SpanishPhrases[Phrases.UNDO_DENIED] = "Petición para deshacer denegada.";
SpanishPhrases[Phrases.UNDO_CANCELLED] = "Petición para deshacer cancelada.";
SpanishPhrases[Phrases.TOC_SENTENCE] = "He leído y acepto los TERMS_OF_SERVICE.";
SpanishPhrases[Phrases.TERMS_OF_SERVICE] = "Términos del servicio";
SpanishPhrases[Phrases.TERMS_OF_SERVICE_URL] = ""; /* edit it after writing translated terms of service */
SpanishPhrases[Phrases.RECONNECTING] = "Reconectando...";
SpanishPhrases[Phrases.PLAYER_JOINED_TABLE] = "PLAYER_NAME se unió a la mesa.";
SpanishPhrases[Phrases.PLAYER_LEFT_TABLE] = "PLAYER_NAME abandonó la mesa.";
SpanishPhrases[Phrases.JOINING_GAME_MESSAGE] = "Uniéndose a la partida #NUMBER en GAMESERVER_NAME.";
SpanishPhrases[Phrases.SIGNING_UP] = "Registrándote...";
SpanishPhrases[Phrases.ACTION] = "Acción";
SpanishPhrases[Phrases.ACTIONS] = "Acciones";
SpanishPhrases[Phrases.BUY] = "Compra";
SpanishPhrases[Phrases.BUYS] = "Compras";
SpanishPhrases[Phrases.KICK_SUCCEEDED] = "Expulsión exitosa";
SpanishPhrases[Phrases.CONNECTION_CLOSED] = "La conexión con el servidor se cerró o no pudo establecerse.";
SpanishPhrases[Phrases.NEW_PASSWORD] = "Nueva contraseña";
SpanishPhrases[Phrases.RETRY] = "Reintentar";
SpanishPhrases[Phrases.FRIEND] = "Amigo";
SpanishPhrases[Phrases.ACTIVITY] = "Actividad";
SpanishPhrases[Phrases.STATUS] = "Estado";
SpanishPhrases[Phrases.PURCHASE] = "Compra";
SpanishPhrases[Phrases.UNTIL] = "hasta";
SpanishPhrases[Phrases.BUY_FOR_AMOUNT] = "Comprar por AMOUNT";
SpanishPhrases[Phrases.CHANGE_PASSWORD] = "Cambiar";
SpanishPhrases[Phrases.HIDE] = "Ocultar";
SpanishPhrases[Phrases.CONFIRM] = "Confirmar";
SpanishPhrases[Phrases.JOIN] = "unirse";
SpanishPhrases[Phrases.TURN] = "Turno";
SpanishPhrases[Phrases.CARD] = "Carta";
SpanishPhrases[Phrases.DESCRIPTION] = "Descripción";
SpanishPhrases[Phrases.SCORE] = "Resultado";
SpanishPhrases[Phrases.TURNS] = "Turnos";
SpanishPhrases[Phrases.NOTHING] = "nada";
SpanishPhrases[Phrases.SPECTATE] = "Ver";
SpanishPhrases[Phrases.FRIENDS] = "Lista de amigos";
SpanishPhrases[Phrases.FOLLOWING] = "Siguiendo";
SpanishPhrases[Phrases.FOLLOWERS] = "Seguidores";
SpanishPhrases[Phrases.UNFRIEND] = "Quitar";
SpanishPhrases[Phrases.UNFOLLOW] = "dejar de seguir";
SpanishPhrases[Phrases.BEFRIEND] = "ofrecer amistad";
SpanishPhrases[Phrases.FRIENDS_EXPLANATION] = "Amigo mutuo";
SpanishPhrases[Phrases.FOLLOWING_EXPLANATION] = "Eres su amigo";
SpanishPhrases[Phrases.FOLLOWERS_EXPLANATION] = "Es tu amigo";
SpanishPhrases[Phrases.ADD] = "Añadir";
SpanishPhrases[Phrases.TYPE_FRIEND] = "Escribe el nombre del amigo";
SpanishPhrases[Phrases.UNLOCKS] = "desbloquea";
SpanishPhrases[Phrases.SELECT_YOUR_COUNTRY] = "Selecciona tu país";
SpanishPhrases[Phrases.SELECT_A_COUNTRY] = "Selecciona el país";
SpanishPhrases[Phrases.KICK_AND_RESIGN] = "Expulsarse y rendirse";
SpanishPhrases[Phrases.BETWEEN_TURNS] = "Entre Turnos";
SpanishPhrases[Phrases.PLAYER] = "Jugador";
SpanishPhrases[Phrases.RESIGNED] = "se rindió";
SpanishPhrases[Phrases.YOU_RESIGNED] = "Te has rendido";
SpanishPhrases[Phrases.YOU_LOSE] = "Has perdido";
SpanishPhrases[Phrases.YOU_TIE] = "Has empatado";
SpanishPhrases[Phrases.YOU_WIN] = "Has ganado";
SpanishPhrases[Phrases.BUYS_MANY] = "Compras";
SpanishPhrases[Phrases.ACTIONS_MANY] = "Acciones";
SpanishPhrases[Phrases.TOKEN] = "Cofre";
SpanishPhrases[Phrases.TOKENS] = "Cofres";
SpanishPhrases[Phrases.TOKENS_MANY] = "Cofres";
SpanishPhrases[Phrases.OVERPAY] = "Pagar de más";
SpanishPhrases[Phrases.BID] = "Apostar";
SpanishPhrases[Phrases.TAKE] = "Coger";
SpanishPhrases[Phrases.BOTTOM] = "Debajo";
SpanishPhrases[Phrases.TOP] = "Encima";
SpanishPhrases[Phrases.LOCATION] = "Posición";
SpanishPhrases[Phrases.CLEAN_UP] = "Mantenimiento";
SpanishPhrases[Phrases.PLAN_CLEAN_UP] = "Fase de mantenimiento";
SpanishPhrases[Phrases.EXECUTE] = "Ejecutar";
SpanishPhrases[Phrases.MOVEMENT_VERB] = "VERB.";
SpanishPhrases[Phrases.MOVEMENT_VERB_OBJECT] = "VERB OBJECT.";
SpanishPhrases[Phrases.FORUM] = "Foro";
SpanishPhrases[Phrases.VIEW_LOG] = "Log";
SpanishPhrases[Phrases.REPLAY_GAME] = "Repetir Partida";
SpanishPhrases[Phrases.REMOVE_REPLAY] = "No repetir";
SpanishPhrases[Phrases.SHELTERS] = "Cobijos";
SpanishPhrases[Phrases.NO_SHELTERS] = "Sin Cobijos";
SpanishPhrases[Phrases.COLONIES] = "Colonias";
SpanishPhrases[Phrases.NO_COLONIES] = "Sin Colonias";
SpanishPhrases[Phrases.PLAYER_ORDER] = "Orden de Jugadores";
SpanishPhrases[Phrases.ENTER_USERNAME] = "Por favor, introduce tu nombre de usuario.";
SpanishPhrases[Phrases.ENTER_LOGIN_CREDENTIALS] = "Por favor, introduce tus credenciales.";
SpanishPhrases[Phrases.SUBMIT] = "Enviar";
SpanishPhrases[Phrases.DONE] = "Hecho";
SpanishPhrases[Phrases.GAME_ID] = "Id de Partida";
SpanishPhrases[Phrases.DECISION_INDEX] = "Índice de decisión";
SpanishPhrases[Phrases.ORIGINAL_PLAYER] = "Jugador Original";
SpanishPhrases[Phrases.ORIGINAL_PLAYERS] = "jugadores originales";
SpanishPhrases[Phrases.ADD_BOT] = "Añadir Bot";
SpanishPhrases[Phrases.DECISION] = "Decisión";
SpanishPhrases[Phrases.LOAD_GAME] = "Cargar Partida";
SpanishPhrases[Phrases.CHANGE_REPLAY] = "Cambiar Repetición";
SpanishPhrases[Phrases.NAME_CARD] = "nombra una carta";
SpanishPhrases[Phrases.LOAD_OLD_GAME] = "Cargar Partida Antigua";
SpanishPhrases[Phrases.BOT_READY] = "Bot preparado";
SpanishPhrases[Phrases.CANCEL_RESIGN] = "Cancelar y Rendirse";
SpanishPhrases[Phrases.LOGOUT] = "Cerrar sesión";
SpanishPhrases[Phrases.FIRST] = "Primero";
SpanishPhrases[Phrases.LAST] = "Último";
SpanishPhrases[Phrases.INBOX] = "Buzón";
SpanishPhrases[Phrases.MESSAGES] = "Mensajes";
SpanishPhrases[Phrases.MARK_READ] = "Marcar Como Leído";
SpanishPhrases[Phrases.DELETE] = "Eliminar";
SpanishPhrases[Phrases.NO_MESSAGES] = "No hay mensajes";
SpanishPhrases[Phrases.LEADERBOARD] = "Clasificación";
SpanishPhrases[Phrases.LAST_UPDATED] = "Última actualización";
SpanishPhrases[Phrases.RANK] = "#";
SpanishPhrases[Phrases.NAME] = "Nombre";
SpanishPhrases[Phrases.RATING] = "Nivel";
SpanishPhrases[Phrases.TREND] = "";
SpanishPhrases[Phrases.VP] = "PV";
SpanishPhrases[Phrases.GAME_ID_SHOULD_BE_NUMBER] = "El id de la partida debe ser un número.";
SpanishPhrases[Phrases.FAQ] = "FAQ";
SpanishPhrases[Phrases.RESULTS] = "Resultados";
SpanishPhrases[Phrases.SKILL] = "μ";
SpanishPhrases[Phrases.SKILL_RANGE] = "Rango de nivel";
SpanishPhrases[Phrases.DEVIATION] = "φ";
SpanishPhrases[Phrases.VOLATILITY] = "σ";
SpanishPhrases[Phrases.GAMES] = "Partidas";
SpanishPhrases[Phrases.GLICKO2] = "Glicko-2";
SpanishPhrases[Phrases.IMPLEMENTATION] = "Implementación";
SpanishPhrases[Phrases.RATED_QUEUE] = "Usuarios en la cola de partidas puntuables";
SpanishPhrases[Phrases.UNRATED_QUEUE] = "Usuarios en la cola de partidas amistosas";
SpanishPhrases[Phrases.UNRATED_MATCH] = "Partida amistosa";
SpanishPhrases[Phrases.LEVEL] = "Nivel";
SpanishPhrases[Phrases.TWO_PLAYERS] = "2 Jugadores";
SpanishPhrases[Phrases.THREE_FOUR_PLAYERS] = "3-4 Jugadores";
SpanishPhrases[Phrases.GAME] = "Partida";
SpanishPhrases[Phrases.RATED] = "puntuable";
SpanishPhrases[Phrases.UNRATED] = "amistosa";
SpanishPhrases[Phrases.OTHER_CARDS] = "no familiares";
SpanishPhrases[Phrases.OTHER_CARD] = "no familiar";
SpanishPhrases[Phrases.YOUR_TURN] = "Tu turno!";
SpanishPhrases[Phrases.LOADING_GAME] = "Cargando partida...";
SpanishPhrases[Phrases.BLACKLIST] = "Lista negra";
SpanishPhrases[Phrases.REMOVE] = "Quitar";
SpanishPhrases[Phrases.BLACKLIST_USER] = "Añadir a la lista negra";
SpanishPhrases[Phrases.BEFRIEND_USER] = "Añadir como amigo";
SpanishPhrases[Phrases.BLACKLISTED] = "Añadido a la lista negra";
SpanishPhrases[Phrases.FRIENDLIST_EMPTY] = "Tu lista de amigos está vacía.";
SpanishPhrases[Phrases.BLACKLIST_EMPTY] = "Tu lista negra está vacía.";
SpanishPhrases[Phrases.PLAYERS_OF_TOTAL_PLAYERS] = "NUM_PLAYERS de NUM_TOTAL_PLAYERS";
SpanishPhrases[Phrases.SHOW_RUNNING_GAMES] = "Partidas en progreso";
SpanishPhrases[Phrases.SHOW_1P_GAMES] = "Partidas de 1 jugador";
SpanishPhrases[Phrases.SHOW_2P_GAMES] = "Partidas de 2 jugadores";
SpanishPhrases[Phrases.SHOW_3XP_GAMES] = "Partidas de 3+ jugadores";
SpanishPhrases[Phrases.SHOW_BOT_GAMES] = "Partidas contra Bots";
SpanishPhrases[Phrases.FIND_GAME] = "Emparejar";
SpanishPhrases[Phrases.FIND_BOT_GAME] = "Partida vs Bot";
SpanishPhrases[Phrases.START_SEARCH] = "Comenzar búsqueda";
SpanishPhrases[Phrases.CANCEL_SEARCH] = "Cancelar búsqueda";
SpanishPhrases[Phrases.SEARCHING_2P] = "Dos jugadores";
SpanishPhrases[Phrases.SEARCHING_3P] = "Tres jugadores";
SpanishPhrases[Phrases.SEARCHING_RATED] = "Partida puntuable";
SpanishPhrases[Phrases.SEARCHING_PRACTICE] = "Partida de práctica";
SpanishPhrases[Phrases.MAX] = "Max";
SpanishPhrases[Phrases.MIN] = "Min";
SpanishPhrases[Phrases.SEARCHING] = "buscando";
SpanishPhrases[Phrases.START_BOT_GAME_1] = "1 Bot";
SpanishPhrases[Phrases.START_BOT_GAME_2] = "2 Bots";
SpanishPhrases[Phrases.START_BOT_GAME_3] = "3 Bots";
SpanishPhrases[Phrases.NO_FRIENDS_ONLINE] = "No hay amigos conectados";
SpanishPhrases[Phrases.AUTOMATCH_FEEDBACK] = "Cola de emparejamientos";
SpanishPhrases[Phrases.NOBODY_ELSE_IN_QUEUE] = "Nadie más en la cola.";
SpanishPhrases[Phrases.CANCEL_SMALL] = "Cancelar";
SpanishPhrases[Phrases.MESSAGE_SPECTATORS] = "mensaje a espectadores";
SpanishPhrases[Phrases.MESSAGE_PLAYERS] = "mensaje a jugadores";
SpanishPhrases[Phrases.PLAYER_REPLACED] = "LEAVING_PLAYER fué sustituido por REPLACEMENT.";
SpanishPhrases[Phrases.RESIGN_PROMPT] = "¿Rendirse?";
SpanishPhrases[Phrases.RESIGN_MULTIPLE_OPPONENTS] = "Cuidado: Rendirse en una partida con múltiples rivales se considera poco deportivo.";
SpanishPhrases[Phrases.CONTINUE_WITH_BOTS] = "Continuar contra un Bot";
SpanishPhrases[Phrases.PLAYER_DISCONNECTED] = "PLAYER_NAME se desconectó.";
SpanishPhrases[Phrases.PLAYER_RECONNECTED] = "PLAYER_NAME ha vuelto a conectarse.";
SpanishPhrases[Phrases.VALIDATE_BONUS_CODE] = "Validar";
SpanishPhrases[Phrases.NEW_TO_DOMINION] = "Cómo jugar";
SpanishPhrases[Phrases.SEARCHING_PREVIEWS] = "Avance de Menagerie";
SpanishPhrases[Phrases.AUTOPLAY_CLEANUP] = "Decisiones automáticas de mantenimiento";
SpanishPhrases[Phrases.AUTOPLAY_INTERFACE] = "Decisiones automáticas por defecto";
SpanishPhrases[Phrases.AUTOPLAY_OTHER] = "Otras decisiones automáticas";
SpanishPhrases[Phrases.GAME_ENDED] = "La partida ha finalizado.";
SpanishPhrases[Phrases.OK] = "Ok";
SpanishPhrases[Phrases.HAS_RESIGNED] = "PLAYER_NAME se ha rendido.";
SpanishPhrases[Phrases.MOUSE_TOY] = "Carta apartada para Senda del Ratón";
// Intro page of Store tab in Lobby
SpanishPhrases[Phrases.STORE_WELCOME] = "Tienda de Dominion Online";
SpanishPhrases[Phrases.ONE_MONTH] = "1 mes";
SpanishPhrases[Phrases.TWO_MONTHS] = "2 meses";
SpanishPhrases[Phrases.THREE_MONTHS] = "3 meses";
SpanishPhrases[Phrases.SIX_MONTHS] = "6 meses";
SpanishPhrases[Phrases.NINE_MONTHS] = "9 meses";
SpanishPhrases[Phrases.FIFTEEN_MONTHS] = "15 meses";
SpanishPhrases[Phrases.EIGHTEEN_MONTHS] = "18 meses";
SpanishPhrases[Phrases.ONE_YEAR] = "1 año";
SpanishPhrases[Phrases.TWO_YEAR] = "2 años";
SpanishPhrases[Phrases.CORE_SUBSCRIPTION] = "Core subscription";
SpanishPhrases[Phrases.COMPLETE_SUBSCRIPTION] = "Complete subscription";
SpanishPhrases[Phrases.CORE_SUBSCRIPTION_PRICE] = "(€ 2.15 / month)";
SpanishPhrases[Phrases.CORE_SUBSCRIPTION_EXPLANATION] = "The simpler half of the cards";
SpanishPhrases[Phrases.COMPLETE_SUBSCRIPTION_PRICE] = "(€ 4.30 / month)";
SpanishPhrases[Phrases.COMPLETE_SUBSCRIPTION_EXPLANATION] = "All the cards";
SpanishPhrases[Phrases.CHOOSE_SUBSCRIPTION] = "Elige tu suscripción: ";
SpanishPhrases[Phrases.CHOOSE_DURATION] = "Elige la duración de la suscripción, comenzando por hoy.";
SpanishPhrases[Phrases.CONTINUE] = "Continuar";
SpanishPhrases[Phrases.ADD_LATEST_EXPANSION] = "¿Quieres añadir Menagerie a tu suscripción actual?";
SpanishPhrases[Phrases.INTRODUCTION_TO_STORE] = "Introducción a la tienda";
SpanishPhrases[Phrases.CHECK_YOUR_PURCHASE] = "Revisa los detalles de tu compra";
SpanishPhrases[Phrases.SELLER_INFO] = "Info del vendedor";
SpanishPhrases[Phrases.INVOICE_NEED_ANSWERS] = "Para ver la factura necesitas responder a todas las preguntas anteriores.";
SpanishPhrases[Phrases.BUYING_SUBSCRIPTION_TO] = "Comprando suscripción para";
SpanishPhrases[Phrases.BUYING_SUBSCRIPTION_TILL] = "Comprando suscripción hasta";
SpanishPhrases[Phrases.AMOUNT] = "Cantidad";
SpanishPhrases[Phrases.ITEM] = "Artículo";
SpanishPhrases[Phrases.YOUR_SUBSCRIPTION] = "Tu Suscripción ";
SpanishPhrases[Phrases.YOUR_ACCOUNT] = "Tu Cuenta ";
SpanishPhrases[Phrases.REDIRECTED_TO_PAYMENT_PROVIDER] = "Redirigiendo al proveedor de pago en una nueva pestaña del navegador...";
SpanishPhrases[Phrases.PAYMENT_REDIRECTION_FAILED] = "Si no has sido redirigido, asegúrate que tu navegador permite abrir nuevas pestañas.";
SpanishPhrases[Phrases.REDIRECT_FAILED_BUTTON] = "Haz click si falla la redirección";
SpanishPhrases[Phrases.REDIRECT_YOURSELF] = "Si no has sido redirigido automáticamente, haz click en";
SpanishPhrases[Phrases.THIS_LINK] = "este link";
SpanishPhrases[Phrases.ZERO_INVOICE_1] = "Tu compra seleccionada no añade nada a tu suscripción.";
SpanishPhrases[Phrases.ZERO_INVOICE_2] = "Por favor, selecciona una suscripción de mayor duración.";
SpanishPhrases[Phrases.SORRY_STORE_SERVICE_DOWN] = "El servicio de tienda no está disponible. Disculpe las molestias.";
SpanishPhrases[Phrases.CONNECTING_TO_STORE_SERVICE] = "Conectando...";
SpanishPhrases[Phrases.UPGRADE_DURATION] = "Hasta que la suscripción actual expire";
SpanishPhrases[Phrases.FOR_FREE] = "Gratis";
SpanishPhrases[Phrases.MAXED_OUT_SUBSCRIPTIONS] = "Tu suscripción tiene la máxima duración y contiene todas las expansiones";
SpanishPhrases[Phrases.IP_COUNTRY_MISMATCH] = "El país seleccionado no se corresponde con tu dirección IP";
SpanishPhrases[Phrases.EXPIRES_AT] = "suscripción termina";
SpanishPhrases[Phrases.TOOLTIP_RANDOM_SELECTION] = "Haz click para añadir una carta aleatoria de ARGUMENT0 a tu Reino.";
SpanishPhrases[Phrases.TOOLTIP_LANDSCAPE_SLOT] = "Este espacio puede ser ARGUMENT0. Haz click en cada imágen para cambiarlo. Quita todas las marcas para quitar el espacio.";
SpanishPhrases[Phrases.LANDSCAPE_UNUSED] = "nada";
SpanishPhrases[Phrases.TOOLTIP_LANDSCAPE_PLUS_SLOT] = "Crea un nuevo espacio para cartas apaisadas y luego selecciona que tipos pueden aparecer.";
SpanishPhrases[Phrases.LANDSCAPE] = "carta apaisada";

SpanishPhrases[Phrases.PREVIEW_BOT_MATCH] = "Avance vs Bot";

SpanishPhrases[Phrases.TOOLTIP_USE_COFFERS] = "Click para gastar 1 Cofre";
SpanishPhrases[Phrases.TOOLTIP_CANT_USE_COFFERS] = "Solo puedes gastar Cofres en tu fase de Compra, antes de iniciar las compras";
SpanishPhrases[Phrases.TOOLTIP_USE_VILLAGER] = "Click para usar un Aldeano";
SpanishPhrases[Phrases.TOOLTIP_CANT_USE_VILLAGER] = "Solo puedes usar Aldeanos en tu fase de acción";
//SpanishPhrases[Phrases.CARDS_BANNED_BY_PLAYERS]        = "Cartas vetadas por los jugadores: ";
SpanishPhrases[Phrases.AUTOCOMPLETE_CARD_LISTS] = "Introduce cartas a añadir";
SpanishPhrases[Phrases.RESPECTED_CARD_LISTS] = "Cartas respetadas";
SpanishPhrases[Phrases.BANNED_CARDS] = "vetadas";
SpanishPhrases[Phrases.LIKED_CARDS] = "favoritas";
SpanishPhrases[Phrases.DISLIKED_CARDS] = "rechazadas";
SpanishPhrases[Phrases.KINGDOM_GENERATOR_PERCENTAGES] = "Reino generado con estos porcentajes relativos:";
SpanishPhrases[Phrases.VILLAGERS] = "Aldeanos";
SpanishPhrases[Phrases.DAYS] = "días";
SpanishPhrases[Phrases.SPECIAL_KINGDOM_RULES] = "Reglas especiales de Reino:";
SpanishPhrases[Phrases.SHOW_CHAT] = "Mostrar Chat";
SpanishPhrases[Phrases.HIDE_CHAT] = "Ocultar Chat";
SpanishPhrases[Phrases.UNBLACKLIST] = "Quitar de la Lista Negra";
SpanishPhrases[Phrases.INHERITANCE_INSTRUCTIONS] = "Juega la carta con//la ficha de Finca,//sin moverla.";
// TRANSLATE from here until end of file
SpanishPhrases[Phrases.TOOLTIP_OWNED_ENABLED] = "Click to disable this expansion.<br/>When disabled, you won't bring it to your games.";
SpanishPhrases[Phrases.TOOLTIP_OWNED_DISABLED] = "Click to enable this expansion.<br/>When enabled, you will bring it to your games";
SpanishPhrases[Phrases.TOOLTIP_VOID_ENABLED] = "Click to disable this expansion.<br/>Disabling has no effect if you're not subscribed.";
SpanishPhrases[Phrases.TOOLTIP_VOID_DISABLED] = "Click to enable this expansion.<br/>Enabling has no effect if you're not subscribed.";
SpanishPhrases[Phrases.TOOLTIP_BASE_SET] = "You can't disable the base set.";
SpanishPhrases[Phrases.CHOOSE_CARD_POOL] = "Choose Card Pool";
SpanishPhrases[Phrases.USE_LEVEL] = "Use level";
SpanishPhrases[Phrases.VIEW_INTRODUCTION_CARD_SELECTION] = "View introduction to card selection";
SpanishPhrases[Phrases.VIEW_CARD_POOL_LEVELS] = "View Card Pool levels";
SpanishPhrases[Phrases.CARD_POOL_BOX_HEADER] = "Card Pool";
SpanishPhrases[Phrases.CARD_POOL_LEVEL] = "Level";
SpanishPhrases[Phrases.MIN_CARD_POOL_LEVEL] = "Min Level";
SpanishPhrases[Phrases.CARD_POOL_LEVEL_SMALL] = "level";
SpanishPhrases[Phrases.USED_CARD_POOL_LEVEL] = "Card Pool: level ";
SpanishPhrases[Phrases.SHOW_LEVEL] = "Card Pool: ";
SpanishPhrases[Phrases.SUBSCRIPTION_YES] = "Thank you for subscribing.";
SpanishPhrases[Phrases.SUBSCRIPTION_NO] = "You don't have a subscription.";
SpanishPhrases[Phrases.SUBSCRIPTION_FOR] = "Your subscription lasts another";
SpanishPhrases[Phrases.EMPTY_BECAUSE_DISABLED] = "You can't select cards from this expansion because you have it disabled in the Account tab.";
SpanishPhrases[Phrases.EMPTY_BECAUSE_NOT_SUBSCRIBED] = "You can't select cards from this expansion because you don't have a subscription to it.";
SpanishPhrases[Phrases.EXPLAIN_SUBSCRIPTIONS] = "\nThis site allows you to play with the base set for free. \nIf you want to play with the expansions or want to support the site, please consider subscribing. \nYou can also try to find an opponent that provides the cards. If you want to wait for such an opponent in the queue, check the \"Wait for Expansions\"-checkbox.\n";
SpanishPhrases[Phrases.CARD_SELECTION_INTRODUCTION_TEXT] = ["<span class=\"game-concept\">Automatch</span> offers three different ways to limit the cards you play with.\n    Alternatively, you can create a <span class=\"game-concept\">New Table</span> and find your own opponents, allowing you to manually select everything.", "<div class=\"header\">Card Pool</div>\n        If you're in the process of learning the cards, this is probably what you want to use. \n        We've created 10 different levels, starting with the base set. \n        You are completely free to choose your own progression here. \n        You can read about the new mechanics on each level beforehand. \n        <span class=\"game-concept\">Card Pool</span> should be set to the level you're comfortable with.\n        <span class=\"game-concept\">Pool range</span> is something you could set to zero, meaning you always wait for a game at exactly the desired level. \n        You can also set it higher if you're willing to help out others or just want a faster search.", "<div class=\"header\">Expansion Selection</div>\n        Subscribers to the site can select which expansions they bring along to their games, either by clicking <span class=\"game-concept\">Manage my expansions</span> or using the top-menu <span class=\"game-concept\">Account</span>.\n        <span class=\"game-concept\">Wait for expansions</span> is a feature for non-subscribers. It allows you to wait in the queue until a subscriber wants to play with you.", "<div class=\"header\">Cardlists</div>\n        The top-menu <span class=\"game-concept\">Cardlists</span> allows you to create small personal lists of cards. Cards you ban will never appear in your games, and cards you dislike will be selected 50% less."];

SpanishPhrases[Phrases.LEVEL_DESCRIPTIONS] = ["Base only", "Simple cards", "Duration, Special Treasures, Platinum & Colony", "When-gain, When-trash, VP tokens", "Coffers, Villagers, Exile, This turn", "While-in-play, When discard, Mats", "Events, Reserve, Adventures Tokens", "Projects, Landmarks, Night, Shelters, Horses", "Extra cards, Artifacts, Boons, Hexes, Heirlooms", "All cards"];
SpanishPhrases[Phrases.NEW_CARDS] = "New Cards";
SpanishPhrases[Phrases.FAVOR] = "Favor";
SpanishPhrases[Phrases.FAVORS] = "Favors";
SpanishPhrases[Phrases.FAVORS_MANY] = "Favors";
SpanishPhrases[Phrases.INDIVIDUAL_EXPANSIONS] = "Individual Expansions";
SpanishPhrases[Phrases.WARNING_SEARCHING_SUBSCRIBER] = "Searching for an opponent with a Subscription";
SpanishPhrases[Phrases.WARNING_MAX_RATING_LOW] = "Max rating is set quite low";
SpanishPhrases[Phrases.WARNING_MIN_RATING_HIGH] = "Min rating is set quite high";
SpanishPhrases[Phrases.SEARCHING_2E_PREVIEWS] = "2e Previews";
SpanishPhrases[Phrases.WARNING_ERRATA] = "Note: this game has some cards with recent errata:";
SpanishPhrases[Phrases.DONT_SHOW_THIS_MESSAGE_AGAIN] = "Don't show this message again";
SpanishPhrases[Phrases.REPLAY_LAST_KINGDOM] = "Replay Last Kingdom"; // please translate

// Missing lines as of 09.01.2023 18:45:08
SpanishPhrases[Phrases.QUICK_UNDO] = "Undo 1";
SpanishPhrases[Phrases.UNDO_BUTTON] = "Rewind";
SpanishPhrases[Phrases.BOT] = "Bot";
SpanishPhrases[Phrases.AUTOMATCH_LABEL_CARD_POOL_RANGE] = "Pool range";
SpanishPhrases[Phrases.AUTOMATCH_LABEL_EXPANSIONS] = "Expansions";
SpanishPhrases[Phrases.AUTOMATCH_LABEL_RATING] = "Rating";
SpanishPhrases[Phrases.BASE_ONLY] = "Base";
SpanishPhrases[Phrases.SIMPLE] = "Simple";
SpanishPhrases[Phrases.NO_PONG_RECEIVED] = "Warning: possibly lost connection with gameserver.";
SpanishPhrases[Phrases.MESSAGE_INDEX_MISMATCH] = "Warning: unreliable connection to gameserver.";
SpanishPhrases[Phrases.RECONNECTING_FAILED_MESSAGE] = "You've reconnected to this game COUNT times.";
SpanishPhrases[Phrases.TRY_AGAIN] = "Reconnect again";
SpanishPhrases[Phrases.RETURN_TO_LOBBY] = "Return to lobby";
SpanishPhrases[Phrases.ALL] = "All";
SpanishPhrases[Phrases.TIMED_OUT] = "Timed out";
SpanishPhrases[Phrases.RECONNECT] = "Reconnect";