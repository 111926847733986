"use strict";
// Use like <question-select-buttons ng-model="ctrl.question"> </question-select-buttons> in HTML
//

webclient.component("questionSelectButtons", {

    bindings: {

        question: "=ngModel"
    },

    controllerAs: "ctrl",

    controller: [function () {

        var ctrl = this;

        ctrl.phrases = PHRASES;
        ctrl.paragraphs = PARAGRAPHS;
        ctrl.info = ctrl.question.htmlKnowledge || {};
        ctrl.current = function () {
            return ctrl.question.isAnswered() ? ctrl.question.answer : '-';
        };
        ctrl.validChoice = function (answerLabel) {
            return ctrl.info.validChoice(ctrl.question, answerLabel);
        };

        ctrl.buttonClicked = function (event, answer) {

            event.stopPropagation();
            ctrl.question.setAnswer(answer);
        };
    }],

    templateUrl: 'html/related-questions/select-buttons-1.9.2.2.html'
});