"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AudioPlayer = function () {
    function AudioPlayer() {
        _classCallCheck(this, AudioPlayer);
    }

    _createClass(AudioPlayer, null, [{
        key: "loadSounds",
        value: function loadSounds() {
            var soundNames = getValues(SOUNDS).map(function (s) {
                return s.name;
            });
            var soundArray = soundNames.map(function (s) {
                return { name: s };
            });
            ion.sound({
                sounds: soundArray,
                volume: 0.5,
                path: "sounds/",
                preload: true,
                multiplay: true
            });
        }
    }, {
        key: "play",
        value: function play(sound) {
            ion.sound.play(sound.name);
        }
    }]);

    return AudioPlayer;
}();