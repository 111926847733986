"use strict";

var SpanishCardNames = {};

SpanishCardNames[CardNames.BACK] = new TranslatedCardName("carta", "cartas", "una carta");
SpanishCardNames[CardNames.CURSE] = new TranslatedCardName("Maldición", "Maldiciones", "una Malidición");
SpanishCardNames[CardNames.COPPER] = new TranslatedCardName("Cobre", "Cobres", "un Cobre");
SpanishCardNames[CardNames.SILVER] = new TranslatedCardName("Plata", "Platas", "una Plata");
SpanishCardNames[CardNames.GOLD] = new TranslatedCardName("Oro", "Oros", "un Oro");
SpanishCardNames[CardNames.ESTATE] = new TranslatedCardName("Finca", "Fincas", "una Finca");
SpanishCardNames[CardNames.DUCHY] = new TranslatedCardName("Ducado", "Ducados", "un Ducado");
SpanishCardNames[CardNames.PROVINCE] = new TranslatedCardName("Provincia", "Provincias", "una Provincia");
SpanishCardNames[CardNames.ARTISAN] = new TranslatedCardName("Artesana", "Artesanas", "una Artesana");
SpanishCardNames[CardNames.BANDIT] = new TranslatedCardName("Bandida", "Bandidas", "una Bandida");
SpanishCardNames[CardNames.BUREAUCRAT] = new TranslatedCardName("Burócrata", "Burócratas", "un Burócrata");
SpanishCardNames[CardNames.CELLAR] = new TranslatedCardName("Sótano", "Sótanos", "un Sótano");
SpanishCardNames[CardNames.CHAPEL] = new TranslatedCardName("Capilla", "Capillas", "una Capilla");
SpanishCardNames[CardNames.COUNCIL_ROOM] = new TranslatedCardName("Sala del Consejo", "Salas del Consejo", "una Sala del Consejo");
SpanishCardNames[CardNames.FESTIVAL] = new TranslatedCardName("Festival", "Festivales", "un Festival");
SpanishCardNames[CardNames.GARDENS] = new TranslatedCardName("Jardines", "Jardines", "unos Jardines");
SpanishCardNames[CardNames.HARBINGER] = new TranslatedCardName("Emisario", "Emisarios", "un Emisario");
SpanishCardNames[CardNames.LABORATORY] = new TranslatedCardName("Laboratorio", "Laboratorios", "un Laboratorio");
SpanishCardNames[CardNames.LIBRARY] = new TranslatedCardName("Biblioteca", "Bibliotecas", "una Biblioteca");
SpanishCardNames[CardNames.MARKET] = new TranslatedCardName("Mercado", "Mercados", "un Mercado");
SpanishCardNames[CardNames.MERCHANT] = new TranslatedCardName("Tendera", "Tenderas", "una Tendera");
SpanishCardNames[CardNames.MILITIA] = new TranslatedCardName("Milicia", "Milicias", "una Milicia");
SpanishCardNames[CardNames.MINE] = new TranslatedCardName("Mina", "Minas", "una Mina");
SpanishCardNames[CardNames.MOAT] = new TranslatedCardName("Foso", "Fosos", "un Foso");
SpanishCardNames[CardNames.MONEYLENDER] = new TranslatedCardName("Prestamista", "Prestamistas", "un Prestamista");
SpanishCardNames[CardNames.POACHER] = new TranslatedCardName("Cazadora Furtiva", "Cazadoras Furtivas", "una Cazadora Furtiva");
SpanishCardNames[CardNames.REMODEL] = new TranslatedCardName("Remodelar", "Remodelar", "un Remodelar");
SpanishCardNames[CardNames.SENTRY] = new TranslatedCardName("Centinela", "Centinelas", "un Centinela");
SpanishCardNames[CardNames.SMITHY] = new TranslatedCardName("Herrería", "Herrerías", "una Herrería");
SpanishCardNames[CardNames.THRONE_ROOM] = new TranslatedCardName("Salón del Trono", "Salones del Trono", "un Salón del Trono");
SpanishCardNames[CardNames.VASSAL] = new TranslatedCardName("Vasallo", "Vasallos", "un Vasallo");
SpanishCardNames[CardNames.VILLAGE] = new TranslatedCardName("Aldea", "Aldeas", "una Aldea");
SpanishCardNames[CardNames.WITCH] = new TranslatedCardName("Bruja", "Brujas", "una Bruja");
SpanishCardNames[CardNames.WORKSHOP] = new TranslatedCardName("Taller", "Talleres", "un Taller");

SpanishCardNames[CardNames.COURTYARD] = new TranslatedCardName("Patio", "Patios", "un Patio");
SpanishCardNames[CardNames.CONSPIRATOR] = new TranslatedCardName("Conspirador", "Conspiradores", "un Conspirador");
SpanishCardNames[CardNames.COURTIER] = new TranslatedCardName("Cortesano", "Cortesanos", "un Cortesano");
SpanishCardNames[CardNames.BARON] = new TranslatedCardName("Barón", "Barones", "un Barón");
SpanishCardNames[CardNames.BRIDGE] = new TranslatedCardName("Puente", "Puentes", "un Puente");
SpanishCardNames[CardNames.DIPLOMAT] = new TranslatedCardName("Diplomática", "Diplomáticas", "un Diplomática");
SpanishCardNames[CardNames.DUKE] = new TranslatedCardName("Duque", "Duques", "un Duque");
SpanishCardNames[CardNames.FARM] = new TranslatedCardName("Granja", "Granjas", "una Granja");
SpanishCardNames[CardNames.NOBLES] = new TranslatedCardName("Nobles", "Nobles", "unos Nobles");
SpanishCardNames[CardNames.IRONWORKS] = new TranslatedCardName("Fundición", "Fundiciones", "una Fundición");
SpanishCardNames[CardNames.LURKER] = new TranslatedCardName("Acechador", "Acechadores", "un Acechador");
SpanishCardNames[CardNames.MASQUERADE] = new TranslatedCardName("Mascarada", "Mascaradas", "una Mascarada");
SpanishCardNames[CardNames.MILL] = new TranslatedCardName("Molino", "Molinos", "un Molino");
SpanishCardNames[CardNames.MINING_VILLAGE] = new TranslatedCardName("Aldea Minera", "Aldeas Mineras", "una Aldea Minera");
SpanishCardNames[CardNames.MINION] = new TranslatedCardName("Esbirro", "Esbirros", "un Esbirro");
SpanishCardNames[CardNames.PATROL] = new TranslatedCardName("Patrulla", "Patrullas", "una Patrulla");
SpanishCardNames[CardNames.PAWN] = new TranslatedCardName("Peón", "Peones", "un Peón");
SpanishCardNames[CardNames.REPLACE] = new TranslatedCardName("Reemplazar", "Reemplazar", "un Reemplazar");
SpanishCardNames[CardNames.SECRET_PASSAGE] = new TranslatedCardName("Pasadizo Secreto", "Pasadizos Secretos", "un Pasadizo Secreto");
SpanishCardNames[CardNames.SHANTY_TOWN] = new TranslatedCardName("Chabolas", "Chabolas", "unas Chabola");
SpanishCardNames[CardNames.STEWARD] = new TranslatedCardName("Mayordomo", "Mayordomos", "un Mayordomo");
SpanishCardNames[CardNames.SWINDLER] = new TranslatedCardName("Embaucador", "Embaucadores", "un Embaucador");
SpanishCardNames[CardNames.TORTURER] = new TranslatedCardName("Torturador", "Torturadores", "un Torturador");
SpanishCardNames[CardNames.TRADING_POST] = new TranslatedCardName("Puesto Comercial", "Puestos Comerciales", "un Puesto Comercial");
SpanishCardNames[CardNames.UPGRADE] = new TranslatedCardName("Mejora", "Mejoras", "una Mejora");
SpanishCardNames[CardNames.WISHING_WELL] = new TranslatedCardName("Pozo de los Deseos", "Pozos de los Deseos", "un Pozo de los Deseos");

SpanishCardNames[CardNames.AMBASSADOR] = new TranslatedCardName("Embajador", "Embajadores", "un Embajador");
SpanishCardNames[CardNames.BAZAAR] = new TranslatedCardName("Bazar", "Bazares", "un Bazar");
SpanishCardNames[CardNames.CARAVAN] = new TranslatedCardName("Caravana", "Caravanas", "una Caravana");
SpanishCardNames[CardNames.CUTPURSE] = new TranslatedCardName("Ratero", "Rateros", "un Ratero");
SpanishCardNames[CardNames.EMBARGO] = new TranslatedCardName("Embargo", "Embargos", "un Embargo");
SpanishCardNames[CardNames.EXPLORER] = new TranslatedCardName("Exploradora", "Exploradoras", "una Exploradora");
SpanishCardNames[CardNames.FISHING_VILLAGE] = new TranslatedCardName("Aldea de Pescadores", "Aldeas de Pescadores", "una Aldea de Pescadores");
SpanishCardNames[CardNames.GHOST_SHIP] = new TranslatedCardName("Barco Fantasma", "Barcos Fantasma", "un Barco Fantasma");
SpanishCardNames[CardNames.HAVEN] = new TranslatedCardName("Puerto", "Puertos", "un Puerto");
SpanishCardNames[CardNames.ISLAND] = new TranslatedCardName("Isla", "Islas", "una Isla");
SpanishCardNames[CardNames.LIGHTHOUSE] = new TranslatedCardName("Faro", "Faros", "un Faro");
SpanishCardNames[CardNames.LOOKOUT] = new TranslatedCardName("Vigía", "Vigías", "un Vigía");
SpanishCardNames[CardNames.MERCHANT_SHIP] = new TranslatedCardName("Barco Mercante", "Barcos Mercantes", "un Barco Mercante");
SpanishCardNames[CardNames.NATIVE_VILLAGE] = new TranslatedCardName("Aldea Indígena", "Aldeas Indígenas", "una Aldea Indígena");
SpanishCardNames[CardNames.NAVIGATOR] = new TranslatedCardName("Navegante", "Navegantes", "un Navegante");
SpanishCardNames[CardNames.OUTPOST] = new TranslatedCardName("Puesto Avanzado", "Puestos Avanzados", "un Puesto Avanzado");
SpanishCardNames[CardNames.PEARL_DIVER] = new TranslatedCardName("Pescador de Perlas", "Pescadores de Perlas", "un Pescador de Perlas");
SpanishCardNames[CardNames.PIRATE_SHIP] = new TranslatedCardName("Barco Pirata", "Barcos Piratas", "un Barco Pirata");
SpanishCardNames[CardNames.SALVAGER] = new TranslatedCardName("Rescatador", "Rescatadores", "un Rescatador");
SpanishCardNames[CardNames.SEA_HAG] = new TranslatedCardName("Bruja del Mar", "Brujas del Mar", "una Bruja del Mar");
SpanishCardNames[CardNames.SMUGGLERS] = new TranslatedCardName("Contrabandistas", "Contrabandistas", "unos Contrabandistas");
SpanishCardNames[CardNames.TACTICIAN] = new TranslatedCardName("Estratega", "Estrategas", "un Estratega");
SpanishCardNames[CardNames.TREASURE_MAP] = new TranslatedCardName("Mapa del Tesoro", "Mapas del Tesoro", "un Mapa del Tesoro");
SpanishCardNames[CardNames.TREASURY] = new TranslatedCardName("Tesorería", "Tesorerías", "una Tesorería");
SpanishCardNames[CardNames.WAREHOUSE] = new TranslatedCardName("Almacén", "Almacenes", "un Almacén");
SpanishCardNames[CardNames.WHARF] = new TranslatedCardName("Muelle", "Muelles", "un Muelle");

SpanishCardNames[CardNames.ALCHEMIST] = new TranslatedCardName("Alquimista", "Alquimistas", "un Alquimista");
SpanishCardNames[CardNames.APOTHECARY] = new TranslatedCardName("Boticario", "Boticarios", "un Boticario");
SpanishCardNames[CardNames.APPRENTICE] = new TranslatedCardName("Aprendiz", "Aprendices", "un Aprendiz");
SpanishCardNames[CardNames.FAMILIAR] = new TranslatedCardName("Familiar", "Familiares", "un Familiar");
SpanishCardNames[CardNames.GOLEM] = new TranslatedCardName("Gólem", "Gólems", "un Gólem");
SpanishCardNames[CardNames.HERBALIST] = new TranslatedCardName("Herbalista", "Herbalistas", "un Herbalista");
SpanishCardNames[CardNames.PHILOSOPHERS_STONE] = new TranslatedCardName("Piedra Filosofal", "Piedras Filosofales", "una Piedra Filosofal");
SpanishCardNames[CardNames.POSSESSION] = new TranslatedCardName("Posesión", "Posesiones", "una Posesión");
SpanishCardNames[CardNames.POTION] = new TranslatedCardName("Poción", "Pociones", "una Poción");
SpanishCardNames[CardNames.SCRYING_POOL] = new TranslatedCardName("Hidromancia", "Hidromancias", "una Hidromancia");
SpanishCardNames[CardNames.TRANSMUTE] = new TranslatedCardName("Transmutar", "Transmutar", "un Transmutar");
SpanishCardNames[CardNames.UNIVERSITY] = new TranslatedCardName("Universidad", "Universidades", "una Universidad");
SpanishCardNames[CardNames.VINEYARD] = new TranslatedCardName("Viñedo", "Viñedos", "un Viñedo");

SpanishCardNames[CardNames.BANK] = new TranslatedCardName("Banco", "Bancos", "un Banco");
SpanishCardNames[CardNames.BISHOP] = new TranslatedCardName("Obispo", "Obispos", "un Obispo");
SpanishCardNames[CardNames.COLONY] = new TranslatedCardName("Colonia", "Colonias", "una Colonia");
SpanishCardNames[CardNames.CONTRABAND] = new TranslatedCardName("Contrabando", "Contrabandos", "un Contrabando");
SpanishCardNames[CardNames.COUNTING_HOUSE] = new TranslatedCardName("Contaduría", "Contadurías", "una Contaduría");
SpanishCardNames[CardNames.CITY] = new TranslatedCardName("Ciudad", "Ciudades", "una Ciudad");
SpanishCardNames[CardNames.EXPAND] = new TranslatedCardName("Expansión", "Expansiones", "una Expansión");
SpanishCardNames[CardNames.FORGE] = new TranslatedCardName("Forja", "Forjas", "una Forja");
SpanishCardNames[CardNames.GRAND_MARKET] = new TranslatedCardName("Gran Mercado", "Grandes Mercados", "un Gran Mercado");
SpanishCardNames[CardNames.GOONS] = new TranslatedCardName("Matones", "Matones", "unos Matones");
SpanishCardNames[CardNames.HOARD] = new TranslatedCardName("Tesoro Oculto", "Tesoros Ocultos", "un Tesoro Oculto");
SpanishCardNames[CardNames.KINGS_COURT] = new TranslatedCardName("Corte Real", "Cortes Reales", "una Corte Real");
SpanishCardNames[CardNames.LOAN] = new TranslatedCardName("Préstamo", "Préstamos", "un Préstamo");
SpanishCardNames[CardNames.MINT] = new TranslatedCardName("Acuñador", "Acuñadores", "un Acuñador");
SpanishCardNames[CardNames.MONUMENT] = new TranslatedCardName("Monumento", "Monumentos", "un Monumento");
SpanishCardNames[CardNames.MOUNTEBANK] = new TranslatedCardName("Charlatán", "Charlatanes", "un Charlatán");
SpanishCardNames[CardNames.PEDDLER] = new TranslatedCardName("Buhonera", "Buhoneras", "una Buhonera");
SpanishCardNames[CardNames.PLATINUM] = new TranslatedCardName("Platino", "Platinos", "un Platino");
SpanishCardNames[CardNames.QUARRY] = new TranslatedCardName("Cantera", "Canteras", "una Cantera");
SpanishCardNames[CardNames.RABBLE] = new TranslatedCardName("Plebe", "Chusmas", "una Chusma");
SpanishCardNames[CardNames.ROYAL_SEAL] = new TranslatedCardName("Sello Real", "Sellos Reales", "un Sello Real");
SpanishCardNames[CardNames.TALISMAN] = new TranslatedCardName("Talismán", "Talismanes", "un Talismán");
SpanishCardNames[CardNames.TRADE_ROUTE] = new TranslatedCardName("Ruta Comercial", "Rutas Comerciales", "una Ruta Comercial");
SpanishCardNames[CardNames.VAULT] = new TranslatedCardName("Cámara Acorazada", "Cámaras Acorazadas", "una Cámara Acorazada");
SpanishCardNames[CardNames.VENTURE] = new TranslatedCardName("Especulación", "Especulaciones", "una Especulación");
SpanishCardNames[CardNames.WATCHTOWER] = new TranslatedCardName("Atalaya", "Atalayas", "una Atalaya");
SpanishCardNames[CardNames.WORKERS_VILLAGE] = new TranslatedCardName("Aldea Obrera", "Aldeas Obreras", "una Aldea Obrera");

SpanishCardNames[CardNames.PRIZE_PILE] = new TranslatedCardName("Premio", "Premio", "Premio");
SpanishCardNames[CardNames.BAG_OF_GOLD] = new TranslatedCardName("Bolsa de Oro", "Bolsas de Oro", "una Bolsa de Oro");
SpanishCardNames[CardNames.DIADEM] = new TranslatedCardName("Diadema", "Diademas", "una Diadema");
SpanishCardNames[CardNames.FAIRGROUNDS] = new TranslatedCardName("Recinto Ferial", "Recintos Feriales", "un Recinto Ferial");
SpanishCardNames[CardNames.FARMING_VILLAGE] = new TranslatedCardName("Poblado Agrícola", "Poblados Agrícolas", "un Poblado Agrícola");
SpanishCardNames[CardNames.FOLLOWERS] = new TranslatedCardName("Seguidores", "Seguidores", "unos Seguidores");
SpanishCardNames[CardNames.FORTUNE_TELLER] = new TranslatedCardName("Adivinadora", "Adivinadoras", "una Adivinadora");
SpanishCardNames[CardNames.HAMLET] = new TranslatedCardName("Caserío", "Caseríos", "un Caserío");
SpanishCardNames[CardNames.HARVEST] = new TranslatedCardName("Cosecha", "Cosechas", "una Cosecha");
SpanishCardNames[CardNames.HORSE_TRADERS] = new TranslatedCardName("Tratantes de Caballos", "Tratantes de Caballos", "unos Tratantes de Caballos");
SpanishCardNames[CardNames.HORN_OF_PLENTY] = new TranslatedCardName("Cornucopia", "Cornucopias", "una Cornucopia");
SpanishCardNames[CardNames.HUNTING_PARTY] = new TranslatedCardName("Cacería", "Cacerías", "una Cacería");
SpanishCardNames[CardNames.JESTER] = new TranslatedCardName("Bufón", "Bufones", "un Bufón");
SpanishCardNames[CardNames.MENAGERIE] = new TranslatedCardName("Casa de Fieras", "Casas de Fieras", "una Casa de Fieras");
SpanishCardNames[CardNames.PRINCESS] = new TranslatedCardName("Princesa", "Princesas", "una Princesa");
SpanishCardNames[CardNames.REMAKE] = new TranslatedCardName("Reconstrucción", "Reconstrucciones", "una Reconstrucción");
SpanishCardNames[CardNames.TOURNAMENT] = new TranslatedCardName("Torneo", "Torneos", "un Torneo");
SpanishCardNames[CardNames.TRUSTY_STEED] = new TranslatedCardName("Corcel Fiel", "Corceles Fiels", "un Corcel Fiel");
SpanishCardNames[CardNames.YOUNG_WITCH] = new TranslatedCardName("Bruja Joven", "Brujas Jóvenes", "una Bruja Joven");

SpanishCardNames[CardNames.BORDER_VILLAGE] = new TranslatedCardName("Pueblo Fronterizo", "Pueblos Fronterizos", "un Pueblo Fronterizo");
SpanishCardNames[CardNames.CACHE] = new TranslatedCardName("Hallazgo", "Hallazgos", "un Hallazgo");
SpanishCardNames[CardNames.CARTOGRAPHER] = new TranslatedCardName("Cartógrafo", "Cartógrafos", "un Cartógrafo");
SpanishCardNames[CardNames.CROSSROADS] = new TranslatedCardName("Encrucijada", "Encrucijadas", "una Encrucijada");
SpanishCardNames[CardNames.DEVELOP] = new TranslatedCardName("Desarrollo", "Desarrollos", "un Desarrollo");
SpanishCardNames[CardNames.DUCHESS] = new TranslatedCardName("Duquesa", "Duquesas", "una Duquesa");
SpanishCardNames[CardNames.EMBASSY] = new TranslatedCardName("Embajada", "Embajadas", "una Embajada");
SpanishCardNames[CardNames.FARMLAND] = new TranslatedCardName("Tierra de Labranza", "Tierras de Labranza", "una Tierra de Labranza");
SpanishCardNames[CardNames.FOOLS_GOLD] = new TranslatedCardName("Oro Falso", "Oros Falsos", "un Oro Falso");
SpanishCardNames[CardNames.HAGGLER] = new TranslatedCardName("Regateador", "Regateadores", "un Regateador");
SpanishCardNames[CardNames.HIGHWAY] = new TranslatedCardName("Carretera", "Carreteras", "una Carretera");
SpanishCardNames[CardNames.ILL_GOTTEN_GAINS] = new TranslatedCardName("Dinero Sucio", "Dinero Sucio", "un Dinero Sucio");
SpanishCardNames[CardNames.INN] = new TranslatedCardName("Posada", "Posadas", "una Posada");
SpanishCardNames[CardNames.JACK_OF_ALL_TRADES] = new TranslatedCardName("Chico Para Todo", "Chicos Para Todo", "un Chico Para Todo");
SpanishCardNames[CardNames.MANDARIN] = new TranslatedCardName("Mandarín", "Mandarines", "un Mandarín");
SpanishCardNames[CardNames.NOBLE_BRIGAND] = new TranslatedCardName("Bandido Honrado", "Bandidos Honrados", "un Bandido Honrado");
SpanishCardNames[CardNames.NOMAD_CAMP] = new TranslatedCardName("Campamento Nómada", "Campamentos Nómadas", "un Campamento Nómada");
SpanishCardNames[CardNames.OASIS] = new TranslatedCardName("Oasis", "Oasis", "un Oasis");
SpanishCardNames[CardNames.ORACLE] = new TranslatedCardName("Oráculo", "Oráculos", "un Oráculo");
SpanishCardNames[CardNames.MARGRAVE] = new TranslatedCardName("Margrave", "Margraves", "un Margrave");
SpanishCardNames[CardNames.SCHEME] = new TranslatedCardName("Confabulación", "Confabulaciones", "una Confabulación");
SpanishCardNames[CardNames.SILK_ROAD] = new TranslatedCardName("Ruta de la Seda", "Rutas de la Seda", "una Ruta de la Seda");
SpanishCardNames[CardNames.SPICE_MERCHANT] = new TranslatedCardName("Mercader de Especias", "Mercaderes de Especias", "un Mercader de Especias");
SpanishCardNames[CardNames.STABLES] = new TranslatedCardName("Caballerizas", "Caballerizas", "unas Caballerizas");
SpanishCardNames[CardNames.TRADER] = new TranslatedCardName("Comerciante", "Comerciantes", "un Comerciante");
SpanishCardNames[CardNames.TUNNEL] = new TranslatedCardName("Túnel", "Túneles", "un Túnel");

SpanishCardNames[CardNames.RUIN_PILE] = new TranslatedCardName("Ruinas", "Ruinas", "unas Ruinas");
SpanishCardNames[CardNames.KNIGHTS] = new TranslatedCardName("Caballeros", "Caballeros", "unos Caballeros");
SpanishCardNames[CardNames.ABANDONED_MINE] = new TranslatedCardName("Mina Abandonada", "Minas Abandonadas", "una Mina Abandonada");
SpanishCardNames[CardNames.ALTAR] = new TranslatedCardName("Altar", "Altares", "un Altar");
SpanishCardNames[CardNames.ARMORY] = new TranslatedCardName("Armería", "Armerías", "una Armería");
SpanishCardNames[CardNames.BAND_OF_MISFITS] = new TranslatedCardName("Banda de Rufianes", "Bandas de Rufianes", "una Banda de Rufianes");
SpanishCardNames[CardNames.BANDIT_CAMP] = new TranslatedCardName("Campamento Bandido", "Campamentos Bandidos", "un Campamento Bandido");
SpanishCardNames[CardNames.BEGGAR] = new TranslatedCardName("Mendigo", "Mendigos", "un Mendigo");
SpanishCardNames[CardNames.CATACOMBS] = new TranslatedCardName("Catacumbas", "Catacumbas", "unas Catacumbas");
SpanishCardNames[CardNames.COUNT] = new TranslatedCardName("Conde", "Condes", "un Conde");
SpanishCardNames[CardNames.COUNTERFEIT] = new TranslatedCardName("Falsificación", "Falsificaciones", "una Falsificación");
SpanishCardNames[CardNames.CULTIST] = new TranslatedCardName("Sectario", "Sectarios", "un Sectario");
SpanishCardNames[CardNames.DAME_ANNA] = new TranslatedCardName("Lady Anna", "Lady Anna", "Lady Anna");
SpanishCardNames[CardNames.DAME_JOSEPHINE] = new TranslatedCardName("Lady Josephine", "Lady Josephine", "Lady Josephine");
SpanishCardNames[CardNames.DAME_MOLLY] = new TranslatedCardName("Lady Molly", "Lady Molly", "Lady Molly");
SpanishCardNames[CardNames.DAME_NATALIE] = new TranslatedCardName("Lady Natalie", "Lady Natalie", "Lady Natalie");
SpanishCardNames[CardNames.DAME_SYLVIA] = new TranslatedCardName("Lady Sylvia", "Lady Sylvia", "Lady Sylvia");
SpanishCardNames[CardNames.DEATH_CART] = new TranslatedCardName("Carro de Cadáveres", "Carros de Cadáveres", "un Carro de Cadáveres");
SpanishCardNames[CardNames.FEODUM] = new TranslatedCardName("Feudo", "Feudos", "un Feudo");
SpanishCardNames[CardNames.FORAGER] = new TranslatedCardName("Recolector", "Recolectores", "un Recolector");
SpanishCardNames[CardNames.FORTRESS] = new TranslatedCardName("Fortaleza", "Fortalezas", "una Fortaleza");
SpanishCardNames[CardNames.GRAVEROBBER] = new TranslatedCardName("Ladrón de Tumbas", "Ladrones de Tumbas", "un Ladrón de Tumbas");
SpanishCardNames[CardNames.HERMIT] = new TranslatedCardName("Ermitaño", "Ermitaños", "un Ermitaño");
SpanishCardNames[CardNames.HOVEL] = new TranslatedCardName("Casucha", "Casuchas", "una Casucha");
SpanishCardNames[CardNames.HUNTING_GROUNDS] = new TranslatedCardName("Coto de Caza", "Cotos de Caza", "un Coto de Caza");
SpanishCardNames[CardNames.IRONMONGER] = new TranslatedCardName("Ferretero", "Ferreteros", "un Ferretero");
SpanishCardNames[CardNames.JUNK_DEALER] = new TranslatedCardName("Chatarrero", "Chatarreros", "un Chatarrero");
SpanishCardNames[CardNames.MADMAN] = new TranslatedCardName("Loco", "Locos", "un Loco");
SpanishCardNames[CardNames.MARKET_SQUARE] = new TranslatedCardName("Plaza del Mercado", "Plazas del Mercado", "una Plaza del Mercado");
SpanishCardNames[CardNames.MARAUDER] = new TranslatedCardName("Salteador", "Salteadores", "un Salteador");
SpanishCardNames[CardNames.MERCENARY] = new TranslatedCardName("Mercenario", "Mercenarios", "un Mercenario");
SpanishCardNames[CardNames.MYSTIC] = new TranslatedCardName("Pitonisa", "Pitonisas", "una Pitonisa");
SpanishCardNames[CardNames.NECROPOLIS] = new TranslatedCardName("Necrópolis", "Necrópolis", "una Necrópolis");
SpanishCardNames[CardNames.OVERGROWN_ESTATE] = new TranslatedCardName("Finca Abandonada", "Fincas Abandonadas", "una Finca Abandonada");
SpanishCardNames[CardNames.PILLAGE] = new TranslatedCardName("Pillaje", "Pillajes", "un Pillaje");
SpanishCardNames[CardNames.POOR_HOUSE] = new TranslatedCardName("Hospicio", "Hospicios", "un Hospicio");
SpanishCardNames[CardNames.PROCESSION] = new TranslatedCardName("Procesión", "Procesiones", "una Procesión");
SpanishCardNames[CardNames.RATS] = new TranslatedCardName("Ratas", "Ratas", "unas Ratas");
SpanishCardNames[CardNames.REBUILD] = new TranslatedCardName("Reconstruir", "Reconstruir", "un Reconstruir");
SpanishCardNames[CardNames.ROGUE] = new TranslatedCardName("Granuja", "Granujas", "un Granuja");
SpanishCardNames[CardNames.RUINED_LIBRARY] = new TranslatedCardName("Biblioteca en Ruinas", "Bibliotecas en Ruinas", "una Biblioteca en Ruinas");
SpanishCardNames[CardNames.RUINED_MARKET] = new TranslatedCardName("Mercado en Ruinas", "Mercados en Ruinass", "un Mercado en Ruinas");
SpanishCardNames[CardNames.RUINED_VILLAGE] = new TranslatedCardName("Aldea en Ruinas", "Aldeas en Ruinass", "una Aldea en Ruinas");
SpanishCardNames[CardNames.SAGE] = new TranslatedCardName("Sabio", "Sabios", "un Sabio");
SpanishCardNames[CardNames.SCAVENGER] = new TranslatedCardName("Carroñero", "Carroñeros", "un Carroñero");
SpanishCardNames[CardNames.SIR_BAILEY] = new TranslatedCardName("Sir Bailey", "Sir Bailey", "Sir Bailey");
SpanishCardNames[CardNames.SIR_DESTRY] = new TranslatedCardName("Sir Destry", "Sir Destry", "Sir Destry");
SpanishCardNames[CardNames.SIR_MARTIN] = new TranslatedCardName("Sir Martin", "Sir Martin", "Sir Martin");
SpanishCardNames[CardNames.SIR_MICHAEL] = new TranslatedCardName("Sir Michael", "Sir Michael", "Sir Michael");
SpanishCardNames[CardNames.SIR_VANDER] = new TranslatedCardName("Sir Vander", "Sir Vander", "Sir Vander");
SpanishCardNames[CardNames.SPOILS] = new TranslatedCardName("Botín", "Botines", "un Botín");
SpanishCardNames[CardNames.STOREROOM] = new TranslatedCardName("Depósito", "Depósitos", "un Depósito");
SpanishCardNames[CardNames.SQUIRE] = new TranslatedCardName("Escudero", "Escuderos", "un Escudero");
SpanishCardNames[CardNames.SURVIVORS] = new TranslatedCardName("Supervivientes", "Supervivientes", "unos Supervivientes");
SpanishCardNames[CardNames.URCHIN] = new TranslatedCardName("Pillastre", "Pillastres", "un Pillastre");
SpanishCardNames[CardNames.VAGRANT] = new TranslatedCardName("Vagabundo", "Vagabundos", "un Vagabundo");
SpanishCardNames[CardNames.WANDERING_MINSTREL] = new TranslatedCardName("Juglar", "Juglares", "un Juglar");

SpanishCardNames[CardNames.ADVISOR] = new TranslatedCardName("Asesor", "Asesores", "un Asesor");
SpanishCardNames[CardNames.BAKER] = new TranslatedCardName("Panadera", "Panaderas", "una Panadera");
SpanishCardNames[CardNames.BUTCHER] = new TranslatedCardName("Carnicero", "Carniceros", "un Carnicero");
SpanishCardNames[CardNames.CANDLESTICK_MAKER] = new TranslatedCardName("Fabricante de Velas", "Fabricantes de Velas", "un Fabricante de Velas");
SpanishCardNames[CardNames.DOCTOR] = new TranslatedCardName("Doctor", "Doctores", "un Doctor");
SpanishCardNames[CardNames.HERALD] = new TranslatedCardName("Heraldo", "Heraldos", "un Heraldo");
SpanishCardNames[CardNames.JOURNEYMAN] = new TranslatedCardName("Oficial", "Oficiales", "un Oficial");
SpanishCardNames[CardNames.MASTERPIECE] = new TranslatedCardName("Obra Maestra", "Obras Maestras", "una Obra Maestra");
SpanishCardNames[CardNames.MERCHANT_GUILD] = new TranslatedCardName("Gremio Mercantil", "Gremios Mercantiles", "un Gremio Mercantil");
SpanishCardNames[CardNames.PLAZA] = new TranslatedCardName("Plaza", "Plazas", "una Plaza");
SpanishCardNames[CardNames.TAXMAN] = new TranslatedCardName("Recaudador", "Recaudadores", "un Recaudador");
SpanishCardNames[CardNames.SOOTHSAYER] = new TranslatedCardName("Vidente", "Videntes", "una Vidente");
SpanishCardNames[CardNames.STONEMASON] = new TranslatedCardName("Cantero", "Canteros", "un Cantero");

SpanishCardNames[CardNames.ALMS] = new TranslatedCardName("Limosna", "Limosnas", "una Limosna");
SpanishCardNames[CardNames.AMULET] = new TranslatedCardName("Amuleto", "Amuletos", "un Amuleto");
SpanishCardNames[CardNames.ARTIFICER] = new TranslatedCardName("Vidriera", "Vidriera", "una Vidriera");
SpanishCardNames[CardNames.BALL] = new TranslatedCardName("Baile", "Bailes", "un Baile");
SpanishCardNames[CardNames.BONFIRE] = new TranslatedCardName("Hoguera", "Hogueras", "una Hoguera");
SpanishCardNames[CardNames.BORROW] = new TranslatedCardName("Crédito", "Créditos", "un Crédito");
SpanishCardNames[CardNames.BRIDGE_TROLL] = new TranslatedCardName("Puente del Trol", "Puentes del Trol", "un Puente del Trol");
SpanishCardNames[CardNames.CARAVAN_GUARD] = new TranslatedCardName("Escolta", "Escoltas", "un Escolta");
SpanishCardNames[CardNames.CHAMPION] = new TranslatedCardName("Campeona", "Campeonas", "unas Campeonas");
SpanishCardNames[CardNames.COIN_OF_THE_REALM] = new TranslatedCardName("Moneda Real", "Monedas Reales", "una Moneda Real");
SpanishCardNames[CardNames.DISCIPLE] = new TranslatedCardName("Discípulo", "Discípulos", "un Discípulo");
SpanishCardNames[CardNames.DISTANT_LANDS] = new TranslatedCardName("Tierras Lejanas", "Tierras Lejanas", "unas Tierras Lejanas");
SpanishCardNames[CardNames.DUNGEON] = new TranslatedCardName("Mazmorra", "Mazmorras", "una Mazmorra");
SpanishCardNames[CardNames.DUPLICATE] = new TranslatedCardName("Réplica", "Réplicas", "una Réplica");
SpanishCardNames[CardNames.EXPEDITION] = new TranslatedCardName("Expedición", "Expediciones", "una Expedición");
SpanishCardNames[CardNames.FERRY] = new TranslatedCardName("Barcaza", "Barcazas", "una Barcaza");
SpanishCardNames[CardNames.FUGITIVE] = new TranslatedCardName("Fugitivo", "Fugitivos", "un Fugitivo");
SpanishCardNames[CardNames.GEAR] = new TranslatedCardName("Equipamiento", "Equipamientos", "un Equipamiento");
SpanishCardNames[CardNames.GIANT] = new TranslatedCardName("Gigante", "Gigantes", "un Gigante");
SpanishCardNames[CardNames.GUIDE] = new TranslatedCardName("Guía", "Guías", "un Guía");
SpanishCardNames[CardNames.HAUNTED_WOODS] = new TranslatedCardName("Bosque Encantado", "Bosques Encantados", "un Bosque Encantado");
SpanishCardNames[CardNames.HERO] = new TranslatedCardName("Heroína", "Heroínas", "unas Heroínas");
SpanishCardNames[CardNames.HIRELING] = new TranslatedCardName("Recluta", "Reclutas", "un Recluta");
SpanishCardNames[CardNames.INHERITANCE] = new TranslatedCardName("Herencia", "Herencias", "una Herencia");
SpanishCardNames[CardNames.LOST_ARTS] = new TranslatedCardName("Artes Perdidas", "Artes Perdidas", "unas Artes Perdidas");
SpanishCardNames[CardNames.LOST_CITY] = new TranslatedCardName("Ciudad Perdida", "Ciudades Perdidas", "una Ciudad Perdida");
SpanishCardNames[CardNames.MAGPIE] = new TranslatedCardName("Urraca", "Urracas", "una Urraca");
SpanishCardNames[CardNames.MESSENGER] = new TranslatedCardName("Mensajero", "Mensajeros", "un Mensajero");
SpanishCardNames[CardNames.MISER] = new TranslatedCardName("Avaro", "Avaros", "un Avaro");
SpanishCardNames[CardNames.MISSION] = new TranslatedCardName("Misión", "Misiones", "una Misión");
SpanishCardNames[CardNames.PATHFINDING] = new TranslatedCardName("Rastreo", "Rastreos", "un Rastreo");
SpanishCardNames[CardNames.PAGE] = new TranslatedCardName("Paje", "Pajes", "una Paje");
SpanishCardNames[CardNames.PEASANT] = new TranslatedCardName("Campesino", "Campesinos", "un Campesino");
SpanishCardNames[CardNames.PILGRIMAGE] = new TranslatedCardName("Peregrinaje", "Peregrinajes", "un Peregrinaje");
SpanishCardNames[CardNames.PLAN] = new TranslatedCardName("Planificar", "Planificar", "un Planificar");
SpanishCardNames[CardNames.PORT] = new TranslatedCardName("Aldea Portuaria", "Aldeas Portuarias", "una Aldea Portuaria");
SpanishCardNames[CardNames.QUEST] = new TranslatedCardName("Búsqueda", "Búsquedas", "una Búsqueda");
SpanishCardNames[CardNames.RANGER] = new TranslatedCardName("Guarda Forestal", "Guardas Forestales", "un Guarda Forestal");
SpanishCardNames[CardNames.RAID] = new TranslatedCardName("Asalto", "Asaltos", "un Asalto");
SpanishCardNames[CardNames.RATCATCHER] = new TranslatedCardName("Cazador de Ratas", "Cazadores de Ratas", "un Cazador de Ratas");
SpanishCardNames[CardNames.RAZE] = new TranslatedCardName("Arrasar", "Arrasar", "un Arrasar");
SpanishCardNames[CardNames.RELIC] = new TranslatedCardName("Reliquia", "Reliquias", "una Reliquia");
SpanishCardNames[CardNames.ROYAL_CARRIAGE] = new TranslatedCardName("Carruaje Real", "Carruajes Reales", "un Carruaje Real");
SpanishCardNames[CardNames.SAVE] = new TranslatedCardName("Ahorrar", "Ahorrar", "un Ahorrar");
SpanishCardNames[CardNames.SCOUTING_PARTY] = new TranslatedCardName("Avanzadilla", "Avanzadillas", "una Avanzadilla");
SpanishCardNames[CardNames.SEAWAY] = new TranslatedCardName("Vía Marítima", "Vías Marítimas", "una Vía Marítima");
SpanishCardNames[CardNames.SOLDIER] = new TranslatedCardName("Soldado", "Soldados", "un Soldado");
SpanishCardNames[CardNames.STORYTELLER] = new TranslatedCardName("Cuentacuentos", "Cuentacuentos", "una Cuentacuentos");
SpanishCardNames[CardNames.SWAMP_HAG] = new TranslatedCardName("Bruja del Pantano", "Brujas del Pantano", "una Bruja del Pantano");
SpanishCardNames[CardNames.TEACHER] = new TranslatedCardName("Maestro", "Maestros", "un Maestro");
SpanishCardNames[CardNames.TRAVELLING_FAIR] = new TranslatedCardName("Feria Ambulante", "Ferias Ambulantes", "una Feria Ambulante");
SpanishCardNames[CardNames.TRADE] = new TranslatedCardName("Intercambio", "Intercambios", "un Intercambio");
SpanishCardNames[CardNames.TRAINING] = new TranslatedCardName("Entrenamiento", "Entrenamientos", "un Entrenamiento");
SpanishCardNames[CardNames.TRANSMOGRIFY] = new TranslatedCardName("Transfigurar", "Transfigurar", "un Transfigurar");
SpanishCardNames[CardNames.TREASURE_TROVE] = new TranslatedCardName("Piedras Preciosas", "Piedras Preciosas", "unas Piedras Preciosas");
SpanishCardNames[CardNames.TREASURE_HUNTER] = new TranslatedCardName("Cazadora de Tesoros", "Cazadoras de Tesoros", "una Cazadora de Tesoros");
SpanishCardNames[CardNames.WARRIOR] = new TranslatedCardName("Guerrera", "Guerreras", "un Guerrera");
SpanishCardNames[CardNames.WINE_MERCHANT] = new TranslatedCardName("Mercante de Vino", "Mercantes de Vino", "un Mercante de Vino");

SpanishCardNames[CardNames.ENCAMPMENT] = new TranslatedCardName("Campamento", "Campamentos", "un Campamento");
SpanishCardNames[CardNames.PLUNDER] = new TranslatedCardName("Saqueo", "Saqueos", "un Saqueo");
SpanishCardNames[CardNames.PATRICIAN] = new TranslatedCardName("Patricio", "Patricios", "un Patricio");
SpanishCardNames[CardNames.EMPORIUM] = new TranslatedCardName("Emporio", "Emporios", "un Emporio");
SpanishCardNames[CardNames.SETTLERS] = new TranslatedCardName("Colonos", "Colonos", "unos Colonos");
SpanishCardNames[CardNames.BUSTLING_VILLAGE] = new TranslatedCardName("Aldea Bulliciosa", "Aldeas Bulliciosas", "una Aldea Bulliciosa");
SpanishCardNames[CardNames.CATAPULT] = new TranslatedCardName("Catapulta", "Catapultas", "una Catapulta");
SpanishCardNames[CardNames.ROCKS] = new TranslatedCardName("Rocas", "Rocas", "unas Rocas");
SpanishCardNames[CardNames.GLADIATOR] = new TranslatedCardName("Gladiador", "Gladiadores", "un Gladiador");
SpanishCardNames[CardNames.FORTUNE] = new TranslatedCardName("Fortuna", "Fortunas", "una Fortuna");
SpanishCardNames[CardNames.CASTLES] = new TranslatedCardName("Castillos", "Castillos", "unos Castillos");
SpanishCardNames[CardNames.HUMBLE_CASTLE] = new TranslatedCardName("Castillo Humilde", "Castillos Humildes", "un Castillo Humilde");
SpanishCardNames[CardNames.CRUMBLING_CASTLE] = new TranslatedCardName("Castillo Derruido", "Castillos Derruidos", "un Castillo Derruido");
SpanishCardNames[CardNames.SMALL_CASTLE] = new TranslatedCardName("Castillo Pequeño", "Castillos Pequeños", "un Castillo Pequeño");
SpanishCardNames[CardNames.HAUNTED_CASTLE] = new TranslatedCardName("Castillo Encantado", "Castillos Encantados", "un Castillo Encantado");
SpanishCardNames[CardNames.OPULENT_CASTLE] = new TranslatedCardName("Castillo Fastuoso", "Castillos Fastuosos", "un Castillo Fastuoso");
SpanishCardNames[CardNames.SPRAWLING_CASTLE] = new TranslatedCardName("Castillo Próspero", "Castillos Prósperos", "un Castillo Próspero");
SpanishCardNames[CardNames.GRAND_CASTLE] = new TranslatedCardName("Gran Castillo", "Grandes Castillos", "un Gran Castillo");
SpanishCardNames[CardNames.KINGS_CASTLE] = new TranslatedCardName("Castillo Real", "Castillos Reales", "un Castillo Real");
SpanishCardNames[CardNames.ADVANCE] = new TranslatedCardName("Promoción", "Promociones", "una Promoción");
SpanishCardNames[CardNames.ANNEX] = new TranslatedCardName("Anexión", "Anexiones", "una Anexión");
SpanishCardNames[CardNames.ARCHIVE] = new TranslatedCardName("Archivo", "Archivos", "un Archivo");
SpanishCardNames[CardNames.AQUEDUCT] = new TranslatedCardName("Aqueducto", "Aqueductos", "un Aqueducto");
SpanishCardNames[CardNames.ARENA] = new TranslatedCardName("Arena", "Arenas", "una Arena");
SpanishCardNames[CardNames.BANDIT_FORT] = new TranslatedCardName("Fuerte de Bandidos", "Fuertes de Bandidos", "un Fuerte de Bandidos");
SpanishCardNames[CardNames.BANQUET] = new TranslatedCardName("Banquete", "Banquetes", "un Banquete");
SpanishCardNames[CardNames.BASILICA] = new TranslatedCardName("Basílica", "Basílicas", "una Basílica");
SpanishCardNames[CardNames.BATHS] = new TranslatedCardName("Baños", "Baños", "unos Baños");
SpanishCardNames[CardNames.BATTLEFIELD] = new TranslatedCardName("Campo de Batalla", "Campos de Batalla", "un Campo de Batalla");
SpanishCardNames[CardNames.CAPITAL] = new TranslatedCardName("Capital", "Capitales", "un Capital");
SpanishCardNames[CardNames.CHARM] = new TranslatedCardName("Fetiche", "Fetiches", "un Fetiche");
SpanishCardNames[CardNames.CHARIOT_RACE] = new TranslatedCardName("Carrera de Cuádrigas", "Carreras de Cuádrigas", "una Carrera de Cuádrigas");
SpanishCardNames[CardNames.CITY_QUARTER] = new TranslatedCardName("Suburbio", "Suburbios", "un Suburbio");
SpanishCardNames[CardNames.COLONNADE] = new TranslatedCardName("Columnata", "Columnatas", "una Columnata");
SpanishCardNames[CardNames.CONQUEST] = new TranslatedCardName("Conquista", "Conquistas", "una Conquista");
SpanishCardNames[CardNames.CROWN] = new TranslatedCardName("Corona", "Coronas", "una Corona");
SpanishCardNames[CardNames.DELVE] = new TranslatedCardName("Excavación", "Excavaciones", "una Excavación");
SpanishCardNames[CardNames.DEFILED_SHRINE] = new TranslatedCardName("Santuario Profanado", "Santuarios Profanados", "un Santuario Profanado");
SpanishCardNames[CardNames.DOMINATE] = new TranslatedCardName("Dominar", "Dominar", "un Dominar");
SpanishCardNames[CardNames.DONATE] = new TranslatedCardName("Donar", "Donar", "un Donar");
SpanishCardNames[CardNames.ENCHANTRESS] = new TranslatedCardName("Hechizera", "Hechizeras", "una Hechizera");
SpanishCardNames[CardNames.ENGINEER] = new TranslatedCardName("Ingeniera", "Ingenieras", "un Ingeniera");
SpanishCardNames[CardNames.FARMERS_MARKET] = new TranslatedCardName("Mercado Agrícola", "Mercados Agrícolas", "un Mercado Agrícola");
SpanishCardNames[CardNames.FORUM] = new TranslatedCardName("Foro", "Foros", "un Foro");
SpanishCardNames[CardNames.FOUNTAIN] = new TranslatedCardName("Fuente", "Fuentes", "una Fuente");
SpanishCardNames[CardNames.GROUNDSKEEPER] = new TranslatedCardName("Jardinera", "Jardineras", "una Jardinera");
SpanishCardNames[CardNames.KEEP] = new TranslatedCardName("Fuerte", "Fuertes", "un Fuerte");
SpanishCardNames[CardNames.LABYRINTH] = new TranslatedCardName("Laberinto", "Laberintos", "un Laberinto");
SpanishCardNames[CardNames.LEGIONARY] = new TranslatedCardName("Legionario", "Legionarios", "un Legionario");
SpanishCardNames[CardNames.MOUNTAIN_PASS] = new TranslatedCardName("Desfiladero", "Desfiladeros", "un Desfiladero");
SpanishCardNames[CardNames.MUSEUM] = new TranslatedCardName("Museo", "Museos", "un Museo");
SpanishCardNames[CardNames.OBELISK] = new TranslatedCardName("Obelisco", "Obeliscos", "un Obelisco");
SpanishCardNames[CardNames.ORCHARD] = new TranslatedCardName("Vergel", "Vergeles", "un Vergel");
SpanishCardNames[CardNames.OVERLORD] = new TranslatedCardName("Déspota", "Déspotas", "un Déspota");
SpanishCardNames[CardNames.PALACE] = new TranslatedCardName("Palacio", "Palacios", "un Palacio");
SpanishCardNames[CardNames.RITUAL] = new TranslatedCardName("Ritual", "Rituales", "un Ritual");
SpanishCardNames[CardNames.ROYAL_BLACKSMITH] = new TranslatedCardName("Herrero Real", "Herreros Reales", "un Herrero Real");
SpanishCardNames[CardNames.SACRIFICE] = new TranslatedCardName("Sacrificio", "Sacrificios", "un Sacrificio");
SpanishCardNames[CardNames.SALT_THE_EARTH] = new TranslatedCardName("Salar la Tierra", "Salar la Tierra", "un Salar la Tierra");
SpanishCardNames[CardNames.TAX] = new TranslatedCardName("Impuesto", "Impuestos", "un Impuesto");
SpanishCardNames[CardNames.TEMPLE] = new TranslatedCardName("Templo", "Templos", "un Templo");
SpanishCardNames[CardNames.TOMB] = new TranslatedCardName("Tumba", "Tumbas", "una Tumba");
SpanishCardNames[CardNames.TOWER] = new TranslatedCardName("Torre", "Torres", "una Torre");
SpanishCardNames[CardNames.TRIUMPH] = new TranslatedCardName("Triunfo", "Triunfos", "un Triunfo");
SpanishCardNames[CardNames.TRIUMPHAL_ARCH] = new TranslatedCardName("Arco del Triunfo", "Arcos del Triunfo", "un Arco del Triunfo");
SpanishCardNames[CardNames.VILLA] = new TranslatedCardName("Villa", "Villas", "una Villa");
SpanishCardNames[CardNames.WALL] = new TranslatedCardName("Muro", "Muros", "un Muro");
SpanishCardNames[CardNames.WOLF_DEN] = new TranslatedCardName("Guarida de Lobos", "Guaridas de Lobos", "una Guarida de Lobos");
SpanishCardNames[CardNames.WEDDING] = new TranslatedCardName("Boda", "Bodas", "una Boda");
SpanishCardNames[CardNames.WILD_HUNT] = new TranslatedCardName("Cacería Salvaje", "Cacerías Salvajes", "una Cacería Salvaje");
SpanishCardNames[CardNames.WINDFALL] = new TranslatedCardName("Golpe de Suerte", "Golpes de Suerte", "un Golpe de Suerte");

SpanishCardNames[CardNames.BOON_DRAWPILE] = new TranslatedCardName("Pila de Bendiciones", "Pila de Bendiciones", "una Pila de Bendiciones");
SpanishCardNames[CardNames.BOON_DISCARDPILE] = new TranslatedCardName("Descartes de Bendiciones", "Descartes de Bendiciones", "unos Descartes de Bendiciones");
SpanishCardNames[CardNames.THE_EARTHS_GIFT] = new TranslatedCardName("El Don de la Tierra", "Los Dones de la Tierra", "El Don de la Tierra");
SpanishCardNames[CardNames.THE_FIELDS_GIFT] = new TranslatedCardName("El Don del Campo", "Los Dones del Campo", "El Don del Campo");
SpanishCardNames[CardNames.THE_FLAMES_GIFT] = new TranslatedCardName("El Don de la Llama", "Los Dones de la Llama", "El Don de la Llama");
SpanishCardNames[CardNames.THE_FORESTS_GIFT] = new TranslatedCardName("El Don del Bosque", "Los Dones del Bosque", "El Don del Bosque");
SpanishCardNames[CardNames.THE_MOONS_GIFT] = new TranslatedCardName("El Don de la Luna", "Los Dones de la Luna", "El Don de la Luna");
SpanishCardNames[CardNames.THE_MOUNTAINS_GIFT] = new TranslatedCardName("El Don de la Montaña", "Los Dones de la Montaña", "El Don de la Montaña");
SpanishCardNames[CardNames.THE_RIVERS_GIFT] = new TranslatedCardName("El Don del Río", "Los Dones del Río", "El Don del Río");
SpanishCardNames[CardNames.THE_SEAS_GIFT] = new TranslatedCardName("El Don del Mar", "Los Dones del Mar", "El Don del Mar");
SpanishCardNames[CardNames.THE_SKYS_GIFT] = new TranslatedCardName("El Don del Cielo", "Los Dones del Cielo", "El Don del Cielo");
SpanishCardNames[CardNames.THE_SUNS_GIFT] = new TranslatedCardName("El Don del Sol", "Los Dones del Sol", "El Don del Sol");
SpanishCardNames[CardNames.THE_SWAMPS_GIFT] = new TranslatedCardName("El Don del Pantano", "Los Dones del Pantano", "El Don del Pantano");
SpanishCardNames[CardNames.THE_WINDS_GIFT] = new TranslatedCardName("El Don del Viento", "Los Dones del Viento", "El Don del Viento");
SpanishCardNames[CardNames.HEX_DRAWPILE] = new TranslatedCardName("Pila de Embrujos", "Pila de Embrujos", "una Pila de Embrujos");
SpanishCardNames[CardNames.HEX_DISCARDPILE] = new TranslatedCardName("Descartes de Embrujos", "Descartes de Embrujos", "unos Descartes de Embrujos");
SpanishCardNames[CardNames.BAD_OMENS] = new TranslatedCardName("Malos Presagios", "Malos Presagios", "Malos Presagios");
SpanishCardNames[CardNames.DELUSION] = new TranslatedCardName("Alucinación", "Alucinaciones", "Alucinación");
SpanishCardNames[CardNames.ENVY] = new TranslatedCardName("Envidia", "Envidias", "Envidia");
SpanishCardNames[CardNames.FAMINE] = new TranslatedCardName("Hambruna", "Hambrunas", "Hambruna");
SpanishCardNames[CardNames.FEAR] = new TranslatedCardName("Horror", "Horrores", "Horror");
SpanishCardNames[CardNames.GREED] = new TranslatedCardName("Avaricia", "Avaricias", "Avaricia");
SpanishCardNames[CardNames.HAUNTING] = new TranslatedCardName("Encantamiento", "Encantamientos", "Encantamiento");
SpanishCardNames[CardNames.LOCUSTS] = new TranslatedCardName("Langostas", "Langostas", "Langostas");
SpanishCardNames[CardNames.MISERY] = new TranslatedCardName("Miseria", "Miserias", "Miseria");
SpanishCardNames[CardNames.PLAGUE] = new TranslatedCardName("Plaga", "Plagas", "Plaga");
SpanishCardNames[CardNames.POVERTY] = new TranslatedCardName("Pobreza", "Pobrezas", "Pobreza");
SpanishCardNames[CardNames.WAR] = new TranslatedCardName("Guerra", "Guerras", "Guerra");
SpanishCardNames[CardNames.MISERABLE] = new TranslatedCardName("Miserable", "Miserables", "Miserable");
SpanishCardNames[CardNames.TWICE_MISERABLE] = new TranslatedCardName("Doble Miserable", "Doble Miserables", "Doble Miserable");
SpanishCardNames[CardNames.ENVIOUS] = new TranslatedCardName("Envidioso", "Envidiosos", "Envidioso");
SpanishCardNames[CardNames.DELUDED] = new TranslatedCardName("Alucinado", "Alucinados", "Alucinado");
SpanishCardNames[CardNames.LOST_IN_THE_WOODS] = new TranslatedCardName("Perdido en el Bosque", "Perdido en el Bosque", "Perdido en el Bosque");
SpanishCardNames[CardNames.BARD] = new TranslatedCardName("Bardo", "Bardos", "un Bardo");
SpanishCardNames[CardNames.BLESSED_VILLAGE] = new TranslatedCardName("Aldea Bendita", "Aldeas Benditas", "una Aldea Bendita");
SpanishCardNames[CardNames.CHANGELING] = new TranslatedCardName("Xanín", "Xanines", "un Xanín");
SpanishCardNames[CardNames.CEMETERY] = new TranslatedCardName("Cementerio", "Cementerios", "un Cementerio");
SpanishCardNames[CardNames.COBBLER] = new TranslatedCardName("Zapatero", "Zapateros", "un Zapatero");
SpanishCardNames[CardNames.CONCLAVE] = new TranslatedCardName("Cónclave", "Cónclaves", "un Cónclave");
SpanishCardNames[CardNames.CRYPT] = new TranslatedCardName("Cripta", "Criptas", "una Cripta");
SpanishCardNames[CardNames.CURSED_VILLAGE] = new TranslatedCardName("Aldea Maldita", "Aldeas Malditas", "una Aldea Maldita");
SpanishCardNames[CardNames.DEN_OF_SIN] = new TranslatedCardName("Casa del Pecado", "Casas del Pecado", "una Casa del Pecado");
SpanishCardNames[CardNames.DEVILS_WORKSHOP] = new TranslatedCardName("Taller del Diablo", "Talleres del Diablo", "un Taller del Diablo");
SpanishCardNames[CardNames.DRUID] = new TranslatedCardName("Druida", "Druidas", "un Druida");
SpanishCardNames[CardNames.EXORCIST] = new TranslatedCardName("Exorcista", "Exorcistas", "un Exorcista");
SpanishCardNames[CardNames.FAITHFUL_HOUND] = new TranslatedCardName("Sabueso Leal", "Sabuesos Leales", "un Sabueso Leal");
SpanishCardNames[CardNames.FOOL] = new TranslatedCardName("Necio", "Necios", "un Necio");
SpanishCardNames[CardNames.GHOST_TOWN] = new TranslatedCardName("Pueblo Fantasma", "Pueblos Fantasma", "un Pueblo Fantasma");
SpanishCardNames[CardNames.GUARDIAN] = new TranslatedCardName("Guardiana", "Guardianas", "una Guardiana");
SpanishCardNames[CardNames.IDOL] = new TranslatedCardName("Ídolo", "Ídolos", "un Ídolo");
SpanishCardNames[CardNames.LEPRECHAUN] = new TranslatedCardName("Duende", "Duendes", "un Duende");
SpanishCardNames[CardNames.MONASTERY] = new TranslatedCardName("Monasterio", "Monasterios", "un Monasterio");
SpanishCardNames[CardNames.NECROMANCER] = new TranslatedCardName("Nigromante", "Nigromantes", "un Nigromante");
SpanishCardNames[CardNames.NIGHT_WATCHMAN] = new TranslatedCardName("Sereno", "Serenos", "un Sereno");
SpanishCardNames[CardNames.PIXIE] = new TranslatedCardName("Hada", "Hadas", "un Hada");
SpanishCardNames[CardNames.POOKA] = new TranslatedCardName("Puca", "Pucas", "un Puca");
SpanishCardNames[CardNames.RAIDER] = new TranslatedCardName("Saqueador", "Saqueadores", "un Saqueador");
SpanishCardNames[CardNames.SACRED_GROVE] = new TranslatedCardName("Bosque Sagrado", "Bosques Sagrados", "un Bosque Sagrado");
SpanishCardNames[CardNames.SECRET_CAVE] = new TranslatedCardName("Cueva Secreta", "Cuevas Secretas", "una Cueva Secreta");
SpanishCardNames[CardNames.SHEPHERD] = new TranslatedCardName("Pastor", "Pastores", "un Pastor");
SpanishCardNames[CardNames.SKULK] = new TranslatedCardName("Ladrón Sigiloso", "Ladrones Sigilosos", "un Ladrón Sigiloso");
SpanishCardNames[CardNames.TORMENTOR] = new TranslatedCardName("Verdugo", "Verdugos", "un Verdugo");
SpanishCardNames[CardNames.TRAGIC_HERO] = new TranslatedCardName("Héroe Trágico", "Héroes Trágicos", "un Héroe Trágico");
SpanishCardNames[CardNames.TRACKER] = new TranslatedCardName("Rastreador", "Rastreadores", "un Rastreador");
SpanishCardNames[CardNames.VAMPIRE] = new TranslatedCardName("Vampiresa", "Vampiresas", "una Vampiresa");
SpanishCardNames[CardNames.WEREWOLF] = new TranslatedCardName("Hombre Lobo", "Hombres Lobo", "un Hombre Lobo");
SpanishCardNames[CardNames.CURSED_GOLD] = new TranslatedCardName("Oro Maldito", "Oros Malditos", "un Oro Maldito");
SpanishCardNames[CardNames.GOAT] = new TranslatedCardName("Cabra", "Cabras", "una Cabra");
SpanishCardNames[CardNames.HAUNTED_MIRROR] = new TranslatedCardName("Espejo Encantado", "Espejos Encantados", "un Espejo Encantado");
SpanishCardNames[CardNames.LUCKY_COIN] = new TranslatedCardName("Moneda de la Suerte", "Monedas de la Suerte", "una Moneda de la Suerte");
SpanishCardNames[CardNames.MAGIC_LAMP] = new TranslatedCardName("Lámpara Mágica", "Lámparas Mágicas", "una Lámpara Mágica");
SpanishCardNames[CardNames.PASTURE] = new TranslatedCardName("Pasto", "Pastos", "un Pasto");
SpanishCardNames[CardNames.POUCH] = new TranslatedCardName("Bolsa", "Bolsas", "una Bolsa");
SpanishCardNames[CardNames.BAT] = new TranslatedCardName("Murciélago", "Murciélagos", "un Murciélago");
SpanishCardNames[CardNames.GHOST] = new TranslatedCardName("Fantasma", "Fantasmas", "un Fantasma");
SpanishCardNames[CardNames.IMP] = new TranslatedCardName("Diablillo", "Diablillos", "un Diablillo");
SpanishCardNames[CardNames.WILL_O_WISP] = new TranslatedCardName("Fuego Fatuo", "Fuegos Fatuos", "un Fuego Fatuo");
SpanishCardNames[CardNames.WISH] = new TranslatedCardName("Deseo", "Deseos", "un Deseo");
SpanishCardNames[CardNames.ZOMBIE_APPRENTICE] = new TranslatedCardName("Aprendiz Zombi", "Aprendices Zombis", "un Aprendiz Zombi");
SpanishCardNames[CardNames.ZOMBIE_MASON] = new TranslatedCardName("Albañil Zombi", "Albañiles Zombis", "un Albañil Zombi");
SpanishCardNames[CardNames.ZOMBIE_SPY] = new TranslatedCardName("Espía Zombi", "Espías Zombis", "un Espía Zombi");

SpanishCardNames[CardNames.ACTING_TROUPE] = new TranslatedCardName("Compañía de Teatro", "Compañías de Teatro", "una Compañía de Teatro");
SpanishCardNames[CardNames.BORDER_GUARD] = new TranslatedCardName("Guarda Fronterizo", "Guardas Fronterizos", "un Guarda Fronterizo");
SpanishCardNames[CardNames.CARGO_SHIP] = new TranslatedCardName("Buque de Carga", "Buques de Carga", "un Buque de Carga");
SpanishCardNames[CardNames.DUCAT] = new TranslatedCardName("Maravedí", "Maravedíes", "un Maravedí");
SpanishCardNames[CardNames.EXPERIMENT] = new TranslatedCardName("Experimento", "Experimentos", "un Experimento");
SpanishCardNames[CardNames.FLAG_BEARER] = new TranslatedCardName("Abanderado", "Abanderados", "un Abanderado");
SpanishCardNames[CardNames.HIDEOUT] = new TranslatedCardName("Guarida", "Guaridas", "una Guarida");
SpanishCardNames[CardNames.INVENTOR] = new TranslatedCardName("Inventor", "Inventores", "un Inventor");
SpanishCardNames[CardNames.IMPROVE] = new TranslatedCardName("Perfeccionar", "Perfeccionar", "un Perfeccionar");
SpanishCardNames[CardNames.LACKEYS] = new TranslatedCardName("Lacayos", "Lacayos", "unos Lacayos");
SpanishCardNames[CardNames.MOUNTAIN_VILLAGE] = new TranslatedCardName("Aldea de Montaña", "Aldeas de Montaña", "una Aldea de Montaña");
SpanishCardNames[CardNames.PATRON] = new TranslatedCardName("Mecenas", "Mecenas", "un Mecenas");
SpanishCardNames[CardNames.PRIEST] = new TranslatedCardName("Sacerdote", "Sacerdotes", "un Sacerdote");
SpanishCardNames[CardNames.RESEARCH] = new TranslatedCardName("Investigar", "Investigar", "un Investigar");
SpanishCardNames[CardNames.SILK_MERCHANT] = new TranslatedCardName("Vendedor de Sedas", "Vendedores de Sedas", "un Vendedor de Sedas");
SpanishCardNames[CardNames.OLD_WITCH] = new TranslatedCardName("Bruja Anciana", "Brujas Ancianas", "una Bruja Anciana");
SpanishCardNames[CardNames.RECRUITER] = new TranslatedCardName("Reclutador", "Reclutadores", "un Reclutador");
SpanishCardNames[CardNames.SCEPTER] = new TranslatedCardName("Cetro", "Cetros", "un Cetro");
SpanishCardNames[CardNames.SCHOLAR] = new TranslatedCardName("Erudita", "Eruditas", "una Erudita");
SpanishCardNames[CardNames.SCULPTOR] = new TranslatedCardName("Escultora", "Escultoras", "una Escultora");
SpanishCardNames[CardNames.SEER] = new TranslatedCardName("Profeta", "Profetas", "un Profeta");
SpanishCardNames[CardNames.SPICES] = new TranslatedCardName("Especias", "Especias", "unas Especias");
SpanishCardNames[CardNames.SWASHBUCKLER] = new TranslatedCardName("Espadachina", "Espadachinas", "una Espadachina");
SpanishCardNames[CardNames.TREASURER] = new TranslatedCardName("Tesorera", "Tesoreras", "una Tesorera");
SpanishCardNames[CardNames.VILLAIN] = new TranslatedCardName("Villano", "Villanos", "un Villano");
SpanishCardNames[CardNames.FLAG] = new TranslatedCardName("Bandera", "Banderas", "la Bandera");
SpanishCardNames[CardNames.HORN] = new TranslatedCardName("Cuerno", "Cuernos", "el Cuerno");
SpanishCardNames[CardNames.KEY] = new TranslatedCardName("Llave", "Llaves", "la Llave");
SpanishCardNames[CardNames.LANTERN] = new TranslatedCardName("Linterna", "Linternas", "la Linterna");
SpanishCardNames[CardNames.TREASURE_CHEST] = new TranslatedCardName("Cofre del Tesoro", "Cofres del Tesoro", "el Cofre del Tesoro");
SpanishCardNames[CardNames.ACADEMY] = new TranslatedCardName("Academia");
SpanishCardNames[CardNames.BARRACKS] = new TranslatedCardName("Barracas");
SpanishCardNames[CardNames.CANAL] = new TranslatedCardName("Canal");
SpanishCardNames[CardNames.CAPITALISM] = new TranslatedCardName("Capitalismo");
SpanishCardNames[CardNames.CATHEDRAL] = new TranslatedCardName("Catedral");
SpanishCardNames[CardNames.CITADEL] = new TranslatedCardName("Ciudadela");
SpanishCardNames[CardNames.CITY_GATE] = new TranslatedCardName("Puerta de la Ciudad");
SpanishCardNames[CardNames.CROP_ROTATION] = new TranslatedCardName("Rotación de Cultivos");
SpanishCardNames[CardNames.EXPLORATION] = new TranslatedCardName("Exploración");
SpanishCardNames[CardNames.FAIR] = new TranslatedCardName("Feria");
SpanishCardNames[CardNames.FLEET] = new TranslatedCardName("Flota");
SpanishCardNames[CardNames.GUILDHALL] = new TranslatedCardName("Ayuntamiento");
SpanishCardNames[CardNames.INNOVATION] = new TranslatedCardName("Innovación");
SpanishCardNames[CardNames.PAGEANT] = new TranslatedCardName("Desfile");
SpanishCardNames[CardNames.PIAZZA] = new TranslatedCardName("Piazza");
SpanishCardNames[CardNames.ROAD_NETWORK] = new TranslatedCardName("Red Vial");
SpanishCardNames[CardNames.SEWERS] = new TranslatedCardName("Alcantarillas");
SpanishCardNames[CardNames.SILOS] = new TranslatedCardName("Silos");
SpanishCardNames[CardNames.SINISTER_PLOT] = new TranslatedCardName("Trama Siniestra");
SpanishCardNames[CardNames.STAR_CHART] = new TranslatedCardName("Carta Estelar");

SpanishCardNames[CardNames.AVANTO] = new TranslatedCardName("Avanto", "Avantos", "un Avanto");
SpanishCardNames[CardNames.BLACK_MARKET] = new TranslatedCardName("Mercado Negro", "Mercados Negros", "un Mercado Negro");
SpanishCardNames[CardNames.ENVOY] = new TranslatedCardName("Enviado", "Enviados", "un Enviado");
SpanishCardNames[CardNames.GOVERNOR] = new TranslatedCardName("Gobernador", "Gobernadores", "un Gobernador");
SpanishCardNames[CardNames.PRINCE] = new TranslatedCardName("Príncipe", "Príncipes", "un Príncipe");
SpanishCardNames[CardNames.SAUNA] = new TranslatedCardName("Sauna", "Saunas", "una Sauna");
SpanishCardNames[CardNames.STASH] = new TranslatedCardName("Alijo", "Alijos", "un Alijo");
SpanishCardNames[CardNames.SUMMON] = new TranslatedCardName("Convocar", "Convocar", "un Convocar");
SpanishCardNames[CardNames.WALLED_VILLAGE] = new TranslatedCardName("Ciudad Amurallada", "Ciudades Amuralladas", "una Ciudad Amurallada");
SpanishCardNames[CardNames.BLACK_MARKET_PILE] = new TranslatedCardName("Mazo del Mercado Negro", "Mazos del Mercado Negros", "un Mazo del Mercado Negro");
SpanishCardNames[CardNames.DISMANTLE] = new TranslatedCardName("Desmantelar", "Desmantelar", "un Desmantelar");
SpanishCardNames[CardNames.CHURCH] = new TranslatedCardName("Iglesia", "Iglesias", "una Iglesia");
SpanishCardNames[CardNames.CAPTAIN] = new TranslatedCardName("Capitán", "Capitanes", "un Capitán");

SpanishCardNames[CardNames.BLACK_CAT] = new TranslatedCardName("Gato Negro", "Gatos Negros", "un Gato Negro");
SpanishCardNames[CardNames.SLEIGH] = new TranslatedCardName("Trineo", "Trineos", "un Trineo");
SpanishCardNames[CardNames.SUPPLIES] = new TranslatedCardName("Suministros", "Suministros", "unos Suministros");
SpanishCardNames[CardNames.CAMEL_TRAIN] = new TranslatedCardName("Recua de Camellos", "Recua de Camellos", "una Recua de Camellos");
SpanishCardNames[CardNames.GOATHERD] = new TranslatedCardName("Cabrera", "Cabreras", "una Cabrera");
SpanishCardNames[CardNames.SCRAP] = new TranslatedCardName("Chatarra", "Chatarras", "una Chatarra");
SpanishCardNames[CardNames.SHEEPDOG] = new TranslatedCardName("Perro Pastor", "Perros Pastores", "un Perro Pastor");
SpanishCardNames[CardNames.SNOWY_VILLAGE] = new TranslatedCardName("Aldea Nevada", "Aldeas Nevadas", "una Aldea Nevada");
SpanishCardNames[CardNames.STOCKPILE] = new TranslatedCardName("Reservas", "Reservas", "unas Reservas");
SpanishCardNames[CardNames.BOUNTY_HUNTER] = new TranslatedCardName("Cazarrecompensas", "Cazarrecompensas", "un Cazarrecompensas");
SpanishCardNames[CardNames.CARDINAL] = new TranslatedCardName("Cardenal", "Cardenales", "un Cardenal");
SpanishCardNames[CardNames.CAVALRY] = new TranslatedCardName("Caballería", "Caballerías", "una Caballería");
SpanishCardNames[CardNames.GROOM] = new TranslatedCardName("Palafrenero", "Palafreneros", "un Palafrenero");
SpanishCardNames[CardNames.HOSTELRY] = new TranslatedCardName("Hostal", "Hostales", "un Hostal");
SpanishCardNames[CardNames.VILLAGE_GREEN] = new TranslatedCardName("Prado del Pueblo", "Prado del Pueblo", "un Prado del Pueblo");
SpanishCardNames[CardNames.BARGE] = new TranslatedCardName("Gabarra", "Gabarras", "una Gabarra");
SpanishCardNames[CardNames.COVEN] = new TranslatedCardName("Aquelarre", "Aquelarres", "un Aquelarre");
SpanishCardNames[CardNames.DISPLACE] = new TranslatedCardName("Migración", "Migraciones", "una Migración");
SpanishCardNames[CardNames.FALCONER] = new TranslatedCardName("Halconera", "Halconeras", "un Halconera");
SpanishCardNames[CardNames.FISHERMAN] = new TranslatedCardName("Pescador", "Pescadores", "un Pescador");
SpanishCardNames[CardNames.GATEKEEPER] = new TranslatedCardName("Casa del Guarda", "Casas del Guarda", "una Casa del Guarda");
SpanishCardNames[CardNames.HUNTING_LODGE] = new TranslatedCardName("Refugio de Caza", "Refugios de Caza", "un Refugio de Caza");
SpanishCardNames[CardNames.KILN] = new TranslatedCardName("Horno", "Hornos", "un Horno");
SpanishCardNames[CardNames.LIVERY] = new TranslatedCardName("Establo", "Establos", "un Establo");
SpanishCardNames[CardNames.MASTERMIND] = new TranslatedCardName("Genio", "Genios", "un Genio");
SpanishCardNames[CardNames.PADDOCK] = new TranslatedCardName("Corral", "Corrales", "un Corral");
SpanishCardNames[CardNames.SANCTUARY] = new TranslatedCardName("Santuario", "Santuarios", "un Santuario");
SpanishCardNames[CardNames.DESTRIER] = new TranslatedCardName("Destrero", "Destreros", "un Destrero");
SpanishCardNames[CardNames.WAYFARER] = new TranslatedCardName("Caminante", "Caminantes", "un Caminante");
SpanishCardNames[CardNames.ANIMAL_FAIR] = new TranslatedCardName("Feria de Ganado", "Ferias de Ganado", "una Feria de Ganado");
SpanishCardNames[CardNames.HORSE] = new TranslatedCardName("Caballo", "Caballos", "un Caballo");
SpanishCardNames[CardNames.WAY_OF_THE_BUTTERFLY] = new TranslatedCardName("Senda de la Mariposa");
SpanishCardNames[CardNames.WAY_OF_THE_CAMEL] = new TranslatedCardName("Senda del Camello");
SpanishCardNames[CardNames.WAY_OF_THE_CHAMELEON] = new TranslatedCardName("Senda del Camaleón");
SpanishCardNames[CardNames.WAY_OF_THE_FROG] = new TranslatedCardName("Senda de la Rana");
SpanishCardNames[CardNames.WAY_OF_THE_GOAT] = new TranslatedCardName("Senda de la Cabra");
SpanishCardNames[CardNames.WAY_OF_THE_HORSE] = new TranslatedCardName("Senda del Caballo");
SpanishCardNames[CardNames.WAY_OF_THE_MOLE] = new TranslatedCardName("Senda del Topo");
SpanishCardNames[CardNames.WAY_OF_THE_MONKEY] = new TranslatedCardName("Senda del Mono");
SpanishCardNames[CardNames.WAY_OF_THE_MOUSE] = new TranslatedCardName("Senda del Ratón");
SpanishCardNames[CardNames.WAY_OF_THE_MULE] = new TranslatedCardName("Senda de la Mula");
SpanishCardNames[CardNames.WAY_OF_THE_OTTER] = new TranslatedCardName("Senda de la Nutria");
SpanishCardNames[CardNames.WAY_OF_THE_OWL] = new TranslatedCardName("Senda del Búho");
SpanishCardNames[CardNames.WAY_OF_THE_OX] = new TranslatedCardName("Senda del Buey");
SpanishCardNames[CardNames.WAY_OF_THE_PIG] = new TranslatedCardName("Senda del Cerdo");
SpanishCardNames[CardNames.WAY_OF_THE_RAT] = new TranslatedCardName("Senda de la Rata");
SpanishCardNames[CardNames.WAY_OF_THE_SEAL] = new TranslatedCardName("Senda de la Foca");
SpanishCardNames[CardNames.WAY_OF_THE_SHEEP] = new TranslatedCardName("Senda de la Oveja");
SpanishCardNames[CardNames.WAY_OF_THE_SQUIRREL] = new TranslatedCardName("Senda de la Ardilla");
SpanishCardNames[CardNames.WAY_OF_THE_TURTLE] = new TranslatedCardName("Senda de la Tortuga");
SpanishCardNames[CardNames.WAY_OF_THE_WORM] = new TranslatedCardName("Senda del Gusano");
SpanishCardNames[CardNames.DELAY] = new TranslatedCardName("Demora");
SpanishCardNames[CardNames.DESPERATION] = new TranslatedCardName("Desesperación");
SpanishCardNames[CardNames.GAMBLE] = new TranslatedCardName("Apuesta");
SpanishCardNames[CardNames.PURSUE] = new TranslatedCardName("Persecución");
SpanishCardNames[CardNames.RIDE] = new TranslatedCardName("Cabalgata");
SpanishCardNames[CardNames.TOIL] = new TranslatedCardName("Labor");
SpanishCardNames[CardNames.ENHANCE] = new TranslatedCardName("Reforma");
SpanishCardNames[CardNames.MARCH] = new TranslatedCardName("Marcha");
SpanishCardNames[CardNames.TRANSPORT] = new TranslatedCardName("Transporte");
SpanishCardNames[CardNames.BANISH] = new TranslatedCardName("Destierro");
SpanishCardNames[CardNames.BARGAIN] = new TranslatedCardName("Ganga");
SpanishCardNames[CardNames.INVEST] = new TranslatedCardName("Inversión");
SpanishCardNames[CardNames.SEIZE_THE_DAY] = new TranslatedCardName("Aprovecha el Día");
SpanishCardNames[CardNames.COMMERCE] = new TranslatedCardName("Comercio");
SpanishCardNames[CardNames.DEMAND] = new TranslatedCardName("Demanda");
SpanishCardNames[CardNames.STAMPEDE] = new TranslatedCardName("Estampida");
SpanishCardNames[CardNames.REAP] = new TranslatedCardName("Siega");
SpanishCardNames[CardNames.ENCLAVE] = new TranslatedCardName("Enclave");
SpanishCardNames[CardNames.ALLIANCE] = new TranslatedCardName("Alianza");
SpanishCardNames[CardNames.POPULATE] = new TranslatedCardName("Repoblar");

SpanishCardNames[CardNames.CARD_OF_THE_BUTTERFLY] = "";
SpanishCardNames[CardNames.CARD_OF_THE_CAMEL] = "";
SpanishCardNames[CardNames.CARD_OF_THE_CHAMELEON] = "";
SpanishCardNames[CardNames.CARD_OF_THE_FROG] = "";
SpanishCardNames[CardNames.CARD_OF_THE_GOAT] = "";
SpanishCardNames[CardNames.CARD_OF_THE_HORSE] = "";
SpanishCardNames[CardNames.CARD_OF_THE_MOLE] = "";
SpanishCardNames[CardNames.CARD_OF_THE_MONKEY] = "";
SpanishCardNames[CardNames.CARD_OF_THE_MOUSE] = "";
SpanishCardNames[CardNames.CARD_OF_THE_MULE] = "";
SpanishCardNames[CardNames.CARD_OF_THE_OTTER] = "";
SpanishCardNames[CardNames.CARD_OF_THE_OWL] = "";
SpanishCardNames[CardNames.CARD_OF_THE_OX] = "";
SpanishCardNames[CardNames.CARD_OF_THE_PIG] = "";
SpanishCardNames[CardNames.CARD_OF_THE_RAT] = "";
SpanishCardNames[CardNames.CARD_OF_THE_SEAL] = "";
SpanishCardNames[CardNames.CARD_OF_THE_SHEEP] = "";
SpanishCardNames[CardNames.CARD_OF_THE_SQUIRREL] = "";
SpanishCardNames[CardNames.CARD_OF_THE_TURTLE] = "";
SpanishCardNames[CardNames.CARD_OF_THE_WORM] = "";

SpanishCardNames[CardNames.TOWN] = new TranslatedCardName("Town", "Towns", "a Town");
SpanishCardNames[CardNames.MODIFY] = new TranslatedCardName("Modify", "Modifies", "a Modify");
SpanishCardNames[CardNames.MERCHANT_CAMP] = new TranslatedCardName("Merchant Camp", "Merchant Camps", "a Merchant Camp");
SpanishCardNames[CardNames.SWAP] = new TranslatedCardName("Swap", "Swaps", "a Swap");
SpanishCardNames[CardNames.SKIRMISHER] = new TranslatedCardName("Skirmisher", "Skirmishers", "a Skirmisher");
SpanishCardNames[CardNames.HIGHWAYMAN] = new TranslatedCardName("Highwayman", "Highwaymen", "a Highwayman");
SpanishCardNames[CardNames.BARBARIAN] = new TranslatedCardName("Barbarian", "Barbarians", "a Barbarian");
SpanishCardNames[CardNames.INNKEEPER] = new TranslatedCardName("Innkeeper", "Innkeepers", "an Innkeeper");
SpanishCardNames[CardNames.CAPITAL_CITY] = new TranslatedCardName("Capital City", "Capital Cities", "a Capital City");
SpanishCardNames[CardNames.SPECIALIST] = new TranslatedCardName("Specialist", "Specialists", "a Specialist");
SpanishCardNames[CardNames.CARPENTER] = new TranslatedCardName("Carpenter", "Carpenters", "a Carpenter");
SpanishCardNames[CardNames.ROYAL_GALLEY] = new TranslatedCardName("Royal Galley", "Royal Galleys", "a Royal Galley");
SpanishCardNames[CardNames.MARQUIS] = new TranslatedCardName("Marquis", "Marquises", "a Marquis");
SpanishCardNames[CardNames.SENTINEL] = new TranslatedCardName("Sentinel", "Sentinels", "a Sentinel");
SpanishCardNames[CardNames.COURIER] = new TranslatedCardName("Courier", "Couriers", "a Courier");
SpanishCardNames[CardNames.GALLERIA] = new TranslatedCardName("Galleria", "Gallerias", "a Galleria");
SpanishCardNames[CardNames.HUNTER] = new TranslatedCardName("Hunter", "Hunters", "a Hunter");

SpanishCardNames[CardNames.SYCOPHANT] = new TranslatedCardName("Sycophant", "Sycophants", "a Sycophant");
SpanishCardNames[CardNames.UNDERLING] = new TranslatedCardName("Underling", "Underlings", "an Underling");
SpanishCardNames[CardNames.IMPORTER] = new TranslatedCardName("Importer", "Importers", "an Importer");
SpanishCardNames[CardNames.CONTRACT] = new TranslatedCardName("Contract", "Contracts", "a Contract");
SpanishCardNames[CardNames.EMISSARY] = new TranslatedCardName("Emissary", "Emissaries", "an Emissary");
SpanishCardNames[CardNames.BAUBLE] = new TranslatedCardName("Bauble", "Baubles", "a Bauble");
SpanishCardNames[CardNames.BROKER] = new TranslatedCardName("Broker", "Brokers", "a Broker");
SpanishCardNames[CardNames.GUILDMASTER] = new TranslatedCardName("Guildmaster", "Guildmasters", "a Guildmaster");

SpanishCardNames[CardNames.ARCHITECTS_GUILD] = new TranslatedCardName("Architects' Guild");
SpanishCardNames[CardNames.ORDER_OF_ASTROLOGERS] = new TranslatedCardName("Order of Astrologers");
SpanishCardNames[CardNames.LEAGUE_OF_BANKERS] = new TranslatedCardName("League of Bankers");
SpanishCardNames[CardNames.MOUNTAIN_FOLK] = new TranslatedCardName("Mountain Folk");
SpanishCardNames[CardNames.WOODWORKERS_GUILD] = new TranslatedCardName("Woodworkers' Guild");
SpanishCardNames[CardNames.PEACEFUL_CULT] = new TranslatedCardName("Peaceful Cult");
SpanishCardNames[CardNames.CRAFTERS_GUILD] = new TranslatedCardName("Crafters' Guild");
SpanishCardNames[CardNames.CAVE_DWELLERS] = new TranslatedCardName("Cave Dwellers");
SpanishCardNames[CardNames.PLATEAU_SHEPHERDS] = new TranslatedCardName("Plateau Shepherds");
SpanishCardNames[CardNames.DESERT_GUIDES] = new TranslatedCardName("Desert Guides");
SpanishCardNames[CardNames.FAMILY_OF_INVENTORS] = new TranslatedCardName("Family of Inventors");
SpanishCardNames[CardNames.ISLAND_FOLK] = new TranslatedCardName("Island Folk");
SpanishCardNames[CardNames.ORDER_OF_MASONS] = new TranslatedCardName("Order of Masons");
SpanishCardNames[CardNames.BAND_OF_NOMADS] = new TranslatedCardName("Band of Nomads");
SpanishCardNames[CardNames.GANG_OF_PICKPOCKETS] = new TranslatedCardName("Gang of Pickpockets");
SpanishCardNames[CardNames.MARKET_TOWNS] = new TranslatedCardName("Market Towns");
SpanishCardNames[CardNames.FOREST_DWELLERS] = new TranslatedCardName("Forest Dwellers");
SpanishCardNames[CardNames.FELLOWSHIP_OF_SCRIBES] = new TranslatedCardName("Fellowship of Scribes");
SpanishCardNames[CardNames.LEAGUE_OF_SHOPKEEPERS] = new TranslatedCardName("League of Shopkeepers");
SpanishCardNames[CardNames.COASTAL_HAVEN] = new TranslatedCardName("Coastal Haven");
SpanishCardNames[CardNames.CITY_STATE] = new TranslatedCardName("City-state");
SpanishCardNames[CardNames.TRAPPERS_LODGE] = new TranslatedCardName("Trappers' Lodge");
SpanishCardNames[CardNames.CIRCLE_OF_WITCHES] = new TranslatedCardName("Circle of Witches");

SpanishCardNames[CardNames.AUGUR_PILE] = new TranslatedCardName("Augurs");
SpanishCardNames[CardNames.HERB_GATHERER] = new TranslatedCardName("Herb Gatherer", "Herb Gatherers", "a Herb Gatherer");
SpanishCardNames[CardNames.ACOLYTE] = new TranslatedCardName("Acolyte", "Acolytes", "an Acolyte");
SpanishCardNames[CardNames.SORCERESS] = new TranslatedCardName("Sorceress", "Sorceresses", "a Sorceress");
SpanishCardNames[CardNames.SIBYL] = new TranslatedCardName("Sibyl", "Sibyls", "a Sibyl");
SpanishCardNames[CardNames.CLASH_PILE] = new TranslatedCardName("Clashes");
SpanishCardNames[CardNames.BATTLE_PLAN] = new TranslatedCardName("Battle Plan", "Battle Plans", "a Battle Plan");
SpanishCardNames[CardNames.ARCHER] = new TranslatedCardName("Archer", "Archers", "an Archer");
SpanishCardNames[CardNames.WARLORD] = new TranslatedCardName("Warlord", "Warlords", "a Warlord");
SpanishCardNames[CardNames.TERRITORY] = new TranslatedCardName("Territory", "Territories", "a Territory");
SpanishCardNames[CardNames.FORT_PILE] = new TranslatedCardName("Forts");
SpanishCardNames[CardNames.TENT] = new TranslatedCardName("Tent", "Tents", "a Tent");
SpanishCardNames[CardNames.GARRISON] = new TranslatedCardName("Garrison", "Garrisons", "a Garrison");
SpanishCardNames[CardNames.HILL_FORT] = new TranslatedCardName("Hill Fort", "Hill Forts", "a Hill Fort");
SpanishCardNames[CardNames.STRONGHOLD] = new TranslatedCardName("Stronghold", "Strongholds", "a Stronghold");
SpanishCardNames[CardNames.ODYSSEY_PILE] = new TranslatedCardName("Odysseys");
SpanishCardNames[CardNames.OLD_MAP] = new TranslatedCardName("Old Map", "Old Maps", "an Old Map");
SpanishCardNames[CardNames.VOYAGE] = new TranslatedCardName("Voyage", "Voyages", "a Voyage");
SpanishCardNames[CardNames.SUNKEN_TREASURE] = new TranslatedCardName("Sunken Treasure", "Sunken Treasures", "a Sunken Treasure");
SpanishCardNames[CardNames.DISTANT_SHORE] = new TranslatedCardName("Distant Shore", "Distant Shores", "a Distant Shore");
SpanishCardNames[CardNames.TOWNSFOLK_PILE] = new TranslatedCardName("Townsfolk");
SpanishCardNames[CardNames.TOWN_CRIER] = new TranslatedCardName("Town Crier", "Town Criers", "a Town Crier");
SpanishCardNames[CardNames.BLACKSMITH] = new TranslatedCardName("Blacksmith", "Blacksmiths", "a Blacksmith");
SpanishCardNames[CardNames.MILLER] = new TranslatedCardName("Miller", "Millers", "a Miller");
SpanishCardNames[CardNames.ELDER] = new TranslatedCardName("Elder", "Elders", "an Elder");
SpanishCardNames[CardNames.WIZARD_PILE] = new TranslatedCardName("Wizards");
SpanishCardNames[CardNames.STUDENT] = new TranslatedCardName("Student", "Students", "a Student");
SpanishCardNames[CardNames.CONJURER] = new TranslatedCardName("Conjurer", "Conjurers", "a Conjurer");
SpanishCardNames[CardNames.SORCERER] = new TranslatedCardName("Sorcerer", "Sorcerers", "a Sorcerer");
SpanishCardNames[CardNames.LICH] = new TranslatedCardName("Lich", "Liches", "a Lich");
SpanishCardNames[CardNames.MONKEY] = new TranslatedCardName("Monkey", "Monkeys", "a Monkey");
SpanishCardNames[CardNames.BLOCKADE] = new TranslatedCardName("Blockade", "Blockades", "a Blockade");
SpanishCardNames[CardNames.SAILOR] = new TranslatedCardName("Sailor", "Sailors", "a Sailor");
SpanishCardNames[CardNames.PIRATE] = new TranslatedCardName("Pirate", "Pirates", "a Pirate");
SpanishCardNames[CardNames.CORSAIR] = new TranslatedCardName("Corsair", "Corsairs", "a Corsair");
SpanishCardNames[CardNames.SEA_CHART] = new TranslatedCardName("Sea Chart", "Sea Charts", "a Sea Chart");
SpanishCardNames[CardNames.ASTROLABE] = new TranslatedCardName("Astrolabe", "Astrolabes", "an Astrolabe");
SpanishCardNames[CardNames.TIDE_POOLS] = new TranslatedCardName("Tide Pools", "Tide Pools", "a Tide Pools");
SpanishCardNames[CardNames.SEA_WITCH] = new TranslatedCardName("Sea Witch", "Sea Witches", "a Sea Witch");
SpanishCardNames[CardNames.ANVIL] = new TranslatedCardName("Anvil", "Anvils", "an Anvil");
SpanishCardNames[CardNames.CLERK] = new TranslatedCardName("Clerk", "Clerks", "a Clerk");
SpanishCardNames[CardNames.INVESTMENT] = new TranslatedCardName("Investment", "Investments", "an Investment");
SpanishCardNames[CardNames.TIARA] = new TranslatedCardName("Tiara", "Tiaras", "a Tiara");
SpanishCardNames[CardNames.CHARLATAN] = new TranslatedCardName("Charlatan", "Charlatans", "a Charlatan");
SpanishCardNames[CardNames.COLLECTION] = new TranslatedCardName("Collection", "Collections", "a Collection");
SpanishCardNames[CardNames.CRYSTAL_BALL] = new TranslatedCardName("Crystal Ball", "Crystal Balls", "a Crystal Ball");
SpanishCardNames[CardNames.MAGNATE] = new TranslatedCardName("Magnate", "Magnates", "a Magnate");
SpanishCardNames[CardNames.WAR_CHEST] = new TranslatedCardName("War Chest", "War Chests", "a War Chest");

SpanishCardNames[CardNames.GUARD_DOG] = new TranslatedCardName("Guard Dog", "Guard Dogs", "a Guard Dog");
SpanishCardNames[CardNames.BERSERKER] = new TranslatedCardName("Berserker", "Berserkers", "a Berserker");
SpanishCardNames[CardNames.NOMADS] = new TranslatedCardName("Nomads", "Nomads", "a Nomads");
SpanishCardNames[CardNames.TRAIL] = new TranslatedCardName("Trail", "Trails", "a Trail");
SpanishCardNames[CardNames.WEAVER] = new TranslatedCardName("Weaver", "Weavers", "a Weaver");
SpanishCardNames[CardNames.SOUK] = new TranslatedCardName("Souk", "Souks", "a Souk");
SpanishCardNames[CardNames.CAULDRON] = new TranslatedCardName("Cauldron", "Cauldrons", "a Cauldron");
SpanishCardNames[CardNames.WHEELWRIGHT] = new TranslatedCardName("Wheelwright", "Wheelwrights", "a Wheelwright");
SpanishCardNames[CardNames.WITCHS_HUT] = new TranslatedCardName("Witch's Hut", "Witch's Huts", "a Witch's Hut");

// Missing lines as of 09.01.2023 18:45:02
SpanishCardNames[CardNames.CAGE] = new TranslatedCardName("Cage", "Cages", "a Cage");
SpanishCardNames[CardNames.GROTTO] = new TranslatedCardName("Grotto", "Grottoes", "a Grotto");
SpanishCardNames[CardNames.JEWELLED_EGG] = new TranslatedCardName("Jewelled Egg", "Jewelled Eggs", "a Jewelled Egg");
SpanishCardNames[CardNames.SEARCH] = new TranslatedCardName("Search", "Searches", "a Search");
SpanishCardNames[CardNames.SHAMAN] = new TranslatedCardName("Shaman", "Shamans", "a Shaman");
SpanishCardNames[CardNames.SECLUDED_SHRINE] = new TranslatedCardName("Secluded Shrine", "Secluded Shrines", "a Secluded Shrine");
SpanishCardNames[CardNames.SIREN] = new TranslatedCardName("Siren", "Sirens", "a Siren");
SpanishCardNames[CardNames.STOWAWAY] = new TranslatedCardName("Stowaway", "Stowaways", "a Stowaway");
SpanishCardNames[CardNames.TASKMASTER] = new TranslatedCardName("Taskmaster", "Taskmasters", "a Taskmaster");
SpanishCardNames[CardNames.ABUNDANCE] = new TranslatedCardName("Abundance", "Abundances", "an Abundance");
SpanishCardNames[CardNames.CABIN_BOY] = new TranslatedCardName("Cabin Boy", "Cabin Boys", "a Cabin Boy");
SpanishCardNames[CardNames.CRUCIBLE] = new TranslatedCardName("Crucible", "Crucibles", "a Crucible");
SpanishCardNames[CardNames.FLAGSHIP] = new TranslatedCardName("Flagship", "Flagships", "a Flagship");
SpanishCardNames[CardNames.FORTUNE_HUNTER] = new TranslatedCardName("Fortune Hunter", "Fortune Hunters", "a Fortune Hunter");
SpanishCardNames[CardNames.GONDOLA] = new TranslatedCardName("Gondola", "Gondolas", "a Gondola");
SpanishCardNames[CardNames.HARBOR_VILLAGE] = new TranslatedCardName("Harbor Village", "Harbor Villages", "a Harbor Village");
SpanishCardNames[CardNames.LANDING_PARTY] = new TranslatedCardName("Landing Party", "Landing Parties", "a Landing Party");
SpanishCardNames[CardNames.MAPMAKER] = new TranslatedCardName("Mapmaker", "Mapmakers", "a Mapmaker");
SpanishCardNames[CardNames.MAROON] = new TranslatedCardName("Maroon", "Maroons", "a Maroon");
SpanishCardNames[CardNames.ROPE] = new TranslatedCardName("Rope", "Ropes", "a Rope");
SpanishCardNames[CardNames.SWAMP_SHACKS] = new TranslatedCardName("Swamp Shacks", "Swamp Shacks", "a Swamp Shacks");
SpanishCardNames[CardNames.TOOLS] = new TranslatedCardName("Tools", "Tools", "a Tools");
SpanishCardNames[CardNames.BURIED_TREASURE] = new TranslatedCardName("Buried Treasure", "Buried Treasures", "a Buried Treasure");
SpanishCardNames[CardNames.CREW] = new TranslatedCardName("Crew", "Crews", "a Crew");
SpanishCardNames[CardNames.CUTTHROAT] = new TranslatedCardName("Cutthroat", "Cutthroats", "a Cutthroat");
SpanishCardNames[CardNames.ENLARGE] = new TranslatedCardName("Enlarge", "Enlarges", "an Enlarge");
SpanishCardNames[CardNames.FIGURINE] = new TranslatedCardName("Figurine", "Figurines", "a Figurine");
SpanishCardNames[CardNames.FIRST_MATE] = new TranslatedCardName("First Mate", "First Mates", "a First Mate");
SpanishCardNames[CardNames.FRIGATE] = new TranslatedCardName("Frigate", "Frigates", "a Frigate");
SpanishCardNames[CardNames.LONGSHIP] = new TranslatedCardName("Longship", "Longships", "a Longship");
SpanishCardNames[CardNames.MINING_ROAD] = new TranslatedCardName("Mining Road", "Mining Roads", "a Mining Road");
SpanishCardNames[CardNames.PENDANT] = new TranslatedCardName("Pendant", "Pendants", "a Pendant");
SpanishCardNames[CardNames.PICKAXE] = new TranslatedCardName("Pickaxe", "Pickaxes", "a Pickaxe");
SpanishCardNames[CardNames.PILGRIM] = new TranslatedCardName("Pilgrim", "Pilgrims", "a Pilgrim");
SpanishCardNames[CardNames.QUARTERMASTER] = new TranslatedCardName("Quartermaster", "Quartermasters", "a Quartermaster");
SpanishCardNames[CardNames.SILVER_MINE] = new TranslatedCardName("Silver Mine", "Silver Mines", "a Silver Mine");
SpanishCardNames[CardNames.TRICKSTER] = new TranslatedCardName("Trickster", "Tricksters", "a Trickster");
SpanishCardNames[CardNames.WEALTHY_VILLAGE] = new TranslatedCardName("Wealthy Village", "Wealthy Villages", "a Wealthy Village");
SpanishCardNames[CardNames.SACK_OF_LOOT] = new TranslatedCardName("Sack Of Loot", "Sacks of Loot", "a Sack of Loot");
SpanishCardNames[CardNames.KINGS_CACHE] = new TranslatedCardName("King's Cache", "King's Caches", "a King's Cache");
SpanishCardNames[CardNames.BURY] = new TranslatedCardName("Bury", "Buries", "a Bury");
SpanishCardNames[CardNames.AVOID] = new TranslatedCardName("Avoid", "Avoids", "an Avoid");
SpanishCardNames[CardNames.DELIVER] = new TranslatedCardName("Deliver", "Delivers", "a Deliver");
SpanishCardNames[CardNames.PERIL] = new TranslatedCardName("Peril", "Perils", "a Peril");
SpanishCardNames[CardNames.RUSH] = new TranslatedCardName("Rush", "Rushes", "a Rush");
SpanishCardNames[CardNames.FORAY] = new TranslatedCardName("Foray", "Forays", "a Foray");
SpanishCardNames[CardNames.LAUNCH] = new TranslatedCardName("Launch", "Launches", "a Launch");
SpanishCardNames[CardNames.MIRROR] = new TranslatedCardName("Mirror", "Mirrors", "a Mirror");
SpanishCardNames[CardNames.PREPARE] = new TranslatedCardName("Prepare", "Prepares", "a Prepare");
SpanishCardNames[CardNames.SCROUNGE] = new TranslatedCardName("Scrounge", "Scrounges", "a Scrounge");
SpanishCardNames[CardNames.JOURNEY] = new TranslatedCardName("Journey", "Journeys", "a Journey");
SpanishCardNames[CardNames.MAELSTROM] = new TranslatedCardName("Maelstrom", "Maelstroms", "a Maelstrom");
SpanishCardNames[CardNames.LOOTING] = new TranslatedCardName("Looting", "Lootings", "a Looting");
SpanishCardNames[CardNames.INVASION] = new TranslatedCardName("Invasion", "Invasions", "an Invasion");
SpanishCardNames[CardNames.PROSPER] = new TranslatedCardName("Prosper", "Prospers", "a Prosper");
SpanishCardNames[CardNames.LOOT_PILE] = new TranslatedCardName("Loot");
SpanishCardNames[CardNames.AMPHORA] = new TranslatedCardName("Amphora", "Amphorae", "an Amphora");
SpanishCardNames[CardNames.DOUBLOONS] = new TranslatedCardName("Doubloons", "Doubloons", "a Doubloons");
SpanishCardNames[CardNames.ENDLESS_CHALICE] = new TranslatedCardName("Endless Chalice", "Endless Chalices", "an Endless Chalice");
SpanishCardNames[CardNames.FIGUREHEAD] = new TranslatedCardName("Figurehead", "Figureheads", "a Figurehead");
SpanishCardNames[CardNames.HAMMER] = new TranslatedCardName("Hammer", "Hammers", "a Hammer");
SpanishCardNames[CardNames.INSIGNIA] = new TranslatedCardName("Insignia", "Insignias", "an Insignia");
SpanishCardNames[CardNames.JEWELS] = new TranslatedCardName("Jewels", "Jewels", "a Jewels");
SpanishCardNames[CardNames.ORB] = new TranslatedCardName("Orb", "Orbs", "an Orb");
SpanishCardNames[CardNames.PRIZE_GOAT] = new TranslatedCardName("Prize Goat", "Prize Goats", "a Prize Goat");
SpanishCardNames[CardNames.PUZZLE_BOX] = new TranslatedCardName("Puzzle Box", "Puzzle Boxes", "a Puzzle Box");
SpanishCardNames[CardNames.SEXTANT] = new TranslatedCardName("Sextant", "Sextants", "a Sextant");
SpanishCardNames[CardNames.SHIELD] = new TranslatedCardName("Shield", "Shields", "a Shield");
SpanishCardNames[CardNames.SPELL_SCROLL] = new TranslatedCardName("Spell Scroll", "Spell Scrolls", "a Spell Scroll");
SpanishCardNames[CardNames.STAFF] = new TranslatedCardName("Staff", "Staffs", "a Staff");
SpanishCardNames[CardNames.SWORD] = new TranslatedCardName("Sword", "Swords", "a Sword");
SpanishCardNames[CardNames.CHEAP] = new TranslatedCardName("Cheap", "Cheaps", "a Cheap");
SpanishCardNames[CardNames.CURSED] = new TranslatedCardName("Cursed", "Curseds", "a Cursed");
SpanishCardNames[CardNames.FATED] = new TranslatedCardName("Fated", "Fateds", "a Fated");
SpanishCardNames[CardNames.FAWNING] = new TranslatedCardName("Fawning", "Fawnings", "a Fawning");
SpanishCardNames[CardNames.FRIENDLY] = new TranslatedCardName("Friendly", "Friendlies", "a Friendly");
SpanishCardNames[CardNames.HASTY] = new TranslatedCardName("Hasty", "Hasties", "a Hasty");
SpanishCardNames[CardNames.INHERITED] = new TranslatedCardName("Inherited", "Inheriteds", "an Inherited");
SpanishCardNames[CardNames.INSPIRING] = new TranslatedCardName("Inspiring", "Inspirings", "an Inspiring");
SpanishCardNames[CardNames.NEARBY] = new TranslatedCardName("Nearby", "Nearbys", "an Nearby");
SpanishCardNames[CardNames.PATIENT] = new TranslatedCardName("Patient", "Patients", "a Patient");
SpanishCardNames[CardNames.PIOUS] = new TranslatedCardName("Pious", "Piouses", "a Pious");
SpanishCardNames[CardNames.RECKLESS] = new TranslatedCardName("Reckless", "Recklesses", "a Reckless");
SpanishCardNames[CardNames.RICH] = new TranslatedCardName("Rich", "Riches", "a Rich");
SpanishCardNames[CardNames.SHY] = new TranslatedCardName("Shy", "Shies", "a Shy");
SpanishCardNames[CardNames.TIRELESS] = new TranslatedCardName("Tireless", "Tirelesses", "a Tireless");
SpanishCardNames[CardNames.MARCHLAND] = new TranslatedCardName("Marchland", "Marchlands", "a Marchland");

SpanishCardNames[CardNames.SHOP] = new TranslatedCardName("Shop", "Shops", "a Shop");
SpanishCardNames[CardNames.CARNIVAL] = new TranslatedCardName("Carnival", "Carnivals", "a Carnival");
SpanishCardNames[CardNames.FERRYMAN] = new TranslatedCardName("Ferryman", "Ferrymen", "a Ferryman");
SpanishCardNames[CardNames.FARMHANDS] = new TranslatedCardName("Farmhands", "Farmhands", "a Farmhands");
SpanishCardNames[CardNames.JOUST] = new TranslatedCardName("Joust", "Jousts", "a Joust");
SpanishCardNames[CardNames.REWARDS_PILE] = new TranslatedCardName("Rewards", "Rewards", "Rewards");
SpanishCardNames[CardNames.COURSER] = new TranslatedCardName("Courser", "Coursers", "a Courser");
SpanishCardNames[CardNames.RENOWN] = new TranslatedCardName("Renown", "Renowns", "a Renown");
SpanishCardNames[CardNames.CORONET] = new TranslatedCardName("Coronet", "Coronets", "a Coronet");
SpanishCardNames[CardNames.DEMESNE] = new TranslatedCardName("Demesne", "Demesnes", "a Demesne");
SpanishCardNames[CardNames.HOUSECARL] = new TranslatedCardName("Housecarl", "Housecarls", "a Housecarl");
SpanishCardNames[CardNames.HUGE_TURNIP] = new TranslatedCardName("Huge Turnip", "Huge Turnips", "a Huge Turnip");
SpanishCardNames[CardNames.FARRIER] = new TranslatedCardName("Farrier", "Farriers", "a Farrier");
SpanishCardNames[CardNames.INFIRMARY] = new TranslatedCardName("Infirmary", "Infirmaries", "an Infirmary");
SpanishCardNames[CardNames.FOOTPAD] = new TranslatedCardName("Footpad", "Footpads", "a Footpad");
SpanishCardNames[CardNames.FISHMONGER] = new TranslatedCardName("Fishmonger", "Fishmongers", "a Fishmonger");
SpanishCardNames[CardNames.SNAKE_WITCH] = new TranslatedCardName("Snake Witch", "Snake Witches", "a Snake Witch");
SpanishCardNames[CardNames.ARISTOCRAT] = new TranslatedCardName("Aristocrat", "Aristocrats", "an Aristocrat");
SpanishCardNames[CardNames.CRAFTSMAN] = new TranslatedCardName("Craftsman", "Craftsmen", "a Craftsman");
SpanishCardNames[CardNames.RIVERBOAT] = new TranslatedCardName("Riverboat", "Riverboats", "a Riverboat");
SpanishCardNames[CardNames.ROOT_CELLAR] = new TranslatedCardName("Root Cellar", "Root Cellars", "a Root Cellar");
SpanishCardNames[CardNames.ALLEY] = new TranslatedCardName("Alley", "Alleys", "an Alley");
SpanishCardNames[CardNames.CHANGE] = new TranslatedCardName("Change", "Changes", "a Change");
SpanishCardNames[CardNames.NINJA] = new TranslatedCardName("Ninja", "Ninjas", "a Ninja");
SpanishCardNames[CardNames.POET] = new TranslatedCardName("Poet", "Poets", "a Poet");
SpanishCardNames[CardNames.RIVER_SHRINE] = new TranslatedCardName("River Shrine", "River Shrines", "a River Shrine");
SpanishCardNames[CardNames.RUSTIC_VILLAGE] = new TranslatedCardName("Rustic Village", "Rustic Villages", "a Rustic Village");
SpanishCardNames[CardNames.GOLD_MINE] = new TranslatedCardName("Gold Mine", "Gold Mines", "a Gold Mine");
SpanishCardNames[CardNames.IMPERIAL_ENVOY] = new TranslatedCardName("Imperial Envoy", "Imperial Envoys", "an Imperial Envoy");
SpanishCardNames[CardNames.KITSUNE] = new TranslatedCardName("Kitsune", "Kitsunes", "a Kitsune");
SpanishCardNames[CardNames.LITTER] = new TranslatedCardName("Litter", "Litters", "a Litter");
SpanishCardNames[CardNames.RICE_BROKER] = new TranslatedCardName("Rice Broker", "Rice Brokers", "a Rice Broker");
SpanishCardNames[CardNames.RONIN] = new TranslatedCardName("Ronin", "Ronins", "a Ronin");
SpanishCardNames[CardNames.TANUKI] = new TranslatedCardName("Tanuki", "Tanukis", "a Tanuki");
SpanishCardNames[CardNames.TEA_HOUSE] = new TranslatedCardName("Tea House", "Tea Houses", "a Tea House");
SpanishCardNames[CardNames.SAMURAI] = new TranslatedCardName("Samurai", "Samurais", "a Samurai");
SpanishCardNames[CardNames.RICE] = new TranslatedCardName("Rice", "Rices", "a Rice");
SpanishCardNames[CardNames.MOUNTAIN_SHRINE] = new TranslatedCardName("Mountain Shrine", "Mountain Shrines", "a Mountain Shrine");
SpanishCardNames[CardNames.DAIMYO] = new TranslatedCardName("Daimyo", "Daimyos", "a Daimyo");
SpanishCardNames[CardNames.ARTIST] = new TranslatedCardName("Artist", "Artists", "an Artist");
SpanishCardNames[CardNames.APPROACHING_ARMY] = new TranslatedCardName("Approaching Army");
SpanishCardNames[CardNames.BIDING_TIME] = new TranslatedCardName("Biding Time");
SpanishCardNames[CardNames.BUREAUCRACY] = new TranslatedCardName("Bureaucracy");
SpanishCardNames[CardNames.DIVINE_WIND] = new TranslatedCardName("Divine Wind");
SpanishCardNames[CardNames.ENLIGHTENMENT] = new TranslatedCardName("Enlightenment");
SpanishCardNames[CardNames.FLOURISHING_TRADE] = new TranslatedCardName("Flourishing Trade");
SpanishCardNames[CardNames.GOOD_HARVEST] = new TranslatedCardName("Good Harvest");
SpanishCardNames[CardNames.GREAT_LEADER] = new TranslatedCardName("Great Leader");
SpanishCardNames[CardNames.GROWTH] = new TranslatedCardName("Growth");
SpanishCardNames[CardNames.HARSH_WINTER] = new TranslatedCardName("Harsh Winter");
SpanishCardNames[CardNames.KIND_EMPEROR] = new TranslatedCardName("Kind Emperor");
SpanishCardNames[CardNames.PANIC] = new TranslatedCardName("Panic");
SpanishCardNames[CardNames.PROGRESS] = new TranslatedCardName("Progress");
SpanishCardNames[CardNames.RAPID_EXPANSION] = new TranslatedCardName("Rapid Expansion");
SpanishCardNames[CardNames.SICKNESS] = new TranslatedCardName("Sickness");
SpanishCardNames[CardNames.AMASS] = new TranslatedCardName("Amass");
SpanishCardNames[CardNames.ASCETICISM] = new TranslatedCardName("Asceticism");
SpanishCardNames[CardNames.CREDIT] = new TranslatedCardName("Credit");
SpanishCardNames[CardNames.FORESIGHT] = new TranslatedCardName("Foresight");
SpanishCardNames[CardNames.KINTSUGI] = new TranslatedCardName("Kintsugi");
SpanishCardNames[CardNames.PRACTICE] = new TranslatedCardName("Practice");
SpanishCardNames[CardNames.SEA_TRADE] = new TranslatedCardName("Sea Trade");
SpanishCardNames[CardNames.RECEIVE_TRIBUTE] = new TranslatedCardName("Receive Tribute");
SpanishCardNames[CardNames.GATHER] = new TranslatedCardName("Gather");
SpanishCardNames[CardNames.CONTINUE] = new TranslatedCardName("Continue");