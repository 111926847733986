"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var HandZone = function (_Zone) {
    _inherits(HandZone, _Zone);

    function HandZone(state, index, pileName, owner, createdBy, attachedTrait) {
        _classCallCheck(this, HandZone);

        var _this = _possibleConstructorReturn(this, (HandZone.__proto__ || Object.getPrototypeOf(HandZone)).call(this, state, index, pileName, owner, createdBy, attachedTrait));

        _this.leadStack = null;
        return _this;
    }

    _createClass(HandZone, [{
        key: "reposition",
        value: function reposition() {
            var _this2 = this;

            var shouldAnimate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            var comparator = function comparator(a, b) {
                if (a.topCard.cardName !== b.topCard.cardName) {
                    if (a.topCard.cardName === CardNames.BACK) return 1;
                    if (b.topCard.cardName === CardNames.BACK) return -1;
                    return stackCardNameSorter(a.topCard.cardName, b.topCard.cardName);
                }
                if (a === _this2.leadStack) return -1;
                if (b === _this2.leadStack) return 1;
                return 0;
            };
            this.cardStacks.sort(comparator);

            var vh = 0.01 * window.innerHeight;
            var vw = 0.01 * window.innerWidth;

            var h = void 0,
                margin = void 0,
                left = void 0,
                right = void 0,
                top = void 0,
                zIndex = void 0;
            var hasAnonymousStacks = this.cardStacks.some(function (s) {
                return s.topCard.cardName === CardNames.BACK;
            });

            if (this.owner.isHero) {
                h = (this.layoutModel.isSpec ? SPEC_HAND_HEIGHT : HERO_HAND_HEIGHT) * vh;
                margin = hasAnonymousStacks ? -8 * vh : 3 * vh;
                left = (LEFT_COLUMN_WIDTH + 1) * vw;
                right = (99 - RIGHT_COLUMN_WIDTH) * vw;
                top = 99 * vh - h;
                zIndex = 2000;
            } else {
                h = (SPEC_HAND_HEIGHT - (this.layoutModel.isSpec ? 2 : 0)) * vh;
                margin = this.layoutModel.isSpec && !hasAnonymousStacks ? 2 * vh : -8 * vh;
                left = (2 + LEFT_COLUMN_WIDTH) * vw + 36 * vh / CardSizes.FULL.ratio;
                right = (99 - RIGHT_COLUMN_WIDTH) * vw;
                top = this.layoutModel.isSpec ? 3.5 * vh : -12 * vh;
                zIndex = 990;
            }

            this.cardStacks.forEach(function (cardStack, i) {
                if (_this2.owner.isActiveOpponent) {
                    cardStack.removeFilter("zone-hidden");
                    if (!_this2.layoutModel.isSpec) cardStack.addFilter("inverted-card");
                } else {
                    cardStack.removeFilter("inverted-card");
                    if (_this2.owner.isHero) {
                        cardStack.removeFilter("zone-hidden");
                    } else {
                        cardStack.addFilter("zone-hidden");
                    }
                }
                if (_this2.owner.isMe) cardStack.addFilter("always-show-way");else cardStack.removeFilter("always-show-way");

                var zIndex = void 0;
                if (_this2.owner.isHero) {
                    if (cardStack.topCard.cardName === CardNames.BACK) {
                        zIndex = 2000 - i * 2;
                    } else {
                        zIndex = 2000 + i;
                    }
                } else {
                    if (cardStack.topCard.cardName === CardNames.BACK || !_this2.layoutModel.isSpec) zIndex = 990 - i * 2;else zIndex = 990 + i * 2;
                }

                cardStack.linearlyPosition(_this2.cardStacks.length, i, h, margin, left, right, top, zIndex, true, shouldAnimate);
            });
        }
    }, {
        key: "initialize",
        value: function initialize(cardIds) {
            var _this3 = this;

            cardIds.forEach(function (id) {
                if (id === -1) {
                    var targetStack = new CardStack(_this3.state.getAnonCard(CardNames.BACK), _this3);
                    if (_this3.leadStack === null) _this3.leadStack = targetStack;
                    _this3.leadStack.addAnonymousCard();
                } else {
                    var targetName = _this3.state.cards[id].cardName;
                    var _targetStack = _this3.cardStacks.find(function (s) {
                        return s.topCard.cardName === targetName;
                    });
                    if (isUndefined(_targetStack)) {
                        _targetStack = new CardStack(_this3.state.getAnonCard(targetName), _this3);
                    }
                    _targetStack.addCard(_this3.state.cards[id]);
                }
            });
            this.reposition();
        }
    }, {
        key: "addCard",
        value: function addCard(card) {
            var targetStack = this.cardStacks.find(function (s) {
                return s.topCard.cardName === card.cardName;
            });
            if (isUndefined(targetStack)) {
                targetStack = new CardStack(this.state.getAnonCard(card.cardName), this);
                targetStack.addFilter("intangible");
            }
            this.reposition(true);
            return targetStack;
        }
    }, {
        key: "addAnonymousCard",
        value: function addAnonymousCard() {
            var newStack = new CardStack(this.state.getAnonCard(CardNames.BACK), this);
            if (this.leadStack === null) this.leadStack = newStack;
            newStack.addFilter("intangible");
            this.reposition(true);
            return newStack;
        }
    }, {
        key: "mergeStacks",
        value: function mergeStacks(targetStack, transitionStack) {
            if (targetStack.topCard.cardName === CardNames.BACK) {
                this.leadStack.anonymousCards += transitionStack.cards.length + transitionStack.anonymousCards;
            } else {
                transitionStack.cards.forEach(function (c) {
                    return targetStack.addCard(c);
                });
                targetStack.anonymousCards += transitionStack.anonymousCards;
            }
            transitionStack.destroy(targetStack);
            targetStack.removeFilter("intangible");
            this.reposition();
        }
    }, {
        key: "spliceCard",
        value: function spliceCard(card) {
            var newStack = _get(HandZone.prototype.__proto__ || Object.getPrototypeOf(HandZone.prototype), "spliceCard", this).call(this, card);
            if (this.owner.isActiveOpponent && !this.layoutModel.isSpec) {
                newStack.addFilter("inverted-card");
            }
            return newStack;
        }
    }, {
        key: "spliceAnonymousCard",
        value: function spliceAnonymousCard() {
            var _this4 = this;

            var shouldAnonymize = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

            if (shouldAnonymize) {
                [].concat(_toConsumableArray(this.cardStacks)).forEach(function (s) {
                    if (s.cards.length > 0) {
                        var _targetStack2 = void 0;
                        for (var i = 0; i < s.cards.length; i++) {
                            var _targetStack3 = new CardStack(_this4.state.getAnonCard(CardNames.BACK), _this4);
                            if (_this4.leadStack === null) _this4.leadStack = _targetStack3;
                            _this4.leadStack.addAnonymousCard();
                        }
                        s.destroy(_targetStack2);
                    }
                });
            }

            var backStacks = this.cardStacks.filter(function (s) {
                return s.topCard.cardName === CardNames.BACK;
            });
            var targetStack = backStacks.length > 1 ? backStacks.find(function (s) {
                return s !== _this4.leadStack;
            }) : this.leadStack;
            if (!isUndefined(targetStack) && targetStack !== null) {
                this.leadStack.anonymousCards--;
                var newStack = new CardStack(this.state.getAnonCard(CardNames.BACK), this.state.nowhere);
                newStack.anonymousCards++;
                newStack.copyPosition(targetStack);
                if (targetStack.trackedAnimators.length === 0) {
                    if (targetStack === this.leadStack) this.leadStack = null;
                    targetStack.destroy();
                } else {
                    targetStack.addFilter("intangible");
                }
                if (this.owner.isActiveOpponent && !this.layoutModel.isSpec) {
                    newStack.addFilter("inverted-card");
                }
                this.reposition(true);
                return newStack;
            } else {
                console.error("failed to find card to remove");
                return null;
            }
        }
    }, {
        key: "getScript",
        value: function getScript(fromZone, stack, targetStack, props) {
            if (props.isInstant) return [];

            var width = targetStack.view.bbox.width;
            var height = targetStack.view.bbox.height;
            if (this.owner.isHero) {
                return [new Keyframe(0, new StackTarget(new Rectangle(-0.2 * width, 0.4 * height, 0, 0, 10, 0, -1)), Curves.SMOOTH), new Keyframe(props.speed, new StackTarget(), Curves.SMOOTH)];
            } else {
                return [new Keyframe(0, new StackTarget(new Rectangle(-0.2 * width, -0.4 * height, 0, 0, 10, 0, -1)), Curves.SMOOTH), new Keyframe(props.speed, new StackTarget(), Curves.SMOOTH), new Keyframe(props.delay * 0.2, new StackTarget(), Curves.SMOOTH)];
            }
        }
    }, {
        key: "zoneName",
        get: function get() {
            return "HandZone";
        }
    }, {
        key: "isOwnershipDependent",
        get: function get() {
            return true;
        }
    }]);

    return HandZone;
}(Zone);