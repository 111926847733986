"use strict";

webclient.service('gameSoundService', ['$rootScope', 'tableService', 'soundService', function ($rootScope, tableService, soundService) {
    var self = this;
    var scope = $rootScope.$new(true);
    var lastClickTime = Date.now();
    self.hasAnsweredQuestion = false;

    var flashTab = function flashTab() {
        return flashTitle(getPhrase(Phrases.YOUR_TURN));
    };

    function shouldNotify() {
        return Date.now() > lastClickTime + 1000 && self.hasAnsweredQuestion;
    }

    self.notify = function () {
        if (!shouldNotify()) return;
        if (!document.hasFocus()) flashTab();
        soundService.play(SOUNDS.YOUR_TURN);
        self.hasAnsweredQuestion = false;
    };

    window.onfocus = function () {
        return cancelFlashTitle();
    };
    document.onmousedown = function () {
        lastClickTime = Date.now();
    };

    self.newQuestion = function () {
        if (shouldNotify()) self.notify();
        lastClickTime = Date.now();
    };

    scope.$on(Events.UNDO_REQUEST_CANCELLED, function () {
        return cancelFlashTitle();
    });

    self.ping = function () {
        soundService.play(SOUNDS.PING);
    };
}]);