"use strict";

var EnglishLogEntries = {};

EnglishLogEntries[LogEntryNames.GAIN] = "Argument0 gains Argument1.";
EnglishLogEntries[LogEntryNames.GAIN_WITH] = "Argument0 gains Argument1. (Argument2)";
EnglishLogEntries[LogEntryNames.BUY] = "Argument0 buys Argument1.";
EnglishLogEntries[LogEntryNames.BUY_AND_GAIN] = "Argument0 buys and gains Argument1.";
EnglishLogEntries[LogEntryNames.TRASH] = "Argument0 trashes Argument1.";
EnglishLogEntries[LogEntryNames.DISCARD] = "Argument0 discards Argument1.";
EnglishLogEntries[LogEntryNames.DISCARD_WITH] = "Argument0 discards Argument1. (Argument2)";
EnglishLogEntries[LogEntryNames.PLAY] = "Argument0 plays Argument1.";
EnglishLogEntries[LogEntryNames.PLAY_AGAIN] = "Argument0 plays Argument1 again.";
EnglishLogEntries[LogEntryNames.PLAY_THIRD] = "Argument0 plays Argument1 a third time.";
EnglishLogEntries[LogEntryNames.COUNTER_ADD] = "COUNTER_ADD";
EnglishLogEntries[LogEntryNames.COUNTER_RESET] = "COUNTER_RESET";
EnglishLogEntries[LogEntryNames.TOPDECK] = "Argument0 topdecks Argument1.";
EnglishLogEntries[LogEntryNames.DRAW] = "Argument0 draws Argument1.";
EnglishLogEntries[LogEntryNames.REVEAL] = "Argument0 reveals Argument1.";
EnglishLogEntries[LogEntryNames.REVEAL_SEARCHING] = "Argument0 reveals Argument1.";
EnglishLogEntries[LogEntryNames.REVEAL_SEARCHING_AND_FINDING] = "Argument0 reveals Argument1 and finally Argument2.";
EnglishLogEntries[LogEntryNames.REVEAL_FINDING] = "Argument0 reveals Argument1.";
EnglishLogEntries[LogEntryNames.REVEAL_HAND] = "Argument0 reveals their hand: Argument1.";
EnglishLogEntries[LogEntryNames.REVEAL_DISCARD] = "Argument0 reveals their discard: Argument1.";
EnglishLogEntries[LogEntryNames.LOOK_AT] = "Argument0 looks at Argument1.";
EnglishLogEntries[LogEntryNames.PUT_IN_HAND] = "Argument0 puts Argument1 into their hand.";
EnglishLogEntries[LogEntryNames.SET_ASIDE] = "Argument0 sets Argument1 aside.";
EnglishLogEntries[LogEntryNames.PUT_ON_MAT] = "Argument0 puts Argument1 on their Argument2.";
EnglishLogEntries[LogEntryNames.CALL] = "Argument0 calls Argument1.";
EnglishLogEntries[LogEntryNames.DECK_TO_DISCARD] = "Argument0 moves their deck to the discard.";
EnglishLogEntries[LogEntryNames.BACK_ON_DECK] = "Argument0 puts Argument1 back onto their deck.";
EnglishLogEntries[LogEntryNames.SHUFFLE_INTO_DECK] = "Argument0 shuffles Argument1 into their deck.";
EnglishLogEntries[LogEntryNames.INSERT_IN_DECK] = "Argument0 inserts Argument1 into their deck.";
EnglishLogEntries[LogEntryNames.PASS] = "Argument0 passes Argument1 to Argument2.";
EnglishLogEntries[LogEntryNames.STARTS_WITH] = "Argument0 starts with Argument1.";
EnglishLogEntries[LogEntryNames.REACTS_WITH] = "Argument0 reacts with Argument1.";
EnglishLogEntries[LogEntryNames.SHUFFLES] = "Argument0 shuffles their deck.";
EnglishLogEntries[LogEntryNames.NEW_TURN] = "Argument0";
EnglishLogEntries[LogEntryNames.WISH_CORRECT] = "Argument0 wishes for Argument1 and finds it.";
EnglishLogEntries[LogEntryNames.WISH_WRONG] = "Argument0 wishes for Argument1 but reveals Argument2.";
EnglishLogEntries[LogEntryNames.BLACK_MARKET_RESHUFFLE] = "Reshuffling the Black Market deck.";
EnglishLogEntries[LogEntryNames.RETURN_TO] = "Argument0 returns Argument1 to Argument2.";

EnglishLogEntries[LogEntryNames.PUT_ON_BOTTOM_OF] = "Argument0 puts Argument1 on the bottom of Argument2.";
EnglishLogEntries[LogEntryNames.LOSE_TRACK_MOVED] = "Argument0 can no longer move Argument1 (it already moved).";
EnglishLogEntries[LogEntryNames.LOSE_TRACK_HIDDEN] = "Argument0 can no longer move Argument1 (it was covered up).";
EnglishLogEntries[LogEntryNames.LOSE_TRACK_RESHUFFLED] = "Argument0 can no longer move Argument1 (it was shuffled).";
EnglishLogEntries[LogEntryNames.LOSE_TRACK_ANONYMOUS] = "Argument0 can no longer move.";

EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_BANDIT_CAMP] = "Argument0.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_TOWER] = "Argument0.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH_ABSENT] = "No differently named Action cards.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH] = "Argument0.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_WALL] = "Argument0.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_WOLF_DEN] = "exactly one of Argument0.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_PALACE] = "Argument0.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_ORCHARD] = "Argument0.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_OBELISK] = "Argument0 cards from the Argument1-pile.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_MUSEUM] = "Argument0 differently named cards.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_KINGS_CASTLE] = "Argument0 Castles.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_KEEP] = "Argument0.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_HUMBLE_CASTLE] = "Argument0 Castles.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_FOUNTAIN] = "Argument0.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_FEODUM] = "Argument0.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_GARDENS] = "Argument0.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_VINEYARD] = "Argument0 Action cards.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_SILK_ROAD] = "Argument0 Victory cards.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_FAIRGROUNDS] = "Argument0 differently named cards.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_DUKE] = "Argument0.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_DUH] = "";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_VP_TOKENS] = "VP tokens.";

EnglishLogEntries[LogEntryNames.PUT_IN_HAND_FROM] = "Argument0 puts Argument1 in hand (Argument2).";
EnglishLogEntries[LogEntryNames.STARTS_TURN] = "Argument0 starts their turn.";

EnglishLogEntries[LogEntryNames.TAKE_VP_FROM] = "Argument0 takes Argument1 VP from Argument2.";
EnglishLogEntries[LogEntryNames.MOVE_VP_FROM_TO] = "Argument0 moves Argument1 VP from Argument2 to Argument3.";
EnglishLogEntries[LogEntryNames.OBELISK_CHOOSES_PILE] = "Obelisk chooses Argument0.";
EnglishLogEntries[LogEntryNames.PLAYER_MOVES_TOKEN_TO] = "Argument0 moves Argument1 to Argument2.";
EnglishLogEntries[LogEntryNames.PLAYER_GETS_VP] = "Argument0 gets Argument1 VP.";
EnglishLogEntries[LogEntryNames.PLAYER_GETS_VP_FROM] = "Argument0 gets Argument1 VP from Argument2.";
EnglishLogEntries[LogEntryNames.ADDS_VP_TO] = "Argument0 adds Argument1 VP to Argument2.";
EnglishLogEntries[LogEntryNames.ALMS_FAIL] = "Argument0 buys Alms but has Argument1 in play.";
EnglishLogEntries[LogEntryNames.BORROW_FAIL] = "Argument0 buys Borrow but already had Argument1";
EnglishLogEntries[LogEntryNames.QUEST_FAIL_ATTACK] = "Argument0 failed to discard an Attack.";
EnglishLogEntries[LogEntryNames.QUEST_FAIL_CURSES] = "Argument0 failed to discard Argument1.";
EnglishLogEntries[LogEntryNames.QUEST_FAIL_DISCARD] = "Argument0 failed to discard 6 cards.";
EnglishLogEntries[LogEntryNames.SAVE_FAIL] = "Argument0 had no cards to set aside.";
EnglishLogEntries[LogEntryNames.SCOUTING_PARTY_FAIL] = "Argument0 had no cards to discard or topdeck.";
EnglishLogEntries[LogEntryNames.BONFIRE_FAIL] = "Argument0 trashes nothing.";
EnglishLogEntries[LogEntryNames.MISSION_SUCCESS] = "Argument0 takes an extra turn after this one.";
EnglishLogEntries[LogEntryNames.TRADE_FAIL] = "Argument0 trashes nothing.";
EnglishLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_DOWN] = "Argument0 flips Argument1 face down.";
EnglishLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_UP] = "Argument0 flips Argument1 face up.";

EnglishLogEntries[LogEntryNames.TRIUMPH_FAIL] = "Argument0 failed to gain Argument1.";
EnglishLogEntries[LogEntryNames.ADVANCE_FAIL] = "Argument0 didn't trash an Action card.";
EnglishLogEntries[LogEntryNames.TAX_ADD_DEBT] = "Argument0 adds Argument1 to Argument2.";
EnglishLogEntries[LogEntryNames.TAX_TAKE_DEBT] = "Argument0 takes Argument1 from Argument2.";
EnglishLogEntries[LogEntryNames.RITUAL_FAIL] = "Argument0 failed to gain Argument1.";
EnglishLogEntries[LogEntryNames.WINDFALL_FAIL] = "Argument0 isn't empty.";
EnglishLogEntries[LogEntryNames.DOMINATE_FAIL] = "Argument0 failed to gain Argument1.";

EnglishLogEntries[LogEntryNames.PLUS_ACTION_FROM] = "Argument0 gets +1 Action (from Argument1).";
EnglishLogEntries[LogEntryNames.PLUS_BUY_FROM] = "Argument0 gets +1 Buy (from Argument1).";
EnglishLogEntries[LogEntryNames.DRAWS_FROM] = "Argument0 draws Argument1 (from Argument2).";
EnglishLogEntries[LogEntryNames.PLUS_COIN_FROM] = "Argument0 gets +1 Coin (from Argument1).";
EnglishLogEntries[LogEntryNames.DRAWS_LESS_BECAUSE] = "Argument0 skips a draw (because of Argument1).";

EnglishLogEntries[LogEntryNames.WAITING_FOR] = "Waiting for Argument0.";
EnglishLogEntries[LogEntryNames.EXCHANGE_RETURN] = "Argument0 returns Argument1.";
EnglishLogEntries[LogEntryNames.EXCHANGE_RECEIVE] = "Argument0 receives Argument1.";

EnglishLogEntries[LogEntryNames.PRINCE_FAILS] = "";
EnglishLogEntries[LogEntryNames.POSSESSION_TAKES_COIN_TOKENS] = "Argument0 takes the coin tokens instead.";
EnglishLogEntries[LogEntryNames.POSSESSION_TAKES_DEBT_TOKENS] = "Argument0 takes the Debt instead.";
EnglishLogEntries[LogEntryNames.POSSESION_TAKES_VP_TOKENS] = "Argument0 takes the VP tokens instead.";

EnglishLogEntries[LogEntryNames.TAKES_DEBT] = "Argument0 takes Argument1 debt.";
EnglishLogEntries[LogEntryNames.REPAYS_DEBT] = "Argument0 repays Argument1 debt.";
EnglishLogEntries[LogEntryNames.REPAYS_SOME_DEBT] = "Argument0 repays Argument1 debt (Argument2 remaining).";
EnglishLogEntries[LogEntryNames.RETURNS_TOKEN] = "Argument0 returns Argument1 set by Argument2.";

EnglishLogEntries[LogEntryNames.SET_ASIDE_WITH] = "Argument0 sets Argument1 aside with Argument2.";
EnglishLogEntries[LogEntryNames.BLOCKS_WITH] = "Argument0 blocks with Argument1.";
EnglishLogEntries[LogEntryNames.NAMES] = "Argument0 names Argument1.";
EnglishLogEntries[LogEntryNames.GAIN_ON_DRAWPILE] = "Argument0 gains Argument1 onto their drawpile.";
EnglishLogEntries[LogEntryNames.GAIN_FROM_TRASH] = "Argument0 gains Argument1 from trash.";
EnglishLogEntries[LogEntryNames.OBELISK_FAILS] = "Obelisk failed to select an Action Supply pile.";
EnglishLogEntries[LogEntryNames.INHERITS] = "Argument0 inherits Argument1.";
EnglishLogEntries[LogEntryNames.GAME_META_INFO] = "Argument0";

EnglishLogEntries[LogEntryNames.THE_SKYS_GIFT_FAIL] = "Argument0 fails to discard for " + getColoredEnglishCardName(CardNames.THE_SKYS_GIFT);
EnglishLogEntries[LogEntryNames.RECEIVES] = "Argument0 receives Argument1.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_PASTURE] = "Argument0.";
EnglishLogEntries[LogEntryNames.TAKE] = "Argument0 takes Argument1.";
EnglishLogEntries[LogEntryNames.RETURN] = "Argument0 returns Argument1.";
EnglishLogEntries[LogEntryNames.THE_SUNS_GIFT_FAIL] = getColoredEnglishCardName(CardNames.THE_SUNS_GIFT) + " has nothing to discard.";
EnglishLogEntries[LogEntryNames.DRUID_SELECTS_BOONS] = getColoredEnglishCardName(CardNames.DRUID) + " sets Argument1 aside.";
EnglishLogEntries[LogEntryNames.DISCARD_RECEIVABLE] = "Argument0 discards Argument1.";
EnglishLogEntries[LogEntryNames.PLAY_ENCHANTED] = "Argument0 is enchanted by Argument1";

EnglishLogEntries[LogEntryNames.GAIN_ANOTHER_EXPERIMENT] = "Argument0 gains another " + getColoredEnglishCardName(CardNames.EXPERIMENT);
EnglishLogEntries[LogEntryNames.COFFERS_FROM_PATRON] = "Argument0 gets a Coffers from " + getColoredEnglishCardName(CardNames.PATRON) + ".";
EnglishLogEntries[LogEntryNames.GETS_COFFER] = "Argument0 gets +Argument1 Coffers.";
EnglishLogEntries[LogEntryNames.GETS_COFFERS] = "Argument0 gets +Argument1 Coffers.";
EnglishLogEntries[LogEntryNames.GETS_COFFER_FROM] = "Argument0 gets +Argument1 Coffers. (Argument2)";
EnglishLogEntries[LogEntryNames.GETS_COFFERS_FROM] = "Argument0 gets +Argument1 Coffers. (Argument2)";
EnglishLogEntries[LogEntryNames.USES_COFFER] = "Argument0 uses Argument1 Coffers. (+$Argument1)";
EnglishLogEntries[LogEntryNames.USES_COFFERS] = "Argument0 uses Argument1 Coffers. (+$Argument1)";
EnglishLogEntries[LogEntryNames.GETS_VILLAGER_FROM] = "Argument0 gets +Argument1 Villager. (Argument2)";
EnglishLogEntries[LogEntryNames.GETS_VILLAGERS_FROM] = "Argument0 gets +Argument1 Villagers. (Argument2)";
EnglishLogEntries[LogEntryNames.GETS_VILLAGER] = "Argument0 gets +Argument1 Villager.";
EnglishLogEntries[LogEntryNames.GETS_VILLAGERS] = "Argument0 gets +Argument1 Villagers.";
EnglishLogEntries[LogEntryNames.USES_VILLAGER] = "Argument0 uses Argument1 Villager.";
EnglishLogEntries[LogEntryNames.USES_VILLAGERS] = "Argument0 uses Argument1 Villagers.";
EnglishLogEntries[LogEntryNames.BEGINS_BUYPHASE] = "Argument0 begins their buy phase.";
EnglishLogEntries[LogEntryNames.ENDS_BUYPHASE] = "Argument0 ends their buy phase.";
EnglishLogEntries[LogEntryNames.GETS_ACTION] = "Argument0 gets +Argument1 Action.";
EnglishLogEntries[LogEntryNames.GETS_ACTIONS] = "Argument0 gets +Argument1 Actions.";
EnglishLogEntries[LogEntryNames.GETS_ACTION_FROM] = "Argument0 gets +Argument1 Action. (Argument2)";
EnglishLogEntries[LogEntryNames.GETS_ACTIONS_FROM] = "Argument0 gets +Argument1 Actions. (Argument2)";
EnglishLogEntries[LogEntryNames.GETS_BUY] = "Argument0 gets +Argument1 Buy.";
EnglishLogEntries[LogEntryNames.GETS_BUYS] = "Argument0 gets +Argument1 Buys.";
EnglishLogEntries[LogEntryNames.GETS_BUY_FROM] = "Argument0 gets +Argument1 Buy. (Argument2)";
EnglishLogEntries[LogEntryNames.GETS_BUYS_FROM] = "Argument0 gets +Argument1 Buys. (Argument2)";
EnglishLogEntries[LogEntryNames.GETS_COIN] = "Argument0 gets +$Argument1.";
EnglishLogEntries[LogEntryNames.GETS_COINS] = "Argument0 gets +$Argument1.";
EnglishLogEntries[LogEntryNames.GETS_COIN_FROM] = "Argument0 gets +$Argument1. (Argument2)";
EnglishLogEntries[LogEntryNames.GETS_COINS_FROM] = "Argument0 gets +$Argument1. (Argument2)";
EnglishLogEntries[LogEntryNames.DRAW_FROM] = "Argument0 draws Argument1. (Argument2)";
EnglishLogEntries[LogEntryNames.LOSES_COIN] = "Argument0 loses Argument1 Coin.";
EnglishLogEntries[LogEntryNames.LOSES_COINS] = "Argument0 loses Argument1 Coins.";
EnglishLogEntries[LogEntryNames.BOTTOMDECKS] = "Argument0 puts Argument1 on the bottom of their deck.";
EnglishLogEntries[LogEntryNames.TRASH_WITH] = "Argument0 trashes Argument1. (Argument2)";
EnglishLogEntries[LogEntryNames.PRIEST_ACTIVATES] = "from here on, when you trash a card, +$Argument0.";
EnglishLogEntries[LogEntryNames.PLAY_TREASURES_FOR] = EnglishLogEntries[LogEntryNames.PLAY] + " (+$Argument2)";
EnglishLogEntries[LogEntryNames.PLAY_TREASURES_AGAIN_FOR] = EnglishLogEntries[LogEntryNames.PLAY_AGAIN] + " (+$Argument2)";
EnglishLogEntries[LogEntryNames.PLAY_TREASURES_THIRD_FOR] = EnglishLogEntries[LogEntryNames.PLAY_THIRD] + " (+$Argument2)";
EnglishLogEntries[LogEntryNames.RETURNS_MINUS_COIN_TOKEN] = EnglishLogEntries[LogEntryNames.RETURNS_TOKEN] + " (-$1)";
EnglishLogEntries[LogEntryNames.NOT_IN_PLAY_PREVENTED_PLAYING] = "Argument0 was not in play, so it could not be played.";
EnglishLogEntries[LogEntryNames.PILLAGE_FAIL] = getColoredEnglishCardName(CardNames.PILLAGE) + " wasn't trashed, so does nothing.";
EnglishLogEntries[LogEntryNames.DEATH_CART_FAIL] = getColoredEnglishCardName(CardNames.DEATH_CART) + " didn't trash, so does nothing.";
EnglishLogEntries[LogEntryNames.TACTICIAN_FAIL] = getColoredEnglishCardName(CardNames.TACTICIAN) + " didn't discard, so does nothing.";
EnglishLogEntries[LogEntryNames.EMBARGO_FAIL] = getColoredEnglishCardName(CardNames.EMBARGO) + " wasn't trashed, so does nothing.";
EnglishLogEntries[LogEntryNames.EXILE] = "Argument0 exiles Argument1.";
EnglishLogEntries[LogEntryNames.DISCARD_FROM_EXILE] = "Argument0 discards Argument1 from Exile.";
EnglishLogEntries[LogEntryNames.DISCARD_REVEALED] = "Argument0 discards and reveals Argument1.";
EnglishLogEntries[LogEntryNames.PLAY_WITH] = EnglishLogEntries[LogEntryNames.PLAY] + " (Argument2)";
EnglishLogEntries[LogEntryNames.PLAY_AGAIN_WITH] = EnglishLogEntries[LogEntryNames.PLAY] + " (Argument2)";
EnglishLogEntries[LogEntryNames.PLAY_THIRD_WITH] = EnglishLogEntries[LogEntryNames.PLAY] + " (Argument2)";
EnglishLogEntries[LogEntryNames.IS_INVESTED] = "Argument0 draws Argument2 cards when an opponent gains or invests in Argument1.";
EnglishLogEntries[LogEntryNames.COMMERCE_FAIL] = "Argument0 had not gained any cards.";
EnglishLogEntries[LogEntryNames.STAMPEDE_FAIL] = "Argument0 bought " + getColoredEnglishCardName(CardNames.STAMPEDE) + " with Argument1 cards in play.";
EnglishLogEntries[LogEntryNames.PLAY_USING_WAY] = "Argument0 plays Argument1 using Argument2.";
EnglishLogEntries[LogEntryNames.WAY_OF_THE_HORSE_FAILS_NO_PILE] = "Argument0 has no pile to return to.";
EnglishLogEntries[LogEntryNames.WAY_OF_THE_HORSE_FAILS_NOT_IN_PLAY] = getColoredEnglishCardName(CardNames.WAY_OF_THE_HORSE) + " fails to move Argument0, because it's not in play.";
EnglishLogEntries[LogEntryNames.WAY_OF_THE_TURTLE_FAILS_TO_SET_ASIDE] = getColoredEnglishCardName(CardNames.WAY_OF_THE_TURTLE) + " fails to set aside Argument0.";
EnglishLogEntries[LogEntryNames.USES_WAY] = "Argument0 uses Argument1.";
EnglishLogEntries[LogEntryNames.WOULD_GET_COIN] = "Argument0 would get +$Argument1.";
EnglishLogEntries[LogEntryNames.WOULD_DRAW] = "Argument0 would draw Argument1.";
EnglishLogEntries[LogEntryNames.TOPDECK_WITH] = "Argument0 topdecks Argument1 with Argument2.";
EnglishLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_NO_PILE] = "Argument0 has no pile to return to.";
EnglishLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_NOT_IN_PLAY] = getColoredEnglishCardName(CardNames.WAY_OF_THE_BUTTERFLY) + " fails to move Argument0, because it's not in play.";
EnglishLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_INTENTIONAL] = "Argument0 decided not to return Argument1 to its pile.";
EnglishLogEntries[LogEntryNames.PLAYER_BIDS] = "Argument0 bids Argument1 debt";
EnglishLogEntries[LogEntryNames.GAIN_SETTING_ASIDE] = "Argument0 gains Argument1 setting it aside.";
EnglishLogEntries[LogEntryNames.INVESTMENT_FAIL] = getColoredEnglishCardName(CardNames.INVESTMENT) + " can't trash itself because it's not in play.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_MARCHLAND] = "Argument0 Victory cards.";
EnglishLogEntries[LogEntryNames.PLAY_NULLIFIED] = "Argument0 plays Argument1 (nullified by " + getColoredEnglishCardName(CardNames.HIGHWAYMAN) + ").";
EnglishLogEntries[LogEntryNames.PLAY_NULLIFIED] = "Argument0 plays Argument1 (nullified by " + getColoredEnglishCardName(CardNames.HIGHWAYMAN) + ").";
EnglishLogEntries[LogEntryNames.GETS_FAVOR] = "Argument0 gets +Argument1 Favor.";
EnglishLogEntries[LogEntryNames.GETS_FAVORS] = "Argument0 gets +Argument1 Favors.";
EnglishLogEntries[LogEntryNames.GETS_FAVOR_FROM] = "Argument0 gets +Argument1 Favor. (Argument2)";
EnglishLogEntries[LogEntryNames.GETS_FAVORS_FROM] = "Argument0 gets +Argument1 Favors. (Argument2)";
EnglishLogEntries[LogEntryNames.SPENDS_FAVOR] = "Argument0 spends Argument1 Favor.";
EnglishLogEntries[LogEntryNames.SPENDS_FAVORS] = "Argument0 spends Argument1 Favors.";
EnglishLogEntries[LogEntryNames.ROTATES_EMPTY_PILE] = "Argument0 rotates an empty pile";
EnglishLogEntries[LogEntryNames.ROTATES_IDENTICAL_PILE] = "Argument0 rotates a pile of identical cards.";
EnglishLogEntries[LogEntryNames.ROTATES] = "Argument0 rotates Argument1.";
EnglishLogEntries[LogEntryNames.ISLAND_FOLK] = "Argument0 takes an extra turn after this one.";
EnglishLogEntries[LogEntryNames.ROYAL_GALLEY_FAIL] = getColoredEnglishCardName(CardNames.ROYAL_GALLEY) + " fails to set aside.";
EnglishLogEntries[LogEntryNames.FAILS_TO_PLAY] = "Argument0 fails to play Argument1";
EnglishLogEntries[LogEntryNames.FAILS_TO_PLAY_WRONG_LOCATION] = "Argument0 fails to play Argument1 because it isn't in Argument2";
EnglishLogEntries[LogEntryNames.STRANDS_CARDS] = "Argument0 strands Argument1";
EnglishLogEntries[LogEntryNames.VOYAGE_SUCCESS] = "Argument0 takes an extra Voyage turn after this one.";
EnglishLogEntries[LogEntryNames.GARRISON_ADDS_TOKEN] = "Argument0 adds a token to " + getColoredEnglishCardName(CardNames.GARRISON) + ", now at Argument1.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_PLATEAU_SHEPHERDS] = "Argument0 Cards, Argument1 Favors.";
EnglishLogEntries[LogEntryNames.PUT_IN_DISCARD] = "Argument0 puts Argument1 into their discard pile.";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_TERRITORY] = "Argument0 differently named victory cards.";
EnglishLogEntries[LogEntryNames.SORCERESS_GUESS] = "Argument0 guesses Argument1.";
EnglishLogEntries[LogEntryNames.SORCERER_GUESS] = "Argument0 guesses Argument1.";
EnglishLogEntries[LogEntryNames.SKIPS_A_TURN_REMAINING] = "Argument0 skips a Turn (Argument1 remaining).";
EnglishLogEntries[LogEntryNames.SKIPS_A_TURN] = "Argument0 skips a Turn.";
EnglishLogEntries[LogEntryNames.CANCELS_A_TURN] = "Argument0 fails to take a Turn (from Argument1).";
EnglishLogEntries[LogEntryNames.WILL_SKIP_A_TURN] = "Argument0 will skip a Turn.";
EnglishLogEntries[LogEntryNames.WILL_SKIP_A_TURN_COUNT] = "Argument0 will skip a Turn (now Argument1).";
EnglishLogEntries[LogEntryNames.KEEPS_IN_HAND] = "Argument0 keeps Argument1 in hand.";
EnglishLogEntries[LogEntryNames.PLAYER_ADDS_FAVOR_TOKEN_TO] = "Argument0 places a Favor token on Argument1. ([Family of Inventors])";
EnglishLogEntries[LogEntryNames.BEGINS_CLEANUP_PHASE] = "Argument0 begins their Cleanup phase.";
EnglishLogEntries[LogEntryNames.PREMOVES] = "Argument0 premoves Argument1.";
EnglishLogEntries[LogEntryNames.SPELL_SCROLL_FAILS] = "[Spell Scroll] didn't trash, so does nothing.";
EnglishLogEntries[LogEntryNames.PERIL_FAIL] = "[Peril] didn't trash, so does nothing.";
EnglishLogEntries[LogEntryNames.ACQUIRES_TRAIT] = "The Argument1 Trait is acquired by Argument0.";
EnglishLogEntries[LogEntryNames.TRAIT_FAILS] = "Argument0 fails to select an Action or Treasure pile.";
EnglishLogEntries[LogEntryNames.SHUFFLES_CARDS] = "Argument0 shuffles Argument1.";
EnglishLogEntries[LogEntryNames.FARMHANDS_FAIL] = "[Farmhands] failed to set aside a card";
EnglishLogEntries[LogEntryNames.FARMHANDS_REFUSE] = "[Farmhands] didn't set aside a card";
EnglishLogEntries[LogEntryNames.EXPLAIN_POINTS_DEMESNE] = "Argument0 Golds.";
EnglishLogEntries[LogEntryNames.REACTS_PLAYING] = "Argument0 reacts with Argument1, playing it.";
EnglishLogEntries[LogEntryNames.PLAY_ENLIGHTENED] = "Argument0 plays Argument1 for +1 Card +1 Action";
EnglishLogEntries[LogEntryNames.PANIC_FAILS] = "[Panic] fails to return Argument0";
EnglishLogEntries[LogEntryNames.PANIC_FAILS_NO_PILE] = "[Panic] can't return Argument0 without a pile.";
EnglishLogEntries[LogEntryNames.AMASS_FAILS] = "Argument0 buys [Amass] but has Argument1 in play.";