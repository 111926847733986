"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var BorderLayer = function (_StackViewLayer) {
    _inherits(BorderLayer, _StackViewLayer);

    function BorderLayer(rootNode, cardStack) {
        _classCallCheck(this, BorderLayer);

        var _this = _possibleConstructorReturn(this, (BorderLayer.__proto__ || Object.getPrototypeOf(BorderLayer)).call(this, rootNode, cardStack));

        _this.node.style.zIndex = 11;
        _this.node.classList.add("border-layer");
        _this.isHovered = false;
        _this.isWayHovered = false;
        return _this;
    }

    _createClass(BorderLayer, [{
        key: "redraw",
        value: function redraw(w, h) {
            var card = this.cardStack.getClickableCard();
            var actionType = card === null ? Actions.NONE : card.question.type.actionType;
            var isSelected = card === null ? false : card.isSelected;
            var isUndo = card === null ? false : card.question.accumulatedAnswers.includes(card.answer);

            var r = Math.max(w, h) * 0.04;
            var m = r / 8;
            var color = void 0;
            if (this.isWayHovered) color = Actions.WAY.hoverColor;else if (this.isHovered) color = actionType.hoverColor;else if (isUndo) color = actionType.undoColor;else color = actionType.color;

            this.node.style.borderColor = color;
            this.node.style.borderWidth = r / 3 + "px";
            this.node.style.borderRadius = r + "px";
            assignPositionToNode(new Rectangle(0, 0, w - 2 * m - r / 3, h - 2 * m - r / 3), this.node);

            clearChildNodes(this.node);
            if (isSelected && actionType.crossURL !== null) {
                var crossNode = document.createElement("selection-cross");
                this.node.appendChild(crossNode);
                crossNode.style.backgroundImage = "url(images/elements/" + actionType.crossURL + ".png)";
                crossNode.style.filter = "hue-rotate(" + actionType.crossHue + ")";
                var sizeW = Math.min(w, 0.65 * h);
                var sizeH = 1.2 * sizeW;
                assignPositionToNode(new Rectangle((w - sizeW) / 2, (h - sizeH) / 2, sizeW, sizeH), crossNode);
            }
            if (this.cardStack.cardSize === CardSizes.MINI && actionType.isActive || actionType === Actions.BUY) {
                var plusNode = document.createElement("card-plus-sign");
                this.node.appendChild(plusNode);
                plusNode.style.backgroundImage = "url(images/elements/plus-sign.png)";
                var size = Math.min(0.2 * h, w * 0.2);
                assignPositionToNode(new Rectangle(w - size, h - size, size, size), plusNode);
            }
        }
    }]);

    return BorderLayer;
}(StackViewLayer);