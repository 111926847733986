"use strict";

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

webclient.component("pileContent", {
    bindings: {
        zone: '<'
    },
    controller: ["$scope", "game", function ($scope, game) {
        var _this = this;

        var self = this;

        self.shouldShow = self.zone.zoneName.isSupplyZone;
        var positionTokens = function positionTokens() {
            self.zone.tokens.forEach(function (t, i) {
                var vh = 0.01 * window.innerHeight;
                var sideLength = 3 * vh;

                t.bbox.x = computeX(self.zone.bbox.width * 0.1, self.zone.bbox.width * 0.9, sideLength, vh, i, self.zone.tokens.length);
                t.bbox.y = self.zone.bbox.height * 0.7;
                t.bbox.width = sideLength;
                t.bbox.height = sideLength;
                t.bbox.zIndex = self.zone.bbox.zIndex + 10;

                t.dirty = false;
            });
        };

        var positionCounters = function positionCounters() {
            var visibleCounters = self.zone.counters.filter(function (c) {
                return c.value > 0 || c.counterName.alwaysVisible;
            });
            visibleCounters.forEach(function (c, i) {
                var vh = 0.01 * window.innerHeight;
                var sideLength = 4 * vh;

                c.bbox.x = computeX(self.zone.bbox.width * 0.1, self.zone.bbox.width * 0.9, sideLength, 0.2 * vh, i, visibleCounters.length, false);
                c.bbox.y = self.zone.bbox.height * (self.zone.zoneName === ZoneNames.SINGLE_LANDSCAPE_ZONE ? 0.2 : 0.3);
                c.bbox.width = sideLength;
                c.bbox.height = sideLength;
                c.bbox.zIndex = self.zone.bbox.zIndex + 10;

                c.dirty = false;
            });
        };

        var getCounter = function getCounter(counter) {
            var myCounters = self.zone.counters.filter(function (c) {
                return c.counterName === counter;
            });
            if (myCounters.length === 0) return 0;
            return myCounters[0].value;
        };

        var positionCubes = function positionCubes() {
            self.cubeBBoxes = [];
            self.projectCubes.forEach(function (c, i) {
                var vh = 0.01 * window.innerHeight;
                var sideLength = 3 * vh;

                var newBBox = new Rectangle(computeX(self.zone.bbox.width * 0.1 + 0.5 * vh, self.zone.bbox.width * 0.9, sideLength, 1.2 * vh, i, self.projectCubes.length, false), self.zone.bbox.height * 0.45, sideLength, sideLength, self.zone.bbox.zIndex + 8);
                self.cubeBBoxes.push(newBBox);
            });
        };

        self.projectCubes = [];
        var updateCubes = function updateCubes() {
            var oldCubes = [].concat(_toConsumableArray(self.projectCubes));
            if (self.zone.cards.length > 0) self.projectCubes = game.playerService.players.filter(function (p) {
                return p.boughtProjects.includes(self.zone.cards[0].name);
            });else self.projectCubes = [];
            self.projectCubes.forEach(function (p) {
                if (!oldCubes.includes(p)) p.dirty = false;
            });
            positionCubes();
        };
        updateCubes();
        self.getCube = function (p) {
            return p.color.cubeImg;
        };

        var reposition = function reposition() {
            positionTokens();
            positionCounters();
            updateCubes();
        };

        reposition();
        $scope.$on(Events.REDRAW, function () {
            if (_this.zone.counters.some(function (c) {
                return c.dirty;
            }) || _this.zone.tokens.some(function (t) {
                return t.dirty;
            }) || _this.zone.dirty || players.players.some(function (p) {
                return p.dirty;
            })) {
                reposition();
                safeDigest($scope);
            }
        });
        $scope.$watch("$ctrl.zone.bbox", reposition, true);
    }],
    template: "\n        <player-token \n          ng-repeat=\"t in $ctrl.zone.tokens\"\n          token=\"t\">\n        </player-token>\n        <pile-counter\n          ng-repeat=\"c in $ctrl.zone.counters\"\n          ng-if=\"c.value > 0\"\n          counter=\"c\">\n        </pile-counter>\n        <div \n          ng-repeat=\"p in $ctrl.projectCubes\"\n          class=\"pile-project-cube\"\n          style=\"{{$ctrl.cubeBBoxes[$index].getStyleString()}}; background-image: url('{{$ctrl.getCube(p)}}');\">\n        </div>\n        "
});