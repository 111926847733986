"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Invoice = function () {
	function Invoice(checkoutData) {
		_classCallCheck(this, Invoice);

		this.data = checkoutData;
	}

	_createClass(Invoice, [{
		key: "endDate",
		value: function endDate() {

			var date = this.data.purchase.onlineProduct.enddateobj;

			return this.data.display.Date(date);
		}
	}, {
		key: "amounts",
		value: function amounts() {

			return this.data.purchase.prices;
		}
	}, {
		key: "amountLabel",
		value: function amountLabel(amount) {

			return this.data.display.priceLabel(amount.priceCategory);
		}
	}, {
		key: "total",
		value: function total() {

			return this.data.purchase.amountInEuroCents;
		}
	}, {
		key: "paymentProvider",
		value: function paymentProvider() {

			return this.data.display.PaymentHandler();
		}
	}]);

	return Invoice;
}();