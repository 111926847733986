"use strict";

var RussianDeclineButtons = {};

RussianDeclineButtons[DeclineButtonIds.END_ACTION_PHASE] = "Закончить Действия";
RussianDeclineButtons[DeclineButtonIds.END_BUY_PHASE] = "закончить фазу покупок";
RussianDeclineButtons[DeclineButtonIds.END_TURN] = "Закончить ход";
RussianDeclineButtons[DeclineButtonIds.DONE_PLAYING_TREASURES] = "Не играть Сокровища";
RussianDeclineButtons[DeclineButtonIds.DONE_REACTING] = "Не реагировать";
RussianDeclineButtons[DeclineButtonIds.MINING_VILLAGE_DONT_TRASH] = "Не выкидывать";
RussianDeclineButtons[DeclineButtonIds.PEARL_DIVER_DONT_TOPDECK] = "Не класть наверх";
RussianDeclineButtons[DeclineButtonIds.HARBINGER_DONT_TOPDECK] = "Не класть наверх";
RussianDeclineButtons[DeclineButtonIds.THRONE_ROOM_DONT] = "Не удваивать";
RussianDeclineButtons[DeclineButtonIds.SMUGGLERS_GAIN_IMPOSSIBLE_COPY] = "Не получать";
RussianDeclineButtons[DeclineButtonIds.EXPLORER_GAIN_SILVER] = "Получить Серебро";
RussianDeclineButtons[DeclineButtonIds.HERBALIST_DONT_TOPDECK] = "Не класть наверх";
RussianDeclineButtons[DeclineButtonIds.MINT_DONT_COPY] = "Не получать копию";
RussianDeclineButtons[DeclineButtonIds.HAMLET_DONT_DISCARD] = "Не сбрасывать";
RussianDeclineButtons[DeclineButtonIds.TOURNAMENT_DONT_REVEAL] = "Не раскрывать";
RussianDeclineButtons[DeclineButtonIds.YOUNG_WITCH_DONT_REVEAL_BANE] = "Не раскрывать";
RussianDeclineButtons[DeclineButtonIds.IGG_NO_COPPER] = "Не получать Медь";
RussianDeclineButtons[DeclineButtonIds.COUNTERFEIT_NO_TREASURE] = "Не удваивать";
RussianDeclineButtons[DeclineButtonIds.CULTIST_DONT_PLAY_ANOTHER] = "Не играть ещё Культистов";
RussianDeclineButtons[DeclineButtonIds.IRONMONGER_KEEP] = "Оставить наверху";
RussianDeclineButtons[DeclineButtonIds.PROCESSION_DONT] = "Не удваивать";
RussianDeclineButtons[DeclineButtonIds.PLAZA_NO_DISCARD] = "Не сбрасывать";
RussianDeclineButtons[DeclineButtonIds.DONT_OVERPAY] = "Не переплачивать";
RussianDeclineButtons[DeclineButtonIds.DONE_SETTING_ASIDE] = "Закончить откладывать в сторону";
RussianDeclineButtons[DeclineButtonIds.ARTIFICER_DONT] = "Не сбрасывать";
RussianDeclineButtons[DeclineButtonIds.ENGINEER_DONT_TRASH] = "Не выкидывать";
RussianDeclineButtons[DeclineButtonIds.ENCAMPMENT_DONT_REVEAL] = "Не раскрывать";
RussianDeclineButtons[DeclineButtonIds.SETTLERS_DONT_TAKE_COPPER] = "Не брать Медь";
RussianDeclineButtons[DeclineButtonIds.GLADIATOR_DONT_REVEAL] = "Не раскрывать";
RussianDeclineButtons[DeclineButtonIds.CROWN_DONT] = "Не удваивать";
RussianDeclineButtons[DeclineButtonIds.LEGIONARY_DONT_REVEAL] = "Не раскрывать";
RussianDeclineButtons[DeclineButtonIds.SHUFFLE_ALL] = "Замешать всё";
RussianDeclineButtons[DeclineButtonIds.PASS] = "Передать";
RussianDeclineButtons[DeclineButtonIds.DONT_DISCARD] = "Не сбрасывать";
RussianDeclineButtons[DeclineButtonIds.DONT_TRASH] = "Не выкидывать";
RussianDeclineButtons[DeclineButtonIds.VAULT_NO_DISCARD] = "Не сбрасывать";
RussianDeclineButtons[DeclineButtonIds.DONT_BUY] = "Не покупать";
RussianDeclineButtons[DeclineButtonIds.DONT_GAIN] = "не получать";
RussianDeclineButtons[DeclineButtonIds.DISCARD_ALL] = "Сбросить всё";
RussianDeclineButtons[DeclineButtonIds.TORTURER_FAIL_CURSE] = "Получить Проклятие";
RussianDeclineButtons[DeclineButtonIds.TORTURER_GAIN_CURSE] = "Получить Проклятие";
RussianDeclineButtons[DeclineButtonIds.BUSTLING_VILLAGE_NO_SETTLERS] = "Не брать Поселенцев";
RussianDeclineButtons[DeclineButtonIds.PRINCE_NO] = "Не откладывать в сторону";
RussianDeclineButtons[DeclineButtonIds.SAUNA_DONT_CHAIN] = "Не играть Прорубь";
RussianDeclineButtons[DeclineButtonIds.AVANTO_DONT_CHAIN] = "Не играть Сауну";
RussianDeclineButtons[DeclineButtonIds.CONCLAVE_DONT] = "Не играть";
RussianDeclineButtons[DeclineButtonIds.IMP_DONT] = "Не играть";
RussianDeclineButtons[DeclineButtonIds.NECROMANCER_DONT] = "Не играть";
RussianDeclineButtons[DeclineButtonIds.NO_THANKS] = "Не надо";
RussianDeclineButtons[DeclineButtonIds.DONT_EXILE] = "Не изгонять";
RussianDeclineButtons[DeclineButtonIds.KINGS_COURT_DONT] = "Не утраивать";
RussianDeclineButtons[DeclineButtonIds.MASTERMIND_DONT] = "Не утраивать";
RussianDeclineButtons[DeclineButtonIds.DELAY_DONT] = "Не использовать Задержку";
RussianDeclineButtons[DeclineButtonIds.TOIL_DONT] = "Не использовать Тяжкий Труд";
RussianDeclineButtons[DeclineButtonIds.MARCH_DONT] = "Не использовать Марш";
RussianDeclineButtons[DeclineButtonIds.DONT_TOPDECK] = "Не класть на верх колоды";
RussianDeclineButtons[DeclineButtonIds.RANDOM_ORDER] = "Случайный порядок";
RussianDeclineButtons[DeclineButtonIds.HUNTING_LODGE_KEEP] = "Не сбрасывать";
RussianDeclineButtons[DeclineButtonIds.TIARA_NO_TREASURE] = "Не играть";
RussianDeclineButtons[DeclineButtonIds.SWAP_DONT] = "Не менять местами";
RussianDeclineButtons[DeclineButtonIds.DONT_PLAY] = "Не играть";
RussianDeclineButtons[DeclineButtonIds.DONT_REVEAL] = "Не раскрывать";
RussianDeclineButtons[DeclineButtonIds.DONT_ROTATE] = "Не прокручивать";
RussianDeclineButtons[DeclineButtonIds.DONT] = "Нет";
RussianDeclineButtons[DeclineButtonIds.SPEND_FAVOR] = "Потратить услугу";
RussianDeclineButtons[DeclineButtonIds.CONTINUE] = "Продолжить";
RussianDeclineButtons[DeclineButtonIds.ORB_DONT] = "+1 Покупка, +3 Монеты";
RussianDeclineButtons[DeclineButtonIds.DONT_PUZZLE_BOX] = "Не откладывать в сторону";
RussianDeclineButtons[DeclineButtonIds.DONT_INSPIRING] = "Не играть";
RussianDeclineButtons[DeclineButtonIds.DONT_JOUST] = "Не откладывать в сторону";
RussianDeclineButtons[DeclineButtonIds.SNAKE_WITCH_DONT_RETURN] = "Не возвращать";

RussianDeclineButtons[DoneButtonIds.DONE_TRASHING] = "Подтвердить выкинутое";
RussianDeclineButtons[DoneButtonIds.DONE_DISCARDING] = "Подтвердить сброс";
RussianDeclineButtons[DoneButtonIds.DONE_ORDERING] = "Закончить упорядочивание";
RussianDeclineButtons[DoneButtonIds.DONE_RETURNING] = "Подтвердить";
RussianDeclineButtons[DoneButtonIds.DONE_SETTING_ASIDE] = "Подтвердить";
RussianDeclineButtons[DoneButtonIds.DONE_SELECTING] = "Подтвердить выбор";
RussianDeclineButtons[DoneButtonIds.DONE_CHOOSING] = "Подтвердить";
RussianDeclineButtons[DoneButtonIds.TRASH] = "Выкинуть";
RussianDeclineButtons[DoneButtonIds.DISCARD] = "Сбросить";
RussianDeclineButtons[DoneButtonIds.PASS] = "Передать";
RussianDeclineButtons[DoneButtonIds.TOPDECK] = "Положить наверх";
RussianDeclineButtons[DoneButtonIds.BOTTOMDECK] = "Под низ колоды";
RussianDeclineButtons[DoneButtonIds.EXILE] = "Изгнать";
RussianDeclineButtons[DoneButtonIds.OVERPAY] = "Переплачивать";

var RussianGameButtons = {};

RussianGameButtons[GameButtons.AUTOPLAY_TREASURES] = "Играть все Сокровища";
RussianGameButtons[GameButtons.USE_VILLAGER] = "Использовать Хуторянина";
RussianGameButtons[GameButtons.USE_FAVOR_TOPDECK] = "Использовать Услугу чтобы положить на верх";
RussianGameButtons[GameButtons.USE_FAVOR_DISCARD] = "Использовать Услугу чтобы сбросить";
RussianGameButtons[GameButtons.COFFERS] = "Использовать Монетки";

var RussianDelayedQuestionButtons = {};

RussianDelayedQuestionButtons[CardNames.ARTIFICER] = "Использовать Ремесленника";
RussianDelayedQuestionButtons[CardNames.BUSTLING_VILLAGE] = "Взять Поселенцев";
RussianDelayedQuestionButtons[CardNames.CULTIST] = "Сыграть Культиста";
RussianDelayedQuestionButtons[CardNames.ENGINEER] = "Выкинуть Инженера";
RussianDelayedQuestionButtons[CardNames.HAMLET] = "Сбросить";
RussianDelayedQuestionButtons[CardNames.HARBINGER] = "Положить наверх";
RussianDelayedQuestionButtons[CardNames.ILL_GOTTEN_GAINS] = "Получить Медь";
RussianDelayedQuestionButtons[CardNames.IRONMONGER] = "Сбросить";
RussianDelayedQuestionButtons[CardNames.MINING_VILLAGE] = "Выкинуть";
RussianDelayedQuestionButtons[CardNames.PEARL_DIVER] = "Положить наверх";
RussianDelayedQuestionButtons[CardNames.PLAZA] = "Сбросить";
RussianDelayedQuestionButtons[CardNames.SETTLERS] = "Взять Медь";
RussianDelayedQuestionButtons[CardNames.HUNTING_LODGE] = "Сбросить руку и вытянуть 5 карт";
RussianDelayedQuestionButtons[CardNames.SNAKE_WITCH] = "Вернуть и раздать Проклятия";
RussianDelayedQuestionButtons[CardNames.RUSTIC_VILLAGE] = "Сбросить две и вытянуть одну карту";
RussianDelayedQuestionButtons[CardNames.GOLD_MINE] = "Получить Золото и 4 долга";

var RussianCleanupActions = {};

RussianCleanupActions[CleanupActions.TOPDECK] = new CleanupButtonText("Положить наверх", "Сбросить");
RussianCleanupActions[CleanupActions.EXCHANGE] = new CleanupButtonText("Обменять", "Оставить");