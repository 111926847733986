"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var WayButtonLayer = function (_StackViewLayer) {
    _inherits(WayButtonLayer, _StackViewLayer);

    function WayButtonLayer(rootNode, cardStack) {
        _classCallCheck(this, WayButtonLayer);

        var _this = _possibleConstructorReturn(this, (WayButtonLayer.__proto__ || Object.getPrototypeOf(WayButtonLayer)).call(this, rootNode, cardStack));

        _this.node.style.zIndex = 10;
        _this.node.classList.add("way-button");
        _this.isHovered = false;
        return _this;
    }

    _createClass(WayButtonLayer, [{
        key: "redraw",
        value: function redraw(w, h) {
            var clickableCard = this.cardStack.getClickableCard();
            var wayQuestion = clickableCard === null ? null : clickableCard.question.getBundledQuestion(QuestionTypes.WAY);
            var shouldDraw = wayQuestion === null ? null : wayQuestion.affectedCards.includes(clickableCard.id);
            var hasDisabledButton = this.cardStack.hasFilter("always-show-way") && this.cardStack.topCard.cardName.isAction();

            // This isn't the way button but is drawn similarly so hijack time
            var specialIcon = CardNames.BACK;
            if (!isUndefined(this.cardStack.topCard.cardStatus)) {
                if (this.cardStack.topCard.cardStatus.isFlipped) specialIcon = CardNames.NECROMANCER;else if (this.cardStack.topCard.cardStatus.isInvested) specialIcon = CardNames.INVEST;
            }

            this.node.classList.remove("invisible");
            var artURL = void 0;
            if (specialIcon === CardNames.BACK) {
                if ((wayQuestion === null || !shouldDraw) && !hasDisabledButton) {
                    this.node.classList.add("invisible");
                    return;
                }
                var wayCard = this.cardStack.location.state.cards.find(function (c) {
                    return c.cardName.isWay();
                });
                if (isUndefined(wayCard)) {
                    this.node.classList.add("invisible");
                    return;
                }
                var wayName = wayCard.cardName;
                artURL = getFullArtURL(wayName.changedImage);
            } else {
                switch (specialIcon) {
                    case CardNames.NECROMANCER:
                        artURL = "images/elements/no-necromancer.jpg";
                        break;
                    case CardNames.INVEST:
                        artURL = getFullArtURL(CardNames.INVEST);
                        break;
                }
            }

            assignPositionToNode(new Rectangle(0.21 * w, 0.57 * h, 0.58 * w, 0.27 * h), this.node);
            this.node.style.backgroundImage = "url(" + artURL + ")";
            this.node.style.fontSize = 0.4 * h + "px";
            if ((wayQuestion === null || !shouldDraw) && hasDisabledButton) {
                this.node.style.filter = "grayscale(100%)";
            } else {
                this.node.style.filter = "";
            }

            this.node.style.borderColor = this.isHovered ? Actions.WAY.hoverColor : "#CCC";
            this.node.style.opacity = this.isHovered || !wayButtonsAreTransparent ? 1 : 0.4;
        }
    }]);

    return WayButtonLayer;
}(StackViewLayer);