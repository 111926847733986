"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PlayerInfoView = function () {
    function PlayerInfoView(player) {
        var _this = this;

        _classCallCheck(this, PlayerInfoView);

        this.node = document.createElement("player-info");
        this.rendered = false;

        this.nameNode = document.createElement("player-info-name");
        this.nameNode.style.fontFamily = "Raleway";
        this.node.appendChild(this.nameNode);

        this.vpNode = document.createElement("player-info-vp");
        this.node.appendChild(this.vpNode);

        this.countersNode = document.createElement("player-info-counters");
        this.node.appendChild(this.countersNode);

        this.player = player;
        this.views = [];
        this.reminderViews = [];
        this.resizeListener = function () {
            return _this.redraw();
        };
        window.addEventListener("resize", this.resizeListener);

        var vw = window.innerWidth / 100;
        var vh = window.innerHeight / 100;
        this.width = 12.5 * vw;
        this.height = (this.player.isHero ? 6 : 5.5) * vh;
    }

    _createClass(PlayerInfoView, [{
        key: "unload",
        value: function unload() {
            this.renderNode.removeChild(this.node);
            this.node = null;
            this.rendered = false;
            window.removeEventListener("resize", this.resizeListener);
        }
    }, {
        key: "render",
        value: function render(node) {
            var _this2 = this;

            this.rendered = true;
            node.appendChild(this.node);
            this.renderNode = node;
            if (this.player.playerModel.opponents.length > 0) this.node.style.cursor = "pointer";
            this.node.onclick = function () {
                if (_this2.player.isHero) {
                    if (_this2.player.playerModel.opponents.length > 0) {
                        _this2.player.playerModel.hero = _this2.player.playerModel.activeOpponent;
                        _this2.player.state.game.heroOverride = _this2.player.playerModel.hero;
                    }
                } else {
                    if (_this2.player.isActiveOpponent) {
                        _this2.player.playerModel.hero = _this2.player;
                        _this2.player.state.game.heroOverride = _this2.player.playerId;
                    } else {
                        _this2.player.playerModel.activeOpponent = _this2.player;
                    }
                }
            };
            this.initializeCounters();
            this.updateReminders();
            this.redraw();
        }
    }, {
        key: "repositionCanvas",
        value: function repositionCanvas() {
            var vw = window.innerWidth / 100;
            var vh = window.innerHeight / 100;
            var width = 12.5 * vw;
            var height = (this.player.isHero ? 6 : 5.5) * vh;
            var x = 0;
            var y = 79.5 * vh;
            if (!this.player.isHero) y = (this.player.playerModel.opponents.indexOf(this.player) * 5.5 + 4) * vh;

            this.node.style.position = "fixed";
            this.node.style.width = width + "px";
            this.node.style.height = height + "px";
            this.node.style.transform = "translateX(" + x + "px) translateY(" + y + "px)";

            this.node.width = width * 2;
            this.node.height = height * 2;
        }
    }, {
        key: "redrawBackground",
        value: function redrawBackground() {
            this.node.style.borderColor = this.player.color;
            this.redrawName();
            this.redrawVP();
            this.redrawCounters();
        }
    }, {
        key: "redrawName",
        value: function redrawName() {
            var bbox = new Rectangle(0.05 * this.width, 0.05 * this.height, 0.65 * this.width, 0.45 * this.height);
            fitTextInNode(this.player.name, this.nameNode, bbox);
        }
    }, {
        key: "redrawVP",
        value: function redrawVP() {
            var vpCounter = this.player.counters.find(function (c) {
                return c.counterName === CounterNames.POINTS;
            });
            if (!isUndefined(vpCounter) && vpCounter.isVisible) {
                var view = this.views.find(function (v) {
                    return v.counter === vpCounter;
                });
                if (isUndefined(view)) {
                    view = new CounterView(vpCounter);
                    view.render(this.vpNode);
                    this.views.push(view);
                }
                view.reposition(new Rectangle(0.78 * this.width, 0.05 * this.height, 0.20 * this.width, 0.45 * this.height));
            }
        }
    }, {
        key: "redrawCounters",
        value: function redrawCounters() {
            var vh = window.innerHeight / 100;
            var size = 2.6 * vh;
            this.views.filter(function (v) {
                return v.node !== null && v.counter.counterName !== CounterNames.POINTS;
            }).forEach(function (view) {
                view.reposition(new Rectangle(0, 0, size, size));
                if (view.counter.value > 0) {
                    view.node.classList.remove("invisible");
                } else {
                    view.node.classList.add("invisible");
                }
            });
            this.reminderViews.forEach(function (view) {
                view.reposition(new Rectangle(0, 0, size, size));
            });

            var projectsBought = this.player.boughtProjects.length;
            this.reminderViews.filter(function (view) {
                return view instanceof ProjectCubeView;
            }).forEach(function (v, i) {
                v.reposition(new Rectangle(0, 0, size, size));
                if (i < projectsBought) v.node.classList.add("invisible");else v.node.classList.remove("invisible");
            });
        }
    }, {
        key: "initializeCounters",
        value: function initializeCounters() {
            var _this3 = this;

            this.views = [];

            var projectsInKingdom = this.player.state.cards.filter(function (c) {
                return c.cardName.isProject();
            }).length;
            for (var i = 0; i < Math.min(2, projectsInKingdom); i++) {
                var cubeView = new ProjectCubeView(this.player);
                this.reminderViews.push(cubeView);
                cubeView.render(this.countersNode);
            }

            var counterNames = [CounterNames.VP_TOKENS, CounterNames.DEBT, CounterNames.PIRATE_STRIKES, CounterNames.VILLAGERS, CounterNames.COFFERS, CounterNames.FAVORS];
            counterNames.forEach(function (counterName) {
                var counter = _this3.player.getCounter(counterName);
                if (!isUndefined(counter)) {
                    var counterView = new CounterView(counter);
                    _this3.views.push(counterView);
                    counterView.render(_this3.countersNode);
                }
            });
        }
    }, {
        key: "updateReminders",
        value: function updateReminders() {
            var _this4 = this;

            var actualReminders = [];
            this.reminderViews.forEach(function (v) {
                if (v instanceof TokenView || v instanceof ProjectCubeView || _this4.player.activeReminders.some(function (r) {
                    return r.cardName === v.reminder.cardName;
                })) {
                    actualReminders.push(v);
                } else {
                    v.destroy();
                }
            });
            this.reminderViews = actualReminders;

            if (!this.reminderViews.some(function (v) {
                return v instanceof TokenView && v.token.tokenName === TokenNames.JOURNEY;
            })) {
                var journeyToken = this.player.state.tokens.find(function (t) {
                    return t.owner === _this4.player && t.tokenName === TokenNames.JOURNEY;
                });
                if (!isUndefined(journeyToken)) {
                    var tokenView = new TokenView(journeyToken);
                    this.reminderViews.push(tokenView);
                    tokenView.render(this.countersNode);
                }
            }

            this.player.activeReminders.forEach(function (r) {
                var index = _this4.reminderViews.findIndex(function (v) {
                    return v instanceof ReminderView && r.cardName === v.reminder.cardName;
                });
                if (index === -1) {
                    var reminderView = new ReminderView(r, _this4.player.state.game);
                    _this4.reminderViews.push(reminderView);
                    reminderView.render(_this4.countersNode);
                    index = _this4.reminderViews.length - 1;
                }
                _this4.reminderViews[index].update();
            });
        }
    }, {
        key: "redraw",
        value: function redraw() {
            if (this.player.isActiveOpponent) {
                this.node.classList.add("active");
            } else {
                this.node.classList.remove("active");
            }
            if (this.rendered) {
                this.repositionCanvas();
                this.redrawBackground();
            }
        }
    }, {
        key: "onReminderChange",
        value: function onReminderChange() {
            this.updateReminders();
            this.redraw();
        }
    }]);

    return PlayerInfoView;
}();