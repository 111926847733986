"use strict";

var GermanUserPreferences = {};

GermanUserPreferences[UserPrefIds.EMAIL] = "Email";
GermanUserPreferences[UserPrefIds.CARD_OWNERSHIP] = "Karten in Besitz";
GermanUserPreferences[UserPrefIds.LIKED_CARDS] = "Deine beliebten Karten";
GermanUserPreferences[UserPrefIds.DISLIKED_CARDS] = "Deine unbeliebten Karten";
GermanUserPreferences[UserPrefIds.BANNED_CARDS] = "Deine verbotenen Karten";
GermanUserPreferences[UserPrefIds.MOVE_KINGDOM] = "Bewege Königreich und Spielbereich";
GermanUserPreferences[UserPrefIds.CONFIRM_ACTIONS] = "Bestätige einzelnes Entsorgen, Ablegen und ins Exil Legen";
GermanUserPreferences[UserPrefIds.PREFERRED_BACKGROUND] = "Bevorzugter Hintergrund";
GermanUserPreferences[UserPrefIds.USE_AUTOBUY] = "Benutze Autokauf";
GermanUserPreferences[UserPrefIds.FOLLOWERS_SEE_ACTIVITY] = "Follower sehen deine Aktivität";
GermanUserPreferences[UserPrefIds.NOTIFICATION_SOUNDS] = "Ton bei Benachrichtigung";
GermanUserPreferences[UserPrefIds.SOUND_FX] = "Sonstige Soundeffekte";
GermanUserPreferences[UserPrefIds.SPECTATORS_SEE_HAND] = "Zuschauern meine Karten zeigen";
GermanUserPreferences[UserPrefIds.AUTOPLAY_SETTINGS] = "Autoplay the following cards";
GermanUserPreferences[UserPrefIds.LOG_FIXED_BASIC_BONUS] = "Log zeigt alle +Aktionen, +Käufe, +Geld";
GermanUserPreferences[UserPrefIds.TRANSPARENT_WAY_BUTTON] = "Durchsichtiger Weg-Knopf";
GermanUserPreferences[UserPrefIds.CHAT_VISIBLE] = "Chat ist sichtbar";
GermanUserPreferences[UserPrefIds.SPECTATOR_CHAT_IN_AUTOMATCH] = "Sieh Zuschauerchat im Automatch";
GermanUserPreferences[UserPrefIds.CARD_POOL_LEVEL] = "Kartenstufe";
GermanUserPreferences[UserPrefIds.ANIMATION_TYPE] = "Animationen";
GermanUserPreferences[UserPrefIds.PREMOVES] = "Vor-Klicken bei Animationen";

GermanUserPreferences.USERNAME = "Benutzername";
GermanUserPreferences.PASSWORD = "Passwort";
GermanUserPreferences.OLD_PASSWORD = "Altes Passwort";
GermanUserPreferences.NEW_PASSWORD = "Neues Passwort";
GermanUserPreferences.REPEAT_PASSWORD = "Wiederhole neues Passwort";
GermanUserPreferences.OWNERSHIP_INTRO = "Dein aktuelles Abonnement:";
GermanUserPreferences.OWNERSHIP_INTRO_EMPTY = "Du hast aktuell kein Abonnement.";
GermanUserPreferences.FAMILIAR_WITH = "Ich kenne: ";
GermanUserPreferences.USE_BONUS_CODE = "Löse den Bonus Code ein";
GermanUserPreferences.BONUS_CODE_ORIGIN = "Dein Bonus Code ist aus: ";
GermanUserPreferences.BONUS_CODE_CHOOSE_EXPANSION = "Wähle eine Erweiterung: ";

var GermanProfileButtons = {};

GermanProfileButtons[true] = "Ja";
GermanProfileButtons[false] = "Nein";
GermanProfileButtons[UserPrefIds.FAMILIAR_CARDS] = {
    ALL: "Diese Erweiterung",
    NONE: "Keine von dieser Erweiterung",
    ALL_EXPANSIONS: "Alle Erweiterungen"
};

var GermanStoreLabels = {};

GermanStoreLabels[StorePackages.HALF] = "Silber-Abonnement";
GermanStoreLabels[StorePackages.ALL] = "Gold-Abonnement";
GermanStoreLabels[StorePackages.CORE] = "Kern-Abonnement";
GermanStoreLabels[StorePackages.COMPLETE] = "Komplett-Abonnement";

GermanStoreLabels[PriceCategories.PURCHASED] = ''; // "Kaufe Erweiterung: ";
GermanStoreLabels[PriceCategories.ALREADY_OWNED] = "Bereits in deinem Besitz";
GermanStoreLabels[PriceCategories.DISCOUNT] = "Rabatt";
GermanStoreLabels[PriceCategories.TRANSACTION] = "Transaktionsgebühr";
GermanStoreLabels[PriceCategories.SUBTOTAL] = "Zwischensumme";
GermanStoreLabels[PriceCategories.VAT] = "Umsatzsteuer";
GermanStoreLabels[PriceCategories.TOTAL] = "Summe";

GermanStoreLabels.SELLER_INFO = "Seller info";
GermanStoreLabels.PRODUCT_CONFIRM = "Purchasing until DATE: EXPANSIONS";
GermanStoreLabels.PAYMENT_HANDLER = "Die Zahlung wird durch unseren Zahlungsdienstleister Mollie durchgeführt.";
GermanStoreLabels.PROCEED_TO_PAYMENT = "Weiter zur Zahlung";

var GermanAutoplayLabels = {};

GermanAutoplayLabels.REGULAR_OPTIONS = "Normale Optionen";
GermanAutoplayLabels.AUTOPLAY_OPTIONS = "Autoplay-Optionen";
GermanAutoplayLabels.AUTOPLAY_DOCUMENTATION_GENERIC = "Was ist Autoplay?";
GermanAutoplayLabels.AUTOPLAY_DOCUMENTATION_SPECIFIC = "-spezifische Optionen";
GermanAutoplayLabels.CUSTOMIZE_OPTIONS = "Optionen anpassen";
GermanAutoplayLabels.ALL_OFF = "Alle aus";
GermanAutoplayLabels.ALL_DEFAULT = "Alle Standard";
GermanAutoplayLabels.ALL_CONVENIENT = "Alle bequem";

var GermanAnimationTypes = {};
GermanAnimationTypes[AnimationOptions.NEW_PLAYER] = "Sehr langsam";
GermanAnimationTypes[AnimationOptions.BEGINNER] = "Langsam";
GermanAnimationTypes[AnimationOptions.MEDIUM] = "Gemütlich";
GermanAnimationTypes[AnimationOptions.ADVANCED] = "Schnell";
GermanAnimationTypes[AnimationOptions.VERYFAST] = "Sehr schnell";
GermanAnimationTypes[AnimationOptions.INSTANT] = "Sofort";