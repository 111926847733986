"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var StatusBarView = function () {
    function StatusBarView(logModel) {
        var _this = this;

        _classCallCheck(this, StatusBarView);

        this.node = document.createElement("status-bar");
        this.minimizerNode = document.createElement("status-bar-minimizer");
        this.node.appendChild(this.minimizerNode);
        this.countersNode = document.createElement("status-bar-counters");
        this.node.appendChild(this.countersNode);
        this.tickerNode = document.createElement("status-bar-ticker");
        this.node.appendChild(this.tickerNode);

        this.logModel = logModel;
        this.views = [];
        this.reminderViews = [];
        this.minimized = false;

        this.resizeListener = function () {
            return _this.redraw();
        };
        window.addEventListener("resize", this.resizeListener);
    }

    _createClass(StatusBarView, [{
        key: "unload",
        value: function unload() {
            this.renderNode.removeChild(this.node);
            this.node = null;
            this.rendered = false;
            window.removeEventListener("resize", this.resizeListener);
        }
    }, {
        key: "render",
        value: function render(node) {
            var _this2 = this;

            this.rendered = true;
            node.appendChild(this.node);
            this.renderNode = node;

            this.minimizerNode.onclick = function () {
                _this2.minimized = !_this2.minimized;
                if (_this2.minimized) {
                    _this2.node.classList.add("minimized");
                } else {
                    _this2.node.classList.remove("minimized");
                }
            };

            this.initializeCounters();
            this.updateReminders();
            this.redraw();
        }
    }, {
        key: "setClickableCounter",
        value: function setClickableCounter(counterName, handler) {
            this.views.forEach(function (block) {
                block.forEach(function (counterView) {
                    if (counterView.counter.counterName === counterName) {
                        counterView.node.onclick = handler;
                        counterView.node.classList.add("clickable");
                    }
                });
            });
        }
    }, {
        key: "clearClickHandlers",
        value: function clearClickHandlers() {
            this.views.forEach(function (block) {
                block.forEach(function (counterView) {
                    counterView.node.onclick = function () {};
                    counterView.node.classList.remove("clickable");
                });
            });
        }
    }, {
        key: "initializeCounters",
        value: function initializeCounters() {
            var _this3 = this;

            this.views = [];
            var counterNames = [[CounterNames.ACTIONS, CounterNames.VILLAGERS], [CounterNames.BUYS], [CounterNames.COINS, CounterNames.POTIONS, CounterNames.DEBT, CounterNames.COFFERS], [CounterNames.FAVORS], [CounterNames.REMAINING_HAND_PLAYS]];
            var player = this.logModel.game.state.activeTurn.owner;
            var vh = window.innerHeight / 100;
            counterNames.forEach(function (block) {
                var viewBlock = [];
                block.forEach(function (counterName) {
                    var counter = player.getCounter(counterName);
                    if (!isUndefined(counter)) {
                        var counterView = void 0;
                        var forceShow = [CounterNames.ACTIONS, CounterNames.BUYS, CounterNames.COINS].includes(counter.counterName);
                        if (counter.counterName.phrases !== null) counterView = new FreeWidthCounterView(counter);else counterView = new CounterView(counter, forceShow);
                        counterView.render(_this3.countersNode);
                        counterView.reposition(new Rectangle(0, 0, 3.3 * vh, 3.3 * vh));
                        viewBlock.push(counterView);
                    }
                });
                if (viewBlock.length > 0) _this3.views.push(viewBlock);
            });
        }
    }, {
        key: "updateReminders",
        value: function updateReminders() {
            var _this4 = this;

            var player = this.logModel.game.state.activeTurn.owner;
            var vh = window.innerHeight / 100;
            var realReminders = [];
            this.reminderViews.forEach(function (v) {
                if (player.activeReminders.some(function (r) {
                    return r === v.reminder;
                })) {
                    realReminders.push(v);
                } else {
                    v.destroy();
                }
            });
            this.reminderViews = realReminders;
            player.activeReminders.forEach(function (r) {
                var index = _this4.reminderViews.findIndex(function (v) {
                    return r === v.reminder;
                });
                if (index === -1) {
                    var reminderView = new ReminderView(r, _this4.logModel.game);
                    _this4.reminderViews.push(reminderView);
                    reminderView.render(_this4.countersNode);
                    reminderView.reposition(new Rectangle(0, 0, 3 * vh, 3 * vh));
                }
            });
        }
    }, {
        key: "redrawCounters",
        value: function redrawCounters() {
            var vh = window.innerHeight / 100;
            var vw = window.innerWidth / 100;
            this.views.forEach(function (block) {
                block.forEach(function (view) {
                    view.node.classList.remove("last-of-block");
                    view.reposition(new Rectangle(0, 0, view.bbox.width, 3 * vh));
                    view.node.style.fontSize = "3vh";
                });
                var visibleCounters = block.filter(function (view) {
                    return !view.node.classList.contains("invisible");
                });
                if (visibleCounters.length > 0) {
                    visibleCounters[visibleCounters.length - 1].node.classList.add("last-of-block");
                }
            });

            this.reminderViews.forEach(function (view) {
                view.reposition(new Rectangle(0, 0, view.bbox.width, 3 * vh));
            });
            if (this.reminderViews.length === 0 && this.views.length > 0) {
                var lastBlock = this.views[this.views.length - 1];
                lastBlock[lastBlock.length - 1].node.classList.remove("last-of-block");
            }
        }
    }, {
        key: "redrawTicker",
        value: function redrawTicker() {
            var _this5 = this;

            var vw = window.innerWidth / 100;
            var vh = window.innerHeight / 100;
            var ticker = this.logModel.ticker;
            clearChildNodes(this.tickerNode);
            if (ticker === null) return;

            var parser = new LogLineParser(this.logModel.game.state, this.logModel.game.state.players, true);
            var blocks = void 0;
            if (ticker instanceof LogEntry) {
                blocks = parser.parseLineWithArguments(LANGUAGE.getLogEntry[ticker.name], ticker.logArguments);
            } else {
                blocks = parser.parseStory(ticker);
            }
            this.textBlockBboxes = new Map();

            var truncatedBlocks = [];
            var characterCount = 0;
            var MAX_CHARS = 100;
            for (var i = 0; i < blocks.length; i++) {
                if (blocks[i].text.length > MAX_CHARS - characterCount) {
                    blocks[i].text = blocks[i].text.slice(0, MAX_CHARS - characterCount) + "...";
                    truncatedBlocks.push(blocks[i]);
                    break;
                } else {
                    truncatedBlocks.push(blocks[i]);
                    characterCount += blocks[i].text.length;
                }
            }
            blocks = truncatedBlocks;

            var measurementCanvas = document.createElement("canvas");
            var ctx = measurementCanvas.getContext("2d");
            var fontFamily = LANGUAGE.getEnvironment.hasOwnProperty("titleFont") ? LANGUAGE.getEnvironment.titleFont : "TrajanPro-Bold";
            var measurementSize = 50;
            ctx.font = measurementSize + "px " + fontFamily;
            var lengths = blocks.map(function (block) {
                return ctx.measureText(block.text).width;
            });
            var totalLength = sum(lengths);
            var w = 45 * vw - 6 * vh;
            var measuredSize = 0.9 * w / totalLength * measurementSize;
            this.tickerNode.style.fontSize = "min(1em, " + measuredSize + "px)";

            blocks.forEach(function (block, i) {
                var node = document.createElement("span");
                node.style.fontFamily = fontFamily;
                node.style.color = block.color === null ? "#91ACFF" : block.color;
                node.innerHTML = block.text;
                if (block.studyCard !== null) {
                    node.classList.add("clickable");
                    node.oncontextmenu = function () {
                        _this5.logModel.game.studyModel.study(block.studyCard);
                    };
                }
                _this5.tickerNode.appendChild(node);
            });
        }
    }, {
        key: "redraw",
        value: function redraw() {
            if (this.canvas === null) return;
            if (!this.minimized) {
                this.redrawCounters();
                this.redrawTicker();
            }
        }
    }, {
        key: "onCounterChange",
        value: function onCounterChange() {
            this.redrawCounters();
        }
    }, {
        key: "onTickerChange",
        value: function onTickerChange() {
            this.redrawTicker();
        }
    }, {
        key: "onPlayerChange",
        value: function onPlayerChange() {
            var player = this.logModel.game.state.activeTurn.owner;
            this.views.forEach(function (viewBlock) {
                return viewBlock.forEach(function (view) {
                    return view.counter = player.getCounter(view.counter.counterName);
                });
            });
            this.updateReminders();
        }
    }, {
        key: "onReminderChange",
        value: function onReminderChange() {
            this.updateReminders();
            this.redraw();
        }
    }]);

    return StatusBarView;
}();