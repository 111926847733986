"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var StudyModel = function () {
    function StudyModel(game) {
        _classCallCheck(this, StudyModel);

        this.game = game;
        this.studyView = null;

        this.studyStack = new AnonymousCardStack(this, CardNames.BACK);
        this.studyView = this.studyStack.view;
        this.pileStacks = [];
        this.associationStacks = [];

        this.studyView.onclick = function () {};
        this.studyView.onmousemove = function () {};
        this.studyView.onmouseover = function () {};
        this.studyView.onmouseout = function () {};

        this.aboutToHide = false;
        this.clickHandlers = [];
    }

    _createClass(StudyModel, [{
        key: "renderEverything",
        value: function renderEverything() {
            this.studyStack.render(this.renderNode);
        }
    }, {
        key: "clearHandlers",
        value: function clearHandlers() {
            this.clickHandlers.forEach(function (_ref) {
                var e = _ref.e,
                    h = _ref.h;
                return window.removeEventListener(e, h);
            });
        }
    }, {
        key: "addHandler",
        value: function addHandler(e, h) {
            this.clickHandlers.push({ e: e, h: h });
            window.addEventListener(e, h);
        }
    }, {
        key: "study",
        value: function study(cardName) {
            var _this = this;

            this.aboutToHide = false;
            var isLongClick = false;

            var mouseUpHandler = function mouseUpHandler() {
                if (!isLongClick) {
                    _this.aboutToHide = true;
                    setTimeout(function () {
                        if (_this.aboutToHide) hideStudyCard();
                    });
                } else {
                    _this.addHandler("mousedown", mouseDownHandler);
                    _this.addHandler("touchstart", mouseDownHandler);
                }
            };
            var mouseDownHandler = function mouseDownHandler() {
                isLongClick = false;
                setTimeout(function () {
                    isLongClick = true;
                }, 500);
                _this.clearHandlers();
                _this.addHandler("mouseup", mouseUpHandler);
                _this.addHandler("touchend", mouseUpHandler);
            };
            var hideStudyCard = function hideStudyCard() {
                _this.study(null);
                _this.clearHandlers();
            };
            this.clearHandlers();
            if (cardName === CardNames.PRIZE_PILE) cardName = CardNames.TOURNAMENT;
            if (cardName === CardNames.REWARDS_PILE) cardName = CardNames.JOUST;

            if (cardName !== null && !cardName.isFake) {
                this.studyStack.cardName = cardName;

                var hasAutoplay = AutoPlay.autoplayable(cardName) && !isUndefined(this.game.optionsModel) && this.game.optionsModel.autoplaySettings.map[AutoPlay.find(cardName)];
                var vw = window.innerWidth / 100;
                var vh = window.innerHeight / 100;

                var height = (cardName.isLandscape() ? 43 : 67.5) * vh;
                var width = height / this.studyStack.ratio;
                var x = (hasAutoplay ? 60 : 50) * vw - width / 2;
                var y = 50 * vh - height / 2;
                this.studyView.reposition(x, y, width, height, 20000);

                var associations = getCardNameAssociations(this.studyStack.cardName);
                var pileCards = [];
                var isInMixedPile = false;
                var instance = this.game.state.cards.find(function (c) {
                    return c.cardName === cardName;
                });
                if (!isUndefined(instance)) {
                    associations = instance.associations.map(function (c) {
                        return c.cardName;
                    });
                    this.studyStack.topCard.types = [].concat(_toConsumableArray(instance.types));
                    this.studyStack.topCard.cost = instance.cost.copy();

                    var targetName = instance.pileName;
                    if (cardName === CardNames.TOURNAMENT) targetName = CardNames.PRIZE_PILE;
                    if (cardName === CardNames.JOUST) targetName = CardNames.REWARDS_PILE;

                    var pileInstance = this.game.state.zones.filter(function (z) {
                        return z !== null;
                    }).find(function (z) {
                        return z.pileName === targetName;
                    });
                    if (!isUndefined(pileInstance) && pileInstance instanceof SupplyZone) {
                        pileCards = pileInstance.cardStacks.map(function (s) {
                            return s.cards.map(function (c) {
                                return c.cardName;
                            });
                        }).reduce(function (a, b) {
                            return a.concat(b);
                        }, []);
                        isInMixedPile = pileInstance.markers.some(function (m) {
                            return m.type === PileMarkerTypes.MIXED;
                        });
                    }
                }
                this.pileStacks.forEach(function (s) {
                    return s.destroy();
                });
                this.pileStacks = [];
                this.showPileCards = false;
                if (!isInMixedPile) {
                    pileCards.forEach(function (name) {
                        if (_this.pileStacks.length === 0 || _this.pileStacks[_this.pileStacks.length - 1].cardName !== name) {
                            _this.pileStacks.push(new AnonymousCardStack(_this, name, CardSizes.FULL, 1));
                        } else {
                            _this.pileStacks[_this.pileStacks.length - 1].counter++;
                        }
                    });

                    if (this.pileStacks.length > 1) this.pileStacks.forEach(function (s) {
                        return s.render(_this.renderNode);
                    });else this.pileStacks = [];
                }

                this.pileStacks.forEach(function (s, i) {
                    s.addFilter("northeast-counter");
                    var stackHeight = s.topCard.cardName.isLandscape() ? 20 * vh : 25 * vh;
                    var stackWidth = stackHeight / s.ratio;
                    var stackX = computeX((hasAutoplay ? 20 : 2) * vh, x - 2 * vh, stackWidth, -2 * vh, i, _this.pileStacks.length, true);
                    s.view.reposition(stackX, 50 * vh - stackHeight / 2, stackWidth, stackHeight, 15000 - i * 5);
                });

                associations = associations.filter(function (name) {
                    return !_this.pileStacks.some(function (d) {
                        return d.topCard.cardName === name;
                    });
                });

                var hasInstanceInMixedPile = function hasInstanceInMixedPile(name) {
                    return _this.game.state.cards.some(function (card) {
                        return card.cardName === name && card.cardStacks.length > 0 && card.cardStacks[0].location.markers.some(function (marker) {
                            return marker.type === PileMarkerTypes.MIXED;
                        });
                    });
                };
                associations = associations.filter(function (name) {
                    return !hasInstanceInMixedPile(name);
                });

                this.associationStacks.forEach(function (s) {
                    return s.destroy();
                });
                this.associationStacks = [];
                associations.forEach(function (name) {
                    return _this.associationStacks.push(new AnonymousCardStack(_this, name, CardSizes.FULL, -1));
                });

                this.associationStacks.forEach(function (s, i) {
                    s.render(_this.renderNode);
                    var stackHeight = s.topCard.cardName.isLandscape() ? 20 * vh : 30 * vh;
                    var stackWidth = stackHeight / s.ratio;
                    var stackY = computeX(12 * vh, 88 * vh, stackHeight, 2 * vh, i, _this.associationStacks.length, true);
                    s.view.reposition((hasAutoplay ? 82 : 75) * vw - stackWidth / 2, stackY, stackWidth, stackHeight, 15000 + i * 5);
                });

                this.renderNode.style.visibility = "visible";
                this.studyStack.removeFilter("intangible");
                this.drawAutoplays();

                this.addHandler("mousedown", mouseDownHandler);
                this.addHandler("touchstart", mouseDownHandler);
            } else {
                this.studyStack.cardName = CardNames.BACK;
                if (!isUndefined(this.renderNode)) this.renderNode.style.visibility = "hidden";
                this.studyStack.addFilter("intangible");
                this.pileStacks.forEach(function (s) {
                    return s.destroy();
                });
                this.pileStacks = [];
                this.associationStacks.forEach(function (s) {
                    return s.destroy();
                });
                this.associationStacks = [];
            }
            this.studyView.redrawAll();
            this.drawAutoplays();
        }
    }, {
        key: "drawAutoplays",
        value: function drawAutoplays() {
            if (!isUndefined(this.game.gameArea)) this.game.gameArea.setStudyCard(this.studyStack.topCard);
        }
    }, {
        key: "renderNode",
        get: function get() {
            if (!isUndefined(this.game.gameArea)) return this.game.gameArea.studyArea;
        }
    }]);

    return StudyModel;
}();

var MetaStudyModel = function (_StudyModel) {
    _inherits(MetaStudyModel, _StudyModel);

    function MetaStudyModel() {
        _classCallCheck(this, MetaStudyModel);

        var _this2 = _possibleConstructorReturn(this, (MetaStudyModel.__proto__ || Object.getPrototypeOf(MetaStudyModel)).call(this, { state: { cards: [] } }));

        _this2.studyView.unload = function () {};
        return _this2;
    }

    _createClass(MetaStudyModel, [{
        key: "drawAutoplays",
        value: function drawAutoplays() {}
    }, {
        key: "renderNode",
        get: function get() {
            return document.getElementById("meta-study-container");
        }
    }]);

    return MetaStudyModel;
}(StudyModel);

webclient.service("metaStudyService", [function () {
    this.studyModel = new MetaStudyModel();
    this.studyModel.renderEverything();
}]);