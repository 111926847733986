"use strict";
// Use like <question-boolean ng-model="ctrl.question"> </question-boolean> in HTML
//

webclient.component("questionBoolean", {

    bindings: {

        question: "=ngModel"
    },

    controllerAs: "ctrl",

    controller: [function () {
        var _this = this;

        var ctrl = this;

        ctrl.value = undefined;
        ctrl.checkOrUncheck = function () {
            return _this.value;
        };
        ctrl.checkboxChanged = function () {
            return ctrl.question.setAnswer(ctrl.value);
        };
    }],

    templateUrl: 'html/related-questions/boolean-1.9.2.2.html'
});