"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Association = function (_GameStateChange) {
    _inherits(Association, _GameStateChange);

    function Association(game, cardIndex, linkedCardIndices) {
        _classCallCheck(this, Association);

        var _this = _possibleConstructorReturn(this, (Association.__proto__ || Object.getPrototypeOf(Association)).call(this));

        _this.game = game;
        _this.cardIndex = cardIndex;
        _this.linkedCardIndices = linkedCardIndices;
        return _this;
    }

    _createClass(Association, [{
        key: "serialize",
        value: function serialize(writer) {
            writer.writeInt(this.cardIndex);
            writer.writeInts(this.linkedCardIndices);
        }
    }, {
        key: "execute",
        value: function execute() {
            var _this2 = this;

            var associations = this.linkedCardIndices.filter(function (id) {
                return id !== -1;
            }).map(function (id) {
                return _this2.game.state.cards[id];
            });
            var filteredAssociations = [];
            associations.forEach(function (card) {
                if (!filteredAssociations.some(function (c) {
                    return c.cardName === card.cardName;
                })) filteredAssociations.push(card);
            });
            var card = this.game.state.cards[this.cardIndex];
            card.associations = filteredAssociations;
        }
    }], [{
        key: "parse",
        value: function parse(game, reader) {
            var cardIndex = reader.readInt();
            var linkedCardIndices = reader.readInts();
            return new Association(game, cardIndex, linkedCardIndices);
        }
    }]);

    return Association;
}(GameStateChange);