"use strict";

var JapaneseAbilityNames = {};

JapaneseAbilityNames[AbilityNames.BUY] = "購入";
JapaneseAbilityNames[AbilityNames.PLAY_ATTACK] = "アタックの使用";
JapaneseAbilityNames[AbilityNames.DRAW_INSTRUCTIONS] = "ドロー";
JapaneseAbilityNames[AbilityNames.PLAY_INSTRUCTIONS] = "使用";
JapaneseAbilityNames[AbilityNames.RESOLVE_EVENT] = "イベントの解決";
JapaneseAbilityNames[AbilityNames.GAIN_BY_NAME] = "獲得";

JapaneseAbilityNames[AbilityNames.FISHING_VILLAGE_ABILITY] = JapaneseCardNames[CardNames.FISHING_VILLAGE].singular;
JapaneseAbilityNames[AbilityNames.REMOVE_COST_REDUCTION] = "コストの変更";
JapaneseAbilityNames[AbilityNames.HAVEN_ABILITY] = JapaneseCardNames[CardNames.HAVEN].singular;
JapaneseAbilityNames[AbilityNames.SAVE_RETURN_TO_HAND] = JapaneseCardNames[CardNames.SAVE].singular;
JapaneseAbilityNames[AbilityNames.CARAVAN_ABILITY] = JapaneseCardNames[CardNames.CARAVAN].singular;
JapaneseAbilityNames[AbilityNames.MERCHANT_SHIP_ABILITY] = JapaneseCardNames[CardNames.MERCHANT_SHIP].singular;
JapaneseAbilityNames[AbilityNames.OUTPOST_ABILITY] = JapaneseCardNames[CardNames.OUTPOST].singular;
JapaneseAbilityNames[AbilityNames.TACTICIAN_ABILITY] = JapaneseCardNames[CardNames.TACTICIAN].singular;
JapaneseAbilityNames[AbilityNames.WHARF_ABILITY] = JapaneseCardNames[CardNames.WHARF].singular;
JapaneseAbilityNames[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = JapaneseCardNames[CardNames.HORSE_TRADERS].singular;
JapaneseAbilityNames[AbilityNames.DUCHESS_MAY_GAIN] = JapaneseCardNames[CardNames.DUCHESS].singular;
JapaneseAbilityNames[AbilityNames.FOOLS_GOLD_TRASH] = JapaneseCardNames[CardNames.FOOLS_GOLD].singular;
JapaneseAbilityNames[AbilityNames.NOBLE_BRIGAND] = JapaneseCardNames[CardNames.NOBLE_BRIGAND].singular;
JapaneseAbilityNames[AbilityNames.HAGGLER_ABILITY] = JapaneseCardNames[CardNames.HAGGLER].singular;
JapaneseAbilityNames[AbilityNames.INN_ABILITY] = JapaneseCardNames[CardNames.INN].singular;
JapaneseAbilityNames[AbilityNames.MANDARIN] = JapaneseCardNames[CardNames.MANDARIN].singular;
JapaneseAbilityNames[AbilityNames.FARMLAND] = JapaneseCardNames[CardNames.FARMLAND].singular;
JapaneseAbilityNames[AbilityNames.FORTRESS] = JapaneseCardNames[CardNames.FORTRESS].singular;
JapaneseAbilityNames[AbilityNames.GAIN_CHEAPER] = "安いカードの獲得";
JapaneseAbilityNames[AbilityNames.GAIN_TWO_RUINS] = JapaneseCardNames[CardNames.DEATH_CART].singular;
JapaneseAbilityNames[AbilityNames.HUNTING_GROUNDS] = JapaneseCardNames[CardNames.HUNTING_GROUNDS].singular;
JapaneseAbilityNames[AbilityNames.SQUIRE_GAIN_ATTACK] = JapaneseCardNames[CardNames.SQUIRE].singular;
JapaneseAbilityNames[AbilityNames.URCHIN_TRANSFORM] = JapaneseCardNames[CardNames.URCHIN].singular;
JapaneseAbilityNames[AbilityNames.FEODUM_GAIN_SILVERS] = JapaneseCardNames[CardNames.FEODUM].singular;
JapaneseAbilityNames[AbilityNames.DOCTOR_OVERPAY] = "過払い";
JapaneseAbilityNames[AbilityNames.RESOLVE_DOCTOR_OVERPAY] = JapaneseCardNames[CardNames.DOCTOR].singular;
JapaneseAbilityNames[AbilityNames.HERALD_OVERPAY] = "過払い";
JapaneseAbilityNames[AbilityNames.RESOLVE_HERALD_OVERPAY] = JapaneseCardNames[CardNames.HERALD].singular;
JapaneseAbilityNames[AbilityNames.MASTERPIECE_OVERPAY] = "過払い";
JapaneseAbilityNames[AbilityNames.RESOLVE_MASTERPIECE_OVERPAY] = JapaneseCardNames[CardNames.MASTERPIECE].singular;
JapaneseAbilityNames[AbilityNames.STONEMASON_OVERPAY] = "過払い";
JapaneseAbilityNames[AbilityNames.RESOLVE_STONEMASON_OVERPAY] = JapaneseCardNames[CardNames.STONEMASON].singular;
JapaneseAbilityNames[AbilityNames.EXCHANGE] = "交換";
JapaneseAbilityNames[AbilityNames.SHUFFLE] = "シャッフル";
JapaneseAbilityNames[AbilityNames.AMULET] = JapaneseCardNames[CardNames.AMULET].singular;
JapaneseAbilityNames[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = JapaneseCardNames[CardNames.CARAVAN_GUARD].singular;
JapaneseAbilityNames[AbilityNames.DUNGEON_ABILITY] = JapaneseCardNames[CardNames.DUNGEON].singular;
JapaneseAbilityNames[AbilityNames.GEAR_ABILITY] = JapaneseCardNames[CardNames.GEAR].singular;
JapaneseAbilityNames[AbilityNames.MESSENGER_GAIN] = JapaneseCardNames[CardNames.MESSENGER].singular;
JapaneseAbilityNames[AbilityNames.BRIDGE_TROLL_ABILITY] = JapaneseCardNames[CardNames.BRIDGE_TROLL].singular;
JapaneseAbilityNames[AbilityNames.HAUNTED_WOODS_DURATION] = JapaneseCardNames[CardNames.HAUNTED_WOODS].singular + "のドロー";
JapaneseAbilityNames[AbilityNames.HAUNTED_WOODS_TOPDECK] = JapaneseCardNames[CardNames.HAUNTED_WOODS].singular + "のアタック";
JapaneseAbilityNames[AbilityNames.LOST_CITY] = JapaneseCardNames[CardNames.LOST_CITY].singular;
JapaneseAbilityNames[AbilityNames.WINE_MERCHANT_DISCARD] = JapaneseCardNames[CardNames.WINE_MERCHANT].singular;
JapaneseAbilityNames[AbilityNames.HIRELING] = JapaneseCardNames[CardNames.HIRELING].singular;
JapaneseAbilityNames[AbilityNames.SWAMP_HAG_DURATION] = JapaneseCardNames[CardNames.SWAMP_HAG].singular;
JapaneseAbilityNames[AbilityNames.TRASH_TOKEN_ABILITY] = JapaneseCardNames[CardNames.PLAN].singular;
JapaneseAbilityNames[AbilityNames.EMPORIUM_GAIN_POINTS] = JapaneseCardNames[CardNames.EMPORIUM].singular;
JapaneseAbilityNames[AbilityNames.ROCKS_GAINS_SILVER] = JapaneseCardNames[CardNames.ROCKS].singular;
JapaneseAbilityNames[AbilityNames.FORTUNE] = JapaneseCardNames[CardNames.FORTUNE].singular;
JapaneseAbilityNames[AbilityNames.CRUMBLING_CASTLE] = JapaneseCardNames[CardNames.CRUMBLING_CASTLE].singular;
JapaneseAbilityNames[AbilityNames.HAUNTED_CASTLE] = JapaneseCardNames[CardNames.HAUNTED_CASTLE].singular;
JapaneseAbilityNames[AbilityNames.SPRAWLING_CASTLE] = JapaneseCardNames[CardNames.SPRAWLING_CASTLE].singular;
JapaneseAbilityNames[AbilityNames.GRAND_CASTLE] = JapaneseCardNames[CardNames.GRAND_CASTLE].singular;
JapaneseAbilityNames[AbilityNames.ENCHANTRESS_ABILITY] = JapaneseCardNames[CardNames.ENCHANTRESS].singular;
JapaneseAbilityNames[AbilityNames.ENCHANTRESS_CANTRIP] = "キャントリップ化";
JapaneseAbilityNames[AbilityNames.TEMPLE] = JapaneseCardNames[CardNames.TEMPLE].singular;
JapaneseAbilityNames[AbilityNames.VILLA] = JapaneseCardNames[CardNames.VILLA].singular;
JapaneseAbilityNames[AbilityNames.ARCHIVE_ABILITY] = JapaneseCardNames[CardNames.ARCHIVE].singular;
JapaneseAbilityNames[AbilityNames.CAPITAL_TAKE_DEBT] = JapaneseCardNames[CardNames.CAPITAL].singular;
JapaneseAbilityNames[AbilityNames.CHARM] = JapaneseCardNames[CardNames.CHARM].singular;
JapaneseAbilityNames[AbilityNames.FORUM] = JapaneseCardNames[CardNames.FORUM].singular;
JapaneseAbilityNames[AbilityNames.HERMIT] = JapaneseCardNames[CardNames.HERMIT].singular;
JapaneseAbilityNames[AbilityNames.DONATE] = JapaneseCardNames[CardNames.DONATE].singular;
JapaneseAbilityNames[AbilityNames.TAX_RECEIVE] = JapaneseCardNames[CardNames.TAX].singular;
JapaneseAbilityNames[AbilityNames.DOMINATE] = JapaneseCardNames[CardNames.DOMINATE].singular;
JapaneseAbilityNames[AbilityNames.VP_TO_AQUEDUCT] = JapaneseCardNames[CardNames.AQUEDUCT].singular + "へのvp移動";
JapaneseAbilityNames[AbilityNames.VP_FROM_AQUEDUCT] = JapaneseCardNames[CardNames.AQUEDUCT].singular + "からのvp取得";
JapaneseAbilityNames[AbilityNames.ARENA_DISCARD_ACTION] = JapaneseCardNames[CardNames.ARENA].singular;
JapaneseAbilityNames[AbilityNames.VP_FROM_BASILICA] = JapaneseCardNames[CardNames.BASILICA].singular;
JapaneseAbilityNames[AbilityNames.VP_FROM_BATHS] = JapaneseCardNames[CardNames.BATHS].singular;
JapaneseAbilityNames[AbilityNames.VP_FROM_BATTLEFIELD] = JapaneseCardNames[CardNames.BATTLEFIELD].singular;
JapaneseAbilityNames[AbilityNames.VP_FROM_COLONNADE] = JapaneseCardNames[CardNames.COLONNADE].singular;
JapaneseAbilityNames[AbilityNames.VP_TO_DEFILED_SHRINE] = JapaneseCardNames[CardNames.DEFILED_SHRINE].singular + "へのvp移動";
JapaneseAbilityNames[AbilityNames.VP_FROM_DEFILED_SHRINE] = JapaneseCardNames[CardNames.DEFILED_SHRINE].singular + "からのvp取得";
JapaneseAbilityNames[AbilityNames.VP_FROM_LABYRINTH] = JapaneseCardNames[CardNames.LABYRINTH].singular;
JapaneseAbilityNames[AbilityNames.MOUNTAIN_PASS_BIDDING] = JapaneseCardNames[CardNames.MOUNTAIN_PASS].singular;
JapaneseAbilityNames[AbilityNames.TOMB] = JapaneseCardNames[CardNames.TOMB].singular;
JapaneseAbilityNames[AbilityNames.LIGHTHOUSE_COIN] = JapaneseCardNames[CardNames.LIGHTHOUSE].singular;
JapaneseAbilityNames[AbilityNames.REACTION] = "リアクション";
JapaneseAbilityNames[AbilityNames.CALL_GUIDE] = JapaneseCardNames[CardNames.GUIDE].singular + "呼び出し";
JapaneseAbilityNames[AbilityNames.CALL_RATCATCHER] = JapaneseCardNames[CardNames.RATCATCHER].singular + "呼び出し";
JapaneseAbilityNames[AbilityNames.CALL_TRANSMOGRIFY] = JapaneseCardNames[CardNames.TRANSMOGRIFY].singular + "呼び出し";
JapaneseAbilityNames[AbilityNames.CALL_TEACHER] = JapaneseCardNames[CardNames.TEACHER].singular + "呼び出し";
JapaneseAbilityNames[AbilityNames.CALL_DUPLICATE] = JapaneseCardNames[CardNames.DUPLICATE].singular + "呼び出し";
JapaneseAbilityNames[AbilityNames.CALL_COIN] = JapaneseCardNames[CardNames.COIN_OF_THE_REALM].singular + "呼び出し";
JapaneseAbilityNames[AbilityNames.CALL_ROYAL_CARRIAGE] = JapaneseCardNames[CardNames.ROYAL_CARRIAGE].singular + "呼び出し";
JapaneseAbilityNames[AbilityNames.START_OF_TURN] = "ターンの開始";
JapaneseAbilityNames[AbilityNames.START_BUY_PHASE] = "購入フェイズの開始";
JapaneseAbilityNames[AbilityNames.END_OF_BUY_PHASE] = "購入フェイズの終了";
JapaneseAbilityNames[AbilityNames.BETWEEN_TURNS] = "ターンの間";
JapaneseAbilityNames[AbilityNames.END_OF_TURN] = "ターンの終了";
JapaneseAbilityNames[AbilityNames.SUMMON_PLAY] = JapaneseCardNames[CardNames.SUMMON].singular;
JapaneseAbilityNames[AbilityNames.CARAVAN_GUARD_REACTION] = JapaneseCardNames[CardNames.CARAVAN_GUARD].singular;
JapaneseAbilityNames[AbilityNames.TRAVELLING_FAIR_REACTION] = JapaneseCardNames[CardNames.TRAVELLING_FAIR].singular;
JapaneseAbilityNames[AbilityNames.MOAT_REACTION] = JapaneseCardNames[CardNames.MOAT].singular;
JapaneseAbilityNames[AbilityNames.HORSE_TRADERS_REACTION] = JapaneseCardNames[CardNames.HORSE_TRADERS].singular;
JapaneseAbilityNames[AbilityNames.BEGGAR_REACTION] = JapaneseCardNames[CardNames.BEGGAR].singular;
JapaneseAbilityNames[AbilityNames.MARKET_SQUARE_REACTION] = JapaneseCardNames[CardNames.MARKET_SQUARE].singular;
JapaneseAbilityNames[AbilityNames.TRADER_REACTION] = JapaneseCardNames[CardNames.TRADER].singular;
JapaneseAbilityNames[AbilityNames.DIPLOMAT_REACTION] = JapaneseCardNames[CardNames.DIPLOMAT].singular;
JapaneseAbilityNames[AbilityNames.TOPDECK_REACTION] = "山札への移動";
JapaneseAbilityNames[AbilityNames.WATCHTOWER_TRASH_REACTION] = JapaneseCardNames[CardNames.WATCHTOWER].singular + "(廃棄)";
JapaneseAbilityNames[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = JapaneseCardNames[CardNames.WATCHTOWER].singular + "(山札に置く)";
JapaneseAbilityNames[AbilityNames.PRINCE_PLAY] = JapaneseCardNames[CardNames.PRINCE].singular;
JapaneseAbilityNames[AbilityNames.DELUDED_RETURNED] = "状態の返却";
JapaneseAbilityNames[AbilityNames.ENVIOUS_RETURNED] = "状態の返却";
JapaneseAbilityNames[AbilityNames.RECEIVE_BOON] = "祝福の享受";
JapaneseAbilityNames[AbilityNames.COBBLER_ABILITY] = JapaneseCardNames[CardNames.COBBLER].singular;
JapaneseAbilityNames[AbilityNames.CRYPT_ABILITY] = JapaneseCardNames[CardNames.CRYPT].singular;
JapaneseAbilityNames[AbilityNames.DEN_OF_SIN_ABILITY] = JapaneseCardNames[CardNames.DEN_OF_SIN].singular;
JapaneseAbilityNames[AbilityNames.FAITHFUL_HOUND] = JapaneseCardNames[CardNames.FAITHFUL_HOUND].singular;
JapaneseAbilityNames[AbilityNames.FAITHFUL_HOUND_RETURN] = JapaneseCardNames[CardNames.FAITHFUL_HOUND].singular;
JapaneseAbilityNames[AbilityNames.GHOST_TOWN_ABILITY] = JapaneseCardNames[CardNames.GHOST_TOWN].singular;
JapaneseAbilityNames[AbilityNames.GUARDIAN_COIN] = JapaneseCardNames[CardNames.GUARDIAN].singular;
JapaneseAbilityNames[AbilityNames.RAIDER_ABILITY] = JapaneseCardNames[CardNames.RAIDER].singular;
JapaneseAbilityNames[AbilityNames.SECRET_CAVE] = JapaneseCardNames[CardNames.SECRET_CAVE].singular;
JapaneseAbilityNames[AbilityNames.CEMETERY] = JapaneseCardNames[CardNames.CEMETERY].singular;
JapaneseAbilityNames[AbilityNames.HAUNTED_MIRROR] = JapaneseCardNames[CardNames.HAUNTED_MIRROR].singular;
JapaneseAbilityNames[AbilityNames.GHOST] = JapaneseCardNames[CardNames.GHOST].singular;
JapaneseAbilityNames[AbilityNames.REMOVE_FLIPPED_STATUS] = "X";
JapaneseAbilityNames[AbilityNames.LOST_IN_THE_WOODS] = JapaneseCardNames[CardNames.LOST_IN_THE_WOODS].singular;
JapaneseAbilityNames[AbilityNames.BLESSED_VILLAGE] = JapaneseCardNames[CardNames.BLESSED_VILLAGE].singular;
JapaneseAbilityNames[AbilityNames.RECEIVE_HEX] = "呪詛の発生";
JapaneseAbilityNames[AbilityNames.HOVEL_TRASH] = JapaneseCardNames[CardNames.HOVEL].singular;
JapaneseAbilityNames[AbilityNames.TUNNEL_REVEAL] = JapaneseCardNames[CardNames.TUNNEL].singular;
JapaneseAbilityNames[AbilityNames.CHANGELING_MAY_EXCHANGE] = JapaneseCardNames[CardNames.CHANGELING].singular + "への交換";

JapaneseAbilityNames[AbilityNames.DUCAT] = JapaneseCardNames[CardNames.DUCAT].singular;
JapaneseAbilityNames[AbilityNames.CARGO_SHIP_SET_ASIDE] = JapaneseCardNames[CardNames.CARGO_SHIP].singular;
JapaneseAbilityNames[AbilityNames.CARGO_SHIP_RETURN] = JapaneseCardNames[CardNames.CARGO_SHIP].singular;
JapaneseAbilityNames[AbilityNames.IMPROVE] = JapaneseCardNames[CardNames.IMPROVE].singular;
JapaneseAbilityNames[AbilityNames.PRIEST] = JapaneseCardNames[CardNames.PRIEST].singular;
JapaneseAbilityNames[AbilityNames.RESEARCH] = JapaneseCardNames[CardNames.RESEARCH].singular;
JapaneseAbilityNames[AbilityNames.SILK_MERCHANT] = JapaneseCardNames[CardNames.SILK_MERCHANT].singular;
JapaneseAbilityNames[AbilityNames.LACKEYS] = JapaneseCardNames[CardNames.LACKEYS].singular;
JapaneseAbilityNames[AbilityNames.SPICES] = JapaneseCardNames[CardNames.SPICES].singular;
JapaneseAbilityNames[AbilityNames.KEY] = JapaneseCardNames[CardNames.KEY].singular;
JapaneseAbilityNames[AbilityNames.TREASURE_CHEST] = JapaneseCardNames[CardNames.TREASURE_CHEST].singular;
JapaneseAbilityNames[AbilityNames.FLAG_BEARER] = JapaneseCardNames[CardNames.FLAG_BEARER].singular;
JapaneseAbilityNames[AbilityNames.CATHEDRAL] = JapaneseCardNames[CardNames.CATHEDRAL].singular;
JapaneseAbilityNames[AbilityNames.CITY_GATE] = JapaneseCardNames[CardNames.CITY_GATE].singular;
JapaneseAbilityNames[AbilityNames.PAGEANT] = JapaneseCardNames[CardNames.PAGEANT].singular;
JapaneseAbilityNames[AbilityNames.SEWERS_MAY_TRASH] = JapaneseCardNames[CardNames.SEWERS].singular;
JapaneseAbilityNames[AbilityNames.EXPLORATION] = JapaneseCardNames[CardNames.EXPLORATION].singular;
JapaneseAbilityNames[AbilityNames.FAIR] = JapaneseCardNames[CardNames.FAIR].singular;
JapaneseAbilityNames[AbilityNames.SILOS] = JapaneseCardNames[CardNames.SILOS].singular;
JapaneseAbilityNames[AbilityNames.ACADEMY] = JapaneseCardNames[CardNames.ACADEMY].singular;
JapaneseAbilityNames[AbilityNames.GUILDHALL] = JapaneseCardNames[CardNames.GUILDHALL].singular;
JapaneseAbilityNames[AbilityNames.PIAZZA] = JapaneseCardNames[CardNames.PIAZZA].singular;
JapaneseAbilityNames[AbilityNames.BARRACKS] = JapaneseCardNames[CardNames.BARRACKS].singular;
JapaneseAbilityNames[AbilityNames.CROP_ROTATION] = JapaneseCardNames[CardNames.CROP_ROTATION].singular;
JapaneseAbilityNames[AbilityNames.INNOVATION] = JapaneseCardNames[CardNames.INNOVATION].singular;
JapaneseAbilityNames[AbilityNames.CITADEL] = JapaneseCardNames[CardNames.CITADEL].singular;
JapaneseAbilityNames[AbilityNames.SINISTER_PLOT] = JapaneseCardNames[CardNames.SINISTER_PLOT].singular;
JapaneseAbilityNames[AbilityNames.ENCAMPMENT_RETURNED] = JapaneseCardNames[CardNames.ENCAMPMENT].singular;
JapaneseAbilityNames[AbilityNames.EMBARGO_GAIN_CURSE] = JapaneseCardNames[CardNames.EMBARGO].singular;
JapaneseAbilityNames[AbilityNames.POSSESSION_GAIN_INSTEAD] = JapaneseCardNames[CardNames.POSSESSION].singular;
JapaneseAbilityNames[AbilityNames.TRADE_ROUTE_MOVE_TOKEN] = JapaneseCardNames[CardNames.TRADE_ROUTE].singular;
JapaneseAbilityNames[AbilityNames.CAPTAIN] = JapaneseCardNames[CardNames.CAPTAIN].singular;
JapaneseAbilityNames[AbilityNames.CHURCH] = JapaneseCardNames[CardNames.CHURCH].singular;
JapaneseAbilityNames[AbilityNames.POSSESSION_SETS_ASIDE] = JapaneseCardNames[CardNames.POSSESSION].singular;
JapaneseAbilityNames[AbilityNames.MINT] = JapaneseCardNames[CardNames.MINT].singular;

JapaneseAbilityNames[AbilityNames.SLEIGH_TOPDECK_REACTION] = JapaneseCardNames[CardNames.SLEIGH].singular + "(山札に置く)";
JapaneseAbilityNames[AbilityNames.SLEIGH_PUT_IN_HAND_REACTION] = JapaneseCardNames[CardNames.SLEIGH].singular + "(手札に加える)";
JapaneseAbilityNames[AbilityNames.BLACK_CAT_REACTION] = JapaneseCardNames[CardNames.BLACK_CAT].singular;
JapaneseAbilityNames[AbilityNames.DISCARD_FROM_EXILE] = "追放マットから捨て札";
JapaneseAbilityNames[AbilityNames.EXILE_BY_NAME] = "追放";
JapaneseAbilityNames[AbilityNames.SHEEPDOG_REACTION] = JapaneseCardNames[CardNames.SHEEPDOG].singular;
JapaneseAbilityNames[AbilityNames.CAVALRY] = JapaneseCardNames[CardNames.CAVALRY].singular;
JapaneseAbilityNames[AbilityNames.HOSTELRY] = JapaneseCardNames[CardNames.HOSTELRY].singular;
JapaneseAbilityNames[AbilityNames.VILLAGE_GREEN_VILLAGE] = JapaneseCardNames[CardNames.VILLAGE_GREEN].singular;
JapaneseAbilityNames[AbilityNames.VILLAGE_GREEN_PLAY] = JapaneseCardNames[CardNames.VILLAGE_GREEN].singular;
JapaneseAbilityNames[AbilityNames.BARGE] = JapaneseCardNames[CardNames.BARGE].singular;
JapaneseAbilityNames[AbilityNames.FALCONER_REACTION] = JapaneseCardNames[CardNames.FALCONER].singular;
JapaneseAbilityNames[AbilityNames.GATEKEEPER_EXILE] = JapaneseCardNames[CardNames.GATEKEEPER].singular;
JapaneseAbilityNames[AbilityNames.GATEKEEPER_DURATION] = JapaneseCardNames[CardNames.GATEKEEPER].singular;
JapaneseAbilityNames[AbilityNames.LIVERY] = JapaneseCardNames[CardNames.LIVERY].singular;
JapaneseAbilityNames[AbilityNames.MASTERMIND] = JapaneseCardNames[CardNames.MASTERMIND].singular;
JapaneseAbilityNames[AbilityNames.DELAY_PLAY] = JapaneseCardNames[CardNames.DELAY].singular;
JapaneseAbilityNames[AbilityNames.INVEST] = JapaneseCardNames[CardNames.INVEST].singular;
JapaneseAbilityNames[AbilityNames.REAP_PLAY] = JapaneseCardNames[CardNames.REAP].singular;
JapaneseAbilityNames[AbilityNames.PLUS_CARD_TOKEN] = "＋１　カードを引く";
JapaneseAbilityNames[AbilityNames.PLUS_BUY_TOKEN] = "＋１　購入";
JapaneseAbilityNames[AbilityNames.PLUS_ACTION_TOKEN] = "＋１　アクション";
JapaneseAbilityNames[AbilityNames.PLUS_COIN_TOKEN] = "＋＄１";
JapaneseAbilityNames[AbilityNames.KILN] = JapaneseCardNames[CardNames.KILN].singular;
JapaneseAbilityNames[AbilityNames.TURTLE_PLAY] = JapaneseCardNames[CardNames.WAY_OF_THE_TURTLE].singular;
JapaneseAbilityNames[AbilityNames.GIVE_PLUS_CARDS] = JapaneseAbilityNames[AbilityNames.PLUS_CARD_TOKEN];
JapaneseAbilityNames[AbilityNames.GIVE_PLUS_BUYS] = JapaneseAbilityNames[AbilityNames.PLUS_BUY_TOKEN];
JapaneseAbilityNames[AbilityNames.GIVE_PLUS_ACTIONS] = JapaneseAbilityNames[AbilityNames.PLUS_ACTION_TOKEN];
JapaneseAbilityNames[AbilityNames.GIVE_PLUS_COINS] = JapaneseAbilityNames[AbilityNames.PLUS_COIN_TOKEN];

// Allies
JapaneseAbilityNames[AbilityNames.CONJURER] = JapaneseCardNames[CardNames.CONJURER].singular;
JapaneseAbilityNames[AbilityNames.CONTRACT_ABILITY] = JapaneseCardNames[CardNames.CONTRACT].singular;
JapaneseAbilityNames[AbilityNames.ENCHANTRESS_REPLACE_ABILITY] = JapaneseCardNames[CardNames.ENCHANTRESS].singular;
JapaneseAbilityNames[AbilityNames.GALLERIA] = JapaneseCardNames[CardNames.GALLERIA].singular;
JapaneseAbilityNames[AbilityNames.GARRISON_ADD_TOKEN] = JapaneseCardNames[CardNames.GARRISON].singular;
JapaneseAbilityNames[AbilityNames.GARRISON_REMOVE_ABILITY] = JapaneseCardNames[CardNames.GARRISON].singular;
JapaneseAbilityNames[AbilityNames.GUILDMASTER] = JapaneseCardNames[CardNames.GUILDMASTER].singular;
JapaneseAbilityNames[AbilityNames.HIGHWAYMAN_NEXT_TURN_ABILITY] = JapaneseCardNames[CardNames.HIGHWAYMAN].singular;
JapaneseAbilityNames[AbilityNames.HIGHWAYMAN_NULLIFIED] = JapaneseCardNames[CardNames.HIGHWAYMAN].singular;
JapaneseAbilityNames[AbilityNames.HIGHWAYMAN_REPLACE_ABILITY] = JapaneseCardNames[CardNames.HIGHWAYMAN].singular;
JapaneseAbilityNames[AbilityNames.IMPORTER_ABILITY] = JapaneseCardNames[CardNames.IMPORTER].singular;
JapaneseAbilityNames[AbilityNames.LICH] = JapaneseCardNames[CardNames.LICH].singular;
JapaneseAbilityNames[AbilityNames.ROYAL_GALLEY_PLAY] = JapaneseCardNames[CardNames.ROYAL_GALLEY].singular;
JapaneseAbilityNames[AbilityNames.ROYAL_GALLEY_SET_ASIDE] = JapaneseCardNames[CardNames.ROYAL_GALLEY].singular;
JapaneseAbilityNames[AbilityNames.SKIRMISHER] = JapaneseCardNames[CardNames.SKIRMISHER].singular;
JapaneseAbilityNames[AbilityNames.STRONGHOLD_ABILITY] = JapaneseCardNames[CardNames.STRONGHOLD].singular;
JapaneseAbilityNames[AbilityNames.SYCOPHANT] = JapaneseCardNames[CardNames.SYCOPHANT].singular;
JapaneseAbilityNames[AbilityNames.TERRITORY_GAIN] = JapaneseCardNames[CardNames.TERRITORY].singular;
JapaneseAbilityNames[AbilityNames.WARLORD_ABILITY] = JapaneseCardNames[CardNames.WARLORD].singular;

JapaneseAbilityNames[AbilityNames.ARCHITECTS_GUILD] = JapaneseCardNames[CardNames.ARCHITECTS_GUILD].singular;
JapaneseAbilityNames[AbilityNames.BAND_OF_NOMADS_ACTION] = "アクション";
JapaneseAbilityNames[AbilityNames.BAND_OF_NOMADS_BUY] = "購入";
JapaneseAbilityNames[AbilityNames.BAND_OF_NOMADS_CARD] = "カード";
JapaneseAbilityNames[AbilityNames.CAVE_DWELLERS] = JapaneseCardNames[CardNames.CAVE_DWELLERS].singular;
JapaneseAbilityNames[AbilityNames.CIRCLE_OF_WITCHES] = JapaneseCardNames[CardNames.CIRCLE_OF_WITCHES].singular;
JapaneseAbilityNames[AbilityNames.CITY_STATE] = JapaneseCardNames[CardNames.CITY_STATE].singular;
JapaneseAbilityNames[AbilityNames.CITY_STATE_PLAY] = JapaneseCardNames[CardNames.CITY_STATE].singular;
JapaneseAbilityNames[AbilityNames.COASTAL_HAVEN] = JapaneseCardNames[CardNames.COASTAL_HAVEN].singular;
JapaneseAbilityNames[AbilityNames.CRAFTERS_GUILD] = JapaneseCardNames[CardNames.CRAFTERS_GUILD].singular;
JapaneseAbilityNames[AbilityNames.DESERT_GUIDES] = JapaneseCardNames[CardNames.DESERT_GUIDES].singular;
JapaneseAbilityNames[AbilityNames.FAMILY_OF_INVENTORS] = JapaneseCardNames[CardNames.FAMILY_OF_INVENTORS].singular;
JapaneseAbilityNames[AbilityNames.FELLOWSHIP_OF_SCRIBES] = JapaneseCardNames[CardNames.FELLOWSHIP_OF_SCRIBES].singular;
JapaneseAbilityNames[AbilityNames.FOREST_DWELLERS] = JapaneseCardNames[CardNames.FOREST_DWELLERS].singular;
JapaneseAbilityNames[AbilityNames.GANG_OF_PICKPOCKETS] = JapaneseCardNames[CardNames.GANG_OF_PICKPOCKETS].singular;
JapaneseAbilityNames[AbilityNames.ISLAND_FOLK] = JapaneseCardNames[CardNames.ISLAND_FOLK].singular;
JapaneseAbilityNames[AbilityNames.LEAGUE_OF_BANKERS] = JapaneseCardNames[CardNames.LEAGUE_OF_BANKERS].singular;
JapaneseAbilityNames[AbilityNames.LEAGUE_OF_SHOPKEEPERS] = JapaneseCardNames[CardNames.LEAGUE_OF_SHOPKEEPERS].singular;
JapaneseAbilityNames[AbilityNames.MARKET_TOWNS] = JapaneseCardNames[CardNames.MARKET_TOWNS].singular;
JapaneseAbilityNames[AbilityNames.MOUNTAIN_FOLK] = JapaneseCardNames[CardNames.MOUNTAIN_FOLK].singular;
JapaneseAbilityNames[AbilityNames.PEACEFUL_CULT] = JapaneseCardNames[CardNames.PEACEFUL_CULT].singular;
JapaneseAbilityNames[AbilityNames.TRAPPERS_LODGE] = JapaneseCardNames[CardNames.TRAPPERS_LODGE].singular;
JapaneseAbilityNames[AbilityNames.WOODWORKERS_GUILD] = JapaneseCardNames[CardNames.WOODWORKERS_GUILD].singular;
JapaneseAbilityNames[AbilityNames.ASTROLABE_ABILITY] = JapaneseCardNames[CardNames.ASTROLABE].singular;
JapaneseAbilityNames[AbilityNames.BLOCKADE_ABILITY] = JapaneseCardNames[CardNames.BLOCKADE].singular;
JapaneseAbilityNames[AbilityNames.CORSAIR_NEXT_TURN_ABILITY] = JapaneseCardNames[CardNames.CORSAIR].singular;
JapaneseAbilityNames[AbilityNames.CORSAIR_TRASH] = JapaneseCardNames[CardNames.CORSAIR].singular;
JapaneseAbilityNames[AbilityNames.MONKEY_NEXT_TURN_ABILITY] = JapaneseCardNames[CardNames.MONKEY].singular;
JapaneseAbilityNames[AbilityNames.PIRATE_ABILITY] = JapaneseCardNames[CardNames.PIRATE].singular;
JapaneseAbilityNames[AbilityNames.PIRATE_REACTION] = JapaneseCardNames[CardNames.PIRATE].singular;
JapaneseAbilityNames[AbilityNames.SAILOR_NEXT_TURN] = JapaneseCardNames[CardNames.SAILOR].singular;
JapaneseAbilityNames[AbilityNames.SAILOR_REACTION] = JapaneseCardNames[CardNames.SAILOR].singular;
JapaneseAbilityNames[AbilityNames.SEA_WITCH_ABILITY] = JapaneseCardNames[CardNames.SEA_WITCH].singular;
JapaneseAbilityNames[AbilityNames.TIDE_POOLS_ABILITY] = JapaneseCardNames[CardNames.TIDE_POOLS].singular;
JapaneseAbilityNames[AbilityNames.TREASURY] = JapaneseCardNames[CardNames.TREASURY].singular;
JapaneseAbilityNames[AbilityNames.CLERK_ABILITY] = JapaneseCardNames[CardNames.CLERK].singular;
JapaneseAbilityNames[AbilityNames.BERSERKER] = JapaneseCardNames[CardNames.BERSERKER].singular;
JapaneseAbilityNames[AbilityNames.CAULDRON_CURSING] = JapaneseCardNames[CardNames.CAULDRON].singular;
JapaneseAbilityNames[AbilityNames.GUARD_DOG_REACTION] = JapaneseCardNames[CardNames.GUARD_DOG].singular;
JapaneseAbilityNames[AbilityNames.NOMADS] = JapaneseCardNames[CardNames.NOMADS].singular;
JapaneseAbilityNames[AbilityNames.SOUK] = JapaneseCardNames[CardNames.SOUK].singular;
JapaneseAbilityNames[AbilityNames.TRAIL_REACTION] = JapaneseCardNames[CardNames.TRAIL].singular;
JapaneseAbilityNames[AbilityNames.WEAVER_PLAY] = JapaneseCardNames[CardNames.WEAVER].singular;
JapaneseAbilityNames[AbilityNames.ALCHEMIST_TOPDECK] = JapaneseCardNames[CardNames.ALCHEMIST].singular;
JapaneseAbilityNames[AbilityNames.HERBALIST_TOPDECK] = JapaneseCardNames[CardNames.HERBALIST].singular;
JapaneseAbilityNames[AbilityNames.SCHEME_TOPDECK] = JapaneseCardNames[CardNames.SCHEME].singular;
JapaneseAbilityNames[AbilityNames.FROG_TOPDECK] = JapaneseCardNames[CardNames.WAY_OF_THE_FROG].singular;
JapaneseAbilityNames[AbilityNames.MERCHANT_CAMP_TOPDECK] = JapaneseCardNames[CardNames.MERCHANT_CAMP].singular;
JapaneseAbilityNames[AbilityNames.WALLED_VILLAGE_TOPDECK] = JapaneseCardNames[CardNames.WALLED_VILLAGE].singular;
JapaneseAbilityNames[AbilityNames.TENT_TOPDECK] = JapaneseCardNames[CardNames.TENT].singular;
JapaneseAbilityNames[AbilityNames.HORN_TOPDECK] = JapaneseCardNames[CardNames.HORN].singular;
JapaneseAbilityNames[AbilityNames.PAGE_EXCHANGE] = JapaneseCardNames[CardNames.TREASURE_HUNTER].singular;
JapaneseAbilityNames[AbilityNames.TREASURE_HUNTER_EXCHANGE] = JapaneseCardNames[CardNames.WARRIOR].singular;
JapaneseAbilityNames[AbilityNames.WARRIOR_EXCHANGE] = JapaneseCardNames[CardNames.HERO].singular;
JapaneseAbilityNames[AbilityNames.HERO_EXCHANGE] = JapaneseCardNames[CardNames.CHAMPION].singular;
JapaneseAbilityNames[AbilityNames.PEASANT_EXCHANGE] = JapaneseCardNames[CardNames.SOLDIER].singular;
JapaneseAbilityNames[AbilityNames.SOLDIER_EXCHANGE] = JapaneseCardNames[CardNames.FUGITIVE].singular;
JapaneseAbilityNames[AbilityNames.FUGITIVE_EXCHANGE] = JapaneseCardNames[CardNames.DISCIPLE].singular;
JapaneseAbilityNames[AbilityNames.DISCIPLE_EXCHANGE] = JapaneseCardNames[CardNames.TEACHER].singular;

JapaneseAbilityNames[AbilityNames.CAGE_TRASH] = JapaneseCardNames[CardNames.CAGE].singular;
JapaneseAbilityNames[AbilityNames.CAGE_PUT_IN_HAND] = JapaneseCardNames[CardNames.CAGE].singular;
JapaneseAbilityNames[AbilityNames.GROTTO] = JapaneseCardNames[CardNames.GROTTO].singular;
JapaneseAbilityNames[AbilityNames.JEWELLED_EGG_GAIN_LOOT] = JapaneseCardNames[CardNames.JEWELLED_EGG].singular;
JapaneseAbilityNames[AbilityNames.SEARCH] = JapaneseCardNames[CardNames.SEARCH].singular;
JapaneseAbilityNames[AbilityNames.SHAMAN] = JapaneseCardNames[CardNames.SHAMAN].singular;
JapaneseAbilityNames[AbilityNames.SECLUDED_SHRINE] = JapaneseCardNames[CardNames.SECLUDED_SHRINE].singular;
JapaneseAbilityNames[AbilityNames.SIREN_GAIN] = JapaneseCardNames[CardNames.SIREN].singular;
JapaneseAbilityNames[AbilityNames.SIREN_DURATION] = JapaneseCardNames[CardNames.SIREN].singular;
JapaneseAbilityNames[AbilityNames.STOWAWAY_DRAW] = JapaneseCardNames[CardNames.STOWAWAY].singular;
JapaneseAbilityNames[AbilityNames.STOWAWAY_REACTION] = JapaneseCardNames[CardNames.STOWAWAY].singular;
JapaneseAbilityNames[AbilityNames.TASKMASTER] = JapaneseCardNames[CardNames.TASKMASTER].singular;
JapaneseAbilityNames[AbilityNames.ABUNDANCE] = JapaneseCardNames[CardNames.ABUNDANCE].singular;
JapaneseAbilityNames[AbilityNames.CABIN_BOY] = JapaneseCardNames[CardNames.CABIN_BOY].singular;
JapaneseAbilityNames[AbilityNames.CRUCIBLE] = JapaneseCardNames[CardNames.CRUCIBLE].singular;
JapaneseAbilityNames[AbilityNames.FLAGSHIP_ACTIVATE] = JapaneseCardNames[CardNames.FLAGSHIP].singular;
JapaneseAbilityNames[AbilityNames.FLAGSHIP_REPLAY] = JapaneseCardNames[CardNames.FLAGSHIP].singular;
JapaneseAbilityNames[AbilityNames.GONDOLA_DURATION] = JapaneseCardNames[CardNames.GONDOLA].singular;
JapaneseAbilityNames[AbilityNames.GONDOLA_PLAY] = JapaneseCardNames[CardNames.GONDOLA].singular;
JapaneseAbilityNames[AbilityNames.HARBOR_VILLAGE] = JapaneseCardNames[CardNames.HARBOR_VILLAGE].singular;
JapaneseAbilityNames[AbilityNames.LANDING_PARTY] = JapaneseCardNames[CardNames.LANDING_PARTY].singular;
JapaneseAbilityNames[AbilityNames.MAPMAKER] = JapaneseCardNames[CardNames.MAPMAKER].singular;
JapaneseAbilityNames[AbilityNames.ROPE] = JapaneseCardNames[CardNames.ROPE].singular;
JapaneseAbilityNames[AbilityNames.BURIED_TREASURE_PLAY] = JapaneseCardNames[CardNames.BURIED_TREASURE].singular;
JapaneseAbilityNames[AbilityNames.BURIED_TREASURE_DURATION] = JapaneseCardNames[CardNames.BURIED_TREASURE].singular;
JapaneseAbilityNames[AbilityNames.CREW_DURATION] = JapaneseCardNames[CardNames.CREW].singular;
JapaneseAbilityNames[AbilityNames.CUTTHROAT_GAIN] = JapaneseCardNames[CardNames.CUTTHROAT].singular;
JapaneseAbilityNames[AbilityNames.ENLARGE] = JapaneseCardNames[CardNames.ENLARGE].singular;
JapaneseAbilityNames[AbilityNames.FRIGATE_DURATION] = JapaneseCardNames[CardNames.FRIGATE].singular;
JapaneseAbilityNames[AbilityNames.FRIGATE_DISCARD] = JapaneseCardNames[CardNames.FRIGATE].singular;
JapaneseAbilityNames[AbilityNames.LONGSHIP_DURATION] = JapaneseCardNames[CardNames.LONGSHIP].singular;
JapaneseAbilityNames[AbilityNames.MINING_ROAD] = JapaneseCardNames[CardNames.MINING_ROAD].singular;
JapaneseAbilityNames[AbilityNames.QUARTERMASTER] = JapaneseCardNames[CardNames.QUARTERMASTER].singular;
JapaneseAbilityNames[AbilityNames.TRICKSTER_REACTION] = JapaneseCardNames[CardNames.TRICKSTER].singular;
JapaneseAbilityNames[AbilityNames.TRICKSTER_RETURN_TO_HAND] = JapaneseCardNames[CardNames.TRICKSTER].singular;
JapaneseAbilityNames[AbilityNames.WEALTHY_VILLAGE] = JapaneseCardNames[CardNames.WEALTHY_VILLAGE].singular;
JapaneseAbilityNames[AbilityNames.DELIVER_SET_ASIDE] = JapaneseCardNames[CardNames.DELIVER].singular;
JapaneseAbilityNames[AbilityNames.DELIVER_RETURN] = JapaneseCardNames[CardNames.DELIVER].singular;
JapaneseAbilityNames[AbilityNames.RUSH] = JapaneseCardNames[CardNames.RUSH].singular;
JapaneseAbilityNames[AbilityNames.MIRROR] = JapaneseCardNames[CardNames.MIRROR].singular;
JapaneseAbilityNames[AbilityNames.PREPARE] = JapaneseCardNames[CardNames.PREPARE].singular;
JapaneseAbilityNames[AbilityNames.AMPHORA] = JapaneseCardNames[CardNames.AMPHORA].singular;
JapaneseAbilityNames[AbilityNames.DOUBLOONS] = JapaneseCardNames[CardNames.DOUBLOONS].singular;
JapaneseAbilityNames[AbilityNames.ENDLESS_CHALICE] = JapaneseCardNames[CardNames.ENDLESS_CHALICE].singular;
JapaneseAbilityNames[AbilityNames.FIGUREHEAD] = JapaneseCardNames[CardNames.FIGUREHEAD].singular;
JapaneseAbilityNames[AbilityNames.JEWELS] = JapaneseCardNames[CardNames.JEWELS].singular;
JapaneseAbilityNames[AbilityNames.PUZZLE_BOX_RETURN] = JapaneseCardNames[CardNames.PUZZLE_BOX].singular;
JapaneseAbilityNames[AbilityNames.SHIELD_REACTION] = JapaneseCardNames[CardNames.SHIELD].singular;
JapaneseAbilityNames[AbilityNames.SPELL_SCROLL] = JapaneseCardNames[CardNames.SPELL_SCROLL].singular;
JapaneseAbilityNames[AbilityNames.CURSED_GAIN] = JapaneseCardNames[CardNames.CURSED].singular;
JapaneseAbilityNames[AbilityNames.FAWNING_GAIN] = JapaneseCardNames[CardNames.FAWNING].singular;
JapaneseAbilityNames[AbilityNames.SCHEDULER] = JapaneseCardNames[CardNames.FRIENDLY].singular;
JapaneseAbilityNames[AbilityNames.FRIENDLY_ABILITY] = JapaneseCardNames[CardNames.FRIENDLY].singular;
JapaneseAbilityNames[AbilityNames.HASTY_SET_ASIDE] = JapaneseCardNames[CardNames.HASTY].singular;
JapaneseAbilityNames[AbilityNames.HASTY_PLAY] = JapaneseCardNames[CardNames.HASTY].singular;
JapaneseAbilityNames[AbilityNames.INHERITED] = JapaneseCardNames[CardNames.INHERITED].singular;
JapaneseAbilityNames[AbilityNames.INSPIRING] = JapaneseCardNames[CardNames.INSPIRING].singular;
JapaneseAbilityNames[AbilityNames.NEARBY] = JapaneseCardNames[CardNames.NEARBY].singular;
JapaneseAbilityNames[AbilityNames.PATIENT_SCHEDULER] = JapaneseCardNames[CardNames.PATIENT].singular;
JapaneseAbilityNames[AbilityNames.PATIENT_SET_ASIDE] = JapaneseCardNames[CardNames.PATIENT].singular;
JapaneseAbilityNames[AbilityNames.PATIENT_PLAY] = JapaneseCardNames[CardNames.PATIENT].singular;
JapaneseAbilityNames[AbilityNames.PIOUS] = JapaneseCardNames[CardNames.PIOUS].singular;
JapaneseAbilityNames[AbilityNames.RECKLESS_REPEAT] = JapaneseCardNames[CardNames.RECKLESS].singular;
JapaneseAbilityNames[AbilityNames.RECKLESS_RETURN] = JapaneseCardNames[CardNames.RECKLESS].singular;
JapaneseAbilityNames[AbilityNames.RICH_GAIN] = JapaneseCardNames[CardNames.RICH].singular;
JapaneseAbilityNames[AbilityNames.SHY] = JapaneseCardNames[CardNames.SHY].singular;
JapaneseAbilityNames[AbilityNames.TIRELESS_SET_ASIDE] = JapaneseCardNames[CardNames.TIRELESS].singular;
JapaneseAbilityNames[AbilityNames.TIRELESS_RETURN] = JapaneseCardNames[CardNames.TIRELESS].singular;
JapaneseAbilityNames[AbilityNames.STAR_CHART] = JapaneseCardNames[CardNames.STAR_CHART].singular;
JapaneseAbilityNames[AbilityNames.ORDER_OF_ASTROLOGERS] = JapaneseCardNames[CardNames.ORDER_OF_ASTROLOGERS].singular;
JapaneseAbilityNames[AbilityNames.ORDER_OF_MASONS] = JapaneseCardNames[CardNames.ORDER_OF_MASONS].singular;
JapaneseAbilityNames[AbilityNames.FATED] = JapaneseCardNames[CardNames.FATED].singular;
JapaneseAbilityNames[AbilityNames.AVOID] = JapaneseCardNames[CardNames.AVOID].singular;
JapaneseAbilityNames[AbilityNames.HERBALIST_ASK_FOR_DISCARD] = JapaneseCardNames[CardNames.HERBALIST].singular;
JapaneseAbilityNames[AbilityNames.SCHEME_ASK_FOR_DISCARD] = JapaneseCardNames[CardNames.SCHEME].singular;
JapaneseAbilityNames[AbilityNames.TRICKSTER_ASK_FOR_DISCARD] = JapaneseCardNames[CardNames.TRICKSTER].singular;

JapaneseAbilityNames[AbilityNames.SAUNA_TRASH_ABILITY] = JapaneseCardNames[CardNames.SAUNA].singular;
JapaneseAbilityNames[AbilityNames.CHAMELEON_RESOLUTION] = JapaneseCardNames[CardNames.WAY_OF_THE_CHAMELEON].singular;
JapaneseAbilityNames[AbilityNames.RETURN_MINUS_COIN_TOKEN] = "-$1トークンを戻す";
JapaneseAbilityNames[AbilityNames.MERCHANT_GUILD] = JapaneseCardNames[CardNames.MERCHANT_GUILD].singular;
JapaneseAbilityNames[AbilityNames.MARCHLAND] = JapaneseCardNames[CardNames.MARCHLAND].singular;
JapaneseAbilityNames[AbilityNames.FARMHANDS_SET_ASIDE] = JapaneseCardNames[CardNames.FARMHANDS].singular;
JapaneseAbilityNames[AbilityNames.FARMHANDS_PLAY] = JapaneseCardNames[CardNames.FARMHANDS].singular;
JapaneseAbilityNames[AbilityNames.JOUST_DISCARD] = JapaneseCardNames[CardNames.JOUST].singular;
JapaneseAbilityNames[AbilityNames.INFIRMARY_OVERPAY] = JapaneseCardNames[CardNames.INFIRMARY].singular;
JapaneseAbilityNames[AbilityNames.RESOLVE_INFIRMARY_OVERPAY] = JapaneseCardNames[CardNames.INFIRMARY].singular;
JapaneseAbilityNames[AbilityNames.DRAW_FROM_FOOTPAD] = JapaneseCardNames[CardNames.FOOTPAD].singular;
JapaneseAbilityNames[AbilityNames.FARRIER_OVERPAY] = JapaneseCardNames[CardNames.FARRIER].singular;
JapaneseAbilityNames[AbilityNames.RESOLVE_FARRIER_OVERPAY] = JapaneseCardNames[CardNames.FARRIER].singular;
JapaneseAbilityNames[AbilityNames.DRAW_FROM_SQUIRREL] = JapaneseCardNames[CardNames.WAY_OF_THE_SQUIRREL].singular;
JapaneseAbilityNames[AbilityNames.DRAW_FROM_RIVER] = JapaneseCardNames[CardNames.THE_RIVERS_GIFT].singular;
JapaneseAbilityNames[AbilityNames.DRAW_FROM_FARRIER] = JapaneseCardNames[CardNames.FARRIER].singular;
JapaneseAbilityNames[AbilityNames.FERRYMAN] = JapaneseCardNames[CardNames.FERRYMAN].singular;

JapaneseAbilityNames[AbilityNames.BIDING_TIME_SET_ASIDE_HAND] = JapaneseCardNames[CardNames.BIDING_TIME].singular;
JapaneseAbilityNames[AbilityNames.BIDING_TIME_RETURN_TO_HAND] = JapaneseCardNames[CardNames.BIDING_TIME].singular;
JapaneseAbilityNames[AbilityNames.ENLIGHTENMENT_CANTRIP] = JapaneseCardNames[CardNames.ENLIGHTENMENT].singular;
JapaneseAbilityNames[AbilityNames.ENLIGHTENMENT_REPLACE_ABILITY] = JapaneseCardNames[CardNames.ENLIGHTENMENT].singular;
JapaneseAbilityNames[AbilityNames.GOOD_HARVEST] = JapaneseCardNames[CardNames.GOOD_HARVEST].singular;
JapaneseAbilityNames[AbilityNames.HARSH_WINTER] = JapaneseCardNames[CardNames.HARSH_WINTER].singular;
JapaneseAbilityNames[AbilityNames.KIND_EMPEROR] = JapaneseCardNames[CardNames.KIND_EMPEROR].singular;
JapaneseAbilityNames[AbilityNames.PANIC_BUYS] = JapaneseCardNames[CardNames.PANIC].singular;
JapaneseAbilityNames[AbilityNames.PANIC_RETURN] = JapaneseCardNames[CardNames.PANIC].singular;
JapaneseAbilityNames[AbilityNames.PROGRESS] = JapaneseCardNames[CardNames.PROGRESS].singular;
JapaneseAbilityNames[AbilityNames.RAPID_EXPANSION_SET_ASIDE] = JapaneseCardNames[CardNames.RAPID_EXPANSION].singular;
JapaneseAbilityNames[AbilityNames.RAPID_EXPANSION_PLAY_LATER] = JapaneseCardNames[CardNames.RAPID_EXPANSION].singular;
JapaneseAbilityNames[AbilityNames.SICKNESS] = JapaneseCardNames[CardNames.SICKNESS].singular;
JapaneseAbilityNames[AbilityNames.RIVER_SHRINE] = JapaneseCardNames[CardNames.RIVER_SHRINE].singular;
JapaneseAbilityNames[AbilityNames.SAMURAI] = JapaneseCardNames[CardNames.SAMURAI].singular;
JapaneseAbilityNames[AbilityNames.DAIMYO_REPLAY] = JapaneseCardNames[CardNames.DAIMYO].singular;
JapaneseAbilityNames[AbilityNames.FORESIGHT_RETURN_TO_HAND] = JapaneseCardNames[CardNames.FORESIGHT].singular;