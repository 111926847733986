"use strict";

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var QueueElement = function () {
    function QueueElement(animation, director, game) {
        _classCallCheck(this, QueueElement);

        this.director = director;
        this.game = game;

        this.animation = animation;
        this.isRunning = false;
        this.blockerElements = [];
        this.startBlockedElements = [];
        this.endBlockedElements = [];
    }

    _createClass(QueueElement, [{
        key: "begin",
        value: function begin() {
            var _this = this;

            this.isRunning = true;
            this.animation.begin(this.game, this.end.bind(this));
            this.startBlockedElements.forEach(function (e) {
                return e.removeBlocker(_this);
            });
        }
    }, {
        key: "end",
        value: function end() {
            var _this2 = this;

            if (this.isRunning) {
                this.isRunning = false;
                this.endBlockedElements.forEach(function (e) {
                    return e.removeBlocker(_this2);
                });
                this.director.animationEnded(this);
            }
        }
    }, {
        key: "tryToStart",
        value: function tryToStart() {
            if (this.blockerElements.length === 0) {
                this.begin();
            }
        }
    }, {
        key: "removeBlocker",
        value: function removeBlocker(blocker) {
            var index = this.blockerElements.indexOf(blocker);
            this.blockerElements.splice(index, 1);
            this.tryToStart();
        }
    }, {
        key: "startBlock",
        value: function startBlock(that) {
            if (!this.endBlockedElements.includes(that)) {
                this.startBlockedElements.push(that);
                that.blockerElements.push(this);
            }
        }
    }, {
        key: "endBlock",
        value: function endBlock(that) {
            var index = this.startBlockedElements.indexOf(that);
            this.endBlockedElements.push(that);
            if (index > -1) this.startBlockedElements.splice(index, 1);else that.blockerElements.push(this);
        }
    }, {
        key: "blocks",
        value: function blocks(animation) {
            return this.animation.blocks(animation) || this.attachedElements.some(function (child) {
                return child.blocks(animation);
            });
        }
    }]);

    return QueueElement;
}();

var Animation = function () {
    function Animation() {
        _classCallCheck(this, Animation);

        this.isUniversalBlocker = false;
    }

    _createClass(Animation, [{
        key: "begin",
        value: function begin(game, terminator) {
            terminator();
        }
    }, {
        key: "isStartBlockedBy",
        value: function isStartBlockedBy(that) {
            return false;
        }
    }, {
        key: "isEndBlockedBy",
        value: function isEndBlockedBy(that) {
            return false;
        }
    }]);

    return Animation;
}();

// In general, noninstant animations will block instant ones, with some exceptions (questions, for example).


var NonInstantStateChangeAnimation = function (_Animation) {
    _inherits(NonInstantStateChangeAnimation, _Animation);

    function NonInstantStateChangeAnimation(stateChange) {
        _classCallCheck(this, NonInstantStateChangeAnimation);

        var _this3 = _possibleConstructorReturn(this, (NonInstantStateChangeAnimation.__proto__ || Object.getPrototypeOf(NonInstantStateChangeAnimation)).call(this));

        _this3.stateChange = stateChange;
        return _this3;
    }

    _createClass(NonInstantStateChangeAnimation, [{
        key: "begin",
        value: function begin(game, terminator) {
            this.stateChange.execute(game, terminator);
        }
    }]);

    return NonInstantStateChangeAnimation;
}(Animation);

var InstantStateChangeAnimation = function (_Animation2) {
    _inherits(InstantStateChangeAnimation, _Animation2);

    function InstantStateChangeAnimation(stateChange) {
        _classCallCheck(this, InstantStateChangeAnimation);

        var _this4 = _possibleConstructorReturn(this, (InstantStateChangeAnimation.__proto__ || Object.getPrototypeOf(InstantStateChangeAnimation)).call(this));

        _this4.stateChange = stateChange;
        return _this4;
    }

    _createClass(InstantStateChangeAnimation, [{
        key: "begin",
        value: function begin(game, terminator) {
            this.stateChange.execute(game);
            terminator();
        }
    }, {
        key: "isEndBlockedBy",
        value: function isEndBlockedBy(that) {
            return that instanceof NonInstantStateChangeAnimation || that.isUniversalBlocker;
        }
    }]);

    return InstantStateChangeAnimation;
}(Animation);

var SelfBlockingAnimation = function (_InstantStateChangeAn) {
    _inherits(SelfBlockingAnimation, _InstantStateChangeAn);

    function SelfBlockingAnimation(stateChange, typeString) {
        _classCallCheck(this, SelfBlockingAnimation);

        var _this5 = _possibleConstructorReturn(this, (SelfBlockingAnimation.__proto__ || Object.getPrototypeOf(SelfBlockingAnimation)).call(this, stateChange));

        _this5.typeString = typeString;
        return _this5;
    }

    _createClass(SelfBlockingAnimation, [{
        key: "isStartBlockedBy",
        value: function isStartBlockedBy(that) {
            return _get(SelfBlockingAnimation.prototype.__proto__ || Object.getPrototypeOf(SelfBlockingAnimation.prototype), "isEndBlockedBy", this).call(this, that) || that instanceof SelfBlockingAnimation && that.typeString === this.typeString;
        }
    }, {
        key: "isEndBlockedBy",
        value: function isEndBlockedBy(that) {
            return false;
        }
    }]);

    return SelfBlockingAnimation;
}(InstantStateChangeAnimation);

var TickerChangingAnimation = function (_SelfBlockingAnimatio) {
    _inherits(TickerChangingAnimation, _SelfBlockingAnimatio);

    function TickerChangingAnimation(stateChange) {
        _classCallCheck(this, TickerChangingAnimation);

        return _possibleConstructorReturn(this, (TickerChangingAnimation.__proto__ || Object.getPrototypeOf(TickerChangingAnimation)).call(this, stateChange, "ticker"));
    }

    return TickerChangingAnimation;
}(SelfBlockingAnimation);

var UniversalBlocker = function (_InstantStateChangeAn2) {
    _inherits(UniversalBlocker, _InstantStateChangeAn2);

    function UniversalBlocker(stateChange) {
        _classCallCheck(this, UniversalBlocker);

        var _this7 = _possibleConstructorReturn(this, (UniversalBlocker.__proto__ || Object.getPrototypeOf(UniversalBlocker)).call(this, stateChange));

        _this7.isUniversalBlocker = true;
        return _this7;
    }

    return UniversalBlocker;
}(InstantStateChangeAnimation);

var CounterAnimation = function (_InstantStateChangeAn3) {
    _inherits(CounterAnimation, _InstantStateChangeAn3);

    function CounterAnimation() {
        _classCallCheck(this, CounterAnimation);

        return _possibleConstructorReturn(this, (CounterAnimation.__proto__ || Object.getPrototypeOf(CounterAnimation)).apply(this, arguments));
    }

    _createClass(CounterAnimation, [{
        key: "isStartBlockedBy",
        value: function isStartBlockedBy(that) {
            return that instanceof NonInstantStateChangeAnimation || that.isUniversalBlocker;
        }
    }, {
        key: "isEndBlockedBy",
        value: function isEndBlockedBy(that) {
            return that instanceof CounterAnimation && that.stateChange.index === this.stateChange.index;
        }
    }]);

    return CounterAnimation;
}(InstantStateChangeAnimation);

var GameEndingAnimation = function (_Animation3) {
    _inherits(GameEndingAnimation, _Animation3);

    function GameEndingAnimation(callback) {
        _classCallCheck(this, GameEndingAnimation);

        var _this9 = _possibleConstructorReturn(this, (GameEndingAnimation.__proto__ || Object.getPrototypeOf(GameEndingAnimation)).call(this));

        _this9.callback = callback;
        return _this9;
    }

    _createClass(GameEndingAnimation, [{
        key: "begin",
        value: function begin(game, terminator) {
            this.callback();
            terminator();
        }
    }, {
        key: "isEndBlockedBy",
        value: function isEndBlockedBy(that) {
            return true;
        }
    }]);

    return GameEndingAnimation;
}(Animation);

var QuestionAnimation = function (_InstantStateChangeAn4) {
    _inherits(QuestionAnimation, _InstantStateChangeAn4);

    function QuestionAnimation() {
        _classCallCheck(this, QuestionAnimation);

        return _possibleConstructorReturn(this, (QuestionAnimation.__proto__ || Object.getPrototypeOf(QuestionAnimation)).apply(this, arguments));
    }

    _createClass(QuestionAnimation, [{
        key: "begin",
        value: function begin(game, terminator) {
            this.stateChange.execute(game);
            terminator();
        }
    }, {
        key: "isStartBlockedBy",
        value: function isStartBlockedBy(that) {
            // if (that.isUniversalBlocker || that instanceof PileConstructingAnimation)
            //     return true;
            if (that instanceof AnimatedCardMove) {
                if (this.stateChange.questionClass === QuestionClasses.CHOICE_QUESTION) {
                    var affectedCardIds = this.stateChange.computeAffectedCardIds();
                    return that.affectedCardIds.some(function (id) {
                        return affectedCardIds.includes(id);
                    });
                }
                return true;
            }
            if (that instanceof TickerChangingAnimation) return true;
            return false;
        }
    }, {
        key: "isEndBlockedBy",
        value: function isEndBlockedBy(that) {
            return false;
        }
    }]);

    return QuestionAnimation;
}(InstantStateChangeAnimation);

var EndBlockedQuestionAnimation = function (_QuestionAnimation) {
    _inherits(EndBlockedQuestionAnimation, _QuestionAnimation);

    function EndBlockedQuestionAnimation() {
        _classCallCheck(this, EndBlockedQuestionAnimation);

        return _possibleConstructorReturn(this, (EndBlockedQuestionAnimation.__proto__ || Object.getPrototypeOf(EndBlockedQuestionAnimation)).apply(this, arguments));
    }

    _createClass(EndBlockedQuestionAnimation, [{
        key: "isStartBlockedBy",
        value: function isStartBlockedBy(that) {
            return false;
        }
    }, {
        key: "isEndBlockedBy",
        value: function isEndBlockedBy(that) {
            if (that.isUniversalBlocker || that instanceof PileConstructingAnimation) return true;
            if (that instanceof AnimatedCardMove) {
                if (this.stateChange.questionClass === QuestionClasses.CHOICE_QUESTION) {
                    var affectedCardIds = this.stateChange.computeAffectedCardIds();
                    return that.affectedCardIds.some(function (id) {
                        return affectedCardIds.includes(id);
                    });
                }
                return true;
            }
            if (that instanceof TickerChangingAnimation) return true;
            return false;
        }
    }]);

    return EndBlockedQuestionAnimation;
}(QuestionAnimation);

var PileAffectingAnimation = function (_NonInstantStateChang) {
    _inherits(PileAffectingAnimation, _NonInstantStateChang);

    function PileAffectingAnimation(stateChange, affectedPileIndex) {
        _classCallCheck(this, PileAffectingAnimation);

        var _this12 = _possibleConstructorReturn(this, (PileAffectingAnimation.__proto__ || Object.getPrototypeOf(PileAffectingAnimation)).call(this, stateChange));

        _this12.affectedPileIndex = affectedPileIndex;
        return _this12;
    }

    _createClass(PileAffectingAnimation, [{
        key: "isStartBlockedBy",
        value: function isStartBlockedBy(that) {
            return that instanceof AnimatedCardMove && that.stateChange.fromZoneIndex === this.affectedPileIndex;
        }
    }, {
        key: "isEndBlockedBy",
        value: function isEndBlockedBy(that) {
            if (that.isUniversalBlocker) {
                return true;
            } else if (that instanceof PileAffectingAnimation) {
                return that.affectedPileIndex === this.affectedPileIndex;
            } else {
                return false;
            }
        }
    }]);

    return PileAffectingAnimation;
}(NonInstantStateChangeAnimation);

var PileDependentAnimation = function (_PileAffectingAnimati) {
    _inherits(PileDependentAnimation, _PileAffectingAnimati);

    function PileDependentAnimation() {
        _classCallCheck(this, PileDependentAnimation);

        return _possibleConstructorReturn(this, (PileDependentAnimation.__proto__ || Object.getPrototypeOf(PileDependentAnimation)).apply(this, arguments));
    }

    _createClass(PileDependentAnimation, [{
        key: "isStartBlockedBy",
        value: function isStartBlockedBy(that) {
            return false;
        }
    }, {
        key: "isEndBlockedBy",
        value: function isEndBlockedBy(that) {
            if (that.isUniversalBlocker) {
                return true;
            } else if (that instanceof AnimatedCardMove) {
                return that.stateChange.toZoneIndex === this.stateChange.fromZoneIndex;
            } else if (that instanceof PileAffectingAnimation) {
                return that.affectedPileIndex === this.stateChange.fromZoneIndex;
            } else {
                return that instanceof NonInstantStateChangeAnimation;
            }
        }
    }]);

    return PileDependentAnimation;
}(PileAffectingAnimation);

var PileConstructingAnimation = function (_PileAffectingAnimati2) {
    _inherits(PileConstructingAnimation, _PileAffectingAnimati2);

    function PileConstructingAnimation(stateChange, affectedPileIndex) {
        _classCallCheck(this, PileConstructingAnimation);

        var _this14 = _possibleConstructorReturn(this, (PileConstructingAnimation.__proto__ || Object.getPrototypeOf(PileConstructingAnimation)).call(this, stateChange, affectedPileIndex));

        _this14.isUniversalBlocker = true;
        return _this14;
    }

    return PileConstructingAnimation;
}(PileAffectingAnimation);

var AnimatedCardMove = function (_PileAffectingAnimati3) {
    _inherits(AnimatedCardMove, _PileAffectingAnimati3);

    function AnimatedCardMove(stateChange, affectedCardIds, props) {
        _classCallCheck(this, AnimatedCardMove);

        var _this15 = _possibleConstructorReturn(this, (AnimatedCardMove.__proto__ || Object.getPrototypeOf(AnimatedCardMove)).call(this, stateChange, stateChange.toZoneIndex));

        _this15.affectedCardIds = affectedCardIds;
        _this15.props = props;
        _this15.isUniversalBlocker = props.type === ClientAnimationTypes.HIGHLIGHTED;
        return _this15;
    }

    _createClass(AnimatedCardMove, [{
        key: "isStartBlockedBy",
        value: function isStartBlockedBy(that) {
            if (that instanceof AnimatedCardMove) {
                if (this.stateChange.toZoneIndex === that.stateChange.fromZoneIndex) return true;
            }
            return false;
        }
    }, {
        key: "isEndBlockedBy",
        value: function isEndBlockedBy(that) {
            if (that.isUniversalBlocker) {
                return true;
            } else if (that instanceof PileAffectingAnimation) {
                var settings = getAnimationSettings();
                if (that instanceof PileConstructingAnimation) return that.affectedPileIndex === this.fromZoneIndex || that.affectedPileIndex === this.toZoneIndex;else if (that instanceof AnimatedCardMove) {
                    if (!settings.showConcurrentAnimations) {
                        return that.stateChange.fromZoneIndex !== this.stateChange.fromZoneIndex || that.stateChange.toZoneIndex !== this.stateChange.toZoneIndex;
                    } else {
                        if (this.affectedCardIds.some(function (id) {
                            return id !== -1 && that.affectedCardIds.includes(id);
                        })) {
                            return true;
                        } else if (this.affectedCardIds.includes(-1) || that.affectedCardIds.includes(-1)) {
                            if (that.affectedPileIndex === this.stateChange.fromZoneIndex) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    }
                } else {
                    return that.affectedPileIndex === this.stateChange.fromZoneIndex;
                }
            } else {
                return false;
            }
        }
    }, {
        key: "begin",
        value: function begin(game, terminator) {
            this.stateChange.execute(game, terminator);
        }
    }]);

    return AnimatedCardMove;
}(PileAffectingAnimation);

var AnimatedPileReorder = function (_PileAffectingAnimati4) {
    _inherits(AnimatedPileReorder, _PileAffectingAnimati4);

    function AnimatedPileReorder() {
        _classCallCheck(this, AnimatedPileReorder);

        return _possibleConstructorReturn(this, (AnimatedPileReorder.__proto__ || Object.getPrototypeOf(AnimatedPileReorder)).apply(this, arguments));
    }

    _createClass(AnimatedPileReorder, [{
        key: "begin",
        value: function begin(game, terminator) {
            var _this17 = this;

            var settings = getAnimationSettings();
            var props = settings.map.get(AnimationClassIds.TRANSITION);

            var zone = game.state.zones[this.stateChange.pileId];
            var stack = zone.cardStacks.find(function (s) {
                return s.cards.some(function (c) {
                    return c.id === _this17.stateChange.cardIds[0];
                });
            });
            var movedCards = stack.cards.slice(0, stack.cards.findIndex(function (c) {
                return c.id === _this17.stateChange.cardIds[0];
            }) + 1);
            if (movedCards.length > 0) {
                var finishCount = 0;
                var callback = function callback() {
                    finishCount++;
                    if (finishCount == movedCards.length) terminator();
                };
                var newStacks = movedCards.forEach(function (c) {
                    var transitionStack = zone.spliceCard(c);
                    transitionStack.cardSize = stack.cardSize;
                    var targetStack = zone.addCard(c);
                    transitionStack.animator = new TransitionAnimator(transitionStack, reorderTransitionScript(stack), stack, function () {
                        targetStack.appendCard(c);
                        transitionStack.destroy();
                        callback();
                    });
                });
                stack.animator = new TransitionAnimator(stack, reorderBaseTransition(stack.view.bbox.copy()), stack, function () {});
            } else {
                terminator();
            }
        }
    }]);

    return AnimatedPileReorder;
}(PileAffectingAnimation);

var getAnimationSettings = function getAnimationSettings() {
    return getByOrdinal(AnimationOptions, animationType);
};

var AnimationDirector = function () {
    function AnimationDirector(game) {
        _classCallCheck(this, AnimationDirector);

        this.game = game;
        this.queueElements = [];
        this.queueEmptyCallbacks = [];
    }

    _createClass(AnimationDirector, [{
        key: "interruptAnimations",
        value: function interruptAnimations() {
            this.queueElements.forEach(function (e) {
                if (e.isRunning) {
                    e.endBlockedElements = [];
                    e.startBlockedElements = [];
                }
            });

            this.queueElements = [];
        }
    }, {
        key: "newAnimation",
        value: function newAnimation(animationGenerator) {
            var animation = animationGenerator(this.game);
            var newElement = new QueueElement(animation, this, this.game);
            this.queueElements.forEach(function (element) {
                if (!element.isRunning && animation.isStartBlockedBy(element.animation)) element.startBlock(newElement);
                if (animation.isEndBlockedBy(element.animation)) element.endBlock(newElement);
            });
            this.queueElements.push(newElement);
            newElement.tryToStart();
        }
    }, {
        key: "animationEnded",
        value: function animationEnded(queueElement) {
            var index = this.queueElements.indexOf(queueElement);
            if (index > -1) {
                this.queueElements.splice(index, 1);
                if (this.queueElements.length === 0) {
                    this.game.state.cards.forEach(function (c) {
                        return c.checkIfOrphaned();
                    });
                    this.queueEmptyCallbacks.forEach(function (callback) {
                        return callback();
                    });
                    this.queueEmptyCallbacks = [];
                }
                if (this.queueElements.filter(function (e) {
                    return e.animation instanceof QuestionAnimation;
                }).length === 0) {
                    this.game.questionModel.startPremoving();
                }
            }
        }
    }, {
        key: "endGame",
        value: function endGame(gameResult) {
            var _this18 = this;

            this.newAnimation(function () {
                return new GameEndingAnimation(function () {
                    _this18.game.metaGameModel.endGame(gameResult);
                    _this18.game.gameSoundService.ping();
                });
            });
        }
    }, {
        key: "onNextEmptyQueue",
        value: function onNextEmptyQueue(callback) {
            if (this.queueElements.length === 0) {
                callback();
            } else {
                this.queueEmptyCallbacks.push(callback);
            }
        }
    }]);

    return AnimationDirector;
}();