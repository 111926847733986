"use strict";

webclient.component("store", {
    bindings: {},
    controllerAs: "store",
    controller: ['$scope', '$window', '$timeout', 'redirectService', 'cardService', 'checkoutData', 'serverMessenger', 'metaBroadcaster', 'meta', function ($scope, $window, $timeout, redirectService, cardService, checkoutData, serverMessenger, metaBroadcaster, meta) {

        var store = this;

        store.initialiseVariables = function () {

            store.phrases = PHRASES;
            store.data = checkoutData;
            store.cardService = cardService;
            store.customer = new Customer(meta.model.me, store.cardService.ownership);
            store.state = new StoreState($timeout);

            var durations = [new Duration(store.phrases.ONE_MONTH, 0, 1, 0), new Duration(store.phrases.TWO_MONTHS, 0, 2, 0), new Duration(store.phrases.THREE_MONTHS, 0, 3, 0, 2), new Duration(store.phrases.SIX_MONTHS, 0, 6, 0, 5), new Duration(store.phrases.NINE_MONTHS, 0, 9, 0, 8), new Duration(store.phrases.ONE_YEAR, 1, 0, 0, 10), new Duration(store.phrases.FIFTEEN_MONTHS, 1, 3, 0, 12), new Duration(store.phrases.EIGHTEEN_MONTHS, 1, 6, 0, 15), new Duration(store.phrases.TWO_YEAR, 2, 0, 0, 15)];

            store.durations = durations.filter(function (duration) {
                return duration.endDate() <= StoreSubscription.maxSubscriptionDate();
            });

            store.redirectPageUrl = '';
            store.coreLevels = Expansion.coreLevels();
            store.completeLevels = Expansion.completeLevels();
            store.subscriptions = [store.phrases.CORE_SUBSCRIPTION, store.phrases.COMPLETE_SUBSCRIPTION];
            store.noCountrySelected = new Country('--');store.noCountrySelected.name = '--';
            store.countryNames = [store.noCountrySelected.name].concat(ObjectValues(Countries).map(function (country) {
                return country.name();
            }));

            // order countries according to translated names, using language specific sort order
            // Non-EU must be 1st/2nd and stays first/(second with same first)
            // this way it works at the time of commit both on master-translations (which starts with "Non-EU") and master (which has "--" before)
            // Ingix, Rewritten by Aad.

            var nonEuIndex = store.countryNames.indexOf(Countries.NON_EU.name());

            if (nonEuIndex !== -1) {
                var unsorted = store.countryNames.splice(0, nonEuIndex + 1);
                store.countryNames.sort(function (c1, c2) {
                    return c1.localeCompare(c2, LANGUAGE.getSortOrderLocale());
                });
                Array.prototype.splice.apply(store.countryNames, [0, 0].concat(unsorted));
            }

            store.packages = StorePackages;
            store.data.checkoutInProgress = false;
            store.previousAnswerLabels = undefined;
            store.paymentProviderUrl = undefined;
            store.invoice = undefined;
            store.tabRedirectName = 'paymentProvider' + Math.random();
        };

        store.countryQuestionIncluded = function () {
            return store.selectedQuestions.indexOf(store.questions.country) !== -1;
        };
        store.zeroSumInvoice = function () {
            return isUndefined(store.invoice) || store.invoice.total() === 0;
        };
        store.questionIsSelected = function (question) {
            return store.selectedQuestions.indexOf(question) !== -1;
        };
        store.sendEvent = function (event) {
            return store.state.handleEvent(event);
        };

        store.createDefaultProduct = function () {

            var product = store.packages.COMPLETE;
            var country = store.customer.ipCountryCode();
            var minDate = new Date();
            var duration = store.durations.find(function (duration) {
                return duration.endDate() > minDate;
            });
            var date = duration ? duration.endDate() : undefined;

            store.sendEvent(StoreState.events.SENDING_REQUEST);
            store.defaultProduct = date ? new OnlineProduct(product, date, country) : undefined;
        };

        store.getPrice = function (product) {

            store.sendEvent(StoreState.events.SENDING_REQUEST);
            serverMessenger.requestPurchasePrice(product);
        };

        // This method is to big
        store.continueAfterLastQuestion = function () {

            var currentQuestion = store.questionList.currentQuestion();

            if (currentQuestion === store.questions.maxedOut) {

                metaBroadcaster.send(Events.SHOW_SUBSCRIPTIONS, {});
                timeLog('Store: Customer is redirected to Account tab.');
                return;
            }

            if (currentQuestion === store.questionList.lastQuestion() && store.questionList.hasErrors()) {

                var question = store.questionList.firstWithError();

                timeLog('Store: Customer is redirected to question with invalid answer.');
                question.angularListController.onclickQuestionList(false, question);
                return;
            }

            if (store.zeroSumInvoice()) {
                return;
            }

            timeLog('Store: Customer finished answering questsions and is redirected.');

            if (!store.data.checkoutInProgress) {

                store.sendEvent(StoreState.events.REDIRECT);
                store.paymentTab = $window.open(store.redirectPageUrl, store.tabRedirectName);
                store.paymentTab.document.write(redirectService.html());

                //listen to tab change events
                $scope.$watch(store.paymentTab, function () {
                    timeLog('Store: payment tab activated.');
                    store.paymentProviderUrl = undefined;
                });

                if (store.countryQuestionIncluded()) {
                    var country = ObjectValues(Countries).find(function (country) {
                        return country.name() === store.questions.country.answerObject();
                    });
                    store.data.purchase.onlineProduct.country = isDebug ? '??' : country.code;
                }
                store.data.startCheckout();
            }
        };

        store.initialise = function () {

            store.initialiseVariables();
            store.sendEvent(StoreState.events.START);
            store.createDefaultProduct();

            if (isDefined(store.defaultProduct)) {
                store.getPrice(store.defaultProduct);
            }

            store.createQuestions();
            store.selectQuestions();
        };

        store.requestedProduct = function () {

            var product = store.questions.subscription.answerObject();
            var endDate = store.questions.duration.answerObject().endDate();
            var countryName = store.countryQuestionIncluded() ? store.questions.country.answerObject() : Countries.NON_EU.name();
            var countryCode = getCountryByName(countryName).code;

            return new OnlineProduct(product, endDate, countryCode);
        };

        store.productQuestionsAnsweredCorrectly = function () {

            var questions = [store.questions.duration, store.questions.subscription];

            if (store.countryQuestionIncluded()) {
                questions.push(store.questions.country);
            }

            return questions.every(function (question) {
                return question.isAnswered() && question.validAnswer();
            });
        };

        $scope.$on(Events.PURCHASE_PRICE_AVAILABLE, function (event, failureReason) {

            timeLog('Store: received PURCHASE PRICE AVAILABLE event');

            store.sendEvent(StoreState.events.REQUEST_ANSWERED);
            store.invoice = new Invoice(store.data);
            var url = 'html/store/' + (store.zeroSumInvoice() ? 'invoice-zero-total-1.9.2.2.html' : 'invoice-1.9.2.2.html');
            store.questions.invoice.setHtmlUrl(url);
        });

        $scope.$on(Events.COMMAND_FAILED, function (event, failure) {

            timeLog('Store: received COMMAND FAILED event');

            if (failure.failureReason === FailureReasons.COUNTRY_MISMATCH) {
                store.data.checkoutInProgress = false;
                metaBroadcaster.send(Events.COUNTRY_MISMATCH);
            }
            if (failure.failureReason === FailureReasons.INCORRECT_PRICE) {
                timeLog('Store: failure reason INCORRECT PRICE');
                store.data.checkoutInProgress = false;
            }
        });

        $scope.$on(Events.PURCHASE_REDIRECT, function (event, paymentProviderUrl) {

            store.paymentProviderUrl = paymentProviderUrl;

            timeLog('Store: Got a PURCHASE REDIRECT url, opening it in payment tab.');
            store.paymentTab = $window.open(store.paymentProviderUrl, store.tabRedirectName);
        });

        store.initInvoice = function () {

            timeLog('Store: initializing invoice.');

            var product = store.requestedProduct();

            assert(isDefined(product));

            store.getPrice(product);
        };

        store.createQuestions = function () {

            var bla = store;

            var countryValidator = function countryValidator(country) {
                return store.customer.ipCountry().name() === country;
            };
            var durationValidator = function durationValidator(duration) {
                return store.customer.validDurations().indexOf(duration) !== -1;
            };

            store.questions = {

                intro: new RelatedQuestion(store.phrases.INTRODUCTION_TO_STORE, 'info'),
                maxedOut: new RelatedQuestion(store.phrases.MAXED_OUT_SUBSCRIPTIONS, 'info'),
                subscription: new RelatedQuestion(store.phrases.CHOOSE_SUBSCRIPTION, 'select-buttons'),
                duration: new RelatedQuestion(store.phrases.CHOOSE_DURATION, 'select-buttons', durationValidator),
                country: new RelatedQuestion(store.phrases.SELECT_A_COUNTRY, 'select', countryValidator),
                invoice: new RelatedQuestion(store.phrases.CHECK_YOUR_PURCHASE, 'info')
            };

            store.durations.filter(function (d) {
                return d.discount;
            }).forEach(function (d) {
                return d.description = '( -' + d.discount + '%)';
            });
            store.questions.duration.setAnswerObjects(store.durations);
            store.questions.country.setAnswerObjects(store.countryNames);
            store.questions.country.setErrorText(store.phrases.IP_COUNTRY_MISMATCH + ' ' + store.customer.ipCountry().code);

            store.questions.intro.setShortText(store.phrases.INTRODUCTION_TO_STORE);
            store.questions.intro.setHtmlUrl('html/store/intro-1.9.2.2.html');

            store.questions.subscription.setAnswerLabels(store.subscriptions);
            store.questions.subscription.setAnswerObjects([store.packages.CORE, store.packages.COMPLETE]);
            store.questions.subscription.setHtmlUrl('html/store/subscriptions-1.9.2.2.html');
            store.questions.subscription.setLabel(store.phrases.CHOOSE_SUBSCRIPTION);

            store.atInvoice = function () {
                return store.questionList.currentQuestion() === store.questions.invoice;
            };
            store.purchasePricePositive = function () {
                return !store.atInvoice() || isUndefined(store.invoice) || store.invoice.total() > 0;
            };
            store.productQuestionsValidator = function () {

                if (!store.state.answeringQuestions()) {
                    return true;
                }
                if (!store.atInvoice()) {
                    return true;
                }
                if (isUndefined(store.invoice)) {
                    return true;
                }

                return !store.zeroSumInvoice();
            };

            store.questions.subscription.setValidator(store.productQuestionsValidator, store.phrases.PRICE_IS_ZERO);
            store.questions.duration.setValidator(store.productQuestionsValidator, store.phrases.PRICE_IS_ZERO);

            store.questions.invoice.setHtmlUrl('html/store/invoice-1.9.2.2.html');
            store.questions.invoice.setCurrentFunction(store.initInvoice);

            ObjectValues(store.questions).forEach(function (question) {
                return question.setHtmlKnowledge(store);
            });

            store.nextQuestion = function (question) {

                var max = store.selectedQuestions.length - 1;
                var index = store.selectedQuestions.indexOf(question);

                return index === max ? undefined : store.selectedQuestions[index + 1];
            };
        };

        store.validChoice = function (question, answerLabel) {

            var duration = undefined;
            var subscription = undefined;

            if (question === store.questions.duration) {

                duration = store.durations.find(function (duration) {
                    return duration.name === answerLabel;
                });
                subscription = store.questions.subscription.answerObject();

                return store.customer.validCombination(duration, subscription);
            } else if (question === store.questions.subscription) {

                subscription = store.questions.subscription.labelToObject(answerLabel);
                return store.durations.some(function (duration) {
                    return store.customer.validCombination(duration, subscription);
                });
            }

            return true;
        };

        store.selectQuestions = function () {

            if (store.customer.maxedOutSubscriptions(store.durations)) {
                store.selectedQuestions = [store.questions.intro, store.questions.maxedOut];
            } else {
                store.selectedQuestions = ObjectValues(store.questions);

                removeFromArray(store.selectedQuestions, store.questions.maxedOut);
                if (!store.customer.ipFromEU()) {
                    removeFromArray(store.selectedQuestions, store.questions.country);
                }
            }

            var questionList = new QuestionList(ObjectValues(store.selectedQuestions));

            store.questionList = questionList;
        };

        store.paymentInProgress = function () {

            if (!store.state.customerIsRedirected()) {
                return false;
            }

            return store.data.checkoutInProgress;
        };

        store.redirectFailedAccordingToCustomer = function (event) {

            timeLog('Store: user clicks [Redirect failed] button');
            store.sendEvent(StoreState.events.REDIRECT_FAILED);
            store.handleRedirectFailure();
        };

        store.handleRedirectFailure = function () {

            $window.close(store.tabRedirectName);

            store.data.checkoutInProgress = false;
            store.paymentProviderUrl = undefined;
            store.paymentTab = undefined;
        };

        store.initialise();
    }],
    template: "\n        <div class=\"store\">\n            <div ng-if=\"store.state.customerIsRedirected()\">\n                <div ng-if=\"store.paymentInProgress()\" class=\"store making-purchase\">\n                    <div ng-include=\"'html/store/waiting-for-redirect-1.9.2.2.html'\"></div>\n                </div>\n                <div ng-if=\"!store.paymentInProgress()\" class=\"store\">\n                    <div ng-include=\"'html/store/store-service/connecting-1.9.2.2.html'\"></div>\n                </div>\n            </div>\n            <div ng-if=\"store.state.customerIsWaiting()\">\n                <div ng-include=\"'html/store/store-service/connecting-1.9.2.2.html'\"></div>\n            </div>\n            <div ng-if=\"store.state.serviceIsUnreachable()\">\n                <div ng-include=\"'html/store/store-service/down-1.9.2.2.html'\"></div>\n            </div>\n            <div class=\"store store-title\">\n                <div class=\"questions-in-store\" ng-if=\"store.state.answeringQuestions()\">\n                    <question-list hide-future=true ng-model=\"store.questionList\"> </question-list>\n                </div>\n            </div>\n        </div>\n    "
});