"use strict";

webclient.component("gamePrefAutoplay", {
    bindings: {
        card: "<"
    },
    controller: ['autoplayService', '$scope', function (autoplayService, $scope) {

        var controller = this;

        controller.autoplayable = function () {
            return autoplayService.autoplayable();
        };
        controller.value = function () {
            return autoplayService.value();
        };
        controller.cssClass = function (value) {
            return 'autoplay-setting-' + (controller.value() === value ? 'chosen' : 'other') + '-value';
        };
        controller.changeValue = function (value) {
            return autoplayService.changeAutoplaySetting(value);
        };

        controller.getTitle = function () {
            return LANGUAGE.getAutoplayLabels.AUTOPLAY_OPTIONS;
        };
        controller.getDescriptions = function () {
            return autoplayService.descriptions();
        };
        controller.getValues = function () {
            return autoplayService.values();
        };
        controller.getName = function () {
            return autoplayService.name();
        };
        controller.getGenericHelpLink = function () {
            return autoplayService.genericHelpLink();
        };
        controller.getSpecificHelpLink = function () {
            return autoplayService.specificHelpLink();
        };
        controller.getGenericLinkText = function () {
            return autoplayService.genericLinkText();
        };
        controller.getSpecificLinkText = function () {
            return autoplayService.specificLinkText();
        };

        controller.mousedown = function (e) {
            e.stopPropagation();
        };
    }],
    template: "<div ng-if=\"$ctrl.autoplayable()\" ng-mousedown=\"$ctrl.mousedown($event)\">\n                    <h1 class=\"game-options-title\">{{$ctrl.getTitle()}}</h1>\n                    <a class=\"autoplay-documentation-link autoplay-text\" href=\"{{$ctrl.getGenericHelpLink()}}\" target=\"autoplay-help\">{{$ctrl.getGenericLinkText()}}</a>\n                    <a class=\"autoplay-documentation-link autoplay-text\" href=\"{{$ctrl.getSpecificHelpLink()}}\" target=\"autoplay-help\">{{$ctrl.getSpecificLinkText()}}</a>\n                    <ul>\n                        <li \n                          ng-repeat=\"value in $ctrl.getValues()\"\n                          class=\"autoplay-entry\"\n                          ng-click=\"$ctrl.changeValue(value); $event.stopPropagation();\">\n                            <div class=\"autoplay-radio-button\" ng-class=\"$ctrl.cssClass(value)\"></div>\n                            <label class=\"autoplay-game-text\"> {{$ctrl.getDescriptions()[value]}} </label>\n                        </li>\n                   </ul>\n               </div>\n               "
});