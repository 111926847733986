"use strict";

var RussianUserPreferences = {};

RussianUserPreferences[UserPrefIds.EMAIL] = "Email";
RussianUserPreferences[UserPrefIds.CARD_OWNERSHIP] = "Купленные карты";
RussianUserPreferences[UserPrefIds.LIKED_CARDS] = "Любимые карты";
RussianUserPreferences[UserPrefIds.DISLIKED_CARDS] = "Нелюбимые карты";
RussianUserPreferences[UserPrefIds.BANNED_CARDS] = "Запрещённые карты";
RussianUserPreferences[UserPrefIds.MOVE_KINGDOM] = "Передвигать игровую область";
RussianUserPreferences[UserPrefIds.CONFIRM_ACTIONS] = "Подтверждать одиночные действия";
RussianUserPreferences[UserPrefIds.PREFERRED_BACKGROUND] = "Предпочитаемый фон";
RussianUserPreferences[UserPrefIds.USE_AUTOBUY] = "Автоматическая игра сокровищ";
RussianUserPreferences[UserPrefIds.FOLLOWERS_SEE_ACTIVITY] = "Подписчики могут видеть вашу активность";
RussianUserPreferences[UserPrefIds.NOTIFICATION_SOUNDS] = "Включить звуковые уведомления";
RussianUserPreferences[UserPrefIds.SOUND_FX] = "Включить звуковые эффекты";
RussianUserPreferences[UserPrefIds.SPECTATORS_SEE_HAND] = "Зрители могут видеть мои карты";
RussianUserPreferences[UserPrefIds.AUTOPLAY_SETTINGS] = "Autoplay the following cards";
RussianUserPreferences[UserPrefIds.LOG_FIXED_BASIC_BONUS] = "Писать в лог +Действия, +Покупки, +Монеты";
RussianUserPreferences[UserPrefIds.TRANSPARENT_WAY_BUTTON] = "Прозрачные кнопки путей";
RussianUserPreferences[UserPrefIds.CHAT_VISIBLE] = "Показывать чат";
RussianUserPreferences[UserPrefIds.SPECTATOR_CHAT_IN_AUTOMATCH] = "Видеть чат зрителей во время игры со случаными оппонентами";
RussianUserPreferences[UserPrefIds.CARD_POOL_LEVEL] = "Уровень сложности карт";
RussianUserPreferences[UserPrefIds.ANIMATION_TYPE] = "Анимации";
RussianUserPreferences[UserPrefIds.PREMOVES] = "Предварительные ходы";

RussianUserPreferences.USERNAME = "Имя пользователя";
RussianUserPreferences.PASSWORD = "Пароль";
RussianUserPreferences.OLD_PASSWORD = "Старый пароль";
RussianUserPreferences.NEW_PASSWORD = "Новый пароль";
RussianUserPreferences.REPEAT_PASSWORD = "Повторите пароль";
RussianUserPreferences.OWNERSHIP_INTRO = "Ваши текущие подписки:";
RussianUserPreferences.OWNERSHIP_INTRO_EMPTY = "На данный момент у вас нет подписок.";
RussianUserPreferences.FAMILIAR_WITH = "Я знаком: ";
RussianUserPreferences.USE_BONUS_CODE = "Использовать промокод";
RussianUserPreferences.BONUS_CODE_ORIGIN = "Ваш промокод взят из: ";
RussianUserPreferences.BONUS_CODE_CHOOSE_EXPANSION = "Выберите дополнение ";

var RussianProfileButtons = {};

RussianProfileButtons[true] = "Да";
RussianProfileButtons[false] = "Нет";
RussianProfileButtons[UserPrefIds.FAMILIAR_CARDS] = { ALL: "c этим дополнением", NONE: "ни с чем отсюда", ALL_EXPANSIONS: "со всеми дополнениями" };

var RussianStoreLabels = {};

RussianStoreLabels[StorePackages.HALF] = "Серебряная подписка";
RussianStoreLabels[StorePackages.ALL] = "Золотая подписка";
RussianStoreLabels[StorePackages.CORE] = "Базовая подписка";
RussianStoreLabels[StorePackages.COMPLETE] = "Полная подписка";

RussianStoreLabels[PriceCategories.PURCHASED] = ''; // "Покупка: ";
RussianStoreLabels[PriceCategories.ALREADY_OWNED] = "Уже куплено";
RussianStoreLabels[PriceCategories.DISCOUNT] = "Скидка";
RussianStoreLabels[PriceCategories.TRANSACTION] = "Плата за транзакцию";
RussianStoreLabels[PriceCategories.SUBTOTAL] = "Промежуточный итог";
RussianStoreLabels[PriceCategories.VAT] = "НДС";
RussianStoreLabels[PriceCategories.TOTAL] = "Итого";

RussianStoreLabels.SELLER_INFO = "Информация о продавце";
RussianStoreLabels.PRODUCT_CONFIRM = "Покупка до DATE: EXPANSIONS";
RussianStoreLabels.PAYMENT_HANDLER = "Оплата оформляется через провайдера Mollie.";
RussianStoreLabels.PROCEED_TO_PAYMENT = "Перейти к оплате";

var RussianAutoplayLabels = {};

RussianAutoplayLabels.REGULAR_OPTIONS = "Опции";
RussianAutoplayLabels.AUTOPLAY_OPTIONS = "Опции автоигры";
RussianAutoplayLabels.AUTOPLAY_DOCUMENTATION_GENERIC = "Что такое автоигра?";
RussianAutoplayLabels.AUTOPLAY_DOCUMENTATION_SPECIFIC = " - настройки для";
RussianAutoplayLabels.CUSTOMIZE_OPTIONS = "Кастомизировать опции";
RussianAutoplayLabels.ALL_OFF = "Выключить все";
RussianAutoplayLabels.ALL_DEFAULT = "По умолчанию";
RussianAutoplayLabels.ALL_CONVENIENT = "Удобная настройка";

var RussianAnimationTypes = {};
RussianAnimationTypes[AnimationOptions.NEW_PLAYER] = "Медленно";
RussianAnimationTypes[AnimationOptions.BEGINNER] = "Стандарт";
RussianAnimationTypes[AnimationOptions.MEDIUM] = "Быстрее";
RussianAnimationTypes[AnimationOptions.ADVANCED] = "Ещё быстрее";
RussianAnimationTypes[AnimationOptions.VERYFAST] = "Очень быстро";
RussianAnimationTypes[AnimationOptions.INSTANT] = "Мгновенно";