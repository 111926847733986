"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Player = function () {
    function Player(state, playerId, index, activeReminders, boughtProjects) {
        _classCallCheck(this, Player);

        this.state = state;
        this.playerId = playerId;
        this.index = index;
        this.name = this.playerModel.meta.playerNames[playerId];
        this.isMe = this.playerModel.meta.model.me.id === playerId;
        this.color = getByOrdinal(PlayerColors, index).color;
        this.cubeImg = getByOrdinal(PlayerColors, index).cubeImg;

        this.activeReminders = activeReminders;
        this.boughtProjects = boughtProjects;
        this.ownedZones = [];
        this.counters = [];

        this.infoView = new PlayerInfoView(this);
    }

    _createClass(Player, [{
        key: "getCounter",
        value: function getCounter(counterName) {
            return this.counters.find(function (c) {
                return c.counterName === counterName;
            });
        }
    }, {
        key: "getCounterValue",
        value: function getCounterValue(counterName) {
            var counters = this.counters.filter(function (c) {
                return c.counterName === counterName;
            });
            if (counters.length === 0) return 0;
            if (counters.length > 1) console.log("Found more than 1 counter for owner: " + this + " with name: " + counterName);
            return counters[0].value;
        }
    }, {
        key: "getZone",
        value: function getZone(zoneClass) {
            var zone = this.ownedZones.find(function (z) {
                return z instanceof zoneClass;
            });
            if (isUndefined(zone)) return this.state.nowhere;
            return zone;
        }
    }, {
        key: "setNewReminders",
        value: function setNewReminders(reminders) {
            this.activeReminders = reminders;
            this.onReminderChange();
        }
    }, {
        key: "onReminderChange",
        value: function onReminderChange() {
            this.state.game.logModel.statusBarView.onReminderChange();
            this.infoView.onReminderChange();
        }
    }, {
        key: "newBoughtProject",
        value: function newBoughtProject(boughtProject) {
            this.boughtProjects.push(boughtProject);
            var projectZone = this.state.zones.find(function (z) {
                return z instanceof SingleLandscapeZone && z.cardStacks.some(function (s) {
                    return s.cards.some(function (c) {
                        return c.cardName === boughtProject;
                    });
                });
            });
            projectZone.cardStacks.forEach(function (s) {
                return s.tokensChanged();
            });
        }
    }, {
        key: "playerModel",
        get: function get() {
            return this.state.game.playerModel;
        }
    }, {
        key: "isHero",
        get: function get() {
            return this.playerModel.hero === this;
        }
    }, {
        key: "isOpponent",
        get: function get() {
            return this.playerModel.opponents.includes(this);
        }
    }, {
        key: "isActiveOpponent",
        get: function get() {
            return this.playerModel.activeOpponent === this;
        }
    }, {
        key: "isNobody",
        get: function get() {
            return this.state.nobody === this;
        }
    }]);

    return Player;
}();

var PlayerModel = function () {
    function PlayerModel(game, meta) {
        _classCallCheck(this, PlayerModel);

        this.game = game;
        this.meta = meta;
        this.opponents = [];
    }

    _createClass(PlayerModel, [{
        key: "redrawDependents",
        value: function redrawDependents() {
            this.game.state.zones.filter(function (z) {
                return z.isVisible && z.isOwnershipDependent;
            }).forEach(function (z) {
                return z.reposition();
            });
            this.game.state.players.forEach(function (p) {
                return p.infoView.redraw();
            });
            this.game.layoutModel.tabViews.forEach(function (t) {
                return t.repositionCanvas();
            });
        }
    }, {
        key: "initialize",
        value: function initialize() {
            var _this = this;

            this.hero = this.game.state.players.find(function (p) {
                return p.isMe;
            });
            if (isUndefined(this.hero)) {
                if (this.game.heroOverride !== null) this.hero = this.game.state.players.find(function (p) {
                    return p.playerId === _this.game.heroOverride;
                });
            }
            if (isUndefined(this.hero)) this.hero = this.game.state.players[0];
            this.activeOpponent = this.game.state.players.find(function (p) {
                return !p.isHero;
            });
            this.newTurn(this.game.state.activeTurn);
            this.createUniqueInitials();
        }
    }, {
        key: "createUniqueInitials",
        value: function createUniqueInitials() {
            var players = this.game.state.players;
            players.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            });
            var previousPrefix = 1;
            for (var i = 0; i < players.length - 1; i++) {
                var prefix = 1;
                for (; prefix < players[i].name.length; prefix++) {
                    if (players[i].name[prefix - 1].toLowerCase() !== players[i + 1].name[prefix - 1].toLowerCase()) break;
                }
                players[i].initials = players[i].name.slice(0, Math.max(previousPrefix, prefix));
                previousPrefix = prefix;
            }
            if (players.length > 1 && players[players.length - 2].name.length === previousPrefix) previousPrefix += 1;
            players[players.length - 1].initials = players[players.length - 1].name.slice(0, previousPrefix);
            players.sort(function (a, b) {
                return a.index - b.index;
            });
        }
    }, {
        key: "heroIsCurrent",
        value: function heroIsCurrent() {
            return this.currentPlayerIndex !== null && this.players[this.currentPlayerIndex].isHero;
        }
    }, {
        key: "newTurn",
        value: function newTurn(turnDescription) {
            var players = this.game.state.players;
            var turnOwner = turnDescription.owner;
            if (!isUndefined(this.playerMe)) {
                if (turnDescription.controller.isMe && !turnOwner.isMe) {
                    this.hero = turnDescription.owner;
                    this.activeOpponent = this.playerMe;
                    return;
                } else {
                    this.hero = this.playerMe;
                }
            }

            if (players.length > 1) {
                if (!turnOwner.isHero) {
                    this.activeOpponent = turnOwner;
                } else {
                    var index = (turnOwner.index + 1) % players.length;
                    this.activeOpponent = players[index];
                }
            }
        }
    }, {
        key: "hero",
        set: function set(value) {
            var _this2 = this;

            this.privateHero = value;
            this.opponents = this.game.state.players.filter(function (p) {
                return p !== _this2.hero;
            });
            if (this.opponents.length > 0) {
                if (this.activeOpponent === this.privateHero || isUndefined(this.activeOpponent)) this.activeOpponent = this.game.state.players.find(function (p) {
                    return !p.isHero;
                });
            }
            this.redrawDependents();
        },
        get: function get() {
            return this.privateHero;
        }
    }, {
        key: "activeOpponent",
        set: function set(value) {
            this.privateActiveOpponent = value;
            this.redrawDependents();
        },
        get: function get() {
            return this.privateActiveOpponent;
        }
    }, {
        key: "playerMe",
        get: function get() {
            return this.game.state.players.find(function (p) {
                return p.isMe;
            });
        }
    }, {
        key: "currentPlayerIndex",
        get: function get() {
            return this.game.state.players.indexOf(this.game.state.activeTurn.owner);
        }
    }]);

    return PlayerModel;
}();