"use strict";

var EsperantoTypes = {};

EsperantoTypes[Types.ACTION] = "Ago";
EsperantoTypes[Types.TREASURE] = "Trezoro";
EsperantoTypes[Types.ATTACK] = "Atako";
EsperantoTypes[Types.CURSE] = "Malbeno";
EsperantoTypes[Types.DURATION] = "Daŭro";
EsperantoTypes[Types.EVENT] = "Okazo";
EsperantoTypes[Types.GATHERING] = "Kolektado";
EsperantoTypes[Types.KNIGHT] = "Kavaliro";
EsperantoTypes[Types.LANDMARK] = "Rekonilo";
EsperantoTypes[Types.LOOTER] = "Rabanto";
EsperantoTypes[Types.RUINS] = "Ruino";
EsperantoTypes[Types.TRAVELLER] = "Vojaĝanto";
EsperantoTypes[Types.PRIZE] = "Premio";
EsperantoTypes[Types.RESERVE] = "Rezervo";
EsperantoTypes[Types.VICTORY] = "Venko";
EsperantoTypes[Types.REACTION] = "Reago";
EsperantoTypes[Types.SHELTER] = "Ŝirmejo";
EsperantoTypes[Types.CASTLE] = "Kastelo";
EsperantoTypes[Types.FATE] = "Sorto";
EsperantoTypes[Types.NIGHT] = "Nokto";
EsperantoTypes[Types.DOOM] = "Pereo";
EsperantoTypes[Types.HEIRLOOM] = "Heredaĵo";
EsperantoTypes[Types.SPIRIT] = "Spirito";
EsperantoTypes[Types.ZOMBIE] = "Zombio";
EsperantoTypes[Types.BOON] = "Beno";
EsperantoTypes[Types.HEX] = "Envulto";
EsperantoTypes[Types.STATE] = "Stato";
EsperantoTypes[Types.PROJECT] = "Projekto";
EsperantoTypes[Types.COMMAND] = "Komando";
EsperantoTypes[Types.WAY] = "Vojo";
EsperantoTypes[Types.LIAISON] = "Kontaktulo";
EsperantoTypes[Types.ALLY] = "Aliancano";
EsperantoTypes[Types.AUGUR] = "Aŭguristo";
EsperantoTypes[Types.CLASH] = "Kolizio";
EsperantoTypes[Types.FORT] = "Reduto";
EsperantoTypes[Types.ODYSSEY] = "Odiseado";
EsperantoTypes[Types.TOWNSFOLK] = "Urbulo";
EsperantoTypes[Types.WIZARD] = "Sorĉisto";
EsperantoTypes[Types.SHADOW] = "Shadow";
EsperantoTypes[Types.OMEN] = "Omen";
EsperantoTypes[Types.PROPHECY] = "Prophecy";

var EsperantoZoneNames = {};

EsperantoZoneNames["HandZone"] = "Mano";
EsperantoZoneNames["DrawZone"] = "Tira Stako";
EsperantoZoneNames["DiscardZone"] = "Forĵeta Stako";
EsperantoZoneNames["TrashZone"] = "Rubujo";
EsperantoZoneNames["SupplyZone"] = "Provizstako";
EsperantoZoneNames["InPlayZone"] = "Ludloko";
EsperantoZoneNames["SetAsideZone"] = "Flankenmetitaj";
EsperantoZoneNames["TavernZone"] = "Mato de Taverno";
EsperantoZoneNames["NVZone"] = "Mato de Indiĝena Vilaĝo";
EsperantoZoneNames["IslandZone"] = "Mato de Insulo";
EsperantoZoneNames["ExileZone"] = "Ekzilo";
EsperantoZoneNames["RevealZone"] = "Malkaŝu";
EsperantoZoneNames["SetAsideZone"] = "Flankenmetu";
EsperantoZoneNames["SkippableRevealZone"] = "Malkaŝu";

var EsperantoMovementTypes = {};

EsperantoMovementTypes[MovementTypes.PLAY] = new TranslatedMovementType("ludu", "ludas");
EsperantoMovementTypes[MovementTypes.DRAW] = new TranslatedMovementType("tiru", "tiras");
EsperantoMovementTypes[MovementTypes.DISCARD] = new TranslatedMovementType("forĵetu", "forĵetas");
EsperantoMovementTypes[MovementTypes.GAIN] = new TranslatedMovementType("gajnu", "gajnas");
EsperantoMovementTypes[MovementTypes.TRASH] = new TranslatedMovementType("rubigu", "rubigas");
EsperantoMovementTypes[MovementTypes.TOPDECK] = new TranslatedMovementType("surmetu", "surmetas");
EsperantoMovementTypes[MovementTypes.REVEAL] = new TranslatedMovementType("malkaŝu", "malkaŝas");
EsperantoMovementTypes[MovementTypes.REVEAL_SEARCHING] = new TranslatedMovementType("malkaŝu", "malkaŝas");
EsperantoMovementTypes[MovementTypes.LOOK_AT] = new TranslatedMovementType("rigardu", "rigardas");
EsperantoMovementTypes[MovementTypes.PUT_IN_HAND] = new TranslatedMovementType("metu en manon", "metas en manon");
EsperantoMovementTypes[MovementTypes.SET_ASIDE] = new TranslatedMovementType("flankenmetu ĝin", "flankenmetas ĝin");
EsperantoMovementTypes[MovementTypes.PUT_ON_MAT] = new TranslatedMovementType("metu sur maton", "metas sur maton");
EsperantoMovementTypes[MovementTypes.DECK_TO_DISCARD] = new TranslatedMovementType("forĵetu el kartaron", "forĵetas el kartaron");
EsperantoMovementTypes[MovementTypes.BACK_ON_DECK] = new TranslatedMovementType("BACK_ON_DECK", "BACK_ON_DECK");
EsperantoMovementTypes[MovementTypes.SHUFFLE_INTO_DECK] = new TranslatedMovementType("miksu en kartaron", "miksas en kartaron");
EsperantoMovementTypes[MovementTypes.INSERT_IN_DECK] = new TranslatedMovementType("INSERT_IN_DECK", "INSERT_IN_DECK");
EsperantoMovementTypes[MovementTypes.EXCHANGE_RETURN] = new TranslatedMovementType("EXCHANGE_RETURN", "EXCHANGE_RETURN");
EsperantoMovementTypes[MovementTypes.EXCHANGE_RECEIVE] = new TranslatedMovementType("EXCHANGE_RECEIVE", "EXCHANGE_RECEIVE");
EsperantoMovementTypes[MovementTypes.PASS] = new TranslatedMovementType("donu", "donas");
EsperantoMovementTypes[MovementTypes.RETURN_TO] = new TranslatedMovementType("redonu", "redonas");
EsperantoMovementTypes[MovementTypes.PUT_ON_BOTTOM_OF] = new TranslatedMovementType("PUT_ON_BOTTOM_OF", "PUT_ON_BOTTOM_OF");
EsperantoMovementTypes[MovementTypes.STARTS_WITH] = new TranslatedMovementType("STARTS_WITH", "STARTS_WITH");
EsperantoMovementTypes[MovementTypes.TAKE] = new TranslatedMovementType("TAKE", "TAKE");
EsperantoMovementTypes[MovementTypes.GET_STATE] = new TranslatedMovementType("GET_STATE", "GET_STATE");
EsperantoMovementTypes[MovementTypes.RETURN_STATE] = new TranslatedMovementType("RETURN_STATE", "RETURN_STATE");
EsperantoMovementTypes[MovementTypes.EXILE] = new TranslatedMovementType("Ekzilu", "Ekzilas");

var EsperantoPileTokenTexts = {};

EsperantoPileTokenTexts[TokenNames.PLUS_ACTION] = "+Ag";
EsperantoPileTokenTexts[TokenNames.PLUS_BUY] = "+Aĉ";
EsperantoPileTokenTexts[TokenNames.PLUS_CARD] = "+K";
EsperantoPileTokenTexts[TokenNames.PLUS_COIN] = "+1";
EsperantoPileTokenTexts[TokenNames.MINUS_COST] = "-2";
EsperantoPileTokenTexts[TokenNames.TRASH] = "X";

var EsperantoPileMarkers = {};

EsperantoPileMarkers[PileMarkerTypes.BANE] = "Malhelpo";
EsperantoPileMarkers[PileMarkerTypes.OBELISK] = "Obelisko";
EsperantoPileMarkers[PileMarkerTypes.SPLIT] = "Disigita";
EsperantoPileMarkers[PileMarkerTypes.MIXED] = "Miksita";

var EsperantoTokenNames = {};

EsperantoTokenNames[TokenNames.PLUS_ACTION] = "+Ago jetono";
EsperantoTokenNames[TokenNames.PLUS_BUY] = "+Aĉeto jetono";
EsperantoTokenNames[TokenNames.PLUS_COIN] = "+Monero jetono";
EsperantoTokenNames[TokenNames.PLUS_CARD] = "+Karto jetono";
EsperantoTokenNames[TokenNames.MINUS_COST] = "-Kosto jetono";
EsperantoTokenNames[TokenNames.TRASH] = "Rubigjetono";
EsperantoTokenNames[TokenNames.JOURNEY] = "Vojaĝjetono";
EsperantoTokenNames[TokenNames.MINUS_CARD] = "-Karto jetono";
EsperantoTokenNames[TokenNames.MINUS_COIN] = "-Monero jetono";

var EsperantoKingdomViewerNames = {};

EsperantoKingdomViewerNames[KingdomViewerGroups.SUPPLY_CARDS] = "Kartoj en Provizo";
EsperantoKingdomViewerNames[KingdomViewerGroups.KINGDOM_LANDSCAPES] = "Okazoj, Rekoniloj, Projektoj kaj Vojoj";
EsperantoKingdomViewerNames[KingdomViewerGroups.NON_KINGDOM] = "Ne-Regna Kartoj";
EsperantoKingdomViewerNames[KingdomViewerGroups.RUINS] = "Ruinoj";
EsperantoKingdomViewerNames[KingdomViewerGroups.DRUID_BOONS] = "Benoj de Druido";
EsperantoKingdomViewerNames[KingdomViewerGroups.KNIGHTS] = "Kavaliroj";
EsperantoKingdomViewerNames[KingdomViewerGroups.CASTLES] = "Kasteloj";
EsperantoKingdomViewerNames[KingdomViewerGroups.PRIZES] = "Premioj";
EsperantoKingdomViewerNames[KingdomViewerGroups.REWARDS] = "Rewards";
EsperantoKingdomViewerNames[KingdomViewerGroups.BOONS_HEXES] = "Benoj kaj Envultoj";
EsperantoKingdomViewerNames[KingdomViewerGroups.STATES_ARTIFACTS] = "Statoj kaj Artefaktoj";
EsperantoKingdomViewerNames[KingdomViewerGroups.BLACK_MARKET] = "Nigra Merkato";

// Missing lines as of 09.01.2023 19:11:50
EsperantoTypes[Types.LOOT] = "Loot";
EsperantoTypes[Types.TRAIT] = "Trait";
EsperantoZoneNames["TheBox"] = "the Box";
EsperantoKingdomViewerNames[KingdomViewerGroups.LOOT] = "Loot";