"use strict";

var TChineseLogEntries = {};

TChineseLogEntries[LogEntryNames.GAIN] = "Argument0 獲得 Argument1.";
TChineseLogEntries[LogEntryNames.GAIN_WITH] = "Argument0 獲得 Argument1. (Argument2)";
TChineseLogEntries[LogEntryNames.BUY_AND_GAIN] = "Argument0 購買並且獲得 Argument1.";
TChineseLogEntries[LogEntryNames.TRASH] = "Argument0 移除 Argument1.";
TChineseLogEntries[LogEntryNames.DISCARD] = "Argument0 棄掉 Argument1.";
TChineseLogEntries[LogEntryNames.DISCARD_WITH] = "Argument0 棄掉 Argument1. (Argument2)";
TChineseLogEntries[LogEntryNames.PLAY] = "Argument0 打出 Argument1.";
TChineseLogEntries[LogEntryNames.PLAY_AGAIN] = "Argument0 再一次打出 Argument1 .";
TChineseLogEntries[LogEntryNames.PLAY_THIRD] = "Argument0 第三次打出 Argument1 .";
TChineseLogEntries[LogEntryNames.TOPDECK] = "Argument0 將 Argument1 放到牌庫頂";
TChineseLogEntries[LogEntryNames.DRAW] = "Argument0 抽出 Argument1.";
TChineseLogEntries[LogEntryNames.DRAW_FROM] = "Argument0 抽出 Argument1. (Argument2)";
TChineseLogEntries[LogEntryNames.REVEAL] = "Argument0 展示 Argument1.";
TChineseLogEntries[LogEntryNames.REVEAL_SEARCHING] = "Argument0  Argument1.";
TChineseLogEntries[LogEntryNames.REVEAL_FINDING] = "Argument0 展示了 Argument1.";
TChineseLogEntries[LogEntryNames.REVEAL_SEARCHING_AND_FINDING] = "Argument0 展示了 Argument1 跟 Argument2.";
TChineseLogEntries[LogEntryNames.REVEAL_HAND] = "Argument0 展示了手牌: Argument1.";
TChineseLogEntries[LogEntryNames.REVEAL_DISCARD] = "Argument0 reveals their discard: Argument1.";
TChineseLogEntries[LogEntryNames.LOOK_AT] = "Argument0 觀看 Argument1.";
TChineseLogEntries[LogEntryNames.PUT_IN_HAND] = "Argument0 將 Argument1 放到手中.";
TChineseLogEntries[LogEntryNames.SET_ASIDE] = "Argument0 將 Argument1 放置一邊.";
TChineseLogEntries[LogEntryNames.PUT_ON_MAT] = "Argument0 將 Argument1 放置於 Argument2.";
TChineseLogEntries[LogEntryNames.CALL] = "Argument0 呼叫 Argument1.";
TChineseLogEntries[LogEntryNames.DECK_TO_DISCARD] = "Argument0 將他們的牌庫放到棄牌堆.";
TChineseLogEntries[LogEntryNames.BACK_ON_DECK] = "Argument0 將 Argument1 放回牌堆.";
TChineseLogEntries[LogEntryNames.SHUFFLE_INTO_DECK] = "Argument0 將 Argument1 洗回牌堆.";
TChineseLogEntries[LogEntryNames.INSERT_IN_DECK] = "Argument0 將 Argument1 插入牌堆.";
TChineseLogEntries[LogEntryNames.EXCHANGE_RETURN] = "Argument0 歸還 Argument1.";
TChineseLogEntries[LogEntryNames.EXCHANGE_RECEIVE] = "Argument0 收到 Argument1.";
TChineseLogEntries[LogEntryNames.PASS] = "Argument0 將 Argument1 傳給 Argument2.";
TChineseLogEntries[LogEntryNames.STARTS_WITH] = "Argument0 起始牌是 Argument1.";
TChineseLogEntries[LogEntryNames.BUY] = "Argument0 購買 Argument1.";
TChineseLogEntries[LogEntryNames.COUNTER_ADD] = "COUNTER_ADD";
TChineseLogEntries[LogEntryNames.COUNTER_RESET] = "COUNTER_RESET";
TChineseLogEntries[LogEntryNames.REACTS_WITH] = "Argument0 使用 Argument1 應對.";
TChineseLogEntries[LogEntryNames.SHUFFLES] = "Argument0 重洗牌庫.";
TChineseLogEntries[LogEntryNames.NEW_TURN] = "Argument0";
TChineseLogEntries[LogEntryNames.WISH_CORRECT] = "Argument0 許願 Argument1 並且找到了它.";
TChineseLogEntries[LogEntryNames.WISH_WRONG] = "Argument0 許願 Argument1 但翻開是 Argument2.";
TChineseLogEntries[LogEntryNames.BLACK_MARKET_RESHUFFLE] = "重洗黑市牌堆";
TChineseLogEntries[LogEntryNames.RETURN_TO] = "Argument0 歸還 Argument1 到 Argument2.";
TChineseLogEntries[LogEntryNames.PUT_ON_BOTTOM_OF] = "Argument0 將 Argument1 放到 Argument2. 底部";
TChineseLogEntries[LogEntryNames.LOSE_TRACK_MOVED] = "Argument0 無法追蹤 Argument1 在哪裡(它被移動了).";
TChineseLogEntries[LogEntryNames.LOSE_TRACK_HIDDEN] = "Argument0 無法追蹤 Argument1 (它被覆蓋了).";
TChineseLogEntries[LogEntryNames.LOSE_TRACK_RESHUFFLED] = "Argument0 無法追蹤 Argument1 (它被重洗了).";
TChineseLogEntries[LogEntryNames.LOSE_TRACK_ANONYMOUS] = "Argument0 無法追蹤.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_BANDIT_CAMP] = "Argument0.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_TOWER] = "Argument0.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH_ABSENT] = "沒有名字不相同的行動卡";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH] = "Argument0.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_WALL] = "Argument0.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_WOLF_DEN] = "剛剛好一張的卡片：Argument0.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_PALACE] = "Argument0.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_ORCHARD] = "Argument0.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_OBELISK] = "從 Argument1牌堆來的Argument0張卡.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_MUSEUM] = "Argument0 種不一樣名字的卡片.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_KINGS_CASTLE] = "Argument0張城堡.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_KEEP] = "Argument0.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_HUMBLE_CASTLE] = "Argument0張城堡.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_FOUNTAIN] = "Argument0.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_FEODUM] = "Argument0.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_GARDENS] = "Argument0.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_VINEYARD] = "Argument0 張行動卡.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_SILK_ROAD] = "Argument0 張分數卡.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_FAIRGROUNDS] = "Argument0 張不一樣名字的卡片.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_DUKE] = "Argument0.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_DUH] = "";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_VP_TOKENS] = "分數指示物.";
TChineseLogEntries[LogEntryNames.PUT_IN_HAND_FROM] = "Argument0 將 Argument1 放入手中 (Argument2).";
TChineseLogEntries[LogEntryNames.STARTS_TURN] = "Argument0 開始他的回合.";
TChineseLogEntries[LogEntryNames.TAKE_VP_FROM] = "Argument0 從 Argument2 拿取 Argument1分.";
TChineseLogEntries[LogEntryNames.MOVE_VP_FROM_TO] = "Argument0 將 Argument1 上的分數指示物從 Argument2 移動到 Argument3.";
TChineseLogEntries[LogEntryNames.OBELISK_CHOOSES_PILE] = "Obelisk 選擇 Argument0.";
TChineseLogEntries[LogEntryNames.PLAYER_MOVES_TOKEN_TO] = "Argument0 移動 Argument1 到 Argument2.";
TChineseLogEntries[LogEntryNames.PLAYER_GETS_VP] = "Argument0 得到 Argument1分.";
TChineseLogEntries[LogEntryNames.PLAYER_GETS_VP_FROM] = "Argument0 因為 Argument2的效果得到了Argument1分.";
TChineseLogEntries[LogEntryNames.ADDS_VP_TO] = "Argument0 加 Argument1 分 到 Argument2.";
TChineseLogEntries[LogEntryNames.WAITING_FOR] = "等待 Argument0.";
TChineseLogEntries[LogEntryNames.ALMS_FAIL] = "Argument0 購買施捨 但是有 Argument1 在打出中.";
TChineseLogEntries[LogEntryNames.BORROW_FAIL] = "Argument0 購買借貸但是因為已經拿取 Argument1了";
TChineseLogEntries[LogEntryNames.QUEST_FAIL_ATTACK] = "Argument0 無法棄掉一張攻擊卡";
TChineseLogEntries[LogEntryNames.QUEST_FAIL_CURSES] = "Argument0 無法棄掉 Argument1.";
TChineseLogEntries[LogEntryNames.QUEST_FAIL_DISCARD] = "Argument0 無法棄掉六張卡片.";
TChineseLogEntries[LogEntryNames.SAVE_FAIL] = "Argument0 沒有將卡片放到一邊.";
TChineseLogEntries[LogEntryNames.SCOUTING_PARTY_FAIL] = "Argument0 沒有卡片來棄卡或放回牌庫頂.";
TChineseLogEntries[LogEntryNames.BONFIRE_FAIL] = "Argument0 沒有移除任何卡片.";
TChineseLogEntries[LogEntryNames.MISSION_SUCCESS] = "Argument0 這回合之後獲得一個額外的回合";
TChineseLogEntries[LogEntryNames.TRADE_FAIL] = "Argument0 沒有移除任何東西.";
TChineseLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_DOWN] = "Argument0 將 Argument1 翻成反面.";
TChineseLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_UP] = "Argument0 將 Argument1 翻成正面.";
TChineseLogEntries[LogEntryNames.TRIUMPH_FAIL] = "Argument0 無法獲得 Argument1.";
TChineseLogEntries[LogEntryNames.ADVANCE_FAIL] = "Argument0 沒有移除行動卡.";
TChineseLogEntries[LogEntryNames.TAX_ADD_DEBT] = "Argument0 增加 Argument2 上面的 Argument1  .";
TChineseLogEntries[LogEntryNames.TAX_TAKE_DEBT] = "Argument0 拿取來自 Argument2的 Argument1 .";
TChineseLogEntries[LogEntryNames.RITUAL_FAIL] = "Argument0 沒有 Argument1.";
TChineseLogEntries[LogEntryNames.WINDFALL_FAIL] = "Argument0 不是空的.";
TChineseLogEntries[LogEntryNames.DOMINATE_FAIL] = "Argument0 沒有獲得 Argument1.";
TChineseLogEntries[LogEntryNames.PLUS_ACTION_FROM] = "Argument0 得到 +1 行動 (來自 Argument1).";
TChineseLogEntries[LogEntryNames.PLUS_BUY_FROM] = "Argument0 得到 +1 購買 (來自 Argument1).";
TChineseLogEntries[LogEntryNames.DRAWS_FROM] = "Argument0 抽出 Argument1 (來自 Argument2).";
TChineseLogEntries[LogEntryNames.PLUS_COIN_FROM] = "Argument0 得到 +1 預算 (來自 Argument1).";
TChineseLogEntries[LogEntryNames.DRAWS_LESS_BECAUSE] = "Argument0 少抽一張卡 (因為 Argument1).";
TChineseLogEntries[LogEntryNames.PRINCE_FAILS] = "";
TChineseLogEntries[LogEntryNames.POSSESSION_TAKES_COIN_TOKENS] = "改由Argument0 拿取預算指示物.";
TChineseLogEntries[LogEntryNames.POSSESSION_TAKES_DEBT_TOKENS] = "改由Argument0 拿取負債指示物.";
TChineseLogEntries[LogEntryNames.POSSESION_TAKES_VP_TOKENS] = "改為 Argument0 拿取分數指示物.";
TChineseLogEntries[LogEntryNames.TAKES_DEBT] = "Argument0 拿取 Argument1 負債.";
TChineseLogEntries[LogEntryNames.REPAYS_DEBT] = "Argument0 償還 Argument1 負債.";
TChineseLogEntries[LogEntryNames.REPAYS_SOME_DEBT] = "Argument0 償還 Argument1 負債 (剩下 Argument2 ).";
TChineseLogEntries[LogEntryNames.RETURNS_TOKEN] = "Argument0 歸還 Argument1 (從 Argument2 來的).";
TChineseLogEntries[LogEntryNames.SET_ASIDE_WITH] = "Argument0 使用 Argument2 將 Argument1 放到一邊 .";
TChineseLogEntries[LogEntryNames.BLOCKS_WITH] = "Argument0 用 Argument1 阻擋 .";
TChineseLogEntries[LogEntryNames.NAMES] = "Argument0 指定 Argument1.";
TChineseLogEntries[LogEntryNames.GAIN_ON_DRAWPILE] = "Argument0 獲得 Argument1 並起放在牌庫頂.";
TChineseLogEntries[LogEntryNames.GAIN_FROM_TRASH] = "Argument0 獲得 Argument1 來自移除堆.";
TChineseLogEntries[LogEntryNames.OBELISK_FAILS] = "Obelisk 無法選擇一組供應區的行動卡.";
TChineseLogEntries[LogEntryNames.INHERITS] = "Argument0 繼承 Argument1.";
TChineseLogEntries[LogEntryNames.GAME_META_INFO] = "Argument0";
TChineseLogEntries[LogEntryNames.THE_SKYS_GIFT_FAIL] = "Argument0 沒有成功棄卡 [The Skys Gift]";
TChineseLogEntries[LogEntryNames.RECEIVES] = "Argument0 收到 Argument1.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_PASTURE] = "Argument0.";
TChineseLogEntries[LogEntryNames.TAKE] = "Argument0 拿取 Argument1.";
TChineseLogEntries[LogEntryNames.RETURN] = "Argument0 歸還 Argument1.";
TChineseLogEntries[LogEntryNames.THE_SUNS_GIFT_FAIL] = "[The Suns Gift] 沒有辦法棄卡.";
TChineseLogEntries[LogEntryNames.DRUID_SELECTS_BOONS] = "[Druid] 將 Argument1 放置一邊.";
TChineseLogEntries[LogEntryNames.DISCARD_RECEIVABLE] = "Argument0 棄掉 Argument1.";
TChineseLogEntries[LogEntryNames.PLAY_ENCHANTED] = "Argument0 被 Argument1 迷惑了";
TChineseLogEntries[LogEntryNames.GAIN_ANOTHER_EXPERIMENT] = "Argument0 獲得另一張 [Experiment]";
TChineseLogEntries[LogEntryNames.COFFERS_FROM_PATRON] = "Argument0 由[Patron]獲得一存款 .";
TChineseLogEntries[LogEntryNames.GETS_COFFER] = "Argument0 得到 +Argument1 存款.";
TChineseLogEntries[LogEntryNames.GETS_COFFERS] = "Argument0 得到 +Argument1 存款.";
TChineseLogEntries[LogEntryNames.GETS_COFFER_FROM] = "Argument0 得到 +Argument1 存款. (Argument2)";
TChineseLogEntries[LogEntryNames.GETS_COFFERS_FROM] = "Argument0 得到 +Argument1 存款. (Argument2)";
TChineseLogEntries[LogEntryNames.USES_COFFER] = "Argument0 使用 Argument1 存款. (+$Argument1)";
TChineseLogEntries[LogEntryNames.USES_COFFERS] = "Argument0 使用 Argument1 存款. (+$Argument1)";
TChineseLogEntries[LogEntryNames.GETS_VILLAGER] = "Argument0 得到 +Argument1 村民.";
TChineseLogEntries[LogEntryNames.GETS_VILLAGERS] = "Argument0 得到 +Argument1 村民.";
TChineseLogEntries[LogEntryNames.GETS_VILLAGER_FROM] = "Argument0 得到 +Argument1 村民. (Argument2)";
TChineseLogEntries[LogEntryNames.GETS_VILLAGERS_FROM] = "Argument0 得到 +Argument1 村民. (Argument2)";
TChineseLogEntries[LogEntryNames.USES_VILLAGER] = "Argument0 使用 Argument1 村民.";
TChineseLogEntries[LogEntryNames.USES_VILLAGERS] = "Argument0 使用 Argument1 村民.";
TChineseLogEntries[LogEntryNames.PLAYER_ADDS_FAVOR_TOKEN_TO] = "Argument0 places a Favor token on Argument1. ([Family of Inventors])"; //please translate
TChineseLogEntries[LogEntryNames.ENDS_BUYPHASE] = "Argument0 結束他的購買階段.";
TChineseLogEntries[LogEntryNames.GETS_ACTION] = "Argument0 得到 +Argument1 行動.";
TChineseLogEntries[LogEntryNames.GETS_ACTIONS] = "Argument0 得到 +Argument1 行動.";
TChineseLogEntries[LogEntryNames.GETS_ACTION_FROM] = "Argument0 得到 +Argument1 行動. (Argument2)";
TChineseLogEntries[LogEntryNames.GETS_ACTIONS_FROM] = "Argument0 得到 +Argument1 行動. (Argument2)";
TChineseLogEntries[LogEntryNames.GETS_BUY] = "Argument0 得到 +Argument1 購買.";
TChineseLogEntries[LogEntryNames.GETS_BUYS] = "Argument0 得到 +Argument1 購買.";
TChineseLogEntries[LogEntryNames.GETS_BUY_FROM] = "Argument0 得到 +Argument1 購買. (Argument2)";
TChineseLogEntries[LogEntryNames.GETS_BUYS_FROM] = "Argument0 得到 +Argument1 購買. (Argument2)";
TChineseLogEntries[LogEntryNames.GETS_COIN] = "Argument0 得到 +Argument1 預算.";
TChineseLogEntries[LogEntryNames.GETS_COINS] = "Argument0 得到 +Argument1 預算.";
TChineseLogEntries[LogEntryNames.GETS_COIN_FROM] = "Argument0 得到 +Argument1 預算. (Argument2)";
TChineseLogEntries[LogEntryNames.GETS_COINS_FROM] = "Argument0 得到 +Argument1 預算. (Argument2)";
TChineseLogEntries[LogEntryNames.LOSES_COIN] = "Argument0 失去 Argument1 預算.";
TChineseLogEntries[LogEntryNames.LOSES_COINS] = "Argument0 失去 Argument1 預算.";
TChineseLogEntries[LogEntryNames.BOTTOMDECKS] = "Argument0 將 Argument1 放到牌庫底.";
TChineseLogEntries[LogEntryNames.TRASH_WITH] = "Argument0 移除 Argument1. (Argument2)";
TChineseLogEntries[LogEntryNames.PRIEST_ACTIVATES] = "從現在開始每次你移除卡片就得到 +$Argument0.";
TChineseLogEntries[LogEntryNames.PLAY_TREASURES_FOR] = "Argument0 打出 Argument1. (+$Argument2)";
TChineseLogEntries[LogEntryNames.PLAY_TREASURES_AGAIN_FOR] = "Argument0 再一次打出 Argument1 . (+$Argument2)";
TChineseLogEntries[LogEntryNames.RETURNS_MINUS_COIN_TOKEN] = "Argument0 歸還 Argument1 (從 Argument2 來的). (-$1)";
TChineseLogEntries[LogEntryNames.NOT_IN_PLAY_PREVENTED_PLAYING] = "由於 Argument0 不在出牌區,因此無法發動效果。";
TChineseLogEntries[LogEntryNames.PILLAGE_FAIL] = "[Pillage] 沒有被移除，因此無法發動後續效果";
TChineseLogEntries[LogEntryNames.DEATH_CART_FAIL] = "[Death Cart]沒有被移除，因此無法發動後續效果";
TChineseLogEntries[LogEntryNames.TACTICIAN_FAIL] = "[Tactician] 沒有被移除，因此無法發動後續效果";
TChineseLogEntries[LogEntryNames.EMBARGO_FAIL] = "[Embargo] 沒有被移除，因此無法發動後續效果";
TChineseLogEntries[LogEntryNames.EXILE] = "Argument0 驅逐 Argument1.";
TChineseLogEntries[LogEntryNames.DISCARD_FROM_EXILE] = "Argument0 棄掉被驅逐的 Argument1 ";
TChineseLogEntries[LogEntryNames.DISCARD_REVEALED] = "Argument0 棄掉並展示 Argument1.";
TChineseLogEntries[LogEntryNames.PLAY_WITH] = TChineseLogEntries[LogEntryNames.PLAY] + " (Argument2)";
TChineseLogEntries[LogEntryNames.PLAY_AGAIN_WITH] = TChineseLogEntries[LogEntryNames.PLAY] + " (Argument2)";
TChineseLogEntries[LogEntryNames.PLAY_THIRD_WITH] = TChineseLogEntries[LogEntryNames.PLAY] + " (Argument2)";
TChineseLogEntries[LogEntryNames.IS_INVESTED] = "其他玩家共同投資或獲得 Argument1時，Argument0 將會抽出 Argument2 張卡";
TChineseLogEntries[LogEntryNames.COMMERCE_FAIL] = "Argument0 沒有獲得任何卡片";
TChineseLogEntries[LogEntryNames.STAMPEDE_FAIL] = "Argument0 購買[Stampede] 時，有 Argument1 張卡在出牌區";
TChineseLogEntries[LogEntryNames.PLAY_USING_WAY] = "Argument0 打出 Argument1 ，但是發動 Argument2 的效果";
TChineseLogEntries[LogEntryNames.WAY_OF_THE_HORSE_FAILS_NO_PILE] = "Argument0 沒有地方歸還";
TChineseLogEntries[LogEntryNames.WAY_OF_THE_HORSE_FAILS_NOT_IN_PLAY] = "[Way Of The Horse] 沒辦法移動 Argument0, 因為那張卡已經不在出牌區了.";
TChineseLogEntries[LogEntryNames.WAY_OF_THE_TURTLE_FAILS_TO_SET_ASIDE] = "[Way Of The Turtle] 無法將 Argument0 放到一邊";
TChineseLogEntries[LogEntryNames.USES_WAY] = "Argument0 使用 Argument1.";
TChineseLogEntries[LogEntryNames.WOULD_GET_COIN] = "Argument0 原本要 +$Argument1.";
TChineseLogEntries[LogEntryNames.WOULD_DRAW] = "Argument0 原本要抽 Argument1 張卡.";
TChineseLogEntries[LogEntryNames.TOPDECK_WITH] = "Argument0 用 Argument2 將 Argument1 放回牌庫頂 .";
TChineseLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_NO_PILE] = "Argument0 沒有地方歸還，因此效果失效。";
TChineseLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_NOT_IN_PLAY] = "[Way Of The Butterfly]由於卡片不在出牌區中，因此無法移動 Argument0，並效果失效";
TChineseLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_INTENTIONAL] = "Argument0 決定不歸還 Argument1 。";
TChineseLogEntries[LogEntryNames.PLAY_NULLIFIED] = "Argument0 打出 Argument1 (但是被 [Highwayman]無效了).";
TChineseLogEntries[LogEntryNames.GETS_FAVOR] = "Argument0 得到 +Argument1 點支持.";
TChineseLogEntries[LogEntryNames.GETS_FAVORS] = "Argument0 得到 +Argument1 點支持.";
TChineseLogEntries[LogEntryNames.GETS_FAVOR_FROM] = "Argument0 得到 +Argument1 點支持. (Argument2)";
TChineseLogEntries[LogEntryNames.GETS_FAVORS_FROM] = "Argument0 得到 +Argument1 點支持. (Argument2)";
TChineseLogEntries[LogEntryNames.SPENDS_FAVOR] = "Argument0 花費 Argument1 點支持.";
TChineseLogEntries[LogEntryNames.SPENDS_FAVORS] = "Argument0 花費 Argument1 點支持.";
TChineseLogEntries[LogEntryNames.ROTATES_EMPTY_PILE] = "Argument0 旋轉了一疊空的卡片";
TChineseLogEntries[LogEntryNames.ROTATES_IDENTICAL_PILE] = "Argument0 旋轉了一疊一樣的卡片.";
TChineseLogEntries[LogEntryNames.ROTATES] = "Argument0 旋轉 Argument1.";
TChineseLogEntries[LogEntryNames.ISLAND_FOLK] = "Argument0 此回合之後再多一個回合.";
TChineseLogEntries[LogEntryNames.ROYAL_GALLEY_FAIL] = "[Royal Galley] 無法放到一邊.";
TChineseLogEntries[LogEntryNames.FAILS_TO_PLAY] = "Argument0 無法打出 Argument1";
TChineseLogEntries[LogEntryNames.FAILS_TO_PLAY_WRONG_LOCATION] = "Argument0 無法發動 play Argument1 因為它不在 Argument2"; // TRANSLATE, example "Ingix fails to play a Village Green because it isn't in Discard pile"
TChineseLogEntries[LogEntryNames.STRANDS_CARDS] = "Argument0 strands Argument1";
TChineseLogEntries[LogEntryNames.VOYAGE_SUCCESS] = "此回合之後，Argument0 會有多一個回合。";
TChineseLogEntries[LogEntryNames.GARRISON_ADDS_TOKEN] = "Argument0 累積一點在 [Garrison], 目前總共有 Argument1 點.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_PLATEAU_SHEPHERDS] = "Argument0 張卡, Argument1 個支持.";
TChineseLogEntries[LogEntryNames.PUT_IN_DISCARD] = "Argument0 將 Argument1 加入手中.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_TERRITORY] = "總共有 Argument0 種不同名字的分數卡.";
TChineseLogEntries[LogEntryNames.SORCERESS_GUESS] = "Argument0 猜下一張卡是 Argument1.";
TChineseLogEntries[LogEntryNames.SORCERER_GUESS] = "Argument0 猜下一張卡是 Argument1.";
TChineseLogEntries[LogEntryNames.SKIPS_A_TURN_REMAINING] = "Argument0 將要跳過下個回合 (還有 Argument1 個回合要跳過).";
TChineseLogEntries[LogEntryNames.SKIPS_A_TURN] = "Argument0 會跳過下個回合.";
TChineseLogEntries[LogEntryNames.CANCELS_A_TURN] = "Argument0 想要連續執行三個回合，所以 Argument1 的效果失效了.";
TChineseLogEntries[LogEntryNames.WILL_SKIP_A_TURN] = "Argument0 將會跳過下個回合";
TChineseLogEntries[LogEntryNames.WILL_SKIP_A_TURN_COUNT] = "Argument0 將會跳過下個回合 (總共跳過 Argument1 個回合).";
TChineseLogEntries[LogEntryNames.KEEPS_IN_HAND] = "Argument0 將 Argument1 留在手中.";
TChineseLogEntries[LogEntryNames.INVESTMENT_FAIL] = "[Investment] 不在出牌區，因此無法移除他自己";
TChineseLogEntries[LogEntryNames.GAIN_SETTING_ASIDE] = "Argument0 獲得 Argument1 並將他放到一邊.";
TChineseLogEntries[LogEntryNames.PLAYER_BIDS] = "Argument0 以 Argument1 負債來競標";
TChineseLogEntries[LogEntryNames.BEGINS_BUYPHASE] = "Argument0 開始他的購買階段"; // please translate
TChineseLogEntries[LogEntryNames.BEGINS_CLEANUP_PHASE] = "Argument0 開始他的清除階段";
TChineseLogEntries[LogEntryNames.PLAY_TREASURES_FOR] = TChineseLogEntries[LogEntryNames.PLAY] + " (+$Argument2)";
TChineseLogEntries[LogEntryNames.PLAY_TREASURES_AGAIN_FOR] = TChineseLogEntries[LogEntryNames.PLAY_AGAIN] + " (+$Argument2)";
TChineseLogEntries[LogEntryNames.PLAY_TREASURES_THIRD_FOR] = TChineseLogEntries[LogEntryNames.PLAY_THIRD] + " (+$Argument2)";
TChineseLogEntries[LogEntryNames.SPELL_SCROLL_FAILS] = "[Spell Scroll] 無法移除，因此效果失效";
TChineseLogEntries[LogEntryNames.PERIL_FAIL] = "[Peril] 無法移除，因此效果失效";
TChineseLogEntries[LogEntryNames.ACQUIRES_TRAIT] = " Argument0 變成了 Argument1 Argument0 ! ";
TChineseLogEntries[LogEntryNames.TRAIT_FAILS] = "Argument0 本場遊戲沒有行動卡可以搭配";
TChineseLogEntries[LogEntryNames.SHUFFLES_CARDS] = "Argument0 洗牌 Argument1.";
TChineseLogEntries[LogEntryNames.PREMOVES] = "Argument0 先動了 Argument1.";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_MARCHLAND] = "Argument0 分數ˇ卡";

TChineseLogEntries[LogEntryNames.FARMHANDS_FAIL] = "[Farmhands] 無法將一張卡放到一邊";
TChineseLogEntries[LogEntryNames.FARMHANDS_REFUSE] = "[Farmhands] 無法將一張卡放到一邊";
TChineseLogEntries[LogEntryNames.EXPLAIN_POINTS_DEMESNE] = "Argument0 黃金";
TChineseLogEntries[LogEntryNames.REACTS_PLAYING] = "Argument0 使用 Argument1 應對, 打出他.";

TChineseLogEntries[LogEntryNames.PLAY_ENLIGHTENED] = "Argument0 打出 Argument1 來 +1 卡片 +1 行動";
TChineseLogEntries[LogEntryNames.PANIC_FAILS] = "[Panic] 無法歸還 Argument0";
TChineseLogEntries[LogEntryNames.PANIC_FAILS_NO_PILE] = "[Panic] 無法歸還 Argument0 (本場遊戲沒有它的供應區)";
TChineseLogEntries[LogEntryNames.AMASS_FAILS] = "Argument0 購買 [Amass] 但已經有 Argument1 在出牌區了.";