"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Bezier = function () {
	function Bezier(x1, y1, x2, y2) {
		_classCallCheck(this, Bezier);

		this.x1 = x1;
		this.x2 = x2;
		this.y1 = y1;
		this.y2 = y2;
	}

	_createClass(Bezier, [{
		key: "getX",
		value: function getX(t) {
			return 3 * (1 - t) * t * (this.x1 * (1 - t) + this.x2 * t) + Math.pow(t, 3);
		}
	}, {
		key: "getY",
		value: function getY(t) {
			return 3 * (1 - t) * t * (this.y1 * (1 - t) + this.y2 * t) + Math.pow(t, 3);
		}
	}, {
		key: "invertX",
		value: function invertX(x) {
			var threshold = 0.0001;
			if (x > 1 - threshold) return 1;
			if (x < threshold) return 0;
			var upper = 1;
			var lower = 0;
			while (upper - lower > threshold) {
				var guess = (upper + lower) / 2;
				var result = this.getX(guess);
				if (result < x - threshold) {
					lower = guess;
				} else if (result > x + threshold) {
					upper = guess;
				} else {
					return guess;
				}
			}
			return (upper + lower) / 2;
		}
	}, {
		key: "getYFromX",
		value: function getYFromX(x) {
			return this.getY(this.invertX(x));
		}
	}]);

	return Bezier;
}();