"use strict";

webclient.component("orphanage", {
    controller: ['$scope', 'gamestate', 'cardWindowService', function ($scope, gamestate, cardWindowService) {

        this.getOrphanage = function () {
            return gamestate.orphanage;
        };
        this.getOrphanageStacks = function () {
            return gamestate.orphanage.cardStacks;
        };
        this.shouldShowOrphanage = function () {
            return gamestate.orphanage.visible;
        };
        $scope.$on(Events.REDRAW, function () {
            if (gamestate.orphanage.dirty) {
                if (gamestate.orphanage.cards.length > 0) {
                    if (!gamestate.orphanage.visible) {
                        cardWindowService.showWindow(gamestate.orphanage);
                    }
                    gamestate.orphanage.cards.forEach(function (card) {
                        card.locate(gamestate.orphanage);
                    });
                    revealWindowPositioner(gamestate.orphanage);
                } else {
                    cardWindowService.hideWindow(gamestate.orphanage);
                }
                gamestate.orphanage.dirty = false;
                safeDigest($scope);
            }
        });
    }],
    template: "\n        <card-window\n            ng-if=\"$ctrl.shouldShowOrphanage()\"\n            zone=\"$ctrl.getOrphanage()\">\n        </card-window>\n        <card-wrapper\n            ng-if=\"$ctrl.shouldShowOrphanage()\"\n            ng-repeat=\"s in $ctrl.getOrphanageStacks()\"\n            card-stack=\"s\">\n        </card-wrapper>\n    "
});