"use strict";

var DutchPhrases = {};

DutchPhrases[Phrases.THE] = "the";
DutchPhrases[Phrases.THE_FROM] = "the";
DutchPhrases[Phrases.THE_TO] = "the";
DutchPhrases[Phrases.AND] = "and";
DutchPhrases[Phrases.OTHER] = "other";
DutchPhrases[Phrases.ANOTHER] = "another";
DutchPhrases[Phrases.PILE] = "pile";
DutchPhrases[Phrases.UNDO] = "Undo";
DutchPhrases[Phrases.YOU] = "You";
DutchPhrases[Phrases.WOULD] = "would";
DutchPhrases[Phrases.YOU_MAY] = "You may...";
DutchPhrases[Phrases.COMMA] = ", ";
DutchPhrases[Phrases.PERIOD] = ".";
DutchPhrases[Phrases.COMPLEX_STORY_LINE] = "FIRST, SECOND";
DutchPhrases[Phrases.COIN] = "Coin";
DutchPhrases[Phrases.COINS] = "Coins";
DutchPhrases[Phrases.COINS_MANY] = "Coins";
DutchPhrases[Phrases.POTION] = "Potion";
DutchPhrases[Phrases.POTIONS] = "Potions";
DutchPhrases[Phrases.POTIONS_MANY] = "Potions";
DutchPhrases[Phrases.DEBT] = "Debt";

DutchPhrases[Phrases.VERSION] = "Version";
DutchPhrases[Phrases.USERNAME] = "Username";
DutchPhrases[Phrases.PASSWORD] = "Password";
DutchPhrases[Phrases.CONFIRM_PASSWORD] = "Confirm Password";
DutchPhrases[Phrases.FORGOT_YOUR_PASSWORD] = "Forgot your password?";
DutchPhrases[Phrases.CREATE_ACCOUNT] = "Create Account";
DutchPhrases[Phrases.EMAIL] = "Email";
DutchPhrases[Phrases.SIGN_UP] = "Sign Up";
DutchPhrases[Phrases.LOGIN] = "Login";
DutchPhrases[Phrases.KICK] = "Kick";
DutchPhrases[Phrases.RESIGN] = "Resign";
DutchPhrases[Phrases.CREATE_TABLE] = "Create Table";
DutchPhrases[Phrases.LEAVE_TABLE] = "Leave Table";
DutchPhrases[Phrases.MESSAGE] = "message";
DutchPhrases[Phrases.START_GAME] = "Start Game";
DutchPhrases[Phrases.READY] = "Ready";
DutchPhrases[Phrases.NOT_READY] = "Not Ready";
DutchPhrases[Phrases.PLAYERS] = "Players";
DutchPhrases[Phrases.SPECTATORS] = "Spectators";
DutchPhrases[Phrases.HOST] = "Host";
DutchPhrases[Phrases.RANDOM] = "Random";
DutchPhrases[Phrases.BASIC_OPTIONS] = "Basic Options";
DutchPhrases[Phrases.ADVANCED_OPTIONS] = "Advanced Options";
DutchPhrases[Phrases.YES] = "Yes";
DutchPhrases[Phrases.NO] = "No";
DutchPhrases[Phrases.FRIENDS_ONLY] = "Friends only";
DutchPhrases[Phrases.EVENTS_AND_LANDMARKS] = "Events and Landmarks";
DutchPhrases[Phrases.TRUE] = "true";
DutchPhrases[Phrases.FALSE] = "false";
DutchPhrases[Phrases.QUICK_MATCH] = "Quick Match";
DutchPhrases[Phrases.GOOD_MATCH] = "Good Match";
DutchPhrases[Phrases.WAIT_FOR_EXPANSIONS] = "Wait for Expansions";
DutchPhrases[Phrases.CANCEL] = "Cancel";
DutchPhrases[Phrases.VIEW_TRASH] = "Trash";
DutchPhrases[Phrases.VIEW_KINGDOM] = "Kingdom";
DutchPhrases[Phrases.VIEW_PLAY_AREA] = "Play Area";
DutchPhrases[Phrases.LEAVE] = "Leave";
DutchPhrases[Phrases.TOTAL_IN_QUEUE] = "Total users in queue";
DutchPhrases[Phrases.EDIT_TABLE] = "Edit Table";
DutchPhrases[Phrases.NEED_HELP] = "Need help?";
DutchPhrases[Phrases.PLAY] = "Play";
DutchPhrases[Phrases.MAKE_RESIGN] = "Make USERNAME resign?";
DutchPhrases[Phrases.UNDO_REQUEST_SINGULAR] = "Request to undo 1 step";
DutchPhrases[Phrases.UNDO_REQUEST_PLURAL] = "Request to undo NUMBER steps";
DutchPhrases[Phrases.UNDO_REQUEST_MANY] = "Request to undo NUMBER steps";
DutchPhrases[Phrases.PENDING_UNDO_REQUEST] = "Pending undo request...";
DutchPhrases[Phrases.GRANT] = "Grant";
DutchPhrases[Phrases.ACCEPT] = "Accept";
DutchPhrases[Phrases.DECLINE] = "Decline";
DutchPhrases[Phrases.DENY] = "Deny";
DutchPhrases[Phrases.UNDO_DENIED] = "Undo request was denied.";
DutchPhrases[Phrases.UNDO_CANCELLED] = "Undo request was cancelled.";
DutchPhrases[Phrases.TOC_SENTENCE] = "I have read and accept the TERMS_OF_SERVICE.";
DutchPhrases[Phrases.TERMS_OF_SERVICE] = "Terms of Service";
DutchPhrases[Phrases.TERMS_OF_SERVICE_URL] = ""; /* edit it after writing translated terms of service */
DutchPhrases[Phrases.RECONNECTING] = "Reconnecting...";
DutchPhrases[Phrases.PLAYER_JOINED_TABLE] = "PLAYER_NAME joined the table.";
DutchPhrases[Phrases.PLAYER_LEFT_TABLE] = "PLAYER_NAME left the table.";
DutchPhrases[Phrases.JOINING_GAME_MESSAGE] = "Joining game #NUMBER on GAMESERVER_NAME.";
DutchPhrases[Phrases.SIGNING_UP] = "Signing you up...";
DutchPhrases[Phrases.ACTION] = "Action";
DutchPhrases[Phrases.ACTIONS] = "Actions";
DutchPhrases[Phrases.BUY] = "Buy";
DutchPhrases[Phrases.BUYS] = "Buys";
DutchPhrases[Phrases.KICK_SUCCEEDED] = "Kick Succeeded";
DutchPhrases[Phrases.CONNECTION_CLOSED] = "Server connection was closed or could not be established.";
DutchPhrases[Phrases.NEW_PASSWORD] = "New Password";
DutchPhrases[Phrases.RETRY] = "Retry";
DutchPhrases[Phrases.FRIEND] = "Friend";
DutchPhrases[Phrases.ACTIVITY] = "Activity";
DutchPhrases[Phrases.STATUS] = "Status";
DutchPhrases[Phrases.PURCHASE] = "Purchase";
DutchPhrases[Phrases.UNTIL] = "until";
DutchPhrases[Phrases.BUY_FOR_AMOUNT] = "Buy for AMOUNT";
DutchPhrases[Phrases.CHANGE_PASSWORD] = "Change";
DutchPhrases[Phrases.HIDE] = "Hide";
DutchPhrases[Phrases.CONFIRM] = "Confirm";
DutchPhrases[Phrases.JOIN] = "join";
DutchPhrases[Phrases.TURN] = "Turn";
DutchPhrases[Phrases.CARD] = "Card";
DutchPhrases[Phrases.DESCRIPTION] = "Description";
DutchPhrases[Phrases.SCORE] = "Score";
DutchPhrases[Phrases.TURNS] = "Turns";
DutchPhrases[Phrases.NOTHING] = "nothing";
DutchPhrases[Phrases.SPECTATE] = "Spectate";
DutchPhrases[Phrases.FRIENDS] = "Friends";
DutchPhrases[Phrases.FOLLOWING] = "Following";
DutchPhrases[Phrases.FOLLOWERS] = "Followers";
DutchPhrases[Phrases.UNFRIEND] = "unfriend";
DutchPhrases[Phrases.UNFOLLOW] = "unfollow";
DutchPhrases[Phrases.BEFRIEND] = "befriend";
DutchPhrases[Phrases.FRIENDS_EXPLANATION] = "Mutual friend";
DutchPhrases[Phrases.FOLLOWING_EXPLANATION] = "You befriended them";
DutchPhrases[Phrases.FOLLOWERS_EXPLANATION] = "They befriended you";
DutchPhrases[Phrases.ADD] = "Add";
DutchPhrases[Phrases.TYPE_FRIEND] = "Type friend's name";
DutchPhrases[Phrases.UNLOCKS] = "unlocks";
DutchPhrases[Phrases.SELECT_YOUR_COUNTRY] = "Select your country";
DutchPhrases[Phrases.SELECT_A_COUNTRY] = "Select a country";
DutchPhrases[Phrases.KICK_AND_RESIGN] = "Kick & Resign";
DutchPhrases[Phrases.BETWEEN_TURNS] = "Between Turns";
DutchPhrases[Phrases.PLAYER] = "Player";
DutchPhrases[Phrases.RESIGNED] = "resigned";
DutchPhrases[Phrases.YOU_RESIGNED] = "You Resigned";
DutchPhrases[Phrases.YOU_LOSE] = "You Lose";
DutchPhrases[Phrases.YOU_TIE] = "You Tie";
DutchPhrases[Phrases.YOU_WIN] = "You Win";
DutchPhrases[Phrases.BUYS_MANY] = "Buys";
DutchPhrases[Phrases.ACTIONS_MANY] = "Actions";
DutchPhrases[Phrases.TOKEN] = "token";
DutchPhrases[Phrases.TOKENS] = "tokens";
DutchPhrases[Phrases.TOKENS_MANY] = "tokens";
DutchPhrases[Phrases.OVERPAY] = "Overpay";
DutchPhrases[Phrases.BID] = "Bid";
DutchPhrases[Phrases.TAKE] = "Take";
DutchPhrases[Phrases.BOTTOM] = "Bottom";
DutchPhrases[Phrases.TOP] = "Top";
DutchPhrases[Phrases.LOCATION] = "Location";
DutchPhrases[Phrases.CLEAN_UP] = "Clean-up";
DutchPhrases[Phrases.PLAN_CLEAN_UP] = "Plan Your Clean-up";
DutchPhrases[Phrases.EXECUTE] = "Execute";
DutchPhrases[Phrases.MOVEMENT_VERB] = "VERB.";
DutchPhrases[Phrases.MOVEMENT_VERB_OBJECT] = "VERB OBJECT.";
DutchPhrases[Phrases.FORUM] = "Forum";
DutchPhrases[Phrases.VIEW_LOG] = "Log";
DutchPhrases[Phrases.REPLAY_GAME] = "Replay Game";
DutchPhrases[Phrases.REMOVE_REPLAY] = "Remove Replay";
DutchPhrases[Phrases.SHELTERS] = "Shelters";
DutchPhrases[Phrases.NO_SHELTERS] = "No Shelters";
DutchPhrases[Phrases.COLONIES] = "Colonies";
DutchPhrases[Phrases.NO_COLONIES] = "No Colonies";
DutchPhrases[Phrases.PLAYER_ORDER] = "Player Order";
DutchPhrases[Phrases.ENTER_USERNAME] = "Please enter your username.";
DutchPhrases[Phrases.ENTER_LOGIN_CREDENTIALS] = "Please enter your login credentials.";
DutchPhrases[Phrases.SUBMIT] = "Submit";
DutchPhrases[Phrases.DONE] = "Done";
DutchPhrases[Phrases.GAME_ID] = "Game Id";
DutchPhrases[Phrases.DECISION_INDEX] = "Decision Index";
DutchPhrases[Phrases.ORIGINAL_PLAYER] = "Original Player";
DutchPhrases[Phrases.ORIGINAL_PLAYERS] = "original players";
DutchPhrases[Phrases.ADD_BOT] = "Add Bot";
DutchPhrases[Phrases.DECISION] = "Decision";
DutchPhrases[Phrases.LOAD_GAME] = "Load Game";
DutchPhrases[Phrases.CHANGE_REPLAY] = "Change Replay";
DutchPhrases[Phrases.NAME_CARD] = "name a card";
DutchPhrases[Phrases.LOAD_OLD_GAME] = "Load Old Game";
DutchPhrases[Phrases.BOT] = "Bot";
DutchPhrases[Phrases.CANCEL_RESIGN] = "Cancel & Resign";
DutchPhrases[Phrases.LOGOUT] = "Logout";
DutchPhrases[Phrases.FIRST] = "First";
DutchPhrases[Phrases.LAST] = "Last";
DutchPhrases[Phrases.INBOX] = "Inbox";
DutchPhrases[Phrases.MESSAGES] = "Messages";
DutchPhrases[Phrases.MARK_READ] = "Mark as Read";
DutchPhrases[Phrases.DELETE] = "Delete";
DutchPhrases[Phrases.NO_MESSAGES] = "No Messages";
DutchPhrases[Phrases.LEADERBOARD] = "Leaderboard";
DutchPhrases[Phrases.LAST_UPDATED] = "Last Updated";
DutchPhrases[Phrases.RANK] = "#";
DutchPhrases[Phrases.NAME] = "name";
DutchPhrases[Phrases.RATING] = "level";
DutchPhrases[Phrases.TREND] = "";
DutchPhrases[Phrases.VP] = "VP";
DutchPhrases[Phrases.GAME_ID_SHOULD_BE_NUMBER] = "Game Id should be a number.";
DutchPhrases[Phrases.FAQ] = "FAQ";
DutchPhrases[Phrases.RESULTS] = "Results";
DutchPhrases[Phrases.SKILL] = "μ";
DutchPhrases[Phrases.SKILL_RANGE] = "Skill-range";
DutchPhrases[Phrases.DEVIATION] = "φ";
DutchPhrases[Phrases.VOLATILITY] = "σ";
DutchPhrases[Phrases.GAMES] = "Games";
DutchPhrases[Phrases.GLICKO2] = "Glicko-2";
DutchPhrases[Phrases.IMPLEMENTATION] = "Implementation";
DutchPhrases[Phrases.RATED_QUEUE] = "Users in rated queue";
DutchPhrases[Phrases.UNRATED_QUEUE] = "Users in unrated queue";
DutchPhrases[Phrases.UNRATED_MATCH] = "Unrated Match";
DutchPhrases[Phrases.LEVEL] = "level";
DutchPhrases[Phrases.TWO_PLAYERS] = "2 Players";
DutchPhrases[Phrases.THREE_FOUR_PLAYERS] = "3-4 Players";
DutchPhrases[Phrases.GAME] = "Game";
DutchPhrases[Phrases.RATED] = "rated";
DutchPhrases[Phrases.UNRATED] = "unrated";
DutchPhrases[Phrases.FAMILIAR_CARDS] = "familiar cards";
DutchPhrases[Phrases.OTHER_CARDS] = "others";
DutchPhrases[Phrases.OTHER_CARD] = "other";
DutchPhrases[Phrases.YOUR_TURN] = "Your turn!";
DutchPhrases[Phrases.LOADING_GAME] = "Loading game...";
DutchPhrases[Phrases.BLACKLIST] = "Blacklist";
DutchPhrases[Phrases.REMOVE] = "Remove";
DutchPhrases[Phrases.BLACKLIST_USER] = "Blacklist this user";
DutchPhrases[Phrases.BEFRIEND_USER] = "Befriend this user";
DutchPhrases[Phrases.BLACKLISTED] = "Blacklisted";
DutchPhrases[Phrases.FRIENDLIST_EMPTY] = "Your friend list is currently empty.";
DutchPhrases[Phrases.BLACKLIST_EMPTY] = "Your blacklist is currently empty.";
DutchPhrases[Phrases.PLAYERS_OF_TOTAL_PLAYERS] = "NUM_PLAYERS of NUM_TOTAL_PLAYERS";
DutchPhrases[Phrases.SHOW_RUNNING_GAMES] = "Show running games";
DutchPhrases[Phrases.SHOW_1P_GAMES] = "Show 1 player games";
DutchPhrases[Phrases.SHOW_2P_GAMES] = "Show 2 player games";
DutchPhrases[Phrases.SHOW_3XP_GAMES] = "Show 3+ player games";
DutchPhrases[Phrases.SHOW_BOT_GAMES] = "Show bot games";
DutchPhrases[Phrases.FIND_GAME] = "Automatch";
DutchPhrases[Phrases.FIND_BOT_GAME] = "Bot Game";
DutchPhrases[Phrases.START_SEARCH] = "Start search";
DutchPhrases[Phrases.CANCEL_SEARCH] = "Cancel search";
DutchPhrases[Phrases.SEARCHING_2P] = "Two player";
DutchPhrases[Phrases.SEARCHING_3P] = "Three player";
DutchPhrases[Phrases.SEARCHING_RATED] = "Rated game";
DutchPhrases[Phrases.SEARCHING_PRACTICE] = "Practice game";
DutchPhrases[Phrases.SEARCHING] = "searching";
DutchPhrases[Phrases.START_BOT_GAME_1] = "1 Bot";
DutchPhrases[Phrases.START_BOT_GAME_2] = "2 Bots";
DutchPhrases[Phrases.START_BOT_GAME_3] = "3 Bots";
DutchPhrases[Phrases.NO_FRIENDS_ONLINE] = "No friends online";
DutchPhrases[Phrases.AUTOMATCH_FEEDBACK] = "Automatch queue";
DutchPhrases[Phrases.NOBODY_ELSE_IN_QUEUE] = "Nobody else in the queue.";
DutchPhrases[Phrases.CANCEL_SMALL] = "Cancel";
DutchPhrases[Phrases.MESSAGE_SPECTATORS] = "message spectators";
DutchPhrases[Phrases.MESSAGE_PLAYERS] = "message players";
DutchPhrases[Phrases.PLAYER_REPLACED] = "LEAVING_PLAYER was replaced by REPLACEMENT.";
DutchPhrases[Phrases.RESIGN_PROMPT] = "Resign?";
DutchPhrases[Phrases.RESIGN_MULTIPLE_OPPONENTS] = "Warning: Resigning a game with multiple opponents could be considered bad sportsmanship.";
DutchPhrases[Phrases.CONTINUE_WITH_BOTS] = "Continue with bots";
DutchPhrases[Phrases.PLAYER_DISCONNECTED] = "PLAYER_NAME disconnected.";
DutchPhrases[Phrases.PLAYER_RECONNECTED] = "PLAYER_NAME reconnected.";
DutchPhrases[Phrases.VALIDATE_BONUS_CODE] = "Validate";
DutchPhrases[Phrases.NEW_TO_DOMINION] = "How to Play";
DutchPhrases[Phrases.SEARCHING_PREVIEWS] = "Nocturne Previews";
DutchPhrases[Phrases.AUTOPLAY_CLEANUP] = "Cleanup phase autoplays";
DutchPhrases[Phrases.AUTOPLAY_INTERFACE] = "Default autoplays";
DutchPhrases[Phrases.AUTOPLAY_OTHER] = "Other autoplays";
DutchPhrases[Phrases.GAME_ENDED] = "The game has ended.";
DutchPhrases[Phrases.OK] = "Ok";
DutchPhrases[Phrases.HAS_RESIGNED] = "PLAYER_NAME has resigned.";

// Intro page of Store tab in Lobby
DutchPhrases[Phrases.STORE_WELCOME] = "Dominion Online Store";
DutchPhrases[Phrases.ONE_MONTH] = "1 month";
DutchPhrases[Phrases.TWO_MONTHS] = "2 months";
DutchPhrases[Phrases.THREE_MONTHS] = "3 months";
DutchPhrases[Phrases.SIX_MONTHS] = "6 months";
DutchPhrases[Phrases.NINE_MONTHS] = "9 months";
DutchPhrases[Phrases.FIFTEEN_MONTHS] = "15 months";
DutchPhrases[Phrases.EIGHTEEN_MONTHS] = "18 months";
DutchPhrases[Phrases.ONE_YEAR] = "1 year";
DutchPhrases[Phrases.TWO_YEAR] = "2 year";
DutchPhrases[Phrases.CHOOSE_SUBSCRIPTION] = "Choose your subscription: ";
DutchPhrases[Phrases.CHOOSE_DURATION] = "Choose the duration of your subscription, starting from today.";
DutchPhrases[Phrases.CONTINUE] = "Continue";
DutchPhrases[Phrases.ADD_LATEST_EXPANSION] = "Do you want to add Nocturne to your current subscription?";
DutchPhrases[Phrases.INTRODUCTION_TO_STORE] = "Introduction to store";
DutchPhrases[Phrases.CHECK_YOUR_PURCHASE] = "Check the details of your purchase";
DutchPhrases[Phrases.SELLER_INFO] = "Seller info";
DutchPhrases[Phrases.INVOICE_NEED_ANSWERS] = "To view the invoice you need valid answers to all questions above.";
DutchPhrases[Phrases.BUYING_SUBSCRIPTION_TO] = "Buying subscription to";
DutchPhrases[Phrases.BUYING_SUBSCRIPTION_TILL] = "Buying subscription till";
DutchPhrases[Phrases.AMOUNT] = "Amount";
DutchPhrases[Phrases.ITEM] = "Item";
DutchPhrases[Phrases.YOUR_SUBSCRIPTION] = "Your Subscription ";
DutchPhrases[Phrases.YOUR_ACCOUNT] = "Your Account ";
DutchPhrases[Phrases.REDIRECTED_TO_PAYMENT_PROVIDER] = "Redirecting to payment provider in new browser tab...";
DutchPhrases[Phrases.PAYMENT_REDIRECTION_FAILED] = "If you where not redirected, make sure you browser allows opening new tabs.";
DutchPhrases[Phrases.REDIRECT_FAILED_BUTTON] = "Click when redirection failed";
DutchPhrases[Phrases.REDIRECT_YOURSELF] = "If you are not redirected automatically, click";
DutchPhrases[Phrases.THIS_LINK] = "this link";
DutchPhrases[Phrases.ZERO_INVOICE_1] = "Your selected purchase does not add anything to your current subscription.";
DutchPhrases[Phrases.ZERO_INVOICE_2] = "Please select another subscription or a longer duration.";
DutchPhrases[Phrases.SORRY_STORE_SERVICE_DOWN] = "The store service is currently down. Sorry for the inconvenience.";
DutchPhrases[Phrases.CONNECTING_TO_STORE_SERVICE] = "Connecting...";
DutchPhrases[Phrases.UPGRADE_DURATION] = "Till current subscription expires";
DutchPhrases[Phrases.FOR_FREE] = "Free";
DutchPhrases[Phrases.MAXED_OUT_SUBSCRIPTIONS] = "You subscription has the maximum duration and contains all current expansions";
DutchPhrases[Phrases.IP_COUNTRY_MISMATCH] = "The country you selected does not match your IP address";
DutchPhrases[Phrases.EXPIRES_AT] = "subscription ends at";
DutchPhrases[Phrases.TOOLTIP_RANDOM_SELECTION] = "Click to add a random card from ARGUMENT0 to your kingdom.";

//DutchPhrases[Phrases.CARDS_BANNED_BY_PLAYERS]        = "Cards banned by players: ";
DutchPhrases[Phrases.AUTOCOMPLETE_CARD_LISTS] = "Enter cards to add";
DutchPhrases[Phrases.RESPECT_FAMILIAR_CARDS] = "Respect Familiar cards";
DutchPhrases[Phrases.RESPECTED_CARD_LISTS] = "Respected card lists";
DutchPhrases[Phrases.BANNED_CARDS] = "banned cards";
DutchPhrases[Phrases.LIKED_CARDS] = "liked cards";
DutchPhrases[Phrases.DISLIKED_CARDS] = "disliked cards";
DutchPhrases[Phrases.KINGDOM_GENERATOR_PERCENTAGES] = "Kingdom generated with these relative percentages:";