"use strict";
// Intro page of Store tab in Lobby

RussianParagraphs.STORE_INTRO = new Paragraph(["Игра с базовыми картами бесплатна.", "Для игры с дополнениями требуется подписка.", "Одного подписчика в игре достаточно."]);

RussianParagraphs.STORE_BUYING = new Paragraph(["Подписка не обновляется автоматически.", "Вы можете совершить покупку для продолжения или улучшения подписки.", "Цена снижена за счет уже купленных обновлений.", "Вы получаете скидку покупая на более долгий период."]);

RussianParagraphs.STORE_SUBSCRIPTIONS = new Paragraph(["Серебряная подписка &mdash; половина дополнений &mdash; € 2.15 в месяц.", "Золотая подписка &mdash; все существующие дополнения &mdash; € 4.30 в месяц.", "Золотая подписка не включает в себя будущие дополнения."]);

RussianParagraphs.STORE_DOLLARS = new Paragraph(["Цены представлены в евро.", "Покупатели из США увидят точную цену в долларах перед транзакцией."]);

RussianParagraphs.STORE_UPGRADE = new Paragraph(["Ваша подписка содержит все дополнения кроме последнего.", "Выберите 'Да' и дополнение добавится к вашей подписке.", "Дата истечения подписки - это дата самого раннего истечения одного из обновлений..", "Выберите 'Нет', чтобы посмотреть остальные покупки.."]);