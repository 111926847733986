"use strict";

var TChineseUserPreferences = {};

TChineseUserPreferences[UserPrefIds.EMAIL] = "信箱";
TChineseUserPreferences[UserPrefIds.CARD_OWNERSHIP] = "擁有的卡片";
TChineseUserPreferences[UserPrefIds.LIKED_CARDS] = "挑選你想要多玩一點的卡片";
TChineseUserPreferences[UserPrefIds.DISLIKED_CARDS] = "你想要少玩一點的卡片";
TChineseUserPreferences[UserPrefIds.BANNED_CARDS] = "挑選禁卡！";
TChineseUserPreferences[UserPrefIds.MOVE_KINGDOM] = "遊戲中你的出牌區與對手出牌區分開";
TChineseUserPreferences[UserPrefIds.CONFIRM_ACTIONS] = "移除或是棄掉單張卡片多做確認";
TChineseUserPreferences[UserPrefIds.PREFERRED_BACKGROUND] = "選用幾號背景";
TChineseUserPreferences[UserPrefIds.USE_AUTOBUY] = "使用自動購買";
TChineseUserPreferences[UserPrefIds.FOLLOWERS_SEE_ACTIVITY] = "把你的動態(遊戲中或上線中)給追隨者知道";
TChineseUserPreferences[UserPrefIds.NOTIFICATION_SOUNDS] = "輪到你的時候嘟一聲";
TChineseUserPreferences[UserPrefIds.SOUND_FX] = "播放獲勝和敗北音效";
TChineseUserPreferences[UserPrefIds.SPECTATORS_SEE_HAND] = "讓觀眾看你的手牌";
TChineseUserPreferences[UserPrefIds.AUTOPLAY_SETTINGS] = "輔助功能支援的卡片：";
TChineseUserPreferences[UserPrefIds.LOG_FIXED_BASIC_BONUS] = "文字紀錄會顯示+N行動+N購買+N預算";
TChineseUserPreferences[UserPrefIds.TRANSPARENT_WAY_BUTTON] = "行動卡上將不會出現[法則]的選項";
TChineseUserPreferences[UserPrefIds.CHAT_VISIBLE] = "可以看到玩家與觀眾的對話";
TChineseUserPreferences[UserPrefIds.SPECTATOR_CHAT_IN_AUTOMATCH] = "在積分配對的遊戲中，自動隱藏觀眾的對話";
TChineseUserPreferences[UserPrefIds.CARD_POOL_LEVEL] = "你可以接受的等級範圍";
TChineseUserPreferences[UserPrefIds.ANIMATION_TYPE] = "動畫選項";
TChineseUserPreferences[UserPrefIds.PREMOVES] = "輪到你動作之前你可以預先點好卡片";

TChineseUserPreferences.USERNAME = "使用者名稱";
TChineseUserPreferences.PASSWORD = "密碼";
TChineseUserPreferences.OLD_PASSWORD = "舊密碼";
TChineseUserPreferences.NEW_PASSWORD = "新密碼";
TChineseUserPreferences.REPEAT_PASSWORD = "確認新密碼";
TChineseUserPreferences.OWNERSHIP_INTRO = "你現在的訂閱；";
TChineseUserPreferences.OWNERSHIP_INTRO_EMPTY = "您現在並沒有訂閱";
TChineseUserPreferences.FAMILIAR_WITH = "(彩色表示會玩，黑白表示不會)";
TChineseUserPreferences.USE_BONUS_CODE = "使用特別代碼";
TChineseUserPreferences.BONUS_CODE_ORIGIN = "你的特別代碼是從： ";
TChineseUserPreferences.BONUS_CODE_CHOOSE_EXPANSION = "選擇一個擴充";

var TChineseProfileButtons = {};

TChineseProfileButtons[true] = "接受";
TChineseProfileButtons[false] = "拒絕";
TChineseProfileButtons[UserPrefIds.FAMILIAR_CARDS] = {
    ALL: "這擴充我會玩!",
    NONE: "這個擴充不會",
    ALL_EXPANSIONS: "我整套DOMI都會玩"
};

var TChineseStoreLabels = {};

TChineseStoreLabels[StorePackages.HALF] = "白銀訂閱";
TChineseStoreLabels[StorePackages.ALL] = "黃金訂閱";

TChineseStoreLabels[PriceCategories.PURCHASED] = ''; // "購買的擴充：";
TChineseStoreLabels[PriceCategories.ALREADY_OWNED] = "已經訂閱的：";
TChineseStoreLabels[PriceCategories.DISCOUNT] = "訂閱長期的折扣";
TChineseStoreLabels[PriceCategories.TRANSACTION] = "匯款費用";
TChineseStoreLabels[PriceCategories.SUBTOTAL] = "總共";
TChineseStoreLabels[PriceCategories.VAT] = "增值稅";
TChineseStoreLabels[PriceCategories.TOTAL] = "總共";

TChineseStoreLabels.SELLER_INFO = "店家資訊";
TChineseStoreLabels.PRODUCT_CONFIRM = "Purchasing until DATE: EXPANSIONS";
TChineseStoreLabels.PAYMENT_HANDLER = "帳單會交由荷蘭的支付服務商Mollie處理。";
TChineseStoreLabels.PROCEED_TO_PAYMENT = "執行付款動作";

var TChineseAutoplayLabels = {};

TChineseAutoplayLabels.REGULAR_OPTIONS = "基本選項";
TChineseAutoplayLabels.AUTOPLAY_OPTIONS = "輔助功能的選項";
TChineseAutoplayLabels.AUTOPLAY_DOCUMENTATION = "輔助功能的手冊";
TChineseAutoplayLabels.CUSTOMIZE_OPTIONS = "用戶自訂";
TChineseAutoplayLabels.ALL_OFF = "全部關閉";
TChineseAutoplayLabels.ALL_DEFAULT = "全部預設";
TChineseAutoplayLabels.ALL_CONVENIENT = "全部方便就好";
TChineseAutoplayLabels.AUTOPLAY_DOCUMENTATION_GENERIC = "甚麼輔助功能?能吃嗎?";
TChineseAutoplayLabels.AUTOPLAY_DOCUMENTATION_SPECIFIC = "的輔助功能設定和說明";

var TChineseAnimationTypes = {};
TChineseAnimationTypes[AnimationOptions.NEW_PLAYER] = "緩慢";
TChineseAnimationTypes[AnimationOptions.BEGINNER] = "微慢";
TChineseAnimationTypes[AnimationOptions.MEDIUM] = "普通";
TChineseAnimationTypes[AnimationOptions.ADVANCED] = "快速";
TChineseAnimationTypes[AnimationOptions.VERYFAST] = "非常快";
TChineseAnimationTypes[AnimationOptions.INSTANT] = "跳過動畫";

// Missing lines as of 09.01.2023 20:41:43
TChineseStoreLabels[StorePackages.CORE] = "基礎訂閱";
TChineseStoreLabels[StorePackages.COMPLETE] = "完整訂閱";