"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var WindowedZone = function (_Zone) {
    _inherits(WindowedZone, _Zone);

    function WindowedZone(state, index, pileName, owner, createdBy, attachedTrait) {
        _classCallCheck(this, WindowedZone);

        var _this = _possibleConstructorReturn(this, (WindowedZone.__proto__ || Object.getPrototypeOf(WindowedZone)).call(this, state, index, pileName, owner, createdBy, attachedTrait));

        var vh = 0.01 * window.innerHeight;
        var vw = 0.01 * window.innerWidth;
        _this.bbox = new Rectangle(25 * vw, 25 * vh, 50 * vw, 50 * vh);
        _this.customBbox = false;
        _this.showCauses = [];
        _this.leftText = "";
        _this.rightText = "";
        _this.windowView = new WindowView(_this);
        _this.lastFocus = null;
        return _this;
    }

    _createClass(WindowedZone, [{
        key: "unload",
        value: function unload() {
            this.windowView.unload();
            _get(WindowedZone.prototype.__proto__ || Object.getPrototypeOf(WindowedZone.prototype), "unload", this).call(this);
        }
    }, {
        key: "repositionWindow",
        value: function repositionWindow(x, y, width, height) {
            this.bbox.x = x;
            this.bbox.y = y;
            this.bbox.width = width;
            this.bbox.height = height;
            this.reposition();
        }
    }, {
        key: "reposition",
        value: function reposition() {
            var _this2 = this;

            var shouldAnimate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            var vh = window.innerHeight / 100;
            var vw = window.innerWidth / 100;
            if (this.shouldSort) this.cardStacks.sort(function (a, b) {
                return stackCardNameSorter(a.topCard.cardName, b.topCard.cardName);
            });
            var mainStacks = this.cardStacks.filter(function (s) {
                return !s.hasFilter("ignored-order") && !s.hasFilter("not-windowed");
            });
            if (mainStacks.length > 0) {
                var left = this.bbox.x + this.bbox.width * 0.05;
                var right = this.bbox.x + this.bbox.width * 0.95;
                var maxMargin = WINDOW_HEIGHT * vh * this.maxMargin;

                var weights = [];
                var widths = [];
                mainStacks.forEach(function (cardStack, index) {
                    if (mainStacks.length > 5 && cardStack.isHovered) {
                        var factor = cardStack.topCard.cardName.isLandscape() ? 2.4 : 1.2;
                        weights.push(0);
                        widths.push(Math.min(WINDOW_HEIGHT * vh, 0.6 * _this2.bbox.height) / CardSizes.FULL.ratio * factor + 2 * maxMargin);
                    } else {
                        var _factor = cardStack.topCard.cardName.isLandscape() ? 2 : 1;
                        weights.push(index === mainStacks.length - 1 ? 0 : _factor);
                        widths.push(Math.min(WINDOW_HEIGHT * vh, 0.6 * _this2.bbox.height) / CardSizes.FULL.ratio * _factor);
                    }
                });
                var totalWidth = sum(widths);
                var excess = right - left - totalWidth;
                var renderedWidth = totalWidth + Math.min(excess, sum(weights) * maxMargin);
                var x = (left + right - renderedWidth) / 2;

                mainStacks.forEach(function (cardStack, index) {
                    var width = widths[index];
                    var height = cardStack.ratio * width;
                    var y = _this2.bbox.y + (_this2.bbox.height - height) / 2 + _this2.titleBarSize * height;
                    var margin = Math.min(maxMargin, weights[index] * excess / sum(weights));
                    var zIndex = _this2.bbox.zIndex + 5 + index;
                    if (cardStack.hasFilter("free-grab")) {
                        cardStack.view.bbox.zIndex = _this2.bbox.zIndex + 5 + index;
                    } else {
                        if (mainStacks.length > 5 && cardStack.isHovered) {
                            width -= 2 * maxMargin;
                            height = cardStack.ratio * width;
                            x += 2 * index * maxMargin / mainStacks.length;
                        }

                        if (shouldAnimate) cardStack.animator = new TransitionAnimator(cardStack, absoluteTransitionScript(new Rectangle(x, y, width, height, zIndex)), cardStack, function () {});else cardStack.reposition(x, y, width, height, zIndex);
                    }
                    if (_this2.visible) cardStack.removeFilter("zone-hidden");else cardStack.addFilter("zone-hidden");

                    if (mainStacks.length > 5 && cardStack.isHovered) x += width + 2 * (mainStacks.length - index - 1) * maxMargin / mainStacks.length;else x += width + margin;
                });
            }

            var ignoredStacks = this.cardStacks.filter(function (s) {
                return s.hasFilter("ignored-order");
            });
            ignoredStacks.forEach(function (cardStack, index) {
                var height = 5 * vh;
                var margin = -1 * vh;
                var width = height / CardSizes.FULL.ratio;
                var zIndex = _this2.bbox.zIndex + 5 + index;
                var left = _this2.bbox.x + 0.5 * vh;
                var right = _this2.bbox.x + _this2.bbox.width * 0.95;
                var top = _this2.bbox.y + 0.5 * vh;

                cardStack.linearlyPosition(ignoredStacks.length, index, height, margin, left, right, top, zIndex, false, shouldAnimate);
                if (_this2.visible) cardStack.removeFilter("zone-hidden");else cardStack.addFilter("zone-hidden");
            });

            this.windowView.redraw();
        }
    }, {
        key: "show",
        value: function show(cause) {
            var _this3 = this;

            if (!this.customBbox) {
                this.bbox.zIndex = 13100 + 40 * this.index;
                if (this.showCauses.indexOf(cause) === -1) this.showCauses.push(cause);
                if (cause instanceof Question || cause instanceof Zone) {
                    var vh = 0.01 * window.innerHeight;
                    var vw = 0.01 * window.innerWidth;
                    var maxWidth = (100 - RIGHT_COLUMN_WIDTH - LEFT_COLUMN_WIDTH - 2) * 0.8 * vw;
                    var centralSupplyZoneNames = [SupplyCardZone, SingleLandscapeZone];
                    var hasActiveSupplyAction = this.state.zones.some(function (z) {
                        return z !== null && centralSupplyZoneNames.some(function (zoneName) {
                            return z instanceof zoneName;
                        }) && z.cardStacks.some(function (s) {
                            return s.getClickableCard() !== null;
                        });
                    });

                    var cardStackWidth = sum(this.cardStacks.map(function (s) {
                        return s.topCard.cardName.isLandscape() ? 3 : 1;
                    }));
                    var width = Math.min(maxWidth, WINDOW_HEIGHT / CardSizes.FULL.ratio * (2 + cardStackWidth * 0.8) * vh);
                    var x = ((LEFT_COLUMN_WIDTH + (100 - RIGHT_COLUMN_WIDTH)) * vw - width) / 2;
                    var height = 32 * vh;
                    var possibleYs = [];
                    if (hasActiveSupplyAction) {
                        possibleYs.push(99 * vh - height);
                        possibleYs.push(MAIN_SUPPLY_HEIGHT / 2 * vh);
                        possibleYs.push(MAIN_SUPPLY_HEIGHT / 2 * vh + height);
                    } else {
                        possibleYs.push(MAIN_SUPPLY_HEIGHT / 2 * vh);
                        possibleYs.push(99 * vh - height);
                        possibleYs.push(MAIN_SUPPLY_HEIGHT / 2 * vh + height);
                    }
                    var existingOverlaps = this.state.zones.filter(function (z) {
                        return z !== _this3 && z instanceof WindowedZone && z.visible && z.bbox.x === x && z.bbox.width === width;
                    });
                    var y = possibleYs.find(function (y) {
                        return !existingOverlaps.some(function (z) {
                            return z.bbox.y === y;
                        });
                    });
                    if (isUndefined(y)) y = existingOverlaps.length * height;

                    this.repositionWindow(x, y, width, height);
                } else if (cause instanceof GameTab) {
                    if (!cause.zoneLayout.has(this)) cause.computeZoneLayout();

                    var _cause$zoneLayout$get = cause.zoneLayout.get(this),
                        _x2 = _cause$zoneLayout$get.x,
                        _y = _cause$zoneLayout$get.y,
                        _width = _cause$zoneLayout$get.width,
                        _height = _cause$zoneLayout$get.height;

                    this.repositionWindow(_x2, _y, _width, _height);
                }
            } else {
                if (this.showCauses.indexOf(cause) === -1) this.showCauses.push(cause);
                this.reposition();
            }
        }
    }, {
        key: "hide",
        value: function hide(cause) {
            this.showCauses.splice(this.showCauses.indexOf(cause), 1);
            this.reposition();
        }
    }, {
        key: "hideAll",
        value: function hideAll() {
            this.showCauses = [];
            this.reposition();
        }
    }, {
        key: "hideAllQuestions",
        value: function hideAllQuestions() {
            this.showCauses = this.showCauses.filter(function (c) {
                return !(c instanceof Question);
            });
            this.reposition();
        }
    }, {
        key: "initialize",
        value: function initialize(cardIds) {
            var _this4 = this;

            cardIds.forEach(function (id) {
                if (id === -1) {
                    var targetStack = _this4.cardStacks.find(function (s) {
                        return s.topCard.cardName === CardNames.BACK;
                    });
                    if (isUndefined(targetStack) || !_this4.shouldStack) {
                        targetStack = new CardStack(_this4.state.getAnonCard(CardNames.BACK), _this4);
                    }
                    targetStack.addAnonymousCard();
                } else {
                    var targetCard = _this4.state.cards[id];
                    var targetName = targetCard.cardName;
                    var _targetStack = _this4.cardStacks.find(function (s) {
                        return s.topCard.isCompatibleWith(targetCard);
                    });
                    if (isUndefined(_targetStack) || !_this4.shouldStack) {
                        _targetStack = new CardStack(_this4.state.getAnonCard(targetName), _this4);
                    }
                    _targetStack.addCard(targetCard);
                }
            });
            this.reposition();
        }
    }, {
        key: "addCard",
        value: function addCard(card) {
            var targetStack = this.cardStacks.find(function (s) {
                return s.topCard.isCompatibleWith(card);
            });
            if (isUndefined(targetStack) || !this.shouldStack) {
                targetStack = new CardStack(this.state.getAnonCard(card.cardName), this);
                targetStack.addFilter("intangible");
            }
            this.reposition();
            return targetStack;
        }
    }, {
        key: "addAnonymousCard",
        value: function addAnonymousCard() {
            var targetStack = this.cardStacks.find(function (s) {
                return s.topCard.cardName === CardNames.BACK;
            });
            if (isUndefined(targetStack) || !this.shouldStack) {
                targetStack = new CardStack(this.state.getAnonCard(CardNames.BACK), this);
                targetStack.addFilter("intangible");
            }
            this.reposition();
            return targetStack;
        }
    }, {
        key: "collateCards",
        value: function collateCards() {
            var _this5 = this;

            var newStacks = [];
            var script = simpleTrackedTransitionScript();
            var oldStacks = [].concat(_toConsumableArray(this.cardStacks));
            oldStacks.forEach(function (s) {
                var targetStack = newStacks.find(function (targetStack) {
                    return targetStack.topCard.cardName === s.topCard.cardName;
                });
                if (isUndefined(targetStack)) {
                    targetStack = new CardStack(_this5.state.getAnonCard(s.topCard.cardName), _this5);
                    targetStack.addFilter("intangible");
                    newStacks.push(targetStack);
                }
                _this5.mergeStacks(targetStack, s);
            });
            this.reposition();
        }
    }, {
        key: "getScript",
        value: function getScript(fromZone, stack, targetStack, props) {
            if (props.isInstant) return [];

            var width = targetStack.view.bbox.width;
            var height = targetStack.view.bbox.height;
            if (this.visible) {
                var unitDelay = 0.3;
                var factor = Math.max(1, unitDelay * this.cardStacks.length);
                var startLag = this.cardStacks.indexOf(targetStack) * props.delay * 0.3 / factor;
                if (props.type === ClientAnimationTypes.MINIMAL || stack.topCard.cardName === CardNames.BACK) {
                    return [new Keyframe(0, new StackTarget(new Rectangle(-0.5 * width, 0, 0, 0, 10, 0, -1, 0)), Curves.LINEAR), new Keyframe(props.speed * 0.6, new StackTarget(new Rectangle(0, 0, 0, 0, 10, 0, 0, 0)), Curves.SMOOTH), new Keyframe(props.delay, new StackTarget(new Rectangle(0, 0, 0, 0, 10, 0, 0, 0)), Curves.LINEAR)];
                }
                if (props.type === ClientAnimationTypes.HIGHLIGHTED) {
                    return [new Keyframe(0, new StackTarget(new Rectangle(0, 0, 0, 0, 10, 0, 0, 0)), Curves.LINEAR, ["appears-anonymous"]), new Keyframe(startLag, new StackTarget(new Rectangle(0, 0, 0, 0, 10, 0, 0, 0)), Curves.LINEAR, ["appears-anonymous"]), new Keyframe(props.speed * 0.6, new StackTarget(new Rectangle(-0.5 * width, 0, 0, 0, 10, -10, 0, 90)), Curves.SMOOTH), new Keyframe(props.speed * 1.2, new StackTarget(new Rectangle(0, 0, 0, 0, 10, 0, 0, 0)), Curves.SMOOTH, [], ["appears-anonymous"]), new Keyframe(props.delay, new StackTarget(new Rectangle(0, 0, 0, 0, 10, 0, 0, 0)), Curves.LINEAR)];
                }
                return [new Keyframe(0, new StackTarget(new Rectangle(-0.5 * width, 0, 0, 0, 10, -10, 0, 90)), Curves.SMOOTH), new Keyframe(props.speed * 1, new StackTarget(new Rectangle(0, 0, 0, 0, 10, 0, 0, 0)), Curves.SMOOTH, [], ["appears-anonymous"])];
            } else {
                if (fromZone instanceof WindowedZone && !fromZone.visible) return [];else return [new Keyframe(props.speed, new RelativeTarget(new Rectangle(0, -0.6 * height, 0, 0, 10, 0, -1)), Curves.SMOOTH)];
            }
        }
    }, {
        key: "onFocusChange",
        value: function onFocusChange(stack, value) {
            if (this.lastFocus === stack !== value) {
                if (value) this.lastFocus = stack;else this.lastFocus = null;
                this.reposition(true);
            }
        }
    }, {
        key: "visible",
        get: function get() {
            return this.showCauses.length > 0;
        }
    }, {
        key: "showWindow",
        get: function get() {
            return this.visible && this.cardStacks.length > 0;
        }
    }, {
        key: "shouldSort",
        get: function get() {
            return true;
        }
    }, {
        key: "titleBarSize",
        get: function get() {
            return 0.15;
        }
    }, {
        key: "maxMargin",
        get: function get() {
            return 0.05;
        }
    }, {
        key: "shouldStack",
        get: function get() {
            return this.state.game.questionModel.openQuestions.some(function (q) {
                return q.shouldStackCards;
            });
        }
    }]);

    return WindowedZone;
}(Zone);

var HiddenWindowZone = function (_WindowedZone) {
    _inherits(HiddenWindowZone, _WindowedZone);

    function HiddenWindowZone() {
        _classCallCheck(this, HiddenWindowZone);

        return _possibleConstructorReturn(this, (HiddenWindowZone.__proto__ || Object.getPrototypeOf(HiddenWindowZone)).apply(this, arguments));
    }

    _createClass(HiddenWindowZone, [{
        key: "show",
        value: function show() {}
    }, {
        key: "hide",
        value: function hide() {}
    }, {
        key: "visible",
        get: function get() {
            return false;
        }
    }]);

    return HiddenWindowZone;
}(WindowedZone);

var TheBox = function (_HiddenWindowZone) {
    _inherits(TheBox, _HiddenWindowZone);

    function TheBox() {
        _classCallCheck(this, TheBox);

        return _possibleConstructorReturn(this, (TheBox.__proto__ || Object.getPrototypeOf(TheBox)).apply(this, arguments));
    }

    _createClass(TheBox, [{
        key: "zoneName",
        get: function get() {
            return "TheBox";
        }
    }]);

    return TheBox;
}(HiddenWindowZone);