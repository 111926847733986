"use strict";

webclient.component("pendingUndoRequest", {
    transclude: true,
    controller: ['game', '$scope', function (game, $scope) {
        var self = this;

        $scope.$on(Events.REDRAW, function () {
            safeDigest($scope);
        });
        $scope.$on(Events.GAME_MODEL_CREATED, function () {
            safeDigest($scope);
        });

        self.shouldShow = function () {
            return game.metaGameModel.undoRequest !== null && !isUndefined(game.playerModel.playerMe) && game.metaGameModel.undoRequest.playerIndex === game.playerModel.playerMe.index;
        };
        self.cancel = function () {
            return game.metaGameModel.cancelUndoRequest();
        };

        self.getWindowText = function () {
            return getPhrase(Phrases.PENDING_UNDO_REQUEST);
        };

        self.getCancelText = function () {
            return getPhrase(Phrases.CANCEL);
        };
    }],
    template: "\n            <modal-window ng-if=\"$ctrl.shouldShow()\">\n                <div class=\"timeout\">{{::$ctrl.getWindowText()}}</div>\n                <button\n                    class=\"lobby-button\"\n                    ng-click=\"$ctrl.cancel()\"\n                    >\n                    {{::$ctrl.getCancelText()}}\n                </button>\n            </modal-window>\n        "
});