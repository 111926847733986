"use strict";

var TChineseAutoPlays = {};

TChineseAutoPlays[AutoPlays.MOAT] = ["關閉", "不詢問就自動展示"];
TChineseAutoPlays[AutoPlays.CROWN] = ["關閉", "自動結束行動階段"];
TChineseAutoPlays[AutoPlays.CHANGELING] = ["關閉", "有條件的詢問", "全部都不想要換"];
TChineseAutoPlays[AutoPlays.URCHIN] = ["關閉", "都不要移除貧童"];
TChineseAutoPlays[AutoPlays.MOUNTEBANK] = ["關閉", "如果你手上有詛咒自動棄掉"];
TChineseAutoPlays[AutoPlays.SETTLERS] = ["關閉", "有銅幣可拿就自動拿"];
TChineseAutoPlays[AutoPlays.BUSTLING_VILLAGE] = ["關閉", "有拓荒者可拿就自動拿"];
TChineseAutoPlays[AutoPlays.TOURNAMENT] = ["關閉", "手上有行省就自動展示"];
TChineseAutoPlays[AutoPlays.PEASANT] = ["關閉", "不詢問就直接交換", "不詢問就直接保留"];
TChineseAutoPlays[AutoPlays.SOLDIER] = ["關閉", "不詢問就直接交換", "不詢問就直接保留"];
TChineseAutoPlays[AutoPlays.FUGITIVE] = ["關閉", "不詢問就直接交換", "不詢問就直接保留"];
TChineseAutoPlays[AutoPlays.DISCIPLE] = ["關閉", "不詢問就直接交換", "不詢問就直接保留"];
TChineseAutoPlays[AutoPlays.PAGE] = ["關閉", "不詢問就直接交換", "不詢問就直接保留"];
TChineseAutoPlays[AutoPlays.TREASURE_HUNTER] = ["關閉", "不詢問就直接交換", "不詢問就直接保留"];
TChineseAutoPlays[AutoPlays.WARRIOR] = ["關閉", "不詢問就直接交換", "不詢問就直接保留"];
TChineseAutoPlays[AutoPlays.HERO] = ["關閉", "不詢問就直接交換", "不詢問就直接保留"];
TChineseAutoPlays[AutoPlays.FAITHFUL_HOUND] = ["關閉", "可以放置一邊不詢問就直接放"];
TChineseAutoPlays[AutoPlays.YOUNG_WITCH] = ["關閉", "手上有毒藥卡就自動展示"];
TChineseAutoPlays[AutoPlays.TREASURY] = ["關閉", "可以放回牌庫頂就自動放回牌庫頂"];
TChineseAutoPlays[AutoPlays.ALCHEMIST] = ["關閉", "可以放回牌庫頂就自動放回牌庫頂"];
TChineseAutoPlays[AutoPlays.WALLED_VILLAGE] = ["關閉", "可以放回牌庫頂就自動放回牌庫頂"];
TChineseAutoPlays[AutoPlays.HERBALIST] = ["關閉", "任意順序"];
TChineseAutoPlays[AutoPlays.ROYAL_SEAL] = ["關閉", "獲得的卡片自動放回牌庫頂", "獲得的卡片不要放回牌庫頂"];
TChineseAutoPlays[AutoPlays.COIN_OF_THE_REALM] = ["關閉", "聰明詢問"];
TChineseAutoPlays[AutoPlays.BORDER_GUARD] = ["關閉", "可以放回牌庫頂就自動放回牌庫頂"];

TChineseAutoPlays[AutoPlays.TRADER] = ["關閉", "獲得銀幣的時候自動略過"];
TChineseAutoPlays[AutoPlays.PROVINCE] = ["關閉", "獲得此卡時不會問[你要不要棄掉驅逐堆上的此卡]"];
TChineseAutoPlays[AutoPlays.CHAMPION] = ["關閉", "自動判斷法則"];
TChineseAutoPlays[AutoPlays.WAY_OF_THE_BUTTERFLY] = ["關閉", "簡易判定要不要歸還"];
TChineseAutoPlays[AutoPlays.DESPERATION] = ["關閉", "自動獲得詛咒"];
TChineseAutoPlays[AutoPlays.TORTURER] = ["關閉", "當沒有詛咒，就自動不棄卡"];
TChineseAutoPlays[AutoPlays.ARENA] = ["關閉", "當沒有分數，就自動不棄行動卡"];
TChineseAutoPlays[AutoPlays.PAGEANT] = ["關閉", "自動換成存款"];
TChineseAutoPlays[AutoPlays.OLD_WITCH] = ["關閉", "自動移除詛咒"];
TChineseAutoPlays[AutoPlays.SINISTER_PLOT] = ["關閉", "零個指示物就自動不抽卡"];
TChineseAutoPlays[AutoPlays.MONEYLENDER] = ["關閉", "自動移除銅幣"];
TChineseAutoPlays[AutoPlays.ENCAMPMENT] = ["關閉", "如果有卡就自動展示"];
TChineseAutoPlays[AutoPlays.DUPLICATE] = ["關閉", "只詢問能獲得的卡片", "也不詢問詛咒"];