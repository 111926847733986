"use strict";

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var activeMeta = void 0;

webclient.service('meta', ['$rootScope', 'metaBroadcaster', function ($rootScope, metaBroadcaster) {
    var self = this;
    var scope = $rootScope.$new(true);
    activeMeta = self;
    self.state = new MetaState();
    self.model = new MetaModel();
    self.playerNames = [];

    function extractPlayerNames(details) {
        for (var i = 0; i < details.playerNames.length; i++) {
            self.playerNames[details.players[i]] = details.playerNames[i];
        }
        for (var _i = 0; _i < details.spectatorNames.length; _i++) {
            self.playerNames[details.spectators[_i]] = details.spectatorNames[_i];
        }
        for (var _i2 = 0; _i2 < details.reconnectingPlayerNames.length; _i2++) {
            self.playerNames[details.reconnectingPlayers[_i2]] = details.reconnectingPlayerNames[_i2];
        }
    }

    self.extractPlayerName = function (playerJoined) {
        self.playerNames[playerJoined.playerId] = playerJoined.playerName;
    };

    self.broadcast = function (event, data) {
        return metaBroadcaster.send(event, data);
    };

    scope.$on(Events.LOGIN_SUCCESS, function (e, loginSuccess) {
        var id = loginSuccess.playerId;
        var name = loginSuccess.username;
        self.model.me.name = name;
        self.model.me.id = id;
        self.model.me.tableDetails = "";
        self.model.me.tableId = -1;
        self.model.me.ipCountry = loginSuccess.ipCountry;
        self.playerNames[self.model.me.id] = name;
        self.processCardLevelMap(loginSuccess.cardLevelMap);
        errorHandler.setUserDetails(id, name);
    });

    scope.$on(Events.TABLES_OVERVIEW, function (event, info) {
        self.state.tableSummaries = info.tableSummaries;
    });

    scope.$on(Events.AUTOMATCH_FOUND, function (e, automatchFound) {
        var details = automatchFound.tableDetails;
        self.model.me.tableId = details.tableId;
        extractPlayerNames(details);
    });

    scope.$on(Events.TABLE_JOINED, function (e, details) {
        self.model.me.tableId = details.tableId;
        extractPlayerNames(details);
    });

    scope.$on(Events.TABLE_DETAILS, function (e, details) {
        extractPlayerNames(details);
    });

    scope.$on(Events.PLAYER_LEFT, function (e, playerLeft) {
        if (playerLeft.playerId === self.model.me.id) {
            self.model.me.tableId = -1;
            self.model.me.tableDetails = "";
        }
    });

    self.setUsername = function (username) {
        self.model.me.name = username;
        self.playerNames[self.model.me.id] = username;
    };

    self.getPlayerNameById = function (id) {
        if (isUndefined(self.playerNames[id])) return "UNKNOWN_" + id;
        return self.playerNames[id];
    };

    self.getTableIdString = function () {
        return 't' + self.getMyTableId();
    };
    self.getMyId = function () {
        return self.model.me.id;
    };
    self.getMyTableId = function () {
        return self.model.me.tableId;
    };

    (function setBotNames() {
        self.playerNames["1073741824"] = "Lord Rattington";
        self.playerNames["1073741825"] = "Revenge Witch";
        self.playerNames["1073741826"] = "Lord Voldebot";
        self.playerNames["1073741827"] = "Bad Advisor";
        self.playerNames["1073741828"] = "Scout Rush";
    })();

    self.processCardLevelMap = function (map) {
        var arrays = CardPoolLevels.map(function (l) {
            return [];
        });
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
            for (var _iterator = map[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var _step$value = _slicedToArray(_step.value, 2),
                    cardName = _step$value[0],
                    level = _step$value[1];

                if (level >= 1 && level <= 10) {
                    cardName.cardPoolLevel = level;
                    if (cardName.expansion !== Expansions.DEPRECATED) {
                        arrays[level - 1].push(cardName);
                    }
                }
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally {
            try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                    _iterator.return();
                }
            } finally {
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }

        arrays.forEach(function (cards, index) {
            return CardPoolLevels[index].setCards(cards);
        });
    };
}]);

function MetaState() {
    var self = this;
    self.tableSummaries = [];
    self.friendActivities = [];
}

function MetaModel() {
    var self = this;
    self.me = new Person(-1);
    self.me.tableId = -1;
    self.me.isSeated = function () {
        return self.me.tableId !== -1;
    };
    self.getSender = function () {
        return 'p' + self.me.id;
    };
}

function Person(id) {
    var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'NoName';

    var self = this;
    self.id = id;
    self.name = name;
}