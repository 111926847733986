"use strict";

var JapaneseAutoPlays = {};

JapaneseAutoPlays[AutoPlays.MOAT] = ["オフ", "常に公開"];
JapaneseAutoPlays[AutoPlays.MONEYLENDER] = ["オフ", "常に銅貨を廃棄する"];
JapaneseAutoPlays[AutoPlays.TORTURER] = ["オフ", "呪いの山が空なら捨て札にしない"];
JapaneseAutoPlays[AutoPlays.TREASURY] = ["オフ", "常に上に置く"];
JapaneseAutoPlays[AutoPlays.HERBALIST] = ["オフ", "順序を決めない"];
JapaneseAutoPlays[AutoPlays.ALCHEMIST] = ["オフ", "常に上に置く"];
JapaneseAutoPlays[AutoPlays.ROYAL_SEAL] = ["オフ", "常に上に置く", "確認を省略"];
JapaneseAutoPlays[AutoPlays.MOUNTEBANK] = ["オフ", "常に捨て札にする"];
JapaneseAutoPlays[AutoPlays.TOURNAMENT] = ["オフ", "常に公開"];
JapaneseAutoPlays[AutoPlays.YOUNG_WITCH] = ["オフ", "常に公開"];
JapaneseAutoPlays[AutoPlays.TRADER] = ["オフ", "銀貨にはリアクションしない"];
JapaneseAutoPlays[AutoPlays.URCHIN] = ["オフ", "常に維持"];
JapaneseAutoPlays[AutoPlays.COIN_OF_THE_REALM] = ["オフ", "スマート"];
JapaneseAutoPlays[AutoPlays.PEASANT] = ["オフ", "標準で交換", "標準で維持"];
JapaneseAutoPlays[AutoPlays.SOLDIER] = ["オフ", "標準で交換", "標準で維持"];
JapaneseAutoPlays[AutoPlays.FUGITIVE] = ["オフ", "標準で交換", "標準で維持"];
JapaneseAutoPlays[AutoPlays.DISCIPLE] = ["オフ", "標準で交換", "標準で維持"];
JapaneseAutoPlays[AutoPlays.PAGE] = ["オフ", "標準で交換", "標準で維持"];
JapaneseAutoPlays[AutoPlays.TREASURE_HUNTER] = ["オフ", "標準で交換", "標準で維持"];
JapaneseAutoPlays[AutoPlays.WARRIOR] = ["オフ", "標準で交換", "標準で維持"];
JapaneseAutoPlays[AutoPlays.HERO] = ["オフ", "標準で交換", "標準で維持"];
JapaneseAutoPlays[AutoPlays.CHAMPION] = ["オフ", "習性の再検討を省略"];
JapaneseAutoPlays[AutoPlays.SETTLERS] = ["オフ", "常に銅貨を加える"];
JapaneseAutoPlays[AutoPlays.BUSTLING_VILLAGE] = ["オフ", "常に開拓者を加える"];
JapaneseAutoPlays[AutoPlays.CROWN] = ["オフ", "自動で購入開始"];
JapaneseAutoPlays[AutoPlays.ARENA] = ["オフ", "勝利点がないなら捨て札にしない"];
JapaneseAutoPlays[AutoPlays.FAITHFUL_HOUND] = ["オフ", "常に脇に置く"];
JapaneseAutoPlays[AutoPlays.CHANGELING] = ["オフ", "スマート", "確認を省略"];
JapaneseAutoPlays[AutoPlays.BORDER_GUARD] = ["オフ", "常に上に置く"];
JapaneseAutoPlays[AutoPlays.OLD_WITCH] = ["オフ", "常に呪いを廃棄する"];
JapaneseAutoPlays[AutoPlays.PAGEANT] = ["オフ", "常にコイントークンを得る"];
JapaneseAutoPlays[AutoPlays.SINISTER_PLOT] = ["オフ", "トークンが0なら常に追加する"];
JapaneseAutoPlays[AutoPlays.DESPERATION] = ["オフ", "常に呪いを獲得する"];
JapaneseAutoPlays[AutoPlays.WAY_OF_THE_BUTTERFLY] = ["オフ", "スマート"];
JapaneseAutoPlays[AutoPlays.WALLED_VILLAGE] = ["オフ", "常に上に置く"];
JapaneseAutoPlays[AutoPlays.PROVINCE] = ["オフ", "不要カードは永久追放"];
JapaneseAutoPlays[AutoPlays.ENCAMPMENT] = ["オフ", "スマート"];
JapaneseAutoPlays[AutoPlays.DUPLICATE] = ["オフ", "獲得不可なカードには呼び出さない", "獲得不可なカードや呪いには呼び出さない"];