"use strict";

webclient.component("userPrefEnum", {
    bindings: {
        pref: "="
    },
    controllerAs: "userPref",
    controller: ['preferences', function (preferences) {
        var userPref = this;
        console.log(userPref.pref);

        userPref.buttonLabel = function (bool) {
            return LANGUAGE.getProfileButtons[bool == true];
        };

        userPref.toggleBoolean = function (pref) {
            pref.arguments = !pref.arguments;
            preferences.setUserPreference(pref);
        };
    }],
    template: "<button class=\"profile-button toggle-button\" ng-click=\"userPref.toggleBoolean(userPref.pref)\">\n                    {{userPref.buttonLabel(userPref.pref.arguments)}}\n                </button>\n"
});