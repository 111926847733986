"use strict";

webclient.service('chat', ['$rootScope', 'meta', 'game', 'serverMessenger', 'metaBroadcaster', 'tableService', 'soundService', 'connection', 'relations', function ($rootScope, meta, game, serverMessenger, metaBroadcaster, tableService, soundService, connection, relations) {
    var self = this;
    var scope = $rootScope.$new(true);
    self.chatLines = [];
    self.message = "";
    self.lastPrintedGameNumber = null;
    var lastTime = new Date().getTime();

    function executeChatCommand(message) {
        if (!message.startsWith('/')) return false;
        return false;
    }

    self.send = function (receiver, sender, message) {
        if (!executeChatCommand(message)) serverMessenger.chatMessage(receiver, sender, message);
    };

    self.sendToTable = function (tableId) {
        if (self.message === "") return;
        var receiver = meta.getTableIdString();
        self.send(receiver, 'p' + meta.model.me.id, self.message);
        self.message = "";
    };

    self.printMessage = function (text) {
        self.chatLines.push(createServerChatLine(text));
    };

    function sufficientTimeHasPassed() {
        var time = new Date().getTime();
        var b = time - lastTime > 30000;
        lastTime = time;
        return b;
    }

    function shouldRingBell(chatMessage) {
        return chatMessage.sender.id !== meta.model.me.id && sufficientTimeHasPassed();
    }

    scope.$on(Events.CHAT_MESSAGE_RECEIVED, function (event, chatMessage) {
        var senderId = parseInt(chatMessage.sender.substring(1));
        if (!relations.isBlacklisted(senderId)) {
            var m = parseChatMessage(chatMessage, meta, game);
            if (self.chatLines.length > 0 && m.sender === self.chatLines[self.chatLines.length - 1].sender) m.shouldShowSender = false;
            self.chatLines.push(m);
            if (shouldRingBell(chatMessage)) soundService.play(SOUNDS.PING);
            metaBroadcaster.send(Events.CHAT_MESSAGE_PROCESSED);
        }
    });

    scope.$on(Events.PLAYER_JOINED, function (event, playerJoined) {
        var id = playerJoined.playerId;
        if (!shouldWriteConnectionInfoToChat(id)) return;
        var s = getPhrase(Phrases.PLAYER_JOINED_TABLE).replace('PLAYER_NAME', meta.getPlayerNameById(id));
        self.chatLines.push(createServerChatLine(s));
        soundService.play(SOUNDS.PING);
        metaBroadcaster.send(Events.CHAT_MESSAGE_PROCESSED);
    });

    scope.$on(Events.PLAYER_LEFT, function (event, playerLeft) {
        var id = playerLeft.playerId;
        if (!shouldWriteConnectionInfoToChat(id)) return;
        var s = getPhrase(Phrases.PLAYER_LEFT_TABLE).replace('PLAYER_NAME', meta.getPlayerNameById(id));
        self.chatLines.push(createServerChatLine(s));
        metaBroadcaster.send(Events.CHAT_MESSAGE_PROCESSED);
    });

    scope.$on(Events.PLAYER_REPLACED, function (event, playerReplaced) {
        console.log('event: %o', event);
        console.log('playerReplaced: %o', playerReplaced);
        var s = getPhrase(Phrases.PLAYER_REPLACED).replace('LEAVING_PLAYER', playerReplaced.oldPlayer.name).replace('REPLACEMENT', playerReplaced.newPlayer.name);
        self.chatLines.push(createServerChatLine(s));
        metaBroadcaster.send(Events.CHAT_MESSAGE_PROCESSED);
    });

    scope.$on(Events.PLAYER_DISCONNECTED, function (event, playerDisconnected) {
        var phrase = getPhrase(Phrases.PLAYER_DISCONNECTED);
        var s = phrase.replace('PLAYER_NAME', playerDisconnected.namedId.name);
        self.chatLines.push(createServerChatLine(s));
        soundService.play(SOUNDS.PING);
        metaBroadcaster.send(Events.CHAT_MESSAGE_PROCESSED);
    });

    scope.$on(Events.PLAYER_RECONNECTED, function (event, playerReconnected) {
        var s = getPhrase(Phrases.PLAYER_RECONNECTED).replace('PLAYER_NAME', playerReconnected.namedId.name);
        self.chatLines.push(createServerChatLine(s));
        soundService.play(SOUNDS.PING);
        metaBroadcaster.send(Events.CHAT_MESSAGE_PROCESSED);
    });

    scope.$on(Events.PRINT_JOIN_GAME, function (event, gameId) {
        if (gameId !== self.lastPrintedGameNumber) {
            var message = getPhrase(Phrases.JOINING_GAME_MESSAGE).replace('NUMBER', gameId);
            self.printMessage(message);
            self.lastPrintedGameNumber = gameId;
        }
    });

    function shouldWriteConnectionInfoToChat(id) {
        return id !== meta.model.me.id && tableService.isPlayer(id);
    }

    scope.$on(Events.UNDO_REQUEST_DENIED, function (event, data) {
        self.chatLines.push(createAlertChatLine(getPhrase(Phrases.UNDO_DENIED)));
        metaBroadcaster.send(Events.CHAT_MESSAGE_PROCESSED);
    });

    scope.$on(Events.NO_PONG_RECEIVED, function (event) {
        self.chatLines.push(createAlertChatLine(getPhrase(Phrases.NO_PONG_RECEIVED)));
        metaBroadcaster.send(Events.CHAT_MESSAGE_PROCESSED);
    });

    scope.$on(Events.MESSAGE_INDEX_MISMATCH, function (event) {
        self.chatLines.push(createAlertChatLine(getPhrase(Phrases.MESSAGE_INDEX_MISMATCH)));
        metaBroadcaster.send(Events.CHAT_MESSAGE_PROCESSED);
    });

    scope.$on(Events.UNDO_REQUEST_CANCELLED, function (event, data) {
        self.chatLines.push(createAlertChatLine(getPhrase(Phrases.UNDO_CANCELLED)));
        metaBroadcaster.send(Events.CHAT_MESSAGE_PROCESSED);
    });

    scope.$on(Events.CLEAR_META_STATE, function () {
        self.chatLines = [];
        self.message = "";
    });
}]);