"use strict";

var JapaneseLogEntries = {};

JapaneseLogEntries[LogEntryNames.GAIN] = "Argument0はArgument1を獲得した。";
JapaneseLogEntries[LogEntryNames.GAIN_WITH] = "Argument0はArgument1を獲得した(Argument2)。";
JapaneseLogEntries[LogEntryNames.BUY_AND_GAIN] = "Argument0はArgument1を購入・獲得した。";
JapaneseLogEntries[LogEntryNames.TRASH] = "Argument0はArgument1を廃棄した。";
JapaneseLogEntries[LogEntryNames.DISCARD] = "Argument0はArgument1を捨て札にした。";
JapaneseLogEntries[LogEntryNames.DISCARD_WITH] = "Argument0はArgument1を捨て札にした(Argument2)。";
JapaneseLogEntries[LogEntryNames.PLAY] = "Argument0はArgument1を使用した。";
JapaneseLogEntries[LogEntryNames.PLAY_AGAIN] = "Argument0はArgument1を再使用した。";
JapaneseLogEntries[LogEntryNames.PLAY_THIRD] = "Argument0はArgument1を再々使用した。";
JapaneseLogEntries[LogEntryNames.TOPDECK] = "Argument0はArgument1を山札の上に置いた。";
JapaneseLogEntries[LogEntryNames.DRAW] = "Argument0はArgument1を引いた。";
JapaneseLogEntries[LogEntryNames.DRAW_FROM] = "Argument0はArgument1を引いた(Argument2)。";
JapaneseLogEntries[LogEntryNames.REVEAL] = "Argument0はArgument1を公開した。";
JapaneseLogEntries[LogEntryNames.REVEAL_SEARCHING] = "Argument0はArgument1を公開した。";
JapaneseLogEntries[LogEntryNames.REVEAL_FINDING] = "Argument0はArgument1を公開した。";
JapaneseLogEntries[LogEntryNames.REVEAL_SEARCHING_AND_FINDING] = "Argument0はArgument1を公開した末にArgument2を見つけた。";
JapaneseLogEntries[LogEntryNames.REVEAL_HAND] = "Argument0は手札のArgument1を公開した。";
JapaneseLogEntries[LogEntryNames.REVEAL_DISCARD] = "Argument0は捨て札置き場のArgument1を公開した。";
JapaneseLogEntries[LogEntryNames.LOOK_AT] = "Argument0はArgument1を見た。";
JapaneseLogEntries[LogEntryNames.PUT_IN_HAND] = "Argument0はArgument1を手札に加えた。";
JapaneseLogEntries[LogEntryNames.SET_ASIDE] = "Argument0はArgument1を脇に置いた。";
JapaneseLogEntries[LogEntryNames.PUT_ON_MAT] = "Argument0はArgument1をArgument2に置いた。";
JapaneseLogEntries[LogEntryNames.CALL] = "Argument0はArgument1を呼び出した。";
JapaneseLogEntries[LogEntryNames.DECK_TO_DISCARD] = "Argument0は山札を捨て札置き場に置いた。";
JapaneseLogEntries[LogEntryNames.BACK_ON_DECK] = "Argument0はArgument1を山札に戻した。";
JapaneseLogEntries[LogEntryNames.SHUFFLE_INTO_DECK] = "Argument0はArgument1を山札に混ぜシャッフルした。";
JapaneseLogEntries[LogEntryNames.INSERT_IN_DECK] = "Argument0はArgument1を山札に加えた。";
JapaneseLogEntries[LogEntryNames.EXCHANGE_RETURN] = "Argument0はArgument1を戻した。";
JapaneseLogEntries[LogEntryNames.EXCHANGE_RECEIVE] = "Argument0はArgument1を受け取った。";
JapaneseLogEntries[LogEntryNames.PASS] = "Argument0はArgument1をArgument2に渡した。";
JapaneseLogEntries[LogEntryNames.STARTS_WITH] = "Argument0はArgument1を受け取った。";
JapaneseLogEntries[LogEntryNames.BUY] = "Argument0はArgument1を購入した。";
JapaneseLogEntries[LogEntryNames.COUNTER_ADD] = "カウンターを追加した。";
JapaneseLogEntries[LogEntryNames.COUNTER_RESET] = "カウンターをリセットした。";
JapaneseLogEntries[LogEntryNames.REACTS_WITH] = "Argument0はArgument1でリアクションした。";
JapaneseLogEntries[LogEntryNames.SHUFFLES] = "Argument0は山札をシャッフルした。";
JapaneseLogEntries[LogEntryNames.NEW_TURN] = "Argument0";
JapaneseLogEntries[LogEntryNames.WISH_CORRECT] = "Argument0はArgument1を指定し、的中した。";
JapaneseLogEntries[LogEntryNames.WISH_WRONG] = "Argument0をArgument1を指定したが、Argument2が公開された。";
JapaneseLogEntries[LogEntryNames.BLACK_MARKET_RESHUFFLE] = "闇市場デッキをシャッフルした。";
JapaneseLogEntries[LogEntryNames.RETURN_TO] = "Argument0はArgument1をArgument2に戻した。";
JapaneseLogEntries[LogEntryNames.PUT_ON_BOTTOM_OF] = "Argument0はArgument1をArgument2の底に置いた。";
JapaneseLogEntries[LogEntryNames.LOSE_TRACK_MOVED] = "Argument0はArgument1を移動できなくなった(別途移動済)。";
JapaneseLogEntries[LogEntryNames.LOSE_TRACK_HIDDEN] = "Argument0はArgument1を移動できなくなった(覆われた)。";
JapaneseLogEntries[LogEntryNames.LOSE_TRACK_RESHUFFLED] = "Argument0はArgument1を移動できなくなった(シャッフル)。";
JapaneseLogEntries[LogEntryNames.LOSE_TRACK_ANONYMOUS] = "Argument0はカードを移動できなくなった。";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_BANDIT_CAMP] = "Argument0";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_TOWER] = "Argument0";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH_ABSENT] = "アクションカードは2種類未満です。";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_TRIUMPHAL_ARCH] = "Argument0";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_WALL] = "Argument0";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_WOLF_DEN] = "Argument0";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_PALACE] = "Argument0";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_ORCHARD] = "Argument0";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_OBELISK] = "Argument1Argument0枚";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_MUSEUM] = "Argument0種類";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_KINGS_CASTLE] = "城Argument0枚";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_KEEP] = "Argument0";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_HUMBLE_CASTLE] = "城Argument0枚";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_FOUNTAIN] = "Argument0";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_FEODUM] = "Argument0";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_GARDENS] = "Argument0";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_VINEYARD] = "アクションカードArgument0枚";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_SILK_ROAD] = "勝利点カードArgument0枚";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_FAIRGROUNDS] = "Argument0種類";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_DUKE] = "Argument0";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_DUH] = "";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_VP_TOKENS] = "勝利点トークン";
JapaneseLogEntries[LogEntryNames.PUT_IN_HAND_FROM] = "Argument0はArgument1を手札に加えた(Argument2)。";
JapaneseLogEntries[LogEntryNames.STARTS_TURN] = "Argument0はターンを開始した。";
JapaneseLogEntries[LogEntryNames.TAKE_VP_FROM] = "Argument0はArgument2からArgument1勝利点を得た。";
JapaneseLogEntries[LogEntryNames.MOVE_VP_FROM_TO] = "Argument0はArgument2からArgument3にArgument1勝利点を移動した。";
JapaneseLogEntries[LogEntryNames.OBELISK_CHOOSES_PILE] = "Argument0が[Obelisk]に選ばれた。";
JapaneseLogEntries[LogEntryNames.PLAYER_MOVES_TOKEN_TO] = "Argument0はArgument1をArgument2に移動した。";
JapaneseLogEntries[LogEntryNames.PLAYER_GETS_VP] = "Argument0はArgument1勝利点を得た。";
JapaneseLogEntries[LogEntryNames.PLAYER_GETS_VP_FROM] = "Argument0はArgument2からArgument1勝利点を得た。";
JapaneseLogEntries[LogEntryNames.ADDS_VP_TO] = "Argument0はArgument1勝利点をArgument2に乗せた。";
JapaneseLogEntries[LogEntryNames.WAITING_FOR] = "Argument0を待機中です"; //mainly used for description
JapaneseLogEntries[LogEntryNames.ALMS_FAIL] = "Argument1があるので、Argument0は[Alms]を受けなかった。";
JapaneseLogEntries[LogEntryNames.BORROW_FAIL] = "Argument1があるので、Argument0は[Borrow]できなかった。";
JapaneseLogEntries[LogEntryNames.QUEST_FAIL_ATTACK] = "Argument0はアタックカードを捨て札にしなかった。";
JapaneseLogEntries[LogEntryNames.QUEST_FAIL_CURSES] = "Argument0はArgument1を捨て札にしなかった。";
JapaneseLogEntries[LogEntryNames.QUEST_FAIL_DISCARD] = "Argument0の手札は5枚以下だった。";
JapaneseLogEntries[LogEntryNames.SAVE_FAIL] = "Argument0の手札はなかった。";
JapaneseLogEntries[LogEntryNames.SCOUTING_PARTY_FAIL] = "Argument0の山札と捨て札はなかった。";
JapaneseLogEntries[LogEntryNames.BONFIRE_FAIL] = "Argument0は何も廃棄しなかった。";
JapaneseLogEntries[LogEntryNames.MISSION_SUCCESS] = "Argument0は追加ターンを得る。";
JapaneseLogEntries[LogEntryNames.TRADE_FAIL] = "Argument0は何も廃棄しなかった。";
JapaneseLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_DOWN] = "Argument0はArgument1を裏にした。";
JapaneseLogEntries[LogEntryNames.PLAYER_FLIPS_TOKEN_UP] = "Argument0はArgument1を表にした。";
JapaneseLogEntries[LogEntryNames.TRIUMPH_FAIL] = "Argument0はArgument1の獲得に失敗した。";
JapaneseLogEntries[LogEntryNames.ADVANCE_FAIL] = "Argument0は何も廃棄しなかった。";
JapaneseLogEntries[LogEntryNames.TAX_ADD_DEBT] = "Argument0はArgument1をArgument2に乗せた。";
JapaneseLogEntries[LogEntryNames.TAX_TAKE_DEBT] = "Argument0はArgument1をArgument2から得た。";
JapaneseLogEntries[LogEntryNames.RITUAL_FAIL] = "Argument0はArgument1の獲得に失敗した。";
JapaneseLogEntries[LogEntryNames.WINDFALL_FAIL] = "Argument0があった。";
JapaneseLogEntries[LogEntryNames.DOMINATE_FAIL] = "Argument0はArgument1の獲得に失敗した。";
JapaneseLogEntries[LogEntryNames.PLUS_ACTION_FROM] = "Argument0はArgument1により+1アクションを得た。";
JapaneseLogEntries[LogEntryNames.PLUS_BUY_FROM] = "Argument0はArgument1により+1購入を得た。";
JapaneseLogEntries[LogEntryNames.DRAWS_FROM] = "Argument0はArgument2によりArgument1を引いた。";
JapaneseLogEntries[LogEntryNames.PLUS_COIN_FROM] = "Argument0はArgument1により+$1を得た。";
JapaneseLogEntries[LogEntryNames.DRAWS_LESS_BECAUSE] = "Argument0はArgument1による-1カードトークンを取り除いた。";
JapaneseLogEntries[LogEntryNames.PRINCE_FAILS] = "";
JapaneseLogEntries[LogEntryNames.POSSESSION_TAKES_COIN_TOKENS] = "Argument0が代わりに財源を得た。";
JapaneseLogEntries[LogEntryNames.POSSESSION_TAKES_DEBT_TOKENS] = "Argument0が代わりに負債を得た。";
JapaneseLogEntries[LogEntryNames.POSSESION_TAKES_VP_TOKENS] = "Argument0が代わりに勝利点トークンを得た。";
JapaneseLogEntries[LogEntryNames.TAKES_DEBT] = "Argument0はArgument1負債を得た。";
JapaneseLogEntries[LogEntryNames.REPAYS_DEBT] = "Argument0はArgument1負債を完済した。";
JapaneseLogEntries[LogEntryNames.REPAYS_SOME_DEBT] = "Argument0はArgument2負債を残し、Argument1負債を返済した。";
JapaneseLogEntries[LogEntryNames.RETURNS_TOKEN] = "Argument0はArgument2によるArgument1を戻した。";
JapaneseLogEntries[LogEntryNames.SET_ASIDE_WITH] = "Argument0はArgument2によりArgument1を脇に置いた。";
JapaneseLogEntries[LogEntryNames.BLOCKS_WITH] = "Argument0はArgument1により阻止した。";
JapaneseLogEntries[LogEntryNames.NAMES] = "Argument0はArgument1を指定した。";
JapaneseLogEntries[LogEntryNames.GAIN_ON_DRAWPILE] = "Argument0はArgument1を山札の上に獲得した。";
JapaneseLogEntries[LogEntryNames.GAIN_FROM_TRASH] = "Argument0はArgument1を廃棄置き場から獲得した。";
JapaneseLogEntries[LogEntryNames.OBELISK_FAILS] = "[Obelisk]で指定可能な山がありません。";
JapaneseLogEntries[LogEntryNames.INHERITS] = "Argument0はArgument1を相続した。";
JapaneseLogEntries[LogEntryNames.GAME_META_INFO] = "Argument0";
JapaneseLogEntries[LogEntryNames.THE_SKYS_GIFT_FAIL] = "Argument0は3枚捨て札にしなかった。";
JapaneseLogEntries[LogEntryNames.RECEIVES] = "Argument0はArgument1を受けた。";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_PASTURE] = "Argument0。";
JapaneseLogEntries[LogEntryNames.TAKE] = "Argument0はArgument1を得た。";
JapaneseLogEntries[LogEntryNames.RETURN] = "Argument0はArgument1を戻した。";
JapaneseLogEntries[LogEntryNames.THE_SUNS_GIFT_FAIL] = "Argument0の山札と捨て札はなかった。";
JapaneseLogEntries[LogEntryNames.DRUID_SELECTS_BOONS] = "[Druid]によりArgument1を脇に置いた。";
JapaneseLogEntries[LogEntryNames.DISCARD_RECEIVABLE] = "Argument0はArgument1を捨て札にした。";
JapaneseLogEntries[LogEntryNames.PLAY_ENCHANTED] = "Argument1によりArgument0は無効化された。";
JapaneseLogEntries[LogEntryNames.GAIN_ANOTHER_EXPERIMENT] = "Argument0は追加で[Experiment]1枚を獲得した。";
JapaneseLogEntries[LogEntryNames.COFFERS_FROM_PATRON] = "Argument0は[Patron]から財源を得た。";
JapaneseLogEntries[LogEntryNames.GETS_COFFER] = "Argument0は+Argument1財源を得た。";
JapaneseLogEntries[LogEntryNames.GETS_COFFERS] = "Argument0は+Argument1財源を得た。";
JapaneseLogEntries[LogEntryNames.GETS_COFFER_FROM] = "Argument0はArgument2から+Argument1財源を得た。";
JapaneseLogEntries[LogEntryNames.GETS_COFFERS_FROM] = "Argument0はArgument2から+Argument1財源を得た。";
JapaneseLogEntries[LogEntryNames.USES_COFFER] = "Argument0はArgument1財源を使った(+$Argument1)。";
JapaneseLogEntries[LogEntryNames.USES_COFFERS] = "Argument0はArgument1財源を使った(+$Argument1)。";
JapaneseLogEntries[LogEntryNames.GETS_VILLAGER] = "Argument0は+Argument1村人を得た。";
JapaneseLogEntries[LogEntryNames.GETS_VILLAGERS] = "Argument0は+Argument1村人を得た。";
JapaneseLogEntries[LogEntryNames.GETS_VILLAGER_FROM] = "Argument0はArgument2から+Argument1村人を得た。";
JapaneseLogEntries[LogEntryNames.GETS_VILLAGERS_FROM] = "Argument0はArgument2から+Argument1村人を得た。";
JapaneseLogEntries[LogEntryNames.USES_VILLAGER] = "Argument0はArgument1村人を使った。";
JapaneseLogEntries[LogEntryNames.USES_VILLAGERS] = "Argument0はArgument1村人を使った。";
JapaneseLogEntries[LogEntryNames.BEGINS_BUYPHASE] = "Argument0は購入フェイズを開始した。";
JapaneseLogEntries[LogEntryNames.ENDS_BUYPHASE] = "Argument0は購入フェイズを終了した。";
JapaneseLogEntries[LogEntryNames.GETS_ACTION] = "Argument0は+Argument1アクションを得た。";
JapaneseLogEntries[LogEntryNames.GETS_ACTIONS] = "Argument0は+Argument1アクションを得た。";
JapaneseLogEntries[LogEntryNames.GETS_ACTION_FROM] = "Argument0は+Argument1アクションを得た(Argument2)。";
JapaneseLogEntries[LogEntryNames.GETS_ACTIONS_FROM] = "Argument0は+Argument1アクションを得た(Argument2)。";
JapaneseLogEntries[LogEntryNames.GETS_BUY] = "Argument0は+Argument1購入を得た。";
JapaneseLogEntries[LogEntryNames.GETS_BUYS] = "Argument0は+Argument1購入を得た。";
JapaneseLogEntries[LogEntryNames.GETS_BUY_FROM] = "Argument0は+Argument1購入を得た(Argument2)。";
JapaneseLogEntries[LogEntryNames.GETS_BUYS_FROM] = "Argument0は+Argument1購入を得た(Argument2)。";
JapaneseLogEntries[LogEntryNames.GETS_COIN] = "Argument0は+$Argument1を得た。";
JapaneseLogEntries[LogEntryNames.GETS_COINS] = "Argument0は+$Argument1を得た。";
JapaneseLogEntries[LogEntryNames.GETS_COIN_FROM] = "Argument0は+$Argument1を得た(Argument2)。";
JapaneseLogEntries[LogEntryNames.GETS_COINS_FROM] = "Argument0は+$Argument1を得た(Argument2)。";
JapaneseLogEntries[LogEntryNames.LOSES_COIN] = "Argument0は$Argument1を失った。";
JapaneseLogEntries[LogEntryNames.LOSES_COINS] = "Argument0は$Argument1を失った。";
JapaneseLogEntries[LogEntryNames.BOTTOMDECKS] = "Argument0はArgument1を山札の一番下に置いた。";
JapaneseLogEntries[LogEntryNames.TRASH_WITH] = "Argument0はArgument2でArgument1を廃棄した。";
JapaneseLogEntries[LogEntryNames.PRIEST_ACTIVATES] = "これ以降、カード1枚を廃棄するとき、+$Argument0。";
JapaneseLogEntries[LogEntryNames.PLAY_TREASURES_FOR] = "Argument0はArgument1を使用した(+$Argument2)。";
JapaneseLogEntries[LogEntryNames.PLAY_TREASURES_AGAIN_FOR] = "Argument0はArgument1を再使用した(+$Argument2)。";
JapaneseLogEntries[LogEntryNames.PLAY_TREASURES_THIRD_FOR] = "Argument0はArgument1を再々使用した(+$Argument2)。";
JapaneseLogEntries[LogEntryNames.RETURNS_MINUS_COIN_TOKEN] = "Argument0はArgument2によるArgument1を戻した(-$1)。";
JapaneseLogEntries[LogEntryNames.NOT_IN_PLAY_PREVENTED_PLAYING] = "Argument0は場にないため、使用できなかった。";
JapaneseLogEntries[LogEntryNames.PILLAGE_FAIL] = "廃棄しなかったため、[Pillage]は不発となる。";
JapaneseLogEntries[LogEntryNames.DEATH_CART_FAIL] = "廃棄しなかったため、[Death Cart]は不発となる。";
JapaneseLogEntries[LogEntryNames.TACTICIAN_FAIL] = "捨て札にしなかったため、[Tactician]は不発となる。";
JapaneseLogEntries[LogEntryNames.EMBARGO_FAIL] = "廃棄しなかったため、[Embargo]トークンは乗せられない。";
JapaneseLogEntries[LogEntryNames.EXILE] = "Argument0はArgument1を追放した。";
JapaneseLogEntries[LogEntryNames.DISCARD_FROM_EXILE] = "Argument0は追放マットからArgument1を捨て札にした。";
JapaneseLogEntries[LogEntryNames.DISCARD_REVEALED] = "Argument0はArgument1を公開して捨て札にした。";
JapaneseLogEntries[LogEntryNames.PLAY_WITH] = "Argument0はArgument1を使用した(Argument2)。";
JapaneseLogEntries[LogEntryNames.PLAY_AGAIN_WITH] = "Argument0はArgument1を再使用した(Argument2)。";
JapaneseLogEntries[LogEntryNames.PLAY_THIRD_WITH] = "Argument0はArgument1を再々使用した(Argument2)。";
JapaneseLogEntries[LogEntryNames.IS_INVESTED] = "Argument0は他のプレイヤーがArgument1を獲得・投資するときカードArgument2枚を引くことになる。";
JapaneseLogEntries[LogEntryNames.COMMERCE_FAIL] = "Argument0は何も獲得していなかった。";
JapaneseLogEntries[LogEntryNames.STAMPEDE_FAIL] = "Argument0は[Stampede]を購入したが場にカードArgument1枚が出ていた。";
JapaneseLogEntries[LogEntryNames.PLAY_USING_WAY] = "Argument0はArgument1を使用し、Argument2を使った。";
JapaneseLogEntries[LogEntryNames.WAY_OF_THE_HORSE_FAILS_NO_PILE] = "Argument0には山がないため戻せなかった。";
JapaneseLogEntries[LogEntryNames.WAY_OF_THE_HORSE_FAILS_NOT_IN_PLAY] = "[Way Of The Horse]はArgument0が場にないため戻せなかった。";
JapaneseLogEntries[LogEntryNames.WAY_OF_THE_TURTLE_FAILS_TO_SET_ASIDE] = "[Way Of The Turtle]はArgument0を脇に置けなかった。";
JapaneseLogEntries[LogEntryNames.USES_WAY] = "Argument0はArgument1を使った。";
JapaneseLogEntries[LogEntryNames.WOULD_GET_COIN] = "Argument0は+$Argument1を得ようとした。";
JapaneseLogEntries[LogEntryNames.WOULD_DRAW] = "Argument0はArgument1を引こうとした。";
JapaneseLogEntries[LogEntryNames.TOPDECK_WITH] = "Argument0はArgument2でArgument1を山札の上に置いた。";
JapaneseLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_NO_PILE] = "Argument0には山がないため戻せなかった。";
JapaneseLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_NOT_IN_PLAY] = "[Way Of The Butterfly]はArgument0が場にないため戻せなかった。";
JapaneseLogEntries[LogEntryNames.WAY_OF_THE_BUTTERFLY_FAILS_INTENTIONAL] = "Argument0はArgument1を山に戻さなかった。";
JapaneseLogEntries[LogEntryNames.PLAY_NULLIFIED] = "Argument0はArgument1を使用しようとしたが、[Highwayman]に無効化された。";
JapaneseLogEntries[LogEntryNames.GETS_FAVOR] = "Argument0は+Argument1好意を得た。";
JapaneseLogEntries[LogEntryNames.GETS_FAVORS] = "Argument0は+Argument1好意を得た。";
JapaneseLogEntries[LogEntryNames.GETS_FAVOR_FROM] = "Argument0は+Argument1好意を得た(Argument2)。";
JapaneseLogEntries[LogEntryNames.GETS_FAVORS_FROM] = "Argument0は+Argument1好意を得た(Argument2)。";
JapaneseLogEntries[LogEntryNames.SPENDS_FAVOR] = "Argument0はArgument1好意を支払った。";
JapaneseLogEntries[LogEntryNames.SPENDS_FAVORS] = "Argument0はArgument1好意を支払った。";
JapaneseLogEntries[LogEntryNames.ROTATES_EMPTY_PILE] = "Argument0が循環させた山は空だった。";
JapaneseLogEntries[LogEntryNames.ROTATES_IDENTICAL_PILE] = "Argument0が循環させた山に異なる名前のカードはなかった。";
JapaneseLogEntries[LogEntryNames.ROTATES] = "Argument0はArgument1の山を循環させた。";
JapaneseLogEntries[LogEntryNames.ISLAND_FOLK] = "Argument0は[Island Folk]の追加ターンを得る。";
JapaneseLogEntries[LogEntryNames.ROYAL_GALLEY_FAIL] = "[Royal Galley]で脇に置けなかった。";
JapaneseLogEntries[LogEntryNames.FAILS_TO_PLAY] = "Argument0はArgument1を使用できなかった。";
JapaneseLogEntries[LogEntryNames.FAILS_TO_PLAY_WRONG_LOCATION] = "Argument0はArgument1をArgument2に見つけられず使用できなかった。";
JapaneseLogEntries[LogEntryNames.STRANDS_CARDS] = "Argument0 strands Argument1";
JapaneseLogEntries[LogEntryNames.VOYAGE_SUCCESS] = "Argument0は[Voyage]の追加ターンを得る。";
JapaneseLogEntries[LogEntryNames.GARRISON_ADDS_TOKEN] = "Argument0は[Garrison]にトークンを乗せた(現在Argument1)。";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_PLATEAU_SHEPHERDS] = "カードArgument0枚、Argument1好意";
JapaneseLogEntries[LogEntryNames.PUT_IN_DISCARD] = "Argument0はArgument1を捨て札に置いた。";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_TERRITORY] = "勝利点カードArgument0種類";
JapaneseLogEntries[LogEntryNames.SORCERESS_GUESS] = "Argument0はArgument1を指定した。";
JapaneseLogEntries[LogEntryNames.SORCERER_GUESS] = "Argument0はArgument1を指定した。";
JapaneseLogEntries[LogEntryNames.SKIPS_A_TURN_REMAINING] = "Argument0はターンをスキップした(残りArgument1スキップ)。";
JapaneseLogEntries[LogEntryNames.SKIPS_A_TURN] = "Argument0はターンをスキップした。";
JapaneseLogEntries[LogEntryNames.CANCELS_A_TURN] = "Argument0はターンを得られなかった(Argument1)。";
JapaneseLogEntries[LogEntryNames.WILL_SKIP_A_TURN] = "Argument0はターンをスキップすることになる。";
JapaneseLogEntries[LogEntryNames.WILL_SKIP_A_TURN_COUNT] = "Argument0はターンをスキップすることになる(残りArgument1スキップ)。";
JapaneseLogEntries[LogEntryNames.KEEPS_IN_HAND] = "Argument0はArgument1を手札に保留した。";
JapaneseLogEntries[LogEntryNames.INVESTMENT_FAIL] = "[Investment]は場にないため廃棄できなかった。";
JapaneseLogEntries[LogEntryNames.GAIN_SETTING_ASIDE] = "Argument0は脇にArgument1を獲得した。";

JapaneseLogEntries[LogEntryNames.PLAYER_BIDS] = "Argument0はArgument1負債を入札した。";
JapaneseLogEntries[LogEntryNames.PLAYER_ADDS_FAVOR_TOKEN_TO] = "Argument0はArgument1に好意トークンを1つ乗せた([Family of Inventors])。";
JapaneseLogEntries[LogEntryNames.BEGINS_CLEANUP_PHASE] = "Argument0はクリーンアップフェイズを開始した。";
JapaneseLogEntries[LogEntryNames.SPELL_SCROLL_FAILS] = "[Spell Scroll]を廃棄できなかったため、何も起こらなかった。";
JapaneseLogEntries[LogEntryNames.PERIL_FAIL] = "[Peril]で廃棄しなかったため、何も起こらなかった。";
JapaneseLogEntries[LogEntryNames.PREMOVES] = "Argument0はArgument1を行動予約した。";
JapaneseLogEntries[LogEntryNames.ACQUIRES_TRAIT] = "Argument1特性はArgument0に備わっている。";
JapaneseLogEntries[LogEntryNames.TRAIT_FAILS] = "Argument0特性を備えるアクションカードか財宝カードの山がなかった。";
JapaneseLogEntries[LogEntryNames.SHUFFLES_CARDS] = "Argument0はArgument1をシャッフルする。";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_MARCHLAND] = "勝利点カードArgument0枚";

JapaneseLogEntries[LogEntryNames.FARMHANDS_FAIL] = "[Farmhands]でカードを脇に置けなかった。";
JapaneseLogEntries[LogEntryNames.FARMHANDS_REFUSE] = "[Farmhands]でカードを脇に置かなかった。";
JapaneseLogEntries[LogEntryNames.EXPLAIN_POINTS_DEMESNE] = "金貨Argument0枚";
JapaneseLogEntries[LogEntryNames.REACTS_PLAYING] = "Argument0はArgument1でリアクションし使用した。";

JapaneseLogEntries[LogEntryNames.PLAY_ENLIGHTENED] = "Argument0はArgument1を使用し、+1カードを引く、+1アクションを得た。";
JapaneseLogEntries[LogEntryNames.PANIC_FAILS] = "[Panic]でArgument0を山に戻せなかった。";
JapaneseLogEntries[LogEntryNames.PANIC_FAILS_NO_PILE] = "[Panic]でArgument0の山がなく戻せなかった。";
JapaneseLogEntries[LogEntryNames.AMASS_FAILS] = "Argument0は[Amass]を購入したが、Argument1を場に出していた。";