"use strict";

var FrenchCardTexts = {};

FrenchCardTexts[CardNames.BACK] = "";
FrenchCardTexts[CardNames.CURSE] = "";
FrenchCardTexts[CardNames.COPPER] = "";
FrenchCardTexts[CardNames.SILVER] = "";
FrenchCardTexts[CardNames.GOLD] = "";
FrenchCardTexts[CardNames.ESTATE] = "";
FrenchCardTexts[CardNames.DUCHY] = "";
FrenchCardTexts[CardNames.PROVINCE] = "";
FrenchCardTexts[CardNames.ARTISAN] = "Recevez en main une carte co\xFBtant//jusqu'\xE0 [5]. Placez une carte de//votre main sur votre pioche.";
FrenchCardTexts[CardNames.BANDIT] = "Recevez un Or.//Tous vos adversaires d\xE9voilent//les 2 premi\xE8res cartes de leur//pioche, \xE9cartent une carte Tr\xE9sor//d\xE9voil\xE9e autre qu'un Cuivre, et//d\xE9faussent le reste.";
FrenchCardTexts[CardNames.BUREAUCRAT] = "Recevez un Argent sur votre pioche.//Tous vos adversaires d\xE9voilent une//carte Victoire de leur main et la//placent sur leur pioche (ou d\xE9voilent//une main sans carte Victoire).";
FrenchCardTexts[CardNames.CELLAR] = "|+1 Action|//D\xE9faussez autant de cartes//que vous voulez, puis piochez-en// le m\xEAme nombre.";
FrenchCardTexts[CardNames.CHAPEL] = "\xC9cartez jusqu'\xE0 4 cartes//de votre main.";
FrenchCardTexts[CardNames.COUNCIL_ROOM] = "|+4 Cartes|//|+1 Achat|////Tous vos adversaires//piochent une carte.";
FrenchCardTexts[CardNames.FESTIVAL] = "|+2 Actions|//|+1 Achat|//|+[2]|";
FrenchCardTexts[CardNames.GARDENS] = "Vaut {1} pour chaque 10 cartes//que vous avez//(arrondi \xE0 l'unit\xE9 inf\xE9rieure).";
FrenchCardTexts[CardNames.HARBINGER] = "|+1 Carte|//|+1 Action|////Consultez votre d\xE9fausse.//Vous pouvez placer une carte de//votre d\xE9fausse sur votre pioche.";
FrenchCardTexts[CardNames.LABORATORY] = "|+2 Cartes|//|+1 Action|";
FrenchCardTexts[CardNames.LIBRARY] = "Piochez jusqu'\xE0 ce que vous ayez//7 cartes en main. Chaque carte Action//pioch\xE9e peut \xEAtre mise de c\xF4t\xE9.//D\xE9faussez les cartes mises de c\xF4t\xE9//lorsque vous avez termin\xE9 de piocher";
FrenchCardTexts[CardNames.MARKET] = "|+1 Carte|//|+1 Action|//|+1 Achat|//|+[1]|";
FrenchCardTexts[CardNames.MERCHANT] = "|+1 Carte|//|+1 Action|////La premi\xE8re fois que vous jouez// un Argent ce tour-ci, +[1].";
FrenchCardTexts[CardNames.MILITIA] = "|+|[2]//Tous vos adversaires d\xE9faussent//jusqu'\xE0 avoir 3 cartes en main.";
FrenchCardTexts[CardNames.MINE] = "Vous pouvez \xE9carter une carte//Tr\xE9sor de votre main.//Recevez en main une carte Tr\xE9sor//co\xFBtant jusqu'\xE0 [3] de plus.";
FrenchCardTexts[CardNames.MOAT] = "|+2 Cartes|//---//Lorsqu'un adversaire joue une//carte Attaque, vous pouvez d\xE9voiler//cette carte de votre main. Dans ce//cas, l'attaque n'a pas d'effet sur vous.";
FrenchCardTexts[CardNames.MONEYLENDER] = "Vous pouvez \xE9carter un Cuivre//de votre main pour +[3].";
FrenchCardTexts[CardNames.POACHER] = "|+1 Carte|//|+1 Action|//|+[1]|////D\xE9fausser une carte pour chaque//pile vide de la r\xE9serve.";
FrenchCardTexts[CardNames.REMODEL] = "\xC9cartez une carte de votre main.//Recevez une carte co\xFBtant jusqu'\xE0//[2] de plus que la carte \xE9cart\xE9e.";
FrenchCardTexts[CardNames.SENTRY] = "|+1 Carte|//|+1 Action|////Consultez les 2 premi\xE8res cartes//de votre pioche. \xC9cartez-en et/ou//d\xE9faussez-en autant que vous le//voulez. Replacez le reste sur votre//pioche dans l'ordre de votre choix.";
FrenchCardTexts[CardNames.SMITHY] = "|+3 Cartes|";
FrenchCardTexts[CardNames.THRONE_ROOM] = "Vous pouvez jouer une carte//Action de votre main deux fois.";
FrenchCardTexts[CardNames.VASSAL] = "|+|[2]////D\xE9faussez la carte du haut de votre//pioche. Si c'est une carte Action,//vous pouvez la jouer.";
FrenchCardTexts[CardNames.VILLAGE] = "|+1 Carte|//|+2 Actions|";
FrenchCardTexts[CardNames.WITCH] = "|+2 Cartes|////Tous vos adversaires//re\xE7oivent une Mal\xE9diction.";
FrenchCardTexts[CardNames.WORKSHOP] = "Recevez une carte//co\xFBtant jusqu'\xE0 [4].";
FrenchCardTexts[CardNames.COURTYARD] = "|+3 Cartes|////Placez une carte de votre main//sur votre pioche.";
FrenchCardTexts[CardNames.CONSPIRATOR] = "|+|[2]////Si vous avez jou\xE9 3 Actions//ou plus ce tour-ci//(incluant cette carte),//|+1 Carte| et |+1 Action|.";
FrenchCardTexts[CardNames.COURTIER] = "D\xE9voilez une carte de votre main.//Pour chacun de ses types (Action,//Attaque, etc.), choisissez : |+1 Action|;//ou |+1 Achat|; ou +[3]; ou recevez un//Or. Les choix doivent \xEAtre diff\xE9rents.";
FrenchCardTexts[CardNames.BARON] = "|+1 Achat|////Vous pouvez d\xE9fausser un//Domaine pour +[4]. Si vous ne//le faites pas, recevez un Domaine.";
FrenchCardTexts[CardNames.BRIDGE] = "|+1 Achat|//|+[1]|////Ce tour-ci, les cartes (o\xF9 qu'elles//soient) co\xFBtent [1] de moins.";
FrenchCardTexts[CardNames.DIPLOMAT] = "|+2 Cartes|////Si apr\xE8s cela vous avez en main//5 cartes ou moins, |+2 Actions|.//---//Quand un autre joueur joue une carte//Attaque et que vous avez au moins 5//cartes en main, vous pouvez d\xE9voiler ceci//pour piocher 2 cartes puis en d\xE9fausser 3.";
FrenchCardTexts[CardNames.DUKE] = "Vaut {1} pour chaque Duch\xE9//que vous avez.";
FrenchCardTexts[CardNames.FARM] = "[!2]//---//{!2}";
FrenchCardTexts[CardNames.NOBLES] = "{!2}//---//Choisissez : |+3 Cartes| ou//|+2 Actions|.";
FrenchCardTexts[CardNames.IRONWORKS] = "Recevez une carte co\xFBtant jusqu'\xE0 [4].//Si la carte re\xE7ue est une...//Carte Action, |+1 Action|//Carte Tr\xE9sor, |+|[1]//Carte Victoire,|+1 Carte|";
FrenchCardTexts[CardNames.LURKER] = "|+1 Action|////Choisissez : \xC9cartez une carte//Action de la r\xE9serve ; ou recevez//une carte Action du rebut.";
FrenchCardTexts[CardNames.MASQUERADE] = "|+2 Cartes|////Parmi les joueurs ayant au moins//une carte en main, simultan\xE9ment,//chacun passe une carte au suivant//\xE0 gauche. Ensuite, vous pouvez//\xE9carter une carte de votre main.";
FrenchCardTexts[CardNames.MILL] = "{!1}////|+1 Carte|//|+1 Action|//---//Vous pouvez d\xE9fausser 2 cartes pour +[2].";
FrenchCardTexts[CardNames.MINING_VILLAGE] = "|+1 Carte|//|+2 Actions|////Vous pouvez \xE9carter cette carte// pour +[2].";
FrenchCardTexts[CardNames.MINION] = "|+1 Action|////Choisissez : +[2]; ou d\xE9faussez//votre main, |+4 Cartes|, et chaque//adversaire avec au moins 5//cartes en main d\xE9fausse sa main//et pioche 4 cartes.";
FrenchCardTexts[CardNames.PATROL] = "|+3 Cartes|////D\xE9voilez les 4 premi\xE8res cartes de//votre pioche. Prenez en main les//cartes Victoire et Mal\xE9diction.//Replacez les autres sur votre//pioche dans l'ordre de votre choix.";
FrenchCardTexts[CardNames.PAWN] = "Choisissez deux options : //|+1 Carte|; |+1 Action|;//|+1 Achat|; +[1].//Les choix doivent \xEAtre diff\xE9rents.";
FrenchCardTexts[CardNames.REPLACE] = "\xC9cartez une carte de votre main.//Recevez une carte co\xFBtant jusqu'\xE0//[2] de plus. Si la carte re\xE7ue est une//Action ou un Tr\xE9sor, placez-la sur//votre pioche; si c'est une carte//Victoire, tous vos adversaires//re\xE7oivent une Mal\xE9diction.";
FrenchCardTexts[CardNames.SECRET_PASSAGE] = "|+2 Cartes|//|+1 Action|////Prenez une carte de votre main et//placez-la o\xF9 vous voulez dans//votre pioche.";
FrenchCardTexts[CardNames.SHANTY_TOWN] = "|+2 Actions|////D\xE9voilez votre main.//Si vous n'avez aucune carte//Action en main, |+2 Cartes|.";
FrenchCardTexts[CardNames.STEWARD] = "Choisissez : |+2 Cartes|; ou +[2];//ou \xE9cartez 2 cartes de votre main.";
FrenchCardTexts[CardNames.SWINDLER] = "|+|[2]////Tous vos adversaires \xE9cartent//la carte du haut de leur pioche et//re\xE7oivent une carte de m\xEAme co\xFBt//de votre choix.";
FrenchCardTexts[CardNames.TORTURER] = "|+3 Cartes|////Tous vos adversaires d\xE9faussent//2 cartes, ou re\xE7oivent en main//une Mal\xE9diction, \xE0 leur choix.//(Ils peuvent choisir une option//qu'ils ne peuvent pas r\xE9aliser.)";
FrenchCardTexts[CardNames.TRADING_POST] = "\xC9cartez 2 cartes de votre main.//Dans ce cas, recevez un Argent//dans votre main.";
FrenchCardTexts[CardNames.UPGRADE] = "|+1 Carte|//|+1 Action|////\xC9cartez une carte de votre main.//Recevez une carte co\xFBtant//exactement [1] de plus.";
FrenchCardTexts[CardNames.WISHING_WELL] = "|+1 Carte|//|+1 Action|////Nommez une carte, puis d\xE9voilez la//carte du haut de votre pioche. Si le//nom correspond, prenez-la en main.";
FrenchCardTexts[CardNames.AMBASSADOR] = "D\xE9voilez une carte de votre main.//Replacez, de votre main \xE0 la//r\xE9serve, jusqu'\xE0 2 exemplaires//de cette carte. Ensuite, tous vos//adversaires re\xE7oivent un//exemplaire de cette carte.";
FrenchCardTexts[CardNames.BAZAAR] = "|+1 Carte|//|+2 Actions|//|+[1]|";
FrenchCardTexts[CardNames.CARAVAN] = "|+1 Carte|//|+1 Action|////Au d\xE9but de votre prochain//tour, |+1 Carte|.";
FrenchCardTexts[CardNames.CUTPURSE] = "+[2]////Tous vos adversaires d\xE9faussent//un Cuivre (ou d\xE9voilent une//main sans Cuivre).";
FrenchCardTexts[CardNames.EMBARGO] = "+[2]////\xC9cartez ceci pour placer un jeton//Embargo sur une pile de la r\xE9serve.//(Pour la suite de la partie, quand//un joueur ach\xE8te une carte de cette//pile, il re\xE7oit une Mal\xE9diction.)";
FrenchCardTexts[CardNames.EXPLORER] = "Vous pouvez d\xE9voiler une//Province de votre main.//Si vous le faites, recevez un Or//en main. Sinon, recevez un//Argent en main.";
FrenchCardTexts[CardNames.FISHING_VILLAGE] = "|+2 Actions|//|+[1]|////Au d\xE9but de votre prochain tour ://|+1 Action| et |+|[1]";
FrenchCardTexts[CardNames.GHOST_SHIP] = "|+2 Cartes|////Tous vos adversaires ayant au//moins 4 cartes en main placent//des cartes de leur main sur leur//pioche jusqu'\xE0 avoir 3 cartes//en main.";
FrenchCardTexts[CardNames.HAVEN] = "|+3 Carte|//|+1 Action|////Mettez de c\xF4t\xE9 une carte de votre//main face cach\xE9e (sous cette carte).//Au d\xE9but de votre prochain tour,//prenez-la en main.";
FrenchCardTexts[CardNames.ISLAND] = "{!2}//---//Placez cette carte et une carte de//votre main sur votre plateau \xCEle.";
FrenchCardTexts[CardNames.LIGHTHOUSE] = "|+1 Action|////Maintenant et au d\xE9but de//votre prochain tour, |+|[1].//---//D'ici l\xE0,//les cartes Attaque jou\xE9es par vos//adversaires ne vous affectent pas.";
FrenchCardTexts[CardNames.LOOKOUT] = "|+1 Action|////Consultez les 3 premi\xE8res cartes//de votre pioche. \xC9cartez-en une.//D\xE9faussez-en une. Placez la carte//restante sur le haut de votre pioche.";
FrenchCardTexts[CardNames.MERCHANT_SHIP] = "Maintenant et au d\xE9but de//votre prochain tour, |+|[2].";
FrenchCardTexts[CardNames.NATIVE_VILLAGE] = "|+2 Actions|////Choisissez : placez la carte du haut//de votre pioche, face cach\xE9e, sur//votre plateau Village indig\xE8ne//vous pouvez consulter ces cartes//\xE0 tout moment) ; ou prenez en//main toutes les cartes du plateau.";
FrenchCardTexts[CardNames.NAVIGATOR] = "+[2]////Consultez les 5 premi\xE8res cartes//de votre pioche. D\xE9faussez-les//toutes ou replacez-les sur votre//pioche dans l'ordre de votre choix.";
FrenchCardTexts[CardNames.OUTPOST] = "Piochez seulement 3 cartes//pour votre prochaine main.// Jouez un tour suppl\xE9mentaire//apr\xE8s celui-ci (mais pas//un troisi\xE8me cons\xE9cutif).";
FrenchCardTexts[CardNames.PEARL_DIVER] = "|+1 Carte|//|+1 Action|////Consultez la carte du bas de//votre pioche. Vous pouvez la//placer sur le haut.";
FrenchCardTexts[CardNames.PIRATE_SHIP] = "Choisissez : +[1] par jeton Pi\xE8ce//sur votre plateau Bateau pirate ; ou//tous vos adversaires d\xE9voilent les 2//premi\xE8res cartes de leur pioche, \xE9car-//tent un Tr\xE9sor d\xE9voil\xE9 de votre choix//et d\xE9faussent le reste, et si au moins un//Tr\xE9sor a \xE9t\xE9 \xE9cart\xE9, placez un jeton//Pi\xE8ce sur votre plateau Bateau pirate.";
FrenchCardTexts[CardNames.SALVAGER] = "|+1 Achat|////\xC9cartez une carte de votre main.//|+|[1] par [1] de son co\xFBt.";
FrenchCardTexts[CardNames.SEA_HAG] = "Tous vos adversaires d\xE9faussent//la carte du haut de leur pioche,//puis re\xE7oivent une Mal\xE9diction//sur leur pioche.";
FrenchCardTexts[CardNames.SMUGGLERS] = "Recevez un exemplaire d'une//carte co\xFBtant jusqu'\xE0 [6] que le//joueur \xE0 votre droite a re\xE7ue//\xE0 son dernier tour.";
FrenchCardTexts[CardNames.TACTICIAN] = "Si vous avez au moins une//carte en main, d\xE9faussez votre//main, et au d\xE9but de votre//prochain tour, |+5 Cartes|,//|+1 Action|, et |+1 Achat|.";
FrenchCardTexts[CardNames.TREASURE_MAP] = "\xC9cartez ceci et une Carte aux//tr\xE9sors de votre main. Si vous//avez \xE9cart\xE9 deux Cartes aux//tr\xE9sors, recevez 4 Ors sur//votre pioche.";
FrenchCardTexts[CardNames.TREASURY] = "|+1 Carte|//|+1 Action|//|+[1]|////A la fin de votre phase Achat,//si vous n'avez pas re\xE7u de carte Victoire//durant celle-ci, vous pouvez//placer cette carte sur votre pioche.";
FrenchCardTexts[CardNames.WAREHOUSE] = "|+ 3 Cartes|//|+1 Action|////D\xE9faussez 3 cartes.";
FrenchCardTexts[CardNames.WHARF] = "Maintenant et au d\xE9but//de votre prochain tour ://|+2 Cartes |et |+1 Achat|.";
FrenchCardTexts[CardNames.ALCHEMIST] = "|+2 Cartes|//|+1 Action|////Au d\xE9but de votre phase//Ajustement, si vous avez une//Potion en jeu, vous pouvez placer//cette carte sur votre pioche.";
FrenchCardTexts[CardNames.APOTHECARY] = "|+1 Carte|//|+1 Action|////D\xE9voilez les 4 premi\xE8res cartes de//votre pioche. Prenez en main les//Cuivres et les Potions. Replacez le//reste dans l'ordre de votre choix.";
FrenchCardTexts[CardNames.APPRENTICE] = "|+1 Action|////\xC9cartez une carte de votre main.//|+1 Carte| par [] de son co\xFBt.//|+2 Cartes| si [P] dans son co\xFBt.";
FrenchCardTexts[CardNames.FAMILIAR] = "|+1 Carte|//|+1 Action|////Tous vos adversaires re\xE7oivent//une Mal\xE9diction.";
FrenchCardTexts[CardNames.GOLEM] = "D\xE9voilez des cartes de votre//pioche jusqu'\xE0 d\xE9voiler 2 cartes//Action autre que des Golems.//D\xE9faussez les autres cartes, puis//jouez les cartes Action dans// l'ordre de votre choix.";
FrenchCardTexts[CardNames.HERBALIST] = "|+1 Achat|//|+[1]|////Une fois \xE0 ce tour, quand vous//d\xE9faussez un Tr\xE9sor de votre//zone de jeu, vous pouvez//le placer sur votre pioche.";
FrenchCardTexts[CardNames.PHILOSOPHERS_STONE] = "Comptez le nombre de cartes de//votre pioche et de votre d\xE9fausse.//+[1] par tranche de 5 cartes//au total (arrondi inf\xE9rieurement).";
FrenchCardTexts[CardNames.POSSESSION] = "Le joueur \xE0 votre gauche joue un tour//suppl\xE9mentaire apr\xE8s celui-ci (mais//pas un second cons\xE9cutif) pendant//lequel vous voyez ses cartes et prenez//les d\xE9cisions pour lui. Vous recevez \xE0//sa place les cartes et jetons [D] qu'il//aurait re\xE7us ; ses cartes \xE9cart\xE9es sont//mises de c\xF4t\xE9 et plac\xE9es dans sa//d\xE9fausse \xE0 la fin de son tour.";
FrenchCardTexts[CardNames.POTION] = "";
FrenchCardTexts[CardNames.SCRYING_POOL] = "|+1 Action|////Tous les joueurs (y compris vous)//d\xE9voilent la carte du haut de leur pioche//et la d\xE9faussent ou la replacent (votre//choix). Ensuite, d\xE9voilez des cartes de//votre pioche jusqu'\xE0 d\xE9voiler une carte//qui n'est pas une Action. Prenez en main//les cartes d\xE9voil\xE9es.";
FrenchCardTexts[CardNames.TRANSMUTE] = "\xC9cartez une carte de votre main.//Si c'est une carte...//Action, recevez un Duch\xE9//Tr\xE9sor, recevez une Transmutation//Victoire, recevez un Or";
FrenchCardTexts[CardNames.UNIVERSITY] = "|+2 Actions|////Vous pouvez recevoir une carte//Action co\xFBtant jusqu'\xE0 [5].";
FrenchCardTexts[CardNames.VINEYARD] = "Vaut {1} pour chaque lot de//3 cartes Action que vous avez//(arrondi inf\xE9rieurement).";
FrenchCardTexts[CardNames.BANK] = "+[1] par carte Tr\xE9sor que//vous avez en jeu//(y compris cette carte).";
FrenchCardTexts[CardNames.BISHOP] = "|+{1}|//|+[1]|////\xC9cartez une carte de votre main.//|+|{1} par [2] de son co\xFBt (arrondi//// inf\xE9rieurement). Tous vos adversaires//peuvent \xE9carter une carte de leur main.";
FrenchCardTexts[CardNames.COLONY] = "";
FrenchCardTexts[CardNames.CONTRABAND] = "[!3]////|+1 Achat|////Le joueur \xE0 votre gauche//nomme une carte. Vous ne pouvez pas//acheter cette carte \xE0 ce tour.";
FrenchCardTexts[CardNames.COUNTING_HOUSE] = "Regardez dans votre d\xE9fausse,//d\xE9voilez autant de Cuivres que//vous le voulez, et prenez-les//en main.";
FrenchCardTexts[CardNames.CITY] = "|+1 Carte|//|+2 Actions|////Si au moins une pile de la r\xE9serve//est vide, |+1 Carte|. Si au moins 2//piles sont vides, |+1 Achat| et |+|[1].";
FrenchCardTexts[CardNames.EXPAND] = "\xC9cartez une carte de votre main.//Recevez une carte co\xFBtant//jusqu'\xE0 [3] de plus.";
FrenchCardTexts[CardNames.FORGE] = "\xC9cartez autant de cartes de votre//main que vous souhaitez.//Recevez une carte dont le co\xFBt//est \xE9gal au co\xFBt total en []//des cartes \xE9cart\xE9es.";
FrenchCardTexts[CardNames.GRAND_MARKET] = "|+1 Carte|//|+1 Action|//|+1 Achat|//|+[2]|//---//Vous ne pouvez pas acheter cette//carte si vous avez un Cuivre en jeu.";
FrenchCardTexts[CardNames.GOONS] = "|+1 Achat|//|+[2]|////Tous vos adversaires d\xE9faussent//jusqu'\xE0 avoir 3 cartes en main.//---//Tant que vous avez cette carte en jeu,//si vous achetez une carte, |+|{1}.";
FrenchCardTexts[CardNames.HOARD] = "[!2]//---//Tant que vous avez cette carte//en jeu, quand vous achetez une//carte Victoire, recevez un Or.";
FrenchCardTexts[CardNames.KINGS_COURT] = "Vous pouvez jouer trois fois//une carte Action de votre main.";
FrenchCardTexts[CardNames.LOAN] = "[!1]////D\xE9voilez des cartes de votre pioche//jusqu'\xE0 d\xE9voiler une carte Tr\xE9sor.//D\xE9faussez-la ou \xE9cartez-la.//D\xE9faussez les autres cartes.";
FrenchCardTexts[CardNames.MINT] = "Vous pouvez d\xE9voiler une carte//Tr\xE9sor de votre main. Recevez-en//un exemplaire.//---//Quand vous recevez cette carte,//\xE9cartez tous vos Tr\xE9sors en jeu.";
FrenchCardTexts[CardNames.MONUMENT] = "|+{1}|//|+[2]|";
FrenchCardTexts[CardNames.MOUNTEBANK] = "|+[2]|////Tous vos adversaires peuvent//d\xE9fausser une Mal\xE9diction.//S'ils ne le font, pas ils re\xE7oivent//une Mal\xE9diction et un Cuivre.";
FrenchCardTexts[CardNames.PEDDLER] = "|+1 Carte|//|+1 Action|//|+[1]|//---//Pendant la phase Achat d'un joueur,//cette carte co\xFBte [2] de moins//par carte Action qu'il a en jeu.";
FrenchCardTexts[CardNames.PLATINUM] = "";
FrenchCardTexts[CardNames.QUARRY] = "[!1]//---//Lorsque cette carte est en jeu,//les cartes Actions co\xFBtent [2]//de moins.";
FrenchCardTexts[CardNames.RABBLE] = "|+3 Cartes|////Tous vos adversaires d\xE9voilent les//3 premi\xE8res cartes de leur pioche,//d\xE9faussent les cartes Action et //Tr\xE9sor et replacent les autres dans//l'ordre de leur choix.";
FrenchCardTexts[CardNames.ROYAL_SEAL] = "[!2]//---//Tant que vous avez cette carte en jeu,//quand vous recevez une carte, vous//pouvez la placer sur votre pioche.";
FrenchCardTexts[CardNames.TALISMAN] = "[!1]//Tant que vous avez cette carte en jeu,//quand vous achetez une carte//non-Victoire co\xFBtant jusqu'\xE0 [!4],//recevez-en un autre exemplaire.";
FrenchCardTexts[CardNames.TRADE_ROUTE] = "|+1 Achat|////\xC9cartez une carte de votre main. |+|[1] par//Pi\xE8ce sur le plateau Route Commerciale.//---//Mise en place : placez une Pi\xE8ce sur chaque//pile de cartes Victoire de la r\xE9serve; d\xE9pla-//cez-la vers le plateau Route Commerciale//lorsqu'une carte de cette pile est re\xE7ue.";
FrenchCardTexts[CardNames.VAULT] = "|+2 Cartes|////D\xE9faussez autant de cartes que//vous voulez pour |+|[1] chacune.//Tous vos adversaires peuvent//d\xE9fausser 2 cartes pour//piocher 1 carte.";
FrenchCardTexts[CardNames.VENTURE] = "[!1]////D\xE9voilez des cartes de votre pioche//jusqu'\xE0 d\xE9voiler une carte Tr\xE9sor.//D\xE9faussez les autres cartes, puis//jouez la carte Tr\xE9sor.";
FrenchCardTexts[CardNames.WATCHTOWER] = "Piochez jusqu'\xE0 avoir 6 cartes//en main.//---//Quand vous recevez une carte,//vous pouvez d\xE9voiler cette carte de//votre main, pour \xE9carter ou placer//sur votre pioche la carte re\xE7ue.";
FrenchCardTexts[CardNames.WORKERS_VILLAGE] = "|+1 Carte|//|+2 Actions|//|+1 Achat|";
FrenchCardTexts[CardNames.PRIZE_PILE] = "";
FrenchCardTexts[CardNames.BAG_OF_GOLD] = "|+1 Action|////Recevez un Or sur votre pioche.//%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.DIADEM] = "[!2]//////+[!1] par Action inutilis\xE9e//(Action, et non carte Action).////%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.FAIRGROUNDS] = "Vaut {2} pour chaque 5 cartes de//noms diff\xE9rents que vous avez//(arrondi inf\xE9rieurement).";
FrenchCardTexts[CardNames.FARMING_VILLAGE] = "|+2 Actions|////D\xE9voilez des cartes de votre pioche//jusqu'\xE0 d\xE9voiler une carte Tr\xE9sor//ou Action. Prenez en main cette//carte et d\xE9faussez les autres.";
FrenchCardTexts[CardNames.FOLLOWERS] = "|+2 Cartes|////Recevez un Domaine. Tous vos ad-//versaires re\xE7oivent une Mal\xE9diction//et d\xE9faussent jusqu'\xE0 avoir 3 cartes//en main.//%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.FORTUNE_TELLER] = "+[2]//////Tous vos adversaires d\xE9voilent des//cartes de leur pioche jusqu'\xE0 d\xE9voiler//une carte Victoire ou une//Mal\xE9diction. Ils la replacent et//d\xE9faussent les autres cartes.";
FrenchCardTexts[CardNames.HAMLET] = "|+1 Carte|//|+1 Action|////Vous pouvez d\xE9fausser une carte//pour |+1 Action|.//Vous pouvez d\xE9fausser une carte//pour |+1 Achat|.";
FrenchCardTexts[CardNames.HARVEST] = "D\xE9voilez les 4 premi\xE8res cartes//de votre pioche, puis d\xE9faussez-les.//+[1] par carte d\xE9voil\xE9e de//nom diff\xE9rent.";
FrenchCardTexts[CardNames.HORSE_TRADERS] = "|+1 Achat|//|+[3]|//D\xE9faussez deux cartes.//---//Quand un adversaire joue une carte//Attaque, vous pouvez d'abord mettre de//c\xF4t\xE9 cette carte de votre main. Dans ce//cas, au d\xE9but de votre prochain tour,//+|1 Carte| et reprenez en main cette carte.";
FrenchCardTexts[CardNames.HORN_OF_PLENTY] = "Recevez une carte co\xFBtant//jusqu'\xE0 [1] par carte en jeu//de nom diff\xE9rent (y compris cette//carte). Si la carte re\xE7ue est une//carte Victoire, \xE9cartez cette carte.";
FrenchCardTexts[CardNames.HUNTING_PARTY] = "|+1 Carte|//|+1 Action|////D\xE9voilez votre main. D\xE9voilez des//cartes de votre pioche jusqu'\xE0 d\xE9voiler//une carte dont vous n'ayez pas un//exemplaire en main. Prenez-la//en main et d\xE9faussez le reste.";
FrenchCardTexts[CardNames.JESTER] = "+[2]////Tous vos adversaires d\xE9faussent la//carte du haut de leur pioche. Si//c'est une carte Victoire, ils//re\xE7oivent une Mal\xE9diction; sinon//d\xE9cidez qui en re\xE7oit un exemplaire ://vous ou l'adversaire.";
FrenchCardTexts[CardNames.MENAGERIE] = "|+1 Action|////D\xE9voilez votre main.//Si les cartes d\xE9voil\xE9es ont toutes// des noms diff\xE9rents, |+3 Cartes|.//Sinon, |+1 Carte.|";
FrenchCardTexts[CardNames.PRINCESS] = "|+1 Achat|////\xC0 ce tour, les cartes//co\xFBtent [2] de moins.////%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.REMAKE] = "Faites ceci deux fois : \xE9cartez une//carte de votre main, puis recevez//une carte co\xFBtant exactement [1]//de plus.";
FrenchCardTexts[CardNames.TOURNAMENT] = "|+1 Action|////Tous les joueurs peuvent d\xE9voiler//une Province de leur main.//Si vous le faites, d\xE9faussez-la et//recevez sur votre pioche un Prix (de la//pile des Prix) ou un Duch\xE9. Si personne//d'autre ne le fait, |+1 Carte| et +[1].";
FrenchCardTexts[CardNames.TRUSTY_STEED] = "Choisissez deux : |+2 Cartes|;//|+2 Actions|; +[2]; recevez 4//Argents et mettez votre pioche//dans votre d\xE9fausse. Les choix//doivent \xEAtre diff\xE9rents.//%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.YOUNG_WITCH] = "|+2 Cartes|////D\xE9faussez 2 cartes. Tous vos adversaires//re\xE7oivent une Mal\xE9diction a moins//de d\xE9voiler une carte Fl\xE9au de leur main.//---//Mise en place : ajoutez \xE0 la r\xE9serve une pile//Royaume suppl\xE9mentaire co\xFBtant [2] ou [3].//Les cartes de cette pile sont les cartes Fl\xE9au.";
FrenchCardTexts[CardNames.BORDER_VILLAGE] = "|+1 Carte|//|+2 Actions|//---//Quand vous recevez cette carte,//recevez une carte moins ch\xE8re.";
FrenchCardTexts[CardNames.CACHE] = "[!3]//---//Quand vous recevez cette carte,//recevez deux Cuivres.";
FrenchCardTexts[CardNames.CARTOGRAPHER] = "|+1 Carte|//|+1 Action|////Consultez les 4 premi\xE8res cartes// de votre pioche. D\xE9faussez-en//autant que souhait\xE9, et replacez les//autres dans l'ordre de votre choix.";
FrenchCardTexts[CardNames.CROSSROADS] = "D\xE9voilez votre main.| +1 Carte|//par carte Victoire d\xE9voil\xE9e.//Si c'est la premi\xE8re fois que//vous jouez un Carrefour//\xE0 ce tour,| +3 Actions|.";
FrenchCardTexts[CardNames.DEVELOP] = "\xC9cartez une carte de votre main.//Recevez deux cartes sur votre//pioche, une co\xFBtant exactement//[1] de plus, et une co\xFBtant//exactement [1] de moins,//dans l'ordre de votre choix.";
FrenchCardTexts[CardNames.DUCHESS] = "+[2]////Tous les joueurs (vous inclus)//consultent la carte du haut de leur//pioche et peuvent la d\xE9fausser.//---//Si la Duchesse est dans le royaume,//vous pouvez en recevoir une//lorsque vous recevez un Duch\xE9.";
FrenchCardTexts[CardNames.EMBASSY] = "|+5 Cartes|////D\xE9faussez 3 cartes.//---//Lorsque vous recevez cette carte,//tous vos adversaires re\xE7oivent//un Argent.";
FrenchCardTexts[CardNames.FARMLAND] = "{!2}//---//Lorsque vous achetez cette carte,//\xE9cartez une carte de votre main et//recevez une carte co\xFBtant//exactement [2] de plus.";
FrenchCardTexts[CardNames.FOOLS_GOLD] = "Si c'est la premi\xE8re fois que//que vous jouez un Or des Fous \xE0//ce tour, +[1]. Sinon, +[4].//---//Quand un autre joueur re\xE7oit une//Province, vous pouvez \xE9carter//cette carte de votre main, pour//recevoir un Or sur votre pioche.";
FrenchCardTexts[CardNames.HAGGLER] = "|+[2]|//---//Tant que vous avez cette carte//en jeu, quand vous achetez//une carte, recevez une carte//non-Victoire moins ch\xE8re.";
FrenchCardTexts[CardNames.HIGHWAY] = "|+1 Carte|//|+1 Action|//---//Tant que cette carte est en jeu, les//cartes co\xFBtent [1] de moins.";
FrenchCardTexts[CardNames.ILL_GOTTEN_GAINS] = "[!1]////Vous pouvez recevoir un Cuivre en main.//---//Lorsque vous recevez cette carte, tous//vos adversaires re\xE7oivent une Mal\xE9diction.";
FrenchCardTexts[CardNames.INN] = "|+2 Cartes|//|+2 Actions|////D\xE9faussez 2 cartes.//---//Lorsque vous recevez cette carte, consultez//votre d\xE9fausse, d\xE9voilez-en autant de cartes//Action que souhait\xE9 (y compris celle-ci), et//m\xE9langez-les \xE0 votre pioche.";
FrenchCardTexts[CardNames.JACK_OF_ALL_TRADES] = "Recevez un Argent.////Consultez la carte du haut de votre//pioche; vous pouvez la d\xE9fausser.////Piochez jusqu'\xE0 avoir 5 cartes//en main.////Vous pouvez \xE9carter une carte//non-Tr\xE9sor de votre main.";
FrenchCardTexts[CardNames.MANDARIN] = "|+|[3]////Placez une carte de votre main//sur votre pioche.//---//Lorsque vous recevez cette carte,//placez vos Tr\xE9sors en jeu sur votre//pioche, dans l'ordre de votre choix.";
FrenchCardTexts[CardNames.NOBLE_BRIGAND] = "|+|[1]////Tous vos adversaires d\xE9voilent les 2//premi\xE8res cartes de leur pioche, \xE9cartent//un Argent ou Or d\xE9voil\xE9 que vous//choisissez, d\xE9faussent le reste, et//re\xE7oivent un Cuivre s'ils n'ont pas d\xE9voil\xE9//de Tr\xE9sor. Vous recevez les cartes \xE9cart\xE9es.//---//Quand vous achetez cette carte,//effectuez son attaque";
FrenchCardTexts[CardNames.NOMAD_CAMP] = "|+1 Achat|//|+[2]|//---//Cette carte est re\xE7ue sur votre//pioche (au lieu de votre d\xE9fausse).";
FrenchCardTexts[CardNames.OASIS] = "|+1 Carte|//|+1 Action|//|+[1]|////D\xE9faussez une carte.";
FrenchCardTexts[CardNames.ORACLE] = "Chaque joueur (vous compris)//d\xE9voile les deux premi\xE8res cartes//de sa pioche et, selon votre choix,//les d\xE9fausse ou les replace dans//l'ordre de son choix.//Ensuite, |+2 Cartes|.";
FrenchCardTexts[CardNames.MARGRAVE] = "|+3 Cartes|//|+1 Achat|////Tous vos adversaires piochent//une carte, puis d\xE9faussent//jusqu'\xE0 avoir 3 cartes en main.";
FrenchCardTexts[CardNames.SCHEME] = "|+1 Carte|//|+1 Action|////A ce tour, vous pouvez replacer//une carte Action sur votre pioche//quand vous la d\xE9faussez//de votre zone de jeu.";
FrenchCardTexts[CardNames.SILK_ROAD] = "Vaut {1} pour chaque 4 cartes//Victoire que vous avez//(arrondi inf\xE9rieurement).";
FrenchCardTexts[CardNames.SPICE_MERCHANT] = "Vous pouvez \xE9carter une carte//Tr\xE9sor de votre main pour choisir//|+2 Cartes| et| +1 Action|;//ou| +|[2] et| +1 Achat|.";
FrenchCardTexts[CardNames.STABLES] = "Vous pouvez d\xE9fausser//une carte Tr\xE9sor pour//|+3 Cartes| et| +1 Action|.";
FrenchCardTexts[CardNames.TRADER] = "\xC9cartez une carte de votre main.//Recevez un Argent par [1] de son co\xFBt.//---//Lorsque vous recevez une carte,//vous pouvez d\xE9voiler cette carte//de votre main pour \xE9changer//la carte re\xE7ue contre un Argent.";
FrenchCardTexts[CardNames.TUNNEL] = "{!2}//---//Quand vous d\xE9faussez cette carte en//dehors de la phase Ajustement, vous//pouvez la d\xE9voiler pour recevoir un Or.";
FrenchCardTexts[CardNames.RUIN_PILE] = "";
FrenchCardTexts[CardNames.KNIGHTS] = "";
FrenchCardTexts[CardNames.ABANDONED_MINE] = "|+|[1]";
FrenchCardTexts[CardNames.ALTAR] = "\xC9cartez une carte de votre main.//Recevez une carte co\xFBtant//jusqu'\xE0 [5].";
FrenchCardTexts[CardNames.ARMORY] = "Recevez sur votre pioche//une carte co\xFBtant jusqu'\xE0 [4].";
FrenchCardTexts[CardNames.BAND_OF_MISFITS] = "Jouez une carte Action non-Ordre de//la r\xE9serve moins ch\xE8re que celle-ci,//en la laissant dans la r\xE9serve.";
FrenchCardTexts[CardNames.BANDIT_CAMP] = "|+1 Carte|//|+2 Actions|////Recevez un Butin//de la pile des Butins.";
FrenchCardTexts[CardNames.BEGGAR] = "Recevez 3 Cuivres en main.//---//Quand un adversaire joue une//carte Attaque, vous pouvez d'abord//d\xE9fausser cette carte pour recevoir//2 Argents, dont 1 sur votre pioche.";
FrenchCardTexts[CardNames.CATACOMBS] = "Consultez les 3 premi\xE8res cartes de//votre pioche. Choisissez : prenez-les en//main, ou d\xE9faussez-les et |+3 Cartes|.//---//Lorsque vous \xE9cartez cette carte,//recevez une carte moins ch\xE8re.";
FrenchCardTexts[CardNames.COUNT] = "Choisissez : d\xE9faussez 2 cartes ;//ou placez une carte de votre main sur//votre pioche , ou recevez un Cuivre.////Choisissez : +[3]; ou \xE9cartez//votre main ; ou recevez un Duch\xE9.";
FrenchCardTexts[CardNames.COUNTERFEIT] = "[!1]////|+1 Achat|////Vous pouvez jouez deux fois une//carte Tr\xE9sor non-Dur\xE9e de votre main.//\xC9cartez cette derni\xE8re.";
FrenchCardTexts[CardNames.CULTIST] = "|+2 Cartes|////Tous vos adversaires re\xE7oivent//une Ruine. Vous pouvez jouer//un Cultiste de votre main.//---//Lorsque vous \xE9cartez cette carte,//|+3 Cartes|.";
FrenchCardTexts[CardNames.DAME_ANNA] = "Vous pouvez \xE9carter jusqu'\xE0//deux cartes de votre main.////Tous vos adversaires d\xE9voilent les//deux cartes du haut de leur pioche, en//\xE9cartent une co\xFBtant entre [3] et [6],//et d\xE9faussent le reste. Si un Chevalier//a \xE9t\xE9 \xE9cart\xE9, \xE9cartez cette carte.";
FrenchCardTexts[CardNames.DAME_JOSEPHINE] = "{!2}//---//Tous vos adversaires d\xE9voilent les//deux cartes du haut de leur pioche, en//\xE9cartent une co\xFBtant entre [3] et [6],//et d\xE9faussent le reste. Si un Chevalier//a \xE9t\xE9 \xE9cart\xE9, \xE9cartez cette carte.";
FrenchCardTexts[CardNames.DAME_MOLLY] = "|+2 Actions|////Tous vos adversaires d\xE9voilent les//deux cartes du haut de leur pioche, en//\xE9cartent une co\xFBtant entre [3] et [6],//et d\xE9faussent le reste. Si un Chevalier//a \xE9t\xE9 \xE9cart\xE9, \xE9cartez cette carte.";
FrenchCardTexts[CardNames.DAME_NATALIE] = "Recevez une carte//co\xFBtant jusqu'\xE0 [3].////Tous vos adversaires d\xE9voilent les//deux cartes du haut de leur pioche, en//\xE9cartent une co\xFBtant entre [3] et [6],//et d\xE9faussent le reste. Si un Chevalier//a \xE9t\xE9 \xE9cart\xE9, \xE9cartez cette carte.";
FrenchCardTexts[CardNames.DAME_SYLVIA] = "+[2]////Tous vos adversaires d\xE9voilent les//deux cartes du haut de leur pioche, en//\xE9cartent une co\xFBtant entre [3] et [6],//et d\xE9faussent le reste. Si un Chevalier//a \xE9t\xE9 \xE9cart\xE9, \xE9cartez cette carte.";
FrenchCardTexts[CardNames.DEATH_CART] = "Vous pouvez \xE9carter cette carte//ou une carte Action de votre main//pour +[5].//---//Lorsque vous recevez cette carte,//recevez 2 ruines.";
FrenchCardTexts[CardNames.FEODUM] = "Vaut {1} pour chaque 3 Argents//que vous avez (arrondi inf\xE9rieurement).//---//Quand vous \xE9cartez cette carte,//recevez 3 Argents.";
FrenchCardTexts[CardNames.FORAGER] = "|+1 Action|//|+1 Achat|////\xC9cartez une carte de votre main,//puis |+|[1] par carte Tr\xE9sor de//nom diff\xE9rent dans le rebut.";
FrenchCardTexts[CardNames.FORTRESS] = "|+1 Carte|//|+2 Actions|//---//Quand vous \xE9cartez cette carte,//prenez-la en main.";
FrenchCardTexts[CardNames.GRAVEROBBER] = "Choisissez : recevez sur votre//pioche une carte du rebut co\xFBtant//entre [3] et [6]; ou \xE9cartez une//carte Action de votre main et//recevez une carte co\xFBtant//jusqu'\xE0 [3] de plus.";
FrenchCardTexts[CardNames.HERMIT] = "Consultez votre d\xE9fausse. Vous//pouvez \xE9carter une carte non-Tr\xE9sor//de votre d\xE9fausse ou de votre main.//Recevez une carte co\xFBtant jusqu'\xE0 [3].//\xC0 ce tour, \xE0 la fin de votre//phase Achat, si vous n'avez pas re\xE7u//de carte durant celle-ci, \xE9changez//cette carte contre un Fou.";
FrenchCardTexts[CardNames.HOVEL] = "Quand vous recevez une carte//Victoire, vous pouvez \xE9carter//cette carte de votre main.";
FrenchCardTexts[CardNames.HUNTING_GROUNDS] = "|+4 Cartes|//---//Quand vous \xE9cartez cette carte,//recevez un Duch\xE9 ou 3 Domaines.";
FrenchCardTexts[CardNames.IRONMONGER] = "|+1 Carte|//|+1 Action|//D\xE9voilez la carte du haut de votre//pioche. Vous pouvez la d\xE9fausser.//Dans tous les cas, si c'est une carte...//Action, |+1 Action|//Tr\xE9sor, |+|[1]//Victoire,|+1 Carte|";
FrenchCardTexts[CardNames.JUNK_DEALER] = "|+1 Carte|//|+1 Action|//|+[1]|////\xC9cartez une carte de votre main.";
FrenchCardTexts[CardNames.MADMAN] = "|+2 Actions|////Retournez cette carte sur la pile//des Fous. Dans ce cas, |+1 Carte|//par carte en main.//%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.MARKET_SQUARE] = "|+1 Carte|//|+1 Action|//|+1 Achat|//---//Quand une de vos cartes est \xE9cart\xE9e,//vous pouvez d\xE9fausser cette carte//de votre main pour recevoir un Or.";
FrenchCardTexts[CardNames.MARAUDER] = "Recevez un Butin de la pile des//Butins. Tous vos adversaires//re\xE7oivent une Ruine.";
FrenchCardTexts[CardNames.MERCENARY] = "Vous pouvez \xE9carter 2 cartes de//votre main. Dans ce cas, //|+2 Cartes|, +[2], et tous vos//adversaires d\xE9faussent jusqu'\xE0//avoir 3 cartes en main.//%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.MYSTIC] = "|+1 Action|//|+[2]|////Nommez une carte, puis d\xE9voilez la//carte du haut de votre pioche. Si le//nom correspond, prenez-la en main.";
FrenchCardTexts[CardNames.NECROPOLIS] = "|+2 Actions|";
FrenchCardTexts[CardNames.OVERGROWN_ESTATE] = "{!0}//---//Quand vous \xE9cartez cette carte, //|+1 Carte|.";
FrenchCardTexts[CardNames.PILLAGE] = "\xC9cartez cette carte. Dans ce cas,//Recevez 2 Butins, et tous vos//adversaires ayant au moins//5 cartes en main d\xE9voilent//leur main et d\xE9faussent//une carte de votre choix.";
FrenchCardTexts[CardNames.POOR_HOUSE] = "+[4]////D\xE9voilez votre main. -[1] par//carte Tr\xE9sor en main.//(Vous ne pouvez pas descendre//en dessous de [0].)";
FrenchCardTexts[CardNames.PROCESSION] = "Vous pouvez jouer deux fois une//carte Action non-Dur\xE9e de votre//main. \xC9cartez-la. Recevez une//carte Action co\xFBtant//exactement [1] de plus.";
FrenchCardTexts[CardNames.RATS] = "|+1 Carte|//|+1 Action|////Recevez un Rats. \xC9cartez une carte//de votre main autre qu'un Rats//(ou d\xE9voilez une main de Rats).//---//Quand vous \xE9cartez cette carte, //|+1 Carte|.";
FrenchCardTexts[CardNames.REBUILD] = "|+1 Action|////Nommez une carte. D\xE9voilez des cartes//de votre pioche jusqu'\xE0 d\xE9voiler une//carte Victoire diff\xE9rente de la carte//nomm\xE9e. D\xE9faussez le reste, \xE9cartez//la carte Victoire, puis recevez une carte//Victoire co\xFBtant jusqu'\xE0 [3] de plus.";
FrenchCardTexts[CardNames.ROGUE] = "+[2]////S'il y a dans le rebut des cartes co\xFBtant//entre [3] et [6], recevez-en une.//Sinon, tous vos adversaires d\xE9voilent//les deux premi\xE8res cartes de leur//pioche, en \xE9cartent une co\xFBtant entre//[3] et [6], et d\xE9faussent le reste.";
FrenchCardTexts[CardNames.RUINED_LIBRARY] = "|+1 Carte|";
FrenchCardTexts[CardNames.RUINED_MARKET] = "|+1 Achat|";
FrenchCardTexts[CardNames.RUINED_VILLAGE] = "|+1 Action|";
FrenchCardTexts[CardNames.SAGE] = "|+1 Action|////D\xE9voilez des cartes de votre pioche//jusqu'\xE0 d\xE9voiler une carte co\xFBtant//[3] ou plus. Prenez-la en main et//d\xE9faussez le reste";
FrenchCardTexts[CardNames.SCAVENGER] = "+[2]////Vous pouvez mettre votre pioche//dans votre d\xE9fausse. Consultez//votre d\xE9fausse et placez-en une//carte sur votre pioche.";
FrenchCardTexts[CardNames.SIR_BAILEY] = "|+1 Carte|//|+1 Action|////Tous vos adversaires d\xE9voilent les//deux cartes du haut de leur pioche, en//\xE9cartent une co\xFBtant entre [3] et [6],//et d\xE9faussent le reste. Si un Chevalier//a \xE9t\xE9 \xE9cart\xE9, \xE9cartez cette carte.";
FrenchCardTexts[CardNames.SIR_DESTRY] = "|+2 Cartes|////Tous vos adversaires d\xE9voilent les//deux cartes du haut de leur pioche, en//\xE9cartent une co\xFBtant entre [3] et [6],//et d\xE9faussent le reste. Si un Chevalier//a \xE9t\xE9 \xE9cart\xE9, \xE9cartez cette carte.";
FrenchCardTexts[CardNames.SIR_MARTIN] = "|+2 Achats|////Tous vos adversaires d\xE9voilent les//deux cartes du haut de leur pioche, en//\xE9cartent une co\xFBtant entre [3] et [6],//et d\xE9faussent le reste. Si un Chevalier//a \xE9t\xE9 \xE9cart\xE9, \xE9cartez cette carte.";
FrenchCardTexts[CardNames.SIR_MICHAEL] = "Tous vos adversaires d\xE9faussent//jusqu'\xE0 avoir 3 cartes en main.////Tous vos adversaires d\xE9voilent les//deux cartes du haut de leur pioche, en//\xE9cartent une co\xFBtant entre [3] et [6],//et d\xE9faussent le reste. Si un Chevalier//a \xE9t\xE9 \xE9cart\xE9, \xE9cartez cette carte.";
FrenchCardTexts[CardNames.SIR_VANDER] = "Tous vos adversaires d\xE9voilent les//deux cartes du haut de leur pioche, en//\xE9cartent une co\xFBtant entre [3] et [6],//et d\xE9faussent le reste. Si un Chevalier//a \xE9t\xE9 \xE9cart\xE9, \xE9cartez cette carte.//---//Quand vous \xE9cartez cette carte,//recevez un Or.";
FrenchCardTexts[CardNames.SPOILS] = "[!3]////Quand vous jouez cette carte,//retournez-la sur la pile des Butins.//%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.STOREROOM] = "|+1 Achat|////D\xE9faussez autant de cartes que//souhait\xE9, puis piochez-en autant.//Ensuite, d\xE9faussez autant de cartes//que souhait\xE9 pour +[1] par carte.";
FrenchCardTexts[CardNames.SQUIRE] = "+[1]////Choisissez : |+2 Actions|; //|+2 Achats|; ou recevez un Argent.//---//Quand vous \xE9cartez cette carte,//recevez une carte Attaque.";
FrenchCardTexts[CardNames.SURVIVORS] = "Consultez les deux premi\xE8res//cartes de votre pioche.//D\xE9faussez-les ou replacez-les//dans l'ordre de votre choix.";
FrenchCardTexts[CardNames.URCHIN] = "|+1 Carte|//|+1 Action|////Tous vos adversaires d\xE9faussent//jusqu'\xE0 avoir 4 cartes en main.//---//Quand vous jouez une autre carte Attaque//alors que cette carte est en jeu, vous pouvez//d'abord \xE9carter cette carte pour recevoir un//Mercenaire de la pile des Mercenaires.";
FrenchCardTexts[CardNames.VAGRANT] = "|+1 Carte|//|+1 Action|////D\xE9voilez la premi\xE8re carte de//votre pioche. Si c'est une//Mal\xE9diction, une Ruine, un//Refuge ou une carte//Victoire, prenez-la en main.";
FrenchCardTexts[CardNames.WANDERING_MINSTREL] = "|+1 Carte|//|+2 Actions|////D\xE9voilez les 3 premi\xE8res cartes//de votre pioche. Replacez les//cartes Action dans l'ordre de votre//choix et d\xE9faussez le reste.";
FrenchCardTexts[CardNames.ADVISOR] = "|+1 Action|////D\xE9voilez les 3 premi\xE8re cartes//de votre pioche. Le joueur \xE0 votre//gauche en choisit une.//D\xE9faussez-la et prenez en main//le reste.";
FrenchCardTexts[CardNames.BAKER] = "|+1 Carte|//|+1 Action|//|+1 Coffres|//---//Mise en place : pour tous les joueurs,//|+1 Coffres|.";
FrenchCardTexts[CardNames.BUTCHER] = "|+2 Coffres|////Vous pouvez \xE9carter une carte//de votre main pour recevoir//une carte co\xFBtant jusqu'\xE0 [1]//de plus par Coffres d\xE9pens\xE9.";
FrenchCardTexts[CardNames.CANDLESTICK_MAKER] = "|+1 Action|//|+1 Achat|//|+1 Coffres|";
FrenchCardTexts[CardNames.DOCTOR] = "Nommez une carte. D\xE9voilez les 3//premi\xE8res cartes de votre pioche. \xC9cartez//celles dont le nom correspond, et remettez//les autres sur votre pioche.//---//Surpaiement : pour chaque [] surpay\xE9,//consultez la carte du haut de votre pioche;//\xE9cartez-la, d\xE9faussez-la, ou replacez-la.";
FrenchCardTexts[CardNames.HERALD] = "|+1 Carte|//|+1 Action|//---//D\xE9voilez la carte du haut de votre//pioche. Si c'est une Action, jouez-la.////Surpaiement : pour chaque []//surpay\xE9, consultez votre d\xE9fausse et//placez-en une carte sur votre pioche.";
FrenchCardTexts[CardNames.JOURNEYMAN] = "Nommez une carte. D\xE9voilez des//cartes de votre pioche jusqu'\xE0//d\xE9voiler 3 cartes dont le nom//ne correspond pas. Prenez-les//en main et d\xE9faussez le reste.";
FrenchCardTexts[CardNames.MASTERPIECE] = "[!1]//---//Surpaiement : Pour chaque [1]//surpay\xE9, recevez un Argent.";
FrenchCardTexts[CardNames.MERCHANT_GUILD] = "|+1 Achat|//|+[1]|////A ce tour, \xE0 la fin de//votre phase Achat, |+1 Coffres|//par carte re\xE7ue durant cette phase";
FrenchCardTexts[CardNames.PLAZA] = "|+1 Carte|//|+2 Actions|////Vous pouvez d\xE9fausser une carte//Tr\xE9sor pour |+1 Coffres|";
FrenchCardTexts[CardNames.TAXMAN] = "Vous pouvez \xE9carter une carte//Tr\xE9sor de votre main. Tous vos//adversaires ayant au moins 5 cartes//en main en d\xE9faussent un exemplaire//(\xE0 d\xE9faut, d\xE9voilent leur main).//Recevez sur votre pioche une carte//Tr\xE9sor co\xFBtant jusqu'\xE0 [3] de plus.";
FrenchCardTexts[CardNames.SOOTHSAYER] = "Recevez un Or.//Tous vos adversaires re\xE7oivent//une Mal\xE9diction, et dans ce cas,//piochent une carte.";
FrenchCardTexts[CardNames.STONEMASON] = "\xC9cartez une carte de votre main.//Recevez 2 cartes de co\xFBt inf\xE9rieur.//---//Surpaiement : recevez 2 cartes//Action co\xFBtant chacune//exactement le montant surpay\xE9.";
FrenchCardTexts[CardNames.ALMS] = "Une fois par tour: si vous n'avez pas de carte Tr\xE9sor//en jeu, recevez une carte co\xFBtant jusqu'\xE0 [4].";
FrenchCardTexts[CardNames.AMULET] = "Maintenant et au d\xE9but de votre//prochain tour, choisissez : +[1];//ou \xE9cartez une carte de votre main;//ou recevez un Argent.";
FrenchCardTexts[CardNames.ARTIFICER] = "|+1 Carte|//|+1 Action|//|+[1]|////D\xE9faussez autant de cartes que//souhait\xE9. Vous pouvez recevoir sur//votre pioche une carte co\xFBtant//exactement [1] par carte d\xE9fauss\xE9e.";
FrenchCardTexts[CardNames.BALL] = "Prenez votre jeton -[1].//Recevez 2 cartes co\xFBtant chacune jusqu'\xE0 [4].";
FrenchCardTexts[CardNames.BONFIRE] = "\xC9cartez jusqu'\xE0 2 Cuivres en jeu.";
FrenchCardTexts[CardNames.BORROW] = "Une fois par tour : |+1 Achat|. Si votre jeton -1 Carte n'est//pas sur votre pioche, placez-le \xE0 cet endroit et |+|[1].";
FrenchCardTexts[CardNames.BRIDGE_TROLL] = "Tous vos adversaires prennent leur//jeton -[1]. Maintenant et au d\xE9but//de votre prochain tour : |+1 Achat|.//Durant ce tour et votre prochain tour,//les cartes co\xFBtent [1] de moins.";
FrenchCardTexts[CardNames.CARAVAN_GUARD] = "|+1 Carte|//|+1 Action|////Au d\xE9but de votre prochain tour, |+|[1].//---//Quand un adversaire joue une carte//Attaque, vous pouvez d'abord jouer//cette carte de votre main.";
FrenchCardTexts[CardNames.CHAMPION] = "|+1 Action|//Pour la suite de la partie, quand un//adversaire joue une carte Attaque,//vous n'\xEAtes pas affect\xE9, et quand//vous jouez une Action, |+1 Action|.//%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.COIN_OF_THE_REALM] = "[!1]////Quand vous jouez cette carte,//placez-la sur votre plateau Taverne.//---//Imm\xE9diatement apr\xE8s avoir fini de//jouer une carte Action, vous pouvez//recourir \xE0 cette carte pour |+2 Actions|.";
FrenchCardTexts[CardNames.DISCIPLE] = "Vous pouvez jouez deux fois//une carte Action de votre main.//Recevez-en un exemplaire.//---//Quand vous d\xE9faussez cette carte//de votre zone de jeu, vous pouvez//l'\xE9changer contre un Ma\xEEtre.//%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.DISTANT_LANDS] = "Placez cette carte//sur votre plateau taverne.//---//Cette carte vaut {4} si elle est//sur votre plateau Taverne \xE0 la fin//de la partie ({0} sinon).";
FrenchCardTexts[CardNames.DUNGEON] = "|+1 Action|////Maintenant et au d\xE9but de votre//prochain tour : |+2 Cartes|,//puis d\xE9faussez 2 cartes.";
FrenchCardTexts[CardNames.DUPLICATE] = "Placez cette carte//sur votre plateau Taverne.//---//Quand vous recevez une carte//co\xFBtant jusqu'\xE0 [6], vous pouvez//recourir \xE0 cette carte pour//en recevoir un exemplaire.";
FrenchCardTexts[CardNames.EXPEDITION] = "Piochez 2 cartes suppl\xE9mentaires//pour votre prochaine main.";
FrenchCardTexts[CardNames.FERRY] = "Placez votre jeton -[2] sur une pile de cartes Action//de la r\xE9serve. (Les cartes de cette pile co\xFBtent [2] de moins//\xE0 votre tour.)";
FrenchCardTexts[CardNames.FUGITIVE] = "|+2 Cartes|//|+1 Action|////D\xE9faussez une carte.//---//Quand vous d\xE9faussez cette carte//de votre zone de jeu, vous pouvez//l'\xE9changer contre un Disciple.//%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.GEAR] = "|+2 Cartes|////Mettez de c\xF4t\xE9 face cach\xE9e//jusqu'\xE0 2 cartes de votre main//(sous cette carte).//Au d\xE9but de votre prochain tour,//prenez-les en main.";
FrenchCardTexts[CardNames.GIANT] = "Retournez votre jeton Voyage (mettez-//le face visible au d\xE9but de la partie).//S'il est face cach\xE9e, +[1]. S'il est face//visible, +[5], et tous vos adversaires//d\xE9voilent la carte du haut de leur//pioche, l'\xE9cartent si elle co\xFBte entre//[3] et [6], et sinon la d\xE9faussent//et re\xE7oivent une Mal\xE9diction.";
FrenchCardTexts[CardNames.GUIDE] = "|+1 Carte|//|+1 Action|////Placez cette carte//sur votre plateau Taverne.//---//Au d\xE9but de votre tour, vous pouvez//recourir \xE0 cette carte pour d\xE9fausser//votre main et piocher 5 cartes.";
FrenchCardTexts[CardNames.HAUNTED_WOODS] = "Au d\xE9but de votre prochain tour,//|+3 Cartes|//Jusqu'\xE0 votre prochain tour, quand//un adversaire re\xE7oit une carte//qu'il a achet\xE9e, il place//sa main sur sa pioche dans//l'ordre de son choix.";
FrenchCardTexts[CardNames.HERO] = "|+[2]|//---//Recevez une carte Tr\xE9sor.////Quand vous d\xE9faussez cette carte//de votre zone de jeu, vous pouvez//l'\xE9changer contre un Champion.//%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.HIRELING] = "Au d\xE9but de tous vos tours,//jusqu'\xE0 la fin de la partie :////|+1 Carte|";
FrenchCardTexts[CardNames.INHERITANCE] = "Une fois dans la partie : mettez de c\xF4t\xE9 une carte Action non-Ordre//de la r\xE9serve co\xFBtant jusqu'\xE0 [4]. Placez dessus votre jeton Domaine.//(Durant vos tours, les Domaines sont des Actions Ordre ayant pour//effet \xAB Jouez la carte sous votre jeton Domaine, sans la d\xE9placer \xBB.)";
FrenchCardTexts[CardNames.LOST_ARTS] = "Placez votre jeton +1 Action sur une pile de cartes Action de la r\xE9serve.//(Quand vous jouez une carte de cette pile, obtenez d'abord |+1 Action|.)";
FrenchCardTexts[CardNames.LOST_CITY] = "|+2 Cartes|//|+2 Actions|//---//Quand vous recevez cette carte, tous//vos adversaires piochent une carte.";
FrenchCardTexts[CardNames.MAGPIE] = "|+1 Carte|//|+1 Action|////D\xE9voilez la carte du haut de votre pioche.//Si c'est une carte Tr\xE9sor, prenez-la//en main. Si c'est une carte Action ou//Victoire, recevez une Pie voleuse.";
FrenchCardTexts[CardNames.MESSENGER] = "|+1 Achat|//|+[2]|//---//Vous pouvez placer votre pioche//dans votre d\xE9fausse.////Si ceci est votre premi\xE8re carte re\xE7ue//pendant votre phase Achat, recevez//une carte co\xFBtant jusqu'\xE0 [4], et tous vos//adversaires en re\xE7oivent un exemplaire.";
FrenchCardTexts[CardNames.MISER] = "Choisissez : placez un Cuivre de//votre main sur le plateau Taverne;//ou |+|[1] par Cuivre sur votre//plateau Taverne.";
FrenchCardTexts[CardNames.MISSION] = "Jouez un tour suppl\xE9mentaire apr\xE8s celui-ci//(mais pas un troisi\xE8me cons\xE9cutif) pendant lequel//vous ne pourrez pas acheter de carte.";
FrenchCardTexts[CardNames.PATHFINDING] = "Placez votre jeton +1 Carte sur une pile de cartes Action de la r\xE9serve.//(Quand vous jouez une carte de cette pile, obtenez d'abord |+1 Carte|.)";
FrenchCardTexts[CardNames.PAGE] = "|+1 Carte|//|+1 Action|//---//Quand vous d\xE9faussez cette carte de//votre zone de jeu, vous pouvez l'\xE9chan-//ger contre une Chasseuse de Tr\xE9sors.";
FrenchCardTexts[CardNames.PEASANT] = "---//|+1 Achat|//|+[1]|////Quand vous d\xE9faussez cette carte//de votre zone de jeu, vous pouvez//l'\xE9changer contre un Soldat.";
FrenchCardTexts[CardNames.PILGRIMAGE] = "Une fois par tour : Retournez votre jeton Voyage (placez-le face//visible au d\xE9but de la partie); s'il est facile visible, choisissez jusqu'\xE0//3 cartes diff\xE9rentes en jeu et recevez un exemplaire de chacune.";
FrenchCardTexts[CardNames.PLAN] = "Placez votre jeton \xC9cart sur une pile de cartes Action//de la r\xE9serve. (Quand vous recevez une carte de cette pile,//vous pouvez \xE9carter une carte de votre main.)";
FrenchCardTexts[CardNames.PORT] = "|+1 Carte|//|+2 Actions|//---//Quand vous recevez cette carte,//recevez une autre Ville portuaire//(mais pas encore une autre)";
FrenchCardTexts[CardNames.QUEST] = "Vous pouvez d\xE9fausser une carte Attaque, deux//Mal\xE9dictions ou six cartes. Dans ce cas, recevez un Or.";
FrenchCardTexts[CardNames.RANGER] = "|+1 Achat|////Retournez votre jeton Voyage//(placez-le face visible au d\xE9but de//la partie). S'il est face visible,//|+5 Cartes|.";
FrenchCardTexts[CardNames.RAID] = "Recevez un Argent par Argent que vous avez en jeu. Tous//vos adversaires placent leur jeton -1 Carte sur leur pioche.";
FrenchCardTexts[CardNames.RATCATCHER] = "|+1 Carte|//|+1 Action|////Placez cette carte//sur votre plateau Taverne//---//Au d\xE9but de votre tour, vous//pouvez recourir \xE0 cette carte pour//\xE9carter une carte de votre main.";
FrenchCardTexts[CardNames.RAZE] = "|+1 Action|////\xC9cartez cette carte ou une carte de//votre main. Consultez une carte//du haut de votre pioche par [1] que//co\xFBte la carte \xE9cart\xE9e. Prenez-en//une en main et d\xE9faussez le reste.";
FrenchCardTexts[CardNames.RELIC] = "[!2]////Quand vous jouez cette carte,//tous vos adversaires placent leur//jeton -1 Carte sur leur pioche.";
FrenchCardTexts[CardNames.ROYAL_CARRIAGE] = "|+1 Action|////Placez cette carte sur//votre plateau Taverne.//---//Imm\xE9diatement apr\xE8s avoir fini de//jouer une carte Action, si elle est//encore en jeu, vous pouvez recourir//\xE0 cette carte pour la rejouer.";
FrenchCardTexts[CardNames.SAVE] = "Une fois par tour : |+1 Achat|. Mettez de c\xF4t\xE9 une carte de votre//main et prenez-la en main apr\xE8s la phase Ajustement de ce tour.";
FrenchCardTexts[CardNames.SCOUTING_PARTY] = "|+1 Achat|////Consultez les 5 premi\xE8res cartes de votre pioche.//D\xE9faussez-en 3 et replacez les autres dans l'ordre de votre choix.";
FrenchCardTexts[CardNames.SEAWAY] = "Recevez une carte Action co\xFBtant jusqu'\xE0 [4].//Placez votre jeton +1 Achat sur cette pile. (Quand vous jouez//une carte de cette pile, vous obtenez d'abord |+1 Achat|.)";
FrenchCardTexts[CardNames.SOLDIER] = "|+[2]|//---//+[1] par autre carte Attaque que//vous avez en jeu. Tous vos//adversaires ayant au moins 4 cartes//en main d\xE9faussent une carte.////Quand vous d\xE9faussez cette carte//de votre zone de jeu, vous pouvez//l'\xE9changer contre un Fugitif.//%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.STORYTELLER] = "|+1 Action|////Jouez jusqu'\xE0 3 cartes Tr\xE9sor de//votre main. Ensuite, |+1 Carte|,//d\xE9pensez tous vos [] et//|+1 Carte| par [1] d\xE9pens\xE9.";
FrenchCardTexts[CardNames.SWAMP_HAG] = "Au d\xE9but de votre prochain tour,//+[3].//Jusqu'\xE0 votre prochain tour, quand//un adversaire re\xE7oit une carte qu'il//a achet\xE9e, il re\xE7oit une Mal\xE9diction.";
FrenchCardTexts[CardNames.TEACHER] = "---//Placez cette carte//sur votre plateau taverne////Au d\xE9but de votre tour, vous pouvez recourir \xE0//cette carte pour d\xE9placer votre jeton +1 Carte,//+1 Action, + 1 Achat ou +[1] vers une pile//de cartes Actions de la r\xE9serve sans jeton.//(Quand vous jouez une carte de cette pile,//vous obtenez d'abord ce bonus.)//%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.TRAVELLING_FAIR] = "|+2 Achats|////Quand vous recevez une carte \xE0 ce tour,//vous pouvez la placer sur votre pioche.";
FrenchCardTexts[CardNames.TRADE] = "\xC9cartez jusqu'\xE0 2 cartes de votre main.//Recevez un Argent par carte \xE9cart\xE9e.";
FrenchCardTexts[CardNames.TRAINING] = "Placez votre jeton +[1] sur une pile de cartes Action de la r\xE9serve.//(Quand vous jouez une carte de cette pile, obtenez d'abord +[1].)";
FrenchCardTexts[CardNames.TRANSMOGRIFY] = "|+1 Action|////Placez cette carte//sur votre plateau Taverne.//---//Au d\xE9but de votre tour, vous pouvez//recourir \xE0 cette carte, pour \xE9carter une//carte de votre main et recevoir en main//une carte co\xFBtant jusqu'\xE0 [1] de plus.";
FrenchCardTexts[CardNames.TREASURE_TROVE] = "[!2]////Quand vous jouez cette carte,//recevez un Or et un Cuivre.";
FrenchCardTexts[CardNames.TREASURE_HUNTER] = "|+1 Action|//|+[1]|//---//Recevez un Argent par carte re\xE7ue par//le joueur \xE0 votre droite \xE0 son dernier tour.////Quand vous d\xE9faussez cette carte//de votre zone de jeu, vous pouvez//l'\xE9changer contre une Guerri\xE8re.//%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.WARRIOR] = "|+2 Cartes|////Une fois par Itin\xE9rant que vous avez en jeu//(y compris cette carte), tous vos adversaires//d\xE9faussent la carte du haut de leur pioche//et l'\xE9cartent si elle co\xFBte [3] ou [4].//---//Quand vous d\xE9faussez cette carte//de votre zone de jeu, vous pouvez//l'\xE9changer contre une H\xE9ro\xEFne.//%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.WINE_MERCHANT] = "|+1 Achat|//|+[4]|//---//Placez cette carte//sur votre plateau Taverne.////A la fin de votre phase Achat, si vous avez//au moins [2] non d\xE9pens\xE9s, vous pouvez//d\xE9fausser cette carte de votre Taverne.";
FrenchCardTexts[CardNames.ENCAMPMENT] = "|+2 Cartes|//|+2 Actions|//////Vous pouvez d\xE9voiler un Or ou un//Saccage de votre main. Si vous ne//le faites pas, mettez cette carte de//c\xF4t\xE9 et retournez-la sur sa pile au//d\xE9but de la phase d'Ajustement.";
FrenchCardTexts[CardNames.PLUNDER] = "{+1}//[!2]";
FrenchCardTexts[CardNames.PATRICIAN] = "|+1 Carte|//|+1 Action|//////D\xE9voilez la carte du haut de votre//pioche. Si elle co\xFBte [5] ou plus,//prenez-la en main.";
FrenchCardTexts[CardNames.EMPORIUM] = "|+1 Carte|//|+1 Action|//|+[1]|//---////Lorsque vous recevez cette carte,//si avez au moins 5 cartes Action//en jeu, {+2}.";
FrenchCardTexts[CardNames.SETTLERS] = "|+1 Carte|//|+1 Action|////Vous pouvez d\xE9voiler//un Cuivre de votre d\xE9fausse//et le prendre en main.";
FrenchCardTexts[CardNames.BUSTLING_VILLAGE] = "|+1 Carte|//|+3 Actions|//////Vous pouvez d\xE9voiler//un Colons de votre d\xE9fausse//et le prendre en main.";
FrenchCardTexts[CardNames.CATAPULT] = "|+[1]|////\xC9cartez une carte de votre main. Si//elle co\xFBte [3] ou plus, tous vos//adversaires re\xE7oivent une//Mal\xE9diction. Si c'est une carte Tr\xE9sor,//tous vos adversaires d\xE9faussent//jusqu'\xE0 avoir 3 cartes en main.";
FrenchCardTexts[CardNames.ROCKS] = "[!1]//---//Lorsque vous recevez ou \xE9cartez cette//carte, recevez un Argent ; si c'est votre//phase Achat, placez-le sur votre pioche,//sinon prenez-le en main.";
FrenchCardTexts[CardNames.GLADIATOR] = "|+|[2]////D\xE9voilez une carte de votre main.//Le joueur \xE0 votre gauche peut en//d\xE9voiler un exemplaire de sa main.//S'il ne le fait pas, +[1] et \xE9cartez//un Gladiateur de la r\xE9serve.";
FrenchCardTexts[CardNames.FORTUNE] = "|+1 Achat|////Doublez votre [] si vous ne l'avez//pas d\xE9j\xE0 fait ce tour-ci.//---//Lorsque vous recevez cette carte,//recevez un Or par Gladiateur en jeu.";
FrenchCardTexts[CardNames.CASTLES] = "Triez la pile des Ch\xE2teaux par,//co\xFBt, en pla\xE7ant les Ch\xE2teaux//les plus chers en-dessous. Pour une//partie \xE0 2 joueurs, n'utilisez qu'un//exemplaire de chaque Ch\xE2teau.//Seule la carte du haut de la pile//peut \xEAtre re\xE7ue ou achet\xE9e.";
FrenchCardTexts[CardNames.HUMBLE_CASTLE] = "[!1]//---//Vaut {1} par Ch\xE2teau//que vous avez.";
FrenchCardTexts[CardNames.CRUMBLING_CASTLE] = "{!1}//---//Lorsque vous recevez ou \xE9cartez cette//carte, +{1} et recevez un Argent.";
FrenchCardTexts[CardNames.SMALL_CASTLE] = "{!2}//---//\xC9cartez cette carte ou//un Ch\xE2teau de votre main.//Dans ces cas, recevez un Ch\xE2teau.";
FrenchCardTexts[CardNames.HAUNTED_CASTLE] = "{!2}//---//Lorsque vous recevez cette carte//pendant votre tour, recevez un Or//et tous vos adversaires ayant//au moins 5 cartes en main//en placent 2 sur leur pioche.";
FrenchCardTexts[CardNames.OPULENT_CASTLE] = "{!3}//---//D\xE9faussez autant de cartes//Victoire que souhait\xE9, d\xE9voil\xE9es.//+[!2] par carte d\xE9fauss\xE9e.";
FrenchCardTexts[CardNames.SPRAWLING_CASTLE] = "{!4}//---//Lorsque vous recevez cette carte,//recevez un Duch\xE9 ou 3 Domaines.";
FrenchCardTexts[CardNames.GRAND_CASTLE] = "{!5}//---//Lorsque vous recevez cette carte,//d\xE9voilez votre main. +{1} par carte//Victoire en main et/ou en jeu.";
FrenchCardTexts[CardNames.KINGS_CASTLE] = "Vaut {2} par Ch\xE2teau//que vous avez.";
FrenchCardTexts[CardNames.ADVANCE] = "Vous pouvez \xE9carter une carte Action de votre main.//Dans ce cas, recevez une carte Action co\xFBtant jusqu'\xE0 [6].";
FrenchCardTexts[CardNames.ANNEX] = "Consultez votre d\xE9fausse. M\xE9langez-la toute, sauf//au plus 5 cartes, avec votre pioche. Recevez un Duch\xE9.";
FrenchCardTexts[CardNames.ARCHIVE] = "|+1 Action|////Mettez de c\xF4t\xE9 face cach\xE9e les 3 pre-//mi\xE8res cartes de votre pioche (vous//pouvez les consulter). Maintenant//et au d\xE9but de vos deux prochains//tours, prenez-en une en main.";
FrenchCardTexts[CardNames.AQUEDUCT] = "Lorsque vous recevez un Tr\xE9sor, d\xE9placez {1} de sa pile vers ici.//Lorsque vous recevez une carte Victoire, prenez les {} d'ici.//---//Mise en place : placez {8} sur les piles des Argents et des Ors.";
FrenchCardTexts[CardNames.ARENA] = "Au d\xE9but de votre phase Achat, vous pouvez d\xE9fausser//une carte Action. Dans ce cas, prenez {2} d'ici.//---//Mise en place : placez ici {6} par joueur.";
FrenchCardTexts[CardNames.BANDIT_FORT] = "Pour le d\xE9compte, {-2} pour chaque Argent//et chaque Or que vous avez.";
FrenchCardTexts[CardNames.BANQUET] = "Recevez 2 Cuivres et une carte non-Victoire//co\xFBtant jusqu'\xE0 [5].";
FrenchCardTexts[CardNames.BASILICA] = "Lorsque vous recevez une carte, si vous avez//[2] ou plus, prenez {2} d'ici.//---//Mise en place : placez ici {6} par joueur.";
FrenchCardTexts[CardNames.BATHS] = "Si vous terminez votre tour sans avoir re\xE7u une seule carte,//prenez {2} d'ici.//---//Mise en place : placez ici {6} par joueur.";
FrenchCardTexts[CardNames.BATTLEFIELD] = "Lorsque vous recevez une carte Victoire, prenez {2} d'ici.//---//Mise en place : placez ici {6} par joueur.";
FrenchCardTexts[CardNames.CAPITAL] = "[!6]////|+1 Achat|//---//Lorsque vous d\xE9faussez cette carte//de votre zone de jeu, prenez [6D],//puis vous pouvez rembourser [D].";
FrenchCardTexts[CardNames.CHARM] = "Choisissez : |+1 Achat| et +[2];//ou la prochaine fois que vous//recevez une carte \xE0 ce tour,//vous pouvez aussi recevoir une//carte diff\xE9rente de m\xEAme co\xFBt.";
FrenchCardTexts[CardNames.CHARIOT_RACE] = "|+1 Action|////D\xE9voilez la carte du haut de votre//pioche et prenez-la en main. Le//joueur \xE0 votre gauche d\xE9voile la//carte du haut de sa pioche. Si votre//carte co\xFBte plus, +[1] et {+1}.";
FrenchCardTexts[CardNames.CITY_QUARTER] = "|+2 Actions|//////D\xE9voilez votre main. |+1 Carte|//par carte Action d\xE9voil\xE9e.";
FrenchCardTexts[CardNames.COLONNADE] = "Lorsque vous recevez une carte Action dont vous avez//un exemplaire en jeu, prenez {2} d'ici.//---//Mise en place : placez ici {6} par joueur.";
FrenchCardTexts[CardNames.CONQUEST] = "Recevez 2 Argents.//{+1} par Argent que vous avez re\xE7u \xE0 ce tour.";
FrenchCardTexts[CardNames.CROWN] = "Si c'est votre phase Action,//vous pouvez jouer deux fois//une carte Action de votre main.//Si c'est votre phase Achat//vous pouvez jouer deux fois//une carte Tr\xE9sor de votre main.";
FrenchCardTexts[CardNames.DELVE] = "|+1 Achat|//Recevez un Argent.";
FrenchCardTexts[CardNames.DEFILED_SHRINE] = "Quand vous recevez une Action, d\xE9placez {1} de sa pile vers ici.//Quand vous recevez une Mal\xE9diction pendant votre phase Achat, prenez les {} d'ici.//---//Mise en place : placez {2} sur chaque pile de carte Action// non-Collecte de la r\xE9serve.";
FrenchCardTexts[CardNames.DOMINATE] = "Recevez une Province. Dans ce cas, {+9}.";
FrenchCardTexts[CardNames.DONATE] = "Au d\xE9but de votre prochain tour, d'abord, prenez en main votre pioche// et votre d\xE9fausse, \xE9cartez en autant de cartes que souhait\xE9,// m\xE9langez les autres dans votre pioche puis piochez 5 cartes.";
FrenchCardTexts[CardNames.ENCHANTRESS] = "Jusqu'\xE0 votre prochain tour, la//premi\xE8re fois qu'un adversaire//joue une carte Action \xE0 son tour,//il a |+1 Carte| et |+1 Action| au//lieu de suivre ses instructions.//////Au d\xE9but de votre prochain tour,//|+2 Cartes|";
FrenchCardTexts[CardNames.ENGINEER] = "Recevez une carte co\xFBtant//jusqu'\xE0 [4]. Vous pouvez//\xE9carter cette carte.//Dans ce cas, recevez une//carte co\xFBtant jusqu'\xE0 [4].";
FrenchCardTexts[CardNames.FARMERS_MARKET] = "|+1 Achat|////S'il y a {4} ou plus sur la pile//des March\xE9s Agricoles, prenez-les//et \xE9cartez cette carte. Sinon,//ajoutez {1} \xE0 la pile, puis//+[1] par {1} sur la pile.";
FrenchCardTexts[CardNames.FORUM] = "|+3 Cartes|//|+1 Action|//D\xE9faussez 2 cartes.//---//Quand vous recevez cette carte,//|+1 Achat.|";
FrenchCardTexts[CardNames.FOUNTAIN] = "Pour le d\xE9compte, {15} si vous avez au moins 10 Cuivres.";
FrenchCardTexts[CardNames.GROUNDSKEEPER] = "|+1 Carte|//|+1 Action|//\xC0 ce tour, quand vous recevez//une carte Victoire, {+1}.";
FrenchCardTexts[CardNames.KEEP] = "Pour le d\xE9compte, {5} par carte Tr\xE9sor de nom diff\xE9rent//dont avez au moins autant d'exemplaires//que chacun de vos adversaires.";
FrenchCardTexts[CardNames.LABYRINTH] = "Lorsque vous recevez une deuxi\xE8me carte \xE0 l'un de vos tours,//prenez {2} d'ici.//---//Mise en place : placez ici {6} par joueur.";
FrenchCardTexts[CardNames.LEGIONARY] = "|+|[3]////Vous pouvez d\xE9voiler un Or de//votre main. Dans ce cas, tous//vos adversaires d\xE9faussent jusqu'\xE0//avoir 2 cartes en main, puis//piochent une carte.";
FrenchCardTexts[CardNames.MOUNTAIN_PASS] = "Quand vous \xEAtes le premier joueur \xE0 recevoir une Province,//chaque joueur ench\xE9rit une fois jusqu'\xE0 [40D] en terminant par vous.//Le meilleur ench\xE9risseur prend {8} et les [D] de son ench\xE8re.";
FrenchCardTexts[CardNames.MUSEUM] = "Pour le d\xE9compte, {2} par carte de nom diff\xE9rent//que vous avez.";
FrenchCardTexts[CardNames.OBELISK] = "Pour le d\xE9compte, {2} par carte de la pile choisie que vous avez.//---//Mise en place : choisissez au hasard//une pile de cartes Action de la r\xE9serve.";
FrenchCardTexts[CardNames.ORCHARD] = "Pour le d\xE9compte, {4} par carte Action de nom diff\xE9rent//dont vous avez au moins 3 exemplaires.";
FrenchCardTexts[CardNames.OVERLORD] = "Jouez une carte Action//non-Ordre de la r\xE9serve//co\xFBtant jusqu'\xE0 [5], en//la laissant dans la r\xE9serve.";
FrenchCardTexts[CardNames.PALACE] = "Pour le d\xE9compte, {3} par lot de//Cuivre - Argent - Or que vous avez.";
FrenchCardTexts[CardNames.RITUAL] = "Recevez une Mal\xE9diction. Dans ce cas, \xE9cartez//une carte de votre main. |+|{1} par [1] de son co\xFBt.";
FrenchCardTexts[CardNames.ROYAL_BLACKSMITH] = "|+5 Cartes|//////D\xE9voilez votre main ;//d\xE9faussez les Cuivres.";
FrenchCardTexts[CardNames.SACRIFICE] = "\xC9cartez une carte de votre main.//Si c'est une carte...//Action, |+2 Cartes, +2 Actions|//Tr\xE9sor, |+|[2]//Victoire,|+|{2}";
FrenchCardTexts[CardNames.SALT_THE_EARTH] = "{+1}//\xC9cartez une carte Victoire de la r\xE9serve.";
FrenchCardTexts[CardNames.TAX] = "Ajoutez [2D] \xE0 une pile de la r\xE9serve.//---//Mise en place : ajoutez [1D] \xE0 chaque pile de la r\xE9serve. Quand un joueur//re\xE7oit une carte pendant sa phase Achat, il prend les [D] de sa pile.";
FrenchCardTexts[CardNames.TEMPLE] = "|+{1}|////\xC9cartez entre 1 et 3 cartes de noms//diff\xE9rents de votre main.//Ajoutez {1} \xE0 la pile des Temples.//---//Lorsque vous recevez cette carte//prenez les {} de la pile des Temples.";
FrenchCardTexts[CardNames.TOMB] = "Lorsque vous \xE9cartez une carte, |+|{1}.";
FrenchCardTexts[CardNames.TOWER] = "Pour le d\xE9compte, {1} par carte non-Victoire//dont la pile de la r\xE9serve est vide que vous avez.";
FrenchCardTexts[CardNames.TRIUMPH] = "Recevez un Domaine. Dans ce cas,//|+|{1} par carte que vous avez re\xE7ue \xE0 ce tour.";
FrenchCardTexts[CardNames.TRIUMPHAL_ARCH] = "Pour le d\xE9compte, {3} par exemplaire de la deuxi\xE8me//carte Action la plus fr\xE9quente parmi vos cartes.";
FrenchCardTexts[CardNames.VILLA] = "|+2 Actions|//|+1 Achat|//---//|+[1]|////Quand vous recevez cette carte,//prenez-la en main, |+1 Action|, et//si c'est votre phase Achat,//retournez \xE0 la phase Action.";
FrenchCardTexts[CardNames.WALL] = "Pour le d\xE9compte, {-1} par carte, hormis//les 15 premi\xE8res.";
FrenchCardTexts[CardNames.WOLF_DEN] = "Pour le d\xE9compte, {-3} par carte dont vous avez//exactement un exemplaire.";
FrenchCardTexts[CardNames.WEDDING] = "{+1}//Recevez un Or.";
FrenchCardTexts[CardNames.WILD_HUNT] = "Choisissez : |+3 Cartes| et//ajoutez {1} \xE0 la pile des//Chasses Fantastiques ; ou//recevez un Domaine, et dans//ce cas prenez les {} de la pile.";
FrenchCardTexts[CardNames.WINDFALL] = "Si votre pioche et votre d\xE9fausse sont vides, recevez 3 Ors.";
FrenchCardTexts[CardNames.PIG] = "";
FrenchCardTexts[CardNames.MINUS_CARD] = "";
FrenchCardTexts[CardNames.MINUS_COIN] = "";
FrenchCardTexts[CardNames.STATE_LIMBO] = "";
FrenchCardTexts[CardNames.DRUID_BOONS] = "";
FrenchCardTexts[CardNames.BOON_DRAWPILE] = "";
FrenchCardTexts[CardNames.BOON_DISCARDPILE] = "";
FrenchCardTexts[CardNames.THE_EARTHS_GIFT] = "Vous pouvez d\xE9fausser une carte Tr\xE9sor//pour recevoir une carte co\xFBtant jusqu'\xE0 [4].";
FrenchCardTexts[CardNames.THE_FIELDS_GIFT] = "|+1 Action|//|+[1]|////(Conservez ceci jusqu'\xE0 la phase Ajustement.)";
FrenchCardTexts[CardNames.THE_FLAMES_GIFT] = "Vous pouvez \xE9carter une carte de votre main.";
FrenchCardTexts[CardNames.THE_FORESTS_GIFT] = "|+1 Achat|//|+[1]|////(Conservez ceci jusqu'\xE0 la phase Ajustement.)";
FrenchCardTexts[CardNames.THE_MOONS_GIFT] = "Consultez votre d\xE9fausse. Vous pouvez//en placer une carte sur votre pioche.";
FrenchCardTexts[CardNames.THE_MOUNTAINS_GIFT] = "Recevez un Argent.";
FrenchCardTexts[CardNames.THE_RIVERS_GIFT] = "|+1 Carte| \xE0 la fin de ce tour.//(Conservez ceci jusqu'\xE0 la phase Ajustement.)";
FrenchCardTexts[CardNames.THE_SEAS_GIFT] = "|+1 Carte|";
FrenchCardTexts[CardNames.THE_SKYS_GIFT] = "Vous pouvez d\xE9fausser 3 cartes pour recevoir un Or.";
FrenchCardTexts[CardNames.THE_SUNS_GIFT] = "Consultez les 4 premi\xE8res cartes de votre pioche.// D\xE9faussez-en autant que vous le souhaitez//et replacez le reste dans l'ordre de votre choix.";
FrenchCardTexts[CardNames.THE_SWAMPS_GIFT] = "Recevez un Feu follet de sa pile.";
FrenchCardTexts[CardNames.THE_WINDS_GIFT] = "|+2 Cartes|//D\xE9faussez 2 cartes.";
FrenchCardTexts[CardNames.HEX_DRAWPILE] = "";
FrenchCardTexts[CardNames.HEX_DISCARDPILE] = "";
FrenchCardTexts[CardNames.BAD_OMENS] = "Placez votre pioche dans votre d\xE9fausse. Consultez-la//et placez en 2 Cuivres sur votre pioche (ou d\xE9voilez// votre d\xE9fausse pour prouver que c'est impossible).";
FrenchCardTexts[CardNames.DELUSION] = "Si vous n'avez pas Envo\xFBt\xE9 ou Jaloux,//prenez Envo\xFBt\xE9.";
FrenchCardTexts[CardNames.ENVY] = "Si vous n'avez pas Envo\xFBt\xE9 ou Jaloux,//prenez Jaloux.";
FrenchCardTexts[CardNames.FAMINE] = "Consultez les 3 premi\xE8res cartes de votre pioche.//D\xE9faussez les cartes Action.//M\xE9langez le reste dans votre pioche.";
FrenchCardTexts[CardNames.FEAR] = "Si vous avez au moins 5 cartes en main, d\xE9faussez une//carte Action ou Tr\xE9sor (\xE0 d\xE9faut, d\xE9voilez votre main).";
FrenchCardTexts[CardNames.GREED] = "Recevez un Cuivre sur votre pioche.";
FrenchCardTexts[CardNames.HAUNTING] = "Si vous avez au moins 4 cartes en main,//placez-en une sur votre pioche.";
FrenchCardTexts[CardNames.LOCUSTS] = "\xC9cartez la carte du dessus de votre pioche. Si c'est un//Cuivre ou un Domaine, recevez une Mal\xE9diction. Sinon,//recevez une carte moins ch\xE8re ayant un type en commun.";
FrenchCardTexts[CardNames.MISERY] = "Si c'est votre premi\xE8re D\xE9tresse de la partie//prenez \xABEn d\xE9tresse\xBB. Sinon, retournez-le// du c\xF4t\xE9 \xABEn grande d\xE9tresse\xBB.";
FrenchCardTexts[CardNames.PLAGUE] = "Recevez une Mal\xE9diction en main.";
FrenchCardTexts[CardNames.POVERTY] = "D\xE9faussez jusqu'\xE0 avoir 3 cartes en main.";
FrenchCardTexts[CardNames.WAR] = "D\xE9voilez des cartes de votre pioche jusqu'\xE0 en d\xE9voiler//une co\xFBtant [3] ou [4]. \xC9cartez-la et d\xE9faussez le reste.";
FrenchCardTexts[CardNames.MISERABLE] = "{!-2}";
FrenchCardTexts[CardNames.TWICE_MISERABLE] = "{!-4}";
FrenchCardTexts[CardNames.ENVIOUS] = "Au d\xE9but de votre phase Achat, rendez Jaloux, et//les cartes Argent et Or produisent [1]\xE0 ce tour.";
FrenchCardTexts[CardNames.DELUDED] = "Au d\xE9but de votre phase Achat, rendez Envo\xFBt\xE9, et//vous ne pouvez pas acheter de cartes Action \xE0 ce tour.";
FrenchCardTexts[CardNames.LOST_IN_THE_WOODS] = "Au d\xE9but de votre tour, vous pouvez//d\xE9fausser une carte pour appliquer une Aubaine.";
FrenchCardTexts[CardNames.BARD] = "|+|[2]//Appliquez une Aubaine.";
FrenchCardTexts[CardNames.BLESSED_VILLAGE] = "|+1 Carte|//|+2 Actions|//---//Quand vous recevez cette carte, prenez//une Aubaine. Appliquez-la maintenant//ou au d\xE9but de votre prochain tour.";
FrenchCardTexts[CardNames.CHANGELING] = "\xC9cartez cette carte. Recevez un//exemplaire d'une carte que vous//avez en jeu.//---//Si le Changelin est dans le royaume,//lorsque vous recevez une carte//co\xFBtant [3] ou plus, vous pouvez//l'\xE9changer contre un Changelin.";
FrenchCardTexts[CardNames.CEMETERY] = "{!2}//---//Quand vous recevez cette carte,//\xE9cartez jusqu'\xE0 4 cartes de votre main.//%%(Patrimoine : Miroir hant\xE9)%%";
FrenchCardTexts[CardNames.COBBLER] = "Au d\xE9but de votre prochain tour,//recevez en main une carte co\xFBtant//jusqu'\xE0 [4].";
FrenchCardTexts[CardNames.CONCLAVE] = "|+|[2]//Vous pouvez jouer une carte Action//de votre main dont vous n'avez pas//d'exemplaire en jeu. Dans ce cas,//|+1 Action.|";
FrenchCardTexts[CardNames.CRYPT] = "Mettez de c\xF4t\xE9 autant de cartes Tr\xE9sor//non-Dur\xE9e en jeu que souhait\xE9, face//cach\xE9e (sous cette carte). Tant qu'il//en reste, au d\xE9but de chacun de//vos tours, prenez-en une en main.";
FrenchCardTexts[CardNames.CURSED_VILLAGE] = "|+2 Actions|////Piochez jusqu'\xE0 avoir 6 cartes//en main.//---//Quand vous recevez cette carte,//appliquez un Sortil\xE8ge.";
FrenchCardTexts[CardNames.DEN_OF_SIN] = "Au d\xE9but de votre prochain tour : //|+2 Cartes|.//---//Cette carte est re\xE7ue en main//(et non dans la d\xE9fausse).";
FrenchCardTexts[CardNames.DEVILS_WORKSHOP] = "Si le nombre de cartes que vous//avez re\xE7ue(s) \xE0 ce tour est ://|2+: |recevez un Farfadet de sa pile ;//|1: |recevez une carte co\xFBtant jusqu'\xE0 [4];//|0: |recevez un Or.";
FrenchCardTexts[CardNames.DRUID] = "|+1 Achat|////Appliquez une des trois Aubaines//mises de c\xF4t\xE9 (laissez-la en place).//---//Mise en place : mettez de cot\xE9 face//visible les 3 premi\xE8res Aubaines.";
FrenchCardTexts[CardNames.EXORCIST] = "\xC9cartez une carte//de votre main.//Recevez un Esprit moins cher//de l'une des piles Esprit.";
FrenchCardTexts[CardNames.FAITHFUL_HOUND] = "|+2 Cartes|//---//Quand vous d\xE9faussez cette carte//en dehors de la phase Ajustement,//vous pouvez la mettre de c\xF4t\xE9, et la//prendre en main \xE0 la fin de votre tour.";
FrenchCardTexts[CardNames.FOOL] = "Si vous n'avez pas Perdu dans//les bois, prenez-le, prenez 3//Aubaines, et appliquez-les// dans l'ordre de votre choix.////%%(Patrimoine : Porte-bonheur)%%";
FrenchCardTexts[CardNames.GHOST_TOWN] = "Au d\xE9but de votre prochain//tour : |+1 Carte |et |+1 Action|.//---//Cette carte est re\xE7ue en main//(et non dans la d\xE9fausse).";
FrenchCardTexts[CardNames.GUARDIAN] = "Au d\xE9but de votre prochain tour,//|+|[1]. D'ici l\xE0, quand un adversaire//joue une carte Attaque, vous//n'en subissez pas les effets. //---//Cette carte est re\xE7ue en main//(et non dans la d\xE9fausse).";
FrenchCardTexts[CardNames.IDOL] = "[!2]////Apr\xE8s avoir jou\xE9 cette carte, si vous//avez un nombre impair d'Idoles en jeu,//appliquez une Aubaine ; si vous en//avez un nombre pair, tous vos adver-//saires re\xE7oivent une Mal\xE9diction.";
FrenchCardTexts[CardNames.LEPRECHAUN] = "Recevez un Or. Si vous avez//exactement 7 cartes en jeu,//recevez un V\u0153u de sa pile.//Sinon, appliquez un Sortil\xE8ge.";
FrenchCardTexts[CardNames.MONASTERY] = "Pour chaque carte que vous avez//re\xE7ue ce tour, vous pouvez \xE9carter//une carte de votre main ou un//Cuivre en jeu.";
FrenchCardTexts[CardNames.NECROMANCER] = "Jouez une carte Action non-Dur\xE9e//face visible du rebut, en la laissant//en place et en la retournant face//cach\xE9e jusqu'\xE0 la fin du tour.//---//Mise en place : placez les 3//Zombies dans le rebut.";
FrenchCardTexts[CardNames.NIGHT_WATCHMAN] = "Consultez les 5 premi\xE8res cartes de//votre pioche, d\xE9faussez-en autant//que souhait\xE9, et replacez le reste//dans l'ordre de votre choix.//---//Cette carte est re\xE7ue en main//(et non dans la d\xE9fausse).";
FrenchCardTexts[CardNames.PIXIE] = "|+1 Carte|//|+1 Action|////D\xE9faussez la prochaine Aubaine.//Vous pouvez \xE9carter cette carte pour//appliquer deux fois cette Aubaine.//%%(Patrimoine : Ch\xE8vre)%%";
FrenchCardTexts[CardNames.POOKA] = "Vous pouvez \xE9carter une carte//Tr\xE9sor de votre main autre qu'un//Or maudit pour |+4 Cartes|.//%%(Patrimoine : Or maudit)%%";
FrenchCardTexts[CardNames.RAIDER] = "Tous vos adversaires ayant au//moins 5 cartes en main d\xE9faussent//un exemplaire d'une carte que vous//avez en jeu (ou montrent qu'ils ne// peuvent pas). Au d\xE9but de votre//prochain tour, +[3].";
FrenchCardTexts[CardNames.SACRED_GROVE] = "|+1 Achat|//|+[3]|////Appliquez une Aubaine. Si elle//ne donne pas +[1], tous vos//adversaires peuvent l'appliquer.";
FrenchCardTexts[CardNames.SECRET_CAVE] = "|+1 Carte|//|+1 Action|////Vous pouvez d\xE9fausser 3 cartes.//Dans ce cas, au d\xE9but de votre//prochain tour, +[3].//%%(Patrimoine : Lampe magique)%%";
FrenchCardTexts[CardNames.SHEPHERD] = "|+1 Action|////D\xE9faussez en les d\xE9voilant autant//de cartes Victoire que souhait\xE9.//|+2 Cartes| par carte d\xE9fauss\xE9e.//%%(Patrimoine : P\xE2turage)%%";
FrenchCardTexts[CardNames.SKULK] = "|+1 Achat|////Tous vos adversaires appliquent//le prochain Sortil\xE8ge.//---//Quand vous recevez cette carte,//recevez un Or.";
FrenchCardTexts[CardNames.TORMENTOR] = "|+|[2]////Si vous n'avez pas d'autre carte en jeu,//recevez un Farfadet de sa pile.//Sinon, tous vos adversaires//appliquent le prochain Sortil\xE8ge.";
FrenchCardTexts[CardNames.TRAGIC_HERO] = "|+3 Cartes|//|+1 Achat|//////Si vous avez en main 8 cartes ou//plus (apr\xE8s avoir pioch\xE9), \xE9cartez//cette carte et recevez une//carte Tr\xE9sor.";
FrenchCardTexts[CardNames.TRACKER] = "|+[1]|////\xC0 ce tour, quand vous//recevez une carte, vous pouvez//la placer sur votre pioche.////Appliquez une Aubaine.////%%(Patrimoine : Pochette)%%";
FrenchCardTexts[CardNames.VAMPIRE] = "Tous vos adversaires appliquent le//prochain Sortil\xE8ge. Recevez une//carte co\xFBtant jusqu'\xE0 [5] autre qu'un//Vampire. \xC9changez cette carte//contre une Chauve-souris.";
FrenchCardTexts[CardNames.WEREWOLF] = "Si c'est votre phase Nuit, tous//vos adversaires appliquent le//prochain Sortil\xE8ge. Sinon,//|+3 Cartes|.";
FrenchCardTexts[CardNames.CURSED_GOLD] = "[!3]////Quand vous jouez cette carte,//recevez une Mal\xE9diction.";
FrenchCardTexts[CardNames.GOAT] = "[!1]////Quand vous jouez cette carte,//vous pouvez \xE9carter une carte//de votre main.";
FrenchCardTexts[CardNames.HAUNTED_MIRROR] = "[!1]//---//Quand vous \xE9cartez cette carte,//vous pouvez d\xE9fausser une carte//Action pour recevoir un Fant\xF4me//de sa pile.";
FrenchCardTexts[CardNames.LUCKY_COIN] = "[!1]////Quand vous jouez cette carte,//recevez un Argent.";
FrenchCardTexts[CardNames.MAGIC_LAMP] = "[!1]////Quand vous jouez cette carte, si vous//avez au moins 6 cartes avec un unique//exemplaire en jeu, \xE9cartez cette carte.//Dans ce cas, recevez 3 V\u0153ux.";
FrenchCardTexts[CardNames.PASTURE] = "[!1]//---//Vaut {1} par Domaine//que vous avez.";
FrenchCardTexts[CardNames.POUCH] = "[!1]////|+1 Achat|";
FrenchCardTexts[CardNames.BAT] = "\xC9cartez jusqu'\xE0 2 cartes de votre//main. Si vous en avez \xE9cart\xE9 au//moins une, \xE9changez cette carte//contre un Vampire.//%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.GHOST] = "D\xE9voilez des cartes de votre pioche//jusqu'\xE0 d\xE9voiler une carte Action.//D\xE9faussez les autres carte et mettez//la carte Action de c\xF4t\xE9. Au d\xE9but de//votre prochain tour, jouez-la deux fois.//%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.IMP] = "|+2 Cartes|////Vous pouvez jouer une carte//Action de votre main dont vous//n'avez pas d'exemplaire en jeu.//%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.WILL_O_WISP] = "|+1 Carte|//|+1 Action|////D\xE9voilez la carte du haut de//votre pioche. Si elle co\xFBte [2]//ou moins, prenez-la en main.//%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.WISH] = "|+1 Action|////Retournez cette carte sur sa pile.//Dans ce cas, recevez en main une//carte co\xFBtant jusqu'\xE0 [6].//%(Ne fait pas partie de la r\xE9serve.)%";
FrenchCardTexts[CardNames.ZOMBIE_APPRENTICE] = "Vous pouvez \xE9carter une carte//Action de votre main pour//|+3 Cartes |et |+1 Action|.";
FrenchCardTexts[CardNames.ZOMBIE_MASON] = "\xC9cartez la carte du dessus de votre//pioche. Vous pouvez recevoir une//carte co\xFBtant jusqu'\xE0 [1] de plus.";
FrenchCardTexts[CardNames.ZOMBIE_SPY] = "|+1 Carte|//|+1 Action|////Consultez la carte du dessus de//votre pioche. D\xE9faussez-la ou //replacez-la.";
FrenchCardTexts[CardNames.ACTING_TROUPE] = "|+4 Villageois|//\xC9cartez cette carte.";
FrenchCardTexts[CardNames.BORDER_GUARD] = "|+1 Action|////D\xE9voilez les 2 premi\xE8res cartes//de votre pioche. Prenez-en une//en main et d\xE9faussez l'autre. Si//les deux \xE9taient des cartes Action,//prenez la Lanterne ou la Corne.";
FrenchCardTexts[CardNames.CARGO_SHIP] = "|+[2]|////Une fois \xE0 ce tour, quand vous//recevez une carte, vous pouvez la//mettre de c\xF4t\xE9 face visible (sur//cette carte). Au d\xE9but de votre//prochain tour, prenez-la en main.";
FrenchCardTexts[CardNames.DUCAT] = "|+1 Coffres|//|+1 Achat|//---//Quand vous recevez cette carte,//vous pouvez \xE9carter un Cuivre//de votre main.";
FrenchCardTexts[CardNames.EXPERIMENT] = "|+2 Cartes|//|+1 Action|//Replacez cette carte sur sa pile.//---//Quand vous recevez cette carte,//recevez une autre Exp\xE9rience//(mais pas encore une autre).";
FrenchCardTexts[CardNames.FLAG_BEARER] = "|+[2]|//---//Quand vous recevez ou \xE9cartez//cette carte, prenez le Drapeau.";
FrenchCardTexts[CardNames.HIDEOUT] = "|+1 Carte|//|+2 Actions|////\xC9cartez une carte de votre//main. Si c'est une carte Victoire,//recevez une Mal\xE9diction.";
FrenchCardTexts[CardNames.INVENTOR] = "Recevez une carte co\xFBtant//jusqu'\xE0 [4], puis les cartes//co\xFBtent [1] de moins \xE0 ce tour.";
FrenchCardTexts[CardNames.IMPROVE] = "|+[2]|////Au d\xE9but de votre phase Ajustement,//vous pouvez \xE9carter une carte Action//que vous auriez d\xE9fauss\xE9 de la zone//de jeu \xE0 ce tour, pour recevoir une//carte co\xFBtant exactement [1] de plus.";
FrenchCardTexts[CardNames.LACKEYS] = "|+2 Cartes|//---//Quand vous recevez cette carte,//|+2 Villageois|.";
FrenchCardTexts[CardNames.MOUNTAIN_VILLAGE] = "|+2 Actions|////Consultez votre d\xE9fausse et//prenez-en une carte en main ; si//vous ne pouvez pas, |+1 Carte|.";
FrenchCardTexts[CardNames.PATRON] = "|+1 Villageois|//|+[2]|//---//Lorsque vous d\xE9voilez cette carte//(suite \xE0 une instruction utilisant//le mot \xABd\xE9voiler\xBB) pendant//une phase Action, |+1 Coffres|.";
FrenchCardTexts[CardNames.PRIEST] = "|+[2]|////\xC9cartez une carte de votre main.//Pour la suite de votre tour, quand//vous \xE9cartez une carte, |+|[2].";
FrenchCardTexts[CardNames.RESEARCH] = "|+1 Action|////\xC9cartez une carte de votre main. Par//[1] de son co\xFBt, mettez de c\xF4t\xE9 une//carte de votre pioche face cach\xE9e (sur//cette carte). Au d\xE9but de votre pro-//chain tour, prenez en main ces cartes.";
FrenchCardTexts[CardNames.SILK_MERCHANT] = "|+2 Cartes|//|+1 Achat|//---//Quand vous recevez//ou \xE9cartez cette carte,//|+1 Coffres| et |+1 Villageois|.";
FrenchCardTexts[CardNames.OLD_WITCH] = "|+3 Cartes|////Tous vos adversaires//re\xE7oivent une Mal\xE9diction//et peuvent \xE9carter une//Mal\xE9diction de leur main.";
FrenchCardTexts[CardNames.RECRUITER] = "|+2 Cartes|////\xC9cartez une carte de votre main.//|+1 Villageois| par [1] de son co\xFBt.";
FrenchCardTexts[CardNames.SCEPTER] = "Quand vous jouez cette carte,//choisissez : |+|[2];//ou rejouez une carte Action//jou\xE9e \xE0 ce tour qui est//encore en jeu.";
FrenchCardTexts[CardNames.SCHOLAR] = "D\xE9faussez votre main.//|+7 Cartes|.";
FrenchCardTexts[CardNames.SCULPTOR] = "Recevez en main une carte//co\xFBtant jusqu'\xE0 [4]. Si c'est//un Tr\xE9sor, |+1 Villageois|.";
FrenchCardTexts[CardNames.SEER] = "|+1 Carte|//|+1 Action|////D\xE9voilez les 3 premi\xE8res cartes de//votre pioche. Prenez en main celles//co\xFBtant de [2] \xE0 [4]. Replacez les//autres dans l'ordre de votre choix.";
FrenchCardTexts[CardNames.SPICES] = "[!2]////|+1 Achat|//---//Quand vous recevez cette carte,//|+2 Coffres|.";
FrenchCardTexts[CardNames.SWASHBUCKLER] = "|+3 Cartes|////Si votre d\xE9fausse n'est pas vide ://|+1 Coffres|, puis si vous avez au//moins 4 jetons sur vos Coffres,//prenez le Coffre au tr\xE9sor.";
FrenchCardTexts[CardNames.TREASURER] = "|+[3]|////Choisisez une option : \xE9cartez//une carte Tr\xE9sor de votre main;//ou recevez en main une carte//Tr\xE9sor du Rebut; ou prenez la Cl\xE9.";
FrenchCardTexts[CardNames.VILLAIN] = "|+2 Coffres|////Tous vos adversaires ayant au//moins 5 cartes en main d\xE9faussent//une carte co\xFBtant [2] ou plus (ou//\xE0 d\xE9faut, d\xE9voilent leur main).";
FrenchCardTexts[CardNames.FLAG] = "Quand vous piochez votre main, |+1 Carte|.";
FrenchCardTexts[CardNames.HORN] = "Une fois par tour, quand vous d\xE9faussez une Garde-fronti\xE8re//de la zone de jeu, vous pouvez la replacer sur votre pioche.";
FrenchCardTexts[CardNames.KEY] = "Au d\xE9but de votre tour, |+|[1].";
FrenchCardTexts[CardNames.LANTERN] = "Les Gardes-fronti\xE8res que vous jouez d\xE9voilent 3 cartes et en//d\xE9faussent 2. Il faut d\xE9voiler 3 cartes Action pour prendre la Corne.";
FrenchCardTexts[CardNames.TREASURE_CHEST] = "Au d\xE9but de votre phase Achat, recevez un Or.";
FrenchCardTexts[CardNames.ACADEMY] = "Quand vous recevez une carte Action,//|+1 Villageois|.";
FrenchCardTexts[CardNames.BARRACKS] = "Au d\xE9but de votre tour, |+1 Action|.";
FrenchCardTexts[CardNames.CANAL] = "Pendant vos tours, les cartes co\xFBtent [1] de moins.";
FrenchCardTexts[CardNames.CAPITALISM] = "Pendant vos tours, les cartes Action ayant dans leur//texte un montant \xAB +[]\xBB sont aussi des Tr\xE9sors.";
FrenchCardTexts[CardNames.CATHEDRAL] = "Au d\xE9but de votre tour,//\xE9cartez une carte de votre main.";
FrenchCardTexts[CardNames.CITADEL] = "\xC0 chacun de vos tours, la premi\xE8re fois que vous//jouez une carte Action, rejouez-la ensuite.";
FrenchCardTexts[CardNames.CITY_GATE] = "Au d\xE9but de votre tour, |+1 Carte|, puis replacez//une carte de votre main sur votre pioche.";
FrenchCardTexts[CardNames.CROP_ROTATION] = "Au d\xE9but de votre tour, vous pouvez d\xE9fausser//une carte Victoire pour |+2 Cartes|.";
FrenchCardTexts[CardNames.EXPLORATION] = "\xC0 la fin de votre phase Achat, si vous n'avez pas re\xE7u//de carte pendant celle-ci, |+1 Coffres| et |+1 Villageois|.";
FrenchCardTexts[CardNames.FAIR] = "Au d\xE9but de votre tour, |+1 Achat|.";
FrenchCardTexts[CardNames.FLEET] = "Apr\xE8s que la partie est finie, les joueurs ayant//un cube ici jouent un tour suppl\xE9mentaire.";
FrenchCardTexts[CardNames.GUILDHALL] = "Quand vous recevez un Tr\xE9sor, |+1 Coffres|.";
FrenchCardTexts[CardNames.INNOVATION] = "Une fois \xE0 chacun de vos tours, quand vous//recevez une carte Action, vous pouvez la jouer.";
FrenchCardTexts[CardNames.PAGEANT] = "\xC0 la fin de votre phase Achat, vous pouvez//payer [1] pour |+1 Coffres|.";
FrenchCardTexts[CardNames.PIAZZA] = "Au d\xE9but de votre tour, d\xE9voilez la carte du haut//de votre pioche. Si c'est une carte Action, jouez-la.";
FrenchCardTexts[CardNames.ROAD_NETWORK] = "Quand un autre joueur re\xE7oit une carte Victoire,//|+1 Carte|.";
FrenchCardTexts[CardNames.SEWERS] = "Quand vous \xE9cartez une carte autrement que par ceci,//vous pouvez \xE9carter une carte de votre main.";
FrenchCardTexts[CardNames.SILOS] = "Au d\xE9but de votre tour, d\xE9faussez autant de Cuivres que//souhait\xE9, d\xE9voil\xE9s, et piochez le m\xEAme nombre de cartes.";
FrenchCardTexts[CardNames.SINISTER_PLOT] = "Au d\xE9but de votre tour, ajoutez un jeton ici, ou//retirez tous vos jetons pour |+1 Carte| chacun.";
FrenchCardTexts[CardNames.STAR_CHART] = "Quand vous m\xE9langez, vous pouvez choisir//quelle carte vous mettez en haut.";
FrenchCardTexts[CardNames.SAUNA] = "|+1 Carte|//|+1 Action|////Vous pouvez jouer un Trou dans//la glace de votre main.////\xC0 ce tour, quand vous jouez//un Argent, vous pouvez \xE9carter//une carte de votre main.";
FrenchCardTexts[CardNames.AVANTO] = "|+3 Cartes|//////Vous pouvez jouer un Sauna//de votre main.";
FrenchCardTexts[CardNames.BLACK_MARKET] = "|+[2]|////D\xE9voilez les 3 premi\xE8res cartes//du paquet du March\xE9 noir. Jouez//autant de cartes Tr\xE9sor de votre main//que souhait\xE9. Vous pouvez acheter//une des cartes d\xE9voil\xE9es. Replacez//les autres sous le paquet March\xE9 noir//dans l'ordre de votre choix.//---//Mise en place : pr\xE9parez le paquet//du March\xE9 noir avec des cartes Royaume//diff\xE9rentes non utilis\xE9es.";
FrenchCardTexts[CardNames.ENVOY] = "D\xE9voilez les 5 premi\xE8res cartes de//votre pioche. Le joueur \xE0 votre//gauche en choisit une. D\xE9faussez-la//et prenez les autres en main.";
FrenchCardTexts[CardNames.GOVERNOR] = "|+1 Action|////Choisissez (vous obtenez la version//entre parenth\xE8ses) : tous les joueurs//obtiennent |+1 (+3) Cartes|; ou tous les//joueurs re\xE7oivent un Argent (Or); ou//tous les joueurs peuvent \xE9carter une//carte de leur main pour recevoir une//carte co\xFBtant exactement [1]([2]) de plus.";
FrenchCardTexts[CardNames.PRINCE] = "Vous pouvez mettre de c\xF4t\xE9//sur cette carte une carte Action//non-Dur\xE9e non-Ordre de votre//main co\xFBtant jusqu'\xE0 [4]. Au d\xE9but//de chacun de vos tours, jouez cette//carte en la laissant de c\xF4t\xE9.";
FrenchCardTexts[CardNames.STASH] = "[!2]//---//Quand vous m\xE9langez, si cette carte//est dans votre pioche, placez-la o\xF9//vous souhaitez dans votre pioche.";
FrenchCardTexts[CardNames.SUMMON] = "Recevez une carte Action co\xFBtant jusqu'\xE0 [4]. Mettez-la//de c\xF4t\xE9. Dans ce cas, jouez-la au d\xE9but de votre prochain tour.";
FrenchCardTexts[CardNames.WALLED_VILLAGE] = "|+1 Carte|//|+2 Actions|//---//Au d\xE9but de la phase Ajustement, si//vous avez cette carte et au plus une//autre carte Action en jeu, vous pouvez//remettre cette carte sur votre pioche.";
FrenchCardTexts[CardNames.BLACK_MARKET_PILE] = "";
FrenchCardTexts[CardNames.DISMANTLE] = "\xC9cartez une carte de votre main.//Si elle co\xFBte [1] ou plus, recevez//une carte moins ch\xE8re et un Or.";
FrenchCardTexts[CardNames.CAPTAIN] = "Maintenant et au d\xE9but de//votre prochain tour ://jouez une carte Action//non-Dur\xE9e, non-Ordre depuis//la r\xE9serve co\xFBtant jusqu'\xE0 [4],//en la laissant dans la r\xE9serve.";
FrenchCardTexts[CardNames.CHURCH] = "|+1 Action|////Mettez de c\xF4t\xE9 jusqu'\xE0 3 cartes de//votre main, face cach\xE9e. Au d\xE9but//de votre prochain tour, prenez-les//en main, puis vous pouvez \xE9carter//une carte de votre main.";
FrenchCardTexts[CardNames.BLACK_CAT] = "|+2 Cartes|//Si ce n'est pas votre tour, tous vos//adversaires re\xE7oivent une Mal\xE9diction.//---//Quand un adversaire re\xE7oit une//carte Victoire, vous pouvez jouer//cette carte depuis votre main.";
FrenchCardTexts[CardNames.SLEIGH] = "Recevez 2 Chevaux.//---//Quand vous recevez une carte,//vous pouvez d\xE9fausser ceci//pour la prendre en main ou//la placer sur votre pioche.";
FrenchCardTexts[CardNames.SUPPLIES] = "[!1]////Quand vous jouez cette carte,//recevez un Cheval sur votre pioche.";
FrenchCardTexts[CardNames.CAMEL_TRAIN] = "Exilez une carte non-Victoire//depuis la r\xE9serve.//---//Quand vous recevez cette carte,//exilez un Or depuis la r\xE9serve.";
FrenchCardTexts[CardNames.GOATHERD] = "|+1 Action|////Vous pouvez \xE9carter//une carte de votre main.////|+1 Carte| par carte que//le joueur a votre droite//a \xE9cart\xE9 \xE0 son dernier tour.";
FrenchCardTexts[CardNames.SCRAP] = "\xC9cartez une carte de votre main.//Choisissez un effet diff\xE9rent//par [1] de son co\xFBt : |+1 Carte|;//|+1 Action|; |+1 Achat|; //|+|[1]; recevez un Argent;//recevez un Cheval.";
FrenchCardTexts[CardNames.SHEEPDOG] = "|+2 Cartes|//---//Quand vous recevez une//carte, vous pouvez jouer//ceci depuis votre main.";
FrenchCardTexts[CardNames.SNOWY_VILLAGE] = "|+1 Carte|//|+4 Actions|//|+1 Achat|////Ignorez tout effet |+Action(s)|//que vous recevrez ce tour.";
FrenchCardTexts[CardNames.STOCKPILE] = "[!3]////|+1 Achat|////Quand vous jouez cette carte//exilez-la.";
FrenchCardTexts[CardNames.BOUNTY_HUNTER] = "|+1 Action|////Exilez une carte de votre main.//Si vous n'en aviez pas//d'exemplaire en exil, |+|[3].";
FrenchCardTexts[CardNames.CARDINAL] = "|+[2]|////Tous vos adversaires d\xE9voilent les//deux premi\xE8res cartes de leur pioche,//en exilent une co\xFBtant entre [3]//et [6], et d\xE9faussent le reste.";
FrenchCardTexts[CardNames.CAVALRY] = "Recevez 2 Chevaux.//---//Quand vous recevez cette carte,//|+2 Cartes|, |+1 Achat|, et si//c'est votre phase Achat,//retournez \xE0 votre phase Action.";
FrenchCardTexts[CardNames.GROOM] = "Recevez une carte co\xFBtant//jusqu'\xE0 [4]. Si c'est une...////carte Action, recevez un Cheval//carte Tr\xE9sor, recevez un Argent//carte Victoire, |+1 Carte|// et |+1 Action|";
FrenchCardTexts[CardNames.HOSTELRY] = "|+1 Carte|//|+2 Actions|//---//Quand vous recevez cette carte,//vous pouvez d\xE9fausser autant de//Tr\xE9sors que souhait\xE9, d\xE9voil\xE9s,//pour recevoir autant de Chevaux.";
FrenchCardTexts[CardNames.VILLAGE_GREEN] = "Maintenant ou au d\xE9but//de votre prochain tour,//|+1 Carte| et |+2 Actions.|//---//Quand vous d\xE9faussez cette//carte en dehors de votre phase//Ajustement, vous pouvez//la d\xE9fausser pour la jouer.";
FrenchCardTexts[CardNames.BARGE] = "Maintenant ou au d\xE9but//de votre prochain tour,//|+3 Cartes| et |+1 Achat.|";
FrenchCardTexts[CardNames.COVEN] = "|+1 Action|//|+[2]|////Tous vos adversaires exilent//une Mal\xE9diction depuis la r\xE9serve.//S'ils ne peuvent pas, ils d\xE9faussent//leurs Mal\xE9dictions en exil.";
FrenchCardTexts[CardNames.DISPLACE] = "Exilez une carte de votre main.//Recevez une carte de//nom diff\xE9rent, co\xFBtant//jusqu'\xE0 [2] de plus.";
FrenchCardTexts[CardNames.FALCONER] = "Recevez en main une carte//co\xFBtant moins que ceci.//---//Quand un joueur re\xE7oit une carte//ayant 2 types ou plus (Action,//Attaque, etc.), vous pouvez//jouer ceci depuis votre main.";
FrenchCardTexts[CardNames.FISHERMAN] = "|+1 Carte|//|+1 Action|//|+[1]|//---//Pendant vos tours,//si votre d\xE9fausse est vide,//ceci co\xFBte [3] de moins.";
FrenchCardTexts[CardNames.GATEKEEPER] = "Au d\xE9but de votre prochain//tour, |+|[3]. D'ici l\xE0,//quand un adversaire re\xE7oit//une carte Action ou Tr\xE9sor//dont il n'a pas d'exemplaire//en exil, il l'exile.";
FrenchCardTexts[CardNames.HUNTING_LODGE] = "|+1 Carte|//|+2 Actions|////Vous pouvez d\xE9fausser votre//main pour |+5 Cartes|.";
FrenchCardTexts[CardNames.KILN] = "|+[2]|////La prochaine fois que vous//jouerez une carte \xE0 ce tour,//vous pourrez d'abord//en recevoir un exemplaire.";
FrenchCardTexts[CardNames.LIVERY] = "|+[3]|////Ce tour-ci, quand vous//recevez une carte co\xFBtant [4]//ou plus, recevez un Cheval.";
FrenchCardTexts[CardNames.MASTERMIND] = "Au d\xE9but de votre//prochain tour, vous pouvez//jouer une carte Action//de votre main trois fois.";
FrenchCardTexts[CardNames.PADDOCK] = "|+[2]|////Recevez 2 Chevaux.////|+1 Action| par//pile vide de la R\xE9serve.";
FrenchCardTexts[CardNames.SANCTUARY] = "|+1 Carte|//|+1 Action|//|+1 Achat|////Vous pouvez exiler//une carte de votre main.";
FrenchCardTexts[CardNames.DESTRIER] = "|+2 Cartes|//|+1 Action|//---//Pendant vos tours, ceci//co\xFBte [1] de moins par carte//que vous avez re\xE7ue \xE0 ce tour.";
FrenchCardTexts[CardNames.WAYFARER] = "|+3 Cartes|////Vous pouvez recevoir un Argent.//---//Cette carte a le m\xEAme co\xFBt//que la carte pr\xE9c\xE9demment//re\xE7ue \xE0 ce tour (si une carte//a \xE9t\xE9 re\xE7ue \xE0 ce tour).";
FrenchCardTexts[CardNames.ANIMAL_FAIR] = "|+[4]|////|+1 Achat| par pile vide//de la R\xE9serve.//---//Au lieu de payer le co\xFBt de//cette carte, vous pouvez \xE9carter//une carte Action de votre main.";
FrenchCardTexts[CardNames.HORSE] = "|+2 Cartes|//|+1 Action|////Retournez cette carte//sur la pile des Chevaux.//%(Ne fait pas partie de la r\xE9serve)%";
FrenchCardTexts[CardNames.WAY_OF_THE_BUTTERFLY] = "Vous pouvez retourner cette carte sur sa pile pour//recevoir une carte co\xFBtant exactement [1] de plus.";
FrenchCardTexts[CardNames.WAY_OF_THE_CAMEL] = "Exilez un Or depuis la R\xE9serve.";
FrenchCardTexts[CardNames.WAY_OF_THE_CHAMELEON] = "Suivez les instructions de cette carte; chaque effet//|+Carte(s)| donnera \xE0 la place |+|[]\xE0 ce tour,//et vice-versa.";
FrenchCardTexts[CardNames.WAY_OF_THE_FROG] = "|+1 Action|////Quand vous d\xE9faussez cette carte de votre//zone de jeu \xE0 ce tour, placez-la sur votre pioche.";
FrenchCardTexts[CardNames.WAY_OF_THE_GOAT] = "\xC9cartez une carte de votre main.";
FrenchCardTexts[CardNames.WAY_OF_THE_HORSE] = "|+2 Cartes|//|+1 Action|////Retournez cette carte sur sa pile.";
FrenchCardTexts[CardNames.WAY_OF_THE_MOLE] = "|+1 Action|////D\xE9faussez votre main. |+3 Cartes.|";
FrenchCardTexts[CardNames.WAY_OF_THE_MONKEY] = "|+1 Achat|//|+[1]|";
FrenchCardTexts[CardNames.WAY_OF_THE_MOUSE] = "---//Jouez la carte mise de c\xF4t\xE9, en l'y laissant.////Mise en place : mettez de c\xF4t\xE9 une//carte Action non utilis\xE9e co\xFBtant [2] ou [3].";
FrenchCardTexts[CardNames.WAY_OF_THE_MULE] = "|+1 Action|//|+[1]|";
FrenchCardTexts[CardNames.WAY_OF_THE_OTTER] = "|+2 Cartes|";
FrenchCardTexts[CardNames.WAY_OF_THE_OWL] = "Piochez jusqu'\xE0 avoir 6 cartes en main.";
FrenchCardTexts[CardNames.WAY_OF_THE_OX] = "|+2 Actions|";
FrenchCardTexts[CardNames.WAY_OF_THE_PIG] = "|+1 Carte|//|+1 Action|";
FrenchCardTexts[CardNames.WAY_OF_THE_RAT] = "Vous pouvez d\xE9fausser un Tr\xE9sor//pour recevoir un exemplaire de cette carte.";
FrenchCardTexts[CardNames.WAY_OF_THE_SEAL] = "|+[1]|////\xC0 ce tour, quand vous recevez une carte,//vous pouvez la placer sur votre pioche.";
FrenchCardTexts[CardNames.WAY_OF_THE_SHEEP] = "|+[2]|";
FrenchCardTexts[CardNames.WAY_OF_THE_SQUIRREL] = "|+2 Cartes| \xE0 la fin de ce tour.";
FrenchCardTexts[CardNames.WAY_OF_THE_TURTLE] = "Mettez cette carte de c\xF4t\xE9. Dans ce cas,//jouez-la au d\xE9but de votre prochain tour.";
FrenchCardTexts[CardNames.WAY_OF_THE_WORM] = "Exilez un Domaine de la R\xE9serve.";
FrenchCardTexts[CardNames.DELAY] = "Vous pouvez mettre de c\xF4t\xE9 une carte Action de//votre main. Au d\xE9but de votre prochain tour, jouez-la.";
FrenchCardTexts[CardNames.DESPERATION] = "Une fois par tour : vous pouvez recevoir// une Mal\xE9diction. Dans ce cas, |+1 Achat| et |+|[2].";
FrenchCardTexts[CardNames.GAMBLE] = "|+1 Achat|////D\xE9voilez la premi\xE8re carte de votre pioche. Si c'est un Tr\xE9sor//ou une Action, vous pouvez la jouer. Sinon, d\xE9faussez-la.";
FrenchCardTexts[CardNames.PURSUE] = "|+1 Achat|////Nommez une carte. D\xE9voilez les 4 premi\xE8res cartes de votre pioche.//Replacez celles qui correspondent, et d\xE9faussez le reste.";
FrenchCardTexts[CardNames.RIDE] = "Recevez un Cheval.";
FrenchCardTexts[CardNames.TOIL] = "|+1 Achat|////Vous pouvez jouer une carte Action de votre main.";
FrenchCardTexts[CardNames.ENHANCE] = "Vous pouvez \xE9carter une carte non-Victoire de votre main//pour recevoir une carte co\xFBtant jusqu'\xE0 [2] de plus. ";
FrenchCardTexts[CardNames.MARCH] = "Consultez votre d\xE9fausse. Vous pouvez//jouer une Action depuis votre d\xE9fausse.";
FrenchCardTexts[CardNames.TRANSPORT] = "Choisissez : exilez une carte Action//de la r\xE9serve; ou placez sur votre pioche//une carte Action que vous avez en exil.";
FrenchCardTexts[CardNames.BANISH] = "Exilez un nombre quelconque de cartes//portant le m\xEAme nom, depuis votre main.";
FrenchCardTexts[CardNames.BARGAIN] = "Recevez une carte non-Victoire co\xFBtant jusqu'\xE0 [5].//Tous vos adversaires re\xE7oivent un Cheval.";
FrenchCardTexts[CardNames.INVEST] = "Exilez une carte Action depuis la R\xE9serve.//Tant qu'elle est en exil, lorsqu'un adversaire re\xE7oit//ou investit dans un exemplaire de cette carte, |+2 Cartes|.";
FrenchCardTexts[CardNames.SEIZE_THE_DAY] = "Une fois par partie ://jouez un tour suppl\xE9mentaire apr\xE8s celui-ci.";
FrenchCardTexts[CardNames.COMMERCE] = "Recevez un Or par carte de nom diff\xE9rent//que vous avez re\xE7ue \xE0 ce tour.";
FrenchCardTexts[CardNames.DEMAND] = "Recevez un Cheval et une carte co\xFBtant//jusqu'\xE0 [4], tous deux sur votre pioche.";
FrenchCardTexts[CardNames.STAMPEDE] = "Si vous avez 5 cartes en jeu, ou moins,//recevez 5 Chevaux sur votre pioche.";
FrenchCardTexts[CardNames.REAP] = "Recevez un Or. Mettez-le de c\xF4t\xE9. Dans ce cas,//au d\xE9but de votre prochain tour, jouez-le.";
FrenchCardTexts[CardNames.ENCLAVE] = "Recevez un Or.//Exilez un Duch\xE9 depuis la R\xE9serve.";
FrenchCardTexts[CardNames.ALLIANCE] = "Recevez une Province, un Duch\xE9, un Domaine,//un Or, un Argent et un Cuivre.";
FrenchCardTexts[CardNames.POPULATE] = "Recevez une carte de chaque pile//de cartes Action de la R\xE9serve.";
FrenchCardTexts[CardNames.CARD_OF_THE_BUTTERFLY] = "";
FrenchCardTexts[CardNames.CARD_OF_THE_CAMEL] = "";
FrenchCardTexts[CardNames.CARD_OF_THE_CHAMELEON] = "";
FrenchCardTexts[CardNames.CARD_OF_THE_FROG] = "";
FrenchCardTexts[CardNames.CARD_OF_THE_GOAT] = "";
FrenchCardTexts[CardNames.CARD_OF_THE_HORSE] = "";
FrenchCardTexts[CardNames.CARD_OF_THE_MOLE] = "";
FrenchCardTexts[CardNames.CARD_OF_THE_MONKEY] = "";
FrenchCardTexts[CardNames.CARD_OF_THE_MOUSE] = "";
FrenchCardTexts[CardNames.CARD_OF_THE_MULE] = "";
FrenchCardTexts[CardNames.CARD_OF_THE_OTTER] = "";
FrenchCardTexts[CardNames.CARD_OF_THE_OWL] = "";
FrenchCardTexts[CardNames.CARD_OF_THE_OX] = "";
FrenchCardTexts[CardNames.CARD_OF_THE_PIG] = "";
FrenchCardTexts[CardNames.CARD_OF_THE_RAT] = "";
FrenchCardTexts[CardNames.CARD_OF_THE_SEAL] = "";
FrenchCardTexts[CardNames.CARD_OF_THE_SHEEP] = "";
FrenchCardTexts[CardNames.CARD_OF_THE_SQUIRREL] = "";
FrenchCardTexts[CardNames.CARD_OF_THE_TURTLE] = "";
FrenchCardTexts[CardNames.CARD_OF_THE_WORM] = "";

// ***** TODO *****: Allies

FrenchCardTexts[CardNames.TOWN] = "Choisissez une option://|+1 Carte| et |+2 Actions|;// ou |+1 Achat| et +[2].";
FrenchCardTexts[CardNames.MODIFY] = "Écartez une carte de votre main.//Choisissez une option://|+1 Carte| et |+1 Action|;// ou recevez une carte coûtant//jusqu'à [2] de plus//que la carte écartée.";
FrenchCardTexts[CardNames.MERCHANT_CAMP] = "|+2 Actions|//+[1]//---//Quand vous défaussez cette carte//de votre zone de jeu, vous pouvez//la placer sur votre pioche.";
FrenchCardTexts[CardNames.SWAP] = "|+1 Carte|//|+1 Action|//Vous pouvez retourner une carte//Action de votre main sur sa pile,//pour recevoir en main une carte//Action différente coûtant jusqu'à [5].";
FrenchCardTexts[CardNames.SKIRMISHER] = "|+1 Carte|//|+1 Action|//|+[1]|//À ce tour, quand vous recevez//une carte Attaque, tous vos//adversaires défaussent jusqu'à//avoir 3 cartes en main.";
FrenchCardTexts[CardNames.HIGHWAYMAN] = "Au début de votre prochain tour,//défaussez cette carte//de la zone de jeu et |+3 Cartes|.//D'ici là, le premier Trésor//joué par chaque adversaire//à chaque tour n'a pas d'effet.";
FrenchCardTexts[CardNames.BARBARIAN] = "|+[2]|//Tous vos adversaires écartent//la carte du haut de leur pioche//Si elle coûte [3] ou plus,//ils reçoivent une carte moins chère//ayant un type en commun;//sinon ils reçoivent une Malédiction.";
FrenchCardTexts[CardNames.INNKEEPER] = "|+1 Action|//Choisissez: |+1 Carte|; //ou |+3 Cartes|, défaussez 3 Cartes;//or |+5 Cartes|, défaussez 6 cartes.";
FrenchCardTexts[CardNames.CAPITAL_CITY] = "|+1 Carte|//|+2 Actions|//Vous pouvez défausser 2 cartes//pour +[2]. Vous pouvez payer [2]//pour |+2 Cartes|.";
FrenchCardTexts[CardNames.SPECIALIST] = "Vous pouvez jouer une carte//Action ou Trésor de votre main.//Choisissez: rejouez-la;//ou recevez-en un exemplaire.";
FrenchCardTexts[CardNames.CARPENTER] = "Si aucune pile de la Réserve//n'est vide, |+1 Action| et recevez//une carte coûtant jusqu'à [4].//Sinon, écartez une carte de//votre main et recevez une carte//coûtant jusqu'à [2] de plus.";
FrenchCardTexts[CardNames.ROYAL_GALLEY] = "|+1 Carte|//Vous pouvez jouer une carte//Action non-Durée de votre main.//Mettez-la de côté;//dans ce cas, jouez-la//au début de votre prochain tour.";
FrenchCardTexts[CardNames.MARQUIS] = "|+1 Achat|//|+1 Carte| par carte//en main. Défaussez//jusqu'à avoir//10 cartes en main.";
FrenchCardTexts[CardNames.SENTINEL] = "Consultez les cinq premières//cartes de votre pioche. Écartez//jusqu'à deux d'entre elles.//Replacez le reste sur votre pioche//dans l'ordre de votre choix.";
FrenchCardTexts[CardNames.COURIER] = "|+[1]|//Défaussez la carte du haut//de votre pioche. Consultez//votre défausse; vous pouvez en//jouer une carte Action ou Trésor.";
FrenchCardTexts[CardNames.GALLERIA] = "|+[3]|//À ce tour, quand vous//recevez une carte coûtant//[3] ou [4], |+1 Achat|.";
FrenchCardTexts[CardNames.HUNTER] = "|+1 Action|//Dévoilez les 3 premières//cartes de votre pioche.// Parmi elles, prenez en main//une carte Action, Trésor//et Victoire. Défaussez les autres.";

FrenchCardTexts[CardNames.SYCOPHANT] = "|+1 Action|//Défaussez 3 cartes. Si vous en//avez défaussé au moins une, |+|[3].//---//Lorsque vous recevez ou //écartez cette carte, |+2 Faveurs|.";
FrenchCardTexts[CardNames.UNDERLING] = "|+1 Carte|//|+1 Action|//|+1 Faveur|";
FrenchCardTexts[CardNames.IMPORTER] = "Au début de votre prochain//tour, recevez une carte//coûtant jusqu'à [5].//---//Mise en place : chaque//joueur obtient +4 Faveurs.";
FrenchCardTexts[CardNames.CONTRACT] = "[!2]//|+1 Faveur|//Vous pouvez mettre de côté//une carte Action de votre main//pour la jouer au début//de votre prochain tour.";
FrenchCardTexts[CardNames.EMISSARY] = "|+3 Cartes|//Si ceci vous a fait mélanger//(au moins une carte),//|+1 Action| et |+2 Faveurs|.";
FrenchCardTexts[CardNames.BAUBLE] = "Choisissez deux options différentes://|+1 Achat|; +[1]; |+1 Faveur|;//à ce tour, quand vous recevez//une carte, vous pouvez//la placer sur votre pioche.";
FrenchCardTexts[CardNames.BROKER] = "Écartez une carte de votre main.//Choisissez une option, à obtenir une//fois par [1] de la carte écartée:// |+1 Carte|; |+1 Action|;//+[1]; ou |+1 Faveur|.";
FrenchCardTexts[CardNames.GUILDMASTER] = "|+[3]|//À ce tour, quand vous//recevez une carte, |+1 Faveur|.";
FrenchCardTexts[CardNames.ARCHITECTS_GUILD] = "Quand vous recevez une carte, vous pouvez dépenser//|2 Faveurs| pour recevoir une carte non-Victoire moins chère.";
FrenchCardTexts[CardNames.ORDER_OF_ASTROLOGERS] = "Quand vous mélangez, vous pouvez choisir une carte//par |Faveur| dépensée et la placer en haut de votre pioche.";
FrenchCardTexts[CardNames.LEAGUE_OF_BANKERS] = "Au début de votre phase Achat, +[1] pour chaque//4 jetons |Faveur| que vous avez (arrondi inférieurement).";
FrenchCardTexts[CardNames.MOUNTAIN_FOLK] = "Au début de votre tour, vous pouvez//dépenser |5 Faveurs| pour |+3 Cartes|.";
FrenchCardTexts[CardNames.WOODWORKERS_GUILD] = "Au début de votre phase Achat, vous pouvez dépenser//une |Faveur| pour écarter une carte Action de votre main.//Dans ce cas, recevez une carte Action.";
FrenchCardTexts[CardNames.PEACEFUL_CULT] = "Au début de votre phase Achat, dépensez autant de |Faveurs|//que souhaité pour écarter autant de cartes de votre main.";
FrenchCardTexts[CardNames.CRAFTERS_GUILD] = "Au début de votre tour, vous pouvez dépenser |2 Faveurs|//pour recevoir une carte coûtant jusqu'à [4] sur votre pioche.";
FrenchCardTexts[CardNames.CAVE_DWELLERS] = "Au début de votre tour, autant de fois que souhaité, vous pouvez//dépenser une |Faveur| pour défausser une carte puis piocher une carte.";
FrenchCardTexts[CardNames.PLATEAU_SHEPHERDS] = "Pour le décompte, appairez vos |Faveurs| avez les// cartes coûtant [2] que vous avez, pour {2} par paire. ";
FrenchCardTexts[CardNames.DESERT_GUIDES] = "Au début de votre tour, autant de fois que souhaité, vous pouvez//dépenser une |Faveur| pour défausser votre main et piocher 5 cartes.";
FrenchCardTexts[CardNames.FAMILY_OF_INVENTORS] = "Au début de votre phase Achat, vous pouvez placer un jeton//|Faveur| sur une pile non-Victoire de la Réserve. Les cartes//de cette pile coûtent [1] de moins par jeton |Faveur| dessus.";
FrenchCardTexts[CardNames.ISLAND_FOLK] = "À la fin de votre tour, vous pouvez dépenser//|5 Faveurs| pour jouer un tour supplémentaire//(mais pas un troisième consécutif).";
FrenchCardTexts[CardNames.ORDER_OF_MASONS] = " Quand vous mélangez, vous pouvez choisir jusqu'à deux cartes//par |Faveur| dépensée et les mettre dans votre défausse.";
FrenchCardTexts[CardNames.BAND_OF_NOMADS] = "Quand vous recevez une carte coûtant [3] ou plus,// vous pouvez dépenser une Faveur pour//|+1 Carte| ou |+1 Action| ou |+1 Achat|.";
FrenchCardTexts[CardNames.GANG_OF_PICKPOCKETS] = "Au début de votre tour, défaussez jusqu'à 4 cartes//à moins de dépenser une |Faveur|.";
FrenchCardTexts[CardNames.MARKET_TOWNS] = "Au début de votre phase Achat, autant de fois//que souhaité, vous pouvez dépenser une |Faveur|//pour jouer une carte Action de votre main.";
FrenchCardTexts[CardNames.FOREST_DWELLERS] = "Au début de votre tour, vous pouvez dépenser une |Faveur| pour consulter//les 3 premières cartes de votre pioche. Défaussez-en autant que//souhaité et replacez les autres sur votre pioche dans l'ordre de votre choix.";
FrenchCardTexts[CardNames.FELLOWSHIP_OF_SCRIBES] = "Après avoir joué une Action, si vous avez en main 4 cartes//ou moins, vous pouvez défausser une |Faveur| pour |+1 Carte|.";
FrenchCardTexts[CardNames.LEAGUE_OF_SHOPKEEPERS] = "Après avoir joué une Liaison,//si vous avez |5| |Faveurs| ou plus , |+|[1],//et si |10| ou plus, |+1 Action| et |+1 Achat|.";
FrenchCardTexts[CardNames.COASTAL_HAVEN] = "Au moment de défausser votre main en phase d'Ajustement, vous//pouvez dépenser autant de |Faveurs| que souhaité pour conserver autant//de cartes en main pour le prochain tour (vous piochez toujours 5).";
FrenchCardTexts[CardNames.CITY_STATE] = " Quand vous recevez une carte Action pendant votre tour, //vous pouvez dépenser |2 Faveurs| pour la jouer.";
FrenchCardTexts[CardNames.TRAPPERS_LODGE] = "Quand vous recevez une carte, vous pouvez dépenser//une |Faveur| pour la placer sur votre pioche.";
FrenchCardTexts[CardNames.CIRCLE_OF_WITCHES] = "Après avoir joué une Liaison, vous pouvez dépenser |3 Faveurs|//pour que tous vos adversaires reçoivent une Malédiction.";
FrenchCardTexts[CardNames.AUGUR_PILE] = "Cette pile commence avec//4 exemplaires de//Cueilleuse d'Herbes, Acolyte,//Sorcière Maléfique et Sibylle,// dans cette ordre. Seule la carte//du haut peut être reçue ou achetée.";
FrenchCardTexts[CardNames.HERB_GATHERER] = "|+1 Achat|//Placez votre pioche dans//votre défausse. Consultez-la.//Vous pouvez jouer un Trésor//depuis votre défausse. Vous//pouvez tourner les Augures.";
FrenchCardTexts[CardNames.ACOLYTE] = "Vous pouvez écarter une carte//Action ou Victoire de votre//main pour recevoir un Or.//Vous pouvez écarter cette//carte pour recevoir un Augure.";
FrenchCardTexts[CardNames.SORCERESS] = "|+1 Action|//Nommez une carte. Dévoilez//et prenez en main la carte//du haut de votre pioche. Si elle//correspond, tous vos adversaires//reçoivent une Malédiction.";
FrenchCardTexts[CardNames.SIBYL] = "|+4 Cartes|//|+1 Action|//Placez une carte de votre main//en haut de votre pioche,//et une autre en bas.";
FrenchCardTexts[CardNames.CLASH_PILE] = "Cette pile commence avec//4 exemplaires de//Tactique, Archère,//Seigneur de Guerre et Territoire,// dans cette ordre. Seule la carte//du haut peut être reçue ou achetée.";
FrenchCardTexts[CardNames.BATTLE_PLAN] = "|+1 Carte|//|+1 Action|//Vous pouvez dévoiler une//carte Attaque de votre main//pour |+1 Carte|. Vous pouvez//tourner une pile de la Réserve.";
FrenchCardTexts[CardNames.ARCHER] = "|+[2]|//Tous vos adversaires ayant//au moins 5 cartes en main//les dévoilent toutes sauf une,// et défaussent une carte//dévoilée de votre choix.";
FrenchCardTexts[CardNames.WARLORD] = "|+1 Action|//Au début de votre prochain tour,//|+2 Cartes|. D'ici là, vos adversaires//ne peuvent pas jouer une carte//Action de leur main dont ils ont//en jeu 2 exemplaires ou plus.";
FrenchCardTexts[CardNames.TERRITORY] = "Vaut {1} par carte Victoire//de nom différent que vous avez.//---//Quand vous recevez cette carte, recevez//un Or par pile vide de la Réserve.";
FrenchCardTexts[CardNames.FORT_PILE] = "Cette pile commence avec//4 exemplaires de//Tente, Garnison,//Fort de la Colline et Bastion,// dans cette ordre. Seule la carte//du haut peut être reçue ou achetée.";
FrenchCardTexts[CardNames.TENT] = "|+[2]|//Vous pouvez tourner//les Fortifications.//---//Quand vous défaussez cette carte//de votre zone de jeu, vous pouvez//la placer sur votre pioche.";
FrenchCardTexts[CardNames.GARRISON] = "|+[2]|//À ce tour, lorsque vous recevez//une carte, placez un jeton ici.//Au début de votre prochain tour,//retirez-les pour |+1 Carte| chacun.";
FrenchCardTexts[CardNames.HILL_FORT] = "Recevez une carte coûtant//jusqu'à [4]. Choisissez ://prenez-la en main;//ou |+1 Carte| et |+1 Action|.";
FrenchCardTexts[CardNames.STRONGHOLD] = "Choisissez : |+|[3];//ou au début de votre//prochain tour, |+3 Cartes.|//---//{!2}";
FrenchCardTexts[CardNames.ODYSSEY_PILE] = "Cette pile commence avec//4 exemplaires de//Vieille Carte, Voyage,//Trésor Englouti et Rivage Lointain,// dans cette ordre. Seule la carte//du haut peut être reçue ou achetée.";
FrenchCardTexts[CardNames.OLD_MAP] = "|+1 Carte|//|+1 Action|//Défaussez une carte. |+1 Carte|.//Vous pouvez tourner les Odyssées.";
FrenchCardTexts[CardNames.VOYAGE] = "|+1 Action|//Jouez un tour supplémentaire//après celui-ci (mais pas un//troisième consécutif) durant//lequel vous ne pouvez jouer//que 3 cartes de votre main.";
FrenchCardTexts[CardNames.SUNKEN_TREASURE] = "Recevez une carte Action//dont vous n'avez pas//d'exemplaire en jeu.";
FrenchCardTexts[CardNames.DISTANT_SHORE] = "|+2 Cartes|//|+1 Action|//Recevez un Domaine.//---//{!2}";
FrenchCardTexts[CardNames.TOWNSFOLK_PILE] = "Cette pile commence avec//4 exemplaires de//Crieuse Publique, Forgeur,//Meunier et Aînée,// dans cette ordre. Seule la carte//du haut peut être reçue ou achetée.";
FrenchCardTexts[CardNames.TOWN_CRIER] = "Choisissez une option: |+|[2];// ou recevez un Argent;// ou |+1 Carte| et |+1 Action|.////Vous pouvez tourner//les Citoyens. ";
FrenchCardTexts[CardNames.BLACKSMITH] = "Choisissez une option : piochez//jusqu'à avoir 6 cartes en main;//ou |+2 Cartes|; ou//|+1 Carte| et |+1 Action|.";
FrenchCardTexts[CardNames.MILLER] = "|+1 Action|//Consultez les quatre premières//cartes de votre pioche. Prenez-en//une en main et défaussez les autres.";
FrenchCardTexts[CardNames.ELDER] = "|+[2]|//Vous pouvez jouer une carte Action//de votre main. Si elle offre un choix//entre plusieurs capacités à ce tour//(par exemple à l'aide du mot//« choisissez »), vous pouvez en choisir//une différente supplémentaire.";
FrenchCardTexts[CardNames.WIZARD_PILE] = "Cette pile commence avec//4 exemplaires de//Apprenti Magicien, Illusionniste,//Sorcier et Liche,// dans cette ordre. Seule la carte//du haut peut être reçue ou achetée.";
FrenchCardTexts[CardNames.STUDENT] = "|+1 Action|//Vous pouvez tourner les Magiciens.//Écartez une carte de votre main.//Si c'est un Trésor, |+1 Faveur|//et placez cette carte sur votre pioche.";
FrenchCardTexts[CardNames.CONJURER] = "Recevez une carte coûtant//jusqu'à [4]. Au début de//votre prochain tour,//prenez ceci en main.";
FrenchCardTexts[CardNames.SORCERER] = "|+1 Carte|//|+1 Action|//Tous vos adversaires nomment une//carte, puis dévoilent la carte du haut//de leur pioche. En cas d'erreur,//ils reçoivent une Malédiction.";
FrenchCardTexts[CardNames.LICH] = "|+6 Cartes|//|+2 Actions|//Sautez un tour.//---//Quand vous écartez cette carte,//défaussez-la et recevez//une carte moins cher du Rebut.";

// new Seaside
FrenchCardTexts[CardNames.MONKEY] = "Jusqu'à votre prochain tour, quand//le joueur à votre droite reçoit//une carte, |+1 Carte|. Au début//de votre prochain tour, |+1 Carte|.";
FrenchCardTexts[CardNames.BLOCKADE] = "Recevez une carte coûtant jusqu'à [4],//en la mettant de côté. Au début de votre//prochain tour, prenez-là en main//Tant qu'elle est mise de côté,//quand un autre joueur en reçoit//un exemplaire durant leur tour,//il reçoit une Malédiction.";
FrenchCardTexts[CardNames.SAILOR] = "|+1 Action|//Une fois durant ce tour, quand vous recevez//une carte Durée, vous pouvez la jouer.//Au début de votre prochain tour,//+[2] et vous pouvez écarter//une carte de votre main.";
FrenchCardTexts[CardNames.PIRATE] = "Au début de votre prochain tour//recevez en main un Trésor//coûtant jusqu'à [6].//---//Quand un joueur reçoit un Trésor//vous pouvez jouer cette carte//depuis votre main.";
FrenchCardTexts[CardNames.CORSAIR] = "|+[2]|//Au début de votre prochain tour,//|+1 Carte|. D'ici là, chacun de vos//adversaires écarte le premier Argent//ou Or qu'il joue à chaque tour.";
FrenchCardTexts[CardNames.SEA_CHART] = "|+1 Carte|//|+1 Action|//Dévoilez la carte du haut de votre pioche.//Si vous en avez un exemplaire//en jeu, prenez-là en main.";
FrenchCardTexts[CardNames.ASTROLABE] = "Maintenant et au début//de votre prochain tour :////[!1]//|+1 Achat|";
FrenchCardTexts[CardNames.TIDE_POOLS] = "|+3 Cartes|//|+1 Action|//Au début de votre prochain//tour, défaussez 2 cartes.";
FrenchCardTexts[CardNames.SEA_WITCH] = "|+2 Cartes|//Tous vos adversaires reçoivent//une Malédiction. Au début de votre//prochain tour, |+2 Cartes|,//puis défaussez 2 cartes.";

FrenchCardTexts[CardNames.HOARD] = "[!2]//---//\xC0 ce tour, quand vous recevez//une carte Victoire que vous//avez achet\xE9, recevez un Or.";
FrenchCardTexts[CardNames.MINT] = "Vous pouvez d\xE9voiler une carte//Tr\xE9sor de votre main. Recevez-en//un exemplaire.//---//Quand vous achetez cette carte,//\xE9cartez tous vos Tr\xE9sors non-Dur\xE9e en jeu.";
FrenchCardTexts[CardNames.QUARRY] = "[!1]//---//\xC0 ce tour, les cartes Actions// co\xFBtent [2] de moins.";
FrenchCardTexts[CardNames.ANVIL] = "[!1]//Vous pouvez défausser un Trésor//pour recevoir une carte//coûtant jusqu'à [4].";
FrenchCardTexts[CardNames.CLERK] = "|+[2]|//Tous vos adversaires ayant//en main 5 cartes ou plus//en placent une sur leur pioche.//---//Au début de votre tour, vous pouvez//jouer cette carte depuis votre main.";
FrenchCardTexts[CardNames.INVESTMENT] = "Écartez une carte de votre main.//Choisissez : +[1]; ou écartez//cette carte pour dévoiler votre main//pour +{1} par Trésor révélé//de nom différent.";
FrenchCardTexts[CardNames.TIARA] = "|+1 Achat|//À ce tour, quand vous recevez//une carte, vous pouvez la placer//sur votre pioche. Vous pouvez jouer//deux fois un Trésor de votre main.";
FrenchCardTexts[CardNames.CHARLATAN] = "|+[!3]|//Tous vos adversaires//reçoivent une Malédiction.//---//Dans les parties utilisant//cette carte, les Malédictions//sont aussi un Trésor valant [1].";
FrenchCardTexts[CardNames.COLLECTION] = "[!2]//|+1	 Achat|//À ce tour, quand vous recevez//une carte Action, +{1}.";
FrenchCardTexts[CardNames.CRYSTAL_BALL] = "[!1]//Consultez la carte du haut de//votre pioche. Vous pouvez l'écarter,//la défausser, ou, si c'est une//Action on un Trésor, la jouer.";
FrenchCardTexts[CardNames.MAGNATE] = "Dévoilez votre main.//|+1 Carte| par Trésor dévoilé.";
FrenchCardTexts[CardNames.WAR_CHEST] = "Le jouer à votre gauche nomme//une carte. Recevez une carte//coûtant jusqu'à [5] qui//n'a pas été nommée à ce tour//pour les Trésors de guerre.";

FrenchCardTexts[CardNames.FARMLAND] = "{!2}//---//Lorsque vous recevez cette carte,//\xE9cartez une carte de votre main et//recevez une carte autre//qu'une Terre Agricole co\xFBtant//exactement [2] de plus.";
FrenchCardTexts[CardNames.HAGGLER] = "|+[2]|//---//\xC0 ce tour, quand vous recevez//une carte que vous avez achet\xE9e,//recevez une carte//non-Victoire moins ch\xE8re.";
FrenchCardTexts[CardNames.HIGHWAY] = "|+1 Carte|//|+1 Action|//---//\xC0 ce tour, les cartes//co\xFBtent [1] de moins.";
FrenchCardTexts[CardNames.BERSERKER] = "Recevez une carte coûtant//moins que celle-ci.//Tous vos adversaires défaussent//jusqu'à avoir 3 cartes en main.//---//Quand vous recevez cette carte,//jouez-la si vous avez//une carte Action en jeu.";
FrenchCardTexts[CardNames.GUARD_DOG] = "|+2 Cartes|//Si vous avez en main 5 cartes//ou moins, |+2 Cartes|.//---//Quand un autre joueur joue une//Attaque, vous pouvez d'abord jouer//cette carte depuis votre main.";
FrenchCardTexts[CardNames.NOMADS] = "|+1 Achat|//|+[2]|//---//Quand vous recevez ou//écartez cette carte, +[2].";
FrenchCardTexts[CardNames.TRAIL] = "|+1 Carte|//|+1 Action|//---//Quand vous recevez, écartez,//ou défaussez cette carte en//dehors de la phase Ajustement,//vous pouvez la jouer.";
FrenchCardTexts[CardNames.WEAVER] = "Recevez deux Argents ou//une carte coûtant jusqu'à [4].//---//Quand vous défaussez cette carte//en dehors de la phase Ajustement,//vous pouvez la jouer.";
FrenchCardTexts[CardNames.SOUK] = "|+1 Achat|//|+[7]|//-[1] par carte en main. (Vous ne pouvez//pas aller en-dessous de [0].)//---//Quand vous recevez cette carte, écartez//jusqu'à 2 cartes de votre main.";
FrenchCardTexts[CardNames.CAULDRON] = "[!2]//|+1 Achat|//La troisième fois que vous//recevez une Action à ce tour,//tous vos adversaires//reçoivent une Malédiction.";
FrenchCardTexts[CardNames.WHEELWRIGHT] = "|+1 Carte|//|+1 Action|//Vous pouvez défausser une carte//pour recevoir une Action//coûtant autant ou moins.";
FrenchCardTexts[CardNames.WITCHS_HUT] = "|+4 Cartes|//Défaussez 2 cartes, dévoilées.//Si les deux sont des Actions,//tous vos adversaires//reçoivent une Malédiction.";
// Plunder
FrenchCardTexts[CardNames.CAGE] = "Mettez de côté jusqu'à//4 cartes de votre main//face cachée (sous cette carte).//La prochaine fois que vous//recevez une carte Victoire,//écartez cette carte et prenez//en main les cartes mises de côté//à la fin de votre tour.";
FrenchCardTexts[CardNames.GROTTO] = "|+1 Action|////Mettez de côté jusqu'à//4 cartes de votre main//face cachée (sous cette carte).//Au début de votre//prochain tour, défaussez-les,//puis piochez-en autant.";
FrenchCardTexts[CardNames.JEWELLED_EGG] = "[!1]//|+1 Achat|//---//Quand vous écartez cette//carte, recevez un Trophée.";
FrenchCardTexts[CardNames.SEARCH] = "|+[2]|////La prochaine fois qu'une//pile de la Réserve devient vide,//écartez cette carte et//recevez un Trophée.";
FrenchCardTexts[CardNames.SHAMAN] = "|+1 Action|//|+[1]|////Vous pouvez écarter//une carte de votre main.//---//Dans les parties utilisant//cette carte, au début de//votre tour, recevez une carte//du rebut coûtant jusqu'à [6].";
FrenchCardTexts[CardNames.SECLUDED_SHRINE] = "|+[1]|////La prochaine fois que vous//recevez un Trésor, écartez//jusqu'à 2 cartes de votre main.";
FrenchCardTexts[CardNames.SIREN] = "Tous vos adversaires//reçoivent une Malédiction.////Au début de votre prochain tour, piochez//jusqu'à avoir 8 cartes en main.//---//Quand vous recevez cette carte,//écartez-la à moins d'écarter une//Action de votre main.";
FrenchCardTexts[CardNames.STOWAWAY] = "Au début de votre//prochain tour, |+2 Cartes|.//---//Quand un joueur reçoit//une carte Durée, vous//pouvez jouer cette carte//de votre main.";
FrenchCardTexts[CardNames.TASKMASTER] = "|+1 Action|, |+|[1], et si à ce tour//vous recevez une carte//coûtant exactement [5],//répétez cette capacité//au début de votre prochain tour.";
FrenchCardTexts[CardNames.ABUNDANCE] = "La prochaine fois que vous//recevez une carte Action,//|+1 Achat| et |+|[3].";
FrenchCardTexts[CardNames.CABIN_BOY] = "|+1 Carte|//|+1 Action|////Au début de votre prochain tour,//choisissez: |+|[2];//ou écartez cette carte pour//recevoir une carte Durée.";
FrenchCardTexts[CardNames.CRUCIBLE] = "Écartez une carte//de votre main.//|+|[1] par [1] de son coût.";
FrenchCardTexts[CardNames.FLAGSHIP] = "|+[2]|////La prochaine fois que//vous jouez une carte Action//non-Ordre, rejouez-la.";
FrenchCardTexts[CardNames.FORTUNE_HUNTER] = "|+[2]|////Consultez les 3 premières//cartes de votre pioche.//Vous pouvez en jouer//un Trésor. Replacez les//autres dans l’ordre//de votre choix.";
FrenchCardTexts[CardNames.GONDOLA] = "Maintenant ou au début//de votre prochain tour: |+|[2].//---//Quand vous recevez cette carte,//vous pouvez jouer//une carte Action de votre main.";

FrenchCardTexts[CardNames.HARBOR_VILLAGE] = "|+1 Carte|//|+2 Actions|////Après la prochaine Action//que vous jouez à ce tour//si elle vous a donné//|+|[], |+|[1]."; //TODO
FrenchCardTexts[CardNames.LANDING_PARTY] = "|+2 Cartes|//|+2 Actions|////La prochaine fois que la première carte//que vous jouez à un tour est un Trésor,//placez ensuite cette carte sur votre pioche."; // TODO

FrenchCardTexts[CardNames.MAPMAKER] = "Consultez les 4 premières cartes//de votre pioche. Prenez-en 2 en main//et défaussez les autres.//---//Quand un joueur reçoit//une carte Victoire, vous pouvez//jouez cette carte de votre main.";
FrenchCardTexts[CardNames.MAROON] = "Écartez une carte de votre main.//|+2 Cartes| pour chacun de ses types.//(Action, Attaque, etc.).";
FrenchCardTexts[CardNames.ROPE] = "[!1]//|+1 Achat|////Au début de votre prochain tour,//|+1 Carte| et vous pouvez écarter//une carte de votre main.";
FrenchCardTexts[CardNames.SWAMP_SHACKS] = "|+2 Actions|////|+1 Carte| pour chaque 3 cartes que//vous avez dans votre zone de jeu//(arrondi à l'unité inférieure).";

FrenchCardTexts[CardNames.TOOLS] = "Recevez un exemplaire d'une carte//qu'un joueur a en jeu."; //TODO

FrenchCardTexts[CardNames.BURIED_TREASURE] = "Au début de votre prochain tour,//|+1 Achat| et |+|[3].//---//Lorsque vous recevez cette carte,//jouez-la.";
FrenchCardTexts[CardNames.CREW] = "|+3 Cartes|////Au début de votre prochain tour,//replacez cette carte sur votre pioche.";
FrenchCardTexts[CardNames.CUTTHROAT] = "Tous vos adversaires défaussent//jusqu'à avoir 3 cartes en main.//La prochaine fois qu'un joueur//reçoit un Trésor coûtant//[5] ou plus, recevez un Trophée.";
FrenchCardTexts[CardNames.ENLARGE] = "Maintenant et au début de votre//prochain tour: Écartez une carte//de votre main, et recevez une carte//coûtant jusqu'à [2] de plus.";
FrenchCardTexts[CardNames.FIGURINE] = "|+2 Cartes|////Vous pouvez défausser//une carte Action pour//|+1 Achat| et |+|[1].";
FrenchCardTexts[CardNames.FIRST_MATE] = "Jouez autant que souhaité//des cartes Action de même nom//de votre main, puis pioche//jusqu'à avoir 6 cartes en main."; // REVIEW
FrenchCardTexts[CardNames.FRIGATE] = "|+[3]|////Jusqu'au début de votre prochain//tour, quand un adversaire joue une//carte Action, il défausse jusqu'à avoir//4 cartes en main par la suite."; // REVIEW
FrenchCardTexts[CardNames.LONGSHIP] = "|+2 Actions|////Au début de votre prochain//tour, |+2 Cartes|.";
FrenchCardTexts[CardNames.MINING_ROAD] = "|+1 Action|//|+1 Achat|//|+[2]|////Une fois ce tour, lorsque vous recevez//un Trésor, vous pouvez le jouer.";
FrenchCardTexts[CardNames.PENDANT] = "|+|[1] par Trésor//de nom différent//que vous avez en jeu.";
FrenchCardTexts[CardNames.PICKAXE] = "[!1]////Écartez une carte de votre main.//Si elle coûte [3] ou plus,//recevez en main un Trophée.";
FrenchCardTexts[CardNames.PILGRIM] = "|+4 Cartes|////Placez une carte de votre main//sur votre pioche.";
FrenchCardTexts[CardNames.QUARTERMASTER] = "Au début de chacun vos tour,//pour la suite de la partie, choisissez ://recevez et mettez de côté ici//une carte coûtant jusqu'à [4];//ou prenez en main une carte//mise ici de côté.";
FrenchCardTexts[CardNames.SILVER_MINE] = "Recevez en main un Trésor//coûtant moins que cette carte.";
FrenchCardTexts[CardNames.TRICKSTER] = "Tous vos adversaires//reçoivent une Malédiction.//Une fois à ce tour,//quand vous défaussez un Trésor//de votre zone de jeu,//vous pouvez le mettre de côté.//Prenez-le en main à la fin du tour.";
FrenchCardTexts[CardNames.WEALTHY_VILLAGE] = "|+1 Carte|//|+2 Actions|//---//Quand vous recevez cette carte,//si vous avez en jeu au moins//3 Trésors de noms différents,//recevez un Trophée.";
FrenchCardTexts[CardNames.SACK_OF_LOOT] = "[!1]//|+1 Achat|//Recevez un Trophée.";
FrenchCardTexts[CardNames.KINGS_CACHE] = "Vous pouvez jouer un trésor//depuis votre main 3 fois.";
FrenchCardTexts[CardNames.BURY] = "|+1 Achat|////Placez n'importe quelle carte de votre défausse//en-dessous de votre pioche.";
FrenchCardTexts[CardNames.AVOID] = "|+1 Achat|////La prochaine fois que vous mélangez à ce tour,//choisissez jusqu'à 3 cartes à placer dans votre défausse.";
FrenchCardTexts[CardNames.DELIVER] = "|+1 Achat|////À ce tour, chaque fois que vous recevez une carte,//mettez-la de côté et prenez-la en main à la fin du tour.";
FrenchCardTexts[CardNames.PERIL] = "Vous pouvez écarter une carte Action de votre main//pour recevoir un Trophée.";
FrenchCardTexts[CardNames.RUSH] = "|+1 Achat|////La prochaine vous que vous recevez//une carte Action à ce tour, jouez-la.";
FrenchCardTexts[CardNames.FORAY] = "Défaussez 3 cartez, en les dévoilant. Si elles ont// 3 noms différents, recevez un Trophée.";
FrenchCardTexts[CardNames.LAUNCH] = "Une fois par tour : retournez à votre phase Action.//|+1 Carte|, |+1 Action|, et |+1 Achat|.";
FrenchCardTexts[CardNames.MIRROR] = "|+1 Achat|////La prochaine fois que vous recevez une carte Action à ce tour,//recevez-en un exemplaire.";
FrenchCardTexts[CardNames.PREPARE] = "Mettez de côté votre main face visible. Au début de//votre prochain tour, jouez les Actions et Trésors//dans l'ordre de votre choix, puis défaussez le reste.";
FrenchCardTexts[CardNames.SCROUNGE] = "Choisissez : écartez une carte de votre main;//ou recevez un Domaine depuis le rebut, et dans ce cas,//recevez une carte coûtant jusqu'à [5].";
FrenchCardTexts[CardNames.JOURNEY] = "Ne défaussez pas les cartes en jeu en phase d'Ajustement à ce tour.//Jouez un tour supplémentaire après celui-ci//(mais pas un troisième consécutif).";
FrenchCardTexts[CardNames.MAELSTROM] = "Écartez 3 cartes de votre main. Tous vos adversaires//ayant en main 5 cartes ou plus en écartent une.";
FrenchCardTexts[CardNames.LOOTING] = "Recevez un Trophée.";
FrenchCardTexts[CardNames.INVASION] = "Vous pouvez jouer une Attaque depuis votre main. Recevez un Duché.//Recevez une Action sur votre pioche. Recevez un Trophée; jouez-le.";
FrenchCardTexts[CardNames.PROSPER] = "Recevez un Trophée, ainsi que n'importe quel nombre//de Trésors de noms différents.";
FrenchCardTexts[CardNames.AMPHORA] = "Maintenant ou au début//de votre prochain tour ://|+1 Achat| et |+|[3].";
FrenchCardTexts[CardNames.DOUBLOONS] = "[!3]////---//Quand vous recevez cette carte,//recevez un Or.";
FrenchCardTexts[CardNames.ENDLESS_CHALICE] = "Maintenant et au début de tous//vos tours pour la suite de la partie ://[!1]//|+1 Achat|";
FrenchCardTexts[CardNames.FIGUREHEAD] = "[!3]////Au début de votre//prochain tour, |+2 Cartes|.";
FrenchCardTexts[CardNames.HAMMER] = "[!3]////Recevez une carte coûtant//jusqu'à [4].";
FrenchCardTexts[CardNames.INSIGNIA] = "[!3]////À ce tour, quand vous//recevez une carte, vous pouvez//la placer sur votre pioche.";
FrenchCardTexts[CardNames.JEWELS] = "[!3]//|+1 Achat|////Au début de votre prochain//tour, placez cette carte//en-dessous de votre pioche.";
FrenchCardTexts[CardNames.ORB] = "Consultez votre défausse. Choisissez ://jouez-en une carte Action ou Trésor,////ou |+1 Achat| et |+|[3].";
FrenchCardTexts[CardNames.PRIZE_GOAT] = "[!3]//|+1 Achat|////Vous pouvez écarter//une carte de votre main.";
FrenchCardTexts[CardNames.PUZZLE_BOX] = "[!3]//|+1 Achat|////Vous pouvez mettre de côté//une carte de cotre main face cachée.//Prenez-la en main//à la fin du tour.";
FrenchCardTexts[CardNames.SEXTANT] = "[!3]//|+1 Achat|////Consultez les 5 cartes du haut//de votre pioche. Défaussez-en autant//que souhaité, et replacez le reste//dans l'ordre de votre choix.";
FrenchCardTexts[CardNames.SHIELD] = "[!3]//|+1 Achat|//---//Quand un adversaire joue une Attaque,//vous pouvez d'abord révéler//cette carte de votre main//pour ne pas être affecté.";
FrenchCardTexts[CardNames.SPELL_SCROLL] = "Écartez cette carte pour//recevoir une carte moins chère.//Si c'est une Action ou un Trésor,//vous pouvez la jouer.";
FrenchCardTexts[CardNames.STAFF] = "[!3]//|+1 Achat|////Vous pouvez jouer une carte Action//depuis votre main.";
FrenchCardTexts[CardNames.SWORD] = "[!3]//|+1 Achat|////Tous vos adversaires défaussent//jusqu'à avoir 4 cartes en main.";
FrenchCardTexts[CardNames.CHEAP] = "Les cartes Abordables coûtent [1] de moins.";
FrenchCardTexts[CardNames.CURSED] = "Quand vous recevez une carte Maudite,//recevez un Trophée et une Malédiction.";
FrenchCardTexts[CardNames.FAWNING] = "Quand vous recevez une Province,//recevez une carte Servile.";
FrenchCardTexts[CardNames.FATED] = "Quand vous mélangez, vous pouvez consulter les cartes et dévoiler//des cartes Destinées pour les placer en haut ou en bas.";
FrenchCardTexts[CardNames.FRIENDLY] = "Au début de votre phase Ajustement//vous pouvez défausser une carte Amicale//pour recevoir une carte Amicale.";
FrenchCardTexts[CardNames.HASTY] = "Quand vous recevez une carte Impatiente,//mettez-la de côté et jouez-la//au début de votre prochain tour.";
FrenchCardTexts[CardNames.INHERITED] = "Mise en place : vous commencez la partie avec une carte Héritée//à la place d'une carte de départ de votre choix.";
FrenchCardTexts[CardNames.INSPIRING] = "Après avoir joué une carte Exaltante à votre tour,//vous pouvez jouer depuis votre main une Action//dont vous n'avez pas d'exemplaire en jeu.";
FrenchCardTexts[CardNames.NEARBY] = "Quand vous recevez une carte Proche, |+1 Achat|.";
FrenchCardTexts[CardNames.PATIENT] = "Au début de votre phase Ajustement, vous pouvez//mettre de côté des cartes Patientes depuis votre main//pour les jouer au début de votre prochain tour.";
FrenchCardTexts[CardNames.PIOUS] = "Quand vous recevez une carte Pieuse, vous pouvez//écarter une carte de votre main.";
FrenchCardTexts[CardNames.RECKLESS] = "Suivez les instructions des cartes Téméraires jouées deux fois.//Quand vous en défaussez une de votre zone de jeu,//replacez-la dans sa pile.";
FrenchCardTexts[CardNames.RICH] = "Quand vous recevez une carte Riche, recevez un Argent.";
FrenchCardTexts[CardNames.SHY] = "Au début de votre tour, vous pouvez défausser//une carte Timide pour |+2 Cartes|.";
FrenchCardTexts[CardNames.TIRELESS] = "Quand vous défaussez une carte Infatigable//de votre zone de jeu, mettez-la de côté//et placez-la sur votre pioche à la fin de votre tour.";
FrenchCardTexts[CardNames.MARCHLAND] = "Vaut {1} pour chaque//3 cartes Victoire que vous avez.//---//Quand vous recevez ceci, |+1 Achat|,//et défaussez autant de cartes//que souhaité pour +[1] chacune.";

FrenchCardTexts[CardNames.SHOP] = "|+1 Carte|//|+[1]|//Vous pouvez jouer depuis votre main//une carte Action dont vous//n'avez pas d'exemplaire en jeu.\n";
FrenchCardTexts[CardNames.CARNIVAL] = "Dévoilez les 4 premières//cartes de votre pioche.//Prenez en main une carte//de chaque nom différent dévoilé//et défaussez les autres.";
FrenchCardTexts[CardNames.FERRYMAN] = "|+2 Cartes|//|+1 Action|//Défaussez une carte.//---//Mise en place : choisissez une pile//de cartes Royaume non utilisées//coûtant [3] or [4].//Recevez-en un exemplaire//lorsque vous recevez un Passeur.";
FrenchCardTexts[CardNames.FARMHANDS] = "|+1 Carte|//|+2 Actions|//---//Quand vous recevez cette carte,//vous pouvez mettre de côté//une carte Action ou Trésor//de votre main, et la jouer//au début de votre prochain tour.";
FrenchCardTexts[CardNames.JOUST] = "|+1 Carte|//|+1 Action|//|+[1]|//Vous pouvez mettre de côté une//Province depuis votre main pour//recevoir en main une Récompense.//Défaussez la Province//en phase d'Ajustement.";
FrenchCardTexts[CardNames.REWARDS_PILE] = "--";
FrenchCardTexts[CardNames.COURSER] = "Choisissez deux//options différentes ://|+2 Cartes|; |+2 Actions|;//|+|[2]; recevez 4 Argents.////%(Ne fait pas partie de la Réserve.)%";
FrenchCardTexts[CardNames.RENOWN] = "|+1 Achat|////À ce tour, les cartes//coûtent [2] de moins.////%(Ne fait pas partie de la Réserve.)%";
FrenchCardTexts[CardNames.CORONET] = "Vous pouvez jouer deux fois//une carte Action non-Récompense//depuis votre main.////Vous pouvez jouer deux fois//une carte Trésor non-Récompense//depuis votre main..////%(Ne fait pas partie de la Réserve.)%";
FrenchCardTexts[CardNames.DEMESNE] = "|+2 Actions|//|+2 Achats|//Recevez un Or.//---//Vaut {1} par Or que vous avez.////%(Ne fait pas partie de la Réserve.)%";
FrenchCardTexts[CardNames.HOUSECARL] = "|+1 Carte| par carte Action de nom//différent que vous avez en jeu.////%(Ne fait pas partie de la Réserve.)%";
FrenchCardTexts[CardNames.HUGE_TURNIP] = "|+2 Coffres|//|+|[1] par Coffres que vous avez.////%(Ne fait pas partie de la Réserve.)%";
FrenchCardTexts[CardNames.FARRIER] = "|+1 Carte|//|+1 Action|//|+1 Achat|//---//Surpaiement : |+1 Carte| à la fin//de ce tour par [1] surpayé.";
FrenchCardTexts[CardNames.INFIRMARY] = "|+1 Carte|//Vous pouvez écarter une carte//de votre main.//---//Surpaiement : jouez cette carte//une fois par [1] surpayé.";
FrenchCardTexts[CardNames.FOOTPAD] = "|+2 Coffres|//Tous vos adversaires défaussent//jusqu'à avoir 3 cartes en main.//---//Si le Gredin est dans le Royaume,//quand vos recevez une carte//pendant une phase Action, |+1 Carte|.\n";

FrenchCardTexts[CardNames.FISHMONGER] = "|+1 Achat|//|+[1]|//---//Vous pouvez jouer cette carte//depuis votre pioche comme si//elle était en main.";
FrenchCardTexts[CardNames.SNAKE_WITCH] = "|+1 Carte|//|+1 Action|//Si les cartes de votre main ont//des noms différents, vous pouvez//la dévoiler et retourner cette carte//sur sa pile pour que tous vos//adversaires reçoivent une Malédiction.";
FrenchCardTexts[CardNames.ARISTOCRAT] = "Si le nombre d'Aristocrates//que vous avez en jeu vaut:////1 ou 5 : |+3 Actions|;//2 ou 6 : |+3 Cartes|;//3 ou 7 : |+|[3];//4 ou 8 : |+3 Achats|.";
FrenchCardTexts[CardNames.CRAFTSMAN] = "|+[2D]|////Recevez une carte//coûtant jusqu'à [5].";
FrenchCardTexts[CardNames.RIVERBOAT] = "Au début de votre prochain tour,//jouez la carte mise de côté,//sans la déplacer.//---//Mise en place : mettez de côté//une carte Action non-Durée//non utilisée coûtant [5].";
FrenchCardTexts[CardNames.ROOT_CELLAR] = "|+3 Cartes|//|+1 Action|//|+[3D]|";
FrenchCardTexts[CardNames.ALLEY] = "|+1 Carte|//|+1 Action|//Défaussez une carte.//---//Vous pouvez jouer cette carte//depuis votre pioche comme si//elle était en main.";
FrenchCardTexts[CardNames.CHANGE] = "Si vous avez [D], |+|[3].////Sinon, écartez une carte//de votre main et recevez//une carte coûtant plus en [].// +[D] égal à la différence en [].";
FrenchCardTexts[CardNames.NINJA] = "|+1 Carte|//Tous vos adversaires défaussent//jusqu'à avoir 3 cartes en main.//---//Vous pouvez jouer cette carte//depuis votre pioche comme si//elle était en main.";
FrenchCardTexts[CardNames.POET] = "|+1 <>|//|+1 Carte|//|+1 Action|//Dévoilez la carte du haut//de votre pioche. Si elle coûte//[3] ou moins, prenez-la en main.";
FrenchCardTexts[CardNames.RIVER_SHRINE] = "|+1 <>|////Écartez jusqu'à 2 cartes//de votre main. Au début de la//phase Ajustement, si vous n'avez//pas reçu de carte pendant votre//phase Achat à ce tour, recevez//une carte coûtant jusqu'à [4].";
FrenchCardTexts[CardNames.RUSTIC_VILLAGE] = "|+1 <>|//|+1 Carte|//|+2 Actions|//Vous pouvez défausser//2 cartes pour |+1 Carte|.";
FrenchCardTexts[CardNames.GOLD_MINE] = "|+1 Carte|//|+1 Action|//|+1 Achat|//Vous pouvez recevoir//un Or et obtenir +[4D].";
FrenchCardTexts[CardNames.IMPERIAL_ENVOY] = "|+5 Cartes|//|+1 Achat|//|+[2D]|";
FrenchCardTexts[CardNames.KITSUNE] = "|+1 <>|//Choisissez deux options//différentes : |+|[2]; |+2 Actions|;//tous vos adversaires//reçoivent une Malédiction;//recevez un Argent.";
FrenchCardTexts[CardNames.LITTER] = "|+2 Cartes|//|+2 Actions|//|+[1D]|";
FrenchCardTexts[CardNames.RICE_BROKER] = "|+1 Action|//Écartez une carte de votre main.//Si c'est un Trésor, |+2 Cartes|.//Si c'est une Action, |+5 Cartes|.";
FrenchCardTexts[CardNames.RONIN] = "Piochez jusqu'à//avoir 7 cartes en main.//---//Vous pouvez jouer cette carte//depuis votre pioche comme si//elle était en main.";
FrenchCardTexts[CardNames.TANUKI] = "Écartez une carte de votre main.//Recevez une carte coûtant//jusqu'à [2] de plus.//---//Vous pouvez jouer cette carte//depuis votre pioche comme si//elle était en main.";
FrenchCardTexts[CardNames.TEA_HOUSE] = "|+1 <>|//|+1 Carte|//|+1 Action|//|+[2]|";
FrenchCardTexts[CardNames.SAMURAI] = "Tous vos adversaires défaussent//jusqu'à avoir 3 cartes en main//(une seule fois).//Au début de chacun de vos tours,//+[1].//%(Cette carte reste en jeu.)%";
FrenchCardTexts[CardNames.RICE] = "|+1 Achat|//|+|[1] par type de carte différent//que vous avez en jeu.";
FrenchCardTexts[CardNames.MOUNTAIN_SHRINE] = "|+1 <>|//+[2]//Vous pouvez écarter//une carte de votre main.//Ensuite, s'il y a au moins une//carte Action dans le Rebut,//|+2 Cartes|";
FrenchCardTexts[CardNames.DAIMYO] = "|+1 Carte|//|+1 Action|//La prochaine fois que vous//jouez une carte Action non-Ordre//à ce tour, rejouez-la ensuite.";
FrenchCardTexts[CardNames.ARTIST] = "|+1 Action|////|+1 Carte| par carte dont//vous avez exactement//un exemplaire en jeu.";
FrenchCardTexts[CardNames.APPROACHING_ARMY] = "Après que vous avez joué une carte Attaque, +[1].//---//Mise en place : ajoutez une Attaque à la partie.";
FrenchCardTexts[CardNames.BIDING_TIME] = "Au début de votre phase Ajustement, mettez de côte votre main face cachée.//Au début de votre prochain tour, prenez en main ces cartes.";
FrenchCardTexts[CardNames.BUREAUCRACY] = "Quand vous recevez une carte qui// ne coûte pas [0], recevez un Cuivre.";
FrenchCardTexts[CardNames.DIVINE_WIND] = "Quand vous retirez le dernier| |<>, retirez//toutes les cartes Royaume de la réserve et mettez//en place 10 nouvelles piles choisies au hasard.";
FrenchCardTexts[CardNames.ENLIGHTENMENT] = "Les Trésors sont aussi des Actions. Quand vous//jouez un Trésor dans une phase Action, au lieu de//suivre ses instructions, |+1 Carte| et |+1 Action|.";
FrenchCardTexts[CardNames.GOOD_HARVEST] = "La première fois que vous jouez un Trésor de nom différent//à chaque tour, d'abord, |+1 Achat| et +[1].";
FrenchCardTexts[CardNames.FLOURISHING_TRADE] = "Les cartes coûtent [1] de moins.//Vous pouvez utiliser les Actions comme des Achats.";
FrenchCardTexts[CardNames.GREAT_LEADER] = "Après chaque carte Action que vous jouez, |+1 Action|.";
FrenchCardTexts[CardNames.GROWTH] = "Quand vous recevez un Trésor,//recevez une carte moins chère.";
FrenchCardTexts[CardNames.HARSH_WINTER] = "Quand vous recevez une carte à votre tour,//s'il y a [D] sur sa pile, prenez-le;//sinon placez [2D] sur sa pile.";
FrenchCardTexts[CardNames.KIND_EMPEROR] = "Au début de votre tour, et quand vous retirez//le dernier| |<> : recevez en main une carte Action.";
FrenchCardTexts[CardNames.PANIC] = "Quand vous jouez un Trésor, |+2 Achats|. Quand vous en//défaussez un de votre zone de jeu, retournez-le sur sa pile.";
FrenchCardTexts[CardNames.PROGRESS] = "Quand vous recevez une carte,//placez-la sur votre pioche.";
FrenchCardTexts[CardNames.RAPID_EXPANSION] = "Quand vous recevez une Action ou un Trésor,//mettez-le de côté et jouez-le au début de votre prochain tour.";
FrenchCardTexts[CardNames.SICKNESS] = "Au début de votre tour, choisissez://recevez une Malédiction sur votre pioche ; ou défaussez 3 cartes.";
FrenchCardTexts[CardNames.AMASS] = "Si vous n'avez pas de carte Action en jeu,//recevez une carte Action coûtant jusqu'à [5].";
FrenchCardTexts[CardNames.ASCETICISM] = "Payez un montant quelconque de []//pour écarter autant de cartes de votre main.";
FrenchCardTexts[CardNames.CREDIT] = "Recevez une Action ou un Trésor coûtant//jusqu'à [8]. +[D] égal à son coût.";
FrenchCardTexts[CardNames.FORESIGHT] = "Dévoilez des cartes de votre pioche jusqu'à dévoiler une Action.//Mettez-la de côté de défaussez le reste.//Prenez-la à la fin de votre tour.";
FrenchCardTexts[CardNames.KINTSUGI] = "Écartez une carte de votre main. Si vous avez reçu un Or pendant cette partie,//recevez une carte coûtant jusqu'à [2] de plus que la carte écartée.";
FrenchCardTexts[CardNames.PRACTICE] = "Vous pouvez jouer deux fois une carte Action de votre main.";
FrenchCardTexts[CardNames.SEA_TRADE] = "|+1 Carte| par carte Action que vous avez en jeu.//Écartez jusqu'à ce nombre de cartes depuis votre main.";
FrenchCardTexts[CardNames.RECEIVE_TRIBUTE] = "Si vous avez reçu au moins 3 cartes à ce tour,//recevez jusqu'à 3 cartes Action de noms différents//dont vous n'avez pas d'exemplaire en jeu.";
FrenchCardTexts[CardNames.GATHER] = "Recevez une carte coûtant exactement [3],//une carte coûtant exactement [4],//et une carte coûtant exactement [5].";
FrenchCardTexts[CardNames.CONTINUE] = "Une fois par tour : recevez une carte Action non-Attaque coûtant jusqu'à [4].//Retournez à votre phase Action et jouez la carte reçue. |+1 Action| et |+1 Achat|.";