"use strict";

var DutchAbilityNames = {};

DutchAbilityNames[AbilityNames.BUY] = "a";
DutchAbilityNames[AbilityNames.PLAY_ATTACK] = "play attack";
DutchAbilityNames[AbilityNames.DRAW_INSTRUCTIONS] = "b";
DutchAbilityNames[AbilityNames.PLAY_INSTRUCTIONS] = "play";
DutchAbilityNames[AbilityNames.RESOLVE_EVENT] = "c";
DutchAbilityNames[AbilityNames.GAIN_BY_NAME] = "d";

DutchAbilityNames[AbilityNames.FISHING_VILLAGE_ABILITY] = DutchCardNames[CardNames.FISHING_VILLAGE].singular;
DutchAbilityNames[AbilityNames.REMOVE_COST_REDUCTION] = "e";
DutchAbilityNames[AbilityNames.HAVEN_ABILITY] = DutchCardNames[CardNames.HAVEN].singular;
DutchAbilityNames[AbilityNames.SAVE_RETURN_TO_HAND] = DutchCardNames[CardNames.SAVE].singular;
DutchAbilityNames[AbilityNames.CARAVAN_ABILITY] = DutchCardNames[CardNames.CARAVAN].singular;
DutchAbilityNames[AbilityNames.MERCHANT_SHIP_ABILITY] = DutchCardNames[CardNames.MERCHANT_SHIP].singular;
DutchAbilityNames[AbilityNames.OUTPOST_ABILITY] = DutchCardNames[CardNames.OUTPOST].singular;
DutchAbilityNames[AbilityNames.TACTICIAN_ABILITY] = DutchCardNames[CardNames.TACTICIAN].singular;
DutchAbilityNames[AbilityNames.WHARF_ABILITY] = DutchCardNames[CardNames.WHARF].singular;
DutchAbilityNames[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = DutchCardNames[CardNames.HORSE_TRADERS].singular;
DutchAbilityNames[AbilityNames.DUCHESS_MAY_GAIN] = DutchCardNames[CardNames.DUCHESS].singular;
DutchAbilityNames[AbilityNames.FOOLS_GOLD_TRASH] = DutchCardNames[CardNames.FOOLS_GOLD].singular;
DutchAbilityNames[AbilityNames.NOBLE_BRIGAND] = DutchCardNames[CardNames.NOBLE_BRIGAND].singular;
DutchAbilityNames[AbilityNames.HAGGLER_ABILITY] = DutchCardNames[CardNames.HAGGLER].singular;
DutchAbilityNames[AbilityNames.INN_ABILITY] = DutchCardNames[CardNames.INN].singular;
DutchAbilityNames[AbilityNames.MANDARIN] = DutchCardNames[CardNames.MANDARIN].singular;
DutchAbilityNames[AbilityNames.FARMLAND] = DutchCardNames[CardNames.FARMLAND].singular;
DutchAbilityNames[AbilityNames.GAIN_CHEAPER] = "Gain Cheaper";
DutchAbilityNames[AbilityNames.GAIN_TWO_RUINS] = "Gain 2 Ruins";
DutchAbilityNames[AbilityNames.HUNTING_GROUNDS] = DutchCardNames[CardNames.HUNTING_GROUNDS].singular;
DutchAbilityNames[AbilityNames.SQUIRE_GAIN_ATTACK] = "Gain Attack";
DutchAbilityNames[AbilityNames.URCHIN_TRANSFORM] = "Trash Urchin";
DutchAbilityNames[AbilityNames.FEODUM_GAIN_SILVERS] = "Gain 3 Silvers";
DutchAbilityNames[AbilityNames.DOCTOR_OVERPAY] = "Overpay Doctor";
DutchAbilityNames[AbilityNames.HERALD_OVERPAY] = "Overpay Herald";
DutchAbilityNames[AbilityNames.MASTERPIECE_OVERPAY] = "Overpay Masterpiece";
DutchAbilityNames[AbilityNames.STONEMASON_OVERPAY] = "Overpay Stonemason";
DutchAbilityNames[AbilityNames.EXCHANGE] = "Exchange";
DutchAbilityNames[AbilityNames.SHUFFLE] = "Shuffle";
DutchAbilityNames[AbilityNames.AMULET] = DutchCardNames[CardNames.AMULET].singular;
DutchAbilityNames[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = DutchCardNames[CardNames.CARAVAN_GUARD].singular;
DutchAbilityNames[AbilityNames.DUNGEON_ABILITY] = DutchCardNames[CardNames.DUNGEON].singular;
DutchAbilityNames[AbilityNames.GEAR_ABILITY] = DutchCardNames[CardNames.GEAR].singular;
DutchAbilityNames[AbilityNames.MESSENGER_GAIN] = DutchCardNames[CardNames.MESSENGER].singular;
DutchAbilityNames[AbilityNames.BRIDGE_TROLL_ABILITY] = DutchCardNames[CardNames.BRIDGE_TROLL].singular;
DutchAbilityNames[AbilityNames.HAUNTED_WOODS_DURATION] = "Haunted Woods Draw";
DutchAbilityNames[AbilityNames.HAUNTED_WOODS_TOPDECK] = "Haunted Woods Topdeck";
DutchAbilityNames[AbilityNames.LOST_CITY] = DutchCardNames[CardNames.LOST_CITY].singular;
DutchAbilityNames[AbilityNames.WINE_MERCHANT_DISCARD] = DutchCardNames[CardNames.WINE_MERCHANT].singular;
DutchAbilityNames[AbilityNames.HIRELING] = DutchCardNames[CardNames.HIRELING].singular;
DutchAbilityNames[AbilityNames.SWAMP_HAG_DURATION] = DutchCardNames[CardNames.SWAMP_HAG].singular;
DutchAbilityNames[AbilityNames.TRASH_TOKEN_ABILITY] = "Trash Token";
DutchAbilityNames[AbilityNames.EMPORIUM_GAIN_POINTS] = "Gain Emporium VP";
DutchAbilityNames[AbilityNames.ROCKS_GAINS_SILVER] = "Gain Silver";
DutchAbilityNames[AbilityNames.FORTUNE] = DutchCardNames[CardNames.FORTUNE].singular;
DutchAbilityNames[AbilityNames.CRUMBLING_CASTLE] = DutchCardNames[CardNames.CRUMBLING_CASTLE].singular;
DutchAbilityNames[AbilityNames.HAUNTED_CASTLE] = DutchCardNames[CardNames.HAUNTED_CASTLE].singular;
DutchAbilityNames[AbilityNames.SPRAWLING_CASTLE] = DutchCardNames[CardNames.SPRAWLING_CASTLE].singular;
DutchAbilityNames[AbilityNames.GRAND_CASTLE] = DutchCardNames[CardNames.GRAND_CASTLE].singular;
DutchAbilityNames[AbilityNames.ENCHANTRESS_ABILITY] = DutchCardNames[CardNames.ENCHANTRESS].singular;
DutchAbilityNames[AbilityNames.ENCHANTRESS_CANTRIP] = "+1 Card, +1 Action";
DutchAbilityNames[AbilityNames.TEMPLE] = DutchCardNames[CardNames.TEMPLE].singular;
DutchAbilityNames[AbilityNames.VILLA] = DutchCardNames[CardNames.VILLA].singular;
DutchAbilityNames[AbilityNames.ARCHIVE_ABILITY] = DutchCardNames[CardNames.ARCHIVE].singular;
DutchAbilityNames[AbilityNames.CAPITAL_TAKE_DEBT] = DutchCardNames[CardNames.CAPITAL].singular;
DutchAbilityNames[AbilityNames.CHARM] = DutchCardNames[CardNames.CHARM].singular;
DutchAbilityNames[AbilityNames.FORUM] = DutchCardNames[CardNames.FORUM].singular;
DutchAbilityNames[AbilityNames.HERMIT] = DutchCardNames[CardNames.HERMIT].singular;
DutchAbilityNames[AbilityNames.DONATE] = DutchCardNames[CardNames.DONATE].singular;
DutchAbilityNames[AbilityNames.TAX_RECEIVE] = DutchCardNames[CardNames.TAX].singular;
DutchAbilityNames[AbilityNames.DOMINATE] = DutchCardNames[CardNames.DOMINATE].singular;
DutchAbilityNames[AbilityNames.VP_TO_AQUEDUCT] = "VP to Aqueduct";
DutchAbilityNames[AbilityNames.VP_FROM_AQUEDUCT] = "VP from Aqueduct";
DutchAbilityNames[AbilityNames.ARENA_DISCARD_ACTION] = DutchCardNames[CardNames.ARENA].singular;
DutchAbilityNames[AbilityNames.VP_FROM_BASILICA] = DutchCardNames[CardNames.BASILICA].singular;
DutchAbilityNames[AbilityNames.VP_FROM_BATHS] = DutchCardNames[CardNames.BATHS].singular;
DutchAbilityNames[AbilityNames.VP_FROM_BATTLEFIELD] = DutchCardNames[CardNames.BATTLEFIELD].singular;
DutchAbilityNames[AbilityNames.VP_FROM_COLONNADE] = DutchCardNames[CardNames.COLONNADE].singular;
DutchAbilityNames[AbilityNames.VP_TO_DEFILED_SHRINE] = "VP to Defiled Shrine";
DutchAbilityNames[AbilityNames.VP_FROM_DEFILED_SHRINE] = "VP from Defiled Shrine";
DutchAbilityNames[AbilityNames.VP_FROM_LABYRINTH] = DutchCardNames[CardNames.LABYRINTH].singular;
DutchAbilityNames[AbilityNames.MOUNTAIN_PASS_BIDDING] = DutchCardNames[CardNames.MOUNTAIN_PASS].singular;
DutchAbilityNames[AbilityNames.TOMB] = DutchCardNames[CardNames.TOMB].singular;
DutchAbilityNames[AbilityNames.LIGHTHOUSE_COIN] = DutchCardNames[CardNames.LIGHTHOUSE].singular;
DutchAbilityNames[AbilityNames.REACTION] = "Reaction";
DutchAbilityNames[AbilityNames.CALL_GUIDE] = "Call " + DutchCardNames[CardNames.GUIDE].singular;
DutchAbilityNames[AbilityNames.CALL_RATCATCHER] = "Call " + DutchCardNames[CardNames.RATCATCHER].singular;
DutchAbilityNames[AbilityNames.CALL_TRANSMOGRIFY] = "Call " + DutchCardNames[CardNames.TRANSMOGRIFY].singular;
DutchAbilityNames[AbilityNames.CALL_TEACHER] = "Call " + DutchCardNames[CardNames.TEACHER].singular;
DutchAbilityNames[AbilityNames.CALL_DUPLICATE] = "Call " + DutchCardNames[CardNames.DUPLICATE].singular;
DutchAbilityNames[AbilityNames.CALL_COIN] = "Call " + DutchCardNames[CardNames.COIN_OF_THE_REALM].singular;
DutchAbilityNames[AbilityNames.CALL_ROYAL_CARRIAGE] = "Call " + DutchCardNames[CardNames.ROYAL_CARRIAGE].singular;
DutchAbilityNames[AbilityNames.START_OF_TURN] = "start your turn";
DutchAbilityNames[AbilityNames.START_BUY_PHASE] = "START_BUY_PHASE";
DutchAbilityNames[AbilityNames.END_OF_BUY_PHASE] = "END_OF_BUY_PHASE";
DutchAbilityNames[AbilityNames.BETWEEN_TURNS] = "BETWEEN_TURNS";
DutchAbilityNames[AbilityNames.END_OF_TURN] = "END_OF_TURN";
DutchAbilityNames[AbilityNames.SUMMON_PLAY] = DutchCardNames[CardNames.SUMMON].singular;
DutchAbilityNames[AbilityNames.CARAVAN_GUARD_REACTION] = DutchCardNames[CardNames.CARAVAN_GUARD].singular;
DutchAbilityNames[AbilityNames.TRAVELLING_FAIR_REACTION] = DutchCardNames[CardNames.TRAVELLING_FAIR].singular;
DutchAbilityNames[AbilityNames.MOAT_REACTION] = DutchCardNames[CardNames.MOAT].singular;
DutchAbilityNames[AbilityNames.HORSE_TRADERS_REACTION] = DutchCardNames[CardNames.HORSE_TRADERS].singular;
DutchAbilityNames[AbilityNames.BEGGAR_REACTION] = DutchCardNames[CardNames.BEGGAR].singular;
DutchAbilityNames[AbilityNames.MARKET_SQUARE_REACTION] = DutchCardNames[CardNames.MARKET_SQUARE].singular;
DutchAbilityNames[AbilityNames.TRADER_REACTION] = DutchCardNames[CardNames.TRADER].singular;
DutchAbilityNames[AbilityNames.DIPLOMAT_REACTION] = DutchCardNames[CardNames.DIPLOMAT].singular;
DutchAbilityNames[AbilityNames.TOPDECK_REACTION] = "Topdeck";
DutchAbilityNames[AbilityNames.WATCHTOWER_TRASH_REACTION] = "Trash";
DutchAbilityNames[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "Topdeck";
DutchAbilityNames[AbilityNames.PRINCE_PLAY] = "play with " + DutchCardNames[CardNames.PRINCE].singular + ".";
DutchAbilityNames[AbilityNames.PRINCE_SET_ASIDE] = "set aside with " + DutchCardNames[CardNames.PRINCE].singular + ".";
DutchAbilityNames[AbilityNames.DELUDED_RETURNED] = "return state";
DutchAbilityNames[AbilityNames.ENVIOUS_RETURNED] = "return state";
DutchAbilityNames[AbilityNames.RECEIVE_BOON] = "Receive Boon";
DutchAbilityNames[AbilityNames.COBBLER_ABILITY] = DutchCardNames[CardNames.COBBLER].singular;
DutchAbilityNames[AbilityNames.CRYPT_ABILITY] = DutchCardNames[CardNames.CRYPT].singular;
DutchAbilityNames[AbilityNames.DEN_OF_SIN_ABILITY] = DutchCardNames[CardNames.DEN_OF_SIN].singular;
DutchAbilityNames[AbilityNames.FAITHFUL_HOUND] = DutchCardNames[CardNames.FAITHFUL_HOUND].singular;
DutchAbilityNames[AbilityNames.FAITHFUL_HOUND_RETURN] = DutchCardNames[CardNames.FAITHFUL_HOUND].singular;
DutchAbilityNames[AbilityNames.GHOST_TOWN_ABILITY] = DutchCardNames[CardNames.GHOST_TOWN].singular;
DutchAbilityNames[AbilityNames.GUARDIAN_COIN] = DutchCardNames[CardNames.GUARDIAN].singular;
DutchAbilityNames[AbilityNames.RAIDER_ABILITY] = DutchCardNames[CardNames.RAIDER].singular;
DutchAbilityNames[AbilityNames.SECRET_CAVE] = DutchCardNames[CardNames.SECRET_CAVE].singular;
DutchAbilityNames[AbilityNames.CEMETERY] = DutchCardNames[CardNames.CEMETERY].singular;
DutchAbilityNames[AbilityNames.HAUNTED_MIRROR] = DutchCardNames[CardNames.HAUNTED_MIRROR].singular;
DutchAbilityNames[AbilityNames.GHOST] = DutchCardNames[CardNames.GHOST].singular;
DutchAbilityNames[AbilityNames.REMOVE_FLIPPED_STATUS] = "X";
DutchAbilityNames[AbilityNames.LOST_IN_THE_WOODS] = DutchCardNames[CardNames.LOST_IN_THE_WOODS].singular;
DutchAbilityNames[AbilityNames.BLESSED_VILLAGE] = DutchCardNames[CardNames.BLESSED_VILLAGE].singular;
DutchAbilityNames[AbilityNames.FORTRESS] = DutchCardNames[CardNames.FORTRESS].singular;
DutchAbilityNames[AbilityNames.RECEIVE_HEX] = "Receive Hex";
DutchAbilityNames[AbilityNames.HOVEL_TRASH] = "Rek dat hovel";
DutchAbilityNames[AbilityNames.TUNNEL_REVEAL] = "Gimme dat bling";

DutchAbilityNames[AbilityNames.CHANGELING_MAY_EXCHANGE] = "Exchange for " + DutchCardNames[CardNames.CHANGELING].singular;
DutchAbilityNames[AbilityNames.CAPTAIN] = DutchCardNames[CardNames.CAPTAIN].singular;
DutchAbilityNames[AbilityNames.CHURCH] = DutchCardNames[CardNames.CHURCH].singular;