"use strict";

// possible ability descriptions that could use "Argument0" to be more helpful
//
// Argument0 is some number an effect relates to
// ----------------------------------------------
// EsperantoAbilityDescriptions[AbilityNames.EMPORIUM_GAIN_POINTS] = "+Argument0 VP (" + EsperantoCardNames[CardNames.EMPORIUM].singular + ")";
//    the following needs the (server side?) magic that takes "3" and "Gold" and turns it into the language correct version of "3 Golds"
// EsperantoAbilityDescriptions[AbilityNames.FORTUNE] = "gain Argument0 (" + EsperantoCardNames[CardNames.FORTUNE].singular + ")";
// EsperantoAbilityDescriptions[AbilityNames.GRAND_CASTLE] = "+Argument0 VP (" + EsperantoCardNames[CardNames.GRAND_CASTLE].singular + ")";
// EsperantoAbilityDescriptions[AbilityNames.TEMPLE] = "take all (Argument0) VP from the " + EsperantoCardNames[CardNames.TEMPLE].singular + " pile";
// EsperantoAbilityDescriptions[AbilityNames.TAX_RECEIVE] = "take all (Argument0) debt from the pile you bought from (" + EsperantoCardNames[CardNames.TAX].singular + ")";
// EsperantoAbilityDescriptions[AbilityNames.VP_FROM_AQUEDUCT] = "take all (Argument0) VP from " + EsperantoCardNames[CardNames.AQUEDUCT].singular;
// EsperantoAbilityDescriptions[AbilityNames.VP_FROM_DEFILED_SHRINE] = "take all (Argument0) VP from " + EsperantoCardNames[CardNames.DEFILED_SHRINE].singular;


var EsperantoAbilityDescriptions = {};

EsperantoAbilityDescriptions[AbilityNames.BUY] = "aĉetu";
EsperantoAbilityDescriptions[AbilityNames.PLAY_ATTACK] = "ludu";
EsperantoAbilityDescriptions[AbilityNames.DRAW_INSTRUCTIONS] = "tiru Argument0";
EsperantoAbilityDescriptions[AbilityNames.PLAY_INSTRUCTIONS] = "ludu";
EsperantoAbilityDescriptions[AbilityNames.RESOLVE_EVENT] = "RESOLVE_EVENT";
EsperantoAbilityDescriptions[AbilityNames.GAIN_BY_NAME] = "gajnu Argument0 (Argument1)";
EsperantoAbilityDescriptions[AbilityNames.FISHING_VILLAGE_ABILITY] = "+1 Ago, +$1";
EsperantoAbilityDescriptions[AbilityNames.REMOVE_COST_REDUCTION] = "REMOVE_COST_REDUCTION";
EsperantoAbilityDescriptions[AbilityNames.HAVEN_ABILITY] = "metu Argument0 en vian manon";
EsperantoAbilityDescriptions[AbilityNames.SAVE_RETURN_TO_HAND] = "metu Argument0 en vian manon";
EsperantoAbilityDescriptions[AbilityNames.CARAVAN_ABILITY] = "+1 Karto";
EsperantoAbilityDescriptions[AbilityNames.MERCHANT_SHIP_ABILITY] = "+$2";
EsperantoAbilityDescriptions[AbilityNames.OUTPOST_ABILITY] = "[Outpost]";
EsperantoAbilityDescriptions[AbilityNames.TACTICIAN_ABILITY] = "+5 Kartoj, +1 Ago, +1 Aĉeto";
EsperantoAbilityDescriptions[AbilityNames.WHARF_ABILITY] = "+2 Kartoj, +1 Aĉeto";
EsperantoAbilityDescriptions[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = "+1 Karto, metu [^nHorse Traders] en vian manon";
EsperantoAbilityDescriptions[AbilityNames.DUCHESS_MAY_GAIN] = "gajnu [^nDuchess]";
EsperantoAbilityDescriptions[AbilityNames.FOOLS_GOLD_TRASH] = "rubigu [^nFools Gold] por surmetu [^nGold]";
EsperantoAbilityDescriptions[AbilityNames.NOBLE_BRIGAND] = "provu rabi kontraŭulojn por [Silver] aŭ [Gold]";
EsperantoAbilityDescriptions[AbilityNames.HAGGLER_ABILITY] = "gajnu pli malmultekostan ne-Venkan karton";
EsperantoAbilityDescriptions[AbilityNames.INN_ABILITY] = "miksu Agkartoj el forĵeta stako en vian kartaron";
EsperantoAbilityDescriptions[AbilityNames.MANDARIN] = "surmetu ĉiujn viajn Trezorkartojn en ludo";
EsperantoAbilityDescriptions[AbilityNames.FARMLAND] = "aliformu manan karton precize $2 pli";
EsperantoAbilityDescriptions[AbilityNames.GAIN_CHEAPER] = "gajnu pli malmultekostan karton";
EsperantoAbilityDescriptions[AbilityNames.GAIN_TWO_RUINS] = "gajnu 2 Ruinojn";
EsperantoAbilityDescriptions[AbilityNames.HUNTING_GROUNDS] = "gajnu [^nDuchy] aŭ 3 [^pEstate]";
EsperantoAbilityDescriptions[AbilityNames.SQUIRE_GAIN_ATTACK] = "gajnu Atakkarton";
EsperantoAbilityDescriptions[AbilityNames.URCHIN_TRANSFORM] = "rubigu [Urchin] por gajni [^nMercenary]";
EsperantoAbilityDescriptions[AbilityNames.FEODUM_GAIN_SILVERS] = "gajnu 3 [^pSilver]";
EsperantoAbilityDescriptions[AbilityNames.DOCTOR_OVERPAY] = "tropagu por rubigi aŭ forĵeti kartojn el via kartaro";
EsperantoAbilityDescriptions[AbilityNames.RESOLVE_DOCTOR_OVERPAY] = "trash or discard cards from your deck";
EsperantoAbilityDescriptions[AbilityNames.HERALD_OVERPAY] = "tropagu por surmetu kartojn el via forĵeta stako";
EsperantoAbilityDescriptions[AbilityNames.RESOLVE_HERALD_OVERPAY] = "topdeck cards from your discard";
EsperantoAbilityDescriptions[AbilityNames.MASTERPIECE_OVERPAY] = "tropagu por gajni [^pSilver]";
EsperantoAbilityDescriptions[AbilityNames.RESOLVE_MASTERPIECE_OVERPAY] = "gain [^pSilver]";
EsperantoAbilityDescriptions[AbilityNames.STONEMASON_OVERPAY] = "tropagu por gajni 2 Agkartojn";
EsperantoAbilityDescriptions[AbilityNames.RESOLVE_STONEMASON_OVERPAY] = "gain 2 Action cards";
EsperantoAbilityDescriptions[AbilityNames.EXCHANGE] = "EXCHANGE";
EsperantoAbilityDescriptions[AbilityNames.AMULET] = "+$1; aŭ rubigu karton; aŭ gajnu [^nSilver]";
EsperantoAbilityDescriptions[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = "+$1";
EsperantoAbilityDescriptions[AbilityNames.DUNGEON_ABILITY] = "+2 Kartoj, poste forĵetu 2 kartojn";
EsperantoAbilityDescriptions[AbilityNames.GEAR_ABILITY] = "metu Argument0 en vian manon";
EsperantoAbilityDescriptions[AbilityNames.MESSENGER_GAIN] = "ĉiuj ludantoj gajnas la sama $4 (aŭ pli malmultekostan) karton";
EsperantoAbilityDescriptions[AbilityNames.BRIDGE_TROLL_ABILITY] = "+1 Aĉeto";
EsperantoAbilityDescriptions[AbilityNames.HAUNTED_WOODS_DURATION] = "+3 Kartoj";
EsperantoAbilityDescriptions[AbilityNames.HAUNTED_WOODS_TOPDECK] = "surmetu manon ([Haunted Woods] de kontraŭulo)";
EsperantoAbilityDescriptions[AbilityNames.LOST_CITY] = "+1 Karto ([Lost City] de kontraŭulo)";
EsperantoAbilityDescriptions[AbilityNames.WINE_MERCHANT_DISCARD] = "forĵetu [^nWine Merchant] el via mato de Taverno";
EsperantoAbilityDescriptions[AbilityNames.HIRELING] = "+1 Karto";
EsperantoAbilityDescriptions[AbilityNames.SWAMP_HAG_DURATION] = "+$3";
EsperantoAbilityDescriptions[AbilityNames.TRASH_TOKEN_ABILITY] = "rubigu karton (Rubigjetono)";
EsperantoAbilityDescriptions[AbilityNames.EMPORIUM_GAIN_POINTS] = "+2 VP, se vi havas almenaŭ 5 Agkartojn en ludo";
EsperantoAbilityDescriptions[AbilityNames.ROCKS_GAINS_SILVER] = "gajnu [^nSilver]";
EsperantoAbilityDescriptions[AbilityNames.FORTUNE] = "gajnu [^nGold] po [Gladiator] en ludo";
EsperantoAbilityDescriptions[AbilityNames.CRUMBLING_CASTLE] = "+1 VP kaj gajnu [^nSilver]";
EsperantoAbilityDescriptions[AbilityNames.HAUNTED_CASTLE] = "gajnu [^nGold] kaj kontraŭuloj devas surmetu kartojn";
EsperantoAbilityDescriptions[AbilityNames.SPRAWLING_CASTLE] = "gajnu [^nDuchy] aŭ 3 [^pEstate]";
EsperantoAbilityDescriptions[AbilityNames.GRAND_CASTLE] = "+1 VP po Venkkarto en ludo aŭ en via mano";
EsperantoAbilityDescriptions[AbilityNames.ENCHANTRESS_ABILITY] = "+2 Kartoj";
EsperantoAbilityDescriptions[AbilityNames.ENCHANTRESS_CANTRIP] = "+1 Karto, +1 Ago";
EsperantoAbilityDescriptions[AbilityNames.TEMPLE] = "prenu la VP el la stako de [Temple]";
EsperantoAbilityDescriptions[AbilityNames.VILLA] = "metu [Villa] en vian manon, +1 Ago, eble reiru al via Agfazo";
EsperantoAbilityDescriptions[AbilityNames.ARCHIVE_ABILITY] = "prenu unu el Argument0";
EsperantoAbilityDescriptions[AbilityNames.CAPITAL_TAKE_DEBT] = "prenu 6 ŝuldon, poste vi povas repagi ŝuldon";
EsperantoAbilityDescriptions[AbilityNames.CHARM] = "gajnu malsaman karton kun sama kosto";
EsperantoAbilityDescriptions[AbilityNames.FORUM] = "+1 Aĉeto";
EsperantoAbilityDescriptions[AbilityNames.HERMIT] = "rubigu ĉi tion, gajnu [^nMadman]";
EsperantoAbilityDescriptions[AbilityNames.DONATE] = "rubigu el via kombinita mano, forĵeta stako, kaj kartaro";
EsperantoAbilityDescriptions[AbilityNames.TAX_RECEIVE] = "prenu la ŝuldon el la stako, el kiu vi aĉetis";
EsperantoAbilityDescriptions[AbilityNames.DOMINATE] = "gajnu [^nProvince], poste +9 VP se vi faris";
EsperantoAbilityDescriptions[AbilityNames.VP_TO_AQUEDUCT] = "movu 1 VP el Trezorstako al [Aqueduct]";
EsperantoAbilityDescriptions[AbilityNames.VP_FROM_AQUEDUCT] = "prenu la VP el [Aqueduct]";
EsperantoAbilityDescriptions[AbilityNames.ARENA_DISCARD_ACTION] = "discard an Action to take 2 VP from [Arena]";
EsperantoAbilityDescriptions[AbilityNames.VP_FROM_BASILICA] = "prenu 2 VP el [Basilica] se vi havas almenaŭ $2";
EsperantoAbilityDescriptions[AbilityNames.VP_FROM_BATHS] = "prenu 2 VP el [Baths]";
EsperantoAbilityDescriptions[AbilityNames.VP_FROM_BATTLEFIELD] = "prenu 2 VP el [Battlefield]";
EsperantoAbilityDescriptions[AbilityNames.VP_FROM_COLONNADE] = "prenu 2 VP el [Colonnade] se vi aĉetis Agkarton vi havas en ludo";
EsperantoAbilityDescriptions[AbilityNames.VP_TO_DEFILED_SHRINE] = "movu 1 VP el Agstako al [Defiled Shrine]";
EsperantoAbilityDescriptions[AbilityNames.VP_FROM_DEFILED_SHRINE] = "prenu la VP el [Defiled Shrine]";
EsperantoAbilityDescriptions[AbilityNames.VP_FROM_LABYRINTH] = "prenu 2 VP el [Labyrinth]";
EsperantoAbilityDescriptions[AbilityNames.MOUNTAIN_PASS_BIDDING] = "proponu ŝuldon por ricevi +8 VP";
EsperantoAbilityDescriptions[AbilityNames.TOMB] = "+1 VP";
EsperantoAbilityDescriptions[AbilityNames.LIGHTHOUSE_COIN] = "+$1";
EsperantoAbilityDescriptions[AbilityNames.REACTION] = "REACTION";
EsperantoAbilityDescriptions[AbilityNames.CALL_GUIDE] = "alvoku [^nGuide] por forĵeti, +5 Cards";
EsperantoAbilityDescriptions[AbilityNames.CALL_RATCATCHER] = "alvoku [^nRatcatcher] por rubigi karton";
EsperantoAbilityDescriptions[AbilityNames.CALL_TRANSMOGRIFY] = "alvoku [^nTransmogrify] por altgradigu karton";
EsperantoAbilityDescriptions[AbilityNames.CALL_TEACHER] = "alvoku [^nTeacher] por movu jetono de Instruisto al Agstako";
EsperantoAbilityDescriptions[AbilityNames.CALL_DUPLICATE] = "alvoku [^nDuplicate] por gajni kopion de Argument0";
EsperantoAbilityDescriptions[AbilityNames.CALL_COIN] = "alvoku [^nCoin Of The Realm] por +2 Agoj";
EsperantoAbilityDescriptions[AbilityNames.CALL_ROYAL_CARRIAGE] = "alvoku [^nRoyal Carriage] por reludi Argument0";
EsperantoAbilityDescriptions[AbilityNames.START_OF_TURN] = "komencu vian vicon";
EsperantoAbilityDescriptions[AbilityNames.START_BUY_PHASE] = "komencu vian Aĉetfazo";
EsperantoAbilityDescriptions[AbilityNames.END_OF_BUY_PHASE] = "finu vian Aĉetfazon";
EsperantoAbilityDescriptions[AbilityNames.BETWEEN_TURNS] = "estas inter vicoj";
EsperantoAbilityDescriptions[AbilityNames.END_OF_TURN] = "finu vian vicon";
EsperantoAbilityDescriptions[AbilityNames.SUMMON_PLAY] = "ludu Argument0";
EsperantoAbilityDescriptions[AbilityNames.CARAVAN_GUARD_REACTION] = "ludu [^nCaravan Guard]";
EsperantoAbilityDescriptions[AbilityNames.TRAVELLING_FAIR_REACTION] = "povas surmetu Argument0";
EsperantoAbilityDescriptions[AbilityNames.MOAT_REACTION] = "malkaŝu [^nMoat] por bari la atakon";
EsperantoAbilityDescriptions[AbilityNames.HORSE_TRADERS_REACTION] = "flankenmetu [^nHorse Traders] por +1 Karto sekvan vicon";
EsperantoAbilityDescriptions[AbilityNames.BEGGAR_REACTION] = "forĵetu [^nBeggar] por gajni 2 [^pSilver], unu sur vian kartaron";
EsperantoAbilityDescriptions[AbilityNames.MARKET_SQUARE_REACTION] = "forĵetu [^nMarket Square] por gajni [^nGold]";
EsperantoAbilityDescriptions[AbilityNames.TRADER_REACTION] = "gajnu [^nSilver] anstataŭ Argument0";
EsperantoAbilityDescriptions[AbilityNames.DIPLOMAT_REACTION] = "+2 Kartoj, forĵetu 3 kartojn";
EsperantoAbilityDescriptions[AbilityNames.TOPDECK_REACTION] = "surmetu Argument0";
EsperantoAbilityDescriptions[AbilityNames.WATCHTOWER_TRASH_REACTION] = "rubigu Argument0";
EsperantoAbilityDescriptions[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "surmetu Argument0";
EsperantoAbilityDescriptions[AbilityNames.PRINCE_PLAY] = "ludu Argument0";
EsperantoAbilityDescriptions[AbilityNames.TUNNEL_REVEAL] = "gajnu [^nGold]";
EsperantoAbilityDescriptions[AbilityNames.POSSESSION_GAIN_INSTEAD] = "Argument0 gajnu Argument2 anstataŭ Argument1";
EsperantoAbilityDescriptions[AbilityNames.HOVEL_TRASH] = "rubigu [^nHovel]";
EsperantoAbilityDescriptions[AbilityNames.DELUDED_RETURNED] = "redonu [^nDeluded]";
EsperantoAbilityDescriptions[AbilityNames.ENVIOUS_RETURNED] = "redonu [^nEnvious]";
EsperantoAbilityDescriptions[AbilityNames.RECEIVE_BOON] = "ricevas Argument0";
EsperantoAbilityDescriptions[AbilityNames.COBBLER_ABILITY] = "gajnu $4 (aŭ pli malmultekostan) karton el vian manon";
EsperantoAbilityDescriptions[AbilityNames.CRYPT_ABILITY] = "prenu unu el Argument0";
EsperantoAbilityDescriptions[AbilityNames.DEN_OF_SIN_ABILITY] = "+2 Kartoj";
EsperantoAbilityDescriptions[AbilityNames.FAITHFUL_HOUND] = "flankenmetu [^nFaithful Hound]";
EsperantoAbilityDescriptions[AbilityNames.FAITHFUL_HOUND_RETURN] = "remetu [^nFaithful Hound] en vian manon";
EsperantoAbilityDescriptions[AbilityNames.GHOST_TOWN_ABILITY] = "+1 Karto, +1 Ago";
EsperantoAbilityDescriptions[AbilityNames.GUARDIAN_COIN] = "+$1";
EsperantoAbilityDescriptions[AbilityNames.RAIDER_ABILITY] = "+$3";
EsperantoAbilityDescriptions[AbilityNames.SECRET_CAVE] = "+$3";
EsperantoAbilityDescriptions[AbilityNames.CEMETERY] = "rubigi ĝis 4 kartojn";
EsperantoAbilityDescriptions[AbilityNames.HAUNTED_MIRROR] = "forĵetu Agkarton por gajni [^nGhost]";
EsperantoAbilityDescriptions[AbilityNames.GHOST] = "ludu Argument0 dufoje";
EsperantoAbilityDescriptions[AbilityNames.REMOVE_FLIPPED_STATUS] = "malrenversu kartojn en la rubujo";
EsperantoAbilityDescriptions[AbilityNames.LOST_IN_THE_WOODS] = "forĵetu karton por ricevas Benon";
EsperantoAbilityDescriptions[AbilityNames.BLESSED_VILLAGE] = "prenu Benon";
EsperantoAbilityDescriptions[AbilityNames.FORTRESS] = "metu [^nFortress] en vian manon";
EsperantoAbilityDescriptions[AbilityNames.RECEIVE_HEX] = "ricevas Envulton";
EsperantoAbilityDescriptions[AbilityNames.CHANGELING_MAY_EXCHANGE] = "interŝanĝu Argument0 por [Changeling]";
EsperantoAbilityDescriptions[AbilityNames.TRADE_ROUTE_MOVE_TOKEN] = "Movu monerjetonon al la mato de Komerca Vojo";
EsperantoAbilityDescriptions[AbilityNames.DUCAT] = "rubigu [^nCopper]";
EsperantoAbilityDescriptions[AbilityNames.CARGO_SHIP_SET_ASIDE] = "flankenmetu Argument0";
EsperantoAbilityDescriptions[AbilityNames.CARGO_SHIP_RETURN] = "metu Argument0 en vian manon";
EsperantoAbilityDescriptions[AbilityNames.IMPROVE] = "plibonigu Agkarton en ludo";
EsperantoAbilityDescriptions[AbilityNames.PRIEST] = "+$2";
EsperantoAbilityDescriptions[AbilityNames.RESEARCH] = "metu Argument0 en vian manon";
EsperantoAbilityDescriptions[AbilityNames.SILK_MERCHANT] = "+1 Monrezervo, +1 Vilaĝano";
EsperantoAbilityDescriptions[AbilityNames.LACKEYS] = "+2 Vilaĝanoj";
EsperantoAbilityDescriptions[AbilityNames.SPICES] = "+2 Monrezervoj";
EsperantoAbilityDescriptions[AbilityNames.KEY] = "+$1";
EsperantoAbilityDescriptions[AbilityNames.TREASURE_CHEST] = "gajnu [^nGold]";
EsperantoAbilityDescriptions[AbilityNames.FLAG_BEARER] = "prenu [^nFlag]";
EsperantoAbilityDescriptions[AbilityNames.CATHEDRAL] = "rubigu karton";
EsperantoAbilityDescriptions[AbilityNames.CITY_GATE] = "+1 Karto, poste surmetu";
EsperantoAbilityDescriptions[AbilityNames.PAGEANT] = "pagu $1 por +1 Monrezervo";
EsperantoAbilityDescriptions[AbilityNames.SEWERS_MAY_TRASH] = "rubigu alian karton";
EsperantoAbilityDescriptions[AbilityNames.EXPLORATION] = "+1 Monrezervo, +1 Vilaĝano";
EsperantoAbilityDescriptions[AbilityNames.FAIR] = "+1 Aĉeto";
EsperantoAbilityDescriptions[AbilityNames.SILOS] = "forĵetu Kuprojn por kartojn";
EsperantoAbilityDescriptions[AbilityNames.ACADEMY] = "+1 Vilaĝano";
EsperantoAbilityDescriptions[AbilityNames.GUILDHALL] = "+1 Monrezervo";
EsperantoAbilityDescriptions[AbilityNames.PIAZZA] = "malkaŝu kaj ludu se Agkarto";
EsperantoAbilityDescriptions[AbilityNames.BARRACKS] = "+1 Ago";
EsperantoAbilityDescriptions[AbilityNames.CROP_ROTATION] = "forĵetu Venkkarton por +2 Kartoj";
EsperantoAbilityDescriptions[AbilityNames.INNOVATION] = "flankenmetu & ludu Argument0";
EsperantoAbilityDescriptions[AbilityNames.CITADEL] = "reludu Argument0";
EsperantoAbilityDescriptions[AbilityNames.SINISTER_PLOT] = "tiru aŭ aldonu jetonon";
EsperantoAbilityDescriptions[AbilityNames.ENCAMPMENT_RETURNED] = "redonu [^nEncampment] al la Provizo";
EsperantoAbilityDescriptions[AbilityNames.EMBARGO_GAIN_CURSE] = "gajnu [^nCurse]";
EsperantoAbilityDescriptions[AbilityNames.CAPTAIN] = "Metu Agkarton el la Provizo";
EsperantoAbilityDescriptions[AbilityNames.CHURCH] = "metu Argument0 en vian manon, poste vi povas rubigi";
EsperantoAbilityDescriptions[AbilityNames.POSSESSION_SETS_ASIDE] = "flankenmetu Argument0";
EsperantoAbilityDescriptions[AbilityNames.MINT] = "Rubigu ĉiujn viajn Trezorkartjon en ludo";
EsperantoAbilityDescriptions[AbilityNames.SLEIGH_TOPDECK_REACTION] = "forĵetu [^nSleigh] por surmetu Argument0";
EsperantoAbilityDescriptions[AbilityNames.SLEIGH_PUT_IN_HAND_REACTION] = "forĵetu [^nSleigh] por metu Argument0 en manon";
EsperantoAbilityDescriptions[AbilityNames.BLACK_CAT_REACTION] = "ludu [^nBlack Cat]";
EsperantoAbilityDescriptions[AbilityNames.DISCARD_FROM_EXILE] = "forĵetu Argument0 el Ekzilo.";
EsperantoAbilityDescriptions[AbilityNames.EXILE_BY_NAME] = "Ekzilu Argument0";
EsperantoAbilityDescriptions[AbilityNames.SHEEPDOG_REACTION] = "Ludu [^nSheepdog]";
EsperantoAbilityDescriptions[AbilityNames.CAVALRY] = "+2 Kartoj, +1 Aĉeto & reiru al Agfazo.";
EsperantoAbilityDescriptions[AbilityNames.HOSTELRY] = "forĵetu Trezorkartojn por Ĉevaloj";
EsperantoAbilityDescriptions[AbilityNames.VILLAGE_GREEN_VILLAGE] = "+1 Karto, +2 Agoj";
EsperantoAbilityDescriptions[AbilityNames.VILLAGE_GREEN_PLAY] = "ludu [^nVillage Green]";
EsperantoAbilityDescriptions[AbilityNames.BARGE] = "+3 Kartoj, +1 Aĉeto";
EsperantoAbilityDescriptions[AbilityNames.FALCONER_REACTION] = "Gajnu pli malmultekostan karton";
EsperantoAbilityDescriptions[AbilityNames.GATEKEEPER_EXILE] = "Ekzilu Argument0";
EsperantoAbilityDescriptions[AbilityNames.GATEKEEPER_DURATION] = "+$3";
EsperantoAbilityDescriptions[AbilityNames.LIVERY] = "Gajnu Ĉevalon";
EsperantoAbilityDescriptions[AbilityNames.MASTERMIND] = "Vi povas ludi Agkarton trifoje";
EsperantoAbilityDescriptions[AbilityNames.DELAY_PLAY] = "Ludu Argument0";
EsperantoAbilityDescriptions[AbilityNames.INVEST] = "INVEST ABILITY (never shown to user)";
EsperantoAbilityDescriptions[AbilityNames.REAP_PLAY] = "Ludu la flankenmetitan [^nGold]";
EsperantoAbilityDescriptions[AbilityNames.PLUS_CARD_TOKEN] = "+Karto el la jetono";
EsperantoAbilityDescriptions[AbilityNames.PLUS_ACTION_TOKEN] = "+Ago el la jetono";
EsperantoAbilityDescriptions[AbilityNames.PLUS_BUY_TOKEN] = "+Aĉeto el la jetono";
EsperantoAbilityDescriptions[AbilityNames.PLUS_COIN_TOKEN] = "+$ el la jetono";
EsperantoAbilityDescriptions[AbilityNames.KILN] = "gajnu kopion de Argument0";
EsperantoAbilityDescriptions[AbilityNames.TURTLE_PLAY] = "Ludu la flankenmetantan Argument0";
EsperantoAbilityDescriptions[AbilityNames.GIVE_PLUS_CARDS] = "+Karto";
EsperantoAbilityDescriptions[AbilityNames.GIVE_PLUS_ACTIONS] = "+Ago";
EsperantoAbilityDescriptions[AbilityNames.GIVE_PLUS_BUYS] = "+Aĉeto";
EsperantoAbilityDescriptions[AbilityNames.GIVE_PLUS_COINS] = "+Monero";
EsperantoAbilityDescriptions[AbilityNames.MONKEY_NEXT_TURN_ABILITY] = "+1 Karto";
EsperantoAbilityDescriptions[AbilityNames.BLOCKADE_ABILITY] = "metu Argument0 en vian manon";
EsperantoAbilityDescriptions[AbilityNames.PIRATE_ABILITY] = "gajnu Trezorkarton al via mano";
EsperantoAbilityDescriptions[AbilityNames.PIRATE_REACTION] = "Ludu [Pirate]";
EsperantoAbilityDescriptions[AbilityNames.CORSAIR_NEXT_TURN_ABILITY] = "+1 Karto";
EsperantoAbilityDescriptions[AbilityNames.CORSAIR_TRASH] = "Rubigu Argument0";
EsperantoAbilityDescriptions[AbilityNames.SAILOR_NEXT_TURN] = "+$2, kaj vi povas rubigi";
EsperantoAbilityDescriptions[AbilityNames.SAILOR_REACTION] = "Ludu Argument0";
EsperantoAbilityDescriptions[AbilityNames.SKIRMISHER] = "aliaj ludantoj forĵetas ĝis tiu havas 3";
EsperantoAbilityDescriptions[AbilityNames.ENCHANTRESS_REPLACE_ABILITY] = "Anstataŭigo per Ĉarmistino";
EsperantoAbilityDescriptions[AbilityNames.HIGHWAYMAN_NEXT_TURN_ABILITY] = "Forĵetu Vojrabisto, +3 Kartoj";
EsperantoAbilityDescriptions[AbilityNames.HIGHWAYMAN_REPLACE_ABILITY] = "Anstataŭigo per Vojrabisto";
EsperantoAbilityDescriptions[AbilityNames.HIGHWAYMAN_NULLIFIED] = "Ludado Nuligita";
EsperantoAbilityDescriptions[AbilityNames.LEAGUE_OF_BANKERS] = "+$1 po 4 Komplezoj";
EsperantoAbilityDescriptions[AbilityNames.WOODWORKERS_GUILD] = "Elspezu Komplezon kaj rubigu Agkarton por gajni Agkarton.";
EsperantoAbilityDescriptions[AbilityNames.CRAFTERS_GUILD] = "Elspezu 2 Komplezojn por gajni";
EsperantoAbilityDescriptions[AbilityNames.CAVE_DWELLERS] = "Elspezu Komplezon por forĵeti poste tiri";
EsperantoAbilityDescriptions[AbilityNames.DESERT_GUIDES] = "Elspezu Komplezon por forĵeti poste tiri";
EsperantoAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_CARD] = "Elspezu Komplezon por +1 Karto";
EsperantoAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_ACTION] = "Elspezu Komplezon por +1 Ago";
EsperantoAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_BUY] = "Elspezu Komplezon por +1 Aĉeto";
EsperantoAbilityDescriptions[AbilityNames.SYCOPHANT] = "+2 Komplezoj";
EsperantoAbilityDescriptions[AbilityNames.IMPORTER_ABILITY] = "Gajnu karton ĝis $5";
EsperantoAbilityDescriptions[AbilityNames.CONTRACT_ABILITY] = "ludu Argument0";
EsperantoAbilityDescriptions[AbilityNames.MARKET_TOWNS] = "Elspezu Komplezon por ludi Agkarton";
EsperantoAbilityDescriptions[AbilityNames.TRAPPERS_LODGE] = "Elspezu Komplezon por surmeti Argument0";
EsperantoAbilityDescriptions[AbilityNames.GUILDMASTER] = "+1 Komplezo";
EsperantoAbilityDescriptions[AbilityNames.ISLAND_FOLK] = "Elspezu 5 Komplezojn por ekstra vico";
EsperantoAbilityDescriptions[AbilityNames.GANG_OF_PICKPOCKETS] = "Forĵetu ĝis 4 krom se vi elspezas Komplezon";
EsperantoAbilityDescriptions[AbilityNames.COASTAL_HAVEN] = "Gardu kartojn por sekva vico";
EsperantoAbilityDescriptions[AbilityNames.CITY_STATE] = "Elspezu 2 Komplezojn por ludi Argument0";
EsperantoAbilityDescriptions[AbilityNames.CITY_STATE_PLAY] = "---";
EsperantoAbilityDescriptions[AbilityNames.CIRCLE_OF_WITCHES] = "Elspezu 3 Komplezojn por doni al kontraŭuloj Malbenon";
EsperantoAbilityDescriptions[AbilityNames.ROYAL_GALLEY_SET_ASIDE] = "Flankenmetu kun Reĝa Galero";
EsperantoAbilityDescriptions[AbilityNames.ROYAL_GALLEY_PLAY] = "Ludu Argument0";
EsperantoAbilityDescriptions[AbilityNames.STRONGHOLD_ABILITY] = "+3 Kartoj";
EsperantoAbilityDescriptions[AbilityNames.MOUNTAIN_FOLK] = "Elspezu 5 Komplezojn por tiri 3 Kartojn";
EsperantoAbilityDescriptions[AbilityNames.PEACEFUL_CULT] = "Elspezu Komplezojn por rubigi";
EsperantoAbilityDescriptions[AbilityNames.FELLOWSHIP_OF_SCRIBES] = "Elspezu Komplezon por tiri karton";
EsperantoAbilityDescriptions[AbilityNames.LEAGUE_OF_SHOPKEEPERS] = "Butikistoj";
EsperantoAbilityDescriptions[AbilityNames.TERRITORY_GAIN] = "Gajnu Oron po malplena Provizstako";
EsperantoAbilityDescriptions[AbilityNames.WARLORD_ABILITY] = "+2 Kartoj";
EsperantoAbilityDescriptions[AbilityNames.GARRISON_ADD_TOKEN] = "Aldonu jetonon";
EsperantoAbilityDescriptions[AbilityNames.GARRISON_REMOVE_ABILITY] = "+Argument0 Kartoj";
EsperantoAbilityDescriptions[AbilityNames.CONJURER] = "Metu ĝin en vian manon";
EsperantoAbilityDescriptions[AbilityNames.LICH] = "Forĵetu kaj gajnu el Rubujo";
EsperantoAbilityDescriptions[AbilityNames.GALLERIA] = "+1 Aĉeto";
EsperantoAbilityDescriptions[AbilityNames.ARCHITECTS_GUILD] = "Gajnu pli malmultekostan ne-Venkan";
EsperantoAbilityDescriptions[AbilityNames.FAMILY_OF_INVENTORS] = "Plimalmultekostigu stakon";
EsperantoAbilityDescriptions[AbilityNames.FOREST_DWELLERS] = "Skoltas 3 kartoj";
EsperantoAbilityDescriptions[AbilityNames.ASTROLABE_ABILITY] = "+$1, +1 Aĉeto";
EsperantoAbilityDescriptions[AbilityNames.TIDE_POOLS_ABILITY] = "Forĵetu 2 kartojn";
EsperantoAbilityDescriptions[AbilityNames.SEA_WITCH_ABILITY] = "+2 Kartoj, poste forĵetu 2 kartojn";
EsperantoAbilityDescriptions[AbilityNames.CLERK_ABILITY] = "Ludu [Clerk]";
EsperantoAbilityDescriptions[AbilityNames.GUARD_DOG_REACTION] = "Ludu [Guard Dog]";
EsperantoAbilityDescriptions[AbilityNames.BERSERKER] = "Ludu [Berserker]";
EsperantoAbilityDescriptions[AbilityNames.NOMADS] = "+$2";
EsperantoAbilityDescriptions[AbilityNames.TRAIL_REACTION] = "Ludu [Trail]";
EsperantoAbilityDescriptions[AbilityNames.WEAVER_PLAY] = "Ludu [Weaver]";
EsperantoAbilityDescriptions[AbilityNames.SOUK] = "Rubigu ĝis 2";
EsperantoAbilityDescriptions[AbilityNames.CAULDRON_CURSING] = "Ĉiu alia ludanto gajnas Malbenon";
EsperantoAbilityDescriptions[AbilityNames.UNUSED_ABILITY_2] = "unused";
EsperantoAbilityDescriptions[AbilityNames.UNUSED_ABILITY_2] = "unused";
EsperantoAbilityDescriptions[AbilityNames.UNUSED_ABILITY_2] = "unused";
EsperantoAbilityDescriptions[AbilityNames.UNUSED_ABILITY_2] = "unused";
EsperantoAbilityDescriptions[AbilityNames.UNUSED_ABILITY_2] = "unused";
EsperantoAbilityDescriptions[AbilityNames.TREASURY] = "Surmetu Trezorejon";
EsperantoAbilityDescriptions[AbilityNames.UNUSED_ABILITY_2] = "unused";
EsperantoAbilityDescriptions[AbilityNames.MERCHANT_GUILD] = "Ricevu Monrezervojn po gajnita karto";
EsperantoAbilityDescriptions[AbilityNames.ALCHEMIST_TOPDECK] = "Topdeck Alchemist";

// Missing lines as of 09.01.2023 19:11:38
EsperantoAbilityDescriptions[AbilityNames.HERBALIST_TOPDECK] = "Topdeck Argument0 with [Herbalist]";
EsperantoAbilityDescriptions[AbilityNames.SCHEME_TOPDECK] = "Topdeck Argument0 with [Scheme]";
EsperantoAbilityDescriptions[AbilityNames.FROG_TOPDECK] = "Topdeck Argument0";
EsperantoAbilityDescriptions[AbilityNames.MERCHANT_CAMP_TOPDECK] = "Topdeck [Merchant Camp]";
EsperantoAbilityDescriptions[AbilityNames.WALLED_VILLAGE_TOPDECK] = "Topdeck [Walled Village]";
EsperantoAbilityDescriptions[AbilityNames.TENT_TOPDECK] = "Topdeck [Tent]";
EsperantoAbilityDescriptions[AbilityNames.HORN_TOPDECK] = "Topdeck [Border Guard] with [Horn]";
EsperantoAbilityDescriptions[AbilityNames.PAGE_EXCHANGE] = "Exchange [Page] for a [Treasure Hunter]";
EsperantoAbilityDescriptions[AbilityNames.TREASURE_HUNTER_EXCHANGE] = "Exchange [Treasure Hunter] for a [Warrior]";
EsperantoAbilityDescriptions[AbilityNames.WARRIOR_EXCHANGE] = "Exchange [Warrior] for a [Hero]";
EsperantoAbilityDescriptions[AbilityNames.HERO_EXCHANGE] = "Exchange [Hero] for a [Champion]";
EsperantoAbilityDescriptions[AbilityNames.PEASANT_EXCHANGE] = "Exchange [Peasant] for a [Soldier]";
EsperantoAbilityDescriptions[AbilityNames.SOLDIER_EXCHANGE] = "Exchange [Soldier] for a [Fugitive]";
EsperantoAbilityDescriptions[AbilityNames.FUGITIVE_EXCHANGE] = "Exchange [Fugitive] for a [Disciple]";
EsperantoAbilityDescriptions[AbilityNames.DISCIPLE_EXCHANGE] = "Exchange [Disciple] for a [Teacher]";

EsperantoAbilityDescriptions[AbilityNames.CAGE_TRASH] = "Trash [Cage]";
EsperantoAbilityDescriptions[AbilityNames.CAGE_PUT_IN_HAND] = "Put Argument0 in Hand";
EsperantoAbilityDescriptions[AbilityNames.GROTTO] = "Discard Argument0, then draw";
EsperantoAbilityDescriptions[AbilityNames.JEWELLED_EGG_GAIN_LOOT] = "Gain a Loot.";
EsperantoAbilityDescriptions[AbilityNames.SEARCH] = "Trash [Search], and gain a Loot.";
EsperantoAbilityDescriptions[AbilityNames.SHAMAN] = "Gain a card from the trash costing up to 6.";
EsperantoAbilityDescriptions[AbilityNames.SECLUDED_SHRINE] = "Trash up to 2 cards";
EsperantoAbilityDescriptions[AbilityNames.SIREN_GAIN] = "Trash an Action, or [Siren]";
EsperantoAbilityDescriptions[AbilityNames.SIREN_DURATION] = "Draw up to 8 cards";
EsperantoAbilityDescriptions[AbilityNames.STOWAWAY_DRAW] = "Draw 2 cards";
EsperantoAbilityDescriptions[AbilityNames.STOWAWAY_REACTION] = "Play [Stowaway]";
EsperantoAbilityDescriptions[AbilityNames.TASKMASTER] = "+1 Action, +1 Coin";
EsperantoAbilityDescriptions[AbilityNames.ABUNDANCE] = "+1 Buy, +3 Coins";
EsperantoAbilityDescriptions[AbilityNames.CABIN_BOY] = "+2 Coins, or trash [Cabin Boy] to gain a Duration.";
EsperantoAbilityDescriptions[AbilityNames.FLAGSHIP_ACTIVATE] = "";
EsperantoAbilityDescriptions[AbilityNames.FLAGSHIP_REPLAY] = "Replay Argument0";
EsperantoAbilityDescriptions[AbilityNames.GONDOLA_DURATION] = "+2 Coins";
EsperantoAbilityDescriptions[AbilityNames.GONDOLA_PLAY] = "Play an Action card";
EsperantoAbilityDescriptions[AbilityNames.HARBOR_VILLAGE] = "+1 Coin";
EsperantoAbilityDescriptions[AbilityNames.LANDING_PARTY] = "Topdeck [Landing Party]";
EsperantoAbilityDescriptions[AbilityNames.MAPMAKER] = "Play [Mapmaker]";
EsperantoAbilityDescriptions[AbilityNames.ROPE] = "+1 Card, then you may trash a card";
EsperantoAbilityDescriptions[AbilityNames.BURIED_TREASURE_DURATION] = "+1 Buy, +3 Coins";
EsperantoAbilityDescriptions[AbilityNames.BURIED_TREASURE_PLAY] = "Play [Buried Treasure]";
EsperantoAbilityDescriptions[AbilityNames.CREW_DURATION] = "Topdeck [Crew]";
EsperantoAbilityDescriptions[AbilityNames.CUTTHROAT_GAIN] = "Gain a Loot";
EsperantoAbilityDescriptions[AbilityNames.ENLARGE] = "Trash a card, to gain one costing up to 2 more.";
EsperantoAbilityDescriptions[AbilityNames.FRIGATE_DURATION] = "";
EsperantoAbilityDescriptions[AbilityNames.FRIGATE_DISCARD] = "Discard down to 4 cards in hand.";
EsperantoAbilityDescriptions[AbilityNames.LONGSHIP_DURATION] = "+2 Cards";
EsperantoAbilityDescriptions[AbilityNames.MINING_ROAD] = "Play Argument0";
EsperantoAbilityDescriptions[AbilityNames.QUARTERMASTER] = "Resolve [Quartermaster]";
EsperantoAbilityDescriptions[AbilityNames.TRICKSTER_REACTION] = "Set aside a Treasure";
EsperantoAbilityDescriptions[AbilityNames.TRICKSTER_RETURN_TO_HAND] = "Put Argument0 in hand";
EsperantoAbilityDescriptions[AbilityNames.WEALTHY_VILLAGE] = "Gain a Loot";
EsperantoAbilityDescriptions[AbilityNames.DELIVER_SET_ASIDE] = "Set aside Argument0";
EsperantoAbilityDescriptions[AbilityNames.DELIVER_RETURN] = "Put Argument0 in hand";
EsperantoAbilityDescriptions[AbilityNames.RUSH] = "Play Argument0";
EsperantoAbilityDescriptions[AbilityNames.MIRROR] = "Gain another Argument0";
EsperantoAbilityDescriptions[AbilityNames.PREPARE] = "Play a set-aside card";
EsperantoAbilityDescriptions[AbilityNames.AMPHORA] = "+1 Buy, +3 Coins";
EsperantoAbilityDescriptions[AbilityNames.DOUBLOONS] = "Gain a Gold";
EsperantoAbilityDescriptions[AbilityNames.ENDLESS_CHALICE] = "+1 Coin, +1 Buy";
EsperantoAbilityDescriptions[AbilityNames.FIGUREHEAD] = "+2 Cards";
EsperantoAbilityDescriptions[AbilityNames.JEWELS] = "Put [Jewels] on the bottom of your deck.";
EsperantoAbilityDescriptions[AbilityNames.PUZZLE_BOX_RETURN] = "Put Argument0 in hand";
EsperantoAbilityDescriptions[AbilityNames.SHIELD_REACTION] = "Reveal [Shield] to be unaffected by the attack.";
EsperantoAbilityDescriptions[AbilityNames.SPELL_SCROLL] = "Play Argument0";
EsperantoAbilityDescriptions[AbilityNames.CURSED_GAIN] = "Gain a Loot and a Curse";
EsperantoAbilityDescriptions[AbilityNames.FAWNING_GAIN] = "Gain a [Fawning] card";
EsperantoAbilityDescriptions[AbilityNames.SCHEDULER] = "[Friendly] (seeing this is a bug!)";
EsperantoAbilityDescriptions[AbilityNames.FRIENDLY_ABILITY] = "Discard a [Friendly] card to gain another";
EsperantoAbilityDescriptions[AbilityNames.HASTY_SET_ASIDE] = "Set Argument0 aside";
EsperantoAbilityDescriptions[AbilityNames.HASTY_PLAY] = "Play Argument0";
EsperantoAbilityDescriptions[AbilityNames.INHERITED] = "Replace a starting card.";
EsperantoAbilityDescriptions[AbilityNames.INSPIRING] = "Play a card that you don't have in play.";
EsperantoAbilityDescriptions[AbilityNames.NEARBY] = "+1 Buy";
EsperantoAbilityDescriptions[AbilityNames.PATIENT_SCHEDULER] = "[Patient] (seeing this is a bug!)";
EsperantoAbilityDescriptions[AbilityNames.PATIENT_SET_ASIDE] = "Set aside [Patient] cards.";
EsperantoAbilityDescriptions[AbilityNames.PATIENT_PLAY] = "Play Argument0";
EsperantoAbilityDescriptions[AbilityNames.PIOUS] = "Trash a card";
EsperantoAbilityDescriptions[AbilityNames.RECKLESS_REPEAT] = "Follow the instructions of Argument0 again.";
EsperantoAbilityDescriptions[AbilityNames.RECKLESS_RETURN] = "Return Argument0 to its pile.";
EsperantoAbilityDescriptions[AbilityNames.RICH_GAIN] = "Gain [^nSilver]";
EsperantoAbilityDescriptions[AbilityNames.SHY] = "Discard a [Shy] card for +2 Cards";
EsperantoAbilityDescriptions[AbilityNames.TIRELESS_SET_ASIDE] = "Set aside Argument0";
EsperantoAbilityDescriptions[AbilityNames.TIRELESS_RETURN] = "Topdeck Argument0";
EsperantoAbilityDescriptions[AbilityNames.STAR_CHART] = "Topdeck";
EsperantoAbilityDescriptions[AbilityNames.ORDER_OF_ASTROLOGERS] = "Topdeck";
EsperantoAbilityDescriptions[AbilityNames.ORDER_OF_MASONS] = "Discard";
EsperantoAbilityDescriptions[AbilityNames.FATED] = "Topdeck and/or Discard";
EsperantoAbilityDescriptions[AbilityNames.AVOID] = "Discard";
EsperantoAbilityDescriptions[AbilityNames.HERBALIST_ASK_FOR_DISCARD] = "Topdeck a Treasure";
EsperantoAbilityDescriptions[AbilityNames.SCHEME_ASK_FOR_DISCARD] = "Topdeck an Action";
EsperantoAbilityDescriptions[AbilityNames.TRICKSTER_ASK_FOR_DISCARD] = "Set aside a Treasure";
EsperantoAbilityDescriptions[AbilityNames.MARCHLAND] = "+1 Buy, Discard cards for +$1 each";
EsperantoAbilityDescriptions[AbilityNames.FARMHANDS_SET_ASIDE] = "Set aside an Action or Treasure";
EsperantoAbilityDescriptions[AbilityNames.FARMHANDS_PLAY] = "play Argument0";
EsperantoAbilityDescriptions[AbilityNames.JOUST_DISCARD] = "Discard a Province";
EsperantoAbilityDescriptions[AbilityNames.INFIRMARY_OVERPAY] = "overpay to play [Infirmary]";
EsperantoAbilityDescriptions[AbilityNames.RESOLVE_INFIRMARY_OVERPAY] = "play [Infirmary]";
EsperantoAbilityDescriptions[AbilityNames.DRAW_FROM_FOOTPAD] = "Draw a card";
EsperantoAbilityDescriptions[AbilityNames.FARRIER_OVERPAY] = "overpay for +Cards at end of turn.";
EsperantoAbilityDescriptions[AbilityNames.RESOLVE_FARRIER_OVERPAY] = "resolve [Farrier] overpay";
EsperantoAbilityDescriptions[AbilityNames.DRAW_FROM_SQUIRREL] = "Draw 2 extra Cards";
EsperantoAbilityDescriptions[AbilityNames.DRAW_FROM_RIVER] = "Draw 1 extra Cards";
EsperantoAbilityDescriptions[AbilityNames.DRAW_FROM_FARRIER] = "Draw Argument0 extra Cards";
EsperantoAbilityDescriptions[AbilityNames.FERRYMAN] = "Gain Argument0";

EsperantoAbilityDescriptions[AbilityNames.BIDING_TIME_SET_ASIDE_HAND] = "Set Cards in hand Aside";
EsperantoAbilityDescriptions[AbilityNames.BIDING_TIME_RETURN_TO_HAND] = "Return cards to hand";
EsperantoAbilityDescriptions[AbilityNames.ENLIGHTENMENT_CANTRIP] = "+1 Card, +1 Action";
EsperantoAbilityDescriptions[AbilityNames.ENLIGHTENMENT_REPLACE_ABILITY] = "Replacement by Enlightenment";
EsperantoAbilityDescriptions[AbilityNames.GOOD_HARVEST] = "+1 Buy +1 Coin";
EsperantoAbilityDescriptions[AbilityNames.HARSH_WINTER] = "Take Debt or put it on the pile";
EsperantoAbilityDescriptions[AbilityNames.KIND_EMPEROR] = "Gain an Action to your hand";
EsperantoAbilityDescriptions[AbilityNames.PANIC_BUYS] = "+2 Buys";
EsperantoAbilityDescriptions[AbilityNames.PANIC_RETURN] = "Return Argument0 to its pile";
EsperantoAbilityDescriptions[AbilityNames.PROGRESS] = "Put Argument0 onto your deck";
EsperantoAbilityDescriptions[AbilityNames.RAPID_EXPANSION_SET_ASIDE] = "Set Argument0 aside";
EsperantoAbilityDescriptions[AbilityNames.RAPID_EXPANSION_PLAY_LATER] = "Play Argument0";
EsperantoAbilityDescriptions[AbilityNames.SICKNESS] = "Curse onto your deck or discard 3 cards";
EsperantoAbilityDescriptions[AbilityNames.RIVER_SHRINE] = "Gain a card up to 4 Coins";
EsperantoAbilityDescriptions[AbilityNames.SAMURAI] = "+1 Coin";
EsperantoAbilityDescriptions[AbilityNames.DAIMYO_REPLAY] = "Replay Argument0";
EsperantoAbilityDescriptions[AbilityNames.FORESIGHT_RETURN_TO_HAND] = EsperantoAbilityDescriptions[AbilityNames.SAVE_RETURN_TO_HAND];