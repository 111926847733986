"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TurnDescription = function (_GameStateChange) {
    _inherits(TurnDescription, _GameStateChange);

    function TurnDescription(game, ownerId, turnNumber, turnType, controllerId) {
        _classCallCheck(this, TurnDescription);

        var _this = _possibleConstructorReturn(this, (TurnDescription.__proto__ || Object.getPrototypeOf(TurnDescription)).call(this, game));

        _this.ownerId = ownerId;
        _this.turnNumber = turnNumber;
        _this.turnType = turnType;
        _this.controllerId = controllerId;
        return _this;
    }

    _createClass(TurnDescription, [{
        key: "execute",
        value: function execute(game) {
            game.state.activeTurn = this;
            game.playerModel.newTurn(this);
            game.layoutModel.computeBestLayout();
            game.logModel.statusBarView.onPlayerChange();
            game.state.zones.filter(function (z) {
                return z.isVisible && z.repositionOnNewTurn;
            }).forEach(function (z) {
                return z.reposition(true);
            });
            game.state.cards.forEach(function (c) {
                return c.revertImage();
            });
        }
    }, {
        key: "createAnimation",
        value: function createAnimation(game) {
            return new UniversalBlocker(this);
        }
    }, {
        key: "owner",
        get: function get() {
            return this.game.state.getPlayer(this.ownerId);
        }
    }, {
        key: "controller",
        get: function get() {
            return this.game.state.getPlayer(this.controllerId);
        }
    }], [{
        key: "parse",
        value: function parse(game, reader) {
            var owner = reader.readInt();
            var number = reader.readInt();
            var type = reader.readInt();
            var controller = reader.readInt();
            return new TurnDescription(game, owner, number, type, controller);
        }
    }]);

    return TurnDescription;
}(GameStateChange);