"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function TokenName(name) {
    var bgURL = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
    var flippedBG = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    var coloredBG = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
    self.name = name;
    self.bgURL = "images/tokens/" + bgURL + ".png";
    self.flippedBG = flippedBG === null ? self.bgURL : "images/tokens/" + flippedBG + ".png";
    self.coloredBG = coloredBG;
}

var TokenNames = {
    PLUS_ACTION: new TokenName("+Action token", "action"),
    PLUS_BUY: new TokenName("+Buy token", "buy"),
    PLUS_COIN: new TokenName("+Coin token", "coin"),
    PLUS_CARD: new TokenName("+Card token", "card"),
    MINUS_COST: new TokenName("-Cost token", "ferry"),
    TRASH: new TokenName("Trashing token", "trash"),
    JOURNEY: new TokenName("Journey token", "journey-token", "journey-token-back", false),
    MINUS_CARD: new TokenName("-Card token", "minuscard", null),
    MINUS_COIN: new TokenName("-Coin token", "minuscoin", null)
};

function CounterName(name, bgURL) {
    var phrases = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    var colorClass = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "black";
    var shouldStroke = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
    var alwaysVisible = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
    var neverNumbered = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;

    this.name = name;
    this.bgURL = "images/elements/" + bgURL + ".png";
    this.phrases = phrases;
    this.colorClass = colorClass;
    this.shouldStroke = shouldStroke;
    this.alwaysVisible = alwaysVisible;
    this.neverNumbered = neverNumbered;
}

var CounterNames = {
    ACTIONS: new CounterName("Actions", null, {
        singular: Phrases.ACTION,
        plural: Phrases.ACTIONS,
        many: Phrases.ACTIONS_MANY
    }, "#BFB"),
    COINS: new CounterName("Coins", "coin"),
    POTIONS: new CounterName("Potions", "potion-square", null, "white", true),
    BUYS: new CounterName("Buys", null, { singular: Phrases.BUY, plural: Phrases.BUYS, many: Phrases.BUYS_MANY }, "#FDB"),
    POINTS: new CounterName("Points", null, { singular: Phrases.VP, plural: Phrases.VP, many: Phrases.VP }, "#0F0"),
    COFFERS: new CounterName("Coffers", "coffers", null, "white", true),
    DEBT: new CounterName("Debt", "debt_130", null, "white", true),
    VP_TOKENS: new CounterName("VP Tokens", "victory_outlined", null, "black", true),
    PIRATE_STRIKES: new CounterName("Pirate Strikes", "pirate_130"),
    EMBARGO_TOKENS: new CounterName("Embargo Tokens", "embargo"),
    TRADE_ROUTE_COINS: new CounterName("Trade Route Coins", "coin-token", null, "black", false, false, true),
    TRADE_ROUTE_MAT: new CounterName("Trade Route Mat"),
    VILLAGERS: new CounterName("Villagers", "villagers", null, "black", true),
    SINISTER_PLOT: new CounterName("Sinister Plot Tokens", "sinister-plot", null, "black", true, true),
    BLOCKADE_TOKENS: new CounterName("Blockade Tokens", "embargo"),
    FAVORS: new CounterName("Favors", "favors", null, "white", true),
    CARD_TOKENS: new CounterName("Card Tokens", "coin-token"),
    REMAINING_HAND_PLAYS: new CounterName("Remaining Hand Plays", "voyage", null, "white", true),
    SUN_TOKENS: new CounterName("Sun Tokens", "rsun", null, "black", true)
};

function AbilityName(name) {
    var isStacking = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    var hasAllButton = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var icon = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    var cardName = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : CardNames.BACK;

    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.name;
    };
    this.buttonText = "";
    this.name = name;
    this.isStacking = isStacking;
    this.hasAllButton = hasAllButton;
    this.icon = icon;
    this.cardName = cardName;
}

var AbilityNames = {
    BUY: new AbilityName("BUY"),
    PLAY_ATTACK: new AbilityName("PLAY_ATTACK"),
    DRAW_INSTRUCTIONS: new AbilityName("DRAW_INSTRUCTIONS"),
    PLAY_INSTRUCTIONS: new AbilityName("PLAY_INSTRUCTIONS"),
    RESOLVE_EVENT: new AbilityName("RESOLVE_EVENT"),
    GAIN_BY_NAME: new AbilityName("GAIN_BY_NAME"),
    FISHING_VILLAGE_ABILITY: new AbilityName("FISHING_VILLAGE_ABILITY", true, true),
    REMOVE_COST_REDUCTION: new AbilityName("REMOVE_COST_REDUCTION"),
    HAVEN_ABILITY: new AbilityName("HAVEN_ABILITY"),
    SAVE_RETURN_TO_HAND: new AbilityName("SAVE_RETURN_TO_HAND"),
    CARAVAN_ABILITY: new AbilityName("CARAVAN_ABILITY", true, true),
    MERCHANT_SHIP_ABILITY: new AbilityName("MERCHANT_SHIP_ABILITY", true, true),
    OUTPOST_ABILITY: new AbilityName("OUTPOST_ABILITY"),
    TACTICIAN_ABILITY: new AbilityName("TACTICIAN_ABILITY", true, true),
    WHARF_ABILITY: new AbilityName("WHARF_ABILITY", true, true),
    HORSE_TRADERS_RETURN_ABILITY: new AbilityName("HORSE_TRADERS_RETURN_ABILITY", true, true),
    DUCHESS_MAY_GAIN: new AbilityName("DUCHESS_MAY_GAIN"),
    FOOLS_GOLD_TRASH: new AbilityName("FOOLS_GOLD_TRASH", true, true),
    NOBLE_BRIGAND: new AbilityName("NOBLE_BRIGAND"),
    HAGGLER_ABILITY: new AbilityName("HAGGLER_ABILITY"),
    INN_ABILITY: new AbilityName("INN_ABILITY"),
    MANDARIN: new AbilityName("MANDARIN"),
    FARMLAND: new AbilityName("FARMLAND"),
    GAIN_CHEAPER: new AbilityName("GAIN_CHEAPER"),
    GAIN_TWO_RUINS: new AbilityName("GAIN_TWO_RUINS"),
    HUNTING_GROUNDS: new AbilityName("HUNTING_GROUNDS"),
    SQUIRE_GAIN_ATTACK: new AbilityName("SQUIRE_GAIN_ATTACK"),
    URCHIN_TRANSFORM: new AbilityName("URCHIN_TRANSFORM"),
    FEODUM_GAIN_SILVERS: new AbilityName("FEODUM_GAIN_SILVERS", true, true),
    DOCTOR_OVERPAY: new AbilityName("DOCTOR_OVERPAY"),
    RESOLVE_DOCTOR_OVERPAY: new AbilityName("RESOLVE_DOCTOR_OVERPAY"),
    HERALD_OVERPAY: new AbilityName("HERALD_OVERPAY"),
    RESOLVE_HERALD_OVERPAY: new AbilityName("RESOLVE_HERALD_OVERPAY"),
    MASTERPIECE_OVERPAY: new AbilityName("MASTERPIECE_OVERPAY"),
    RESOLVE_MASTERPIECE_OVERPAY: new AbilityName("RESOLVE_MASTERPIECE_OVERPAY"),
    STONEMASON_OVERPAY: new AbilityName("STONEMASON_OVERPAY"),
    RESOLVE_STONEMASON_OVERPAY: new AbilityName("RESOLVE_STONEMASON_OVERPAY"),
    EXCHANGE: new AbilityName("EXCHANGE"),
    RESHUFFLE: new AbilityName("RESHUFFLE"),
    AMULET: new AbilityName("AMULET"),
    CARAVAN_GUARD_NEXT_TURN: new AbilityName("CARAVAN_GUARD_NEXT_TURN", true, true),
    DUNGEON_ABILITY: new AbilityName("DUNGEON_ABILITY"),
    GEAR_ABILITY: new AbilityName("GEAR_ABILITY", false),
    MESSENGER_GAIN: new AbilityName("MESSENGER_GAIN"),
    BRIDGE_TROLL_ABILITY: new AbilityName("BRIDGE_TROLL_ABILITY", true, true),
    HAUNTED_WOODS_DURATION: new AbilityName("HAUNTED_WOODS_DURATION", true, true),
    HAUNTED_WOODS_TOPDECK: new AbilityName("HAUNTED_WOODS_TOPDECK"),
    LOST_CITY: new AbilityName("LOST_CITY", true, true),
    WINE_MERCHANT_DISCARD: new AbilityName("WINE_MERCHANT_DISCARD", true, true),
    HIRELING: new AbilityName("HIRELING", true, true),
    SWAMP_HAG_DURATION: new AbilityName("SWAMP_HAG_DURATION", true, true),
    TRASH_TOKEN_ABILITY: new AbilityName("TRASH_TOKEN_ABILITY"),
    EMPORIUM_GAIN_POINTS: new AbilityName("EMPORIUM_GAIN_POINTS", true, true),
    ROCKS_GAINS_SILVER: new AbilityName("ROCKS_GAINS_SILVER", true, true),
    FORTUNE: new AbilityName("FORTUNE"),
    CRUMBLING_CASTLE: new AbilityName("CRUMBLING_CASTLE"),
    HAUNTED_CASTLE: new AbilityName("HAUNTED_CASTLE"),
    SPRAWLING_CASTLE: new AbilityName("SPRAWLING_CASTLE"),
    GRAND_CASTLE: new AbilityName("GRAND_CASTLE"),
    ENCHANTRESS_ABILITY: new AbilityName("ENCHANTRESS_ABILITY", true, true),
    ENCHANTRESS_CANTRIP: new AbilityName("ENCHANTRESS_CANTRIP"),
    TEMPLE: new AbilityName("TEMPLE"),
    VILLA: new AbilityName("VILLA"),
    ARCHIVE_ABILITY: new AbilityName("ARCHIVE_ABILITY", false),
    CAPITAL_TAKE_DEBT: new AbilityName("CAPITAL_TAKE_DEBT", true, true),
    CHARM: new AbilityName("CHARM"),
    FORUM: new AbilityName("FORUM"),
    HERMIT: new AbilityName("HERMIT"),
    DONATE: new AbilityName("DONATE"),
    TAX_RECEIVE: new AbilityName("TAX_RECEIVE"),
    DOMINATE: new AbilityName("DOMINATE"),
    VP_TO_AQUEDUCT: new AbilityName("VP_TO_AQUEDUCT"),
    VP_FROM_AQUEDUCT: new AbilityName("VP_FROM_AQUEDUCT"),
    ARENA_DISCARD_ACTION: new AbilityName("ARENA_DISCARD_ACTION"),
    VP_FROM_BASILICA: new AbilityName("VP_FROM_BASILICA"),
    VP_FROM_BATHS: new AbilityName("VP_FROM_BATHS"),
    VP_FROM_BATTLEFIELD: new AbilityName("VP_FROM_BATTLEFIELD"),
    VP_FROM_COLONNADE: new AbilityName("VP_FROM_COLONNADE"),
    VP_TO_DEFILED_SHRINE: new AbilityName("VP_TO_DEFILED_SHRINE"),
    VP_FROM_DEFILED_SHRINE: new AbilityName("VP_FROM_DEFILED_SHRINE"),
    VP_FROM_LABYRINTH: new AbilityName("VP_FROM_LABYRINTH"),
    MOUNTAIN_PASS_BIDDING: new AbilityName("MOUNTAIN_PASS_BIDDING"),
    TOMB: new AbilityName("TOMB"),
    LIGHTHOUSE_COIN: new AbilityName("LIGHTHOUSE_COIN", true, true),
    REACTION: new AbilityName("REACTION"),
    CALL_GUIDE: new AbilityName("CALL_GUIDE"),
    CALL_RATCATCHER: new AbilityName("CALL_RATCATCHER"),
    CALL_TRANSMOGRIFY: new AbilityName("CALL_TRANSMOGRIFY"),
    CALL_TEACHER: new AbilityName("CALL_TEACHER"),
    CALL_DUPLICATE: new AbilityName("CALL_DUPLICATE", true, true),
    CALL_COIN: new AbilityName("CALL_COIN", true, true),
    CALL_ROYAL_CARRIAGE: new AbilityName("CALL_ROYAL_CARRIAGE"),
    START_OF_TURN: new AbilityName("START_OF_TURN"),
    START_BUY_PHASE: new AbilityName("START_BUY_PHASE"),
    END_OF_BUY_PHASE: new AbilityName("END_OF_BUY_PHASE"),
    BETWEEN_TURNS: new AbilityName("BETWEEN_TURNS"),
    END_OF_TURN: new AbilityName("END_OF_TURN"),
    RIGHT_BEFORE_DRAWING_YOUR_NEXT_HAND: new AbilityName("RIGHT_BEFORE_DRAWING_YOUR_NEXT_HAND"),
    SUMMON_PLAY: new AbilityName("SUMMON_PLAY"),
    CARAVAN_GUARD_REACTION: new AbilityName("CARAVAN_GUARD_REACTION", true, true),
    TRAVELLING_FAIR_REACTION: new AbilityName("TRAVELLING_FAIR_REACTION"),
    MOAT_REACTION: new AbilityName("MOAT_REACTION"),
    HORSE_TRADERS_REACTION: new AbilityName("HORSE_TRADERS_REACTION", true, true),
    BEGGAR_REACTION: new AbilityName("BEGGAR_REACTION", true, true),
    MARKET_SQUARE_REACTION: new AbilityName("MARKET_SQUARE_REACTION", true, true),
    TRADER_REACTION: new AbilityName("TRADER_REACTION"),
    DIPLOMAT_REACTION: new AbilityName("DIPLOMAT_REACTION"),
    TOPDECK_REACTION: new AbilityName("TOPDECK_REACTION"),
    WATCHTOWER_TRASH_REACTION: new AbilityName("WATCHTOWER_TRASH_REACTION", true, false, "trash"),
    WATCHTOWER_TOPDECK_REACTION: new AbilityName("WATCHTOWER_TOPDECK_REACTION", true, false, "topdeck"),
    PRINCE_PLAY: new AbilityName("PRINCE_PLAY"),
    TUNNEL_REVEAL: new AbilityName("TUNNEL_REVEAL", true, true),
    POSSESSION_GAIN_INSTEAD: new AbilityName("POSSESSION_GAIN_INSTEAD"),
    HOVEL_TRASH: new AbilityName("HOVEL_TRASH", true, true),
    SAUNA_TRASH_ABILITY: new AbilityName("SAUNA_TRASH_ABILITY"),
    DELUDED_RETURNED: new AbilityName("DELUDED_RETURNED"),
    ENVIOUS_RETURNED: new AbilityName("ENVIOUS_RETURNED"),
    RECEIVE_BOON: new AbilityName("RECEIVE_BOON"),
    COBBLER_ABILITY: new AbilityName("COBBLER_ABILITY"),
    CRYPT_ABILITY: new AbilityName("CRYPT_ABILITY", false),
    DEN_OF_SIN_ABILITY: new AbilityName("DEN_OF_SIN_ABILITY", true, true),
    FAITHFUL_HOUND: new AbilityName("FAITHFUL_HOUND", true, true),
    FAITHFUL_HOUND_RETURN: new AbilityName("FAITHFUL_HOUND_RETURN", true, true),
    GHOST_TOWN_ABILITY: new AbilityName("GHOST_TOWN_ABILITY", true, true),
    GUARDIAN_COIN: new AbilityName("GUARDIAN_COIN", true, true),
    RAIDER_ABILITY: new AbilityName("RAIDER_ABILITY", true, true),
    SECRET_CAVE: new AbilityName("SECRET_CAVE", true, true),
    CEMETERY: new AbilityName("CEMETERY"),
    HAUNTED_MIRROR: new AbilityName("HAUNTED_MIRROR"),
    GHOST: new AbilityName("GHOST"),
    REMOVE_FLIPPED_STATUS: new AbilityName("REMOVE_FLIPPED_STATUS"),
    LOST_IN_THE_WOODS: new AbilityName("LOST_IN_THE_WOODS"),
    BLESSED_VILLAGE: new AbilityName("BLESSED_VILLAGE", false),
    FORTRESS: new AbilityName("FORTRESS", true, true),
    RECEIVE_HEX: new AbilityName("RECEIVE_HEX"),
    END_TEMPORARY_EFFECT: new AbilityName("END_TEMPORARY_EFFECT"),
    CHANGELING_MAY_EXCHANGE: new AbilityName("CHANGELING_MAY_EXCHANGE"),

    TRADE_ROUTE_MOVE_TOKEN: new AbilityName("TRADE_ROUTE_MOVE_TOKEN"),
    GET_POINTS: new AbilityName("GET_POINTS"),
    REPLACE_TOPDECK: new AbilityName("REPLACE_TOPDECK"),

    DUCAT: new AbilityName('DUCAT'),
    CARGO_SHIP_SET_ASIDE: new AbilityName("Cargo Ship set aside"),
    CARGO_SHIP_RETURN: new AbilityName("Cargo Ship return", true, true),
    IMPROVE: new AbilityName("Improve"),
    REVEAL: new AbilityName("Reveal"),
    ADD_COFFERS: new AbilityName("Add Coffers"),
    PRIEST: new AbilityName("Priest", true, true),
    RESEARCH: new AbilityName("Research", true, true),
    SILK_MERCHANT: new AbilityName("Silk Merchant", true, true),
    LACKEYS: new AbilityName("Lackeys", true, true),
    SPICES: new AbilityName("Spices", true, true),
    KEY: new AbilityName("Key"),
    TREASURE_CHEST: new AbilityName("Treasure Chest"),
    FLAG_BEARER: new AbilityName("Flag Bearer"),
    CATHEDRAL: new AbilityName("Cathedral"),
    CITY_GATE: new AbilityName("CITY_GATE"),
    PAGEANT: new AbilityName("PAGEANT"),
    SEWERS_MAY_TRASH: new AbilityName("SEWERS_MAY_TRASH"),
    EXPLORATION: new AbilityName("EXPLORATION"),
    FAIR: new AbilityName("FAIR"),
    SILOS: new AbilityName("SILOS"),
    ACADEMY: new AbilityName("ACADEMY"),
    GUILDHALL: new AbilityName("GUILDHALL"),
    PIAZZA: new AbilityName("PIAZZA"),
    BARRACKS: new AbilityName("BARRACKS"),
    CROP_ROTATION: new AbilityName("CROP_ROTATION"),
    INNOVATION: new AbilityName("INNOVATION"),
    CITADEL: new AbilityName("CITADEL"),
    SINISTER_PLOT: new AbilityName("SINISTER_PLOT"),
    CAPITALISM: new AbilityName("CAPITALISM"),
    ENCAMPMENT_RETURNED: new AbilityName("ENCAMPMENT_RETURNED", true, true),
    EMBARGO_GAIN_CURSE: new AbilityName("EMBARGO_GAIN_CURSE", true, true),
    CAPTAIN: new AbilityName("CAPTAIN"),
    CHURCH: new AbilityName("CHURCH", false),
    POSSESSION_SETS_ASIDE: new AbilityName("POSSESSION_SETS_ASIDE"),
    INHERITANCE_ENDS_ACTION_TYPE: new AbilityName("INHERITANCE_ENDS_ACTION_TYPE"),
    MINT: new AbilityName("MINT"),
    SLEIGH_TOPDECK_REACTION: new AbilityName("SLEIGH_TOPDECK_REACTION", true, false, "topdeck"),
    SLEIGH_PUT_IN_HAND_REACTION: new AbilityName("SLEIGH_PUT_IN_HAND_REACTION", true, false, "put-in-hand"),
    BLACK_CAT_REACTION: new AbilityName("BLACK_CAT_REACTION"),
    DISCARD_FROM_EXILE: new AbilityName("DISCARD_FROM_EXILE"),
    EXILE_BY_NAME: new AbilityName("EXILE_BY_NAME"),
    SHEEPDOG_REACTION: new AbilityName("SHEEPDOG_REACTION"),
    CAVALRY: new AbilityName("CAVALRY"),
    HOSTELRY: new AbilityName("HOSTELRY"),
    VILLAGE_GREEN_VILLAGE: new AbilityName("VILLAGE_GREEN_VILLAGE", true, true),
    VILLAGE_GREEN_PLAY: new AbilityName("VILLAGE_GREEN_PLAY"),
    BARGE: new AbilityName("BARGE", true, true),
    FALCONER_REACTION: new AbilityName("FALCONER_REACTION"),
    GATEKEEPER_EXILE: new AbilityName("GATEKEEPER_EXILE"),
    GATEKEEPER_DURATION: new AbilityName("GATEKEEPER_DURATION", true, true),
    LIVERY: new AbilityName("LIVERY", true, true),
    MASTERMIND: new AbilityName("MASTERMIND"),
    DELAY_PLAY: new AbilityName("DELAY_PLAY"),
    INVEST: new AbilityName("INVEST", true, true),
    REAP_PLAY: new AbilityName("REAP_PLAY", true),
    PLUS_CARD_TOKEN: new AbilityName("PLUS_CARD_TOKEN"),
    PLUS_ACTION_TOKEN: new AbilityName("PLUS_ACTION_TOKEN"),
    PLUS_BUY_TOKEN: new AbilityName("PLUS_BUY_TOKEN"),
    PLUS_COIN_TOKEN: new AbilityName("PLUS_COIN_TOKEN"),
    KILN: new AbilityName("KILN"),
    CHAMELEON_RESOLUTION: new AbilityName("CHAMELEON_RESOLUTION", true, false, null, CardNames.WAY_OF_THE_CHAMELEON),
    RETURN_MINUS_COIN_TOKEN: new AbilityName("RETURN_MINUS_COIN"),
    TURTLE_PLAY: new AbilityName("TURTLE_PLAY"),
    GIVE_PLUS_CARDS: new AbilityName("GIVE_PLUS_CARDS"),
    GIVE_PLUS_ACTIONS: new AbilityName("GIVE_PLUS_ACTIONS"),
    GIVE_PLUS_BUYS: new AbilityName("GIVE_PLUS_BUYS"),
    GIVE_PLUS_COINS: new AbilityName("GIVE_PLUS_COINS"),
    MONKEY_NEXT_TURN_ABILITY: new AbilityName("MONKEY_NEXT_TURN_ABILITY", true, true),
    BLOCKADE_ABILITY: new AbilityName("BLOCKADE_ABILITY", true, true),
    PIRATE_ABILITY: new AbilityName("PIRATE_ABILITY"),
    PIRATE_REACTION: new AbilityName("PIRATE_REACTION", true, true),
    CORSAIR_NEXT_TURN_ABILITY: new AbilityName("CORSAIR_NEXT_TURN_ABILITY", true, true),
    CORSAIR_TRASH: new AbilityName("CORSAIR_TRASH"),
    SAILOR_NEXT_TURN: new AbilityName("SAILOR_NEXT_TURN"),
    SAILOR_REACTION: new AbilityName("SAILOR_REACTION"),
    RESET_COUNTER: new AbilityName("RESET_COUNTER"),
    UNUSED_ABILITY_8: new AbilityName("UNUSED_ABILITY_8"),
    SKIRMISHER: new AbilityName("SKIRMISHER", true, true),
    ENCHANTRESS_REPLACE_ABILITY: new AbilityName("ENCHANTRESS_REPLACE_ABILITY"),
    HIGHWAYMAN_NEXT_TURN_ABILITY: new AbilityName("HIGHWAYMAN_NEXT_TURN_ABILITY", true, true),
    HIGHWAYMAN_REPLACE_ABILITY: new AbilityName("HIGHWAYMAN_REPLACE_ABILITY"),
    HIGHWAYMAN_NULLIFIED: new AbilityName("HIGHWAYMAN_NULLIFIED"),
    LEAGUE_OF_BANKERS: new AbilityName("LEAGUE_OF_BANKERS"),
    WOODWORKERS_GUILD: new AbilityName("WOODWORKERS_GUILD"),
    CRAFTERS_GUILD: new AbilityName("CRAFTERS_GUILD"),
    CAVE_DWELLERS: new AbilityName("CAVE_DWELLERS"),
    DESERT_GUIDES: new AbilityName("DESERT_GUIDES"),
    BAND_OF_NOMADS_CARD: new AbilityName("BAND_OF_NOMADS_CARD", true, false, "1-card"),
    BAND_OF_NOMADS_ACTION: new AbilityName("BAND_OF_NOMADS_ACTION", true, false, "action"),
    BAND_OF_NOMADS_BUY: new AbilityName("BAND_OF_NOMADS_BUY", true, false, "buy"),
    SYCOPHANT: new AbilityName("SYCOPHANT"),
    IMPORTER_ABILITY: new AbilityName("IMPORTER_ABILITY"),
    CONTRACT_ABILITY: new AbilityName("CONTRACT_ABILITY"),
    MARKET_TOWNS: new AbilityName("MARKET_TOWNS"),
    TRAPPERS_LODGE: new AbilityName("TRAPPERS_LODGE"),
    GUILDMASTER: new AbilityName("GUILDMASTER", true, true),
    ISLAND_FOLK: new AbilityName("ISLAND_FOLK"),
    GANG_OF_PICKPOCKETS: new AbilityName("GANG_OF_PICKPOCKETS"),
    COASTAL_HAVEN: new AbilityName("COASTAL_HAVEN"),
    CITY_STATE: new AbilityName("CITY_STATE"),
    CITY_STATE_PLAY: new AbilityName("CITY_STATE_PLAY"),
    CIRCLE_OF_WITCHES: new AbilityName("CIRCLE_OF_WITCHES"),
    ROYAL_GALLEY_SET_ASIDE: new AbilityName("ROYAL_GALLEY_SET_ASIDE"),
    ROYAL_GALLEY_PLAY: new AbilityName("ROYAL_GALLEY_PLAY"),
    STRONGHOLD_ABILITY: new AbilityName("STRONGHOLD_ABILITY", true, true),
    VOYAGE_NULL_ABILITY: new AbilityName("VOYAGE_NULL_ABILITY"),
    MOUNTAIN_FOLK: new AbilityName("MOUNTAIN_FOLK"),
    PEACEFUL_CULT: new AbilityName("PEACEFUL_CULT"),
    FELLOWSHIP_OF_SCRIBES: new AbilityName("FELLOWSHIP_OF_SCRIBES"),
    LEAGUE_OF_SHOPKEEPERS: new AbilityName("LEAGUE_OF_SHOPKEEPERS"),
    TERRITORY_GAIN: new AbilityName("TERRITORY_GAIN"),
    WARLORD_ABILITY: new AbilityName("WARLORD_ABILITY", true, true),
    GARRISON_ADD_TOKEN: new AbilityName("GARRISON_ADD_TOKEN", true, true),
    GARRISON_REMOVE_ABILITY: new AbilityName("GARRISON_REMOVE_ABILITY", true, true),
    CONJURER: new AbilityName("CONJURER", true, true),
    LICH: new AbilityName("LICH"),
    GALLERIA: new AbilityName("GALLERIA", true, true),
    ARCHITECTS_GUILD: new AbilityName("ARCHITECTS_GUILD"),
    FAMILY_OF_INVENTORS: new AbilityName("FAMILY_OF_INVENTORS"),
    FOREST_DWELLERS: new AbilityName("FOREST_DWELLERS"),
    ASTROLABE_ABILITY: new AbilityName("ASTROLABE_ABILITY", true, true),
    TIDE_POOLS_ABILITY: new AbilityName("TIDE_POOLS_ABILITY"),
    SEA_WITCH_ABILITY: new AbilityName("SEA_WITCH_ABILITY"),
    CLERK_ABILITY: new AbilityName("CLERK_ABILITY", true, true),
    GUARD_DOG_REACTION: new AbilityName("GUARD_DOG_REACTION"),
    BERSERKER: new AbilityName("BERSERKER"),
    NOMADS: new AbilityName("NOMADS", true, true),
    TRAIL_REACTION: new AbilityName("TRAIL_REACTION", true, true),
    WEAVER_PLAY: new AbilityName("WEAVER_PLAY"),
    SOUK: new AbilityName("SOUK"),
    CAULDRON_CURSING: new AbilityName("CAULDRON_CURSING", true, true),
    MARCHLAND: new AbilityName("MARCHLAND", true),
    UNUSED_ABILITY_21: new AbilityName("UNUSED_ABILITY_21"),
    UNUSED_ABILITY_22: new AbilityName("UNUSED_ABILITY_22", true, true),
    UNUSED_ABILITY_23: new AbilityName("UNUSED_ABILITY_23"),
    FERRYMAN: new AbilityName("FERRYMAN"),
    TREASURY: new AbilityName("TREASURY", true, true),
    UNUSED_ABILITY_26: new AbilityName("UNUSED_ABILITY_26"),
    MERCHANT_GUILD: new AbilityName("MERCHANT_GUILD", true, true),
    ALCHEMIST_TOPDECK: new AbilityName("ALCHEMIST_TOPDECK", true, true),
    HERBALIST_TOPDECK: new AbilityName("HERBALIST_TOPDECK"),
    SCHEME_TOPDECK: new AbilityName("SCHEME_TOPDECK"),
    FROG_TOPDECK: new AbilityName("FROG_TOPDECK", true, true),
    MERCHANT_CAMP_TOPDECK: new AbilityName("MERCHANT_CAMP_TOPDECK", true, true),
    WALLED_VILLAGE_TOPDECK: new AbilityName("WALLED_VILLAGE_TOPDECK", true, true),
    TENT_TOPDECK: new AbilityName("TENT_TOPDECK", true, true),
    HORN_TOPDECK: new AbilityName("HORN_TOPDECK", true, true),
    PAGE_EXCHANGE: new AbilityName("PAGE_EXCHANGE", true, true, null, CardNames.TREASURE_HUNTER),
    TREASURE_HUNTER_EXCHANGE: new AbilityName("TREASURE_HUNTER_EXCHANGE", true, true, null, CardNames.WARRIOR),
    WARRIOR_EXCHANGE: new AbilityName("WARRIOR_EXCHANGE", true, true, null, CardNames.HERO),
    HERO_EXCHANGE: new AbilityName("HERO_EXCHANGE", true, true, null, CardNames.CHAMPION),
    PEASANT_EXCHANGE: new AbilityName("PEASANT_EXCHANGE", true, true, null, CardNames.SOLDIER),
    SOLDIER_EXCHANGE: new AbilityName("SOLDIER_EXCHANGE", true, true, null, CardNames.FUGITIVE),
    FUGITIVE_EXCHANGE: new AbilityName("FUGITIVE_EXCHANGE", true, true, null, CardNames.DISCIPLE),
    DISCIPLE_EXCHANGE: new AbilityName("DISCIPLE_EXCHANGE", true, true, null, CardNames.TEACHER),

    CAGE_TRASH: new AbilityName("CAGE_ABILITY", false),
    CAGE_PUT_IN_HAND: new AbilityName("CAGE_PUT_IN_HAND", false),
    GROTTO: new AbilityName("GROTTO_ABILITY"),
    JEWELLED_EGG_GAIN_LOOT: new AbilityName("JEWELLED_EGG_GAIN_LOOT", true, true),
    SEARCH: new AbilityName("SEARCH", true, true),
    SHAMAN: new AbilityName("SHAMAN_ABILITY", true, true, null, CardNames.SHAMAN),
    SECLUDED_SHRINE: new AbilityName("SECLUDED_SHRINE"),
    SIREN_GAIN: new AbilityName("SIREN_GAIN"),
    SIREN_DURATION: new AbilityName("SIREN_DURATION", true, true),
    STOWAWAY_DRAW: new AbilityName("STOWAWAY_DRAW", true, true),
    STOWAWAY_REACTION: new AbilityName("STOWAWAY_REACTION", true, true),
    TASKMASTER: new AbilityName("TASKMASTER", true, true),
    ABUNDANCE: new AbilityName("ABUNDANCE", true, true),
    CABIN_BOY: new AbilityName("CABIN_BOY"),
    FLAGSHIP_ACTIVATE: new AbilityName("FLAGSHIP_ACTIVATE", true, true),
    FLAGSHIP_REPLAY: new AbilityName("FLAGSHIP_REPLAY"),
    GONDOLA_DURATION: new AbilityName("GONDOLA_DURATION", true, true),
    GONDOLA_PLAY: new AbilityName("GONDOLA_PLAY"),
    HARBOR_VILLAGE: new AbilityName("HARBOR_VILLAGE", true, true),
    LANDING_PARTY: new AbilityName("LANDING_PARTY", true, true),
    MAPMAKER: new AbilityName("MAPMAKER"),
    ROPE: new AbilityName("ROPE"),
    BURIED_TREASURE_DURATION: new AbilityName("BURIED_TREASURE_DURATION", true, true),
    BURIED_TREASURE_PLAY: new AbilityName("BURIED_TREASURE_PLAY", true, true),
    CREW_DURATION: new AbilityName("CREW_DURATION", true, true),
    CUTTHROAT_GAIN: new AbilityName("CUTTHROAT", true, true),
    ENLARGE: new AbilityName("ENLARGE"),
    FRIGATE_DURATION: new AbilityName("FRIGATE_DURATION"),
    FRIGATE_DISCARD: new AbilityName("FRIGATE_DISCARD", true, true),
    LONGSHIP_DURATION: new AbilityName("LONGSHIP_DURATION", true, true),
    MINING_ROAD: new AbilityName("MINING_ROAD"),
    QUARTERMASTER: new AbilityName("QUARTERMASTER"),
    TRICKSTER_REACTION: new AbilityName("TRICKSTER_REACTION"),
    TRICKSTER_RETURN_TO_HAND: new AbilityName("TRICKSTER_RETURN_TO_HAND"),
    WEALTHY_VILLAGE: new AbilityName("WEALTHY_VILLAGE", true, true),
    DELIVER_SET_ASIDE: new AbilityName("DELIVER_SET_ASIDE", true, true),
    DELIVER_RETURN: new AbilityName("DELIVER_RETURN", false),
    RUSH: new AbilityName("RUSH", false),
    MIRROR: new AbilityName("MIRROR"),
    PREPARE: new AbilityName("PREPARE", false),
    AMPHORA: new AbilityName("AMPHORA", true, true),
    DOUBLOONS: new AbilityName("DOUBLOONS"),
    ENDLESS_CHALICE: new AbilityName("ENDLESS_CHALICE", true, true),
    FIGUREHEAD: new AbilityName("FIGUREHEAD", true, true),
    JEWELS: new AbilityName("JEWELS", true, true),
    PUZZLE_BOX_RETURN: new AbilityName("PUZZLE_BOX_RETURN", true, true),
    SHIELD_REACTION: new AbilityName("SHIELD"),
    SPELL_SCROLL: new AbilityName("SPELL_SCROLL"),
    CURSED_GAIN: new AbilityName("CURSED_GAIN"),
    FAWNING_GAIN: new AbilityName("FAWNING_GAIN"),
    SCHEDULER: new AbilityName("SCHEDULER"),
    FRIENDLY_ABILITY: new AbilityName("FRIENDLY_ABILITY"),
    HASTY_SET_ASIDE: new AbilityName("HASTY_SET_ASIDE"),
    HASTY_PLAY: new AbilityName("HASTY_PLAY"),
    INHERITED: new AbilityName("INHERITED"),
    INSPIRING: new AbilityName("INSPIRING"),
    NEARBY: new AbilityName("NEARBY"),
    PATIENT_SCHEDULER: new AbilityName("PATIENT_SCHEDULER"),
    PATIENT_SET_ASIDE: new AbilityName("PATIENT_SET_ASIDE"),
    PATIENT_PLAY: new AbilityName("PATIENT_PLAY"),
    PIOUS: new AbilityName("PIOUS"),
    RECKLESS_REPEAT: new AbilityName("RECKLESS_REPEAT"),
    RECKLESS_RETURN: new AbilityName("RECKLESS_RETURN"),
    RICH_GAIN: new AbilityName("RICH_GAIN"),
    SHY: new AbilityName("SHY"),
    TIRELESS_SET_ASIDE: new AbilityName("TIRELESS_SET_ASIDE"),
    TIRELESS_RETURN: new AbilityName("TIRELESS_RETURN"),
    BEFORE_FIRST_TURN: new AbilityName("BEFORE_FIRST_TURN", true, true),
    SHUFFLING: new AbilityName("SHUFFLING"),
    STAR_CHART: new AbilityName("STAR_CHART"),
    ORDER_OF_ASTROLOGERS: new AbilityName("ORDER_OF_ASTROLOGERS"),
    ORDER_OF_MASONS: new AbilityName("ORDER_OF_MASONS"),
    FATED: new AbilityName("FATED"),
    AVOID: new AbilityName("AVOID"),
    HERBALIST_ASK_FOR_DISCARD: new AbilityName("HERBALIST_ASK_FOR_DISCARD"),
    SCHEME_ASK_FOR_DISCARD: new AbilityName("SCHEME_ASK_FOR_DISCARD"),
    TRICKSTER_ASK_FOR_DISCARD: new AbilityName("TRICKSTER_ASK_FOR_DISCARD"),
    FARMHANDS_SET_ASIDE: new AbilityName("FARMHANDS_SET_ASIDE"),
    FARMHANDS_PLAY: new AbilityName("FARMHANDS_PLAY"),
    JOUST_DISCARD: new AbilityName("JOUST_DISCARD"),
    INFIRMARY_OVERPAY: new AbilityName("INFIRMARY_OVERPAY"),
    RESOLVE_INFIRMARY_OVERPAY: new AbilityName("RESOLVE_INFIRMARY_OVERPAY"),
    DRAW_FROM_FOOTPAD: new AbilityName("DRAW_FROM_FOOTPAD"),
    FARRIER_OVERPAY: new AbilityName("FARRIER_OVERPAY"),
    RESOLVE_FARRIER_OVERPAY: new AbilityName("RESOLVE_FARRIER_OVERPAY"),
    DRAW_FROM_SQUIRREL: new AbilityName("DRAW_FROM_SQUIRREL"),
    DRAW_FROM_RIVER: new AbilityName("DRAW_FROM_RIVER"),
    DRAW_FROM_FARRIER: new AbilityName("DRAW_FROM_FARRIER"),
    BIDING_TIME_SET_ASIDE_HAND: new AbilityName("BIDING_TIME_SET_ASIDE_HAND"),
    BIDING_TIME_RETURN_TO_HAND: new AbilityName("BIDING_TIME_RETURN_TO_HAND"),
    ENLIGHTENMENT_CANTRIP: new AbilityName("ENLIGHTENMENT_CANTRIP"),
    ENLIGHTENMENT_REPLACE_ABILITY: new AbilityName("ENLIGHTENMENT_REPLACE_ABILITY"),
    GOOD_HARVEST: new AbilityName("GOOD_HARVEST"),
    HARSH_WINTER: new AbilityName("HARSH_WINTER"),
    KIND_EMPEROR: new AbilityName("KIND_EMPEROR"),
    PANIC_BUYS: new AbilityName("PANIC_BUYS"),
    PANIC_RETURN: new AbilityName("PANIC_RETURN"),
    PROGRESS: new AbilityName("PROGRESS"),
    RAPID_EXPANSION_SET_ASIDE: new AbilityName("RAPID_EXPANSION_SET_ASIDE"),
    RAPID_EXPANSION_PLAY_LATER: new AbilityName("RAPID_EXPANSION_PLAY_LATER"),
    SICKNESS: new AbilityName("SICKNESS"),
    RIVERBOAT: new AbilityName("RIVERBOAT"),
    RIVER_SHRINE: new AbilityName("RIVER_SHRINE"),
    SAMURAI: new AbilityName("SAMURAI", true, true),
    DAIMYO_REPLAY: new AbilityName("DAIMYO_REPLAY"),
    FORESIGHT_RETURN_TO_HAND: new AbilityName("FORESIGHT_RETURN_TO_HAND")
};

function QuestionElementType() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var QuestionElementTypes = {
    CARDS: new QuestionElementType(),
    ABILITIES: new QuestionElementType(),
    EXTRA_TURNS: new QuestionElementType(),
    ZONES: new QuestionElementType(),
    CARD_MODES: new QuestionElementType(),
    CARD_NAMES: new QuestionElementType(),
    GAME_BUTTONS: new QuestionElementType(),
    CLEANUP_ACTIONS: new QuestionElementType(),
    BUYABLE_CARDS: new QuestionElementType()
};

function ExtraTurnType(cardName) {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
    self.cardName = cardName;
}

var ExtraTurnTypes = {
    REGULAR: new ExtraTurnType(CardNames.BACK),
    BETWEEN: new ExtraTurnType(CardNames.BACK),
    POSSESSION: new ExtraTurnType(CardNames.POSSESSION),
    MISSION: new ExtraTurnType(CardNames.MISSION),
    OUTPOST: new ExtraTurnType(CardNames.OUTPOST),
    FLEET: new ExtraTurnType(CardNames.FLEET),
    SEIZE_THE_DAY: new ExtraTurnType(CardNames.SEIZE_THE_DAY),
    ISLAND_FOLK: new ExtraTurnType(CardNames.ISLAND_FOLK),
    VOYAGE: new ExtraTurnType(CardNames.VOYAGE),
    JOURNEY: new ExtraTurnType(CardNames.JOURNEY)
};

function CardMode(cardName) {
    var imageName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var getLabel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    var premovable = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
    self.cardName = cardName;
    self.imageName = imageName === null ? null : "images/buttons/" + imageName + ".png";
    self.getLabel = getLabel === null ? function (game) {
        return "";
    } : getLabel;
    self.premovable = premovable;

    if (cardName !== CardNames.BACK) {
        cardName.cardModes.push(this);
    }
}

var CardModes = {
    PAWN_CARD: new CardMode(CardNames.PAWN, "1-card", null, false),
    PAWN_ACTION: new CardMode(CardNames.PAWN, "action", null, false),
    PAWN_BUY: new CardMode(CardNames.PAWN, "buy", null, false),
    PAWN_COIN: new CardMode(CardNames.PAWN, "1-coin", null, false),
    PAWN_CARD_ACTION: new CardMode(CardNames.PAWN, "card-action"),
    STEWARD_CARDS: new CardMode(CardNames.STEWARD, "2-card"),
    STEWARD_COINS: new CardMode(CardNames.STEWARD, "2-coin"),
    STEWARD_TRASH: new CardMode(CardNames.STEWARD, "trash-multiple"),
    MINION_COINS: new CardMode(CardNames.MINION, "2-coin"),
    MINION_NEWHAND: new CardMode(CardNames.MINION, "minion-draw"),
    NOBLES_CARDS: new CardMode(CardNames.NOBLES, "3-card"),
    NOBLES_ACTIONS: new CardMode(CardNames.NOBLES, "2-action"),
    COURTIER_ACTION: new CardMode(CardNames.COURTIER, "action", null, false),
    COURTIER_BUY: new CardMode(CardNames.COURTIER, "buy", null, false),
    COURTIER_COINS: new CardMode(CardNames.COURTIER, "3-coin", null, false),
    COURTIER_GOLD: new CardMode(CardNames.COURTIER, "gold", null, false),
    NATIVE_VILLAGE_SET_ASIDE: new CardMode(CardNames.NATIVE_VILLAGE, "put-on-mat"),
    NATIVE_VILLAGE_RETURN: new CardMode(CardNames.NATIVE_VILLAGE, "take-from-mat"),
    PIRATE_SHIP_RAID: new CardMode(CardNames.PIRATE_SHIP, "attack"),
    PIRATE_SHIP_PROFIT: new CardMode(CardNames.PIRATE_SHIP, "coin-generic", function (game) {
        return game.playerModel.hero.getCounterValue(CounterNames.PIRATE_STRIKES);
    }),
    SCRYING_POOL_TOPDECK: new CardMode(CardNames.SCRYING_POOL, "topdeck", null, false),
    SCRYING_POOL_DISCARD: new CardMode(CardNames.SCRYING_POOL, "discard-deck", null, false),
    LOAN_DISCARD: new CardMode(CardNames.LOAN, "discard-deck", null, false),
    LOAN_TRASH: new CardMode(CardNames.LOAN, "trash", null, false),
    TRUSTY_STEED_CARDS: new CardMode(CardNames.TRUSTY_STEED, "2-card", null, false),
    TRUSTY_STEED_ACTIONS: new CardMode(CardNames.TRUSTY_STEED, "2-action", null, false),
    TRUSTY_STEED_COINS: new CardMode(CardNames.TRUSTY_STEED, "2-coin", null, false),
    TRUSTY_STEED_SILVERS: new CardMode(CardNames.TRUSTY_STEED, "take-silver", null, false),
    TRUSTY_STEED_CARDS_ACTIONS: new CardMode(CardNames.TRUSTY_STEED, "steed-card-action", null, false),
    JESTER_THEY_GAIN: new CardMode(CardNames.JESTER, "give-card", null, false),
    JESTER_YOU_GAIN: new CardMode(CardNames.JESTER, "gain-card", null, false),
    DUCHESS_KEEP: new CardMode(CardNames.DUCHESS, "topdeck", null, false),
    DUCHESS_DISCARD: new CardMode(CardNames.DUCHESS, "discard-deck", null, false),
    ORACLE_TOPDECK: new CardMode(CardNames.ORACLE, "topdeck", null, false),
    ORACLE_DISCARD: new CardMode(CardNames.ORACLE, "discard-deck", null, false),
    JACK_KEEP: new CardMode(CardNames.JACK_OF_ALL_TRADES, "topdeck", null, false),
    JACK_DISCARD: new CardMode(CardNames.JACK_OF_ALL_TRADES, "discard-deck", null, false),
    SPICE_MERCHANT_CARDS: new CardMode(CardNames.SPICE_MERCHANT, "cards-action"),
    SPICE_MERCHANT_COINS: new CardMode(CardNames.SPICE_MERCHANT, "spice-coin-buy"),
    CATACOMBS_TAKE: new CardMode(CardNames.CATACOMBS, "put-in-hand", null, false),
    CATACOMBS_DISCARD: new CardMode(CardNames.CATACOMBS, "discard-deck", null, false),
    COUNT_DISCARD: new CardMode(CardNames.COUNT, "count-discard"),
    COUNT_TOPDECK: new CardMode(CardNames.COUNT, "topdeck-hand"),
    COUNT_GAIN_COPPER: new CardMode(CardNames.COUNT, "take-copper"),
    COUNT_COINS: new CardMode(CardNames.COUNT, "3-coin", null, false),
    COUNT_TRASH_HAND: new CardMode(CardNames.COUNT, "trash-multiple", null, false),
    COUNT_GAIN_DUCHY: new CardMode(CardNames.COUNT, "gain-duchy", null, false),
    HUNTING_GROUNDS_ESTATES: new CardMode(CardNames.HUNTING_GROUNDS, "take-estates"),
    HUNTING_GROUNDS_DUCHY: new CardMode(CardNames.HUNTING_GROUNDS, "gain-duchy"),
    IRONMONGER_KEEP: new CardMode(CardNames.IRONMONGER, "topdeck", null, false),
    IRONMONGER_DISCARD: new CardMode(CardNames.IRONMONGER, "discard-deck", null, false),
    SCAVENGER_DISCARD: new CardMode(CardNames.SCAVENGER, "discard-deck"),
    SCAVENGER_KEEP: new CardMode(CardNames.SCAVENGER, "refuse"),
    SQUIRE_ACTIONS: new CardMode(CardNames.SQUIRE, "2-action"),
    SQUIRE_BUYS: new CardMode(CardNames.SQUIRE, "buy"),
    SQUIRE_SILVER: new CardMode(CardNames.SQUIRE, "take-silver"),
    MERCENARY_DONT_TRASH: new CardMode(CardNames.MERCENARY, "refuse", null, false),
    DOCTOR_TRASH: new CardMode(CardNames.DOCTOR, "trash", null, false),
    DOCTOR_DISCARD: new CardMode(CardNames.DOCTOR, "discard-deck", null, false),
    DOCTOR_TOPDECK: new CardMode(CardNames.DOCTOR, "topdeck", null, false),
    AMULET_COIN: new CardMode(CardNames.AMULET, "1-coin"),
    AMULET_TRASH: new CardMode(CardNames.AMULET, "trash"),
    AMULET_SILVER: new CardMode(CardNames.AMULET, "take-silver"),
    MISER_COPPER_TO_MAT: new CardMode(CardNames.MISER, "put-on-mat"),
    MISER_PROFIT: new CardMode(CardNames.MISER, "coin-generic", function (game) {
        return sum(game.playerModel.hero.getZone(TavernZone).cardStacks.filter(function (s) {
            return s.topCard.cardName === CardNames.COPPER;
        }).map(function (s) {
            return s.cardCount;
        }));
    }),
    MESSENGER_DISCARD: new CardMode(CardNames.MESSENGER, "discard-deck"),
    MESSENGER_KEEP: new CardMode(CardNames.MESSENGER, "refuse"),
    QUEST_ATTACK: new CardMode(CardNames.QUEST, "attack"),
    QUEST_CURSES: new CardMode(CardNames.QUEST, "quest-2-curses"),
    QUEST_CARDS: new CardMode(CardNames.QUEST, "quest-6-cards"),
    TEACHER_CARD: new CardMode(CardNames.TEACHER, "1-card", null, false),
    TEACHER_ACTION: new CardMode(CardNames.TEACHER, "action", null, false),
    TEACHER_BUY: new CardMode(CardNames.TEACHER, "buy", null, false),
    TEACHER_COIN: new CardMode(CardNames.TEACHER, "1-coin", null, false),
    LIBRARY_DRAW_IT: new CardMode(CardNames.LIBRARY, "put-in-hand", null, false),
    LIBRARY_SKIP_IT: new CardMode(CardNames.LIBRARY, "refuse", null, false),
    GLADIATOR_PRETEND: new CardMode(CardNames.GLADIATOR, "refuse", null, false),
    SPRAWLING_CASTLE_ESTATES: new CardMode(CardNames.SPRAWLING_CASTLE, "take-estates", null, false),
    SPRAWLING_CASTLE_DUCHY: new CardMode(CardNames.SPRAWLING_CASTLE, "gain-duchy", null, false),
    CHARM_BUY_MONEY: new CardMode(CardNames.CHARM, "spice-coin-buy"),
    CHARM_COPY: new CardMode(CardNames.CHARM, "gain-card"),
    WILD_HUNT_CARDS: new CardMode(CardNames.WILD_HUNT, "3-card"),
    WILD_HUNT_POINTS: new CardMode(CardNames.WILD_HUNT, "take-vp"),
    HAMLET_ACTION: new CardMode(CardNames.HAMLET, "action"),
    HAMLET_BUY: new CardMode(CardNames.HAMLET, "buy"),
    HAMLET_BOTH: new CardMode(CardNames.HAMLET, "action-buy"),
    GOVERNOR_CARDS: new CardMode(CardNames.GOVERNOR, "3-card"),
    GOVERNOR_TREASURE: new CardMode(CardNames.GOVERNOR, "gold"),
    GOVERNOR_REMODEL: new CardMode(CardNames.GOVERNOR, "remodel"),
    BLESSED_VILLAGE_NOW: new CardMode(CardNames.BLESSED_VILLAGE, "take-generic", null, false),
    BLESSED_VILLAGE_LATER: new CardMode(CardNames.BLESSED_VILLAGE, "boon-delay", null, false),
    PIXIE_TRASH: new CardMode(CardNames.PIXIE, "take-generic", null, false),
    PIXIE_KEEP: new CardMode(CardNames.PIXIE, "refuse", null, false),
    SACRED_GROVE_RECEIVE: new CardMode(CardNames.SACRED_GROVE, "take-generic", null, false),
    SACRED_GROVE_NO: new CardMode(CardNames.SACRED_GROVE, "refuse", null, false),
    ZOMBIE_SPY_DISCARD: new CardMode(CardNames.ZOMBIE_SPY, "discard-deck"),
    ZOMBIE_SPY_TOPDECK: new CardMode(CardNames.ZOMBIE_SPY, "topdeck"),
    START_GAME: new CardMode(CardNames.BACK, null, null, false),
    PEARL_DIVER_TOPDECK: new CardMode(CardNames.PEARL_DIVER, "topdeck"),
    PEARL_DIVER_BOTTOMDECK: new CardMode(CardNames.PEARL_DIVER, "bottomdeck"),
    SCEPTER_FAIL_ACTION: new CardMode(CardNames.SCEPTER, "refuse"),
    SCEPTER_ADD_COINS: new CardMode(CardNames.SCEPTER, "2-coin"),
    TREASURER_KEY: new CardMode(CardNames.TREASURER, "take-key"),
    TREASURER_FAIL: new CardMode(CardNames.TREASURER, "refuse", null, false),
    BORDER_GUARD_LANTERN: new CardMode(CardNames.BORDER_GUARD, null, null, false),
    BORDER_GUARD_HORN: new CardMode(CardNames.BORDER_GUARD, null, null, false),
    BORDER_GUARD_FAIL: new CardMode(CardNames.BORDER_GUARD, "refuse", null, false),
    PAGEANT_YES: new CardMode(CardNames.PAGEANT, "1-coin", null, false),
    PAGEANT_NO: new CardMode(CardNames.PAGEANT, "refuse", null, false),
    SINISTER_PLOT_ADD: new CardMode(CardNames.SINISTER_PLOT, "put-on-mat", null, false),
    SINISTER_PLOT_DRAW: new CardMode(CardNames.SINISTER_PLOT, "card-generic", function (game) {
        return game.playerModel.hero.getCounterValue(CounterNames.SINISTER_PLOT);
    }, false),
    PLAY: new CardMode(CardNames.BACK, "continue", null, false),
    DONT_PLAY: new CardMode(CardNames.BACK, "refuse", null, false),
    VILLAGE_GREEN_NOW: new CardMode(CardNames.VILLAGE_GREEN, "take-generic", null, false),
    VILLAGE_GREEN_LATER: new CardMode(CardNames.VILLAGE_GREEN, "boon-delay", null, false),
    BARGE_NOW: new CardMode(CardNames.BARGE, "3-card"),
    BARGE_LATER: new CardMode(CardNames.BARGE, "boon-delay"),
    SCRAP_CARD: new CardMode(CardNames.SCRAP, "1-card", null, false),
    SCRAP_ACTION: new CardMode(CardNames.SCRAP, "action", null, false),
    SCRAP_BUY: new CardMode(CardNames.SCRAP, "buy", null, false),
    SCRAP_COIN: new CardMode(CardNames.SCRAP, "1-coin", null, false),
    SCRAP_SILVER: new CardMode(CardNames.SCRAP, "take-silver", null, false),
    SCRAP_HORSE: new CardMode(CardNames.SCRAP, "take-horse", null, false),
    MODE_OF_THE_BUTTERFLY: new CardMode(CardNames.BACK, "way", null, false),
    MODE_OF_THE_CAMEL: new CardMode(CardNames.BACK, "way", null, false),
    MODE_OF_THE_CHAMELEON: new CardMode(CardNames.BACK, "way", null, false),
    MODE_OF_THE_FROG: new CardMode(CardNames.BACK, "way", null, false),
    MODE_OF_THE_GOAT: new CardMode(CardNames.BACK, "way", null, false),
    MODE_OF_THE_HORSE: new CardMode(CardNames.BACK, "way", null, false),
    MODE_OF_THE_MOLE: new CardMode(CardNames.BACK, "way", null, false),
    MODE_OF_THE_MONKEY: new CardMode(CardNames.BACK, "way", null, false),
    MODE_OF_THE_MOUSE: new CardMode(CardNames.BACK, "way", null, false),
    MODE_OF_THE_MULE: new CardMode(CardNames.BACK, "way", null, false),
    MODE_OF_THE_OTTER: new CardMode(CardNames.BACK, "way", null, false),
    MODE_OF_THE_OWL: new CardMode(CardNames.BACK, "way", null, false),
    MODE_OF_THE_OX: new CardMode(CardNames.BACK, "way", null, false),
    MODE_OF_THE_PIG: new CardMode(CardNames.BACK, "way", null, false),
    MODE_OF_THE_RAT: new CardMode(CardNames.BACK, "way", null, false),
    MODE_OF_THE_SEAL: new CardMode(CardNames.BACK, "way", null, false),
    MODE_OF_THE_SHEEP: new CardMode(CardNames.BACK, "way", null, false),
    MODE_OF_THE_SQUIRREL: new CardMode(CardNames.BACK, "way", null, false),
    MODE_OF_THE_TURTLE: new CardMode(CardNames.BACK, "way", null, false),
    MODE_OF_THE_WORM: new CardMode(CardNames.BACK, "way", null, false),
    BUTTERFLY_RETURN_IT: new CardMode(CardNames.WAY_OF_THE_BUTTERFLY, "exchange", null, false),
    BUTTERFLY_DO_NOTHING: new CardMode(CardNames.WAY_OF_THE_BUTTERFLY, "refuse", null, false),
    WAYFARER_SILVER: new CardMode(CardNames.WAYFARER, "take-silver"),
    DESPERATION_CURSE: new CardMode(CardNames.DESPERATION, "take-curse", null, false),
    CRYSTAL_BALL_TRASH: new CardMode(CardNames.CRYSTAL_BALL, "trash", null, false),
    CRYSTAL_BALL_DISCARD: new CardMode(CardNames.CRYSTAL_BALL, "discard-deck", null, false),
    CRYSTAL_BALL_PLAY: new CardMode(CardNames.CRYSTAL_BALL, "play", null, false),
    CRYSTAL_BALL_TOPDECK: new CardMode(CardNames.CRYSTAL_BALL, "topdeck", null, false),
    TOWN_VILLAGE: new CardMode(CardNames.TOWN, "card-action"),
    TOWN_COINS_BUY: new CardMode(CardNames.TOWN, "spice-coin-buy"),
    MODIFY_CANTRIP: new CardMode(CardNames.MODIFY, "card-action"),
    MODIFY_GAIN: new CardMode(CardNames.MODIFY, "exchange"),
    DESERT_GUIDES_KEEP: new CardMode(CardNames.DESERT_GUIDES, "refuse", null, false),
    DESERT_GUIDES_NEW_HAND: new CardMode(CardNames.DESERT_GUIDES, "minion-draw", null, false),
    BAUBLE_BUY: new CardMode(CardNames.BAUBLE, "buy", null, false),
    BAUBLE_COIN: new CardMode(CardNames.BAUBLE, "1-coin", null, false),
    BAUBLE_FAVOR: new CardMode(CardNames.BAUBLE, "take-generic", null, false),
    BAUBLE_TOPDECK: new CardMode(CardNames.BAUBLE, "topdeck", null, false),
    BROKER_CARDS: new CardMode(CardNames.BROKER, "3-card", null, false),
    BROKER_ACTIONS: new CardMode(CardNames.BROKER, "2-action", null, false),
    BROKER_COINS: new CardMode(CardNames.BROKER, "coin-generic", null, false),
    BROKER_FAVORS: new CardMode(CardNames.BROKER, "take-generic", null, false),
    INNKEEPER_CARD: new CardMode(CardNames.INNKEEPER, "card-action"),
    INNKEEPER_3: new CardMode(CardNames.INNKEEPER, "discard"),
    INNKEEPER_5_6: new CardMode(CardNames.INNKEEPER, "count-discard"),
    CAPITAL_CITY_DISCARD: new CardMode(CardNames.CAPITAL_CITY, "count-discard"),
    CAPITAL_CITY_PAY: new CardMode(CardNames.CAPITAL_CITY, "2-card"),
    SPECIALIST_PLAY_AGAIN: new CardMode(CardNames.SPECIALIST, "throne"),
    SPECIALIST_GAIN_COPY: new CardMode(CardNames.SPECIALIST, "gain-card"),
    HILL_FORT_TO_HAND: new CardMode(CardNames.HILL_FORT, "put-in-hand"),
    HILL_FORT_CANTRIP: new CardMode(CardNames.HILL_FORT, "card-action"),
    STRONGHOLD_COINS: new CardMode(CardNames.STRONGHOLD, "3-coin"),
    STRONGHOLD_CARDS: new CardMode(CardNames.STRONGHOLD, "3-card"),
    TOWN_CRIER_COINS: new CardMode(CardNames.TOWN_CRIER, "2-coin"),
    TOWN_CRIER_SILVER: new CardMode(CardNames.TOWN_CRIER, "take-silver"),
    TOWN_CRIER_CANTRIP: new CardMode(CardNames.TOWN_CRIER, "card-action"),
    BLACKSMITH_OWL: new CardMode(CardNames.BLACKSMITH, "put-in-hand"),
    BLACKSMITH_OTTER: new CardMode(CardNames.BLACKSMITH, "2-card"),
    BLACKSMITH_PIG: new CardMode(CardNames.BLACKSMITH, "card-action"),
    LURKER_TRASH: new CardMode(CardNames.LURKER, "trash", null, false),
    LURKER_GAIN: new CardMode(CardNames.LURKER, "gain-card", null, false),
    GRAVEROBBER_GAIN: new CardMode(CardNames.GRAVEROBBER, "gain-card"),
    GRAVEROBBER_TRASH: new CardMode(CardNames.GRAVEROBBER, "trash"),
    TREASURER_TRASH: new CardMode(CardNames.TREASURER, "trash", null, false),
    TREASURER_GAIN: new CardMode(CardNames.TREASURER, "gain-card", null, false),
    INVESTMENT_COIN: new CardMode(CardNames.INVESTMENT, "1-coin"),
    INVESTMENT_VP: new CardMode(CardNames.INVESTMENT, "take-vp"),
    WEAVER_SILVERS: new CardMode(CardNames.WEAVER, "take-silver"),
    UNUSED_CARDMODE_7: new CardMode(CardNames.BACK, null, null, false),
    UNUSED_CARDMODE_8: new CardMode(CardNames.BACK, null, null, false),
    UNUSED_CARDMODE_9: new CardMode(CardNames.BACK, null, null, false),
    UNUSED_CARDMODE_10: new CardMode(CardNames.BACK, null, null, false),
    UNUSED_CARDMODE_11: new CardMode(CardNames.BACK, null, null, false),
    CABIN_BOY_COINS: new CardMode(CardNames.CABIN_BOY, "2-coin", null, false),
    CABIN_BOY_GAIN: new CardMode(CardNames.CABIN_BOY, "exchange", null, false),
    GONDOLA_NOW: new CardMode(CardNames.GONDOLA, "2-coin"),
    GONDOLA_LATER: new CardMode(CardNames.GONDOLA, "boon-delay"),
    QUARTERMASTER_GAIN: new CardMode(CardNames.QUARTERMASTER, "gain-card", null, false),
    QUARTERMASTER_TAKE: new CardMode(CardNames.QUARTERMASTER, "put-in-hand", null, false),
    AMPHORA_NOW: new CardMode(CardNames.AMPHORA, "3-coin"),
    AMPHORA_LATER: new CardMode(CardNames.AMPHORA, "boon-delay"),
    SPELL_SCROLL_PLAY: new CardMode(CardNames.SPELL_SCROLL, "play"),
    SPELL_SCROLL_REFUSE: new CardMode(CardNames.SPELL_SCROLL, "refuse"),
    COURSER_CARDS: new CardMode(CardNames.COURSER, "2-card", null, false),
    COURSER_ACTIONS: new CardMode(CardNames.COURSER, "2-action", null, false),
    COURSER_COINS: new CardMode(CardNames.COURSER, "2-coin", null, false),
    COURSER_SILVERS: new CardMode(CardNames.COURSER, "take-silver", null, false),
    COURSER_CARDS_ACTIONS: new CardMode(CardNames.COURSER, "steed-card-action", null, false),
    SICKNESS_FAIL_DISCARD: new CardMode(CardNames.SICKNESS, "refuse", null, false),
    SICKNESS_CURSE: new CardMode(CardNames.SICKNESS, "take-curse", null, false),
    SICKNESS_FAIL_CURSE: new CardMode(CardNames.SICKNESS, "refuse", null, false),
    SICKNESS_DISCARD: new CardMode(CardNames.SICKNESS, "discard", null, false),
    KITSUNE_COINS: new CardMode(CardNames.KITSUNE, "2-coin", null, false),
    KITSUNE_ACTIONS: new CardMode(CardNames.KITSUNE, "2-action", null, false),
    KITSUNE_CURSE: new CardMode(CardNames.KITSUNE, "attack", null, false),
    KITSUNE_SILVER: new CardMode(CardNames.KITSUNE, "take-silver", null, false)

};

function MovementType() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var MovementTypes = {
    PLAY: new MovementType(),
    DRAW: new MovementType(),
    DISCARD: new MovementType(),
    GAIN: new MovementType(),
    TRASH: new MovementType(),
    TOPDECK: new MovementType(),
    REVEAL: new MovementType(),
    REVEAL_SEARCHING: new MovementType(),
    LOOK_AT: new MovementType(),
    PUT_IN_HAND: new MovementType(),
    SET_ASIDE: new MovementType(),
    PUT_ON_MAT: new MovementType(),
    DECK_TO_DISCARD: new MovementType(),
    BACK_ON_DECK: new MovementType(),
    SHUFFLE_INTO_DECK: new MovementType(),
    INSERT_IN_DECK: new MovementType(),
    EXCHANGE_RETURN: new MovementType(),
    EXCHANGE_RECEIVE: new MovementType(),
    PASS: new MovementType(),
    RETURN_TO: new MovementType(),
    PUT_ON_BOTTOM_OF: new MovementType(),
    STARTS_WITH: new MovementType(),
    TAKE: new MovementType(),
    GET_STATE: new MovementType(),
    RETURN_STATE: new MovementType(),
    EXILE: new MovementType()
};

function AbilityDescriptionType(name) {
    var self = this;
    self.name = name;
    self.toString = function () {
        return self.name;
    };
}

var AbilityDescriptionTypes = {
    MOVEMENT_CAUSE: new AbilityDescriptionType("Movement Cause"),
    BY_NAME: new AbilityDescriptionType("By Name"),
    WITH_ARGUMENTS: new AbilityDescriptionType("With Arguments")
};

function EventType() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var EventTypes = {
    WHEN_WOULD: new EventType(),
    FIRST: new EventType(),
    WHEN: new EventType()
};

function GameButton(imageName) {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
    self.imageName = imageName;
}

var GameButtons = {
    AUTOPLAY_TREASURES: new GameButton('autoplay-treasures'),
    USE_VILLAGER: new GameButton('villagers'),
    USE_FAVOR_TOPDECK: new GameButton('topdeck'),
    USE_FAVOR_DISCARD: new GameButton('discard-deck'),
    USE_COFFERS: new GameButton('coffers'),
    PAY_OFF_DEBT: new GameButton('debt')
};

function LogEntryType() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var LogEntryTypes = {
    NAMED_LOG_ENTRY: new LogEntryType(),
    DECISION_ENTRY: new LogEntryType()
};

function PlayerColor(color) {
    var colorWord = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;

    var self = this;
    self.uuid = generateUUID();
    self.color = color;
    self.toString = function () {
        return self.color;
    };
    self.cubeImg = 'images/cubes/cube_' + (colorWord || color) + '.png';
}

var PlayerColors = {
    RED: new PlayerColor("rgb(255, 0, 0)", "red"),
    BLUE: new PlayerColor("rgb(80, 160, 255)", "blue"),
    GREEN: new PlayerColor("rgb(0, 128, 0)", "green"),
    YELLOW: new PlayerColor("rgb(255, 255, 0)", "yellow"),
    ORANGE: new PlayerColor("rgb(255, 165, 0)", "orange"),
    PURPLE: new PlayerColor("rgb(128, 0, 128)", "purple")
};

function PileMarkerType() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var PileMarkerTypes = {
    BANE: new PileMarkerType(),
    OBELISK: new PileMarkerType(),
    SPLIT: new PileMarkerType(),
    MIXED: new PileMarkerType(),
    FERRYMAN: new PileMarkerType()
};

var AutoPlay = function () {
    function AutoPlay(cardName, nOptions, defaultValue, convenientValue) {
        var template = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : undefined;

        var _this = this;

        var value = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 0;
        var alternativeNames = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : [];

        _classCallCheck(this, AutoPlay);

        this.cardName = cardName;
        this.nOptions = nOptions;
        this.template = template;
        this.value = value;
        this.alternativeNames = alternativeNames;
        this.defaultValue = defaultValue;
        this.convenientValue = convenientValue;
        this.toString = function () {
            return cardName.name;
        };
        this.settingName = function () {
            return LANGUAGE.getCardName[_this.cardName].singular;
        };
        this.nameValueString = function () {
            return _this.settingName() + ": " + _this.value;
        };
        this.uuid = generateUUID();
    }

    _createClass(AutoPlay, [{
        key: "optionValues",
        value: function optionValues() {

            if (isUndefined(this.values)) {

                this.values = [];
                for (var i = 0; i < this.nOptions; i++) {
                    this.values[i] = i;
                }
            }

            return this.values;
        }
    }, {
        key: "optionDescription",
        value: function optionDescription(value) {

            return LANGUAGE.getAutoPlays[this.template][value];
        }
    }, {
        key: "optionDescriptions",
        value: function optionDescriptions() {
            var _this2 = this;

            return this.optionValues().map(function (value) {
                return _this2.optionDescription(value);
            });
        }
    }, {
        key: "toggle",
        value: function toggle() {
            this.value = (this.value + 1) % this.nOptions;
        }
    }, {
        key: "setValue",
        value: function setValue(value) {
            this.value = value;
        }
    }, {
        key: "setOffValue",
        value: function setOffValue() {
            this.value = 0;
        }
    }, {
        key: "setDefaultValue",
        value: function setDefaultValue() {
            this.value = this.defaultValue;
        }
    }, {
        key: "setConvenientValue",
        value: function setConvenientValue() {
            this.value = this.convenientValue;
        }
    }, {
        key: "serialize",
        value: function serialize(writer) {
            this.cardName.serialize(writer);
            writer.writeInt(this.value);
        }
    }, {
        key: "getTranslatedLabel",
        value: function getTranslatedLabel() {
            return LANGUAGE.getAutoPlays[this.template][this.value];
        }
    }], [{
        key: "parse",
        value: function parse(reader) {
            var cardName = CardName.parse(reader);
            var value = reader.readInt();
            var template = this.find(cardName);
            if (isUndefined(template)) {
                throw new Error("Server sent us autoplay settings for this unknown card: " + cardName);
            }
            return new AutoPlay(cardName, template.nOptions, template.defaultValue, template.convenientValue, template, value, template.alternativeNames);
        }
    }, {
        key: "cardNames",
        value: function cardNames() {
            return ObjectValues(AutoPlays).map(function (autoPlay) {
                return autoPlay.cardName;
            });
        }
    }, {
        key: "find",
        value: function find(cardName) {
            return AutoPlay.findInList(cardName, ObjectValues(AutoPlays));
        }
    }, {
        key: "findInList",
        value: function findInList(cardName, searchMe) {
            return searchMe.find(function (a) {
                return a.cardName === cardName || a.alternativeNames.some(function (n) {
                    return n === cardName;
                });
            });
        }
    }, {
        key: "findInMap",
        value: function findInMap(cardName, searchMe) {
            var primaryName = Object.keys(searchMe.map).find(function (name) {
                return name === cardName || searchMe.map[name].alternativeNames.some(function (n) {
                    return n.name === cardName;
                });
            });
            return searchMe.map[primaryName];
        }
    }, {
        key: "autoplayable",
        value: function autoplayable(cardName) {

            return AutoPlay.find(cardName) instanceof AutoPlay;
        }
    }]);

    return AutoPlay;
}();

var AutoPlays = {
    MOAT: new AutoPlay(CardNames.MOAT, 2, 0, 1, undefined, 0, [CardNames.SHIELD]),
    TRADER: new AutoPlay(CardNames.TRADER, 2, 1, 1),
    CROWN: new AutoPlay(CardNames.CROWN, 2, 1, 1),
    CHANGELING: new AutoPlay(CardNames.CHANGELING, 3, 1, 1),
    URCHIN: new AutoPlay(CardNames.URCHIN, 2, 0, 0),
    MOUNTEBANK: new AutoPlay(CardNames.MOUNTEBANK, 2, 0, 1),
    SETTLERS: new AutoPlay(CardNames.SETTLERS, 2, 0, 1),
    BUSTLING_VILLAGE: new AutoPlay(CardNames.BUSTLING_VILLAGE, 2, 0, 1),
    TOURNAMENT: new AutoPlay(CardNames.TOURNAMENT, 2, 0, 1),
    PEASANT: new AutoPlay(CardNames.PEASANT, 3, 0, 1),
    SOLDIER: new AutoPlay(CardNames.SOLDIER, 3, 0, 1),
    FUGITIVE: new AutoPlay(CardNames.FUGITIVE, 3, 0, 1),
    DISCIPLE: new AutoPlay(CardNames.DISCIPLE, 3, 0, 0),
    PAGE: new AutoPlay(CardNames.PAGE, 3, 0, 1),
    TREASURE_HUNTER: new AutoPlay(CardNames.TREASURE_HUNTER, 3, 0, 1),
    WARRIOR: new AutoPlay(CardNames.WARRIOR, 3, 0, 0),
    HERO: new AutoPlay(CardNames.HERO, 3, 0, 1),
    FAITHFUL_HOUND: new AutoPlay(CardNames.FAITHFUL_HOUND, 2, 0, 1),
    YOUNG_WITCH: new AutoPlay(CardNames.YOUNG_WITCH, 2, 0, 1),
    TREASURY: new AutoPlay(CardNames.TREASURY, 2, 0, 1),
    ALCHEMIST: new AutoPlay(CardNames.ALCHEMIST, 2, 0, 1),
    WALLED_VILLAGE: new AutoPlay(CardNames.WALLED_VILLAGE, 2, 0, 1),
    HERBALIST: new AutoPlay(CardNames.HERBALIST, 2, 0, 1),
    ROYAL_SEAL: new AutoPlay(CardNames.ROYAL_SEAL, 3, 0, 0, undefined, 0, [CardNames.TRAVELLING_FAIR, CardNames.TRACKER, CardNames.WAY_OF_THE_SEAL, CardNames.BAUBLE, CardNames.TRAPPERS_LODGE, CardNames.TIARA, CardNames.INSIGNIA]),
    COIN_OF_THE_REALM: new AutoPlay(CardNames.COIN_OF_THE_REALM, 2, 1, 1),
    BORDER_GUARD: new AutoPlay(CardNames.BORDER_GUARD, 2, 0, 1),
    PROVINCE: new AutoPlay(CardNames.PROVINCE, 2, 0, 1, undefined, 0, [CardNames.DUCHY, CardNames.ESTATE, CardNames.COLONY, CardNames.SILK_ROAD, CardNames.VINEYARD, CardNames.GARDENS, CardNames.CEMETERY, CardNames.DUKE, CardNames.FAIRGROUNDS, CardNames.CURSE, CardNames.COPPER, CardNames.RUINED_LIBRARY, CardNames.RUINED_MARKET, CardNames.RUINED_VILLAGE, CardNames.ABANDONED_MINE, CardNames.SURVIVORS]),
    CHAMPION: new AutoPlay(CardNames.CHAMPION, 2, 1, 1, undefined, 0, [CardNames.TEACHER, CardNames.LOST_ARTS, CardNames.TRAINING, CardNames.SEAWAY]),
    WAY_OF_THE_BUTTERFLY: new AutoPlay(CardNames.WAY_OF_THE_BUTTERFLY, 2, 0, 1),
    DESPERATION: new AutoPlay(CardNames.DESPERATION, 2, 1, 1),
    TORTURER: new AutoPlay(CardNames.TORTURER, 2, 0, 1),
    ARENA: new AutoPlay(CardNames.ARENA, 2, 1, 1),
    PAGEANT: new AutoPlay(CardNames.PAGEANT, 2, 1, 1),
    OLD_WITCH: new AutoPlay(CardNames.OLD_WITCH, 2, 0, 1),
    SINISTER_PLOT: new AutoPlay(CardNames.SINISTER_PLOT, 2, 1, 1),
    MONEYLENDER: new AutoPlay(CardNames.MONEYLENDER, 2, 1, 1),
    ENCAMPMENT: new AutoPlay(CardNames.ENCAMPMENT, 2, 0, 1),
    DUPLICATE: new AutoPlay(CardNames.DUPLICATE, 3, 1, 2)
};

var KingdomViewerGroups = {
    SUPPLY_CARDS: new String("1"),
    KINGDOM_LANDSCAPES: new String("2"),
    NON_KINGDOM: new String("3"),
    RUINS: new String("4"),
    DRUID_BOONS: new String("5"),
    KNIGHTS: new String("6"),
    CASTLES: new String("7"),
    PRIZES: new String("8"),
    REWARDS: new String("8b"),
    BOONS_HEXES: new String("9"),
    BLACK_MARKET: new String("10"),
    STATES_ARTIFACTS: new String("11"),
    LOOT: new String("12")
};

function CardSize(baseHeight, baseWidth, landscapeHeight, landscapeWidth, cssClass) {
    this.uuid = generateUUID();
    this.baseHeight = baseHeight;
    this.baseWidth = baseWidth;
    this.landscapeHeight = landscapeHeight;
    this.landscapeWidth = landscapeWidth;
    this.ratio = baseHeight / baseWidth;
    this.landscapeRatio = landscapeHeight / landscapeWidth;
    this.cssClass = cssClass;
}

var CardSizes = {
    FULL: new CardSize(994, 630, 630, 994, "card-full"),
    MINI: new CardSize(594, 630, 630, 994, "card-mini"),
    MICRO: new CardSize(112, 616, 112, 616, "card-micro")
};