"use strict";

webclient.service("checkoutData", ['$rootScope', 'serverMessenger', 'metaBroadcaster', 'preferences', 'debounce', function ($rootScope, serverMessenger, metaBroadcaster, preferences, debounce) {

    var scope = $rootScope.$new(true);
    var checkoutData = this;

    checkoutData.checkoutInProgress = false;
    checkoutData.checkoutReady = false;
    checkoutData.owned = preferences[UserPrefIds.CARD_OWNERSHIP].arguments;
    checkoutData.country = null;
    checkoutData.ipCountry = 'StartValue';
    checkoutData.purchaseValid = false;
    checkoutData.stringify = cardNamesToString;
    checkoutData.cancelCheckout = checkoutData.finishCheckout;
    checkoutData.countryInvalid = false;

    checkoutData.startCheckout = function () {
        console.log('checkout-data.js startCheckout');
        serverMessenger.purchase(checkoutData.purchase);
        checkoutData.checkoutInProgress = true;
    };

    checkoutData.finishCheckout = function () {
        console.log('checkout-data.js finishCheckout');
        checkoutData.checkoutInProgress = false;
        metaBroadcaster.send(Events.SHOW_SUBSCRIPTIONS);
    };

    scope.$on(Events.PURCHASE_PRICE, function (event, purchase) {
        console.log('checkout-data.js PURCHASE_PRICE');
        scope.$apply(function () {
            checkoutData.ipCountry = getCountryByCode(purchase.onlineProduct.country);
            checkoutData.purchase = purchase;
        });
        metaBroadcaster.send(Events.PURCHASE_PRICE_AVAILABLE);
    });

    scope.$on(Events.PURCHASE_REDIRECT, function (event, redirectUrl) {
        console.log('checkout-data.js PURCHASE_REDIRECT');
        scope.$apply(function () {
            checkoutData.checkoutReady = true;
            checkoutData.redirectUrl = redirectUrl;
        });
    });

    scope.$on(Events.COUNTRY_MISMATCH, function (event, country) {
        console.log('checkout-data.js COUNTRY_MISMATCH');
        timeLog("Country mismatch in checkoutData service.");
        checkoutData.countryInvalid = true;
        returnToFullStore();
    });

    scope.$on(Events.COMMAND_FAILED, function (event, failure) {
        console.log('checkout-data.js COMMAND_FAILED');
        if (failure.failureReason === FailureReasons.FAILED_SETTING_UP_PURCHASE) returnToFullStore();
        if (failure.failureReason === FailureReasons.PRICE_IS_ZERO) returnToFullStore();
    });

    function returnToFullStore() {
        console.log('checkout-data.js returnToFullStore');
        checkoutData.checkoutInProgress = false;
        $rootScope.$digest();
    }

    scope.$on(Events.PURCHASE_SUCCESS, function (event, reason) {
        console.log('checkout-data.js PURCHASE_SUCCESS');
        scope.$apply(checkoutData.finishCheckout);
    });

    checkoutData.display = {};

    checkoutData.display.StorePackage = function () {
        return LANGUAGE.getStoreLabels[checkoutData.purchase.onlineProduct.storePackage];
    };

    checkoutData.display.priceLabel = function (priceCategory) {
        switch (priceCategory) {
            case PriceCategories.PURCHASED:
                return LANGUAGE.getStoreLabels[priceCategory] + checkoutData.display.StorePackage();
            default:
                return LANGUAGE.getStoreLabels[priceCategory];
        }
    };

    checkoutData.display.Cancel = function () {
        return LANGUAGE.getPhrase[Phrases.CANCEL];
    };

    checkoutData.display.Seller = function () {
        return LANGUAGE.getStoreLabels.SELLER_INFO;
    };

    checkoutData.display.Product = function (date, expansion) {
        var confirm = LANGUAGE.getStoreLabels.PRODUCT_CONFIRM;
        confirm = confirm.replace('DATE', date.toDateString());
        confirm = confirm.replace('EXPANSIONS', expansion);
        return confirm;
    };

    checkoutData.display.Date = function (date) {

        return date.toLocaleDateString(LANGUAGE.getSortOrderLocale(), { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' });
    };

    checkoutData.display.PaymentHandler = function () {
        return LANGUAGE.getStoreLabels.PAYMENT_HANDLER;
    };

    checkoutData.display.Proceed = function () {
        return LANGUAGE.getStoreLabels.PROCEED_TO_PAYMENT;
    };

    scope.$on(Events.PURCHASE_PRICE, function (event, purchase) {
        scope.$apply(function () {
            console.log('checkout-data.js PURCHASE_PRICE 2');
            console.log('purchase price received %o', purchase);
            checkoutData.purchaseDisabled = false;
        });
    });

    scope.$on(Events.PURCHASE_REDIRECT, function (event, redirectUrl) {
        console.log('checkout-data.js PURCHASE_REDIRECT 2');
        scope.$apply(function () {
            checkoutData.redirectUrl = redirectUrl;
        });
    });

    checkoutData.setupPurchase = function () {
        serverMessenger.purchase(checkoutData.purchase);
    };
}]);