"use strict";

var EnglishUserPreferences = {};

EnglishUserPreferences[UserPrefIds.EMAIL] = "Email";
EnglishUserPreferences[UserPrefIds.CARD_OWNERSHIP] = "Owned cards";
EnglishUserPreferences[UserPrefIds.LIKED_CARDS] = "Cards you like";
EnglishUserPreferences[UserPrefIds.DISLIKED_CARDS] = "Cards you dislike";
EnglishUserPreferences[UserPrefIds.BANNED_CARDS] = "Banned cards";
EnglishUserPreferences[UserPrefIds.MOVE_KINGDOM] = "Move kingdom and play area";
EnglishUserPreferences[UserPrefIds.CONFIRM_ACTIONS] = "Confirm single trash, discard & exile";
EnglishUserPreferences[UserPrefIds.PREFERRED_BACKGROUND] = "Preferred background";
EnglishUserPreferences[UserPrefIds.USE_AUTOBUY] = "Use autobuy";
EnglishUserPreferences[UserPrefIds.FOLLOWERS_SEE_ACTIVITY] = "Followers see your activity";
EnglishUserPreferences[UserPrefIds.NOTIFICATION_SOUNDS] = "Play notification sounds";
EnglishUserPreferences[UserPrefIds.SOUND_FX] = "Play sound fx";
EnglishUserPreferences[UserPrefIds.SPECTATORS_SEE_HAND] = "Spectators can see my cards";
EnglishUserPreferences[UserPrefIds.AUTOPLAY_SETTINGS] = "Autoplay the following cards";
EnglishUserPreferences[UserPrefIds.LOG_FIXED_BASIC_BONUS] = "Log all +Actions, +Buys, +Coins";
EnglishUserPreferences[UserPrefIds.MAKE_THRONE_ROOM_ORANGE] = "Color Throne Room staying in play orange";
EnglishUserPreferences[UserPrefIds.MAKE_DURATIONS_NOT_ORANGE] = "Dynamically remove orange from Durations";
EnglishUserPreferences[UserPrefIds.TRANSPARENT_WAY_BUTTON] = "Transparent Way buttons";
EnglishUserPreferences[UserPrefIds.CHAT_VISIBLE] = "Chat is visible";
EnglishUserPreferences[UserPrefIds.SPECTATOR_CHAT_IN_AUTOMATCH] = "See spectator chat in automatch";
EnglishUserPreferences[UserPrefIds.CARD_POOL_LEVEL] = "Card Pool Level";
EnglishUserPreferences[UserPrefIds.ANIMATION_TYPE] = "Animation Type";
EnglishUserPreferences[UserPrefIds.PREMOVES] = "Premoves";

EnglishUserPreferences.USERNAME = "Username";
EnglishUserPreferences.PASSWORD = "Password";
EnglishUserPreferences.OLD_PASSWORD = "Old password";
EnglishUserPreferences.NEW_PASSWORD = "New password";
EnglishUserPreferences.REPEAT_PASSWORD = "Repeat new password";
EnglishUserPreferences.OWNERSHIP_INTRO = "Your current subscriptions:";
EnglishUserPreferences.OWNERSHIP_INTRO_EMPTY = "You currently have no subscriptions.";
EnglishUserPreferences.FAMILIAR_WITH = "I'm familiar with: ";
EnglishUserPreferences.USE_BONUS_CODE = "Use bonus code";
EnglishUserPreferences.BONUS_CODE_ORIGIN = "Your bonus code is from: ";
EnglishUserPreferences.BONUS_CODE_CHOOSE_EXPANSION = "Select an expansion: ";

var EnglishProfileButtons = {};

EnglishProfileButtons[true] = "Yes";
EnglishProfileButtons[false] = "No";
EnglishProfileButtons[UserPrefIds.FAMILIAR_CARDS] = {
    ALL: "This expansion",
    NONE: "None from this expansion",
    ALL_EXPANSIONS: "All expansions"
};

var EnglishStoreLabels = {};

EnglishStoreLabels[StorePackages.HALF] = "Silver subscription";
EnglishStoreLabels[StorePackages.ALL] = "Gold subscription";
EnglishStoreLabels[StorePackages.CORE] = "Core subscription";
EnglishStoreLabels[StorePackages.COMPLETE] = "Complete subscription";

EnglishStoreLabels[PriceCategories.PURCHASED] = ''; // "Purchase expansions: ";
EnglishStoreLabels[PriceCategories.ALREADY_OWNED] = "Already subscribed";
EnglishStoreLabels[PriceCategories.DISCOUNT] = "Discount";
EnglishStoreLabels[PriceCategories.TRANSACTION] = "Transaction fee";
EnglishStoreLabels[PriceCategories.SUBTOTAL] = "Subtotal";
EnglishStoreLabels[PriceCategories.VAT] = "VAT";
EnglishStoreLabels[PriceCategories.TOTAL] = "Total";

EnglishStoreLabels.SELLER_INFO = "Seller info";
EnglishStoreLabels.PRODUCT_CONFIRM = "Purchasing until DATE: EXPANSIONS";
EnglishStoreLabels.PAYMENT_HANDLER = "Payment will be handled by our payment provider Mollie.";
EnglishStoreLabels.PROCEED_TO_PAYMENT = "Proceed to payment";

var EnglishAutoplayLabels = {};

EnglishAutoplayLabels.REGULAR_OPTIONS = "Regular Options";
EnglishAutoplayLabels.AUTOPLAY_OPTIONS = "Autoplay Options";
EnglishAutoplayLabels.AUTOPLAY_DOCUMENTATION_GENERIC = "What is Autoplay?";
EnglishAutoplayLabels.AUTOPLAY_DOCUMENTATION_SPECIFIC = "-specific options";
EnglishAutoplayLabels.CUSTOMIZE_OPTIONS = "Customize options";
EnglishAutoplayLabels.ALL_OFF = "All off";
EnglishAutoplayLabels.ALL_DEFAULT = "All default";
EnglishAutoplayLabels.ALL_CONVENIENT = "All convenient";

var EnglishAnimationTypes = {};
EnglishAnimationTypes[AnimationOptions.NEW_PLAYER] = "Slow";
EnglishAnimationTypes[AnimationOptions.BEGINNER] = "Standard";
EnglishAnimationTypes[AnimationOptions.MEDIUM] = "Sprightly";
EnglishAnimationTypes[AnimationOptions.ADVANCED] = "Swift";
EnglishAnimationTypes[AnimationOptions.VERYFAST] = "Speedy";
EnglishAnimationTypes[AnimationOptions.INSTANT] = "None";