"use strict";

var EsperantoExpansionNames = {};

EsperantoExpansionNames[Expansions.BASE] = 'Bazo';
EsperantoExpansionNames[Expansions.INTRIGUE] = 'Intrigo';
EsperantoExpansionNames[Expansions.SEASIDE] = 'Marbordo';
EsperantoExpansionNames[Expansions.ALCHEMY] = 'Alĥemio';
EsperantoExpansionNames[Expansions.PROSPERITY] = 'Prospero';
EsperantoExpansionNames[Expansions.CORNUCOPIA_GUILDS] = 'Superabundo & Gildoj';
EsperantoExpansionNames[Expansions.HINTERLANDS] = 'Landinterno';
EsperantoExpansionNames[Expansions.DARK_AGES] = 'Mallumaj Epokoj';
EsperantoExpansionNames[Expansions.ADVENTURES] = 'Aventuroj';
EsperantoExpansionNames[Expansions.EMPIRES] = 'Imperioj';
EsperantoExpansionNames[Expansions.PROMOS] = 'Varboj';
EsperantoExpansionNames[Expansions.NOCTURNE] = 'Nokturno';
EsperantoExpansionNames[Expansions.RENAISSANCE] = 'Renesanco';
EsperantoExpansionNames[Expansions.MENAGERIE] = 'Bestaro';
EsperantoExpansionNames[Expansions.ALLIES] = 'Aliancanoj';
EsperantoExpansionNames[Expansions.PLUNDER] = 'Prirabado';
EsperantoExpansionNames[Expansions.DEPRECATED] = 'Eksmodaj';
EsperantoExpansionNames[Expansions.RISING_SUN] = 'Rising Sun';