"use strict";

var SpanishUserPreferences = {};

SpanishUserPreferences[UserPrefIds.EMAIL] = "Email";
SpanishUserPreferences[UserPrefIds.CARD_OWNERSHIP] = "Cartas propias";
SpanishUserPreferences[UserPrefIds.LIKED_CARDS] = "Cartas preferidas";
SpanishUserPreferences[UserPrefIds.DISLIKED_CARDS] = "Cartas rechazadas";
SpanishUserPreferences[UserPrefIds.BANNED_CARDS] = "Cartas vetadas";
SpanishUserPreferences[UserPrefIds.MOVE_KINGDOM] = "Mover el Reino y el área de juego";
SpanishUserPreferences[UserPrefIds.CONFIRM_ACTIONS] = "Confirmar decisiones individuales";
SpanishUserPreferences[UserPrefIds.PREFERRED_BACKGROUND] = "Fondo preferido";
SpanishUserPreferences[UserPrefIds.USE_AUTOBUY] = "Usar auto-compra";
SpanishUserPreferences[UserPrefIds.FOLLOWERS_SEE_ACTIVITY] = "Actividad visible a los amigos";
SpanishUserPreferences[UserPrefIds.NOTIFICATION_SOUNDS] = "Sonidos de notificaciones";
SpanishUserPreferences[UserPrefIds.SOUND_FX] = "Sonidos de efectos";
SpanishUserPreferences[UserPrefIds.SPECTATORS_SEE_HAND] = "Cartas visibles a los espectadores";
SpanishUserPreferences[UserPrefIds.AUTOPLAY_SETTINGS] = "Juega automáticamente las siguientes cartas";
SpanishUserPreferences[UserPrefIds.LOG_FIXED_BASIC_BONUS] = "Registro de +Compras, +Monedas";
SpanishUserPreferences[UserPrefIds.MAKE_THRONE_ROOM_ORANGE] = "Colorea Salón del Trono que sigue en juego en Naranja";
SpanishUserPreferences[UserPrefIds.MAKE_DURATIONS_NOT_ORANGE] = "Quita el color naranja de las Duraciones dinámicamente";
SpanishUserPreferences[UserPrefIds.TRANSPARENT_WAY_BUTTON] = "Botones de Senda transparentes";
SpanishUserPreferences[UserPrefIds.CHAT_VISIBLE] = "Chat visible";
SpanishUserPreferences[UserPrefIds.SPECTATOR_CHAT_IN_AUTOMATCH] = "Chat de espectadores visible";
SpanishUserPreferences[UserPrefIds.CARD_POOL_LEVEL] = "Nivel de cartas";
SpanishUserPreferences[UserPrefIds.ANIMATION_TYPE] = "Tipo de animaciones";
SpanishUserPreferences[UserPrefIds.PREMOVES] = "Premoves"; // TRANSLATE, means the ability for a player to click on a card to play/activate before the animation for the current action is done

SpanishUserPreferences.USERNAME = "Nombre de usuario";
SpanishUserPreferences.PASSWORD = "Contraseña";
SpanishUserPreferences.OLD_PASSWORD = "Contraseña antigua";
SpanishUserPreferences.NEW_PASSWORD = "Contraseña nueva";
SpanishUserPreferences.REPEAT_PASSWORD = "Repite contraseña nueva";
SpanishUserPreferences.OWNERSHIP_INTRO = "Tus suscripciones actuales:";
SpanishUserPreferences.OWNERSHIP_INTRO_EMPTY = "No tienes suscripciones.";
SpanishUserPreferences.FAMILIAR_WITH = "Cartas familiares: ";
SpanishUserPreferences.USE_BONUS_CODE = "Usar código de bonificación";
SpanishUserPreferences.BONUS_CODE_ORIGIN = "Tu código de bonificación es de: ";
SpanishUserPreferences.BONUS_CODE_CHOOSE_EXPANSION = "Selecciona una expansión: ";

var SpanishProfileButtons = {};

SpanishProfileButtons[true] = "Sí";
SpanishProfileButtons[false] = "No";
SpanishProfileButtons[UserPrefIds.FAMILIAR_CARDS] = {
    ALL: "Esta expansión",
    NONE: "Ninguna de esta expansión",
    ALL_EXPANSIONS: "Todas las expansiones"
};

var SpanishStoreLabels = {};

SpanishStoreLabels[StorePackages.HALF] = "Suscripción Plata";
SpanishStoreLabels[StorePackages.ALL] = "Suscripción Oro";

SpanishStoreLabels[PriceCategories.PURCHASED] = ''; // "Purchase expansions: ";
SpanishStoreLabels[PriceCategories.ALREADY_OWNED] = "Ya suscrito";
SpanishStoreLabels[PriceCategories.DISCOUNT] = "Descuento";
SpanishStoreLabels[PriceCategories.TRANSACTION] = "Cargo por transacción";
SpanishStoreLabels[PriceCategories.SUBTOTAL] = "Subtotal";
SpanishStoreLabels[PriceCategories.VAT] = "IVA";
SpanishStoreLabels[PriceCategories.TOTAL] = "Total";

SpanishStoreLabels.SELLER_INFO = "Info del vendedor";
SpanishStoreLabels.PRODUCT_CONFIRM = "Comprando hasta DATE: EXPANSIONS";
SpanishStoreLabels.PAYMENT_HANDLER = "El pago será tramitado por nuestro proveedor de pagos, Mollie.";
SpanishStoreLabels.PROCEED_TO_PAYMENT = "Proceder al pago";

var SpanishAutoplayLabels = {};

SpanishAutoplayLabels.REGULAR_OPTIONS = "Ajustes comunes";
SpanishAutoplayLabels.AUTOPLAY_OPTIONS = "Decisiones Automáticas";
SpanishAutoplayLabels.AUTOPLAY_DOCUMENTATION_GENERIC = "¿Qué son las Decisiones Automáticas?";
SpanishAutoplayLabels.AUTOPLAY_DOCUMENTATION_SPECIFIC = "Ajustes específicos";
SpanishAutoplayLabels.CUSTOMIZE_OPTIONS = "Personalizar ajustes";
SpanishAutoplayLabels.ALL_OFF = "Todas desactivadas";
SpanishAutoplayLabels.ALL_DEFAULT = "Todas por defecto";
SpanishAutoplayLabels.ALL_CONVENIENT = "Todas convenientes";

var SpanishAnimationTypes = {};
SpanishAnimationTypes[AnimationOptions.NEW_PLAYER] = "Slow"; //TRANSLATE all 6 speeds
SpanishAnimationTypes[AnimationOptions.BEGINNER] = "Standard";
SpanishAnimationTypes[AnimationOptions.MEDIUM] = "Sprightly";
SpanishAnimationTypes[AnimationOptions.ADVANCED] = "Swift";
SpanishAnimationTypes[AnimationOptions.VERYFAST] = "Swift";
SpanishAnimationTypes[AnimationOptions.INSTANT] = "None";

// Missing lines as of 09.01.2023 18:45:08
SpanishStoreLabels[StorePackages.CORE] = "Core subscription";
SpanishStoreLabels[StorePackages.COMPLETE] = "Complete subscription";