"use strict";

webclient.service("redirectService", ['$rootScope', 'preferences', function ($rootScope, preferences) {

    var service = this;

    service.phrases = PHRASES;

    service.html = function () {

        var html = "\n            <head>\n                <style>\n                .container{\n                    max-width:        100vw;\n                    min-width:        100vw;\n                    width:            100vw;\n                    max-height:       100vh;\n                    min-height:       100vh;\n                    height:           100vh;\n                    text-align:       center;\n                    background-color: beige;\n                    color:            gray;\n                    font-size:        xx-large\n                }\n                </style>\n            </head>\n            <body>\n                <div class=\"container\">\n                    <p> " + service.phrases.STORE_WELCOME + " </p>\n                    <p> " + service.phrases.REDIRECTED_TO_PAYMENT_PROVIDER + "</p>\n                </div>\n            </body>\n        ";

        return html;
    };
}]);