"use strict";
// Use like <question-text ng-model="ctrl.question"> </question-text> in HTML
//

webclient.component("questionText", {

    bindings: {

        question: "=ngModel"
    },

    controllerAs: "ctrl",

    controller: [function () {

        var ctrl = this;

        ctrl.answer = undefined;
        ctrl.textChanged = function () {}; // Define this when you for example want autocomplete
        ctrl.onEnterKey = function () {
            return ctrl.question.setAnswer(ctrl.answer);
        };

        ctrl.ensureValue = function () {

            if (ctrl.answer === undefined) {
                ctrl.answer = 'type here';
            } // ctrl.question.defaultAnswer;
            return '';
        };
    }],

    templateUrl: 'html/related-questions/text-1.9.2.2.html'
});