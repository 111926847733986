"use strict";

var FrenchExpansionNames = {};

FrenchExpansionNames[Expansions.BASE] = 'Base';
FrenchExpansionNames[Expansions.INTRIGUE] = 'Intrigue';
FrenchExpansionNames[Expansions.SEASIDE] = 'Rivages';
FrenchExpansionNames[Expansions.ALCHEMY] = 'Alchimie';
FrenchExpansionNames[Expansions.PROSPERITY] = 'Prospérité';
FrenchExpansionNames[Expansions.CORNUCOPIA_GUILDS] = 'Abondance & Guildes';
FrenchExpansionNames[Expansions.HINTERLANDS] = 'Arrière-pays';
FrenchExpansionNames[Expansions.DARK_AGES] = 'L\'âge des ténèbres';
FrenchExpansionNames[Expansions.ADVENTURES] = 'Aventures';
FrenchExpansionNames[Expansions.EMPIRES] = 'Empires';
FrenchExpansionNames[Expansions.PROMOS] = 'Promos';
FrenchExpansionNames[Expansions.NOCTURNE] = 'Nocturne';
FrenchExpansionNames[Expansions.RENAISSANCE] = 'Renaissance';
FrenchExpansionNames[Expansions.MENAGERIE] = 'Ménagerie';
FrenchExpansionNames[Expansions.ALLIES] = 'Alliés';
FrenchExpansionNames[Expansions.PLUNDER] = 'Pillage';
FrenchExpansionNames[Expansions.DEPRECATED] = 'Obsolètes';
FrenchExpansionNames[Expansions.RISING_SUN] = 'Soleil levant';