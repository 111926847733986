"use strict";

var CzechTypes = {};

CzechTypes[Types.ACTION] = "Akce";
CzechTypes[Types.TREASURE] = "Poklad";
CzechTypes[Types.ATTACK] = "Útok";
CzechTypes[Types.CURSE] = "Kletba";
CzechTypes[Types.DURATION] = "Trvání";
CzechTypes[Types.EVENT] = "Událost";
CzechTypes[Types.GATHERING] = "Sbírání";
CzechTypes[Types.KNIGHT] = "Rytíř";
CzechTypes[Types.LANDMARK] = "Mezník";
CzechTypes[Types.LOOTER] = "Loupež";
CzechTypes[Types.RUINS] = "Ruiny";
CzechTypes[Types.TRAVELLER] = "Cestovatel";
CzechTypes[Types.PRIZE] = "Odměna";
CzechTypes[Types.RESERVE] = "Rezervace";
CzechTypes[Types.VICTORY] = "Vítězné body";
CzechTypes[Types.REACTION] = "Reakce";
CzechTypes[Types.SHELTER] = "Úkryt";
CzechTypes[Types.CASTLE] = "Hrad";
CzechTypes[Types.FATE] = "Osud";
CzechTypes[Types.NIGHT] = "Noc";
CzechTypes[Types.DOOM] = "Záhuba";
CzechTypes[Types.HEIRLOOM] = "Dědictví";
CzechTypes[Types.SPIRIT] = "Duše";
CzechTypes[Types.ZOMBIE] = "Zombie";
CzechTypes[Types.BOON] = "Výhoda";
CzechTypes[Types.HEX] = "Prokletí";
CzechTypes[Types.STATE] = "Stát";
CzechTypes[Types.PROJECT] = "Projekt";
CzechTypes[Types.COMMAND] = "Příkaz";
CzechTypes[Types.WAY] = "Cesta";

var CzechZoneNames = {};

CzechZoneNames["HandZone"] = "Ruka";
CzechZoneNames["DrawZone"] = "Dobírací balíček";
CzechZoneNames["DiscardZone"] = "Odkládací balíček";
CzechZoneNames["TrashZone"] = "Smetiště";
CzechZoneNames["SupplyZone"] = "Hromádka";
CzechZoneNames["InPlayZone"] = "Hrací plocha";
CzechZoneNames["TavernZone"] = "Deska Hospoda";
CzechZoneNames["NVZone"] = "Deska Domorodá vesnice";
CzechZoneNames["IslandZone"] = "Deska Ostrov";
CzechZoneNames["ExileZone"] = "Exil";

var CzechMovementTypes = {};

CzechMovementTypes[MovementTypes.PLAY] = new TranslatedMovementType("hrát", "hraje");
CzechMovementTypes[MovementTypes.DRAW] = new TranslatedMovementType("brát", "bere");
CzechMovementTypes[MovementTypes.DISCARD] = new TranslatedMovementType("odložit", "odkládá");
CzechMovementTypes[MovementTypes.GAIN] = new TranslatedMovementType("získat", "získává");
CzechMovementTypes[MovementTypes.TRASH] = new TranslatedMovementType("vyhodit", "vyhazuje");
CzechMovementTypes[MovementTypes.TOPDECK] = new TranslatedMovementType("dát navrch", "dává navrch");
CzechMovementTypes[MovementTypes.REVEAL] = new TranslatedMovementType("odhalit", "odhaluje");
CzechMovementTypes[MovementTypes.REVEAL_SEARCHING] = new TranslatedMovementType("odhalit", "odhaluje");
CzechMovementTypes[MovementTypes.LOOK_AT] = new TranslatedMovementType("podívat se na", "dívá se na");
CzechMovementTypes[MovementTypes.PUT_IN_HAND] = new TranslatedMovementType("dát si do ruky", "dává si do ruky");
CzechMovementTypes[MovementTypes.SET_ASIDE] = new TranslatedMovementType("dát na stranu", "dává na stanu");
CzechMovementTypes[MovementTypes.PUT_ON_MAT] = new TranslatedMovementType("dát na Desku", "Dává na Desku");
CzechMovementTypes[MovementTypes.DECK_TO_DISCARD] = new TranslatedMovementType("DECK_TO_DISCARD", "DECK_TO_DISCARD");
CzechMovementTypes[MovementTypes.BACK_ON_DECK] = new TranslatedMovementType("BACK_ON_DECK", "BACK_ON_DECK");
CzechMovementTypes[MovementTypes.SHUFFLE_INTO_DECK] = new TranslatedMovementType("SHUFFLE_INTO_DECK", "SHUFFLE_INTO_DECK");
CzechMovementTypes[MovementTypes.INSERT_IN_DECK] = new TranslatedMovementType("INSERT_IN_DECK", "INSERT_IN_DECK");
CzechMovementTypes[MovementTypes.EXCHANGE_RETURN] = new TranslatedMovementType("EXCHANGE_RETURN", "EXCHANGE_RETURN");
CzechMovementTypes[MovementTypes.EXCHANGE_RECEIVE] = new TranslatedMovementType("EXCHANGE_RECEIVE", "EXCHANGE_RECEIVE");
CzechMovementTypes[MovementTypes.PASS] = new TranslatedMovementType("PASS", "PASS");
CzechMovementTypes[MovementTypes.RETURN_TO] = new TranslatedMovementType("vrátit", "vrací");
CzechMovementTypes[MovementTypes.PUT_ON_BOTTOM_OF] = new TranslatedMovementType("PUT_ON_BOTTOM_OF", "PUT_ON_BOTTOM_OF");
CzechMovementTypes[MovementTypes.STARTS_WITH] = new TranslatedMovementType("STARTS_WITH", "STARTS_WITH");
CzechMovementTypes[MovementTypes.TAKE] = new TranslatedMovementType("TAKE", "TAKE");
CzechMovementTypes[MovementTypes.GET_STATE] = new TranslatedMovementType("GET_STATE", "GET_STATE");
CzechMovementTypes[MovementTypes.RETURN_STATE] = new TranslatedMovementType("RETURN_STATE", "RETURN_STATE");
CzechMovementTypes[MovementTypes.EXILE] = new TranslatedMovementType("EXILE", "EXILE");

var CzechPileTokenTexts = {};

CzechPileTokenTexts[TokenNames.PLUS_ACTION] = "+A";
CzechPileTokenTexts[TokenNames.PLUS_BUY] = "+B";
CzechPileTokenTexts[TokenNames.PLUS_CARD] = "+C";
CzechPileTokenTexts[TokenNames.PLUS_COIN] = "+1";
CzechPileTokenTexts[TokenNames.MINUS_COST] = "-2";
CzechPileTokenTexts[TokenNames.TRASH] = "X";

var CzechPileMarkers = {};

CzechPileMarkers[PileMarkerTypes.BANE] = "Prokletí";
CzechPileMarkers[PileMarkerTypes.OBELISK] = "Obelisk";
CzechPileMarkers[PileMarkerTypes.SPLIT] = "Rozdíl";
CzechPileMarkers[PileMarkerTypes.MIXED] = "Smíšené";

var CzechTokenNames = {};

CzechTokenNames[TokenNames.PLUS_ACTION] = "+ Žeton Akce";
CzechTokenNames[TokenNames.PLUS_BUY] = "+Žeton Nákupu";
CzechTokenNames[TokenNames.PLUS_COIN] = "+Žeton Mince";
CzechTokenNames[TokenNames.PLUS_CARD] = "+Žeton Karty";
CzechTokenNames[TokenNames.MINUS_COST] = "-Žeton Ceny";
CzechTokenNames[TokenNames.TRASH] = "Žeton Smetiště";
CzechTokenNames[TokenNames.JOURNEY] = "Žeton Cestování";
CzechTokenNames[TokenNames.MINUS_CARD] = "-Žeton Karty";
CzechTokenNames[TokenNames.MINUS_COIN] = "-Žeton Mince";

var CzechKingdomViewerNames = {};

CzechKingdomViewerNames[KingdomViewerGroups.SUPPLY_CARDS] = "Karty v Hromádce";
CzechKingdomViewerNames[KingdomViewerGroups.KINGDOM_LANDSCAPES] = "Události, Milníky, Projekty a Cesty";
CzechKingdomViewerNames[KingdomViewerGroups.NON_KINGDOM] = "Karty mimo Království";
CzechKingdomViewerNames[KingdomViewerGroups.RUINS] = "Ruiny";
CzechKingdomViewerNames[KingdomViewerGroups.DRUID_BOONS] = "Výhody Druidů";
CzechKingdomViewerNames[KingdomViewerGroups.KNIGHTS] = "Rytíři";
CzechKingdomViewerNames[KingdomViewerGroups.CASTLES] = "Hrady";
CzechKingdomViewerNames[KingdomViewerGroups.PRIZES] = "Odměny";
CzechKingdomViewerNames[KingdomViewerGroups.BOONS_HEXES] = "Výhody a Prokletí";
CzechKingdomViewerNames[KingdomViewerGroups.STATES_ARTIFACTS] = "Státy a Artefakty";
CzechKingdomViewerNames[KingdomViewerGroups.BLACK_MARKET] = "Černý trh";