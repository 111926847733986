"use strict";

var EnglishQuestionDescriptions = {};

EnglishQuestionDescriptions[QuestionIds.GAME_RESOLVE_ABILITY] = ["Resolve a forced ability:", "Resolve an effect"];
EnglishQuestionDescriptions[QuestionIds.GAME_MAY_RESOLVE_ABILITY] = ["Resolve an optional ability.", "You may resolve an effect"];
EnglishQuestionDescriptions[QuestionIds.GAME_MAY_REACT_WITH] = ["React with something.", "Do you want to react?"];
EnglishQuestionDescriptions[QuestionIds.GAME_TURN_SELECT] = ["Select a turn.", "What kind of turn next?"];
EnglishQuestionDescriptions[QuestionIds.GAME_MAY_BUY] = ["buy cards.", "You may buy cards"];
EnglishQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING] = ["start buying cards.", "You may buy cards"];
EnglishQuestionDescriptions[QuestionIds.GAME_MAY_BUY_NONCARDS] = ["buy, but not cards.", "You may buy, but not cards"];
EnglishQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING_NONCARDS] = ["start buying, but not cards.", "You may buy, but not cards"];
EnglishQuestionDescriptions[QuestionIds.GAME_REPAY_HOW_MUCH] = ["Repay debt.", "You may pay off debt"];
EnglishQuestionDescriptions[QuestionIds.GAME_SPEND_COIN_TOKENS] = ["spend coffers tokens.", "You may spend coffers tokens"];
EnglishQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_ACTION] = ["play an Action from your hand.", "You may play Actions"];
EnglishQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_TREASURES] = ["play treasures.", "You may play Treasures"];
EnglishQuestionDescriptions[QuestionIds.GAME_PLAY_ALL_TREASURES] = ["autoplay treasures.", "GAME_PLAY_ALL_TREASURES"];
EnglishQuestionDescriptions[QuestionIds.GAME_ACTION_PHASE] = ["It's your action phase", "You may play Actions"];
EnglishQuestionDescriptions[QuestionIds.GAME_BUY_PHASE] = ["It's your buy phase", "You may buy cards"];
EnglishQuestionDescriptions[QuestionIds.GAME_BUY_PHASE_NONCARDS] = ["It's your buy phase", "You may buy, but not cards"];
EnglishQuestionDescriptions[QuestionIds.GAME_CLEANUP_PHASE] = ["Choose clean-up items and resolution order.", "Choose cleanup abilities"];
EnglishQuestionDescriptions[QuestionIds.GAME_USE_VILLAGER] = ["You may use a Villager.", "You may use a Villager"];
EnglishQuestionDescriptions[QuestionIds.GAME_USE_COFFERS] = ["You may use Coffers.", "You may use Coffers"];
EnglishQuestionDescriptions[QuestionIds.ARTISAN_GAIN] = ["Gain a card to your hand costing up to $5. ", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.ARTISAN_TOPDECK] = ["Put a card from your hand onto your deck.", "Topdeck a card"];
EnglishQuestionDescriptions[QuestionIds.BANDIT] = ["Trash a card from PLAYER0.", "Trash a card from PLAYER0."];
EnglishQuestionDescriptions[QuestionIds.BUREAUCRAT] = ["Reveal a Victory card from your hand and put it onto your deck.", "Topdeck a Victory card"];
EnglishQuestionDescriptions[QuestionIds.CELLAR] = ["Discard any number of cards, then draw that many.", "Discard, then draw"];
EnglishQuestionDescriptions[QuestionIds.CHAPEL] = ["Trash up to 4 cards from your hand.", "Trash up to 4 cards"];
EnglishQuestionDescriptions[QuestionIds.HARBINGER_TOPDECK] = ["You may topdeck a card from your discard pile to put onto your deck.", "You may topdeck"];
EnglishQuestionDescriptions[QuestionIds.LIBRARY] = ["You may skip CARDS0 by setting it aside.", "You may skip CARDS0"];
EnglishQuestionDescriptions[QuestionIds.MILITIA] = ["Discard down to 3 cards in hand.", "Discard down to 3"];
EnglishQuestionDescriptions[QuestionIds.MINE_TRASH] = ["You may trash a Treasure from your hand. Gain a Treasure to your hand costing up to $3 more than it.", "You may improve a Treasure"];
EnglishQuestionDescriptions[QuestionIds.MINE_GAIN] = ["Gain a Treasure to your hand costing up to AMOUNT0.", "Gain a Treasure up to AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.MONEYLENDER] = ["You may trash a Copper from your hand for +$3.", "You may trash a Copper"];
EnglishQuestionDescriptions[QuestionIds.POACHER] = ["Discard Argument0.", "Discard Argument0."];
EnglishQuestionDescriptions[QuestionIds.REMODEL_TRASH] = ["Trash a card from your hand. Gain a card costing up to $2 more than it.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.REMODEL_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card up to AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.SENTRY_TRASH] = ["Trash any of the revealed cards", "You may trash"];
EnglishQuestionDescriptions[QuestionIds.SENTRY_DISCARD] = ["Discard any of the revealed cards", "You may discard"];
EnglishQuestionDescriptions[QuestionIds.SENTRY_TOPDECK] = ["Put them back onto your deck in any order", "Topdeck in any order"];
EnglishQuestionDescriptions[QuestionIds.THRONE_ROOM] = ["You may play an Action card from your hand twice.", "You may play an Action twice"];
EnglishQuestionDescriptions[QuestionIds.VASSAL] = ["You may play CARDS0 from your discard pile.", "You may play CARDS0"];
EnglishQuestionDescriptions[QuestionIds.WORKSHOP] = ["Gain a card costing up to $4.", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.TRASHING_TOKEN] = ["You may trash a card from your hand.", "You may trash a card"];
EnglishQuestionDescriptions[QuestionIds.ALMS] = ["Gain a card costing up to $4.", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.AMULET_MODE] = ["Choose one: +$1; or trash a card from your hand; or gain a Silver.", "Choose how to play Amulet"];
EnglishQuestionDescriptions[QuestionIds.AMULET_TRASH] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.ARTIFICER_DELAY] = ["You may discard any number of cards. You may gain a card costing exactly $1 per card discarded, onto your deck.", "You may use Artificer"];
EnglishQuestionDescriptions[QuestionIds.ARTIFICER_DISCARD] = ["Discard any number of cards. You may gain a card costing exactly $1 per card discarded, onto your deck.", "Discard, then gain"];
EnglishQuestionDescriptions[QuestionIds.ARTIFICER_GAIN] = ["You may gain a card costing exactly AMOUNT0, onto your deck.", "You may gain a card for AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.BALL_FIRST] = ["Gain 2 cards each costing up to $4.", "Gain two cards"];
EnglishQuestionDescriptions[QuestionIds.BALL_SECOND] = ["Gain another card costing up to $4.", "Gain another card"];
EnglishQuestionDescriptions[QuestionIds.BONFIRE] = ["Trash up to 2 Coppers you have in play.", "Trash up to 2 Coppers from play"];
EnglishQuestionDescriptions[QuestionIds.DISCIPLE] = ["You may play an Action card from your hand twice. Gain a copy of it.", "Play twice, then gain a copy"];
EnglishQuestionDescriptions[QuestionIds.DUNGEON] = ["Discard 2 cards.", "Discard 2 cards"];
EnglishQuestionDescriptions[QuestionIds.FERRY] = ["Move your -$2 cost token to an Action Supply pile.", "Move -$2 cost token"];
EnglishQuestionDescriptions[QuestionIds.FUGITIVE] = ["Discard a card.", "Discard a card"];
EnglishQuestionDescriptions[QuestionIds.GEAR] = ["Set aside up to 2 cards from your hand. At the start of your next turn, put them into your hand.", "Set aside up to 2 cards"];
EnglishQuestionDescriptions[QuestionIds.HAUNTED_WOODS] = ["Put your hand onto your deck in any order", "Put your hand onto your deck"];
EnglishQuestionDescriptions[QuestionIds.HERO] = ["Gain a Treasure.", "Gain a Treasure"];
EnglishQuestionDescriptions[QuestionIds.LOST_ARTS_NEW] = ["Put your +1 Action token on an Action Supply pile.", "Move +1 Action token"];
EnglishQuestionDescriptions[QuestionIds.LOST_ARTS_MOVE] = ["Move your +1 Action token to an Action Supply pile.", "Move +1 Action token"];
EnglishQuestionDescriptions[QuestionIds.MESSENGER_DISCARD] = ["You may put your deck into your discard pile.", "You may discard your deck"];
EnglishQuestionDescriptions[QuestionIds.MESSENGER_GAIN] = ["Gain a card costing up to $4, and each other player gains a copy of it.", "Distribute a card costing up to $4"];
EnglishQuestionDescriptions[QuestionIds.MISER] = ["Choose one: Put a Copper from your hand onto your Tavern mat; or +AMOUNT0", "Copper to mat or +AMOUNT0?"];
EnglishQuestionDescriptions[QuestionIds.PATHFINDING_NEW] = ["Put your +1 Card token on an Action Supply pile.", "Move +1 Card token"];
EnglishQuestionDescriptions[QuestionIds.PATHFINDING_MOVE] = ["Move your +1 Card token to an Action Supply pile.", "Move +1 Card token"];
EnglishQuestionDescriptions[QuestionIds.PILGRIMAGE_FIRST] = ["You may choose up to 3 differently named cards you have in play and gain a copy of each.", "Choose 3 cards to gain"];
EnglishQuestionDescriptions[QuestionIds.PILGRIMAGE_SECOND] = ["You may choose another 2 differently named cards you have in play and gain a copy of each.", "Choose 2 cards to gain"];
EnglishQuestionDescriptions[QuestionIds.PILGRIMAGE_THIRD] = ["You may choose one more differently named card you have in play to gain a copy of.", "Choose 1 card to gain"];
EnglishQuestionDescriptions[QuestionIds.PLAN_NEW] = ["Put your Trashing token on an Action Supply pile.", "Move Trashing token"];
EnglishQuestionDescriptions[QuestionIds.PLAN_MOVE] = ["Move your Trashing token to an Action Supply pile.", "Move Trashing token"];
EnglishQuestionDescriptions[QuestionIds.QUEST_MODE] = ["Choose between discarding an Attack, two Curses, or six cards.", "Choose your quest"];
EnglishQuestionDescriptions[QuestionIds.QUEST_ATTACK] = ["Discard an Attack card.", "Discard an Attack"];
EnglishQuestionDescriptions[QuestionIds.QUEST_6CARDS] = ["Discard six cards.", "Discard six cards"];
EnglishQuestionDescriptions[QuestionIds.RATCATCHER] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_PLAY] = ["You may trash Raze from play.", "You may trash Raze"];
EnglishQuestionDescriptions[QuestionIds.RAZE_ALL] = ["Trash a card from your hand or Raze from play.", "Trash a card or trash Raze"];
EnglishQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_HAND] = ["You may trash a card from your hand.", "You may trash a card"];
EnglishQuestionDescriptions[QuestionIds.RAZE_CHOOSE_CARD] = ["Choose a card to put into your hand.", "Put a card into your hand"];
EnglishQuestionDescriptions[QuestionIds.SAVE] = ["Set aside a card from your hand, and put it into your hand at end of turn (after drawing).", "Set a card aside"];
EnglishQuestionDescriptions[QuestionIds.SCOUTING_PARTY_DISCARD] = ["Discard NUMBER0 cards.", "Discard NUMBER0 cards."];
EnglishQuestionDescriptions[QuestionIds.SCOUTING_PARTY_TOPDECK] = ["Put the remaining two cards on top of your deck in any order.", "Topdeck these cards"];
EnglishQuestionDescriptions[QuestionIds.SEAWAY] = ["Gain an Action card costing up to $4. Move your +1 Buy token to its pile.", "Gain an Action card"];
EnglishQuestionDescriptions[QuestionIds.SOLDIER] = ["Discard a card.", "Discard a card"];
EnglishQuestionDescriptions[QuestionIds.STORYTELLER_FIRST] = ["Play up to 3 Treasures from your hand.", "Play up to 3 Treasures"];
EnglishQuestionDescriptions[QuestionIds.STORYTELLER_SECOND] = ["Play up to 2 more Treasures from your hand.", "Play up to 2 Treasures"];
EnglishQuestionDescriptions[QuestionIds.STORYTELLER_THIRD] = ["You may play another Treasure from your hand.", "You may play another Treasure"];
EnglishQuestionDescriptions[QuestionIds.TEACHER_CHOOSE_TOKEN] = ["Choose which token to move.", "Choose which token"];
EnglishQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_MOVE] = ["Put your +1 Action token on an Action Supply pile you have no tokens on.", "Move +1 Action token"];
EnglishQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_NEW] = ["Move your +1 Action token to an Action Supply pile you have no tokens on.", "Move +1 Action token"];
EnglishQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_MOVE] = ["Put your +1 Card token on an Action Supply pile you have no tokens on.", "Move +1 Card token"];
EnglishQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_NEW] = ["Move your +1 Card token to an Action Supply pile you have no tokens on. ", "Move +1 Card token"];
EnglishQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_MOVE] = ["Put your +1 Coin token on an Action Supply pile you have no tokens on.", "Move +1 Coin token"];
EnglishQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_NEW] = ["Move your +1 Coin token to an Action Supply pile you have no tokens on.", "Move +1 Coin token"];
EnglishQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_MOVE] = ["Put your +1 Buy token on an Action Supply pile you have no tokens on.", "Move +1 Buy token"];
EnglishQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_NEW] = ["Move your +1 Buy token to an Action Supply pile you have no tokens on.", "Move +1 Buy token"];
EnglishQuestionDescriptions[QuestionIds.TRADE] = ["Trash up to 2 cards from your hand. Gain a Silver per card you trashed.", "Trash up to 2 cards"];
EnglishQuestionDescriptions[QuestionIds.TRAINING_NEW] = ["Put your +1 Coin token on an Action Supply pile.", "Move +1 Coin token"];
EnglishQuestionDescriptions[QuestionIds.TRAINING_MOVE] = ["Move your +1 Coin token to an Action Supply pile.", "Move +1 Coin token"];
EnglishQuestionDescriptions[QuestionIds.TRANSMOGRIFY_TRASH] = ["Trash a card from your hand, and gain a card costing up to $1 more than it into your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.TRANSMOGRIFY_GAIN] = ["Gain a card costing up to AMOUNT0 into your hand.", "Gain a card up to AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.APOTHECARY] = ["Put the cards back on your deck in any order.", "Topdeck these cards"];
EnglishQuestionDescriptions[QuestionIds.APPRENTICE] = ["Trash a card from your hand. +1 Card per $1 it costs. +2 Cards if it has [P] in its cost.", "Trash, then draw"];
EnglishQuestionDescriptions[QuestionIds.GOLEM] = ["Choose which Action to play first.", "Choose play order"];
EnglishQuestionDescriptions[QuestionIds.HERBALIST] = ["You may choose a Treasure to topdeck", "You may topdeck a Treasure"];
EnglishQuestionDescriptions[QuestionIds.HERBALISTS] = ["What Treasures to topdeck", "Topdeck up to Argument0 Treasures"];
EnglishQuestionDescriptions[QuestionIds.SCRYING_POOL_YOU] = ["Keep or discard CARDS0", "You: keep or discard CARDS0"];
EnglishQuestionDescriptions[QuestionIds.SCRYING_POOL_OTHER] = ["Choose whether PLAYER1 should keep or discard CARDS0.", "PLAYER1: keep or discard CARDS0."];
EnglishQuestionDescriptions[QuestionIds.TRANSMUTE] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.UNIVERSITY] = ["You may gain an Action card costing up to $5.", "You may gain an Action"];
EnglishQuestionDescriptions[QuestionIds.FOLLOWERS] = ["Discard down to 3 cards in hand.", "Discard down to 3"];
EnglishQuestionDescriptions[QuestionIds.DIADEM] = ["Use Villagers before getting Coins for Actions?", "Use Villagers?"];
EnglishQuestionDescriptions[QuestionIds.HAMLET] = ["You may discard to get +1 Action and/or +1 Buy", "You may discard"];
EnglishQuestionDescriptions[QuestionIds.HAMLET_ACTION] = ["Discard a card for +1 Action.", "Discard for +1 Action"];
EnglishQuestionDescriptions[QuestionIds.HAMLET_BUY] = ["Discard a card for +1 Buy.", "Discard for +1 Buy"];
EnglishQuestionDescriptions[QuestionIds.HAMLET_BOTH] = ["Discard 2 cards.", "Discard 2 cards"];
EnglishQuestionDescriptions[QuestionIds.HORN_OF_PLENTY] = ["Gain a card costing up to AMOUNT0. If it's a Victory card, trash this.", "Gain a card up to AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.HORSE_TRADERS] = ["Discard 2 cards.", "Discard 2 cards."];
EnglishQuestionDescriptions[QuestionIds.JESTER] = ["Choose who gains CARDS0: You or PLAYER1.", "Choose who gains CARDS0"];
EnglishQuestionDescriptions[QuestionIds.REMAKE_TRASH_FIRST] = ["Do this twice: Trash a card from your hand, then gain a card costing exactly $1 more than it.", "Trash first card"];
EnglishQuestionDescriptions[QuestionIds.REMAKE_TRASH_SECOND] = ["Trash another card from your hand, then gain a card costing exactly $1 more than it.", "Trash another card"];
EnglishQuestionDescriptions[QuestionIds.REMAKE_GAIN_FIRST] = ["Gain a card costing exactly COST0", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.REMAKE_GAIN_SECOND] = ["Gain a card costing exactly COST0", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.TOURNAMENT_GAIN_PRIZE_OR_DUCHY] = ["Gain a Prize or a Duchy onto your deck.", "Gain a Prize or a Duchy"];
EnglishQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_PRIZE] = ["You may gain a Prize onto your deck.", "You may gain a Prize"];
EnglishQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_DUCHY] = ["You may gain a Duchy onto your deck.", "You may gain a Duchy"];
EnglishQuestionDescriptions[QuestionIds.TOURNAMENT_REVEAL] = ["You may reveal a Province.", "You may reveal a Province"];
EnglishQuestionDescriptions[QuestionIds.TRUSTY_STEED] = ["Choose how to play Trusty Steed.", "Choose how to play Trusty Steed"];
EnglishQuestionDescriptions[QuestionIds.YOUNG_WITCH] = ["Discard 2 cards.", "Discard 2 cards"];
EnglishQuestionDescriptions[QuestionIds.YOUNG_WITCH_BANE] = ["You may reveal CARDS0 to block Young Witch.", "Reveal CARDS0 to block Young Witch?"];
EnglishQuestionDescriptions[QuestionIds.ALTAR_TRASH] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.ALTAR_GAIN] = ["Gain a card costing up to $5.", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.ARMORY] = ["Gain a card onto your deck costing up to $4.", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.BAND_OF_MISFITS] = ["Play an Action card costing less then this.", "Play a card"];
EnglishQuestionDescriptions[QuestionIds.CATACOMBS_MODE] = ["Put CARDS0 into your hand; or discard them and +3 Cards.", "Take or draw new?"];
EnglishQuestionDescriptions[QuestionIds.CATACOMBS_GAIN] = ["Gain a card cheaper than AMOUNT0.", "Gain a card cheaper than AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.COUNT_PAY] = ["Choose one: Discard 2 cards; or put a card from your hand onto your deck; or gain a Copper.", "Choose Count payment"];
EnglishQuestionDescriptions[QuestionIds.COUNT_DISCARD] = ["Discard 2 cards.", "Discard 2 cards"];
EnglishQuestionDescriptions[QuestionIds.COUNT_TOPDECK] = ["Put a card from your hand onto your deck.", "Topdeck a card"];
EnglishQuestionDescriptions[QuestionIds.COUNT_PROFIT] = ["Choose one: +$3; or trash your hand; or gain a Duchy.", "Choose Count profit"];
EnglishQuestionDescriptions[QuestionIds.COUNTERFEIT] = ["You may play a Treasure from your hand twice. If you do, trash that Treasure.", "Play twice, then trash"];
EnglishQuestionDescriptions[QuestionIds.CULTIST] = ["You may play a Cultist from your hand for free.", "Play another Cultist?"];
EnglishQuestionDescriptions[QuestionIds.DAME_ANNA] = ["You may trash up to 2 cards from your hand.", "Trash up to 2 cards"];
EnglishQuestionDescriptions[QuestionIds.DAME_NATALIE] = ["You may gain a card costing up to $3.", "You may gain a card"];
EnglishQuestionDescriptions[QuestionIds.DEATH_CART] = ["You may trash an Action card from your hand or Death Cart from play.", "You may trash Death Cart or an Action card."];
EnglishQuestionDescriptions[QuestionIds.FORAGER] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.KNIGHTS] = ["Choose a card to trash.", "Choose a card to trash"];
EnglishQuestionDescriptions[QuestionIds.KNIGHTS_ALREADY_DIES] = ["Choose a card to trash. (CARDS0 already dies)", "Choose a card to trash"];
EnglishQuestionDescriptions[QuestionIds.KNIGHTS_STILL_MATTERS] = ["Choose a card to trash (CARDS1 will be trashed if you trash CARDS0)", "Choose a card to trash"];
EnglishQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_EXPAND] = ["You may trash from your hand and gain a card costing up to $3 more than it.", "You may trash a card"];
EnglishQuestionDescriptions[QuestionIds.GRAVEROBBER_EXPAND] = ["Trash from your hand and gain a card costing up to $3 more than it.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_GAIN] = ["You may gain from the trash onto your deck.", "You may gain from trash"];
EnglishQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN] = ["Gain from the trash onto your deck.", "Gain from the trash"];
EnglishQuestionDescriptions[QuestionIds.GRAVEROBBER_COMPLEX] = ["When playing Graverobber", "Gain a card from the trash or trash an Action card"];
EnglishQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN_SUPPLY] = ["Gain a card costing up to AMOUNT0.", "Gain a card up to AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.HERMIT_TRASH] = ["You may trash a non-Treasure card from your discard pile or hand.", "You may trash"];
EnglishQuestionDescriptions[QuestionIds.HERMIT_GAIN] = ["Gain a card costing up to $3.", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.HUNTING_GROUNDS] = ["Gain a Duchy or 3 Estates.", "Duchy or 3 Estates?"];
EnglishQuestionDescriptions[QuestionIds.IRONMONGER] = ["You may keep or discard CARDS0.", "You may discard CARDS0"];
EnglishQuestionDescriptions[QuestionIds.JUNK_DEALER] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.MERCENARY_ONE] = ["You may trash a card from your hand (for no bonus).", "You may trash a card"];
EnglishQuestionDescriptions[QuestionIds.MERCENARY_MULTIPLE] = ["You may trash two cards from your hand.", "You may trash two cards"];
EnglishQuestionDescriptions[QuestionIds.MERCENARY_DISCARD] = ["Discard down to 3 cards.", "Discard down to 3"];
EnglishQuestionDescriptions[QuestionIds.MYSTIC] = ["Guess what card is on top of your deck.", "Guess a card"];
EnglishQuestionDescriptions[QuestionIds.PILLAGE] = ["Choose which card PLAYER0 discards.", "Choose discard for PLAYER0"];
EnglishQuestionDescriptions[QuestionIds.PROCESSION_PLAY] = ["You may play an Action card from your hand twice.", "You may play an Action twice."];
EnglishQuestionDescriptions[QuestionIds.PROCESSION_GAIN] = ["Gain an Action costing exactly AMOUNT0.", "Gain an Action for AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.RATS_TRASH_ANY] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.RATS_TRASH_OTHER] = ["Trash a card from your hand other than a Rats.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.REBUILD_SKIP] = ["Name a Victory card to skip (or Copper).", "Name a card to skip"];
EnglishQuestionDescriptions[QuestionIds.REBUILD_GAIN] = ["Gain a Victory card costing up to AMOUNT0.", "Gain a Victory card up to AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.ROGUE_TRASH] = ["Choose a card from PLAYER0 to trash.", "Trash a card from PLAYER0"];
EnglishQuestionDescriptions[QuestionIds.ROGUE_GAIN] = ["Gain a card from the trash costing from $3 to $6.", "Gain a card from the trash"];
EnglishQuestionDescriptions[QuestionIds.SCAVENGER] = ["You may put your deck into your discard pile.", "You may discard your deck"];
EnglishQuestionDescriptions[QuestionIds.SCAVENGER_TOPDECK] = ["Put a card from your discard pile onto your deck.", "Topdeck a card"];
EnglishQuestionDescriptions[QuestionIds.SIR_MICHAEL] = ["Discard down to 3 cards.", "Discard down to 3 cards"];
EnglishQuestionDescriptions[QuestionIds.SQUIRE_MODE] = ["Choose one: +2 Actions; or +2 Buys; or gain a Silver.", "Choose Squire mode"];
EnglishQuestionDescriptions[QuestionIds.SQUIRE_GAIN] = ["Gain an Attack card.", "Gain an Attack"];
EnglishQuestionDescriptions[QuestionIds.STOREROOM_CARDS] = ["Discard any number of cards, then draw that many.", "Discard for cards"];
EnglishQuestionDescriptions[QuestionIds.STOREROOM_COINS] = ["Discard any number of cards for +$1 each.", "Discard for coins"];
EnglishQuestionDescriptions[QuestionIds.SURVIVORS] = ["Topdeck these two cards in any order, or discard them both.", "Topdeck or discard"];
EnglishQuestionDescriptions[QuestionIds.URCHIN] = ["Discard down to 4 cards.", "Discard down to 4 cards"];
EnglishQuestionDescriptions[QuestionIds.WANDERING_MINSTREL] = ["Put the Action cards back in any order.", "Topdeck in any order"];
EnglishQuestionDescriptions[QuestionIds.ADVANCE_TRASH] = ["You may trash an Action card from your hand.", "You may trash an Action"];
EnglishQuestionDescriptions[QuestionIds.ADVANCE_GAIN] = ["Gain an Action card costing up to $6.", "Gain an Action"];
EnglishQuestionDescriptions[QuestionIds.ANNEX] = ["Choose up to 5 cards not to shuffle into your deck.", "Choose up to 5 cards"];
EnglishQuestionDescriptions[QuestionIds.ARCHIVE] = ["Choose a card to put into your hand.", "Choose a card"];
EnglishQuestionDescriptions[QuestionIds.ARENA] = ["You may discard an Action card for NUMBER0VP.", "Arena: discard for NUMBER0VP?"];
EnglishQuestionDescriptions[QuestionIds.BANQUET] = ["Gain a non-Victory card costing up to $5.", "Gain a non-Victory"];
EnglishQuestionDescriptions[QuestionIds.BUSTLING_VILLAGE] = ["You may put a Settlers into your hand.", "You may take a Settlers"];
EnglishQuestionDescriptions[QuestionIds.CATAPULT_TRASH] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.CATAPULT_DISCARD] = ["Discard down to 3 cards.", "Discard down to 3 cards"];
EnglishQuestionDescriptions[QuestionIds.CHARM_MODE] = ["Choose how to play Charm", "Choose how to play Charm"];
EnglishQuestionDescriptions[QuestionIds.CHARM_GAIN] = ["Gain a card other than CARDS0 costing exactly AMOUNT1.", "Gain a card for AMOUNT1 (not CARDS0)"];
EnglishQuestionDescriptions[QuestionIds.CROWN_ACTION] = ["You may play an Action from your hand twice.", "You may play an Action twice"];
EnglishQuestionDescriptions[QuestionIds.CROWN_TREASURE] = ["You may play a Treasure from your hand twice.", "You may play a Treasure twice"];
EnglishQuestionDescriptions[QuestionIds.DONATE] = ["Trash any number of cards.", "Trash any number"];
EnglishQuestionDescriptions[QuestionIds.ENCAMPMENT] = ["You may reveal a Gold or Plunder from your hand.", "You may reveal Gold or Plunder"];
EnglishQuestionDescriptions[QuestionIds.ENGINEER_GAIN] = ["Gain a card costing up to $4.", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.ENGINEER_TRASH] = ["You may trash the Engineer.", "You may trash the Engineer"];
EnglishQuestionDescriptions[QuestionIds.FORUM] = ["Discard 2 cards.", "Discard 2 cards"];
EnglishQuestionDescriptions[QuestionIds.GLADIATOR_REVEAL] = ["Reveal a card from your hand.", "Reveal a card"];
EnglishQuestionDescriptions[QuestionIds.GLADIATOR_PRETEND] = ["Pretend to think about revealing a copy.", "Pretend to think"];
EnglishQuestionDescriptions[QuestionIds.GLADIATOR_BLOCK] = ["You may reveal CARDS0 from your hand.", "You may reveal CARDS0"];
EnglishQuestionDescriptions[QuestionIds.HAUNTED_CASTLE] = ["Put 2 cards from your hand onto your deck.", "Topdeck 2 cards"];
EnglishQuestionDescriptions[QuestionIds.LEGIONARY_REVEAL] = ["You may reveal a Gold from your hand.", "You may reveal a Gold"];
EnglishQuestionDescriptions[QuestionIds.LEGIONARY_DISCARD] = ["Discard down to 2 cards in hand.", "Discard down to 2 cards"];
EnglishQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_NEW] = ["Bid up to 40D.", "Bid up to 40D"];
EnglishQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_OVERBID] = ["Bid up to 40D. Currently PLAYER0 holds the high bid of NUMBER1.", "Bid up to 40D"];
EnglishQuestionDescriptions[QuestionIds.OPULENT_CASTLE] = ["Discard any number of Victory cards for +$2 each. ", "Discard for +$2 each"];
EnglishQuestionDescriptions[QuestionIds.OVERLORD] = ["Play an Action card costing up to $5. ", "Play a card"];
EnglishQuestionDescriptions[QuestionIds.RITUAL] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.SACRIFICE] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.SALT_THE_EARTH] = ["Trash a Victory card from the Supply.", "Trash from the Supply"];
EnglishQuestionDescriptions[QuestionIds.SETTLERS] = ["You may put a Copper from your discard pile into your hand.", "You may take a Copper"];
EnglishQuestionDescriptions[QuestionIds.SMALL_CASTLE_IN_PLAY] = ["Trash Small Castle from play or a Castle from your hand.", "Trash a Castle"];
EnglishQuestionDescriptions[QuestionIds.SMALL_CASTLE_GONE] = ["Trash a Castle from your hand.", "Trash a Castle"];
EnglishQuestionDescriptions[QuestionIds.SMALL_CASTLE_GAIN] = ["Gain a Castle.", "Gain a Castle"];
EnglishQuestionDescriptions[QuestionIds.SPRAWLING_CASTLE] = ["Gain a Duchy or 3 Estates.", "Duchy or 3 Estates?"];
EnglishQuestionDescriptions[QuestionIds.TAX] = ["Choose a supply pile to add (2D) to.", "Add (2D) to a pile"];
EnglishQuestionDescriptions[QuestionIds.TEMPLE] = ["Trash from 1 to 3 differently named cards from your hand.", "Trash 1 - 3 cards"];
EnglishQuestionDescriptions[QuestionIds.WILD_HUNT] = ["Choose between +3 Cards and the Estate and VP", "Cards or points?"];
EnglishQuestionDescriptions[QuestionIds.ADVISOR] = ["Choose what card PLAYER0 doesn't get.", "Discard for PLAYER0"];
EnglishQuestionDescriptions[QuestionIds.BUTCHER_TRASH] = ["You may trash a card from your hand.", "You may trash a card"];
EnglishQuestionDescriptions[QuestionIds.BUTCHER_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card up to AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.DOCTOR_PLAY] = ["Name a card to trash.", "Name a card"];
EnglishQuestionDescriptions[QuestionIds.DOCTOR_TOPDECK] = ["Put these cards back in any order.", "Topdeck these cards"];
EnglishQuestionDescriptions[QuestionIds.DOCTOR_OVERPAY] = ["You may overpay for Doctor.", "You may overpay"];
EnglishQuestionDescriptions[QuestionIds.DOCTOR_MODE] = ["What to do with CARDS0?", "What to do with CARDS0?"];
EnglishQuestionDescriptions[QuestionIds.DOCTOR_MODE_MANY] = ["What to do with CARDS0? (NUMBER1 more)", "CARDS0? (NUMBER1 more)"];
EnglishQuestionDescriptions[QuestionIds.HERALD_OVERPAY] = ["You may overpay for Herald.", "You may overpay"];
EnglishQuestionDescriptions[QuestionIds.HERALD_CHOOSE] = ["Put a card from your discard pile on your deck.", "Topdeck a card"];
EnglishQuestionDescriptions[QuestionIds.JOURNEYMAN] = ["Name a card not to draw.", "Name a card"];
EnglishQuestionDescriptions[QuestionIds.MASTERPIECE] = ["You may overpay for Masterpiece.", "You may overpay"];
EnglishQuestionDescriptions[QuestionIds.PLAZA_DELAY] = ["You may discard a Treasure to take a Coin token.", "You may discard a Treasure"];
EnglishQuestionDescriptions[QuestionIds.PLAZA_DISCARD] = ["Discard a Treasure.", "Discard a Treasure"];
EnglishQuestionDescriptions[QuestionIds.STONEMASON_TRASH] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.STONEMASON_GAIN1] = ["Gain 2 cards each costing less than AMOUNT0.", "Gain 1st card cheaper than AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.STONEMASON_GAIN2] = ["Gain another card costing less than AMOUNT0.", "Gain 2nd card cheaper than AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.STONEMASON_OVERPAY] = ["You may overpay for stonemason by gaining an Action up to AMOUNT0.", "Either specify the overpay amount, or a card to overpay for"];
EnglishQuestionDescriptions[QuestionIds.STONEMASON_GAIN_EQUAL] = ["Gain another Action costing exactly AMOUNT0.", "Gain another Action for AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.TAXMAN_TRASH] = ["You may trash a Treasure from your hand.", "You may trash a Treasure"];
EnglishQuestionDescriptions[QuestionIds.TAXMAN_GAIN] = ["Gain a treasure costing up to AMOUNT0 onto your deck.", "Gain a treasure up to AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.BORDER_VILLAGE] = ["Gain a card cheaper than AMOUNT0.", "Gain a card cheaper than AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.CARTOGRAPHER_DISCARD] = ["Discard any number.", "Discard any number"];
EnglishQuestionDescriptions[QuestionIds.CARTOGRAPHER_TOPDECK] = ["Put the remaining cards on top of your deck in any order.", "Topdeck these cards"];
EnglishQuestionDescriptions[QuestionIds.DEVELOP_TRASH] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.DEVELOP_GAIN1] = ["Gain a card onto your deck costing AMOUNT0 or Argument1.", "Gain 1st card for AMOUNT0 or Argument1"];
EnglishQuestionDescriptions[QuestionIds.DEVELOP_GAIN2] = ["Gain a card onto your deck costing AMOUNT0.", "Gain 2nd card for AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.DUCHESS] = ["You may discard CARDS0.", "You may discard CARDS0"];
EnglishQuestionDescriptions[QuestionIds.EMBASSY] = ["Discard 3 cards.", "Discard 3 cards"];
EnglishQuestionDescriptions[QuestionIds.FARMLAND_TRASH] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.FARMLAND_GAIN] = ["Gain a card costing exactly AMOUNT0.", "Gain a card for AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.HAGGLER] = ["Gain a non-victory costing less than AMOUNT0.", "Gain a non-Victory cheaper than AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.ILL_GOTTEN_GAINS] = ["You may gain a Copper to your hand.", "You may gain a Copper"];
EnglishQuestionDescriptions[QuestionIds.INN_DISCARD] = ["Discard 2 cards.", "Discard 2 cards"];
EnglishQuestionDescriptions[QuestionIds.INN_SHUFFLE] = ["Shuffle any number of Actions from your discard pile into your deck.", "Shuffle Actions back in"];
EnglishQuestionDescriptions[QuestionIds.JACK_DISCARD] = ["You may discard CARDS0.", "You may discard CARDS0"];
EnglishQuestionDescriptions[QuestionIds.JACK_TRASH] = ["You may trash a non-Treasure from your hand.", "You may trash a non-Treasure"];
EnglishQuestionDescriptions[QuestionIds.MANDARIN_PLAY] = ["Put a card from your hand onto your deck.", "Topdeck a card"];
EnglishQuestionDescriptions[QuestionIds.MANDARIN_GAIN] = ["Put all Treasures you have in play onto your deck in any order.", "Topdeck your Treasures"];
EnglishQuestionDescriptions[QuestionIds.MARGRAVE] = ["Discard down to 3 cards in hand.", "Discard down to 3 cards"];
EnglishQuestionDescriptions[QuestionIds.NOBLE_BRIGAND] = ["Choose a Treasure to have trashed.", "Choose a Treasure"];
EnglishQuestionDescriptions[QuestionIds.OASIS] = ["Discard a card.", "Discard a card"];
EnglishQuestionDescriptions[QuestionIds.ORACLE_DISCARD] = ["You may discard CARDS0 from PLAYER1.", "Make PLAYER1 discard?"];
EnglishQuestionDescriptions[QuestionIds.ORACLE_TOPDECK] = ["Put the cards on top of your deck in any order.", "Topdeck these cards"];
EnglishQuestionDescriptions[QuestionIds.SCHEME] = ["You may choose a card to scheme", "You may scheme a card"];
EnglishQuestionDescriptions[QuestionIds.SCHEMES] = ["What cards to Scheme", "Scheme up to Argument0 cards"];
EnglishQuestionDescriptions[QuestionIds.SPICE_MERCHANT_TRASH] = ["You may trash a Treasure from your hand.", "You may trash a Treasure"];
EnglishQuestionDescriptions[QuestionIds.SPICE_MERCHANT_MODE] = ["Choose one: +2 Cards and +1 Action; or +1 Buy and +$2.", "Choose how to play Spice Merchant"];
EnglishQuestionDescriptions[QuestionIds.STABLES] = ["You may discard a Treasure.", "You may discard a Treasure"];
EnglishQuestionDescriptions[QuestionIds.TRADER_TRASH] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.BARON] = ["You may discard an Estate.", "You may discard an Estate"];
EnglishQuestionDescriptions[QuestionIds.COURTIER_REVEAL] = ["Reveal a card from your hand.", "Reveal a card"];
EnglishQuestionDescriptions[QuestionIds.COURTIER_MODE] = ["Choose NUMBER0: +1 Action; or +1 Buy; or +$3; or gain a Gold. The choices must be different.", "Choose NUMBER0 options"];
EnglishQuestionDescriptions[QuestionIds.COURTYARD] = ["Put a card from your hand onto your deck.", "Topdeck a card"];
EnglishQuestionDescriptions[QuestionIds.DIPLOMAT] = ["Discard 3 cards.", "Discard 3 cards"];
EnglishQuestionDescriptions[QuestionIds.IRONWORKS] = ["Gain a card costing up to $4.", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.LURKER_MAY_TRASH] = ["You may trash an Action card from the Supply.", "You may trash from the Supply"];
EnglishQuestionDescriptions[QuestionIds.LURKER_TRASH] = ["Trash an Action card from the Supply.", "Trash from the Supply"];
EnglishQuestionDescriptions[QuestionIds.LURKER_MAY_GAIN] = ["You may gain an Action card from the trash.", "You may gain the trash"];
EnglishQuestionDescriptions[QuestionIds.LURKER_GAIN] = ["Gain an Action card from the trash.", "Gain from the trash"];
EnglishQuestionDescriptions[QuestionIds.MASQUERADE_PASS] = ["Pass a card to PLAYER0.", "Pass a card to PLAYER0"];
EnglishQuestionDescriptions[QuestionIds.MASQUERADE_TRASH] = ["You may trash a card from your hand.", "You may trash a card"];
EnglishQuestionDescriptions[QuestionIds.MILL] = ["You may discard 2 cards", "You may discard 2 cards"];
EnglishQuestionDescriptions[QuestionIds.MINING_VILLAGE] = ["You may trash Mining Village.", "You may trash Mining Village"];
EnglishQuestionDescriptions[QuestionIds.MINION] = ["Choose one: +$2 or new hands.", "Choose +$2 or new hands"];
EnglishQuestionDescriptions[QuestionIds.NOBLES] = ["Choose one: +3 Cards; or +2 Actions.", "Choose +3 Cards or +2 Actions"];
EnglishQuestionDescriptions[QuestionIds.PATROL] = ["Put these cards back in any order.", "Topdeck these cards"];
EnglishQuestionDescriptions[QuestionIds.PAWN] = ["Choose how to play Pawn.", "Choose how to play Pawn"];
EnglishQuestionDescriptions[QuestionIds.REPLACE_TRASH] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.REPLACE_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card up to AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.SECRET_PASSAGE_TOPDECK] = ["Put a card onto your empty deck.", "Topdeck a card"];
EnglishQuestionDescriptions[QuestionIds.SECRET_PASSAGE_INSERT] = ["Choose a card to put into your deck.", "Choose a location and a card"];
EnglishQuestionDescriptions[QuestionIds.STEWARD_MODE] = ["Choose one: +2 Cards; or +$2; or trash 2 cards from your hand.", "Choose how to play [Steward]"];
EnglishQuestionDescriptions[QuestionIds.STEWARD_TRASH] = ["Choose 2 cards to trash.", "Choose 2 cards to trash"];
EnglishQuestionDescriptions[QuestionIds.SWINDLER] = ["Choose a card for PLAYER0 to gain.", "Choose a card for PLAYER0"];
EnglishQuestionDescriptions[QuestionIds.TRADING_POST] = ["Trash 2 cards from your hand.", "Trash 2 cards"];
EnglishQuestionDescriptions[QuestionIds.UPGRADE_TRASH] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.UPGRADE_GAIN] = ["Gain a card costing exactly AMOUNT0.", "Gain a card for AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.WISHING_WELL] = ["Guess what card is on top of your deck.", "Guess a card"];
EnglishQuestionDescriptions[QuestionIds.SUMMON] = ["Gain an Action card costing up to $4.", "Summon a card"];
EnglishQuestionDescriptions[QuestionIds.GOVERNOR_MODE] = ["Choose how to play Governor", "Choose how to play Governor"];
EnglishQuestionDescriptions[QuestionIds.GOVERNOR_MAY_TRASH] = ["You may trash a card from your hand.", "You may trash"];
EnglishQuestionDescriptions[QuestionIds.GOVERNOR_GAIN] = ["Gain a card costing exactly Argument0", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.SAUNA_CHAIN] = ["You may play an Avanto without spending an action", "You may play an Avanto"];
EnglishQuestionDescriptions[QuestionIds.SAUNA_MAY_TRASH] = ["You may trash a card with Sauna", "You may trash"];
EnglishQuestionDescriptions[QuestionIds.AVANTO_CHAIN] = ["You may play a Sauna without spending an action", "You may play a Sauna"];

EnglishQuestionDescriptions[QuestionIds.BISHOP_SELF] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.BISHOP_OTHER] = ["You may trash a card from your hand.", "You may trash a card"];
EnglishQuestionDescriptions[QuestionIds.CONTRABAND] = ["Name a card PLAYER0 can't buy this turn.", "Ban a card"];
EnglishQuestionDescriptions[QuestionIds.COUNTING_HOUSE] = ["How many Coppers to put into your hand?", "Take coppers"];
EnglishQuestionDescriptions[QuestionIds.EXPAND_TRASH] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.EXPAND_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card up to AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.FORGE_TRASH] = ["Trash any number of cards from your hand.", "Trash any number"];
EnglishQuestionDescriptions[QuestionIds.FORGE_GAIN] = ["Gain a card costing exactly AMOUNT0.", "Gain a card for AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.GOONS] = ["Discard down to 3 cards in hand.", "Discard down to 3 cards"];
EnglishQuestionDescriptions[QuestionIds.KINGS_COURT] = ["You may play an Action card three times.", "You may play an Action three times"];
EnglishQuestionDescriptions[QuestionIds.LOAN] = ["Discard or trash CARDS0.", "Discard or trash CARDS0"];
EnglishQuestionDescriptions[QuestionIds.MINT] = ["You may reveal a Treasure from your hand. Gain a copy of it.", "You may copy a Treasure"];
EnglishQuestionDescriptions[QuestionIds.MOUNTEBANK] = ["You may discard a Curse.", "You may discard a Curse"];
EnglishQuestionDescriptions[QuestionIds.RABBLE] = ["Put these cards back in any order.", "Topdeck these cards"];
EnglishQuestionDescriptions[QuestionIds.TRADE_ROUTE] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.VAULT_SELF] = ["Discard any number of cards.", "Discard any number"];
EnglishQuestionDescriptions[QuestionIds.VAULT_OTHER] = ["You may discard 2 cards.", "You may discard 2 cards"];
EnglishQuestionDescriptions[QuestionIds.AMBASSADOR_REVEAL] = ["Reveal a card from your hand.", "Reveal a card from your hand"];
EnglishQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_ONE] = ["You may return CARDS0 to the Supply.", "You may return CARDS0"];
EnglishQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_TWO] = ["Return 0, 1 or CARDS0 to the Supply.", "Return 0, 1 or 2"];
EnglishQuestionDescriptions[QuestionIds.EMBARGO] = ["Add an Embargo token to a Supply pile.", "Embargo a pile"];
EnglishQuestionDescriptions[QuestionIds.EXPLORER] = ["You may reveal a Province.", "You may reveal a Province"];
EnglishQuestionDescriptions[QuestionIds.GHOST_SHIP] = ["Put NUMBER0 cards on top of your deck.", "Topdeck NUMBER0 cards"];
EnglishQuestionDescriptions[QuestionIds.HAVEN] = ["Set a card aside.", "Set a card aside"];
EnglishQuestionDescriptions[QuestionIds.ISLAND] = ["Put a card from your hand onto your Island mat.", "Put a card onto your mat"];
EnglishQuestionDescriptions[QuestionIds.LOOKOUT_TRASH] = ["Trash a card.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.LOOKOUT_DISCARD] = ["Discard a card.", "Discard a card"];
EnglishQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_TAKE] = ["You may put CARDS0 into your hand.", "Pick up Argument0?"];
EnglishQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_SET_ASIDE] = ["You may put the top card of your deck onto your mat.", "You may put onto your mat"];
EnglishQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_BOTH] = ["Add a card to your mat or take CARDS0 into your hand.", "Pick up Argument0?"];
EnglishQuestionDescriptions[QuestionIds.NAVIGATOR] = ["Topdeck these 5 cards in any order, or discard them all.", "Topdeck or discard"];
EnglishQuestionDescriptions[QuestionIds.PEARL_DIVER] = ["Put CARDS0 on top or bottom of your deck.", "Put CARDS0 on top or bottom"];
EnglishQuestionDescriptions[QuestionIds.PIRATE_SHIP_MODE] = ["Choose one: +AMOUNT0 or attack.", "+AMOUNT0 or attack"];
EnglishQuestionDescriptions[QuestionIds.PIRATE_SHIP_TRASH] = ["Choose a Treasure to trash.", "Choose a Treasure to trash"];
EnglishQuestionDescriptions[QuestionIds.SALVAGER] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.SMUGGLERS_MAY_GAIN] = ["You may gain a copy.", "You may gain a card"];
EnglishQuestionDescriptions[QuestionIds.SMUGGLERS_GAIN] = ["Gain a copy.", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.WAREHOUSE] = ["Discard 3 cards.", "Discard 3 cards."];
EnglishQuestionDescriptions[QuestionIds.LURKER_COMPLEX] = ["When playing Lurker", "When playing Lurker"];
EnglishQuestionDescriptions[QuestionIds.BLACK_MARKET_COMPLEX] = ["You play Black Market", "You play Black Market"];
EnglishQuestionDescriptions[QuestionIds.BLACK_MARKET_BUY] = ["buy one of the revealed cards.", "You may buy"];
EnglishQuestionDescriptions[QuestionIds.BLACK_MARKET_PLAY] = ["play Treasures from your hand.", "You may play Treasures"];
EnglishQuestionDescriptions[QuestionIds.ENVOY] = ["Choose a card to discard.", "Discard a card"];
EnglishQuestionDescriptions[QuestionIds.PRINCE_MAY_SET_ASIDE] = ["You may set aside a card with ", "You may set a card aside"];
EnglishQuestionDescriptions[QuestionIds.TORTURER_MAY_CURSE] = ["You may gain a Curse.", "You may gain a Curse"];
EnglishQuestionDescriptions[QuestionIds.TORTURER_MAY_DISCARD] = ["You may discard 2 cards.", "You may discard 2 cards"];
EnglishQuestionDescriptions[QuestionIds.TORTURER_DISCARD_OR_CURSE] = ["Either gain a Curse or discard 2 cards.", "Discard 2 cards or gain a Curse"];
EnglishQuestionDescriptions[QuestionIds.INHERITANCE] = ["Choose a card to inherit.", "Choose a card to inherit"];

EnglishQuestionDescriptions[QuestionIds.SHORT_YOU_MAY_REACT] = ["-", "You may react"];
EnglishQuestionDescriptions[QuestionIds.SHORT_WHEN_GAIN] = ["-", "You gain Argument0..."];
EnglishQuestionDescriptions[QuestionIds.SHORT_WHEN_WOULD_GAIN] = ["-", "You would gain Argument0..."];

EnglishQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL_SPECIFIC] = ["-", "Play or call Argument0..."];
EnglishQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL] = ["-", "Play or call Actions"];
EnglishQuestionDescriptions[QuestionIds.SHORT_PLAY] = ["-", "You may play Actions"];
EnglishQuestionDescriptions[QuestionIds.SHORT_CALL_SPECIFIC] = ["-", "You may call Argument0"];
EnglishQuestionDescriptions[QuestionIds.SHORT_CALL] = ["-", "You may call cards"];

EnglishQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_DISCARD] = ["You may discard a Treasure", "You may discard"];
EnglishQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_GAIN] = ["Gain a card costing up to $4.", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.THE_FLAMES_GIFT] = ["You may trash a card from your hand.", "You may trash"];
EnglishQuestionDescriptions[QuestionIds.THE_MOONS_GIFT] = ["You may put a card onto your deck.", "You may topdeck"];
EnglishQuestionDescriptions[QuestionIds.THE_SKYS_GIFT] = ["You may discard 3 cards to gain a Gold.", "You may discard"];
EnglishQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_TOPDECK] = ["put the rest back in any order", "put the rest back"];
EnglishQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_DISCARD] = ["Discard any number", "Discard any number"];
EnglishQuestionDescriptions[QuestionIds.THE_WINDS_GIFT] = ["Discard 2 cards.", "Discard 2 cards"];
EnglishQuestionDescriptions[QuestionIds.FEAR_DISCARD] = ["discard an Action or Treasure", "Discard a card"];
EnglishQuestionDescriptions[QuestionIds.HAUNTING] = ["Topdeck something.", "Topdeck something"];
EnglishQuestionDescriptions[QuestionIds.LOCUSTS] = ["Gain a cheaper card that shares a type.", "Gain a cheaper card"];
EnglishQuestionDescriptions[QuestionIds.POVERTY] = ["Discard down to 3 cards in hand", "Discard down to 3"];
EnglishQuestionDescriptions[QuestionIds.BAT] = ["You may trash up to 2 cards", "You may Trash"];
EnglishQuestionDescriptions[QuestionIds.BLESSED_VILLAGE_MODE] = ["When to receive Argument0?", "When to receive?"];
EnglishQuestionDescriptions[QuestionIds.CEMETERY] = ["You may trash up to 4 cards", "You may Trash"];
EnglishQuestionDescriptions[QuestionIds.CHANGELING_GAIN_COPY] = ["Gain a copy of a card you have in play", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.CHANGELING_REPLACE] = ["", ""];
EnglishQuestionDescriptions[QuestionIds.COBBLER] = ["Gain a card costing up to $4 to your hand.", "Gain to your hand"];
EnglishQuestionDescriptions[QuestionIds.CONCLAVE] = ["You may play an Action", "You may play an Action"];
EnglishQuestionDescriptions[QuestionIds.CRYPT_SET_ASIDE] = ["Set aside any number of Treasures you have in play", "Set aside any number"];
EnglishQuestionDescriptions[QuestionIds.CRYPT_RETURN_TO_HAND] = ["Choose a card to put into your hand.", "Choose a card"];
EnglishQuestionDescriptions[QuestionIds.DEVILS_WORKSHOP] = ["Gain a card costing up to $4.", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.DRUID] = ["Which Boon to receive?", "Which Boon?"];
EnglishQuestionDescriptions[QuestionIds.EXORCIST_TRASH] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.EXORCIST_GAIN] = ["Gain a cheaper Spirit", "Gain a cheaper Spirit"];
EnglishQuestionDescriptions[QuestionIds.FOOL] = ["What Boon next?", "What Boon next?"];
EnglishQuestionDescriptions[QuestionIds.GOAT] = ["You may trash a card", "You may Trash"];
EnglishQuestionDescriptions[QuestionIds.HAUNTED_MIRROR] = ["You may discard an action to get a Ghost", "You may discard"];
EnglishQuestionDescriptions[QuestionIds.IMP] = ["You may play an action you don't have in play", "What to play?"];
EnglishQuestionDescriptions[QuestionIds.NECROMANCER] = ["Play from trash", "Play from trash"];
EnglishQuestionDescriptions[QuestionIds.MONASTERY] = ["You may trash up to AMOUNT0 cards or Coppers in play.", "You may trash up to AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_TOPDECK] = ["Topdeck the rest", "Topdeck the rest"];
EnglishQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_DISCARD] = ["Discard any number", "Discard any number"];
EnglishQuestionDescriptions[QuestionIds.PIXIE_MODE] = ["Trash Pixie to receive twice?", "Trash Pixie?"];
EnglishQuestionDescriptions[QuestionIds.POOKA] = ["You may trash a Treasure other than Cursed Gold", "You may trash"];
EnglishQuestionDescriptions[QuestionIds.RAIDER] = ["Discard a card", "Discard a card"];
EnglishQuestionDescriptions[QuestionIds.SACRED_GROVE_MODE] = ["Also receive Argument0?", "receive Argument0?"];
EnglishQuestionDescriptions[QuestionIds.SECRET_CAVE] = ["You may discard 3 cards for +$3 next turn", "You may discard"];
EnglishQuestionDescriptions[QuestionIds.SHEPHERD] = ["Discard Victory cards for +2 cards each", "You may discard"];
EnglishQuestionDescriptions[QuestionIds.TRAGIC_HERO] = ["Gain a Treasure", "Gain a Treasure"];
EnglishQuestionDescriptions[QuestionIds.VAMPIRE] = ["Gain a non-Vampire up to 5", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.WISH] = ["Gain a card to your hand", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.ZOMBIE_APPRENTICE] = ["You may trash an Action", "You may trash"];
EnglishQuestionDescriptions[QuestionIds.ZOMBIE_MASON] = ["You may gain a card", "You may gain a card"];
EnglishQuestionDescriptions[QuestionIds.ZOMBIE_SPY] = ["You may Discard", "You may Discard"];
EnglishQuestionDescriptions[QuestionIds.WHAT_NIGHT_TO_PLAY] = ["You may play a Night card", "What Night to play?"];
EnglishQuestionDescriptions[QuestionIds.LOST_IN_THE_WOODS] = ["You may discard a card to receive a Boon", "Discard for a Boon?"];
EnglishQuestionDescriptions[QuestionIds.START_GAME] = ["Inspect your starting cards", "Your starting cards:"];
EnglishQuestionDescriptions[QuestionIds.DISMANTLE_TRASH] = ["Trash a card from your hand", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.DISMANTLE_GAIN] = ["Gain a card cheaper than AMOUNT0.", "Gain a card cheaper than AMOUNT0"];

EnglishQuestionDescriptions[QuestionIds.DUCAT] = ["You may trash a Copper.", "You may trash a Copper"];
EnglishQuestionDescriptions[QuestionIds.IMPROVE_TRASH] = ["You may trash an Action in play that would be discarded", "You may improve"];
EnglishQuestionDescriptions[QuestionIds.IMPROVE_GAIN] = ["Gain a card costing exactly AMOUNT0.", "Gain a card for AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.HIDEOUT] = ["Trash a card. If it's a Victory card, gain a Curse", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.MOUNTAIN_VILLAGE] = ["Put a card from your discard into your hand", "Put into your hand"];
EnglishQuestionDescriptions[QuestionIds.PRIEST] = ["Trash a card from your hand", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.RESEARCH_TRASH] = ["Trash a card from your hand", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.OLD_WITCH] = ["You may trash a Curse from your hand", "You may trash"];
EnglishQuestionDescriptions[QuestionIds.RECRUITER] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.SCEPTER_COMPLEX] = ["When playing Scepter", "Replay or Coins?"];
EnglishQuestionDescriptions[QuestionIds.SCEPTER_MODE] = ["get +$2", "add Coins"];
EnglishQuestionDescriptions[QuestionIds.SCEPTER_PLAY] = ["replay an Action still in play", "Replay an Action"];
EnglishQuestionDescriptions[QuestionIds.SCULPTOR] = ["Gain a card costing up to $4 to your hand.", "Gain to your hand"];
EnglishQuestionDescriptions[QuestionIds.SEER] = ["Put them back onto your deck in any order", "Topdeck in any order"];
EnglishQuestionDescriptions[QuestionIds.VILLAIN] = ["Discard a card costing 2 or more.", "Discard a card"];
EnglishQuestionDescriptions[QuestionIds.TREASURER_COMPLEX] = ["When playing Treasurer", "When playing Treasurer"];
EnglishQuestionDescriptions[QuestionIds.TREASURER_TRASH] = ["trash a Treasure", "trash a Treasure"];
EnglishQuestionDescriptions[QuestionIds.TREASURER_GAIN] = ["gain a Treasure to your hand", "gain a Treasure"];
EnglishQuestionDescriptions[QuestionIds.TREASURER_KEY] = ["take the Key", "take the Key"];
EnglishQuestionDescriptions[QuestionIds.TREASURER_FAIL] = ["do nothing", "do nothing"];
EnglishQuestionDescriptions[QuestionIds.BORDER_GUARD_PUT_IN_HAND] = ["Put one of the revealed cards into your hand", "Put into your hand"];
EnglishQuestionDescriptions[QuestionIds.BORDER_GUARD_MODE] = ["What Artifact do you want to take?", "Horn or Lantern?"];
EnglishQuestionDescriptions[QuestionIds.HORN_TOPDECK] = ["Which [Border Guard] to topdeck?", "Which [Border Guard] to topdeck?"];
EnglishQuestionDescriptions[QuestionIds.CATHEDRAL] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.CITY_GATE] = ["Put a card from your hand onto your deck.", "Topdeck a card."];
EnglishQuestionDescriptions[QuestionIds.PAGEANT] = ["you may pay $1 for +1 Coffers.", "$1 for +1 Coffers?"];
EnglishQuestionDescriptions[QuestionIds.SEWERS] = ["You may trash up to AMOUNT0 cards from your hand. (Sewers)", "You may trash up to AMOUNT0 (Sewers)"];
EnglishQuestionDescriptions[QuestionIds.SILOS] = ["Discard Coppers for +1 card each", "You may discard"];
EnglishQuestionDescriptions[QuestionIds.CROP_ROTATION] = ["Discard a Victory card for +2 cards?", "You may discard"];
EnglishQuestionDescriptions[QuestionIds.SINISTER_PLOT] = ["Draw AMOUNT0 cards or add a token?", "Draw AMOUNT0 or add a token?"];
EnglishQuestionDescriptions[QuestionIds.STAR_CHART] = ["Select a card to go on top when shuffling", "Select a card to go on top"];
EnglishQuestionDescriptions[QuestionIds.CAPTAIN] = ["Choose an Action from the supply up to $4 to play", "Choose what to play"];
EnglishQuestionDescriptions[QuestionIds.CHURCH_SET_ASIDE] = ["Set aside up to 3 cards", "Set aside up to 3 cards"];
EnglishQuestionDescriptions[QuestionIds.CHURCH_TRASH] = ["You may trash a card from your hand.", "You may trash a card"];
EnglishQuestionDescriptions[QuestionIds.CAMEL_TRAIN] = ["Exile a card from the supply.", "Exile a card"];
EnglishQuestionDescriptions[QuestionIds.GOATHERD] = ["You may trash a card from your hand.", "You may trash a card"];
EnglishQuestionDescriptions[QuestionIds.SNOWY_VILLAGE] = ["You may use villagers while you can", "Use villagers while you can?"];
EnglishQuestionDescriptions[QuestionIds.BOUNTY_HUNTER] = ["Exile a card from your hand", "Exile a card"];
EnglishQuestionDescriptions[QuestionIds.CARDINAL_EXILE] = ["Exile a card", "Exile a card"];
EnglishQuestionDescriptions[QuestionIds.GROOM] = ["Gain a card costing up to AMOUNT0.", "Gain a card up to AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.HOSTELRY] = ["Discard for Horses", "Discard for Horses"];
EnglishQuestionDescriptions[QuestionIds.VILLAGE_GREEN] = ["Now or next turn?", "Now or next turn?"];
EnglishQuestionDescriptions[QuestionIds.BARGE] = ["Now or next turn?", "Now or next turn?"];
EnglishQuestionDescriptions[QuestionIds.DISPLACE_EXILE] = ["Exile a card from your hand", "Exile a card"];
EnglishQuestionDescriptions[QuestionIds.DISPLACE_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card up to AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.FALCONER] = ["Gain a card to your hand", "Gain a card to your hand"];
EnglishQuestionDescriptions[QuestionIds.HUNTING_LODGE] = ["Discard for +5 Cards?", "Discard for +5 Cards?"];
EnglishQuestionDescriptions[QuestionIds.MASTERMIND] = ["You may play an Action card three times.", "You may play an Action three times"];
EnglishQuestionDescriptions[QuestionIds.SANCTUARY] = ["You may Exile a Card from your hand", "You may Exile a Card"];
EnglishQuestionDescriptions[QuestionIds.WAYFARER] = ["You may gain a Silver", "You may gain a Silver"];
EnglishQuestionDescriptions[QuestionIds.DELAY] = ["You may set aside an Action", "You may set aside an Action"];
EnglishQuestionDescriptions[QuestionIds.DESPERATION] = ["You may gain a Curse", "You may gain a Curse"];
EnglishQuestionDescriptions[QuestionIds.GAMBLE] = ["Play Argument0?", "Play Argument0?"];
EnglishQuestionDescriptions[QuestionIds.PURSUE] = ["What card to keep on top?", "What card to keep on top?"];
EnglishQuestionDescriptions[QuestionIds.TOIL] = ["You may play a card", "You may play a card"];
EnglishQuestionDescriptions[QuestionIds.ENHANCE_TRASH] = ["You may trash a non-Victory card", "You may trash a non-Victory card"];
EnglishQuestionDescriptions[QuestionIds.ENHANCE_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card up to AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.MARCH] = ["You may play a card from your discard pile", "You may play a card from discard"];
EnglishQuestionDescriptions[QuestionIds.TRANSPORT_MAY_EXILE] = ["You may exile an Action card", "You may exile an Action card"];
EnglishQuestionDescriptions[QuestionIds.TRANSPORT_EXILE] = ["Exile an Action card", "Exile an Action card"];
EnglishQuestionDescriptions[QuestionIds.TRANSPORT_MAY_TOPDECK] = ["You may topdeck an Exiled Action", "You may topdeck an Exiled Action"];
EnglishQuestionDescriptions[QuestionIds.TRANSPORT_TOPDECK] = ["Topdeck an Exiled Action", "Topdeck an Exiled Action"];
EnglishQuestionDescriptions[QuestionIds.TRANSPORT_COMPLEX] = ["Exile from the Supply; or Topdeck from Exile", "Exile from the Supply; or Topdeck from Exile"];
EnglishQuestionDescriptions[QuestionIds.BANISH] = ["Exile cards with the same name", "Exile cards with the same name"];
EnglishQuestionDescriptions[QuestionIds.BARGAIN] = ["Gain a non-Victory up to $5", "Gain a non-Victory up to $5"];
EnglishQuestionDescriptions[QuestionIds.INVEST] = ["Exile an Action card to Invest in", "Exile an Action card to Invest in"];
EnglishQuestionDescriptions[QuestionIds.DEMAND] = ["Gain a card up to $4 onto your deck", "Gain a card up to $4 onto your deck"];
EnglishQuestionDescriptions[QuestionIds.POPULATE] = ["What card to gain next?", "What card to gain next?"];
EnglishQuestionDescriptions[QuestionIds.RECONSIDER_WAY] = ["Reconsider the Way you play Argument0", "Reconsider the Way you play Argument0"];
EnglishQuestionDescriptions[QuestionIds.CONSIDER_WAY] = ["Consider the Way you play Argument0", "Consider the Way you play Argument0"];
EnglishQuestionDescriptions[QuestionIds.WAY_OF_THE_GOAT] = ["Trash a Card from your hand.", "Trash a Card from your hand."];
EnglishQuestionDescriptions[QuestionIds.WAY_OF_THE_RAT] = ["Discard a Treasure to gain a copy", "Discard a Treasure to gain a copy"];
EnglishQuestionDescriptions[QuestionIds.SCRAP_TRASH] = ["Trash a card from your hand.", "Trash a card from your hand."];
EnglishQuestionDescriptions[QuestionIds.SCRAP_MODES] = ["Choose Argument0 effects", "Choose Argument0 effects"];
EnglishQuestionDescriptions[QuestionIds.ANIMAL_FAIR_TRASH] = ["Trash an Action from your hand", "Trash an Action from your hand"];
EnglishQuestionDescriptions[QuestionIds.ANIMAL_FAIR_MAY_TRASH] = ["You may trash an Action from your hand", "You may trash an Action from your hand"];
EnglishQuestionDescriptions[QuestionIds.BUTTERFLY_RETURN] = ["Return Argument0 to its pile?", "Return Argument0 to its pile?"];
EnglishQuestionDescriptions[QuestionIds.BUTTERFLY_GAIN] = EnglishQuestionDescriptions[QuestionIds.UPGRADE_GAIN];
EnglishQuestionDescriptions[QuestionIds.BLOCKADE] = ["Gain a card costing up to $4.", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.PIRATE] = ["Gain a Treasure to your hand.", "Gain to your hand"];
EnglishQuestionDescriptions[QuestionIds.SAILOR] = ["You may trash a card from your hand.", "You may trash a card"];
EnglishQuestionDescriptions[QuestionIds.TIDE_POOLS] = EnglishQuestionDescriptions[QuestionIds.DUNGEON];
EnglishQuestionDescriptions[QuestionIds.SEA_WITCH] = EnglishQuestionDescriptions[QuestionIds.DUNGEON];
EnglishQuestionDescriptions[QuestionIds.TIARA_MODE] = ["Choose how to play Tiara", "Choose how to play Tiara"];
EnglishQuestionDescriptions[QuestionIds.TIARA_PLAY] = ["What Treasure to play twice", "What Treasure to play twice"];
EnglishQuestionDescriptions[QuestionIds.CRYSTAL_BALL] = ["What to do with Argument0.", "What to do with Argument0"];
EnglishQuestionDescriptions[QuestionIds.WAR_CHEST_PROHIBIT] = ["Ban a card for Player0", "Ban a card"];
EnglishQuestionDescriptions[QuestionIds.WAR_CHEST_GAIN] = ["Gain a card.", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.ANVIL_DISCARD] = EnglishQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_DISCARD];
EnglishQuestionDescriptions[QuestionIds.ANVIL_GAIN] = EnglishQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_GAIN];
EnglishQuestionDescriptions[QuestionIds.CLERK] = ["Topdeck a Card", "Topdeck a Card"];
EnglishQuestionDescriptions[QuestionIds.INVESTMENT_TRASH] = EnglishQuestionDescriptions[QuestionIds.TRADE_ROUTE];
EnglishQuestionDescriptions[QuestionIds.INVESTMENT_MODE] = ["Choose how to play Investment", "Choose how to play Investment"];
EnglishQuestionDescriptions[QuestionIds.WHEELWRIGHT_DISCARD] = ["You may discard to Wheelwright", "Discard to gain"];
EnglishQuestionDescriptions[QuestionIds.WHEELWRIGHT_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card up to AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.BERSERKER_DISCARD] = EnglishQuestionDescriptions[QuestionIds.MILITIA];
EnglishQuestionDescriptions[QuestionIds.BERSERKER_GAIN] = ["Gain a cheaper card", "Gain a cheaper card"];
EnglishQuestionDescriptions[QuestionIds.WEAVER] = ["Gain a Card or 2 Silvers", "Gain a Card or 2 Silvers"];
EnglishQuestionDescriptions[QuestionIds.SOUK] = ["Trash up to 2", "Trash up to 2"];
EnglishQuestionDescriptions[QuestionIds.WITCHS_HUT] = ["Discard 2 Cards revealed", "Discard 2 Cards revealed"];

// new Promo
EnglishQuestionDescriptions[QuestionIds.MARCHLAND] = ["", "Discard for +$1 each"];

// Allies
EnglishQuestionDescriptions[QuestionIds.TOWN_MODE] = ["Choose how to play Town.", "How to play Town"];
EnglishQuestionDescriptions[QuestionIds.MODIFY_TRASH] = ["Trash a card from your hand.", "Trash a card"];
EnglishQuestionDescriptions[QuestionIds.MODIFY_MODE] = ["+1 Card +1Action or gain up to $AMOUNT0", "How to play Modify"];
EnglishQuestionDescriptions[QuestionIds.MODIFY_GAIN] = ["Gain a card costing up to $AMOUNT0.", "Gain a card"];
EnglishQuestionDescriptions[QuestionIds.SWAP_RETURN] = ["You may return an Action card to the supply.", "You may return a card"];
EnglishQuestionDescriptions[QuestionIds.SWAP_GAIN] = ["Gain an Action other then ARGUMENT0 to your hand.", "Gain an Action"];
EnglishQuestionDescriptions[QuestionIds.SKIRMISHER] = ["Discard down to 3 cards in hand.", "Discard down to 3"];
EnglishQuestionDescriptions[QuestionIds.WOODWORKERS_GUILD_TRASH] = ["Trash an Action", "Trash an Action"];
EnglishQuestionDescriptions[QuestionIds.WOODWORKERS_GUILD_GAIN] = ["Gain an Action", "Gain an Action"];
EnglishQuestionDescriptions[QuestionIds.CRAFTERS_GUILD] = ["Spend 2 Favors to gain a card?", "Spend 2 Favors to gain a card?"];
EnglishQuestionDescriptions[QuestionIds.CAVE_DWELLERS] = ["Spend a Favor and discard to draw?", "Spend a Favor and discard to draw?"];
EnglishQuestionDescriptions[QuestionIds.DESERT_GUIDES] = ["Keep this hand?", "Keep this hand?"];
EnglishQuestionDescriptions[QuestionIds.BAND_OF_NOMADS] = ["Spend more Favors for Buys?", "Spend more Favors for Buys?"];
EnglishQuestionDescriptions[QuestionIds.SYCOPHANT] = ["Discard 3 cards", "Discard 3 cards"];
EnglishQuestionDescriptions[QuestionIds.IMPORTER] = ["Gain a card costing up to 5", "Gain a card costing up to $5"];
EnglishQuestionDescriptions[QuestionIds.CONTRACT] = ["You may play an action next turn", "You may play an action next turn"];
EnglishQuestionDescriptions[QuestionIds.BAUBLE] = ["Choose how to play Bauble.", "Choose how to play Bauble"];
EnglishQuestionDescriptions[QuestionIds.BROKER_TRASH] = ["What card to trash?", "What card to trash?"];
EnglishQuestionDescriptions[QuestionIds.BROKER_MODE] = ["What bonus to receive?", "What bonus to receive?"];
EnglishQuestionDescriptions[QuestionIds.MARKET_TOWNS_FIRST] = ["What action to play?", "What action to play?"];
EnglishQuestionDescriptions[QuestionIds.MARKET_TOWNS_AGAIN] = ["Play another action for a Favor?", "Play another action for a Favor?"];
EnglishQuestionDescriptions[QuestionIds.BATTLE_PLAN_REVEAL] = ["Reveal an Attack for +1 Card?", "Reveal an Attack for +1 Card?"];
EnglishQuestionDescriptions[QuestionIds.BATTLE_PLAN_ROTATE] = ["Rotate a supply pile?", "Rotate a supply pile?"];
EnglishQuestionDescriptions[QuestionIds.BARBARIAN] = ["Gain a cheaper card that shares a type.", "Gain a cheaper card"];
EnglishQuestionDescriptions[QuestionIds.INNKEEPER_MODE] = ["How to play Innkeeper?", "How to play Innkeeper?"];
EnglishQuestionDescriptions[QuestionIds.INNKEEPER_DISCARD] = ["Discard Argument0 Cards", "Discard Argument0 Cards"];
EnglishQuestionDescriptions[QuestionIds.CAPITAL_CITY_MODE] = ["Discard or pay?", "Discard or pay?"];
EnglishQuestionDescriptions[QuestionIds.CAPITAL_CITY_DISCARD] = ["Discard 2 Cards", "Discard 2 Cards"];
EnglishQuestionDescriptions[QuestionIds.SPECIALIST_PLAY] = ["What card to play?", "What card to play?"];
EnglishQuestionDescriptions[QuestionIds.SPECIALIST_MODE] = ["Play Argument0 again or copy?", "Play Argument0 again or copy?"];
EnglishQuestionDescriptions[QuestionIds.GANG_OF_PICKPOCKETS] = ["Discard or spend a Favor?", "Discard or spend a Favor?"];
EnglishQuestionDescriptions[QuestionIds.COASTAL_HAVEN] = ["What cards to keep in hand?", "What cards to keep in hand?"];
EnglishQuestionDescriptions[QuestionIds.CARPENTER_GAIN] = EnglishQuestionDescriptions[QuestionIds.WORKSHOP];
EnglishQuestionDescriptions[QuestionIds.CARPENTER_TRASH] = EnglishQuestionDescriptions[QuestionIds.REMODEL_TRASH];
EnglishQuestionDescriptions[QuestionIds.CARPENTER_GAIN_UP_TO] = EnglishQuestionDescriptions[QuestionIds.REMODEL_GAIN];
EnglishQuestionDescriptions[QuestionIds.MAY_ROTATE_FORTS] = ["You may rotate the Forts", "You may rotate the Forts"];
EnglishQuestionDescriptions[QuestionIds.ROYAL_GALLEY] = ["You may play an Action", "You may play an Action"];
EnglishQuestionDescriptions[QuestionIds.HILL_FORT_GAIN] = EnglishQuestionDescriptions[QuestionIds.WORKSHOP];
EnglishQuestionDescriptions[QuestionIds.HILL_FORT_MODE] = ["Choose how to play Hill Fort", "Choose how to play Hill Fort"];
EnglishQuestionDescriptions[QuestionIds.STRONGHOLD_MODE] = ["Choose how to play Stronghold", "Choose how to play Stronghold"];
EnglishQuestionDescriptions[QuestionIds.MAY_ROTATE_ODYSSEYS] = ["You may rotate the Odysseys", "You may rotate the Odysseys"];
EnglishQuestionDescriptions[QuestionIds.OLD_MAP_DISCARD] = ["Discard a Card", "Discard a Card"];
EnglishQuestionDescriptions[QuestionIds.SUNKEN_TREASURE] = ["Gain an Action you don't have in play", "Gain an Action you don't have in play"];
EnglishQuestionDescriptions[QuestionIds.PEACEFUL_CULT] = ["You may trash for Favors", "You may trash for Favors"];
EnglishQuestionDescriptions[QuestionIds.ORDER_OF_ASTROLOGERS] = ["You may topdeck for Favors", "You may topdeck for Favors"];
EnglishQuestionDescriptions[QuestionIds.STAR_CHART_ASTROLOGERS_COMBINED] = ["You may topdeck for Favors (one free)", "You may topdeck for Favors (one free)"];
EnglishQuestionDescriptions[QuestionIds.ORDER_OF_MASONS] = ["You may pick 2 cards per Favor to put into your discard", "You may pick 2 cards per Favor to put into your discard"];
EnglishQuestionDescriptions[QuestionIds.ARCHER_HIDE] = ["Choose a Card not to reveal", "Choose a Card not to reveal"];
EnglishQuestionDescriptions[QuestionIds.ARCHER_DISCARD] = EnglishQuestionDescriptions[QuestionIds.PILLAGE];
EnglishQuestionDescriptions[QuestionIds.MARQUIS] = ["Discard down to 10", "Discard down to 10"];
EnglishQuestionDescriptions[QuestionIds.HERB_GATHERER_PLAY] = ["What Treasure to play?", "What Treasure to play?"];
EnglishQuestionDescriptions[QuestionIds.MAY_ROTATE_AUGURS] = ["You may rotate the Augurs", "You may rotate the Augurs"];
EnglishQuestionDescriptions[QuestionIds.ACOLYTE] = ["Trash to gain a Gold?", "Trash to gain a Gold?"];
EnglishQuestionDescriptions[QuestionIds.ACOLYTE_SELF] = ["Trash to gain an Augur?", "Trash to gain an Augur?"];
EnglishQuestionDescriptions[QuestionIds.SORCERESS] = ["Guess a Card", "Guess a Card"];
EnglishQuestionDescriptions[QuestionIds.SIBYL_TOPDECK] = ["Put a card from your hand onto your deck.", "Topdeck a card"];
EnglishQuestionDescriptions[QuestionIds.SIBYL_BOTTOMDECK] = ["Put a card from your hand on the bottom of your deck.", "Bottomdeck a card"];
EnglishQuestionDescriptions[QuestionIds.MAY_ROTATE_WIZARDS] = ["You may rotate the Wizards", "You may rotate the Wizards"];
EnglishQuestionDescriptions[QuestionIds.STUDENT] = ["Trash a Card", "Trash a Card"];
EnglishQuestionDescriptions[QuestionIds.CONJURER] = EnglishQuestionDescriptions[QuestionIds.WORKSHOP];
EnglishQuestionDescriptions[QuestionIds.SORCERER] = ["Guess a Card", "Guess a Card"];
EnglishQuestionDescriptions[QuestionIds.LICH] = ["Gain a Card from the Trash", "Gain a Card from the Trash"];
EnglishQuestionDescriptions[QuestionIds.MAY_ROTATE_TOWNSFOLK] = ["You may rotate the Townsfolk", "You may rotate the Townsfolk"];
EnglishQuestionDescriptions[QuestionIds.TOWN_CRIER] = ["How to play Town Crier?", "How to play Town Crier?"];
EnglishQuestionDescriptions[QuestionIds.BLACKSMITH] = ["How to play Blacksmith?", "How to play Blacksmith?"];
EnglishQuestionDescriptions[QuestionIds.MILLER] = ["Put a card in hand.", "Put a card in hand."];
EnglishQuestionDescriptions[QuestionIds.ELDER] = ["You may play a card", "You may play a card"];
EnglishQuestionDescriptions[QuestionIds.SENTINEL_TRASH] = ["Trash up to two cards", "Trash up to two cards"];
EnglishQuestionDescriptions[QuestionIds.SENTINEL_TOPDECK] = EnglishQuestionDescriptions[QuestionIds.APOTHECARY];
EnglishQuestionDescriptions[QuestionIds.COURIER] = ["You may play a card", "You may play a card"];
EnglishQuestionDescriptions[QuestionIds.HUNTER_ACTION] = ["Put an Action into your hand", "Put an Action into your hand"];
EnglishQuestionDescriptions[QuestionIds.HUNTER_TREASURE] = ["Put a Treasure into your hand", "Put a Treasure into your hand"];
EnglishQuestionDescriptions[QuestionIds.HUNTER_VICTORY] = ["Put a Victory card into your hand", "Put a Victory card into your hand"];
EnglishQuestionDescriptions[QuestionIds.LURKER_MODE] = ["Choose how to play Lurker", "Choose how to play Lurker"];
EnglishQuestionDescriptions[QuestionIds.GRAVEROBBER_MODE] = ["Choose how to play Graverobber", "Choose how to play Graverobber"];
EnglishQuestionDescriptions[QuestionIds.TREASURER_MODE] = ["Choose how to play Treasurer", "Choose how to play Treasurer"];
EnglishQuestionDescriptions[QuestionIds.ARCHITECTS_GUILD] = ["What to gain", "What to gain"];
EnglishQuestionDescriptions[QuestionIds.FAMILY_OF_INVENTORS] = ["What pile to make cheaper", "What pile to make cheaper"];
EnglishQuestionDescriptions[QuestionIds.FOREST_DWELLERS_DISCARD] = ["Discard any number", "Discard any number"];
EnglishQuestionDescriptions[QuestionIds.FOREST_DWELLERS_TOPDECK] = ["Topdeck in some order", "Topdeck in some order"];
EnglishQuestionDescriptions[QuestionIds.USE_FAVOR_WHEN_SHUFFLING] = ["Use a Favor when shuffling?", "Use a favor when shuffling?"];
EnglishQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS] = ["You may spend extra coffers", "You may spend extra coffers"];
EnglishQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_BASILICA] = ["You may spend extra coffers for Basilica", "You may spend extra coffers for Basilica"];
EnglishQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_SWASHBUCKLER] = ["You may spend extra coffers to not get the Treasure Chest", "You may spend extra coffers to not get the Treasure Chest"];
EnglishQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_FORTUNE] = ["You may spend extra coffers before doubling your Coins", "You may spend extra coffers before doubling your Coins"];
EnglishQuestionDescriptions[QuestionIds.STONEMASON_OVERPAY_AMOUNT] = ["You may overpay for stonemason by gaining an Action up to AMOUNT0.", "Specify the overpay amount."];

//Plunder
EnglishQuestionDescriptions[QuestionIds.CAGE_SET_ASIDE] = ["You may set aside up to 4 cards.", "You may set aside up to 4 cards."];
EnglishQuestionDescriptions[QuestionIds.GROTTO_SET_ASIDE] = ["You may set aside up to 4 cards.", "You may set aside up to 4 cards."];
EnglishQuestionDescriptions[QuestionIds.SHAMAN_GAIN] = ["Gain a card costing up to 6 from the trash.", "Gain a card costing up to 6 from the trash."];
EnglishQuestionDescriptions[QuestionIds.SHAMAN_TRASH] = ["You may trash a card from your hand.", "You may trash a card from your hand."];
EnglishQuestionDescriptions[QuestionIds.SECLUDED_SHRINE] = ["You may trash up to 2 cards from your hand.", "You may trash up to 2 cards from your hand."];
EnglishQuestionDescriptions[QuestionIds.SIREN] = ["You may trash an Action.", "You may trash an Action."];
EnglishQuestionDescriptions[QuestionIds.CABIN_BOY_MODE] = ["Choose one: +2 Coins, or trash [Cabin Boy] to gain a Duration.", "Choose one: +2 Coins, or trash [Cabin Boy] to gain a Duration."];
EnglishQuestionDescriptions[QuestionIds.CABIN_BOY_GAIN] = ["Gain a Duration card.", "Gain a Duration card."];
EnglishQuestionDescriptions[QuestionIds.CRUCIBLE] = ["Trash a card from your hand.", "Trash a card from your hand."];
EnglishQuestionDescriptions[QuestionIds.FORTUNE_HUNTER_PLAY] = ["Play a revealed treasure.", "Play a revealed treasure."];
EnglishQuestionDescriptions[QuestionIds.FORTUNE_HUNTER_TOPDECK] = ["Topdeck the remaining cards.", "Topdeck the remaining cards."];
EnglishQuestionDescriptions[QuestionIds.GONDOLA_MODE] = ["+2 Coins now or later?", "+2 Coins now or later?"];
EnglishQuestionDescriptions[QuestionIds.GONDOLA_PLAY] = ["You may play an Action card from your hand.", "You may play an Action card from your hand."];
EnglishQuestionDescriptions[QuestionIds.MAPMAKER] = ["Put 2 cards into your hand.", "Put 2 cards into your hand."];
EnglishQuestionDescriptions[QuestionIds.MAROON] = ["Trash a card.", "Trash a card."];
EnglishQuestionDescriptions[QuestionIds.ROPE_TRASH] = ["You may trash a card from your hand.", "You may trash a card from your hand."];
EnglishQuestionDescriptions[QuestionIds.TOOLS] = ["Gain a copy of a card somebody has in play.", "Gain a copy of a card somebody has in play."];
EnglishQuestionDescriptions[QuestionIds.CUTTHROAT_DISCARD] = ["Discard down to 3 cards in hand.", "Discard down to 3 cards in hand."];
EnglishQuestionDescriptions[QuestionIds.ENLARGE_TRASH] = ["Trash a card.", "Trash a card."];
EnglishQuestionDescriptions[QuestionIds.ENLARGE_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card costing up to AMOUNT0"];
EnglishQuestionDescriptions[QuestionIds.FIGURINE] = ["You may discard an Action for +1 Coin and +1 Buy.", "You may discard an Action for +1 Coin and +1 Buy."];
EnglishQuestionDescriptions[QuestionIds.FIRST_MATE] = ["You may play a card.", "You may play a card."];
EnglishQuestionDescriptions[QuestionIds.FRIGATE] = ["Discard down to 4 cards in hand.", "Discard down to 4 cards in hand."];
EnglishQuestionDescriptions[QuestionIds.PICKAXE] = ["Trash a card from your hand.", "Trash a card from your hand."];
EnglishQuestionDescriptions[QuestionIds.PILGRIM_TOPDECK] = ["Put a card from your hand onto your deck.", "Put a card from your hand onto your deck."];
EnglishQuestionDescriptions[QuestionIds.QUARTERMASTER_MODE] = ["Gain a card costing up to 4, or take one of: Argument0.", "Gain a card costing up to 4, or take one of: Argument0."];
EnglishQuestionDescriptions[QuestionIds.QUARTERMASTER_GAIN] = ["Gain a card costing up to 4.", "Gain a card costing up to 4."];
EnglishQuestionDescriptions[QuestionIds.QUARTERMASTER_TAKE] = ["Put a card into your hand.", "Put a card into your hand."];
EnglishQuestionDescriptions[QuestionIds.TRICKSTER] = ["You may set aside a treasure from play.", "You may set aside a treasure from play."];
EnglishQuestionDescriptions[QuestionIds.SILVER_MINE] = ["Gain a treasure into your hand.", "Gain a treasure into your hand."];
EnglishQuestionDescriptions[QuestionIds.KINGS_CACHE] = ["You may play a Treasure 3 times.", "You may play a Treasure 3 times."];
EnglishQuestionDescriptions[QuestionIds.BURY] = ["Place a card on the bottom of your deck.", "Place a card on the bottom of your deck."];
EnglishQuestionDescriptions[QuestionIds.PERIL] = ["You may trash an action to gain a Loot.", "You may trash an action to gain a Loot."];
EnglishQuestionDescriptions[QuestionIds.PREPARE] = ["Play a set-aside card.", "Play a set-aside card."];
EnglishQuestionDescriptions[QuestionIds.FORAY] = ["Discard 3 cards.", "Discard 3 cards."];
EnglishQuestionDescriptions[QuestionIds.SCROUNGE_COMPLEX] = ["When playing Scrounge,", "When playing Scrounge,"];
EnglishQuestionDescriptions[QuestionIds.SCROUNGE_TRASH] = ["Trash a card from your hand.", "Trash a card from your hand."];
EnglishQuestionDescriptions[QuestionIds.SCROUNGE_MAY_TRASH] = ["You may trash a card from your hand.", "You may trash a card from your hand."];
EnglishQuestionDescriptions[QuestionIds.SCROUNGE_GAIN_ESTATE] = ["Gain an Estate.", "Gain an Estate."];
EnglishQuestionDescriptions[QuestionIds.SCROUNGE_MAY_GAIN_ESTATE] = ["You may gain an Estate.", "You may gain an Estate."];
EnglishQuestionDescriptions[QuestionIds.SCROUNGE_GAIN] = ["Gain a card costing up to 5.", "Gain a card costing up to 5."];
EnglishQuestionDescriptions[QuestionIds.MAELSTROM_TRASH] = ["Trash a card.", "Trash a card."];
EnglishQuestionDescriptions[QuestionIds.MAELSTROM_OPPONENT_TRASH] = ["Trash Argument0 cards.", "Trash Argument0 cards."];
EnglishQuestionDescriptions[QuestionIds.INVASION_PLAY] = ["You may play an Attack.", "You may play an Attack."];
EnglishQuestionDescriptions[QuestionIds.INVASION_GAIN] = ["Gain an Action.", "Gain an Action."];
EnglishQuestionDescriptions[QuestionIds.PROSPER] = ["Gain a differently-named Treasure.", "Gain a differently-named Treasure."];
EnglishQuestionDescriptions[QuestionIds.AMPHORA_MODE] = ["+1 Buy, +3 Coins now or next turn?", "+1 Buy, +3 Coins now or next turn?"];
EnglishQuestionDescriptions[QuestionIds.HAMMER] = ["Gain a card costing up to 4.", "Gain a card costing up to 4."];
EnglishQuestionDescriptions[QuestionIds.ORB] = ["Play an Action or Treasure.", "Play an Action or Treasure."];
EnglishQuestionDescriptions[QuestionIds.PRIZE_GOAT] = ["You may trash a card.", "You may trash a card."];
EnglishQuestionDescriptions[QuestionIds.PUZZLE_BOX] = ["Set aside a card from your hand, and put it into your hand at end of turn (after drawing).", "Set a card aside"];
EnglishQuestionDescriptions[QuestionIds.SEXTANT_DISCARD] = ["Discard any number of cards.", "Discard any number of cards."];
EnglishQuestionDescriptions[QuestionIds.SEXTANT_TOPDECK] = ["Put the remaining cards back in any order.", "Put the remaining cards back in any order."];
EnglishQuestionDescriptions[QuestionIds.SPELL_SCROLL_GAIN] = ["Gain a card costing less than AMOUNT0.", "Gain a card costing less than AMOUNT0."];
EnglishQuestionDescriptions[QuestionIds.SPELL_SCROLL_PLAY] = ["Play Argument0?", "Play Argument0?"];
EnglishQuestionDescriptions[QuestionIds.STAFF] = ["You may play an Action card.", "You may play an Action card."];
EnglishQuestionDescriptions[QuestionIds.SWORD] = ["Discard down to 4 cards.", "Discard down to 4 cards."];
EnglishQuestionDescriptions[QuestionIds.FRIENDLY] = ["Discard a [Friendly] card to gain another.", "Discard a [Friendly] card to gain another."];
EnglishQuestionDescriptions[QuestionIds.INHERITED] = ["Replace a starting card with an [Inherited] card.", "Replace a starting card with an [Inherited] card."];
EnglishQuestionDescriptions[QuestionIds.INSPIRING] = ["You may play an action you don't have in play.", "You may play an action you don't have in play ([Inspiring])."];
EnglishQuestionDescriptions[QuestionIds.PATIENT_SET_ASIDE] = ["Set aside [Patient] cards to play next turn.", "Set aside [Patient] cards to play next turn."];
EnglishQuestionDescriptions[QuestionIds.PATIENT_PLAY] = ["Which [Patient] card to play next?", "Which [Patient] card to play next?"];
EnglishQuestionDescriptions[QuestionIds.PIOUS_TRASH] = ["You may trash a card from your hand.", "You may trash a card from your hand."];
EnglishQuestionDescriptions[QuestionIds.SHY] = ["Discard a [Shy] card for +2 Cards.", "Discard a [Shy] card for +2 Cards."];
EnglishQuestionDescriptions[QuestionIds.FATED_TOPDECK] = ["You may topdeck Fated Cards.", "You may topdeck Fated Cards."];
EnglishQuestionDescriptions[QuestionIds.FATED_BOTTOMDECK] = ["You may bottomdeck Fated Cards.", "You may bottomdeck Fated Cards."];
EnglishQuestionDescriptions[QuestionIds.AVOID] = ["Put up to 3 cards in your Discard pile.", "Put up to 3 cards in your Discard pile."];
EnglishQuestionDescriptions[QuestionIds.TRICKSTER_DISCARD] = ["What Treasure to set aside?", "What Treasure to set aside?"];
EnglishQuestionDescriptions[QuestionIds.FARMHANDS] = ["What Card to play at the start of your next turn?.", "What Card to play at the start of your next turn?."];
EnglishQuestionDescriptions[QuestionIds.COURSER] = ["Choose how to play Courser.", "Choose how to play Courser"];
EnglishQuestionDescriptions[QuestionIds.CORONET_ACTION] = ["What Action to play twice?", "What Action to play twice?"];
EnglishQuestionDescriptions[QuestionIds.CORONET_TREASURE] = ["What Treasure to play twice?", "What Treasure to play twice?"];
EnglishQuestionDescriptions[QuestionIds.JOUST_SET_ASIDE] = ["Set aside a Province?", "Set aside a Province?"];
EnglishQuestionDescriptions[QuestionIds.JOUST_GAIN_REWARD] = ["What Reward to gain to your hand?", "What Reward to gain to your hand?"];
EnglishQuestionDescriptions[QuestionIds.INFIRMARY_TRASH] = ["You may trash a card.", "You may trash a card."];
EnglishQuestionDescriptions[QuestionIds.INFIRMARY_OVERPAY] = ["You may overpay for Infirmary.", "You may overpay"];
EnglishQuestionDescriptions[QuestionIds.FOOTPAD] = EnglishQuestionDescriptions[QuestionIds.MILITIA];
EnglishQuestionDescriptions[QuestionIds.FARRIER_OVERPAY] = ["You may overpay for Farrier.", "You may overpay"];
EnglishQuestionDescriptions[QuestionIds.SHOP] = EnglishQuestionDescriptions[QuestionIds.CONCLAVE];
EnglishQuestionDescriptions[QuestionIds.FERRYMAN] = EnglishQuestionDescriptions[QuestionIds.FUGITIVE];
EnglishQuestionDescriptions[QuestionIds.ORDER_BOTTOMDECKED_CARDS] = ["Choose the order on the bottom", "Choose the order on the bottom"];
EnglishQuestionDescriptions[QuestionIds.GROWTH] = EnglishQuestionDescriptions[QuestionIds.BORDER_VILLAGE];
EnglishQuestionDescriptions[QuestionIds.KIND_EMPEROR] = ["Gain an Action to your Hand", "Gain an Action to your Hand"];
EnglishQuestionDescriptions[QuestionIds.SICKNESS_MODE] = ["", ""];
EnglishQuestionDescriptions[QuestionIds.SICKNESS_DISCARD] = ["Gain a Curse onto your deck or Discard?", "Gain a Curse onto your deck or Discard?"];
EnglishQuestionDescriptions[QuestionIds.SICKNESS] = ["Discard 3 or gain a Curse onto your deck", "Discard 3 or gain a Curse onto your deck"];
EnglishQuestionDescriptions[QuestionIds.SNAKE_WITCH] = ["Return [Snake Witch] to Curse?", "Return [Snake Witch] to have each other player gain a Curse?"];
EnglishQuestionDescriptions[QuestionIds.CRAFTSMAN] = ["Gain a card costing up to 5.", "Gain a card costing up to 5."];
EnglishQuestionDescriptions[QuestionIds.ALLEY] = EnglishQuestionDescriptions[QuestionIds.OASIS];
EnglishQuestionDescriptions[QuestionIds.CHANGE_TRASH] = ["Trash a card from your Hand", "Trash a card from your Hand"];
EnglishQuestionDescriptions[QuestionIds.CHANGE_GAIN] = ["Gain a card and get debt", "Gain a card and get debt"];
EnglishQuestionDescriptions[QuestionIds.NINJA] = EnglishQuestionDescriptions[QuestionIds.MILITIA];
EnglishQuestionDescriptions[QuestionIds.RIVER_SHRINE_TRASH] = ["Trash up to 2 cards", "Trash up to 2 cards"];
EnglishQuestionDescriptions[QuestionIds.RIVER_SHRINE_GAIN] = ["Gain a card costing up to 4.", "Gain a card costing up to 4."];
EnglishQuestionDescriptions[QuestionIds.RUSTIC_VILLAGE_CHOICE] = ["Discard 2 cards to draw 1?", "Discard 2 cards to draw 1?"];
EnglishQuestionDescriptions[QuestionIds.RUSTIC_VILLAGE_DISCARD] = ["Which 2 cards to discard?", "Which 2 cards to discard?"];
EnglishQuestionDescriptions[QuestionIds.GOLD_MINE] = ["Gain a Gold and get 4 debt?", "Gain a Gold and get 4 debt?"];
EnglishQuestionDescriptions[QuestionIds.KITSUNE] = ["Choose how to play Kitsune", "Choose how to play Kitsune"];
EnglishQuestionDescriptions[QuestionIds.TANUKI_TRASH] = EnglishQuestionDescriptions[QuestionIds.REMODEL_TRASH];
EnglishQuestionDescriptions[QuestionIds.TANUKI_GAIN] = EnglishQuestionDescriptions[QuestionIds.REMODEL_GAIN];
EnglishQuestionDescriptions[QuestionIds.SAMURAI] = EnglishQuestionDescriptions[QuestionIds.MILITIA];
EnglishQuestionDescriptions[QuestionIds.MOUNTAIN_SHRINE] = ["You may trash a card from your hand.", "You may trash a card from your hand."];
EnglishQuestionDescriptions[QuestionIds.AMASS] = ["Gain an Action card costing up to 5.", "Gain an Action card costing up to 5."];
EnglishQuestionDescriptions[QuestionIds.ASCETICISM] = ["Trash up to Argument0 Cards.", "Trash up to Argument0 Cards."];
EnglishQuestionDescriptions[QuestionIds.CONTINUE] = ["Gain and play a non-attack Action card", "Gain and play a non-attack Action card"];
EnglishQuestionDescriptions[QuestionIds.CREDIT] = ["Gain an Action or Treasure up to 8", "Gain an Action or Treasure up to 8"];
EnglishQuestionDescriptions[QuestionIds.KINTSUGI_TRASH] = ["Trash a card from your hand.", "Trash a card from your hand."];
EnglishQuestionDescriptions[QuestionIds.KINTSUGI_GAIN] = EnglishQuestionDescriptions[QuestionIds.REMODEL_GAIN];
EnglishQuestionDescriptions[QuestionIds.PRACTICE] = EnglishQuestionDescriptions[QuestionIds.THRONE_ROOM];
EnglishQuestionDescriptions[QuestionIds.SEA_TRADE] = ["Trash up to Argument0 Cards.", "Trash up to Argument0 Cards."];
EnglishQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_FIRST] = ["You may gain 3 Action cards you don't have in play.", "You may gain 3 Action cards you don't have in play."];
EnglishQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_SECOND] = ["You may gain 2 Action cards you don't have in play.", "You may gain 2 Action cards you don't have in play."];
EnglishQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_THIRD] = ["You may gain an Action card you don't have in play.", "You may gain an Action card you don't have in play."];
EnglishQuestionDescriptions[QuestionIds.GATHER] = ["Gain a card costing exactly Argument0.", "Gain a card costing exactly Argument0."];

EnglishQuestionDescriptions[QuestionIds.GAME_PAY_OFF_DEBT] = ["You may pay off Debt.", "You may pay off Debt"];
EnglishQuestionDescriptions[QuestionIds.MAY_PAY_OFF_EXTRA_DEBT] = ["You may pay off Debt.", "You may pay off Debt"];
EnglishQuestionDescriptions[QuestionIds.PAY_OFF_DEBT_BEFORE_CHANGE] = ["Pay off Debt to remodel?", "Pay off Debt to remodel?"];
EnglishQuestionDescriptions[QuestionIds.PAY_OFF_DEBT_BEFORE_STORYTELLER] = ["Pay off Debt to draw fewer Cards?", "Pay off Debt to draw fewer Cards?"];