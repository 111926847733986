"use strict";
// Intro page of Store tab in Lobby

SpanishParagraphs.STORE_INTRO = new Paragraph(["Jugar con las cartas básicas es gratis.", "Jugar con expansiones requiere una suscripción.", "Un subscriptor por partida es suficiente."]);

SpanishParagraphs.STORE_BUYING = new Paragraph(["La suscripción no se renueva automáticamente.", "Puedes comprar de nuevo para continuar la suscripción o actualizarla.", "El valor de tu suscripción restante se descontará del precio.", "Al comprar por un periodo superior se aplicarán descuentos."]);

SpanishParagraphs.STORE_SUBSCRIPTIONS = new Paragraph(["Suscripción Plata &mdash; mitad de las expansiones &mdash; 2.15€ al mes.", "Suscripción Oro &mdash; todas las expansiones &mdash; 4.30€ al mes.", "La suscripción Oro no incluye futuras expansiones."]);

SpanishParagraphs.STORE_DOLLARS = new Paragraph(["Los precios se muestran en euros.", "El pago en dólares será automáticamente convertido."]);

SpanishParagraphs.STORE_UPGRADE = new Paragraph(["Tu suscripción contiene todas las expansiones excepto la última.", "Elige 'Sí' y esta expansión se añadirá a tu suscripción.", "La fecha de expiración será la más próxima de tus suscripciones.", "Elige 'No' para el resto de compras."]);