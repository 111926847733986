"use strict";

webclient.component("reconnecting", {
    transclude: true,
    controller: ['reconnect', function (reconnect) {
        var self = this;

        self.shouldShow = function () {
            return reconnect.isReconnecting();
        };
        self.cancel = function () {
            return reconnect.cancel();
        };

        self.getWindowText = function () {
            return getPhrase(Phrases.RECONNECTING);
        };
        self.getButtonText = function () {
            return getPhrase(Phrases.CANCEL_RESIGN);
        };
    }],
    template: "\n            <modal-window ng-if=\"$ctrl.shouldShow()\">\n                <div class=\"timeout\">{{::$ctrl.getWindowText()}}</div>\n                <button class=\"lobby-button\" ng-click=\"$ctrl.cancel()\">{{::$ctrl.getButtonText()}}</button>\n            </modal-window>\n        "
});