"use strict";

var GermanCardNames = {};

//Base
GermanCardNames[CardNames.BACK] = new TranslatedCardName("Karte", "Karten", "eine Karte");
GermanCardNames[CardNames.CURSE] = new TranslatedCardName("Fluch", "Flüche", "einen Fluch");
GermanCardNames[CardNames.COPPER] = new TranslatedCardName("Kupfer", "Kupfer", "ein Kupfer");
GermanCardNames[CardNames.SILVER] = new TranslatedCardName("Silber", "Silber", "ein Silber");
GermanCardNames[CardNames.GOLD] = new TranslatedCardName("Gold", "Gold", "ein Gold");
GermanCardNames[CardNames.ESTATE] = new TranslatedCardName("Anwesen", "Anwesen", "ein Anwesen");
GermanCardNames[CardNames.DUCHY] = new TranslatedCardName("Herzogtum", "Herzogtümer", "ein Herzogtum");
GermanCardNames[CardNames.PROVINCE] = new TranslatedCardName("Provinz", "Provinzen", "eine Provinz");
GermanCardNames[CardNames.ARTISAN] = new TranslatedCardName("Töpferei", "Töpfereien", "eine Töpferei");
GermanCardNames[CardNames.BANDIT] = new TranslatedCardName("Banditin", "Banditinnen", "eine Banditin");
GermanCardNames[CardNames.BUREAUCRAT] = new TranslatedCardName("Bürokrat", "Bürokraten", "einen Bürokraten");
GermanCardNames[CardNames.CELLAR] = new TranslatedCardName("Keller", "Keller", "einen Keller");
GermanCardNames[CardNames.CHAPEL] = new TranslatedCardName("Kapelle", "Kapellen", "eine Kapelle");
GermanCardNames[CardNames.COUNCIL_ROOM] = new TranslatedCardName("Ratsversammlung", "Ratsversammlungen", "eine Ratsversammlung");
GermanCardNames[CardNames.FESTIVAL] = new TranslatedCardName("Jahrmarkt", "Jahrmärkte", "einen Jahrmarkt");
GermanCardNames[CardNames.GARDENS] = new TranslatedCardName("Gärten", "Gärten", "Gärten");
GermanCardNames[CardNames.HARBINGER] = new TranslatedCardName("Vorbotin", "Vorbotinnen", "eine Vorbotin");
GermanCardNames[CardNames.LABORATORY] = new TranslatedCardName("Laboratorium", "Laboratorien", "ein Laboratorium");
GermanCardNames[CardNames.LIBRARY] = new TranslatedCardName("Bibliothek", "Bibliotheken", "eine Bibliothek");
GermanCardNames[CardNames.MARKET] = new TranslatedCardName("Markt", "Märkte", "einen Markt");
GermanCardNames[CardNames.MERCHANT] = new TranslatedCardName("Händlerin", "Händlerinnen", "eine Händlerin");
GermanCardNames[CardNames.MILITIA] = new TranslatedCardName("Miliz", "Milizen", "eine Miliz");
GermanCardNames[CardNames.MINE] = new TranslatedCardName("Mine", "Minen", "eine Mine");
GermanCardNames[CardNames.MOAT] = new TranslatedCardName("Burggraben", "Burggräben", "einen Burggraben");
GermanCardNames[CardNames.MONEYLENDER] = new TranslatedCardName("Geldverleiher", "Geldverleiher", "einen Geldverleiher");
GermanCardNames[CardNames.POACHER] = new TranslatedCardName("Wilddiebin", "Wilddiebinnen", "eine Wilddiebin");
GermanCardNames[CardNames.REMODEL] = new TranslatedCardName("Umbau", "Umbauten", "einen Umbau");
GermanCardNames[CardNames.SENTRY] = new TranslatedCardName("Torwächterin", "Torwächterinnen", "eine Torwächterin");
GermanCardNames[CardNames.SMITHY] = new TranslatedCardName("Schmiede", "Schmieden", "eine Schmiede");
GermanCardNames[CardNames.THRONE_ROOM] = new TranslatedCardName("Thronsaal", "Thronsääle", "einen Thronsaal");
GermanCardNames[CardNames.VASSAL] = new TranslatedCardName("Vasall", "Vasallen", "einen Vasallen");
GermanCardNames[CardNames.VILLAGE] = new TranslatedCardName("Dorf", "Dörfer", "ein Dorf");
GermanCardNames[CardNames.WITCH] = new TranslatedCardName("Hexe", "Hexen", "eine Hexe");
GermanCardNames[CardNames.WORKSHOP] = new TranslatedCardName("Werkstatt", "Werkstätten", "eine Werkstatt");

//Intrigue
GermanCardNames[CardNames.COURTYARD] = new TranslatedCardName("Burghof", "Burghöfe", "einen Burghof");
GermanCardNames[CardNames.CONSPIRATOR] = new TranslatedCardName("Verschwörer", "Verschwörer", "einen Verschwörer");
GermanCardNames[CardNames.COURTIER] = new TranslatedCardName("Höflinge", "Höflinge", "Höflinge");
GermanCardNames[CardNames.BARON] = new TranslatedCardName("Baron", "Barone", "einen Baron");
GermanCardNames[CardNames.BRIDGE] = new TranslatedCardName("Brücke", "Brücken", "eine Brücke");
GermanCardNames[CardNames.DIPLOMAT] = new TranslatedCardName("Diplomatin", "Diplomatinnen", "eine Diplomatin");
GermanCardNames[CardNames.DUKE] = new TranslatedCardName("Herzog", "Herzöge", "einen Herzog");
GermanCardNames[CardNames.FARM] = new TranslatedCardName("Farm", "Farmen", "eine Farm");
GermanCardNames[CardNames.NOBLES] = new TranslatedCardName("Adlige", "Adlige", "einen Adligen");
GermanCardNames[CardNames.IRONWORKS] = new TranslatedCardName("Eisenhütte", "Eisenhütten", "eine Eisenhütte");
GermanCardNames[CardNames.LURKER] = new TranslatedCardName("Herumtreiberin", "Herumtreiberinnen", "eine Herumtreiberin");
GermanCardNames[CardNames.MASQUERADE] = new TranslatedCardName("Maskerade", "Maskeraden", "eine Maskerade");
GermanCardNames[CardNames.MILL] = new TranslatedCardName("Mühle", "Mühlen", "eine Mühle");
GermanCardNames[CardNames.MINING_VILLAGE] = new TranslatedCardName("Bergwerk", "Bergwerke", "ein Bergwerk");
GermanCardNames[CardNames.MINION] = new TranslatedCardName("Lakai", "Lakaien", "einen Lakaien");
GermanCardNames[CardNames.PATROL] = new TranslatedCardName("Patrouille", "Patrouillen", "eine Patrouille");
GermanCardNames[CardNames.PAWN] = new TranslatedCardName("Handlanger", "Handlanger", "einen Handlanger");
GermanCardNames[CardNames.REPLACE] = new TranslatedCardName("Austausch", "Austausche", "einen Austausch");
GermanCardNames[CardNames.SECRET_PASSAGE] = new TranslatedCardName("Geheimgang", "Geheimgänge", "einen Geheimgang");
GermanCardNames[CardNames.SHANTY_TOWN] = new TranslatedCardName("Armenviertel", "Armenviertel", "ein Armenviertel");
GermanCardNames[CardNames.STEWARD] = new TranslatedCardName("Verwalter", "Verwalter", "einen Verwalter");
GermanCardNames[CardNames.SWINDLER] = new TranslatedCardName("Trickser", "Trickser", "einen Trickser");
GermanCardNames[CardNames.TORTURER] = new TranslatedCardName("Kerkermeister", "Kerkermeister", "einen Kerkermeister");
GermanCardNames[CardNames.TRADING_POST] = new TranslatedCardName("Handelsposten", "Handelsposten", "einen Handelsposten");
GermanCardNames[CardNames.UPGRADE] = new TranslatedCardName("Anbau", "Anbau", "einen Anbau");
GermanCardNames[CardNames.WISHING_WELL] = new TranslatedCardName("Wunschbrunnen", "Wunschbrunnen", "einen Wunschbrunnen");

//Seaside
GermanCardNames[CardNames.AMBASSADOR] = new TranslatedCardName("Botschafter", "Botschafter", "einen Botschafter");
GermanCardNames[CardNames.BAZAAR] = new TranslatedCardName("Bazar", "Bazare", "einen Bazar");
GermanCardNames[CardNames.CARAVAN] = new TranslatedCardName("Karawane", "Karawanen", "eine Karawane");
GermanCardNames[CardNames.CUTPURSE] = new TranslatedCardName("Beutelschneider", "Beutelschneider", "einen Beutelschneider");
GermanCardNames[CardNames.EMBARGO] = new TranslatedCardName("Embargo", "Embargos", "ein Embargo");
GermanCardNames[CardNames.EXPLORER] = new TranslatedCardName("Entdecker", "Entdecker", "einen Entdecker");
GermanCardNames[CardNames.FISHING_VILLAGE] = new TranslatedCardName("Fischerdorf", "Fischerdörfer", "ein Fischerdorf");
GermanCardNames[CardNames.GHOST_SHIP] = new TranslatedCardName("Geisterschiff", "Geisterschiffe", "ein Geisterschiff");
GermanCardNames[CardNames.HAVEN] = new TranslatedCardName("Hafen", "Häfen", "einen Hafen");
GermanCardNames[CardNames.ISLAND] = new TranslatedCardName("Insel", "Inseln", "eine Insel");
GermanCardNames[CardNames.LIGHTHOUSE] = new TranslatedCardName("Leuchtturm", "Leuchttürme", "einen Leuchtturm");
GermanCardNames[CardNames.LOOKOUT] = new TranslatedCardName("Ausguck", "Ausgucke", "einen Ausguck");
GermanCardNames[CardNames.MERCHANT_SHIP] = new TranslatedCardName("Handelsschiff", "Handelsschiffe", "ein Handelsschiff");
GermanCardNames[CardNames.NATIVE_VILLAGE] = new TranslatedCardName("Eingeborenendorf", "Eingeborenendörfer", "ein Eingeborenendorf");
GermanCardNames[CardNames.NAVIGATOR] = new TranslatedCardName("Navigator", "Navigatoren", "einen Navigator");
GermanCardNames[CardNames.OUTPOST] = new TranslatedCardName("Außenposten", "Außenposten", "einen Außenposten");
GermanCardNames[CardNames.PEARL_DIVER] = new TranslatedCardName("Perlentaucher", "Perlentaucher", "einen Perlentaucher");
GermanCardNames[CardNames.PIRATE_SHIP] = new TranslatedCardName("Piratenschiff", "Piratenschiffe", "ein Piratenschiff");
GermanCardNames[CardNames.SALVAGER] = new TranslatedCardName("Müllverwerter", "Müllverwerter", "einen Müllverwerter");
GermanCardNames[CardNames.SEA_HAG] = new TranslatedCardName("Seehexe", "Seehexen", "eine Seehexe");
GermanCardNames[CardNames.SMUGGLERS] = new TranslatedCardName("Schmuggler", "Schmuggler", "einen Schmuggler");
GermanCardNames[CardNames.TACTICIAN] = new TranslatedCardName("Taktiker", "Taktiker", "einen Taktiker");
GermanCardNames[CardNames.TREASURE_MAP] = new TranslatedCardName("Schatzkarte", "Schatzkarten", "eine Schatzkarte");
GermanCardNames[CardNames.TREASURY] = new TranslatedCardName("Schatzkammer", "Schatzkammern", "eine Schatzkammer");
GermanCardNames[CardNames.WAREHOUSE] = new TranslatedCardName("Lagerhaus", "Lagerhäuser", "ein Lagerhaus");
GermanCardNames[CardNames.WHARF] = new TranslatedCardName("Werft", "Werften", "eine Werft");

//Alchemy
GermanCardNames[CardNames.ALCHEMIST] = new TranslatedCardName("Alchemist", "Alchemisten", "einen Alchemisten");
GermanCardNames[CardNames.APOTHECARY] = new TranslatedCardName("Apotheker", "Apotheker", "einen Apotheker");
GermanCardNames[CardNames.APPRENTICE] = new TranslatedCardName("Lehrling", "Lehrlinge", "einen Lehrling");
GermanCardNames[CardNames.FAMILIAR] = new TranslatedCardName("Vertrauter", "Vertraute", "einen Vertrauten");
GermanCardNames[CardNames.GOLEM] = new TranslatedCardName("Golem", "Golems", "einen Golem");
GermanCardNames[CardNames.HERBALIST] = new TranslatedCardName("Kräuterkundiger", "Kräuterkundige", "einen Kräuterkundigen");
GermanCardNames[CardNames.PHILOSOPHERS_STONE] = new TranslatedCardName("Stein der Weisen", "Steine der Weisen", "einen Stein der Weisen");
GermanCardNames[CardNames.POSSESSION] = new TranslatedCardName("Besessenheit", "Besessenheiten", "eine Besessenheit");
GermanCardNames[CardNames.POTION] = new TranslatedCardName("Trank", "Tränke", "einen Trank");
GermanCardNames[CardNames.SCRYING_POOL] = new TranslatedCardName("Vision", "Visionen", "eine Vision");
GermanCardNames[CardNames.TRANSMUTE] = new TranslatedCardName("Verwandlung", "Verwandlungen", "eine Verwandlung");
GermanCardNames[CardNames.UNIVERSITY] = new TranslatedCardName("Universität", "Universitäten", "eine Universität");
GermanCardNames[CardNames.VINEYARD] = new TranslatedCardName("Weinberg", "Weinberge", "einen Weinberg");

//Prosperity
GermanCardNames[CardNames.BANK] = new TranslatedCardName("Bank", "Banken", "eine Bank");
GermanCardNames[CardNames.BISHOP] = new TranslatedCardName("Bischof", "Bischöfe", "einen Bischof");
GermanCardNames[CardNames.COLONY] = new TranslatedCardName("Kolonie", "Kolonien", "eine Kolonie");
GermanCardNames[CardNames.CONTRABAND] = new TranslatedCardName("Schmuggelware", "Schmuggelwaren", "eine Schmuggelware");
GermanCardNames[CardNames.COUNTING_HOUSE] = new TranslatedCardName("Leihhaus", "Leihhäuser", "ein Leihhaus");
GermanCardNames[CardNames.CITY] = new TranslatedCardName("Stadt", "Städte", "eine Stadt");
GermanCardNames[CardNames.EXPAND] = new TranslatedCardName("Ausbau", "Ausbauten", "einen Ausbau");
GermanCardNames[CardNames.FORGE] = new TranslatedCardName("Kunstschmiede", "Kunstschmieden", "eine Kunstschmiede");
GermanCardNames[CardNames.GRAND_MARKET] = new TranslatedCardName("Großer Markt", "Große Märkte", "einen Großen Markt");
GermanCardNames[CardNames.GOONS] = new TranslatedCardName("Halsabschneider", "Halsabschneider", "einen Halsabschneider");
GermanCardNames[CardNames.HOARD] = new TranslatedCardName("Hort", "Horte", "einen Hort");
GermanCardNames[CardNames.KINGS_COURT] = new TranslatedCardName("Königshof", "Königshöfe", "einen Königshof");
GermanCardNames[CardNames.LOAN] = new TranslatedCardName("Lohn", "Löhne", "einen Lohn");
GermanCardNames[CardNames.MINT] = new TranslatedCardName("Münzer", "Münzer", "einen Münzer");
GermanCardNames[CardNames.MONUMENT] = new TranslatedCardName("Denkmal", "Denkmäler", "ein Denkmal");
GermanCardNames[CardNames.MOUNTEBANK] = new TranslatedCardName("Quacksalber", "Quacksalber", "einen Quacksalber");
GermanCardNames[CardNames.PEDDLER] = new TranslatedCardName("Hausiererin", "Hausiererinnen", "eine Hausiererin");
GermanCardNames[CardNames.PLATINUM] = new TranslatedCardName("Platin", "Platins", "ein Platin");
GermanCardNames[CardNames.QUARRY] = new TranslatedCardName("Steinbruch", "Steinbrüche", "einen Steinbruch");
GermanCardNames[CardNames.RABBLE] = new TranslatedCardName("Gesindel", "Gesindel", "ein Gesindel");
GermanCardNames[CardNames.ROYAL_SEAL] = new TranslatedCardName("Königliches Siegel", "Königliche Siegel", "ein Königliches Siegel");
GermanCardNames[CardNames.TALISMAN] = new TranslatedCardName("Talisman", "Talismane", "einen Talisman");
GermanCardNames[CardNames.TRADE_ROUTE] = new TranslatedCardName("Handelsroute", "Handelsrouten", "eine Handelsroute");
GermanCardNames[CardNames.VAULT] = new TranslatedCardName("Gewölbe", "Gewölbe", "ein Gewölbe");
GermanCardNames[CardNames.VENTURE] = new TranslatedCardName("Abenteuer", "Abenteuer", "ein Abenteuer");
GermanCardNames[CardNames.WATCHTOWER] = new TranslatedCardName("Wachturm", "Wachtürme", "einen Wachturm");
GermanCardNames[CardNames.WORKERS_VILLAGE] = new TranslatedCardName("Arbeiterdorf", "Arbeiterdörfer", "ein Arbeiterdorf");

//Cornucpoia
GermanCardNames[CardNames.PRIZE_PILE] = new TranslatedCardName("Preis", "Preise", "einen Preis");
GermanCardNames[CardNames.BAG_OF_GOLD] = new TranslatedCardName("Ein Sack voll Gold", "Säcke voll Gold", "einen Sack voll Gold");
GermanCardNames[CardNames.DIADEM] = new TranslatedCardName("Diadem", "Diademe", "ein Diadem");
GermanCardNames[CardNames.FAIRGROUNDS] = new TranslatedCardName("Festplatz", "Festplätze", "einen Festplatz");
GermanCardNames[CardNames.FARMING_VILLAGE] = new TranslatedCardName("Bauerndorf", "Bauerndörfer", "ein Bauerndorf");
GermanCardNames[CardNames.FOLLOWERS] = new TranslatedCardName("Gefolge", "Gefolge", "ein Gefolge");
GermanCardNames[CardNames.FORTUNE_TELLER] = new TranslatedCardName("Wahrsagerin", "Wahrsagerinnen", "eine Wahrsagerin");
GermanCardNames[CardNames.HAMLET] = new TranslatedCardName("Weiler", "Weiler", "einen Weiler");
GermanCardNames[CardNames.HARVEST] = new TranslatedCardName("Ernte", "Ernten", "eine Ernte");
GermanCardNames[CardNames.HORSE_TRADERS] = new TranslatedCardName("Pferdehändler", "Pferdehändler", "einen Pferdehändler");
GermanCardNames[CardNames.HORN_OF_PLENTY] = new TranslatedCardName("Füllhorn", "Füllhörner", "ein Füllhorn");
GermanCardNames[CardNames.HUNTING_PARTY] = new TranslatedCardName("Treibjagd", "Treibjagden", "eine Treibjagd");
GermanCardNames[CardNames.JESTER] = new TranslatedCardName("Harlekin", "Harlekine", "einen Harlekin");
GermanCardNames[CardNames.MENAGERIE] = new TranslatedCardName("Menagerie", "Menagerien", "eine Menagerie");
GermanCardNames[CardNames.PRINCESS] = new TranslatedCardName("Prinzessin", "Prinzessinen", "eine Prinzessin");
GermanCardNames[CardNames.REMAKE] = new TranslatedCardName("Nachbau", "Nachbauten", "einen Nachbau");
GermanCardNames[CardNames.TOURNAMENT] = new TranslatedCardName("Turnier", "Turniere", "ein Turnier");
GermanCardNames[CardNames.TRUSTY_STEED] = new TranslatedCardName("Streitross", "Streitrösser", "ein Streitross");
GermanCardNames[CardNames.YOUNG_WITCH] = new TranslatedCardName("Junge Hexe", "Junge Hexen", "eine Junge Hexe");

//Hinterlands
GermanCardNames[CardNames.BORDER_VILLAGE] = new TranslatedCardName("Grenzdorf", "Grenzdörfer", "ein Grenzdorf");
GermanCardNames[CardNames.CACHE] = new TranslatedCardName("Schatztruhe", "Schatztruhen", "eine Schatztruhe");
GermanCardNames[CardNames.CARTOGRAPHER] = new TranslatedCardName("Kartograph", "Kartographen", "einen Kartographen");
GermanCardNames[CardNames.CROSSROADS] = new TranslatedCardName("Wegkreuzung", "Wegkreuzungen", "eine Wegkreuzung");
GermanCardNames[CardNames.DEVELOP] = new TranslatedCardName("Aufbau", "Aufbauten", "einen Aufbau");
GermanCardNames[CardNames.DUCHESS] = new TranslatedCardName("Herzogin", "Herzoginnen", "eine Herzogin");
GermanCardNames[CardNames.EMBASSY] = new TranslatedCardName("Botschaft", "Botschaften", "eine Botschaft");
GermanCardNames[CardNames.FARMLAND] = new TranslatedCardName("Fruchtbares Land", "Fruchtbare Länder", "ein Fruchtbares Land");
GermanCardNames[CardNames.FOOLS_GOLD] = new TranslatedCardName("Katzengold", "Katzengold", "ein Katzengold");
GermanCardNames[CardNames.HAGGLER] = new TranslatedCardName("Feilscher", "Feilscher", "einen Feilscher");
GermanCardNames[CardNames.HIGHWAY] = new TranslatedCardName("Fernstraße", "Fernstraßen", "eine Fernstraße");
GermanCardNames[CardNames.ILL_GOTTEN_GAINS] = new TranslatedCardName("Blutzoll", "Blutzölle", "einen Blutzoll");
GermanCardNames[CardNames.INN] = new TranslatedCardName("Gasthaus", "Gasthäuser", "ein Gasthaus");
GermanCardNames[CardNames.JACK_OF_ALL_TRADES] = new TranslatedCardName("Lebenskünstler", "Lebenskünstler", "einen Lebenskünstler");
GermanCardNames[CardNames.MANDARIN] = new TranslatedCardName("Mandarin", "Mandarine", "einen Mandarin");
GermanCardNames[CardNames.NOBLE_BRIGAND] = new TranslatedCardName("Edler Räuber", "Edle Räuber", "einen Edlen Räuber");
GermanCardNames[CardNames.NOMAD_CAMP] = new TranslatedCardName("Nomadencamp", "Nomadencamps", "ein Nomadencamp");
GermanCardNames[CardNames.OASIS] = new TranslatedCardName("Oase", "Oasen", "eine Oase");
GermanCardNames[CardNames.ORACLE] = new TranslatedCardName("Orakel", "Orakel", "ein Orakel");
GermanCardNames[CardNames.MARGRAVE] = new TranslatedCardName("Markgraf", "Markgrafen", "einen Markgraf");
GermanCardNames[CardNames.SCHEME] = new TranslatedCardName("Komplott", "Komplotte", "ein Komplott");
GermanCardNames[CardNames.SILK_ROAD] = new TranslatedCardName("Seidenstraße", "Seidenstraßen", "eine Seidenstraße");
GermanCardNames[CardNames.SPICE_MERCHANT] = new TranslatedCardName("Gewürzhändler", "Gewürzhändler", "einen Gewürzhändler");
GermanCardNames[CardNames.STABLES] = new TranslatedCardName("Stallungen", "Stallungen", "Stallungen");
GermanCardNames[CardNames.TRADER] = new TranslatedCardName("Fahrende Händlerin", "Fahrende Händlerinnen", "eine Fahrende Händlerin");
GermanCardNames[CardNames.TUNNEL] = new TranslatedCardName("Tunnel", "Tunnel", "einen Tunnel");

//Dark Ages
GermanCardNames[CardNames.RUIN_PILE] = new TranslatedCardName("Ruine", "Ruinen", "eine Ruine");
GermanCardNames[CardNames.KNIGHTS] = new TranslatedCardName("Ritter", "Ritter", "einen Ritter");
GermanCardNames[CardNames.ABANDONED_MINE] = new TranslatedCardName("Verlassene Mine", "Verlassene Minen", "eine Verlassene Mine");
GermanCardNames[CardNames.ALTAR] = new TranslatedCardName("Altar", "Altare", "einen Altar");
GermanCardNames[CardNames.ARMORY] = new TranslatedCardName("Waffenkammer", "Waffenkammern", "eine Waffenkammer");
GermanCardNames[CardNames.BAND_OF_MISFITS] = new TranslatedCardName("Vogelfreie", "Vogelfreie", "Vogelfreie");
GermanCardNames[CardNames.BANDIT_CAMP] = new TranslatedCardName("Banditenlager", "Banditenlager", "ein Banditenlager");
GermanCardNames[CardNames.BEGGAR] = new TranslatedCardName("Bettler", "Bettler", "einen Bettler");
GermanCardNames[CardNames.CATACOMBS] = new TranslatedCardName("Katakomben", "Katakomben", "eine Katakomben");
GermanCardNames[CardNames.COUNT] = new TranslatedCardName("Graf", "Grafen", "einen Grafen");
GermanCardNames[CardNames.COUNTERFEIT] = new TranslatedCardName("Falschgeld", "Falschgeld", "ein Falschgeld");
GermanCardNames[CardNames.CULTIST] = new TranslatedCardName("Kultist", "Kultisten", "einen Kultisten");
GermanCardNames[CardNames.DAME_ANNA] = new TranslatedCardName("Dame Anna", "Damen Anna", "eine Dame Anna");
GermanCardNames[CardNames.DAME_JOSEPHINE] = new TranslatedCardName("Dame Josephine", "Damen Josephine", "eine Dame Josephine");
GermanCardNames[CardNames.DAME_MOLLY] = new TranslatedCardName("Dame Molly", "Damen Molly", "eine Dame Molly");
GermanCardNames[CardNames.DAME_NATALIE] = new TranslatedCardName("Dame Natalie", "Damen Natalie", "eine Dame Natalie");
GermanCardNames[CardNames.DAME_SYLVIA] = new TranslatedCardName("Dame Sylvia", "Damen Sylvia", "eine Dame Sylvia");
GermanCardNames[CardNames.DEATH_CART] = new TranslatedCardName("Leichenkarren", "Leichenkarren", "einen Leichenkarren");
GermanCardNames[CardNames.FEODUM] = new TranslatedCardName("Lehen", "Lehen", "ein Lehen");
GermanCardNames[CardNames.FORAGER] = new TranslatedCardName("Mundraub", "Mundraube", "einen Mundraub");
GermanCardNames[CardNames.FORTRESS] = new TranslatedCardName("Festung", "Festungen", "eine Festung");
GermanCardNames[CardNames.GRAVEROBBER] = new TranslatedCardName("Grabräuber", "Grabräuber", "einen Grabräuber");
GermanCardNames[CardNames.HERMIT] = new TranslatedCardName("Eremit", "Eremiten", "einen Eremiten");
GermanCardNames[CardNames.HOVEL] = new TranslatedCardName("Hütte", "Hütten", "eine Hütte");
GermanCardNames[CardNames.HUNTING_GROUNDS] = new TranslatedCardName("Jagdgründe", "Jagdgründe", "Jagdgründe");
GermanCardNames[CardNames.IRONMONGER] = new TranslatedCardName("Eisenhändler", "Eisenhändler", "einen Eisenhändler");
GermanCardNames[CardNames.JUNK_DEALER] = new TranslatedCardName("Schrotthändler", "Schrotthändler", "einen Schrotthändler");
GermanCardNames[CardNames.MADMAN] = new TranslatedCardName("Verrückter", "Verrückte", "einen Verrückten");
GermanCardNames[CardNames.MARKET_SQUARE] = new TranslatedCardName("Marktplatz", "Marktplätze", "einen Marktplatz");
GermanCardNames[CardNames.MARAUDER] = new TranslatedCardName("Marodeur", "Marodeure", "einen Marodeur");
GermanCardNames[CardNames.MERCENARY] = new TranslatedCardName("Söldner", "Söldner", "einen Söldner");
GermanCardNames[CardNames.MYSTIC] = new TranslatedCardName("Medium", "Medien", "ein Medium");
GermanCardNames[CardNames.NECROPOLIS] = new TranslatedCardName("Totenstadt", "Totenstädte", "eine Totenstadt");
GermanCardNames[CardNames.OVERGROWN_ESTATE] = new TranslatedCardName("Verfallenes Anwesen", "Verfallene Anwesen", "ein Verfallenes Anwesen");
GermanCardNames[CardNames.PILLAGE] = new TranslatedCardName("Raubzug", "Raubzüge", "einen Raubzug");
GermanCardNames[CardNames.POOR_HOUSE] = new TranslatedCardName("Armenhaus", "Armenhäuser", "ein Armenhaus");
GermanCardNames[CardNames.PROCESSION] = new TranslatedCardName("Prozession", "Prozessionen", "eine Prozession");
GermanCardNames[CardNames.RATS] = new TranslatedCardName("Ratten", "Ratten", "Ratten");
GermanCardNames[CardNames.REBUILD] = new TranslatedCardName("Neubau", "Neubauten", "einen Neubau");
GermanCardNames[CardNames.ROGUE] = new TranslatedCardName("Schurke", "Schurken", "einen Schurken");
GermanCardNames[CardNames.RUINED_LIBRARY] = new TranslatedCardName("Zerstörte Bibliothek", "Zerstörte Bibliotheken", "eine Zerstörte Bibliothek");
GermanCardNames[CardNames.RUINED_MARKET] = new TranslatedCardName("Zerstörter Markt", "Zerstörte Märkte", "einen Zerstörter Markt");
GermanCardNames[CardNames.RUINED_VILLAGE] = new TranslatedCardName("Zerstörtes Dorf", "Zerstörte Dörfer", "ein Zerstörtes Dorf");
GermanCardNames[CardNames.SAGE] = new TranslatedCardName("Weiser", "Weise", "einen Weisen");
GermanCardNames[CardNames.SCAVENGER] = new TranslatedCardName("Lumpensammler", "Lumpensammler", "einen Lumpensammler");
GermanCardNames[CardNames.SIR_BAILEY] = new TranslatedCardName("Sir Bailey", "Sir Baileys", "einen Sir Bailey");
GermanCardNames[CardNames.SIR_DESTRY] = new TranslatedCardName("Sir Destry", "Sir Destrys", "einen Sir Destry");
GermanCardNames[CardNames.SIR_MARTIN] = new TranslatedCardName("Sir Martin", "Sir Martins", "einen Sir Martin");
GermanCardNames[CardNames.SIR_MICHAEL] = new TranslatedCardName("Sir Michael", "Sir Michaels", "einen Sir Michael");
GermanCardNames[CardNames.SIR_VANDER] = new TranslatedCardName("Sir Vander", "Sir Vanders", "einen Sir Vander");
GermanCardNames[CardNames.SPOILS] = new TranslatedCardName("Beute", "Beute", "eine Beute");
GermanCardNames[CardNames.STOREROOM] = new TranslatedCardName("Lagerraum", "Lagerräume", "einen Lagerraum");
GermanCardNames[CardNames.SQUIRE] = new TranslatedCardName("Knappe", "Knappen", "einen Knappen");
GermanCardNames[CardNames.SURVIVORS] = new TranslatedCardName("Überlebende", "Überlebende", "Überlebende");
GermanCardNames[CardNames.URCHIN] = new TranslatedCardName("Gassenjunge", "Gassenjungen", "einen Gassenjungen");
GermanCardNames[CardNames.VAGRANT] = new TranslatedCardName("Landstreicher", "Landstreicher", "einen Landstreicher");
GermanCardNames[CardNames.WANDERING_MINSTREL] = new TranslatedCardName("Barde", "Barden", "einen Barden");

//Guilds
GermanCardNames[CardNames.ADVISOR] = new TranslatedCardName("Berater", "Berater", "einen Berater");
GermanCardNames[CardNames.BAKER] = new TranslatedCardName("Bäcker", "Bäcker", "einen Bäcker");
GermanCardNames[CardNames.BUTCHER] = new TranslatedCardName("Metzger", "Metzger", "einen Metzger");
GermanCardNames[CardNames.CANDLESTICK_MAKER] = new TranslatedCardName("Leuchtenmacher", "Leuchtenmacher", "einen Leuchtenmacher");
GermanCardNames[CardNames.DOCTOR] = new TranslatedCardName("Arzt", "Ärzte", "einen Arzt");
GermanCardNames[CardNames.HERALD] = new TranslatedCardName("Herold", "Herolde", "einen Herold");
GermanCardNames[CardNames.JOURNEYMAN] = new TranslatedCardName("Wandergeselle", "Wandergesellen", "einen Wandergesellen");
GermanCardNames[CardNames.MASTERPIECE] = new TranslatedCardName("Meisterstück", "Meisterstücke", "ein Meisterstück");
GermanCardNames[CardNames.MERCHANT_GUILD] = new TranslatedCardName("Kaufmannsgilde", "Kaufmannsgilden", "eine Kaufmannsgilde");
GermanCardNames[CardNames.PLAZA] = new TranslatedCardName("Platz", "Plätze", "einen Platz");
GermanCardNames[CardNames.TAXMAN] = new TranslatedCardName("Steuereintreiber", "Steuereintreiber", "einen Steuereintreiber");
GermanCardNames[CardNames.SOOTHSAYER] = new TranslatedCardName("Hellseherin", "Hellseherinnen", "eine Hellseherin");
GermanCardNames[CardNames.STONEMASON] = new TranslatedCardName("Steinmetz", "Steinmetze", "einen Steinmetz");

//Adeventures
GermanCardNames[CardNames.ALMS] = new TranslatedCardName("Almosen", "Almosen", "Almosen");
GermanCardNames[CardNames.AMULET] = new TranslatedCardName("Amulett", "Amulette", "ein Amulett");
GermanCardNames[CardNames.ARTIFICER] = new TranslatedCardName("Kunsthandwerker", "Kunsthandwerker", "einen Kunsthandwerker");
GermanCardNames[CardNames.BALL] = new TranslatedCardName("Ball", "Bälle", "einen Ball");
GermanCardNames[CardNames.BONFIRE] = new TranslatedCardName("Freudenfeuer", "Freudenfeuer", "ein Freudenfeuer");
GermanCardNames[CardNames.BORROW] = new TranslatedCardName("Leihgabe", "Leihgaben", "eine Leihgabe");
GermanCardNames[CardNames.BRIDGE_TROLL] = new TranslatedCardName("Brückentroll", "Brückentrolle", "einen Brückentroll");
GermanCardNames[CardNames.CARAVAN_GUARD] = new TranslatedCardName("Karawanenwächter", "Karawanenwächter", "einen Karawanenwächter");
GermanCardNames[CardNames.CHAMPION] = new TranslatedCardName("Champion", "Champions", "einen Champion");
GermanCardNames[CardNames.COIN_OF_THE_REALM] = new TranslatedCardName("Königliche Münze", "Königliche Münzen", "eine Königliche Münze");
GermanCardNames[CardNames.DISCIPLE] = new TranslatedCardName("Schüler", "Schüler", "einen Schüler");
GermanCardNames[CardNames.DISTANT_LANDS] = new TranslatedCardName("Ferne Lande", "Ferne Lande", "eine Ferne Lande");
GermanCardNames[CardNames.DUNGEON] = new TranslatedCardName("Verlies", "Verliese", "ein Verlies");
GermanCardNames[CardNames.DUPLICATE] = new TranslatedCardName("Duplikat", "Duplikate", "ein Duplikat");
GermanCardNames[CardNames.EXPEDITION] = new TranslatedCardName("Expedition", "Expeditionen", "eine Expedition");
GermanCardNames[CardNames.FERRY] = new TranslatedCardName("Überfahrt", "Überfahrten", "eine Überfahrt");
GermanCardNames[CardNames.FUGITIVE] = new TranslatedCardName("Flüchtling", "Flüchtlinge", "einen Flüchtling");
GermanCardNames[CardNames.GEAR] = new TranslatedCardName("Ausrüstung", "Ausrüstungen", "eine Ausrüstung");
GermanCardNames[CardNames.GIANT] = new TranslatedCardName("Riese", "Riesen", "einen Riesen");
GermanCardNames[CardNames.GUIDE] = new TranslatedCardName("Kundschafter", "Kundschafter", "einen Kundschafter");
GermanCardNames[CardNames.HAUNTED_WOODS] = new TranslatedCardName("Geisterwald", "Geisterwälder", "einen Geisterwald");
GermanCardNames[CardNames.HERO] = new TranslatedCardName("Held", "Helden", "einen Helden");
GermanCardNames[CardNames.HIRELING] = new TranslatedCardName("Gefolgsmann", "Gefolgsmänner", "einen Gefolgsmann");
GermanCardNames[CardNames.INHERITANCE] = new TranslatedCardName("Erbschaft", "Erbschaften", "eine Erbschaft");
GermanCardNames[CardNames.LOST_ARTS] = new TranslatedCardName("Verlorene Kunst", "Verlorene Künste", "eine Verlorene Kunst");
GermanCardNames[CardNames.LOST_CITY] = new TranslatedCardName("Verlorene Stadt", "Verlorene Städte", "eine Verlorene Stadt");
GermanCardNames[CardNames.MAGPIE] = new TranslatedCardName("Elster", "Elstern", "eine Elster");
GermanCardNames[CardNames.MESSENGER] = new TranslatedCardName("Kurier", "Kuriere", "einen Kurier");
GermanCardNames[CardNames.MISER] = new TranslatedCardName("Geizhals", "Geizhälse", "einen Geizhals");
GermanCardNames[CardNames.MISSION] = new TranslatedCardName("Mission", "Missionen", "eine Mission");
GermanCardNames[CardNames.PATHFINDING] = new TranslatedCardName("Wegsuche", "Wegsuchen", "eine Wegsuche");
GermanCardNames[CardNames.PAGE] = new TranslatedCardName("Page", "Pagen", "einen Pagen");
GermanCardNames[CardNames.PEASANT] = new TranslatedCardName("Kleinbauer", "Kleinbauern", "einen Kleinbauern");
GermanCardNames[CardNames.PILGRIMAGE] = new TranslatedCardName("Pilgerfahrt", "Pilgerfahrten", "eine Pilgerfahrt");
GermanCardNames[CardNames.PLAN] = new TranslatedCardName("Planung", "Planungen", "eine Planung");
GermanCardNames[CardNames.PORT] = new TranslatedCardName("Hafenstadt", "Hafenstädte", "eine Hafenstadt");
GermanCardNames[CardNames.QUEST] = new TranslatedCardName("Quest", "Quests", "eine Quest");
GermanCardNames[CardNames.RANGER] = new TranslatedCardName("Wildhüter", "Wildhüter", "einen Wildhüter");
GermanCardNames[CardNames.RAID] = new TranslatedCardName("Überfall", "Überfälle", "einen Überfall");
GermanCardNames[CardNames.RATCATCHER] = new TranslatedCardName("Rattenfänger", "Rattenfänger", "einen Rattenfänger");
GermanCardNames[CardNames.RAZE] = new TranslatedCardName("Zerstörung", "Zerstörungen", "eine Zerstörung");
GermanCardNames[CardNames.RELIC] = new TranslatedCardName("Relikt", "Relikte", "ein Relikt");
GermanCardNames[CardNames.ROYAL_CARRIAGE] = new TranslatedCardName("Königliche Kutsche", "Königliche Kutschen", "eine Königliche Kutsche");
GermanCardNames[CardNames.SAVE] = new TranslatedCardName("Zuflucht", "Zuflüchte", "eine Zuflucht");
GermanCardNames[CardNames.SCOUTING_PARTY] = new TranslatedCardName("Spähtrupp", "Spähtruppe", "einen Spähtrupp");
GermanCardNames[CardNames.SEAWAY] = new TranslatedCardName("Seeweg", "Seewege", "einen Seeweg");
GermanCardNames[CardNames.SOLDIER] = new TranslatedCardName("Soldat", "Soldaten", "einen Soldaten");
GermanCardNames[CardNames.STORYTELLER] = new TranslatedCardName("Geschichtenerzähler", "Geschichtenerzähler", "einen Geschichtenerzähler");
GermanCardNames[CardNames.SWAMP_HAG] = new TranslatedCardName("Sumpfhexe", "Sumpfhexen", "eine Sumpfhexe");
GermanCardNames[CardNames.TEACHER] = new TranslatedCardName("Lehrer", "Lehrer", "einen Lehrer");
GermanCardNames[CardNames.TRAVELLING_FAIR] = new TranslatedCardName("Wanderzirkus", "Wanderzirkusse", "einen Wanderzirkus");
GermanCardNames[CardNames.TRADE] = new TranslatedCardName("Handel", "Handel", "einen Handel");
GermanCardNames[CardNames.TRAINING] = new TranslatedCardName("Training", "Trainings", "ein Training");
GermanCardNames[CardNames.TRANSMOGRIFY] = new TranslatedCardName("Transformation", "Transformationen", "eine Transformation");
GermanCardNames[CardNames.TREASURE_TROVE] = new TranslatedCardName("Schatz", "Schätze", "einen Schatz");
GermanCardNames[CardNames.TREASURE_HUNTER] = new TranslatedCardName("Schatzsucher", "Schatzsucher", "einen Schatzsucher");
GermanCardNames[CardNames.WARRIOR] = new TranslatedCardName("Krieger", "Krieger", "einen Krieger");
GermanCardNames[CardNames.WINE_MERCHANT] = new TranslatedCardName("Weinhändler", "Weinhändler", "einen Weinhändler");

//Empires
GermanCardNames[CardNames.ENCAMPMENT] = new TranslatedCardName("Feldlager", "Feldlager", "ein Feldlager");
GermanCardNames[CardNames.PLUNDER] = new TranslatedCardName("Diebesgut", "Diebesgüter", "ein Diebesgut");
GermanCardNames[CardNames.PATRICIAN] = new TranslatedCardName("Patrizier", "Patrizier", "einen Patrizier");
GermanCardNames[CardNames.EMPORIUM] = new TranslatedCardName("Handelsplatz", "Handelsplätze", "einen Handelsplatz");
GermanCardNames[CardNames.SETTLERS] = new TranslatedCardName("Siedler", "Siedler", "einen Siedler");
GermanCardNames[CardNames.BUSTLING_VILLAGE] = new TranslatedCardName("Emsiges Dorf", "Emsige Dörfer", "ein Emsiges Dorf");
GermanCardNames[CardNames.CATAPULT] = new TranslatedCardName("Katapult", "Katapulte", "ein Katapult");
GermanCardNames[CardNames.ROCKS] = new TranslatedCardName("Felsen", "Felsen", "einen Felsen");
GermanCardNames[CardNames.GLADIATOR] = new TranslatedCardName("Gladiator", "Gladiatoren", "einen Gladiator");
GermanCardNames[CardNames.FORTUNE] = new TranslatedCardName("Reichtum", "Reichtümer", "einen Reichtum");
GermanCardNames[CardNames.CASTLES] = new TranslatedCardName("Schlösser", "Schlösser", "Schlösser");
GermanCardNames[CardNames.HUMBLE_CASTLE] = new TranslatedCardName("Bescheidenes Schloss", "Bescheidene Schlösser", "ein Bescheidenes Schloss");
GermanCardNames[CardNames.CRUMBLING_CASTLE] = new TranslatedCardName("Verfallendes Schloss", "Verfallende Schlösser", "ein Verfallendes Schloss");
GermanCardNames[CardNames.SMALL_CASTLE] = new TranslatedCardName("Kleines Schloss", "Kleine Schlösser", "ein Kleines Schloss");
GermanCardNames[CardNames.HAUNTED_CASTLE] = new TranslatedCardName("Spukschloss", "Spukschlösser", "ein Spukschloss");
GermanCardNames[CardNames.OPULENT_CASTLE] = new TranslatedCardName("Reiches Schloss", "Reiches Schlösser", "ein Reiches Schloss");
GermanCardNames[CardNames.SPRAWLING_CASTLE] = new TranslatedCardName("Ausgedehntes Schloss", "Ausgedehnte Schlösser", "ein Ausgedehntes Schloss");
GermanCardNames[CardNames.GRAND_CASTLE] = new TranslatedCardName("Prunkschloss", "Prunkschlösser", "ein Prunkschloss");
GermanCardNames[CardNames.KINGS_CASTLE] = new TranslatedCardName("Königsschloss", "Königsschlösser", "ein Königsschloss");
GermanCardNames[CardNames.ADVANCE] = new TranslatedCardName("Aufstieg", "Aufstiege", "einen Aufstieg");
GermanCardNames[CardNames.ANNEX] = new TranslatedCardName("Schlacht", "Schlachten", "eine Schlacht");
GermanCardNames[CardNames.ARCHIVE] = new TranslatedCardName("Archiv", "Archive", "ein Archiv");
GermanCardNames[CardNames.AQUEDUCT] = new TranslatedCardName("Aquädukt", "Aquädukte", "ein Aquädukt");
GermanCardNames[CardNames.ARENA] = new TranslatedCardName("Arena", "Arenen", "eine Arena");
GermanCardNames[CardNames.BANDIT_FORT] = new TranslatedCardName("Räuberfestung", "Räuberfestungen", "eine Räuberfestung");
GermanCardNames[CardNames.BANQUET] = new TranslatedCardName("Bankett", "Bankett", "ein Bankett");
GermanCardNames[CardNames.BASILICA] = new TranslatedCardName("Basilika", "Basilikas", "eine Basilika");
GermanCardNames[CardNames.BATHS] = new TranslatedCardName("Badehaus", "Badehäuser", "ein Badehaus");
GermanCardNames[CardNames.BATTLEFIELD] = new TranslatedCardName("Schlachtfeld", "Schlachtfelder", "ein Schlachtfeld");
GermanCardNames[CardNames.CAPITAL] = new TranslatedCardName("Vermögen", "Vermögen", "ein Vermögen");
GermanCardNames[CardNames.CHARM] = new TranslatedCardName("Zauber", "Zauber", "einen Zauber");
GermanCardNames[CardNames.CHARIOT_RACE] = new TranslatedCardName("Wagenrennen", "Wagenrennen", "ein Wagenrennen");
GermanCardNames[CardNames.CITY_QUARTER] = new TranslatedCardName("Stadtviertel", "Stadtviertel", "ein Stadtviertel");
GermanCardNames[CardNames.COLONNADE] = new TranslatedCardName("Kolonnaden", "Kolonnaden", "eine Kolonnaden");
GermanCardNames[CardNames.CONQUEST] = new TranslatedCardName("Eroberung", "Eroberungen", "eine Eroberung");
GermanCardNames[CardNames.CROWN] = new TranslatedCardName("Krone", "Kronen", "eine Krone");
GermanCardNames[CardNames.DELVE] = new TranslatedCardName("Erforschen", "Erforschen", "ein Erforschen");
GermanCardNames[CardNames.DEFILED_SHRINE] = new TranslatedCardName("Entweihter Schrein", "Entweihte Schreine", "einen Entweihten Schrein");
GermanCardNames[CardNames.DOMINATE] = new TranslatedCardName("Beherrschen", "Beherrschen", "ein Beherrschen");
GermanCardNames[CardNames.DONATE] = new TranslatedCardName("Spende", "Spenden", "eine Spende");
GermanCardNames[CardNames.ENCHANTRESS] = new TranslatedCardName("Zauberin", "Zauberinnen", "eine Zauberin");
GermanCardNames[CardNames.ENGINEER] = new TranslatedCardName("Ingenieurin", "Ingenieurinnen", "eine Ingenieurin");
GermanCardNames[CardNames.FARMERS_MARKET] = new TranslatedCardName("Bauernmarkt", "Bauernmärkte", "einen Bauernmarkt");
GermanCardNames[CardNames.FORUM] = new TranslatedCardName("Forum", "Foren", "ein Forum");
GermanCardNames[CardNames.FOUNTAIN] = new TranslatedCardName("Brunnen", "Brunnen", "einen Brunnen");
GermanCardNames[CardNames.GROUNDSKEEPER] = new TranslatedCardName("Gärtnerin", "Gärtnerinnen", "eine Gärtnerin");
GermanCardNames[CardNames.KEEP] = new TranslatedCardName("Bollwerk", "Bollwerke", "ein Bollwerk");
GermanCardNames[CardNames.LABYRINTH] = new TranslatedCardName("Labyrinth", "Labyrinthe", "ein Labyrinth");
GermanCardNames[CardNames.LEGIONARY] = new TranslatedCardName("Legionär", "Legionäre", "einen Legionär");
GermanCardNames[CardNames.MOUNTAIN_PASS] = new TranslatedCardName("Gebirgspass", "Gebirgspässe", "einen Gebirgspass");
GermanCardNames[CardNames.MUSEUM] = new TranslatedCardName("Museum", "Museen", "ein Museum");
GermanCardNames[CardNames.OBELISK] = new TranslatedCardName("Obelisk", "Obelisken", "einen Obelisken");
GermanCardNames[CardNames.ORCHARD] = new TranslatedCardName("Obstgarten", "Obstgärten", "einen Obstgarten");
GermanCardNames[CardNames.OVERLORD] = new TranslatedCardName("Lehnsherr", "Lehnsherren", "einen Lehnsherren");
GermanCardNames[CardNames.PALACE] = new TranslatedCardName("Palast", "Paläste", "einen Palast");
GermanCardNames[CardNames.RITUAL] = new TranslatedCardName("Ritual", "Rituale", "ein Ritual");
GermanCardNames[CardNames.ROYAL_BLACKSMITH] = new TranslatedCardName("Königlicher Schmied", "Königliche Schmiede", "einen Königlichen Schmied");
GermanCardNames[CardNames.SACRIFICE] = new TranslatedCardName("Opfer", "Opfer", "ein Opfer");
GermanCardNames[CardNames.SALT_THE_EARTH] = new TranslatedCardName("Versalztes Land", "Versalzte Länder", "ein Versalztes Land");
GermanCardNames[CardNames.TAX] = new TranslatedCardName("Steuer", "Steuern", "eine Steuer");
GermanCardNames[CardNames.TEMPLE] = new TranslatedCardName("Tempel", "Tempel", "einen Tempel");
GermanCardNames[CardNames.TOMB] = new TranslatedCardName("Grabmal", "Grabmale", "ein Grabmal");
GermanCardNames[CardNames.TOWER] = new TranslatedCardName("Turm", "Türme", "einen Turm");
GermanCardNames[CardNames.TRIUMPH] = new TranslatedCardName("Siegeszug", "Siegeszüge", "einen Siegeszug");
GermanCardNames[CardNames.TRIUMPHAL_ARCH] = new TranslatedCardName("Triumphbogen", "Triumphbögen", "einen Triumphbogen");
GermanCardNames[CardNames.VILLA] = new TranslatedCardName("Villa", "Villen", "eine Villa");
GermanCardNames[CardNames.WALL] = new TranslatedCardName("Mauer", "Mauern", "eine Mauer");
GermanCardNames[CardNames.WOLF_DEN] = new TranslatedCardName("Wolfsbau", "Wolfsbauten", "einen Wolfsbau");
GermanCardNames[CardNames.WEDDING] = new TranslatedCardName("Hochzeit", "Hochzeiten", "eine Hochzeit");
GermanCardNames[CardNames.WILD_HUNT] = new TranslatedCardName("Wilde Jagd", "Wilde Jagden", "eine Wilde Jagd");
GermanCardNames[CardNames.WINDFALL] = new TranslatedCardName("Glücksfall", "Glücksfälle", "einen Glücksfall");

//Nocturne
GermanCardNames[CardNames.BOON_DRAWPILE] = new TranslatedCardName("Gaben-Stapel", "Gaben-Stapel", "ein Gaben-Stapel");
GermanCardNames[CardNames.BOON_DISCARDPILE] = new TranslatedCardName("Gaben-Ablagestapel", "Gaben-Ablagestapel", "ein Gaben-Ablagestapel");
GermanCardNames[CardNames.THE_EARTHS_GIFT] = new TranslatedCardName("Geschenk der Erde", "Geschenke der Erde", "das Geschenk der Erde");
GermanCardNames[CardNames.THE_FIELDS_GIFT] = new TranslatedCardName("Geschenk des Feldes", "Geschenke des Feldes", "das Geschenk des Feldes");
GermanCardNames[CardNames.THE_FLAMES_GIFT] = new TranslatedCardName("Geschenk des Feuers", "Geschenke des Feuers", "das Geschenk des Feuers");
GermanCardNames[CardNames.THE_FORESTS_GIFT] = new TranslatedCardName("Geschenk des Waldes", "Geschenke des Waldes", "das Geschenk des Waldes");
GermanCardNames[CardNames.THE_MOONS_GIFT] = new TranslatedCardName("Geschenk des Mondes", "Geschenke des Mondes", "das Geschenk des Mondes");
GermanCardNames[CardNames.THE_MOUNTAINS_GIFT] = new TranslatedCardName("Geschenk des Berges", "Geschenke des Berges", "das Geschenk des Berges");
GermanCardNames[CardNames.THE_RIVERS_GIFT] = new TranslatedCardName("Geschenk des Flusses", "Geschenke des Flusses", "das Geschenk des Flusses");
GermanCardNames[CardNames.THE_SEAS_GIFT] = new TranslatedCardName("Geschenk des Meeres", "Geschenke des Meeres", "das Geschenk des Meeres");
GermanCardNames[CardNames.THE_SKYS_GIFT] = new TranslatedCardName("Geschenk des Himmels", "Geschenke des Himmels", "das Geschenk des Himmels");
GermanCardNames[CardNames.THE_SUNS_GIFT] = new TranslatedCardName("Geschenk der Sonne", "Geschenke der Sonne", "das Geschenk der Sonne");
GermanCardNames[CardNames.THE_SWAMPS_GIFT] = new TranslatedCardName("Geschenk des Sumpfes", "Geschenke des Sumpfes", "das Geschenk des Sumpfes");
GermanCardNames[CardNames.THE_WINDS_GIFT] = new TranslatedCardName("Geschenk des Windes", "Geschenke des Windes", "das Geschenk des Windes");
GermanCardNames[CardNames.HEX_DRAWPILE] = new TranslatedCardName("Plagen-Stapel", "Plagen-Stapel", "ein Plagen-Stapel");
GermanCardNames[CardNames.HEX_DISCARDPILE] = new TranslatedCardName("Plagen-Ablagestapel", "Plagen-Ablagestapel", "ein Plagen-Ablagestapel");
GermanCardNames[CardNames.BAD_OMENS] = new TranslatedCardName("Schlechtes Omen", "Schlechte Omen", "ein Schlechtes Omen");
GermanCardNames[CardNames.DELUSION] = new TranslatedCardName("Täuschung", "Täuschungen", "Täuschung");
GermanCardNames[CardNames.ENVY] = new TranslatedCardName("Neid", "Neid", "Neid");
GermanCardNames[CardNames.FAMINE] = new TranslatedCardName("Hungersnot", "Hungersnöte", "Hungersnot");
GermanCardNames[CardNames.FEAR] = new TranslatedCardName("Furcht", "Furcht", "Furcht");
GermanCardNames[CardNames.GREED] = new TranslatedCardName("Gier", "Gier", "Gier");
GermanCardNames[CardNames.HAUNTING] = new TranslatedCardName("Spuk", "Spuke", "Spuk");
GermanCardNames[CardNames.LOCUSTS] = new TranslatedCardName("Heuschrecken", "Heuschrecken", "Heuschrecken");
GermanCardNames[CardNames.MISERY] = new TranslatedCardName("Elend", "Elend", "Elend");
GermanCardNames[CardNames.PLAGUE] = new TranslatedCardName("Seuche", "Seuchen", "Seuche");
GermanCardNames[CardNames.POVERTY] = new TranslatedCardName("Armut", "Armut", "Armut");
GermanCardNames[CardNames.WAR] = new TranslatedCardName("Krieg", "Kriege", "Krieg");
GermanCardNames[CardNames.MISERABLE] = new TranslatedCardName("Elendig", "Elendig", "Elendig");
GermanCardNames[CardNames.TWICE_MISERABLE] = new TranslatedCardName("Doppelt Elendig", "Doppelt Elendig", "Doppelt Elendig");
GermanCardNames[CardNames.ENVIOUS] = new TranslatedCardName("Neidisch", "Neidisch", "Neidisch");
GermanCardNames[CardNames.DELUDED] = new TranslatedCardName("Getäuscht", "Getäuscht", "Getäuscht");
GermanCardNames[CardNames.LOST_IN_THE_WOODS] = new TranslatedCardName("Im Wald verirrt", "Im Wald verirrt", "Im Wald verirrt");
GermanCardNames[CardNames.BARD] = new TranslatedCardName("Minnesängerin", "Minnesängerinnen", "eine Minnesängerin");
GermanCardNames[CardNames.BLESSED_VILLAGE] = new TranslatedCardName("Seliges Dorf", "Selige Dörfer", "ein Seliges Dorf");
GermanCardNames[CardNames.CHANGELING] = new TranslatedCardName("Wechselbalg", "Wechselbälge", "einen Wechselbalg");
GermanCardNames[CardNames.CEMETERY] = new TranslatedCardName("Friedhof", "Friedhöfe", "einen Friedhof");
GermanCardNames[CardNames.COBBLER] = new TranslatedCardName("Schuster", "Schuster", "einen Schuster");
GermanCardNames[CardNames.CONCLAVE] = new TranslatedCardName("Konklave", "Konklaven", "ein Konklave");
GermanCardNames[CardNames.CRYPT] = new TranslatedCardName("Krypta", "Krypten", "eine Krypta");
GermanCardNames[CardNames.CURSED_VILLAGE] = new TranslatedCardName("Verfluchtes Dorf", "Verfluchte Dörfer", "ein Verfluchtes Dorf");
GermanCardNames[CardNames.DEN_OF_SIN] = new TranslatedCardName("Sündenpfuhl", "Sündenpfuhle", "einen Sündenpfuhl");
GermanCardNames[CardNames.DEVILS_WORKSHOP] = new TranslatedCardName("Teufelswerkstatt", "Teufelswerkstätten", "eine Teufelswerkstatt");
GermanCardNames[CardNames.DRUID] = new TranslatedCardName("Druidin", "Druidinnen", "eine Druidin");
GermanCardNames[CardNames.EXORCIST] = new TranslatedCardName("Exorzistin", "Exorzistinnen", "eine Exorzistin");
GermanCardNames[CardNames.FAITHFUL_HOUND] = new TranslatedCardName("Getreuer Hund", "Getreue Hunde", "einen Getreuen Hund");
GermanCardNames[CardNames.FOOL] = new TranslatedCardName("Narr", "Narren", "einen Narren");
GermanCardNames[CardNames.GHOST_TOWN] = new TranslatedCardName("Geisterstadt", "Geisterstädte", "eine Geisterstadt");
GermanCardNames[CardNames.GUARDIAN] = new TranslatedCardName("Wächterin", "Wächterinnen", "eine Wächterin");
GermanCardNames[CardNames.IDOL] = new TranslatedCardName("Götze", "Götzen", "einen Götzen");
GermanCardNames[CardNames.LEPRECHAUN] = new TranslatedCardName("Kobold", "Kobolde", "einen Kobold");
GermanCardNames[CardNames.MONASTERY] = new TranslatedCardName("Kloster", "Klöster", "ein Kloster");
GermanCardNames[CardNames.NECROMANCER] = new TranslatedCardName("Totenbeschwörer", "Totenbeschwörer", "einen Totenbeschwörer");
GermanCardNames[CardNames.NIGHT_WATCHMAN] = new TranslatedCardName("Nachtwache", "Nachtwachen", "eine Nachtwache");
GermanCardNames[CardNames.PIXIE] = new TranslatedCardName("Fee", "Feen", "eine Fee");
GermanCardNames[CardNames.POOKA] = new TranslatedCardName("Puka", "Pukas", "eine Puka");
GermanCardNames[CardNames.RAIDER] = new TranslatedCardName("Plünderer", "Plünderer", "einen Plünderer");
GermanCardNames[CardNames.SACRED_GROVE] = new TranslatedCardName("Heiliger Hain", "Heilige Haine", "einen Heiligen Hain");
GermanCardNames[CardNames.SECRET_CAVE] = new TranslatedCardName("Geheime Höhle", "Geheime Höhlen", "eine Geheime Höhle");
GermanCardNames[CardNames.SHEPHERD] = new TranslatedCardName("Schäferin", "Schäferinnen", "eine Schäferin");
GermanCardNames[CardNames.SKULK] = new TranslatedCardName("Attentäter", "Attentäter", "einen Attentäter");
GermanCardNames[CardNames.TORMENTOR] = new TranslatedCardName("Folterknecht", "Folterknechte", "einen Folterknecht");
GermanCardNames[CardNames.TRAGIC_HERO] = new TranslatedCardName("Tragischer Held", "Tragische Helden", "einen Tragischen Helden");
GermanCardNames[CardNames.TRACKER] = new TranslatedCardName("Fährtensucher", "Fährtensucher", "einen Fährtensucher");
GermanCardNames[CardNames.VAMPIRE] = new TranslatedCardName("Vampirin", "Vampirinnen", "eine Vampirin");
GermanCardNames[CardNames.WEREWOLF] = new TranslatedCardName("Werwolf", "Werwölfe", "einen Werwolf");
GermanCardNames[CardNames.CURSED_GOLD] = new TranslatedCardName("Verfluchtes Gold", "Verfluchte Gold", "ein Verfluchtes Gold");
GermanCardNames[CardNames.GOAT] = new TranslatedCardName("Ziege", "Ziegen", "eine Ziege");
GermanCardNames[CardNames.HAUNTED_MIRROR] = new TranslatedCardName("Zauberspiegel", "Zauberspiegel", "einen Zauberspiegel");
GermanCardNames[CardNames.LUCKY_COIN] = new TranslatedCardName("Glückstaler", "Glückstaler", "einen Glückstaler");
GermanCardNames[CardNames.MAGIC_LAMP] = new TranslatedCardName("Wunderlampe", "Wunderlampen", "eine Wunderlampe");
GermanCardNames[CardNames.PASTURE] = new TranslatedCardName("Weideland", "Weideländer", "ein Weideland");
GermanCardNames[CardNames.POUCH] = new TranslatedCardName("Beutel", "Beutel", "einen Beutel");
GermanCardNames[CardNames.BAT] = new TranslatedCardName("Fledermaus", "Fledermäuse", "eine Fledermaus");
GermanCardNames[CardNames.GHOST] = new TranslatedCardName("Geist", "Geister", "einen Geist");
GermanCardNames[CardNames.IMP] = new TranslatedCardName("Teufelchen", "Teufelchen", "ein Teufelchen");
GermanCardNames[CardNames.WILL_O_WISP] = new TranslatedCardName("Irrlicht", "Irrlichter", "ein Irrlicht");
GermanCardNames[CardNames.WISH] = new TranslatedCardName("Wunsch", "Wünsche", "einen Wunsch");
GermanCardNames[CardNames.ZOMBIE_APPRENTICE] = new TranslatedCardName("Zombie-Lehrling", "Zombie-Lehrlinge", "einen Zombie-Lehrling");
GermanCardNames[CardNames.ZOMBIE_MASON] = new TranslatedCardName("Zombie-Maurer", "Zombie-Maurer", "einen Zombi-Maurer");
GermanCardNames[CardNames.ZOMBIE_SPY] = new TranslatedCardName("Zombie-Spion", "Zombie-Spione", "einen Zombie-Spion");

//Renaissance
GermanCardNames[CardNames.ACTING_TROUPE] = new TranslatedCardName("Schauspieltruppe", "Schauspieltruppen", "eine Schauspieltruppe");
GermanCardNames[CardNames.BORDER_GUARD] = new TranslatedCardName("Grenzposten", "Grenzposten", "einen Grenzposten");
GermanCardNames[CardNames.CARGO_SHIP] = new TranslatedCardName("Frachtschiff", "Frachtschiffe", "ein Frachtschiff");
GermanCardNames[CardNames.DUCAT] = new TranslatedCardName("Goldmünze", "Goldmünzen", "eine Goldmünze");
GermanCardNames[CardNames.EXPERIMENT] = new TranslatedCardName("Experiment", "Experimente", "ein Experiment");
GermanCardNames[CardNames.FLAG_BEARER] = new TranslatedCardName("Fahnenträger", "Fahnenträger", "einen Fahnenträger");
GermanCardNames[CardNames.HIDEOUT] = new TranslatedCardName("Versteck", "Verstecke", "ein Versteck");
GermanCardNames[CardNames.INVENTOR] = new TranslatedCardName("Erfinder", "Erfinder", "einen Erfinder");
GermanCardNames[CardNames.IMPROVE] = new TranslatedCardName("Fortschritt", "Fortschritte", "einen Fortschritt");
GermanCardNames[CardNames.LACKEYS] = new TranslatedCardName("Diener", "Diener", "Diener");
GermanCardNames[CardNames.MOUNTAIN_VILLAGE] = new TranslatedCardName("Bergdorf", "Bergdörfer", "ein Bergdorf");
GermanCardNames[CardNames.PATRON] = new TranslatedCardName("Patron", "Patrone", "einen Patron");
GermanCardNames[CardNames.PRIEST] = new TranslatedCardName("Priester", "Priester", "einen Priester");
GermanCardNames[CardNames.RESEARCH] = new TranslatedCardName("Forscherin", "Forscherinnen", "eine Forscherin");
GermanCardNames[CardNames.SILK_MERCHANT] = new TranslatedCardName("Seidenhändlerin", "Seidenhändlerinnen", "eine Seidenhändlerin");
GermanCardNames[CardNames.OLD_WITCH] = new TranslatedCardName("Alte Hexe", "Alte Hexen", "eine Alte Hexe");
GermanCardNames[CardNames.RECRUITER] = new TranslatedCardName("Anwerber", "Anwerber", "einen Anwerber");
GermanCardNames[CardNames.SCEPTER] = new TranslatedCardName("Zepter", "Zepter", "ein Zepter");
GermanCardNames[CardNames.SCHOLAR] = new TranslatedCardName("Gelehrte", "Gelehrte", "eine Gelehrte");
GermanCardNames[CardNames.SCULPTOR] = new TranslatedCardName("Bildhauerin", "Bildhauerinnen", "eine Bildhauerin");
GermanCardNames[CardNames.SEER] = new TranslatedCardName("Seher", "Seher", "einen Seher");
GermanCardNames[CardNames.SPICES] = new TranslatedCardName("Gewürze", "Gewürze", "Gewürze");
GermanCardNames[CardNames.SWASHBUCKLER] = new TranslatedCardName("Freibeuterin", "Freibeuterinnen", "eine Freibeuterin");
GermanCardNames[CardNames.TREASURER] = new TranslatedCardName("Schatzmeisterin", "Schatzmeisterinnen", "eine Schatzmeisterin");
GermanCardNames[CardNames.VILLAIN] = new TranslatedCardName("Schwarzer Meister", "Schwarze Meister", "einen Schwarzen Meister");
GermanCardNames[CardNames.FLAG] = new TranslatedCardName("Fahne", "Fahnen", "die Fahne");
GermanCardNames[CardNames.HORN] = new TranslatedCardName("Horn", "Hörner", "das Horn");
GermanCardNames[CardNames.KEY] = new TranslatedCardName("Schlüssel", "Schlüssel", "den Schlüssel");
GermanCardNames[CardNames.LANTERN] = new TranslatedCardName("Laterne", "Laternen", "die Laterne");
GermanCardNames[CardNames.TREASURE_CHEST] = new TranslatedCardName("Schatzkiste", "Schatzkisten", "die Schatzkiste");
GermanCardNames[CardNames.ACADEMY] = new TranslatedCardName("Akademie");
GermanCardNames[CardNames.BARRACKS] = new TranslatedCardName("Kaserne");
GermanCardNames[CardNames.CANAL] = new TranslatedCardName("Kanal");
GermanCardNames[CardNames.CAPITALISM] = new TranslatedCardName("Kapitalismus");
GermanCardNames[CardNames.CATHEDRAL] = new TranslatedCardName("Kathedrale");
GermanCardNames[CardNames.CITADEL] = new TranslatedCardName("Zitadelle");
GermanCardNames[CardNames.CITY_GATE] = new TranslatedCardName("Stadttor");
GermanCardNames[CardNames.CROP_ROTATION] = new TranslatedCardName("Fruchtwechsel");
GermanCardNames[CardNames.EXPLORATION] = new TranslatedCardName("Erkundung");
GermanCardNames[CardNames.FAIR] = new TranslatedCardName("Kleiner Markt");
GermanCardNames[CardNames.FLEET] = new TranslatedCardName("Flotte");
GermanCardNames[CardNames.GUILDHALL] = new TranslatedCardName("Gildenhaus");
GermanCardNames[CardNames.INNOVATION] = new TranslatedCardName("Innovation");
GermanCardNames[CardNames.PAGEANT] = new TranslatedCardName("Festzug");
GermanCardNames[CardNames.PIAZZA] = new TranslatedCardName("Piazza");
GermanCardNames[CardNames.ROAD_NETWORK] = new TranslatedCardName("Straßennetz");
GermanCardNames[CardNames.SEWERS] = new TranslatedCardName("Abwassertunnel");
GermanCardNames[CardNames.SILOS] = new TranslatedCardName("Speicher");
GermanCardNames[CardNames.SINISTER_PLOT] = new TranslatedCardName("Finsterer Plan");
GermanCardNames[CardNames.STAR_CHART] = new TranslatedCardName("Sternenkarte");

//Promos
GermanCardNames[CardNames.AVANTO] = new TranslatedCardName("Eisloch", "Eislöcher", "ein Eisloch");
GermanCardNames[CardNames.BLACK_MARKET] = new TranslatedCardName("Schwarzmarkt", "Schwarzmärkte", "einen Schwarzmarkt");
GermanCardNames[CardNames.ENVOY] = new TranslatedCardName("Gesandter", "Gesandte", "einen Gesandten");
GermanCardNames[CardNames.GOVERNOR] = new TranslatedCardName("Gouverneur", "Gouverneure", "einen Gouverneur");
GermanCardNames[CardNames.PRINCE] = new TranslatedCardName("Prinz", "Prinzen", "einen Prinz");
GermanCardNames[CardNames.SAUNA] = new TranslatedCardName("Sauna", "Saunen", "eine Sauna");
GermanCardNames[CardNames.STASH] = new TranslatedCardName("Geldversteck", "Geldverstecke", "ein Geldversteck");
GermanCardNames[CardNames.SUMMON] = new TranslatedCardName("Einladung", "Einladungen", "eine Einladung");
GermanCardNames[CardNames.WALLED_VILLAGE] = new TranslatedCardName("Carcassonne", "Carcassonnes", "ein Carcassonne");
GermanCardNames[CardNames.BLACK_MARKET_PILE] = new TranslatedCardName("Schwarzmarkt-Stapel", "Schwarzmarkt-Stapel", "einen Schwarzmarkt-Stapel");
GermanCardNames[CardNames.DISMANTLE] = new TranslatedCardName("Abbruch", "Abbrüche", "einen Abbruch");
GermanCardNames[CardNames.CHURCH] = new TranslatedCardName("Schweriner Dom", "Schweriner Dome", "den Schweriner Dom");
GermanCardNames[CardNames.CAPTAIN] = new TranslatedCardName("Kapitän Tobias", "Kapitäne Tobias", "den Kapitän Tobias");

//Menagerie
GermanCardNames[CardNames.BLACK_CAT] = new TranslatedCardName("Schwarze Katze", "Schwarze Katzen", "eine Schwarze Katze");
GermanCardNames[CardNames.SLEIGH] = new TranslatedCardName("Schlitten", "Schlitten", "einen Schlitten");
GermanCardNames[CardNames.SUPPLIES] = new TranslatedCardName("Nachschub", "Nachschübe", "einen Nachschub");
GermanCardNames[CardNames.CAMEL_TRAIN] = new TranslatedCardName("Kamelzug", "Kamelzüge", "einen Kamelzug");
GermanCardNames[CardNames.GOATHERD] = new TranslatedCardName("Ziegenhirtin", "Ziegenhirtinnen", "eine Ziegenhirtin");
GermanCardNames[CardNames.SCRAP] = new TranslatedCardName("Schrott", "Schrott", "Schrott");
GermanCardNames[CardNames.SHEEPDOG] = new TranslatedCardName("Hirtenhund", "Hirtenhunde", "einen Hirtenhund");
GermanCardNames[CardNames.SNOWY_VILLAGE] = new TranslatedCardName("Verschneites Dorf", "Verschneite Dörfer", "ein Verschneites Dorf");
GermanCardNames[CardNames.STOCKPILE] = new TranslatedCardName("Depot", "Depots", "ein Depot");
GermanCardNames[CardNames.BOUNTY_HUNTER] = new TranslatedCardName("Kopfgeldjägerin", "Kopfgeldjägerinnen", "eine Kopfgeldjägerin");
GermanCardNames[CardNames.CARDINAL] = new TranslatedCardName("Kardinal", "Kardinäle", "einen Kardinal");
GermanCardNames[CardNames.CAVALRY] = new TranslatedCardName("Kavallerie", "Kavallerien", "eine Kavallerie");
GermanCardNames[CardNames.GROOM] = new TranslatedCardName("Stallbursche", "Stallburschen", "einen Stallburschen");
GermanCardNames[CardNames.HOSTELRY] = new TranslatedCardName("Herberge", "Herbergen", "eine Herberge");
GermanCardNames[CardNames.VILLAGE_GREEN] = new TranslatedCardName("Dorfanger", "Dorfanger", "einen Dorfanger");
GermanCardNames[CardNames.BARGE] = new TranslatedCardName("Lastkahn", "Lastkähne", "einen Lastkahn");
GermanCardNames[CardNames.COVEN] = new TranslatedCardName("Hexenzirkel", "Hexenzirkel", "einen Hexenzirkel");
GermanCardNames[CardNames.DISPLACE] = new TranslatedCardName("Vertreibung", "Vertreibungen", "eine Vertreibung");
GermanCardNames[CardNames.FALCONER] = new TranslatedCardName("Falknerin", "Falknerinnen", "eine Falknerin");
GermanCardNames[CardNames.FISHERMAN] = new TranslatedCardName("Fischer", "Fischer", "einen Fischer");
GermanCardNames[CardNames.GATEKEEPER] = new TranslatedCardName("Wache", "Wachen", "eine Wache");
GermanCardNames[CardNames.HUNTING_LODGE] = new TranslatedCardName("Jagdhütte", "Jagdhütten", "eine Jagdhütte");
GermanCardNames[CardNames.KILN] = new TranslatedCardName("Brennofen", "Brennöfen", "einen Brennofen");
GermanCardNames[CardNames.LIVERY] = new TranslatedCardName("Pferdestall", "Pferdeställe", "einen Pferdestall");
GermanCardNames[CardNames.MASTERMIND] = new TranslatedCardName("Drahtzieher", "Drahtzieher", "einen Drahtzieher");
GermanCardNames[CardNames.PADDOCK] = new TranslatedCardName("Koppel", "Koppeln", "eine Koppel");
GermanCardNames[CardNames.SANCTUARY] = new TranslatedCardName("Zufluchtsort", "Zufluchtsorte", "einen Zufluchtsort");
GermanCardNames[CardNames.DESTRIER] = new TranslatedCardName("Schlachtross", "Schlachtrösser", "ein Schlachtross");
GermanCardNames[CardNames.WAYFARER] = new TranslatedCardName("Wanderin", "Wanderinnen", "eine Wanderin");
GermanCardNames[CardNames.ANIMAL_FAIR] = new TranslatedCardName("Viehmarkt", "Viehmärkte", "einen Viehmarkt");
GermanCardNames[CardNames.HORSE] = new TranslatedCardName("Pferd", "Pferde", "ein Pferd");
GermanCardNames[CardNames.WAY_OF_THE_BUTTERFLY] = new TranslatedCardName("Weg des Schmetterlings");
GermanCardNames[CardNames.WAY_OF_THE_CAMEL] = new TranslatedCardName("Weg des Kamels");
GermanCardNames[CardNames.WAY_OF_THE_CHAMELEON] = new TranslatedCardName("Weg des Chamäleons");
GermanCardNames[CardNames.WAY_OF_THE_FROG] = new TranslatedCardName("Weg des Frosches");
GermanCardNames[CardNames.WAY_OF_THE_GOAT] = new TranslatedCardName("Weg der Ziege");
GermanCardNames[CardNames.WAY_OF_THE_HORSE] = new TranslatedCardName("Weg des Pferdes");
GermanCardNames[CardNames.WAY_OF_THE_MOLE] = new TranslatedCardName("Weg des Maulwurfs");
GermanCardNames[CardNames.WAY_OF_THE_MONKEY] = new TranslatedCardName("Weg des Affen");
GermanCardNames[CardNames.WAY_OF_THE_MOUSE] = new TranslatedCardName("Weg der Maus");
GermanCardNames[CardNames.WAY_OF_THE_MULE] = new TranslatedCardName("Weg des Maultiers");
GermanCardNames[CardNames.WAY_OF_THE_OTTER] = new TranslatedCardName("Weg des Otters");
GermanCardNames[CardNames.WAY_OF_THE_OWL] = new TranslatedCardName("Weg der Eule");
GermanCardNames[CardNames.WAY_OF_THE_OX] = new TranslatedCardName("Weg des Ochsen");
GermanCardNames[CardNames.WAY_OF_THE_PIG] = new TranslatedCardName("Weg des Schweins");
GermanCardNames[CardNames.WAY_OF_THE_RAT] = new TranslatedCardName("Weg der Ratte");
GermanCardNames[CardNames.WAY_OF_THE_SEAL] = new TranslatedCardName("Weg der Robbe");
GermanCardNames[CardNames.WAY_OF_THE_SHEEP] = new TranslatedCardName("Weg des Schafs");
GermanCardNames[CardNames.WAY_OF_THE_SQUIRREL] = new TranslatedCardName("Weg des Eichhörnchens");
GermanCardNames[CardNames.WAY_OF_THE_TURTLE] = new TranslatedCardName("Weg der Schildkröte");
GermanCardNames[CardNames.WAY_OF_THE_WORM] = new TranslatedCardName("Weg des Wurms");
GermanCardNames[CardNames.DELAY] = new TranslatedCardName("Verzögerung");
GermanCardNames[CardNames.DESPERATION] = new TranslatedCardName("Verzweiflung");
GermanCardNames[CardNames.GAMBLE] = new TranslatedCardName("Wagnis");
GermanCardNames[CardNames.PURSUE] = new TranslatedCardName("Verfolgung");
GermanCardNames[CardNames.RIDE] = new TranslatedCardName("Ausritt");
GermanCardNames[CardNames.TOIL] = new TranslatedCardName("Plackerei");
GermanCardNames[CardNames.ENHANCE] = new TranslatedCardName("Verbesserung");
GermanCardNames[CardNames.MARCH] = new TranslatedCardName("Fußmarsch");
GermanCardNames[CardNames.TRANSPORT] = new TranslatedCardName("Transport");
GermanCardNames[CardNames.BANISH] = new TranslatedCardName("Verbannung");
GermanCardNames[CardNames.BARGAIN] = new TranslatedCardName("Gutes Geschäft");
GermanCardNames[CardNames.INVEST] = new TranslatedCardName("Investition");
GermanCardNames[CardNames.SEIZE_THE_DAY] = new TranslatedCardName("Nutze den Tag");
GermanCardNames[CardNames.COMMERCE] = new TranslatedCardName("Kommerz");
GermanCardNames[CardNames.DEMAND] = new TranslatedCardName("Forderung");
GermanCardNames[CardNames.STAMPEDE] = new TranslatedCardName("Stampede");
GermanCardNames[CardNames.REAP] = new TranslatedCardName("Reiche Ernte");
GermanCardNames[CardNames.ENCLAVE] = new TranslatedCardName("Enklave");
GermanCardNames[CardNames.ALLIANCE] = new TranslatedCardName("Bündnis");
GermanCardNames[CardNames.POPULATE] = new TranslatedCardName("Besiedlung");

GermanCardNames[CardNames.CARD_OF_THE_BUTTERFLY] = "";
GermanCardNames[CardNames.CARD_OF_THE_CAMEL] = "";
GermanCardNames[CardNames.CARD_OF_THE_CHAMELEON] = "";
GermanCardNames[CardNames.CARD_OF_THE_FROG] = "";
GermanCardNames[CardNames.CARD_OF_THE_GOAT] = "";
GermanCardNames[CardNames.CARD_OF_THE_HORSE] = "";
GermanCardNames[CardNames.CARD_OF_THE_MOLE] = "";
GermanCardNames[CardNames.CARD_OF_THE_MONKEY] = "";
GermanCardNames[CardNames.CARD_OF_THE_MOUSE] = "";
GermanCardNames[CardNames.CARD_OF_THE_MULE] = "";
GermanCardNames[CardNames.CARD_OF_THE_OTTER] = "";
GermanCardNames[CardNames.CARD_OF_THE_OWL] = "";
GermanCardNames[CardNames.CARD_OF_THE_OX] = "";
GermanCardNames[CardNames.CARD_OF_THE_PIG] = "";
GermanCardNames[CardNames.CARD_OF_THE_RAT] = "";
GermanCardNames[CardNames.CARD_OF_THE_SEAL] = "";
GermanCardNames[CardNames.CARD_OF_THE_SHEEP] = "";
GermanCardNames[CardNames.CARD_OF_THE_SQUIRREL] = "";
GermanCardNames[CardNames.CARD_OF_THE_TURTLE] = "";
GermanCardNames[CardNames.CARD_OF_THE_WORM] = "";

//Allies
GermanCardNames[CardNames.TOWN] = new TranslatedCardName("Ortschaft", "Ortschaften", "eine Ortschaft");
GermanCardNames[CardNames.MODIFY] = new TranslatedCardName("Umgestaltung", "Umgestaltungen", "eine Umgestaltung");
GermanCardNames[CardNames.MERCHANT_CAMP] = new TranslatedCardName("Händlerlager", "Händlerlager", "ein Händlerlager");
GermanCardNames[CardNames.SWAP] = new TranslatedCardName("Tausch", "Täusche", "einen Tausch");
GermanCardNames[CardNames.SKIRMISHER] = new TranslatedCardName("Aufwiegler", "Aufwiegler", "einen Aufwiegler");
GermanCardNames[CardNames.HIGHWAYMAN] = new TranslatedCardName("Wegelagerer", "Wegelagerer", "einen Wegelagerer");
GermanCardNames[CardNames.BARBARIAN] = new TranslatedCardName("Barbar", "Barbaren", "einen Barbar");
GermanCardNames[CardNames.INNKEEPER] = new TranslatedCardName("Wirtin", "Wirtinnen", "eine Wirtin");
GermanCardNames[CardNames.CAPITAL_CITY] = new TranslatedCardName("Hauptstadt", "Hauptstädte", "eine Hauptstadt");
GermanCardNames[CardNames.SPECIALIST] = new TranslatedCardName("Spezialistin", "Spezialistinnen", "eine Spezialistin");
GermanCardNames[CardNames.CARPENTER] = new TranslatedCardName("Schreinerin", "Schreinerinnen", "eine Schreinerin");
GermanCardNames[CardNames.ROYAL_GALLEY] = new TranslatedCardName("Königliche Galeere", "Königliche Galeeren", "eine Königliche Galeere");
GermanCardNames[CardNames.MARQUIS] = new TranslatedCardName("Marquis", "Marquis", "einen Marquis");
GermanCardNames[CardNames.SENTINEL] = new TranslatedCardName("Wächter", "Wächter", "einen Wächter");
GermanCardNames[CardNames.COURIER] = new TranslatedCardName("Botin", "Botinnen", "eine Botin");
GermanCardNames[CardNames.GALLERIA] = new TranslatedCardName("Markthalle", "Markthallen", "eine Markthalle");
GermanCardNames[CardNames.HUNTER] = new TranslatedCardName("Jägerin", "Jägerinnen", "eine Jägerin");

GermanCardNames[CardNames.SYCOPHANT] = new TranslatedCardName("Schmeichler", "Schmeichler", "einen Schmeichler");
GermanCardNames[CardNames.UNDERLING] = new TranslatedCardName("Untergebener", "Untergebene", "einen Untergebenen");
GermanCardNames[CardNames.IMPORTER] = new TranslatedCardName("Importeurin", "Importeurinnen", "eine Importeurin");
GermanCardNames[CardNames.CONTRACT] = new TranslatedCardName("Vertrag", "Verträge", "einen Vertrag");
GermanCardNames[CardNames.EMISSARY] = new TranslatedCardName("Unterhändlerin", "Unterhändlerinnen", "eine Unterhändlerin");
GermanCardNames[CardNames.BAUBLE] = new TranslatedCardName("Tand", "Tand", "ein Tand");
GermanCardNames[CardNames.BROKER] = new TranslatedCardName("Mittelsmann", "Mittelsmänner", "einen Mittelsmann");
GermanCardNames[CardNames.GUILDMASTER] = new TranslatedCardName("Gildemeisterin", "Gildemeisterinnen", "eine Gildemeisterin");

GermanCardNames[CardNames.ARCHITECTS_GUILD] = new TranslatedCardName("Architektengilde");
GermanCardNames[CardNames.ORDER_OF_ASTROLOGERS] = new TranslatedCardName("Sterndeuterorden");
GermanCardNames[CardNames.LEAGUE_OF_BANKERS] = new TranslatedCardName("Bankiersverbund");
GermanCardNames[CardNames.MOUNTAIN_FOLK] = new TranslatedCardName("Bergvolk");
GermanCardNames[CardNames.WOODWORKERS_GUILD] = new TranslatedCardName("Holzarbeitergilde");
GermanCardNames[CardNames.PEACEFUL_CULT] = new TranslatedCardName("Friedlicher Kult");
GermanCardNames[CardNames.CRAFTERS_GUILD] = new TranslatedCardName("Handwerkergilde");
GermanCardNames[CardNames.CAVE_DWELLERS] = new TranslatedCardName("Höhlenbewohner");
GermanCardNames[CardNames.PLATEAU_SHEPHERDS] = new TranslatedCardName("Schäfer der Hochebene");
GermanCardNames[CardNames.DESERT_GUIDES] = new TranslatedCardName("Wüstenführer");
GermanCardNames[CardNames.FAMILY_OF_INVENTORS] = new TranslatedCardName("Erfinderfamilie");
GermanCardNames[CardNames.ISLAND_FOLK] = new TranslatedCardName("Inselvolk");
GermanCardNames[CardNames.ORDER_OF_MASONS] = new TranslatedCardName("Freimaurerloge");
GermanCardNames[CardNames.BAND_OF_NOMADS] = new TranslatedCardName("Nomadenstamm");
GermanCardNames[CardNames.GANG_OF_PICKPOCKETS] = new TranslatedCardName("Taschendiebe");
GermanCardNames[CardNames.MARKET_TOWNS] = new TranslatedCardName("Marktstädte");
GermanCardNames[CardNames.FOREST_DWELLERS] = new TranslatedCardName("Waldbewohner");
GermanCardNames[CardNames.FELLOWSHIP_OF_SCRIBES] = new TranslatedCardName("Gefolgschaft der Schreiber");
GermanCardNames[CardNames.LEAGUE_OF_SHOPKEEPERS] = new TranslatedCardName("Bund der Ladenbesitzer");
GermanCardNames[CardNames.COASTAL_HAVEN] = new TranslatedCardName("Höhlenhafen");
GermanCardNames[CardNames.CITY_STATE] = new TranslatedCardName("Stadtstaat");
GermanCardNames[CardNames.TRAPPERS_LODGE] = new TranslatedCardName("Hütte der Fallensteller");
GermanCardNames[CardNames.CIRCLE_OF_WITCHES] = new TranslatedCardName("Hexenbund");

GermanCardNames[CardNames.AUGUR_PILE] = new TranslatedCardName("Augurinnen");
GermanCardNames[CardNames.HERB_GATHERER] = new TranslatedCardName("Kräutersammlerin", "Kräutersammlerinnen", "eine Kräutersammlerin");
GermanCardNames[CardNames.ACOLYTE] = new TranslatedCardName("Altardienerin", "Altardienerinnen", "eine Altardienerin");
GermanCardNames[CardNames.SORCERESS] = new TranslatedCardName("Meisterhexe", "Meisterhexen", "eine Meisterhexe");
GermanCardNames[CardNames.SIBYL] = new TranslatedCardName("Prophetin", "Prophetinnen", "eine Prophetin");
GermanCardNames[CardNames.CLASH_PILE] = new TranslatedCardName("Konflikte");
GermanCardNames[CardNames.BATTLE_PLAN] = new TranslatedCardName("Schlachtplan", "Schlachtpläne", "einen Schlachtplan");
GermanCardNames[CardNames.ARCHER] = new TranslatedCardName("Bogenschützin", "Bogenschützinnen", "eine Bogenschützin");
GermanCardNames[CardNames.WARLORD] = new TranslatedCardName("Kriegsherr", "Kriegsherren", "einen Kriegsherrn");
GermanCardNames[CardNames.TERRITORY] = new TranslatedCardName("Territorium", "Territorien", "ein Territorium");
GermanCardNames[CardNames.FORT_PILE] = new TranslatedCardName("Bastionen");
GermanCardNames[CardNames.TENT] = new TranslatedCardName("Zelt", "Zelte", "ein Zelt");
GermanCardNames[CardNames.GARRISON] = new TranslatedCardName("Garnison", "Garnisonen", "eine Garnison");
GermanCardNames[CardNames.HILL_FORT] = new TranslatedCardName("Hügelfort", "Hügelforts", "ein Hügelfort");
GermanCardNames[CardNames.STRONGHOLD] = new TranslatedCardName("Burg", "Burgen", "eine Burg");
GermanCardNames[CardNames.ODYSSEY_PILE] = new TranslatedCardName("Irrfahrten");
GermanCardNames[CardNames.OLD_MAP] = new TranslatedCardName("Alte Landkarte", "Alte Landkarten", "eine Alte Landkarte");
GermanCardNames[CardNames.VOYAGE] = new TranslatedCardName("Seereise", "Seereisen", "eine Seereise");
GermanCardNames[CardNames.SUNKEN_TREASURE] = new TranslatedCardName("Versunkener Schatz", "Versunkene Schätze", "einen Versunkenen Schatz");
GermanCardNames[CardNames.DISTANT_SHORE] = new TranslatedCardName("Ferne Küste", "Ferne Küsten", "eine Ferne Küste");
GermanCardNames[CardNames.TOWNSFOLK_PILE] = new TranslatedCardName("Bürger");
GermanCardNames[CardNames.TOWN_CRIER] = new TranslatedCardName("Ausruferin", "Ausruferinnen", "eine Ausruferin");
GermanCardNames[CardNames.BLACKSMITH] = new TranslatedCardName("Eisenschmied", "Eisenschmiede", "einen Eisenschmied");
GermanCardNames[CardNames.MILLER] = new TranslatedCardName("Müller", "Müller", "einen Müller");
GermanCardNames[CardNames.ELDER] = new TranslatedCardName("Älteste", "Älteste", "eine Älteste");
GermanCardNames[CardNames.WIZARD_PILE] = new TranslatedCardName("Zauberer");
GermanCardNames[CardNames.STUDENT] = new TranslatedCardName("Zauberschüler", "Zauberschüler", "einen Zauberschüler");
GermanCardNames[CardNames.CONJURER] = new TranslatedCardName("Beschwörer", "Beschwörer", "einen Beschwörer");
GermanCardNames[CardNames.SORCERER] = new TranslatedCardName("Hexenmeister", "Hexenmeister", "einen Hexenmeister");
GermanCardNames[CardNames.LICH] = new TranslatedCardName("Lich", "Liche", "einen Lich");

//Seaside 2E
GermanCardNames[CardNames.MONKEY] = new TranslatedCardName("Affe", "Affen", "einen Affen");
GermanCardNames[CardNames.BLOCKADE] = new TranslatedCardName("Blockade", "Blockaden", "eine Blockade");
GermanCardNames[CardNames.SAILOR] = new TranslatedCardName("Seefahrerin", "Seefahrerinnen", "eine Seefahrerin");
GermanCardNames[CardNames.PIRATE] = new TranslatedCardName("Piratin", "Piratinnen", "eine Piratin");
GermanCardNames[CardNames.CORSAIR] = new TranslatedCardName("Korsarenschiff", "Korsarenschiffe", "ein Korsarenschiff");
GermanCardNames[CardNames.SEA_CHART] = new TranslatedCardName("Seekarte", "Seekarten", "eine Seekarte");
GermanCardNames[CardNames.ASTROLABE] = new TranslatedCardName("Astrolabium", "Astrolabien", "ein Astrolabium");
GermanCardNames[CardNames.TIDE_POOLS] = new TranslatedCardName("Gezeitenbecken", "Gezeitenbecken", "ein Gezeitenbecken");
GermanCardNames[CardNames.SEA_WITCH] = new TranslatedCardName("Meerhexe", "Meerhexen", "eine Meerhexe");

// Prosperity 2E
GermanCardNames[CardNames.ANVIL] = new TranslatedCardName("Amboss", "Ambosse", "einen Amboss");
GermanCardNames[CardNames.CLERK] = new TranslatedCardName("Buchhalterin", "Buchhalterinnen", "eine Buchhalterin");
GermanCardNames[CardNames.INVESTMENT] = new TranslatedCardName("Geldanlage", "Geldanlagen", "eine Geldanlage");
GermanCardNames[CardNames.TIARA] = new TranslatedCardName("Brautkrone", "Brautkronen", "eine Brautkrone");
GermanCardNames[CardNames.CHARLATAN] = new TranslatedCardName("Wunderheilerin", "Wunderheilerinnen", "eine Wunderheilerin");
GermanCardNames[CardNames.COLLECTION] = new TranslatedCardName("Sammelsurium", "Sammelsurien", "ein Sammelsurium");
GermanCardNames[CardNames.CRYSTAL_BALL] = new TranslatedCardName("Kristallkugel", "Kristallkugeln", "eine Kristallkugel");
GermanCardNames[CardNames.MAGNATE] = new TranslatedCardName("Magnatin", "Magnatinnen", "eine Magnatin");
GermanCardNames[CardNames.WAR_CHEST] = new TranslatedCardName("Waffenkiste", "Waffenkisten", "eine Waffenkiste");

//Hinterlands 2E
GermanCardNames[CardNames.GUARD_DOG] = new TranslatedCardName("Wachhund", "Wachhunde", "einen Wachhund");
GermanCardNames[CardNames.BERSERKER] = new TranslatedCardName("Berserker", "Berserker", "einen Berserker");
GermanCardNames[CardNames.NOMADS] = new TranslatedCardName("Nomaden", "Nomaden", "Nomaden");
GermanCardNames[CardNames.TRAIL] = new TranslatedCardName("Pfad", "Pfade", "einen Pfad");
GermanCardNames[CardNames.WEAVER] = new TranslatedCardName("Weberin", "Weberinnen", "eine Weberin");
GermanCardNames[CardNames.SOUK] = new TranslatedCardName("Souk", "Souks", "einen Souk");
GermanCardNames[CardNames.CAULDRON] = new TranslatedCardName("Hexenkessel", "Hexenkessel", "einen Hexenkessel");
GermanCardNames[CardNames.WHEELWRIGHT] = new TranslatedCardName("Radmacherin", "Radmacherinnen", "eine Radmacherin");
GermanCardNames[CardNames.WITCHS_HUT] = new TranslatedCardName("Hexenhütte", "Hexenhütten", "eine Hexenhütte");

//Plunder
GermanCardNames[CardNames.CAGE] = new TranslatedCardName("Käfig", "Käfige", "einen Käfig");
GermanCardNames[CardNames.GROTTO] = new TranslatedCardName("Grotte", "Grotten", "eine Grotte");
GermanCardNames[CardNames.JEWELLED_EGG] = new TranslatedCardName("Juwelen-Ei", "Juwelen-Eier", "ein Juwelen-Ei");
GermanCardNames[CardNames.SEARCH] = new TranslatedCardName("Suche", "Suchen", "eine Suche");
GermanCardNames[CardNames.SHAMAN] = new TranslatedCardName("Schamanin", "Schamaninnen", "eine Schamanin");
GermanCardNames[CardNames.SECLUDED_SHRINE] = new TranslatedCardName("Einsamer Schrein", "Einsame Schreine", "einen Einsamen Schrein");
GermanCardNames[CardNames.SIREN] = new TranslatedCardName("Sirene", "Sirenen", "eine Sirene");
GermanCardNames[CardNames.STOWAWAY] = new TranslatedCardName("Blinder Passagier", "Blinde Passagiere", "einen Blinden Passagier");
GermanCardNames[CardNames.TASKMASTER] = new TranslatedCardName("Vorarbeiter", "Vorarbeiter", "einen Vorarbeiter");
GermanCardNames[CardNames.ABUNDANCE] = new TranslatedCardName("Überfluss", "Überflüsse", "einen Überfluss");
GermanCardNames[CardNames.CABIN_BOY] = new TranslatedCardName("Schiffsjunge", "Schiffsjungen", "einen Schiffsjungen");
GermanCardNames[CardNames.CRUCIBLE] = new TranslatedCardName("Schmelztiegel", "Schmelztiegel", "einen Schmelztiegel");
GermanCardNames[CardNames.FLAGSHIP] = new TranslatedCardName("Flaggschiff", "Flaggschiffe", "ein Flaggschiff");
GermanCardNames[CardNames.FORTUNE_HUNTER] = new TranslatedCardName("Abenteurerin", "Abenteurerinnen", "eine Abenteurerin");
GermanCardNames[CardNames.GONDOLA] = new TranslatedCardName("Gondel", "Gondeln", "eine Gondel");
GermanCardNames[CardNames.HARBOR_VILLAGE] = new TranslatedCardName("Hafendorf", "Hafendörfer", "ein Hafendorf");
GermanCardNames[CardNames.LANDING_PARTY] = new TranslatedCardName("Landungstrupp", "Landungstrupps", "einen Landungstrupp");
GermanCardNames[CardNames.MAPMAKER] = new TranslatedCardName("Kartenzeichnerin", "Kartenzeichnerinnen", "eine Kartenzeichnerin");
GermanCardNames[CardNames.MAROON] = new TranslatedCardName("Ausgesetzter", "Ausgesetzte", "einen Ausgesetzten");
GermanCardNames[CardNames.ROPE] = new TranslatedCardName("Seil", "Seile", "ein Seil");
GermanCardNames[CardNames.SWAMP_SHACKS] = new TranslatedCardName("Sumpfhütten", "Sumpfhütten", "Sumpfhütten");
GermanCardNames[CardNames.TOOLS] = new TranslatedCardName("Werkzeug", "Werkzeug", "Werkzeug");
GermanCardNames[CardNames.BURIED_TREASURE] = new TranslatedCardName("Vergrabener Schatz", "Vergrabene Schätze", "einen Vergrabenen Schatz");
GermanCardNames[CardNames.CREW] = new TranslatedCardName("Mannschaft", "Mannschaften", "eine Mannschaft");
GermanCardNames[CardNames.CUTTHROAT] = new TranslatedCardName("Meuchlerin", "Meuchlerinnen", "eine Meuchlerin");
GermanCardNames[CardNames.ENLARGE] = new TranslatedCardName("Vergrößerung", "Vergrößerungen", "eine Vergrößerung");
GermanCardNames[CardNames.FIGURINE] = new TranslatedCardName("Figurine", "Figurinen", "eine Figurine");
GermanCardNames[CardNames.FIRST_MATE] = new TranslatedCardName("Erste Maatin", "Erste Maatinnen", "eine Erste Maatin");
GermanCardNames[CardNames.FRIGATE] = new TranslatedCardName("Fregatte", "Fregatten", "eine Fregatte");
GermanCardNames[CardNames.LONGSHIP] = new TranslatedCardName("Langschiff", "Langschiffe", "ein Langschiff");
GermanCardNames[CardNames.MINING_ROAD] = new TranslatedCardName("Bergbaustraße", "Bergbaustraßen", "eine Bergbaustraße");
GermanCardNames[CardNames.PENDANT] = new TranslatedCardName("Anhänger", "Anhänger", "einen Anhänger");
GermanCardNames[CardNames.PICKAXE] = new TranslatedCardName("Spitzhacke", "Spitzhacken", "eine Spitzhacke");
GermanCardNames[CardNames.PILGRIM] = new TranslatedCardName("Pilger", "Pilger", "einen Pilger");
GermanCardNames[CardNames.QUARTERMASTER] = new TranslatedCardName("Quartiermeister", "Quartiermeister", "einen Quartiermeister");
GermanCardNames[CardNames.SILVER_MINE] = new TranslatedCardName("Silbermine", "Silberminen", "eine Silbermine");
GermanCardNames[CardNames.TRICKSTER] = new TranslatedCardName("Gauner", "Gauner", "einen Gauner");
GermanCardNames[CardNames.WEALTHY_VILLAGE] = new TranslatedCardName("Wohlhabendes Dorf", "Wohlhabende Dörfer", "ein Wohlhabendes Dorf");
GermanCardNames[CardNames.SACK_OF_LOOT] = new TranslatedCardName("Sack voll Kostbarkeiten", "Säcke voll Kostbarkeiten", "einen Sack voll Kostbarkeiten");
GermanCardNames[CardNames.KINGS_CACHE] = new TranslatedCardName("Königstruhe", "Königstruhen", "eine Königstruhe");
GermanCardNames[CardNames.BURY] = new TranslatedCardName("Vergraben");
GermanCardNames[CardNames.AVOID] = new TranslatedCardName("Ausweichen");
GermanCardNames[CardNames.DELIVER] = new TranslatedCardName("Zustellung");
GermanCardNames[CardNames.PERIL] = new TranslatedCardName("Riskieren");
GermanCardNames[CardNames.RUSH] = new TranslatedCardName("Beeilung");
GermanCardNames[CardNames.FORAY] = new TranslatedCardName("Brandschatzung");
GermanCardNames[CardNames.LAUNCH] = new TranslatedCardName("In See stechen");
GermanCardNames[CardNames.MIRROR] = new TranslatedCardName("Spiegeln");
GermanCardNames[CardNames.PREPARE] = new TranslatedCardName("Vorbereitung");
GermanCardNames[CardNames.SCROUNGE] = new TranslatedCardName("Herumsuchen");
GermanCardNames[CardNames.JOURNEY] = new TranslatedCardName("Reise");
GermanCardNames[CardNames.MAELSTROM] = new TranslatedCardName("Mahlstrom");
GermanCardNames[CardNames.LOOTING] = new TranslatedCardName("Plünderung");
GermanCardNames[CardNames.INVASION] = new TranslatedCardName("Invasion");
GermanCardNames[CardNames.PROSPER] = new TranslatedCardName("Aufblühen");
GermanCardNames[CardNames.LOOT_PILE] = new TranslatedCardName("Kostbarkeiten");
GermanCardNames[CardNames.AMPHORA] = new TranslatedCardName("Amphore", "Amphoren", "eine Amphore");
GermanCardNames[CardNames.DOUBLOONS] = new TranslatedCardName("Dublonen", "Dublonen", "Dublonen");
GermanCardNames[CardNames.ENDLESS_CHALICE] = new TranslatedCardName("Endloser Kelch", "Endlose Kelche", "einen Endlosen Kelch");
GermanCardNames[CardNames.FIGUREHEAD] = new TranslatedCardName("Galionsfigur", "Galionsfiguren", "eine Galionsfigur");
GermanCardNames[CardNames.HAMMER] = new TranslatedCardName("Hammer", "Hämmer", "einen Hammer");
GermanCardNames[CardNames.INSIGNIA] = new TranslatedCardName("Insignien", "Insignien", "Insignien");
GermanCardNames[CardNames.JEWELS] = new TranslatedCardName("Juwelen", "Juwelen", "Juwelen");
GermanCardNames[CardNames.ORB] = new TranslatedCardName("Kugel", "Kugeln", "eine Kugel");
GermanCardNames[CardNames.PRIZE_GOAT] = new TranslatedCardName("Zuchtziege", "Zuchtziegen", "eine Zuchtziege");
GermanCardNames[CardNames.PUZZLE_BOX] = new TranslatedCardName("Rätselschatulle", "Rätselschatullen", "eine Rätselschatulle");
GermanCardNames[CardNames.SEXTANT] = new TranslatedCardName("Sextant", "Sextanten", "einen Sextanten");
GermanCardNames[CardNames.SHIELD] = new TranslatedCardName("Schild", "Schilde", "einen Schild");
GermanCardNames[CardNames.SPELL_SCROLL] = new TranslatedCardName("Zauberrolle", "Zauberrollen", "eine Zauberrolle");
GermanCardNames[CardNames.STAFF] = new TranslatedCardName("Stab", "Stäbe", "einen Stab");
GermanCardNames[CardNames.SWORD] = new TranslatedCardName("Schwert", "Schwerter", "ein Schwert");
GermanCardNames[CardNames.CHEAP] = new TranslatedCardName("Billig");
GermanCardNames[CardNames.CURSED] = new TranslatedCardName("Verflucht");
GermanCardNames[CardNames.FATED] = new TranslatedCardName("Vorherbestimmt");
GermanCardNames[CardNames.FAWNING] = new TranslatedCardName("Aufdringlich");
GermanCardNames[CardNames.FRIENDLY] = new TranslatedCardName("Freundlich");
GermanCardNames[CardNames.HASTY] = new TranslatedCardName("Eilig");
GermanCardNames[CardNames.INHERITED] = new TranslatedCardName("Geerbt");
GermanCardNames[CardNames.INSPIRING] = new TranslatedCardName("Inspirierend");
GermanCardNames[CardNames.NEARBY] = new TranslatedCardName("Benachbart");
GermanCardNames[CardNames.PATIENT] = new TranslatedCardName("Geduldig");
GermanCardNames[CardNames.PIOUS] = new TranslatedCardName("Fromm");
GermanCardNames[CardNames.RECKLESS] = new TranslatedCardName("Waghalsig");
GermanCardNames[CardNames.RICH] = new TranslatedCardName("Reich");
GermanCardNames[CardNames.SHY] = new TranslatedCardName("Scheu");
GermanCardNames[CardNames.TIRELESS] = new TranslatedCardName("Unermüdlich");
GermanCardNames[CardNames.MARCHLAND] = new TranslatedCardName("Grenzregion", "Grenzregionen", "eine Grenzregion"); // Grenzgebiet

GermanCardNames[CardNames.SHOP] = new TranslatedCardName("Geschäft", "Geschäfte", "ein Geschäft");
GermanCardNames[CardNames.CARNIVAL] = new TranslatedCardName("Karneval", "Karnevals", "einen Karneval");
GermanCardNames[CardNames.FERRYMAN] = new TranslatedCardName("Fährmann", "Fährmänner", "einen Fährmann");
GermanCardNames[CardNames.FARMHANDS] = new TranslatedCardName("Landarbeiterinnen", "Landarbeiterinnen", "Landarbeiterinnen");
GermanCardNames[CardNames.JOUST] = new TranslatedCardName("Ritterkampf", "Ritterkampfe", "einen Ritterkampf");
GermanCardNames[CardNames.REWARDS_PILE] = new TranslatedCardName("Belohnung", "Belohnungen ", "eine Belohnung");
GermanCardNames[CardNames.COURSER] = new TranslatedCardName("Rennpferd", "Rennpferde", "ein Rennpferd");
GermanCardNames[CardNames.RENOWN] = new TranslatedCardName("Ruhm", "Ruhm", "einen Ruhm");
GermanCardNames[CardNames.CORONET] = new TranslatedCardName("Adelskrone", "Adelskronen", "eine Adelskrone");
GermanCardNames[CardNames.DEMESNE] = new TranslatedCardName("Landsitz", "Landsitze", "einen Landsitz");
GermanCardNames[CardNames.HOUSECARL] = new TranslatedCardName("Leibgardist", "Leibgardisten", "einen Leibgardist");
GermanCardNames[CardNames.HUGE_TURNIP] = new TranslatedCardName("Riesenrübe", "Riesenrüben", "eine Riesenrübe");
GermanCardNames[CardNames.FARRIER] = new TranslatedCardName("Hufschmied", "Hufschmiede", "einen Hufschmied");
GermanCardNames[CardNames.INFIRMARY] = new TranslatedCardName("Krankenhaus", "Krankenhäuser", "ein Krankenhaus");
GermanCardNames[CardNames.FOOTPAD] = new TranslatedCardName("Straßenräuber", "Straßenräuber", "einen Straßenräuber");

GermanCardNames[CardNames.FISHMONGER] = new TranslatedCardName("Fischhändlerin", "Fischhändlerinnen", "eine Fischhändlerin");
GermanCardNames[CardNames.SNAKE_WITCH] = new TranslatedCardName("Schlangenhexe", "Schlangenhexen", "eine Schlangenhexe");
GermanCardNames[CardNames.ARISTOCRAT] = new TranslatedCardName("Aristokratin", "Aristokratinnen", "eine Aristokratin");
GermanCardNames[CardNames.CRAFTSMAN] = new TranslatedCardName("Handwerker", "Handwerker", "einen Handwerker");
GermanCardNames[CardNames.RIVERBOAT] = new TranslatedCardName("Flussboot", "Flussboote", "ein Flussboot");
GermanCardNames[CardNames.ROOT_CELLAR] = new TranslatedCardName("Erdkeller", "Erdkeller", "einen Erdkeller");
GermanCardNames[CardNames.ALLEY] = new TranslatedCardName("Gasse", "Gassen", "eine Gasse");
GermanCardNames[CardNames.CHANGE] = new TranslatedCardName("Wandel", "Wandel", "einen Wandel");
GermanCardNames[CardNames.NINJA] = new TranslatedCardName("Ninja", "Ninjas", "einen Ninja");
GermanCardNames[CardNames.POET] = new TranslatedCardName("Dichterin", "Dichterinnen", "eine Dichterin");
GermanCardNames[CardNames.RIVER_SHRINE] = new TranslatedCardName("Flussschrein", "Flussschreine", "einen Flussschrein");
GermanCardNames[CardNames.RUSTIC_VILLAGE] = new TranslatedCardName("Rustikales Dorf", "Rustikale Dörfer", "ein Rustikales Dorf");
GermanCardNames[CardNames.GOLD_MINE] = new TranslatedCardName("Goldmine", "Goldminen", "eine Goldmine");
GermanCardNames[CardNames.IMPERIAL_ENVOY] = new TranslatedCardName("Kaiserlicher Gesandter", "Kaiserliche Gesandte", "einen Kaiserlichen Gesandter");
GermanCardNames[CardNames.KITSUNE] = new TranslatedCardName("Kitsune", "Kitsune", "eine Kitsune");
GermanCardNames[CardNames.LITTER] = new TranslatedCardName("Sänfte", "Sänften", "eine Sänfte");
GermanCardNames[CardNames.RICE_BROKER] = new TranslatedCardName("Reismakler", "Reismakler", "einen Reismakler");
GermanCardNames[CardNames.RONIN] = new TranslatedCardName("Ronin", "Ronins", "einen Ronin");
GermanCardNames[CardNames.TANUKI] = new TranslatedCardName("Tanuki", "Tanuki", "einen Tanuki");
GermanCardNames[CardNames.TEA_HOUSE] = new TranslatedCardName("Teehaus", "Teehäuser", "ein Teehaus");
GermanCardNames[CardNames.SAMURAI] = new TranslatedCardName("Samurai", "Samurais", "einen Samurai");
GermanCardNames[CardNames.RICE] = new TranslatedCardName("Reis", "Reis", "einen Reis");
GermanCardNames[CardNames.MOUNTAIN_SHRINE] = new TranslatedCardName("Bergschrein", "Bergschreine", "einen Bergschrein");
GermanCardNames[CardNames.DAIMYO] = new TranslatedCardName("Daimyo", "Daimyos", "einen Daimyo");
GermanCardNames[CardNames.ARTIST] = new TranslatedCardName("Künstlerin", "Künstlerinnen", "eine Künstlerin");
GermanCardNames[CardNames.APPROACHING_ARMY] = new TranslatedCardName("Herannahende Armee");
GermanCardNames[CardNames.BIDING_TIME] = new TranslatedCardName("Wartezeit");
GermanCardNames[CardNames.BUREAUCRACY] = new TranslatedCardName("Bürokratie");
GermanCardNames[CardNames.DIVINE_WIND] = new TranslatedCardName("Göttlicher Wind");
GermanCardNames[CardNames.ENLIGHTENMENT] = new TranslatedCardName("Erleuchtung");
GermanCardNames[CardNames.FLOURISHING_TRADE] = new TranslatedCardName("Florierender Handel");
GermanCardNames[CardNames.GOOD_HARVEST] = new TranslatedCardName("Gute Ernte");
GermanCardNames[CardNames.GREAT_LEADER] = new TranslatedCardName("Großer Anführer");
GermanCardNames[CardNames.GROWTH] = new TranslatedCardName("Wachstum");
GermanCardNames[CardNames.HARSH_WINTER] = new TranslatedCardName("Strenger Winter");
GermanCardNames[CardNames.KIND_EMPEROR] = new TranslatedCardName("Gütiger Kaiser");
GermanCardNames[CardNames.PANIC] = new TranslatedCardName("Panik");
GermanCardNames[CardNames.PROGRESS] = new TranslatedCardName("Entwicklung");
GermanCardNames[CardNames.RAPID_EXPANSION] = new TranslatedCardName("Schnelle Expansion");
GermanCardNames[CardNames.SICKNESS] = new TranslatedCardName("Krankheit");
GermanCardNames[CardNames.AMASS] = new TranslatedCardName("Anhäufen");
GermanCardNames[CardNames.ASCETICISM] = new TranslatedCardName("Askese");
GermanCardNames[CardNames.CREDIT] = new TranslatedCardName("Kredit");
GermanCardNames[CardNames.FORESIGHT] = new TranslatedCardName("Voraussicht");
GermanCardNames[CardNames.KINTSUGI] = new TranslatedCardName("Kintsugi");
GermanCardNames[CardNames.PRACTICE] = new TranslatedCardName("Kampfübung");
GermanCardNames[CardNames.SEA_TRADE] = new TranslatedCardName("Seehandel");
GermanCardNames[CardNames.RECEIVE_TRIBUTE] = new TranslatedCardName("Tribut empfangen");
GermanCardNames[CardNames.GATHER] = new TranslatedCardName("Sammeln");
GermanCardNames[CardNames.CONTINUE] = new TranslatedCardName("Fortsetzen");