"use strict";

webclient.constant("shuffleItData", {
    name: "Shuffle iT",
    address: "Honselerdijkstraat 21-1",
    postcode: "1059 EX",
    city: "Amsterdam",
    country: "The Netherlands",
    vat: "NL8561.70.203.B01",
    kvk: "65579127",
    email: "info@shuffleit.nl"
});