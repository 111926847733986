"use strict";

var EnglishEnvironment = new function () {
    this.enter = function () {};
    this.leave = function () {};
    this.strlen = function (str) {
        return str.length;
    };

    this.getPileName = function (pile, direction) {
        var pileString = LANGUAGE.getCardName[pile.pileName].singular;
        var separator = LANGUAGE.getEnvironment.pileNameSeparator;
        var s = pile.pileName === CardNames.BLACK_MARKET_PILE ? "" : separator + LANGUAGE.getPhrase[Phrases.PILE];
        var article = getDirectionalArticle(direction);
        return colorTextByCardname(article + " " + pileString + s, pile.pileName);
    };
    this.pileNameSeparator = " ";

    this.phraseCollection_Or = function (phrases) {
        return EnglishEnvironment.phraseCollection(phrases, EnglishPhrases[Phrases.COMMA], EnglishPhrases[Phrases.OR]);
    };
    this.phraseCollection = function (phrases, repeatedDelimiter, finalDelimiter) {
        var phrColl = "";
        for (var i = 0; i < phrases.length; i++) {
            phrColl += phrases[i] + (i < phrases.length - 2 ? repeatedDelimiter : i === phrases.length - 2 ? " " + finalDelimiter + " " : "");
        }
        return phrColl;
    };

    this.cardComparator = function (a, b) {
        if (a.isLandscape() !== b.isLandscape()) return a.isLandscape() ? 1 : -1;
        if (a.isEvent() !== b.isEvent()) return a.isEvent() ? -1 : 1;
        if (a.isLandmark() !== b.isLandmark()) return a.isLandmark() ? -1 : 1;
        if (a.isProject() !== b.isProject()) return a.isProject() ? -1 : 1;
        if (a.isWay() !== b.isWay()) return a.isWay() ? -1 : 1;
        if (a.isAlly() !== b.isAlly()) return a.isAlly() ? -1 : 1;
        if (!isDefined(LANGUAGE.getCardName[a]) || !isDefined(LANGUAGE.getCardName[a].singular)) console.log("Problems with card %o", a);
        var translatedNameA = LANGUAGE.getCardName[a].singular;
        var translatedNameB = LANGUAGE.getCardName[b].singular;
        return translatedNameA.localeCompare(translatedNameB);
    };

    this.determineFontStyle = function (isLandscape, lines) {
        var estimateLineLength = function estimateLineLength(line) {
            return line.replace(/{!?\d+}/g, "x").replace(/\[!?(\d+|P)\]/g, "x").replace(/[|/%]/g, "").length;
        };
        var maxLineLength = lines.map(estimateLineLength).reduce(function (a, b) {
            return a > b ? a : b;
        });
        var cutoffs = isLandscape ? [42, 50, 57, 65, 99] : [20, 26, 32, 39, 99];
        var styles = ["huge-font", "large-font", "medium-font", "small-font", "tiny-font"];
        var index = cutoffs.findIndex(function (e) {
            return maxLineLength < e;
        });
        if (index === -1 || lines.length >= 9) index = 4;
        return styles[index];
    };
}();