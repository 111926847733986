"use strict";

var SERVER_CHAT_COLOR = '#66CD00';
var DEFAULT_SENDER_COLOR = "#D8D8D8";
var DEFAULT_TEXT_COLOR = "#BBBBBB";

function ChatLine(sender, message) {
    var senderColor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DEFAULT_SENDER_COLOR;
    var textColor = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : DEFAULT_TEXT_COLOR;
    var isChatLine = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
    var size = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 1;

    var self = this;
    self.id = generateUUID();
    self.sender = sender;
    self.message = message;
    self.size = size;
    self.shouldShowSender = sender !== null;
    self.senderColor = senderColor;
    self.textColor = textColor;
    self.isChatLine = isChatLine;
}

function parseChatMessage(chatMessage, meta, game) {
    var senderId = parseInt(chatMessage.sender.substring(1));
    var color = senderId === meta.model.me.id ? "white" : "#d8d8d8";
    var senderName = meta.getPlayerNameById(senderId);
    if (!isUndefined(game.state)) {
        var senderPlayer = game.state.players.find(function (p) {
            return p.name === senderName;
        });
        if (!isUndefined(senderPlayer)) color = senderPlayer.color;
    }
    return new ChatLine(senderName, chatMessage.message, color);
}

function createServerChatLine(message) {
    return new ChatLine(null, message, DEFAULT_SENDER_COLOR, SERVER_CHAT_COLOR, false, 0.8);
}

function createAlertChatLine(message) {
    return new ChatLine(null, message, DEFAULT_SENDER_COLOR, "red", false);
}