"use strict";

var GermanDeclineButtons = {};

GermanDeclineButtons[DeclineButtonIds.END_ACTION_PHASE] = "Beende Aktionen";
GermanDeclineButtons[DeclineButtonIds.END_BUY_PHASE] = "Beende Käufe";
GermanDeclineButtons[DeclineButtonIds.END_TURN] = "Beende Zug";
GermanDeclineButtons[DeclineButtonIds.DONE_PLAYING_TREASURES] = "Keine Geldkarten";
GermanDeclineButtons[DeclineButtonIds.DONE_REACTING] = "Fertig mit Reaktionen";
GermanDeclineButtons[DeclineButtonIds.MINING_VILLAGE_DONT_TRASH] = "Nicht entsorgen";
GermanDeclineButtons[DeclineButtonIds.PEARL_DIVER_DONT_TOPDECK] = "Nicht auf Nachziehstapel";
GermanDeclineButtons[DeclineButtonIds.HARBINGER_DONT_TOPDECK] = "Nein danke";
GermanDeclineButtons[DeclineButtonIds.THRONE_ROOM_DONT] = "Nicht vervielfachen";
GermanDeclineButtons[DeclineButtonIds.SMUGGLERS_GAIN_IMPOSSIBLE_COPY] = "Nehmen nicht möglich";
GermanDeclineButtons[DeclineButtonIds.EXPLORER_GAIN_SILVER] = "Nimm Silber";
GermanDeclineButtons[DeclineButtonIds.HERBALIST_DONT_TOPDECK] = "Nicht auf Nachziehstapel";
GermanDeclineButtons[DeclineButtonIds.MINT_DONT_COPY] = "Nicht kopieren";
GermanDeclineButtons[DeclineButtonIds.HAMLET_DONT_DISCARD] = "Nicht ablegen";
GermanDeclineButtons[DeclineButtonIds.TOURNAMENT_DONT_REVEAL] = "Nicht aufdecken";
GermanDeclineButtons[DeclineButtonIds.YOUNG_WITCH_DONT_REVEAL_BANE] = "Nicht aufdecken";
GermanDeclineButtons[DeclineButtonIds.IGG_NO_COPPER] = "Kein Kupfer";
GermanDeclineButtons[DeclineButtonIds.COUNTERFEIT_NO_TREASURE] = "Nicht entsorgen";
GermanDeclineButtons[DeclineButtonIds.CULTIST_DONT_PLAY_ANOTHER] = "Nein danke";
GermanDeclineButtons[DeclineButtonIds.IRONMONGER_KEEP] = "Behalten";
GermanDeclineButtons[DeclineButtonIds.PROCESSION_DONT] = "Nicht prozessieren";
GermanDeclineButtons[DeclineButtonIds.PLAZA_NO_DISCARD] = "Nicht ablegen";
GermanDeclineButtons[DeclineButtonIds.DONT_OVERPAY] = "Nicht mehr bezahlen";
GermanDeclineButtons[DeclineButtonIds.DONE_SETTING_ASIDE] = "Fertig mit Beiseite legen";
GermanDeclineButtons[DeclineButtonIds.ARTIFICER_DONT] = "Nicht ablegen";
GermanDeclineButtons[DeclineButtonIds.ENGINEER_DONT_TRASH] = "Nicht entsorgen";
GermanDeclineButtons[DeclineButtonIds.ENCAMPMENT_DONT_REVEAL] = "Nicht aufdecken";
GermanDeclineButtons[DeclineButtonIds.SETTLERS_DONT_TAKE_COPPER] = "Kein Kupfer";
GermanDeclineButtons[DeclineButtonIds.GLADIATOR_DONT_REVEAL] = "Nicht aufdecken";
GermanDeclineButtons[DeclineButtonIds.CROWN_DONT] = "Nicht verdoppeln";
GermanDeclineButtons[DeclineButtonIds.LEGIONARY_DONT_REVEAL] = "Nicht aufdecken";
GermanDeclineButtons[DeclineButtonIds.SHUFFLE_ALL] = "Alles mischen";
GermanDeclineButtons[DeclineButtonIds.PASS] = "Passen";
GermanDeclineButtons[DeclineButtonIds.DONT_DISCARD] = "Nicht ablegen";
GermanDeclineButtons[DeclineButtonIds.DONT_TRASH] = "Nicht entsorgen";
GermanDeclineButtons[DeclineButtonIds.VAULT_NO_DISCARD] = "Nicht ablegen";
GermanDeclineButtons[DeclineButtonIds.DONT_BUY] = "Nicht kaufen";
GermanDeclineButtons[DeclineButtonIds.DONT_GAIN] = "Nicht nehmen";
GermanDeclineButtons[DeclineButtonIds.DISCARD_ALL] = "Alle ablegen";
GermanDeclineButtons[DeclineButtonIds.TORTURER_FAIL_CURSE] = "Nimm Fluch";
GermanDeclineButtons[DeclineButtonIds.TORTURER_GAIN_CURSE] = "Nimm Fluch";
GermanDeclineButtons[DeclineButtonIds.BUSTLING_VILLAGE_NO_SETTLERS] = "Keine Siedler";
GermanDeclineButtons[DeclineButtonIds.PRINCE_NO] = "Nicht beiseite legen";
GermanDeclineButtons[DeclineButtonIds.SAUNA_DONT_CHAIN] = "Nicht aneinander reihen";
GermanDeclineButtons[DeclineButtonIds.AVANTO_DONT_CHAIN] = "Nicht aneinander reihen";
GermanDeclineButtons[DeclineButtonIds.CONCLAVE_DONT] = "Nichts spielen";
GermanDeclineButtons[DeclineButtonIds.IMP_DONT] = "Nichts spielen";
GermanDeclineButtons[DeclineButtonIds.NECROMANCER_DONT] = "Nichts spielen";
GermanDeclineButtons[DeclineButtonIds.NO_THANKS] = "Nein Danke";
GermanDeclineButtons[DeclineButtonIds.DONT_EXILE] = "Nicht ins Exil";
GermanDeclineButtons[DeclineButtonIds.KINGS_COURT_DONT] = "Nichts dreimal spielen";
GermanDeclineButtons[DeclineButtonIds.MASTERMIND_DONT] = "Drahtzieher nicht benutzen";
GermanDeclineButtons[DeclineButtonIds.DELAY_DONT] = "Nicht verzögern";
GermanDeclineButtons[DeclineButtonIds.TOIL_DONT] = "Nicht abmühen";
GermanDeclineButtons[DeclineButtonIds.MARCH_DONT] = "Nicht marschieren";
GermanDeclineButtons[DeclineButtonIds.DONT_TOPDECK] = "Nicht auf Nachziehstapel";
GermanDeclineButtons[DeclineButtonIds.RANDOM_ORDER] = "Zufällige Reihenfolge";
GermanDeclineButtons[DeclineButtonIds.HUNTING_LODGE_KEEP] = "Behalten";
GermanDeclineButtons[DeclineButtonIds.TIARA_NO_TREASURE] = "Nichts spielen";
GermanDeclineButtons[DeclineButtonIds.SWAP_DONT] = "Nicht tauschen";
GermanDeclineButtons[DeclineButtonIds.DONT_PLAY] = "Nichts spielen"; // Specialist
GermanDeclineButtons[DeclineButtonIds.DONT_REVEAL] = "Nichts aufdecken"; // Battle Plans
GermanDeclineButtons[DeclineButtonIds.DONT_ROTATE] = "Nicht rotieren";
GermanDeclineButtons[DeclineButtonIds.DONT] = "Nicht"; // used by Capital City
GermanDeclineButtons[DeclineButtonIds.SPEND_FAVOR] = "Gefallen einlösen";
GermanDeclineButtons[DeclineButtonIds.CONTINUE] = "Weiter";
GermanDeclineButtons[DeclineButtonIds.ORB_DONT] = "+1 Kauf, +3 Geld";
GermanDeclineButtons[DeclineButtonIds.DONT_PUZZLE_BOX] = "Nichts beiseite legen";
GermanDeclineButtons[DeclineButtonIds.DONT_INSPIRING] = "Nichts spielen";
GermanDeclineButtons[DeclineButtonIds.DONT_JOUST] = "Nicht zur Seite legen";
GermanDeclineButtons[DeclineButtonIds.SNAKE_WITCH_DONT_RETURN] = "Nicht zurücklegen";

GermanDeclineButtons[DoneButtonIds.DONE_TRASHING] = "Bestätige Entsorgen";
GermanDeclineButtons[DoneButtonIds.DONE_DISCARDING] = "Bestätige Ablegen";
GermanDeclineButtons[DoneButtonIds.DONE_ORDERING] = "Reihenfolge fertig";
GermanDeclineButtons[DoneButtonIds.DONE_RETURNING] = "Zurücklegen fertig";
GermanDeclineButtons[DoneButtonIds.DONE_SETTING_ASIDE] = "Beiseite legen fertig";
GermanDeclineButtons[DoneButtonIds.DONE_SELECTING] = "Auswahl fertig";
GermanDeclineButtons[DoneButtonIds.DONE_CHOOSING] = "Wahl fertig";
GermanDeclineButtons[DoneButtonIds.TRASH] = "Entsorge";
GermanDeclineButtons[DoneButtonIds.DISCARD] = "Lege ab";
GermanDeclineButtons[DoneButtonIds.PASS] = "Gib ab";
GermanDeclineButtons[DoneButtonIds.TOPDECK] = "Auf Nachziehstapel";
GermanDeclineButtons[DoneButtonIds.BOTTOMDECK] = "Unter Nachziehstapel";
GermanDeclineButtons[DoneButtonIds.EXILE] = "Ins Exil";
GermanDeclineButtons[DoneButtonIds.OVERPAY] = "Überzahlen";

var GermanGameButtons = {};

GermanGameButtons[GameButtons.AUTOPLAY_TREASURES] = "Alle Geldkarten spielen";
GermanGameButtons[GameButtons.USE_VILLAGER] = "Dorfbewohner einsetzen";
GermanGameButtons[GameButtons.USE_FAVOR_TOPDECK] = "Karten auf Nachziehstapel gegen Gefallen";
GermanGameButtons[GameButtons.USE_FAVOR_DISCARD] = "Karten in Ablagestapel gegen Gefallen";
GermanGameButtons[GameButtons.COFFERS] = "Nutze Taler";

var GermanDelayedQuestionButtons = {};

GermanDelayedQuestionButtons[CardNames.ARTIFICER] = "Ablegen";
GermanDelayedQuestionButtons[CardNames.BUSTLING_VILLAGE] = "Siedler auf die Hand";
GermanDelayedQuestionButtons[CardNames.CULTIST] = "Spiele Kultist";
GermanDelayedQuestionButtons[CardNames.ENGINEER] = "Entsorge Ingenieurin";
GermanDelayedQuestionButtons[CardNames.HAMLET] = "Lege ab";
GermanDelayedQuestionButtons[CardNames.HARBINGER] = "Auf Nachziehstapel";
GermanDelayedQuestionButtons[CardNames.ILL_GOTTEN_GAINS] = "Nimm Kupfer";
GermanDelayedQuestionButtons[CardNames.IRONMONGER] = "Lege ab";
GermanDelayedQuestionButtons[CardNames.MINING_VILLAGE] = "Entsorge";
GermanDelayedQuestionButtons[CardNames.PEARL_DIVER] = "Auf Nachziehstapel";
GermanDelayedQuestionButtons[CardNames.PLAZA] = "Lege ab";
GermanDelayedQuestionButtons[CardNames.SETTLERS] = "Kupfer auf die Hand";
GermanDelayedQuestionButtons[CardNames.HUNTING_LODGE] = "Ablegen & 5 Ziehen";
GermanDelayedQuestionButtons[CardNames.SNAKE_WITCH] = "Zurücklegen & Verfluchen";
GermanDelayedQuestionButtons[CardNames.RUSTIC_VILLAGE] = "2 ablegen für +1 Karte";
GermanDelayedQuestionButtons[CardNames.GOLD_MINE] = "Nimm Gold & 4 Schulden";

var GermanCleanupActions = {};

GermanCleanupActions[CleanupActions.TOPDECK] = new CleanupButtonText("Auf Nachziehstapel", "Ablegen");
GermanCleanupActions[CleanupActions.EXCHANGE] = new CleanupButtonText("Austauschen", "Behalten");