"use strict";

var JapaneseUserPreferences = {};

JapaneseUserPreferences[UserPrefIds.EMAIL] = "メールアドレス";
JapaneseUserPreferences[UserPrefIds.CARD_OWNERSHIP] = "所持カード";
JapaneseUserPreferences[UserPrefIds.LIKED_CARDS] = "好きなカード";
JapaneseUserPreferences[UserPrefIds.DISLIKED_CARDS] = "嫌いなカード";
JapaneseUserPreferences[UserPrefIds.BANNED_CARDS] = "禁止カード";
JapaneseUserPreferences[UserPrefIds.MOVE_KINGDOM] = "王国、プレイエリアの移動";
JapaneseUserPreferences[UserPrefIds.CONFIRM_ACTIONS] = "捨て札・廃棄・追放の確認";
JapaneseUserPreferences[UserPrefIds.PREFERRED_BACKGROUND] = "使用する背景壁紙";
JapaneseUserPreferences[UserPrefIds.USE_AUTOBUY] = "財宝を自動で使用する";
JapaneseUserPreferences[UserPrefIds.FOLLOWERS_SEE_ACTIVITY] = "ログイン状況を公開する";
JapaneseUserPreferences[UserPrefIds.NOTIFICATION_SOUNDS] = "通知音を鳴らす";
JapaneseUserPreferences[UserPrefIds.SOUND_FX] = "効果音を鳴らす";
JapaneseUserPreferences[UserPrefIds.SPECTATORS_SEE_HAND] = "観戦者に手札を見せる";
JapaneseUserPreferences[UserPrefIds.AUTOPLAY_SETTINGS] = "以下のカードで操作を自動化する";
JapaneseUserPreferences[UserPrefIds.LOG_FIXED_BASIC_BONUS] = "リソースの増加をログに表示する";
JapaneseUserPreferences[UserPrefIds.MAKE_THRONE_ROOM_ORANGE] = "場に残る玉座の間を橙色で表示する";
JapaneseUserPreferences[UserPrefIds.MAKE_DURATIONS_NOT_ORANGE] = "持続カードから橙色を動的に消す";
JapaneseUserPreferences[UserPrefIds.TRANSPARENT_WAY_BUTTON] = "習性のボタンを透明にする";
JapaneseUserPreferences[UserPrefIds.CHAT_VISIBLE] = "チャットを見る";
JapaneseUserPreferences[UserPrefIds.SPECTATOR_CHAT_IN_AUTOMATCH] = "自動マッチングで観戦チャットを表示する";
JapaneseUserPreferences[UserPrefIds.CARD_POOL_LEVEL] = "カードプール";
JapaneseUserPreferences[UserPrefIds.ANIMATION_TYPE] = "アニメーション";
JapaneseUserPreferences[UserPrefIds.PREMOVES] = "アニメーションや相手を待たず行動する";

JapaneseUserPreferences.USERNAME = "ユーザー名";
JapaneseUserPreferences.PASSWORD = "パスワード";
JapaneseUserPreferences.OLD_PASSWORD = "旧パスワード";
JapaneseUserPreferences.NEW_PASSWORD = "新パスワード";
JapaneseUserPreferences.REPEAT_PASSWORD = "新パスワード(確認用)";
JapaneseUserPreferences.OWNERSHIP_INTRO = "現在の所持セット:";
JapaneseUserPreferences.OWNERSHIP_INTRO_EMPTY = "所持セットはありません。";
JapaneseUserPreferences.FAMILIAR_WITH = "知っているカード:";
JapaneseUserPreferences.USE_BONUS_CODE = "ボーナスコード";
JapaneseUserPreferences.BONUS_CODE_ORIGIN = "ボーナスコード付属元: ";
JapaneseUserPreferences.BONUS_CODE_CHOOSE_EXPANSION = "適用する拡張セットを選択: ";

var JapaneseProfileButtons = {};

JapaneseProfileButtons[true] = "はい";
JapaneseProfileButtons[false] = "いいえ";
JapaneseProfileButtons[UserPrefIds.FAMILIAR_CARDS] = {
    ALL: "この拡張全部",
    NONE: "この拡張を除く",
    ALL_EXPANSIONS: "全拡張"
};

var JapaneseStoreLabels = {};

JapaneseStoreLabels[StorePackages.HALF] = "銀のサブスクリプション";
JapaneseStoreLabels[StorePackages.ALL] = "金のサブスクリプション";
JapaneseStoreLabels[StorePackages.CORE] = "コア・サブスク";
JapaneseStoreLabels[StorePackages.COMPLETE] = "コンプリート・サブスク";

JapaneseStoreLabels[PriceCategories.PURCHASED] = ""; // "Purchase expansions: ";
JapaneseStoreLabels[PriceCategories.ALREADY_OWNED] = "保有中";
JapaneseStoreLabels[PriceCategories.DISCOUNT] = "長期割引額";
JapaneseStoreLabels[PriceCategories.TRANSACTION] = "取引手数料";
JapaneseStoreLabels[PriceCategories.SUBTOTAL] = "小計";
JapaneseStoreLabels[PriceCategories.VAT] = "付加価値税";
JapaneseStoreLabels[PriceCategories.TOTAL] = "総計";

JapaneseStoreLabels.SELLER_INFO = "販売人情報";
JapaneseStoreLabels.PRODUCT_CONFIRM = "DATEまでEXPANSIONSを購入します。";
JapaneseStoreLabels.PAYMENT_HANDLER = "支払いはMollieを利用して行われます。";
JapaneseStoreLabels.PROCEED_TO_PAYMENT = "支払いに進む(英語)";

var JapaneseAutoplayLabels = {};

JapaneseAutoplayLabels.REGULAR_OPTIONS = "通常の設定";
JapaneseAutoplayLabels.AUTOPLAY_OPTIONS = "操作の自動化の設定";
JapaneseAutoplayLabels.AUTOPLAY_DOCUMENTATION_GENERIC = "操作の自動化とは";
JapaneseAutoplayLabels.AUTOPLAY_DOCUMENTATION_SPECIFIC = "の設定について";
JapaneseAutoplayLabels.CUSTOMIZE_OPTIONS = "設定";
JapaneseAutoplayLabels.ALL_OFF = "全てオフ";
JapaneseAutoplayLabels.ALL_DEFAULT = "標準設定";
JapaneseAutoplayLabels.ALL_CONVENIENT = "推奨設定";

var JapaneseAnimationTypes = {};
JapaneseAnimationTypes[AnimationOptions.NEW_PLAYER] = "低速";
JapaneseAnimationTypes[AnimationOptions.BEGINNER] = "普通";
JapaneseAnimationTypes[AnimationOptions.MEDIUM] = "中速";
JapaneseAnimationTypes[AnimationOptions.ADVANCED] = "高速";
JapaneseAnimationTypes[AnimationOptions.VERYFAST] = "超速";
JapaneseAnimationTypes[AnimationOptions.INSTANT] = "無し";