"use strict";

var DutchUserPreferences = {};

DutchUserPreferences[UserPrefIds.EMAIL] = "Email";
DutchUserPreferences[UserPrefIds.CARD_OWNERSHIP] = "Owned cards";
DutchUserPreferences[UserPrefIds.FAMILIAR_CARDS] = "Familiar cards";
DutchUserPreferences[UserPrefIds.LIKED_CARDS] = "Cards you like";
DutchUserPreferences[UserPrefIds.DISLIKED_CARDS] = "Cards you dislike";
DutchUserPreferences[UserPrefIds.BANNED_CARDS] = "Banned cards";
DutchUserPreferences[UserPrefIds.MOVE_KINGDOM] = "Move kingdom and play area";
DutchUserPreferences[UserPrefIds.CONFIRM_ACTIONS] = "Confirm single trashes and discards";
DutchUserPreferences[UserPrefIds.PREFERRED_BACKGROUND] = "Preferred background";
DutchUserPreferences[UserPrefIds.USE_AUTOBUY] = "Use autobuy";
DutchUserPreferences[UserPrefIds.FOLLOWERS_SEE_ACTIVITY] = "Followers see your activity";
DutchUserPreferences[UserPrefIds.MAX_UNFAMILIAR_CARDS] = "Max unfamiliar cards";
DutchUserPreferences[UserPrefIds.NOTIFICATION_SOUNDS] = "Play notification sounds";
DutchUserPreferences[UserPrefIds.SOUND_FX] = "Play sound fx";
DutchUserPreferences[UserPrefIds.SPECTATORS_SEE_HAND] = "Spectators can see my cards";
DutchUserPreferences[UserPrefIds.AUTOPLAY_SETTINGS] = "Autoplay the following cards";
DutchUserPreferences[UserPrefIds.LOG_FIXED_BASIC_BONUS] = "Log fixed +Actions, +Buys, +Coins";

DutchUserPreferences.USERNAME = "Username";
DutchUserPreferences.PASSWORD = "Password";
DutchUserPreferences.OLD_PASSWORD = "Old password";
DutchUserPreferences.NEW_PASSWORD = "New password";
DutchUserPreferences.REPEAT_PASSWORD = "Repeat new password";
DutchUserPreferences.OWNERSHIP_INTRO = "Your current subscriptions:";
DutchUserPreferences.OWNERSHIP_INTRO_EMPTY = "You currently have no subscriptions.";
DutchUserPreferences.FAMILIAR_WITH = "I'm familiar with: ";
DutchUserPreferences.USE_BONUS_CODE = "Use bonus code";
DutchUserPreferences.BONUS_CODE_ORIGIN = "Your bonus code is from: ";
DutchUserPreferences.BONUS_CODE_CHOOSE_EXPANSION = "Select an expansion: ";

var DutchProfileButtons = {};

DutchProfileButtons[true] = "Yes";
DutchProfileButtons[false] = "No";
DutchProfileButtons[UserPrefIds.FAMILIAR_CARDS] = {
    ALL: "This expansion",
    NONE: "None from this expansion",
    ALL_EXPANSIONS: "All expansions"
};

var DutchStoreLabels = {};

DutchStoreLabels[StorePackages.HALF] = "Silver subscription";
DutchStoreLabels[StorePackages.ALL] = "Gold subscription";

DutchStoreLabels[PriceCategories.PURCHASED] = ''; // "Purchase expansions: ";
DutchStoreLabels[PriceCategories.ALREADY_OWNED] = "Already subscribed";
DutchStoreLabels[PriceCategories.TRANSACTION] = "Transaction fee";
DutchStoreLabels[PriceCategories.SUBTOTAL] = "Subtotal";
DutchStoreLabels[PriceCategories.VAT] = "VAT";
DutchStoreLabels[PriceCategories.TOTAL] = "Total";

DutchStoreLabels.SELLER_INFO = "Seller info";
DutchStoreLabels.PRODUCT_CONFIRM = "Purchasing until DATE: EXPANSIONS";
DutchStoreLabels.PAYMENT_HANDLER = "Payment will be handled by our payment provider Mollie.";
DutchStoreLabels.PROCEED_TO_PAYMENT = "Proceed to payment";

var DutchAutoplayLabels = {};

DutchAutoplayLabels.REGULAR_OPTIONS = "Regular Options";
DutchAutoplayLabels.AUTOPLAY_OPTIONS = "Autoplay Options";
DutchAutoplayLabels.AUTOPLAY_DOCUMENTATION_GENERIC = "What is Autoplay?";
DutchAutoplayLabels.AUTOPLAY_DOCUMENTATION_SPECIFIC = "-specific options";
DutchAutoplayLabels.CUSTOMIZE_OPTIONS = "Customize options";
DutchAutoplayLabels.ALL_OFF = "All off";
DutchAutoplayLabels.ALL_DEFAULT = "All default";
DutchAutoplayLabels.ALL_CONVENIENT = "All convenient";