"use strict";

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var QuestionModel = function () {
    function QuestionModel(game, serverMessenger) {
        var _this = this;

        _classCallCheck(this, QuestionModel);

        this.game = game;
        this.openQuestions = [];
        this.gameButtons = [];
        this.transientButtons = [];
        this.serverMessenger = serverMessenger;
        this.resizeListener = function () {
            _this.positionButtons();
            _this.gameButtons.forEach(function (b) {
                return b.redraw();
            });
        };
        window.addEventListener("resize", this.resizeListener);

        this.newQuestionHandlers = [];
        this.queuedPremoves = [];
        this.premovedCardModes = [];
    }

    _createClass(QuestionModel, [{
        key: "unload",
        value: function unload() {
            window.removeEventListener("resize", this.resizeListener);
            this.gameButtons = [];
        }
    }, {
        key: "createCleanupWindow",
        value: function createCleanupWindow() {
            this.cleanupWindow = new CleanupWindow(this.game);
        }
    }, {
        key: "newQuestion",
        value: function newQuestion(question) {
            this.openQuestions.push(question);
            this.showHiddenWindowsWithAnswers(question);
            this.clearTransientButtons();
        }
    }, {
        key: "showHiddenWindowsWithAnswers",
        value: function showHiddenWindowsWithAnswers(question) {
            if (question.story.questionId.showWindows) {
                this.game.state.zones.filter(function (z) {
                    return z instanceof WindowedZone && z.cardStacks.some(function (s) {
                        return s.getClickableCard() !== null;
                    });
                }).forEach(function (z) {
                    z.show(question);
                });
            }
        }
    }, {
        key: "startPremoving",
        value: function startPremoving() {
            if (this.openQuestions.length > 0) {
                this.newQuestionHandlers.forEach(function (handler) {
                    return handler();
                });
                this.checkForPremoves();
                this.game.gameSoundService.newQuestion();
            }
        }
    }, {
        key: "sortButtons",
        value: function sortButtons() {
            this.gameButtons.sort(function (a, b) {
                return a.sortingGroup - b.sortingGroup;
            });
        }
    }, {
        key: "positionButtons",
        value: function positionButtons() {
            this.sortButtons();
            var vh = window.innerHeight / 100;
            var vw = window.innerWidth / 100;

            var bigButtons = void 0,
                sideButtons = void 0;
            if (this.gameButtons.filter(function (b) {
                return b.hasNormalLayout;
            }).length < 5) {
                bigButtons = this.gameButtons.filter(function (b) {
                    return !(b instanceof AbilityButtonView || b.isSideButton);
                });
                sideButtons = this.gameButtons.filter(function (b) {
                    return b instanceof AbilityButtonView || b.isSideButton;
                });
            } else {
                bigButtons = this.gameButtons.filter(function (b) {
                    return b instanceof NumericButtonView;
                });
                sideButtons = this.gameButtons.filter(function (b) {
                    return !(b instanceof NumericButtonView);
                });
            }

            this.gameButtons.filter(function (b) {
                return b instanceof DeclineGameButtonView && !(b instanceof EndButtonView) && b.visibility && b.enabled;
            }).slice(1).forEach(function (b) {
                return b.visibility = false;
            });
            this.gameButtons.filter(function (b) {
                return b instanceof DoneGameButtonView && b.visibility && b.enabled;
            }).slice(1).forEach(function (b) {
                return b.visibility = false;
            });

            var maxWidth = 56 * vw;
            var margin = 1 * vh;

            var currentRow = [];
            var rowWidth = 0;
            var y = 65 * vh;

            var processRow = function processRow() {
                var x = 43 * vw - rowWidth / 2 + margin;
                currentRow.forEach(function (button, i) {
                    button.repositionCanvas(x, y - button.canvas.height / 2);
                    x += button.canvas.width / 2 + 2 * margin;
                });
            };
            bigButtons.filter(function (b) {
                return b.visibility;
            }).forEach(function (b) {
                if (b.hasNormalLayout) {
                    if (b.canvas.width / 2 + rowWidth > maxWidth) {
                        processRow();
                        rowWidth = 0;
                        currentRow = [];
                        y -= b.canvas.height / 2 + margin;
                    }
                    rowWidth += b.canvas.width / 2 + 2 * margin;
                    currentRow.push(b);
                } else {
                    b.repositionCanvas();
                }
            });
            processRow();
            var x = (100 - RIGHT_COLUMN_WIDTH - 1) * vw;
            y = 40 * vh;

            sideButtons.filter(function (b) {
                return b.visibility;
            }).forEach(function (b) {
                if (b.hasNormalLayout) {
                    b.repositionCanvas(x - b.canvas.width / 2, y);
                    y += b.canvas.height / 2 + vh;
                } else {
                    b.repositionCanvas();
                }
            });

            this.gameButtons.filter(function (b) {
                return b instanceof UndoButtonView;
            }).forEach(function (undoButton) {
                if (bigButtons.length > 0) {
                    undoButton.repositionCanvas(69, 60.5, 14000);
                } else {
                    undoButton.repositionCanvas(69, 94, 14000);
                }
            });
        }
    }, {
        key: "newButtons",
        value: function newButtons(buttons) {
            var _gameButtons,
                _this2 = this;

            (_gameButtons = this.gameButtons).push.apply(_gameButtons, _toConsumableArray(buttons));
            this.cleanupWindow.newButtons(buttons.filter(function (b) {
                return b instanceof CleanupButtonView;
            }));
            if (this.newQuestionHandlers.length === 0) {
                if (!isUndefined(this.game.gameArea)) this.gameButtons.forEach(function (b) {
                    return b.render(_this2.game.gameArea.gameButtonsArea);
                });
                this.positionButtons();
            }
        }
    }, {
        key: "removeButton",
        value: function removeButton(button) {
            var index = this.gameButtons.indexOf(button);
            if (index > -1) this.gameButtons.splice(index, 1);
            this.positionButtons();
        }
    }, {
        key: "answerQuestion",
        value: function answerQuestion(answerInfo) {
            this.serverMessenger.answerQuestion(answerInfo);
            this.game.state.cards.forEach(function (c) {
                return c.associateAnswer(null, null);
            });
            this.game.state.anonCards.forEach(function (c) {
                return c.associateAnswer(null, null);
            });
            this.game.state.zones.filter(function (z) {
                return z instanceof WindowedZone;
            }).forEach(function (z) {
                return z.hideAllQuestions();
            });
            this.game.state.zones.filter(function (z) {
                return z instanceof RevealZone;
            }).forEach(function (z) {
                return z.disableOrdering();
            });
            this.game.state.orphanage.disableOrdering();
            this.game.logModel.statusBarView.clearClickHandlers();
            this.clearButtons();
            this.openQuestions = [];
            this.cleanupWindow.hide();
            this.game.gameArea.updateNameInput();
            [].concat(_toConsumableArray(this.game.state.orphanage.cardStacks)).forEach(function (s) {
                return s.destroy();
            });
            this.game.state.orphanage.cardStacks = [];
            this.game.state.orphanage.hideAll();
            this.game.gameSoundService.hasAnsweredQuestion = true;
        }
    }, {
        key: "resetQuestions",
        value: function resetQuestions() {
            var _this3 = this;

            this.clearButtons();
            var questions = [].concat(_toConsumableArray(this.openQuestions));
            this.openQuestions = [];
            questions.forEach(function (q) {
                return q.execute(_this3.game);
            });
        }
    }, {
        key: "clearButtons",
        value: function clearButtons() {
            var transientButtons = this.transientButtons;
            this.gameButtons.forEach(function (b) {
                transientButtons.push(b);
                setTimeout(function () {
                    var index = transientButtons.indexOf(b);
                    if (index > -1) {
                        if (b.canvas !== null) b.canvas.remove();
                        transientButtons.splice(index, 1);
                    }
                }, 100);
            });
            this.gameButtons = [];
        }
    }, {
        key: "clearTransientButtons",
        value: function clearTransientButtons() {
            this.transientButtons.forEach(function (b) {
                b.canvas.remove();
            });
            this.transientButtons = [];
        }
    }, {
        key: "answerAll",
        value: function answerAll(ability) {
            var _this4 = this;

            var questionModel = this;
            var handler = function handler() {
                var findAbilityQuestion = function findAbilityQuestion(question) {
                    if (question instanceof ChoiceQuestion) {
                        if (question.type === QuestionTypes.RESOLVE_ABILITY) return question;
                    } else if (question instanceof ComplexQuestion) {
                        var _iteratorNormalCompletion = true;
                        var _didIteratorError = false;
                        var _iteratorError = undefined;

                        try {
                            for (var _iterator = question.subquestions[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                                var subquestion = _step.value;

                                var result = findAbilityQuestion(subquestion);
                                if (result !== null) return result;
                            }
                        } catch (err) {
                            _didIteratorError = true;
                            _iteratorError = err;
                        } finally {
                            try {
                                if (!_iteratorNormalCompletion && _iterator.return) {
                                    _iterator.return();
                                }
                            } finally {
                                if (_didIteratorError) {
                                    throw _iteratorError;
                                }
                            }
                        }
                    }
                    return null;
                };
                var abilityQuestion = null;
                var _iteratorNormalCompletion2 = true;
                var _didIteratorError2 = false;
                var _iteratorError2 = undefined;

                try {
                    for (var _iterator2 = questionModel.openQuestions[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                        var question = _step2.value;

                        var result = findAbilityQuestion(question);
                        if (result !== null) {
                            abilityQuestion = result;
                            break;
                        }
                    }
                } catch (err) {
                    _didIteratorError2 = true;
                    _iteratorError2 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion2 && _iterator2.return) {
                            _iterator2.return();
                        }
                    } finally {
                        if (_didIteratorError2) {
                            throw _iteratorError2;
                        }
                    }
                }

                if (abilityQuestion !== null) {
                    var abilities = abilityQuestion.content.elements;
                    var index = abilities.findIndex(function (a) {
                        return a.name === ability.name;
                    });
                    if (index > -1) {
                        return abilityQuestion.answer(index);
                    }
                }
                questionModel.newQuestionHandlers = questionModel.newQuestionHandlers.filter(function (h) {
                    return h !== handler;
                });
                _this4.newButtons([]);
            };
            this.newQuestionHandlers.push(handler);
            handler();
        }
    }, {
        key: "premove",
        value: function premove(cardStack, method) {
            var isWay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

            if (this.openQuestions.length === 0) {
                this.queuedPremoves.push([cardStack, method, isWay]);
                cardStack.updatePremoves();
                this.updatePremoveLogEntries();
            }
        }
    }, {
        key: "premoveCardMode",
        value: function premoveCardMode(cardMode) {
            this.premovedCardModes.push(cardMode);
            this.updatePremoveLogEntries();
        }
    }, {
        key: "checkForPremoves",
        value: function checkForPremoves() {
            var _this5 = this;

            var baseCall = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

            if (this.queuedPremoves.length > 0) {
                var _queuedPremoves$ = _slicedToArray(this.queuedPremoves[0], 3),
                    cardStack = _queuedPremoves$[0],
                    methodIndex = _queuedPremoves$[1],
                    isWay = _queuedPremoves$[2];

                var methods = [null, [Actions.DISCARD, Actions.EXILE, Actions.PASS], [Actions.BUY], [Actions.REACT, Actions.REVEAL], [Actions.PLAY, Actions.NAME, Actions.REVEAL]][methodIndex];
                if (cardStack.location === this.game.state.nowhere) {
                    // The cardstack got removed at some point, it's not "real".
                    this.queuedPremoves = [];
                    this.premovedCardModes = [];
                    cardStack.updatePremoves();
                    this.updatePremoveLogEntries();
                    return;
                }
                var execute = function execute(question, answer) {
                    if (methods === null || methods.includes(question.type.actionType)) {
                        if (isWay) {
                            var wayQuestion = question.getBundledQuestion(QuestionTypes.WAY);
                            if (wayQuestion !== null && question !== null) {
                                wayQuestion.answer(0);
                            }
                        }
                        question.answer(answer);
                        if (question instanceof ChoiceQuestion && question.accumulatedAnswers.length === question.max) question.send();
                    }

                    var _queuedPremoves$2 = _slicedToArray(_this5.queuedPremoves[0], 3),
                        stack = _queuedPremoves$2[0],
                        _ = _queuedPremoves$2[1],
                        __ = _queuedPremoves$2[2];

                    _this5.queuedPremoves = _this5.queuedPremoves.slice(1);
                    _this5.checkForPremoves(false);
                };

                var clickableCard = cardStack.getClickableCard();
                if (clickableCard !== null && clickableCard.question.canAccept(clickableCard.answer)) {
                    execute(clickableCard.question, clickableCard.answer);
                    return;
                }
            }
            if (this.premovedCardModes.length > 0) {
                var firstMode = this.premovedCardModes[0];
                var matchingQuestion = this.openQuestions.find(function (q) {
                    return q instanceof ChoiceQuestion && q.content instanceof QuestionCardModeElements && q.content.elements.includes(firstMode);
                });
                if (!isUndefined(matchingQuestion)) {
                    matchingQuestion.answer(matchingQuestion.content.elements.indexOf(firstMode));
                    if (matchingQuestion.accumulatedAnswers.length === matchingQuestion.max) matchingQuestion.send();
                    this.premovedCardModes = this.premovedCardModes.slice(1);
                    this.checkForPremoves(false);
                } else {
                    matchingQuestion = this.openQuestions.find(function (q) {
                        return q instanceof ChoiceQuestion && q.content instanceof QuestionCardModeElements && q.buttons.some(function (b) {
                            return b instanceof ShortcutButtonView && b.cardMode === firstMode;
                        });
                    });
                    if (!isUndefined(matchingQuestion)) {
                        var matchingButton = matchingQuestion.buttons.find(function (b) {
                            return b instanceof ShortcutButtonView && b.cardMode === firstMode;
                        });
                        matchingButton.onClick();
                        this.premovedCardModes = this.premovedCardModes.slice(1);
                        this.checkForPremoves(false);
                    }
                }
            }
            if (baseCall) {
                this.queuedPremoves = [];
                this.premovedCardModes = [];
                this.updatePremoveLogEntries();
            }
        }
    }, {
        key: "updatePremoveLogEntries",
        value: function updatePremoveLogEntries() {
            var _this6 = this;

            this.game.logModel.setPremoveEntries(this.queuedPremoves.map(function (_ref, index) {
                var _ref2 = _slicedToArray(_ref, 3),
                    cardStack = _ref2[0],
                    methodIndex = _ref2[1],
                    isWay = _ref2[2];

                var cardName = cardStack.topCard.cardName;
                var logEntryName = [LogEntryNames.PREMOVES, LogEntryNames.DISCARD, LogEntryNames.BUY, LogEntryNames.REACTS_WITH, LogEntryNames.PLAY][methodIndex];
                var args = [new LogArgument(1, _this6.game.playerModel.playerMe.index), new LogArgument(0, [new CardFrequency(cardName, 1)])];
                if (isWay) {
                    logEntryName = LogEntryNames.PLAY_USING_WAY;
                    var primaryWay = _this6.game.state.cards.find(function (c) {
                        return c.cardName.isWay();
                    }).cardName;
                    args.push(new LogArgument(0, [new CardFrequency(primaryWay, 1)]));
                }
                return new LogEntry(index, 0, logEntryName, args);
            }));
            if (this.game.optionsModel.getPreference(UserPrefIds.PREMOVES)) this.game.state.zones.forEach(function (z) {
                return z.cardStacks.forEach(function (s) {
                    return s.updatePremoves();
                });
            });
        }
    }, {
        key: "cancelPremove",
        value: function cancelPremove(i) {
            var _queuedPremoves$i = _slicedToArray(this.queuedPremoves[i], 3),
                stack = _queuedPremoves$i[0],
                _ = _queuedPremoves$i[1],
                __ = _queuedPremoves$i[2];

            this.queuedPremoves.splice(i, 1);
            stack.updatePremoves();
            this.updatePremoveLogEntries();
        }
    }, {
        key: "cancelFirstPremoveOfStack",
        value: function cancelFirstPremoveOfStack(targetStack) {
            var i = this.queuedPremoves.findIndex(function (_ref3) {
                var _ref4 = _slicedToArray(_ref3, 3),
                    cardStack = _ref4[0],
                    _ = _ref4[1],
                    __ = _ref4[2];

                return cardStack === targetStack;
            });
            if (i > -1) this.cancelPremove(i);
        }
    }, {
        key: "getPremoves",
        value: function getPremoves(targetStack) {
            return this.queuedPremoves.filter(function (_ref5) {
                var _ref6 = _slicedToArray(_ref5, 3),
                    cardStack = _ref6[0],
                    _ = _ref6[1],
                    __ = _ref6[2];

                return cardStack === targetStack;
            });
        }
    }]);

    return QuestionModel;
}();