"use strict";

var SpanishCardModes = {};

SpanishCardModes[CardModes.PAWN_CARD_ACTION] = "Carta y Acción";
SpanishCardModes[CardModes.PAWN_CARD] = "+1 Carta";
SpanishCardModes[CardModes.PAWN_ACTION] = "+1 Acción";
SpanishCardModes[CardModes.PAWN_BUY] = "+1 Compra";
SpanishCardModes[CardModes.PAWN_COIN] = "+1 Moneda";
SpanishCardModes[CardModes.STEWARD_CARDS] = "+2 Cartas";
SpanishCardModes[CardModes.STEWARD_COINS] = "+2 Monedas";
SpanishCardModes[CardModes.STEWARD_TRASH] = "Eliminar 2";
SpanishCardModes[CardModes.MINION_COINS] = "+2 Monedas";
SpanishCardModes[CardModes.MINION_NEWHAND] = "Descartar";
SpanishCardModes[CardModes.NOBLES_CARDS] = "+3 Cartas";
SpanishCardModes[CardModes.NOBLES_ACTIONS] = "+2 Acciones";
SpanishCardModes[CardModes.COURTIER_ACTION] = "+1 Acción";
SpanishCardModes[CardModes.COURTIER_BUY] = "+1 Compra";
SpanishCardModes[CardModes.COURTIER_COINS] = "+3 Monedas";
SpanishCardModes[CardModes.COURTIER_GOLD] = "Ganar Oro";
SpanishCardModes[CardModes.NATIVE_VILLAGE_SET_ASIDE] = "Apartar";
SpanishCardModes[CardModes.NATIVE_VILLAGE_RETURN] = "Recoger";
SpanishCardModes[CardModes.PIRATE_SHIP_RAID] = "Atacar";
SpanishCardModes[CardModes.PIRATE_SHIP_PROFIT] = "Beneficio";
SpanishCardModes[CardModes.SCRYING_POOL_TOPDECK] = "Poner sobre el mazo";
SpanishCardModes[CardModes.SCRYING_POOL_DISCARD] = "Descartar";
SpanishCardModes[CardModes.LOAN_DISCARD] = "Descartar";
SpanishCardModes[CardModes.LOAN_TRASH] = "Eliminar";
SpanishCardModes[CardModes.TRUSTY_STEED_CARDS_ACTIONS] = "Cartas y Acciones";
SpanishCardModes[CardModes.TRUSTY_STEED_CARDS] = "+2 Cartas";
SpanishCardModes[CardModes.TRUSTY_STEED_ACTIONS] = "+2 Acciones";
SpanishCardModes[CardModes.TRUSTY_STEED_COINS] = "+2 Monedas";
SpanishCardModes[CardModes.TRUSTY_STEED_SILVERS] = "+4 Platas";
SpanishCardModes[CardModes.JESTER_THEY_GAIN] = "El rival";
SpanishCardModes[CardModes.JESTER_YOU_GAIN] = "Tú";
SpanishCardModes[CardModes.DUCHESS_KEEP] = "Mantener";
SpanishCardModes[CardModes.DUCHESS_DISCARD] = "Descartar";
SpanishCardModes[CardModes.ORACLE_TOPDECK] = "Poner sobre el mazo";
SpanishCardModes[CardModes.ORACLE_DISCARD] = "Descartar";
SpanishCardModes[CardModes.JACK_KEEP] = "Mantener";
SpanishCardModes[CardModes.JACK_DISCARD] = "Descartar";
SpanishCardModes[CardModes.SPICE_MERCHANT_CARDS] = "Cartas, Acción";
SpanishCardModes[CardModes.SPICE_MERCHANT_COINS] = "Monedas, Compra";
SpanishCardModes[CardModes.CATACOMBS_TAKE] = "Poner en la Mano";
SpanishCardModes[CardModes.CATACOMBS_DISCARD] = "Descartar";
SpanishCardModes[CardModes.COUNT_DISCARD] = "Descartar";
SpanishCardModes[CardModes.COUNT_TOPDECK] = "Poner sobre el mazo";
SpanishCardModes[CardModes.COUNT_GAIN_COPPER] = "Ganar Cobre";
SpanishCardModes[CardModes.COUNT_COINS] = "+3 Monedas";
SpanishCardModes[CardModes.COUNT_TRASH_HAND] = "Eliminar la Mano";
SpanishCardModes[CardModes.COUNT_GAIN_DUCHY] = "Ganar Ducado";
SpanishCardModes[CardModes.HUNTING_GROUNDS_ESTATES] = "Ganar 3 Fincas";
SpanishCardModes[CardModes.HUNTING_GROUNDS_DUCHY] = "Ganar Ducado";
SpanishCardModes[CardModes.IRONMONGER_KEEP] = "Mantener";
SpanishCardModes[CardModes.IRONMONGER_DISCARD] = "Descartar";
SpanishCardModes[CardModes.SCAVENGER_DISCARD] = "Descartar";
SpanishCardModes[CardModes.SCAVENGER_KEEP] = "Continuar";
SpanishCardModes[CardModes.SQUIRE_ACTIONS] = "+2 Acciones";
SpanishCardModes[CardModes.SQUIRE_BUYS] = "+2 Compras";
SpanishCardModes[CardModes.SQUIRE_SILVER] = "Ganar Plata";
SpanishCardModes[CardModes.MERCENARY_DONT_TRASH] = "No Eliminar";
SpanishCardModes[CardModes.DOCTOR_TRASH] = "Eliminar";
SpanishCardModes[CardModes.DOCTOR_DISCARD] = "Descartar";
SpanishCardModes[CardModes.DOCTOR_TOPDECK] = "Poner sobre el mazo";
SpanishCardModes[CardModes.AMULET_COIN] = "+1 Moneda";
SpanishCardModes[CardModes.AMULET_TRASH] = "Eliminar";
SpanishCardModes[CardModes.AMULET_SILVER] = "Ganar Plata";
SpanishCardModes[CardModes.MISER_COPPER_TO_MAT] = "Cobre al Tablero";
SpanishCardModes[CardModes.MISER_PROFIT] = "Beneficio";
SpanishCardModes[CardModes.MESSENGER_DISCARD] = "Descartar";
SpanishCardModes[CardModes.MESSENGER_KEEP] = "Mantener";
SpanishCardModes[CardModes.QUEST_ATTACK] = "Atacar";
SpanishCardModes[CardModes.QUEST_CURSES] = "2 Maldiciones";
SpanishCardModes[CardModes.QUEST_CARDS] = "6 Cartas";
SpanishCardModes[CardModes.TEACHER_CARD] = "Carta";
SpanishCardModes[CardModes.TEACHER_ACTION] = "Acción";
SpanishCardModes[CardModes.TEACHER_BUY] = "Compra";
SpanishCardModes[CardModes.TEACHER_COIN] = "Moneda";
SpanishCardModes[CardModes.LIBRARY_DRAW_IT] = "Mantener";
SpanishCardModes[CardModes.LIBRARY_SKIP_IT] = "Omitir";
SpanishCardModes[CardModes.GLADIATOR_PRETEND] = "No Revelar";
SpanishCardModes[CardModes.SPRAWLING_CASTLE_ESTATES] = "Ganar 3 Fincas";
SpanishCardModes[CardModes.SPRAWLING_CASTLE_DUCHY] = "Ganar Ducado";
SpanishCardModes[CardModes.CHARM_BUY_MONEY] = "+Compra, +2 Monedas";
SpanishCardModes[CardModes.CHARM_COPY] = "Ganar Carta";
SpanishCardModes[CardModes.WILD_HUNT_CARDS] = "Cartas";
SpanishCardModes[CardModes.WILD_HUNT_POINTS] = "Puntos";
SpanishCardModes[CardModes.PRINCE_SET_ASIDE] = "Apartar";
SpanishCardModes[CardModes.PRINCE_NO] = "Continuar";
SpanishCardModes[CardModes.HAMLET_ACTION] = "Descartar por Acción";
SpanishCardModes[CardModes.HAMLET_BUY] = "Descartar por Compra";
SpanishCardModes[CardModes.HAMLET_BOTH] = "Ambas";
SpanishCardModes[CardModes.GOVERNOR_CARDS] = "+3 Cartas";
SpanishCardModes[CardModes.GOVERNOR_TREASURE] = "Ganar Oro";
SpanishCardModes[CardModes.GOVERNOR_REMODEL] = "Eliminar y Ganar";
SpanishCardModes[CardModes.BLESSED_VILLAGE_NOW] = "Recibir ahora";
SpanishCardModes[CardModes.BLESSED_VILLAGE_LATER] = "Próximo turno";
SpanishCardModes[CardModes.PIXIE_TRASH] = "Eliminar";
SpanishCardModes[CardModes.PIXIE_KEEP] = "Mantener";
SpanishCardModes[CardModes.SACRED_GROVE_RECEIVE] = "Recibir";
SpanishCardModes[CardModes.SACRED_GROVE_NO] = "No gracias";
SpanishCardModes[CardModes.ZOMBIE_SPY_DISCARD] = "Descartar";
SpanishCardModes[CardModes.ZOMBIE_SPY_TOPDECK] = "Poner sobre el mazo";
SpanishCardModes[CardModes.START_GAME] = "Comenzar";
SpanishCardModes[CardModes.PEARL_DIVER_TOPDECK] = SpanishPhrases[Phrases.TOP];
SpanishCardModes[CardModes.PEARL_DIVER_BOTTOMDECK] = SpanishPhrases[Phrases.BOTTOM];
SpanishCardModes[CardModes.SCEPTER_FAIL_ACTION] = "Acción Fallida";
SpanishCardModes[CardModes.SCEPTER_ADD_COINS] = "+2 Monedas";
SpanishCardModes[CardModes.TREASURER_FAIL] = "No hacer nada";
SpanishCardModes[CardModes.TREASURER_KEY] = "Coger la Llave";
SpanishCardModes[CardModes.BORDER_GUARD_LANTERN] = "Linterna";
SpanishCardModes[CardModes.BORDER_GUARD_HORN] = "Cuerno";
SpanishCardModes[CardModes.BORDER_GUARD_FAIL] = "No hacer nada";
SpanishCardModes[CardModes.PAGEANT_YES] = "+1 Cofre";
SpanishCardModes[CardModes.PAGEANT_NO] = "No gracias";
SpanishCardModes[CardModes.SINISTER_PLOT_ADD] = "Añadir ficha";
SpanishCardModes[CardModes.SINISTER_PLOT_DRAW] = "Robar";
SpanishCardModes[CardModes.PLAY] = "Jugar";
SpanishCardModes[CardModes.DONT_PLAY] = "No jugar";
SpanishCardModes[CardModes.VILLAGE_GREEN_NOW] = "Ahora";
SpanishCardModes[CardModes.VILLAGE_GREEN_LATER] = "Próximo turno";
SpanishCardModes[CardModes.BARGE_NOW] = "Ahora";
SpanishCardModes[CardModes.BARGE_LATER] = "Próximo turno";
SpanishCardModes[CardModes.SCRAP_CARD] = "+1 Carta";
SpanishCardModes[CardModes.SCRAP_ACTION] = "+1 Acción";
SpanishCardModes[CardModes.SCRAP_BUY] = "+1 Compra";
SpanishCardModes[CardModes.SCRAP_COIN] = "+1 Moneda";
SpanishCardModes[CardModes.SCRAP_SILVER] = "ganar una Plata";
SpanishCardModes[CardModes.SCRAP_HORSE] = "ganar un Caballo";
SpanishCardModes[CardModes.MODE_OF_THE_BUTTERFLY] = "Mariposa";
SpanishCardModes[CardModes.MODE_OF_THE_CAMEL] = "Camello";
SpanishCardModes[CardModes.MODE_OF_THE_CHAMELEON] = "Camaleón";
SpanishCardModes[CardModes.MODE_OF_THE_FROG] = "Rana";
SpanishCardModes[CardModes.MODE_OF_THE_GOAT] = "Cabra";
SpanishCardModes[CardModes.MODE_OF_THE_HORSE] = "Caballo";
SpanishCardModes[CardModes.MODE_OF_THE_MOLE] = "Topo";
SpanishCardModes[CardModes.MODE_OF_THE_MONKEY] = "Mono";
SpanishCardModes[CardModes.MODE_OF_THE_MOUSE] = "Ratón";
SpanishCardModes[CardModes.MODE_OF_THE_MULE] = "Mula";
SpanishCardModes[CardModes.MODE_OF_THE_OTTER] = "Nutria";
SpanishCardModes[CardModes.MODE_OF_THE_OWL] = "Búho";
SpanishCardModes[CardModes.MODE_OF_THE_OX] = "Buey";
SpanishCardModes[CardModes.MODE_OF_THE_PIG] = "Cerdo";
SpanishCardModes[CardModes.MODE_OF_THE_RAT] = "Rata";
SpanishCardModes[CardModes.MODE_OF_THE_SEAL] = "Foca";
SpanishCardModes[CardModes.MODE_OF_THE_SHEEP] = "Oveja";
SpanishCardModes[CardModes.MODE_OF_THE_SQUIRREL] = "Ardilla";
SpanishCardModes[CardModes.MODE_OF_THE_TURTLE] = "Tortuga";
SpanishCardModes[CardModes.MODE_OF_THE_WORM] = "Gusano";
SpanishCardModes[CardModes.BUTTERFLY_RETURN_IT] = "Devolver";
SpanishCardModes[CardModes.BUTTERFLY_DO_NOTHING] = "No hacer nada";
SpanishCardModes[CardModes.WAYFARER_SILVER] = "Ganar Plata";
SpanishCardModes[CardModes.DESPERATION_CURSE] = "Ganar Maldición";
SpanishCardModes[CardModes.CRYSTAL_BALL_TRASH] = "Trash";
SpanishCardModes[CardModes.CRYSTAL_BALL_DISCARD] = "Discard";
SpanishCardModes[CardModes.CRYSTAL_BALL_PLAY] = "Play";
SpanishCardModes[CardModes.CRYSTAL_BALL_TOPDECK] = "Topdeck";
SpanishCardModes[CardModes.INVESTMENT_COIN] = "+$1";
SpanishCardModes[CardModes.INVESTMENT_VP] = "trash for +VP";
SpanishCardModes[CardModes.TOWN_VILLAGE] = "+1 Card +2 Actions"; //        .
SpanishCardModes[CardModes.TOWN_COINS_BUY] = "+1 Buy +2 Coins";
SpanishCardModes[CardModes.MODIFY_CANTRIP] = "+1 Card +1 Action";
SpanishCardModes[CardModes.MODIFY_GAIN] = "Gain a card";
SpanishCardModes[CardModes.DESERT_GUIDES_KEEP] = "Keep it";
SpanishCardModes[CardModes.DESERT_GUIDES_NEW_HAND] = "New Hand";
SpanishCardModes[CardModes.BAUBLE_BUY] = "+1 Buy";
SpanishCardModes[CardModes.BAUBLE_COIN] = "+1 Coin";
SpanishCardModes[CardModes.BAUBLE_FAVOR] = "+1 Favor";
SpanishCardModes[CardModes.BAUBLE_TOPDECK] = "Topdecking";
SpanishCardModes[CardModes.BROKER_CARDS] = "+Cards";
SpanishCardModes[CardModes.BROKER_ACTIONS] = "+Actions";
SpanishCardModes[CardModes.BROKER_COINS] = "+Coins";
SpanishCardModes[CardModes.BROKER_FAVORS] = "+Favors";
SpanishCardModes[CardModes.INNKEEPER_CARD] = "+1 Card";
SpanishCardModes[CardModes.INNKEEPER_3] = "+3 Cards, Discard 3";
SpanishCardModes[CardModes.INNKEEPER_5_6] = "+5 Cards, Discard 6";
SpanishCardModes[CardModes.CAPITAL_CITY_DISCARD] = "Discard 2 for +$2";
SpanishCardModes[CardModes.CAPITAL_CITY_PAY] = "$2 for +2 Cards";
SpanishCardModes[CardModes.SPECIALIST_PLAY_AGAIN] = "Play Again";
SpanishCardModes[CardModes.SPECIALIST_GAIN_COPY] = "Gain Copy";
SpanishCardModes[CardModes.HILL_FORT_TO_HAND] = "Put in Hand";
SpanishCardModes[CardModes.HILL_FORT_CANTRIP] = "+1 Card +1 Action";
SpanishCardModes[CardModes.STRONGHOLD_COINS] = "+3 Coins";
SpanishCardModes[CardModes.STRONGHOLD_CARDS] = "next turn: +3 Cards";
SpanishCardModes[CardModes.TOWN_CRIER_COINS] = "+2 Coins";
SpanishCardModes[CardModes.TOWN_CRIER_SILVER] = "gain a Silver";
SpanishCardModes[CardModes.TOWN_CRIER_CANTRIP] = "+1 Card +1 Action";
SpanishCardModes[CardModes.BLACKSMITH_OWL] = "draw until 6";
SpanishCardModes[CardModes.BLACKSMITH_OTTER] = "+2 Cards";
SpanishCardModes[CardModes.BLACKSMITH_PIG] = "+1 Card +1 Action";
SpanishCardModes[CardModes.LURKER_TRASH] = "Trash from Supply";
SpanishCardModes[CardModes.LURKER_GAIN] = "Gain from Trash";
SpanishCardModes[CardModes.GRAVEROBBER_GAIN] = "Gain onto deck";
SpanishCardModes[CardModes.GRAVEROBBER_TRASH] = "Expand from hand";
SpanishCardModes[CardModes.TREASURER_TRASH] = "Trash";
SpanishCardModes[CardModes.TREASURER_GAIN] = "Gain from Trash";
SpanishCardModes[CardModes.WEAVER_SILVERS] = "2 Silvers";
SpanishCardModes[CardModes.COURSER_CARDS_ACTIONS] = "Cards & Actions";
SpanishCardModes[CardModes.COURSER_CARDS] = "+2 Cards";
SpanishCardModes[CardModes.COURSER_ACTIONS] = "+2 Actions";
SpanishCardModes[CardModes.COURSER_COINS] = "+2 Coins";
SpanishCardModes[CardModes.COURSER_SILVERS] = "+4 Silvers";
SpanishCardModes[CardModes.SICKNESS_FAIL_DISCARD] = "Fail to discard";
SpanishCardModes[CardModes.SICKNESS_CURSE] = "Curse onto your deck";
SpanishCardModes[CardModes.SICKNESS_FAIL_CURSE] = "Fail to gain a Curse";
SpanishCardModes[CardModes.SICKNESS_DISCARD] = "Discard 3 cards";
SpanishCardModes[CardModes.KITSUNE_ACTIONS] = "+2 Actions";
SpanishCardModes[CardModes.KITSUNE_COINS] = "+2 Coins";
SpanishCardModes[CardModes.KITSUNE_CURSE] = "Give Curse";
SpanishCardModes[CardModes.KITSUNE_SILVER] = "Gain a Silver";

SpanishCardModes[ExtraTurnTypes.MISSION] = SpanishCardNames[CardNames.MISSION].singular;
SpanishCardModes[ExtraTurnTypes.OUTPOST] = SpanishCardNames[CardNames.OUTPOST].singular;
SpanishCardModes[ExtraTurnTypes.POSSESSION] = SpanishCardNames[CardNames.POSSESSION].singular;
SpanishCardModes[ExtraTurnTypes.FLEET] = SpanishCardNames[CardNames.FLEET].singular;
SpanishCardModes[ExtraTurnTypes.SEIZE_THE_DAY] = SpanishCardNames[CardNames.SEIZE_THE_DAY].singular;
SpanishCardModes[ExtraTurnTypes.ISLAND_FOLK] = SpanishCardNames[CardNames.ISLAND_FOLK].singular;
SpanishCardModes[ExtraTurnTypes.VOYAGE] = SpanishCardNames[CardNames.VOYAGE].singular;
SpanishCardModes[ExtraTurnTypes.JOURNEY] = SpanishCardNames[CardNames.JOURNEY].singular;

// Missing lines as of 09.01.2023 18:44:56
SpanishCardModes[CardModes.CABIN_BOY_COINS] = "+2 Coins";
SpanishCardModes[CardModes.CABIN_BOY_GAIN] = "Trash for Duration";
SpanishCardModes[CardModes.GONDOLA_NOW] = "+2 Coins";
SpanishCardModes[CardModes.GONDOLA_LATER] = "Next Turn";
SpanishCardModes[CardModes.QUARTERMASTER_GAIN] = "Gain a card";
SpanishCardModes[CardModes.QUARTERMASTER_TAKE] = "Take a card";
SpanishCardModes[CardModes.AMPHORA_NOW] = "+1 Buy, +3 Coins";
SpanishCardModes[CardModes.AMPHORA_LATER] = "Later";
SpanishCardModes[CardModes.SPELL_SCROLL_PLAY] = "Play";
SpanishCardModes[CardModes.SPELL_SCROLL_REFUSE] = "Don't Play";