"use strict";

var EnglishTypes = {};

EnglishTypes[Types.ACTION] = "Action";
EnglishTypes[Types.TREASURE] = "Treasure";
EnglishTypes[Types.ATTACK] = "Attack";
EnglishTypes[Types.CURSE] = "Curse";
EnglishTypes[Types.DURATION] = "Duration";
EnglishTypes[Types.EVENT] = "Event";
EnglishTypes[Types.GATHERING] = "Gathering";
EnglishTypes[Types.KNIGHT] = "Knight";
EnglishTypes[Types.LANDMARK] = "Landmark";
EnglishTypes[Types.LOOTER] = "Looter";
EnglishTypes[Types.RUINS] = "Ruins";
EnglishTypes[Types.TRAVELLER] = "Traveller";
EnglishTypes[Types.PRIZE] = "Prize";
EnglishTypes[Types.RESERVE] = "Reserve";
EnglishTypes[Types.VICTORY] = "Victory";
EnglishTypes[Types.REACTION] = "Reaction";
EnglishTypes[Types.SHELTER] = "Shelter";
EnglishTypes[Types.CASTLE] = "Castle";
EnglishTypes[Types.FATE] = "Fate";
EnglishTypes[Types.NIGHT] = "Night";
EnglishTypes[Types.DOOM] = "Doom";
EnglishTypes[Types.HEIRLOOM] = "Heirloom";
EnglishTypes[Types.SPIRIT] = "Spirit";
EnglishTypes[Types.ZOMBIE] = "Zombie";
EnglishTypes[Types.BOON] = "Boon";
EnglishTypes[Types.HEX] = "Hex";
EnglishTypes[Types.STATE] = "State";
EnglishTypes[Types.PROJECT] = "Project";
EnglishTypes[Types.COMMAND] = "Command";
EnglishTypes[Types.WAY] = "Way";
EnglishTypes[Types.LIAISON] = "Liaison";
EnglishTypes[Types.ALLY] = "Ally";
EnglishTypes[Types.AUGUR] = "Augur";
EnglishTypes[Types.CLASH] = "Clash";
EnglishTypes[Types.CRAFT] = "Craft";
EnglishTypes[Types.FORT] = "Fort";
EnglishTypes[Types.ODYSSEY] = "Odyssey";
EnglishTypes[Types.TOWNSFOLK] = "Townsfolk";
EnglishTypes[Types.TOY] = "Toy";
EnglishTypes[Types.WIZARD] = "Wizard";
EnglishTypes[Types.LOOT] = "Loot";
EnglishTypes[Types.TRAIT] = "Trait";
EnglishTypes[Types.REWARD] = "Reward";
EnglishTypes[Types.SHADOW] = "Shadow";
EnglishTypes[Types.OMEN] = "Omen";
EnglishTypes[Types.PROPHECY] = "Prophecy";

var EnglishZoneNames = {};

EnglishZoneNames["HandZone"] = "Hand";
EnglishZoneNames["DrawZone"] = "Draw Pile";
EnglishZoneNames["DiscardZone"] = "Discard Pile";
EnglishZoneNames["TrashZone"] = "Trash";
EnglishZoneNames["SupplyZone"] = "Supply Pile";
EnglishZoneNames["InPlayZone"] = "Play Area";
EnglishZoneNames["TavernZone"] = "Tavern mat";
EnglishZoneNames["NVZone"] = "Native Village Mat";
EnglishZoneNames["IslandZone"] = "Island mat";
EnglishZoneNames["ExileZone"] = "Exile";
EnglishZoneNames["RevealZone"] = "Reveal";
EnglishZoneNames["TheBox"] = "the Box";
EnglishZoneNames["SetAsideZone"] = "Set Aside";
EnglishZoneNames["SkippableRevealZone"] = "Reveal";

var EnglishMovementTypes = {};

EnglishMovementTypes[MovementTypes.PLAY] = new TranslatedMovementType("play", "plays");
EnglishMovementTypes[MovementTypes.DRAW] = new TranslatedMovementType("draw", "draws");
EnglishMovementTypes[MovementTypes.DISCARD] = new TranslatedMovementType("discard", "discards");
EnglishMovementTypes[MovementTypes.GAIN] = new TranslatedMovementType("gain", "gains");
EnglishMovementTypes[MovementTypes.TRASH] = new TranslatedMovementType("trash", "trashes");
EnglishMovementTypes[MovementTypes.TOPDECK] = new TranslatedMovementType("topdeck", "topdecks");
EnglishMovementTypes[MovementTypes.REVEAL] = new TranslatedMovementType("reveal", "reveals");
EnglishMovementTypes[MovementTypes.REVEAL_SEARCHING] = new TranslatedMovementType("reveal", "reveals");
EnglishMovementTypes[MovementTypes.LOOK_AT] = new TranslatedMovementType("look at", "looks at");
EnglishMovementTypes[MovementTypes.PUT_IN_HAND] = new TranslatedMovementType("put it in hand", "puts it in hand");
EnglishMovementTypes[MovementTypes.SET_ASIDE] = new TranslatedMovementType("set it aside", "sets it aside");
EnglishMovementTypes[MovementTypes.PUT_ON_MAT] = new TranslatedMovementType("put on mat", "puts on mat");
EnglishMovementTypes[MovementTypes.DECK_TO_DISCARD] = new TranslatedMovementType("DECK_TO_DISCARD", "DECK_TO_DISCARD");
EnglishMovementTypes[MovementTypes.BACK_ON_DECK] = new TranslatedMovementType("BACK_ON_DECK", "BACK_ON_DECK");
EnglishMovementTypes[MovementTypes.SHUFFLE_INTO_DECK] = new TranslatedMovementType("SHUFFLE_INTO_DECK", "SHUFFLE_INTO_DECK");
EnglishMovementTypes[MovementTypes.INSERT_IN_DECK] = new TranslatedMovementType("INSERT_IN_DECK", "INSERT_IN_DECK");
EnglishMovementTypes[MovementTypes.EXCHANGE_RETURN] = new TranslatedMovementType("EXCHANGE_RETURN", "EXCHANGE_RETURN");
EnglishMovementTypes[MovementTypes.EXCHANGE_RECEIVE] = new TranslatedMovementType("EXCHANGE_RECEIVE", "EXCHANGE_RECEIVE");
EnglishMovementTypes[MovementTypes.PASS] = new TranslatedMovementType("PASS", "PASS");
EnglishMovementTypes[MovementTypes.RETURN_TO] = new TranslatedMovementType("return", "returns");
EnglishMovementTypes[MovementTypes.PUT_ON_BOTTOM_OF] = new TranslatedMovementType("PUT_ON_BOTTOM_OF", "PUT_ON_BOTTOM_OF");
EnglishMovementTypes[MovementTypes.STARTS_WITH] = new TranslatedMovementType("STARTS_WITH", "STARTS_WITH");
EnglishMovementTypes[MovementTypes.TAKE] = new TranslatedMovementType("TAKE", "TAKE");
EnglishMovementTypes[MovementTypes.GET_STATE] = new TranslatedMovementType("GET_STATE", "GET_STATE");
EnglishMovementTypes[MovementTypes.RETURN_STATE] = new TranslatedMovementType("RETURN_STATE", "RETURN_STATE");
EnglishMovementTypes[MovementTypes.EXILE] = new TranslatedMovementType("EXILE", "EXILE");

var EnglishPileTokenTexts = {};

EnglishPileTokenTexts[TokenNames.PLUS_ACTION] = "+A";
EnglishPileTokenTexts[TokenNames.PLUS_BUY] = "+B";
EnglishPileTokenTexts[TokenNames.PLUS_CARD] = "+C";
EnglishPileTokenTexts[TokenNames.PLUS_COIN] = "+1";
EnglishPileTokenTexts[TokenNames.MINUS_COST] = "-2";
EnglishPileTokenTexts[TokenNames.TRASH] = "X";

var EnglishPileMarkers = {};

EnglishPileMarkers[PileMarkerTypes.BANE] = "Bane";
EnglishPileMarkers[PileMarkerTypes.OBELISK] = "Obelisk";
EnglishPileMarkers[PileMarkerTypes.SPLIT] = "Split";
EnglishPileMarkers[PileMarkerTypes.MIXED] = "Mixed";

var EnglishTokenNames = {};

EnglishTokenNames[TokenNames.PLUS_ACTION] = "+Action token";
EnglishTokenNames[TokenNames.PLUS_BUY] = "+Buy token";
EnglishTokenNames[TokenNames.PLUS_COIN] = "+Coin token";
EnglishTokenNames[TokenNames.PLUS_CARD] = "+Card token";
EnglishTokenNames[TokenNames.MINUS_COST] = "-Cost token";
EnglishTokenNames[TokenNames.TRASH] = "Trashing token";
EnglishTokenNames[TokenNames.JOURNEY] = "Journey token";
EnglishTokenNames[TokenNames.MINUS_CARD] = "-Card token";
EnglishTokenNames[TokenNames.MINUS_COIN] = "-Coin token";

var EnglishKingdomViewerNames = {};

EnglishKingdomViewerNames[KingdomViewerGroups.SUPPLY_CARDS] = "Cards in Supply";
EnglishKingdomViewerNames[KingdomViewerGroups.KINGDOM_LANDSCAPES] = "Events, Landmarks, Projects, Ways, Allies and Traits";
EnglishKingdomViewerNames[KingdomViewerGroups.NON_KINGDOM] = "Non-Kingdom Cards";
EnglishKingdomViewerNames[KingdomViewerGroups.RUINS] = "Ruins";
EnglishKingdomViewerNames[KingdomViewerGroups.DRUID_BOONS] = "Druid Boons";
EnglishKingdomViewerNames[KingdomViewerGroups.KNIGHTS] = "Knights";
EnglishKingdomViewerNames[KingdomViewerGroups.CASTLES] = "Castles";
EnglishKingdomViewerNames[KingdomViewerGroups.PRIZES] = "Prizes";
EnglishKingdomViewerNames[KingdomViewerGroups.REWARDS] = "Rewards";
EnglishKingdomViewerNames[KingdomViewerGroups.BOONS_HEXES] = "Boons and Hexes";
EnglishKingdomViewerNames[KingdomViewerGroups.STATES_ARTIFACTS] = "States and Artifacts";
EnglishKingdomViewerNames[KingdomViewerGroups.BLACK_MARKET] = "Black Market";
EnglishKingdomViewerNames[KingdomViewerGroups.LOOT] = "Loot";