"use strict";

var TChineseExpansionNames = {};

TChineseExpansionNames[Expansions.BASE] = "皇輿爭霸";
TChineseExpansionNames[Expansions.INTRIGUE] = "暗潮洶湧";
TChineseExpansionNames[Expansions.SEASIDE] = "海國圖誌";
TChineseExpansionNames[Expansions.ALCHEMY] = "錬金術士";
TChineseExpansionNames[Expansions.PROSPERITY] = "繁華富貴";
TChineseExpansionNames[Expansions.CORNUCOPIA_GUILDS] = "豐收 & 公會";
TChineseExpansionNames[Expansions.HINTERLANDS] = "內陸迷情";
TChineseExpansionNames[Expansions.DARK_AGES] = "黑暗時代";
TChineseExpansionNames[Expansions.ADVENTURES] = "冒險時刻";
TChineseExpansionNames[Expansions.EMPIRES] = "帝國崛起";
TChineseExpansionNames[Expansions.PROMOS] = "推廣卡片";
TChineseExpansionNames[Expansions.NOCTURNE] = "午夜";
TChineseExpansionNames[Expansions.RENAISSANCE] = "文藝復興";
TChineseExpansionNames[Expansions.MENAGERIE] = "動物世界";
TChineseExpansionNames[Expansions.ALLIES] = '同盟';
TChineseExpansionNames[Expansions.DEPRECATED] = '淘汰合集';
TChineseExpansionNames[Expansions.PLUNDER] = '掠奪';
TChineseExpansionNames[Expansions.RISING_SUN] = '太陽升起';