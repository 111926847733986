"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var LogEntriesChange = function (_GameStateChange) {
    _inherits(LogEntriesChange, _GameStateChange);

    function LogEntriesChange(game, entries) {
        _classCallCheck(this, LogEntriesChange);

        var _this = _possibleConstructorReturn(this, (LogEntriesChange.__proto__ || Object.getPrototypeOf(LogEntriesChange)).call(this, game));

        _this.entries = entries;
        return _this;
    }

    _createClass(LogEntriesChange, [{
        key: "createAnimation",
        value: function createAnimation(game) {
            return new SelfBlockingAnimation(this, "log");
        }
    }, {
        key: "execute",
        value: function execute(game) {
            game.logModel.newLogEntries(this.entries);
        }
    }], [{
        key: "parse",
        value: function parse(game, reader, startIndex, size) {
            var processors = [LogEntry, DecisionEntry];
            var entries = [];
            for (var i = 0; i < size; i++) {
                var processor = processors[reader.readInt()];
                var newEntry = processor.parse(reader, startIndex + i);
                entries.push(newEntry);
            }

            return new LogEntriesChange(game, entries);
        }
    }]);

    return LogEntriesChange;
}(GameStateChange);