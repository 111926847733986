"use strict";

webclient.component('languageSelector', {
    controller: ['languageSelection', function (languageSelection) {
        var self = this;
        self.getLanguages = function () {
            return LANGUAGES.filter(function (l) {
                return l.isVisible();
            });
        };
        self.select = function (language) {
            return languageSelection.select(language);
        };
        self.isSelected = function (language) {
            return language === LANGUAGE;
        };
    }],
    template: '\n            <div class="language-selection">\n                <div ng-repeat="language in $ctrl.getLanguages() track by language.getOrdinal()" class="localized-language-description copper-button {{$ctrl.isSelected(language) ? \'selected\' : \'\'}}" ng-click="$ctrl.select(language)">\n                    {{language.getLocalizedDescription()}}\n                </div>                \n            </div>'
});