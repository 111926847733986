"use strict";

var JapaneseExpansionNames = {};

JapaneseExpansionNames[Expansions.BASE] = "基本";
JapaneseExpansionNames[Expansions.INTRIGUE] = "陰謀";
JapaneseExpansionNames[Expansions.SEASIDE] = "海辺";
JapaneseExpansionNames[Expansions.ALCHEMY] = "錬金術";
JapaneseExpansionNames[Expansions.PROSPERITY] = "繁栄";
JapaneseExpansionNames[Expansions.CORNUCOPIA_GUILDS] = "収穫祭 & ギルド";
JapaneseExpansionNames[Expansions.HINTERLANDS] = "異郷";
JapaneseExpansionNames[Expansions.DARK_AGES] = "暗黒時代";
JapaneseExpansionNames[Expansions.ADVENTURES] = "冒険";
JapaneseExpansionNames[Expansions.EMPIRES] = "帝国";
JapaneseExpansionNames[Expansions.PROMOS] = "プロモ";
JapaneseExpansionNames[Expansions.NOCTURNE] = "夜想曲";
JapaneseExpansionNames[Expansions.RENAISSANCE] = "ルネサンス";
JapaneseExpansionNames[Expansions.MENAGERIE] = "移動動物園";
JapaneseExpansionNames[Expansions.ALLIES] = '同盟';
JapaneseExpansionNames[Expansions.PLUNDER] = '略奪';
JapaneseExpansionNames[Expansions.DEPRECATED] = '旧版';
JapaneseExpansionNames[Expansions.RISING_SUN] = '朝日';