"use strict";

var JapaneseTypes = {};

JapaneseTypes[Types.ACTION] = "アクション";
JapaneseTypes[Types.TREASURE] = "財宝";
JapaneseTypes[Types.ATTACK] = "アタック";
JapaneseTypes[Types.CURSE] = "呪い";
JapaneseTypes[Types.DURATION] = "持続";
JapaneseTypes[Types.EVENT] = "イベント";
JapaneseTypes[Types.GATHERING] = "集合";
JapaneseTypes[Types.KNIGHT] = "騎士";
JapaneseTypes[Types.LANDMARK] = "ランドマーク";
JapaneseTypes[Types.LOOTER] = "略奪者";
JapaneseTypes[Types.RUINS] = "廃墟";
JapaneseTypes[Types.TRAVELLER] = "トラベラー";
JapaneseTypes[Types.PRIZE] = "褒賞";
JapaneseTypes[Types.RESERVE] = "リザーブ";
JapaneseTypes[Types.VICTORY] = "勝利点";
JapaneseTypes[Types.REACTION] = "リアクション";
JapaneseTypes[Types.SHELTER] = "避難所";
JapaneseTypes[Types.CASTLE] = "城";
JapaneseTypes[Types.FATE] = "幸運";
JapaneseTypes[Types.NIGHT] = "夜行";
JapaneseTypes[Types.DOOM] = "不運";
JapaneseTypes[Types.HEIRLOOM] = "家宝";
JapaneseTypes[Types.SPIRIT] = "精霊";
JapaneseTypes[Types.ZOMBIE] = "ゾンビ";
JapaneseTypes[Types.BOON] = "祝福";
JapaneseTypes[Types.HEX] = "呪詛";
JapaneseTypes[Types.STATE] = "状態";
JapaneseTypes[Types.PROJECT] = "プロジェクト";
JapaneseTypes[Types.COMMAND] = "命令";
JapaneseTypes[Types.WAY] = "習性";
JapaneseTypes[Types.LIAISON] = "連携";
JapaneseTypes[Types.ALLY] = "同盟";
JapaneseTypes[Types.AUGUR] = "卜占官";
JapaneseTypes[Types.CLASH] = "衝突";
JapaneseTypes[Types.FORT] = "城砦";
JapaneseTypes[Types.ODYSSEY] = "叙事詩";
JapaneseTypes[Types.TOWNSFOLK] = "町民";
JapaneseTypes[Types.WIZARD] = "魔法使い";
JapaneseTypes[Types.LOOT] = "戦利品";
JapaneseTypes[Types.TRAIT] = "特性";
JapaneseTypes[Types.REWARD] = "恩賞";
JapaneseTypes[Types.SHADOW] = "影";
JapaneseTypes[Types.OMEN] = "前兆";
JapaneseTypes[Types.PROPHECY] = "予言";

var JapaneseZoneNames = {};

JapaneseZoneNames["HandZone"] = "手札";
JapaneseZoneNames["DrawZone"] = "山札";
JapaneseZoneNames["DiscardZone"] = "捨て札置き場";
JapaneseZoneNames["TrashZone"] = "廃棄置き場";
JapaneseZoneNames["SupplyZone"] = "サプライの山";
JapaneseZoneNames["InPlayZone"] = "場";
JapaneseZoneNames["SetAsideZone"] = "脇";
JapaneseZoneNames["TavernZone"] = "酒場マット";
JapaneseZoneNames["NVZone"] = "原住民の村マット";
JapaneseZoneNames["IslandZone"] = "島マット";
JapaneseZoneNames["ExileZone"] = "追放マット";
JapaneseZoneNames["RevealZone"] = "公開中";
JapaneseZoneNames["TheBox"] = "箱"; //for Inherited
JapaneseZoneNames["SetAsideZone"] = "脇";
JapaneseZoneNames["SkippableRevealZone"] = "公開中";

var JapaneseMovementTypes = {};

JapaneseMovementTypes[MovementTypes.PLAY] = new TranslatedMovementType("使用する", "使用する");
JapaneseMovementTypes[MovementTypes.DRAW] = new TranslatedMovementType("引く", "引く");
JapaneseMovementTypes[MovementTypes.DISCARD] = new TranslatedMovementType("捨て札にする", "捨て札にする");
JapaneseMovementTypes[MovementTypes.GAIN] = new TranslatedMovementType("獲得する", "獲得する");
JapaneseMovementTypes[MovementTypes.TRASH] = new TranslatedMovementType("廃棄する", "廃棄する");
JapaneseMovementTypes[MovementTypes.TOPDECK] = new TranslatedMovementType("山札に置く", "山札に置く");
JapaneseMovementTypes[MovementTypes.REVEAL] = new TranslatedMovementType("公開する", "公開する");
JapaneseMovementTypes[MovementTypes.REVEAL_SEARCHING] = new TranslatedMovementType("公開する", "公開する");
JapaneseMovementTypes[MovementTypes.LOOK_AT] = new TranslatedMovementType("見る", "見る");
JapaneseMovementTypes[MovementTypes.PUT_IN_HAND] = new TranslatedMovementType("手札に加える", "手札に加える");
JapaneseMovementTypes[MovementTypes.SET_ASIDE] = new TranslatedMovementType("脇に置く", "脇に置く");
JapaneseMovementTypes[MovementTypes.PUT_ON_MAT] = new TranslatedMovementType("マット上に置く", "マット上に置く");
JapaneseMovementTypes[MovementTypes.DECK_TO_DISCARD] = new TranslatedMovementType("山札を捨て札に置く", "山札を捨て札に置く");
JapaneseMovementTypes[MovementTypes.BACK_ON_DECK] = new TranslatedMovementType("山札に戻す", "山札に戻す");
JapaneseMovementTypes[MovementTypes.SHUFFLE_INTO_DECK] = new TranslatedMovementType("山札に混ぜシャッフルする", "山札に混ぜシャッフルする");
JapaneseMovementTypes[MovementTypes.INSERT_IN_DECK] = new TranslatedMovementType("山札に加える", "山札に加える");
JapaneseMovementTypes[MovementTypes.EXCHANGE_RETURN] = new TranslatedMovementType("山に戻す", "山に戻す");
JapaneseMovementTypes[MovementTypes.EXCHANGE_RECEIVE] = new TranslatedMovementType("山から受け取る", "山から受け取る");
JapaneseMovementTypes[MovementTypes.PASS] = new TranslatedMovementType("渡す", "渡す");
JapaneseMovementTypes[MovementTypes.RETURN_TO] = new TranslatedMovementType("戻す", "戻す");
JapaneseMovementTypes[MovementTypes.PUT_ON_BOTTOM_OF] = new TranslatedMovementType("底に置く", "底に置く");
JapaneseMovementTypes[MovementTypes.STARTS_WITH] = new TranslatedMovementType("始める", "始める");
JapaneseMovementTypes[MovementTypes.TAKE] = new TranslatedMovementType("取る", "取る");
JapaneseMovementTypes[MovementTypes.GET_STATE] = new TranslatedMovementType("状態を得る", "状態を得る");
JapaneseMovementTypes[MovementTypes.RETURN_STATE] = new TranslatedMovementType("状態を戻す", "状態を戻す");
JapaneseMovementTypes[MovementTypes.EXILE] = new TranslatedMovementType("追放する", "追放する");

var JapanesePileTokenTexts = {};

JapanesePileTokenTexts[TokenNames.PLUS_ACTION] = "+行";
JapanesePileTokenTexts[TokenNames.PLUS_BUY] = "+購";
JapanesePileTokenTexts[TokenNames.PLUS_CARD] = "+引";
JapanesePileTokenTexts[TokenNames.PLUS_COIN] = "+$";
JapanesePileTokenTexts[TokenNames.MINUS_COST] = "-$2";
JapanesePileTokenTexts[TokenNames.TRASH] = "棄";

var JapanesePileMarkers = {};

JapanesePileMarkers[PileMarkerTypes.BANE] = "災い";
JapanesePileMarkers[PileMarkerTypes.OBELISK] = "オベリスク";
JapanesePileMarkers[PileMarkerTypes.SPLIT] = "分割";
JapanesePileMarkers[PileMarkerTypes.MIXED] = "複合";

var JapaneseTokenNames = {};

JapaneseTokenNames[TokenNames.PLUS_ACTION] = "+1アクショントークン";
JapaneseTokenNames[TokenNames.PLUS_BUY] = "+1購入トークン";
JapaneseTokenNames[TokenNames.PLUS_COIN] = "+$1トークン";
JapaneseTokenNames[TokenNames.PLUS_CARD] = "+1カードトークン";
JapaneseTokenNames[TokenNames.MINUS_COST] = "-$2トークン";
JapaneseTokenNames[TokenNames.TRASH] = "廃棄トークン";
JapaneseTokenNames[TokenNames.JOURNEY] = "旅トークン";
JapaneseTokenNames[TokenNames.MINUS_CARD] = "-1カードトークン";
JapaneseTokenNames[TokenNames.MINUS_COIN] = "-$1トークン";

var JapaneseKingdomViewerNames = {};

JapaneseKingdomViewerNames[KingdomViewerGroups.SUPPLY_CARDS] = "サプライ";
JapaneseKingdomViewerNames[KingdomViewerGroups.KINGDOM_LANDSCAPES] = "ランドスケープ";
JapaneseKingdomViewerNames[KingdomViewerGroups.NON_KINGDOM] = "サプライ外";
JapaneseKingdomViewerNames[KingdomViewerGroups.RUINS] = "廃墟";
JapaneseKingdomViewerNames[KingdomViewerGroups.DRUID_BOONS] = "ドルイドの祝福";
JapaneseKingdomViewerNames[KingdomViewerGroups.KNIGHTS] = "騎士";
JapaneseKingdomViewerNames[KingdomViewerGroups.CASTLES] = "城";
JapaneseKingdomViewerNames[KingdomViewerGroups.BOONS_HEXES] = "祝福、呪詛";
JapaneseKingdomViewerNames[KingdomViewerGroups.STATES_ARTIFACTS] = "状態、アーティファクト";
JapaneseKingdomViewerNames[KingdomViewerGroups.BLACK_MARKET] = "闇市場";
JapaneseKingdomViewerNames[KingdomViewerGroups.PRIZES] = "褒賞";
JapaneseKingdomViewerNames[KingdomViewerGroups.REWARDS] = "恩賞";
JapaneseKingdomViewerNames[KingdomViewerGroups.LOOT] = "戦利品";