"use strict";

var TChineseLevelIntroductions = ["等級一包含了：基本版的25張卡片，能讓新手開心遊玩的等級。", "等級二新增了一些簡單的卡片，完全沒有額外的規則負擔！"];

var TChineseGameConcepts = {};
TChineseGameConcepts[GameConcepts.CHOICE_CARDS] = new TranslatedGameConcept("抉擇卡", "\u904A\u6232\u4E2D\u6709\u4E9B\u5361\u7247\u5728\u6253\u51FA\u4F86\u4E4B\u5F8C\uFF0C\u6703\u4F7F\u73A9\u5BB6\u505A\u4E00\u4E9B\u9078\u64C7\uFF0C\n\u5728\u6253\u51FA\u4E4B\u5F8C\u6709\u9078\u9805\u53EF\u4EE5\u9078\uFF0C\u6216\u662F\u8B93\u4F60\u6307\u5B9A\u4E00\u4E9B\u5361\u7247\u540D\u7A31\uFF0C\n\u5982\u679C\u9700\u8981\u6307\u5B9A\u5361\u7247\u540D\u7A31\uFF0C\u4F60\u53EF\u4EE5\u6253\u5B57\u6216\u662F\u9EDE\u9078\u4F9B\u61C9\u5340\u7684\u5361\u7247\u3002");

TChineseGameConcepts[GameConcepts.DURATIONS] = new TranslatedGameConcept("持續卡", "\u6301\u7E8C\u5361\u662F\u6A58\u8272\u7684\u5361\u7247\uFF0C\u6548\u679C\u70BA\u8DE8\u56DE\u5408\u7684\u80FD\u529B\uFF0C. \n\u6301\u7E8C\u5361\u6BD4\u8F03\u7279\u5225\u7684\u662F\uFF0C\u4ED6\u5728\u6548\u679C\u767C\u52D5\u5B8C\u4E4B\u524D\uFF0C\u6703\u4E00\u76F4\u7559\u5728\u51FA\u724C\u5340\u3002\n\u738B\u5EA7\u4E4B\u985E\u7684\u5361\u7247\u914D\u4E0A\u6301\u7E8C\u5361\u7684\u8A71\uFF0C\u738B\u5EA7\u4E5F\u6703\u5F85\u5728\u51FA\u724C\u5340\uFF0C\u7B49\u6301\u7E8C\u5361\u88AB\u68C4\u6389\u3002");

TChineseGameConcepts[GameConcepts.KINGDOM_TREASURES] = new TranslatedGameConcept("特殊錢幣卡", "\u9322\u5E63\u5361\u4E0D\u4E00\u5B9A\u53EA\u6703\u63D0\u4F9B\u4F60\u9810\u7B97\uFF0C\u53EF\u80FD\u9084\u6703\u7D66\u4F60\u66F4\u591A\u6548\u679C\uFF0C\u5C31\u597D\u50CF\u662F\u884C\u52D5\u5361\u4E00\u6A23\uFF0C ");

TChineseGameConcepts[GameConcepts.PLATINUM_COLONY] = new TranslatedGameConcept("白金與殖民地", "\u767D\u91D1\u662F\u5F35\u63D0\u4F9B [5]\u7684\u9322\u5E63\u5361; \u6B96\u6C11\u5730\u5247\u662F10\u5206\u5361. \u9019\u5169\u5F35\u5361\u4E0D\u662F\u738B\u570B\u5361; \u4ED6\u5011\u5C31\u50CF\u662F\u4E00\u822C\u9EC3\u91D1\u548C\u884C\u7701\u4E00\u6A23\u7684\u57FA\u672C\u5361\uFF0C\n\u4F46\u662F\u4ED6\u5011\u4E26\u4E0D\u6703\u983B\u7E41\u5730\u51FA\u73FE\uFF0C\u5728\u738B\u570B\u5361\u88E1\u9762\u6709\u7E41\u83EF\u5BCC\u8CB4\u7684\u5361\u7247\u51FA\u73FE\u6642\uFF0C\u624D\u6709\u6A5F\u7387\u6703\u51FA\u73FE. \u7E41\u83EF\u5BCC\u8CB4\u7684\u5361\u7247\u8D8A\u591A\uFF0C\u6A5F\u7387\u5C31\u8D8A\u9AD8\u3002\n\u904A\u6232\u7D50\u675F\u689D\u4EF6\u65B0\u589E\u4E00\u9805\uFF1A\u4F9B\u61C9\u5340\u7684\u6B96\u6C11\u5730\u7A7A\u4E86");

TChineseGameConcepts[GameConcepts.WHEN_GAIN_BUY] = new TranslatedGameConcept("當你購買或獲得卡片時", "\u67D0\u4E9B\u5361\u7247\u6703\u5728\u4F60\u8CFC\u8CB7\u6216\u662F\u7372\u5F97\u6642\u767C\u52D5\u6548\u679C\uFF0C\u8CFC\u8CB7\u8207\u7372\u5F97\u662F\u5169\u4EF6\u4E8B\u60C5\uFF0C\u4E0D\u6703\u540C\u6642\u767C\u751F\uFF0C\n\u7576\u4F60\u8CFC\u8CB7\u5361\u7247\uFF0C\u6703\u5148\u89F8\u767C\u8CFC\u8CB7\u7684\u6548\u679C\uFF0C\u8CFC\u8CB7\u5B8C\u4E4B\u5F8C\u63A5\u8457\u662F\u7372\u5F97\uFF0C\u518D\u89F8\u767C\u7372\u5F97\u5361\u7247\u7684\u6548\u679C\u3002.\n\u4E5F\u53EF\u4EE5\u4E0D\u900F\u904E\u8CFC\u8CB7\u800C\u76F4\u63A5\u7372\u5F97, \u4F8B\u5982\u4F7F\u7528\u91CD\u5EFA\u3002");

TChineseGameConcepts[GameConcepts.WHEN_TRASH] = new TranslatedGameConcept("當卡片被移除", "\u67D0\u4E9B\u5361\u5728\u79FB\u9664\u5361\u7247\u6642\u6703\u767C\u52D5\u6548\u679C.\n\u5361\u7247\u6703\u5728\u89F8\u767C\u79FB\u9664\u7684\u7576\u4E0B\u7ACB\u523B\u767C\u52D5\u6548\u679C\u3002");

TChineseGameConcepts[GameConcepts.VP_TOKENS] = new TranslatedGameConcept("分數片", "\u672C\u904A\u6232\u5230\u6700\u5F8C\u662F\u6BD4\u8F03\u5206\u6578\uFF0C\u5206\u6578\u7247\u4E00\u6A23\u6703\u7B97\u662F\u4F60\u7684\u5206\u6578\uFF0C\u4F46\u662F\u4E26\u4E0D\u662F\u5361\u7247\uFF0C\u4E5F\u4E0D\u6703\u9032\u5165\u4F60\u7684\u724C\u5EAB\u3002");

TChineseGameConcepts[GameConcepts.COFFERS] = new TranslatedGameConcept("存款", "\u67D0\u4E9B\u5361\u7247\u4E0A\u9762\u6703\u6709\u5BEB +\u5B58\u6B3E; \u9019\u4E9B\u5B58\u6B3E\u662F\u53EF\u4EE5\u653E\u5230\u672A\u4F86\u518D\u7528\u7684 []\u3002\n\u5728\u4F60\u56DE\u5408\u4E2D\u4EFB\u4F55\u6642\u5019\uFF0C\u53EF\u4EE5\u6D88\u8017\u5B58\u6B3E\u4F86\u8F49\u70BA\u9810\u7B97\u3002\n\u59B3\u53EF\u4EE5\u4F7F\u7528\u7576\u56DE\u5408\u7372\u5F97\u7684\u9810\u7B97\u3002");

TChineseGameConcepts[GameConcepts.VILLAGERS] = new TranslatedGameConcept("村民", "\u6709\u4E9B\u5361\u6703\u7D66\u4F60\u6751\u6C11\uFF0C\u9019\u662F\u53EF\u4EE5\u5728\u672A\u4F86\u4F7F\u7528\u7684\u884C\u52D5\u6578\uFF0C\n\u5728\u4F60\u7684\u884C\u52D5\u968E\u6BB5\uFF0C\u4F60\u53EF\u4EE5\u5C07\u4EFB\u610F\u6751\u6C11\u8F49\u63DB\u70BA\u5C0D\u61C9\u6578\u91CF\u7684\u884C\u52D5\u6578\u3002");

TChineseGameConcepts[GameConcepts.EXILE] = new TranslatedGameConcept("驅逐", "\u6BCF\u500B\u73A9\u5BB6\u6709\u81EA\u5DF1\u7684\u9A45\u9010\u677F\u584A\uFF0C\u4E0D\u6703\u8DDF\u5176\u4ED6\u4EBA\u5171\u7528\uFF0C\n\"\u9A45\u9010\u4E00\u5F35\u5361\" \u4EE3\u8868\u5C07\u90A3\u5F35\u5361\u653E\u5230\u90A3\u500B\u4EBA\u7684\u9A45\u9010\u677F\u584A\u4E0A;\n\u5982\u679C\u8A72\u5361\u5728\u51FA\u724C\u5340\uFF0C\u4E0D\u6703\u767C\u52D5 \"\u7576\u4F60\u7372\u5F97\u5361\u7247\u6642\" \u7684\u6548\u679C, \u4F46\u662F\u5361\u7247\u4ECD\u7136\u662F\u4F60\u7684 (\u6700\u5F8C\u8A08\u5206\u7528).\n\u800C\u7576\u4F60\u7372\u5F97\u4E00\u5F35\u5361\u6642\uFF0C\u4F60\u53EF\u4EE5\u68C4\u6389\u6240\u6709\u9A45\u9010\u677F\u584A\u4E0A\u7684\u540C\u540D\u5361(\u5C07\u4ED6\u5011\u5168\u90E8\u653E\u5230\u68C4\u724C\u5806)\u3002\n\u4F60\u4E0D\u53EF\u4EE5\u53EA\u68C4\u6389\u5176\u4E2D\u5E7E\u4E2D;\u53EA\u80FD\u68C4\u6389\u5168\u90E8\u6216\u662F\u4E0D\u68C4\u6389\u3002");

TChineseGameConcepts[GameConcepts.REACTIONS] = new TranslatedGameConcept("應對卡", "\u61C9\u5C0D\u5361\u662F\u85CD\u8272\u7684\uFF0C\u5728\u67D0\u4E9B\u6642\u523B\u53EF\u4EE5\u767C\u52D5\u6548\u679C\uFF0C\n\u6709\u6642\u5019\u5728\u4F60\u624B\u4E0A\u7684\u6642\u5019\u53EF\u4EE5\u767C\u52D5\u6548\u679C;\u6709\u4E9B\u6642\u5019\u5728\u4F60\u68C4\u6389\u724C\u7684\u6642\u5019; \u6216\u662F\u8B93\u4F60\u6253\u51FA\u724C\u3002.\n\u76F8\u4FE1\u4F60\u5F88\u719F\u6089\u57FA\u672C\u7248\u88E1\u9762\u7684\u8B77\u57CE\u6CB3; \u4F46\u662F\u5176\u4ED6\u7684\u5C0D\u61C9\u5361\u4E26\u4E0D\u662F\u76F4\u63A5\u7121\u6548\uFF0C\u800C\u662F\u6839\u64DA\u8A72\u5361\u7247\u63CF\u8FF0\u3002");

TChineseGameConcepts[GameConcepts.WHEN_DISCARD] = new TranslatedGameConcept("卡片被棄掉的效果", "\u6709\u4E9B\u5361\u7247\u5728\u88AB\u68C4\u6389\u7684\u6642\u5019\u6703\u6709\u6548\u679C\uFF0C\n\u6709\u4E9B\u662F\u5728\u5F9E\u4F60\u624B\u4E0A\u88AB\u68C4\u6389\u7684\u6642\u5019\u767C\u52D5\uFF0C\n\u6709\u4E9B\u5361\u7247\u662F\u8981\u5F9E\u4F60\u51FA\u724C\u5340\u88AB\u68C4\u6389\u7684\u6642\u5019\uFF0C\u6703\u767C\u52D5\u6548\u679C\u3002");

TChineseGameConcepts[GameConcepts.TRASH_INTERACTION] = new TranslatedGameConcept("跟移除牌堆互動的卡", "\u6709\u4E9B\u5361\u7247\u6703\u8DDF\u79FB\u9664\u724C\u5806\u4E92\u52D5\uFF0C\u4F8B\u5982\u8AAA\u5F9E\u79FB\u9664\u5361\u7247\u88E1\u9762\u7372\u5F97\u5361\u7247\uFF0C\n\u6216\u662F\u5F9E\u79FB\u9664\u724C\u5806\u88E1\u9762\u767C\u52D5\u6548\u679C\u3002\n\u4F60\u53EF\u4EE5\u9EDE\u9078\u756B\u9762\u53F3\u4E0A\u7684\u79FB\u9664\u5361\uFF0C\u4F86\u67E5\u770B\u6709\u54EA\u4E9B\u5361\u7247\u88AB\u653E\u5230\u79FB\u9664\u724C\u5806\u3002");

TChineseGameConcepts[GameConcepts.UNIQUE_MATS] = new TranslatedGameConcept("專屬板塊", "\u67D0\u4E9B\u5361\u7247\u6703\u4F7F\u7528\u7279\u6B8A\u677F\u584A\uFF0C\u6BCF\u500B\u73A9\u5BB6\u6703\u4F7F\u7528\u81EA\u5DF1\u7684\u677F\u584A\uFF0C\n\u6703\u8DDF\u5176\u4ED6\u73A9\u5BB6\u7684\u677F\u584A\u5206\u958B\u4F7F\u7528\u3002");

TChineseGameConcepts[GameConcepts.EVENTS] = new TranslatedGameConcept("事件卡", "\u904A\u6232\u4E2D\u5076\u723E\u6703\u51FA\u73FE\u4E00\u4E9B\u6A6B\u7F6E\u7684\u666F\u89C0\u5361\uFF0C\u800C\u4E8B\u4EF6\u662F\u5176\u4E2D\u4E00\u7A2E\u666F\u89C0\u5361\u3002\n\u4E8B\u4EF6\u5361\u6BCF\u5F35\u90FD\u64C1\u6709\u81EA\u5DF1\u7684\u50F9\u683C\u8207\u6548\u679C\uFF0C\u4F60\u53EF\u4EE5\u5728\u4F60\u7684\u8CFC\u8CB7\u968E\u6BB5\u4E2D,\n\u6D88\u8017\u6389\u6240\u9700\u7684\u9810\u7B97\u8207\u4E00\u500B\u8CFC\u8CB7\uFF0C\u4F86\u8CFC\u8CB7\u4E00\u6B21\u4E8B\u4EF6\uFF0C\n\u8A18\u4F4F\uFF0C\u5728\u672C\u904A\u6232\u4E2D\uFF0C\u8CFC\u8CB7\u4E8B\u4EF6\u4E0D\u5C6C\u65BC\u8CFC\u8CB7\u5361\u7247\u3002");

TChineseGameConcepts[GameConcepts.RESERVE] = new TranslatedGameConcept("保留卡", "\u6DE1\u8910\u8272\u7684\u5361\u662F\u4FDD\u7559\u5361\uFF0C\u4ED6\u5011\u6703\u5C07\u81EA\u5DF1\u653E\u5230\u4F60\u81EA\u5DF1\u7684\u65C5\u884C\u677F\u584A\u4E0A\uFF0C\n\u4E26\u4E14\u5927\u90E8\u5206\u6703\u63D0\u4F9B\u4E00\u500B\"\u547C\u53EB\"\u529F\u80FD\uFF0C\"\u547C\u53EB\" \u6703\u8B93\u4F60\u5C07\u5361\u7247\u653E\u5230\u51FA\u724C\u5340\uFF0C\n\u6240\u4EE5\u5927\u81F4\u4E0A\u90FD\u662F\uFF0C\u4ED6\u5011\u7684\u6548\u679C\u6703\u7559\u5230\u4E4B\u5F8C\u7684\u67D0\u500B\u6642\u9593\u767C\u52D5\u3002");

TChineseGameConcepts[GameConcepts.ADVENTURES_TOKENS] = new TranslatedGameConcept("冒險指示物", "\u5728\u5192\u96AA\u64F4\u5145\u88E1\u9762\uFF0C\u6709\u8A31\u591A\u7A2E\u6307\u793A\u7269\uFF0C\n\u9019\u4E9B\u6307\u793A\u7269\u6703\u6839\u64DA\u6307\u793A\uFF0C\u653E\u5728\u4F9B\u61C9\u5340\u7684\u67D0\u758A\u5361\u7247\u4E0A\uFF0C\n\u5982\u679C\u4F60\u4E4B\u5F8C\u6253\u51FA\u8A72\u7A2E\u5361\u7247\uFF0C\u6703\u5148\u7372\u5F97\u8A72\u984D\u5916\u6548\u679C\u3002");

TChineseGameConcepts[GameConcepts.NIGHT_PHASE] = new TranslatedGameConcept("夜晚卡", "\u591C\u665A\u5361\u53EA\u80FD\u5728\u591C\u665A\u968E\u6BB5\u6253\u51FA\u4F86\uFF0C\n\u800C\u591C\u665A\u968E\u6BB5\u6703\u5728\u8CFC\u8CB7\u968E\u6BB5\u4E4B\u5F8C\uFF0C\u6E05\u9664\u968E\u6BB5\u4E4B\u524D\u3002\n\u5728\u591C\u665A\u968E\u6BB5\u4F60\u53EA\u80FD\u6253\u51FA\u591C\u665A\u5361\uFF0C\u4F60\u53EF\u4EE5\u6253\u51FA\u591A\u5F35\u591C\u665A\u5361\u3002\n\u7576\u5361\u7247\u6709\u9664\u4E86\u591C\u665A\u5361\u4EE5\u5916\u7684\u985E\u5225\u6642\uFF0C\u4E5F\u53EF\u4EE5\u5728\u5225\u7684\u968E\u6BB5\u6253\u51FA\u3002");

TChineseGameConcepts[GameConcepts.SHELTERS] = new TranslatedGameConcept("避難所", "\u5982\u679C\u5834\u4E0A\u6709\u6697\u9ED1\u6642\u4EE3\u7684\u5361\u7247\uFF0C\u907F\u96E3\u6240\u7684\u51FA\u73FE\u7684\u6A5F\u7387\u6703\u589E\u52A010%\u3002\n\u907F\u96E3\u6240\u7E3D\u5171\u6709\u4E09\u5F35\uFF0C\u6703\u53D6\u4EE3\u6389\u8D77\u59CB\u7684\u4E09\u5F35\u838A\u5712\u3002\n\u9019\u4E09\u5F35\u907F\u96E3\u6240\u4E0D\u5C6C\u65BC\u4F9B\u61C9\u5340\uFF0C\u4E26\u4E14\u5404\u6709\u4E0D\u4E00\u6A23\u7684\u6548\u679C\u3002");

TChineseGameConcepts[GameConcepts.HORSES] = new TranslatedGameConcept("馬", "\u67D0\u4E9B\u5361\u53EF\u4EE5\u8B93\u4F60\u7372\u5F97\u99AC\uFF0C\u4F46\u662F\u99AC\u4E0D\u5C6C\u65BC\u4F9B\u61C9\u5340\uFF0C\n\u7136\u800C\u99AC\u7684\u724C\u5806\u7A7A\u4E86\u4E0D\u6703\u5C0E\u81F4\u904A\u6232\u7D50\u675F\u3002");

TChineseGameConcepts[GameConcepts.PROJECTS] = new TranslatedGameConcept("計畫", "\u8A08\u756B\u662F\u5176\u4E2D\u4E00\u7A2E\u666F\u89C0\u5361\uFF0C\u6BCF\u5834\u904A\u6232\u4F60\u4E5F\u6700\u591A\u53EA\u80FD\u8CB7\u5169\u7A2E\u8A08\u756B\uFF0C\u56E0\u6B64\u5C31\u7B97\u6709\u56DB\u7A2E\u8A08\u756B\u5728\u5834\u4E0A\uFF0C\u4F60\u4E5F\u53EA\u80FD\u9078\u5176\u4E2D\u5169\u7A2E\u8CFC\u8CB7\u3002\n\u5728\u4F60\u8CFC\u8CB7\u968E\u6BB5\u4E2D\uFF0C\u4F60\u53EF\u4EE5\u7528\u4E00\u500B\u8CFC\u8CB7\u8207\u6240\u9700\u7684\u9810\u7B97\u8CFC\u8CB7\u4E00\u5F35\u8A08\u756B\uFF0C\u7576\u4F60\u8CFC\u8CB7\u8A08\u756B\u5F8C\uFF0C\n\u6703\u7528\u4F60\u7684\u984F\u8272\u6A19\u793A\u4F60\u5DF2\u7D93\u8CB7\u904E\u8A72\u8A08\u756B\u4E86\uFF0C\u4E00\u65E6\u4F60\u6A19\u8A18\u4E86\u8A08\u756B\uFF0C\u5728\u5269\u4E0B\u7684\u904A\u6232\u4E2D\u4F60\u90FD\u5FC5\u9808\u4F7F\u7528\u8A72\u6548\u679C\u3002");

TChineseGameConcepts[GameConcepts.LANDMARKS] = new TranslatedGameConcept("地標", "\u5730\u6A19\u662F\u5176\u4E2D\u4E00\u7A2E\u666F\u89C0\u5361\uFF0C\u9019\u4E9B\u5730\u6A19\u6703\u63D0\u4F9B\u5404\u7A2E\u4E0D\u540C\u7684\u984D\u5916\u5206\u6578\n,\u5206\u6578\u53EF\u80FD\u662F\u5206\u6578\u6A19\u8A8C\u6216\u662F\u6700\u5F8C\u7684\u984D\u5916\u7B97\u5206\u3002");

TChineseGameConcepts[GameConcepts.EXTRA_CARDS] = new TranslatedGameConcept("額外卡片", "\u6709\u4E9B\u5361\u7247\u6703\u4F7F\u7528\u5230\u4E00\u4E9B\u984D\u5916\u7684\u5361\u7247\uFF0C\n\u4F8B\u5982\u8AAA\u99AC\uFF0C\u9019\u4E9B\u975E\u4F9B\u61C9\u5340\u7684\u5361\u7247\uFF0C\u53EA\u80FD\u7531\u6307\u5B9A\u7684\u5361\u7247\u7372\u5F97\uFF0C\u6216\u662F\u900F\u904E\u4EA4\u63DB\uFF0C\u800C\u4E14\u6B64\u724C\u5806\u6E05\u7A7A\u4E0D\u6703\u89F8\u767C\u904A\u6232\u7D50\u675F\u689D\u4EF6\u3002\n\u9664\u4E86\u975E\u4F9B\u61C9\u5340\u4EE5\u5916\u7684\u984D\u5916\u5361\u7247\uFF0C\u9084\u6709\u5EE2\u589F\uFF0C\n\u5EE2\u589F\u6703\u5728\u9700\u8981\u7528\u5230\u5EE2\u589F\u7684\u6642\u5019\u51FA\u73FE\uFF0C\u7E3D\u5171\u6709\u4E94\u7A2E\u5361\u7247\u6703\u8B93\u5EE2\u589F\u51FA\u73FE\u3002\n\u5EE2\u589F\u7E3D\u5171\u6709\u4E94\u7A2E\uFF0C\u6BCF\u5834\u51FA\u73FE\u7684\u7A2E\u985E\u9806\u5E8F\u4E0D\u5B9A\uFF0C\u4F46\u55AE\u4E00\u7A2E\u985E\u7684\u5EE2\u589F\u6700\u591A10\u5F35\u3002\n");

TChineseGameConcepts[GameConcepts.SPLIT_PILES] = new TranslatedGameConcept("各半的卡片", "\n\u6709\u4E9B\u5361\u7247\u6703\u6709\u524D\u4E94\u5F35\u5F8C\u4E94\u5F35\u4E0D\u4E00\u6A23\u7684\u72C0\u6CC1.\n\u524D\u4E94\u5F35\u6703\u76F8\u5C0D\u4FBF\u5B9C\uFF0C \u5F8C\u4E94\u5F35\u6703\u6BD4\u8F03\u8CB4\u3002\n\n\u9084\u6709\u5169\u7A2E\u6BD4\u8F03\u7279\u5225\uFF0C\u516B\u5F35\u90FD\u4E0D\u4E00\u6A23\u7684\u57CE\u5821\uFF0C\u5341\u5F35\u90FD\u4E0D\u4E00\u6A23\u7684\u9A0E\u58EB\u3002\n");

TChineseGameConcepts[GameConcepts.ARTIFACTS] = new TranslatedGameConcept("工藝品", "\u6709\u56DB\u7A2E\u5361\u7247\u6703\u63D0\u5230\u5DE5\u85DD\u54C1\uFF0C\n\u5DE5\u85DD\u54C1\u50CF\u662F\u4E00\u7A2E\u72C0\u614B\uFF0C\u6A19\u793A\u4F60\u6709\u751A\u9EBC\u984D\u5916\u6548\u679C\u3002\n\u4F46\u662F\u5DE5\u85DD\u54C1\u53EA\u80FD\u7531\u4E00\u500B\u4EBA\u62FF\u8457\uFF0C\u5982\u679C\u5728\u5176\u4ED6\u4EBA\u62FF\u8457\u7684\u6642\u5F8C\u4F60\u8981\u62FF\uFF0C\n\u5C31\u6703\u628A\u5B83\u62FF\u904E\u4F86\u3002");

TChineseGameConcepts[GameConcepts.BOONS_HEXES] = new TranslatedGameConcept("咒術與祝福", "\u9019\u662F\u5169\u758A\u5404\u81EA\u4E0D\u540C\u7684\u6548\u679C\u5361\uFF0C\n\u5492\u8853\u985E\u4F3C\u653B\u64CA\uFF0C\u795D\u798F\u5247\u662F\u734E\u52F5\uFF0C\n\u5169\u7A2E\u5404\u670912\u7A2E\u4E0D\u540C\u7684\u5361\u7247\uFF0C\u757612\u7A2E\u6548\u679C\u90FD\u767C\u52D5\u904E\u4E00\u6B21\u5F8C\uFF0C\u6703\u91CD\u6D17\u4E00\u6B21\u8A72\u758A\u3002");

TChineseGameConcepts[GameConcepts.HEIRLOOMS] = new TranslatedGameConcept("傳家寶", "\u50B3\u5BB6\u5BF6\u6703\u53D6\u4EE3\u8D77\u59CB\u7684\u9285\u5E63\uFF0C\n\u800C\u67D0\u4E9B\u7279\u5B9A\u7684\u738B\u570B\u5361\u6703\u7D66\u4E88\u6BCF\u500B\u73A9\u5BB6\u4E00\u5F35\u50B3\u5BB6\u5BF6\u3002\n\u7576\u8A72\u7A2E\u738B\u570B\u5361\u540C\u6642\u51FA\u73FE\u591A\u5F35\uFF0C\u50B3\u5BB6\u5BF6\u4E5F\u6703\u8DDF\u8457\u8B8A\u591A\u3002");

TChineseGameConcepts[GameConcepts.STRANGE_COSTS] = new TranslatedGameConcept("浮動價格", "\u6709\u4E9B\u5361\u7247\u50F9\u683C\u6703\u8DDF\u8457\u72C0\u6CC1\u6539\u8B8A.\n\u90A3\u4E9B\u738B\u570B\u5361\u7684\u50F9\u683C\u4E0D\u6B62\u5F71\u97FF\u4F9B\u61C9\u5340\u7684\u90E8\u5206\uFF0C\u5728\u4EFB\u4F55\u5730\u65B9\u50F9\u683C\u90FD\u6703\u8DDF\u8457\u6539\u8B8A\n");

TChineseGameConcepts[GameConcepts.OVERPAY] = new TranslatedGameConcept("超額支付", "\u7576\u4F60\u8CFC\u8CB7\u7684\u6642\u5019\uFF0C\u4F60\u53EF\u4EE5\u591A\u4ED8\u51FA\u9810\u7B97\u4F86\u8CB7\uFF0C\u4E26\u4E14\u89F8\u767C\u984D\u5916\u6548\u679C\u3002\n");

TChineseGameConcepts[GameConcepts.COMMAND] = new TranslatedGameConcept("命令卡", "\u6709\u4E9B\u5361\u7247\u53EF\u4EE5\u76F4\u63A5\u767C\u52D5\u5176\u4ED6\u5361\u7247\u7684\u6548\u679C\uFF0C\u767C\u52D5\u6548\u679C\u7684\u5361\u7247\u6703\u4E0D\u80FD\u88AB\u79FB\u52D5\u3002\n\u5982\u679C\u547D\u4EE4\u5361\u767C\u52D5\u6301\u7E8C\u5361\u7684\u6548\u679C\uFF0C\u547D\u4EE4\u5361\u8981\u5F85\u5230\u8A72\u6548\u679C\u767C\u52D5\u5B8C\u6210\uFF0C\u624D\u80FD\u88AB\u68C4\u6389\u3002");

TChineseGameConcepts[GameConcepts.POTION] = new TranslatedGameConcept("藥劑", "\u6709\u4E9B\u5361\u7684\u50F9\u683C\u4E0A\u6703\u6709 [P] \u3002\n\u53EA\u6709\u5728\u6253\u51FA\u85E5\u5291\u7684\u6642\u5019\u624D\u6709\u8FA6\u6CD5\u63D0\u4F9B\u6B64\u8B8A\u9AD4\u50F9\u683C (\u8A72\u5834\u904A\u6232\u6709\u9700\u8981\u85E5\u5291\u7684\u5361\u7247\uFF0C\u85E5\u5291\u624D\u6703\u8DDF\u8457\u51FA\u73FE\u5728\u4F9B\u61C9\u5340).\n\u85E5\u5291\u50F9\u683C\u4E5F\u8DDF [] \u4E00\u6A23; \u4F60\u6253\u51FA\u85E5\u5291, \u7136\u5F8C\u628A\u4ED6\u82B1\u6389\u3002\n\n\u5728\u6AA2\u67E5\u50F9\u683C\u7684\u6642\u5019\uFF0C\u6703\u5FFD\u7565\u85E5\u5291\u7684\u90E8\u5206\uFF0C\n\u4F46\u662F\u5728\u6BD4\u8F03\u5169\u5F35\u5361\u7247\u7684\u50F9\u683C\u6642\uFF0C\u9810\u7B97\u8DDF\u85E5\u5291\u662F\u500B\u5225\u6BD4\u8F03\u7684\uFF0C\n\u53EA\u6709\u5728\u9810\u7B97\u8207\u85E5\u5291\u5169\u7A2E\u90FD\u6BD4\u8F03\u9AD8\u7684\u6642\u5019\uFF0C\u4E00\u5F35\u5361\u624D\u662F\u6BD4\u53E6\u4E00\u5F35\u9AD8\uFF0C\n\u6240\u4EE5\u6703\u767C\u751F\u5169\u5F35\u5361\u7247\u50F9\u503C\u90FD\u6C92\u6709\u6BD4\u5C0D\u65B9\u9AD8\u3002");

TChineseGameConcepts[GameConcepts.DEBT] = new TranslatedGameConcept("負債", "\u8CA0\u50B5\u53EF\u4EE5\u8B93\u4F60\u5148\u8CB7\u5361\u7247\uFF0C\u4E4B\u5F8C\u518D\u9084\u9322\u3002\n\u6709\u4E9B\u5361\u7247\u7684\u50F9\u683C\u6703\u5305\u542B [D] \u3002\u4EE3\u8868\u8457\u8CA0\u50B5;\u4F60\u4E0D\u9700\u8981\u7ACB\u523B\u4ED8\u51FA []\uFF0C\u800C\u662F\u62FF\u53D6\u5C0D\u61C9\u6578\u91CF\u7684\u8CA0\u50B5\u3002\n\u5982\u679C\u4F60\u8EAB\u4E0A\u6709\u9084\u6C92\u9084\u5B8C\u7684\u8CA0\u50B5\uFF0C\u4F60\u4E0D\u80FD\u8CB7\u4EFB\u4F55\u6771\u897F\u3002\n\u5728\u4F60\u7684\u8CFC\u8CB7\u968E\u6BB5\u4E2D\uFF0C\u4F60\u53EF\u4EE5\u9084\u4F60\u7684\u8CA0\u50B5\uFF0C \u6BCF [1] \u53EF\u4EE5\u9084 [1D]\u3002\n\u9084\u50B5\u4E0D\u9700\u8981\u82B1\u8CBB\u8CFC\u8CB7\uFF0C\u4F60\u53EF\u4EE5\u9084\u4EFB\u4F55\u6578\u91CF\u7684\u50B5\u52D9\u3002\n\u88DC\u5145\u898F\u5247\uFF1A\u6240\u6709\u6C92\u6709\u8CA0\u50B5\u7684\u7684\u5361\u7247\uFF0C\u5747\u7576\u4F5C\u6709 [0D] \u5728\u50F9\u683C\u4E0A\uFF0C\u6240\u4EE5\u5728\u6BD4\u50F9\u7684\u6642\u5019\u9700\u6CE8\u610F\u8CA0\u50B5");

TChineseGameConcepts[GameConcepts.WAYS] = new TranslatedGameConcept("法則", "\u6CD5\u5247\u662F\u5176\u4E2D\u4E00\u7A2E\u666F\u89C0\u5361\uFF0C\u6BCF\u6B21\u767C\u52D5\u884C\u52D5\u5361\u7684\u6642\u5019\uFF0C\u4F60\u53EF\u4EE5\u6539\u70BA\u767C\u52D5\u6CD5\u5247\u7684\u6548\u679C\u3002\n\u4F46\u662F\u4E00\u4E9B\u5361\u7247\u4E0B\u534A\u90E8\u7684\u6548\u679C\u4E0D\u6703\u88AB\u53D6\u6D88\u6389\uFF0C\u50C5\u6539\u8B8A\u51FA\u724C\u6548\u679C\u3002\n\u4F8B\u5982\u8AAA\uFF1A\u6253\u51FA\u5E79\u9053\u767C\u52D5\u99F1\u99DD\u7684\u6548\u679C\uFF0C\u4F60\u6703\u9A45\u9010\u4E00\u5F35\u9EC3\u91D1\uFF0C\n\u800C\u5E79\u9053\u9084\u5728\u51FA\u724C\u5340\uFF0C\u9084\u662F\u6703\u8B93\u6240\u6709\u5361\u7247\u50F9\u683C\u964D\u4F4E\u3002\n");

// Missing lines as of 09.01.2023 20:41:41
TChineseGameConcepts[GameConcepts.FAVORS] = new TranslatedGameConcept("支持", "\u806F\u7D61\u4EBA\u53EF\u4EE5\u8B93\u4F60\u7372\u5F97\u652F\u6301\uFF0C\u5728\u6709\u904A\u73A9\u806F\u7D61\u4EBA\u7684\u904A\u6232\u4E2D\uFF0C\u4E00\u5B9A\u6703\u914D\u4E0A\u4E00\u5F35\u540C\u76DF\u5361.\n\u540C\u76DF\u5361\u6703\u544A\u8A34\u4F60\u672C\u5834\u904A\u6232\u7684\u652F\u6301\u53EF\u4EE5\u505A\u4E9B\u751A\u9EBC\u3002 \n\u5728\u6709\u806F\u7D61\u4EBA\u7684\u904A\u6232\u4E2D\uFF0C\u904A\u6232\u958B\u59CB\u6642\u4F60\u6703\u7372\u5F97\u4E00\u9EDE\u652F\u6301\u3002");

TChineseGameConcepts[GameConcepts.DEBT_COSTS] = new TranslatedGameConcept("負債", "\u8CA0\u50B5\u6703\u8B93\u4F60\u5148\u7372\u5F97\u5361\u7247\uFF0C\u904A\u6232\u4E4B\u5F8C\u518D\u511F\u9084\u9019\u4E9B\u8CA0\u50B5\uFF0C \u9019\u500B [D] \u7B26\u865F\u4EE3\u8868\u4E86\u8CA0\u50B5\uFF0C \n\u4F60\u4E0D\u7528\u652F\u4ED8\u4EFB\u4F55 [] \uFF0C\u5C31\u53EF\u4EE5\u8CFC\u8CB7 [D] \u7684\u5361\u7247\uFF0C\u4F46\u662F\u4F60\u4E5F\u6703\u62FF\u53BB\u5C0D\u61C9\u6578\u91CF\u7684 [D] \uFF0C  \u53EA\u8981\u4F60\u9084\u64C1\u6709 [D] \u6A19\u8A18\uFF0C\u4F60\u5C31\u4E0D\u53EF\u4EE5\u8CFC\u8CB7\u5361\u7247/\u4E8B\u4EF6/\u8A08\u756B;\n\u5728\u4F60\u7684\u56DE\u5408\uFF0C\u4F60\u53EF\u4EE5\u652F\u4ED8 [1] \u4F86\u511F\u9084 [1D] \uFF0C\u511F\u9084\u7684\u6642\u5019\u4E0D\u9700\u8981\u6D88\u8017\u8CFC\u8CB7\u6578. \n\n[D] \u50F9\u503C\u7684\u5361\u7247\u7121\u6CD5\u76F4\u63A5\u8207 [] \u6BD4\u8F03\u50F9\u683C\uFF0C\u6C92\u6709 [D] \u50F9\u503C\u7684\u5361\u7247\u5747\u7576\u4F5C\u6709\u96B1\u85CF\u7684 [0D]\uFF0C\u800C\u53EA\u6709 [D] \u7684\u5361\u7247\u5247\u7576\u4F5C\u6709\u96B1\u85CF\u7684 [0]\uFF0C\u6BD4\u8F03\u50F9\u683C\u6642\uFF0C\u9700\u8981\u5169\u500B\u90FD\u5927\u65BC\u7B49\u65BC\u624D\u7B97\u662F\u6BD4\u8F03\u9AD8\u50F9\u503C\u3002");

TChineseGameConcepts[GameConcepts.ON_PLAY_DEBT] = new TranslatedGameConcept("打出時獲得負債", "\u6709\u4E9B\u5361\u7247\u6703\u518D\u6253\u51FA\u6642\u8B93\u4F60\u7372\u5F97\u8CA0\u50B5\uFF0C\u8A72\u8CA0\u50B5\u8207\u4E00\u822C\u7684\u8CA0\u50B5\u7121\u5DEE\u5225\uFF0C\u4E00\u6A23\u53EF\u4EE5\u96A8\u6642\u511F\u9084\uFF0C\u4E26\u4E14\u6703\u5F71\u97FF\u4F60\u8CFC\u8CB7");

TChineseGameConcepts[GameConcepts.SHADOW] = new TranslatedGameConcept("陰影", "\u9670\u5F71\u724C\u6709\u7279\u6B8A\u7684\u724C\u80CC\uFF0C\u5728\u6BCF\u6B21\u6D17\u724C\u7684\u6642\u5019\uFF0C\u4F60\u5FC5\u9808\u5C07\u6240\u6709\u9670\u5F71\u724C\u6D17\u5230\u724C\u5EAB\u5E95\uFF0C\u4E26\u4E14\u5728\u4F60\u60F3\u8981\u7684\u6642\u5019\uFF0C\u53EF\u4EE5\u5F9E\u724C\u5EAB\u6700\u5E95\u4E0B\u76F4\u63A5\u6253\u51FA\uFF0C\u7576\u4F5C\u5F9E\u4F60\u624B\u724C\u6253\u51FA\u3002\n    \u4F60\u53EF\u4EE5\u900F\u904E\u738B\u5EA7\uFF0C\u7136\u5F8C\u5F9E\u724C\u5EAB\u5E95\u6253\u51FA\u9670\u5F71\u724C\uFF0C\u4E26\u57F7\u884C\u5169\u6B21\u6548\u679C");

TChineseGameConcepts[GameConcepts.OMEN_PROPHECY] = new TranslatedGameConcept("預兆 & 預言", "\u9810\u5146\u5361\u6703\u8B93\u904A\u6232\u52A0\u5165\u9810\u8A00\u4E8B\u4EF6\uFF0C\u4E26\u4E14\u9810\u5146\u5361\u7686\u6709 +1 \u967D\u5149\uFF0C\u9810\u8A00\u5361\u4E0A\u9762\u6703\u6709\u5C0D\u61C9\u6578\u91CF\u7684 [\u967D\u5149] \u6A19\u8A18\uFF0C2\u4EBA\u5C405\u500B 3\u4EBA\u5C408\u500B,\u56DB\u4EBA\u5C40 10\u500B\uFF0C\u5728\u6BCF\u6B21 +1\u967D\u5149\u7684\u6642\u5019\uFF0C\u79FB\u9664\u4E00\u500B\u967D\u5149\n    . \u9810\u5146\u5361\u5728\u904A\u6232\u4E00\u958B\u59CB\u662F\u6C92\u6709\u4EFB\u4F55\u6548\u679C\u7684\uFF0C\u4F46\u7576\u9810\u5146\u5361\u4E0A\u9762\u7684\u592A\u967D\u88AB\u6E05\u9664\u5B8C\u7684\u6642\u5019; \u9810\u5146\u5361\u5728\u63A5\u4E0B\u4F86\u7684\u904A\u6232\u7686\u6703\u767C\u52D5\u6548\u679C\u3002");