"use strict";

var GermanQuestionDescriptions = {};

GermanQuestionDescriptions[QuestionIds.GAME_RESOLVE_ABILITY] = ["Führe eine Pflicht-Fähigkeit durch.", "Führe einen Effekt aus."];
GermanQuestionDescriptions[QuestionIds.GAME_MAY_RESOLVE_ABILITY] = ["Führe eine optionale Fähigkeit durch.", "Du darfst einen Effekt ausführen."];
GermanQuestionDescriptions[QuestionIds.GAME_MAY_REACT_WITH] = ["Reagiere auf etwas.", "Willst Du reagieren?"];
GermanQuestionDescriptions[QuestionIds.GAME_TURN_SELECT] = ["Wähle einen Zug.", "Welche Art Zug soll als nächstes durchgeführt werden?"];
GermanQuestionDescriptions[QuestionIds.GAME_MAY_BUY] = ["Karten kaufen.", "Du darfst Karten kaufen"];
GermanQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING] = ["beginnen, Karten zu kaufen.", "Du darfst Karten kaufen"];
GermanQuestionDescriptions[QuestionIds.GAME_MAY_BUY_NONCARDS] = ["kaufen, aber keine Karten.", "Du darfst etwas kaufen, aber keine Karten"];
GermanQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING_NONCARDS] = ["beginnen zu kaufen, aber keine Karten.", "Du darfst etwas kaufen, aber keine Karten"];
GermanQuestionDescriptions[QuestionIds.GAME_REPAY_HOW_MUCH] = ["Bezahle Schulden.", "Du darfst deine Schulden abbezahlen"];
GermanQuestionDescriptions[QuestionIds.GAME_SPEND_COIN_TOKENS] = ["Bezahle Taler.", "Du darfst Taler ausgeben"];
GermanQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_ACTION] = ["eine Aktionskarte aus deiner Hand spielen.", "Du darfst Aktionskarten spielen"];
GermanQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_TREASURES] = ["Geldkarten spielen.", "Du darfst Geldkarten spielen"];
GermanQuestionDescriptions[QuestionIds.GAME_PLAY_ALL_TREASURES] = ["Alle Geldkarten spielen.", "GAME_PLAY_ALL_TREASURES"];
GermanQuestionDescriptions[QuestionIds.GAME_ACTION_PHASE] = ["Es ist deine Aktionsphase", "Du darfst Aktionskarten spielen"];
GermanQuestionDescriptions[QuestionIds.GAME_BUY_PHASE] = ["Es ist deine Kaufphase", "Du darfst Karten kaufen"];
GermanQuestionDescriptions[QuestionIds.GAME_BUY_PHASE_NONCARDS] = ["Es ist deine Kaufphase", "Du darfst etwas kaufen, aber keine Karten"];
GermanQuestionDescriptions[QuestionIds.GAME_CLEANUP_PHASE] = ["Wähle Aufräumfähigkeiten und ihre Reihenfolge.", "Wähle Aufräumfähigkeiten"];
GermanQuestionDescriptions[QuestionIds.GAME_USE_VILLAGER] = ["Du kannst einen Dorfbewohner einsetzen.", "Dorfbewohner einsetzen"];
GermanQuestionDescriptions[QuestionIds.GAME_USE_COFFERS] = ["You may use Coffers.", "Du kannst Taler ausgeben"];
GermanQuestionDescriptions[QuestionIds.ARTISAN_GAIN] = ["Nimm dir eine Karte, die bis zu $5 kostet.", "Nimm dir eine Karte"];
GermanQuestionDescriptions[QuestionIds.ARTISAN_TOPDECK] = ["Lege ein Handkarte auf deinen Nachziehstapel.", "Lege ein Karte auf den Nachziehstapel."];
GermanQuestionDescriptions[QuestionIds.BANDIT] = ["Entsorge eine Karte von PLAYER0.", "Entsorge eine Karte von PLAYER0."];
GermanQuestionDescriptions[QuestionIds.BUREAUCRAT] = ["Decke eine Punktekarte aus deiner Hand auf und lege sie auf deinen Nachziehstapel.", "Lege eine Punktekarte auf deinen Nachziehstapel."];
GermanQuestionDescriptions[QuestionIds.CELLAR] = ["Lege eine beliebige Anzahl Karten ab, dann ziehe genauso viele.", "Lege Karten ab, dann ziehe"];
GermanQuestionDescriptions[QuestionIds.CHAPEL] = ["Entsorge bis zu 4 Karten aus deiner Hand.", "Entsorge bis zu 4 Karten"];
GermanQuestionDescriptions[QuestionIds.HARBINGER_TOPDECK] = ["Du darfst eine Karte aus dem Ablagestapel auf deinen Nachziehstapel legen.", "Du darfst eine Karte auf deinen Nachziehstapel legen."];
GermanQuestionDescriptions[QuestionIds.LIBRARY] = ["Du darfst CARDS0 auslassen und beiseite legen.", "Du darfst CARDS0 auslassen"];
GermanQuestionDescriptions[QuestionIds.MILITIA] = ["Lege alle bis auf 3 Karten ab.", "Lege bis auf 3 Handkarten ab."];
GermanQuestionDescriptions[QuestionIds.MINE_TRASH] = ["Du darfst eine Geldkarte entsorgen, um dir eine Geldkarte auf die Hand zu nehmen, die bis zu 3$ mehr kostet", "Du darfst eine Geldkarte aufwerten"];
GermanQuestionDescriptions[QuestionIds.MINE_GAIN] = ["Nimm dir eine Geldkarte auf die Hand, die bis zu AMOUNT0 kostet.", "Nimm dir eine Geldkarte bis zu AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.MONEYLENDER] = ["Du darfst ein Kupfer aus der Hand für +$3 entsorgen.", "Du darfst ein Kupfer entsorgen"];
GermanQuestionDescriptions[QuestionIds.POACHER] = ["Lege Argument0 ab.", "Lege Argument0 ab."];
GermanQuestionDescriptions[QuestionIds.REMODEL_TRASH] = ["Entsorge eine Karte aus deiner Hand. Nimm dir eine Karte, die bis zu $2 mehr kostet.", "Entsorge ein Karte"];
GermanQuestionDescriptions[QuestionIds.REMODEL_GAIN] = ["Nimm dir eine Karte, die bis zu AMOUNT0 kostet.", "Nimm dir eine Karte bis zu AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.SENTRY_TRASH] = ["Du darfst eine beliebige Anzahl der aufgedeckten Karten entsorgen", "Du darfst entsorgen"];
GermanQuestionDescriptions[QuestionIds.SENTRY_DISCARD] = ["Lege eine beliebige Anzahl der aufgedeckten Karten ab.", "Du darfst ablegen"];
GermanQuestionDescriptions[QuestionIds.SENTRY_TOPDECK] = ["Lege die Karten in beliebiger Reihenfolge zurück auf deinen Nachziehstapel", "Lege in beliebiger Reihenfolge zurück"];
GermanQuestionDescriptions[QuestionIds.THRONE_ROOM] = ["Du darfst eine Aktionskarte aus deiner Hand zweimal spielen.", "Du darfst eine Aktionskarte 2 mal spielen"];
GermanQuestionDescriptions[QuestionIds.VASSAL] = ["Du darfst CARDS0 aus deinem Ablagestapel spielen.", "Du darfst CARDS0 spielen"];
GermanQuestionDescriptions[QuestionIds.WORKSHOP] = ["Nimm eine Karte, die bis zu $4 kostet.", "Nimm eine Karte"];
GermanQuestionDescriptions[QuestionIds.TRASHING_TOKEN] = ["Du darfst eine Karte aus deiner Hand entsorgen.", "Du darfst entsorgen"];
GermanQuestionDescriptions[QuestionIds.ALMS] = ["Nimm dir eine Karte, die bis zu $4 kostet.", "Nimm dir eine Karte"];
GermanQuestionDescriptions[QuestionIds.AMULET_MODE] = ["Wähle eines: +$1; oder entsorge eine Karte aus deiner Hand; oder nimm dir ein Silber.", "Wähle Amulett-Option"];
GermanQuestionDescriptions[QuestionIds.AMULET_TRASH] = ["Entsorge eine Karte aus deiner Hand.", "Entsorge eine Karte"];
GermanQuestionDescriptions[QuestionIds.ARTIFICER_DELAY] = ["Du darfst eine beliebige Anzahl Handkarten ablegen. Du darfst dir eine Karte auf deinen Nachziehstapel nehmen, die genau $1 pro abgelegter Karte kostet.", "Du darfst den Kunsthandwerker benutzen."];
GermanQuestionDescriptions[QuestionIds.ARTIFICER_DISCARD] = ["Lege eine beliebige Anzahl Handkarten ab. Du darfst dir eine Karte auf deinen Nachziehstapel nehmen, die genau $1 pro abgelegter Karte kostet.", "Lege Karten ab, dann nimm eine Karte"];
GermanQuestionDescriptions[QuestionIds.ARTIFICER_GAIN] = ["Du darfst dir eine Karte auf deinen Nachziehstapel nehmen, die genau AMOUNT0 kostet.", "Du darfst dir eine Karte nehmen für AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.BALL_FIRST] = ["Nimm dir 2 Karten, die bis zu $4 kosten.", "Nimm dir zwei Karten"];
GermanQuestionDescriptions[QuestionIds.BALL_SECOND] = ["Nimm dir noch eine Karte, die bis zu $4 kostet.", "Nimm dir noch eine Karte"];
GermanQuestionDescriptions[QuestionIds.BONFIRE] = ["Entsorge bis zu zwei Kupfer, die du im Spiel hast.", "Entsorge bis zu 2 deiner Kupfer aus dem Spiel"];
GermanQuestionDescriptions[QuestionIds.DISCIPLE] = ["Du darfst eine Aktionskarte aus deiner Hand zweimal spielen. Nimm dir ein weiteres Exemplar der Karte.", "Spiele zweimal, dann nimm eine weitere"];
GermanQuestionDescriptions[QuestionIds.DUNGEON] = ["Lege 2 Karten ab.", "Lege 2 Karten ab"];
GermanQuestionDescriptions[QuestionIds.FERRY] = ["Lege deinen -$2 Kostenmarker auf einen Aktionskarten-Vorratsstapel.", "Platziere deinen -2$ Kostenmarker"];
GermanQuestionDescriptions[QuestionIds.FUGITIVE] = ["Lege eine Karte aus deiner Hand ab.", "Lege eine Karte ab"];
GermanQuestionDescriptions[QuestionIds.GEAR] = ["Lege bis zu 2 Handkarten beiseite. Zu Beginn deines nächsten Zuges, nimm sie auf die Hand.", "Lege bis zu 2 Karten beiseite"];
GermanQuestionDescriptions[QuestionIds.HAUNTED_WOODS] = ["Lege deine Handkarten in beliebiger Reihenfolge auf deinen Nachziehstapel", "Lege deine Handkarten auf den Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.HERO] = ["Nimm dir eine Geldkarte.", "Nimm dir eine Geldkarte"];
GermanQuestionDescriptions[QuestionIds.LOST_ARTS_NEW] = ["Lege deinen +1 Aktion-Marker auf einen Aktionskarten-Vorratsstapel.", "Platziere deinen +1 Aktion-Marker"];
GermanQuestionDescriptions[QuestionIds.LOST_ARTS_MOVE] = ["Bewege deinen +1 Aktion-Marker auf einen Aktionskarten-Vorratsstapel.", "Platziere deinen +1 Aktion-Marker"];
GermanQuestionDescriptions[QuestionIds.MESSENGER_DISCARD] = ["Du darfst deinen Nachziehstapel auf den Ablagestapel legen.", "Du darfst deinen Nachziehstapel ablegen"];
GermanQuestionDescriptions[QuestionIds.MESSENGER_GAIN] = ["Nimm dir eine Karte, die bis zu $4 kostet, jeder andere Spieler bekommt ebenfalls ein Exemplar.", "Alle nehmen eine Karte, die bis zu $4 kostet"];
GermanQuestionDescriptions[QuestionIds.MISER] = ["Wähle eines: Lege ein Kupfer aus deiner Hand auf dein Wirtshaustableau; oder +AMOUNT0", "Kupfer auf Tableau oder +AMOUNT0?"];
GermanQuestionDescriptions[QuestionIds.PATHFINDING_NEW] = ["Lege deinen +1 Karte-Marker auf einen Aktionskarten-Vorratsstapel.", "Platziere deinen +1 Karte-Marker"];
GermanQuestionDescriptions[QuestionIds.PATHFINDING_MOVE] = ["Bewege deinen +1 Karte-Marker auf einen Aktionskarten-Vorratsstapel.", "Platziere deinen +1 Karte-Marker"];
GermanQuestionDescriptions[QuestionIds.PILGRIMAGE_FIRST] = ["Nimm dir bis zu drei Karten mit unterschiedlichen Namen, von denen du ein Exemplar im Spiel hast.", "Nimm dir bis zu 3 Karten"];
GermanQuestionDescriptions[QuestionIds.PILGRIMAGE_SECOND] = ["Nimm dir bis zu zwei weitere Karten mit unterschiedlichen Namen, von denen du ein Exemplar im Spiel hast.", "Nimm dir bis zu 2 Karten"];
GermanQuestionDescriptions[QuestionIds.PILGRIMAGE_THIRD] = ["Du darfst dir eine weitere Karten mit unterschiedlichen Namen nehmen, von der du ein Exemplar im Spiel hast.", "Nimm dir eine Karte"];
GermanQuestionDescriptions[QuestionIds.PLAN_NEW] = ["Lege deinen Entsorgungsmarker auf einen Aktionskarten-Vorratsstapel.", "Platziere deinen Entsorgungsmarker"];
GermanQuestionDescriptions[QuestionIds.PLAN_MOVE] = ["Bewege deinen Entsorgungsmarker auf einen Aktionskarten-Vorratsstapel.", "Platziere deinen Entsorgungsmarker"];
GermanQuestionDescriptions[QuestionIds.QUEST_MODE] = ["Wähle Angriffskarte, zwei Flüche, oder 6 Karten abzulegen", "Wähle Quest-Option"];
GermanQuestionDescriptions[QuestionIds.QUEST_ATTACK] = ["Lege eine Angriffskarte ab.", "Lege eine Angriffskarte ab"];
GermanQuestionDescriptions[QuestionIds.QUEST_6CARDS] = ["Lege sechs Karten ab.", "Lege sechs Karten ab"];
GermanQuestionDescriptions[QuestionIds.RATCATCHER] = ["Entsorge eine Handkarte.", "Entsorge eine Karte"];
GermanQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_PLAY] = ["Du darfst die Zerstörung entsorgen.", "Du darfst die Zerstörung entsorgen"];
GermanQuestionDescriptions[QuestionIds.RAZE_ALL] = ["Entsorge eine Handkarte oder die Zerstörung im Spiel.", "Entsorge Handkarte oder Zerstörung"];
GermanQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_HAND] = ["Du darfst eine Handkarte entsorgen.", "Du darfst eine Handkarte entsorgen"];
GermanQuestionDescriptions[QuestionIds.RAZE_CHOOSE_CARD] = ["Wähle die Karte, die du auf die Hand nimmst.", "Nimm eine Karte auf die Hand"];
GermanQuestionDescriptions[QuestionIds.SAVE] = ["Lege eine Handkarte beiseite, Nimm sie am Ende des Zuges auf deine Hand zurück (nach dem Karten ziehen).", "Lege eine Karte beiseite"];
GermanQuestionDescriptions[QuestionIds.SCOUTING_PARTY_DISCARD] = ["Lege NUMBER0 Karten ab.", "Lege NUMBER0 Karten ab."];
GermanQuestionDescriptions[QuestionIds.SCOUTING_PARTY_TOPDECK] = ["Lege die restlichen Karten in beliebiger auf deinen Nachziehstapel.", "Lege Karten auf deinen Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.SEAWAY] = ["Nimm dir eine Aktionskarte, die bis zu $4 kostet. Lege deinen +1 Kauf-Marker auf diesen Vorratsstapel", "Nimm dir eine Aktionskarte"];
GermanQuestionDescriptions[QuestionIds.SOLDIER] = ["Lege eine Karte ab.", "Lege eine Karte ab"];
GermanQuestionDescriptions[QuestionIds.STORYTELLER_FIRST] = ["Spiele bis zu 3 Geldkarten aus deiner Hand.", "Spiele bis zu 3 Geldkarten"];
GermanQuestionDescriptions[QuestionIds.STORYTELLER_SECOND] = ["Spiele bis zu 2 weitere Geldkarten aus deiner Hand.", "Spiele bis zu 2 Geldkarten"];
GermanQuestionDescriptions[QuestionIds.STORYTELLER_THIRD] = ["Du darfst eine weitere Geldkarte aus deiner Hand spielen", "Du darfst eine Geldkarte spielen"];
GermanQuestionDescriptions[QuestionIds.TEACHER_CHOOSE_TOKEN] = ["Wähle einen Marker aus, den du bewegen möchtest.", "Wähle einen Marker"];
GermanQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_MOVE] = ["Lege deinen +1 Aktion-Marker auf einen Vorratsstapel, auf dem du keinen Marker hast.", "Platziere deinen +1 Aktion-Marker"];
GermanQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_NEW] = ["Bewege deinen +1 Aktion-Marker auf einen Vorratsstapel, auf dem du keinen Marker hast.", "Platziere deinen +1 Aktion-Marker"];
GermanQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_MOVE] = ["Lege deinen +1 Karte-Marker auf einen Vorratsstapel, auf dem du keinen Marker hast.", "Platziere deinen +1 Karte-Marker"];
GermanQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_NEW] = ["Bewege deinen +1 Karte-Marker auf einen Vorratsstapel, auf dem du keinen Marker hast.", "Platziere deinen +1 Karte-Marker"];
GermanQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_MOVE] = ["Lege deinen +1 Geld-Marker auf einen Vorratsstapel, auf dem du keinen Marker hast.", "Platziere deinen +1 Geld-Marker"];
GermanQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_NEW] = ["Bewege deinen +1 Geld-Marker auf einen Vorratsstapel, auf dem du keinen Marker hast.", "Platziere deinen +1 Geld-Marker"];
GermanQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_MOVE] = ["Lege deinen +1 Kauf-Marker auf einen Vorratsstapel, auf dem du keinen Marker hast.", "Platziere deinen +1 Kauf-Marker"];
GermanQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_NEW] = ["Bewege deinen +1 Kauf-Marker auf einen Vorratsstapel, auf dem du keinen Marker hast.", "Platziere deinen +1 Kauf-Marker"];
GermanQuestionDescriptions[QuestionIds.TRADE] = ["Entsorge bis zu 2 Handkarten. Nimm dir ein Silber, für jede entsorgte Karte.", "Entsorge bis zu 2 Karten"];
GermanQuestionDescriptions[QuestionIds.TRAINING_NEW] = ["Lege deinen +1 Geld-Marker auf einen Aktionskarten-Vorratsstapel.", "Platziere deinen +1 Geld-Marker"];
GermanQuestionDescriptions[QuestionIds.TRAINING_MOVE] = ["Bewege deinen +1 Geld-Marker auf einen Aktionskarten-Vorratsstapel.", "Platziere deinen +1 Geld-Marker"];
GermanQuestionDescriptions[QuestionIds.TRANSMOGRIFY_TRASH] = ["Entsorge eine Karte aus deiner Hand und nimm dir eine Karte, die bis zu 1$ teurer ist auf deine Hand.", "Entsorge eine Karte"];
GermanQuestionDescriptions[QuestionIds.TRANSMOGRIFY_GAIN] = ["Nimm dir eine Karte, die bis zu AMOUNT0 kostet auf deine Hand.", "Nimm dir eine Karte bis zu AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.APOTHECARY] = ["Lege die Karten in beliebiger Reihenfolge auf deinen Nachziehstapel.", "Lege auf den Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.APPRENTICE] = ["Entsorge eine Karte aus deiner Hand. +1 Karte pro $1, die es kostet. +2 Karten, wenn sie [P] kostet.", "Entsorge, dann ziehe"];
GermanQuestionDescriptions[QuestionIds.GOLEM] = ["Wähle, welche Aktion du zuerst spielen möchtest.", "Reihenfolge wählen"];
GermanQuestionDescriptions[QuestionIds.HERBALIST] = ["Du darfst eine abzulegende Geldkarte auf deinen Nachziehstapel legen.", "Geldkarte auf den Nachziehstapel legen (optional)"];
GermanQuestionDescriptions[QuestionIds.HERBALISTS] = ["Welche Geldkarten auf den Nachziehstapel legen", "Lege bis zu Argument0 Geldkarten auf den Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.SCRYING_POOL_YOU] = ["Behalte CARDS0 oder lege sie ab", "Du: Behalte oder lege CARDS0 ab"];
GermanQuestionDescriptions[QuestionIds.SCRYING_POOL_OTHER] = ["Wähle, ob PLAYER1 CARDS0 behält oder ablegt.", "PLAYER1: CARDS0 behalten oder ablegen."];
GermanQuestionDescriptions[QuestionIds.TRANSMUTE] = ["Entsorge eine Karte aus deiner Hand.", "Entsorge eine Karte"];
GermanQuestionDescriptions[QuestionIds.UNIVERSITY] = ["Du darfst dir eine Aktionskarte nehmen, die bis zu $5 kostet.", "Du darfst eine Aktionskarte nehmen"];
GermanQuestionDescriptions[QuestionIds.FOLLOWERS] = ["Lege alle bis auf 3 Karten ab.", "Lege bis auf 3 ab"];
GermanQuestionDescriptions[QuestionIds.DIADEM] = ["Dorfbewohner einsetzen bevor +$1 pro Aktion?", "Dorfbewohner einsetzen?"];
GermanQuestionDescriptions[QuestionIds.HAMLET] = ["Du darfst für +1 Aktion und/oder +1 Kauf Karten ablegen", "Du darfst Karten ablegen"];
GermanQuestionDescriptions[QuestionIds.HAMLET_ACTION] = ["Lege eine Karte für +1 Aktion.", "Lege für +1 Aktion ab"];
GermanQuestionDescriptions[QuestionIds.HAMLET_BUY] = ["Lege eine Karte für +1 Kauf.", "Lege für +1 Kauf ab"];
GermanQuestionDescriptions[QuestionIds.HAMLET_BOTH] = ["Lege 2 Karten ab.", "Lege 2 Karten ab"];
GermanQuestionDescriptions[QuestionIds.HORN_OF_PLENTY] = ["Nimm dir eine Karte, die bis zu AMOUNT0 kostet. Wenn es eine Punktekarte ist, entsorge diese Karte.", "Nimm dir eine Karte bis AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.HORSE_TRADERS] = ["Lege 2 Karten ab.", "Lege 2 Karten ab"];
GermanQuestionDescriptions[QuestionIds.JESTER] = ["Wähle, wer CARDS0 bekommt: Du oder Spieler PLAYER1.", "Wähle, wer CARDS0 bekommt"];
GermanQuestionDescriptions[QuestionIds.REMAKE_TRASH_FIRST] = ["Führe das zweimal durch: Entsorge eine Handkarte, dann nimm dir eine Karte, die genau $1 mehr kostet.", "Entsorge die erste Karte"];
GermanQuestionDescriptions[QuestionIds.REMAKE_TRASH_SECOND] = ["Entsorge eine weitere Handkarte, dann nimm dir eine Karte, die genau $1 mehr kostet.", "Entsorge die zweite Karte"];
GermanQuestionDescriptions[QuestionIds.REMAKE_GAIN_FIRST] = ["Nimm dir eine Karte, die COST0 kostet", "Nimm dir eine Karte"];
GermanQuestionDescriptions[QuestionIds.REMAKE_GAIN_SECOND] = ["Nimm dir eine Karte, die COST0 kostet", "Nimm dir eine Karte"];
GermanQuestionDescriptions[QuestionIds.TOURNAMENT_GAIN_PRIZE_OR_DUCHY] = ["Nimm dir einen Preis oder ein Herzogtum auf deinen Nachziehstapel.", "Preis oder Herzogtum"];
GermanQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_PRIZE] = ["Du darfst dir einen Preis auf den Nachziehstapel nehmen.", "Du darfst dir einen Preis nehmen"];
GermanQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_DUCHY] = ["Du darfst dir ein Herzogtum auf den Nachziehstapel nehmen.", "Du darfst dir ein Herzogtum nehmen"];
GermanQuestionDescriptions[QuestionIds.TOURNAMENT_REVEAL] = ["Du darfst eine Provinz aus deiner Hand aufdecken.", "Du darfst eine Provinz aufdecken"];
GermanQuestionDescriptions[QuestionIds.TRUSTY_STEED] = ["Wähle, wie du das Streitross spielen möchtest.", "Wähle Streitross-Optionen"];
GermanQuestionDescriptions[QuestionIds.YOUNG_WITCH] = ["Lege 2 Karten ab.", "Lege 2 Karten ab"];
GermanQuestionDescriptions[QuestionIds.YOUNG_WITCH_BANE] = ["Du darfst CARDS0 aus deiner Hand aufdecken, um die Junge Hexe zu blocken.", "Decke CARDS0 auf, um Junge Hexe zu blocken?"];
GermanQuestionDescriptions[QuestionIds.ALTAR_TRASH] = ["Entsorge eine Karte aus deiner Hand.", "Trash a card"];
GermanQuestionDescriptions[QuestionIds.ALTAR_GAIN] = ["Nimm dir eine Karte, die bis zu $5 kostet.", "Nimm dir eine Karte"];
GermanQuestionDescriptions[QuestionIds.ARMORY] = ["Nimm dir eine Karte, die bis zu $4 kostet auf deinen Nachziehstapel.", "Nimm dir eine Karte"];
GermanQuestionDescriptions[QuestionIds.BAND_OF_MISFITS] = ["Spiele eine Aktionskarte, die weniger als dies kostet.", "Spiele eine Aktionskarte, die weniger als dies kostet."];
GermanQuestionDescriptions[QuestionIds.CATACOMBS_MODE] = ["Nimm CARDS0 auf deine Hand; oder lege sie ab und +3 Karten.", "Karten nehmen oder weitere ziehen?"];
GermanQuestionDescriptions[QuestionIds.CATACOMBS_GAIN] = ["Nimm dir eine Karte, die weniger als AMOUNT0 kostet.", "Nimm dir eine Karte billiger als AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.COUNT_PAY] = ["Wähle eines: Lege 2 Handkarten ab; oder lege eine Handkarte auf deinen Nachziehstapel; oder nimm dir ein Kupfer.", "Wähle Graf-Malus"];
GermanQuestionDescriptions[QuestionIds.COUNT_DISCARD] = ["Lege 2 Handkarten ab.", "Lege 2 Handkarten ab"];
GermanQuestionDescriptions[QuestionIds.COUNT_TOPDECK] = ["Lege eine Handkarte auf deinen Nachziehstapel.", "Lege eine Handkarte auf den Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.COUNT_PROFIT] = ["Wähle: +$3; oder entsorge deine Hand; oder nimm dir ein Herzogtum.", "Wähle Graf-Bonus"];
GermanQuestionDescriptions[QuestionIds.COUNTERFEIT] = ["Du darfst eine Geldkarte aus deiner Hand zweimal spielen. Wenn du das machst, entsorge sie.", "Spiele zweimal und entsorge"];
GermanQuestionDescriptions[QuestionIds.CULTIST] = ["Du darfst einen Kultisten aus deiner Hand spielen", "Weiteren Kultisten spielen?"];
GermanQuestionDescriptions[QuestionIds.DAME_ANNA] = ["Du darfst bis zu 2 Handkarten entsorgen", "Entsorge bis zu 2 Handkarten"];
GermanQuestionDescriptions[QuestionIds.DAME_NATALIE] = ["Du darfst dir eine Karte nehmen, die bis zu $3 kostet.", "Nimm dir eine Karte"];
GermanQuestionDescriptions[QuestionIds.DEATH_CART] = ["Du darfst eine Aktionskarte aus deiner Hand oder Leichenkarren aus dem Spiel entsorgen.", "Du darfst Leichenkarren oder eine Aktionskarte entsorgen."];
GermanQuestionDescriptions[QuestionIds.FORAGER] = ["Entsorge eine Handkarte.", "Entsorge eine Karte"];
GermanQuestionDescriptions[QuestionIds.KNIGHTS] = ["Wähle eine Karte zum Entsorgen.", "Wähle eine Karte zum Entsorgen"];
GermanQuestionDescriptions[QuestionIds.KNIGHTS_ALREADY_DIES] = ["Wähle eine Karte zum Entsorgen. (CARDS0 wird bereits entsorgt)", "Wähle eine Karte zum Entsorgen"];
GermanQuestionDescriptions[QuestionIds.KNIGHTS_STILL_MATTERS] = ["Wähle eine Karte zum Entsorgen (CARDS1 wird entsorgt, wenn du CARDS0 entsorgst)", "Wähle eine Karte zum Entsorgen"];
GermanQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_EXPAND] = ["Du darfst eine Aktionskarte aus deiner Hand entsorgen und eine Karte nehmen, die bis zu $3 mehr kostet.", "You may trash a card"];
GermanQuestionDescriptions[QuestionIds.GRAVEROBBER_EXPAND] = ["Entsorge eine Karte aus deiner Hand und nimm dir eine Karte, bis bis zu 3$ mehr kostet.", "Entsorge eine Karte"];
GermanQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_GAIN] = ["Du darfst dir eine Karte vom Müllstapel auf deinen Nachziehstapel nehmen.", "Du darfst eine Karte vom Müll nehmen"];
GermanQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN] = ["Nimm eine Karte vom Müllstapel auf deinen Nachziehstapel.", "Nimm eine Karte vom Müll"];
GermanQuestionDescriptions[QuestionIds.GRAVEROBBER_COMPLEX] = ["Wenn du den Grabräuber spielst", "Entsorge eine Aktionskarte oder nimm eine Karte aus dem Müll"];
GermanQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN_SUPPLY] = ["Nimm dir eine Karte, die bis zu AMOUNT0 kostet.", "Nimm eine Karte bis zu AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.HERMIT_TRASH] = ["Du darfst eine Nicht-Geldkarte aus deiner Hand oder Ablagestapel entsorgen.", "Du darfst entsorgen"];
GermanQuestionDescriptions[QuestionIds.HERMIT_GAIN] = ["Nimm dir eine Karte, die bis zu $3 kostet.", "Nimm eine Karte"];
GermanQuestionDescriptions[QuestionIds.HUNTING_GROUNDS] = ["Nimm dir ein Herzogtum oder 3 Anwesen.", "Herzogtum oder 3 Anwesen?"];
GermanQuestionDescriptions[QuestionIds.IRONMONGER] = ["Du darfst CARDS0 behalten oder ablegen.", "Du darfst CARDS0 ablegen"];
GermanQuestionDescriptions[QuestionIds.JUNK_DEALER] = ["Entsorge eine Karte aus deiner Hand.", "Entsorge eine Handkarte"];
GermanQuestionDescriptions[QuestionIds.MERCENARY_ONE] = ["Du darst eine Karte aus deiner Hand entsorgen (ohne Bonus).", "Du darfst entsorgen"];
GermanQuestionDescriptions[QuestionIds.MERCENARY_MULTIPLE] = ["Du darst zwei Karte aus deiner Hand entsorgen.", "Du darfst 2 Karten entsorgen"];
GermanQuestionDescriptions[QuestionIds.MERCENARY_DISCARD] = ["Lege alle bis auf 3 Karten ab.", "Lege bis auf 3 ab"];
GermanQuestionDescriptions[QuestionIds.MYSTIC] = ["Rate, welche Karte auf deinem Nachziehstapel liegt", "Rate eine Karte"];
GermanQuestionDescriptions[QuestionIds.PILLAGE] = ["Wähle, welche Karte PLAYER0 ablegt.", "Wähle, welche Karte PLAYER0 ablegen muss"];
GermanQuestionDescriptions[QuestionIds.PROCESSION_PLAY] = ["Du darfst eine Karte aus deiner Hand zweimal spielen.", "Du darfst eine Aktionskarte 2 mal spielen"];
GermanQuestionDescriptions[QuestionIds.PROCESSION_GAIN] = ["Nimm dir eine Aktionskarte, die genau AMOUNT0 kostet.", "Nimm eine Aktionskarte für AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.RATS_TRASH_ANY] = ["Entsorge eine Karte aus deiner Hand.", "Entsorge eine Handkarte"];
GermanQuestionDescriptions[QuestionIds.RATS_TRASH_OTHER] = ["Entsorge eine Karte aus deiner Hand außer Ratten.", "Entsorge eine Handkarte"];
GermanQuestionDescriptions[QuestionIds.REBUILD_SKIP] = ["Nenne eine Punktekarte, die nicht ausgelassen wird (oder Kupfer).", "Was soll ausgelassen werden?"];
GermanQuestionDescriptions[QuestionIds.REBUILD_GAIN] = ["Nimm dir eine Punktekarte, die bis zu AMOUNT0 kostet.", "Nimm eine Punktekarte bis zu AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.ROGUE_TRASH] = ["Wähle eine Karte von PLAYER0, die entsorgt werden soll.", "Entsorge von PLAYER0"];
GermanQuestionDescriptions[QuestionIds.ROGUE_GAIN] = ["Nimm dir eine Karte vom Müllstapel, die zwischen $3 und $6 kostet.", "Nimm eine Karte vom Müll"];
GermanQuestionDescriptions[QuestionIds.SCAVENGER] = ["Du darfst deinen Nachziehstapel in den Ablagestapel legen.", "Nachziehstapel ablegen?"];
GermanQuestionDescriptions[QuestionIds.SCAVENGER_TOPDECK] = ["Lege eine Karte von deinem Ablagestapel auf deinen Nachziehstapel.", "Karte auf Nachziehstapel legen"];
GermanQuestionDescriptions[QuestionIds.SIR_MICHAEL] = ["Lege alle bis auf 3 Karten ab.", "Lege bis auf 3 ab"];
GermanQuestionDescriptions[QuestionIds.SQUIRE_MODE] = ["Wähle eines: +2 Aktionen; oder +2 Käufe; oder nimm dir ein Silber.", "Wähle Knappe-Option"];
GermanQuestionDescriptions[QuestionIds.SQUIRE_GAIN] = ["Nimm dir eine Angriffskarte.", "Nimm eine Angriffskarte"];
GermanQuestionDescriptions[QuestionIds.STOREROOM_CARDS] = ["Lege Karten ab aus deiner Hand ab, dann ziehe genauso viele.", "Lege ab, um zu ziehen"];
GermanQuestionDescriptions[QuestionIds.STOREROOM_COINS] = ["Lege Karten ab aus deiner Hand ab für +$1 pro Karte.", "Lege ab für Geld"];
GermanQuestionDescriptions[QuestionIds.SURVIVORS] = ["Lege diese in beliebiger Reihenfolge auf deinen Nachziehstapel oder lege sie ab.", "Lege auf den Nachziehstapel oder lege sie ab"];
GermanQuestionDescriptions[QuestionIds.URCHIN] = ["Lege alle bis auf 4 Karten ab.", "Lege bis auf 4 ab"];
GermanQuestionDescriptions[QuestionIds.WANDERING_MINSTREL] = ["Lege die Aktionskarten in beliebiger Reihenfolge auf deinen Nachziehstapel.", "Lege in beliebiger Reihenfolge auf den Nachziehstapel."];
GermanQuestionDescriptions[QuestionIds.ADVANCE_TRASH] = ["Du darfst eine Aktionskarte aus deiner Hand entsorgen.", "Du darfst eine Aktionskarte entsorgen"];
GermanQuestionDescriptions[QuestionIds.ADVANCE_GAIN] = ["Nimm dir eine Aktionskarte, die bis zu $6 kostet.", "Nimm dir eine Aktionskarte"];
GermanQuestionDescriptions[QuestionIds.ANNEX] = ["Wähle bis zu 5 Karten, die nicht in deinen Nachziehstapel gemischt werden.", "Wähle bis zu 5 Karten"];
GermanQuestionDescriptions[QuestionIds.ARCHIVE] = ["Wähle eine Karte, die du auf die Hand nimmst.", "Wähle eine Karte"];
GermanQuestionDescriptions[QuestionIds.ARENA] = ["Du darfst eine Aktionskarte für NUMBER0 Siegpunkte ablegen.", "Arena: Für NUMBER0 Punkte ablegen?"];
GermanQuestionDescriptions[QuestionIds.BANQUET] = ["Nimm dir eine Nicht-Punktekarte, die bis zu $5 kostet.", "Nimm dir eine Nicht-Punktekarte"];
GermanQuestionDescriptions[QuestionIds.BUSTLING_VILLAGE] = ["Du darfst dir eine Siedler-Karte auf die Hand nehmen.", "Siedler auf die Hand nehmen?"];
GermanQuestionDescriptions[QuestionIds.CATAPULT_TRASH] = ["Entsorge eine Handkarte.", "Entsorge eine Karte"];
GermanQuestionDescriptions[QuestionIds.CATAPULT_DISCARD] = ["Lege alle bis auf 3 Karten ab.", "Lege bis auf 3 ab"];
GermanQuestionDescriptions[QuestionIds.CHARM_MODE] = ["Wähle, wie du den Zauber spielen möchtest", "Wähle Zauber-Option"];
GermanQuestionDescriptions[QuestionIds.CHARM_GAIN] = ["Nimm dir eine Karte außer CARDS0, die genau AMOUNT1 kostet.", "Nimm eine Karte für AMOUNT1 (nicht CARDS0)"];
GermanQuestionDescriptions[QuestionIds.CROWN_ACTION] = ["Du darfst eine Aktionskarte aus deiner Hand zweimal spielen.", "Du darfst eine Aktionskarte 2 mal spielen"];
GermanQuestionDescriptions[QuestionIds.CROWN_TREASURE] = ["Du darfst eine Geldkarte aus deiner Hand zweimal spielen.", "Du darfst eine Geldkarte 2 mal spielen"];
GermanQuestionDescriptions[QuestionIds.DONATE] = ["Entsorge eine beliebige Anzahl Karten.", "Entsorge Karten"];
GermanQuestionDescriptions[QuestionIds.ENCAMPMENT] = ["Du darfst ein Gold oder ein Diebesgut aufdecken.", "Gold oder Diebesgut aufdecken?"];
GermanQuestionDescriptions[QuestionIds.ENGINEER_GAIN] = ["Nimm dir eine Karte, die bis zu $4 kostet.", "Nimm eine Karte"];
GermanQuestionDescriptions[QuestionIds.ENGINEER_TRASH] = ["Du darfst die Ingeneurin entsorgen.", "Ingeneurin entsorgen?"];
GermanQuestionDescriptions[QuestionIds.FORUM] = ["Lege 2 Karten aus deiner Hand ab.", "Lege 2 Handkarten ab"];
GermanQuestionDescriptions[QuestionIds.GLADIATOR_REVEAL] = ["Decke eine Karte aus deiner Hand auf.", "Decke eine Karte auf"];
GermanQuestionDescriptions[QuestionIds.GLADIATOR_PRETEND] = ["Täusche vor, über das Aufdecken nachzudenken.", "Täusche Nachdenken vor"];
GermanQuestionDescriptions[QuestionIds.GLADIATOR_BLOCK] = ["Du darfst CARDS0 aus deiner Hand aufdecken.", "Du darfst CARDS0 aufdecken"];
GermanQuestionDescriptions[QuestionIds.HAUNTED_CASTLE] = ["Lege 2 Karten aus deiner Hand auf deinen Nachziehstapel.", "Lege 2 Karten auf den Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.LEGIONARY_REVEAL] = ["Du darfst ein Gold aus deiner Hand aufdecken.", "Du darfst ein Gold aufdecken"];
GermanQuestionDescriptions[QuestionIds.LEGIONARY_DISCARD] = ["Lege alle bis auf 2 Karten ab.", "Lege bis auf 2 ab"];
GermanQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_NEW] = ["Biete bis zu 40D.", "Biete bis zu 40D"];
GermanQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_OVERBID] = ["Biete bis zu 40D. Momentan hat PLAYER0 das höchste Gebot mit NUMBER1.", "Biete bis zu 40D"];
GermanQuestionDescriptions[QuestionIds.OPULENT_CASTLE] = ["Lege Punktekarten für +$2 pro Karte ab. ", "Lege für +$2/Karte ab"];
GermanQuestionDescriptions[QuestionIds.OVERLORD] = ["Spiele eine Aktionskarte, die bis zu 5 Geld kostet.", "Spiele eine Aktionskarte, die bis zu 5 Geld kostet."];
GermanQuestionDescriptions[QuestionIds.RITUAL] = ["Entsorge eine Handkarte.", "Entsorge eine Karte"];
GermanQuestionDescriptions[QuestionIds.SACRIFICE] = ["Entsorge eine Handkarte.", "Entsorge eine Karte"];
GermanQuestionDescriptions[QuestionIds.SALT_THE_EARTH] = ["Entsorge eine Punktekarte aus dem Vorrat.", "Entsorge aus dem Vorrat"];
GermanQuestionDescriptions[QuestionIds.SETTLERS] = ["Du darfst dir ein Kupfer aus deinem Ablagestapel auf die Hand nehmen.", "Kupfer auf die Hand nehmen?"];
GermanQuestionDescriptions[QuestionIds.SMALL_CASTLE_IN_PLAY] = ["Entsorge das kleine Schloss oder ein Schloss aus deiner Hand.", "Entsorge ein Schloss"];
GermanQuestionDescriptions[QuestionIds.SMALL_CASTLE_GONE] = ["Entsorge ein Schloss aus deiner Hand.", "Entsorge ein Schloss"];
GermanQuestionDescriptions[QuestionIds.SMALL_CASTLE_GAIN] = ["Nimm dir ein Schloss.", "Nimm ein Schloss"];
GermanQuestionDescriptions[QuestionIds.SPRAWLING_CASTLE] = ["Nimm dir ein Herzogtum oder 3 Anwesen.", "Herzogtum oder 3 Anwesen?"];
GermanQuestionDescriptions[QuestionIds.TAX] = ["Wähle einen Vorratsstapel, auf den du (2D) legst.", "Lege (2D) auf einen Vorratsstapel"];
GermanQuestionDescriptions[QuestionIds.TEMPLE] = ["Entsorge 1 - 3 verschieden benamte Karten aus deiner Hand.", "Entsorge 1 - 3 Karten"];
GermanQuestionDescriptions[QuestionIds.WILD_HUNT] = ["Wähle zwischen +3 Karten oder nimm dir ein Anwesen und die Siegpunkte vom Wilde Jagd Vorrats-Stapel", "Karten oder Punkte?"];
GermanQuestionDescriptions[QuestionIds.ADVISOR] = ["Wähle, welche Karte PLAYER0 nicht bekommt.", "Lege für PLAYER0 ab"];
GermanQuestionDescriptions[QuestionIds.BUTCHER_TRASH] = ["Du darfst eine Karte aus deiner Hand entsorgen.", "Du darfst entsorgen"];
GermanQuestionDescriptions[QuestionIds.BUTCHER_GAIN] = ["Nimm dir eine Karte, die bis zu AMOUNT0 kostet.", "Nimm eine Karte bis zu AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.DOCTOR_PLAY] = ["Nenne eine Karte, die du entsorgen möchtest.", "Nenne eine Karte"];
GermanQuestionDescriptions[QuestionIds.DOCTOR_TOPDECK] = ["Lege diese Karten in beliebiger auf deinen Nachziehstapel.", "Lege auf den Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.DOCTOR_OVERPAY] = ["Du darfst für den Arzt mehr zahlen.", "Mehr zahlen, um Karten entsorgen/ablgen zu können?"];
GermanQuestionDescriptions[QuestionIds.DOCTOR_MODE] = ["Was möchtest du mit CARDS0 machen?", "Was möchtest du mit CARDS0 machen?"];
GermanQuestionDescriptions[QuestionIds.DOCTOR_MODE_MANY] = ["Was möchtest du mit CARDS0 machen? (NUMBER1 mehr)", "CARDS0? (NUMBER1 mehr)"];
GermanQuestionDescriptions[QuestionIds.HERALD_OVERPAY] = ["Du darfst für den Herold mehr zahlen.", "Mehr zahlen, um Karten auf den Nachziehstapel zu legen?"];
GermanQuestionDescriptions[QuestionIds.HERALD_CHOOSE] = ["Lege eine Karte aus dem Ablagestapel auf den Nachziehstapel.", "Lege auf den Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.JOURNEYMAN] = ["Nenne eine Karte, die du nicht ziehen möchtest.", "Nenne eine Karte"];
GermanQuestionDescriptions[QuestionIds.MASTERPIECE] = ["Du darfst für das Meisterstück mehr zahlen.", "Mehr zahlen, um Silber zu nehmen?"];
GermanQuestionDescriptions[QuestionIds.PLAZA_DELAY] = ["Du darfst eine Geldkarte für einen Taler ablegen.", "Geldkarte ablegen?"];
GermanQuestionDescriptions[QuestionIds.PLAZA_DISCARD] = ["Lege eine Geldkarte ab.", "Geldkarte ablegen"];
GermanQuestionDescriptions[QuestionIds.STONEMASON_TRASH] = ["Entsorge eine Karte aus deiner Hand.", "Handkarte entsorgen"];
GermanQuestionDescriptions[QuestionIds.STONEMASON_GAIN1] = ["Nimm dir zwei Karten, die weniger als AMOUNT0 kosten.", "Nimm erste Karte billiger als AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.STONEMASON_GAIN2] = ["Nimm dir eine weitere Karte, die weniger als AMOUNT0 kostet.", "Nimm zweite Karte billiger als AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.STONEMASON_OVERPAY] = ["Du darfst für den Steinmetz mehr zahlen, indem du eine Aktionskarte nimmst, die bis zu AMOUNT0 kostet.", "Gib den Betrag des Überzahlens an, oder wähle eine Karte direkt."];
GermanQuestionDescriptions[QuestionIds.STONEMASON_GAIN_EQUAL] = ["Nimm dir eine weitere Aktionskarte, die genau AMOUNT0 kostet.", "Nimm eine weitere Aktionskarte für AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.TAXMAN_TRASH] = ["Du darfst eine Geldkarte aus deiner Hand entsorgen.", "Geldkarte entsorgen?"];
GermanQuestionDescriptions[QuestionIds.TAXMAN_GAIN] = ["Nimm dir eine Geldkarte, die bis zu AMOUNT0 kostet, auf deinen Nachziehstapel.", "Nimm eine Geldkarte bis zu AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.BORDER_VILLAGE] = ["Nimm eine Karte, die weniger als AMOUNT0 kostet.", "Nimm eine Karte, billiger als AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.CARTOGRAPHER_DISCARD] = ["Lege eine beliebige Anzahl Karten ab.", "Lege Karten ab"];
GermanQuestionDescriptions[QuestionIds.CARTOGRAPHER_TOPDECK] = ["Lege den Rest in beliebiger Reihenfolge auf deinen Nachziehstapel.", "Lege in beliebiger Reihenfolge zurück"];
GermanQuestionDescriptions[QuestionIds.DEVELOP_TRASH] = ["Entsorge eine Karte aus deiner Hand.", "Entsorge eine Handkarte"];
GermanQuestionDescriptions[QuestionIds.DEVELOP_GAIN1] = ["Nimm dir eine Karte auf deinen Nachziehstapel, die AMOUNT0 oder Argument1 kostet.", "Nimm erste Karte für AMOUNT0 oder Argument1"];
GermanQuestionDescriptions[QuestionIds.DEVELOP_GAIN2] = ["Nimm dir eine Karte auf deinen Nachziehstapel, die AMOUNT0 kostet.", "Nimm zweite Karte für AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.DUCHESS] = ["Du darfst CARDS0 ablegen.", "Du darfst CARDS0 ablegen"];
GermanQuestionDescriptions[QuestionIds.EMBASSY] = ["Lege 3 Karten aus deiner Hand ab.", "Lege 3 Handkarten ab"];
GermanQuestionDescriptions[QuestionIds.FARMLAND_TRASH] = ["Entsorge eine Karte aus deiner Hand.", "Entsorge eine Handkarte"];
GermanQuestionDescriptions[QuestionIds.FARMLAND_GAIN] = ["Nimm dir eine Karte, die genau AMOUNT0 kostet.", "Nimm eine Karte für AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.HAGGLER] = ["Nimm dir eine Nicht-Punktekarte, die weniger als AMOUNT0 kostet.", "Nimm eine Nicht-Punktekarte, billiger als AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.ILL_GOTTEN_GAINS] = ["Du darfst dir ein Kupfer auf die Hand nehmen.", "Du darfst Kupfer nehmen"];
GermanQuestionDescriptions[QuestionIds.INN_DISCARD] = ["Lege 2 Karten aus deiner Hand ab.", "Lege 2 Handkarten ab"];
GermanQuestionDescriptions[QuestionIds.INN_SHUFFLE] = ["Mische eine beliebige Anzahl Aktionskarte aus deinem Ablagestapel in deinen Nachziehstapel.", "Mische in Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.JACK_DISCARD] = ["Du darfst CARDS0 ablegen.", "Du darfst CARDS0 ablegen"];
GermanQuestionDescriptions[QuestionIds.JACK_TRASH] = ["Du darfst eine Nicht-Geldkarte aus deiner Hand entsorgen.", "Nicht-Geldkarte entsorgen?"];
GermanQuestionDescriptions[QuestionIds.MANDARIN_PLAY] = ["Lege eine Karte aus deiner Hand auf deinen Nachziehstapel.", "Lege auf den Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.MANDARIN_GAIN] = ["Lege alle Geldkarten, die du im Spiel hast, in beliebiger Reihenfolge auf deinen Nachziehstapel.", "Lege Geldkarten auf den Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.MARGRAVE] = ["Lege alle bis auf 3 Karten ab.", "Lege bis auf 3 ab"];
GermanQuestionDescriptions[QuestionIds.NOBLE_BRIGAND] = ["Wähle eine Geldkarte, die entsorgt werden soll.", "Wähle Geldkarte"];
GermanQuestionDescriptions[QuestionIds.OASIS] = ["Lege eine Karte aus deiner Hand ab.", "Lege eine Handkarte ab"];
GermanQuestionDescriptions[QuestionIds.ORACLE_DISCARD] = ["Du darfst CARDS0 von PLAYER1 ablegen.", "Soll PLAYER1 ablegen?"];
GermanQuestionDescriptions[QuestionIds.ORACLE_TOPDECK] = ["Lege diese Karten in beliebiger Reihenfolge auf deinen Nachziehstapel.", "Lege in beliebiger Reihenfolge auf den Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.SCHEME] = ["Du darfst mit Komplott eine Aktionskarte auf deinen Nachziehstapel legen", "Karte auf Nachziehstapel legen?"];
GermanQuestionDescriptions[QuestionIds.SCHEMES] = ["Du darfst mit Komplott Aktionskarten auf deinen Nachziehstapel legen", "Bis zu Argument0 Karten auf Nachziehstapel legen"];
GermanQuestionDescriptions[QuestionIds.SPICE_MERCHANT_TRASH] = ["Du darfst eine Geldkarte von deiner Hand entsorgen.", "Geldkarte entsorgen?"];
GermanQuestionDescriptions[QuestionIds.SPICE_MERCHANT_MODE] = ["Wähle eines: +2 Karten und +1 Aktion; oder +1 Kauf und +$2.", "Wähle Gewürzhändler-Option"];
GermanQuestionDescriptions[QuestionIds.STABLES] = ["Du darfst eine Geldkarte ablegen.", "Du darfst eine Geldkarte ablegen"];
GermanQuestionDescriptions[QuestionIds.TRADER_TRASH] = ["Entsorge eine Karte aus deiner Hand.", "Entsorge eine Handkarte"];
GermanQuestionDescriptions[QuestionIds.BARON] = ["Du darst ein Anwesen aus deiner Hand ablegen.", "Du darfst ein Anwesen ablegen"];
GermanQuestionDescriptions[QuestionIds.COURTIER_REVEAL] = ["Decke eine Karte aus deiner Hand auf.", "Decke eine Handkarte auf"];
GermanQuestionDescriptions[QuestionIds.COURTIER_MODE] = ["Wähle NUMBER0: +1 Aktion; oder +1 Kauf; oder +$3; oder nimm dir ein Gold. Du darfst jeden Effekt nur einmal auswählen.", "Wähle NUMBER0 Option(en)"];
GermanQuestionDescriptions[QuestionIds.COURTYARD] = ["Lege eine Karte aus deiner Hand auf deinen Nachziehstapel.", "Lege auf den Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.DIPLOMAT] = ["Lege 3 Karten aus deiner Hand ab.", "Lege 3 Handkarten ab"];
GermanQuestionDescriptions[QuestionIds.IRONWORKS] = ["Nimm dir eine Karte, die bis zu $4 kostet.", "Nimm eine Karte"];
GermanQuestionDescriptions[QuestionIds.LURKER_MAY_TRASH] = ["Du darfst eine Aktionskarte aus dem Vorrat entsorgen.", "Aktionskarte aus Vorrat entsorgen?"];
GermanQuestionDescriptions[QuestionIds.LURKER_TRASH] = ["Entsorge eine Aktionskarte aus dem Vorrat", "Aktionskarte aus Vorrat entsorgen"];
GermanQuestionDescriptions[QuestionIds.LURKER_MAY_GAIN] = ["Du darfst dir eine Aktionskarte vom Müllstapel nehmen.", "Vom Müllstapel nehmen"];
GermanQuestionDescriptions[QuestionIds.LURKER_GAIN] = ["Nimm dir eine Aktionskarte vom Müllstapel.", "Vom Müllstapel nehmen"];
GermanQuestionDescriptions[QuestionIds.MASQUERADE_PASS] = ["Gib PLAYER0 eine deiner Handkarten.", "Gib PLAYER0 eine Handkarte"];
GermanQuestionDescriptions[QuestionIds.MASQUERADE_TRASH] = ["Du darfst eine Karte aus deiner Hand entsorgen.", "Du darfst eine Handkarte entsorgen"];
GermanQuestionDescriptions[QuestionIds.MILL] = ["Du darfst 2 Karten aus deiner Hand ablegen", "Du darfst 2 Handkarten ablegen"];
GermanQuestionDescriptions[QuestionIds.MINING_VILLAGE] = ["Du darfst das Bergwerk entsorgen.", "Du darfst das Bergwerk entsorgen"];
GermanQuestionDescriptions[QuestionIds.MINION] = ["Wähle eines: +$2 oder neue Handkarten.", "Wähle +$2 oder neue Handkarten"];
GermanQuestionDescriptions[QuestionIds.NOBLES] = ["Wähle eines +3 Karten; oder +2 Aktionen.", "Wähle +3 Karten oder +2 Aktionen"];
GermanQuestionDescriptions[QuestionIds.PATROL] = ["Lege diese Karten in beliebiger Reihenfolge auf deinen Nachziehstapel.", "Lege in beliebiger Reihenfolge zurück"];
GermanQuestionDescriptions[QuestionIds.PAWN] = ["Wähle, wie du den Handlanger spielen möchtest", "Wähle Handlanger-Optionen"];
GermanQuestionDescriptions[QuestionIds.REPLACE_TRASH] = ["Entsorge eine Karte aus deiner Hand.", "Entsorge eine Handkarte"];
GermanQuestionDescriptions[QuestionIds.REPLACE_GAIN] = ["Nimm dir eine Karte, die bis zu AMOUNT0 kostet.", "Nimm eine Karte bis zu AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.SECRET_PASSAGE_TOPDECK] = ["Lege eine Karte auf deinen leeren Nachziehstapel.", "Lege auf den Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.SECRET_PASSAGE_INSERT] = ["Wähle eine Karte, die in deinen Nachziehstapel gelegt werden soll.", "Wähle eine Position, danach die Karte"];
GermanQuestionDescriptions[QuestionIds.STEWARD_MODE] = ["Wähle eines: +2 Karten; oder +$2; oder entsorge 2 Karten aus deiner Hand.", "Wähle Verwslter-Option"];
GermanQuestionDescriptions[QuestionIds.STEWARD_TRASH] = ["Entsorge 2 Karten aus deiner Hand.", "Entsorge 2 Handkarten"];
GermanQuestionDescriptions[QuestionIds.SWINDLER] = ["Wähle, welche Karte sich PLAYER0 nimmt.", "Karte für PLAYER0 wählen"];
GermanQuestionDescriptions[QuestionIds.TRADING_POST] = ["Entsorge 2 Karten aus deiner Hand.", "Entsorge 2 Handkarten"];
GermanQuestionDescriptions[QuestionIds.UPGRADE_TRASH] = ["Entsorge eine Karte aus deiner Hand.", "Entsorge eine Handkarte"];
GermanQuestionDescriptions[QuestionIds.UPGRADE_GAIN] = ["Nimm dir eine Karte, die genau AMOUNT0 kostet.", "Nimm eine Karte für AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.WISHING_WELL] = ["Rate, welche Karte auf deinem Nachziehstapel liegt", "Rate eine Karte"];
GermanQuestionDescriptions[QuestionIds.SUMMON] = ["Nimm dir eine Aktionskarte, die bis zu $4 kostet.", "Lade eine Aktionskarte ein"];
GermanQuestionDescriptions[QuestionIds.GOVERNOR_MODE] = ["Wähle, wie du den Gouverneur spielen möchtest", "Wähle Governeur-Option"];
GermanQuestionDescriptions[QuestionIds.GOVERNOR_TRASH] = ["Entsorge eine Karte aus deiner Hand.", "Entsorge eine Handkarte"];
GermanQuestionDescriptions[QuestionIds.GOVERNOR_MAY_TRASH] = ["Du darfst eine Karte aus deiner Hand entsorgen.", "Du darfst entsorgen"];
GermanQuestionDescriptions[QuestionIds.GOVERNOR_GAIN] = ["Nimm dir eine Karte, die genau Argument0 kostet.", "Nimm eine Karte"];
GermanQuestionDescriptions[QuestionIds.SAUNA_CHAIN] = ["Du darfst ein Eisloch spielen, ohne, dass es eine Aktion verbraucht", "Du darfst ein Eisloch spielen"];
GermanQuestionDescriptions[QuestionIds.SAUNA_MAY_TRASH] = ["Du darfst mit der Sauna eine Karte entsorgen", "Du darfst entsorgen"];
GermanQuestionDescriptions[QuestionIds.AVANTO_CHAIN] = ["Du darfst eine Sauna spielen, ohne, dass sie eine Aktion verbraucht", "Du darfst eine Sauna spielen"];

GermanQuestionDescriptions[QuestionIds.BISHOP_SELF] = ["Entsorge eine Karte aus deiner Hand.", "Entsorge eine Handkarte"];
GermanQuestionDescriptions[QuestionIds.BISHOP_OTHER] = ["Du darfst eine Karte aus deiner Hand entsorgen.", "Du darfst entsorgen"];
GermanQuestionDescriptions[QuestionIds.CONTRABAND] = ["Nenne eine Karte, die PLAYER0 in diesem Zug nicht kaufen kann.", "Verbiete eine Karte"];
GermanQuestionDescriptions[QuestionIds.COUNTING_HOUSE] = ["Wie viele Kupfer möchtest du auf die Hand nehmen?", "Nimm Kupfer auf die Hand"];
GermanQuestionDescriptions[QuestionIds.EXPAND_TRASH] = ["Entsorge eine Karte aus deiner Hand.", "Entsorge eine Handkarte"];
GermanQuestionDescriptions[QuestionIds.EXPAND_GAIN] = ["Nimm dir eine Karte, die bis zu AMOUNT0 kostet.", "Nimm eine Karte bis zu AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.FORGE_TRASH] = ["Entsorge eine beliebige Anzahl Karten aus deiner Hand.", "Entsorge Handkarten"];
GermanQuestionDescriptions[QuestionIds.FORGE_GAIN] = ["Nimm dir eine Karte, die genau AMOUNT0 kostet.", "Nimm eine Karte für AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.GOONS] = ["Lege alle bis auf 3 Karten ab.", "Lege bis auf 3 ab"];
GermanQuestionDescriptions[QuestionIds.KINGS_COURT] = ["Du darfst eine Aktionskarte aus deiner Hand drei mal spielen.", "Du darfst eine Aktionskarte 3 mal spielen"];
GermanQuestionDescriptions[QuestionIds.LOAN] = ["Lege CARDS0 ab oder entsorge es.", "Lege CARDS0 ab oder entsorge"];
GermanQuestionDescriptions[QuestionIds.MINT] = ["Du darfst eine Geldkarte aus deiner Hand aufdecken. Nimm dir ein weiteres Exemplar", "Nimm eine Geldkarte"];
GermanQuestionDescriptions[QuestionIds.MOUNTEBANK] = ["Du darfst einen Fluch aus deiner Hand ablegen.", "Fluch ablegen?"];
GermanQuestionDescriptions[QuestionIds.RABBLE] = ["Lege diese Karten in beliebiger Reihenfolge auf deinen Nachziehstapel zurück.", "Lege auf den Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.TRADE_ROUTE] = ["Entsorge eine Karte aus deiner Hand.", "Entsorge eine Handkarte"];
GermanQuestionDescriptions[QuestionIds.VAULT_SELF] = ["Lege eine beliebige Anzahl Karten aus deiner Hand ab.", "Lege Handkarten ab"];
GermanQuestionDescriptions[QuestionIds.VAULT_OTHER] = ["Du darfst 2 Karten aus deiner Hand ablegen.", "Du darfst 2 Handkarten ablegen"];
GermanQuestionDescriptions[QuestionIds.AMBASSADOR_REVEAL] = ["Decke eine Karte aus deiner Hand auf.", "Decke Handkarte auf"];
GermanQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_ONE] = ["Du darfst CARDS0 in den Vorrat zurücklegen.", "Du darfst CARDS0 zurücklegen"];
GermanQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_TWO] = ["Lege 0, 1 oder CARDS0 in den Vorrat zurücklegen.", "Lege 0, 1 or 2 zurück"];
GermanQuestionDescriptions[QuestionIds.EMBARGO] = ["Lege einen Embargo-Marker auf einen Vorratsstapel.", "Embargomarker auf Stapel legen"];
GermanQuestionDescriptions[QuestionIds.EXPLORER] = ["Du darfst eine Provinz aus deiner Hand aufdecken.", "Du darfst eine Provinz aufdecken"];
GermanQuestionDescriptions[QuestionIds.GHOST_SHIP] = ["Lege NUMBER0 Karten auf deinen Nachziehstapel zurück.", "Lege NUMBER0 Karten auf den Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.HAVEN] = ["Lege eine Karte beiseite.", "Lege eine Karte beiseite"];
GermanQuestionDescriptions[QuestionIds.ISLAND] = ["Lege eine Karte auf dein Insel-Tableau.", "Lege aufs Insel-Tableau"];
GermanQuestionDescriptions[QuestionIds.LOOKOUT_TRASH] = ["Entsorge eine Karte.", "Entsorge eine Karte"];
GermanQuestionDescriptions[QuestionIds.LOOKOUT_DISCARD] = ["Lege eine Karte ab.", "Lege eine Karte ab"];
GermanQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_TAKE] = ["Du darfst CARDS0 auf die Hand nehmen.", "Argument0 auf die Hand nehmen?"];
GermanQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_SET_ASIDE] = ["Du darfst die oberste Karte deines Nachziehstapels auf dein Eingeborenendorf-Tableau legen.", "Auf das Tableau legen?"];
GermanQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_BOTH] = ["Lege eine Karte aufs Eingeborenendorf-Tableau oder nimm CARDS0 auf deine Hand.", "Argument0 auf die Hand nehmen?"];
GermanQuestionDescriptions[QuestionIds.NAVIGATOR] = ["Lege diese 5 Karten in beliebiger Reihenfolge auf deinen Nachziehstapel oder lege alle ab.", "Auf den Nachziehstapel legen oder ablegen?"];
GermanQuestionDescriptions[QuestionIds.PEARL_DIVER] = ["Lege CARDS0 oben oder unten auf deinen Nachziehstapel.", "CARDS0 nach oben oder unten legen"];
GermanQuestionDescriptions[QuestionIds.PIRATE_SHIP_MODE] = ["Wähle eines: +AMOUNT0 oder angreifen.", "+AMOUNT0 oder angreifen?"];
GermanQuestionDescriptions[QuestionIds.PIRATE_SHIP_TRASH] = ["Wähle eine Geldkarte, die entsorgt werden soll.", "Wähle Geldkarte"];
GermanQuestionDescriptions[QuestionIds.SALVAGER] = ["Entsorge eine Karte aus deiner Hand.", "Entsorge eine Handkarte"];
GermanQuestionDescriptions[QuestionIds.SMUGGLERS_MAY_GAIN] = ["Du darfst dir ein Exemplar dieser Karten nehmen.", "Du darfst eine Karte nehmen"];
GermanQuestionDescriptions[QuestionIds.SMUGGLERS_GAIN] = ["Nimm ein Exemplar dieser Karten.", "Nimm eine Karte"];
GermanQuestionDescriptions[QuestionIds.WAREHOUSE] = ["Lege 3 Karten aus deiner Hand ab.", "Lege 3 Handkarten ab."];
GermanQuestionDescriptions[QuestionIds.LURKER_COMPLEX] = ["Nimm vom Müll oder entsorge vom Vorrat", "Nimm vom Müll oder entsorge vom Vorrat"];
GermanQuestionDescriptions[QuestionIds.BLACK_MARKET_COMPLEX] = ["Du darfst den Schwarzmarkt spielen", "Du darfst Schwarzmarkt spielen"];
GermanQuestionDescriptions[QuestionIds.BLACK_MARKET_BUY] = ["kaufe eine der aufgedeckten Karten.", "Du darfst kaufen"];
GermanQuestionDescriptions[QuestionIds.BLACK_MARKET_PLAY] = ["spiele Geldkarten aus deiner Hand.", "Du darfst Geldkarten spielen"];
GermanQuestionDescriptions[QuestionIds.ENVOY] = ["Wähle eine Karte, die abgelegt werden soll.", "Lege Karte ab"];
//GermanQuestionDescriptions[QuestionIds.PRINCE_MODE] = ["PRINCE_MODE", "Prinz beiseite legen?"];
GermanQuestionDescriptions[QuestionIds.PRINCE_MAY_SET_ASIDE] = ["Du darfst eine Karte beiseite legen mit ", "Du darfst eine Karte beisite legen"];
GermanQuestionDescriptions[QuestionIds.TORTURER_MAY_CURSE] = ["Du darfst dir einen Fluch nehmen.", "Du darfst einen Fluch nehmen"];
GermanQuestionDescriptions[QuestionIds.TORTURER_MAY_DISCARD] = ["Du darfst 2 Karten aus deiner Hand ablegen.", "Du darfst 2 Handkarten ablegen"];
GermanQuestionDescriptions[QuestionIds.TORTURER_DISCARD_OR_CURSE] = ["Nimm dir einen Fluch oder lege 2 Karten aus deiner Hand ab.", "Nimm einen Fluch oder lege 2 Handkarten ab"];
//GermanQuestionDescriptions[QuestionIds.WHAT_TO_IMPERSONATE] = ["Welche Karte soll immitiert werden.", "Wähle Karte zum Immitieren"];
GermanQuestionDescriptions[QuestionIds.INHERITANCE] = ["Wähle eine Karte als Erbschaft.", "Wähle als Erbschaft"];

GermanQuestionDescriptions[QuestionIds.SHORT_YOU_MAY_REACT] = ["-", "Du darfst reagieren"];
GermanQuestionDescriptions[QuestionIds.SHORT_WHEN_GAIN] = ["-", "Du nimmst Argument0..."];
GermanQuestionDescriptions[QuestionIds.SHORT_WHEN_WOULD_GAIN] = ["-", "Du würdest Argument0 nehmen..."];

GermanQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL_SPECIFIC] = ["-", "Spiele oder rufe Argument0 auf..."];
GermanQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL] = ["-", "Spiele oder rufe Aktionskarten auf"];
GermanQuestionDescriptions[QuestionIds.SHORT_PLAY] = ["-", "Du darfst Aktionskarten spielen"];
GermanQuestionDescriptions[QuestionIds.SHORT_CALL_SPECIFIC] = ["-", "Du darfst Argument0 aufrufen"];
GermanQuestionDescriptions[QuestionIds.SHORT_CALL] = ["-", "Du darfst Karten aufrufen"];

GermanQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_DISCARD] = ["Du darfst eine Geldkarte ablegen.", "Du darfst ablegen"];
GermanQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_GAIN] = ["Nimm dir eine Karte, die bis zu $4 kostet.", "Nimm dir eine Karte"];
GermanQuestionDescriptions[QuestionIds.THE_FLAMES_GIFT] = ["Du darfst eine Handkarte entsorgen.", "Du darfst entsorgen"];
GermanQuestionDescriptions[QuestionIds.THE_MOONS_GIFT] = ["Du darfst eine Karte auf deinen Nachziehstapel legen.", "Du darfst eine Karte auf deinen Nachziehstapel legen"];
GermanQuestionDescriptions[QuestionIds.THE_SKYS_GIFT] = ["Du darfst 3 Karten ablegen, um dir ein Gold zu nehmen.", "Du darfst ablegen"];
GermanQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_TOPDECK] = ["lege den Rest in beliebiger Reihenfolge zurück", "lege den Rest zurück"];
GermanQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_DISCARD] = ["Lege eine beliebige Anzahl ab.", "Lege ab"];
GermanQuestionDescriptions[QuestionIds.THE_WINDS_GIFT] = ["Lege zwei Karten ab.", "Lege 2 Karten ab"];
GermanQuestionDescriptions[QuestionIds.FEAR_DISCARD] = ["Lege eine Aktions- oder Geldkarte ab.", "Lege ab"];
GermanQuestionDescriptions[QuestionIds.HAUNTING] = ["Lege etwas auf den Nachziehstapel.", "Lege auf den Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.LOCUSTS] = ["Nimm dir eine günstigere Karte, die einen gemeinsamen Typ hat.", "Nimm eine günstigere Karte"];
GermanQuestionDescriptions[QuestionIds.POVERTY] = ["Lege alle bis auf 3 Karten ab.", "Lege bis auf 3 ab"];
GermanQuestionDescriptions[QuestionIds.BAT] = ["Du darfst bis zu 2 Karten entsorgen.", "Du darfst entsorgen"];
GermanQuestionDescriptions[QuestionIds.BLESSED_VILLAGE_MODE] = ["Wann möchtest du Argument0 empfangen?", "Wann empfangen?"];
GermanQuestionDescriptions[QuestionIds.CEMETERY] = ["Du darfst bis zu 4 Karten entsorgen.", "Du darfst entsorgen"];
GermanQuestionDescriptions[QuestionIds.CHANGELING_GAIN_COPY] = ["Nimm dir ein weiteres Exemplar einer Karte, die du im Spiel hast.", "Nimm eine Karte"];
GermanQuestionDescriptions[QuestionIds.CHANGELING_REPLACE] = ["", ""];
GermanQuestionDescriptions[QuestionIds.COBBLER] = ["Nimm dir eine Karte, die bis zu $4 kostet, auf die Hand.", "Nimm auf die Hand"];
GermanQuestionDescriptions[QuestionIds.CONCLAVE] = ["Du darfst eine Aktionskarte spielen, von der du kein Exemplar im Spiel hast.", "Du darfst eine Aktionskarte spielen"];
GermanQuestionDescriptions[QuestionIds.CRYPT_SET_ASIDE] = ["Lege eine beliebige Anzahl Geldkarten, die du im Spiel hast, beiseite.", "Lege beiseite"];
GermanQuestionDescriptions[QuestionIds.CRYPT_RETURN_TO_HAND] = ["Wähle eine Karte, um sie auf die Hand zu nehmen.", "Wähle eine Karte"];
GermanQuestionDescriptions[QuestionIds.DEVILS_WORKSHOP] = ["Nimm dir eine Karte, die bis zu $4 kostet.", "Nimm eine Karte"];
GermanQuestionDescriptions[QuestionIds.DRUID] = ["Welche Gabe möchtest du empfangen?", "Welche Gabe?"];
GermanQuestionDescriptions[QuestionIds.EXORCIST_TRASH] = ["Entsorge eine Handkarte.", "Entsorge eine Karte"];
GermanQuestionDescriptions[QuestionIds.EXORCIST_GAIN] = ["Nimm eine günstigere Erscheinung.", "Nimm eine günstigere Erscheinung."];
GermanQuestionDescriptions[QuestionIds.FOOL] = ["Welche Gabe möchtest du als nächstes?", "Welche Gabe als nächstes?"];
GermanQuestionDescriptions[QuestionIds.GOAT] = ["Du darfst eine Karte entsorgen.", "Du darfst entsorgen"];
GermanQuestionDescriptions[QuestionIds.HAUNTED_MIRROR] = ["Du darfst eine Aktionskarte ablegen, um einen Geist zu nehmen.", "Du darfst ablegen"];
GermanQuestionDescriptions[QuestionIds.IMP] = ["Du darfst eine Aktionskarte spielen, von der du kein Exemplar im Spiel hast.", "Du darfst eine Aktionskarte spielen"];
GermanQuestionDescriptions[QuestionIds.NECROMANCER] = ["Spiele eine Karte vom Müllstapel.", "Spiele vom Müll"];
GermanQuestionDescriptions[QuestionIds.MONASTERY] = ["Du darfst bis zu AMOUNT0 Handkarten oder Kupfer im Spiel entsorgen.", "Du darfst AMOUNT0 entsorgen"];
GermanQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_TOPDECK] = ["Lege den Rest zurück auf den Nachziehstapel.", "Lege den Rest zurück"];
GermanQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_DISCARD] = ["Lege eine beliebige Anzahl Karten ab.", "Lege ab"];
GermanQuestionDescriptions[QuestionIds.PIXIE_MODE] = ["Möchtest du die Fee entsorgen, um die Gabe zweimal zu empfangen?", "Fee?"];
GermanQuestionDescriptions[QuestionIds.POOKA] = ["Du darfst eine Geldkarte außer Verfluchtes Gold entsorgen.", "Du darfst entsorgen"];
GermanQuestionDescriptions[QuestionIds.RAIDER] = ["Lege eine Handkarte ab", "Lege eine Karte ab"];
GermanQuestionDescriptions[QuestionIds.SACRED_GROVE_MODE] = ["Möchtest du Argument0 ebenfalls empfangen?", "Argument0 empfangen?"];
GermanQuestionDescriptions[QuestionIds.SECRET_CAVE] = ["Du darfst 3 Karten für +$3 im nächsten Zug ablegen.", "Du darfst ablegen"];
GermanQuestionDescriptions[QuestionIds.SHEPHERD] = ["Du darfst Punktekarten für je +2 Karten ablegen", "Du darfst ablegen"];
GermanQuestionDescriptions[QuestionIds.TRAGIC_HERO] = ["Nimm dir eine Geldkarte", "Nimm eine Geldkarte"];
GermanQuestionDescriptions[QuestionIds.VAMPIRE] = ["Nimm dir eine Karte außer einer Vampirin, die bis zu $5 kostet", "Nimm eine Karte"];
GermanQuestionDescriptions[QuestionIds.WISH] = ["Nimm dir eine Karte auf die Hand", "Nimm eine Karte"];
GermanQuestionDescriptions[QuestionIds.ZOMBIE_APPRENTICE] = ["Du darfst eine Aktionskarte entsorgen", "Du darfst entsorgen"];
GermanQuestionDescriptions[QuestionIds.ZOMBIE_MASON] = ["Du darfst dir eine Karte nehmen", "Du darfst nehmen"];
GermanQuestionDescriptions[QuestionIds.ZOMBIE_SPY] = ["Du darfst die Karte ablegen.", "Du darfst ablegen"];
GermanQuestionDescriptions[QuestionIds.WHAT_NIGHT_TO_PLAY] = ["Du darfst eine Nacht-Karte spielen", "Du darfst Nacht-Karten spielen"];
GermanQuestionDescriptions[QuestionIds.LOST_IN_THE_WOODS] = ["Du darfst eine Karte ablegen, um eine Gabe zu empfangen", "Für Gabe ablegen?"];
GermanQuestionDescriptions[QuestionIds.START_GAME] = ["Prüfe deine Anfangskarten", "Deine Anfangskarten:"];
GermanQuestionDescriptions[QuestionIds.DISMANTLE_TRASH] = ["Entsorge eine Karte aus deiner Hand.", "Entsorge eine Karte"];
GermanQuestionDescriptions[QuestionIds.DISMANTLE_GAIN] = ["Nimm eine Karte, die weniger als AMOUNT0 kostet. ", "Nimm eine Karte billiger als AMOUNT0"];

GermanQuestionDescriptions[QuestionIds.DUCAT] = ["Du darfst ein Kupfer entsorgen.", "Kupfer entsorgen?"];
GermanQuestionDescriptions[QuestionIds.IMPROVE_TRASH] = ["Du darfst eine Aktion im Spiel entsorgen (um dir eine bessere Karte zu nehmen).", "Aktion um $1 verbessern."];
GermanQuestionDescriptions[QuestionIds.IMPROVE_GAIN] = ["Nimm eine Karte, die genau AMOUNT0 kostet.", "Nimm eine Karte für AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.HIDEOUT] = ["Entsorge eine Karte (falls Punktekarte: nimm Fluch).", "Entsorge eine Karte"];
GermanQuestionDescriptions[QuestionIds.MOUNTAIN_VILLAGE] = ["Nimm eine Karte aus deinem Ablagestapel auf die Hand.", "Nimm auf die Hand"];
GermanQuestionDescriptions[QuestionIds.PRIEST] = ["Entsorge eine Karte aus deiner Hand.", "Entsorge eine Karte"];
GermanQuestionDescriptions[QuestionIds.RESEARCH_TRASH] = ["Entsorge eine Karte aus deiner Hand.", "Entsorge eine Karte"];
GermanQuestionDescriptions[QuestionIds.OLD_WITCH] = ["Du darfst einen Fluch aus deiner Hand entsorgen.", "Entsorge einen Fluch"];
GermanQuestionDescriptions[QuestionIds.RECRUITER] = ["Entsorge eine Karte aus deiner Hand.", "Entsorge eine Karte"];
GermanQuestionDescriptions[QuestionIds.SCEPTER_COMPLEX] = ["Zepter ist gespielt", "Aktion nochmal oder +2 Geld?"];
GermanQuestionDescriptions[QuestionIds.SCEPTER_MODE] = ["+2 Geld", "+2 Geld"];
GermanQuestionDescriptions[QuestionIds.SCEPTER_PLAY] = ["eine Aktion im Spiel noch einmal spielen", "Spiele Aktion nochmal"];
GermanQuestionDescriptions[QuestionIds.SCULPTOR] = ["Nimm eine Karte bis zu $4 auf die Hand.", "Nimm auf die Hand"];
GermanQuestionDescriptions[QuestionIds.SEER] = ["Lege die Karten in beliebiger Reihenfolge zurück auf den Nachziehstapel.", "Karten zurücklegen"];
GermanQuestionDescriptions[QuestionIds.VILLAIN] = ["Lege eine Karte ab, die $2 oder mehr kostet.", "Lege eine Karte ab"];
GermanQuestionDescriptions[QuestionIds.TREASURER_COMPLEX] = ["Die Schatzmeisterin ist gespielt", "Schatzmeisterin gespielt"];
GermanQuestionDescriptions[QuestionIds.TREASURER_TRASH] = ["eine Geldkarte aus der Hand entsorgen", "entsorge eine Geldkarte"];
GermanQuestionDescriptions[QuestionIds.TREASURER_GAIN] = ["eine Geldkarte auf die Hand nehmen", "nimm eine Geldkarte"];
GermanQuestionDescriptions[QuestionIds.TREASURER_KEY] = ["den Schlüssel erhalten", "erhalte den Schlüssel"];
GermanQuestionDescriptions[QuestionIds.TREASURER_FAIL] = ["nichts tun", "Tue nichts"];
GermanQuestionDescriptions[QuestionIds.BORDER_GUARD_PUT_IN_HAND] = ["Nimm eine der aufgedeckten Karten auf die Hand.", "Nimm auf die Hand"];
GermanQuestionDescriptions[QuestionIds.BORDER_GUARD_MODE] = ["Welches Artefakt willst du erhalten?", "Laterne oder Horn?"];
GermanQuestionDescriptions[QuestionIds.HORN_TOPDECK] = ["", "Welchen [Border Guard] auf den Nachziehstapel legen?"];
GermanQuestionDescriptions[QuestionIds.CATHEDRAL] = ["Entsorge eine Karte aus deiner Hand.", "Entsorge eine Karte"];
GermanQuestionDescriptions[QuestionIds.CITY_GATE] = ["Lege eine Handkarte auf den Nachziehstapel.", "Auf Nachziehstapel."];
GermanQuestionDescriptions[QuestionIds.PAGEANT] = ["Du darfst $1 für +1 Taler zahlen.", "$1 für +1 Taler?"];
GermanQuestionDescriptions[QuestionIds.SEWERS] = ["Du darfst bis zu AMOUNT0 Karten aus deiner Hand entsorgen. (" + GermanCardNames[CardNames.SEWERS].singular + ")", "Bis zu AMOUNT0 entsorgen (" + GermanCardNames[CardNames.SEWERS].singular + ")"];
GermanQuestionDescriptions[QuestionIds.SILOS] = ["+1 Karte pro abgelegtem Kupfer", "Kupferkarten ablegen"];
GermanQuestionDescriptions[QuestionIds.CROP_ROTATION] = ["Punktekarte für +2 Karten ablegen?", "Du darfst ablegen"];
GermanQuestionDescriptions[QuestionIds.SINISTER_PLOT] = ["Ziehe AMOUNT0 Karten oder lege Marker drauf?", "Ziehe AMOUNT0 oder Marker drauf?"];
GermanQuestionDescriptions[QuestionIds.STAR_CHART] = ["Wähle die oberste Karte nach dem Mischen.", "Wähle die oberste Karte"];
GermanQuestionDescriptions[QuestionIds.CAPTAIN] = ["Spiele eine Aktion aus dem Vorrat bis zu $4.", "Spiele eine Aktion aus dem Vorrat"];
GermanQuestionDescriptions[QuestionIds.CHURCH_SET_ASIDE] = ["Lege bis zu 3 Karten beiseite.", "Lege bis zu 3 Karten beiseite"];
GermanQuestionDescriptions[QuestionIds.CHURCH_TRASH] = ["Du darfst eine Karte aus deiner Hand entsorgen.", "Du darfst eine Karte entsorgen"];

GermanQuestionDescriptions[QuestionIds.CAMEL_TRAIN] = ["**<Blank>.**", "Lege eine Vorratskarte ins Exil"];
GermanQuestionDescriptions[QuestionIds.GOATHERD] = ["You may trash a card from your hand.", "Du darfst eine Handkarte entsorgen"];
GermanQuestionDescriptions[QuestionIds.SNOWY_VILLAGE] = ["You may use villagers while you can", "Dorfbewohner einsetzen vor Aktionsstop?"];
GermanQuestionDescriptions[QuestionIds.BOUNTY_HUNTER] = ["Exile a card from your hand", "Lege eine Handkarte ins Exil"];
GermanQuestionDescriptions[QuestionIds.CARDINAL_EXILE] = ["Exile a card", "Lege eine Karte ins Exil"]; // not yet tested
GermanQuestionDescriptions[QuestionIds.GROOM] = ["Gain a card costing up to AMOUNT0.", "Nimm eine Karte bis zu AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.HOSTELRY] = ["Discard for Horses", "Lege Geldkarten für Pferde ab"];
GermanQuestionDescriptions[QuestionIds.VILLAGE_GREEN] = ["Now or next turn?", "Jetzt oder im nächsten Zug?"];
GermanQuestionDescriptions[QuestionIds.BARGE] = ["Now or next turn?", "Jetzt oder im nächsten Zug?"];
GermanQuestionDescriptions[QuestionIds.DISPLACE_EXILE] = ["Exile a card from your hand", "Lege eine Handkarte ins Exil"];
GermanQuestionDescriptions[QuestionIds.DISPLACE_GAIN] = ["Gain a card costing up to AMOUNT0.", "Nimm eine Karte bis zu AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.FALCONER] = ["Gain a card to your hand", "Nimm eine Karte billiger als diese auf die Hand"];
GermanQuestionDescriptions[QuestionIds.HUNTING_LODGE] = ["Discard for +5 Cards?", "Hand ablegen für +5 Karten?"];
GermanQuestionDescriptions[QuestionIds.MASTERMIND] = ["You may play an Action card three times.", "Du darfst eine Aktionskarte dreimal spielen"];
GermanQuestionDescriptions[QuestionIds.SANCTUARY] = ["You may Exile a Card from your hand", "Du darfst eine Handkarte ins Exil legen"];
GermanQuestionDescriptions[QuestionIds.WAYFARER] = ["You may gain a Silver", "Du darfst dir ein Silber nehmen"];
GermanQuestionDescriptions[QuestionIds.DELAY] = ["You may set aside an Action", "Du darfst eine Aktionskarte beiseite legen."];
GermanQuestionDescriptions[QuestionIds.DESPERATION] = ["You may gain a Curse", "Du darfst einen Fluch nehmen"];
GermanQuestionDescriptions[QuestionIds.GAMBLE] = ["Play Argument0?", "Argument0 spielen?"];
GermanQuestionDescriptions[QuestionIds.PURSUE] = ["What card to keep on top?", "Welche Karte suchen?"];
GermanQuestionDescriptions[QuestionIds.TOIL] = ["You may play a card", "Du darfst eine Aktionskarte spielen"];
GermanQuestionDescriptions[QuestionIds.ENHANCE_TRASH] = ["You may trash a non-Victory card", "Du darfst eine Handkarte entsorgen."];
GermanQuestionDescriptions[QuestionIds.ENHANCE_GAIN] = ["Gain a card costing up to AMOUNT0.", "Nimm eine Karte bis zu AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.MARCH] = ["You may play a card from your discard pile", "Du darfst eine Karte aus dem Ablagestapel spielen"];
GermanQuestionDescriptions[QuestionIds.TRANSPORT_MAY_EXILE] = ["You may exile an Action card", "Du darfst eine Vorratskarte ins Exil legen"];
GermanQuestionDescriptions[QuestionIds.TRANSPORT_EXILE] = ["Exile an Action card", "Lege eine Aktionskarte ins Exil"];
GermanQuestionDescriptions[QuestionIds.TRANSPORT_MAY_TOPDECK] = ["You may topdeck an Exiled Action", "Du darfst eine Aktionskarte im Exil auf deinen Nachziehstapel legen"];
GermanQuestionDescriptions[QuestionIds.TRANSPORT_TOPDECK] = ["Topdeck an Exiled Action", "Lege eine Aktionskarte im Exil auf deinen Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.TRANSPORT_COMPLEX] = ["Exile from the Supply; or Topdeck from Exile", "Vom Vorrat ins Exil oder aus dem Exil auf den Nachziehstapel"]; //OK
GermanQuestionDescriptions[QuestionIds.BANISH] = ["Exile cards with the same name", "Lege Karten gleichen Namens ins Exil"];
GermanQuestionDescriptions[QuestionIds.BARGAIN] = ["Gain a non-Victory up to $5", "Nimm eine Karte (keine Punktekarte) bis zu $5"];
GermanQuestionDescriptions[QuestionIds.INVEST] = ["Exile an Action card to Invest in", "Lege eine Aktionskarte als Investition ins Exil"];
GermanQuestionDescriptions[QuestionIds.DEMAND] = ["Gain a card up to $4 onto your deck", "Lege eine Karte bis zu $4 auf deinen Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.POPULATE] = ["What card to gain next?", "Welche Karte jetzt nehmen?"];
GermanQuestionDescriptions[QuestionIds.RECONSIDER_WAY] = ["Reconsider the Way you play Argument0", "Überdenke den Weg, Argument0 zu spielen"];
GermanQuestionDescriptions[QuestionIds.CONSIDER_WAY] = ["Consider the Way you play Argument0", "Entscheide den Weg, Argument0 zu spielen"];
GermanQuestionDescriptions[QuestionIds.WAY_OF_THE_GOAT] = ["Trash a Card from your hand.", "Entsorge eine Handkarte."];
GermanQuestionDescriptions[QuestionIds.WAY_OF_THE_RAT] = ["Discard a Treasure to gain a copy", "Lege eine Geldkarte ab, um eine Kopie dieser Karte zu nehmen"];
GermanQuestionDescriptions[QuestionIds.SCRAP_TRASH] = ["Trash a card from your hand.", "Entsorge eine Handkarte"];
GermanQuestionDescriptions[QuestionIds.SCRAP_MODES] = ["Choose Argument0 effects", "Wähle Argument0 Effekt(e)"];
GermanQuestionDescriptions[QuestionIds.ANIMAL_FAIR_TRASH] = ["Trash an Action from your hand", "Entsorge eine Aktionskarte aus deiner Hand"];
GermanQuestionDescriptions[QuestionIds.ANIMAL_FAIR_MAY_TRASH] = ["You may trash an Action from your hand", "Du darfst eine Aktionkarte aus deiner Hand entsorgen"];
GermanQuestionDescriptions[QuestionIds.BUTTERFLY_RETURN] = ["Return Argument0 to its pile?", "Argument0 auf den Stapel zurücklegen?"];
GermanQuestionDescriptions[QuestionIds.BUTTERFLY_GAIN] = GermanQuestionDescriptions[QuestionIds.UPGRADE_GAIN];

GermanQuestionDescriptions[QuestionIds.BLOCKADE] = ["", "Nimm eine Karte"];
GermanQuestionDescriptions[QuestionIds.PIRATE] = ["", "Nimm auf die Hand"];
GermanQuestionDescriptions[QuestionIds.SAILOR] = ["", "Du darfst eine Karte entsorgen"];
GermanQuestionDescriptions[QuestionIds.TIDE_POOLS] = GermanQuestionDescriptions[QuestionIds.DUNGEON];
GermanQuestionDescriptions[QuestionIds.SEA_WITCH] = GermanQuestionDescriptions[QuestionIds.DUNGEON];
GermanQuestionDescriptions[QuestionIds.TIARA_MODE] = ["", "Wie Brautkrone spielen"];
GermanQuestionDescriptions[QuestionIds.TIARA_PLAY] = ["", "Welche Geldkarte doppelt spielen"];
GermanQuestionDescriptions[QuestionIds.CRYSTAL_BALL] = ["", "Wie mit Argument0 verfahren"];
GermanQuestionDescriptions[QuestionIds.WAR_CHEST_PROHIBIT] = ["", "Verbiete eine Karte"];
GermanQuestionDescriptions[QuestionIds.WAR_CHEST_GAIN] = ["", "Nimm eine Karte"];
GermanQuestionDescriptions[QuestionIds.ANVIL_DISCARD] = GermanQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_DISCARD];
GermanQuestionDescriptions[QuestionIds.ANVIL_GAIN] = GermanQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_GAIN];
GermanQuestionDescriptions[QuestionIds.CLERK] = ["", "Lege Karte auf Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.INVESTMENT_TRASH] = GermanQuestionDescriptions[QuestionIds.TRADE_ROUTE];
GermanQuestionDescriptions[QuestionIds.INVESTMENT_MODE] = ["", "Wie Geldanlage spielen"];

// Allies
GermanQuestionDescriptions[QuestionIds.TOWN_MODE] = ["", "Wie die Ortschaft spielen"];
GermanQuestionDescriptions[QuestionIds.MODIFY_TRASH] = ["", "Entsorge eine Karte"];
GermanQuestionDescriptions[QuestionIds.MODIFY_MODE] = ["", "Wie die Umgestaltung spielen"];
GermanQuestionDescriptions[QuestionIds.MODIFY_GAIN] = ["", "Nimm eine Karte"];
GermanQuestionDescriptions[QuestionIds.SWAP_RETURN] = ["", "Du darfst eine Karte zurücklegen"];
GermanQuestionDescriptions[QuestionIds.SWAP_GAIN] = ["", "Nimm eine amdere Aktionskarte"];
GermanQuestionDescriptions[QuestionIds.SKIRMISHER] = ["", "Lege bis auf 3 ab"];
GermanQuestionDescriptions[QuestionIds.WOODWORKERS_GUILD_TRASH] = ["", "Entsorge eine Aktionskarte"];
GermanQuestionDescriptions[QuestionIds.WOODWORKERS_GUILD_GAIN] = ["", "Nimm eine Aktionskarte"];
GermanQuestionDescriptions[QuestionIds.CRAFTERS_GUILD] = ["", "2 Gefallen einlösen, um eine Karte zu nehmen?"];
GermanQuestionDescriptions[QuestionIds.CAVE_DWELLERS] = ["", "1 Gefallen einlösen für 1 Karte ablegen und +1 Karte?"];
GermanQuestionDescriptions[QuestionIds.DESERT_GUIDES] = ["", "Handkarten behalten?"];
GermanQuestionDescriptions[QuestionIds.BAND_OF_NOMADS] = ["", "Nomadenstamm Effekt"];
GermanQuestionDescriptions[QuestionIds.SYCOPHANT] = ["", "Lege 3 Karten ab"];
GermanQuestionDescriptions[QuestionIds.IMPORTER] = ["", "Nimm eine Karte für maximal 5 Geld"];
GermanQuestionDescriptions[QuestionIds.CONTRACT] = ["", "Lege Aktionskarte beiseite zum Spielen im nächten Zug"];
GermanQuestionDescriptions[QuestionIds.BAUBLE] = ["", "Wähle die Boni"];
GermanQuestionDescriptions[QuestionIds.BROKER_TRASH] = ["", "Entsorge eine Karte"];
GermanQuestionDescriptions[QuestionIds.BROKER_MODE] = ["", "Wähle den Bonus"];
GermanQuestionDescriptions[QuestionIds.MARKET_TOWNS_FIRST] = ["", "Aktionskarte spielen?"];
GermanQuestionDescriptions[QuestionIds.MARKET_TOWNS_AGAIN] = ["", "Weitere Aktionskarte gegen Gefallen spielen?"];
GermanQuestionDescriptions[QuestionIds.BATTLE_PLAN_REVEAL] = ["", "Angriffskarte aufdecken für +1 Karte?"];
GermanQuestionDescriptions[QuestionIds.BATTLE_PLAN_ROTATE] = ["", "Einen Vorratsstapel rotieren?"];
GermanQuestionDescriptions[QuestionIds.BARBARIAN] = ["", "Nimm eine billigere Karte"];
GermanQuestionDescriptions[QuestionIds.INNKEEPER_MODE] = ["", "Wie die Wirtin spielen"];
GermanQuestionDescriptions[QuestionIds.INNKEEPER_DISCARD] = ["", "Lege Argument0 Karten ab"];
GermanQuestionDescriptions[QuestionIds.CAPITAL_CITY_MODE] = ["", "Ablegen oder bezahlen?"];
GermanQuestionDescriptions[QuestionIds.CAPITAL_CITY_DISCARD] = ["", "Lege 2 Karten ab"];
GermanQuestionDescriptions[QuestionIds.SPECIALIST_PLAY] = ["", "Welche Karte spielen?"];
GermanQuestionDescriptions[QuestionIds.SPECIALIST_MODE] = ["", "Argument0 nochmal spielen oder gleiche Karte nehmen?"];
GermanQuestionDescriptions[QuestionIds.GANG_OF_PICKPOCKETS] = ["", "Ablegen oder Gefallen einlösen?"];
GermanQuestionDescriptions[QuestionIds.COASTAL_HAVEN] = ["", "Welche Karten behalten?"];
GermanQuestionDescriptions[QuestionIds.CARPENTER_GAIN] = GermanQuestionDescriptions[QuestionIds.WORKSHOP];
GermanQuestionDescriptions[QuestionIds.CARPENTER_TRASH] = GermanQuestionDescriptions[QuestionIds.REMODEL_TRASH];
GermanQuestionDescriptions[QuestionIds.CARPENTER_GAIN_UP_TO] = GermanQuestionDescriptions[QuestionIds.REMODEL_GAIN];
GermanQuestionDescriptions[QuestionIds.MAY_ROTATE_FORTS] = ["", "Die Bastionen rotieren?"];
GermanQuestionDescriptions[QuestionIds.ROYAL_GALLEY] = ["", "Du darfst eine Aktionskarte spielen"];
GermanQuestionDescriptions[QuestionIds.HILL_FORT_GAIN] = GermanQuestionDescriptions[QuestionIds.WORKSHOP];
GermanQuestionDescriptions[QuestionIds.HILL_FORT_MODE] = ["", "Wie das Hügelfort fortsetzen"];
GermanQuestionDescriptions[QuestionIds.STRONGHOLD_MODE] = ["", "Wie die Burg spielen"];
GermanQuestionDescriptions[QuestionIds.MAY_ROTATE_ODYSSEYS] = ["", "Die Irrfahrten rotieren?"];
GermanQuestionDescriptions[QuestionIds.OLD_MAP_DISCARD] = ["", "Lege eine Karte ab"];
GermanQuestionDescriptions[QuestionIds.SUNKEN_TREASURE] = ["", "Nimm eine Aktionskarte, die du nicht im Spiel hast"];
GermanQuestionDescriptions[QuestionIds.PEACEFUL_CULT] = ["", "Du darfst Karten entsorgen gegen Gefallen"];
GermanQuestionDescriptions[QuestionIds.ORDER_OF_ASTROLOGERS] = ["", "Karten gegen Gefallen auf den Nachziehstapel legen"];
GermanQuestionDescriptions[QuestionIds.STAR_CHART_ASTROLOGERS_COMBINED] = ["", "Karten gegen Gefallen auf den Nachziehstapel legen (eine umsonst)"];
GermanQuestionDescriptions[QuestionIds.ORDER_OF_MASONS] = ["", "Karten gegen Gefallen (Verhältnis 2:1) auf den Ablagestapel legen"];
GermanQuestionDescriptions[QuestionIds.ARCHER_HIDE] = ["", "Wähle die Karte, die nicht aufgedeckt wird"];
GermanQuestionDescriptions[QuestionIds.ARCHER_DISCARD] = GermanQuestionDescriptions[QuestionIds.PILLAGE];
GermanQuestionDescriptions[QuestionIds.MARQUIS] = ["", "Lege bis auf 10 Karten ab"];
GermanQuestionDescriptions[QuestionIds.HERB_GATHERER_PLAY] = ["", "Welche Geldkarte spielen?"];
GermanQuestionDescriptions[QuestionIds.MAY_ROTATE_AUGURS] = ["", "Die Augurinnen rotieren?"];
GermanQuestionDescriptions[QuestionIds.ACOLYTE] = ["", "Karte entsorgen für Gold?"];
GermanQuestionDescriptions[QuestionIds.ACOLYTE_SELF] = ["", "Selbst entsorgen für Augurin?"];
GermanQuestionDescriptions[QuestionIds.SORCERESS] = ["", "Rate die oberste Karte deines Nachziehstapels"];
GermanQuestionDescriptions[QuestionIds.SIBYL_TOPDECK] = ["", "Lege oben auf den Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.SIBYL_BOTTOMDECK] = ["", "Lege unter den Nachziehstapel"];
GermanQuestionDescriptions[QuestionIds.MAY_ROTATE_WIZARDS] = ["", "Die Zauberer rotieren?"];
GermanQuestionDescriptions[QuestionIds.STUDENT] = ["", "1 Karte entsorgen"];
GermanQuestionDescriptions[QuestionIds.CONJURER] = GermanQuestionDescriptions[QuestionIds.WORKSHOP];
GermanQuestionDescriptions[QuestionIds.SORCERER] = ["", "Rate die oberste Karte deines Nachziehstapels"];
GermanQuestionDescriptions[QuestionIds.LICH] = ["", "Nimm eine Karte aus dem Müll"];
GermanQuestionDescriptions[QuestionIds.MAY_ROTATE_TOWNSFOLK] = ["", "Die Bürger rotieren?"];
GermanQuestionDescriptions[QuestionIds.TOWN_CRIER] = ["", "Wähle den [Town Crier] - Bonus"];
GermanQuestionDescriptions[QuestionIds.BLACKSMITH] = ["", "Wähle den [Blacksmith] - Bonus"];
GermanQuestionDescriptions[QuestionIds.MILLER] = ["", "Nimm eine Karte auf die Hand"];
GermanQuestionDescriptions[QuestionIds.ELDER] = ["", "Du darfst eine Aktionsarte spielen"];
GermanQuestionDescriptions[QuestionIds.SENTINEL_TRASH] = ["", "Entsorge bis zu 2 Karten"];
GermanQuestionDescriptions[QuestionIds.SENTINEL_TOPDECK] = GermanQuestionDescriptions[QuestionIds.APOTHECARY];
GermanQuestionDescriptions[QuestionIds.COURIER] = ["", "Du darst eine Karte spielen"];
GermanQuestionDescriptions[QuestionIds.HUNTER_ACTION] = ["", "Nimm eine Aktionskarte auf die Hand"];
GermanQuestionDescriptions[QuestionIds.HUNTER_TREASURE] = ["", "Nimm eine Geldkarte auf die Hand"];
GermanQuestionDescriptions[QuestionIds.HUNTER_VICTORY] = ["", "Nimm eine Punktekarte auf die Hand"];
GermanQuestionDescriptions[QuestionIds.LURKER_MODE] = ["", "Wie die Herumtreiberin spielen"];
GermanQuestionDescriptions[QuestionIds.GRAVEROBBER_MODE] = ["", "Wie den Grabräuber spielen"];
GermanQuestionDescriptions[QuestionIds.TREASURER_MODE] = ["", "Wie die Schatzmeisterin spielen"];
GermanQuestionDescriptions[QuestionIds.ARCHITECTS_GUILD] = ["", "Nimm eine billigere Karte"];
GermanQuestionDescriptions[QuestionIds.FAMILY_OF_INVENTORS] = ["", "Welcher Stapel soll billiger werden?"];
GermanQuestionDescriptions[QuestionIds.FOREST_DWELLERS_DISCARD] = ["", "Lege beliebig viele Karten ab"];
GermanQuestionDescriptions[QuestionIds.FOREST_DWELLERS_TOPDECK] = ["", "Lege zurück in beliebiger Reihenfolge"];
GermanQuestionDescriptions[QuestionIds.USE_FAVOR_WHEN_SHUFFLING] = ["", "Gefallen einlösen beim Mischen?"];
GermanQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS] = ["", "Du kannst mehr Taler ausgeben"];
GermanQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_BASILICA] = ["", "Du kannst Taler ausgeben für Basilika Bonus"];
GermanQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_SWASHBUCKLER] = ["", "Du kannst Taler ausgeben, um die Schatzkiste nicht zu bekommen"];
GermanQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_FORTUNE] = ["", "Du kannst Taler ausgeben, bevor dein Geld verdopplt wird"];

GermanQuestionDescriptions[QuestionIds.WHEELWRIGHT_DISCARD] = ["", "Ablegen, um zu nehmen"];
GermanQuestionDescriptions[QuestionIds.WHEELWRIGHT_GAIN] = ["", "Nimm eine Karte bis zu AMOUNT0"];
GermanQuestionDescriptions[QuestionIds.BERSERKER_DISCARD] = GermanQuestionDescriptions[QuestionIds.MILITIA];
GermanQuestionDescriptions[QuestionIds.BERSERKER_GAIN] = ["", "Nimm eine billigere Karte"];
GermanQuestionDescriptions[QuestionIds.WEAVER] = ["", "Nimm eine Karte oder 2 Silber"];
GermanQuestionDescriptions[QuestionIds.SOUK] = ["", "Entsorge bis zu 2 Karten"];
GermanQuestionDescriptions[QuestionIds.WITCHS_HUT] = ["", "Lege 2 Karten aufgedeckt ab"];
GermanQuestionDescriptions[QuestionIds.STONEMASON_OVERPAY_AMOUNT] = ["You may overpay for stonemason by gaining an Action up to AMOUNT0.", "Specify the overpay amount."];

//Plunder
GermanQuestionDescriptions[QuestionIds.CAGE_SET_ASIDE] = ["", "Du darfst bis zu 4 Karten beiseite legen."];
GermanQuestionDescriptions[QuestionIds.GROTTO_SET_ASIDE] = ["", "Du darfst bis zu 4 Karten beiseite legen."];
GermanQuestionDescriptions[QuestionIds.SHAMAN_GAIN] = ["", "Nimm eine Karte, die bis zu $6 kostet, aus dem Müll."];
GermanQuestionDescriptions[QuestionIds.SHAMAN_TRASH] = ["", "Du darfst eine Handkarte von dir entsorgen."];
GermanQuestionDescriptions[QuestionIds.SECLUDED_SHRINE] = ["", "Du darfst bis zu 2 deiner Handkarten entsorgen."];
GermanQuestionDescriptions[QuestionIds.SIREN] = ["", "Du darfst eine Aktionskarte aus deiner Hand entsorgen."];
GermanQuestionDescriptions[QuestionIds.CABIN_BOY_MODE] = ["", "Wähle: +2 Geld, oder entsorge [Cabin Boy], um eine Dauerkarte zu nehmen."];
GermanQuestionDescriptions[QuestionIds.CABIN_BOY_GAIN] = ["", "Nimm eine Dauerkarte."];
GermanQuestionDescriptions[QuestionIds.CRUCIBLE] = ["", "Entsorge eine Handkarte von dir."];
GermanQuestionDescriptions[QuestionIds.FORTUNE_HUNTER_PLAY] = ["", "Spiele eine der Geldkarten."];
GermanQuestionDescriptions[QuestionIds.FORTUNE_HUNTER_TOPDECK] = ["", "Lege die anderen Karten zurück auf den Nachziehstapel."];
GermanQuestionDescriptions[QuestionIds.GONDOLA_MODE] = ["", "+2 Geld jetzt oder im nächsten Zug?"];
GermanQuestionDescriptions[QuestionIds.GONDOLA_PLAY] = ["", "Du darfst eine Aktionskarte aus deiner Hand spielen."];
GermanQuestionDescriptions[QuestionIds.MAPMAKER] = ["", "Nimm 2 Karten auf deine Hand."];
GermanQuestionDescriptions[QuestionIds.MAROON] = ["", "Entsorge eine Handkarte von dir."];
GermanQuestionDescriptions[QuestionIds.ROPE_TRASH] = ["", "Du darfst ein Handkarte von dir entsorgen."];
GermanQuestionDescriptions[QuestionIds.TOOLS] = ["", "Nimm eine gleiche Karte zu einer, die irgendjemand im Spiel hat."];
GermanQuestionDescriptions[QuestionIds.CUTTHROAT_DISCARD] = ["", "Lege bis auf 3 Handkarten ab."];
GermanQuestionDescriptions[QuestionIds.ENLARGE_TRASH] = ["", "Entsorge eine Karte."];
GermanQuestionDescriptions[QuestionIds.ENLARGE_GAIN] = ["", "Nimm eine Karte, die bis zu AMOUNT0 kostet."];
GermanQuestionDescriptions[QuestionIds.FIGURINE] = ["", "Du darfst eine Aktionskarte für +1 Geld und +1 Kauf ablegen."];
GermanQuestionDescriptions[QuestionIds.FIRST_MATE] = ["", "Du darfst eine Karte spielen."];
GermanQuestionDescriptions[QuestionIds.FRIGATE] = ["", "Lege bis auf 4 Handkarten ab."];
GermanQuestionDescriptions[QuestionIds.PICKAXE] = ["", "Entsorge eine Handkarte von dir."];
GermanQuestionDescriptions[QuestionIds.PILGRIM_TOPDECK] = ["", "Lege eine Karte aus deiner Hand auf deinen Nachziehstapel."];
GermanQuestionDescriptions[QuestionIds.QUARTERMASTER_MODE] = ["", "Nimm eine Karte bis zu $4, oder nimm eine von Argument0 auf die Hand."];
GermanQuestionDescriptions[QuestionIds.QUARTERMASTER_GAIN] = ["", "Nimm eine Karte, die bis zu $4 kostet."];
GermanQuestionDescriptions[QuestionIds.QUARTERMASTER_TAKE] = ["", "Nimm eine Karte auf die Hand."];
GermanQuestionDescriptions[QuestionIds.TRICKSTER] = ["", "Du darfst eine Geldkarte von dir aus dem Spiel beiseite legen."];
GermanQuestionDescriptions[QuestionIds.SILVER_MINE] = ["", "Nimm eine Geldkarte direkt auf deine Hand."];
GermanQuestionDescriptions[QuestionIds.KINGS_CACHE] = ["", "Du darfst eine Geldkarte dreimal spielen."];
GermanQuestionDescriptions[QuestionIds.BURY] = ["", "Lege eine Karte unter deinen Nachziehstapel."];
GermanQuestionDescriptions[QuestionIds.PERIL] = ["", "Du darfst eine Aktion entsorgen, um eine Kostbarkeit zu nehmen."];
GermanQuestionDescriptions[QuestionIds.PREPARE] = ["", "Spiele eine beiseite gelegte Karte."]; //vertauscht mit FORAY
GermanQuestionDescriptions[QuestionIds.FORAY] = ["", "Lege 3 Handkarten ab."]; //vertauscht mit PREPARE
GermanQuestionDescriptions[QuestionIds.SCROUNGE_COMPLEX] = ["", "Wenn du Herumsuchen spielst,"]; //RETEST
GermanQuestionDescriptions[QuestionIds.SCROUNGE_TRASH] = ["", "Entsorge eine Handkarte von dir."]; //RETEST
GermanQuestionDescriptions[QuestionIds.SCROUNGE_MAY_TRASH] = ["", "Du darfst eine Handkarte von dir entsorgen."]; //RETEST
GermanQuestionDescriptions[QuestionIds.SCROUNGE_GAIN_ESTATE] = ["", "Nimm ein Anwesen."]; //RETEST
GermanQuestionDescriptions[QuestionIds.SCROUNGE_MAY_GAIN_ESTATE] = ["", "Du darfst ein Anwesen nehmen."]; //RETEST
GermanQuestionDescriptions[QuestionIds.SCROUNGE_GAIN] = ["", "Nimm eine Karte, die bis zu $5 kostet."]; //RETEST
GermanQuestionDescriptions[QuestionIds.MAELSTROM_TRASH] = ["", "Entsorge eine deiner Handkarten."]; // vertauscht mit MAELSTROM_OPPONENT_TRASH
GermanQuestionDescriptions[QuestionIds.MAELSTROM_OPPONENT_TRASH] = ["", "Entsorge Argument0 Handkarten."]; //vertauscht mit MAELSTROM_TRASH
GermanQuestionDescriptions[QuestionIds.INVASION_PLAY] = ["", "Du darfst einen Angriff spielen."];
GermanQuestionDescriptions[QuestionIds.INVASION_GAIN] = ["", "Nimm eine Aktionskarte."];
GermanQuestionDescriptions[QuestionIds.PROSPER] = ["", "Nimm eine Geldkarte mit anderem Namen."];
GermanQuestionDescriptions[QuestionIds.AMPHORA_MODE] = ["", "+1 Kauf, +3 Geld jetzt oder im nächsten Zug?"];
GermanQuestionDescriptions[QuestionIds.HAMMER] = ["", "Nimm eine Karte, die bis zu 4 Geld kostet."];
GermanQuestionDescriptions[QuestionIds.ORB] = ["", "Erhalte 3 Geld und einen Kauf oder spiele eine Aktions- bzw. Geldkarte."];
GermanQuestionDescriptions[QuestionIds.PRIZE_GOAT] = ["", "Du darfst eine Handkarte von dir entsorgen."];
GermanQuestionDescriptions[QuestionIds.PUZZLE_BOX] = ["", "Lege eine Karte beiseite bis zum Ende des Zuges."];
GermanQuestionDescriptions[QuestionIds.SEXTANT_DISCARD] = ["", "Lege eine beliebige Anzahl dieser Karten ab."];
GermanQuestionDescriptions[QuestionIds.SEXTANT_TOPDECK] = ["", "Legen die übigen Karten in beliebiger Reihenfolge zurück."];
GermanQuestionDescriptions[QuestionIds.SPELL_SCROLL_GAIN] = ["", "Nimm eine Karte, die weniger als AMOUNT0 kostet."];
GermanQuestionDescriptions[QuestionIds.SPELL_SCROLL_PLAY] = ["", "Argument0 spielen?"];
GermanQuestionDescriptions[QuestionIds.STAFF] = ["", "Du darfst eine Aktionskarte aus deiner Hand spielen."];
GermanQuestionDescriptions[QuestionIds.SWORD] = ["", "Lege bis auf 4 Handkarten ab."];
GermanQuestionDescriptions[QuestionIds.FRIENDLY] = ["", "Lege eine [Friendly]e Karte ab, um eine weitere zu nehmen."]; //TOTEST
GermanQuestionDescriptions[QuestionIds.INHERITED] = ["", "Ersetze eine Startkarte durch eine [Inherited]e Karte."];
GermanQuestionDescriptions[QuestionIds.INSPIRING] = ["", "Du darfst eine Aktionskarte spielen, die du nicht im Spiel hast ([Inspiring])."];
GermanQuestionDescriptions[QuestionIds.PATIENT_SET_ASIDE] = ["", "Lege [Patient]e Karten zur Seite, um sie im nächsten Zug zu spielen."];
GermanQuestionDescriptions[QuestionIds.PATIENT_PLAY] = ["", "Welche [Patient]e Karte als nächtes spielen?"];
GermanQuestionDescriptions[QuestionIds.PIOUS_TRASH] = ["", "Du darfst eine Karte aus deiner Hand entsorgen."];
GermanQuestionDescriptions[QuestionIds.SHY] = ["", "Lege 1 [Shy]e Karte für +2 Karten ab."];
GermanQuestionDescriptions[QuestionIds.FATED_TOPDECK] = ["", "Du darfst Vorherbestimmte Karten nach oben legen."];
GermanQuestionDescriptions[QuestionIds.FATED_BOTTOMDECK] = ["", "Du darfst Vorherbestimmte Karten nach unten legen."];
GermanQuestionDescriptions[QuestionIds.AVOID] = ["", "Lege bis zu 3 Karten in den Ablagestapel."];
GermanQuestionDescriptions[QuestionIds.TRICKSTER_DISCARD] = ["", "Welche Geldkarte zur Seite legen?"];
GermanQuestionDescriptions[QuestionIds.MARCHLAND] = ["", "Lege für +$1/Karte ab"];

GermanQuestionDescriptions[QuestionIds.FARMHANDS] = ["", "Welche Karte zu Beginn deines nächsten Zuges spielen?"];
GermanQuestionDescriptions[QuestionIds.COURSER] = ["", "Wähle die Optionen für das [Courser]."];
GermanQuestionDescriptions[QuestionIds.CORONET_ACTION] = ["", "Welche Aktionskarte zweimal spielen?"];
GermanQuestionDescriptions[QuestionIds.CORONET_TREASURE] = ["", "Welche Geldkarte zweimal spielen?"];
GermanQuestionDescriptions[QuestionIds.JOUST_SET_ASIDE] = ["", "Provinz zur Seite legen?"];
GermanQuestionDescriptions[QuestionIds.JOUST_GAIN_REWARD] = ["", "Welche Belohnung auf die Hand nehmen?"];
GermanQuestionDescriptions[QuestionIds.INFIRMARY_TRASH] = ["", "Du darfst eine Handkarte entsorgen."];
GermanQuestionDescriptions[QuestionIds.INFIRMARY_OVERPAY] = ["", "Du darfst für das [Infirmary] überzahlen."];
GermanQuestionDescriptions[QuestionIds.FOOTPAD] = GermanQuestionDescriptions[QuestionIds.MILITIA];
GermanQuestionDescriptions[QuestionIds.FARRIER_OVERPAY] = ["", "Du darfst für den [Farrier] überzahlen."];
GermanQuestionDescriptions[QuestionIds.SHOP] = GermanQuestionDescriptions[QuestionIds.CONCLAVE];
GermanQuestionDescriptions[QuestionIds.FERRYMAN] = GermanQuestionDescriptions[QuestionIds.FUGITIVE];

GermanQuestionDescriptions[QuestionIds.ORDER_BOTTOMDECKED_CARDS] = ["", "Wähle die Reihenfolge unten im Nachziehstapel."];
GermanQuestionDescriptions[QuestionIds.GROWTH] = GermanQuestionDescriptions[QuestionIds.BORDER_VILLAGE];
GermanQuestionDescriptions[QuestionIds.KIND_EMPEROR] = ["", "Nimm eine Aktionskarte auf deine Hand."];
GermanQuestionDescriptions[QuestionIds.SICKNESS_MODE] = ["", ""]; //NOT USED
GermanQuestionDescriptions[QuestionIds.SICKNESS_DISCARD] = ["", "Lege 3 Karten ab oder nimm einen Fluch auf deinen Nachziehstapel."];
GermanQuestionDescriptions[QuestionIds.SICKNESS] = ["", "Lege 3 Karten ab oder nimm einen Fluch auf deinen Nachziehstapel."]; //NOT USED
GermanQuestionDescriptions[QuestionIds.SNAKE_WITCH] = ["", "[Snake Witch] zurücklegen, damit alle Anderen einen Fluch bekommen?"]; //NOT USED
GermanQuestionDescriptions[QuestionIds.CRAFTSMAN] = ["", "Nimm ein Karte, die bis zu $5 kostet."];
GermanQuestionDescriptions[QuestionIds.ALLEY] = GermanQuestionDescriptions[QuestionIds.OASIS];
GermanQuestionDescriptions[QuestionIds.CHANGE_TRASH] = ["", "Entsorge eine deiner Handkarten."];
GermanQuestionDescriptions[QuestionIds.CHANGE_GAIN] = ["", "Nimm eine Karte und erhalte Schulden."];
GermanQuestionDescriptions[QuestionIds.NINJA] = GermanQuestionDescriptions[QuestionIds.MILITIA];
GermanQuestionDescriptions[QuestionIds.RIVER_SHRINE_TRASH] = ["", "Entsorge bis zu 2 deiner Handkarten."];
GermanQuestionDescriptions[QuestionIds.RIVER_SHRINE_GAIN] = ["", "Nimm eine Karte bis zu 4 Geld."];
GermanQuestionDescriptions[QuestionIds.RUSTIC_VILLAGE_CHOICE] = ["", "2 Karten ablegen um 1 Karte zu ziehen?"];
GermanQuestionDescriptions[QuestionIds.RUSTIC_VILLAGE_DISCARD] = ["", "Welche 2 Karten ablegen?"];
GermanQuestionDescriptions[QuestionIds.GOLD_MINE] = ["", "Ein Gold nehmen und 4 Schulden erhalten?"];
GermanQuestionDescriptions[QuestionIds.KITSUNE] = ["", "Wähle die Effekte für Kitsune."];
GermanQuestionDescriptions[QuestionIds.TANUKI_TRASH] = GermanQuestionDescriptions[QuestionIds.REMODEL_TRASH];
GermanQuestionDescriptions[QuestionIds.TANUKI_GAIN] = GermanQuestionDescriptions[QuestionIds.REMODEL_GAIN];
GermanQuestionDescriptions[QuestionIds.SAMURAI] = GermanQuestionDescriptions[QuestionIds.MILITIA];
GermanQuestionDescriptions[QuestionIds.MOUNTAIN_SHRINE] = ["", "Du darfst eine deiner Handkarten entsorgen."];
GermanQuestionDescriptions[QuestionIds.AMASS] = ["", "Nimm eine Aktionskarte bis zu $5."];
GermanQuestionDescriptions[QuestionIds.ASCETICISM] = ["", "Entsorge bis zu Argument0 Handarte(n)."];
GermanQuestionDescriptions[QuestionIds.CONTINUE] = ["", "Nimm und spiele eine Aktionskarte bis zu $4 (kein Angriff)."];
GermanQuestionDescriptions[QuestionIds.CREDIT] = ["", "Nimm eine Aktions- oder Geldkarte bis zu $8."];
GermanQuestionDescriptions[QuestionIds.KINTSUGI_TRASH] = GermanQuestionDescriptions[QuestionIds.CHANGE_TRASH];
GermanQuestionDescriptions[QuestionIds.KINTSUGI_GAIN] = GermanQuestionDescriptions[QuestionIds.REMODEL_GAIN];
GermanQuestionDescriptions[QuestionIds.PRACTICE] = GermanQuestionDescriptions[QuestionIds.THRONE_ROOM];
GermanQuestionDescriptions[QuestionIds.SEA_TRADE] = GermanQuestionDescriptions[QuestionIds.ASCETICISM];
GermanQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_FIRST] = ["", "Du kannst 3 Aktionskarten nehmen, die du nicht im Spiel hast."];
GermanQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_SECOND] = ["", "Du kannst 2 Aktionskarten nehmen, die du nicht im Spiel hast."];
GermanQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_THIRD] = ["", "Du kannst 1 Aktionskarte nehmen, die du nicht im Spiel hast."];
GermanQuestionDescriptions[QuestionIds.GATHER] = ["", "Nimm eine Karte, die genau Argument0 Geld kostet."];

GermanQuestionDescriptions[QuestionIds.GAME_PAY_OFF_DEBT] = ["", "Du kannst Schulden tilgen."];
GermanQuestionDescriptions[QuestionIds.MAY_PAY_OFF_EXTRA_DEBT] = ["", "Du kannst Schulden tilgen."]; //when used?
GermanQuestionDescriptions[QuestionIds.PAY_OFF_DEBT_BEFORE_CHANGE] = ["", "Schulden tilgen, um mit [Change] zu entsorgen statt Geld zu erhalten?"];
GermanQuestionDescriptions[QuestionIds.PAY_OFF_DEBT_BEFORE_STORYTELLER] = ["", "Schulden tilgen, um weniger Karten zu ziehen?"];