"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var NameLayer = function (_StackViewLayer) {
    _inherits(NameLayer, _StackViewLayer);

    function NameLayer(rootNode, cardStack) {
        _classCallCheck(this, NameLayer);

        var _this = _possibleConstructorReturn(this, (NameLayer.__proto__ || Object.getPrototypeOf(NameLayer)).call(this, rootNode, cardStack));

        _this.node.style.zIndex = 6;
        _this.node.classList.add("name-layer");
        return _this;
    }

    _createClass(NameLayer, [{
        key: "redraw",
        value: function redraw(w, h) {
            var card = this.cardStack.topCard;
            var cardName = card.cardName;
            var cardSize = this.cardStack.cardSize;
            var isInherited = card.cardName === CardNames.ESTATE && card.types.includes(Types.ACTION);
            var proxyName = isInherited ? CardNames.MILL : card.image;
            var color = cardName.titleColor();
            var isShifted = this.cardStack.hasFilter("shifted-name");
            var isInverted = this.cardStack.hasFilter("inverted-card");
            var shouldHide = cardName.isBack() || this.cardStack.hasFilter("appears-anonymous");

            if (shouldHide) {
                this.node.classList.add("invisible");
                return;
            } else {
                this.node.classList.remove("invisible");
            }

            var nameString = LANGUAGE.getCardName[cardName].singular;
            var cardNameBbox = getCardNameBbox(w, proxyName, cardSize, false).roundPositions();
            cardNameBbox = getCardNameBbox(w, proxyName, cardSize, isShifted).roundPositions();
            if (isInverted) cardNameBbox = getCardTypeBbox(w, proxyName).roundPositions();

            this.node.style.left = cardNameBbox.x + "px";
            this.node.style.top = cardNameBbox.y + "px";
            this.node.style.width = cardNameBbox.width + "px";
            this.node.style.height = cardNameBbox.height + "px";
            this.node.style.color = color;
            this.node.style.fontFamily = LANGUAGE.getEnvironment.hasOwnProperty("titleFont") ? LANGUAGE.getEnvironment.titleFont : "TrajanPro-Bold";
            this.node.style.fontSize = cardNameBbox.height * 0.7 + "px";

            fitTextInNode(nameString, this.node, cardNameBbox);
        }
    }]);

    return NameLayer;
}(StackViewLayer);