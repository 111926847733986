"use strict";

webclient.component("newErrataWarning", {
    transclude: true,
    controller: ['game', 'preferences', function (game, preferences) {
        var self = this;
        var recentErrataCards = [CardNames.HAUNTED_WOODS, CardNames.SWAMP_HAG, CardNames.MESSENGER, CardNames.PORT, CardNames.CHARM, CardNames.FORUM, CardNames.HOVEL, CardNames.HERMIT, CardNames.BASILICA, CardNames.DEFILED_SHRINE, CardNames.COLONNADE, CardNames.EXPLORATION, CardNames.PLAN, CardNames.TAX, CardNames.STONEMASON, CardNames.DOCTOR, CardNames.MASTERPIECE, CardNames.HERALD, CardNames.BRIDGE_TROLL, CardNames.GROUNDSKEEPER, CardNames.TRACKER, CardNames.PRINCESS, CardNames.SAUNA, CardNames.CRYPT, CardNames.COUNTERFEIT, CardNames.BONFIRE, CardNames.PRINCE, CardNames.INHERITANCE, CardNames.STORYTELLER, CardNames.HERBALIST, CardNames.ALCHEMIST, CardNames.DONATE, CardNames.MOUNTAIN_PASS, CardNames.INNOVATION, CardNames.HIGHWAY, CardNames.HAGGLER, CardNames.FARMLAND];

        var hasHidden = false;
        var isPregame = function isPregame() {
            return game.questionModel.openQuestions.length > 0 && game.questionModel.openQuestions[0].story.questionId === QuestionIds.START_GAME;
        };

        var shouldShowCard = function shouldShowCard(c) {
            return recentErrataCards.includes(c.cardName) && c.pileName !== CardNames.BLACK_MARKET_PILE;
        };

        self.shouldShow = function () {
            return false;
        };
        // self.shouldShow = () => game.state.cards.some(shouldShowCard)
        //     && !isUndefined(game.playerModel.playerMe)
        //     && !hasHidden
        //     && isPregame()
        //     && preferences.getUserPrefValue(UserPrefIds.SHOW_REWORDING_2022_WARNING);

        self.hide = function () {
            hasHidden = true;
            if (isPregame()) game.questionModel.openQuestions[0].answer(0);
        };
        self.hideForever = function () {
            self.hide();
            gameOptions.hideErrataWarning();
        };

        self.getCards = function () {
            var showableCards = game.state.cards.filter(shouldShowCard);
            return recentErrataCards.filter(function (cn) {
                return showableCards.some(function (c) {
                    return c.cardName === cn;
                });
            });
        };

        self.getWindowText = function () {
            return getPhrase(Phrases.WARNING_ERRATA);
        };
        self.getOkayText = function () {
            return getPhrase(Phrases.OK);
        };
        self.getButtonText = function () {
            return getPhrase(Phrases.DONT_SHOW_THIS_MESSAGE_AGAIN);
        };

        self.size = CardSizes.FULL;
        self.isLandscape = function (c) {
            return c.isLandscape();
        };
    }],
    template: "\n            <modal-window ng-if=\"$ctrl.shouldShow()\">\n                <div>{{::$ctrl.getWindowText()}}</div>\n                <div class=\"errata-warning-card-container\">\n                    <div class=\"errata-warning-card\" ng-class=\"{'landscape': $ctrl.isLandscape(c)}\" ng-repeat=\"c in $ctrl.getCards()\">\n                        <anonymous-card card-name=\"c\" card-size=\"$ctrl.size\"></anonymous-card>\n                    </div>\n                </div>\n                <div class=\"button-line\">\n                    <button class=\"lobby-button\" ng-click=\"$ctrl.hide()\">{{::$ctrl.getOkayText()}}</button>\n                    <button class=\"lobby-button\" ng-click=\"$ctrl.hideForever()\">{{::$ctrl.getButtonText()}}</button>\n                </div>\n            </modal-window>\n        "
});