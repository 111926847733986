"use strict";

webclient.service('metaBroadcaster', ["$rootScope", function ($rootScope) {
    var self = this;
    var storedEvents = [];

    var shouldBroadcast = function shouldBroadcast(event) {
        return event === Events.GAME_MODEL_CREATED || event === Events.ALREADY_LOGGED_IN || event === Events.IMPORT_ACCOUNT;
    };

    self.send = function (event) {
        var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

        if (isUndefined(event)) throw new Error("Attempting to broadcast undefined event.");

        if (isLoadingLobby && !shouldBroadcast(event)) {
            storedEvents.push(new StoredEvent(event, data));
        } else {
            $rootScope.$broadcast(event, data);
        }
    };

    self.sendStoredEvents = function () {
        storedEvents.forEach(function (e) {
            return self.send(e.event, e.data);
        });
        storedEvents = [];
        self.send(Events.RESIZE);
    };
}]);

webclient.service('gameBroadcaster', ["$rootScope", function ($rootScope) {
    var self = this;
    var storedEvents = [];

    var shouldBroadcast = function shouldBroadcast(event) {
        return event === Events.GAME_MODEL_CREATED;
    };

    self.send = function (event) {
        var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

        if (isUndefined(event)) {
            console.log(new Error().stack);
            throw new Error("Attempting to broadcast undefined event.");
        }

        if (isLoadingGame && !shouldBroadcast(event)) {
            storedEvents.push(new StoredEvent(event, data));
        } else {
            $rootScope.$broadcast(event, data);
        }
    };

    self.sendStoredEvents = function () {
        storedEvents.forEach(function (e) {
            return self.send(e.event, e.data);
        });
        storedEvents = [];
        self.send(Events.RESIZE);
    };
}]);

function StoredEvent(event, data) {
    var self = this;
    self.event = event;
    self.data = data;
}