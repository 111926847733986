"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PilesStatus = function (_GameStateChange) {
    _inherits(PilesStatus, _GameStateChange);

    function PilesStatus(drawIndex, drawSize, discardIndex, discardSize, topCardId) {
        _classCallCheck(this, PilesStatus);

        var _this = _possibleConstructorReturn(this, (PilesStatus.__proto__ || Object.getPrototypeOf(PilesStatus)).call(this));

        _this.drawIndex = drawIndex;
        _this.drawSize = drawSize;
        _this.discardIndex = discardIndex;
        _this.discardSize = discardSize;
        _this.topCardId = topCardId;
        return _this;
    }

    _createClass(PilesStatus, [{
        key: "execute",
        value: function execute(game, terminator) {
            if (this.discardSize === 0) {
                var discardZone = game.state.zones[this.discardIndex];
                var drawZone = game.state.zones[this.drawIndex];

                discardZone.cardStacks.forEach(function (s) {
                    s.cards.forEach(function (c) {
                        s.removeCard(c);
                        drawZone.addAnonymousCard();
                        drawZone.cardStacks[0].addAnonymousCard();
                    });

                    for (var i = 0; i < s.anonymousCards; i++) {
                        drawZone.addAnonymousCard();
                        drawZone.cardStacks[0].addAnonymousCard();
                    }
                });
                discardZone.cardStacks.forEach(function (s) {
                    return s.destroy();
                });
            }
            if (this.topCardId > -1) {
                var targetCard = game.state.cards[this.topCardId];
                var zone = game.state.zones[this.discardIndex];
                var targetStack = zone.cardStacks.find(function (s) {
                    return s.cards.includes(targetCard);
                });
                if (isUndefined(targetStack)) {
                    targetStack = zone.cardStacks.find(function (s) {
                        return s.anonymousCards > 0;
                    });
                }
                if (!isUndefined(targetStack)) {
                    targetStack.removeCard(targetCard);
                    targetStack.addCard(targetCard);
                }
            }
            terminator();
        }
    }, {
        key: "createAnimation",
        value: function createAnimation() {
            return new PileAffectingAnimation(this, this.drawIndex);
        }
    }], [{
        key: "parse",
        value: function parse(game, reader) {
            var drawIndex = reader.readInt();
            var drawSize = reader.readInt();
            var discardIndex = reader.readInt();
            var discardSize = reader.readInt();
            var topCardId = reader.readInt();
            return new PilesStatus(drawIndex, drawSize, discardIndex, discardSize, topCardId);
        }
    }]);

    return PilesStatus;
}(GameStateChange);