"use strict";

webclient.service('relations', ['$rootScope', 'serverMessenger', 'meta', function ($rootScope, serverMessenger, meta) {
    var self = this;
    var scope = $rootScope.$new(true);
    var relationships = null;
    var selectedTab = RELATION_TABS.FRIENDS;
    var relations = [];

    self.getSelectedTab = function () {
        return selectedTab;
    };
    self.selectTab = function (tab) {
        return selectedTab = tab;
    };

    self.getRelations = function () {
        return relations;
    };

    self.getFriends = function () {
        if (relationships === null) return [];
        return relationships.friends;
    };

    self.getFollowing = function () {
        if (relationships === null) return [];
        return relationships.following;
    };

    self.getFollowers = function () {
        if (relationships === null) return [];
        return relationships.followers;
    };

    self.getBlacklist = function () {
        if (relationships === null) return [];
        return relationships.blacklisted;
    };

    self.hasFriends = function () {
        return relations.length > 0;
    };
    self.hasBlacklist = function () {
        return self.getBlacklist().length > 0;
    };

    self.addFriend = function (username) {
        serverMessenger.changeFriendStatus(new NamedId(-1, username), true, true);
    };

    self.followNamedId = function (namedId) {
        self.removeFromBlacklist(namedId.name);
        relationships.following.push(namedId);
        serverMessenger.changeFriendStatus(namedId, true, true);
    };

    self.unFollow = function (username) {
        relationships.following = relationships.following.filter(function (r) {
            return r.name !== username;
        });
        relationships.friends = relationships.friends.filter(function (r) {
            return r.name !== username;
        });
        serverMessenger.changeFriendStatus(new NamedId(-1, username), false, true);
    };

    self.addToBlacklist = function (username) {
        serverMessenger.changeBlacklistStatus(new NamedId(-1, username), true, true);
    };

    self.addNamedIdToBlacklist = function (namedId) {
        self.unFollow(namedId.name);
        relationships.blacklisted.push(namedId);
        serverMessenger.changeBlacklistStatus(namedId, true, true);
    };

    self.removeFromBlacklist = function (username) {
        relationships.blacklisted = relationships.blacklisted.filter(function (r) {
            return r.name !== username;
        });
        serverMessenger.changeBlacklistStatus(new NamedId(-1, username), false, true);
    };

    self.getImageName = function (relation) {
        var status = relation.relationStatus;
        if (status === RelationStati.FRIEND) {
            return "heart-small";
        } else if (status === RelationStati.FOLLOWING) {
            return "heart-small-left";
        } else {
            return "heart-small-right";
        }
    };

    self.getTooltipText = function (relation) {
        var status = relation.relationStatus;
        if (status === RelationStati.FRIEND) {
            return getPhrase(Phrases.FRIENDS_EXPLANATION);
        } else if (status === RelationStati.FOLLOWER) {
            return getPhrase(Phrases.FOLLOWERS_EXPLANATION);
        } else if (status === RelationStati.FOLLOWING) {
            return getPhrase(Phrases.FOLLOWING_EXPLANATION);
        } else {
            throw new Error("Unknown relationship status.");
        }
    };

    self.hasRelation = function (id) {
        return relations.some(function (r) {
            return r.getId() === id;
        });
    };
    self.getRelation = function (id) {
        var relation = relations.find(function (r) {
            return r.getId() === id;
        });
        if (isUndefined(relation)) throw new Error("No relationship can be found.");
        return relation;
    };

    self.isBlacklisted = function (id) {
        return relationships.blacklisted.some(function (r) {
            return r.id === id;
        });
    };
    self.isFollowing = function (id) {
        return relationships.following.some(function (r) {
            return r.id === id;
        }) || relationships.friends.some(function (r) {
            return r.id === id;
        });
    };
    self.isFollowed = function (id) {
        return relationships.followers.some(function (r) {
            return r.id === id;
        }) || relationships.friends.some(function (r) {
            return r.id === id;
        });
    };

    function processRelationshipData() {
        relations = [];
        relationships.friends.forEach(function (f) {
            return relations.push(new Relation(f, RelationStati.FRIEND));
        });
        relationships.followers.forEach(function (f) {
            return relations.push(new Relation(f, RelationStati.FOLLOWER));
        });
        relationships.following.forEach(function (f) {
            return relations.push(new Relation(f, RelationStati.FOLLOWING));
        });
        relations.sort(function (a, b) {
            return a.namedId.name.localeCompare(b.namedId.name);
        });
    }

    scope.$on(Events.RELATIONSHIPS, function (event, data) {
        relationships = data;
        processRelationshipData();
        $rootScope.safeApply();
    });
}]);