"use strict";

var EsperantoCardNames = {};

EsperantoCardNames[CardNames.BACK] = new TranslatedCardName("karto", "kartojn", "karton");
EsperantoCardNames[CardNames.CURSE] = new TranslatedCardName("Malbeno", "Malbenojn", "Malbenon");
EsperantoCardNames[CardNames.COPPER] = new TranslatedCardName("Kupro", "Kuprojn", "Kupron");
EsperantoCardNames[CardNames.SILVER] = new TranslatedCardName("Arĝento", "Arĝentojn", "Arĝenton");
EsperantoCardNames[CardNames.GOLD] = new TranslatedCardName("Oro", "Orojn", "Oron");
EsperantoCardNames[CardNames.ESTATE] = new TranslatedCardName("Bieno", "Bienojn", "Bienon");
EsperantoCardNames[CardNames.DUCHY] = new TranslatedCardName("Duklando", "Duklandojn", "Duklandon");
EsperantoCardNames[CardNames.PROVINCE] = new TranslatedCardName("Provinco", "Provincojn", "Provincon");
EsperantoCardNames[CardNames.ARTISAN] = new TranslatedCardName("Metiisto", "Metiistojn", "Metiiston");
EsperantoCardNames[CardNames.BANDIT] = new TranslatedCardName("Bandito", "Banditojn", "Banditon");
EsperantoCardNames[CardNames.BUREAUCRAT] = new TranslatedCardName("Burokrato", "Burokratojn", "Burokraton");
EsperantoCardNames[CardNames.CELLAR] = new TranslatedCardName("Kelo", "Kelojn", "Kelon");
EsperantoCardNames[CardNames.CHAPEL] = new TranslatedCardName("Kapelo", "Kapelojn", "Kapelon");
EsperantoCardNames[CardNames.COUNCIL_ROOM] = new TranslatedCardName("Konsiliejo", "Konsiliejojn", "Konsiliejon");
EsperantoCardNames[CardNames.FESTIVAL] = new TranslatedCardName("Festo", "Festojn", "Feston");
EsperantoCardNames[CardNames.GARDENS] = new TranslatedCardName("Ĝardenoj", "Ĝardenojn", "Ĝardenojn");
EsperantoCardNames[CardNames.HARBINGER] = new TranslatedCardName("Antaŭanoncanto", "Antaŭanoncantojn", "Antaŭanoncanton");
EsperantoCardNames[CardNames.LABORATORY] = new TranslatedCardName("Laboratorio", "Laboratoriojn", "Laboratorion");
EsperantoCardNames[CardNames.LIBRARY] = new TranslatedCardName("Libraro", "Librarojn", "Libraron");
EsperantoCardNames[CardNames.MARKET] = new TranslatedCardName("Merkato", "Merkatojn", "Merkaton");
EsperantoCardNames[CardNames.MERCHANT] = new TranslatedCardName("Komercisto", "Komercistojn", "Komerciston");
EsperantoCardNames[CardNames.MILITIA] = new TranslatedCardName("Milicio", "Miliciojn", "Milicion");
EsperantoCardNames[CardNames.MINE] = new TranslatedCardName("Mino", "Minojn", "Minon");
EsperantoCardNames[CardNames.MOAT] = new TranslatedCardName("Fosaĵo", "Fosaĵojn", "Fosaĵon");
EsperantoCardNames[CardNames.MONEYLENDER] = new TranslatedCardName("Monpruntisto", "Monpruntistojn", "Monpruntiston");
EsperantoCardNames[CardNames.POACHER] = new TranslatedCardName("Ŝtelĉasisto", "Ŝtelĉasistojn", "Ŝtelĉasiston");
EsperantoCardNames[CardNames.REMODEL] = new TranslatedCardName("Aliformo", "Aliformojn", "Aliformon");
EsperantoCardNames[CardNames.SENTRY] = new TranslatedCardName("Gardostaranto", "Gardostarantojn", "Gardostaranton");
EsperantoCardNames[CardNames.SMITHY] = new TranslatedCardName("Forĝejo", "Forĝejojn", "Forĝejon");
EsperantoCardNames[CardNames.THRONE_ROOM] = new TranslatedCardName("Tronoĉambro", "Tronoĉambrojn", "Tronoĉambron");
EsperantoCardNames[CardNames.VASSAL] = new TranslatedCardName("Vasalo", "Vasalojn", "Vasalon");
EsperantoCardNames[CardNames.VILLAGE] = new TranslatedCardName("Vilaĝo", "Vilaĝojn", "Vilaĝon");
EsperantoCardNames[CardNames.WITCH] = new TranslatedCardName("Sorĉistino", "Sorĉistjn", "Sorĉistinon");
EsperantoCardNames[CardNames.WORKSHOP] = new TranslatedCardName("Ateliero", "Atelierojn", "Atelieron");

EsperantoCardNames[CardNames.COURTYARD] = new TranslatedCardName("Korto", "Kortojn", "Korton");
EsperantoCardNames[CardNames.CONSPIRATOR] = new TranslatedCardName("Konspiranto", "Konspirantojn", "Konspiranton");
EsperantoCardNames[CardNames.COURTIER] = new TranslatedCardName("Kortegano", "Korteganojn", "Korteganon");
EsperantoCardNames[CardNames.BARON] = new TranslatedCardName("Barono", "Baronojn", "Baronon");
EsperantoCardNames[CardNames.BRIDGE] = new TranslatedCardName("Ponto", "Pontojn", "Ponton");
EsperantoCardNames[CardNames.DIPLOMAT] = new TranslatedCardName("Diplomato", "Diplomatojn", "Diplomaton");
EsperantoCardNames[CardNames.DUKE] = new TranslatedCardName("Duko", "Dukojn", "Dukon");
EsperantoCardNames[CardNames.FARM] = new TranslatedCardName("Farmo", "Farmoj", "Farmon");
EsperantoCardNames[CardNames.NOBLES] = new TranslatedCardName("Nobeloj", "Nobelojn", "unu Nobelojn");
EsperantoCardNames[CardNames.IRONWORKS] = new TranslatedCardName("Ferfandejo", "Ferfandejojn", "Ferfandejon");
EsperantoCardNames[CardNames.LURKER] = new TranslatedCardName("Kaŭranto", "Kaŭrantojn", "Kaŭranton");
EsperantoCardNames[CardNames.MASQUERADE] = new TranslatedCardName("Maskerado", "Maskeradojn", "Maskeradon");
EsperantoCardNames[CardNames.MILL] = new TranslatedCardName("Muelejo", "Muelejojn", "Muelejon");
EsperantoCardNames[CardNames.MINING_VILLAGE] = new TranslatedCardName("Minanta Vilaĝo", "Minantajn Vilaĝojn", "Minantan Vilaĝon");
EsperantoCardNames[CardNames.MINION] = new TranslatedCardName("Sbiro", "Sbirojn", "Sbiron");
EsperantoCardNames[CardNames.PATROL] = new TranslatedCardName("Patrolo", "Patrolojn", "Patrolon");
EsperantoCardNames[CardNames.PAWN] = new TranslatedCardName("Peono", "Peonojn", "Peonon");
EsperantoCardNames[CardNames.REPLACE] = new TranslatedCardName("Anstataŭo", "Anstataŭojn", "Anstataŭon");
EsperantoCardNames[CardNames.SECRET_PASSAGE] = new TranslatedCardName("Sekreta Pasejo", "Sekretajn Pasejojn", "Sekretan Pasejon");
EsperantoCardNames[CardNames.SHANTY_TOWN] = new TranslatedCardName("Ladurbo", "Ladurbojn", "Ladurbon");
EsperantoCardNames[CardNames.STEWARD] = new TranslatedCardName("Intendanto", "Intendantojn", "Intendanton");
EsperantoCardNames[CardNames.SWINDLER] = new TranslatedCardName("Komercistaĉo", "Komercistaĉojn", "Komercistaĉon");
EsperantoCardNames[CardNames.TORTURER] = new TranslatedCardName("Torturisto", "Torturistojn", "Torturiston");
EsperantoCardNames[CardNames.TRADING_POST] = new TranslatedCardName("Komerca Posteno", "Komercajn Postenojn", "Komercan Postenon");
EsperantoCardNames[CardNames.UPGRADE] = new TranslatedCardName("Altgradigo", "Altgradigojn", "Altgradigon");
EsperantoCardNames[CardNames.WISHING_WELL] = new TranslatedCardName("Dezirputo", "Dezirputojn", "Dezirputon");

EsperantoCardNames[CardNames.AMBASSADOR] = new TranslatedCardName("Ambasadoro", "Ambasadorojn", "Ambasadoron");
EsperantoCardNames[CardNames.BAZAAR] = new TranslatedCardName("Bazaro", "Bazarojn", "Bazaron");
EsperantoCardNames[CardNames.CARAVAN] = new TranslatedCardName("Karavano", "Karavanojn", "Karavanon");
EsperantoCardNames[CardNames.CUTPURSE] = new TranslatedCardName("Poŝoŝtelisto", "Poŝoŝtelistojn", "Poŝoŝteliston");
EsperantoCardNames[CardNames.EMBARGO] = new TranslatedCardName("Embargo", "Embargojn", "Embargon");
EsperantoCardNames[CardNames.EXPLORER] = new TranslatedCardName("Esploristo", "Esploristojn", "Esploriston");
EsperantoCardNames[CardNames.FISHING_VILLAGE] = new TranslatedCardName("Fiŝkaptista Vilaĝo", "Fiŝkaptistajn Vilaĝojn", "Fiŝkaptistan Vilaĝon");
EsperantoCardNames[CardNames.GHOST_SHIP] = new TranslatedCardName("Fantoma Ŝipo", "Fantomajn Ŝipojn", "Fantoman Ŝipon");
EsperantoCardNames[CardNames.HAVEN] = new TranslatedCardName("Haveno", "Havenojn", "Havenon");
EsperantoCardNames[CardNames.ISLAND] = new TranslatedCardName("Insulo", "Insulojn", "Insulon");
EsperantoCardNames[CardNames.LIGHTHOUSE] = new TranslatedCardName("Lumturo", "Lumturojn", "Lumturon");
EsperantoCardNames[CardNames.LOOKOUT] = new TranslatedCardName("Gvatisto", "Gvatistojn", "Gvatiston");
EsperantoCardNames[CardNames.MERCHANT_SHIP] = new TranslatedCardName("Komerca Ŝipo", "Komercajn Ŝipojn", "Komercan Ŝipon");
EsperantoCardNames[CardNames.NATIVE_VILLAGE] = new TranslatedCardName("Indiĝena Vilaĝo", "Indiĝenajn Vilaĝojn", "Indiĝenan Vilaĝon");
EsperantoCardNames[CardNames.NAVIGATOR] = new TranslatedCardName("Navigisto", "Navigistojn", "Navigiston");
EsperantoCardNames[CardNames.OUTPOST] = new TranslatedCardName("Antaŭposteno", "Antaŭpostenojn", "Antaŭpostenon");
EsperantoCardNames[CardNames.PEARL_DIVER] = new TranslatedCardName("Perla Plonĝisto", "Perlajn Plonĝistojn", "Perlan Plonĝiston");
EsperantoCardNames[CardNames.PIRATE_SHIP] = new TranslatedCardName("Pirata Ŝipo", "Piratajn Ŝipojn", "Piratan Ŝipon");
EsperantoCardNames[CardNames.SALVAGER] = new TranslatedCardName("Savisto", "Savistojn", "Saviston");
EsperantoCardNames[CardNames.SEA_HAG] = new TranslatedCardName("Mara Virinaĉo", "Marajn Virinaĉojn", "Maran Virinaĉon");
EsperantoCardNames[CardNames.SMUGGLERS] = new TranslatedCardName("Kontrabandistoj", "Kontrabandistojn", "Kontrabandistojn");
EsperantoCardNames[CardNames.TACTICIAN] = new TranslatedCardName("Taktikisto", "Taktikistojn", "Taktikiston");
EsperantoCardNames[CardNames.TREASURE_MAP] = new TranslatedCardName("Trezormapo", "Trezormapojn", "Trezormapon");
EsperantoCardNames[CardNames.TREASURY] = new TranslatedCardName("Trezorejo", "Trezorejojn", "Trezorejon");
EsperantoCardNames[CardNames.WAREHOUSE] = new TranslatedCardName("Magazeno", "Magazenojn", "Magazenon");
EsperantoCardNames[CardNames.WHARF] = new TranslatedCardName("Varfo", "Varfojn", "Varfon");

EsperantoCardNames[CardNames.ALCHEMIST] = new TranslatedCardName("Alĥemiisto", "Alĥemiistojn", "Alĥemiiston");
EsperantoCardNames[CardNames.APOTHECARY] = new TranslatedCardName("Apotekisto", "Apotekistojn", "Apotekiston");
EsperantoCardNames[CardNames.APPRENTICE] = new TranslatedCardName("Metilernanto", "Metilernantojn", "Metilernanton");
EsperantoCardNames[CardNames.FAMILIAR] = new TranslatedCardName("Familiara Spirito", "Familiarajn Spiritojn", "Familiaran Spiriton");
EsperantoCardNames[CardNames.GOLEM] = new TranslatedCardName("Golemo", "Golemojn", "Golemon");
EsperantoCardNames[CardNames.HERBALIST] = new TranslatedCardName("Herbisto", "Herbistojn", "Herbiston");
EsperantoCardNames[CardNames.PHILOSOPHERS_STONE] = new TranslatedCardName("Ŝtono de la Saĝuloj", "Ŝtonojn de la Saĝuloj", "Ŝtonon de la Saĝuloj");
EsperantoCardNames[CardNames.POSSESSION] = new TranslatedCardName("Posedaĵo", "Posedaĵojn", "Posedaĵon");
EsperantoCardNames[CardNames.POTION] = new TranslatedCardName("Eliksiro", "Eliksirojn", "Eliksiron");
EsperantoCardNames[CardNames.SCRYING_POOL] = new TranslatedCardName("Antaŭvidanta Baseno", "Antaŭvidantajn Basenojn", "Antaŭvidantan Basenon");
EsperantoCardNames[CardNames.TRANSMUTE] = new TranslatedCardName("Transmutacio", "Transmutaciojn", "Transmutacion");
EsperantoCardNames[CardNames.UNIVERSITY] = new TranslatedCardName("Universitato", "Universitatojn", "Universitaton");
EsperantoCardNames[CardNames.VINEYARD] = new TranslatedCardName("Vitejo", "Vitejojn", "Vitejon");

EsperantoCardNames[CardNames.BANK] = new TranslatedCardName("Banko", "Bankojn", "Bankon");
EsperantoCardNames[CardNames.BISHOP] = new TranslatedCardName("Episkopo", "Episkopojn", "Episkopon");
EsperantoCardNames[CardNames.COLONY] = new TranslatedCardName("Kolonio", "Koloniojn", "Kolonion");
EsperantoCardNames[CardNames.CONTRABAND] = new TranslatedCardName("Kontrabando", "Kontrabandojn", "Kontrabandon");
EsperantoCardNames[CardNames.COUNTING_HOUSE] = new TranslatedCardName("Kontoro", "Kontorojn", "Kontoron");
EsperantoCardNames[CardNames.CITY] = new TranslatedCardName("Urbo", "Urbojn", "Urbon");
EsperantoCardNames[CardNames.EXPAND] = new TranslatedCardName("Etendo", "Etendojn", "Etendon");
EsperantoCardNames[CardNames.FORGE] = new TranslatedCardName("Forĝo", "Forĝojn", "Forĝon");
EsperantoCardNames[CardNames.GRAND_MARKET] = new TranslatedCardName("Granda Merkato", "Grandajn Merkatojn", "Grandan Merkaton");
EsperantoCardNames[CardNames.GOONS] = new TranslatedCardName("Brutuloj", "Brutulojn", "Brutulojn");
EsperantoCardNames[CardNames.HOARD] = new TranslatedCardName("Kaŝamaso", "Kaŝamasojn", "Kaŝamason");
EsperantoCardNames[CardNames.KINGS_COURT] = new TranslatedCardName("Kortego de Reĝo", "Kortegojn de Reĝo", "Kortegon de Reĝo");
EsperantoCardNames[CardNames.LOAN] = new TranslatedCardName("Prunto", "Pruntojn", "Prunton");
EsperantoCardNames[CardNames.MINT] = new TranslatedCardName("Monfarejo", "Monfarejojn", "Monfarejon");
EsperantoCardNames[CardNames.MONUMENT] = new TranslatedCardName("Monumento", "Monumentojn", "Monumenton");
EsperantoCardNames[CardNames.MOUNTEBANK] = new TranslatedCardName("Trompulo", "Trompulon", "Trompulon");
EsperantoCardNames[CardNames.PEDDLER] = new TranslatedCardName("Kolportisto", "Kolportistojn", "Kolportiston");
EsperantoCardNames[CardNames.PLATINUM] = new TranslatedCardName("Plateno", "Platenojn", "Platenon");
EsperantoCardNames[CardNames.QUARRY] = new TranslatedCardName("Ŝtonejo", "Ŝtonejojn", "Ŝtonejon");
EsperantoCardNames[CardNames.RABBLE] = new TranslatedCardName("Homamaso", "Homamasojn", "Homamason");
EsperantoCardNames[CardNames.ROYAL_SEAL] = new TranslatedCardName("Reĝa Sigelo", "Reĝajn Sigelojn", "Reĝan Sigelon");
EsperantoCardNames[CardNames.TALISMAN] = new TranslatedCardName("Talismano", "Talismanojn", "Talismanon");
EsperantoCardNames[CardNames.TRADE_ROUTE] = new TranslatedCardName("Komerca Vojo", "Komercajn Vojojn", "Komercan Vojon");
EsperantoCardNames[CardNames.VAULT] = new TranslatedCardName("Sekurĉambro", "Sekurĉambrojn", "Sekurĉambron");
EsperantoCardNames[CardNames.VENTURE] = new TranslatedCardName("Enterpreno", "Enterprenojn", "Enterprenon");
EsperantoCardNames[CardNames.WATCHTOWER] = new TranslatedCardName("Gardoturo", "Gardoturojn", "Gardoturon");
EsperantoCardNames[CardNames.WORKERS_VILLAGE] = new TranslatedCardName("Vilaĝo de Laboristoj", "Vilaĝojn de Laboristoj", "Vilaĝon de Laboristoj");

EsperantoCardNames[CardNames.PRIZE_PILE] = new TranslatedCardName("Premio", "Premio", "Premio");
EsperantoCardNames[CardNames.BAG_OF_GOLD] = new TranslatedCardName("Sako da Oro", "Sakojn da Oro", "Sakon da Oro");
EsperantoCardNames[CardNames.DIADEM] = new TranslatedCardName("Diademo", "Diademojn", "Diademon");
EsperantoCardNames[CardNames.FAIRGROUNDS] = new TranslatedCardName("Foirejo", "Foirejojn", "Foirejon");
EsperantoCardNames[CardNames.FARMING_VILLAGE] = new TranslatedCardName("Farmista Vilaĝo", "Farmistajn Vilaĝojn", "Farmistan Vilaĝon");
EsperantoCardNames[CardNames.FOLLOWERS] = new TranslatedCardName("Sekvantoj", "Sekvantojn", "Sekvantojn");
EsperantoCardNames[CardNames.FORTUNE_TELLER] = new TranslatedCardName("Antaŭdiristo", "Antaŭdiristojn", "Antaŭdiriston");
EsperantoCardNames[CardNames.HAMLET] = new TranslatedCardName("Vilaĝeto", "Vilaĝetojn", "Vilaĝeton");
EsperantoCardNames[CardNames.HARVEST] = new TranslatedCardName("Rikolto", "Rikoltojn", "Rikolton");
EsperantoCardNames[CardNames.HORSE_TRADERS] = new TranslatedCardName("Ĉevalkomercistoj", "Ĉevalkomercistojn", "Ĉevalkomercistojn");
EsperantoCardNames[CardNames.HORN_OF_PLENTY] = new TranslatedCardName("Korno de Abundo", "Kornojn de Abundo", "Kornon de Abundo");
EsperantoCardNames[CardNames.HUNTING_PARTY] = new TranslatedCardName("Ĉasado", "Ĉasadojn", "Ĉasadon");
EsperantoCardNames[CardNames.JESTER] = new TranslatedCardName("Bufono", "Bufonojn", "Bufonon");
EsperantoCardNames[CardNames.MENAGERIE] = new TranslatedCardName("Menaĝerio", "Menaĝeriojn", "Menaĝerion");
EsperantoCardNames[CardNames.PRINCESS] = new TranslatedCardName("Princino", "Princinojn", "Princinon");
EsperantoCardNames[CardNames.REMAKE] = new TranslatedCardName("Refaro", "Refarojn", "Refaron");
EsperantoCardNames[CardNames.TOURNAMENT] = new TranslatedCardName("Turniro", "Turnirojn", "Turniron");
EsperantoCardNames[CardNames.TRUSTY_STEED] = new TranslatedCardName("Lojala Ĉevalo", "Lojalajn Ĉevalojn", "Lojalan Ĉevalon");
EsperantoCardNames[CardNames.YOUNG_WITCH] = new TranslatedCardName("Juna Sorĉistino", "Junajn Sorĉistinojn", "Junan Sorĉistinon");

EsperantoCardNames[CardNames.BORDER_VILLAGE] = new TranslatedCardName("Lima Vilaĝo", "Limajn Vilaĝojn", "Liman Vilaĝon");
EsperantoCardNames[CardNames.CACHE] = new TranslatedCardName("Kaŝaĵo", "Kaŝaĵojn", "Kaŝaĵon");
EsperantoCardNames[CardNames.CARTOGRAPHER] = new TranslatedCardName("Kartografo", "Kartografojn", "Kartografon");
EsperantoCardNames[CardNames.CROSSROADS] = new TranslatedCardName("Vojkruciĝo", "Vojkruciĝojn", "Vojkruciĝon");
EsperantoCardNames[CardNames.DEVELOP] = new TranslatedCardName("Disvolvo", "Disvolvojn", "Disvolvon");
EsperantoCardNames[CardNames.DUCHESS] = new TranslatedCardName("Dukino", "Dukinojn", "Dukinon");
EsperantoCardNames[CardNames.EMBASSY] = new TranslatedCardName("Ambasado", "Ambasadojn", "Ambasadon");
EsperantoCardNames[CardNames.FARMLAND] = new TranslatedCardName("Agro", "Agrojn", "Agron");
EsperantoCardNames[CardNames.FOOLS_GOLD] = new TranslatedCardName("Malsaĝa Oro", "Malsaĝajn Orojn", "Malsaĝan Oron");
EsperantoCardNames[CardNames.HAGGLER] = new TranslatedCardName("Marĉandanto", "Marĉandantojn", "Marĉandanton");
EsperantoCardNames[CardNames.HIGHWAY] = new TranslatedCardName("Ŝoseo", "Ŝoseojn", "Ŝoseon");
EsperantoCardNames[CardNames.ILL_GOTTEN_GAINS] = new TranslatedCardName("Prezo de Sango", "Prezojn de Sango", "Prezon de Sango");
EsperantoCardNames[CardNames.INN] = new TranslatedCardName("Albergo", "Albergojn", "Albergon");
EsperantoCardNames[CardNames.JACK_OF_ALL_TRADES] = new TranslatedCardName("Ĉiofarulo", "Ĉiofarulojn", "Ĉiofarulon");
EsperantoCardNames[CardNames.MANDARIN] = new TranslatedCardName("Mandareno", "Mandarenojn", "Mandarenon");
EsperantoCardNames[CardNames.NOBLE_BRIGAND] = new TranslatedCardName("Nobla Bandito", "Noblajn Banditojn", "Noblan Banditon");
EsperantoCardNames[CardNames.NOMAD_CAMP] = new TranslatedCardName("Nomada Tendaro", "Nomadajn Tendarojn", "Nomadan Tendaron");
EsperantoCardNames[CardNames.OASIS] = new TranslatedCardName("Oazo", "Oazojn", "Oazon");
EsperantoCardNames[CardNames.ORACLE] = new TranslatedCardName("Orakolo", "Orakolojn", "Orakolon");
EsperantoCardNames[CardNames.MARGRAVE] = new TranslatedCardName("Margrafo", "Margrafojn", "Margrafon");
EsperantoCardNames[CardNames.SCHEME] = new TranslatedCardName("Komploto", "Komplotojn", "Komploton");
EsperantoCardNames[CardNames.SILK_ROAD] = new TranslatedCardName("Silka Vojo", "Silkajn Vojojn", "Silkan Vojon");
EsperantoCardNames[CardNames.SPICE_MERCHANT] = new TranslatedCardName("Spickomercisto", "Spickomercistojn", "Spickomerciston");
EsperantoCardNames[CardNames.STABLES] = new TranslatedCardName("Ĉevalejo", "Ĉevalejojn", "Ĉevalejon");
EsperantoCardNames[CardNames.TRADER] = new TranslatedCardName("Vendisto", "Vendistojn", "Vendiston");
EsperantoCardNames[CardNames.TUNNEL] = new TranslatedCardName("Tunelo", "Tunelojn", "Tunelon");

EsperantoCardNames[CardNames.RUIN_PILE] = new TranslatedCardName("Ruino", "Ruinojn", "Ruinon");
EsperantoCardNames[CardNames.KNIGHTS] = new TranslatedCardName("Kavaliroj", "Kavalirojn", "Kavalirojn");
EsperantoCardNames[CardNames.ABANDONED_MINE] = new TranslatedCardName("Forlasita Mino", "Forlasitajn Minojn", "Forlasitan Minon");
EsperantoCardNames[CardNames.ALTAR] = new TranslatedCardName("Altaro", "Altarojn", "Altaron");
EsperantoCardNames[CardNames.ARMORY] = new TranslatedCardName("Armilejo", "Armilejojn", "Armilejon");
EsperantoCardNames[CardNames.BAND_OF_MISFITS] = new TranslatedCardName("Malsocietemularo", "Malsocietemularojn", "Malsocietemularon");
EsperantoCardNames[CardNames.BANDIT_CAMP] = new TranslatedCardName("Bandita Tendaro", "Banditajn Tendarojn", "Banditan Tendaron");
EsperantoCardNames[CardNames.BEGGAR] = new TranslatedCardName("Almozulo", "Almozulojn", "Almozulon");
EsperantoCardNames[CardNames.CATACOMBS] = new TranslatedCardName("Katakomboj", "Katakombojn", "Katakombojn");
EsperantoCardNames[CardNames.COUNT] = new TranslatedCardName("Grafo", "Grafojn", "Grafon");
EsperantoCardNames[CardNames.COUNTERFEIT] = new TranslatedCardName("Falsado", "Falsadojn", "Falsadon");
EsperantoCardNames[CardNames.CULTIST] = new TranslatedCardName("Sektano", "Sektanojn", "Sektanon");
EsperantoCardNames[CardNames.DAME_ANNA] = new TranslatedCardName("Sinjorino Anna", "Sinjorinojn Anna", "Sinjorinon Anna");
EsperantoCardNames[CardNames.DAME_JOSEPHINE] = new TranslatedCardName("Sinjorino Josephine", "Sinjorinojn Josephine", "Sinjorinon Josephine");
EsperantoCardNames[CardNames.DAME_MOLLY] = new TranslatedCardName("Sinjorino Molly", "Sinjorinojn Molly", "Sinjorinon Molly");
EsperantoCardNames[CardNames.DAME_NATALIE] = new TranslatedCardName("Sinjorino Natalie", "Sinjorinojn Natalie", "Sinjorinon Natalie");
EsperantoCardNames[CardNames.DAME_SYLVIA] = new TranslatedCardName("Sinjorino Sylvia", "Sinjorinojn Sylvia", "Sinjorinon Sylvia");
EsperantoCardNames[CardNames.DEATH_CART] = new TranslatedCardName("Kadavroĉaro", "Kadavroĉarojn", "Kadavroĉaron");
EsperantoCardNames[CardNames.FEODUM] = new TranslatedCardName("Feŭdo", "Feŭdojn", "Feŭdon");
EsperantoCardNames[CardNames.FORAGER] = new TranslatedCardName("Furaĝanto", "Furaĝantojn", "Furaĝanton");
EsperantoCardNames[CardNames.FORTRESS] = new TranslatedCardName("Fortikaĵo", "Fortikaĵojn", "Fortikaĵon");
EsperantoCardNames[CardNames.GRAVEROBBER] = new TranslatedCardName("Tomborabisto", "Tomborabistojn", "Tomborabiston");
EsperantoCardNames[CardNames.HERMIT] = new TranslatedCardName("Ermito", "Ermitojn", "Ermiton");
EsperantoCardNames[CardNames.HOVEL] = new TranslatedCardName("Domaĉo", "Domaĉojn", "Domaĉon");
EsperantoCardNames[CardNames.HUNTING_GROUNDS] = new TranslatedCardName("Ĉasejo", "Ĉasejojn", "Ĉasejon");
EsperantoCardNames[CardNames.IRONMONGER] = new TranslatedCardName("Feraĵisto", "Feraĵistojn", "Feraĵiston");
EsperantoCardNames[CardNames.JUNK_DEALER] = new TranslatedCardName("Brokantisto", "Brokantistojn", "Brokantiston");
EsperantoCardNames[CardNames.MADMAN] = new TranslatedCardName("Frenezulo", "Frenezulojn", "Frenezulon");
EsperantoCardNames[CardNames.MARKET_SQUARE] = new TranslatedCardName("Bazarejo", "Bazarejojn", "Bazarejon");
EsperantoCardNames[CardNames.MARAUDER] = new TranslatedCardName("Rabisto", "Rabistojn", "Rabiston");
EsperantoCardNames[CardNames.MERCENARY] = new TranslatedCardName("Soldulo", "Soldulojn", "Soldulon");
EsperantoCardNames[CardNames.MYSTIC] = new TranslatedCardName("Mistikulo", "Mistikulojn", "Mistikulon");
EsperantoCardNames[CardNames.NECROPOLIS] = new TranslatedCardName("Nekropolo", "Nekropolojn", "Nekropolon");
EsperantoCardNames[CardNames.OVERGROWN_ESTATE] = new TranslatedCardName("Surkreskata Bieno", "Surkreskatajn Bienojn", "Surkreskatan Bienon");
EsperantoCardNames[CardNames.PILLAGE] = new TranslatedCardName("Forŝtelo", "Forŝtelojn", "Forŝtelon");
EsperantoCardNames[CardNames.POOR_HOUSE] = new TranslatedCardName("Malriĉulejo", "Malriĉulejojn", "Malriĉulejon");
EsperantoCardNames[CardNames.PROCESSION] = new TranslatedCardName("Procesio", "Procesiojn", "Procesion");
EsperantoCardNames[CardNames.RATS] = new TranslatedCardName("Ratoj", "Ratojn", "Ratojn");
EsperantoCardNames[CardNames.REBUILD] = new TranslatedCardName("Rekonstruo", "Rekonstruojn", "Rekonstruon");
EsperantoCardNames[CardNames.ROGUE] = new TranslatedCardName("Fripono", "Friponojn", "Friponon");
EsperantoCardNames[CardNames.RUINED_LIBRARY] = new TranslatedCardName("Ruina Libraro", "Ruinajn Librarojn", "Ruinan Libraron");
EsperantoCardNames[CardNames.RUINED_MARKET] = new TranslatedCardName("Ruina Merkato", "Ruinajn Merkatojn", "Ruinan Merkaton");
EsperantoCardNames[CardNames.RUINED_VILLAGE] = new TranslatedCardName("Ruina Vilaĝo", "Ruinajn Vilaĝojn", "Ruinan Vilaĝon");
EsperantoCardNames[CardNames.SAGE] = new TranslatedCardName("Saĝulo", "Saĝulojn", "Saĝulon");
EsperantoCardNames[CardNames.SCAVENGER] = new TranslatedCardName("Stratbalaisto", "Stratbalaistojn", "Stratbalaiston");
EsperantoCardNames[CardNames.SIR_BAILEY] = new TranslatedCardName("Sinjoro Bailey", "Sinjorojn Bailey", "Sinjoron Bailey");
EsperantoCardNames[CardNames.SIR_DESTRY] = new TranslatedCardName("Sinjoro Destry", "Sinjorojn Destry", "Sinjoron Destry");
EsperantoCardNames[CardNames.SIR_MARTIN] = new TranslatedCardName("Sinjoro Martin", "Sinjorojn Martin", "Sinjoron Martin");
EsperantoCardNames[CardNames.SIR_MICHAEL] = new TranslatedCardName("Sinjoro Michael", "Sinjorojn Michael", "Sinjoron Michael");
EsperantoCardNames[CardNames.SIR_VANDER] = new TranslatedCardName("Sinjoro Vander", "Sinjorojn Vander", "Sinjoron Vander");
EsperantoCardNames[CardNames.SPOILS] = new TranslatedCardName("Rabaĵo", "Rabaĵojn", "Rabaĵon");
EsperantoCardNames[CardNames.STOREROOM] = new TranslatedCardName("Provizejo", "Provizejojn", "Provizejon");
EsperantoCardNames[CardNames.SQUIRE] = new TranslatedCardName("Eskviro", "Eskvirojn", "Eskvirojn");
EsperantoCardNames[CardNames.SURVIVORS] = new TranslatedCardName("Postvivantoj", "Postvivantojn", "Postvivantojn");
EsperantoCardNames[CardNames.URCHIN] = new TranslatedCardName("Bubo", "Bubojn", "Bubon");
EsperantoCardNames[CardNames.VAGRANT] = new TranslatedCardName("Vaganto", "Vagantojn", "Vaganton");
EsperantoCardNames[CardNames.WANDERING_MINSTREL] = new TranslatedCardName("Vaganta Menestrelo", "Vagantajn Menestrelojn", "Vagantan Menestrelon");

EsperantoCardNames[CardNames.ADVISOR] = new TranslatedCardName("Konsilisto", "Konsilistojn", "Konsiliston");
EsperantoCardNames[CardNames.BAKER] = new TranslatedCardName("Panisto", "Panistojn", "Paniston");
EsperantoCardNames[CardNames.BUTCHER] = new TranslatedCardName("Buĉisto", "Buĉistojn", "Buĉiston");
EsperantoCardNames[CardNames.CANDLESTICK_MAKER] = new TranslatedCardName("Kandelingfaristo", "Kandelingfaristojn", "Kandelingfariston");
EsperantoCardNames[CardNames.DOCTOR] = new TranslatedCardName("Kuracisto", "Kuracistojn", "Kuraciston");
EsperantoCardNames[CardNames.HERALD] = new TranslatedCardName("Heroldo", "Heroldojn", "Heroldon");
EsperantoCardNames[CardNames.JOURNEYMAN] = new TranslatedCardName("Submajstro", "Submajstrojn", "Submajstron");
EsperantoCardNames[CardNames.MASTERPIECE] = new TranslatedCardName("Majstraĵo", "Majstraĵojn", "Majstraĵon");
EsperantoCardNames[CardNames.MERCHANT_GUILD] = new TranslatedCardName("Komerca Gildo", "Komercajn Gildojn", "Komercan Gildon");
EsperantoCardNames[CardNames.PLAZA] = new TranslatedCardName("Placo", "Placojn", "Placon");
EsperantoCardNames[CardNames.TAXMAN] = new TranslatedCardName("Impostisto", "Impostistojn", "Impostiston");
EsperantoCardNames[CardNames.SOOTHSAYER] = new TranslatedCardName("Divenisto", "Divenistojn", "Diveniston");
EsperantoCardNames[CardNames.STONEMASON] = new TranslatedCardName("Masonisto", "Masonistojn", "Masoniston");

EsperantoCardNames[CardNames.ALMS] = new TranslatedCardName("Almozo", "Almozojn", "Almozon");
EsperantoCardNames[CardNames.AMULET] = new TranslatedCardName("Amuleto", "Amuletojn", "Amuleton");
EsperantoCardNames[CardNames.ARTIFICER] = new TranslatedCardName("Farinto", "Farintojn", "Farinton");
EsperantoCardNames[CardNames.BALL] = new TranslatedCardName("Balo", "Balojn", "Balon");
EsperantoCardNames[CardNames.BONFIRE] = new TranslatedCardName("Ĝojfajro", "Ĝojfajrojn", "Ĝojfajron");
EsperantoCardNames[CardNames.BORROW] = new TranslatedCardName("Pruntepreno", "Prunteprenojn", "Prunteprenon");
EsperantoCardNames[CardNames.BRIDGE_TROLL] = new TranslatedCardName("Pontotrolo", "Pontotrolojn", "Pontotrolon");
EsperantoCardNames[CardNames.CARAVAN_GUARD] = new TranslatedCardName("Karavangardisto", "Karavangardistojn", "Karavangardiston");
EsperantoCardNames[CardNames.CHAMPION] = new TranslatedCardName("Ĉampiono", "Ĉampionojn", "Ĉampionon");
EsperantoCardNames[CardNames.COIN_OF_THE_REALM] = new TranslatedCardName("Monero de la Regno", "Monerojn de la Regno", "Moneron de la Regno");
EsperantoCardNames[CardNames.DISCIPLE] = new TranslatedCardName("Disĉiplo", "Disĉiplojn", "Disĉiplon");
EsperantoCardNames[CardNames.DISTANT_LANDS] = new TranslatedCardName("Forlandoj", "Forlandojn", "Forlandojn");
EsperantoCardNames[CardNames.DUNGEON] = new TranslatedCardName("Karcero", "Karcerojn", "Karceron");
EsperantoCardNames[CardNames.DUPLICATE] = new TranslatedCardName("Duplikato", "Duplikatojn", "Duplikaton");
EsperantoCardNames[CardNames.EXPEDITION] = new TranslatedCardName("Ekspedicio", "Ekspediciojn", "Ekspedicion");
EsperantoCardNames[CardNames.FERRY] = new TranslatedCardName("Pramŝipo", "Pramŝipojn", "Pramŝipon");
EsperantoCardNames[CardNames.FUGITIVE] = new TranslatedCardName("Fuĝanto", "Fuĝantojn", "Fuĝanto");
EsperantoCardNames[CardNames.GEAR] = new TranslatedCardName("Ekipaĵo", "Ekipaĵojn", "Ekipaĵon");
EsperantoCardNames[CardNames.GIANT] = new TranslatedCardName("Giganto", "Gigantojn", "Giganton");
EsperantoCardNames[CardNames.GUIDE] = new TranslatedCardName("Gvidisto", "Gvidistojn", "Gvidisto");
EsperantoCardNames[CardNames.HAUNTED_WOODS] = new TranslatedCardName("Hantata Arbaro", "Hantatajn Arbarojn", "Hantatan Arbaron");
EsperantoCardNames[CardNames.HERO] = new TranslatedCardName("Heroo", "Heroojn", "Heroon");
EsperantoCardNames[CardNames.HIRELING] = new TranslatedCardName("Salajrulo", "Salajrulojn", "Salajrulo");
EsperantoCardNames[CardNames.INHERITANCE] = new TranslatedCardName("Heredo", "Heredojn", "Heredon");
EsperantoCardNames[CardNames.LOST_ARTS] = new TranslatedCardName("Perditaj Artoj", "Perditajn Artojn", "Perditajn Artojn");
EsperantoCardNames[CardNames.LOST_CITY] = new TranslatedCardName("Perdita Urbo", "Perditajn Urbojn", "Perditan Urbon");
EsperantoCardNames[CardNames.MAGPIE] = new TranslatedCardName("Pigo", "Pigojn", "Pigon");
EsperantoCardNames[CardNames.MESSENGER] = new TranslatedCardName("Mesaĝisto", "Mesaĝistojn", "Mesaĝiston");
EsperantoCardNames[CardNames.MISER] = new TranslatedCardName("Avarulo", "Avarulojn", "Avarulon");
EsperantoCardNames[CardNames.MISSION] = new TranslatedCardName("Misio", "Misiojn", "Mision");
EsperantoCardNames[CardNames.PATHFINDING] = new TranslatedCardName("Vojtrovo", "Vojtrovojn", "Vojtrovon");
EsperantoCardNames[CardNames.PAGE] = new TranslatedCardName("Paĝio", "Paĝiojn", "Paĝion");
EsperantoCardNames[CardNames.PEASANT] = new TranslatedCardName("Kamparano", "Kamparanojn", "Kamparanon");
EsperantoCardNames[CardNames.PILGRIMAGE] = new TranslatedCardName("Pilgrimado", "Pilgrimadojn", "Pilgrimadon");
EsperantoCardNames[CardNames.PLAN] = new TranslatedCardName("Plano", "Planojn", "Planon");
EsperantoCardNames[CardNames.PORT] = new TranslatedCardName("Havenurbo", "Havenurbojn", "Havenurbon");
EsperantoCardNames[CardNames.QUEST] = new TranslatedCardName("Serĉado", "Serĉadojn", "Serĉadon");
EsperantoCardNames[CardNames.RANGER] = new TranslatedCardName("Arbargardisto", "Arbargardistojn", "Arbargardiston");
EsperantoCardNames[CardNames.RAID] = new TranslatedCardName("Marodo", "Marodojn", "Marodon");
EsperantoCardNames[CardNames.RATCATCHER] = new TranslatedCardName("Ratkaptisto", "Ratkaptistojn", "Ratkaptiston");
EsperantoCardNames[CardNames.RAZE] = new TranslatedCardName("Eldetruo", "Eldetruojn", "Eldetruon");
EsperantoCardNames[CardNames.RELIC] = new TranslatedCardName("Relikvo", "Relikvojn", "Relikvon");
EsperantoCardNames[CardNames.ROYAL_CARRIAGE] = new TranslatedCardName("Reĝa Ĉaro", "Reĝajn Ĉarojn", "Reĝan Ĉaron");
EsperantoCardNames[CardNames.SAVE] = new TranslatedCardName("Ŝparo", "Ŝparojn", "Ŝparon");
EsperantoCardNames[CardNames.SCOUTING_PARTY] = new TranslatedCardName("Skoltantaro", "Skoltantarojn", "Skoltantaron");
EsperantoCardNames[CardNames.SEAWAY] = new TranslatedCardName("Marvojo", "Marvojojn", "Marvojon");
EsperantoCardNames[CardNames.SOLDIER] = new TranslatedCardName("Soldato", "Soldatojn", "Soldaton");
EsperantoCardNames[CardNames.STORYTELLER] = new TranslatedCardName("Rakontisto", "Rakontistojn", "Rakontiston");
EsperantoCardNames[CardNames.SWAMP_HAG] = new TranslatedCardName("Marĉa Virinaĉo", "Marĉajn Virinaĉojn", "Marĉan Virinaĉon");
EsperantoCardNames[CardNames.TEACHER] = new TranslatedCardName("Instruisto", "Instruistojn", "Instruiston");
EsperantoCardNames[CardNames.TRAVELLING_FAIR] = new TranslatedCardName("Vaganta Foiro", "Vagantajn Foirojn", "Vagantan Foiron");
EsperantoCardNames[CardNames.TRADE] = new TranslatedCardName("Negoco", "Negocojn", "Negocon");
EsperantoCardNames[CardNames.TRAINING] = new TranslatedCardName("Trejnado", "Trejnadojn", "Trejnadon");
EsperantoCardNames[CardNames.TRANSMOGRIFY] = new TranslatedCardName("Transformo", "Transformojn", "Transformon");
EsperantoCardNames[CardNames.TREASURE_TROVE] = new TranslatedCardName("Trezoraro", "Trezorarojn", "Trezoraron");
EsperantoCardNames[CardNames.TREASURE_HUNTER] = new TranslatedCardName("Trezorserĉanto", "Trezorserĉantojn", "Trezorserĉanton");
EsperantoCardNames[CardNames.WARRIOR] = new TranslatedCardName("Batalanto", "Batalantojn", "Batalanton");
EsperantoCardNames[CardNames.WINE_MERCHANT] = new TranslatedCardName("Vinkomercisto", "Vinkomercistojn", "Vinkomerciston");

EsperantoCardNames[CardNames.ENCAMPMENT] = new TranslatedCardName("Tendaro", "Tendarojn", "Tendaron");
EsperantoCardNames[CardNames.PLUNDER] = new TranslatedCardName("Prirabaĵo", "Prirabaĵojn", "Prirabaĵon");
EsperantoCardNames[CardNames.PATRICIAN] = new TranslatedCardName("Patricio", "Patriciojn", "Patricion");
EsperantoCardNames[CardNames.EMPORIUM] = new TranslatedCardName("Vendoplaco", "Vendoplacojn", "Vendoplacon");
EsperantoCardNames[CardNames.SETTLERS] = new TranslatedCardName("Setlantoj", "Setlantojn", "Setlantojn");
EsperantoCardNames[CardNames.BUSTLING_VILLAGE] = new TranslatedCardName("Movoplena Vilaĝo", "Movoplenajn Vilaĝojn", "Movoplenan Vilaĝon");
EsperantoCardNames[CardNames.CATAPULT] = new TranslatedCardName("Katapulto", "Katapultojn", "Katapulton");
EsperantoCardNames[CardNames.ROCKS] = new TranslatedCardName("Rokoj", "Rokojn", "Rokojn");
EsperantoCardNames[CardNames.GLADIATOR] = new TranslatedCardName("Gladiatoro", "Gladiatorojn", "Gladiatoron");
EsperantoCardNames[CardNames.FORTUNE] = new TranslatedCardName("Riĉaĵo", "Riĉaĵojn", "Riĉaĵon");
EsperantoCardNames[CardNames.CASTLES] = new TranslatedCardName("Kasteloj", "Kastelojn", "Kastelojn");
EsperantoCardNames[CardNames.HUMBLE_CASTLE] = new TranslatedCardName("Modesta Kastelo", "Modestajn Kastelojn", "Modestan Kastelon");
EsperantoCardNames[CardNames.CRUMBLING_CASTLE] = new TranslatedCardName("Kaduka Kastelo", "Kadukajn Kastelojn", "Kadukan Kastelon");
EsperantoCardNames[CardNames.SMALL_CASTLE] = new TranslatedCardName("Malgranda Kastelo", "Malgrandajn Kastelojn", "Malgrandan Kastelon");
EsperantoCardNames[CardNames.HAUNTED_CASTLE] = new TranslatedCardName("Hantata Kastelo", "Hantatajn Kastelojn", "Hantatan Kastelon");
EsperantoCardNames[CardNames.OPULENT_CASTLE] = new TranslatedCardName("Luksa Kastelo", "Luksajn Kastelojn", "Luksan Kastelon");
EsperantoCardNames[CardNames.SPRAWLING_CASTLE] = new TranslatedCardName("Vasta Kastelo", "Vastajn Kastelojn", "Vastan Kastelon");
EsperantoCardNames[CardNames.GRAND_CASTLE] = new TranslatedCardName("Granda Kastelo", "Grandajn Kastelojn", "Grandan Kastelon");
EsperantoCardNames[CardNames.KINGS_CASTLE] = new TranslatedCardName("Reĝa Kastelo", "Reĝajn Kastelojn", "Reĝan Kastelon");
EsperantoCardNames[CardNames.ADVANCE] = new TranslatedCardName("Antaŭenigo", "Antaŭenigojn", "Antaŭenigon");
EsperantoCardNames[CardNames.ANNEX] = new TranslatedCardName("Anekso", "Aneksojn", "Anekson");
EsperantoCardNames[CardNames.ARCHIVE] = new TranslatedCardName("Arkivo", "Arkivojn", "Arkivon");
EsperantoCardNames[CardNames.AQUEDUCT] = new TranslatedCardName("Akvedukto", "Akveduktojn", "Akvedukton");
EsperantoCardNames[CardNames.ARENA] = new TranslatedCardName("Areno", "Arenojn", "Arenon");
EsperantoCardNames[CardNames.BANDIT_FORT] = new TranslatedCardName("Bandita Fortikaĵo", "Banditajn Fortikaĵojn", "Banditan Fortikaĵon");
EsperantoCardNames[CardNames.BANQUET] = new TranslatedCardName("Bankedo", "Bankedojn", "Bankedon");
EsperantoCardNames[CardNames.BASILICA] = new TranslatedCardName("Baziliko", "Bazilikojn", "Bazilikon");
EsperantoCardNames[CardNames.BATHS] = new TranslatedCardName("Banejo", "Banejojn", "Banejon");
EsperantoCardNames[CardNames.BATTLEFIELD] = new TranslatedCardName("Batalkampo", "Batalkampojn", "Batalkampon");
EsperantoCardNames[CardNames.CAPITAL] = new TranslatedCardName("Kapitalo", "Kapitalojn", "Kapitalon");
EsperantoCardNames[CardNames.CHARM] = new TranslatedCardName("Ĉarmo", "Ĉarmojn", "Ĉarmon");
EsperantoCardNames[CardNames.CHARIOT_RACE] = new TranslatedCardName("Ĉara Vetkuro", "Ĉarajn Vetkurojn", "Ĉaran Vetkuron");
EsperantoCardNames[CardNames.CITY_QUARTER] = new TranslatedCardName("Urbokvartalo", "Urbokvartalojn", "Urbokvartalon");
EsperantoCardNames[CardNames.COLONNADE] = new TranslatedCardName("Kolonaro", "Kolonarojn", "Kolonaron");
EsperantoCardNames[CardNames.CONQUEST] = new TranslatedCardName("Konkero", "Konkerojn", "Konkeron");
EsperantoCardNames[CardNames.CROWN] = new TranslatedCardName("Krono", "Kronojn", "Kronon");
EsperantoCardNames[CardNames.DELVE] = new TranslatedCardName("Foso", "Fosojn", "Foson");
EsperantoCardNames[CardNames.DEFILED_SHRINE] = new TranslatedCardName("Profanita Sanktejo", "Profanitajn Sanktejojn", "Profanitan Sanktejon");
EsperantoCardNames[CardNames.DOMINATE] = new TranslatedCardName("Superrego", "Superregojn", "Superregon");
EsperantoCardNames[CardNames.DONATE] = new TranslatedCardName("Donaco", "Donacojn", "Donacon");
EsperantoCardNames[CardNames.ENCHANTRESS] = new TranslatedCardName("Ĉarmistino", "Ĉarmistinojn", "Ĉarmistinon");
EsperantoCardNames[CardNames.ENGINEER] = new TranslatedCardName("Inĝeniero", "Inĝenierojn", "Inĝenieron");
EsperantoCardNames[CardNames.FARMERS_MARKET] = new TranslatedCardName("Kampara Bazaro", "Kamparajn Bazarojn", "Kamparan Bazaron");
EsperantoCardNames[CardNames.FORUM] = new TranslatedCardName("Forumo", "Forumojn", "Forumon");
EsperantoCardNames[CardNames.FOUNTAIN] = new TranslatedCardName("Fontano", "Fontanojn", "Fontanon");
EsperantoCardNames[CardNames.GROUNDSKEEPER] = new TranslatedCardName("Ĝardenisto", "Ĝardenistojn", "Ĝardeniston");
EsperantoCardNames[CardNames.KEEP] = new TranslatedCardName("Loĝejturo", "Loĝejturojn", "Loĝejturon");
EsperantoCardNames[CardNames.LABYRINTH] = new TranslatedCardName("Labirinto", "Labirintojn", "Labirinton");
EsperantoCardNames[CardNames.LEGIONARY] = new TranslatedCardName("Legiano", "Legianojn", "Legianon");
EsperantoCardNames[CardNames.MOUNTAIN_PASS] = new TranslatedCardName("Montpasejo", "Montpasejojn", "Montpasejon");
EsperantoCardNames[CardNames.MUSEUM] = new TranslatedCardName("Muzeo", "Muzeojn", "Muzeon");
EsperantoCardNames[CardNames.OBELISK] = new TranslatedCardName("Obelisko", "Obeliskojn", "Obeliskon");
EsperantoCardNames[CardNames.ORCHARD] = new TranslatedCardName("Fruktarbejo", "Fruktarbejojn", "Fruktarbejon");
EsperantoCardNames[CardNames.OVERLORD] = new TranslatedCardName("Reganto", "Regantojn", "Reganton");
EsperantoCardNames[CardNames.PALACE] = new TranslatedCardName("Palaco", "Palacojn", "Palacon");
EsperantoCardNames[CardNames.RITUAL] = new TranslatedCardName("Ritaro", "Ritarojn", "Ritaron");
EsperantoCardNames[CardNames.ROYAL_BLACKSMITH] = new TranslatedCardName("Reĝa Forĝisto", "Reĝajn Forĝistojn", "Reĝan Forĝiston");
EsperantoCardNames[CardNames.SACRIFICE] = new TranslatedCardName("Ofero", "Oferojn", "Oferon");
EsperantoCardNames[CardNames.SALT_THE_EARTH] = new TranslatedCardName("Salsemado", "Salsemadojn", "Salsemadon");
EsperantoCardNames[CardNames.TAX] = new TranslatedCardName("Imposto", "Impostojn", "Imposton");
EsperantoCardNames[CardNames.TEMPLE] = new TranslatedCardName("Templo", "Templojn", "Templon");
EsperantoCardNames[CardNames.TOMB] = new TranslatedCardName("Tombo", "Tombojn", "Tombon");
EsperantoCardNames[CardNames.TOWER] = new TranslatedCardName("Turo", "Turojn", "Turon");
EsperantoCardNames[CardNames.TRIUMPH] = new TranslatedCardName("Triumfo", "Triumfojn", "Triumfo");
EsperantoCardNames[CardNames.TRIUMPHAL_ARCH] = new TranslatedCardName("Triumfa Arko", "Triumfajn Arkojn", "Triumfan Arkon");
EsperantoCardNames[CardNames.VILLA] = new TranslatedCardName("Vilao", "Vilaojn", "Vilaon");
EsperantoCardNames[CardNames.WALL] = new TranslatedCardName("Muro", "Murojn", "Muron");
EsperantoCardNames[CardNames.WOLF_DEN] = new TranslatedCardName("Luponestego", "Luponestegojn", "Luponestegon");
EsperantoCardNames[CardNames.WEDDING] = new TranslatedCardName("Edziĝo", "Edziĝojn", "Edziĝon");
EsperantoCardNames[CardNames.WILD_HUNT] = new TranslatedCardName("Sovaĝa Ĉaso", "Sovaĝajn Ĉasojn", "Sovaĝan Ĉason");
EsperantoCardNames[CardNames.WINDFALL] = new TranslatedCardName("Bonŝancaĵo", "Bonŝancaĵojn", "Bonŝancaĵon");

EsperantoCardNames[CardNames.BOON_DRAWPILE] = new TranslatedCardName("Benstako", "Benstakojn", "Benstakon");
EsperantoCardNames[CardNames.BOON_DISCARDPILE] = new TranslatedCardName("Benforĵetujo", "Benforĵetujojn", "Benforĵetujon");
EsperantoCardNames[CardNames.THE_EARTHS_GIFT] = new TranslatedCardName("Dono de la Tero", "Donojn de la Tero", "Donon de la Tero");
EsperantoCardNames[CardNames.THE_FIELDS_GIFT] = new TranslatedCardName("Dono de la Kampo", "Donojn de la Kampo", "Donon de la Kampo");
EsperantoCardNames[CardNames.THE_FLAMES_GIFT] = new TranslatedCardName("Dono de la Fajro", "Donojn de la Fajro", "Donon de la Fajro");
EsperantoCardNames[CardNames.THE_FORESTS_GIFT] = new TranslatedCardName("Dono de la Arbaro", "Donojn de la Arbaro", "Donon de la Arbaro");
EsperantoCardNames[CardNames.THE_MOONS_GIFT] = new TranslatedCardName("Dono de la Luno", "Donojn de la Luno", "Donon de la Luno");
EsperantoCardNames[CardNames.THE_MOUNTAINS_GIFT] = new TranslatedCardName("Dono de la Monto", "Donojn de la Monto", "Donon de la Monto");
EsperantoCardNames[CardNames.THE_RIVERS_GIFT] = new TranslatedCardName("Dono de la Rivero", "Donojn de la Rivero", "Donon de la Rivero");
EsperantoCardNames[CardNames.THE_SEAS_GIFT] = new TranslatedCardName("Dono de la Maro", "Donojn de la Maro", "Donon de la Maro");
EsperantoCardNames[CardNames.THE_SKYS_GIFT] = new TranslatedCardName("Dono de la Ĉielo", "Donojn de la Ĉielo", "Donon de la Ĉielo");
EsperantoCardNames[CardNames.THE_SUNS_GIFT] = new TranslatedCardName("Dono de la Suno", "Donojn de la Suno", "Donon de la Suno");
EsperantoCardNames[CardNames.THE_SWAMPS_GIFT] = new TranslatedCardName("Dono de la Marĉo", "Donojn de la Marĉo", "Donon de la Marĉo");
EsperantoCardNames[CardNames.THE_WINDS_GIFT] = new TranslatedCardName("Dono de la Vento", "Donojn de la Vento", "Donon de la Vento");
EsperantoCardNames[CardNames.HEX_DRAWPILE] = new TranslatedCardName("Envultstako", "Envultstakojn", "Envultstakon");
EsperantoCardNames[CardNames.HEX_DISCARDPILE] = new TranslatedCardName("Envultforĵetujo", "Envultforĵetujojn", "Envultforĵetujon");
EsperantoCardNames[CardNames.BAD_OMENS] = new TranslatedCardName("Malbonaj Aŭguroj", "Malbonajn Aŭgurojn", "Malbonajn Aŭgurojn");
EsperantoCardNames[CardNames.DELUSION] = new TranslatedCardName("Trompo", "Trompojn", "Trompon");
EsperantoCardNames[CardNames.ENVY] = new TranslatedCardName("Envio", "Enviojn", "Envion");
EsperantoCardNames[CardNames.FAMINE] = new TranslatedCardName("Malsatego", "Malsategojn", "Malsategon");
EsperantoCardNames[CardNames.FEAR] = new TranslatedCardName("Timo", "Timojn", "Timon");
EsperantoCardNames[CardNames.GREED] = new TranslatedCardName("Avideco", "Avidecojn", "Avidecon");
EsperantoCardNames[CardNames.HAUNTING] = new TranslatedCardName("Hantado", "Hantadojn", "Hantadon");
EsperantoCardNames[CardNames.LOCUSTS] = new TranslatedCardName("Akridoj", "Akridojn", "Akridojn");
EsperantoCardNames[CardNames.MISERY] = new TranslatedCardName("Mizero", "Mizerojn", "Mizeron");
EsperantoCardNames[CardNames.PLAGUE] = new TranslatedCardName("Pesto", "Pestojn", "Peston");
EsperantoCardNames[CardNames.POVERTY] = new TranslatedCardName("Malriĉeco", "Malriĉecojn", "Malriĉecon");
EsperantoCardNames[CardNames.WAR] = new TranslatedCardName("Milito", "Militojn", "Militon");
EsperantoCardNames[CardNames.MISERABLE] = new TranslatedCardName("Mizera", "Mizerajn", "Mizeran");
EsperantoCardNames[CardNames.TWICE_MISERABLE] = new TranslatedCardName("Duoble Mizera", "Duoble Mizerajn", "Duoble Mizeran");
EsperantoCardNames[CardNames.ENVIOUS] = new TranslatedCardName("Enviema", "Enviemajn", "Envieman");
EsperantoCardNames[CardNames.DELUDED] = new TranslatedCardName("Trompita", "Trompitajn", "Trompitan");
EsperantoCardNames[CardNames.LOST_IN_THE_WOODS] = new TranslatedCardName("Perdita en la Arbaro", "Perditajn en la Arbaro", "Perditan en la Arbaro");
EsperantoCardNames[CardNames.BARD] = new TranslatedCardName("Bardo", "Bardojn", "Bardon");
EsperantoCardNames[CardNames.BLESSED_VILLAGE] = new TranslatedCardName("Benita Vilaĝo", "Benitajn Vilaĝojn", "Benitan Vilaĝon");
EsperantoCardNames[CardNames.CHANGELING] = new TranslatedCardName("Anstataŭinfano", "Anstataŭinfanojn", "Anstataŭinfanon");
EsperantoCardNames[CardNames.CEMETERY] = new TranslatedCardName("Tombejo", "Tombejojn", "Tombejon");
EsperantoCardNames[CardNames.COBBLER] = new TranslatedCardName("Ŝuisto", "Ŝuistojn", "Ŝuiston");
EsperantoCardNames[CardNames.CONCLAVE] = new TranslatedCardName("Konklavo", "Konklavojn", "Konklavon");
EsperantoCardNames[CardNames.CRYPT] = new TranslatedCardName("Kripto", "Kriptojn", "Kripton");
EsperantoCardNames[CardNames.CURSED_VILLAGE] = new TranslatedCardName("Malbenita Vilaĝo", "Malbenitajn Vilaĝojn", "Malbenitan Vilaĝon");
EsperantoCardNames[CardNames.DEN_OF_SIN] = new TranslatedCardName("Pekdomaĉo", "Pekdomaĉojn", "Pekdomaĉon");
EsperantoCardNames[CardNames.DEVILS_WORKSHOP] = new TranslatedCardName("Diabla Ateliero", "Diablajn Atelierojn", "Diablan Atelieron");
EsperantoCardNames[CardNames.DRUID] = new TranslatedCardName("Druido", "Druidojn", "Druidon");
EsperantoCardNames[CardNames.EXORCIST] = new TranslatedCardName("Ekzorcisto", "Ekzorcistojn", "Ekzorciston");
EsperantoCardNames[CardNames.FAITHFUL_HOUND] = new TranslatedCardName("Fidela Hundo", "Fidelajn Hundojn", "Fidelan Hundon");
EsperantoCardNames[CardNames.FOOL] = new TranslatedCardName("Stultulo", "Stultulojn", "Stultulon");
EsperantoCardNames[CardNames.GHOST_TOWN] = new TranslatedCardName("Fantomurbo", "Fantomurbojn", "Fantomurbon");
EsperantoCardNames[CardNames.GUARDIAN] = new TranslatedCardName("Gardanto", "Gardantojn", "Gardanton");
EsperantoCardNames[CardNames.IDOL] = new TranslatedCardName("Idolo", "Idolojn", "Idolon");
EsperantoCardNames[CardNames.LEPRECHAUN] = new TranslatedCardName("Leprekono", "Leprekonojn", "Leprekonon");
EsperantoCardNames[CardNames.MONASTERY] = new TranslatedCardName("Monaĥejo", "Monaĥejojn", "Monaĥejon");
EsperantoCardNames[CardNames.NECROMANCER] = new TranslatedCardName("Nekromancisto", "Nekromancistojn", "Nekromanciston");
EsperantoCardNames[CardNames.NIGHT_WATCHMAN] = new TranslatedCardName("Nokta Gardisto", "Noktajn Gardistojn", "Noktan Gardiston");
EsperantoCardNames[CardNames.PIXIE] = new TranslatedCardName("Feeto", "Feetojn", "Feeton");
EsperantoCardNames[CardNames.POOKA] = new TranslatedCardName("Pukao", "Pukaojn", "Pukaon");
EsperantoCardNames[CardNames.RAIDER] = new TranslatedCardName("Atakanto", "Atakantojn", "Atakanton");
EsperantoCardNames[CardNames.SACRED_GROVE] = new TranslatedCardName("Sankta Bosko", "Sanktajn Boskojn", "Sanktan Boskon");
EsperantoCardNames[CardNames.SECRET_CAVE] = new TranslatedCardName("Sekreta Kavo", "Sekretajn Kavojn", "Sekretan Kavon");
EsperantoCardNames[CardNames.SHEPHERD] = new TranslatedCardName("Ŝafisto", "Ŝafistojn", "Ŝafiston");
EsperantoCardNames[CardNames.SKULK] = new TranslatedCardName("Kaŝemulo", "Kaŝemulojn", "Kaŝemulon");
EsperantoCardNames[CardNames.TORMENTOR] = new TranslatedCardName("Turmentisto", "Turmentistojn", "Turmentiston");
EsperantoCardNames[CardNames.TRAGIC_HERO] = new TranslatedCardName("Tragika Heroo", "Tragikajn Heroojn", "Tragikan Heroon");
EsperantoCardNames[CardNames.TRACKER] = new TranslatedCardName("Spuristo", "Spuristojn", "Spuriston");
EsperantoCardNames[CardNames.VAMPIRE] = new TranslatedCardName("Vampiro", "Vampirojn", "Vampiron");
EsperantoCardNames[CardNames.WEREWOLF] = new TranslatedCardName("Luphomo", "Luphomojn", "Luphomon");
EsperantoCardNames[CardNames.CURSED_GOLD] = new TranslatedCardName("Malbenita Oro", "Malbenitajn Orojn", "Malbenitan Oron");
EsperantoCardNames[CardNames.GOAT] = new TranslatedCardName("Kapro", "Kaprojn", "Kapron");
EsperantoCardNames[CardNames.HAUNTED_MIRROR] = new TranslatedCardName("Hantata Spegulo", "Hantatajn Spegulojn", "Hantatan Spegulon");
EsperantoCardNames[CardNames.LUCKY_COIN] = new TranslatedCardName("Bonŝanca Monero", "Bonŝancajn Monerojn", "Bonŝancan Moneron");
EsperantoCardNames[CardNames.MAGIC_LAMP] = new TranslatedCardName("Sorĉa Lampo", "Sorĉajn Lampojn", "Sorĉan Lampon");
EsperantoCardNames[CardNames.PASTURE] = new TranslatedCardName("Paŝtejo", "Paŝtejojn", "Paŝtejon");
EsperantoCardNames[CardNames.POUCH] = new TranslatedCardName("Saketo", "Saketojn", "Saketon");
EsperantoCardNames[CardNames.BAT] = new TranslatedCardName("Vesperto", "Vespertojn", "Vesperton");
EsperantoCardNames[CardNames.GHOST] = new TranslatedCardName("Fantomo", "Fantomojn", "Fantomon");
EsperantoCardNames[CardNames.IMP] = new TranslatedCardName("Diableto", "Diabletojn", "Diableton");
EsperantoCardNames[CardNames.WILL_O_WISP] = new TranslatedCardName("Vaglumo", "Vaglumojn", "Vaglumon");
EsperantoCardNames[CardNames.WISH] = new TranslatedCardName("Deziro", "Dezirojn", "Deziron");
EsperantoCardNames[CardNames.ZOMBIE_APPRENTICE] = new TranslatedCardName("Zombimetilernanto", "Zombimetilernantojn", "Zombimetilernanton");
EsperantoCardNames[CardNames.ZOMBIE_MASON] = new TranslatedCardName("Zombimasonisto", "Zombimasonistojn", "Zombimasoniston");
EsperantoCardNames[CardNames.ZOMBIE_SPY] = new TranslatedCardName("Zombispiono", "Zombispionojn", "Zombispionon");

EsperantoCardNames[CardNames.ACTING_TROUPE] = new TranslatedCardName("Teatra Trupo", "Teatrajn Trupojn", "Teatran Trupon");
EsperantoCardNames[CardNames.BORDER_GUARD] = new TranslatedCardName("Limgardisto", "Limgardistojn", "Limgardiston");
EsperantoCardNames[CardNames.CARGO_SHIP] = new TranslatedCardName("Kargoŝipo", "Kargoŝipojn", "Kargoŝipon");
EsperantoCardNames[CardNames.DUCAT] = new TranslatedCardName("Dukato", "Dukatojn", "Dukaton");
EsperantoCardNames[CardNames.EXPERIMENT] = new TranslatedCardName("Eksperimento", "Eksperimentojn", "Eksperimenton");
EsperantoCardNames[CardNames.FLAG_BEARER] = new TranslatedCardName("Flagportanto", "Flagportantojn", "Flagportanton");
EsperantoCardNames[CardNames.HIDEOUT] = new TranslatedCardName("Kaŝejo", "Kaŝejojn", "Kaŝejon");
EsperantoCardNames[CardNames.INVENTOR] = new TranslatedCardName("Inventisto", "Inventistojn", "Inventiston");
EsperantoCardNames[CardNames.IMPROVE] = new TranslatedCardName("Plibonigo", "Plibonigojn", "Plibonigon");
EsperantoCardNames[CardNames.LACKEYS] = new TranslatedCardName("Lakeoj", "Lakeojn", "Lakeojn");
EsperantoCardNames[CardNames.MOUNTAIN_VILLAGE] = new TranslatedCardName("Montara Vilaĝo", "Montarajn Vilaĝojn", "Montaran Vilaĝon");
EsperantoCardNames[CardNames.PATRON] = new TranslatedCardName("Patrono", "Patronojn", "Patronon");
EsperantoCardNames[CardNames.PRIEST] = new TranslatedCardName("Pastro", "Pastrojn", "Pastron");
EsperantoCardNames[CardNames.RESEARCH] = new TranslatedCardName("Reserĉado", "Reserĉadojn", "Reserĉadon");
EsperantoCardNames[CardNames.SILK_MERCHANT] = new TranslatedCardName("Silkokomercisto", "Silkokomercistojn", "Silkokomerciston");
EsperantoCardNames[CardNames.OLD_WITCH] = new TranslatedCardName("Maljuna Sorĉistino", "Maljunajn Sorĉistinojn", "Maljunan Sorĉistinon");
EsperantoCardNames[CardNames.RECRUITER] = new TranslatedCardName("Rekrutigisto", "Rekrutigistojn", "Rekrutigiston");
EsperantoCardNames[CardNames.SCEPTER] = new TranslatedCardName("Sceptro", "Sceptrojn", "Sceptron");
EsperantoCardNames[CardNames.SCHOLAR] = new TranslatedCardName("Akademiulo", "Akademiulojn", "Akademiulon");
EsperantoCardNames[CardNames.SCULPTOR] = new TranslatedCardName("Skulptisto", "Skulptistojn", "Skulptiston");
EsperantoCardNames[CardNames.SEER] = new TranslatedCardName("Viziulo", "Viziulojn", "Viziulon");
EsperantoCardNames[CardNames.SPICES] = new TranslatedCardName("Spicoj", "Spicojn", "Spicojn");
EsperantoCardNames[CardNames.SWASHBUCKLER] = new TranslatedCardName("Aŭdaculo", "Aŭdaculojn", "Aŭdaculon");
EsperantoCardNames[CardNames.TREASURER] = new TranslatedCardName("Trezoristo", "Trezoristojn", "Trezoriston");
EsperantoCardNames[CardNames.VILLAIN] = new TranslatedCardName("Kanajlo", "Kanajlojn", "Kanajlon");
EsperantoCardNames[CardNames.FLAG] = new TranslatedCardName("Flago", "Flagojn", "la Flagon");
EsperantoCardNames[CardNames.HORN] = new TranslatedCardName("Korno", "Kornojn", "la Kornon");
EsperantoCardNames[CardNames.KEY] = new TranslatedCardName("Ŝlosilo", "Ŝlosilojn", "la Ŝlosilon");
EsperantoCardNames[CardNames.LANTERN] = new TranslatedCardName("Lanterno", "Lanternojn", "la Lanternon");
EsperantoCardNames[CardNames.TREASURE_CHEST] = new TranslatedCardName("Trezorkesto", "Trezorkestojn", "la Trezorkeston");
EsperantoCardNames[CardNames.ACADEMY] = new TranslatedCardName("Akademio", "Akademiojn", "Akademion");
EsperantoCardNames[CardNames.BARRACKS] = new TranslatedCardName("Kazerno", "Kazernojn", "Kazernon");
EsperantoCardNames[CardNames.CANAL] = new TranslatedCardName("Kanalo", "Kanalojn", "Kanalon");
EsperantoCardNames[CardNames.CAPITALISM] = new TranslatedCardName("Kapitalismo", "Kapitalismojn", "Kapitalismon");
EsperantoCardNames[CardNames.CATHEDRAL] = new TranslatedCardName("Katedralo", "Katedralojn", "Katedralon");
EsperantoCardNames[CardNames.CITADEL] = new TranslatedCardName("Citadelo", "Citadelojn", "Citadelon");
EsperantoCardNames[CardNames.CITY_GATE] = new TranslatedCardName("Urbopordego", "Urbopordegojn", "Urbopordegon");
EsperantoCardNames[CardNames.CROP_ROTATION] = new TranslatedCardName("Kultivciklo", "Kultivciklojn", "Kultivciklon");
EsperantoCardNames[CardNames.EXPLORATION] = new TranslatedCardName("Esplorado", "Esploradojn", "Esploradon");
EsperantoCardNames[CardNames.FAIR] = new TranslatedCardName("Foiro", "Foirojn", "Foiron");
EsperantoCardNames[CardNames.FLEET] = new TranslatedCardName("Ŝiparo", "Ŝiparojn", "Ŝiparon");
EsperantoCardNames[CardNames.GUILDHALL] = new TranslatedCardName("Gildejo", "Gildejojn", "Gildejon");
EsperantoCardNames[CardNames.INNOVATION] = new TranslatedCardName("Novigado", "Novigadojn", "Novigadon");
EsperantoCardNames[CardNames.PAGEANT] = new TranslatedCardName("Spektaklo", "Spektaklojn", "Spektaklon");
EsperantoCardNames[CardNames.PIAZZA] = new TranslatedCardName("Piazza", "Piazze", "Piazza");
EsperantoCardNames[CardNames.ROAD_NETWORK] = new TranslatedCardName("Ŝosereto", "Ŝoseretojn", "Ŝosereton");
EsperantoCardNames[CardNames.SEWERS] = new TranslatedCardName("Kloakoj", "Kloakojn", "Kloakojn");
EsperantoCardNames[CardNames.SILOS] = new TranslatedCardName("Siloj", "Silojn", "Silojn");
EsperantoCardNames[CardNames.SINISTER_PLOT] = new TranslatedCardName("Sinistra Komploto", "Sinistrajn Komplotojn", "Sinistran Komploton");
EsperantoCardNames[CardNames.STAR_CHART] = new TranslatedCardName("Stelmapo", "Stelmapojn", "Stelmapon");

EsperantoCardNames[CardNames.AVANTO] = new TranslatedCardName("Glacitruo", "Glacitruojn", "Glacitruon");
EsperantoCardNames[CardNames.BLACK_MARKET] = new TranslatedCardName("Nigra Merkato", "Nigrajn Merkatojn", "Nigran Merkaton");
EsperantoCardNames[CardNames.ENVOY] = new TranslatedCardName("Sendito", "Senditojn", "Senditon");
EsperantoCardNames[CardNames.GOVERNOR] = new TranslatedCardName("Guberniestro", "Guberniestrojn", "Guberniestron");
EsperantoCardNames[CardNames.PRINCE] = new TranslatedCardName("Princo", "Princojn", "Princon");
EsperantoCardNames[CardNames.SAUNA] = new TranslatedCardName("Saŭno", "Saŭnojn", "Saŭnon");
EsperantoCardNames[CardNames.STASH] = new TranslatedCardName("Kaŝaĵeto", "Kaŝaĵetojn", "Kaŝaĵeton");
EsperantoCardNames[CardNames.SUMMON] = new TranslatedCardName("Voko", "Vokojn", "Vokon");
EsperantoCardNames[CardNames.WALLED_VILLAGE] = new TranslatedCardName("Fortika Vilaĝo", "Fortikajn Vilaĝojn", "Fortikan Vilaĝon");
EsperantoCardNames[CardNames.BLACK_MARKET_PILE] = new TranslatedCardName("Black Market Deck", "Black Market Decks", "a Black Market Deck");
EsperantoCardNames[CardNames.DISMANTLE] = new TranslatedCardName("Malmunto", "Malmuntojn", "Malmunton");
EsperantoCardNames[CardNames.CHURCH] = new TranslatedCardName("Eklezio", "Ekleziojn", "Eklezion");
EsperantoCardNames[CardNames.CAPTAIN] = new TranslatedCardName("Kapitano", "Kapitanojn", "Kapitanon");

EsperantoCardNames[CardNames.BLACK_CAT] = new TranslatedCardName("Nigra Kato", "Nigrajn Katojn", "Nigran Katon");
EsperantoCardNames[CardNames.SLEIGH] = new TranslatedCardName("Sledo", "Sledojn", "Sledon");
EsperantoCardNames[CardNames.SUPPLIES] = new TranslatedCardName("Proviantoj", "Proviantojn", "Proviantojn");
EsperantoCardNames[CardNames.CAMEL_TRAIN] = new TranslatedCardName("Kamelkaravano", "Kamelkaravanojn", "Kamelkaravanon");
EsperantoCardNames[CardNames.GOATHERD] = new TranslatedCardName("Kapristo", "Kapristojn", "Kapristo");
EsperantoCardNames[CardNames.SCRAP] = new TranslatedCardName("Malkonstruo", "Malkonstruojn", "Malkonstruon");
EsperantoCardNames[CardNames.SHEEPDOG] = new TranslatedCardName("Ŝafhundo", "Ŝafhundojn", "Ŝafhundon");
EsperantoCardNames[CardNames.SNOWY_VILLAGE] = new TranslatedCardName("Neĝkovrita Vilaĝo", "Neĝkovritajn Vilaĝojn", "Neĝkovritan Vilaĝon");
EsperantoCardNames[CardNames.STOCKPILE] = new TranslatedCardName("Provizaro", "Provizarojn", "Provizaron");
EsperantoCardNames[CardNames.BOUNTY_HUNTER] = new TranslatedCardName("Premiĉasisto", "Premiĉasistojn", "Premiĉasiston");
EsperantoCardNames[CardNames.CARDINAL] = new TranslatedCardName("Kardinalo", "Kardinalojn", "Kardinalon");
EsperantoCardNames[CardNames.CAVALRY] = new TranslatedCardName("Kavalerio", "Kavaleriojn", "Kavaleriom");
EsperantoCardNames[CardNames.GROOM] = new TranslatedCardName("Ĉevalisto", "Ĉevalistojn", "Ĉevaliston");
EsperantoCardNames[CardNames.HOSTELRY] = new TranslatedCardName("Gastejo", "Gastejojn", "Gastejon");
EsperantoCardNames[CardNames.VILLAGE_GREEN] = new TranslatedCardName("Vilaĝherbejo", "Vilaĝherbejojn", "Vilaĝherbejon");
EsperantoCardNames[CardNames.BARGE] = new TranslatedCardName("Barĝo", "Barĝojn", "Barĝon");
EsperantoCardNames[CardNames.COVEN] = new TranslatedCardName("Sorĉistinaro", "Sorĉistinarojn", "Sorĉistinaron");
EsperantoCardNames[CardNames.DISPLACE] = new TranslatedCardName("Delokigo", "Delokigojn", "Delokigon");
EsperantoCardNames[CardNames.FALCONER] = new TranslatedCardName("Falkisto", "Falkistojn", "Falkiston");
EsperantoCardNames[CardNames.FISHERMAN] = new TranslatedCardName("Fiŝkaptisto", "Fiŝkaptistojn", "Fiŝkaptiston");
EsperantoCardNames[CardNames.GATEKEEPER] = new TranslatedCardName("Pordgardisto", "Pordgardistojn", "Pordgardiston");
EsperantoCardNames[CardNames.HUNTING_LODGE] = new TranslatedCardName("Ĉasdometo", "Ĉasdometojn", "Ĉasdometon");
EsperantoCardNames[CardNames.KILN] = new TranslatedCardName("Forno", "Fornojn", "Fornon");
EsperantoCardNames[CardNames.LIVERY] = new TranslatedCardName("Ĉevalpensiono", "Ĉevalpensionojn", "Ĉevalpensionon");
EsperantoCardNames[CardNames.MASTERMIND] = new TranslatedCardName("Malica Geniulo", "Malicajn Geniulojn", "Malican Geniulon");
EsperantoCardNames[CardNames.PADDOCK] = new TranslatedCardName("Brutejo", "Brutejojn", "Brutejon");
EsperantoCardNames[CardNames.SANCTUARY] = new TranslatedCardName("Sanktejo", "Sanktejojn", "Sanktejon");
EsperantoCardNames[CardNames.DESTRIER] = new TranslatedCardName("Batalĉevalo", "Batalĉevalojn", "Batalĉevalon");
EsperantoCardNames[CardNames.WAYFARER] = new TranslatedCardName("Vojiranto", "Vojirantojn", "Vojiranton");
EsperantoCardNames[CardNames.ANIMAL_FAIR] = new TranslatedCardName("Bestfoiro", "Bestfoirojn", "Bestfoiron");
EsperantoCardNames[CardNames.HORSE] = new TranslatedCardName("Ĉevalo", "Ĉevalojn", "Ĉevalon");
EsperantoCardNames[CardNames.WAY_OF_THE_BUTTERFLY] = new TranslatedCardName("Vojo de la Papilio");
EsperantoCardNames[CardNames.WAY_OF_THE_CAMEL] = new TranslatedCardName("Vojo de la Kamelo");
EsperantoCardNames[CardNames.WAY_OF_THE_CHAMELEON] = new TranslatedCardName("Vojo de la Ĥameleono");
EsperantoCardNames[CardNames.WAY_OF_THE_FROG] = new TranslatedCardName("Vojo de la Rano");
EsperantoCardNames[CardNames.WAY_OF_THE_GOAT] = new TranslatedCardName("Vojo de la Kapro");
EsperantoCardNames[CardNames.WAY_OF_THE_HORSE] = new TranslatedCardName("Vojo de la Ĉevalo");
EsperantoCardNames[CardNames.WAY_OF_THE_MOLE] = new TranslatedCardName("Vojo de la Talpo");
EsperantoCardNames[CardNames.WAY_OF_THE_MONKEY] = new TranslatedCardName("Vojo de la Simio");
EsperantoCardNames[CardNames.WAY_OF_THE_MOUSE] = new TranslatedCardName("Vojo de la Muso");
EsperantoCardNames[CardNames.WAY_OF_THE_MULE] = new TranslatedCardName("Vojo de la Mulo");
EsperantoCardNames[CardNames.WAY_OF_THE_OTTER] = new TranslatedCardName("Vojo de la Lutro");
EsperantoCardNames[CardNames.WAY_OF_THE_OWL] = new TranslatedCardName("Vojo de la Strigo");
EsperantoCardNames[CardNames.WAY_OF_THE_OX] = new TranslatedCardName("Vojo de la Okso");
EsperantoCardNames[CardNames.WAY_OF_THE_PIG] = new TranslatedCardName("Vojo de la Porko");
EsperantoCardNames[CardNames.WAY_OF_THE_RAT] = new TranslatedCardName("Vojo de la Rato");
EsperantoCardNames[CardNames.WAY_OF_THE_SEAL] = new TranslatedCardName("Vojo de la Foko");
EsperantoCardNames[CardNames.WAY_OF_THE_SHEEP] = new TranslatedCardName("Vojo de la Ŝafo");
EsperantoCardNames[CardNames.WAY_OF_THE_SQUIRREL] = new TranslatedCardName("Vojo de la Sciuro");
EsperantoCardNames[CardNames.WAY_OF_THE_TURTLE] = new TranslatedCardName("Vojo de la Testudo");
EsperantoCardNames[CardNames.WAY_OF_THE_WORM] = new TranslatedCardName("Vojo de la Vermo");
EsperantoCardNames[CardNames.DELAY] = new TranslatedCardName("Prokrasto", "Prokrastojn", "Prokraston");
EsperantoCardNames[CardNames.DESPERATION] = new TranslatedCardName("Malespero", "Malesperojn", "Malesperon");
EsperantoCardNames[CardNames.GAMBLE] = new TranslatedCardName("Veto", "Vetojn", "Veton");
EsperantoCardNames[CardNames.PURSUE] = new TranslatedCardName("Persekuto", "Persekutojn", "Persekuton");
EsperantoCardNames[CardNames.RIDE] = new TranslatedCardName("Rajdo", "Rajdojn", "Rajdon");
EsperantoCardNames[CardNames.TOIL] = new TranslatedCardName("Laborego", "Laboregojn", "Laboregon");
EsperantoCardNames[CardNames.ENHANCE] = new TranslatedCardName("Pliigo", "Pliigojn", "Pliigon");
EsperantoCardNames[CardNames.MARCH] = new TranslatedCardName("Marŝo", "Marŝojn", "Marŝon");
EsperantoCardNames[CardNames.TRANSPORT] = new TranslatedCardName("Transporto", "Transportojn", "Transporton");
EsperantoCardNames[CardNames.BANISH] = new TranslatedCardName("Forpelo", "Forpelojn", "Forpelon");
EsperantoCardNames[CardNames.BARGAIN] = new TranslatedCardName("Favora Aĉetpropono", "Favorajn Aĉetproponojn", "Favoran Aĉetproponon");
EsperantoCardNames[CardNames.INVEST] = new TranslatedCardName("Investo", "Investojn", "Investon");
EsperantoCardNames[CardNames.SEIZE_THE_DAY] = new TranslatedCardName("Rikolto de la Tago", "Rikoltojn de la Tago", "Rikolton de la Tago");
EsperantoCardNames[CardNames.COMMERCE] = new TranslatedCardName("Komerco", "Komercojn", "Komercon");
EsperantoCardNames[CardNames.DEMAND] = new TranslatedCardName("Postulo", "Postulojn", "Postulo");
EsperantoCardNames[CardNames.STAMPEDE] = new TranslatedCardName("Stampedo", "Stampedojn", "Stampedon");
EsperantoCardNames[CardNames.REAP] = new TranslatedCardName("Falĉado", "Falĉadojn", "Falĉadon");
EsperantoCardNames[CardNames.ENCLAVE] = new TranslatedCardName("Enklavo", "Enklavojn", "Enklavon");
EsperantoCardNames[CardNames.ALLIANCE] = new TranslatedCardName("Alianco", "Aliancojn", "Aliancon");
EsperantoCardNames[CardNames.POPULATE] = new TranslatedCardName("Loĝatigo", "Loĝatigojn", "Loĝatigon");

EsperantoCardNames[CardNames.CARD_OF_THE_BUTTERFLY] = "";
EsperantoCardNames[CardNames.CARD_OF_THE_CAMEL] = "";
EsperantoCardNames[CardNames.CARD_OF_THE_CHAMELEON] = "";
EsperantoCardNames[CardNames.CARD_OF_THE_FROG] = "";
EsperantoCardNames[CardNames.CARD_OF_THE_GOAT] = "";
EsperantoCardNames[CardNames.CARD_OF_THE_HORSE] = "";
EsperantoCardNames[CardNames.CARD_OF_THE_MOLE] = "";
EsperantoCardNames[CardNames.CARD_OF_THE_MONKEY] = "";
EsperantoCardNames[CardNames.CARD_OF_THE_MOUSE] = "";
EsperantoCardNames[CardNames.CARD_OF_THE_MULE] = "";
EsperantoCardNames[CardNames.CARD_OF_THE_OTTER] = "";
EsperantoCardNames[CardNames.CARD_OF_THE_OWL] = "";
EsperantoCardNames[CardNames.CARD_OF_THE_OX] = "";
EsperantoCardNames[CardNames.CARD_OF_THE_PIG] = "";
EsperantoCardNames[CardNames.CARD_OF_THE_RAT] = "";
EsperantoCardNames[CardNames.CARD_OF_THE_SEAL] = "";
EsperantoCardNames[CardNames.CARD_OF_THE_SHEEP] = "";
EsperantoCardNames[CardNames.CARD_OF_THE_SQUIRREL] = "";
EsperantoCardNames[CardNames.CARD_OF_THE_TURTLE] = "";
EsperantoCardNames[CardNames.CARD_OF_THE_WORM] = "";

EsperantoCardNames[CardNames.TOWN] = new TranslatedCardName("Urbeto", "Urbetojn", "Urbeton");
EsperantoCardNames[CardNames.MODIFY] = new TranslatedCardName("Modifado", "Modifadojn", "Modifadon");
EsperantoCardNames[CardNames.MERCHANT_CAMP] = new TranslatedCardName("Tendaro de Komercistoj", "Tendarojn de Komercistoj", "Tendaron de Komercistoj");
EsperantoCardNames[CardNames.SWAP] = new TranslatedCardName("Interŝanĝado", "Interŝanĝadojn", "Interŝanĝadon");
EsperantoCardNames[CardNames.SKIRMISHER] = new TranslatedCardName("Bataletanto", "Bataletantojn", "Bataletanton");
EsperantoCardNames[CardNames.HIGHWAYMAN] = new TranslatedCardName("Vojrabisto", "Vojrabistojn", "Vojrabiston");
EsperantoCardNames[CardNames.BARBARIAN] = new TranslatedCardName("Barbaro", "Barbarojn", "Barbaron");
EsperantoCardNames[CardNames.INNKEEPER] = new TranslatedCardName("Gastejestro", "Gastejestrojn", "Gastejestron");
EsperantoCardNames[CardNames.CAPITAL_CITY] = new TranslatedCardName("Ĉefurbo", "Ĉefurbojn", "Ĉefurbon");
EsperantoCardNames[CardNames.SPECIALIST] = new TranslatedCardName("Fakulo", "Fakulojn", "Fakulon");
EsperantoCardNames[CardNames.CARPENTER] = new TranslatedCardName("Ĉarpentisto", "Ĉarpentistojn", "Ĉarpentiston");
EsperantoCardNames[CardNames.ROYAL_GALLEY] = new TranslatedCardName("Reĝa Galero", "Reĝajn Galerojn", "Reĝan Galeron");
EsperantoCardNames[CardNames.MARQUIS] = new TranslatedCardName("Markizo", "Markizojn", "Markizon");
EsperantoCardNames[CardNames.SENTINEL] = new TranslatedCardName("Sentinelo", "Sentinelojn", "Sentinelon");
EsperantoCardNames[CardNames.COURIER] = new TranslatedCardName("Kuriero", "Kurierojn", "Kurieron");
EsperantoCardNames[CardNames.GALLERIA] = new TranslatedCardName("Galerio", "Galeriojn", "Galerion");
EsperantoCardNames[CardNames.HUNTER] = new TranslatedCardName("Ĉasisto", "Ĉasistojn", "Ĉasiston");

EsperantoCardNames[CardNames.SYCOPHANT] = new TranslatedCardName("Jesulo", "Jesulojn", "Jesulon");
EsperantoCardNames[CardNames.UNDERLING] = new TranslatedCardName("Subulo", "Subulojn", "Subulon");
EsperantoCardNames[CardNames.IMPORTER] = new TranslatedCardName("Importisto", "Importistojn", "Importiston");
EsperantoCardNames[CardNames.CONTRACT] = new TranslatedCardName("Kontrakto", "Kontraktojn", "Kontrakton");
EsperantoCardNames[CardNames.EMISSARY] = new TranslatedCardName("Emisario", "Emisariojn", "Emisarion");
EsperantoCardNames[CardNames.BAUBLE] = new TranslatedCardName("Bagatelo", "Bagatelojn", "Bagatelon");
EsperantoCardNames[CardNames.BROKER] = new TranslatedCardName("Makleristo", "Makleristojn", "Makleriston");
EsperantoCardNames[CardNames.GUILDMASTER] = new TranslatedCardName("Gildestro", "Gildestrojn", "Gildestron");

EsperantoCardNames[CardNames.ARCHITECTS_GUILD] = new TranslatedCardName("Gildo de Arĥitektoj");
EsperantoCardNames[CardNames.ORDER_OF_ASTROLOGERS] = new TranslatedCardName("Ordeno de Astrologoj");
EsperantoCardNames[CardNames.LEAGUE_OF_BANKERS] = new TranslatedCardName("Ligo de Bankistoj");
EsperantoCardNames[CardNames.MOUNTAIN_FOLK] = new TranslatedCardName("Monta Popolo");
EsperantoCardNames[CardNames.WOODWORKERS_GUILD] = new TranslatedCardName("Gildo de Lignaĵistoj");
EsperantoCardNames[CardNames.PEACEFUL_CULT] = new TranslatedCardName("Paca Sekto");
EsperantoCardNames[CardNames.CRAFTERS_GUILD] = new TranslatedCardName("Gildo de Metiistoj");
EsperantoCardNames[CardNames.CAVE_DWELLERS] = new TranslatedCardName("Kavloĝantoj");
EsperantoCardNames[CardNames.PLATEAU_SHEPHERDS] = new TranslatedCardName("Altebenaĵoŝafistoj");
EsperantoCardNames[CardNames.DESERT_GUIDES] = new TranslatedCardName("Dezertgvidistoj");
EsperantoCardNames[CardNames.FAMILY_OF_INVENTORS] = new TranslatedCardName("Familio de Inventistoj");
EsperantoCardNames[CardNames.ISLAND_FOLK] = new TranslatedCardName("Insula Popolo");
EsperantoCardNames[CardNames.ORDER_OF_MASONS] = new TranslatedCardName("Ordeno de Masonistoj");
EsperantoCardNames[CardNames.BAND_OF_NOMADS] = new TranslatedCardName("Nomadaro");
EsperantoCardNames[CardNames.GANG_OF_PICKPOCKETS] = new TranslatedCardName("Poŝoŝtelistaro");
EsperantoCardNames[CardNames.MARKET_TOWNS] = new TranslatedCardName("Merkaturboj");
EsperantoCardNames[CardNames.FOREST_DWELLERS] = new TranslatedCardName("Arbarloĝantoj");
EsperantoCardNames[CardNames.FELLOWSHIP_OF_SCRIBES] = new TranslatedCardName("Kunuleco de Skribistoj");
EsperantoCardNames[CardNames.LEAGUE_OF_SHOPKEEPERS] = new TranslatedCardName("Ligo de Butikistoj");
EsperantoCardNames[CardNames.COASTAL_HAVEN] = new TranslatedCardName("Marborda Haveno");
EsperantoCardNames[CardNames.CITY_STATE] = new TranslatedCardName("Urboŝtato");
EsperantoCardNames[CardNames.TRAPPERS_LODGE] = new TranslatedCardName("Dometo de Kaptistoj");
EsperantoCardNames[CardNames.CIRCLE_OF_WITCHES] = new TranslatedCardName("Rondo de Sorĉistinoj");

EsperantoCardNames[CardNames.AUGUR_PILE] = new TranslatedCardName("Aŭguristoj");
EsperantoCardNames[CardNames.HERB_GATHERER] = new TranslatedCardName("Herbkolektanto", "Herbkolektantojn", "Herbkolektanton");
EsperantoCardNames[CardNames.ACOLYTE] = new TranslatedCardName("Akolito", "Akolitojn", "Akoliton");
EsperantoCardNames[CardNames.SORCERESS] = new TranslatedCardName("Magiistino", "Magiistinojn", "Magiistinon");
EsperantoCardNames[CardNames.SIBYL] = new TranslatedCardName("Sibilo", "Sibilojn", "Sibilon");
EsperantoCardNames[CardNames.CLASH_PILE] = new TranslatedCardName("Kolizioj");
EsperantoCardNames[CardNames.BATTLE_PLAN] = new TranslatedCardName("Batalplano", "Batalplanojn", "Batalplanon");
EsperantoCardNames[CardNames.ARCHER] = new TranslatedCardName("Arkisto", "Arkistojn", "Arkiston");
EsperantoCardNames[CardNames.WARLORD] = new TranslatedCardName("Militestro", "Militestrojn", "Militestron");
EsperantoCardNames[CardNames.TERRITORY] = new TranslatedCardName("Teritorio", "Teritoriojn", "Teritorion");
EsperantoCardNames[CardNames.FORT_PILE] = new TranslatedCardName("Redutoj");
EsperantoCardNames[CardNames.TENT] = new TranslatedCardName("Tendo", "Tendojn", "Tendon");
EsperantoCardNames[CardNames.GARRISON] = new TranslatedCardName("Garnizono", "Garnizonojn", "Garnizonon");
EsperantoCardNames[CardNames.HILL_FORT] = new TranslatedCardName("Monteta Fortikaĵo", "Montetajn Fortikaĵojn", "Montetan Fortikaĵon");
EsperantoCardNames[CardNames.STRONGHOLD] = new TranslatedCardName("Fortikaĵego", "Fortikaĵegojn", "Fortikaĵegon");
EsperantoCardNames[CardNames.ODYSSEY_PILE] = new TranslatedCardName("Odiseadoj");
EsperantoCardNames[CardNames.OLD_MAP] = new TranslatedCardName("Malnova Mapo", "Malnovajn Mapojn", "Malnovan Mapon");
EsperantoCardNames[CardNames.VOYAGE] = new TranslatedCardName("Vojaĝo", "Vojaĝojn", "Vojaĝon");
EsperantoCardNames[CardNames.SUNKEN_TREASURE] = new TranslatedCardName("Subakvigita Trezoro", "Subakvigitajn Trezorojn", "Subakvigitan Trezoron");
EsperantoCardNames[CardNames.DISTANT_SHORE] = new TranslatedCardName("Formarbordo", "Formarbordojn", "Formarbordon");
EsperantoCardNames[CardNames.TOWNSFOLK_PILE] = new TranslatedCardName("Urbuloj");
EsperantoCardNames[CardNames.TOWN_CRIER] = new TranslatedCardName("Urbanoncisto", "Urbanoncistojn", "Urbanonciston");
EsperantoCardNames[CardNames.BLACKSMITH] = new TranslatedCardName("Forĝisto", "Forĝistojn", "Forĝiston");
EsperantoCardNames[CardNames.MILLER] = new TranslatedCardName("Muelisto", "Muelistojn", "Mueliston");
EsperantoCardNames[CardNames.ELDER] = new TranslatedCardName("Pliaĝulo", "Pliaĝulojn", "Pliaĝulon");
EsperantoCardNames[CardNames.WIZARD_PILE] = new TranslatedCardName("Sorĉistoj");
EsperantoCardNames[CardNames.STUDENT] = new TranslatedCardName("Studento", "Studentojn", "Studenton");
EsperantoCardNames[CardNames.CONJURER] = new TranslatedCardName("Elvokisto", "Elvokistojn", "Elvokiston");
EsperantoCardNames[CardNames.SORCERER] = new TranslatedCardName("Magiisto", "Magiistojn", "Magiiston");
EsperantoCardNames[CardNames.LICH] = new TranslatedCardName("Liĉo", "Liĉojn", "Liĉon");

EsperantoCardNames[CardNames.MONKEY] = new TranslatedCardName("Simio", "Simiojn", "Simion");
EsperantoCardNames[CardNames.BLOCKADE] = new TranslatedCardName("Blokado", "Blokadojn", "Blokadon");
EsperantoCardNames[CardNames.SAILOR] = new TranslatedCardName("Maristo", "Maristojn", "Mariston");
EsperantoCardNames[CardNames.PIRATE] = new TranslatedCardName("Pirato", "Piratojn", "Piraton");
EsperantoCardNames[CardNames.CORSAIR] = new TranslatedCardName("Korsaro", "Korsarojn", "Korsaron");
EsperantoCardNames[CardNames.SEA_CHART] = new TranslatedCardName("Marmapo", "Marmapojn", "Marmapon");
EsperantoCardNames[CardNames.ASTROLABE] = new TranslatedCardName("Astrolabo", "Astrolabojn", "Astrolabon");
EsperantoCardNames[CardNames.TIDE_POOLS] = new TranslatedCardName("Tajdujo", "Tajdujojn", "Tajdujon");
EsperantoCardNames[CardNames.SEA_WITCH] = new TranslatedCardName("Mara Sorĉistino", "Marajn Sorĉistinojn", "Maran Sorĉistinon");

EsperantoCardNames[CardNames.ANVIL] = new TranslatedCardName("Amboso", "Ambosojn", "Amboson");
EsperantoCardNames[CardNames.CLERK] = new TranslatedCardName("Komizo", "Komizojn", "Komizon");
EsperantoCardNames[CardNames.INVESTMENT] = new TranslatedCardName("Investado", "Investadojn", "Investadon");
EsperantoCardNames[CardNames.TIARA] = new TranslatedCardName("Tiaro", "Tiarojn", "Tiaron");
EsperantoCardNames[CardNames.CHARLATAN] = new TranslatedCardName("Ĉarlatano", "Ĉarlatanojn", "Ĉarlatanon");
EsperantoCardNames[CardNames.COLLECTION] = new TranslatedCardName("Kolekto", "Kolektojn", "Kolekton");
EsperantoCardNames[CardNames.CRYSTAL_BALL] = new TranslatedCardName("Kristalglobo", "Kristalglobojn", "Kristalglobon");
EsperantoCardNames[CardNames.MAGNATE] = new TranslatedCardName("Magnato", "Magnatojn", "Magnaton");
EsperantoCardNames[CardNames.WAR_CHEST] = new TranslatedCardName("Militkesto", "Militkestojn", "Militkeston");

EsperantoCardNames[CardNames.GUARD_DOG] = new TranslatedCardName("Gardhundo", "Gardhundojn", "Gardhundon");
EsperantoCardNames[CardNames.BERSERKER] = new TranslatedCardName("Berserko", "Berserkojn", "Berserkon");
EsperantoCardNames[CardNames.NOMADS] = new TranslatedCardName("Nomadoj", "Nomadojn", "Nomadojn");
EsperantoCardNames[CardNames.TRAIL] = new TranslatedCardName("Spuro", "Spurojn", "Spuron");
EsperantoCardNames[CardNames.WEAVER] = new TranslatedCardName("Teksisto", "Teksistojn", "Teksiston");
EsperantoCardNames[CardNames.SOUK] = new TranslatedCardName("Soŭko", "Soŭkojn", "Soŭkon");
EsperantoCardNames[CardNames.CAULDRON] = new TranslatedCardName("Kaldrono", "Kaldronojn", "Kaldronon");
EsperantoCardNames[CardNames.WHEELWRIGHT] = new TranslatedCardName("Radfaristo", "Radfaristojn", "Radfariston");
EsperantoCardNames[CardNames.WITCHS_HUT] = new TranslatedCardName("Kabano de Sorĉistino", "Kabanojn de Sorĉistino", "Kabanon de Sorĉistino");

EsperantoCardNames[CardNames.CAGE] = new TranslatedCardName("Kaĝo", "Kaĝojn", "Kaĝon");
EsperantoCardNames[CardNames.GROTTO] = new TranslatedCardName("Groto", "Grotojn", "Groton");
EsperantoCardNames[CardNames.JEWELLED_EGG] = new TranslatedCardName("Juvela Ovo", "Juvelajn Ovojn", "Juvelan Ovon");
EsperantoCardNames[CardNames.SEARCH] = new TranslatedCardName("Serĉo", "Serĉojn", "Serĉon");
EsperantoCardNames[CardNames.SHAMAN] = new TranslatedCardName("Ŝamano", "Ŝamanojn", "Ŝamanon");
EsperantoCardNames[CardNames.SECLUDED_SHRINE] = new TranslatedCardName("Izola Sanktejo", "Izolajn Sanktejojn", "Izolan Sanktejon");
EsperantoCardNames[CardNames.SIREN] = new TranslatedCardName("Sireno", "Sirenojn", "Sirenon");
EsperantoCardNames[CardNames.STOWAWAY] = new TranslatedCardName("Kaŝpasaĝero", "Kaŝpasaĝerojn", "Kaŝpasaĝeron");
EsperantoCardNames[CardNames.TASKMASTER] = new TranslatedCardName("Vokto", "Voktojn", "Vokton");
EsperantoCardNames[CardNames.ABUNDANCE] = new TranslatedCardName("Abundo", "Abundojn", "Abundon");
EsperantoCardNames[CardNames.CABIN_BOY] = new TranslatedCardName("Ŝipknabo", "Ŝipknabojn", "Ŝipknabon");
EsperantoCardNames[CardNames.CRUCIBLE] = new TranslatedCardName("Fandujo", "Fandujojn", "Fandujon");
EsperantoCardNames[CardNames.FLAGSHIP] = new TranslatedCardName("Ĉefŝipo", "Ĉefŝipojn", "Ĉefŝipon");
EsperantoCardNames[CardNames.FORTUNE_HUNTER] = new TranslatedCardName("Fortunĉasisto", "Fortunĉasistojn", "Fortunĉasiston");
EsperantoCardNames[CardNames.GONDOLA] = new TranslatedCardName("Gondolo", "Gondolojn", "a Gondolon");
EsperantoCardNames[CardNames.HARBOR_VILLAGE] = new TranslatedCardName("Havenvilaĝo", "Havenvilaĝojn", "Havenvilaĝon");
EsperantoCardNames[CardNames.LANDING_PARTY] = new TranslatedCardName("Alteriĝantaro", "Alteriĝantarojn", "Alteriĝantaron");
EsperantoCardNames[CardNames.MAPMAKER] = new TranslatedCardName("Mapfaristo", "Mapfaristojn", "Mapfariston");
EsperantoCardNames[CardNames.MAROON] = new TranslatedCardName("Insulforlaso", "Insulforlasojn", "Insulforlason");
EsperantoCardNames[CardNames.ROPE] = new TranslatedCardName("Ŝnuro", "Ŝnurojn", "Ŝnuron");
EsperantoCardNames[CardNames.SWAMP_SHACKS] = new TranslatedCardName("Marĉdomaĉaro", "Marĉdomaĉarojn", "Marĉdomaĉaron");
EsperantoCardNames[CardNames.TOOLS] = new TranslatedCardName("Ilaro", "Ilarojn", "Ilaron");
EsperantoCardNames[CardNames.BURIED_TREASURE] = new TranslatedCardName("Enterigita Trezoro", "Enterigitajn Trezorojn", "Enterigitan Trezoron");
EsperantoCardNames[CardNames.CREW] = new TranslatedCardName("Ŝipanaro", "Ŝipanarojn", "Ŝipanaron");
EsperantoCardNames[CardNames.CUTTHROAT] = new TranslatedCardName("Murdisto", "Murdistojn", "Murdiston");
EsperantoCardNames[CardNames.ENLARGE] = new TranslatedCardName("Pligrandigo", "Pligrandigojn", "Pligrandigon");
EsperantoCardNames[CardNames.FIGURINE] = new TranslatedCardName("Statueto", "Statuetojn", "Statueton");
EsperantoCardNames[CardNames.FIRST_MATE] = new TranslatedCardName("Vickapitano", "Vickapitanojn", "Vickapitanon");
EsperantoCardNames[CardNames.FRIGATE] = new TranslatedCardName("Fregato", "Fregatojn", "Fregaton");
EsperantoCardNames[CardNames.LONGSHIP] = new TranslatedCardName("Drakŝipo", "Drakŝipojn", "Drakŝipon");
EsperantoCardNames[CardNames.MINING_ROAD] = new TranslatedCardName("Minada Vojo", "Minadajn Vojojn", "Minadan Vojon");
EsperantoCardNames[CardNames.PENDANT] = new TranslatedCardName("Pendaĵo", "Pendaĵojn", "Pendaĵon");
EsperantoCardNames[CardNames.PICKAXE] = new TranslatedCardName("Pioĉo", "Pioĉojn", "Pioĉon");
EsperantoCardNames[CardNames.PILGRIM] = new TranslatedCardName("Pilgrimanto", "Pilgrimantojn", "Pilgrimanton");
EsperantoCardNames[CardNames.QUARTERMASTER] = new TranslatedCardName("Ŝipkontisto", "Ŝipkontistojn", "Ŝipkontiston");
EsperantoCardNames[CardNames.SILVER_MINE] = new TranslatedCardName("Arĝentminejo", "Arĝentminejojn", "Arĝentminejon");
EsperantoCardNames[CardNames.TRICKSTER] = new TranslatedCardName("Ruzulo", "Ruzulojn", "Ruzulon");
EsperantoCardNames[CardNames.WEALTHY_VILLAGE] = new TranslatedCardName("Riĉa Vilaĝo", "Riĉajn Vilaĝojn", "Riĉan Vilaĝon");
EsperantoCardNames[CardNames.SACK_OF_LOOT] = new TranslatedCardName("Sako da Akiraĵo", "Sakojn da Akiraĵo", "Sakon da Akiraĵo");
EsperantoCardNames[CardNames.KINGS_CACHE] = new TranslatedCardName("Reĝa Kaŝaĵo", "Reĝajn Kaŝaĵojn", "Reĝan Kaŝaĵon");
EsperantoCardNames[CardNames.BURY] = new TranslatedCardName("Entero", "Enterojn", "Enteron");
EsperantoCardNames[CardNames.AVOID] = new TranslatedCardName("Evito", "Evitojn", "Eviton");
EsperantoCardNames[CardNames.DELIVER] = new TranslatedCardName("Livero", "Liverojn", "Liveron");
EsperantoCardNames[CardNames.PERIL] = new TranslatedCardName("Danĝero", "Danĝerojn", "Danĝeron");
EsperantoCardNames[CardNames.RUSH] = new TranslatedCardName("Rapidigo", "Rapidigojn", "Rapidigon");
EsperantoCardNames[CardNames.FORAY] = new TranslatedCardName("Ekskurso", "Ekskursojn", "Ekskurson");
EsperantoCardNames[CardNames.LAUNCH] = new TranslatedCardName("Lanĉo", "Lanĉojn", "Lanĉon");
EsperantoCardNames[CardNames.MIRROR] = new TranslatedCardName("Spegulado", "Speguladojn", "Speguladon");
EsperantoCardNames[CardNames.PREPARE] = new TranslatedCardName("Preparo", "Preparojn", "Preparon");
EsperantoCardNames[CardNames.SCROUNGE] = new TranslatedCardName("Furaĝado", "Furaĝadojn", "Furaĝadon");
EsperantoCardNames[CardNames.JOURNEY] = new TranslatedCardName("Veturo", "Veturojn", "Veturon");
EsperantoCardNames[CardNames.MAELSTROM] = new TranslatedCardName("Kirlakvego", "Kirlakvegojn", "Kirlakvegon");
EsperantoCardNames[CardNames.LOOTING] = new TranslatedCardName("Rabado", "Rabadojn", "Rabadon");
EsperantoCardNames[CardNames.INVASION] = new TranslatedCardName("Invado", "Invadojn", "Invadon");
EsperantoCardNames[CardNames.PROSPER] = new TranslatedCardName("Prosperado", "Prosperadojn", "Prosperadon");
EsperantoCardNames[CardNames.LOOT_PILE] = new TranslatedCardName("Akiraĵstako");
EsperantoCardNames[CardNames.AMPHORA] = new TranslatedCardName("Amforo", "Amforojn", "Amforon");
EsperantoCardNames[CardNames.DOUBLOONS] = new TranslatedCardName("Dublonaro", "Dublonarojn", "Dublonaron");
EsperantoCardNames[CardNames.ENDLESS_CHALICE] = new TranslatedCardName("Ĉiamplena Kaliko", "Ĉiamplenajn Kalikojn", "Ĉiamplenan Kalikon");
EsperantoCardNames[CardNames.FIGUREHEAD] = new TranslatedCardName("Prufiguro", "Prufigurojn", "Prufiguron");
EsperantoCardNames[CardNames.HAMMER] = new TranslatedCardName("Martelo", "Martelojn", "Martelon");
EsperantoCardNames[CardNames.INSIGNIA] = new TranslatedCardName("Insigno", "Insignojn", "Insignon");
EsperantoCardNames[CardNames.JEWELS] = new TranslatedCardName("Juvelaro", "Juvelarojn", "Juvelaron");
EsperantoCardNames[CardNames.ORB] = new TranslatedCardName("Orbo", "Orbojn", "Orbon");
EsperantoCardNames[CardNames.PRIZE_GOAT] = new TranslatedCardName("Karvalora Kapro", "Karvalorajn Kaprojn", "Karvaloran Kapron");
EsperantoCardNames[CardNames.PUZZLE_BOX] = new TranslatedCardName("Enigmokesto", "Enigmokestojn", "Enigmokeston");
EsperantoCardNames[CardNames.SEXTANT] = new TranslatedCardName("Sekstanto", "Sekstantojn", "Sekstanton");
EsperantoCardNames[CardNames.SHIELD] = new TranslatedCardName("Ŝildo", "Ŝildojn", "Ŝildon");
EsperantoCardNames[CardNames.SPELL_SCROLL] = new TranslatedCardName("Sorĉrulaĵo", "Sorĉrulaĵojn", "Sorĉrulaĵon");
EsperantoCardNames[CardNames.STAFF] = new TranslatedCardName("Bastono", "Bastonojn", "Bastonon");
EsperantoCardNames[CardNames.SWORD] = new TranslatedCardName("Glavo", "Glavojn", "Glavon");

// Missing lines as of 09.01.2023 19:11:45
EsperantoCardNames[CardNames.CHEAP] = new TranslatedCardName("Cheap", "Cheaps", "a Cheap");
EsperantoCardNames[CardNames.CURSED] = new TranslatedCardName("Cursed", "Curseds", "a Cursed");
EsperantoCardNames[CardNames.FATED] = new TranslatedCardName("Fated", "Fateds", "a Fated");
EsperantoCardNames[CardNames.FAWNING] = new TranslatedCardName("Fawning", "Fawnings", "a Fawning");
EsperantoCardNames[CardNames.FRIENDLY] = new TranslatedCardName("Friendly", "Friendlies", "a Friendly");
EsperantoCardNames[CardNames.HASTY] = new TranslatedCardName("Hasty", "Hasties", "a Hasty");
EsperantoCardNames[CardNames.INHERITED] = new TranslatedCardName("Inherited", "Inheriteds", "an Inherited");
EsperantoCardNames[CardNames.INSPIRING] = new TranslatedCardName("Inspiring", "Inspirings", "an Inspiring");
EsperantoCardNames[CardNames.NEARBY] = new TranslatedCardName("Nearby", "Nearbys", "an Nearby");
EsperantoCardNames[CardNames.PATIENT] = new TranslatedCardName("Patient", "Patients", "a Patient");
EsperantoCardNames[CardNames.PIOUS] = new TranslatedCardName("Pious", "Piouses", "a Pious");
EsperantoCardNames[CardNames.RECKLESS] = new TranslatedCardName("Reckless", "Recklesses", "a Reckless");
EsperantoCardNames[CardNames.RICH] = new TranslatedCardName("Rich", "Riches", "a Rich");
EsperantoCardNames[CardNames.SHY] = new TranslatedCardName("Shy", "Shies", "a Shy");
EsperantoCardNames[CardNames.TIRELESS] = new TranslatedCardName("Tireless", "Tirelesses", "a Tireless");
EsperantoCardNames[CardNames.MARCHLAND] = new TranslatedCardName("Marchland", "Marchlands", "a Marchland");
EsperantoCardNames[CardNames.SHOP] = new TranslatedCardName("Shop", "Shops", "a Shop");
EsperantoCardNames[CardNames.CARNIVAL] = new TranslatedCardName("Carnival", "Carnivals", "a Carnival");
EsperantoCardNames[CardNames.FERRYMAN] = new TranslatedCardName("Ferryman", "Ferrymen", "a Ferryman");
EsperantoCardNames[CardNames.FARMHANDS] = new TranslatedCardName("Farmhands", "Farmhands", "a Farmhands");
EsperantoCardNames[CardNames.JOUST] = new TranslatedCardName("Joust", "Jousts", "a Joust");
EsperantoCardNames[CardNames.REWARDS_PILE] = new TranslatedCardName("Rewards", "Rewards", "Rewards");
EsperantoCardNames[CardNames.COURSER] = new TranslatedCardName("Courser", "Coursers", "a Courser");
EsperantoCardNames[CardNames.RENOWN] = new TranslatedCardName("Renown", "Renowns", "a Renown");
EsperantoCardNames[CardNames.CORONET] = new TranslatedCardName("Coronet", "Coronets", "a Coronet");
EsperantoCardNames[CardNames.DEMESNE] = new TranslatedCardName("Demesne", "Demesnes", "a Demesne");
EsperantoCardNames[CardNames.HOUSECARL] = new TranslatedCardName("Housecarl", "Housecarls", "a Housecarl");
EsperantoCardNames[CardNames.HUGE_TURNIP] = new TranslatedCardName("Huge Turnip", "Huge Turnips", "a Huge Turnip");
EsperantoCardNames[CardNames.FARRIER] = new TranslatedCardName("Farrier", "Farriers", "a Farrier");
EsperantoCardNames[CardNames.INFIRMARY] = new TranslatedCardName("Infirmary", "Infirmaries", "an Infirmary");
EsperantoCardNames[CardNames.FOOTPAD] = new TranslatedCardName("Footpad", "Footpads", "a Footpad");
EsperantoCardNames[CardNames.FISHMONGER] = new TranslatedCardName("Fishmonger", "Fishmongers", "a Fishmonger");
EsperantoCardNames[CardNames.SNAKE_WITCH] = new TranslatedCardName("Snake Witch", "Snake Witches", "a Snake Witch");
EsperantoCardNames[CardNames.ARISTOCRAT] = new TranslatedCardName("Aristocrat", "Aristocrats", "an Aristocrat");
EsperantoCardNames[CardNames.CRAFTSMAN] = new TranslatedCardName("Craftsman", "Craftsmen", "a Craftsman");
EsperantoCardNames[CardNames.RIVERBOAT] = new TranslatedCardName("Riverboat", "Riverboats", "a Riverboat");
EsperantoCardNames[CardNames.ROOT_CELLAR] = new TranslatedCardName("Root Cellar", "Root Cellars", "a Root Cellar");
EsperantoCardNames[CardNames.ALLEY] = new TranslatedCardName("Alley", "Alleys", "an Alley");
EsperantoCardNames[CardNames.CHANGE] = new TranslatedCardName("Change", "Changes", "a Change");
EsperantoCardNames[CardNames.NINJA] = new TranslatedCardName("Ninja", "Ninjas", "a Ninja");
EsperantoCardNames[CardNames.POET] = new TranslatedCardName("Poet", "Poets", "a Poet");
EsperantoCardNames[CardNames.RIVER_SHRINE] = new TranslatedCardName("River Shrine", "River Shrines", "a River Shrine");
EsperantoCardNames[CardNames.RUSTIC_VILLAGE] = new TranslatedCardName("Rustic Village", "Rustic Villages", "a Rustic Village");
EsperantoCardNames[CardNames.GOLD_MINE] = new TranslatedCardName("Gold Mine", "Gold Mines", "a Gold Mine");
EsperantoCardNames[CardNames.IMPERIAL_ENVOY] = new TranslatedCardName("Imperial Envoy", "Imperial Envoys", "an Imperial Envoy");
EsperantoCardNames[CardNames.KITSUNE] = new TranslatedCardName("Kitsune", "Kitsunes", "a Kitsune");
EsperantoCardNames[CardNames.LITTER] = new TranslatedCardName("Litter", "Litters", "a Litter");
EsperantoCardNames[CardNames.RICE_BROKER] = new TranslatedCardName("Rice Broker", "Rice Brokers", "a Rice Broker");
EsperantoCardNames[CardNames.RONIN] = new TranslatedCardName("Ronin", "Ronins", "a Ronin");
EsperantoCardNames[CardNames.TANUKI] = new TranslatedCardName("Tanuki", "Tanukis", "a Tanuki");
EsperantoCardNames[CardNames.TEA_HOUSE] = new TranslatedCardName("Tea House", "Tea Houses", "a Tea House");
EsperantoCardNames[CardNames.SAMURAI] = new TranslatedCardName("Samurai", "Samurais", "a Samurai");
EsperantoCardNames[CardNames.RICE] = new TranslatedCardName("Rice", "Rices", "a Rice");
EsperantoCardNames[CardNames.MOUNTAIN_SHRINE] = new TranslatedCardName("Mountain Shrine", "Mountain Shrines", "a Mountain Shrine");
EsperantoCardNames[CardNames.DAIMYO] = new TranslatedCardName("Daimyo", "Daimyos", "a Daimyo");
EsperantoCardNames[CardNames.ARTIST] = new TranslatedCardName("Artist", "Artists", "an Artist");
EsperantoCardNames[CardNames.APPROACHING_ARMY] = new TranslatedCardName("Approaching Army");
EsperantoCardNames[CardNames.BIDING_TIME] = new TranslatedCardName("Biding Time");
EsperantoCardNames[CardNames.BUREAUCRACY] = new TranslatedCardName("Bureaucracy");
EsperantoCardNames[CardNames.DIVINE_WIND] = new TranslatedCardName("Divine Wind");
EsperantoCardNames[CardNames.ENLIGHTENMENT] = new TranslatedCardName("Enlightenment");
EsperantoCardNames[CardNames.FLOURISHING_TRADE] = new TranslatedCardName("Flourishing Trade");
EsperantoCardNames[CardNames.GOOD_HARVEST] = new TranslatedCardName("Good Harvest");
EsperantoCardNames[CardNames.GREAT_LEADER] = new TranslatedCardName("Great Leader");
EsperantoCardNames[CardNames.GROWTH] = new TranslatedCardName("Growth");
EsperantoCardNames[CardNames.HARSH_WINTER] = new TranslatedCardName("Harsh Winter");
EsperantoCardNames[CardNames.KIND_EMPEROR] = new TranslatedCardName("Kind Emperor");
EsperantoCardNames[CardNames.PANIC] = new TranslatedCardName("Panic");
EsperantoCardNames[CardNames.PROGRESS] = new TranslatedCardName("Progress");
EsperantoCardNames[CardNames.RAPID_EXPANSION] = new TranslatedCardName("Rapid Expansion");
EsperantoCardNames[CardNames.SICKNESS] = new TranslatedCardName("Sickness");
EsperantoCardNames[CardNames.AMASS] = new TranslatedCardName("Amass");
EsperantoCardNames[CardNames.ASCETICISM] = new TranslatedCardName("Asceticism");
EsperantoCardNames[CardNames.CREDIT] = new TranslatedCardName("Credit");
EsperantoCardNames[CardNames.FORESIGHT] = new TranslatedCardName("Foresight");
EsperantoCardNames[CardNames.KINTSUGI] = new TranslatedCardName("Kintsugi");
EsperantoCardNames[CardNames.PRACTICE] = new TranslatedCardName("Practice");
EsperantoCardNames[CardNames.SEA_TRADE] = new TranslatedCardName("Sea Trade");
EsperantoCardNames[CardNames.RECEIVE_TRIBUTE] = new TranslatedCardName("Receive Tribute");
EsperantoCardNames[CardNames.GATHER] = new TranslatedCardName("Gather");
EsperantoCardNames[CardNames.CONTINUE] = new TranslatedCardName("Continue");