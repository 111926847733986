"use strict";

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function getPhrase(phrase) {
    return LANGUAGE.getPhrase[phrase];
}

function getTableRuleText(id) {
    return LANGUAGE.getTableRule[id];
}

function getSingularCardName(cardName) {
    return LANGUAGE.getCardName[cardName].singular;
}

function getTranslatedTypeString(cardName, cardTypes) {
    var types = cardTypes || cardName.types.concat([]);
    return ObjectValues(Types).filter(function (t) {
        return types.includes(t);
    }).map(function (t) {
        return LANGUAGE.getType[t];
    }).join(' - ');
}

function getTranslatedMarkerString(card, cardStack) {
    if (cardStack !== null) {
        var location = cardStack.location;
        if (location !== null) {
            var markers = location.markers.map(function (m) {
                return LANGUAGE.getPileMarker[m.type];
            });
            if (location.pileName !== null) markers.push.apply(markers, _toConsumableArray(location.pileName.types.filter(function (t) {
                return t.isShownOnPile;
            }).map(function (t) {
                return LANGUAGE.getType[t];
            })));
            return markers.join("/");
        }
    }
    return "";
}

var PHRASES = {};
var PARAGRAPHS = LANGUAGE.paragraphs;

Object.keys(Phrases).forEach(function (key) {
    return PHRASES[key] = LANGUAGE.getPhrase[Phrases[key]];
});

function getDirectionalArticle(direction) {
    if (isUndefined(direction)) {
        return getPhrase(Phrases.THE);
    } else if (direction === Directions.TO) {
        return getPhrase(Phrases.THE_TO);
    } else if (direction === Directions.FROM) {
        return getPhrase(Phrases.THE_FROM);
    } else {
        throw new Error("Unknown direction");
    }
}