"use strict";

var EnglishPhrases = {};

EnglishPhrases[Phrases.THE] = "the";
EnglishPhrases[Phrases.THE_FROM] = "the";
EnglishPhrases[Phrases.THE_TO] = "the";
EnglishPhrases[Phrases.AND] = "and";
EnglishPhrases[Phrases.OR] = "or";
EnglishPhrases[Phrases.OTHER] = "other";
EnglishPhrases[Phrases.ANOTHER] = "another";
EnglishPhrases[Phrases.PILE] = "pile";
EnglishPhrases[Phrases.QUICK_UNDO] = "Undo 1";
EnglishPhrases[Phrases.UNDO_BUTTON] = "Rewind";
EnglishPhrases[Phrases.UNDO] = "Undo";
EnglishPhrases[Phrases.YOU] = "You";
EnglishPhrases[Phrases.WOULD] = "would";
EnglishPhrases[Phrases.YOU_MAY] = "You may...";
EnglishPhrases[Phrases.COMMA] = ", ";
EnglishPhrases[Phrases.PERIOD] = ".";
EnglishPhrases[Phrases.COMPLEX_STORY_LINE] = "FIRST, SECOND";
EnglishPhrases[Phrases.COIN] = "Coin";
EnglishPhrases[Phrases.COINS] = "Coins";
EnglishPhrases[Phrases.COINS_MANY] = "Coins";
EnglishPhrases[Phrases.POTION] = "Potion";
EnglishPhrases[Phrases.POTIONS] = "Potions";
EnglishPhrases[Phrases.POTIONS_MANY] = "Potions";
EnglishPhrases[Phrases.DEBT] = "Debt";

EnglishPhrases[Phrases.VERSION] = "Version";
EnglishPhrases[Phrases.USERNAME] = "Username";
EnglishPhrases[Phrases.PASSWORD] = "Password";
EnglishPhrases[Phrases.CONFIRM_PASSWORD] = "Confirm Password";
EnglishPhrases[Phrases.FORGOT_YOUR_PASSWORD] = "Forgot your password?";
EnglishPhrases[Phrases.CREATE_ACCOUNT] = "Create Account";
EnglishPhrases[Phrases.EMAIL] = "Email";
EnglishPhrases[Phrases.SIGN_UP] = "Sign Up";
EnglishPhrases[Phrases.LOGIN] = "Login";
EnglishPhrases[Phrases.KICK] = "Kick";
EnglishPhrases[Phrases.RESIGN] = "Resign";
EnglishPhrases[Phrases.CREATE_TABLE] = "Create Table";
EnglishPhrases[Phrases.LEAVE_TABLE] = "Leave Table";
EnglishPhrases[Phrases.MESSAGE] = "message";
EnglishPhrases[Phrases.START_GAME] = "Start Game";
EnglishPhrases[Phrases.READY] = "Ready";
EnglishPhrases[Phrases.NOT_READY] = "Not Ready";
EnglishPhrases[Phrases.PLAYERS] = "Players";
EnglishPhrases[Phrases.SPECTATORS] = "Spectators";
EnglishPhrases[Phrases.HOST] = "Host";
EnglishPhrases[Phrases.RANDOM] = "Random";
EnglishPhrases[Phrases.BASIC_OPTIONS] = "Basic Options";
EnglishPhrases[Phrases.ADVANCED_OPTIONS] = "Advanced Options";
EnglishPhrases[Phrases.YES] = "Yes";
EnglishPhrases[Phrases.NO] = "No";
EnglishPhrases[Phrases.FRIENDS_ONLY] = "Friends only";
EnglishPhrases[Phrases.EVENTS_AND_LANDMARKS] = "Events and Landmarks";
EnglishPhrases[Phrases.TRUE] = "true";
EnglishPhrases[Phrases.FALSE] = "false";
EnglishPhrases[Phrases.QUICK_MATCH] = "Quick Match";
EnglishPhrases[Phrases.GOOD_MATCH] = "Good Match";
EnglishPhrases[Phrases.CANCEL] = "Cancel";
EnglishPhrases[Phrases.VIEW_TRASH] = "Trash";
EnglishPhrases[Phrases.VIEW_KINGDOM] = "Kingdom";
EnglishPhrases[Phrases.VIEW_PLAY_AREA] = "Play Area";
EnglishPhrases[Phrases.LEAVE] = "Leave";
EnglishPhrases[Phrases.TOTAL_IN_QUEUE] = "Total users in queue";
EnglishPhrases[Phrases.EDIT_TABLE] = "Edit Table";
EnglishPhrases[Phrases.NEED_HELP] = "Need help?";
EnglishPhrases[Phrases.PLAY] = "Play";
EnglishPhrases[Phrases.MAKE_RESIGN] = "Make USERNAME resign?";
EnglishPhrases[Phrases.UNDO_REQUEST_SINGULAR] = "Request to undo 1 step";
EnglishPhrases[Phrases.UNDO_REQUEST_PLURAL] = "Request to undo NUMBER steps";
EnglishPhrases[Phrases.UNDO_REQUEST_MANY] = "Request to undo NUMBER steps";
EnglishPhrases[Phrases.PENDING_UNDO_REQUEST] = "Pending undo request...";
EnglishPhrases[Phrases.SPECTATOR_UNDO_REQUEST] = "USERNAME has requested an undo";
EnglishPhrases[Phrases.GRANT] = "Grant";
EnglishPhrases[Phrases.ACCEPT] = "Accept";
EnglishPhrases[Phrases.DECLINE] = "Decline";
EnglishPhrases[Phrases.DENY] = "Deny";
EnglishPhrases[Phrases.UNDO_DENIED] = "Undo request was denied.";
EnglishPhrases[Phrases.UNDO_CANCELLED] = "Undo request was cancelled.";
EnglishPhrases[Phrases.TOC_SENTENCE] = "I have read and accept the TERMS_OF_SERVICE.";
EnglishPhrases[Phrases.TERMS_OF_SERVICE] = "Terms of Service";
EnglishPhrases[Phrases.TERMS_OF_SERVICE_URL] = ""; /* edit it after writing translated terms of service */
EnglishPhrases[Phrases.RECONNECTING] = "Reconnecting...";
EnglishPhrases[Phrases.PLAYER_JOINED_TABLE] = "PLAYER_NAME joined the table.";
EnglishPhrases[Phrases.PLAYER_LEFT_TABLE] = "PLAYER_NAME left the table.";
EnglishPhrases[Phrases.JOINING_GAME_MESSAGE] = "Joining game #NUMBER";
EnglishPhrases[Phrases.SIGNING_UP] = "Signing you up...";
EnglishPhrases[Phrases.ACTION] = "Action";
EnglishPhrases[Phrases.ACTIONS] = "Actions";
EnglishPhrases[Phrases.BUY] = "Buy";
EnglishPhrases[Phrases.BUYS] = "Buys";
EnglishPhrases[Phrases.KICK_SUCCEEDED] = "Kick Succeeded";
EnglishPhrases[Phrases.CONNECTION_CLOSED] = "Server connection was closed or could not be established.";
EnglishPhrases[Phrases.NEW_PASSWORD] = "New Password";
EnglishPhrases[Phrases.RETRY] = "Retry";
EnglishPhrases[Phrases.FRIEND] = "Friend";
EnglishPhrases[Phrases.ACTIVITY] = "Activity";
EnglishPhrases[Phrases.STATUS] = "Status";
EnglishPhrases[Phrases.PURCHASE] = "Purchase";
EnglishPhrases[Phrases.UNTIL] = "until";
EnglishPhrases[Phrases.BUY_FOR_AMOUNT] = "Buy for AMOUNT";
EnglishPhrases[Phrases.CHANGE_PASSWORD] = "Change";
EnglishPhrases[Phrases.HIDE] = "Hide";
EnglishPhrases[Phrases.CONFIRM] = "Confirm";
EnglishPhrases[Phrases.JOIN] = "join";
EnglishPhrases[Phrases.TURN] = "Turn";
EnglishPhrases[Phrases.CARD] = "Card";
EnglishPhrases[Phrases.DESCRIPTION] = "Description";
EnglishPhrases[Phrases.SCORE] = "Score";
EnglishPhrases[Phrases.TURNS] = "Turns";
EnglishPhrases[Phrases.NOTHING] = "nothing";
EnglishPhrases[Phrases.SPECTATE] = "Spectate";
EnglishPhrases[Phrases.FRIENDS] = "Friends";
EnglishPhrases[Phrases.FOLLOWING] = "Following";
EnglishPhrases[Phrases.FOLLOWERS] = "Followers";
EnglishPhrases[Phrases.UNFRIEND] = "Unfriend";
EnglishPhrases[Phrases.UNFOLLOW] = "Unfollow";
EnglishPhrases[Phrases.BEFRIEND] = "Befriend";
EnglishPhrases[Phrases.FRIENDS_EXPLANATION] = "Mutual friend";
EnglishPhrases[Phrases.FOLLOWING_EXPLANATION] = "You befriended them";
EnglishPhrases[Phrases.FOLLOWERS_EXPLANATION] = "They befriended you";
EnglishPhrases[Phrases.ADD] = "Add";
EnglishPhrases[Phrases.TYPE_FRIEND] = "Type friend's name";
EnglishPhrases[Phrases.UNLOCKS] = "unlocks";
EnglishPhrases[Phrases.SELECT_YOUR_COUNTRY] = "Select your country";
EnglishPhrases[Phrases.SELECT_A_COUNTRY] = "Select a country";
EnglishPhrases[Phrases.KICK_AND_RESIGN] = "Kick & Resign";
EnglishPhrases[Phrases.BETWEEN_TURNS] = "Between Turns";
EnglishPhrases[Phrases.PLAYER] = "Player";
EnglishPhrases[Phrases.RESIGNED] = "resigned";
EnglishPhrases[Phrases.YOU_RESIGNED] = "You Resigned";
EnglishPhrases[Phrases.YOU_LOSE] = "You Lose";
EnglishPhrases[Phrases.YOU_TIE] = "You Tie";
EnglishPhrases[Phrases.YOU_WIN] = "You Win";
EnglishPhrases[Phrases.BUYS_MANY] = "Buys";
EnglishPhrases[Phrases.ACTIONS_MANY] = "Actions";
EnglishPhrases[Phrases.TOKEN] = "Coffers";
EnglishPhrases[Phrases.TOKENS] = "Coffers";
EnglishPhrases[Phrases.TOKENS_MANY] = "Coffers";
EnglishPhrases[Phrases.OVERPAY] = "Overpay";
EnglishPhrases[Phrases.BID] = "Bid";
EnglishPhrases[Phrases.TAKE] = "Take";
EnglishPhrases[Phrases.BOTTOM] = "Bottom";
EnglishPhrases[Phrases.TOP] = "Top";
EnglishPhrases[Phrases.LOCATION] = "Location";
EnglishPhrases[Phrases.CLEAN_UP] = "Clean-up";
EnglishPhrases[Phrases.PLAN_CLEAN_UP] = "Plan Your Clean-up";
EnglishPhrases[Phrases.EXECUTE] = "Execute";
EnglishPhrases[Phrases.MOVEMENT_VERB] = "VERB.";
EnglishPhrases[Phrases.MOVEMENT_VERB_OBJECT] = "VERB OBJECT.";
EnglishPhrases[Phrases.FORUM] = "Forum";
EnglishPhrases[Phrases.VIEW_LOG] = "Log";
EnglishPhrases[Phrases.REPLAY_GAME] = "Replay Game";
EnglishPhrases[Phrases.REMOVE_REPLAY] = "Remove Replay";
EnglishPhrases[Phrases.SHELTERS] = "Shelters";
EnglishPhrases[Phrases.NO_SHELTERS] = "No Shelters";
EnglishPhrases[Phrases.COLONIES] = "Colonies";
EnglishPhrases[Phrases.NO_COLONIES] = "No Colonies";
EnglishPhrases[Phrases.PLAYER_ORDER] = "Player Order";
EnglishPhrases[Phrases.ENTER_USERNAME] = "Please enter your username.";
EnglishPhrases[Phrases.ENTER_LOGIN_CREDENTIALS] = "Please enter your login credentials.";
EnglishPhrases[Phrases.SUBMIT] = "Submit";
EnglishPhrases[Phrases.DONE] = "Done";
EnglishPhrases[Phrases.GAME_ID] = "Game Id";
EnglishPhrases[Phrases.DECISION_INDEX] = "Decision Index";
EnglishPhrases[Phrases.ORIGINAL_PLAYER] = "Original Player";
EnglishPhrases[Phrases.ORIGINAL_PLAYERS] = "original players";
EnglishPhrases[Phrases.ADD_BOT] = "Add Bot";
EnglishPhrases[Phrases.DECISION] = "Decision";
EnglishPhrases[Phrases.LOAD_GAME] = "Load Game";
EnglishPhrases[Phrases.CHANGE_REPLAY] = "Change Replay";
EnglishPhrases[Phrases.NAME_CARD] = "name a card";
EnglishPhrases[Phrases.LOAD_OLD_GAME] = "Load Old Game";
EnglishPhrases[Phrases.BOT] = "Bot";
EnglishPhrases[Phrases.CANCEL_RESIGN] = "Cancel & Resign";
EnglishPhrases[Phrases.LOGOUT] = "Logout";
EnglishPhrases[Phrases.FIRST] = "First";
EnglishPhrases[Phrases.LAST] = "Last";
EnglishPhrases[Phrases.INBOX] = "Inbox";
EnglishPhrases[Phrases.MESSAGES] = "Messages";
EnglishPhrases[Phrases.MARK_READ] = "Mark as Read";
EnglishPhrases[Phrases.DELETE] = "Delete";
EnglishPhrases[Phrases.NO_MESSAGES] = "No Messages";
EnglishPhrases[Phrases.LEADERBOARD] = "Leaderboard";
EnglishPhrases[Phrases.LAST_UPDATED] = "Last Updated";
EnglishPhrases[Phrases.RANK] = "#";
EnglishPhrases[Phrases.NAME] = "name";
EnglishPhrases[Phrases.RATING] = "level";
EnglishPhrases[Phrases.TREND] = "";
EnglishPhrases[Phrases.VP] = "VP";
EnglishPhrases[Phrases.GAME_ID_SHOULD_BE_NUMBER] = "Game Id should be a number.";
EnglishPhrases[Phrases.FAQ] = "FAQ";
EnglishPhrases[Phrases.RESULTS] = "Results";
EnglishPhrases[Phrases.SKILL] = "μ";
EnglishPhrases[Phrases.SKILL_RANGE] = "Skill-range";
EnglishPhrases[Phrases.DEVIATION] = "φ";
EnglishPhrases[Phrases.VOLATILITY] = "σ";
EnglishPhrases[Phrases.GAMES] = "Games";
EnglishPhrases[Phrases.GLICKO2] = "Glicko-2";
EnglishPhrases[Phrases.IMPLEMENTATION] = "Implementation";
EnglishPhrases[Phrases.RATED_QUEUE] = "Users in rated queue";
EnglishPhrases[Phrases.UNRATED_QUEUE] = "Users in unrated queue";
EnglishPhrases[Phrases.UNRATED_MATCH] = "Unrated Match";
EnglishPhrases[Phrases.LEVEL] = "level";
EnglishPhrases[Phrases.TWO_PLAYERS] = "2 Players";
EnglishPhrases[Phrases.THREE_FOUR_PLAYERS] = "3-4 Players";
EnglishPhrases[Phrases.GAME] = "Game";
EnglishPhrases[Phrases.RATED] = "rated";
EnglishPhrases[Phrases.UNRATED] = "unrated";
EnglishPhrases[Phrases.OTHER_CARDS] = "others";
EnglishPhrases[Phrases.OTHER_CARD] = "other";
EnglishPhrases[Phrases.YOUR_TURN] = "Your turn!";
EnglishPhrases[Phrases.LOADING_GAME] = "Loading game...";
EnglishPhrases[Phrases.BLACKLIST] = "Blocklist";
EnglishPhrases[Phrases.REMOVE] = "Remove";
EnglishPhrases[Phrases.BLACKLIST_USER] = "Block this user";
EnglishPhrases[Phrases.BEFRIEND_USER] = "Befriend this user";
EnglishPhrases[Phrases.BLACKLISTED] = "Blocked";
EnglishPhrases[Phrases.FRIENDLIST_EMPTY] = "Your friend list is currently empty.";
EnglishPhrases[Phrases.BLACKLIST_EMPTY] = "Your blocklist is currently empty.";
EnglishPhrases[Phrases.PLAYERS_OF_TOTAL_PLAYERS] = "NUM_PLAYERS of NUM_TOTAL_PLAYERS";
EnglishPhrases[Phrases.SHOW_RUNNING_GAMES] = "Show running games";
EnglishPhrases[Phrases.SHOW_1P_GAMES] = "Show 1 player games";
EnglishPhrases[Phrases.SHOW_2P_GAMES] = "Show 2 player games";
EnglishPhrases[Phrases.SHOW_3XP_GAMES] = "Show 3+ player games";
EnglishPhrases[Phrases.SHOW_BOT_GAMES] = "Show bot games";
EnglishPhrases[Phrases.FIND_GAME] = "Automatch";
EnglishPhrases[Phrases.FIND_BOT_GAME] = "Bot Game";
EnglishPhrases[Phrases.START_SEARCH] = "Start search";
EnglishPhrases[Phrases.CANCEL_SEARCH] = "Cancel search";
EnglishPhrases[Phrases.SEARCHING_2P] = "Two player";
EnglishPhrases[Phrases.SEARCHING_3P] = "Three player";
EnglishPhrases[Phrases.SEARCHING_RATED] = "Rated game";
EnglishPhrases[Phrases.SEARCHING_PRACTICE] = "Practice game";
EnglishPhrases[Phrases.MAX] = "Max";
EnglishPhrases[Phrases.MIN] = "Min";
EnglishPhrases[Phrases.SEARCHING] = "searching";
EnglishPhrases[Phrases.START_BOT_GAME_1] = "1 Bot";
EnglishPhrases[Phrases.START_BOT_GAME_2] = "2 Bots";
EnglishPhrases[Phrases.START_BOT_GAME_3] = "3 Bots";
EnglishPhrases[Phrases.NO_FRIENDS_ONLINE] = "No friends online";
EnglishPhrases[Phrases.AUTOMATCH_FEEDBACK] = "Automatch queue";
EnglishPhrases[Phrases.NOBODY_ELSE_IN_QUEUE] = "Nobody else in the queue.";
EnglishPhrases[Phrases.CANCEL_SMALL] = "Cancel";
EnglishPhrases[Phrases.MESSAGE_SPECTATORS] = "message spectators";
EnglishPhrases[Phrases.MESSAGE_PLAYERS] = "message players";
EnglishPhrases[Phrases.PLAYER_REPLACED] = "LEAVING_PLAYER was replaced by REPLACEMENT.";
EnglishPhrases[Phrases.RESIGN_PROMPT] = "Resign?";
EnglishPhrases[Phrases.RESIGN_MULTIPLE_OPPONENTS] = "Warning: Resigning a game with multiple opponents could be considered bad sportsmanship.";
EnglishPhrases[Phrases.CONTINUE_WITH_BOTS] = "Continue with bots";
EnglishPhrases[Phrases.PLAYER_DISCONNECTED] = "PLAYER_NAME disconnected.";
EnglishPhrases[Phrases.PLAYER_RECONNECTED] = "PLAYER_NAME reconnected.";
EnglishPhrases[Phrases.VALIDATE_BONUS_CODE] = "Validate";
EnglishPhrases[Phrases.NEW_TO_DOMINION] = "How to Play";
EnglishPhrases[Phrases.SEARCHING_PREVIEWS] = "Menagerie Previews";
EnglishPhrases[Phrases.AUTOPLAY_CLEANUP] = "Cleanup phase autoplays";
EnglishPhrases[Phrases.AUTOPLAY_INTERFACE] = "Default autoplays";
EnglishPhrases[Phrases.AUTOPLAY_OTHER] = "Other autoplays";
EnglishPhrases[Phrases.GAME_ENDED] = "The game has ended.";
EnglishPhrases[Phrases.OK] = "Ok";
EnglishPhrases[Phrases.HAS_RESIGNED] = "PLAYER_NAME has resigned.";
EnglishPhrases[Phrases.MOUSE_TOY] = "Card set aside for Way of the Mouse";
// Intro page of Store tab in Lobby
EnglishPhrases[Phrases.STORE_WELCOME] = "Dominion Online Store";
EnglishPhrases[Phrases.ONE_MONTH] = "1 month";
EnglishPhrases[Phrases.TWO_MONTHS] = "2 months";
EnglishPhrases[Phrases.THREE_MONTHS] = "3 months";
EnglishPhrases[Phrases.SIX_MONTHS] = "6 months";
EnglishPhrases[Phrases.NINE_MONTHS] = "9 months";
EnglishPhrases[Phrases.FIFTEEN_MONTHS] = "15 months";
EnglishPhrases[Phrases.EIGHTEEN_MONTHS] = "18 months";
EnglishPhrases[Phrases.ONE_YEAR] = "1 year";
EnglishPhrases[Phrases.TWO_YEAR] = "2 year";
EnglishPhrases[Phrases.CORE_SUBSCRIPTION] = "Core subscription";
EnglishPhrases[Phrases.COMPLETE_SUBSCRIPTION] = "Complete subscription";
EnglishPhrases[Phrases.CORE_SUBSCRIPTION_PRICE] = "(€ 2.15 / month)";
EnglishPhrases[Phrases.CORE_SUBSCRIPTION_EXPLANATION] = "The simpler half of the cards";
EnglishPhrases[Phrases.COMPLETE_SUBSCRIPTION_PRICE] = "(€ 4.30 / month)";
EnglishPhrases[Phrases.COMPLETE_SUBSCRIPTION_EXPLANATION] = "All the cards";
EnglishPhrases[Phrases.CHOOSE_SUBSCRIPTION] = "Choose your subscription: ";
EnglishPhrases[Phrases.CHOOSE_DURATION] = "Choose the duration of your subscription, starting from today.";
EnglishPhrases[Phrases.CONTINUE] = "Continue";
EnglishPhrases[Phrases.ADD_LATEST_EXPANSION] = "Do you want to add Menagerie to your current subscription?";
EnglishPhrases[Phrases.INTRODUCTION_TO_STORE] = "Introduction to store";
EnglishPhrases[Phrases.CHECK_YOUR_PURCHASE] = "Check the details of your purchase";
EnglishPhrases[Phrases.SELLER_INFO] = "Seller info";
EnglishPhrases[Phrases.INVOICE_NEED_ANSWERS] = "To view the invoice you need valid answers to all questions above.";
EnglishPhrases[Phrases.BUYING_SUBSCRIPTION_TO] = "Buying subscription to";
EnglishPhrases[Phrases.BUYING_SUBSCRIPTION_TILL] = "Buying subscription till";
EnglishPhrases[Phrases.AMOUNT] = "Amount";
EnglishPhrases[Phrases.ITEM] = "Item";
EnglishPhrases[Phrases.YOUR_SUBSCRIPTION] = "Your Subscription ";
EnglishPhrases[Phrases.YOUR_ACCOUNT] = "Your Account ";
EnglishPhrases[Phrases.REDIRECTED_TO_PAYMENT_PROVIDER] = "Redirecting to payment provider in new browser tab...";
EnglishPhrases[Phrases.PAYMENT_REDIRECTION_FAILED] = "If you were not redirected, make sure you browser allows opening new tabs.";
EnglishPhrases[Phrases.REDIRECT_FAILED_BUTTON] = "Click when redirection failed";
EnglishPhrases[Phrases.REDIRECT_YOURSELF] = "If you are not redirected automatically, click";
EnglishPhrases[Phrases.THIS_LINK] = "this link";
EnglishPhrases[Phrases.ZERO_INVOICE_1] = "Your selected purchase does not add anything to your current subscription.";
EnglishPhrases[Phrases.ZERO_INVOICE_2] = "Please select another subscription or a longer duration.";
EnglishPhrases[Phrases.SORRY_STORE_SERVICE_DOWN] = "The store service is currently down. Sorry for the inconvenience.";
EnglishPhrases[Phrases.CONNECTING_TO_STORE_SERVICE] = "Connecting...";
EnglishPhrases[Phrases.UPGRADE_DURATION] = "Till current subscription expires";
EnglishPhrases[Phrases.FOR_FREE] = "Free";
EnglishPhrases[Phrases.MAXED_OUT_SUBSCRIPTIONS] = "Your subscription has the maximum duration and contains all current expansions";
EnglishPhrases[Phrases.IP_COUNTRY_MISMATCH] = "The country you selected does not match your IP address";
EnglishPhrases[Phrases.EXPIRES_AT] = "subscription ends at";
EnglishPhrases[Phrases.TOOLTIP_RANDOM_SELECTION] = "Click to add a random card from ARGUMENT0 to your kingdom.";
EnglishPhrases[Phrases.TOOLTIP_LANDSCAPE_SLOT] = "This slot could be ARGUMENT0. Click on the pictures to change this. Remove all checkmarks to remove the slot.";
EnglishPhrases[Phrases.LANDSCAPE_UNUSED] = "empty";
EnglishPhrases[Phrases.TOOLTIP_LANDSCAPE_PLUS_SLOT] = "Create a new slot for landscape cards and then select which types of landscapes can appear in it.";
EnglishPhrases[Phrases.LANDSCAPE] = "landscape";

EnglishPhrases[Phrases.PREVIEW_BOT_MATCH] = "Preview vs Bot";

EnglishPhrases[Phrases.TOOLTIP_USE_COFFERS] = "Click to spend 1 Coffers";
EnglishPhrases[Phrases.TOOLTIP_CANT_USE_COFFERS] = "You can only spend Coffers in your Buy phase, before you start buying";
EnglishPhrases[Phrases.TOOLTIP_USE_VILLAGER] = "Click to use 1 Villager";
EnglishPhrases[Phrases.TOOLTIP_CANT_USE_VILLAGER] = "You can only use Villagers in your Action phase";
//EnglishPhrases[Phrases.CARDS_BANNED_BY_PLAYERS]        = "Cards banned by players: ";
//EnglishPhrases[Phrases.CARDS_BANNED_BY_PLAYERS]        = "Cards banned by players: ";
EnglishPhrases[Phrases.AUTOCOMPLETE_CARD_LISTS] = "Enter cards to add";
EnglishPhrases[Phrases.RESPECTED_CARD_LISTS] = "Respected card lists";
EnglishPhrases[Phrases.BANNED_CARDS] = "banned cards";
EnglishPhrases[Phrases.LIKED_CARDS] = "liked cards";
EnglishPhrases[Phrases.DISLIKED_CARDS] = "disliked cards";
EnglishPhrases[Phrases.KINGDOM_GENERATOR_PERCENTAGES] = "Kingdom generated with these relative percentages:";
EnglishPhrases[Phrases.VILLAGERS] = "Villagers";
EnglishPhrases[Phrases.DAYS] = "days";
EnglishPhrases[Phrases.SPECIAL_KINGDOM_RULES] = "Special Kingdom Rules:";
EnglishPhrases[Phrases.SHOW_CHAT] = "Show Chat";
EnglishPhrases[Phrases.HIDE_CHAT] = "Hide Chat";
EnglishPhrases[Phrases.UNBLACKLIST] = "Remove from blacklist";
EnglishPhrases[Phrases.AUTOMATCH_LABEL_CARD_POOL_RANGE] = "Pool range";
EnglishPhrases[Phrases.AUTOMATCH_LABEL_EXPANSIONS] = "Expansions";
EnglishPhrases[Phrases.AUTOMATCH_LABEL_RATING] = "Rating";
EnglishPhrases[Phrases.BASE_ONLY] = "Base";
EnglishPhrases[Phrases.SIMPLE] = "Simple";
EnglishPhrases[Phrases.TOOLTIP_OWNED_ENABLED] = "Click to disable this expansion.<br/>When disabled, you won't bring it to your games.";
EnglishPhrases[Phrases.TOOLTIP_OWNED_DISABLED] = "Click to enable this expansion.<br/>When enabled, you will bring it to your games";
EnglishPhrases[Phrases.TOOLTIP_VOID_ENABLED] = "Click to disable this expansion.<br/>Disabling has no effect if you're not subscribed.";
EnglishPhrases[Phrases.TOOLTIP_VOID_DISABLED] = "Click to enable this expansion.<br/>Enabling has no effect if you're not subscribed.";
EnglishPhrases[Phrases.TOOLTIP_BASE_SET] = "You can't disable the base set.";
EnglishPhrases[Phrases.CHOOSE_CARD_POOL] = "View Card Pools";
EnglishPhrases[Phrases.USE_LEVEL] = "Use level";
EnglishPhrases[Phrases.VIEW_INTRODUCTION_CARD_SELECTION] = "View introduction to card selection";
EnglishPhrases[Phrases.VIEW_CARD_POOL_LEVELS] = "View Card Pool levels";
EnglishPhrases[Phrases.CARD_POOL_BOX_HEADER] = "Card Pool";
EnglishPhrases[Phrases.CARD_POOL_LEVEL] = "Level";
EnglishPhrases[Phrases.MIN_CARD_POOL_LEVEL] = "Min Level";
EnglishPhrases[Phrases.CARD_POOL_LEVEL_SMALL] = "level";
EnglishPhrases[Phrases.USED_CARD_POOL_LEVEL] = "Card Pool: level ";
EnglishPhrases[Phrases.SHOW_LEVEL] = "Card Pool: ";
EnglishPhrases[Phrases.SUBSCRIPTION_YES] = "Thank you for subscribing.";
EnglishPhrases[Phrases.SUBSCRIPTION_NO] = "You don't have a subscription.";
EnglishPhrases[Phrases.SUBSCRIPTION_FOR] = "Your subscription lasts another";
EnglishPhrases[Phrases.EMPTY_BECAUSE_DISABLED] = "You can't select cards from this expansion because you have it disabled in the Account tab.";
EnglishPhrases[Phrases.EMPTY_BECAUSE_NOT_SUBSCRIBED] = "You can't select cards from this expansion because you don't have a subscription to it.";
EnglishPhrases[Phrases.EXPLAIN_SUBSCRIPTIONS] = "\nThis site allows you to play with the base set for free. \nIf you want to play with the expansions or want to support the site, please consider subscribing. \nYou can also try to find an opponent that provides the cards. If you want to wait for such an opponent in the queue, check the \"Wait for Expansions\"-checkbox.\n";
EnglishPhrases[Phrases.CARD_SELECTION_INTRODUCTION_TEXT] = ["<span class=\"game-concept\">Automatch</span> offers two different ways to limit the cards you play with.\n    Alternatively, you can create a <span class=\"game-concept\">New Table</span> and find your own opponents, allowing you to manually select everything.", "<div class=\"header\">Card Pool</div>\n        If you're in the process of learning the cards, this is probably what you want to use. \n        We've created 10 different levels, starting with the base set. \n        You are completely free to choose your own progression here. \n        You can read about the new mechanics on each level beforehand. \n        <span class=\"game-concept\">Level</span> should be set to the level you're comfortable with.\n        <span class=\"game-concept\">Min Level</span> affects your automatching, and is the minimum level you're willing to play at. \n        You can set it lower if you're willing to help out others or just want a faster search.", "<div class=\"header\">Cardlists</div>\n        The <span class=\"game-concept\">Cardlists</span> button in the options menu allows you to create small personal lists of cards. Cards you ban will never appear in your games, and cards you dislike will be selected 50% less."];

EnglishPhrases[Phrases.LEVEL_DESCRIPTIONS] = ["Base only", "Simple cards", "Duration, Special Treasures, Platinum & Colony", "When-gain, When-trash, VP tokens", "Coffers, Villagers, Exile, This turn", "While-in-play, When discard, Mats", "Events, Reserve, Adventures Tokens", "Projects, Landmarks, Night, Shelters, Horses", "Extra cards, Artifacts, Boons, Hexes, Heirlooms", "All cards"];
EnglishPhrases[Phrases.NEW_CARDS] = "New Cards";
EnglishPhrases[Phrases.INHERITANCE_INSTRUCTIONS] = "Play the card with//your Estate token,//leaving it there.";
EnglishPhrases[Phrases.FAVOR] = "Favor";
EnglishPhrases[Phrases.FAVORS] = "Favors";
EnglishPhrases[Phrases.FAVORS_MANY] = "Favors";
EnglishPhrases[Phrases.NO_PONG_RECEIVED] = "Warning: possibly lost connection with gameserver.";
EnglishPhrases[Phrases.MESSAGE_INDEX_MISMATCH] = "Warning: unreliable connection to gameserver.";
EnglishPhrases[Phrases.INDIVIDUAL_EXPANSIONS] = "Individual Expansions";
EnglishPhrases[Phrases.WARNING_SEARCHING_SUBSCRIBER] = "Searching for an opponent with a Subscription";
EnglishPhrases[Phrases.WARNING_MAX_RATING_LOW] = "Max rating is set quite low";
EnglishPhrases[Phrases.WARNING_MIN_RATING_HIGH] = "Min rating is set quite high";
EnglishPhrases[Phrases.SEARCHING_2E_PREVIEWS] = "2e Previews";
EnglishPhrases[Phrases.WARNING_ERRATA] = "Note: this game has some cards with recent errata:";
EnglishPhrases[Phrases.DONT_SHOW_THIS_MESSAGE_AGAIN] = "Don't show this message again";
EnglishPhrases[Phrases.REPLAY_LAST_KINGDOM] = "Replay Last Kingdom";
EnglishPhrases[Phrases.RECONNECTING_FAILED_MESSAGE] = "You've reconnected to this game COUNT times.";
EnglishPhrases[Phrases.TRY_AGAIN] = "Reconnect again";
EnglishPhrases[Phrases.RETURN_TO_LOBBY] = "Return to lobby";
EnglishPhrases[Phrases.ALL] = "All";
EnglishPhrases[Phrases.INSTRUCT_TO_RECONNECT] = "Reconnecting after server restart; if it fails please reload the page";
EnglishPhrases[Phrases.TIMED_OUT] = "Timed out";
EnglishPhrases[Phrases.RECONNECT] = "Reconnect";
EnglishPhrases[Phrases.SHADOW_CARDS] = "Shadow Cards";
EnglishPhrases[Phrases.OTHER_SERVER] = "Other Server";