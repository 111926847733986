"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var QuestionRelation = function () {
    function QuestionRelation(question, otherQuestion) {
        var answerValidator = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;
        var answerLinkage = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;

        _classCallCheck(this, QuestionRelation);

        this.question = question;
        this.otherQuestion = otherQuestion;
        this.validAnswerCombination = answerValidator; // Returns true when answers do 'match', false otherwise
        this.toOtherAnswer = answerLinkage; // Given my answer, sets other questions answer.

        if (this.validAnswerCombination === undefined) {

            if (this.answerLinkage === undefined) {

                this.validAnswerCombination = function () {
                    return true;
                };
            } else {
                this.validAnswerCombination = this.answerCombinationMatchesLinkage;
            }
        }
    }

    _createClass(QuestionRelation, [{
        key: "answerCombinationMatchesLinkage",
        value: function answerCombinationMatchesLinkage() {

            return this.answerLinkage[this.question.answer] === this.otherQuestion.answer;
        }
    }, {
        key: "setAnswerLinkage",
        value: function setAnswerLinkage(map) {

            this.question.answerLabels.forEach(function (answer) {

                if (map[answer] === undefined) {
                    throw "Missing answer in answer linkage related questions: " + answer;
                }
            });

            this.answerLinkage = map;
        }
    }, {
        key: "setLinkedAnswer",
        value: function setLinkedAnswer() {

            var linkedAnswer = this.toOtherAnswer[this.question.answer];

            if (linkedAnswer !== undefined) {
                this.otherQuestion.setAnswer(linkedAnswer, false);
            }
        }
    }]);

    return QuestionRelation;
}();