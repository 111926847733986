"use strict";

var SpanishQuestionDescriptions = {};

SpanishQuestionDescriptions[QuestionIds.GAME_RESOLVE_ABILITY] = ["Resuelve una capacidad obligatoria:", "Resuelve un efecto"];
SpanishQuestionDescriptions[QuestionIds.GAME_MAY_RESOLVE_ABILITY] = ["Resuelve una capacidad opcional.", "Puedes resolver un efecto"];
SpanishQuestionDescriptions[QuestionIds.GAME_MAY_REACT_WITH] = ["Reacciona con algo.", "¿Quieres reaccionar?"];
SpanishQuestionDescriptions[QuestionIds.GAME_TURN_SELECT] = ["Selecciona un turno.", "¿Qué turno jugar a continuación?"];
SpanishQuestionDescriptions[QuestionIds.GAME_MAY_BUY] = ["compra cartas.", "Puedes comprar cartas"];
SpanishQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING] = ["compra cartas.", "Puedes comprar cartas"];
SpanishQuestionDescriptions[QuestionIds.GAME_MAY_BUY_NONCARDS] = ["compra, pero no cartas.", "Puedes comprar, pero no cartas"];
SpanishQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING_NONCARDS] = ["compra, pero no cartas.", "Puedes comprar, pero no cartas"];
SpanishQuestionDescriptions[QuestionIds.GAME_REPAY_HOW_MUCH] = ["Salda la deuda.", "Puedes saldar deuda"];
SpanishQuestionDescriptions[QuestionIds.GAME_SPEND_COIN_TOKENS] = ["gasta Cofres.", "Puedes gastar fichas de Cofre"];
SpanishQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_ACTION] = ["juega una carta de Acción de tu mano.", "Puedes jugar Acciones"];
SpanishQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_TREASURES] = ["juega Tesoros.", "Puedes jugar Tesoros"];
SpanishQuestionDescriptions[QuestionIds.GAME_PLAY_ALL_TREASURES] = ["juega todos los Tesoros.", "GAME_PLAY_ALL_TREASURES"];
SpanishQuestionDescriptions[QuestionIds.GAME_ACTION_PHASE] = ["Es tu fase de acción", "Puedes jugar Acciones"];
SpanishQuestionDescriptions[QuestionIds.GAME_BUY_PHASE] = ["Es tu fase de compra", "Puedes comprar cartas"];
SpanishQuestionDescriptions[QuestionIds.GAME_BUY_PHASE_NONCARDS] = ["Es tu fase de compra", "Puedes comprar, pero no cartas"];
SpanishQuestionDescriptions[QuestionIds.GAME_CLEANUP_PHASE] = ["Elige las opciones de mantenimiento, y su orden.", "Elige opciones de mantenimiento"];
SpanishQuestionDescriptions[QuestionIds.GAME_USE_VILLAGER] = ["Puedes usar un Aldeano.", "Puedes usar un Aldeano"];
SpanishQuestionDescriptions[QuestionIds.GAME_USE_COFFERS] = ["You may use Coffers.", "You may use Coffers"]; // TRANSLATE
SpanishQuestionDescriptions[QuestionIds.ARTISAN_GAIN] = ["Gana una carta a tu mano que cueste hasta $5. ", "Gana una carta"];
SpanishQuestionDescriptions[QuestionIds.ARTISAN_TOPDECK] = ["Pon una carta de tu mano sobre tu mazo.", "Pon una carta sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.BANDIT] = ["Elimina una carta de PLAYER0.", "Elimina una carta de PLAYER0."];
SpanishQuestionDescriptions[QuestionIds.BUREAUCRAT] = ["Revela una carta de Victoria de tu mano y ponla sobre el mazo.", "Pon una Victoria sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.CELLAR] = ["Descarta cualquier número de cartas, y roba esa misma cantidad.", "Descarta, luego roba"];
SpanishQuestionDescriptions[QuestionIds.CHAPEL] = ["Elimina hasta 4 cartas de tu mano.", "Elimina hasta 4 cartas"];
SpanishQuestionDescriptions[QuestionIds.HARBINGER_TOPDECK] = ["Puedes poner una carta de tu pila de descartes sobre tu mazo.", "Puedes poner sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.LIBRARY] = ["Puedes saltar CARDS0, apartándolas.", "Puedes saltar CARDS0"];
SpanishQuestionDescriptions[QuestionIds.MILITIA] = ["Descártate hasta tener 3 cartas en la mano.", "Descarta hasta tener 3"];
SpanishQuestionDescriptions[QuestionIds.MINE_TRASH] = ["Puedes eliminar a Tesoro de tu mano. Gana un Tesoro a tu mano que cueste hasta $3 más que ella.", "Puedes improve a Tesoro"];
SpanishQuestionDescriptions[QuestionIds.MINE_GAIN] = ["Gana un Tesoro a tu mano que cueste hasta AMOUNT0.", "Gana un Tesoro hasta AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.MONEYLENDER] = ["Puedes eliminar un Cobre de tu mano para +$3.", "Puedes eliminar un Cobre"];
SpanishQuestionDescriptions[QuestionIds.POACHER] = ["Descarta Argument0.", "Descarta Argument0."];
SpanishQuestionDescriptions[QuestionIds.REMODEL_TRASH] = ["Elimina una carta de tu mano. Gana una carta que cueste hasta $2 más.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.REMODEL_GAIN] = ["Gana una carta que cueste hasta AMOUNT0.", "Gana una carta hasta AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.SENTRY_TRASH] = ["Elimina cualquiera de las cartas reveladas", "Puedes eliminar"];
SpanishQuestionDescriptions[QuestionIds.SENTRY_DISCARD] = ["Descarta cualquiera de las cartas reveladas", "Puedes descartar"];
SpanishQuestionDescriptions[QuestionIds.SENTRY_TOPDECK] = ["Devuélvelas al mazo en cualquier orden", "Devuélvelas en cualquier orden"];
SpanishQuestionDescriptions[QuestionIds.THRONE_ROOM] = ["Puedes jugar dos veces una carta de Acción de tu mano.", "Puedes jugar dos veces una Acción"];
SpanishQuestionDescriptions[QuestionIds.VASSAL] = ["Puedes jugar CARDS0 de tu pila de Descartes.", "Puedes jugar CARDS0"];
SpanishQuestionDescriptions[QuestionIds.WORKSHOP] = ["Gana una carta que cueste hasta $4.", "Gana una carta"];
SpanishQuestionDescriptions[QuestionIds.TRASHING_TOKEN] = ["Puedes eliminar una carta de tu mano.", "Puedes eliminar una carta"];
SpanishQuestionDescriptions[QuestionIds.ALMS] = ["Gana una carta que cueste hasta $4.", "Gana una carta"];
SpanishQuestionDescriptions[QuestionIds.AMULET_MODE] = ["Elige una: +$1; o eliminar una carta de tu mano; o ganar una Plata.", "Elige cómo jugar Amuleto"];
SpanishQuestionDescriptions[QuestionIds.AMULET_TRASH] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.ARTIFICER_DELAY] = ["Puedes descartar cualquier número de cartas. Puedes ganar una carta que cueste exactamente $1 por carta descartada, sobre tu mazo.", "Puedes usar Vidriera"];
SpanishQuestionDescriptions[QuestionIds.ARTIFICER_DISCARD] = ["Descarta cualquier número de cartas. Puedes ganar una carta que cueste exactamente $1 por carta descartada, sobre tu mazo.", "Descarta, luego gana"];
SpanishQuestionDescriptions[QuestionIds.ARTIFICER_GAIN] = ["Puedes ganar una carta que cueste exactamente AMOUNT0, sobre tu mazo.", "Puedes ganar una carta for AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.BALL_FIRST] = ["Gana 2 cartas que cuesten hasta $4 cada una.", "Gana dos cartas"];
SpanishQuestionDescriptions[QuestionIds.BALL_SECOND] = ["Gana otra carta que cueste hasta $4.", "Gana otra carta"];
SpanishQuestionDescriptions[QuestionIds.BONFIRE] = ["Elimina hasta 2 cartas que tengas en juego.", "Elimina hasta 2 cartas en juego"];
SpanishQuestionDescriptions[QuestionIds.DISCIPLE] = ["Puedes jugar dos veces una carta de Acción de tu mano. Gana un copia.", "Juega dos veces, luego gana una copia"];
SpanishQuestionDescriptions[QuestionIds.DUNGEON] = ["Descarta 2 cartas.", "Descarta 2 cartas"];
SpanishQuestionDescriptions[QuestionIds.FERRY] = ["Mueve tu ficha de coste -$2 a una pila de Acción del Suministro.", "Mueve tu ficha de -$2"];
SpanishQuestionDescriptions[QuestionIds.FUGITIVE] = ["Descarta una carta.", "Descarta una carta"];
SpanishQuestionDescriptions[QuestionIds.GEAR] = ["Aparta hasta 2 cartas de tu mano. Al inicio de tu siguiente turno, ponlas en tu mano.", "Aparta hasta 2 cartas"];
SpanishQuestionDescriptions[QuestionIds.HAUNTED_WOODS] = ["Pon tu mano sobre tu mazo en cualquier orden", "Pon tu mano sobre tu mazo"];
SpanishQuestionDescriptions[QuestionIds.HERO] = ["Gana un Tesoro.", "Gana un Tesoro"];
SpanishQuestionDescriptions[QuestionIds.LOST_ARTS_NEW] = ["Pon tu ficha de +1 Acción en una pila de Acción del Suministro.", "Mueve tu ficha de +1 Acción"];
SpanishQuestionDescriptions[QuestionIds.LOST_ARTS_MOVE] = ["Mueve tu ficha de +1 Acción a una pila de Acción del Suministro.", "Mueve tu ficha de +1 Acción"];
SpanishQuestionDescriptions[QuestionIds.MESSENGER_DISCARD] = ["Puedes poner tu mazo en tu pila de descartes.", "Puedes descartar tu mazo"];
SpanishQuestionDescriptions[QuestionIds.MESSENGER_GAIN] = ["Gana una carta que cueste hasta $4, y los demás jugadores ganan una copia.", "Distribuye una carta que cueste hasta $4"];
SpanishQuestionDescriptions[QuestionIds.MISER] = ["Elige una: Pon un Cobre de tu mano en tu Taberna; o +AMOUNT0", "Cobre a Taberna o +AMOUNT0?"];
SpanishQuestionDescriptions[QuestionIds.PATHFINDING_NEW] = ["Pon tu ficha de +1 Carta en una pila de Acción del Suministro.", "Mueve tu ficha de +1 Carta"];
SpanishQuestionDescriptions[QuestionIds.PATHFINDING_MOVE] = ["Mueve tu ficha de +1 Carta a una pila de Acción del Suministro.", "Mueve tu ficha de +1 Carta"];
SpanishQuestionDescriptions[QuestionIds.PILGRIMAGE_FIRST] = ["Puedes elegir hasta 3 cartas en juego con distinto nombre y ganar una copia de cada.", "Elige 3 cartas para ganar"];
SpanishQuestionDescriptions[QuestionIds.PILGRIMAGE_SECOND] = ["Puedes elegir otras 2 cartas en juego con distinto nombre y ganar una copia de cada.", "Elige 2 cartas para ganar"];
SpanishQuestionDescriptions[QuestionIds.PILGRIMAGE_THIRD] = ["Puedes elegir otra carta en juego con distinto nombre y ganar una copia.", "Elige 1 cartas para ganar"];
SpanishQuestionDescriptions[QuestionIds.PLAN_NEW] = ["Pon tu ficha de Eliminar a una pila de Acción del Suministro.", "Mueve tu ficha de Eliminar"];
SpanishQuestionDescriptions[QuestionIds.PLAN_MOVE] = ["Mueve tu ficha de Eliminar a una pila de Acción del Suministro.", "Mueve tu ficha de Eliminar"];
SpanishQuestionDescriptions[QuestionIds.QUEST_MODE] = ["Elige entre descartar un Ataque, dos Maldiciones, o 6 cartas.", "Elige tu Búsqueda"];
SpanishQuestionDescriptions[QuestionIds.QUEST_ATTACK] = ["Descarta una carta de Ataque.", "Descarta un Ataque"];
SpanishQuestionDescriptions[QuestionIds.QUEST_6CARDS] = ["Descarta 6 cartas.", "Descarta 6 cartas"];
SpanishQuestionDescriptions[QuestionIds.RATCATCHER] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_PLAY] = ["Puedes eliminar el Arrasar en juego.", "Puedes eliminar Arrasar"];
SpanishQuestionDescriptions[QuestionIds.RAZE_ALL] = ["Elimina una carta de tu mano o el Arrasar en juego.", "Elimina una carta o elimina Arrasar"];
SpanishQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_HAND] = ["Puedes eliminar una carta de tu mano.", "Puedes eliminar una carta"];
SpanishQuestionDescriptions[QuestionIds.RAZE_CHOOSE_CARD] = ["Elige una carta para poner en tu mano.", "Pon una carta en tu mano"];
SpanishQuestionDescriptions[QuestionIds.SAVE] = ["Aparta una carta de tu mano, y ponla en tu mano al final del turno (después de robar).", "Aparta un carta"];
SpanishQuestionDescriptions[QuestionIds.SCOUTING_PARTY_DISCARD] = ["Descarta NUMBER0 cartas.", "Descarta NUMBER0 cartas."];
SpanishQuestionDescriptions[QuestionIds.SCOUTING_PARTY_TOPDECK] = ["Pon las dos cartas restantes sobre tu mazo en cualquier orden.", "Pon estas cartas sobre tu mazo"];
SpanishQuestionDescriptions[QuestionIds.SEAWAY] = ["Gana una carta de Acción que cueste hasta $4. Mueve tu ficha de +1 Compra a su pila.", "Gana una Acción"];
SpanishQuestionDescriptions[QuestionIds.SOLDIER] = ["Descarta una carta.", "Descarta una carta"];
SpanishQuestionDescriptions[QuestionIds.STORYTELLER_FIRST] = ["Juega hasta 3 Tesoros de tu mano.", "Juega hasta 3 Tesoros"];
SpanishQuestionDescriptions[QuestionIds.STORYTELLER_SECOND] = ["Juega hasta 2 Tesoros más de tu mano.", "Juega hasta 2 Tesoros"];
SpanishQuestionDescriptions[QuestionIds.STORYTELLER_THIRD] = ["Puedes jugar otro Tesoro de tu mano.", "Puedes jugar otro Tesoro"];
SpanishQuestionDescriptions[QuestionIds.TEACHER_CHOOSE_TOKEN] = ["Elige qué ficha mover.", "Elige qué ficha"];
SpanishQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_MOVE] = ["Pon tu ficha de +1 Acción en una pila de Acción del Suministro en la que no tengas fichas.", "Mueve tu ficha de +1 Acción"];
SpanishQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_NEW] = ["Mueve tu ficha de +1 Acción a una pila de Acción del Suministro en la que no tengas fichas.", "Mueve tu ficha de +1 Acción"];
SpanishQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_MOVE] = ["Pon tu ficha de +1 Carta en una pila de Acción del Suministro en la que no tengas fichas.", "Mueve tu ficha de +1 Carta"];
SpanishQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_NEW] = ["Mueve tu ficha de +1 Carta a una pila de Acción del Suministro en la que no tengas fichas. ", "Mueve tu ficha de +1 Carta"];
SpanishQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_MOVE] = ["Pon tu ficha de +1 Moneda en una pila de Acción del Suministro en la que no tengas fichas.", "Mueve tu ficha de +1 Moneda"];
SpanishQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_NEW] = ["Mueve tu ficha de +1 Moneda a una pila de Acción del Suministro en la que no tengas fichas.", "Mueve tu ficha de +1 Moneda"];
SpanishQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_MOVE] = ["Pon tu ficha de +1 Compra en una pila de Acción del Suministro en la que no tengas fichas.", "Mueve tu ficha de +1 Compra"];
SpanishQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_NEW] = ["Mueve tu ficha de +1 Compra a una pila de Acción del Suministro en la que no tengas fichas.", "Mueve tu ficha de +1 Compra"];
SpanishQuestionDescriptions[QuestionIds.TRADE] = ["Elimina hasta 2 cartas de tu mano. Gana una Plata por carta eliminada.", "Elimina hasta 2 cartas"];
SpanishQuestionDescriptions[QuestionIds.TRAINING_NEW] = ["Pon tu ficha de +1 Moneda en una pila de Acción del Suministro.", "Mueve tu ficha de +1 Moneda"];
SpanishQuestionDescriptions[QuestionIds.TRAINING_MOVE] = ["Mueve tu ficha de +1 Moneda a una pila de Acción del Suministro.", "Mueve tu ficha de +1 Moneda"];
SpanishQuestionDescriptions[QuestionIds.TRANSMOGRIFY_TRASH] = ["Elimina una carta de tu mano, y gana una carta que cueste hasta $1 más a tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.TRANSMOGRIFY_GAIN] = ["Gana una carta que cueste hasta AMOUNT0 a tu mano.", "Gana una carta hasta AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.APOTHECARY] = ["Pon las cartas sobre tu mazo en cualquier orden.", "Pon estas cartas sobre tu mazo"];
SpanishQuestionDescriptions[QuestionIds.APPRENTICE] = ["Elimina una carta de tu mano. +1 Carta por $1 de coste. +2 Cartas si tiene [P] en su coste.", "Elimina, luego roba"];
SpanishQuestionDescriptions[QuestionIds.GOLEM] = ["Elige qué carta de Acción jugar primero.", "Elige el orden para jugar"];
SpanishQuestionDescriptions[QuestionIds.HERBALIST] = ["Puedes poner uno de tus Tesoros en juego sobre tu mazo.", "Puedes poner un Tesoro sobre tu mazo"];
SpanishQuestionDescriptions[QuestionIds.HERBALISTS] = ["What Treasures to topdeck", "Topdeck up to Argument0 Treasures"];
SpanishQuestionDescriptions[QuestionIds.SCRYING_POOL_YOU] = ["Mantén o descarta CARDS0", "Tú: mantén o descarta CARDS0"];
SpanishQuestionDescriptions[QuestionIds.SCRYING_POOL_OTHER] = ["Elige si PLAYER1 debería mantener o descartar CARDS0.", "PLAYER1: mantiene o descarta CARDS0."];
SpanishQuestionDescriptions[QuestionIds.TRANSMUTE] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.UNIVERSITY] = ["Puedes ganar una carta de Acción que cueste hasta $5.", "Puedes ganar una Acción"];
SpanishQuestionDescriptions[QuestionIds.FOLLOWERS] = ["Descarta hasta tener 3 cartas en la mano.", "Descarta hasta tener 3"];
SpanishQuestionDescriptions[QuestionIds.DIADEM] = ["¿Usar Aldeanos antes de cambiar Acciones por Monedas?", "¿Usar Aldeanos?"];
SpanishQuestionDescriptions[QuestionIds.HAMLET] = ["Puedes descartar para +1 Acción y/o +1 Compra", "Puedes descartar"];
SpanishQuestionDescriptions[QuestionIds.HAMLET_ACTION] = ["Descarta una carta para +1 Acción.", "Descarta para +1 Acción"];
SpanishQuestionDescriptions[QuestionIds.HAMLET_BUY] = ["Descarta una carta para +1 Compra.", "Descarta para +1 Compra"];
SpanishQuestionDescriptions[QuestionIds.HAMLET_BOTH] = ["Descarta 2 cartas.", "Descarta 2 cartas"];
SpanishQuestionDescriptions[QuestionIds.HORN_OF_PLENTY] = ["Gana una carta que cueste hasta AMOUNT0. Si es una carta de Victoria, elimina esta.", "Gana una carta hasta AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.HORSE_TRADERS] = ["Descarta 2 cartas.", "Descarta 2 cartas."];
SpanishQuestionDescriptions[QuestionIds.JESTER] = ["Elige quién gana CARDS0: You o PLAYER1.", "Elige quién gana CARDS0"];
SpanishQuestionDescriptions[QuestionIds.REMAKE_TRASH_FIRST] = ["Haz esto dos veces: elimina una carta de tu mano, luego gana una carta que cueste exactamente $1 más que ella.", "Elimina la primera carta"];
SpanishQuestionDescriptions[QuestionIds.REMAKE_TRASH_SECOND] = ["Elimina otra carta de tu mano, luego gana una carta que cueste exactamente $1 más que ella.", "Elimina otra carta"];
SpanishQuestionDescriptions[QuestionIds.REMAKE_GAIN_FIRST] = ["Gana una carta que cueste exactamente COST0", "Gana una carta"];
SpanishQuestionDescriptions[QuestionIds.REMAKE_GAIN_SECOND] = ["Gana una carta que cueste exactamente COST0", "Gana una carta"];
SpanishQuestionDescriptions[QuestionIds.TOURNAMENT_GAIN_PRIZE_OR_DUCHY] = ["Gana un Premio o un Ducado sobre tu mazo.", "Gana un Premio o un Ducado"];
SpanishQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_PRIZE] = ["Puedes ganar un Premio sobre tu mazo.", "Puedes ganar un Premio"];
SpanishQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_DUCHY] = ["Puedes ganar un Ducado sobre tu mazo.", "Puedes ganar un Ducado"];
SpanishQuestionDescriptions[QuestionIds.TOURNAMENT_REVEAL] = ["Puedes revelar una Provincia.", "Puedes revelar una Provincia"];
SpanishQuestionDescriptions[QuestionIds.TRUSTY_STEED] = ["Elige cómo jugar Corcel Fiel.", "Elige cómo jugar Corcel Fiel"];
SpanishQuestionDescriptions[QuestionIds.YOUNG_WITCH] = ["Descarta 2 cartas.", "Descarta 2 cartas"];
SpanishQuestionDescriptions[QuestionIds.YOUNG_WITCH_BANE] = ["Puedes revelar CARDS0 para bloquear Bruja Joven.", "¿Revelar CARDS0 para bloquear Bruja Joven?"];
SpanishQuestionDescriptions[QuestionIds.ALTAR_TRASH] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.ALTAR_GAIN] = ["Gana una carta que cueste hasta $5.", "Gana una carta"];
SpanishQuestionDescriptions[QuestionIds.ARMORY] = ["Gana una carta sobre tu mazo que cueste hasta $4.", "Gana una carta"];
SpanishQuestionDescriptions[QuestionIds.BAND_OF_MISFITS] = ["Juega una carta de Acción que cueste menos que esta.", "Juega una carta"];
SpanishQuestionDescriptions[QuestionIds.CATACOMBS_MODE] = ["Pon CARDS0 en tu mano; o descártalas y +3 Cartas.", "¿Coger o robar nuevas?"];
SpanishQuestionDescriptions[QuestionIds.CATACOMBS_GAIN] = ["Gana una carta más barata que AMOUNT0.", "Gana una carta más barata que AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.COUNT_PAY] = ["Elige: descarta 2 cartas; o pon una carta de tu mano sobre tu mazo; o gana un Cobre.", "Elige el pago de Conde"];
SpanishQuestionDescriptions[QuestionIds.COUNT_DISCARD] = ["Descarta 2 cartas.", "Descarta 2 cartas"];
SpanishQuestionDescriptions[QuestionIds.COUNT_TOPDECK] = ["Pon una carta de tu mano sobre tu mazo.", "Pon una carta sobre tu mazo"];
SpanishQuestionDescriptions[QuestionIds.COUNT_PROFIT] = ["Elige: +$3; o elimina tu mano; o gana un Ducado.", "Elige la recompensa de Conde"];
SpanishQuestionDescriptions[QuestionIds.COUNTERFEIT] = ["Puedes jugar un Tesoro de tu mano dos veces. Si lo haces, elimina ese Tesoro.", "Juega dos veces, luego elimina"];
SpanishQuestionDescriptions[QuestionIds.CULTIST] = ["Puedes jugar un Sectario de tu mano gratuitamente.", "¿Jugar otro Sectario?"];
SpanishQuestionDescriptions[QuestionIds.DAME_ANNA] = ["Puedes eliminar hasta 2 cartas de tu mano.", "Elimina hasta 2 cartas"];
SpanishQuestionDescriptions[QuestionIds.DAME_NATALIE] = ["Puedes ganar una carta que cueste hasta $3.", "Puedes ganar una carta"];
SpanishQuestionDescriptions[QuestionIds.DEATH_CART] = ["Puedes eliminar una carta de Acción de tu mano o el Carro de Cadáveres en juego.", "Puedes eliminar el Carro de Cadáveres o una Acción."];
SpanishQuestionDescriptions[QuestionIds.FORAGER] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.KNIGHTS] = ["Elige una carta para eliminar.", "Elige una carta para eliminar"];
SpanishQuestionDescriptions[QuestionIds.KNIGHTS_ALREADY_DIES] = ["Elige una carta para eliminar. (CARDS0 ya se elimina)", "Elige una carta para eliminar"];
SpanishQuestionDescriptions[QuestionIds.KNIGHTS_STILL_MATTERS] = ["Elige una carta para eliminar (CARDS1 se eliminará si eliminas CARDS0)", "Elige una carta para eliminar"];
SpanishQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_EXPAND] = ["Puedes eliminar una carta de tu mano y ganar otra que cueste hasta $3 más que ella.", "Puedes eliminar una carta"];
SpanishQuestionDescriptions[QuestionIds.GRAVEROBBER_EXPAND] = ["Elimina una carta de tu mano y gana otra que cueste hasta $3 más que ella.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_GAIN] = ["Puedes ganar una de las cartas eliminadas sobre tu mazo.", "Puedes ganar una de las cartas eliminadas"];
SpanishQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN] = ["Gana una de las cartas eliminadas sobre tu mazo.", "Gana una de las cartas eliminadas"];
SpanishQuestionDescriptions[QuestionIds.GRAVEROBBER_COMPLEX] = ["Al jugar el Ladrón de Tumbas", "Gana una de las cartas eliminadas o elimina una carta de Acción"];
SpanishQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN_SUPPLY] = ["Gana una carta que cueste hasta AMOUNT0.", "Gana una carta hasta AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.HERMIT_TRASH] = ["Puedes eliminar una carta (que no sea Tesoro) de tu mano o pila de descartes.", "Puedes eliminar"];
SpanishQuestionDescriptions[QuestionIds.HERMIT_GAIN] = ["Gana una carta que cueste hasta $3.", "Gana una carta"];
SpanishQuestionDescriptions[QuestionIds.HUNTING_GROUNDS] = ["Gana un Ducado o 3 Fincas.", "¿Ducado o 3 Fincas?"];
SpanishQuestionDescriptions[QuestionIds.IRONMONGER] = ["Puedes mantener o descartar CARDS0.", "Puedes descartar CARDS0"];
SpanishQuestionDescriptions[QuestionIds.JUNK_DEALER] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.MERCENARY_ONE] = ["Puedes eliminar una carta de tu mano (sin beneficio).", "Puedes eliminar una carta"];
SpanishQuestionDescriptions[QuestionIds.MERCENARY_MULTIPLE] = ["Puedes eliminar dos cartas de tu mano.", "Puedes eliminar dos cartas"];
SpanishQuestionDescriptions[QuestionIds.MERCENARY_DISCARD] = ["Descarta hasta tener 3 cartas.", "Descarta hasta tener 3"];
SpanishQuestionDescriptions[QuestionIds.MYSTIC] = ["Adivina la carta sobre tu mazo.", "Adivina la carta"];
SpanishQuestionDescriptions[QuestionIds.PILLAGE] = ["Elige qué carta descarta PLAYER0.", "Elige descarte para PLAYER0"];
SpanishQuestionDescriptions[QuestionIds.PROCESSION_PLAY] = ["Puedes jugar una carta de Acción de tu mano dos veces.", "Puedes jugar una Acción dos veces."];
SpanishQuestionDescriptions[QuestionIds.PROCESSION_GAIN] = ["Gana una Acción que cueste exactamente AMOUNT0.", "Gana una Acción de AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.RATS_TRASH_ANY] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.RATS_TRASH_OTHER] = ["Elimina una carta de tu mano que no sea Ratas.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.REBUILD_SKIP] = ["Nombra una carta a omitir (si es de Victoria).", "Nombra una carta a omitir"];
SpanishQuestionDescriptions[QuestionIds.REBUILD_GAIN] = ["Gana una carta de Victoria que cueste hasta AMOUNT0.", "Gana un carta de Victoria hasta AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.ROGUE_TRASH] = ["Elige una carta de PLAYER0 para eliminar.", "Elimina una carta de PLAYER0"];
SpanishQuestionDescriptions[QuestionIds.ROGUE_GAIN] = ["Gana una de las cartas eliminadas que cueste de $3 a $6.", "Gana una de las cartas eliminadas"];
SpanishQuestionDescriptions[QuestionIds.SCAVENGER] = ["Puedes poner tu mazo en la pila de descartes.", "Puedes descartar tu mazo"];
SpanishQuestionDescriptions[QuestionIds.SCAVENGER_TOPDECK] = ["Pon una carta de tu pila de descartes sobre tu mazo.", "Pon una carta sobre tu mazo"];
SpanishQuestionDescriptions[QuestionIds.SIR_MICHAEL] = ["Descarta hasta tener 3 cartas.", "Descarta hasta tener 3 cartas"];
SpanishQuestionDescriptions[QuestionIds.SQUIRE_MODE] = ["Elige: +2 Acciones; o +2 Buys; o ganar a Plata.", "Elige modo del Escudero"];
SpanishQuestionDescriptions[QuestionIds.SQUIRE_GAIN] = ["Gana una Attack carta.", "Gana una carta de Ataque"];
SpanishQuestionDescriptions[QuestionIds.STOREROOM_CARDS] = ["Descarta cualquier número de cartas, luego draw that many.", "Descarta para robar"];
SpanishQuestionDescriptions[QuestionIds.STOREROOM_COINS] = ["Descarta cualquier número de cartas for +$1 each.", "Descarta por monedas"];
SpanishQuestionDescriptions[QuestionIds.SURVIVORS] = ["Pon these dos cartas en cualquier orden, o descartar them both.", "Pon sobre el mazo o descarta"];
SpanishQuestionDescriptions[QuestionIds.URCHIN] = ["Descarta hasta tener 4 cartas.", "Descarta hasta tener 4 cartas"];
SpanishQuestionDescriptions[QuestionIds.WANDERING_MINSTREL] = ["Put the carta de Accións back en cualquier orden.", "Pon sobre el mazo en cualquier orden"];
SpanishQuestionDescriptions[QuestionIds.ADVANCE_TRASH] = ["Puedes eliminar una carta de Acción de tu mano.", "Puedes eliminar una Acción"];
SpanishQuestionDescriptions[QuestionIds.ADVANCE_GAIN] = ["Gana una carta de Acción que cueste hasta $6.", "Gana una Acción"];
SpanishQuestionDescriptions[QuestionIds.ANNEX] = ["Elige hasta 5 cartas not to shuffle into your deck.", "Elige hasta 5 cartas"];
SpanishQuestionDescriptions[QuestionIds.ARCHIVE] = ["Elige una carta to poner en tu mano.", "Elige una carta"];
SpanishQuestionDescriptions[QuestionIds.ARENA] = ["Puedes descartar una carta de Acción for NUMBER0VP.", "Arena: ¿descartar por NUMBER0VP?"];
SpanishQuestionDescriptions[QuestionIds.BANQUET] = ["Gana un non-carta de Victoria que cueste hasta $5.", "Gana una carta (no de Victoria)"];
SpanishQuestionDescriptions[QuestionIds.BUSTLING_VILLAGE] = ["Puedes put a Settlers en tu mano.", "Puedes coger un Colonos"];
SpanishQuestionDescriptions[QuestionIds.CATAPULT_TRASH] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.CATAPULT_DISCARD] = ["Descarta hasta tener 3 cartas.", "Descarta hasta tener 3 cartas"];
SpanishQuestionDescriptions[QuestionIds.CHARM_MODE] = ["Elige cómo jugar Charm", "Elige cómo jugar Fetiche"];
SpanishQuestionDescriptions[QuestionIds.CHARM_GAIN] = ["Gana una carta other than CARDS0 que cueste exactamente AMOUNT1.", "Gana una carta de AMOUNT1 (no CARDS0)"];
SpanishQuestionDescriptions[QuestionIds.CROWN_ACTION] = ["Puedes jugar una Acción de tu mano dos veces.", "Puedes jugar una Acción dos veces"];
SpanishQuestionDescriptions[QuestionIds.CROWN_TREASURE] = ["Puedes jugar a Tesoro de tu mano dos veces.", "Puedes jugar un Tesoro dos veces"];
SpanishQuestionDescriptions[QuestionIds.DONATE] = ["Elimina cualquier número de cartas.", "Elimina cualquier cantidad"];
SpanishQuestionDescriptions[QuestionIds.ENCAMPMENT] = ["Puedes revelar un Oro o Saqueo de tu mano.", "Puedes revelar Oro o Saqueo"];
SpanishQuestionDescriptions[QuestionIds.ENGINEER_GAIN] = ["Gana una carta que cueste hasta $4.", "Gana una carta"];
SpanishQuestionDescriptions[QuestionIds.ENGINEER_TRASH] = ["Puedes eliminar the Engineer.", "Puedes eliminar la Ingeniera"];
SpanishQuestionDescriptions[QuestionIds.FORUM] = ["Descarta 2 cartas.", "Descarta 2 cartas"];
SpanishQuestionDescriptions[QuestionIds.GLADIATOR_REVEAL] = ["Revela una carta de tu mano.", "Revela una carta"];
SpanishQuestionDescriptions[QuestionIds.GLADIATOR_PRETEND] = ["Pretend to think about revealing a copia.", "No revelar"];
SpanishQuestionDescriptions[QuestionIds.GLADIATOR_BLOCK] = ["Puedes revela CARDS0 de tu mano.", "Puedes revelar CARDS0"];
SpanishQuestionDescriptions[QuestionIds.HAUNTED_CASTLE] = ["Put 2 cartas de tu mano sobre tu mazo.", "Pon 2 cartas sobre tu mazo"];
SpanishQuestionDescriptions[QuestionIds.LEGIONARY_REVEAL] = ["Puedes revela a Oro de tu mano.", "Puedes revelar un Oro"];
SpanishQuestionDescriptions[QuestionIds.LEGIONARY_DISCARD] = ["Descarta hasta tener 2 cartas in hand.", "Descarta hasta tener 2 cartas"];
SpanishQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_NEW] = ["Bid hasta 40D.", "Apuesta hasta 40D"];
SpanishQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_OVERBID] = ["Bid hasta 40D. Currently PLAYER0 holds the high bid de NUMBER1.", "Apuesta hasta 40D"];
SpanishQuestionDescriptions[QuestionIds.OPULENT_CASTLE] = ["Descarta cualquier número de carta de Victorias for +$2 each. ", "Descarta por +$2 cada una"];
SpanishQuestionDescriptions[QuestionIds.OVERLORD] = ["Juega una carta de Acción que cueste hasta $5. ", "Juega una carta"];
SpanishQuestionDescriptions[QuestionIds.RITUAL] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.SACRIFICE] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.SALT_THE_EARTH] = ["Elimina a carta de Victoria from the Suministro.", "Elimina del Suministro"];
SpanishQuestionDescriptions[QuestionIds.SETTLERS] = ["Puedes put a Cobre de tu pila de descartes en tu mano.", "Puedes coger un Cobre"];
SpanishQuestionDescriptions[QuestionIds.SMALL_CASTLE_IN_PLAY] = ["Elimina Small Castle from jugar o a Castle de tu mano.", "Elimina un Castillo"];
SpanishQuestionDescriptions[QuestionIds.SMALL_CASTLE_GONE] = ["Elimina a Castle de tu mano.", "Elimina un Castillo"];
SpanishQuestionDescriptions[QuestionIds.SMALL_CASTLE_GAIN] = ["Gana un Castle.", "Gana un Castillo"];
SpanishQuestionDescriptions[QuestionIds.SPRAWLING_CASTLE] = ["Gana un Ducado o 3 Estates.", "¿Ducado o 3 Fincas?"];
SpanishQuestionDescriptions[QuestionIds.TAX] = ["Elige a supply pile to add (2D) to.", "Añade (2D) a una pila"];
SpanishQuestionDescriptions[QuestionIds.TEMPLE] = ["Elimina from 1 to 3 differently named cartas de tu mano.", "Elimina 1 - 3 cartas"];
SpanishQuestionDescriptions[QuestionIds.WILD_HUNT] = ["Elige between +3 Cartas y the Finca y VP", "¿Cartas o puntos?"];
SpanishQuestionDescriptions[QuestionIds.ADVISOR] = ["Elige what carta PLAYER0 doesn't get.", "Descarta para PLAYER0"];
SpanishQuestionDescriptions[QuestionIds.BUTCHER_TRASH] = ["Puedes eliminar una carta de tu mano.", "Puedes eliminar una carta"];
SpanishQuestionDescriptions[QuestionIds.BUTCHER_GAIN] = ["Gana una carta que cueste hasta AMOUNT0.", "Gana una carta hasta AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.DOCTOR_PLAY] = ["Name una carta to eliminar.", "Nombra una carta"];
SpanishQuestionDescriptions[QuestionIds.DOCTOR_TOPDECK] = ["Put these cartas back en cualquier orden.", "Pon estas cartas sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.DOCTOR_OVERPAY] = ["Puedes pagar de más for Doctor.", "Puedes pagar de más"];
SpanishQuestionDescriptions[QuestionIds.DOCTOR_MODE] = ["What to do with CARDS0?", "¿Qué hacer con CARDS0?"];
SpanishQuestionDescriptions[QuestionIds.DOCTOR_MODE_MANY] = ["What to do with CARDS0? (NUMBER1 more)", "¿CARDS0? (NUMBER1 más)"];
SpanishQuestionDescriptions[QuestionIds.HERALD_OVERPAY] = ["Puedes pagar de más for Herald.", "Puedes pagar de más"];
SpanishQuestionDescriptions[QuestionIds.HERALD_CHOOSE] = ["Put una carta de tu pila de descartes on your deck.", "Pon una carta sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.JOURNEYMAN] = ["Name una carta not to draw.", "Nombra una carta"];
SpanishQuestionDescriptions[QuestionIds.MASTERPIECE] = ["Puedes pagar de más for Masterpiece.", "Puedes pagar de más"];
SpanishQuestionDescriptions[QuestionIds.PLAZA_DELAY] = ["Puedes descartar a Tesoro to take a Moneda ficha.", "Puedes descartar un Tesoro"];
SpanishQuestionDescriptions[QuestionIds.PLAZA_DISCARD] = ["Descarta a Tesoro.", "Descarta un Tesoro"];
SpanishQuestionDescriptions[QuestionIds.STONEMASON_TRASH] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.STONEMASON_GAIN1] = ["Gana 2 cartas each que cueste less than AMOUNT0.", "Gana 1ª carta de menos de AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.STONEMASON_GAIN2] = ["Gana otra carta que cueste less than AMOUNT0.", "Gana 2ª carta de menos de AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.STONEMASON_OVERPAY] = ["Puedes pagar de más for stonemason by gaining una Acción hasta AMOUNT0.", "Pagar de más para ganar..."];
SpanishQuestionDescriptions[QuestionIds.STONEMASON_GAIN_EQUAL] = ["Gana otra Acción que cueste exactamente AMOUNT0.", "Gana otra Acción de AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.TAXMAN_TRASH] = ["Puedes eliminar a Tesoro de tu mano.", "Puedes eliminar un Tesoro"];
SpanishQuestionDescriptions[QuestionIds.TAXMAN_GAIN] = ["Gana un treasure que cueste hasta AMOUNT0 sobre tu mazo.", "Gana un Tesoro hasta AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.BORDER_VILLAGE] = ["Gana una carta cheaper than AMOUNT0.", "Gana una carta de menos de AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.CARTOGRAPHER_DISCARD] = ["Descarta cualquier número.", "Descarta cualquier número"];
SpanishQuestionDescriptions[QuestionIds.CARTOGRAPHER_TOPDECK] = ["Put the remaining cartas on top de your deck en cualquier orden.", "Pon estas cartas sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.DEVELOP_TRASH] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.DEVELOP_GAIN1] = ["Gana una carta sobre tu mazo que cueste AMOUNT0 o Argument1.", "Gana 1ª carta de AMOUNT0 o Argument1"];
SpanishQuestionDescriptions[QuestionIds.DEVELOP_GAIN2] = ["Gana una carta sobre tu mazo que cueste AMOUNT0.", "Gana 2ª carta de AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.DUCHESS] = ["Puedes descartar CARDS0.", "Puedes descartar CARDS0"];
SpanishQuestionDescriptions[QuestionIds.EMBASSY] = ["Descarta 3 cartas.", "Descarta 3 cartas"];
SpanishQuestionDescriptions[QuestionIds.FARMLAND_TRASH] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.FARMLAND_GAIN] = ["Gana una carta que cueste exactamente AMOUNT0.", "Gana una carta de AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.HAGGLER] = ["Gana un non-victory que cueste less than AMOUNT0.", "Gana un carta (no de Victoria) de hasta AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.ILL_GOTTEN_GAINS] = ["Puedes ganar a Cobre a tu mano.", "Puedes ganar un Cobre"];
SpanishQuestionDescriptions[QuestionIds.INN_DISCARD] = ["Descarta 2 cartas.", "Descarta 2 cartas"];
SpanishQuestionDescriptions[QuestionIds.INN_SHUFFLE] = ["Shuffle cualquier número de Acciones de tu pila de descartes into your deck.", "Baraja Acciones en tu mazo"];
SpanishQuestionDescriptions[QuestionIds.JACK_DISCARD] = ["Puedes descartar CARDS0.", "Puedes descartar CARDS0"];
SpanishQuestionDescriptions[QuestionIds.JACK_TRASH] = ["Puedes eliminar un non-Tesoro de tu mano.", "Puedes eliminar una carta excepto Tesoro"];
SpanishQuestionDescriptions[QuestionIds.MANDARIN_PLAY] = ["Put una carta de tu mano sobre tu mazo.", "Pon una carta sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.MANDARIN_GAIN] = ["Put all Tesoros you have in jugar sobre tu mazo en cualquier orden.", "Pon tus Tesoros sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.MARGRAVE] = ["Descarta hasta tener 3 cartas in hand.", "Descarta hasta tener 3 cartas"];
SpanishQuestionDescriptions[QuestionIds.NOBLE_BRIGAND] = ["Elige un Tesoro to have trashed.", "Elige un Tesoro"];
SpanishQuestionDescriptions[QuestionIds.OASIS] = ["Descarta una carta.", "Descarta una carta"];
SpanishQuestionDescriptions[QuestionIds.ORACLE_DISCARD] = ["Puedes descartar CARDS0 from PLAYER1.", "¿Descartar de PLAYER1?"];
SpanishQuestionDescriptions[QuestionIds.ORACLE_TOPDECK] = ["Put the cartas on top de your deck en cualquier orden.", "Pon estas cartas sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.SCHEME] = ["Puedes elegir una carta to scheme", "Puedes Confabular una carta"];
SpanishQuestionDescriptions[QuestionIds.SCHEMES] = ["What cartas to Scheme", "Confabula hasta Argument0 cartas"];
SpanishQuestionDescriptions[QuestionIds.SPICE_MERCHANT_TRASH] = ["Puedes eliminar un Tesoro de tu mano.", "Puedes eliminar un Tesoro"];
SpanishQuestionDescriptions[QuestionIds.SPICE_MERCHANT_MODE] = ["Elige: +2 Cartas y +1 Acción; o +1 Compra y +$2.", "Elige cómo jugar Mercader de Especias"];
SpanishQuestionDescriptions[QuestionIds.STABLES] = ["Puedes descartar un Tesoro.", "Puedes descartar un Tesoro"];
SpanishQuestionDescriptions[QuestionIds.TRADER_TRASH] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.BARON] = ["Puedes descartar una Finca.", "Puedes descartar una Finca"];
SpanishQuestionDescriptions[QuestionIds.COURTIER_REVEAL] = ["Revela una carta de tu mano.", "Revela una carta"];
SpanishQuestionDescriptions[QuestionIds.COURTIER_MODE] = ["Elige NUMBER0: +1 Acción; o +1 Compra; o +$3; o ganar un Oro. The choices must be different.", "Elige NUMBER0 opciones"];
SpanishQuestionDescriptions[QuestionIds.COURTYARD] = ["Put una carta de tu mano sobre tu mazo.", "Pon una carta sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.DIPLOMAT] = ["Descarta 3 cartas.", "Descarta 3 cartas"];
SpanishQuestionDescriptions[QuestionIds.IRONWORKS] = ["Gana una carta que cueste hasta $4.", "Gana una carta"];
SpanishQuestionDescriptions[QuestionIds.LURKER_MAY_TRASH] = ["Puedes eliminar una carta de Acción from the Suministro.", "Puedes eliminar del Suministro"];
SpanishQuestionDescriptions[QuestionIds.LURKER_TRASH] = ["Elimina una carta de Acción from the Suministro.", "Elimina del Suministro"];
SpanishQuestionDescriptions[QuestionIds.LURKER_MAY_GAIN] = ["Puedes ganar una carta de Acción una de las cartas eliminadas.", "Puedes ganar una carta eliminada"];
SpanishQuestionDescriptions[QuestionIds.LURKER_GAIN] = ["Gana una carta de Acción una de las cartas eliminadas.", "Gana una de las cartas eliminadas"];
SpanishQuestionDescriptions[QuestionIds.MASQUERADE_PASS] = ["Pass una carta to PLAYER0.", "Pasa una carta a PLAYER0"];
SpanishQuestionDescriptions[QuestionIds.MASQUERADE_TRASH] = ["Puedes eliminar una carta de tu mano.", "Puedes eliminar una carta"];
SpanishQuestionDescriptions[QuestionIds.MILL] = ["Puedes descartar 2 cartas", "Puedes descartar 2 cartas"];
SpanishQuestionDescriptions[QuestionIds.MINING_VILLAGE] = ["Puedes eliminar Aldea Minera.", "Puedes eliminar Aldea Minera"];
SpanishQuestionDescriptions[QuestionIds.MINION] = ["Elige: +$2 o new hands.", "Elige +$2 o nuevas manos"];
SpanishQuestionDescriptions[QuestionIds.NOBLES] = ["Elige: +3 Cartas; o +2 Acciones.", "Elige +3 Cartas o +2 Acciones"];
SpanishQuestionDescriptions[QuestionIds.PATROL] = ["Put these cartas back en cualquier orden.", "Pon estas cartas sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.PAWN] = ["Elige cómo to jugar Pawn.", "Elige cómo to jugar Peón"];
SpanishQuestionDescriptions[QuestionIds.REPLACE_TRASH] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.REPLACE_GAIN] = ["Gana una carta que cueste hasta AMOUNT0.", "Gana una carta hasta AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.SECRET_PASSAGE_TOPDECK] = ["Put una carta onto your empty deck.", "Pon una carta sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.SECRET_PASSAGE_INSERT] = ["Elige una carta to put into your deck.", "Elige una carta"];
SpanishQuestionDescriptions[QuestionIds.STEWARD_MODE] = ["Elige: +2 Cartas; o +$2; o eliminar 2 cartas de tu mano.", "Elige cómo jugar Mayordomo"];
SpanishQuestionDescriptions[QuestionIds.STEWARD_TRASH] = ["Elige 2 cartas to eliminar.", "Elige 2 cartas a eliminar"];
SpanishQuestionDescriptions[QuestionIds.SWINDLER] = ["Elige una carta for PLAYER0 to ganar.", "Elige una carta para PLAYER0"];
SpanishQuestionDescriptions[QuestionIds.TRADING_POST] = ["Elimina 2 cartas de tu mano.", "Elimina 2 cartas"];
SpanishQuestionDescriptions[QuestionIds.UPGRADE_TRASH] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.UPGRADE_GAIN] = ["Gana una carta que cueste exactamente AMOUNT0.", "Gana una carta de AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.WISHING_WELL] = ["Guess what carta is on top de your deck.", "Adivina una carta"];
SpanishQuestionDescriptions[QuestionIds.SUMMON] = ["Gana una carta de Acción que cueste hasta $4.", "Convoca una carta"];
SpanishQuestionDescriptions[QuestionIds.GOVERNOR_MODE] = ["Elige cómo to jugar Governor", "Elige cómo jugar Gobernador"];
SpanishQuestionDescriptions[QuestionIds.GOVERNOR_TRASH] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.GOVERNOR_MAY_TRASH] = ["Puedes eliminar una carta de tu mano.", "Puedes eliminar"];
SpanishQuestionDescriptions[QuestionIds.GOVERNOR_GAIN] = ["Gana una carta que cueste exactamente Argument0", "Gana una carta"];
SpanishQuestionDescriptions[QuestionIds.SAUNA_CHAIN] = ["Puedes jugar una Avanto without spending una action", "Puedes jugar un Avanto"];
SpanishQuestionDescriptions[QuestionIds.SAUNA_MAY_TRASH] = ["Puedes eliminar una carta with Sauna", "Puedes eliminar"];
SpanishQuestionDescriptions[QuestionIds.AVANTO_CHAIN] = ["Puedes jugar un Sauna without spending una action", "Puedes jugar una Sauna"];

SpanishQuestionDescriptions[QuestionIds.BISHOP_SELF] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.BISHOP_OTHER] = ["Puedes eliminar una carta de tu mano.", "Puedes eliminar una carta"];
SpanishQuestionDescriptions[QuestionIds.CONTRABAND] = ["Name una carta PLAYER0 can't buy this turn.", "Veta una carta"];
SpanishQuestionDescriptions[QuestionIds.COUNTING_HOUSE] = ["cómo many Coppers to poner en tu mano?", "Coge Cobres"];
SpanishQuestionDescriptions[QuestionIds.EXPAND_TRASH] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.EXPAND_GAIN] = ["Gana una carta que cueste hasta AMOUNT0.", "Gana una carta hasta AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.FORGE_TRASH] = ["Elimina cualquier número de cartas de tu mano.", "Elimina cualquier número"];
SpanishQuestionDescriptions[QuestionIds.FORGE_GAIN] = ["Gana una carta que cueste exactamente AMOUNT0.", "Gana una carta for AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.GOONS] = ["Descarta hasta tener 3 cartas in hand.", "Descarta hasta tener 3 cartas"];
SpanishQuestionDescriptions[QuestionIds.KINGS_COURT] = ["Puedes jugar una carta de Acción three times.", "Puedes jugar una Acción tres veces"];
SpanishQuestionDescriptions[QuestionIds.LOAN] = ["Descarta o eliminar CARDS0.", "Descarta o elimina CARDS0"];
SpanishQuestionDescriptions[QuestionIds.MINT] = ["Puedes revela un Tesoro de tu mano. Gana un copia de it.", "Puedes copiar un Tesoro"];
SpanishQuestionDescriptions[QuestionIds.MOUNTEBANK] = ["Puedes descartar un Curse.", "Puedes descartar una Maldición"];
SpanishQuestionDescriptions[QuestionIds.RABBLE] = ["Put these cartas back en cualquier orden.", "Pon estas cartas sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.TRADE_ROUTE] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.VAULT_SELF] = ["Descarta cualquier número de cartas.", "Descarta cualquier número"];
SpanishQuestionDescriptions[QuestionIds.VAULT_OTHER] = ["Puedes descartar 2 cartas.", "Puedes descartar 2 cartas"];
SpanishQuestionDescriptions[QuestionIds.AMBASSADOR_REVEAL] = ["Revela una carta de tu mano.", "Revela una carta de tu mano"];
SpanishQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_ONE] = ["Puedes return CARDS0 to the Suministro.", "Puedes devolver CARDS0"];
SpanishQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_dos] = ["Return 0, 1 o CARDS0 to the Suministro.", "Devuelve 0, 1 o 2"];
SpanishQuestionDescriptions[QuestionIds.EMBARGO] = ["Add una Embargo ficha to un Suministro pile.", "Embarga una pila"];
SpanishQuestionDescriptions[QuestionIds.EXPLORER] = ["Puedes revela un Provincia.", "Puedes revelar una Provincia"];
SpanishQuestionDescriptions[QuestionIds.GHOST_SHIP] = ["Put NUMBER0 cartas on top de your deck.", "Pon NUMBER0 cartas sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.HAVEN] = ["Set una carta aside.", "Aparta una carta"];
SpanishQuestionDescriptions[QuestionIds.ISLAND] = ["Put una carta de tu mano onto your Island mat.", "Pon una carta en tu tablero de Isla"];
SpanishQuestionDescriptions[QuestionIds.LOOKOUT_TRASH] = ["Elimina una carta.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.LOOKOUT_DISCARD] = ["Descarta una carta.", "Descarta una carta"];
SpanishQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_TAKE] = ["Puedes put CARDS0 en tu mano.", "¿Recoger Argument0?"];
SpanishQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_SET_ASIDE] = ["Puedes put the top carta de your deck onto your mat.", "Puedes poner en tu tablero"];
SpanishQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_BOTH] = ["Add una carta to your mat o take CARDS0 en tu mano.", "¿Recoger Argument0?"];
SpanishQuestionDescriptions[QuestionIds.NAVIGATOR] = ["Pon sobre el mazo these 5 cartas en cualquier orden, o descartar them all.", "Pon sobre el mazo o descarta"];
SpanishQuestionDescriptions[QuestionIds.PEARL_DIVER] = ["Put CARDS0 on top o bottom de your deck.", "Pon CARDS0 sobre el mazo o debajo"];
SpanishQuestionDescriptions[QuestionIds.PIRATE_SHIP_MODE] = ["Elige: +AMOUNT0 o attack.", "+AMOUNT0 o atacar"];
SpanishQuestionDescriptions[QuestionIds.PIRATE_SHIP_TRASH] = ["Elige a Tesoro to eliminar.", "Elige un Tesoro para eliminar"];
SpanishQuestionDescriptions[QuestionIds.SALVAGER] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.SMUGGLERS_MAY_GAIN] = ["Puedes ganar a copia.", "Puedes ganar una carta"];
SpanishQuestionDescriptions[QuestionIds.SMUGGLERS_GAIN] = ["Gana un copia.", "Gana una carta"];
SpanishQuestionDescriptions[QuestionIds.WAREHOUSE] = ["Descarta 3 cartas.", "Descarta 3 cartas."];
SpanishQuestionDescriptions[QuestionIds.LURKER_COMPLEX] = ["When playing Lurker", "Al jugar Acechador..."];
SpanishQuestionDescriptions[QuestionIds.BLACK_MARKET_COMPLEX] = ["You jugar Black Market", "Juegas Mercado Negro..."];
SpanishQuestionDescriptions[QuestionIds.BLACK_MARKET_BUY] = ["buy one de the revealed cartas.", "Puedes comprar"];
SpanishQuestionDescriptions[QuestionIds.BLACK_MARKET_PLAY] = ["jugar Tesoros de tu mano.", "Puedes jugar Tesoros"];
SpanishQuestionDescriptions[QuestionIds.ENVOY] = ["Elige una carta to descartar.", "Descarta una carta"];
//SpanishQuestionDescriptions[QuestionIds.PRINCE_MODE] = ["PRINCE_MODE", "¿Apartar Príncipe?"];
SpanishQuestionDescriptions[QuestionIds.PRINCE_MAY_SET_ASIDE] = ["Puedes set aside una carta with ", "Puedes apartar una carta"];
SpanishQuestionDescriptions[QuestionIds.TORTURER_MAY_CURSE] = ["Puedes ganar a Curse.", "Puedes ganar una Maldición"];
SpanishQuestionDescriptions[QuestionIds.TORTURER_MAY_DISCARD] = ["Puedes descartar 2 cartas.", "Puedes descartar 2 cartas"];
SpanishQuestionDescriptions[QuestionIds.TORTURER_DISCARD_OR_CURSE] = ["Either ganar una Maldición o descartar 2 cartas.", "Descarta 2 cartas o gana una Maldición"];
//SpanishQuestionDescriptions[QuestionIds.WHAT_TO_IMPERSONATE] = ["Elige una carta to impersonate.", "Elige una carta a suplantar"];
SpanishQuestionDescriptions[QuestionIds.INHERITANCE] = ["Elige una carta to inherit.", "Elige una carta a heredar"];

SpanishQuestionDescriptions[QuestionIds.SHORT_YOU_MAY_REACT] = ["-", "Puedes reaccionar"];
SpanishQuestionDescriptions[QuestionIds.SHORT_WHEN_GAIN] = ["-", "Ganas Argument0..."];
SpanishQuestionDescriptions[QuestionIds.SHORT_WHEN_WOULD_GAIN] = ["-", "Ganarías Argument0..."];

SpanishQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL_SPECIFIC] = ["-", "Juega o convoca Argument0..."];
SpanishQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL] = ["-", "Juega o convoca Acciones"];
SpanishQuestionDescriptions[QuestionIds.SHORT_PLAY] = ["-", "Puedes jugar Acciones"];
SpanishQuestionDescriptions[QuestionIds.SHORT_CALL_SPECIFIC] = ["-", "Puedes convocar Argument0"];
SpanishQuestionDescriptions[QuestionIds.SHORT_CALL] = ["-", "Puedes convocar cartas"];

SpanishQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_DISCARD] = ["Puedes descartar un Tesoro", "Puedes descartar"];
SpanishQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_GAIN] = ["Gana una carta que cueste hasta $4.", "Gana una carta"];
SpanishQuestionDescriptions[QuestionIds.THE_FLAMES_GIFT] = ["Puedes eliminar una carta de tu mano.", "Puedes eliminar"];
SpanishQuestionDescriptions[QuestionIds.THE_MOONS_GIFT] = ["Puedes put una carta sobre tu mazo.", "Puedes poner sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.THE_SKYS_GIFT] = ["Puedes descartar 3 cartas to ganar a Oro.", "Puedes descartar"];
SpanishQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_TOPDECK] = ["put the rest back en cualquier orden", "Pon el resto sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_DISCARD] = ["Descarta cualquier número", "Descarta cualquier número"];
SpanishQuestionDescriptions[QuestionIds.THE_WINDS_GIFT] = ["Descarta 2 cartas.", "Descarta 2 cartas"];
SpanishQuestionDescriptions[QuestionIds.FEAR_DISCARD] = ["descartar una Acción o Tesoro", "Descarta una carta"];
SpanishQuestionDescriptions[QuestionIds.HAUNTING] = ["Pon something sobre el mazo.", "Pon algo sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.LOCUSTS] = ["Gana un cheaper carta that shares a type.", "Gana una carta más barata"];
SpanishQuestionDescriptions[QuestionIds.POVERTY] = ["Descarta hasta tener 3 cartas in hand", "Descarta hasta tener 3"];
SpanishQuestionDescriptions[QuestionIds.BAT] = ["Puedes eliminar hasta 2 cartas", "Puedes eliminar"];
SpanishQuestionDescriptions[QuestionIds.BLESSED_VILLAGE_MODE] = ["When to receive Argument0?", "¿Cuándo recibir?"];
SpanishQuestionDescriptions[QuestionIds.CEMETERY] = ["Puedes eliminar hasta 4 cartas", "Puedes eliminar"];
SpanishQuestionDescriptions[QuestionIds.CHANGELING_GAIN_COPY] = ["Gana un copia de una carta you have in jugar", "Gana una carta"];
SpanishQuestionDescriptions[QuestionIds.CHANGELING_REPLACE] = ["", ""];
SpanishQuestionDescriptions[QuestionIds.COBBLER] = ["Gana una carta que cueste hasta $4 a tu mano.", "Gana a tu mano"];
SpanishQuestionDescriptions[QuestionIds.CONCLAVE] = ["Puedes jugar una Acción", "Puedes jugar una Acción"];
SpanishQuestionDescriptions[QuestionIds.CRYPT_SET_ASIDE] = ["Aparta cualquier número de Tesoros you have in jugar", "Aparta cualquier número"];
SpanishQuestionDescriptions[QuestionIds.CRYPT_RETURN_TO_HAND] = ["Elige una carta to poner en tu mano.", "Elige una carta"];
SpanishQuestionDescriptions[QuestionIds.DEVILS_WORKSHOP] = ["Gana una carta que cueste hasta $4.", "Gana una carta"];
SpanishQuestionDescriptions[QuestionIds.DRUID] = ["Which Boon to receive?", "¿Qué Bendición?"];
SpanishQuestionDescriptions[QuestionIds.EXORCIST_TRASH] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.EXORCIST_GAIN] = ["Gana un cheaper Spirit", "Gana un Espíritu más barato"];
SpanishQuestionDescriptions[QuestionIds.FOOL] = ["What Boon next?", "¿Siguiente Bendición?"];
SpanishQuestionDescriptions[QuestionIds.GOAT] = ["Puedes eliminar una carta", "Puedes eliminar"];
SpanishQuestionDescriptions[QuestionIds.HAUNTED_MIRROR] = ["Puedes descartar una action to get a Ghost", "Puedes descartar"];
SpanishQuestionDescriptions[QuestionIds.IMP] = ["Puedes jugar una action you don't have in jugar", "¿Qué jugar?"];
SpanishQuestionDescriptions[QuestionIds.NECROMANCER] = ["Juega from eliminar", "Juega una de las cartas eliminadas"];
SpanishQuestionDescriptions[QuestionIds.MONASTERY] = ["Puedes eliminar hasta AMOUNT0 cartas o Coppers in jugar.", "Puedes eliminar hasta AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_TOPDECK] = ["Pon the rest sobre el mazo", "Pon el resto sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_DISCARD] = ["Descarta cualquier número", "Descarta cualquier número"];
SpanishQuestionDescriptions[QuestionIds.PIXIE_MODE] = ["Elimina Pixie to receive dos veces?", "¿Eliminar Hada?"];
SpanishQuestionDescriptions[QuestionIds.POOKA] = ["Puedes eliminar a Tesoro other than Cursed Oro", "Puedes eliminar"];
SpanishQuestionDescriptions[QuestionIds.RAIDER] = ["Descarta una carta", "Descarta una carta"];
SpanishQuestionDescriptions[QuestionIds.SACRED_GROVE_MODE] = ["Also receive Argument0?", "¿Recibir Argument0?"];
SpanishQuestionDescriptions[QuestionIds.SECRET_CAVE] = ["Puedes descartar 3 cartas for +$3 next turn", "Puedes descartar"];
SpanishQuestionDescriptions[QuestionIds.SHEPHERD] = ["Descarta carta de Victorias for +2 cartas each", "Puedes descartar"];
SpanishQuestionDescriptions[QuestionIds.TRAGIC_HERO] = ["Gana un Tesoro", "Gana un Tesoro"];
SpanishQuestionDescriptions[QuestionIds.VAMPIRE] = ["Gana un non-Vampire hasta 5", "Gana una carta"];
SpanishQuestionDescriptions[QuestionIds.WISH] = ["Gana una carta a tu mano", "Gana una carta"];
SpanishQuestionDescriptions[QuestionIds.ZOMBIE_APPRENTICE] = ["Puedes eliminar una Acción", "Puedes eliminar"];
SpanishQuestionDescriptions[QuestionIds.ZOMBIE_MASON] = ["Puedes ganar una carta", "Puedes ganar una carta"];
SpanishQuestionDescriptions[QuestionIds.ZOMBIE_SPY] = ["Puedes Descarta", "Puedes descartar"];
SpanishQuestionDescriptions[QuestionIds.WHAT_NIGHT_TO_PLAY] = ["Puedes jugar a Night carta", "¿Qué Noche jugar?"];
SpanishQuestionDescriptions[QuestionIds.LOST_IN_THE_WOODS] = ["Puedes descartar una carta to receive a Boon", "¿Descarta por Bendición?"];
SpanishQuestionDescriptions[QuestionIds.START_GAME] = ["Inspect your starting cartas", "Tus cartas iniciales:"];
SpanishQuestionDescriptions[QuestionIds.DISMANTLE_TRASH] = ["Elimina una carta de tu mano", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.DISMANTLE_GAIN] = ["Gana una carta cheaper than AMOUNT0.", "Gana una carta más barata que AMOUNT0"];

SpanishQuestionDescriptions[QuestionIds.DUCAT] = ["Puedes eliminar a Cobre.", "Puedes eliminar un Cobre"];
SpanishQuestionDescriptions[QuestionIds.IMPROVE_TRASH] = ["Puedes eliminar una Acción in jugar that would be descartada", "Puedes Perfeccionar"];
SpanishQuestionDescriptions[QuestionIds.IMPROVE_GAIN] = ["Gana una carta que cueste exactamente AMOUNT0.", "Gana una carta de AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.HIDEOUT] = ["Elimina una carta. If it's a carta de Victoria, ganar a Curse", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.MOUNTAIN_VILLAGE] = ["Put una carta de tu descartar en tu mano", "Pon en tu mano"];
SpanishQuestionDescriptions[QuestionIds.PRIEST] = ["Elimina una carta de tu mano", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.RESEARCH_TRASH] = ["Elimina una carta de tu mano", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.OLD_WITCH] = ["Puedes eliminar a Curse de tu mano", "Puedes eliminar"];
SpanishQuestionDescriptions[QuestionIds.RECRUITER] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.SCEPTER_COMPLEX] = ["When playing Scepter", "¿Jugar de nuevo o Monedas?"];
SpanishQuestionDescriptions[QuestionIds.SCEPTER_MODE] = ["get +$2", "+$2"];
SpanishQuestionDescriptions[QuestionIds.SCEPTER_PLAY] = ["replay una Acción still in jugar", "Jugar de nuevo una Acción"];
SpanishQuestionDescriptions[QuestionIds.SCULPTOR] = ["Gana una carta que cueste hasta $4 a tu mano.", "Gana a tu mano"];
SpanishQuestionDescriptions[QuestionIds.SEER] = ["Put them back sobre tu mazo en cualquier orden", "Pon sobre el mazo en cualquier orden"];
SpanishQuestionDescriptions[QuestionIds.VILLAIN] = ["Descarta una carta que cueste 2 o more.", "Descarta una carta"];
SpanishQuestionDescriptions[QuestionIds.TREASURER_COMPLEX] = ["When playing Treasurer", "Al jugar Tesorera..."];
SpanishQuestionDescriptions[QuestionIds.TREASURER_TRASH] = ["eliminar a Tesoro", "Eliminar un Tesoro"];
SpanishQuestionDescriptions[QuestionIds.TREASURER_GAIN] = ["ganar a Tesoro a tu mano", "Ganar un Tesoro"];
SpanishQuestionDescriptions[QuestionIds.TREASURER_KEY] = ["take the Key", "Coger la Llave"];
SpanishQuestionDescriptions[QuestionIds.TREASURER_FAIL] = ["do nothing", "No hacer nada"];
SpanishQuestionDescriptions[QuestionIds.BORDER_GUARD_PUT_IN_HAND] = ["Put one de the revealed cartas en tu mano", "Pon en tu mano"];
SpanishQuestionDescriptions[QuestionIds.BORDER_GUARD_MODE] = ["What Artifact do you want to take?", "¿Cuerno o Linterna?"];
SpanishQuestionDescriptions[QuestionIds.CATHEDRAL] = ["Elimina una carta de tu mano.", "Elimina una carta"];
SpanishQuestionDescriptions[QuestionIds.CITY_GATE] = ["Put una carta de tu mano sobre tu mazo.", "Pon una carta sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.PAGEANT] = ["you may pay $1 for +1 Coffers.", "¿$1 para +1 Cofre?"];
SpanishQuestionDescriptions[QuestionIds.SEWERS] = ["Puedes eliminar hasta AMOUNT0 cartas de tu mano. (Sewers)", "Puedes eliminar hasta AMOUNT0 (Alcantarillas)"];
SpanishQuestionDescriptions[QuestionIds.SILOS] = ["Descarta Coppers for +1 carta each", "Puedes descartar"];
SpanishQuestionDescriptions[QuestionIds.CROP_ROTATION] = ["Descarta a carta de Victoria for +2 cartas?", "Puedes descartar"];
SpanishQuestionDescriptions[QuestionIds.SINISTER_PLOT] = ["Draw AMOUNT0 cartas o add a ficha?", "Roba AMOUNT0 o añade una ficha?"];
SpanishQuestionDescriptions[QuestionIds.STAR_CHART] = ["Select una carta to go on top when shuffling", "Elige una carta para poner encima"];
SpanishQuestionDescriptions[QuestionIds.CAPTAIN] = ["Elige una Acción from the supply hasta $4 to jugar", "Elige qué jugar"];
SpanishQuestionDescriptions[QuestionIds.CHURCH_SET_ASIDE] = ["Aparta hasta 3 cartas", "Aparta hasta 3 cartas"];
SpanishQuestionDescriptions[QuestionIds.CHURCH_TRASH] = ["Puedes eliminar una carta de tu mano.", "Puedes eliminar una carta"];
SpanishQuestionDescriptions[QuestionIds.CAMEL_TRAIN] = ["Exilia una carta from the supply.", "Exilia una carta"];
SpanishQuestionDescriptions[QuestionIds.GOATHERD] = ["Puedes eliminar una carta de tu mano.", "Puedes eliminar una carta"];
SpanishQuestionDescriptions[QuestionIds.SNOWY_VILLAGE] = ["Puedes usar villagers while you can", "¿Usar Aldeanos mientras aún puedes?"];
SpanishQuestionDescriptions[QuestionIds.BOUNTY_HUNTER] = ["Exilia una carta de tu mano", "Exilia una carta"];
SpanishQuestionDescriptions[QuestionIds.CARDINAL_EXILE] = ["Exilia una carta", "Exilia una carta"];
SpanishQuestionDescriptions[QuestionIds.GROOM] = ["Gana una carta que cueste hasta AMOUNT0.", "Gana una carta hasta AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.HOSTELRY] = ["Descarta for Caballos", "Descarta por Caballos"];
SpanishQuestionDescriptions[QuestionIds.VILLAGE_GREEN] = ["Now o next turn?", "¿Ahora o el próximo turno?"];
SpanishQuestionDescriptions[QuestionIds.BARGE] = ["Now o next turn?", "¿Ahora o el próximo turno?"];
SpanishQuestionDescriptions[QuestionIds.DISPLACE_EXILE] = ["Exilia una carta de tu mano", "Exilia una carta"];
SpanishQuestionDescriptions[QuestionIds.DISPLACE_GAIN] = ["Gana una carta que cueste hasta AMOUNT0.", "Gana una carta hasta AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.FALCONER] = ["Gana una carta a tu mano", "Gana una carta a tu mano"];
SpanishQuestionDescriptions[QuestionIds.HUNTING_LODGE] = ["Descarta for +5 Cartas?", "Descarta para +5 Cartas?"];
SpanishQuestionDescriptions[QuestionIds.MASTERMIND] = ["Puedes jugar una carta de Acción three times.", "Puedes jugar una Acción tres veces"];
SpanishQuestionDescriptions[QuestionIds.SANCTUARY] = ["Puedes Exile a Carta de tu mano", "Puedes Exiliar una Carta"];
SpanishQuestionDescriptions[QuestionIds.WAYFARER] = ["Puedes ganar a Plata", "Puedes ganar una Plata"];
SpanishQuestionDescriptions[QuestionIds.DELAY] = ["Puedes set aside una Acción", "Puedes apartar una Acción"];
SpanishQuestionDescriptions[QuestionIds.DESPERATION] = ["Puedes ganar a Curse", "Puedes ganar una Maldición"];
SpanishQuestionDescriptions[QuestionIds.GAMBLE] = ["Juega Argument0?", "¿Jugar Argument0?"];
SpanishQuestionDescriptions[QuestionIds.PURSUE] = ["What carta to keep on top?", "¿Qué carta mantener encima?"];
SpanishQuestionDescriptions[QuestionIds.TOIL] = ["Puedes jugar una carta", "Puedes jugar una carta"];
SpanishQuestionDescriptions[QuestionIds.ENHANCE_TRASH] = ["Puedes eliminar a non-carta de Victoria", "Puedes eliminar una carta que no sea de Victoria"];
SpanishQuestionDescriptions[QuestionIds.ENHANCE_GAIN] = ["Gana una carta que cueste hasta AMOUNT0.", "Gana una carta hasta AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.MARCH] = ["Puedes jugar una carta de tu pila de descartes", "Puedes jugar una carta desde el descarte"];
SpanishQuestionDescriptions[QuestionIds.TRANSPORT_MAY_EXILE] = ["Puedes exile una carta de Acción", "Puedes exiliar una carta de Acción"];
SpanishQuestionDescriptions[QuestionIds.TRANSPORT_EXILE] = ["Exile una carta de Acción", "Exilia una carta de Acción"];
SpanishQuestionDescriptions[QuestionIds.TRANSPORT_MAY_TOPDECK] = ["Puedes poner una Exiled Acción sobre el mazo", "Puedes poner una Acción exiliada sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.TRANSPORT_TOPDECK] = ["Pon una Exiled Acción", "Pon una Acción exiliada sobre el mazos"];
SpanishQuestionDescriptions[QuestionIds.TRANSPORT_COMPLEX] = ["Exile from the Suministro; o pon sobre el mazo from Exile", "Exilia desde el Suministro; o pon una carta del Exilio sobre el mazo"];
SpanishQuestionDescriptions[QuestionIds.BANISH] = ["Exile cartas with the same name", "Exilia cartas con el mismo nombre"];
SpanishQuestionDescriptions[QuestionIds.BARGAIN] = ["Gana un non-Victoria hasta $5", "Gana un carta hasta $5 que no sea de Victoria"];
SpanishQuestionDescriptions[QuestionIds.INVEST] = ["Exile una carta de Acción to Invest in", "Exilia una carta de Acción como Inversión"];
SpanishQuestionDescriptions[QuestionIds.DEMAND] = ["Gana una carta hasta $4 sobre tu mazo", "Gana una carta de hasta $4 sobre tu mazo"];
SpanishQuestionDescriptions[QuestionIds.POPULATE] = ["What carta to ganar next?", "¿Qué carta ganar a continuación?"];
SpanishQuestionDescriptions[QuestionIds.RECONSIDER_WAY] = ["Reconsider the Way you jugar Argument0", "Reconsidera jugar Argument0 como Senda"];
SpanishQuestionDescriptions[QuestionIds.CONSIDER_WAY] = ["Consider the Way you jugar Argument0", "Considera jugar Argument0 como Senda"];
SpanishQuestionDescriptions[QuestionIds.WAY_OF_THE_GOAT] = ["Elimina a Carta de tu mano.", "Elimina una carta de tu mano."];
SpanishQuestionDescriptions[QuestionIds.WAY_OF_THE_RAT] = ["Descarta a Tesoro to ganar a copia", "Descarta un Tesoro para ganar una copia"];
SpanishQuestionDescriptions[QuestionIds.SCRAP_TRASH] = ["Elimina una carta de tu mano.", "Elimina una carta de tu mano."];
SpanishQuestionDescriptions[QuestionIds.SCRAP_MODES] = ["Elige Argument0 effects", "Elige Argument0 efectos"];
SpanishQuestionDescriptions[QuestionIds.ANIMAL_FAIR_TRASH] = ["Elimina una Acción de tu mano", "Elimina una Acción de tu mano"];
SpanishQuestionDescriptions[QuestionIds.ANIMAL_FAIR_MAY_TRASH] = ["Puedes eliminar una Acción de tu mano", "Puedes eliminar una Acción de tu mano"];
SpanishQuestionDescriptions[QuestionIds.BUTTERFLY_RETURN] = ["Return Argument0 to its pile?", "¿Devolver Argument0 a su pila?"];
SpanishQuestionDescriptions[QuestionIds.BUTTERFLY_GAIN] = SpanishQuestionDescriptions[QuestionIds.UPGRADE_GAIN];

SpanishQuestionDescriptions[QuestionIds.BLOCKADE] = ["", "Gain a card"];
SpanishQuestionDescriptions[QuestionIds.PIRATE] = ["", "Gain to your hand"];
SpanishQuestionDescriptions[QuestionIds.SAILOR] = ["", "You may trash a card"];
SpanishQuestionDescriptions[QuestionIds.TIDE_POOLS] = SpanishQuestionDescriptions[QuestionIds.DUNGEON];
SpanishQuestionDescriptions[QuestionIds.SEA_WITCH] = SpanishQuestionDescriptions[QuestionIds.DUNGEON];
SpanishQuestionDescriptions[QuestionIds.TIARA_MODE] = ["", "Choose how to play Tiara"];
SpanishQuestionDescriptions[QuestionIds.TIARA_PLAY] = ["", "What Treasure to play twice"];
SpanishQuestionDescriptions[QuestionIds.CRYSTAL_BALL] = ["", "What to do with Argument0"];
SpanishQuestionDescriptions[QuestionIds.WAR_CHEST_PROHIBIT] = ["", "Ban a card"];
SpanishQuestionDescriptions[QuestionIds.WAR_CHEST_GAIN] = ["", "Gain a card"];
SpanishQuestionDescriptions[QuestionIds.ANVIL_DISCARD] = SpanishQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_DISCARD];
SpanishQuestionDescriptions[QuestionIds.ANVIL_GAIN] = SpanishQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_GAIN];
SpanishQuestionDescriptions[QuestionIds.CLERK] = ["", "Topdeck a Card"];
SpanishQuestionDescriptions[QuestionIds.INVESTMENT_TRASH] = SpanishQuestionDescriptions[QuestionIds.TRADE_ROUTE];
SpanishQuestionDescriptions[QuestionIds.INVESTMENT_MODE] = ["", "Choose how to play Investment"];
SpanishQuestionDescriptions[QuestionIds.TOWN_MODE] = ["", "How to play Town"];
SpanishQuestionDescriptions[QuestionIds.MODIFY_TRASH] = ["", "Trash a card"];
SpanishQuestionDescriptions[QuestionIds.MODIFY_MODE] = ["", "How to play Modify"];
SpanishQuestionDescriptions[QuestionIds.MODIFY_GAIN] = ["", "Gain a card"];
SpanishQuestionDescriptions[QuestionIds.SWAP_RETURN] = ["", "You may return a card"];
SpanishQuestionDescriptions[QuestionIds.SWAP_GAIN] = ["", "Gain an Action"];
SpanishQuestionDescriptions[QuestionIds.SKIRMISHER] = ["", "Discard down to 3"];
SpanishQuestionDescriptions[QuestionIds.WOODWORKERS_GUILD_TRASH] = ["", "Trash an Action"];
SpanishQuestionDescriptions[QuestionIds.WOODWORKERS_GUILD_GAIN] = ["", "Gain an Action"];
SpanishQuestionDescriptions[QuestionIds.CRAFTERS_GUILD] = ["", "Spend 2 Favors to gain a card?"];
SpanishQuestionDescriptions[QuestionIds.CAVE_DWELLERS] = ["", "Spend a Favor and discard to draw?"];
SpanishQuestionDescriptions[QuestionIds.DESERT_GUIDES] = ["", "Keep this hand?"];
SpanishQuestionDescriptions[QuestionIds.BAND_OF_NOMADS] = ["", "Spend more Favors for Buys?"];
SpanishQuestionDescriptions[QuestionIds.SYCOPHANT] = ["", "Discard 3 cards"];
SpanishQuestionDescriptions[QuestionIds.IMPORTER] = ["", "Gain a card costing up to $5"];
SpanishQuestionDescriptions[QuestionIds.CONTRACT] = ["Y", "You may play an action next turn"];
SpanishQuestionDescriptions[QuestionIds.BAUBLE] = ["", "Choose how to play Bauble"];
SpanishQuestionDescriptions[QuestionIds.BROKER_TRASH] = ["", "What card to trash?"];
SpanishQuestionDescriptions[QuestionIds.BROKER_MODE] = ["W", "What bonus to receive?"];
SpanishQuestionDescriptions[QuestionIds.MARKET_TOWNS_FIRST] = ["", "What action to play?"];
SpanishQuestionDescriptions[QuestionIds.MARKET_TOWNS_AGAIN] = ["", "Play another action for a Favor?"];
SpanishQuestionDescriptions[QuestionIds.BATTLE_PLAN_REVEAL] = ["", "Reveal an Attack for +1 Card?"];
SpanishQuestionDescriptions[QuestionIds.BATTLE_PLAN_ROTATE] = ["", "Rotate a supply pile?"];
SpanishQuestionDescriptions[QuestionIds.BARBARIAN] = ["", "Gain a cheaper card"];
SpanishQuestionDescriptions[QuestionIds.INNKEEPER_MODE] = ["", "How to play Innkeeper?"];
SpanishQuestionDescriptions[QuestionIds.INNKEEPER_DISCARD] = ["", "Discard Argument0 Cards"];
SpanishQuestionDescriptions[QuestionIds.CAPITAL_CITY_MODE] = ["", "Discard or pay?"];
SpanishQuestionDescriptions[QuestionIds.CAPITAL_CITY_DISCARD] = ["", "Discard 2 Cards"];
SpanishQuestionDescriptions[QuestionIds.SPECIALIST_PLAY] = ["", "What card to play?"];
SpanishQuestionDescriptions[QuestionIds.SPECIALIST_MODE] = ["", "Play Argument0 again or copy?"];
SpanishQuestionDescriptions[QuestionIds.GANG_OF_PICKPOCKETS] = ["", "Discard or spend a Favor?"];
SpanishQuestionDescriptions[QuestionIds.COASTAL_HAVEN] = ["", "What cards to keep in hand?"];
SpanishQuestionDescriptions[QuestionIds.CARPENTER_GAIN] = SpanishQuestionDescriptions[QuestionIds.WORKSHOP];
SpanishQuestionDescriptions[QuestionIds.CARPENTER_TRASH] = SpanishQuestionDescriptions[QuestionIds.REMODEL_TRASH];
SpanishQuestionDescriptions[QuestionIds.CARPENTER_GAIN_UP_TO] = SpanishQuestionDescriptions[QuestionIds.REMODEL_GAIN];
SpanishQuestionDescriptions[QuestionIds.MAY_ROTATE_FORTS] = ["", "You may rotate the Forts"];
SpanishQuestionDescriptions[QuestionIds.ROYAL_GALLEY] = ["", "You may play an Action"];
SpanishQuestionDescriptions[QuestionIds.HILL_FORT_GAIN] = SpanishQuestionDescriptions[QuestionIds.WORKSHOP];
SpanishQuestionDescriptions[QuestionIds.HILL_FORT_MODE] = ["", "Choose how to play Hill Fort"];
SpanishQuestionDescriptions[QuestionIds.STRONGHOLD_MODE] = ["", "Choose how to play Stronghold"];
SpanishQuestionDescriptions[QuestionIds.MAY_ROTATE_ODYSSEYS] = ["Y", "You may rotate the Odysseys"];
SpanishQuestionDescriptions[QuestionIds.OLD_MAP_DISCARD] = ["", "Discard a Card"];
SpanishQuestionDescriptions[QuestionIds.SUNKEN_TREASURE] = ["", "Gain an Action you don't have in play"];
SpanishQuestionDescriptions[QuestionIds.PEACEFUL_CULT] = ["", "You may trash for Favors"];
SpanishQuestionDescriptions[QuestionIds.ORDER_OF_ASTROLOGERS] = ["", "You may topdeck for Favors"];
SpanishQuestionDescriptions[QuestionIds.STAR_CHART_ASTROLOGERS_COMBINED] = ["", "You may topdeck for Favors (one free)"];
SpanishQuestionDescriptions[QuestionIds.ORDER_OF_MASONS] = ["", "You may pick 2 cards per Favor to put into your discard"];
SpanishQuestionDescriptions[QuestionIds.ARCHER_HIDE] = ["", "Choose a Card not to reveal"];
SpanishQuestionDescriptions[QuestionIds.ARCHER_DISCARD] = SpanishQuestionDescriptions[QuestionIds.PILLAGE];
SpanishQuestionDescriptions[QuestionIds.MARQUIS] = ["", "Discard down to 10"];
SpanishQuestionDescriptions[QuestionIds.HERB_GATHERER_PLAY] = ["", "What Treasure to play?"];
SpanishQuestionDescriptions[QuestionIds.MAY_ROTATE_AUGURS] = ["", "You may rotate the Augurs"];
SpanishQuestionDescriptions[QuestionIds.ACOLYTE] = ["", "Trash to gain a Gold?"];
SpanishQuestionDescriptions[QuestionIds.ACOLYTE_SELF] = ["", "Trash to gain an Augur?"];
SpanishQuestionDescriptions[QuestionIds.SORCERESS] = ["", "Guess a Card"];
SpanishQuestionDescriptions[QuestionIds.SIBYL_TOPDECK] = ["", "Topdeck a card"];
SpanishQuestionDescriptions[QuestionIds.SIBYL_BOTTOMDECK] = ["", "Bottomdeck a card"];
SpanishQuestionDescriptions[QuestionIds.MAY_ROTATE_WIZARDS] = ["", "You may rotate the Wizards"];
SpanishQuestionDescriptions[QuestionIds.STUDENT] = ["", "Trash a Card"];
SpanishQuestionDescriptions[QuestionIds.CONJURER] = SpanishQuestionDescriptions[QuestionIds.WORKSHOP];
SpanishQuestionDescriptions[QuestionIds.SORCERER] = ["", "Guess a Card"];
SpanishQuestionDescriptions[QuestionIds.LICH] = ["", "Gain a Card from the Trash"];
SpanishQuestionDescriptions[QuestionIds.MAY_ROTATE_TOWNSFOLK] = ["", "You may rotate the Townsfolk"];
SpanishQuestionDescriptions[QuestionIds.TOWN_CRIER] = ["", "How to play Town Crier?"];
SpanishQuestionDescriptions[QuestionIds.MILLER] = ["", "How to play Miller?"];
SpanishQuestionDescriptions[QuestionIds.ELDER] = ["", "You may play a card"];
SpanishQuestionDescriptions[QuestionIds.SENTINEL_TRASH] = ["", "Trash up to two cards"];
SpanishQuestionDescriptions[QuestionIds.SENTINEL_TOPDECK] = SpanishQuestionDescriptions[QuestionIds.APOTHECARY];
SpanishQuestionDescriptions[QuestionIds.COURIER] = ["", "You may play a card"];
SpanishQuestionDescriptions[QuestionIds.HUNTER_ACTION] = ["", "Put an Action into your hand"];
SpanishQuestionDescriptions[QuestionIds.HUNTER_TREASURE] = ["", "Put a Treasure into your hand"];
SpanishQuestionDescriptions[QuestionIds.HUNTER_VICTORY] = ["", "Put a Victory card into your hand"];
SpanishQuestionDescriptions[QuestionIds.LURKER_MODE] = ["", "Choose how to play Lurker"];
SpanishQuestionDescriptions[QuestionIds.GRAVEROBBER_MODE] = ["", "Choose how to play Graverobber"];
SpanishQuestionDescriptions[QuestionIds.TREASURER_MODE] = ["", "Choose how to play Treasurer"];
SpanishQuestionDescriptions[QuestionIds.ARCHITECTS_GUILD] = ["", "What to gain"];
SpanishQuestionDescriptions[QuestionIds.FAMILY_OF_INVENTORS] = ["", "What pile to make cheaper"];
SpanishQuestionDescriptions[QuestionIds.FOREST_DWELLERS_DISCARD] = ["", "Discard any number"];
SpanishQuestionDescriptions[QuestionIds.FOREST_DWELLERS_TOPDECK] = ["", "Topdeck in some order"];
SpanishQuestionDescriptions[QuestionIds.USE_FAVOR_WHEN_SHUFFLING] = ["", "Use a favor when shuffling?"];
SpanishQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS] = ["", "You may spend extra coffers"];
SpanishQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_BASILICA] = ["", "You may spend extra coffers for Basilica"];
SpanishQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_SWASHBUCKLER] = ["", "You may spend extra coffers to not get the Treasure Chest"];
SpanishQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_FORTUNE] = ["", "You may spend extra coffers before doubling your Coins"];

SpanishQuestionDescriptions[QuestionIds.WHEELWRIGHT_DISCARD] = ["", "Discard to gain"];
SpanishQuestionDescriptions[QuestionIds.WHEELWRIGHT_GAIN] = ["", "Gain a card up to AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.BERSERKER_DISCARD] = SpanishQuestionDescriptions[QuestionIds.MILITIA];
SpanishQuestionDescriptions[QuestionIds.BERSERKER_GAIN] = ["", "Gain a cheaper card"];
SpanishQuestionDescriptions[QuestionIds.WEAVER] = ["", "Gain a Card or 2 Silvers"];
SpanishQuestionDescriptions[QuestionIds.SOUK] = ["", "Trash up to 2"];
SpanishQuestionDescriptions[QuestionIds.WITCHS_HUT] = ["", "Discard 2 Cards revealed"];
SpanishQuestionDescriptions[QuestionIds.STONEMASON_OVERPAY_AMOUNT] = ["You may overpay for stonemason by gaining an Action up to AMOUNT0.", "Specify the overpay amount."];

// Missing lines as of 09.01.2023 18:47:42
SpanishQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_TWO] = ["Return 0, 1 or CARDS0 to the Supply.", "Return 0, 1 or 2"];
SpanishQuestionDescriptions[QuestionIds.HORN_TOPDECK] = ["Which [Border Guard] to topdeck?", "Which [Border Guard] to topdeck?"];
SpanishQuestionDescriptions[QuestionIds.BLACKSMITH] = ["How to play Blacksmith?", "How to play Blacksmith?"];
SpanishQuestionDescriptions[QuestionIds.CAGE_SET_ASIDE] = ["You may set aside up to 4 cards.", "You may set aside up to 4 cards."];
SpanishQuestionDescriptions[QuestionIds.GROTTO_SET_ASIDE] = ["You may set aside up to 4 cards.", "You may set aside up to 4 cards."];
SpanishQuestionDescriptions[QuestionIds.SHAMAN_GAIN] = ["Gain a card costing up to 6 from the trash.", "Gain a card costing up to 6 from the trash."];
SpanishQuestionDescriptions[QuestionIds.SHAMAN_TRASH] = ["You may trash a card from your hand.", "You may trash a card from your hand."];
SpanishQuestionDescriptions[QuestionIds.SECLUDED_SHRINE] = ["You may trash up to 2 cards from your hand.", "You may trash up to 2 cards from your hand."];
SpanishQuestionDescriptions[QuestionIds.SIREN] = ["You may trash an Action.", "You may trash an Action."];
SpanishQuestionDescriptions[QuestionIds.CABIN_BOY_MODE] = ["Choose one: +2 Coins, or trash [Cabin Boy] to gain a Duration.", "Choose one: +2 Coins, or trash [Cabin Boy] to gain a Duration."];
SpanishQuestionDescriptions[QuestionIds.CABIN_BOY_GAIN] = ["Gain a Duration card.", "Gain a Duration card."];
SpanishQuestionDescriptions[QuestionIds.CRUCIBLE] = ["Trash a card from your hand.", "Trash a card from your hand."];
SpanishQuestionDescriptions[QuestionIds.FORTUNE_HUNTER_PLAY] = ["Play a revealed treasure.", "Play a revealed treasure."];
SpanishQuestionDescriptions[QuestionIds.FORTUNE_HUNTER_TOPDECK] = ["Topdeck the remaining cards.", "Topdeck the remaining cards."];
SpanishQuestionDescriptions[QuestionIds.GONDOLA_MODE] = ["+2 Coins now or later?", "+2 Coins now or later?"];
SpanishQuestionDescriptions[QuestionIds.GONDOLA_PLAY] = ["You may play an Action card from your hand.", "You may play an Action card from your hand."];
SpanishQuestionDescriptions[QuestionIds.MAPMAKER] = ["Put 2 cards into your hand.", "Put 2 cards into your hand."];
SpanishQuestionDescriptions[QuestionIds.MAROON] = ["Trash a card.", "Trash a card."];
SpanishQuestionDescriptions[QuestionIds.ROPE_TRASH] = ["You may trash a card from your hand.", "You may trash a card from your hand."];
SpanishQuestionDescriptions[QuestionIds.TOOLS] = ["Gain a copy of a card somebody has in play.", "Gain a copy of a card somebody has in play."];
SpanishQuestionDescriptions[QuestionIds.CUTTHROAT_DISCARD] = ["Discard down to 3 cards in hand.", "Discard down to 3 cards in hand."];
SpanishQuestionDescriptions[QuestionIds.ENLARGE_TRASH] = ["Trash a card.", "Trash a card."];
SpanishQuestionDescriptions[QuestionIds.ENLARGE_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card costing up to AMOUNT0"];
SpanishQuestionDescriptions[QuestionIds.FIGURINE] = ["You may discard an Action for +1 Coin and +1 Buy.", "You may discard an Action for +1 Coin and +1 Buy."];
SpanishQuestionDescriptions[QuestionIds.FIRST_MATE] = ["You may play a card.", "You may play a card."];
SpanishQuestionDescriptions[QuestionIds.FRIGATE] = ["Discard down to 4 cards in hand.", "Discard down to 4 cards in hand."];
SpanishQuestionDescriptions[QuestionIds.PICKAXE] = ["Trash a card from your hand.", "Trash a card from your hand."];
SpanishQuestionDescriptions[QuestionIds.PILGRIM_TOPDECK] = ["Put a card from your hand onto your deck.", "Put a card from your hand onto your deck."];
SpanishQuestionDescriptions[QuestionIds.QUARTERMASTER_MODE] = ["Gain a card costing up to 4, or take one of: Argument0.", "Gain a card costing up to 4, or take one of: Argument0."];
SpanishQuestionDescriptions[QuestionIds.QUARTERMASTER_GAIN] = ["Gain a card costing up to 4.", "Gain a card costing up to 4."];
SpanishQuestionDescriptions[QuestionIds.QUARTERMASTER_TAKE] = ["Put a card into your hand.", "Put a card into your hand."];
SpanishQuestionDescriptions[QuestionIds.TRICKSTER] = ["You may set aside a treasure from play.", "You may set aside a treasure from play."];
SpanishQuestionDescriptions[QuestionIds.SILVER_MINE] = ["Gain a treasure into your hand.", "Gain a treasure into your hand."];
SpanishQuestionDescriptions[QuestionIds.KINGS_CACHE] = ["You may play a Treasure 3 times.", "You may play a Treasure 3 times."];
SpanishQuestionDescriptions[QuestionIds.BURY] = ["Place a card on the bottom of your deck.", "Place a card on the bottom of your deck."];
SpanishQuestionDescriptions[QuestionIds.PERIL] = ["You may trash an action to gain a Loot.", "You may trash an action to gain a Loot."];
SpanishQuestionDescriptions[QuestionIds.PREPARE] = ["Play a set-aside card.", "Play a set-aside card."];
SpanishQuestionDescriptions[QuestionIds.FORAY] = ["Discard 3 cards.", "Discard 3 cards."];
SpanishQuestionDescriptions[QuestionIds.SCROUNGE_COMPLEX] = ["When playing Scrounge,", "When playing Scrounge,"];
SpanishQuestionDescriptions[QuestionIds.SCROUNGE_TRASH] = ["Trash a card from your hand.", "Trash a card from your hand."];
SpanishQuestionDescriptions[QuestionIds.SCROUNGE_MAY_TRASH] = ["You may trash a card from your hand.", "You may trash a card from your hand."];
SpanishQuestionDescriptions[QuestionIds.SCROUNGE_GAIN_ESTATE] = ["Gain an Estate.", "Gain an Estate."];
SpanishQuestionDescriptions[QuestionIds.SCROUNGE_MAY_GAIN_ESTATE] = ["You may gain an Estate.", "You may gain an Estate."];
SpanishQuestionDescriptions[QuestionIds.SCROUNGE_GAIN] = ["Gain a card costing up to 5.", "Gain a card costing up to 5."];
SpanishQuestionDescriptions[QuestionIds.MAELSTROM_TRASH] = ["Trash a card.", "Trash a card."];
SpanishQuestionDescriptions[QuestionIds.MAELSTROM_OPPONENT_TRASH] = ["Trash Argument0 cards.", "Trash Argument0 cards."];
SpanishQuestionDescriptions[QuestionIds.INVASION_PLAY] = ["You may play an Attack.", "You may play an Attack."];
SpanishQuestionDescriptions[QuestionIds.INVASION_GAIN] = ["Gain an Action.", "Gain an Action."];
SpanishQuestionDescriptions[QuestionIds.PROSPER] = ["Gain a differently-named Treasure.", "Gain a differently-named Treasure."];
SpanishQuestionDescriptions[QuestionIds.AMPHORA_MODE] = ["+1 Buy, +3 Coins now or next turn?", "+1 Buy, +3 Coins now or next turn?"];
SpanishQuestionDescriptions[QuestionIds.HAMMER] = ["Gain a card costing up to 4.", "Gain a card costing up to 4."];
SpanishQuestionDescriptions[QuestionIds.ORB] = ["Play an Action or Treasure.", "Play an Action or Treasure."];
SpanishQuestionDescriptions[QuestionIds.PRIZE_GOAT] = ["You may trash a card.", "You may trash a card."];
SpanishQuestionDescriptions[QuestionIds.PUZZLE_BOX] = ["Set aside a card from your hand, and put it into your hand at end of turn (after drawing).", "Set a card aside"];
SpanishQuestionDescriptions[QuestionIds.SEXTANT_DISCARD] = ["Discard any number of cards.", "Discard any number of cards."];
SpanishQuestionDescriptions[QuestionIds.SEXTANT_TOPDECK] = ["Put the remaining cards back in any order.", "Put the remaining cards back in any order."];
SpanishQuestionDescriptions[QuestionIds.SPELL_SCROLL_GAIN] = ["Gain a card costing less than AMOUNT0.", "Gain a card costing less than AMOUNT0."];
SpanishQuestionDescriptions[QuestionIds.SPELL_SCROLL_PLAY] = ["Play Argument0?", "Play Argument0?"];
SpanishQuestionDescriptions[QuestionIds.STAFF] = ["You may play an Action card.", "You may play an Action card."];
SpanishQuestionDescriptions[QuestionIds.SWORD] = ["Discard down to 4 cards.", "Discard down to 4 cards."];
SpanishQuestionDescriptions[QuestionIds.FRIENDLY] = ["Discard a [Friendly] card to gain another.", "Discard a [Friendly] card to gain another."];
SpanishQuestionDescriptions[QuestionIds.INHERITED] = ["Replace a starting card with an [Inherited] card.", "Replace a starting card with an [Inherited] card."];
SpanishQuestionDescriptions[QuestionIds.INSPIRING] = ["You may play an action you don't have in play.", "You may play an action you don't have in play ([Inspiring])."];
SpanishQuestionDescriptions[QuestionIds.PATIENT_SET_ASIDE] = ["Set aside [Patient] cards to play next turn.", "Set aside [Patient] cards to play next turn."];
SpanishQuestionDescriptions[QuestionIds.PATIENT_PLAY] = ["Which [Patient] card to play next?", "Which [Patient] card to play next?"];
SpanishQuestionDescriptions[QuestionIds.PIOUS_TRASH] = ["You may trash a card from your hand.", "You may trash a card from your hand."];
SpanishQuestionDescriptions[QuestionIds.SHY] = ["Discard a [Shy] card for +2 Cards.", "Discard a [Shy] card for +2 Cards."];
SpanishQuestionDescriptions[QuestionIds.FATED_TOPDECK] = ["You may topdeck Fated Cards.", "You may topdeck Fated Cards."];
SpanishQuestionDescriptions[QuestionIds.FATED_BOTTOMDECK] = ["You may bottomdeck Fated Cards.", "You may bottomdeck Fated Cards."];
SpanishQuestionDescriptions[QuestionIds.AVOID] = ["Put up to 3 cards in your Discard pile.", "Put up to 3 cards in your Discard pile."];
SpanishQuestionDescriptions[QuestionIds.TRICKSTER_DISCARD] = ["What Treasure to set aside?.", "What Treasure to set aside?"];
SpanishQuestionDescriptions[QuestionIds.MARCHLAND] = ["", "Discard for +$1 each"];

SpanishQuestionDescriptions[QuestionIds.FARMHANDS] = ["What Card to play at the start of your next turn?.", "What Card to play at the start of your next turn?."];
SpanishQuestionDescriptions[QuestionIds.COURSER] = ["Choose how to play Courser.", "Choose how to play Courser"];
SpanishQuestionDescriptions[QuestionIds.CORONET_ACTION] = ["What Action to play twice?", "What Action to play twice?"];
SpanishQuestionDescriptions[QuestionIds.CORONET_TREASURE] = ["What Treasure to play twice?", "What Treasure to play twice?"];
SpanishQuestionDescriptions[QuestionIds.JOUST_SET_ASIDE] = ["Set aside a Province?", "Set aside a Province?"];
SpanishQuestionDescriptions[QuestionIds.JOUST_GAIN_REWARD] = ["What Reward to gain to your hand?", "What Reward to gain to your hand?"];
SpanishQuestionDescriptions[QuestionIds.INFIRMARY_TRASH] = ["You may trash a card.", "You may trash a card."];
SpanishQuestionDescriptions[QuestionIds.INFIRMARY_OVERPAY] = ["You may overpay for Infirmary.", "You may overpay"];
SpanishQuestionDescriptions[QuestionIds.FOOTPAD] = SpanishQuestionDescriptions[QuestionIds.MILITIA];
SpanishQuestionDescriptions[QuestionIds.FARRIER_OVERPAY] = ["You may overpay for Farrier.", "You may overpay"];
SpanishQuestionDescriptions[QuestionIds.SHOP] = SpanishQuestionDescriptions[QuestionIds.CONCLAVE];
SpanishQuestionDescriptions[QuestionIds.FERRYMAN] = SpanishQuestionDescriptions[QuestionIds.FUGITIVE];

SpanishQuestionDescriptions[QuestionIds.ORDER_BOTTOMDECKED_CARDS] = ["Choose the order on the bottom", "Choose the order on the bottom"];
SpanishQuestionDescriptions[QuestionIds.GROWTH] = SpanishQuestionDescriptions[QuestionIds.BORDER_VILLAGE];
SpanishQuestionDescriptions[QuestionIds.KIND_EMPEROR] = ["Gain an Action to your Hand", "Gain an Action to your Hand"];
SpanishQuestionDescriptions[QuestionIds.SICKNESS_MODE] = ["", ""];
SpanishQuestionDescriptions[QuestionIds.SICKNESS_DISCARD] = ["", ""];
SpanishQuestionDescriptions[QuestionIds.SICKNESS] = ["Discard 3 or gain a Curse onto your deck", "Discard 3 or gain a Curse onto your deck"];
SpanishQuestionDescriptions[QuestionIds.SNAKE_WITCH] = ["Return [Snake Witch] to Curse?", "Return [Snake Witch] to have each other player gain a Curse?"];
SpanishQuestionDescriptions[QuestionIds.CRAFTSMAN] = ["Gain a card costing up to 5.", "Gain a card costing up to 5."];
SpanishQuestionDescriptions[QuestionIds.ALLEY] = SpanishQuestionDescriptions[QuestionIds.OASIS];
SpanishQuestionDescriptions[QuestionIds.CHANGE_TRASH] = ["Trash a card from your Hand", "Trash a card from your Hand"];
SpanishQuestionDescriptions[QuestionIds.CHANGE_GAIN] = ["Gain a card and get debt", "Gain a card and get debt"];
SpanishQuestionDescriptions[QuestionIds.NINJA] = SpanishQuestionDescriptions[QuestionIds.MILITIA];
SpanishQuestionDescriptions[QuestionIds.RIVER_SHRINE_TRASH] = ["Trash up to 2 cards", "Trash up to 2 cards"];
SpanishQuestionDescriptions[QuestionIds.RIVER_SHRINE_GAIN] = ["Gain a card costing up to 4.", "Gain a card costing up to 4."];
SpanishQuestionDescriptions[QuestionIds.RUSTIC_VILLAGE_CHOICE] = ["Discard 2 cards to draw 1?", "Discard 2 cards to draw 1?"];
SpanishQuestionDescriptions[QuestionIds.RUSTIC_VILLAGE_DISCARD] = ["Which 2 cards to discard?", "Which 2 cards to discard?"];
SpanishQuestionDescriptions[QuestionIds.GOLD_MINE] = ["Gain a Gold and get 4 debt?", "Gain a Gold and get 4 debt?"];
SpanishQuestionDescriptions[QuestionIds.KITSUNE] = ["Choose how to play Kitsune", "Choose how to play Kitsune"];
SpanishQuestionDescriptions[QuestionIds.TANUKI_TRASH] = SpanishQuestionDescriptions[QuestionIds.REMODEL_TRASH];
SpanishQuestionDescriptions[QuestionIds.TANUKI_GAIN] = SpanishQuestionDescriptions[QuestionIds.REMODEL_GAIN];
SpanishQuestionDescriptions[QuestionIds.SAMURAI] = SpanishQuestionDescriptions[QuestionIds.MILITIA];
SpanishQuestionDescriptions[QuestionIds.MOUNTAIN_SHRINE] = ["You may trash a card from your hand.", "You may trash a card from your hand."];
SpanishQuestionDescriptions[QuestionIds.AMASS] = ["Gain an Action card costing up to 5.", "Gain an Action card costing up to 5."];
SpanishQuestionDescriptions[QuestionIds.ASCETICISM] = ["Trash up to Argument0 Cards.", "Trash up to Argument0 Cards."];
SpanishQuestionDescriptions[QuestionIds.CONTINUE] = ["Gain and play a non-attack Action card", "Gain and play a non-attack Action card"];
SpanishQuestionDescriptions[QuestionIds.CREDIT] = ["Gain an Action or Treasure up to 8", "Gain an Action or Treasure up to 8"];
SpanishQuestionDescriptions[QuestionIds.KINTSUGI_TRASH] = ["Trash a card from your hand.", "Trash a card from your hand."];
SpanishQuestionDescriptions[QuestionIds.KINTSUGI_GAIN] = SpanishQuestionDescriptions[QuestionIds.REMODEL_GAIN];
SpanishQuestionDescriptions[QuestionIds.PRACTICE] = SpanishQuestionDescriptions[QuestionIds.THRONE_ROOM];
SpanishQuestionDescriptions[QuestionIds.SEA_TRADE] = ["Trash up to Argument0 Cards.", "Trash up to Argument0 Cards."];
SpanishQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_FIRST] = ["You may gain 3 Action cards you don't have in play.", "You may gain 3 Action cards you don't have in play."];
SpanishQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_SECOND] = ["You may gain 2 Action cards you don't have in play.", "You may gain 2 Action cards you don't have in play."];
SpanishQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_THIRD] = ["You may gain an Action card you don't have in play.", "You may gain an Action card you don't have in play."];
SpanishQuestionDescriptions[QuestionIds.GATHER] = ["Gain a card costing exactly Argument0.", "Gain a card costing exactly Argument0."];

SpanishQuestionDescriptions[QuestionIds.GAME_PAY_OFF_DEBT] = ["You may pay off Debt.", "You may pay off Debt"];
SpanishQuestionDescriptions[QuestionIds.MAY_PAY_OFF_EXTRA_DEBT] = ["You may pay off Debt.", "You may pay off Debt"];
SpanishQuestionDescriptions[QuestionIds.PAY_OFF_DEBT_BEFORE_CHANGE] = ["Pay off Debt to remodel?", "Pay off Debt to remodel?"];
SpanishQuestionDescriptions[QuestionIds.PAY_OFF_DEBT_BEFORE_STORYTELLER] = ["Pay off Debt to draw fewer Cards?", "Pay off Debt to draw fewer Cards?"];