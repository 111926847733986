"use strict";

webclient.component("blacklistTable", {
    controller: ['relations', function (relations) {
        var self = this;

        self.getRelations = function () {
            return relations.getBlacklist();
        };
        self.hasBlacklist = function () {
            return relations.hasBlacklist();
        };

        self.getRelationImage = relations.getImageName;

        self.getButtonText = function () {
            return getPhrase(Phrases.REMOVE);
        };
        self.getNoBlacklistText = function () {
            return getPhrase(Phrases.BLACKLIST_EMPTY);
        };

        self.remove = function (relation) {
            relations.removeFromBlacklist(relation.name);
        };
    }],
    template: "\n            <div class=\"friends-table-content-container\">\n                <div ng-repeat=\"relation in $ctrl.getRelations()\" class=\"friends-table-row\">\n                    <div class=\"friends-user-column\">{{relation.name}}</div>\n                    <div class=\"user-pref-separator\"></div>\n                    <div class=\"friends-status-column\">\n                        <img class=\"friends-image\" src=\"images/elements/lightning-small.png\">\n                    </div>\n                    <div class=\"friends-action-column\">\n                        <button class=\"friends-button oval-button\" ng-click=\"$ctrl.remove(relation)\">{{$ctrl.getButtonText(relation)}}</button>\n                    </div>\n                </div>\n                <div ng-if=\"!$ctrl.hasBlacklist()\" class=\"empty-relation-list\">{{$ctrl.getNoBlacklistText()}}</div>\n            </div> \n            "
});