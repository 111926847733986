"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AnimationClassId = function () {
    function AnimationClassId() {
        _classCallCheck(this, AnimationClassId);

        this.uuid = generateUUID();
    }

    _createClass(AnimationClassId, [{
        key: "toString",
        value: function toString() {
            return this.uuid;
        }
    }]);

    return AnimationClassId;
}();

var AnimationClassIds = {
    CARDS_CHANGING_OWNERSHIP_OUTSIDE_YOUR_TURN: new AnimationClassId(),
    CARDS_CHANGING_OWNERSHIP_THAT_ARENT_YOURS: new AnimationClassId(),
    CARDS_CHANGING_OWNERSHIP: new AnimationClassId(),
    IMPORTANT_REVEALS: new AnimationClassId(),
    CONSCIOUS_SIMPLE_GAIN: new AnimationClassId(),
    REGULAR: new AnimationClassId(),
    TRANSITION: new AnimationClassId()
};

var ClientAnimationType = function () {
    function ClientAnimationType() {
        _classCallCheck(this, ClientAnimationType);

        this.uuid = generateUUID();
    }

    _createClass(ClientAnimationType, [{
        key: "toString",
        value: function toString() {
            return this.uuid;
        }
    }]);

    return ClientAnimationType;
}();

var ClientAnimationTypes = {
    NORMAL: new ClientAnimationType(),
    HIGHLIGHTED: new ClientAnimationType(),
    MINIMAL: new ClientAnimationType(),
    INSTANT: new ClientAnimationType()
};

var ClientAnimationProperties = function () {
    function ClientAnimationProperties(type, speed, delay) {
        var shouldZap = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

        _classCallCheck(this, ClientAnimationProperties);

        this.type = type;
        this.speed = speed;
        this.delay = delay;
        this.shouldZap = shouldZap;
    }

    _createClass(ClientAnimationProperties, [{
        key: "isInstant",
        get: function get() {
            return this.type === ClientAnimationTypes.INSTANT;
        }
    }]);

    return ClientAnimationProperties;
}();

var ClientAnimationSettings = function () {
    function ClientAnimationSettings(name, showConcurrentAnimations, array) {
        var _this = this;

        var isInstant = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

        _classCallCheck(this, ClientAnimationSettings);

        this.name = name;
        this.showConcurrentAnimations = showConcurrentAnimations;
        this.map = new Map();
        this.isInstant = isInstant;
        ObjectValues(AnimationClassIds).forEach(function (c, index) {
            return _this.map.set(c, array[index]);
        });
    }

    _createClass(ClientAnimationSettings, [{
        key: "toString",
        value: function toString() {
            return this.name;
        }
    }]);

    return ClientAnimationSettings;
}();

var AnimationOptions = {
    NEW_PLAYER: new ClientAnimationSettings("Slow", false, [new ClientAnimationProperties(ClientAnimationTypes.HIGHLIGHTED, 350, 800), new ClientAnimationProperties(ClientAnimationTypes.NORMAL, 350, 800), new ClientAnimationProperties(ClientAnimationTypes.HIGHLIGHTED, 300, 400), new ClientAnimationProperties(ClientAnimationTypes.HIGHLIGHTED, 350, 0), new ClientAnimationProperties(ClientAnimationTypes.NORMAL, 300, 400), new ClientAnimationProperties(ClientAnimationTypes.NORMAL, 300, 200), new ClientAnimationProperties(ClientAnimationTypes.NORMAL, 200, 0)]),
    BEGINNER: new ClientAnimationSettings("Standard", false, [new ClientAnimationProperties(ClientAnimationTypes.HIGHLIGHTED, 350, 750), new ClientAnimationProperties(ClientAnimationTypes.NORMAL, 350, 750), new ClientAnimationProperties(ClientAnimationTypes.NORMAL, 300, 400), new ClientAnimationProperties(ClientAnimationTypes.HIGHLIGHTED, 350, 0), new ClientAnimationProperties(ClientAnimationTypes.NORMAL, 300, 200), new ClientAnimationProperties(ClientAnimationTypes.NORMAL, 300, 200), new ClientAnimationProperties(ClientAnimationTypes.NORMAL, 200, 0)]),
    MEDIUM: new ClientAnimationSettings("Sprightly", true, [new ClientAnimationProperties(ClientAnimationTypes.HIGHLIGHTED, 350, 500), new ClientAnimationProperties(ClientAnimationTypes.NORMAL, 350, 500), new ClientAnimationProperties(ClientAnimationTypes.NORMAL, 300, 0), new ClientAnimationProperties(ClientAnimationTypes.NORMAL, 350, 0), new ClientAnimationProperties(ClientAnimationTypes.NORMAL, 300, 0), new ClientAnimationProperties(ClientAnimationTypes.NORMAL, 300, 150), new ClientAnimationProperties(ClientAnimationTypes.NORMAL, 200, 0)]),
    ADVANCED: new ClientAnimationSettings("Swift", true, [new ClientAnimationProperties(ClientAnimationTypes.HIGHLIGHTED, 300, 450, true), new ClientAnimationProperties(ClientAnimationTypes.NORMAL, 300, 450, true), new ClientAnimationProperties(ClientAnimationTypes.NORMAL, 200, 0, true), new ClientAnimationProperties(ClientAnimationTypes.NORMAL, 300, 0, true), new ClientAnimationProperties(ClientAnimationTypes.NORMAL, 200, 0, true), new ClientAnimationProperties(ClientAnimationTypes.MINIMAL, 250, 150, true), new ClientAnimationProperties(ClientAnimationTypes.MINIMAL, 200, 0)]),
    VERYFAST: new ClientAnimationSettings("Speedy", true, [new ClientAnimationProperties(ClientAnimationTypes.HIGHLIGHTED, 300, 300, true), new ClientAnimationProperties(ClientAnimationTypes.MINIMAL, 300, 300, true), new ClientAnimationProperties(ClientAnimationTypes.MINIMAL, 200, 0, true), new ClientAnimationProperties(ClientAnimationTypes.MINIMAL, 300, 0, true), new ClientAnimationProperties(ClientAnimationTypes.MINIMAL, 200, 0, true), new ClientAnimationProperties(ClientAnimationTypes.MINIMAL, 200, 100, true), new ClientAnimationProperties(ClientAnimationTypes.MINIMAL, 200, 0)]),
    INSTANT: new ClientAnimationSettings("Instant", true, [new ClientAnimationProperties(ClientAnimationTypes.INSTANT, 0, 0), new ClientAnimationProperties(ClientAnimationTypes.INSTANT, 0, 0), new ClientAnimationProperties(ClientAnimationTypes.INSTANT, 0, 0), new ClientAnimationProperties(ClientAnimationTypes.INSTANT, 0, 0), new ClientAnimationProperties(ClientAnimationTypes.INSTANT, 0, 0), new ClientAnimationProperties(ClientAnimationTypes.INSTANT, 0, 0), new ClientAnimationProperties(ClientAnimationTypes.INSTANT, 0, 0)], true)
};