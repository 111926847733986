"use strict";

webclient.component("gameArea", {
    controller: ["$element", "$scope", "game", "autoplayService", function ($element, $scope, game, autoplayService) {
        var _this = this;

        game.gameArea = this;
        this.shouldShowKingdomViewer = false;

        $element[0].ontouchstart = function () {
            if (!isUndefined(document.getElementById("game-chat-input"))) document.getElementById("game-chat-input").blur();
        };

        $element[0].oncontextmenu = function () {
            event.preventDefault();
        };

        var initialized = false;
        this.initialize = function () {
            if (initialized) return;
            initialized = true;
            _this.cardStacksArea = $element[0].getElementsByClassName("card-stacks")[0];
            game.state.zones.filter(function (z) {
                return z.isVisible;
            }).forEach(function (z) {
                return z.cardStacks.forEach(function (s) {
                    return s.render(_this.cardStacksArea);
                });
            });
            game.state.nowhere.cardStacks.forEach(function (s) {
                return s.render(_this.cardStacksArea);
            });
            game.state.orphanage.cardStacks.forEach(function (s) {
                return s.render(_this.cardStacksArea);
            });

            _this.playerInfoArea = $element[0].getElementsByClassName("player-info")[0];
            game.state.players.forEach(function (p) {
                return p.infoView.render(_this.playerInfoArea);
            });

            _this.statusBarArea = $element[0].getElementsByClassName("status-bar")[0];
            game.logModel.statusBarView.render(_this.statusBarArea);

            _this.gameButtonsArea = $element[0].getElementsByClassName("game-buttons")[0];
            game.questionModel.gameButtons.forEach(function (b) {
                return b.render(_this.gameButtonsArea);
            });
            game.questionModel.positionButtons();

            _this.gameLogArea = $element[0].getElementsByClassName("log-container")[0];
            game.logModel.logView.render(_this.gameLogArea);

            _this.gameTabArea = $element[0].getElementsByClassName("game-tabs")[0];
            game.layoutModel.tabViews.forEach(function (t) {
                return t.render(_this.gameTabArea);
            });
            game.state.zones.filter(function (z) {
                return z.isVisible && z instanceof WindowedZone;
            }).forEach(function (z) {
                return z.windowView.render(_this.gameTabArea);
            });
            game.state.orphanage.windowView.render(_this.gameTabArea);
            game.questionModel.cleanupWindow.windowView.render(_this.gameTabArea);

            _this.studyArea = $element[0].getElementsByClassName("study-area")[0];
            game.studyModel.renderEverything();
            _this.studyCard = game.studyModel.studyStack.topCard;
        };
        this.initialize();

        this.setStudyCard = function (card) {
            _this.studyCard = card;
            autoplayService.init("game", card.cardName);
            _this.shouldShowAutoplayOptions = !game.studyModel.studyStack.hasFilter("intangible") && AutoPlay.autoplayable(_this.studyCard.cardName) && game.optionsModel.autoplaySettings.map[AutoPlay.find(_this.studyCard.cardName)];;
            safeDigest($scope);
        };

        var removeAllChildNodes = function removeAllChildNodes(node) {
            while (node.firstChild) {
                node.removeChild(node.firstChild);
            }
        };

        this.reset = function () {
            removeAllChildNodes(_this.cardStacksArea);
            removeAllChildNodes(_this.playerInfoArea);
            removeAllChildNodes(_this.statusBarArea);
            removeAllChildNodes(_this.gameButtonsArea);
            removeAllChildNodes(_this.gameLogArea);
            removeAllChildNodes(_this.gameTabArea);
            initialized = false;
        };

        this.updateNameInput = function () {
            _this.nameQuestion = game.questionModel.openQuestions.find(function (q) {
                return q instanceof NameQuestion;
            });
            _this.hasNameQuestion = !isUndefined(_this.nameQuestion);
            safeDigest($scope);
        };
        this.updateNameInput();
    }],
    template: "\n        <div class=\"game-buttons\" ng-show=\"!$ctrl.shouldShowKingdomViewer\"></div>\n        <div class=\"status-bar\" ng-show=\"!$ctrl.shouldShowKingdomViewer\"></div>\n        <div class=\"player-info\" ng-show=\"!$ctrl.shouldShowKingdomViewer\"></div>\n        <div class=\"card-stacks\" ng-show=\"!$ctrl.shouldShowKingdomViewer\"></div>\n        <div class=\"game-tabs\" ng-show=\"!$ctrl.shouldShowKingdomViewer\"></div>\n        <div class=\"study-area\">\n            <div class=\"card-study-autoplay-options\" ng-if=\"$ctrl.shouldShowAutoplayOptions\">\n                <game-pref-autoplay card=\"$ctrl.studyCard\"></game-pref-autoplay>\n            </div>\n        </div>\n        <div class=\"log-container\"></div>\n        <name-input ng-if=\"$ctrl.hasNameQuestion\" question=\"$ctrl.nameQuestion\"></name-input>\n        <game-chat></game-chat>\n        <spec-panel></spec-panel>\n        <kingdom-viewer ng-show=\"$ctrl.shouldShowKingdomViewer\"></kingdom-viewer>\n    "
});