"use strict";

webclient.component("lobbyMenu", {
    bindings: {
        lobby: '<'
    },
    controller: [function () {
        var self = this;
        self.shouldShow = function () {
            return self.lobby.shouldShowMenu;
        };
        self.getInboxText = function () {
            return getPhrase(Phrases.INBOX);
        };
        self.getNeedHelpText = function () {
            return getPhrase(Phrases.NEED_HELP);
        };
        self.getLogoutText = function () {
            return getPhrase(Phrases.LOGOUT);
        };
        self.getFaqText = function () {
            return getPhrase(Phrases.FAQ);
        };
        self.getNewToDominionText = function () {
            return getPhrase(Phrases.NEW_TO_DOMINION);
        };
    }],
    template: "\n            <div class=\"bottom-lobby-links\" ng-if=\"$ctrl.shouldShow()\">\n                <div class=\"bottom-lobby-link\" ng-click=\"$ctrl.lobby.loadInbox()\">{{$ctrl.getInboxText()}}</div>\n                <div class=\"bottom-lobby-link\" ng-click=\"$ctrl.lobby.loadFaq()\">{{$ctrl.getFaqText()}}</div>\n                <div class=\"bottom-lobby-link\" ng-click=\"$ctrl.lobby.loadNewToDominion()\">{{$ctrl.getNewToDominionText()}}</div>\n                <div class=\"bottom-lobby-link\" ng-click=\"$ctrl.lobby.loadForum()\">{{$ctrl.getNeedHelpText()}}</div>\n                <div class=\"bottom-lobby-link\" ng-click=\"$ctrl.lobby.logout()\">{{$ctrl.getLogoutText()}}</div>\n            </div>\n            "
});