"use strict";

webclient.component("reconnectingFailed", {
    transclude: true,
    controller: ['$scope', 'reconnect', 'serverMessenger', 'gameBroadcaster', 'tableService', function ($scope, reconnect, serverMessenger, gameBroadcaster, tableService) {
        var self = this;

        var _shouldShow = false;
        var reconnectCount = 0;
        $scope.$on(Events.LOGIN_SUCCESS, function (event, loginSuccess) {
            if (!loginSuccess.isReconnecting && loginSuccess.previousReconnects > 0) {
                _shouldShow = true;
                reconnectCount = loginSuccess.previousReconnects;
            }
        });

        self.shouldShow = function () {
            return _shouldShow;
        };
        self.reconnect = function () {
            _shouldShow = false;
            serverMessenger.reconnectGame();
            gameBroadcaster.send(Events.FORCE_RECONNECT);
        };
        self.decline = function () {
            _shouldShow = false;
            serverMessenger.removeActiveSessions();
            tableService.clearTable();
            console.log("returning to lobby because I declined a reconnect");
            gameBroadcaster.send(Events.RETURN_TO_LOBBY);
        };

        self.getWindowText = function () {
            return LANGUAGE.getPhrase[Phrases.RECONNECTING_FAILED_MESSAGE].replace("COUNT", reconnectCount);
        };
        self.getRetryText = function () {
            return LANGUAGE.getPhrase[Phrases.TRY_AGAIN];
        };
        self.getDeclineText = function () {
            return LANGUAGE.getPhrase[Phrases.RETURN_TO_LOBBY];
        };
    }],
    template: "\n            <modal-window ng-if=\"$ctrl.shouldShow()\">\n                <div class=\"timeout\">{{::$ctrl.getWindowText()}}</div>\n                <div class=\"button-line\">\n                    <button class=\"lobby-button\" ng-click=\"$ctrl.reconnect()\">{{::$ctrl.getRetryText()}}</button>\n                    <button class=\"lobby-button\" ng-click=\"$ctrl.decline()\">{{::$ctrl.getDeclineText()}}</button>\n                </div>\n            </modal-window>\n        "
});