"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ZoneCreated = function (_GameStateChange) {
    _inherits(ZoneCreated, _GameStateChange);

    function ZoneCreated(zoneIndex, ownerIndex, zoneName, createdBy) {
        _classCallCheck(this, ZoneCreated);

        var _this = _possibleConstructorReturn(this, (ZoneCreated.__proto__ || Object.getPrototypeOf(ZoneCreated)).call(this));

        _this.zoneIndex = zoneIndex;
        _this.ownerIndex = ownerIndex;
        _this.zoneName = zoneName;
        _this.createdBy = createdBy;
        return _this;
    }

    _createClass(ZoneCreated, [{
        key: "execute",
        value: function execute(game, terminator) {
            var owner = this.ownerIndex === -1 ? game.state.nobody : game.state.players[this.ownerIndex];
            var createdBy = this.createdBy === -1 ? null : game.state.cards[this.createdBy];
            var newZone = new this.zoneName(game.state, this.zoneIndex, null, owner, createdBy, CardNames.BACK);
            game.state.zones[this.zoneIndex] = newZone;
            terminator();
        }
    }, {
        key: "createAnimation",
        value: function createAnimation(game) {
            return new PileConstructingAnimation(this, this.zoneIndex);
        }
    }], [{
        key: "parse",
        value: function parse(game, reader) {
            var index = reader.readInt();
            var ownerIndex = reader.readInt();
            var zoneName = getByOrdinal(ZoneNames, reader.readInt());
            var createdBy = reader.readInt();
            return new ZoneCreated(index, ownerIndex, zoneName, createdBy);
        }
    }]);

    return ZoneCreated;
}(GameStateChange);