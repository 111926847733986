"use strict";

webclient.service('serverConnection', ['metaBroadcaster', 'serverToClientProcessor', "$rootScope", '$injector', function (metaBroadcaster, serverToClientProcessor, $rootScope, $injector) {
    var self = this;
    self.nextConnectionId = 0;
    self.hostname = "";
    self.sendingPingId = null;
    self.expectingPongId = null;
    self.expectedMessageCounter = 0;
    self.isPinging = false;

    self.connect = function (description, connection, isPinging, onOpen, onClose, onError, messageProcessor) {
        self.description = description;
        self.connection = connection;
        self.isPinging = isPinging;
        self.onOpen = onOpen;
        self.onClose = onClose;
        self.onError = onError;
        self.messageProcessor = messageProcessor;
        var protocol = connection.ssl ? 'wss://' : 'ws://';
        self.webSocket = new WebSocket(protocol + connection.host + ':' + connection.port);
        self.webSocket.binaryType = 'arraybuffer';

        var connectionId = self.nextConnectionId++;

        self.webSocket.onopen = function (event) {
            self.expectedMessageCounter = 0;
            self.onOpen();
        };

        self.webSocket.onmessage = function (event) {
            // if (connectionId !== self.connectionId) return;
            var reader = new Reader(event.data);
            while (reader.hasMessages()) {
                var messageCounter = reader.readInt();
                if (messageCounter !== self.expectedMessageCounter) {
                    metaBroadcaster.send(Events.MESSAGE_INDEX_MISMATCH);
                }
                self.expectedMessageCounter++;
                self.messageProcessor.process(reader);
                if (isPinging) self.prepareToPing();
            }
        };

        self.webSocket.onerror = function (event) {
            self.clearPingPong();
            self.onError(event);
        };

        self.webSocket.onclose = function (event) {
            self.clearPingPong();
            self.onClose();
        };
    };

    self.isConnecting = function () {
        if (isUndefined(self.webSocket)) return false;
        return self.webSocket.readyState === 0;
    };

    self.isAlive = function () {
        if (isUndefined(self.webSocket)) return false;
        return self.webSocket.readyState < 3;
    };

    self.disconnect = function () {
        var w = self.webSocket;
        if (isUndefined(w)) throw new Error("Trying to close undefined connection.");
        w.close();
    };

    self.reconnect = function () {
        var connectToOtherServer = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        var otherConnection = self.connection === USED_ALPHA_CONNECTION ? USED_BETA_CONNECTION : USED_ALPHA_CONNECTION;
        var shouldUseOther = connectToOtherServer && isDefined(USED_ALPHA_CONNECTION) && isDefined(USED_BETA_CONNECTION);
        var nextConnection = shouldUseOther ? otherConnection : self.connection;
        var nextOnOpen = function nextOnOpen() {
            console.log("Successfully connected to " + nextConnection.host + ":" + nextConnection.port);
            metaBroadcaster.send(Events.SERVER_RECONNECTED);
        };
        var nextOnClose = self.onClose;
        var nextOnError = self.onError;
        self.onClose = function () {
            console.log("old connection successfully closed");
            self.connect(self.description, nextConnection, self.isPinging, nextOnOpen, nextOnClose, nextOnError, self.messageProcessor);
        };
        self.onError = function () {
            return console.log("There was a problem on the old connection");
        };
        self.disconnect();
    };

    self.send = function (buffer) {
        if (self.isConnecting()) {
            timeLog('Attempting to send a message to the server before a connection is established.');
            return;
        }
        self.webSocket.send(buffer);
    };

    self.clearCurrentPing = function () {
        if (self.sendingPingId) {
            clearTimeout(self.sendingPingId);
            self.sendingPingId = null;
        }
    };

    self.clearExpectingPong = function () {
        if (self.expectingPongId) {
            clearTimeout(self.expectingPongId);
            self.expectingPongId = null;
        }
    };

    self.clearPingPong = function () {
        self.clearCurrentPing();
        self.clearExpectingPong();
    };

    self.prepareToPing = function () {
        self.clearPingPong();
        var noPongReceived = function noPongReceived() {
            return metaBroadcaster.send(Events.NO_PONG_RECEIVED);
        };
        var sendPing = function sendPing(time) {
            var serverMessenger = $injector.get('serverMessenger');
            serverMessenger.ping();
            self.expectingPongId = setTimeout(noPongReceived, 5000);
        };
        self.sendingPingId = setTimeout(sendPing, 10000);
    };

    $rootScope.$on(Events.PONG_RECEIVED, function (e) {
        self.clearExpectingPong();
    });
}]);