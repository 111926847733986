"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DelayedQuestion = function (_Question) {
    _inherits(DelayedQuestion, _Question);

    function DelayedQuestion(game, index, description, declineButtonId) {
        _classCallCheck(this, DelayedQuestion);

        var _this = _possibleConstructorReturn(this, (DelayedQuestion.__proto__ || Object.getPrototypeOf(DelayedQuestion)).call(this, game, index, description));

        _this.questionClass = QuestionClasses.DELAYED_QUESTION;
        _this.declineButtonId = declineButtonId;
        return _this;
    }

    _createClass(DelayedQuestion, [{
        key: "createButtons",
        value: function createButtons() {
            var addedButtons = [];
            if (this.declineButtonId > -1) {
                var declineButtonInfo = getByOrdinal(DeclineButtonIds, this.declineButtonId);
                if (declineButtonInfo.isEndButton) this.declineButton = new EndButtonView(this, LANGUAGE.getDeclineButtonText[declineButtonInfo], getButtonArtURL(declineButtonInfo), "");else this.declineButton = new DeclineGameButtonView(this, LANGUAGE.getDeclineButtonText[declineButtonInfo], getButtonArtURL(declineButtonInfo), "");
                addedButtons.push(this.declineButton);
            }

            var id = this.association;
            var cardName = this.game.state.cards[id].cardName;
            this.cardName = cardName;
            addedButtons.push(new IconGameButtonView(this, 1, LANGUAGE.getDelayedQuestionButton[cardName], getDelayedQuestionArtURL(cardName), ""));
            this.game.questionModel.newButtons(addedButtons);
        }
    }, {
        key: "execute",
        value: function execute(game) {
            var isRoot = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

            this.createButtons();
            _get(DelayedQuestion.prototype.__proto__ || Object.getPrototypeOf(DelayedQuestion.prototype), "execute", this).call(this, game, isRoot);
        }
    }, {
        key: "answer",
        value: function answer(_answer) {
            this.accumulatedAnswers = [_answer];
            this.send();
        }
    }, {
        key: "answerDecline",
        value: function answerDecline() {
            this.accumulatedAnswers = [0];
            this.send();
        }
    }, {
        key: "answerDone",
        value: function answerDone() {
            this.accumulatedAnswers = [0];
            this.send();
        }
    }], [{
        key: "parse",
        value: function parse(game, reader, questionIndex) {
            var questionDescription = QuestionDescription.parse(reader);
            var doneButtonId = reader.readInt();
            return new DelayedQuestion(game, questionIndex, questionDescription, doneButtonId);
        }
    }]);

    return DelayedQuestion;
}(Question);