"use strict";

var FrenchDeclineButtons = {};

FrenchDeclineButtons[DeclineButtonIds.END_ACTION_PHASE] = "Fin Actions";
FrenchDeclineButtons[DeclineButtonIds.END_BUY_PHASE] = "Fin Achats";
FrenchDeclineButtons[DeclineButtonIds.END_TURN] = "Fin du tour";
FrenchDeclineButtons[DeclineButtonIds.DONE_PLAYING_TREASURES] = "Fin Trésors";
FrenchDeclineButtons[DeclineButtonIds.DONE_REACTING] = "Fini de réagir";
FrenchDeclineButtons[DeclineButtonIds.MINING_VILLAGE_DONT_TRASH] = "Ne pas écarter";
FrenchDeclineButtons[DeclineButtonIds.PEARL_DIVER_DONT_TOPDECK] = "Ne pas mettre sur la pioche";
FrenchDeclineButtons[DeclineButtonIds.HARBINGER_DONT_TOPDECK] = "Ne pas mettre sur la pioche";
FrenchDeclineButtons[DeclineButtonIds.THRONE_ROOM_DONT] = "Ne pas rejouer une action";
FrenchDeclineButtons[DeclineButtonIds.SMUGGLERS_GAIN_IMPOSSIBLE_COPY] = "Ne pas recevoir";
FrenchDeclineButtons[DeclineButtonIds.EXPLORER_GAIN_SILVER] = "Recevoir un argent";
FrenchDeclineButtons[DeclineButtonIds.HERBALIST_DONT_TOPDECK] = "Ne pas mettre sur la pioche";
FrenchDeclineButtons[DeclineButtonIds.MINT_DONT_COPY] = "Ne pas copier";
FrenchDeclineButtons[DeclineButtonIds.HAMLET_DONT_DISCARD] = "Ne pas défausser";
FrenchDeclineButtons[DeclineButtonIds.TOURNAMENT_DONT_REVEAL] = "Ne pas dévoiler";
FrenchDeclineButtons[DeclineButtonIds.YOUNG_WITCH_DONT_REVEAL_BANE] = "Ne pas dévoiler";
FrenchDeclineButtons[DeclineButtonIds.IGG_NO_COPPER] = "Pas de cuivre";
FrenchDeclineButtons[DeclineButtonIds.COUNTERFEIT_NO_TREASURE] = "Ne pas contrefaire";
FrenchDeclineButtons[DeclineButtonIds.CULTIST_DONT_PLAY_ANOTHER] = "Ne pas enchaîner les Cultistes";
FrenchDeclineButtons[DeclineButtonIds.IRONMONGER_KEEP] = "Laisser sur la pioche";
FrenchDeclineButtons[DeclineButtonIds.PROCESSION_DONT] = "Ne pas jouer une action deux fois";
FrenchDeclineButtons[DeclineButtonIds.PLAZA_NO_DISCARD] = "Ne pas défausser";
FrenchDeclineButtons[DeclineButtonIds.DONT_OVERPAY] = "Ne pas surpayer";
FrenchDeclineButtons[DeclineButtonIds.DONE_SETTING_ASIDE] = "Fini de mettre de côté";
FrenchDeclineButtons[DeclineButtonIds.ARTIFICER_DONT] = "Ne pas défausser";
FrenchDeclineButtons[DeclineButtonIds.ENGINEER_DONT_TRASH] = "Ne pas écarter";
FrenchDeclineButtons[DeclineButtonIds.ENCAMPMENT_DONT_REVEAL] = "Ne pas dévoiler";
FrenchDeclineButtons[DeclineButtonIds.SETTLERS_DONT_TAKE_COPPER] = "Ne pas prendre de Cuivre";
FrenchDeclineButtons[DeclineButtonIds.GLADIATOR_DONT_REVEAL] = "Ne pas dévoiler";
FrenchDeclineButtons[DeclineButtonIds.CROWN_DONT] = "Ne pas doubler";
FrenchDeclineButtons[DeclineButtonIds.LEGIONARY_DONT_REVEAL] = "Ne pas dévoiler";
FrenchDeclineButtons[DeclineButtonIds.SHUFFLE_ALL] = "Tout mélanger";
FrenchDeclineButtons[DeclineButtonIds.PASS] = "Passer";
FrenchDeclineButtons[DeclineButtonIds.DONT_DISCARD] = "Ne pas défausser";
FrenchDeclineButtons[DeclineButtonIds.DONT_TRASH] = "Ne pas écarter";
FrenchDeclineButtons[DeclineButtonIds.VAULT_NO_DISCARD] = "Ne pas défausser";
FrenchDeclineButtons[DeclineButtonIds.DONT_BUY] = "Ne pas acheter";
FrenchDeclineButtons[DeclineButtonIds.DONT_GAIN] = "Ne pas recevoir";
FrenchDeclineButtons[DeclineButtonIds.DISCARD_ALL] = "Tout défausser";
FrenchDeclineButtons[DeclineButtonIds.TORTURER_FAIL_CURSE] = "Recevoir une Malédiction";
FrenchDeclineButtons[DeclineButtonIds.TORTURER_GAIN_CURSE] = "Recevoir une Malédiction";
FrenchDeclineButtons[DeclineButtonIds.BUSTLING_VILLAGE_NO_SETTLERS] = "Ne pas prendre de Colons";
FrenchDeclineButtons[DeclineButtonIds.PRINCE_NO] = "Ne pas mettre de côté";
FrenchDeclineButtons[DeclineButtonIds.SAUNA_DONT_CHAIN] = "Ne pas enchaîner";
FrenchDeclineButtons[DeclineButtonIds.AVANTO_DONT_CHAIN] = "Ne pas enchaîner";
FrenchDeclineButtons[DeclineButtonIds.CONCLAVE_DONT] = "Ne pas jouer";
FrenchDeclineButtons[DeclineButtonIds.IMP_DONT] = "Ne pas jouer";
FrenchDeclineButtons[DeclineButtonIds.NECROMANCER_DONT] = "Ne pas jouer";
FrenchDeclineButtons[DeclineButtonIds.NO_THANKS] = "Non merci";
FrenchDeclineButtons[DeclineButtonIds.DONT_EXILE] = "Ne pas exiler";
FrenchDeclineButtons[DeclineButtonIds.KINGS_COURT_DONT] = "Ne pas rejouer";
FrenchDeclineButtons[DeclineButtonIds.MASTERMIND_DONT] = "Ne pas utiliser le Génie";
FrenchDeclineButtons[DeclineButtonIds.DELAY_DONT] = "Ne pas retarder";
FrenchDeclineButtons[DeclineButtonIds.TOIL_DONT] = "Ne pas utiliser le Labeur";
FrenchDeclineButtons[DeclineButtonIds.MARCH_DONT] = "Ne pas utiliser la Marche";
FrenchDeclineButtons[DeclineButtonIds.DONT_TOPDECK] = "Ne pas mettre sur la pioche";
FrenchDeclineButtons[DeclineButtonIds.RANDOM_ORDER] = "Ordre aléatoire";
FrenchDeclineButtons[DeclineButtonIds.HUNTING_LODGE_KEEP] = "Conserver";
FrenchDeclineButtons[DeclineButtonIds.TIARA_NO_TREASURE] = "Ne pas jouer";
FrenchDeclineButtons[DeclineButtonIds.SWAP_DONT] = "Ne pas troquer";
FrenchDeclineButtons[DeclineButtonIds.DONT_PLAY] = "Ne pas jouer";
FrenchDeclineButtons[DeclineButtonIds.DONT_REVEAL] = "Ne pas dévoiler";
FrenchDeclineButtons[DeclineButtonIds.DONT_ROTATE] = "Ne pas tourner";
FrenchDeclineButtons[DeclineButtonIds.DONT] = "Non";
FrenchDeclineButtons[DeclineButtonIds.SPEND_FAVOR] = "Dépenser une Faveur";
FrenchDeclineButtons[DeclineButtonIds.CONTINUE] = "Continuer";
FrenchDeclineButtons[DeclineButtonIds.ORB_DONT] = "+1 Achat, +3 Pièces";
FrenchDeclineButtons[DeclineButtonIds.DONT_PUZZLE_BOX] = "Ne pas mettre de côté";
FrenchDeclineButtons[DeclineButtonIds.DONT_INSPIRING] = "Ne pas jouer";
FrenchDeclineButtons[DeclineButtonIds.DONT_JOUST] = "Ne pas mettre de côté";
FrenchDeclineButtons[DeclineButtonIds.SNAKE_WITCH_DONT_RETURN] = "Don't return"; // <- translate

FrenchDeclineButtons[DoneButtonIds.DONE_TRASHING] = "Confirmer l'écart";
FrenchDeclineButtons[DoneButtonIds.DONE_DISCARDING] = "Confirmer la défausse";
FrenchDeclineButtons[DoneButtonIds.DONE_ORDERING] = "Confirmer l'ordre";
FrenchDeclineButtons[DoneButtonIds.DONE_RETURNING] = "Confirmer le retour";
FrenchDeclineButtons[DoneButtonIds.DONE_SETTING_ASIDE] = "Confirmer la mise de côté";
FrenchDeclineButtons[DoneButtonIds.DONE_SELECTING] = "Confirmer la sélection";
FrenchDeclineButtons[DoneButtonIds.DONE_CHOOSING] = "Confirmer le choix";
FrenchDeclineButtons[DoneButtonIds.TRASH] = "Écarter";
FrenchDeclineButtons[DoneButtonIds.DISCARD] = "Défausser";
FrenchDeclineButtons[DoneButtonIds.PASS] = "Passer";
FrenchDeclineButtons[DoneButtonIds.TOPDECK] = "Mettre sur la pioche";
FrenchDeclineButtons[DoneButtonIds.BOTTOMDECK] = "Mettre sous la pioche";
FrenchDeclineButtons[DoneButtonIds.EXILE] = "Exiler";
FrenchDeclineButtons[DoneButtonIds.OVERPAY] = "Surpayer";

var FrenchGameButtons = {};

FrenchGameButtons[GameButtons.AUTOPLAY_TREASURES] = "Jouer automatiquement les trésors";
FrenchGameButtons[GameButtons.USE_VILLAGER] = "Utiliser des Villageois";
FrenchGameButtons[GameButtons.USE_FAVOR_TOPDECK] = "Utiliser des Faveurs pour placer sur la pioche";
FrenchGameButtons[GameButtons.USE_FAVOR_DISCARD] = "Utiliser des Faveurs pour défausser";
FrenchGameButtons[GameButtons.COFFERS] = "Utiliser des Coffres";

var FrenchDelayedQuestionButtons = {};

FrenchDelayedQuestionButtons[CardNames.ARTIFICER] = "Utiliser le Maître Artisan";
FrenchDelayedQuestionButtons[CardNames.BUSTLING_VILLAGE] = "Prendre les Colons";
FrenchDelayedQuestionButtons[CardNames.CULTIST] = "Jouer un Cultiste";
FrenchDelayedQuestionButtons[CardNames.ENGINEER] = "Écarter l'Ingénieur";
FrenchDelayedQuestionButtons[CardNames.HAMLET] = "Défausser";
FrenchDelayedQuestionButtons[CardNames.HARBINGER] = "Mettre sur la pioche";
FrenchDelayedQuestionButtons[CardNames.ILL_GOTTEN_GAINS] = "Recevoir un Cuivre";
FrenchDelayedQuestionButtons[CardNames.IRONMONGER] = "Défausser";
FrenchDelayedQuestionButtons[CardNames.MINING_VILLAGE] = "Écarter";
FrenchDelayedQuestionButtons[CardNames.PEARL_DIVER] = "Mettre sur la pioche";
FrenchDelayedQuestionButtons[CardNames.PLAZA] = "Défausser";
FrenchDelayedQuestionButtons[CardNames.SETTLERS] = "Prendre un Cuivre";
//FrenchDelayedQuestionButtons[CardNames.VASSAL] = "Jouer l'action"; // this doesn't exist in French
FrenchDelayedQuestionButtons[CardNames.HUNTING_LODGE] = "Défausser & Piocher 5";
FrenchDelayedQuestionButtons[CardNames.SNAKE_WITCH] = "Return & Curse";
FrenchDelayedQuestionButtons[CardNames.RUSTIC_VILLAGE] = "Discard 2 to draw 1";
FrenchDelayedQuestionButtons[CardNames.GOLD_MINE] = "Gold & 4 Debt";

var FrenchCleanupActions = {};

FrenchCleanupActions[CleanupActions.TOPDECK] = new CleanupButtonText("Remettre", "Défausser");
FrenchCleanupActions[CleanupActions.EXCHANGE] = new CleanupButtonText("Échanger", "Garder");

// Missing lines as of 08.01.2023 18:54:47
FrenchDeclineButtons[DeclineButtonIds.ORB_DONT] = "+1 Achat, +3 Pièces";
FrenchDeclineButtons[DeclineButtonIds.DONT_PUZZLE_BOX] = "Ne pas mettre de côté";
FrenchDeclineButtons[DeclineButtonIds.DONT_INSPIRING] = "Ne pas jouer";