"use strict";

var TChineseErrors = {};

TChineseErrors[FailureReasons.WRONG_PASSWORD] = "密碼錯誤。";
TChineseErrors[FailureReasons.NOT_ENOUGH_PLAYERS] = "人數不夠！";
TChineseErrors[FailureReasons.TOO_MANY_PLAYERS] = "太多人要玩了！";
TChineseErrors[FailureReasons.NEW_HOST_NOT_FOUND] = "無法找到新的房長";
TChineseErrors[FailureReasons.ALREADY_JOINED_A_TABLE] = "你已經在一間房間裡面了！";
TChineseErrors[FailureReasons.NOT_ALLOWED_TO_JOIN] = "你被禁止加入房間";
TChineseErrors[FailureReasons.BAD_CLIENT_VERSION] = "你的用戶端太舊了; 請重新整理來下載新的用戶端。";
TChineseErrors[FailureReasons.UNKNOWN_RULE_INDEX] = "不知名的規則表";
TChineseErrors[FailureReasons.RULE_NOT_REMOVABLE] = "這規則無法被移除";
TChineseErrors[FailureReasons.INVITEE_NOT_LOGGED_IN] = "被邀請的人沒有登入遊戲。";
TChineseErrors[FailureReasons.NOT_ALLOWED_TO_PLAY] = "你不能在此房間遊玩";
TChineseErrors[FailureReasons.TABLE_CHAT_REQUIRES_TABLE] = "需要在房間內才能聊天！";
TChineseErrors[FailureReasons.SPECTATOR_CHAT_REQUIRES_TABLE] = "在房間內才能使用觀眾聊天室";
TChineseErrors[FailureReasons.LOGIN_REQUIRED] = "你需要登入才能！";
TChineseErrors[FailureReasons.COMMAND_REQUIRES_TABLE] = "要在房間內才能做！";
TChineseErrors[FailureReasons.COMMAND_REQUIRES_HOST] = "房主才能做！";
TChineseErrors[FailureReasons.RULE_CONFLICTS] = "遊戲規則衝突啦";
TChineseErrors[FailureReasons.NO_GAMESERVER_AVAILABLE] = "找不到遊戲伺服器";
TChineseErrors[FailureReasons.SELECTED_CARD_NOT_OWNED] = "你不擁有選擇的卡片";
TChineseErrors[FailureReasons.SELECTED_CARD_NOT_KINGDOM_CARD] = "你選的卡片不是王國卡";
TChineseErrors[FailureReasons.SELECTED_TOO_MANY_EVENTS_OR_LANDMARKS] = "選太多事件和地標了";
TChineseErrors[FailureReasons.THIS_IS_NOT_YOUR_TABLE] = "這房間不屬於你喔";
TChineseErrors[FailureReasons.ALREADY_LOGGED_IN] = "已經登入了.";
TChineseErrors[FailureReasons.CANNOT_RECONNECT_WHEN_STILL_CONNECTED] = "正在連接伺服器，請不要重新連結";
TChineseErrors[FailureReasons.KICK_FAILED] = "踢除失敗";
TChineseErrors[FailureReasons.ALREADY_STARTING] = "已經準備開始囉";
TChineseErrors[FailureReasons.ALREADY_STARTED] = "已經開始囉";
TChineseErrors[FailureReasons.NOTHING_TO_KICK] = "沒有人可以踢";
TChineseErrors[FailureReasons.INTERNAL_ERROR] = "我們系統出錯...，非常抱歉QQ";
TChineseErrors[FailureReasons.ILLEGAL_PROPERTY_CHANGE] = "不正當的改變";
TChineseErrors[FailureReasons.CODE_ALREADY_USED] = "序號已被使用";
TChineseErrors[FailureReasons.FAILED_CONFIRMING_EMAIL] = "無法驗證信箱";
TChineseErrors[FailureReasons.FAILED_RETRIEVING_USER] = "無法恢復使用者";
TChineseErrors[FailureReasons.EMAIL_NOT_CONFIRMED_YET] = "還沒驗證信箱，您無法當房主開始遊戲";
TChineseErrors[FailureReasons.FAILURE_SENDING_EMAIL] = "驗證信無法送出";
TChineseErrors[FailureReasons.CONFIRM_EMAIL_FIRST] = "抱歉，沒有任何驗證的信箱可以讓我們寄出您的密碼。";
TChineseErrors[FailureReasons.OLD_PASSWORD_INCORRECT] = "舊密碼錯誤";
TChineseErrors[FailureReasons.INCORRECT_PRICE] = "價錢錯誤！";
TChineseErrors[FailureReasons.PURCHASE_IN_FUTURE] = "PURCHASE IN FUTURE!大概是你的起始時間有誤";
TChineseErrors[FailureReasons.PURCHASE_TOO_OLD] = "Purchase too old; please try again";
TChineseErrors[FailureReasons.FAILED_SETTING_UP_PURCHASE] = "無法設定購買";
TChineseErrors[FailureReasons.FAILED_STORING_PURCHASE] = "購買失敗QQ";
TChineseErrors[FailureReasons.DUPLICATE_USERNAME] = "這個使用者名稱已經被使用囉";
TChineseErrors[FailureReasons.INVALID_USERNAME] = "不合法的使用者名稱！";
TChineseErrors[FailureReasons.INVALID_PASSWORD] = "不合法的密碼！";
TChineseErrors[FailureReasons.INVALID_GENDER] = "請確認性別";
TChineseErrors[FailureReasons.PURCHASE_TOO_SHORT] = "訂閱至少要一個月以上";
TChineseErrors[FailureReasons.PURCHASE_TOO_LONG] = "我們目前不販售2024年以後的訂閱";
TChineseErrors[FailureReasons.METASERVER_INACTIVE] = "新的版本已經釋出了; 請重新整理此頁面";
TChineseErrors[FailureReasons.NO_SUCH_TABLE] = "沒有這種房間";
TChineseErrors[FailureReasons.NO_SUCH_USER] = "沒有此使用者";
TChineseErrors[FailureReasons.NO_SUCH_CODE] = "沒有此代碼";
TChineseErrors[FailureReasons.RESERVED_USERNAME] = "此使用者名稱已保留";
TChineseErrors[FailureReasons.PRICE_IS_ZERO] = "你不可以用零元購買";
TChineseErrors[FailureReasons.NO_SUCH_USERNAME] = "此使用者名稱不存在";
TChineseErrors[FailureReasons.NO_SUCH_GAMELOG] = "要求的遊戲不存在";
TChineseErrors[FailureReasons.ILLEGAL_PLAYER_ORDER] = "玩家順序有問題";
TChineseErrors[FailureReasons.INVALID_SESSION_ID] = "Invalid Session ID.";
TChineseErrors[FailureReasons.ACTION_CODE_EXPIRED] = "活動碼已過期.";
TChineseErrors[FailureReasons.BOT_GAMES_CANT_BE_RATED] = "跟電腦遊玩不能算積分";
TChineseErrors[FailureReasons.TOO_MANY_PLAYERS_FOR_RATED_GAME] = "五人局以上不可是積分對戰";
TChineseErrors[FailureReasons.ALREADY_ON_BLACKLIST] = "已經在黑名單上了";
TChineseErrors[FailureReasons.NOT_ON_BLACKLIST] = "不屬於黑名單";
TChineseErrors[FailureReasons.ALREADY_ON_FRIENDLIST] = "已經是朋友了";
TChineseErrors[FailureReasons.NOT_ON_FRIENDLIST] = "此人不在朋友名單上喔";
TChineseErrors[FailureReasons.SEARCHING_FOR_MYSELF] = "不可以自己跟自己當朋友";
TChineseErrors[FailureReasons.SEARCHED_FOR_BOT] = "請不要跟電腦當朋友";
TChineseErrors[FailureReasons.USER_IS_BANNED] = "你已經被bang了";
TChineseErrors[FailureReasons.WRONG_BONUS_CODE_TYPE] = "序號種類錯誤！";
TChineseErrors[FailureReasons.ILLEGAL_BONUS_CODE] = "序號錯誤！";
TChineseErrors[FailureReasons.GAME_ID_SHOULD_BE_NUMBER] = "遊戲編號必須為數字.";
TChineseErrors[FailureReasons.NOT_SUBSCRIBED_TO] = "您的訂閱不包含";
TChineseErrors[FailureReasons.KINGDOM_HAS_TOO_MANY_CARDS] = "選太多卡來玩了";
TChineseErrors[FailureReasons.NO_CARDS_AVAILABLE_TO_FILL_KINGDOM] = "不夠多卡片來玩";
TChineseErrors[FailureReasons.FAILED_TO_SELECT_BANE_CARD] = "沒有辦法選到毒藥";
TChineseErrors[FailureReasons.FAILED_TO_SELECT_MOUSE_CARD] = "沒有辦法選到老鼠的卡片";
TChineseErrors[FailureReasons.FAILED_TO_SELECT_FERRYMAN_CARD] = "沒有辦法選到船夫的卡片.";
TChineseErrors[FailureReasons.FAILED_TO_SELECT_PREVIEW_CARDS] = "沒有辦法選到搶先看的卡片.";
TChineseErrors[FailureReasons.FAILED_TO_SELECT_ALLY] = "沒有辦法選到同盟的卡片";
TChineseErrors[FailureReasons.FAILED_TO_SELECT_LIASON] = "沒有辦法選到聯絡人的卡片";
TChineseErrors[FailureReasons.REQUIRING_RANDOM_CARD_FROM_DISABLED_EXPANSION] = "你設定不想遊玩某個擴充，但你又選擇了那個擴充的隨機卡片。";
TChineseErrors[FailureReasons.COMMAND_REQUIRES_PLAYER] = "有加入遊戲的玩家才能這樣做";
TChineseErrors[FailureReasons.COMMAND_REQUIRES_GAME] = "This command requires a game.";
TChineseErrors[FailureReasons.ILLEGAL_ANSWER] = "Provided an illegal answer in this game.";
TChineseErrors[FailureReasons.ILLEGAL_REQUEST] = "Illegal request in this game.";
TChineseErrors[FailureReasons.NO_SUCH_UNDO] = "No such undo.";
TChineseErrors[FailureReasons.SERVER_DOESNT_ALLOW_NEW_GAMES] = "Server doesn't allow new games. Please reload the page.";
TChineseErrors[FailureReasons.SERVER_IS_DOWN] = "Server is down. Please reload the page.";

var TChineseSuccessDescriptions = {};

TChineseSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_FORGOT_PASSWORD] = "已寄出更改密碼的信件。";
TChineseSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_CONFIRM_EMAIL] = "我們已經寄出驗證信到你的信箱了";
TChineseSuccessDescriptions[SuccessDescriptions.PASSWORD_CHANGED] = "密碼改成功";
TChineseSuccessDescriptions[SuccessDescriptions.KICK_SUCCESS] = "踢除成功";
TChineseSuccessDescriptions[SuccessDescriptions.PURCHASE_SUCCESS] = "購買成功！";
TChineseSuccessDescriptions[SuccessDescriptions.EMAIL_CONFIRMED] = "信箱驗證";
TChineseSuccessDescriptions[SuccessDescriptions.REFUND_SUCCESS] = "退款成功";
TChineseSuccessDescriptions[SuccessDescriptions.ACTION_CODE_USED] = "成功使用活動碼";
TChineseSuccessDescriptions[SuccessDescriptions.SUBSCRIPTION_UPDATED] = "你的訂閱更新囉";

var TChineseTableRules = {};

TChineseTableRules[TableRuleIds.PLAYERS_CAN_SEE_SPECTATOR_CHAT] = "玩家可以看到觀眾的對話";
//TChineseTableRules[TableRuleIds.SPECTATORS_CAN_SEE_PLAYER_CHAT] = "觀眾可以看到玩家的對話"; // does not exist anywhere else
TChineseTableRules[TableRuleIds.BANNED_CARDS] = "禁卡";
TChineseTableRules[TableRuleIds.REQUIRED_CARDS] = "指定要玩的卡片";
TChineseTableRules[TableRuleIds.USED_EXPANSIONS] = "使用的擴充";
TChineseTableRules[TableRuleIds.RESTRICTIVE_PLAYER_RULES] = "只有被指定的玩家可以遊玩";
TChineseTableRules[TableRuleIds.SPECTATE_RULES] = "允許有觀眾";
TChineseTableRules[TableRuleIds.MIN_RATING] = "對手的最低等級";
TChineseTableRules[TableRuleIds.MAX_RATING] = "對手的最高等級";
TChineseTableRules[TableRuleIds.RANDOM_SEED] = "指定亂數產生碼";
TChineseTableRules[TableRuleIds.MIN_PLAYERS] = "玩家數下限";
TChineseTableRules[TableRuleIds.MAX_PLAYERS] = "玩家數上限";
TChineseTableRules[TableRuleIds.SPECIAL_KINGDOM_RULES] = "一些特殊設定";
TChineseTableRules[TableRuleIds.RESPECTED_CARD_LISTS] = "依照雙方的 1. 喜歡的卡片 2. 討厭的卡片 3.禁玩的卡片 來選卡";
TChineseTableRules[TableRuleIds.SHOW_VP_COUNTER] = "顯示分數";
TChineseTableRules[TableRuleIds.UNDO_SETTINGS] = "上一步的設定";
TChineseTableRules[TableRuleIds.ALWAYS_ALLOWED_PLAYER_RULES] = "ALWAYS_ALLOWED_PLAYER_RULES";
TChineseTableRules[TableRuleIds.PLAYER_ORDER] = "玩家順序";
TChineseTableRules[TableRuleIds.REPLAY_INSTRUCTIONS] = "重播指令";
TChineseTableRules[TableRuleIds.RATED_GAME] = "積分對戰";
TChineseTableRules[TableRuleIds.PREVIEW_GAME] = "動物世界搶先看";
TChineseTableRules[TableRuleIds.SCRIPTED_RULES] = "特殊規則";
TChineseTableRules[TableRuleIds.CARD_POOL_LEVEL] = "卡片複雜度";

var TChineseActivities = {};

TChineseActivities[ActivityStati.LOGGED_IN] = "登入中";
TChineseActivities[ActivityStati.PLAYING] = "遊戲中";
TChineseActivities[ActivityStati.SPECTATING] = "觀看中";
TChineseActivities[ActivityStati.AT_TABLE_PLAYER] = "準備遊戲中(玩家)";
TChineseActivities[ActivityStati.AT_TABLE_SPECTATOR] = "準備遊戲中(觀眾)";
TChineseActivities[ActivityStati.DISCONNECTED] = "斷線中";

var TChineseTableStati = {};

TChineseTableStati[TableStati.NEW] = "剛建立";
TChineseTableStati[TableStati.POST_GAME] = "設定遊戲中";
TChineseTableStati[TableStati.RUNNING] = "進行中";
TChineseTableStati[TableStati.ABANDONED] = "解散中";

var TChineseLobbyButtons = {
    DONE: "完成",
    RESET: "重置",
    CLEAR_SELECTION: "清除已選擇的卡片",
    SHOW_TEXT_KINGDOM: "列出已選擇的卡片名稱",
    NEW_TABLE: "新房間",
    START_GAME: "開始遊戲",
    LEAVE_TABLE: "離開此房間",
    SELECT_KINGDOM: "選擇王國卡",
    LIST_BANNED: "禁卡",
    LIST_DISLIKED: "討厭的卡片",
    LIST_LIKED: "喜歡的卡片",
    LIST_FAMILIAR: "願意玩的卡片",
    LOAD_FROM_END: "跳到遊戲結束的前一步",
    SELECT_COLONIES: "殖民地: ",
    SELECT_SHELTERS: "避難所: "
};

var TChineseLobbyTexts = {
    AUTOCOMPLETE_FAILED: "沒有找到與 \"CARDS\" 名字相符的卡片",
    ADD_CARDS_AUTOCOMPLETE: "輸入你想要遊玩的卡片",
    BAN_CARDS_AUTOCOMPLETE: "輸入您想要禁止的卡片",
    KINGDOM_OPTIONS: "王國卡設定",
    GAME_OPTIONS: "遊戲選項",
    RESPECTED_CARDS: "根據右方有亮的選項來玩：",
    RELATIVE_LEVEL: "希望的對手與你的等級差距",
    LEVEL_INTERVAL: "${min} 到 ${max}",
    EMPTY_SLOT: "玩家空位",
    REMOVE_SLOT: "減少空位",
    ADD_SLOT: "新增空位",
    RANDOMIZE_PLAYER_ORDER: "隨機玩家順序",
    CHAT: "對話框",
    SELECT_CARDS: "選擇卡片"
};

var TChineseTernaryFieldTexts = {};

TChineseTernaryFieldTexts[TernaryField.YES] = "有";
TChineseTernaryFieldTexts[TernaryField.NO] = "沒有";
TChineseTernaryFieldTexts[TernaryField.RANDOM] = "隨機";

var TChineseTabs = {};

TChineseTabs[LobbyTabs.INBOX] = "信箱";
TChineseTabs[LobbyTabs.MATCH] = "配對";
TChineseTabs[LobbyTabs.FRIEND_LIST] = "好友列表";
TChineseTabs[LobbyTabs.TABLES] = "現存的房間";
TChineseTabs[LobbyTabs.NEW_TABLE] = "新房間";
TChineseTabs[LobbyTabs.MY_TABLE] = "我的房間";
TChineseTabs[LobbyTabs.ACCOUNT] = "帳戶";
TChineseTabs[LobbyTabs.STORE] = "商店";
TChineseTabs[LobbyTabs.OPTIONS] = "選項";
TChineseTabs[LobbyTabs.FAMILIAR_CARDS] = "練習場選卡";
TChineseTabs[LobbyTabs.LEADERBOARD] = "積分表";
TChineseTabs[LobbyTabs.CARDLISTS] = "積分禁卡設定";

var TChineseMatchTypes = {};

TChineseMatchTypes[AutomatchTypes.QUICK_MATCH] = "任意對手";
TChineseMatchTypes[AutomatchTypes.GOOD_MATCH] = "等級相近的對手";
TChineseMatchTypes[AutomatchTypes.MATCH_WITH_EXPANSIONS] = "與有購買擴充的對手";
TChineseMatchTypes[AutomatchTypes.MATCH_WITH_BOT] = "電腦練習";
TChineseMatchTypes[AutomatchTypes.MATCH_UNRATED] = "電腦對手";

var TChineseUndoSettings = {};

TChineseUndoSettings[UndoSettings.NEVER] = "從不";
TChineseUndoSettings[UndoSettings.ASK] = "詢問";
TChineseUndoSettings[UndoSettings.ALWAYS] = "永遠";

var TChineseGroupIds = {};

TChineseGroupIds[GroupIds.EVERYBODY] = "所有人";
TChineseGroupIds[GroupIds.NOBODY] = "沒有人";
TChineseGroupIds[GroupIds.FRIENDS_OF] = "好友";
TChineseGroupIds[GroupIds.LIST_PLAYER_IDS] = "選擇的玩家:";

var TChineseCountries = {};

TChineseCountries[Countries.NON_EU] = "非歐洲國家";
TChineseCountries[Countries.AT] = "奧地利";
TChineseCountries[Countries.BE] = "比利時";
TChineseCountries[Countries.BG] = "保加利亞";
TChineseCountries[Countries.HR] = "克羅埃西亞";
TChineseCountries[Countries.CY] = "賽普勒斯";
TChineseCountries[Countries.CZ] = "捷克";
TChineseCountries[Countries.DK] = "丹麥";
TChineseCountries[Countries.EE] = "愛沙尼亞";
TChineseCountries[Countries.FI] = "芬蘭";
TChineseCountries[Countries.FR] = "法果";
TChineseCountries[Countries.DE] = "德國";
TChineseCountries[Countries.GR] = "希臘";
TChineseCountries[Countries.HU] = "匈牙利";
TChineseCountries[Countries.IE] = "愛爾蘭";
TChineseCountries[Countries.IT] = "義大利";
TChineseCountries[Countries.LV] = "拉脫維亞";
TChineseCountries[Countries.LT] = "立陶宛";
TChineseCountries[Countries.LU] = "盧森堡";
TChineseCountries[Countries.MT] = "馬爾他";
TChineseCountries[Countries.NL] = "荷蘭";
TChineseCountries[Countries.PL] = "波瀾";
TChineseCountries[Countries.PT] = "葡萄牙";
TChineseCountries[Countries.RO] = "羅馬尼亞";
TChineseCountries[Countries.SK] = "斯洛伐克";
TChineseCountries[Countries.SI] = "斯洛維尼亞";
TChineseCountries[Countries.ES] = "西班牙";
TChineseCountries[Countries.SE] = "瑞典";
TChineseCountries[Countries.GB] = "英國";

var TChineseAutomatchQuestions = {};
var TChineseAutomatchValues = {};

TChineseAutomatchQuestions[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS] = "幾人場";
TChineseAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS] = {};
TChineseAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.REQUIRE] = "2人";
TChineseAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.EITHER] = "2人或3人";
TChineseAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.REJECT] = "3人";

TChineseAutomatchQuestions[AUTOMATCH_QUESTION.RATED_GAME] = "積分或練習";
TChineseAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME] = {};
TChineseAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.REQUIRE] = "積分賽";
TChineseAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.EITHER] = "積分賽或練習場";
TChineseAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.REJECT] = "練習賽";

TChineseAutomatchQuestions[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME] = "想玩更多新卡片";
TChineseAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME] = {};
TChineseAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.REQUIRE] = "要";
TChineseAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.EITHER] = "隨意";
TChineseAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.REJECT] = "不需要";

TChineseAutomatchQuestions[AUTOMATCH_QUESTION.PREVIEW_GAME] = "多玩一些新的卡";
TChineseAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME] = {};
TChineseAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.REQUIRE] = "要";
TChineseAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.REJECT] = "不需要";

// Missing lines as of 09.01.2023 20:44:21
TChineseAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.EITHER] = "要不要";