"use strict";

var GermanAutoPlays = {};

GermanAutoPlays[AutoPlays.MOAT] = ["Aus", "Immer aufdecken"];
GermanAutoPlays[AutoPlays.TRADER] = ["Aus", "Nicht bei Silber reagieren"];
GermanAutoPlays[AutoPlays.CROWN] = ["Aus", "Aktionen automatisch beenden"];
GermanAutoPlays[AutoPlays.CHANGELING] = ["Aus", "Schlaues Nachfragen", "Nie austauschen"];
GermanAutoPlays[AutoPlays.URCHIN] = ["Aus", "Nie entsorgen"];
GermanAutoPlays[AutoPlays.MOUNTEBANK] = ["Aus", "Immer ablegen"];
GermanAutoPlays[AutoPlays.SETTLERS] = ["Aus", "Immer auf die Hand nehmen"];
GermanAutoPlays[AutoPlays.BUSTLING_VILLAGE] = ["Aus", "Immer auf die Hand nehmen"];
GermanAutoPlays[AutoPlays.TOURNAMENT] = ["Aus", "Immer Provinz aufdecken"];
GermanAutoPlays[AutoPlays.PEASANT] = ["Aus", "Standardmäßig austauschen", "Standardmäßig behalten"];
GermanAutoPlays[AutoPlays.SOLDIER] = ["Aus", "Standardmäßig austauschen", "Standardmäßig behalten"];
GermanAutoPlays[AutoPlays.FUGITIVE] = ["Aus", "Standardmäßig austauschen", "Standardmäßig behalten"];
GermanAutoPlays[AutoPlays.DISCIPLE] = ["Aus", "Standardmäßig austauschen", "Standardmäßig behalten"];
GermanAutoPlays[AutoPlays.PAGE] = ["Aus", "Standardmäßig austauschen", "Standardmäßig behalten"];
GermanAutoPlays[AutoPlays.TREASURE_HUNTER] = ["Aus", "Standardmäßig austauschen", "Standardmäßig behalten"];
GermanAutoPlays[AutoPlays.WARRIOR] = ["Aus", "Standardmäßig austauschen", "Standardmäßig behalten"];
GermanAutoPlays[AutoPlays.HERO] = ["Aus", "Standardmäßig austauschen", "Standardmäßig behalten"];
GermanAutoPlays[AutoPlays.FAITHFUL_HOUND] = ["Aus", "Immer zur Seite legen"];
GermanAutoPlays[AutoPlays.YOUNG_WITCH] = ["Aus", "Immer Bannkarte aufdecken"];
GermanAutoPlays[AutoPlays.TREASURY] = ["Aus", "Immer auf Nachziehstapel"];
GermanAutoPlays[AutoPlays.ALCHEMIST] = ["Aus", "Immer auf Nachziehstapel"];
GermanAutoPlays[AutoPlays.WALLED_VILLAGE] = ["Aus", "Immer auf Nachziehstapel"];
GermanAutoPlays[AutoPlays.HERBALIST] = ["Aus", "Beliebige Reihenfolge"];
GermanAutoPlays[AutoPlays.ROYAL_SEAL] = ["Aus", "Immer auf Nachziehstapel", "Nie auf Nachziehstapel"];
GermanAutoPlays[AutoPlays.COIN_OF_THE_REALM] = ["Aus", "Schlaues Nachfragen"];
GermanAutoPlays[AutoPlays.BORDER_GUARD] = ["Aus", "Immer auf Nachziehstapel"];
GermanAutoPlays[AutoPlays.PROVINCE] = ["Aus", "Schlechte Karten im Exil lassen"];
GermanAutoPlays[AutoPlays.CHAMPION] = ["Aus", "Schlaues Überdenken eines Weges"];
GermanAutoPlays[AutoPlays.WAY_OF_THE_BUTTERFLY] = ["Aus", "Schlaues Zurücklegen"];
GermanAutoPlays[AutoPlays.DESPERATION] = ["Aus", "Immer Fluch nehmen"];
GermanAutoPlays[AutoPlays.TORTURER] = ["Aus", "Nicht unnötig ablegen"];
GermanAutoPlays[AutoPlays.ARENA] = ["Aus", "Nicht unnötig ablegen"];
GermanAutoPlays[AutoPlays.PAGEANT] = ["Aus", "Immer Taler nehmen"];
GermanAutoPlays[AutoPlays.OLD_WITCH] = ["Aus", "Immer Fluch entsorgen"];
GermanAutoPlays[AutoPlays.SINISTER_PLOT] = ["Aus", "Nie null Karten ziehen"];
GermanAutoPlays[AutoPlays.MONEYLENDER] = ["Aus", "Immer Kupfer entsorgen"];
GermanAutoPlays[AutoPlays.ENCAMPMENT] = ["Aus", "Schlaues Aufdecken"];
GermanAutoPlays[AutoPlays.DUPLICATE] = ["Aus", "Nur nehmbare Karten", "... und keine Flüche"];