"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PileReorder = function (_GameStateChange) {
    _inherits(PileReorder, _GameStateChange);

    function PileReorder(game, pileId, cardIds) {
        _classCallCheck(this, PileReorder);

        var _this = _possibleConstructorReturn(this, (PileReorder.__proto__ || Object.getPrototypeOf(PileReorder)).call(this));

        _this.game = game;
        _this.pileId = pileId;
        _this.cardIds = cardIds;
        _this.toZoneIndex = pileId;
        _this.fromZoneIndex = pileId;
        return _this;
    }

    _createClass(PileReorder, [{
        key: "execute",
        value: function execute(game, terminator) {
            var pile = game.state.zones[this.pileId];
            [].concat(_toConsumableArray(pile.cardStacks)).forEach(function (s) {
                if (s.trackedAnimators.length === 0) {
                    s.destroy();
                } else {
                    s.addFilter("intangible");
                }
            });
            pile.initialize(this.cardIds);
            terminator();
        }
    }, {
        key: "createAnimation",
        value: function createAnimation() {
            var settings = getAnimationSettings();
            if (!settings.isInstant && document.hasFocus() && !(this.game.state.zones[this.toZoneIndex] instanceof DrawZone)) {
                return new AnimatedPileReorder(this, this.toZoneIndex);
            } else {
                return new PileConstructingAnimation(this, this.toZoneIndex);
            }
        }
    }], [{
        key: "parse",
        value: function parse(game, reader) {
            var pileId = reader.readInt();
            var cardIds = reader.readInts();
            return new PileReorder(game, pileId, cardIds);
        }
    }]);

    return PileReorder;
}(GameStateChange);