"use strict";

function Action(name, color) {
    var crossURL = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "yellow-cross";
    var hoverColor = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    var undoColor = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
    var isActive = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : true;

    this.name = name;
    this.lowercase = this.name.toLowerCase();
    this.undoAction = this;
    this.color = color;
    this.hoverColor = hoverColor === null ? color : hoverColor;
    this.undoColor = undoColor === null ? color : undoColor;
    this.isActive = isActive;
    this.crossURL = crossURL;
}

var Actions = {
    DISABLED: new Action("Disabled", "#000", null, null, null, false),
    NONE: new Action("None", "#000", null, null, null, false),
    PLAY: new Action("Play", "#32CD32", "green-cross", "#32A932"),
    TRASH: new Action("Trash", "#D00", "red-cross", "#F00"),
    DISCARD: new Action("Discard", "#FFA500"),
    NAME: new Action("Name", "#6A5ACD"),
    REVEAL: new Action("Pass", "#1B37C3", "blue-cross", "#323F80"),
    PASS: new Action("Pass", "#87CEFA", "light-blue-cross", "#468BB6"),
    REACT: new Action("React", "#507EC5", null, "#3A547C"),
    PLAY_USING_VILLAGER: new Action("PlayUsingVillager", "#29CCFF", "light-blue-cross", "#68B0C7"),
    EXILE: new Action("Exile", "#9B0000", "dark-red-cross", "#672424"),
    WAY: new Action("Way", "#3C3C3C", null, "#2FB4DC", "#84E2FF", null),
    UNDO_WAY: new Action("UndoWay", "#3C3C3C"),
    BUY: new Action("Buy", "#111", null, "#EEE")
};

Actions.WAY.undoAction = Actions.UNDO_WAY;
Actions.UNDO_WAY.undoAction = Actions.WAY;