"use strict";

webclient.directive('kingdomStack', ['gameBroadcaster', KingdomStack]);

function KingdomStack(gameBroadcaster) {
    KingdomStackController.$inject = ['$scope'];

    function KingdomStackController($scope) {
        $scope.getLandscapeClass = function (card) {
            return card.isLandscape() ? "landscape" : "";
        };

        $scope.shouldShowKVRemark = function (card) {
            return typeof card.KVRemark !== "undefined";
        };
    }

    return {
        restrict: 'AE',
        scope: {
            card: '='
        },
        template: '\n<div class="kingdom-viewer-card-container {{getLandscapeClass(card)}}">\n    <anonymous-card card-name="card"></anonymous-card>\n</div>',
        replace: true,
        controller: KingdomStackController
    };
}