"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var GameButtonView = function (_RenderableObject) {
    _inherits(GameButtonView, _RenderableObject);

    function GameButtonView(question, answer, text) {
        _classCallCheck(this, GameButtonView);

        var _this = _possibleConstructorReturn(this, (GameButtonView.__proto__ || Object.getPrototypeOf(GameButtonView)).call(this));

        _this.question = question;
        _this.answer = answer;
        _this.text = text;
        _this.visibility = true;
        _this.isSideButton = false;
        _this.hoverState = 0;
        _this.hovered = false;
        return _this;
    }

    _createClass(GameButtonView, [{
        key: "tickHandler",
        value: function tickHandler() {
            this.redraw();
        }
    }, {
        key: "tick",
        value: function tick() {
            var _this2 = this;

            if (this.hovered) this.hoverState += 0.1;else this.hoverState -= 0.1;
            this.hoverState = Math.min(1, Math.max(0, this.hoverState));
            this.tickHandler();
            if (this.hovered && this.hoverState < 1 || !this.hovered && this.hoverState > 0) requestAnimationFrame(function () {
                return _this2.tick();
            });
        }
    }, {
        key: "render",
        value: function render(node) {
            var _this3 = this;

            _get(GameButtonView.prototype.__proto__ || Object.getPrototypeOf(GameButtonView.prototype), "render", this).call(this, node);
            this.canvas.onclick = function (e) {
                _this3.onClick(e);
            };
            this.canvas.onmouseover = function () {
                _this3.hovered = true;
                _this3.tick();
            };
            this.canvas.onmouseout = function () {
                _this3.hovered = false;
                _this3.tick();
            };
            this.redraw();
        }
    }, {
        key: "onClick",
        value: function onClick() {
            this.question.answer(this.answer);
        }
    }, {
        key: "repositionCanvas",
        value: function repositionCanvas(x, y) {
            var zIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 14000;

            this.canvas.style.transform = "translateX(" + x + "px) translateY(" + y + "px)";
            this.canvas.style.zIndex = zIndex;
        }
    }, {
        key: "resizeCanvas",
        value: function resizeCanvas() {
            var vh = window.innerHeight / 100;
            var width = this.width * vh;
            var height = this.height * vh;

            this.canvas.style.position = "fixed";
            this.canvas.style.width = width + "px";
            this.canvas.style.height = height + "px";

            this.canvas.width = width * 2;
            this.canvas.height = height * 2;
            this.canvas.style.cursor = "pointer";
        }
    }, {
        key: "redrawBackground",
        value: function redrawBackground() {
            var _ref = [this.canvas.width, this.canvas.height, this.context],
                w = _ref[0],
                h = _ref[1],
                ctx = _ref[2];

            var r = h * 0.1;
            ctx.lineWidth = r / 3;
            ctx.fillStyle = "rgba(0, 0, 0)";
            ctx.strokeStyle = "white";
            ctx.clearRect(0, 0, w, h);
            ctx.beginPath();
            ctx.rect(0, 0, w, h);
            ctx.globalAlpha = 0.8 + 0.15 * Curves.SYMMETRIC.getYFromX(this.hoverState);
            ctx.fill();
            if (this.enabled) ctx.stroke();
            ctx.globalAlpha = 1;
        }
    }, {
        key: "redrawText",
        value: function redrawText() {
            var _ref2 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref2[0],
                h = _ref2[1],
                ctx = _ref2[2];

            var titleFont = LANGUAGE.getEnvironment.hasOwnProperty("titleFont") ? LANGUAGE.getEnvironment.titleFont : "TrajanPro-Bold";
            ctx.fillStyle = this.enabled ? "white" : "#666";
            drawFittedText(ctx, this.text, new Rectangle(0.05 * w, 0.09 * h, 0.9 * w, 0.9 * h), 0.45, titleFont);
        }
    }, {
        key: "redraw",
        value: function redraw() {
            this.resizeCanvas();
            this.redrawBackground();
            this.redrawText();
        }
    }, {
        key: "enabled",
        get: function get() {
            return true;
        }
    }, {
        key: "hasNormalLayout",
        get: function get() {
            return true;
        }
    }, {
        key: "sortingGroup",
        get: function get() {
            return 3;
        }
    }, {
        key: "width",
        get: function get() {
            return 21;
        }
    }, {
        key: "height",
        get: function get() {
            return 7;
        }
    }]);

    return GameButtonView;
}(RenderableObject);

var IconGameButtonView = function (_GameButtonView) {
    _inherits(IconGameButtonView, _GameButtonView);

    function IconGameButtonView(question, answer, text, icon) {
        var label = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "";

        _classCallCheck(this, IconGameButtonView);

        var _this4 = _possibleConstructorReturn(this, (IconGameButtonView.__proto__ || Object.getPrototypeOf(IconGameButtonView)).call(this, question, answer, text));

        _this4.icon = icon;
        _this4.label = label;
        _this4.redraw();
        return _this4;
    }

    _createClass(IconGameButtonView, [{
        key: "redrawText",
        value: function redrawText() {
            var _ref3 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref3[0],
                h = _ref3[1],
                ctx = _ref3[2];

            var titleFont = LANGUAGE.getEnvironment.hasOwnProperty("titleFont") ? LANGUAGE.getEnvironment.titleFont : "TrajanPro-Bold";
            ctx.fillStyle = this.enabled ? "white" : "#666";
            var fontSize = measureFittedText(ctx, this.text, new Rectangle(0.05 * w, 0.09 * h, 0.9 * (w - h), 0.9 * h), 0.45, titleFont);
            if (fontSize < 0.3 * h) {
                var textWords = this.text.split(" ");
                var firstHalf = textWords.slice(0, textWords.length / 2).join(" ");
                var secondHalf = textWords.slice(textWords.length / 2).join(" ");

                drawFittedText(ctx, firstHalf, new Rectangle(0.05 * w, 0.19 * h, 0.9 * (w - h), 0.3 * h), 1, titleFont);
                drawFittedText(ctx, secondHalf, new Rectangle(0.05 * w, 0.55 * h, 0.9 * (w - h), 0.3 * h), 1, titleFont);
            } else {
                drawFittedText(ctx, this.text, new Rectangle(0.05 * w, 0.09 * h, 0.9 * (w - h), 0.9 * h), 0.45, titleFont);
            }
        }
    }, {
        key: "redrawIcon",
        value: function redrawIcon() {
            var _this5 = this;

            var _ref4 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref4[0],
                h = _ref4[1],
                ctx = _ref4[2];

            var artBbox = new Rectangle(w - 0.95 * h, 0.05 * h, 0.9 * h, 0.9 * h);
            if (isUndefined(this.icon)) return;
            Preloader.load(this.icon, function (source) {
                if (!_this5.enabled) ctx.filter = "brightness(50%)";
                ctx.drawImage(source, artBbox.x, artBbox.y, artBbox.width, artBbox.height);
                ctx.filter = "brightness(100%)";
                ctx.fillStyle = "white";
                ctx.strokeStyle = "black";
                ctx.lineWidth = 0.05 * h;
                drawFittedText(ctx, _this5.label, new Rectangle(w - h, h * 0.18, h, h * 0.7), 0.8, "Montserrat", "", true);
            });
        }
    }, {
        key: "redraw",
        value: function redraw() {
            _get(IconGameButtonView.prototype.__proto__ || Object.getPrototypeOf(IconGameButtonView.prototype), "redraw", this).call(this);
            this.redrawIcon();
        }
    }, {
        key: "label",
        set: function set(value) {
            this.privateLabel = value;
            this.redraw();
        },
        get: function get() {
            return this.privateLabel;
        }
    }, {
        key: "width",
        get: function get() {
            return 28;
        }
    }]);

    return IconGameButtonView;
}(GameButtonView);

var SelectableGameButtonView = function (_IconGameButtonView) {
    _inherits(SelectableGameButtonView, _IconGameButtonView);

    function SelectableGameButtonView() {
        _classCallCheck(this, SelectableGameButtonView);

        return _possibleConstructorReturn(this, (SelectableGameButtonView.__proto__ || Object.getPrototypeOf(SelectableGameButtonView)).apply(this, arguments));
    }

    _createClass(SelectableGameButtonView, [{
        key: "redrawBackground",
        value: function redrawBackground() {
            if (this.selected) {
                this.context.filter = "invert(1)";
            }
            _get(SelectableGameButtonView.prototype.__proto__ || Object.getPrototypeOf(SelectableGameButtonView.prototype), "redrawBackground", this).call(this);
        }
    }, {
        key: "selected",
        get: function get() {
            return this.question.accumulatedAnswers.includes(this.answer);
        }
    }, {
        key: "enabled",
        get: function get() {
            return this.selected || this.question.accumulatedAnswers.length < this.question.max;
        }
    }]);

    return SelectableGameButtonView;
}(IconGameButtonView);

var DoneGameButtonView = function (_IconGameButtonView2) {
    _inherits(DoneGameButtonView, _IconGameButtonView2);

    function DoneGameButtonView(question, text, icon) {
        _classCallCheck(this, DoneGameButtonView);

        return _possibleConstructorReturn(this, (DoneGameButtonView.__proto__ || Object.getPrototypeOf(DoneGameButtonView)).call(this, question, null, text, icon, ""));
    }

    _createClass(DoneGameButtonView, [{
        key: "resizeCanvas",
        value: function resizeCanvas() {
            _get(DoneGameButtonView.prototype.__proto__ || Object.getPrototypeOf(DoneGameButtonView.prototype), "resizeCanvas", this).call(this);
            this.canvas.style.cursor = this.enabled ? "pointer" : "default";
        }
    }, {
        key: "onClick",
        value: function onClick() {
            if (this.enabled) this.question.answerDone();
        }
    }, {
        key: "visibility",
        set: function set(value) {
            this.canvas.style.display = value ? "initial" : "none";
            this.privateVisibility = value;
        },
        get: function get() {
            return this.privateVisibility;
        }
    }, {
        key: "enabled",
        set: function set(value) {
            var shouldRedraw = value !== this.privateEnabled;
            this.privateEnabled = value;
            this.canvas.style.cursor = value ? "pointer" : "default";
            if (shouldRedraw) this.redraw();
        },
        get: function get() {
            return this.privateEnabled;
        }
    }, {
        key: "sortingGroup",
        get: function get() {
            return 4;
        }
    }]);

    return DoneGameButtonView;
}(IconGameButtonView);

var DeclineGameButtonView = function (_IconGameButtonView3) {
    _inherits(DeclineGameButtonView, _IconGameButtonView3);

    function DeclineGameButtonView(question, text, icon) {
        _classCallCheck(this, DeclineGameButtonView);

        return _possibleConstructorReturn(this, (DeclineGameButtonView.__proto__ || Object.getPrototypeOf(DeclineGameButtonView)).call(this, question, null, text, icon, ""));
    }

    _createClass(DeclineGameButtonView, [{
        key: "onClick",
        value: function onClick() {
            this.question.answerDecline();
        }
    }, {
        key: "visibility",
        set: function set(value) {
            this.canvas.style.display = value ? "initial" : "none";
            this.privateVisibility = value;
        },
        get: function get() {
            return this.privateVisibility;
        }
    }, {
        key: "sortingGroup",
        get: function get() {
            return 5;
        }
    }]);

    return DeclineGameButtonView;
}(IconGameButtonView);

var EndButtonView = function (_DeclineGameButtonVie) {
    _inherits(EndButtonView, _DeclineGameButtonVie);

    function EndButtonView() {
        _classCallCheck(this, EndButtonView);

        return _possibleConstructorReturn(this, (EndButtonView.__proto__ || Object.getPrototypeOf(EndButtonView)).apply(this, arguments));
    }

    _createClass(EndButtonView, [{
        key: "repositionCanvas",
        value: function repositionCanvas() {
            var vh = window.innerHeight / 100;
            var vw = window.innerWidth / 100;
            var x = 70 * vw - 21 * vh;
            var y = 66.5 * vh;
            this.canvas.style.transform = "translateX(" + x + "px) translateY(" + y + "px)";
        }
    }, {
        key: "resizeCanvas",
        value: function resizeCanvas() {
            var vh = window.innerHeight / 100;
            var width = 21 * vh;
            var height = 7 * vh;

            this.canvas.style.position = "fixed";
            this.canvas.style.width = width + "px";
            this.canvas.style.height = height + "px";
            this.canvas.style.zIndex = 11000;

            this.canvas.width = width * 2;
            this.canvas.height = height * 2;
            this.canvas.style.cursor = "pointer";
        }
    }, {
        key: "redraw",
        value: function redraw() {
            var _this10 = this;

            this.resizeCanvas();
            var _ref5 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref5[0],
                h = _ref5[1],
                ctx = _ref5[2];

            var artBbox = new Rectangle(0, 0, w, h);
            if (isUndefined(this.icon)) return;
            Preloader.load(this.icon, function (source) {
                var factor = Curves.SYMMETRIC.getYFromX(_this10.hoverState);
                ctx.filter = "sepia(" + factor * 100 + "%) saturate(" + (1 + factor) + ")";
                ctx.drawImage(source, 0, 0, w, h);
                var titleFont = LANGUAGE.getEnvironment.hasOwnProperty("titleFont") ? LANGUAGE.getEnvironment.titleFont : "Raleway";
                ctx.fillStyle = "#111";
                ctx.strokeStyle = "#EEE";
                drawFittedText(ctx, _this10.text, new Rectangle(0.15 * w, 0.27 * h, 0.7 * w, 0.5 * h), 1, titleFont, "bold", true);
            });
        }
    }, {
        key: "hasNormalLayout",
        get: function get() {
            return false;
        }
    }]);

    return EndButtonView;
}(DeclineGameButtonView);

var DetachedGameButtonView = function (_GameButtonView2) {
    _inherits(DetachedGameButtonView, _GameButtonView2);

    function DetachedGameButtonView(question, answer, gameButton) {
        _classCallCheck(this, DetachedGameButtonView);

        var _this11 = _possibleConstructorReturn(this, (DetachedGameButtonView.__proto__ || Object.getPrototypeOf(DetachedGameButtonView)).call(this, question, answer, ""));

        var game = question.game;
        var targetCounter = void 0,
            handler = void 0;
        switch (gameButton) {
            case GameButtons.USE_VILLAGER:
                targetCounter = CounterNames.VILLAGERS;
                handler = function handler() {
                    return question.answer(0);
                };
                break;
            case GameButtons.USE_COFFERS:
                targetCounter = CounterNames.COFFERS;
                handler = function handler() {
                    return question.answer(0);
                };
                break;
            case GameButtons.PAY_OFF_DEBT:
                targetCounter = CounterNames.DEBT;
                handler = function handler() {
                    return question.answer(0);
                };
                break;
            default:
                return _possibleConstructorReturn(_this11);
        }
        game.logModel.statusBarView.setClickableCounter(targetCounter, handler);
        return _this11;
    }

    _createClass(DetachedGameButtonView, [{
        key: "resizeCanvas",
        value: function resizeCanvas() {
            this.canvas.style.display = "none";
        }
    }, {
        key: "hasNormalLayout",
        get: function get() {
            return false;
        }
    }]);

    return DetachedGameButtonView;
}(GameButtonView);

var ShortcutButtonView = function (_IconGameButtonView4) {
    _inherits(ShortcutButtonView, _IconGameButtonView4);

    function ShortcutButtonView(question, answer, cardMode) {
        _classCallCheck(this, ShortcutButtonView);

        var _this12 = _possibleConstructorReturn(this, (ShortcutButtonView.__proto__ || Object.getPrototypeOf(ShortcutButtonView)).call(this, question, answer, LANGUAGE.getCardMode[cardMode], cardMode.imageName));

        _this12.cardMode = cardMode;
        return _this12;
    }

    _createClass(ShortcutButtonView, [{
        key: "onClick",
        value: function onClick() {
            var _this13 = this;

            this.question.clearAnswers();
            this.answer.forEach(function (a) {
                return _this13.question.answer(a);
            });
            this.question.send();
        }
    }]);

    return ShortcutButtonView;
}(IconGameButtonView);

var UndoButtonView = function (_IconGameButtonView5) {
    _inherits(UndoButtonView, _IconGameButtonView5);

    function UndoButtonView() {
        _classCallCheck(this, UndoButtonView);

        return _possibleConstructorReturn(this, (UndoButtonView.__proto__ || Object.getPrototypeOf(UndoButtonView)).apply(this, arguments));
    }

    _createClass(UndoButtonView, [{
        key: "onClick",
        value: function onClick() {
            this.question.clearAnswers();
        }
    }, {
        key: "repositionCanvas",
        value: function repositionCanvas(left, top) {
            var zIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 14000;

            var vh = window.innerHeight / 100;
            var vw = window.innerWidth / 100;
            var x = left * vw;
            var y = top * vh;
            this.canvas.style.transform = "translateX(" + x + "px) translateY(" + y + "px)";
            this.canvas.style.zIndex = zIndex;
        }
    }, {
        key: "hasNormalLayout",
        get: function get() {
            return false;
        }
    }, {
        key: "width",
        get: function get() {
            return 14;
        }
    }, {
        key: "height",
        get: function get() {
            return 5;
        }
    }, {
        key: "visibility",
        set: function set(value) {
            this.canvas.style.display = value ? "initial" : "none";
            this.privateVisibility = value;
        },
        get: function get() {
            return this.privateVisibility;
        }
    }]);

    return UndoButtonView;
}(IconGameButtonView);

var AbilityButtonView = function (_GameButtonView3) {
    _inherits(AbilityButtonView, _GameButtonView3);

    function AbilityButtonView(question, answer, label, ability) {
        _classCallCheck(this, AbilityButtonView);

        var _this15 = _possibleConstructorReturn(this, (AbilityButtonView.__proto__ || Object.getPrototypeOf(AbilityButtonView)).call(this, question, answer, label));

        _this15.ability = ability;
        _this15.count = 0;
        if (_this15.ability.name.cardName !== CardNames.BACK) _this15.cardName = _this15.ability.name.cardName;else _this15.cardName = _this15.question.game.state.cards[_this15.ability.association].cardName;
        _this15.isOptional = _this15.ability.isOptional;
        _this15.bgURL = getFullArtURL(_this15.cardName);
        _this15.iconURL = _this15.ability.name.icon === null ? null : "images/buttons/" + ability.name.icon + ".png";
        _this15.hints = _this15.ability.getButtonHints();
        _this15.wayQuestion = _this15.question.getBundledQuestion(QuestionTypes.WAY);

        _this15.wayHovered = false;
        _this15.allHovered = false;
        return _this15;
    }

    _createClass(AbilityButtonView, [{
        key: "render",
        value: function render(node) {
            var _this16 = this;

            _get(AbilityButtonView.prototype.__proto__ || Object.getPrototypeOf(AbilityButtonView.prototype), "render", this).call(this, node);
            this.canvas.onmousemove = function (e) {
                var _ref6 = [_this16.canvas.width, _this16.canvas.height],
                    w = _ref6[0],
                    h = _ref6[1];

                var wayBbox = new Rectangle(0.60 * w, 0.3 * h, 0.39 * w, 0.69 * h);
                var allBbox = new Rectangle(0.8 * w, 0, 0.2 * w, 0.3 * h);
                _this16.hovered = true;
                _this16.wayHovered = wayBbox.contains(e.offsetX * 2, e.offsetY * 2);
                _this16.allHovered = allBbox.contains(e.offsetX * 2, e.offsetY * 2);
                _this16.redraw();
            };

            this.canvas.onmouseover = function () {
                _this16.question.game.logModel.tempTicker = _this16.ability;
            };

            this.canvas.onmouseout = function () {
                _this16.question.game.logModel.restoreTicker();
            };

            this.canvas.oncontextmenu = function () {
                _this16.onRightClick();
            };
        }
    }, {
        key: "onClick",
        value: function onClick(e) {
            var _ref7 = [this.canvas.clientWidth, this.canvas.clientHeight],
                w = _ref7[0],
                h = _ref7[1];

            if (this.wayQuestion !== null && this.wayQuestion.max > 0 && this.cardName.isAction()) {
                var wayBbox = new Rectangle(0.60 * w, 0.3 * h, 0.39 * w, 0.69 * h);
                if (wayBbox.contains(e.offsetX, e.offsetY)) {
                    this.wayQuestion.answer(0);
                }
            }
            var allBbox = new Rectangle(0.8 * w, 0, 0.2 * w, 0.3 * h);
            if (this.count > 1 && this.ability.name.hasAllButton && allBbox.contains(e.offsetX, e.offsetY)) {
                this.question.game.questionModel.answerAll(this.ability);
            } else {
                this.question.answer(this.answer);
            }
        }
    }, {
        key: "onRightClick",
        value: function onRightClick() {
            if (this.cardName !== CardNames.BACK) {
                this.question.game.studyModel.study(this.cardName);
            }
        }
    }, {
        key: "redrawBackground",
        value: function redrawBackground() {
            var _this17 = this;

            var _ref8 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref8[0],
                h = _ref8[1],
                ctx = _ref8[2];

            ctx.clearRect(0, 0, w, h);
            Preloader.load(this.bgURL, function (source) {
                ctx.globalAlpha = 0.8 + 0.15 * Curves.SYMMETRIC.getYFromX(_this17.hoverState);
                var sourceH = source.width * h / w;
                ctx.drawImage(source, 0, (source.height - sourceH) / 2, source.width, sourceH, 0, 0, w, h);

                _this17.redrawWayButton();
            });
        }
    }, {
        key: "redrawWayButton",
        value: function redrawWayButton() {
            var _this18 = this;

            var _ref9 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref9[0],
                h = _ref9[1],
                ctx = _ref9[2];

            if (this.wayQuestion !== null && this.wayQuestion.max > 0 && this.cardName.isAction()) {
                var wayCard = this.question.game.state.cards.find(function (c) {
                    return c.cardName.isWay();
                });
                if (!isUndefined(wayCard)) {
                    var wayName = wayCard.cardName;
                    var artURL = getFullArtURL(wayName.changedImage);
                    Preloader.load(artURL, function (source) {
                        ctx.strokeStyle = "#2FB4DC";
                        ctx.lineWidth = 0.02 * h;
                        ctx.rect(0.60 * w, 0.3 * h, 0.39 * w, 0.69 * h);
                        ctx.stroke();
                        ctx.globalAlpha = _this18.wayHovered ? 0.8 : 1;
                        ctx.drawImage(source, 0.60 * w, 0.3 * h, 0.39 * w, 0.69 * h);
                        ctx.globalAlpha = 1;
                        _this18.redrawTitle();
                    });
                } else {
                    this.redrawTitle();
                }
            } else {
                this.redrawTitle();
            }
        }
    }, {
        key: "redrawTitle",
        value: function redrawTitle() {
            var _ref10 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref10[0],
                h = _ref10[1],
                ctx = _ref10[2];

            ctx.beginPath();
            ctx.rect(0, 0, w, 0.3 * h);
            ctx.fillStyle = "black";
            ctx.globalAlpha = 0.6;
            ctx.fill();
            ctx.globalAlpha = 0.8 + 0.15 * Curves.SYMMETRIC.getYFromX(this.hoverState);
            this.redrawText();
        }
    }, {
        key: "redrawText",
        value: function redrawText() {
            var _ref11 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref11[0],
                h = _ref11[1],
                ctx = _ref11[2];

            var titleFont = LANGUAGE.getEnvironment.hasOwnProperty("titleFont") ? LANGUAGE.getEnvironment.titleFont : "TrajanPro-Bold";
            ctx.fillStyle = "white";
            var offset = this.count > 1 ? 0.33 * h : 0;
            var maxWidth = this.count > 1 && this.ability.name.hasAllButton ? 0.65 * w : 0.85 * w;
            drawFittedText(ctx, this.text, new Rectangle(offset + 0.07 * w, 0.09 * h, maxWidth - offset, 0.20 * h), 1, titleFont, "", false, false);

            if (this.count > 1 && this.ability.name.hasAllButton) {
                ctx.fillStyle = this.allHovered ? "#FFCB5C" : "white";
                drawFittedText(ctx, "(" + getPhrase(Phrases.ALL) + ")", new Rectangle(0.8 * w, 0.1 * h, 0.2 * w, 0.2 * h), 0.8, titleFont, "", false, false);
            }
            this.redrawIcon();
        }
    }, {
        key: "redrawIcon",
        value: function redrawIcon() {
            var _this19 = this;

            var _ref12 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref12[0],
                h = _ref12[1],
                ctx = _ref12[2];

            var artBbox = new Rectangle(w - 0.7 * h, 0.3 * h, 0.7 * h, 0.7 * h);
            if (this.iconURL === null) return this.redrawHints();
            Preloader.load(this.iconURL, function (source) {
                ctx.fillStyle = "rgba(0, 0, 0, 0.3)";
                ctx.beginPath();
                ctx.rect(w - 0.7 * h, 0.3 * h, 0.7 * h, 0.7 * h);
                ctx.fill();
                ctx.drawImage(source, artBbox.x, artBbox.y, artBbox.width, artBbox.height);
                _this19.redrawHints();
            });
        }
    }, {
        key: "redrawHints",
        value: function redrawHints() {
            var _this20 = this;

            var _ref13 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref13[0],
                h = _ref13[1],
                ctx = _ref13[2];

            var width = 0.4 * h;
            var height = 0.3 * h;
            var x = 0.05 * w - width;
            var y = 0.95 * h - height;
            if (this.hints.length === 0) this.redrawCount();else {
                var arts = this.hints.map(function (h) {
                    return getMiniArtURL(h.cardName);
                });
                Preloader.loadAll(arts, function (sources) {
                    var blockCount = 0;
                    _this20.hints.forEach(function (_ref14, index) {
                        var cardName = _ref14.cardName,
                            repeat = _ref14.repeat;

                        var source = sources[index];
                        if (x + width > w) return;
                        if (repeat) y -= (y - 0.35 * h) * 0.1;else {
                            x += width + 0.03 * h;
                            y = 0.95 * h - height;
                            blockCount = 0;
                        }
                        ctx.drawImage(source, x, y, width, height);
                        ctx.strokeStyle = "white";
                        ctx.lineWidth = 0.01 * h;
                        ctx.beginPath();
                        ctx.rect(x, y, width, height);
                        ctx.stroke();
                        blockCount++;

                        if (index === _this20.hints.length - 1 || !_this20.hints[index + 1].repeat) {
                            ctx.fillStyle = "white";
                            ctx.strokeStyle = "black";
                            if (blockCount > 1) drawFittedText(ctx, blockCount, new Rectangle(x, y, width, height), 0.9, "Montserrat", "", true);
                        }
                    });
                    _this20.redrawCount();
                });
            }
        }
    }, {
        key: "redrawCount",
        value: function redrawCount() {
            var _this21 = this;

            var _ref15 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref15[0],
                h = _ref15[1],
                ctx = _ref15[2];

            if (this.count <= 1) return this.redrawBorder();
            var artURL = 'images/elements/card-counter.png';
            Preloader.load(artURL, function (source) {
                var size = h * 0.33;
                ctx.drawImage(source, 0, 0, size, size);
                ctx.fillStyle = "white";
                drawFittedText(ctx, _this21.count.toString(), new Rectangle(size * 0.1, size * 0.1, size * 0.8, size * 0.8), 1, "Montserrat");
                _this21.redrawBorder();
            });
        }
    }, {
        key: "redrawBorder",
        value: function redrawBorder() {
            var _ref16 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref16[0],
                h = _ref16[1],
                ctx = _ref16[2];

            ctx.beginPath();
            ctx.rect(0, 0, w, h);
            ctx.strokeStyle = this.isOptional ? "white" : "#3CA159";
            ctx.lineWidth = h * 0.05;
            ctx.stroke();
        }
    }, {
        key: "height",
        get: function get() {
            return 8.8;
        }
    }, {
        key: "sortingGroup",
        get: function get() {
            return 0;
        }
    }]);

    return AbilityButtonView;
}(GameButtonView);

var NumericButtonView = function (_RenderableObject2) {
    _inherits(NumericButtonView, _RenderableObject2);

    function NumericButtonView(question) {
        var hasSubmit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        _classCallCheck(this, NumericButtonView);

        var _this22 = _possibleConstructorReturn(this, (NumericButtonView.__proto__ || Object.getPrototypeOf(NumericButtonView)).call(this));

        _this22.question = question;
        _this22.visibility = true;
        _this22.answer = question.defaultAnswer;
        _this22.min = question.min;
        _this22.max = question.max;
        _this22.hasSubmit = hasSubmit;

        _this22.ticks = [];
        for (var i = _this22.min; i <= _this22.max; i++) {
            _this22.ticks[i] = 0;
        }
        _this22.ticks[_this22.answer] = 1;

        _this22.sliderState = 0;
        _this22.sliderClicked = false;

        _this22.buttonState = 0;
        _this22.buttonHovered = false;
        return _this22;
    }

    _createClass(NumericButtonView, [{
        key: "render",
        value: function render(node) {
            var _this23 = this;

            _get(NumericButtonView.prototype.__proto__ || Object.getPrototypeOf(NumericButtonView.prototype), "render", this).call(this, node);
            this.canvas.onmousedown = function (e) {
                _this23.onMouseDown(e);
            };
            this.canvas.ontouchstart = function (e) {
                if (e.touches.length > 0) _this23.onMouseDown(e.touches[0]);
            };
            if (this.hasSubmit) {
                this.canvas.onclick = function (e) {
                    var offsetX = 2 * (e.clientX - _this23.canvas.getBoundingClientRect().x);
                    var offsetY = 2 * (e.clientY - _this23.canvas.getBoundingClientRect().y);
                    var oldState = _this23.buttonHovered;
                    if (_this23.buttonBbox.contains(offsetX, offsetY)) {
                        _this23.question.answer(_this23.answer);
                    }
                };
                this.canvas.onmousemove = function (e) {
                    var offsetX = 2 * (e.clientX - _this23.canvas.getBoundingClientRect().x);
                    var offsetY = 2 * (e.clientY - _this23.canvas.getBoundingClientRect().y);
                    var oldState = _this23.buttonHovered;
                    if (_this23.buttonBbox.contains(offsetX, offsetY)) {
                        _this23.buttonHovered = true;
                    } else {
                        _this23.buttonHovered = false;
                    }
                    if (_this23.buttonHovered !== oldState) _this23.tick();
                };
                this.canvas.onmouseout = function (e) {
                    _this23.buttonHovered = false;
                    _this23.tick();
                };
            }
            this.redraw();
        }
    }, {
        key: "onMouseDown",
        value: function onMouseDown(e) {
            var _this24 = this;

            var _ref17 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref17[0],
                h = _ref17[1],
                ctx = _ref17[2];

            var offsetX = 2 * (e.clientX - this.canvas.getBoundingClientRect().x);
            var offsetY = 2 * (e.clientY - this.canvas.getBoundingClientRect().y);
            var sliderLeft = 0.05 * w;
            var sliderRight = this.hasSubmit ? 0.9 * w - 1.5 * h : 0.9 * w;
            if (!this.hasSubmit || offsetX < this.buttonBbox.x) {
                if (!this.sliderBbox.contains(offsetX, offsetY)) {
                    if (this.max > this.min) {
                        var i = (offsetX - sliderLeft) / (sliderRight - sliderLeft) * (2 * (this.max - this.min));
                        i = Math.min(2 * (this.max - this.min), Math.max(0, i));
                        this.answer = this.min + Math.floor((i + 1) / 2);
                    }
                }
                this.sliderClicked = true;
                this.tick();
                var moveHandler = function moveHandler(clientX, clientY) {
                    var moveX = 2 * (clientX - _this24.canvas.getBoundingClientRect().x);
                    var moveY = 2 * (clientY - _this24.canvas.getBoundingClientRect().y);
                    if (_this24.max > _this24.min) {
                        var _i = (moveX - sliderLeft) / (sliderRight - sliderLeft) * (2 * (_this24.max - _this24.min));
                        _i = Math.min(2 * (_this24.max - _this24.min), Math.max(0, _i));
                        var newAnswer = _this24.min + Math.floor((_i + 1) / 2);
                        if (newAnswer !== _this24.answer) {
                            _this24.answer = newAnswer;
                            _this24.tick();
                        }
                    }
                };
                var mouseMoveHandler = function mouseMoveHandler(e) {
                    return moveHandler(e.clientX, e.clientY);
                };
                var touchMoveHandler = function touchMoveHandler(e) {
                    return moveHandler(e.touches[0].clientX, e.touches[0].clientY);
                };
                var dropHandler = function dropHandler() {
                    _this24.sliderClicked = false;
                    _this24.tick();
                    document.removeEventListener("mousemove", mouseMoveHandler);
                    document.removeEventListener("touchmove", touchMoveHandler);
                    document.removeEventListener("mouseup", dropHandler);
                    document.removeEventListener("touchend", dropHandler);
                    document.removeEventListener("touchcancel", dropHandler);
                };
                document.addEventListener("mousemove", mouseMoveHandler);
                document.addEventListener("touchmove", touchMoveHandler);
                document.addEventListener("mouseup", dropHandler);
                document.addEventListener("touchend", dropHandler);
                document.addEventListener("touchcancel", dropHandler);
            }
        }
    }, {
        key: "hoverHandler",
        value: function hoverHandler() {
            this.redraw();
        }
    }, {
        key: "tick",
        value: function tick() {
            var _this25 = this;

            var keepTicking = false;
            if (this.buttonHovered) {
                this.buttonState += 0.1;
                if (this.buttonState > 1) this.buttonState = 1;else keepTicking = true;
            } else {
                this.buttonState -= 0.1;
                if (this.buttonState < 0) this.buttonState = 0;else keepTicking = true;
            }

            if (this.sliderClicked) {
                this.sliderState += 0.1;
                if (this.sliderState > 1) this.sliderState = 1;else keepTicking = true;
            } else {
                this.sliderState -= 0.1;
                if (this.sliderState < 0) this.sliderState = 0;else keepTicking = true;
            }
            this.ticks.forEach(function (t, i) {
                if (i === _this25.answer) {
                    _this25.ticks[i] += 0.15;
                    if (_this25.ticks[i] > 1) {
                        _this25.ticks[i] = 1;
                    } else {
                        keepTicking = true;
                    }
                } else {
                    _this25.ticks[i] -= 0.15;
                    if (_this25.ticks[i] < 0) {
                        _this25.ticks[i] = 0;
                    } else {
                        keepTicking = true;
                    }
                }
            });
            this.hoverHandler();
            if (keepTicking) requestAnimationFrame(function () {
                return _this25.tick();
            });
        }
    }, {
        key: "repositionCanvas",
        value: function repositionCanvas(x, y) {
            this.canvas.style.transform = "translateX(" + x + "px) translateY(" + y + "px)";
        }
    }, {
        key: "resizeCanvas",
        value: function resizeCanvas() {
            var vh = window.innerHeight / 100;
            var width = this.width * vh;
            var height = this.height * vh;

            this.canvas.style.position = "fixed";
            this.canvas.style.width = width + "px";
            this.canvas.style.height = height + "px";
            this.canvas.style.zIndex = 10000;

            this.canvas.width = width * 2;
            this.canvas.height = height * 2;
            this.canvas.style.cursor = "pointer";
        }
    }, {
        key: "redrawBackground",
        value: function redrawBackground() {
            var _ref18 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref18[0],
                h = _ref18[1],
                ctx = _ref18[2];

            var r = h * 0.1;
            ctx.lineWidth = r / 3;
            ctx.fillStyle = "black";
            ctx.strokeStyle = "white";
            ctx.clearRect(0, 0, w, h);
            ctx.beginPath();
            ctx.rect(0, 0, w, h);
            ctx.globalAlpha = 0.8 + 0.15 * Curves.SYMMETRIC.getYFromX(this.hoverState);
            ctx.fill();
            ctx.stroke();
            ctx.globalAlpha = 1;

            this.redrawSlider();
        }
    }, {
        key: "redrawSlider",
        value: function redrawSlider() {
            var _this26 = this;

            var _ref19 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref19[0],
                h = _ref19[1],
                ctx = _ref19[2];

            var sliderLeft = 0.05 * w;
            var sliderRight = this.hasSubmit ? 0.9 * w - 1.5 * h : 0.9 * w;
            var getX = function getX(i) {
                return _this26.max - _this26.min === 0 ? (sliderLeft + sliderRight) / 2 : sliderLeft + (i - _this26.min) * (sliderRight - sliderLeft) / (_this26.max - _this26.min);
            };
            var pointerX = getX(this.answer);
            ctx.lineWidth = h * 0.05;
            ctx.strokeStyle = "#28F0FF";
            ctx.beginPath();
            ctx.moveTo(sliderLeft, 0.5 * h);
            ctx.lineTo(pointerX, 0.5 * h);
            ctx.stroke();
            ctx.strokeStyle = "#2D4064";
            ctx.beginPath();
            ctx.moveTo(pointerX, 0.5 * h);
            ctx.lineTo(sliderRight, 0.5 * h);
            ctx.stroke();

            var tickTop = 0.55 * h;
            var tickHeight = 0.1 * h;
            this.ticks.forEach(function (value, i) {
                var baseR = i > _this26.answer ? 45 : 40;
                var baseG = i > _this26.answer ? 65 : 240;
                var baseB = i > _this26.answer ? 100 : 255;
                ctx.strokeStyle = rgbToHex(baseR * (1 - value) + 200 * value, baseG * (1 - value) + 65 * value, baseB * (1 - value) + 80 * value);
                var x = getX(i);
                ctx.beginPath();
                ctx.moveTo(x, tickTop);
                ctx.lineTo(x, tickHeight * (1 + value) + tickTop);
                ctx.stroke();
            });

            var titleFont = LANGUAGE.getEnvironment.hasOwnProperty("titleFont") ? LANGUAGE.getEnvironment.titleFont : "TrajanPro-Bold";
            ctx.fillStyle = "#AAA";
            drawFittedText(ctx, this.question.leftLabel, new Rectangle(sliderLeft - 0.04 * w, 0.25 * h, 0.08 * w, 0.2 * h), 1, titleFont);
            drawFittedText(ctx, this.question.rightLabel, new Rectangle(sliderRight - 0.04 * w, 0.25 * h, 0.08 * w, 0.2 * h), 1, titleFont);

            ctx.strokeStyle = "white";
            ctx.fillStyle = rgbToHex(45, 55 + 150 * this.sliderState, 80 - 30 * this.sliderState);
            ctx.beginPath();
            ctx.arc(pointerX, 0.5 * h, 0.12 * h, 2 * Math.PI, false);
            this.sliderBbox = new Rectangle(getX(this.answer) - 0.1 * w, 0.45 * h, 0.2 * w, 0.3 * h);
            ctx.fill();
            ctx.stroke();

            if (this.hasSubmit) {
                ctx.strokeStyle = "white";
                ctx.lineWidth = h * 0.03;
                var value = this.buttonState;
                ctx.fillStyle = rgbToHex(20 * (1 - value) + 65 * value, 20 * (1 - value) + 200 * value, 20 * (1 - value) + 80 * value);
                ctx.beginPath();
                ctx.rect(0.99 * w - 1.47 * h, 0.065 * h, 1.47 * h, 0.87 * h);
                this.buttonBbox = new Rectangle(0.99 * w - 1.47 * h, 0.065 * h, 1.47 * h, 0.87 * h);
                ctx.fill();
                ctx.fillStyle = "white";
                drawFittedText(ctx, this.question.getLabel(this.answer), this.buttonBbox.offset(new Rectangle(h * 0.1, h * 0.05, -2 * h * 0.1, -h * 0.05)), 1, titleFont);
                ctx.stroke();
            } else {
                ctx.fillStyle = "white";
                drawFittedText(ctx, this.question.getLabel(this.answer), new Rectangle(sliderLeft + 0.1 * w, 0.1 * h, sliderRight - sliderLeft - 0.2 * w, 0.3 * h), 1, titleFont);
            }
        }
    }, {
        key: "redraw",
        value: function redraw() {
            this.resizeCanvas();
            this.redrawBackground();
        }
    }, {
        key: "hasNormalLayout",
        get: function get() {
            return true;
        }
    }, {
        key: "sortingGroup",
        get: function get() {
            return 1;
        }
    }, {
        key: "width",
        get: function get() {
            return this.hasSubmit ? 35 : 28;
        }
    }, {
        key: "height",
        get: function get() {
            return 7;
        }
    }, {
        key: "answer",
        set: function set(value) {
            this.privateAnswer = value;
            this.question.accumulatedAnswers = [value];
        },
        get: function get() {
            return this.privateAnswer;
        }
    }]);

    return NumericButtonView;
}(RenderableObject);

var BundledDoneButtonView = function (_DoneGameButtonView) {
    _inherits(BundledDoneButtonView, _DoneGameButtonView);

    function BundledDoneButtonView(question) {
        _classCallCheck(this, BundledDoneButtonView);

        return _possibleConstructorReturn(this, (BundledDoneButtonView.__proto__ || Object.getPrototypeOf(BundledDoneButtonView)).call(this, question, "", "images/buttons/accept.png"));
    }

    _createClass(BundledDoneButtonView, [{
        key: "resizeCanvas",
        value: function resizeCanvas() {
            _get(BundledDoneButtonView.prototype.__proto__ || Object.getPrototypeOf(BundledDoneButtonView.prototype), "resizeCanvas", this).call(this);
            this.canvas.style.cursor = this.enabled ? "pointer" : "default";
        }
    }, {
        key: "onClick",
        value: function onClick() {
            this.question.send();
        }
    }, {
        key: "redrawIcon",
        value: function redrawIcon() {
            var _ref20 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref20[0],
                h = _ref20[1],
                ctx = _ref20[2];

            var artBbox = new Rectangle(0, 0, h, h);
            if (isUndefined(this.icon)) return;
            Preloader.load(this.icon, function (source) {
                ctx.drawImage(source, artBbox.x, artBbox.y, artBbox.width, artBbox.height);
            });
        }
    }, {
        key: "redraw",
        value: function redraw() {
            _get(BundledDoneButtonView.prototype.__proto__ || Object.getPrototypeOf(BundledDoneButtonView.prototype), "redraw", this).call(this);
            this.redrawIcon();
        }
    }, {
        key: "sortingGroup",
        get: function get() {
            return 2;
        }
    }, {
        key: "label",
        set: function set(value) {
            this.privateLabel = value;
            this.redraw();
        }
    }, {
        key: "width",
        get: function get() {
            return 7;
        }
    }, {
        key: "height",
        get: function get() {
            return 7;
        }
    }, {
        key: "enabled",
        get: function get() {
            return true;
        }
    }]);

    return BundledDoneButtonView;
}(DoneGameButtonView);

var ExtraTurnButtonView = function (_GameButtonView4) {
    _inherits(ExtraTurnButtonView, _GameButtonView4);

    function ExtraTurnButtonView(question, answer, description) {
        _classCallCheck(this, ExtraTurnButtonView);

        var _this28 = _possibleConstructorReturn(this, (ExtraTurnButtonView.__proto__ || Object.getPrototypeOf(ExtraTurnButtonView)).call(this, question, answer, LANGUAGE.getCardMode[description.type]));

        _this28.description = description;
        _this28.bgURL = getFullArtURL(_this28.description.type.cardName);
        _this28.count = 0;
        return _this28;
    }

    _createClass(ExtraTurnButtonView, [{
        key: "redrawBackground",
        value: function redrawBackground() {
            var _this29 = this;

            var _ref21 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref21[0],
                h = _ref21[1],
                ctx = _ref21[2];

            ctx.clearRect(0, 0, w, h);
            Preloader.load(this.bgURL, function (source) {
                ctx.globalAlpha = 0.8 + 0.15 * Curves.SYMMETRIC.getYFromX(_this29.hoverState);
                var sourceH = source.width * h / w;
                ctx.drawImage(source, 0, (source.height - sourceH) / 2, source.width, sourceH, 0, 0, w, h);

                _this29.redrawTitle();
            });
        }
    }, {
        key: "redrawTitle",
        value: function redrawTitle() {
            var _ref22 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref22[0],
                h = _ref22[1],
                ctx = _ref22[2];

            ctx.beginPath();
            ctx.rect(0, 0, w, 0.3 * h);
            ctx.fillStyle = "black";
            ctx.globalAlpha = 0.6;
            ctx.fill();
            ctx.globalAlpha = 0.8 + 0.15 * Curves.SYMMETRIC.getYFromX(this.hoverState);
            ctx.beginPath();
            ctx.rect(0, 0, w, h);
            ctx.strokeStyle = "white";
            ctx.lineWidth = h * 0.05;
            ctx.stroke();
            this.redrawText();
        }
    }, {
        key: "redrawText",
        value: function redrawText() {
            var _ref23 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref23[0],
                h = _ref23[1],
                ctx = _ref23[2];

            var titleFont = LANGUAGE.getEnvironment.hasOwnProperty("titleFont") ? LANGUAGE.getEnvironment.titleFont : "TrajanPro-Bold";
            ctx.fillStyle = "white";
            var offset = this.count > 1 ? 0.33 * h : 0;
            drawFittedText(ctx, this.text, new Rectangle(offset + 0.07 * w, 0.09 * h, 0.85 * w - offset, 0.20 * h), 1, titleFont, "", false, false);
            this.redrawCount();
        }
    }, {
        key: "redrawCount",
        value: function redrawCount() {
            var _this30 = this;

            var _ref24 = [this.canvas.width, this.canvas.height, this.context],
                w = _ref24[0],
                h = _ref24[1],
                ctx = _ref24[2];

            if (this.count <= 1) return;
            var artURL = 'images/elements/card-counter.png';
            Preloader.load(artURL, function (source) {
                var size = h * 0.33;
                ctx.drawImage(source, 0, 0, size, size);
                ctx.fillStyle = "white";
                drawFittedText(ctx, _this30.count.toString(), new Rectangle(size * 0.1, size * 0.1, size * 0.8, size * 0.8), 1, "Montserrat");
            });
        }
    }, {
        key: "height",
        get: function get() {
            return 8.8;
        }
    }, {
        key: "sortingGroup",
        get: function get() {
            return 0;
        }
    }]);

    return ExtraTurnButtonView;
}(GameButtonView);