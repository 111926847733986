"use strict";

webclient.service('signUp', ['$rootScope', 'serverMessenger', function ($rootScope, serverMessenger) {
    var self = this;
    var scope = $rootScope.$new(true);
    var shouldShowForm = false;
    var shouldShowStatus = false;
    var signUpCode = "";

    self.shouldShow = function () {
        return shouldShowForm;
    };
    self.shouldShowStatus = function () {
        return shouldShowStatus;
    };

    self.send = function (accountInfo) {
        serverMessenger.createAccount(accountInfo, signUpCode);
        shouldShowForm = false;
        shouldShowStatus = true;
        if (signUpCode === "") return;
        window.history.pushState("", "", "index.html");
    };

    self.signUp = function () {
        var code = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";

        signUpCode = code;
        shouldShowForm = true;
    };

    function hide() {
        shouldShowForm = false;
        shouldShowStatus = false;
    }

    var signupErrors = [FailureReasons.DUPLICATE_USERNAME, FailureReasons.INVALID_USERNAME, FailureReasons.INVALID_PASSWORD, FailureReasons.INVALID_GENDER];

    self.isSignupError = function (failure) {
        return signupErrors.indexOf(failure.failureReason) > -1;
    };

    scope.$on(Events.SIGN_UP, function () {
        return shouldShowForm = true;
    });
    scope.$on(Events.CLOSE_SIGNUP_FORM, function () {
        return shouldShowForm = false;
    });
    scope.$on(Events.LOGIN_SUCCESS, function () {
        return hide();
    });
    scope.$on(Events.COMMAND_FAILED, function (event, failure) {
        if (shouldShowStatus) {
            shouldShowStatus = self.isSignupError(failure);
            shouldShowForm = true;
            $rootScope.safeApply();
        }
    });
}]);