"use strict";

webclient.service('languageSelection', [function () {
    var self = this;
    var _hasChangedLanguage = false;
    var codeToLanguage = new Map();
    var languageToCode = new Map();

    codeToLanguage.set('en', ENGLISH);
    codeToLanguage.set('ru', RUSSIAN);
    codeToLanguage.set('de', GERMAN);
    codeToLanguage.set('fr', FRENCH);
    codeToLanguage.set('jp', JAPANESE);
    codeToLanguage.set('tc', TCHINESE);
    codeToLanguage.set('nl', DUTCH);
    codeToLanguage.set('es', SPANISH);
    codeToLanguage.set('eo', ESPERANTO);
    codeToLanguage.set('cs', CZECH);
    codeToLanguage.forEach(function (value, key) {
        return languageToCode.set(value, key);
    });

    self.hasChangedLanguage = function () {
        return _hasChangedLanguage;
    };
    self.knowsLanguageCode = function (code) {
        return codeToLanguage.has(code);
    };
    self.getLanguageByCode = function (code) {
        return codeToLanguage.get(code);
    };

    self.select = function (language) {
        var change = language !== LANGUAGE;
        if (change) LANGUAGE.getEnvironment.leave();
        LANGUAGE = language;
        Object.keys(Phrases).forEach(function (key) {
            return PHRASES[key] = LANGUAGE.getPhrase[Phrases[key]];
        });
        PARAGRAPHS = LANGUAGE.paragraphs;
        if (change) LANGUAGE.getEnvironment.enter();
        _hasChangedLanguage = true;
        if (localStorageIsEnabled()) localStorage.language = languageToCode.get(language);
    };

    self.selectByCode = function (code) {
        var isChange = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        if (!self.knowsLanguageCode(code)) {
            self.selectDefault();
            return;
        }
        self.select(codeToLanguage.get(code));
    };

    self.selectDefault = function () {
        self.select(ENGLISH);
    };
}]);