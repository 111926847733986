"use strict";

var EsperantoPhrases = {};

EsperantoPhrases[Phrases.THE] = "la";
EsperantoPhrases[Phrases.THE_FROM] = "la";
EsperantoPhrases[Phrases.THE_TO] = "la";
EsperantoPhrases[Phrases.AND] = "kaj";
EsperantoPhrases[Phrases.OR] = "aŭ";
EsperantoPhrases[Phrases.OTHER] = "alia";
EsperantoPhrases[Phrases.ANOTHER] = "alia";
EsperantoPhrases[Phrases.PILE] = "stako";
EsperantoPhrases[Phrases.UNDO] = "Malfaru";
EsperantoPhrases[Phrases.YOU] = "Vi";
EsperantoPhrases[Phrases.WOULD] = "";
EsperantoPhrases[Phrases.YOU_MAY] = "Vi povas...";
EsperantoPhrases[Phrases.COMMA] = ", ";
EsperantoPhrases[Phrases.PERIOD] = ".";
EsperantoPhrases[Phrases.COMPLEX_STORY_LINE] = "FIRST, SECOND";
EsperantoPhrases[Phrases.COIN] = "Monero";
EsperantoPhrases[Phrases.COINS] = "Moneroj";
EsperantoPhrases[Phrases.COINS_MANY] = "Moneroj";
EsperantoPhrases[Phrases.POTION] = "Eliksiro";
EsperantoPhrases[Phrases.POTIONS] = "Eliksiroj";
EsperantoPhrases[Phrases.POTIONS_MANY] = "Eliksiroj";
EsperantoPhrases[Phrases.DEBT] = "Ŝuldo";

EsperantoPhrases[Phrases.VERSION] = "Versio";
EsperantoPhrases[Phrases.USERNAME] = "Salutnomo";
EsperantoPhrases[Phrases.PASSWORD] = "Pasvorto";
EsperantoPhrases[Phrases.CONFIRM_PASSWORD] = "Konfirmu pasvorton";
EsperantoPhrases[Phrases.FORGOT_YOUR_PASSWORD] = "Ĉu vi forgesis vian pasvorton?";
EsperantoPhrases[Phrases.CREATE_ACCOUNT] = "Kreu Konton";
EsperantoPhrases[Phrases.EMAIL] = "Retpoŝto";
EsperantoPhrases[Phrases.SIGN_UP] = "Enskribiĝu";
EsperantoPhrases[Phrases.LOGIN] = "Ensalutu";
EsperantoPhrases[Phrases.KICK] = "Elĵetu";
EsperantoPhrases[Phrases.RESIGN] = "Abdiku";
EsperantoPhrases[Phrases.CREATE_TABLE] = "Kreu Tablon";
EsperantoPhrases[Phrases.LEAVE_TABLE] = "Foriru Tablon";
EsperantoPhrases[Phrases.MESSAGE] = "mesaĝo";
EsperantoPhrases[Phrases.START_GAME] = "Komencu Ludon";
EsperantoPhrases[Phrases.READY] = "Preta";
EsperantoPhrases[Phrases.NOT_READY] = "Ne Preta";
EsperantoPhrases[Phrases.PLAYERS] = "Ludantoj";
EsperantoPhrases[Phrases.SPECTATORS] = "Spektantoj";
EsperantoPhrases[Phrases.HOST] = "Gastiganto";
EsperantoPhrases[Phrases.RANDOM] = "Hazarde";
EsperantoPhrases[Phrases.BASIC_OPTIONS] = "Bazaj Elektoj";
EsperantoPhrases[Phrases.ADVANCED_OPTIONS] = "Altnivelaj Elektoj";
EsperantoPhrases[Phrases.YES] = "Jes";
EsperantoPhrases[Phrases.NO] = "Ne";
EsperantoPhrases[Phrases.FRIENDS_ONLY] = "Nur Amikoj";
EsperantoPhrases[Phrases.EVENTS_AND_LANDMARKS] = "Okazoj kaj Rekoniloj";
EsperantoPhrases[Phrases.TRUE] = "vera";
EsperantoPhrases[Phrases.FALSE] = "malvera";
EsperantoPhrases[Phrases.QUICK_MATCH] = "Rapida Matĉo";
EsperantoPhrases[Phrases.GOOD_MATCH] = "Bona Matĉo";
EsperantoPhrases[Phrases.WAIT_FOR_EXPANSIONS] = "Atendu Etendigojn";
EsperantoPhrases[Phrases.CANCEL] = "Nuligu";
EsperantoPhrases[Phrases.VIEW_TRASH] = "Rubujo";
EsperantoPhrases[Phrases.VIEW_KINGDOM] = "Regno";
EsperantoPhrases[Phrases.VIEW_PLAY_AREA] = "Ludloko";
EsperantoPhrases[Phrases.LEAVE] = "Foriru";
EsperantoPhrases[Phrases.TOTAL_IN_QUEUE] = "Totalaj uzantoj atendantaj";
EsperantoPhrases[Phrases.EDIT_TABLE] = "Redaktu Tablon";
EsperantoPhrases[Phrases.NEED_HELP] = "Ĉu vi bezonas helpon?";
EsperantoPhrases[Phrases.PLAY] = "Ludu";
EsperantoPhrases[Phrases.MAKE_RESIGN] = "Ĉu igu USERNAME abdiki?";
EsperantoPhrases[Phrases.UNDO_REQUEST_SINGULAR] = "Petu malfari 1 paŝon";
EsperantoPhrases[Phrases.UNDO_REQUEST_PLURAL] = "Petu malfari NUMBER paŝojn";
EsperantoPhrases[Phrases.UNDO_REQUEST_MANY] = "Petu malfari NUMBER paŝojn";
EsperantoPhrases[Phrases.PENDING_UNDO_REQUEST] = "Antendante malfarpeton...";
EsperantoPhrases[Phrases.SPECTATOR_UNDO_REQUEST] = "USERNAME petis malfaron";
EsperantoPhrases[Phrases.GRANT] = "Permesu";
EsperantoPhrases[Phrases.ACCEPT] = "Konsentu";
EsperantoPhrases[Phrases.DECLINE] = "Rifuzu";
EsperantoPhrases[Phrases.DENY] = "Forneu";
EsperantoPhrases[Phrases.UNDO_DENIED] = "Malfarpeto estis forneita.";
EsperantoPhrases[Phrases.UNDO_CANCELLED] = "Malfarpeto estis nuligita.";
EsperantoPhrases[Phrases.TOC_SENTENCE] = "Mi legis kaj akceptas la TERMS_OF_SERVICE.";
EsperantoPhrases[Phrases.TERMS_OF_SERVICE] = "Reguloj de Servado";
EsperantoPhrases[Phrases.TERMS_OF_SERVICE_URL] = ""; /* edit it after writing translated terms of service */
EsperantoPhrases[Phrases.RECONNECTING] = "Rekonektas...";
EsperantoPhrases[Phrases.PLAYER_JOINED_TABLE] = "PLAYER_NAME aliĝis la tablon.";
EsperantoPhrases[Phrases.PLAYER_LEFT_TABLE] = "PLAYER_NAME foriris la tablon.";
EsperantoPhrases[Phrases.JOINING_GAME_MESSAGE] = "Aliĝante ludon #NUMBER sur GAMESERVER_NAME.";
EsperantoPhrases[Phrases.SIGNING_UP] = "Enskribigante vin...";
EsperantoPhrases[Phrases.ACTION] = "Ago";
EsperantoPhrases[Phrases.ACTIONS] = "Agoj";
EsperantoPhrases[Phrases.BUY] = "Aĉeto";
EsperantoPhrases[Phrases.BUYS] = "Aĉetoj";
EsperantoPhrases[Phrases.KICK_SUCCEEDED] = "Elĵeto Sukcesis";
EsperantoPhrases[Phrases.CONNECTION_CLOSED] = "Servilkonekto estis fermita aŭ ne povis esti establita.";
EsperantoPhrases[Phrases.NEW_PASSWORD] = "Nova Pasvorto";
EsperantoPhrases[Phrases.RETRY] = "Reprovu";
EsperantoPhrases[Phrases.FRIEND] = "Amiko";
EsperantoPhrases[Phrases.ACTIVITY] = "Agado";
EsperantoPhrases[Phrases.STATUS] = "Stato";
EsperantoPhrases[Phrases.PURCHASE] = "Aĉeto";
EsperantoPhrases[Phrases.UNTIL] = "ĝis";
EsperantoPhrases[Phrases.BUY_FOR_AMOUNT] = "Aĉetu por AMOUNT";
EsperantoPhrases[Phrases.CHANGE_PASSWORD] = "Ŝanĝu";
EsperantoPhrases[Phrases.HIDE] = "Kaŝu";
EsperantoPhrases[Phrases.CONFIRM] = "Konfirmu";
EsperantoPhrases[Phrases.JOIN] = "aliĝu";
EsperantoPhrases[Phrases.TURN] = "Vico";
EsperantoPhrases[Phrases.CARD] = "Karto";
EsperantoPhrases[Phrases.DESCRIPTION] = "Priskribo";
EsperantoPhrases[Phrases.SCORE] = "Poentaro";
EsperantoPhrases[Phrases.TURNS] = "Vicoj";
EsperantoPhrases[Phrases.NOTHING] = "nenio";
EsperantoPhrases[Phrases.SPECTATE] = "spektatu";
EsperantoPhrases[Phrases.FRIENDS] = "Amikoj";
EsperantoPhrases[Phrases.FOLLOWING] = "Sekvante";
EsperantoPhrases[Phrases.FOLLOWERS] = "Sekvantoj";
EsperantoPhrases[Phrases.UNFRIEND] = "malamikiĝu";
EsperantoPhrases[Phrases.UNFOLLOW] = "malsekvu";
EsperantoPhrases[Phrases.BEFRIEND] = "amikiĝu";
EsperantoPhrases[Phrases.FRIENDS_EXPLANATION] = "Komuna amiko";
EsperantoPhrases[Phrases.FOLLOWING_EXPLANATION] = "Vi amikiĝis kun tiu";
EsperantoPhrases[Phrases.FOLLOWERS_EXPLANATION] = "Tiu amikiĝis kun vi";
EsperantoPhrases[Phrases.ADD] = "Aldonu";
EsperantoPhrases[Phrases.TYPE_FRIEND] = "Tajpu nomo de amiko";
EsperantoPhrases[Phrases.UNLOCKS] = "Malŝlosas";
EsperantoPhrases[Phrases.SELECT_YOUR_COUNTRY] = "Elektu vian landon";
EsperantoPhrases[Phrases.SELECT_A_COUNTRY] = "Elektu landon";
EsperantoPhrases[Phrases.KICK_AND_RESIGN] = "Elĵetu & Abdiku";
EsperantoPhrases[Phrases.BETWEEN_TURNS] = "Inter Vicoj";
EsperantoPhrases[Phrases.PLAYER] = "Ludanto";
EsperantoPhrases[Phrases.RESIGNED] = "abdikis";
EsperantoPhrases[Phrases.YOU_RESIGNED] = "Vi abdikis";
EsperantoPhrases[Phrases.YOU_LOSE] = "Vi malvenkas";
EsperantoPhrases[Phrases.YOU_TIE] = "Vi egalvenkas";
EsperantoPhrases[Phrases.YOU_WIN] = "Vi venkas";
EsperantoPhrases[Phrases.BUYS_MANY] = "Aĉetoj";
EsperantoPhrases[Phrases.ACTIONS_MANY] = "Agoj";
EsperantoPhrases[Phrases.TOKEN] = "Monrezervo";
EsperantoPhrases[Phrases.TOKENS] = "Monrezervoj";
EsperantoPhrases[Phrases.TOKENS_MANY] = "Monrezervoj";
EsperantoPhrases[Phrases.OVERPAY] = "Tropagu";
EsperantoPhrases[Phrases.BID] = "Proponu";
EsperantoPhrases[Phrases.TAKE] = "Prenu";
EsperantoPhrases[Phrases.BOTTOM] = "Funda";
EsperantoPhrases[Phrases.TOP] = "Supra";
EsperantoPhrases[Phrases.LOCATION] = "Loko";
EsperantoPhrases[Phrases.CLEAN_UP] = "Purigo";
EsperantoPhrases[Phrases.PLAN_CLEAN_UP] = "Planu vian Purigon";
EsperantoPhrases[Phrases.EXECUTE] = "Realigu";
EsperantoPhrases[Phrases.MOVEMENT_VERB] = "VERB.";
EsperantoPhrases[Phrases.MOVEMENT_VERB_OBJECT] = "VERB OBJECT.";
EsperantoPhrases[Phrases.FORUM] = "Forumo";
EsperantoPhrases[Phrases.VIEW_LOG] = "Raporto";
EsperantoPhrases[Phrases.REPLAY_GAME] = "Reludu Ludon";
EsperantoPhrases[Phrases.REMOVE_REPLAY] = "Forigu Reludon";
EsperantoPhrases[Phrases.SHELTERS] = "Ŝirmejoj";
EsperantoPhrases[Phrases.NO_SHELTERS] = "Neniuj Ŝirmejoj";
EsperantoPhrases[Phrases.COLONIES] = "Kolonioj";
EsperantoPhrases[Phrases.NO_COLONIES] = "Neniuj Kolonioj";
EsperantoPhrases[Phrases.PLAYER_ORDER] = "Lundantordo";
EsperantoPhrases[Phrases.ENTER_USERNAME] = "Bonvolu enskribu vian salutnomon.";
EsperantoPhrases[Phrases.ENTER_LOGIN_CREDENTIALS] = "Bonvolu enskribu viajn ensalutilojn.";
EsperantoPhrases[Phrases.SUBMIT] = "Sendu";
EsperantoPhrases[Phrases.DONE] = "Finita";
EsperantoPhrases[Phrases.GAME_ID] = "Identigilo de Ludo";
EsperantoPhrases[Phrases.DECISION_INDEX] = "Decidindiko";
EsperantoPhrases[Phrases.ORIGINAL_PLAYER] = "Originala Ludanto";
EsperantoPhrases[Phrases.ORIGINAL_PLAYERS] = "Originalaj Ludantoj";
EsperantoPhrases[Phrases.ADD_BOT] = "Aldonu Roboton";
EsperantoPhrases[Phrases.DECISION] = "Decido";
EsperantoPhrases[Phrases.LOAD_GAME] = "Ŝarĝu Ludon";
EsperantoPhrases[Phrases.CHANGE_REPLAY] = "Ŝanĝu Reludon";
EsperantoPhrases[Phrases.NAME_CARD] = "nomumu karton";
EsperantoPhrases[Phrases.LOAD_OLD_GAME] = "Ŝarĝu Malnovan Ludon";
EsperantoPhrases[Phrases.BOT_READY] = "Roboto Pretas";
EsperantoPhrases[Phrases.CANCEL_RESIGN] = "Nuligu & Abdiku";
EsperantoPhrases[Phrases.LOGOUT] = "Elsalutu";
EsperantoPhrases[Phrases.FIRST] = "Unua";
EsperantoPhrases[Phrases.LAST] = "Fina";
EsperantoPhrases[Phrases.INBOX] = "Ricevujo";
EsperantoPhrases[Phrases.MESSAGES] = "Mesaĝoj";
EsperantoPhrases[Phrases.MARK_READ] = "Marku ke estas legita";
EsperantoPhrases[Phrases.DELETE] = "Forstreku";
EsperantoPhrases[Phrases.NO_MESSAGES] = "Neniuj Mesaĝoj";
EsperantoPhrases[Phrases.LEADERBOARD] = "Ĉampionejo";
EsperantoPhrases[Phrases.LAST_UPDATED] = "Antaŭe Ĝisdatigis";
EsperantoPhrases[Phrases.RANK] = "#";
EsperantoPhrases[Phrases.NAME] = "nomo";
EsperantoPhrases[Phrases.RATING] = "nivelo";
EsperantoPhrases[Phrases.TREND] = "";
EsperantoPhrases[Phrases.VP] = "VP";
EsperantoPhrases[Phrases.GAME_ID_SHOULD_BE_NUMBER] = "ID de Ludo devas esti nomo.";
EsperantoPhrases[Phrases.FAQ] = "Oftaj Demandoj";
EsperantoPhrases[Phrases.RESULTS] = "Rezultoj";
EsperantoPhrases[Phrases.SKILL] = "μ";
EsperantoPhrases[Phrases.SKILL_RANGE] = "Lertogamo";
EsperantoPhrases[Phrases.DEVIATION] = "φ";
EsperantoPhrases[Phrases.VOLATILITY] = "σ";
EsperantoPhrases[Phrases.GAMES] = "Ludoj";
EsperantoPhrases[Phrases.GLICKO2] = "Glicko-2";
EsperantoPhrases[Phrases.IMPLEMENTATION] = "Realigo";
EsperantoPhrases[Phrases.RATED_QUEUE] = "Uzantoj en poenta atendovico";
EsperantoPhrases[Phrases.UNRATED_QUEUE] = "Uzantoj en malpoenta atendovico";
EsperantoPhrases[Phrases.UNRATED_MATCH] = "Nepoenta Matĉo";
EsperantoPhrases[Phrases.LEVEL] = "nivelo";
EsperantoPhrases[Phrases.TWO_PLAYERS] = "2 Ludantoj";
EsperantoPhrases[Phrases.THREE_FOUR_PLAYERS] = "3-4 Ludantoj";
EsperantoPhrases[Phrases.GAME] = "Ludo";
EsperantoPhrases[Phrases.RATED] = "poenta";
EsperantoPhrases[Phrases.UNRATED] = "nepoenta";
EsperantoPhrases[Phrases.OTHER_CARDS] = "aliajn";
EsperantoPhrases[Phrases.OTHER_CARD] = "alian";
EsperantoPhrases[Phrases.YOUR_TURN] = "Via vico!";
EsperantoPhrases[Phrases.LOADING_GAME] = "Ŝarĝas ludon...";
EsperantoPhrases[Phrases.BLACKLIST] = "Barlisto";
EsperantoPhrases[Phrases.REMOVE] = "Forigu";
EsperantoPhrases[Phrases.BLACKLIST_USER] = "Baru ĉi tiun uzanton";
EsperantoPhrases[Phrases.BEFRIEND_USER] = "Amikiĝu kun ĉi tiun uzanton";
EsperantoPhrases[Phrases.BLACKLISTED] = "Barita";
EsperantoPhrases[Phrases.FRIENDLIST_EMPTY] = "Via amiklisto estas nuntempe malplena.";
EsperantoPhrases[Phrases.BLACKLIST_EMPTY] = "Via barlisto estas nuntempe malplena.";
EsperantoPhrases[Phrases.PLAYERS_OF_TOTAL_PLAYERS] = "NUM_PLAYERS de NUM_TOTAL_PLAYERS";
EsperantoPhrases[Phrases.SHOW_RUNNING_GAMES] = "Montru ludatajn ludojn";
EsperantoPhrases[Phrases.SHOW_1P_GAMES] = "Montru 1-lundantajn ludojn";
EsperantoPhrases[Phrases.SHOW_2P_GAMES] = "Montru 2-ludantajn ludojn";
EsperantoPhrases[Phrases.SHOW_3XP_GAMES] = "Montru 3+-ludantajn ludojn";
EsperantoPhrases[Phrases.SHOW_BOT_GAMES] = "Montru robotajn ludojn";
EsperantoPhrases[Phrases.FIND_GAME] = "Trovu Ludon";
EsperantoPhrases[Phrases.FIND_BOT_GAME] = "Trovu Robotan Ludon";
EsperantoPhrases[Phrases.START_SEARCH] = "Startu serĉi";
EsperantoPhrases[Phrases.CANCEL_SEARCH] = "Nuligu serĉon";
EsperantoPhrases[Phrases.SEARCHING_2P] = "2-ludanta";
EsperantoPhrases[Phrases.SEARCHING_3P] = "3-ludanta";
EsperantoPhrases[Phrases.SEARCHING_RATED] = "Poenta ludo";
EsperantoPhrases[Phrases.SEARCHING_PRACTICE] = "Ekzerca ludo";
EsperantoPhrases[Phrases.MAX] = "Maks";
EsperantoPhrases[Phrases.MIN] = "Min";
EsperantoPhrases[Phrases.SEARCHING] = "serĉas";
EsperantoPhrases[Phrases.START_BOT_GAME_1] = "1 Roboto";
EsperantoPhrases[Phrases.START_BOT_GAME_2] = "2 Robotoj";
EsperantoPhrases[Phrases.START_BOT_GAME_3] = "3 Robotoj";
EsperantoPhrases[Phrases.NO_FRIENDS_ONLINE] = "Neniuj enretaj amikoj";
EsperantoPhrases[Phrases.AUTOMATCH_FEEDBACK] = "Atendu ludon";
EsperantoPhrases[Phrases.NOBODY_ELSE_IN_QUEUE] = "Neniu pli atendas.";
EsperantoPhrases[Phrases.CANCEL_SMALL] = "Nuligu";
EsperantoPhrases[Phrases.MESSAGE_SPECTATORS] = "mesaĝu al spektantojn";
EsperantoPhrases[Phrases.MESSAGE_PLAYERS] = "mesaĝu al ludantojn";
EsperantoPhrases[Phrases.PLAYER_REPLACED] = "LEAVING_PLAYER estis anstataŭita de REPLACEMENT.";
EsperantoPhrases[Phrases.RESIGN_PROMPT] = "Ĉu abdiku?";
EsperantoPhrases[Phrases.RESIGN_MULTIPLE_OPPONENTS] = "Averto: Abdiki ludon kun multaj kontraŭuloj povus esti opiniita malĝentila ludo.";
EsperantoPhrases[Phrases.CONTINUE_WITH_BOTS] = "Daŭrigu kun robotoj";
EsperantoPhrases[Phrases.PLAYER_DISCONNECTED] = "PLAYER_NAME malkonektis.";
EsperantoPhrases[Phrases.PLAYER_RECONNECTED] = "PLAYER_NAME rekonektis.";
EsperantoPhrases[Phrases.VALIDATE_BONUS_CODE] = "Validigu";
EsperantoPhrases[Phrases.NEW_TO_DOMINION] = "Ludreguloj";
EsperantoPhrases[Phrases.SEARCHING_PREVIEWS] = "Antaŭrigardoj de Bestaro";
EsperantoPhrases[Phrases.AUTOPLAY_CLEANUP] = "Aŭtoludoj de Purigfazo";
EsperantoPhrases[Phrases.AUTOPLAY_INTERFACE] = "Defaŭltaj aŭtoludoj";
EsperantoPhrases[Phrases.AUTOPLAY_OTHER] = "Aliaj aŭtoludoj";
EsperantoPhrases[Phrases.GAME_ENDED] = "La ludo finas.";
EsperantoPhrases[Phrases.OK] = "Bone";
EsperantoPhrases[Phrases.HAS_RESIGNED] = "PLAYER_NAME abdikis.";
EsperantoPhrases[Phrases.MOUSE_TOY] = "Flankenmetita karto por Vojo de la Muso";
// Intro page of Store tab in Lobby
EsperantoPhrases[Phrases.STORE_WELCOME] = "Vendejo de Dominion Online";
EsperantoPhrases[Phrases.ONE_MONTH] = "1 monato";
EsperantoPhrases[Phrases.TWO_MONTHS] = "2 monatoj";
EsperantoPhrases[Phrases.THREE_MONTHS] = "3 monatoj";
EsperantoPhrases[Phrases.SIX_MONTHS] = "6 monatoj";
EsperantoPhrases[Phrases.NINE_MONTHS] = "9 monatoj";
EsperantoPhrases[Phrases.FIFTEEN_MONTHS] = "15 monatoj";
EsperantoPhrases[Phrases.EIGHTEEN_MONTHS] = "18 monatoj";
EsperantoPhrases[Phrases.ONE_YEAR] = "1 jaro";
EsperantoPhrases[Phrases.TWO_YEAR] = "2 jaroj";
EsperantoPhrases[Phrases.CHOOSE_SUBSCRIPTION] = "Elektu vian abonon: ";
EsperantoPhrases[Phrases.CORE_SUBSCRIPTION] = "Core subscription";
EsperantoPhrases[Phrases.COMPLETE_SUBSCRIPTION] = "Complete subscription";
EsperantoPhrases[Phrases.CORE_SUBSCRIPTION_PRICE] = "(€ 2.15 / month)";
EsperantoPhrases[Phrases.CORE_SUBSCRIPTION_EXPLANATION] = "The simpler half of the cards";
EsperantoPhrases[Phrases.COMPLETE_SUBSCRIPTION_PRICE] = "(€ 4.30 / month)";
EsperantoPhrases[Phrases.COMPLETE_SUBSCRIPTION_EXPLANATION] = "All the cards";
EsperantoPhrases[Phrases.CHOOSE_DURATION] = "Elektu la daŭron de via abono, ekde hodiaŭ.";
EsperantoPhrases[Phrases.CONTINUE] = "Jesigu";
EsperantoPhrases[Phrases.ADD_LATEST_EXPANSION] = "Ĉu vi volas aldonu Bestaron al via nuntempa abono?";
EsperantoPhrases[Phrases.INTRODUCTION_TO_STORE] = "Enkonduko al vendejo";
EsperantoPhrases[Phrases.CHECK_YOUR_PURCHASE] = "Kontrolu la detalojn de via aĉeto";
EsperantoPhrases[Phrases.SELLER_INFO] = "Vendanta informo";
EsperantoPhrases[Phrases.INVOICE_NEED_ANSWERS] = "Por vidi la fakturon vi bezonas validajn respondojn por ĉiuj supraj demandoj.";
EsperantoPhrases[Phrases.BUYING_SUBSCRIPTION_TO] = "Aĉetas abono por";
EsperantoPhrases[Phrases.BUYING_SUBSCRIPTION_TILL] = "Aĉetas abono ĝis";
EsperantoPhrases[Phrases.AMOUNT] = "Kvanto";
EsperantoPhrases[Phrases.ITEM] = "Ero";
EsperantoPhrases[Phrases.YOUR_SUBSCRIPTION] = "Via Abono ";
EsperantoPhrases[Phrases.YOUR_ACCOUNT] = "Via Konto ";
EsperantoPhrases[Phrases.REDIRECTED_TO_PAYMENT_PROVIDER] = "Alidirektas al paganta provizanto en nova langeto...";
EsperantoPhrases[Phrases.PAYMENT_REDIRECTION_FAILED] = "Se vi ne estis alidirektita, certigu ke via retumilo permesas malfermi novajn langetojn.";
EsperantoPhrases[Phrases.REDIRECT_FAILED_BUTTON] = "Klaku kiam alidirekto fiaskis";
EsperantoPhrases[Phrases.REDIRECT_YOURSELF] = "Se vi ne estas alidirektita aŭtomate, klaku";
EsperantoPhrases[Phrases.THIS_LINK] = "ĉi tiun ligilon";
EsperantoPhrases[Phrases.ZERO_INVOICE_1] = "Via elektita aĉeto ne aldonas iun ajn al via nuntempa abono.";
EsperantoPhrases[Phrases.ZERO_INVOICE_2] = "Bonvolu elekti alian abonon aŭ pli longan daŭron.";
EsperantoPhrases[Phrases.SORRY_STORE_SERVICE_DOWN] = "La vendejo nuntempe estas fermita. Ni bedaŭras pri la ĝeno.";
EsperantoPhrases[Phrases.CONNECTING_TO_STORE_SERVICE] = "Konektas...";
EsperantoPhrases[Phrases.UPGRADE_DURATION] = "Ĝis nuntempa abono eksvalidiĝas";
EsperantoPhrases[Phrases.FOR_FREE] = "Senpaga";
EsperantoPhrases[Phrases.MAXED_OUT_SUBSCRIPTIONS] = "Via abono havas la maksiman daŭron kaj enhavas ĉiujn nuntempajn etendigojn";
EsperantoPhrases[Phrases.IP_COUNTRY_MISMATCH] = "La lando, kiun vi elektis ne kongruas vian IP-adreson";
EsperantoPhrases[Phrases.EXPIRES_AT] = "abono finas je";
EsperantoPhrases[Phrases.TOOLTIP_RANDOM_SELECTION] = "Klaku por aldonu hazardan karton el ARGUMENT0 al via regno.";
EsperantoPhrases[Phrases.TOOLTIP_LANDSCAPE_SLOT] = "Ĉi tiu fendo povus esti ARGUMENT0. Klaku la bildojn por ŝanĝi ĉi tion. Malmarku ĉiujn bildojn por forigu la fendon.";
EsperantoPhrases[Phrases.LANDSCAPE_UNUSED] = "malplena";
EsperantoPhrases[Phrases.TOOLTIP_LANDSCAPE_PLUS_SLOT] = "Kreu novan fendon por horizontalaj kartoj kaj poste elektu kiujn specojn de horizantaloj povas aperi.";
EsperantoPhrases[Phrases.LANDSCAPE] = "Horizantalo";

EsperantoPhrases[Phrases.PREVIEW_BOT_MATCH] = "Antaŭrigardo vs Roboto";

EsperantoPhrases[Phrases.TOOLTIP_USE_COFFERS] = "Klaku por elspezi 1 Monrezervon";
EsperantoPhrases[Phrases.TOOLTIP_CANT_USE_COFFERS] = "Vi povas nur elspezi Monrezervojn en via Aĉetfazo, antaŭ vi startas aĉeti";
EsperantoPhrases[Phrases.TOOLTIP_USE_VILLAGER] = "Klaku por uzi 1 Vilaĝanon";
EsperantoPhrases[Phrases.TOOLTIP_CANT_USE_VILLAGER] = "Vi povas nur uzi Vilaĝanojn en via Agfazo";
//EsperantoPhrases[Phrases.CARDS_BANNED_BY_PLAYERS]        = "Cards banned by players: ";
EsperantoPhrases[Phrases.AUTOCOMPLETE_CARD_LISTS] = "Enskribu kartojn por aldoni";
EsperantoPhrases[Phrases.RESPECTED_CARD_LISTS] = "Observitaj kartlistoj";
EsperantoPhrases[Phrases.BANNED_CARDS] = "malpermesitaj kartoj";
EsperantoPhrases[Phrases.LIKED_CARDS] = "ŝatitaj kartoj";
EsperantoPhrases[Phrases.DISLIKED_CARDS] = "malŝatitaj kartoj";
EsperantoPhrases[Phrases.KINGDOM_GENERATOR_PERCENTAGES] = "Regno generita kun ĉi tiuj relativaj procentoj:";
EsperantoPhrases[Phrases.VILLAGERS] = "Vilaĝanoj";
EsperantoPhrases[Phrases.DAYS] = "tagoj";
EsperantoPhrases[Phrases.SPECIAL_KINGDOM_RULES] = "Specialaj Regnoreguloj:";
EsperantoPhrases[Phrases.SHOW_CHAT] = "Montru Babiladon";
EsperantoPhrases[Phrases.HIDE_CHAT] = "Kaŝu Babiladon";
EsperantoPhrases[Phrases.UNBLACKLIST] = "Forigu el barlisto";
EsperantoPhrases[Phrases.INHERITANCE_INSTRUCTIONS] = "Ludu la karton kun via//Bienjetono, lasante ĝin tie";
EsperantoPhrases[Phrases.TOOLTIP_OWNED_ENABLED] = "Klaku por malebligi ĉi tiun etendigon.<br/>Kiam malebligita, vi ne alportis ĝin al viaj ludoj.";
EsperantoPhrases[Phrases.TOOLTIP_OWNED_DISABLED] = "Klaku por ebligi ĉi tiun etendigon.<br/>Kiam ebligita, vi alportis ĝin al viaj ludoj";
EsperantoPhrases[Phrases.TOOLTIP_VOID_ENABLED] = "Klaku por malebligi ĉi tiun etendigon.<br/>Malebligado faras nenion se vi ne estas abonita.";
EsperantoPhrases[Phrases.TOOLTIP_VOID_DISABLED] = "Klaku por ebligi ĉi tiun etendigon.<br/>Ebligado faras nenion se vi ne estas abonita.";
EsperantoPhrases[Phrases.TOOLTIP_BASE_SET] = "Vi ne povas malebligi la bazludon.";
EsperantoPhrases[Phrases.CHOOSE_CARD_POOL] = "Elektu Kartgrupon";
EsperantoPhrases[Phrases.USE_LEVEL] = "Uzu nivelon";
EsperantoPhrases[Phrases.VIEW_INTRODUCTION_CARD_SELECTION] = "Rigardu antaŭparolon por kartelektado";
EsperantoPhrases[Phrases.VIEW_CARD_POOL_LEVELS] = "Rigardu Kartgrupnivelojn";
EsperantoPhrases[Phrases.CARD_POOL_BOX_HEADER] = "Kartgrupo";
EsperantoPhrases[Phrases.CARD_POOL_LEVEL] = "Nivelo";
EsperantoPhrases[Phrases.MIN_CARD_POOL_LEVEL] = "Min Level";
EsperantoPhrases[Phrases.CARD_POOL_LEVEL_SMALL] = "nivelo";
EsperantoPhrases[Phrases.USED_CARD_POOL_LEVEL] = "Kartgrupo: nivelo ";
EsperantoPhrases[Phrases.SHOW_LEVEL] = "Kartgrupo: ";
EsperantoPhrases[Phrases.SUBSCRIPTION_YES] = "Dankon pro abonado.";
EsperantoPhrases[Phrases.SUBSCRIPTION_NO] = "Vi ne havas abonon.";
EsperantoPhrases[Phrases.SUBSCRIPTION_FOR] = "Via abono daŭras por";
EsperantoPhrases[Phrases.EMPTY_BECAUSE_DISABLED] = "Vi ne povas elekti kartojn el ĉi tiu etendigo ĉar vi malebligis ĝin en la langeto Konto.";
EsperantoPhrases[Phrases.EMPTY_BECAUSE_NOT_SUBSCRIBED] = "Vi ne povas elekti kartojn el ĉi tiu etendigo ĉar vi ne havas abonon por ĝin.";
EsperantoPhrases[Phrases.EXPLAIN_SUBSCRIPTIONS] = "\n\u0108i tiu retejo peremesas vin ludi kun la bazludo senpage. \nSe vi volas ludi kun la etendigoj a\u016D volas to subteni la retejon, bonvolu konsideri aboni. \nVi povas anka\u016D provi trovi kontra\u016Dulon, kiu provizas la kartjon. Se vi volas atendi por tiel kontra\u016Dulo, marku \"Atendu Etendigojn\".\n";
EsperantoPhrases[Phrases.CARD_SELECTION_INTRODUCTION_TEXT] = ["<span class=\"game-concept\">A\u016Dtoludo</span> ofertas tri malsamajn vojojn por limigi la kartjon, kun kiuj vi ludas.\n    Alternative, vi povas krei <span class=\"game-concept\">Novan Tablon</span> kaj trovi viajn proprajn kontra\u016Dulojn, permesante vin permane elekti \u0109ion.", "<div class=\"header\">Kartgrupo</div>\n        Se vi ankora\u016D lernas la kartojn, vi ver\u015Dajne volas uzi \u0109i tion. \n        Estas 10 malsamaj niveloj, komencante kun la bazludo. \n        Vi povas libere elekti vian progresadon \u0109i tie. \n        Vi povas legi pri la novajn me\u0125anikojn \u0109e \u0109iu nivelo anta\u016De. \n        <span class=\"game-concept\">Kartgrupo</span> devas esti starigita al la nivelo, kun kiu vi estas komforta.\n        <span class=\"game-concept\">Grupgamo</span> povas esti starigita al nul, signifante ke vi \u0109iam atendi ludon \u0109e precize la volitan nivelon. \n        Vi povas anka\u016D starigi \u011Din pli alte se vi pretas helpi aliajn a\u016D volas pli rapidan ser\u0109on.", "<div class=\"header\">Etendigelekton</div>\n        Abonantoj povas elekti tiujn etendigojn, kiujn tiu alporti al ties ludoj, a\u016D per klakante <span class=\"game-concept\">Administru miajn etendigojn</span> a\u016D uzante <span class=\"game-concept\">Konto</span> sub la <span class=\"game-concept\">Elektoj</span> menuo (la dentrado).\n        <span class=\"game-concept\">Atendu etendigojn</span> estas trajto por neabonantoj. \u011Ci permesas vin atendi \u011Dis abonanto volas ludi kun vi.", "<div class=\"header\">Kartlistoj</div>\n        <span class=\"game-concept\">Kartlistoj</span> permesas vin krei malgrandajn personajn listojn da kartoj. Kartoj, kiujn vi baras neniam aperos en viaj ludoj, kaj kartoj, kiujn vi malamas elektos 50% malpli."];

EsperantoPhrases[Phrases.LEVEL_DESCRIPTIONS] = ["Nur Bazo", "Simplaj kartoj", "Daŭrokartoj, specialaj Trezorkartoj, Plateno & Kolonio", "Kiam-gajni, Kiam-rubigi, VP jetonoj", "Monrezervoj, Vilaĝanoj, Ekzilo, Ĉi-vice", "Kiam-en-ludo, Kiam-forĵeti, Matoj", "Okazoj, Rezervkartoj, Aventuroj-jetonoj", "Projektoj, Rekoniloj, Noktokartoj, Ŝirmejoj, Ĉevaloj", "Ekstraj kartoj, Artefaktoj, Benoj, Envultoj, Heredaĵoj", "Ĉiuj kartoj"];
EsperantoPhrases[Phrases.NEW_CARDS] = "Novaj Kartoj";
EsperantoPhrases[Phrases.FAVOR] = "Komplezo";
EsperantoPhrases[Phrases.FAVORS] = "Komplezoj";
EsperantoPhrases[Phrases.FAVORS_MANY] = "Komplezoj";
EsperantoPhrases[Phrases.INDIVIDUAL_EXPANSIONS] = "Individual Expansions";
EsperantoPhrases[Phrases.WARNING_SEARCHING_SUBSCRIBER] = "Searching for an opponent with a Subscription";
EsperantoPhrases[Phrases.WARNING_MAX_RATING_LOW] = "Max rating is set quite low";
EsperantoPhrases[Phrases.WARNING_MIN_RATING_HIGH] = "Min rating is set quite high";
EsperantoPhrases[Phrases.SEARCHING_2E_PREVIEWS] = "2e Previews";
EsperantoPhrases[Phrases.WARNING_ERRATA] = "Note: this game has some cards with recent errata:";
EsperantoPhrases[Phrases.DONT_SHOW_THIS_MESSAGE_AGAIN] = "Don't show this message again";
EsperantoPhrases[Phrases.REPLAY_LAST_KINGDOM] = "Replay Last Kingdom"; // please translate

// Missing lines as of 09.01.2023 19:11:51
EsperantoPhrases[Phrases.QUICK_UNDO] = "Undo 1";
EsperantoPhrases[Phrases.UNDO_BUTTON] = "Rewind";
EsperantoPhrases[Phrases.BOT] = "Bot";
EsperantoPhrases[Phrases.AUTOMATCH_LABEL_CARD_POOL_RANGE] = "Pool range";
EsperantoPhrases[Phrases.AUTOMATCH_LABEL_EXPANSIONS] = "Expansions";
EsperantoPhrases[Phrases.AUTOMATCH_LABEL_RATING] = "Rating";
EsperantoPhrases[Phrases.BASE_ONLY] = "Base";
EsperantoPhrases[Phrases.SIMPLE] = "Simple";
EsperantoPhrases[Phrases.NO_PONG_RECEIVED] = "Warning: possibly lost connection with gameserver.";
EsperantoPhrases[Phrases.MESSAGE_INDEX_MISMATCH] = "Warning: unreliable connection to gameserver.";
EsperantoPhrases[Phrases.RECONNECTING_FAILED_MESSAGE] = "You've reconnected to this game COUNT times.";
EsperantoPhrases[Phrases.TRY_AGAIN] = "Reconnect again";
EsperantoPhrases[Phrases.RETURN_TO_LOBBY] = "Return to lobby";
EsperantoPhrases[Phrases.ALL] = "All";
EsperantoPhrases[Phrases.TIMED_OUT] = "Timed out";
EsperantoPhrases[Phrases.RECONNECT] = "Reconnect";