"use strict";

webclient.component("myTable", {
    controller: ['$scope', 'preferences', 'kingdomService', 'metaBroadcaster', function ($scope, preferences, kingdomService, metaBroadcaster) {
        var self = this;

        self.chatCondensed = !preferences.getUserPrefValue(UserPrefIds.CHAT_VISIBLE);
        self.showSelector = false;
        self.toggleSelector = function () {
            self.showSelector = !self.showSelector;
            metaBroadcaster.send(Events.REDRAW);
            if (self.showSelector) {
                setTimeout(function () {
                    return window.document.getElementsByClassName("card-selector-input")[0].focus();
                }, 0);
            }
        };
        self.hideSelector = function () {
            self.showSelector = false;
        };

        self.shouldShowAdvancedRules = !kingdomService.kingdomIsDefault();
    }],
    template: "\n            <div class=\"table-content\">\n                <div class=\"table-main-column\" ng-class=\"{condensed: !$ctrl.shouldShowAdvancedRules}\">\n                    <kingdom-rules\n                      toggle-selector=\"$ctrl.toggleSelector\"\n                      showing-selector=\"$ctrl.showSelector\"\n                      should-show-advanced-rules=\"$ctrl.shouldShowAdvancedRules\">\n                    </kingdom-rules>\n                    <game-rules></game-rules>\n                </div>\n                <div class=\"table-side-bar\">\n                    <participant-list expanded=\"$ctrl.chatCondensed\"></participant-list>\n                    <table-chat condensed=\"$ctrl.chatCondensed\"></table-chat>\n                </div>\n            </div>\n            <table-card-selector ng-show=\"$ctrl.showSelector\"></table-card-selector>\n            <div class=\"fullscreen\" ng-if=\"$ctrl.showSelector\" ng-click=\"$ctrl.hideSelector()\"></div>\n            <table-buttons></table-buttons>\n            <div id=\"tooltip\" class=\"table-tooltip hidden\"></div>\n            "
});