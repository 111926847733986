"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MetaGameModel = function () {
    function MetaGameModel(game, serverMessenger, gameBroadcaster) {
        _classCallCheck(this, MetaGameModel);

        this.game = game;
        this.serverMessenger = serverMessenger;
        this.gameBroadcaster = gameBroadcaster;
        this.hasResignRequest = false;
        this.shouldShowGameEndedNotification = false;
        this.undoRequest = null;
        this.gameResult = null;
        this.timeoutOffer = null;
    }

    _createClass(MetaGameModel, [{
        key: "hideResignWindow",
        value: function hideResignWindow() {
            this.timeoutOffer = null;
            this.gameBroadcaster.send(Events.REDRAW);
        }
    }, {
        key: "getResigningPlayerId",
        value: function getResigningPlayerId() {
            if (this.gameResult === null) return -1;
            return this.gameResult.anyResigningPlayerId();
        }
    }, {
        key: "endGame",
        value: function endGame(gameResult) {
            this.gameResult = gameResult;
            this.gameBroadcaster.send(Events.REDRAW);
            this.game.reset();
        }
    }, {
        key: "change",
        value: function change(type, data) {
            switch (type) {
                case Events.UNDO_REQUEST:
                    this.undoRequest = data;
                    this.game.logModel.logView.updateUndoVisibility();
                    if (!this.game.state.players[data.playerIndex].isMe) this.game.gameSoundService.notify();
                    break;
                case Events.TIMEOUT_OFFER:
                    this.timeoutOffer = data;
                    if (!this.game.state.players[data.playerIndex].isMe) this.game.gameSoundService.notify();
                    break;
                case Events.UNDO_REQUEST_DENIED:
                    this.undoRequest = null;
                    this.gameBroadcaster.send(Events.UNDO_REQUEST_DENIED);
                    this.game.logModel.logView.updateUndoVisibility();
                    break;
                case Events.UNDO_REQUEST_CANCELLED:
                    this.undoRequest = null;
                    this.gameBroadcaster.send(Events.UNDO_REQUEST_CANCELLED);
                    this.game.logModel.logView.updateUndoVisibility();
                    break;
            }
            this.gameBroadcaster.send(Events.REDRAW);
        }
    }, {
        key: "cancelUndoRequest",
        value: function cancelUndoRequest() {
            if (this.undoRequest !== null) {
                this.serverMessenger.undoRequestCancelled(this.undoRequest);
                this.undoRequest = null;
                this.gameBroadcaster.send(Events.REDRAW);
            }
        }
    }, {
        key: "requestTimeout",
        value: function requestTimeout() {
            if (this.timeoutOffer !== null) {
                this.serverMessenger.timeoutRequest(this.timeoutOffer);
                this.timeoutOffer = null;
            }
        }
    }, {
        key: "grantUndoRequest",
        value: function grantUndoRequest() {
            if (this.undoRequest !== null) {
                this.serverMessenger.undoRequestGranted(this.undoRequest);
                this.undoRequest = null;
            }
        }
    }, {
        key: "denyUndoRequest",
        value: function denyUndoRequest() {
            if (this.undoRequest !== null) {
                this.serverMessenger.undoRequestDenied(this.undoRequest);
                this.undoRequest = null;
            }
        }
    }, {
        key: "quickUndo",
        value: function quickUndo() {
            var depth = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;

            var decisionEntries = this.game.logModel.entries.filter(function (e) {
                return e instanceof DecisionEntry;
            });
            for (var i = decisionEntries.length - 1; i >= 0; i--) {
                if (this.game.state.players[decisionEntries[i].playerIndex].isMe && !decisionEntries[i].autoPlayed) {
                    if (depth === 1) {
                        this.serverMessenger.undoRequest(decisionEntries[i].decisionIndex);
                        break;
                    } else {
                        depth--;
                    }
                }
            }
        }
    }, {
        key: "hasResignRequest",
        set: function set(value) {
            this.privateResignRequest = value;
            this.gameBroadcaster.send(Events.REDRAW);
        },
        get: function get() {
            return this.privateResignRequest;
        }
    }, {
        key: "undoIndex",
        get: function get() {
            return this.undoRequest === null ? -1 : this.undoRequest.decisionIndex;
        }
    }]);

    return MetaGameModel;
}();