"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var InPlayZone = function (_TabbedZone) {
    _inherits(InPlayZone, _TabbedZone);

    function InPlayZone() {
        _classCallCheck(this, InPlayZone);

        return _possibleConstructorReturn(this, (InPlayZone.__proto__ || Object.getPrototypeOf(InPlayZone)).apply(this, arguments));
    }

    _createClass(InPlayZone, [{
        key: "extractTab",
        value: function extractTab() {
            var _this2 = this;

            return new InPlayGameTab(this.layoutModel, "images/cards/art/base/village.webp", function (zone) {
                return zone instanceof InPlayZone && zone.owner === _this2.owner;
            });
        }
    }, {
        key: "reposition",
        value: function reposition() {
            var _this3 = this;

            var shouldAnimate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            if (isUndefined(this.layoutModel.layout)) return;
            // if (this.isActiveCenterZone)
            //     this.bbox.zIndex = 100;
            var vh = 0.01 * window.innerHeight;
            var vw = 0.01 * window.innerWidth;
            var baseTopPoint = void 0;
            if (this.layoutModel.isSpec) {
                if (this.layoutModel.isLowered) baseTopPoint = (2 + SPEC_HAND_HEIGHT) * vh;else baseTopPoint = (3 + SPEC_HAND_HEIGHT + SPEC_SUPPLY_HEIGHT) * vh;
            } else {
                if (this.layoutModel.isLowered) baseTopPoint = OPPONENT_HEIGHT * vh;else baseTopPoint = this.layoutModel.kingdomBbox.y + this.layoutModel.kingdomBbox.height;
            }

            if (this.owner === this.state.activeTurn.owner) {
                if (this.showCauses.length > 0) this.hideAll();
                this.cardStacks.forEach(function (cardStack, stackIndex) {
                    cardStack.linearlyPosition(_this3.cardStacks.length, stackIndex, (_this3.layoutModel.isSpec ? SPEC_PLAY_HEIGHT : INPLAY_HEIGHT) * vh, -2 * vh, (1 + LEFT_COLUMN_WIDTH) * vw, (99 - RIGHT_COLUMN_WIDTH) * vw, stackIndex % 3 * 4 * vh + baseTopPoint, 100 + 5 * stackIndex, false);
                    cardStack.removeFilter("zone-hidden");
                });
            } else {
                var shouldStagger = this.cardStacks.length > 4;
                this.cardStacks.forEach(function (cardStack, stackIndex) {
                    if (_this3.visible) {
                        var topCard = cardStack.topCard;
                        var height = Math.min(WINDOW_HEIGHT * vh, 0.6 * _this3.bbox.height);
                        var margin = -WINDOW_MARGIN * vh;
                        var width = height / cardStack.ratio * vh;
                        var zIndex = _this3.bbox.zIndex + 5 + stackIndex;
                        var left = _this3.bbox.x + _this3.bbox.width * 0.05;
                        var right = _this3.bbox.x + _this3.bbox.width * 0.95;
                        var offset = shouldStagger ? (stackIndex % 3 - 1) * 3 * vh : 0;
                        var top = _this3.bbox.y + (_this3.bbox.height - height) / 2 + 2 * vh + offset;
                        cardStack.linearlyPosition(_this3.cardStacks.length, stackIndex, height, margin, left, right, top, zIndex, false, shouldAnimate);
                        cardStack.removeFilter("zone-hidden");
                    } else cardStack.addFilter("zone-hidden");
                });
            };
            this.windowView.redraw();
        }
    }, {
        key: "show",
        value: function show(cause) {
            if (cause instanceof GameTab || cause instanceof Question) _get(InPlayZone.prototype.__proto__ || Object.getPrototypeOf(InPlayZone.prototype), "show", this).call(this, cause);
        }
    }, {
        key: "updateOwnership",
        value: function updateOwnership() {
            this.reposition();
        }
    }, {
        key: "initialize",
        value: function initialize(cardIds) {
            var _this4 = this;

            cardIds.forEach(function (id) {
                var card = _this4.state.cards[id];
                if (_this4.cardStacks.length > 0) {
                    var lastStack = _this4.cardStacks[_this4.cardStacks.length - 1];
                    var lastCard = lastStack.topCard;
                    if (lastCard.cardName === card.cardName && card.isIdenticalTo(lastCard)) {
                        lastStack.addCard(card);
                        return;
                    }
                }
                var newStack = new CardStack(_this4.state.getAnonCard(card.cardName), _this4);
                newStack.addCard(card);
            });
            this.reposition();
        }
    }, {
        key: "addCard",
        value: function addCard(card) {
            if (this.cardStacks.length > 0) {
                var lastStack = this.cardStacks[this.cardStacks.length - 1];
                var lastCard = lastStack.topCard;
                if (lastCard.cardName === card.cardName && card.isIdenticalTo(lastCard)) {
                    return lastStack;
                }
            }
            var newStack = new CardStack(card, this);
            newStack.addFilter("intangible");
            this.reposition();
            return newStack;
        }
    }, {
        key: "addAnonymousCard",
        value: function addAnonymousCard() {
            var newStack = new CardStack(this.state.getAnonCard(CardNames.BACK), this);
            newStack.addFilter("intangible");
            this.reposition();
            return newStack;
        }
    }, {
        key: "getScript",
        value: function getScript(fromZone, stack, targetStack, props) {
            if (props.isInstant) return [];

            if (stack.hasFilter("inverted-card")) {
                switch (props.type) {
                    case ClientAnimationTypes.MINIMAL:
                        var init = stack.view.bbox.linearInterpolate(targetStack.view.bbox, 0.8);
                        init.opacity = 0;
                        return [new Keyframe(0, new AbsoluteTarget(init), Curves.LINEAR, [], ["inverted-card"]), new Keyframe(props.speed, new StackTarget(), Curves.SMOOTH)];
                        break;
                    case ClientAnimationTypes.NORMAL:
                    case ClientAnimationTypes.HIGHLIGHTED:
                        return [new Keyframe(0, new RelativeTarget(new Rectangle(0, 0, 0, 0, 0, 180)), Curves.LINEAR, [], ["inverted-card"]), new Keyframe(props.speed * 1.5, new StackTarget(), Curves.SMOOTH), new Keyframe(props.delay * 0.5, new StackTarget(), Curves.SMOOTH)];
                        break;
                }
            } else {
                switch (props.type) {
                    case ClientAnimationTypes.MINIMAL:
                        var _init = stack.view.bbox.linearInterpolate(targetStack.view.bbox, 0.8);
                        _init.opacity = 0;
                        return [new Keyframe(0, new AbsoluteTarget(_init), Curves.LINEAR), new Keyframe(props.speed, new StackTarget(), Curves.SMOOTH)];
                        break;
                    case ClientAnimationTypes.NORMAL:
                    case ClientAnimationTypes.HIGHLIGHTED:
                        return [new Keyframe(props.speed * 1.5, new StackTarget(), Curves.SMOOTH), new Keyframe(props.delay * 0.5, new StackTarget(), Curves.SMOOTH)];
                        break;
                }
            }
        }
    }, {
        key: "zoneName",
        get: function get() {
            return "InPlayZone";
        }
    }, {
        key: "visible",
        get: function get() {
            return this.showCauses.length > 0 || this.owner === this.state.activeTurn.owner;
        }
    }, {
        key: "showWindow",
        get: function get() {
            return this.showCauses.length > 0;
        }
    }, {
        key: "repositionOnNewTurn",
        get: function get() {
            return true;
        }
    }, {
        key: "isOwnershipDependent",
        get: function get() {
            return true;
        }
    }]);

    return InPlayZone;
}(TabbedZone);