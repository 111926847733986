"use strict";

var FrenchAbilityDescriptions = {};

FrenchAbilityDescriptions[AbilityNames.BUY] = "acheter";
FrenchAbilityDescriptions[AbilityNames.PLAY_ATTACK] = "jouer";
FrenchAbilityDescriptions[AbilityNames.DRAW_INSTRUCTIONS] = "piocher Argument0";
FrenchAbilityDescriptions[AbilityNames.PLAY_INSTRUCTIONS] = "jouer";
FrenchAbilityDescriptions[AbilityNames.RESOLVE_EVENT] = "RESOLVE_EVENT";
FrenchAbilityDescriptions[AbilityNames.GAIN_BY_NAME] = "recevoir Argument0 (Argument1)";
FrenchAbilityDescriptions[AbilityNames.FISHING_VILLAGE_ABILITY] = "+1 Action, +$1";
FrenchAbilityDescriptions[AbilityNames.REMOVE_COST_REDUCTION] = "REMOVE_COST_REDUCTION";
FrenchAbilityDescriptions[AbilityNames.HAVEN_ABILITY] = "prendre Argument0 en main";
FrenchAbilityDescriptions[AbilityNames.SAVE_RETURN_TO_HAND] = "prendre Argument0 en main";
FrenchAbilityDescriptions[AbilityNames.CARAVAN_ABILITY] = "+1 Carte";
FrenchAbilityDescriptions[AbilityNames.MERCHANT_SHIP_ABILITY] = "+$2";
FrenchAbilityDescriptions[AbilityNames.OUTPOST_ABILITY] = "[Outpost]";
FrenchAbilityDescriptions[AbilityNames.TACTICIAN_ABILITY] = "+5 Cartes, +1 Action, +1 Achat";
FrenchAbilityDescriptions[AbilityNames.WHARF_ABILITY] = "+2 Cartes, +1 Achat";
FrenchAbilityDescriptions[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = "+1 Carte, prendre [Horse Traders] en main";
FrenchAbilityDescriptions[AbilityNames.DUCHESS_MAY_GAIN] = "recevoir [^nDuchess]";
FrenchAbilityDescriptions[AbilityNames.FOOLS_GOLD_TRASH] = "écarter [Fools Gold] pour mettre sur la pioche [^nGold]";
FrenchAbilityDescriptions[AbilityNames.NOBLE_BRIGAND] = "essayer de voler aux adversaires [^nSilver] ou [^nGold]";
FrenchAbilityDescriptions[AbilityNames.HAGGLER_ABILITY] = "recevoir une carte non-Victoire moins chère";
FrenchAbilityDescriptions[AbilityNames.INN_ABILITY] = "mélanger des Actions de votre défausse dans votre pioche";
FrenchAbilityDescriptions[AbilityNames.MANDARIN] = "placer sur votre pioche tous les Trésors en jeu";
FrenchAbilityDescriptions[AbilityNames.FARMLAND] = "rénover une carte en main de $2 exactement";
FrenchAbilityDescriptions[AbilityNames.GAIN_CHEAPER] = "recevoir une carte moins chère";
FrenchAbilityDescriptions[AbilityNames.GAIN_TWO_RUINS] = "recevoir 2 Ruines";
FrenchAbilityDescriptions[AbilityNames.HUNTING_GROUNDS] = "recevoir [^nDuchy] ou 3 [^pEstate]";
FrenchAbilityDescriptions[AbilityNames.SQUIRE_GAIN_ATTACK] = "recevoir une carte Attaque";
FrenchAbilityDescriptions[AbilityNames.URCHIN_TRANSFORM] = "écarter [Urchin] pour recevoir [^nMercenary]";
FrenchAbilityDescriptions[AbilityNames.FEODUM_GAIN_SILVERS] = "recevoir 3 [^pSilver]";
FrenchAbilityDescriptions[AbilityNames.DOCTOR_OVERPAY] = "surpayer pour écarter ou défausser des cartes de votre pioche";
FrenchAbilityDescriptions[AbilityNames.RESOLVE_DOCTOR_OVERPAY] = "écarter ou défausser des cartes de votre pioche";
FrenchAbilityDescriptions[AbilityNames.HERALD_OVERPAY] = "surpayer pour mettre des cartes de votre défausse sur votre pioche";
FrenchAbilityDescriptions[AbilityNames.RESOLVE_HERALD_OVERPAY] = "mettre sur votre pioche des cartes de votre défausse";
FrenchAbilityDescriptions[AbilityNames.MASTERPIECE_OVERPAY] = "surpayer pour recevoir [^pSilver]";
FrenchAbilityDescriptions[AbilityNames.RESOLVE_MASTERPIECE_OVERPAY] = "recevoir [^pSilver]";
FrenchAbilityDescriptions[AbilityNames.STONEMASON_OVERPAY] = "surpayer pour recevoir 2 cartes Action";
FrenchAbilityDescriptions[AbilityNames.RESOLVE_STONEMASON_OVERPAY] = "recevoir 2 cartes Action";
FrenchAbilityDescriptions[AbilityNames.EXCHANGE] = "EXCHANGE";
FrenchAbilityDescriptions[AbilityNames.AMULET] = "+$1; ou écarter une carte; ou recevoir [^nSilver]";
FrenchAbilityDescriptions[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = "+$1";
FrenchAbilityDescriptions[AbilityNames.DUNGEON_ABILITY] = "+2 Cartes, puis défausser 2 cartes";
FrenchAbilityDescriptions[AbilityNames.GEAR_ABILITY] = "prendre Argument0 en main";
FrenchAbilityDescriptions[AbilityNames.MESSENGER_GAIN] = "tous les joueuers reçoivent la même carte coûtant $4 ou moins";
FrenchAbilityDescriptions[AbilityNames.BRIDGE_TROLL_ABILITY] = "+1 Achat";
FrenchAbilityDescriptions[AbilityNames.HAUNTED_WOODS_DURATION] = "+3 Cartes";
FrenchAbilityDescriptions[AbilityNames.HAUNTED_WOODS_TOPDECK] = "tout mettre sur la pioche ([Haunted Woods] de l'adversaire)";
FrenchAbilityDescriptions[AbilityNames.LOST_CITY] = "+1 Carte ([Lost City] de l'adversaire)";
FrenchAbilityDescriptions[AbilityNames.WINE_MERCHANT_DISCARD] = "défausser [^nWine Merchant] de votre plateau Taverne";
FrenchAbilityDescriptions[AbilityNames.HIRELING] = "+1 Carte";
FrenchAbilityDescriptions[AbilityNames.SWAMP_HAG_DURATION] = "+$3";
FrenchAbilityDescriptions[AbilityNames.TRASH_TOKEN_ABILITY] = "écarter une carte (Jeton Écart)";
FrenchAbilityDescriptions[AbilityNames.EMPORIUM_GAIN_POINTS] = "+2 PV, si vous avez au moins 5 cartes Action en jeu";
FrenchAbilityDescriptions[AbilityNames.ROCKS_GAINS_SILVER] = "recevoir [^nSilver]";
FrenchAbilityDescriptions[AbilityNames.FORTUNE] = "recevoir [^nGold] par [Gladiator] en jeu";
FrenchAbilityDescriptions[AbilityNames.CRUMBLING_CASTLE] = "+1 PV et recevoir [^nSilver]";
FrenchAbilityDescriptions[AbilityNames.HAUNTED_CASTLE] = "recevoir [^nGold] et les adversaires doivent mettre des cartes sur leur pioche";
FrenchAbilityDescriptions[AbilityNames.SPRAWLING_CASTLE] = "recevoir [^nDuchy] ou 3 [^pEstate]";
FrenchAbilityDescriptions[AbilityNames.GRAND_CASTLE] = "+1 PV par carte Victoire en jeu ou en main";
FrenchAbilityDescriptions[AbilityNames.ENCHANTRESS_ABILITY] = "+2 Cartes";
FrenchAbilityDescriptions[AbilityNames.ENCHANTRESS_CANTRIP] = "+1 Carte, +1 Action";
FrenchAbilityDescriptions[AbilityNames.TEMPLE] = "prendre tous les PV de la pile [Temple]";
FrenchAbilityDescriptions[AbilityNames.VILLA] = "prendre [Villa] en main, +1 Action, revenant à votre phase Action le cas échéant";
FrenchAbilityDescriptions[AbilityNames.ARCHIVE_ABILITY] = "prendre une carte parmi Argument0";
FrenchAbilityDescriptions[AbilityNames.CAPITAL_TAKE_DEBT] = "prendre 6 jetons dette, puis rembourser la dette";
FrenchAbilityDescriptions[AbilityNames.CHARM] = "recevoir une carte différente de même coût";
FrenchAbilityDescriptions[AbilityNames.FORUM] = "+1 Achat";
FrenchAbilityDescriptions[AbilityNames.HERMIT] = "écarter ceci, recevoir [^nMadman]";
FrenchAbilityDescriptions[AbilityNames.DONATE] = "écarter depuis votre main, votre pioche et votre défausse combinées";
FrenchAbilityDescriptions[AbilityNames.TAX_RECEIVE] = "prendre la dette depuis la pile de votre achat";
FrenchAbilityDescriptions[AbilityNames.DOMINATE] = "recevoir [^nProvince], puis dans ce cas +9 PV";
FrenchAbilityDescriptions[AbilityNames.VP_TO_AQUEDUCT] = "déplacer 1 PV d'une pile Trésor vers [Aqueduct]";
FrenchAbilityDescriptions[AbilityNames.VP_FROM_AQUEDUCT] = "prendre tous les PV depuis [Aqueduct]";
FrenchAbilityDescriptions[AbilityNames.ARENA_DISCARD_ACTION] = "défausser une Action pour prendre 2 PV depuis [Arena]";
FrenchAbilityDescriptions[AbilityNames.VP_FROM_BASILICA] = "prendre 2 PV depuis [Basilica] si vous avez $2 ou plus";
FrenchAbilityDescriptions[AbilityNames.VP_FROM_BATHS] = "prendre 2 PV depuis [Baths]";
FrenchAbilityDescriptions[AbilityNames.VP_FROM_BATTLEFIELD] = "prendre 2 PV depuis [Battlefield]";
FrenchAbilityDescriptions[AbilityNames.VP_FROM_COLONNADE] = "prendre 2 PV depuis [Colonnade] si vous avec reçu une copie d'une carte en jeu";
FrenchAbilityDescriptions[AbilityNames.VP_TO_DEFILED_SHRINE] = "déplacer 1 PV d'une pile Action vers [Defiled Shrine]";
FrenchAbilityDescriptions[AbilityNames.VP_FROM_DEFILED_SHRINE] = "prendre tous les PV depuis [Defiled Shrine]";
FrenchAbilityDescriptions[AbilityNames.VP_FROM_LABYRINTH] = "prendre 2 PV depuis [Labyrinth]";
FrenchAbilityDescriptions[AbilityNames.MOUNTAIN_PASS_BIDDING] = "miser de la dette pour +8 PV";
FrenchAbilityDescriptions[AbilityNames.TOMB] = "+1 PV";
FrenchAbilityDescriptions[AbilityNames.LIGHTHOUSE_COIN] = "+$1";
FrenchAbilityDescriptions[AbilityNames.REACTION] = "Réaction";
FrenchAbilityDescriptions[AbilityNames.CALL_GUIDE] = "recourir à [Guide] pour défausse votre main, +5 cartes";
FrenchAbilityDescriptions[AbilityNames.CALL_RATCATCHER] = "recourir à [Ratcatcher] pour écarter une carte";
FrenchAbilityDescriptions[AbilityNames.CALL_TRANSMOGRIFY] = "recourir à [Transmogrify] pour mettre à niveau une carte";
FrenchAbilityDescriptions[AbilityNames.CALL_TEACHER] = "recourir à [Teacher] pour déplacer un jeton Aventures sur une pile de cartes Action";
FrenchAbilityDescriptions[AbilityNames.CALL_DUPLICATE] = "recourir à [Duplicate] pour gagner une copie de Argument0";
FrenchAbilityDescriptions[AbilityNames.CALL_COIN] = "recourir à [Coin Of The Realm] pour +2 Actions";
FrenchAbilityDescriptions[AbilityNames.CALL_ROYAL_CARRIAGE] = "recourir à [Royal Carriage] pour rejouer Argument0";
FrenchAbilityDescriptions[AbilityNames.START_OF_TURN] = "commencer votre tour";
FrenchAbilityDescriptions[AbilityNames.START_BUY_PHASE] = "commencer la phase Achat";
FrenchAbilityDescriptions[AbilityNames.END_OF_BUY_PHASE] = "terminer la phase Achat";
FrenchAbilityDescriptions[AbilityNames.BETWEEN_TURNS] = "entre les tours";
FrenchAbilityDescriptions[AbilityNames.END_OF_TURN] = "terminer votre tour";
FrenchAbilityDescriptions[AbilityNames.SUMMON_PLAY] = "jouer Argument0";
FrenchAbilityDescriptions[AbilityNames.CARAVAN_GUARD_REACTION] = "Jouer [Caravan Guard]";
FrenchAbilityDescriptions[AbilityNames.TRAVELLING_FAIR_REACTION] = "pouvoir mettre Argument0 sur la pioche";
FrenchAbilityDescriptions[AbilityNames.MOAT_REACTION] = "révéler [Moat] pour bloquer l'attaque.";
FrenchAbilityDescriptions[AbilityNames.HORSE_TRADERS_REACTION] = "mettre de côté [Horse Traders] pour +1 Carte au prochain tour";
FrenchAbilityDescriptions[AbilityNames.BEGGAR_REACTION] = "défausser [Beggar] pour recevoir 2 [^pSilver], dont un sur votre pioche";
FrenchAbilityDescriptions[AbilityNames.MARKET_SQUARE_REACTION] = "défausser [Market Square] pour recevoir [^nGold]";
FrenchAbilityDescriptions[AbilityNames.TRADER_REACTION] = "recevoir [^nSilver] au lieu de Argument0";
FrenchAbilityDescriptions[AbilityNames.DIPLOMAT_REACTION] = "+2 cartes, défausser 3 cartes";
FrenchAbilityDescriptions[AbilityNames.TOPDECK_REACTION] = "placer Argument0 sur la pioche";
FrenchAbilityDescriptions[AbilityNames.WATCHTOWER_TRASH_REACTION] = "écarter Argument0";
FrenchAbilityDescriptions[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "placer Argument0 sur la pioche";
FrenchAbilityDescriptions[AbilityNames.PRINCE_PLAY] = "jouer Argument0";
FrenchAbilityDescriptions[AbilityNames.TUNNEL_REVEAL] = "recevoir [^nGold]";
FrenchAbilityDescriptions[AbilityNames.POSSESSION_GAIN_INSTEAD] = "Argument0 reçoit Argument2 au lieu de Argument1";
FrenchAbilityDescriptions[AbilityNames.HOVEL_TRASH] = "écarter [Hovel]";
FrenchAbilityDescriptions[AbilityNames.DELUDED_RETURNED] = "retourner [Deluded]";
FrenchAbilityDescriptions[AbilityNames.ENVIOUS_RETURNED] = "retourner [Envious]";
FrenchAbilityDescriptions[AbilityNames.RECEIVE_BOON] = "appliquer Argument0";
FrenchAbilityDescriptions[AbilityNames.COBBLER_ABILITY] = "recevoir une carte";
FrenchAbilityDescriptions[AbilityNames.CRYPT_ABILITY] = "prendre une carte parmi Argument0";
FrenchAbilityDescriptions[AbilityNames.DEN_OF_SIN_ABILITY] = "+2 Cartes";
FrenchAbilityDescriptions[AbilityNames.FAITHFUL_HOUND] = "mettre de côté [Faithful Hound]";
FrenchAbilityDescriptions[AbilityNames.FAITHFUL_HOUND_RETURN] = "reprendre en main [^nFaithful Hound]";
FrenchAbilityDescriptions[AbilityNames.GHOST_TOWN_ABILITY] = "+1 Cartes, +1 Action";
FrenchAbilityDescriptions[AbilityNames.GUARDIAN_COIN] = "+$1";
FrenchAbilityDescriptions[AbilityNames.RAIDER_ABILITY] = "+$3";
FrenchAbilityDescriptions[AbilityNames.SECRET_CAVE] = "+$3";
FrenchAbilityDescriptions[AbilityNames.CEMETERY] = "écarter jusqu'à 4 cartes";
FrenchAbilityDescriptions[AbilityNames.HAUNTED_MIRROR] = "défausser une action pour gagner [^nGhost]";
FrenchAbilityDescriptions[AbilityNames.GHOST] = "jouer Argument0 deux fois";
FrenchAbilityDescriptions[AbilityNames.REMOVE_FLIPPED_STATUS] = "ne plus tourner les cartes de l'écart";
FrenchAbilityDescriptions[AbilityNames.LOST_IN_THE_WOODS] = "défausser pour appliquer une Aubaine";
FrenchAbilityDescriptions[AbilityNames.BLESSED_VILLAGE] = "appliquer une Aubaine";
FrenchAbilityDescriptions[AbilityNames.FORTRESS] = "prendre [Fortress] en main.";
FrenchAbilityDescriptions[AbilityNames.RECEIVE_HEX] = "appliquer un Sortilège";
FrenchAbilityDescriptions[AbilityNames.CHANGELING_MAY_EXCHANGE] = "échanger Argument0 contre [Changeling]";
FrenchAbilityDescriptions[AbilityNames.TRADE_ROUTE_MOVE_TOKEN] = "déplacer un Jeton pièce sur le plateau Route commerciale";
FrenchAbilityDescriptions[AbilityNames.DUCAT] = "écarter un[^nCopper]";
FrenchAbilityDescriptions[AbilityNames.CARGO_SHIP_SET_ASIDE] = "mettre de côté Argument0";
FrenchAbilityDescriptions[AbilityNames.CARGO_SHIP_RETURN] = "prendre en main Argument0";
FrenchAbilityDescriptions[AbilityNames.IMPROVE] = "améliorer une Action en jeu";
FrenchAbilityDescriptions[AbilityNames.PRIEST] = "+$2";
FrenchAbilityDescriptions[AbilityNames.RESEARCH] = "prendre Argument0 en main";
FrenchAbilityDescriptions[AbilityNames.SILK_MERCHANT] = "+1 Coffres, +1 Villageois";
FrenchAbilityDescriptions[AbilityNames.LACKEYS] = "+2 Villageois";
FrenchAbilityDescriptions[AbilityNames.SPICES] = "+2 Coffres";
FrenchAbilityDescriptions[AbilityNames.KEY] = "+$1";
FrenchAbilityDescriptions[AbilityNames.TREASURE_CHEST] = "recevoir un Or";
FrenchAbilityDescriptions[AbilityNames.FLAG_BEARER] = "prendre le [^nFlag]";
FrenchAbilityDescriptions[AbilityNames.CATHEDRAL] = "écarter une carte";
FrenchAbilityDescriptions[AbilityNames.CITY_GATE] = "+1 Carte, puis mettre sur la pioche";
FrenchAbilityDescriptions[AbilityNames.PAGEANT] = "payer $1 pour +1 Coffres";
FrenchAbilityDescriptions[AbilityNames.SEWERS_MAY_TRASH] = "écarter une autre carte";
FrenchAbilityDescriptions[AbilityNames.EXPLORATION] = "+1 Coffres, +1 Villageois";
FrenchAbilityDescriptions[AbilityNames.FAIR] = "+1 achat";
FrenchAbilityDescriptions[AbilityNames.SILOS] = "défausser des Cuivres pour des cartes";
FrenchAbilityDescriptions[AbilityNames.ACADEMY] = "+1 Villageois";
FrenchAbilityDescriptions[AbilityNames.GUILDHALL] = "+1 Coffres";
FrenchAbilityDescriptions[AbilityNames.PIAZZA] = "dévoiler et jouer si c'est une Action";
FrenchAbilityDescriptions[AbilityNames.BARRACKS] = "+1 Action";
FrenchAbilityDescriptions[AbilityNames.CROP_ROTATION] = "défausser une carte Victoire pour +2 Cartes";
FrenchAbilityDescriptions[AbilityNames.INNOVATION] = "mettre de côté et jouer Argument0";
FrenchAbilityDescriptions[AbilityNames.CITADEL] = "jouer à nouveau Argument0";
FrenchAbilityDescriptions[AbilityNames.SINISTER_PLOT] = "piocher ou ajouter un jeton";
FrenchAbilityDescriptions[AbilityNames.ENCAMPMENT_RETURNED] = "retourner [Encampment] dans la Réserve";
FrenchAbilityDescriptions[AbilityNames.EMBARGO_GAIN_CURSE] = "recevoir [^nCurse]";
FrenchAbilityDescriptions[AbilityNames.CAPTAIN] = "jouer une action depuis la réserve";
FrenchAbilityDescriptions[AbilityNames.CHURCH] = "prendre en main Argument0, puis vous pouvez écarter";
FrenchAbilityDescriptions[AbilityNames.POSSESSION_SETS_ASIDE] = "mettez de côté Argument0";
FrenchAbilityDescriptions[AbilityNames.MINT] = "écarter tous vos Trésors en jeu";
FrenchAbilityDescriptions[AbilityNames.SLEIGH_TOPDECK_REACTION] = "défausser [Sleigh] pour mettre Argument0 sur votre pioche";
FrenchAbilityDescriptions[AbilityNames.SLEIGH_PUT_IN_HAND_REACTION] = "défausser [Sleigh] pour prendre Argument0 en main";
FrenchAbilityDescriptions[AbilityNames.BLACK_CAT_REACTION] = "jouer [Black Cat]";
FrenchAbilityDescriptions[AbilityNames.DISCARD_FROM_EXILE] = "défausser Argument0 depuis l'Exil";
FrenchAbilityDescriptions[AbilityNames.EXILE_BY_NAME] = "exiler Argument0";
FrenchAbilityDescriptions[AbilityNames.SHEEPDOG_REACTION] = "Jouer [Sheepdog]";
FrenchAbilityDescriptions[AbilityNames.CAVALRY] = "+2 Cartes, +1 Achat & retourner à la phase Action.";
FrenchAbilityDescriptions[AbilityNames.HOSTELRY] = "défausser des Trésors pour des Chevaux";
FrenchAbilityDescriptions[AbilityNames.VILLAGE_GREEN_VILLAGE] = "+1 Carte, +2 Actions";
FrenchAbilityDescriptions[AbilityNames.VILLAGE_GREEN_PLAY] = "jouer [Village Green]";
FrenchAbilityDescriptions[AbilityNames.BARGE] = "+3 Cartes, +1 Achat";
FrenchAbilityDescriptions[AbilityNames.FALCONER_REACTION] = "recevoir une carte moins chère";
FrenchAbilityDescriptions[AbilityNames.GATEKEEPER_EXILE] = "exiler Argument0";
FrenchAbilityDescriptions[AbilityNames.GATEKEEPER_DURATION] = "+3 Pièces";
FrenchAbilityDescriptions[AbilityNames.LIVERY] = "recevoir un Cheval";
FrenchAbilityDescriptions[AbilityNames.MASTERMIND] = "vous pouvez jouer une carte trois fois";
FrenchAbilityDescriptions[AbilityNames.DELAY_PLAY] = "jouer Argument0";
FrenchAbilityDescriptions[AbilityNames.INVEST] = "INVEST ABILITY (never shown to user)";
FrenchAbilityDescriptions[AbilityNames.REAP_PLAY] = "jouer l'[Gold]mis de côté";
FrenchAbilityDescriptions[AbilityNames.PLUS_CARD_TOKEN] = "+Carte depuis le jeton";
FrenchAbilityDescriptions[AbilityNames.PLUS_ACTION_TOKEN] = "+Action depuis le jeton";
FrenchAbilityDescriptions[AbilityNames.PLUS_BUY_TOKEN] = "+Achat depuis le jeton";
FrenchAbilityDescriptions[AbilityNames.PLUS_COIN_TOKEN] = "+Pièce depuis le jeton";
FrenchAbilityDescriptions[AbilityNames.KILN] = "recevoir une copie de Argument0";
FrenchAbilityDescriptions[AbilityNames.TURTLE_PLAY] = "Jouer Argument0 mis de côté";
FrenchAbilityDescriptions[AbilityNames.GIVE_PLUS_CARDS] = "+Carte";
FrenchAbilityDescriptions[AbilityNames.GIVE_PLUS_ACTIONS] = "+Action";
FrenchAbilityDescriptions[AbilityNames.GIVE_PLUS_BUYS] = "+Achat";
FrenchAbilityDescriptions[AbilityNames.GIVE_PLUS_COINS] = "+Pièce";
FrenchAbilityDescriptions[AbilityNames.MONKEY_NEXT_TURN_ABILITY] = "+1 Carte";
FrenchAbilityDescriptions[AbilityNames.BLOCKADE_ABILITY] = "prendre Argument0 en main";
FrenchAbilityDescriptions[AbilityNames.PIRATE_ABILITY] = "recevoir un Trésor un main";
FrenchAbilityDescriptions[AbilityNames.PIRATE_REACTION] = "Jouer [Pirate]";
FrenchAbilityDescriptions[AbilityNames.CORSAIR_NEXT_TURN_ABILITY] = "+1 Carte";
FrenchAbilityDescriptions[AbilityNames.CORSAIR_TRASH] = "Écarter Argument0";
FrenchAbilityDescriptions[AbilityNames.SAILOR_NEXT_TURN] = "+$2, et vous pouvez écarter";
FrenchAbilityDescriptions[AbilityNames.SAILOR_REACTION] = "Jouer Argument0";
FrenchAbilityDescriptions[AbilityNames.ASTROLABE_ABILITY] = "+$1, +1 Achat";
FrenchAbilityDescriptions[AbilityNames.TIDE_POOLS_ABILITY] = "Défausser 2 Cartes";
FrenchAbilityDescriptions[AbilityNames.SEA_WITCH_ABILITY] = "+2 Cartes, puis défausser 2 cartes";
FrenchAbilityDescriptions[AbilityNames.SKIRMISHER] = "vos adversaires défaussent à 3";
FrenchAbilityDescriptions[AbilityNames.ENCHANTRESS_REPLACE_ABILITY] = "Appliquer l'effet de la Magicienne";
FrenchAbilityDescriptions[AbilityNames.HIGHWAYMAN_NEXT_TURN_ABILITY] = "Défausser le Bandit de Grand Chemin, 3 Cartes";
FrenchAbilityDescriptions[AbilityNames.HIGHWAYMAN_REPLACE_ABILITY] = "Appliquer l'effet du Bandit de Grand Chemin";
FrenchAbilityDescriptions[AbilityNames.HIGHWAYMAN_NULLIFIED] = "Jouer sans effet";
FrenchAbilityDescriptions[AbilityNames.LEAGUE_OF_BANKERS] = "+$1 pour chaque 4 Faveurs";
FrenchAbilityDescriptions[AbilityNames.WOODWORKERS_GUILD] = "Dépenser une Faveur et écarter une Action pour recevoir une Action.";
FrenchAbilityDescriptions[AbilityNames.CRAFTERS_GUILD] = "Dépenser 2 Faveurs pour recevoir";
FrenchAbilityDescriptions[AbilityNames.CAVE_DWELLERS] = "Dépenser une Faveur pour défausser et piocher";
FrenchAbilityDescriptions[AbilityNames.DESERT_GUIDES] = "Dépenser une Faveur pour défausser et piocher";
FrenchAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_CARD] = "Dépenser une Faveur pour +1 Carte";
FrenchAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_ACTION] = "Dépenser une Faveur pour  +1 Action";
FrenchAbilityDescriptions[AbilityNames.BAND_OF_NOMADS_BUY] = "Dépenser une Faveur pour  +1 Achat";
FrenchAbilityDescriptions[AbilityNames.SYCOPHANT] = "+2 Faveurs";
FrenchAbilityDescriptions[AbilityNames.IMPORTER_ABILITY] = "Recevoir une carte coûtant jusqu'à 5";
FrenchAbilityDescriptions[AbilityNames.CONTRACT_ABILITY] = "jouer Argument0";
FrenchAbilityDescriptions[AbilityNames.MARKET_TOWNS] = "Dépenser une Faveur pour jouer une Action";
FrenchAbilityDescriptions[AbilityNames.TRAPPERS_LODGE] = "Dépenser une Faveur pour placer Argument0 sur la pioche";
FrenchAbilityDescriptions[AbilityNames.GUILDMASTER] = "+1 Faveur";
FrenchAbilityDescriptions[AbilityNames.ISLAND_FOLK] = "Dépenser 5 Faveurs pour un tour supplémentaire";
FrenchAbilityDescriptions[AbilityNames.GANG_OF_PICKPOCKETS] = "Défausser à 4 cartes à moins de dépenser une Faveur";
FrenchAbilityDescriptions[AbilityNames.COASTAL_HAVEN] = "Conserver des cartes en main pour le prochain tour";
FrenchAbilityDescriptions[AbilityNames.CITY_STATE] = "Dépenser 2 Faveurs pour jouer Argument0";
FrenchAbilityDescriptions[AbilityNames.CITY_STATE_PLAY] = "---";
FrenchAbilityDescriptions[AbilityNames.CIRCLE_OF_WITCHES] = "Dépenser 3 Faveurs pour donner une Malédiction aux adversaires";
FrenchAbilityDescriptions[AbilityNames.ROYAL_GALLEY_SET_ASIDE] = "Mettre de côté avec la Galère Royale";
FrenchAbilityDescriptions[AbilityNames.ROYAL_GALLEY_PLAY] = "Jouer Argument0";
FrenchAbilityDescriptions[AbilityNames.STRONGHOLD_ABILITY] = "+3 Cartes";
FrenchAbilityDescriptions[AbilityNames.MOUNTAIN_FOLK] = "Dépenser 5 Faveurs pour piocher 3 Cartes";
FrenchAbilityDescriptions[AbilityNames.PEACEFUL_CULT] = "Dépenser des Faveurs pour écarter";
FrenchAbilityDescriptions[AbilityNames.FELLOWSHIP_OF_SCRIBES] = "Dépenser une faveur pour piocher une Carte";
FrenchAbilityDescriptions[AbilityNames.LEAGUE_OF_SHOPKEEPERS] = "Commerçants";
FrenchAbilityDescriptions[AbilityNames.TERRITORY_GAIN] = "Recevoir un Or par pile vide";
FrenchAbilityDescriptions[AbilityNames.WARLORD_ABILITY] = "+2 Cartes";
FrenchAbilityDescriptions[AbilityNames.GARRISON_ADD_TOKEN] = "Ajouter un jeton";
FrenchAbilityDescriptions[AbilityNames.GARRISON_REMOVE_ABILITY] = "+Argument0 Cartes";
FrenchAbilityDescriptions[AbilityNames.CONJURER] = "Prendre ceci en main";
FrenchAbilityDescriptions[AbilityNames.LICH] = "Défausser et recevoir depuis le rebut";
FrenchAbilityDescriptions[AbilityNames.GALLERIA] = "+1 Achat";
FrenchAbilityDescriptions[AbilityNames.ARCHITECTS_GUILD] = "Recevoir une carte non-Victoire moins chère";
FrenchAbilityDescriptions[AbilityNames.FAMILY_OF_INVENTORS] = "Réduire le coût d'une pile";
FrenchAbilityDescriptions[AbilityNames.FOREST_DWELLERS] = "Filtrer 3 cartes";
FrenchAbilityDescriptions[AbilityNames.TREASURY] = "Mettre la Trésorerie sur la pioche";
FrenchAbilityDescriptions[AbilityNames.MERCHANT_GUILD] = "Obtenir des Coffres par carte reçue";
FrenchAbilityDescriptions[AbilityNames.CLERK_ABILITY] = "Jouer [Clerk]";

FrenchAbilityDescriptions[AbilityNames.GUARD_DOG_REACTION] = "Jouer [Guard Dog]";
FrenchAbilityDescriptions[AbilityNames.BERSERKER] = "Jouer [Berserker]";
FrenchAbilityDescriptions[AbilityNames.NOMADS] = "+2 Pièces";
FrenchAbilityDescriptions[AbilityNames.TRAIL_REACTION] = "Jouer [Trail]";
FrenchAbilityDescriptions[AbilityNames.WEAVER_PLAY] = "Jouer [Weaver]";
FrenchAbilityDescriptions[AbilityNames.SOUK] = "Écarter jusqu'à 2 cartes";
FrenchAbilityDescriptions[AbilityNames.CAULDRON_CURSING] = "Vos adversaires reçoivent une Malédiction";
FrenchAbilityDescriptions[AbilityNames.ALCHEMIST_TOPDECK] = "Placer [Alchemist] sur la pioche";

// Missing lines as of 08.01.2023 18:54:47
FrenchAbilityDescriptions[AbilityNames.HERBALIST_TOPDECK] = "Placer Argument0 sur la pioche avec [Herbalist]";
FrenchAbilityDescriptions[AbilityNames.SCHEME_TOPDECK] = "Placer Argument0 sur la pioche avec [Scheme]";
FrenchAbilityDescriptions[AbilityNames.FROG_TOPDECK] = "Placer Argument0 sur la pioche";
FrenchAbilityDescriptions[AbilityNames.MERCHANT_CAMP_TOPDECK] = "Placer [Merchant Camp] sur la pioche";
FrenchAbilityDescriptions[AbilityNames.WALLED_VILLAGE_TOPDECK] = "Placer [Walled Village] sur la pioche";
FrenchAbilityDescriptions[AbilityNames.TENT_TOPDECK] = "Placer [Tent] sur la pioche";
FrenchAbilityDescriptions[AbilityNames.HORN_TOPDECK] = "Placer [Border Guard] sur la pioche avec [Horn]";
FrenchAbilityDescriptions[AbilityNames.PAGE_EXCHANGE] = "Échanger [Page] contre [Treasure Hunter]";
FrenchAbilityDescriptions[AbilityNames.TREASURE_HUNTER_EXCHANGE] = "Échanger [Treasure Hunter] contre [Warrior]";
FrenchAbilityDescriptions[AbilityNames.WARRIOR_EXCHANGE] = "Échanger [Warrior] contre [Hero]";
FrenchAbilityDescriptions[AbilityNames.HERO_EXCHANGE] = "Échanger [Hero] contre [Champion]";
FrenchAbilityDescriptions[AbilityNames.PEASANT_EXCHANGE] = "Échanger [Peasant] contre [Soldier]";
FrenchAbilityDescriptions[AbilityNames.SOLDIER_EXCHANGE] = "Échanger [Soldier] contre [Fugitive]";
FrenchAbilityDescriptions[AbilityNames.FUGITIVE_EXCHANGE] = "Échanger [Fugitive] contre [Disciple]";
FrenchAbilityDescriptions[AbilityNames.DISCIPLE_EXCHANGE] = "Échanger [Disciple] contre [Teacher]";

FrenchAbilityDescriptions[AbilityNames.CAGE_TRASH] = "Écarter [Cage]";
FrenchAbilityDescriptions[AbilityNames.CAGE_PUT_IN_HAND] = "Prendre Argument0 en main";
FrenchAbilityDescriptions[AbilityNames.GROTTO] = "Défausser Argument0, puis piocher";
FrenchAbilityDescriptions[AbilityNames.JEWELLED_EGG_GAIN_LOOT] = "Recevoir un Trophée.";
FrenchAbilityDescriptions[AbilityNames.SEARCH] = "Écarter [Search] et recevoir un Trophée.";
FrenchAbilityDescriptions[AbilityNames.SHAMAN] = "Recevoir une carte du rebut coûtant jusqu'à 6.";
FrenchAbilityDescriptions[AbilityNames.SECLUDED_SHRINE] = "Écarter jusqu'à 2 cartes";
FrenchAbilityDescriptions[AbilityNames.SIREN_GAIN] = "Écarter une Action ou [Siren]";
FrenchAbilityDescriptions[AbilityNames.SIREN_DURATION] = "Piocher jusqu'à 8 cartes";
FrenchAbilityDescriptions[AbilityNames.STOWAWAY_DRAW] = "Piocher 2 cartes";
FrenchAbilityDescriptions[AbilityNames.STOWAWAY_REACTION] = "Jouer [Stowaway]";
FrenchAbilityDescriptions[AbilityNames.TASKMASTER] = "+1 Action, +1 Pièce";
FrenchAbilityDescriptions[AbilityNames.ABUNDANCE] = "+1 Achat, +3 Pièces";
FrenchAbilityDescriptions[AbilityNames.CABIN_BOY] = "+2 Pièces, ou écarter [Cabin Boy] pour recevoir une Durée.";
FrenchAbilityDescriptions[AbilityNames.FLAGSHIP_ACTIVATE] = "";
FrenchAbilityDescriptions[AbilityNames.FLAGSHIP_REPLAY] = "Rejouer Argument0";
FrenchAbilityDescriptions[AbilityNames.GONDOLA_DURATION] = "+2 Pièces";
FrenchAbilityDescriptions[AbilityNames.GONDOLA_PLAY] = "Jouer une carte Action";
FrenchAbilityDescriptions[AbilityNames.HARBOR_VILLAGE] = "+1 Pièce";
FrenchAbilityDescriptions[AbilityNames.LANDING_PARTY] = "Placer [Landing Party] sur la pioche";
FrenchAbilityDescriptions[AbilityNames.MAPMAKER] = "Jouer [Mapmaker]";
FrenchAbilityDescriptions[AbilityNames.ROPE] = "+1 Carte, puis vous pouvez écarter une carte";
FrenchAbilityDescriptions[AbilityNames.BURIED_TREASURE_DURATION] = "+1 Achat, +3 Pièces";
FrenchAbilityDescriptions[AbilityNames.BURIED_TREASURE_PLAY] = "Jouer [Buried Treasure]";
FrenchAbilityDescriptions[AbilityNames.CREW_DURATION] = "Placer [Crew] sur la pioche";
FrenchAbilityDescriptions[AbilityNames.CUTTHROAT_GAIN] = "Recevoir un Trophée";
FrenchAbilityDescriptions[AbilityNames.ENLARGE] = "Écarter une carte pour en recevoir une coûtant jusqu'à 2 de plus.";
FrenchAbilityDescriptions[AbilityNames.FRIGATE_DURATION] = "";
FrenchAbilityDescriptions[AbilityNames.FRIGATE_DISCARD] = "Défausser jusqu'à avoir 4 cartes en main.";
FrenchAbilityDescriptions[AbilityNames.LONGSHIP_DURATION] = "+2 Cartes";
FrenchAbilityDescriptions[AbilityNames.MINING_ROAD] = "Jouer Argument0";
FrenchAbilityDescriptions[AbilityNames.QUARTERMASTER] = "Résoudre [Quartermaster]";
FrenchAbilityDescriptions[AbilityNames.TRICKSTER_REACTION] = "Mettre de côté un Trésor";
FrenchAbilityDescriptions[AbilityNames.TRICKSTER_RETURN_TO_HAND] = "Prendre Argument0 en main";
FrenchAbilityDescriptions[AbilityNames.WEALTHY_VILLAGE] = "Recevoir un Trophée";
FrenchAbilityDescriptions[AbilityNames.DELIVER_SET_ASIDE] = "Mettre de côté Argument0";
FrenchAbilityDescriptions[AbilityNames.DELIVER_RETURN] = "Prendre Argument0 en main";
FrenchAbilityDescriptions[AbilityNames.RUSH] = "Jouer Argument0";
FrenchAbilityDescriptions[AbilityNames.MIRROR] = "Recevoir un exemplaire de Argument0";
FrenchAbilityDescriptions[AbilityNames.PREPARE] = "Jouer une carte mise de côté";
FrenchAbilityDescriptions[AbilityNames.AMPHORA] = "+1 Achat, +3 Pièces";
FrenchAbilityDescriptions[AbilityNames.DOUBLOONS] = "Recevoir un Or";
FrenchAbilityDescriptions[AbilityNames.ENDLESS_CHALICE] = "+1 Pièce, +1 Achat";
FrenchAbilityDescriptions[AbilityNames.FIGUREHEAD] = "+2 Cartes";
FrenchAbilityDescriptions[AbilityNames.JEWELS] = "Placer [Jewels] sous votre pioche.";
FrenchAbilityDescriptions[AbilityNames.PUZZLE_BOX_RETURN] = "Prendre Argument0 en main";
FrenchAbilityDescriptions[AbilityNames.SHIELD_REACTION] = "Dévoiler [Shield] pour ne pas être affecté par l'attaque.";
FrenchAbilityDescriptions[AbilityNames.SPELL_SCROLL] = "Jouer Argument0";
FrenchAbilityDescriptions[AbilityNames.CURSED_GAIN] = "Recevoir un Trophée et une Malédiction";
FrenchAbilityDescriptions[AbilityNames.FAWNING_GAIN] = "Recevoir une carte [Fawning]";
FrenchAbilityDescriptions[AbilityNames.SCHEDULER] = "[Friendly] (seeing this is a bug!)";
FrenchAbilityDescriptions[AbilityNames.FRIENDLY_ABILITY] = "Défausser une carte [Friendly] pour en recevoir un exemplaire";
FrenchAbilityDescriptions[AbilityNames.HASTY_SET_ASIDE] = "Mettre Argument0 de côté";
FrenchAbilityDescriptions[AbilityNames.HASTY_PLAY] = "Jouer Argument0";
FrenchAbilityDescriptions[AbilityNames.INHERITED] = "Remplacer une carte de départ.";
FrenchAbilityDescriptions[AbilityNames.INSPIRING] = "Jouer une carte dont vous n'avez pas d'exemplaire en jeu.";
FrenchAbilityDescriptions[AbilityNames.NEARBY] = "+1 Achat";
FrenchAbilityDescriptions[AbilityNames.PATIENT_SCHEDULER] = "[Patient] (seeing this is a bug!)";
FrenchAbilityDescriptions[AbilityNames.PATIENT_SET_ASIDE] = "Mettre de côté les cartes [Patient].";
FrenchAbilityDescriptions[AbilityNames.PATIENT_PLAY] = "Jouer Argument0";
FrenchAbilityDescriptions[AbilityNames.PIOUS] = "Écarter une carte";
FrenchAbilityDescriptions[AbilityNames.RECKLESS_REPEAT] = "Suivre à nouveau les instructions de Argument0.";
FrenchAbilityDescriptions[AbilityNames.RECKLESS_RETURN] = "Retourner Argument0 sur sa pile.";
FrenchAbilityDescriptions[AbilityNames.RICH_GAIN] = "Recevoir [^nSilver]";
FrenchAbilityDescriptions[AbilityNames.SHY] = "Défausser une carte [Shy] pour +2 Cartes";
FrenchAbilityDescriptions[AbilityNames.TIRELESS_SET_ASIDE] = "Mettre de côté Argument0";
FrenchAbilityDescriptions[AbilityNames.TIRELESS_RETURN] = "Mettre Argument0 sur la pioche";
FrenchAbilityDescriptions[AbilityNames.STAR_CHART] = "Mettre sur la pioche";
FrenchAbilityDescriptions[AbilityNames.ORDER_OF_ASTROLOGERS] = "Mettre sur la pioche";
FrenchAbilityDescriptions[AbilityNames.ORDER_OF_MASONS] = "Défausser";
FrenchAbilityDescriptions[AbilityNames.FATED] = "Mettre sur la pioche et/ou défausser";
FrenchAbilityDescriptions[AbilityNames.AVOID] = "Défausser";
FrenchAbilityDescriptions[AbilityNames.HERBALIST_ASK_FOR_DISCARD] = "Mettre un Trésor sur la pioche";
FrenchAbilityDescriptions[AbilityNames.SCHEME_ASK_FOR_DISCARD] = "Mettre une Action sur la pioche";
FrenchAbilityDescriptions[AbilityNames.TRICKSTER_ASK_FOR_DISCARD] = "Mettre de côté un Trésor";
FrenchAbilityDescriptions[AbilityNames.MARCHLAND] = "+1 Achat, Défausser des cartes pour +$1 chacune";
FrenchAbilityDescriptions[AbilityNames.FARMHANDS_SET_ASIDE] = "Mettre de côté une Action ou un Trésor";
FrenchAbilityDescriptions[AbilityNames.FARMHANDS_PLAY] = "jouer Argument0";
FrenchAbilityDescriptions[AbilityNames.JOUST_DISCARD] = "Défausser une Province";
FrenchAbilityDescriptions[AbilityNames.INFIRMARY_OVERPAY] = "surpayer pour jouer [Infirmary]";
FrenchAbilityDescriptions[AbilityNames.RESOLVE_INFIRMARY_OVERPAY] = "jouer [Infirmary]";
FrenchAbilityDescriptions[AbilityNames.DRAW_FROM_FOOTPAD] = "Piocher une carte";
FrenchAbilityDescriptions[AbilityNames.FARRIER_OVERPAY] = "surpayer pour piocher des cartes en fin de tour.";
FrenchAbilityDescriptions[AbilityNames.RESOLVE_FARRIER_OVERPAY] = "résoudre le surpaiement du [Farrier]";
FrenchAbilityDescriptions[AbilityNames.DRAW_FROM_SQUIRREL] = "Piocher 2 cartes supplémentaire";
FrenchAbilityDescriptions[AbilityNames.DRAW_FROM_RIVER] = "Piocher 1 carte supplémentaire";
FrenchAbilityDescriptions[AbilityNames.DRAW_FROM_FARRIER] = "Piocher Argument0 cartes supplémentaires";
FrenchAbilityDescriptions[AbilityNames.FERRYMAN] = "Gain Argument0";

FrenchAbilityDescriptions[AbilityNames.BIDING_TIME_SET_ASIDE_HAND] = "Mettre de côté les cartes en main";
FrenchAbilityDescriptions[AbilityNames.BIDING_TIME_RETURN_TO_HAND] = "Prendre en main les cartes mises de côté";
FrenchAbilityDescriptions[AbilityNames.ENLIGHTENMENT_CANTRIP] = "+1 Carte, +1 Action";
FrenchAbilityDescriptions[AbilityNames.ENLIGHTENMENT_REPLACE_ABILITY] = "Remplacer par l'Illumination";
FrenchAbilityDescriptions[AbilityNames.GOOD_HARVEST] = "+1 Achat +1 Pièce";
FrenchAbilityDescriptions[AbilityNames.HARSH_WINTER] = "Prendre de la dette ou la placer sur la pile";
FrenchAbilityDescriptions[AbilityNames.KIND_EMPEROR] = "Recevoir en main une Action";
FrenchAbilityDescriptions[AbilityNames.PANIC_BUYS] = "+2 Achats";
FrenchAbilityDescriptions[AbilityNames.PANIC_RETURN] = "Retourner Argument0 sur sa pile";
FrenchAbilityDescriptions[AbilityNames.PROGRESS] = "Placer Argument0 sur votre pioche";
FrenchAbilityDescriptions[AbilityNames.RAPID_EXPANSION_SET_ASIDE] = "Mettre de côté Argument0";
FrenchAbilityDescriptions[AbilityNames.RAPID_EXPANSION_PLAY_LATER] = "Jouer Argument0";
FrenchAbilityDescriptions[AbilityNames.SICKNESS] = "Malédiction sur votre pioche ou défausser 3 cartes";
FrenchAbilityDescriptions[AbilityNames.RIVER_SHRINE] = "Recevoir une carte coûtant jusqu'à 4";
FrenchAbilityDescriptions[AbilityNames.SAMURAI] = "+1 Pièce";
FrenchAbilityDescriptions[AbilityNames.DAIMYO_REPLAY] = "Rejouer Argument0";
FrenchAbilityDescriptions[AbilityNames.FORESIGHT_RETURN_TO_HAND] = FrenchAbilityDescriptions[AbilityNames.SAVE_RETURN_TO_HAND];