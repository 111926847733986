"use strict";

webclient.component("ratingDetails", {
    controller: ['leaderboardService', function (leaderboardService) {
        var self = this;
        self.getName = function () {
            return leaderboardService.getSelectedEntry().namedId.name;
        };
        self.getSkill = function () {
            return Math.round(10000 * leaderboardService.getSelectedEntry().skill) / 10000;
        };
        self.getDeviation = function () {
            return Math.round(10000 * leaderboardService.getSelectedEntry().deviation) / 10000;
        };
        self.getVolatility = function () {
            return Math.round(10000 * leaderboardService.getSelectedEntry().volatility) / 10000;
        };
        self.getLevel = function () {
            return Math.round(100 * leaderboardService.getSelectedEntry().getDetailedLevel()) / 100;
        };
        self.getSkillRange = function () {
            var skill = self.getSkill();
            var deviation = self.getDeviation();
            var low = Math.round(100 * skill - 2 * deviation) / 100;
            var high = Math.round(100 * skill + 2 * deviation) / 100;
            return low + " - " + high;
        };
        self.getGames = function () {
            return leaderboardService.getSelectedEntry().gameCount;
        };

        self.getLevelText = function () {
            return getPhrase(Phrases.LEVEL);
        };
        self.getNameText = function () {
            return getPhrase(Phrases.NAME);
        };
        self.getSkillText = function () {
            return getPhrase(Phrases.SKILL);
        };
        self.getDeviationText = function () {
            return getPhrase(Phrases.DEVIATION);
        };
        self.getVolatilityText = function () {
            return getPhrase(Phrases.VOLATILITY);
        };
        self.getSkillRangeText = function () {
            return getPhrase(Phrases.SKILL_RANGE);
        };
        self.getGamesText = function () {
            return getPhrase(Phrases.GAMES);
        };
    }],
    template: "\n            <div class=\"rating-details-container\">\n                <div class=\"rating-details\">\n                    <div class=\"rating-details-name\">{{$ctrl.getName()}}</div>\n                    <div class=\"rating-details-level-label\">{{::$ctrl.getLevelText()}}</div>\n                    <div class=\"rating-details-level\">{{$ctrl.getLevel()}}</div>\n                    <div class=\"rating-details-label rating-skill\">{{::$ctrl.getSkillText()}}</div>\n                    <div class=\"rating-details-value rating-skill\">{{$ctrl.getSkill()}}</div>\n                    <div class=\"rating-details-label rating-deviation\">{{::$ctrl.getDeviationText()}}</div>\n                    <div class=\"rating-details-value rating-deviation\">{{$ctrl.getDeviation()}}</div>\n                    <div class=\"rating-details-label rating-volatility\">{{::$ctrl.getVolatilityText()}}</div>\n                    <div class=\"rating-details-value rating-volatility\">{{$ctrl.getVolatility()}}</div>\n                    <div class=\"rating-details-label rating-games\">{{::$ctrl.getGamesText()}}</div>\n                    <div class=\"rating-details-value rating-games\">{{$ctrl.getGames()}}</div>\n                </div>\n            </div>                         \n            "
});