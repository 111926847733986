"use strict";

var TChineseQuestionDescriptions = {};

TChineseQuestionDescriptions[QuestionIds.GAME_RESOLVE_ABILITY] = ["執行必須的能力:", "(點擊以下選項)"];
TChineseQuestionDescriptions[QuestionIds.GAME_MAY_RESOLVE_ABILITY] = ["執行一個可選的能力", "(點擊以下選項)"];
TChineseQuestionDescriptions[QuestionIds.GAME_MAY_REACT_WITH] = ["做應對", "(點擊以下選項)"];
TChineseQuestionDescriptions[QuestionIds.GAME_TURN_SELECT] = ["選擇一個回合", "你想先執行哪一個回合?"];
TChineseQuestionDescriptions[QuestionIds.GAME_MAY_BUY] = ["購買卡片", "你可以購買卡片"];
TChineseQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING] = ["開始購買卡片", "你可以購買卡片"];
TChineseQuestionDescriptions[QuestionIds.GAME_MAY_BUY_NONCARDS] = ["購買階段，但不能購買卡片", "你可以購買卡片, 但不能購買卡片"];
TChineseQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING_NONCARDS] = ["開始購買, 但不能購買卡片。", "你可以購買, 但不能購買卡片"];
TChineseQuestionDescriptions[QuestionIds.GAME_REPAY_HOW_MUCH] = ["償還債務", "你可以償還債務"];
TChineseQuestionDescriptions[QuestionIds.GAME_SPEND_COIN_TOKENS] = ["消耗預算指示物", "你可以花掉預算指示物"];
TChineseQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_ACTION] = ["你可以從手上打出行動卡", "你可以打出行動"];
TChineseQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_TREASURES] = ["打出錢幣卡", "你可以打出錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.GAME_PLAY_ALL_TREASURES] = ["自動打出錢幣卡", "GAME_PLAY_ALL_TREASURES"];
TChineseQuestionDescriptions[QuestionIds.GAME_ACTION_PHASE] = ["開始你的行動階段", "你可以打出行動卡"];
TChineseQuestionDescriptions[QuestionIds.GAME_BUY_PHASE] = ["開始你的購買階段", "你可以購買卡片"];
TChineseQuestionDescriptions[QuestionIds.GAME_BUY_PHASE_NONCARDS] = ["你的購買階段", "你可以購買，但是不能購買卡片。"];
TChineseQuestionDescriptions[QuestionIds.GAME_CLEANUP_PHASE] = ["選擇在清除階段的效果，並且規劃順序", "選擇一個清除階段的效果"];
TChineseQuestionDescriptions[QuestionIds.GAME_USE_VILLAGER] = ["你可以使用村民.", "你可以使用村民"];
TChineseQuestionDescriptions[QuestionIds.GAME_USE_COFFERS] = ["你可以使用存款. ", "你可以使用存款."]; // TRANSLATE
TChineseQuestionDescriptions[QuestionIds.ARTISAN_GAIN] = ["獲得一張價值五元以下的卡片，並放入手中", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.ARTISAN_TOPDECK] = ["將一張你手上的卡片放到牌庫頂", "將一張卡片放到牌庫頂"];
TChineseQuestionDescriptions[QuestionIds.BANDIT] = ["移除PLAYER0的一張卡片。", "移除PLAYER0 的一張卡片。"];
TChineseQuestionDescriptions[QuestionIds.BUREAUCRAT] = ["展示一張手中的分數卡並放到牌庫頂", "將一張分數卡放到牌庫頂"];
TChineseQuestionDescriptions[QuestionIds.CELLAR] = ["棄調任意數量的卡片，抽取相等數量的卡片", "棄掉然後抽卡"];
TChineseQuestionDescriptions[QuestionIds.CHAPEL] = ["移除你手中至多四張卡片", "移除四張卡片"];
TChineseQuestionDescriptions[QuestionIds.HARBINGER_TOPDECK] = ["你可以將一張棄牌堆的卡片置頂", "你可以置頂"];
TChineseQuestionDescriptions[QuestionIds.LIBRARY] = ["你可以用覆蓋略過CARDS0。", "你可以略過CARDS0"];
TChineseQuestionDescriptions[QuestionIds.MILITIA] = ["棄到剩三張手牌。", "棄到剩三張"];
TChineseQuestionDescriptions[QuestionIds.MINE_TRASH] = ["你可以從手牌移除一張錢幣卡。獲得一張價值至多比他多3塊的錢幣卡到你的手牌。", "你可以升級一張錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.MINE_GAIN] = ["獲得一張錢幣卡到你的手牌價值至多AMOUNT0。", "獲得一張錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.MONEYLENDER] = ["你可以從你的手牌移除一張銅幣來換取+3塊。", "你可以移除一張銅幣"];
TChineseQuestionDescriptions[QuestionIds.POACHER] = ["棄掉Argument0。", "棄掉Argument0。"];
TChineseQuestionDescriptions[QuestionIds.REMODEL_TRASH] = ["從手牌移除一張卡片。獲得一張卡片價值至多比他多2塊。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.REMODEL_GAIN] = ["獲得一張價值至多AMOUNT0塊的卡片。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.SENTRY_TRASH] = ["移除任意張數卡片", "你可以移除"];
TChineseQuestionDescriptions[QuestionIds.SENTRY_DISCARD] = ["棄掉任意張數卡片", "你可以棄掉"];
TChineseQuestionDescriptions[QuestionIds.SENTRY_TOPDECK] = ["以任意順序放上你的牌庫", "以任意順序置頂"];
TChineseQuestionDescriptions[QuestionIds.THRONE_ROOM] = ["你可以打出一張行動卡從你的手牌兩次。", "你可以打出一張行動卡兩次"];
TChineseQuestionDescriptions[QuestionIds.VASSAL] = ["你可以打出CARDS0 從你的棄牌堆。", "你可以打出CARDS0"];
TChineseQuestionDescriptions[QuestionIds.WORKSHOP] = ["獲得一張價值至多4塊的卡片。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.TRASHING_TOKEN] = ["你可以從手牌移除一張卡片。", "你可以移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.ALMS] = ["獲得一張價值至多4塊的卡片。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.AMULET_MODE] = ["選擇以下一個效果: +1塊;或從手牌移除一張卡片;或獲得一張銀幣。", "選擇如何打出護身符"];
TChineseQuestionDescriptions[QuestionIds.AMULET_TRASH] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.ARTIFICER_DELAY] = ["你可以棄掉任意數量卡片。你可以獲得一張卡片價值等同於被棄掉的張數,放上你的牌庫。", "你可以使用技工的效果"];
TChineseQuestionDescriptions[QuestionIds.ARTIFICER_DISCARD] = ["棄掉任意數量卡片。你可以獲得一張卡片價值等同於被棄掉的張數,放上你的牌庫。", "棄掉, 然後獲得"];
TChineseQuestionDescriptions[QuestionIds.ARTIFICER_GAIN] = ["你可以獲得一張卡片價值等同於AMOUNT0,放上你的牌庫。", "你可以獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.BALL_FIRST] = ["獲得兩張卡片每張價值至多4塊。", "獲得兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.BALL_SECOND] = ["獲得另一張價值至多4塊的卡片。", "獲得另一張卡片"];
TChineseQuestionDescriptions[QuestionIds.BONFIRE] = ["從打出的卡片中移除至多兩張卡片。", "從打出的卡片中移除至多兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.DISCIPLE] = ["你可以打出一張行動卡從你的手牌兩次。再獲得一張。", "打出兩次, 然後再獲得一張"];
TChineseQuestionDescriptions[QuestionIds.DUNGEON] = ["棄掉兩張卡片。", "棄掉兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.FERRY] = ["將你的-2元指示物放上一個行動卡牌堆。", "放上-2元指示物"];
TChineseQuestionDescriptions[QuestionIds.FUGITIVE] = ["棄掉一張卡片。", "棄掉一張卡片"];
TChineseQuestionDescriptions[QuestionIds.GEAR] = ["從你的手牌覆蓋至多兩張卡片。下回合開始時, 將他們放入你的手牌。", "覆蓋至多兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.HAUNTED_WOODS] = ["將手牌以任意順序放上你的牌庫", "將手牌以任意順序放上你的牌庫"];
TChineseQuestionDescriptions[QuestionIds.HERO] = ["獲得一張錢幣卡。", "獲得一張錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.LOST_ARTS_NEW] = ["將你的+1行動指示物放上一個行動卡牌堆。", "移動+1行動指示物"];
TChineseQuestionDescriptions[QuestionIds.LOST_ARTS_MOVE] = ["將你的+1行動指示物放上一個行動卡牌堆。", "移動+1行動指示物"];
TChineseQuestionDescriptions[QuestionIds.MESSENGER_DISCARD] = ["你可以將你的牌庫放入棄牌堆。", "你可以棄掉牌庫"];
TChineseQuestionDescriptions[QuestionIds.MESSENGER_GAIN] = ["獲得一張價值至多4塊的卡片, 然後其他每位玩家再獲得一張。", "選擇一張價值至多4塊的卡片"];
TChineseQuestionDescriptions[QuestionIds.MISER] = ["選擇以下一個效果: 將放一張銅幣從你的手牌放上你的旅行區;或+AMOUNT0", "銅幣放上旅行區或+AMOUNT0?"];
TChineseQuestionDescriptions[QuestionIds.PATHFINDING_NEW] = ["將你的+1卡片指示物放上一個行動卡牌堆。", "移動+1卡片指示物"];
TChineseQuestionDescriptions[QuestionIds.PATHFINDING_MOVE] = ["將你的+1卡片指示物放上一個行動卡牌堆。", "移動+1卡片指示物"];
TChineseQuestionDescriptions[QuestionIds.PILGRIMAGE_FIRST] = ["你可以從你這回合打出的卡片中選擇至多3種不同的卡片然後再獲得一張。", "選擇三張卡片獲得"];
TChineseQuestionDescriptions[QuestionIds.PILGRIMAGE_SECOND] = ["你可以從你這回合打出的卡片中選擇另外2種不同的卡片然後再獲得一張。", "選擇兩張卡片獲得"];
TChineseQuestionDescriptions[QuestionIds.PILGRIMAGE_THIRD] = ["你可以從你這回合打出的卡片中選擇另外1種不同的卡片然後再獲得一張。", "選擇一張卡片獲得"];
TChineseQuestionDescriptions[QuestionIds.PLAN_NEW] = ["將你的移除指示物放上一個行動卡牌堆。", "移動移除指示物"];
TChineseQuestionDescriptions[QuestionIds.PLAN_MOVE] = ["將你的移除指示物放上一個行動卡牌堆。", "移動移除指示物"];
TChineseQuestionDescriptions[QuestionIds.QUEST_MODE] = ["從中選擇一種棄掉 一張攻擊卡, 兩張詛咒,或六張卡片。", "選擇你的試煉"];
TChineseQuestionDescriptions[QuestionIds.QUEST_ATTACK] = ["棄掉一張攻擊卡。", "棄掉一張攻擊卡"];
TChineseQuestionDescriptions[QuestionIds.QUEST_6CARDS] = ["棄掉六張卡片。", "棄掉六張卡片"];
TChineseQuestionDescriptions[QuestionIds.RATCATCHER] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_PLAY] = ["你可以移除此卡", "你可以移除此卡"];
TChineseQuestionDescriptions[QuestionIds.RAZE_ALL] = ["從手牌移除一張卡片或在打出中的此卡。", "移除一張卡片或移除此卡"];
TChineseQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_HAND] = ["你可以從手牌移除一張卡片。", "你可以移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.RAZE_CHOOSE_CARD] = ["選擇一張卡片到放入你的手牌。", "將一張卡片放入你的手牌"];
TChineseQuestionDescriptions[QuestionIds.SAVE] = ["覆蓋一張卡片從你的手牌, 然後在回合結束抽牌後放入你的手牌。", "覆蓋一張卡片"];
TChineseQuestionDescriptions[QuestionIds.SCOUTING_PARTY_DISCARD] = ["棄掉NUMBER0張卡片。", "棄掉NUMBER0張卡片。"];
TChineseQuestionDescriptions[QuestionIds.SCOUTING_PARTY_TOPDECK] = ["將剩的兩張卡片以任意順序放上你的牌庫。", "置頂這些卡片"];
TChineseQuestionDescriptions[QuestionIds.SEAWAY] = ["獲得一張行動卡價值至多4塊。將你的+1購買指示物放上該卡的供應區。", "獲得一張行動卡"];
TChineseQuestionDescriptions[QuestionIds.SOLDIER] = ["棄掉一張卡片。", "棄掉一張卡片"];
TChineseQuestionDescriptions[QuestionIds.STORYTELLER_FIRST] = ["從你的手牌打出至多3張金錢卡。", "打出至多3張金錢卡"];
TChineseQuestionDescriptions[QuestionIds.STORYTELLER_SECOND] = ["從你的手牌打出至多2張金錢卡。", "打出至多2張金錢卡"];
TChineseQuestionDescriptions[QuestionIds.STORYTELLER_THIRD] = ["你可以打出另外一張金錢卡從你的手牌。", "你可以打出另外一張金錢卡"];
TChineseQuestionDescriptions[QuestionIds.TEACHER_CHOOSE_TOKEN] = ["選擇其中一個指示物移動。", "選擇其中一個指示物"];
TChineseQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_MOVE] = ["將你的+1行動指示物放上 一個你沒有指示物在上面的行動卡供應區。", "移動+1行動指示物"];
TChineseQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_NEW] = ["將你的+1行動指示物放上一個你沒有指示物在上面的行動卡供應區。", "移動+1行動指示物"];
TChineseQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_MOVE] = ["將你的+1卡片指示物放上一個你沒有指示物在上面的行動卡供應區。", "移動+1卡片指示物"];
TChineseQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_NEW] = ["將你的+1卡片指示物放上一個你沒有指示物在上面的行動卡供應區。", "移動+1卡片指示物"];
TChineseQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_MOVE] = ["將你的+1元指示物放上一個你沒有指示物在上面的行動卡供應區。", "移動+1元指示物"];
TChineseQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_NEW] = ["將你的+1元指示物放上一個你沒有指示物在上面的行動卡供應區。", "移動+1元指示物"];
TChineseQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_MOVE] = ["將你的+1購買指示物放上一個你沒有指示物在上面的行動卡供應區。", "移動+1購買指示物"];
TChineseQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_NEW] = ["將你的+1購買指示物放上一個你沒有指示物在上面的行動卡供應區。", "移動+1購買指示物"];
TChineseQuestionDescriptions[QuestionIds.TRADE] = ["從手牌移除至多兩張卡片。獲得等同於你移除張數的銀幣。", "移除至多兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.TRAINING_NEW] = ["將你的+1塊指示物放上一個行動卡牌堆。", "移動+1塊指示物"];
TChineseQuestionDescriptions[QuestionIds.TRAINING_MOVE] = ["將你的+1塊指示物放上一個行動卡牌堆。", "移動+1塊指示物"];
TChineseQuestionDescriptions[QuestionIds.TRANSMOGRIFY_TRASH] = ["從手牌移除一張卡片, 然後獲得一張價值至多比他多1塊的卡片放入你的手牌。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.TRANSMOGRIFY_GAIN] = ["獲得一張價值至多AMOUNT0塊的卡片放入你的手牌。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.APOTHECARY] = ["將這些卡片以任意順序放回你的牌庫頂。", "置頂這些卡片"];
TChineseQuestionDescriptions[QuestionIds.APPRENTICE] = ["從手牌移除一張卡片。抽取等同於該卡價值張數的卡片。+兩張卡片, 如果價值中有[P]。", "移除, 然後抽牌"];
TChineseQuestionDescriptions[QuestionIds.GOLEM] = ["選擇其中一個行動先打出。", "選擇打出順序"];
TChineseQuestionDescriptions[QuestionIds.HERBALIST] = ["你可以將一張在打出中的金錢卡放上牌庫頂。", "你可以將置頂一張錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.HERBALISTS] = ["What Treasures to topdeck", "Topdeck up to Argument0 Treasures"];
TChineseQuestionDescriptions[QuestionIds.SCRYING_POOL_YOU] = ["保留或棄掉CARDS0", "你: 保留或棄掉CARDS0"];
TChineseQuestionDescriptions[QuestionIds.SCRYING_POOL_OTHER] = ["選擇PLAYER1要保留或棄掉CARDS0。", "PLAYER1: 保留或棄掉CARDS0。"];
TChineseQuestionDescriptions[QuestionIds.TRANSMUTE] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.UNIVERSITY] = ["你可以獲得一張價值至多5塊的行動卡。", "你可以獲得一張行動卡"];
TChineseQuestionDescriptions[QuestionIds.FOLLOWERS] = ["棄到剩三張手牌。", "棄到剩三張"];
TChineseQuestionDescriptions[QuestionIds.DIADEM] = ["Use Villagers before getting Coins for Actions?", "Use Villagers?"];
TChineseQuestionDescriptions[QuestionIds.HAMLET] = ["你可以任意選擇 棄掉一張卡片+1行動, 棄掉一張卡片+1購買", "你可以棄掉"];
TChineseQuestionDescriptions[QuestionIds.HAMLET_ACTION] = ["棄掉一張卡片來換取+1行動。", "棄掉來換取+1行動"];
TChineseQuestionDescriptions[QuestionIds.HAMLET_BUY] = ["棄掉一張卡片來換取+1購買。", "棄掉來換取+1購買"];
TChineseQuestionDescriptions[QuestionIds.HAMLET_BOTH] = ["棄掉兩張卡片。", "棄掉兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.HORN_OF_PLENTY] = ["獲得一張價值至多AMOUNT0塊的卡片。If it's 一張分數卡,移除this。", "獲得一張卡片至多AMOUNT0"];
TChineseQuestionDescriptions[QuestionIds.HORSE_TRADERS] = ["棄掉兩張卡片。", "棄掉兩張卡片。"];
TChineseQuestionDescriptions[QuestionIds.JESTER] = ["選擇誰獲得CARDS0: 你或PLAYER1。", "選擇誰獲得CARDS0"];
TChineseQuestionDescriptions[QuestionIds.REMAKE_TRASH_FIRST] = ["重複兩次: 從手牌移除一張卡片, 然後獲得一張價值剛好多該卡1塊的卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.REMAKE_TRASH_SECOND] = ["從手牌移除另一張卡片, 然後獲得一張價值剛好多該卡1塊的卡片。", "移除另一張卡片"];
TChineseQuestionDescriptions[QuestionIds.REMAKE_GAIN_FIRST] = ["獲得一張價值COST0塊的卡片", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.REMAKE_GAIN_SECOND] = ["獲得一張價值COST0塊的卡片", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.TOURNAMENT_GAIN_PRIZE_OR_DUCHY] = ["獲得一張獎賞卡或一張公國放上你的牌庫頂。", "獲得一張獎賞卡或一張公國"];
TChineseQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_PRIZE] = ["你可以獲得一張獎賞卡放上你的牌庫。", "你可以獲得一張獎賞卡"];
TChineseQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_DUCHY] = ["你可以獲得一張公國放上你的牌庫。", "你可以獲得一張公國"];
TChineseQuestionDescriptions[QuestionIds.TOURNAMENT_REVEAL] = ["你可以展示一張行省。", "你可以展示一張行省"];
TChineseQuestionDescriptions[QuestionIds.TRUSTY_STEED] = ["選擇如何打出良駒。", "選擇如何打出良駒"];
TChineseQuestionDescriptions[QuestionIds.YOUNG_WITCH] = ["棄掉兩張卡片。", "棄掉兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.YOUNG_WITCH_BANE] = ["你可以展示CARDS0來阻止小魔女。", "展示CARDS0來阻止小魔女?"];
TChineseQuestionDescriptions[QuestionIds.ALTAR_TRASH] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.ALTAR_GAIN] = ["獲得一張價值至多5塊的卡片。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.ARMORY] = ["獲得一張價值至多4塊的卡片放上你的牌庫頂。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.CATACOMBS_MODE] = ["將CARDS0放入你的手牌;或棄掉然後抽三張卡片。", "收下或抽新的?"];
TChineseQuestionDescriptions[QuestionIds.CATACOMBS_GAIN] = ["獲得一張價值低於此卡的卡片AMOUNT0。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.COUNT_PAY] = ["選擇以下一個效果: 棄掉兩張卡片;或將 一張卡片從你的手牌放上牌庫頂;或獲得一張銅幣。", "選擇代價"];
TChineseQuestionDescriptions[QuestionIds.COUNT_DISCARD] = ["棄掉兩張卡片。", "棄掉兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.COUNT_TOPDECK] = ["將一張卡片從你的手牌放上牌庫頂。", "置頂一張卡片"];
TChineseQuestionDescriptions[QuestionIds.COUNT_PROFIT] = ["選擇以下一個效果: +3塊;或移除你的手牌;或獲得一張公國。", "選擇效果"];
TChineseQuestionDescriptions[QuestionIds.COUNTERFEIT] = ["你可以從手牌打出一張錢幣卡兩次,移除該卡。", "打出兩次, 然後移除"];
TChineseQuestionDescriptions[QuestionIds.CULTIST] = ["你可以從手牌打出一張邪教徒。", "打出邪教徒?"];
TChineseQuestionDescriptions[QuestionIds.DAME_ANNA] = ["你可以從手牌移除至多兩張卡片。", "移除至多兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.DAME_NATALIE] = ["你可以獲得一張價值至多3塊的卡片。", "你可以獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.DEATH_CART_INPLAY] = ["你可以從手牌移除一張行動卡。若你沒有移除,則移除在打出中的運屍車。", "你可以移除一張行動卡"];
TChineseQuestionDescriptions[QuestionIds.DEATH_CART_GONE] = ["你可以從手牌移除一張行動卡, 若你沒有移除,則移除在打出中的運屍車。", "你可以移除一張行動卡"];
TChineseQuestionDescriptions[QuestionIds.FORAGER] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.KNIGHTS] = ["選擇一張卡片移除。", "選擇一張卡片移除"];
TChineseQuestionDescriptions[QuestionIds.KNIGHTS_ALREADY_DIES] = ["選擇一張卡片移除。(CARDS0已經死了)", "選擇一張卡片移除"];
TChineseQuestionDescriptions[QuestionIds.KNIGHTS_STILL_MATTERS] = ["選擇一張卡片移除(若你移除CARDS0, CARDS1會被移除)", "選擇一張卡片移除"];
TChineseQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_EXPAND] = ["你可以移除從你的手牌 然後獲得一張價值至多比他多3塊的卡片。", "你可以移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.GRAVEROBBER_EXPAND] = ["移除從你的手牌 然後獲得一張價值至多比他多3塊的卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_GAIN] = ["你可以獲得從移除區放上你的牌庫。", "你可以獲得從移除"];
TChineseQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN] = ["從移除區獲得放上你的牌庫。", "從移除區獲得"];
TChineseQuestionDescriptions[QuestionIds.GRAVEROBBER_COMPLEX] = ["你打出盜墓者", "你打出盜墓者"];
TChineseQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN_SUPPLY] = ["獲得一張價值至多AMOUNT0塊的卡片。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.HERMIT_TRASH] = ["你可以從棄牌堆或手牌移除一張非金錢卡的卡片 。", "你可以移除"];
TChineseQuestionDescriptions[QuestionIds.HERMIT_GAIN] = ["獲得一張價值至多3塊的卡片。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.HUNTING_GROUNDS] = ["獲得1張公國或3張莊園。", "1張公國或3張莊園?"];
TChineseQuestionDescriptions[QuestionIds.IRONMONGER] = ["你可以保留或棄掉CARDS0。", "你可以棄掉CARDS0"];
TChineseQuestionDescriptions[QuestionIds.JUNK_DEALER] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.MERCENARY_ONE] = ["你可以從手牌移除一張卡片 (但沒有效果)。", "你可以移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.MERCENARY_MULTIPLE] = ["你可以從手牌移除兩張卡片。", "你可以移除兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.MERCENARY_DISCARD] = ["棄到剩三張。", "棄到剩三張"];
TChineseQuestionDescriptions[QuestionIds.MYSTIC] = ["猜你牌庫頂的牌。", "猜一張卡片"];
TChineseQuestionDescriptions[QuestionIds.PILLAGE] = ["選擇棄掉PLAYER0的一張卡片。", "棄掉PLAYER0的手牌"];
TChineseQuestionDescriptions[QuestionIds.PROCESSION_PLAY] = ["你可以從你的手牌打出一張行動卡兩次。", "你可以打出一張行動卡兩次。"];
TChineseQuestionDescriptions[QuestionIds.PROCESSION_GAIN] = ["獲得一張價值等同於AMOUNT0的行動卡。", "獲得一張行動卡"];
TChineseQuestionDescriptions[QuestionIds.RATS_TRASH_ANY] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.RATS_TRASH_OTHER] = ["從手牌移除一張除了老鼠之外的卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.REBUILD_SKIP] = ["宣告一張分數卡(或銅幣)略過。", "宣告一張卡片到略過"];
TChineseQuestionDescriptions[QuestionIds.REBUILD_GAIN] = ["獲得一張分數卡價值至多AMOUNT0。", "獲得一張分數卡"];
TChineseQuestionDescriptions[QuestionIds.ROGUE_TRASH] = ["選擇一張卡片從PLAYER0移除。", "移除一張卡片從PLAYER0"];
TChineseQuestionDescriptions[QuestionIds.ROGUE_GAIN] = ["從移除區獲得一張價值3~6塊的卡片。", "從移除區獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.SCAVENGER] = ["你可以將你的牌庫棄掉。", "你可以棄掉你的牌庫"];
TChineseQuestionDescriptions[QuestionIds.SCAVENGER_TOPDECK] = ["將放一張卡片從你的棄牌堆放上你的牌庫頂。", "置頂一張卡片"];
TChineseQuestionDescriptions[QuestionIds.SIR_MICHAEL] = ["棄到剩三張。", "棄到剩三張"];
TChineseQuestionDescriptions[QuestionIds.SQUIRE_MODE] = ["選擇以下一個效果: +2行動;或+2購買;或獲得一張銀幣。", "選擇效果"];
TChineseQuestionDescriptions[QuestionIds.SQUIRE_GAIN] = ["獲得一張攻擊卡。", "獲得一張攻擊卡"];
TChineseQuestionDescriptions[QuestionIds.STOREROOM_CARDS] = ["棄掉任意數量卡片, 然後抽同等數量。", "棄掉來換取抽牌"];
TChineseQuestionDescriptions[QuestionIds.STOREROOM_COINS] = ["棄掉任意數量卡片, 每棄掉一張+1塊。", "棄掉來換取錢"];
TChineseQuestionDescriptions[QuestionIds.SURVIVORS] = ["以任意順序置頂這兩張卡片,或全棄掉。", "置頂或棄掉"];
TChineseQuestionDescriptions[QuestionIds.URCHIN] = ["棄到剩4張卡片。", "棄到剩4張卡片"];
TChineseQuestionDescriptions[QuestionIds.WANDERING_MINSTREL] = ["將行動卡以任意順序放回。", "以任意順序放回"];
TChineseQuestionDescriptions[QuestionIds.ADVANCE_TRASH] = ["你可以從手牌移除一張行動卡。", "你可以移除一張行動卡"];
TChineseQuestionDescriptions[QuestionIds.ADVANCE_GAIN] = ["獲得一張行動卡價值至多6塊。", "獲得一張行動卡"];
TChineseQuestionDescriptions[QuestionIds.ANNEX] = ["選擇至多5張卡片以任意順序放入你的牌庫。", "選擇至多5張卡片"];
TChineseQuestionDescriptions[QuestionIds.ARCHIVE] = ["選擇一張卡片放入你的手牌。", "選擇一張卡片"];
TChineseQuestionDescriptions[QuestionIds.ARENA] = ["你可以棄掉一張行動卡來換取NUMBNER0分。", "競技場: 棄掉來換取NUMBER0分?"];
TChineseQuestionDescriptions[QuestionIds.BANQUET] = ["獲得一張價值至多5塊的非分數卡。", "獲得一張非分數卡"];
TChineseQuestionDescriptions[QuestionIds.BUSTLING_VILLAGE] = ["你可以將一張拓荒者 放入你的手牌。", "你可以拿一張拓荒者"];
TChineseQuestionDescriptions[QuestionIds.CATAPULT_TRASH] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.CATAPULT_DISCARD] = ["棄到剩三張。", "棄到剩三張"];
TChineseQuestionDescriptions[QuestionIds.CHARM_MODE] = ["選擇如何打出幸運物", "選擇如何打出幸運物"];
TChineseQuestionDescriptions[QuestionIds.CHARM_GAIN] = ["除了CARDS0之外獲得一張價值AMOUNT1塊的卡片。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.CROWN_ACTION] = ["你可以從手牌打出一張行動卡兩次。", "你可以打出一張行動卡兩次"];
TChineseQuestionDescriptions[QuestionIds.CROWN_TREASURE] = ["你可以從手牌打出一張錢幣卡兩次。", "你可以打出一張錢幣卡兩次"];
TChineseQuestionDescriptions[QuestionIds.DONATE] = ["移除任意數量卡片。", "移除任意數量"];
TChineseQuestionDescriptions[QuestionIds.ENCAMPMENT] = ["你可以從手牌展示一張金幣或掠奪品。", "你可以展示金幣或掠奪品"];
TChineseQuestionDescriptions[QuestionIds.ENGINEER_GAIN] = ["獲得一張價值至多4塊的卡片。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.ENGINEER_TRASH] = ["你可以移除工程師。", "你可以移除工程師。"];
TChineseQuestionDescriptions[QuestionIds.FORUM] = ["棄掉兩張卡片。", "棄掉兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.GLADIATOR_REVEAL] = ["從你的手牌展示一張卡片。", "展示一張卡片"];
TChineseQuestionDescriptions[QuestionIds.GLADIATOR_PRETEND] = ["假裝正在思考要不要展示。", "假裝正在思考"];
TChineseQuestionDescriptions[QuestionIds.GLADIATOR_BLOCK] = ["你可以從手牌展示CARDS0。", "你可以展示CARDS0"];
TChineseQuestionDescriptions[QuestionIds.HAUNTED_CASTLE] = ["將兩張卡片從手牌放上牌庫頂。", "將置頂兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.LEGIONARY_REVEAL] = ["你可以從手牌展示一張金幣。", "你可以展示一張金幣"];
TChineseQuestionDescriptions[QuestionIds.LEGIONARY_DISCARD] = ["手牌棄到剩兩張卡片。", "棄到剩兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_NEW] = ["舉債至多40D。", "舉債至多40D"];
TChineseQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_OVERBID] = ["舉債至多40D。現在 PLAYER0喊價最高到NUMBER1。", "舉債至多40D"];
TChineseQuestionDescriptions[QuestionIds.OPULENT_CASTLE] = ["棄掉任意數量的分數卡, 每棄掉一張+2塊。", "棄掉來換取錢"];
TChineseQuestionDescriptions[QuestionIds.RITUAL] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.SACRIFICE] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.SALT_THE_EARTH] = ["從供應區移除一張分數卡。", "移除一張分數卡"];
TChineseQuestionDescriptions[QuestionIds.SETTLERS] = ["你可以將一張銅幣從你的棄牌堆放入你的手牌。", "你可以拿一張銅幣"];
TChineseQuestionDescriptions[QuestionIds.SMALL_CASTLE_IN_PLAY] = ["移除打出的推進之城或手牌的一張城堡卡。", "移除一張城堡卡"];
TChineseQuestionDescriptions[QuestionIds.SMALL_CASTLE_GONE] = ["從手牌移除一張城堡卡。", "移除一張城堡卡"];
TChineseQuestionDescriptions[QuestionIds.SMALL_CASTLE_GAIN] = ["獲得一張城堡卡。", "獲得一張城堡卡"];
TChineseQuestionDescriptions[QuestionIds.SPRAWLING_CASTLE] = ["獲得一張公國或3張莊園。", "一張公國或3張莊園?"];
TChineseQuestionDescriptions[QuestionIds.TAX] = ["選擇一個供應區來加上2負債。", "加上2負債到一個供應區"];
TChineseQuestionDescriptions[QuestionIds.TEMPLE] = ["移除手牌1 - 3種不同的卡片。", "移除1 - 3張卡片"];
TChineseQuestionDescriptions[QuestionIds.WILD_HUNT] = ["選擇其中一項 +3卡片或獲得莊園及分數", "卡片或分數?"];
TChineseQuestionDescriptions[QuestionIds.ADVISOR] = ["棄掉PLAYER0哪一張卡片?", "PLAYER0會拿到剩下兩張"];
TChineseQuestionDescriptions[QuestionIds.BUTCHER_TRASH] = ["你可以從手牌移除一張卡片。", "你可以移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.BUTCHER_GAIN] = ["獲得一張價值至多AMOUNT0的卡片。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.DOCTOR_PLAY] = ["宣告一張卡片移除。", "宣告一張卡片"];
TChineseQuestionDescriptions[QuestionIds.DOCTOR_TOPDECK] = ["將這些卡片以任意順序放回。", "置頂這些卡片"];
TChineseQuestionDescriptions[QuestionIds.DOCTOR_OVERPAY] = ["你可以超付來使用大夫。", "你可以超付"];
TChineseQuestionDescriptions[QuestionIds.DOCTOR_MODE] = ["要對CARDS0做什麼?", "要對CARDS0做什麼?"];
TChineseQuestionDescriptions[QuestionIds.DOCTOR_MODE_MANY] = ["要對CARDS0做什麼? (還有NUMBER1張)", "CARDS0? (還有NUMBER1張)"];
TChineseQuestionDescriptions[QuestionIds.HERALD_OVERPAY] = ["你可以超付來使用置頂。", "你可以超付"];
TChineseQuestionDescriptions[QuestionIds.HERALD_CHOOSE] = ["將一張卡片從你的棄牌堆放上牌庫頂。", "置頂一張卡片"];
TChineseQuestionDescriptions[QuestionIds.JOURNEYMAN] = ["宣告不會被抽起的卡片。", "宣告一張卡片"];
TChineseQuestionDescriptions[QuestionIds.MASTERPIECE] = ["你可以超付來換取銀幣。", "你可以超付"];
TChineseQuestionDescriptions[QuestionIds.PLAZA_DELAY] = ["你可以棄掉一張錢幣卡來換取存款。", "你可以棄掉一張錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.PLAZA_DISCARD] = ["棄掉一張錢幣卡。", "棄掉一張錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.STONEMASON_TRASH] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.STONEMASON_GAIN1] = ["獲得兩張每張價值不多於AMOUNT0塊的卡片。", "獲得兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.STONEMASON_GAIN2] = ["獲得另一張價值不多於AMOUNT0的卡片。", "獲得另一張卡片"];
TChineseQuestionDescriptions[QuestionIds.STONEMASON_OVERPAY] = ["你可以超付來換取行動卡。", "你可以超付"];
TChineseQuestionDescriptions[QuestionIds.STONEMASON_GAIN_EQUAL] = ["獲得另外一張價值AMOUNT0塊的行動卡。", "獲得另外一張行動卡"];
TChineseQuestionDescriptions[QuestionIds.TAXMAN_TRASH] = ["你可以從手牌移除一張錢幣卡。", "你可以移除一張錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.TAXMAN_GAIN] = ["獲得一張價值至多AMOUNT0塊的錢幣卡放上你的牌庫。", "獲得一張錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.BORDER_VILLAGE] = ["獲得一張價值低於AMOUNT0塊的卡片。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.CARTOGRAPHER_DISCARD] = ["棄掉任意數量。", "棄掉任意數量"];
TChineseQuestionDescriptions[QuestionIds.CARTOGRAPHER_TOPDECK] = ["將剩餘卡片以任意順序放上牌庫頂。", "將置頂這些卡片"];
TChineseQuestionDescriptions[QuestionIds.DEVELOP_TRASH] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.DEVELOP_GAIN1] = ["獲得一張價值AMOUNT0塊或Argument1塊的卡片並放上牌庫頂。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.DEVELOP_GAIN2] = ["獲得一張價值至多AMOUNT0的卡片放上你的牌庫。", "獲得另一張卡片"];
TChineseQuestionDescriptions[QuestionIds.DUCHESS] = ["你可以棄掉CARDS0。", "你可以棄掉CARDS0"];
TChineseQuestionDescriptions[QuestionIds.EMBASSY] = ["棄掉3張卡片。", "棄掉3張卡片"];
TChineseQuestionDescriptions[QuestionIds.FARMLAND_TRASH] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.FARMLAND_GAIN] = ["獲得一張卡片價值等同於AMOUNT0。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.HAGGLER] = ["獲得一張價值不多於AMOUNT0的非分數卡。", "獲得一張非分數卡"];
TChineseQuestionDescriptions[QuestionIds.ILL_GOTTEN_GAINS] = ["你可以獲得一張銅幣到你的手牌。", "你可以獲得一張銅幣"];
TChineseQuestionDescriptions[QuestionIds.INN_DISCARD] = ["棄掉兩張卡片。", "棄掉兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.INN_SHUFFLE] = ["將任意數量的行動卡從棄牌堆洗入你的牌庫。", "取回行動卡"];
TChineseQuestionDescriptions[QuestionIds.JACK_DISCARD] = ["你可以棄掉CARDS0。", "你可以棄掉CARDS0"];
TChineseQuestionDescriptions[QuestionIds.JACK_TRASH] = ["你可以從手牌移除一張非金錢卡。", "你可以移除一張非金錢卡"];
TChineseQuestionDescriptions[QuestionIds.MANDARIN_PLAY] = ["將一張卡片從手牌放上你的牌庫頂。", "將置頂一張卡片"];
TChineseQuestionDescriptions[QuestionIds.MANDARIN_GAIN] = ["將所有這回合打出的金錢卡以任意順序放上你的牌庫頂。", "置頂你的金錢卡"];
TChineseQuestionDescriptions[QuestionIds.MARGRAVE] = ["棄到剩三張手牌。", "棄到剩三張"];
TChineseQuestionDescriptions[QuestionIds.NOBLE_BRIGAND] = ["選擇一張錢幣卡移除。", "選擇一張錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.OASIS] = ["棄掉一張卡片。", "棄掉一張卡片"];
TChineseQuestionDescriptions[QuestionIds.ORACLE_DISCARD] = ["你可以棄掉PLAYER1的CARDS0。", "將CARDS0棄掉?"];
TChineseQuestionDescriptions[QuestionIds.ORACLE_TOPDECK] = ["將這些卡片以任意順序放回你的牌庫頂。", "置頂這些卡片"];
TChineseQuestionDescriptions[QuestionIds.SCHEME] = ["你可以選擇一張卡片置頂", "你可以置頂一張卡片"];
TChineseQuestionDescriptions[QuestionIds.SCHEMES] = ["置頂哪些卡片?", "置頂至多Argument0張卡片"];
TChineseQuestionDescriptions[QuestionIds.SPICE_MERCHANT_TRASH] = ["你可以從手牌移除一張錢幣卡。", "你可以移除一張錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.SPICE_MERCHANT_MODE] = ["選擇以下一個效果: +兩張卡片 然後 +1行動;或+1購買 然後 +2塊。", "選擇效果"];
TChineseQuestionDescriptions[QuestionIds.STABLES] = ["你可以棄掉一張錢幣卡。", "你可以棄掉一張錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.TRADER_TRASH] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.BARON] = ["你可以棄掉1張莊園。", "你可以棄掉1張莊園"];
TChineseQuestionDescriptions[QuestionIds.COURTIER_REVEAL] = ["從你的手牌展示一張卡片。", "展示一張卡片"];
TChineseQuestionDescriptions[QuestionIds.COURTYARD] = ["將一張卡片從你的手牌放上牌庫頂。", "置頂一張卡片"];
TChineseQuestionDescriptions[QuestionIds.DIPLOMAT] = ["棄掉3張卡片。", "棄掉3張卡片"];
TChineseQuestionDescriptions[QuestionIds.IRONWORKS] = ["獲得一張價值至多4塊的卡片。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.LURKER_MAY_TRASH] = ["你可以移除一張行動卡從供應區。", "你可以移除從供應區"];
TChineseQuestionDescriptions[QuestionIds.LURKER_TRASH] = ["從供應區移除一張行動卡。", "從供應區移除"];
TChineseQuestionDescriptions[QuestionIds.LURKER_MAY_GAIN] = ["你可以從移除區獲得一張行動卡。", "你可以獲得行動卡"];
TChineseQuestionDescriptions[QuestionIds.LURKER_GAIN] = ["從移除區獲得一張行動卡。", "獲得行動卡"];
TChineseQuestionDescriptions[QuestionIds.MASQUERADE_PASS] = ["傳遞一張卡片給PLAYER0。", "傳遞 一張卡片到PLAYER0"];
TChineseQuestionDescriptions[QuestionIds.MASQUERADE_TRASH] = ["你可以從手牌移除一張卡片。", "你可以移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.MILL] = ["你可以棄掉兩張卡片", "你可以棄掉兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.MINING_VILLAGE] = ["你可以移除礦業小鎮。", "你可以移除礦業小鎮"];
TChineseQuestionDescriptions[QuestionIds.MINION] = ["選擇以下一個效果: +2塊或重抽4張手牌。", "選擇錢或重抽手牌"];
TChineseQuestionDescriptions[QuestionIds.NOBLES] = ["選擇以下一個效果: +3卡片;或+2行動。", "選擇+3卡片或+2行動"];
TChineseQuestionDescriptions[QuestionIds.PATROL] = ["將這些卡片以任意順序放回。", "置頂這些卡片"];
TChineseQuestionDescriptions[QuestionIds.PAWN] = ["選擇如何打出小兵。", "選擇效果"];
TChineseQuestionDescriptions[QuestionIds.REPLACE_TRASH] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.REPLACE_GAIN] = ["獲得一張價值至多AMOUNT0塊的卡片。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.SECRET_PASSAGE_TOPDECK] = ["將一張卡片放上你的牌庫頂。", "置頂一張卡片"];
TChineseQuestionDescriptions[QuestionIds.SECRET_PASSAGE_INSERT] = ["選擇一張卡片放入你的牌庫。", "選擇一張卡片與一個位置"];
TChineseQuestionDescriptions[QuestionIds.STEWARD_MODE] = ["選擇以下一個效果: +兩張卡片;或+2塊;或從你的手牌移除兩張卡片。", "選擇效果"];
TChineseQuestionDescriptions[QuestionIds.STEWARD_TRASH] = ["選擇兩張卡片移除。", "選擇兩張卡片移除"];
TChineseQuestionDescriptions[QuestionIds.SWINDLER] = ["選擇一張給PLAYER0的卡片。", "很棒的禮物。"];
TChineseQuestionDescriptions[QuestionIds.TRADING_POST] = ["從手牌移除兩張卡片。", "移除兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.UPGRADE_TRASH] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.UPGRADE_GAIN] = ["獲得一張價值AMOUNT0塊的卡片。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.WISHING_WELL] = ["猜你的牌庫頂是哪一張卡片。", "猜一張卡片"];
TChineseQuestionDescriptions[QuestionIds.SUMMON] = ["獲得一張價值至多4塊的行動卡。", "召喚一張卡片"];
TChineseQuestionDescriptions[QuestionIds.GOVERNOR_MODE] = ["選擇如何打出總督", "選擇如何打出總督"];
TChineseQuestionDescriptions[QuestionIds.GOVERNOR_TRASH] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.GOVERNOR_MAY_TRASH] = ["你可以從手牌移除一張卡片。", "你可以移除"];
TChineseQuestionDescriptions[QuestionIds.GOVERNOR_GAIN] = ["獲得一張卡片價值等同於Argument0", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.SAUNA_CHAIN] = ["你可以打出一張冰湖", "你可以打出冰湖"];
TChineseQuestionDescriptions[QuestionIds.SAUNA_MAY_TRASH] = ["你可以移除一張卡片", "移除你手上一張卡"];
TChineseQuestionDescriptions[QuestionIds.AVANTO_CHAIN] = ["你可以打出一張桑拿", "你可以打出桑拿"];

TChineseQuestionDescriptions[QuestionIds.BISHOP_SELF] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.BISHOP_OTHER] = ["你可以從手牌移除一張卡片。", "你可以移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.CONTRABAND] = ["宣告一張卡片PLAYER0這回合不購買。", "禁一張卡片"];
TChineseQuestionDescriptions[QuestionIds.COUNTING_HOUSE] = ["將幾張銅幣放入你的手牌?", "錢!"];
TChineseQuestionDescriptions[QuestionIds.EXPAND_TRASH] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.EXPAND_GAIN] = ["獲得一張價值至多AMOUNT0塊的卡片。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.FORGE_TRASH] = ["從你的手牌移除任意數量卡片。", "移除任意數量"];
TChineseQuestionDescriptions[QuestionIds.FORGE_GAIN] = ["獲得一張價值AMOUNT0塊的卡片。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.GOONS] = ["棄到剩三張手牌。", "棄到剩三張"];
TChineseQuestionDescriptions[QuestionIds.KINGS_COURT] = ["你可以打出一張行動卡三次。", "你可以打出一張行動卡三次"];
TChineseQuestionDescriptions[QuestionIds.LOAN] = ["棄掉或移除CARDS0。", "棄掉或移除CARDS0"];
TChineseQuestionDescriptions[QuestionIds.MINT] = ["你可以展示一張錢幣卡從你的手牌。再獲得一張。", "複製一張錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.MOUNTEBANK] = ["你可以棄掉一張詛咒。", "你可以棄掉一張詛咒"];
TChineseQuestionDescriptions[QuestionIds.RABBLE] = ["將這些卡片以任意順序放回。", "置頂這些卡片"];
TChineseQuestionDescriptions[QuestionIds.TRADE_ROUTE] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.VAULT_SELF] = ["棄掉任意數量卡片。", "棄掉任意數量"];
TChineseQuestionDescriptions[QuestionIds.VAULT_OTHER] = ["你可以棄掉兩張卡片。", "你可以棄掉兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.AMBASSADOR_REVEAL] = ["從你的手牌展示一張卡片。", "從你的手牌展示一張卡片"];
TChineseQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_ONE] = ["你可以放回CARDS0供應區。", "你可以放回CARDS0"];
TChineseQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_TWO] = ["放回0, 1或2張到CARDS0供應區。", "放回 0, 1或2"];
TChineseQuestionDescriptions[QuestionIds.EMBARGO] = ["移動一個封港令指示物到一個供應區。", "下令封港"];
TChineseQuestionDescriptions[QuestionIds.EXPLORER] = ["你可以展示一張行省。", "你可以展示一張行省"];
TChineseQuestionDescriptions[QuestionIds.GHOST_SHIP] = ["將放NUMBER0張卡片放上你的牌庫頂。", "置頂NUMBER0張卡片"];
TChineseQuestionDescriptions[QuestionIds.HAVEN] = ["覆蓋一張卡片。", "覆蓋一張卡片"];
TChineseQuestionDescriptions[QuestionIds.ISLAND] = ["將一張卡片從手牌放上你的島嶼板塊。", "將一張卡片放上你的板塊"];
TChineseQuestionDescriptions[QuestionIds.LOOKOUT_TRASH] = ["移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.LOOKOUT_DISCARD] = ["棄掉一張卡片。", "棄掉一張卡片"];
TChineseQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_TAKE] = ["你可以將CARDS0 放入你的手牌。", "你可以拿這些卡片"];
TChineseQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_SET_ASIDE] = ["你可以將你牌庫頂的一張卡片放上板塊。", "你可以放上板塊"];
TChineseQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_BOTH] = ["放一張卡片到板塊上或將 CARDS0放入手牌。", "選擇效果?"];
TChineseQuestionDescriptions[QuestionIds.NAVIGATOR] = ["以任意順序置頂這5張卡片,或全棄掉。", "置頂或棄掉"];
TChineseQuestionDescriptions[QuestionIds.PEARL_DIVER] = ["你可以將CARDS0放上牌庫頂。", "你可以將CARDS0置頂"];
TChineseQuestionDescriptions[QuestionIds.PIRATE_SHIP_MODE] = ["選擇以下一個效果: +AMOUNT0或攻擊。", "+AMOUNT0或攻擊"];
TChineseQuestionDescriptions[QuestionIds.PIRATE_SHIP_TRASH] = ["選擇一張錢幣卡移除。", "選擇一張錢幣卡移除"];
TChineseQuestionDescriptions[QuestionIds.SALVAGER] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.SMUGGLERS_MAY_GAIN] = ["你可以再獲得一張。", "你可以獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.SMUGGLERS_GAIN] = ["再獲得一張。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.WAREHOUSE] = ["棄掉3張卡片。", "棄掉3張卡片。"];
TChineseQuestionDescriptions[QuestionIds.LURKER_COMPLEX] = ["你打出潛伏者", "你打出潛伏者"];
TChineseQuestionDescriptions[QuestionIds.BLACK_MARKET_COMPLEX] = ["你打出黑市", "你打出黑市"];
TChineseQuestionDescriptions[QuestionIds.BLACK_MARKET_BUY] = ["購買其中一張翻開的卡片。", "你可以購買"];
TChineseQuestionDescriptions[QuestionIds.BLACK_MARKET_PLAY] = ["從你的手牌打出一張金錢卡。", "你可以打出一張金錢卡"];
TChineseQuestionDescriptions[QuestionIds.ENVOY] = ["選擇一張卡片到棄掉。", "棄掉一張卡片"];
//TChineseQuestionDescriptions[QuestionIds.PRINCE_MODE] = ["王子模式", "覆蓋王子?"];
TChineseQuestionDescriptions[QuestionIds.PRINCE_MAY_SET_ASIDE] = ["你可以覆蓋一張卡片", "你可以覆蓋一張卡片"];
TChineseQuestionDescriptions[QuestionIds.TORTURER_MAY_CURSE] = ["你可以獲得一張詛咒。", "你可以獲得一張詛咒"];
TChineseQuestionDescriptions[QuestionIds.TORTURER_MAY_DISCARD] = ["你可以棄掉兩張卡片。", "你可以棄掉兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.TORTURER_DISCARD_OR_CURSE] = ["獲得一張詛咒或棄掉兩張卡片。", "棄掉兩張卡片或獲得一張詛咒"];
//TChineseQuestionDescriptions[QuestionIds.WHAT_TO_IMPERSONATE] = ["選擇一張卡片來模仿。", "選擇一張卡片模仿"];
TChineseQuestionDescriptions[QuestionIds.INHERITANCE] = ["選擇一張卡片繼承。", "選擇一張卡片繼承"];

TChineseQuestionDescriptions[QuestionIds.SHORT_YOU_MAY_REACT] = ["-", "你可以發動效果"];
TChineseQuestionDescriptions[QuestionIds.SHORT_WHEN_GAIN] = ["-", "你獲得Argument0..。"];
TChineseQuestionDescriptions[QuestionIds.SHORT_WHEN_WOULD_GAIN] = ["-", "你會獲得Argument0..。"];

TChineseQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL_SPECIFIC] = ["-", "打出或呼叫 Argument0..。"];
TChineseQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL] = ["-", "打出或呼叫行動卡"];
TChineseQuestionDescriptions[QuestionIds.SHORT_PLAY] = ["-", "你可以打出行動卡"];
TChineseQuestionDescriptions[QuestionIds.SHORT_CALL_SPECIFIC] = ["-", "你可以呼叫Argument0"];
TChineseQuestionDescriptions[QuestionIds.SHORT_CALL] = ["-", "你可以呼叫卡片"];

TChineseQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_DISCARD] = ["你可以棄掉一張錢幣卡", "你可以棄掉"];
TChineseQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_GAIN] = ["獲得一張價值至多4塊的卡片。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.THE_FLAMES_GIFT] = ["你可以從手牌移除一張卡片。", "你可以移除"];
TChineseQuestionDescriptions[QuestionIds.THE_MOONS_GIFT] = ["你可以將一張卡片放上你的牌庫。", "你可以置頂"];
TChineseQuestionDescriptions[QuestionIds.THE_SKYS_GIFT] = ["你可以棄掉三張卡片獲得一張金幣。", "你可以棄掉"];
TChineseQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_TOPDECK] = ["以任意順序放回", "放回剩餘卡片"];
TChineseQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_DISCARD] = ["棄掉任意數量", "棄掉任意數量"];
TChineseQuestionDescriptions[QuestionIds.THE_WINDS_GIFT] = ["棄掉兩張卡片。", "棄掉兩張卡片"];
TChineseQuestionDescriptions[QuestionIds.FEAR_DISCARD] = ["棄掉一張行動卡或一張金錢卡", "棄掉一張卡片"];
TChineseQuestionDescriptions[QuestionIds.HAUNTING] = ["將一張卡片置頂。", "將一張卡片置頂"];
TChineseQuestionDescriptions[QuestionIds.LOCUSTS] = ["獲得一張較低價值的同類型卡片。", "獲得一張較低價值的卡片"];
TChineseQuestionDescriptions[QuestionIds.POVERTY] = ["棄到剩三張手牌", "棄到剩三張"];
TChineseQuestionDescriptions[QuestionIds.BAT] = ["你可以移除至多兩張卡片", "你可以移除"];
TChineseQuestionDescriptions[QuestionIds.BLESSED_VILLAGE_MODE] = ["何時收下Argument0?", "選擇使用時機。"];
TChineseQuestionDescriptions[QuestionIds.CEMETERY] = ["你可以移除至多4張卡片", "你可以移除"];
TChineseQuestionDescriptions[QuestionIds.CHANGELING_GAIN_COPY] = ["獲得一張你這回合打出的卡片", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.CHANGELING_REPLACE] = ["", ""];
TChineseQuestionDescriptions[QuestionIds.COBBLER] = ["獲得一張價值至多4塊的卡片你的手牌。", "獲得到你的手牌"];
TChineseQuestionDescriptions[QuestionIds.CONCLAVE] = ["你可以打出一張行動卡", "你可以打出一張行動卡"];
TChineseQuestionDescriptions[QuestionIds.CRYPT_SET_ASIDE] = ["覆蓋你這回合打出的任意數量錢幣卡 ", "覆蓋任意數量"];
TChineseQuestionDescriptions[QuestionIds.CRYPT_RETURN_TO_HAND] = ["選擇一張卡片放入你的手牌。", "選擇一張卡片"];
TChineseQuestionDescriptions[QuestionIds.DEVILS_WORKSHOP] = ["獲得一張價值至多4塊的卡片。", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.DRUID] = ["想要收到哪一個祝福", "選一個祝福。"];
TChineseQuestionDescriptions[QuestionIds.EXORCIST_TRASH] = ["從手牌移除一張卡片。", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.EXORCIST_GAIN] = ["獲得一張較低價值的靈體", "獲得一張較低價值的靈體"];
TChineseQuestionDescriptions[QuestionIds.FOOL] = ["接著是哪個祝福?", "接著是哪個祝福?"];
TChineseQuestionDescriptions[QuestionIds.GOAT] = ["你可以移除一張卡片", "你可以移除"];
TChineseQuestionDescriptions[QuestionIds.HAUNTED_MIRROR] = ["你可以棄掉一張行動卡來獲得一張鬼魂", "你可以棄掉"];
TChineseQuestionDescriptions[QuestionIds.IMP] = ["你可以打出一張行動卡你這回合沒打出過的", "要打哪張?"];
TChineseQuestionDescriptions[QuestionIds.NECROMANCER] = ["從移除卡片中打出", "從移除卡片中打出"];
TChineseQuestionDescriptions[QuestionIds.MONASTERY] = ["你可以移除至多AMOUNT0張打出的卡片或銅幣。", "你可以移除至多AMOUNT0"];
TChineseQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_TOPDECK] = ["將剩餘卡片置頂", "將剩餘卡片置頂"];
TChineseQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_DISCARD] = ["棄掉任意數量", "棄掉任意數量"];
TChineseQuestionDescriptions[QuestionIds.PIXIE_MODE] = ["移除精靈來獲得兩次?", "移除精靈?"];
TChineseQuestionDescriptions[QuestionIds.POOKA] = ["你可以移除一張錢幣卡除了詛咒金幣", "你可以移除"];
TChineseQuestionDescriptions[QuestionIds.RAIDER] = ["棄掉一張卡片", "棄掉一張卡片"];
TChineseQuestionDescriptions[QuestionIds.SACRED_GROVE_MODE] = ["收下Argument0?", "收下 Argument0?"];
TChineseQuestionDescriptions[QuestionIds.SECRET_CAVE] = ["你可以棄掉三張卡片來換取下回合+3塊", "你可以棄掉"];
TChineseQuestionDescriptions[QuestionIds.SHEPHERD] = ["棄掉分數卡，抽取等同於棄掉張數兩倍的卡片", "你可以棄掉"];
TChineseQuestionDescriptions[QuestionIds.TRAGIC_HERO] = ["獲得一張錢幣卡", "獲得一張錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.VAMPIRE] = ["獲得一張非吸血鬼且價值至多5塊的卡片", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.WISH] = ["獲得一張卡片到你的手牌", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.ZOMBIE_APPRENTICE] = ["你可以移除一張行動卡", "你可以移除"];
TChineseQuestionDescriptions[QuestionIds.ZOMBIE_MASON] = ["你可以獲得一張卡片", "你可以獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.ZOMBIE_SPY] = ["你可以棄掉", "你可以棄掉"];
TChineseQuestionDescriptions[QuestionIds.WHAT_NIGHT_TO_PLAY] = ["你可以打出夜晚卡", "要打哪一張夜晚卡?"];
TChineseQuestionDescriptions[QuestionIds.LOST_IN_THE_WOODS] = ["你可以棄掉一張卡片來獲得一個祝福", "棄掉來獲得一個祝福?"];
TChineseQuestionDescriptions[QuestionIds.START_GAME] = ["查看你的起始卡片", "你的起始卡片:"];
TChineseQuestionDescriptions[QuestionIds.DISMANTLE_TRASH] = ["移除你手上的一張卡片", "移除一張卡"];
TChineseQuestionDescriptions[QuestionIds.DISMANTLE_GAIN] = ["獲得一張價值低於AMOUNT0的卡片.", "獲得一張卡片"];

TChineseQuestionDescriptions[QuestionIds.DUCAT] = ["你可以移除一張銅幣", "你可以移除一張銅幣"];
TChineseQuestionDescriptions[QuestionIds.IMPROVE_TRASH] = ["你可以移除一張出牌區要被棄掉的行動卡", "你可以進行改良"];
TChineseQuestionDescriptions[QuestionIds.IMPROVE_GAIN] = ["獲得一張價值剛好 AMOUNT0 的卡片", "獲得一張卡片"];
TChineseQuestionDescriptions[QuestionIds.HIDEOUT] = ["移除一張卡片，如果移除分數卡，你獲得一張詛咒", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.MOUNTAIN_VILLAGE] = ["從你棄牌堆選一張卡片加到你的手牌裡", "放到手牌裡"];
TChineseQuestionDescriptions[QuestionIds.PRIEST] = ["移除你手上的一張卡片", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.RESEARCH_TRASH] = ["移除你手上一張卡片", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.OLD_WITCH] = ["你可以移除你手上的一張詛咒", "你可以移除"];
TChineseQuestionDescriptions[QuestionIds.RECRUITER] = ["移除你手上一張卡", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.SCEPTER_COMPLEX] = ["當你打出權杖", "要預算還是要發動行動卡"];
TChineseQuestionDescriptions[QuestionIds.SCEPTER_MODE] = ["選擇效果", "增加預算"];
TChineseQuestionDescriptions[QuestionIds.SCEPTER_PLAY] = ["再次發動出牌區裡的一張行動卡", "再次發動行動卡"];
TChineseQuestionDescriptions[QuestionIds.SCULPTOR] = ["獲得一張價值至多$4的卡片到你手中", "獲得到你手中"];
TChineseQuestionDescriptions[QuestionIds.SEER] = ["將卡片以任意順序放回牌庫頂", "以任意順序放回牌庫頂"];
TChineseQuestionDescriptions[QuestionIds.VILLAIN] = ["棄掉一張價值$2以上的卡片", "棄掉一張卡片"];
TChineseQuestionDescriptions[QuestionIds.TREASURER_COMPLEX] = ["打出收稅人之後", "打出收稅人之後"];
TChineseQuestionDescriptions[QuestionIds.TREASURER_TRASH] = ["移除一張錢幣卡", "移除一張錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.TREASURER_GAIN] = ["獲得一張被移除的錢幣卡到你手中", "獲得一張錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.TREASURER_KEY] = ["拿取金鑰", "拿取金鑰"];
TChineseQuestionDescriptions[QuestionIds.TREASURER_FAIL] = ["無法動作", "無法動作"];
TChineseQuestionDescriptions[QuestionIds.BORDER_GUARD_PUT_IN_HAND] = ["從你展示的卡片中選擇一張加入手牌", "加入手牌"];
TChineseQuestionDescriptions[QuestionIds.BORDER_GUARD_MODE] = ["你想要拿取哪個工藝品?", "號角或是燈籠?"];
TChineseQuestionDescriptions[QuestionIds.CATHEDRAL] = ["移除你手上的一張卡片.", "移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.CITY_GATE] = ["將你的一張手牌放回牌庫頂", "放回牌庫頂"];
TChineseQuestionDescriptions[QuestionIds.PAGEANT] = ["你可以付出 $1 來換取 1存款", "將1預算變存款?"];
TChineseQuestionDescriptions[QuestionIds.SEWERS] = ["你可以移除至多 AMOUNT0 張手牌 (" + TChineseCardNames[CardNames.SEWERS].singular + ")", "你可以移除至多 AMOUNT0 (" + TChineseCardNames[CardNames.SEWERS].singular + ")"];
TChineseQuestionDescriptions[QuestionIds.SILOS] = ["每棄一張銅幣，你就可以抽一張卡", "你可以棄掉"];
TChineseQuestionDescriptions[QuestionIds.CROP_ROTATION] = ["棄掉一張分數卡來 +2 卡片?", "你可以棄掉"];
TChineseQuestionDescriptions[QuestionIds.SINISTER_PLOT] = ["抽 AMOUNT0 張卡片或是繼續累積?", "抽 AMOUNT0 張卡或是繼續累積?"];
TChineseQuestionDescriptions[QuestionIds.STAR_CHART] = ["選擇一張卡片，洗完牌之後它會在牌庫頂", "選一張卡片放到牌庫頂"];
TChineseQuestionDescriptions[QuestionIds.CAPTAIN] = ["選擇供應區一張價值4以下的行動卡來發動效果", "選哪張發動效果"];
TChineseQuestionDescriptions[QuestionIds.CHURCH_SET_ASIDE] = ["將至多三張卡放到一邊", "將至多三張卡放到一邊"];
TChineseQuestionDescriptions[QuestionIds.CHURCH_TRASH] = ["你可以從手上移除一張卡片", "你可以從手上移除一張卡片"];
TChineseQuestionDescriptions[QuestionIds.COURTIER_MODE] = ["選擇 NUMBER0 個: +1 行動; 或 +1 購買; 或 +$3; 或 獲得一張黃金。必須選擇不一樣的。", "選擇 NUMBER0 個選項"];
TChineseQuestionDescriptions[QuestionIds.CAMEL_TRAIN] = ["驅逐一張供應區的卡。", "驅逐一張卡。"];
TChineseQuestionDescriptions[QuestionIds.GOATHERD] = ["你可以移除你手上一張卡片。", "你可以移除一張卡。"];
TChineseQuestionDescriptions[QuestionIds.SNOWY_VILLAGE] = ["你目前可以使用村民，要用嗎", "你還可以用村民喔"];
TChineseQuestionDescriptions[QuestionIds.BOUNTY_HUNTER] = ["驅逐你手上一張卡", "驅逐一張卡"];
TChineseQuestionDescriptions[QuestionIds.CARDINAL_EXILE] = ["驅逐一張卡", "驅逐一張卡"];
TChineseQuestionDescriptions[QuestionIds.GROOM] = ["獲得一張價值至多 AMOUNT0 的卡片", "獲得一張價值至多 AMOUNT0 的卡片"];
TChineseQuestionDescriptions[QuestionIds.HOSTELRY] = ["棄掉來獲得馬", "棄掉來獲得馬"];
TChineseQuestionDescriptions[QuestionIds.VILLAGE_GREEN] = ["立刻獲得還是下個回合?", "立刻獲得還是下個回合?"];
TChineseQuestionDescriptions[QuestionIds.BARGE] = ["立刻獲得還是下個回合?", "立刻獲得還是下個回合?"];
TChineseQuestionDescriptions[QuestionIds.DISPLACE_EXILE] = ["驅逐一張你手上的卡", "驅逐一張卡"];
TChineseQuestionDescriptions[QuestionIds.DISPLACE_GAIN] = ["獲得一張價值至多 AMOUNT0 的卡片", "獲得一張價值至多 AMOUNT0 的卡片"];
TChineseQuestionDescriptions[QuestionIds.FALCONER] = ["獲得一張卡，並加入你手中", "獲得一張卡，並加入你手中。"];
TChineseQuestionDescriptions[QuestionIds.HUNTING_LODGE] = ["棄掉手排來+5 卡片?", "棄掉手排來+5 卡片?"];
TChineseQuestionDescriptions[QuestionIds.MASTERMIND] = ["你可以打出手上一張卡，發動三次效果。", "你可以打出手上一張卡，發動三次效果。"];
TChineseQuestionDescriptions[QuestionIds.SANCTUARY] = ["你可以驅逐你手中的一張卡", "你可以驅逐一張卡"];
TChineseQuestionDescriptions[QuestionIds.WAYFARER] = ["你可以獲得一張銀幣", "你可以獲得一張銀幣"];
TChineseQuestionDescriptions[QuestionIds.DELAY] = ["你可以將一張行動卡放到邊", "你可以將一張行動卡放到一邊"];
TChineseQuestionDescriptions[QuestionIds.DESPERATION] = ["你可以獲得一張詛咒", "你可以獲得一張詛咒"];
TChineseQuestionDescriptions[QuestionIds.GAMBLE] = ["打出 Argument0?", "打出 Argument0?"];
TChineseQuestionDescriptions[QuestionIds.PURSUE] = ["想要留哪一種卡片?", "想要留哪一種卡片?"];
TChineseQuestionDescriptions[QuestionIds.TOIL] = ["你可以打出一張卡", "你可以打出一張卡"];
TChineseQuestionDescriptions[QuestionIds.ENHANCE_TRASH] = ["你可以移除一張非分數的卡片。", "你可以移除一張非分數的卡片。"];
TChineseQuestionDescriptions[QuestionIds.ENHANCE_GAIN] = ["獲得一張價值至多 AMOUNT0 的卡片", "獲得一張價值至多 AMOUNT0 的卡片"];
TChineseQuestionDescriptions[QuestionIds.MARCH] = ["你可以從你棄排堆打出一張卡", "你可以從你棄排堆打出一張卡"];
TChineseQuestionDescriptions[QuestionIds.TRANSPORT_MAY_EXILE] = ["你可以驅逐一張行動卡", "你可以驅逐一張行動卡"];
TChineseQuestionDescriptions[QuestionIds.TRANSPORT_EXILE] = ["驅逐一張行動卡", "驅逐一張行動卡"];
TChineseQuestionDescriptions[QuestionIds.TRANSPORT_MAY_TOPDECK] = ["你可以將一張被驅逐的卡片放牌庫頂", "你可以將一張被驅逐的卡片放牌庫頂"];
TChineseQuestionDescriptions[QuestionIds.TRANSPORT_TOPDECK] = ["將一張被驅逐的行動卡放到牌庫頂", "將一張被驅逐的行動卡放到牌庫頂"];
TChineseQuestionDescriptions[QuestionIds.TRANSPORT_COMPLEX] = ["移除一張供應區的卡; 或將一張被驅逐的卡放回牌庫頂。", "移除一張供應區的卡; 或將一張被驅逐的卡放回牌庫頂。"];
TChineseQuestionDescriptions[QuestionIds.BANISH] = ["驅逐一張同名卡", "驅逐一張同名卡"];
TChineseQuestionDescriptions[QuestionIds.BARGAIN] = ["獲得一張價值至多 $5 的同名卡", "獲得一張價值至多 $5 的同名卡"];
TChineseQuestionDescriptions[QuestionIds.INVEST] = ["驅逐一張卡，來當作共同投資的目標", "驅逐一張卡，來當作共同投資的目標"];
TChineseQuestionDescriptions[QuestionIds.DEMAND] = ["獲得一張價值至多 $4的卡，並放到牌庫頂", "獲得一張價值至多 $4的卡，並放到牌庫頂"];
TChineseQuestionDescriptions[QuestionIds.POPULATE] = ["按順序，下一張獲得什麼?", "下一張獲得什麼?"];
TChineseQuestionDescriptions[QuestionIds.RECONSIDER_WAY] = ["思考當作你打出 Argument0", "思考當作你打出 Argument0"];
TChineseQuestionDescriptions[QuestionIds.CONSIDER_WAY] = ["當作你打出 Argument0", "當作你打出 Argument0"];
TChineseQuestionDescriptions[QuestionIds.WAY_OF_THE_GOAT] = ["移除你手上的一張卡片", "移除你手上的一張卡片"];
TChineseQuestionDescriptions[QuestionIds.WAY_OF_THE_RAT] = ["棄掉一張錢幣卡，來獲得一張同名卡。", "棄掉一張錢幣卡，來獲得一張同名卡。"];
TChineseQuestionDescriptions[QuestionIds.SCRAP_TRASH] = ["移除你手上的一張卡片", "移除你手上的一張卡片"];
TChineseQuestionDescriptions[QuestionIds.SCRAP_MODES] = ["選擇 Argument0 個效果", "選擇 Argument0 個效果"];
TChineseQuestionDescriptions[QuestionIds.ANIMAL_FAIR_TRASH] = ["移除你手上的一張行動卡", "移除你手上的一張行動卡"];
TChineseQuestionDescriptions[QuestionIds.ANIMAL_FAIR_MAY_TRASH] = ["你可以移除手上一張行動卡。", "你可以移除手上的一張卡"];
TChineseQuestionDescriptions[QuestionIds.BUTTERFLY_RETURN] = ["歸還 Argument0 ?", "歸還 Argument0 ?"];
TChineseQuestionDescriptions[QuestionIds.BUTTERFLY_GAIN] = TChineseQuestionDescriptions[QuestionIds.UPGRADE_GAIN];

TChineseQuestionDescriptions[QuestionIds.BLOCKADE] = ["", "獲得一張卡。"];
TChineseQuestionDescriptions[QuestionIds.PIRATE] = ["", "獲得並加入你手中"];
TChineseQuestionDescriptions[QuestionIds.SAILOR] = ["", "你可以移除一張卡"];
TChineseQuestionDescriptions[QuestionIds.TIDE_POOLS] = TChineseQuestionDescriptions[QuestionIds.DUNGEON];
TChineseQuestionDescriptions[QuestionIds.SEA_WITCH] = TChineseQuestionDescriptions[QuestionIds.DUNGEON];

TChineseQuestionDescriptions[QuestionIds.TIARA_MODE] = ["", "選擇冠冕的效果"];
TChineseQuestionDescriptions[QuestionIds.TIARA_PLAY] = ["", "選一張錢幣卡發動兩次效果"];
TChineseQuestionDescriptions[QuestionIds.CRYSTAL_BALL] = ["", "該怎麼處理 Argument0"];
TChineseQuestionDescriptions[QuestionIds.WAR_CHEST_PROHIBIT] = ["", "禁止他此回合用戰爭寶相獲得此卡"];
TChineseQuestionDescriptions[QuestionIds.WAR_CHEST_GAIN] = ["", "獲得一張"];
TChineseQuestionDescriptions[QuestionIds.ANVIL_DISCARD] = TChineseQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_DISCARD];
TChineseQuestionDescriptions[QuestionIds.ANVIL_GAIN] = TChineseQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_GAIN];
TChineseQuestionDescriptions[QuestionIds.CLERK] = ["", "將一張卡放到牌庫頂"];
TChineseQuestionDescriptions[QuestionIds.INVESTMENT_TRASH] = TChineseQuestionDescriptions[QuestionIds.TRADE_ROUTE];
TChineseQuestionDescriptions[QuestionIds.INVESTMENT_MODE] = ["", "選擇投資要使用哪個效果"];

// Allies
TChineseQuestionDescriptions[QuestionIds.TOWN_MODE] = ["", "如何使用城鎮"];
TChineseQuestionDescriptions[QuestionIds.MODIFY_TRASH] = ["", "移除一張卡"];
TChineseQuestionDescriptions[QuestionIds.MODIFY_MODE] = ["", "如何使用修建"];
TChineseQuestionDescriptions[QuestionIds.MODIFY_GAIN] = ["", "獲得一張卡"];
TChineseQuestionDescriptions[QuestionIds.SWAP_RETURN] = ["", "你可以歸還一張卡"];
TChineseQuestionDescriptions[QuestionIds.SWAP_GAIN] = ["", "獲得一張行動卡"];
TChineseQuestionDescriptions[QuestionIds.SKIRMISHER] = ["", "棄到剩3張手牌"];
TChineseQuestionDescriptions[QuestionIds.WOODWORKERS_GUILD_TRASH] = ["", "移除一張行動卡"];
TChineseQuestionDescriptions[QuestionIds.WOODWORKERS_GUILD_GAIN] = ["", "獲得一張行動卡"];
TChineseQuestionDescriptions[QuestionIds.CRAFTERS_GUILD] = ["", "要花費2支持來獲得一張卡?"];
TChineseQuestionDescriptions[QuestionIds.CAVE_DWELLERS] = ["", "要花費1支持來棄一抽一嗎?"];
TChineseQuestionDescriptions[QuestionIds.DESERT_GUIDES] = ["", "要保留手牌嗎"];
TChineseQuestionDescriptions[QuestionIds.BAND_OF_NOMADS] = ["", "消耗更多支持來換取購買?"];
TChineseQuestionDescriptions[QuestionIds.SYCOPHANT] = ["", "棄掉三張卡"];
TChineseQuestionDescriptions[QuestionIds.IMPORTER] = ["", "獲得一張價值至多 $5 的卡"];
TChineseQuestionDescriptions[QuestionIds.CONTRACT] = ["Y", "你可以在下個回合打出行動卡"];
TChineseQuestionDescriptions[QuestionIds.BAUBLE] = ["", "選擇如何使用飾品"];
TChineseQuestionDescriptions[QuestionIds.BROKER_TRASH] = ["", "移除哪張卡?"];
TChineseQuestionDescriptions[QuestionIds.BROKER_MODE] = ["W", "想要使用哪個效果?"];
TChineseQuestionDescriptions[QuestionIds.MARKET_TOWNS_FIRST] = ["", "想要打出哪張行動卡?"];
TChineseQuestionDescriptions[QuestionIds.MARKET_TOWNS_AGAIN] = ["", "花費1支持來打出一張行動卡?"];
TChineseQuestionDescriptions[QuestionIds.BATTLE_PLAN_REVEAL] = ["", "展示一張行動卡來換取 +1卡片?"];
TChineseQuestionDescriptions[QuestionIds.BATTLE_PLAN_ROTATE] = ["", "旋轉一個牌堆?"];
TChineseQuestionDescriptions[QuestionIds.BARBARIAN] = ["", "獲得一張較便宜的卡"];
TChineseQuestionDescriptions[QuestionIds.INNKEEPER_MODE] = ["", "如何使用旅店老闆?"];
TChineseQuestionDescriptions[QuestionIds.INNKEEPER_DISCARD] = ["", "棄掉 Argument0 張手牌"];
TChineseQuestionDescriptions[QuestionIds.CAPITAL_CITY_MODE] = ["", "棄卡或是付出預算?"];
TChineseQuestionDescriptions[QuestionIds.CAPITAL_CITY_DISCARD] = ["", "棄掉兩張手排"];
TChineseQuestionDescriptions[QuestionIds.SPECIALIST_PLAY] = ["", "選擇效果"];
TChineseQuestionDescriptions[QuestionIds.SPECIALIST_MODE] = ["", "要再一次發動 Argument0 還是獲得同名卡?"];
TChineseQuestionDescriptions[QuestionIds.GANG_OF_PICKPOCKETS] = ["", "棄卡或是花費1支持?"];
TChineseQuestionDescriptions[QuestionIds.COASTAL_HAVEN] = ["", "保留那些手牌?"];
TChineseQuestionDescriptions[QuestionIds.CARPENTER_GAIN] = TChineseQuestionDescriptions[QuestionIds.WORKSHOP];
TChineseQuestionDescriptions[QuestionIds.CARPENTER_TRASH] = TChineseQuestionDescriptions[QuestionIds.REMODEL_TRASH];
TChineseQuestionDescriptions[QuestionIds.CARPENTER_GAIN_UP_TO] = TChineseQuestionDescriptions[QuestionIds.REMODEL_GAIN];
TChineseQuestionDescriptions[QuestionIds.MAY_ROTATE_FORTS] = ["", "你可以旋轉碉堡牌堆"];
TChineseQuestionDescriptions[QuestionIds.ROYAL_GALLEY] = ["", "你可以打出一張行動卡"];
TChineseQuestionDescriptions[QuestionIds.HILL_FORT_GAIN] = TChineseQuestionDescriptions[QuestionIds.WORKSHOP];
TChineseQuestionDescriptions[QuestionIds.HILL_FORT_MODE] = ["", "如何使用山寨"];
TChineseQuestionDescriptions[QuestionIds.STRONGHOLD_MODE] = ["", "如何使用據點?"];
TChineseQuestionDescriptions[QuestionIds.MAY_ROTATE_ODYSSEYS] = ["Y", "你可以旋轉奧德賽牌堆"];
TChineseQuestionDescriptions[QuestionIds.OLD_MAP_DISCARD] = ["", "棄掉一張手牌"];
TChineseQuestionDescriptions[QuestionIds.SUNKEN_TREASURE] = ["", "獲得一張你出牌區沒有的行動卡。"];
TChineseQuestionDescriptions[QuestionIds.PEACEFUL_CULT] = ["", "你可以花費支持來移除手牌"];
TChineseQuestionDescriptions[QuestionIds.ORDER_OF_ASTROLOGERS] = ["", "你可以選擇一張卡片置頂"];
TChineseQuestionDescriptions[QuestionIds.STAR_CHART_ASTROLOGERS_COMBINED] = ["", "你可以選擇一張卡片置頂 (星空圖優先使用)"];
TChineseQuestionDescriptions[QuestionIds.ORDER_OF_MASONS] = ["", "你可以花費2支持來從棄牌堆選一張卡加入手牌"];
TChineseQuestionDescriptions[QuestionIds.ARCHER_HIDE] = ["", "選擇保留哪張卡"];
TChineseQuestionDescriptions[QuestionIds.ARCHER_DISCARD] = TChineseQuestionDescriptions[QuestionIds.PILLAGE];
TChineseQuestionDescriptions[QuestionIds.MARQUIS] = ["", "棄到剩10張手牌"];
TChineseQuestionDescriptions[QuestionIds.HERB_GATHERER_PLAY] = ["", "想要打出哪張錢幣卡?"];
TChineseQuestionDescriptions[QuestionIds.MAY_ROTATE_AUGURS] = ["", "你可以旋轉占兆官"];
TChineseQuestionDescriptions[QuestionIds.ACOLYTE] = ["", "移除來獲得一張黃金?"];
TChineseQuestionDescriptions[QuestionIds.ACOLYTE_SELF] = ["", "移除來獲得下一張占兆官?"];
TChineseQuestionDescriptions[QuestionIds.SORCERESS] = ["", "猜一名字"];
TChineseQuestionDescriptions[QuestionIds.SIBYL_TOPDECK] = ["", "將一張卡放到牌庫頂"];
TChineseQuestionDescriptions[QuestionIds.SIBYL_BOTTOMDECK] = ["", "將一張卡放到牌庫底"];
TChineseQuestionDescriptions[QuestionIds.MAY_ROTATE_WIZARDS] = ["", "你可以旋轉巫師牌堆"];
TChineseQuestionDescriptions[QuestionIds.STUDENT] = ["", "移除一張卡"];
TChineseQuestionDescriptions[QuestionIds.CONJURER] = TChineseQuestionDescriptions[QuestionIds.WORKSHOP];
TChineseQuestionDescriptions[QuestionIds.SORCERER] = ["", "猜一個名字"];
TChineseQuestionDescriptions[QuestionIds.LICH] = ["", "從移除牌堆獲得一張卡"];
TChineseQuestionDescriptions[QuestionIds.MAY_ROTATE_TOWNSFOLK] = ["", "你可以旋轉市民牌堆"];
TChineseQuestionDescriptions[QuestionIds.TOWN_CRIER] = ["", "如何使用街頭公告員?"];
TChineseQuestionDescriptions[QuestionIds.MILLER] = ["", "如何使用磨坊主?"];
TChineseQuestionDescriptions[QuestionIds.ELDER] = ["", "你可以打出一張卡"];
TChineseQuestionDescriptions[QuestionIds.SENTINEL_TRASH] = ["", "移除至多兩張卡"];
TChineseQuestionDescriptions[QuestionIds.SENTINEL_TOPDECK] = TChineseQuestionDescriptions[QuestionIds.APOTHECARY];
TChineseQuestionDescriptions[QuestionIds.COURIER] = ["", "你可以打出一張卡"];
TChineseQuestionDescriptions[QuestionIds.HUNTER_ACTION] = ["", "將一張行動卡加入你手中"];
TChineseQuestionDescriptions[QuestionIds.HUNTER_TREASURE] = ["", "將一張錢幣卡加入你手中"];
TChineseQuestionDescriptions[QuestionIds.HUNTER_VICTORY] = ["", "將一張分數卡加入你手牌"];
TChineseQuestionDescriptions[QuestionIds.LURKER_MODE] = ["", "要如何使用潛伏者"];
TChineseQuestionDescriptions[QuestionIds.GRAVEROBBER_MODE] = ["", "要如何使用盜墓者"];
TChineseQuestionDescriptions[QuestionIds.TREASURER_MODE] = ["", "選擇如何使用沉默的寶藏"];
TChineseQuestionDescriptions[QuestionIds.ARCHITECTS_GUILD] = ["", "要獲得甚麼?"];
TChineseQuestionDescriptions[QuestionIds.FAMILY_OF_INVENTORS] = ["", "使哪一疊卡片變便宜?"];
TChineseQuestionDescriptions[QuestionIds.FOREST_DWELLERS_DISCARD] = ["", "棄掉任意張數的卡片"];
TChineseQuestionDescriptions[QuestionIds.FOREST_DWELLERS_TOPDECK] = ["", "以任意順序放到牌庫頂"];
TChineseQuestionDescriptions[QuestionIds.USE_FAVOR_WHEN_SHUFFLING] = ["", "洗牌時使用一點支持?"];
TChineseQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS] = ["", "你想要使用更多存款嗎?"];
TChineseQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_BASILICA] = ["", "你想要使用更多存款來達成殿堂的條件?"];
TChineseQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_SWASHBUCKLER] = ["", "你想要使用更多存款來避免拿到寶相嗎?"];
TChineseQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_FORTUNE] = ["", "你想要在加倍預算之前，使用存款嗎?"];

TChineseQuestionDescriptions[QuestionIds.WHEELWRIGHT_DISCARD] = ["", "棄掉一張卡來獲得卡片"];
TChineseQuestionDescriptions[QuestionIds.WHEELWRIGHT_GAIN] = ["", "獲得一張價值至多 AMOUNT0 的行動卡"];
TChineseQuestionDescriptions[QuestionIds.BERSERKER_DISCARD] = TChineseQuestionDescriptions[QuestionIds.MILITIA];
TChineseQuestionDescriptions[QuestionIds.BERSERKER_GAIN] = ["", "獲得一張較便宜的卡"];
TChineseQuestionDescriptions[QuestionIds.WEAVER] = ["", "獲得一張卡或是兩張銀幣"];
TChineseQuestionDescriptions[QuestionIds.SOUK] = ["", "移除至多兩張手牌"];
TChineseQuestionDescriptions[QuestionIds.WITCHS_HUT] = ["", "棄掉兩張卡片。"];
TChineseQuestionDescriptions[QuestionIds.STONEMASON_OVERPAY_AMOUNT] = ["你可以對石匠超額支付至多 AMOUNT0.", "請指定要多少"];

//Plunder
TChineseQuestionDescriptions[QuestionIds.CAGE_SET_ASIDE] = ["你可以將至多四張卡放去關", "你可以將至多四張卡放去關"];
TChineseQuestionDescriptions[QuestionIds.GROTTO_SET_ASIDE] = ["你可以將至多四張卡放到一邊", "你可以將至多四張卡放到一邊"];
TChineseQuestionDescriptions[QuestionIds.SHAMAN_GAIN] = ["從移除牌堆獲得一張價值至多 6 的卡片", "從移除牌堆獲得一張價值至多 6 的卡片"];
TChineseQuestionDescriptions[QuestionIds.SHAMAN_TRASH] = ["你可以移除一張手牌", "你可以移除一張手牌"];
TChineseQuestionDescriptions[QuestionIds.SECLUDED_SHRINE] = ["你可以移除至多兩張手牌", "你可以移除至多兩張手牌"];
TChineseQuestionDescriptions[QuestionIds.SIREN] = ["你可以移除一張行動卡", "你可以移除一張行動卡"];
TChineseQuestionDescriptions[QuestionIds.CABIN_BOY_MODE] = ["選擇一個： +2預算，或是移除少年來換取持續卡", "選擇一個： +2預算，或是移除少年來換取持續卡"];
TChineseQuestionDescriptions[QuestionIds.CABIN_BOY_GAIN] = ["獲得一張持續卡", "獲得一張持續卡"];
TChineseQuestionDescriptions[QuestionIds.CRUCIBLE] = ["移除一張手牌", "移除一張手牌"];
TChineseQuestionDescriptions[QuestionIds.FORTUNE_HUNTER_PLAY] = ["打出展示的錢幣卡", "打出展示的錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.FORTUNE_HUNTER_TOPDECK] = ["將剩下的卡片放到牌庫頂", "將剩下的卡片放到牌庫頂"];
TChineseQuestionDescriptions[QuestionIds.GONDOLA_MODE] = ["現在得到 +2預算還是下回合?", "現在得到 +2預算還是下回合?"];
TChineseQuestionDescriptions[QuestionIds.GONDOLA_PLAY] = ["你可以打出一張行動卡", "你可以打出一張行動卡"];
TChineseQuestionDescriptions[QuestionIds.MAPMAKER] = ["將兩張卡加入手中", "將兩張卡加入手中"];
TChineseQuestionDescriptions[QuestionIds.MAROON] = ["移除一張卡", "移除一張卡"];
TChineseQuestionDescriptions[QuestionIds.ROPE_TRASH] = ["你可以移除一張手牌", "你可以移除一張手牌"];
TChineseQuestionDescriptions[QuestionIds.TOOLS] = ["獲得一張任意玩家出牌區的同名卡", "獲得一張任意玩家出牌區的同名卡"];
TChineseQuestionDescriptions[QuestionIds.CUTTHROAT_DISCARD] = ["棄到剩三張手牌", "棄到剩三張手牌"];
TChineseQuestionDescriptions[QuestionIds.ENLARGE_TRASH] = ["移除一張卡", "移除一張卡"];
TChineseQuestionDescriptions[QuestionIds.ENLARGE_GAIN] = ["獲得一張價值至多 AMOUNT0.", "獲得一張價值至多 AMOUNT0"];
TChineseQuestionDescriptions[QuestionIds.FIGURINE] = ["你可以棄掉行動卡來換取 +1預算和 +1購買", "你可以棄掉行動卡來換取 +1預算和 +1購買"];
TChineseQuestionDescriptions[QuestionIds.FIRST_MATE] = ["你可以打出一張卡", "你可以打出一張卡"];
TChineseQuestionDescriptions[QuestionIds.FRIGATE] = ["將手牌棄到剩四張", "將手牌棄到剩四張"];
TChineseQuestionDescriptions[QuestionIds.PICKAXE] = ["移除一張手牌", "移除一張手牌"];
TChineseQuestionDescriptions[QuestionIds.PILGRIM_TOPDECK] = ["將一張手牌放到牌庫頂", "將一張手牌放到牌庫頂"];
TChineseQuestionDescriptions[QuestionIds.QUARTERMASTER_MODE] = ["獲得一張價值至多 4的卡,或是從 Argument0 上拿取一張卡", "獲得一張價值至多 4的卡,或是從 Argument0 上拿取一張卡"];
TChineseQuestionDescriptions[QuestionIds.QUARTERMASTER_GAIN] = ["獲得一張價值至多 4的卡.", "獲得一張價值至多 4 的卡."];
TChineseQuestionDescriptions[QuestionIds.QUARTERMASTER_TAKE] = ["將一張卡加入手牌", "將一張卡加入手牌"];
TChineseQuestionDescriptions[QuestionIds.TRICKSTER] = ["你可以將一張出牌區的錢幣卡放到一邊", "你可以將一張出牌區的錢幣卡放到一邊"];
TChineseQuestionDescriptions[QuestionIds.SILVER_MINE] = ["獲得一張錢幣卡並加入手中", "獲得一張錢幣卡並加入手中"];
TChineseQuestionDescriptions[QuestionIds.KINGS_CACHE] = ["你可以打出一張錢幣卡發動三次效果", "你可以打出一張錢幣卡發動三次效果"];
TChineseQuestionDescriptions[QuestionIds.BURY] = ["將一張卡放到你牌庫底", "將一張卡放到你牌庫底"];
TChineseQuestionDescriptions[QuestionIds.PERIL] = ["你可以移除一張行動卡來獲得贓物", "你可以移除一張行動卡來獲得贓物"];
TChineseQuestionDescriptions[QuestionIds.PREPARE] = ["打出被放置一邊的卡", "打出被放置一邊的卡"];
TChineseQuestionDescriptions[QuestionIds.FORAY] = ["棄掉三張手牌", "棄掉三張手牌"];
TChineseQuestionDescriptions[QuestionIds.SCROUNGE_COMPLEX] = ["正在發動乞討的效果", "正在發動乞討的效果"];
TChineseQuestionDescriptions[QuestionIds.SCROUNGE_TRASH] = ["移除一張手牌", "移除一張手牌"];
TChineseQuestionDescriptions[QuestionIds.SCROUNGE_MAY_TRASH] = ["你可以移除一張手牌", "你可以移除一張手牌"];
TChineseQuestionDescriptions[QuestionIds.SCROUNGE_GAIN_ESTATE] = ["獲得一張莊園", "獲得一張莊園"];
TChineseQuestionDescriptions[QuestionIds.SCROUNGE_MAY_GAIN_ESTATE] = ["你可以獲得一張莊園", "你可以獲得一張莊園"];
TChineseQuestionDescriptions[QuestionIds.SCROUNGE_GAIN] = ["獲得一張價值至多 5.", "獲得一張價值至多 5."];
TChineseQuestionDescriptions[QuestionIds.MAELSTROM_TRASH] = ["移除 Argument0 張卡", "移除 Argument0 張卡"];
TChineseQuestionDescriptions[QuestionIds.MAELSTROM_OPPONENT_TRASH] = ["移除一張卡", "移除一張卡"];
TChineseQuestionDescriptions[QuestionIds.INVASION_PLAY] = ["你可以打出一張攻擊卡", "你可以打出一張攻擊卡"];
TChineseQuestionDescriptions[QuestionIds.INVASION_GAIN] = ["獲得一張行動卡", "獲得一張行動卡"];
TChineseQuestionDescriptions[QuestionIds.PROSPER] = ["獲得不同名字的錢幣卡", "獲得不同名字的錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.AMPHORA_MODE] = ["現在還是下回合 +1 購買, +3 預算?", "現在還是下回合 +1 購買, +3 預算?"];
TChineseQuestionDescriptions[QuestionIds.HAMMER] = ["獲得一張價值至多 4.", "獲得一張價值至多 4."];
TChineseQuestionDescriptions[QuestionIds.ORB] = ["打出一張行動卡或錢幣卡", "打出一張行動卡或錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.PRIZE_GOAT] = ["你可以移除一張卡", "你可以移除一張卡"];
TChineseQuestionDescriptions[QuestionIds.PUZZLE_BOX] = ["將你一張手牌放到一邊，再回合結束時加回手牌", "放到一邊"];
TChineseQuestionDescriptions[QuestionIds.SEXTANT_DISCARD] = ["棄掉任意張數的手牌", "棄掉任意張數的手牌"];
TChineseQuestionDescriptions[QuestionIds.SEXTANT_TOPDECK] = ["以任意順序放回牌庫頂", "以任意順序放回牌庫頂"];
TChineseQuestionDescriptions[QuestionIds.SPELL_SCROLL_GAIN] = ["獲得一張價值低於 AMOUNT0 的卡.", "獲得一張價值低於 AMOUNT0 的卡"];
TChineseQuestionDescriptions[QuestionIds.SPELL_SCROLL_PLAY] = ["打出 Argument0?", "打出 Argument0?"];
TChineseQuestionDescriptions[QuestionIds.STAFF] = ["你可以打出一張行動卡.", "你可以打出一張行動卡."];
TChineseQuestionDescriptions[QuestionIds.SWORD] = ["棄到剩四張卡", "棄到剩四張卡"];
TChineseQuestionDescriptions[QuestionIds.FRIENDLY] = ["棄掉一張 [Friendly] 的卡來獲得卡片", "棄掉一張 [Friendly] 的卡來獲得卡片"];
TChineseQuestionDescriptions[QuestionIds.INHERITED] = ["將起始的卡片替換為[Inherited]卡", "將起始的卡片替換為[Inherited]卡"];
TChineseQuestionDescriptions[QuestionIds.INSPIRING] = ["你可以打出一張你出牌區沒有的卡片", "你可以打出一張你出牌區沒有的卡片 ([Inspiring])."];
TChineseQuestionDescriptions[QuestionIds.PATIENT_SET_ASIDE] = ["將 [Patient]卡放到一邊，下回合再打出", "將 [Patient]卡放到一邊，下回合再打出"];
TChineseQuestionDescriptions[QuestionIds.PATIENT_PLAY] = ["你要打出哪一張[Patient]卡片呢", "你要打出哪一張[Patient]卡片呢"];
TChineseQuestionDescriptions[QuestionIds.PIOUS_TRASH] = ["你可以移除一張手牌", "你可以移除一張手牌"];
TChineseQuestionDescriptions[QuestionIds.SHY] = ["棄掉一[Shy]的卡來換取 +2 卡片", "棄掉一張[Shy]的卡來換取 +2 卡片"];
TChineseQuestionDescriptions[QuestionIds.FATED_TOPDECK] = ["你可以將一張[Fated]卡放到牌庫頂", "你可以將一張[Fated]卡放到牌庫頂"];
TChineseQuestionDescriptions[QuestionIds.FATED_BOTTOMDECK] = ["你可以將一張[Fated]卡放到牌庫底", "你可以將一張[Fated]卡放到牌庫底"];
TChineseQuestionDescriptions[QuestionIds.AVOID] = ["將至多 3 張卡放到你棄牌堆", "將至多 3 張卡放到你棄牌堆"];
TChineseQuestionDescriptions[QuestionIds.TRICKSTER_DISCARD] = ["請問要將哪張錢幣卡放到一邊?", "請問要將哪張錢幣卡放到一邊?"];

// Missing lines as of 09.01.2023 20:41:48
TChineseQuestionDescriptions[QuestionIds.BAND_OF_MISFITS] = ["發動一張較便宜的卡片", "發動一張卡片"];
TChineseQuestionDescriptions[QuestionIds.DEATH_CART] = ["你要移除一張行動卡還是運屍車", "你要移除一張行動卡還是運屍車"];
TChineseQuestionDescriptions[QuestionIds.OVERLORD] = ["發動一張價值至多 $5 的卡片", "發動一張卡"];
TChineseQuestionDescriptions[QuestionIds.HORN_TOPDECK] = ["選擇哪一張 [Border Guard] 來放到牌庫頂?", "選擇哪一張 [Border Guard] 來放到牌庫頂?"];
TChineseQuestionDescriptions[QuestionIds.BLACKSMITH] = ["選擇鐵工的哪個能力?", "選擇鐵工的哪個能力?"];
TChineseQuestionDescriptions[QuestionIds.MARCHLAND] = ["", "棄卡來換取 +$1"];

TChineseQuestionDescriptions[QuestionIds.FARMHANDS] = ["哪一張卡要你下次回合開始的時候發動效果?", "哪一張卡要你下次回合開始的時候發動效果?"];
TChineseQuestionDescriptions[QuestionIds.COURSER] = ["選擇駿馬的效果", "選擇駿馬的效果"];
TChineseQuestionDescriptions[QuestionIds.CORONET_ACTION] = ["將一張行動卡打出並發動兩次效果?", "將一張行動卡打出並發動兩次效果?"];
TChineseQuestionDescriptions[QuestionIds.CORONET_TREASURE] = ["將一張錢幣卡打出並發動兩次效果?", "將一張錢幣卡打出並發動兩次效果?"];
TChineseQuestionDescriptions[QuestionIds.JOUST_SET_ASIDE] = ["將行省放到一邊?", "將行省放到一邊?"];
TChineseQuestionDescriptions[QuestionIds.JOUST_GAIN_REWARD] = ["你想要獲得哪張獎勵卡?", "你想要獲得哪張獎勵卡到手中?"];
TChineseQuestionDescriptions[QuestionIds.INFIRMARY_TRASH] = ["你可以移除一張卡", "你可以移除一張卡"];
TChineseQuestionDescriptions[QuestionIds.INFIRMARY_OVERPAY] = ["你可以對醫院超額支付.", "你可以超額支付"];
TChineseQuestionDescriptions[QuestionIds.FOOTPAD] = TChineseQuestionDescriptions[QuestionIds.MILITIA];
TChineseQuestionDescriptions[QuestionIds.FARRIER_OVERPAY] = ["你可以對蹄鐵工超額支付", "你可以超額支付"];
TChineseQuestionDescriptions[QuestionIds.SHOP] = TChineseQuestionDescriptions[QuestionIds.CONCLAVE];
TChineseQuestionDescriptions[QuestionIds.FERRYMAN] = TChineseQuestionDescriptions[QuestionIds.FUGITIVE];

TChineseQuestionDescriptions[QuestionIds.ORDER_BOTTOMDECKED_CARDS] = ["決定牌庫底的順序", "決定牌庫底的順序"];
TChineseQuestionDescriptions[QuestionIds.GROWTH] = TChineseQuestionDescriptions[QuestionIds.BORDER_VILLAGE];
TChineseQuestionDescriptions[QuestionIds.KIND_EMPEROR] = ["獲得一張行動到手牌中", "獲得一張行動到手牌中"];
TChineseQuestionDescriptions[QuestionIds.SICKNESS_MODE] = ["", ""];
TChineseQuestionDescriptions[QuestionIds.SICKNESS_DISCARD] = ["", ""];
TChineseQuestionDescriptions[QuestionIds.SICKNESS] = ["棄掉三張手牌或是獲得一張詛咒到牌庫頂", "棄掉三張手牌或是獲得一張詛咒到牌庫頂"];
TChineseQuestionDescriptions[QuestionIds.SNAKE_WITCH] = ["歸還 [Snake Witch] 來發詛咒?", "歸還 [Snake Witch] 來發詛咒?"];
TChineseQuestionDescriptions[QuestionIds.CRAFTSMAN] = ["獲得一張至多 5的卡", "獲得一張至多 5的卡"];
TChineseQuestionDescriptions[QuestionIds.ALLEY] = TChineseQuestionDescriptions[QuestionIds.OASIS];
TChineseQuestionDescriptions[QuestionIds.CHANGE_TRASH] = ["移除一張手牌", "移除一張手牌"];
TChineseQuestionDescriptions[QuestionIds.CHANGE_GAIN] = ["獲得一張卡跟負債", "獲得一張卡跟負債"];
TChineseQuestionDescriptions[QuestionIds.NINJA] = TChineseQuestionDescriptions[QuestionIds.MILITIA];
TChineseQuestionDescriptions[QuestionIds.RIVER_SHRINE_TRASH] = ["移除至多兩張牌", "移除至多兩張牌"];
TChineseQuestionDescriptions[QuestionIds.RIVER_SHRINE_GAIN] = ["獲得一張至多4 的卡片", "獲得一張至多4 的卡片"];
TChineseQuestionDescriptions[QuestionIds.RUSTIC_VILLAGE_CHOICE] = ["棄掉兩張卡來抽一張?", "棄掉兩張卡來抽一張?"];
TChineseQuestionDescriptions[QuestionIds.RUSTIC_VILLAGE_DISCARD] = ["棄掉哪兩張卡?", "棄掉哪兩張卡?"];
TChineseQuestionDescriptions[QuestionIds.GOLD_MINE] = ["獲得一張黃金跟4負債?", "獲得一張黃金跟4負債?"];
TChineseQuestionDescriptions[QuestionIds.KITSUNE] = ["如果發動效果?", "如果發動效果?"];
TChineseQuestionDescriptions[QuestionIds.TANUKI_TRASH] = TChineseQuestionDescriptions[QuestionIds.REMODEL_TRASH];
TChineseQuestionDescriptions[QuestionIds.TANUKI_GAIN] = TChineseQuestionDescriptions[QuestionIds.REMODEL_GAIN];
TChineseQuestionDescriptions[QuestionIds.SAMURAI] = TChineseQuestionDescriptions[QuestionIds.MILITIA];
TChineseQuestionDescriptions[QuestionIds.MOUNTAIN_SHRINE] = ["你可以移除一張手牌", "你可以移除一張手牌"];
TChineseQuestionDescriptions[QuestionIds.AMASS] = ["獲得一張至多 5 的行動卡", "獲得一張至多 5 的行動卡"];
TChineseQuestionDescriptions[QuestionIds.ASCETICISM] = ["移除至多 Argument0 張卡", "移除至多 Argument0 張卡"];
TChineseQuestionDescriptions[QuestionIds.CONTINUE] = ["獲得一張非攻擊卡", "獲得一張非攻擊卡"];
TChineseQuestionDescriptions[QuestionIds.CREDIT] = ["獲得一張至多 8 行動卡跟錢幣卡", "獲得一張至多 8 行動卡跟錢幣卡"];
TChineseQuestionDescriptions[QuestionIds.KINTSUGI_TRASH] = ["移除一張手牌", "移除一張手牌"];
TChineseQuestionDescriptions[QuestionIds.KINTSUGI_GAIN] = TChineseQuestionDescriptions[QuestionIds.REMODEL_GAIN];
TChineseQuestionDescriptions[QuestionIds.PRACTICE] = TChineseQuestionDescriptions[QuestionIds.THRONE_ROOM];
TChineseQuestionDescriptions[QuestionIds.SEA_TRADE] = ["移除至多 Argument0 張卡", "移除至多 Argument0 張卡"];
TChineseQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_FIRST] = ["你可以獲得三張你沒打出的行動卡", "你可以獲得三張你沒打出的行動卡"];
TChineseQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_SECOND] = ["你可以獲得兩張你沒打出的行動卡", "你可以獲得兩張你沒打出的行動卡"];
TChineseQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_THIRD] = ["你可以獲得一張你沒打出的行動卡", "你可以獲得一張你沒打出的行動卡"];
TChineseQuestionDescriptions[QuestionIds.GATHER] = ["獲得一張價值剛好 Argument0 的卡", "獲得一張價值剛好 Argument0 的卡"];

TChineseQuestionDescriptions[QuestionIds.GAME_PAY_OFF_DEBT] = ["你可以還債.", "你可以還債"];
TChineseQuestionDescriptions[QuestionIds.MAY_PAY_OFF_EXTRA_DEBT] = ["你可以還債", "你可以還債"];
TChineseQuestionDescriptions[QuestionIds.PAY_OFF_DEBT_BEFORE_CHANGE] = ["歸還負債來重建?", "歸還負債來重建?"];
TChineseQuestionDescriptions[QuestionIds.PAY_OFF_DEBT_BEFORE_STORYTELLER] = ["歸還負債來抽少一些卡?", "歸還負債來抽少一些卡?"];