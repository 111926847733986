"use strict";

webclient.component("botmatch", {
    controller: ['automatch', function (automatch) {
        var self = this;

        self.practiceGamesHelp = function () {
            return ClientActions.openUrl('http://forum.shuffleit.nl/index.php?topic=1679.msg5889#msg5889');
        };
        self.automatch = automatch;
        self.getFindBotGameText = function () {
            return getPhrase(Phrases.FIND_BOT_GAME);
        };
        self.getStartPreviewBotMatchText = function () {
            return getPhrase(Phrases.PREVIEW_BOT_MATCH);
        };
        self.getStart1BotMatchText = function () {
            return getPhrase(Phrases.START_BOT_GAME_1);
        };
        self.getStart2BotMatchText = function () {
            return getPhrase(Phrases.START_BOT_GAME_2);
        };
        self.getStart3BotMatchText = function () {
            return getPhrase(Phrases.START_BOT_GAME_3);
        };
    }],
    template: "\n        <div ng-if=\"!$ctrl.automatch.iAmSearching()\" class=\"automatch-page automatch-table unselectable\">\n            <div class=\"fieldset-div automatch-fieldset automatch-middle\">\n                <div class=\"fieldset-title\">{{::$ctrl.getFindBotGameText()}} <div class=\"fieldset-title-link\" ng-click=\"$ctrl.practiceGamesHelp()\">\u2139</div></div>\n                <div ng-if=\"$ctrl.automatch.showPreviewQueue\" class=\"automatch-start\">\n                    <button class=\"lobby-button stone-button automatch-botmatch-button-wide\"\n                        ng-click=\"$ctrl.automatch.botMatch(1, true)\">\n                            {{$ctrl.getStartPreviewBotMatchText()}}\n                    </button>\n                </div>\n                <div class=\"automatch-start\">                \n                    <button class=\"lobby-button stone-button automatch-botmatch-button\"\n                        ng-click=\"$ctrl.automatch.botMatch(1)\">\n                            {{$ctrl.getStart1BotMatchText()}}\n                    </button>\n                    <button class=\"lobby-button stone-button automatch-botmatch-button\"\n                        ng-click=\"$ctrl.automatch.botMatch(2)\">\n                            {{$ctrl.getStart2BotMatchText()}}\n                    </button>\n                    <button class=\"lobby-button stone-button automatch-botmatch-button\"\n                        ng-click=\"$ctrl.automatch.botMatch(3)\">\n                            {{$ctrl.getStart3BotMatchText()}}\n                    </button>\n                </div>\n            </div>\n        </div>        \n    "
});