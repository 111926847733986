"use strict";

var TChineseCardTexts = {};

TChineseCardTexts[CardNames.BACK] = "";
TChineseCardTexts[CardNames.CURSE] = "";
TChineseCardTexts[CardNames.COPPER] = "";
TChineseCardTexts[CardNames.SILVER] = "";
TChineseCardTexts[CardNames.GOLD] = "";
TChineseCardTexts[CardNames.ESTATE] = "";
TChineseCardTexts[CardNames.DUCHY] = "";
TChineseCardTexts[CardNames.PROVINCE] = "";
TChineseCardTexts[CardNames.ARTISAN] = "\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A[5]\u7684\u5361\u5230\u624B\u724C\uFF0C//\u5F9E\u624B\u724C\u9078\u64C7\u4E00\u5F35\u5361\u7247\u653E\u56DE\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.BANDIT] = "\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\uFF0C\u5176\u4ED6\u73A9\u5BB6//\u5C55\u793A\u724C\u5EAB\u9802\u7684\u5169\u5F35\u724C\uFF0C\u79FB//\u9664\u5176\u4E2D\u4E00\u5F35\u975E\u9285\u5E63\u7684\u9322\u5E63//\u5361\uFF0C\u68C4\u6389\u5269\u4E0B\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.BUREAUCRAT] = "\u7372\u5F97\u4E00\u5F35\u9280\u5E63\uFF0C\u5C07\u9280\u5E63\u653E//\u7F6E\u5728\u4F60\u7684\u724C\u5806\u9802\uFF0C\u5176\u4ED6\u6240//\u6709\u4EBA\u5C55\u793A\u4E00\u5F35\u624B\u4E0A\u7684\u5206\u6578//\u5361\uFF0C\u5C07\u5B83\u653E\u5230\u5404\u81EA\u724C\u5806\u9802//(\u6216\u662F\u5C55\u793A\u624B\u724C\u6C92\u6709\u5206\u6578\u5361)";
TChineseCardTexts[CardNames.CELLAR] = "|\uFF0B1 \u884C\u52D5\u3002|////\u68C4\u6389\u4EFB\u610F\u5F35\u6578\u7684\u5361\u7247\uFF0C//\u62BD\u53D6\u76F8\u540C\u6578\u91CF\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.CHAPEL] = "\u79FB\u9664\u4F60\u624B\u4E0A\u81F3\u591A\u56DB\u5F35\u5361\u7247\u3002";
TChineseCardTexts[CardNames.COUNCIL_ROOM] = "|\uFF0B4 \u5361\u7247|//|\uFF0B1 \u8CFC\u8CB7|////\u5176\u4ED6\u73A9\u5BB6\u62BD\u53D6\u4E00\u5F35\u5361\u7247\u3002";
TChineseCardTexts[CardNames.FESTIVAL] = "|\uFF0B2 \u884C\u52D5|//|\uFF0B1 \u8CFC\u8CB7|//|\uFF0B[2]|";
TChineseCardTexts[CardNames.GARDENS] = "\u904A\u6232\u7D50\u675F\u6642\uFF0C\u4F60\u6BCF\u64C1\u670910\u5F35//\u5361\u7247\uFF0C\u6B64\u5361\u6703\u7D66\u4E88\u4F60{1}\u3002//(\u7121\u689D\u4EF6\u6368\u53BB)";
TChineseCardTexts[CardNames.HARBINGER] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u4F60\u53EF\u4EE5\u5F9E\u4F60\u7684\u68C4\u724C\u5806\u9078\u51FA//\u4E00\u5F35\u5361\u7247\uFF0C\u653E\u5230\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.LABORATORY] = "|\uFF0B2 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|";
TChineseCardTexts[CardNames.LIBRARY] = "\u62BD\u5230\u624B\u724C\u4E03\u5F35\u70BA\u6B62\uFF0C\u4F60\u53EF\u4EE5//\u9078\u64C7\u8DF3\u904E\u884C\u52D5\u5361\uFF1B\u88AB\u8DF3\u904E\u7684//\u884C\u52D5\u5361\u5148\u653E\u7F6E\u4E00\u908A\uFF0C\u62BD\u6EFF\u4E03//\u5F35\u4E4B\u5F8C\uFF0C\u68C4\u6389\u90A3\u4E9B\u88AB\u653E\u7F6E\u4E00//\u908A\u7684\u884C\u52D5\u5361\u3002";
TChineseCardTexts[CardNames.MARKET] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//|\uFF0B1 \u8CFC\u8CB7|//|\uFF0B[1]|";
TChineseCardTexts[CardNames.MERCHANT] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u6B64\u56DE\u5408\u7B2C\u4E00\u6B21\u6253\u51FA//\u9280\u5E63\u7684\u6642\u5019|\uFF0B|[1]\u3002";
TChineseCardTexts[CardNames.MILITIA] = "|\uFF0B[2]|//\u5176\u4ED6\u73A9\u5BB6\u5C07\u624B\u724C//\u68C4\u5230\u5269\u4E09\u5F35\u5361\u3002";
TChineseCardTexts[CardNames.MINE] = "\u4F60\u53EF\u4EE5\u79FB\u9664\u624B\u4E0A\u7684\u4E00\u5F35\u9322//\u5E63\u5361\uFF0C\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A//\u52A0[3]\u7684\u9322\u5E63\u5361\u5230\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.MOAT] = "|\uFF0B2 \u5361\u7247|//---//\u7576\u5176\u4ED6\u73A9\u5BB6\u6253\u51FA\u653B\u64CA\u5361\u6642\uFF0C//\u4F60\u53EF\u4EE5\u5148\u5C55\u793A\u624B\u4E0A\u7684\u6B64\u5361\uFF0C//\u4F86\u7121\u6548\u5C0D\u4F60\u7684\u5F71\u97FF\u3002";
TChineseCardTexts[CardNames.MONEYLENDER] = "\u4F60\u53EF\u4EE5\u79FB\u9664\u624B\u4E0A\u4E00\u5F35\u9285\u5E63//\u63DB\u53D6|\uFF0B|[3]\u3002";
TChineseCardTexts[CardNames.POACHER] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//|\uFF0B[1]|////\u4F9B\u61C9\u5340\u6BCF\u6709\u4E00\u758A\u7A7A\u7684\u724C\u5806\uFF0C//\u4F60\u5C31\u5FC5\u9808\u68C4\u6389\u4E00\u5F35\u624B\u724C\u3002";
TChineseCardTexts[CardNames.REMODEL] = "\u79FB\u9664\u624B\u4E0A\u4E00\u5F35\u5361\u7247\uFF0C\u7372\u5F97\u4E00//\u5F35\u50F9\u503C\u81F3\u591A\u52A0[2]\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.SENTRY] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u770B\u724C\u9802\u4E0A\u9762\u7684\u5169\u5F35\u5361\uFF0C\u5C0D\u5169//\u5F35\u724C\u5404\u81EA\u6C7A\u5B9A\u79FB\u9664\u6216\u662F\u68C4\u6389//\u6216\u662F\u6309\u7167\u4EFB\u610F\u9806\u5E8F\u653E\u56DE\u3002";
TChineseCardTexts[CardNames.SMITHY] = "|\uFF0B3 \u5361\u7247|";
TChineseCardTexts[CardNames.THRONE_ROOM] = "\u4F60\u53EF\u4EE5\u6253\u51FA\u624B\u4E0A\u7684\u4E00\u5F35\u884C\u52D5\u5361\uFF0C//\u4E26\u57F7\u884C\u5169\u6B21\u6548\u679C\u3002";
TChineseCardTexts[CardNames.VASSAL] = "|\uFF0B[2]|////\u68C4\u6389\u724C\u5EAB\u9802\u4E0A\u7684\u4E00\u5F35\u5361\u7247\uFF0C//\u5982\u679C\u662F\u884C\u52D5\u5361\u7684\u8A71\uFF0C\u4F60\u53EF\u4EE5//\u6253\u51FA\u5B83\u3002";
TChineseCardTexts[CardNames.VILLAGE] = "|\uFF0B1 \u5361\u7247|//|\uFF0B2 \u884C\u52D5|";
TChineseCardTexts[CardNames.WITCH] = "|\uFF0B2\u5361\u7247|////\u5176\u4ED6\u73A9\u5BB6\u7372\u5F97\u4E00\u5F35\u8A5B\u5492\u3002";
TChineseCardTexts[CardNames.WORKSHOP] = "\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A[4]\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.COURTYARD] = "|\uFF0B3 \u5361\u7247|////\u5C07\u4F60\u4E00\u5F35\u624B\u724C\u653E\u56DE\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.CONSPIRATOR] = "|\uFF0B[2]|////\u5982\u679C\u4F60\u9019\u56DE\u5408\u767C\u52D5\u904E\u4E09\u5F35//\u4EE5\u4E0A\u7684\u884C\u52D5\u5361(\u5305\u542B\u6B64\u5361)\uFF0C//\u5247|\uFF0B1 \u5361\u7247| \u548C |\uFF0B1 \u884C\u52D5|.";
TChineseCardTexts[CardNames.COURTIER] = "\u5C55\u793A\u4F60\u624B\u4E0A\u7684\u4E00\u5F35\u5361\u7247\uFF0C\u90A3//\u5F35\u5361\u6709\u5E7E\u500B\u7A2E\u985E\uFF08\u884C\u52D5\uFF0C\u653B//\u64CA\u7B49\u7B49\uFF09\u5C31\u53EF\u4EE5\u9078\u64C7\u4E0B\u5217\u5E7E//\u500B\u4E0D\u540C\u7684\u6548\u679C\uFF1A|\uFF0B1 \u884C\u52D5|//\uFF1B\u6216|\uFF0B1 \u8CFC\u8CB7|\uFF1B\u6216|\uFF0B|[3]//\uFF1B\u6216\u662F\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\u3002";
TChineseCardTexts[CardNames.BARON] = "|\uFF0B1 \u8CFC\u8CB7|////\u4F60\u53EF\u4EE5\u68C4\u6389\u4E00\u5F35\u838A\u5712\u4F86|\uFF0B|[4]\uFF0C//\u5982\u679C\u6C92\u6709\u68C4\u6389\u5247\u7372\u5F97\u4E00\u5F35\u838A\u5712\u3002";
TChineseCardTexts[CardNames.BRIDGE] = "|\uFF0B1 \u8CFC\u8CB7|//|\uFF0B[1]|////\u9019\u56DE\u5408\u6240\u6709\u5361\u7247\u50F9\u503C\u964D\u4F4E[1]\u3002//";
TChineseCardTexts[CardNames.DIPLOMAT] = "|\uFF0B2 \u5361\u7247|////\u5982\u679C\u4F60\u62BD\u724C\u4E4B\u5F8C\u624B\u724C\u57285\u5F35\u4EE5\u4E0B\uFF0C//\u5247\u4F60|\uFF0B2 \u884C\u52D5|\u3002//---//\u7576\u5176\u4ED6\u4EBA\u6253\u51FA\u4E00\u5F35\u653B\u64CA\u5361\uFF0C//\u4E14\u4F60\u624B\u724C\u5927\u65BC\u7B49\u65BC5\u5F35\u7684\u6642//\u5019\uFF0C\u4F60\u53EF\u4EE5\u5148\u5C55\u793A\u6B64\u5361\uFF0C\u4F86//\u62BD\u5169\u5F35\u5361\u7247\u68C4\u6389\u4E09\u5F35\u5361\u7247\u3002";
TChineseCardTexts[CardNames.DUKE] = "\u904A\u6232\u7D50\u675F\u6642\uFF0C\u6BCF\u4E00\u5F35\u516C\u570B\u90FD//\u6703\u8B93\u4F60\u904A\u6232\u7D50\u675F\u591A{1}\u3002";
TChineseCardTexts[CardNames.FARM] = "[!2]////---//{!2}";
TChineseCardTexts[CardNames.NOBLES] = "\u9078\u64C7\u4E00\u500B\uFF1A|\uFF0B3 \u5361\u7247|; //\u6216|\uFF0B2 \u884C\u52D5|\u3002//---//{!2}";
TChineseCardTexts[CardNames.IRONWORKS] = "\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A[4]\u7684\u5361\uFF0C//\u5982\u679C\u7372\u5F97\u7684\u5361\u7247\u985E\u578B\u5305\u542B...//\u884C\u52D5\u5361\uFF1A\u5247|\uFF0B1 \u884C\u52D5|//\u9322\u5E63\u5361\uFF1A\u5247|\uFF0B|[1]    //\u5206\u6578\u5361\uFF1A\u5247|\uFF0B1 \u5361\u7247|";
TChineseCardTexts[CardNames.LURKER] = "|\uFF0B1 \u884C\u52D5|////\u9078\u64C7\u4E00\u500B\u6548\u679C\uFF1A//\u79FB\u9664\u4E00\u5F35\u5728\u4F9B\u61C9\u5340\u7684\u884C\u52D5\u5361\uFF1B//\u6216\u7372\u5F97\u4E00\u5F35\u88AB\u79FB\u9664\u7684\u884C\u52D5\u5361\u3002";
TChineseCardTexts[CardNames.MASQUERADE] = "|\uFF0B2 \u5361\u7247|////\u6240\u6709\u9084\u6709\u624B\u724C\u7684\u73A9\u5BB6\uFF0C\u5C07\u4E00\u5F35//\u624B\u724C\u50B3\u905E\u7D66\u5DE6\u624B\u908A\u540C\u6A23\u6709\u50B3\u905E//\u5361\u7247\u7684\u73A9\u5BB6\uFF0C\u63A5\u8457\u4F60\u53EF\u4EE5\u79FB\u9664//\u624B\u4E0A\u7684\u4E00\u5F35\u5361\u7247\u3002";
TChineseCardTexts[CardNames.MILL] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//\u4F60\u53EF\u4EE5\u68C4\u6389\u5169\u5F35\u624B\u724C\u4F86|\uFF0B|[2]\u3002//---////{!1}";
TChineseCardTexts[CardNames.MINING_VILLAGE] = "|\uFF0B1 \u5361\u7247|//|\uFF0B2 \u884C\u52D5|////\u4F60\u53EF\u4EE5\u79FB\u9664\u6B64\u5361\u4F86|\uFF0B|[2]\u3002";
TChineseCardTexts[CardNames.MINION] = "|\uFF0B1 \u884C\u52D5|////\u9078\u4E00\u500B\u6548\u679C\uFF1A|\uFF0B|[2]\uFF1B//\u6216\u68C4\u6389\u4F60\u7684\u624B\u724C\u7136\u5F8C|\uFF0B4 \u5361\u7247|\uFF0C//\u5176\u4ED6\u624B\u724C\u6709\u4E94\u5F35\u4EE5\u4E0A\u7684\u73A9\u5BB6\uFF0C//\u68C4\u6389\u5168\u90E8\u7684\u624B\u724C\uFF0C\u7136\u5F8C\u62BD4\u5F35\u3002";
TChineseCardTexts[CardNames.PATROL] = "|\uFF0B3 \u5361\u7247|////\u5C55\u793A\u724C\u5EAB\u9802\u7684\u56DB\u5F35\u5361\u7247\u3002\u5C07//\u5176\u4E2D\u7684\u5206\u6578\u5361\u548C\u8A5B\u5492\u5361\u52A0\u5230//\u4F60\u7684\u624B\u724C\u4E2D\uFF0C\u5269\u4E0B\u7684\u5361\u7247\u6309//\u7167\u4EFB\u610F\u9806\u5E8F\u653E\u56DE\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.PAWN] = "\u9078\u5169\u500B\u6548\u679C\uFF1A |\uFF0B1 \u5361\u7247|; //|\uFF0B1 \u884C\u52D5|; |\uFF0B1 \u8CFC\u8CB7|; |\uFF0B|[1].//\u9078\u7684\u5169\u500B\u6548\u679C\u5FC5\u9808\u4E0D\u540C\u3002";
TChineseCardTexts[CardNames.REPLACE] = "\u79FB\u9664\u624B\u4E0A\u4E00\u5F35\u5361\u7247\uFF0C\u7372\u5F97\u4E00\u5F35//\u50F9\u503C\u81F3\u591A\u52A0[2]\u7684\u5361\u7247\uFF0C\u5982\u679C\u7372//\u5F97\u7684\u662F\u9322\u5E63\u5361\u6216\u884C\u52D5\u5361\uFF0C\u5C07\u5361//\u7247\u653E\u5230\u724C\u5EAB\u9802\u3002\u5982\u679C\u662F\u5206\u6578\u5361//\uFF0C\u5247\u5176\u4ED6\u73A9\u5BB6\u7372\u5F97\u4E00\u5F35\u8A5B\u5492\u3002//";
TChineseCardTexts[CardNames.SECRET_PASSAGE] = "|\uFF0B2 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//\u5C07\u4E00\u5F35\u624B\u724C\u653E\u5230\u4F60//\u724C\u5806\u7684\u4EFB\u610F\u4F4D\u7F6E\u3002";
TChineseCardTexts[CardNames.SHANTY_TOWN] = "|\uFF0B2 \u884C\u52D5|////\u5C55\u793A\u4F60\u7684\u624B\u724C\uFF0C\u5982\u679C\u6C92\u6709\u4EFB//\u4F55\u884C\u52D5\u5361\u7684\u8A71 |\uFF0B2 \u5361\u7247|\u3002";
TChineseCardTexts[CardNames.STEWARD] = "\u9078\u64C7\u4E00\u500B\uFF1A|\uFF0B2 \u5361\u7247|; //\u6216|\uFF0B|[2];// \u6216\u79FB\u9664\u5169\u5F35\u624B\u724C\u3002";
TChineseCardTexts[CardNames.SWINDLER] = "|\uFF0B[2]|////\u5176\u4ED6\u73A9\u5BB6\u79FB\u9664\u724C\u5EAB\u9802\u7684\u4E00\u5F35\u5361//\u7247\uFF0C\u4E26\u7372\u5F97\u4E00\u5F35\u540C\u50F9\u503C\u7684\u5361\u7247//\uFF0C\u5982\u679C\u6709\u591A\u7A2E\u540C\u50F9\u503C\u7684\u5361\u7247\uFF0C//\u7531\u4F60\u6C7A\u5B9A\u8981\u7372\u5F97\u54EA\u4E00\u5F35\u5361\u7247\u3002";
TChineseCardTexts[CardNames.TORTURER] = "|\uFF0B3 \u5361\u7247|////\u5176\u4ED6\u73A9\u5BB6\u5404\u81EA\u6C7A\u5B9A\u8981\u68C4\u6389\u5169//\u5F35\u624B\u724C\u6216\u662F\u7372\u5F97\u4E00\u5F35\u8A5B\u5492\u3002//\uFF08\u5C31\u7B97\u505A\u4E0D\u4E86\u9084\u662F\u53EF\u4EE5\u9078\uFF09";
TChineseCardTexts[CardNames.TRADING_POST] = "\u79FB\u9664\u624B\u4E0A\u5169\u5F35\u5361\u7247\uFF0C\u5982\u679C\u4F60//\u6709\u79FB\u9664\u5169\u5F35\u7684\u8A71\uFF0C\u5247\u7372\u5F97\u4E00//\u5F35\u9280\u5E63\u5230\u624B\u4E0A\u3002";
TChineseCardTexts[CardNames.UPGRADE] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u79FB\u9664\u624B\u4E0A\u4E00\u5F35\u5361\u7247\uFF0C\u7372\u5F97\u4E00//\u5F35\u50F9\u503C\u525B\u597D\u591A[1]\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.WISHING_WELL] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u6307\u5B9A\u4E00\u500B\u540D\u5B57\uFF0C\u7136\u5F8C\u5C55\u793A\u724C//\u5EAB\u9802\u7684\u4E00\u5F35\u5361\u7247\uFF0C\u5982\u679C\u76F8\u7B26//\uFF0C\u5247\u5C07\u90A3\u5F35\u5361\u7247\u653E\u5230\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.AMBASSADOR] = "\u5C55\u793A\u4E00\u5F35\u4F60\u624B\u4E0A\u7684\u5361\u7247\uFF0C//\u4F60\u53EF\u4EE5\u6B78\u9084\u81F3\u591A\u5169\u5F35\u540C\u540D//\u7684\u5361\u7247\u5230\u4ED6\u5011\u539F\u672C\u7684\u724C\u5806//\uFF0C\u5176\u4ED6\u73A9\u5BB6\u7372\u5F97\u4E00\u5F35\u540C\u540D//\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.BAZAAR] = "|\uFF0B1 \u5361\u7247|//|\uFF0B2 \u884C\u52D5|//|\uFF0B[1]|";
TChineseCardTexts[CardNames.CARAVAN] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u5728\u4F60\u4E0B\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019//|\uFF0B1 \u5361\u7247|\u3002";
TChineseCardTexts[CardNames.CUTPURSE] = "|\uFF0B[2]|////\u5176\u4ED6\u73A9\u5BB6\u68C4\u6389\u4E00\u5F35\u9285\u5E63\uFF0C\u6216//\u662F\u5C55\u793A\u624B\u724C\u88E1\u9762\u6C92\u6709\u9285\u5E63\u3002";
TChineseCardTexts[CardNames.EMBARGO] = "|\uFF0B[2]|////\u79FB\u9664\u6B64\u5361\u4F86\u653E\u4E00\u500B\u6A19//\u8A18\u7269\u5728\u4E00\u500B\u724C\u5806\u4E0A\u3002//\uFF08\u4E4B\u5F8C\u4EFB\u4F55\u73A9\u5BB6\u8CFC\u8CB7//\u8A72\u724C\u5806\u7684\u5361\u7247\u6642\uFF0C\u8A72//\u73A9\u5BB6\u7372\u5F97\u4E00\u500B\u8A5B\u5492\uFF09";
TChineseCardTexts[CardNames.EXPLORER] = "\u4F60\u53EF\u4EE5\u5C55\u793A\u624B\u4E0A\u7684\u4E00\u5F35\u884C\u7701//\uFF0C\u5982\u679C\u4F60\u5C55\u793A\u4E86\uFF0C\u4F60\u7372\u5F97\u4E00//\u5F35\u9EC3\u91D1\u4E26\u653E\u5165\u624B\u4E2D\uFF0C\u5982\u679C\u4F60//\u6C92\u6709\u5C55\u793A\uFF0C\u5247\u7372\u5F97\u4E00\u5F35\u9280\u5E63//\uFF0C\u653E\u5165\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.FISHING_VILLAGE] = "|\uFF0B2 \u884C\u52D5|//|\uFF0B[1]|////\u5728\u4E0B\u6B21\u4F60\u7684\u56DE\u5408\uFF1A//|\uFF0B1 \u884C\u52D5| \u548C |\uFF0B|[1]";
TChineseCardTexts[CardNames.GHOST_SHIP] = "|\uFF0B2 \u5361\u7247|////\u5176\u4ED6\u624B\u724C\u6709\u56DB\u5F35\u4EE5\u4E0A\u7684\u73A9\u5BB6//\uFF0C\u5C07\u624B\u724C\u653E\u5230\u4ED6\u5011\u7684\u724C\u5EAB\u9802//\uFF0C\u76F4\u5230\u4ED6\u5011\u624B\u724C\u90FD\u5269\u4E09\u5F35\u3002";
TChineseCardTexts[CardNames.HAVEN] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u5C07\u4E00\u5F35\u5361\u7247\u9762\u671D\u4E0B\u653E\u5230\u6B64\u5361//\u4E0B\uFF0C\u5728\u4F60\u4E0B\u6B21\u56DE\u5408\u958B\u59CB\u7684\u6642//\u5019\uFF0C\u5C07\u90A3\u5F35\u5361\u7247\u653E\u5230\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.ISLAND] = "\u5C07\u6B64\u5361\u548C\u4F60\u624B\u4E0A\u7684\u4E00\u5F35\u5361//\u653E\u5230\u4E00\u908A\uFF0C\u5728\u6700\u5F8C\u8A08\u5206\u3002//---//{!2}";
TChineseCardTexts[CardNames.LIGHTHOUSE] = "|\uFF0B1 \u884C\u52D5|////\u6B64\u56DE\u5408\u8DDF\u4E0B\u6B21\u4F60\u7684//\u56DE\u5408|\uFF0B|[1]\u3002//\u4E0B\u6B21\u8F2A\u5230\u4F60\u4E4B\u524D\uFF0C\u5176\u4ED6\u73A9\u5BB6//\u6253\u51FA\u7684\u653B\u64CA\u5361\u4E0D\u6703\u5F71\u97FF\u4F60\u3002";
TChineseCardTexts[CardNames.LOOKOUT] = "|\uFF0B1 \u884C\u52D5|////\u4F60\u89C0\u770B\u724C\u5EAB\u9802\u7684\u4E09\u5F35\u5361\u7247\uFF0C//\u79FB\u9664\u5176\u4E2D\u4E00\u5F35\uFF0C\u68C4\u6389\u5176\u4E2D\u4E00//\u5F35\uFF0C\u5C07\u5269\u4E0B\u7684\u653E\u56DE\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.MERCHANT_SHIP] = "\u6B64\u56DE\u5408\u8DDF\u4E0B\u6B21\u4F60\u7684//\u56DE\u5408\uFF1A|\uFF0B|[2].";
TChineseCardTexts[CardNames.NATIVE_VILLAGE] = "|\uFF0B2 \u884C\u52D5|////\u9078\u64C7\u4E00\u500B\uFF1A\u5C07\u724C\u5EAB\u9802\u7684\u4E00//\u5F35\u5361\u7247\u9762\u671D\u4E0B\u653E\u5230\u4E00\u908A\uFF0C//\uFF08\u4F60\u96A8\u6642\u53EF\u4EE5\u67E5\u770B\u6B64\u5361\u653E//\u4E00\u908A\u7684\u5361\u7247\uFF09\uFF1B\u5C07\u4F60\u7528\u6B64//\u5361\u653E\u5230\u4E00\u908A\u7684\u5361\u62FF\u5230\u624B\u4E0A\u3002";
TChineseCardTexts[CardNames.NAVIGATOR] = "|\uFF0B[2]|////\u89C0\u770B\u4F60\u724C\u5EAB\u9802\u7684\u4E94\u5F35\u5361\u7247\uFF0C//\u4F60\u53EF\u4EE5\u9078\u64C7\u5168\u90E8\u68C4\u6389\u6216\u662F\u5168//\u90E8\u653E\u56DE\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.OUTPOST] = "\u4F60\u672C\u56DE\u5408\u7D50\u675F\u7684\u6642\u5019\u624B\u724C\u53EA//\u88DC\u5230\u4E09\u5F35\u5361\uFF0C\u4E26\u4E14\u4F60\u5F97\u5230\u4E00//\u500B\u984D\u5916\u7684\u56DE\u5408\u3002//(\u4F60\u4E0D\u53EF\u4EE5\u9023\u7E8C\u52D5\u4E09\u500B\u56DE\u5408)";
TChineseCardTexts[CardNames.PEARL_DIVER] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u4F60\u89C0\u770B\u724C\u5EAB\u6700\u5E95\u4E0B\u7684\u5361\u7247\uFF0C//\u4F60\u53EF\u4EE5\u628A\u5B83\u653E\u5230\u724C\u5EAB\u9802\u4E0A\u3002";
TChineseCardTexts[CardNames.PIRATE_SHIP] = "\u9078\u64C7\u4E00\u500B\uFF1A\u4F60\u7684\u6D77\u76DC\u677F\u584A\u4E0A//\u6BCF\u6709\u4E00\u500B\u9322\u5E63\u6307\u793A\u7269\u5247|\uFF0B|[1]//\uFF1B\u5176\u4ED6\u73A9\u5BB6\u5C55\u793A\u724C\u5EAB\u9802\u7684\u5169//\u5F35\u5361\u7247\uFF0C\u79FB\u9664\u5176\u4E2D\u4E00\u5F35\u7684\u9322//\u5E63\u5361\uFF08\u7531\u4F60\u6C7A\u5B9A\uFF09\uFF0C\u68C4\u6389\u5269//\u4E0B\u7684\u5361\u7247\uFF0C\u5982\u679C\u6709\u4EFB\u4F55\u4EBA\u79FB//\u9664\u4E86\u5361\u7247\uFF0C\u5247\u5728\u6D77\u76DC\u677F\u584A\u4E0A//\u52A0\u4E00\u500B\u9322\u5E63\u6307\u793A\u7269\u3002";
TChineseCardTexts[CardNames.SALVAGER] = "|\uFF0B1 \u8CFC\u8CB7|////\u79FB\u9664\u624B\u4E0A\u7684\u4E00\u5F35\u5361\u7247\uFF0C\u90A3\u5F35//\u5361\u7247\u6BCF\u50F9\u503C[1]\uFF0C\u4F60\u5C31|\uFF0B|[1]\u3002";
TChineseCardTexts[CardNames.SEA_HAG] = "\u5176\u4ED6\u6240\u6709\u73A9\u5BB6\u68C4\u6389\u724C\u5EAB\u9802//\u7684\u4E00\u5F35\u5361\u7247\uFF0C\u4ED6\u5011\u7372\u5F97\u4E00//\u5F35\u8A5B\u5492\u4E26\u4E14\u653E\u5728\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.SMUGGLERS] = "\u5F9E\u4F60\u53F3\u624B\u908A\u7684\u73A9\u5BB6\u4E0A\u56DE\u5408//\u7372\u5F97\u7684\u5361\u7247\u4E2D\uFF0C\u9078\u64C7\u4E00\u5F35//\u50F9\u503C[6]\u4EE5\u4E0B\u7684\u5361\u7247\uFF0C//\u4F60\u7372\u5F97\u4E00\u5F35\u540C\u540D\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.TACTICIAN] = "\u5982\u679C\u4F60\u624B\u724C\u9084\u6709\u81F3\u5C11\u4E00\u5F35\u5361//\u7247\uFF0C\u68C4\u6389\u624B\u4E0A\u7684\u6240\u6709\u5361\u7247\uFF0C//\u5728\u4E0B\u6B21\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\uFF1A//|\uFF0B5 \u5361\u7247|, |\uFF0B1 \u884C\u52D5|,//\u548C|\uFF0B1 \u8CFC\u8CB7|.";
TChineseCardTexts[CardNames.TREASURE_MAP] = "\u79FB\u9664\u6B64\u5361\u8DDF\u624B\u4E0A\u7684\u4E00\u5F35\u85CF\u5BF6//\u5716\uFF0C\u5982\u679C\u4F60\u79FB\u9664\u4E86\u5169\u5F35\u85CF\u5BF6//\u5716\uFF0C\u5247\u7372\u5F97\u56DB\u5F35\u9EC3\u91D1\uFF0C\u4E14\u653E//\u5728\u4F60\u7684\u724C\u5EAB\u9802\u4E0A\u3002";
TChineseCardTexts[CardNames.TREASURY] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//|\uFF0B[1]|//\u56DE\u5408\u7D50\u675F\u7684\u968E\u6BB5\u5C07\u51FA\u724C\u5340//\u7684\u6B64\u5361\u68C4\u6389\u6642\uFF0C\u5982\u679C\u4F60\u9019\u56DE\u5408\u6C92\u6709//\u8CFC\u8CB7\u5206\u6578\u5361\uFF0C\u4F60\u53EF\u4EE5\u5C07\u6B64\u5361\u653E\u56DE//\u4F60\u7684\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.WAREHOUSE] = "|\uFF0B3 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u68C4\u6389\u4E09\u5F35\u5361\u7247\u3002";
TChineseCardTexts[CardNames.WHARF] = "\u6B64\u56DE\u5408\u8DDF\u4E0B\u6B21\u4F60\u7684\u56DE\u5408\uFF1A//|\uFF0B2 \u5361\u7247|\u548C|\uFF0B1 \u8CFC\u8CB7|.";
TChineseCardTexts[CardNames.ALCHEMIST] = "|\uFF0B2 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//\u7576\u6B64\u5361\u5F9E\u51FA\u724C\u5340\u88AB\u68C4\u6389\u7684//\u6642\u5019\uFF0C\u5982\u679C\u4F60\u7684\u51FA\u724C\u5340\u88E1//\u6709\u85E5\u5291\uFF0C\u4F60\u53EF\u4EE5\u5C07\u6B64\u5361\u653E//\u56DE\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.APOTHECARY] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u5C55\u793A\u724C\u5EAB\u9802\u7684\u56DB\u5F35\u5361\u7247\uFF0C//\u5C07\u9285\u5E63\u8DDF\u85E5\u5291\u653E\u5230\u624B\u4E2D\uFF0C//\u5C07\u5176\u4ED6\u7684\u5361\u4EE5\u4EFB\u610F\u9806\u5E8F\u653E//\u56DE\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.APPRENTICE] = "|\uFF0B1 \u884C\u52D5|////\u79FB\u9664\u4F60\u624B\u4E0A\u7684\u4E00\u5F35\u5361\u7247\uFF0C//\u6BCF\u50F9\u503C[1]\u5247 |\uFF0B1 \u5361\u7247|//\u5982\u679C\u50F9\u503C\u6709[P]\u5247 |\uFF0B2 \u5361\u7247|";
TChineseCardTexts[CardNames.FAMILIAR] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u5176\u4ED6\u73A9\u5BB6\u7372\u5F97\u4E00\u5F35\u8A5B\u5492\u3002";
TChineseCardTexts[CardNames.GOLEM] = "\u6301\u7E8C\u5C55\u793A\u4F60\u724C\u5EAB\u9802\u7684\u5361\u7247//\uFF0C\u76F4\u5230\u4F60\u5C55\u793A\u5169\u5F35\u4E0D\u662F\u9B54//\u50CF\u7684\u884C\u52D5\u5361\u70BA\u6B62\uFF0C\u68C4\u6389\u5176//\u5B83\u7684\u5361\u7247\uFF0C\u7136\u5F8C\u4EE5\u4EFB\u610F\u7684//\u9806\u5E8F\u6253\u51FA\u90A3\u5169\u5F35\u884C\u52D5\u5361\u3002";
TChineseCardTexts[CardNames.HERBALIST] = "|\uFF0B1 \u8CFC\u8CB7|//|\uFF0B[1]|//\u6B64\u56DE\u5408\u4E00\u6B21\uFF1A\u7576\u4F60\u5F9E\u51FA\u724C//\u5340\u68C4\u6389\u4E00\u5F35\u9322\u5E63\u5361\uFF0C\u4F60\u53EF//\u4EE5\u5C07\u8A72\u5361\u653E\u5230\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.PHILOSOPHERS_STONE] = "\u8A08\u7B97\u4F60\u7684\u724C\u5EAB\u8DDF\u68C4\u724C\u5806\uFF0C//\u6BCF\u6709\u4E94\u5F35\u5361\u6B64\u5361\u5C31\u7D66\u4E88[1]\u3002//\uFF08\u7121\u689D\u4EF6\u6368\u53BB\uFF09";
TChineseCardTexts[CardNames.POSSESSION] = "\u672C\u56DE\u5408\u7D50\u675F\u5F8C\uFF0C\u4F60\u5DE6\u624B\u908A\u7684\u73A9\u5BB6//\u984D\u5916\u9032\u884C\u4E00\u500B\u56DE\u5408\uFF0C\u5728\u8A72\u56DE\u5408\u4E2D//\uFF0C\u4F60\u53EF\u4EE5\u700F\u89BD\u4ED6\u6240\u770B\u5230\u7684\u5361\u7247\uFF0C//\u4E26\u7531\u4F60\u5E6B\u4ED6\u505A\u6240\u6709\u7684\u6C7A\u5B9A\u3002\u9019\u56DE//\u5408\u7372\u5F97\u7684\u6240\u6709\u5361\u548C\u6307\u793A\u7269\u90FD\u6539\u70BA//\u4F60\u7372\u5F97\uFF1B\u4EFB\u4F55\u4ED6\u7684\u5361\u7247\u88AB\u79FB\u9664\u90FD//\u5C07\u88AB\u653E\u7F6E\u5230\u4E00\u908A\uFF0C\u5728\u56DE\u5408\u7D50\u675F\u7684//\u6642\u5019\u653E\u5230\u4ED6\u7684\u68C4\u724C\u5806\u3002//(\u4F60\u4E0D\u80FD\u9023\u63A7\u5C0D\u624B\u5169\u56DE\u5408)";
TChineseCardTexts[CardNames.POTION] = "";
TChineseCardTexts[CardNames.SCRYING_POOL] = "|\uFF0B1 \u884C\u52D5|////\u6240\u6709\u73A9\u5BB6\u5C55\u793A\u81EA\u5DF1\u724C\u5EAB\u9802\u7684//\u4E00\u5F35\u5361\u7247\uFF0C\u7531\u4F60\u6C7A\u5B9A\u653E\u56DE\u6216//\u662F\u68C4\u6389\u3002\u6301\u7E8C\u5C55\u793A\u4F60\u6392\u5806\u9802//\u7684\u5361\uFF0C\u76F4\u5230\u5C55\u793A\u7684\u4E0D\u662F\u884C\u52D5//\u5361\u70BA\u6B62\uFF0C\u5C07\u4F60\u5C55\u793A\u7684\u5361\u7247\u5168//\u90E8\u653E\u5165\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.TRANSMUTE] = "\u79FB\u9664\u624B\u4E0A\u7684\u4E00\u5F35\u5361\u7247\uFF0C//\u5982\u679C\u4ED6\u662F...//\u884C\u52D5\u5361\uFF0C\u5247\u7372\u5F97\u4E00\u5F35\u516C\u570B\u3002//\u91D1\u9322\u5361\uFF0C\u5247\u7372\u5F97\u4E00\u5F35\u7149\u6210\u3002//\u5206\u6578\u5361\uFF0C\u5247\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\u3002";
TChineseCardTexts[CardNames.UNIVERSITY] = "|\uFF0B2 \u884C\u52D5|////\u4F60\u53EF\u4EE5\u7372\u5F97\u4E00\u5F35\u50F9\u503C//\u6700\u591A[5]\u7684\u884C\u52D5\u5361\u3002";
TChineseCardTexts[CardNames.VINEYARD] = "\u904A\u6232\u7D50\u675F\u6642\uFF0C\u4F60\u7684\u724C\u6BCF\u6709//\u4E09\u5F35\u884C\u52D5\u5361\u4FBF\u64C1\u6709{1}\u3002//\uFF08\u7121\u689D\u4EF6\u6368\u53BB\uFF09";
TChineseCardTexts[CardNames.BANK] = "\u7576\u4F60\u6253\u51FA\u9019\u5F35\u5361\uFF0C\u6BCF\u4E00\u5F35//\u51FA\u724C\u5340\u7684\u9322\u5E63\u5361\uFF0C\u6703\u8B93\u6B64//\u5361\u591A\u7D66\u4E88[1]\u3002(\u7B97\u4E0A\u9019\u5F35)";
TChineseCardTexts[CardNames.BISHOP] = "|\uFF0B{1}|//|\uFF0B[1]|////\u79FB\u9664\u624B\u4E0A\u7684\u4E00\u5F35\u5361\uFF0C\u90A3\u5F35\u5361//\u6BCF\u50F9\u503C[2]\u5247\u7372\u5F97|\uFF0B|{1}\u3002//\uFF08\u7121\u689D\u4EF6\u6368\u53BB\uFF09////\u5176\u5B83\u73A9\u5BB6\u53EF\u4EE5\u79FB\u9664\u4E00\u5F35\u624B\u724C\u3002";
TChineseCardTexts[CardNames.COLONY] = "";
TChineseCardTexts[CardNames.CONTRABAND] = "[!3]//|\uFF0B1 \u8CFC\u8CB7|////\u4F60\u5DE6\u908A\u7684\u73A9\u5BB6\u6307\u5B9A\u4E00//\u7A2E\u5361\u7247\uFF0C\u4F60\u9019\u56DE\u5408\u4E0D//\u80FD\u8CFC\u8CB7\u8A72\u5361\u3002";
TChineseCardTexts[CardNames.COUNTING_HOUSE] = "\u89C0\u770B\u4F60\u7684\u68C4\u724C\u5806\uFF0C//\u5C07\u68C4\u724C\u5806\u4EFB\u610F\u6578\u91CF//\u7684\u9285\u5E63\u52A0\u5165\u624B\u724C\u3002";
TChineseCardTexts[CardNames.CITY] = "|\uFF0B1 \u5361\u7247|//|\uFF0B2 \u884C\u52D5|////\u5982\u679C\u6709\u4E00\u500B\u4EE5\u4E0A\u7684\u4F9B\u61C9\u5340//\u724C\u5806\u662F\u7A7A\u7684\uFF0C|\uFF0B1 \u5361\u7247|\u3002////\u5982\u679C\u6709\u5169\u500B\u4EE5\u4E0A\u7684\u8A71\uFF0C//|\uFF0B1 \u8CFC\u8CB7| \u548C |\uFF0B|[1].";
TChineseCardTexts[CardNames.EXPAND] = "\u79FB\u9664\u4E00\u5F35\u624B\u724C\uFF0C\u7372\u5F97\u4E00//\u5F35\u6BD4\u8A72\u5361\u50F9\u503C\u81F3\u591A\u591A[3]//\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.FORGE] = "\u79FB\u9664\u4EFB\u610F\u6578\u91CF\u624B\u724C\uFF0C\u8A08\u7B97//\u88AB\u79FB\u9664\u5361\u50F9\u503C\u7684\u7E3D\u548C(\u50C5//\u8A08\u7B97[]\u7684\u90E8\u5206)\uFF0C\u7372\u5F97\u4E00\u5F35//\u50F9\u503C\u525B\u597D\u7B49\u65BC\u7E3D\u548C\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.GRAND_MARKET] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//|\uFF0B1 \u8CFC\u8CB7|//|\uFF0B[2]|//---//\u5982\u679C\u6709\u9285\u5E63\u5728\u51FA\u724C\u5340\uFF0C//\u5247\u7121\u6CD5\u8CFC\u8CB7\u9019\u5F35\u5361\u3002";
TChineseCardTexts[CardNames.GOONS] = "|\uFF0B1 \u8CFC\u8CB7|//|\uFF0B[2]|////\u5176\u5B83\u73A9\u5BB6\u5C07\u624B\u724C//\u68C4\u5230\u5269\u4E09\u5F35\u3002//---//\u7576\u6B64\u5361\u5728\u4F60\u51FA\u724C\u5340\uFF0C//\u6BCF\u8CFC\u8CB7\u4E00\u5F35\u5361\u7247|\uFF0B|{1}\u3002";
TChineseCardTexts[CardNames.HOARD] = "[!2]//---//\u7576\u6B64\u5361\u5728\u51FA\u724C\u5340\uFF0C\u6BCF\u6B21\u4F60\u8CFC//\u8CB7\u5206\u6578\u5361\uFF0C\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\u3002";
TChineseCardTexts[CardNames.KINGS_COURT] = "\u4F60\u53EF\u4EE5\u5F9E\u624B\u4E2D\u6253\u51FA\u4E00\u5F35\u884C//\u52D5\u5361\uFF0C\u4E26\u57F7\u884C\u4E09\u6B21\u6548\u679C\u3002";
TChineseCardTexts[CardNames.LOAN] = "[!1]//\u5C55\u793A\u4F60\u7684\u724C\u5EAB\u76F4\u5230\u5C55\u793A//\u4E00\u5F35\u9322\u5E63\u5361\u70BA\u6B62\u3002\u9078\u64C7//\u79FB\u9664\u6216\u68C4\u6389\u90A3\u5F35\u9322\u5E63\u5361//\u5F8C\uFF0C\u68C4\u6389\u5269\u4E0B\u7684\u5361\u3002";
TChineseCardTexts[CardNames.MINT] = "\u4F60\u53EF\u4EE5\u5C55\u793A\u624B\u724C\u7684\u4E00\u5F35\u9322//\u5E63\u5361\uFF0C\u7372\u5F97\u4E00\u5F35\u540C\u540D\u5361\u3002//---//\u7576\u4F60\u8CFC\u8CB7\u9019\u5F35\u5361\uFF0C\u79FB\u9664\u9019\u56DE//\u5408\u6240\u6709\u51FA\u724C\u5340\u7684\u9322\u5E63\u5361\u3002";
TChineseCardTexts[CardNames.MONUMENT] = "|\uFF0B{1}|//|\uFF0B[2]|";
TChineseCardTexts[CardNames.MOUNTEBANK] = "|\uFF0B[2]|////\u5176\u5B83\u73A9\u5BB6\u53EF\u4EE5\u68C4\u6389\u624B\u4E0A\u7684\u4E00//\u5F35\u8A5B\u5492\u3002\u5982\u679C\u6C92\u68C4\uFF0C\u5247\u4ED6\u7372//\u5F97\u4E00\u5F35\u8A5B\u5492\u8207\u4E00\u5F35\u9285\u5E63\u3002";
TChineseCardTexts[CardNames.PEDDLER] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//|\uFF0B[1]|//---//\u5728\u73A9\u5BB6\u8CFC\u8CB7\u968E\u6BB5\u6642\uFF0C\u4F60\u51FA\u724C\u5340\u88E1//\u6BCF\u6709\u4E00\u5F35\u884C\u52D5\u5361\u6703\u8B93\u6B64\u5361\u50F9\u503C\u6E1B//\u5C11[2]\uFF0C\u4F46\u4E0D\u6703\u4F4E\u65BC[0]\u3002";
TChineseCardTexts[CardNames.PLATINUM] = "";
TChineseCardTexts[CardNames.QUARRY] = "[!1]//---//\u7576\u6B64\u5361\u5728\u51FA\u724C\u5340\uFF0C\u884C\u52D5\u5361\u7684\u50F9//\u503C\u6E1B\u5C11[2]\u3002\u4F46\u4E0D\u6703\u4F4E\u65BC[0]\u3002";
TChineseCardTexts[CardNames.RABBLE] = "|\uFF0B3 \u5361\u7247|////\u5176\u5B83\u73A9\u5BB6\u5C55\u793A\u724C\u5EAB\u9802\u7684\u4E09//\u5F35\u724C\uFF0C\u68C4\u6389\u884C\u52D5\u5361\u8207\u9322\u5E63//\uFF0C\u5269\u4E0B\u7684\u5361\u8B93\u4ED6\u5011\u4EE5\u4EFB\u610F//\u9806\u5E8F\u653E\u56DE\u5404\u81EA\u7684\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.ROYAL_SEAL] = "[!2]//---//\u7576\u6B64\u5361\u5728\u4F60\u51FA\u724C\u5340\uFF0C\u4F60//\u7372\u5F97\u4E00\u5F35\u5361\u7247\u6642\uFF0C\u4F60\u53EF//\u4EE5\u5C07\u8A72\u5361\u653E\u5230\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.TALISMAN] = "[!1]//---//\u7576\u6B64\u5361\u5728\u4F60\u51FA\u724C\u5340\uFF0C\u4F60\u8CFC//\u8CB7\u4E00\u5F35\u50F9\u503C[4]\u4EE5\u4E0B\u7684\u975E//\u5206\u6578\u5361\u6642\uFF0C\u518D\u7372\u5F97\u4E00\u5F35\u3002";
TChineseCardTexts[CardNames.TRADE_ROUTE] = "|\uFF0B1 \u8CFC\u8CB7|////\u79FB\u9664\u624B\u4E0A\u4E00\u5F35\u5361\u7247\uFF0C\u672C\u5834\u904A\u6232//\u6BCF\u6709\u4E00\u7A2E\u5206\u6578\u5361\u88AB\u7372\u5F97\u904E\u5C31|\uFF0B|[1]\u3002//---//\u8D77\u59CB\u8A2D\u7F6E\uFF1A\u5C07\u767D\u8272\u6307\u793A\u7269\u653E\u5728//\u5206\u6578\u724C\u5806\u4E0A\uFF0C\u7528\u4F86\u6A19\u8A18\u9084\u6C92\u6709//\u88AB\u7372\u5F97\u904E\u7684\u5206\u6578\u5361\uFF0C\u5982\u679C\u8A72\u7A2E//\u5361\u7247\u6709\u88AB\u7372\u5F97\u904E\uFF0C\u79FB\u9664\u8A72\u6A19\u8A18\u3002";
TChineseCardTexts[CardNames.VAULT] = "|\uFF0B2 \u5361\u7247|////\u68C4\u6389\u4EFB\u610F\u6578\u91CF\u624B\u724C\uFF0C//\u6BCF\u68C4\u6389\u4E00\u5F35\u5247|\uFF0B|[1]\u3002////\u5176\u4ED6\u73A9\u5BB6\u53EF\u4EE5\u68C4\u6389\u5169\u5F35//\u5361\u7247\u4F86\u62BD\u4E00\u5F35\u5361\u7247\u3002";
TChineseCardTexts[CardNames.VENTURE] = "[!1]////\u6301\u7E8C\u5C55\u793A\u4F60\u7684\u724C\u5EAB\u9802\uFF0C//\u76F4\u5230\u4F60\u5C55\u793A\u7684\u5361\u7247\u662F\u9322//\u5E63\u5361\uFF0C\u6253\u51FA\u9322\u5E63\u5361\uFF0C\u4E26//\u68C4\u6389\u5176\u4ED6\u5C55\u793A\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.WATCHTOWER] = "\u624B\u724C\u62BD\u5230\u516D\u5F35\u3002//---//\u7576\u4F60\u7372\u5F97\u5361\u7247\u6642\uFF0C\u4F60\u53EF\u4EE5\u5C55//\u793A\u624B\u4E0A\u7684\u9019\u5F35\u5361\uFF0C\u5C07\u7372\u5F97\u7684//\u5361\u79FB\u9664\u6216\u653E\u5230\u724C\u5EAB\u9802\u4E0A\u3002";
TChineseCardTexts[CardNames.WORKERS_VILLAGE] = "|\uFF0B1 \u5361\u7247|//|\uFF0B2 \u884C\u52D5|//|\uFF0B1 \u8CFC\u8CB7|";
TChineseCardTexts[CardNames.PRIZE_PILE] = "";
TChineseCardTexts[CardNames.BAG_OF_GOLD] = "|\uFF0B1 \u884C\u52D5|////\u7372\u5F97\u4E00\u5F35\u7687\u91D1\uFF0C\u5C07\u5B83\u653E\u5230//\u4F60\u7684\u724C\u5EAB\u9802\u3002//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.DIADEM] = "[!2]//\u4F60\u6BCF\u5269\u4E0B\u4E00\u500B\u884C\u52D5\u6578\uFF0C//\u4F60\u5C31|\uFF0B|[1]\u3002//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.FAIRGROUNDS] = "\u4F60\u6BCF\u64C1\u6709\u4E94\u7A2E\u4E0D\u540C\u540D//\u5B57\u7684\u5361\u7247\uFF0C\u5C31\u64C1\u6709{2}//(\u7121\u689D\u4EF6\u6368\u53BB)";
TChineseCardTexts[CardNames.FARMING_VILLAGE] = "|\uFF0B2 \u884C\u52D5|////\u6301\u7E8C\u5C55\u793A\u4F60\u7684\u724C\u5EAB\u9802\uFF0C\u76F4//\u5230\u4F60\u5C55\u793A\u4E86\u4E00\u5F35\u9322\u5E63\u5361\u6216//\u662F\u884C\u52D5\u5361\u3002\u5C07\u90A3\u5F35\u5361\u653E\u5165//\u624B\u4E2D\uFF0C\u5C07\u5176\u4ED6\u5361\u7247\u68C4\u6389\u3002";
TChineseCardTexts[CardNames.FOLLOWERS] = "|\uFF0B2 \u5361\u7247|////\u7372\u5F97\u4E00\u5F35\u838A\u5712\uFF0C\u5176\u4ED6\u73A9//\u5BB6\u7372\u5F97\u4E00\u500B\u8A5B\u5492\u800C\u4E14\u68C4//\u5230\u5269\u4E0B\u4E09\u5F35\u624B\u724C\u3002//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.FORTUNE_TELLER] = "|\uFF0B[2]|//////\u5176\u4ED6\u73A9\u5BB6\u6301\u7E8C\u5C55\u793A\u724C\u5EAB\u9802//\u76F4\u5230\u4ED6\u5011\u5C55\u793A\u51FA\u5206\u6578\u5361\u6216//\u662F\u8A5B\u5492\uFF0C\u4ED6\u5C07\u5C07\u90A3\u5F35\u5361\u653E//\u5230\u724C\u5EAB\u9802\uFF0C\u4E26\u4E14\u5C07\u5176\u4ED6\u5361//\u68C4\u6389\u3002";
TChineseCardTexts[CardNames.HAMLET] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u4F60\u53EF\u4EE5\u68C4\u6389\u4E00\u5F35\u5361\u4F86\u63DB//|\uFF0B1 \u884C\u52D5|.//\u4F60\u53EF\u4EE5\u68C4\u6389\u4E00\u5F35\u5361\u4F86\u63DB//|\uFF0B1 \u8CFC\u8CB7|.";
TChineseCardTexts[CardNames.HARVEST] = "\u5C55\u793A\u4F60\u724C\u5EAB\u9802\u7684\u56DB\u5F35//\u5361\uFF0C\u7136\u5F8C\u68C4\u6389\u5B83\u5011\uFF0C//\u6BCF\u4E00\u7A2E\u4E0D\u540C\u7684\u540D\u5B57\u6703//\u8B93\u4F60|\uFF0B|[1]\u3002";
TChineseCardTexts[CardNames.HORSE_TRADERS] = "|\uFF0B1 \u8CFC\u8CB7|//|\uFF0B[3] |//\u68C4\u6389\u5169\u5F35\u5361\u3002//---//\u7576\u5176\u4ED6\u73A9\u5BB6\u6253\u51FA\u653B\u64CA\u5361\u6642\uFF0C\u4F60\u53EF//\u4EE5\u5C07\u6B64\u5361\u653E\u81F3\u4E00\u908A\uFF0C\u5982\u679C\u4F60\u653E\u7F6E//\u4E00\u908A\u7684\u8A71\uFF0C\u5728\u4F60\u4E0B\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019//|\uFF0B1 \u5361\u7247|\uFF0C\u4E26\u4E14\u6B64\u5361\u56DE\u5230\u624B\u4E0A\u3002";
TChineseCardTexts[CardNames.HORN_OF_PLENTY] = "\u8A08\u7B97\u4F60\u51FA\u724C\u5340\u4E0D\u540C\u540D\u7A31\u7684\u5361//\u7247\u6578\u91CF\uFF08\u5305\u542B\u6B64\u5361\uFF09\uFF0C\u4F60\u53EF//\u4EE5\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u4E0D\u5927\u65BC\u8A72\u6578//\u91CF\u7684\u5361\u7247\u3002\u5982\u679C\u7372\u5F97\u5361\u7247//\u662F\u5206\u6578\u5361\uFF0C\u5247\u79FB\u9664\u6B64\u5361\u3002";
TChineseCardTexts[CardNames.HUNTING_PARTY] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u5C55\u793A\u4F60\u7684\u624B\u724C\uFF0C\u6301\u7E8C\u5C55\u793A\u724C\u5EAB//\u9802\u7684\u5361\u7247\uFF0C\u76F4\u5230\u4F60\u7FFB\u5230\u4E00\u5F35\u4F60//\u624B\u4E0A\u6C92\u6709\u7684\u5361\u7247\uFF0C\u5C07\u90A3\u5F35\u5361\u7247//\u653E\u5165\u624B\u4E2D\uFF0C\u68C4\u6389\u5176\u4ED6\u5C55\u793A\u7684\u5361//\u7247\u3002";
TChineseCardTexts[CardNames.JESTER] = "|\uFF0B[2]|////\u6240\u6709\u5176\u4ED6\u73A9\u5BB6\u68C4\u6389\u724C\u5EAB\u9802\u7684\u4E00//\u5F35\u5361\u7247\uFF0C\u5982\u679C\u662F\u5206\u6578\u5361\uFF0C\u4ED6\u7372//\u5F97\u4E00\u5F35\u8A5B\u5492\uFF0C\u5982\u679C\u4E0D\u662F\uFF0C\u5247\u4F60//\u6C7A\u5B9A\u4F60\u5011\u8AB0\u8981\u7372\u5F97\u4E00\u5F35\u540C\u540D\u7684//\u5361\u3002";
TChineseCardTexts[CardNames.MENAGERIE] = "|\uFF0B1 \u884C\u52D5|////\u5C55\u793A\u4F60\u7684\u624B\u724C\uFF0C\u5982//\u679C\u6C92\u6709\u540D\u5B57\u76F8\u540C\u7684//\u5361\u7247\uFF0C\u5247|\uFF0B3 \u5361\u7247|\u3002//\u5426\u5247|\uFF0B1 \u5361\u7247|\u3002";
TChineseCardTexts[CardNames.PRINCESS] = "|\uFF0B1 \u8CFC\u8CB7|//\u6B64\u56DE\u5408\u6240\u6709\u5361\u7247\u50F9\u503C\u6E1B\u5C11[2]\u3002//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.REMAKE] = "\u57F7\u884C\u4EE5\u4E0B\u7684\u52D5\u4F5C\u5169\u6B21\uFF1A//\u79FB\u9664\u624B\u4E0A\u4E00\u5F35\u5361\u7247\uFF0C\u7372//\u5F97\u4E00\u5F35\u50F9\u503C\u525B\u597D\u591A[1]//\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.TOURNAMENT] = "|\uFF0B1 \u884C\u52D5|////\u6240\u6709\u73A9\u5BB6\u53EF\u4EE5\u5C55\u793A\u624B\u4E0A\u7684\u4E00\u5F35\u884C//\u7701\uFF0C\u5982\u679C\u4F60\u6709\u5C55\u793A\uFF0C\u68C4\u6389\u884C\u7701\u4E26//\u7372\u5F97\u4E00\u5F35\u734E\u52F5\u5361(\u5F9E\u734E\u52F5\u724C\u5806)\u6216//\u662F\u4E00\u5F35\u516C\u570B\uFF0C\u5C07\u62FF\u5230\u7684\u5361\u653E\u5728\u724C//\u5EAB\u9802\u4E0A\uFF0C\u5982\u679C\u5176\u4ED6\u73A9\u5BB6\u6C92\u6709\u5C55\u793A//\u884C\u7701\uFF0C\u4F60|\uFF0B1 \u5361\u7247| \u548C |\uFF0B|[1].";
TChineseCardTexts[CardNames.TRUSTY_STEED] = "\u9078\u64C7\u4EE5\u4E0B\u5169\u500B\u6548\u679C\uFF1A//|\uFF0B2 \u5361\u7247|;|\uFF0B2 \u884C\u52D5|;|\uFF0B|[2];//\u7372\u5F97\u56DB\u5F35\u9280\u5E63\uFF0C\u4E26\u5C07\u724C\u5806//\u653E\u5230\u68C4\u724C\u5806\u3002\u5169\u500B\u6548\u679C\u5FC5//\u9808\u4E0D\u76F8\u540C\u3002//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.YOUNG_WITCH] = "|\uFF0B2 \u5361\u7247|////\u68C4\u6389\u5169\u5F35\u5361\u7247\uFF0C\u5176\u4ED6\u73A9\u5BB6\u53EF\u4EE5//\u5C55\u793A\u624B\u4E0A\u7684\u6BD2\u85E5\u5361\uFF0C\u6C92\u6709\u5C55\u793A//\u7684\u4EBA\u7372\u5F97\u4E00\u5F35\u8A5B\u5492\u3002//---//\u8D77\u59CB\u8A2D\u7F6E: \u591A\u9078\u4E00\u758A\u50F9\u503C//[2]\u5230[3]\u7684\u738B\u570B\u5361\u5230\u4F9B\u61C9//\u5340\uFF0C\u90A3\u758A\u5361\u5C31\u662F\u6BD2\u85E5\u5361\u3002";
TChineseCardTexts[CardNames.BORDER_VILLAGE] = "|\uFF0B1 \u5361\u7247|//|\uFF0B2 \u884C\u52D5|//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\uFF0C\u7372\u5F97//\u4E00\u5F35\u8F03\u4FBF\u5B9C\u7684\u5361\u3002";
TChineseCardTexts[CardNames.CACHE] = "[!3]//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\u6642\uFF0C//\u7372\u5F97\u5169\u5F35\u9285\u5E63\u3002";
TChineseCardTexts[CardNames.CARTOGRAPHER] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u89C0\u770B\u4F60\u724C\u5EAB\u9802\u7684\u56DB\u5F35\u5361//\u7247\uFF0C\u68C4\u6389\u4EFB\u610F\u6578\u91CF\u7684\u5361//\u7247\uFF0C\u5C07\u5269\u4E0B\u7684\u5361\u7247\u4EE5\u4EFB//\u610F\u9806\u5E8F\u653E\u56DE\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.CROSSROADS] = "\u5C55\u793A\u4F60\u7684\u624B\u724C\uFF0C\u6BCF\u6709\u4E00\u5F35//\u5206\u6578\u5361\uFF0C\u4F60\u5C31|\uFF0B1 \u5361\u7247|\u3002//\u5982\u679C\u9019\u662F\u4F60\u672C\u56DE\u5408\u7B2C\u4E00\u6B21//\u4F7F\u7528\u5341\u5B57\u8DEF\u53E3\u7684\u6548\u679C\uFF0C\u5247//| \uFF0B3 \u884C\u52D5|.";
TChineseCardTexts[CardNames.DEVELOP] = "\u79FB\u9664\u4E00\u5F35\u4F60\u624B\u4E0A\u7684\u5361\u7247\uFF0C//\u9078\u64C7\u4E00\u5F35\u50F9\u503C\u525B\u597D\u591A[1]\u548C//\u4E00\u5F35\u50F9\u503C\u525B\u597D\u5C11[1]\u7684\u5361//\u7247\uFF0C\u4EE5\u4EFB\u610F\u9806\u5E8F\u7372\u5F97\u90A3\u5169//\u5F35\u5361\u7247\uFF0C\u4E26\u653E\u5230\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.DUCHESS] = "|\uFF0B[2]|////\u6240\u6709\u73A9\u5BB6\uFF08\u5305\u62EC\u81EA\u5DF1\uFF09//\u89C0\u770B\u724C\u5EAB\u9802\u7684\u4E00\u5F35\u5361\uFF0C//\u5404\u81EA\u9078\u64C7\u8981\u4E0D\u8981\u68C4\u6389\u3002//---//\u5728\u6709\u5973\u516C\u7235\u7684\u904A\u6232\u4E2D\uFF0C\u7372\u5F97\u516C//\u570B\u6642\uFF0C\u53EF\u4EE5\u7372\u5F97\u4E00\u5F35\u5973\u516C\u7235\u3002";
TChineseCardTexts[CardNames.EMBASSY] = "|\uFF0B5 \u5361\u7247|////\u68C4\u6389\u4E09\u5F35\u5361\u7247//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\u6642\uFF0C\u5176\u4ED6//\u73A9\u5BB6\u7372\u5F97\u4E00\u5F35\u9280\u5E63\u3002";
TChineseCardTexts[CardNames.FARMLAND] = "{!2}//---//\u7576\u4F60\u8CFC\u8CB7\u6B64\u5361\u6642\uFF0C\u79FB\u9664\u4F60//\u624B\u4E0A\u7684\u4E00\u5F35\u5361\u7247\uFF0C\u7372\u5F97\u4E00//\u5F35\u50F9\u503C\u525B\u597D\u591A[2]\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.FOOLS_GOLD] = "\u5982\u679C\u9019\u662F\u4F60\u672C\u56DE\u5408\u7B2C\u4E00\u6B21//\u6253\u51FA\u611A\u4EBA\u91D1\u5247\u7D66\u4E88[1]\uFF0C//\u5426\u5247\u7684\u8A71\uFF0C\u7D66\u4E88[4]\u3002//---//\u7576\u5176\u4ED6\u73A9\u5BB6\u7372\u5F97\u884C\u7701\u6642\uFF0C//\u4F60\u53EF\u4EE5\u79FB\u9664\u624B\u4E2D\u7684\u6B64\u5361\uFF0C//\u4F86\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\uFF0C\u4E26\u653E\u5230//\u4F60\u7684\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.HAGGLER] = "|\uFF0B[2]|//---//\u7576\u6B64\u5361\u5728\u51FA\u724C\u5340\uFF0C\u5728\u4F60\u8CFC//\u8CB7\u5361\u7247\u6642\uFF0C\u4F60\u5FC5\u9808\u7372\u5F97\u4E00//\u5F35\u8F03\u4FBF\u5B9C\u7684\u975E\u5206\u6578\u5361\u3002";
TChineseCardTexts[CardNames.HIGHWAY] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//---//\u7576\u6B64\u5361\u5728\u51FA\u724C\u5340\uFF0C\u6240\u6709\u5361//\u50F9\u503C\u6E1B\u5C11[1]\u4F46\u4E0D\u5C11\u65BC[0]\u3002";
TChineseCardTexts[CardNames.ILL_GOTTEN_GAINS] = "[!1]////\u4F60\u53EF\u4EE5\u7372\u5F97\u4E00\u5F35//\u9285\u5E63\u52A0\u5165\u624B\u4E2D\u3002//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\u6642\uFF0C\u5176\u4ED6//\u73A9\u5BB6\u7372\u5F97\u4E00\u5F35\u8A5B\u5492\u3002";
TChineseCardTexts[CardNames.INN] = "|\uFF0B2 \u5361\u7247|//|\uFF0B2 \u884C\u52D5|////\u68C4\u6389\u5169\u5F35\u5361\u7247\u3002//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\uFF0C\u89C0\u770B\u4F60\u7684\u68C4\u724C//\u5806\uFF0C\u5C55\u793A\u88E1\u9762\u4EFB\u610F\u6578\u91CF\u7684\u884C\u52D5//\u5361\uFF08\u53EF\u4EE5\u5305\u542B\u672C\u5361\uFF09\uFF0C\u7136\u5F8C\u5C07//\u5C55\u793A\u7684\u5361\u7247\u6D17\u9032\u724C\u5EAB\u3002";
TChineseCardTexts[CardNames.JACK_OF_ALL_TRADES] = "\u7372\u5F97\u4E00\u5F35\u9280\u5E63\u3002////\u89C0\u770B\u4F60\u724C\u5EAB\u9802\u7684\u4E00\u5F35\u5361\uFF0C//\u9078\u64C7\u68C4\u6389\u6216\u662F\u653E\u56DE\u3002////\u62BD\u5230\u624B\u724C\u4E94\u5F35\u70BA\u6B62\u3002////\u4F60\u53EF\u4EE5\u5F9E\u624B\u4E0A\u79FB\u9664//\u4E00\u5F35\u975E\u9322\u5E63\u5361\u3002";
TChineseCardTexts[CardNames.MANDARIN] = "|\uFF0B[3]|////\u5C07\u4F60\u624B\u4E0A\u4E00\u5F35\u5361//\u653E\u56DE\u724C\u5EAB\u9802\u3002//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\uFF0C\u5C07\u4F60//\u51FA\u724C\u5340\u7684\u9322\u5E63\u5361\u4EE5\u4EFB//\u610F\u9806\u5E8F\u653E\u56DE\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.NOBLE_BRIGAND] = "|\uFF0B[1]|////\u5176\u4ED6\u73A9\u5BB6\u5C55\u793A\u5404\u81EA\u724C\u5EAB\u9802\u7684//\u5169\u5F35\u5361\uFF0C\u5982\u679C\u6709\u9280\u5E63\u6216\u9EC3\u91D1//\uFF0C\u79FB\u9664\u8A72\u5361\u5F8C\uFF0C\u4F60\u7372\u5F97\u5B83\uFF0C//\u518D\u68C4\u6389\u5176\u4ED6\u5361\u7247\u3002\u5982\u679C\u5C55\u793A//\u7684\u5169\u5F35\u5361\u90FD\u4E0D\u662F\u9322\u5E63\u5361\uFF0C\u90A3//\u500B\u4EBA\u7372\u5F97\u4E00\u5F35\u9285\u5E63\u3002//\u8CFC\u8CB7\u6B64\u5361\u6642\uFF0C\u767C\u52D5\u6B64\u5361\u6548\u679C";
TChineseCardTexts[CardNames.NOMAD_CAMP] = "|\uFF0B1 \u8CFC\u8CB7|//|\uFF0B[2]|////---//\u7372\u5F97\u6B64\u5361\u6642\uFF0C\u5C07\u6B64\u5361\u653E\u5230\u724C//\u5EAB\u9802\uFF0C\u800C\u4E0D\u662F\u4F60\u7684\u68C4\u724C\u5806\u3002";
TChineseCardTexts[CardNames.OASIS] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//|\uFF0B[1]|////\u68C4\u6389\u4E00\u5F35\u624B\u724C\u3002";
TChineseCardTexts[CardNames.ORACLE] = "\u6240\u6709\u73A9\u5BB6\uFF08\u5305\u62EC\u4F60\u81EA\u5DF1\uFF09//\u5C55\u793A\u724C\u5EAB\u9802\u7684\u5169\u5F35\u5361\u7247\uFF0C//\u7531\u4F60\u6C7A\u5B9A\u6BCF\u500B\u73A9\u5BB6\u5C55\u793A\u7684//\u5169\u5F35\u724C\u8981\u68C4\u6389\u6216\u662F\u653E\u56DE\u3002//\u5982\u679C\u653E\u56DE\u724C\u5EAB\u9802\u7684\u8A71\uFF0C\u5404//\u81EA\u6C7A\u5B9A\u653E\u7684\u9806\u5E8F\u3002\u4E4B\u5F8C\u4F60//\u62BD\u51FA\u5169\u5F35\u5361\u7247\u3002";
TChineseCardTexts[CardNames.MARGRAVE] = "|\uFF0B3 \u5361\u7247|//|\uFF0B1 \u8CFC\u8CB7|////\u5176\u4ED6\u73A9\u5BB6\u62BD\u51FA\u4E00\u5F35\u5361//\u7247\u3002\u7136\u5F8C\u5C07\u624B\u724C\u68C4\u5230//\u5269\u4E0B\u4E09\u5F35\u3002";
TChineseCardTexts[CardNames.SCHEME] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u9078\u64C7\u4F60\u51FA\u724C\u5340\u7684\u4E00\u5F35\u884C\u52D5\u5361\uFF0C//\u5982\u679C\u5728\u6E05\u9664\u968E\u6BB5\uFF0C\u90A3\u5F35\u5361\u88AB\u68C4//\u6389\uFF0C\u4F60\u53EF\u4EE5\u5C07\u5B83\u653E\u56DE\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.SILK_ROAD] = "\u4F60\u6BCF\u64C1\u6709\u56DB\u5F35\u5206\u6578\u5361\uFF0C//\u6B64\u5361\u5C31\u7D66\u4E88{1}\u3002//\uFF08\u7121\u689D\u4EF6\u6368\u53BB\uFF09";
TChineseCardTexts[CardNames.SPICE_MERCHANT] = "\u4F60\u53EF\u4EE5\u79FB\u9664\u624B\u4E0A\u4E00\u5F35\u9322\u5E63//\u5361\u4F86\u63DB\u53D6\u5176\u4E2D\u4E00\u7D44\u6548\u679C\uFF1A//|\uFF0B2 \u5361\u7247|\u548C| \uFF0B1 \u884C\u52D5|;//\u6216| \uFF0B1 \u8CFC\u8CB7 |\u548C| \uFF0B|[2]\u3002";
TChineseCardTexts[CardNames.STABLES] = "\u4F60\u53EF\u4EE5\u68C4\u6389\u4E00\u5F35\u9322\u5E63\u5361//\u4F86|\uFF0B3 \u5361\u7247|\u548C|\uFF0B1 \u884C\u52D5|\u3002";
TChineseCardTexts[CardNames.TRADER] = "\u79FB\u9664\u4E00\u5F35\u4F60\u624B\u4E0A\u7684\u5361\u7247//\u3002\u90A3\u5F35\u5361\u7247\u6BCF\u50F9\u503C[1]\uFF0C//\u4F60\u7372\u5F97\u4E00\u5F35\u9280\u5E63\u3002//---//\u7576\u4F60\u7372\u5F97\u5361\u7247\u7684\u6642\u5019\uFF0C//\u4F60\u53EF\u4EE5\u5C55\u793A\u624B\u4E0A\u7684\u6B64\u5361//\uFF0C\u7528\u9280\u5E63\u8DDF\u8A72\u5361\u4EA4\u63DB\u3002";
TChineseCardTexts[CardNames.TUNNEL] = "{!2}//---//\u5982\u679C\u6B64\u5361\u5728\u6E05\u9664\u968E\u6BB5\u4EE5\u5916//\u7684\u6642\u5F8C\u88AB\u68C4\u6389\uFF0C\u4F60\u53EF\u4EE5\u5C55//\u793A\u6B64\u5361\u4F86\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\u3002";
TChineseCardTexts[CardNames.RUIN_PILE] = "";
TChineseCardTexts[CardNames.KNIGHTS] = "\u5728\u4F7F\u7528\u6B64\u758A\u738B\u570B\u5361\u4E4B\u524D\uFF0C//\u5148\u5C07\u6B64\u724C\u5806\u6D17\u724C\uFF0C\u5C07\u6B64\u724C\u5806//\u9762\u671D\u4E0B\uFF0C\u6C38\u9060\u5C07\u6700\u4E0A\u65B9\u7684\u5361//\u7247\u7FFB\u70BA\u6B63\u9762\uFF0C\u53EA\u80FD\u7372\u5F97\u6216\u662F//\u8CFC\u8CB7\u6700\u4E0A\u65B9\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.ABANDONED_MINE] = "|\uFF0B|[1]";
TChineseCardTexts[CardNames.ALTAR] = "\u79FB\u9664\u4F60\u624B\u4E0A\u7684\u4E00\u5F35\u5361\u7247\uFF0C\u7372//\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A[5]\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.ARMORY] = "\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A[4]\u7684\u5361\u7247//\uFF0C\u5C07\u5B83\u653E\u5230\u4F60\u7684\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.BAND_OF_MISFITS] = "\u7576\u4F60\u6253\u51FA\u6B64\u5361\uFF0C\u9078\u4E00\u5F35\u6BD4\u6B64\u5361//\u4FBF\u5B9C\u7684\u884C\u52D5\u5361\uFF0C\u767C\u52D5\u8A72\u5361\u7684\u6548//\u679C\uFF0C\u5728\u767C\u52D5\u6548\u679C\u6642\u4E0D\u80FD\u79FB\u52D5\u8A72//\u5361\uFF0C\u4E26\u7559\u5728\u4F9B\u61C9\u5340\u3002(\u4E0D\u80FD\u9078\u64C7//\u547D\u4EE4\u5361)";
TChineseCardTexts[CardNames.BANDIT_CAMP] = "|\uFF0B1 \u5361\u7247|//|\uFF0B2 \u884C\u52D5|////\u5F9E\u6230\u5229\u54C1\u724C\u5806\u7372//\u5F97\u4E00\u5F35\u6230\u5229\u54C1\u3002";
TChineseCardTexts[CardNames.BEGGAR] = "\u7372\u5F97\u4E09\u5F35\u9285\u5E63\u4E26\u653E\u5165\u624B\u724C\u3002//---//\u7576\u5176\u4ED6\u73A9\u5BB6\u6253\u51FA\u653B\u64CA\u5361\u6642\uFF0C//\u4F60\u53EF\u4EE5\u5148\u68C4\u6389\u6B64\u5361\uFF0C\u7372\u5F97\u5169//\u5F35\u9280\u5E63\uFF0C\u5C07\u5176\u4E2D\u4E00\u5F35\u653E\u5230\u4F60//\u7684\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.CATACOMBS] = "\u89C0\u770B\u4F60\u724C\u5EAB\u9802\u7684\u4E09\u5F35\u5361\u7247//\uFF0C\u9078\u64C7\u4E00\u500B\u6548\u679C\uFF1A\u5C07\u90A3\u4E09\u5F35//\u5361\u653E\u5165\u624B\u4E2D; \u6216\u662F\u68C4\u6389\u90A3\u4E09//\u5F35\u7247\uFF0C\u4E4B\u5F8C|\uFF0B3 \u5361\u7247|\u3002//---//\u7576\u4F60\u79FB\u9664\u6B64\u5361\u6642\uFF0C\u4F60\u7372//\u5F97\u4E00\u5F35\u8F03\u4FBF\u5B9C\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.COUNT] = "\u9078\u64C7\u4E00\u500B\uFF1A\u68C4\u6389\u5169\u5F35\u5361;//\u5C07\u624B\u4E0A\u4E00\u5F35\u5361\u653E\u56DE\u724C\u5EAB//\u9802;\u7372\u5F97\u4E00\u5F35\u9285\u5E63\u3002////\u9078\u64C7\u4E00\u500B:|\uFF0B|[3];\u6216\u79FB\u9664\u4F60\u5168//\u90E8\u7684\u624B\u724C;\u6216\u7372\u5F97\u4E00\u5F35\u516C\u570B\u3002";
TChineseCardTexts[CardNames.COUNTERFEIT] = "[!1]////|\uFF0B1 \u8CFC\u8CB7|////\u4F60\u53EF\u4EE5\u6253\u51FA\u624B\u4E0A\u4E00\u5F35\u975E\u6301//\u7E8C\u9322\u5E63\u5361\uFF0C\u767C\u52D5\u5169\u6B21\u6548\u679C//\uFF0C\u63A5\u8457\u79FB\u9664\u90A3\u5F35\u9322\u5E63\u5361\u3002";
TChineseCardTexts[CardNames.CULTIST] = "|\uFF0B2 \u5361\u7247|////\u5176\u4ED6\u73A9\u5BB6\u7372\u5F97\u4E00\u5F35\u5EE2\u589F\uFF0C//\u4F60\u53EF\u4EE5\u5F9E\u4F60\u624B\u4E0A\u6253\u51FA\u90AA//\u6559\u5F92(\u4E0D\u6D88\u8017\u884C\u52D5\u6578)\u3002//---//\u7576\u4F60\u79FB\u9664\u6B64\u5361\uFF0C|\uFF0B3 \u5361\u7247|.";
TChineseCardTexts[CardNames.DAME_ANNA] = "\u4F60\u53EF\u4EE5\u5F9E\u4F60\u624B\u4E0A\u79FB\u9664\u81F3\u591A//\u5169\u5F35\u5361\u3002\u5176\u4ED6\u73A9\u5BB6\u5C55\u793A\u724C//\u5EAB\u9802\u7684\u5169\u5F35\u5361\u7247\uFF0C\u4ED6\u5011\u81EA//\u5DF1\u9078\u64C7\u79FB\u9664\u4E00\u5F35\u50F9\u503C[3]\u5230//[6]\u7684\u5361\u7247\uFF0C\u68C4\u6389\u5269\u4E0B\u7684\u5361\u7247//\uFF0C\u5982\u679C\u88AB\u79FB\u9664\u7684\u5361\u7247\u6709\u9A0E//\u58EB\uFF0C\u5247\u79FB\u9664\u6B64\u5361\u3002";
TChineseCardTexts[CardNames.DAME_JOSEPHINE] = "\u5176\u4ED6\u73A9\u5BB6\u5C55\u793A\u724C\u5EAB\u9802\u7684\u5169\u5F35//\u5361\u7247\uFF0C\u4ED6\u5011\u81EA\u5DF1\u9078\u64C7\u79FB\u9664\u4E00//\u5F35\u50F9\u503C[3]\u5230[6]\u7684\u5361\u7247\uFF0C\u68C4//\u6389\u5269\u4E0B\u7684\u5361\u7247\uFF0C\u5982\u679C\u88AB\u79FB\u9664\u7684//\u5361\u7247\u6709\u9A0E\u58EB\uFF0C\u5247\u79FB\u9664\u6B64\u5361\u3002//---//{!2}";
TChineseCardTexts[CardNames.DAME_MOLLY] = "|\uFF0B2 \u884C\u52D5|////\u5176\u4ED6\u73A9\u5BB6\u5C55\u793A\u724C\u5EAB\u9802\u7684\u5169\u5F35//\u5361\u7247\uFF0C\u4ED6\u5011\u81EA\u5DF1\u9078\u64C7\u79FB\u9664\u4E00//\u5F35\u50F9\u503C[3]\u5230[6]\u7684\u5361\u7247\uFF0C\u68C4//\u6389\u5269\u4E0B\u7684\u5361\u7247\uFF0C\u5982\u679C\u88AB\u79FB\u9664\u7684//\u5361\u7247\u6709\u9A0E\u58EB\uFF0C\u5247\u79FB\u9664\u6B64\u5361\u3002";
TChineseCardTexts[CardNames.DAME_NATALIE] = "\u4F60\u53EF\u4EE5\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A//[3]\u7684\u5361\u7247\u3002\u5176\u4ED6\u73A9\u5BB6\u5C55\u793A\u724C//\u5EAB\u9802\u7684\u5169\u5F35\u5361\u7247\uFF0C\u4ED6\u5011\u81EA//\u5DF1\u9078\u64C7\u79FB\u9664\u4E00\u5F35\u50F9\u503C[3]\u5230//[6]\u7684\u5361\u7247\uFF0C\u68C4\u6389\u5269\u4E0B\u7684\u5361\u7247\uFF0C//\u5982\u679C\u88AB\u79FB\u9664\u7684\u5361\u7247\u6709\u9A0E\u58EB//\uFF0C\u5247\u79FB\u9664\u6B64\u5361\u3002";
TChineseCardTexts[CardNames.DAME_SYLVIA] = "|\uFF0B[2]|////\u5176\u4ED6\u73A9\u5BB6\u5C55\u793A\u724C\u5EAB\u9802\u7684\u5169\u5F35//\u5361\u7247\uFF0C\u4ED6\u5011\u81EA\u5DF1\u9078\u64C7\u79FB\u9664\u4E00//\u5F35\u50F9\u503C[3]\u5230[6]\u7684\u5361\u7247\uFF0C\u68C4//\u6389\u5269\u4E0B\u7684\u5361\u7247\uFF0C\u5982\u679C\u88AB\u79FB\u9664\u7684//\u5361\u7247\u6709\u9A0E\u58EB\uFF0C\u5247\u79FB\u9664\u6B64\u5361\u3002";
TChineseCardTexts[CardNames.DEATH_CART] = "\u4F60\u53EF\u4EE5\u79FB\u9664\u4F60\u624B\u4E0A//\u4E00\u5F35\u884C\u52D5\u5361\u6216\u662F\u6B64//\u5361\u4F86\u63DB\u53D6\uFF0B[5]//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\uFF0C//\u7372\u5F97\u5169\u5F35\u5EE2\u589F\u3002";
TChineseCardTexts[CardNames.FEODUM] = "\u4F60\u6BCF\u64C1\u6709\u4E09\u5F35\u9280\u5E63\uFF0C\u6B64\u5361\u5C31//\u64C1\u6709{1}\uFF08\u7121\u689D\u4EF6\u6368\u53BB\uFF09\u3002//---//\u7576\u4F60\u79FB\u9664\u6B64\u5361\uFF0C//\u7372\u5F97\u4E09\u5F35\u9280\u5E63\u3002";
TChineseCardTexts[CardNames.FORAGER] = "|\uFF0B1 \u884C\u52D5|//|\uFF0B1 \u8CFC\u8CB7|////\u79FB\u9664\u4F60\u624B\u4E0A\u4E00\u5F35\u5361\u7247\uFF0C//\u79FB\u9664\u724C\u5806\u6BCF\u6709\u4E00\u7A2E\u4E0D\u540C//\u540D\u5B57\u7684\u9322\u5E63\u5361\u5247|\uFF0B|[1]\u3002";
TChineseCardTexts[CardNames.FORTRESS] = "|\uFF0B1 \u5361\u7247|//|\uFF0B2 \u884C\u52D5|//---//\u7576\u4F60\u79FB\u9664\u6B64\u5361\uFF0C//\u5C07\u6B64\u5361\u653E\u5230\u4F60\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.GRAVEROBBER] = "\u9078\u64C7\u4E00\u500B\uFF1A\u7372\u5F97\u79FB\u9664\u724C\u5806\u88E1//\u7684\u4E00\u5F35\u50F9\u503C[3]\u5230[6]\u7684\u5361\u7247\u5230//\u4F60\u724C\u5EAB\u9802\uFF1B\u6216\u662F\u79FB\u9664\u4E00\u5F35\u4F60\u624B//\u4E0A\u7684\u884C\u52D5\u5361\uFF0C\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3//\u591A\u591A[3]\u7684\u4E00\u5F35\u5361\u7247\u3002";
TChineseCardTexts[CardNames.HERMIT] = "\u89C0\u770B\u4F60\u7684\u68C4\u724C\u5806\uFF0C\u4F60\u53EF\u4EE5//\u5F9E\u68C4\u724C\u5806\u6216\u4F60\u7684\u624B\u4E0A\u79FB\u9664//\u4E00\u5F35\u975E\u9322\u5E63\u5361\uFF0C\u7372\u5F97\u4E00\u5F35//\u50F9\u503C\u81F3\u591A[3]\u7684\u5361\u7247//---//\u7576\u4F60\u5F9E\u51FA\u724C\u5340\u5C07\u6B64\u5361\u68C4\u6389\uFF0C//\u5982\u679C\u4F60\u8CFC\u8CB7\u968E\u6BB5\u6C92\u6709\u7372\u5F97\u5361//\u7247\uFF0C\u7528\u6B64\u5361\u4EA4\u63DB\u4E00\u5F35\u760B\u4EBA\u3002";
TChineseCardTexts[CardNames.HOVEL] = "\u7576\u4F60\u7372\u5F97\u5206\u6578\u5361\u7684\u6642\u5019\uFF0C//\u4F60\u53EF\u4EE5\u79FB\u9664\u624B\u4E0A\u7684\u6B64\u5361\u3002";
TChineseCardTexts[CardNames.HUNTING_GROUNDS] = "|\uFF0B4 \u5361\u7247|//---//\u7576\u4F60\u79FB\u9664\u6B64\u5361\uFF0C\u7372\u5F97\u4E09//\u5F35\u838A\u5712\u6216\u662F\u4E00\u5F35\u516C\u570B\u3002";
TChineseCardTexts[CardNames.IRONMONGER] = "//|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//\u5C55\u793A\u4F60\u724C\u5EAB\u9802\u7684\u4E00\u5F35\u5361\uFF0C;\u4F60\u53EF//\u4EE5\u68C4\u6389\u90A3\u5F35\u5361\uFF0C\u5982\u679C\u5B83\u662F\u5F35...//\u884C\u52D5\u5361, |\uFF0B1 \u884C\u52D5|//\u9322\u5E63\u5361, |\uFF0B|[1]//\u5206\u6578\u5361,|\uFF0B1 \u5361\u7247|";
TChineseCardTexts[CardNames.JUNK_DEALER] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//|\uFF0B[1]|////\u79FB\u9664\u4F60\u624B\u4E0A\u4E00\u5F35\u5361\u3002";
TChineseCardTexts[CardNames.MADMAN] = "|\uFF0B2 \u884C\u52D5|////\u5C07\u6B64\u5361\u6B78\u9084\u5230\u760B\u4EBA\u724C\u5806\uFF0C//\u5982\u679C\u4F60\u6B78\u9084\u4E86\uFF0C\u4F60\u6BCF\u4E00\u5F35//\u624B\u724C\u53EF\u4EE5\u8B93\u4F60|\uFF0B1 \u5361\u7247|//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.MARKET_SQUARE] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//|\uFF0B1 \u8CFC\u8CB7|//---//\u7576\u4F60\u7684\u5361\u88AB\u79FB\u9664\u7684\u6642\u5019\uFF0C//\u4F60\u53EF\u4EE5\u68C4\u6389\u624B\u4E2D\u7684\u6B64\u5361\uFF0C//\u4F86\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\u3002";
TChineseCardTexts[CardNames.MARAUDER] = "\u5F9E\u6230\u5229\u54C1\u724C\u5806\u7372\u5F97\u4E00\u5F35//\u6230\u5229\u54C1\uFF0C\u5176\u4ED6\u73A9\u5BB6\u7372\u5F97//\u4E00\u5F35\u5EE2\u589F\u3002";
TChineseCardTexts[CardNames.MERCENARY] = "\u4F60\u53EF\u4EE5\u79FB\u9664\u624B\u4E0A\u5169\u5F35\u5361\u7247\uFF0C//\u5982\u679C\u4F60\u79FB\u9664\u4E86\uFF0C//\u5247|\uFF0B2 \u5361\u7247|, |\uFF0B|[2],//\u5176\u4ED6\u73A9\u5BB6\u5C07\u624B\u724C\u68C4\u5230\u5269\u4E09\u5F35\u3002//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.MYSTIC] = "|\uFF0B1 \u884C\u52D5|//|\uFF0B[2]|////\u6307\u5B9A\u4E00\u5F35\u5361\u7247\uFF0C\u7136\u5F8C\u5C55\u793A\u4F60//\u724C\u5EAB\u9802\u7684\u4E00\u5F35\u724C\u3002\u5982\u679C\u8207\u4E4B//\u76F8\u7B26\uFF0C\u5C07\u5B83\u52A0\u5165\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.NECROPOLIS] = "|\uFF0B2 \u884C\u52D5|";
TChineseCardTexts[CardNames.OVERGROWN_ESTATE] = "{!0}//---//\u7576\u4F60\u79FB\u9664\u6B64\u5361\uFF0C//\u4F60\u5F97\u5230|\uFF0B1 \u5361\u7247|.";
TChineseCardTexts[CardNames.PILLAGE] = "\u79FB\u9664\u6B64\u5361\uFF0C\u5982\u679C\u4F60\u79FB\u9664\u4E86//\u3002\u5176\u4ED6\u6709\u4E94\u5F35\u624B\u724C\u7684\u73A9\u5BB6//\u5C55\u793A\u4ED6\u5011\u7684\u624B\u724C\uFF0C\u7531\u4F60\u9078//\u64C7\u4E00\u5F35\u68C4\u6389\u3002\u5F9E\u6230\u5229\u54C1\u724C//\u5806\u7372\u5F97\u5169\u5F35\u6230\u5229\u54C1\u3002";
TChineseCardTexts[CardNames.POOR_HOUSE] = "|\uFF0B[4]|////\u5C55\u793A\u4F60\u7684\u624B\u724C\uFF0C\u6BCF\u4E00\u5F35\u4F60//\u624B\u4E2D\u7684\u9322\u5E63\u5361\u6703\u4F7F\u4F60-[1]//\u4F60\u4E0D\u6703\u4F4E\u65BC[0]\u3002";
TChineseCardTexts[CardNames.PROCESSION] = "\u4F60\u53EF\u4EE5\u5F9E\u624B\u4E2D\u6253\u51FA\u4E00\u5F35\u884C\u52D5//\u5361\uFF0C\u4F46\u4E0D\u80FD\u662F\u6301\u7E8C\u5361\uFF0C\u57F7\u884C//\u8A72\u5361\u6548\u679C\u5169\u6B21\u3002\u79FB\u9664\u5B83\uFF0C\u4E26//\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u525B\u597D\u591A[1]\u7684//\u884C\u52D5\u5361\u3002";
TChineseCardTexts[CardNames.RATS] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u7372\u5F97\u4E00\u5F35\u8001\u9F20\uFF0C\u5F9E\u4F60\u624B\u4E0A\u79FB//\u9664\u4E00\u5F35\u8001\u9F20\u4EE5\u5916\u7684\u5361\u7247\uFF0C//\uFF08\u6216\u5C55\u793A\u6EFF\u624B\u8001\u9F20\u2665\uFF09\u3002//---//\u7576\u4F60\u79FB\u9664\u6B64\u5361\u6642\uFF0C|\uFF0B1 \u5361\u7247|\u3002";
TChineseCardTexts[CardNames.REBUILD] = "|\uFF0B1 \u884C\u52D5|////\u6307\u5B9A\u4E00\u5F35\u5361\u7247\uFF0C\u6301\u7E8C\u5C55\u793A//\u4F60\u7684\u724C\u5EAB\uFF0C\u76F4\u5230\u4F60\u5C55\u793A\u51FA//\u4E00\u5F35\u4E0D\u662F\u4F60\u6307\u5B9A\u7684\u5206\u6578\u5361//\uFF0C\u68C4\u6389\u5269\u4E0B\u7684\u5361\u7247\uFF0C\u79FB\u9664//\u90A3\u5F35\u5206\u6578\u5361\uFF0C\u4E26\u4E14\u7372\u5F97\u4E00//\u5F35\u50F9\u503C\u81F3\u591A\u591A[3]\u7684\u5206\u6578\u5361\u3002";
TChineseCardTexts[CardNames.ROGUE] = "|\uFF0B[2]|////\u5982\u679C\u79FB\u9664\u724C\u5806\u88E1\u9762\u6709\u50F9\u503C//[3]\u5230[6]\u7684\u5361\u7247\uFF0C\u9078\u64C7\u4E00\u5F35\u7372\u5F97//\u3002\u5982\u679C\u6C92\u6709\uFF0C\u5247\u5176\u5B83\u73A9\u5BB6\u5C55\u793A//\u81EA\u5DF1\u724C\u5EAB\u9802\u7684\u5169\u5F35\u5361\u7247\uFF0C\u79FB//\u9664\u5176\u4E2D\u4E00\u5F35\u50F9\u503C[3]\u5230[6]\u7684//\u5361\u7247\uFF0C\u7136\u5F8C\u68C4\u6389\u5176\u4ED6\u5361\u7247\u3002";
TChineseCardTexts[CardNames.RUINED_LIBRARY] = "|\uFF0B1 \u5361\u7247|";
TChineseCardTexts[CardNames.RUINED_MARKET] = "|\uFF0B1 \u8CFC\u8CB7|";
TChineseCardTexts[CardNames.RUINED_VILLAGE] = "|\uFF0B1 \u884C\u52D5|";
TChineseCardTexts[CardNames.SAGE] = "|\uFF0B1 \u884C\u52D5|////\u6301\u7E8C\u5C55\u793A\u4F60\u7684\u724C\u5EAB\uFF0C\u76F4\u5230//\u4F60\u5C55\u793A\u51FA\u4E00\u5F35\u50F9\u503C[3]\u4EE5\u4E0A//\u7684\u5361\u7247\uFF0C\u5C07\u90A3\u5F35\u5361\u7247\u653E\u5165//\u624B\u4E2D\uFF0C\u5C07\u5176\u4ED6\u5361\u7247\u68C4\u6389\u3002";
TChineseCardTexts[CardNames.SCAVENGER] = "|\uFF0B[2]|////\u4F60\u53EF\u4EE5\u5C07\u4F60\u7684\u724C\u5EAB\u653E\u5230\u68C4//\u724C\u5806\uFF0C\u89C0\u770B\u4F60\u7684\u68C4\u724C\u5806\uFF0C//\u4E26\u4E14\u5C07\u68C4\u724C\u5806\u88E1\u7684\u4E00\u5F35\u5361//\u7247\u653E\u5230\u4F60\u7684\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.SIR_BAILEY] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u5176\u4ED6\u73A9\u5BB6\u5C55\u793A\u724C\u5EAB\u9802\u7684\u5169\u5F35//\u5361\u7247\uFF0C\u4ED6\u5011\u81EA\u5DF1\u9078\u64C7\u79FB\u9664\u4E00//\u5F35\u50F9\u503C[3]\u5230[6]\u7684\u5361\u7247\uFF0C\u68C4//\u6389\u5269\u4E0B\u7684\u5361\u7247\uFF0C\u5982\u679C\u88AB\u79FB\u9664\u7684//\u5361\u7247\u6709\u9A0E\u58EB\uFF0C\u5247\u79FB\u9664\u6B64\u5361\u3002";
TChineseCardTexts[CardNames.SIR_DESTRY] = "|\uFF0B2 \u5361\u7247|////\u5176\u4ED6\u73A9\u5BB6\u5C55\u793A\u724C\u5EAB\u9802\u7684\u5169\u5F35//\u5361\u7247\uFF0C\u4ED6\u5011\u81EA\u5DF1\u9078\u64C7\u79FB\u9664\u4E00//\u5F35\u50F9\u503C[3]\u5230[6]\u7684\u5361\u7247\uFF0C\u68C4//\u6389\u5269\u4E0B\u7684\u5361\u7247\uFF0C\u5982\u679C\u88AB\u79FB\u9664\u7684//\u5361\u7247\u6709\u9A0E\u58EB\uFF0C\u5247\u79FB\u9664\u6B64\u5361\u3002";
TChineseCardTexts[CardNames.SIR_MARTIN] = "|\uFF0B2 \u8CFC\u8CB7|////\u5176\u4ED6\u73A9\u5BB6\u5C55\u793A\u724C\u5EAB\u9802\u7684\u5169\u5F35//\u5361\u7247\uFF0C\u4ED6\u5011\u81EA\u5DF1\u9078\u64C7\u79FB\u9664\u4E00//\u5F35\u50F9\u503C[3]\u5230[6]\u7684\u5361\u7247\uFF0C\u68C4//\u6389\u5269\u4E0B\u7684\u5361\u7247\uFF0C\u5982\u679C\u88AB\u79FB\u9664\u7684//\u5361\u7247\u6709\u9A0E\u58EB\uFF0C\u5247\u79FB\u9664\u6B64\u5361\u3002";
TChineseCardTexts[CardNames.SIR_MICHAEL] = "\u5176\u4ED6\u73A9\u5BB6\u5C07\u624B\u724C\u68C4\u5230\u5269\u4E09\u5F35\u3002//\u5176\u4ED6\u73A9\u5BB6\u5C55\u793A\u724C\u5EAB\u9802\u7684\u5169\u5F35//\u5361\u7247\uFF0C\u4ED6\u5011\u81EA\u5DF1\u9078\u64C7\u79FB\u9664\u4E00//\u5F35\u50F9\u503C[3]\u5230[6]\u7684\u5361\u7247\uFF0C\u68C4//\u6389\u5269\u4E0B\u7684\u5361\u7247\uFF0C\u5982\u679C\u88AB\u79FB\u9664\u7684//\u5361\u7247\u6709\u9A0E\u58EB\uFF0C\u5247\u79FB\u9664\u6B64\u5361\u3002";
TChineseCardTexts[CardNames.SIR_VANDER] = "\u5176\u4ED6\u73A9\u5BB6\u5C55\u793A\u724C\u5EAB\u9802\u7684\u5169\u5F35//\u5361\u7247\uFF0C\u4ED6\u5011\u81EA\u5DF1\u9078\u64C7\u79FB\u9664\u4E00//\u5F35\u50F9\u503C[3]\u5230[6]\u7684\u5361\u7247\uFF0C\u68C4//\u6389\u5269\u4E0B\u7684\u5361\u7247\uFF0C\u5982\u679C\u88AB\u79FB\u9664\u7684//\u5361\u7247\u6709\u9A0E\u58EB\uFF0C\u5247\u79FB\u9664\u6B64\u5361\u3002//---//\u7576\u4F60\u79FB\u9664\u6B64\u5361\uFF0C\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\u3002";
TChineseCardTexts[CardNames.SPOILS] = "[!3]////\u7576\u4F60\u6253\u51FA\u6B64\u5361\uFF0C\u5C07\u6B64\u5361//\u6B78\u9084\u5230\u6230\u5229\u54C1\u724C\u5806\u3002//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.STOREROOM] = "|\uFF0B1 \u8CFC\u8CB7|////\u68C4\u6389\u4EFB\u610F\u6578\u91CF\u7684\u5361\u7247\uFF0C//\u62BD\u53D6\u7B49\u91CF\u7684\u5361\u7247\u3002//\u68C4\u6389\u4EFB\u610F\u6578\u91CF\u7684\u5361\u7247\uFF0C//\u6BCF\u68C4\u4E00\u5F35\u5C31\u5F97\u5230\uFF0B[1]";
TChineseCardTexts[CardNames.SQUIRE] = "|\uFF0B[1]|////\u9078\u64C7\u4E00\u500B: |\uFF0B2 \u884C\u52D5|; //|\uFF0B2 \u8CFC\u8CB7|;\u7372\u5F97\u4E00\u5F35\u9280\u5E63\u3002//---//\u7576\u4F60\u79FB\u9664\u6B64\u5361\uFF0C//\u7372\u5F97\u4E00\u5F35\u653B\u64CA\u5361\u3002";
TChineseCardTexts[CardNames.SURVIVORS] = "\u89C0\u770B\u4F60\u724C\u5EAB\u9802\u7684\u5169\u5F35\u5361\uFF0C//\u68C4\u6389\u90A3\u5169\u5F35\u6216\u662F\u5C07\u90A3\u5169\u5F35//\u4EE5\u4EFB\u610F\u9806\u5E8F\u653E\u56DE\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.URCHIN] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//\u5176\u4ED6\u73A9\u5BB6\u5C07\u624B\u724C\u68C4\u5230\u5269\u56DB\u5F35\u3002//---//\u7576\u6B64\u5361\u5728\u51FA\u724C\u5340\uFF0C\u4F60\u6253\u51FA\u5176\u5B83//\u653B\u64CA\u5361\u6642\uFF0C\u4F60\u53EF\u4EE5\u79FB\u9664\u6B64\u5361\uFF0C//\u5F9E\u50AD\u5175\u724C\u5806\u7372\u5F97\u4E00\u5F35\u50AD\u5175\u3002";
TChineseCardTexts[CardNames.VAGRANT] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u5C55\u793A\u4F60\u724C\u5EAB\u9802\u7684\u4E00\u5F35\u5361\uFF0C//\u5982\u679C\u90A3\u5F35\u5361\u662F\u8A5B\u5492\u3001\u907F\u96E3//\u6240\u3001\u5206\u6578\u5361\u3001\u5EE2\u589F\uFF0C\u5247\u5C07//\u5B83\u653E\u5230\u4F60\u7684\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.WANDERING_MINSTREL] = "|\uFF0B1 \u5361\u7247|//|\uFF0B2 \u884C\u52D5|////\u5C55\u793A\u4F60\u724C\u5EAB\u9802\u7684\u4E09\u5F35\u5361\u7247\uFF0C//\u5C07\u88E1\u9762\u7684\u884C\u52D5\u5361\u4EE5\u4EFB\u610F\u9806\u5E8F//\u653E\u56DE\uFF0C\u5C07\u5176\u4ED6\u7684\u5361\u7247\u68C4\u6389\u3002";
TChineseCardTexts[CardNames.ADVISOR] = "|\uFF0B1 \u884C\u52D5|////\u5C55\u793A\u4F60\u724C\u5EAB\u9802\u4E09\u5F35\u5361\u7247//\uFF0C\u4F60\u5DE6\u624B\u908A\u7684\u73A9\u5BB6\u9078\u64C7//\u5176\u4E2D\u4E00\u5F35\u68C4\u6389\uFF0C\u5C07\u5176\u4ED6//\u5361\u7247\u653E\u5230\u4F60\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.BAKER] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//|\uFF0B1 \u5B58\u6B3E|//---//\u904A\u6232\u958B\u59CB\u6642\uFF1A\u6240\u6709\u73A9\u5BB6////|\uFF0B1 \u5B58\u6B3E|";
TChineseCardTexts[CardNames.BUTCHER] = "|\uFF0B2 \u5B58\u6B3E|////\u4F60\u53EF\u4EE5\u79FB\u9664\u624B\u4E0A\u7684\u4E00\u5F35\u5361\u7247//\uFF0C\u5982\u679C\u4F60\u6709\u79FB\u9664\uFF0C\u4F60\u53EF\u4EE5\u4ED8//\u51FA\u4EFB\u610F\u6578\u91CF\u7684\u5B58\u6B3E\uFF0C\u7372\u5F97\u4E00//\u5F35\u5361\u7247\uFF0C\u90A3\u5F35\u5361\u7247\u50F9\u503C\u4E0D\u5927//\u65BC\u4F60\u79FB\u9664\u7684\u5361\u7247\u50F9\u503C\u52A0\u4E0A\u4F60//\u4ED8\u51FA\u7684\u5B58\u6B3E\u6578\u91CF\u3002";
TChineseCardTexts[CardNames.CANDLESTICK_MAKER] = "|\uFF0B1 \u884C\u52D5|//|\uFF0B1 \u8CFC\u8CB7|//|\uFF0B1 \u5B58\u6B3E|";
TChineseCardTexts[CardNames.DOCTOR] = "\u6307\u5B9A\u4E00\u5F35\u5361\u7247\uFF0C\u7FFB\u958B\u724C\u5EAB\u9802\u4E09//\u5F35\u5361\u7247\uFF0C\u79FB\u9664\u4F60\u6307\u5B9A\u7684\u5361\u7247\uFF0C\u5C07//\u5269\u4E0B\u7684\u5361\u7247\u4EFB\u610F\u9806\u5E8F\u64FA\u653E\u56DE\u53BB\u3002//---//\u7576\u4F60\u8CFC\u8CB7\u6B64\u5361\uFF0C\u4F60\u53EF\u4EE5\u8D85\u984D\u652F\u4ED8//\uFF0C\u4F60\u6BCF\u8D85\u4ED8[1]\uFF0C\u4F60\u5FC5\u9808\u5C55\u793A\u724C//\u5EAB\u9802\u7684\u4E00\u5F35\u5361\u7247\uFF0C\u9078\u64C7\u79FB\u9664\u6216\u662F//\u68C4\u6389\u6216\u662F\u653E\u56DE\u53BB\u3002";
TChineseCardTexts[CardNames.HERALD] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u5C55\u793A\u724C\u5EAB\u9802\u7684\u4E00\u5F35\u5361\u7247\uFF0C//\u5982\u679C\u5B83\u662F\u884C\u52D5\u5247\u6253\u51FA\u5B83\u3002//---//\u7576\u4F60\u8CFC\u8CB7\u6B64\u5361\u6642\uFF0C\u4F60\u53EF\u4EE5\u8D85//\u984D\u652F\u4ED8\uFF0C\u4F60\u6BCF\u8D85\u984D\u652F\u4ED8[1]//\uFF0C\u4F60\u5C31\u5FC5\u9808\u5C07\u4F60\u68C4\u724C\u5806\u7684\u4E00//\u5F35\u5361\u7247\u653E\u5230\u4F60\u7684\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.JOURNEYMAN] = "\u6307\u5B9A\u4E00\u5F35\u5361\u7247\uFF0C\u6301\u7E8C\u5C55\u793A//\u724C\u5EAB\u9802\u7684\u5361\u7247\uFF0C\u76F4\u5230\u4F60\u5C55//\u793A\u4E09\u5F35\u4E0D\u662F\u4F60\u6307\u5B9A\u7684\u5361\u7247//\uFF0C\u5C07\u90A3\u4E09\u5F35\u5361\u7247\u653E\u5230\u624B\u4E2D//\uFF0C\u5C07\u5176\u4ED6\u5361\u7247\u68C4\u6389\u3002";
TChineseCardTexts[CardNames.MASTERPIECE] = "[!1]//---//\u7576\u4F60\u8CFC\u8CB7\u6B64\u5361\uFF0C\u4F60\u53EF\u4EE5//\u8D85\u984D\u652F\u4ED8\uFF0C\u4F60\u6BCF\u8D85\u4ED8[!1]//\uFF0C\u7372\u5F97\u4E00\u5F35\u9280\u5E63\u3002";
TChineseCardTexts[CardNames.MERCHANT_GUILD] = "|\uFF0B1 \u8CFC\u8CB7|//|\uFF0B[1]|//\u5728\u4F60\u8CFC\u8CB7\u968E\u6BB5\u7D50\u675F\u6642\uFF0C\u6B64\u968E//\u6BB5\u4F60\u6BCF\u7372\u5F97\u4E00\u5F35\u5361\u7247\uFF0C\u4F60\u5C31//|\uFF0B1 \u5B58\u6B3E|";
TChineseCardTexts[CardNames.PLAZA] = "|\uFF0B1 \u5361\u7247|//|\uFF0B2 \u884C\u52D5|////\u4F60\u53EF\u4EE5\u68C4\u6389\u4E00\u5F35\u9322\u5E63\u5361\uFF0C\u4F86////|\uFF0B1 \u5B58\u6B3E|";
TChineseCardTexts[CardNames.TAXMAN] = "\u4F60\u53EF\u4EE5\u79FB\u9664\u624B\u4E0A\u4E00\u5F35\u9322\u5E63\u5361//\uFF0C\u5176\u4ED6\u624B\u724C\u4E94\u5F35\u4EE5\u4E0A\u7684\u73A9\u5BB6//\u68C4\u6389\u4E00\u5F35\u76F8\u540C\u540D\u5B57\u7684\u9322\u5E63\u5361//\uFF08\u6216\u662F\u5C55\u793A\u624B\u4E0A\u6C92\u6709\uFF09\uFF0C\u4F60//\u7372\u5F97\u4E00\u5F35\u6BD4\u4F60\u68C4\u6389\u7684\u5361\u50F9\u503C//\u81F3\u591A\u591A[3]\u7684\u91D1\u9322\u5361\uFF0C\u4E26\u5C07//\u5B83\u653E\u5230\u4F60\u7684\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.SOOTHSAYER] = "\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\uFF0C//\u5176\u4ED6\u73A9\u5BB6\u7372\u5F97\u4E00\u500B\u8A5B\u5492\uFF0C\u6709\u7372//\u5F97\u8A5B\u5492\u7684\u73A9\u5BB6\u62BD\u4E00\u5F35\u5361\u7247\u3002";
TChineseCardTexts[CardNames.STONEMASON] = "\u79FB\u9664\u624B\u4E0A\u4E00\u5F35\u5361\u7247\uFF0C\u7372\u5F97//\u5169\u5F35\u8F03\u4F4E\u50F9\u503C\u7684\u5361\u7247\u3002//---//\u7576\u4F60\u8CFC\u8CB7\u9019\u5F35\u5361\u7247\u6642\uFF0C\u4F60\u53EF\u4EE5//\u8D85\u984D\u652F\u4ED8\uFF0C\u5982\u679C\u4F60\u4ED8\u4E86\uFF0C\u53EF\u4EE5//\u7372\u5F97\u5169\u5F35\u50F9\u503C\u7B49\u65BC\u4F60\u8D85\u984D\u652F\u4ED8//\u91D1\u984D\u7684\u884C\u52D5\u5361\u3002";
TChineseCardTexts[CardNames.ALMS] = "\u6BCF\u56DE\u5408\u4E00\u6B21\uFF1A\u5982\u679C\u4F60\u6C92\u6709\u9322\u5E63\u5361\u5728\u51FA\u724C\u5340\uFF0C//\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A[4]\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.AMULET] = "\u73FE\u5728\u548C\u4E0B\u4E00\u6B21\u4F60\u7684\u56DE\u5408\u958B//\u59CB\u7684\u6642\u5019\uFF0C\u9078\u64C7\u4E00\u500B\uFF1A|\uFF0B|[1];//\u79FB\u9664\u4F60\u624B\u4E0A\u4E00\u5F35\u5361\u7247;//\u7372\u5F97\u4E00\u5F35\u9280\u5E63\u3002";
TChineseCardTexts[CardNames.ARTIFICER] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//|\uFF0B[1]|////\u68C4\u6389\u4EFB\u610F\u6578\u91CF\u7684\u624B\u724C\u3002//\u4F60\u53EF\u4EE5\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u525B\u597D//\u7B49\u65BC\u4F60\u68C4\u6389\u5F35\u6578\u7684\u5361\u7247\uFF0C//\u4E26\u628A\u5B83\u653E\u5230\u4F60\u7684\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.BALL] = "\u62FF\u53D6\u4F60\u7684-[1]\u6307\u793A\u7269\u3002\u7372\u5F97\u5169\u5F35\u50F9\u503C\u81F3\u591A[4]\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.BONFIRE] = "\u5F9E\u4F60\u51FA\u724C\u5340\u4E2D\u79FB\u9664\u81F3\u591A\u5169\u5F35\u9285\u5E63\u3002";
TChineseCardTexts[CardNames.BORROW] = "\u6BCF\u56DE\u5408\u4E00\u6B21\uFF1A|\uFF0B1 \u8CFC\u8CB7|\uFF0C\u5982\u679C\u4F60\u7684-1\u5361\u7247\u6307\u793A\u7269\u4E0D\u5728\u4F60//\u724C\u5EAB\u9802\u4E0A\uFF0C\u5C07\u5B83\u653E\u4E0A\u53BB\u4E26|\uFF0B|[1]\u3002";
TChineseCardTexts[CardNames.BRIDGE_TROLL] = "\u5176\u4ED6\u73A9\u5BB6\u62FF\u53D6\u4ED6\u5011\u7684//-[1]\u6307\u793A\u7269\uFF0C\u73FE\u5728\u548C\u4E0B\u6B21\u4F60\u56DE//\u5408\u958B\u59CB\u7684\u6642\u5019\uFF1A|\uFF0B1 \u8CFC\u8CB7|\u3002//---//\u7576\u6B64\u5361\u5728\u51FA\u724C\u5340\uFF0C\u4F60\u7684\u56DE\u5408\u6240//\u6709\u5361\u7247\u50F9\u503C\u6E1B\u5C11[1]\uFF0C\u4F46\u662F\u4E0D//\u6703\u4F4E\u65BC[0]\u3002";
TChineseCardTexts[CardNames.CARAVAN_GUARD] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u4F60\u4E0B\u6B21\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019 |\uFF0B|[1]\u3002//---//\u7576\u5176\u4ED6\u73A9\u5BB6\u6253\u51FA\u653B\u64CA\u5361\uFF0C//\u4F60\u53EF\u4EE5\u5148\u6253\u51FA\u4F60\u624B\u4E2D\u7684\u6B64\u5361\u3002//(|\uFF0B1 \u884C\u52D5|%\u5728\u4E0D\u662F\u4F60\u7684\u56DE\u5408\u4E2D%//%\u6C92\u6709\u6548\u679C\u3002)%";
TChineseCardTexts[CardNames.CHAMPION] = "|\uFF0B1 \u884C\u52D5|//\u672C\u5834\u904A\u6232\u4E4B\u5F8C\uFF0C\u5176\u4ED6\u73A9\u5BB6//\u6253\u51FA\u7684\u653B\u64CA\u5361\u4E0D\u6703\u5F71\u97FF\u4F60//\u3002\u7576\u4F60\u6253\u51FA\u884C\u52D5\u5361\u4E4B\u5F8C\uFF0C//|\uFF0B1 \u884C\u52D5|\u3002//%(\u51FA\u724C\u5F8C\u6703\u4E00\u76F4\u5728\u51FA\u724C\u5340\uFF0C%//%\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1)%";
TChineseCardTexts[CardNames.COIN_OF_THE_REALM] = "[!1]////\u5C07\u6B64\u5361\u653E\u5230\u4F60\u7684\u65C5\u884C\u677F\u584A\u4E0A\u3002//---//\u4F60\u767C\u52D5\u5B8C\u4E00\u5F35\u884C\u52D5\u4E4B\u5F8C\uFF0C//\u4F60\u53EF\u4EE5\u7ACB\u523B\u547C\u53EB\u6B64\u5361\u4F86//|\uFF0B2 \u884C\u52D5|.";
TChineseCardTexts[CardNames.DISCIPLE] = "\u4F60\u53EF\u4EE5\u5F9E\u624B\u4E0A\u6253\u51FA\u4E00\u5F35\u884C//\u52D5\u5361\uFF0C\u767C\u52D5\u5169\u6B21\u6548\u679C\uFF0C//\u4E26\u7372\u5F97\u4E00\u5F35\u540C\u540D\u7684\u5361\u3002//---//\u7576\u4F60\u5F9E\u51FA\u724C\u5340\u5C07\u6B64\u5361\u68C4\u6389\uFF0C//\u4F60\u53EF\u4EE5\u7528\u6B64\u5361\u4EA4\u63DB\u4E00\u5F35\u5E2B\u5085\u3002//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.DISTANT_LANDS] = "\u5C07\u6B64\u5361\u653E\u5230\u65C5\u884C\u677F\u584A\u4E0A\u3002//---//\u7576\u904A\u6232\u7D50\u675F\uFF0C\u6B64\u5361\u5728//\u65C5\u884C\u677F\u584A\u4E0A\u7684\u8A71\uFF1A{4}//(\u9664\u6B64\u4E4B\u5916{0}).";
TChineseCardTexts[CardNames.DUNGEON] = "|\uFF0B1 \u884C\u52D5|////\u73FE\u5728\u8DDF\u4F60\u4E0B\u56DE\u5408\u958B\u59CB\uFF1A//|\uFF0B2 \u5361\u7247|//\u7136\u5F8C\u68C4\u6389\u5169\u5F35\u5361\u7247\u3002";
TChineseCardTexts[CardNames.DUPLICATE] = "\u5C07\u6B64\u5361\u653E\u5728\u65C5\u884C\u677F\u584A\u4E0A\u3002//---//\u7576\u4F60\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A[6]//\u7684\u5361\u7247\u6642\uFF0C\u4F60\u53EF\u4EE5\u547C\u53EB\u6B64\u5361//\u4F86\u7372\u5F97\u4E00\u5F35\u76F8\u540C\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.EXPEDITION] = "\u5728\u4F60\u4E0B\u4E00\u6B21\u6E05\u9664\u968E\u6BB5\u91CD\u65B0\u62BD\u5361\u6642\uFF0C\u591A\u62BD\u5169\u5F35\u3002";
TChineseCardTexts[CardNames.FERRY] = "\u5C07\u4F60\u7684-[2]\u6307\u793A\u7269\u79FB\u52D5\u5230\u4F9B\u61C9\u5340\u88E1\u4E00\u758A\u884C\u52D5\u5361\u724C\u5806\u4E0A//(\u5728\u4F60\u7684\u56DE\u5408\u5167\uFF0C\u90A3\u758A\u5361\u7247\u50F9\u503C\u90FD\u6E1B\u5C11[2]\u3002//\u4F46\u4E0D\u6703\u4F4E\u65BC[0]\u3002)";
TChineseCardTexts[CardNames.FUGITIVE] = "|\uFF0B2 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u68C4\u6389\u4E00\u5F35\u5361\u3002//---//\u7576\u4F60\u5F9E\u51FA\u724C\u5340\u5C07\u6B64\u5361\u68C4\u6389\uFF0C//\u4F60\u53EF\u4EE5\u7528\u6B64\u5361\u4EA4\u63DB\u4E00\u5F35\u9580\u5F92\u3002//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.GEAR] = "|\uFF0B2 \u5361\u7247|////\u5C07\u81F3\u591A\u5169\u5F35\u5361\u7247\u79FB\u5230\u4E00\u908A\uFF0C//\u4E14\u9762\u671D\u4E0B\u7684\u64FA\u5230\u6B64\u5361\u4E0B\u65B9\u3002//\u5728\u4E0B\u4E00\u6B21\u4F60\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019//\uFF0C\u5C07\u5B83\u5011\u52A0\u5165\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.GIANT] = "\u5C07\u4F60\u7684\u65C5\u884C\u6307\u793A\u7269\u7FFB\u9762\uFF08\u5B83//\u4E00\u958B\u59CB\u662F\u6B63\u9762\uFF09\uFF0C\u5982\u679C\u4ED6\u73FE\u5728\u662F//\u80CC\u9762|\uFF0B|[1]\u3002\u5982\u679C\u73FE\u5728\u662F\u6B63\u9762|\uFF0B|[5]//\uFF0C\u4E26\u4E14\u5C55\u793A\u5176\u4ED6\u73A9\u5BB6\u724C\u5EAB\u9802\u7684//\u4E00\u5F35\u5361\u7247\uFF0C\u5982\u679C\u90A3\u5F35\u5361\u7247\u50F9\u503C//\u662F[3]\u5230[6]\uFF0C\u5247\u79FB\u9664\u90A3\u5F35\u5361\uFF0C//\u5982\u679C\u4E0D\u662F\u5247\u5B83\u7372\u5F97\u4E00\u5F35\u8A5B\u5492\u3002";
TChineseCardTexts[CardNames.GUIDE] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u5C07\u6B64\u5361\u653E\u5230\u65C5\u884C\u677F\u584A\u4E0A//---//\u5728\u4F60\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\uFF0C//\u4F60\u53EF\u4EE5\u547C\u53EB\u6B64\u5361\uFF0C\u68C4\u6389//\u624B\u724C\uFF0C\u91CD\u65B0\u62BD\u51FA\u4E94\u5F35\u5361\u3002";
TChineseCardTexts[CardNames.HAUNTED_WOODS] = "\u76F4\u5230\u4F60\u4E0B\u6B21\u56DE\u5408\u958B\u59CB\u4E4B\u524D\uFF0C//\u5176\u4ED6\u73A9\u5BB6\u7372\u5F97\u8CFC\u8CB7\u7684\u5361\u7247\u6642\uFF0C//\u4ED6\u5011\u5C07\u624B\u724C\u4EE5\u4EFB\u610F\u9806\u5E8F\u653E\u56DE//\u724C\u5EAB\u9802\u3002\u4F60\u4E0B\u6B21\u56DE\u5408\u958B\u59CB\u6642////|\uFF0B3 \u5361\u7247|";
TChineseCardTexts[CardNames.HERO] = "|\uFF0B[2]|//---//\u7372\u5F97\u4E00\u5F35\u9322\u5E63\u5361\u3002////\u7576\u4F60\u5F9E\u51FA\u724C\u5340\u5C07\u6B64\u5361\u68C4\u6389\uFF0C//\u4F60\u53EF\u4EE5\u7528\u6B64\u5361\u4EA4\u63DB\u4E00\u5F35\u52DD\u5229\u8005\u3002//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.HIRELING] = "\u5728\u5269\u4E0B\u7684\u904A\u6232\u4E2D\uFF0C//\u6BCF\u6B21\u4F60\u56DE\u5408\u958B\u59CB\u6642\uFF1A////|\uFF0B1 \u5361\u7247|";
TChineseCardTexts[CardNames.INHERITANCE] = "\u6BCF\u5834\u904A\u6232\u4E00\u6B21\uFF1A\u5C07\u4F9B\u61C9\u5340\u4E00\u5F35\u50F9\u503C\u81F3\u591A[4]\u7684\u975E\u547D\u4EE4//\u884C\u52D5\u5361\u653E\u5230\u4E00\u908A\uFF0C\u5728\u4F60\u56DE\u5408\u4E2D\uFF0C\u838A\u5712\u4E5F\u662F\u547D\u4EE4\u884C\u52D5//\u5361\uFF0C\u6253\u51FA\u838A\u5712\u6642\u767C\u52D5\u8A72\u5361\u6548\u679C\uFF0C\u4F46\u4E0D\u80FD\u79FB\u52D5\u8A72\u5361\u3002";
TChineseCardTexts[CardNames.LOST_ARTS] = "\u5C07\u4F60\u7684\uFF0B\u884C\u52D5\u6307\u793A\u7269\u79FB\u5230\u4F9B\u61C9\u5340\u4E00\u758A\u884C\u52D5\u5361\u4E0A\u3002//(\u7576\u4F60\u6253\u51FA\u7684\u5361\u7247\u662F\u5F9E\u90A3\u758A\u4F86\u7684\u3002\u4F60\u5148|\uFF0B1 \u884C\u52D5|.)";
TChineseCardTexts[CardNames.LOST_CITY] = "|\uFF0B2 \u5361\u7247|//|\uFF0B2 \u884C\u52D5|//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\uFF0C\u5176\u4ED6//\u73A9\u5BB6\u62BD\u4E00\u5F35\u5361\u7247\u3002";
TChineseCardTexts[CardNames.MAGPIE] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u5C55\u793A\u4F60\u724C\u5EAB\u9802\u7684\u4E00\u5F35\u5361\uFF0C//\u5982\u679C\u662F\u9322\u5E63\u5361\uFF0C\u5247\u653E\u5165\u4F60//\u624B\u4E2D\uFF0C\u5982\u679C\u662F\u884C\u52D5\u5361\u6216\u5206//\u6578\u5361\uFF0C\u7372\u5F97\u4E00\u5F35\u559C\u9D72\u3002";
TChineseCardTexts[CardNames.MESSENGER] = "|\uFF0B1 \u8CFC\u8CB7|//|\uFF0B[2]|//\u4F60\u53EF\u4EE5\u5C07\u4F60\u7684\u724C\u5EAB//\u653E\u5230\u68C4\u724C\u5806\u88E1\u3002//---////\u5982\u679C\u9019\u662F\u4F60\u672C\u56DE\u5408\u7B2C\u4E00\u6B21\u8CFC\u8CB7\uFF0C//\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A[4]\u7684\u5361\u7247\uFF0C//\u5176\u4ED6\u4EBA\u4E5F\u7372\u5F97\u4E00\u5F35\u540C\u540D\u7684\u5361\u3002";
TChineseCardTexts[CardNames.MISER] = "\u9078\u64C7\u4E00\u500B\uFF1A\u5C07\u4E00\u5F35\u4F60\u624B\u4E0A//\u7684\u9285\u5E63\u653E\u5230\u65C5\u884C\u677F\u584A\u4E0A;//\u6216\u6BCF\u4E00\u5F35\u5728\u65C5\u884C\u677F\u584A\u4E0A\u7684//\u9285\u5E63\u8B93\u4F60|\uFF0B|[1]";
TChineseCardTexts[CardNames.MISSION] = "\u4F60\u7372\u5F97\u4E00\u500B\u984D\u5916\u7684\u56DE\u5408(\u4F46\u4F60\u4E0D\u80FD\u9023\u7E8C\u52D5\u4E09\u500B\u56DE\u5408)\uFF0C//\u5728\u8A72\u56DE\u5408\u5167\u4F60\u4E0D\u80FD\u8CFC\u8CB7\u5361\u7247\u3002//(\u9084\u662F\u53EF\u4EE5\u8CFC\u8CB7\u4E8B\u4EF6\u6216\u662F\u8A08\u756B)";
TChineseCardTexts[CardNames.PATHFINDING] = "\u5C07\u4F60\u7684\uFF0B1\u5361\u7247\u6307\u793A\u7269\u79FB\u5230\u4F9B\u61C9\u5340\u4E00\u758A\u884C\u52D5\u5361\u5806\u4E0A\u3002//(\u7576\u4F60\u6253\u51FA\u7684\u5361\u7247\u662F\u5F9E\u90A3\u758A\u4F86\u7684\uFF0C\u4F60\u5148\u7372\u5F97|\uFF0B1 \u5361\u7247|\u3002)";
TChineseCardTexts[CardNames.PAGE] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//---//\u7576\u4F60\u5F9E\u51FA\u724C\u5340\u5C07\u6B64\u5361//\u68C4\u6389\uFF0C\u4F60\u53EF\u4EE5\u7528\u6B64\u5361//\u4EA4\u63DB\u4E00\u5F35\u5BF6\u85CF\u7375\u4EBA\u3002";
TChineseCardTexts[CardNames.PEASANT] = "|\uFF0B1 \u8CFC\u8CB7|//|\uFF0B[1]|//---////\u7576\u4F60\u5F9E\u51FA\u724C\u5340\u5C07\u6B64\u5361//\u68C4\u6389\uFF0C\u4F60\u53EF\u4EE5\u7528\u6B64\u5361//\u4EA4\u63DB\u4E00\u5F35\u8ECD\u4EBA\u3002";
TChineseCardTexts[CardNames.PILGRIMAGE] = "\u6BCF\u56DE\u5408\u6700\u591A\u4E00\u6B21\uFF1A\u5C07\u4F60\u7684\u65C5\u884C\u6307\u793A\u7269\u7FFB\u9762(\u5B83\u8D77\u59CB\u6B63\u9762)//\u5982\u679C\u4ED6\u662F\u6B63\u9762\uFF0C\u9078\u64C7\u4F60\u51FA\u724C\u5340\u4E09\u5F35\u4E0D\u540C\u540D\u5B57\u7684\u5361\u7247\uFF0C//\u4E26\u5404\u7372\u5F97\u4E00\u5F35\u76F8\u540C\u540D\u5B57\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.PLAN] = "\u5C07\u4F60\u7684\u79FB\u9664\u6307\u793A\u7269\u79FB\u5230\u4F9B\u61C9\u5340\u4E00\u758A\u884C\u52D5\u5361\u5806\u4E0A\u3002//(\u6BCF\u7576\u4F60\u7372\u5F97\u90A3\u758A\u7684\u5361\u7247\uFF0C\u4F60\u53EF\u4EE5\u79FB\u9664\u4E00\u5F35\u624B\u724C\u3002)";
TChineseCardTexts[CardNames.PORT] = "|\uFF0B1 \u5361\u7247|//|\uFF0B2 \u884C\u52D5|//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\u6642\uFF0C//\u7372\u5F97\u53E6\u4E00\u5F35\u6D77\u6E2F\u3002//(\u4E0D\u6703\u9023\u7E8C\u7372\u5F97\u3002)";
TChineseCardTexts[CardNames.QUEST] = "\u4F60\u53EF\u4EE5\u68C4\u6389\u4E00\u5F35\u653B\u64CA\u5361\uFF0C\u6216\u5169\u5F35\u8A5B\u5492\uFF0C\u6216\u516D\u5F35\u5361\u7247//\uFF0C\u5982\u679C\u4F60\u68C4\u4E86\uFF0C\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\u3002";
TChineseCardTexts[CardNames.RANGER] = "|\uFF0B1 \u8CFC\u8CB7|////\u5C07\u4F60\u7684\u5192\u96AA\u6307\u793A\u7269\u7FFB\u9762\uFF0C//(\u904A\u6232\u958B\u59CB\u6642\u70BA\u6B63\u9762)//\u7FFB\u9762\u5F8C\u662F\u6B63\u9762\u7684\u8A71\uFF1A//|\uFF0B5 \u5361\u7247|";
TChineseCardTexts[CardNames.RAID] = "\u4F60\u51FA\u724C\u5340\u6709\u5E7E\u5F35\u9280\u5E63\u4F60\u5C31\u7372\u5F97\u5E7E\u5F35\u9280\u5E63\u3002//\u5176\u4ED6\u73A9\u5BB6\u5C07\u5404\u81EA\u7684-1 \u5361\u7247\u6307\u793A\u7269\u653E\u5230\u4ED6\u5011\u724C\u5806\u4E0A\u3002";
TChineseCardTexts[CardNames.RATCATCHER] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u5C07\u6B64\u5361\u653E\u5230\u65C5\u884C\u677F\u584A\u4E0A//---//\u5728\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\uFF0C//\u4F60\u53EF\u4EE5\u547C\u53EB\u6B64\u5361\uFF0C//\u4F86\u79FB\u9664\u624B\u4E0A\u7684\u4E00\u5F35\u5361\u7247\u3002";
TChineseCardTexts[CardNames.RAZE] = "|\uFF0B1 \u884C\u52D5|////\u79FB\u9664\u6B64\u5361\u6216\u4F60\u624B\u4E0A\u7684\u4E00\u5F35\u5361\uFF0C//\u88AB\u79FB\u9664\u7684\u5361\u7247\u6BCF\u50F9\u503C[1]\uFF0C//\u4F60\u591A\u5C55\u793A\u724C\u5EAB\u9802\u7684\u4E00\u5F35\u5361\u7247\uFF0C//\u5C07\u5176\u4E2D\u4E00\u5F35\u653E\u5165\u4F60\u7684\u624B\u4E2D\uFF0C//\u5C07\u5269\u4E0B\u7684\u5361\u7247\u68C4\u6389\u3002";
TChineseCardTexts[CardNames.RELIC] = "[!2]////\u5176\u4ED6\u73A9\u5BB6\u5C07\u5404\u81EA\u7684-1\u5361//\u7247\u6307\u793A\u7269\u653E\u5230\u4ED6\u5011\u724C\u5806\u4E0A\u3002";
TChineseCardTexts[CardNames.ROYAL_CARRIAGE] = "|\uFF0B1 \u884C\u52D5|////\u5C07\u6B64\u5361\u653E\u5230\u65C5\u884C\u677F\u584A\u4E0A\u3002//---//\u5728\u4F60\u6253\u5B8C\u4E00\u5F35\u884C\u52D5\u5361\u4E4B\u5F8C\uFF0C//\u5982\u679C\u90A3\u5F35\u884C\u52D5\u5361\u9084\u5728\u51FA\u724C\u5340//\uFF0C\u4F60\u53EF\u4EE5\u547C\u53EB\u6B64\u5361\uFF0C//\u518D\u6B21\u57F7\u884C\u90A3\u5F35\u884C\u52D5\u5361\u3002";
TChineseCardTexts[CardNames.SAVE] = "\u6BCF\u56DE\u5408\u9650\u4E00\u6B21: |\uFF0B1 \u8CFC\u8CB7|\uFF0C\u5C07\u4F60\u624B\u4E0A\u7684\u4E00\u5F35\u724C\u653E\u5230//\u4E00\u908A\uFF0C\u5728\u4F60\u56DE\u5408\u7D50\u675F\u6642\u653E\u5230\u624B\u4E2D(\u62BD\u4E94\u5F35\u5361\u4E4B\u5F8C).";
TChineseCardTexts[CardNames.SCOUTING_PARTY] = "|\uFF0B1 \u8CFC\u8CB7|////\u89C0\u770B\u4F60\u724C\u5EAB\u9802\u7684\u4E94\u5F35\u5361\u7247\uFF0C//\u68C4\u6389\u4E09\u5F35\uFF0C\u5269\u4E0B\u7684\u4EE5\u4EFB\u610F\u9806\u5E8F\u653E\u5230\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.SEAWAY] = "\u7372\u5F97\u4F9B\u61C9\u5340\u88E1\u4E00\u5F35\u81F3\u50F9\u503C\u81F3\u591A[4]\u7684\u884C\u52D5\u5361\u3002//\u5C07\u4F60\u7684\uFF0B1\u8CFC\u8CB7\u6307\u793A\u7269\u653E\u5230\u90A3\u758A\u738B\u570B\u5361\u4E0A\uFF0C(\u7576\u4F60\u6253\u51FA\u4E00\u5F35//\u90A3\u758A\u4F86\u7684\u884C\u52D5\u5361\uFF0C\u4F60\u5148\u7372\u5F97|\uFF0B1 \u8CFC\u8CB7|.)";
TChineseCardTexts[CardNames.SOLDIER] = "|\uFF0B[2]|//\u51FA\u724C\u5340\u6BCF\u6709\u4E00\u5F35\u5176\u5B83\u653B\u64CA\u5361|\uFF0B|[1]//\u624B\u724C\u6709\u56DB\u5F35\u4EE5\u4E0A\u7684\u73A9\u5BB6\uFF0C//\u5F9E\u624B\u4E0A\u68C4\u6389\u4E00\u5F35\u5361\u3002//---////\u7576\u4F60\u5F9E\u51FA\u724C\u5340\u5C07\u6B64\u5361\u68C4\u6389\uFF0C//\u4F60\u53EF\u4EE5\u7528\u6B64\u5361\u4EA4\u63DB\u4E00\u5F35\u9003\u5175\u3002//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.STORYTELLER] = "|\uFF0B1 \u884C\u52D5|////\u5F9E\u624B\u724C\u6253\u51FA\u81F3\u591A\u4E09\u5F35//\u9322\u5E63\u5361\uFF0C\u4F60|\uFF0B1 \u5361\u7247|//\uFF0C\u4ED8\u51FA\u4F60\u73FE\u5728\u5168\u90E8\u7684[]\u3002//\u4F60\u6BCF\u4ED8[1]\u4F60\u5C31\u62BD\u4E00\u5F35\u5361\u3002";
TChineseCardTexts[CardNames.SWAMP_HAG] = "\u5230\u4E0B\u6B21\u8F2A\u5230\u4F60\u4E4B\u524D\uFF0C\u5176\u4ED6//\u73A9\u5BB6\u7372\u5F97\u8CFC\u8CB7\u7684\u5361\u7247\u6642\uFF0C//\u4ED6\u7372\u5F97\u4E00\u5F35\u8A5B\u5492\u3002//\u5728\u4F60\u4E0B\u6B21\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\uFF1A////|\uFF0B[3]|";
TChineseCardTexts[CardNames.TEACHER] = "---//\u5C07\u6B64\u5361\u653E\u5728\u65C5\u884C\u677F\u584A\u4E0A\u3002////\u5728\u4F60\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\uFF0C\u4F60\u53EF\u4EE5\u547C//\u53EB\u6B64\u5361\uFF0C\u9078\u64C7\u4E00\u758A\u6C92\u6709\u6307\u793A\u7269\u7684\u884C//\u52D5\u724C\u5806\uFF0C\u79FB\u52D5\u4F60\u7684\uFF0B1\u5361\u7247\u3001\u6216\uFF0B1//\u884C\u52D5\u3001\u6216\uFF0B1\u8CFC\u8CB7\uFF0C\u6216\uFF0B[1]\u6307\u793A\u7269//\u5230\u5B83\u4E0A\u9762\uFF0C\u7576\u4F60\u6253\u51FA\u4E00\u5F35\u90A3\u758A\u4F86\u7684//\u884C\u52D5\u5361\uFF0C\u4F60\u5148\u7372\u5F97\u76F8\u5C0D\u61C9\u7684\u734E\u52F5\u3002//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.TRAVELLING_FAIR] = "|\uFF0B2 \u8CFC\u8CB7|////\u4F60\u53EF\u4EE5\u5C07\u9019\u56DE\u5408\u4F60\u7372\u5F97\u7684//\u5361\u7247\uFF0C\u653E\u5230\u4F60\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.TRADE] = "\u79FB\u9664\u4F60\u624B\u4E0A\u81F3\u591A\u5169\u5F35\u7684\u5361\u7247\u3002//\u4F60\u6BCF\u79FB\u9664\u4E00\u5F35\u5361\u7247\u5C31\u7372\u5F97\u4E00\u5F35\u9280\u5E63\u3002";
TChineseCardTexts[CardNames.TRAINING] = "\u79FB\u52D5\u4F60\u7684\uFF0B[1]\u6307\u793A\u7269\u5230\u4E00\u500B\u884C\u52D5\u5361\u724C\u5806//(\u7576\u4F60\u6253\u51FA\u90A3\u500B\u724C\u5806\u7684\u5361\u7247\u6642, \u4F60\u5148\u7372\u5F97\uFF0B[1]\u3002)";
TChineseCardTexts[CardNames.TRANSMOGRIFY] = "|\uFF0B1 \u884C\u52D5|////\u5C07\u6B64\u5361\u653E\u5230\u4F60\u7684\u65C5\u884C\u677F\u584A\u3002//---//\u5728\u4F60\u7684\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\uFF0C\u4F60//\u53EF\u4EE5\u547C\u53EB\u6B64\u5361\uFF0C\u4F86\u79FB\u9664\u624B\u4E0A//\u7684\u4E00\u5F35\u5361\uFF0C\u4E26\u7372\u5F97\u4E00\u5F35\u50F9\u503C//\u81F3\u591A\u591A[1]\u7684\u5361\u7247\u5230\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.TREASURE_TROVE] = "[!2]////\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\u8DDF\u4E00\u5F35\u9285\u5E63\u3002";
TChineseCardTexts[CardNames.TREASURE_HUNTER] = "|\uFF0B1 \u884C\u52D5|//|\uFF0B[1]|//---//\u8A08\u7B97\u4F60\u53F3\u624B\u908A\u73A9\u5BB6\u4E0A\u56DE\u5408\u7372\u5F97\u7684//\u5361\u7247\u6578\u91CF\uFF0C\u4F60\u7372\u5F97\u7B49\u91CF\u7684\u9280\u5E63\u3002////\u7576\u4F60\u5F9E\u51FA\u724C\u5340\u5C07\u6B64\u5361\u68C4\u6389\uFF0C//\u4F60\u53EF\u4EE5\u7528\u6B64\u5361\u4EA4\u63DB\u4E00\u5F35\u6230\u58EB\u3002//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.WARRIOR] = "|\uFF0B2 \u5361\u7247|////\u51FA\u724C\u5340\u7684\u6BCF\u6709\u4E00\u5F35\u65C5\u8005(\u5305\u542B\u6B64//\u5361)\uFF0C\u5176\u4ED6\u73A9\u5BB6\u5C31\u5C55\u793A\u5404\u81EA\u724C\u5EAB\u9802//\u7684\u4E00\u5F35\u5361\uFF0C\u5982\u679C\u50F9\u503C\u662F[3]\u6216[4]//\uFF0C\u5247\u5C07\u90A3\u5F35\u5361\u79FB\u9664\u3002//---//\u7576\u4F60\u5F9E\u51FA\u724C\u5340\u5C07\u6B64\u5361\u68C4\u6389\uFF0C//\u4F60\u53EF\u4EE5\u7528\u6B64\u5361\u4EA4\u63DB\u4E00\u5F35\u82F1\u96C4\u3002//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.WINE_MERCHANT] = "|\uFF0B1 \u8CFC\u8CB7|//|\uFF0B[4]|//---//\u5C07\u6B64\u5361\u653E\u5230\u65C5\u884C\u677F\u584A\u4E0A////\u5728\u4F60\u8CFC\u8CB7\u968E\u6BB5\u7D50\u675F\u6642\uFF0C\u5982\u679C\u4F60//\u6709\u81F3\u5C11[2]\u6C92\u4F7F\u7528\uFF0C\u4F60\u53EF\u4EE5//\u5C07\u65C5\u884C\u677F\u584A\u4E0A\u7684\u6B64\u5361\u68C4\u6389\u3002";
TChineseCardTexts[CardNames.ENCAMPMENT] = "|\uFF0B2 \u5361\u7247|//|\uFF0B2 \u884C\u52D5|////\u4F60\u53EF\u4EE5\u5C55\u793A\u4F60\u624B\u4E0A\u7684\u9EC3\u91D1\u6216//\u63A0\u596A\u54C1\uFF0C\u5982\u679C\u4F60\u6C92\u6709\u5C55\u793A\uFF0C//\u5C07\u6B64\u5361\u653E\u5230\u4E00\u908A\uFF0C\u5728\u6E05\u9664\u968E//\u958B\u59CB\u6642\u5C07\u6B64\u5361\u653E\u56DE\u4F9B\u61C9\u5340\u3002";
TChineseCardTexts[CardNames.PLUNDER] = "[!2]//\uFF0B{1}";
TChineseCardTexts[CardNames.PATRICIAN] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//////\u5C55\u793A\u4F60\u724C\u5EAB\u9802\u7684\u4E00\u5F35\u5361\uFF0C//\u5982\u679C\u5B83\u50F9\u503C[5]\u4EE5\u4E0A\uFF0C//\u5247\u5C07\u90A3\u5F35\u5361\u52A0\u5165\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.EMPORIUM] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//|\uFF0B[1]|////---//\u5728\u4F60\u7372\u5F97\u6B64\u5361\u6642\uFF0C\u5982\u679C\u4F60\u7684//\u51FA\u724C\u5340\u6709\u4E94\u5F35\u4EE5\u4E0A\u7684\u884C\u52D5\u5361//\uFF0C\u4F60\u5F97\u5230\uFF0B{2}";
TChineseCardTexts[CardNames.SETTLERS] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//////\u89C0\u770B\u4F60\u7684\u68C4\u724C\u5806\uFF0C//\u4F60\u53EF\u4EE5\u5C55\u793A\u68C4\u724C\u5806\u88E1\u7684//\u4E00\u5F35\u9285\u5E63\uFF0C//\u4E26\u5C07\u5B83\u653E\u5165\u4F60\u7684\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.BUSTLING_VILLAGE] = "|\uFF0B1 \u5361\u7247|//|\uFF0B3 \u884C\u52D5|//////\u89C0\u770B\u4F60\u7684\u68C4\u724C\u5806\uFF0C//\u4F60\u53EF\u4EE5\u5C55\u793A\u68C4\u724C\u5806\u88E1\u7684//\u4E00\u5F35\u62D3\u8352\u8005\uFF0C//\u4E26\u5C07\u5B83\u653E\u5165\u4F60\u7684\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.CATAPULT] = "|\uFF0B|[1]////\u79FB\u9664\u4F60\u624B\u4E0A\u7684\u4E00\u5F35\u5361\u7247\uFF0C//\u5982\u679C\u5B83\u50F9\u503C[3]\u4EE5\u4E0A\uFF0C//\u5176\u4ED6\u73A9\u5BB6\u7372\u5F97\u4E00\u5F35\u8A5B\u5492\uFF0C//\u5982\u679C\u5B83\u662F\u5F35\u9322\u5E63\u5361\uFF0C//\u5176\u4ED6\u73A9\u5BB6\u5C07\u624B\u724C\u68C4\u5230\u5269\u4E09\u5F35\u3002";
TChineseCardTexts[CardNames.ROCKS] = "[!1]//---//\u7576\u4F60\u7372\u5F97\u6216\u662F\u79FB\u9664\u6B64\u5361\u7684\u6642\u5019\uFF0C//\u7372\u5F97\u4E00\u5F35\u9280\u5E63\uFF0C\u5982\u679C\u6B64\u6642\u662F\u4F60\u7684//\u8CFC\u8CB7\u968E\u6BB5\uFF0C\u5C07\u9280\u5E63\u653E\u5230\u724C\u5EAB\u9802\uFF0C//\u5426\u5247\u5C07\u9280\u5E63\u52A0\u5165\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.GLADIATOR] = "|\uFF0B|[2]////\u5C55\u793A\u4F60\u624B\u4E0A\u7684\u4E00\u5F35\u724C\uFF0C//\u4F60\u5DE6\u624B\u908A\u7684\u73A9\u5BB6\u53EF\u4EE5\u5C55\u793A//\u4ED6\u624B\u4E0A\u4E00\u5F35\u540C\u540D\u7684\u5361\u7247\uFF0C//\u5982\u679C\u4ED6\u6C92\u5C55\u793A\uFF0C\u4F60|\uFF0B|[1]\uFF0C\u4E26\u4E14//\u79FB\u9664\u4F9B\u61C9\u5340\u88E1\u7684\u4E00\u5F35\u528D\u9B25\u58EB\u3002";
TChineseCardTexts[CardNames.FORTUNE] = "|\uFF0B1 \u8CFC\u8CB7|////\u7576\u4F60\u6253\u51FA\u6B64\u5361\uFF0C\u4E14\u9019\u662F\u4F60\u7B2C\u4E00//\u6B21\u767C\u52D5\u5927\u7955\u5BF6\u7684\u6548\u679C\uFF0C\u5C07\u4F60\u73FE//\u6709\u7684[]\u52A0\u500D\u3002//---//\u4F60\u7372\u5F97\u6B64\u5361\u6642\uFF0C\u4F60\u51FA\u724C\u5340\u6BCF\u6709\u4E00//\u5F35\u528D\u9B25\u58EB\uFF0C\u4F60\u5C31\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\u3002";
TChineseCardTexts[CardNames.CASTLES] = "\u5C07\u57CE\u5821\u724C\u5806\u4F9D\u7167\u50F9\u503C\u6392\u5E8F\uFF0C//\u5C07\u8F03\u8CB4\u7684\u5361\u7247\u653E\u5728\u4E0B\u9762\uFF0C//\u5169\u4EBA\u5C40\u7684\u6642\u5019\uFF0C\u6BCF\u7A2E\u57CE\u5821//\u53EA\u4F7F\u7528\u4E00\u5F35\uFF0C\u53EA\u6709\u6700\u4E0A\u65B9//\u7684\u5361\u7247\u53EF\u4EE5\u8CFC\u8CB7\u6216\u7372\u5F97\u3002";
TChineseCardTexts[CardNames.HUMBLE_CASTLE] = "[!1]//---//\u904A\u6232\u7D50\u675F\u6642\uFF0C//\u4F60\u6BCF\u64C1\u6709\u4E00\u5F35\u57CE\u5821\u5C31\u5F97\u5230{!1}";
TChineseCardTexts[CardNames.CRUMBLING_CASTLE] = "{!1}//---//\u7576\u4F60\u79FB\u9664\u6B64\u5361\u6216\u662F\u7372\u5F97\u6B64\u5361\uFF0C//\uFF0B{!1}\u548C\u7372\u5F97\u4E00\u5F35\u9280\u5E63\u3002";
TChineseCardTexts[CardNames.SMALL_CASTLE] = "{!2}//---//\u79FB\u9664\u6B64\u5361\u6216\u662F\u4F60\u624B\u4E2D//\u7684\u4E00\u5F35\u57CE\u5821\uFF0C\u5982\u679C\u4F60\u79FB//\u9664\u4E86\uFF0C\u7372\u5F97\u4E00\u5F35\u57CE\u5821\u3002";
TChineseCardTexts[CardNames.HAUNTED_CASTLE] = "{!2}//---//\u5728\u4F60\u81EA\u5DF1\u56DE\u5408\u7372\u5F97\u6B64\u5361\u7684\u6642\u5019\uFF0C//\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\uFF0C\u5176\u4ED6\u624B\u724C\u6709\u4E94\u5F35//\u4EE5\u4E0A\u7684\u73A9\u5BB6\uFF0C\u5C07\u624B\u4E0A\u5169\u5F35\u5361\u7247\u653E//\u5230\u5404\u81EA\u7684\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.OPULENT_CASTLE] = "{!3}//---//\u68C4\u6389\u624B\u4E0A\u4EFB\u610F\u6578\u91CF\u7684//\u5206\u6578\u5361\uFF0C\u6BCF\u68C4\u6389\u4E00\u5F35//\u5C31\u5F97\u5230|\uFF0B|[2]\u3002";
TChineseCardTexts[CardNames.SPRAWLING_CASTLE] = "{!4}//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\u6642\uFF0C\u7372\u5F97\u4E00\u5F35//\u516C\u570B\u6216\u4E09\u5F35\u838A\u5712\u3002";
TChineseCardTexts[CardNames.GRAND_CASTLE] = "{!5}//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\u6642\uFF0C\u5C55\u793A\u4F60\u7684\u624B//\u724C\u3002\u8A08\u7B97\u4F60\u624B\u724C\u4E0A\u548C\u51FA\u724C\u5340\u7684//\u5206\u6578\u5361\uFF0C\u6BCF\u4E00\u5F35\u6703\u8B93\u4F60\uFF0B{1}\u3002";
TChineseCardTexts[CardNames.KINGS_CASTLE] = "\u904A\u6232\u7D50\u675F\u6642\uFF0C//\u6BCF\u4E00\u5F35\u57CE\u5821\u6703\u8B93\u4F60\uFF0B{2}\u3002";
TChineseCardTexts[CardNames.ADVANCE] = "\u4F60\u53EF\u4EE5\u79FB\u9664\u4F60\u624B\u4E0A\u4E00\u5F35\u884C\u52D5\u5361\uFF0C\u5982\u679C\u4F60//\u79FB\u9664\u4E86\uFF0C\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A[6]\u7684\u884C\u52D5\u5361\u7247\u3002";
TChineseCardTexts[CardNames.ANNEX] = "\u89C0\u770B\u4F60\u7684\u68C4\u724C\u5806\uFF0C\u5148\u9078\u64C7\u81F3\u591A\u4E94\u5F35\u68C4\u724C\u5806\u7684\u5361\u7247\uFF0C//\u9664\u4E86\u4F60\u9078\u7684\u5361\u7247\uFF0C\u5C07\u5176\u4ED6\u5361\u7247\u6D17\u56DE\u4F60\u7684\u724C\u5EAB\uFF0C//\u4F60\u7372\u5F97\u4E00\u5F35\u516C\u570B\u3002";
TChineseCardTexts[CardNames.ARCHIVE] = "|\uFF0B1 \u884C\u52D5|////\u5C07\u4F60\u724C\u5EAB\u9802\u7684\u4E09\u5F35\u5361\u7247//\u9762\u671D\u4E0B\u653E\u5230\u4E00\u908A\uFF0C\u4F60\u53EF//\u4EE5\u770B\u662F\u4EC0\u9EBC\u5361\uFF0C\u73FE\u5728\u548C//\u4E4B\u5F8C\u4F60\u7684\u5169\u500B\u56DE\u5408\uFF0C\u9078\u64C7//\u5176\u4E2D\u4E00\u5F35\u5361\u7247\u52A0\u5165\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.AQUEDUCT] = "\u7576\u4F60\u7372\u5F97\u4E00\u5F35\u9322\u5E63\u5361\uFF0C\u5C07\u4E0A\u9762\u7684{1}\u79FB\u52D5\u5230\u6B64\u724C\u4E0A\u3002//\u7576\u4F60\u7372\u5F97\u5206\u6578\u5361\uFF0C\u62FF\u53D6\u6B64\u5361\u4E0A\u7684\u6240\u6709{}\u3002//---//\u8D77\u59CB\u8A2D\u7F6E\uFF1A\u5728\u9280\u5E63\u724C\u5806\u8DDF\u9EC3\u91D1\u724C\u5806\u5404\u653E\u4E0A{8}\u3002";
TChineseCardTexts[CardNames.ARENA] = "\u5728\u4F60\u8CFC\u8CB7\u968E\u6BB5\u958B\u59CB\u6642\uFF0C\u4F60\u53EF\u4EE5\u68C4\u6389\u4E00\u5F35\u884C\u52D5\u5361\uFF0C//\u5982\u679C\u4F60\u6709\u68C4\u5361\uFF0C\u5247\u5F9E\u6B64\u5361\u4E0A\u62FF\u53D6{2}\u3002//---//\u8D77\u59CB\u8A2D\u7F6E\uFF1A\u6BCF\u6709\u4E00\u4F4D\u73A9\u5BB6\uFF0C\u5728\u6B64\u5361\u4E0A\u653E{6}\u3002";
TChineseCardTexts[CardNames.BANDIT_FORT] = "\u8A08\u7B97\u5206\u6578\u6642\uFF0C\u4F60\u6BCF\u64C1\u6709\u4E00\u5F35\u9280\u5E63\u6216\u9EC3\u91D1\uFF0C//\u4F60\u5C31{-2}";
TChineseCardTexts[CardNames.BANQUET] = "\u7372\u5F97\u5169\u5F35\u9285\u5E63\u548C\u4E00\u5F35//\u50F9\u503C\u81F3\u591A[5]\u7684\u975E\u5206\u6578\u5361\u3002";
TChineseCardTexts[CardNames.BASILICA] = "\u6BCF\u7576\u4F60\u7372\u5F97\u5361\u7247\uFF0C\u4E14\u662F\u4F60\u7684\u8CFC\u8CB7\u968E\u6BB5\u6642\uFF0C//\u5982\u679C\u4F60\u7684\u9810\u7B97\u5269\u4E0B[2]\u4EE5\u4E0A\uFF0C\u5F9E\u6B64\u5361\u4E0A\u62FF\u53D6{2}\u3002//---//\u8D77\u59CB\u8A2D\u7F6E\uFF1A\u6BCF\u6709\u4E00\u4F4D\u73A9\u5BB6\uFF0C\u5728\u6B64\u5361\u4E0A\u591A{2}\u3002";
TChineseCardTexts[CardNames.BATHS] = "\u5728\u4F60\u56DE\u5408\u7D50\u675F\u7684\u6642\u5019\uFF0C\u5982\u679C\u4F60\u7684\u56DE\u5408\u6C92\u6709\u7372\u5F97\u4EFB\u4F55\u4E00\u5F35\u5361\u3002//\u5F9E\u6B64\u5361\u4E0A\u65B9\u62FF\u53D6{2}\u3002//---//\u8D77\u59CB\u8A2D\u7F6E\uFF1A\u6BCF\u6709\u4E00\u4F4D\u73A9\u5BB6\uFF0C\u5728\u6B64\u5361\u4E0A\u653E{6}\u3002";
TChineseCardTexts[CardNames.BATTLEFIELD] = "\u7576\u7372\u5F97\u4E00\u5F35\u5206\u6578\u5361\u7684\u6642\u5019\uFF0C\u62FF\u53D6\u6B64\u5361\u4E0A\u7684{2}//---//\u8D77\u59CB\u8A2D\u7F6E\uFF1A\u6BCF\u6709\u4E00\u4F4D\u73A9\u5BB6\uFF0C\u5728\u6B64\u5361\u4E0A\u653E{6}\u3002";
TChineseCardTexts[CardNames.CAPITAL] = "[!6]////|\uFF0B1 \u8CFC\u8CB7|//---//\u7576\u6B64\u5361\u5F9E\u51FA\u724C\u5340\u88AB\u68C4\u6389\u6642\uFF0C//\u62FF\u53D6[6D]\uFF0C\u6B64\u6642\u4F60\u53EF\u4EE5//\u511F\u9084[D]\u3002";
TChineseCardTexts[CardNames.CHARM] = "\u7576\u4F60\u6253\u51FA\u6B64\u5361\uFF0C\u9078\u64C7\u4E00\u500B//: |\uFF0B1 \u8CFC\u8CB7|\u548C|\uFF0B|[2];\u6216\u662F//\u9019\u56DE\u5408\u4F60\u4E0B\u6B21\u7372\u5F97\u5361\u7247\u6642\uFF0C//\u4F60\u53EF\u4EE5\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u76F8\u540C\uFF0C//\u4F46\u540D\u5B57\u4E0D\u540C\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.CHARIOT_RACE] = "|\uFF0B1 \u884C\u52D5|////\u5C55\u793A\u4F60\u724C\u5EAB\u9802\u7684\u4E00\u5F35\u5361\u7247\uFF0C//\u5C07\u5B83\u653E\u5165\u624B\u4E2D\uFF0C\u4F60\u5DE6\u624B\u908A\u7684//\u73A9\u5BB6\u5C55\u793A\u724C\u5EAB\u9802\u7684\u4E00\u5F35\u5361\u7247//\uFF0C\u5982\u679C\u4F60\u5C55\u793A\u7684\u5361\u7247\u50F9\u503C\u6BD4//\u8F03\u9AD8\uFF0C\u5F97\u5230|\uFF0B|[1]\u548C\uFF0B{1}\u3002";
TChineseCardTexts[CardNames.CITY_QUARTER] = "|\uFF0B2 \u884C\u52D5|//////\u5C55\u793A\u4F60\u7684\u624B\u724C\uFF0C\u4F60\u6BCF\u5C55\u793A//\u4E00\u5F35\u884C\u52D5\u5361\uFF0C\u4F60\u5C31|\uFF0B1 \u5361\u7247|";
TChineseCardTexts[CardNames.COLONNADE] = "\u7576\u4F60\u7372\u5F97\u4E00\u5F35\u884C\u52D5\u5361\uFF0C\u4F60\u7684\u51FA\u724C\u5340\u4E5F\u6709\u4E00\u5F35\u540C\u540D\u7684\u884C\u52D5\u5361//\u5F9E\u6B64\u5361\u4E0A\u62FF\u53D6{2}\u3002//---//\u8D77\u59CB\u8A2D\u7F6E\uFF1A\u6BCF\u6709\u4E00\u4F4D\u73A9\u5BB6\uFF0C\u5728\u6B64\u5361\u4E0A\u653E{6}\u3002";
TChineseCardTexts[CardNames.CONQUEST] = "\u7372\u5F97\u5169\u5F35\u9280\u5E63\u3002//\u6B64\u56DE\u5408\u4F60\u7372\u5F97\u5E7E\u5F35\u9280\u5E63\u4F60\u5C31\u7372\u5F97\u5E7E{1}";
TChineseCardTexts[CardNames.CROWN] = "\u5982\u679C\u9019\u662F\u4F60\u7684\u884C\u52D5\u968E\u6BB5\uFF0C//\u4F60\u53EF\u4EE5\u6253\u51FA\u624B\u4E0A\u7684\u884C//\u52D5\u5361\u767C\u52D5\u6548\u679C\u5169\u6B21\uFF0C//\u5982\u679C\u9019\u662F\u4F60\u7684\u8CFC\u8CB7\u968E\u6BB5\uFF0C//\u4F60\u53EF\u4EE5\u6253\u51FA\u624B\u4E0A\u4E00\u5F35\u9322//\u5E63\u5361\u767C\u52D5\u5169\u6B21\u6548\u679C\u3002";
TChineseCardTexts[CardNames.DELVE] = "|\uFF0B1 \u8CFC\u8CB7|//\u7372\u5F97\u4E00\u5F35\u9280\u5E63\u3002";
TChineseCardTexts[CardNames.DEFILED_SHRINE] = "\u7576\u4F60\u7372\u5F97\u4E00\u5F35\u884C\u52D5\u5361\u6642\uFF0C\u5C07\u5B83\u4F9B\u61C9\u5340\u4E0A\u9762\u7684{1}\u79FB\u52D5\u5230\u6B64\u5361\u4E0A\u3002//\u7576\u4F60\u7372\u5F97\u8A5B\u5492\u6642\uFF0C\u5F9E\u6B64\u5361\u4E0A\u62FF\u8D70\u5168\u90E8\u7684{}\u3002//---//\u8D77\u59CB\u8A2D\u7F6E\uFF1A\u9664\u4E86\u8490\u96C6\u4EE5\u5916\u7684\u884C\u52D5\u5361\u4E0A\u5404\u653E\u4E0A{2}\u3002";
TChineseCardTexts[CardNames.DOMINATE] = "\u7372\u5F97\u4E00\u5F35\u884C\u7701\uFF0C\u5982\u679C\u4F60\u7372\u5F97\u7684\u8A71\uFF0C\uFF0B{9}.";
TChineseCardTexts[CardNames.DONATE] = "\u6B64\u56DE\u5408\u91CD\u62BD\u4E94\u5F35\u5361\u524D\uFF0C\u5C07\u4F60\u68C4\u724C\u5806\u8DDF\u724C\u5EAB\u7684\u5361\u7247\u653E\u5230//\u624B\u4E2D\uFF0C\u79FB\u9664\u4F60\u624B\u4E2D\u4EFB\u610F\u6578\u91CF\u7684\u5361\u7247\uFF0C\u5C07\u4F60\u624B\u724C\u6D17\u9032\u724C//\u5EAB\uFF0C\u7136\u5F8C\u91CD\u65B0\u62BD\u51FA\u4E94\u5F35\u5361\u7247\u3002";
TChineseCardTexts[CardNames.ENCHANTRESS] = "\u76F4\u5230\u4F60\u4E0B\u6B21\u56DE\u5408\u958B\u59CB\u4E4B\u524D\uFF0C//\u5C07\u5176\u4ED6\u73A9\u5BB6\u6253\u51FA\u7684\u7B2C\u4E00\u5F35//\u884C\u52D5\u5361\u7684\u51FA\u724C\u6548\u679C\u6539\u70BA\uFF1A//|\uFF0B1 \u5361\u7247|\u548C|\uFF0B1 \u884C\u52D5|\u3002//\u800C\u4E0D\u662F\u5B83\u539F\u672C\u7684\u6548\u679C\u3002////\u5728\u4F60\u4E0B\u6B21\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\uFF1A//|\uFF0B2 \u5361\u7247| ";
TChineseCardTexts[CardNames.ENGINEER] = "\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A[4]\u7684\u5361\u7247\u3002//\u4F60\u53EF\u4EE5\u79FB\u9664\u6B64\u5361\uFF0C//\u5982\u679C\u4F60\u79FB\u9664\u4E86\uFF0C\u4F60\u7372\u5F97\u4E00\u5F35//\u50F9\u503C\u81F3\u591A[4]\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.FARMERS_MARKET] = "|\uFF0B1 \u8CFC\u8CB7|////\u5982\u679C\u679C\u83DC\u5E02\u5834\u724C\u5806\u4E0A\u6709{4}\u6216//\u4EE5\u4E0A\uFF0C\u62FF\u53D6\u4E0A\u9762\u7684\u5206\u6578\u6307\u793A\u7269\uFF0C//\u4E26\u79FB\u9664\u6B64\u5361\uFF0C\u5982\u679C\u6C92\u6709\u7684\u8A71\uFF0C//\u653E{1}\u5230\u679C\u83DC\u5E02\u5834\u724C\u5806\u4E0A\uFF0C//\u90A3\u724C\u5806\u4E0A\u6BCF\u6709{1}\u5C31\u5F97\u5230|\uFF0B|[1]\u3002";
TChineseCardTexts[CardNames.FORUM] = "|\uFF0B3 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u68C4\u6389\u5169\u5F35\u5361\u7247\u3002//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\u6642|\uFF0B1 \u8CFC\u8CB7|\u3002";
TChineseCardTexts[CardNames.FOUNTAIN] = "\u5982\u679C\u4F60\u64C1\u670910\u5F35\u4EE5\u4E0A\u7684\u9285\u5E63\uFF0C\u4F60\u5F97\u5230{15}\u3002";
TChineseCardTexts[CardNames.GROUNDSKEEPER] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//---//\u6B64\u56DE\u5408\u4F60\u6BCF\u7372\u5F97\u4E00\u5F35\u5206\u6578\u5361\u5C31\uFF0B{1}";
TChineseCardTexts[CardNames.KEEP] = "\u8A08\u7B97\u5206\u6578\u6642\uFF0C\u8A08\u7B97\u6BCF\u7A2E\u4F60\u9322\u5E63\u5361\u7684\u6578\u91CF\uFF0C//\u6BCF\u7A2E\u9322\u5E63\u5361\u64C1\u6709\u6700\u591A\u7684\u73A9\u5BB6\u6703//\u5F97\u5230{5}\u3002";
TChineseCardTexts[CardNames.LABYRINTH] = "\u7576\u4F60\u9019\u56DE\u5408\u7372\u5F97\u7B2C\u4E8C\u5F35\u5361\u7247\u7684\u6642\u5019\u3002//\u62FF\u53D6\u6B64\u5361\u4E0A\u7684{2}\u3002//---//\u8D77\u59CB\u8A2D\u5B9A\uFF1A\u6BCF\u6709\u4E00\u4F4D\u73A9\u5BB6\uFF0C\u5728\u6B64\u5361\u4E0A\u653E{6}\u3002";
TChineseCardTexts[CardNames.LEGIONARY] = "|\uFF0B|[3]////\u4F60\u53EF\u4EE5\u5C55\u793A\u4F60\u624B\u4E0A\u4E00\u5F35\u9EC3\u91D1//\u3002\u5982\u679C\u4F60\u5C55\u793A\u4E86\uFF0C\u5176\u4ED6\u73A9\u5BB6//\u5C07\u624B\u724C\u68C4\u5230\u5269\u4E0B\u5169\u5F35\uFF0C\u7136\u5F8C//\u62BD\u53D6\u4E00\u5F35\u5361\u7247\u3002";
TChineseCardTexts[CardNames.MOUNTAIN_PASS] = "\u5982\u679C\u4F60\u662F\u672C\u5834\u7B2C\u4E00\u500B\u7372\u5F97\u884C\u7701\u7684\uFF0C\u5F9E\u4F60\u5DE6\u908A//\u7684\u73A9\u5BB6\u958B\u59CB\u51FA\u50F9\uFF0C(\u6700\u591A\u51FA\u50F9[40D])\uFF0C\u8F2A\u5230\u4F60\u51FA\u50F9\u5B8C\u5F8C\u505C\u6B62\u51FA\u50F9\u3002//\u51FA\u50F9\u6700\u591A\u7684\u73A9\u5BB6\u5F97\u5230{8}\u4E26\u62FF\u53D6\u8207\u4ED6\u558A\u7684\u6578\u5B57\u76F8\u540C\u6578\u91CF\u7684[D]";
TChineseCardTexts[CardNames.MUSEUM] = "\u6BCF\u4E00\u7A2E\u4E0D\u540C\u540D\u5B57\u7684\u5361\u7247\u6703\u7D66\u4F60{2}\u3002";
TChineseCardTexts[CardNames.OBELISK] = "\u8A08\u7B97\u5206\u6578\u6642\uFF0C\u4F60\u6BCF\u6709\u4E00\u5F35\u6B64\u5730\u6A19\u6307\u5B9A\u7684\u884C\u52D5\u5361\uFF0C//\u4F60\u5C31\u5F97\u5230{2}\u3002//---//\u8D77\u59CB\u8A2D\u7F6E\uFF1A\u96A8\u6A5F\u9078\u4E00\u758A\u884C\u52D5\u5361\u3002";
TChineseCardTexts[CardNames.ORCHARD] = "\u8A08\u7B97\u5206\u6578\u6642\uFF0C//\u6BCF\u4E00\u7A2E\u4E09\u5F35\u4EE5\u4E0A\u7684\u884C\u52D5\u5361\uFF0C\u6703\u63D0\u4F9B\u4F60{4}\u3002";
TChineseCardTexts[CardNames.OVERLORD] = "\u6253\u51FA\u6B64\u5361\u6642\uFF0C\u9078\u64C7\u5728\u4F9B\u61C9\u5340//\u88E1\u4E00\u5F35\u50F9\u503C\u81F3\u591A[5]\u7684//\u884C\u52D5\u5361\uFF0C\u767C\u52D5\u8A72\u5361\u6548\u679C\uFF0C\u767C//\u52D5\u6548\u679C\u6642\u4E0D\u80FD\u79FB\u52D5\u90A3\u5F35\u5361\u3002//(\u4E0D\u80FD\u9078\u64C7\u547D\u4EE4\u5361)";
TChineseCardTexts[CardNames.PALACE] = "\u8A08\u7B97\u5206\u6578\u6642\uFF0C\u4F60\u6BCF\u64C1\u6709\u4E00\u7D44\u9285\u5E63-\u9280\u5E63-\u9EC3\u91D1\uFF0C//\u4F60\u5C31\u7372\u5F97{3}";
TChineseCardTexts[CardNames.RITUAL] = "\u7372\u5F97\u4E00\u5F35\u8A5B\u5492\uFF0C\u5982\u679C\u4F60\u7372\u5F97\u4E86\uFF0C\u79FB\u9664\u4F60\u624B\u4E0A\u4E00\u5F35\u5361\uFF0C//\u90A3\u5F35\u5361\u7247\u6BCF\u50F9\u503C[1]\u4F60\u5C31\u5F97\u5230\uFF0B{1}\u3002";
TChineseCardTexts[CardNames.ROYAL_BLACKSMITH] = "|\uFF0B5 \u5361\u7247|////\u5C55\u793A\u4F60\u7684\u624B\u724C\uFF0C//\u68C4\u6389\u5168\u90E8\u7684\u9285\u5E63\u3002";
TChineseCardTexts[CardNames.SACRIFICE] = "\u79FB\u9664\u4F60\u624B\u4E0A\u4E00\u5F35\u5361\uFF0C//\u5982\u679C\u5B83\u662F\u5F35...////\u884C\u52D5\u5361\uFF1A|\uFF0B2 \u5361\u7247, \uFF0B2 \u884C\u52D5|//\u9322\u5E63\u5361\uFF1A|\uFF0B|[2]//\u5206\u6578\u5361\uFF1A|\uFF0B|{2}";
TChineseCardTexts[CardNames.SALT_THE_EARTH] = "\uFF0B{1}//\u79FB\u9664\u4F9B\u61C9\u5340\u7684\u4E00\u5F35\u5206\u6578\u5361\u3002";
TChineseCardTexts[CardNames.TAX] = "\u653E[2D]\u5230\u4F9B\u61C9\u5340\u88E1\u4E00\u500B\u724C\u5806\u4E0A//---//\u8D77\u59CB\u8A2D\u7F6E\uFF1A\u653E[1D]\u5230\u4F9B\u61C9\u5340\u88E1\u7684\u6BCF\u4E00\u500B\u724C\u5806\u4E0A\u3002//\u4F60\u5728\u81EA\u5DF1\u7684\u8CFC\u8CB7\u968E\u6BB5\u7372\u5F97\u4E86\u4E00\u5F35\u5361\u7247\uFF0C\u4E5F\u62FF\u53D6\u90A3\u758A\u724C\u4E0A\u9762\u7684\u6240\u6709[D]\u3002";
TChineseCardTexts[CardNames.TEMPLE] = "\uFF0B{1}////\u79FB\u9664\u4F60\u624B\u4E0A\u4E00\u5230\u4E09\u7A2E\u4E0D\u540C//\u540D\u5B57\u7684\u5361\u7247\u3002//\u653E{1}\u5230\u4F9B\u61C9\u5340\u7684\u5BFA\u5EDF\u724C\u5806\u4E0A\u3002//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\u6642\uFF0C//\u62FF\u53D6\u5BFA\u5EDF\u4F9B\u61C9\u5340\u4E0A\u7684{}\u3002";
TChineseCardTexts[CardNames.TOMB] = "\u6BCF\u7576\u4F60\u79FB\u9664\u5361\u7247\uFF0C\u5F97\u5230\uFF0B{1}\u3002";
TChineseCardTexts[CardNames.TOWER] = "\u904A\u6232\u7D50\u675F\u6642\uFF0C\u6AA2\u67E5\u4F9B\u61C9\u5340\u88E1\u7A7A\u7684\u975E\u5206\u6578\u724C\u5806\uFF0C//\u4F60\u724C\u5EAB\u88E1\u6BCF\u6709\u4E00\u5F35\u90A3\u88CF\u4F86\u7684\u5361\u7247\u5C31\u6709{1}\u3002";
TChineseCardTexts[CardNames.TRIUMPH] = "\u7372\u5F97\u4E00\u5F35\u838A\u5712\uFF0C\u5982\u679C\u4F60\u7372\u5F97\u4E86\uFF0C//\u4F60\u9019\u56DE\u5408\u6BCF\u7372\u5F97\u4E00\u5F35\u5361\u7247\u5C31\uFF0B{1}";
TChineseCardTexts[CardNames.TRIUMPHAL_ARCH] = "\u8A08\u7B97\u5206\u6578\u6642\u8A08\u7B97\u4F60\u7B2C\u4E8C\u591A\u7684\u884C\u52D5\u5361\uFF0C//\u90A3\u7A2E\u884C\u52D5\u5361\u6BCF\u4E00\u5F35\u7D66\u4E88\u4F60{3}(\u540C\u6A23\u591A\u9078\u5176\u4E2D\u4E00\u7A2E\u7B97)\u3002";
TChineseCardTexts[CardNames.VILLA] = "|\uFF0B2 \u884C\u52D5|//|\uFF0B1 \u8CFC\u8CB7|//|\uFF0B[1]|//---////\u7576\u4F60\u7372\u5F97\u6B64\u5361\uFF0C\u5C07\u6B64\u5361\u653E\u5165\u624B//\u4E2D\u4E26|\uFF0B1 \u884C\u52D5|\uFF0C\u5982\u679C\u73FE\u5728\u662F\u4F60\u7684//\u8CFC\u8CB7\u968E\u6BB5\uFF0C\u5247\u56DE\u5230\u884C\u52D5\u968E\u6BB5\u3002";
TChineseCardTexts[CardNames.WALL] = "\u8A08\u7B97\u5206\u6578\u6642\uFF0C\u5982\u679C\u4F60\u7684\u724C\u5EAB\u8D85\u904E15\u5F35\u5361\uFF0C//\u6BCF\u8D85\u904E\u4E00\u5F35\u5361\u6703\u4F7F\u4F60{-1}";
TChineseCardTexts[CardNames.WOLF_DEN] = "\u8A08\u7B97\u5206\u6578\u6642\uFF0C\u6BCF\u4E00\u7A2E\u4F60\u53EA\u6709//\u55AE\u5F35\u7684\u724C\u6703\u4F7F\u4F60\uFF0D{3}";
TChineseCardTexts[CardNames.WEDDING] = "\uFF0B{1}//\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1";
TChineseCardTexts[CardNames.WILD_HUNT] = "\u9078\u64C7\u4E00\u500B\uFF1A|\uFF0B3 \u5361\u7247|\u4E26//\u4E14\u653E{1}\u5230\u72C2\u7375\u724C\u5806\u4E0A//;\u6216\u662F\u7372\u5F97\u4E00\u5F35\u838A\u5712\uFF0C//\u5982\u679C\u4F60\u6709\u7372\u5F97\uFF0C\u62FF\u53D6//\u72C2\u7375\u724C\u5806\u4E0A\u7684{}\u3002";
TChineseCardTexts[CardNames.WINDFALL] = "\u5982\u679C\u4F60\u7684\u724C\u5EAB\u8DDF\u68C4\u724C\u5806\u90FD\u662F\u7A7A\u7684\uFF0C\u7372\u5F97\u4E09\u5F35\u9EC3\u91D1\u3002";
TChineseCardTexts[CardNames.PIG] = "\u8C6C\u8C6C\uFF01,";
TChineseCardTexts[CardNames.MINUS_CARD] = "";
TChineseCardTexts[CardNames.MINUS_COIN] = "";
TChineseCardTexts[CardNames.STATE_LIMBO] = "";
TChineseCardTexts[CardNames.DRUID_BOONS] = "";
TChineseCardTexts[CardNames.BOON_DRAWPILE] = "";
TChineseCardTexts[CardNames.BOON_DISCARDPILE] = "";
TChineseCardTexts[CardNames.THE_EARTHS_GIFT] = "\u4F60\u53EF\u4EE5\u68C4\u6389\u4E00\u5F35\u9322\u5E63\u5361\u4F86\u7372\u5F97//\u50F9\u503C\u81F3\u591A[4]\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.THE_FIELDS_GIFT] = "|\uFF0B1 \u884C\u52D5|//|\uFF0B[1]|////(\u4FDD\u7559\u6B64\u5361\u5230\u6E05\u9664\u968E\u6BB5)";
TChineseCardTexts[CardNames.THE_FLAMES_GIFT] = "\u4F60\u53EF\u4EE5\u79FB\u9664\u624B\u4E0A\u4E00\u5F35\u5361\u7247";
TChineseCardTexts[CardNames.THE_FORESTS_GIFT] = "|\uFF0B1 \u8CFC\u8CB7|//|\uFF0B[1]|////(\u4FDD\u7559\u6B64\u5361\u5230\u6E05\u9664\u968E\u6BB5)";
TChineseCardTexts[CardNames.THE_MOONS_GIFT] = "\u89C0\u770B\u4F60\u7684\u68C4\u724C\u5806//\u4F60\u53EF\u4EE5\u5C07\u4E00\u5F35\u5361\u653E\u56DE\u724C\u5EAB\u9802";
TChineseCardTexts[CardNames.THE_MOUNTAINS_GIFT] = "\u7372\u5F97\u4E00\u5F35\u9280\u5E63";
TChineseCardTexts[CardNames.THE_RIVERS_GIFT] = "\u6B64\u56DE\u5408\u7D50\u675F\u7684\u6642\u5019\u984D\u5916|\uFF0B1 \u5361\u7247|//(\u5C07\u6B64\u5361\u4FDD\u7559\u5230\u6E05\u9664\u968E\u6BB5)";
TChineseCardTexts[CardNames.THE_SEAS_GIFT] = "|\uFF0B1 \u5361\u7247|";
TChineseCardTexts[CardNames.THE_SKYS_GIFT] = "\u4F60\u53EF\u4EE5\u68C4\u6389\u4E09\u5F35\u5361\u7247\u4F86\u7372\u5F97\u4E00\u5F35\u91D1";
TChineseCardTexts[CardNames.THE_SUNS_GIFT] = "\u89C0\u770B\u4F60\u724C\u5EAB\u9802\u7684\u56DB\u5F35\u5361\u3002\u68C4\u6389\u4EFB\u610F\u6578\u91CF\uFF0C//\u7136\u5F8C\u5C07\u5269\u4E0B\u7684\u5361\u7247\u4EE5\u4EFB\u610F\u9806\u5E8F\u653E\u56DE\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.THE_SWAMPS_GIFT] = "\u5F9E\u9B3C\u706B\u724C\u5806\u7372\u5F97\u4E00\u5F35\u9B3C\u706B";
TChineseCardTexts[CardNames.THE_WINDS_GIFT] = "|\uFF0B2 \u5361\u7247|//\u68C4\u6389\u5169\u5F35\u5361\u7247";
TChineseCardTexts[CardNames.HEX_DRAWPILE] = "";
TChineseCardTexts[CardNames.HEX_DISCARDPILE] = "";
TChineseCardTexts[CardNames.BAD_OMENS] = "\u5C07\u4F60\u7684\u724C\u5EAB\u653E\u5230\u68C4\u724C\u5806\uFF0C\u89C0\u770B//\u68C4\u724C\u5806\u4E26\u4E14\u5C07\u5169\u5F35\u9285\u5E63\u653E\u5230\u724C\u5EAB\u9802//(\u6216\u662F\u5C55\u793A\u4F60\u6C92\u8FA6\u6CD5)\u3002";
TChineseCardTexts[CardNames.DELUSION] = "\u5982\u679C\u4F60\u6C92\u6709\u5AC9\u5992\u6216\u5E7B\u89BA//\u62FF\u53D6\u5E7B\u89BA\u3002";
TChineseCardTexts[CardNames.ENVY] = "\u5982\u679C\u4F60\u6C92\u6709\u5AC9\u5992\u6216\u5E7B\u89BA\uFF0C//\u62FF\u53D6\u5AC9\u5992\u3002";
TChineseCardTexts[CardNames.FAMINE] = "\u5C55\u793A\u4F60\u724C\u5EAB\u9802\u7684\u4E09\u5F35\u5361\u7247\uFF0C\u68C4\u6389//\u88E1\u9762\u7684\u884C\u52D5\u5361. \u5C07\u5269\u4E0B\u7684\u5361\u7247\u6D17\u56DE\u724C\u5EAB\uFF0C";
TChineseCardTexts[CardNames.FEAR] = "\u5982\u679C\u4F60\u624B\u724C\u9084\u6709\u4E94\u5F35\u4EE5\u4E0A\uFF0C\u68C4\u6389//\u4E00\u5F35\u884C\u52D5\u5361\u6216\u662F\u9322\u5E63\u5361(\u6216\u662F\u5C55\u793A\u4F60\u6C92\u8FA6\u6CD5).";
TChineseCardTexts[CardNames.GREED] = "\u7372\u5F97\u4E00\u5F35\u9285\u5E63\u5230\u4F60\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.HAUNTING] = "\u5982\u679C\u4F60\u624B\u724C\u9084\u6709\u56DB\u5F35\u4EE5\u4E0A\uFF0C//\u5C07\u4E00\u5F35\u624B\u724C\u653E\u56DE\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.LOCUSTS] = "\u79FB\u9664\u4F60\u724C\u5EAB\u9802\u7684\u4E00\u5F35\u5361\u7247\uFF0C\u5982\u679C\u79FB\u9664\u7684\u662F\u9285\u5E63\u6216//\u662F\u838A\u5712\uFF0C\u5247\u4F60\u7372\u5F97\u4E00\u5F35\u8A5B\u5492\uFF0C\u4E0D\u662F\u7684\u8A71\u5247\u7372\u5F97\u4E00\u5F35//\u81F3\u5C11\u4E00\u500B\u7A2E\u985E\u8207\u539F\u672C\u5361\u7247\u76F8\u540C\uFF0C\u4E14\u50F9\u503C\u8F03\u4F4E\u7684\u5361\u7247";
TChineseCardTexts[CardNames.MISERY] = "\u5982\u679C\u9019\u662F\u4F60\u672C\u5834\u904A\u6232\u7B2C\u4E00\u6B21\u7684\u60B2\u6158\uFF0C\u7372\u5F97\u6158\u5287//\uFF0C\u5982\u679C\u4E0D\u662F\uFF0C\u5247\u5C07\u4F60\u7684\u6158\u5287\u7FFB\u70BA\u4E8C\u6B21\u6158\u5287\u3002";
TChineseCardTexts[CardNames.PLAGUE] = "\u7372\u5F97\u4E00\u5F35\u8A5B\u5492\u5230\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.POVERTY] = "\u5C07\u624B\u724C\u68C4\u5230\u5269\u4E09\u5F35\u3002";
TChineseCardTexts[CardNames.WAR] = "\u6301\u7E8C\u5C55\u793A\u4F60\u7684\u724C\u5EAB\u9802\uFF0C\u76F4\u5230\u5C55\u793A\u5230\u4E00\u5F35//\u50F9\u503C[3]\u6216[4]\u7684\u5361\u7247\u3002 \u79FB\u9664\u5B83\u4E26\u4E14\u68C4\u6389\u5269\u4E0B\u7684\u3002";
TChineseCardTexts[CardNames.MISERABLE] = "{!-2}";
TChineseCardTexts[CardNames.TWICE_MISERABLE] = "{!-4}";
TChineseCardTexts[CardNames.ENVIOUS] = "\u5728\u4F60\u8CFC\u8CB7\u968E\u6BB5\u958B\u59CB\u6642\u6B78\u9084\u6B64\u5361\uFF0C//\u6B64\u56DE\u5408\u9280\u5E63\u8DDF\u9EC3\u91D1\u53EA\u63D0\u4F9B[1]\u3002";
TChineseCardTexts[CardNames.DELUDED] = "\u5728\u4F60\u8CFC\u8CB7\u968E\u6BB5\u958B\u59CB\u6642\u6B78\u9084\u6B64\u5361\uFF0C//\u4E26\u4E14\u4F60\u9019\u56DE\u5408\u4E0D\u80FD\u8CFC\u8CB7\u884C\u52D5\u5361\u3002";
TChineseCardTexts[CardNames.LOST_IN_THE_WOODS] = "\u5728\u4F60\u7684\u56DE\u5408\u958B\u59CB\u968E\u6BB5\uFF0C\u4F60\u53EF\u4EE5\u68C4\u6389\u4E00//\u5F35\u5361\uFF0C\u4F86\u6536\u5230\u4E00\u500B\u795D\u798F\u3002";
TChineseCardTexts[CardNames.BARD] = "|\uFF0B|[2]//\u6536\u5230\u4E00\u5F35\u795D\u798F\u3002";
TChineseCardTexts[CardNames.BLESSED_VILLAGE] = "|\uFF0B1 \u5361\u7247|//|\uFF0B2 \u884C\u52D5|//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\uFF0C\u4F60\u62FF\u4E00//\u5F35\u795D\u798F\uFF0C\u4F60\u53EF\u4EE5\u73FE\u5728\u6536\u5230//\u5B83\uFF0C\u6216\u662F\u4E0B\u56DE\u5408\u518D\u6536\u5230\u3002";
TChineseCardTexts[CardNames.CHANGELING] = "\u79FB\u9664\u6B64\u5361\uFF0C\u7372\u5F97\u4E00\u5F35\u8207//\u4F60\u51FA\u724C\u5340\u540C\u540D\u7684\u5361\u7247\u3002//---//\u6709\u6B64\u5361\u7684\u904A\u6232\uFF0C\u7576\u4F60\u7372\u5F97//\u4E00\u5F35\u50F9\u503C[3]\u4EE5\u4E0A\u7684\u5361\u7247//\u4F60\u53EF\u4EE5\u7528\u6B64\u5361\u8207\u5B83\u4EA4\u63DB\u3002";
TChineseCardTexts[CardNames.CEMETERY] = "{!2}//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\uFF0C\u4F60\u53EF\u4EE5\u79FB//\u9664\u624B\u4E0A\u81F3\u591A\u56DB\u5F35\u5361\u7247\u3002//%%(\u50B3\u5BB6\u5BF6:\u9B3C\u5165\u93E1)%%";
TChineseCardTexts[CardNames.COBBLER] = "\u4F60\u4E0B\u6B21\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\uFF0C//\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A[4]\u7684//\u5361\u7247\uFF0C\u5C07\u5B83\u52A0\u5165\u4F60\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.CONCLAVE] = "|\uFF0B|[2]//\u5982\u679C\u4F60\u624B\u4E0A\u6709\u884C\u52D5\u5361\u7684\u540D\u5B57//\uFF0C\u8DDF\u4F60\u51FA\u724C\u5340\u7684\u5361\u7247\u540D\u5B57\u7686//\u4E0D\u76F8\u540C\uFF0C\u5247\u4F60\u53EF\u4EE5\u6253\u51FA\u5B83\uFF0C//\u6253\u51FA\u4E4B\u5F8C\u5F97\u5230\uFF1A|\uFF0B1 \u884C\u52D5.|";
TChineseCardTexts[CardNames.CRYPT] = "\u9078\u64C7\u4EFB\u610F\u6578\u91CF\u4F60\u51FA\u724C\u5340\u7684//\u975E\u6301\u7E8C\u9322\u5E63\u5361\uFF0C\u5C07\u5B83\u5011\u9762//\u671D\u4E0B\u5730\u653E\u5230\u6B64\u5361\u4E0B\u65B9\uFF0C\u6BCF//\u500B\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\uFF0C\u4F60\u5FC5//\u9808\u9078\u64C7\u5176\u4E2D\u4E00\u5F35\u9322\u5E63\u5361\u52A0//\u5165\u5230\u4F60\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.CURSED_VILLAGE] = "|\uFF0B2 \u884C\u52D5|////\u5C07\u624B\u724C\u62BD\u5230\u516D\u5F35\u3002//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\uFF0C//\u4F60\u6536\u5230\u4E00\u5F35\u5492\u8853\u3002";
TChineseCardTexts[CardNames.DEN_OF_SIN] = "\u4F60\u4E0B\u6B21\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\uFF1A//|\uFF0B2 \u5361\u7247|\u3002//---//\u7372\u5F97\u6B64\u5361\u6642\u76F4\u63A5\u5C07\u6B64\u5361\u653E//\u5165\u4F60\u624B\u4E2D\uFF0C\u800C\u4E0D\u662F\u68C4\u724C\u5806";
TChineseCardTexts[CardNames.DEVILS_WORKSHOP] = "\u4F9D\u7167\u4F60\u9019\u56DE\u5408\u7372\u5F97\u5361\u7247\u7684\u6578//\u91CF\uFF0C\u4F86\u7372\u5F97\u76F8\u5C0D\u61C9\u7684\u5361\u7247\uFF1A//|2\uFF0B: |\u7372\u5F97\u4E00\u5F35\u5C0F\u9B3C;//|1: |\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A[4]\u7684\u5361;//|0: |\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\u3002";
TChineseCardTexts[CardNames.DRUID] = "|\uFF0B1 \u8CFC\u8CB7|////\u6536\u5230\u4E00\u5F35\u88AB\u6B64\u5361\u653E\u5230\u4E00\u908A//\u7684\u795D\u798F(\u795D\u798F\u4FDD\u6301\u4E0D\u52D5)\u3002//---//\u8D77\u59CB\u8A2D\u7F6E: \u5C07\u4E09\u5F35\u795D\u798F\u653E//\u5230\u4E00\u908A\uFF0C\u4E26\u4E14\u6B63\u9762\u5411\u4E0A\u3002";
TChineseCardTexts[CardNames.EXORCIST] = "\u79FB\u9664\u4F60\u624B\u4E0A\u7684\u4E00\u5F35\u5361\u7247\uFF0C//\u5F9E\u96F6\u9AD4\u5361\u5806\u7372\u5F97\u4E00\u5F35\u50F9\u503C//\u8F03\u4F4E\u7684\u96F6\u9AD4\u3002";
TChineseCardTexts[CardNames.FAITHFUL_HOUND] = "|\uFF0B2 \u5361\u7247|//---//\u7576\u4F60\u5728\u6E05\u9664\u968E\u6BB5\u4EE5\u5916\u7684\u6642\u5019//\u5C07\u6B64\u5361\u68C4\u6389\uFF0C\u4F60\u53EF\u4EE5\u5C07\u6B64\u5361//\u653E\u5230\u4E00\u908A\uFF0C\u5728\u9019\u500B\u56DE\u5408\u7D50\u675F//\u6642\uFF0C\u5C07\u6B64\u5361\u653E\u56DE\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.FOOL] = "\u5982\u679C\u4F60\u4E0D\u5728\u6A39\u6797\u8FF7\u5931\u65B9\u5411//\uFF0C\u5247\u4F60\u62FF\u8D70\u5728\u6A39\u6797\u8FF7\u5931\u65B9//\u5411\uFF0C\u4E26\u4E14\u62FF\u53D6\u4E09\u5F35\u795D\u798F\uFF0C//\u4EE5\u4EFB\u610F\u9806\u5E8F\u6536\u5230\u795D\u798F\u3002////%%(\u50B3\u5BB6\u5BF6: \u5E78\u904B\u5E63)%%";
TChineseCardTexts[CardNames.GHOST_TOWN] = "\u5728\u4F60\u4E0B\u6B21\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019//|\uFF0B1 \u5361\u7247 |\u548C|\uFF0B1 \u884C\u52D5|.//---//\u7372\u5F97\u6B64\u5361\u6642\u76F4\u63A5\u5C07\u6B64\u5361\u653E//\u5165\u4F60\u624B\u4E2D\uFF0C\u800C\u4E0D\u662F\u68C4\u724C\u5806";
TChineseCardTexts[CardNames.GUARDIAN] = "\u76F4\u5230\u4F60\u7684\u4E0B\u56DE\u5408\u958B\u59CB\u4EE5\u524D//\uFF0C\u5176\u4ED6\u73A9\u5BB6\u7684\u653B\u64CA\u5361\u90FD\u4E0D//\u6703\u5F71\u97FF\u5230\u4F60\uFF0C\u5728\u4F60\u4E0B\u500B\u56DE//\u5408\u958B\u59CB\u7684\u6642\u5019|\uFF0B|[1].//---//\u7372\u5F97\u6B64\u5361\u6642\u76F4\u63A5\u5C07\u6B64\u5361\u653E//\u5165\u4F60\u624B\u4E2D\uFF0C\u800C\u4E0D\u662F\u68C4\u724C\u5806";
TChineseCardTexts[CardNames.IDOL] = "[!2]////\u5982\u679C\u4F60\u51FA\u724C\u5340\u7684\u724C\u6709\u5947//\u6578\u5F35\u795E\u50CF\uFF0C\u6536\u5230\u4E00\u5F35\u795D\u798F;//\u5982\u679C\u662F\u5076\u6578\u5F35\u795E\u50CF\uFF0C\u5176\u4ED6//\u73A9\u5BB6\u7372\u5F97\u4E00\u5F35\u8A5B\u5492\u3002";
TChineseCardTexts[CardNames.LEPRECHAUN] = "\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\u3002\u5982\u679C\u4F60\u51FA\u724C\u5340//\u6709\u525B\u597D\u4E03\u5F35\u5361\uFF0C\u5247\u4F60\u7372\u5F97\u4E00\u5F35//\u9858\u671B\uFF0C\u5426\u5247\u4F60\u6536\u5230\u4E00\u500B\u5492\u8853\u3002";
TChineseCardTexts[CardNames.MONASTERY] = "\u9019\u56DE\u5408\u4F60\u6BCF\u7372\u5F97\u4E00\u5F35\u5361//\u7247\uFF0C\u4F60\u5C31\u53EF\u4EE5\u79FB\u9664\u4E00\u5F35//\u4F60\u624B\u4E0A\u7684\u5361\u7247\uFF0C\u6216\u662F\u4E00//\u5F35\u4F60\u6253\u51FA\u7684\u9285\u5E63\u3002";
TChineseCardTexts[CardNames.NECROMANCER] = "\u767C\u52D5\u79FB\u9664\u5361\u5806\u88E1\u4E00\u5F35\u6B63\u9762//\u4E14\u4E0D\u5C6C\u65BC\u6301\u7E8C\u5361\u7684\u884C\u52D5\u5361\uFF0C//\u5C07\u90A3\u5F35\u884C\u52D5\u5361\u7FFB\u5230\u80CC\u9762\uFF0C//\u4F60\u56DE\u5408\u7D50\u675F\u518D\u7FFB\u56DE\u6B63\u9762\u3002//---//\u8D77\u59CB\u8A2D\u7F6E\uFF1A\u5C07\u4E09\u7A2E\u6BAD\u5C4D\u5361//\u653E\u5230\u79FB\u9664\u5361\u5806\u88E1\u9762\u3002";
TChineseCardTexts[CardNames.NIGHT_WATCHMAN] = "\u89C0\u770B\u4F60\u724C\u5EAB\u9802\u7684\u4E94\u5F35\u5361\u3002\u68C4//\u6389\u4EFB\u610F\u7684\u6578\u91CF\uFF0C\u5C07\u5269\u4E0B\u7684\u5361//\u7247\u4EE5\u4EFB\u610F\u9806\u5E8F\u64FA\u56DE\u724C\u5EAB\u9802\u3002//---//\u7372\u5F97\u6B64\u5361\u7684\u6642\u5019\u6703\u5230\u4F60\u624B\u724C//\u4E2D\uFF0C\u800C\u4E0D\u662F\u4F60\u7684\u68C4\u724C\u5806\u3002";
TChineseCardTexts[CardNames.PIXIE] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u68C4\u6389\u6700\u4E0A\u9762\u7684\u795D\u798F\uFF0C//\u4F60\u53EF\u4EE5\u79FB\u9664\u6B64\u5361\u4F86\u6536//\u5230\u90A3\u5F35\u795D\u798F\u5169\u6B21\u3002//%%(\u50B3\u5BB6\u5BF6: \u5C71\u7F8A)%%";
TChineseCardTexts[CardNames.POOKA] = "\u4F60\u53EF\u4EE5\u5F9E\u4F60\u624B\u4E0A\u79FB\u9664\u4E00\u5F35//\u8A5B\u5492\u91D1\u5E63\u4EE5\u5916\u7684\u9322\u5E63\u5361//\u4F86\u5F97\u5230\uFF1A|\uFF0B4 \u5361\u7247|.//%%(\u50B3\u5BB6\u5BF6: \u8A5B\u5492\u91D1\u5E63)%%";
TChineseCardTexts[CardNames.RAIDER] = "\u5176\u4ED6\u624B\u724C\u6709\u4E94\u5F35\u4EE5\u4E0A\u7684//\u73A9\u5BB6\uFF0C\u68C4\u6389\u4E00\u5F35\u540D\u5B57\u8207//\u4F60\u51FA\u724C\u5340\u540D\u5B57\u76F8\u540C\u7684\u5361//\uFF08\u6216\u5C55\u793A\u4ED6\u6C92\u8FA6\u6CD5\uFF09\u3002//\u4F60\u4E0B\u6B21\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019//\uFF0B[3].";
TChineseCardTexts[CardNames.SACRED_GROVE] = "|\uFF0B1 \u8CFC\u8CB7|//|\uFF0B[3]|////\u6536\u5230\u4E00\u500B\u795D\u798F\uFF0C\u5982\u679C\u90A3\u5F35//\u795D\u798F\u4E0D\u63D0\u4F9B\uFF0B[1]\uFF0C\u90A3\u9EBC\u5176//\u4ED6\u73A9\u5BB6\u4E5F\u53EF\u4EE5\u6536\u5230\u5B83\u3002";
TChineseCardTexts[CardNames.SECRET_CAVE] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u4F60\u53EF\u4EE5\u68C4\u6389\u4E09\u5F35\u5361\u3002//\u5982\u679C\u4F60\u68C4\u4E86\uFF0C\u4F60\u4E0B\u6B21\u56DE//\u5408\u958B\u59CB\u7684\u6642\u5019\uFF0C\u4F60|\uFF0B|[3]//%%(\u50B3\u5BB6\u5BF6:\u795E\u71C8)%%";
TChineseCardTexts[CardNames.SHEPHERD] = "|\uFF0B1 \u884C\u52D5|////\u68C4\u6389\u4EFB\u610F\u6578\u91CF\u7684\u5206\u6578\u5361\uFF0C//\u4E26\u4E14\u5C55\u793A\u4ED6\u5011\uFF0C\u6BCF\u68C4\u6389\u4E00//\u5F35\u5361\u7247\u5247|\uFF0B2 \u5361\u7247|\u3002//%%(\u50B3\u5BB6\u5BF6: \u7267\u5834)%%";
TChineseCardTexts[CardNames.SKULK] = "|\uFF0B1 \u8CFC\u8CB7|////\u5176\u4ED6\u73A9\u5BB6\u6536\u5230//\u4E0B\u4E00\u5F35\u5492\u8853\u3002//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\uFF0C//\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\u3002";
TChineseCardTexts[CardNames.TORMENTOR] = "|\uFF0B|[2]////\u7576\u4F60\u53EA\u6709\u6B64\u5361\u5728\u51FA\u724C\u5340\uFF0C//\u5F9E\u5C0F\u9B3C\u724C\u5806\u7372\u5F97\u4E00\u5F35\u5C0F\u9B3C//\uFF0C\u5982\u679C\u4E0D\u53EA\u6B64\u5361\uFF0C\u5176\u4ED6\u73A9//\u5BB6\u6536\u5230\u4E0B\u4E00\u5F35\u5492\u8853\u3002";
TChineseCardTexts[CardNames.TRAGIC_HERO] = "|\uFF0B3 \u5361\u7247|//|\uFF0B1 \u8CFC\u8CB7|//////\u5982\u679C\u4F60\u624B\u724C\u8D85\u904E\u4E03\u5F35\u5361\u7247//\uFF08\u62BD\u724C\u4E4B\u5F8C\uFF09\uFF0C\u79FB\u9664\u6B64\u5361\uFF0C//\u7372\u5F97\u4E00\u5F35\u9322\u5E63\u5361\u3002";
TChineseCardTexts[CardNames.TRACKER] = "|\uFF0B[1]|////\u6B64\u56DE\u5408\u5167\uFF0C\u6BCF\u7576\u4F60\u7372//\u5F97\u5361\u7247\u6642\uFF0C\u4F60\u53EF\u4EE5\u5C07\u90A3\u5F35//\u5361\u7247\u653E\u5230\u4F60\u7684\u724C\u5EAB\u9802\u3002//\u6536\u5230\u4E00\u500B\u795D\u798F\u3002//%%(\u50B3\u5BB6\u5BF6: \u9322\u5305)%%";
TChineseCardTexts[CardNames.VAMPIRE] = "\u5176\u4ED6\u73A9\u5BB6\u6536\u5230\u4E0B\u4E00\u5F35\u5492//\u8853\uFF0C\u4F60\u7372\u5F97\u4E00\u5F35\u50F9//\u503C\u81F3\u591A[5]\u4E14\u4E0D\u662F\u5438\u8840//\u9B3C\u7684\u5361\u7247\uFF0C\u5C07\u6B64\u5361\u548C//\u4E00\u5F35\u8759\u8760\u4EA4\u63DB\u3002";
TChineseCardTexts[CardNames.WEREWOLF] = "\u5982\u679C\u6253\u51FA\u6642\u662F\u4F60\u7684\u591C\u665A\u968E//\u6BB5\uFF0C\u5176\u4ED6\u73A9\u5BB6\u6536\u5230\u4E0B\u4E00\u5F35//\u7684\u5492\u8853\uFF0C\u5728\u5176\u4ED6\u968E\u6BB5\u7684\u8A71\uFF1A//|\uFF0B3 \u5361\u7247|.";
TChineseCardTexts[CardNames.CURSED_GOLD] = "[!3]////\u7372\u5F97\u4E00\u5F35\u8A5B\u5492\uFF0C";
TChineseCardTexts[CardNames.GOAT] = "[!1]////\u4F60\u53EF\u4EE5\u79FB\u9664\u4E00\u5F35\u624B\u724C\u3002";
TChineseCardTexts[CardNames.HAUNTED_MIRROR] = "[!1]//---//\u7576\u4F60\u79FB\u9664\u6B64\u5361\uFF0C\u4F60\u53EF\u4EE5\u68C4\u6389//\u624B\u4E0A\u4E00\u5F35\u884C\u52D5\u5361\uFF0C\u4F86\u7372\u5F97\u9B3C//\u9B42\u724C\u5806\u7684\u4E00\u5F35\u9B3C\u9B42\u3002";
TChineseCardTexts[CardNames.LUCKY_COIN] = "[!1]////\u7372\u5F97\u4E00\u5F35\u9280\u5E63\u3002";
TChineseCardTexts[CardNames.MAGIC_LAMP] = "[!1]////\u8A08\u7B97\u4F60\u51FA\u724C\u5340\u88E1\u540D\u5B57\u53EA//\u51FA\u73FE\u904E\u4E00\u6B21\u7684\u5361\u7247\uFF0C\u5982\u679C\u6709//\u516D\u7A2E\u4EE5\u4E0A\u5247\u79FB\u9664\u6B64\u5361\uFF0C\u5982\u679C\u6709\u79FB\u9664//\uFF0C\u5247\u5F9E\u9858\u671B\u724C\u5806\u7372\u5F97\u4E09\u5F35\u9858\u671B\u3002";
TChineseCardTexts[CardNames.PASTURE] = "[!1]//---//\u4F60\u6BCF\u64C1\u6709\u4E00\u5F35\u838A\u5712\u5C31\u591A{1}\u3002";
TChineseCardTexts[CardNames.POUCH] = "[!1]////|\uFF0B1 \u8CFC\u8CB7|";
TChineseCardTexts[CardNames.BAT] = "\u79FB\u9664\u4F60\u5169\u5F35\u624B\u724C\uFF0C\u5982\u679C\u4F60\u81F3//\u5C11\u79FB\u9664\u4E86\u4E00\u5F35\uFF0C\u5C07\u6B64\u5361\u8207\u4E00//\u5F35\u5438\u8840\u9B3C\u4EA4\u63DB\u3002//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.GHOST] = "\u6301\u7E8C\u5C55\u793A\u4F60\u7684\u724C\u5EAB\uFF0C\u76F4\u5230//\u4F60\u5C55\u793A\u4E86\u884C\u52D5\u5361\uFF0C\u68C4\u6389\u5176//\u4ED6\u7684\u5361\uFF0C\u4E26\u4E14\u5C07\u90A3\u5F35\u884C\u52D5//\u5361\u653E\u5230\u4E00\u908A\uFF0C\u5728\u4F60\u4E0B\u6B21\u56DE//\u548C\u958B\u59CB\u6642\uFF0C\u6253\u51FA\u4E26\u767C\u52D5\u4ED6//\u7684\u6548\u679C\u5169\u6B21\u3002//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.IMP] = "|\uFF0B2 \u5361\u7247|////\u4F60\u53EF\u4EE5\u5F9E\u624B\u4E2D\u6253\u51FA\u4E00\u5F35\u884C//\u52D5\u5361\uFF0C\u4F46\u5FC5\u9808\u6C92\u6709\u8207\u5B83\u540C//\u540D\u7684\u5361\u5728\u51FA\u724C\u5340\u3002//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.WILL_O_WISP] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u5C55\u793A\u4F60\u724C\u5EAB\u9802\u7684\u4E00\u5F35\u5361//\u5982\u679C\u5B83\u50F9\u503C\u4E0D\u9AD8\u65BC[2]//\u5C07\u90A3\u5F35\u5361\u653E\u5165\u624B\u4E2D\u3002//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.WISH] = "|\uFF0B1 \u884C\u52D5|////\u6B78\u9084\u6B64\u5361\u5230\u9858\u671B\u7684\u724C\u5806\uFF0C//\u5982\u679C\u4F60\u6B78\u9084\u4E86\uFF0C\u7372\u5F97\u4E00\u5F35//\u50F9\u503C\u81F3\u591A[6]\u7684\u5361\u5230\u624B\u4E2D\u3002//%(\u6B64\u5361\u4E0D\u5728\u4F9B\u61C9\u5340\u88E1\u3002)%";
TChineseCardTexts[CardNames.ZOMBIE_APPRENTICE] = "\u4F60\u53EF\u4EE5\u79FB\u9664\u4F60\u624B\u4E0A\u7684\u4E00\u5F35//\u884C\u52D5\u5361\u4F86\u63DB\u53D6\uFF1A//|\uFF0B3 \u5361\u7247 |\u548C|\uFF0B1 \u884C\u52D5|.";
TChineseCardTexts[CardNames.ZOMBIE_MASON] = "\u79FB\u9664\u724C\u5EAB\u9802\u7684\u4E00\u5F35\u5361\u7247//\u4F60\u53EF\u4EE5\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3//\u591A\u591A[1]\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.ZOMBIE_SPY] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u89C0\u770B\u4F60\u724C\u5EAB\u9802\u7684\u4E00\u5F35\u5361\u7247//\u4F60\u9078\u64C7\u68C4\u6389\u6216\u662F\u653E\u56DE\u53BB\u3002";
TChineseCardTexts[CardNames.ACTING_TROUPE] = "|\uFF0B4 \u6751\u6C11|//\u79FB\u9664\u6B64\u5361\u3002";
TChineseCardTexts[CardNames.BORDER_GUARD] = "|\uFF0B1 \u884C\u52D5|////\u5C55\u793A\u4F60\u724C\u5EAB\u9802\u7684\u5169\u5F35\u5361\u7247//\uFF0C\u5C07\u5176\u4E2D\u4E00\u5F35\u52A0\u5165\u4F60\u624B\u4E2D//\uFF0C\u68C4\u6389\u5269\u4E0B\u7684\u5361\u7247\u3002\u5982\u679C//\u5C55\u793A\u7684\u5169\u5F35\u90FD\u662F\u884C\u52D5\u5361\uFF0C//\u62FF\u53D6\u71C8\u7C60\u6216\u662F\u865F\u89D2\u3002";
TChineseCardTexts[CardNames.CARGO_SHIP] = "|\uFF0B[2]|////\u6B64\u56DE\u5408\u4E00\u6B21\uFF1A\u4F60\u7372\u5F97\u5361\u7247\u6642\uFF0C//\u4F60\u53EF\u4EE5\u5C07\u5B83\u9762\u671D\u4E0A\u653E\u5230\u4E00\u908A\uFF0C//\u5728\u4F60\u4E0B\u6B21\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\uFF0C//\u5C07\u90A3\u5361\u52A0\u5165\u4F60\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.DUCAT] = "|\uFF0B1 \u5B58\u6B3E|//|\uFF0B1 \u8CFC\u8CB7|//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\uFF0C\u4F60\u53EF\u4EE5//\u79FB\u9664\u4F60\u624B\u4E0A\u7684\u4E00\u5F35\u9285\u5E63\u3002";
TChineseCardTexts[CardNames.EXPERIMENT] = "|\uFF0B2 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//\u5C07\u6B64\u5361\u6B78\u9084\u5230\u4F9B\u61C9\u5340\u3002//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\uFF0C\u4F60\u518D\u7372\u5F97//\u53E6\u4E00\u5F35\u5BE6\u9A57\uFF0C\u6B64\u6548\u679C\u4E0D\u6703//\u518D\u89F8\u767C\u6B64\u6548\u679C\u4E00\u6B21\u3002";
TChineseCardTexts[CardNames.FLAG_BEARER] = "|\uFF0B[2]|//---//\u5982\u679C\u4F60\u7372\u5F97\u6216\u662F\u79FB\u9664\u6B64\u5361\uFF0C//\u62FF\u53D6\u65D7\u5E5F\u3002";
TChineseCardTexts[CardNames.HIDEOUT] = "|\uFF0B1 \u5361\u7247|//|\uFF0B2 \u884C\u52D5|////\u79FB\u9664\u4F60\u624B\u4E0A\u7684\u4E00\u5F35\u5361\u7247\uFF0C//\u5982\u679C\u79FB\u9664\u7684\u662F\u5206\u6578\u5361\uFF0C//\u4F60\u7372\u5F97\u4E00\u5F35\u8A5B\u5492\u3002";
TChineseCardTexts[CardNames.INVENTOR] = "\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A[4]\u7684\u5361\u7247//\uFF0C\u7136\u5F8C\u6B64\u56DE\u5408\u6240\u6709\u5361\u7247\u50F9\u503C//\u964D\u4F4E[1]\u3002";
TChineseCardTexts[CardNames.IMPROVE] = "|\uFF0B[2]|////\u5728\u6E05\u9664\u968E\u6BB5\u958B\u59CB\u7684\u6642\u5019\uFF0C//\u4F60\u53EF\u4EE5\u9078\u64C7\u4E00\u5F35\u51FA\u724C\u5340\u5373//\u5C07\u8981\u88AB\u68C4\u6389\u7684\u884C\u52D5\u5361\uFF0C\u79FB//\u9664\u5B83\uFF0C\u4F60\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u525B//\u597D\u591A[1]\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.LACKEYS] = "|\uFF0B2 \u5361\u7247|//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\u6642\uFF0C//|\uFF0B2 \u6751\u6C11|.";
TChineseCardTexts[CardNames.MOUNTAIN_VILLAGE] = "|\uFF0B2 \u884C\u52D5|////\u89C0\u770B\u4F60\u7684\u68C4\u724C\u5806\uFF0C\u4F60\u5FC5\u9808//\u9078\u64C7\u5176\u4E2D\u4E00\u5F35\u52A0\u5165\u624B\u4E2D\uFF0C//\u5982\u679C\u6C92\u52A0\u5165\u7684\u8A71\u4F60|\uFF0B1 \u5361\u7247|.";
TChineseCardTexts[CardNames.PATRON] = "|\uFF0B1 \u6751\u6C11|//|\uFF0B[2]|//---//\u5982\u679C\u6709\u5361\u7247\u8B93\u4F60\u5728\u884C\u52D5\u968E//\u6BB5\u5C55\u793A\u6B64\u5361\uFF0C\u5247|\uFF0B1 \u5B58\u6B3E|\u3002";
TChineseCardTexts[CardNames.PRIEST] = "|\uFF0B[2]|////\u79FB\u9664\u4F60\u4E00\u5F35\u624B\u724C\uFF0C//\u6B64\u56DE\u5408\u4E4B\u5167\u4F60\u6BCF\u518D//\u79FB\u9664\u4E00\u5F35\u5361\u5C31|\uFF0B|[2]\u3002";
TChineseCardTexts[CardNames.RESEARCH] = "|\uFF0B1 \u884C\u52D5|////\u79FB\u9664\u4F60\u624B\u4E0A\u7684\u4E00\u5F35\u5361\u7247\uFF0C//\u90A3\u5F35\u5361\u6BCF\u50F9\u503C[1]\uFF0C\u4F60\u5C31//\u5F9E\u724C\u5EAB\u9802\u653E\u4E00\u5F35\u5230\u6B64\u5361\u4E0B//\uFF0C\u5728\u4F60\u4E0B\u6B21\u56DE\u5408\u958B\u59CB\u7684\u6642//\u5019\uFF0C\u5C07\u90A3\u4E9B\u5361\u7247\u52A0\u5165\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.SILK_MERCHANT] = "|\uFF0B2 \u5361\u7247|//|\uFF0B1 \u8CFC\u8CB7|//---//\u7576\u4F60\u7372\u5F97\u6216\u662F\u79FB\u9664\u6B64\u5361\u6642\uFF0C//|\uFF0B1 \u5B58\u6B3E| \u548C |\uFF0B1 \u6751\u6C11|\u3002";
TChineseCardTexts[CardNames.OLD_WITCH] = "|\uFF0B3 \u5361\u7247|////\u5176\u4ED6\u73A9\u5BB6\u7372\u5F97\u4E00\u5F35\u8A5B\u5492\u3002//\u5176\u4ED6\u73A9\u5BB6\u53EF\u4EE5\u79FB\u9664\u624B\u724C\u4E2D//\u7684\u4E00\u5F35\u8A5B\u5492\u3002";
TChineseCardTexts[CardNames.RECRUITER] = "|\uFF0B2 \u5361\u7247|////\u79FB\u9664\u4F60\u624B\u4E0A\u7684\u4E00\u5F35\u5361\u7247\uFF0C\u90A3//\u5F35\u6BCF\u50F9\u503C[1]\u4F60\u5C31|\uFF0B1 \u6751\u6C11|\u3002";
TChineseCardTexts[CardNames.SCEPTER] = "\u7576\u4F60\u6253\u51FA\u6B64\u5361\u6642\uFF0C\u9078\u64C7//\u4E00\u500B\uFF1A|\uFF0B|[2]\uFF1B\u6216\u662F\u518D//\u6B21\u767C\u52D5\u4E00\u5F35\u4F60\u51FA\u724C\u5340\u7684//\u884C\u52D5\u5361\uFF0C\u90A3\u5F35\u884C\u52D5\u5361\u5FC5//\u9808\u662F\u9019\u56DE\u5408\u6253\u51FA\u7684\u3002";
TChineseCardTexts[CardNames.SCHOLAR] = "\u68C4\u6389\u4F60\u5168\u90E8\u7684\u624B\u724C\u3002//|\uFF0B7 \u5361\u7247|\u3002";
TChineseCardTexts[CardNames.SCULPTOR] = "\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A[4]\u7684//\u5361\u7247\u5230\u4F60\u624B\u4E2D\uFF0C\u5982\u679C\u5B83\u662F//\u5F35\u9322\u5E63\u5361\uFF1A|\uFF0B1 \u6751\u6C11|\u3002";
TChineseCardTexts[CardNames.SEER] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u5C55\u793A\u4F60\u724C\u5EAB\u9802\u7684\u4E09\u5F35\u5361\u7247\uFF0C//\u5C07\u5176\u4E2D\u50F9\u503C[2]\u5230[4]\u7684\u5361\u7247//\u52A0\u5165\u624B\u4E2D\uFF0C\u5C07\u5269\u4E0B\u7684\u5361\u7247\u4EE5//\u4EFB\u610F\u9806\u5E8F\u653E\u56DE\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.SPICES] = "[!2]////|\uFF0B1 \u8CFC\u8CB7|//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\u6642\uFF0C//|\uFF0B2 \u5B58\u6B3E|\u3002";
TChineseCardTexts[CardNames.SWASHBUCKLER] = "|\uFF0B3 \u5361\u7247|////\u5982\u679C\u4F60\u7684\u68C4\u724C\u5806\u6709\u5361\u7247//\uFF0C\u5247\u4F60 |\uFF0B1 \u5B58\u6B3E|\uFF0C\u5982\u679C//\u4F60\u56E0\u6B64\u6709\u56DB\u5B58\u6B3E\u4EE5\u4E0A\uFF0C//\u5247\u4F60\u62FF\u53D6\u5BF6\u7BB1\u3002";
TChineseCardTexts[CardNames.TREASURER] = "|\uFF0B[3]|////\u9078\u64C7\u4E00\u500B\uFF1A\u79FB\u9664\u4F60\u624B\u4E2D//\u7684\u4E00\u5F35\u9322\u5E63\u5361\uFF1B\u7372\u5F97\u4E00//\u5F35\u79FB\u9664\u724C\u5806\u7684\u9322\u5E63\u5361\u4E26//\u52A0\u5165\u624B\u724C\uFF1B\u62FF\u53D6\u91D1\u9470\u3002";
TChineseCardTexts[CardNames.VILLAIN] = "|\uFF0B2 \u5B58\u6B3E|////\u5176\u4ED6\u624B\u724C\u9084\u6709\u4E94\u5F35\u4EE5\u4E0A\u7684\u73A9\u5BB6//\uFF0C\u4ED6\u5011\u8981\u5F9E\u624B\u4E0A\u68C4\u6389\u4E00\u5F35\u50F9\u503C//[2]\u4EE5\u4E0A\u7684\u5361\u7247\uFF0C(\u6216\u5C55\u793A\u4ED6//\u5011\u6C92\u6709\u8FA6\u6CD5\u68C4\u6389)\u3002";
TChineseCardTexts[CardNames.FLAG] = "\u4F60\u91CD\u65B0\u62BD\u53D6\u4E94\u5F35\u624B\u724C\u7684\u6642\u5019\uFF0C//\u4F60|\uFF0B1 \u5361\u7247|\u3002";
TChineseCardTexts[CardNames.HORN] = "\u6BCF\u56DE\u5408\u4E00\u6B21\uFF0C\u7576\u4F60\u51FA\u724C\u5340\u6709\u908A\u5883\u5B88\u885B\u8981\u88AB\u68C4\u6389//\u7684\u6642\u5019\uFF0C\u4F60\u53EF\u4EE5\u5C07\u90A3\u5F35\u908A\u5883\u5B88\u885B\u653E\u5230\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.KEY] = "\u5728\u4F60\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019|\uFF0B|[1]\u3002";
TChineseCardTexts[CardNames.LANTERN] = "\u4F60\u6253\u51FA\u7684\u908A\u5883\u5B88\u885B\u6539\u70BA\u5C55\u793A\u4E09\u5F35\u5361\u7247\uFF0C\u68C4\u6389//\u5169\u5F35\uFF0C\u5982\u679C\u4E09\u5F35\u90FD\u662F\u884C\u52D5\u5361\u624D\u80FD\u62FF\u53D6\u865F\u89D2\u3002";
TChineseCardTexts[CardNames.TREASURE_CHEST] = "\u5728\u4F60\u8CFC\u8CB7\u968E\u6BB5\u958B\u59CB\u7684\u6642\u5019\uFF0C\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\u3002";
TChineseCardTexts[CardNames.ACADEMY] = "\u7576\u4F60\u7372\u5F97\u4E00\u5F35\u884C\u52D5\u5361\uFF0C\u4F60\u5C31//|\uFF0B1 \u6751\u6C11|\u3002";
TChineseCardTexts[CardNames.BARRACKS] = "\u5728\u4F60\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019|\uFF0B1 \u884C\u52D5|\u3002";
TChineseCardTexts[CardNames.CANAL] = "\u5728\u4F60\u7684\u56DE\u5408\u5167\uFF0C\u6240\u6709\u5361\u7247\u50F9\u503C//\u964D\u4F4E[1]\uFF0C\u4F46\u4E0D\u6703\u4F4E\u65BC[0]\u3002";
TChineseCardTexts[CardNames.CAPITALISM] = "\u5728\u4F60\u7684\u56DE\u5408\u5167\uFF0C\u884C\u52D5\u5361\u4E0A\u6709\uFF0B[]\u7684//\u5361\u7247\u90FD\u5C6C\u65BC\u9322\u5E63\u5361(\u8CFC\u8CB7\u968E\u6BB5\u53EF\u6253\u51FA)\u3002";
TChineseCardTexts[CardNames.CATHEDRAL] = "\u5728\u4F60\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\uFF0C//\u4F60\u5FC5\u9808\u79FB\u9664\u4E00\u5F35\u624B\u724C\u3002";
TChineseCardTexts[CardNames.CITADEL] = "\u6BCF\u7576\u4F60\u56DE\u5408\u7B2C\u4E00\u6B21\u6253\u51FA\u884C\u52D5\u5361\u4E4B\u5F8C//\uFF0C\u518D\u767C\u52D5\u90A3\u5F35\u884C\u52D5\u5361\u7684\u6548\u679C\u4E00\u6B21\u3002";
TChineseCardTexts[CardNames.CITY_GATE] = "\u5728\u4F60\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019|\uFF0B1 \u5361\u7247|\uFF0C//\u7136\u5F8C\u5C07\u4F60\u4E00\u5F35\u624B\u724C\u653E\u56DE\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.CROP_ROTATION] = "\u5728\u4F60\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\uFF0C\u4F60\u53EF\u4EE5//\u68C4\u4E00\u5F35\u5206\u6578\u5361\u4F86\u5F97\u5230|\uFF0B2 \u5361\u7247|\u3002";
TChineseCardTexts[CardNames.EXPLORATION] = "\u5982\u679C\u4F60\u8CFC\u8CB7\u968E\u6BB5\u7D50\u675F\u6642\uFF0C\u8A72\u968E\u6BB5\u6C92\u6709\u7372\u5F97//\u5361\u7247\u7684\u8A71\uFF0C\u4F60|\uFF0B1 \u5B58\u6B3E|\u548C|\uFF0B1 \u6751\u6C11|\u3002";
TChineseCardTexts[CardNames.FAIR] = "\u5728\u4F60\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\uFF0C|\uFF0B1 \u8CFC\u8CB7|\u3002";
TChineseCardTexts[CardNames.FLEET] = "\u904A\u6232\u7D50\u675F\u689D\u4EF6\u89F8\u767C\u5F8C\uFF0C\u904A\u6232\u7E7C\u7E8C\uFF0C\u6709\u8CB7//\u904E\u8239\u968A\u7684\u4EBA\u518D\u591A\u57F7\u884C\u4E00\u500B\u984D\u5916\u7684\u56DE\u5408\u3002";
TChineseCardTexts[CardNames.GUILDHALL] = "\u7576\u4F60\u7372\u5F97\u9322\u5E63\u5361\u7684\u6642\u5019\uFF0C|\uFF0B1 \u5B58\u6B3E|\u3002";
TChineseCardTexts[CardNames.INNOVATION] = "\u6BCF\u56DE\u5408\u4E00\u6B21\uFF1A//\u5982\u679C\u4F60\u7372\u5F97\u4E00\u5F35\u884C\u52D5\u5361\uFF0C\u4F60\u53EF\u4EE5\u6253\u51FA\u5B83\u3002";
TChineseCardTexts[CardNames.PAGEANT] = "\u4F60\u8CFC\u8CB7\u968E\u6BB5\u7D50\u675F\u7684\u6642\u5019\uFF0C//\u4F60\u53EF\u4EE5\u4ED8\u51FA[1]\u4F86\u63DB\u53D6|\uFF0B1 \u5B58\u6B3E|.";
TChineseCardTexts[CardNames.PIAZZA] = "\u5728\u4F60\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\uFF0C\u5C55\u793A\u4F60\u724C\u5EAB\u9802\u7684//\u4E00\u5F35\u5361\u7247\u3002\u5982\u679C\u5B83\u662F\u884C\u52D5\u5361\uFF0C\u6253\u51FA\u5B83\u3002";
TChineseCardTexts[CardNames.ROAD_NETWORK] = "\u5176\u4ED6\u73A9\u5BB6\u7372\u5F97\u5206\u6578\u5361\u7684\u6642\u5019\uFF0C//|\uFF0B1 \u5361\u7247|\u3002";
TChineseCardTexts[CardNames.SEWERS] = "\u7576\u4F60\u4F7F\u7528\u6B64\u8A08\u756B\u4EE5\u5916\u7684\u6548\u679C\u79FB\u9664\u5361\u7247//\u6642\uFF0C\u4F60\u53EF\u4EE5\u79FB\u9664\u4E00\u5F35\u4F60\u624B\u4E0A\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.SILOS] = "\u5728\u4F60\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\uFF0C\u5C55\u793A\u4E26\u68C4\u6389\u4EFB//\u610F\u5F35\u6578\u7684\u9285\u5E63\uFF0C\u62BD\u53D6\u7B49\u91CF\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.SINISTER_PLOT] = "\u5728\u4F60\u56DE\u5408\u958B\u59CB\u6642\uFF1A\u5728\u6B64\u5361\u4E0A\u7D2F\u7A4D\u6307\u793A\u7269\uFF0C\u6216\u79FB//\u9664\u6B64\u5361\u4E0A\u4F60\u7684\u6307\u793A\u7269\uFF0C\u6BCF\u79FB\u4E00\u500B\u4F60\u5C31|\uFF0B1 \u5361\u7247|\u3002";
TChineseCardTexts[CardNames.STAR_CHART] = "\u6BCF\u6B21\u4F60\u91CD\u6D17\u724C\u5EAB\u6642\uFF0C\u4F60\u53EF\u4EE5\u9078//\u64C7\u4E00\u5F35\u5361\u653E\u5230\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.SAUNA] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u4F60\u53EF\u4EE5\u4E0D\u6D88\u8017\u884C\u52D5\u5730//\u6253\u51FA\u624B\u4E0A\u7684\u51B0\u6E56\u3002//\u6B64\u56DE\u5408\u6BCF\u7576\u4F60\u6253\u51FA\u4E00\u5F35\u9280\u5E63//\uFF0C\u4F60\u5C31\u53EF\u4EE5\u79FB\u9664\u4E00\u5F35\u624B\u724C\u3002";
TChineseCardTexts[CardNames.AVANTO] = "|\uFF0B3 \u5361\u7247|////\u4F60\u53EF\u4EE5\u4E0D\u6D88\u8017\u884C\u52D5//\u5730\u6253\u51FA\u6851\u62FF\u3002";
TChineseCardTexts[CardNames.BLACK_MARKET] = "|\uFF0B[2]|////\u7FFB\u958B\u9ED1\u5E02\u724C\u5806\u4E0A\u7684\u4E09\u5F35\u724C\uFF0C\u4F60//\u53EF\u4EE5\u7ACB\u5373\u8CFC\u8CB7\u5176\u4E2D\u4E00\u5F35\uFF08\u4E0D\u8A08//\u5165\u672C\u56DE\u5408\u7684\u6B63\u5E38\u8CFC\u8CB7\u5F35\u6578\uFF09\u3002//\u5C07\u672A\u8CFC\u8CB7\u7684\u724C\u4EE5\u4EFB\u610F\u9806\u5E8F\u653E\u56DE//\u9ED1\u5E02\u724C\u5806\u5E95\u90E8\u3002//---//\u5728\u904A\u6232\u958B\u59CB\u524D\uFF0C\u5F9E\u672A\u4F7F\u7528\u7684\u738B\u570B\u5361//\uFF0C\u6BCF\u758A\u62BD\u51FA\u4E00\u5F35\uFF0C\u7D44\u6210\u9ED1\u5E02\u724C\u5806\u3002";
TChineseCardTexts[CardNames.ENVOY] = "\u5C55\u793A\u724C\u5EAB\u9802\u4E94\u5F35\u5361\u7247\uFF0C\u4F60\u5DE6//\u624B\u908A\u7684\u73A9\u5BB6\u9078\u64C7\u4E00\u5F35\u68C4\u6389\uFF0C//\u5C07\u5269\u4E0B\u7684\u5361\u7247\u653E\u5230\u4F60\u624B\u4E2D\u3002";
TChineseCardTexts[CardNames.GOVERNOR] = "|\uFF0B1 \u884C\u52D5|////\u9078\u64C7\u4E00\u500B\u6548\u679C\uFF0C\u4F60\u7372\u5F97\u62EC\u865F\u88E1//\u9762\u7684\u6548\u679C\uFF1A\u6240\u6709\u73A9\u5BB6|\uFF0B1(\uFF0B3)|//|\u5361\u7247|\uFF1B\u6240\u6709\u73A9\u5BB6\u7372\u5F97\u4E00\u5F35//\u9280\u5E63(\u9EC3\u91D1)\uFF1B\u6240\u6709\u73A9\u5BB6\u53EF\u4EE5\u79FB//\u9664\u4E00\u5F35\u624B\u724C\uFF0C\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u525B//\u597D\u52A0[1]([2])\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.PRINCE] = "\u4F60\u53EF\u4EE5\u5F9E\u624B\u724C\u9078\u4E00\u5F35\u50F9\u503C\u81F3//\u591A[4]\u884C\u52D5\u5361\uFF0C\u4F46\u4E0D\u80FD\u662F\u6301\u7E8C//\u5361\u4E5F\u4E0D\u80FD\u662F\u547D\u4EE4\u5361\u3002\u5C07\u8A72\u5361//\u8207\u6B64\u5361\u653E\u5230\u4E00\u908A\uFF0C\u5728\u4F60\u56DE\u5408//\u958B\u59CB\u7684\u6642\u5019\uFF0C\u767C\u52D5\u90A3\u5F35\u884C\u52D5//\u5361\u7684\u6548\u679C\uFF0C\u4E26\u4E14\u8A72\u884C\u52D5\u5361\u4E0D//\u80FD\u88AB\u79FB\u52D5\u3002";
TChineseCardTexts[CardNames.STASH] = "[!2]//---//\u5728\u724C\u5EAB\u91CD\u6D17\u7684\u6642\u5019\uFF0C\u4F60\u53EF\u4EE5//\u5C07\u724C\u5EAB\u88E1\u7684\u6B64\u5361\u653E\u5728\u4EFB\u610F\u7684//\u4F4D\u7F6E\u3002";
TChineseCardTexts[CardNames.SUMMON] = "\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A[4]\u7684\u884C\u52D5\u5361\uFF0C\u5C07\u5B83\u653E\u5230\u4E00\u908A\uFF0C//\u5982\u679C\u4F60\u6709\u653E\u4E00\u908A\uFF0C\u5728\u4F60\u7684\u4E0B\u4E00\u56DE\u5408\u958B\u59CB\u6642\u6253\u51FA\u5B83\u3002";
TChineseCardTexts[CardNames.WALLED_VILLAGE] = "|\uFF0B1 \u5361\u7247|//|\uFF0B2 \u884C\u52D5|//---//\u5728\u6E05\u9664\u968E\u6BB5\uFF0C\u5982\u679C\u4F60\u9084\u6709\u6B64//\u5361\u548C\u4E0D\u8D85\u904E\u4E00\u5F35\u7684\u884C\u52D5\u5361\u5728//\u51FA\u724C\u5340\uFF0C\u4F60\u53EF\u4EE5\u5C07\u6B64\u5361\u653E\u5230//\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.BLACK_MARKET_PILE] = "";
TChineseCardTexts[CardNames.DISMANTLE] = "\u79FB\u9664\u4F60\u624B\u4E0A\u4E00\u5F35\u5361\u3002\u5982//\u679C\u5B83\u50F9\u503C[1]\u4EE5\u4E0A\uFF0C\u7372\u5F97//\u4E00\u5F35\u8F03\u4FBF\u5B9C\u7684\u5361\u548C\u9EC3\u91D1\u3002";
TChineseCardTexts[CardNames.CAPTAIN] = "\u73FE\u5728\u548C\u4E0B\u6B21\u4F60\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\uFF1A//\u9078\u64C7\u4F9B\u61C9\u5340\u88E1\u7684\u4E00\u5F35\u50F9\u503C[4]\u4EE5\u4E0B//\u7684\u884C\u52D5\u5361\uFF0C\u4F46\u4E0D\u80FD\u662F\u6301\u7E8C\u5361\uFF0C\u767C//\u52D5\u8A72\u5361\u6548\u679C\uFF0C\u767C\u52D5\u6548\u679C\u6642\u4E0D\u80FD\u79FB//\u52D5\u8A72\u5361\u3002(\u4E0D\u80FD\u9078\u64C7\u547D\u4EE4\u5361)";
TChineseCardTexts[CardNames.CHURCH] = "|\uFF0B1 \u884C\u52D5|////\u5C07\u624B\u4E0A\u81F3\u591A\u4E09\u5F35\u5361\u9762\u671D\u4E0B//\u653E\u5230\u4E00\u908A\uFF0C\u5728\u4F60\u4E0B\u6B21\u56DE\u5408//\u958B\u59CB\u7684\u6642\u5019\uFF0C\u5C07\u4ED6\u5011\u52A0\u5165//\u624B\u724C\u4E2D\uFF0C\u4F60\u53EF\u4EE5\u79FB\u9664\u4E00\u5F35//\u624B\u724C\u3002";
TChineseCardTexts[CardNames.BLACK_CAT] = "|\uFF0B2 \u5361\u7247|//\u5982\u679C\u73FE\u5728\u4E0D\u662F\u4F60\u7684\u56DE\u5408\uFF0C//\u5176\u4ED6\u73A9\u5BB6\u7372\u5F97\u4E00\u500B\u8A5B\u5492\u3002//---//\u7576\u5176\u4ED6\u73A9\u5BB6\u7372\u5F97\u5206//\u6578\u5361\u6642\uFF0C\u4F60\u53EF\u4EE5\u6253//\u51FA\u624B\u4E2D\u7684\u6B64\u5361\u3002";
TChineseCardTexts[CardNames.SLEIGH] = "\u7372\u5F97\u5169\u5F35\u99AC\u3002//---//\u7576\u4F60\u7372\u5F97\u5361\u7247\u6642\uFF0C\u4F60//\u53EF\u4EE5\u68C4\u6389\u624B\u4E2D\u7684\u6B64\u5361//\uFF0C\u5C07\u90A3\u5F35\u5361\u7247\u653E\u5165\u4F60//\u624B\u724C\u4E2D\uFF0C\u6216\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.SUPPLIES] = "[!1]////\u7576\u4F60\u6253\u51FA\u6B64\u5361\uFF0C\u7372\u5F97\u4E00//\u5F35\u99AC\uFF0C\u4E26\u653E\u5230\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.CAMEL_TRAIN] = "\u9A45\u9010\u4F9B\u61C9\u5340\u4E2D\u5206\u6578//\u5361\u4EE5\u5916\u7684\u4E00\u5F35\u5361\u3002//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\uFF0C\u5F9E\u4F9B//\u61C9\u5340\u9A45\u9010\u4E00\u5F35\u9EC3\u91D1\u3002";
TChineseCardTexts[CardNames.GOATHERD] = "|\uFF0B1 \u884C\u52D5|////\u4F60\u53EF\u4EE5\u79FB\u9664\u624B//\u4E2D\u7684\u4E00\u5F35\u5361\u3002////\u4F60\u53F3\u624B\u908A\u7684\u73A9\u5BB6\u4E0A\u6B21//\u56DE\u5408\u4E2D\u6BCF\u79FB\u9664\u4E00\u5F35\u5361//\u7247\uFF0C\u4F60\u5C31|\uFF0B1 \u5361\u7247|\u3002";
TChineseCardTexts[CardNames.SCRAP] = "\u79FB\u9664\u4F60\u624B\u4E2D\u7684\u4E00\u5F35\u5361\u3002\u90A3//\u5F35\u5361\u6BCF\u50F9\u503C[1]\uFF0C\u4F60\u5C31\u591A\u9078//\u64C7\u4E00\u9805\u4E0D\u540C\u7684\uFF1A|\uFF0B1 \u5361\u7247|;//|\uFF0B1 \u884C\u52D5|; |\uFF0B1 \u8CFC\u8CB7|; |\uFF0B|[1];//\u7372\u5F97\u4E00\u5F35\u9280\u5E63; \u7372\u5F97\u4E00\u5F35\u99AC\u3002";
TChineseCardTexts[CardNames.SHEEPDOG] = "|\uFF0B2 \u5361\u7247|//---//\u7576\u4F60\u7372\u5F97\u5361\u7247\u6642\uFF0C//\u4F60\u53EF\u4EE5\u5F9E\u624B\u4E2D\u6253\u51FA//\u6B64\u5361\u3002";
TChineseCardTexts[CardNames.SNOWY_VILLAGE] = "|\uFF0B1 \u5361\u7247|//|\uFF0B4 \u884C\u52D5|//|\uFF0B1 \u8CFC\u8CB7|////\u672C\u56DE\u5408\u4E0D\u6703\u518D\u5F97\u5230\u4EFB\u4F55//|\uFF0B \u884C\u52D5|\u3002";
TChineseCardTexts[CardNames.STOCKPILE] = "[!3]////|\uFF0B1 \u8CFC\u8CB7|////\u7576\u4F60\u6253\u51FA\u6B64\u5361\uFF0C\u9A45\u9010\u6B64\u5361\u3002";
TChineseCardTexts[CardNames.BOUNTY_HUNTER] = "|\uFF0B1 \u884C\u52D5|////\u9A45\u9010\u4F60\u624B\u4E2D\u7684\u4E00\u5F35\u5361\u3002//\u5982\u679C\u4F60\u9A45\u9010\u7684\u5361\u7247\u4E2D\u6C92\u6709//\u8207\u90A3\u5F35\u540C\u540D\u7684\uFF0C\u5247|\uFF0B|[3]\u3002";
TChineseCardTexts[CardNames.CARDINAL] = "|\uFF0B[2]|////\u5176\u4ED6\u73A9\u5BB6\u5C55\u793A\u724C\u5EAB\u9802//\u7684\u5169\u5F35\u5361\uFF0C\u9A45\u9010\u4E00\u5F35//\u50F9\u503C[3]\u5230[6]\u7684\u5361\u7247//\uFF0C\u5C07\u5269\u9918\u7684\u5361\u68C4\u6389\u3002";
TChineseCardTexts[CardNames.CAVALRY] = "\u7372\u5F97\u5169\u5F35\u99AC\u3002//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\u6642\uFF0C//|\uFF0B2 \u5361\u7247|, |\uFF0B1 \u8CFC\u8CB7|\u3002//\u5982\u679C\u73FE\u5728\u662F\u4F60\u7684\u8CFC\u8CB7\u968E\u6BB5//\uFF0C\u5247\u56DE\u5230\u4F60\u7684\u884C\u52D5\u968E\u6BB5\u3002";
TChineseCardTexts[CardNames.GROOM] = "\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A[4]\u7684//\u5361\u7247\uFF0C\u5982\u679C\u90A3\u662F\u5F35\uFF1B////\u884C\u52D5\u5361\uFF1A\u7372\u5F97\u4E00\u5F35\u99AC\u3002        //\u9322\u5E63\u5361\uFF1A\u7372\u5F97\u4E00\u5F35\u9280\u5E63        //\u5206\u6578\u5361\uFF1A|\uFF0B1 \u5361\u7247|\u4E26|\uFF0B1 \u884C\u52D5\u3002|//";
TChineseCardTexts[CardNames.HOSTELRY] = "|\uFF0B1 \u5361\u7247|//|\uFF0B2 \u884C\u52D5|//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\uFF0C\u4F60//\u53EF\u4EE5\u68C4\u6389\u4EFB\u610F\u5F35\u6578//\u7684\u9322\u5E63\u5361\uFF0C\u4F86\u7372\u5F97//\u76F8\u540C\u6578\u91CF\u7684\u99AC\u3002";
TChineseCardTexts[CardNames.VILLAGE_GREEN] = "\u73FE\u5728\u6216\u662F\u4E0B\u500B\u56DE\u5408//\u958B\u59CB\u7684\u6642\u5019\u5F97\u5230\uFF1A//|\uFF0B1 \u5361\u7247| \u548C |\uFF0B2 \u884C\u52D5\u3002| //---//\u7576\u4F60\u5728\u6E05\u9664\u968E\u6BB5\u4EE5\u5916//\u7684\u6642\u5019\u5C07\u6B64\u5361\u68C4\u6389\uFF0C//\u4F60\u53EF\u4EE5\u5C07\u6B64\u5361\u6253\u51FA\u3002";
TChineseCardTexts[CardNames.BARGE] = "\u73FE\u5728\u6216\u662F\u4E0B\u500B\u56DE\u5408//\u958B\u59CB\u7684\u6642\u5019\u5F97\u5230\uFF1A//|\uFF0B3 \u5361\u7247| \u548C |\uFF0B1 \u8CFC\u8CB7\u3002| ";
TChineseCardTexts[CardNames.COVEN] = "|\uFF0B1 \u884C\u52D5|//|\uFF0B[2]|////\u5176\u4ED6\u73A9\u5BB6\u9A45\u9010\u4E00\u5F35\u4F9B\u61C9//\u5340\u7684\u8A5B\u5492\uFF0C\u4F46\u5982\u679C\u6709\u4EBA//\u6C92\u8FA6\u6CD5\u9A45\u9010\u8A5B\u5492\uFF0C\u4ED6\u68C4//\u6389\u5168\u90E8\u88AB\u9A45\u9010\u7684\u8A5B\u5492\u3002";
TChineseCardTexts[CardNames.DISPLACE] = "\u9A45\u9010\u4E00\u5F35\u4F60\u624B\u4E0A\u7684\u5361\u7247\u3002//\u7372\u5F97\u4E00\u5F35\u4E0D\u540C\u540D\uFF0C\u4E14\u50F9\u503C//\u81F3\u591A\u591A[2]\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.FALCONER] = "\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u6BD4\u6B64\u5361\u4F4E//\u7684\u5361\u7247\uFF0C\u4E26\u52A0\u5165\u624B\u4E2D\u3002//---//\u7576\u4EFB\u4F55\u73A9\u5BB6\u7372\u5F97\u7684\u5361\u7247\u6709//\u5169\u7A2E\u6216\u4EE5\u4E0A (\u884C\u52D5\u5361\u6216\u653B//\u64CA\u5361\u4E4B\u985E\u7684) \uFF0C\u4F60\u53EF\u4EE5\u6253//\u51FA\u624B\u4E2D\u7684\u6B64\u5361\u3002";
TChineseCardTexts[CardNames.FISHERMAN] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//|\uFF0B[1]|//---//\u5728\u4F60\u56DE\u5408\u4E2D\uFF0C\u5982\u679C\u4F60//\u7684\u68C4\u724C\u5806\u6C92\u6709\u5361\u7247\uFF0C//\u6B64\u5361\u50F9\u503C\u964D\u4F4E[3]\u3002 ";
TChineseCardTexts[CardNames.GATEKEEPER] = "\u5728\u4F60\u4E0B\u6B21\u56DE\u5408\u958B\u59CB\u4E4B\u524D//\uFF0C\u4F60|\uFF0B|[3]\uFF0C\u5728\u9019\u671F\u9593\uFF0C//\u5176\u4ED6\u73A9\u5BB6\u7372\u5F97\u884C\u52D5\u5361\u6216//\u9322\u5E63\u5361\u7684\u6642\u5019\uFF0C\u5982\u679C\u4ED6//\u6C92\u6709\u9A45\u9010\u540C\u540D\u7684\u5361\uFF0C\u5247//\u4ED6\u9A45\u9010\u6389\u4ED6\u7372\u5F97\u7684\u5361\u3002";
TChineseCardTexts[CardNames.HUNTING_LODGE] = "|\uFF0B1 \u5361\u7247|//|\uFF0B2 \u884C\u52D5|////\u4F60\u53EF\u4EE5\u68C4\u6389\u624B\u724C\uFF0C//\u4F86\u63DB\u53D6|\uFF0B5 \u5361\u7247|\u3002";
TChineseCardTexts[CardNames.KILN] = "|\uFF0B[2]|////\u672C\u56DE\u5408\u4E0B\u6B21\u4F60\u767C\u52D5\u5361\u7247\u6548//\u679C\u6642\uFF0C\u4F60\u53EF\u4EE5\u5148\u7372\u5F97\u4E00\u5F35//\u540C\u540D\u7684\u5361\u7247\u518D\u767C\u52D5\u6548\u679C\u3002";
TChineseCardTexts[CardNames.LIVERY] = "|\uFF0B[3]|////\u9019\u56DE\u5408\u4E4B\u5F8C\uFF0C\u4F60\u6BCF\u6B21\u7372//\u5F97\u50F9\u503C[4]\u4EE5\u4E0A\u7684\u5361\u7247//\u6642\uFF0C\u4F60\u7372\u5F97\u4E00\u5F35\u99AC\u3002";
TChineseCardTexts[CardNames.MASTERMIND] = "\u5728\u4F60\u4E0B\u6B21\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\uFF0C//\u4F60\u53EF\u4EE5\u5F9E\u624B\u4E2D\u6253\u51FA\u4E00\u5F35\u884C\u52D5//\u5361\uFF0C\u4E26\u767C\u52D5\u4E09\u6B21\u6548\u679C\u3002";
TChineseCardTexts[CardNames.PADDOCK] = "|\uFF0B[2]|////\u7372\u5F97\u5169\u5F35\u99AC\u3002////\u4F9B\u61C9\u5340\u6BCF\u6709\u4E00\u758A\u7A7A\u7684\u724C//\u5806\uFF0C\u4F60\u5C31|\uFF0B1 \u884C\u52D5|\u3002";
TChineseCardTexts[CardNames.SANCTUARY] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//|\uFF0B1 \u8CFC\u8CB7|////\u4F60\u53EF\u4EE5\u9A45\u9010\u4F60\u624B//\u4E0A\u7684\u4E00\u5F35\u5361\u3002";
TChineseCardTexts[CardNames.DESTRIER] = "|\uFF0B2 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//---//\u5728\u4F60\u7684\u56DE\u5408\u4E2D\uFF0C\u4F60//\u6BCF\u7372\u5F97\u4E00\u5F35\u5361\uFF0C\u672C//\u5361\u50F9\u503C\u964D\u4F4E[1]\u3002 ";
TChineseCardTexts[CardNames.WAYFARER] = "|\uFF0B3 \u5361\u7247|////\u4F60\u53EF\u4EE5\u7372\u5F97\u4E00\u5F35\u9280\u5E63\u3002//---//\u672C\u5361\u7684\u50F9\u683C\u70BA\uFF1A//\u672C\u56DE\u5408\u4E0A\u4E00\u5F35\u88AB\u7372\u5F97\u7684//\u5361\u7247\u50F9\u683C\u3002(\u6709\u7684\u8A71)";
TChineseCardTexts[CardNames.ANIMAL_FAIR] = "|\uFF0B[4]|////\u4F9B\u61C9\u5340\u6BCF\u6709\u4E00\u758A\u7A7A\u7684\u724C//\u5806\uFF0C\u4F60\u5C31|\uFF0B1 \u8CFC\u8CB7|\u3002//---//\u4F60\u53EF\u4EE5\u79FB\u9664\u624B\u4E2D\u7684//\u4E00\u5F35\u884C\u52D5\u5361\uFF0C\u4F86\u4EE3//\u66FF\u6B64\u5361\u7684\u50F9\u683C\u3002";
TChineseCardTexts[CardNames.HORSE] = "|\uFF0B2 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u5C07\u6B64\u5361\u653E\u56DE\u99AC\u7684\u724C\u5806\u4E2D\u3002";
TChineseCardTexts[CardNames.WAY_OF_THE_BUTTERFLY] = "\u4F60\u53EF\u4EE5\u6B78\u9084\u6B64\u5361\uFF0C\u4F86\u7372\u5F97\u4E00\u5F35//\u50F9\u503C\u6BD4\u6B64\u5361\u525B\u597D\u52A0[1]\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.WAY_OF_THE_CAMEL] = "\u9A45\u9010\u4F9B\u61C9\u5340\u7684\u4E00\u5F35\u9EC3\u91D1\u3002";
TChineseCardTexts[CardNames.WAY_OF_THE_CHAMELEON] = "\u6839\u64DA\u539F\u672C\u6B64\u5361\u7684\u6548\u679C\u52D5\u4F5C\uFF1B\u4F46\u6BCF//\u6B21\u90A3\u5F35\u5361\u9019\u56DE\u5408\u8981\u63D0\u4F9B\u4F60|\uFF0B\u5361\u7247|//\u6642\uFF0C\u4F60\u6539\u70BA|\uFF0B|[]\uFF0C\u53CD\u4E4B\u4EA6\u7136\u3002 ";
TChineseCardTexts[CardNames.WAY_OF_THE_FROG] = "|\uFF0B1 \u884C\u52D5|////\u7576\u4F60\u9019\u56DE\u5408\u5F9E\u51FA\u724C\u5340\u5C07\u6B64\u5361\u68C4\u6389\u6642\uFF0C//\u5C07\u6B64\u5361\u653E\u56DE\u4F60\u7684\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.WAY_OF_THE_GOAT] = "\u79FB\u9664\u4F60\u624B\u4E0A\u7684\u4E00\u5F35\u5361\u3002";
TChineseCardTexts[CardNames.WAY_OF_THE_HORSE] = "|\uFF0B2 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|////\u5C07\u6B64\u5361\u6B78\u9084\u5230\u8A72\u56DE\u7684\u5730\u65B9\u3002";
TChineseCardTexts[CardNames.WAY_OF_THE_MOLE] = "|\uFF0B1 \u884C\u52D5|////\u68C4\u6389\u4F60\u7684\u624B\u724C\u5F8C\uFF0C|\uFF0B3 \u5361\u7247|";
TChineseCardTexts[CardNames.WAY_OF_THE_MONKEY] = "|\uFF0B1 \u8CFC\u8CB7|//|\uFF0B[1]|";
TChineseCardTexts[CardNames.WAY_OF_THE_MOUSE] = "\u767C\u52D5\u7530\u9F20\u653E\u5230\u4E00\u908A\u7684\u5361\uFF0C\u767C\u52D5\u6548\u679C\u6642\u4E0D\u80FD\u79FB\u52D5\u5B83\u3002//---//\u8D77\u59CB: \u5C07\u4E00\u5F35\u4E14\u50F9\u503C[2]\u6216[3]\u7684\u884C\u52D5\u5361\u653E\u5230\u4E00\u908A\u3002";
TChineseCardTexts[CardNames.WAY_OF_THE_MULE] = "|\uFF0B1 \u884C\u52D5|//|\uFF0B[1]|";
TChineseCardTexts[CardNames.WAY_OF_THE_OTTER] = "|\uFF0B2 \u5361\u7247|";
TChineseCardTexts[CardNames.WAY_OF_THE_OWL] = "\u6301\u7E8C\u62BD\u5361\uFF0C\u76F4\u5230\u4F60\u624B\u724C\u6709\u516D\u5F35\u5361\u7247\u3002";
TChineseCardTexts[CardNames.WAY_OF_THE_OX] = "|\uFF0B2 \u884C\u52D5|";
TChineseCardTexts[CardNames.WAY_OF_THE_PIG] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|";
TChineseCardTexts[CardNames.WAY_OF_THE_RAT] = "\u4F60\u53EF\u4EE5\u68C4\u6389\u4E00\u5F35\u9322\u5E63\u5361//\u4F86\u7372\u5F97\u8207\u6B64\u5361\u540C\u540D\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.WAY_OF_THE_SEAL] = "|\uFF0B[1]|////\u672C\u56DE\u5408\u4E2D\uFF0C\u4F60\u53EF\u4EE5\u5C07\u4F60\u7372\u5F97\u7684//\u5361\u7247\u653E\u5230\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.WAY_OF_THE_SHEEP] = "|\uFF0B[2]|";
TChineseCardTexts[CardNames.WAY_OF_THE_SQUIRREL] = " \u5728\u672C\u56DE\u5408\u7D50\u675F\u7684\u6642\u5019|\uFF0B2 \u5361\u7247|\u3002";
TChineseCardTexts[CardNames.WAY_OF_THE_TURTLE] = "\u5C07\u6B64\u5361\u653E\u5230\u4E00\u908A\uFF0C\u5982\u679C\u6210\u529F\u653E\u5230\u4E00\u908A//\u5728\u4F60\u4E0B\u6B21\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\u6253\u51FA\u6B64\u5361\u3002";
TChineseCardTexts[CardNames.WAY_OF_THE_WORM] = "\u9A45\u9010\u4F9B\u61C9\u5340\u7684\u4E00\u5F35\u838A\u5712\u3002";
TChineseCardTexts[CardNames.DELAY] = "\u4F60\u53EF\u4EE5\u5C07\u4F60\u624B\u4E2D\u7684\u4E00\u5F35\u884C\u52D5\u5361\u653E\u5230\u4E00\u908A//\uFF0C\u5728\u4E0B\u6B21\u4F60\u56DE\u5408\u958B\u59CB\u7684\u6642\u5019\uFF0C\u6253\u51FA\u5B83\u3002";
TChineseCardTexts[CardNames.DESPERATION] = "\u6BCF\u56DE\u5408\u4E00\u6B21\uFF1A\u4F60\u53EF\u4EE5\u7372\u5F97\u4E00\u5F35\u8A5B\u5492\uFF0C//\u5982\u679C\u4F60\u7372\u5F97\u4E86|\uFF0B1 \u8CFC\u8CB7| \u4E26 |\uFF0B|[2].";
TChineseCardTexts[CardNames.GAMBLE] = "|\uFF0B1 \u8CFC\u8CB7|////\u5C55\u793A\u4F60\u724C\u5EAB\u9802\u7684\u5361\uFF0C\u5982\u679C\u662F\u9322\u5E63\u5361\u6216\u884C\u52D5//\u5361\uFF0C\u4F60\u53EF\u4EE5\u6253\u51FA\u5B83\uFF0C\u5982\u679C\u4E0D\u662F\u5247\u68C4\u6389\u3002";
TChineseCardTexts[CardNames.PURSUE] = "|\uFF0B1 \u8CFC\u8CB7|////\u6307\u5B9A\u4E00\u5F35\u5361\u7684\u540D\u5B57\uFF0C\u5C55\u793A\u4F60\u724C\u5EAB\u9802\u7684\u56DB//\u5F35\u5361\uFF0C\u5C07\u76F8\u540C\u7684\u653E\u56DE\u53BB\uFF0C\u5C07\u5176\u5B83\u68C4\u6389\u3002";
TChineseCardTexts[CardNames.RIDE] = "\u7372\u5F97\u4E00\u5F35\u99AC\u3002";
TChineseCardTexts[CardNames.TOIL] = "|\uFF0B1 \u8CFC\u8CB7|////\u4F60\u53EF\u4EE5\u5F9E\u4F60\u624B\u4E2D\u6253\u51FA\u4E00\u5F35\u884C\u52D5\u5361\u3002";
TChineseCardTexts[CardNames.ENHANCE] = "\u4F60\u53EF\u4EE5\u5F9E\u4F60\u624B\u4E2D\u79FB\u9664\u4E00\u5F35\u975E\u5206\u6578\u5361//\uFF0C\u4F86\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A\u591A[2]\u7684\u5361\u3002";
TChineseCardTexts[CardNames.MARCH] = "\u67E5\u770B\u4F60\u7684\u68C4\u724C\u5806\uFF0C\u4F60\u53EF//\u6253\u51FA\u88E1\u9762\u7684\u4E00\u5F35\u884C\u52D5\u5361\u3002";
TChineseCardTexts[CardNames.TRANSPORT] = "\u9078\u64C7\u4E00\u500B\uFF1A\u9A45\u9010\u4E00\u5F35\u4F9B\u61C9\u5340//\u7684\u884C\u52D5\u5361\uFF1B\u6216\u5C07\u4E00\u5F35\u4F60\u9A45\u9010//\u7684\u5361\u7247\u653E\u56DE\u4F60\u7684\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.BANISH] = "\u9078\u64C7\u624B\u4E0A\u4EFB\u610F\u6578\u91CF\u7684//\u540C\u540D\u5361\uFF0C\u9A45\u9010\u5B83\u5011\u3002";
TChineseCardTexts[CardNames.BARGAIN] = "\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3\u591A[5]\u7684\u975E\u5206//\u6578\u5361\uFF0C\u5176\u4ED6\u73A9\u5BB6\u7372\u5F97\u4E00\u5F35\u99AC\u3002";
TChineseCardTexts[CardNames.INVEST] = "\u9A45\u9010\u4E00\u5F35\u4F9B\u61C9\u5340\u4E2D\u7684\u884C\u52D5\u5361\u3002\u7576\u90A3\u5F35\u5361//\u9084\u5728\u9A45\u9010\u4E2D\uFF0C\u7576\u5176\u4ED6\u73A9\u5BB6\u7372\u5F97\u540C\u540D\u5361\uFF0C\u6216//\u5176\u4ED6\u73A9\u5BB6\u5171\u540C\u6295\u8CC7\u7684\u6642\u5019\uFF0C\u4F60|\uFF0B2 \u5361\u7247|.";
TChineseCardTexts[CardNames.SEIZE_THE_DAY] = "\u4E00\u5834\u904A\u6232\u4E00\u6B21\uFF1A//\u5728\u9019\u56DE\u5408\u7D50\u675F\u5F8C\uFF0C\u4F60\u591A\u4E00\u500B\u56DE\u5408\u3002";
TChineseCardTexts[CardNames.COMMERCE] = "\u6839\u64DA\u4F60\u9019\u56DE\u5408\u7372\u5F97\u7684\u5361\uFF0C\u6BCF\u6709\u4E00\u5F35//\u4E0D\u540C\u540D\u5B57\u7684\uFF0C\u4F60\u5C31\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\u3002";
TChineseCardTexts[CardNames.DEMAND] = "\u7372\u5F97\u4E00\u5F35\u99AC\uFF0C\u7372\u5F97\u4E00\u5F35\u50F9\u503C\u81F3//\u591A[4]\u7684\u5361\u7247\uFF0C\u4E26\u90FD\u653E\u5230\u724C\u5EAB\u9802\u3002";
TChineseCardTexts[CardNames.STAMPEDE] = "\u5982\u679C\u4F60\u51FA\u724C\u5340\u53EA\u6709\u4E94\u5F35\u4EE5\u4E0B\u7684\u5361\u7247//\uFF0C\u7372\u5F97\u4E94\u5F35\u99AC\u5230\u4F60\u7684\u724C\u5EAB\u9802\u4E0A\u3002";
TChineseCardTexts[CardNames.REAP] = "\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\uFF0C\u5C07\u90A3\u9EC3\u91D1\u653E\u5230\u4E00\u908A\uFF0C//\u5982\u679C\u6210\u529F\u653E\u5230\u4E00\u908A\uFF0C\u4E0B\u56DE\u5408\u6253\u51FA\u5B83\u3002";
TChineseCardTexts[CardNames.ENCLAVE] = "\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\uFF0C\u9A45\u9010\u4E00\u5F35\u516C\u570B\u3002";
TChineseCardTexts[CardNames.ALLIANCE] = "\u7372\u5F97\u4E00\u5F35\u884C\u7701\u3001\u516C\u570B\u3001\u838A\u5712\u3001//\u9EC3\u91D1\uFF0C\u9280\u5E63\uFF0C\u9285\u5E63\u3002";
TChineseCardTexts[CardNames.POPULATE] = "\u5F9E\u4F9B\u61C9\u5340\u4E2D\uFF0C\u6BCF\u758A\u884C\u52D5\u5361\u5404\u7372\u5F97\u4E00\u5F35\u3002";
TChineseCardTexts[CardNames.CARD_OF_THE_BUTTERFLY] = "";
TChineseCardTexts[CardNames.CARD_OF_THE_CAMEL] = "";
TChineseCardTexts[CardNames.CARD_OF_THE_CHAMELEON] = "";
TChineseCardTexts[CardNames.CARD_OF_THE_FROG] = "";
TChineseCardTexts[CardNames.CARD_OF_THE_GOAT] = "";
TChineseCardTexts[CardNames.CARD_OF_THE_HORSE] = "";
TChineseCardTexts[CardNames.CARD_OF_THE_MOLE] = "";
TChineseCardTexts[CardNames.CARD_OF_THE_MONKEY] = "";
TChineseCardTexts[CardNames.CARD_OF_THE_MOUSE] = "";
TChineseCardTexts[CardNames.CARD_OF_THE_MULE] = "";
TChineseCardTexts[CardNames.CARD_OF_THE_OTTER] = "";
TChineseCardTexts[CardNames.CARD_OF_THE_OWL] = "";
TChineseCardTexts[CardNames.CARD_OF_THE_OX] = "";
TChineseCardTexts[CardNames.CARD_OF_THE_PIG] = "";
TChineseCardTexts[CardNames.CARD_OF_THE_RAT] = "";
TChineseCardTexts[CardNames.CARD_OF_THE_SEAL] = "";
TChineseCardTexts[CardNames.CARD_OF_THE_SHEEP] = "";
TChineseCardTexts[CardNames.CARD_OF_THE_SQUIRREL] = "";
TChineseCardTexts[CardNames.CARD_OF_THE_TURTLE] = "";
TChineseCardTexts[CardNames.CARD_OF_THE_WORM] = "";

// new Seaside
TChineseCardTexts[CardNames.MONKEY] = "下次輪到你之前，你右手邊//的玩家每獲得一張卡你就：//|＋1 卡片|。//在你下次回合開始的時候//|＋1 卡片|。";
TChineseCardTexts[CardNames.BLOCKADE] = "獲得一張價值至多 [4]的卡片，//將那張卡片放到一邊，在//你下次回合開始時加入手中//，只要該卡還被放到一邊，//其他玩家每次獲得同名卡片//時，該名玩家獲得一張詛咒。";
TChineseCardTexts[CardNames.SAILOR] = "|＋1 行動|//此回合一次，當你獲得一張//持續卡，你可以打出該卡。//在你下次回合開始的時候//＋[2]，你可以移除一張手牌。";
TChineseCardTexts[CardNames.PIRATE] = "在你下次回合開始的時候//獲得一張價值至多//[6]的錢幣卡到你手中。//---//當任何玩家獲得錢幣卡的時候//你可以打出手上的此卡。";
TChineseCardTexts[CardNames.CORSAIR] = "|＋[2]|//在你下次回合開始時：//|＋1 卡片|。//在發動上述效果之前，其他//玩家在他們回合中，移除他//第一次打出的銀幣或黃金。";
TChineseCardTexts[CardNames.SEA_CHART] = "|＋1 卡片|//|＋1 行動|//展示你牌庫頂的一張卡，//如果你出牌區有同名的卡片，//將該卡加入手中。";
TChineseCardTexts[CardNames.ASTROLABE] = "現在還有你下次回合開始時：////[!1]//|＋1 購買|";
TChineseCardTexts[CardNames.TIDE_POOLS] = "|＋3 卡片|//|＋1 行動|//在你下次回合開始時，//棄掉兩張卡片。";
TChineseCardTexts[CardNames.SEA_WITCH] = "|＋2 卡片|//其他玩家獲得一張詛咒。//在你下次回合開始時：//|＋2 卡片|並棄掉兩張卡片。";

// Allies
TChineseCardTexts[CardNames.TOWN] = "選擇一項：//|＋1 卡片|和|＋2 行動|;// 或是|＋1 購買|和＋[2]。";
TChineseCardTexts[CardNames.MODIFY] = "移除你手上的一張卡。//選擇一項：//|＋1 卡片|和|＋1 行動|;//或是獲得一張價值比被移除//的卡片多至多[2]的卡片。";
TChineseCardTexts[CardNames.MERCHANT_CAMP] = "|＋2 行動|//＋[1]//---//當你從出牌區棄掉此卡時，//你可以將此卡放回牌庫頂。";
TChineseCardTexts[CardNames.SWAP] = "|＋1 卡片|//|＋1 行動|//你可以選擇手中的一張行動//卡，讓它回到該卡的牌堆，//來獲得一張價值至多[5]的行//動卡到手白中。";
TChineseCardTexts[CardNames.SKIRMISHER] = "|＋1 卡片|//|＋1 行動|//|＋[1]|//這回合每當你獲得攻//擊卡時，其他玩家將//手牌棄到剩三張卡。";
TChineseCardTexts[CardNames.HIGHWAYMAN] = "在你下次回合開始的時候//，將出牌區的此卡棄掉，//並|＋3 卡片|。//直到此卡執行前述的效果前，其//他玩家回合中打出第一張錢幣卡//時，該卡的出牌效果無效。";
TChineseCardTexts[CardNames.BARBARIAN] = "|＋[2]|//其他玩家移除牌庫頂的一張卡，//如果價值[3]以上，則該玩家選擇//獲得一張至少一個種類與原本卡//片相同，且價值較低的卡片，否//則該玩家獲得一張詛咒。";
TChineseCardTexts[CardNames.INNKEEPER] = "|＋1 行動|//選擇一項：//|＋1 卡片|；//或|＋3 卡|然後棄掉3張卡；//或|＋5 卡|然後棄掉6張卡。";
TChineseCardTexts[CardNames.CAPITAL_CITY] = "|＋1 卡片|//|＋2 行動|//你可以棄掉兩張手牌來＋[2].//你可以付出[2]來|＋2 卡|。";
TChineseCardTexts[CardNames.SPECIALIST] = "你可以從手上打出一//張行動卡或錢幣卡。//選擇一項：//再發動一次該卡效果；//或獲得一張同名卡。";
TChineseCardTexts[CardNames.CARPENTER] = "如果供應區沒有空的牌堆，//則|＋1 行動|和獲得一張價值//至多[4]的卡。//否則移除手上一張卡片，獲//得一張價值至多多[2]的卡。";
TChineseCardTexts[CardNames.ROYAL_GALLEY] = "|＋1 卡片|//你可以從手上打出一張不//是持續卡的行動卡。//將該卡放到一邊；如果成//功放到一邊，在你下次回//合開始的時候，打出它。";
TChineseCardTexts[CardNames.MARQUIS] = "|＋1 購買|//你每有一張手牌就|＋1 卡片|//，你將手牌棄到剩10張。";
TChineseCardTexts[CardNames.SENTINEL] = "觀看你牌庫頂的五張牌，//移除至多其中的兩張，將//剩下的卡片以任意順序放//回牌庫頂。";
TChineseCardTexts[CardNames.COURIER] = "|＋[1]|//棄掉你牌庫頂的一張卡。//查看你的棄牌堆，//你可以選擇其中一張行動//卡或是錢幣卡，打出它。";
TChineseCardTexts[CardNames.GALLERIA] = "|＋[3]|//這回合每當你獲得一//張價值剛好[3]或[4]的//卡片時，|＋1 購買|。";
TChineseCardTexts[CardNames.HUNTER] = "|＋1 行動|//展示牌庫頂的三張卡，//從中選擇行動卡、錢幣卡、//分數卡各一張，將你選的卡//加入手中，棄掉剩下的卡。";

TChineseCardTexts[CardNames.SYCOPHANT] = "|＋1 行動|//棄掉三張卡。//如果有棄掉一張以上，則|＋|[3]。//---//當你獲得或是移除此卡時，//|＋2 支持|。";
TChineseCardTexts[CardNames.UNDERLING] = "|＋1 卡片|//|＋1 行動|//|＋1 支持|";
TChineseCardTexts[CardNames.IMPORTER] = "在你下次回合開始的時候，//獲得一張價值至多[5]的卡。//---//遊戲起始設置：// 所有玩家|＋4 支持|。";
TChineseCardTexts[CardNames.CONTRACT] = "[!2]//|＋1 支持|//你可以將手上的一張行動卡放到//一邊，在你下次回合開始的時候//打出它。";
TChineseCardTexts[CardNames.EMISSARY] = "|＋3 卡片|//如果此卡抽牌導致洗牌，//(至少要有一張牌在牌庫)//則|＋1 行動|和|＋2 支持|。";
TChineseCardTexts[CardNames.BAUBLE] = "選擇下列兩個效果：//|＋1 購買|；＋[1]；|＋1 支持|；//或此回合你獲得卡片時，//你可以將它放到牌庫頂。";
TChineseCardTexts[CardNames.BROKER] = "移除你手上的一張卡。//選擇一項效果://|＋1 卡片|；|＋1 行動|；//＋[1]；|＋1 支持|。//移除的卡片每價值[1]，//你就獲得幾次。";
TChineseCardTexts[CardNames.GUILDMASTER] = "|＋[3]|//這回合每當你獲得卡片時：//|＋1 支持|。";
TChineseCardTexts[CardNames.ARCHITECTS_GUILD] = "當你獲得一張卡片時，你可以花費//|2支持|來獲得一張價值較低的非分數卡。";
TChineseCardTexts[CardNames.ORDER_OF_ASTROLOGERS] = "當你洗牌時，你每花費|1支持|，//你就可以選一張卡放到牌庫頂。";
TChineseCardTexts[CardNames.LEAGUE_OF_BANKERS] = "在你購買階段開始時, 你每擁有|4支持|//，你就會得到＋[1](無條件捨去)。";
TChineseCardTexts[CardNames.MOUNTAIN_FOLK] = "在你回合開始的時候，你可以//花費|5支持|來換取|＋3 卡片|。";
TChineseCardTexts[CardNames.WOODWORKERS_GUILD] = "在你購買階段開始時，你可以花費//|1支持|來移除你手上的一張行動卡，//如果移除了，獲得一張行動卡.";
TChineseCardTexts[CardNames.PEACEFUL_CULT] = "在你購買階段開始的時候，你可以花費任意//數量的|支持|，來移除相對應數量的手牌。";
TChineseCardTexts[CardNames.CRAFTERS_GUILD] = "在你回合開始的時候，你可以花費|2支持|//來獲得一張價值至多[4]的卡，並且放到牌庫頂。";
TChineseCardTexts[CardNames.CAVE_DWELLERS] = "在你回合開始的時候，你可以執行任次次數：//花費|1支持|，來棄掉1張卡並抽一張卡";
TChineseCardTexts[CardNames.PLATEAU_SHEPHERDS] = "遊戲結束時，你每有一組： //|1支持|與一張價值剛好[2]的卡，你就得到{2}。";
TChineseCardTexts[CardNames.DESERT_GUIDES] = "在你回合開始的時候，你可以執行多次：//花費|1支持|並棄掉你手牌，來重抽五張。";
TChineseCardTexts[CardNames.FAMILY_OF_INVENTORS] = "在你購買階段開始的時候，你可以花費//|1支持|來選擇一疊供應區裡的非分數卡，//那疊卡片的價值之後降低[1]。";
TChineseCardTexts[CardNames.ISLAND_FOLK] = "在你回合結束的時候，你可以花費//|5支持|，此回合後多一個額外回合。//(你不能連續動三個回合)";
TChineseCardTexts[CardNames.ORDER_OF_MASONS] = "當洗牌時，你每花費|1支持|：//你就可以選至多兩張卡片放到棄牌堆。";
TChineseCardTexts[CardNames.BAND_OF_NOMADS] = "當你獲得一張價值[3]以上的卡片時，//你可以花費|1支持|來換取：//|＋1 卡片|、或|＋1 行動|、或是|＋1 購買|。";
TChineseCardTexts[CardNames.GANG_OF_PICKPOCKETS] = "在你的回合開始時，如果你沒有花費//|1支持|，你就必須把手牌棄到剩四張。";
TChineseCardTexts[CardNames.MARKET_TOWNS] = "在你購買階段開始時，你可以重複執行：//花費|1支持|來打出你手上的一張行動卡。";
TChineseCardTexts[CardNames.FOREST_DWELLERS] = "在你回合開始時，你可以花費|1支持|，來觀//看你牌庫頂的三張卡，各自決定要棄掉//或是按照任意順序放回。";
TChineseCardTexts[CardNames.FELLOWSHIP_OF_SCRIBES] = "發動完一張行動卡的效果後，如果你手牌不到//4張卡，你可以花費|1支持|來|＋1 卡片|。";
TChineseCardTexts[CardNames.LEAGUE_OF_SHOPKEEPERS] = "發動一張聯絡人的效果之後：//如果你有|5支持|以上，則|＋|[1]。//如果你有|10支持|以上，|＋1 行動|和|＋1 購買|。";
TChineseCardTexts[CardNames.COASTAL_HAVEN] = "在清除階段時你要把手牌棄掉時，//你每付出|1支持|，你就可以保留一張//手牌到下回合(但你只會補到5張卡)。";
TChineseCardTexts[CardNames.CITY_STATE] = "當你在你的回合獲得一張行動卡時，//你可以花費|2支持|來打出它。";
TChineseCardTexts[CardNames.TRAPPERS_LODGE] = "當你獲得一張卡時，你可以花費//|1支持|來將該卡放到你牌庫頂。";
TChineseCardTexts[CardNames.CIRCLE_OF_WITCHES] = "當你發動一張聯絡人的效果後，//你可以花費|3支持|來讓其他玩家獲得一張詛咒。";
TChineseCardTexts[CardNames.AUGUR_PILE] = "選此牌堆當作王國卡的場合，//使用以下各四張：//採藥人、侍僧、女術士、女預言家//，將16張按照順序擺放，且只//有牌堆最上方的卡片可以獲得。";
TChineseCardTexts[CardNames.HERB_GATHERER] = "|＋1 購買|//將你牌庫放到棄牌堆，//你可以查看你棄牌堆，//你可以從中打出一張錢幣卡。//你可以旋轉占兆官牌堆。";
TChineseCardTexts[CardNames.ACOLYTE] = "你可以移除你手上的一張行//動卡或是分數卡，來獲得一//張黃金。你可以移除此卡來//獲得一張占兆官。";
TChineseCardTexts[CardNames.SORCERESS] = "|＋1 行動|//猜一個卡片的名字，展示你//牌庫頂的那張卡，將該卡加//入手中，如果你猜對名字了//，其他玩家獲得一張詛咒。";
TChineseCardTexts[CardNames.SIBYL] = "|＋4 卡片|//|＋1 行動|//將你手上一張卡放回你牌庫//頂，另一張放到你牌庫底。";
TChineseCardTexts[CardNames.CLASH_PILE] = "選此牌堆當作王國卡的場合，//使用以下各四張：//作戰計劃、射手、軍閥、領地//，將16張按照順序擺放，且只//有牌堆最上方的卡片可以獲得。";
TChineseCardTexts[CardNames.BATTLE_PLAN] = "|＋1 卡片|//|＋1 行動|//你可以展示你手上的一//張攻擊卡來|＋1 卡片|。//你可以旋轉任何牌堆。";
TChineseCardTexts[CardNames.ARCHER] = "|＋[2]|//其他有五張以上手牌的玩家，//選一張手牌，展示其他沒選擇//的卡，你從每位展示的卡片中//，選一張棄掉。";
TChineseCardTexts[CardNames.WARLORD] = "|＋1 行動|//在你下次回合開始時|＋2 卡片|。//直到下次輪到你之前，如果該玩//家出牌區已經有兩張同名卡，他//就不能從手上打出同名卡。//";
TChineseCardTexts[CardNames.TERRITORY] = "遊戲結束時，你每有一張不同//名字的分數卡就提供你{1}。//---//當你獲得此卡時，供應區每有一//疊空牌堆，你就獲得一張黃金。";
TChineseCardTexts[CardNames.FORT_PILE] = "選此牌堆當作王國卡的場合，//使用以下各四張：//帳篷、屯駐地、山寨、據點//，將16張按照順序擺放，且只//有牌堆最上方的卡片可以獲得。";
TChineseCardTexts[CardNames.TENT] = "|＋[2]|//你可以旋轉碉堡牌堆。//---//當你從出牌區棄掉此卡，//你可以將此卡放回你牌庫頂。";
TChineseCardTexts[CardNames.GARRISON] = "|＋[2]|//在此卡上累計你本回合//之後獲得了幾張卡。//在你下次回合開始的時候，//先前每獲得一張卡，你就//|＋1 卡片|。";
TChineseCardTexts[CardNames.HILL_FORT] = "獲得一張價值至多[4]的卡。//選擇一個：//將它加入手中。//或|＋1 卡片| 和|＋1 行動|。";
TChineseCardTexts[CardNames.STRONGHOLD] = "選擇一個：//|＋|[3]；//或你下次回合開始時|＋3 卡|。//---//{!2}";
TChineseCardTexts[CardNames.ODYSSEY_PILE] = "選此牌堆當作王國卡的場合，//使用以下各四張：//舊地圖、航海、沉沒的寶藏、遙遠的彼岸//，將16張按照順序擺放，且只//有牌堆最上方的卡片可以獲得。";
TChineseCardTexts[CardNames.OLD_MAP] = "|＋1 卡片|//|＋1 行動|//棄掉一張卡，|＋1 卡片|。//你可以旋轉奧德賽牌堆。";
TChineseCardTexts[CardNames.VOYAGE] = "|＋1 行動|//此回合之後你獲得//一個額外的回合。//(但你不能連續動三個回合)//在該回合中，你只能//從手中打出三張卡片。";
TChineseCardTexts[CardNames.SUNKEN_TREASURE] = "從供應區裡，選擇一張你出牌//區沒有的行動卡，獲得該卡。";
TChineseCardTexts[CardNames.DISTANT_SHORE] = "|＋2 卡片|//|＋1 行動|//獲得一張莊園。//---//{!2}";
TChineseCardTexts[CardNames.TOWNSFOLK_PILE] = "選此牌堆當作王國卡的場合，//使用以下各四張：//街頭公告員、鐵工、磨坊主、長老//，將16張按照順序擺放，且只//有牌堆最上方的卡片可以獲得。";
TChineseCardTexts[CardNames.TOWN_CRIER] = "選擇一個：//|＋|[2]；//或獲得一張銀幣；//或|＋1 卡片|和|＋1 行動|。//你可以旋轉市民牌堆。";
TChineseCardTexts[CardNames.BLACKSMITH] = "選擇一個：//將手牌抽到六張；//或|＋2 卡片|；//或|＋1 卡片|和|＋1 行動|。";
TChineseCardTexts[CardNames.MILLER] = "|＋1 行動|//觀看你牌庫頂的四張卡//，將其中一張加入手中//，棄掉剩下的卡片。";
TChineseCardTexts[CardNames.ELDER] = "|＋[2]|//你可以從你手上打出一張行動卡//，如果該卡這回合有選擇效果，//(例如：\"選擇一個\")//你可以多選一個你沒選到的項目。//";
TChineseCardTexts[CardNames.WIZARD_PILE] = "選此牌堆當作王國卡的場合，//使用以下各四張：//學生、幻術士、術士、巫妖//，將16張按照順序擺放，且只有牌堆//最上方的卡片可以獲得。";
TChineseCardTexts[CardNames.STUDENT] = "|＋1 行動|//你可以旋轉巫師牌堆。//移除你手上一張卡，如//果是錢幣卡，則|＋1支持|//並且將此卡放回牌庫頂。";
TChineseCardTexts[CardNames.CONJURER] = "獲得一張價值至多[4]的卡片，//在你下次回合開始的時候，//把此卡加入手中。";
TChineseCardTexts[CardNames.SORCERER] = "|＋1 卡片|//|＋1 行動|//其他玩家各自猜一個名字，//展示各自牌庫頂的一張卡，//猜錯的人獲得一張詛咒。";
TChineseCardTexts[CardNames.LICH] = "|＋6 卡片|//|＋2 行動|//跳過一次你的下個回合。//---//當你移除此卡，改為棄掉此//卡，並且從移除牌堆獲得一//張價值比此卡低的卡。";
TChineseCardTexts[CardNames.HOARD] = "[!2]//\u6B64\u56DE\u5408\u5167\uFF0C\u6BCF\u7576\u4F60\u900F\u904E\u8CFC\u8CB7\u4F86//\u7372\u5F97\u5206\u6578\u5361\u6642\uFF0C\u7372\u5F97\u4E00\u5F35\u9EC3\u91D1\u3002";
TChineseCardTexts[CardNames.MINT] = "\u4F60\u53EF\u4EE5\u5C55\u793A\u624B\u724C\u7684\u4E00\u5F35\u9322//\u5E63\u5361\uFF0C\u7372\u5F97\u4E00\u5F35\u540C\u540D\u5361\u3002//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\uFF0C\u79FB\u9664\u4F60\u51FA\u724C//\u5340\u6240\u6709\u975E\u6301\u7E8C\u5361\u7684\u9322\u5E63\u5361\u3002";
TChineseCardTexts[CardNames.QUARRY] = "[!1]//\u6B64\u56DE\u5408\u6240\u6709\u884C\u52D5\u5361\u7684\u50F9\u503C\u6E1B\u5C11[2]\u3002";
TChineseCardTexts[CardNames.ANVIL] = "[!1]//你可以棄掉一張錢幣卡，//來獲得一張價值至多[4]的卡。";
TChineseCardTexts[CardNames.CLERK] = "|＋[2]|//有五張手牌以上的其他玩家，//將一張手牌放到他的牌庫頂。//---//在你回合開始的時候，你可以//不消耗行動地從手上打出此卡。";
TChineseCardTexts[CardNames.INVESTMENT] = "移除一張手牌。////選擇一個：＋[1]; //或是移除此卡來展示你的//手牌，每有一張不同名字//的錢幣卡，你就＋{1}。";
TChineseCardTexts[CardNames.TIARA] = "|＋1 購買|//此回合每當你獲得卡片時，//你可以將該卡放到你牌庫頂。////你可以從手中打出一張錢幣卡，//並發動兩次效果。";
TChineseCardTexts[CardNames.CHARLATAN] = "|＋[3]|//其他玩家獲得一張詛咒。//---//有此卡的遊戲中，詛咒也是//錢幣卡，打出會提供你[1]。";
TChineseCardTexts[CardNames.COLLECTION] = "[!2]//|＋1 購買|//此回合你每獲得一張行動卡，//你就 ＋{1}.";
TChineseCardTexts[CardNames.CRYSTAL_BALL] = "[!1]//觀看你牌庫頂的卡，你可//以移除它；或是棄掉它；//又或如果它是錢幣卡或是//行動卡，你可以打出它。";
TChineseCardTexts[CardNames.MAGNATE] = "展示你的手牌，每有一張//錢幣卡你就|＋1 卡片|。";
TChineseCardTexts[CardNames.WAR_CHEST] = "你左手邊的玩家指定一張卡//片，你從這回合沒有被指定//過的卡片中，獲得一張價值//至多[5]的卡片。";

TChineseCardTexts[CardNames.FARMLAND] = "{!2}//---//\u7576\u4F60\u7372\u5F97\u6B64\u5361\u6642\uFF0C\u79FB\u9664\u4F60//\u624B\u4E0A\u7684\u4E00\u5F35\u5361\u7247\uFF0C\u7372\u5F97\u4E00//\u5F35\u50F9\u503C\u525B\u597D\u591A[2]\u7684\u5361\u7247\u3002";
TChineseCardTexts[CardNames.HAGGLER] = "|\uFF0B[2]|//---//\u6B64\u56DE\u5408\u5167\uFF0C\u6BCF\u6B21\u4F60\u7372\u5F97\u4F60\u8CFC//\u8CB7\u7684\u5361\u7247\u6642\uFF0C\u4F60\u5FC5\u9808\u7372\u5F97\u4E00//\u5F35\u8F03\u4FBF\u5B9C\u7684\u975E\u5206\u6578\u5361\u3002";
TChineseCardTexts[CardNames.HIGHWAY] = "|\uFF0B1 \u5361\u7247|//|\uFF0B1 \u884C\u52D5|//---//\u6B64\u56DE\u5408\u6240\u6709\u5361//\u50F9\u503C\u6E1B\u5C11[1]\u3002";
TChineseCardTexts[CardNames.BERSERKER] = "獲得一張價值比此卡低的卡。////其他玩家將手牌棄到剩三張。//---//當你獲得此卡時，如果//你的出牌區有行動卡，//則你可以打出此卡。";
TChineseCardTexts[CardNames.GUARD_DOG] = "|＋2 卡片|//如果你手牌是五張以下，//則你|＋2 卡片|。//---//當其他玩家打出攻擊卡時，//你可以先打出手上的此卡。";
TChineseCardTexts[CardNames.NOMADS] = "|＋1 購買|//|＋[2]|//---//當你獲得或是移//除此卡時＋[2]。";
TChineseCardTexts[CardNames.TRAIL] = "|＋1 卡片|//|＋1 行動|//---//當你獲得、移除、或是在//清除階段以外的時候棄掉//此卡，你可以打出此卡。";
TChineseCardTexts[CardNames.WEAVER] = "獲得兩張銀幣或是一張//價值至多 [4] 的卡片。//---//當你在清除階段以外//的時候棄掉此卡時，//你可以打出此卡。";
TChineseCardTexts[CardNames.SOUK] = "|＋1 購買|//|＋[7]|//你每有一張手牌就-[1]。//(你不會低於 [0]。)//---//當你獲得此卡時，你可//以移除至多兩張手牌。";
TChineseCardTexts[CardNames.CAULDRON] = "[!2]//|＋1 購買|//你此回合第三次獲//得行動卡時其他玩//家獲得一張詛咒。";
TChineseCardTexts[CardNames.WHEELWRIGHT] = "|＋1 卡片|//|＋1 行動|//你可以棄掉一張卡，//來獲得一張等價或較//便宜的行動卡。";
TChineseCardTexts[CardNames.WITCHS_HUT] = "|＋4 卡片|//棄掉兩張手牌，//如果你棄掉了兩張行動卡，//其他玩家獲得一張詛咒。";

//plunder
TChineseCardTexts[CardNames.CAGE] = "將至多四張手牌放在此卡上，//直到你下次有獲得分數卡的回//合結束，才可以將那些卡片加//回手牌，並且移除此卡。";
TChineseCardTexts[CardNames.GROTTO] = "|＋1 行動|////將至多四張手牌放在此卡上。//在你下次回合開始時，棄掉它//們，再抽取相同數量的卡片。";
TChineseCardTexts[CardNames.JEWELLED_EGG] = "[!1]//|＋1 購買|//---//當你移除此卡時，//獲得一張贓物。";
TChineseCardTexts[CardNames.SEARCH] = "|＋[2]|////等到下次有新空牌堆出現時，//移除此卡並獲得一張贓物。";
TChineseCardTexts[CardNames.SHAMAN] = "|＋1 行動|//|＋[1]|////你可以移除一張手牌。//---//有使用此卡的遊戲時：每次你//回合開始，你必須從移除堆裡//獲得一張價值至多[6]的卡。";
TChineseCardTexts[CardNames.SECLUDED_SHRINE] = "|＋[1]|////下次你獲得錢幣卡的時候，//你可以移除至多兩張手牌。";
TChineseCardTexts[CardNames.SIREN] = "其他玩家獲得一張詛咒。////在你下次回合開始的時後，//將手牌抽到有八張為止。//---//當你獲得此卡時，//除非你移除手上的一張行動卡//，不然你必須移除此卡。";
TChineseCardTexts[CardNames.STOWAWAY] = "在你下次回合開始時//|＋2 卡片|。//---//當任何玩家獲得持續卡時，//你可以從手牌中打出此卡。";
TChineseCardTexts[CardNames.TASKMASTER] = "|＋1 行動|，|＋|[1]//如果你這回合有獲得過價值剛//好[5]的卡片，則下次你回合//開始時，重新發動此卡效果。";
TChineseCardTexts[CardNames.ABUNDANCE] = "下次你獲得行動卡的時候：//|＋1 購買|並|＋|[3]。";
TChineseCardTexts[CardNames.CABIN_BOY] = "|＋1 卡片|//|＋1 行動|////在你下次回合開始時，//選擇一個：|＋|[2]; 或是//移除此卡來獲得一張持續卡。";
TChineseCardTexts[CardNames.CRUCIBLE] = "移除你一張手牌，那張卡片//每價值[1]，你就|＋|[1]。";
TChineseCardTexts[CardNames.FLAGSHIP] = "|＋[2]|////下次你發動行動卡的效果後，//(有命令卡類型的行動卡不算)//再發動一次該卡效果。//";
TChineseCardTexts[CardNames.FORTUNE_HUNTER] = "|＋[2]|////觀看你牌庫頂的三張卡。//你可以從中打出一張錢幣卡，//將沒被選到的卡放回牌庫頂。";
TChineseCardTexts[CardNames.GONDOLA] = "現在或是下次你回合開始時：//|＋|[2].//---//當你獲得此卡時，//你可以從手中打出一張行動卡。";
TChineseCardTexts[CardNames.HARBOR_VILLAGE] = "|＋1 卡片|//|＋2 行動|////檢查本回合下一次發動效果的//行動卡，，如果有提供你|＋|[]，//就|＋|[1].";
TChineseCardTexts[CardNames.LANDING_PARTY] = "|＋2 卡片|//|＋2 行動|////此卡在出牌區持續等待，等到//你某回合第一張打出卡片是//錢幣卡，將此卡放回牌庫頂。";
TChineseCardTexts[CardNames.MAPMAKER] = "觀看牌庫頂的四張卡，將其中//兩張加入手中，棄掉剩下的牌////---//任何玩家獲得分數卡片時，//你可以從手上打出此卡。";
TChineseCardTexts[CardNames.MAROON] = "移除你一張手牌。//該卡每有一種類型，你就//|＋2 卡片|。//(行動卡，攻擊卡等等).";
TChineseCardTexts[CardNames.ROPE] = "[!1]//|＋1 購買|////你下次回合開始時|＋1 卡片|，//並且你可以移除一張手牌。";
TChineseCardTexts[CardNames.SWAMP_SHACKS] = "|＋2 行動|////你出牌區每有3張卡片：//你|＋1 卡片|。//(無條件捨去)";
TChineseCardTexts[CardNames.TOOLS] = "從任何玩家選擇一張//出牌區的卡片，獲得//該同名的卡。";
TChineseCardTexts[CardNames.BURIED_TREASURE] = "在下次你回合開始時：//|＋1 購買|, |＋|[3]。//---//當你獲得此卡，立刻打出此卡。";
TChineseCardTexts[CardNames.CREW] = "|＋3 卡片|////在你下次回合開始時，//將此卡放回牌庫頂。";
TChineseCardTexts[CardNames.CUTTHROAT] = "其他玩家將手牌棄到剩三張。//下次任何玩家獲得 [5] 以上的//錢幣卡時，你獲得一張贓物。";
TChineseCardTexts[CardNames.ENLARGE] = "現在與你下次回合開始時：//移除你手上的一張卡，//獲得一張價值至多加[2]的卡。";
TChineseCardTexts[CardNames.FIGURINE] = "|＋2 卡片|////你可以棄掉一張行動卡來換取// |＋1 購買|,|＋|[1]。";
TChineseCardTexts[CardNames.FIRST_MATE] = "從手上打出任意張數的同名行//動卡，接著將手牌抽到六張。";
TChineseCardTexts[CardNames.FRIGATE] = "|＋[3]|////直到你下次回合開始之前，//其他玩家打出行動卡之後，//手牌棄到剩四張。";
TChineseCardTexts[CardNames.LONGSHIP] = "|＋2 行動|////在你下次回合開始時：//|＋2 卡片|。";
TChineseCardTexts[CardNames.MINING_ROAD] = "|＋1 行動|//|＋1 購買|//|＋[2]|////此回合一次：//你獲得錢幣卡時，//你可以打出它。";
TChineseCardTexts[CardNames.PENDANT] = "你出牌區每有一種錢幣卡你就//|＋|[1]。";
TChineseCardTexts[CardNames.PICKAXE] = "[!1]////移除一張手牌。//如果價值有[3]以上，//你獲得一張贓物到手中。";
TChineseCardTexts[CardNames.PILGRIM] = "|＋4 卡片|////將一張手牌放回牌庫頂。";
TChineseCardTexts[CardNames.QUARTERMASTER] = "在你之後每個回合開始時，//選擇一個：獲得一張價值至多//[4]的卡。放到此卡上。//或從此卡上選一張卡加入手牌。";
TChineseCardTexts[CardNames.SILVER_MINE] = "獲得一張比此卡便宜的錢幣卡，//並加入手牌中。";
TChineseCardTexts[CardNames.TRICKSTER] = "其他玩家獲得一張詛咒。////此回合可以發動一次：//當你要從出牌區棄掉錢幣卡或//行動的時候，改為把它放到一//邊，回合結束時加入手牌。";
TChineseCardTexts[CardNames.WEALTHY_VILLAGE] = "|＋1 卡片|//|＋2 行動|//---//當你獲得此卡時，如果你有至//少三種錢幣卡在出牌區，則你//獲得一張贓物。";
TChineseCardTexts[CardNames.SACK_OF_LOOT] = "[!1]//|＋1 購買|//獲得一張贓物。";
TChineseCardTexts[CardNames.KINGS_CACHE] = "你可以打出一張錢幣卡，//發動三次效果。";
TChineseCardTexts[CardNames.BURY] = "|＋1 購買|////從你棄牌推選一張卡片，放到牌庫底。";
TChineseCardTexts[CardNames.AVOID] = "|＋1 購買|////此回合你下一次洗牌時，//你可以選至多三張卡片，先放到棄牌堆不洗。";
TChineseCardTexts[CardNames.DELIVER] = "|＋1 購買|////此回合你每次獲得卡片時，將它放到一邊，//在你回合結束時，加入手中。";
TChineseCardTexts[CardNames.PERIL] = "你可以移除手上一張行動卡來獲得贓物。";
TChineseCardTexts[CardNames.RUSH] = "|＋1 購買|////此回合你下次獲得行動卡時，打出它";
TChineseCardTexts[CardNames.FORAY] = "棄掉三張手牌。展示它們，//如果三張名字不同，你獲得一張贓物。";
TChineseCardTexts[CardNames.LAUNCH] = "每回合一次：回到你的行動階段.//|＋1 卡片|, |＋1 行動|, |＋1 購買|。";
TChineseCardTexts[CardNames.MIRROR] = "|＋1 購買|////此回合你下次獲得行動卡時，//再獲得一張同名的卡片。";
TChineseCardTexts[CardNames.PREPARE] = "將你手牌放到一邊，在你下次回合開始//的時候，你可以打出其中任意數量的錢//幣卡或是行動卡，棄掉剩下的卡片。";
TChineseCardTexts[CardNames.SCROUNGE] = "選擇一個：移除一張手牌。或是從移除//牌獲得一張莊園，如果你獲得了莊園//，則你獲得一張至多[5]的卡。";
TChineseCardTexts[CardNames.JOURNEY] = "此回合清除階段不會棄掉出牌區的卡片，//之後你再多一個回合。(你不能連續動三個回合)";
TChineseCardTexts[CardNames.MAELSTROM] = "移除你三張手牌。//有五張手牌以上的其他玩家，//必須移除一張手牌。";
TChineseCardTexts[CardNames.LOOTING] = "獲得一張贓物。";
TChineseCardTexts[CardNames.INVASION] = "你可以打出一張攻擊卡。獲得一張公國。//獲得一張行動卡並放到牌庫頂。//獲得一張贓物並打出它。";
TChineseCardTexts[CardNames.PROSPER] = "獲得一個贓物，//還有任意數量且名稱不同的錢幣卡。";
TChineseCardTexts[CardNames.AMPHORA] = "現在或是下個回合開始的時候：//|＋1 購買|, |＋|[3].";
TChineseCardTexts[CardNames.DOUBLOONS] = "[!3]////---//當你獲得此卡，//獲得一張黃金。";
TChineseCardTexts[CardNames.ENDLESS_CHALICE] = "現在與之後你的回合開始時：//[!1]//|＋1 購買|";
TChineseCardTexts[CardNames.FIGUREHEAD] = "[!3]////在你下次回合開始時：// |＋2卡片|。";
TChineseCardTexts[CardNames.HAMMER] = "[!3]////獲得一張價值至多[4]的卡。";
TChineseCardTexts[CardNames.INSIGNIA] = "[!3]////此回合你可以將獲得//的卡片放置牌庫頂。";
TChineseCardTexts[CardNames.JEWELS] = "[!3]//|＋1 購買|////下回合開始的時候，//將此卡放置牌庫頂。";
TChineseCardTexts[CardNames.ORB] = "觀看你的棄牌堆，選擇一個://打出其中一張行動卡或是錢幣//卡；或是 |＋1 購買|, | ＋|[3]";
TChineseCardTexts[CardNames.PRIZE_GOAT] = "[!3]//|＋1 購買|////你可以移除一張手牌。";
TChineseCardTexts[CardNames.PUZZLE_BOX] = "[!3]//|＋1 購買|////你可以將一張手牌放到一邊，//在回合結束時將它加入手牌。";
TChineseCardTexts[CardNames.SEXTANT] = "[!3]//|＋1 購買|////觀看你牌庫頂的五張卡片，//你可以棄掉任意張數，將剩//下的以任意順序放回牌庫頂。";
TChineseCardTexts[CardNames.SHIELD] = "[!3]//|＋1 購買|//---//當其他玩家打出攻擊卡時。//你可以展示此卡來無效該卡//對你的效果。";
TChineseCardTexts[CardNames.SPELL_SCROLL] = "移除此卡來獲得一張較便宜的//卡片。如果是錢幣卡或是行動//卡，則你可以打出它。";
TChineseCardTexts[CardNames.STAFF] = "[!3]//|＋1 購買|////你可以從手上打出一張行動卡。";
TChineseCardTexts[CardNames.SWORD] = "[!3]//|＋1 購買|////其他玩家將手牌棄到剩 4 張。";
TChineseCardTexts[CardNames.CHEAP] = "卡片價值降低 [1] 。";
TChineseCardTexts[CardNames.CURSED] = "當你獲得一張被詛咒的卡，//獲得一張詛咒與贓物。";
TChineseCardTexts[CardNames.FAWNING] = "當你獲得行省時，//獲得一張奉承的卡。";
TChineseCardTexts[CardNames.FATED] = "每當你洗牌時，你可以從將命中注定的//卡放到牌庫頂，或是牌庫底。";
TChineseCardTexts[CardNames.FRIENDLY] = "在你清除階段開始時，你可以從手上棄掉一//張友善的卡片來獲得另一張友善的卡片。";
TChineseCardTexts[CardNames.HASTY] = "當你獲得一張急躁的卡片，你可以將它放到一邊，//在你下次回合開始時，打出它。";
TChineseCardTexts[CardNames.INHERITED] = "遊戲設置：遊戲開始時，//每位玩家將起始牌換一張繼承來的卡片。";
TChineseCardTexts[CardNames.INSPIRING] = "每當你打出鼓舞人心的卡片，//選擇手牌與你出牌區名字皆不相同的行動卡，//不需要消耗行動數的打出它。";
TChineseCardTexts[CardNames.NEARBY] = "當你獲得一張附近的卡片時，你 |＋1 購買|。";
TChineseCardTexts[CardNames.PATIENT] = "在清除階段開始時，你可以從手牌將一張有耐//心的卡放到一邊，在你下次回合開始打出它。";
TChineseCardTexts[CardNames.PIOUS] = "當你獲得一張虔誠的卡片時，//可以移除一張手牌。";
TChineseCardTexts[CardNames.RECKLESS] = "當你發動魯莽的卡片效果後，重作一次卡片//上寫的效果，當你要把魯莽的卡片從出牌//區棄掉時，改為歸還到供應區。";
TChineseCardTexts[CardNames.RICH] = "當你獲得一張有錢的卡片時，//獲得一張銀幣。";
TChineseCardTexts[CardNames.SHY] = "在你回合開始的時候，//你可以棄掉一張害羞的卡片來 |＋2 卡片|。";
TChineseCardTexts[CardNames.TIRELESS] = "當你從出牌區棄掉一張不怕累的卡片時，//將它到一邊，//在你回合結束時將他放到你牌庫頂。";
TChineseCardTexts[CardNames.MARCHLAND] = "你每擁有三張分數卡，//此卡就多提供{1}。//---//當你獲得此卡時|＋1 購買|，//還可以棄掉任意張數的手牌，//每棄一張可以換取 +[1]。";

TChineseCardTexts[CardNames.SHOP] = "|＋1 卡片|//|+[1]|//從你手牌中選擇一張行動卡，//該卡必須與出牌區中的卡名皆//不相同，打出它。//\n";
TChineseCardTexts[CardNames.CARNIVAL] = "展示你牌庫頂的四張卡，//把每種名字的各拿一張加//入手牌，棄掉剩餘的卡。";
TChineseCardTexts[CardNames.FERRYMAN] = "|＋2 卡片|//|＋1 行動|//棄掉一張手牌。//---//起始設置： 本場遊戲額外多選一//組 [3] 或 [4] 的王國卡。//當你獲得渡船夫時，你也獲得該卡。";
TChineseCardTexts[CardNames.FARMHANDS] = "|＋1 卡片|//|＋2 行動|//---//當你獲得此卡時，你可以從//手牌中選一張錢幣或是行動//卡，將其放到一邊，下次你//回合開始的時候打出它。";
TChineseCardTexts[CardNames.JOUST] = "|＋1 卡片|//|＋1 行動|//|+[1]|//你可以從手牌選一張行省，//將其放到一邊來獲得一張獎//賞，並加入手牌。清除階段//時將行省棄掉。";
TChineseCardTexts[CardNames.REWARDS_PILE] = "--";
TChineseCardTexts[CardNames.COURSER] = "從下列選擇兩個不同效果://|＋2 卡片|; |＋2 行動|;//|+|[2]; 獲得四張銀幣.////%(此卡不在供應區中.)%";
TChineseCardTexts[CardNames.RENOWN] = "|＋1 購買|////此回合中，所有卡片價值降低 [2] 。////%(此卡不在供應區中)%";
TChineseCardTexts[CardNames.CORONET] = "你可以從手牌上打出一張不是獎//賞的行動卡，並發動兩次效果。////你可以從手牌上打出一張不是獎//賞的錢幣卡，並發動兩次效果。////%(此卡不在供應區中)%";
TChineseCardTexts[CardNames.DEMESNE] = "|＋2 行動|//|＋2 購買|//獲得一張黃金。//---//你每擁有一張黃金，//遊戲結束時此卡就多提供 {1}。////%(此卡不在供應區中)%";
TChineseCardTexts[CardNames.HOUSECARL] = "計算你出牌區有幾種不同的卡片，//每有一種你就|＋1 卡片| ////%(此卡不在供應區中)%";
TChineseCardTexts[CardNames.HUGE_TURNIP] = "|＋2 存款|//你每擁有1存款，你就|+|[1]。////%(此卡不在供應區中)%";
TChineseCardTexts[CardNames.FARRIER] = "|＋1 卡片|//|＋1 行動|//|＋1 購買|//---//超額支付：每超付 [1]，//此回合結束時，你就|＋1 卡片|";
TChineseCardTexts[CardNames.INFIRMARY] = "|＋1 卡片|//你可以移除一張手牌。//---//超額支付：每超付 [1]，//你就發動此卡效果一次。";
TChineseCardTexts[CardNames.FOOTPAD] = "|＋2 存款|//其他玩家將手牌棄到剩三張。//---//有此卡的遊戲中, //每當你在行動階段獲得卡片時，// 你 |＋1 卡片|。\n";

TChineseCardTexts[CardNames.FISHMONGER] = "|＋1 購買|//|+[1]|//---//你可以從牌庫打出這張卡，//當作從你手牌打出。";
TChineseCardTexts[CardNames.SNAKE_WITCH] = "|＋1 卡片|//|＋1 行動|//如果你手牌沒有相同名字//的牌，你可以展示手牌並//歸還此卡，來讓其他玩家//獲得一張詛咒。";
TChineseCardTexts[CardNames.ARISTOCRAT] = "如果你出牌區有幾張名門：////1或5張: |＋3 行動|;//2或6張: |＋3 卡片|;//3或7張: |+|[3];//4或8張: |＋3 購買|";
TChineseCardTexts[CardNames.CRAFTSMAN] = "|+[2D]|////獲得一張價值至多[5]的卡";
TChineseCardTexts[CardNames.RIVERBOAT] = "下次你回合開始的時候，//發動此卡放到一邊的卡，//發動時不能移動該卡。//---//遊戲設置：選一張沒玩且//不是持續的[5]行動卡，//將該卡放到一邊";
TChineseCardTexts[CardNames.ROOT_CELLAR] = "|＋3 卡片|//|＋1 行動|//|+[3D]|";
TChineseCardTexts[CardNames.ALLEY] = "|＋1 卡片|//|＋1 行動|//棄掉一張手牌。//---//你可以從牌庫打出這張卡，//當作從你手牌打出。";
TChineseCardTexts[CardNames.CHANGE] = "如果你有 [D]，則 |+|[3].////如果沒有，則移除一張手牌，//並獲得一張卡，獲得的卡如果//比移除的卡高價，每多 [1]，//你就多拿+[1D]。";
TChineseCardTexts[CardNames.NINJA] = "|＋1 卡片|//其他玩家將手牌棄到剩三張。//---//你可以從牌庫打出這張卡，//當作從你手牌打出。";
TChineseCardTexts[CardNames.POET] = "|+1 <>|//|＋1 卡片|//|＋1 行動|//展示你牌庫頂的一張卡，//如果價值[3]以下則加入手牌。";
TChineseCardTexts[CardNames.RIVER_SHRINE] = "|+1 <>|////移除至多兩張手牌。//清除階段開始時，如果你//這回合購買階段沒有獲得//任何卡片，獲得一張價值//至多 [4]的卡。";
TChineseCardTexts[CardNames.RUSTIC_VILLAGE] = "|+1 <>|//|＋1 卡片|//|＋2 行動|//你可以棄掉兩張卡，//來得到 |＋1 卡片|。";
TChineseCardTexts[CardNames.GOLD_MINE] = "|＋1 卡片|//|＋1 行動|//|＋1 購買|//你可以獲得一張黃金還有＋[4D]。";
TChineseCardTexts[CardNames.IMPERIAL_ENVOY] = "|＋5 卡片|//|＋1 購買|//|+[2D]|";
TChineseCardTexts[CardNames.KITSUNE] = "|+1 <>|//選擇兩個不同的效果://|+|[2]; |＋2 行動|; //其他玩家獲得一張詛咒;// 獲得一張銀幣。";
TChineseCardTexts[CardNames.LITTER] = "|＋2 卡片|//|＋2 行動|//|+[1D]|";
TChineseCardTexts[CardNames.RICE_BROKER] = "|＋1 行動|//移除一張手牌。//如果是錢幣卡則 |＋2 卡片|。//如果是行動卡則 |＋5 卡片|。";
TChineseCardTexts[CardNames.RONIN] = "持續抽牌直到你有七張手牌。//---//你可以從牌庫打出這張卡，//當作從你手牌打出。";
TChineseCardTexts[CardNames.TANUKI] = "移除一張手牌。獲得一張//價值至多多 [2] 的卡片。//---//你可以從牌庫打出這張卡，//當作從你手牌打出。";
TChineseCardTexts[CardNames.TEA_HOUSE] = "|+1 <>|//|＋1 卡片|//|＋1 行動|//|+[2]|";
TChineseCardTexts[CardNames.SAMURAI] = "其他玩家將手牌棄到剩三張//(上述效果僅一次)//未來你回合開始的時候＋[1]//%(此卡會一直留在出牌區)%";
TChineseCardTexts[CardNames.RICE] = "|＋1 購買|//你出牌區有每有一種類別，//你就|+|[1]";
TChineseCardTexts[CardNames.MOUNTAIN_SHRINE] = "|+1 <>|//+[2]//你可以移除一張手牌。 //如果移除牌堆有行動卡，//則 |＋2 卡片|";
TChineseCardTexts[CardNames.DAIMYO] = "|＋1 卡片|//|＋1 行動|//本回合下次打出不是命令卡//的行動卡時，再多發動一次//效果。";
TChineseCardTexts[CardNames.ARTIST] = "|＋1 行動|////計算你出牌有幾張名字只//出現一次的卡片。每有一//張就 |＋1 卡片|。";
TChineseCardTexts[CardNames.APPROACHING_ARMY] = "你打出攻擊卡後 +[1].//---//遊戲設置：本場遊戲額外多一張攻擊卡。";
TChineseCardTexts[CardNames.BIDING_TIME] = "你的清除階段開始時，將你手牌放到一邊，//你下次回合開始時，將那些牌加入回手牌。";
TChineseCardTexts[CardNames.BUREAUCRACY] = "當你獲得一張價值不是 [0] 的片時，獲得一張銅幣";
TChineseCardTexts[CardNames.DIVINE_WIND] = "當你移除最後一道陽光，移除供應區所有王國卡，//接著重選 10張王國卡繼續玩";
TChineseCardTexts[CardNames.ENLIGHTENMENT] = "錢幣卡新增行動卡類別，可以在行動階段打出錢幣卡，//但效果改為 |＋1 卡片| 和 |＋1 行動|。";
TChineseCardTexts[CardNames.FLOURISHING_TRADE] = "所以卡片價值降低 [1] 。//你可以把行動數當成購買來使用";
TChineseCardTexts[CardNames.GOOD_HARVEST] = "本回合你每打出一種不一樣的錢幣卡時，//先|＋1 購買| 和 +[1]";
TChineseCardTexts[CardNames.GREAT_LEADER] = "每次你打出行動卡後， |＋1 行動|";
TChineseCardTexts[CardNames.GROWTH] = "當你獲得錢幣卡時，獲得一張較便宜的卡片。";
TChineseCardTexts[CardNames.HARSH_WINTER] = "你在你的回合獲得卡片時，//如果該牌堆上面有 [D]，則全部拿走。//如果沒有的話，放 [2D] 到該牌堆上。";
TChineseCardTexts[CardNames.KIND_EMPEROR] = "在你的回合開始時，或是你移除最後一道陽光時，// 獲得一張行動卡到你手中。";
TChineseCardTexts[CardNames.PANIC] = "當你打出錢幣卡時 |＋2 購買|，//當你要從出牌區把錢幣卡棄掉時，歸還它。";
TChineseCardTexts[CardNames.PROGRESS] = "當你獲得卡片時，將該卡放到牌庫頂。";
TChineseCardTexts[CardNames.RAPID_EXPANSION] = "當你獲得行動卡或錢幣卡的時候，//將它放到一邊，你下次回合開始的時候打出它。";
TChineseCardTexts[CardNames.SICKNESS] = "在你回合開始的時候：//獲得一張詛咒並放到牌庫頂，// 或是棄掉三張手牌。";
TChineseCardTexts[CardNames.AMASS] = "如果你出牌區沒有行動卡，//獲得一張價值至多[5]的行動卡。";
TChineseCardTexts[CardNames.ASCETICISM] = "額外再付出 []，來移除對應數量的手牌。";
TChineseCardTexts[CardNames.CREDIT] = "獲得一張價值至多 [8] 的錢幣卡或是行動卡// 該卡價值多少你就獲得相同的[D]";
TChineseCardTexts[CardNames.FORESIGHT] = "持續展示你的牌庫頂，直到你展示了一張行動卡，//將該卡放到一邊，並且棄掉其他卡片，//在回合結束時，將該卡加入手牌。";
TChineseCardTexts[CardNames.KINTSUGI] = "移除一張手牌。如果本場遊戲你有獲得過黃金//，你可以獲得一張價值比該卡至多多[2]的卡";
TChineseCardTexts[CardNames.PRACTICE] = "你可以從你手牌打出一張行動卡，//並發動兩次效果";
TChineseCardTexts[CardNames.SEA_TRADE] = "你出牌區每有一張行動卡你就 |＋1 卡片|，//你出牌區每有一張行動卡你就移除一張手牌。";
TChineseCardTexts[CardNames.RECEIVE_TRIBUTE] = "如果此回合你至少獲得三張卡，獲得三張不同//名字的行動卡，且都不能跟你出牌區有名字相同。";
TChineseCardTexts[CardNames.GATHER] = "獲得一張價值剛好 [3] 的卡片。//獲得一張價值剛好 [4] 的卡片。//獲得一張價值剛好 [5] 的卡片。";
TChineseCardTexts[CardNames.CONTINUE] = "每回合一次:獲得一張非攻擊卡且價值至多[4]的行動卡。//回到行動階段，並 |＋1 行動| |＋1 購買|。";