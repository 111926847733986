"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Customer = function () {

    // userData is meta.model.me
    function Customer(userData, cardOwnership) {
        _classCallCheck(this, Customer);

        this.userData = userData;
        this.cardOwnership = cardOwnership;
    }

    _createClass(Customer, [{
        key: "ipCountryCode",
        value: function ipCountryCode() {
            return this.userData.ipCountry;
        }
    }, {
        key: "ipCountry",
        value: function ipCountry() {
            return getCountryByCode(this.ipCountryCode());
        }
    }, {
        key: "ipFromEU",
        value: function ipFromEU() {
            return EU_COUNTRY_CODES.indexOf(this.ipCountryCode()) !== -1;
        }
    }, {
        key: "ipFromUS",
        value: function ipFromUS() {
            return this.ipCountryCode() === 'US';
        }
    }, {
        key: "ownsExpansion",
        value: function ownsExpansion(expansion) {
            return this.ownedExpansions().indexOf(expansion) !== -1;
        }
    }, {
        key: "validDuration",
        value: function validDuration(level, duration) {
            if (duration.endDate() > StoreSubscription.maxSubscriptionDate()) return false;
            var currentEnd = this.cardOwnership.levelEndsAt(level);
            var answer = !currentEnd || currentEnd < duration.endDate();
            console.log("Validated level " + level + " for duration " + duration.endDate() + ": currentEnd = " + currentEnd + " and answer = " + answer);
            return answer;
        }
    }, {
        key: "validDurations",
        value: function validDurations(levels, durations) {
            var _this = this;

            return durations.filter(function (duration) {
                return levels.every(function (level) {
                    return _this.validDuration(level, duration);
                });
            });
        }
    }, {
        key: "maxedOutSubscriptions",
        value: function maxedOutSubscriptions(durations) {
            var _this2 = this;

            return !durations.some(function (duration) {
                return _this2.validDuration(10, duration);
            });
        }
    }, {
        key: "validCombination",
        value: function validCombination(duration, storeSubscription) {
            var _this3 = this;

            console.log("checking combination of duration " + duration.endDate() + " and storeSubscription %o", storeSubscription);
            if (isUndefined(duration)) {
                return true;
            }
            if (isUndefined(storeSubscription)) {
                return true;
            }

            return storeSubscription.levels.some(function (level) {
                return _this3.validDuration(level, duration);
            });
        }
    }, {
        key: "subscription",
        value: function subscription(expansionName) {
            return this.subscriptions().find(function (s) {
                return s.name() === expansionName;
            });
        }

        // Payed stuff, not counting free expansions like BASE and PROMO

    }, {
        key: "subscriptions",
        value: function subscriptions() {

            var map = {};

            this.cardOwnership.forEach(function (cardUniverse) {

                cardUniverse.expansions.forEach(function (expansion) {

                    if (!expansion.isForFree()) {
                        var name = expansion.name;
                        if (isUndefined(map[name])) {
                            map[name] = [];
                        }
                        map[name].push(cardUniverse.dateobj);
                    }
                });
            });

            var names = Object.keys(map);

            return names.map(function (name) {
                return new StoreSubscription(name, map[name]);
            });
        }
    }, {
        key: "earliestEndDate",
        value: function earliestEndDate() {

            var dates = this.subscriptions().map(function (s) {
                return s.endDate();
            });

            return dates.length === 0 ? new Date() : earliestDate(dates);
        }
    }]);

    return Customer;
}();