"use strict";

var EsperantoAbilityNames = {};

EsperantoAbilityNames[AbilityNames.BUY] = "a";
EsperantoAbilityNames[AbilityNames.PLAY_ATTACK] = "ludu atakkarton";
EsperantoAbilityNames[AbilityNames.DRAW_INSTRUCTIONS] = "b";
EsperantoAbilityNames[AbilityNames.PLAY_INSTRUCTIONS] = "ludu";
EsperantoAbilityNames[AbilityNames.RESOLVE_EVENT] = "c";
EsperantoAbilityNames[AbilityNames.GAIN_BY_NAME] = "d";

EsperantoAbilityNames[AbilityNames.FISHING_VILLAGE_ABILITY] = EsperantoCardNames[CardNames.FISHING_VILLAGE].singular;
EsperantoAbilityNames[AbilityNames.REMOVE_COST_REDUCTION] = "e";
EsperantoAbilityNames[AbilityNames.HAVEN_ABILITY] = EsperantoCardNames[CardNames.HAVEN].singular;
EsperantoAbilityNames[AbilityNames.SAVE_RETURN_TO_HAND] = EsperantoCardNames[CardNames.SAVE].singular;
EsperantoAbilityNames[AbilityNames.CARAVAN_ABILITY] = EsperantoCardNames[CardNames.CARAVAN].singular;
EsperantoAbilityNames[AbilityNames.MERCHANT_SHIP_ABILITY] = EsperantoCardNames[CardNames.MERCHANT_SHIP].singular;
EsperantoAbilityNames[AbilityNames.OUTPOST_ABILITY] = EsperantoCardNames[CardNames.OUTPOST].singular;
EsperantoAbilityNames[AbilityNames.TACTICIAN_ABILITY] = EsperantoCardNames[CardNames.TACTICIAN].singular;
EsperantoAbilityNames[AbilityNames.WHARF_ABILITY] = EsperantoCardNames[CardNames.WHARF].singular;
EsperantoAbilityNames[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = EsperantoCardNames[CardNames.HORSE_TRADERS].singular;
EsperantoAbilityNames[AbilityNames.DUCHESS_MAY_GAIN] = EsperantoCardNames[CardNames.DUCHESS].singular;
EsperantoAbilityNames[AbilityNames.FOOLS_GOLD_TRASH] = EsperantoCardNames[CardNames.FOOLS_GOLD].singular;
EsperantoAbilityNames[AbilityNames.NOBLE_BRIGAND] = EsperantoCardNames[CardNames.NOBLE_BRIGAND].singular;
EsperantoAbilityNames[AbilityNames.HAGGLER_ABILITY] = EsperantoCardNames[CardNames.HAGGLER].singular;
EsperantoAbilityNames[AbilityNames.INN_ABILITY] = EsperantoCardNames[CardNames.INN].singular;
EsperantoAbilityNames[AbilityNames.MANDARIN] = EsperantoCardNames[CardNames.MANDARIN].singular;
EsperantoAbilityNames[AbilityNames.FARMLAND] = EsperantoCardNames[CardNames.FARMLAND].singular;
EsperantoAbilityNames[AbilityNames.GAIN_CHEAPER] = "Gajnu pli malmultekostan karton";
EsperantoAbilityNames[AbilityNames.GAIN_TWO_RUINS] = EsperantoCardNames[CardNames.DEATH_CART].singular;
EsperantoAbilityNames[AbilityNames.HUNTING_GROUNDS] = EsperantoCardNames[CardNames.HUNTING_GROUNDS].singular;
EsperantoAbilityNames[AbilityNames.SQUIRE_GAIN_ATTACK] = EsperantoCardNames[CardNames.SQUIRE].singular;
EsperantoAbilityNames[AbilityNames.URCHIN_TRANSFORM] = EsperantoCardNames[CardNames.URCHIN].singular;
EsperantoAbilityNames[AbilityNames.FEODUM_GAIN_SILVERS] = EsperantoCardNames[CardNames.FEODUM].singular;
EsperantoAbilityNames[AbilityNames.DOCTOR_OVERPAY] = "Tropagu";
EsperantoAbilityNames[AbilityNames.RESOLVE_DOCTOR_OVERPAY] = EsperantoCardNames[CardNames.DOCTOR].singular;
EsperantoAbilityNames[AbilityNames.HERALD_OVERPAY] = "Tropagu";
EsperantoAbilityNames[AbilityNames.RESOLVE_HERALD_OVERPAY] = EsperantoCardNames[CardNames.HERALD].singular;
EsperantoAbilityNames[AbilityNames.MASTERPIECE_OVERPAY] = "Tropagu";
EsperantoAbilityNames[AbilityNames.RESOLVE_MASTERPIECE_OVERPAY] = EsperantoCardNames[CardNames.MASTERPIECE].singular;
EsperantoAbilityNames[AbilityNames.STONEMASON_OVERPAY] = "Tropagu";
EsperantoAbilityNames[AbilityNames.RESOLVE_STONEMASON_OVERPAY] = EsperantoCardNames[CardNames.STONEMASON].singular;
EsperantoAbilityNames[AbilityNames.EXCHANGE] = "Interŝanĝu";
EsperantoAbilityNames[AbilityNames.SHUFFLE] = "Miksu";
EsperantoAbilityNames[AbilityNames.AMULET] = EsperantoCardNames[CardNames.AMULET].singular;
EsperantoAbilityNames[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = EsperantoCardNames[CardNames.CARAVAN_GUARD].singular;
EsperantoAbilityNames[AbilityNames.DUNGEON_ABILITY] = EsperantoCardNames[CardNames.DUNGEON].singular;
EsperantoAbilityNames[AbilityNames.GEAR_ABILITY] = EsperantoCardNames[CardNames.GEAR].singular;
EsperantoAbilityNames[AbilityNames.MESSENGER_GAIN] = EsperantoCardNames[CardNames.MESSENGER].singular;
EsperantoAbilityNames[AbilityNames.BRIDGE_TROLL_ABILITY] = EsperantoCardNames[CardNames.BRIDGE_TROLL].singular;
EsperantoAbilityNames[AbilityNames.HAUNTED_WOODS_DURATION] = "Tiru";
EsperantoAbilityNames[AbilityNames.HAUNTED_WOODS_TOPDECK] = "Surmetu";
EsperantoAbilityNames[AbilityNames.LOST_CITY] = EsperantoCardNames[CardNames.LOST_CITY].singular;
EsperantoAbilityNames[AbilityNames.WINE_MERCHANT_DISCARD] = EsperantoCardNames[CardNames.WINE_MERCHANT].singular;
EsperantoAbilityNames[AbilityNames.HIRELING] = EsperantoCardNames[CardNames.HIRELING].singular;
EsperantoAbilityNames[AbilityNames.SWAMP_HAG_DURATION] = EsperantoCardNames[CardNames.SWAMP_HAG].singular;
EsperantoAbilityNames[AbilityNames.TRASH_TOKEN_ABILITY] = EsperantoCardNames[CardNames.PLAN].singular;
EsperantoAbilityNames[AbilityNames.EMPORIUM_GAIN_POINTS] = EsperantoCardNames[CardNames.EMPORIUM].singular;
EsperantoAbilityNames[AbilityNames.ROCKS_GAINS_SILVER] = EsperantoCardNames[CardNames.ROCKS].singular;
EsperantoAbilityNames[AbilityNames.FORTUNE] = EsperantoCardNames[CardNames.FORTUNE].singular;
EsperantoAbilityNames[AbilityNames.CRUMBLING_CASTLE] = EsperantoCardNames[CardNames.CRUMBLING_CASTLE].singular;
EsperantoAbilityNames[AbilityNames.HAUNTED_CASTLE] = EsperantoCardNames[CardNames.HAUNTED_CASTLE].singular;
EsperantoAbilityNames[AbilityNames.SPRAWLING_CASTLE] = EsperantoCardNames[CardNames.SPRAWLING_CASTLE].singular;
EsperantoAbilityNames[AbilityNames.GRAND_CASTLE] = EsperantoCardNames[CardNames.GRAND_CASTLE].singular;
EsperantoAbilityNames[AbilityNames.ENCHANTRESS_ABILITY] = EsperantoCardNames[CardNames.ENCHANTRESS].singular;
EsperantoAbilityNames[AbilityNames.ENCHANTRESS_CANTRIP] = "+1 Karto, +1 Ago";
EsperantoAbilityNames[AbilityNames.TEMPLE] = EsperantoCardNames[CardNames.TEMPLE].singular;
EsperantoAbilityNames[AbilityNames.VILLA] = EsperantoCardNames[CardNames.VILLA].singular;
EsperantoAbilityNames[AbilityNames.ARCHIVE_ABILITY] = EsperantoCardNames[CardNames.ARCHIVE].singular;
EsperantoAbilityNames[AbilityNames.CAPITAL_TAKE_DEBT] = EsperantoCardNames[CardNames.CAPITAL].singular;
EsperantoAbilityNames[AbilityNames.CHARM] = EsperantoCardNames[CardNames.CHARM].singular;
EsperantoAbilityNames[AbilityNames.FORUM] = EsperantoCardNames[CardNames.FORUM].singular;
EsperantoAbilityNames[AbilityNames.HERMIT] = EsperantoCardNames[CardNames.HERMIT].singular;
EsperantoAbilityNames[AbilityNames.DONATE] = EsperantoCardNames[CardNames.DONATE].singular;
EsperantoAbilityNames[AbilityNames.TAX_RECEIVE] = EsperantoCardNames[CardNames.TAX].singular;
EsperantoAbilityNames[AbilityNames.DOMINATE] = EsperantoCardNames[CardNames.DOMINATE].singular;
EsperantoAbilityNames[AbilityNames.VP_TO_AQUEDUCT] = "VP al Akvedukto";
EsperantoAbilityNames[AbilityNames.VP_FROM_AQUEDUCT] = "VP el Akvedukto";
EsperantoAbilityNames[AbilityNames.ARENA_DISCARD_ACTION] = EsperantoCardNames[CardNames.ARENA].singular;
EsperantoAbilityNames[AbilityNames.VP_FROM_BASILICA] = EsperantoCardNames[CardNames.BASILICA].singular;
EsperantoAbilityNames[AbilityNames.VP_FROM_BATHS] = EsperantoCardNames[CardNames.BATHS].singular;
EsperantoAbilityNames[AbilityNames.VP_FROM_BATTLEFIELD] = EsperantoCardNames[CardNames.BATTLEFIELD].singular;
EsperantoAbilityNames[AbilityNames.VP_FROM_COLONNADE] = EsperantoCardNames[CardNames.COLONNADE].singular;
EsperantoAbilityNames[AbilityNames.VP_TO_DEFILED_SHRINE] = "VP al Profanita Sanktejo";
EsperantoAbilityNames[AbilityNames.VP_FROM_DEFILED_SHRINE] = "VP el Profanita Sanktejo";
EsperantoAbilityNames[AbilityNames.VP_FROM_LABYRINTH] = EsperantoCardNames[CardNames.LABYRINTH].singular;
EsperantoAbilityNames[AbilityNames.MOUNTAIN_PASS_BIDDING] = EsperantoCardNames[CardNames.MOUNTAIN_PASS].singular;
EsperantoAbilityNames[AbilityNames.TOMB] = EsperantoCardNames[CardNames.TOMB].singular;
EsperantoAbilityNames[AbilityNames.LIGHTHOUSE_COIN] = EsperantoCardNames[CardNames.LIGHTHOUSE].singular;
EsperantoAbilityNames[AbilityNames.REACTION] = "Reago";
EsperantoAbilityNames[AbilityNames.CALL_GUIDE] = "Alvoku " + EsperantoCardNames[CardNames.GUIDE].noun;
EsperantoAbilityNames[AbilityNames.CALL_RATCATCHER] = "Alvoku " + EsperantoCardNames[CardNames.RATCATCHER].noun;
EsperantoAbilityNames[AbilityNames.CALL_TRANSMOGRIFY] = "Alvoku " + EsperantoCardNames[CardNames.TRANSMOGRIFY].noun;
EsperantoAbilityNames[AbilityNames.CALL_TEACHER] = "Alvoku " + EsperantoCardNames[CardNames.TEACHER].noun;
EsperantoAbilityNames[AbilityNames.CALL_DUPLICATE] = "Alvoku " + EsperantoCardNames[CardNames.DUPLICATE].noun;
EsperantoAbilityNames[AbilityNames.CALL_COIN] = "Alvoku " + EsperantoCardNames[CardNames.COIN_OF_THE_REALM].noun;
EsperantoAbilityNames[AbilityNames.CALL_ROYAL_CARRIAGE] = "Alvoku " + EsperantoCardNames[CardNames.ROYAL_CARRIAGE].noun;
EsperantoAbilityNames[AbilityNames.START_OF_TURN] = "komencu vian vicon";
EsperantoAbilityNames[AbilityNames.START_BUY_PHASE] = "START_BUY_PHASE";
EsperantoAbilityNames[AbilityNames.END_OF_BUY_PHASE] = "END_OF_BUY_PHASE";
EsperantoAbilityNames[AbilityNames.BETWEEN_TURNS] = "BETWEEN_TURNS";
EsperantoAbilityNames[AbilityNames.END_OF_TURN] = "END_OF_TURN";
EsperantoAbilityNames[AbilityNames.SUMMON_PLAY] = EsperantoCardNames[CardNames.SUMMON].singular;
EsperantoAbilityNames[AbilityNames.CARAVAN_GUARD_REACTION] = EsperantoCardNames[CardNames.CARAVAN_GUARD].singular;
EsperantoAbilityNames[AbilityNames.TRAVELLING_FAIR_REACTION] = EsperantoCardNames[CardNames.TRAVELLING_FAIR].singular;
EsperantoAbilityNames[AbilityNames.MOAT_REACTION] = EsperantoCardNames[CardNames.MOAT].singular;
EsperantoAbilityNames[AbilityNames.HORSE_TRADERS_REACTION] = EsperantoCardNames[CardNames.HORSE_TRADERS].singular;
EsperantoAbilityNames[AbilityNames.BEGGAR_REACTION] = EsperantoCardNames[CardNames.BEGGAR].singular;
EsperantoAbilityNames[AbilityNames.MARKET_SQUARE_REACTION] = EsperantoCardNames[CardNames.MARKET_SQUARE].singular;
EsperantoAbilityNames[AbilityNames.TRADER_REACTION] = EsperantoCardNames[CardNames.TRADER].singular;
EsperantoAbilityNames[AbilityNames.DIPLOMAT_REACTION] = EsperantoCardNames[CardNames.DIPLOMAT].singular;
EsperantoAbilityNames[AbilityNames.TOPDECK_REACTION] = "Surmetu";
EsperantoAbilityNames[AbilityNames.WATCHTOWER_TRASH_REACTION] = "Trash";
EsperantoAbilityNames[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "Topdeck";
EsperantoAbilityNames[AbilityNames.PRINCE_PLAY] = EsperantoCardNames[CardNames.PRINCE].singular;
EsperantoAbilityNames[AbilityNames.PRINCE_SET_ASIDE] = EsperantoCardNames[CardNames.PRINCE].singular;
EsperantoAbilityNames[AbilityNames.DELUDED_RETURNED] = "Redonu Staton";
EsperantoAbilityNames[AbilityNames.ENVIOUS_RETURNED] = "Redonu Staton";
EsperantoAbilityNames[AbilityNames.RECEIVE_BOON] = "Ricevas Benon";
EsperantoAbilityNames[AbilityNames.COBBLER_ABILITY] = EsperantoCardNames[CardNames.COBBLER].singular;
EsperantoAbilityNames[AbilityNames.CRYPT_ABILITY] = EsperantoCardNames[CardNames.CRYPT].singular;
EsperantoAbilityNames[AbilityNames.DEN_OF_SIN_ABILITY] = EsperantoCardNames[CardNames.DEN_OF_SIN].singular;
EsperantoAbilityNames[AbilityNames.FAITHFUL_HOUND] = EsperantoCardNames[CardNames.FAITHFUL_HOUND].singular;
EsperantoAbilityNames[AbilityNames.FAITHFUL_HOUND_RETURN] = EsperantoCardNames[CardNames.FAITHFUL_HOUND].singular;
EsperantoAbilityNames[AbilityNames.GHOST_TOWN_ABILITY] = EsperantoCardNames[CardNames.GHOST_TOWN].singular;
EsperantoAbilityNames[AbilityNames.GUARDIAN_COIN] = EsperantoCardNames[CardNames.GUARDIAN].singular;
EsperantoAbilityNames[AbilityNames.RAIDER_ABILITY] = EsperantoCardNames[CardNames.RAIDER].singular;
EsperantoAbilityNames[AbilityNames.SECRET_CAVE] = EsperantoCardNames[CardNames.SECRET_CAVE].singular;
EsperantoAbilityNames[AbilityNames.CEMETERY] = EsperantoCardNames[CardNames.CEMETERY].singular;
EsperantoAbilityNames[AbilityNames.HAUNTED_MIRROR] = EsperantoCardNames[CardNames.HAUNTED_MIRROR].singular;
EsperantoAbilityNames[AbilityNames.GHOST] = EsperantoCardNames[CardNames.GHOST].singular;
EsperantoAbilityNames[AbilityNames.REMOVE_FLIPPED_STATUS] = "X";
EsperantoAbilityNames[AbilityNames.LOST_IN_THE_WOODS] = EsperantoCardNames[CardNames.LOST_IN_THE_WOODS].singular;
EsperantoAbilityNames[AbilityNames.BLESSED_VILLAGE] = EsperantoCardNames[CardNames.BLESSED_VILLAGE].singular;
EsperantoAbilityNames[AbilityNames.FORTRESS] = EsperantoCardNames[CardNames.FORTRESS].singular;
EsperantoAbilityNames[AbilityNames.RECEIVE_HEX] = "Ricevas Envulton";
EsperantoAbilityNames[AbilityNames.HOVEL_TRASH] = EsperantoCardNames[CardNames.HOVEL].singular;
EsperantoAbilityNames[AbilityNames.TUNNEL_REVEAL] = EsperantoCardNames[CardNames.TUNNEL].singular;
EsperantoAbilityNames[AbilityNames.CHANGELING_MAY_EXCHANGE] = EsperantoCardNames[CardNames.CHANGELING].singular;
EsperantoAbilityNames[AbilityNames.DUCAT] = EsperantoCardNames[CardNames.DUCAT].singular;
EsperantoAbilityNames[AbilityNames.CARGO_SHIP_SET_ASIDE] = EsperantoCardNames[CardNames.CARGO_SHIP].singular;
EsperantoAbilityNames[AbilityNames.CARGO_SHIP_RETURN] = EsperantoCardNames[CardNames.CARGO_SHIP].singular;
EsperantoAbilityNames[AbilityNames.IMPROVE] = EsperantoCardNames[CardNames.IMPROVE].singular;
EsperantoAbilityNames[AbilityNames.PRIEST] = EsperantoCardNames[CardNames.PRIEST].singular;
EsperantoAbilityNames[AbilityNames.RESEARCH] = EsperantoCardNames[CardNames.RESEARCH].singular;
EsperantoAbilityNames[AbilityNames.SILK_MERCHANT] = EsperantoCardNames[CardNames.SILK_MERCHANT].singular;
EsperantoAbilityNames[AbilityNames.LACKEYS] = EsperantoCardNames[CardNames.LACKEYS].singular;
EsperantoAbilityNames[AbilityNames.SPICES] = EsperantoCardNames[CardNames.SPICES].singular;
EsperantoAbilityNames[AbilityNames.KEY] = EsperantoCardNames[CardNames.KEY].singular;
EsperantoAbilityNames[AbilityNames.TREASURE_CHEST] = EsperantoCardNames[CardNames.TREASURE_CHEST].singular;
EsperantoAbilityNames[AbilityNames.FLAG_BEARER] = EsperantoCardNames[CardNames.FLAG_BEARER].singular;
EsperantoAbilityNames[AbilityNames.CATHEDRAL] = EsperantoCardNames[CardNames.CATHEDRAL].singular;
EsperantoAbilityNames[AbilityNames.CITY_GATE] = EsperantoCardNames[CardNames.CITY_GATE].singular;
EsperantoAbilityNames[AbilityNames.PAGEANT] = EsperantoCardNames[CardNames.PAGEANT].singular;
EsperantoAbilityNames[AbilityNames.SEWERS_MAY_TRASH] = EsperantoCardNames[CardNames.SEWERS].singular;
EsperantoAbilityNames[AbilityNames.EXPLORATION] = EsperantoCardNames[CardNames.EXPLORATION].singular;
EsperantoAbilityNames[AbilityNames.FAIR] = EsperantoCardNames[CardNames.FAIR].singular;
EsperantoAbilityNames[AbilityNames.SILOS] = EsperantoCardNames[CardNames.SILOS].singular;
EsperantoAbilityNames[AbilityNames.ACADEMY] = EsperantoCardNames[CardNames.ACADEMY].singular;
EsperantoAbilityNames[AbilityNames.GUILDHALL] = EsperantoCardNames[CardNames.GUILDHALL].singular;
EsperantoAbilityNames[AbilityNames.PIAZZA] = EsperantoCardNames[CardNames.PIAZZA].singular;
EsperantoAbilityNames[AbilityNames.BARRACKS] = EsperantoCardNames[CardNames.BARRACKS].singular;
EsperantoAbilityNames[AbilityNames.CROP_ROTATION] = EsperantoCardNames[CardNames.CROP_ROTATION].singular;
EsperantoAbilityNames[AbilityNames.INNOVATION] = EsperantoCardNames[CardNames.INNOVATION].singular;
EsperantoAbilityNames[AbilityNames.CITADEL] = EsperantoCardNames[CardNames.CITADEL].singular;
EsperantoAbilityNames[AbilityNames.SINISTER_PLOT] = EsperantoCardNames[CardNames.SINISTER_PLOT].singular;
EsperantoAbilityNames[AbilityNames.ENCAMPMENT_RETURNED] = EsperantoCardNames[CardNames.ENCAMPMENT].singular;
EsperantoAbilityNames[AbilityNames.EMBARGO_GAIN_CURSE] = EsperantoCardNames[CardNames.EMBARGO].singular;
EsperantoAbilityNames[AbilityNames.POSSESSION_GAIN_INSTEAD] = EsperantoCardNames[CardNames.POSSESSION].singular;
EsperantoAbilityNames[AbilityNames.TRADE_ROUTE_MOVE_TOKEN] = EsperantoCardNames[CardNames.TRADE_ROUTE].singular;
EsperantoAbilityNames[AbilityNames.CAPTAIN] = EsperantoCardNames[CardNames.CAPTAIN].singular;
EsperantoAbilityNames[AbilityNames.CHURCH] = EsperantoCardNames[CardNames.CHURCH].singular;
EsperantoAbilityNames[AbilityNames.POSSESSION_SETS_ASIDE] = EsperantoCardNames[CardNames.POSSESSION].singular;
EsperantoAbilityNames[AbilityNames.MINT] = EsperantoCardNames[CardNames.MINT].singular;
EsperantoAbilityNames[AbilityNames.SLEIGH_TOPDECK_REACTION] = "Surmetu";
EsperantoAbilityNames[AbilityNames.SLEIGH_PUT_IN_HAND_REACTION] = "Metu en manon";
EsperantoAbilityNames[AbilityNames.BLACK_CAT_REACTION] = EsperantoCardNames[CardNames.BLACK_CAT].singular;
EsperantoAbilityNames[AbilityNames.DISCARD_FROM_EXILE] = "Forĵetu el Ekzilo";
EsperantoAbilityNames[AbilityNames.EXILE_BY_NAME] = "Ekzilu";
EsperantoAbilityNames[AbilityNames.SHEEPDOG_REACTION] = EsperantoCardNames[CardNames.SHEEPDOG].singular;
EsperantoAbilityNames[AbilityNames.CAVALRY] = EsperantoCardNames[CardNames.CAVALRY].singular;
EsperantoAbilityNames[AbilityNames.HOSTELRY] = EsperantoCardNames[CardNames.HOSTELRY].singular;
EsperantoAbilityNames[AbilityNames.VILLAGE_GREEN_VILLAGE] = EsperantoCardNames[CardNames.VILLAGE_GREEN].singular;
EsperantoAbilityNames[AbilityNames.VILLAGE_GREEN_PLAY] = EsperantoCardNames[CardNames.VILLAGE_GREEN].singular;
EsperantoAbilityNames[AbilityNames.BARGE] = EsperantoCardNames[CardNames.BARGE].singular;
EsperantoAbilityNames[AbilityNames.FALCONER_REACTION] = EsperantoCardNames[CardNames.FALCONER].singular;
EsperantoAbilityNames[AbilityNames.GATEKEEPER_EXILE] = EsperantoCardNames[CardNames.GATEKEEPER].singular;
EsperantoAbilityNames[AbilityNames.GATEKEEPER_DURATION] = EsperantoCardNames[CardNames.GATEKEEPER].singular;
EsperantoAbilityNames[AbilityNames.LIVERY] = EsperantoCardNames[CardNames.LIVERY].singular;
EsperantoAbilityNames[AbilityNames.MASTERMIND] = EsperantoCardNames[CardNames.MASTERMIND].singular;
EsperantoAbilityNames[AbilityNames.DELAY_PLAY] = EsperantoCardNames[CardNames.DELAY].singular;
EsperantoAbilityNames[AbilityNames.INVEST] = EsperantoCardNames[CardNames.INVEST].singular;
EsperantoAbilityNames[AbilityNames.REAP_PLAY] = EsperantoCardNames[CardNames.REAP].singular;
EsperantoAbilityNames[AbilityNames.PLUS_CARD_TOKEN] = "+Karto";
EsperantoAbilityNames[AbilityNames.PLUS_BUY_TOKEN] = "+Aĉeto";
EsperantoAbilityNames[AbilityNames.PLUS_ACTION_TOKEN] = "+Ago";
EsperantoAbilityNames[AbilityNames.PLUS_COIN_TOKEN] = "+Monero";
EsperantoAbilityNames[AbilityNames.KILN] = EsperantoCardNames[CardNames.KILN].singular;
EsperantoAbilityNames[AbilityNames.TURTLE_PLAY] = EsperantoCardNames[CardNames.WAY_OF_THE_TURTLE].singular;
EsperantoAbilityNames[AbilityNames.GIVE_PLUS_CARDS] = EsperantoAbilityNames[AbilityNames.PLUS_CARD_TOKEN];
EsperantoAbilityNames[AbilityNames.GIVE_PLUS_BUYS] = EsperantoAbilityNames[AbilityNames.PLUS_BUY_TOKEN];
EsperantoAbilityNames[AbilityNames.GIVE_PLUS_ACTIONS] = EsperantoAbilityNames[AbilityNames.PLUS_ACTION_TOKEN];
EsperantoAbilityNames[AbilityNames.GIVE_PLUS_COINS] = EsperantoAbilityNames[AbilityNames.PLUS_COIN_TOKEN];
EsperantoAbilityNames[AbilityNames.MONKEY_NEXT_TURN_ABILITY] = EsperantoCardNames[CardNames.MONKEY].singular;
EsperantoAbilityNames[AbilityNames.BLOCKADE_ABILITY] = EsperantoCardNames[CardNames.BLOCKADE].singular;
EsperantoAbilityNames[AbilityNames.PIRATE_ABILITY] = EsperantoCardNames[CardNames.PIRATE].singular;
EsperantoAbilityNames[AbilityNames.PIRATE_REACTION] = EsperantoCardNames[CardNames.PIRATE].singular;
EsperantoAbilityNames[AbilityNames.CORSAIR_NEXT_TURN_ABILITY] = EsperantoCardNames[CardNames.CORSAIR].singular;
EsperantoAbilityNames[AbilityNames.CORSAIR_TRASH] = EsperantoCardNames[CardNames.CORSAIR].singular;
EsperantoAbilityNames[AbilityNames.SAILOR_NEXT_TURN] = EsperantoCardNames[CardNames.SAILOR].singular;
EsperantoAbilityNames[AbilityNames.SAILOR_REACTION] = EsperantoCardNames[CardNames.SAILOR].singular;
EsperantoAbilityNames[AbilityNames.SKIRMISHER] = EsperantoCardNames[CardNames.SKIRMISHER].singular;
EsperantoAbilityNames[AbilityNames.ENCHANTRESS_REPLACE_ABILITY] = EsperantoCardNames[CardNames.ENCHANTRESS].singular;
EsperantoAbilityNames[AbilityNames.HIGHWAYMAN_NEXT_TURN_ABILITY] = EsperantoCardNames[CardNames.HIGHWAYMAN].singular;
EsperantoAbilityNames[AbilityNames.HIGHWAYMAN_REPLACE_ABILITY] = EsperantoCardNames[CardNames.HIGHWAYMAN].singular;
EsperantoAbilityNames[AbilityNames.HIGHWAYMAN_NULLIFIED] = EsperantoCardNames[CardNames.HIGHWAYMAN].singular;
EsperantoAbilityNames[AbilityNames.LEAGUE_OF_BANKERS] = EsperantoCardNames[CardNames.LEAGUE_OF_BANKERS].singular;
EsperantoAbilityNames[AbilityNames.WOODWORKERS_GUILD] = EsperantoCardNames[CardNames.WOODWORKERS_GUILD].singular;
EsperantoAbilityNames[AbilityNames.CRAFTERS_GUILD] = EsperantoCardNames[CardNames.CRAFTERS_GUILD].singular;
EsperantoAbilityNames[AbilityNames.CAVE_DWELLERS] = EsperantoCardNames[CardNames.CAVE_DWELLERS].singular;
EsperantoAbilityNames[AbilityNames.DESERT_GUIDES] = EsperantoCardNames[CardNames.DESERT_GUIDES].singular;
EsperantoAbilityNames[AbilityNames.BAND_OF_NOMADS_CARD] = "Karto";
EsperantoAbilityNames[AbilityNames.BAND_OF_NOMADS_ACTION] = "Ago";
EsperantoAbilityNames[AbilityNames.BAND_OF_NOMADS_BUY] = "Aĉeto";
EsperantoAbilityNames[AbilityNames.SYCOPHANT] = EsperantoCardNames[CardNames.SYCOPHANT].singular;
EsperantoAbilityNames[AbilityNames.IMPORTER_ABILITY] = EsperantoCardNames[CardNames.IMPORTER].singular;
EsperantoAbilityNames[AbilityNames.CONTRACT_ABILITY] = EsperantoCardNames[CardNames.CONTRACT].singular;
EsperantoAbilityNames[AbilityNames.MARKET_TOWNS] = EsperantoCardNames[CardNames.MARKET_TOWNS].singular;
EsperantoAbilityNames[AbilityNames.TRAPPERS_LODGE] = EsperantoCardNames[CardNames.TRAPPERS_LODGE].singular;
EsperantoAbilityNames[AbilityNames.GUILDMASTER] = EsperantoCardNames[CardNames.GUILDMASTER].singular;
EsperantoAbilityNames[AbilityNames.ISLAND_FOLK] = EsperantoCardNames[CardNames.ISLAND_FOLK].singular;
EsperantoAbilityNames[AbilityNames.GANG_OF_PICKPOCKETS] = EsperantoCardNames[CardNames.GANG_OF_PICKPOCKETS].singular;
EsperantoAbilityNames[AbilityNames.COASTAL_HAVEN] = EsperantoCardNames[CardNames.COASTAL_HAVEN].singular;
EsperantoAbilityNames[AbilityNames.CITY_STATE] = EsperantoCardNames[CardNames.CITY_STATE].singular;
EsperantoAbilityNames[AbilityNames.CITY_STATE_PLAY] = EsperantoCardNames[CardNames.CITY_STATE].singular;
EsperantoAbilityNames[AbilityNames.CIRCLE_OF_WITCHES] = EsperantoCardNames[CardNames.CIRCLE_OF_WITCHES].singular;
EsperantoAbilityNames[AbilityNames.ROYAL_GALLEY_SET_ASIDE] = EsperantoCardNames[CardNames.ROYAL_GALLEY].singular;
EsperantoAbilityNames[AbilityNames.ROYAL_GALLEY_PLAY] = EsperantoCardNames[CardNames.ROYAL_GALLEY].singular;
EsperantoAbilityNames[AbilityNames.STRONGHOLD_ABILITY] = EsperantoCardNames[CardNames.STRONGHOLD].singular;
EsperantoAbilityNames[AbilityNames.MOUNTAIN_FOLK] = EsperantoCardNames[CardNames.MOUNTAIN_FOLK].singular;
EsperantoAbilityNames[AbilityNames.PEACEFUL_CULT] = EsperantoCardNames[CardNames.PEACEFUL_CULT].singular;
EsperantoAbilityNames[AbilityNames.FELLOWSHIP_OF_SCRIBES] = EsperantoCardNames[CardNames.FELLOWSHIP_OF_SCRIBES].singular;
EsperantoAbilityNames[AbilityNames.LEAGUE_OF_SHOPKEEPERS] = EsperantoCardNames[CardNames.LEAGUE_OF_SHOPKEEPERS].singular;
EsperantoAbilityNames[AbilityNames.TERRITORY_GAIN] = EsperantoCardNames[CardNames.TERRITORY].singular;
EsperantoAbilityNames[AbilityNames.WARLORD_ABILITY] = EsperantoCardNames[CardNames.WARLORD].singular;
EsperantoAbilityNames[AbilityNames.GARRISON_ADD_TOKEN] = EsperantoCardNames[CardNames.GARRISON].singular;
EsperantoAbilityNames[AbilityNames.GARRISON_REMOVE_ABILITY] = EsperantoCardNames[CardNames.GARRISON].singular;
EsperantoAbilityNames[AbilityNames.CONJURER] = EsperantoCardNames[CardNames.CONJURER].singular;
EsperantoAbilityNames[AbilityNames.LICH] = EsperantoCardNames[CardNames.LICH].singular;
EsperantoAbilityNames[AbilityNames.GALLERIA] = EsperantoCardNames[CardNames.GALLERIA].singular;
EsperantoAbilityNames[AbilityNames.ARCHITECTS_GUILD] = EsperantoCardNames[CardNames.ARCHITECTS_GUILD].singular;
EsperantoAbilityNames[AbilityNames.FAMILY_OF_INVENTORS] = EsperantoCardNames[CardNames.FAMILY_OF_INVENTORS].singular;
EsperantoAbilityNames[AbilityNames.FOREST_DWELLERS] = EsperantoCardNames[CardNames.FOREST_DWELLERS].singular;
EsperantoAbilityNames[AbilityNames.ASTROLABE_ABILITY] = EsperantoCardNames[CardNames.ASTROLABE].singular;
EsperantoAbilityNames[AbilityNames.TIDE_POOLS_ABILITY] = EsperantoCardNames[CardNames.TIDE_POOLS].singular;
EsperantoAbilityNames[AbilityNames.SEA_WITCH_ABILITY] = EsperantoCardNames[CardNames.SEA_WITCH].singular;
EsperantoAbilityNames[AbilityNames.TREASURY] = EsperantoCardNames[CardNames.TREASURY].singular;
EsperantoAbilityNames[AbilityNames.CLERK_ABILITY] = EsperantoCardNames[CardNames.CLERK].singular;
EsperantoAbilityNames[AbilityNames.GUARD_DOG_REACTION] = EsperantoCardNames[CardNames.GUARD_DOG].singular;
EsperantoAbilityNames[AbilityNames.BERSERKER] = EsperantoCardNames[CardNames.BERSERKER].singular;
EsperantoAbilityNames[AbilityNames.NOMADS] = EsperantoCardNames[CardNames.NOMADS].singular;
EsperantoAbilityNames[AbilityNames.TRAIL_REACTION] = EsperantoCardNames[CardNames.TRAIL].singular;
EsperantoAbilityNames[AbilityNames.WEAVER_PLAY] = EsperantoCardNames[CardNames.WEAVER].singular;
EsperantoAbilityNames[AbilityNames.SOUK] = EsperantoCardNames[CardNames.SOUK].singular;
EsperantoAbilityNames[AbilityNames.CAULDRON_CURSING] = EsperantoCardNames[CardNames.CAULDRON].singular;
EsperantoAbilityNames[AbilityNames.ALCHEMIST_TOPDECK] = EsperantoCardNames[CardNames.ALCHEMIST].singular;

EsperantoAbilityNames[AbilityNames.SAUNA_TRASH_ABILITY] = EsperantoCardNames[CardNames.SAUNA].singular;
EsperantoAbilityNames[AbilityNames.CHAMELEON_RESOLUTION] = EsperantoCardNames[CardNames.WAY_OF_THE_CHAMELEON].singular;
EsperantoAbilityNames[AbilityNames.MERCHANT_GUILD] = EsperantoCardNames[CardNames.MERCHANT_GUILD].singular;
EsperantoAbilityNames[AbilityNames.HERBALIST_TOPDECK] = EsperantoCardNames[CardNames.HERBALIST].singular;
EsperantoAbilityNames[AbilityNames.SCHEME_TOPDECK] = EsperantoCardNames[CardNames.SCHEME].singular;
EsperantoAbilityNames[AbilityNames.FROG_TOPDECK] = EsperantoCardNames[CardNames.WAY_OF_THE_FROG].singular;
EsperantoAbilityNames[AbilityNames.MERCHANT_CAMP_TOPDECK] = EsperantoCardNames[CardNames.MERCHANT_CAMP].singular;
EsperantoAbilityNames[AbilityNames.WALLED_VILLAGE_TOPDECK] = EsperantoCardNames[CardNames.WALLED_VILLAGE].singular;
EsperantoAbilityNames[AbilityNames.TENT_TOPDECK] = EsperantoCardNames[CardNames.TENT].singular;
EsperantoAbilityNames[AbilityNames.HORN_TOPDECK] = EsperantoCardNames[CardNames.HORN].singular;
EsperantoAbilityNames[AbilityNames.PAGE_EXCHANGE] = EsperantoCardNames[CardNames.TREASURE_HUNTER].singular;
EsperantoAbilityNames[AbilityNames.TREASURE_HUNTER_EXCHANGE] = EsperantoCardNames[CardNames.WARRIOR].singular;
EsperantoAbilityNames[AbilityNames.WARRIOR_EXCHANGE] = EsperantoCardNames[CardNames.HERO].singular;
EsperantoAbilityNames[AbilityNames.HERO_EXCHANGE] = EsperantoCardNames[CardNames.CHAMPION].singular;
EsperantoAbilityNames[AbilityNames.PEASANT_EXCHANGE] = EsperantoCardNames[CardNames.SOLDIER].singular;
EsperantoAbilityNames[AbilityNames.SOLDIER_EXCHANGE] = EsperantoCardNames[CardNames.FUGITIVE].singular;
EsperantoAbilityNames[AbilityNames.FUGITIVE_EXCHANGE] = EsperantoCardNames[CardNames.DISCIPLE].singular;
EsperantoAbilityNames[AbilityNames.DISCIPLE_EXCHANGE] = EsperantoCardNames[CardNames.TEACHER].singular;

EsperantoAbilityNames[AbilityNames.CAGE_TRASH] = EsperantoCardNames[CardNames.CAGE].singular;
EsperantoAbilityNames[AbilityNames.CAGE_PUT_IN_HAND] = EsperantoCardNames[CardNames.CAGE].singular;
EsperantoAbilityNames[AbilityNames.GROTTO] = EsperantoCardNames[CardNames.GROTTO].singular;
EsperantoAbilityNames[AbilityNames.JEWELLED_EGG_GAIN_LOOT] = EsperantoCardNames[CardNames.JEWELLED_EGG].singular;
EsperantoAbilityNames[AbilityNames.SEARCH] = EsperantoCardNames[CardNames.SEARCH].singular;
EsperantoAbilityNames[AbilityNames.SHAMAN] = EsperantoCardNames[CardNames.SHAMAN].singular;
EsperantoAbilityNames[AbilityNames.SECLUDED_SHRINE] = EsperantoCardNames[CardNames.SECLUDED_SHRINE].singular;
EsperantoAbilityNames[AbilityNames.SIREN_GAIN] = EsperantoCardNames[CardNames.SIREN].singular;
EsperantoAbilityNames[AbilityNames.SIREN_DURATION] = EsperantoCardNames[CardNames.SIREN].singular;
EsperantoAbilityNames[AbilityNames.STOWAWAY_DRAW] = EsperantoCardNames[CardNames.STOWAWAY].singular;
EsperantoAbilityNames[AbilityNames.STOWAWAY_REACTION] = EsperantoCardNames[CardNames.STOWAWAY].singular;
EsperantoAbilityNames[AbilityNames.TASKMASTER] = EsperantoCardNames[CardNames.TASKMASTER].singular;
EsperantoAbilityNames[AbilityNames.ABUNDANCE] = EsperantoCardNames[CardNames.ABUNDANCE].singular;
EsperantoAbilityNames[AbilityNames.CABIN_BOY] = EsperantoCardNames[CardNames.CABIN_BOY].singular;
EsperantoAbilityNames[AbilityNames.CRUCIBLE] = EsperantoCardNames[CardNames.CRUCIBLE].singular;
EsperantoAbilityNames[AbilityNames.FLAGSHIP_ACTIVATE] = EsperantoCardNames[CardNames.FLAGSHIP].singular;
EsperantoAbilityNames[AbilityNames.FLAGSHIP_REPLAY] = EsperantoCardNames[CardNames.FLAGSHIP].singular;
EsperantoAbilityNames[AbilityNames.GONDOLA_DURATION] = EsperantoCardNames[CardNames.GONDOLA].singular;
EsperantoAbilityNames[AbilityNames.GONDOLA_PLAY] = EsperantoCardNames[CardNames.GONDOLA].singular;
EsperantoAbilityNames[AbilityNames.HARBOR_VILLAGE] = EsperantoCardNames[CardNames.HARBOR_VILLAGE].singular;
EsperantoAbilityNames[AbilityNames.LANDING_PARTY] = EsperantoCardNames[CardNames.LANDING_PARTY].singular;
EsperantoAbilityNames[AbilityNames.MAPMAKER] = EsperantoCardNames[CardNames.MAPMAKER].singular;
EsperantoAbilityNames[AbilityNames.ROPE] = EsperantoCardNames[CardNames.ROPE].singular;
EsperantoAbilityNames[AbilityNames.BURIED_TREASURE_PLAY] = EsperantoCardNames[CardNames.BURIED_TREASURE].singular;
EsperantoAbilityNames[AbilityNames.BURIED_TREASURE_DURATION] = EsperantoCardNames[CardNames.BURIED_TREASURE].singular;
EsperantoAbilityNames[AbilityNames.CREW_DURATION] = EsperantoCardNames[CardNames.CREW].singular;
EsperantoAbilityNames[AbilityNames.CUTTHROAT_GAIN] = EsperantoCardNames[CardNames.CUTTHROAT].singular;
EsperantoAbilityNames[AbilityNames.ENLARGE] = EsperantoCardNames[CardNames.ENLARGE].singular;
EsperantoAbilityNames[AbilityNames.FRIGATE_DURATION] = EsperantoCardNames[CardNames.FRIGATE].singular;
EsperantoAbilityNames[AbilityNames.FRIGATE_DISCARD] = EsperantoCardNames[CardNames.FRIGATE].singular;
EsperantoAbilityNames[AbilityNames.LONGSHIP_DURATION] = EsperantoCardNames[CardNames.LONGSHIP].singular;
EsperantoAbilityNames[AbilityNames.MINING_ROAD] = EsperantoCardNames[CardNames.MINING_ROAD].singular;
EsperantoAbilityNames[AbilityNames.QUARTERMASTER] = EsperantoCardNames[CardNames.QUARTERMASTER].singular;
EsperantoAbilityNames[AbilityNames.TRICKSTER_REACTION] = EsperantoCardNames[CardNames.TRICKSTER].singular;
EsperantoAbilityNames[AbilityNames.TRICKSTER_RETURN_TO_HAND] = EsperantoCardNames[CardNames.TRICKSTER].singular;
EsperantoAbilityNames[AbilityNames.WEALTHY_VILLAGE] = EsperantoCardNames[CardNames.WEALTHY_VILLAGE].singular;
EsperantoAbilityNames[AbilityNames.DELIVER_SET_ASIDE] = EsperantoCardNames[CardNames.DELIVER].singular;
EsperantoAbilityNames[AbilityNames.DELIVER_RETURN] = EsperantoCardNames[CardNames.DELIVER].singular;
EsperantoAbilityNames[AbilityNames.RUSH] = EsperantoCardNames[CardNames.RUSH].singular;
EsperantoAbilityNames[AbilityNames.MIRROR] = EsperantoCardNames[CardNames.MIRROR].singular;
EsperantoAbilityNames[AbilityNames.PREPARE] = EsperantoCardNames[CardNames.PREPARE].singular;
EsperantoAbilityNames[AbilityNames.AMPHORA] = EsperantoCardNames[CardNames.AMPHORA].singular;
EsperantoAbilityNames[AbilityNames.DOUBLOONS] = EsperantoCardNames[CardNames.DOUBLOONS].singular;
EsperantoAbilityNames[AbilityNames.ENDLESS_CHALICE] = EsperantoCardNames[CardNames.ENDLESS_CHALICE].singular;
EsperantoAbilityNames[AbilityNames.FIGUREHEAD] = EsperantoCardNames[CardNames.FIGUREHEAD].singular;
EsperantoAbilityNames[AbilityNames.JEWELS] = EsperantoCardNames[CardNames.JEWELS].singular;
EsperantoAbilityNames[AbilityNames.PUZZLE_BOX_RETURN] = EsperantoCardNames[CardNames.PUZZLE_BOX].singular;
EsperantoAbilityNames[AbilityNames.SHIELD_REACTION] = EsperantoCardNames[CardNames.SHIELD].singular;
EsperantoAbilityNames[AbilityNames.SPELL_SCROLL] = EsperantoCardNames[CardNames.SPELL_SCROLL].singular;
EsperantoAbilityNames[AbilityNames.CURSED_GAIN] = EsperantoCardNames[CardNames.CURSED].singular;
EsperantoAbilityNames[AbilityNames.FAWNING_GAIN] = EsperantoCardNames[CardNames.FAWNING].singular;
EsperantoAbilityNames[AbilityNames.SCHEDULER] = EsperantoCardNames[CardNames.FRIENDLY].singular;
EsperantoAbilityNames[AbilityNames.FRIENDLY_ABILITY] = EsperantoCardNames[CardNames.FRIENDLY].singular;
EsperantoAbilityNames[AbilityNames.HASTY_SET_ASIDE] = EsperantoCardNames[CardNames.HASTY].singular;
EsperantoAbilityNames[AbilityNames.HASTY_PLAY] = EsperantoCardNames[CardNames.HASTY].singular;
EsperantoAbilityNames[AbilityNames.INHERITED] = EsperantoCardNames[CardNames.INHERITED].singular;
EsperantoAbilityNames[AbilityNames.INSPIRING] = EsperantoCardNames[CardNames.INSPIRING].singular;
EsperantoAbilityNames[AbilityNames.NEARBY] = EsperantoCardNames[CardNames.NEARBY].singular;
EsperantoAbilityNames[AbilityNames.PATIENT_SCHEDULER] = EsperantoCardNames[CardNames.PATIENT].singular;
EsperantoAbilityNames[AbilityNames.PATIENT_SET_ASIDE] = EsperantoCardNames[CardNames.PATIENT].singular;
EsperantoAbilityNames[AbilityNames.PATIENT_PLAY] = EsperantoCardNames[CardNames.PATIENT].singular;
EsperantoAbilityNames[AbilityNames.PIOUS] = EsperantoCardNames[CardNames.PIOUS].singular;
EsperantoAbilityNames[AbilityNames.RECKLESS_REPEAT] = EsperantoCardNames[CardNames.RECKLESS].singular;
EsperantoAbilityNames[AbilityNames.RECKLESS_RETURN] = EsperantoCardNames[CardNames.RECKLESS].singular;
EsperantoAbilityNames[AbilityNames.RICH_GAIN] = EsperantoCardNames[CardNames.RICH].singular;
EsperantoAbilityNames[AbilityNames.SHY] = EsperantoCardNames[CardNames.SHY].singular;
EsperantoAbilityNames[AbilityNames.TIRELESS_SET_ASIDE] = EsperantoCardNames[CardNames.TIRELESS].singular;
EsperantoAbilityNames[AbilityNames.TIRELESS_RETURN] = EsperantoCardNames[CardNames.TIRELESS].singular;
EsperantoAbilityNames[AbilityNames.STAR_CHART] = EsperantoCardNames[CardNames.STAR_CHART].singular;
EsperantoAbilityNames[AbilityNames.ORDER_OF_ASTROLOGERS] = EsperantoCardNames[CardNames.ORDER_OF_ASTROLOGERS].singular;
EsperantoAbilityNames[AbilityNames.ORDER_OF_MASONS] = EsperantoCardNames[CardNames.ORDER_OF_MASONS].singular;
EsperantoAbilityNames[AbilityNames.FATED] = EsperantoCardNames[CardNames.FATED].singular;
EsperantoAbilityNames[AbilityNames.AVOID] = EsperantoCardNames[CardNames.AVOID].singular;
EsperantoAbilityNames[AbilityNames.HERBALIST_ASK_FOR_DISCARD] = EsperantoCardNames[CardNames.HERBALIST].singular;
EsperantoAbilityNames[AbilityNames.SCHEME_ASK_FOR_DISCARD] = EsperantoCardNames[CardNames.SCHEME].singular;
EsperantoAbilityNames[AbilityNames.TRICKSTER_ASK_FOR_DISCARD] = EsperantoCardNames[CardNames.TRICKSTER].singular;
EsperantoAbilityNames[AbilityNames.MARCHLAND] = EsperantoCardNames[CardNames.MARCHLAND].singular;
EsperantoAbilityNames[AbilityNames.FARMHANDS_SET_ASIDE] = EsperantoCardNames[CardNames.FARMHANDS].singular;
EsperantoAbilityNames[AbilityNames.FARMHANDS_PLAY] = EsperantoCardNames[CardNames.FARMHANDS].singular;
EsperantoAbilityNames[AbilityNames.JOUST_DISCARD] = EsperantoCardNames[CardNames.JOUST].singular;
EsperantoAbilityNames[AbilityNames.INFIRMARY_OVERPAY] = EsperantoCardNames[CardNames.INFIRMARY].singular;
EsperantoAbilityNames[AbilityNames.RESOLVE_INFIRMARY_OVERPAY] = EsperantoCardNames[CardNames.INFIRMARY].singular;
EsperantoAbilityNames[AbilityNames.DRAW_FROM_FOOTPAD] = EsperantoCardNames[CardNames.FOOTPAD].singular;
EsperantoAbilityNames[AbilityNames.FARRIER_OVERPAY] = EsperantoCardNames[CardNames.FARRIER].singular;
EsperantoAbilityNames[AbilityNames.RESOLVE_FARRIER_OVERPAY] = EsperantoCardNames[CardNames.FARRIER].singular;
EsperantoAbilityNames[AbilityNames.DRAW_FROM_SQUIRREL] = EsperantoCardNames[CardNames.WAY_OF_THE_SQUIRREL].singular;
EsperantoAbilityNames[AbilityNames.DRAW_FROM_RIVER] = EsperantoCardNames[CardNames.THE_RIVERS_GIFT].singular;
EsperantoAbilityNames[AbilityNames.DRAW_FROM_FARRIER] = EsperantoCardNames[CardNames.FARRIER].singular;
EsperantoAbilityNames[AbilityNames.FERRYMAN] = EsperantoCardNames[CardNames.FERRYMAN].singular;

EsperantoAbilityNames[AbilityNames.BIDING_TIME_SET_ASIDE_HAND] = EsperantoCardNames[CardNames.BIDING_TIME].singular;
EsperantoAbilityNames[AbilityNames.BIDING_TIME_RETURN_TO_HAND] = EsperantoCardNames[CardNames.BIDING_TIME].singular;
EsperantoAbilityNames[AbilityNames.ENLIGHTENMENT_CANTRIP] = EsperantoCardNames[CardNames.ENLIGHTENMENT].singular;
EsperantoAbilityNames[AbilityNames.ENLIGHTENMENT_REPLACE_ABILITY] = EsperantoCardNames[CardNames.ENLIGHTENMENT].singular;
EsperantoAbilityNames[AbilityNames.GOOD_HARVEST] = EsperantoCardNames[CardNames.GOOD_HARVEST].singular;
EsperantoAbilityNames[AbilityNames.HARSH_WINTER] = EsperantoCardNames[CardNames.HARSH_WINTER].singular;
EsperantoAbilityNames[AbilityNames.KIND_EMPEROR] = EsperantoCardNames[CardNames.KIND_EMPEROR].singular;
EsperantoAbilityNames[AbilityNames.PANIC_BUYS] = EsperantoCardNames[CardNames.PANIC].singular;
EsperantoAbilityNames[AbilityNames.PANIC_RETURN] = EsperantoCardNames[CardNames.PANIC].singular;
EsperantoAbilityNames[AbilityNames.PROGRESS] = EsperantoCardNames[CardNames.PROGRESS].singular;
EsperantoAbilityNames[AbilityNames.RAPID_EXPANSION_SET_ASIDE] = EsperantoCardNames[CardNames.RAPID_EXPANSION].singular;
EsperantoAbilityNames[AbilityNames.RAPID_EXPANSION_PLAY_LATER] = EsperantoCardNames[CardNames.RAPID_EXPANSION].singular;
EsperantoAbilityNames[AbilityNames.SICKNESS] = EsperantoCardNames[CardNames.SICKNESS].singular;
EsperantoAbilityNames[AbilityNames.RIVER_SHRINE] = EsperantoCardNames[CardNames.RIVER_SHRINE].singular;
EsperantoAbilityNames[AbilityNames.SAMURAI] = EsperantoCardNames[CardNames.SAMURAI].singular;
EsperantoAbilityNames[AbilityNames.DAIMYO_REPLAY] = EsperantoCardNames[CardNames.DAIMYO].singular;
EsperantoAbilityNames[AbilityNames.FORESIGHT_RETURN_TO_HAND] = EsperantoCardNames[CardNames.FORESIGHT].singular;