"use strict";

var FrenchEnvironment = new function () {
    this.enter = function () {};
    this.leave = function () {};
    this.strlen = EnglishEnvironment.strlen;

    this.getPileName = function (pile, directon) {
        var pileString = LANGUAGE.getCardName[pile.pileName].plural;
        return colorTextByCardname('la pile des ' + pileString, pile.pileName);
    };

    this.phraseCollection_Or = function (phrases) {
        return EnglishEnvironment.phraseCollection(phrases, FrenchPhrases[Phrases.COMMA], FrenchPhrases[Phrases.OR]);
    };

    this.cardComparator = EnglishEnvironment.cardComparator;

    this.determineFontStyle = EnglishEnvironment.determineFontStyle;
}();