"use strict";

var Events = {
    SERVER_CONNECTION_OPENED: "serverConnectionOpened",
    SERVER_CONNECTION_CLOSED: "serverConnectionClosed",
    SERVER_CONNECTION_ERROR: "serverConnectionError",
    SERVER_RECONNECTED: "serverReconnected",
    CHAT_MESSAGE_RECEIVED: "chatMessageReceived",
    CHAT_MESSAGE_PROCESSED: "chatMessageProcessed",
    INVITE_RECEIVED: "inviteReceived",
    COMMAND_FAILED: "commandFailed",
    ALREADY_LOGGED_IN: "alreadyLoggedIn",
    LOGIN_SUCCESS: "loginSuccess",
    KICK_SUCCESS: "kickSuccess",
    TABLES_OVERVIEW: "tablesOverview",
    TABLE_STATE_CHANGE: "tableStateChange",
    TABLE_RULE_CHANGED: "tableRuleAdded",
    HOST_CHANGED: "hostChanged",
    PLAYER_JOINED: "playerJoined",
    PLAYER_LEFT: "playerLeft",
    NEW_PLAYER_STATUS: "newPlayerStatus",
    META_MODEL_CREATED: "metaModelCreated",
    PRINT_JOIN_GAME: "printJoinGame",
    GAME_STARTED: "gameStarted",
    TABLE_JOINED: "tableJoined",
    SHOW_TABLE: "showTable",
    NEW_ROLE: "newRole",
    TABLE_DETAILS: "tableDetails",
    GAME_FINISHED: "gameFinished",
    GAME_PAGE_LOADED: "gamePageLoaded",
    SCORE_PAGE_LOADED: "scorePageLoaded",
    RETURN_TO_LOBBY: "returnToLobby",
    LOBBY_PAGE_LOADED: "lobbyPageLoaded",
    RETURN_TO_LOGIN: "returnToLogin",
    CLOSE_SIGNUP_FORM: "closeSignup",
    REMOVE_TABLE_RULE: "removeTableRule",
    GAME_MODEL_CREATED: "gameModelCreated",
    RESIZE: "resize",
    CARD_STUDY_REQUEST: "cardStudyRequest",
    SET_BACKGROUND: "setBackground",
    TOGGLE_UNDO: "toggleUndo",
    CLEAR_META_STATE: "clearMetaState",
    USER_PREFERENCES: "userPreferences",
    COMMAND_SUCCEEDED: "commandSucceeded",
    PURCHASE_PRICE: "purchasePrice",
    PURCHASE_REDIRECT: "purchaseRedirect",
    COUNTRY_MISMATCH: "countryMismatch",
    OWNERSHIP_CHANGED: "ownershipChanged",
    RELATIONSHIPS: "relationships",
    AUTOMATCH_STATUS: "automatchStatus",
    AUTOMATCH_FOUND: "automatchFound",
    UNDO_REQUEST: "undoRequest",
    TIMEOUT_OFFER: "timeoutOffer",
    UNDO_REQUEST_CANCELLED: "undoRequestCancelled",
    UNDO_REQUEST_DENIED: "undoRequestDenied",
    IMPORT_ACCOUNT: "importAccount",
    TAB_CLICKED: "tabClicked",
    BOT_ADDED: "botAdded",
    LOGOUT: "logout",
    MAIL_RECEIVED: "mailReceived",
    MAIL_SUMMARIES_RECEIVED: "mailSummariesReceived",
    MAILS_RECEIVED: "mailsReceived",
    GOTO_TAB: "gotoTab",
    LEADERBOARD_RECEIVED: "leaderboardReceived",
    PREGAME_STATUS: "pregameStatus",
    TABLE_TERMINATED: "tableTerminated",
    PLAYER_REPLACED: "playerReplaced",
    PLAYER_DISCONNECTED: "playerDisconnected",
    PLAYER_RECONNECTED: "playerReconnected",
    BONUS_CODE_VALIDATED: "bonusCodeValidated",
    PURCHASE_SUCCESS: "purchaseSuccess",
    PURCHASE_PRICE_AVAILABLE: "purchasePriceAvailable",
    CLEANUP_GAME: "cleanupGame",
    SHOW_SUBSCRIPTIONS: "showSubscriptions",
    LOG_RESIZE: "logResize",
    SELECTION_MODE_CHANGED: "selectionModeChanged",
    REDRAW: "redraw",
    PONG_RECEIVED: "pong",
    NO_PONG_RECEIVED: "no-pong",
    MESSAGE_INDEX_MISMATCH: "message-index-mismatch",
    FORCE_RECONNECT: "forceReconnect",
    INSTRUCT_TO_RECONNECT: "instructToReconnect",
    INSTRUCT_TO_TIME_OUT: "instructToTimeOut"
};