"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TokenLayer = function (_StackViewLayer) {
    _inherits(TokenLayer, _StackViewLayer);

    function TokenLayer(rootNode, cardStack) {
        _classCallCheck(this, TokenLayer);

        var _this = _possibleConstructorReturn(this, (TokenLayer.__proto__ || Object.getPrototypeOf(TokenLayer)).call(this, rootNode, cardStack));

        _this.tokenViews = [];
        _this.counterViews = [];
        _this.cubeViews = [];

        _this.countersNode = document.createElement("token-layer-counters");
        _this.node.appendChild(_this.countersNode);

        _this.tokensNode = document.createElement("token-layer-tokens");
        _this.node.appendChild(_this.tokensNode);

        _this.cubesNode = document.createElement("token-layer-cubes");
        _this.node.appendChild(_this.cubesNode);

        _this.node.style.zIndex = 8;
        _this.node.classList.add("token-layer");
        return _this;
    }

    _createClass(TokenLayer, [{
        key: "destroy",
        value: function destroy() {
            this.counterViews.forEach(function (v) {
                return v.destroy();
            });
        }
    }, {
        key: "updateViews",
        value: function updateViews(pileTokens, pileCounters, cardCounters, projectCubes, w, h) {
            var _this2 = this;

            var realCubeViews = [];
            this.cubeViews.forEach(function (v) {
                if (projectCubes.includes(v.player)) {
                    realCubeViews.push(v);
                } else {
                    v.destroy();
                }
            });
            this.cubeViews = realCubeViews;
            projectCubes.forEach(function (p) {
                if (!_this2.cubeViews.some(function (v) {
                    return v.player === p;
                })) {
                    var view = new ProjectCubeView(p);
                    _this2.cubeViews.push(view);
                    view.render(_this2.cubesNode);
                }
            });

            var counters = pileCounters.concat(cardCounters);
            var realCounterViews = [];
            this.counterViews.forEach(function (v) {
                if (counters.includes(v.counter)) {
                    realCounterViews.push(v);
                } else {
                    v.destroy();
                }
            });
            this.counterViews = realCounterViews;
            counters.forEach(function (counter) {
                if (!_this2.counterViews.some(function (v) {
                    return v.counter === counter;
                })) {
                    var view = new CounterView(counter, counter.counterName.alwaysVisible);
                    _this2.counterViews.push(view);
                    view.render(_this2.countersNode);
                }
            });

            var realTokenViews = [];
            this.tokenViews.forEach(function (v) {
                if (pileTokens.includes(v.token)) {
                    realTokenViews.push(v);
                } else {
                    v.destroy();
                    _this2.tokensNode.removeChild(v.renderNode);
                }
            });
            this.tokenViews = realTokenViews;
            pileTokens.forEach(function (t) {
                if (!_this2.tokenViews.some(function (v) {
                    return v.token === t;
                })) {
                    var view = new TokenView(t);
                    _this2.tokenViews.push(view);
                    var containerNode = document.createElement("token-container");
                    _this2.tokensNode.appendChild(containerNode);
                    view.render(containerNode);
                }
            });
            this.redrawHandler(w, h);
        }
    }, {
        key: "redrawHandler",
        value: function redrawHandler(w, h) {
            var _this3 = this;

            var shouldUpdate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

            var size = 0.2 * Math.min(h, w);
            this.cubeViews.forEach(function (v) {
                v.reposition(new Rectangle(0, 0, size, size));
                v.node.style.order = v.player.index;
            });
            var tokenWidth = Math.min((0.95 * w - size) / (this.tokenViews.length - 1), size + 0.05 * w);
            this.tokenViews.forEach(function (v) {
                v.reposition(new Rectangle(0, 0, size, size));
                v.renderNode.style.width = size * 1.2 + "px";
                v.renderNode.style.height = size + "px";
            });

            size = 0.3 * Math.min(h, w);

            if (this.cardStack.cardSize === CardSizes.MICRO) {
                this.countersNode.style.width = 0.8 * w + "px";
                this.countersNode.style.height = 0.8 * h + "px";
                this.countersNode.style.left = 0.18 * w + "px";
                this.countersNode.style.top = 0.1 * h + "px";
                this.countersNode.style.justifyContent = "flex-end";
                size = 0.9 * h;
            }

            this.counterViews.forEach(function (v) {
                v.reposition(new Rectangle(0, 0, size, size));
                if (v.counter.counterName === CounterNames.SINISTER_PLOT) {
                    if (_this3.cubeViews.some(function (cube) {
                        return cube.player === v.counter.owner;
                    })) v.node.classList.remove("invisible");else v.node.classList.add("invisible");
                }
            });
        }
    }, {
        key: "redraw",
        value: function redraw(w, h) {
            var card = this.cardStack.topCard;
            var pileTokens = void 0,
                pileCounters = void 0,
                cardCounters = void 0,
                projectCubes = void 0;
            if (this.cardStack instanceof AnonymousCardStack || this.cardStack.parentStack !== null) {
                pileTokens = [];
                pileCounters = [];
                cardCounters = [];
                projectCubes = [];
            } else {
                pileTokens = this.cardStack.location.tokens;
                pileCounters = this.cardStack.location.isNowhere ? [] : this.cardStack.location.counters;
                cardCounters = card.counters;
                projectCubes = this.cardStack.location.state.players.filter(function (p) {
                    return p.boughtProjects.includes(card.cardName);
                });
            }

            this.updateViews(pileTokens, pileCounters, cardCounters, projectCubes, w, h);
        }
    }]);

    return TokenLayer;
}(StackViewLayer);