"use strict";

var GermanExpansionNames = {};

GermanExpansionNames[Expansions.PROMOS] = 'Promos';
GermanExpansionNames[Expansions.DEPRECATED] = 'Ausgemustert';
GermanExpansionNames[Expansions.BASE] = 'Basis';
GermanExpansionNames[Expansions.INTRIGUE] = 'Intrige';
GermanExpansionNames[Expansions.SEASIDE] = 'Seaside';
GermanExpansionNames[Expansions.ALCHEMY] = 'Alchemisten';
GermanExpansionNames[Expansions.PROSPERITY] = 'Blütezeit';
GermanExpansionNames[Expansions.CORNUCOPIA_GUILDS] = 'Reiche Ernte & Gilden';
GermanExpansionNames[Expansions.HINTERLANDS] = 'Hinterland';
GermanExpansionNames[Expansions.DARK_AGES] = 'Dark Ages';
GermanExpansionNames[Expansions.ADVENTURES] = 'Abenteuer';
GermanExpansionNames[Expansions.EMPIRES] = 'Empires';
GermanExpansionNames[Expansions.NOCTURNE] = 'Nocturne';
GermanExpansionNames[Expansions.RENAISSANCE] = 'Renaissance';
GermanExpansionNames[Expansions.MENAGERIE] = 'Menagerie';
GermanExpansionNames[Expansions.ALLIES] = 'Verbündete';
GermanExpansionNames[Expansions.PLUNDER] = 'Plünderer';
GermanExpansionNames[Expansions.RISING_SUN] = 'Rising Sun';