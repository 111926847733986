"use strict";

var CzechUserPreferences = {};

CzechUserPreferences[UserPrefIds.EMAIL] = "Email";
CzechUserPreferences[UserPrefIds.CARD_OWNERSHIP] = "Karty které vlastním";
CzechUserPreferences[UserPrefIds.LIKED_CARDS] = "Oblíbené karty";
CzechUserPreferences[UserPrefIds.DISLIKED_CARDS] = "Neoblíbené karty";
CzechUserPreferences[UserPrefIds.BANNED_CARDS] = "Zakázané karty";
CzechUserPreferences[UserPrefIds.MOVE_KINGDOM] = "Přesunout Království a hrací plochu";
CzechUserPreferences[UserPrefIds.CONFIRM_ACTIONS] = "Potvrzovat vyhazování, odkládání a vyhoštění";
CzechUserPreferences[UserPrefIds.PREFERRED_BACKGROUND] = "Preferovat pozadí";
CzechUserPreferences[UserPrefIds.USE_AUTOBUY] = "Použít automatický nákup";
CzechUserPreferences[UserPrefIds.FOLLOWERS_SEE_ACTIVITY] = "Sledující vidí tvou aktivitu";
CzechUserPreferences[UserPrefIds.NOTIFICATION_SOUNDS] = "Zapnout zvuky upozornění";
CzechUserPreferences[UserPrefIds.SOUND_FX] = "Zapnout zvuky hry";
CzechUserPreferences[UserPrefIds.SPECTATORS_SEE_HAND] = "Pozorovatelé mohou vidět mé karty";
CzechUserPreferences[UserPrefIds.AUTOPLAY_SETTINGS] = " Hrát automaticky následující karty";
CzechUserPreferences[UserPrefIds.LOG_FIXED_BASIC_BONUS] = "Výpis všeho +Akce, +Nákup, +Mince";
CzechUserPreferences[UserPrefIds.MAKE_THRONE_ROOM_ORANGE] = "Barva Trůnního sálu zůstává po zahrání oranžová";
CzechUserPreferences[UserPrefIds.MAKE_DURATIONS_NOT_ORANGE] = "Dynamicky odstraňovat oranžovou v průběhu tahu";
CzechUserPreferences[UserPrefIds.TRANSPARENT_WAY_BUTTON] = "Označit tlačítka Způsob";
CzechUserPreferences[UserPrefIds.CHAT_VISIBLE] = "Chat is visible";
CzechUserPreferences[UserPrefIds.SPECTATOR_CHAT_IN_AUTOMATCH] = "See spectator chat in automatch";
CzechUserPreferences[UserPrefIds.CARD_POOL_LEVEL] = "Card Pool Level"; // TRANSLATE ME
CzechUserPreferences[UserPrefIds.ANIMATION_TYPE] = "Animation Type"; // TRANSLATE ME

CzechUserPreferences.USERNAME = "Přezdívka";
CzechUserPreferences.PASSWORD = "Heslo";
CzechUserPreferences.OLD_PASSWORD = "Staré heslo";
CzechUserPreferences.NEW_PASSWORD = "Nové heslo";
CzechUserPreferences.REPEAT_PASSWORD = "Znovu nové heslo";
CzechUserPreferences.OWNERSHIP_INTRO = "Vaše předplatné:";
CzechUserPreferences.OWNERSHIP_INTRO_EMPTY = "Nemáte žádné předplatné.";
CzechUserPreferences.FAMILIAR_WITH = "Jsem seznámen s: ";
CzechUserPreferences.USE_BONUS_CODE = "Použít bonusový kód";
CzechUserPreferences.BONUS_CODE_ORIGIN = "Váš bonusový kód je z: ";
CzechUserPreferences.BONUS_CODE_CHOOSE_EXPANSION = "Vyberte rozšíření: ";

var CzechProfileButtons = {};

CzechProfileButtons[true] = "Ano";
CzechProfileButtons[false] = "Ne";
CzechProfileButtons[UserPrefIds.FAMILIAR_CARDS] = {
    ALL: "Toto rozšíření",
    NONE: "Nic z tohoto rozšíření",
    ALL_EXPANSIONS: "Všechny rozšíření"
};

var CzechStoreLabels = {};

CzechStoreLabels[StorePackages.HALF] = "Stříbrné předplatné";
CzechStoreLabels[StorePackages.ALL] = "Zlaté předplatné";

CzechStoreLabels[PriceCategories.PURCHASED] = ''; // "Zaplacené rozšíření: ";
CzechStoreLabels[PriceCategories.ALREADY_OWNED] = "Již předplaceno";
CzechStoreLabels[PriceCategories.DISCOUNT] = "Sleva";
CzechStoreLabels[PriceCategories.TRANSACTION] = "Cena za transakci";
CzechStoreLabels[PriceCategories.SUBTOTAL] = "Mezičástka";
CzechStoreLabels[PriceCategories.VAT] = "Daň";
CzechStoreLabels[PriceCategories.TOTAL] = "Finální částka";

CzechStoreLabels.SELLER_INFO = "Informace o prodejci";
CzechStoreLabels.PRODUCT_CONFIRM = "Platba do DATE: EXPANSIONS";
CzechStoreLabels.PAYMENT_HANDLER = "Platba bude převzata naším poskytovatelkou Mollie.";
CzechStoreLabels.PROCEED_TO_PAYMENT = "Pokračovat k platbě";

var CzechAutoplayLabels = {};

CzechAutoplayLabels.REGULAR_OPTIONS = "Základní nastavení";
CzechAutoplayLabels.AUTOPLAY_OPTIONS = "Nastavení automatického hraní";
CzechAutoplayLabels.AUTOPLAY_DOCUMENTATION_GENERIC = "Co je automatické hraní?";
CzechAutoplayLabels.AUTOPLAY_DOCUMENTATION_SPECIFIC = "-konkrétní nastavení";
CzechAutoplayLabels.CUSTOMIZE_OPTIONS = "Přizpůsobit nastavení";
CzechAutoplayLabels.ALL_OFF = "Vše vypnuto";
CzechAutoplayLabels.ALL_DEFAULT = "Vše výchozí";
CzechAutoplayLabels.ALL_CONVENIENT = "Vše vyhovující";