"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var NonSupplyZone = function (_SupplyZone) {
    _inherits(NonSupplyZone, _SupplyZone);

    function NonSupplyZone(state, index, pileName, owner, createdBy, attachedTrait) {
        _classCallCheck(this, NonSupplyZone);

        var _this = _possibleConstructorReturn(this, (NonSupplyZone.__proto__ || Object.getPrototypeOf(NonSupplyZone)).call(this, state, index, pileName, owner, createdBy, attachedTrait));

        _this.indexOfType = _this.state.zones.filter(function (z) {
            return z instanceof NonSupplyZone;
        }).length;

        return _this;
    }

    _createClass(NonSupplyZone, [{
        key: "reposition",
        value: function reposition() {
            var shouldAnimate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            if (this.layoutModel.layout === null) return;
            this.bbox = this.layoutModel.layout.microElements[this.indexOfType].bbox;

            var repositionStack = function repositionStack(stack, x, y, width, height, zIndex) {
                if (shouldAnimate) stack.animator = new TransitionAnimator(stack, absoluteTransitionScript(new Rectangle(x, y, width, height, zIndex)), stack, function () {});else stack.reposition(x, y, width, height, zIndex);
            };
            repositionStack(this.cardStacks[0], this.bbox.x + this.bbox.width * 0.01, this.bbox.y + this.bbox.height * 0.03, this.bbox.width * 0.98, this.bbox.height * 0.94, this.bbox.zIndex);
        }
    }, {
        key: "initialize",
        value: function initialize(cardIds) {
            var _this2 = this;

            var newStack = new CardStack(this.state.getAnonCard(this.pileName), this, CardSizes.MICRO);
            newStack.getCounter = function () {
                return newStack.cards.length + newStack.anonymousCards;
            };
            newStack.updateCount();
            newStack.addFilter("shifted-name");
            cardIds.forEach(function (id) {
                _this2.cardStacks[0].addAnonymousCard();
            });
            this.reposition();
        }
    }, {
        key: "mergeStacks",
        value: function mergeStacks(targetStack, transitionStack) {
            targetStack.anonymousCards += transitionStack.anonymousCards + transitionStack.cards.length;
            transitionStack.destroy(targetStack);
            targetStack.removeFilter("intangible");
        }
    }, {
        key: "spliceCard",
        value: function spliceCard(card) {
            var newStack = this.spliceAnonymousCard();
            newStack.anonymousCards--;
            newStack.addCard(card);
            return newStack;
        }
    }, {
        key: "spliceAnonymousCard",
        value: function spliceAnonymousCard() {
            this.cardStacks[0].anonymousCards--;
            var newStack = new CardStack(this.state.getAnonCard(CardNames.BACK), this.state.nowhere);
            newStack.anonymousCards++;
            newStack.copyPosition(this.cardStacks[0]);
            return newStack;
        }
    }]);

    return NonSupplyZone;
}(SupplyZone);

// let zoneNameNonsupply = new ZoneName(
//     "Non Supply Pile", null, [ZoneTypes.SUPPLY, ZoneTypes.CAN_ORPHAN],
//     supplyPileInserter(CardSizes.MICRO),
//     supplyPileRemover(CardSizes.MICRO),
//     (zone) => {
//         if (zone.cardStacks.length > 0 && zone.cardStacks[0] instanceof AnonymousCardStack) {
//             zone.cardStacks[0].overrideCounter++;
//         }
//     },
//     supplyPileRemover(CardSizes.MICRO),
//     (zone, props) => {
//         zone.bbox = zone.state.layoutService.layout.microElements[zone.indexOfType].bbox;
//         supplyPilePositioner(CardSizes.MICRO)(zone, props);
//     },
//     supplyAnimatedInserter(CardSizes.MICRO),
// )