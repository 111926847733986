"use strict";

var DutchQuestionDescriptions = {};

DutchQuestionDescriptions[QuestionIds.GAME_RESOLVE_ABILITY] = ["Resolve a forced ability:", "(click below the log)"];
DutchQuestionDescriptions[QuestionIds.GAME_MAY_RESOLVE_ABILITY] = ["Resolve an optional ability.", "(click below the log)"];
DutchQuestionDescriptions[QuestionIds.GAME_MAY_REACT_WITH] = ["React with something.", "(click below the log)"];
DutchQuestionDescriptions[QuestionIds.GAME_TURN_SELECT] = ["Select a turn.", "What kind of turn next?"];
DutchQuestionDescriptions[QuestionIds.GAME_MAY_BUY] = ["buy cards.", "You may buy cards"];
DutchQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING] = ["start buying cards.", "You may buy cards"];
DutchQuestionDescriptions[QuestionIds.GAME_MAY_BUY_NONCARDS] = ["buy, but not cards.", "You may buy, but not cards"];
DutchQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING_NONCARDS] = ["start buying, but not cards.", "You may buy, but not cards"];
DutchQuestionDescriptions[QuestionIds.GAME_REPAY_HOW_MUCH] = ["Repay debt.", "You may pay off debt"];
DutchQuestionDescriptions[QuestionIds.GAME_SPEND_COIN_TOKENS] = ["Spend coin tokens.", "You may spend coin tokens"];
DutchQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_ACTION] = ["play an Action from your hand.", "You may play Actions"];
DutchQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_TREASURES] = ["play treasures.", "You may play Treasures"];
DutchQuestionDescriptions[QuestionIds.GAME_PLAY_ALL_TREASURES] = ["autoplay treasures.", "GAME_PLAY_ALL_TREASURES"];
DutchQuestionDescriptions[QuestionIds.GAME_ACTION_PHASE] = ["It's your action phase", "You may play Actions"];
DutchQuestionDescriptions[QuestionIds.GAME_BUY_PHASE] = ["It's your buy phase", "You may buy cards"];
DutchQuestionDescriptions[QuestionIds.GAME_BUY_PHASE_NONCARDS] = ["It's your buy phase", "You may buy, but not cards"];
DutchQuestionDescriptions[QuestionIds.GAME_CLEANUP_PHASE] = ["Choose clean-up items and resolution order.", "Choose cleanup abilities"];
DutchQuestionDescriptions[QuestionIds.ARTISAN_GAIN] = ["Gain a card to your hand costing up to $5. ", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.ARTISAN_TOPDECK] = ["Put a card from your hand onto your deck.", "Topdeck a card"];
DutchQuestionDescriptions[QuestionIds.BANDIT] = ["Trash a card from PLAYER0.", "Trash a card from PLAYER0."];
DutchQuestionDescriptions[QuestionIds.BUREAUCRAT] = ["Reveal a Victory card from your hand and put it onto your deck.", "Topdeck a Victory card"];
DutchQuestionDescriptions[QuestionIds.CELLAR] = ["Discard any number of cards, then draw that many.", "Discard, then draw"];
DutchQuestionDescriptions[QuestionIds.CHAPEL] = ["Trash up to 4 cards from your hand.", "Trash up to 4 cards"];
DutchQuestionDescriptions[QuestionIds.HARBINGER_TOPDECK] = ["You may topdeck a card from your discard pile to put onto your deck.", "You may topdeck"];
DutchQuestionDescriptions[QuestionIds.LIBRARY] = ["You may skip CARDS0 by setting it aside.", "You may skip CARDS0"];
DutchQuestionDescriptions[QuestionIds.MILITIA] = ["Discard down to 3 cards in hand.", "Discard down to 3"];
DutchQuestionDescriptions[QuestionIds.MINE_TRASH] = ["You may trash a Treasure from your hand. Gain a Treasure to your hand costing up to $3 more than it.", "You may improve a Treasure"];
DutchQuestionDescriptions[QuestionIds.MINE_GAIN] = ["Gain a Treasure to your hand costing up to AMOUNT0.", "Gain a Treasure"];
DutchQuestionDescriptions[QuestionIds.MONEYLENDER] = ["You may trash a Copper from your hand for +$3.", "You may trash a Copper"];
DutchQuestionDescriptions[QuestionIds.POACHER] = ["Discard Argument0.", "Discard Argument0."];
DutchQuestionDescriptions[QuestionIds.REMODEL_TRASH] = ["Trash a card from your hand. Gain a card costing up to $2 more than it.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.REMODEL_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.SENTRY_TRASH] = ["Trash any of the revealed cards", "You may trash"];
DutchQuestionDescriptions[QuestionIds.SENTRY_DISCARD] = ["Discard any of the revealed cards", "You may discard"];
DutchQuestionDescriptions[QuestionIds.SENTRY_TOPDECK] = ["Put them back onto your deck in any order", "Topdeck in any order"];
DutchQuestionDescriptions[QuestionIds.THRONE_ROOM] = ["You may play an Action card from your hand twice.", "You may play an Action twice"];
DutchQuestionDescriptions[QuestionIds.VASSAL] = ["You may play CARDS0 from your discard pile.", "You may play CARDS0"];
DutchQuestionDescriptions[QuestionIds.WORKSHOP] = ["Gain a card costing up to $4.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.TRASHING_TOKEN] = ["You may trash a card from your hand.", "You may trash a card"];
DutchQuestionDescriptions[QuestionIds.ALMS] = ["Gain a card costing up to $4.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.AMULET_MODE] = ["Choose one: +$1; or trash a card from your hand; or gain a Silver.", "Choose how to play Amulet"];
DutchQuestionDescriptions[QuestionIds.AMULET_TRASH] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.ARTIFICER_DELAY] = ["You may discard any number of cards. You may gain a card costing exactly $1 per card discarded, onto your deck.", "You may use Artificer"];
DutchQuestionDescriptions[QuestionIds.ARTIFICER_DISCARD] = ["Discard any number of cards. You may gain a card costing exactly $1 per card discarded, onto your deck.", "Discard, then gain"];
DutchQuestionDescriptions[QuestionIds.ARTIFICER_GAIN] = ["You may gain a card costing exactly AMOUNT0, onto your deck.", "You may gain a card"];
DutchQuestionDescriptions[QuestionIds.BALL_FIRST] = ["Gain 2 cards each costing up to $4.", "Gain two cards"];
DutchQuestionDescriptions[QuestionIds.BALL_SECOND] = ["Gain another card costing up to $4.", "Gain another card"];
DutchQuestionDescriptions[QuestionIds.BONFIRE] = ["Trash up to 2 cards you have in play.", "Trash up to 2 cards from play"];
DutchQuestionDescriptions[QuestionIds.DISCIPLE] = ["You may play an Action card from your hand twice. Gain a copy of it.", "Play twice, then gain a copy"];
DutchQuestionDescriptions[QuestionIds.DUNGEON] = ["Discard 2 cards.", "Discard 2 cards"];
DutchQuestionDescriptions[QuestionIds.FERRY] = ["Move your -$2 cost token to an Action Supply pile.", "Move -$2 cost token"];
DutchQuestionDescriptions[QuestionIds.FUGITIVE] = ["Discard a card.", "Discard a card"];
DutchQuestionDescriptions[QuestionIds.GEAR] = ["Set aside up to 2 cards from your hand. At the start of your next turn, put them into your hand.", "Set aside up to 2 cards"];
DutchQuestionDescriptions[QuestionIds.HAUNTED_WOODS] = ["Put your hand onto your deck in any order", "Put your hand onto your deck"];
DutchQuestionDescriptions[QuestionIds.HERO] = ["Gain a Treasure.", "Gain a Treasure"];
DutchQuestionDescriptions[QuestionIds.LOST_ARTS_NEW] = ["Put your +1 Action token on an Action Supply pile.", "Move +1 Action token"];
DutchQuestionDescriptions[QuestionIds.LOST_ARTS_MOVE] = ["Move your +1 Action token to an Action Supply pile.", "Move +1 Action token"];
DutchQuestionDescriptions[QuestionIds.MESSENGER_DISCARD] = ["You may put your deck into your discard pile.", "You may discard your deck"];
DutchQuestionDescriptions[QuestionIds.MESSENGER_GAIN] = ["Gain a card costing up to $4, and each other player gains a copy of it.", "Distribute a card costing up to $4"];
DutchQuestionDescriptions[QuestionIds.MISER] = ["Choose one: Put a Copper from your hand onto your Tavern mat; or +AMOUNT0", "Copper to mat or +AMOUNT0?"];
DutchQuestionDescriptions[QuestionIds.PATHFINDING_NEW] = ["Put your +1 Card token on an Action Supply pile.", "Move +1 Card token"];
DutchQuestionDescriptions[QuestionIds.PATHFINDING_MOVE] = ["Move your +1 Card token to an Action Supply pile.", "Move +1 Card token"];
DutchQuestionDescriptions[QuestionIds.PILGRIMAGE_FIRST] = ["You may choose up to 3 differently named cards you have in play and gain a copy of each.", "Choose 3 cards to gain"];
DutchQuestionDescriptions[QuestionIds.PILGRIMAGE_SECOND] = ["You may choose another 2 differently named cards you have in play and gain a copy of each.", "Choose 2 cards to gain"];
DutchQuestionDescriptions[QuestionIds.PILGRIMAGE_THIRD] = ["You may choose one more differently named card you have in play to gain a copy of.", "Choose 1 card to gain"];
DutchQuestionDescriptions[QuestionIds.PLAN_NEW] = ["Put your Trashing token on an Action Supply pile.", "Move Trashing token"];
DutchQuestionDescriptions[QuestionIds.PLAN_MOVE] = ["Move your Trashing token to an Action Supply pile.", "Move Trashing token"];
DutchQuestionDescriptions[QuestionIds.QUEST_MODE] = ["Choose between discarding an Attack, two Curses, or six cards.", "Choose your quest"];
DutchQuestionDescriptions[QuestionIds.QUEST_ATTACK] = ["Discard an Attack card.", "Discard an Attack"];
DutchQuestionDescriptions[QuestionIds.QUEST_6CARDS] = ["Discard six cards.", "Discard six cards"];
DutchQuestionDescriptions[QuestionIds.RATCATCHER] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_PLAY] = ["You may trash Raze from play.", "You may trash Raze"];
DutchQuestionDescriptions[QuestionIds.RAZE_ALL] = ["Trash a card from your hand or Raze from play.", "Trash a card or trash Raze"];
DutchQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_HAND] = ["You may trash a card from your hand.", "You may trash a card"];
DutchQuestionDescriptions[QuestionIds.RAZE_CHOOSE_CARD] = ["Choose a card to put into your hand.", "Put a card into your hand"];
DutchQuestionDescriptions[QuestionIds.SAVE] = ["Set aside a card from your hand, and put it into your hand at end of turn (after drawing).", "Set a card aside"];
DutchQuestionDescriptions[QuestionIds.SCOUTING_PARTY_DISCARD] = ["Discard NUMBER0 cards.", "Discard NUMBER0 cards."];
DutchQuestionDescriptions[QuestionIds.SCOUTING_PARTY_TOPDECK] = ["Put the remaining two cards on top of your deck in any order.", "Topdeck these cards"];
DutchQuestionDescriptions[QuestionIds.SEAWAY] = ["Gain an Action card costing up to $4. Move your +1 Buy token to its pile.", "Gain an Action card"];
DutchQuestionDescriptions[QuestionIds.SOLDIER] = ["Discard a card.", "Discard a card"];
DutchQuestionDescriptions[QuestionIds.STORYTELLER_FIRST] = ["Play up to 3 Treasures from your hand.", "Play up to 3 Treasures"];
DutchQuestionDescriptions[QuestionIds.STORYTELLER_SECOND] = ["Play up to 2 more Treasures from your hand.", "Play up to 2 Treasures"];
DutchQuestionDescriptions[QuestionIds.STORYTELLER_THIRD] = ["You may play another Treasure from your hand.", "You may play another Treasure"];
DutchQuestionDescriptions[QuestionIds.TEACHER_CHOOSE_TOKEN] = ["Choose which token to move.", "Choose which token"];
DutchQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_MOVE] = ["Put your +1 Action token on an Action Supply pile you have no tokens on.", "Move +1 Action token"];
DutchQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_NEW] = ["Move your +1 Action token to an Action Supply pile you have no tokens on.", "Move +1 Action token"];
DutchQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_MOVE] = ["Put your +1 Card token on an Action Supply pile you have no tokens on.", "Move +1 Card token"];
DutchQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_NEW] = ["Move your +1 Card token to an Action Supply pile you have no tokens on. ", "Move +1 Card token"];
DutchQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_MOVE] = ["Put your +1 Coin token on an Action Supply pile you have no tokens on.", "Move +1 Coin token"];
DutchQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_NEW] = ["Move your +1 Coin token to an Action Supply pile you have no tokens on.", "Move +1 Coin token"];
DutchQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_MOVE] = ["Put your +1 Buy token on an Action Supply pile you have no tokens on.", "Move +1 Buy token"];
DutchQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_NEW] = ["Move your +1 Buy token to an Action Supply pile you have no tokens on.", "Move +1 Buy token"];
DutchQuestionDescriptions[QuestionIds.TRADE] = ["Trash up to 2 cards from your hand. Gain a Silver per card you trashed.", "Trash up to 2 cards"];
DutchQuestionDescriptions[QuestionIds.TRAINING_NEW] = ["Put your +1 Coin token on an Action Supply pile.", "Move +1 Coin token"];
DutchQuestionDescriptions[QuestionIds.TRAINING_MOVE] = ["Move your +1 Coin token to an Action Supply pile.", "Move +1 Coin token"];
DutchQuestionDescriptions[QuestionIds.TRANSMOGRIFY_TRASH] = ["Trash a card from your hand, and gain a card costing up to $1 more than it into your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.TRANSMOGRIFY_GAIN] = ["Gain a card costing up to AMOUNT0 into your hand.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.APOTHECARY] = ["Put the cards back on your deck in any order.", "Topdeck these cards"];
DutchQuestionDescriptions[QuestionIds.APPRENTICE] = ["Trash a card from your hand. +1 Card per $1 it costs. +2 Cards if it has [P] in its cost.", "Trash, then draw"];
DutchQuestionDescriptions[QuestionIds.GOLEM] = ["Choose which Action to play first.", "Choose play order"];
DutchQuestionDescriptions[QuestionIds.HERBALIST] = ["You may put one of your Treasures from play onto your deck.", "You may topdeck a Treasure"];
DutchQuestionDescriptions[QuestionIds.SCRYING_POOL_YOU] = ["Keep or discard CARDS0", "You: keep or discard CARDS0"];
DutchQuestionDescriptions[QuestionIds.SCRYING_POOL_OTHER] = ["Choose whether PLAYER1 should keep or discard CARDS0.", "PLAYER1: keep or discard CARDS0."];
DutchQuestionDescriptions[QuestionIds.TRANSMUTE] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.UNIVERSITY] = ["You may gain an Action card costing up to $5.", "You may gain an Action"];
DutchQuestionDescriptions[QuestionIds.FOLLOWERS] = ["Discard down to 3 cards in hand.", "Discard down to 3"];
DutchQuestionDescriptions[QuestionIds.HAMLET] = ["You may discard to get +1 Action and/or +1 Buy", "You may discard"];
DutchQuestionDescriptions[QuestionIds.HAMLET_ACTION] = ["Discard a card for +1 Action.", "Discard for +1 Action"];
DutchQuestionDescriptions[QuestionIds.HAMLET_BUY] = ["Discard a card for +1 Buy.", "Discard for +1 Buy"];
DutchQuestionDescriptions[QuestionIds.HAMLET_BOTH] = ["Discard 2 cards.", "Discard 2 cards"];
DutchQuestionDescriptions[QuestionIds.HORN_OF_PLENTY] = ["Gain a card costing up to AMOUNT0. If it's a Victory card, trash this.", "Gain a card up to AMOUNT0"];
DutchQuestionDescriptions[QuestionIds.HORSE_TRADERS] = ["Discard 2 cards.", "Discard 2 cards."];
DutchQuestionDescriptions[QuestionIds.JESTER] = ["Choose who gains CARDS0: You or PLAYER1.", "Choose who gains CARDS0"];
DutchQuestionDescriptions[QuestionIds.REMAKE_TRASH_FIRST] = ["Do this twice: Trash a card from your hand, then gain a card costing exactly $1 more than it.", "Trash first card"];
DutchQuestionDescriptions[QuestionIds.REMAKE_TRASH_SECOND] = ["Trash another card from your hand, then gain a card costing exactly $1 more than it.", "Trash another card"];
DutchQuestionDescriptions[QuestionIds.REMAKE_GAIN_FIRST] = ["Gain a card costing exactly COST0", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.REMAKE_GAIN_SECOND] = ["Gain a card costing exactly COST0", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.TOURNAMENT_GAIN_PRIZE_OR_DUCHY] = ["Gain a Prize or a Duchy onto your deck.", "Gain a Prize or a Duchy"];
DutchQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_PRIZE] = ["You may gain a Prize onto your deck.", "You may gain a Prize"];
DutchQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_DUCHY] = ["You may gain a Duchy onto your deck.", "You may gain a Duchy"];
DutchQuestionDescriptions[QuestionIds.TOURNAMENT_REVEAL] = ["You may reveal a Province.", "You may reveal a Province"];
DutchQuestionDescriptions[QuestionIds.TRUSTY_STEED] = ["Choose how to play Trusty Steed.", "Choose how to play Trusty Steed"];
DutchQuestionDescriptions[QuestionIds.YOUNG_WITCH] = ["Discard 2 cards.", "Discard 2 cards"];
DutchQuestionDescriptions[QuestionIds.YOUNG_WITCH_BANE] = ["You may reveal CARDS0 to block Young Witch.", "Reveal CARDS0 to block Young Witch?"];
DutchQuestionDescriptions[QuestionIds.ALTAR_TRASH] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.ALTAR_GAIN] = ["Gain a card costing up to $5.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.ARMORY] = ["Gain a card onto your deck costing up to $4.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.CATACOMBS_MODE] = ["Put CARDS0 into your hand; or discard them and +3 Cards.", "Take or draw new?"];
DutchQuestionDescriptions[QuestionIds.CATACOMBS_GAIN] = ["Gain a card cheaper than AMOUNT0.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.COUNT_PAY] = ["Choose one: Discard 2 cards; or put a card from your hand onto your deck; or gain a Copper.", "Choose Count payment"];
DutchQuestionDescriptions[QuestionIds.COUNT_DISCARD] = ["Discard 2 cards.", "Discard 2 cards"];
DutchQuestionDescriptions[QuestionIds.COUNT_TOPDECK] = ["Put a card from your hand onto your deck.", "Topdeck a card"];
DutchQuestionDescriptions[QuestionIds.COUNT_PROFIT] = ["Choose one: +$3; or trash your hand; or gain a Duchy.", "Choose Count profit"];
DutchQuestionDescriptions[QuestionIds.COUNTERFEIT] = ["You may play a Treasure from your hand twice. If you do, trash that Treasure.", "Play twice, then trash"];
DutchQuestionDescriptions[QuestionIds.CULTIST] = ["You may play a Cultist from your hand for free.", "Play another Cultist?"];
DutchQuestionDescriptions[QuestionIds.DAME_ANNA] = ["You may trash up to 2 cards from your hand.", "Trash up to 2 cards"];
DutchQuestionDescriptions[QuestionIds.DAME_NATALIE] = ["You may gain a card costing up to $3.", "You may gain a card"];
DutchQuestionDescriptions[QuestionIds.DEATH_CART_INPLAY] = ["You may trash an Action card from your hand. If you don't, trash Death Cart from play.", "You may trash an Action"];
DutchQuestionDescriptions[QuestionIds.DEATH_CART_GONE] = ["You may trash an Action card from your hand, or fail to trash Death Cart from play.", "You may trash an Action"];
DutchQuestionDescriptions[QuestionIds.FORAGER] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.KNIGHTS] = ["Choose a card to trash.", "Choose a card to trash"];
DutchQuestionDescriptions[QuestionIds.KNIGHTS_ALREADY_DIES] = ["Choose a card to trash. (CARDS0 already dies)", "Choose a card to trash"];
DutchQuestionDescriptions[QuestionIds.KNIGHTS_STILL_MATTERS] = ["Choose a card to trash (CARDS1 will be trashed if you trash CARDS0)", "Choose a card to trash"];
DutchQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_EXPAND] = ["You may trash from your hand and gain a card costing up to $3 more than it.", "You may trash a card"];
DutchQuestionDescriptions[QuestionIds.GRAVEROBBER_EXPAND] = ["Trash from your hand and gain a card costing up to $3 more than it.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_GAIN] = ["You may gain from the trash onto your deck.", "You may gain from trash"];
DutchQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN] = ["Gain from the trash onto your deck.", "Gain from the trash"];
DutchQuestionDescriptions[QuestionIds.GRAVEROBBER_COMPLEX] = ["When playing Graverobber", "When playing Graverobber"];
DutchQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN_SUPPLY] = ["Gain a card costing up to AMOUNT0.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.HERMIT_TRASH] = ["You may trash a non-Treasure card from your discard pile or hand.", "You may trash"];
DutchQuestionDescriptions[QuestionIds.HERMIT_GAIN] = ["Gain a card costing up to $3.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.HUNTING_GROUNDS] = ["Gain a Duchy or 3 Estates.", "Duchy or 3 Estates?"];
DutchQuestionDescriptions[QuestionIds.IRONMONGER] = ["You may keep or discard CARDS0.", "You may discard CARDS0"];
DutchQuestionDescriptions[QuestionIds.JUNK_DEALER] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.MERCENARY_ONE] = ["You may trash a card from your hand (for no bonus).", "You may trash a card"];
DutchQuestionDescriptions[QuestionIds.MERCENARY_MULTIPLE] = ["You may trash two cards from your hand.", "You may trash two cards"];
DutchQuestionDescriptions[QuestionIds.MERCENARY_DISCARD] = ["Discard down to 3 cards.", "Discard down to 3"];
DutchQuestionDescriptions[QuestionIds.MYSTIC] = ["Guess what card is on top of your deck.", "Guess a card"];
DutchQuestionDescriptions[QuestionIds.PILLAGE] = ["Choose which card PLAYER0 discards.", "Choose discard for PLAYER0"];
DutchQuestionDescriptions[QuestionIds.PROCESSION_PLAY] = ["You may play an Action card from your hand twice.", "You may play an Action twice."];
DutchQuestionDescriptions[QuestionIds.PROCESSION_GAIN] = ["Gain an Action costing exactly AMOUNT0.", "Gain an Action"];
DutchQuestionDescriptions[QuestionIds.RATS_TRASH_ANY] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.RATS_TRASH_OTHER] = ["Trash a card from your hand other than a Rats.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.REBUILD_SKIP] = ["Name a Victory card to skip (or Copper).", "Name a card to skip"];
DutchQuestionDescriptions[QuestionIds.REBUILD_GAIN] = ["Gain a Victory card costing up to AMOUNT0.", "Gain a Victory card"];
DutchQuestionDescriptions[QuestionIds.ROGUE_TRASH] = ["Choose a card from PLAYER0 to trash.", "Trash a card from PLAYER0"];
DutchQuestionDescriptions[QuestionIds.ROGUE_GAIN] = ["Gain a card from the trash costing from $3 to $6.", "Gain a card from the trash"];
DutchQuestionDescriptions[QuestionIds.SCAVENGER] = ["You may put your deck into your discard pile.", "You may discard your deck"];
DutchQuestionDescriptions[QuestionIds.SCAVENGER_TOPDECK] = ["Put a card from your discard pile onto your deck.", "Topdeck a card"];
DutchQuestionDescriptions[QuestionIds.SIR_MICHAEL] = ["Discard down to 3 cards.", "Discard down to 3 cards"];
DutchQuestionDescriptions[QuestionIds.SQUIRE_MODE] = ["Choose one: +2 Actions; or +2 Buys; or gain a Silver.", "Choose Squire mode"];
DutchQuestionDescriptions[QuestionIds.SQUIRE_GAIN] = ["Gain an Attack card.", "Gain an Attack"];
DutchQuestionDescriptions[QuestionIds.STOREROOM_CARDS] = ["Discard any number of cards, then draw that many.", "Discard for cards"];
DutchQuestionDescriptions[QuestionIds.STOREROOM_COINS] = ["Discard any number of cards for +$1 each.", "Discard for coins"];
DutchQuestionDescriptions[QuestionIds.SURVIVORS] = ["Topdeck these two cards in any order, or discard them both.", "Topdeck or discard"];
DutchQuestionDescriptions[QuestionIds.URCHIN] = ["Discard down to 4 cards.", "Discard down to 4 cards"];
DutchQuestionDescriptions[QuestionIds.WANDERING_MINSTREL] = ["Put the Action cards back in any order.", "Topdeck in any order"];
DutchQuestionDescriptions[QuestionIds.ADVANCE_TRASH] = ["You may trash an Action card from your hand.", "You may trash an Action"];
DutchQuestionDescriptions[QuestionIds.ADVANCE_GAIN] = ["Gain an Action card costing up to $6.", "Gain an Action"];
DutchQuestionDescriptions[QuestionIds.ANNEX] = ["Choose up to 5 cards not to shuffle into your deck.", "Choose up to 5 cards"];
DutchQuestionDescriptions[QuestionIds.ARCHIVE] = ["Choose a card to put into your hand.", "Choose a card"];
DutchQuestionDescriptions[QuestionIds.ARENA] = ["You may discard an Action card for 2VP.", "Arena: discard for 2VP?"];
DutchQuestionDescriptions[QuestionIds.BANQUET] = ["Gain a non-Victory card costing up to $5.", "Gain a non-Victory"];
DutchQuestionDescriptions[QuestionIds.BUSTLING_VILLAGE] = ["You may put a Settlers into your hand.", "You may take a Settlers"];
DutchQuestionDescriptions[QuestionIds.CATAPULT_TRASH] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.CATAPULT_DISCARD] = ["Discard down to 3 cards.", "Discard down to 3 cards"];
DutchQuestionDescriptions[QuestionIds.CHARM_MODE] = ["Choose how to play Charm", "Choose how to play Charm"];
DutchQuestionDescriptions[QuestionIds.CHARM_GAIN] = ["Gain a card other than CARDS0 costing exactly AMOUNT1.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.CROWN_ACTION] = ["You may play an Action from your hand twice.", "You may play an Action twice"];
DutchQuestionDescriptions[QuestionIds.CROWN_TREASURE] = ["You may play a Treasure from your hand twice.", "You may play a Treasure twice"];
DutchQuestionDescriptions[QuestionIds.DONATE] = ["Trash any number of cards.", "Trash any number"];
DutchQuestionDescriptions[QuestionIds.ENCAMPMENT] = ["You may reveal a Gold or Plunder from your hand.", "You may reveal Gold or Plunder"];
DutchQuestionDescriptions[QuestionIds.ENGINEER_GAIN] = ["Gain a card costing up to $4.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.ENGINEER_TRASH] = ["You may trash the Engineer.", "You may trash the Engineer"];
DutchQuestionDescriptions[QuestionIds.FORUM] = ["Discard 2 cards.", "Discard 2 cards"];
DutchQuestionDescriptions[QuestionIds.GLADIATOR_REVEAL] = ["Reveal a card from your hand.", "Reveal a card"];
DutchQuestionDescriptions[QuestionIds.GLADIATOR_PRETEND] = ["Pretend to think about revealing a copy.", "Pretend to think"];
DutchQuestionDescriptions[QuestionIds.GLADIATOR_BLOCK] = ["You may reveal CARDS0 from your hand.", "You may reveal CARDS0"];
DutchQuestionDescriptions[QuestionIds.HAUNTED_CASTLE] = ["Put 2 cards from your hand onto your deck.", "Topdeck 2 cards"];
DutchQuestionDescriptions[QuestionIds.LEGIONARY_REVEAL] = ["You may reveal a Gold from your hand.", "You may reveal a Gold"];
DutchQuestionDescriptions[QuestionIds.LEGIONARY_DISCARD] = ["Discard down to 2 cards in hand.", "Discard down to 2 cards"];
DutchQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_NEW] = ["Bid up to 40D.", "Bid up to 40D"];
DutchQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_OVERBID] = ["Bid up to 40D. Currently PLAYER0 holds the high bid of NUMBER1.", "Bid up to 40D"];
DutchQuestionDescriptions[QuestionIds.OPULENT_CASTLE] = ["Discard any number of Victory cards for +$2 each. ", "Discard for +$2 each"];
DutchQuestionDescriptions[QuestionIds.RITUAL] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.SACRIFICE] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.SALT_THE_EARTH] = ["Trash a Victory card from the Supply.", "Trash from the Supply"];
DutchQuestionDescriptions[QuestionIds.SETTLERS] = ["You may put a Copper from your discard pile into your hand.", "You may take a Copper"];
DutchQuestionDescriptions[QuestionIds.SMALL_CASTLE_IN_PLAY] = ["Trash Small Castle from play or a Castle from your hand.", "Trash a Castle"];
DutchQuestionDescriptions[QuestionIds.SMALL_CASTLE_GONE] = ["Trash a Castle from your hand.", "Trash a Castle"];
DutchQuestionDescriptions[QuestionIds.SMALL_CASTLE_GAIN] = ["Gain a Castle.", "Gain a Castle"];
DutchQuestionDescriptions[QuestionIds.SPRAWLING_CASTLE] = ["Gain a Duchy or 3 Estates.", "Duchy or 3 Estates?"];
DutchQuestionDescriptions[QuestionIds.TAX] = ["Choose a supply pile to add (2D) to.", "Add (2D) to a pile"];
DutchQuestionDescriptions[QuestionIds.TEMPLE] = ["Trash from 1 to 3 differently named cards from your hand.", "Trash 1 - 3 cards"];
DutchQuestionDescriptions[QuestionIds.WILD_HUNT] = ["Choose between +3 Cards and the Estate and VP", "Cards or points?"];
DutchQuestionDescriptions[QuestionIds.ADVISOR] = ["Choose what card PLAYER0 doesn't get.", "Discard for PLAYER0"];
DutchQuestionDescriptions[QuestionIds.BUTCHER_TRASH] = ["You may trash a card from your hand.", "You may trash a card"];
DutchQuestionDescriptions[QuestionIds.BUTCHER_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.DOCTOR_PLAY] = ["Name a card to trash.", "Name a card"];
DutchQuestionDescriptions[QuestionIds.DOCTOR_TOPDECK] = ["Put these cards back in any order.", "Topdeck these cards"];
DutchQuestionDescriptions[QuestionIds.DOCTOR_OVERPAY] = ["You may overpay for Doctor.", "You may overpay"];
DutchQuestionDescriptions[QuestionIds.DOCTOR_MODE] = ["What to do with CARDS0?", "What to do with CARDS0?"];
DutchQuestionDescriptions[QuestionIds.DOCTOR_MODE_MANY] = ["What to do with CARDS0? (NUMBER1 more)", "CARDS0? (NUMBER1 more)"];
DutchQuestionDescriptions[QuestionIds.HERALD_OVERPAY] = ["You may overpay for Herald.", "You may overpay"];
DutchQuestionDescriptions[QuestionIds.HERALD_CHOOSE] = ["Put a card from your discard pile on your deck.", "Topdeck a card"];
DutchQuestionDescriptions[QuestionIds.JOURNEYMAN] = ["Name a card not to draw.", "Name a card"];
DutchQuestionDescriptions[QuestionIds.MASTERPIECE] = ["You may overpay for Masterpiece.", "You may overpay"];
DutchQuestionDescriptions[QuestionIds.PLAZA_DELAY] = ["You may discard a Treasure to take a Coin token.", "You may discard a Treasure"];
DutchQuestionDescriptions[QuestionIds.PLAZA_DISCARD] = ["Discard a Treasure.", "Discard a Treasure"];
DutchQuestionDescriptions[QuestionIds.STONEMASON_TRASH] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.STONEMASON_GAIN1] = ["Gain 2 cards each costing less than AMOUNT0.", "Gain 2 cards"];
DutchQuestionDescriptions[QuestionIds.STONEMASON_GAIN2] = ["Gain another card costing less than AMOUNT0.", "Gain another card"];
DutchQuestionDescriptions[QuestionIds.STONEMASON_OVERPAY] = ["You may overpay for stonemason by gaining an Action up to AMOUNT0.", "Overpay by gaining"];
DutchQuestionDescriptions[QuestionIds.STONEMASON_GAIN_EQUAL] = ["Gain another Action costing exactly AMOUNT0.", "Gain another Action"];
DutchQuestionDescriptions[QuestionIds.TAXMAN_TRASH] = ["You may trash a Treasure from your hand.", "You may trash a Treasure"];
DutchQuestionDescriptions[QuestionIds.TAXMAN_GAIN] = ["Gain a treasure costing up to AMOUNT0 onto your deck.", "Gain a treasure"];
DutchQuestionDescriptions[QuestionIds.BORDER_VILLAGE] = ["Gain a card cheaper than AMOUNT0.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.CARTOGRAPHER_DISCARD] = ["Discard any number.", "Discard any number"];
DutchQuestionDescriptions[QuestionIds.CARTOGRAPHER_TOPDECK] = ["Put the remaining cards on top of your deck in any order.", "Topdeck these cards"];
DutchQuestionDescriptions[QuestionIds.DEVELOP_TRASH] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.DEVELOP_GAIN1] = ["Gain a card onto your deck costing AMOUNT0 or Argument1.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.DEVELOP_GAIN2] = ["Gain a card onto your deck costing AMOUNT0.", "Gain another card"];
DutchQuestionDescriptions[QuestionIds.DUCHESS] = ["You may discard CARDS0.", "You may discard CARDS0"];
DutchQuestionDescriptions[QuestionIds.EMBASSY] = ["Discard 3 cards.", "Discard 3 cards"];
DutchQuestionDescriptions[QuestionIds.FARMLAND_TRASH] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.FARMLAND_GAIN] = ["Gain a card costing exactly AMOUNT0.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.HAGGLER] = ["Gain a non-victory costing less than AMOUNT0.", "Gain a non-Victory"];
DutchQuestionDescriptions[QuestionIds.ILL_GOTTEN_GAINS] = ["You may gain a Copper to your hand.", "You may gain a Copper"];
DutchQuestionDescriptions[QuestionIds.INN_DISCARD] = ["Discard 2 cards.", "Discard 2 cards"];
DutchQuestionDescriptions[QuestionIds.INN_SHUFFLE] = ["Shuffle any number of Actions from your discard pile into your deck.", "Shuffle Actions back in"];
DutchQuestionDescriptions[QuestionIds.JACK_DISCARD] = ["You may discard CARDS0.", "You may discard CARDS0"];
DutchQuestionDescriptions[QuestionIds.JACK_TRASH] = ["You may trash a non-Treasure from your hand.", "You may trash a non-Treasure"];
DutchQuestionDescriptions[QuestionIds.MANDARIN_PLAY] = ["Put a card from your hand onto your deck.", "Topdeck a card"];
DutchQuestionDescriptions[QuestionIds.MANDARIN_GAIN] = ["Put all Treasures you have in play onto your deck in any order.", "Topdeck your Treasures"];
DutchQuestionDescriptions[QuestionIds.MARGRAVE] = ["Discard down to 3 cards in hand.", "Discard down to 3 cards"];
DutchQuestionDescriptions[QuestionIds.NOBLE_BRIGAND] = ["Choose a Treasure to have trashed.", "Choose a Treasure"];
DutchQuestionDescriptions[QuestionIds.OASIS] = ["Discard a card.", "Discard a card"];
DutchQuestionDescriptions[QuestionIds.ORACLE_DISCARD] = ["You may discard CARDS0 from PLAYER1.", "Make PLAYER1 discard?"];
DutchQuestionDescriptions[QuestionIds.ORACLE_TOPDECK] = ["Put the cards on top of your deck in any order.", "Topdeck these cards"];
DutchQuestionDescriptions[QuestionIds.SCHEME] = ["You may choose a card to scheme", "You may scheme a card"];
DutchQuestionDescriptions[QuestionIds.SCHEMES] = ["What cards to Scheme", "Scheme up to Argument0 cards"];
DutchQuestionDescriptions[QuestionIds.SPICE_MERCHANT_TRASH] = ["You may trash a Treasure from your hand.", "You may trash a Treasure"];
DutchQuestionDescriptions[QuestionIds.SPICE_MERCHANT_MODE] = ["Choose one: +2 Cards and +1 Action; or +1 Buy and +$2.", "Choose how to play Spice Merchant"];
DutchQuestionDescriptions[QuestionIds.STABLES] = ["You may discard a Treasure.", "You may discard a Treasure"];
DutchQuestionDescriptions[QuestionIds.TRADER_TRASH] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.BARON] = ["You may discard an Estate.", "You may discard an Estate"];
DutchQuestionDescriptions[QuestionIds.COURTIER_REVEAL] = ["Reveal a card from your hand.", "Reveal a card"];
DutchQuestionDescriptions[QuestionIds.COURTIER_MODE] = ["Choose NUMBER0: +1 Action; or +1 Buy; or +$3; or gain a Gold. The choices must be different.", "Choose how to play Courtier"];
DutchQuestionDescriptions[QuestionIds.COURTYARD] = ["Put a card from your hand onto your deck.", "Topdeck a card"];
DutchQuestionDescriptions[QuestionIds.DIPLOMAT] = ["Discard 3 cards.", "Discard 3 cards"];
DutchQuestionDescriptions[QuestionIds.IRONWORKS] = ["Gain a card costing up to $4.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.LURKER_MAY_TRASH] = ["You may trash an Action card from the Supply.", "You may trash from the Supply"];
DutchQuestionDescriptions[QuestionIds.LURKER_TRASH] = ["Trash an Action card from the Supply.", "Trash from the Supply"];
DutchQuestionDescriptions[QuestionIds.LURKER_MAY_GAIN] = ["You may gain an Action card from the trash.", "You may gain the trash"];
DutchQuestionDescriptions[QuestionIds.LURKER_GAIN] = ["Gain an Action card from the trash.", "Gain from the trash"];
DutchQuestionDescriptions[QuestionIds.MASQUERADE_PASS] = ["Pass a card to PLAYER0.", "Pass a card to PLAYER0"];
DutchQuestionDescriptions[QuestionIds.MASQUERADE_TRASH] = ["You may trash a card from your hand.", "You may trash a card"];
DutchQuestionDescriptions[QuestionIds.MILL] = ["You may discard 2 cards", "You may discard 2 cards"];
DutchQuestionDescriptions[QuestionIds.MINING_VILLAGE] = ["You may trash Mining Village.", "You may trash Mining Village"];
DutchQuestionDescriptions[QuestionIds.MINION] = ["Choose one: +$2 or new hands.", "Choose +$2 or new hands"];
DutchQuestionDescriptions[QuestionIds.NOBLES] = ["Choose one: +3 Cards; or +2 Actions.", "Choose +3 Cards or +2 Actions"];
DutchQuestionDescriptions[QuestionIds.PATROL] = ["Put these cards back in any order.", "Topdeck these cards"];
DutchQuestionDescriptions[QuestionIds.PAWN] = ["Choose how to play Pawn.", "Choose how to play Pawn"];
DutchQuestionDescriptions[QuestionIds.REPLACE_TRASH] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.REPLACE_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.SECRET_PASSAGE_TOPDECK] = ["Put a card onto your empty deck.", "Topdeck a card"];
DutchQuestionDescriptions[QuestionIds.SECRET_PASSAGE_INSERT] = ["Choose a card to put into your deck.", "Choose a card"];
DutchQuestionDescriptions[QuestionIds.STEWARD_MODE] = ["Choose one: +2 Cards; or +$2; or trash 2 cards from your hand.", "Choose how to play Steward"];
DutchQuestionDescriptions[QuestionIds.STEWARD_TRASH] = ["Choose 2 cards to trash.", "Choose 2 cards to trash"];
DutchQuestionDescriptions[QuestionIds.SWINDLER] = ["Choose a card for PLAYER0 to gain.", "Choose a card for PLAYER0"];
DutchQuestionDescriptions[QuestionIds.TRADING_POST] = ["Trash 2 cards from your hand.", "Trash 2 cards"];
DutchQuestionDescriptions[QuestionIds.UPGRADE_TRASH] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.UPGRADE_GAIN] = ["Gain a card costing exactly AMOUNT0.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.WISHING_WELL] = ["Guess what card is on top of your deck.", "Guess a card"];
DutchQuestionDescriptions[QuestionIds.SUMMON] = ["Gain an Action card costing up to $4.", "Summon a card"];
DutchQuestionDescriptions[QuestionIds.GOVERNOR_MODE] = ["Choose how to play Governor", "Choose how to play Governor"];
DutchQuestionDescriptions[QuestionIds.GOVERNOR_TRASH] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.GOVERNOR_MAY_TRASH] = ["You may trash a card from your hand.", "You may trash"];
DutchQuestionDescriptions[QuestionIds.GOVERNOR_GAIN] = ["Gain a card costing exactly Argument0", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.SAUNA_CHAIN] = ["You may play an Avanto without spending an action", "You may play an Avanto"];
DutchQuestionDescriptions[QuestionIds.SAUNA_MAY_TRASH] = ["You may trash a card with Sauna", "You may trash"];
DutchQuestionDescriptions[QuestionIds.AVANTO_CHAIN] = ["You may play a Sauna without spending an action", "You may play a Sauna"];

DutchQuestionDescriptions[QuestionIds.BISHOP_SELF] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.BISHOP_OTHER] = ["You may trash a card from your hand.", "You may trash a card"];
DutchQuestionDescriptions[QuestionIds.CONTRABAND] = ["Name a card PLAYER0 can't buy this turn.", "Ban a card"];
DutchQuestionDescriptions[QuestionIds.COUNTING_HOUSE] = ["How many Coppers to put into your hand?", "Take coppers"];
DutchQuestionDescriptions[QuestionIds.EXPAND_TRASH] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.EXPAND_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.FORGE_TRASH] = ["Trash any number of cards from your hand.", "Trash any number"];
DutchQuestionDescriptions[QuestionIds.FORGE_GAIN] = ["Gain a card costing exactly AMOUNT0.", "Gain a card costing"];
DutchQuestionDescriptions[QuestionIds.GOONS] = ["Discard down to 3 cards in hand.", "Discard down to 3 cards"];
DutchQuestionDescriptions[QuestionIds.KINGS_COURT] = ["You may play an Action card three times.", "You may play an Action three times"];
DutchQuestionDescriptions[QuestionIds.LOAN] = ["Discard or trash CARDS0.", "Discard or trash CARDS0"];
DutchQuestionDescriptions[QuestionIds.MINT] = ["You may reveal a Treasure from your hand. Gain a copy of it.", "You may copy a Treasure"];
DutchQuestionDescriptions[QuestionIds.MOUNTEBANK] = ["You may discard a Curse.", "You may discard a Curse"];
DutchQuestionDescriptions[QuestionIds.RABBLE] = ["Put these cards back in any order.", "Topdeck these cards"];
DutchQuestionDescriptions[QuestionIds.TRADE_ROUTE] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.VAULT_SELF] = ["Discard any number of cards.", "Discard any number"];
DutchQuestionDescriptions[QuestionIds.VAULT_OTHER] = ["You may discard 2 cards.", "You may discard 2 cards"];
DutchQuestionDescriptions[QuestionIds.AMBASSADOR_REVEAL] = ["Reveal a card from your hand.", "Reveal a card from your hand"];
DutchQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_ONE] = ["You may return CARDS0 to the Supply.", "You may return CARDS0"];
DutchQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_TWO] = ["Return 0, 1 or CARDS0 to the Supply.", "Return 0, 1 or 2"];
DutchQuestionDescriptions[QuestionIds.EMBARGO] = ["Add an Embargo token to a Supply pile.", "Embargo a pile"];
DutchQuestionDescriptions[QuestionIds.EXPLORER] = ["You may reveal a Province.", "You may reveal a Province"];
DutchQuestionDescriptions[QuestionIds.GHOST_SHIP] = ["Put NUMBER0 cards on top of your deck.", "Topdeck NUMBER0 cards"];
DutchQuestionDescriptions[QuestionIds.HAVEN] = ["Set a card aside.", "Set a card aside"];
DutchQuestionDescriptions[QuestionIds.ISLAND] = ["Put a card from your hand onto your Island mat.", "Put a card onto your mat"];
DutchQuestionDescriptions[QuestionIds.LOOKOUT_TRASH] = ["Trash a card.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.LOOKOUT_DISCARD] = ["Discard a card.", "Discard a card"];
DutchQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_TAKE] = ["You may put CARDS0 into your hand.", "You may take the cards"];
DutchQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_SET_ASIDE] = ["You may put the top card of your deck onto your mat.", "You may put onto your mat"];
DutchQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_BOTH] = ["Add a card to your mat or take CARDS0 into your hand.", "How to play Native Village?"];
DutchQuestionDescriptions[QuestionIds.NAVIGATOR] = ["Topdeck these 5 cards in any order, or discard them all.", "Topdeck or discard"];
DutchQuestionDescriptions[QuestionIds.PEARL_DIVER] = ["Put CARDS0 on top or bottom of your deck.", "Put CARDS0 on top or bottom"];
DutchQuestionDescriptions[QuestionIds.PIRATE_SHIP_MODE] = ["Choose one: +AMOUNT0 or attack.", "+AMOUNT0 or attack"];
DutchQuestionDescriptions[QuestionIds.PIRATE_SHIP_TRASH] = ["Choose a Treasure to trash.", "Choose a Treasure to trash"];
DutchQuestionDescriptions[QuestionIds.SALVAGER] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.SMUGGLERS_MAY_GAIN] = ["You may gain a copy.", "You may gain a card"];
DutchQuestionDescriptions[QuestionIds.SMUGGLERS_GAIN] = ["Gain a copy.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.WAREHOUSE] = ["Discard 3 cards.", "Discard 3 cards."];
DutchQuestionDescriptions[QuestionIds.LURKER_COMPLEX] = ["When playing Lurker", "When playing Lurker"];
DutchQuestionDescriptions[QuestionIds.BLACK_MARKET_COMPLEX] = ["You play Black Market", "You play Black Market"];
DutchQuestionDescriptions[QuestionIds.BLACK_MARKET_BUY] = ["buy one of the revealed cards.", "You may buy"];
DutchQuestionDescriptions[QuestionIds.BLACK_MARKET_PLAY] = ["play Treasures from your hand.", "You may play Treasures"];
DutchQuestionDescriptions[QuestionIds.ENVOY] = ["Choose a card to discard.", "Discard a card"];
DutchQuestionDescriptions[QuestionIds.PRINCE_MODE] = ["PRINCE_MODE", "Set prince aside?"];
DutchQuestionDescriptions[QuestionIds.PRINCE_MAY_SET_ASIDE] = ["You may set aside a card with ", "You may set a card aside"];
DutchQuestionDescriptions[QuestionIds.TORTURER_MAY_CURSE] = ["You may gain a Curse.", "You may gain a Curse"];
DutchQuestionDescriptions[QuestionIds.TORTURER_MAY_DISCARD] = ["You may discard 2 cards.", "You may discard 2 cards"];
DutchQuestionDescriptions[QuestionIds.TORTURER_DISCARD_OR_CURSE] = ["Either gain a Curse or discard 2 cards.", "Discard 2 cards or gain a Curse"];
DutchQuestionDescriptions[QuestionIds.WHAT_TO_IMPERSONATE] = ["Choose a card to impersonate.", "Choose a card to impersonate"];
DutchQuestionDescriptions[QuestionIds.INHERITANCE] = ["Choose a card to inherit.", "Choose a card to inherit"];

DutchQuestionDescriptions[QuestionIds.SHORT_YOU_MAY_REACT] = ["-", "You may react"];
DutchQuestionDescriptions[QuestionIds.SHORT_WHEN_GAIN] = ["-", "You gain Argument0..."];
DutchQuestionDescriptions[QuestionIds.SHORT_WHEN_WOULD_GAIN] = ["-", "You would gain Argument0..."];

DutchQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL_SPECIFIC] = ["-", "Play or call Argument0..."];
DutchQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL] = ["-", "Play or call Actions"];
DutchQuestionDescriptions[QuestionIds.SHORT_PLAY] = ["-", "You may play Actions"];
DutchQuestionDescriptions[QuestionIds.SHORT_CALL_SPECIFIC] = ["-", "You may call Argument0"];
DutchQuestionDescriptions[QuestionIds.SHORT_CALL] = ["-", "You may call cards"];

DutchQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_DISCARD] = ["You may discard a Treasure", "You may discard"];
DutchQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_GAIN] = ["Gain a card costing up to $4.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.THE_FLAMES_GIFT] = ["You may trash a card from your hand.", "You may trash"];
DutchQuestionDescriptions[QuestionIds.THE_MOONS_GIFT] = ["You may put a card onto your deck.", "You may topdeck"];
DutchQuestionDescriptions[QuestionIds.THE_SKYS_GIFT] = ["You may discard 3 cards to gain a Gold.", "You may discard"];
DutchQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_TOPDECK] = ["put the rest back in any order", "put the rest back"];
DutchQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_DISCARD] = ["Discard any number", "Discard any number"];
DutchQuestionDescriptions[QuestionIds.THE_WINDS_GIFT] = ["Discard 2 cards.", "Discard 2 cards"];
DutchQuestionDescriptions[QuestionIds.FEAR_DISCARD] = ["discard an Action or Treasure", "Discard a card"];
DutchQuestionDescriptions[QuestionIds.HAUNTING] = ["Topdeck something.", "Topdeck something"];
DutchQuestionDescriptions[QuestionIds.LOCUSTS] = ["Gain a cheaper card that shares a type.", "Gain a cheaper card"];
DutchQuestionDescriptions[QuestionIds.POVERTY] = ["Discard down to 3 cards in hand", "Discard down to 3"];
DutchQuestionDescriptions[QuestionIds.BAT] = ["You may trash up to 2 cards", "You may Trash"];
DutchQuestionDescriptions[QuestionIds.BLESSED_VILLAGE_MODE] = ["When to receive Argument0?", "When to receive?"];
DutchQuestionDescriptions[QuestionIds.CEMETERY] = ["You may trash up to 4 cards", "You may Trash"];
DutchQuestionDescriptions[QuestionIds.CHANGELING_GAIN_COPY] = ["Gain a copy of a card you have in play", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.CHANGELING_REPLACE] = ["", ""];
DutchQuestionDescriptions[QuestionIds.COBBLER] = ["Gain a card costing up to $4 to your hand.", "Gain to your hand"];
DutchQuestionDescriptions[QuestionIds.CONCLAVE] = ["You may play an Action", "You may play an Action"];
DutchQuestionDescriptions[QuestionIds.CRYPT_SET_ASIDE] = ["Set aside any number of Treasures you have in play", "Set aside any number"];
DutchQuestionDescriptions[QuestionIds.CRYPT_RETURN_TO_HAND] = ["Choose a card to put into your hand.", "Choose a card"];
DutchQuestionDescriptions[QuestionIds.DEVILS_WORKSHOP] = ["Gain a card costing up to $4.", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.DRUID] = ["Which Boon to receive?", "Which Boon?"];
DutchQuestionDescriptions[QuestionIds.EXORCIST_TRASH] = ["Trash a card from your hand.", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.EXORCIST_GAIN] = ["Gain a cheaper Spirit", "Gain a cheaper Spirit"];
DutchQuestionDescriptions[QuestionIds.FOOL] = ["What Boon next?", "What Boon next?"];
DutchQuestionDescriptions[QuestionIds.GOAT] = ["You may trash a card", "You may Trash"];
DutchQuestionDescriptions[QuestionIds.HAUNTED_MIRROR] = ["You may discard an action to get a Ghost", "You may discard"];
DutchQuestionDescriptions[QuestionIds.IMP] = ["You may play an action you don't have in play", "What to play?"];
DutchQuestionDescriptions[QuestionIds.NECROMANCER] = ["Play from trash", "Play from trash"];
DutchQuestionDescriptions[QuestionIds.MONASTERY] = ["You may trash up to AMOUNT0 cards or Coppers in play.", "You may trash up to AMOUNT0"];
DutchQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_TOPDECK] = ["Topdeck the rest", "Topdeck the rest"];
DutchQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_DISCARD] = ["Discard any number", "Discard any number"];
DutchQuestionDescriptions[QuestionIds.PIXIE_MODE] = ["Trash Pixie to receive twice?", "Trash Pixie?"];
DutchQuestionDescriptions[QuestionIds.POOKA] = ["You may trash a Treasure other than Cursed Gold", "You may trash"];
DutchQuestionDescriptions[QuestionIds.RAIDER] = ["Discard a card", "Discard a card"];
DutchQuestionDescriptions[QuestionIds.SACRED_GROVE_MODE] = ["Also receive Argument0?", "receive Argument0?"];
DutchQuestionDescriptions[QuestionIds.SECRET_CAVE] = ["You may discard 3 cards for +$3 next turn", "You may discard"];
DutchQuestionDescriptions[QuestionIds.SHEPHERD] = ["Discard Victory cards for +2 cards each", "You may discard"];
DutchQuestionDescriptions[QuestionIds.TRAGIC_HERO] = ["Gain a Treasure", "Gain a Treasure"];
DutchQuestionDescriptions[QuestionIds.VAMPIRE] = ["Gain a non-Vampire up to 5", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.WISH] = ["Gain a card to your hand", "Gain a card"];
DutchQuestionDescriptions[QuestionIds.ZOMBIE_APPRENTICE] = ["You may trash an Action", "You may trash"];
DutchQuestionDescriptions[QuestionIds.ZOMBIE_MASON] = ["You may gain a card", "You may gain a card"];
DutchQuestionDescriptions[QuestionIds.ZOMBIE_SPY] = ["You may Discard", "You may Discard"];
DutchQuestionDescriptions[QuestionIds.WHAT_NIGHT_TO_PLAY] = ["You may play a Night card", "What Night to play?"];
DutchQuestionDescriptions[QuestionIds.LOST_IN_THE_WOODS] = ["You may discard a card to receive a Boon", "Discard for a Boon?"];
DutchQuestionDescriptions[QuestionIds.START_GAME] = ["Inspect your starting cards", "Your starting cards:"];
DutchQuestionDescriptions[QuestionIds.DISMANTLE_TRASH] = ["Trash a card from your hand", "Trash a card"];
DutchQuestionDescriptions[QuestionIds.DISMANTLE_GAIN] = ["Gain a card cheaper than AMOUNT0.", "Gain a cheaper card"];
DutchQuestionDescriptions[QuestionIds.CAPTAIN] = ["Choose an Action from the supply up to $4 to play", "Choose what to play"];
DutchQuestionDescriptions[QuestionIds.CHURCH_SET_ASIDE] = ["Set aside up to 3 cards", "Set aside up to 3 cards"];
DutchQuestionDescriptions[QuestionIds.CHURCH_TRASH] = ["You may trash a card from your hand.", "You may trash a card"];