"use strict";

webclient.service('soundService', ['$rootScope', 'preferences', function ($rootScope, preferences) {
    var self = this;
    var scope = $rootScope.$new(true);

    self.initialize = function () {
        return AudioPlayer.loadSounds();
    };

    self.play = function (sound) {
        if (!shouldPlay(sound)) return;
        AudioPlayer.play(sound);
    };

    function shouldPlay(sound) {
        if (sound.hasType(SoundTypes.NOTIFICATION)) {
            return preferences.getUserPrefValue(UserPrefIds.NOTIFICATION_SOUNDS);
        } else if (sound.hasType(SoundTypes.FX)) {
            return preferences.getUserPrefValue(UserPrefIds.SOUND_FX);
        }
        return false;
    }

    scope.$on(Events.AUTOMATCH_FOUND, function (event, automatchFound) {
        if (automatchFound.isBotGame) return;
        self.play(SOUNDS.MATCH_FOUND);
    });
}]);