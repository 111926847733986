"use strict";

var RussianExpansionNames = {};

RussianExpansionNames[Expansions.BASE] = 'Доминион';
RussianExpansionNames[Expansions.INTRIGUE] = 'Интрига';
RussianExpansionNames[Expansions.SEASIDE] = 'Побережье';
RussianExpansionNames[Expansions.ALCHEMY] = 'Алхимия';
RussianExpansionNames[Expansions.PROSPERITY] = 'Процветание';
RussianExpansionNames[Expansions.CORNUCOPIA_GUILDS] = 'Изобилие и Гильдии';
RussianExpansionNames[Expansions.HINTERLANDS] = 'Окраины';
RussianExpansionNames[Expansions.DARK_AGES] = 'Тёмные Времена';
RussianExpansionNames[Expansions.ADVENTURES] = 'Приключения';
RussianExpansionNames[Expansions.EMPIRES] = 'Империи';
RussianExpansionNames[Expansions.PROMOS] = 'Промо';
RussianExpansionNames[Expansions.NOCTURNE] = 'Ноктюрн';
RussianExpansionNames[Expansions.RENAISSANCE] = 'Ренессанс';
RussianExpansionNames[Expansions.MENAGERIE] = 'Зверинец';
RussianExpansionNames[Expansions.ALLIES] = 'Союзники';
RussianExpansionNames[Expansions.PLUNDER] = 'Грабёж';
RussianExpansionNames[Expansions.DEPRECATED] = 'Устаревшие карты';
RussianExpansionNames[Expansions.RISING_SUN] = 'Восходящее Солнце';