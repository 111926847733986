"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var IconLayer = function (_StackViewLayer) {
    _inherits(IconLayer, _StackViewLayer);

    function IconLayer(rootNode, cardStack) {
        _classCallCheck(this, IconLayer);

        var _this = _possibleConstructorReturn(this, (IconLayer.__proto__ || Object.getPrototypeOf(IconLayer)).call(this, rootNode, cardStack));

        _this.node.style.zIndex = 3;
        _this.node.classList.add("icon-layer");
        _this.crossNode = document.createElement("div");
        return _this;
    }

    _createClass(IconLayer, [{
        key: "redraw",
        value: function redraw(w, h) {
            var card = this.cardStack.topCard;
            var cardName = card.cardName;
            var cardSize = this.cardStack.cardSize;
            var icon = cardName.expansion.getExpansionSymbolURL(false);
            var shouldHide = cardSize !== CardSizes.FULL || cardName.isBack() || this.cardStack.hasFilter("appears-anonymous");

            if (shouldHide) {
                this.node.classList.add("invisible");
            } else {
                this.node.classList.remove("invisible");
            }

            var position = getExpansionIconBbox(w, cardName).roundPositions();
            this.node.style.left = position.x + "px";
            this.node.style.top = position.y + "px";
            this.node.style.width = position.width + "px";
            this.node.style.height = position.height + "px";
            this.node.style.backgroundImage = "url(" + icon + ")";
        }
    }]);

    return IconLayer;
}(StackViewLayer);