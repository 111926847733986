"use strict";
// Intro page of Store tab in Lobby

TChineseParagraphs.STORE_INTRO = new Paragraph(["皇輿爭霸的基本版大家都可以玩，", "想要玩更多卡片的話需要訂閱，", "每一場遊戲只要有其中一個玩家有訂閱就可以玩擴充囉。"]);

// TRANSLATE ME last sentence was added     
TChineseParagraphs.STORE_BUYING = new Paragraph(["訂閱不會自動續訂，", "你可以自己去續訂或是升級訂閱，", "如果你在訂閱期間再訂閱，價格會扣掉你剩餘的訂閱。", "如果你購買長期的，會有些微折扣。"]);

TChineseParagraphs.STORE_SUBSCRIPTIONS = new Paragraph(["基礎訂閱方案《可遊玩一半的擴充》 每個月的價格：2.15歐元。", "完整訂閱方案《可遊玩所有擴充》 每個月的價格：4.30歐元。"]);

TChineseParagraphs.STORE_DOLLARS = new Paragraph(["這裡訂閱的價格是用歐元來計算，", "匯率請依照各自銀行單位公布的為主。"]);