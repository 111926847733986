"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function LobbyTab(c) {
    var visibility = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    var self = this;
    self.class = c;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
    self.userPrefTab = false;
    self.isVisible = visibility;
}

function UserPrefTab(preferences) {
    var style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'preferences';


    LobbyTab.call(this, style);

    var self = this;

    self.all = preferences;
    self.preferences = preferences;
    self.userPrefTab = true;
    self.showAutoplayTab = false;

    self.activateAutoplayTab = function () {

        self.preferences = [UserPrefIds.AUTOPLAY_SETTINGS];
        self.showAutoplayTab = true;
    };

    self.deactivateAutoplayTab = function () {

        self.preferences = self.all;
        self.showAutoplayTab = false;
    };
}

function OptionIdList() {

    var list = [UserPrefIds.NOTIFICATION_SOUNDS, UserPrefIds.SOUND_FX, UserPrefIds.MOVE_KINGDOM, UserPrefIds.CONFIRM_ACTIONS, UserPrefIds.USE_AUTOBUY, UserPrefIds.PREFERRED_BACKGROUND, UserPrefIds.FOLLOWERS_SEE_ACTIVITY, UserPrefIds.MAX_UNFAMILIAR_CARDS, UserPrefIds.SPECTATORS_SEE_HAND, UserPrefIds.LOG_FIXED_BASIC_BONUS, UserPrefIds.TRANSPARENT_WAY_BUTTON, UserPrefIds.CHAT_VISIBLE, UserPrefIds.SPECTATOR_CHAT_IN_AUTOMATCH, UserPrefIds.PREMOVES, UserPrefIds.AUTOPLAY_SETTINGS];

    // Intention was
    // let autoplay_preferences = preferences.getUserPreference( UserPrefIds.AUTOPLAY_SETTINGS );
    // list = list + autoplay_preferences;
    // However: how to access preferences.getUserPreference from here?

    return list;
}

var LobbyTabs = {
    MATCH: new LobbyTab("matching"),
    NEW_TABLE: new LobbyTab("new-table"),
    MY_TABLE: new LobbyTab("my-table"),
    TABLES: new LobbyTab("tables"),
    OPTIONS: new LobbyTab("options", false),
    FRIEND_LIST: new LobbyTab("friends"),
    CARDLISTS: new LobbyTab("cardlists"),
    LEADERBOARD: new LobbyTab("leaderboard"),
    ACCOUNT: new LobbyTab("account"),
    STORE: new UserPrefTab([UserPrefIds.CARD_OWNERSHIP]),
    INBOX: new LobbyTab("inbox")
};

var CardListUI = function () {
    function CardListUI(listId, userPrefId) {
        _classCallCheck(this, CardListUI);

        this.listId = listId;
        this.userPrefId = userPrefId;
    }

    _createClass(CardListUI, [{
        key: "translation",
        value: function translation() {
            return LANGUAGE.getUserPreferences[this.userPrefId];
        }
    }, {
        key: "maxCards",
        value: function maxCards() {
            return 5;
        }
    }, {
        key: "maxLandscapes",
        value: function maxLandscapes() {
            return 3;
        }
    }, {
        key: "autoCompleteText",
        value: function autoCompleteText() {
            return getPhrase(Phrases.AUTOCOMPLETE_CARD_LISTS);
        }
    }]);

    return CardListUI;
}();

var CardLists = {
    LIKED_CARDS: new CardListUI(2, UserPrefIds.LIKED_CARDS),
    DISLIKED_CARDS: new CardListUI(3, UserPrefIds.DISLIKED_CARDS),
    BANNED_CARDS: new CardListUI(0, UserPrefIds.BANNED_CARDS)
};

var FAMILIAR_CARDS_CARDLIST_ID = 1;