"use strict";

webclient.component("resignRequest", {
    transclude: true,
    controller: ['$scope', 'game', 'serverMessenger', function ($scope, game, serverMessenger) {
        var self = this;

        $scope.$on(Events.REDRAW, function () {
            safeDigest($scope);
        });
        $scope.$on(Events.GAME_MODEL_CREATED, function () {
            safeDigest($scope);
        });

        self.shouldShow = function () {
            return game.metaGameModel.hasResignRequest === true && !isLoadingGame;
        };
        self.grant = function () {
            serverMessenger.resign();
            game.metaGameModel.hasResignRequest = false;
        };
        self.decline = function () {
            game.metaGameModel.hasResignRequest = false;
        };

        self.getWindowText = function () {
            return getPhrase(Phrases.RESIGN_PROMPT);
        };
        self.getYesText = function () {
            return getPhrase(Phrases.YES);
        };
        self.getNoText = function () {
            return getPhrase(Phrases.NO);
        };
    }],
    template: "\n            <modal-window ng-if=\"$ctrl.shouldShow()\">\n                <div class=\"timeout\">{{$ctrl.getWindowText()}}</div>\n                <div>\n                    <button class=\"lobby-button\" ng-click=\"$ctrl.grant()\" >{{::$ctrl.getYesText()}}</button>\n                    <button class=\"lobby-button\" ng-click=\"$ctrl.decline()\" >{{::$ctrl.getNoText()}}</button>\n                </div>\n            </modal-window>\n        "
});