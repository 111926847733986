"use strict";

var JapaneseErrors = {};

JapaneseErrors[FailureReasons.WRONG_PASSWORD] = "パスワードが間違っています。";
JapaneseErrors[FailureReasons.NOT_ENOUGH_PLAYERS] = "プレイヤー数が足りません。";
JapaneseErrors[FailureReasons.TOO_MANY_PLAYERS] = "プレイヤー数が多すぎます。";
JapaneseErrors[FailureReasons.NEW_HOST_NOT_FOUND] = "ホストが見つかりません。";
JapaneseErrors[FailureReasons.ALREADY_JOINED_A_TABLE] = "既に入卓しています。";
JapaneseErrors[FailureReasons.NOT_ALLOWED_TO_JOIN] = "入卓が許可されていません。";
JapaneseErrors[FailureReasons.BAD_CLIENT_VERSION] = "クライアントが最新ではありません。ページを更新してください。";
JapaneseErrors[FailureReasons.UNKNOWN_RULE_INDEX] = "未知のルール番号です。";
JapaneseErrors[FailureReasons.RULE_NOT_REMOVABLE] = "このルールは削除できません。";
JapaneseErrors[FailureReasons.INVITEE_NOT_LOGGED_IN] = "被招待プレイヤーがログインしていません。";
JapaneseErrors[FailureReasons.NOT_ALLOWED_TO_PLAY] = "対戦参加が許可されていません。";
JapaneseErrors[FailureReasons.TABLE_CHAT_REQUIRES_TABLE] = "発言には卓が必要です。";
JapaneseErrors[FailureReasons.SPECTATOR_CHAT_REQUIRES_TABLE] = "観戦者の発言には卓が必要です。";
JapaneseErrors[FailureReasons.LOGIN_REQUIRED] = "このコマンドにはログインが必要です。";
JapaneseErrors[FailureReasons.COMMAND_REQUIRES_TABLE] = "このコマンドには卓が必要です。";
JapaneseErrors[FailureReasons.COMMAND_REQUIRES_HOST] = "このコマンドにはホスト権限が必要です。";
JapaneseErrors[FailureReasons.RULE_CONFLICTS] = "ルールが矛盾しています。";
JapaneseErrors[FailureReasons.NO_GAMESERVER_AVAILABLE] = "どのサーバーも利用できません。";
JapaneseErrors[FailureReasons.SELECTED_CARD_NOT_OWNED] = "選択したカードを所持していません。";
JapaneseErrors[FailureReasons.SELECTED_CARD_NOT_KINGDOM_CARD] = "選択したカードは王国カードではありません。";
JapaneseErrors[FailureReasons.SELECTED_TOO_MANY_EVENTS_OR_LANDMARKS] = "イベント/ランドマークが多すぎます。";
JapaneseErrors[FailureReasons.THIS_IS_NOT_YOUR_TABLE] = "あなたの卓ではありません。";
JapaneseErrors[FailureReasons.ALREADY_LOGGED_IN] = "既にログインしています。";
JapaneseErrors[FailureReasons.CANNOT_RECONNECT_WHEN_STILL_CONNECTED] = "接続中のため再接続できません。";
JapaneseErrors[FailureReasons.KICK_FAILED] = "キックできません。";
JapaneseErrors[FailureReasons.ALREADY_STARTING] = "既に開始中です。";
JapaneseErrors[FailureReasons.ALREADY_STARTED] = "既に開始しました。";
JapaneseErrors[FailureReasons.NOTHING_TO_KICK] = "キック対象がいません。";
JapaneseErrors[FailureReasons.INTERNAL_ERROR] = "内部エラーです。";
JapaneseErrors[FailureReasons.ILLEGAL_PROPERTY_CHANGE] = "不正な所有権変更です。";
JapaneseErrors[FailureReasons.CODE_ALREADY_USED] = "使用済コードです。";
JapaneseErrors[FailureReasons.FAILED_CONFIRMING_EMAIL] = "メールアドレスの認証に失敗しました。";
JapaneseErrors[FailureReasons.FAILED_RETRIEVING_USER] = "アカウントの復旧に失敗しました。";
JapaneseErrors[FailureReasons.EMAIL_NOT_CONFIRMED_YET] = "メールアドレスの認証までは卓を設置できません。";
JapaneseErrors[FailureReasons.FAILURE_SENDING_EMAIL] = "認証メールの送信に失敗しました。";
JapaneseErrors[FailureReasons.CONFIRM_EMAIL_FIRST] = "メールアドレスの認証がされていないため、パスワードを送信できません。";
JapaneseErrors[FailureReasons.OLD_PASSWORD_INCORRECT] = "旧パスワードが違います。";
JapaneseErrors[FailureReasons.INCORRECT_PRICE] = "価格が誤っています。";
JapaneseErrors[FailureReasons.PURCHASE_IN_FUTURE] = "時計がずれています。";
JapaneseErrors[FailureReasons.PURCHASE_TOO_OLD] = "制限時間を超過しました。再試行してください。";
JapaneseErrors[FailureReasons.FAILED_SETTING_UP_PURCHASE] = "購入画面の表示に失敗しました。";
JapaneseErrors[FailureReasons.FAILED_STORING_PURCHASE] = "購入結果の保存に失敗しました。";
JapaneseErrors[FailureReasons.DUPLICATE_USERNAME] = "このユーザー名は使用されています。";
JapaneseErrors[FailureReasons.INVALID_USERNAME] = "ユーザー名に使用できない文字が含まれています。";
JapaneseErrors[FailureReasons.INVALID_PASSWORD] = "パスワードに使用できない文字が含まれています。";
JapaneseErrors[FailureReasons.INVALID_GENDER] = "この性別は使用できません。";
JapaneseErrors[FailureReasons.PURCHASE_TOO_SHORT] = "購入期間の下限は1ヵ月です。";
JapaneseErrors[FailureReasons.PURCHASE_TOO_LONG] = "現在、2024年以降のサブスクリプションは販売していません。";
JapaneseErrors[FailureReasons.METASERVER_INACTIVE] = "サーバーのバージョンが更新されました。ページを更新してください。";
JapaneseErrors[FailureReasons.NO_SUCH_TABLE] = "該当する卓は存在しません。";
JapaneseErrors[FailureReasons.NO_SUCH_USER] = "該当するユーザーは存在しません。";
JapaneseErrors[FailureReasons.NO_SUCH_CODE] = "該当するコードは存在しません。";
JapaneseErrors[FailureReasons.RESERVED_USERNAME] = "このユーザー名は予約されています。";
JapaneseErrors[FailureReasons.PRICE_IS_ZERO] = "価格0の購入はできません。";
JapaneseErrors[FailureReasons.NO_SUCH_USERNAME] = "該当するユーザーは存在しません。";
JapaneseErrors[FailureReasons.NO_SUCH_GAMELOG] = "該当する対戦は存在しません。";
JapaneseErrors[FailureReasons.ILLEGAL_PLAYER_ORDER] = "不正なプレイヤー順です。";
JapaneseErrors[FailureReasons.INVALID_SESSION_ID] = "無効なセッションIDです。";
JapaneseErrors[FailureReasons.ACTION_CODE_EXPIRED] = "アクションコードが切れました。";
JapaneseErrors[FailureReasons.BOT_GAMES_CANT_BE_RATED] = "AI込みの対戦は順位戦にできません。";
JapaneseErrors[FailureReasons.TOO_MANY_PLAYERS_FOR_RATED_GAME] = "5人以上の対戦は順位戦にできません。";
JapaneseErrors[FailureReasons.ALREADY_ON_BLACKLIST] = "既にブロックしています。";
JapaneseErrors[FailureReasons.NOT_ON_BLACKLIST] = "ブロックリストにありません。";
JapaneseErrors[FailureReasons.ALREADY_ON_FRIENDLIST] = "既にフレンドです。";
JapaneseErrors[FailureReasons.NOT_ON_FRIENDLIST] = "フレンドリストにありません。";
JapaneseErrors[FailureReasons.SEARCHING_FOR_MYSELF] = "自分を加えることはできません。";
JapaneseErrors[FailureReasons.SEARCHED_FOR_BOT] = "AIを加えることはできません。";
JapaneseErrors[FailureReasons.USER_IS_BANNED] = "アカウントが凍結されています。";
JapaneseErrors[FailureReasons.WRONG_BONUS_CODE_TYPE] = "WRONG_BONUS_CODE_TYPE。";
JapaneseErrors[FailureReasons.ILLEGAL_BONUS_CODE] = "ILLEGAL_BONUS_CODE。";
JapaneseErrors[FailureReasons.GAME_ID_SHOULD_BE_NUMBER] = "対戦IDには数字を入力してください。";
JapaneseErrors[FailureReasons.NOT_SUBSCRIBED_TO] = "サブスクリプションに含まれていません。";
JapaneseErrors[FailureReasons.KINGDOM_HAS_TOO_MANY_CARDS] = "王国カードが多すぎます。";
JapaneseErrors[FailureReasons.NO_CARDS_AVAILABLE_TO_FILL_KINGDOM] = "王国形成に必要な数のカードを用意できません。";
JapaneseErrors[FailureReasons.FAILED_TO_SELECT_BANE_CARD] = "災いカードが選出できませんでした。";
JapaneseErrors[FailureReasons.FAILED_TO_SELECT_MOUSE_CARD] = "ハツカネズミの習性のカードが選出できませんでした。";
JapaneseErrors[FailureReasons.FAILED_TO_SELECT_FERRYMAN_CARD] = "渡し守のカードが選出できませんでした。";
JapaneseErrors[FailureReasons.FAILED_TO_SELECT_PREVIEW_CARDS] = "プレビューのカードを選出できませんでした。";
JapaneseErrors[FailureReasons.FAILED_TO_SELECT_ALLY] = "同盟を選出できませんでした。";
JapaneseErrors[FailureReasons.FAILED_TO_SELECT_LIASON] = "連携カードを選出できませんでした。";
JapaneseErrors[FailureReasons.REQUIRING_RANDOM_CARD_FROM_DISABLED_EXPANSION] = "オフになっている拡張セットの任意のカードが必要です。";
JapaneseErrors[FailureReasons.COMMAND_REQUIRES_PLAYER] = "このコマンドはプレイヤー専用です。";
JapaneseErrors[FailureReasons.COMMAND_REQUIRES_GAME] = "This command requires a game.";
JapaneseErrors[FailureReasons.ILLEGAL_ANSWER] = "Provided an illegal answer in this game.";
JapaneseErrors[FailureReasons.ILLEGAL_REQUEST] = "Illegal request in this game.";
JapaneseErrors[FailureReasons.NO_SUCH_UNDO] = "No such undo.";
JapaneseErrors[FailureReasons.SERVER_DOESNT_ALLOW_NEW_GAMES] = "Server doesn't allow new games. Please reload the page.";
JapaneseErrors[FailureReasons.SERVER_IS_DOWN] = "Server is down. Please reload the page.";

var JapaneseSuccessDescriptions = {};

JapaneseSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_FORGOT_PASSWORD] = "パスワード再設定用のメールを送信しました。";
JapaneseSuccessDescriptions[SuccessDescriptions.EMAIL_SENT_CONFIRM_EMAIL] = "認証メールを送信しました。";
JapaneseSuccessDescriptions[SuccessDescriptions.PASSWORD_CHANGED] = "パスワードは変更されました。";
JapaneseSuccessDescriptions[SuccessDescriptions.KICK_SUCCESS] = "キックしました。";
JapaneseSuccessDescriptions[SuccessDescriptions.PURCHASE_SUCCESS] = "購入しました。";
JapaneseSuccessDescriptions[SuccessDescriptions.EMAIL_CONFIRMED] = "メールアドレスの認証ができました。";
JapaneseSuccessDescriptions[SuccessDescriptions.REFUND_SUCCESS] = "返金しました。";
JapaneseSuccessDescriptions[SuccessDescriptions.ACTION_CODE_USED] = "アクションコードを適用しました。";
JapaneseSuccessDescriptions[SuccessDescriptions.SUBSCRIPTION_UPDATED] = "サブスクリプションは更新されました。";

var JapaneseTableRules = {};

JapaneseTableRules[TableRuleIds.PLAYERS_CAN_SEE_SPECTATOR_CHAT] = "観戦チャットを表示";
JapaneseTableRules[TableRuleIds.BANNED_CARDS] = "禁止カード";
JapaneseTableRules[TableRuleIds.REQUIRED_CARDS] = "使用カード";
JapaneseTableRules[TableRuleIds.USED_EXPANSIONS] = "使用拡張";
JapaneseTableRules[TableRuleIds.RESTRICTIVE_PLAYER_RULES] = "特定プレイヤーのみ対戦できます。";
JapaneseTableRules[TableRuleIds.SPECTATE_RULES] = "観戦許可";
JapaneseTableRules[TableRuleIds.MIN_RATING] = "相対レート下限";
JapaneseTableRules[TableRuleIds.MAX_RATING] = "相対レート上限";
JapaneseTableRules[TableRuleIds.RANDOM_SEED] = "乱数の種を設定";
JapaneseTableRules[TableRuleIds.MIN_PLAYERS] = "最小人数";
JapaneseTableRules[TableRuleIds.MAX_PLAYERS] = "最大人数";
JapaneseTableRules[TableRuleIds.SPECIAL_KINGDOM_RULES] = "追加ルール";
JapaneseTableRules[TableRuleIds.RESPECTED_CARD_LISTS] = "好き/嫌い/禁止カードを尊重";
JapaneseTableRules[TableRuleIds.SHOW_VP_COUNTER] = "勝利点を表示";
JapaneseTableRules[TableRuleIds.UNDO_SETTINGS] = "設定をやり直す。";
JapaneseTableRules[TableRuleIds.ALWAYS_ALLOWED_PLAYER_RULES] = "ALWAYS_ALLOWED_PLAYER_RULES";
JapaneseTableRules[TableRuleIds.PLAYER_ORDER] = "プレイヤー順";
JapaneseTableRules[TableRuleIds.REPLAY_INSTRUCTIONS] = "決定もリプレイする";
JapaneseTableRules[TableRuleIds.RATED_GAME] = "順位戦";
JapaneseTableRules[TableRuleIds.PREVIEW_GAME] = "プレビュー";
JapaneseTableRules[TableRuleIds.SCRIPTED_RULES] = "特別ルール";
JapaneseTableRules[TableRuleIds.CARD_POOL_LEVEL] = "カードプール";

var JapaneseActivities = {};

JapaneseActivities[ActivityStati.LOGGED_IN] = "ログイン中";
JapaneseActivities[ActivityStati.PLAYING] = "対戦中";
JapaneseActivities[ActivityStati.SPECTATING] = "観戦中";
JapaneseActivities[ActivityStati.AT_TABLE_PLAYER] = "対戦準備中";
JapaneseActivities[ActivityStati.AT_TABLE_SPECTATOR] = "観戦準備中";
JapaneseActivities[ActivityStati.DISCONNECTED] = "接続切れ";

var JapaneseTableStati = {};

JapaneseTableStati[TableStati.NEW] = "新規";
JapaneseTableStati[TableStati.POST_GAME] = "終了";
JapaneseTableStati[TableStati.RUNNING] = "対戦中";
JapaneseTableStati[TableStati.ABANDONED] = "閉卓";

var JapaneseLobbyButtons = {
        DONE: "終了",
        RESET: "リセット",
        CLEAR_SELECTION: "選択解除",
        SHOW_TEXT_KINGDOM: "テキストで確認",
        NEW_TABLE: "新規卓",
        START_GAME: "対戦開始",
        LEAVE_TABLE: "退出",
        SELECT_KINGDOM: "カード選択",
        LIST_BANNED: "禁止",
        LIST_DISLIKED: "嫌い",
        LIST_LIKED: "好き",
        LIST_FAMILIAR: "知っているカード",
        LOAD_FROM_END: "終了時点を読込",
        SELECT_COLONIES: "植民地: ",
        SELECT_SHELTERS: "避難所: "
};

var JapaneseLobbyTexts = {
        AUTOCOMPLETE_FAILED: "「CARDS」で始まるカードは存在しません。",
        ADD_CARDS_AUTOCOMPLETE: "カード名を入力",
        BAN_CARDS_AUTOCOMPLETE: "カード名を入力",
        KINGDOM_OPTIONS: "王国設定",
        GAME_OPTIONS: "卓設定",
        RESPECTED_CARDS: "カードリスト",
        RELATIVE_LEVEL: "レート差",
        LEVEL_INTERVAL: "${min}～${max}",
        EMPTY_SLOT: "席があいています",
        REMOVE_SLOT: "席を削除",
        ADD_SLOT: "席を追加",
        RANDOMIZE_PLAYER_ORDER: "プレイヤー順: ランダム",
        CHAT: "チャット",
        SELECT_CARDS: "カード選択"
};

var JapaneseTernaryFieldTexts = {};

JapaneseTernaryFieldTexts[TernaryField.YES] = "はい";
JapaneseTernaryFieldTexts[TernaryField.NO] = "いいえ";
JapaneseTernaryFieldTexts[TernaryField.RANDOM] = "ランダム";

var JapaneseTabs = {};

JapaneseTabs[LobbyTabs.INBOX] = "受信箱";
JapaneseTabs[LobbyTabs.MATCH] = "マッチング";
JapaneseTabs[LobbyTabs.FRIEND_LIST] = "フレンドリスト";
JapaneseTabs[LobbyTabs.TABLES] = "既存卓";
JapaneseTabs[LobbyTabs.NEW_TABLE] = "新規卓";
JapaneseTabs[LobbyTabs.MY_TABLE] = "あなたの卓";
JapaneseTabs[LobbyTabs.ACCOUNT] = "アカウント";
JapaneseTabs[LobbyTabs.STORE] = "ストア";
JapaneseTabs[LobbyTabs.OPTIONS] = "設定";
JapaneseTabs[LobbyTabs.FAMILIAR_CARDS] = "知っているカード";
JapaneseTabs[LobbyTabs.LEADERBOARD] = "成績表";
JapaneseTabs[LobbyTabs.CARDLISTS] = "カードリスト";

var JapaneseMatchTypes = {};

JapaneseMatchTypes[AutomatchTypes.QUICK_MATCH] = "無差別級";
JapaneseMatchTypes[AutomatchTypes.GOOD_MATCH] = "同級";
JapaneseMatchTypes[AutomatchTypes.MATCH_WITH_EXPANSIONS] = "拡張所持者";
JapaneseMatchTypes[AutomatchTypes.MATCH_WITH_BOT] = "AIを含む";
JapaneseMatchTypes[AutomatchTypes.MATCH_UNRATED] = "練習戦";

var JapaneseUndoSettings = {};

JapaneseUndoSettings[UndoSettings.NEVER] = "拒否";
JapaneseUndoSettings[UndoSettings.ASK] = "確認";
JapaneseUndoSettings[UndoSettings.ALWAYS] = "許可";

var JapaneseGroupIds = {};

JapaneseGroupIds[GroupIds.EVERYBODY] = "全員";
JapaneseGroupIds[GroupIds.NOBODY] = "いいえ";
JapaneseGroupIds[GroupIds.FRIENDS_OF] = "フレンド";
JapaneseGroupIds[GroupIds.LIST_PLAYER_IDS] = "選択済みのプレイヤー:";

var JapaneseCountries = {};

JapaneseCountries[Countries.NON_EU] = "EU域外";
JapaneseCountries[Countries.AT] = "オーストリア";
JapaneseCountries[Countries.BE] = "ベルギー";
JapaneseCountries[Countries.BG] = "ブルガリア";
JapaneseCountries[Countries.HR] = "クロアチア";
JapaneseCountries[Countries.CY] = "キプロス";
JapaneseCountries[Countries.CZ] = "チェコ";
JapaneseCountries[Countries.DK] = "デンマーク";
JapaneseCountries[Countries.EE] = "エストニア";
JapaneseCountries[Countries.FI] = "フィンランド";
JapaneseCountries[Countries.FR] = "フランス";
JapaneseCountries[Countries.DE] = "ドイツ";
JapaneseCountries[Countries.GR] = "ギリシャ";
JapaneseCountries[Countries.HU] = "ハンガリー";
JapaneseCountries[Countries.IE] = "アイルランド";
JapaneseCountries[Countries.IT] = "イタリア";
JapaneseCountries[Countries.LV] = "ラトビア";
JapaneseCountries[Countries.LT] = "リトアニア";
JapaneseCountries[Countries.LU] = "ルクセンブルク";
JapaneseCountries[Countries.MT] = "マルタ";
JapaneseCountries[Countries.NL] = "オランダ";
JapaneseCountries[Countries.PL] = "ポーランド";
JapaneseCountries[Countries.PT] = "ポルトガル";
JapaneseCountries[Countries.RO] = "ルーマニア";
JapaneseCountries[Countries.SK] = "スロバキア";
JapaneseCountries[Countries.SI] = "スロベニア";
JapaneseCountries[Countries.ES] = "スペイン";
JapaneseCountries[Countries.SE] = "スウェーデン";
JapaneseCountries[Countries.GB] = "英国";

var JapaneseAutomatchQuestions = {};
var JapaneseAutomatchValues = {};

JapaneseAutomatchQuestions[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS] = "対戦人数";
JapaneseAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS] = {};
JapaneseAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.REQUIRE] = "二人戦";
JapaneseAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.EITHER] = "二人戦・三人戦";
JapaneseAutomatchValues[AUTOMATCH_QUESTION.NUMBER_OF_PLAYERS][MATCHING_PREFERENCE.REJECT] = "三人戦";

JapaneseAutomatchQuestions[AUTOMATCH_QUESTION.RATED_GAME] = "順位戦";
JapaneseAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME] = {};
JapaneseAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.REQUIRE] = "順位戦";
JapaneseAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.EITHER] = "順位戦・練習戦";
JapaneseAutomatchValues[AUTOMATCH_QUESTION.RATED_GAME][MATCHING_PREFERENCE.REJECT] = "練習戦";

JapaneseAutomatchQuestions[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME] = "朝日祭";
JapaneseAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME] = {};
JapaneseAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.REQUIRE] = "オン";
JapaneseAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.EITHER] = "どちらでも";
JapaneseAutomatchValues[AUTOMATCH_QUESTION.NEW_EXPANSION_GAME][MATCHING_PREFERENCE.REJECT] = "オフ";

JapaneseAutomatchQuestions[AUTOMATCH_QUESTION.PREVIEW_GAME] = "略奪プレビュー";
JapaneseAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME] = {};
JapaneseAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.REQUIRE] = "オン";
JapaneseAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.EITHER] = "どちらでも";
JapaneseAutomatchValues[AUTOMATCH_QUESTION.PREVIEW_GAME][MATCHING_PREFERENCE.REJECT] = "オフ";