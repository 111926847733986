"use strict";
// Use like <question-info ng-model="ctrl.question"> </question-info> in HTML
//

webclient.component("questionInfo", {

    bindings: {

        question: "=ngModel"
    },

    controllerAs: "ctrl",

    controller: [function () {

        var ctrl = this;

        ctrl.phrases = PHRASES;
        ctrl.paragraphs = PARAGRAPHS;
        ctrl.info = ctrl.question.htmlKnowledge || {};
        ctrl.continueText = function () {
            return ctrl.phrases.CONTINUE;
        };

        ctrl.continueClicked = function (event) {

            event.stopPropagation();
            ctrl.question.setAnswered(true);

            var nextQuestion = ctrl.question.questionList.nextQuestion();

            if (nextQuestion === ctrl.question && isDefined(ctrl.info.continueAfterLastQuestion)) {
                ctrl.info.continueAfterLastQuestion();
            } else {
                ctrl.question.angularListController.onclickQuestionList(event, ctrl.question.questionList.nextQuestion());
            }
        };
    }],

    templateUrl: 'html/related-questions/info-1.9.2.2.html'
});