"use strict";

var Preloader = {
	loadedSources: {},
	hasFullyLoaded: {},
	waitingCallbacks: {},
	check_webp_feature: function check_webp_feature(callback) {
		var testImage = "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA";
		var img = new Image();
		img.onload = function () {
			var result = img.width > 0 && img.height > 0;
			callback(true);
		};
		img.onerror = function () {
			callback(false);
		};
		img.src = "data:image/webp;base64," + testImage;
	},
	canWebP: false,

	loadInner: function loadInner(source, callback) {
		var failsafe = null;

		if (Preloader.loadedSources.hasOwnProperty(source)) {
			var _domElement = Preloader.loadedSources[source];
			if (Preloader.hasFullyLoaded[source]) {
				callback(_domElement);
				return;
			}
			var onLoad = function onLoad() {
				callback(_domElement);
				_domElement.removeEventListener("load", onLoad);
			};
			_domElement.addEventListener("load", onLoad);

			return;
		}

		var terminate = function terminate() {
			clearTimeout(failsafe);
			if (!Preloader.hasFullyLoaded[source]) {
				console.error("Loading failed for " + source + ".");
				if ("images/elements/red-cross.png" in Preloader.loadedSources) callback(Preloader.loadedSources["images/elements/red-cross.png"]);
			} else {
				callback(Preloader.loadedSources[source]);
			}
		};
		failsafe = setTimeout(terminate, 5000);
		var domElement = new Image();
		domElement.src = source;
		Preloader.loadedSources[source] = domElement;
		Preloader.hasFullyLoaded[source] = false;
		if (callback !== null) {
			var _onLoad = function _onLoad() {
				Preloader.hasFullyLoaded[source] = true;
				terminate();
				domElement.removeEventListener("load", _onLoad);
			};
			var listener = domElement.addEventListener("load", _onLoad);
		}
	},

	load: function load(source, callback) {
		var match = source.match(/^images\/(.*)\.webp$/);
		if (match == null) {
			Preloader.loadInner(source, callback);
		} else {
			if (Preloader.canWebP) {
				Preloader.loadInner(source, callback);
			} else {
				if (match[1] === "large/title") Preloader.loadInner("images/jpeg/" + match[1] + ".png", callback);else Preloader.loadInner("images/jpeg/" + match[1] + ".jpg", callback);
			}
		}
	},
	loadAll: function loadAll(sources, callback) {
		var timeout = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 5000;

		var loadCount = 0;
		var loadeds = [];
		var failsafe = null;
		var terminate = function terminate() {
			if (failsafe !== null) {
				if (loadeds.length < sources.length) {
					console.error("Loading failed for the following assets:");
					console.log(sources.filter(function (src) {
						return !loadeds.includes(src);
					}));
				}
				clearTimeout(failsafe);
				failsafe = null;
				callback(sources.map(function (s) {
					return Preloader.loadedSources[s];
				}));
			}
		};
		failsafe = setTimeout(terminate, timeout);
		sources.forEach(function (source) {
			Preloader.load(source, function () {
				loadeds.push(source);
				loadCount++;
				if (loadCount === sources.length) terminate();
			});
		});
	}
};
Preloader.check_webp_feature(function (res) {
	Preloader.canWebP = res;
});