"use strict";

function Reader(buffer) {
    var self = this;
    self.buffer = buffer;
    self.offset = 0;
    self.dataView = new DataView(buffer);

    var decoder = {};
    if (shouldUseTextEncoder) {
        decoder = new TextDecoder("utf-8");
    }

    self.hasMessages = function () {
        return self.offset < self.buffer.byteLength;
    };

    self.readInt = function () {
        var n = self.dataView.getInt32(self.offset);
        self.offset += 4;
        return n;
    };

    self.readDouble = function () {
        var n = self.dataView.getFloat64(self.offset);
        self.offset += 8;
        return n;
    };

    var maxInt32 = Math.pow(2, 32);

    self.readLong = function () {
        var a = self.readInt();
        var b = self.readInt() >>> 0;
        return b + a * maxInt32;
    };

    self.readDate = function () {
        // Works for positive longs up to 53 bits. After that, you're on your own.
        var a = self.dataView.getUint32(self.offset);
        self.offset += 4;
        var b = self.dataView.getUint32(self.offset);
        self.offset += 4;
        return a * Math.pow(2, 32) + b;
    };

    self.readBoolean = function () {
        var n = self.dataView.getInt8(self.offset);
        self.offset += 1;
        return !!n;
    };

    self.readBooleans = function () {
        var size = self.readInt();
        var bools = [];
        for (var i = 0; i < size; i++) {
            bools.push(self.readBoolean());
        }
        return bools;
    };

    self.readInts = function () {
        var size = self.readInt();
        var ints = [];
        for (var i = 0; i < size; i++) {
            ints.push(self.readInt());
        }
        return ints;
    };

    self.readString = function () {
        var length = self.readInt();
        var s = "";
        if (shouldUseTextEncoder) {
            s = decoder.decode(new DataView(buffer, self.offset, length));
        } else {
            s = String.fromCharCode.apply(null, new Uint8Array(buffer, self.offset, length));
        }
        self.offset += length;
        return s;
    };

    self.readStrings = function () {
        var size = self.readInt();
        var strings = [];
        for (var i = 0; i < size; i++) {
            strings.push(self.readString());
        }
        return strings;
    };

    self.readArrayOf = function (deserializable) {
        var size = self.readInt();
        var typedArray = [];
        for (var i = 0; i < size; i++) {
            typedArray.push(deserializable.parse(self));
        }
        return typedArray;
    };

    self.readArrayOfGameObject = function (game, deserializable) {
        var size = self.readInt();
        var typedArray = [];
        for (var i = 0; i < size; i++) {
            typedArray.push(deserializable.parse(game, self));
        }
        return typedArray;
    };

    self.readEnumArray = function (targetEnum) {
        var size = self.readInt();
        var typedArray = [];
        for (var i = 0; i < size; i++) {
            typedArray[i] = getByOrdinal(targetEnum, self.readInt());
        }
        return typedArray;
    };

    self.readEnumMap = function (targetEnum) {
        var size = self.readInt();
        var map = {};
        for (var i = 0; i < size; i++) {
            var enumObject = getByOrdinal(targetEnum, self.readInt()).name;
            map[enumObject] = self.readInt();
        }
        return map;
    };

    self.readEnumToEnumMap = function (keyEnum, valueEnum) {
        var size = self.readInt();
        var map = {};
        for (var i = 0; i < size; i++) {
            var key = getByOrdinal(keyEnum, self.readInt()).name;
            var value = getByOrdinal(valueEnum, self.readInt()).name;
            map[key] = value;
        }
        return map;
    };

    self.readEnumToEnumMapObject = function (keyEnum, valueEnum) {
        var size = self.readInt();
        var map = new Map();
        for (var i = 0; i < size; i++) {
            var key = getByOrdinal(keyEnum, self.readInt());
            var value = getByOrdinal(valueEnum, self.readInt());
            map.set(key, value);
        }
        return map;
    };

    self.readEnumToIntMapObject = function (keyEnum) {
        var size = self.readInt();
        var map = new Map();
        for (var i = 0; i < size; i++) {
            var key = getByOrdinal(keyEnum, self.readInt());
            var value = self.readInt();
            map.set(key, value);
        }
        return map;
    };

    self.readEnumToObjectMap = function (keyEnum, valueParser) {
        var size = self.readInt();
        var map = new Map();
        for (var i = 0; i < size; i++) {
            var key = getByOrdinal(keyEnum, self.readInt());
            var value = valueParser(self);
            map.set(key, value);
        }
        return map;
    };

    self.readIntMap = function () {
        var size = self.readInt();
        var result = [];
        for (var i = 0; i < size; i++) {
            var key = self.readInt();
            result[key] = self.readInt();
        }
        return result;
    };
}