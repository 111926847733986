"use strict";

webclient.component("replayGame", {
    transclude: true,
    controller: ['replayService', function (replayService) {
        var self = this;
        self.gameId;
        self.decisionIndex;

        self.shouldShow = function () {
            return replayService.shouldShowReplayWindow();
        };
        self.click = function () {

            replayService.start(self.gameId, self.decisionIndex);
        };

        self.getWindowText = function () {
            return getPhrase(Phrases.REPLAY_GAME);
        };

        self.getButtonText = function () {
            return getPhrase(Phrases.START_GAME);
        };

        self.getGameIdText = function () {
            return 'Game Id';
        };
        self.getDecisionIndexText = function () {
            return 'Decision Index';
        };
    }],
    template: "\n            <modal-window ng-if=\"$ctrl.shouldShow()\">\n                <div class=\"timeout\">{{::$ctrl.getWindowText()}}</div>\n                <input type=\"text\" placeholder=\"{{::$ctrl.getGameIdText()}}\" ng-model=\"$ctrl.gameId\"/><br/>\n                <input type=\"text\" placeholder=\"{{::$ctrl.getDecisionIndexText()}}\" ng-model=\"$ctrl.decisionIndex\"/><br/>\n                <button class=\"lobby-button\" ng-click=\"$ctrl.click()\">{{::$ctrl.getButtonText()}}</button>\n            </modal-window>\n        "
});