"use strict";

var GermanAbilityNames = {};

GermanAbilityNames[AbilityNames.BUY] = "a";
GermanAbilityNames[AbilityNames.PLAY_ATTACK] = "Spiele Angriff";
GermanAbilityNames[AbilityNames.DRAW_INSTRUCTIONS] = "b";
GermanAbilityNames[AbilityNames.PLAY_INSTRUCTIONS] = "Spiele";
GermanAbilityNames[AbilityNames.RESOLVE_EVENT] = "c";
GermanAbilityNames[AbilityNames.GAIN_BY_NAME] = "d";

GermanAbilityNames[AbilityNames.FISHING_VILLAGE_ABILITY] = GermanCardNames[CardNames.FISHING_VILLAGE].singular;
GermanAbilityNames[AbilityNames.REMOVE_COST_REDUCTION] = "e";
GermanAbilityNames[AbilityNames.HAVEN_ABILITY] = GermanCardNames[CardNames.HAVEN].singular;
GermanAbilityNames[AbilityNames.SAVE_RETURN_TO_HAND] = GermanCardNames[CardNames.SAVE].singular;
GermanAbilityNames[AbilityNames.CARAVAN_ABILITY] = GermanCardNames[CardNames.CARAVAN].singular;
GermanAbilityNames[AbilityNames.MERCHANT_SHIP_ABILITY] = GermanCardNames[CardNames.MERCHANT_SHIP].singular;
GermanAbilityNames[AbilityNames.OUTPOST_ABILITY] = GermanCardNames[CardNames.OUTPOST].singular;
GermanAbilityNames[AbilityNames.TACTICIAN_ABILITY] = GermanCardNames[CardNames.TACTICIAN].singular;
GermanAbilityNames[AbilityNames.WHARF_ABILITY] = GermanCardNames[CardNames.WHARF].singular;
GermanAbilityNames[AbilityNames.HORSE_TRADERS_RETURN_ABILITY] = GermanCardNames[CardNames.HORSE_TRADERS].singular;
GermanAbilityNames[AbilityNames.DUCHESS_MAY_GAIN] = GermanCardNames[CardNames.DUCHESS].singular;
GermanAbilityNames[AbilityNames.FOOLS_GOLD_TRASH] = GermanCardNames[CardNames.FOOLS_GOLD].singular;
GermanAbilityNames[AbilityNames.NOBLE_BRIGAND] = GermanCardNames[CardNames.NOBLE_BRIGAND].singular;
GermanAbilityNames[AbilityNames.HAGGLER_ABILITY] = GermanCardNames[CardNames.HAGGLER].singular;
GermanAbilityNames[AbilityNames.INN_ABILITY] = GermanCardNames[CardNames.INN].singular;
GermanAbilityNames[AbilityNames.MANDARIN] = GermanCardNames[CardNames.MANDARIN].singular;
GermanAbilityNames[AbilityNames.FARMLAND] = GermanCardNames[CardNames.FARMLAND].singular;
GermanAbilityNames[AbilityNames.GAIN_CHEAPER] = "Nimm billigere Karte";
GermanAbilityNames[AbilityNames.MERCHANT_GUILD] = GermanCardNames[CardNames.MERCHANT_GUILD].singular;
GermanAbilityNames[AbilityNames.HUNTING_GROUNDS] = GermanCardNames[CardNames.HUNTING_GROUNDS].singular;
GermanAbilityNames[AbilityNames.GAIN_TWO_RUINS] = GermanCardNames[CardNames.DEATH_CART].singular;
GermanAbilityNames[AbilityNames.SQUIRE_GAIN_ATTACK] = GermanCardNames[CardNames.SQUIRE].singular;
GermanAbilityNames[AbilityNames.URCHIN_TRANSFORM] = GermanCardNames[CardNames.URCHIN].singular;
GermanAbilityNames[AbilityNames.FEODUM_GAIN_SILVERS] = GermanCardNames[CardNames.FEODUM].singular;
GermanAbilityNames[AbilityNames.DOCTOR_OVERPAY] = "Überzahlen";
GermanAbilityNames[AbilityNames.RESOLVE_DOCTOR_OVERPAY] = GermanCardNames[CardNames.DOCTOR].singular;
GermanAbilityNames[AbilityNames.HERALD_OVERPAY] = "Überzahlen";
GermanAbilityNames[AbilityNames.RESOLVE_HERALD_OVERPAY] = GermanCardNames[CardNames.HERALD].singular;
GermanAbilityNames[AbilityNames.MASTERPIECE_OVERPAY] = "Überzahlen";
GermanAbilityNames[AbilityNames.RESOLVE_MASTERPIECE_OVERPAY] = GermanCardNames[CardNames.MASTERPIECE].singular;
GermanAbilityNames[AbilityNames.STONEMASON_OVERPAY] = "Überzahlen";
GermanAbilityNames[AbilityNames.RESOLVE_STONEMASON_OVERPAY] = GermanCardNames[CardNames.STONEMASON].singular;
GermanAbilityNames[AbilityNames.EXCHANGE] = "Tausche aus";
GermanAbilityNames[AbilityNames.SHUFFLE] = "Mische";
GermanAbilityNames[AbilityNames.AMULET] = GermanCardNames[CardNames.AMULET].singular;
GermanAbilityNames[AbilityNames.CARAVAN_GUARD_NEXT_TURN] = GermanCardNames[CardNames.CARAVAN_GUARD].singular;
GermanAbilityNames[AbilityNames.DUNGEON_ABILITY] = GermanCardNames[CardNames.DUNGEON].singular;
GermanAbilityNames[AbilityNames.GEAR_ABILITY] = GermanCardNames[CardNames.GEAR].singular;
GermanAbilityNames[AbilityNames.MESSENGER_GAIN] = GermanCardNames[CardNames.MESSENGER].singular;
GermanAbilityNames[AbilityNames.BRIDGE_TROLL_ABILITY] = GermanCardNames[CardNames.BRIDGE_TROLL].singular;
GermanAbilityNames[AbilityNames.HAUNTED_WOODS_DURATION] = "Ziehe";
GermanAbilityNames[AbilityNames.HAUNTED_WOODS_TOPDECK] = "Auf Nachziehstapel";
GermanAbilityNames[AbilityNames.LOST_CITY] = GermanCardNames[CardNames.LOST_CITY].singular;
GermanAbilityNames[AbilityNames.WINE_MERCHANT_DISCARD] = GermanCardNames[CardNames.WINE_MERCHANT].singular;
GermanAbilityNames[AbilityNames.HIRELING] = GermanCardNames[CardNames.HIRELING].singular;
GermanAbilityNames[AbilityNames.SWAMP_HAG_DURATION] = GermanCardNames[CardNames.SWAMP_HAG].singular;
GermanAbilityNames[AbilityNames.TRASH_TOKEN_ABILITY] = GermanCardNames[CardNames.PLAN].singular;
GermanAbilityNames[AbilityNames.EMPORIUM_GAIN_POINTS] = GermanCardNames[CardNames.EMPORIUM].singular;
GermanAbilityNames[AbilityNames.ROCKS_GAINS_SILVER] = GermanCardNames[CardNames.ROCKS].singular;
GermanAbilityNames[AbilityNames.FORTUNE] = GermanCardNames[CardNames.FORTUNE].singular;
GermanAbilityNames[AbilityNames.CRUMBLING_CASTLE] = GermanCardNames[CardNames.CRUMBLING_CASTLE].singular;
GermanAbilityNames[AbilityNames.HAUNTED_CASTLE] = GermanCardNames[CardNames.HAUNTED_CASTLE].singular;
GermanAbilityNames[AbilityNames.SPRAWLING_CASTLE] = GermanCardNames[CardNames.SPRAWLING_CASTLE].singular;
GermanAbilityNames[AbilityNames.GRAND_CASTLE] = GermanCardNames[CardNames.GRAND_CASTLE].singular;
GermanAbilityNames[AbilityNames.ENCHANTRESS_ABILITY] = GermanCardNames[CardNames.ENCHANTRESS].singular;
GermanAbilityNames[AbilityNames.ENCHANTRESS_CANTRIP] = "+1 Karte, +1 Aktion";
GermanAbilityNames[AbilityNames.TEMPLE] = GermanCardNames[CardNames.TEMPLE].singular;
GermanAbilityNames[AbilityNames.VILLA] = GermanCardNames[CardNames.VILLA].singular;
GermanAbilityNames[AbilityNames.ARCHIVE_ABILITY] = GermanCardNames[CardNames.ARCHIVE].singular;
GermanAbilityNames[AbilityNames.CAPITAL_TAKE_DEBT] = GermanCardNames[CardNames.CAPITAL].singular;
GermanAbilityNames[AbilityNames.CHARM] = GermanCardNames[CardNames.CHARM].singular;
GermanAbilityNames[AbilityNames.FORUM] = GermanCardNames[CardNames.FORUM].singular;
GermanAbilityNames[AbilityNames.HERMIT] = GermanCardNames[CardNames.HERMIT].singular;
GermanAbilityNames[AbilityNames.DONATE] = GermanCardNames[CardNames.DONATE].singular;
GermanAbilityNames[AbilityNames.TAX_RECEIVE] = GermanCardNames[CardNames.TAX].singular;
GermanAbilityNames[AbilityNames.DOMINATE] = GermanCardNames[CardNames.DOMINATE].singular;
GermanAbilityNames[AbilityNames.VP_TO_AQUEDUCT] = "SP auf Aquädukt";
GermanAbilityNames[AbilityNames.VP_FROM_AQUEDUCT] = "SP von Aquädukt";
GermanAbilityNames[AbilityNames.ARENA_DISCARD_ACTION] = GermanCardNames[CardNames.ARENA].singular;
GermanAbilityNames[AbilityNames.VP_FROM_BASILICA] = GermanCardNames[CardNames.BASILICA].singular;
GermanAbilityNames[AbilityNames.VP_FROM_BATHS] = GermanCardNames[CardNames.BATHS].singular;
GermanAbilityNames[AbilityNames.VP_FROM_BATTLEFIELD] = GermanCardNames[CardNames.BATTLEFIELD].singular;
GermanAbilityNames[AbilityNames.VP_FROM_COLONNADE] = GermanCardNames[CardNames.COLONNADE].singular;
GermanAbilityNames[AbilityNames.VP_TO_DEFILED_SHRINE] = "SP auf Entweihter Schrein";
GermanAbilityNames[AbilityNames.VP_FROM_DEFILED_SHRINE] = "SP: Entweihter Schrein";
GermanAbilityNames[AbilityNames.VP_FROM_LABYRINTH] = GermanCardNames[CardNames.LABYRINTH].singular;
GermanAbilityNames[AbilityNames.MOUNTAIN_PASS_BIDDING] = GermanCardNames[CardNames.MOUNTAIN_PASS].singular;
GermanAbilityNames[AbilityNames.TOMB] = GermanCardNames[CardNames.TOMB].singular;
GermanAbilityNames[AbilityNames.LIGHTHOUSE_COIN] = GermanCardNames[CardNames.LIGHTHOUSE].singular;
GermanAbilityNames[AbilityNames.REACTION] = "Reaktion";
GermanAbilityNames[AbilityNames.CALL_GUIDE] = "Rufe " + GermanCardNames[CardNames.GUIDE].singular + " auf";
GermanAbilityNames[AbilityNames.CALL_RATCATCHER] = "Rufe " + GermanCardNames[CardNames.RATCATCHER].singular + " auf";
GermanAbilityNames[AbilityNames.CALL_TRANSMOGRIFY] = "Rufe " + GermanCardNames[CardNames.TRANSMOGRIFY].singular + " auf";
GermanAbilityNames[AbilityNames.CALL_TEACHER] = "Rufe " + GermanCardNames[CardNames.TEACHER].singular + " auf";
GermanAbilityNames[AbilityNames.CALL_DUPLICATE] = "Rufe " + GermanCardNames[CardNames.DUPLICATE].singular + " auf";
GermanAbilityNames[AbilityNames.CALL_COIN] = "Rufe " + GermanCardNames[CardNames.COIN_OF_THE_REALM].singular; // too long for " auf"
GermanAbilityNames[AbilityNames.CALL_ROYAL_CARRIAGE] = "Rufe " + GermanCardNames[CardNames.ROYAL_CARRIAGE].singular + " auf";
GermanAbilityNames[AbilityNames.START_OF_TURN] = "Beginn deines Zuges";
GermanAbilityNames[AbilityNames.START_BUY_PHASE] = "Beginn deiner Kaufphase";
GermanAbilityNames[AbilityNames.END_OF_BUY_PHASE] = "Ende deiner Kaufphase";
GermanAbilityNames[AbilityNames.BETWEEN_TURNS] = "Zwischen den Zügen";
GermanAbilityNames[AbilityNames.END_OF_TURN] = "Ende deines Zuges";
GermanAbilityNames[AbilityNames.SUMMON_PLAY] = GermanCardNames[CardNames.SUMMON].singular;
GermanAbilityNames[AbilityNames.CARAVAN_GUARD_REACTION] = GermanCardNames[CardNames.CARAVAN_GUARD].singular;
GermanAbilityNames[AbilityNames.TRAVELLING_FAIR_REACTION] = GermanCardNames[CardNames.TRAVELLING_FAIR].singular;
GermanAbilityNames[AbilityNames.MOAT_REACTION] = GermanCardNames[CardNames.MOAT].singular;
GermanAbilityNames[AbilityNames.HORSE_TRADERS_REACTION] = GermanCardNames[CardNames.HORSE_TRADERS].singular;
GermanAbilityNames[AbilityNames.BEGGAR_REACTION] = GermanCardNames[CardNames.BEGGAR].singular;
GermanAbilityNames[AbilityNames.MARKET_SQUARE_REACTION] = GermanCardNames[CardNames.MARKET_SQUARE].singular;
GermanAbilityNames[AbilityNames.TRADER_REACTION] = GermanCardNames[CardNames.TRADER].singular;
GermanAbilityNames[AbilityNames.DIPLOMAT_REACTION] = GermanCardNames[CardNames.DIPLOMAT].singular;
GermanAbilityNames[AbilityNames.TOPDECK_REACTION] = "Auf Nachziehstapel";
GermanAbilityNames[AbilityNames.WATCHTOWER_TRASH_REACTION] = "Entsorge";
GermanAbilityNames[AbilityNames.WATCHTOWER_TOPDECK_REACTION] = "Auf Nachziehstapel";
GermanAbilityNames[AbilityNames.PRINCE_PLAY] = GermanCardNames[CardNames.PRINCE].singular;
GermanAbilityNames[AbilityNames.PRINCE_SET_ASIDE] = GermanCardNames[CardNames.PRINCE].singular;
GermanAbilityNames[AbilityNames.DELUDED_RETURNED] = "Lege Zustand zurück";
GermanAbilityNames[AbilityNames.ENVIOUS_RETURNED] = "Lege Zustand zurück";
GermanAbilityNames[AbilityNames.RECEIVE_BOON] = "Empfange Gabe";
GermanAbilityNames[AbilityNames.COBBLER_ABILITY] = GermanCardNames[CardNames.COBBLER].singular;
GermanAbilityNames[AbilityNames.CRYPT_ABILITY] = GermanCardNames[CardNames.CRYPT].singular;
GermanAbilityNames[AbilityNames.DEN_OF_SIN_ABILITY] = GermanCardNames[CardNames.DEN_OF_SIN].singular;
GermanAbilityNames[AbilityNames.FAITHFUL_HOUND] = GermanCardNames[CardNames.FAITHFUL_HOUND].singular;
GermanAbilityNames[AbilityNames.FAITHFUL_HOUND_RETURN] = GermanCardNames[CardNames.FAITHFUL_HOUND].singular;
GermanAbilityNames[AbilityNames.GHOST_TOWN_ABILITY] = GermanCardNames[CardNames.GHOST_TOWN].singular;
GermanAbilityNames[AbilityNames.GUARDIAN_COIN] = GermanCardNames[CardNames.GUARDIAN].singular;
GermanAbilityNames[AbilityNames.RAIDER_ABILITY] = GermanCardNames[CardNames.RAIDER].singular;
GermanAbilityNames[AbilityNames.SECRET_CAVE] = GermanCardNames[CardNames.SECRET_CAVE].singular;
GermanAbilityNames[AbilityNames.CEMETERY] = GermanCardNames[CardNames.CEMETERY].singular;
GermanAbilityNames[AbilityNames.HAUNTED_MIRROR] = GermanCardNames[CardNames.HAUNTED_MIRROR].singular;
GermanAbilityNames[AbilityNames.GHOST] = GermanCardNames[CardNames.GHOST].singular;
GermanAbilityNames[AbilityNames.REMOVE_FLIPPED_STATUS] = "X";
GermanAbilityNames[AbilityNames.LOST_IN_THE_WOODS] = GermanCardNames[CardNames.LOST_IN_THE_WOODS].singular;
GermanAbilityNames[AbilityNames.BLESSED_VILLAGE] = GermanCardNames[CardNames.BLESSED_VILLAGE].singular;
GermanAbilityNames[AbilityNames.FORTRESS] = GermanCardNames[CardNames.FORTRESS].singular;
GermanAbilityNames[AbilityNames.RECEIVE_HEX] = "Empfange Plage";
GermanAbilityNames[AbilityNames.HOVEL_TRASH] = GermanCardNames[CardNames.HOVEL].singular;
GermanAbilityNames[AbilityNames.TUNNEL_REVEAL] = GermanCardNames[CardNames.TUNNEL].singular;
GermanAbilityNames[AbilityNames.CHANGELING_MAY_EXCHANGE] = GermanCardNames[CardNames.CHANGELING].singular;

GermanAbilityNames[AbilityNames.DUCAT] = GermanCardNames[CardNames.DUCAT].singular;
GermanAbilityNames[AbilityNames.CARGO_SHIP_SET_ASIDE] = GermanCardNames[CardNames.CARGO_SHIP].singular + " beladen";
GermanAbilityNames[AbilityNames.CARGO_SHIP_RETURN] = GermanCardNames[CardNames.CARGO_SHIP].singular + " entladen";
GermanAbilityNames[AbilityNames.IMPROVE] = GermanCardNames[CardNames.IMPROVE].singular;
GermanAbilityNames[AbilityNames.PRIEST] = GermanCardNames[CardNames.PRIEST].singular;
GermanAbilityNames[AbilityNames.RESEARCH] = GermanCardNames[CardNames.RESEARCH].singular;
GermanAbilityNames[AbilityNames.SILK_MERCHANT] = GermanCardNames[CardNames.SILK_MERCHANT].singular;
GermanAbilityNames[AbilityNames.LACKEYS] = GermanCardNames[CardNames.LACKEYS].singular;
GermanAbilityNames[AbilityNames.SPICES] = GermanCardNames[CardNames.SPICES].singular;
GermanAbilityNames[AbilityNames.KEY] = GermanCardNames[CardNames.KEY].singular;
GermanAbilityNames[AbilityNames.TREASURE_CHEST] = GermanCardNames[CardNames.TREASURE_CHEST].singular;
GermanAbilityNames[AbilityNames.FLAG_BEARER] = GermanCardNames[CardNames.FLAG_BEARER].singular;
GermanAbilityNames[AbilityNames.CATHEDRAL] = GermanCardNames[CardNames.CATHEDRAL].singular;
GermanAbilityNames[AbilityNames.CITY_GATE] = GermanCardNames[CardNames.CITY_GATE].singular;
GermanAbilityNames[AbilityNames.PAGEANT] = GermanCardNames[CardNames.PAGEANT].singular;
GermanAbilityNames[AbilityNames.SEWERS_MAY_TRASH] = GermanCardNames[CardNames.SEWERS].singular;
GermanAbilityNames[AbilityNames.EXPLORATION] = GermanCardNames[CardNames.EXPLORATION].singular;
GermanAbilityNames[AbilityNames.FAIR] = GermanCardNames[CardNames.FAIR].singular;
GermanAbilityNames[AbilityNames.SILOS] = GermanCardNames[CardNames.SILOS].singular;
GermanAbilityNames[AbilityNames.ACADEMY] = GermanCardNames[CardNames.ACADEMY].singular;
GermanAbilityNames[AbilityNames.GUILDHALL] = GermanCardNames[CardNames.GUILDHALL].singular;
GermanAbilityNames[AbilityNames.STAR_CHART] = GermanCardNames[CardNames.STAR_CHART].singular;
GermanAbilityNames[AbilityNames.PIAZZA] = GermanCardNames[CardNames.PIAZZA].singular;
GermanAbilityNames[AbilityNames.BARRACKS] = GermanCardNames[CardNames.BARRACKS].singular;
GermanAbilityNames[AbilityNames.CROP_ROTATION] = GermanCardNames[CardNames.CROP_ROTATION].singular;
GermanAbilityNames[AbilityNames.INNOVATION] = GermanCardNames[CardNames.INNOVATION].singular;
GermanAbilityNames[AbilityNames.CITADEL] = GermanCardNames[CardNames.CITADEL].singular;
GermanAbilityNames[AbilityNames.SINISTER_PLOT] = GermanCardNames[CardNames.SINISTER_PLOT].singular;
GermanAbilityNames[AbilityNames.ENCAMPMENT_RETURNED] = GermanCardNames[CardNames.ENCAMPMENT].singular;
GermanAbilityNames[AbilityNames.EMBARGO_GAIN_CURSE] = GermanCardNames[CardNames.EMBARGO].singular;
GermanAbilityNames[AbilityNames.POSSESSION_GAIN_INSTEAD] = GermanCardNames[CardNames.POSSESSION].singular;
GermanAbilityNames[AbilityNames.TRADE_ROUTE_MOVE_TOKEN] = GermanCardNames[CardNames.TRADE_ROUTE].singular;
GermanAbilityNames[AbilityNames.CAPTAIN] = GermanCardNames[CardNames.CAPTAIN].singular;
GermanAbilityNames[AbilityNames.CHURCH] = GermanCardNames[CardNames.CHURCH].singular;
GermanAbilityNames[AbilityNames.POSSESSION_SETS_ASIDE] = GermanCardNames[CardNames.POSSESSION].singular;
GermanAbilityNames[AbilityNames.MINT] = GermanCardNames[CardNames.MINT].singular;

GermanAbilityNames[AbilityNames.SLEIGH_TOPDECK_REACTION] = "Auf Nachziehstapel";
GermanAbilityNames[AbilityNames.SLEIGH_PUT_IN_HAND_REACTION] = "Auf die Hand";
GermanAbilityNames[AbilityNames.BLACK_CAT_REACTION] = GermanCardNames[CardNames.BLACK_CAT].singular;
GermanAbilityNames[AbilityNames.DISCARD_FROM_EXILE] = "Lege aus Exil ab";
GermanAbilityNames[AbilityNames.EXILE_BY_NAME] = "Lege ins Exil";
GermanAbilityNames[AbilityNames.SHEEPDOG_REACTION] = GermanCardNames[CardNames.SHEEPDOG].singular;
GermanAbilityNames[AbilityNames.CAVALRY] = GermanCardNames[CardNames.CAVALRY].singular;
GermanAbilityNames[AbilityNames.HOSTELRY] = GermanCardNames[CardNames.HOSTELRY].singular;
GermanAbilityNames[AbilityNames.VILLAGE_GREEN_VILLAGE] = GermanCardNames[CardNames.VILLAGE_GREEN].singular;
GermanAbilityNames[AbilityNames.VILLAGE_GREEN_PLAY] = GermanCardNames[CardNames.VILLAGE_GREEN].singular;
GermanAbilityNames[AbilityNames.BARGE] = GermanCardNames[CardNames.BARGE].singular;
GermanAbilityNames[AbilityNames.FALCONER_REACTION] = GermanCardNames[CardNames.FALCONER].singular;
GermanAbilityNames[AbilityNames.GATEKEEPER_EXILE] = GermanCardNames[CardNames.GATEKEEPER].singular;
GermanAbilityNames[AbilityNames.GATEKEEPER_DURATION] = GermanCardNames[CardNames.GATEKEEPER].singular;
GermanAbilityNames[AbilityNames.LIVERY] = GermanCardNames[CardNames.LIVERY].singular;
GermanAbilityNames[AbilityNames.MASTERMIND] = GermanCardNames[CardNames.MASTERMIND].singular;
GermanAbilityNames[AbilityNames.DELAY_PLAY] = GermanCardNames[CardNames.DELAY].singular;
GermanAbilityNames[AbilityNames.INVEST] = GermanCardNames[CardNames.INVEST].singular;
GermanAbilityNames[AbilityNames.REAP_PLAY] = GermanCardNames[CardNames.REAP].singular;
GermanAbilityNames[AbilityNames.PLUS_CARD_TOKEN] = "+1 Karte";
GermanAbilityNames[AbilityNames.PLUS_BUY_TOKEN] = "+1 Kauf";
GermanAbilityNames[AbilityNames.PLUS_ACTION_TOKEN] = "+1 Aktion";
GermanAbilityNames[AbilityNames.PLUS_COIN_TOKEN] = "+1 Geld";
GermanAbilityNames[AbilityNames.RETURN_MINUS_COIN_TOKEN] = "-Geld Marker zurücklegen";
GermanAbilityNames[AbilityNames.KILN] = GermanCardNames[CardNames.KILN].singular;
GermanAbilityNames[AbilityNames.TURTLE_PLAY] = GermanCardNames[CardNames.WAY_OF_THE_TURTLE].singular;
GermanAbilityNames[AbilityNames.CHAMELEON_RESOLUTION] = GermanCardNames[CardNames.WAY_OF_THE_CHAMELEON].singular;
GermanAbilityNames[AbilityNames.GIVE_PLUS_CARDS] = GermanAbilityNames[AbilityNames.PLUS_CARD_TOKEN];
GermanAbilityNames[AbilityNames.GIVE_PLUS_BUYS] = GermanAbilityNames[AbilityNames.PLUS_BUY_TOKEN];
GermanAbilityNames[AbilityNames.GIVE_PLUS_ACTIONS] = GermanAbilityNames[AbilityNames.PLUS_ACTION_TOKEN];
GermanAbilityNames[AbilityNames.GIVE_PLUS_COINS] = GermanAbilityNames[AbilityNames.PLUS_COIN_TOKEN];

// Allies
GermanAbilityNames[AbilityNames.CONJURER] = GermanCardNames[CardNames.CONJURER].singular;
GermanAbilityNames[AbilityNames.CONTRACT_ABILITY] = GermanCardNames[CardNames.CONTRACT].singular;
GermanAbilityNames[AbilityNames.ENCHANTRESS_REPLACE_ABILITY] = GermanCardNames[CardNames.ENCHANTRESS].singular;
GermanAbilityNames[AbilityNames.GALLERIA] = GermanCardNames[CardNames.GALLERIA].singular;
GermanAbilityNames[AbilityNames.GARRISON_ADD_TOKEN] = GermanCardNames[CardNames.GARRISON].singular;
GermanAbilityNames[AbilityNames.GARRISON_REMOVE_ABILITY] = GermanCardNames[CardNames.GARRISON].singular;
GermanAbilityNames[AbilityNames.GUILDMASTER] = GermanCardNames[CardNames.GUILDMASTER].singular;
GermanAbilityNames[AbilityNames.HIGHWAYMAN_NEXT_TURN_ABILITY] = GermanCardNames[CardNames.HIGHWAYMAN].singular;
GermanAbilityNames[AbilityNames.HIGHWAYMAN_NULLIFIED] = GermanCardNames[CardNames.HIGHWAYMAN].singular;
GermanAbilityNames[AbilityNames.HIGHWAYMAN_REPLACE_ABILITY] = GermanCardNames[CardNames.HIGHWAYMAN].singular;
GermanAbilityNames[AbilityNames.IMPORTER_ABILITY] = GermanCardNames[CardNames.IMPORTER].singular;
GermanAbilityNames[AbilityNames.LICH] = GermanCardNames[CardNames.LICH].singular;
GermanAbilityNames[AbilityNames.ROYAL_GALLEY_PLAY] = GermanCardNames[CardNames.ROYAL_GALLEY].singular;
GermanAbilityNames[AbilityNames.ROYAL_GALLEY_SET_ASIDE] = GermanCardNames[CardNames.ROYAL_GALLEY].singular;
GermanAbilityNames[AbilityNames.SKIRMISHER] = GermanCardNames[CardNames.SKIRMISHER].singular;
GermanAbilityNames[AbilityNames.STRONGHOLD_ABILITY] = GermanCardNames[CardNames.STRONGHOLD].singular;
GermanAbilityNames[AbilityNames.SYCOPHANT] = GermanCardNames[CardNames.SYCOPHANT].singular;
GermanAbilityNames[AbilityNames.TERRITORY_GAIN] = GermanCardNames[CardNames.TERRITORY].singular;
GermanAbilityNames[AbilityNames.WARLORD_ABILITY] = GermanCardNames[CardNames.WARLORD].singular;

GermanAbilityNames[AbilityNames.ARCHITECTS_GUILD] = GermanCardNames[CardNames.ARCHITECTS_GUILD].singular;
GermanAbilityNames[AbilityNames.BAND_OF_NOMADS_ACTION] = "+1 Aktion";
GermanAbilityNames[AbilityNames.BAND_OF_NOMADS_BUY] = "+1 Kauf";
GermanAbilityNames[AbilityNames.BAND_OF_NOMADS_CARD] = "+1 Karte";
GermanAbilityNames[AbilityNames.CAVE_DWELLERS] = GermanCardNames[CardNames.CAVE_DWELLERS].singular;
GermanAbilityNames[AbilityNames.CIRCLE_OF_WITCHES] = GermanCardNames[CardNames.CIRCLE_OF_WITCHES].singular;
GermanAbilityNames[AbilityNames.CITY_STATE] = GermanCardNames[CardNames.CITY_STATE].singular;
GermanAbilityNames[AbilityNames.CITY_STATE_PLAY] = GermanCardNames[CardNames.CITY_STATE].singular;
GermanAbilityNames[AbilityNames.COASTAL_HAVEN] = GermanCardNames[CardNames.COASTAL_HAVEN].singular;
GermanAbilityNames[AbilityNames.CRAFTERS_GUILD] = GermanCardNames[CardNames.CRAFTERS_GUILD].singular;
GermanAbilityNames[AbilityNames.DESERT_GUIDES] = GermanCardNames[CardNames.DESERT_GUIDES].singular;
GermanAbilityNames[AbilityNames.FAMILY_OF_INVENTORS] = GermanCardNames[CardNames.FAMILY_OF_INVENTORS].singular;
GermanAbilityNames[AbilityNames.FELLOWSHIP_OF_SCRIBES] = GermanCardNames[CardNames.FELLOWSHIP_OF_SCRIBES].singular;
GermanAbilityNames[AbilityNames.FOREST_DWELLERS] = GermanCardNames[CardNames.FOREST_DWELLERS].singular;
GermanAbilityNames[AbilityNames.GANG_OF_PICKPOCKETS] = GermanCardNames[CardNames.GANG_OF_PICKPOCKETS].singular;
GermanAbilityNames[AbilityNames.ISLAND_FOLK] = GermanCardNames[CardNames.ISLAND_FOLK].singular;
GermanAbilityNames[AbilityNames.LEAGUE_OF_BANKERS] = GermanCardNames[CardNames.LEAGUE_OF_BANKERS].singular;
GermanAbilityNames[AbilityNames.LEAGUE_OF_SHOPKEEPERS] = GermanCardNames[CardNames.LEAGUE_OF_SHOPKEEPERS].singular;
GermanAbilityNames[AbilityNames.MARKET_TOWNS] = GermanCardNames[CardNames.MARKET_TOWNS].singular;
GermanAbilityNames[AbilityNames.MOUNTAIN_FOLK] = GermanCardNames[CardNames.MOUNTAIN_FOLK].singular;
GermanAbilityNames[AbilityNames.ORDER_OF_ASTROLOGERS] = GermanCardNames[CardNames.ORDER_OF_ASTROLOGERS].singular;
GermanAbilityNames[AbilityNames.ORDER_OF_MASONS] = GermanCardNames[CardNames.ORDER_OF_MASONS].singular;
GermanAbilityNames[AbilityNames.PEACEFUL_CULT] = GermanCardNames[CardNames.PEACEFUL_CULT].singular;
GermanAbilityNames[AbilityNames.TRAPPERS_LODGE] = GermanCardNames[CardNames.TRAPPERS_LODGE].singular;
GermanAbilityNames[AbilityNames.WOODWORKERS_GUILD] = GermanCardNames[CardNames.WOODWORKERS_GUILD].singular;

// 2Es
GermanAbilityNames[AbilityNames.ASTROLABE_ABILITY] = GermanCardNames[CardNames.ASTROLABE].singular;
GermanAbilityNames[AbilityNames.BLOCKADE_ABILITY] = GermanCardNames[CardNames.BLOCKADE].singular;
GermanAbilityNames[AbilityNames.CORSAIR_NEXT_TURN_ABILITY] = GermanCardNames[CardNames.CORSAIR].singular;
GermanAbilityNames[AbilityNames.CORSAIR_TRASH] = GermanCardNames[CardNames.CORSAIR].singular;
GermanAbilityNames[AbilityNames.MONKEY_NEXT_TURN_ABILITY] = GermanCardNames[CardNames.MONKEY].singular;
GermanAbilityNames[AbilityNames.PIRATE_ABILITY] = GermanCardNames[CardNames.PIRATE].singular;
GermanAbilityNames[AbilityNames.PIRATE_REACTION] = GermanCardNames[CardNames.PIRATE].singular;
GermanAbilityNames[AbilityNames.SAILOR_NEXT_TURN] = GermanCardNames[CardNames.SAILOR].singular;
GermanAbilityNames[AbilityNames.SAILOR_REACTION] = GermanCardNames[CardNames.SAILOR].singular;
GermanAbilityNames[AbilityNames.SEA_WITCH_ABILITY] = GermanCardNames[CardNames.SEA_WITCH].singular;
GermanAbilityNames[AbilityNames.TIDE_POOLS_ABILITY] = GermanCardNames[CardNames.TIDE_POOLS].singular;
GermanAbilityNames[AbilityNames.TREASURY] = GermanCardNames[CardNames.TREASURY].singular;
GermanAbilityNames[AbilityNames.CLERK_ABILITY] = GermanCardNames[CardNames.CLERK].singular;
GermanAbilityNames[AbilityNames.BERSERKER] = GermanCardNames[CardNames.BERSERKER].singular;
GermanAbilityNames[AbilityNames.CAULDRON_CURSING] = GermanCardNames[CardNames.CAULDRON].singular;
GermanAbilityNames[AbilityNames.GUARD_DOG_REACTION] = GermanCardNames[CardNames.GUARD_DOG].singular;
GermanAbilityNames[AbilityNames.NOMADS] = GermanCardNames[CardNames.NOMADS].singular;
GermanAbilityNames[AbilityNames.SOUK] = GermanCardNames[CardNames.SOUK].singular;
GermanAbilityNames[AbilityNames.TRAIL_REACTION] = GermanCardNames[CardNames.TRAIL].singular;
GermanAbilityNames[AbilityNames.WEAVER_PLAY] = GermanCardNames[CardNames.WEAVER].singular;

// Misc
GermanAbilityNames[AbilityNames.ALCHEMIST_TOPDECK] = GermanCardNames[CardNames.ALCHEMIST].singular;
GermanAbilityNames[AbilityNames.HERBALIST_TOPDECK] = GermanCardNames[CardNames.HERBALIST].singular;
GermanAbilityNames[AbilityNames.HERBALIST_ASK_FOR_DISCARD] = GermanCardNames[CardNames.HERBALIST].singular;
GermanAbilityNames[AbilityNames.SCHEME_TOPDECK] = GermanCardNames[CardNames.SCHEME].singular;
GermanAbilityNames[AbilityNames.SCHEME_ASK_FOR_DISCARD] = GermanCardNames[CardNames.SCHEME].singular;
GermanAbilityNames[AbilityNames.FROG_TOPDECK] = GermanCardNames[CardNames.WAY_OF_THE_FROG].singular;
GermanAbilityNames[AbilityNames.MERCHANT_CAMP_TOPDECK] = GermanCardNames[CardNames.MERCHANT_CAMP].singular;
GermanAbilityNames[AbilityNames.WALLED_VILLAGE_TOPDECK] = GermanCardNames[CardNames.WALLED_VILLAGE].singular;
GermanAbilityNames[AbilityNames.TENT_TOPDECK] = GermanCardNames[CardNames.TENT].singular;
GermanAbilityNames[AbilityNames.HORN_TOPDECK] = GermanCardNames[CardNames.HORN].singular;
GermanAbilityNames[AbilityNames.PAGE_EXCHANGE] = GermanCardNames[CardNames.TREASURE_HUNTER].singular;
GermanAbilityNames[AbilityNames.TREASURE_HUNTER_EXCHANGE] = GermanCardNames[CardNames.WARRIOR].singular;
GermanAbilityNames[AbilityNames.WARRIOR_EXCHANGE] = GermanCardNames[CardNames.HERO].singular;
GermanAbilityNames[AbilityNames.HERO_EXCHANGE] = GermanCardNames[CardNames.CHAMPION].singular;
GermanAbilityNames[AbilityNames.PEASANT_EXCHANGE] = GermanCardNames[CardNames.SOLDIER].singular;
GermanAbilityNames[AbilityNames.SOLDIER_EXCHANGE] = GermanCardNames[CardNames.FUGITIVE].singular;
GermanAbilityNames[AbilityNames.FUGITIVE_EXCHANGE] = GermanCardNames[CardNames.DISCIPLE].singular;
GermanAbilityNames[AbilityNames.DISCIPLE_EXCHANGE] = GermanCardNames[CardNames.TEACHER].singular;
GermanAbilityNames[AbilityNames.SAUNA_TRASH_ABILITY] = GermanCardNames[CardNames.SAUNA].singular;

//Plunder
GermanAbilityNames[AbilityNames.CAGE_TRASH] = GermanCardNames[CardNames.CAGE].singular;
GermanAbilityNames[AbilityNames.CAGE_PUT_IN_HAND] = GermanCardNames[CardNames.CAGE].singular;
GermanAbilityNames[AbilityNames.GROTTO] = GermanCardNames[CardNames.GROTTO].singular;
GermanAbilityNames[AbilityNames.JEWELLED_EGG_GAIN_LOOT] = GermanCardNames[CardNames.JEWELLED_EGG].singular;
GermanAbilityNames[AbilityNames.SEARCH] = GermanCardNames[CardNames.SEARCH].singular;
GermanAbilityNames[AbilityNames.SHAMAN] = GermanCardNames[CardNames.SHAMAN].singular;
GermanAbilityNames[AbilityNames.SECLUDED_SHRINE] = GermanCardNames[CardNames.SECLUDED_SHRINE].singular;
GermanAbilityNames[AbilityNames.SIREN_GAIN] = GermanCardNames[CardNames.SIREN].singular;
GermanAbilityNames[AbilityNames.SIREN_DURATION] = GermanCardNames[CardNames.SIREN].singular;
GermanAbilityNames[AbilityNames.STOWAWAY_DRAW] = GermanCardNames[CardNames.STOWAWAY].singular;
GermanAbilityNames[AbilityNames.STOWAWAY_REACTION] = GermanCardNames[CardNames.STOWAWAY].singular;
GermanAbilityNames[AbilityNames.TASKMASTER] = GermanCardNames[CardNames.TASKMASTER].singular;
GermanAbilityNames[AbilityNames.ABUNDANCE] = GermanCardNames[CardNames.ABUNDANCE].singular;
GermanAbilityNames[AbilityNames.CABIN_BOY] = GermanCardNames[CardNames.CABIN_BOY].singular;
GermanAbilityNames[AbilityNames.CRUCIBLE] = GermanCardNames[CardNames.CRUCIBLE].singular;
GermanAbilityNames[AbilityNames.FLAGSHIP_ACTIVATE] = GermanCardNames[CardNames.FLAGSHIP].singular;
GermanAbilityNames[AbilityNames.FLAGSHIP_REPLAY] = GermanCardNames[CardNames.FLAGSHIP].singular;
GermanAbilityNames[AbilityNames.GONDOLA_DURATION] = GermanCardNames[CardNames.GONDOLA].singular;
GermanAbilityNames[AbilityNames.GONDOLA_PLAY] = GermanCardNames[CardNames.GONDOLA].singular;
GermanAbilityNames[AbilityNames.HARBOR_VILLAGE] = GermanCardNames[CardNames.HARBOR_VILLAGE].singular;
GermanAbilityNames[AbilityNames.LANDING_PARTY] = GermanCardNames[CardNames.LANDING_PARTY].singular;
GermanAbilityNames[AbilityNames.MAPMAKER] = GermanCardNames[CardNames.MAPMAKER].singular;
GermanAbilityNames[AbilityNames.ROPE] = GermanCardNames[CardNames.ROPE].singular;
GermanAbilityNames[AbilityNames.BURIED_TREASURE_PLAY] = GermanCardNames[CardNames.BURIED_TREASURE].singular;
GermanAbilityNames[AbilityNames.BURIED_TREASURE_DURATION] = GermanCardNames[CardNames.BURIED_TREASURE].singular;
GermanAbilityNames[AbilityNames.CREW_DURATION] = GermanCardNames[CardNames.CREW].singular;
GermanAbilityNames[AbilityNames.CUTTHROAT_GAIN] = GermanCardNames[CardNames.CUTTHROAT].singular;
GermanAbilityNames[AbilityNames.ENLARGE] = GermanCardNames[CardNames.ENLARGE].singular;
GermanAbilityNames[AbilityNames.FRIGATE_DURATION] = GermanCardNames[CardNames.FRIGATE].singular;
GermanAbilityNames[AbilityNames.FRIGATE_DISCARD] = GermanCardNames[CardNames.FRIGATE].singular;
GermanAbilityNames[AbilityNames.LONGSHIP_DURATION] = GermanCardNames[CardNames.LONGSHIP].singular;
GermanAbilityNames[AbilityNames.MINING_ROAD] = GermanCardNames[CardNames.MINING_ROAD].singular;
GermanAbilityNames[AbilityNames.QUARTERMASTER] = GermanCardNames[CardNames.QUARTERMASTER].singular;
GermanAbilityNames[AbilityNames.TRICKSTER_REACTION] = GermanCardNames[CardNames.TRICKSTER].singular;
GermanAbilityNames[AbilityNames.TRICKSTER_RETURN_TO_HAND] = GermanCardNames[CardNames.TRICKSTER].singular;
GermanAbilityNames[AbilityNames.TRICKSTER_ASK_FOR_DISCARD] = GermanCardNames[CardNames.TRICKSTER].singular;
GermanAbilityNames[AbilityNames.WEALTHY_VILLAGE] = GermanCardNames[CardNames.WEALTHY_VILLAGE].singular;
GermanAbilityNames[AbilityNames.DELIVER_SET_ASIDE] = GermanCardNames[CardNames.DELIVER].singular;
GermanAbilityNames[AbilityNames.DELIVER_RETURN] = GermanCardNames[CardNames.DELIVER].singular;
GermanAbilityNames[AbilityNames.RUSH] = GermanCardNames[CardNames.RUSH].singular;
GermanAbilityNames[AbilityNames.MIRROR] = GermanCardNames[CardNames.MIRROR].singular;
GermanAbilityNames[AbilityNames.PREPARE] = GermanCardNames[CardNames.PREPARE].singular;
GermanAbilityNames[AbilityNames.AMPHORA] = GermanCardNames[CardNames.AMPHORA].singular;
GermanAbilityNames[AbilityNames.DOUBLOONS] = GermanCardNames[CardNames.DOUBLOONS].singular;
GermanAbilityNames[AbilityNames.ENDLESS_CHALICE] = GermanCardNames[CardNames.ENDLESS_CHALICE].singular;
GermanAbilityNames[AbilityNames.FIGUREHEAD] = GermanCardNames[CardNames.FIGUREHEAD].singular;
GermanAbilityNames[AbilityNames.JEWELS] = GermanCardNames[CardNames.JEWELS].singular;
GermanAbilityNames[AbilityNames.PUZZLE_BOX_RETURN] = GermanCardNames[CardNames.PUZZLE_BOX].singular;
GermanAbilityNames[AbilityNames.SHIELD_REACTION] = GermanCardNames[CardNames.SHIELD].singular;
GermanAbilityNames[AbilityNames.SPELL_SCROLL] = GermanCardNames[CardNames.SPELL_SCROLL].singular;
GermanAbilityNames[AbilityNames.CURSED_GAIN] = GermanCardNames[CardNames.CURSED].singular;
GermanAbilityNames[AbilityNames.FAWNING_GAIN] = GermanCardNames[CardNames.FAWNING].singular;
GermanAbilityNames[AbilityNames.SCHEDULER] = GermanCardNames[CardNames.FRIENDLY].singular;
GermanAbilityNames[AbilityNames.FRIENDLY_ABILITY] = GermanCardNames[CardNames.FRIENDLY].singular;
GermanAbilityNames[AbilityNames.HASTY_SET_ASIDE] = GermanCardNames[CardNames.HASTY].singular;
GermanAbilityNames[AbilityNames.HASTY_PLAY] = GermanCardNames[CardNames.HASTY].singular;
GermanAbilityNames[AbilityNames.INHERITED] = GermanCardNames[CardNames.INHERITED].singular;
GermanAbilityNames[AbilityNames.INSPIRING] = GermanCardNames[CardNames.INSPIRING].singular;
GermanAbilityNames[AbilityNames.NEARBY] = GermanCardNames[CardNames.NEARBY].singular;
GermanAbilityNames[AbilityNames.PATIENT_SCHEDULER] = GermanCardNames[CardNames.PATIENT].singular;
GermanAbilityNames[AbilityNames.PATIENT_SET_ASIDE] = GermanCardNames[CardNames.PATIENT].singular;
GermanAbilityNames[AbilityNames.PATIENT_PLAY] = GermanCardNames[CardNames.PATIENT].singular;
GermanAbilityNames[AbilityNames.PIOUS] = GermanCardNames[CardNames.PIOUS].singular;
GermanAbilityNames[AbilityNames.RECKLESS_REPEAT] = GermanCardNames[CardNames.RECKLESS].singular;
GermanAbilityNames[AbilityNames.RECKLESS_RETURN] = GermanCardNames[CardNames.RECKLESS].singular;
GermanAbilityNames[AbilityNames.RICH_GAIN] = GermanCardNames[CardNames.RICH].singular;
GermanAbilityNames[AbilityNames.SHY] = GermanCardNames[CardNames.SHY].singular;
GermanAbilityNames[AbilityNames.TIRELESS_SET_ASIDE] = GermanCardNames[CardNames.TIRELESS].singular;
GermanAbilityNames[AbilityNames.TIRELESS_RETURN] = GermanCardNames[CardNames.TIRELESS].singular;
GermanAbilityNames[AbilityNames.FATED] = GermanCardNames[CardNames.FATED].singular;
GermanAbilityNames[AbilityNames.AVOID] = GermanCardNames[CardNames.AVOID].singular;
GermanAbilityNames[AbilityNames.MARCHLAND] = GermanCardNames[CardNames.MARCHLAND].singular;
GermanAbilityNames[AbilityNames.FARMHANDS_SET_ASIDE] = GermanCardNames[CardNames.FARMHANDS].singular;
GermanAbilityNames[AbilityNames.FARMHANDS_PLAY] = GermanCardNames[CardNames.FARMHANDS].singular;
GermanAbilityNames[AbilityNames.JOUST_DISCARD] = GermanCardNames[CardNames.JOUST].singular;
GermanAbilityNames[AbilityNames.INFIRMARY_OVERPAY] = GermanCardNames[CardNames.INFIRMARY].singular;
GermanAbilityNames[AbilityNames.RESOLVE_INFIRMARY_OVERPAY] = GermanCardNames[CardNames.INFIRMARY].singular;
GermanAbilityNames[AbilityNames.DRAW_FROM_FOOTPAD] = GermanCardNames[CardNames.FOOTPAD].singular;
GermanAbilityNames[AbilityNames.FARRIER_OVERPAY] = GermanCardNames[CardNames.FARRIER].singular;
GermanAbilityNames[AbilityNames.RESOLVE_FARRIER_OVERPAY] = GermanCardNames[CardNames.FARRIER].singular;
GermanAbilityNames[AbilityNames.DRAW_FROM_SQUIRREL] = GermanCardNames[CardNames.WAY_OF_THE_SQUIRREL].singular;
GermanAbilityNames[AbilityNames.DRAW_FROM_RIVER] = GermanCardNames[CardNames.THE_RIVERS_GIFT].singular;
GermanAbilityNames[AbilityNames.DRAW_FROM_FARRIER] = GermanCardNames[CardNames.FARRIER].singular;
GermanAbilityNames[AbilityNames.FERRYMAN] = GermanCardNames[CardNames.FERRYMAN].singular;

//Rising Sun
GermanAbilityNames[AbilityNames.BIDING_TIME_SET_ASIDE_HAND] = GermanCardNames[CardNames.BIDING_TIME].singular;
GermanAbilityNames[AbilityNames.BIDING_TIME_RETURN_TO_HAND] = GermanCardNames[CardNames.BIDING_TIME].singular;
GermanAbilityNames[AbilityNames.ENLIGHTENMENT_CANTRIP] = GermanCardNames[CardNames.ENLIGHTENMENT].singular;
GermanAbilityNames[AbilityNames.ENLIGHTENMENT_REPLACE_ABILITY] = GermanCardNames[CardNames.ENLIGHTENMENT].singular;
GermanAbilityNames[AbilityNames.GOOD_HARVEST] = GermanCardNames[CardNames.GOOD_HARVEST].singular;
GermanAbilityNames[AbilityNames.HARSH_WINTER] = GermanCardNames[CardNames.HARSH_WINTER].singular;
GermanAbilityNames[AbilityNames.KIND_EMPEROR] = GermanCardNames[CardNames.KIND_EMPEROR].singular;
GermanAbilityNames[AbilityNames.PANIC_BUYS] = GermanCardNames[CardNames.PANIC].singular;
GermanAbilityNames[AbilityNames.PANIC_RETURN] = GermanCardNames[CardNames.PANIC].singular;
GermanAbilityNames[AbilityNames.PROGRESS] = GermanCardNames[CardNames.PROGRESS].singular;
GermanAbilityNames[AbilityNames.RAPID_EXPANSION_SET_ASIDE] = GermanCardNames[CardNames.RAPID_EXPANSION].singular;
GermanAbilityNames[AbilityNames.RAPID_EXPANSION_PLAY_LATER] = GermanCardNames[CardNames.RAPID_EXPANSION].singular;
GermanAbilityNames[AbilityNames.SICKNESS] = GermanCardNames[CardNames.SICKNESS].singular;
GermanAbilityNames[AbilityNames.RIVER_SHRINE] = GermanCardNames[CardNames.RIVER_SHRINE].singular;
GermanAbilityNames[AbilityNames.SAMURAI] = GermanCardNames[CardNames.SAMURAI].singular;
GermanAbilityNames[AbilityNames.DAIMYO_REPLAY] = GermanCardNames[CardNames.DAIMYO].singular;
GermanAbilityNames[AbilityNames.FORESIGHT_RETURN_TO_HAND] = GermanCardNames[CardNames.FORESIGHT].singular;