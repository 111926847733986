"use strict";

(function () {

    var originalTitle = document.title;
    var timeout = void 0;

    window.flashTitle = function (newMsg) {
        var howManyTimes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Math.pow(2, 31);

        function step() {
            document.title = document.title === originalTitle ? newMsg : originalTitle;

            if (--howManyTimes > 0) {
                timeout = setTimeout(step, 1000);
            }
        }

        howManyTimes = parseInt(howManyTimes);

        if (isNaN(howManyTimes)) {
            howManyTimes = 5;
        }

        cancelFlashTitle(timeout);
        step();
    };

    window.cancelFlashTitle = function () {
        clearTimeout(timeout);
        document.title = originalTitle;
    };
})();