"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function FailureReason() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var FailureReasons = {
    WRONG_PASSWORD: new FailureReason(),
    NOT_ENOUGH_PLAYERS: new FailureReason(),
    TOO_MANY_PLAYERS: new FailureReason(),
    NEW_HOST_NOT_FOUND: new FailureReason(),
    ALREADY_JOINED_A_TABLE: new FailureReason(),
    NOT_ALLOWED_TO_JOIN: new FailureReason(),
    BAD_CLIENT_VERSION: new FailureReason(),
    UNKNOWN_RULE_INDEX: new FailureReason(),
    RULE_NOT_REMOVABLE: new FailureReason(),
    INVITEE_NOT_LOGGED_IN: new FailureReason(),
    NOT_ALLOWED_TO_PLAY: new FailureReason(),
    TABLE_CHAT_REQUIRES_TABLE: new FailureReason(),
    SPECTATOR_CHAT_REQUIRES_TABLE: new FailureReason(),
    LOGIN_REQUIRED: new FailureReason(),
    COMMAND_REQUIRES_TABLE: new FailureReason(),
    COMMAND_REQUIRES_HOST: new FailureReason(),
    COMMAND_REQUIRES_PLAYER: new FailureReason(),
    RULE_CONFLICTS: new FailureReason(),
    NO_GAMESERVER_AVAILABLE: new FailureReason(),
    SELECTED_CARD_NOT_OWNED: new FailureReason(),
    SELECTED_CARD_NOT_KINGDOM_CARD: new FailureReason(),
    SELECTED_TOO_MANY_EVENTS_OR_LANDMARKS: new FailureReason(),
    THIS_IS_NOT_YOUR_TABLE: new FailureReason(),
    ALREADY_LOGGED_IN: new FailureReason(),
    CANNOT_RECONNECT_WHEN_STILL_CONNECTED: new FailureReason(),
    KICK_FAILED: new FailureReason(),
    ALREADY_STARTING: new FailureReason(),
    ALREADY_STARTED: new FailureReason(),
    NOTHING_TO_KICK: new FailureReason(),
    INTERNAL_ERROR: new FailureReason(),
    ILLEGAL_PROPERTY_CHANGE: new FailureReason(),
    CODE_ALREADY_USED: new FailureReason(),
    FAILED_CONFIRMING_EMAIL: new FailureReason(),
    FAILED_RETRIEVING_USER: new FailureReason(),
    EMAIL_NOT_CONFIRMED_YET: new FailureReason(),
    FAILURE_SENDING_EMAIL: new FailureReason(),
    CONFIRM_EMAIL_FIRST: new FailureReason(),
    OLD_PASSWORD_INCORRECT: new FailureReason(),
    INCORRECT_PRICE: new FailureReason(),
    PURCHASE_IN_FUTURE: new FailureReason(),
    PURCHASE_TOO_OLD: new FailureReason(),
    FAILED_SETTING_UP_PURCHASE: new FailureReason(),
    FAILED_STORING_PURCHASE: new FailureReason(),
    COUNTRY_MISMATCH: new FailureReason(),
    DUPLICATE_USERNAME: new FailureReason(),
    INVALID_USERNAME: new FailureReason(),
    INVALID_PASSWORD: new FailureReason(),
    INVALID_GENDER: new FailureReason(),
    PURCHASE_TOO_SHORT: new FailureReason(),
    PURCHASE_TOO_LONG: new FailureReason(),
    METASERVER_INACTIVE: new FailureReason(),
    NO_SUCH_TABLE: new FailureReason(),
    NO_SUCH_USER: new FailureReason(),
    NO_SUCH_CODE: new FailureReason(),
    RESERVED_USERNAME: new FailureReason(),
    PRICE_IS_ZERO: new FailureReason(),
    NO_SUCH_USERNAME: new FailureReason(),
    NO_SUCH_GAMELOG: new FailureReason(),
    ILLEGAL_PLAYER_ORDER: new FailureReason(),
    INVALID_SESSION_ID: new FailureReason(),
    ACTION_CODE_EXPIRED: new FailureReason(),
    BOT_GAMES_CANT_BE_RATED: new FailureReason(),
    TOO_MANY_PLAYERS_FOR_RATED_GAME: new FailureReason(),
    ALREADY_ON_BLACKLIST: new FailureReason(),
    NOT_ON_BLACKLIST: new FailureReason(),
    ALREADY_ON_FRIENDLIST: new FailureReason(),
    NOT_ON_FRIENDLIST: new FailureReason(),
    SEARCHING_FOR_MYSELF: new FailureReason(),
    SEARCHED_FOR_BOT: new FailureReason(),
    USER_IS_BANNED: new FailureReason(),
    WRONG_BONUS_CODE_TYPE: new FailureReason(),
    ILLEGAL_BONUS_CODE: new FailureReason(),
    STORE_SERVICE_DOWN: new FailureReason(),
    RATING_SERVICE_DOWN: new FailureReason(),
    NOT_SUBSCRIBED_TO: new FailureReason(),
    KINGDOM_HAS_TOO_MANY_CARDS: new FailureReason(),
    NO_CARDS_AVAILABLE_TO_FILL_KINGDOM: new FailureReason(),
    FAILED_TO_SELECT_BANE_CARD: new FailureReason(),
    FAILED_TO_SELECT_MOUSE_CARD: new FailureReason(),
    FAILED_TO_SELECT_FERRYMAN_CARD: new FailureReason(),
    FAILED_TO_SELECT_PREVIEW_CARDS: new FailureReason(),
    FAILED_TO_SELECT_ALLY: new FailureReason(),
    FAILED_TO_SELECT_LIASON: new FailureReason(),
    REQUIRING_RANDOM_CARD_FROM_DISABLED_EXPANSION: new FailureReason(),
    NO_RECONNECT_AVAILABLE: new FailureReason(),
    COMMAND_REQUIRES_GAME: new FailureReason(),
    ILLEGAL_ANSWER: new FailureReason(),
    ILLEGAL_REQUEST: new FailureReason(),
    NO_SUCH_UNDO: new FailureReason(),
    SERVER_DOESNT_ALLOW_NEW_GAMES: new FailureReason(),
    SERVER_IS_DOWN: new FailureReason(),
    OTHER_SERVER_IS_UNAVAILABLE: new FailureReason(),
    ALREADY_IN_GAME: new FailureReason(),
    /* not coming from server*/
    GAME_ID_SHOULD_BE_NUMBER: new FailureReason()

};

function SuccessDescription() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var SuccessDescriptions = {
    EMAIL_SENT_FORGOT_PASSWORD: new SuccessDescription(),
    EMAIL_SENT_CONFIRM_EMAIL: new SuccessDescription(),
    PASSWORD_CHANGED: new SuccessDescription(),
    KICK_SUCCESS: new SuccessDescription(),
    EMAIL_CONFIRMED: new SuccessDescription(),
    REFUND_SUCCESS: new SuccessDescription(),
    REMOVED_FROM_AUTOMATCH: new SuccessDescription(),
    ACTION_CODE_USED: new SuccessDescription(),
    SUBSCRIPTION_UPDATED: new SuccessDescription(),
    SUCCESSFULLY_CONNECTED_NEW_SERVER: new SuccessDescription(),
    SUCCESSFULLY_CONNECTED_OLD_SERVER: new SuccessDescription()
};

function ActivityStatus() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var ActivityStati = {
    LOGGED_IN: new ActivityStatus(),
    PLAYING: new ActivityStatus(),
    SPECTATING: new ActivityStatus(),
    AT_TABLE_PLAYER: new ActivityStatus(),
    AT_TABLE_SPECTATOR: new ActivityStatus(),
    DISCONNECTED: new ActivityStatus()
};

function TableRuleId() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var TableRuleIds = {
    PLAYERS_CAN_SEE_SPECTATOR_CHAT: new TableRuleId(),
    BANNED_CARDS: new TableRuleId(),
    REQUIRED_CARDS: new TableRuleId(),
    USED_EXPANSIONS: new TableRuleId(),
    RESTRICTIVE_PLAYER_RULES: new TableRuleId(),
    SPECTATE_RULES: new TableRuleId(),
    MIN_RATING: new TableRuleId(),
    MAX_RATING: new TableRuleId(),
    RANDOM_SEED: new TableRuleId(),
    MIN_PLAYERS: new TableRuleId(),
    MAX_PLAYERS: new TableRuleId(),
    SPECIAL_KINGDOM_RULES: new TableRuleId(),
    RESPECTED_CARD_LISTS: new TableRuleId(),
    SHOW_VP_COUNTER: new TableRuleId(),
    PLAYER_ORDER: new TableRuleId(),
    UNDO_SETTINGS: new TableRuleId(),
    ALWAYS_ALLOWED_PLAYER_RULES: new TableRuleId(),
    REPLAY_INSTRUCTIONS: new TableRuleId(),
    RATED_GAME: new TableRuleId(),
    PREVIEW_GAME: new TableRuleId(),
    SCRIPTED_RULES: new TableRuleId(),
    CARD_POOL_LEVEL: new TableRuleId()
};

function ClientToMetaId() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var ClientToServerIds = {
    SEND_CHAT: new ClientToMetaId(),
    LOGIN: new ClientToMetaId(),
    JOIN_TABLE: new ClientToMetaId(),
    SEND_INVITE: new ClientToMetaId(),
    CHANGE_TABLE_RULE: new ClientToMetaId(),
    CHANGE_HOST: new ClientToMetaId(),
    LEAVE_TABLE: new ClientToMetaId(),
    SIT_DOWN: new ClientToMetaId(),
    STAND_UP: new ClientToMetaId(),
    NEW_TABLE_REQUEST: new ClientToMetaId(),
    READY_TO_START: new ClientToMetaId(),
    REQUEST_UPDATE: new ClientToMetaId(),
    CHANGE_USER_PROPERTY: new ClientToMetaId(),
    LOGIN_WITH_CODE: new ClientToMetaId(),
    LOGIN_WITH_NEW_PASSWORD: new ClientToMetaId(),
    FORGOT_PASSWORD: new ClientToMetaId(),
    CHANGE_PASSWORD: new ClientToMetaId(),
    REQUEST_PURCHASE_PRICE: new ClientToMetaId(),
    PURCHASE: new ClientToMetaId(),
    CREATE_ACCOUNT: new ClientToMetaId(),
    REMOVE_SESSIONS: new ClientToMetaId(),
    CHANGE_FRIEND_STATUS: new ClientToMetaId(),
    REQUEST_AUTOMATCH: new ClientToMetaId(),
    ADD_BOT: new ClientToMetaId(),
    LOGIN_WITH_SESSION: new ClientToMetaId(),
    CHANGE_MAIL_STATUS: new ClientToMetaId(),
    REQUEST_MAIL_MESSAGES: new ClientToMetaId(),
    REQUEST_MAIL_SUMMARIES: new ClientToMetaId(),
    REQUEST_LEADERBOARD: new ClientToMetaId(),
    TERMINATE_GAME: new ClientToMetaId(),
    CHANGE_BLACKLIST_STATUS: new ClientToMetaId(),
    CONTINUE_WITH_BOTS: new ClientToMetaId(),
    LOGOUT: new ClientToMetaId(),
    VALIDATE_BONUS_CODE: new ClientToMetaId(),
    USE_BONUS_CODE: new ClientToMetaId(),
    RECONNECT_GAME: new ClientToMetaId(),
    REMOVE_ACTIVE_SESSIONS: new ClientToMetaId(),
    ANSWER_QUESTION: new ClientToMetaId(),
    RESIGN: new ClientToMetaId(),
    UNDO_REQUEST: new ClientToMetaId(),
    TIMEOUT_REQUEST: new ClientToMetaId(),
    UNDO_REQUEST_GRANTED: new ClientToMetaId(),
    UNDO_REQUEST_DENIED: new ClientToMetaId(),
    UNDO_REQUEST_CANCELLED: new ClientToMetaId(),
    PING: new ClientToMetaId(),
    REQUEST_SERVER_STATE: new ClientToMetaId(),
    RECONNECT_SESSION: new ClientToMetaId(),
    REQUEST_CONNECT_TO_OTHER_SERVER: new ClientToMetaId(),
    GET_TIMER: new ClientToMetaId()

};

function UserPrefType() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var UserPrefTypes = {
    STRING: new UserPrefType(),
    INTEGER: new UserPrefType(),
    OWNERSHIP: new UserPrefType(),
    CARD_LIST: new UserPrefType(),
    BOOLEAN: new UserPrefType(),
    MATCHING_CRITERIA: new UserPrefType(),
    AUTOPLAY_SETTINGS: new UserPrefType(),
    EXPANSION_LIST: new UserPrefType(),
    DELETED: new UserPrefType()
};

function UserPrefId(type) {
    var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var self = this;
    self.type = type;
    self.args = args;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var UserPrefIds = {
    CARD_OWNERSHIP: new UserPrefId(UserPrefTypes.OWNERSHIP),
    EMAIL: new UserPrefId(UserPrefTypes.STRING),
    PREFERRED_BACKGROUND: new UserPrefId(UserPrefTypes.INTEGER, { min: 0, max: 25 }),
    CARD_ANIMATIONS: new UserPrefId(UserPrefTypes.DELETED),
    WAIT_FOR_CARD_ANIMATIONS: new UserPrefId(UserPrefTypes.DELETED),
    MOVE_KINGDOM: new UserPrefId(UserPrefTypes.BOOLEAN),
    CONFIRM_ACTIONS: new UserPrefId(UserPrefTypes.BOOLEAN),
    FAMILIAR_CARDS: new UserPrefId(UserPrefTypes.DELETED),
    LIKED_CARDS: new UserPrefId(UserPrefTypes.CARD_LIST),
    DISLIKED_CARDS: new UserPrefId(UserPrefTypes.CARD_LIST),
    FOLLOWERS_SEE_ACTIVITY: new UserPrefId(UserPrefTypes.BOOLEAN),
    USE_AUTOBUY: new UserPrefId(UserPrefTypes.BOOLEAN),
    MAX_UNFAMILIAR_CARDS: new UserPrefId(UserPrefTypes.DELETED),
    AUTO_KICK_ON_LOGIN: new UserPrefId(UserPrefTypes.BOOLEAN),
    CLIENT_LANGUAGE: new UserPrefId(UserPrefTypes.INTEGER, { min: 0, max: 1 }),
    NOTIFICATION_SOUNDS: new UserPrefId(UserPrefTypes.BOOLEAN),
    SOUND_FX: new UserPrefId(UserPrefTypes.BOOLEAN),
    MATCHING_CRITERIA: new UserPrefId(UserPrefTypes.MATCHING_CRITERIA),
    SPECTATORS_SEE_HAND: new UserPrefId(UserPrefTypes.BOOLEAN),
    AUTOPLAY_SETTINGS: new UserPrefId(UserPrefTypes.AUTOPLAY_SETTINGS),
    LOG_FIXED_BASIC_BONUS: new UserPrefId(UserPrefTypes.BOOLEAN),
    MAKE_THRONE_ROOM_ORANGE: new UserPrefId(UserPrefTypes.BOOLEAN),
    MAKE_DURATIONS_NOT_ORANGE: new UserPrefId(UserPrefTypes.BOOLEAN),
    BANNED_CARDS: new UserPrefId(UserPrefTypes.CARD_LIST),
    TRANSPARENT_WAY_BUTTON: new UserPrefId(UserPrefTypes.BOOLEAN),
    CHAT_VISIBLE: new UserPrefId(UserPrefTypes.BOOLEAN),
    SPECTATOR_CHAT_IN_AUTOMATCH: new UserPrefId(UserPrefTypes.BOOLEAN),
    ENABLED_EXPANSIONS: new UserPrefId(UserPrefTypes.DELETED),
    ADVANCED_AUTOMATCH: new UserPrefId(UserPrefTypes.DELETED),
    CARD_POOL_LEVEL: new UserPrefId(UserPrefTypes.INTEGER),
    ANIMATION_TYPE: new UserPrefId(UserPrefTypes.INTEGER),
    CHAT_PANEL: new UserPrefId(UserPrefTypes.DELETED),
    LOG_PANEL: new UserPrefId(UserPrefTypes.DELETED),
    PREMOVES: new UserPrefId(UserPrefTypes.BOOLEAN),
    MIN_CARD_POOL_LEVEL: new UserPrefId(UserPrefTypes.INTEGER),
    SHOW_REWORDING_2022_WARNING: new UserPrefId(UserPrefTypes.BOOLEAN),
    CHAT_PANEL_WEIGHT: new UserPrefId(UserPrefTypes.INTEGER),
    LOG_PANEL_WEIGHT: new UserPrefId(UserPrefTypes.INTEGER)
};

function TableStatus() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var TableStati = {
    NEW: new TableStatus(),
    POST_GAME: new TableStatus(),
    RUNNING: new TableStatus(),
    ABANDONED: new TableStatus(),
    TRANSFERRED: new TableStatus()
};

function WebsocketCloseEvent(code, description) {
    var self = this;
    self.code = code;
    self.description = description;
    self.toString = function () {
        return self.description;
    };
}

var WebsocketCloseEvents = {
    CLOSE_NORMAL: new WebsocketCloseEvent(1000, "CLOSE_NORMAL"),
    CLOSE_GOING_AWAY: new WebsocketCloseEvent(1001, "CLOSE_GOING_AWAY"),
    CLOSE_PROTOCOL_ERROR: new WebsocketCloseEvent(1002, "CLOSE_PROTOCOL_ERROR"),
    CLOSE_UNSUPPORTED: new WebsocketCloseEvent(1003, "CLOSE_UNSUPPORTED"),
    CLOSE_NO_STATUS: new WebsocketCloseEvent(1005, "CLOSE_NO_STATUS"),
    CLOSE_ABNORMAL: new WebsocketCloseEvent(1006, "CLOSE_ABNORMAL"),
    UNSUPPORTED_DATA: new WebsocketCloseEvent(1007, "UNSUPPORTED_DATA"),
    POLICY_VIOLATION: new WebsocketCloseEvent(1008, "POLICY_VIOLATION"),
    CLOSE_TOO_LARGE: new WebsocketCloseEvent(1009, "CLOSE_TOO_LARGE"),
    MISSING_EXTENSION: new WebsocketCloseEvent(1010, "MISSING_EXTENSION"),
    INTERNAL_ERROR: new WebsocketCloseEvent(1011, "INTERNAL_ERROR"),
    SERVICE_RESTART: new WebsocketCloseEvent(1012, "SERVICE_RESTART"),
    TRY_AGAIN_LATER: new WebsocketCloseEvent(1013, "TRY_AGAIN_LATER"),
    TLS_HANDSHAKE: new WebsocketCloseEvent(1015, "TLS_HANDSHAKE")
};

function Ternary(label) {
    var self = this;
    self.label = label;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var TernaryField = {
    NO: new Ternary("No"),
    YES: new Ternary("Yes"),
    RANDOM: new Ternary("Random")
};

function PriceCategory() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var PriceCategories = {
    PURCHASED: new PriceCategory(),
    ALREADY_OWNED: new PriceCategory(),
    DISCOUNT: new PriceCategory(),
    TRANSACTION: new PriceCategory(),
    SUBTOTAL: new PriceCategory(),
    VAT: new PriceCategory(),
    TOTAL: new PriceCategory()
};

function GroupId(parseFunction) {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
    self.parse = function (reader) {
        return parseFunction(reader);
    };
}

var GroupIds = {
    NOBODY: new GroupId(Nobody.parse),
    EVERYBODY: new GroupId(Everybody.parse),
    FRIENDS_OF: new GroupId(FriendsOf.parse),
    LIST_PLAYER_IDS: new GroupId(ListPlayerIds.parse)
};

function UpdateType() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var UpdateTypes = {
    NONE: new UpdateType(),
    AUTOMATCH: new UpdateType(),
    FRIEND_LIST: new UpdateType(),
    TABLES: new UpdateType()
};

function AutomatchType() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var AutomatchTypes = {
    NONE: new AutomatchType(),
    QUICK_MATCH: new AutomatchType(),
    GOOD_MATCH: new AutomatchType(),
    MATCH_WITH_EXPANSIONS: new AutomatchType(),
    MATCH_WITH_BOT: new AutomatchType(),
    MATCH_UNRATED: new AutomatchType()
};

function UndoSetting() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var UndoSettings = {
    NEVER: new UndoSetting(),
    ASK: new UndoSetting(),
    ALWAYS: new UndoSetting()
    // ONLY_WITHOUT_NEW_INFO: new UndoSetting(),
    // ASK_WITH_NEW_INFO: new UndoSetting(),
};

function MailStatus() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var MailStati = {
    UNREAD: new MailStatus(),
    READ: new MailStatus(),
    DELETED: new MailStatus()
};

function Direction() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var Directions = {
    FROM: new Direction(),
    TO: new Direction()
};

function RelationStatus() {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
}

var RelationStati = {
    FRIEND: new RelationStatus(),
    FOLLOWING: new RelationStatus(),
    FOLLOWER: new RelationStatus(),
    BLACKLISTED: new RelationStatus()
};

var AUTOMATCH_QUESTION = {
    PREVIEW_GAME: new AutomatchQuestion(0, true),
    NUMBER_OF_PLAYERS: new AutomatchQuestion(1, false, true),
    RATED_GAME: new AutomatchQuestion(2, false),
    NEW_EXPANSION_GAME: new AutomatchQuestion(3, true)
};

var MATCHING_PREFERENCE = {
    REJECT: 0,
    EITHER: 1,
    REQUIRE: 2
};

function SetupRuleId(type) {
    var self = this;
    self.uuid = generateUUID();
    self.toString = function () {
        return self.uuid;
    };
    self.type = type;
}

var SetupRuleType = {
    CARD: 0,
    CARD_LIST: 1
};

var SetupRuleIds = {
    ALLIES: new SetupRuleId(SetupRuleType.CARD),
    BLACK_MARKET: new SetupRuleId(SetupRuleType.CARD_LIST),
    DRUID: new SetupRuleId(SetupRuleType.CARD_LIST),
    MOUSE: new SetupRuleId(SetupRuleType.CARD),
    KNIGHTS: new SetupRuleId(SetupRuleType.CARD_LIST),
    RUINS: new SetupRuleId(SetupRuleType.CARD_LIST),
    OBELISK: new SetupRuleId(SetupRuleType.CARD),
    YOUNG_WITCH: new SetupRuleId(SetupRuleType.CARD),
    FERRYMAN: new SetupRuleId(SetupRuleType.CARD),
    PROPHECIES: new SetupRuleId(SetupRuleType.CARD),
    DIVINE_WIND: new SetupRuleId(SetupRuleType.CARD_LIST),
    APPROACHING_ARMY: new SetupRuleId(SetupRuleType.CARD),
    RIVERBOAT: new SetupRuleId(SetupRuleType.CARD)
};

var MetagameQuestionId = function () {
    function MetagameQuestionId() {
        _classCallCheck(this, MetagameQuestionId);

        this.uuid = generateUUID();
    }

    _createClass(MetagameQuestionId, [{
        key: "toString",
        value: function toString() {
            return this.uuid;
        }
    }]);

    return MetagameQuestionId;
}();

var MetagameQuestionIds = {
    UNDO_REQUEST: new MetagameQuestionId(),
    TIMEOUT_OFFER: new MetagameQuestionId(),
    UNDO_REQUEST_DENIED: new MetagameQuestionId(),
    UNDO_REQUEST_CANCELLED: new MetagameQuestionId()
};

var CardProperty = function () {
    function CardProperty() {
        _classCallCheck(this, CardProperty);

        this.uuid = generateUUID();
    }

    _createClass(CardProperty, [{
        key: "toString",
        value: function toString() {
            return this.uuid;
        }
    }]);

    return CardProperty;
}();

var CardProperties = {
    BANE: new CardProperty(),
    FACE_DOWN: new CardProperty(),
    INVESTED: new CardProperty()
};