"use strict";

var JapaneseQuestionDescriptions = {};

JapaneseQuestionDescriptions[QuestionIds.GAME_RESOLVE_ABILITY] = ["強制効果の解決:", "効果を解決"];
JapaneseQuestionDescriptions[QuestionIds.GAME_MAY_RESOLVE_ABILITY] = ["任意効果の解決:", "効果を解決可能"];
JapaneseQuestionDescriptions[QuestionIds.GAME_MAY_REACT_WITH] = ["リアクション:", "リアクション可能"];
JapaneseQuestionDescriptions[QuestionIds.GAME_TURN_SELECT] = ["次のターンを選択してください。", "次のターンを選択"];
JapaneseQuestionDescriptions[QuestionIds.GAME_MAY_BUY] = ["カードを買う。", "カードを購入可能"];
JapaneseQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING] = ["カードを購入開始する。", "カードを購入可能"];
JapaneseQuestionDescriptions[QuestionIds.GAME_MAY_BUY_NONCARDS] = ["イベント等を買う。", "イベント等を購入可能"];
JapaneseQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING_NONCARDS] = ["イベント等を購入開始する。", "イベント等を購入可能"];
JapaneseQuestionDescriptions[QuestionIds.GAME_REPAY_HOW_MUCH] = ["負債を返す。", "負債を返済可能"];
JapaneseQuestionDescriptions[QuestionIds.GAME_SPEND_COIN_TOKENS] = ["財源を支払う。", "財源を支払い可能"];
JapaneseQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_ACTION] = ["手札のアクションカードを使用する。", "アクションカードを使用可能"];
JapaneseQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_TREASURES] = ["財宝カードを使用する。", "財宝カードを使用可能"];
JapaneseQuestionDescriptions[QuestionIds.GAME_PLAY_ALL_TREASURES] = ["財宝カードをすべて使用する。", "財宝カードすべての使用"];
JapaneseQuestionDescriptions[QuestionIds.GAME_ACTION_PHASE] = ["アクションフェイズです。", "アクションカードを使用可能"];
JapaneseQuestionDescriptions[QuestionIds.GAME_BUY_PHASE] = ["購入フェイズです。", "カードを購入可能"];
JapaneseQuestionDescriptions[QuestionIds.GAME_BUY_PHASE_NONCARDS] = ["購入フェイズです。", "イベントを購入可能"];
JapaneseQuestionDescriptions[QuestionIds.GAME_CLEANUP_PHASE] = ["クリーンアップの効果解決順を選んでください。", "効果解決順を選択"];
JapaneseQuestionDescriptions[QuestionIds.GAME_USE_VILLAGER] = ["村人トークンを使用する。", "村人トークンを使用可能"];
JapaneseQuestionDescriptions[QuestionIds.GAME_USE_COFFERS] = ["財源を使ってもよい。", "財源を使ってもよい"];
JapaneseQuestionDescriptions[QuestionIds.START_GAME] = ["初期デッキを確認する。", "初期デッキ: "];

JapaneseQuestionDescriptions[QuestionIds.SHORT_YOU_MAY_REACT] = ["-", "リアクション可能"];
JapaneseQuestionDescriptions[QuestionIds.SHORT_WHEN_GAIN] = ["-", "Argument0の獲得時効果の処理"];
JapaneseQuestionDescriptions[QuestionIds.SHORT_WHEN_WOULD_GAIN] = ["-", "Argument0獲得前の割り込みの処理"];
JapaneseQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL_SPECIFIC] = ["-", "Argument0の呼び出し・カードの使用可能"];
JapaneseQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL] = ["-", "カードの呼び出し・使用可能"];
JapaneseQuestionDescriptions[QuestionIds.SHORT_PLAY] = ["-", "アクションカードを使用可能"];
JapaneseQuestionDescriptions[QuestionIds.SHORT_CALL_SPECIFIC] = ["-", "Argument0を呼び出し可能"];
JapaneseQuestionDescriptions[QuestionIds.SHORT_CALL] = ["-", "カードを呼び出し可能"];

JapaneseQuestionDescriptions[QuestionIds.ARTISAN_GAIN] = ["コスト$5以下のカード1枚を手札に獲得する。", "カードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.ARTISAN_TOPDECK] = ["手札1枚を山札の上に置く。", "山札の上に置く"];
JapaneseQuestionDescriptions[QuestionIds.BANDIT] = ["PLAYER0は財宝カードを廃棄する。", "PLAYER0は財宝カードを廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.BUREAUCRAT] = ["手札の勝利点カード1枚を公開し、山札の上に置く。", "勝利点カードを山札の上に置く"];
JapaneseQuestionDescriptions[QuestionIds.CELLAR] = ["好きな枚数のカードを捨て札にし、同じ枚数のカードを引く。", "捨て札にし、カードを引く"];
JapaneseQuestionDescriptions[QuestionIds.CHAPEL] = ["手札から4枚以下のカードを廃棄する。", "手札を4枚まで廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.HARBINGER_TOPDECK] = ["捨て札置き場の中の1枚を山札の上に置いてもよい。", "山札の上に置いてもよい"];
JapaneseQuestionDescriptions[QuestionIds.LIBRARY] = ["CARDS0を脇に置いて飛ばしてもよい。", "CARDS0をスキップしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.MILITIA] = ["手札が3枚になるように捨て札にする。", "手札が3枚になるように捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.MINE_TRASH] = ["手札の財宝カード1枚を廃棄してもよい。", "財宝カードを廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.MINE_GAIN] = ["コストAMOUNT0以下の財宝カード1枚を手札に獲得する。", "コストAMOUNT0以下の財宝カードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.MONEYLENDER] = ["+$3のために、銅貨を廃棄してもよい。", "銅貨を廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.POACHER] = ["Argument0を捨て札にする。", "Argument0を捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.REMODEL_TRASH] = ["手札1枚を廃棄する。", "手札1枚を廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.REMODEL_GAIN] = ["コストAMOUNT0以下のカード1枚を獲得する。", "コストAMOUNT0以下のカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.SENTRY_TRASH] = ["公開した中から好きな枚数を廃棄する。", "廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.SENTRY_DISCARD] = ["公開した中から好きな枚数を捨て札にする。", "捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.SENTRY_TOPDECK] = ["好きな順番で山札の上に戻す。", "好きな順番で山札の上に戻す"];
JapaneseQuestionDescriptions[QuestionIds.THRONE_ROOM] = ["手札のアクションカードを2回使用してもよい。", "アクションカードを2回使用してもよい"];
JapaneseQuestionDescriptions[QuestionIds.VASSAL] = ["CARDS0を捨て札から使用してもよい。", "CARDS0を使用してもよい"];
JapaneseQuestionDescriptions[QuestionIds.WORKSHOP] = ["コスト$4以下のカード1枚を獲得する。", "カードを獲得する"];

JapaneseQuestionDescriptions[QuestionIds.BARON] = ["+$4のために、屋敷1枚を捨て札にしてもよい。", "屋敷を捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.COURTIER_REVEAL] = ["手札1枚を公開する。", "カードを公開する"];
JapaneseQuestionDescriptions[QuestionIds.COURTYARD] = JapaneseQuestionDescriptions[QuestionIds.ARTISAN_TOPDECK];
JapaneseQuestionDescriptions[QuestionIds.DIPLOMAT] = ["3枚捨て札にする。", "3枚捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.IRONWORKS] = JapaneseQuestionDescriptions[QuestionIds.WORKSHOP];
JapaneseQuestionDescriptions[QuestionIds.LURKER_COMPLEX] = ["待ち伏せの使用で...", "待ち伏せの使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.LURKER_MAY_TRASH] = ["サプライのアクションカード1枚を廃棄してもよい。", "サプライから廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.LURKER_TRASH] = ["サプライのアクションカード1枚を廃棄する。", "サプライから廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.LURKER_MAY_GAIN] = ["廃棄置き場のアクションカード1枚を獲得してもよい。", "廃棄置き場から獲得してもよい"];
JapaneseQuestionDescriptions[QuestionIds.LURKER_GAIN] = ["廃棄置き場のアクションカード1枚を獲得する。", "廃棄置き場から獲得する"];
JapaneseQuestionDescriptions[QuestionIds.MASQUERADE_PASS] = ["PLAYER0に手札1枚を渡す。", "PLAYER0にカードを渡す"];
JapaneseQuestionDescriptions[QuestionIds.MASQUERADE_TRASH] = ["手札1枚を廃棄してもよい。", "廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.MILL] = ["+$2のために、2枚捨て札にしてもよい。", "捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.MINING_VILLAGE] = ["+$2のために、鉱山の村を廃棄してもよい。", "鉱山の村を廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.MINION] = ["+$2か、手札を作り直すか選ぶ。", "+$2か、手札を作り直すか選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.NOBLES] = ["+3カードを引くか、+2アクションか選ぶ。", "+3カードを引くか、+2アクションか選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.PATROL] = ["好きな順番で山札の上に戻す。", "好きな順番で山札の上に戻す"];
JapaneseQuestionDescriptions[QuestionIds.PAWN] = ["手先の効果を選ぶ。", "手先の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH] = ["手札1枚を廃棄する。", "カードを廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.REPLACE_GAIN] = JapaneseQuestionDescriptions[QuestionIds.REMODEL_GAIN];
JapaneseQuestionDescriptions[QuestionIds.SECRET_PASSAGE_TOPDECK] = ["手札1枚を山札にする。", "山札の上に置く"];
JapaneseQuestionDescriptions[QuestionIds.SECRET_PASSAGE_INSERT] = ["山札にさしこむカードを選ぶ。", "さしこむカードと位置を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.STEWARD_MODE] = ["+2カードを引くか、+$2か、手札2枚を廃棄するか選ぶ。", "執事の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.STEWARD_TRASH] = ["手札2枚を廃棄する。", "手札2枚を廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.SWINDLER] = ["PLAYER0が獲得するカード1枚を選ぶ。", "PLAYER0が獲得するカードを選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.TRADING_POST] = JapaneseQuestionDescriptions[QuestionIds.STEWARD_TRASH];
JapaneseQuestionDescriptions[QuestionIds.TORTURER_MAY_CURSE] = ["呪い1枚を獲得してもよい。", "呪いを獲得してもよい"];
JapaneseQuestionDescriptions[QuestionIds.TORTURER_MAY_DISCARD] = ["手札2枚を捨て札にしてもよい。", "手札2枚を捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.TORTURER_DISCARD_OR_CURSE] = ["呪い1枚を獲得するか、手札2枚を捨て札にする。", "手札2枚を捨て札にするか、呪いを獲得するか選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.UPGRADE_TRASH] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.UPGRADE_GAIN] = ["コストAMOUNT0のカード1枚を獲得する。", "コストAMOUNT0ちょうどのカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.WISHING_WELL] = ["山札の一番上のカードを予想する。", "カードを予想する"];

JapaneseQuestionDescriptions[QuestionIds.AMBASSADOR_REVEAL] = JapaneseQuestionDescriptions[QuestionIds.COURTIER_REVEAL];
JapaneseQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_ONE] = ["CARDS0をサプライに戻してもよい。", "CARDS0をサプライに戻してもよい"];
JapaneseQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_TWO] = ["CARDS0を2枚までサプライに戻す。", "CARDS0をサプライに戻してもよい"];
JapaneseQuestionDescriptions[QuestionIds.EMBARGO] = ["サプライの山に抑留トークン1枚を追加する。", "山を抑留する"];
JapaneseQuestionDescriptions[QuestionIds.EXPLORER] = ["属州1枚を公開してもよい。", "属州1枚を公開してもよい"];
JapaneseQuestionDescriptions[QuestionIds.GHOST_SHIP] = ["NUMBER0枚のカードを山札の上に置く。", "NUMBER0枚のカードを山札の上に置く"];
JapaneseQuestionDescriptions[QuestionIds.HAVEN] = ["手札1枚を脇に置く。", "手札1枚を脇に置く"];
JapaneseQuestionDescriptions[QuestionIds.ISLAND] = ["手札1枚を島マットに置く。", "手札1枚をマットに置く"];
JapaneseQuestionDescriptions[QuestionIds.LOOKOUT_TRASH] = ["1枚廃棄する。", "廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.LOOKOUT_DISCARD] = ["1枚捨て札にする。", "捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_TAKE] = ["CARDS0を手札に加えてもよい。", "マットからCARDS0を手札に加えてもよい"];
JapaneseQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_SET_ASIDE] = ["山札の一番上のカードを原住民の村マットに置いてもよい。", "マットの上に置く"];
JapaneseQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_BOTH] = ["山札の一番上のカードをマットに送るか、CARDS0を手札に加えるか選ぶ。", "原住民の村の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.NAVIGATOR] = ["5枚を好きな順番で山札の上に置くか、すべて捨て札にする。", "山札の上に戻すか捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.PEARL_DIVER] = ["CARDS0を山札の上に置いてもよい。", "CARDS0を山札の上か底に置く"];
JapaneseQuestionDescriptions[QuestionIds.PIRATE_SHIP_MODE] = ["+AMOUNT0か、攻撃するか選ぶ。", "+AMOUNT0か、攻撃するか選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.PIRATE_SHIP_TRASH] = ["廃棄させる財宝カードを選ぶ。", "廃棄させる財宝カードを選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.SALVAGER] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.SMUGGLERS_MAY_GAIN] = ["同じカード1枚を獲得してもよい。", "カードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.SMUGGLERS_GAIN] = ["同じカード1枚を獲得する。", "カードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.WAREHOUSE] = ["3枚捨て札にする。", "3枚捨て札にする"];

JapaneseQuestionDescriptions[QuestionIds.APOTHECARY] = JapaneseQuestionDescriptions[QuestionIds.SENTRY_TOPDECK];
JapaneseQuestionDescriptions[QuestionIds.APPRENTICE] = ["手札1枚を廃棄する。その$数だけカードを引く。ポーションコストがある場合、さらに2枚引く。", "廃棄し、カードを引く"];
JapaneseQuestionDescriptions[QuestionIds.GOLEM] = ["どちらのアクションカードを先に使用するか選ぶ。", "使用する順番を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.HERBALIST] = ["財宝1枚を山札の上に置いてもよい。", "財宝を山札の上に置いてもよい"];
JapaneseQuestionDescriptions[QuestionIds.HERBALISTS] = ["山札の上に置く財宝を選ぶ", "Argument0枚まで財宝を山札の上に置いてもよい"];
JapaneseQuestionDescriptions[QuestionIds.SCRYING_POOL_YOU] = ["あなたがCARDS0を捨て札にするか山札の上に戻すか選ぶ。", "CARDS0を捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.SCRYING_POOL_OTHER] = ["PLAYER1がCARDS0を捨て札にするか山札の上に戻すか選ぶ。", "PLAYER1がCARDS0を捨て札にするか山札の上に戻すか選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.TRANSMUTE] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.UNIVERSITY] = ["コスト$5以下のアクションカードを獲得してもよい。", "アクションカードを獲得してもよい"];

JapaneseQuestionDescriptions[QuestionIds.BISHOP_SELF] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.BISHOP_OTHER] = JapaneseQuestionDescriptions[QuestionIds.MASQUERADE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.CONTRABAND] = ["PLAYER0が購入できないカードを指定する。", "購入を禁じる"];
JapaneseQuestionDescriptions[QuestionIds.COUNTING_HOUSE] = ["何枚の銅貨を手札に加える?", "銅貨を手札に加える"];
JapaneseQuestionDescriptions[QuestionIds.EXPAND_TRASH] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.EXPAND_GAIN] = JapaneseQuestionDescriptions[QuestionIds.REMODEL_GAIN];
JapaneseQuestionDescriptions[QuestionIds.FORGE_TRASH] = ["手札から好きな枚数のカードを廃棄する。", "カードを廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.FORGE_GAIN] = ["コストAMOUNT0のカード1枚を獲得する。", "コストAMOUNT0のカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.GOONS] = JapaneseQuestionDescriptions[QuestionIds.MILITIA];
JapaneseQuestionDescriptions[QuestionIds.KINGS_COURT] = ["手札のアクションカード1枚を3回使用してもよい。", "アクションカードを3回使用してもよい"];
JapaneseQuestionDescriptions[QuestionIds.LOAN] = ["CARDS0を捨て札にするか廃棄する。", "CARDS0を捨て札にするか廃棄するか選択"];
JapaneseQuestionDescriptions[QuestionIds.MINT] = ["手札の財宝カード1枚を公開してもよい。それと同じカード1枚を獲得する。", "財宝1枚を造幣する"];
JapaneseQuestionDescriptions[QuestionIds.MOUNTEBANK] = ["呪い1枚を捨て札にしてもよい。", "呪い1枚を捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.RABBLE] = JapaneseQuestionDescriptions[QuestionIds.SENTRY_TOPDECK];
JapaneseQuestionDescriptions[QuestionIds.TRADE_ROUTE] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.VAULT_SELF] = ["好きな枚数のカードを捨て札にする。", "捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.VAULT_OTHER] = JapaneseQuestionDescriptions[QuestionIds.TORTURER_MAY_DISCARD];
JapaneseQuestionDescriptions[QuestionIds.FOLLOWERS] = JapaneseQuestionDescriptions[QuestionIds.MILITIA];
JapaneseQuestionDescriptions[QuestionIds.DIADEM] = ["残アクション分の$を得る前に村人を使ってもよい。", "村人を使ってもよい"];
JapaneseQuestionDescriptions[QuestionIds.HAMLET] = ["+1アクション/+1購入のために、カードを捨て札にしてもよい。", "捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.HAMLET_ACTION] = ["+1アクションのためにカードを捨て札にする。", "+1アクションのために捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.HAMLET_BUY] = ["+1購入のためにカードを捨て札にする。", "+1購入のために捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.HAMLET_BOTH] = ["2枚捨て札にする。", "捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.HORN_OF_PLENTY] = ["コストAMOUNT0以下のカード1枚を獲得する。勝利点カードの場合、これを廃棄する。", "カードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.HORSE_TRADERS] = JapaneseQuestionDescriptions[QuestionIds.HAMLET_BOTH];
JapaneseQuestionDescriptions[QuestionIds.JESTER] = ["あなたとPLAYER1のどちらがCARDS0を獲得するか選ぶ。", "CARDS0を獲得するプレイヤーを選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.REMAKE_TRASH_FIRST] = ["手札1枚を廃棄し、それよりコストが$1高いカード1枚を獲得する。", "カードを廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.REMAKE_TRASH_SECOND] = JapaneseQuestionDescriptions[QuestionIds.REMAKE_TRASH_FIRST];
JapaneseQuestionDescriptions[QuestionIds.REMAKE_GAIN_FIRST] = ["コストCOST0のカード1枚を獲得する。", "カードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.REMAKE_GAIN_SECOND] = ["コストCOST0のカード1枚を獲得する。", "カードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.TOURNAMENT_GAIN_PRIZE_OR_DUCHY] = ["褒賞か公領を山札の上に獲得してもよい。", "褒賞か公領を獲得する"];
JapaneseQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_PRIZE] = ["褒賞を山札の上に獲得してもよい。", "褒賞を獲得する"];
JapaneseQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_DUCHY] = ["公領を山札の上に獲得してもよい。", "公領を獲得する"];
JapaneseQuestionDescriptions[QuestionIds.TOURNAMENT_REVEAL] = ["属州を公開してもよい。", "属州を公開してもよい"];
JapaneseQuestionDescriptions[QuestionIds.TRUSTY_STEED] = ["名馬をどう使用するか選ぶ。", "名馬の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.YOUNG_WITCH] = JapaneseQuestionDescriptions[QuestionIds.HAMLET_BOTH];
JapaneseQuestionDescriptions[QuestionIds.YOUNG_WITCH_BANE] = ["CARDS0を災いカードとして公開してもよい。", "CARDS0を災いカードとして公開してもよい"];

JapaneseQuestionDescriptions[QuestionIds.BORDER_VILLAGE] = ["コストAMOUNT0未満のカード1枚を獲得する。", "コストAMOUNT0未満のカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.CARTOGRAPHER_DISCARD] = ["好きな枚数を捨て札にする。", "捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.CARTOGRAPHER_TOPDECK] = JapaneseQuestionDescriptions[QuestionIds.SENTRY_TOPDECK];
JapaneseQuestionDescriptions[QuestionIds.DEVELOP_TRASH] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.DEVELOP_GAIN1] = ["コストAMOUNT0かArgument1のカード1枚を山札の上に獲得する。", "コストAMOUNT0かArgument1のカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.DEVELOP_GAIN2] = ["コストAMOUNT0のカード1枚を山札の上に獲得する。", "コストAMOUNT0のカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.DUCHESS] = ["CARDS0を捨て札にしてもよい。", "CARDS0を捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.EMBASSY] = ["3枚捨て札にする。", "3枚捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.FARMLAND_TRASH] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.FARMLAND_GAIN] = JapaneseQuestionDescriptions[QuestionIds.UPGRADE_GAIN];
JapaneseQuestionDescriptions[QuestionIds.HAGGLER] = ["コストAMOUNT0未満の勝利点以外のカード1枚を獲得する。", "コストAMOUNT0未満の勝利点以外のカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.ILL_GOTTEN_GAINS] = ["手札に銅貨1枚を獲得してもよい。", "銅貨を獲得してもよい"];
JapaneseQuestionDescriptions[QuestionIds.INN_DISCARD] = JapaneseQuestionDescriptions[QuestionIds.HAMLET_BOTH];
JapaneseQuestionDescriptions[QuestionIds.INN_SHUFFLE] = ["捨て札から好きな枚数のアクションカードを公開し、山札に混ぜシャッフルする。", "アクションカードを山札に混ぜてシャッフルする"];
JapaneseQuestionDescriptions[QuestionIds.JACK_DISCARD] = ["CARDS0を捨て札にしてもよい。", "CARDS0を捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.JACK_TRASH] = ["手札から財宝以外のカード1枚を廃棄してもよい。", "財宝以外のカードを廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.MANDARIN_PLAY] = JapaneseQuestionDescriptions[QuestionIds.ARTISAN_TOPDECK];
JapaneseQuestionDescriptions[QuestionIds.MANDARIN_GAIN] = ["場の財宝カードを好きな順番で山札の上に置く。", "財宝を山札の上に置く"];
JapaneseQuestionDescriptions[QuestionIds.MARGRAVE] = JapaneseQuestionDescriptions[QuestionIds.MILITIA];
JapaneseQuestionDescriptions[QuestionIds.NOBLE_BRIGAND] = ["廃棄させる財宝カードを選ぶ。", "廃棄させる財宝カードを選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.OASIS] = ["手札1枚を捨て札にする。", "捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.ORACLE_DISCARD] = ["PLAYER1にCARDS0を捨て札にさせてもよい。", "PLAYER1に捨て札にさせてもよい"];
JapaneseQuestionDescriptions[QuestionIds.ORACLE_TOPDECK] = JapaneseQuestionDescriptions[QuestionIds.SENTRY_TOPDECK];
JapaneseQuestionDescriptions[QuestionIds.SCHEME] = ["画策で山札の上に戻すカードを選んでもよい。", "画策してもよい"];
JapaneseQuestionDescriptions[QuestionIds.SCHEMES] = ["どのカードを画策する?", "Argument0枚までカードを山札の上に置く"];
JapaneseQuestionDescriptions[QuestionIds.SPICE_MERCHANT_TRASH] = ["手札の財宝カード1枚を廃棄してもよい。", "財宝カードを廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.SPICE_MERCHANT_MODE] = ["「+2カードを引く、+1アクション」か、「+1購入、+$2」のうち1つを選ぶ。", "香辛料商人の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.STABLES] = ["財宝カード1枚を捨て札にしてもよい。", "財宝カードを捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.TRADER_TRASH] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];

JapaneseQuestionDescriptions[QuestionIds.ALTAR_TRASH] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.ALTAR_GAIN] = ["コスト$5以下のカード1枚を獲得する。", "カードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.ARMORY] = ["コスト$4以下のカード1枚を山札の上に獲得する。", "カードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.BAND_OF_MISFITS] = ["これカードより安いアクションカードを使用する。", "カードを使用する"];
JapaneseQuestionDescriptions[QuestionIds.CATACOMBS_MODE] = ["CARDS0を手札に加えるか、捨て札にして3枚カードを引くか選ぶ。", "手札に加えるか別のカード3枚を引くか選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.CATACOMBS_GAIN] = JapaneseQuestionDescriptions[QuestionIds.BORDER_VILLAGE];
JapaneseQuestionDescriptions[QuestionIds.COUNT_PAY] = ["2枚捨て札にするか、1枚山札の上に置くか、銅貨1枚を獲得するか選ぶ。", "伯爵のふたつ目の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.COUNT_DISCARD] = ["2枚捨て札にする。", "捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.COUNT_TOPDECK] = JapaneseQuestionDescriptions[QuestionIds.ARTISAN_TOPDECK];
JapaneseQuestionDescriptions[QuestionIds.COUNT_PROFIT] = ["+$3か、手札をすべて廃棄するか、公領を獲得するか選ぶ。", "伯爵のふたつ目の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.COUNTERFEIT] = ["手札の財宝カード1枚を2回使用してもよい。そうした場合、それを廃棄する。", "2回使用し、廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.CULTIST] = ["手札の狂信者を使用してもよい。", "狂信者を使用してもよい"];
JapaneseQuestionDescriptions[QuestionIds.DAME_ANNA] = ["手札を2枚まで廃棄する。", "カードを廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.DAME_NATALIE] = ["コスト$3以下のカード1枚を獲得する。", "カードを獲得してもよい"];
JapaneseQuestionDescriptions[QuestionIds.DEATH_CART] = ["手札のアクションカード1枚か場の死の荷車を廃棄してもよい。", "死の荷車かアクションカードを廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.FORAGER] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.KNIGHTS] = ["廃棄するカードを選ぶ。", "廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.KNIGHTS_ALREADY_DIES] = ["廃棄するカードを選ぶ。(騎士CARDS0はすでに討死しました)", "廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.KNIGHTS_STILL_MATTERS] = ["廃棄するカードを選ぶ。(CARDS0を廃棄すれば、騎士CARDS1は廃棄されます)", "廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_EXPAND] = ["手札のアクションカードを廃棄し、それよりコストが最大$3高いカード1枚を獲得してもよい。", "カードを廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.GRAVEROBBER_EXPAND] = ["手札のアクションカードを廃棄し、それよりコストが最大$3高いカード1枚を獲得する。", "カードを廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_GAIN] = ["廃棄置き場から山札の上にカード1枚を獲得してもよい。", "廃棄置き場からカードを獲得してもよい"];
JapaneseQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN] = ["廃棄置き場から山札の上にカード1枚を獲得する。", "カードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.GRAVEROBBER_COMPLEX] = ["墓暴きの使用で...", "廃棄置き場から獲得するか、手札のアクションカードを廃棄する"]; //abandoned by GRAVEROBBER_MODE
JapaneseQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN_SUPPLY] = JapaneseQuestionDescriptions[QuestionIds.REMODEL_GAIN];
JapaneseQuestionDescriptions[QuestionIds.HERMIT_TRASH] = ["手札か捨て札から財宝以外のカード1枚を廃棄してもよい。", "廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.HERMIT_GAIN] = ["コスト$3以下のカード1枚を獲得する。", "カードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.HUNTING_GROUNDS] = ["公領か屋敷3枚を獲得する。", "公領か屋敷3枚かを選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.IRONMONGER] = JapaneseQuestionDescriptions[QuestionIds.DUCHESS];
JapaneseQuestionDescriptions[QuestionIds.JUNK_DEALER] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.MERCENARY_ONE] = ["手札の1枚を廃棄してもよい(ボーナスなし)。", "廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.MERCENARY_MULTIPLE] = ["手札2枚を廃棄してもよい。", "カード2枚を廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.MERCENARY_DISCARD] = JapaneseQuestionDescriptions[QuestionIds.MILITIA];
JapaneseQuestionDescriptions[QuestionIds.MYSTIC] = JapaneseQuestionDescriptions[QuestionIds.WISHING_WELL];
JapaneseQuestionDescriptions[QuestionIds.PILLAGE] = ["PLAYER0がどのカードを捨て札にするか選ぶ。", "PLAYER0が捨て札にするカードを選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.PROCESSION_PLAY] = ["手札のアクションカードを2回使用してもよい。", "アクションカードを2回使用してもよい"];
JapaneseQuestionDescriptions[QuestionIds.PROCESSION_GAIN] = ["コストAMOUNT0のアクションカード1枚を獲得する。", "コストAMOUNT0のアクションカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.RATS_TRASH_ANY] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.RATS_TRASH_OTHER] = ["ネズミ以外の手札1枚を廃棄する。", "カードを廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.REBUILD_SKIP] = ["飛ばす勝利点カードを指定する。", "カードを指定する"];
JapaneseQuestionDescriptions[QuestionIds.REBUILD_GAIN] = ["コストAMOUNT0以下の勝利点カード1枚を獲得する。", "コストAMOUNT0以下の勝利点カードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.ROGUE_TRASH] = ["廃棄するカードをPLAYER0が選ぶ。", "廃棄するカードをPLAYER0が選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.ROGUE_GAIN] = ["廃棄置き場からコスト$3以上$6以下のカード1枚を獲得する。", "カードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.SCAVENGER] = ["山札を捨て札置き場に置いてもよい。", "山札を捨て札置き場に置いてもよい"];
JapaneseQuestionDescriptions[QuestionIds.SCAVENGER_TOPDECK] = ["捨て札置き場からカード1枚を山札の上に置く。", "カード1枚を山札の上に置く"];
JapaneseQuestionDescriptions[QuestionIds.SIR_MICHAEL] = JapaneseQuestionDescriptions[QuestionIds.MILITIA];
JapaneseQuestionDescriptions[QuestionIds.SQUIRE_MODE] = ["+2アクション、+2購入、銀貨獲得のうち1つ選ぶ。", "従者の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.SQUIRE_GAIN] = ["アタックカード1枚を獲得する。", "アタックカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.STOREROOM_CARDS] = JapaneseQuestionDescriptions[QuestionIds.CELLAR];
JapaneseQuestionDescriptions[QuestionIds.STOREROOM_COINS] = ["好きな枚数のカードを捨て札にし、1枚につき+$1を得る。", "捨て札にし、$を得る。"];
JapaneseQuestionDescriptions[QuestionIds.SURVIVORS] = ["好きな順番で2枚を戻すか、両方を捨て札にする。", "山札に戻すか捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.URCHIN] = ["4枚になるように捨て札にする。", "捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.WANDERING_MINSTREL] = JapaneseQuestionDescriptions[QuestionIds.SENTRY_TOPDECK];

JapaneseQuestionDescriptions[QuestionIds.ADVISOR] = ["PLAYER0が捨て札にするカードを選ぶ。", "PLAYER0が捨て札にするカードを選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.BUTCHER_TRASH] = JapaneseQuestionDescriptions[QuestionIds.MASQUERADE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.BUTCHER_GAIN] = JapaneseQuestionDescriptions[QuestionIds.REMODEL_GAIN];
JapaneseQuestionDescriptions[QuestionIds.DOCTOR_PLAY] = ["廃棄するカードを指定する。", "カードを指定する"];
JapaneseQuestionDescriptions[QuestionIds.DOCTOR_TOPDECK] = JapaneseQuestionDescriptions[QuestionIds.SENTRY_TOPDECK];
JapaneseQuestionDescriptions[QuestionIds.DOCTOR_OVERPAY] = ["医者に過払いしてもよい。", "過払いしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.DOCTOR_MODE] = ["CARDS0をどうする?", "CARDS0の治療法を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.DOCTOR_MODE_MANY] = ["CARDS0をどうする? (残りNUMBER1枚)", "CARDS0の治療法を選ぶ (残りNUMBER1枚)"];
JapaneseQuestionDescriptions[QuestionIds.HERALD_OVERPAY] = ["伝令官に過払いしてもよい。", "過払いしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.HERALD_CHOOSE] = ["捨て札置き場から山札の上にカード1枚を置く。", "捨て札置き場から山札の上に置く"];
JapaneseQuestionDescriptions[QuestionIds.JOURNEYMAN] = ["引かないカードを指定する。", "カードを指定する"];
JapaneseQuestionDescriptions[QuestionIds.MASTERPIECE] = ["名品に過払いしてもよい。", "過払いしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.PLAZA_DELAY] = ["財宝カード1枚を捨て札にして+1財源を得てもよい。", "財宝カードを捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.PLAZA_DISCARD] = ["財宝カード1枚を捨て札にする。", "財宝カードを捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.STONEMASON_TRASH] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.STONEMASON_GAIN1] = ["コストAMOUNT0未満のカード2枚を獲得する。", "コストAMOUNT0未満のカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.STONEMASON_GAIN2] = ["コストAMOUNT0未満のカードをもう1枚獲得する。", "コストAMOUNT0未満のカードをもう1枚獲得する"];
JapaneseQuestionDescriptions[QuestionIds.STONEMASON_OVERPAY] = ["石工に過払いしてコストAMOUNT0までのアクションカードを獲得する。", "先に過払いする、または、過払いしたいカードを直接選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.STONEMASON_GAIN_EQUAL] = ["コストAMOUNT0のアクションカードをもう1枚獲得する。", "コストAMOUNT0のアクションカードをもう1枚獲得する"];
JapaneseQuestionDescriptions[QuestionIds.TAXMAN_TRASH] = ["手札の財宝カード1枚を廃棄してもよい。", "財宝カードを廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.TAXMAN_GAIN] = ["コストAMOUNT0以下の財宝カード1枚を山札の上に獲得する。", "コストAMOUNT0以下の財宝カードを獲得する"];

JapaneseQuestionDescriptions[QuestionIds.TRASHING_TOKEN] = JapaneseQuestionDescriptions[QuestionIds.MASQUERADE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.ALMS] = JapaneseQuestionDescriptions[QuestionIds.WORKSHOP];
JapaneseQuestionDescriptions[QuestionIds.AMULET_MODE] = ["+$1か、手札1枚の廃棄か、銀貨1枚の獲得から1つ選ぶ。", "魔除けの使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.AMULET_TRASH] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.ARTIFICER_DELAY] = ["好きな枚数のカードを捨て札にする。捨てた枚数と同じ$数のカード1枚を獲得する。", "工匠で製作してもよい"];
JapaneseQuestionDescriptions[QuestionIds.ARTIFICER_DISCARD] = ["好きな枚数のカードを捨て札にする。捨てた枚数と同じ$数のカード1枚を山札の上に獲得する。", "捨て札にし、獲得する"];
JapaneseQuestionDescriptions[QuestionIds.ARTIFICER_GAIN] = ["コストAMOUNT0のカードを山札の上に獲得してもよい。", "コストAMOUNT0のカードを獲得してもよい"];
JapaneseQuestionDescriptions[QuestionIds.BALL_FIRST] = ["コスト$4以下のカード2枚を獲得する。", "カード2枚を獲得する"];
JapaneseQuestionDescriptions[QuestionIds.BALL_SECOND] = ["コスト$4以下のカードをもう1枚獲得する。", "カードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.BONFIRE] = ["場から銅貨2枚以下を廃棄する。", "銅貨2枚以下を廃棄をする"];
JapaneseQuestionDescriptions[QuestionIds.DISCIPLE] = ["手札のアクションカードを2回使用してもよい。それと同じカードを獲得する。", "2回使用し、そのカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.DUNGEON] = JapaneseQuestionDescriptions[QuestionIds.HAMLET_BOTH];
JapaneseQuestionDescriptions[QuestionIds.FERRY] = ["あなたの-$2トークンをアクションカードのサプライの山に移動する。", "-$2トークンを移動する"];
JapaneseQuestionDescriptions[QuestionIds.FUGITIVE] = JapaneseQuestionDescriptions[QuestionIds.OASIS];
JapaneseQuestionDescriptions[QuestionIds.GEAR] = ["2枚まで脇に置く。あなたの次のターンの開始時に、それらを手札に加える。", "2枚まで脇に置く"];
JapaneseQuestionDescriptions[QuestionIds.HAUNTED_WOODS] = ["手札を好きな順番で山札の上に置く。", "手札を山札の上に置く"];
JapaneseQuestionDescriptions[QuestionIds.HERO] = ["財宝カード1枚を獲得する。", "財宝カードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.INHERITANCE] = ["相続するカードを選ぶ。", "相続するカードを選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.LOST_ARTS_NEW] = ["アクションカードのサプライの山にあなたの+1アクショントークンを置く。", "+1アクショントークンを移動する"];
JapaneseQuestionDescriptions[QuestionIds.LOST_ARTS_MOVE] = ["アクションカードのサプライの山にあなたの+1アクショントークンを移動する。", "+1アクショントークンを移動する"];
JapaneseQuestionDescriptions[QuestionIds.MESSENGER_DISCARD] = JapaneseQuestionDescriptions[QuestionIds.SCAVENGER];
JapaneseQuestionDescriptions[QuestionIds.MESSENGER_GAIN] = ["コスト$4以下のカードを獲得し、他のプレイヤーも同じカードを獲得する。", "コスト$4以下のカードを配布する"];
JapaneseQuestionDescriptions[QuestionIds.MISER] = ["銅貨1枚を酒場マットに置くか、+AMOUNT0かを選ぶ。", "銅貨を酒場に送るか、+AMOUNT0"];
JapaneseQuestionDescriptions[QuestionIds.PATHFINDING_NEW] = ["アクションカードのサプライの山にあなたの+1カードトークンを置く。", "+1カードトークンを移動する"];
JapaneseQuestionDescriptions[QuestionIds.PATHFINDING_MOVE] = ["アクションカードのサプライの山にあなたの+1カードトークンを移動する。", "+1カードトークンを移動する"];
JapaneseQuestionDescriptions[QuestionIds.PILGRIMAGE_FIRST] = ["場から3種類までカードを選び、それらと同じカードを獲得する。", "獲得するカード3枚を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.PILGRIMAGE_SECOND] = ["場から2種類まで異なるカードを選び、それらと同じカードを獲得する。", "獲得するカード2枚を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.PILGRIMAGE_THIRD] = ["場のカードを選び、それと同じカードを獲得する。", "獲得するカードを選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.PLAN_NEW] = ["アクションカードのサプライの山にあなたの廃棄トークンを置く。", "廃棄トークンを移動する"];
JapaneseQuestionDescriptions[QuestionIds.PLAN_MOVE] = ["アクションカードのサプライの山にあなたの廃棄トークンを移動する。", "廃棄トークンを移動する"];
JapaneseQuestionDescriptions[QuestionIds.QUEST_MODE] = ["アタックカード/呪い2枚/カード6枚のうちから、捨て札にするものを選ぶ。", "探検の仕方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.QUEST_ATTACK] = ["アタックカードを捨て札にする。", "アタックカードを捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.QUEST_6CARDS] = ["6枚捨て札にする。", "6枚捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.RATCATCHER] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_PLAY] = ["場の倒壊を廃棄してもよい。", "倒壊を廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.RAZE_ALL] = ["場の倒壊か手札1枚を廃棄してもよい。", "倒壊か手札1枚を廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_HAND] = JapaneseQuestionDescriptions[QuestionIds.MASQUERADE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.RAZE_CHOOSE_CARD] = ["手札に加えるカードを選ぶ。", "手札にカードを加える"];
JapaneseQuestionDescriptions[QuestionIds.SAVE] = ["カード1枚を脇に置き、クリーンアップ終了後(手札を作った後)に手札に加える。", "脇にカード1枚を置く"];
JapaneseQuestionDescriptions[QuestionIds.SCOUTING_PARTY_DISCARD] = ["NUMBER0枚捨て札にする。", "NUMBER0枚捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.SCOUTING_PARTY_TOPDECK] = JapaneseQuestionDescriptions[QuestionIds.SENTRY_TOPDECK];
JapaneseQuestionDescriptions[QuestionIds.SEAWAY] = ["コスト$4以下のアクションカードを獲得し、その山にあなたの+1購入トークンを移動する。", "アクションカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.SOLDIER] = JapaneseQuestionDescriptions[QuestionIds.OASIS];
JapaneseQuestionDescriptions[QuestionIds.STORYTELLER_FIRST] = ["手札から3枚以下の財宝カードを使用する。", "財宝カードを3枚まで使用する"];
JapaneseQuestionDescriptions[QuestionIds.STORYTELLER_SECOND] = ["手札からあと2枚以下の財宝カードを使用する。", "財宝カードを3枚まで使用する"];
JapaneseQuestionDescriptions[QuestionIds.STORYTELLER_THIRD] = ["手札の財宝カード1枚を使用してもよい。", "財宝カードを使用してもよい"];
JapaneseQuestionDescriptions[QuestionIds.TEACHER_CHOOSE_TOKEN] = ["移動するトークンを選ぶ。", "移動するトークンを選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_MOVE] = ["あなたの+1アクショントークンを、あなたのトークンが置いてないアクションカードのサプライの山に置く。", "+1アクショントークンを移動する"];
JapaneseQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_NEW] = ["あなたの+1アクショントークンを、あなたのトークンが置いてないアクションカードのサプライの山に移動する。", "+1アクショントークンを移動する"];
JapaneseQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_MOVE] = ["あなたの+1カードトークンを、あなたのトークンが置いてないアクションカードのサプライの山に置く。", "+1カードトークンを移動する"];
JapaneseQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_NEW] = ["あなたの+1カードトークンを、あなたのトークンが置いてないアクションカードのサプライの山に移動する。", "+1カードトークンを移動する"];
JapaneseQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_MOVE] = ["あなたの+$1トークンを、あなたのトークンが置いてないアクションカードのサプライの山に置く。", "+$1トークンを移動する"];
JapaneseQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_NEW] = ["あなたの+$1トークンを、あなたのトークンが置いてないアクションカードのサプライの山に移動する。", "+$1トークンを移動する"];
JapaneseQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_MOVE] = ["あなたの+1購入トークンを、あなたのトークンが置いてないアクションカードのサプライの山に置く。", "+1購入トークンを移動する"];
JapaneseQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_NEW] = ["あなたの+1購入トークンを、あなたのトークンが置いてないアクションカードのサプライの山に移動する。", "+1購入トークンを移動する"];
JapaneseQuestionDescriptions[QuestionIds.TRADE] = ["手札2枚以下を廃棄する。廃棄したのと同じ枚数の銀貨を獲得する。", "手札を2枚まで廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.TRAINING_NEW] = ["あなたの+$1トークンを、アクションカードのサプライの山に移動する。", "+$1トークンを移動する"];
JapaneseQuestionDescriptions[QuestionIds.TRAINING_MOVE] = ["あなたの+$1トークンを、アクションカードのサプライの山に置く。", "+$1トークンを移動する"];
JapaneseQuestionDescriptions[QuestionIds.TRANSMOGRIFY_TRASH] = ["手札1枚を廃棄し、それよりコストが最大$1高いカード1枚を手札に獲得する。", "カード1枚を廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.TRANSMOGRIFY_GAIN] = ["コストAMOUNT0以下のカード1枚を手札に獲得する。", "コストAMOUNT0以下のカードを獲得する"];

JapaneseQuestionDescriptions[QuestionIds.ADVANCE_TRASH] = ["手札のアクションカード1枚を廃棄してもよい。", "アクションカードを廃棄可能"];
JapaneseQuestionDescriptions[QuestionIds.ADVANCE_GAIN] = ["コスト$6以下のアクションカード1枚を獲得する。", "アクションカード獲得"];
JapaneseQuestionDescriptions[QuestionIds.ANNEX] = ["シャッフルに含めないカードを5枚まで選ぶ。", "カードを5枚まで選択"];
JapaneseQuestionDescriptions[QuestionIds.ARCHIVE] = ["手札に加えるカードを選ぶ。", "カードを選択"];
JapaneseQuestionDescriptions[QuestionIds.ARENA] = ["NUMBER0勝利点を得るために、手札のアクションカードを捨て札にしてもよい。", "闘技場: NUMBER0勝利点のために捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.BANQUET] = ["コスト$5以下の勝利点以外のカード1枚を獲得する。", "カード獲得"];
JapaneseQuestionDescriptions[QuestionIds.BUSTLING_VILLAGE] = ["開拓者1枚を手札に加えてもよい。", "開拓者を手札に加えてもよい"];
JapaneseQuestionDescriptions[QuestionIds.CATAPULT_TRASH] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.CATAPULT_DISCARD] = JapaneseQuestionDescriptions[QuestionIds.MILITIA];
JapaneseQuestionDescriptions[QuestionIds.CHARM_MODE] = ["御守りの効果を選ぶ。", "御守りの効果を選択"];
JapaneseQuestionDescriptions[QuestionIds.CHARM_GAIN] = ["CARDS0以外のコストAMOUNT1のカード1枚を獲得してもよい。", "CARDS0以外のコストAMOUNT1のカードを獲得可能"];
JapaneseQuestionDescriptions[QuestionIds.CROWN_ACTION] = ["手札のアクションカード1枚を2回使用してもよい。", "アクションカードを2回使用してもよい"];
JapaneseQuestionDescriptions[QuestionIds.CROWN_TREASURE] = ["手札の財宝カード1枚を2回使用してもよい。", "財宝カードを2回使用してもよい"];
JapaneseQuestionDescriptions[QuestionIds.DONATE] = JapaneseQuestionDescriptions[QuestionIds.FORGE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.ENCAMPMENT] = ["手札の金貨か鹵獲品を公開してもよい。", "金貨か鹵獲品を公開可能"];
JapaneseQuestionDescriptions[QuestionIds.ENGINEER_GAIN] = JapaneseQuestionDescriptions[QuestionIds.WORKSHOP];
JapaneseQuestionDescriptions[QuestionIds.ENGINEER_TRASH] = ["技術者を廃棄してもよい。", "技術者を廃棄可能"];
JapaneseQuestionDescriptions[QuestionIds.FORUM] = JapaneseQuestionDescriptions[QuestionIds.HAMLET_BOTH];
JapaneseQuestionDescriptions[QuestionIds.GLADIATOR_REVEAL] = JapaneseQuestionDescriptions[QuestionIds.COURTIER_REVEAL];
JapaneseQuestionDescriptions[QuestionIds.GLADIATOR_PRETEND] = ["同じカードを公開するフリをしています。", "考えるフリ"];
JapaneseQuestionDescriptions[QuestionIds.GLADIATOR_BLOCK] = ["CARDS0を手札から公開してもよい。", "CARDS0を公開可能"];
JapaneseQuestionDescriptions[QuestionIds.HAUNTED_CASTLE] = ["山札の上に手札から2枚を置く。", "2枚山札の上に置く"];
JapaneseQuestionDescriptions[QuestionIds.LEGIONARY_REVEAL] = ["手札の金貨を手札から公開してもよい。", "金貨を公開可能"];
JapaneseQuestionDescriptions[QuestionIds.LEGIONARY_DISCARD] = ["2枚になるように捨て札にする。", "捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_NEW] = ["40負債まで入札する。", "40負債まで入札"];
JapaneseQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_OVERBID] = ["40負債まで入札する。現在PLAYER0がNUMBER1を入札しています。", "40負債まで入札"];
JapaneseQuestionDescriptions[QuestionIds.OVERLORD] = ["コスト$5以下のアクションカードを使用する。", "カード使用"];
JapaneseQuestionDescriptions[QuestionIds.OPULENT_CASTLE] = ["好きな枚数の勝利点カードを捨て札にし、1枚につき+$2。", "1枚につき+$2得るために捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.RITUAL] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.SACRIFICE] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.SALT_THE_EARTH] = ["サプライの勝利点カード1枚を廃棄する。", "サプライから廃棄"];
JapaneseQuestionDescriptions[QuestionIds.SETTLERS] = ["捨て札の銅貨1枚を手札に加えてもよい。", "銅貨1枚を手札に加えてもよい"];
JapaneseQuestionDescriptions[QuestionIds.SMALL_CASTLE_IN_PLAY] = ["手札の城カードか、小さな城を廃棄する。", "城カードを廃棄"];
JapaneseQuestionDescriptions[QuestionIds.SMALL_CASTLE_GONE] = ["手札から城カードを廃棄してもよい。", "城カードを廃棄可能"];
JapaneseQuestionDescriptions[QuestionIds.SMALL_CASTLE_GAIN] = ["城カード1枚を獲得する。", "城カード獲得"];
JapaneseQuestionDescriptions[QuestionIds.SPRAWLING_CASTLE] = JapaneseQuestionDescriptions[QuestionIds.HUNTING_GROUNDS];
JapaneseQuestionDescriptions[QuestionIds.TAX] = ["2負債追加するサプライの山を選ぶ。", "山に2負債追加する"];
JapaneseQuestionDescriptions[QuestionIds.TEMPLE] = ["手札から1から3種類のカードを廃棄する。", "1から3枚のカードを廃棄"];
JapaneseQuestionDescriptions[QuestionIds.WILD_HUNT] = ["+3カードを引くか、屋敷を獲得して勝利点を得るか獲得する。", "ドローか、得点か選択"];

JapaneseQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_DISCARD] = JapaneseQuestionDescriptions[QuestionIds.STABLES];
JapaneseQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_GAIN] = JapaneseQuestionDescriptions[QuestionIds.WORKSHOP];
JapaneseQuestionDescriptions[QuestionIds.THE_FLAMES_GIFT] = JapaneseQuestionDescriptions[QuestionIds.MASQUERADE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.THE_MOONS_GIFT] = JapaneseQuestionDescriptions[QuestionIds.HARBINGER_TOPDECK];
JapaneseQuestionDescriptions[QuestionIds.THE_SKYS_GIFT] = ["金貨獲得のために、3枚捨て札にしてもよい。", "捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_TOPDECK] = JapaneseQuestionDescriptions[QuestionIds.SENTRY_TOPDECK];
JapaneseQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_DISCARD] = JapaneseQuestionDescriptions[QuestionIds.CARTOGRAPHER_DISCARD];
JapaneseQuestionDescriptions[QuestionIds.THE_WINDS_GIFT] = JapaneseQuestionDescriptions[QuestionIds.HAMLET_BOTH];
JapaneseQuestionDescriptions[QuestionIds.FEAR_DISCARD] = ["アクションカードか財宝カード1枚を捨て札にする。", "捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.HAUNTING] = JapaneseQuestionDescriptions[QuestionIds.ARTISAN_TOPDECK];
JapaneseQuestionDescriptions[QuestionIds.LOCUSTS] = ["廃棄したカードと同じ種類を持つ、安いカード1枚を獲得する。", "カード獲得"];
JapaneseQuestionDescriptions[QuestionIds.POVERTY] = JapaneseQuestionDescriptions[QuestionIds.MILITIA];
JapaneseQuestionDescriptions[QuestionIds.BAT] = ["手札を2枚まで廃棄する。", "カードを廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.BLESSED_VILLAGE_MODE] = ["Argument0をいつ受けるか?", "いつ受けるか選択"];
JapaneseQuestionDescriptions[QuestionIds.CEMETERY] = JapaneseQuestionDescriptions[QuestionIds.CHAPEL];
JapaneseQuestionDescriptions[QuestionIds.CHANGELING_GAIN_COPY] = JapaneseQuestionDescriptions[QuestionIds.PILGRIMAGE_THIRD];
JapaneseQuestionDescriptions[QuestionIds.CHANGELING_REPLACE] = ["取り替え子と交換してもよい。", "取り替え子と交換"];
JapaneseQuestionDescriptions[QuestionIds.COBBLER] = ["コスト$4以下のカード1枚を手札に獲得する。", "カード獲得"];
JapaneseQuestionDescriptions[QuestionIds.CONCLAVE] = ["手札のアクションカードを使用してもよい。", "アクションカードを使用可能"];
JapaneseQuestionDescriptions[QuestionIds.CRYPT_SET_ASIDE] = ["好きな枚数の財宝カードを脇に置く。あなたの各ターンの開始時に、それらのうち1枚を手札に加える。", "脇に置く"];
JapaneseQuestionDescriptions[QuestionIds.CRYPT_RETURN_TO_HAND] = JapaneseQuestionDescriptions[QuestionIds.ARCHIVE];
JapaneseQuestionDescriptions[QuestionIds.DEVILS_WORKSHOP] = JapaneseQuestionDescriptions[QuestionIds.WORKSHOP];
JapaneseQuestionDescriptions[QuestionIds.DRUID] = ["どの祝福を受けるか選ぶ。", "祝福選択"];
JapaneseQuestionDescriptions[QuestionIds.EXORCIST_TRASH] = JapaneseQuestionDescriptions[QuestionIds.REMODEL_TRASH];
JapaneseQuestionDescriptions[QuestionIds.EXORCIST_GAIN] = ["安い精霊を獲得する。", "精霊獲得"];
JapaneseQuestionDescriptions[QuestionIds.FOOL] = ["次の祝福を選ぶ。", "祝福選択"];
JapaneseQuestionDescriptions[QuestionIds.GOAT] = JapaneseQuestionDescriptions[QuestionIds.MASQUERADE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.HAUNTED_MIRROR] = ["幽霊獲得のために、アクションカードを捨て札にしてもよい。", "捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.IMP] = ["場にない手札のアクションカードを使用してもよい。", "アクションカードを使用可能"];
JapaneseQuestionDescriptions[QuestionIds.NECROMANCER] = ["廃棄置き場からカードを使用する。", "廃棄置き場から使用"];
JapaneseQuestionDescriptions[QuestionIds.MONASTERY] = ["AMOUNT0枚までの場の銅貨または手札を廃棄する。", "AMOUNT0枚まで廃棄"];
JapaneseQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_TOPDECK] = JapaneseQuestionDescriptions[QuestionIds.SENTRY_TOPDECK];
JapaneseQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_DISCARD] = JapaneseQuestionDescriptions[QuestionIds.CARTOGRAPHER_DISCARD];
JapaneseQuestionDescriptions[QuestionIds.PIXIE_MODE] = ["祝福を受けるためにピクシーを廃棄するか?", "カード廃棄"];
JapaneseQuestionDescriptions[QuestionIds.POOKA] = ["呪われた金貨以外の財宝カード1枚を廃棄してもよい。", "カード廃棄"];
JapaneseQuestionDescriptions[QuestionIds.RAIDER] = JapaneseQuestionDescriptions[QuestionIds.OASIS];
JapaneseQuestionDescriptions[QuestionIds.SACRED_GROVE_MODE] = ["Argument0を受けるか選ぶ。", "Argument0を受けるか選択"];
JapaneseQuestionDescriptions[QuestionIds.SECRET_CAVE] = ["次のターン+$3を得るために3枚捨て札にしてもよい。", "捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.SHEPHERD] = ["好きな枚数の勝利点カードを捨て札にし、1枚につき+2カードを引く。", "1枚につき+2カードを引くために捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.TRAGIC_HERO] = JapaneseQuestionDescriptions[QuestionIds.HERO];
JapaneseQuestionDescriptions[QuestionIds.VAMPIRE] = ["吸血鬼以外のコスト$5以下のカード1枚を獲得する。", "カード獲得"];
JapaneseQuestionDescriptions[QuestionIds.WISH] = ["コスト$6以下のカード1枚を手札に獲得する。", "カード1枚を獲得"];
JapaneseQuestionDescriptions[QuestionIds.ZOMBIE_APPRENTICE] = ["手札のアクションカード1枚を廃棄してもよい。", "アクションカードを廃棄可能"];
JapaneseQuestionDescriptions[QuestionIds.ZOMBIE_MASON] = ["カードを獲得してもよい。", "カード獲得可能"];
JapaneseQuestionDescriptions[QuestionIds.ZOMBIE_SPY] = JapaneseQuestionDescriptions[QuestionIds.DUCHESS];
JapaneseQuestionDescriptions[QuestionIds.WHAT_NIGHT_TO_PLAY] = ["夜フェイズ中には夜行カードを使用できます。", "夜行カード使用可能"];
JapaneseQuestionDescriptions[QuestionIds.LOST_IN_THE_WOODS] = ["祝福を受けるために、1枚捨て札にしてもよい。", "捨て札にしてもよい"];

JapaneseQuestionDescriptions[QuestionIds.DUCAT] = ["銅貨1枚を廃棄してもよい。", "銅貨廃棄可能"];
JapaneseQuestionDescriptions[QuestionIds.IMPROVE_TRASH] = ["捨て札になる場のアクションカード1枚を廃棄してもよい。", "増築可能"];
JapaneseQuestionDescriptions[QuestionIds.IMPROVE_GAIN] = JapaneseQuestionDescriptions[QuestionIds.UPGRADE_GAIN];
JapaneseQuestionDescriptions[QuestionIds.HIDEOUT] = ["手札1枚を廃棄する。それが勝利点の場合、呪い1枚を獲得する。", "カード廃棄"];
JapaneseQuestionDescriptions[QuestionIds.MOUNTAIN_VILLAGE] = ["捨て札置き場から手札にカード1枚を加える。", "手札に加える"];
JapaneseQuestionDescriptions[QuestionIds.PRIEST] = JapaneseQuestionDescriptions[QuestionIds.REMODEL_TRASH];
JapaneseQuestionDescriptions[QuestionIds.RESEARCH_TRASH] = JapaneseQuestionDescriptions[QuestionIds.REMODEL_TRASH];
JapaneseQuestionDescriptions[QuestionIds.OLD_WITCH] = ["手札の呪い1枚を廃棄してもよい。", "呪い廃棄可能"];
JapaneseQuestionDescriptions[QuestionIds.RECRUITER] = JapaneseQuestionDescriptions[QuestionIds.REMODEL_TRASH];
JapaneseQuestionDescriptions[QuestionIds.SCEPTER_COMPLEX] = ["王笏の使用時に...", "再使用か、$か"];
JapaneseQuestionDescriptions[QuestionIds.SCEPTER_MODE] = ["効果を選ぶ。", "$を増やす"];
JapaneseQuestionDescriptions[QuestionIds.SCEPTER_PLAY] = ["場のアクションカード1枚を再使用する。", "アクションカード1枚を再使用"];
JapaneseQuestionDescriptions[QuestionIds.SCULPTOR] = JapaneseQuestionDescriptions[QuestionIds.COBBLER];
JapaneseQuestionDescriptions[QuestionIds.SEER] = ["それらを山札に好きな順番で戻す。", "山札戻し"];
JapaneseQuestionDescriptions[QuestionIds.VILLAIN] = ["コスト$2以上のカード1枚を捨て札にする。", "捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.TREASURER_COMPLEX] = ["会計人を使用するときに...", "会計人の使用"];
JapaneseQuestionDescriptions[QuestionIds.TREASURER_TRASH] = ["財宝カードを廃棄する。", "財宝カードの廃棄"];
JapaneseQuestionDescriptions[QuestionIds.TREASURER_GAIN] = ["手札に財宝カードを獲得する。", "財宝カードの獲得"];
JapaneseQuestionDescriptions[QuestionIds.TREASURER_KEY] = ["鍵を得る。", "鍵を得る"];
JapaneseQuestionDescriptions[QuestionIds.TREASURER_FAIL] = ["何もしない。", "何もしない"];
JapaneseQuestionDescriptions[QuestionIds.BORDER_GUARD_PUT_IN_HAND] = ["公開されたカードのうち1枚を手札に加える。", "手札に加える"];
JapaneseQuestionDescriptions[QuestionIds.BORDER_GUARD_MODE] = ["どのアーティファクトを得ますか。", "角笛か、ランタンか"];
JapaneseQuestionDescriptions[QuestionIds.HORN_TOPDECK] = ["山札の上に置く[Border Guard]を選択する。", "山札の上に置く[Border Guard]を選択する"];
JapaneseQuestionDescriptions[QuestionIds.CATHEDRAL] = ["手札1枚を廃棄する。", "カード廃棄"];
JapaneseQuestionDescriptions[QuestionIds.CITY_GATE] = ["手札1枚を山札の一番上に置く。", "山札の上に置く"];
JapaneseQuestionDescriptions[QuestionIds.PAGEANT] = ["$1払って+1財源を得てもよい。", "$1を払うか"];
JapaneseQuestionDescriptions[QuestionIds.SEWERS] = ["手札からAMOUNT0枚以下のカードを廃棄する。 (" + JapaneseCardNames[CardNames.SEWERS].singular + ")", "AMOUNT0枚以下を廃棄 (" + JapaneseCardNames[CardNames.SEWERS].singular + ")"];
JapaneseQuestionDescriptions[QuestionIds.SILOS] = ["銅貨を捨て札にし、同じ枚数のカードを引く。", "捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.CROP_ROTATION] = ["勝利点カードを捨て札にし、2枚カードを引いてもよい。", "捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.SINISTER_PLOT] = ["AMOUNT0枚のカードを引くか、トークンを追加する。", "AMOUNT0枚引くか、トークンを追加する"];
JapaneseQuestionDescriptions[QuestionIds.STAR_CHART] = ["シャッフル時に山札の一番上に置くカードを選ぶ。", "積み込むカードを選ぶ"];

JapaneseQuestionDescriptions[QuestionIds.SUMMON] = ["コスト$4以下のアクションカード1枚を獲得する。", "カード召喚"];
JapaneseQuestionDescriptions[QuestionIds.GOVERNOR_MODE] = ["総督の効果を選ぶ。", "総督の効果を選択"];
JapaneseQuestionDescriptions[QuestionIds.GOVERNOR_TRASH] = ["手札1枚を廃棄してもよい。", "カード1枚を廃棄可能"];
JapaneseQuestionDescriptions[QuestionIds.GOVERNOR_MAY_TRASH] = ["手札1枚を廃棄してもよい。", "カード1枚を廃棄可能"];
JapaneseQuestionDescriptions[QuestionIds.GOVERNOR_GAIN] = ["コストArgument0のカード1枚を獲得する。", "カード獲得"];
JapaneseQuestionDescriptions[QuestionIds.SAUNA_CHAIN] = ["手札のアヴァントを使用してもよい。", "アヴァントを使用可能"];
JapaneseQuestionDescriptions[QuestionIds.SAUNA_MAY_TRASH] = ["サウナの効果で手札1枚を廃棄してもよい。", "手札1枚を廃棄可能"];
JapaneseQuestionDescriptions[QuestionIds.AVANTO_CHAIN] = ["手札のサウナを使用してもよい。", "サウナを使用可能"];
JapaneseQuestionDescriptions[QuestionIds.BLACK_MARKET_COMPLEX] = ["闇市場の使用で...", "闇市場の使用中"];
JapaneseQuestionDescriptions[QuestionIds.BLACK_MARKET_BUY] = ["公開されたカード1枚を購入してもよい。", "購入可能"];
JapaneseQuestionDescriptions[QuestionIds.BLACK_MARKET_PLAY] = ["手札の財宝カードを使用してもよい。", "財宝を使用可能"];
JapaneseQuestionDescriptions[QuestionIds.ENVOY] = ["捨て札にさせるカードを選ぶ。", "捨て札にさせる"];
//JapaneseQuestionDescriptions[QuestionIds.PRINCE_MODE] = ["王子を脇に置いてもよい。", "王子を脇に置いてもよい"];
JapaneseQuestionDescriptions[QuestionIds.PRINCE_MAY_SET_ASIDE] = ["王子の脇に置いてもよい:", "王子の脇に置いてもよい"];
JapaneseQuestionDescriptions[QuestionIds.DISMANTLE_TRASH] = JapaneseQuestionDescriptions[QuestionIds.REMODEL_TRASH];
JapaneseQuestionDescriptions[QuestionIds.DISMANTLE_GAIN] = JapaneseQuestionDescriptions[QuestionIds.BORDER_VILLAGE];
JapaneseQuestionDescriptions[QuestionIds.CAPTAIN] = ["サプライから使用するコスト$4以下のアクションカードを選ぶ。", "使用するカードを選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.CHURCH_SET_ASIDE] = ["3枚まで脇に置く。", "3枚まで脇に置く"];
JapaneseQuestionDescriptions[QuestionIds.CHURCH_TRASH] = ["手札1枚を廃棄してもよい。", "カード廃棄可能"];
JapaneseQuestionDescriptions[QuestionIds.MARCHLAND] = ["", "手札を好きな枚数捨て札にし、1枚につき+$1"];

//JapaneseQuestionDescriptions[QuestionIds.WHAT_TO_IMPERSONATE] = ["どのカードを真似るか選ぶ。", "どのカードを真似るか選択"];

JapaneseQuestionDescriptions[QuestionIds.COURTIER_MODE] = ["次のうち異なるNUMBER0つを選ぶ: +1アクション、+1購入、+$3、金貨1枚を獲得。", "効果をNUMBER0つ選択"];

JapaneseQuestionDescriptions[QuestionIds.CAMEL_TRAIN] = ["サプライからカード1枚を追放する。", "カードを追放"];
JapaneseQuestionDescriptions[QuestionIds.GOATHERD] = ["手札1枚を廃棄してもよい。", "手札を廃棄可能"];
JapaneseQuestionDescriptions[QuestionIds.SNOWY_VILLAGE] = ["今のうちに村人トークンを使ってもよい。", "今のうちに村人トークンを使ってもよい"];
JapaneseQuestionDescriptions[QuestionIds.BOUNTY_HUNTER] = ["手札1枚を追放する。", "カードを追放"];
JapaneseQuestionDescriptions[QuestionIds.CARDINAL_EXILE] = ["カード1枚を追放する。", "カードを追放"];
JapaneseQuestionDescriptions[QuestionIds.GROOM] = JapaneseQuestionDescriptions[QuestionIds.REMODEL_GAIN];
JapaneseQuestionDescriptions[QuestionIds.HOSTELRY] = ["財宝カードを捨て札にして馬を獲得", "財宝カードを捨て札にして馬を獲得"];
JapaneseQuestionDescriptions[QuestionIds.VILLAGE_GREEN] = ["現在か、次のターンか", "現在か、次のターンか"];
JapaneseQuestionDescriptions[QuestionIds.BARGE] = ["現在か、次のターンか", "現在か、次のターンか"];
JapaneseQuestionDescriptions[QuestionIds.DISPLACE_EXILE] = ["手札1枚を追放する。", "カードを追放"];
JapaneseQuestionDescriptions[QuestionIds.DISPLACE_GAIN] = JapaneseQuestionDescriptions[QuestionIds.REMODEL_GAIN];
JapaneseQuestionDescriptions[QuestionIds.FALCONER] = ["カード1枚を手札に獲得する。", "カードを手札に獲得"];
JapaneseQuestionDescriptions[QuestionIds.HUNTING_LODGE] = ["手札を捨て札にしてカードを5枚引いてもよい。", "手札を捨て札にしてカードを5枚引いてもよい"];
JapaneseQuestionDescriptions[QuestionIds.MASTERMIND] = JapaneseQuestionDescriptions[QuestionIds.KINGS_COURT];
JapaneseQuestionDescriptions[QuestionIds.SANCTUARY] = ["手札1枚を追放してもよい。", "カードを追放可能"];
JapaneseQuestionDescriptions[QuestionIds.WAYFARER] = ["銀貨1枚を獲得してもよい。", "銀貨を獲得可能"];
JapaneseQuestionDescriptions[QuestionIds.DELAY] = ["アクションカード1枚を脇に置いてもよい。", "アクションカードを脇に置いてもよい"];
JapaneseQuestionDescriptions[QuestionIds.DESPERATION] = ["呪い1枚を獲得してもよい。", "呪いを獲得可能"];
JapaneseQuestionDescriptions[QuestionIds.GAMBLE] = ["Argument0を使用してもよい。", "Argument0を使用可能"];
JapaneseQuestionDescriptions[QuestionIds.PURSUE] = ["山札の上に戻すカードを指定する。", "山札の上に戻すカードを指定"];
JapaneseQuestionDescriptions[QuestionIds.TOIL] = ["手札のアクションカード1枚を使用してもよい。", "手札のアクションカードを使用可能"];
JapaneseQuestionDescriptions[QuestionIds.ENHANCE_TRASH] = ["手札から勝利点以外のカード1枚を廃棄してもよい。", "勝利点以外のカードを廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.ENHANCE_GAIN] = JapaneseQuestionDescriptions[QuestionIds.REMODEL_GAIN];
JapaneseQuestionDescriptions[QuestionIds.MARCH] = ["捨て札置き場からカード1枚を使用してもよい。", "捨て札置き場のアクションカードを使用可能"];
JapaneseQuestionDescriptions[QuestionIds.TRANSPORT_MAY_EXILE] = ["アクションカード1枚を追放してもよい。", "アクションカードを追放可能"];
JapaneseQuestionDescriptions[QuestionIds.TRANSPORT_EXILE] = ["アクションカード1枚を追放する。", "アクションカードを追放"];
JapaneseQuestionDescriptions[QuestionIds.TRANSPORT_MAY_TOPDECK] = ["追放マットのアクションカード1枚を山札の上に置いてもよい。", "追放マットのアクションカードを山札の上へ置いてもよい"];
JapaneseQuestionDescriptions[QuestionIds.TRANSPORT_TOPDECK] = ["追放したアクションカード1枚を山札の上に置く。", "追放マットのアクションカードを山札の上へ移動"];
JapaneseQuestionDescriptions[QuestionIds.TRANSPORT_COMPLEX] = ["サプライから追放するか、追放マットから山札の上に置く。", "サプライから追放するか、追放マットから山札の上へ移動"];
JapaneseQuestionDescriptions[QuestionIds.BANISH] = ["手札のカード1種類を好きな枚数追放する。", "カード1種類を好きな枚数追放"];
JapaneseQuestionDescriptions[QuestionIds.BARGAIN] = ["コスト$5以下の勝利点以外のカード1枚を獲得する。", "コスト$5以下の勝利点以外のカードを獲得"];
JapaneseQuestionDescriptions[QuestionIds.INVEST] = ["投資したいアクションカード1枚を追放する。", "投資したいアクションカードを追放"];
JapaneseQuestionDescriptions[QuestionIds.DEMAND] = ["コスト$4以下のカードを山札の上に獲得する。", "コスト4以下のカードを山札の上に獲得"];
JapaneseQuestionDescriptions[QuestionIds.POPULATE] = ["次に獲得するカードを選ぶ。", "次に獲得するカードを選択"];
JapaneseQuestionDescriptions[QuestionIds.RECONSIDER_WAY] = ["Argument0を使用するにあたって習性を再検討する。", "Argument0に習性を使うか再検討"];
JapaneseQuestionDescriptions[QuestionIds.CONSIDER_WAY] = ["Argument0を使用するにあたって習性を検討する。", "Argument0に習性を使うか検討"];
JapaneseQuestionDescriptions[QuestionIds.WAY_OF_THE_GOAT] = ["手札1枚を廃棄する。", "手札1枚を廃棄"];
JapaneseQuestionDescriptions[QuestionIds.WAY_OF_THE_RAT] = ["獲得したいカード1枚を捨て札にする。", "獲得したいカードを捨て札"];
JapaneseQuestionDescriptions[QuestionIds.SCRAP_TRASH] = ["手札1枚を廃棄する。", "手札1枚を廃棄"];
JapaneseQuestionDescriptions[QuestionIds.SCRAP_MODES] = ["効果をArgument0つ選ぶ。", "効果をArgument0つ選択"];
JapaneseQuestionDescriptions[QuestionIds.ANIMAL_FAIR_TRASH] = ["手札のアクションカード1枚を廃棄する。", "手札のアクションカードを廃棄"];
JapaneseQuestionDescriptions[QuestionIds.ANIMAL_FAIR_MAY_TRASH] = ["手札のアクションカード1枚を廃棄してもよい。", "手札のアクションカードを廃棄可能"];
JapaneseQuestionDescriptions[QuestionIds.BUTTERFLY_RETURN] = ["Argument0を由来する山に戻してもよい。", "Argument0を由来する山に戻してもよい"];
JapaneseQuestionDescriptions[QuestionIds.BUTTERFLY_GAIN] = JapaneseQuestionDescriptions[QuestionIds.UPGRADE_GAIN];

JapaneseQuestionDescriptions[QuestionIds.BLOCKADE] = ["", "カードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.PIRATE] = ["", "手札に獲得する"];
JapaneseQuestionDescriptions[QuestionIds.SAILOR] = ["", "手札1枚を廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.TIDE_POOLS] = JapaneseQuestionDescriptions[QuestionIds.DUNGEON];
JapaneseQuestionDescriptions[QuestionIds.SEA_WITCH] = JapaneseQuestionDescriptions[QuestionIds.DUNGEON];

JapaneseQuestionDescriptions[QuestionIds.TIARA_MODE] = ["", "ティアラの使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.TIARA_PLAY] = ["", "財宝カードを2回使用してもよい"];
JapaneseQuestionDescriptions[QuestionIds.CRYSTAL_BALL] = ["", "Argument0に対して実行するものを選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.WAR_CHEST_PROHIBIT] = ["", "獲得を禁ずる"];
JapaneseQuestionDescriptions[QuestionIds.WAR_CHEST_GAIN] = ["", "カードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.ANVIL_DISCARD] = JapaneseQuestionDescriptions[QuestionIds.STABLES];
JapaneseQuestionDescriptions[QuestionIds.ANVIL_GAIN] = JapaneseQuestionDescriptions[QuestionIds.WORKSHOP];
JapaneseQuestionDescriptions[QuestionIds.CLERK] = ["", "山札の上に置く"];
JapaneseQuestionDescriptions[QuestionIds.INVESTMENT_TRASH] = JapaneseQuestionDescriptions[QuestionIds.REPLACE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.INVESTMENT_MODE] = ["", "出資の使い方を選ぶ"];

// Hinterlands 2E
JapaneseQuestionDescriptions[QuestionIds.WHEELWRIGHT_DISCARD] = ["", "捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.WHEELWRIGHT_GAIN] = ["", "コストAMOUNT0以下のカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.BERSERKER_DISCARD] = JapaneseQuestionDescriptions[QuestionIds.MILITIA];
JapaneseQuestionDescriptions[QuestionIds.BERSERKER_GAIN] = ["", "カード獲得"];
JapaneseQuestionDescriptions[QuestionIds.WEAVER] = ["", "カード1枚か銀貨2枚を獲得する"];
JapaneseQuestionDescriptions[QuestionIds.SOUK] = ["", "手札を2枚まで廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.WITCHS_HUT] = ["", "手札2枚を公開して捨て札にする"];

// Allies
JapaneseQuestionDescriptions[QuestionIds.TOWN_MODE] = ["", "町の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.MODIFY_TRASH] = ["", "カードを廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.MODIFY_MODE] = ["", "改造の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.MODIFY_GAIN] = ["", "カードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.SWAP_RETURN] = ["", "カードを山に戻す"];
JapaneseQuestionDescriptions[QuestionIds.SWAP_GAIN] = ["", "アクションカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.SKIRMISHER] = ["", "手札を3枚まで捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.WOODWORKERS_GUILD_TRASH] = ["", "アクションカードを廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.WOODWORKERS_GUILD_GAIN] = ["", "アクションカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.CRAFTERS_GUILD] = ["", "好意を支払ってカードを獲得してもよい"];
JapaneseQuestionDescriptions[QuestionIds.CAVE_DWELLERS] = ["", "好意を支払って手札1枚を捨て札にし1枚カードを引いてもよい"];
JapaneseQuestionDescriptions[QuestionIds.DESERT_GUIDES] = ["", "好意を支払って手札を作り直してもよい"];
JapaneseQuestionDescriptions[QuestionIds.BAND_OF_NOMADS] = ["", "購入のためにさらに好意を支払ってもよい"];
JapaneseQuestionDescriptions[QuestionIds.SYCOPHANT] = ["", "手札3枚を捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.IMPORTER] = ["", "コスト$5以下のカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.CONTRACT] = ["", "アクションカードを次のターンに使用してもよい"];
JapaneseQuestionDescriptions[QuestionIds.BAUBLE] = ["", "道化棒の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.BROKER_TRASH] = ["", "カードを廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.BROKER_MODE] = ["", "得る効果を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.MARKET_TOWNS_FIRST] = ["", "使用するアクションカードを選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.MARKET_TOWNS_AGAIN] = ["", "もっと使用する"];
JapaneseQuestionDescriptions[QuestionIds.BATTLE_PLAN_REVEAL] = ["", "アタックカードを公開して1枚カードを引いてもよい"];
JapaneseQuestionDescriptions[QuestionIds.BATTLE_PLAN_ROTATE] = ["", "サプライの山を循環させてもよい"];
JapaneseQuestionDescriptions[QuestionIds.BARBARIAN] = ["", "より安いカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.INNKEEPER_MODE] = ["", "宿屋の主人の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.INNKEEPER_DISCARD] = ["", "手札Argument0枚を捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.CAPITAL_CITY_MODE] = ["", "首都の追加効果を処理する"];
JapaneseQuestionDescriptions[QuestionIds.CAPITAL_CITY_DISCARD] = ["", "手札2枚を捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.SPECIALIST_PLAY] = ["", "使用するカードを選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.SPECIALIST_MODE] = ["", "Argument0を再度使用するか、獲得する"];
JapaneseQuestionDescriptions[QuestionIds.GANG_OF_PICKPOCKETS] = ["", "1好意を支払う、さもなくば手札1枚を捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.COASTAL_HAVEN] = ["", "手札に保留するカードを選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.CARPENTER_GAIN] = JapaneseQuestionDescriptions[QuestionIds.WORKSHOP];
JapaneseQuestionDescriptions[QuestionIds.CARPENTER_TRASH] = JapaneseQuestionDescriptions[QuestionIds.REMODEL_TRASH];
JapaneseQuestionDescriptions[QuestionIds.CARPENTER_GAIN_UP_TO] = JapaneseQuestionDescriptions[QuestionIds.REMODEL_GAIN];
JapaneseQuestionDescriptions[QuestionIds.MAY_ROTATE_FORTS] = ["", "城砦の山を循環させてもよい"];
JapaneseQuestionDescriptions[QuestionIds.ROYAL_GALLEY] = ["", "アクションカードを使用してもよい"];
JapaneseQuestionDescriptions[QuestionIds.HILL_FORT_GAIN] = JapaneseQuestionDescriptions[QuestionIds.WORKSHOP];
JapaneseQuestionDescriptions[QuestionIds.HILL_FORT_MODE] = ["", "堡塁の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.STRONGHOLD_MODE] = ["", "要塞の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.MAY_ROTATE_ODYSSEYS] = ["", "叙事詩の山を循環させてもよい"];
JapaneseQuestionDescriptions[QuestionIds.OLD_MAP_DISCARD] = ["", "手札1枚を捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.SUNKEN_TREASURE] = ["", "場に出していないアクションカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.PEACEFUL_CULT] = ["", "好意を支払ってカードを廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.ORDER_OF_ASTROLOGERS] = ["", "好意を支払ってカードを積み込んでもよい"];
JapaneseQuestionDescriptions[QuestionIds.STAR_CHART_ASTROLOGERS_COMBINED] = ["", "好意を支払ってカードを積み込んでもよい(星図の分を含む)"];
JapaneseQuestionDescriptions[QuestionIds.ORDER_OF_MASONS] = ["", "好意を支払って、1好意につき2枚捨て札に残してもよい"];
JapaneseQuestionDescriptions[QuestionIds.ARCHER_HIDE] = ["", "公開しないカードを選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.ARCHER_DISCARD] = JapaneseQuestionDescriptions[QuestionIds.PILLAGE];
JapaneseQuestionDescriptions[QuestionIds.MARQUIS] = ["", "手札が10枚になるように捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.HERB_GATHERER_PLAY] = ["", "使用する財宝カードを選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.MAY_ROTATE_AUGURS] = ["", "卜占官の山を循環させてもよい"];
JapaneseQuestionDescriptions[QuestionIds.ACOLYTE] = ["", "カードを廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.ACOLYTE_SELF] = ["", "侍祭を廃棄して卜占官カードを獲得してもよい"];
JapaneseQuestionDescriptions[QuestionIds.SORCERESS] = ["", "カードを予想する"];
JapaneseQuestionDescriptions[QuestionIds.SIBYL_TOPDECK] = ["", "山札の上に置く"];
JapaneseQuestionDescriptions[QuestionIds.SIBYL_BOTTOMDECK] = ["", "山札の底に置く"];
JapaneseQuestionDescriptions[QuestionIds.MAY_ROTATE_WIZARDS] = ["", "魔法使いの山を循環させてもよい"];
JapaneseQuestionDescriptions[QuestionIds.STUDENT] = ["", "カードを廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.CONJURER] = JapaneseQuestionDescriptions[QuestionIds.WORKSHOP];
JapaneseQuestionDescriptions[QuestionIds.SORCERER] = ["", "カードを予想する"];
JapaneseQuestionDescriptions[QuestionIds.LICH] = ["", "廃棄置き場からカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.MAY_ROTATE_TOWNSFOLK] = ["", "町民の山を循環させてもよい"];
JapaneseQuestionDescriptions[QuestionIds.TOWN_CRIER] = ["", "触れ役の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.BLACKSMITH] = ["", "蹄鉄工の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.MILLER] = ["", "粉屋の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.ELDER] = ["", "カードを使用してもよい"];
JapaneseQuestionDescriptions[QuestionIds.SENTINEL_TRASH] = ["", "2枚まで廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.SENTINEL_TOPDECK] = JapaneseQuestionDescriptions[QuestionIds.APOTHECARY];
JapaneseQuestionDescriptions[QuestionIds.COURIER] = ["", "カードを使用してもよい"];
JapaneseQuestionDescriptions[QuestionIds.HUNTER_ACTION] = ["", "アクションカードを手札に加える"];
JapaneseQuestionDescriptions[QuestionIds.HUNTER_TREASURE] = ["", "財宝カードを手札に加える"];
JapaneseQuestionDescriptions[QuestionIds.HUNTER_VICTORY] = ["", "勝利点カードを手札に加える"];
JapaneseQuestionDescriptions[QuestionIds.LURKER_MODE] = ["", "待ち伏せの使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.GRAVEROBBER_MODE] = ["", "墓暴きの使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.TREASURER_MODE] = ["", "出納官の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.ARCHITECTS_GUILD] = ["", "カードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.FAMILY_OF_INVENTORS] = ["", "コストを安くする山を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.FOREST_DWELLERS_DISCARD] = ["", "カードを捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.FOREST_DWELLERS_TOPDECK] = ["", "戻す順番を決める"];
JapaneseQuestionDescriptions[QuestionIds.USE_FAVOR_WHEN_SHUFFLING] = ["", "シャッフルするとき好意を使うか選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS] = ["", "財源を追加で支払ってもよい"];
JapaneseQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_BASILICA] = ["", "公会堂のために財源を追加で支払ってもよい"];
JapaneseQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_SWASHBUCKLER] = ["", "財源を追加で支払い宝箱を断ってもよい"];
JapaneseQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_FORTUNE] = ["", "2倍にする前に財源を追加で支払ってもよい"];

JapaneseQuestionDescriptions[QuestionIds.WHEELWRIGHT_DISCARD] = ["", "捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.WHEELWRIGHT_GAIN] = ["", "コストAMOUNT0以下のカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.BERSERKER_DISCARD] = JapaneseQuestionDescriptions[QuestionIds.MILITIA];
JapaneseQuestionDescriptions[QuestionIds.BERSERKER_GAIN] = ["", "カード獲得"];
JapaneseQuestionDescriptions[QuestionIds.WEAVER] = ["", "カード1枚か銀貨2枚を獲得する"];
JapaneseQuestionDescriptions[QuestionIds.SOUK] = ["", "手札を2枚まで廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.WITCHS_HUT] = ["", "手札2枚を公開して捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.STONEMASON_OVERPAY_AMOUNT] = ["AMOUNT0以下のアクションカード1枚を獲得することで過払いする。", "過払いする"];

//Plunder
JapaneseQuestionDescriptions[QuestionIds.CAGE_SET_ASIDE] = ["手札を最大4枚脇に置いてもよい。", "手札を最大4枚脇に置いてもよい"];
JapaneseQuestionDescriptions[QuestionIds.GROTTO_SET_ASIDE] = JapaneseQuestionDescriptions[QuestionIds.CAGE_SET_ASIDE];
JapaneseQuestionDescriptions[QuestionIds.SHAMAN_GAIN] = ["コスト$6以下のカードを廃棄置き場から獲得する。", "コスト$6以下のカードを廃棄置き場から獲得する"];
JapaneseQuestionDescriptions[QuestionIds.SHAMAN_TRASH] = ["手札1枚を廃棄してもよい。", "手札1枚を廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.SECLUDED_SHRINE] = ["手札を最大2枚廃棄してもよい。", "手札を最大2枚廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.SIREN] = ["手札のアクションカードを廃棄してもよい。", "手札のアクションカードを廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.CABIN_BOY_MODE] = ["+$2か、持続カードを獲得するために[Cabin Boy]を廃棄するか選ぶ。", "[Cabin Boy]の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.CABIN_BOY_GAIN] = ["持続カード1枚を獲得する。", "持続カード1枚を獲得する"];
JapaneseQuestionDescriptions[QuestionIds.CRUCIBLE] = ["手札1枚を廃棄する。", "手札1枚を廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.FORTUNE_HUNTER_PLAY] = ["公開した財宝カード1枚を使用する。", "公開した財宝カード1枚を使用する"];
JapaneseQuestionDescriptions[QuestionIds.FORTUNE_HUNTER_TOPDECK] = ["山札に戻す。", "山札に戻す"];
JapaneseQuestionDescriptions[QuestionIds.GONDOLA_MODE] = ["+$2を現在得るか、後で得るか選ぶ。", "+$2をいつ得るか選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.GONDOLA_PLAY] = ["手札のアクションカード1枚を使用してもよい。", "手札のアクションカードを使用してもよい"];
JapaneseQuestionDescriptions[QuestionIds.MAPMAKER] = ["カード2枚を手札に加える。", "カード2枚を手札に加える"];
JapaneseQuestionDescriptions[QuestionIds.MAROON] = ["手札1枚を廃棄する。", "手札1枚を廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.ROPE_TRASH] = ["手札1枚を廃棄してもよい。", "手札1枚を廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.TOOLS] = ["誰かの場にあるカード1枚を獲得する。", "誰かの場にあるカード1枚を獲得する"];
JapaneseQuestionDescriptions[QuestionIds.CUTTHROAT_DISCARD] = ["手札が3枚になるように捨て札にする。", "手札が3枚になるように捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.ENLARGE_TRASH] = ["手札1枚を廃棄する。", "手札1枚を廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.ENLARGE_GAIN] = ["コスト$AMOUNT0以下のカード1枚を獲得する。", "コスト$AMOUNT0以下のカード1枚を獲得する"];
JapaneseQuestionDescriptions[QuestionIds.FIGURINE] = ["+$1と+1購入のためにアクションカードを捨て札にしてもよい。", "アクションカードを捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.FIRST_MATE] = ["アクションカードを使用してもよい。", "アクションカードを使用してもよい"];
JapaneseQuestionDescriptions[QuestionIds.FRIGATE] = ["手札が4枚になるように捨て札にする。", "手札が4枚になるように捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.PICKAXE] = ["手札1枚を廃棄する。", "手札1枚を廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.PILGRIM_TOPDECK] = ["手札1枚を山札の上に置く。", "手札1枚を山札の上に置く"];
JapaneseQuestionDescriptions[QuestionIds.QUARTERMASTER_MODE] = ["コスト$4以下のカード1枚を脇に獲得するか、Argument0のうち1枚を手札に加える。", "コスト$4以下のカード1枚を脇に獲得するか、Argument0のうち1枚を手札に加える"];
JapaneseQuestionDescriptions[QuestionIds.QUARTERMASTER_GAIN] = ["コスト$4以下のカードを獲得する。", "コスト$4以下のカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.QUARTERMASTER_TAKE] = ["カード1枚を手札に加える。", "カード1枚を手札に加える"];
JapaneseQuestionDescriptions[QuestionIds.TRICKSTER] = ["場から捨て札にする財宝1枚を脇に置いてもよい。", "場から捨て札にする財宝1枚を脇に置いてもよい"];
JapaneseQuestionDescriptions[QuestionIds.SILVER_MINE] = ["財宝1枚を手札に獲得する。", "財宝1枚を手札に獲得する"];
JapaneseQuestionDescriptions[QuestionIds.KINGS_CACHE] = ["財宝カードを3回使用してもよい。", "財宝カードを3回使用してもよい"];
JapaneseQuestionDescriptions[QuestionIds.BURY] = ["カードを山札の一番下に置く。", "カードを山札の一番下に置く"];
JapaneseQuestionDescriptions[QuestionIds.PERIL] = ["戦利品を獲得するためにアクションカードを廃棄してもよい。", "戦利品を獲得するためにアクションカードを廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.PREPARE] = ["脇に置いたカードを使用する。", "脇に置いたカードを使用する"];
JapaneseQuestionDescriptions[QuestionIds.FORAY] = ["手札3枚を捨て札にする。", "手札3枚を捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.SCROUNGE_COMPLEX] = ["[Scrounge]の使い方を選ぶ。", "[Scrounge]の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.SCROUNGE_TRASH] = ["手札1枚を廃棄する。", "手札1枚を廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.SCROUNGE_MAY_TRASH] = ["手札1枚を廃棄してもよい。", "手札1枚を廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.SCROUNGE_GAIN_ESTATE] = ["屋敷を獲得する。", "屋敷を獲得する"];
JapaneseQuestionDescriptions[QuestionIds.SCROUNGE_MAY_GAIN_ESTATE] = ["屋敷を獲得してもよい。", "屋敷を獲得してもよい"];
JapaneseQuestionDescriptions[QuestionIds.SCROUNGE_GAIN] = ["コスト$5以下のカード1枚を獲得する。", "コスト$5以下のカード1枚を獲得する"];
JapaneseQuestionDescriptions[QuestionIds.MAELSTROM_TRASH] = ["手札1枚を廃棄する。", "手札1枚を廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.MAELSTROM_OPPONENT_TRASH] = ["手札Argument0枚を廃棄する。", "手札Argument0枚を廃棄する"];
JapaneseQuestionDescriptions[QuestionIds.INVASION_PLAY] = ["アタックカードを使用してもよい。", "アタックカードを使用してもよい"];
JapaneseQuestionDescriptions[QuestionIds.INVASION_GAIN] = ["アクションカード1枚を獲得する。", "アクションカード1枚を獲得する"];
JapaneseQuestionDescriptions[QuestionIds.PROSPER] = ["名前の異なる財宝カードを獲得してもよい。", "財宝を獲得してもよい"];
JapaneseQuestionDescriptions[QuestionIds.AMPHORA_MODE] = ["+1購入と+$3を現在得るか次のターンに得るか選ぶ。", "現在か、次のターンか"];
JapaneseQuestionDescriptions[QuestionIds.HAMMER] = ["コスト$4以下のカード1枚を獲得する。", "コスト$4以下のカード1枚を獲得する"];
JapaneseQuestionDescriptions[QuestionIds.ORB] = ["アクションか財宝を使用する。", "アクションか財宝を使用する"];
JapaneseQuestionDescriptions[QuestionIds.PRIZE_GOAT] = ["手札1枚を廃棄してもよい。", "手札1枚を廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.PUZZLE_BOX] = ["カード1枚を脇に置き、クリーンアップ終了後(手札を作った後)に手札に加える。", "手札1枚を脇に置いてもよい"];
JapaneseQuestionDescriptions[QuestionIds.SEXTANT_DISCARD] = ["好きな枚数のカードを捨て札にする。", "捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.SEXTANT_TOPDECK] = ["好きな順番で山札の上に戻す。", "好きな順番で山札の上に戻す"];
JapaneseQuestionDescriptions[QuestionIds.SPELL_SCROLL_GAIN] = ["コストAMOUNT0以下のカードを獲得する。", "コストAMOUNT0以下のカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.SPELL_SCROLL_PLAY] = ["Argument0を使用してもよい。", "Argument0を使用してもよい"];
JapaneseQuestionDescriptions[QuestionIds.STAFF] = ["アクションカード1枚を使用してもよい。", "アクションカード1枚を使用してもよい"];
JapaneseQuestionDescriptions[QuestionIds.SWORD] = ["手札が4枚になるように捨て札にする。", "手札が4枚になるように捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.FRIENDLY] = ["[Friendly]カード1枚を捨て札にしてもよい。", "[Friendly]カード1枚を捨て札にしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.INHERITED] = ["ゲーム開始時のカード1枚を[Inherited]カードと入れ替える。", "ゲーム開始時のカード1枚を[Inherited]カードと入れ替える"];
JapaneseQuestionDescriptions[QuestionIds.INSPIRING] = ["場に出していないカード1枚を使用してもよい([Inspiring]特性)。", "場に出していないカード1枚を使用してもよい([Inspiring]特性)"];
JapaneseQuestionDescriptions[QuestionIds.PATIENT_SET_ASIDE] = ["[Patient]カードを脇に置いてもよい。", "[Patient]カードを脇に置いてもよい"];
JapaneseQuestionDescriptions[QuestionIds.PATIENT_PLAY] = ["次に使用する[Patient]カードを選ぶ。", "次に使用する[Patient]カードを選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.PIOUS_TRASH] = ["手札1枚を廃棄してもよい。", "手札1枚を廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.SHY] = ["[Shy]カード1枚を捨て札にする", "[Shy]カード1枚を捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.FATED_TOPDECK] = ["[Fated]カードを一番上にしてもよい。", "[Fated]カードを一番上に置いてもよい"];
JapaneseQuestionDescriptions[QuestionIds.FATED_BOTTOMDECK] = ["[Fated]カードを一番下にしてもよい。", "[Fated]カードを一番下に置いてもよい"];
JapaneseQuestionDescriptions[QuestionIds.AVOID] = ["最大3枚捨て札置き場に置いてもよい。", "最大3枚捨て札置き場に置いてもよい"];
JapaneseQuestionDescriptions[QuestionIds.TRICKSTER_DISCARD] = ["脇に置きたい財宝カードを選ぶ。", "脇に置きたい財宝カードを選ぶ"];

JapaneseQuestionDescriptions[QuestionIds.FARMHANDS] = ["次のターン開始時に使用するカードを選択する。", "次のターン開始時に使用するカードを選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.COURSER] = ["[Courser]の効果を選ぶ", "[Courser]の効果を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.CORONET_ACTION] = JapaneseQuestionDescriptions[QuestionIds.THRONE_ROOM];
JapaneseQuestionDescriptions[QuestionIds.CORONET_TREASURE] = JapaneseQuestionDescriptions[QuestionIds.CROWN_TREASURE];
JapaneseQuestionDescriptions[QuestionIds.JOUST_SET_ASIDE] = ["属州を脇に置いてもよい。", "属州を脇に置いてもよい"];
JapaneseQuestionDescriptions[QuestionIds.JOUST_GAIN_REWARD] = ["恩賞を手札に獲得する", "恩賞を手札に獲得する"];
JapaneseQuestionDescriptions[QuestionIds.INFIRMARY_TRASH] = JapaneseQuestionDescriptions[QuestionIds.MASQUERADE_TRASH];
JapaneseQuestionDescriptions[QuestionIds.INFIRMARY_OVERPAY] = ["[Infirmary]に過払いしてもよい。", "過払いしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.FOOTPAD] = JapaneseQuestionDescriptions[QuestionIds.MILITIA];
JapaneseQuestionDescriptions[QuestionIds.FARRIER_OVERPAY] = ["[Farrier]に過払いしてもよい。", "過払いしてもよい"];
JapaneseQuestionDescriptions[QuestionIds.SHOP] = JapaneseQuestionDescriptions[QuestionIds.CONCLAVE];
JapaneseQuestionDescriptions[QuestionIds.FERRYMAN] = JapaneseQuestionDescriptions[QuestionIds.FUGITIVE];

JapaneseQuestionDescriptions[QuestionIds.ORDER_BOTTOMDECKED_CARDS] = ["山札の底の順番を選ぶ", "山札の底の順番を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.GROWTH] = JapaneseQuestionDescriptions[QuestionIds.BORDER_VILLAGE];
JapaneseQuestionDescriptions[QuestionIds.KIND_EMPEROR] = ["アクションカードを手札に獲得する", "アクションカードを手札に獲得"];
JapaneseQuestionDescriptions[QuestionIds.SICKNESS_MODE] = ["", ""];
JapaneseQuestionDescriptions[QuestionIds.SICKNESS_DISCARD] = ["山札に呪いを獲得するか、捨て札にする。", "山札に[Curse]を獲得するか、捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.SICKNESS] = ["3枚捨て札にするか、呪いを山札の上に獲得する。", "3枚捨て札にするか、[Curse]を山札の上に獲得"];
JapaneseQuestionDescriptions[QuestionIds.SNAKE_WITCH] = ["[Snake Witch]を山に戻し呪ってもよい", "[Snake Witch]を山に戻し他のプレイヤーに[Curse]を配ってもよい"];
JapaneseQuestionDescriptions[QuestionIds.CRAFTSMAN] = ["コスト$5のカードを獲得する。", "コスト$5のカードを獲得"];
JapaneseQuestionDescriptions[QuestionIds.ALLEY] = JapaneseQuestionDescriptions[QuestionIds.OASIS];
JapaneseQuestionDescriptions[QuestionIds.CHANGE_TRASH] = ["手札1枚を廃棄してもよい。", "カード廃棄可能"];
JapaneseQuestionDescriptions[QuestionIds.CHANGE_GAIN] = ["カード1枚を獲得し負債を負う。", "カード1枚を獲得し負債を負う"];
JapaneseQuestionDescriptions[QuestionIds.NINJA] = JapaneseQuestionDescriptions[QuestionIds.MILITIA];
JapaneseQuestionDescriptions[QuestionIds.RIVER_SHRINE_TRASH] = ["カードを2枚まで廃棄してもよい。", "カードを最大2枚廃棄可能"];
JapaneseQuestionDescriptions[QuestionIds.RIVER_SHRINE_GAIN] = JapaneseQuestionDescriptions[QuestionIds.WORKSHOP];
JapaneseQuestionDescriptions[QuestionIds.RUSTIC_VILLAGE_CHOICE] = ["1枚カードを引くために手札2枚を捨て札にする。", "1枚カードを引くために手札2枚を捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.RUSTIC_VILLAGE_DISCARD] = ["2枚捨て札にする。", "2枚捨て札にする"];
JapaneseQuestionDescriptions[QuestionIds.GOLD_MINE] = ["金貨1枚を獲得し、4負債を負う。", "金貨獲得、4負債"];
JapaneseQuestionDescriptions[QuestionIds.KITSUNE] = ["[Kitsune]の使い方を選ぶ", "[Kitsune]の使い方を選ぶ"];
JapaneseQuestionDescriptions[QuestionIds.TANUKI_TRASH] = JapaneseQuestionDescriptions[QuestionIds.REMODEL_TRASH];
JapaneseQuestionDescriptions[QuestionIds.TANUKI_GAIN] = JapaneseQuestionDescriptions[QuestionIds.REMODEL_GAIN];
JapaneseQuestionDescriptions[QuestionIds.SAMURAI] = JapaneseQuestionDescriptions[QuestionIds.MILITIA];
JapaneseQuestionDescriptions[QuestionIds.MOUNTAIN_SHRINE] = ["手札1枚を廃棄してもよい。", "手札1枚を廃棄可能"];
JapaneseQuestionDescriptions[QuestionIds.AMASS] = ["コスト$5以下のアクションカード1枚を獲得する。", "コスト$5以下のアクションカードを獲得する"];
JapaneseQuestionDescriptions[QuestionIds.ASCETICISM] = ["最大Argument0枚のカードを廃棄してよい。", "Argument0枚以下のカードを廃棄可能"];
JapaneseQuestionDescriptions[QuestionIds.CONTINUE] = ["アタック以外のアクションカード1枚を獲得し使用する。", "アタック以外のアクションカードを獲得し使用する"];
JapaneseQuestionDescriptions[QuestionIds.CREDIT] = ["コスト$8以下のアクションまたは財宝カード1枚を獲得する。", "コスト$8以下のアクションまたは財宝カードを獲得"];
JapaneseQuestionDescriptions[QuestionIds.KINTSUGI_TRASH] = JapaneseQuestionDescriptions[QuestionIds.MOUNTAIN_SHRINE];
JapaneseQuestionDescriptions[QuestionIds.KINTSUGI_GAIN] = JapaneseQuestionDescriptions[QuestionIds.REMODEL_GAIN];
JapaneseQuestionDescriptions[QuestionIds.PRACTICE] = JapaneseQuestionDescriptions[QuestionIds.THRONE_ROOM];
JapaneseQuestionDescriptions[QuestionIds.SEA_TRADE] = ["最大Argument0枚廃棄してもよい。", "最大Argument0枚廃棄してもよい"];
JapaneseQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_FIRST] = ["場に出していないアクションカードをあと3種獲得してもよい。", "場に出していないアクションカードをあと3種獲得してもよい"];
JapaneseQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_SECOND] = ["場に出していないアクションカードをあと2種獲得してもよい。", "場に出していないアクションカードをあと2種獲得してもよい"];
JapaneseQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_THIRD] = ["場に出していないアクションカードをあと1種獲得してもよい。", "場に出していないアクションカードをあと1種獲得してもよい"];
JapaneseQuestionDescriptions[QuestionIds.GATHER] = ["コストArgument0のカードを獲得する", "コストArgument0のカードを獲得"];

JapaneseQuestionDescriptions[QuestionIds.GAME_PAY_OFF_DEBT] = ["負債を返済してもよい。", "負債を返済してもよい"];
JapaneseQuestionDescriptions[QuestionIds.MAY_PAY_OFF_EXTRA_DEBT] = ["負債を返済してもよい。", "負債を返済してもよい"];
JapaneseQuestionDescriptions[QuestionIds.PAY_OFF_DEBT_BEFORE_CHANGE] = ["先に負債を返済してもよい。", "先に負債を返済してもよい"]; //to remodel
JapaneseQuestionDescriptions[QuestionIds.PAY_OFF_DEBT_BEFORE_STORYTELLER] = ["先に負債を返済してもよい。カードを引く枚数が少なくなる。", "先に負債を返済してもよい。カードを引く枚数が少なくなる"];