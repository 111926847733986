"use strict";

webclient.component("userPrefAutoplay", {
    bindings: {
        pref: "="
    },
    controllerAs: "ctrl",
    controller: ['autoplayService', 'gameBroadcaster', function (autoplayService, gameBroadcaster) {

        var controller = this;

        autoplayService.init('user', undefined);

        controller.cardNames = AutoPlay.cardNames().sort();
        controller.cssClass = function (cardName) {
            autoplayService.setCardName(cardName);return autoplayService.cssClass();
        };
        controller.statusText = function (cardName) {
            autoplayService.setCardName(cardName);return autoplayService.statusText();
        };
        controller.specificHelpLink = function (cardName) {
            autoplayService.setCardName(cardName);return autoplayService.specificHelpLink();
        };
        controller.genericHelpLink = function () {
            return autoplayService.genericHelpLink();
        };
        controller.genericLinkText = function () {
            return autoplayService.genericLinkText();
        };
        controller.name = function (cardName) {
            autoplayService.setCardName(cardName);return autoplayService.name();
        };
        controller.toggle = function (cardName) {
            autoplayService.setCardName(cardName);autoplayService.toggle();
        };
        controller.groupTitle = function (groupLabel) {
            return getPhrase(groupLabel);
        };

        controller.setPredefinedSettings = function (settingsName) {
            return autoplayService.setPredefinedSettings(settingsName);
        };
        controller.namesPredefinedSettings = Object.keys(autoplayService.predefinedSettings);
        controller.translatedSettingsName = function (settingsName) {
            return LANGUAGE.getAutoplayLabels[settingsName];
        };
        controller.groups = AutoPlaySettings.groups();
        controller.groupLabels = Object.keys(controller.groups);

        controller.group = function (groupLabel) {

            var cardNames = controller.groups[groupLabel].map(function (autoplaySetting) {
                return autoplaySetting.cardName;
            });

            return cardNames;
        };
    }],
    template: "\n    <div class=\"user-pref-button-row\" ng-show=\"!$ctrl.replayService.shouldShowReplayWindow()\">\n        <button class=\"oval-button tab-button\" ng-click=\"ctrl.setPredefinedSettings(settingsName)\" ng-repeat=\"settingsName in ctrl.namesPredefinedSettings\">\n            {{ctrl.translatedSettingsName(settingsName)}}\n        </button>\n    </div>\n    <div class=\"user-pref-autoplay-groups\">\n        <div class=\"fieldset-div autoplay-group\" ng-repeat=\"groupLabel in ctrl.groupLabels\">\n            <div class=\"fieldset-title\">{{ctrl.groupTitle(groupLabel)}}</div>\n            <div class=\"autoplay-fieldset-content\">\n                <div class=\"autoplay-card-row\" ng-repeat=\"cardName in ctrl.group(groupLabel)\">\n                    <div class=\"autoplay-card\" ng-class=\"{'landscape-card': cardName.isLandscape()}\">\n                        <anonymous-card card-name=\"cardName\"></anonymous-card>\n                    </div>\n                    <a class=\"autoplay-documentation-button\" href=\"{{ctrl.specificHelpLink(cardName)}}\" target=\"autoplay-help\"></a>\n                    <div class=\"autoplay-description oval-button{{ctrl.cssClass(cardName)}}\" ng-click=\"ctrl.toggle(cardName)\"> {{ctrl.statusText(cardName)}} </div>\n                </div>\n            </div>\n        </div>\n    </div>\n    "
});