"use strict";

webclient.directive('kingdomSplitStack', ['gameBroadcaster', KingdomSplitStack]);

function KingdomSplitStack(gameBroadcaster) {
    return {
        restrict: 'AE',
        scope: {
            topCard: '=',
            bottomCard: '='
        },
        template: '\n<div class="kingdom-viewer-split-card-container">\n    <div class="kingdom-viewer-split-bottom-card">\n        <anonymous-card card-name="bottomCard"></anonymous-card>\n    </div>\n    <div class="kingdom-viewer-split-top-card">\n        <anonymous-card card-name="topCard"></anonymous-card>\n    </div>\n</div>',
        replace: true
    };
}