"use strict";

var EsperantoQuestionDescriptions = {};

EsperantoQuestionDescriptions[QuestionIds.GAME_RESOLVE_ABILITY] = ["Resolve a forced ability:", "Solvu efikon"];
EsperantoQuestionDescriptions[QuestionIds.GAME_MAY_RESOLVE_ABILITY] = ["Resolve an optional ability.", "Vi povas solvi efikon"];
EsperantoQuestionDescriptions[QuestionIds.GAME_MAY_REACT_WITH] = ["React with something.", "Ĉu vi volas reagi?"];
EsperantoQuestionDescriptions[QuestionIds.GAME_TURN_SELECT] = ["Select a turn.", "Kia vico poste?"];
EsperantoQuestionDescriptions[QuestionIds.GAME_MAY_BUY] = ["buy cards.", "Vi povas aĉeti kartojn"];
EsperantoQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING] = ["start buying cards.", "Vi povas aĉeti kartojn"];
EsperantoQuestionDescriptions[QuestionIds.GAME_MAY_BUY_NONCARDS] = ["buy, but not cards.", "Vi povas aĉeti, sed ne kartojn"];
EsperantoQuestionDescriptions[QuestionIds.GAME_MAY_START_BUYING_NONCARDS] = ["start buying, but not cards.", "Vi povas aĉeti, sed ne kartojn"];
EsperantoQuestionDescriptions[QuestionIds.GAME_REPAY_HOW_MUCH] = ["Repay debt.", "Vi povas forpagi ŝuldon"];
EsperantoQuestionDescriptions[QuestionIds.GAME_SPEND_COIN_TOKENS] = ["spend coffers tokens.", "Vi povas elspezi monrezervjetonojn"];
EsperantoQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_ACTION] = ["play an Action from your hand.", "Vi povas ludi Agkartojn"];
EsperantoQuestionDescriptions[QuestionIds.GAME_MAY_PLAY_TREASURES] = ["play treasures.", "Vi povas ludi Trezorkartojn"];
EsperantoQuestionDescriptions[QuestionIds.GAME_PLAY_ALL_TREASURES] = ["autoplay treasures.", "GAME_PLAY_ALL_TREASURES"];
EsperantoQuestionDescriptions[QuestionIds.GAME_ACTION_PHASE] = ["It's your action phase", "Vi povas ludi Agkartojn"];
EsperantoQuestionDescriptions[QuestionIds.GAME_BUY_PHASE] = ["It's your buy phase", "Vi povas aĉeti kartojn"];
EsperantoQuestionDescriptions[QuestionIds.GAME_BUY_PHASE_NONCARDS] = ["It's your buy phase", "Vi povas aĉeti, sed ne kartojn"];
EsperantoQuestionDescriptions[QuestionIds.GAME_CLEANUP_PHASE] = ["Choose clean-up items and resolution order.", "Elektu Purigajn povojn"];
EsperantoQuestionDescriptions[QuestionIds.GAME_USE_VILLAGER] = ["You may use a Villager.", "Vi povas uzi Vilaĝanon"];
EsperantoQuestionDescriptions[QuestionIds.GAME_USE_COFFERS] = ["You may use Coffers.", "Vi povas uzi Monrezervon"];
EsperantoQuestionDescriptions[QuestionIds.ARTISAN_GAIN] = ["Gain a card to your hand costing up to $5. ", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.ARTISAN_TOPDECK] = ["Put a card from your hand onto your deck.", "Surmetu karton"];
EsperantoQuestionDescriptions[QuestionIds.BANDIT] = ["Trash a card from PLAYER0.", "Rubigu karton el PLAYER0."];
EsperantoQuestionDescriptions[QuestionIds.BUREAUCRAT] = ["Reveal a Victory card from your hand and put it onto your deck.", "Surmetu Venkkarton"];
EsperantoQuestionDescriptions[QuestionIds.CELLAR] = ["Discard any number of cards, then draw that many.", "Forĵetu, poste tiru"];
EsperantoQuestionDescriptions[QuestionIds.CHAPEL] = ["Trash up to 4 cards from your hand.", "Rubigu ĝis 4 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.HARBINGER_TOPDECK] = ["You may topdeck a card from your discard pile to put onto your deck.", "Vi povas surmeti"];
EsperantoQuestionDescriptions[QuestionIds.LIBRARY] = ["You may skip CARDS0 by setting it aside.", "Vi povas preterpasi CARDS0"];
EsperantoQuestionDescriptions[QuestionIds.MILITIA] = ["Discard down to 3 cards in hand.", "Forĵetu ĝis vi havas 3"];
EsperantoQuestionDescriptions[QuestionIds.MINE_TRASH] = ["You may trash a Treasure from your hand. Gain a Treasure to your hand costing up to $3 more than it.", "Vi povas plibonigi Trezorkarton"];
EsperantoQuestionDescriptions[QuestionIds.MINE_GAIN] = ["Gain a Treasure to your hand costing up to AMOUNT0.", "Gajnu Trezorkarton ĝis AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.MONEYLENDER] = ["You may trash a Copper from your hand for +$3.", "Vi povas rubigi Kupron"];
EsperantoQuestionDescriptions[QuestionIds.POACHER] = ["Discard Argument0.", "Forĵetu Argument0."];
EsperantoQuestionDescriptions[QuestionIds.REMODEL_TRASH] = ["Trash a card from your hand. Gain a card costing up to $2 more than it.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.REMODEL_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gajnu karton ĝis AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.SENTRY_TRASH] = ["Trash any of the revealed cards", "Vi povas rubigi"];
EsperantoQuestionDescriptions[QuestionIds.SENTRY_DISCARD] = ["Discard any of the revealed cards", "Vi povas forĵeti"];
EsperantoQuestionDescriptions[QuestionIds.SENTRY_TOPDECK] = ["Put them back onto your deck in any order", "Surmetu en ajna ordo"];
EsperantoQuestionDescriptions[QuestionIds.THRONE_ROOM] = ["You may play an Action card from your hand twice.", "Vi povas ludi Agkarton dufoje"];
EsperantoQuestionDescriptions[QuestionIds.VASSAL] = ["You may play CARDS0 from your discard pile.", "Vi povas ludi CARDS0"];
EsperantoQuestionDescriptions[QuestionIds.WORKSHOP] = ["Gain a card costing up to $4.", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.TRASHING_TOKEN] = ["You may trash a card from your hand.", "Vi povas rubigi karton"];
EsperantoQuestionDescriptions[QuestionIds.ALMS] = ["Gain a card costing up to $4.", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.AMULET_MODE] = ["Choose one: +$1; or trash a card from your hand; or gain a Silver.", "Elektu kiel ludi Amuleton"];
EsperantoQuestionDescriptions[QuestionIds.AMULET_TRASH] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.ARTIFICER_DELAY] = ["You may discard any number of cards. You may gain a card costing exactly $1 per card discarded, onto your deck.", "Vi povas uzi Farinton"];
EsperantoQuestionDescriptions[QuestionIds.ARTIFICER_DISCARD] = ["Discard any number of cards. You may gain a card costing exactly $1 per card discarded, onto your deck.", "Forĵetu, poste gajnu"];
EsperantoQuestionDescriptions[QuestionIds.ARTIFICER_GAIN] = ["You may gain a card costing exactly AMOUNT0, onto your deck.", "Vi povas gajnu karton por AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.BALL_FIRST] = ["Gain 2 cards each costing up to $4.", "Gajnu du kartojn"];
EsperantoQuestionDescriptions[QuestionIds.BALL_SECOND] = ["Gain another card costing up to $4.", "Gajnu alian karton"];
EsperantoQuestionDescriptions[QuestionIds.BONFIRE] = ["Trash up to 2 cards you have in play.", "Rubigu ĝis 2 kartojn el ludo"];
EsperantoQuestionDescriptions[QuestionIds.DISCIPLE] = ["You may play an Action card from your hand twice. Gain a copy of it.", "Ludu dufoje, poste gajnu kopion"];
EsperantoQuestionDescriptions[QuestionIds.DUNGEON] = ["Discard 2 cards.", "Forĵetu 2 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.FERRY] = ["Move your -$2 cost token to an Action Supply pile.", "Movu -$2 jetonon"];
EsperantoQuestionDescriptions[QuestionIds.FUGITIVE] = ["Discard a card.", "Forĵetu karton"];
EsperantoQuestionDescriptions[QuestionIds.GEAR] = ["Set aside up to 2 cards from your hand. At the start of your next turn, put them into your hand.", "Flankenmetu ĝis 2 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.HAUNTED_WOODS] = ["Put your hand onto your deck in any order", "Metu via manon sur vian kartaron"];
EsperantoQuestionDescriptions[QuestionIds.HERO] = ["Gain a Treasure.", "Gajnu Trezorkarton"];
EsperantoQuestionDescriptions[QuestionIds.LOST_ARTS_NEW] = ["Put your +1 Action token on an Action Supply pile.", "Movu +1 Ago jetonon"];
EsperantoQuestionDescriptions[QuestionIds.LOST_ARTS_MOVE] = ["Move your +1 Action token to an Action Supply pile.", "Movu +1 Ago jetonon"];
EsperantoQuestionDescriptions[QuestionIds.MESSENGER_DISCARD] = ["You may put your deck into your discard pile.", "Vi povas forĵeti vian kartaron"];
EsperantoQuestionDescriptions[QuestionIds.MESSENGER_GAIN] = ["Gain a card costing up to $4, and each other player gains a copy of it.", "Disdonu karton kostante ĝis $4"];
EsperantoQuestionDescriptions[QuestionIds.MISER] = ["Choose one: Put a Copper from your hand onto your Tavern mat; or +AMOUNT0", "Kupro al mato aŭ +AMOUNT0?"];
EsperantoQuestionDescriptions[QuestionIds.PATHFINDING_NEW] = ["Put your +1 Card token on an Action Supply pile.", "Movu +1 Karto jetonon"];
EsperantoQuestionDescriptions[QuestionIds.PATHFINDING_MOVE] = ["Move your +1 Card token to an Action Supply pile.", "Movu +1 Karto jetonon"];
EsperantoQuestionDescriptions[QuestionIds.PILGRIMAGE_FIRST] = ["You may choose up to 3 differently named cards you have in play and gain a copy of each.", "Elektu 3 kartojn por gajni"];
EsperantoQuestionDescriptions[QuestionIds.PILGRIMAGE_SECOND] = ["You may choose another 2 differently named cards you have in play and gain a copy of each.", "Elektu 2 kartojn por gajni"];
EsperantoQuestionDescriptions[QuestionIds.PILGRIMAGE_THIRD] = ["You may choose one more differently named card you have in play to gain a copy of.", "Elektu 1 karton por gajni"];
EsperantoQuestionDescriptions[QuestionIds.PLAN_NEW] = ["Put your Trashing token on an Action Supply pile.", "Movu Rubigjetonon"];
EsperantoQuestionDescriptions[QuestionIds.PLAN_MOVE] = ["Move your Trashing token to an Action Supply pile.", "Movu Rubigjetonon"];
EsperantoQuestionDescriptions[QuestionIds.QUEST_MODE] = ["Choose between discarding an Attack, two Curses, or six cards.", "Elektu vian serĉadon"];
EsperantoQuestionDescriptions[QuestionIds.QUEST_ATTACK] = ["Discard an Attack card.", "Forĵetu Atakkarton"];
EsperantoQuestionDescriptions[QuestionIds.QUEST_6CARDS] = ["Discard six cards.", "Forĵetu ses kartojn"];
EsperantoQuestionDescriptions[QuestionIds.RATCATCHER] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_PLAY] = ["You may trash Raze from play.", "Vi povas rubigi Eldetruon"];
EsperantoQuestionDescriptions[QuestionIds.RAZE_ALL] = ["Trash a card from your hand or Raze from play.", "Rubigu karton aŭ rubigu Eldetruon"];
EsperantoQuestionDescriptions[QuestionIds.RAZE_CAN_TRASH_FROM_HAND] = ["You may trash a card from your hand.", "Vi povas rubigi karton"];
EsperantoQuestionDescriptions[QuestionIds.RAZE_CHOOSE_CARD] = ["Choose a card to put into your hand.", "Metu karton en vian manon"];
EsperantoQuestionDescriptions[QuestionIds.SAVE] = ["Set aside a card from your hand, and put it into your hand at end of turn (after drawing).", "Flankenmetu karton"];
EsperantoQuestionDescriptions[QuestionIds.SCOUTING_PARTY_DISCARD] = ["Discard NUMBER0 cards.", "Forĵetu NUMBER0 kartojn."];
EsperantoQuestionDescriptions[QuestionIds.SCOUTING_PARTY_TOPDECK] = ["Put the remaining two cards on top of your deck in any order.", "Surmetu ĉi tiujn kartojn"];
EsperantoQuestionDescriptions[QuestionIds.SEAWAY] = ["Gain an Action card costing up to $4. Move your +1 Buy token to its pile.", "Gajnu Agkarton"];
EsperantoQuestionDescriptions[QuestionIds.SOLDIER] = ["Discard a card.", "Forĵetu karton"];
EsperantoQuestionDescriptions[QuestionIds.STORYTELLER_FIRST] = ["Play up to 3 Treasures from your hand.", "Ludu ĝis 3 Trezorkartojn"];
EsperantoQuestionDescriptions[QuestionIds.STORYTELLER_SECOND] = ["Play up to 2 more Treasures from your hand.", "Ludu ĝis 2 Trezorkartojn"];
EsperantoQuestionDescriptions[QuestionIds.STORYTELLER_THIRD] = ["You may play another Treasure from your hand.", "Vi povas ludi alian Trezorkarton"];
EsperantoQuestionDescriptions[QuestionIds.TEACHER_CHOOSE_TOKEN] = ["Choose which token to move.", "Elektu kiun jetonon"];
EsperantoQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_MOVE] = ["Put your +1 Action token on an Action Supply pile you have no tokens on.", "Movu +1 Ago jetonon"];
EsperantoQuestionDescriptions[QuestionIds.TEACHER_PLUS_ACTION_NEW] = ["Move your +1 Action token to an Action Supply pile you have no tokens on.", "Movu +1 Ago jetonon"];
EsperantoQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_MOVE] = ["Put your +1 Card token on an Action Supply pile you have no tokens on.", "Movu +1 Karto jetonon"];
EsperantoQuestionDescriptions[QuestionIds.TEACHER_PLUS_CARD_NEW] = ["Move your +1 Card token to an Action Supply pile you have no tokens on. ", "Movu +1 Karto jetonon"];
EsperantoQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_MOVE] = ["Put your +1 Coin token on an Action Supply pile you have no tokens on.", "Movu +1 Monero jetonon"];
EsperantoQuestionDescriptions[QuestionIds.TEACHER_PLUS_COIN_NEW] = ["Move your +1 Coin token to an Action Supply pile you have no tokens on.", "Movu +1 Monero jetonon"];
EsperantoQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_MOVE] = ["Put your +1 Buy token on an Action Supply pile you have no tokens on.", "Movu +1 Aĉeto jetonon"];
EsperantoQuestionDescriptions[QuestionIds.TEACHER_PLUS_BUY_NEW] = ["Move your +1 Buy token to an Action Supply pile you have no tokens on.", "Movu +1 Aĉeto jetonon"];
EsperantoQuestionDescriptions[QuestionIds.TRADE] = ["Trash up to 2 cards from your hand. Gain a Silver per card you trashed.", "Rubigu ĝis 2 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.TRAINING_NEW] = ["Put your +1 Coin token on an Action Supply pile.", "Movu +1 Monero jetonon"];
EsperantoQuestionDescriptions[QuestionIds.TRAINING_MOVE] = ["Move your +1 Coin token to an Action Supply pile.", "Movu +1 Monero jetonon"];
EsperantoQuestionDescriptions[QuestionIds.TRANSMOGRIFY_TRASH] = ["Trash a card from your hand, and gain a card costing up to $1 more than it into your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.TRANSMOGRIFY_GAIN] = ["Gain a card costing up to AMOUNT0 into your hand.", "Gajnu karton ĝis AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.APOTHECARY] = ["Put the cards back on your deck in any order.", "Surmetu ĉi tiujn kartojn"];
EsperantoQuestionDescriptions[QuestionIds.APPRENTICE] = ["Trash a card from your hand. +1 Card per $1 it costs. +2 Cards if it has [P] in its cost.", "Rubigu, poste tiru"];
EsperantoQuestionDescriptions[QuestionIds.GOLEM] = ["Choose which Action to play first.", "Elektu ludordon"];
EsperantoQuestionDescriptions[QuestionIds.HERBALIST] = ["You may put one of your Treasures from play onto your deck.", "Vi povas surmeti Trezorkarton"];
EsperantoQuestionDescriptions[QuestionIds.HERBALISTS] = ["What Treasures to topdeck", "Topdeck up to Argument0 Treasures"];
EsperantoQuestionDescriptions[QuestionIds.SCRYING_POOL_YOU] = ["Keep or discard CARDS0", "Vi: gardu aŭ forĵetu CARDS0"];
EsperantoQuestionDescriptions[QuestionIds.SCRYING_POOL_OTHER] = ["Choose whether PLAYER1 should keep or discard CARDS0.", "PLAYER1: gardu aŭ forĵetu CARDS0."];
EsperantoQuestionDescriptions[QuestionIds.TRANSMUTE] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.UNIVERSITY] = ["You may gain an Action card costing up to $5.", "Vi povas gajni Agkarton"];
EsperantoQuestionDescriptions[QuestionIds.FOLLOWERS] = ["Discard down to 3 cards in hand.", "Forĵetu ĝis vi havas 3"];
EsperantoQuestionDescriptions[QuestionIds.DIADEM] = ["Use Villagers before getting Coins for Actions?", "Uzu Vilaĝanojn?"];
EsperantoQuestionDescriptions[QuestionIds.HAMLET] = ["You may discard to get +1 Action and/or +1 Buy", "Vi povas forĵeti"];
EsperantoQuestionDescriptions[QuestionIds.HAMLET_ACTION] = ["Discard a card for +1 Action.", "Forĵetu por +1 Ago"];
EsperantoQuestionDescriptions[QuestionIds.HAMLET_BUY] = ["Discard a card for +1 Buy.", "Forĵetu por +1 Aĉeto"];
EsperantoQuestionDescriptions[QuestionIds.HAMLET_BOTH] = ["Discard 2 cards.", "Forĵetu 2 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.HORN_OF_PLENTY] = ["Gain a card costing up to AMOUNT0. If it's a Victory card, trash this.", "Gajnu karton ĝis AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.HORSE_TRADERS] = ["Discard 2 cards.", "Forĵetu 2 kartojn."];
EsperantoQuestionDescriptions[QuestionIds.JESTER] = ["Choose who gains CARDS0: You or PLAYER1.", "Elektu tiun, kiu gajnas CARDS0"];
EsperantoQuestionDescriptions[QuestionIds.REMAKE_TRASH_FIRST] = ["Do this twice: Trash a card from your hand, then gain a card costing exactly $1 more than it.", "Rubigu unuan karton"];
EsperantoQuestionDescriptions[QuestionIds.REMAKE_TRASH_SECOND] = ["Trash another card from your hand, then gain a card costing exactly $1 more than it.", "Rubigu alian karton"];
EsperantoQuestionDescriptions[QuestionIds.REMAKE_GAIN_FIRST] = ["Gain a card costing exactly COST0", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.REMAKE_GAIN_SECOND] = ["Gain a card costing exactly COST0", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.TOURNAMENT_GAIN_PRIZE_OR_DUCHY] = ["Gain a Prize or a Duchy onto your deck.", "Gajnu Premion aŭ Duklandon"];
EsperantoQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_PRIZE] = ["You may gain a Prize onto your deck.", "Vi povas gajni Premion"];
EsperantoQuestionDescriptions[QuestionIds.TOURNAMENT_MAY_GAIN_DUCHY] = ["You may gain a Duchy onto your deck.", "Vi povas gajni Duklandon"];
EsperantoQuestionDescriptions[QuestionIds.TOURNAMENT_REVEAL] = ["You may reveal a Province.", "Vi povas malkaŝi Provincon"];
EsperantoQuestionDescriptions[QuestionIds.TRUSTY_STEED] = ["Choose how to play Trusty Steed.", "Elektu kiel ludi Lojalan Ĉevalon"];
EsperantoQuestionDescriptions[QuestionIds.YOUNG_WITCH] = ["Discard 2 cards.", "Forĵetu 2 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.YOUNG_WITCH_BANE] = ["You may reveal CARDS0 to block Young Witch.", "Ĉu malkaŝu CARDS0 por bari Junan Sorĉistinon?"];
EsperantoQuestionDescriptions[QuestionIds.ALTAR_TRASH] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.ALTAR_GAIN] = ["Gain a card costing up to $5.", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.ARMORY] = ["Gain a card onto your deck costing up to $4.", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.BAND_OF_MISFITS] = ["Play an Action card costing less then this.", "Ludu karton"];
EsperantoQuestionDescriptions[QuestionIds.CATACOMBS_MODE] = ["Put CARDS0 into your hand; or discard them and +3 Cards.", "Prenu aŭ tiru novajn?"];
EsperantoQuestionDescriptions[QuestionIds.CATACOMBS_GAIN] = ["Gain a card cheaper than AMOUNT0.", "Gajnu karton pli malmultekostan ol AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.COUNT_PAY] = ["Choose one: Discard 2 cards; or put a card from your hand onto your deck; or gain a Copper.", "Elektu malbonan elekton por Grafo"];
EsperantoQuestionDescriptions[QuestionIds.COUNT_DISCARD] = ["Discard 2 cards.", "Forĵetu 2 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.COUNT_TOPDECK] = ["Put a card from your hand onto your deck.", "Surmetu karton"];
EsperantoQuestionDescriptions[QuestionIds.COUNT_PROFIT] = ["Choose one: +$3; or trash your hand; or gain a Duchy.", "Elektu bonan elekton por Grafo"];
EsperantoQuestionDescriptions[QuestionIds.COUNTERFEIT] = ["You may play a Treasure from your hand twice. If you do, trash that Treasure.", "Ludu dufoje, poste rubigu"];
EsperantoQuestionDescriptions[QuestionIds.CULTIST] = ["You may play a Cultist from your hand for free.", "Ĉu ludu alian Sektanon?"];
EsperantoQuestionDescriptions[QuestionIds.DAME_ANNA] = ["You may trash up to 2 cards from your hand.", "Rubigu ĝis 2 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.DAME_NATALIE] = ["You may gain a card costing up to $3.", "Vi povas gajni karton"];
EsperantoQuestionDescriptions[QuestionIds.DEATH_CART] = ["You may trash an Action card from your hand or Death Cart from play.", "Vi povas rubigi Kadavroĉaron aŭ Agkarton."];
EsperantoQuestionDescriptions[QuestionIds.FORAGER] = ["Trash a card from your hand.", "Rubigi karton"];
EsperantoQuestionDescriptions[QuestionIds.KNIGHTS] = ["Choose a card to trash.", "Elektu karton por rubigi"];
EsperantoQuestionDescriptions[QuestionIds.KNIGHTS_ALREADY_DIES] = ["Choose a card to trash. (CARDS0 already dies)", "Elektu karton por rubigi"];
EsperantoQuestionDescriptions[QuestionIds.KNIGHTS_STILL_MATTERS] = ["Choose a card to trash (CARDS1 will be trashed if you trash CARDS0)", "Elektu karton por rubigi"];
EsperantoQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_EXPAND] = ["You may trash from your hand and gain a card costing up to $3 more than it.", "Vi povas rubigi karton"];
EsperantoQuestionDescriptions[QuestionIds.GRAVEROBBER_EXPAND] = ["Trash from your hand and gain a card costing up to $3 more than it.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.GRAVEROBBER_MAY_GAIN] = ["You may gain from the trash onto your deck.", "Vi povas gajni el la Rubujo"];
EsperantoQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN] = ["Gain from the trash onto your deck.", "Gajnu el la Rubujo"];
EsperantoQuestionDescriptions[QuestionIds.GRAVEROBBER_COMPLEX] = ["When playing Graverobber", "Gajnu karton el la Rubujo aŭ rubigu Agkarton"];
EsperantoQuestionDescriptions[QuestionIds.GRAVEROBBER_GAIN_SUPPLY] = ["Gain a card costing up to AMOUNT0.", "Gajnu karton ĝis AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.HERMIT_TRASH] = ["You may trash a non-Treasure card from your discard pile or hand.", "Vi povas rubigi"];
EsperantoQuestionDescriptions[QuestionIds.HERMIT_GAIN] = ["Gain a card costing up to $3.", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.HUNTING_GROUNDS] = ["Gain a Duchy or 3 Estates.", "Duklandon aŭ 3 Bienojn?"];
EsperantoQuestionDescriptions[QuestionIds.IRONMONGER] = ["You may keep or discard CARDS0.", "Vi povas forĵeti CARDS0"];
EsperantoQuestionDescriptions[QuestionIds.JUNK_DEALER] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.MERCENARY_ONE] = ["You may trash a card from your hand (for no bonus).", "Vi povas rubigi karton"];
EsperantoQuestionDescriptions[QuestionIds.MERCENARY_MULTIPLE] = ["You may trash two cards from your hand.", "Vi povas rubigi 2 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.MERCENARY_DISCARD] = ["Discard down to 3 cards.", "Forĵetu ĝis vi havas 3"];
EsperantoQuestionDescriptions[QuestionIds.MYSTIC] = ["Guess what card is on top of your deck.", "Divenu karton"];
EsperantoQuestionDescriptions[QuestionIds.PILLAGE] = ["Choose which card PLAYER0 discards.", "Elektu forĵeton por PLAYER0"];
EsperantoQuestionDescriptions[QuestionIds.PROCESSION_PLAY] = ["You may play an Action card from your hand twice.", "Vi povas ludi Agkarton dufoje."];
EsperantoQuestionDescriptions[QuestionIds.PROCESSION_GAIN] = ["Gain an Action costing exactly AMOUNT0.", "Gajnu Agkarton por AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.RATS_TRASH_ANY] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.RATS_TRASH_OTHER] = ["Trash a card from your hand other than a Rats.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.REBUILD_SKIP] = ["Name a Victory card to skip (or Copper).", "Nomumu karton por preterpasi"];
EsperantoQuestionDescriptions[QuestionIds.REBUILD_GAIN] = ["Gain a Victory card costing up to AMOUNT0.", "Gajnu Venkkarton ĝis AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.ROGUE_TRASH] = ["Choose a card from PLAYER0 to trash.", "Rubigu karton el PLAYER0"];
EsperantoQuestionDescriptions[QuestionIds.ROGUE_GAIN] = ["Gain a card from the trash costing from $3 to $6.", "Gajnu karton el la Rubujo"];
EsperantoQuestionDescriptions[QuestionIds.SCAVENGER] = ["You may put your deck into your discard pile.", "Vi povas forĵeti vian kartaron"];
EsperantoQuestionDescriptions[QuestionIds.SCAVENGER_TOPDECK] = ["Put a card from your discard pile onto your deck.", "Surmetu karton"];
EsperantoQuestionDescriptions[QuestionIds.SIR_MICHAEL] = ["Discard down to 3 cards.", "Forĵetu ĝis vi havas 3 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.SQUIRE_MODE] = ["Choose one: +2 Actions; or +2 Buys; or gain a Silver.", "Elektu por Eskviro"];
EsperantoQuestionDescriptions[QuestionIds.SQUIRE_GAIN] = ["Gain an Attack card.", "Gajnu Atakkarton"];
EsperantoQuestionDescriptions[QuestionIds.STOREROOM_CARDS] = ["Discard any number of cards, then draw that many.", "Forĵetu por kartoj"];
EsperantoQuestionDescriptions[QuestionIds.STOREROOM_COINS] = ["Discard any number of cards for +$1 each.", "Forĵetu por moneroj"];
EsperantoQuestionDescriptions[QuestionIds.SURVIVORS] = ["Topdeck these two cards in any order, or discard them both.", "Surmetu aŭ forĵetu"];
EsperantoQuestionDescriptions[QuestionIds.URCHIN] = ["Discard down to 4 cards.", "Forĵetu ĝis vi havas 4 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.WANDERING_MINSTREL] = ["Put the Action cards back in any order.", "Surmetu en ajna ordo"];
EsperantoQuestionDescriptions[QuestionIds.ADVANCE_TRASH] = ["You may trash an Action card from your hand.", "Vi povas rubigi Agkarton"];
EsperantoQuestionDescriptions[QuestionIds.ADVANCE_GAIN] = ["Gain an Action card costing up to $6.", "Gajnu Agkarton"];
EsperantoQuestionDescriptions[QuestionIds.ANNEX] = ["Choose up to 5 cards not to shuffle into your deck.", "Elektu ĝis 5 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.ARCHIVE] = ["Choose a card to put into your hand.", "Elektu karton"];
EsperantoQuestionDescriptions[QuestionIds.ARENA] = ["You may discard an Action card for NUMBER0VP.", "Areno: ĉu forĵeti por NUMBER0VP?"];
EsperantoQuestionDescriptions[QuestionIds.BANQUET] = ["Gain a non-Victory card costing up to $5.", "Gajnu ne-Venkan karton"];
EsperantoQuestionDescriptions[QuestionIds.BUSTLING_VILLAGE] = ["You may put a Settlers into your hand.", "Vi povas preni Setlantojn"];
EsperantoQuestionDescriptions[QuestionIds.CATAPULT_TRASH] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.CATAPULT_DISCARD] = ["Discard down to 3 cards.", "Forĵetu ĝis vi havas 3 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.CHARM_MODE] = ["Choose how to play Charm", "Elektu kiu ludu Ĉarmon"];
EsperantoQuestionDescriptions[QuestionIds.CHARM_GAIN] = ["Gain a card other than CARDS0 costing exactly AMOUNT1.", "Gajnu karton por AMOUNT1 (ne CARDS0)"];
EsperantoQuestionDescriptions[QuestionIds.CROWN_ACTION] = ["You may play an Action from your hand twice.", "Vi povas ludi Agkarton dufoje"];
EsperantoQuestionDescriptions[QuestionIds.CROWN_TREASURE] = ["You may play a Treasure from your hand twice.", "Vi povas ludi Trezorkarton dufoje"];
EsperantoQuestionDescriptions[QuestionIds.DONATE] = ["Trash any number of cards.", "Rubigu iom ajn"];
EsperantoQuestionDescriptions[QuestionIds.ENCAMPMENT] = ["You may reveal a Gold or Plunder from your hand.", "Vi povas malkaŝi Oron aŭ Prirabaĵon"];
EsperantoQuestionDescriptions[QuestionIds.ENGINEER_GAIN] = ["Gain a card costing up to $4.", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.ENGINEER_TRASH] = ["You may trash the Engineer.", "Vi povas rubigi la Inĝenieron"];
EsperantoQuestionDescriptions[QuestionIds.FORUM] = ["Discard 2 cards.", "Form 2 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.GLADIATOR_REVEAL] = ["Reveal a card from your hand.", "Malkaŝu karton"];
EsperantoQuestionDescriptions[QuestionIds.GLADIATOR_PRETEND] = ["Pretend to think about revealing a copy.", "Ŝajnigu pensi"];
EsperantoQuestionDescriptions[QuestionIds.GLADIATOR_BLOCK] = ["You may reveal CARDS0 from your hand.", "Vi povas malkaŝi CARDS0"];
EsperantoQuestionDescriptions[QuestionIds.HAUNTED_CASTLE] = ["Put 2 cards from your hand onto your deck.", "Surmetu 2 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.LEGIONARY_REVEAL] = ["You may reveal a Gold from your hand.", "Vi povas malkaŝi Oron"];
EsperantoQuestionDescriptions[QuestionIds.LEGIONARY_DISCARD] = ["Discard down to 2 cards in hand.", "Forĵetu ĝis vi havas 2 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_NEW] = ["Bid up to 40D.", "Proponu ĝis 40D"];
EsperantoQuestionDescriptions[QuestionIds.MOUNTAIN_PASS_OVERBID] = ["Bid up to 40D. Currently PLAYER0 holds the high bid of NUMBER1.", "Proponu ĝis 40D"];
EsperantoQuestionDescriptions[QuestionIds.OPULENT_CASTLE] = ["Discard any number of Victory cards for +$2 each. ", "Forĵetu por +$2 po ĉio"];
EsperantoQuestionDescriptions[QuestionIds.OVERLORD] = ["Play an Action card costing up to $5. ", "Ludu karton"];
EsperantoQuestionDescriptions[QuestionIds.RITUAL] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.SACRIFICE] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.SALT_THE_EARTH] = ["Trash a Victory card from the Supply.", "Rubigu el la Provizo"];
EsperantoQuestionDescriptions[QuestionIds.SETTLERS] = ["You may put a Copper from your discard pile into your hand.", "Vi povas preni Kupron"];
EsperantoQuestionDescriptions[QuestionIds.SMALL_CASTLE_IN_PLAY] = ["Trash Small Castle from play or a Castle from your hand.", "Rubigu Kastelon"];
EsperantoQuestionDescriptions[QuestionIds.SMALL_CASTLE_GONE] = ["Trash a Castle from your hand.", "Rubigu Kastelon"];
EsperantoQuestionDescriptions[QuestionIds.SMALL_CASTLE_GAIN] = ["Gain a Castle.", "Gajnu Kastelon"];
EsperantoQuestionDescriptions[QuestionIds.SPRAWLING_CASTLE] = ["Gain a Duchy or 3 Estates.", "Duklandon aŭ 3 Bienojn?"];
EsperantoQuestionDescriptions[QuestionIds.TAX] = ["Choose a supply pile to add (2D) to.", "Aldonu (2D) al stako"];
EsperantoQuestionDescriptions[QuestionIds.TEMPLE] = ["Trash from 1 to 3 differently named cards from your hand.", "Rubigu 1 - 3 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.WILD_HUNT] = ["Choose between +3 Cards and the Estate and VP", "Kartojn aŭ poentojn?"];
EsperantoQuestionDescriptions[QuestionIds.ADVISOR] = ["Choose what card PLAYER0 doesn't get.", "Forĵetu por PLAYER0"];
EsperantoQuestionDescriptions[QuestionIds.BUTCHER_TRASH] = ["You may trash a card from your hand.", "Vi povas rubigi karton"];
EsperantoQuestionDescriptions[QuestionIds.BUTCHER_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gajnu karton ĝis AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.DOCTOR_PLAY] = ["Name a card to trash.", "Nomumu karton"];
EsperantoQuestionDescriptions[QuestionIds.DOCTOR_TOPDECK] = ["Put these cards back in any order.", "Surmetu ĉi tiujn kartojn"];
EsperantoQuestionDescriptions[QuestionIds.DOCTOR_OVERPAY] = ["You may overpay for Doctor.", "Vi povas tropagi"];
EsperantoQuestionDescriptions[QuestionIds.DOCTOR_MODE] = ["What to do with CARDS0?", "Kion faras kun CARDS0?"];
EsperantoQuestionDescriptions[QuestionIds.DOCTOR_MODE_MANY] = ["What to do with CARDS0? (NUMBER1 more)", "CARDS0? (NUMBER1 pli)"];
EsperantoQuestionDescriptions[QuestionIds.HERALD_OVERPAY] = ["You may overpay for Herald.", "Vi povas tropagi"];
EsperantoQuestionDescriptions[QuestionIds.HERALD_CHOOSE] = ["Put a card from your discard pile on your deck.", "Surmetu karton"];
EsperantoQuestionDescriptions[QuestionIds.JOURNEYMAN] = ["Name a card not to draw.", "Nomumu karton"];
EsperantoQuestionDescriptions[QuestionIds.MASTERPIECE] = ["You may overpay for Masterpiece.", "Vi povas tropagi"];
EsperantoQuestionDescriptions[QuestionIds.PLAZA_DELAY] = ["You may discard a Treasure to take a Coin token.", "Vi povas forĵeti Trezorkarton"];
EsperantoQuestionDescriptions[QuestionIds.PLAZA_DISCARD] = ["Discard a Treasure.", "Forĵetu Trezorkarton"];
EsperantoQuestionDescriptions[QuestionIds.STONEMASON_TRASH] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.STONEMASON_GAIN1] = ["Gain 2 cards each costing less than AMOUNT0.", "Gajnu 1an karton pli malmultekostan ol AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.STONEMASON_GAIN2] = ["Gain another card costing less than AMOUNT0.", "Gajnu 2an karton pli malmultekostan ol AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.STONEMASON_OVERPAY] = ["You may overpay for stonemason by gaining an Action up to AMOUNT0.", "Tropagu gajnante"];
EsperantoQuestionDescriptions[QuestionIds.STONEMASON_GAIN_EQUAL] = ["Gain another Action costing exactly AMOUNT0.", "Gajnu alian Agkarton por AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.TAXMAN_TRASH] = ["You may trash a Treasure from your hand.", "Vi povas rubigi Trezorkarton"];
EsperantoQuestionDescriptions[QuestionIds.TAXMAN_GAIN] = ["Gain a treasure costing up to AMOUNT0 onto your deck.", "Gajnu Trezorkarton ĝis AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.BORDER_VILLAGE] = ["Gain a card cheaper than AMOUNT0.", "Gajnu karton pli malmultekostan ol AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.CARTOGRAPHER_DISCARD] = ["Discard any number.", "Forĵetu iom ajn"];
EsperantoQuestionDescriptions[QuestionIds.CARTOGRAPHER_TOPDECK] = ["Put the remaining cards on top of your deck in any order.", "Surmetu ĉi tiujn kartojn"];
EsperantoQuestionDescriptions[QuestionIds.DEVELOP_TRASH] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.DEVELOP_GAIN1] = ["Gain a card onto your deck costing AMOUNT0 or Argument1.", "Gajnu 1an karton por AMOUNT0 aŭ Argument1"];
EsperantoQuestionDescriptions[QuestionIds.DEVELOP_GAIN2] = ["Gain a card onto your deck costing AMOUNT0.", "Gajnu 2an karton por AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.DUCHESS] = ["You may discard CARDS0.", "Vi povas forĵeti CARDS0"];
EsperantoQuestionDescriptions[QuestionIds.EMBASSY] = ["Discard 3 cards.", "Forĵetu 3 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.FARMLAND_TRASH] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.FARMLAND_GAIN] = ["Gain a card costing exactly AMOUNT0.", "Gajnu karton por AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.HAGGLER] = ["Gain a non-victory costing less than AMOUNT0.", "Gajnu ne-Venkan karton pli malmultekostan ol AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.ILL_GOTTEN_GAINS] = ["You may gain a Copper to your hand.", "Vi povas gajni Kupron"];
EsperantoQuestionDescriptions[QuestionIds.INN_DISCARD] = ["Discard 2 cards.", "Forĵetu 2 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.INN_SHUFFLE] = ["Shuffle any number of Actions from your discard pile into your deck.", "Miksu Agkartojn"];
EsperantoQuestionDescriptions[QuestionIds.JACK_DISCARD] = ["You may discard CARDS0.", "Vi povas forĵeti CARDS0"];
EsperantoQuestionDescriptions[QuestionIds.JACK_TRASH] = ["You may trash a non-Treasure from your hand.", "Vi povas rubigi ne-Trezoran karton"];
EsperantoQuestionDescriptions[QuestionIds.MANDARIN_PLAY] = ["Put a card from your hand onto your deck.", "Surmetu karton"];
EsperantoQuestionDescriptions[QuestionIds.MANDARIN_GAIN] = ["Put all Treasures you have in play onto your deck in any order.", "Surmetu vian Trezorkartojn"];
EsperantoQuestionDescriptions[QuestionIds.MARGRAVE] = ["Discard down to 3 cards in hand.", "Forĵetu ĝis vi havas 3 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.NOBLE_BRIGAND] = ["Choose a Treasure to have trashed.", "Elektu Trezorkartojn"];
EsperantoQuestionDescriptions[QuestionIds.OASIS] = ["Discard a card.", "Forĵetu karton"];
EsperantoQuestionDescriptions[QuestionIds.ORACLE_DISCARD] = ["You may discard CARDS0 from PLAYER1.", "Ĉu igu PLAYER1 forĵeti?"];
EsperantoQuestionDescriptions[QuestionIds.ORACLE_TOPDECK] = ["Put the cards on top of your deck in any order.", "Surmetu ĉi tiujn kartojn"];
EsperantoQuestionDescriptions[QuestionIds.SCHEME] = ["You may choose a card to scheme", "Vi povas surmetu Agkarton"];
EsperantoQuestionDescriptions[QuestionIds.SCHEMES] = ["What cards to Scheme", "Surmetu ĝis Argument0 Agkartojn"];
EsperantoQuestionDescriptions[QuestionIds.SPICE_MERCHANT_TRASH] = ["You may trash a Treasure from your hand.", "Vi povas rubigi Trezorkarton"];
EsperantoQuestionDescriptions[QuestionIds.SPICE_MERCHANT_MODE] = ["Choose one: +2 Cards and +1 Action; or +1 Buy and +$2.", "Elektu kiel ludi Spickomerciston"];
EsperantoQuestionDescriptions[QuestionIds.STABLES] = ["You may discard a Treasure.", "Vi povas forĵeti Trezorkarton"];
EsperantoQuestionDescriptions[QuestionIds.TRADER_TRASH] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.BARON] = ["You may discard an Estate.", "Vi povas forĵeti Bienon"];
EsperantoQuestionDescriptions[QuestionIds.COURTIER_REVEAL] = ["Reveal a card from your hand.", "Malkaŝu karton"];
EsperantoQuestionDescriptions[QuestionIds.COURTIER_MODE] = ["Choose NUMBER0: +1 Action; or +1 Buy; or +$3; or gain a Gold. The choices must be different.", "Elektu NUMBER0 elektojn"];
EsperantoQuestionDescriptions[QuestionIds.COURTYARD] = ["Put a card from your hand onto your deck.", "Surmetu karton"];
EsperantoQuestionDescriptions[QuestionIds.DIPLOMAT] = ["Discard 3 cards.", "Forĵetu 3 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.IRONWORKS] = ["Gain a card costing up to $4.", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.LURKER_MAY_TRASH] = ["You may trash an Action card from the Supply.", "Vi povas rubigi el la Provizo"];
EsperantoQuestionDescriptions[QuestionIds.LURKER_TRASH] = ["Trash an Action card from the Supply.", "Rubigu el la Provizo"];
EsperantoQuestionDescriptions[QuestionIds.LURKER_MAY_GAIN] = ["You may gain an Action card from the trash.", "Vi povas gajni el la Rubujo"];
EsperantoQuestionDescriptions[QuestionIds.LURKER_GAIN] = ["Gain an Action card from the trash.", "Gajnu el la Rubujo"];
EsperantoQuestionDescriptions[QuestionIds.MASQUERADE_PASS] = ["Pass a card to PLAYER0.", "Donu karton al PLAYER0"];
EsperantoQuestionDescriptions[QuestionIds.MASQUERADE_TRASH] = ["You may trash a card from your hand.", "Vi povas rubigi karton"];
EsperantoQuestionDescriptions[QuestionIds.MILL] = ["You may discard 2 cards", "Vi povas forĵeti 2 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.MINING_VILLAGE] = ["You may trash Mining Village.", "Vi povas rubigi Minantan Vilaĝon"];
EsperantoQuestionDescriptions[QuestionIds.MINION] = ["Choose one: +$2 or new hands.", "Elektu +$2 aŭ novajn manojn"];
EsperantoQuestionDescriptions[QuestionIds.NOBLES] = ["Choose one: +3 Cards; or +2 Actions.", "Elektu +3 Kartoj aŭ +2 Agoj"];
EsperantoQuestionDescriptions[QuestionIds.PATROL] = ["Put these cards back in any order.", "Surmetu ĉi tiujn kartojn"];
EsperantoQuestionDescriptions[QuestionIds.PAWN] = ["Choose how to play Pawn.", "Elektu kiel ludi Peonon"];
EsperantoQuestionDescriptions[QuestionIds.REPLACE_TRASH] = ["Trash a card from your hand.", "Rubigi karton"];
EsperantoQuestionDescriptions[QuestionIds.REPLACE_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gajnu karton ĝis AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.SECRET_PASSAGE_TOPDECK] = ["Put a card onto your empty deck.", "Surmetu karton"];
EsperantoQuestionDescriptions[QuestionIds.SECRET_PASSAGE_INSERT] = ["Choose a card to put into your deck.", "Elektu karton"];
EsperantoQuestionDescriptions[QuestionIds.STEWARD_MODE] = ["Choose one: +2 Cards; or +$2; or trash 2 cards from your hand.", "Elektu kiel ludi Intendanton"];
EsperantoQuestionDescriptions[QuestionIds.STEWARD_TRASH] = ["Choose 2 cards to trash.", "Elektu 2 kartojn por rubigi"];
EsperantoQuestionDescriptions[QuestionIds.SWINDLER] = ["Choose a card for PLAYER0 to gain.", "Elektu karton por PLAYER0"];
EsperantoQuestionDescriptions[QuestionIds.TRADING_POST] = ["Trash 2 cards from your hand.", "Rubigu 2 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.UPGRADE_TRASH] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.UPGRADE_GAIN] = ["Gain a card costing exactly AMOUNT0.", "Gajnu karton por AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.WISHING_WELL] = ["Guess what card is on top of your deck.", "Divenu karton"];
EsperantoQuestionDescriptions[QuestionIds.SUMMON] = ["Gain an Action card costing up to $4.", "Voku karton"];
EsperantoQuestionDescriptions[QuestionIds.GOVERNOR_MODE] = ["Choose how to play Governor", "Elektu kiel ludi Guberniestron"];
EsperantoQuestionDescriptions[QuestionIds.GOVERNOR_TRASH] = ["Trash a card from your hand.", "Rubigi karton"];
EsperantoQuestionDescriptions[QuestionIds.GOVERNOR_MAY_TRASH] = ["You may trash a card from your hand.", "Vi povas rubigi"];
EsperantoQuestionDescriptions[QuestionIds.GOVERNOR_GAIN] = ["Gain a card costing exactly Argument0", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.SAUNA_CHAIN] = ["You may play an Avanto without spending an action", "Vi povas ludi Glacitruon"];
EsperantoQuestionDescriptions[QuestionIds.SAUNA_MAY_TRASH] = ["You may trash a card with Sauna", "Vi povas rubigi"];
EsperantoQuestionDescriptions[QuestionIds.AVANTO_CHAIN] = ["You may play a Sauna without spending an action", "Vi povas ludi Saŭnon"];

EsperantoQuestionDescriptions[QuestionIds.BISHOP_SELF] = ["Trash a card from your hand.", "Rubigi karton"];
EsperantoQuestionDescriptions[QuestionIds.BISHOP_OTHER] = ["You may trash a card from your hand.", "Vi povas rubigi karton"];
EsperantoQuestionDescriptions[QuestionIds.CONTRABAND] = ["Name a card PLAYER0 can't buy this turn.", "Malpermesu karton"];
EsperantoQuestionDescriptions[QuestionIds.COUNTING_HOUSE] = ["How many Coppers to put into your hand?", "Prenu Kuprojn"];
EsperantoQuestionDescriptions[QuestionIds.EXPAND_TRASH] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.EXPAND_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gajnu karton ĝis AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.FORGE_TRASH] = ["Trash any number of cards from your hand.", "Rubigu iom ajn"];
EsperantoQuestionDescriptions[QuestionIds.FORGE_GAIN] = ["Gain a card costing exactly AMOUNT0.", "Gajnu karton por AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.GOONS] = ["Discard down to 3 cards in hand.", "Forĵetu ĝis vi havas 3 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.KINGS_COURT] = ["You may play an Action card three times.", "Vi povas ludi Agkarton trifoje"];
EsperantoQuestionDescriptions[QuestionIds.LOAN] = ["Discard or trash CARDS0.", "Forĵetu aŭ rubigu CARDS0"];
EsperantoQuestionDescriptions[QuestionIds.MINT] = ["You may reveal a Treasure from your hand. Gain a copy of it.", "Vi povas kopii Trezorkarton"];
EsperantoQuestionDescriptions[QuestionIds.MOUNTEBANK] = ["You may discard a Curse.", "Vi povas forĵeti Malbenon"];
EsperantoQuestionDescriptions[QuestionIds.RABBLE] = ["Put these cards back in any order.", "Surmetu ĉi tiujn kartojn"];
EsperantoQuestionDescriptions[QuestionIds.TRADE_ROUTE] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.VAULT_SELF] = ["Discard any number of cards.", "Forĵetu iom ajn"];
EsperantoQuestionDescriptions[QuestionIds.VAULT_OTHER] = ["You may discard 2 cards.", "Vi povas forĵeti 2 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.AMBASSADOR_REVEAL] = ["Reveal a card from your hand.", "Malkaŝu karton el via mano"];
EsperantoQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_ONE] = ["You may return CARDS0 to the Supply.", "Vi povas redoni CARDS0"];
EsperantoQuestionDescriptions[QuestionIds.AMBASSADOR_RETURN_TWO] = ["Return 0, 1 or CARDS0 to the Supply.", "Redoni 0, 1 aŭ 2"];
EsperantoQuestionDescriptions[QuestionIds.EMBARGO] = ["Add an Embargo token to a Supply pile.", "Embargu stakon"];
EsperantoQuestionDescriptions[QuestionIds.EXPLORER] = ["You may reveal a Province.", "Vi povas malkaŝi Provincon"];
EsperantoQuestionDescriptions[QuestionIds.GHOST_SHIP] = ["Put NUMBER0 cards on top of your deck.", "Surmetu NUMBER0 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.HAVEN] = ["Set a card aside.", "Flankenmetu karton"];
EsperantoQuestionDescriptions[QuestionIds.ISLAND] = ["Put a card from your hand onto your Island mat.", "Metu karton sur vian maton"];
EsperantoQuestionDescriptions[QuestionIds.LOOKOUT_TRASH] = ["Trash a card.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.LOOKOUT_DISCARD] = ["Discard a card.", "Forĵetu karton"];
EsperantoQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_TAKE] = ["You may put CARDS0 into your hand.", "Ĉu prenu Argument0?"];
EsperantoQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_MAY_SET_ASIDE] = ["You may put the top card of your deck onto your mat.", "Vi povas meti sur vian maton"];
EsperantoQuestionDescriptions[QuestionIds.NATIVE_VILLAGE_BOTH] = ["Add a card to your mat or take CARDS0 into your hand.", "Ĉu prenu Argument0?"];
EsperantoQuestionDescriptions[QuestionIds.NAVIGATOR] = ["Topdeck these 5 cards in any order, or discard them all.", "Surmetu aŭ forĵetu"];
EsperantoQuestionDescriptions[QuestionIds.PEARL_DIVER] = ["Put CARDS0 on top or bottom of your deck.", "Metu CARDS0 supre aŭ funde"];
EsperantoQuestionDescriptions[QuestionIds.PIRATE_SHIP_MODE] = ["Choose one: +AMOUNT0 or attack.", "+AMOUNT0 aŭ Ataku"];
EsperantoQuestionDescriptions[QuestionIds.PIRATE_SHIP_TRASH] = ["Choose a Treasure to trash.", "Elektu Trezorkarton por rubigi"];
EsperantoQuestionDescriptions[QuestionIds.SALVAGER] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.SMUGGLERS_MAY_GAIN] = ["You may gain a copy.", "Vi povas gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.SMUGGLERS_GAIN] = ["Gain a copy.", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.WAREHOUSE] = ["Discard 3 cards.", "Forĵetu 3 kartojn."];
EsperantoQuestionDescriptions[QuestionIds.LURKER_COMPLEX] = ["When playing Lurker", "Dum ludante Kaŭranton"];
EsperantoQuestionDescriptions[QuestionIds.BLACK_MARKET_COMPLEX] = ["You play Black Market", "Vi povas ludi Nigran Merkaton"];
EsperantoQuestionDescriptions[QuestionIds.BLACK_MARKET_BUY] = ["buy one of the revealed cards.", "Vi povas aĉeti"];
EsperantoQuestionDescriptions[QuestionIds.BLACK_MARKET_PLAY] = ["play Treasures from your hand.", "Vi povas ludi Trezorkartojn"];
EsperantoQuestionDescriptions[QuestionIds.ENVOY] = ["Choose a card to discard.", "Forĵetu karton"];
//EsperantoQuestionDescriptions[QuestionIds.PRINCE_MODE] = ["PRINCE_MODE", "Ĉu flankenmetu Princon?"];
EsperantoQuestionDescriptions[QuestionIds.PRINCE_MAY_SET_ASIDE] = ["You may set aside a card with ", "Vi povas flankenmetu karton"];
EsperantoQuestionDescriptions[QuestionIds.TORTURER_MAY_CURSE] = ["You may gain a Curse.", "Vi povas gajni Malbenon"];
EsperantoQuestionDescriptions[QuestionIds.TORTURER_MAY_DISCARD] = ["You may discard 2 cards.", "Vi povas forĵeti 2 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.TORTURER_DISCARD_OR_CURSE] = ["Either gain a Curse or discard 2 cards.", "Forĵetu 2 kartojn aŭ gajnu Malbenon"];
//EsperantoQuestionDescriptions[QuestionIds.WHAT_TO_IMPERSONATE] = ["Choose a card to impersonate.", "Elektu karton por imiti"];
EsperantoQuestionDescriptions[QuestionIds.INHERITANCE] = ["Choose a card to inherit.", "Elektu karton por Heredi"];

EsperantoQuestionDescriptions[QuestionIds.SHORT_YOU_MAY_REACT] = ["-", "Vi povas reagi"];
EsperantoQuestionDescriptions[QuestionIds.SHORT_WHEN_GAIN] = ["-", "Vi gajnas Argument0..."];
EsperantoQuestionDescriptions[QuestionIds.SHORT_WHEN_WOULD_GAIN] = ["-", "Vi gajnus Argument0..."];

EsperantoQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL_SPECIFIC] = ["-", "Ludu aŭ alvoku Argument0..."];
EsperantoQuestionDescriptions[QuestionIds.SHORT_PLAY_CALL] = ["-", "Ludu aŭ alvoku Agkartojn"];
EsperantoQuestionDescriptions[QuestionIds.SHORT_PLAY] = ["-", "Vi povas ludi Agkartojn"];
EsperantoQuestionDescriptions[QuestionIds.SHORT_CALL_SPECIFIC] = ["-", "Vi povas alvoki Argument0"];
EsperantoQuestionDescriptions[QuestionIds.SHORT_CALL] = ["-", "Vi povas alvoki kartojn"];

EsperantoQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_DISCARD] = ["You may discard a Treasure", "Vi povas forĵeti"];
EsperantoQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_GAIN] = ["Gain a card costing up to $4.", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.THE_FLAMES_GIFT] = ["You may trash a card from your hand.", "Vi povas rubigi"];
EsperantoQuestionDescriptions[QuestionIds.THE_MOONS_GIFT] = ["You may put a card onto your deck.", "Vi povas surmeti"];
EsperantoQuestionDescriptions[QuestionIds.THE_SKYS_GIFT] = ["You may discard 3 cards to gain a Gold.", "Vi povas forĵeti"];
EsperantoQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_TOPDECK] = ["put the rest back in any order", "remetu la ceteron"];
EsperantoQuestionDescriptions[QuestionIds.THE_SUNS_GIFT_DISCARD] = ["Discard any number", "Forĵetu iom ajn"];
EsperantoQuestionDescriptions[QuestionIds.THE_WINDS_GIFT] = ["Discard 2 cards.", "Forĵetu 2 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.FEAR_DISCARD] = ["discard an Action or Treasure", "Forĵetu karton"];
EsperantoQuestionDescriptions[QuestionIds.HAUNTING] = ["Topdeck something.", "Surmetu karton"];
EsperantoQuestionDescriptions[QuestionIds.LOCUSTS] = ["Gain a cheaper card that shares a type.", "Gajnu pli malmultekostan karton"];
EsperantoQuestionDescriptions[QuestionIds.POVERTY] = ["Discard down to 3 cards in hand", "Forĵetu ĝis vi havas 3"];
EsperantoQuestionDescriptions[QuestionIds.BAT] = ["You may trash up to 2 cards", "Vi povas rubigi"];
EsperantoQuestionDescriptions[QuestionIds.BLESSED_VILLAGE_MODE] = ["When to receive Argument0?", "Kiam ricevas?"];
EsperantoQuestionDescriptions[QuestionIds.CEMETERY] = ["You may trash up to 4 cards", "Vi povas rubigi"];
EsperantoQuestionDescriptions[QuestionIds.CHANGELING_GAIN_COPY] = ["Gain a copy of a card you have in play", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.CHANGELING_REPLACE] = ["", ""];
EsperantoQuestionDescriptions[QuestionIds.COBBLER] = ["Gain a card costing up to $4 to your hand.", "Gajnu al via mano"];
EsperantoQuestionDescriptions[QuestionIds.CONCLAVE] = ["You may play an Action", "Vi povas ludi Agkarton"];
EsperantoQuestionDescriptions[QuestionIds.CRYPT_SET_ASIDE] = ["Set aside any number of Treasures you have in play", "Flankenmetu iom ajn"];
EsperantoQuestionDescriptions[QuestionIds.CRYPT_RETURN_TO_HAND] = ["Choose a card to put into your hand.", "Elektu karton"];
EsperantoQuestionDescriptions[QuestionIds.DEVILS_WORKSHOP] = ["Gain a card costing up to $4.", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.DRUID] = ["Which Boon to receive?", "Kiun Benon?"];
EsperantoQuestionDescriptions[QuestionIds.EXORCIST_TRASH] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.EXORCIST_GAIN] = ["Gain a cheaper Spirit", "Gajnu pli malmultekostan Spiriton"];
EsperantoQuestionDescriptions[QuestionIds.FOOL] = ["What Boon next?", "Kiun Benon poste?"];
EsperantoQuestionDescriptions[QuestionIds.GOAT] = ["You may trash a card", "Vi povas rubigi"];
EsperantoQuestionDescriptions[QuestionIds.HAUNTED_MIRROR] = ["You may discard an action to get a Ghost", "Vi povas forĵeti"];
EsperantoQuestionDescriptions[QuestionIds.IMP] = ["You may play an action you don't have in play", "Vi povas ludi"];
EsperantoQuestionDescriptions[QuestionIds.NECROMANCER] = ["Play from trash", "Ludu el Rubujo"];
EsperantoQuestionDescriptions[QuestionIds.MONASTERY] = ["You may trash up to AMOUNT0 cards or Coppers in play.", "Vi povas rubigi ĝis AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_TOPDECK] = ["Topdeck the rest", "Surmetu la ceteron"];
EsperantoQuestionDescriptions[QuestionIds.NIGHT_WATCHMAN_DISCARD] = ["Discard any number", "Forĵetu iom ajn"];
EsperantoQuestionDescriptions[QuestionIds.PIXIE_MODE] = ["Trash Pixie to receive twice?", "Ĉu rubigu Feeton?"];
EsperantoQuestionDescriptions[QuestionIds.POOKA] = ["You may trash a Treasure other than Cursed Gold", "Vi povas rubigi"];
EsperantoQuestionDescriptions[QuestionIds.RAIDER] = ["Discard a card", "Forĵetu karton"];
EsperantoQuestionDescriptions[QuestionIds.SACRED_GROVE_MODE] = ["Also receive Argument0?", "Ĉu ricevu Argument0?"];
EsperantoQuestionDescriptions[QuestionIds.SECRET_CAVE] = ["You may discard 3 cards for +$3 next turn", "Vi povas forĵeti"];
EsperantoQuestionDescriptions[QuestionIds.SHEPHERD] = ["Discard Victory cards for +2 cards each", "Vi povas forĵeti"];
EsperantoQuestionDescriptions[QuestionIds.TRAGIC_HERO] = ["Gain a Treasure", "Gajnu Trezorkarton"];
EsperantoQuestionDescriptions[QuestionIds.VAMPIRE] = ["Gain a non-Vampire up to 5", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.WISH] = ["Gain a card to your hand", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.ZOMBIE_APPRENTICE] = ["You may trash an Action", "Vi povas rubigi"];
EsperantoQuestionDescriptions[QuestionIds.ZOMBIE_MASON] = ["You may gain a card", "Vi povas gajni karton"];
EsperantoQuestionDescriptions[QuestionIds.ZOMBIE_SPY] = ["You may Discard", "Vi povas forĵeti"];
EsperantoQuestionDescriptions[QuestionIds.WHAT_NIGHT_TO_PLAY] = ["You may play a Night card", "Vi povas ludo Noktokarton"];
EsperantoQuestionDescriptions[QuestionIds.LOST_IN_THE_WOODS] = ["You may discard a card to receive a Boon", "Ĉu forĵetu por Beno?"];
EsperantoQuestionDescriptions[QuestionIds.START_GAME] = ["Inspect your starting cards", "Viaj komencaj kartoj:"];
EsperantoQuestionDescriptions[QuestionIds.DISMANTLE_TRASH] = ["Trash a card from your hand", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.DISMANTLE_GAIN] = ["Gain a card cheaper than AMOUNT0.", "Gajnu karton pli malmultekostan ol AMOUNT0"];

EsperantoQuestionDescriptions[QuestionIds.DUCAT] = ["You may trash a Copper.", "Vi povas rubigi Kupron"];
EsperantoQuestionDescriptions[QuestionIds.IMPROVE_TRASH] = ["You may trash an Action in play that would be discarded", "Vi povas Plibonigi"];
EsperantoQuestionDescriptions[QuestionIds.IMPROVE_GAIN] = ["Gain a card costing exactly AMOUNT0.", "Gajnu karton por AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.HIDEOUT] = ["Trash a card. If it's a Victory card, gain a Curse", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.MOUNTAIN_VILLAGE] = ["Put a card from your discard into your hand", "Metu en vian manon"];
EsperantoQuestionDescriptions[QuestionIds.PRIEST] = ["Trash a card from your hand", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.RESEARCH_TRASH] = ["Trash a card from your hand", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.OLD_WITCH] = ["You may trash a Curse from your hand", "Vi povas rubigi"];
EsperantoQuestionDescriptions[QuestionIds.RECRUITER] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.SCEPTER_COMPLEX] = ["When playing Scepter", "Ĉu reludu aŭ monerojn?"];
EsperantoQuestionDescriptions[QuestionIds.SCEPTER_MODE] = ["get +$2", "aldonu Monerojn"];
EsperantoQuestionDescriptions[QuestionIds.SCEPTER_PLAY] = ["replay an Action still in play", "Reludu Agkarton"];
EsperantoQuestionDescriptions[QuestionIds.SCULPTOR] = ["Gain a card costing up to $4 to your hand.", "Gajnu al via mano"];
EsperantoQuestionDescriptions[QuestionIds.SEER] = ["Put them back onto your deck in any order", "Surmetu en ajna ordo"];
EsperantoQuestionDescriptions[QuestionIds.VILLAIN] = ["Discard a card costing 2 or more.", "Forĵetu karton"];
EsperantoQuestionDescriptions[QuestionIds.TREASURER_COMPLEX] = ["When playing Treasurer", "Kiam ludante Trezoriston"];
EsperantoQuestionDescriptions[QuestionIds.TREASURER_TRASH] = ["trash a Treasure", "rubigu Trezorkarton"];
EsperantoQuestionDescriptions[QuestionIds.TREASURER_GAIN] = ["gain a Treasure to your hand", "gajnu Trezorkarton"];
EsperantoQuestionDescriptions[QuestionIds.TREASURER_KEY] = ["take the Key", "prenu la Ŝlosilon"];
EsperantoQuestionDescriptions[QuestionIds.TREASURER_FAIL] = ["do nothing", "faru nenion"];
EsperantoQuestionDescriptions[QuestionIds.BORDER_GUARD_PUT_IN_HAND] = ["Put one of the revealed cards into your hand", "Metu en vian manon"];
EsperantoQuestionDescriptions[QuestionIds.BORDER_GUARD_MODE] = ["What Artifact do you want to take?", "Kornon aŭ Lanternon?"];
EsperantoQuestionDescriptions[QuestionIds.CATHEDRAL] = ["Trash a card from your hand.", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.CITY_GATE] = ["Put a card from your hand onto your deck.", "Surmetu karton."];
EsperantoQuestionDescriptions[QuestionIds.PAGEANT] = ["you may pay $1 for +1 Coffers.", "Ĉu $1 por +1 Monrezervo?"];
EsperantoQuestionDescriptions[QuestionIds.SEWERS] = ["You may trash up to AMOUNT0 cards from your hand. (Sewers)", "Vi povas rubigi ĝis AMOUNT0 (Kloakoj)"];
EsperantoQuestionDescriptions[QuestionIds.SILOS] = ["Discard Coppers for +1 card each", "Vi povas forĵeti"];
EsperantoQuestionDescriptions[QuestionIds.CROP_ROTATION] = ["Discard a Victory card for +2 cards?", "Vi povas forĵeti"];
EsperantoQuestionDescriptions[QuestionIds.SINISTER_PLOT] = ["Draw AMOUNT0 cards or add a token?", "Tiru AMOUNT0 aŭ aldonu jetonon?"];
EsperantoQuestionDescriptions[QuestionIds.STAR_CHART] = ["Select a card to go on top when shuffling", "Elektu karton por meti supre"];
EsperantoQuestionDescriptions[QuestionIds.CAPTAIN] = ["Choose an Action from the supply up to $4 to play", "Elektu kion por ludi"];
EsperantoQuestionDescriptions[QuestionIds.CHURCH_SET_ASIDE] = ["Set aside up to 3 cards", "Flankenmetu ĝis 3 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.CHURCH_TRASH] = ["You may trash a card from your hand.", "Vi povas rubigi karton"];
EsperantoQuestionDescriptions[QuestionIds.CAMEL_TRAIN] = ["Exile a card from the supply.", "Ekzilu karton"];
EsperantoQuestionDescriptions[QuestionIds.GOATHERD] = ["You may trash a card from your hand.", "Vi povas rubigi karton"];
EsperantoQuestionDescriptions[QuestionIds.SNOWY_VILLAGE] = ["You may use villagers while you can", "Ĉu uzu vilaĝanojn dum vi povas?"];
EsperantoQuestionDescriptions[QuestionIds.BOUNTY_HUNTER] = ["Exile a card from your hand", "Ekzilu karton"];
EsperantoQuestionDescriptions[QuestionIds.CARDINAL_EXILE] = ["Exile a card", "Ekzilu karton"];
EsperantoQuestionDescriptions[QuestionIds.GROOM] = ["Gain a card costing up to AMOUNT0.", "Gajnu karton ĝis AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.HOSTELRY] = ["Discard for Horses", "Forĵetu por Ĉevaloj"];
EsperantoQuestionDescriptions[QuestionIds.VILLAGE_GREEN] = ["Now or next turn?", "Ĉu nun aŭ sekva vico?"];
EsperantoQuestionDescriptions[QuestionIds.BARGE] = ["Now or next turn?", "Ĉu nun aŭ sekva vico?"];
EsperantoQuestionDescriptions[QuestionIds.DISPLACE_EXILE] = ["Exile a card from your hand", "Ekzilu karton"];
EsperantoQuestionDescriptions[QuestionIds.DISPLACE_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gajnu karton ĝis AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.FALCONER] = ["Gain a card to your hand", "Gajnu karton al via mano"];
EsperantoQuestionDescriptions[QuestionIds.HUNTING_LODGE] = ["Discard for +5 Cards?", "Ĉu forĵetu por +5 Kartoj?"];
EsperantoQuestionDescriptions[QuestionIds.MASTERMIND] = ["You may play an Action card three times.", "Vi povas ludi Agkarton trifoje"];
EsperantoQuestionDescriptions[QuestionIds.SANCTUARY] = ["You may Exile a Card from your hand", "Vi povas Ekzili karton"];
EsperantoQuestionDescriptions[QuestionIds.WAYFARER] = ["You may gain a Silver", "Vi povas gajni Arĝenton"];
EsperantoQuestionDescriptions[QuestionIds.DELAY] = ["You may set aside an Action", "Vi povas flankenmeti Agkarton"];
EsperantoQuestionDescriptions[QuestionIds.DESPERATION] = ["You may gain a Curse", "Vi povas gajni Malbenon"];
EsperantoQuestionDescriptions[QuestionIds.GAMBLE] = ["Play Argument0?", "Ĉu ludu Argument0?"];
EsperantoQuestionDescriptions[QuestionIds.PURSUE] = ["What card to keep on top?", "Gardu supre kiun karton?"];
EsperantoQuestionDescriptions[QuestionIds.TOIL] = ["You may play a card", "Vi povas ludi karton"];
EsperantoQuestionDescriptions[QuestionIds.ENHANCE_TRASH] = ["You may trash a non-Victory card", "Vi povas rubigu ne-Venkan karton"];
EsperantoQuestionDescriptions[QuestionIds.ENHANCE_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gajnu karton ĝis AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.MARCH] = ["You may play a card from your discard pile", "Vi povas ludi karton el via forĵetita stako"];
EsperantoQuestionDescriptions[QuestionIds.TRANSPORT_MAY_EXILE] = ["You may exile an Action card", "Vi povas Ekzili Agkarton"];
EsperantoQuestionDescriptions[QuestionIds.TRANSPORT_EXILE] = ["Exile an Action card", "Ekzilu Agkarton"];
EsperantoQuestionDescriptions[QuestionIds.TRANSPORT_MAY_TOPDECK] = ["You may topdeck an Exiled Action", "Vi povas surmeti Ekzilitan Agkarton"];
EsperantoQuestionDescriptions[QuestionIds.TRANSPORT_TOPDECK] = ["Topdeck an Exiled Action", "Surmetu Ekzilitan Agkarton"];
EsperantoQuestionDescriptions[QuestionIds.TRANSPORT_COMPLEX] = ["Exile from the Supply; or Topdeck from Exile", "Ekzilu el la Provizo; aŭ surmetu el Ekzilo"];
EsperantoQuestionDescriptions[QuestionIds.BANISH] = ["Exile cards with the same name", "Ekzilu kartojn kun la sama nomo"];
EsperantoQuestionDescriptions[QuestionIds.BARGAIN] = ["Gain a non-Victory up to $5", "Gajnu ne-Venkan karton ĝis $5"];
EsperantoQuestionDescriptions[QuestionIds.INVEST] = ["Exile an Action card to Invest in", "Ekzilu Agkarton por Investi"];
EsperantoQuestionDescriptions[QuestionIds.DEMAND] = ["Gain a card up to $4 onto your deck", "Gajnu karton ĝis $4 sur vian kartaron"];
EsperantoQuestionDescriptions[QuestionIds.POPULATE] = ["What card to gain next?", "Gajnu poste kiun karton?"];
EsperantoQuestionDescriptions[QuestionIds.RECONSIDER_WAY] = ["Reconsider the Way you play Argument0", "Rekonsideru la Vojon por ludi Argument0"];
EsperantoQuestionDescriptions[QuestionIds.CONSIDER_WAY] = ["Consider the Way you play Argument0", "Konsideru la Vojon por ludi Argument0"];
EsperantoQuestionDescriptions[QuestionIds.WAY_OF_THE_GOAT] = ["Trash a Card from your hand.", "Rubigu karton el via mano."];
EsperantoQuestionDescriptions[QuestionIds.WAY_OF_THE_RAT] = ["Discard a Treasure to gain a copy", "Forĵetu Trezorkarton por gajnu kopion"];
EsperantoQuestionDescriptions[QuestionIds.SCRAP_TRASH] = ["Trash a card from your hand.", "Rubigu karton el via mano."];
EsperantoQuestionDescriptions[QuestionIds.SCRAP_MODES] = ["Choose Argument0 effects", "Elektu Argument0 efikojn"];
EsperantoQuestionDescriptions[QuestionIds.ANIMAL_FAIR_TRASH] = ["Trash an Action from your hand", "Rubigu Agkarton el via mano"];
EsperantoQuestionDescriptions[QuestionIds.ANIMAL_FAIR_MAY_TRASH] = ["You may trash an Action from your hand", "Vi povas rubigi Agkarton el via mano"];
EsperantoQuestionDescriptions[QuestionIds.BUTTERFLY_RETURN] = ["Ĉu redonu Argument0 al ĝia stako?", "Ĉu redonu Argument0 al ĝia stako?"];
EsperantoQuestionDescriptions[QuestionIds.BUTTERFLY_GAIN] = EsperantoQuestionDescriptions[QuestionIds.UPGRADE_GAIN];
EsperantoQuestionDescriptions[QuestionIds.BLOCKADE] = ["Gajnu karton kostantan ĝis $4.", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.PIRATE] = ["Gain a Treasure to your hand.", "Gajnu al mano"];
EsperantoQuestionDescriptions[QuestionIds.SAILOR] = ["You may trash a card from your hand.", "Vi povas rubigi karton"];
EsperantoQuestionDescriptions[QuestionIds.TIDE_POOLS] = EsperantoQuestionDescriptions[QuestionIds.DUNGEON];
EsperantoQuestionDescriptions[QuestionIds.SEA_WITCH] = EsperantoQuestionDescriptions[QuestionIds.DUNGEON];
EsperantoQuestionDescriptions[QuestionIds.TOWN_MODE] = ["Choose how to play Town.", "Kiel ludi Urbeton"];
EsperantoQuestionDescriptions[QuestionIds.MODIFY_TRASH] = ["Trash a card from your hand.", "Rubigi karton"];
EsperantoQuestionDescriptions[QuestionIds.MODIFY_MODE] = ["+1 Card +1Action or gain up to $AMOUNT0", "Kiel ludi Modifadon"];
EsperantoQuestionDescriptions[QuestionIds.MODIFY_GAIN] = ["Gain a card costing up to $AMOUNT0.", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.SWAP_RETURN] = ["You may return an Action card to the supply.", "Vi povas redoni karton"];
EsperantoQuestionDescriptions[QuestionIds.SWAP_GAIN] = ["Gain an Action other then ARGUMENT0 to your hand.", "Gajnu Agkarton"];
EsperantoQuestionDescriptions[QuestionIds.SKIRMISHER] = ["Discard down to 3 cards in hand.", "Forĵetu ĝis vi havas 3"];
EsperantoQuestionDescriptions[QuestionIds.WOODWORKERS_GUILD_TRASH] = ["Trash an Action", "Rubigu Agkarton"];
EsperantoQuestionDescriptions[QuestionIds.WOODWORKERS_GUILD_GAIN] = ["Gain an Action", "Gajnu Agkarton"];
EsperantoQuestionDescriptions[QuestionIds.CRAFTERS_GUILD] = ["Spend 2 Favors to gain a card?", "Elspezu 2 Komplezojn por gajni karton?"];
EsperantoQuestionDescriptions[QuestionIds.CAVE_DWELLERS] = ["Spend a Favor and discard to draw?", "Elspezu Komplezon kaj forĵetu por tiri?"];
EsperantoQuestionDescriptions[QuestionIds.DESERT_GUIDES] = ["Keep this hand?", "Gardu ĉi tiun manon?"];
EsperantoQuestionDescriptions[QuestionIds.BAND_OF_NOMADS] = ["Spend more Favors for Buys?", "Elspezu pli Komplezojn por Aĉetojn?"];
EsperantoQuestionDescriptions[QuestionIds.SYCOPHANT] = ["Discard 3 cards", "Forĵetu 3 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.IMPORTER] = ["Gain a card costing up to 5", "Gajnu karton kostantan ĝis $5"];
EsperantoQuestionDescriptions[QuestionIds.CONTRACT] = ["You may play an action next turn", "Vi povas ludi Agkarton en sekva vico"];
EsperantoQuestionDescriptions[QuestionIds.BAUBLE] = ["Choose how to play Bauble.", "Elektu kiel ludi Bagatelon"];
EsperantoQuestionDescriptions[QuestionIds.BROKER_TRASH] = ["What card to trash?", "Rubigu kiun karton?"];
EsperantoQuestionDescriptions[QuestionIds.BROKER_MODE] = ["What bonus to receive?", "Ricevas kiun bonuson?"];
EsperantoQuestionDescriptions[QuestionIds.MARKET_TOWNS_FIRST] = ["What action to play?", "Ludu kiun Agkarton?"];
EsperantoQuestionDescriptions[QuestionIds.MARKET_TOWNS_AGAIN] = ["Play another action for a Favor?", "Elspezu Komplezon por ludu alian Agkarton?"];
EsperantoQuestionDescriptions[QuestionIds.BATTLE_PLAN_REVEAL] = ["Reveal an Attack for +1 Card?", "Malkaŝu Atakkarton por +1 Karto?"];
EsperantoQuestionDescriptions[QuestionIds.BATTLE_PLAN_ROTATE] = ["Rotate a supply pile?", "Rotaciu provizstakon?"];
EsperantoQuestionDescriptions[QuestionIds.BARBARIAN] = ["Gain a cheaper card that shares a type.", "Gajnu malmultekostan karton"];
EsperantoQuestionDescriptions[QuestionIds.INNKEEPER_MODE] = ["How to play Innkeeper?", "Kiel ludu Gastejstron?"];
EsperantoQuestionDescriptions[QuestionIds.INNKEEPER_DISCARD] = ["Discard Argument0 Cards", "Forĵetu Argument0 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.CAPITAL_CITY_MODE] = ["Discard or pay?", "Forĵetu aŭ pagu?"];
EsperantoQuestionDescriptions[QuestionIds.CAPITAL_CITY_DISCARD] = ["Discard 2 Cards", "Forĵetu 2 kartojn"];
EsperantoQuestionDescriptions[QuestionIds.SPECIALIST_PLAY] = ["What card to play?", "Ludu kiun karton?"];
EsperantoQuestionDescriptions[QuestionIds.SPECIALIST_MODE] = ["Play Argument0 again or copy?", "Ludu Argument0 denove aŭ kopiu?"];
EsperantoQuestionDescriptions[QuestionIds.GANG_OF_PICKPOCKETS] = ["Discard or spend a Favor?", "Forĵetu aŭ elspezu Komplezon?"];
EsperantoQuestionDescriptions[QuestionIds.COASTAL_HAVEN] = ["What cards to keep in hand?", "Gardu kiujn kartojn mane?"];
EsperantoQuestionDescriptions[QuestionIds.CARPENTER_GAIN] = EsperantoQuestionDescriptions[QuestionIds.WORKSHOP];
EsperantoQuestionDescriptions[QuestionIds.CARPENTER_TRASH] = EsperantoQuestionDescriptions[QuestionIds.REMODEL_TRASH];
EsperantoQuestionDescriptions[QuestionIds.CARPENTER_GAIN_UP_TO] = EsperantoQuestionDescriptions[QuestionIds.REMODEL_GAIN];
EsperantoQuestionDescriptions[QuestionIds.MAY_ROTATE_FORTS] = ["You may rotate the Forts", "Vi povas rotacii la Redutojn"];
EsperantoQuestionDescriptions[QuestionIds.ROYAL_GALLEY] = ["You may play an Action", "Vi povas ludi Agkarton"];
EsperantoQuestionDescriptions[QuestionIds.HILL_FORT_GAIN] = EsperantoQuestionDescriptions[QuestionIds.WORKSHOP];
EsperantoQuestionDescriptions[QuestionIds.HILL_FORT_MODE] = ["Choose how to play Hill Fort", "Elektu kiel ludi Montetan Fortikaĵon"];
EsperantoQuestionDescriptions[QuestionIds.STRONGHOLD_MODE] = ["Choose how to play Stronghold", "Elektu kiel ludi Fortikaĵegon"];
EsperantoQuestionDescriptions[QuestionIds.MAY_ROTATE_ODYSSEYS] = ["You may rotate the Odysseys", "Vi povas rotacii la Odiseadojn"];
EsperantoQuestionDescriptions[QuestionIds.OLD_MAP_DISCARD] = ["Discard a Card", "Forĵetu karton"];
EsperantoQuestionDescriptions[QuestionIds.SUNKEN_TREASURE] = ["Gain an Action you don't have in play", "Gajnu Agkarton, kiun vi ne havas en ludo"];
EsperantoQuestionDescriptions[QuestionIds.PEACEFUL_CULT] = ["You may trash for Favors", "Vi povas elspezi Komplezojn por rubigi"];
EsperantoQuestionDescriptions[QuestionIds.ORDER_OF_ASTROLOGERS] = ["You may topdeck for Favors", "Vi povas elspezi Komplezojn por surmeti"];
EsperantoQuestionDescriptions[QuestionIds.STAR_CHART_ASTROLOGERS_COMBINED] = ["You may topdeck for Favors (one free)", "Vi povas elspezi Komplezojn por surmeti (unu senpage)"];
EsperantoQuestionDescriptions[QuestionIds.ORDER_OF_MASONS] = ["You may pick 2 cards per Favor to put into your discard", "Vi povas elekti 2 kartojn po Komplezo por meti en via forĵeta stako"];
EsperantoQuestionDescriptions[QuestionIds.ARCHER_HIDE] = ["Choose a Card not to reveal", "Elektu karton por kaŝi"];
EsperantoQuestionDescriptions[QuestionIds.ARCHER_DISCARD] = EsperantoQuestionDescriptions[QuestionIds.PILLAGE];
EsperantoQuestionDescriptions[QuestionIds.MARQUIS] = ["Discard down to 10", "Forĵetu ĝis vi havas 10"];
EsperantoQuestionDescriptions[QuestionIds.HERB_GATHERER_PLAY] = ["What Treasure to play?", "Ludu kiun Trezorkarton?"];
EsperantoQuestionDescriptions[QuestionIds.MAY_ROTATE_AUGURS] = ["You may rotate the Augurs", "Vi povas rotacii la Aŭguristojn"];
EsperantoQuestionDescriptions[QuestionIds.ACOLYTE] = ["Trash to gain a Gold?", "Rubigu por gajni Oron?"];
EsperantoQuestionDescriptions[QuestionIds.ACOLYTE_SELF] = ["Trash to gain an Augur?", "Rubigu por gajni Aŭguriston?"];
EsperantoQuestionDescriptions[QuestionIds.SORCERESS] = ["Guess a Card", "Divenu karton"];
EsperantoQuestionDescriptions[QuestionIds.SIBYL_TOPDECK] = ["Put a card from your hand onto your deck.", "Surmetu karton"];
EsperantoQuestionDescriptions[QuestionIds.SIBYL_BOTTOMDECK] = ["Put a card from your hand on the bottom of your deck.", "Submetu karton"];
EsperantoQuestionDescriptions[QuestionIds.MAY_ROTATE_WIZARDS] = ["You may rotate the Wizards", "Vi povas rotacii la Sorĉistojn"];
EsperantoQuestionDescriptions[QuestionIds.STUDENT] = ["Trash a Card", "Rubigu karton"];
EsperantoQuestionDescriptions[QuestionIds.CONJURER] = EsperantoQuestionDescriptions[QuestionIds.WORKSHOP];
EsperantoQuestionDescriptions[QuestionIds.SORCERER] = ["Guess a Card", "Divenu karton"];
EsperantoQuestionDescriptions[QuestionIds.LICH] = ["Gain a Card from the Trash", "Gajnu karton el rubujo"];
EsperantoQuestionDescriptions[QuestionIds.MAY_ROTATE_TOWNSFOLK] = ["You may rotate the Townsfolk", "Vi povas rotacii la Urbulojn"];
EsperantoQuestionDescriptions[QuestionIds.TOWN_CRIER] = ["How to play Town Crier?", "Kiel ludi Urbanonciston?"];
EsperantoQuestionDescriptions[QuestionIds.MILLER] = ["How to play Miller?", "Kiel ludi Mueliston?"];
EsperantoQuestionDescriptions[QuestionIds.ELDER] = ["You may play a card", "Vi povas ludi karton"];
EsperantoQuestionDescriptions[QuestionIds.SENTINEL_TRASH] = ["Trash up to two cards", "Rubigu ĝis du kartojn"];
EsperantoQuestionDescriptions[QuestionIds.SENTINEL_TOPDECK] = EsperantoQuestionDescriptions[QuestionIds.APOTHECARY];
EsperantoQuestionDescriptions[QuestionIds.COURIER] = ["You may play a card", "Vi povas ludi karton"];
EsperantoQuestionDescriptions[QuestionIds.HUNTER_ACTION] = ["Put an Action into your hand", "Metu Agkarton en via mano"];
EsperantoQuestionDescriptions[QuestionIds.HUNTER_TREASURE] = ["Put a Treasure into your hand", "Metu Trezorkarton en via mano"];
EsperantoQuestionDescriptions[QuestionIds.HUNTER_VICTORY] = ["Put a Victory card into your hand", "Metu Venkkarton en via mano"];
EsperantoQuestionDescriptions[QuestionIds.LURKER_MODE] = ["Choose how to play Lurker", "Elektu kiel ludi Kaŭranton"];
EsperantoQuestionDescriptions[QuestionIds.GRAVEROBBER_MODE] = ["Choose how to play Graverobber", "Elektu kiel ludi Tomborabiston"];
EsperantoQuestionDescriptions[QuestionIds.TREASURER_MODE] = ["Choose how to play Treasurer", "Elektu kiel ludi Trezoriston"];
EsperantoQuestionDescriptions[QuestionIds.ARCHITECTS_GUILD] = ["What to gain", "Kiun gajnu"];
EsperantoQuestionDescriptions[QuestionIds.FAMILY_OF_INVENTORS] = ["What pile to make cheaper", "Malmultekostigu kiun stakon"];
EsperantoQuestionDescriptions[QuestionIds.FOREST_DWELLERS_DISCARD] = ["Discard any number", "Forĵetu iom ajn"];
EsperantoQuestionDescriptions[QuestionIds.FOREST_DWELLERS_TOPDECK] = ["Topdeck in some order", "Surmetu"];
EsperantoQuestionDescriptions[QuestionIds.TIARA_MODE] = ["Choose how to play Tiara", "Elektu kiel ludi Tiaron"];
EsperantoQuestionDescriptions[QuestionIds.TIARA_PLAY] = ["What Treasure to play twice", "Ludu kiun Trezorkarton duoble"];
EsperantoQuestionDescriptions[QuestionIds.CRYSTAL_BALL] = ["What to do with Argument0.", "Kion faru kun Argument0"];
EsperantoQuestionDescriptions[QuestionIds.WAR_CHEST_PROHIBIT] = ["Ban a card for Player0", "Baru karton"];
EsperantoQuestionDescriptions[QuestionIds.WAR_CHEST_GAIN] = ["Gain a card.", "Gajnu karton"];
EsperantoQuestionDescriptions[QuestionIds.ANVIL_DISCARD] = EsperantoQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_DISCARD];
EsperantoQuestionDescriptions[QuestionIds.ANVIL_GAIN] = EsperantoQuestionDescriptions[QuestionIds.THE_EARTHS_GIFT_GAIN];
EsperantoQuestionDescriptions[QuestionIds.CLERK] = ["Topdeck a Card", "Surmetu karton"];
EsperantoQuestionDescriptions[QuestionIds.INVESTMENT_TRASH] = EsperantoQuestionDescriptions[QuestionIds.TRADE_ROUTE];
EsperantoQuestionDescriptions[QuestionIds.INVESTMENT_MODE] = ["Choose how to play Investment", "Elektu kiel ludi Investadon"];
EsperantoQuestionDescriptions[QuestionIds.WHEELWRIGHT_DISCARD] = ["You may discard to Wheelwright", "Forĵetu por gajni"];
EsperantoQuestionDescriptions[QuestionIds.WHEELWRIGHT_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gajnu karton ĝis AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.BERSERKER_DISCARD] = EsperantoQuestionDescriptions[QuestionIds.MILITIA];
EsperantoQuestionDescriptions[QuestionIds.BERSERKER_GAIN] = ["Gajnu pli malmultekostan karton", "Gajnu pli malmultekostan karton"];
EsperantoQuestionDescriptions[QuestionIds.WEAVER] = ["Gain a Card or 2 Silvers", "Gajnu karton aŭ 2 Arĝentojn"];
EsperantoQuestionDescriptions[QuestionIds.SOUK] = ["Trash up to 2", "Rubigu ĝis 2"];
EsperantoQuestionDescriptions[QuestionIds.WITCHS_HUT] = ["Discard 2 Cards revealed", "Forĵetu 2 kartojn, malkaŝante"];
EsperantoQuestionDescriptions[QuestionIds.STONEMASON_OVERPAY_AMOUNT] = ["You may overpay for stonemason by gaining an Action up to AMOUNT0.", "Specify the overpay amount."];

// Missing lines as of 09.01.2023 19:11:55
EsperantoQuestionDescriptions[QuestionIds.HORN_TOPDECK] = ["Which [Border Guard] to topdeck?", "Which [Border Guard] to topdeck?"];
EsperantoQuestionDescriptions[QuestionIds.UNUSED_QUESTION_22] = ["unused", "unused"];
EsperantoQuestionDescriptions[QuestionIds.BLACKSMITH] = ["How to play Blacksmith?", "How to play Blacksmith?"];
EsperantoQuestionDescriptions[QuestionIds.USE_FAVOR_WHEN_SHUFFLING] = ["Use a Favor when shuffling?", "Use a favor when shuffling?"];
EsperantoQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS] = ["You may spend extra coffers", "You may spend extra coffers"];
EsperantoQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_BASILICA] = ["You may spend extra coffers for Basilica", "You may spend extra coffers for Basilica"];
EsperantoQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_SWASHBUCKLER] = ["You may spend extra coffers to not get the Treasure Chest", "You may spend extra coffers to not get the Treasure Chest"];
EsperantoQuestionDescriptions[QuestionIds.MAY_SPEND_EXTRA_COFFERS_FORTUNE] = ["You may spend extra coffers before doubling your Coins", "You may spend extra coffers before doubling your Coins"];

EsperantoQuestionDescriptions[QuestionIds.CAGE_SET_ASIDE] = ["You may set aside up to 4 cards.", "You may set aside up to 4 cards."];
EsperantoQuestionDescriptions[QuestionIds.GROTTO_SET_ASIDE] = ["You may set aside up to 4 cards.", "You may set aside up to 4 cards."];
EsperantoQuestionDescriptions[QuestionIds.SHAMAN_GAIN] = ["Gain a card costing up to 6 from the trash.", "Gain a card costing up to 6 from the trash."];
EsperantoQuestionDescriptions[QuestionIds.SHAMAN_TRASH] = ["You may trash a card from your hand.", "You may trash a card from your hand."];
EsperantoQuestionDescriptions[QuestionIds.SECLUDED_SHRINE] = ["You may trash up to 2 cards from your hand.", "You may trash up to 2 cards from your hand."];
EsperantoQuestionDescriptions[QuestionIds.SIREN] = ["You may trash an Action.", "You may trash an Action."];
EsperantoQuestionDescriptions[QuestionIds.CABIN_BOY_MODE] = ["Choose one: +2 Coins, or trash [Cabin Boy] to gain a Duration.", "Choose one: +2 Coins, or trash [Cabin Boy] to gain a Duration."];
EsperantoQuestionDescriptions[QuestionIds.CABIN_BOY_GAIN] = ["Gain a Duration card.", "Gain a Duration card."];
EsperantoQuestionDescriptions[QuestionIds.CRUCIBLE] = ["Trash a card from your hand.", "Trash a card from your hand."];
EsperantoQuestionDescriptions[QuestionIds.FORTUNE_HUNTER_PLAY] = ["Play a revealed treasure.", "Play a revealed treasure."];
EsperantoQuestionDescriptions[QuestionIds.FORTUNE_HUNTER_TOPDECK] = ["Topdeck the remaining cards.", "Topdeck the remaining cards."];
EsperantoQuestionDescriptions[QuestionIds.GONDOLA_MODE] = ["+2 Coins now or later?", "+2 Coins now or later?"];
EsperantoQuestionDescriptions[QuestionIds.GONDOLA_PLAY] = ["You may play an Action card from your hand.", "You may play an Action card from your hand."];
EsperantoQuestionDescriptions[QuestionIds.MAPMAKER] = ["Put 2 cards into your hand.", "Put 2 cards into your hand."];
EsperantoQuestionDescriptions[QuestionIds.MAROON] = ["Trash a card.", "Trash a card."];
EsperantoQuestionDescriptions[QuestionIds.ROPE_TRASH] = ["You may trash a card from your hand.", "You may trash a card from your hand."];
EsperantoQuestionDescriptions[QuestionIds.TOOLS] = ["Gain a copy of a card somebody has in play.", "Gain a copy of a card somebody has in play."];
EsperantoQuestionDescriptions[QuestionIds.CUTTHROAT_DISCARD] = ["Discard down to 3 cards in hand.", "Discard down to 3 cards in hand."];
EsperantoQuestionDescriptions[QuestionIds.ENLARGE_TRASH] = ["Trash a card.", "Trash a card."];
EsperantoQuestionDescriptions[QuestionIds.ENLARGE_GAIN] = ["Gain a card costing up to AMOUNT0.", "Gain a card costing up to AMOUNT0"];
EsperantoQuestionDescriptions[QuestionIds.FIGURINE] = ["You may discard an Action for +1 Coin and +1 Buy.", "You may discard an Action for +1 Coin and +1 Buy."];
EsperantoQuestionDescriptions[QuestionIds.FIRST_MATE] = ["You may play a card.", "You may play a card."];
EsperantoQuestionDescriptions[QuestionIds.FRIGATE] = ["Discard down to 4 cards in hand.", "Discard down to 4 cards in hand."];
EsperantoQuestionDescriptions[QuestionIds.PICKAXE] = ["Trash a card from your hand.", "Trash a card from your hand."];
EsperantoQuestionDescriptions[QuestionIds.PILGRIM_TOPDECK] = ["Put a card from your hand onto your deck.", "Put a card from your hand onto your deck."];
EsperantoQuestionDescriptions[QuestionIds.QUARTERMASTER_MODE] = ["Gain a card costing up to 4, or take one of: Argument0.", "Gain a card costing up to 4, or take one of: Argument0."];
EsperantoQuestionDescriptions[QuestionIds.QUARTERMASTER_GAIN] = ["Gain a card costing up to 4.", "Gain a card costing up to 4."];
EsperantoQuestionDescriptions[QuestionIds.QUARTERMASTER_TAKE] = ["Put a card into your hand.", "Put a card into your hand."];
EsperantoQuestionDescriptions[QuestionIds.TRICKSTER] = ["You may set aside a treasure from play.", "You may set aside a treasure from play."];
EsperantoQuestionDescriptions[QuestionIds.SILVER_MINE] = ["Gain a treasure into your hand.", "Gain a treasure into your hand."];
EsperantoQuestionDescriptions[QuestionIds.KINGS_CACHE] = ["You may play a Treasure 3 times.", "You may play a Treasure 3 times."];
EsperantoQuestionDescriptions[QuestionIds.BURY] = ["Place a card on the bottom of your deck.", "Place a card on the bottom of your deck."];
EsperantoQuestionDescriptions[QuestionIds.PERIL] = ["You may trash an action to gain a Loot.", "You may trash an action to gain a Loot."];
EsperantoQuestionDescriptions[QuestionIds.PREPARE] = ["Play a set-aside card.", "Play a set-aside card."];
EsperantoQuestionDescriptions[QuestionIds.FORAY] = ["Discard 3 cards.", "Discard 3 cards."];
EsperantoQuestionDescriptions[QuestionIds.SCROUNGE_COMPLEX] = ["When playing Scrounge,", "When playing Scrounge,"];
EsperantoQuestionDescriptions[QuestionIds.SCROUNGE_TRASH] = ["Trash a card from your hand.", "Trash a card from your hand."];
EsperantoQuestionDescriptions[QuestionIds.SCROUNGE_MAY_TRASH] = ["You may trash a card from your hand.", "You may trash a card from your hand."];
EsperantoQuestionDescriptions[QuestionIds.SCROUNGE_GAIN_ESTATE] = ["Gain an Estate.", "Gain an Estate."];
EsperantoQuestionDescriptions[QuestionIds.SCROUNGE_MAY_GAIN_ESTATE] = ["You may gain an Estate.", "You may gain an Estate."];
EsperantoQuestionDescriptions[QuestionIds.SCROUNGE_GAIN] = ["Gain a card costing up to 5.", "Gain a card costing up to 5."];
EsperantoQuestionDescriptions[QuestionIds.MAELSTROM_TRASH] = ["Trash a card.", "Trash a card."];
EsperantoQuestionDescriptions[QuestionIds.MAELSTROM_OPPONENT_TRASH] = ["Trash Argument0 cards.", "Trash Argument0 cards."];
EsperantoQuestionDescriptions[QuestionIds.INVASION_PLAY] = ["You may play an Attack.", "You may play an Attack."];
EsperantoQuestionDescriptions[QuestionIds.INVASION_GAIN] = ["Gain an Action.", "Gain an Action."];
EsperantoQuestionDescriptions[QuestionIds.PROSPER] = ["Gain a differently-named Treasure.", "Gain a differently-named Treasure."];
EsperantoQuestionDescriptions[QuestionIds.AMPHORA_MODE] = ["+1 Buy, +3 Coins now or next turn?", "+1 Buy, +3 Coins now or next turn?"];
EsperantoQuestionDescriptions[QuestionIds.HAMMER] = ["Gain a card costing up to 4.", "Gain a card costing up to 4."];
EsperantoQuestionDescriptions[QuestionIds.ORB] = ["Play an Action or Treasure.", "Play an Action or Treasure."];
EsperantoQuestionDescriptions[QuestionIds.PRIZE_GOAT] = ["You may trash a card.", "You may trash a card."];
EsperantoQuestionDescriptions[QuestionIds.PUZZLE_BOX] = ["Set aside a card from your hand, and put it into your hand at end of turn (after drawing).", "Set a card aside"];
EsperantoQuestionDescriptions[QuestionIds.SEXTANT_DISCARD] = ["Discard any number of cards.", "Discard any number of cards."];
EsperantoQuestionDescriptions[QuestionIds.SEXTANT_TOPDECK] = ["Put the remaining cards back in any order.", "Put the remaining cards back in any order."];
EsperantoQuestionDescriptions[QuestionIds.SPELL_SCROLL_GAIN] = ["Gain a card costing less than AMOUNT0.", "Gain a card costing less than AMOUNT0."];
EsperantoQuestionDescriptions[QuestionIds.SPELL_SCROLL_PLAY] = ["Play Argument0?", "Play Argument0?"];
EsperantoQuestionDescriptions[QuestionIds.STAFF] = ["You may play an Action card.", "You may play an Action card."];
EsperantoQuestionDescriptions[QuestionIds.SWORD] = ["Discard down to 4 cards.", "Discard down to 4 cards."];
EsperantoQuestionDescriptions[QuestionIds.FRIENDLY] = ["Discard a [Friendly] card to gain another.", "Discard a [Friendly] card to gain another."];
EsperantoQuestionDescriptions[QuestionIds.INHERITED] = ["Replace a starting card with an [Inherited] card.", "Replace a starting card with an [Inherited] card."];
EsperantoQuestionDescriptions[QuestionIds.INSPIRING] = ["You may play an action you don't have in play.", "You may play an action you don't have in play ([Inspiring])."];
EsperantoQuestionDescriptions[QuestionIds.PATIENT_SET_ASIDE] = ["Set aside [Patient] cards to play next turn.", "Set aside [Patient] cards to play next turn."];
EsperantoQuestionDescriptions[QuestionIds.PATIENT_PLAY] = ["Which [Patient] card to play next?", "Which [Patient] card to play next?"];
EsperantoQuestionDescriptions[QuestionIds.PIOUS_TRASH] = ["You may trash a card from your hand.", "You may trash a card from your hand."];
EsperantoQuestionDescriptions[QuestionIds.SHY] = ["Discard a [Shy] card for +2 Cards.", "Discard a [Shy] card for +2 Cards."];
EsperantoQuestionDescriptions[QuestionIds.FATED_TOPDECK] = ["You may topdeck Fated Cards.", "You may topdeck Fated Cards."];
EsperantoQuestionDescriptions[QuestionIds.FATED_BOTTOMDECK] = ["You may bottomdeck Fated Cards.", "You may bottomdeck Fated Cards."];
EsperantoQuestionDescriptions[QuestionIds.AVOID] = ["Put up to 3 cards in your Discard pile.", "Put up to 3 cards in your Discard pile."];
EsperantoQuestionDescriptions[QuestionIds.TRICKSTER_DISCARD] = ["What Treasure to set aside?.", "What Treasure to set aside?"];
EsperantoQuestionDescriptions[QuestionIds.MARCHLAND] = ["", "Discard for +$1 each"];
EsperantoQuestionDescriptions[QuestionIds.FARMHANDS] = ["What Card to play at the start of your next turn?.", "What Card to play at the start of your next turn?."];
EsperantoQuestionDescriptions[QuestionIds.COURSER] = ["Choose how to play Courser.", "Choose how to play Courser"];
EsperantoQuestionDescriptions[QuestionIds.CORONET_ACTION] = ["What Action to play twice?", "What Action to play twice?"];
EsperantoQuestionDescriptions[QuestionIds.CORONET_TREASURE] = ["What Treasure to play twice?", "What Treasure to play twice?"];
EsperantoQuestionDescriptions[QuestionIds.JOUST_SET_ASIDE] = ["Set aside a Province?", "Set aside a Province?"];
EsperantoQuestionDescriptions[QuestionIds.JOUST_GAIN_REWARD] = ["What Reward to gain to your hand?", "What Reward to gain to your hand?"];
EsperantoQuestionDescriptions[QuestionIds.INFIRMARY_TRASH] = ["You may trash a card.", "You may trash a card."];
EsperantoQuestionDescriptions[QuestionIds.INFIRMARY_OVERPAY] = ["You may overpay for Infirmary.", "You may overpay"];
EsperantoQuestionDescriptions[QuestionIds.FOOTPAD] = EsperantoQuestionDescriptions[QuestionIds.MILITIA];
EsperantoQuestionDescriptions[QuestionIds.FARRIER_OVERPAY] = ["You may overpay for Farrier.", "You may overpay"];
EsperantoQuestionDescriptions[QuestionIds.SHOP] = EsperantoQuestionDescriptions[QuestionIds.CONCLAVE];
EsperantoQuestionDescriptions[QuestionIds.FERRYMAN] = EsperantoQuestionDescriptions[QuestionIds.FUGITIVE];

EsperantoQuestionDescriptions[QuestionIds.ORDER_BOTTOMDECKED_CARDS] = ["Choose the order on the bottom", "Choose the order on the bottom"];
EsperantoQuestionDescriptions[QuestionIds.GROWTH] = EsperantoQuestionDescriptions[QuestionIds.BORDER_VILLAGE];
EsperantoQuestionDescriptions[QuestionIds.KIND_EMPEROR] = ["Gain an Action to your Hand", "Gain an Action to your Hand"];
EsperantoQuestionDescriptions[QuestionIds.SICKNESS_MODE] = ["", ""];
EsperantoQuestionDescriptions[QuestionIds.SICKNESS_DISCARD] = ["", ""];
EsperantoQuestionDescriptions[QuestionIds.SICKNESS] = ["Discard 3 or gain a Curse onto your deck", "Discard 3 or gain a Curse onto your deck"];
EsperantoQuestionDescriptions[QuestionIds.SNAKE_WITCH] = ["Return [Snake Witch] to Curse?", "Return [Snake Witch] to have each other player gain a Curse?"];
EsperantoQuestionDescriptions[QuestionIds.CRAFTSMAN] = ["Gain a card costing up to 5.", "Gain a card costing up to 5."];
EsperantoQuestionDescriptions[QuestionIds.ALLEY] = EsperantoQuestionDescriptions[QuestionIds.OASIS];
EsperantoQuestionDescriptions[QuestionIds.CHANGE_TRASH] = ["Trash a card from your Hand", "Trash a card from your Hand"];
EsperantoQuestionDescriptions[QuestionIds.CHANGE_GAIN] = ["Gain a card and get debt", "Gain a card and get debt"];
EsperantoQuestionDescriptions[QuestionIds.NINJA] = EsperantoQuestionDescriptions[QuestionIds.MILITIA];
EsperantoQuestionDescriptions[QuestionIds.RIVER_SHRINE_TRASH] = ["Trash up to 2 cards", "Trash up to 2 cards"];
EsperantoQuestionDescriptions[QuestionIds.RIVER_SHRINE_GAIN] = ["Gain a card costing up to 4.", "Gain a card costing up to 4."];
EsperantoQuestionDescriptions[QuestionIds.RUSTIC_VILLAGE_CHOICE] = ["Discard 2 cards to draw 1?", "Discard 2 cards to draw 1?"];
EsperantoQuestionDescriptions[QuestionIds.RUSTIC_VILLAGE_DISCARD] = ["Which 2 cards to discard?", "Which 2 cards to discard?"];
EsperantoQuestionDescriptions[QuestionIds.GOLD_MINE] = ["Gain a Gold and get 4 debt?", "Gain a Gold and get 4 debt?"];
EsperantoQuestionDescriptions[QuestionIds.KITSUNE] = ["Choose how to play Kitsune", "Choose how to play Kitsune"];
EsperantoQuestionDescriptions[QuestionIds.TANUKI_TRASH] = EsperantoQuestionDescriptions[QuestionIds.REMODEL_TRASH];
EsperantoQuestionDescriptions[QuestionIds.TANUKI_GAIN] = EsperantoQuestionDescriptions[QuestionIds.REMODEL_GAIN];
EsperantoQuestionDescriptions[QuestionIds.SAMURAI] = EsperantoQuestionDescriptions[QuestionIds.MILITIA];
EsperantoQuestionDescriptions[QuestionIds.MOUNTAIN_SHRINE] = ["You may trash a card from your hand.", "You may trash a card from your hand."];
EsperantoQuestionDescriptions[QuestionIds.AMASS] = ["Gain an Action card costing up to 5.", "Gain an Action card costing up to 5."];
EsperantoQuestionDescriptions[QuestionIds.ASCETICISM] = ["Trash up to Argument0 Cards.", "Trash up to Argument0 Cards."];
EsperantoQuestionDescriptions[QuestionIds.CONTINUE] = ["Gain and play a non-attack Action card", "Gain and play a non-attack Action card"];
EsperantoQuestionDescriptions[QuestionIds.CREDIT] = ["Gain an Action or Treasure up to 8", "Gain an Action or Treasure up to 8"];
EsperantoQuestionDescriptions[QuestionIds.KINTSUGI_TRASH] = ["Trash a card from your hand.", "Trash a card from your hand."];
EsperantoQuestionDescriptions[QuestionIds.KINTSUGI_GAIN] = EsperantoQuestionDescriptions[QuestionIds.REMODEL_GAIN];
EsperantoQuestionDescriptions[QuestionIds.PRACTICE] = EsperantoQuestionDescriptions[QuestionIds.THRONE_ROOM];
EsperantoQuestionDescriptions[QuestionIds.SEA_TRADE] = ["Trash up to Argument0 Cards.", "Trash up to Argument0 Cards."];
EsperantoQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_FIRST] = ["You may gain 3 Action cards you don't have in play.", "You may gain 3 Action cards you don't have in play."];
EsperantoQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_SECOND] = ["You may gain 2 Action cards you don't have in play.", "You may gain 2 Action cards you don't have in play."];
EsperantoQuestionDescriptions[QuestionIds.RECEIVE_TRIBUTE_THIRD] = ["You may gain an Action card you don't have in play.", "You may gain an Action card you don't have in play."];
EsperantoQuestionDescriptions[QuestionIds.GATHER] = ["Gain a card costing exactly Argument0.", "Gain a card costing exactly Argument0."];

EsperantoQuestionDescriptions[QuestionIds.GAME_PAY_OFF_DEBT] = ["You may pay off Debt.", "You may pay off Debt"];
EsperantoQuestionDescriptions[QuestionIds.MAY_PAY_OFF_EXTRA_DEBT] = ["You may pay off Debt.", "You may pay off Debt"];
EsperantoQuestionDescriptions[QuestionIds.PAY_OFF_DEBT_BEFORE_CHANGE] = ["Pay off Debt to remodel?", "Pay off Debt to remodel?"];
EsperantoQuestionDescriptions[QuestionIds.PAY_OFF_DEBT_BEFORE_STORYTELLER] = ["Pay off Debt to draw fewer Cards?", "Pay off Debt to draw fewer Cards?"];